define({
  "Template":
   {
  "issueCount": 0,
  "testType": "dns",
  "testResultDetails": {
   "passCount": 2479,
    "failureCount": 68,
    "slowCount": 0
  },
  "worstPerformingSite": {
    "tagName": "Global/North America/USA/California/San Jose/SJC01/Flr-SJC1-1",
    "count": 2547
  },
  "KpiPerformanceInterval": [
    {
      "kpi": "dns_queryResponseTimeMillis",
      "performanceThreshold": 2000,
      "averagePerformanceValue": 631.3612032312926,
      "minPerformanceList": [
        {
          "intervalIndex": "interval-0",
          "value": 592.0,
          "endTime": 1542272400000
        },
        {
          "intervalIndex": "interval-1",
          "value": 592.0,
          "endTime": 1542268800000
        },
        {
          "intervalIndex": "interval-2",
          "value": 592.0,
          "endTime": 1542265200000
        },
        {
          "intervalIndex": "interval-3",
          "value": 592.0,
          "endTime": 1542261600000
        },
        {
          "intervalIndex": "interval-4",
          "value": 131.0,
          "endTime": 1542258000000
        },
        {
          "intervalIndex": "interval-5",
          "value": 592.0,
          "endTime": 1542254400000
        },
        {
          "intervalIndex": "interval-6",
          "value": 592.0,
          "endTime": 1542250800000
        },
        {
          "intervalIndex": "interval-7",
          "value": 592.0,
          "endTime": 1542247200000
        },
        {
          "intervalIndex": "interval-8",
          "value": 592.0,
          "endTime": 1542243600000
        },
        {
          "intervalIndex": "interval-9",
          "value": 592.0,
          "endTime": 1542240000000
        },
        {
          "intervalIndex": "interval-10",
          "value": 593.0,
          "endTime": 1542236400000
        },
        {
          "intervalIndex": "interval-11",
          "value": 593.0,
          "endTime": 1542232800000
        },
        {
          "intervalIndex": "interval-12",
          "value": 593.0,
          "endTime": 1542229200000
        },
        {
          "intervalIndex": "interval-13",
          "value": 593.0,
          "endTime": 1542225600000
        },
        {
          "intervalIndex": "interval-14",
          "value": 591.0,
          "endTime": 1542222000000
        },
        {
          "intervalIndex": "interval-15",
          "value": 593.0,
          "endTime": 1542218400000
        },
        {
          "intervalIndex": "interval-16",
          "value": 592.0,
          "endTime": 1542214800000
        },
        {
          "intervalIndex": "interval-17",
          "value": 592.0,
          "endTime": 1542211200000
        },
        {
          "intervalIndex": "interval-18",
          "value": 592.0,
          "endTime": 1542207600000
        },
        {
          "intervalIndex": "interval-19",
          "value": 593.0,
          "endTime": 1542204000000
        },
        {
          "intervalIndex": "interval-20",
          "value": 593.0,
          "endTime": 1542200400000
        },
        {
          "intervalIndex": "interval-21",
          "value": 592.0,
          "endTime": 1542196800000
        },
        {
          "intervalIndex": "interval-22",
          "value": 592.0,
          "endTime": 1542193200000
        },
        {
          "intervalIndex": "interval-23",
          "value": 593.0,
          "endTime": 1542189600000
        },
        {
          "intervalIndex": "interval-24",
          "value": 592.0,
          "endTime": 1542186000000
        },
        {
          "intervalIndex": "interval-25",
          "value": 592.0,
          "endTime": 1542182400000
        },
        {
          "intervalIndex": "interval-26",
          "value": 592.0,
          "endTime": 1542178800000
        },
        {
          "intervalIndex": "interval-27",
          "value": 592.0,
          "endTime": 1542175200000
        },
        {
          "intervalIndex": "interval-28",
          "value": 593.0,
          "endTime": 1542171600000
        },
        {
          "intervalIndex": "interval-29",
          "value": 592.0,
          "endTime": 1542168000000
        },
        {
          "intervalIndex": "interval-30",
          "value": 592.0,
          "endTime": 1542164400000
        },
        {
          "intervalIndex": "interval-31",
          "value": 593.0,
          "endTime": 1542160800000
        },
        {
          "intervalIndex": "interval-32",
          "value": 592.0,
          "endTime": 1542157200000
        },
        {
          "intervalIndex": "interval-33",
          "value": 592.0,
          "endTime": 1542153600000
        },
        {
          "intervalIndex": "interval-34",
          "value": 593.0,
          "endTime": 1542150000000
        },
        {
          "intervalIndex": "interval-35",
          "value": 591.0,
          "endTime": 1542146400000
        },
        {
          "intervalIndex": "interval-36",
          "value": 593.0,
          "endTime": 1542142800000
        },
        {
          "intervalIndex": "interval-37",
          "value": 592.0,
          "endTime": 1542139200000
        },
        {
          "intervalIndex": "interval-38",
          "value": 593.0,
          "endTime": 1542135600000
        },
        {
          "intervalIndex": "interval-39",
          "value": 592.0,
          "endTime": 1542132000000
        },
        {
          "intervalIndex": "interval-40",
          "value": 592.0,
          "endTime": 1542128400000
        },
        {
          "intervalIndex": "interval-41",
          "value": 591.0,
          "endTime": 1542124800000
        },
        {
          "intervalIndex": "interval-42",
          "value": 592.0,
          "endTime": 1542121200000
        },
        {
          "intervalIndex": "interval-43",
          "value": 593.0,
          "endTime": 1542117600000
        },
        {
          "intervalIndex": "interval-44",
          "value": 593.0,
          "endTime": 1542114000000
        },
        {
          "intervalIndex": "interval-45",
          "value": 593.0,
          "endTime": 1542110400000
        },
        {
          "intervalIndex": "interval-46",
          "value": 592.0,
          "endTime": 1542106800000
        },
        {
          "intervalIndex": "interval-47",
          "value": 592.0,
          "endTime": 1542103200000
        },
        {
          "intervalIndex": "interval-48",
          "value": 592.0,
          "endTime": 1542099600000
        },
        {
          "intervalIndex": "interval-49",
          "value": 591.0,
          "endTime": 1542096000000
        },
        {
          "intervalIndex": "interval-50",
          "value": 592.0,
          "endTime": 1542092400000
        },
        {
          "intervalIndex": "interval-51",
          "value": 591.0,
          "endTime": 1542088800000
        },
        {
          "intervalIndex": "interval-52",
          "value": 594.0,
          "endTime": 1542085200000
        },
        {
          "intervalIndex": "interval-53",
          "value": 592.0,
          "endTime": 1542081600000
        },
        {
          "intervalIndex": "interval-54",
          "value": 592.0,
          "endTime": 1542078000000
        },
        {
          "intervalIndex": "interval-55",
          "value": 592.0,
          "endTime": 1542074400000
        },
        {
          "intervalIndex": "interval-56",
          "value": 593.0,
          "endTime": 1542070800000
        },
        {
          "intervalIndex": "interval-57",
          "value": 593.0,
          "endTime": 1542067200000
        },
        {
          "intervalIndex": "interval-58",
          "value": 591.0,
          "endTime": 1542063600000
        },
        {
          "intervalIndex": "interval-59",
          "value": 591.0,
          "endTime": 1542060000000
        },
        {
          "intervalIndex": "interval-60",
          "value": 593.0,
          "endTime": 1542056400000
        },
        {
          "intervalIndex": "interval-61",
          "value": 95.0,
          "endTime": 1542052800000
        },
        {
          "intervalIndex": "interval-62",
          "value": 591.0,
          "endTime": 1542049200000
        },
        {
          "intervalIndex": "interval-63",
          "value": 593.0,
          "endTime": 1542045600000
        },
        {
          "intervalIndex": "interval-64",
          "value": 592.0,
          "endTime": 1542042000000
        },
        {
          "intervalIndex": "interval-65",
          "value": 593.0,
          "endTime": 1542038400000
        },
        {
          "intervalIndex": "interval-66",
          "value": 593.0,
          "endTime": 1542034800000
        },
        {
          "intervalIndex": "interval-67",
          "value": 592.0,
          "endTime": 1542031200000
        },
        {
          "intervalIndex": "interval-68",
          "value": 593.0,
          "endTime": 1542027600000
        },
        {
          "intervalIndex": "interval-69",
          "value": 591.0,
          "endTime": 1542024000000
        },
        {
          "intervalIndex": "interval-70",
          "value": 591.0,
          "endTime": 1542020400000
        },
        {
          "intervalIndex": "interval-71",
          "value": 591.0,
          "endTime": 1542016800000
        },
        {
          "intervalIndex": "interval-72",
          "value": 592.0,
          "endTime": 1542013200000
        },
        {
          "intervalIndex": "interval-73",
          "value": 592.0,
          "endTime": 1542009600000
        },
        {
          "intervalIndex": "interval-74",
          "value": 592.0,
          "endTime": 1542006000000
        },
        {
          "intervalIndex": "interval-75",
          "value": 592.0,
          "endTime": 1542002400000
        },
        {
          "intervalIndex": "interval-76",
          "value": 593.0,
          "endTime": 1541998800000
        },
        {
          "intervalIndex": "interval-77",
          "value": 592.0,
          "endTime": 1541995200000
        },
        {
          "intervalIndex": "interval-78",
          "value": 592.0,
          "endTime": 1541991600000
        },
        {
          "intervalIndex": "interval-79",
          "value": 590.0,
          "endTime": 1541988000000
        },
        {
          "intervalIndex": "interval-80",
          "value": 593.0,
          "endTime": 1541984400000
        },
        {
          "intervalIndex": "interval-81",
          "value": 592.0,
          "endTime": 1541980800000
        },
        {
          "intervalIndex": "interval-82",
          "value": 593.0,
          "endTime": 1541977200000
        },
        {
          "intervalIndex": "interval-83",
          "value": 592.0,
          "endTime": 1541973600000
        },
        {
          "intervalIndex": "interval-84",
          "value": 592.0,
          "endTime": 1541970000000
        },
        {
          "intervalIndex": "interval-85",
          "value": 593.0,
          "endTime": 1541966400000
        },
        {
          "intervalIndex": "interval-86",
          "value": 592.0,
          "endTime": 1541962800000
        },
        {
          "intervalIndex": "interval-87",
          "value": 593.0,
          "endTime": 1541959200000
        },
        {
          "intervalIndex": "interval-88",
          "value": 592.0,
          "endTime": 1541955600000
        },
        {
          "intervalIndex": "interval-89",
          "value": 592.0,
          "endTime": 1541952000000
        },
        {
          "intervalIndex": "interval-90",
          "value": 592.0,
          "endTime": 1541948400000
        },
        {
          "intervalIndex": "interval-91",
          "value": 593.0,
          "endTime": 1541944800000
        },
        {
          "intervalIndex": "interval-92",
          "value": 593.0,
          "endTime": 1541941200000
        },
        {
          "intervalIndex": "interval-93",
          "value": 593.0,
          "endTime": 1541937600000
        },
        {
          "intervalIndex": "interval-94",
          "value": 591.0,
          "endTime": 1541934000000
        },
        {
          "intervalIndex": "interval-95",
          "value": 592.0,
          "endTime": 1541930400000
        },
        {
          "intervalIndex": "interval-96",
          "value": 592.0,
          "endTime": 1541926800000
        },
        {
          "intervalIndex": "interval-97",
          "value": 593.0,
          "endTime": 1541923200000
        },
        {
          "intervalIndex": "interval-98",
          "value": 592.0,
          "endTime": 1541919600000
        },
        {
          "intervalIndex": "interval-99",
          "value": 593.0,
          "endTime": 1541916000000
        },
        {
          "intervalIndex": "interval-100",
          "value": 592.0,
          "endTime": 1541912400000
        },
        {
          "intervalIndex": "interval-101",
          "value": 592.0,
          "endTime": 1541908800000
        },
        {
          "intervalIndex": "interval-102",
          "value": 597.0,
          "endTime": 1541905200000
        },
        {
          "intervalIndex": "interval-103",
          "value": 592.0,
          "endTime": 1541901600000
        },
        {
          "intervalIndex": "interval-104",
          "value": 592.0,
          "endTime": 1541898000000
        },
        {
          "intervalIndex": "interval-105",
          "value": 592.0,
          "endTime": 1541894400000
        },
        {
          "intervalIndex": "interval-106",
          "value": 592.0,
          "endTime": 1541890800000
        },
        {
          "intervalIndex": "interval-107",
          "value": 592.0,
          "endTime": 1541887200000
        },
        {
          "intervalIndex": "interval-108",
          "value": 592.0,
          "endTime": 1541883600000
        },
        {
          "intervalIndex": "interval-109",
          "value": 591.0,
          "endTime": 1541880000000
        },
        {
          "intervalIndex": "interval-110",
          "value": 592.0,
          "endTime": 1541876400000
        },
        {
          "intervalIndex": "interval-111",
          "value": 591.0,
          "endTime": 1541872800000
        },
        {
          "intervalIndex": "interval-112",
          "value": 592.0,
          "endTime": 1541869200000
        },
        {
          "intervalIndex": "interval-113",
          "value": 593.0,
          "endTime": 1541865600000
        },
        {
          "intervalIndex": "interval-114",
          "value": 592.0,
          "endTime": 1541862000000
        },
        {
          "intervalIndex": "interval-115",
          "value": 592.0,
          "endTime": 1541858400000
        },
        {
          "intervalIndex": "interval-116",
          "value": 594.0,
          "endTime": 1541854800000
        },
        {
          "intervalIndex": "interval-117",
          "value": 591.0,
          "endTime": 1541851200000
        },
        {
          "intervalIndex": "interval-118",
          "value": 592.0,
          "endTime": 1541847600000
        },
        {
          "intervalIndex": "interval-119",
          "value": 592.0,
          "endTime": 1541844000000
        },
        {
          "intervalIndex": "interval-120",
          "value": 592.0,
          "endTime": 1541840400000
        },
        {
          "intervalIndex": "interval-121",
          "value": 592.0,
          "endTime": 1541836800000
        },
        {
          "intervalIndex": "interval-122",
          "value": 593.0,
          "endTime": 1541833200000
        },
        {
          "intervalIndex": "interval-123",
          "value": 592.0,
          "endTime": 1541829600000
        },
        {
          "intervalIndex": "interval-124",
          "value": 592.0,
          "endTime": 1541826000000
        },
        {
          "intervalIndex": "interval-125",
          "value": 593.0,
          "endTime": 1541822400000
        },
        {
          "intervalIndex": "interval-126",
          "value": 105.0,
          "endTime": 1541818800000
        },
        {
          "intervalIndex": "interval-127",
          "value": 593.0,
          "endTime": 1541815200000
        },
        {
          "intervalIndex": "interval-128",
          "value": 593.0,
          "endTime": 1541811600000
        },
        {
          "intervalIndex": "interval-129",
          "value": 593.0,
          "endTime": 1541808000000
        },
        {
          "intervalIndex": "interval-130",
          "value": 593.0,
          "endTime": 1541804400000
        },
        {
          "intervalIndex": "interval-131",
          "value": 594.0,
          "endTime": 1541800800000
        },
        {
          "intervalIndex": "interval-132",
          "value": 594.0,
          "endTime": 1541797200000
        },
        {
          "intervalIndex": "interval-133",
          "value": 593.0,
          "endTime": 1541793600000
        },
        {
          "intervalIndex": "interval-134",
          "value": 593.0,
          "endTime": 1541790000000
        },
        {
          "intervalIndex": "interval-135",
          "value": 592.0,
          "endTime": 1541786400000
        },
        {
          "intervalIndex": "interval-136",
          "value": 593.0,
          "endTime": 1541782800000
        },
        {
          "intervalIndex": "interval-137",
          "value": 592.0,
          "endTime": 1541779200000
        },
        {
          "intervalIndex": "interval-138",
          "value": 592.0,
          "endTime": 1541775600000
        },
        {
          "intervalIndex": "interval-139",
          "value": 593.0,
          "endTime": 1541772000000
        },
        {
          "intervalIndex": "interval-140",
          "value": 593.0,
          "endTime": 1541768400000
        },
        {
          "intervalIndex": "interval-141",
          "value": 592.0,
          "endTime": 1541764800000
        },
        {
          "intervalIndex": "interval-142",
          "value": 593.0,
          "endTime": 1541761200000
        },
        {
          "intervalIndex": "interval-143",
          "value": 593.0,
          "endTime": 1541757600000
        },
        {
          "intervalIndex": "interval-144",
          "value": 591.0,
          "endTime": 1541754000000
        },
        {
          "intervalIndex": "interval-145",
          "value": 592.0,
          "endTime": 1541750400000
        },
        {
          "intervalIndex": "interval-146",
          "value": 592.0,
          "endTime": 1541746800000
        },
        {
          "intervalIndex": "interval-147",
          "value": 592.0,
          "endTime": 1541743200000
        },
        {
          "intervalIndex": "interval-148",
          "value": 593.0,
          "endTime": 1541739600000
        },
        {
          "intervalIndex": "interval-149",
          "value": 592.0,
          "endTime": 1541736000000
        },
        {
          "intervalIndex": "interval-150",
          "value": 592.0,
          "endTime": 1541732400000
        },
        {
          "intervalIndex": "interval-151",
          "value": 593.0,
          "endTime": 1541728800000
        },
        {
          "intervalIndex": "interval-152",
          "value": 591.0,
          "endTime": 1541725200000
        },
        {
          "intervalIndex": "interval-153",
          "value": 592.0,
          "endTime": 1541721600000
        },
        {
          "intervalIndex": "interval-154",
          "value": 592.0,
          "endTime": 1541718000000
        },
        {
          "intervalIndex": "interval-155",
          "value": 593.0,
          "endTime": 1541714400000
        },
        {
          "intervalIndex": "interval-156",
          "value": 594.0,
          "endTime": 1541710800000
        },
        {
          "intervalIndex": "interval-157",
          "value": 593.0,
          "endTime": 1541707200000
        },
        {
          "intervalIndex": "interval-158",
          "value": 593.0,
          "endTime": 1541703600000
        },
        {
          "intervalIndex": "interval-159",
          "value": 593.0,
          "endTime": 1541700000000
        },
        {
          "intervalIndex": "interval-160",
          "value": 592.0,
          "endTime": 1541696400000
        },
        {
          "intervalIndex": "interval-161",
          "value": 592.0,
          "endTime": 1541692800000
        },
        {
          "intervalIndex": "interval-162",
          "value": 106.0,
          "endTime": 1541689200000
        },
        {
          "intervalIndex": "interval-163",
          "value": 593.0,
          "endTime": 1541685600000
        },
        {
          "intervalIndex": "interval-164",
          "value": 592.0,
          "endTime": 1541682000000
        },
        {
          "intervalIndex": "interval-165",
          "value": 593.0,
          "endTime": 1541678400000
        },
        {
          "intervalIndex": "interval-166",
          "value": 593.0,
          "endTime": 1541674800000
        },
        {
          "intervalIndex": "interval-167",
          "value": 593.0,
          "endTime": 1541671200000
        }
      ],
      "averagePerformanceList": [
        {
          "intervalIndex": "interval-0",
          "value": 600.4,
          "endTime": 1542272400000
        },
        {
          "intervalIndex": "interval-1",
          "value": 595.8333333333334,
          "endTime": 1542268800000
        },
        {
          "intervalIndex": "interval-2",
          "value": 763.5,
          "endTime": 1542265200000
        },
        {
          "intervalIndex": "interval-3",
          "value": 599.8571428571429,
          "endTime": 1542261600000
        },
        {
          "intervalIndex": "interval-4",
          "value": 518.3333333333334,
          "endTime": 1542258000000
        },
        {
          "intervalIndex": "interval-5",
          "value": 601.5,
          "endTime": 1542254400000
        },
        {
          "intervalIndex": "interval-6",
          "value": 597.0,
          "endTime": 1542250800000
        },
        {
          "intervalIndex": "interval-7",
          "value": 593.1666666666666,
          "endTime": 1542247200000
        },
        {
          "intervalIndex": "interval-8",
          "value": 594.0,
          "endTime": 1542243600000
        },
        {
          "intervalIndex": "interval-9",
          "value": 593.3333333333334,
          "endTime": 1542240000000
        },
        {
          "intervalIndex": "interval-10",
          "value": 597.2,
          "endTime": 1542236400000
        },
        {
          "intervalIndex": "interval-11",
          "value": 593.7142857142857,
          "endTime": 1542232800000
        },
        {
          "intervalIndex": "interval-12",
          "value": 607.4285714285714,
          "endTime": 1542229200000
        },
        {
          "intervalIndex": "interval-13",
          "value": 601.3333333333334,
          "endTime": 1542225600000
        },
        {
          "intervalIndex": "interval-14",
          "value": 599.8333333333334,
          "endTime": 1542222000000
        },
        {
          "intervalIndex": "interval-15",
          "value": 599.3333333333334,
          "endTime": 1542218400000
        },
        {
          "intervalIndex": "interval-16",
          "value": 595.1428571428571,
          "endTime": 1542214800000
        },
        {
          "intervalIndex": "interval-17",
          "value": 594.1666666666666,
          "endTime": 1542211200000
        },
        {
          "intervalIndex": "interval-18",
          "value": 598.6666666666666,
          "endTime": 1542207600000
        },
        {
          "intervalIndex": "interval-19",
          "value": 611.0,
          "endTime": 1542204000000
        },
        {
          "intervalIndex": "interval-20",
          "value": 595.8333333333334,
          "endTime": 1542200400000
        },
        {
          "intervalIndex": "interval-21",
          "value": 604.1666666666666,
          "endTime": 1542196800000
        },
        {
          "intervalIndex": "interval-22",
          "value": 595.6666666666666,
          "endTime": 1542193200000
        },
        {
          "intervalIndex": "interval-23",
          "value": 607.1666666666666,
          "endTime": 1542189600000
        },
        {
          "intervalIndex": "interval-24",
          "value": 630.0,
          "endTime": 1542186000000
        },
        {
          "intervalIndex": "interval-25",
          "value": 593.1666666666666,
          "endTime": 1542182400000
        },
        {
          "intervalIndex": "interval-26",
          "value": 612.8571428571429,
          "endTime": 1542178800000
        },
        {
          "intervalIndex": "interval-27",
          "value": 598.0,
          "endTime": 1542175200000
        },
        {
          "intervalIndex": "interval-28",
          "value": 599.6666666666666,
          "endTime": 1542171600000
        },
        {
          "intervalIndex": "interval-29",
          "value": 598.0,
          "endTime": 1542168000000
        },
        {
          "intervalIndex": "interval-30",
          "value": 597.0,
          "endTime": 1542164400000
        },
        {
          "intervalIndex": "interval-31",
          "value": 599.0,
          "endTime": 1542160800000
        },
        {
          "intervalIndex": "interval-32",
          "value": 593.8333333333334,
          "endTime": 1542157200000
        },
        {
          "intervalIndex": "interval-33",
          "value": 605.6666666666666,
          "endTime": 1542153600000
        },
        {
          "intervalIndex": "interval-34",
          "value": 599.6666666666666,
          "endTime": 1542150000000
        },
        {
          "intervalIndex": "interval-35",
          "value": 598.6666666666666,
          "endTime": 1542146400000
        },
        {
          "intervalIndex": "interval-36",
          "value": 595.8333333333334,
          "endTime": 1542142800000
        },
        {
          "intervalIndex": "interval-37",
          "value": 596.8333333333334,
          "endTime": 1542139200000
        },
        {
          "intervalIndex": "interval-38",
          "value": 772.5,
          "endTime": 1542135600000
        },
        {
          "intervalIndex": "interval-39",
          "value": 600.4166666666666,
          "endTime": 1542132000000
        },
        {
          "intervalIndex": "interval-40",
          "value": 598.5,
          "endTime": 1542128400000
        },
        {
          "intervalIndex": "interval-41",
          "value": 632.1666666666666,
          "endTime": 1542124800000
        },
        {
          "intervalIndex": "interval-42",
          "value": 610.8333333333334,
          "endTime": 1542121200000
        },
        {
          "intervalIndex": "interval-43",
          "value": 603.5,
          "endTime": 1542117600000
        },
        {
          "intervalIndex": "interval-44",
          "value": 608.5,
          "endTime": 1542114000000
        },
        {
          "intervalIndex": "interval-45",
          "value": 597.1666666666666,
          "endTime": 1542110400000
        },
        {
          "intervalIndex": "interval-46",
          "value": 598.5,
          "endTime": 1542106800000
        },
        {
          "intervalIndex": "interval-47",
          "value": 609.6666666666666,
          "endTime": 1542103200000
        },
        {
          "intervalIndex": "interval-48",
          "value": 603.1666666666666,
          "endTime": 1542099600000
        },
        {
          "intervalIndex": "interval-49",
          "value": 594.3333333333334,
          "endTime": 1542096000000
        },
        {
          "intervalIndex": "interval-50",
          "value": 611.3333333333334,
          "endTime": 1542092400000
        },
        {
          "intervalIndex": "interval-51",
          "value": 593.5,
          "endTime": 1542088800000
        },
        {
          "intervalIndex": "interval-52",
          "value": 599.0,
          "endTime": 1542085200000
        },
        {
          "intervalIndex": "interval-53",
          "value": 592.8333333333334,
          "endTime": 1542081600000
        },
        {
          "intervalIndex": "interval-54",
          "value": 597.8333333333334,
          "endTime": 1542078000000
        },
        {
          "intervalIndex": "interval-55",
          "value": 596.5,
          "endTime": 1542074400000
        },
        {
          "intervalIndex": "interval-56",
          "value": 594.8333333333334,
          "endTime": 1542070800000
        },
        {
          "intervalIndex": "interval-57",
          "value": 594.8333333333334,
          "endTime": 1542067200000
        },
        {
          "intervalIndex": "interval-58",
          "value": 595.8333333333334,
          "endTime": 1542063600000
        },
        {
          "intervalIndex": "interval-59",
          "value": 593.3333333333334,
          "endTime": 1542060000000
        },
        {
          "intervalIndex": "interval-60",
          "value": 601.0,
          "endTime": 1542056400000
        },
        {
          "intervalIndex": "interval-61",
          "value": 514.0,
          "endTime": 1542052800000
        },
        {
          "intervalIndex": "interval-62",
          "value": 597.3333333333334,
          "endTime": 1542049200000
        },
        {
          "intervalIndex": "interval-63",
          "value": 597.0,
          "endTime": 1542045600000
        },
        {
          "intervalIndex": "interval-64",
          "value": 595.5,
          "endTime": 1542042000000
        },
        {
          "intervalIndex": "interval-65",
          "value": 593.8333333333334,
          "endTime": 1542038400000
        },
        {
          "intervalIndex": "interval-66",
          "value": 596.0,
          "endTime": 1542034800000
        },
        {
          "intervalIndex": "interval-67",
          "value": 649.8333333333334,
          "endTime": 1542031200000
        },
        {
          "intervalIndex": "interval-68",
          "value": 598.0,
          "endTime": 1542027600000
        },
        {
          "intervalIndex": "interval-69",
          "value": 594.1666666666666,
          "endTime": 1542024000000
        },
        {
          "intervalIndex": "interval-70",
          "value": 596.1666666666666,
          "endTime": 1542020400000
        },
        {
          "intervalIndex": "interval-71",
          "value": 593.0,
          "endTime": 1542016800000
        },
        {
          "intervalIndex": "interval-72",
          "value": 594.3333333333334,
          "endTime": 1542013200000
        },
        {
          "intervalIndex": "interval-73",
          "value": 594.3333333333334,
          "endTime": 1542009600000
        },
        {
          "intervalIndex": "interval-74",
          "value": 598.8333333333334,
          "endTime": 1542006000000
        },
        {
          "intervalIndex": "interval-75",
          "value": 594.5,
          "endTime": 1542002400000
        },
        {
          "intervalIndex": "interval-76",
          "value": 602.3333333333334,
          "endTime": 1541998800000
        },
        {
          "intervalIndex": "interval-77",
          "value": 594.0,
          "endTime": 1541995200000
        },
        {
          "intervalIndex": "interval-78",
          "value": 593.1666666666666,
          "endTime": 1541991600000
        },
        {
          "intervalIndex": "interval-79",
          "value": 594.0,
          "endTime": 1541988000000
        },
        {
          "intervalIndex": "interval-80",
          "value": 605.0,
          "endTime": 1541984400000
        },
        {
          "intervalIndex": "interval-81",
          "value": 598.8333333333334,
          "endTime": 1541980800000
        },
        {
          "intervalIndex": "interval-82",
          "value": 595.5,
          "endTime": 1541977200000
        },
        {
          "intervalIndex": "interval-83",
          "value": 602.3333333333334,
          "endTime": 1541973600000
        },
        {
          "intervalIndex": "interval-84",
          "value": 598.1666666666666,
          "endTime": 1541970000000
        },
        {
          "intervalIndex": "interval-85",
          "value": 602.4,
          "endTime": 1541966400000
        },
        {
          "intervalIndex": "interval-86",
          "value": 602.0,
          "endTime": 1541962800000
        },
        {
          "intervalIndex": "interval-87",
          "value": 600.5,
          "endTime": 1541959200000
        },
        {
          "intervalIndex": "interval-88",
          "value": 603.8333333333334,
          "endTime": 1541955600000
        },
        {
          "intervalIndex": "interval-89",
          "value": 593.3333333333334,
          "endTime": 1541952000000
        },
        {
          "intervalIndex": "interval-90",
          "value": 593.3333333333334,
          "endTime": 1541948400000
        },
        {
          "intervalIndex": "interval-91",
          "value": 598.6666666666666,
          "endTime": 1541944800000
        },
        {
          "intervalIndex": "interval-92",
          "value": 598.8333333333334,
          "endTime": 1541941200000
        },
        {
          "intervalIndex": "interval-93",
          "value": 598.5,
          "endTime": 1541937600000
        },
        {
          "intervalIndex": "interval-94",
          "value": 594.1666666666666,
          "endTime": 1541934000000
        },
        {
          "intervalIndex": "interval-95",
          "value": 1373.6666666666667,
          "endTime": 1541930400000
        },
        {
          "intervalIndex": "interval-96",
          "value": 595.6666666666666,
          "endTime": 1541926800000
        },
        {
          "intervalIndex": "interval-97",
          "value": 597.1666666666666,
          "endTime": 1541923200000
        },
        {
          "intervalIndex": "interval-98",
          "value": 593.6666666666666,
          "endTime": 1541919600000
        },
        {
          "intervalIndex": "interval-99",
          "value": 607.5,
          "endTime": 1541916000000
        },
        {
          "intervalIndex": "interval-100",
          "value": 593.5,
          "endTime": 1541912400000
        },
        {
          "intervalIndex": "interval-101",
          "value": 597.6666666666666,
          "endTime": 1541908800000
        },
        {
          "intervalIndex": "interval-102",
          "value": 775.3333333333334,
          "endTime": 1541905200000
        },
        {
          "intervalIndex": "interval-103",
          "value": 593.1666666666666,
          "endTime": 1541901600000
        },
        {
          "intervalIndex": "interval-104",
          "value": 595.3333333333334,
          "endTime": 1541898000000
        },
        {
          "intervalIndex": "interval-105",
          "value": 595.7142857142857,
          "endTime": 1541894400000
        },
        {
          "intervalIndex": "interval-106",
          "value": 598.0,
          "endTime": 1541890800000
        },
        {
          "intervalIndex": "interval-107",
          "value": 599.0,
          "endTime": 1541887200000
        },
        {
          "intervalIndex": "interval-108",
          "value": 593.4285714285714,
          "endTime": 1541883600000
        },
        {
          "intervalIndex": "interval-109",
          "value": 598.0,
          "endTime": 1541880000000
        },
        {
          "intervalIndex": "interval-110",
          "value": 627.5,
          "endTime": 1541876400000
        },
        {
          "intervalIndex": "interval-111",
          "value": 609.1666666666666,
          "endTime": 1541872800000
        },
        {
          "intervalIndex": "interval-112",
          "value": 603.1666666666666,
          "endTime": 1541869200000
        },
        {
          "intervalIndex": "interval-113",
          "value": 636.5,
          "endTime": 1541865600000
        },
        {
          "intervalIndex": "interval-114",
          "value": 593.4285714285714,
          "endTime": 1541862000000
        },
        {
          "intervalIndex": "interval-115",
          "value": 595.3333333333334,
          "endTime": 1541858400000
        },
        {
          "intervalIndex": "interval-116",
          "value": 607.6666666666666,
          "endTime": 1541854800000
        },
        {
          "intervalIndex": "interval-117",
          "value": 592.1666666666666,
          "endTime": 1541851200000
        },
        {
          "intervalIndex": "interval-118",
          "value": 3002.5,
          "endTime": 1541847600000
        },
        {
          "intervalIndex": "interval-119",
          "value": 593.1666666666666,
          "endTime": 1541844000000
        },
        {
          "intervalIndex": "interval-120",
          "value": 598.5,
          "endTime": 1541840400000
        },
        {
          "intervalIndex": "interval-121",
          "value": 593.8571428571429,
          "endTime": 1541836800000
        },
        {
          "intervalIndex": "interval-122",
          "value": 595.1666666666666,
          "endTime": 1541833200000
        },
        {
          "intervalIndex": "interval-123",
          "value": 598.3333333333334,
          "endTime": 1541829600000
        },
        {
          "intervalIndex": "interval-124",
          "value": 601.5714285714286,
          "endTime": 1541826000000
        },
        {
          "intervalIndex": "interval-125",
          "value": 767.1666666666666,
          "endTime": 1541822400000
        },
        {
          "intervalIndex": "interval-126",
          "value": 535.1666666666666,
          "endTime": 1541818800000
        },
        {
          "intervalIndex": "interval-127",
          "value": 595.8333333333334,
          "endTime": 1541815200000
        },
        {
          "intervalIndex": "interval-128",
          "value": 2029.5714285714287,
          "endTime": 1541811600000
        },
        {
          "intervalIndex": "interval-129",
          "value": 621.1666666666666,
          "endTime": 1541808000000
        },
        {
          "intervalIndex": "interval-130",
          "value": 594.1666666666666,
          "endTime": 1541804400000
        },
        {
          "intervalIndex": "interval-131",
          "value": 600.0,
          "endTime": 1541800800000
        },
        {
          "intervalIndex": "interval-132",
          "value": 596.3333333333334,
          "endTime": 1541797200000
        },
        {
          "intervalIndex": "interval-133",
          "value": 598.2,
          "endTime": 1541793600000
        },
        {
          "intervalIndex": "interval-134",
          "value": 595.375,
          "endTime": 1541790000000
        },
        {
          "intervalIndex": "interval-135",
          "value": 593.5,
          "endTime": 1541786400000
        },
        {
          "intervalIndex": "interval-136",
          "value": 597.1666666666666,
          "endTime": 1541782800000
        },
        {
          "intervalIndex": "interval-137",
          "value": 597.8333333333334,
          "endTime": 1541779200000
        },
        {
          "intervalIndex": "interval-138",
          "value": 605.0,
          "endTime": 1541775600000
        },
        {
          "intervalIndex": "interval-139",
          "value": 597.6666666666666,
          "endTime": 1541772000000
        },
        {
          "intervalIndex": "interval-140",
          "value": 604.6666666666666,
          "endTime": 1541768400000
        },
        {
          "intervalIndex": "interval-141",
          "value": 609.3333333333334,
          "endTime": 1541764800000
        },
        {
          "intervalIndex": "interval-142",
          "value": 594.8333333333334,
          "endTime": 1541761200000
        },
        {
          "intervalIndex": "interval-143",
          "value": 627.2857142857143,
          "endTime": 1541757600000
        },
        {
          "intervalIndex": "interval-144",
          "value": 599.8333333333334,
          "endTime": 1541754000000
        },
        {
          "intervalIndex": "interval-145",
          "value": 760.6666666666666,
          "endTime": 1541750400000
        },
        {
          "intervalIndex": "interval-146",
          "value": 593.3333333333334,
          "endTime": 1541746800000
        },
        {
          "intervalIndex": "interval-147",
          "value": 597.1666666666666,
          "endTime": 1541743200000
        },
        {
          "intervalIndex": "interval-148",
          "value": 600.4,
          "endTime": 1541739600000
        },
        {
          "intervalIndex": "interval-149",
          "value": 593.8333333333334,
          "endTime": 1541736000000
        },
        {
          "intervalIndex": "interval-150",
          "value": 598.3333333333334,
          "endTime": 1541732400000
        },
        {
          "intervalIndex": "interval-151",
          "value": 598.0,
          "endTime": 1541728800000
        },
        {
          "intervalIndex": "interval-152",
          "value": 597.5714285714286,
          "endTime": 1541725200000
        },
        {
          "intervalIndex": "interval-153",
          "value": 608.3333333333334,
          "endTime": 1541721600000
        },
        {
          "intervalIndex": "interval-154",
          "value": 594.1666666666666,
          "endTime": 1541718000000
        },
        {
          "intervalIndex": "interval-155",
          "value": 596.8333333333334,
          "endTime": 1541714400000
        },
        {
          "intervalIndex": "interval-156",
          "value": 612.1666666666666,
          "endTime": 1541710800000
        },
        {
          "intervalIndex": "interval-157",
          "value": 621.8333333333334,
          "endTime": 1541707200000
        },
        {
          "intervalIndex": "interval-158",
          "value": 639.8333333333334,
          "endTime": 1541703600000
        },
        {
          "intervalIndex": "interval-159",
          "value": 604.6,
          "endTime": 1541700000000
        },
        {
          "intervalIndex": "interval-160",
          "value": 597.6666666666666,
          "endTime": 1541696400000
        },
        {
          "intervalIndex": "interval-161",
          "value": 605.5,
          "endTime": 1541692800000
        },
        {
          "intervalIndex": "interval-162",
          "value": 526.4285714285714,
          "endTime": 1541689200000
        },
        {
          "intervalIndex": "interval-163",
          "value": 604.3333333333334,
          "endTime": 1541685600000
        },
        {
          "intervalIndex": "interval-164",
          "value": 597.8333333333334,
          "endTime": 1541682000000
        },
        {
          "intervalIndex": "interval-165",
          "value": 660.6666666666666,
          "endTime": 1541678400000
        },
        {
          "intervalIndex": "interval-166",
          "value": 597.8333333333334,
          "endTime": 1541674800000
        },
        {
          "intervalIndex": "interval-167",
          "value": 594.8333333333334,
          "endTime": 1541671200000
        }
      ],
      "maxPerformanceList": [
        {
          "intervalIndex": "interval-0",
          "value": 617.0,
          "endTime": 1542272400000
        },
        {
          "intervalIndex": "interval-1",
          "value": 605.0,
          "endTime": 1542268800000
        },
        {
          "intervalIndex": "interval-2",
          "value": 1582.0,
          "endTime": 1542265200000
        },
        {
          "intervalIndex": "interval-3",
          "value": 626.0,
          "endTime": 1542261600000
        },
        {
          "intervalIndex": "interval-4",
          "value": 598.0,
          "endTime": 1542258000000
        },
        {
          "intervalIndex": "interval-5",
          "value": 624.0,
          "endTime": 1542254400000
        },
        {
          "intervalIndex": "interval-6",
          "value": 611.0,
          "endTime": 1542250800000
        },
        {
          "intervalIndex": "interval-7",
          "value": 594.0,
          "endTime": 1542247200000
        },
        {
          "intervalIndex": "interval-8",
          "value": 598.0,
          "endTime": 1542243600000
        },
        {
          "intervalIndex": "interval-9",
          "value": 595.0,
          "endTime": 1542240000000
        },
        {
          "intervalIndex": "interval-10",
          "value": 611.0,
          "endTime": 1542236400000
        },
        {
          "intervalIndex": "interval-11",
          "value": 595.0,
          "endTime": 1542232800000
        },
        {
          "intervalIndex": "interval-12",
          "value": 630.0,
          "endTime": 1542229200000
        },
        {
          "intervalIndex": "interval-13",
          "value": 634.0,
          "endTime": 1542225600000
        },
        {
          "intervalIndex": "interval-14",
          "value": 623.0,
          "endTime": 1542222000000
        },
        {
          "intervalIndex": "interval-15",
          "value": 627.0,
          "endTime": 1542218400000
        },
        {
          "intervalIndex": "interval-16",
          "value": 603.0,
          "endTime": 1542214800000
        },
        {
          "intervalIndex": "interval-17",
          "value": 599.0,
          "endTime": 1542211200000
        },
        {
          "intervalIndex": "interval-18",
          "value": 624.0,
          "endTime": 1542207600000
        },
        {
          "intervalIndex": "interval-19",
          "value": 634.0,
          "endTime": 1542204000000
        },
        {
          "intervalIndex": "interval-20",
          "value": 601.0,
          "endTime": 1542200400000
        },
        {
          "intervalIndex": "interval-21",
          "value": 658.0,
          "endTime": 1542196800000
        },
        {
          "intervalIndex": "interval-22",
          "value": 608.0,
          "endTime": 1542193200000
        },
        {
          "intervalIndex": "interval-23",
          "value": 669.0,
          "endTime": 1542189600000
        },
        {
          "intervalIndex": "interval-24",
          "value": 781.0,
          "endTime": 1542186000000
        },
        {
          "intervalIndex": "interval-25",
          "value": 594.0,
          "endTime": 1542182400000
        },
        {
          "intervalIndex": "interval-26",
          "value": 727.0,
          "endTime": 1542178800000
        },
        {
          "intervalIndex": "interval-27",
          "value": 611.0,
          "endTime": 1542175200000
        },
        {
          "intervalIndex": "interval-28",
          "value": 613.0,
          "endTime": 1542171600000
        },
        {
          "intervalIndex": "interval-29",
          "value": 622.0,
          "endTime": 1542168000000
        },
        {
          "intervalIndex": "interval-30",
          "value": 617.0,
          "endTime": 1542164400000
        },
        {
          "intervalIndex": "interval-31",
          "value": 619.0,
          "endTime": 1542160800000
        },
        {
          "intervalIndex": "interval-32",
          "value": 600.0,
          "endTime": 1542157200000
        },
        {
          "intervalIndex": "interval-33",
          "value": 640.0,
          "endTime": 1542153600000
        },
        {
          "intervalIndex": "interval-34",
          "value": 617.0,
          "endTime": 1542150000000
        },
        {
          "intervalIndex": "interval-35",
          "value": 625.0,
          "endTime": 1542146400000
        },
        {
          "intervalIndex": "interval-36",
          "value": 601.0,
          "endTime": 1542142800000
        },
        {
          "intervalIndex": "interval-37",
          "value": 609.0,
          "endTime": 1542139200000
        },
        {
          "intervalIndex": "interval-38",
          "value": 1643.0,
          "endTime": 1542135600000
        },
        {
          "intervalIndex": "interval-39",
          "value": 632.0,
          "endTime": 1542132000000
        },
        {
          "intervalIndex": "interval-40",
          "value": 615.0,
          "endTime": 1542128400000
        },
        {
          "intervalIndex": "interval-41",
          "value": 808.0,
          "endTime": 1542124800000
        },
        {
          "intervalIndex": "interval-42",
          "value": 655.0,
          "endTime": 1542121200000
        },
        {
          "intervalIndex": "interval-43",
          "value": 619.0,
          "endTime": 1542117600000
        },
        {
          "intervalIndex": "interval-44",
          "value": 625.0,
          "endTime": 1542114000000
        },
        {
          "intervalIndex": "interval-45",
          "value": 615.0,
          "endTime": 1542110400000
        },
        {
          "intervalIndex": "interval-46",
          "value": 613.0,
          "endTime": 1542106800000
        },
        {
          "intervalIndex": "interval-47",
          "value": 655.0,
          "endTime": 1542103200000
        },
        {
          "intervalIndex": "interval-48",
          "value": 626.0,
          "endTime": 1542099600000
        },
        {
          "intervalIndex": "interval-49",
          "value": 598.0,
          "endTime": 1542096000000
        },
        {
          "intervalIndex": "interval-50",
          "value": 655.0,
          "endTime": 1542092400000
        },
        {
          "intervalIndex": "interval-51",
          "value": 597.0,
          "endTime": 1542088800000
        },
        {
          "intervalIndex": "interval-52",
          "value": 616.0,
          "endTime": 1542085200000
        },
        {
          "intervalIndex": "interval-53",
          "value": 594.0,
          "endTime": 1542081600000
        },
        {
          "intervalIndex": "interval-54",
          "value": 619.0,
          "endTime": 1542078000000
        },
        {
          "intervalIndex": "interval-55",
          "value": 613.0,
          "endTime": 1542074400000
        },
        {
          "intervalIndex": "interval-56",
          "value": 603.0,
          "endTime": 1542070800000
        },
        {
          "intervalIndex": "interval-57",
          "value": 600.0,
          "endTime": 1542067200000
        },
        {
          "intervalIndex": "interval-58",
          "value": 604.0,
          "endTime": 1542063600000
        },
        {
          "intervalIndex": "interval-59",
          "value": 595.0,
          "endTime": 1542060000000
        },
        {
          "intervalIndex": "interval-60",
          "value": 637.0,
          "endTime": 1542056400000
        },
        {
          "intervalIndex": "interval-61",
          "value": 618.0,
          "endTime": 1542052800000
        },
        {
          "intervalIndex": "interval-62",
          "value": 614.0,
          "endTime": 1542049200000
        },
        {
          "intervalIndex": "interval-63",
          "value": 608.0,
          "endTime": 1542045600000
        },
        {
          "intervalIndex": "interval-64",
          "value": 600.0,
          "endTime": 1542042000000
        },
        {
          "intervalIndex": "interval-65",
          "value": 595.0,
          "endTime": 1542038400000
        },
        {
          "intervalIndex": "interval-66",
          "value": 605.0,
          "endTime": 1542034800000
        },
        {
          "intervalIndex": "interval-67",
          "value": 877.0,
          "endTime": 1542031200000
        },
        {
          "intervalIndex": "interval-68",
          "value": 619.0,
          "endTime": 1542027600000
        },
        {
          "intervalIndex": "interval-69",
          "value": 601.0,
          "endTime": 1542024000000
        },
        {
          "intervalIndex": "interval-70",
          "value": 607.0,
          "endTime": 1542020400000
        },
        {
          "intervalIndex": "interval-71",
          "value": 596.0,
          "endTime": 1542016800000
        },
        {
          "intervalIndex": "interval-72",
          "value": 598.0,
          "endTime": 1542013200000
        },
        {
          "intervalIndex": "interval-73",
          "value": 599.0,
          "endTime": 1542009600000
        },
        {
          "intervalIndex": "interval-74",
          "value": 628.0,
          "endTime": 1542006000000
        },
        {
          "intervalIndex": "interval-75",
          "value": 599.0,
          "endTime": 1542002400000
        },
        {
          "intervalIndex": "interval-76",
          "value": 620.0,
          "endTime": 1541998800000
        },
        {
          "intervalIndex": "interval-77",
          "value": 599.0,
          "endTime": 1541995200000
        },
        {
          "intervalIndex": "interval-78",
          "value": 595.0,
          "endTime": 1541991600000
        },
        {
          "intervalIndex": "interval-79",
          "value": 605.0,
          "endTime": 1541988000000
        },
        {
          "intervalIndex": "interval-80",
          "value": 627.0,
          "endTime": 1541984400000
        },
        {
          "intervalIndex": "interval-81",
          "value": 613.0,
          "endTime": 1541980800000
        },
        {
          "intervalIndex": "interval-82",
          "value": 605.0,
          "endTime": 1541977200000
        },
        {
          "intervalIndex": "interval-83",
          "value": 621.0,
          "endTime": 1541973600000
        },
        {
          "intervalIndex": "interval-84",
          "value": 617.0,
          "endTime": 1541970000000
        },
        {
          "intervalIndex": "interval-85",
          "value": 619.0,
          "endTime": 1541966400000
        },
        {
          "intervalIndex": "interval-86",
          "value": 646.0,
          "endTime": 1541962800000
        },
        {
          "intervalIndex": "interval-87",
          "value": 618.0,
          "endTime": 1541959200000
        },
        {
          "intervalIndex": "interval-88",
          "value": 628.0,
          "endTime": 1541955600000
        },
        {
          "intervalIndex": "interval-89",
          "value": 595.0,
          "endTime": 1541952000000
        },
        {
          "intervalIndex": "interval-90",
          "value": 594.0,
          "endTime": 1541948400000
        },
        {
          "intervalIndex": "interval-91",
          "value": 618.0,
          "endTime": 1541944800000
        },
        {
          "intervalIndex": "interval-92",
          "value": 622.0,
          "endTime": 1541941200000
        },
        {
          "intervalIndex": "interval-93",
          "value": 611.0,
          "endTime": 1541937600000
        },
        {
          "intervalIndex": "interval-94",
          "value": 599.0,
          "endTime": 1541934000000
        },
        {
          "intervalIndex": "interval-95",
          "value": 5219.0,
          "endTime": 1541930400000
        },
        {
          "intervalIndex": "interval-96",
          "value": 605.0,
          "endTime": 1541926800000
        },
        {
          "intervalIndex": "interval-97",
          "value": 615.0,
          "endTime": 1541923200000
        },
        {
          "intervalIndex": "interval-98",
          "value": 596.0,
          "endTime": 1541919600000
        },
        {
          "intervalIndex": "interval-99",
          "value": 642.0,
          "endTime": 1541916000000
        },
        {
          "intervalIndex": "interval-100",
          "value": 595.0,
          "endTime": 1541912400000
        },
        {
          "intervalIndex": "interval-101",
          "value": 619.0,
          "endTime": 1541908800000
        },
        {
          "intervalIndex": "interval-102",
          "value": 1590.0,
          "endTime": 1541905200000
        },
        {
          "intervalIndex": "interval-103",
          "value": 594.0,
          "endTime": 1541901600000
        },
        {
          "intervalIndex": "interval-104",
          "value": 606.0,
          "endTime": 1541898000000
        },
        {
          "intervalIndex": "interval-105",
          "value": 610.0,
          "endTime": 1541894400000
        },
        {
          "intervalIndex": "interval-106",
          "value": 621.0,
          "endTime": 1541890800000
        },
        {
          "intervalIndex": "interval-107",
          "value": 618.0,
          "endTime": 1541887200000
        },
        {
          "intervalIndex": "interval-108",
          "value": 597.0,
          "endTime": 1541883600000
        },
        {
          "intervalIndex": "interval-109",
          "value": 618.0,
          "endTime": 1541880000000
        },
        {
          "intervalIndex": "interval-110",
          "value": 782.0,
          "endTime": 1541876400000
        },
        {
          "intervalIndex": "interval-111",
          "value": 683.0,
          "endTime": 1541872800000
        },
        {
          "intervalIndex": "interval-112",
          "value": 639.0,
          "endTime": 1541869200000
        },
        {
          "intervalIndex": "interval-113",
          "value": 847.0,
          "endTime": 1541865600000
        },
        {
          "intervalIndex": "interval-114",
          "value": 596.0,
          "endTime": 1541862000000
        },
        {
          "intervalIndex": "interval-115",
          "value": 600.0,
          "endTime": 1541858400000
        },
        {
          "intervalIndex": "interval-116",
          "value": 662.0,
          "endTime": 1541854800000
        },
        {
          "intervalIndex": "interval-117",
          "value": 594.0,
          "endTime": 1541851200000
        },
        {
          "intervalIndex": "interval-118",
          "value": 15029.0,
          "endTime": 1541847600000
        },
        {
          "intervalIndex": "interval-119",
          "value": 595.0,
          "endTime": 1541844000000
        },
        {
          "intervalIndex": "interval-120",
          "value": 619.0,
          "endTime": 1541840400000
        },
        {
          "intervalIndex": "interval-121",
          "value": 595.0,
          "endTime": 1541836800000
        },
        {
          "intervalIndex": "interval-122",
          "value": 599.0,
          "endTime": 1541833200000
        },
        {
          "intervalIndex": "interval-123",
          "value": 620.0,
          "endTime": 1541829600000
        },
        {
          "intervalIndex": "interval-124",
          "value": 623.0,
          "endTime": 1541826000000
        },
        {
          "intervalIndex": "interval-125",
          "value": 1589.0,
          "endTime": 1541822400000
        },
        {
          "intervalIndex": "interval-126",
          "value": 706.0,
          "endTime": 1541818800000
        },
        {
          "intervalIndex": "interval-127",
          "value": 599.0,
          "endTime": 1541815200000
        },
        {
          "intervalIndex": "interval-128",
          "value": 10601.0,
          "endTime": 1541811600000
        },
        {
          "intervalIndex": "interval-129",
          "value": 728.0,
          "endTime": 1541808000000
        },
        {
          "intervalIndex": "interval-130",
          "value": 595.0,
          "endTime": 1541804400000
        },
        {
          "intervalIndex": "interval-131",
          "value": 615.0,
          "endTime": 1541800800000
        },
        {
          "intervalIndex": "interval-132",
          "value": 605.0,
          "endTime": 1541797200000
        },
        {
          "intervalIndex": "interval-133",
          "value": 610.0,
          "endTime": 1541793600000
        },
        {
          "intervalIndex": "interval-134",
          "value": 599.0,
          "endTime": 1541790000000
        },
        {
          "intervalIndex": "interval-135",
          "value": 595.0,
          "endTime": 1541786400000
        },
        {
          "intervalIndex": "interval-136",
          "value": 610.0,
          "endTime": 1541782800000
        },
        {
          "intervalIndex": "interval-137",
          "value": 613.0,
          "endTime": 1541779200000
        },
        {
          "intervalIndex": "interval-138",
          "value": 657.0,
          "endTime": 1541775600000
        },
        {
          "intervalIndex": "interval-139",
          "value": 614.0,
          "endTime": 1541772000000
        },
        {
          "intervalIndex": "interval-140",
          "value": 660.0,
          "endTime": 1541768400000
        },
        {
          "intervalIndex": "interval-141",
          "value": 681.0,
          "endTime": 1541764800000
        },
        {
          "intervalIndex": "interval-142",
          "value": 598.0,
          "endTime": 1541761200000
        },
        {
          "intervalIndex": "interval-143",
          "value": 705.0,
          "endTime": 1541757600000
        },
        {
          "intervalIndex": "interval-144",
          "value": 619.0,
          "endTime": 1541754000000
        },
        {
          "intervalIndex": "interval-145",
          "value": 1598.0,
          "endTime": 1541750400000
        },
        {
          "intervalIndex": "interval-146",
          "value": 596.0,
          "endTime": 1541746800000
        },
        {
          "intervalIndex": "interval-147",
          "value": 611.0,
          "endTime": 1541743200000
        },
        {
          "intervalIndex": "interval-148",
          "value": 616.0,
          "endTime": 1541739600000
        },
        {
          "intervalIndex": "interval-149",
          "value": 598.0,
          "endTime": 1541736000000
        },
        {
          "intervalIndex": "interval-150",
          "value": 621.0,
          "endTime": 1541732400000
        },
        {
          "intervalIndex": "interval-151",
          "value": 621.0,
          "endTime": 1541728800000
        },
        {
          "intervalIndex": "interval-152",
          "value": 620.0,
          "endTime": 1541725200000
        },
        {
          "intervalIndex": "interval-153",
          "value": 648.0,
          "endTime": 1541721600000
        },
        {
          "intervalIndex": "interval-154",
          "value": 598.0,
          "endTime": 1541718000000
        },
        {
          "intervalIndex": "interval-155",
          "value": 612.0,
          "endTime": 1541714400000
        },
        {
          "intervalIndex": "interval-156",
          "value": 669.0,
          "endTime": 1541710800000
        },
        {
          "intervalIndex": "interval-157",
          "value": 663.0,
          "endTime": 1541707200000
        },
        {
          "intervalIndex": "interval-158",
          "value": 856.0,
          "endTime": 1541703600000
        },
        {
          "intervalIndex": "interval-159",
          "value": 624.0,
          "endTime": 1541700000000
        },
        {
          "intervalIndex": "interval-160",
          "value": 605.0,
          "endTime": 1541696400000
        },
        {
          "intervalIndex": "interval-161",
          "value": 639.0,
          "endTime": 1541692800000
        },
        {
          "intervalIndex": "interval-162",
          "value": 609.0,
          "endTime": 1541689200000
        },
        {
          "intervalIndex": "interval-163",
          "value": 656.0,
          "endTime": 1541685600000
        },
        {
          "intervalIndex": "interval-164",
          "value": 619.0,
          "endTime": 1541682000000
        },
        {
          "intervalIndex": "interval-165",
          "value": 928.0,
          "endTime": 1541678400000
        },
        {
          "intervalIndex": "interval-166",
          "value": 619.0,
          "endTime": 1541674800000
        },
        {
          "intervalIndex": "interval-167",
          "value": 601.0,
          "endTime": 1541671200000
        }
      ]
    }
  ]
}
});
