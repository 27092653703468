define({
    "Services" :  [
        {
            "service-name" : "group", enable: true, "database-info" : [
            { "adapterName" : "sim-group" , "dbName" : "sim-group", "collectionName" : "group",
                "collectionHandler" : null }], "description" : "seems to be not used"
        },
        {
            "service-name" : "siteprofile", enable: true, "database-info" : [
            { "adapterName" : "sim-siteprofile" , "dbName" : "sim-siteprofile", "collectionName" : "siteprofile",
              "collectionHandler" : null }], "description" : ""
        },
        {
            "service-name" : "ConnectivityDomain", enable: true, "database-info" : [
            { "adapterName" : "sim-ConnectivityDomain" , "dbName" : "sim-ConnectivityDomain", "collectionName" : "cd",
              "collectionHandler" : null }], "description" : "Fabric LAN, Fabric Site, Transit information"
        },
        {
            "service-name" : "network-device", enable : true, "database-info" : [
            { "adapterName" : "sim-network-device" , "dbName" : "sim-network-device", "collectionName" : "network-device", 
                "collectionHandler" : null  }], "description" : ""
        },
        {
            "service-name" : "device-credential", enable : true, "database-info" : [
            { "adapterName" : "sim-device-credential" , "dbName" : "sim-device-credential", "collectionName" : "device-credential", 
                "collectionHandler" : null  }], "description" : ""
        },
        {
            "service-name" : "device-image", enable : true, "database-info" : [
            { "adapterName" : "sim-device-image" , "dbName" : "sim-device-image", "collectionName" : "device-image",
              "collectionHandler" : null  }], "description" : "device to image details"
        },
        {
            "service-name" : "DeviceInfo", enable : true, "database-info" : [
            { "adapterName" : "sim-device-info" , "dbName" : "sim-device-info", "collectionName" : "device-info",
              "collectionHandler" : null  }], "description" : ""
        },
        {
            "service-name" : "host", enable : true, "database-info" : [
            { "adapterName" : "sim-host" , "dbName" : "sim-host", "collectionName" : "host",
              "collectionHandler" : null  }], "description" : "clients or host data"
        },
        {
            "service-name" : "interface", enable : true, "database-info" : [
            { "adapterName" : "sim-interface" , "dbName" : "sim-interface", "collectionName" : "iface",
              "collectionHandler" : null }], "description" : ""
        },
        {
            "service-name" : "physical-topology", enable : true, "database-info" : [
            { "adapterName" : "sim-topology" , "dbName" : "sim-topology", "collectionName" : "topology",
              "collectionHandler" : null }], "description" : "topology data of what device is connected to what device"
        },
        {
            "service-name" : "virtualnetworkcontext", enable : true, "database-info" : [
            { "adapterName" : "sim-vncontext" , "dbName" : "sim-vncontext", "collectionName" : "vncontext",
              "collectionHandler" : null }], "description" : "one entry for each VN created in Policy"
        },
        {
            "service-name" : "VirtualNetwork", enable : true, "database-info" : [
            { "adapterName" : "sim-virtual-network" , "dbName" : "sim-virtual-network", "collectionName" : "vn",
              "collectionHandler" : null }], "description" : "VN in Fabric Site Domain"
        },
        {
            "service-name" : "ippool", enable : true, "database-info" : [
            { "adapterName" : "sim-ippool" , "dbName" : "sim-ippool", "collectionName" : "ippool",
              "collectionHandler" : null  }], "description" : "ip address pool information"
        },
        {
            "service-name" : "Segment", enable : true, "database-info" : [
            { "adapterName" : "sim-segment" , "dbName" : "sim-segment", "collectionName" : "sg",
              "collectionHandler" : null  }], "description" : "pools added in VN of Fabric Site"
        },
        {
            "service-name" : "applicationSet", enable : true, "database-info" : [
            { "adapterName" : "sim-application-set" , "dbName" : "sim-application-set", "collectionName" : "aSet",
              "collectionHandler" : null }], "description" : ""
        },
        {
            "service-name" : "scalablegroup", enable : true, "database-info" : [
            { "adapterName" : "sim-scalable-group" , "dbName" : "sim-scalable-group", "collectionName" : "sgroup",
              "collectionHandler" : null }], "description" : ""
        },
        {
            "service-name" : "scalablegroupsummary", enable : true, "database-info" : [
            { "adapterName" : "scalablegroupsummary" , "dbName" : "scalablegroupsummary", "collectionName" : "scalablegroupsummary",
              "collectionHandler" : null }], "description" : "Scalable Group related Data from 1.3.1"
        },
        {
            "service-name" : "policy", enable : true, "database-info" : [
            { "adapterName" : "sim-policy" , "dbName" : "sim-policy", "collectionName" : "policy",
              "collectionHandler" : null }], "description" : ""
        },
        {
            "service-name" : "access-contract", enable : true, "database-info" : [
            { "adapterName" : "sim-access-contract" , "dbName" : "sim-access-contract",
              "collectionName" : "access-contract", "collectionHandler" : null }], "description" : "access contract information that is present in policy"
        },
        {
            "service-name" : "networkapplicationcategory", enable : true, "database-info" : [
            { "adapterName" : "sim-networkapplicationcategory" , "dbName" : "sim-networkapplicationcategory",
              "collectionName" : "networkapplicationcategory", "collectionHandler" : null }], "description" : ""
        },
        {
            "service-name" : "audit", enable : true, "database-info" : [
            { "adapterName" : "sim-audit" , "dbName" : "sim-audit", "collectionName" : "audit",
                "collectionHandler" : null }], "description" : ""
        },
        {
            "service-name" : "sim-activity-log", enable : true, "database-info" : [
            { "adapterName" : "sim-activity-log" , "dbName" : "sim-activity-log", "collectionName" : "sim-activity-log",
                "collectionHandler" : null }], "description" : "Activity-->Audit log info "
        },
        {
            "service-name" : "access-policy", enable : true, "database-info" : [
            { "adapterName" : "sim-access-policy" , "dbName" : "sim-access-policy",
                "collectionHandler" : null }], "description" : ""
        },
        {
            "service-name" : "copy-policy", enable : true, "database-info" : [
            { "adapterName" : "sim-copy-policy" , "dbName" : "sim-copy-policy", "collectionName" : "copy-policy",
              "collectionHandler" : null }], "description" : ""
        },
        {
            "service-name" : "copy-policy-summary", enable : true, "database-info" : [
            { "adapterName" : "sim-copy-policy-summary" , "dbName" : "sim-copy-policy-summary",
              "collectionName" : "copy-policy-summary", "collectionHandler" : null }], "description" : ""
        },
        {
            "service-name" : "policy-summary", enable : true, "database-info" : [
            { "adapterName" : "sim-policy-summary" , "dbName" : "sim-policy-summary", "collectionName" : "policy-summary", 
                "collectionHandler" : null }], "description" : ""
        },
        {
            "service-name" : "application-set", enable : true, "database-info" : [
            { "adapterName" : "sim-application-set" , "dbName" : "sim-application-set",
              "collectionName" : "application-set", "collectionHandler" : null }], "description" : ""
        },
        {
            "service-name" : "USER_DEVICE", enable : true, "database-info" : [
            { "adapterName" : "sim-USER_DEVICE" , "dbName" : "sim-USER_DEVICE", "collectionName" : "USER_DEVICE",
              "collectionHandler" : null }], "description" : ""
        },
        {
            "service-name" : "copy-destination", enable : true, "database-info" : [
                { "adapterName" : "sim-copy-destination" , "dbName" : "sim-copy-destination",
                  "collectionName" : "copy-destination", "collectionHandler" : null }], "description" : ""
        },{
            "service-name" : "copy-contract", enable : true, "database-info" : [
                { "adapterName" : "sim-copy-contract" , "dbName" : "sim-copy-contract",
                  "collectionName" : "copy-contract", "collectionHandler" : null }], "description" : ""
        },
        {
            "service-name" : "ip-access-contract", enable : true, "database-info" : [
                { "adapterName" : "ip-access-contract" , "dbName" : "ip-access-contract",
                  "collectionName" : "ip-access-contract", "collectionHandler" : null }], "description" : "Using for path Policy-IPBasedAccessControl-AccessContract"
        },
        {
            "service-name" : "ip-network-group", enable : true, "database-info" : [
                { "adapterName" : "ip-network-group" , "dbName" : "ip-network-group",
                  "collectionName" : "ip-network-group", "collectionHandler" : null }], "description" : "Using for path Policy-IPBasedAccessControl-IPNetworkGroups"
        },
        {
            "service-name" : "ip-access-control", enable : true, "database-info" : [
                { "adapterName" : "ip-access-control" , "dbName" : "ip-access-control",
                  "collectionName" : "ip-access-control", "collectionHandler" : null }], "description" : "Using for path Policy-IPBasedAccessControl-IP Based Access Control"
        },
        {
            "service-name" : "ip-access-control-policy", enable : true, "database-info" : [
                { "adapterName" : "ip-access-control-policy" , "dbName" : "ip-access-control-policy",
                  "collectionName" : "ip-access-control-policy", "collectionHandler" : null }], "description" : "Using for path Policy-IPBasedAccessControl-IP Based Access Control will have 2tables"
        },
        {
            "service-name" : "policy-endpoint-tag", enable : true, "database-info" : [
                { "adapterName" : "policy-endpoint-tag" , "dbName" : "policy-endpoint-tag",
                  "collectionName" : "policy-endpoint-tag", "collectionHandler" : null }], "description" : "Using for path Policy-Endpoint-Analytics-Heirarchy create new"
        },
        {
            "service-name" : "policy-endpoint-inventory", enable : true, "database-info" : [
                { "adapterName" : "policy-endpoint-inventory" , "dbName" : "policy-endpoint-inventory",
                  "collectionName" : "policy-endpoint-inventory", "collectionHandler" : null }], "description" : "Using for path Policy-Endpoint-Analytics-Endpoint Inventory"
        },
        {
            "service-name" : "policy-endpoint-profile", enable : true, "database-info" : [
                { "adapterName" : "policy-endpoint-profile" , "dbName" : "policy-endpoint-profile",
                  "collectionName" : "policy-endpoint-profile", "collectionHandler" : null }], "description" : "Using for path Policy-Endpoint-Analytics-Endpoint profile Rules"
        },
        {
            "service-name" : "policy-endpoint-lable", enable : true, "database-info" : [
                { "adapterName" : "policy-endpoint-lable" , "dbName" : "policy-endpoint-lable",
                  "collectionName" : "policy-endpoint-lable", "collectionHandler" : null }], "description" : "Using for path Policy-Endpoint-Analytics-Heirarchy Lables like(Ctscan,camera,...)"
        },
        {
            "service-name" : "site", enable : true, "database-info" : [
                { "adapterName" : "sim-site" , "dbName" : "sim-site", "collectionName" : "site",
                  "collectionHandler" : null }], "description" : "site information - areas, building, floor and their relationship"
        },
        {
            "service-name" : "DeviceConfigStatus", enable : true, "database-info" : [
                { "adapterName" : "sim-device-config" , "dbName" : "sim-device-config",
                  "collectionName" : "device-config", "collectionHandler" : null  }], "description" : "Device Provision data"
        },
        {
            "service-name" : "segment-ssid", enable : true, "database-info" : [
                { "adapterName" : "sim-segment-ssid" , "dbName" : "sim-segment-ssid", "collectionName" : "segment-ssid",
                  "collectionHandler" : null  }], "description" : ""
        },
        {
            "service-name" : "servers", enable : true, "database-info" : [
                { "adapterName" : "sim-server" , "dbName" : "sim-servers", "collectionName" : "servers",
                  "collectionHandler" : null  }], "description" : ""
        },{
            "service-name" : "common-setting-key", enable : true, "database-info" : [
                { "adapterName" : "sim-common-setting-key" , "dbName" : "sim-common-setting-key",
                  "collectionName" : "common-setting-key", "collectionHandler" : null  }], "description" : ""
        },{
            "service-name" : "aaa", enable : true, "database-info" : [
                { "adapterName" : "sim-aaa" , "dbName" : "sim-aaa", "collectionName" : "aaa",
                  "collectionHandler" : null  }], "description" : ""
        },{
            "service-name" : "psn-server", enable : true, "database-info" : [
                { "adapterName" : "sim-psn-server" , "dbName" : "psn-server", "collectionName" : "psn-server",
                  "collectionHandler" : null  }], "description" : ""
        },{
            "service-name" : "global-credential", enable : true, "database-info" : [
                { "adapterName" : "sim-global-credential" , "dbName" : "global-credential",
                  "collectionName" : "global-credential", "collectionHandler" : null  }], "description" : ""
        },{
            "service-name" : "floor-map", enable : true, "database-info" : [
               { "adapterName" : "sim-floor-map" , "dbName" : "floor-map", "collectionName" : "floor-map",
                  "collectionHandler" : null  }], "description" : ""
        },
        {
            "service-name" : "wlan", enable : true, "database-info" : [
                { "adapterName" : "sim-wlan" , "dbName" : "sim-wlan", "collectionName" : "wlan",
                  "collectionHandler" : null }], "description" : ""
        },
        {
            "service-name" : "global", enable : true, "database-info" : [
                { "adapterName" : "sim-global" , "dbName" : "sim-global", "collectionName" : "global",
                  "collectionHandler" : null }], "description" : ""
        },
        {
            "service-name" : "sensor", enable : true, "database-info" : [
                { "adapterName" : "sensor" , "dbName" : "sensor", "collectionName" : "sensor",
                  "collectionHandler" : null }], "description" : ""
        },
        {
            "service-name" : "images", enable : true, "database-info" : [
                { "adapterName" : "sim-images" , "dbName" : "sim-images", "collectionName" : "images",
                  "collectionHandler" : null  }], "description" : "SWIM - images of different device types"
        },
        {
            "service-name" : "lan-network-orch-status", enable : true, "database-info" : [
                { "adapterName" : "sim-lan-network-orch-status" , "dbName" : "lan-network-orch-status",
                  "collectionName" : "lan-network-orch-status", "collectionHandler" : null }], "description" : ""
        },
        {
            "service-name" : "lan-network-orch-log", enable : true, "database-info" : [
                { "adapterName" : "sim-lan-network-orch-log" , "dbName" : "lan-network-orch-log",
                  "collectionName" : "lan-network-orch-log", "collectionHandler" : null }], 
                  "description" : "Log section of Lan Automation"
        },
        {
            "service-name" : "commonsetting-lan", enable : true, "database-info" : [
                { "adapterName" : "sim-lan" , "dbName" : "sim-lan", "collectionName" : "commonsetting-lan",
                  "collectionHandler" : null }], "description" : "common settings lan info, related to lan automation"
        },
        {
            "service-name" : "wlan-ssid", enable : true, "database-info" : [
                { "adapterName" : "sim-wlan-ssid" , "dbName" : "wlan-ssid",
                  "collectionName" : "wlan-ssid", "collectionHandler" : null }], "description" : ""
        },
        {
            "service-name" : "DELETEfloor-map", enable : true, "database-info" : [
                { "adapterName" : "DELETEsim-floor-map" , "dbName" : "DELETEglobal-credential", "collectionName" : "DELETEfloor-map",
                  "collectionHandler" : null  }], "description" : ""
        },
        {
            "service-name" : "validation-base-config", enable : true, "database-info" : [
                { "adapterName" : "sim-validation-base-config" , "dbName" : "sim-validation-base-config",
                  "collectionName" : "validation-base-config", "collectionHandler" : null }], "description" : ""
        },
        {
            "service-name" : "validation-base-operation", enable : true, "database-info" : [
                { "adapterName" : "sim-validation-base-operation" , "dbName" : "sim-validation-base-operation",
                  "collectionName" : "validation-base-operation", "collectionHandler" : null }], "description" : ""
        },
        {
            "service-name" : "validation-options", enable : true, "database-info" : [
                { "adapterName" : "sim-validation-options" , "dbName" : "sim-validation-options",
                  "collectionName" : "validation-options", "collectionHandler" : null }], "description" : ""
        },
        {
            "service-name" : "validation-result", enable : true, "database-info" : [
                { "adapterName" : "sim-validation-result" , "dbName" : "sim-validation-result",
                  "collectionName" : "validation-result", "collectionHandler" : null }], "description" : ""
        },
        {
            "service-name" : "discovery", enable : true, "database-info" : [
                { "adapterName" : "sim-discovery" , "dbName" : "sim-discovery",
                  "collectionName" : "discovery", "collectionHandler" : null }], "description" : "discovery detail (Tools)"
        },
        {
            "service-name" : "discovery-job", enable : true, "database-info" : [
                { "adapterName" : "sim-discovery-job" , "dbName" : "sim-discovery-job",
                  "collectionName" : "discovery-job", "collectionHandler" : null }], "description" : "discovery job summary (Tools)"
        },
        {
            "service-name" : "user", enable : true, "database-info" : [
                { "adapterName" : "sim-user" , "dbName" : "sim-user",
                  "collectionName" : "user", "collectionHandler" : null }], "description" : ""
        },
        {
            "service-name" : "issue", enable : true, "database-info" : [
                { "adapterName" : "sim-issue" , "dbName" : "sim-issue",
                  "collectionName" : "sim-issue", "collectionHandler" : null }], "description" : "issues of devices, hosts and applications"
        },
        {
            "service-name" : "pathtrace", enable : true, "database-info" : [
                { "adapterName" : "sim-pathtrace" , "dbName" : "sim-pathtrace",
                  "collectionName" : "sim-pathtrace", "collectionHandler" : null }], "description" : "pathtrace info generation, present in device360"
        },
        {
            "service-name" : "assuranceissue-detail", enable : true, "database-info" : [
                { "adapterName" : "assuranceissue-detail" , "dbName" : "assuranceissue-detail",
                  "collectionName" : "assuranceissue-detail", "collectionHandler" : null }], "description" : ""
        },
        {
            "service-name" : "impacted-hosts", enable : true, "database-info" : [
                { "adapterName" : "impacted-hosts", "dbName" : "impacted-hosts",
                  "collectionName" : "impacted-hosts", "collectionHandler" : null }], "description" : ""
        },
        {
            "service-name" : "issue-onboarding", enable : true, "database-info" : [
                { "adapterName" : "issue-onboarding", "dbName" : "issue-onboarding",
                  "collectionName" : "issue-onboarding", "collectionHandler" : null }], "description" : ""
        },
        {
            "service-name" : "guest-portal", enable : true, "database-info" : [
                { "adapterName" : "guest-portal", "dbName" : "guest-portal",
                  "collectionName" : "guest-portal", "collectionHandler" : null }], "description" : ""
        },
        {
            "service-name" : "guest-policy", enable : true, "database-info" : [
                { "adapterName" : "guest-policy", "dbName" : "guest-policy",
                  "collectionName" : "guest-policy", "collectionHandler" : null }], "description" : ""
        },
        {
            "service-name" : "display-filter", enable : true, "database-info" : [
                { "adapterName" : "display-filter", "dbName" : "display-filter",
                  "collectionName" : "display-filter", "collectionHandler" : null }], "description" : ""
        },
        {
            "service-name" : "preferences", enable : true, "database-info" : [
                { "adapterName" : "preferences", "dbName" : "preferences",
                  "collectionName" : "preferences", "collectionHandler" : null }], "description" : ""
        },
        {
            "service-name" : "access-point", enable : true, "database-info" : [
                { "adapterName" : "access-point", "dbName" : "access-point",
                  "collectionName" : "access-point", "collectionHandler" : null }], "description" : "access point information used for displaying in floor map"
        },
        {
            "service-name" : "issue-trigger", enable : true, "database-info" : [
                { "adapterName" : "issue-trigger", "dbName" : "issue-trigger",
                  "collectionName" : "issue-trigger", "collectionHandler" : null }], "description" : ""
        },
        {
            "service-name" : "application-contract", enable : true, "database-info" : [
            { "adapterName" : "sim-application-contract" , "dbName" : "sim-application-contract",
              "collectionName" : "application-contract", "collectionHandler" : null }], "description" : ""
        },
        {
            "service-name" : "application-policy", enable : true, "database-info" : [
            { "adapterName" : "sim-application-policy" , "dbName" : "sim-application-policy",
              "collectionName" : "application-policy", "collectionHandler" : null }], "description" : ""
        },
        {
            "service-name" : "application-policy-preview", enable : true, "database-info" : [
            { "adapterName" : "sim-application-policy-preview" , "dbName" : "sim-application-policy-perview",
              "collectionName" : "application-policy-preview", "collectionHandler" : null }], "description" : ""
        },
        {
            "service-name" : "sensor-test-suite", enable : true, "database-info" : [
                { "adapterName" : "sensor-test-suite", "dbName" : "sensor-test-suite",
                  "collectionName" : "sensor-test-suite", "collectionHandler" : null }], "description" : ""
        },
        {
            "service-name" : "sensor-test-results", enable : true, "database-info" : [
                { "adapterName" : "sensor-test-results", "dbName" : "sensor-test-results",
                  "collectionName" : "sensor-test-results", "collectionHandler" : null }], "description" : ""
        },
        {
            "service-name" : "onboarding-pnp-device", enable : true, "database-info" : [
                { "adapterName" : "onboarding-pnp-device", "dbName" : "onboarding-pnp-device",
                  "collectionName" : "onboarding-pnp-device", "collectionHandler" : null }], "description" : ""
        },
        {
            "service-name" : "learn-device-device", enable : true, "database-info" : [
                { "adapterName" : "learn-device-device", "dbName" : "learn-device-device",
                  "collectionName" : "learn-device-device", "collectionHandler" : null }], "description" : ""
        },
        {
            "service-name" : "image-task-list", enable : true, "database-info" : [
                { "adapterName" : "image-task-list", "dbName" : "image-task-list",
                  "collectionName" : "image-task-list", "collectionHandler" : null }], "description" : "For task list displayed in Image Repository of Design"
        },
        {
            "service-name" : "image-activation", enable : true, "database-info" : [
                { "adapterName" : "image-activation", "dbName" : "image-activation",
                  "collectionName" : "image-activation", "collectionHandler" : null }], "description" : "Upgrade OS action - 1/3. Show tasks in Provision - First level data displayed."
        },
        {
            "service-name" : "sim-issue-timing", enable : true, "database-info" : [
                { "adapterName" : "sim-issue-timing", "dbName" : "sim-issue-timing",
                  "collectionName" : "sim-issue-timing", "collectionHandler" : null }], "description" : "To have dip in the graph in client 360"
        },
        {
            "service-name" : "sim-sensor", enable : true, "database-info" : [
                { "adapterName" : "sim-sensor", "dbName" : "sim-sensor",
                  "collectionName" : "sim-sensor", "collectionHandler" : null }], "description" : ""
        },
        {
            "service-name" : "application", enable : true, "database-info" : [
                { "adapterName" : "application", "dbName" : "application",
                  "collectionName" : "application", "collectionHandler" : null }], "description" : "Applications info (Assurance)"
        },
        {
            "service-name" : "tag", enable : true, "database-info" : [
                { "adapterName" : "tag", "dbName" : "tag",
                  "collectionName" : "tag", "collectionHandler" : null }], "description" : "Tags present (Provision)"
        },
        {
            "service-name" : "tag-preview-count", enable : true, "database-info" : [
                { "adapterName" : "tag-preview-count", "dbName" : "tag-preview-count",
                  "collectionName" : "tag-preview-count", "collectionHandler" : null }], "description" : "Temporary data storage in mid of tag assignment to device creation or dynamic rule recreation"
        },
        {
            "service-name" : "tag-device-mapping", enable : true, "database-info" : [
                { "adapterName" : "tag-device-mapping", "dbName" : "tag-device-mapping",
                  "collectionName" : "tag-device-mapping", "collectionHandler" : null }], "description" : "tag mapping to device information"
        },
        {
            "service-name" : "scheduled-capture", enable : true, "database-info" : [
                { "adapterName" : "scheduled-capture", "dbName" : "scheduled-capture",
                  "collectionName" : "scheduled-capture", "collectionHandler" : null }], "description" : ""
        },
        {
            "service-name" : "scheduled-capture-settings", enable : true, "database-info" : [
                { "adapterName" : "scheduled-capture-settings", "dbName" : "scheduled-capture-settings",
                  "collectionName" : "scheduled-capture-settings", "collectionHandler" : null }], "description" : ""
        },
        {
            "service-name" : "issue_capture_settings_126", enable : true, "database-info" : [
                { "adapterName" : "issue_capture_settings_126", "dbName" : "issue_capture_settings_126",
                  "collectionName" : "issue_capture_settings_126", "collectionHandler" : null }], "description" : ""
        },
        {
            "service-name" : "scheduled-capture-settings_126", enable : true, "database-info" : [
                { "adapterName" : "scheduled-capture-settings_126", "dbName" : "scheduled-capture-settings_126",
                  "collectionName" : "scheduled-capture-settings_126", "collectionHandler" : null }], "description" : ""
        },
        {
            "service-name" : "upgrade-os-list", enable : true, "database-info" : [
                { "adapterName" : "upgrade-os-list", "dbName" : "upgrade-os-list",
                  "collectionName" : "upgrade-os-list", "collectionHandler" : null }], "description" : "Upgrade OS action - 2/3. Second level of Upgrade OS data displayed in Show Tasks of Provision (Distribution, Activation), on expanding a device entry."
        },
        {
            "service-name" : "nfv-provision-details", enable : true, "database-info" : [
                { "adapterName" : "nfv-provision-details", "dbName" : "nfv-provision-details",
                  "collectionName" : "nfv-provision-details", "collectionHandler" : null }], "description" : "NFV device provisioning - 2/2. Details of provision workflow"
        },
        {
            "service-name" : "nfv-provision-data", enable : true, "database-info" : [
                { "adapterName" : "nfv-provision-data", "dbName" : "nfv-provision-data",
                  "collectionName" : "nfv-provision-data", "collectionHandler" : null }], "description" : "NFV device provisioning - 1/2"
        },
        {
            "service-name" : "pnp-workflow", enable : true, "database-info" : [
                { "adapterName" : "pnp-workflow", "dbName" : "pnp-workflow",
                  "collectionName" : "pnp-workflow", "collectionHandler" : null }], "description" : "PnP workflow section"
        },
        {
            "service-name" : "sim-issue-timing-device", enable : true, "database-info" : [
                { "adapterName" : "sim-issue-timing-device", "dbName" : "sim-issue-timing-device",
                  "collectionName" : "sim-issue-timing-device", "collectionHandler" : null }], "description" : "To have dip in the graph in device 360"
        },
        {
            "service-name" : "licenseInfo", enable : true, "database-info" : [
                { "adapterName" : "licenseInfo", "dbName" : "licenseInfo",
                  "collectionName" : "licenseInfo", "collectionHandler" : null }], "description" : "License infor for each device"
        },
        {
            "service-name" : "licenseUsage", enable : true, "database-info" : [
                { "adapterName" : "licenseUsage", "dbName" : "licenseUsage",
                  "collectionName" : "licenseUsage", "collectionHandler" : null }], "description" : "License Usage info count infos for each device type like legacy, deployed, entitled, etc's counts"
        },
        {
            "service-name" : "licenseVirtualAccount", enable : true, "database-info" : [
                { "adapterName" : "licenseVirtualAccount", "dbName" : "licenseVirtualAccount",
                  "collectionName" : "licenseVirtualAccount", "collectionHandler" : null }], "description" : "list of virtual accounts. Displayed during license Registration. Also in settings of DNAC section."
        },
        {
            "service-name" : "licenseTaskStatus", enable : true, "database-info" : [
                { "adapterName" : "licenseTaskStatus", "dbName" : "licenseTaskStatus",
                  "collectionName" : "licenseTaskStatus", "collectionHandler" : null }], "description" : "Task Status in All Licenses showing the actions of register, deregister, enable resevation, cancel reservation"
        },
        {
            "service-name" : "dnacConstants", enable : true, "database-info" : [
                { "adapterName" : "dnacConstants", "dbName" : "dnacConstants",
                  "collectionName" : "dnacConstants", "collectionHandler" : null }], "description" : "Few DNAC constants that can be used accrosed. Ex: DNAC version"
        },
        {
            "service-name" : "templateProject", enable : true, "database-info" : [
                { "adapterName" : "templateProject", "dbName" : "templateProject",
                  "collectionName" : "templateProject", "collectionHandler" : null }], "description" : "Projects in Template Editor module"
        },
        {
            "service-name" : "templatesConfig", enable : true, "database-info" : [
                { "adapterName" : "templatesConfig", "dbName" : "templatesConfig",
                  "collectionName" : "templatesConfig", "collectionHandler" : null }], "description" : "List of templates present in Template Editor module"
        },
        {
            "service-name" : "templateDetail", enable : true, "database-info" : [
                { "adapterName" : "templateDetail", "dbName" : "templateDetail",
                  "collectionName" : "templateDetail", "collectionHandler" : null }], "description" : "Details of a template present in Template Editor module"
        },
        {
            "service-name" : "issueSettings", enable : true, "database-info" : [
                { "adapterName" : "issueSettings", "dbName" : "issueSettings",
                  "collectionName" : "issueSettings", "collectionHandler" : null }], "description" : ""
        },
        {
            "service-name" : "assuranceIssueDetails", enable : true, "database-info" : [
                { "adapterName" : "assuranceIssueDetails", "dbName" : "assuranceIssueDetails",
                  "collectionName" : "assuranceIssueDetails", "collectionHandler" : null }], "description" : ""
        },
        {
            "service-name" : "issueSettingCustomised", enable : true, "database-info" : [
                { "adapterName" : "issueSettingCustomised", "dbName" : "issueSettingCustomised",
                  "collectionName" : "issueSettingCustomised", "collectionHandler" : null }], "description" : ""
        },
        {
            "service-name" : "customIssue", enable : true, "database-info" : [
                { "adapterName" : "customIssue", "dbName" : "customIssue",
                  "collectionName" : "customIssue", "collectionHandler" : null }], "description" : ""
        },
        {
            "service-name" : "customAssuranceissue-detail", enable : true, "database-info" : [
                { "adapterName" : "customAssuranceissue-detail", "dbName" : "customAssuranceissue-detail",
                  "collectionName" : "customAssuranceissue-detail", "collectionHandler" : null }], "description" : ""
        },
        {
            "service-name" : "icap-captures", enable : true, "database-info" : [
                { "adapterName" : "icap-captures", "dbName" : "icap-captures",
                  "collectionName" : "icap-captures", "collectionHandler" : null }], "description" : ""
        },
        {
            "service-name" : "device-interface", enable : true, "database-info" : [
                { "adapterName" : "device-interface", "dbName" : "device-interface",
                  "collectionName" : "device-interface", "collectionHandler" : null }], "description" : "List of interfaces in each device"
        },
        {
            "service-name" : "functional-capability", enable : true, "database-info" : [
                { "adapterName" : "functional-capability", "dbName" : "functional-capability",
                  "collectionName" : "functional-capability", "collectionHandler" : null }], "description" : ""
        },
        {
            "service-name" : "scheduled-job", enable : true, "database-info" : [
                { "adapterName" : "scheduled-job", "dbName" : "scheduled-job",
                  "collectionName" : "scheduled-job", "collectionHandler" : null }], "description" : ""
        },
        {
            "service-name" : "interface-port-channel", enable : true, "database-info" : [
                { "adapterName" : "interface-port-channel", "dbName" : "interface-port-channel",
                  "collectionName" : "interface-port-channel", "collectionHandler" : null }], "description" : ""
        },
        {
            "service-name" : "device-replacement", enable : true, "database-info" : [
                { "adapterName" : "device-replacement", "dbName" : "device-replacement",
                  "collectionName" : "device-replacement", "collectionHandler" : null }], "description" : "device replacement - Provision"
        },
        {
            "service-name" : "rogue-management", enable : true, "database-info" : [
                { "adapterName" : "rogue-management", "dbName" : "rogue-management",
                  "collectionName" : "rogue-management", "collectionHandler" : null }], "description" : "rogue-management - Assurance"
        },
        {
            "service-name" : "rogue-whitelist", enable : true, "database-info" : [
                { "adapterName" : "rogue-whitelist", "dbName" : "rogue-whitelist",
                  "collectionName" : "rogue-whitelist", "collectionHandler" : null }], "description" : "Whitelist threats for rogue-management - Assurance"
        },
        {
            "service-name" : "rogue-threattype", enable : true, "database-info" : [
                { "adapterName" : "rogue-threattype", "dbName" : "rogue-threattype",
                  "collectionName" : "rogue-threattype", "collectionHandler" : null }], "description" : "rogue-management threattypes"
        },
        {
            "service-name" : "rogue-rule", enable : true, "database-info" : [
                { "adapterName" : "rogue-rule", "dbName" : "rogue-rule",
                  "collectionName" : "rogue-rule", "collectionHandler" : null }], "description" : "rogue-management Adding Rules"
        },
        {
            "service-name" : "rogue-rule-profile", enable : true, "database-info" : [
                { "adapterName" : "rogue-rule-profile", "dbName" : "rogue-rule-profile",
                  "collectionName" : "rogue-rule-profile", "collectionHandler" : null }], "description" : "rogue-management Adding Profile Rules"
        },
        {
            "service-name" : "rogue-allow-rule", enable : true, "database-info" : [
                { "adapterName" : "rogue-allow-rule", "dbName" : "rogue-allow-rule",
                  "collectionName" : "rogue-allow-rule", "collectionHandler" : null }], "description" : "rogue-management Adding AllowedList Rules"
        },
        {
            "service-name" : "health-threshold-setting", enable : true, "database-info" : [
                { "adapterName" : "health-threshold-setting", "dbName" : "health-threshold-setting",
                  "collectionName" : "health-threshold-setting", "collectionHandler" : null }], "description" : "health-threshold-setting - Assurance"
        },
        {
            "service-name" : "bonjour-service-type", enable : true, "database-info" : [
                { "adapterName" : "bonjour-service-type", "dbName" : "bonjour-service-type",
                  "collectionName" : "bonjour-service-type", "collectionHandler" : null }], "description" : "service types used in bonjour"
        },
        {
            "service-name" : "bonjour-service-policy", enable : true, "database-info" : [
                { "adapterName" : "bonjour-service-policy", "dbName" : "bonjour-service-policy",
                  "collectionName" : "bonjour-service-policy", "collectionHandler" : null }], "description" : "service policies/filters used in bonjour"
        },
        {
            "service-name" : "bonjour-service-entry", enable : true, "database-info" : [
                { "adapterName" : "bonjour-service-entry", "dbName" : "bonjour-service-entry",
                  "collectionName" : "bonjour-service-entry", "collectionHandler" : null }], "description" : "service entries used in bonjour"
        },
        {
            "service-name" : "bonjour-domain", enable : true, "database-info" : [
                { "adapterName" : "bonjour-domain", "dbName" : "bonjour-domain",
                  "collectionName" : "bonjour-domain", "collectionHandler" : null }], "description" : "domains/sub-domains present in bonjour"
        },
        {
            "service-name" : "bonjour-nodes", enable : true, "database-info" : [
                { "adapterName" : "bonjour-nodes", "dbName" : "bonjour-nodes",
                  "collectionName" : "bonjour-nodes", "collectionHandler" : null }], "description" : "nodes/devices present in bonjour"
        },
        {
            "service-name" : "bonjour-global-settings", enable : true, "database-info" : [
                { "adapterName" : "bonjour-global-settings", "dbName" : "bonjour-global-settings",
                  "collectionName" : "bonjour-global-settings", "collectionHandler" : null }], "description" : "settings of bonjour administration"
        },
        {
            "service-name" : "replace-device-workflow", enable : true, "database-info" : [
                { "adapterName" : "replace-device-workflow", "dbName" : "replace-device-workflow",
                  "collectionName" : "replace-device-workflow", "collectionHandler" : null }], "description" : "workflow details when device replacement is successful"
        },
        {
            "service-name" : "sensor-list-summary", enable : true, "database-info" : [
                { "adapterName" : "sensor-list-summary", "dbName" : "sensor-list-summary",
                  "collectionName" : "sensor-list-summary", "collectionHandler" : null }], "description" : "In assurance we collect the sensor list"
        },
        {
            "service-name" : "kairos-network-insight", enable : true, "database-info" : [
                { "adapterName" : "kairos-network-insight", "dbName" : "kairos-network-insight",
                  "collectionName" : "kairos-network-insight", "collectionHandler" : null }], "description" : "Kairos - Network Insights listing"
        },
        {
            "service-name" : "kairos-network-insight-entity-details", enable : true, "database-info" : [
                { "adapterName" : "kairos-network-insight-entity-details", "dbName" : "kairos-network-insight-entity-details",
                  "collectionName" : "kairos-network-insight-entity-details", "collectionHandler" : null }], "description" : "Aps of corresponding insight"
        },
        {
            "service-name" : "kairos-heatmap", enable : true, "database-info" : [
                { "adapterName" : "kairos-heatmap", "dbName" : "kairos-heatmap",
                  "collectionName" : "kairos-heatmap", "collectionHandler" : null }], "description" : "kairos-heatmap"
        },
        {
            "service-name" : "kairos-feedback", enable : true, "database-info" : [
                { "adapterName" : "kairos-feedback", "dbName" : "kairos-feedback",
                  "collectionName" : "kairos-feedback", "collectionHandler" : null }], "description" : "kairos-feedback"
        },
        {
          "service-name" : "kairos-peer-comparision-totalKpisData", enable : true, "database-info" : [
              { "adapterName" : "kairos-peer-comparision-totalKpisData", "dbName" : "kairos-peer-comparision-totalKpisData",
                "collectionName" : "kairos-peer-comparision-totalKpisData", "collectionHandler" : null }], "description" : "Kairos -Peer comparision Complate kpis data"
        },
        {
          "service-name" : "kairos-peer-comparision-Aggregated-Weekday-Data", enable : true, "database-info" : [
              { "adapterName" : "kairos-peer-comparision-Aggregated-Weekday-Data", "dbName" : "kairos-peer-comparision-Aggregated-Weekday-Data",
                "collectionName" : "kairos-peer-comparision-Aggregated-Weekday-Data", "collectionHandler" : null }], "description" : "Kairos -Peer comparision kpi's Aggragated weekday data"
        },
        {
          "service-name" : "kairos-peer-comparision-Aggregated-Data", enable : true, "database-info" : [
              { "adapterName" : "kairos-peer-comparision-Aggregated-Data", "dbName" : "kairos-peer-comparision-Aggregated-Data",
                "collectionName" : "kairos-peer-comparision-Aggregated-Data", "collectionHandler" : null }], "description" : "Kairos -Peer comparision kpi's Aggragated data"
        },
        {
            "service-name" : "poe-aggregated-data", enable : true, "database-info" : [
                { "adapterName" : "poe-aggregated-data" , "dbName" : "poe-aggregated-data",
                  "collectionName" : "poe-aggregated-data", "collectionHandler" : null }], "description" : "data of devices which supports POE "
        },
        {

            "service-name" : "udn-data", enable : true, "database-info" : [
                { "adapterName" : "udn-data" , "dbName" : "udn-data",
                  "collectionName" : "udn-data", "collectionHandler" : null }], "description" : "user Defined Network data"
        },
        {

            "service-name" : "kairos-sitecmprsn-site-day-mapping", enable : true, "database-info" : [
                { "adapterName" : "kairos-sitecmprsn-site-day-mapping" , "dbName" : "kairos-sitecmprsn-site-day-mapping",
                  "collectionName" : "kairos-sitecmprsn-site-day-mapping", "collectionHandler" : null }], "description" : "Kairos Site Comparison - for KPIS using metadata, to sync top and below graph"

        },
        {
            "service-name" : "device-compliance", enable : true, "database-info" : [
                { "adapterName" : "device-compliance" , "dbName" : "device-compliance",
                  "collectionName" : "device-compliance", "collectionHandler" : null }], "description" : "Compliance of device"
        },
        {
            "service-name" : "kairos-traits-timestamps", enable : true, "database-info" : [
                { "adapterName" : "kairos-traits-timestamps" , "dbName" : "kairos-traits-timestamps",
                  "collectionName" : "kairos-traits-timestamps", "collectionHandler" : null }], "description" : "kairos traits TimeStamps"
        },
        {
            "service-name" : "kairos-mappings", enable : true, "database-info" : [
                { "adapterName" : "kairos-mappings" , "dbName" : "kairos-mappings",
                  "collectionName" : "kairos-mappings", "collectionHandler" : null }], "description" : "kairos mappings with keys indicating the need for this table. Ex: ap to entityid mapping with key apEntityIdMapping"
        },
        {
            "service-name" : "temporary-data", enable : true, "database-info" : [
                { "adapterName" : "temporary-data" , "dbName" : "temporary-data",
                  "collectionName" : "temporary-data", "collectionHandler" : null }], "description" : "used for actions which need interlink between two calls. Ex: scheduled image upgrade"
        },
        {
            "service-name" : "custom-topology-view", enable : true, "database-info" : [
                { "adapterName" : "custom-topology-view" , "dbName" : "custom-topology-view",
                  "collectionName" : "custom-topology-view", "collectionHandler" : null }], "description" : "custom physical topologies view details are saved and read from here"
        },
        {
            "service-name" : "custom-topology", enable : true, "database-info" : [
                { "adapterName" : "custom-topology" , "dbName" : "custom-topology",
                  "collectionName" : "custom-topology", "collectionHandler" : null }], "description" : "physical topologies page under a controller, its view are saved and read from here"
        },
        {
            "service-name" : "kairos-heatmap-kpivalues", enable : true, "database-info" : [
                { "adapterName" : "kairos-heatmap-kpivalues" , "dbName" : "kairos-heatmap-kpivalues",
                  "collectionName" : "kairos-heatmap-kpivalues", "collectionHandler" : null }], "description" : "kairos heatmap kpi values"
        },
        {
          "service-name" : "apphoisting-response", enable : true, "database-info" : [
              { "adapterName" : "apphoisting-response" , "dbName" : "apphoisting-response",
                "collectionName" : "apphoisting-response", "collectionHandler" : null }], "description" : "appHoisting post call data"
        },
        {
          "service-name" : "apphoisting-jobid", enable : true, "database-info" : [
              { "adapterName" : "apphoisting-jobid" , "dbName" : "apphoisting-jobid",
                "collectionName" : "apphoisting-jobid", "collectionHandler" : null }], "description" : "appHoisting job data"
        },
        {
            "service-name" : "inventory-insight", enable : true, "database-info" : [
                { "adapterName" : "inventory-insight" , "dbName" : "inventory-insight",
                  "collectionName" : "inventory-insight", "collectionHandler" : null }], "description" : "inventory insights in provision"
        },
        {
            "service-name" : "vmanage-devices", enable : true, "database-info" : [
                { "adapterName" : "vmanage-devices" , "dbName" : "vmanage-devices",
                  "collectionName" : "vmanage-devices", "collectionHandler" : null }], "description" : "sdwan devices synced with dnac"
        },
        {
            "service-name" : "deviceEox", enable : true, "database-info" : [
                { "adapterName" : "deviceEox" , "dbName" : "deviceEox",
                  "collectionName" : "deviceEox", "collectionHandler" : null }], "description" : "EoX status in inventory"
        },
        {
            "service-name" : "enhancedRRM-sim", enable : true, "database-info" : [
                { "adapterName" : "enhancedRRM-sim" , "dbName" : "enhancedRRM-sim",
                  "collectionName" : "enhancedRRM-sim", "collectionHandler" : null }], "description" : "EnhancedRRM Simulation Run Feature"
        },
        {
            "service-name" : "extranet-policies", enable : true, "database-info" : [
                { "adapterName" : "extranet-policies" , "dbName" : "extranet-policies",
                  "collectionName" : "extranet-policies", "collectionHandler" : null }], "description" : "ExtranetPolicies in Provision"
        }
    ]
});
