define({
    "Issue_Time_Template": [

         {"hostId":"6C:19:C0:BD:87:C9","start" : 7,"end" : 12,graphStartDateTime:0},
         {"hostId":"6C:19:C0:BD:87:C7","start" : 10,"end" :11,graphStartDateTime:0 },
         {"hostId":"B8:27:EB:CA:AA:88","start" : 5,"end" : 6,graphStartDateTime:0 },
         {"hostId":"6C:19:C0:BD:93:AB","start" : 0,"end" : 0,graphStartDateTime:0 },
         {"hostId":"6C:18:C0:BD:81:00","start" : 0,"end" : 0,graphStartDateTime:0 },
         {"hostId":"6C:18:C0:BD:93:00","start" : 0,"end" : 0,graphStartDateTime:0 },

        ],
    "Empty_Time": [
        {"hostId":"00:0C:29:77:FC:99", "preConnectedDevices":[{'name':'p1.edge1-sda1.local'}], 'isUpdated':false, 
            "noOfDisconnects":1, "isEmptySpecificTime":true },//should have disconnect number of preConnectDevices
        {"hostId":"A8:B5:27:36:70:09", "preConnectedDevices":[{'name':'SJC01_9136_1'},{'name':'SJC01_9136_1'},{'name':'SJC01_9136_1'},
            {'name':'SJC01_9136_1'},{'name':'SJC01_9136_1'}], 'isUpdated':false, 
            "noOfDisconnects":5, "isEmptySpecificTime":false }
    ]

    });