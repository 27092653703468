define([
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/utilities/UtilityFunctions',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/database/SimLokiDatabaseActions',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/data-generation/configuration/SimAssuranceDataGenerator',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/assurance/eventview/WirelessClientEventView',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/assurance/eventview/WirelessClientEventView_10',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/assurance/eventview/WirelessClientEventView_4',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/assurance/eventview/WirelessClientEventView_4',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/assurance/eventview/SpecialWirelessClientEventView',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/assurance/eventview/WiredClientEventView'
], function (UtilityFunctions, SimLokiDatabaseActions, SimAssuranceDataGenerator, WirelessClientEventView, WirelessClientEventView_10,
    WirelessClientEventView_4, WirelessClientEventView_3, SpecialWirelessClientEventView, WiredClientEventView) {

        return {

            init: function () {
            },

            getEventView: function (urlAction) {
                //if(urlAction.filter['entityId'] != undefined) {
                //getting host for reading score
                //  var hosts = SimAssuranceDataGenerator.createHosts().hosts;
                var score = 10, t;
                var host = undefined;
                var entityId = urlAction.filter['entityId'];

                //CAUTION -- Just a hack..
                if (entityId == undefined || entityId == "undefined") {
                    entityId = window.currentClientMacId;
                }

                if (entityId != undefined && entityId != '') {

                    host = SimLokiDatabaseActions.getFilteredRecordHandler('host', { 'id': entityId });

                    if (host.length > 0 && host[0].hostType == "WIRED") {
                        if (host[0].hostName == "Camera2") {
                            var connectedNWDeviceDetails = SimLokiDatabaseActions.getFilteredRecordHandler('network-device', { 'id': host[0].connectedNetworkDeviceId });
                            var eventsResponse = JSON.stringify(WiredClientEventView.VC_Camera2_eventsViewTemplate);
                            eventsResponse = eventsResponse.replace('LINK_DOWN_StartTime', 'LINK_DOWN_'+ urlAction.filter.startTime)
                            eventsResponse = eventsResponse.replaceAll('Connected_Device_Name', connectedNWDeviceDetails[0].name);
                            eventsResponse = eventsResponse.replace('Connected_Device_IP', connectedNWDeviceDetails[0].managementIpAddress)
                            eventsResponse = JSON.parse(eventsResponse);
                            eventsResponse.response[0].timestamp = urlAction.filter.startTime;
                            return eventsResponse;
                        } else
                            return { "version": "1.0", "response": [], "totalCount": 0 };
                    } else if (host.length == 0) {
                        return { "version": "1.0", "response": [], "totalCount": 0 };
                    }
                    score = host != undefined ? host[0].healthScore[0].score : 10;
                } else {

                    return { "version": "1.0", "response": [], "totalCount": 0 };
                }
                if (score <= 3) {
                    t = JSON.parse(JSON.stringify(WirelessClientEventView_3));
                } else
                    if (score <= 6) {
                        t = JSON.parse(JSON.stringify(WirelessClientEventView_4));
                    } else if (score > 6) {
                        t = JSON.parse(JSON.stringify(WirelessClientEventView_10));
                    }

                var apDevice = SimLokiDatabaseActions.getFilteredRecordHandler('network-device', { 'id': host[0].connectedNetworkDeviceId });
                var wlcDevice = SimLokiDatabaseActions.getFilteredRecordHandler('network-device', { 'managementIpAddress': apDevice[0].associatedWlcIp });

                var apDeviceName = "", apMacAddr = "";
                var wlcDeviceName = "";
                var ipAddress = "";
                if (apDevice.length > 0 && apDevice.length > 0) {
                    apDeviceName = apDevice[0].name;
                    wlcDeviceName = wlcDevice[0].name;
                    ipAddress = wlcDevice[0].managementIpAddress;
                    apMacAddr = apDevice[0].macAddress;
                }

                if (urlAction.filter.startTime > new Date().getTime() - 100000) return { "version": "1.0", "response": [], "totalCount": 0 } //changed by ram
                return generateData(urlAction, t.Template, apDeviceName, wlcDeviceName, host, ipAddress, apMacAddr); //changed by ram

            }

        };

        function generateData(urlAction, data, apDeviceName, wlcDeviceName, host, ipAddress, apMacAddr) { //ram changes start
            /*var i = 0, timeWindow = 20;
            var recordLen = data.response.length;
            var incrementBy = 0;
            var tOrder = urlAction.filter['order'] != undefined ? urlAction.filter['order'].toLowerCase():'asc';
      
            var startDate = UtilityFunctions.getAdjustStartDateForDataGeneration(urlAction.filter['startTime'],urlAction.filter['endTime'], tOrder, timeWindow);
      
            for(; i < recordLen; i++) {
                var tTime = UtilityFunctions.incrementDateByMinutes(startDate, incrementBy);
      
                var tTimeInMs = new Date(tTime).getTime()+16*60*60*1000;
      
                data.response[i].name = data.response[i].name.replace(/[0-9]{13}$/, tTimeInMs)
      
                for(var j = 0; j < data.response[i].children.length; j++) {
                    if(data.response[i].children[j].timestamp != undefined) {
                        data.response[i].children[j].timestamp = tTimeInMs;
                    }
                }
      
                for(var k = 0; k < data.response[i].details.length; k++) {
                    if(data.response[i].details[k].key == 'startTime' || data.response[i].details[k].key == 'endTime') {
                        data.response[i].details[k].value = tTimeInMs;
                    }
                }
      
                incrementBy += timeWindow;
            }
            console.log(data)
            return data; */
            //  data.response=  data.response.reverse();

            if (host[0].subType.indexOf('Samsung') >= 0 && host[0].userId == "Grace.Smith") {
                data.response.splice(2, SpecialWirelessClientEventView.SAMSUNG_ANALYTICS_EVENTS.length, JSON.parse(JSON.stringify(SpecialWirelessClientEventView.SAMSUNG_ANALYTICS_EVENTS)));
                data.response = data.response.flat();
            }

            var hours = UtilityFunctions.getHours(urlAction.filter["startTime"], urlAction.filter["endTime"]);
            var timeWindow = 4;
            if (hours > 24) {
                timeWindow = 30;
            }
            var time = urlAction.filter['endTime'] - i * 60 * 1000; //changed by Ram for showing events in past hour
            
            if (host[0].subType.indexOf('Samsung') >= 0 && host[0].userId == "Grace.Smith") {
                time = urlAction.filter['endTime'] - 105 * 60 * 1000;
            }
            data.response.forEach(function (entry, i) {

                let eventTimeStamp = time - i * timeWindow * 60 * 1000
                function updateTimestamp(entry) {
                    entry.name = entry.name.replace(/[0-9]{13}$/, eventTimeStamp)
                        .replace(/[0-9]{13}_/, eventTimeStamp - 5000 + '_')
                    if (entry.details) entry.details.forEach(function (detail) {

                        if (detail.key == 'endTime') detail.value = JSON.stringify(eventTimeStamp)
                        if (detail.key == 'startTime') detail.value = JSON.stringify(eventTimeStamp - 5000)
                        if (wlcDeviceName != "" && apDeviceName != "") {
                            detail.value = detail.value.replace(/WLCDeviceNamePlaceHolder/g, wlcDeviceName);
                            detail.value = detail.value.replace(/APDeviceNamePlaceHolder/g, apDeviceName);
                            detail.value = detail.value.replace(/UserNamePlaceHolder/g, host[0].userId);
                            detail.value = detail.value.replace(/ssidPlaceHolder/g, host[0].ssid);
                            detail.value = detail.value.replace(/ipAddressPlaceHolder/g, ipAddress);
                            detail.value = detail.value.replace(/VlanPlaceHolder/g, host[0].vlanId);
                            detail.value = detail.value.replace(/ApMacPlaceHolder/g, apMacAddr);
                            detail.value = detail.value.replace(/HostMacPlaceHolder/g, host[0].hostMac);
                            detail.value = detail.value.replace(/ipv6PlaceHolder/g, host[0].hostIpV6[0]);                            
                        }

                    });
                    if (entry.timestamp) {
                        entry.timestamp = eventTimeStamp;
                    }

                    /* if(entry.name=="Run" || entry.name=="KeyExchange") {
           
                       entry.id= entry.id.replace(/[0-9]{13}_/, eventTimeStamp+'_');
                       console.log(entry);
                     } */

                    if (entry.children) entry.children.forEach(updateTimestamp)
                }

                // For failed events the name is appended with a space for identification in APIs 
                // where only the event name is passed and not the status
                function renameFailedEvents(entry) {
                    if (entry.resultStatus === 'CL_FAIL') { entry.eventName = entry.eventName + ' '; entry.hasAnomalyPackets = true }
                    if (entry.children) entry.children.forEach(renameFailedEvents);
                }

                updateTimestamp(entry)
                renameFailedEvents(entry)

            })

            data.response = data.response.reverse();
            return data;
        } //ram changes end

    });
