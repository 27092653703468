define({
"Template":
{
  "version": "1.0",
  "response": {
    "detailList": [
      {
        "itemType": "ALL",
        "timestamp": "2020-05-07T08:40:00.000+0000",
        "count": 539,
        "nestedItems": [
          {
            "itemType": "<0",
            "timestamp": "2020-05-07T08:40:00.000+0000",
            "count": 0
          },
          {
            "itemType": "0-4",
            "timestamp": "2020-05-07T08:40:00.000+0000",
            "count": 10
          },
          {
            "itemType": ">4-15.4",
            "timestamp": "2020-05-07T08:40:00.000+0000",
            "count": 481
          },
          {
            "itemType": ">15.4-30",
            "timestamp": "2020-05-07T08:40:00.000+0000",
            "count": 44
          },
          {
            "itemType": ">30-60",
            "timestamp": "2020-05-07T08:40:00.000+0000",
            "count": 2
          },
          {
            "itemType": ">60-90",
            "timestamp": "2020-05-07T08:40:00.000+0000",
            "count": 2
          },
          {
            "itemType": ">90",
            "timestamp": "2020-05-07T08:40:00.000+0000",
            "count": 0
          }
        ]
      }
    ]
  }
},
"Power_Usage_Template":
{
  "version": "1.0",
  "response": {
    "detailList": [
      {
        "itemType": "Total Power Budget",
        "timestamp": "2021-07-21T12:20:00.000+0000",
        "count": 2201,
        "nestedItems": [
          {
            "itemType": "Consumed Power",
            "timestamp": "2021-07-21T12:20:00.000+0000",
            "count": 129
          },
          {
            "itemType": "Remaining Power",
            "timestamp": "2021-07-21T12:20:00.000+0000",
            "count": 2072
          }
        ]
      }
    ]
  }
}
})