define({
    "Network_Orchestration_Template": {
        "id":"",
        "primarySeedDevice":"",
        "ipPoolList":[],
        "primaryInterfaceList":[],
        "status":"Initialized1",
        "action":"START",
        "discoveredDeviceList":[],
        "siteId":"",
        "creationTime":""
    },
    "Network_Orchestration_Log_Template": {
       "nwOrchId":"",
       "entry":[]
    }
});
