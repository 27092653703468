define([
     'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/database/SimLokiDatabaseActions',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/data-generation/services/assurance/SimAssuranceMetricUtility',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/data-generation/services/assurance/SimAssuranceCacheData',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/utilities/UtilityFunctions',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/assurance/pagination/PaginationBaseTemplate',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/assurance/metric1/device360/SensorListing_EnvTemp',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/assurance/metric1/device360/CpuListing_MaxCpu',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/assurance/metric1/sessionReachability',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/assurance/metric1/issue/FabricPhysicalLink_Issue',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/assurance/metric1/fabric/IfAvailabiltiy_ifavail_issue',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/assurance/metric1/fabric/IfAvailabiltiy_ifavail_page1',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/assurance/Issue_Device_Interface_Template',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/assurance/metric1/issue/clientDualBandIssues',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/assurance/metric1/device360/wlc_ap_connectivity',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/assurance/Device_Interface_Template',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/assurance/device/ConnectivityTemplate',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/interface/DeviceInterfaceTemplate',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/utilities/SimulationUtility',
    'core/../../../public/libs/node_modules/moment'    
], function(SimLokiDatabaseActions, SimAssuranceMetricUtility, SimAssuranceCacheData,
            UtilityFunctions, PaginationBaseTemplate, SensorListing_EnvTemp, CpuListing_MaxCpu,
            sessionReachability, FabricPhysicalLink_Issue, fabricIfAvailabiltiy_ifavail_issue,
            fabricIfAvailabiltiy_ifavail_page1,
            Issue_Device_Interface_Template, clientDualBandIssues, wlc_ap_connectivity,Device_Interface_Template, ConnectivityTemplate, 
            DeviceInterfaceTemplate, SimulationUtility, moment){

  return {

      init: function(){
      },

      getDeviceInterfaces: function(urlAction) {
          var dnacVersion = (SimLokiDatabaseActions.getFilteredRecordHandler('dnacConstants', {'key':'version'}))[0].value;
          // This if condition code can be deleted as it is specific for 1.3.3
          var versionCmpOutput = UtilityFunctions.versionCompare(dnacVersion, '2.1.1');
          var deviceDetail = SimLokiDatabaseActions.getFilteredRecordHandler('network-device', {'id':urlAction.action.id});
          //if(dnacVersion == '2.1.1'){
          if(deviceDetail.length > 0){

                var tJson = [], deviceInterfaceDetails = [];
                if(deviceDetail.length > 0) {
                    var duplexOper = ["AutoNegotiate", "FullDuplex", "HalfDuplex"],
                    isL3Interface = ["TRUE", "FALSE"], speed = ["10000000","1000000", "100000", "8000000", "2000000"],
                    vlanId = ["All", "0", "1"]
                    var objArr = SimLokiDatabaseActions.getAll('device-interface');
                    tJson = objArr.filter(function (itm) { return (itm.deviceId == deviceDetail[0].instanceUuid)})
                    
                    var obj = JSON.parse(JSON.stringify(DeviceInterfaceTemplate.IntefaceTemplateFor360));

                    for (var i = 0; i<tJson.length; i++){
                        obj = {};
                        obj.portMode=tJson[i].portMode;
                        obj.vlanId=vlanId[Math.floor((Math.random() * 2) + 0)];                    
                        obj.speed=speed[Math.floor((Math.random() * 4) + 0)];
                        obj.interfaceType=tJson[i].interfaceType;
                        obj.portType=tJson[i].portType;
                        obj.isL3Interface=isL3Interface[Math.floor((Math.random() * 1) + 0)];
                                            
                        if (tJson[i].interfaceType != "Virtual") {
                            obj.duplexConfig="AUTO_NEGOTIATE";
                            obj.duplexOper= duplexOper[Math.floor((Math.random() * 2) + 0)];
                            obj.mediaType="ETHERNETCSMACD";
                        } else {
                            obj.mediaType="PROPVIRTUAL";
                        }
                        obj.adminStatus="UP";
                        obj.portChannelId="0";
                        obj.name=tJson[i].portName;
                        obj.operStatus=tJson[i].status.toUpperCase();
                        obj.interfaceId=tJson[i].id;
                        deviceInterfaceDetails.push(obj)
                    }
                    if(deviceDetail[0].stackCount != undefined){
                        var platId = deviceDetail[0].platformId.split(',')
                        if(platId[0] == "C9500-16X"){
                            var resp1 = JSON.parse(JSON.stringify(DeviceInterfaceTemplate.C9500_Trunk_SVL_DAD));
                            for(var j = 0; j<resp1.length; j++){
                                deviceInterfaceDetails.push(resp1[j])
                            }
                        }else{
                            var resp = JSON.parse(JSON.stringify(DeviceInterfaceTemplate.StackPort_Template));
                            for(var j = 0; j<resp.length; j++){
                                deviceInterfaceDetails.push(resp[j])
                            }
                        }
                    }
                }
                return deviceInterfaceDetails;
          }
          var temp = urlAction.filter['q'], validQuotes = ["'",'"'], quote = "'";
          var startIndex = -1;
          for(var i=0; i<validQuotes.length && startIndex<0; i++) {
              quote = validQuotes[i];
              startIndex = temp.indexOf('has('+quote+'managementIpAddr'+quote);
          }
          startIndex = startIndex+23;
          temp = temp.substring(startIndex);
          startIndex = temp.indexOf(quote)+1;
          var endIndx = temp.indexOf(')')-1;
          var deviceIp = temp.substring(startIndex,endIndx);

          if(dnacVersion == '1.2.8') {
            // This if condition code can be deleted as it is specific for 1.2.8
            var type = "DEFAULT";
            var deviceDetail = SimLokiDatabaseActions.getFilteredRecordHandler('network-device', {'managementIpAddress':deviceIp});
            var cat9kTypes = ["cisco catalyst 9200 switch stack", "cisco catalyst 9300 switch", "cisco catalyst 9404r switch", "cisco catalyst 9500 switch"];
            if(cat9kTypes.includes(deviceDetail[0].type.toLowerCase()) > -1) {
                type = "CAT 9K";
            }
            var cachedData = SimAssuranceCacheData.getCacheData(urlAction);
            return cachedData.interfaces[type];
          } else if(dnacVersion == '1.2.10'){
            //1.2.10 uses the below format
            var deviceDetail = SimLokiDatabaseActions.getFilteredRecordHandler('network-device', {'managementIpAddress':deviceIp});
            var tJson = [], deviceInterfaceDetails = [];
            if(deviceDetail.length > 0) {
                var duplexOper = ["AutoNegotiate", "FullDuplex", "HalfDuplex"],
                isL3Interface = ["TRUE", "FALSE"], speed = ["10000000","1000000", "100000", "8000000", "2000000"],
                vlanId = ["All", "0", "1"]
                var objArr = SimLokiDatabaseActions.getAll('device-interface');
                tJson = objArr.filter(function (itm) { return (itm.deviceId == deviceDetail[0].instanceUuid)})
                
                var obj = JSON.parse(JSON.stringify(DeviceInterfaceTemplate.IntefaceTemplateFor360));

                for (var i = 0; i<tJson.length; i++){
                    obj = [{}];
                    obj[0].portMode=[tJson[i].portMode];
                    obj[0].vlanId=[vlanId[Math.floor((Math.random() * 2) + 0)]];                    
                    obj[0].speed=[speed[Math.floor((Math.random() * 4) + 0)]];
                    obj[0].interfaceType=[tJson[i].interfaceType];
                    obj[0].portType=[tJson[i].portType];
                    obj[0].isL3Interface=[isL3Interface[Math.floor((Math.random() * 1) + 0)]];
                                        
                    if (tJson[i].interfaceType != "Virtual") {
                        obj[0].duplexConfig=["AUTO_NEGOTIATE"];
                        obj[0].duplexOper= [duplexOper[Math.floor((Math.random() * 2) + 0)]];
                        obj[0].mediaType=["ETHERNETCSMACD"];
                    } else {
                        obj[0].mediaType=["PROPVIRTUAL"];
                    }

                    obj[0].adminStatus=["UP"];
                    obj[0].portChannelId=["0"];
                    obj[0].name=[tJson[i].portName];
                    obj[0].operStatus=[tJson[i].status.toUpperCase()];
                    obj[0].interfaceId=[tJson[i].id];
                    deviceInterfaceDetails.push(obj)
                }
                
                // if("distribution" == deviceDetail[0].role.toLowerCase()) {
                //     tJson = JSON.parse(JSON.stringify(ConnectivityTemplate.DISTRIBUTION));
                // } else if("core" == deviceDetail[0].role.toLowerCase()) {
                //     tJson = JSON.parse(JSON.stringify(ConnectivityTemplate.CORE));
                // } else if(["router","border router"].indexOf(deviceDetail[0].role.toLowerCase())>-1) {
                //     tJson = JSON.parse(JSON.stringify(ConnectivityTemplate.ROUTER));
                // } 
            }
            return deviceInterfaceDetails;
          }
      },

      getAPDeviceInterfaces: function(deviceId,isRadioOnly) {
        let deviceInfo, tJson, macAddr, ipAddr
        tJson = JSON.parse(JSON.stringify(Device_Interface_Template.Interface_Template))  
          if(deviceId){
            deviceInfo = SimLokiDatabaseActions.getFilteredRecordHandler('network-device',{'id':deviceId})
            macAddr = deviceInfo[0].macAddress
            ipAddr = deviceInfo[0].managementIpAddress
            tJson = tJson.map((obj,ind) => { 
                let id = (ind == 0) ? `${macAddr}_GigabitEthernet1` : 
                (ind == 1) ? `${macAddr}_GigabitEthernet0`: 
                (ind == 2) ? `${macAddr}_0`:
                (ind == 3) ? `${ipAddr}_GigabitEthernet1`:
                (ind == 4) ? `${macAddr}_1`:
                (ind == 5) ? `${macAddr}_2`: ""
                obj.id = id 
                for (const prop in obj.properties){
                    if(prop == 'macAddress'){ 
                      obj.properties[prop][0].value = `${macAddr}`
                    }
                }
                return obj              
            })
          }
          if(isRadioOnly) {
            return tJson.splice(0,1);
          } else {
            return tJson
          }
      },
      getDeviceFabricInterfaces: function (urlAction) {
          if (urlAction.filter['query'] != undefined && urlAction.filter['query'].indexOf('dedup()') >= 0) {
              return ["GigabitEthernet1/0/22", "TenGigabitEthernet1/0/23", "TenGigabitEthernet1/0/24"];
          } else {
              return FabricPhysicalLink_Issue.Template;
          }
      },

      getMetricDetail: function(urlAction) {

          if(urlAction.restPayload.name == undefined) {
              console.log('Metric is not handled, please check..');
              return [];
          }

          var tResult = handleExceptionRequest(urlAction);
          if(tResult.isHandled) {
              return tResult.data;
          }

          //e.g. {pagination:{},request:{}, records or values: [] };
          var result = {};
          var t = findPaginationDetail(urlAction);
          // name attribute to handle response format {pagination:{},request:{}, records} having field in restpayload
          var exceptionalRespKeys = ['apRadioStatsAggregation','ap_up_down_aggregation','ap_memory_threshold_aggregation',
           'ap_cpu_threshold_aggregation', 'apRadioStatsAggregationAirQuality', 'apRadioStatsAggregationInterference', 'apRadioStatsAggregationNoise',
           'wlc_mem_stats_per_device', 'wlc_count_stats_ap_count','wlc_count_stats_client_count', "snmp_cpu_per_device",
           'wlc_ap_license_exhausted_agg','snmp_memory_per_device','wlc_stats_per_device', "derivedInterfaceAvailability"]
          var fieldFlag=urlAction.restPayload.field?true:false;
          if(exceptionalRespKeys.indexOf(urlAction.restPayload.name) > -1) {
           if(fieldFlag){ 
           urlAction.restPayload.fields = [urlAction.restPayload.field];
             delete urlAction.restPayload.field;
           }
          }

          var data = getMetricDataPopulated(urlAction, t.paginationRequired, t.limit, t.timeWindow, t.offset);

          var pagination = getPaginationAttrs(urlAction, t.paginationRequired, t.totalRecords, t.limit, t.offset);

          var dataFieldNames = getDataFieldName(urlAction);

          /*temporary fix for 1.3.3, need to be removed (fix for displaying healthScore at LeftTop in device360 Page , In Restpayload we have field,which expects response
          format as {pagination:{},request:{}, records} which was handled above in exceptionalRespKeys ,
          if tyring to handle through exceptionalRespKeys the next call is getting impacted so forcefully giving records to dataFieldNames*/
          if((urlAction.restPayload.name == 'allDeviceHealthScoreAgg' && urlAction.restPayload.field == 'overallScore') || (urlAction.restPayload.name == 'tcam_util' && urlAction.restPayload.field == 'max_tcam_util' )
          || (urlAction.restPayload.name == 'derivedInterfaceAvailability' && urlAction.restPayload.field == undefined)){
            dataFieldNames = ["records"];
          }

          if(exceptionalRespKeys.indexOf(urlAction.restPayload.name) > -1) {
           if(fieldFlag){   
           urlAction.restPayload.field = urlAction.restPayload.fields[0];
             delete urlAction.restPayload.fields;
           }
          }

          var request    = getRequestAttrs(urlAction);
          result['pagination'] = pagination;
          result['request'] = request;

          for(var i = 0; i < dataFieldNames.length; i++) {
            // result[dataFieldNames[i]] = data;
              if(urlAction.restPayload.name == 'fabricIfAvailabiltiy' && urlAction.service[0] != 'ndp'){
                  result['records'] = data;
              }else{
                result[dataFieldNames[i]] = data;
              }
          }
              return result
        },
      getWLCMetricDetail : function(urlAction) {

       var t = findPaginationDetail(urlAction);
       var field = urlAction.restPayload.field

       var recordLen= t.limit;
       var tOrder = urlAction.filter['order'] != undefined ? urlAction.filter['order'].toLowerCase():'asc'; 
      var sDate=urlAction.restPayload.timeRange.start;
      var eDate=urlAction.restPayload.timeRange.end;
      var startDate = UtilityFunctions.getAdjustStartDateForDataGeneration(sDate,eDate,tOrder, t.timeWindow);
     // var startDate = getAdjustStartDateForDataGeneration(urlAction, t.timeWindow);
      var incrementBy = 0;
       incrementBy = t.offset * t.timeWindow;

       tRecordLen = recordLen > urlAction.filter['pageSize'] ? urlAction.filter['pageSize']: recordLen;
       var result ={ "name": urlAction.restPayload.name, "entityId": urlAction.restPayload.entity.managementIpAddr,"field": urlAction.restPayload.field,"window": urlAction.restPayload.window,"type": urlAction.restPayload.type,"timeRange": urlAction.restPayload.timeRange,values:[]};
       var data = SimLokiDatabaseActions.getFilteredRecordHandler('network-device',{'associatedWlcIp':urlAction.restPayload.entity.managementIpAddr});
       var min,max,base_Threshold_min,base_Threshold_max,mean_min,mean_max;

         if(urlAction.restPayload.field=="wlc_global_cpu_avg")  {
            if(urlAction.restPayload.entity.managementIpAddr == '10.30.200.4'){
                min  = 75;
                max =80;
                base_Threshold_min=82;
                base_Threshold_max=82;
                mean_min=73;
                mean_max=74;
            }else{
                min  = 15;
                max =20;
                base_Threshold_min=22;
                base_Threshold_max=22;
                mean_min=13;
                mean_max=14;
            }
         } else  if(urlAction.restPayload.field=="wlc_avg_memory_util")  {
             if(urlAction.restPayload.entity.managementIpAddr == '10.30.200.4'){
                min  = 75;
                max =80;
                base_Threshold_min=85;
                base_Threshold_max=85;
                mean_min=74;
                mean_max=75;    
             }else{
                min  = 25;
                max =30;
                base_Threshold_min=35;
                base_Threshold_max=35;
                mean_min=24;
                mean_max=25;
             }          
         } else  if(urlAction.restPayload.field=="wlc_ap_count")  {
             min  = data.length;
             max =data.length;
             base_Threshold_min=data.length+1;
             base_Threshold_max=data.length+1;
             mean_min=data.length;
             mean_max=data.length;

         } else  if(urlAction.restPayload.field=="wlc_client_count")  {

            var apDevicelistIds=[];

            for(var j=0;j<data.length;j++) {
                apDevicelistIds.push(data[j].id);
            }
            var clientList = SimLokiDatabaseActions.getFilteredRecordHandler('host',{
                                             'connectedNetworkDeviceId': { '$containsAny' :  apDevicelistIds}});
             min  = clientList.length;
             max =clientList.length;
             base_Threshold_min=clientList.length+1;
             base_Threshold_max=clientList.length+1;
             mean_min=clientList.length;
             mean_max=clientList.length;

         } else  if((urlAction.restPayload.field=="ap_radio_utilization_stats_max" ||
            urlAction.restPayload.field=="ap_radio_interference_stats_max") )  {
             var config = SimAssuranceMetricUtility.getAPConfiguration(urlAction);
             if(urlAction.restPayload.dimensions.slot=="0")  {
                 min = config.itfValue_1.min;
                 max = config.itfValue_1.max;
                 base_Threshold_min=81;
                 base_Threshold_max=81;
                 mean_min=72;
                 mean_max=74;
             } else {
                 min  = config.itfValue_2.min;
                 max =config.itfValue_2.max;
                 base_Threshold_min=23;
                 base_Threshold_max=23;
                 mean_min=15;
                 mean_max=17;
             }

         }
          for(; i < tRecordLen + t.offset; i++) {
              var curTime = UtilityFunctions.incrementDateByMinutes(startDate, incrementBy);
              result.values.push({
                                "value": UtilityFunctions.getRandomIntForRangeValues(min, max),
                                "time": curTime,
                                "mean": UtilityFunctions.getRandomIntForRangeValues(mean_min, mean_max),
                                "criticalThreshold": UtilityFunctions.getRandomIntForRangeValues(base_Threshold_min, base_Threshold_max),
                                "warningThreshold": UtilityFunctions.getRandomIntForRangeValues(base_Threshold_min, base_Threshold_max),
                                "standardDeviation": 0
                               });
              incrementBy += t.timeWindow;
        }
        return result;
      }
  };

  function findPaginationDetail(urlAction) {
      var tWindowTime = (urlAction.restPayload.window != undefined) ? urlAction.restPayload.window: "5 min";
      tWindowTime = parseInt(tWindowTime.replace(/min/,'').trim());
      tWindowTime = tWindowTime < 5 ? 5 : tWindowTime; //It seems minimum time grnularity is 5 minute

      var st = urlAction.restPayload.timeRange.start, ed = urlAction.restPayload.timeRange.end;
      var iLen = UtilityFunctions.getNumberOfRecordsNeedToGenerate(st, ed, tWindowTime);
      iLen = iLen == 0 ? 1 : iLen;//CAUTION -- check it is correct..
      var total = iLen;

//      var bPagination = urlAction.filter['pageSize'] != undefined && iLen > urlAction.filter['pageSize'] ? true:false;
       var bPagination = urlAction.filter['pageSize'] != undefined && iLen >= urlAction.filter['pageSize'] ? true:false;
      var offset = 0;

      if(bPagination) {
          var t = getPaginationAtPageGranularity(urlAction, iLen);
          iLen = t.lenForThisPage;
          offset = t.pageOffset;
      }

      return {paginationRequired: bPagination, limit: iLen, timeWindow:tWindowTime, offset:offset, totalRecords:total};
  }

  function getMetricDataPopulated(urlAction, paginationRequired, recordLen, timeWindow, offset) {

      /*if(urlAction.restPayload.dimensions != undefined) {//CAUTION -- need a proper fix when restpayload has dimension is passed..
          return [];
      }*/

      var fieldsToCheckEmptyRecord = ["avgInputUtil","avgOutputUtil","avgInputErrors","avgOutputErrors"];
      var fields = urlAction.restPayload.fields != undefined ? urlAction.restPayload.fields:[urlAction.restPayload.field];
      var tData = [], i, j, fieldLen = fields.length;
      var tOrder = urlAction.filter['order'] != undefined ? urlAction.filter['order'].toLowerCase():'asc'; 
      var sDate=urlAction.restPayload.timeRange.start;
      var eDate=urlAction.restPayload.timeRange.end;
      var startDate = UtilityFunctions.getAdjustStartDateForDataGeneration(sDate,eDate,tOrder, timeWindow);
      //var startDate = getAdjustStartDateForDataGeneration(urlAction, timeWindow);

      var incrementBy = 0;



      var filter = {};
      if(urlAction.restPayload.entity != undefined) {
          if(urlAction.restPayload.entity._id != undefined && urlAction.restPayload.entity._id == "__global__") {
              filter['siteId'] = 'global';
          }
      }

      i = offset;
      incrementBy = offset * timeWindow;

      var tOrder = urlAction.filter['order'] != undefined ? urlAction.filter['order'].toLowerCase():'asc';
    //  incrementBy = tOrder == 'desc' ? -1 * incrementBy : incrementBy;
      if(tOrder == 'desc') {
       /*  if(offset==0) {
          incrementBy = 1 * timeWindow;
         } */
          incrementBy = incrementBy * -1;
          timeWindow = timeWindow * -1;
      }

      var tRecordLen = (recordLen) > urlAction.filter['pageSize'] ? urlAction.filter['pageSize']: recordLen;   //filling gaps in health charts   
      var bClientIssueMetric = isMetricNeededForClientIssues(urlAction);
      var dnacVersion = (SimLokiDatabaseActions.getFilteredRecordHandler('dnacConstants', {'key':'version'}))[0].value;
      var versionCmpOutput = UtilityFunctions.versionCompare(dnacVersion, '1.3.3');

      var dataObj;
     // This will be moved...
      if( urlAction.restPayload.name == 'wlc_ap_connectivity') {
          var result= wlc_ap_connectivity.Template;
          result.request.entity.macAddress= urlAction.restPayload.entity.macAddress;
          result.pagination.links[0].body.entity.macAddress= urlAction.restPayload.entity.macAddress;
          return result;
      }
      if (urlAction.restPayload.name == 'sessionReachability') {
          return getSessionReachability(urlAction, timeWindow, offset,tRecordLen,startDate);
      }
      if (urlAction.restPayload.name == "baselineMLPredictionOutput") {
        return getBaselineMLPredictionOutput(urlAction, timeWindow, offset,tRecordLen,startDate);
    }
       // This code will be removed - Its temp fix for ap down.
      if(urlAction.restPayload.name == "ap_up_down_aggregation" && urlAction.restPayload.field !=undefined && urlAction.restPayload.field == 'apUpDownStatus') {
        tRecordLen =tRecordLen-1;
      }
      if (urlAction.restPayload.name == 'fabricIfAvailabiltiy' && dnacVersion == '1.3.3' ||
      urlAction.restPayload.name == 'derivedInterfaceAvailability') {
        //1.2.10 uses the below format. For 1.2.8 and below, it is handled in AssuranceMetricUtility for fieldName ifavail
        return getIfAvailDetails(urlAction, timeWindow, offset,tRecordLen,startDate);
      }
      if (urlAction.restPayload.name == 'radiometricAggregation') {
        //called from AP360 RF section - related to Wifi6
        return getRadioMetric(urlAction, timeWindow, offset,tRecordLen,startDate);
      }
      if (urlAction.restPayload.name == 'apLinkErrorAggregation') {        
        return getApLinkErrorAgg(urlAction, timeWindow, offset,tRecordLen,startDate);
      }

      dataObj = getDataSpecificToMetricCall(urlAction, bClientIssueMetric);
      for(; i < tRecordLen + offset; i++) {
          var curTime = UtilityFunctions.incrementMinutes(startDate, incrementBy);
          var obj = {};
          var metricName = urlAction.restPayload.name;

          if( metricName == 'clienthealthAggregation' && urlAction.restPayload.fields != undefined &&
              urlAction.restPayload.fields.length) {

              if(urlAction.restPayload.fields[0] == 'averageHealthScore') {
                  var randomizeAvgScore = i == 0 ? false : true;
                  var disconnectObj = {'isDeviceDisconnectHandled':false, 'isEmptySpecificTime':false, 
                    'disconnectPart':1, 'totalDisconnects':1, 'preConnectedDevices':[]};
                  obj = SimAssuranceMetricUtility.getClientHealthAggregationAvgScore(dataObj, startDate, curTime, randomizeAvgScore, disconnectObj);
              } else if(urlAction.restPayload.fields.indexOf('dnsRequestCount') >= 0) {
                  var count = UtilityFunctions.getRandomIntForRangeValues(10, 30);
                  obj = {dnsRequestCount: count, dnsResponseCount: count - 3};
              }
          /*temporary fix for 1.3.3 ,else if(urlAction.restPayload.name == 'allDeviceHealthScoreAgg' && urlAction.restPayload.field == 'overallScore')
          handles deviceHealthScore at leftTop in device360 PAGE*/
          }else if(urlAction.restPayload.name == 'allDeviceHealthScoreAgg' && urlAction.restPayload.field == 'overallScore'){
            var val = SimAssuranceMetricUtility.getData(urlAction.restPayload.field, urlAction, filter, startDate, curTime);
            obj[urlAction.restPayload.field] = val;
          }else if( metricName == 'allDeviceHealthScoreAgg' &&
                     urlAction.restPayload.fields != undefined && urlAction.restPayload.fields.length) {
              obj = SimAssuranceMetricUtility.getDeviceHealthScoreAgg(dataObj, urlAction, startDate, curTime, i);
          } else if(bClientIssueMetric) {
              obj = SimAssuranceMetricUtility.getClientMetricCountForIssues(dataObj, urlAction);
          } else if(metricName == "clientOnboardingTimes") {
             obj = SimAssuranceMetricUtility.getClientOnboardTimes(dataObj, urlAction);
          } else if(metricName == 'fabricGlobalHealthScoreAggregation' || metricName == 'globalHealthScoreAggregation' || metricName =="siteHealthScoreAggregation") {
              obj =  JSON.parse(JSON.stringify( dataObj ));

          //} else if(metricName == 'globalHealthScoreAggregation') {
          //    obj = dataObj;
          } else if(metricName == 'fabricCategoryAggregation' || metricName == 'globalCategoryAggregation' ||  metricName == "nwHealthSiteCategoryAggregation") {
              obj =  JSON.parse(JSON.stringify( dataObj ));
          } else if(metricName == 'globalKpiAggregation' || metricName == 'fabricKpiAggregation' || metricName == 'siteKpiAggregation') {
              obj =  JSON.parse(JSON.stringify( dataObj ));
          } else if(metricName == 'fabricWlcReachability') {
            obj = SimAssuranceMetricUtility.getFabricWlcSessionStatus(urlAction);
          } else if(metricName == "apRadioStatsAggregationNoise") {
            obj = SimAssuranceMetricUtility.getApNoise(urlAction);
          } else if(metricName == 'wlc_main_stats_up_down' || metricName == 'ap_up_down') {
            obj = SimAssuranceMetricUtility.getDeviceStatus(urlAction);
          }  else if(metricName == "ap_up_down_aggregation") {
            obj = SimAssuranceMetricUtility.getAPConnectivityChart(urlAction);
          } else if(metricName == "apRadioStatsAggregationTraffic" && urlAction.restPayload.fields != undefined && urlAction.restPayload.fields.indexOf("rxBytes")>=0 ) {
            obj = SimAssuranceMetricUtility.apRadioStatsAggregationTraffic(urlAction);
          }/* else if(metricName == "apRadioStatsAggregation"){
            var val = SimAssuranceMetricUtility.getData(urlAction.restPayload.field, urlAction, filter, startDate, curTime);
            obj[urlAction.restPayload.field] = val;
          }*/
          else {
              for(j = 0; j < fieldLen; j++) {
                  var val = SimAssuranceMetricUtility.getData(fields[j], urlAction, filter, startDate, curTime);
                  if(metricName =='tcam_util' && urlAction.restPayload.field == 'max_tcam_util'){
                    if(urlAction.restPayload.dimensions[0].value != 'sgacl'){
                        obj['max_tcam_util'] = val;
                      }
                      else{
                        obj['value'] = val;
                      }
                    
                  }
                 else if(urlAction.restPayload.fields == undefined) {
                     obj['value'] = val;
                  } else {
                     obj[fields[j]] = val;
                  }
              }
          }
          obj['modificationtime'] = (new Date(curTime).getTime()).toString();
          obj['time'] = curTime;
          tData.push(obj);
          incrementBy += timeWindow;
      }

      if(urlAction.restPayload.field) {
          if(fieldsToCheckEmptyRecord.indexOf(urlAction.restPayload.field)>-1 && tData[0].value==0) {
              tData = [];
          }
      } else if(urlAction.restPayload.fields) {
          if(fieldsToCheckEmptyRecord.indexOf(fields[0])>-1 && (tData[0])[fields[0]]==0) {
              tData = [];
          }
      }

      if(urlAction.restPayload.name == 'allDeviceHealthScoreAgg' && urlAction.restPayload.field == 'overallScore'){
         tData[0].time = urlAction.restPayload.timeRange.end;
      }

      /*if( urlAction.restPayload.name == 'allDeviceHealthScoreAgg' &&
                     urlAction.restPayload.fields != undefined && urlAction.restPayload.fields.length && urlAction.restPayload.window=="5 min" ) {
              tData = [];
      }*/

      return tData;
  }

  function getPaginationAttrs(urlAction, paginationRequired, totalRecords, limit, offset) {
      var t = JSON.parse(JSON.stringify(PaginationBaseTemplate.Template));
      var metricName = urlAction.restPayload.name;
      var len = 3, isThisLastPage = true;
      var curPage = 1;

      if(paginationRequired) {
          curPage = urlAction.filter['page'], curPageSize = urlAction.filter['pageSize'];
          var prevTotal = curPage > 1 ? (curPage - 1) * curPageSize : 0;
          if( totalRecords <=  prevTotal + limit) {
              len = 4;
          } else {
              len = curPage == 1 ? 4 : 5;
              isThisLastPage = false;
          }
      }

      var tBody = getRequestAttrs(urlAction);
      tBody['values'] = [];
      var bodyStr = JSON.stringify(tBody);

      for(var i = 0; i < len; i++) {
        var pageTemplate = {"rel":"","url":"http://queryengine.ndp.svc.cluster.local:8006","method":"POST","body":""};
        var tUrl = pageTemplate.url + "/api/ndp/v1/data/entities/metrics?page=";
        var order = urlAction.filter['order'] != undefined ? urlAction.filter['order']: "";
        order = order != "" ? "&order="+order:"";
        var tRel;

        if(i == 0) {
            tUrl = tUrl + curPage + "&pageSize=" + urlAction.filter['pageSize'] + order;
            tRel = "self";
        } else if(i == 1) {
            tUrl = tUrl + "1" + "&pageSize=" + urlAction.filter['pageSize'] + order;
            tRel = "first";
        } else if(i == 2) {
            if(paginationRequired == false) {
                tUrl = tUrl + curPage + "&pageSize=" + urlAction.filter['pageSize'] + order;
                tRel = "last";
            } else {
                if(isThisLastPage == false) {
                    var tPage = curPage == 1 ? 2 : curPage - 1;
                    tUrl = tUrl + tPage + "&pageSize=" + urlAction.filter['pageSize'] + order;
                    tRel = curPage == 1 ? "next" : "prev";
                } else {
	            tUrl = tUrl + curPage + "&pageSize=" + urlAction.filter['pageSize'] + order;
                    tRel = "last";
                }
            }
        } else if(i == 3) {
            if(curPage == 1) {
                tRel = "last";
                var tTotalPage = Math.floor(totalRecords / urlAction.filter['pageSize']);
                var tRemainingPage = totalRecords % urlAction.filter['pageSize'] > 0 ? 1 : 0;
                tTotalPage += tRemainingPage;
                tUrl = tUrl + tTotalPage + "&pageSize=" + urlAction.filter['pageSize'] + order;
            }
            else if(isThisLastPage == false && paginationRequired) {
                var nextPage = curPage+1;
                tUrl = tUrl + nextPage + "&pageSize=" + urlAction.filter['pageSize'] + order;
                tRel = "next";
            } else {
                continue;
            }
        } else if(i == 4) {
            var tTotalPage = Math.floor(totalRecords / urlAction.filter['pageSize']);
            var tRemainingPage = totalRecords % urlAction.filter['pageSize'] > 0 ? 1 : 0;
            tTotalPage += tRemainingPage;

            tUrl = tUrl + tTotalPage + "&pageSize=" + urlAction.filter['pageSize'] + order;
            tRel = "last";
        }

        pageTemplate.rel = tRel;
        pageTemplate.url = tUrl;
        pageTemplate.body = bodyStr;

        t.links.push(pageTemplate);
      }

      t.page = urlAction.filter['page'];
      t.pageSize = urlAction.filter['pageSize'];
      t.totalResults = totalRecords;

      return t;
  }

  function getRequestAttrs(urlAction) {
      var tRequest = {};
      for (var property in urlAction.restPayload) {
          //some fields e.g. fields , dimension has to be ignored..
          if (urlAction.restPayload.hasOwnProperty(property)) {
              tRequest[property] = urlAction.restPayload[property];
          }
      }
      return tRequest;
  }

  function getAdjustStartDateForDataGeneration(urlAction, timeWindow) {
      var tOrder = urlAction.filter['order'] != undefined ? urlAction.filter['order'].toLowerCase():'asc';

      var stDate = tOrder == 'desc' ? urlAction.restPayload.timeRange.end : urlAction.restPayload.timeRange.start;
      //var stDate = tOrder == 'desc' ? urlAction.restPayload.timeRange.start : urlAction.restPayload.timeRange.end;
      var dt = new Date(stDate), tMinute = dt.getUTCMinutes(), changedTime, adjustedMinute;

      if(tMinute > 55) {
          changedTime = new Date( dt.setUTCHours(dt.getUTCHours() + 1));
          changedTime.setUTCMinutes(0);
      } else {
          if(tMinute % timeWindow!=0){
          if(timeWindow > tMinute)
              adjustedMinute = -1 * (tMinute % timeWindow);
          else
              adjustedMinute =  timeWindow - tMinute;

          tMinute = tMinute + adjustedMinute;
        }
          changedTime = new Date( dt.setUTCMinutes(tMinute));
      }

      console.log(changedTime);
      console.log(stDate);


      return changedTime;
  }

  function getDataFieldName(urlAction) {
      /*if(urlAction.restPayload != undefined && urlAction.restPayload.entity != undefined) {
          if(urlAction.restPayload.entity._type != undefined &&
                urlAction.restPayload.entity._type == 'NetworkDevice' && urlAction.restPayload.entity._id != undefined
            ) {
              if(urlAction.restPayload.field != undefined && urlAction.restPayload.field == 'overallScore') {
                  return ["records", "values"];
              }
          }
      }*/

      return urlAction.restPayload.fields != undefined ? ["records"]:["values"];
  }

  function getPaginationAtPageGranularity(urlAction, totalLen) {
      var pageSize = urlAction.filter['pageSize'];
      var pageNo = urlAction.filter['page'];
      var len = pageSize;

      var prevTotal = pageNo > 1 ? (pageNo - 1) * pageSize : 0;
      var tLen = totalLen - prevTotal;
      if(tLen <= pageSize) {
          len = tLen;
      }

      return { lenForThisPage: len, pageOffset: prevTotal+1};
  }

  function packStaticMetricDataWithCurrentDateAndTime(urlAction, metricData) {
      var tWindowTime = urlAction.restPayload.window;
      tWindowTime = parseInt(tWindowTime.replace(/min/,'').trim());
      var incrementBy = tWindowTime;
      var tOrder = urlAction.filter['order'] != undefined ? urlAction.filter['order'].toLowerCase():'asc'; 
      var sDate=urlAction.restPayload.timeRange.start;
      var eDate=urlAction.restPayload.timeRange.end;
      var startDate = UtilityFunctions.getAdjustStartDateForDataGeneration(sDate,eDate,tOrder, tWindowTime);
      var len, arrayName = "";
      if(metricData.records != undefined && Array.isArray(metricData.records)) {
          len = metricData.records.length;
          arrayName = "records";
      } else if(metricData.values != undefined && Array.isArray(metricData.values)) {
          len = metricData.values.length;
          arrayName = "values";
      } else {
          return metricData;
      }

      for(var i = 0; i < len; i++) {
          metricData[arrayName][i].time = UtilityFunctions.incrementDateByMinutes(startDate, incrementBy);
          //obj['time'] = UtilityFunctions.incrementDateByMinutes(startDate, incrementBy);
          incrementBy += tWindowTime;
      }

      return metricData;
  }

  function handleExceptionRequest(urlAction) {
      var data = "", handled = false, tData;

      if( urlAction.restPayload.name == 'wlc_ap_connectivity') {
          //FIXME -- Caution need to delete quick worak aorund put here need to be deleted.. -- Swarup
          return wlc_ap_connectivity.Template;
      }

      if(urlAction.restPayload.dimensions == undefined || ! Array.isArray(urlAction.restPayload.dimensions) ) {
          return { isHandled: handled, data: data};
      }

      var fieldName = "";
      if(urlAction.restPayload.fields != undefined && urlAction.restPayload.fields.length){
          fieldName = urlAction.restPayload.fields[0];
      }

      if(fieldName == 'max_cpu') {
          var name1 = 'o_cpuIndex', name2 = 'o_cpuName';
      } else if(fieldName == 'envtemp_fn') {
          var name1 = 'o_sensorName', name2 = 'o_switchInstance';
      }

      if( (urlAction.restPayload.dimensions.length >= 1 &&
           urlAction.restPayload.dimensions[0].name == name1) &&
           ( urlAction.restPayload.dimensions.length >= 2 &&
             urlAction.restPayload.dimensions[1].name == name2) ) {
          if(fieldName == 'envtemp_fn') {
              handled = true;
              data = JSON.parse(JSON.stringify(SensorListing_EnvTemp.Template));
          }
          else if(fieldName == 'max_cpu') {
              handled = true;
              data = JSON.parse(JSON.stringify(CpuListing_MaxCpu.Template));
              var modifyBaseCpu = modifyBaseCpuName(urlAction.restPayload.entity);
              if(modifyBaseCpu!=undefined) {
                  for(var i=0; i<data.records.length; i++) {
                      if(data.records[i].o_cpuName == "c38xx Stack") {
                        data.records[i].o_cpuName = modifyBaseCpu;
                        break;
                      }
                  }
              }
          }
      }

      //if (urlAction.restPayload.name == 'sessionReachability') {
      //    handled = true;
          //data = packStaticMetricDataWithCurrentDateAndTime(urlAction, sessionReachability.MetricData);
      //}
      if (urlAction.restPayload.name == 'clientDualBandIssues') {
          handled = true;
          data = packStaticMetricDataWithCurrentDateAndTime(urlAction, clientDualBandIssues.MetricData);
      }

      return { isHandled: handled, data: data};
  }

  function isMetricNeededForClientIssues(urlAction) {
       var supportedIssue = ['clientOnboardingAttempts','clientOnboardingAttemptsSiteGroup30Min', 'onboarding30MinFailExcessive', 'clientOnboardingAttemptsWlc30Min'];
       var i = supportedIssue.indexOf(urlAction.restPayload.name);
       return i >= 0 ? true : false;
  }

  function getDataSpecificToMetricCall(urlAction, bClientIssueMetric) {
      var dataObj;
      var metricName = urlAction.restPayload.name;
      if( (metricName == 'clienthealthAggregation' && urlAction.restPayload.fields != undefined &&
          urlAction.restPayload.fields.length) || bClientIssueMetric) {
          var id = urlAction.restPayload.entity._id;
          id = id.replace(/_/g,':');
          dataObj = SimLokiDatabaseActions.getFilteredRecordHandler('host', {'id' : id });
          //CAUTION -- Just a hack
          window.currentClientMacId = id;
      }
      else if( metricName == 'allDeviceHealthScoreAgg' && urlAction.restPayload.fields != undefined &&
              urlAction.restPayload.fields.length) {
          if(urlAction.restPayload.entity != undefined) {
              var filter = {};
              if(urlAction.restPayload.entity.macAddress != undefined) {
                  filter = { key: 'macAddress', value: urlAction.restPayload.entity.macAddress.toLowerCase()};
              } else {
                  var objs = { managementIpAddr:{'managementIpAddr':'managementIpAddress'},"_id":{ '_id': 'id'}, "uuid":{'uuid':"id"} };
                  Object.keys(objs).forEach(function(key) {
                      if(urlAction.restPayload.entity[key] != undefined) {
                          filter = { key: objs[key][key], value: urlAction.restPayload.entity[key]};
                      }
                  });
              }

              var cachedSummaryData = SimAssuranceCacheData.getCacheData(urlAction);
              dataObj = cachedSummaryData.network_devices.filter(function(itm){ return itm[filter.key] == filter.value });
          }
      }
      else if(metricName == 'fabricGlobalHealthScoreAggregation' || metricName == 'globalHealthScoreAggregation' ||  metricName =="siteHealthScoreAggregation") {
          //dataObj = SimAssuranceMetricUtility.getFabricGlobalHealthScoreAggregation(urlAction);
          dataObj = SimAssuranceMetricUtility.getHealthScoreAggregation(urlAction);
      } else if(metricName == 'fabricCategoryAggregation' || metricName == 'globalCategoryAggregation'|| metricName == "nwHealthSiteCategoryAggregation") {
          dataObj = SimAssuranceMetricUtility.getCategoryAggregation(urlAction);
      } else if(metricName == 'globalKpiAggregation' || metricName == 'fabricKpiAggregation' || metricName== 'siteKpiAggregation') {
          dataObj = SimAssuranceMetricUtility.getKpiAggregation(urlAction);
      }
      /*else if(metricName == 'globalHealthScoreAggregation') {
          dataObj = SimAssuranceMetricUtility.getGlobalHealthScoreAggregation(urlAction);
      }*/

      return dataObj;
  }

  function getSessionReachability(urlAction, timeWindow, offset, tRecordLen, startDate) {
     /* var  data = JSON.parse(JSON.stringify(sessionReachability.MetricData));
      if(urlAction.restPayload.dimensions != undefined ||  Array.isArray(urlAction.restPayload.dimensions) ) {
          if(urlAction.restPayload.dimensions[0].name == "sessionIdentifier") {
              urlAction.restPayload.fields.push("sessionIdentifier");
              for (var i=0;i<data.length;i++) {
                 if(urlAction.restPayload.dimensions[0].value !=undefined) {
                  data[i].sessionIdentifier=urlAction.restPayload.dimensions[0].value;
                  } else {
                   data[i].sessionIdentifier="10.16.31.21_10.16.81.86_Ise";
                   data[i].reachabilitySessionStatus=0;
                  }
              }
          }
      }
      var j = offset;
      incrementBy = offset * timeWindow;
      tData = [];
     console.log(j);
      if(j ==0 ) {
       j=1;
       incrementBy = 1 * timeWindow;
      }
      for(; j < tRecordLen + offset; j++) {
          var curTime = UtilityFunctions.incrementDateByMinutes(startDate, incrementBy);

          data[j-1]['time'] = curTime;
          incrementBy += timeWindow;
          tData.push(data[j-1]);
      } */

      var j = offset;
      incrementBy = offset * timeWindow;
      var tData = [];

      if(j ==0 ) {
       j=1;
       incrementBy = 1 * timeWindow;
      }

      if(urlAction.restPayload.dimensions != undefined ||  Array.isArray(urlAction.restPayload.dimensions) ) {
          for(; j < tRecordLen + offset; j++) {
              var curTime = UtilityFunctions.incrementDateByMinutes(startDate, incrementBy);


              incrementBy += timeWindow;
              if(urlAction.restPayload.dimensions[0].value !=undefined) {
                      tData.push({"time":curTime,"reachabilitySessionStatus":1.0,"sessionIdentifier":urlAction.restPayload.dimensions[0].value});
              } else {
                 tData.push({"time":curTime,"reachabilitySessionStatus":0,"sessionIdentifier": "10.16.31.21_10.16.81.86_Ise"});
              }

          }
     }
      return tData;
   }

   function getBaselineMLPredictionOutput(urlAction, timeWindow, offset, tRecordLen, startDate) {
        var j = offset;
        incrementBy = offset * timeWindow;
        var tData = [];

        if(j ==0 ) {
        j=1;
        incrementBy = 1 * timeWindow;
        }
        if(urlAction.restPayload.dimensions != undefined ||  Array.isArray(urlAction.restPayload.dimensions) ) {
        for(; j < tRecordLen + offset; j++) {
        var curTime = UtilityFunctions.incrementDateByMinutes(startDate, incrementBy);
        incrementBy += timeWindow;
        if(urlAction.restPayload.dimensions[0].value !=undefined) {
            let predictedHigh=UtilityFunctions.getRandomIntForRangeValues(240,250);
            let actual=UtilityFunctions.getRandomIntForRangeValues(230,240);
            let predictedLow=UtilityFunctions.getRandomIntForRangeValues(220,230);
                tData.push({"time":curTime,"predictedHigh":predictedHigh,"actual":actual,'predictedLow':predictedLow});
        } 
        }
        }
        return tData;
        }

        function getIfAvailDetails(urlAction, timeWindow, offset, tRecordLen, startDate) {
        //handled seperately to avoid the onetime checks happen repeatedly
        var j = offset, value=0, ifName='', tData = [],format = "YYYY-MM-DDTHH:mm:ss.SSSZ";
        incrementBy = offset*timeWindow;              
        if(j==0 ) {
            j=1;
            incrementBy = 1*timeWindow;
        }
        var timeWindowMills = j*timeWindow*60*1000
        var startTimeInMills = Date.parse(startDate, format);
        if(urlAction.restPayload.entity._id) {
            var deviceDetail = SimLokiDatabaseActions.getFilteredRecordHandler('network-device', {'id':urlAction.restPayload.entity._id});
            var objArr = SimLokiDatabaseActions.getAll('device-interface');
            var ifList = objArr.filter(function (itm) { return (itm.deviceId == deviceDetail[0].instanceUuid)})
            
            // var ifList = JSON.parse(JSON.stringify(ConnectivityTemplate.ACCESS));
            // if(deviceDetail.length > 0) {
            //     if("distribution" == deviceDetail[0].role.toLowerCase()) {
            //         ifList = JSON.parse(JSON.stringify(ConnectivityTemplate.DISTRIBUTION));
            //     } else if("core" == deviceDetail[0].role.toLowerCase()) {
            //         ifList = JSON.parse(JSON.stringify(ConnectivityTemplate.CORE));
            //     } else if(["router","border router"].indexOf(deviceDetail[0].role.toLowerCase())>-1) {
            //         ifList = JSON.parse(JSON.stringify(ConnectivityTemplate.ROUTER));
            //     } 
            // }
        }
        if(urlAction.restPayload.dimensions.length>0 && urlAction.restPayload.dimensions[0].name=='o_interfaceName') {
            ifName = urlAction.restPayload.dimensions[0].value;
        }
        for(var i=0; i<ifList.length; i++) {
            if(ifList[i].portName==ifName) {
                value = ifList[i].adminStatus == "UP"?100:0;
                break;
            }
        }
        for(; j < tRecordLen + offset; j++) {
            // var curTime = UtilityFunctions.incrementDateByMinutes(startDate, incrementBy);
            // incrementBy += timeWindow;
            var mTime = startTimeInMills.toString();
            tData.push({"availability":value.toString(),"modificationtime":mTime});
            startTimeInMills += timeWindowMills;
        }
        return tData;
    }

    function getRadioMetric(urlAction, timeWindow, offset, tRecordLen, startDate) {
        //handled seperately to avoid the onetime checks happen repeatedly and modficationtime to be added in each obj
        var avgVal = { "voAteNonax":20, "voAteAx":28, "vdAteNonax":41, "vdAteAx":50, "beAteNonax":51, "beAteAx":69,
            "bgAteNonax":15, "bgAteAx":24, "ateNonax":34, "ateAx":40, 
            "voLatNonax":32, "voLatAx":25,"vdLatNonax":45,"vdLatAx":30,"beLatNonax":20,"beLatAx":15,"bgLatNonax":35,"bgLatAx":25}
        var fields = urlAction.restPayload.fields;
        var reqSlot = -1, devSlot = -1, isEligible = false, format = "YYYY-MM-DDTHH:mm:ss.SSSZZ";
        var j = offset, tData = [], wifiField, nonWifiField, isWifi6Enabled = false;
        j = j==0 ? 1 : j;
        var timeWindowMills = j*timeWindow*60*1000;//ms
        var startTimeInMills = Date.parse(startDate, format);

        if(urlAction.restPayload.entity.macAddress) {
            var deviceDetail = SimLokiDatabaseActions.getFilteredRecordHandler('network-device', 
                {'macAddress':urlAction.restPayload.entity.macAddress.toLowerCase()});
            if(deviceDetail.length>0) devSlot = parseInt(deviceDetail[0].slot_wifi6);
            isWifi6Enabled = SimulationUtility.validateWifi6Config(deviceDetail[0].family, deviceDetail[0].softwareVersion,
                deviceDetail[0].platformId);
        }
        if(urlAction.restPayload.dimensions.length>0 && urlAction.restPayload.dimensions[0].name=='slot') {
            reqSlot = urlAction.restPayload.dimensions[0].value;
        }
        if(reqSlot==0 && (devSlot==2 || devSlot==0)) {
            isEligible = true;
        } else if(reqSlot==1 && (devSlot==2 || devSlot==1)) {
            isEligible = true;
        }
        if(fields.length < 2) {
            isEligible = false
        } else {
            if(fields[0].includes('Non')) {
                wifiField = fields[1],nonWifiField = fields[0];
            } else {
                wifiField = fields[0],nonWifiField = fields[1];
            }
        }

        var tObj = {"modificationtime":"1654675800000","slot":"0","adminState":"0","time":"2022-06-08T08:10:00.000+0000","operState":"NaN", "channels":"11",};
        for(; j < tRecordLen+offset; j++) {
            tObj = JSON.parse(JSON.stringify(tObj));
            if(isEligible) {
                if(isWifi6Enabled) {
                    tObj[wifiField] = UtilityFunctions.getRandomFloatForRangeValues(avgVal[wifiField]-2, avgVal[wifiField]+2, 3);
                } else {
                    tObj[wifiField] = "0";
                }
                tObj[nonWifiField] = UtilityFunctions.getRandomFloatForRangeValues(avgVal[nonWifiField]-4, avgVal[nonWifiField]+4, 3);
            } else {
                if(!isWifi6Enabled && devSlot == -1) {
                    tObj[wifiField] = "null";
                    tObj[nonWifiField] = "null";
                } else {
                    tObj[wifiField] = "0";
                    tObj[nonWifiField] = "0";
                }
            }
            tObj.time = moment(startTimeInMills).utc().format(format);
            tObj.channelUtilPct= UtilityFunctions.getRandomIntForRangeValues(15,20);
            tObj.radioTrafficUtil= UtilityFunctions.getRandomIntForRangeValues(25,35);
            tObj.rxUtilPct= UtilityFunctions.getRandomIntForRangeValues(20,30);
            tObj.txPower= UtilityFunctions.getRandomIntForRangeValues(15,20);
            tObj.txUtilPct= UtilityFunctions.getRandomIntForRangeValues(5,10);
            tObj.radioUtil=UtilityFunctions.getRandomIntForRangeValues(80,90);
            tObj.radioIntf= UtilityFunctions.getRandomIntForRangeValues(70,80);
            tObj.modificationtime = startTimeInMills.toString();
            startTimeInMills += timeWindowMills;
            tData.push(tObj);
        }
        return tData;
    }

    function modifyBaseCpuName(field) {
        var deviceIp = field.managementIpAddr, id = field.uuid;
        var filter = deviceIp!=undefined ? {'managementIpAddress':deviceIp} : {'id':id};
        var deviceDetail = SimLokiDatabaseActions.getFilteredRecordHandler('network-device', filter);
        var modifyBaseCpu = undefined;
        var type = deviceDetail[0].type.toLowerCase();
        if(type == "cisco catalyst 9200 switch stack") {
            modifyBaseCpu = "c92xx Stack";
        } else if(type == "cisco catalyst 9300 switch") {
            modifyBaseCpu = "c93xx Stack";
        } else if(type == "cisco catalyst 9404r switch") {
            modifyBaseCpu = "c94xx Stack";
        } else if(type == "cisco catalyst 9500 switch") {
            modifyBaseCpu = "c95xx Stack";
        } else if(type == "cisco catalyst 9606r switch") {
            modifyBaseCpu = "c96xx Stack";
        } else if(type == "cisco catalyst 36xx stack-able ethernet switch") {
            modifyBaseCpu = "c36xx Stack";
        }
        return modifyBaseCpu;
    }

    function getApLinkErrorAgg(urlAction, timeWindow, offset, tRecordLen, startDate){
        let fields = urlAction.restPayload.fields
        let intfName = urlAction.restPayload.dimensions[0].value
        let macAddress = urlAction.restPayload.entity.macAddress
        let deviceInfo,healthScore
        if(macAddress){
          deviceInfo = SimLokiDatabaseActions.getFilteredRecordHandler('network-device', {'macAddress':macAddress.toLowerCase()});
          healthScore = deviceInfo[0].assuranceHealthScore
        }
        let format = "YYYY-MM-DDTHH:mm:ss.SSSZZ";
        let j = offset, tData = []
        j = j==0 ? 1 : j;
        let timeWindowMills = j*timeWindow*60*1000;
        let startTimeInMills = Date.parse(startDate, format)
        let tObj = {"modificationtime":"","interfaceName":intfName,"time":""}
        fields.forEach(field => {
            tObj[field] = "0"
        }) //append filelds to obj to frame response
        for(; j < tRecordLen+offset; j++) {
            tObj = JSON.parse(JSON.stringify(tObj));
            if(tObj.rxRate != undefined ){
                let rxRate = UtilityFunctions.getRandomFloatForRangeValues(400,900)
                let txRate = UtilityFunctions.getRandomFloatForRangeValues(7000,16000)
                let totalRate = txRate + rxRate
                tObj.rxRate = `${rxRate}`
                tObj.txRate = `${txRate}`
                tObj.totalRate = `${totalRate}`
            }
            if(tObj.rxUtilization != undefined ){
                let rxUtil,txUtil
                if(healthScore>3){
                    rxUtil = UtilityFunctions.getRandomIntForRangeValues(0.5,1) //(0,2)
                    txUtil  = UtilityFunctions.getRandomIntForRangeValues(0,0.5)
                }else {
                    rxUtil = UtilityFunctions.getRandomFloatForRangeValues(0.5,2)-0
                    txUtil  = UtilityFunctions.getRandomFloatForRangeValues(0,1.5)-0
                }
                let totalUtilization =  (rxUtil) + (txUtil)
                tObj.rxUtilization = `${rxUtil}`
                tObj.txUtilization = `${txUtil}`
                tObj.totalUtilization= `${totalUtilization}`
            }
            if(tObj.rxErrors != undefined ){
                let rxErrors,txErrors
                if(healthScore<3){
                    rxErrors = UtilityFunctions.getRandomIntForRangeValues(0,1)
                    txErrors  = UtilityFunctions.getRandomIntForRangeValues(0,0.5)
                }else {
                    rxErrors = 0
                    txErrors  = 0
                }
                let utilization = rxErrors + txErrors
                tObj.txErrorsAbs = `${txErrors}`
                tObj.rxErrorsAbs = `${rxErrors}`
                tObj.utilizationAbs = `${utilization}`
                tObj.txErrors = `${txErrors}`
                tObj.rxErrors = `${rxErrors}`
                tObj.utilization= `${utilization}`
            }
            tObj.time = moment(startTimeInMills).utc().format(format);
            tObj.modificationtime = startTimeInMills.toString();
            startTimeInMills += timeWindowMills;
            tData.push(tObj);
        }
        return tData
    }


});
