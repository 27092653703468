define([
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/database/SimLokiDatabaseActions',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/data-generation/services/SimNetworkDeviceData',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/data-generation/configuration/SimTaskDataGenerator',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/data-generation/services/SimDiscoveryData',
    ], function (SimLokiDatabaseActions, SimNetworkDeviceData, SimTaskDataGenerator, SimDiscoveryData) {

        return {
            init: function() {
            },

            doProcessRestRequest: function(urlAction){
                var data;
                switch(urlAction.method) {
                    case 'GET':
                    data = processGetRequest(urlAction);
                    break;

                    case 'POST':
                    data = processPostRequest(urlAction);
                    break;

                    case 'PUT':
                    data = processPutRequest(urlAction);
                    break;

                    case 'DELETE':
                    data = processDeleteRequest(urlAction);
                    break;

                    default:
                    break;
                }

                return data;
            }
        };

    function processGetRequest(urlAction){

        //api/v1/inventory/global-settings?propertyName=device_controllability
        if(urlAction.service.indexOf('inventory') > -1) {
            if(urlAction.service.indexOf('global-settings') > -1 && urlAction.filter['propertyName'] && 
              urlAction.filter['propertyName'] == 'device_controllability' ) {
                return 1;
            }
            return [];
        } 

        if(urlAction.service.indexOf('global-credential') >= 0){
            return SimDiscoveryData.getCredential(urlAction);
        }
        if(urlAction.service.indexOf('job') >= 0){
            return SimDiscoveryData.getJobDetail(urlAction);
        }

        var obj = SimDiscoveryData.getDiscoveryDetail(urlAction);

        if(urlAction.url.indexOf('summary') >= 0) {
            return SimDiscoveryData.getDiscoveredDevices(urlAction);
        }

        if(urlAction.service.indexOf('network-device') >= 0){
            return SimDiscoveryData.getDiscoveredDevices(urlAction);
        }

        //Request URL:https://171.71.186.69/api/v1/discovery/330/filter?recordsToReturn=25&startIndex=1&sortBy=pingStatus&sortOrder=asc
        if(urlAction.service.indexOf('filter') >= 0){
            return SimDiscoveryData.getDiscoveredDevices(urlAction);
        }

        //if(urlAction.service.indexOf('discovery') >= 0){
        if(urlAction.action.count)
        {
            if(urlAction.service.indexOf('alltype') > -1) {
                return SimDiscoveryData.getCount(obj, 'discoveryType');
            } else if(urlAction.service.indexOf('allstatus') > -1) {
                return SimDiscoveryData.getCount(obj, 'discoveryCondition');
            }
            var data = obj!= undefined ? obj.length:0;
            return updateResponse(data);
        }

        if((urlAction.filter["autoComplete"] != undefined && urlAction.filter["autoComplete"]=="true") || 
          urlAction.service.indexOf('autoComplete') > -1 ){
            if(urlAction.filter['ipAddress']) {
                var ipObjList=[];
                if(obj!=undefined) {
                    for(var i=0; i<obj.length; i++) {
                        var ipList = obj[i].ipAddressList;
                        var ipRange = ipList.split(' ');
                        for(var range=0; range<ipRange.length; range++) {
                            var ipStart = ipRange[range].split('-')[0];
                            var ipEnd = ipRange[range].split('-')[1];
                            var templateIp = ipStart.split('.')[0]+"."+ipStart.split('.')[1]+"."+ipStart.split('.')[2]+".";
                            for(var octet=ipStart.split('.')[3]; octet<=ipEnd.split('.')[3]; octet++) {
                                var createIp = templateIp+octet;
                                if (ipObjList.indexOf(createIp) < 0) {
                                    ipObjList.push(createIp);
                                }
                            }
                        }
                    }
                    return updateResponse(ipObjList);
                }
            } else if(urlAction.filter['pingStatus'] || urlAction.filter['snmpStatus'] || urlAction.filter['cliStatus']) {
                var tData = ["SUCCESS"];
                return updateResponse(tData);
            } else if(urlAction.filter['httpStatus'] || urlAction.filter['netconfStatus']) {
                var tData = ["NOT-PROVIDED"];
                return updateResponse(tData);
            }
        }

        //api/v1/discovery/1/1 - num1-num2 range of list is to be returned. Latest first.
        if(urlAction.service.length>2) {
            var num1 = urlAction.service[1], num2 = urlAction.service[2];
            if( !(isNaN(num1) || isNaN(num1)) ) { //num1 & num2 are numbers
                num2 = parseInt(num2), response = [];
                //var length = num2<obj.length ? num2 : obj.length;
                if(num2<obj.length) {
                    for(var i=0; i<num2; i++) {
                        response.push(obj[i]);
                    }
                    return updateResponse(response);
                } else {
                    return updateResponse(obj);
                }
            }
        } else if(urlAction.service.length <= 2){
            var resp = obj.filter(discovery => {
                return discovery.id == parseInt(urlAction.service[1]);
            })
            return updateResponse(resp[0]);
        }

        return updateResponse(obj);

    }

    function processPostRequest(urlAction){
        //var resultObj = SimTaskDataGenerator.createTask('Inventory service');
        var resultObj, taskResponse;
        if(urlAction.service.indexOf('global-credential') >= 0){
            resultObj = SimTaskDataGenerator.createTask('Inventory service');
            taskResponse = SimDiscoveryData.postCredential(urlAction);
        }
        else{
            taskResponse = SimDiscoveryData.createDiscovery(urlAction.restPayload);
            resultObj = SimTaskDataGenerator.createTask('Inventory service', {'data':
                {"discoveryId":taskResponse.discoveryid.toString(),
                "note":"For further details on the discovery job use discoveryId and jobTaskId.",
                "jobTaskId":taskResponse.taskId.toString(),"status":"Success"},
                "progress": "Discovery updated successfully"
            });
        }
        SimTaskDataGenerator.updateTaskCompletion(resultObj.taskId, taskResponse);
        
        resultObj =  updateResponse(resultObj);
        resultObj.id = resultObj.response.taskId;//adding based on UI code. Not sure of expected response.
        return resultObj;
    }

    function processPutRequest(urlAction){

       var resultObj = SimTaskDataGenerator.createTask('Inventory service');
        var taskResponse = SimDiscoveryData.putDiscovery(urlAction);
        SimTaskDataGenerator.updateTaskCompletion(resultObj.taskId, taskResponse);
        return updateResponse(resultObj);
    }

    function  processDeleteRequest(urlAction){
        var resultObj = SimTaskDataGenerator.createTask('SPFService');
        SimDiscoveryData.deleteDiscovery(urlAction);
        SimTaskDataGenerator.updateTaskCompletion(resultObj.taskId, {isError: false });
        return updateResponse(resultObj);
    }

    function updateResponse(data) {
        return {"response":data,"version":"1.0"};
    }

});
