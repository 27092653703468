define({
    "PLATFORM_ID_LIST": [
        "2801SRST-U-HSEC/K9",
        "2911-AIRCVTR-NEBS",
        "2911-FANFLTR-NEBS",
        "2D-C6800IA-48TD",
        "867VAE-W-E-K9",
        "9010AC3X24TRKITC",
        "9010ACV2-440SE-BUN",
        "9010DC3X24TRKITC",
        "9010DCV2-440SE-BUN",
        "A901-12C-F-D",
        "A901-12C-F-D-CC",
        "A901-12C-FT-D",
        "A901-12C-FT-D-CC",
        "A901-6CZ-F-A",
        "A901-6CZ-F-A-RF",
        "A901-6CZ-F-A-RL",
        "A901-6CZ-F-A-WS",
        "A901-6CZ-F-D-CC",
        "A901-6CZ-FS-A-RF",
        "A901-6CZ-FS-A-WS",
        "A901-6CZ-FT-A",
        "A901-6CZ-FT-A-RF",
        "A901-6CZ-FT-A-WS",
        "A901S-2SG-F-AH",
        "A901S-2SG-F-AH-RF",
        "A901S-2SG-F-D",
        "A901S-2SG-F-D-RF",
        "A901S-3SG-F-AH",
        "A901S-3SG-F-D",
        "A901S-3SG-F-D-RF",
        "A901S-3SG-F-D-WS",
        "A901S-4SG-F-D",
        "A901S-4SG-F-D-RF",
        "A901S-4SG-F-D-WS",
        "A903-BUN-2R1A-8S-1",
        "A903-BUN-IOT",
        "A903-BUN-R1A-2XZGE",
        "A903-BUN-R1A-8S-1",
        "A903-BUN-R1A-8S-2",
        "A903-BUN-R1A-GE-D",
        "A903-BUN-R1A-XGE02",
        "A903-BUN-R1B-2XZGE",
        "A9K-3KW-AC-WS",
        "A9K-48X10GE-TEL-BN",
        "A9K-9006-UPGRADE",
        "A9K-9010-UPGRADE",
        "A9K-9912-UPGRADE",
        "A9K-9922-UPGRADE",
        "A9K-AC-V3-UPGRADE",
        "A9K-DC-V3-UPGRADE",
        "ACS-1841-RM-19",
        "ACS-1941-RM-19",
        "ACS-2801-ENV",
        "ACS-2801-STAN",
        "ACS-2821-51-ENV",
        "ACS-2821-51-STAN",
        "AIR-1800I-A-K9",
        "AIR-1800I-A-K9C",
        "AIR-1800I-B-K9",
        "AIR-1800I-B-K9C",
        "AIR-1800I-D-K9",
        "AIR-1800I-D-K9C",
        "AIR-1800I-E-K9",
        "AIR-1800I-E-K9C",
        "AIR-1800I-F-K9",
        "AIR-1800I-F-K9C",
        "AIR-AP1542D-A-K9",
        "AIR-AP1542D-B-K9",
        "AIR-AP1542D-D-K9",
        "AIR-AP1542D-E-K9",
        "AIR-AP1542D-F-K9",
        "AIR-AP1542D-G-K9",
        "AIR-AP1542D-H-K9",
        "AIR-AP1542D-I-K9",
        "AIR-AP1542D-K-K9",
        "AIR-AP1542D-M-K9",
        "AIR-AP1542D-N-K9",
        "AIR-AP1542D-Q-K9",
        "AIR-AP1542D-R-K9",
        "AIR-AP1542D-S-K9",
        "AIR-AP1542D-T-K9",
        "AIR-AP1542I-A-K9",
        "AIR-AP1542I-D-K9",
        "AIR-AP1542I-F-K9",
        "AIR-AP1542I-G-K9",
        "AIR-AP1542I-H-K9",
        "AIR-AP1542I-I-K9",
        "AIR-AP1542I-K-K9",
        "AIR-AP1542I-M-K9",
        "AIR-AP1542I-N-K9",
        "AIR-AP1542I-Q-K9",
        "AIR-AP1542I-R-K9",
        "AIR-AP1542I-S-K9",
        "AIR-AP1542I-T-K9",
        "AIR-AP1602e-UXK9",
        "AIR-AP1602i-UXK9",
        "AIR-AP1810W-A-K9",
        "AIR-AP1810W-B-K9",
        "AIR-AP1810W-C-K9",
        "AIR-AP1810W-D-K9",
        "AIR-AP1810W-E-K9",
        "AIR-AP1810W-F-K9",
        "AIR-AP1810W-G-K9",
        "AIR-AP1810W-H-K9",
        "AIR-AP1810W-I-K9",
        "AIR-AP1810W-K-K9",
        "AIR-AP1810W-N-K9",
        "AIR-AP1810W-Q-K9",
        "AIR-AP1810W-R-K9",
        "AIR-AP1810W-S-K9",
        "AIR-AP1810W-T-K9",
        "AIR-AP1810W-UXK9",
        "AIR-AP1810W-Z-K9",
        "AIR-AP1815I-A-K9",
        "AIR-AP1815I-B-K9",
        "AIR-AP1815I-C-K9",
        "AIR-AP1815I-D-K9",
        "AIR-AP1815I-E-K9",
        "AIR-AP1815I-F-K9",
        "AIR-AP1815I-G-K9",
        "AIR-AP1815I-H-K9",
        "AIR-AP1815I-I-K9",
        "AIR-AP1815I-K-K9",
        "AIR-AP1815I-N-K9",
        "AIR-AP1815I-Q-K9",
        "AIR-AP1815I-R-K9",
        "AIR-AP1815I-S-K9",
        "AIR-AP1815I-T-K9",
        "AIR-AP1815I-UXK9",
        "AIR-AP1815I-Z-K9",
        "AIR-AP1815M-B-K9",
        "AIR-AP1815W-B-K9",
        "AIR-AP1832I-A-K9",
        "AIR-AP1832I-B-K9",
        "AIR-AP1832I-C-K9",
        "AIR-AP1832I-D-K9",
        "AIR-AP1832I-E-K9",
        "AIR-AP1832I-F-K9",
        "AIR-AP1832I-H-K9",
        "AIR-AP1832I-I-K9",
        "AIR-AP1832I-K-K9",
        "AIR-AP1832I-N-K9",
        "AIR-AP1832I-Q-K9",
        "AIR-AP1832I-R-K9",
        "AIR-AP1832I-S-K9",
        "AIR-AP1832I-T-K9",
        "AIR-AP1832I-UXK9",
        "AIR-AP1832I-Z-K9",
        "AIR-AP1840-B-K9",
        "AIR-AP1840I-B-K9",
        "AIR-AP1852E-A-K9",
        "AIR-AP1852E-B-K9",
        "AIR-AP1852E-C-K9",
        "AIR-AP1852E-D-K9",
        "AIR-AP1852E-E-K9",
        "AIR-AP1852E-F-K9",
        "AIR-AP1852E-H-K9",
        "AIR-AP1852E-I-K9",
        "AIR-AP1852E-K-K9",
        "AIR-AP1852E-N-K9",
        "AIR-AP1852E-Q-K9",
        "AIR-AP1852E-R-K9",
        "AIR-AP1852E-R-K9C",
        "AIR-AP1852E-S-K9",
        "AIR-AP1852E-T-K9",
        "AIR-AP1852E-T-K9C",
        "AIR-AP1852E-UXK9",
        "AIR-AP1852E-Z-K9",
        "AIR-AP1852I-A-K9",
        "AIR-AP1852I-B-K9",
        "AIR-AP1852I-B-K9C",
        "AIR-AP1852I-C-K9",
        "AIR-AP1852I-D-K9",
        "AIR-AP1852I-E-K9",
        "AIR-AP1852I-E-K9C",
        "AIR-AP1852I-F-K9",
        "AIR-AP1852I-G-K9",
        "AIR-AP1852I-G-K9C",
        "AIR-AP1852I-H-K9",
        "AIR-AP1852I-I-K9",
        "AIR-AP1852I-K-K9",
        "AIR-AP1852I-N-K9",
        "AIR-AP1852I-Q-K9",
        "AIR-AP1852I-Q-K9C",
        "AIR-AP1852I-R-K9",
        "AIR-AP1852I-R-K9C",
        "AIR-AP1852I-S-K9",
        "AIR-AP1852I-T-K9",
        "AIR-AP1852I-UXK9",
        "AIR-AP1852I-Z-K9",
        "AIR-AP2602e-UXK9",
        "AIR-AP2602i-UXK9",
        "AIR-AP2702e-A-K9",
        "AIR-AP2702e-B-K9",
        "AIR-AP2702e-C-K9",
        "AIR-AP2702e-D-K9",
        "AIR-AP2702e-E-K9",
        "AIR-AP2702e-F-K9",
        "AIR-AP2702e-H-K9",
        "AIR-AP2702e-I-K9",
        "AIR-AP2702e-K-K9",
        "AIR-AP2702e-N-K9",
        "AIR-AP2702e-Q-K9",
        "AIR-AP2702e-R-K9",
        "AIR-AP2702e-S-K9",
        "AIR-AP2702e-UXK9",
        "AIR-AP2702e-Z-K9",
        "AIR-AP2702i-A-K9",
        "AIR-AP2702i-B-K9",
        "AIR-AP2702i-C-K9",
        "AIR-AP2702i-E-K9",
        "AIR-AP2702i-F-K9",
        "AIR-AP2702i-I-K9",
        "AIR-AP2702i-K-K9",
        "AIR-AP2702i-R-K9",
        "AIR-AP2702i-S-K9",
        "AIR-AP2702i-UXK9",
        "AIR-AP2702i-Z-K9",
        "AIR-AP2802E-A-K9",
        "AIR-AP2802E-B-K9",
        "AIR-AP2802E-C-K9",
        "AIR-AP2802E-D-K9",
        "AIR-AP2802E-E-K9",
        "AIR-AP2802E-F-K9",
        "AIR-AP2802E-G-K9",
        "AIR-AP2802E-H-K9",
        "AIR-AP2802E-I-K9",
        "AIR-AP2802E-K-K9",
        "AIR-AP2802E-N-K9",
        "AIR-AP2802E-Q-K9",
        "AIR-AP2802E-R-K9",
        "AIR-AP2802E-S-K9",
        "AIR-AP2802E-T-K9",
        "AIR-AP2802E-UXK9",
        "AIR-AP2802E-Z-K9",
        "AIR-AP2802I-A-K9",
        "AIR-AP2802I-B-K9",
        "AIR-AP2802I-C-K9",
        "AIR-AP2802I-D-K9",
        "AIR-AP2802I-E-K9",
        "AIR-AP2802I-F-K9",
        "AIR-AP2802I-G-K9",
        "AIR-AP2802I-H-K9",
        "AIR-AP2802I-I-K9",
        "AIR-AP2802I-K-K9",
        "AIR-AP2802I-N-K9",
        "AIR-AP2802I-Q-K9",
        "AIR-AP2802I-R-K9",
        "AIR-AP2802I-S-K9",
        "AIR-AP2802I-T-K9",
        "AIR-AP2802I-UXK9",
        "AIR-AP2802I-Z-K9",
        "AIR-AP3602e-UXK9",
        "AIR-AP3602i-UXK9",
        "AIR-AP3702e-A-K9",
        "AIR-AP3702e-B-K9",
        "AIR-AP3702e-C-K9",
        "AIR-AP3702e-D-K9",
        "AIR-AP3702e-E-K9",
        "AIR-AP3702e-F-K9",
        "AIR-AP3702e-H-K9",
        "AIR-AP3702e-I-K9",
        "AIR-AP3702e-K-K9",
        "AIR-AP3702e-N-K9",
        "AIR-AP3702e-Q-K9",
        "AIR-AP3702e-R-K9",
        "AIR-AP3702e-S-K9",
        "AIR-AP3702e-T-K9",
        "AIR-AP3702e-UXK9",
        "AIR-AP3702e-Z-K9",
        "AIR-AP3702i-A-K9",
        "AIR-AP3702i-B-K9",
        "AIR-AP3702i-C-K9",
        "AIR-AP3702i-D-K9",
        "AIR-AP3702i-E-K9",
        "AIR-AP3702i-F-K9",
        "AIR-AP3702i-H-K9",
        "AIR-AP3702i-I-K9",
        "AIR-AP3702i-K-K9",
        "AIR-AP3702i-N-K9",
        "AIR-AP3702i-Q-K9",
        "AIR-AP3702i-R-K9",
        "AIR-AP3702i-S-K9",
        "AIR-AP3702i-T-K9",
        "AIR-AP3702i-UXK9",
        "AIR-AP3702i-Z-K9",
        "AIR-AP3802E-A-K9",
        "AIR-AP3802E-B-K9",
        "AIR-AP3802E-C-K9",
        "AIR-AP3802E-D-K9",
        "AIR-AP3802E-E-K9",
        "AIR-AP3802E-F-K9",
        "AIR-AP3802E-G-K9",
        "AIR-AP3802E-H-K9",
        "AIR-AP3802E-I-K9",
        "AIR-AP3802E-K-K9",
        "AIR-AP3802E-N-K9",
        "AIR-AP3802E-Q-K9",
        "AIR-AP3802E-R-K9",
        "AIR-AP3802E-S-K9",
        "AIR-AP3802E-T-K9",
        "AIR-AP3802E-UXK9",
        "AIR-AP3802E-Z-K9",
        "AIR-AP3802I-A-K9",
        "AIR-AP3802I-B-K9",
        "AIR-AP3802I-C-K9",
        "AIR-AP3802I-D-K9",
        "AIR-AP3802I-E-K9",
        "AIR-AP3802I-F-K9",
        "AIR-AP3802I-G-K9",
        "AIR-AP3802I-H-K9",
        "AIR-AP3802I-I-K9",
        "AIR-AP3802I-K-K9",
        "AIR-AP3802I-N-K9",
        "AIR-AP3802I-Q-K9",
        "AIR-AP3802I-R-K9",
        "AIR-AP3802I-S-K9",
        "AIR-AP3802I-T-K9",
        "AIR-AP3802I-UXK9",
        "AIR-AP3802I-Z-K9",
        "AIR-AP3802P-G-K9",
        "AIR-AP4800-B-K9",
        "AIR-AP702i-UXK9",
        "AIR-CAP1602e-A-K9",
        "AIR-CAP1602e-B-K9",
        "AIR-CAP1602e-C-K9",
        "AIR-CAP1602e-E-K9",
        "AIR-CAP1602e-F-K9",
        "AIR-CAP1602e-H-K9",
        "AIR-CAP1602e-I-K9",
        "AIR-CAP1602e-K-K9",
        "AIR-CAP1602e-N-K9",
        "AIR-CAP1602e-Q-K9",
        "AIR-CAP1602e-R-K9",
        "AIR-CAP1602e-S-K9",
        "AIR-CAP1602e-T-K9",
        "AIR-CAP1602e-Z-K9",
        "AIR-CAP1602i-A-K9",
        "AIR-CAP1602i-B-K9",
        "AIR-CAP1602i-C-K9",
        "AIR-CAP1602i-E-K9",
        "AIR-CAP1602i-F-K9",
        "AIR-CAP1602i-H-K9",
        "AIR-CAP1602i-I-K9",
        "AIR-CAP1602i-K-K9",
        "AIR-CAP1602i-N-K9",
        "AIR-CAP1602i-Q-K9",
        "AIR-CAP1602i-R-K9",
        "AIR-CAP1602i-S-K9",
        "AIR-CAP1602i-T-K9",
        "AIR-CAP1602i-Z-K9",
        "AIR-CAP1702i-A-K9",
        "AIR-CAP1702i-B-K9",
        "AIR-CAP1702i-C-K9",
        "AIR-CAP1702i-D-K9",
        "AIR-CAP1702i-E-K9",
        "AIR-CAP1702i-F-K9",
        "AIR-CAP1702i-H-K9",
        "AIR-CAP1702i-I-K9",
        "AIR-CAP1702i-K-K9",
        "AIR-CAP1702i-N-K9",
        "AIR-CAP1702i-Q-K9",
        "AIR-CAP1702i-R-K9",
        "AIR-CAP1702i-S-K9",
        "AIR-CAP1702i-T-K9",
        "AIR-CAP1702i-Z-K9",
        "AIR-CAP2602e-A-K9",
        "AIR-CAP2602e-B-K9",
        "AIR-CAP2602e-C-K9",
        "AIR-CAP2602e-E-K9",
        "AIR-CAP2602e-F-K9",
        "AIR-CAP2602e-H-K9",
        "AIR-CAP2602e-I-K9",
        "AIR-CAP2602e-K-K9",
        "AIR-CAP2602e-N-K9",
        "AIR-CAP2602e-Q-K9",
        "AIR-CAP2602e-R-K9",
        "AIR-CAP2602e-S-K9",
        "AIR-CAP2602e-T-K9",
        "AIR-CAP2602i-A-K9",
        "AIR-CAP2602i-B-K9",
        "AIR-CAP2602i-C-K9",
        "AIR-CAP2602i-E-K9",
        "AIR-CAP2602i-F-K9",
        "AIR-CAP2602i-H-K9",
        "AIR-CAP2602i-I-K9",
        "AIR-CAP2602i-K-K9",
        "AIR-CAP2602i-N-K9",
        "AIR-CAP2602i-Q-K9",
        "AIR-CAP2602i-R-K9",
        "AIR-CAP2602i-S-K9",
        "AIR-CAP2602i-T-K9",
        "AIR-CAP2702e-A-K9",
        "AIR-CAP2702e-B-K9",
        "AIR-CAP2702e-C-K9",
        "AIR-CAP2702e-D-KAIR-AP2702i-D-K9",
        "AIR-CAP2702e-E-K9",
        "AIR-CAP2702e-F-K9",
        "AIR-CAP2702e-H-KAIR-AP2702i-H-K9",
        "AIR-CAP2702e-I-K9",
        "AIR-CAP2702e-K-K9",
        "AIR-CAP2702e-N-KAIR-AP2702i-N-K9",
        "AIR-CAP2702e-Q-KAIR-AP2702i-Q-K9",
        "AIR-CAP2702e-R-K9",
        "AIR-CAP2702e-S-K9",
        "AIR-CAP2702e-Z-K9",
        "AIR-CAP2702i-A-K9",
        "AIR-CAP2702i-B-K9",
        "AIR-CAP2702i-C-K9",
        "AIR-CAP2702i-D-K9",
        "AIR-CAP2702i-E-K9",
        "AIR-CAP2702i-F-K9",
        "AIR-CAP2702i-H-K9",
        "AIR-CAP2702i-I-K9",
        "AIR-CAP2702i-K-K9",
        "AIR-CAP2702i-N-K9",
        "AIR-CAP2702i-Q-K9",
        "AIR-CAP2702i-R-K9",
        "AIR-CAP2702i-S-K9",
        "AIR-CAP2702i-Z-K9",
        "AIR-CAP3602P-A-K9",
        "AIR-CAP3602e-A-K9",
        "AIR-CAP3602e-B-K9",
        "AIR-CAP3602e-C-K9",
        "AIR-CAP3602e-E-K9",
        "AIR-CAP3602e-F-K9",
        "AIR-CAP3602e-H-K9",
        "AIR-CAP3602e-I-K9",
        "AIR-CAP3602e-K-K9",
        "AIR-CAP3602e-N-K9",
        "AIR-CAP3602e-Q-K9",
        "AIR-CAP3602e-R-K9",
        "AIR-CAP3602e-S-K9",
        "AIR-CAP3602e-T-K9",
        "AIR-CAP3602e-Z-K9",
        "AIR-CAP3602i-A-K9",
        "AIR-CAP3602i-B-K9",
        "AIR-CAP3602i-C-K9",
        "AIR-CAP3602i-E-K9",
        "AIR-CAP3602i-F-K9",
        "AIR-CAP3602i-H-K9",
        "AIR-CAP3602i-I-K9",
        "AIR-CAP3602i-K-K9",
        "AIR-CAP3602i-N-K9",
        "AIR-CAP3602i-Q-K9",
        "AIR-CAP3602i-R-K9",
        "AIR-CAP3602i-S-K9",
        "AIR-CAP3602i-T-K9",
        "AIR-CAP3602i-Z-K9",
        "AIR-CAP3702P-A-K9",
        "AIR-CAP3702P-B-K9",
        "AIR-CAP3702P-C-K9",
        "AIR-CAP3702P-D-K9",
        "AIR-CAP3702P-E-K9",
        "AIR-CAP3702P-F-K9",
        "AIR-CAP3702P-H-K9",
        "AIR-CAP3702P-I-K9",
        "AIR-CAP3702P-K-K9",
        "AIR-CAP3702P-N-K9",
        "AIR-CAP3702P-Q-K9",
        "AIR-CAP3702P-R-K9",
        "AIR-CAP3702P-S-K9",
        "AIR-CAP3702P-T-K9",
        "AIR-CAP3702P-Z-K9",
        "AIR-CAP3702e-A-K9",
        "AIR-CAP3702e-B-K9",
        "AIR-CAP3702e-C-K9",
        "AIR-CAP3702e-D-K9",
        "AIR-CAP3702e-E-K9",
        "AIR-CAP3702e-F-K9",
        "AIR-CAP3702e-H-K9",
        "AIR-CAP3702e-I-K9",
        "AIR-CAP3702e-K-K9",
        "AIR-CAP3702e-N-K9",
        "AIR-CAP3702e-Q-K9",
        "AIR-CAP3702e-R-K9",
        "AIR-CAP3702e-S-K9",
        "AIR-CAP3702e-T-K9",
        "AIR-CAP3702e-Z-K9",
        "AIR-CAP3702i-A-K9",
        "AIR-CAP3702i-B-K9",
        "AIR-CAP3702i-C-K9",
        "AIR-CAP3702i-D-K9",
        "AIR-CAP3702i-E-K9",
        "AIR-CAP3702i-F-K9",
        "AIR-CAP3702i-H-K9",
        "AIR-CAP3702i-I-K9",
        "AIR-CAP3702i-K-K9",
        "AIR-CAP3702i-N-K9",
        "AIR-CAP3702i-Q-K9",
        "AIR-CAP3702i-R-K9",
        "AIR-CAP3702i-S-K9",
        "AIR-CAP3702i-T-K9",
        "AIR-CAP3702i-Z-K9",
        "AIR-CAP702i-A-K9",
        "AIR-CAP702i-B-K9",
        "AIR-CAP702i-C-K9",
        "AIR-CAP702i-E-K9",
        "AIR-CAP702i-F-K9",
        "AIR-CAP702i-H-K9",
        "AIR-CAP702i-I-K9",
        "AIR-CAP702i-K-K9",
        "AIR-CAP702i-N-K9",
        "AIR-CAP702i-Q-K9",
        "AIR-CAP702i-R-K9",
        "AIR-CAP702i-S-K9",
        "AIR-CAP702i-T-K9",
        "AIR-CAP702i-Z-K9",
        "AIR-CAP702w-A-K9",
        "AIR-CAP702w-B-K9",
        "AIR-CAP702w-C-K9",
        "AIR-CAP702w-E-K9",
        "AIR-CAP702w-F-K9",
        "AIR-CAP702w-H-K9",
        "AIR-CAP702w-I-K9",
        "AIR-CAP702w-K-K9",
        "AIR-CAP702w-N-K9",
        "AIR-CAP702w-Q-K9",
        "AIR-CAP702w-R-K9",
        "AIR-CAP702w-S-K9",
        "AIR-CAP702w-T-K9",
        "AIR-CAP702w-Z-K9",
        "AIR-CT100-1140A30",
        "AIR-CT100-1140E30",
        "AIR-CT12-1140E5",
        "AIR-CT25-1140A10",
        "AIR-CT25-1140E10",
        "AIR-CT50-1140E20",
        "AIR-CT5508-100-K9",
        "AIR-CT5508-100K9++",
        "AIR-CT5508-12-K9",
        "AIR-CT5508-12-K9++",
        "AIR-CT5508-12K9-RF",
        "AIR-CT5508-12K9-WS",
        "AIR-CT5508-25-K9",
        "AIR-CT5508-25-K9++",
        "AIR-CT5508-250-2PK",
        "AIR-CT5508-250-K9",
        "AIR-CT5508-250K9++",
        "AIR-CT5508-25K9-RF",
        "AIR-CT5508-25K9-WS",
        "AIR-CT5508-50-K9",
        "AIR-CT5508-50-K9++",
        "AIR-CT5508-500-2PK",
        "AIR-CT5508-500-K9",
        "AIR-CT5508-500K9++",
        "AIR-CT5508-50K9-RF",
        "AIR-CT5508-50K9-WS",
        "AIR-CT5508-CA-K9",
        "AIR-CT5508-CA-K9++",
        "AIR-CT5508-CALPEK9",
        "AIR-CT5508-HA-K9",
        "AIR-CT5508-HA-K9++",
        "AIR-CT5508100K9-WS",
        "AIR-CT5508CA-K9-WS",
        "AIR-CT5508H-100-K9",
        "AIR-CT5508H-250-K9",
        "AIR-CT5508H-50-K9",
        "AIR-CT5508H-500-2X",
        "AIR-CT5508H-500-K9",
        "AIR-CT5508H-HA-K9",
        "AIR-CT5508HA-K9-RF",
        "AIR-CT5508HA-K9-WS",
        "AIR-CT5760",
        "AIR-CT5760-00-K9",
        "AIR-CT5760-10-K9",
        "AIR-CT5760-100-K9",
        "AIR-CT5760-1K-K9",
        "AIR-CT5760-1KK9-RF",
        "AIR-CT5760-25-K9",
        "AIR-CT5760-250-K9",
        "AIR-CT5760-25K9-RF",
        "AIR-CT5760-25K9-WS",
        "AIR-CT5760-50-K9",
        "AIR-CT5760-500-K9",
        "AIR-CT5760-50K9-RF",
        "AIR-CT5760-CA-K9",
        "AIR-CT5760-CA-K9++",
        "AIR-CT5760-CAK9-RF",
        "AIR-CT5760-CALPEK9",
        "AIR-CT5760-HA-K9",
        "AIR-CT5760-HAK9-WS",
        "AIR-CT5760-K9",
        "AIR-CT5760-RK-MNT",
        "AIR-CT5760100K9-RF",
        "AIR-CT5760100K9-WS",
        "AIR-CT5760250K9-RF",
        "AIR-CT5760500K9-RF",
        "AIR-CT5760HA-K9-RF",
        "AIR-CT6-1042C3-K9",
        "AIR-CT6-1042E3-K9",
        "AIR-CT6-1042I3-K9",
        "AIR-CT6-1042K3-K9",
        "AIR-CT6-1042N3-K9",
        "AIR-CT6-1042P3-K9",
        "AIR-OEAP1810-A-K9",
        "AIR-OEAP1810-B-K9",
        "AIR-OEAP1810-C-K9",
        "AIR-OEAP1810-D-K9",
        "AIR-OEAP1810-E-K9",
        "AIR-OEAP1810-F-K9",
        "AIR-OEAP1810-G-K9",
        "AIR-OEAP1810-H-K9",
        "AIR-OEAP1810-I-K9",
        "AIR-OEAP1810-K-K9",
        "AIR-OEAP1810-N-K9",
        "AIR-OEAP1810-Q-K9",
        "AIR-OEAP1810-R-K9",
        "AIR-OEAP1810-S-K9",
        "AIR-OEAP1810-T-K9",
        "AIR-OEAP1810-UXK9",
        "AIR-OEAP1810-Z-K9",
        "AIR-WLC2106-K9",
        "AIR-WLC2106-K9-RF",
        "AIR-WLC2106-K9-WS",
        "AIR-WLC2106-PAK=",
        "AIR-WLC3750-PAK=",
        "AIR-WLC4402-12-K9",
        "AIR-WLC4402-25-C4K",
        "AIR-WLC4402-25-K9",
        "AIR-WLC4402-50-C4K",
        "AIR-WLC4402-50-K9",
        "AIR-WLC440212K9-RF",
        "AIR-WLC440225K9-RF",
        "AIR-WLC440250K9-RF",
        "AIR-WLC4404-100-K9",
        "AIRCT5508-250K9-RF",
        "AIRCT5508-250K9-WS",
        "AIRCT5508-500K9-RF",
        "AIRWLC4402-12K9-WS",
        "AIRWLC4404100K9-RF",
        "AM-A9K-9010-D-BUN",
        "ASA5506",
        "ASA5506H",
        "ASA5506W",
        "ASA5508",
        "ASA5508sc",
        "ASA5515",
        "ASA5515K7",
        "ASA5515K7sc",
        "ASA5515sc",
        "ASA5516",
        "ASA5525",
        "ASA5525K7",
        "ASA5525K7sc",
        "ASA5525sc",
        "ASA5545",
        "ASA5545sc",
        "ASA5555",
        "ASA5555sc",
        "ASR-9001",
        "ASR-9001-S",
        "ASR-9006-AC",
        "ASR-9006-AC-BUN",
        "ASR-9006-AC-SE-BUN",
        "ASR-9006-AC-SE-MIG",
        "ASR-9006-AC-TR-BUN",
        "ASR-9006-AC-TR-MIG",
        "ASR-9006-AC-V2",
        "ASR-9006-AC-WS",
        "ASR-9006-DC",
        "ASR-9006-DC-BUN",
        "ASR-9006-DC-SE-BUN",
        "ASR-9006-DC-SE-MIG",
        "ASR-9006-DC-TR-BUN",
        "ASR-9006-DC-TR-MIG",
        "ASR-9006-DC-V2",
        "ASR-9006-SYS",
        "ASR-9006-SYS-RF",
        "ASR-9006-SYS-WS",
        "ASR-9010",
        "ASR-9010-AC",
        "ASR-9010-AC-BUN",
        "ASR-9010-AC-SE-BUN",
        "ASR-9010-AC-SE-MIG",
        "ASR-9010-AC-TR-BUN",
        "ASR-9010-AC-TR-MIG",
        "ASR-9010-AC-V2",
        "ASR-9010-AC-V2-RF",
        "ASR-9010-AC-V2-WS",
        "ASR-9010-DC",
        "ASR-9010-DC-BUN",
        "ASR-9010-DC-SE-BUN",
        "ASR-9010-DC-SE-MIG",
        "ASR-9010-DC-TR-BUN",
        "ASR-9010-DC-TR-MIG",
        "ASR-9010-DC-V2",
        "ASR-9010-OF-BUN",
        "ASR-9010-SYS",
        "ASR-9010-SYS-RF",
        "ASR-9010-SYS-WS",
        "ASR-9010-TH-BUN",
        "ASR-902",
        "ASR-902-RF",
        "ASR-902U",
        "ASR-903",
        "ASR-903++",
        "ASR-903-CG",
        "ASR-903-IOT",
        "ASR-903U",
        "ASR-907",
        "ASR-907++",
        "ASR-914",
        "ASR-920-10SZ-PD",
        "ASR-920-12CZ-A-WS",
        "ASR-920-12CZ-D",
        "ASR-920-12SZ-IM",
        "ASR-920-12SZ-IM++",
        "ASR-920-12SZ-IM-CC",
        "ASR-920-12SZ-IM-RF",
        "ASR-920-12SZ-IM-WS",
        "ASR-920-24SZ-IM",
        "ASR-920-24SZ-IM-RF",
        "ASR-920-24SZ-IM-WS",
        "ASR-920-24SZ-M",
        "ASR-920-24SZ-M++",
        "ASR-920-24SZ-M-WS",
        "ASR-920-24TZ-M-WS",
        "ASR-920-4SZ-A",
        "ASR-920-4SZ-A-10G",
        "ASR-920-4SZ-A-1G",
        "ASR-920-4SZ-D",
        "ASR-920-8SZ-OAD",
        "ASR-920-8SZ-OD",
        "ASR-920U-12SZ-IM",
        "ASR-9904",
        "ASR-9904-AC",
        "ASR-9904-AC-SE-MIG",
        "ASR-9904-AC-TR-MIG",
        "ASR-9904-DC",
        "ASR-9904-DC-SE-MIG",
        "ASR-9904-DC-TR-MIG",
        "ASR-9904-RF",
        "ASR-9904-WS",
        "ASR-9912",
        "ASR-9912-AC",
        "ASR-9912-AC-BUN",
        "ASR-9912-AC-SE-MIG",
        "ASR-9912-AC-TR-MIG",
        "ASR-9912-DC",
        "ASR-9912-DC-BUN",
        "ASR-9912-DC-MBH",
        "ASR-9912-DC-SE-MIG",
        "ASR-9912-DC-TR-MIG",
        "ASR-9912-TH-BUN",
        "ASR-9922",
        "ASR-9922-AC",
        "ASR-9922-AC-BUN",
        "ASR-9922-AC-WS",
        "ASR-9922-DC",
        "ASR-9922-DC-BUN",
        "ASR-9922-RF",
        "ASR-9922-TH-BUN",
        "ASR1001",
        "ASR1001-2.5G-SECK9",
        "ASR1001-2.5G-VPNK9",
        "ASR1001-2XOC3POS",
        "ASR1001-4X1GE",
        "ASR1001-4X1GE-RF",
        "ASR1001-4X1GE-WS",
        "ASR1001-4XT3",
        "ASR1001-4XT3-RF",
        "ASR1001-5G-AES-AX",
        "ASR1001-5G-AIS-AX",
        "ASR1001-5G-AX",
        "ASR1001-5G-SECK9",
        "ASR1001-5G-VPNK9",
        "ASR1001-8XCHT1E1",
        "ASR1001-BB4K",
        "ASR1001-HDD",
        "ASR1001-HX",
        "ASR1001-HX-4GE",
        "ASR1001-HX-WS",
        "ASR1001-RF",
        "ASR1001-WS",
        "ASR1001-X",
        "ASR1001-X-DNA",
        "ASR1001-XA",
        "ASR10012XOC3POS-RF",
        "ASR1001=",
        "ASR1001X-10G-K9",
        "ASR1001X-10G-SEC",
        "ASR1001X-10G-VPN",
        "ASR1001X-2.5G-K9",
        "ASR1001X-2.5G-SEC",
        "ASR1001X-2.5G-VPN",
        "ASR1001X-2.5GK9-RF",
        "ASR1001X-20G-K9",
        "ASR1001X-20G-K9-RF",
        "ASR1001X-20G-K9-WS",
        "ASR1001X-20G-SEC",
        "ASR1001X-20G-VPN",
        "ASR1001X-20GVPN-RF",
        "ASR1001X-20GVPN-WS",
        "ASR1001X-25GSEC-WS",
        "ASR1001X-25GVPN-WS",
        "ASR1001X-5G-K9",
        "ASR1001X-5G-K9-RF",
        "ASR1001X-5G-SEC",
        "ASR1001X-5G-VPN",
        "ASR1001X-AES-AX",
        "ASR1001X-AIS-AX",
        "ASR1002",
        "ASR1002-10G-FPI/K9",
        "ASR1002-10G-HA/K9",
        "ASR1002-10G-SEC/K9",
        "ASR1002-10G-SHA/K9",
        "ASR1002-10G-VPN/K9",
        "ASR1002-10G/K9",
        "ASR1002-10G/K9-WS",
        "ASR1002-10GHAK9-WS",
        "ASR1002-2GB",
        "ASR1002-5G-B16/K9",
        "ASR1002-5G-BB16",
        "ASR1002-5G-FPI/K9",
        "ASR1002-5G-HA/K9",
        "ASR1002-5G-SEC/K9",
        "ASR1002-5G-SHA/K9",
        "ASR1002-5G-VPN/K9",
        "ASR1002-5G/K9",
        "ASR1002-5G/K9-RF",
        "ASR1002-BB4K",
        "ASR1002-EM",
        "ASR1002-ESP5",
        "ASR1002-ESP5-RF",
        "ASR1002-F",
        "ASR1002-F-RF",
        "ASR1002-F-WS",
        "ASR1002-HX",
        "ASR1002-HX-DNA",
        "ASR1002-RF",
        "ASR1002-SB",
        "ASR1002-WS",
        "ASR1002-X",
        "ASR1002-X-RF",
        "ASR1002-X=",
        "ASR100210GHA/K9-RF",
        "ASR100210GSECK9-RF",
        "ASR100210GVPNK9-RF",
        "ASR1002=",
        "ASR1002F-SEC/K9",
        "ASR1002F-SHA/K9",
        "ASR1002F-VPN/K9",
        "ASR1002HX-6GE-2TE",
        "ASR1002X-10G-HA-K9",
        "ASR1002X-10G-K9",
        "ASR1002X-10G-K9-RF",
        "ASR1002X-10G-K9-WS",
        "ASR1002X-10G-SECK9",
        "ASR1002X-10G-SHAK9",
        "ASR1002X-10G-VPNK9",
        "ASR1002X-20G-HA-K9",
        "ASR1002X-20G-K9",
        "ASR1002X-20G-SECK9",
        "ASR1002X-20G-SHAK9",
        "ASR1002X-20G-VPNK9",
        "ASR1002X-36G-HA-K9",
        "ASR1002X-36G-K9",
        "ASR1002X-36G-NB",
        "ASR1002X-36G-SECK9",
        "ASR1002X-36G-SHAK9",
        "ASR1002X-36G-VPNK9",
        "ASR1002X-5G-HA-K9",
        "ASR1002X-5G-K9",
        "ASR1002X-5G-K9-WS",
        "ASR1002X-5G-SECK9",
        "ASR1002X-5G-SHAK9",
        "ASR1002X-5G-VPNK9",
        "ASR1002X-AES-AX",
        "ASR1002X-AIS-AX",
        "ASR1002X-AIS-AX-RF",
        "ASR1002X-CB",
        "ASR1002X-CB-RF",
        "ASR1002X-CB-WS",
        "ASR1002X-SB",
        "ASR1002X-SB-WS",
        "ASR1002X10GHAK9-RF",
        "ASR1002X10GVPNK9RF",
        "ASR1002X5GVPNK9-WS",
        "ASR1004",
        "ASR1004-10G-FPI/K9",
        "ASR1004-10G-HA/K9",
        "ASR1004-10G-SEC/K9",
        "ASR1004-10G-SHA/K9",
        "ASR1004-10G-VPN/K9",
        "ASR1004-10G/K9",
        "ASR1004-20G-FPI/K9",
        "ASR1004-20G-HA/K9",
        "ASR1004-20G-SEC/K9",
        "ASR1004-20G-SHA/K9",
        "ASR1004-20G-VPN/K9",
        "ASR1004-20G/K9",
        "ASR1004-40G-NB",
        "ASR1004-40G-NB-RF",
        "ASR1004-BBA",
        "ASR1004-CB",
        "ASR1004-RF",
        "ASR1004-SB",
        "ASR1004-WS",
        "ASR1004=",
        "ASR1006",
        "ASR1006-10G-B16/K9",
        "ASR1006-10G-B24/K9",
        "ASR1006-10G-B32/K9",
        "ASR1006-10G-BB16",
        "ASR1006-10G-BB32",
        "ASR1006-10G-FPI/K9",
        "ASR1006-10G-HA/K9",
        "ASR1006-10G-SEC/K9",
        "ASR1006-10G-SHA/K9",
        "ASR1006-10G-VPN/K9",
        "ASR1006-20G-B16/K9",
        "ASR1006-20G-B32/K9",
        "ASR1006-20G-FPI/K9",
        "ASR1006-20G-HA/K9",
        "ASR1006-20G-SEC/K9",
        "ASR1006-20G-SHA/K9",
        "ASR1006-20G-VPN/K9",
        "ASR1006-BB",
        "ASR1006-CB",
        "ASR1006-NTT",
        "ASR1006-NTT2",
        "ASR1006-RP1ESP10",
        "ASR1006-RP1ESP10-R",
        "ASR1006-SB",
        "ASR1006-SB-RF",
        "ASR1006-SB-WS",
        "ASR1006-WS",
        "ASR1006-X",
        "ASR1006=",
        "ASR1006X-CB",
        "ASR1009-X",
        "ASR1009X-CB",
        "ASR1013",
        "ASR1013-BB",
        "ASR1013-CB",
        "ASR1013-RF",
        "ASR1013-SB",
        "ASR1013-SB-WS",
        "ASR1013=",
        "ASR1K13R2-40G-HAK9",
        "ASR1K4R2-10GSHA/K9",
        "ASR1K4R2-20G-FPIK9",
        "ASR1K4R2-20G-HA/K9",
        "ASR1K4R2-20G-SECK9",
        "ASR1K4R2-20G-SHAK9",
        "ASR1K4R2-20G-VPNK9",
        "ASR1K4R2-20G/K9",
        "ASR1K4R2-40G-SECK9",
        "ASR1K4R2-40G-VPNK9",
        "ASR1K6R2-100-SECK9",
        "ASR1K6R2-100-SHAK9",
        "ASR1K6R2-100-VPNK9",
        "ASR1K6R2-10GSHA/K9",
        "ASR1K6R2-20-B32/K9",
        "ASR1K6R2-20G-FPIK9",
        "ASR1K6R2-20G-HA/K9",
        "ASR1K6R2-20G-SECK9",
        "ASR1K6R2-20G-SHAK9",
        "ASR1K6R2-20G-VPNK9",
        "ASR1K6R2-40G-SECK9",
        "ASR1K6R2-40G-SHAK9",
        "ASR1K6R2-40G-VPNK9",
        "ASR903-RSP3-BUN",
        "ASR907-RSP3-BUN",
        "ASR9904-COMP01-BUN",
        "ASR9904-COMP02-BUN",
        "ASS-9K-ISR-K9",
        "BPX8620",
        "BPX8650",
        "BPX8680",
        "C1-AIR-CT5508-K9",
        "C1-AIR-CT5508-K9++",
        "C1-AIR-CT5760-K9",
        "C1-AIR-CT5760-K9++",
        "C1-ASR1001-HX/K9",
        "C1-ASR1001-X/K9",
        "C1-ASR1002-HX/K9",
        "C1-ASR1002-X/K9",
        "C1-ASR1004/K9",
        "C1-ASR1006/K9",
        "C1-ASR1006X/K9",
        "C1-ASR1009X/K9",
        "C1-ASR1013/K9",
        "C1-C4500X-16SFP+",
        "C1-C4500X-32SFP+",
        "C1-C4503-E",
        "C1-C4503E+48V+",
        "C1-C4503E-S7L+48V+",
        "C1-C4506-E",
        "C1-C4506E+96V+",
        "C1-C4506E-DNA",
        "C1-C4506E-S7L+96V+",
        "C1-C4506E-SDA",
        "C1-C4507R+E",
        "C1-C4507RE+96V+",
        "C1-C4507RE-DNA",
        "C1-C4507RE-SDA",
        "C1-C4510R+E",
        "C1-C4510RE-DNA",
        "C1-C4510RE-S7+96V+",
        "C1-C4510RE-S8+96V+",
        "C1-C4510RE-SDA",
        "C1-C6807XL-S2T-BUN",
        "C1-C6816-X-LE",
        "C1-C6824-X-LE-40G",
        "C1-C6832-X-LE",
        "C1-C6840-X-LE-40G",
        "C1-C6880-X-LE",
        "C1-CISCO1941/K9",
        "C1-CISCO2911/K9",
        "C1-CISCO2951/K9",
        "C1-CISCO3925/K9",
        "C1-CISCO3925E/K9",
        "C1-CISCO3945E/K9",
        "C1-CISCO4221/K9",
        "C1-CISCO4221J/K9",
        "C1-CISCO4221T-P/K9",
        "C1-CISCO4321/K9",
        "C1-CISCO4321T-P/K9",
        "C1-CISCO4331/K9",
        "C1-CISCO4331/K9-RF",
        "C1-CISCO4331T-P/K9",
        "C1-CISCO4351/K9",
        "C1-CISCO4351T-P/K9",
        "C1-CISCO4431/K9",
        "C1-CISCO4451/K9",
        "C1-WS3850-48U/K9",
        "C1-WSC385024XSS-RF",
        "C1000-12MP-2X-L",
        "C1000-16FP-2G-L",
        "C1000-16P-2G-L",
        "C1000-16P-E-2G-L",
        "C1000-16T-2G-L",
        "C1000-16T-E-2G-L",
        "C1000-24FP-4G-L",
        "C1000-24FP-4X-L",
        "C1000-24MP-4X-L",
        "C1000-24P-4G-L",
        "C1000-24P-4X-L",
        "C1000-24PP-4G-L",
        "C1000-24T-4G-L",
        "C1000-24T-4X-L",
        "C1000-48FP-4G-L",
        "C1000-48FP-4X-L",
        "C1000-48P-4G-L",
        "C1000-48P-4X-L",
        "C1000-48PP-4G-L",
        "C1000-48T-4G-L",
        "C1000-48T-4X-L",
        "C1000-8FP-2G-L",
        "C1000-8FP-E-2G-L",
        "C1000-8P-2G-L",
        "C1000-8P-E-2G-L",
        "C1000-8T-2G-L",
        "C1000-8T-E-2G-L",
        "C1000FE-24P-4G-L",
        "C1000FE-24T-4G-L",
        "C1000FE-48P-4G-L",
        "C1000FE-48T-4G-L",
        "C1100TG-1N24P32A",
        "C1100TG-1N32A",
        "C1100TGX-1N24P32A",
        "C1101-4P",
        "C1101-4PLTEP",
        "C1101-4PLTEPWA",
        "C1101-4PLTEPWB",
        "C1101-4PLTEPWD",
        "C1101-4PLTEPWE",
        "C1101-4PLTEPWF",
        "C1101-4PLTEPWH",
        "C1101-4PLTEPWN",
        "C1101-4PLTEPWQ",
        "C1101-4PLTEPWR",
        "C1101-4PLTEPWZ",
        "C1109-4PLTE2P",
        "C1109-4PLTE2PWE",
        "C1111-4P",
        "C1111-4P-DNA",
        "C1111-4P-RF",
        "C1111-4PJ",
        "C1111-4PLTEEA",
        "C1111-4PLTEEA-DNA",
        "C1111-4PLTEEA-RF",
        "C1111-4PLTELA",
        "C1111-4PLTELA-DNA",
        "C1111-4PWA",
        "C1111-4PWB",
        "C1111-4PWD",
        "C1111-4PWE",
        "C1111-4PWF",
        "C1111-4PWH",
        "C1111-4PWN",
        "C1111-4PWQ",
        "C1111-4PWR",
        "C1111-4PWZ",
        "C1111-8P",
        "C1111-8P-DNA",
        "C1111-8P-RF",
        "C1111-8PJ",
        "C1111-8PLTEEA",
        "C1111-8PLTEEA-DNA",
        "C1111-8PLTEEA-RF",
        "C1111-8PLTEEAWA",
        "C1111-8PLTEEAWB",
        "C1111-8PLTEEAWE",
        "C1111-8PLTEEAWE-RF",
        "C1111-8PLTEEAWR",
        "C1111-8PLTELA",
        "C1111-8PLTELA-DNA",
        "C1111-8PLTELAWA",
        "C1111-8PLTELAWD",
        "C1111-8PLTELAWE",
        "C1111-8PLTELAWF",
        "C1111-8PLTELAWH",
        "C1111-8PLTELAWN",
        "C1111-8PLTELAWQ",
        "C1111-8PLTELAWS",
        "C1111-8PLTELAWZ",
        "C1111-8PLTEPWE",
        "C1111-8PWA",
        "C1111-8PWB",
        "C1111-8PWE",
        "C1111-8PWE-RF",
        "C1111-8PWF",
        "C1111-8PWH",
        "C1111-8PWN",
        "C1111-8PWQ",
        "C1111-8PWR",
        "C1111-8PWS",
        "C1111-8PWZ",
        "C1111X-8P",
        "C1112-8P",
        "C1112-8PLTEEA",
        "C1112-8PLTEEAWE",
        "C1112-8PWE",
        "C1113-8P",
        "C1113-8PLTEEA",
        "C1113-8PLTEEAWA",
        "C1113-8PLTEEAWB",
        "C1113-8PLTEEAWE",
        "C1113-8PLTELA",
        "C1113-8PLTELAWA",
        "C1113-8PLTELAWZ",
        "C1113-8PM",
        "C1113-8PMLTEEA",
        "C1113-8PMWE",
        "C1113-8PWA",
        "C1113-8PWB",
        "C1113-8PWE",
        "C1113-8PWZ",
        "C1116-4P",
        "C1116-4PLTEEA",
        "C1116-4PLTEEA-RF",
        "C1116-4PLTEEAWE",
        "C1116-4PWE",
        "C1117-4PLTEEAWA",
        "C1117-4PLTEEAWE",
        "C1117-4PLTELA",
        "C1117-4PLTELAWZ",
        "C1117-4PM",
        "C1117-4PMLTEEA",
        "C1117-4PMLTEEAWE",
        "C1117-4PMWE",
        "C1117-4PWA",
        "C1117-4PWE",
        "C1117-4PWZ",
        "C1118-8P",
        "C1118-8PLTEEA",
        "C1121-8P",
        "C1121-8PLTEP",
        "C1121-8PLTEPWB",
        "C1121-8PLTEPWE",
        "C1121-8PLTEPWQ",
        "C1121-8PLTEPWZ",
        "C1121X-8P",
        "C1121X-8PLTEP",
        "C1121X-8PLTEPWA",
        "C1121X-8PLTEPWB",
        "C1121X-8PLTEPWE",
        "C1121X-8PLTEPWZ",
        "C1127X-8PLTEP",
        "C1161-8P",
        "C1161-8PLTEP",
        "C1161X-8P",
        "C1161X-8PLTEP",
        "C1841-2SHDSL/K9",
        "C1841-3G-G",
        "C1841-3G-G-ADSL2",
        "C1841-3G-G-SEC/K9",
        "C1841-3G-S",
        "C1841-3G-S-SEC/K9",
        "C1841-A3G-S-SEC/K9",
        "C1861-SRST-B/K9",
        "C1861-SRST-C-BK9",
        "C1861-SRST-C-F/K9",
        "C1861-SRST-F/K9",
        "C1861-UC-2BRI-K9",
        "C1861-UC-4FXO-K9",
        "C1861E-SRST-B/K9",
        "C1861E-SRST-C-BK9",
        "C1861E-SRST-C-F/K9",
        "C1861E-SRST-F/K9",
        "C1861E-UC-4FXO-K",
        "C1861W-SRST-B/K9",
        "C1861W-SRST-C-BK9",
        "C1861W-SRST-C-F/K9",
        "C1861W-SRST-F/K9",
        "C1861W-UC-2BRI-K9",
        "C1861W-UC-4FXO-K9",
        "C1905-MS",
        "C1905-UTM3Y-25S",
        "C1921-3G-S-K9",
        "C1921-3G-S-SEC/K9",
        "C1921-3G-U-K9",
        "C1921-4G-V-SEC/K9",
        "C1921-4SHDSL-EA/K9",
        "C1921-4SHDSL/K9",
        "C1921-ADSL2-M/K9",
        "C1921-AX/K9",
        "C1921-VA/K9",
        "C1921VAM/K9",
        "C1941-AX/K9",
        "C1941-MS",
        "C1941-SEC-SRE/K9",
        "C1941-VA-3G/K9",
        "C1941-VA/K9",
        "C1941-WAASX-SEC/K9",
        "C1941-WAASX/K9",
        "C1941W-E-N-SEC/K9",
        "C2801-10UC-VSEC/K9",
        "C2801-10UC/K9",
        "C2801-2SHDSL/K9",
        "C2801-4SHDSL/K9",
        "C2801-ADSL2-M/K9",
        "C2801-BRI-V/K9",
        "C2801-CCME-12/K9",
        "C2801-CCME-8/K9",
        "C2801-SHDSL-V2/K9",
        "C2801-SHDSL-V3/K9",
        "C2801-VSEC-SRST/K9",
        "C2801-VSEC/K9",
        "C2821-25UC-VSEC/K9",
        "C2821-25UC/K9",
        "C2821-4SHDSL/K9",
        "C2821-BIAB-24/K9",
        "C2821-H-VSEC/K9",
        "C2821-RETAIL/K9",
        "C2821-VSEC/K9",
        "C2821SEC/K9-U-V",
        "C2851-35UC-VSEC/K9",
        "C2851-35UC/K9",
        "C2851-H-VSEC/K9",
        "C2851-V-U-HSEC/K9",
        "C2851-VSEC-CCME/K9",
        "C2851-VSEC-CUBE/K9",
        "C2851-VSEC-SRST/K9",
        "C2851-VSEC-WAE/K9",
        "C2901-AX/K9",
        "C2901-AXV/K9",
        "C2901-HW/K9",
        "C2901-MS",
        "C2901-VSEC-CUBE/K9",
        "C2901-VSEC-SRE/K9",
        "C2901-VSEC/K9",
        "C2901-VSEC/K9-RF",
        "C2901-WAASX/K9",
        "C2911-16ES2-P",
        "C2911-4G-A-SEC/K9",
        "C2911-4G-V-SEC/K9",
        "C2911-AX-AKC/K9",
        "C2911-AX/K9",
        "C2911-AXV/K9",
        "C2911-MS",
        "C2911-VA/K9",
        "C2921-4G-A-SEC/K9",
        "C2921-4G-V-SEC/K9",
        "C2921-AX-AKC/K9",
        "C2921-AX/K9",
        "C2921-AXV/K9",
        "C2921-CME-SRST/K9",
        "C2921-CMESRSTK9-RF",
        "C2921-ESG/K9",
        "C2921-HW/K9",
        "C2921-MS",
        "C2921-OTO/K9",
        "C2921-UCSE/K9",
        "C2921-VSEC-CUBE/K9",
        "C2921-VSEC-PSRE/K9",
        "C2951-4G-V-SEC/K9",
        "C2951-AX-AKC/K9",
        "C2951-AX/K9",
        "C2951-AXV/K9",
        "C2951-CME-SRST/K9",
        "C2951-HW/K9",
        "C2951-MS",
        "C2951-OTO/K9",
        "C2951-S-SRE-WAE/K9",
        "C2951-UCSE/K9",
        "C2951-VSEC-CUBE/K9",
        "C2951-WAASX/K9",
        "C3270-K9",
        "C3270ENC-1W-FO-K9",
        "C3270ENC-1W-K9",
        "C3270ENC-2W-2V4-K9",
        "C3270ENC-2W-FO-K9",
        "C3270ENC-2W-K9",
        "C3270ENC-2W-V4-K9",
        "C3270ENC-3W-3V-K9",
        "C3270ENC-3W-3V4-K9",
        "C3270ENC-3W-FO-K9",
        "C3270ENC-3W-K9",
        "C3270ENC-3W-V4-K9",
        "C3270ENC-FO-K9",
        "C3270ENC-FO-V4-K9",
        "C3270ENC-K9",
        "C3560CX-12PC-S++",
        "C3560CX-12PD-S++",
        "C3560CX-12TC-S++",
        "C3560CX-8PC-S++",
        "C3560CX-8TC-S++",
        "C3560G24-4AP1140-A",
        "C3560G24-4AP1140-E",
        "C3825-35UC/K9",
        "C3825-BIAB-24/K9",
        "C3825-BIAB-24K9-RF",
        "C3825-DC-1DSU",
        "C3825-DC-2DSU",
        "C3825-H-VSEC/K9",
        "C3825-H-VSEC/K9-RF",
        "C3825-HC-CUBE/K9",
        "C3825-NOVPN",
        "C3825-RETAIL/K9",
        "C3825-VSEC/K9",
        "C3825-VSEC/K9-RF",
        "C3825SEC/K9-U-V",
        "C3845-35UC-VSEC/K9",
        "C3845-35UC/K9",
        "C3845-BIAB-24/K9",
        "C3845-BIAB-24K9-RF",
        "C3845-BIAB-48/K9",
        "C3845-BIAB-48K9-RF",
        "C3845-BRDLSS/K9",
        "C3845-NOVPN",
        "C3845-NOVPN-MB",
        "C3845-PS-BLANK",
        "C3845-RETAIL/K9",
        "C3845-V-U-HSEC/K9",
        "C3845-VSEC-CCME/K9",
        "C3845-VSEC-SRST/K9",
        "C3845-VSEC/K9",
        "C3845-VSEC/K9-RF",
        "C3845VSECCCMEK9-RF",
        "C3845VSECSRSTK9-RF",
        "C3850-12X48U-E++",
        "C3850-12XS-E++",
        "C3850-24XS-E++",
        "C3850-48XS-E++",
        "C3925-AX-AKC/K9",
        "C3925-AX/K9",
        "C3925-AXV/K9",
        "C3925-CME-SRST/K9",
        "C3925-CMESRSTK9-RF",
        "C3925-ES24-UCSE/K9",
        "C3925-HW/K9",
        "C3925-MS",
        "C3925-STM-1-SM/K9",
        "C3925-UCSE/K9",
        "C3925-VSEC-CUBE/K9",
        "C3925-VSEC-PSRE/K9",
        "C3925-VSEC-SRE/K9",
        "C3925-VSEC/K9",
        "C3925-VSEC/K9-RF",
        "C3925-WAAS-SEC/K9",
        "C3925-WAASX/K9",
        "C3925E-AX-AKC/K9",
        "C3925E-AX/K9",
        "C3925E-CME-SRST/K9",
        "C3925E-HW/K9",
        "C3925E-MS",
        "C3925E-STM-1-SM/K9",
        "C3925E-VSEC/K9",
        "C3945-AX-AKC/K9",
        "C3945-AX/K9",
        "C3945-AXV/K9",
        "C3945-CME-SRST/K9",
        "C3945-HW/K9",
        "C3945-MS",
        "C3945-STM-1-SM/K9",
        "C3945-VSEC-CUBE/K9",
        "C3945-VSEC-PSRE/K9",
        "C3945-VSEC/K9",
        "C3945-VSEC/K9-RF",
        "C3945-WAASX/K9",
        "C3945E-AX-AKC/K9",
        "C3945E-AX/K9",
        "C3945E-CME-SRST/K9",
        "C3945E-ESG/K9",
        "C3945E-HW/K9",
        "C3945E-OTO/K9",
        "C3945E-STM-1-SM/K9",
        "C3945E-VSEC-CUBEK9",
        "C3945E-VSEC-CUBEK9C3945E-VSEC-SRE/K9",
        "C3K-PWR-300WAC-RF",
        "C3K-PWR-300WAC-WS",
        "C4503-1S7-C",
        "C4503-1S7ES-C",
        "C4503-1S7LES-C",
        "C4503-S6L-C",
        "C4503-S6L-C-RF",
        "C4506-1S7-C",
        "C4506-1S7ES-C",
        "C4506-1S7LES-C",
        "C4506-1S7LES-C-RF",
        "C4506-S6L-C",
        "C4506-S6L-C-RF",
        "C4507+1S6L-C",
        "C4507+1S7ES-C",
        "C4507+1S7LES-C",
        "C4507+2S6L-C",
        "C4507+2S7ES-C",
        "C4507+2S7LES-C",
        "C4507-1S6L-C",
        "C4507-2S6-C",
        "C4507-2S6L-C",
        "C4510+1S7ES-C",
        "C4510-1S6-C",
        "C4KX-PWR-750ACF-RF",
        "C6509VE-ACE20-6716",
        "C6800IA-48FPD",
        "C6800IA-48FPD++",
        "C6800IA-48FPDR",
        "C6800IA-48FPDR-RF",
        "C6800IA-48FPDR-WS",
        "C6800IA-48TD",
        "C6800IA-48TD-WS",
        "C6800IA-FPDR++",
        "C6800IA-TD++",
        "C6807-XL",
        "C6807-XL-S2T-BUN",
        "C6807-XL-S2TBUN-RF",
        "C6807-XL-S2TBUN-WS",
        "C6807-XL-S6T-BUN",
        "C6807-XL-WS",
        "C6816-X-LE",
        "C6816-X-LE++",
        "C6816-X-LE-RF",
        "C6816-X-LE-WS",
        "C6824-X-LE-40G",
        "C6832-X-LE",
        "C6832-X-LE++",
        "C6832-X-LE-RF",
        "C6832-X-LE-WS",
        "C6840-X-LE-40G",
        "C6840-X-LE-40G-RF",
        "C6840-X-LE-40G-WS",
        "C6880-X",
        "C6880-X++",
        "C6880-X-LE",
        "C6880-X-LE++",
        "C819G-4G-GA-K9",
        "C819G-LTE-LA-K9",
        "C819GW-LTE-GA-EK9",
        "C819GW-LTE-MNA-AK9",
        "C819H-K9",
        "C819HG+7-K9",
        "C819HG-4G-A-K9",
        "C819HG-4G-G-K9",
        "C819HG-4G-V-K9",
        "C819HG-B-K9",
        "C819HG-S-K9",
        "C819HG-U-K9",
        "C819HG-V-K9",
        "C8200-1N-4T",
        "C8300-1N1S-4T2X",
        "C8300-1N1S-6T",
        "C8300-2N2S-4T2X",
        "C8300-2N2S-6T",
        "C841M-4X/K9",
        "C841M-8X/K9",
        "C8500-12X",
        "C8500-12X4QC",
        "C8500L-8G4X",
        "C866VAE-W-E-K9",
        "C867VAE-POE-W-A-K9",
        "C867VAE-W-A-K9",
        "C881-K9",
        "C881-V-K9",
        "C881G+7-A-K9",
        "C881G+7-K9",
        "C881G-4G-A-K9",
        "C881G-4G-G-K9",
        "C881G-4G-GA-K9",
        "C881G-4G-V-K9",
        "C881G-B-K9",
        "C881G-MM-K9",
        "C881G-S-K9",
        "C881G-U-K9",
        "C881G-V-K9",
        "C881GW+7-A-K9",
        "C881GW+7-E-K9",
        "C881GW-S-A-K9",
        "C881GW-V-A-K9",
        "C881SRST-K9",
        "C881SRSTW-GN-A-K9",
        "C881SRSTW-GN-E-K9",
        "C881W-A-K9",
        "C881W-E-K9",
        "C881W-P-K9",
        "C881WD-A-K9",
        "C881WD-E-K9",
        "C886VA-W-E-K9",
        "C886VAG+7-K9",
        "C887VA-W-A-K9",
        "C887VA-W-E-K9",
        "C887VAG+7-K9",
        "C887VAG-4G-GA-K9",
        "C887VAM-K9",
        "C887VAM-W-E-K9",
        "C887VAMG+7-K9",
        "C888EG+7-K9",
        "C891F-K9",
        "C892FE-K9",
        "C892FSP-K9",
        "C896VA-K9",
        "C896VAG-LTE-GA-K9",
        "C897VA-K9",
        "C897VA-M-K9",
        "C897VAB-K9",
        "C897VAG-LTE-GA-K9",
        "C897VAG-LTE-LA-K9",
        "C897VAGW-LTE-GA-E-K9",
        "C897VAM-W-E-K9",
        "C897VAMG-LTE-GA-K9",
        "C897VAW-A-K9",
        "C897VAW-E-K9",
        "C897VAW-E-M-K9",
        "C898EA-K9",
        "C898EAG-LTE-GA-K9",
        "C898EAG-LTE-LA-K9",
        "C899G-LTE-GA-K9",
        "C899G-LTE-JP-K9",
        "C899G-LTE-LA-K9",
        "C899G-LTE-NA-K9",
        "C899G-LTE-ST-K9",
        "C899G-LTE-VZ-K9",
        "C9105AXI-A",
        "C9105AXI-B",
        "C9105AXI-C",
        "C9105AXI-D",
        "C9105AXI-E",
        "C9105AXI-EWC-A",
        "C9105AXI-EWC-B",
        "C9105AXI-EWC-C",
        "C9105AXI-EWC-D",
        "C9105AXI-EWC-E",
        "C9105AXI-EWC-F",
        "C9105AXI-EWC-G",
        "C9105AXI-EWC-H",
        "C9105AXI-EWC-I",
        "C9105AXI-EWC-K",
        "C9105AXI-EWC-N",
        "C9105AXI-EWC-Q",
        "C9105AXI-EWC-R",
        "C9105AXI-EWC-S",
        "C9105AXI-EWC-T",
        "C9105AXI-EWC-Z",
        "C9105AXI-F",
        "C9105AXI-G",
        "C9105AXI-H",
        "C9105AXI-I",
        "C9105AXI-K",
        "C9105AXI-N",
        "C9105AXI-Q",
        "C9105AXI-R",
        "C9105AXI-S",
        "C9105AXI-T",
        "C9105AXI-Z",
        "C9105AXW-B",
        "C9115AXE-A",
        "C9115AXE-B",
        "C9115AXE-C",
        "C9115AXE-D",
        "C9115AXE-E",
        "C9115AXE-EWC-A",
        "C9115AXE-EWC-B",
        "C9115AXE-EWC-C",
        "C9115AXE-EWC-D",
        "C9115AXE-EWC-E",
        "C9115AXE-EWC-F",
        "C9115AXE-EWC-G",
        "C9115AXE-EWC-H",
        "C9115AXE-EWC-I",
        "C9115AXE-EWC-K",
        "C9115AXE-EWC-N",
        "C9115AXE-EWC-Q",
        "C9115AXE-EWC-R",
        "C9115AXE-EWC-S",
        "C9115AXE-EWC-T",
        "C9115AXE-EWC-Z",
        "C9115AXE-F",
        "C9115AXE-G",
        "C9115AXE-H",
        "C9115AXE-I",
        "C9115AXE-K",
        "C9115AXE-N",
        "C9115AXE-Q",
        "C9115AXE-R",
        "C9115AXE-S",
        "C9115AXE-T",
        "C9115AXE-Z",
        "C9115AXI-A",
        "C9115AXI-B",
        "C9115AXI-C",
        "C9115AXI-D",
        "C9115AXI-E",
        "C9115AXI-EWC-A",
        "C9115AXI-EWC-B",
        "C9115AXI-EWC-C",
        "C9115AXI-EWC-D",
        "C9115AXI-EWC-E",
        "C9115AXI-EWC-F",
        "C9115AXI-EWC-G",
        "C9115AXI-EWC-H",
        "C9115AXI-EWC-I",
        "C9115AXI-EWC-K",
        "C9115AXI-EWC-N",
        "C9115AXI-EWC-Q",
        "C9115AXI-EWC-R",
        "C9115AXI-EWC-S",
        "C9115AXI-EWC-T",
        "C9115AXI-EWC-Z",
        "C9115AXI-F",
        "C9115AXI-G",
        "C9115AXI-H",
        "C9115AXI-I",
        "C9115AXI-K",
        "C9115AXI-N",
        "C9115AXI-Q",
        "C9115AXI-R",
        "C9115AXI-S",
        "C9115AXI-T",
        "C9115AXI-Z",
        "C9117AXI-A",
        "C9117AXI-B",
        "C9117AXI-C",
        "C9117AXI-D",
        "C9117AXI-E",
        "C9117AXI-EWC-A",
        "C9117AXI-EWC-B",
        "C9117AXI-EWC-C",
        "C9117AXI-EWC-D",
        "C9117AXI-EWC-E",
        "C9117AXI-EWC-F",
        "C9117AXI-EWC-G",
        "C9117AXI-EWC-H",
        "C9117AXI-EWC-I",
        "C9117AXI-EWC-K",
        "C9117AXI-EWC-N",
        "C9117AXI-EWC-Q",
        "C9117AXI-EWC-R",
        "C9117AXI-EWC-S",
        "C9117AXI-EWC-T",
        "C9117AXI-EWC-Z",
        "C9117AXI-F",
        "C9117AXI-G",
        "C9117AXI-H",
        "C9117AXI-I",
        "C9117AXI-K",
        "C9117AXI-N",
        "C9117AXI-Q",
        "C9117AXI-R",
        "C9117AXI-S",
        "C9117AXI-T",
        "C9117AXI-Z",
        "C9120AXE-A",
        "C9120AXE-B",
        "C9120AXE-C",
        "C9120AXE-D",
        "C9120AXE-E",
        "C9120AXE-EWC-A",
        "C9120AXE-EWC-B",
        "C9120AXE-EWC-C",
        "C9120AXE-EWC-D",
        "C9120AXE-EWC-E",
        "C9120AXE-EWC-F",
        "C9120AXE-EWC-G",
        "C9120AXE-EWC-H",
        "C9120AXE-EWC-I",
        "C9120AXE-EWC-K",
        "C9120AXE-EWC-N",
        "C9120AXE-EWC-Q",
        "C9120AXE-EWC-R",
        "C9120AXE-EWC-S",
        "C9120AXE-EWC-T",
        "C9120AXE-EWC-Z",
        "C9120AXE-F",
        "C9120AXE-G",
        "C9120AXE-H",
        "C9120AXE-I",
        "C9120AXE-K",
        "C9120AXE-N",
        "C9120AXE-Q",
        "C9120AXE-R",
        "C9120AXE-S",
        "C9120AXE-T",
        "C9120AXE-Z",
        "C9120AXI-A",
        "C9120AXI-B",
        "C9120AXI-C",
        "C9120AXI-D",
        "C9120AXI-E",
        "C9120AXI-EWC-A",
        "C9120AXI-EWC-B",
        "C9120AXI-EWC-C",
        "C9120AXI-EWC-D",
        "C9120AXI-EWC-E",
        "C9120AXI-EWC-F",
        "C9120AXI-EWC-G",
        "C9120AXI-EWC-H",
        "C9120AXI-EWC-I",
        "C9120AXI-EWC-K",
        "C9120AXI-EWC-N",
        "C9120AXI-EWC-Q",
        "C9120AXI-EWC-R",
        "C9120AXI-EWC-S",
        "C9120AXI-EWC-T",
        "C9120AXI-EWC-Z",
        "C9120AXI-F",
        "C9120AXI-G",
        "C9120AXI-H",
        "C9120AXI-I",
        "C9120AXI-K",
        "C9120AXI-N",
        "C9120AXI-Q",
        "C9120AXI-R",
        "C9120AXI-S",
        "C9120AXI-T",
        "C9120AXI-Z",
        "C9120AXP-A",
        "C9120AXP-B",
        "C9120AXP-C",
        "C9120AXP-D",
        "C9120AXP-E",
        "C9120AXP-EWC-A",
        "C9120AXP-EWC-B",
        "C9120AXP-EWC-C",
        "C9120AXP-EWC-D",
        "C9120AXP-EWC-E",
        "C9120AXP-EWC-F",
        "C9120AXP-EWC-G",
        "C9120AXP-EWC-H",
        "C9120AXP-EWC-I",
        "C9120AXP-EWC-K",
        "C9120AXP-EWC-N",
        "C9120AXP-EWC-Q",
        "C9120AXP-EWC-R",
        "C9120AXP-EWC-S",
        "C9120AXP-EWC-T",
        "C9120AXP-EWC-Z",
        "C9120AXP-F",
        "C9120AXP-G",
        "C9120AXP-H",
        "C9120AXP-I",
        "C9120AXP-K",
        "C9120AXP-N",
        "C9120AXP-Q",
        "C9120AXP-R",
        "C9120AXP-S",
        "C9120AXP-T",
        "C9120AXP-Z",
        "C9130AXE",
        "C9130AXE-A",
        "C9130AXE-B",
        "C9130AXE-B-EDU",
        "C9130AXE-B1",
        "C9130AXE-C",
        "C9130AXE-D",
        "C9130AXE-E",
        "C9130AXE-E1",
        "C9130AXE-EWC-A",
        "C9130AXE-EWC-B",
        "C9130AXE-EWC-C",
        "C9130AXE-EWC-D",
        "C9130AXE-EWC-E",
        "C9130AXE-EWC-F",
        "C9130AXE-EWC-G",
        "C9130AXE-EWC-H",
        "C9130AXE-EWC-I",
        "C9130AXE-EWC-K",
        "C9130AXE-EWC-N",
        "C9130AXE-EWC-Q",
        "C9130AXE-EWC-R",
        "C9130AXE-EWC-S",
        "C9130AXE-EWC-T",
        "C9130AXE-EWC-Z",
        "C9130AXE-F",
        "C9130AXE-G",
        "C9130AXE-H",
        "C9130AXE-I",
        "C9130AXE-K",
        "C9130AXE-N",
        "C9130AXE-Q",
        "C9130AXE-R",
        "C9130AXE-S",
        "C9130AXE-T",
        "C9130AXE-Z",
        "C9130AXI",
        "C9130AXI-A",
        "C9130AXI-B",
        "C9130AXI-B-EDU",
        "C9130AXI-B1",
        "C9130AXI-C",
        "C9130AXI-D",
        "C9130AXI-E",
        "C9130AXI-E1",
        "C9130AXI-EWC-A",
        "C9130AXI-EWC-B",
        "C9130AXI-EWC-C",
        "C9130AXI-EWC-D",
        "C9130AXI-EWC-E",
        "C9130AXI-EWC-F",
        "C9130AXI-EWC-G",
        "C9130AXI-EWC-H",
        "C9130AXI-EWC-I",
        "C9130AXI-EWC-K",
        "C9130AXI-EWC-N",
        "C9130AXI-EWC-Q",
        "C9130AXI-EWC-R",
        "C9130AXI-EWC-S",
        "C9130AXI-EWC-T",
        "C9130AXI-EWC-Z",
        "C9130AXI-F",
        "C9130AXI-G",
        "C9130AXI-H",
        "C9130AXI-I",
        "C9130AXI-K",
        "C9130AXI-N",
        "C9130AXI-Q",
        "C9130AXI-R",
        "C9130AXI-S",
        "C9130AXI-T",
        "C9130AXI-Z",
        "C9136I-B",
        "C9200-24P",
        "C9200-24PB",
        "C9200-24PXG",
        "C9200-24T",
        "C9200-48P",
        "C9200-48PB",
        "C9200-48PL",
        "C9200-48PXG",
        "C9200-48T",
        "C9200L-24P-4G",
        "C9200L-24P-4X",
        "C9200L-24PXG-2Y",
        "C9200L-24PXG-4X",
        "C9200L-24PXG-4XA++",
        "C9200L-24T-4G",
        "C9200L-24T-4X",
        "C9200L-48P-4G",
        "C9200L-48P-4X",
        "C9200L-48P-4X-A++",
        "C9200L-48PL-4G",
        "C9200L-48PL-4X",
        "C9200L-48PXG-2Y",
        "C9200L-48PXG-4X",
        "C9200L-48PXG-4XA++",
        "C9200L-48T-4G",
        "C9200L-48T-4X",
        "C921-4P",
        "C921-4PLTEAS",
        "C921-4PLTEAU",
        "C921-4PLTEGB",
        "C921J-4P",
        "C921J-4PSEC",
        "C926-4P",
        "C926-4PLTEGB",
        "C927-4P",
        "C927-4PLTEAU",
        "C927-4PLTEGB",
        "C927-4PM",
        "C927-4PMLTEGB",
        "C9300-24H",
        "C9300-24S",
        "C9300-24T",
        "C9300-24U",
        "C9300-24UB",
        "C9300-24UX",
        "C9300-24UXB",
        "C9300-48H",
        "C9300-48P",
        "C9300-48S",
        "C9300-48T",
        "C9300-48U",
        "C9300-48UB",
        "C9300-48UN",
        "C9300-48UXM",
        "C9300L-24P-4G",
        "C9300L-24P-4X",
        "C9300L-24T-4G",
        "C9300L-24T-4X",
        "C9300L-24UXG-2Q",
        "C9300L-24UXG-4X",
        "C9300L-48P-4G",
        "C9300L-48P-4X",
        "C9300L-48PF-4G",
        "C9300L-48PF-4X",
        "C9300L-48T-4G",
        "C9300L-48T-4X",
        "C9300L-48UX-4X",
        "C9300L-48UXG-2Q",
        "C9300L-48UXG-4X",
        "C931-4P",
        "C931J-4P",
        "C931J-4PSEC",
        "C9404R",
        "C9407R",
        "C941-4PLTEPWD",
        "C941-4PLTEPWF",
        "C941-4PLTEPWH",
        "C941-4PLTEPWN",
        "C941-4PLTEPWQ",
        "C941-4PLTEPWR",
        "C9410R",
        "C941J-4P",
        "C941J-4PSEC",
        "C9500-12Q",
        "C9500-16X",
        "C9500-24Q",
        "C9500-24Y4C",
        "C9500-32C",
        "C9500-32QC",
        "C9500-40X",
        "C9500-48Y4C",
        "C951-4P",
        "C959-2PLTEGB",
        "C959-2PLTEUS",
        "C959-2PLTEVZ",
        "C959_2PLTEIN",
        "C9606R",
        "C9800-40-K9",
        "C9800-80-K9",
        "C9800-CL-K9",
        "C9800-L-C-K9",
        "C9800-L-F-K9",
        "CDB-8P",
        "CDB-8U",
        "CH-C2960-24PC-L-9",
        "CH-C2960-24TC-L-24",
        "CH-C2960-48TC-L-24",
        "CH-C3560-24TS-S-24",
        "CH-C3560-48TS-S-24",
        "CISCO1801",
        "CISCO1801-M",
        "CISCO1801-M/K9",
        "CISCO1801/K9",
        "CISCO1801W-AG-B/K9",
        "CISCO1801W-AG-C/K9",
        "CISCO1801W-AG-N/K9",
        "CISCO1801WM-AGB/K9",
        "CISCO1802",
        "CISCO1802/K9",
        "CISCO1802/K9-RF",
        "CISCO1802W-AG-E/K9",
        "CISCO1802WPOEEK9RF",
        "CISCO1803",
        "CISCO1803/K9",
        "CISCO1803W-AG-A/K9",
        "CISCO1803W-AG-B/K9",
        "CISCO1803W-AG-E/K9",
        "CISCO1803WAGA/K9RF",
        "CISCO1803WAGBK9-RFCISCO1803WAGE/K9RF",
        "CISCO1805-D",
        "CISCO1805-D/K9",
        "CISCO1805EJ",
        "CISCO1811/K9",
        "CISCO1811W-AG-B/K9",
        "CISCO1811W-AG-C/K9",
        "CISCO1811W-AG-N/K9",
        "CISCO1812-J/K9",
        "CISCO1812/K9",
        "CISCO1812W-AG-C/K9",
        "CISCO1812W-AG-P/K9",
        "CISCO1841",
        "CISCO1841VE/K9",
        "CISCO1905-SEC/K9",
        "CISCO1905-SECK9-RF",
        "CISCO1905/K9",
        "CISCO1905/K9-RF",
        "CISCO1921-ADSL2/K9",
        "CISCO1921-SEC/K9",
        "CISCO1921-T1SEC/K9",
        "CISCO1921/K9",
        "CISCO1921DC/K9",
        "CISCO1941-HSEC/K9",
        "CISCO1941-SEC/K9",
        "CISCO1941-SECK9-RF",
        "CISCO1941-SECK9-WS",
        "CISCO1941/K9",
        "CISCO1941W-A/K9",
        "CISCO1941W-C/K9",
        "CISCO1941W-E/K9",
        "CISCO1941W-I/K9",
        "CISCO1941W-N/K9",
        "CISCO1941W-P/K9",
        "CISCO1941W-T/K9",
        "CISCO2801",
        "CISCO2811",
        "CISCO2811VE/K9",
        "CISCO2821",
        "CISCO2851",
        "CISCO2901-V/K9",
        "CISCO2901-V/K9-RF",
        "CISCO2901/K9",
        "CISCO2901/K9-RF",
        "CISCO2911/K9",
        "CISCO2921-V/K9",
        "CISCO2921-V/K9-RF",
        "CISCO2921/K9",
        "CISCO2921/K9-RF",
        "CISCO2951-DC/K9",
        "CISCO2951-H-VPN-K9",
        "CISCO2951-SEC/K9",
        "CISCO2951-V/K9",
        "CISCO2951-V/K9-RF",
        "CISCO2951/K9",
        "CISCO3825",
        "CISCO3845",
        "CISCO3925-CHASSIS",
        "CISCO3925-V/K9",
        "CISCO3925/K9",
        "CISCO3925/K9-RF",
        "CISCO3925E-CHASSIS",
        "CISCO3925E-SEC/K9",
        "CISCO3925E-V/K9",
        "CISCO3925E-V/K9-RF",
        "CISCO3925E/K9",
        "CISCO3925E/K9-RF",
        "CISCO3945-CHASSIS",
        "CISCO3945-HSEC+/K9",
        "CISCO3945/K9",
        "CISCO3945/K9-RF",
        "CISCO3945E-CHASSIS",
        "CISCO3945E-SEC/K9",
        "CISCO3945E-V/K9",
        "CISCO3945E-V/K9-RF",
        "CISCO3945E/K9",
        "CISCO3945E/K9-RF",
        "CISCO801",
        "CISCO803",
        "CISCO804",
        "CISCO804-IDSL",
        "CISCO804-J",
        "CISCO805",
        "CISCO806",
        "CISCO811",
        "CISCO8110",
        "CISCO813",
        "CISCO826",
        "CISCO827",
        "CISCO827H",
        "CISCO828",
        "CISCO831-K9",
        "CISCO836-K9",
        "CISCO837-K9",
        "CISCO851-K9",
        "CISCO851W-E-A-K9",
        "CISCO851W-G-A-K9",
        "CISCO851W-G-E-K9",
        "CISCO851W-G-J-K9",
        "CISCO857-K9",
        "CISCO857W-G-A-K9",
        "CISCO857W-G-E-K9",
        "CISCO857W-G-J-K9",
        "CISCO861-K9",
        "CISCO861W-GN-A-K9",
        "CISCO861W-GN-E-K9",
        "CISCO861W-GN-P-K9",
        "CISCO866VAE",
        "CISCO866VAE-K9",
        "CISCO867VAE",
        "CISCO867VAE-K9",
        "CISCO871-K9",
        "CISCO871W-G-A-K9",
        "CISCO871W-G-E-K9",
        "CISCO871W-G-J-K9",
        "CISCO876-K9",
        "CISCO876W-G-E-K9",
        "CISCO877-K9",
        "CISCO877-M-K9",
        "CISCO877W-G-A-K9",
        "CISCO877W-G-A-M-K9",
        "CISCO877W-G-E-K9",
        "CISCO877W-G-E-M-K9",
        "CISCO877W-G-J-K9",
        "CISCO878-K9",
        "CISCO878W-G-A-K9",
        "CISCO878W-G-E-K9",
        "CISCO878W-G-J-K9",
        "CISCO881-K9",
        "CISCO881-V/K9",
        "CISCO881G-A-K9",
        "CISCO881G-B-K9",
        "CISCO881G-K9",
        "CISCO881G-S-K9",
        "CISCO881G-V-K9",
        "CISCO881GW-GN-A-K9",
        "CISCO881GW-GN-E-K9",
        "CISCO881W-GN-A-K9",
        "CISCO881W-GN-E-K9",
        "CISCO881W-GN-P-K9",
        "CISCO886-K9",
        "CISCO886G-K9",
        "CISCO886GW-GN-E-K9",
        "CISCO886VA-J-K9",
        "CISCO886VA-K9",
        "CISCO886W-GN-E-K9",
        "CISCO887G-K9",
        "CISCO887GW-GN-A-K9",
        "CISCO887GW-GN-E-K9",
        "CISCO887GW-GNA-E-K9",
        "CISCO887GW-GNE-K9",
        "CISCO888G-K9",
        "CISCO888GW-GN-A-K9",
        "CISCO888GW-GN-E-K9",
        "CISCO891-K9",
        "CISCO891W-AGN-A-K9",
        "CISCO891W-AGN-N-K9",
        "CISCO892-K9",
        "CISCO892F-K9",
        "CISCO892FW-A-K9",
        "CISCO892FW-E-K9",
        "CISCO892J-K9",
        "CISCO892W-AGN-E-K9",
        "CISCO892W-AGN-P-K9",
        "CMICR-4PC",
        "CMICR-4PS",
        "CMICR-4PT",
        "CSP-5216",
        "CSP-5228",
        "CSP-5436",
        "CSP-5444",
        "CSP-5456",
        "CSR-ASR1K2-CHT1-1",
        "CSR-ASR1K2-ETH-1",
        "CSR-ASR1K2-FE-T1-1",
        "CSR1000V",
        "CUBESP-AP-H250A/K9",
        "CUBESP-AP-H250B/K9",
        "CUBESP-AP-H500A/K9",
        "CUBESP-AP-H500B/K9",
        "CUBESP-AP-HA1KA/K9",
        "CUBESP-AP-HA1KB/K9",
        "CUBESP-AP-SA250/K9",
        "Cisco1841",
        "Cisco1905/K9",
        "DN-APL-TTA-M",
        "EDU-CT5508-100-K9",
        "EDU-CT5508-50-K9",
        "ENCS5104-200/K9",
        "ENCS5104-400/K9",
        "ENCS5104-64/K9",
        "ENCS5406/K9",
        "ENCS5408/K9",
        "ENCS5412/K9",
        "ESR-6300-CON-K9",
        "ESR-6300-NCP-K9",
        "ESS-3300-CON",
        "ESS-3300-NCP",
        "ESW-6300-CON",
        "ESW-6300-CON-K9",
        "ESW-6300-ME",
        "FIPS-SHIELD-2901=",
        "HW-TEST-Q4FY18-M1",
        "HW-TEST-Q4FY18-M2",
        "IE-2000-16PTC-G-NX",
        "IE-2000-16TC-B",
        "IE-2000-16TC-G-E",
        "IE-2000-16TC-G-L",
        "IE-2000-16TC-G-X",
        "IE-2000-16TC-L",
        "IE-2000-4S-TS-G",
        "IE-2000-4S-TS-G-B",
        "IE-2000-4S-TS-G-L",
        "IE-2000-4T-B",
        "IE-2000-4T-G-L",
        "IE-2000-4T-L",
        "IE-2000-4TS-B",
        "IE-2000-4TS-G-B",
        "IE-2000-4TS-G-L",
        "IE-2000-4TS-L",
        "IE-2000-8TC-B",
        "IE-2000-8TC-G-B",
        "IE-2000-8TC-G-E",
        "IE-2000-8TC-G-L",
        "IE-2000-8TC-G-N",
        "IE-2000-8TC-L",
        "IE-3000-4TC",
        "IE-3000-4TC-E",
        "IE-3000-8TC",
        "IE-3000-8TC-E",
        "IE-3010-16S-8PC",
        "IE-3010-24TC",
        "IE-3200-8P2S",
        "IE-3200-8T2S",
        "IE-3300-8P2S",
        "IE-3300-8T2S",
        "IE-3300-8T2X",
        "IE-3300-8T2X-A",
        "IE-3300-8T2X-E",
        "IE-3300-8U2X",
        "IE-3400-8P2S",
        "IE-3400-8T2S",
        "IE-3400H-16FT",
        "IE-3400H-16T",
        "IE-3400H-24FT",
        "IE-3400H-24T",
        "IE-3400H-8FT",
        "IE-3400H-8T",
        "IE-4000-16GT4G-E",
        "IE-4000-16T4G-E",
        "IE-4000-4GC4GP4G-E",
        "IE-4000-4GS8GP4G-E",
        "IE-4000-4S8P4G-E",
        "IE-4000-4T4P4G-E",
        "IE-4000-4TC4G-E",
        "IE-4000-8GS4G-E",
        "IE-4000-8GT4G-E",
        "IE-4000-8GT8GP4G-E",
        "IE-4000-8S4G-E",
        "IE-4000-8T4G-E",
        "IE-4010-16S12P",
        "IE-4010-4S24P",
        "IE-5000-12S12P-10G",
        "IE-5000-16S12P",
        "IR1101-K9",
        "IR1821-K9",
        "IR1831-K9",
        "IR1833-K9",
        "IR1835-K9",
        "IR807G-LTE-GA-K9",
        "IR807G-LTE-NA-K9",
        "IR807G-LTE-VZ-K9",
        "IR809G-LTE-GA-K9",
        "IR809G-LTE-LA-K9",
        "IR809G-LTE-NA-K9",
        "IR809G-LTE-ST-K9",
        "IR809G-LTE-VZ-K9",
        "IR829-2LTE-EA-AK9",
        "IR829-2LTE-EA-BK9",
        "IR829-2LTE-EA-EK9",
        "IR829B-2LTE-EA-AK9",
        "IR829B-2LTE-EA-BK9",
        "IR829B-2LTE-EA-EK9",
        "IR829B-LTE-EA-AK9",
        "IR829B-LTE-EA-BK9",
        "IR829B-LTE-EA-EK9",
        "IR829B-LTE-LA-ZK9",
        "IR829GW-LTE-GA-EK9",
        "IR829GW-LTE-GA-ZK9",
        "IR829GW-LTE-LA-ZK9",
        "IR829GW-LTE-NA-AK9",
        "IR829GW-LTE-ST-AK9",
        "IR829GW-LTE-VZ-AK9",
        "IR829M-2LTE-EA-AK9",
        "IR829M-2LTE-EA-BK9",
        "IR829M-2LTE-EA-EK9",
        "IR829M-LTE-EA-AK9",
        "IR829M-LTE-EA-BK9",
        "IR829M-LTE-EA-EK9",
        "IR829M-LTE-LA-ZK9",
        "ISCO1905BR/K9",
        "ISR4221-SEC/K9",
        "ISR4221-SEC/K9-RF",
        "ISR4221/K9",
        "ISR4221/K9-RF",
        "ISR4221T-P/K9",
        "ISR4221X/K9",
        "ISR4321-AX/K9",
        "ISR4321-AX/K9-RF",
        "ISR4321-AX/K9-WS",
        "ISR4321-AXV/K9",
        "ISR4321-AXV/K9-RF",
        "ISR4321-AXV/K9-WS",
        "ISR4321-B/K9",
        "ISR4321-SEC/K9",
        "ISR4321-SEC/K9-RF",
        "ISR4321-SEC/K9-WS",
        "ISR4321-V/K9",
        "ISR4321-V/K9-RF",
        "ISR4321-V/K9-WS",
        "ISR4321-VSEC/K9",
        "ISR4321-VSEC/K9-RF",
        "ISR4321-VSEC/K9-WS",
        "ISR4321/K9",
        "ISR4321BR-V/K9",
        "ISR4321BR/K9",
        "ISR4321R-AX/K9",
        "ISR4321R-AXV/K9",
        "ISR4321R-SEC/K9",
        "ISR4321R-V/K9",
        "ISR4321R-VSEC/K9",
        "ISR4321R/K9",
        "ISR4331-AX/K9",
        "ISR4331-AX/K9-RF",
        "ISR4331-AX/K9-WS",
        "ISR4331-AXV/K9",
        "ISR4331-B/K9",
        "ISR4331-DC/K9",
        "ISR4331-DNA",
        "ISR4331-DNA-PM",
        "ISR4331-SEC/K9",
        "ISR4331-SEC/K9-RF",
        "ISR4331-SEC/K9-WS",
        "ISR4331-V/K9",
        "ISR4331-V/K9-RF",
        "ISR4331-V/K9-WS",
        "ISR4331-VSEC/K9",
        "ISR4331-VSEC/K9-RF",
        "ISR4331-VSEC/K9-WS",
        "ISR4331/K9",
        "ISR4331/K9-WS",
        "ISR4331BR-V/K9",
        "ISR4331BR/K9",
        "ISR4331R-AX/K9",
        "ISR4331R-AXV/K9",
        "ISR4331R-SEC/K9",
        "ISR4331R-V/K9",
        "ISR4331R-VSEC/K9",
        "ISR4331R/K9",
        "ISR4351-AX/K9",
        "ISR4351-AX/K9-RF",
        "ISR4351-AX/K9-WS",
        "ISR4351-AXV/K9",
        "ISR4351-SEC/K9",
        "ISR4351-SEC/K9-RF",
        "ISR4351-SEC/K9-WS",
        "ISR4351-V/K9",
        "ISR4351-VSEC/K9",
        "ISR4351-VSEC/K9-RF",
        "ISR4351-VSEC/K9-WS",
        "ISR4351/K9",
        "ISR4431-AX/K9",
        "ISR4431-AX/K9-RF",
        "ISR4431-AX/K9-WS",
        "ISR4431-AXV/K9",
        "ISR4431-AXV/K9-RF",
        "ISR4431-SEC/K9",
        "ISR4431-SEC/K9-RF",
        "ISR4431-SEC/K9-WS",
        "ISR4431-V/K9",
        "ISR4431-V/K9-RF",
        "ISR4431-V/K9-WS",
        "ISR4431-VSEC/K9",
        "ISR4431-VSEC/K9-RF",
        "ISR4431/K9",
        "ISR4431/K9-RF",
        "ISR4431/K9-WS",
        "ISR4451-DNA",
        "ISR4451-UCSE-D/K9",
        "ISR4451-UCSE-S/K9",
        "ISR4451-X-AX/K9",
        "ISR4451-X-AX/K9-RF",
        "ISR4451-X-AX/K9-WS",
        "ISR4451-X-AXV/K9",
        "ISR4451-X-SEC/K9",
        "ISR4451-X-SECK9-WS",
        "ISR4451-X-V/K9",
        "ISR4451-X-V/K9-RF",
        "ISR4451-X-V/K9-WS",
        "ISR4451-X-VSEC/K9",
        "ISR4451-X/K9",
        "ISR4451-X/K9-RF",
        "ISR4451-X/K9-WS",
        "ISR4451-XSEC/K9-RF",
        "ISR4451X-AX-AKC/K9",
        "ISR4451XVSEC/K9-RF",
        "ISR4461/K9",
        "IW-6300-ME",
        "IW-6300H-AC",
        "IW-6300H-AC-K9",
        "IW-6300H-DC",
        "IW-6300H-DC-K9",
        "IW-6300H-DCW",
        "IW-6300H-DCW-K9",
        "IW3702-2E-B-K9",
        "IW3702-2E-E-K9",
        "IW3702-4E-B-K9",
        "IW3702-4E-E-K9",
        "ME-2400-24TS-A",
        "ME-2400-24TS-D",
        "ME-3400-24FS-A",
        "ME-3400-24TS-A",
        "ME-3400-24TS-D",
        "ME-3400E-24TS-M",
        "ME-3400EG-12CS-M",
        "ME-3400EG-2CS-A",
        "ME-3400G-12CS-A",
        "ME-3400G-12CS-D",
        "ME-3400G-2CS-A",
        "ME-4506-GB-BDL",
        "ME-4506E-S7L+96SFP",
        "ME-4507E-S7L+96SFP",
        "ME-4510-FTTH-BDL",
        "ME-4510-GB-BDL",
        "ME-4924-10GE",
        "ME-C4510E-S7+96SFP",
        "ME-C6524GS-8S",
        "ME1200-4S-A",
        "MWR-2941-DC",
        "MWR-2941-DC-A",
        "N1K-C1010",
        "N1K-VSMK9-404S12=",
        "N3K-C3016Q-40GE",
        "N3K-C3048TP-1GE",
        "N3K-C3064PQ-10GX",
        "N3K-C31128PQ-10GE",
        "N3K-C3132Q-40GE",
        "N3K-C3164Q-40GE",
        "N3K-C3232C",
        "N4K-4001I-XPX",
        "N5K-C5010P-BF",
        "N5K-C5020P-BF",
        "N5K-C5548P-FA",
        "N5K-C5548UP-FA",
        "N5K-C5596UP-FA",
        "N77-C7702",
        "N77-C7702=",
        "N77-C7706",
        "N77-C7706=",
        "N77-C7710",
        "N77-C7710=",
        "N77-C7718",
        "N77-C7718=",
        "N7K-C7009",
        "N7K-C7009=",
        "N7K-C7010",
        "N7K-C7010=",
        "N7K-C7018",
        "N7K-C7018=",
        "N9K-C93120TX",
        "N9K-C93128TX",
        "N9K-C9372PX",
        "N9K-C9372PX-E",
        "N9K-C9372TX",
        "N9K-C9396PX",
        "N9K-C9396TX",
        "N9K-C9504",
        "N9K-C9508",
        "N9K-C9516",
        "PROMOCT5508-1-K9",
        "PROMOCT5520-1-K9",
        "PROMOCT5520-100-K9",
        "SI-ISR4321-IWAN/K9",
        "SI-ISR4331-IWAN/K9",
        "SI-ISR4351-IWAN/K9",
        "SI-ISR4431-IWAN/K9",
        "SI-ISR4451-IWAN/K9",
        "SM-X-ES3-24-P",
        "SPIAD4321",
        "UA-C3850-24U-E",
        "UA-C3850-48U-E",
        "UCS-E1120D-M3/K9",
        "UCS-E140S-M2/K9",
        "UCS-E160D-M2/K9",
        "UCS-E160S-M3/K9",
        "UCS-E180D-M2/K9",
        "UCS-E180D-M3/K9",
        "UCSC-C220-M3S",
        "UCSC-C220-M4S",
        "VG450",
        "VS-C6504E-S720-10G",
        "VS-C6504E-SUP2T",
        "VS-C6504E-SUP2T-WS",
        "VS-C6509VE-S72010G",
        "VS-C6509VE-SUP2T",
        "VS-C6513-S720-10G",
        "VS-C6513S72010G-RF",
        "VSC6504ES72010G-WS",
        "WS-450096V",
        "WS-450096V-RF",
        "WS-450096V-WS",
        "WS-4506E-S8L+96SFP",
        "WS-4507E-S8L+96SFP",
        "WS-4507RE-S7L+96V+",
        "WS-4510E-S8+96SFP",
        "WS-4510RE-S7+96V++",
        "WS-6513XL-2FWM-K9",
        "WS-C2360-48TD-S",
        "WS-C2912-XL",
        "WS-C2912MF-XL",
        "WS-C2924-XL",
        "WS-C2924C-XL",
        "WS-C2924M-XL",
        "WS-C2928-24LT-C",
        "WS-C2928-24TC-C",
        "WS-C2928-48TC-C",
        "WS-C2940-8TF",
        "WS-C2940-8TT",
        "WS-C2948G-L3",
        "WS-C2950-12",
        "WS-C2950-24",
        "WS-C2950C-24",
        "WS-C2950G-12",
        "WS-C2950G-24-DC",
        "WS-C2950SX-24",
        "WS-C2950SX-48",
        "WS-C2950T-24",
        "WS-C2950T-48",
        "WS-C2955C-12",
        "WS-C2955S-12",
        "WS-C2955T-12",
        "WS-C2960+24LC-L",
        "WS-C2960+24LC-S",
        "WS-C2960+24PC-L",
        "WS-C2960+24PC-L-RF",
        "WS-C2960+24PC-L-WS",
        "WS-C2960+24PC-S",
        "WS-C2960+24TC-BR",
        "WS-C2960+24TC-L",
        "WS-C2960+24TC-S",
        "WS-C2960+48PST-L",
        "WS-C2960+48PST-S",
        "WS-C2960+48TC-L",
        "WS-C2960+48TC-S",
        "WS-C2960-24-S",
        "WS-C2960-24-S-RF",
        "WS-C2960-24-S-WS",
        "WS-C2960-24LC-S",
        "WS-C2960-24LC-S-RF",
        "WS-C2960-24LC-S-WS",
        "WS-C2960-24LT-L",
        "WS-C2960-24LT-L-RF",
        "WS-C2960-24LT-L-WS",
        "WS-C2960-24PC-L",
        "WS-C2960-24PC-L-RF",
        "WS-C2960-24PC-L-WS",
        "WS-C2960-24PC-S",
        "WS-C2960-24PC-S-RF",
        "WS-C2960-24PC-S-WS",
        "WS-C2960-24TC",
        "WS-C2960-24TC-J1",
        "WS-C2960-24TC-L",
        "WS-C2960-24TC-L-RF",
        "WS-C2960-24TC-L-WS",
        "WS-C2960-24TC-S",
        "WS-C2960-24TC-S-RF",
        "WS-C2960-24TC-S-WS",
        "WS-C2960-24TT",
        "WS-C2960-24TT-L",
        "WS-C2960-24TT-L-RF",
        "WS-C2960-24TT-L-WS",
        "WS-C2960-48PST-L",
        "WS-C2960-48PST-L-M",
        "WS-C2960-48PST-S",
        "WS-C2960-48PSTL-RF",
        "WS-C2960-48PSTL-WS",
        "WS-C2960-48PSTS-RF",
        "WS-C2960-48PSTS-WS",
        "WS-C2960-48TC",
        "WS-C2960-48TC-L",
        "WS-C2960-48TC-L-RF",
        "WS-C2960-48TC-L-WS",
        "WS-C2960-48TC-S",
        "WS-C2960-48TC-S-RF",
        "WS-C2960-48TC-S-WS",
        "WS-C2960-48TT",
        "WS-C2960-48TT-L",
        "WS-C2960-48TT-L-RF",
        "WS-C2960-48TT-L-WS",
        "WS-C2960-48TT-S",
        "WS-C2960-48TT-S-RF",
        "WS-C2960-48TT-S-WS",
        "WS-C2960-8TC",
        "WS-C2960-8TC-L",
        "WS-C2960-8TC-L-RF",
        "WS-C2960-8TC-L-WS",
        "WS-C2960-8TC-S",
        "WS-C2960-8TC-S-RF",
        "WS-C2960-8TC-S-WS",
        "WS-C2960C-12PC-L",
        "WS-C2960C-8PC-L",
        "WS-C2960C-8TC-L",
        "WS-C2960C-8TC-S",
        "WS-C2960CG-8TC-L",
        "WS-C2960CPD-8PT-L",
        "WS-C2960CPD-8TT-L",
        "WS-C2960CX-8PC",
        "WS-C2960CX-8PC-L",
        "WS-C2960CX-8TC",
        "WS-C2960CX-8TC-L",
        "WS-C2960G-24TC",
        "WS-C2960G-24TC-L",
        "WS-C2960G-24TC-RF",
        "WS-C2960G-24TCL-RF",
        "WS-C2960G-24TCL-WS",
        "WS-C2960G-48TC",
        "WS-C2960G-48TC-L",
        "WS-C2960G-48TCL-RF",
        "WS-C2960G-48TCL-WS",
        "WS-C2960G-8TC",
        "WS-C2960G-8TC-L",
        "WS-C2960G-8TC-L-RF",
        "WS-C2960G-8TC-L-WS",
        "WS-C2960L-16PS-LL",
        "WS-C2960L-16TS-LL",
        "WS-C2960L-24PQ-LL",
        "WS-C2960L-24PS-LL",
        "WS-C2960L-24TQ-LL",
        "WS-C2960L-24TS-LL",
        "WS-C2960L-48PQ-LL",
        "WS-C2960L-48PS-LL",
        "WS-C2960L-48TQ-LL",
        "WS-C2960L-48TS-LL",
        "WS-C2960L-8PS-LL",
        "WS-C2960L-8TS-LL",
        "WS-C2960PD-8TT-L",
        "WS-C2960PD-8TT-L-M",
        "WS-C2960PD-8TTL-RF",
        "WS-C2960PD-8TTL-WS",
        "WS-C2960PD8TTLM-RF",
        "WS-C2960S",
        "WS-C2960S-24PD-L",
        "WS-C2960S-24PS-L",
        "WS-C2960S-24TD-L",
        "WS-C2960S-24TS-L",
        "WS-C2960S-24TS-S",
        "WS-C2960S-48FPD-L",
        "WS-C2960S-48FPS-L",
        "WS-C2960S-48LPD-L",
        "WS-C2960S-48LPS-L",
        "WS-C2960S-48TD-L",
        "WS-C2960S-48TS-L",
        "WS-C2960S-48TS-S",
        "WS-C2960S-F24PS-L",
        "WS-C2960S-F24TS-L",
        "WS-C2960S-F24TS-S",
        "WS-C2960S-F48FPS-L",
        "WS-C2960S-F48LPS-L",
        "WS-C2960S-F48TS-L",
        "WS-C2960S-F48TS-S",
        "WS-C2960X-24PD-L",
        "WS-C2960X-24PS-L",
        "WS-C2960X-24PS-S",
        "WS-C2960X-24PSQ-L",
        "WS-C2960X-24TD-L",
        "WS-C2960X-24TS-L",
        "WS-C2960X-24TS-LL",
        "WS-C2960X-48FPD-L",
        "WS-C2960X-48FPS-L",
        "WS-C2960X-48LPD-L",
        "WS-C2960X-48LPS-L",
        "WS-C2960X-48LPS-S",
        "WS-C2960X-48TD-L",
        "WS-C2960X-48TS-L",
        "WS-C2960X-48TS-LL",
        "WS-C2960XR-24PD-I",
        "WS-C2960XR-24PD-L",
        "WS-C2960XR-24PS-I",
        "WS-C2960XR-24PS-L",
        "WS-C2960XR-24TD-I",
        "WS-C2960XR-24TD-L",
        "WS-C2960XR-24TS-I",
        "WS-C2960XR-24TS-L",
        "WS-C2960XR-48FPD-I",
        "WS-C2960XR-48FPD-L",
        "WS-C2960XR-48FPS-I",
        "WS-C2960XR-48FPS-L",
        "WS-C2960XR-48LPD-I",
        "WS-C2960XR-48LPD-L",
        "WS-C2960XR-48LPS-I",
        "WS-C2960XR-48LPS-L",
        "WS-C2960XR-48TD-I",
        "WS-C2960XR-48TD-L",
        "WS-C2960XR-48TS-I",
        "WS-C2960XR-48TS-L",
        "WS-C2970G-24T",
        "WS-C2970G-24TS",
        "WS-C2975GS-48PS-L",
        "WS-C3508G-XL",
        "WS-C3512-XL",
        "WS-C3524-PWR-XL",
        "WS-C3524-XL",
        "WS-C3548-XL",
        "WS-C3550-12G",
        "WS-C3550-12T",
        "WS-C3550-24",
        "WS-C3550-24-DC",
        "WS-C3550-24-FX",
        "WS-C3550-24PWR",
        "WS-C3550-48",
        "WS-C3560-12PC",
        "WS-C3560-12PC-S",
        "WS-C3560-12PC-S-M",
        "WS-C3560-24PS",
        "WS-C3560-24PS-E",
        "WS-C3560-24PS-E-RF",
        "WS-C3560-24PS-E-WS",
        "WS-C3560-24PS-S",
        "WS-C3560-24PS-S-RF",
        "WS-C3560-24PS-S-WS",
        "WS-C3560-24TS",
        "WS-C3560-24TS-E",
        "WS-C3560-24TS-E-RF",
        "WS-C3560-24TS-E-WS",
        "WS-C3560-24TS-S",
        "WS-C3560-24TS-S-RF",
        "WS-C3560-24TS-S-WS",
        "WS-C3560-48PS",
        "WS-C3560-48PS-E",
        "WS-C3560-48PS-E-RF",
        "WS-C3560-48PS-E-WS",
        "WS-C3560-48PS-S",
        "WS-C3560-48PS-S-M",
        "WS-C3560-48PS-S-RF",
        "WS-C3560-48PS-S-WS",
        "WS-C3560-48TS",
        "WS-C3560-48TS-E",
        "WS-C3560-48TS-E-RF",
        "WS-C3560-48TS-E-WS",
        "WS-C3560-48TS-S",
        "WS-C3560-48TS-S-RF",
        "WS-C3560-48TS-S-WS",
        "WS-C3560-8PC",
        "WS-C3560-8PC-S",
        "WS-C3560-8PC-S-RF",
        "WS-C3560-8PC-S-WS",
        "WS-C3560C-12PC-S",
        "WS-C3560CG-8PC-S",
        "WS-C3560CG-8PCS-RF",
        "WS-C3560CG-8PCS-WS",
        "WS-C3560CG-8TC-L",
        "WS-C3560CG-8TC-S",
        "WS-C3560CG-8TCS-RF",
        "WS-C3560CG-8TCS-WS",
        "WS-C3560CPD-8PT-S",
        "WS-C3560CPD8PTS-RF",
        "WS-C3560CPD8PTS-WS",
        "WS-C3560CX-12PC",
        "WS-C3560CX-12PC-S",
        "WS-C3560CX-12PCS++",
        "WS-C3560CX-12PD-S",
        "WS-C3560CX-12PDS++",
        "WS-C3560CX-12TC",
        "WS-C3560CX-12TC-S",
        "WS-C3560CX-12TCS++",
        "WS-C3560CX-8PC",
        "WS-C3560CX-8PC-S",
        "WS-C3560CX-8PCS++",
        "WS-C3560CX-8PCS-RF",
        "WS-C3560CX-8PCS-WS",
        "WS-C3560CX-8PT",
        "WS-C3560CX-8PT-S",
        "WS-C3560CX-8PTS++",
        "WS-C3560CX-8PTS-RF",
        "WS-C3560CX-8PTS-WS",
        "WS-C3560CX-8TC",
        "WS-C3560CX-8TC-S",
        "WS-C3560CX-8TCS++",
        "WS-C3560CX-8TCS-RF",
        "WS-C3560CX-8TCS-WS",
        "WS-C3560CX-8XPD",
        "WS-C3560CX-8XPD-S",
        "WS-C3560CX-8XPDS++",
        "WS-C3560CX12PCS-RF",
        "WS-C3560CX12PCS-WS",
        "WS-C3560CX12PDS-RF",
        "WS-C3560CX12PDS-WS",
        "WS-C3560CX12TCS-RF",
        "WS-C3560CX12TCS-WS",
        "WS-C3560CX8XPDS-RF",
        "WS-C3560CX8XPDS-WS",
        "WS-C3560E-12D",
        "WS-C3560E-12D-E",
        "WS-C3560E-12D-S",
        "WS-C3560E-12D-S-RF",
        "WS-C3560E-12D-S-WS",
        "WS-C3560E-12SD",
        "WS-C3560E-12SD-E",
        "WS-C3560E-12SD-S",
        "WS-C3560E-12SDE-RF",
        "WS-C3560E-12SDE-WS",
        "WS-C3560E-24PD",
        "WS-C3560E-24PD-E",
        "WS-C3560E-24PD-S",
        "WS-C3560E-24PDE-RF",
        "WS-C3560E-24PDE-WS",
        "WS-C3560E-24TD",
        "WS-C3560E-24TD-E",
        "WS-C3560E-24TD-S",
        "WS-C3560E-24TDE-RF",
        "WS-C3560E-24TDE-WS",
        "WS-C3560E-48PD",
        "WS-C3560E-48PD-E",
        "WS-C3560E-48PD-S",
        "WS-C3560E-48PDE-WS",
        "WS-C3560E-48TD",
        "WS-C3560E-48TD-E",
        "WS-C3560E-48TD-S",
        "WS-C3560E-48TDE-RF",
        "WS-C3560E-48TDE-WS",
        "WS-C3560G-24PS",
        "WS-C3560G-24PS-E",
        "WS-C3560G-24PS-S",
        "WS-C3560G-24PS-S-M",
        "WS-C3560G-24PSE-RF",
        "WS-C3560G-24PSE-WS",
        "WS-C3560G-24PSS-RF",
        "WS-C3560G-24PSS-WS",
        "WS-C3560G-24TS",
        "WS-C3560G-24TS-E",
        "WS-C3560G-24TS-S",
        "WS-C3560G-24TSE-RF",
        "WS-C3560G-24TSE-WS",
        "WS-C3560G-24TSS-RF",
        "WS-C3560G-24TSS-WS",
        "WS-C3560G-48PS",
        "WS-C3560G-48PS-E",
        "WS-C3560G-48PS-S",
        "WS-C3560G-48PSE-RF",
        "WS-C3560G-48PSE-WS",
        "WS-C3560G-48PSS-RF",
        "WS-C3560G-48PSS-WS",
        "WS-C3560G-48TS",
        "WS-C3560G-48TS-E",
        "WS-C3560G-48TS-S",
        "WS-C3560G-48TSE-RF",
        "WS-C3560G-48TSE-WS",
        "WS-C3560G-48TSS-RF",
        "WS-C3560G-48TSS-WS",
        "WS-C3560V2-24DC",
        "WS-C3560V2-24PS",
        "WS-C3560V2-24PS-E",
        "WS-C3560V2-24PS-S",
        "WS-C3560V2-24TS",
        "WS-C3560V2-24TS-E",
        "WS-C3560V2-24TS-S",
        "WS-C3560V2-24TS-SD",
        "WS-C3560V2-48PS",
        "WS-C3560V2-48PS-E",
        "WS-C3560V2-48PS-S",
        "WS-C3560V2-48PS-SM",
        "WS-C3560V2-48TS",
        "WS-C3560V2-48TS-E",
        "WS-C3560V2-48TS-S",
        "WS-C3560V224PSE-RF",
        "WS-C3560V224PSE-WS",
        "WS-C3560V224PSS-RF",
        "WS-C3560V224PSS-WS",
        "WS-C3560V224TSE-RF",
        "WS-C3560V224TSE-WS",
        "WS-C3560V224TSS-RF",
        "WS-C3560V224TSS-WS",
        "WS-C3560V248PSE-RF",
        "WS-C3560V248PSE-WS",
        "WS-C3560V248PSS-RF",
        "WS-C3560V248PSS-WS",
        "WS-C3560V248TSE-RF",
        "WS-C3560V248TSE-WS",
        "WS-C3560V248TSS-RF",
        "WS-C3560V248TSS-WS",
        "WS-C3560X-24P",
        "WS-C3560X-24P-E",
        "WS-C3560X-24P-L",
        "WS-C3560X-24P-S",
        "WS-C3560X-24T",
        "WS-C3560X-24T-E",
        "WS-C3560X-24T-IND",
        "WS-C3560X-24T-L",
        "WS-C3560X-24T-S",
        "WS-C3560X-24T-S-WS",
        "WS-C3560X-24U",
        "WS-C3560X-24U-E",
        "WS-C3560X-24U-L",
        "WS-C3560X-24U-S",
        "WS-C3560X-48P",
        "WS-C3560X-48P-E",
        "WS-C3560X-48P-L",
        "WS-C3560X-48P-S",
        "WS-C3560X-48PF",
        "WS-C3560X-48PF-E",
        "WS-C3560X-48PF-L",
        "WS-C3560X-48PF-S",
        "WS-C3560X-48T-E",
        "WS-C3560X-48T-IND",
        "WS-C3560X-48T-L",
        "WS-C3560X-48T-L-WS",
        "WS-C3560X-48T-S",
        "WS-C3560X-48U",
        "WS-C3560X-48U-E",
        "WS-C3560X-48U-L",
        "WS-C3560X-48U-S",
        "WS-C3650-12X48UQ-E",
        "WS-C3650-24PD",
        "WS-C3650-24PD-E",
        "WS-C3650-24PDM-L",
        "WS-C3650-24PDM-S",
        "WS-C3650-24PS",
        "WS-C3650-24PS-E",
        "WS-C3650-24PS-S",
        "WS-C3650-24TD",
        "WS-C3650-24TD-E",
        "WS-C3650-24TS",
        "WS-C3650-24TS-E",
        "WS-C3650-48FQM-S",
        "WS-C3650-48PD",
        "WS-C3650-48PD-E",
        "WS-C3650-48PQ",
        "WS-C3650-48PQ-E",
        "WS-C3650-48PS",
        "WS-C3650-48PS-E",
        "WS-C3650-48TD",
        "WS-C3650-48TD-E",
        "WS-C3650-48TQ",
        "WS-C3650-48TQ-E",
        "WS-C3650-48TS",
        "WS-C3650-48TS-E",
        "WS-C3650-8X24UQ-E",
        "WS-C3750-24FS-S",
        "WS-C3750-24PS",
        "WS-C3750-24TS",
        "WS-C3750-48PS",
        "WS-C3750E-24PD-E",
        "WS-C3750E-24PD-S",
        "WS-C3750E-24TD-E",
        "WS-C3750E-24TD-S",
        "WS-C3750E-48PD-E",
        "WS-C3750E-48PD-EF",
        "WS-C3750E-48PD-S",
        "WS-C3750E-48PD-SF",
        "WS-C3750E-48TD-E",
        "WS-C3750E-48TD-S",
        "WS-C3750G-12S",
        "WS-C3750G-16TD",
        "WS-C3750G-24PS",
        "WS-C3750G-24T",
        "WS-C3750G-24WS-S25",
        "WS-C3750G-24WS-S50",
        "WS-C3750G-48PS",
        "WS-C3750G-48TS",
        "WS-C3750V2-24PS",
        "WS-C3750V2-24PS-E",
        "WS-C3750V2-24PS-S",
        "WS-C3750V2-24TS",
        "WS-C3750V2-24TS-E",
        "WS-C3750V2-48PS",
        "WS-C3750V2-48TS",
        "WS-C3750X-24P-L",
        "WS-C3750X-24P-S",
        "WS-C3750X-24T-L",
        "WS-C3750X-24T-S",
        "WS-C3750X-48P-L",
        "WS-C3750X-48PF-L",
        "WS-C3750X-48PF-S",
        "WS-C3750X-48T-L",
        "WS-C3750X-48T-S",
        "WS-C3850-12S",
        "WS-C3850-12S-E",
        "WS-C3850-12S-S",
        "WS-C3850-12X48U-E",
        "WS-C3850-12X48U-EJ",
        "WS-C3850-12XS",
        "WS-C3850-12XS-E",
        "WS-C3850-12XS-E-RF",
        "WS-C3850-24P",
        "WS-C3850-24P-E",
        "WS-C3850-24P-L",
        "WS-C3850-24P-S",
        "WS-C3850-24PW-S",
        "WS-C3850-24T",
        "WS-C3850-24T-E",
        "WS-C3850-24T-L",
        "WS-C3850-24T-S",
        "WS-C3850-24U",
        "WS-C3850-24U-E",
        "WS-C3850-24U-E++",
        "WS-C3850-24U-E-RF",
        "WS-C3850-24U-E-WS",
        "WS-C3850-24XS",
        "WS-C3850-24XS-E",
        "WS-C3850-24XS-E-RF",
        "WS-C3850-24XS-L",
        "WS-C3850-24XS-S",
        "WS-C3850-24XU",
        "WS-C3850-24XU-E",
        "WS-C3850-24XU-E++",
        "WS-C3850-24XU-E-RF",
        "WS-C3850-24XU-E-WS",
        "WS-C3850-24XU-EJ",
        "WS-C3850-48F",
        "WS-C3850-48F-E",
        "WS-C3850-48F-L",
        "WS-C3850-48F-S",
        "WS-C3850-48P",
        "WS-C3850-48P-E",
        "WS-C3850-48P-L",
        "WS-C3850-48P-S",
        "WS-C3850-48T",
        "WS-C3850-48T-E",
        "WS-C3850-48T-L",
        "WS-C3850-48T-S",
        "WS-C3850-48U",
        "WS-C3850-48U-E",
        "WS-C3850-48U-E++",
        "WS-C3850-48U-E-RF",
        "WS-C3850-48U-E-WS",
        "WS-C3850-48XS",
        "WS-C3850-48XS-E",
        "WS-C3850-48XS-E-WS",
        "WS-C385012X48UE-RF",
        "WS-C385012X48UE-WS",
        "WS-C3850E-12X48U",
        "WS-C3850E-12X48U-E",
        "WS-C3850E-24XU-E",
        "WS-C3900",
        "WS-C3920",
        "WS-C4003",
        "WS-C4003-RF",
        "WS-C4003-S1",
        "WS-C4003-S1-650AC",
        "WS-C4003-S1-650DC",
        "WS-C4003-S1-82",
        "WS-C4003-S1-DC",
        "WS-C4003-S1-RF",
        "WS-C4006-RF",
        "WS-C4006-S2",
        "WS-C4006-S2+",
        "WS-C4006-S2+-DC",
        "WS-C4006-S2+-DC-RF",
        "WS-C4006-S2+-RF",
        "WS-C4006-S2-650AC",
        "WS-C4006-S2-650DC",
        "WS-C4006-S2-DC",
        "WS-C4006-S2-DC-RF",
        "WS-C4006-S2-RF",
        "WS-C4006-S3",
        "WS-C4006-S3-650AC",
        "WS-C4006-S3-650DC",
        "WS-C4006-S3-DC",
        "WS-C4006-S3-DC-RF",
        "WS-C4006-S3-RF",
        "WS-C4006-S4",
        "WS-C4006-S4-DC",
        "WS-C4006-S4-DC-RF",
        "WS-C4006-S4-RF",
        "WS-C4006-S5",
        "WS-C4006-S5-DC",
        "WS-C4224V-8FXS",
        "WS-C4500X-16",
        "WS-C4500X-16SFP+",
        "WS-C4500X-16SFP++",
        "WS-C4500X-24X-ES",
        "WS-C4500X-32",
        "WS-C4500X-32SFP++",
        "WS-C4500X-F-16SFP+",
        "WS-C4500X16SFP+-RF",
        "WS-C4500X16SFP+-WS",
        "WS-C4500X32SFP+-WS",
        "WS-C4503",
        "WS-C4503-1400AC",
        "WS-C4503-1400DC",
        "WS-C4503-1500AC",
        "WS-C4503-2800AC",
        "WS-C4503-900AC",
        "WS-C4503-E",
        "WS-C4503-E++",
        "WS-C4503-E-S2+48",
        "WS-C4503-E-S2+48V",
        "WS-C4503-RF",
        "WS-C4503-S2+48",
        "WS-C4503-S2+48-RF",
        "WS-C4503-S2+48GB",
        "WS-C4503E+48V+",
        "WS-C4503E-48V++",
        "WS-C4503E-S6L-1300",
        "WS-C4503E-S6L-48V+",
        "WS-C4503E-S6L-48VE",
        "WS-C4503E-S7L+10GB",
        "WS-C4503E-S7L+48V+",
        "WS-C4503E-S8L+10GB",
        "WS-C4506",
        "WS-C4506-1400AC",
        "WS-C4506-1400DC",
        "WS-C4506-1500AC",
        "WS-C4506-2800AC",
        "WS-C4506-900AC",
        "WS-C4506-E",
        "WS-C4506-E++",
        "WS-C4506-RF",
        "WS-C4506-S2+96",
        "WS-C4506-S2+96-RF",
        "WS-C4506-S4-96",
        "WS-C4506-S4-AP",
        "WS-C4506-WS",
        "WS-C4506E+96",
        "WS-C4506E+96-RF",
        "WS-C4506E+96V+",
        "WS-C4506E-10GE-96V",
        "WS-C4506E-96V++",
        "WS-C4506E-GE-96V",
        "WS-C4506E-S6L-1300",
        "WS-C4506E-S6L-2800",
        "WS-C4506E-S6L-4200",
        "WS-C4506E-S6L-96V+",
        "WS-C4506E-S6L-96VE",
        "WS-C4506E-S7L+10GB",
        "WS-C4506E-S7L+96",
        "WS-C4506E-S7L+96V+",
        "WS-C4506E-S8L+10GB",
        "WS-C4507R",
        "WS-C4507R+E",
        "WS-C4507R+E++",
        "WS-C4507R-E",
        "WS-C4507R-E-S+96",
        "WS-C4507R-E-S2+240",
        "WS-C4507R-E-S2+96",
        "WS-C4507R-E-S2+96V",
        "WS-C4507R-RF",
        "WS-C4507RE+96",
        "WS-C4507RE+96V+",
        "WS-C4507RE+96V++",
        "WS-C4507RE-1S2+240",
        "WS-C4507RE-2S2+240",
        "WS-C4507RE-S7+10GB",
        "WS-C4507RE-S7L+96",
        "WS-C4507RES6L+96V+",
        "WS-C4507RES6L-96V+",
        "WS-C4507RES7L+96V+",
        "WS-C4507S7L-XG96V",
        "WS-C4510R",
        "WS-C4510R+E",
        "WS-C4510R+E++",
        "WS-C4510R-1S5-10GE",
        "WS-C4510R-2S5-10GE",
        "WS-C4510R-E",
        "WS-C4510R-RF",
        "WS-C4510R-WS",
        "WS-C4510RE+96",
        "WS-C4510RE+96V++",
        "WS-C4510RE-S6-96V+",
        "WS-C4510RE-S7+96V+",
        "WS-C4510RE-S8+96V+",
        "WS-C4840G",
        "WS-C4840G-RF",
        "WS-C4900M",
        "WS-C4908G-L3",
        "WS-C4908G-L3-RF",
        "WS-C4912G",
        "WS-C4912G-3PACK",
        "WS-C4912G-RF",
        "WS-C4928-10GE",
        "WS-C4928-10GE-WS",
        "WS-C4948",
        "WS-C4948-10GE-E",
        "WS-C4948-10GE-E-RF",
        "WS-C4948-10GE-E-WS",
        "WS-C4948-10GE-S",
        "WS-C4948-10GE-S-RF",
        "WS-C4948-10GE-S-WS",
        "WS-C4948-10GE-WS",
        "WS-C4948-10GEBDL",
        "WS-C4948-BDL",
        "WS-C4948-E",
        "WS-C4948-E-RF",
        "WS-C4948-E-WS",
        "WS-C4948-S",
        "WS-C4948-S-RF",
        "WS-C4948-S-WS",
        "WS-C4948-WS",
        "WS-C4948E",
        "WS-C4948E-BDL",
        "WS-C4948E-E",
        "WS-C4948E-E-RF",
        "WS-C4948E-E-WS",
        "WS-C4948E-F",
        "WS-C4948E-F-E",
        "WS-C4948E-F-E-RF",
        "WS-C4948E-F-E-WS",
        "WS-C4948E-F-RF",
        "WS-C4948E-F-S",
        "WS-C4948E-F-S-RF",
        "WS-C4948E-F-S-WS",
        "WS-C4948E-F-WS",
        "WS-C4948E-L",
        "WS-C4948E-S",
        "WS-C4948E-S-RF",
        "WS-C4948E-S-WS",
        "WS-C5000",
        "WS-C5002",
        "WS-C5500",
        "WS-C5505",
        "WS-C5509",
        "WS-C6006",
        "WS-C6006-1000AC-RF",
        "WS-C6006-1000AC=",
        "WS-C6006-1300AC-RF",
        "WS-C6006-1300AC=",
        "WS-C6006-RF",
        "WS-C6006-S1",
        "WS-C6006-S1-MSM",
        "WS-C6009",
        "WS-C6009-1300AC=",
        "WS-C6009-2500AC-RF",
        "WS-C6009-2500AC=",
        "WS-C6009-RF",
        "WS-C6009-S1",
        "WS-C6503",
        "WS-C6503-FWM-K9",
        "WS-C6503-PFC2",
        "WS-C6504-E",
        "WS-C6504-E-6716-K9",
        "WS-C6504-E-VPN+-K9",
        "WS-C6504-E-WISM",
        "WS-C6504-E-WISM-RF",
        "WS-C6504-E-WS",
        "WS-C6504-EACEK9-RF",
        "WS-C6504E-S32-10GE",
        "WS-C6504E-S32-GE",
        "WS-C6504E-S32GE-RF",
        "WS-C6504E-S32P-GE",
        "WS-C6504E-S32P10GE",
        "WS-C6504E-VPN/K9",
        "WS-C6506",
        "WS-C6506-1000AC-RF",
        "WS-C6506-1000AC=",
        "WS-C6506-1300AC-RF",
        "WS-C6506-1300AC=",
        "WS-C6506-FWM-K9",
        "WS-C6506-IPSEC-K9",
        "WS-C6506-PFC2",
        "WS-C6506-RF",
        "WS-C6506-S1",
        "WS-C6506-WS",
        "WS-C6509",
        "WS-C6509-1300AC-RF",
        "WS-C6509-1300AC=",
        "WS-C6509-2500AC-RF",
        "WS-C6509-2500AC=",
        "WS-C6509-E",
        "WS-C6509-E-FWM-K9",
        "WS-C6509-EFWMK9-RF",
        "WS-C6509-FW-IDS-K9",
        "WS-C6509-FW-VPN-K9",
        "WS-C6509-NEB",
        "WS-C6509-NEB-A",
        "WS-C6509-NEB-A-RF",
        "WS-C6509-NEB-RF",
        "WS-C6509-NEBFAN-RF",
        "WS-C6509-NV",
        "WS-C6509-PFC2",
        "WS-C6509-RF",
        "WS-C6509-S1",
        "WS-C6509-S720-6724",
        "WS-C6509-V-E",
        "WS-C6509-V-E-WS",
        "WS-C6509-WS",
        "WS-C6513",
        "WS-C6513-2500AC-RF",
        "WS-C6513-FWM-K8",
        "WS-C6513-FWM-K9",
        "WS-C6513-FWM-K9-RF",
        "WS-C6513-S32-10GE",
        "WS-C6513-S32-GE",
        "WS-C6513-S32-GE-RF",
        "WS-C6513-S32P-GE",
        "WS-C6513-S32P10GE",
        "WS-C6513-VPN+-K9",
        "WS-C6513-VPN/K9",
        "WS-C6513-WS",
        "WS-C6513-XL-FWM-K8",
        "WS-C6513-XL-FWM-K9",
        "WS-C65134KWACUS-RF",
        "WS-C6513=",
        "WS-C6513S3210GE-RF",
        "WS-C6513XLFWMK9-RF",
        "WS-CBS3012-IBM-I",
        "WS-CBS3020-HPQ",
        "WS-CBS3030-DEL-F",
        "WS-CBS3030-DEL-S",
        "WS-CBS3032-DEL",
        "WS-CBS3040-FSC",
        "WS-CBS3110G-S-I",
        "WS-CBS3110X-S-I",
        "WS-CBS3120G-S",
        "WS-CBS3120X-S",
        "WS-CBS3125G-S",
        "WS-CBS3125X-S",
        "WS-CBS3130G-S",
        "WS-CBS3130X-S",
        "WS-XC10R",
        "WS-XC4R",
        "WS-XC7R",
        "WS4506E-8L+96SFP++",
        "WS4507E-8L+96SFP++",
        "WS4510E-S8+96SFP++",
        "WSC3560V248PSSM-RF",
        "WSC385012X48UWS-WS",
        "WSC4503ES7L48V+-RF",
        "WSC4506ES6L2800-RF",
        "WSC4506ES6L4200-RF",
        "WSC4506ES6L96V+-RF",
        "WSC4507RES7L+96-WS",
        "WSC4510RES696V+-RF",
        "WSC6504EACE20K9-RF",
        "WSC6504ES3210GE-RF",
        "c9300-24P"
    ],

    "PID_DETAIL_TEMPLATE": {
        "associatedProductIds":["C9300-48P","C9300-24UX","c9300-24P","C9300-48UXM","C9300-48T","C9300-48U","C9300-24T"],
        "associatedSystemObjectIds":["1.3.6.1.4.1.9.1.2494"],
        "bootstrapParserRule":null,
        "ciscoMdfId":"286315874",
        "iconFile":"16x16-Layer_3_blue_SF.gif",
        "rootGroupingElement":{
            "id":"268438038",
            "name":"Switches and Hubs",
            "nextElement":{
                "id":"286313806",
                "name":"Cisco Catalyst 9300 Series Switches",
                "nextElement":{
                    "id":"286315874",
                    "name":"Cisco Catalyst 9300 Switch",
                    "nextElement":null
                }
            }
        },
        "supportLevel":"Supported",
        "supportLevelCriteria":null,
        "supportedFeatures": []
    }

});