define({
    "SITE_DEFAULT_DATA": [
    { parent: "global" ,name: "Europe",location : { "nameSpace": "Location","attributes": { "type": "area"}}},
    { parent: "global" ,name: "Asia",location : { "nameSpace": "Location","attributes": { "type": "area"}}},
    { parent: "global" ,name: "North America",location : { "nameSpace": "Location","attributes": { "type": "area"}}},
    { parent: "global" ,name: "South America",location : { "nameSpace": "Location","attributes": { "type": "area"}}},
    { parent: "global" ,name: "Africa",location : { "nameSpace": "Location","attributes": { "type": "area"}}},
    { parent: "Europe" ,name: "England",location : { "nameSpace": "Location","attributes": { "type": "area"}}},
    { parent: "Europe" ,name: "Germany",location : { "nameSpace": "Location","attributes": { "type": "area"}}},
    { parent: "Europe" ,name: "France",location : { "nameSpace": "Location","attributes": { "type": "area"}}},
    { parent: "Europe" ,name: "Spain",location : { "nameSpace": "Location","attributes": { "type": "area"}}},
    { parent: "Asia" ,name: "ANZ",location : { "nameSpace": "Location","attributes": { "type": "area"}}},
    { parent: "Asia" ,name: "China",location : { "nameSpace": "Location","attributes": { "type": "area"}}},
    { parent: "Asia" ,name: "Japan",location : { "nameSpace": "Location","attributes": { "type": "area"}}},
    { parent: "Asia" ,name: "Korea",location : { "nameSpace": "Location","attributes": { "type": "area"}}},
    { parent: "Africa" ,name: "Egypt",location : { "nameSpace": "Location","attributes": { "type": "area"}}},
    { parent: "Africa" ,name: "South Africa",location : { "nameSpace": "Location","attributes": { "type": "area"}}},
    { parent: "North America" ,name: "USA",location : { "nameSpace": "Location","attributes": { "type": "area"}}},
    { parent: "North America" ,name: "Canada",location : { "nameSpace": "Location","attributes": { "type": "area"}}},
    { parent: "North America" ,name: "Mexico",location : { "nameSpace": "Location","attributes": { "type": "area"}}},
    { parent: "USA" ,name: "New York",location : { "nameSpace": "Location","attributes": { "type": "area"}}},
    { parent: "USA" ,name: "California",location : { "nameSpace": "Location","attributes": { "type": "area"}}},
    { parent: "USA" ,name: "Washington",location : { "nameSpace": "Location","attributes": { "type": "area"}}},
    { parent: "USA" ,name: "Texas",location : { "nameSpace": "Location","attributes": { "type": "area"}}},
    { parent: "California" ,name: "San Jose",location : { "nameSpace": "Location","attributes": { "type": "area"}}},
    { parent: "California" ,name: "San Francisco",location : { "nameSpace": "Location","attributes": { "type": "area"}}},
    { parent: "San Jose" ,name: "SJC01", location : { "nameSpace": "Location","attributes": { "country": "United States","address": "3850 Zanker Road, San Jose, California 95134, United States","latitude": "37.414724","type": "building","longitude": "-121.936660"}}},
    { parent: "SJC01" ,name: "Flr-SJC1-1", type: "floor",location:{"address":"3850 Zanker Road, San Jose, California 95134, United States","lat":"37.414724","lon":"-121.936660"}},
    { parent: "San Jose" ,name: "SJC06",location : { "nameSpace": "Location","attributes": {"country": "United States","address": "3800 Zanker Road, San Jose, California 95134, United States","latitude": "37.414373","type": "building","longitude": "-121.929769"}}},
    { parent: "SJC06" ,name: "Flr-SJC6-1", type: "floor", location :{"address":"3800 Zanker Road, San Jose, California 95134, United States","lat":"37.414373","lon":"-121.929769"} },
    { parent: "San Jose" ,name: "SJC05",location : { "nameSpace": "Location","attributes": {"country": "United States","address": "325 East Tasman Drive, San Jose, California 95134, United States","latitude": "37.414456","type": "building","longitude": "-121.931350"}}},
    { parent: "SJC05" ,name: "Flr-SJC5-1", type: "floor", location :{"address":"325 East Tasman Drive, San Jose, California 95134, United States","lat":"37.414456","lon":"-121.931350"} },
    { parent: "San Jose" ,name: "SJC04",location : { "nameSpace": "Location","attributes": {"country": "United States","address": "275 East Tasman Drive, San Jose, California 95134, United States","latitude": "37.414334","type": "building","longitude": "-121.933040"}}},
    { parent: "SJC04" ,name: "Flr-SJC4-1", type: "floor", location :{"address":"275 East Tasman Drive, San Jose, California 95134, United States","lat":"37.414334","lon":"-121.933040"} },
    { parent: "San Jose" ,name: "SJC23",location : { "nameSpace": "Location","attributes": {"country": "United States","address": "300 East Tasman Drive, San Jose, California 95134, United States","latitude": "37.414334","type": "building","longitude": "-121.933040"}}},
    { parent: "SJC23" ,name: "Flr-SJC23-1", type: "floor", location :{"address":"300 East Tasman Drive, San Jose, California 95134, United States","lat":"37.414334","lon":"-121.933040"} },
    { parent: "San Jose" ,name: "SJC22", location : { "nameSpace": "Location","attributes": { "country": "United States","address": "Alder Drive, Milpitas, California 95035, United States","latitude": "37.412450","type": "building","longitude": "-121.916811"}}},
    { parent: "SJC22" ,name: "Flr-SJC22-1", type: "floor",location:{"address":"Alder Drive, Milpitas, California 95035, United States","lat":"37.412450","lon":"-121.916811"}},
    { parent: "San Francisco" ,name: "SFO12", location : { "nameSpace": "Location","attributes": { "country": "United States","address": "500 Terry A. Francois Blvd San Francisco, California 94158 United States","latitude": "37.770472","type": "building","longitude": "-122.387080"}}},
    { parent: "SFO12" ,name: "Flr-SFO12-1", type: "floor",location:{"address":"500 Terry A. Francois Blvd San Francisco, California 94158 United States","lat":"37.770472","lon":"-122.387080"}},
    { parent: "San Francisco" ,name: "SFO15", location : { "nameSpace": "Location","attributes": { "country": "United States","address": "500 Terry A. Francois Blvd San Francisco, California 94158 United States","latitude": "37.770472","type": "building","longitude": "-122.387080"}}},
    { parent: "SFO15" ,name: "Flr-SFO15-1", type: "floor",location:{"address":"500 Terry A. Francois Blvd San Francisco, California 94158 United States","lat":"37.770472","lon":"-122.387080"}},
    { parent: "San Francisco" ,name: "SFO10", location : { "nameSpace": "Location","attributes": { "country": "United States","address": "500 Terry A. Francois Blvd San Francisco, California 94158 United States","latitude": "37.770472","type": "building","longitude": "-122.387080"}}},
    { parent: "SFO10" ,name: "Flr-SFO10-1", type: "floor",location:{"address":"500 Terry A. Francois Blvd San Francisco, California 94158 United States","lat":"37.770472","lon":"-122.387080"}},
    { parent: "San Francisco" ,name: "SFO11", location : { "nameSpace": "Location","attributes": { "country": "United States","address": "500 Terry A. Francois Blvd San Francisco, California 94158 United States","latitude": "37.770472","type": "building","longitude": "-122.387080"}}},
    { parent: "SFO11" ,name: "Flr-SFO11-1", type: "floor",location:{"address":"500 Terry A. Francois Blvd San Francisco, California 94158 United States","lat":"37.770472","lon":"-122.387080"}},
    { parent: "San Francisco" ,name: "SFO13", location : { "nameSpace": "Location","attributes": { "country": "United States","address": "500 Terry A. Francois Blvd San Francisco, California 94158 United States","latitude": "37.770472","type": "building","longitude": "-122.387080"}}},
    { parent: "SFO13" ,name: "Flr-SFO13-1", type: "floor",location:{"address":"500 Terry A. Francois Blvd San Francisco, California 94158 United States","lat":"37.770472","lon":"-122.387080"}},
    { parent: "New York" ,name: "NY1", location : { "nameSpace": "Location","attributes": {"country": "United States","address": "New York, New York, United States","latitude": "40.7648","type": "building", "longitude": "-73.9808"}}},
    { parent: "NY1" ,name: "Flr-NY-1", type: "floor", location :{"address":"New York, New York, United States","lat":"40.7648","lon":"-73.9808"} },
    { parent: "Texas" ,name: "Austin",location : { "nameSpace": "Location","attributes": { "type": "area"}}},
    { parent: "Austin" ,name: "AU1",location : { "nameSpace": "Location","attributes": {"country": "United States","address": "Austin, Texas, United States","latitude": "30.2711", "type": "building","longitude": "-97.7437"}}},
    { parent: "AU1" ,name: "Flr-AU-1", type: "floor", location : {"address":"Austin, Texas, United States","lat":"30.2711","lon":"-97.7437"}},
    { parent: "Texas" ,name: "San Antonio",location : { "nameSpace": "Location","attributes": { "type": "area"}}},
    { parent: "San Antonio" ,name: "SA01",location : { "nameSpace": "Location","attributes": {"country": "United States","address": "San Antonio, Texas, United States","latitude": "29.453948", "type": "building","longitude": "-98.541870"}}},
    { parent: "SA01" ,name: "Flr-SA-1", type: "floor", location : {"address":"San Antonio, Texas, United States","lat":"29.453948","lon":"-98.541870"}},
    { parent: "Washington" ,name: "Seattle",location : { "nameSpace": "Location","attributes": { "type": "area"}}},
    { parent: "Seattle" ,name: "SE1", location : { "nameSpace": "Location","attributes": { "country": "United States","address": "Seattle, Washington, United States","latitude": "47.6038","type": "building","longitude": "-122.3301"}}},
    { parent: "SE1" ,name: "Flr-SE-1", type: "floor", location : {"address":"Seattle, Washington, United States","lat":"47.6038","lon":"-122.3301"}},
    { parent: "California" ,name: "Pleasanton",location : { "nameSpace": "Location","attributes": { "type": "area"}}},
    { parent: "Pleasanton" ,name: "PLS06", location : { "nameSpace": "Location","attributes": { "country": "United States","address": "4460 Rosewood Drive, Pleasanton, California 94588, United States","latitude": "37.697759","type": "building","longitude": "-121.883799"}}},
    { parent: "PLS06" ,name: "Flr-PLS06-1", type: "floor",location:{"address":"4460 Rosewood Drive, Pleasanton, California 94588, United States","lat":"37.697759","lon":"-121.883799"}},
    { parent: "South America" ,name: "Brazil",location : { "nameSpace": "Location","attributes": { "type": "area"}}},
    { parent: "South America" ,name: "Argentina",location : { "nameSpace": "Location","attributes": { "type": "area"}}}
    ]
});
