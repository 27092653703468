define({
    "overall":{
        "summary":[
            {
               "serverType":"AAA",
               "serverCount":8,
               "transactions":{
                  "total":331,
                  "successful":304,
                  "percentageSuccessful":87,
                  "failed":45,
                  "percentageFailed":13.0,
                  "totalPercentageChange":54.55,
                  "successfulPercentageChange":54.55,
               "failedPercentageChange":null
            },
            "latency":{
               "averageLatency":8,
               "percentageChange":-11.11
            }, 
            "insight":{
                "success":[
                   {
                      "serverIp":"170.204.94.112",
                      "percentageChange":0.4
                   },
                   {
                      "serverIp":"10.1.30.75",
                      "percentageChange":0.0
                   },
                   {
                    "serverIp":"10.1.30.89",
                    "percentageChange":0.0
                 }
              ],
              "failed":[
                 {
                    "serverIp":"10.1.30.71",
                    "percentageChange":0.0
                 },
                 {
                    "serverIp":"10.1.30.75",
                    "percentageChange":0.0
                 },
                 {
                    "serverIp":"10.1.30.89",
                    "percentageChange":0.0
                 }
              ]
           }
        },
         {
            "serverType":"DHCP",
            "serverCount":6,
            "transactions":{
               "total":59,
               "successful":53,
               "percentageSuccessful":89,
               "failed":6,
               "percentageFailed":11,
               "totalPercentageChange":90.53,
               "successfulPercentageChange":87.53,
               "failedPercentageChange":null
            },
            "latency":{
                "averageLatency":574,
                "percentageChange":32.56
             },
             "insight":{
                "success":[
                   {
                      "serverIp":"192.168.152.1",
                      "percentageChange":0
                   }
                ],
                "failed":[
                   {
                      "serverIp":"192.168.152.1",
                      "percentageChange":0
                   }
                ]
             }
          }
       ]
    },
    "server":{
        "version":"1.0",
        "response":[
            {
              "serverIp":"106.235.200.202",
              "wlcName":"WLC-9800",
              "wlcUuid":"32d8b0d6-0363-1474-8282-834c465c20cb",
              "wlcLocation":"Global/North America/USA/California/San Jose/SJC01",
              "totalTransaction":238,
              "failedTransaction":28,
              "eapTransaction":238,
              "mabTransaction":0,
              "eapFailedTransaction":28,
              "mabFailedTransaction":0,
              "totalAvgLatency":150,
              "eapAvgLatency":150,
              "mabAvgLatency":null,
              "doAvgLatency":150,
              "raAvgLatency":null
            },{
                "serverIp":"109.7.150.69",
                "wlcName":"SWLC-FABRIC-01",
                "wlcUuid":"dd0c8391-e48d-983f-fa9a-586e19abdaa1",
                "wlcLocation":"Global/North America/USA/California/San Jose/SJC01",
                "totalTransaction":13,
                "failedTransaction":4,
                "eapTransaction":13,
                "mabTransaction":0,
                "eapFailedTransaction":4,
                "mabFailedTransaction":0,
                "totalAvgLatency":5,
                "eapAvgLatency":5,
                "mabAvgLatency":null,
                "doAvgLatency":5,
                "raAvgLatency":null
              },
              {
                "serverIp":"14.10.181.87",
                "wlcName":"SJC06-vWLC-9800",
                "wlcUuid":"855c5cb7-363d-166f-d8d8-d06bc54fcc53",
                "wlcLocation":"Global/North America/USA/California/San Jose/SJC06",
                "totalTransaction":9,
                "failedTransaction":2,
                "eapTransaction":9,
                "mabTransaction":0,
                "eapFailedTransaction":2,
                "mabFailedTransaction":0,
                "totalAvgLatency":6,
                "eapAvgLatency":6,
                "mabAvgLatency":null,
                "doAvgLatency":6,
                "raAvgLatency":null
              },
             {
                "serverIp":"140.102.148.249",
                "wlcName":"Campus_WLC3",
                "wlcUuid":"ca6fcfa9-66ea-dc59-31b3-f7bbbf664d05",
                "wlcLocation":"Global/North America/USA/California/San Jose/SJC05",
                "totalTransaction":6,
                "failedTransaction":2,
                "eapTransaction":6,
                "mabTransaction":0,
                "eapFailedTransaction":2,
                "mabFailedTransaction":0,
                "totalAvgLatency":4,
                "eapAvgLatency":4,
                "mabAvgLatency":null,
                "doAvgLatency":5,
                "raAvgLatency":null
              },
              {
                "serverIp":"158.128.154.123",
                "wlcName":"Campus_WLC4",
                "wlcUuid":"82e06d214-e186-b3cc-b2ac-bb8f7be968d8",
                "wlcLocation":"Global/North America/USA/Washington/Seattle/SE1",
                "totalTransaction":8,
                "failedTransaction":4,
                "eapTransaction":8,
                "mabTransaction":0,
                "eapFailedTransaction":4,
                "mabFailedTransaction":0,
                "totalAvgLatency":6,
                "eapAvgLatency":6,
                "mabAvgLatency":null,
                "doAvgLatency":6,
                "raAvgLatency":null
              },
              {
                "serverIp":"170.204.94.112",
                "wlcName":"SJC06-WLC-ISSU",
                "wlcUuid":"273f236c-245d-0ef6-6304-2c25bdd93f8b",
                "wlcLocation":"Global/North America/USA/California/San Jose/SJC06",
                "totalTransaction":7,
                "failedTransaction":1,
                "eapTransaction":7,
                "mabTransaction":0,
                "eapFailedTransaction":1,
                "mabFailedTransaction":0,
                "totalAvgLatency":4,
                "eapAvgLatency":4,
                "mabAvgLatency":null,
                "doAvgLatency":4,
                "raAvgLatency":null
              },
              {
                "serverIp":"173.219.75.50",
                "wlcName":"PLS06-WLC-9800",
                "wlcUuid":"70ba00f3-898f-ffb8-8108-a7bf4e820e62",
                "wlcLocation":"Global/North America/USA/California/Pleasanton/PLS06",
                "totalTransaction":10,
                "failedTransaction":2,
                "eapTransaction":10,
                "mabTransaction":0,
                "eapFailedTransaction":2,
                "mabFailedTransaction":0,
                "totalAvgLatency":5,
                "eapAvgLatency":5,
                "mabAvgLatency":null,
                "doAvgLatency":5,
                "raAvgLatency":null
              },
              {
                "serverIp":"207.49.188.94",
                "wlcName":"SJC06-vWLC-9800",
                "wlcUuid":"855c5cb7-363d-166f-d8d8-d06bc54fcc53",
                "wlcLocation":"Global/North America/USA/California/San Jose/SJC06",
                "totalTransaction":13,
                "failedTransaction":2,
                "eapTransaction":13,
                "mabTransaction":0,
                "eapFailedTransaction":2,
                "mabFailedTransaction":0,
                "totalAvgLatency":3,
                "eapAvgLatency":3,
                "mabAvgLatency":null,
                "doAvgLatency":3,
                "raAvgLatency":null
              }
        ],
        "page":{
            "pageSize":1,
            "offset":0,
            "totalCount":1,
            "randomMacCount":null,
            "duidCount":null,
            "nextKey":null,
            "topN":null
        }
    },
    "ALL_TYPE_OF_LATENCY" :{
        
            "detailList":[
               {
                  "rankVar":null,
                  "rankValue":null,
                  "varName":"eapAvgLatency",
                  "varValue":null,
                  "detailList":[
                    {
                       "rankVar":"serverIp",
                       "rankValue":"106.235.200.202",
                       "varName":"eapAvgLatency",
                       "varValue":null,
                       "detailList":[
                          {
                             "rankVar":null,
                             "rankValue":"2021-08-09T17:25:00.000+0000",
                             "varName":"eapAvgLatency",
                             "varValue":7.0
                           }
                        ]
                    },
                    {
                        "rankVar":"serverIp",
                        "rankValue":"109.7.150.69",
                        "varName":"eapAvgLatency",
                        "varValue":null,
                        "detailList":[
                           {
                              "rankVar":null,
                              "rankValue":"2021-08-09T17:25:00.000+0000",
                              "varName":"eapAvgLatency",
                              "varValue":7.0
                            }
                         ]
                     },{
                        "rankVar":"serverIp",
                        "rankValue":"14.10.181.87",
                        "varName":"eapAvgLatency",
                        "varValue":null,
                        "detailList":[
                           {
                              "rankVar":null,
                              "rankValue":"2021-08-09T17:25:00.000+0000",
                              "varName":"eapAvgLatency",
                              "varValue":7.0
                            }
                         ]
                     },{
                        "rankVar":"serverIp",
                        "rankValue":"140.102.148.249",
                        "varName":"eapAvgLatency",
                        "varValue":null,
                        "detailList":[
                           {
                              "rankVar":null,
                              "rankValue":"2021-08-09T17:25:00.000+0000",
                              "varName":"eapAvgLatency",
                              "varValue":7.0
                            }
                         ]
                     }
                    ]
                },
                {
                  "rankVar":null,
                  "rankValue":null,
                  "varName":"mabAvgLatency",
                  "varValue":null,
                  "detailList":[
                    {
                       "rankVar":"serverIp",
                       "rankValue":"158.128.154.123",
                       "varName":"mabAvgLatency",
                       "varValue":null,
                       "detailList":[
                          {
                             "rankVar":null,
                             "rankValue":"2021-08-09T17:25:00.000+0000",
                             "varName":"mabAvgLatency",
                             "varValue":7.0
                           }
                        ]
                    },
                    {
                        "rankVar":"serverIp",
                        "rankValue":"170.204.94.112",
                        "varName":"mabAvgLatency",
                        "varValue":null,
                        "detailList":[
                           {
                              "rankVar":null,
                              "rankValue":"2021-08-09T17:25:00.000+0000",
                              "varName":"mabAvgLatency",
                              "varValue":7.0
                            }
                         ]
                     },{
                        "rankVar":"serverIp",
                        "rankValue":"173.219.75.50",
                        "varName":"mabAvgLatency",
                        "varValue":null,
                        "detailList":[
                           {
                              "rankVar":null,
                              "rankValue":"2021-08-09T17:25:00.000+0000",
                              "varName":"mabAvgLatency",
                              "varValue":7.0
                            }
                         ]
                     },{
                        "rankVar":"serverIp",
                        "rankValue":"1207.49.188.94",
                        "varName":"mabAvgLatency",
                        "varValue":null,
                        "detailList":[
                           {
                              "rankVar":null,
                              "rankValue":"2021-08-09T17:25:00.000+0000",
                              "varName":"mabAvgLatency",
                              "varValue":7.0
                            }
                         ]
                     }
                    ]
                },
                {
                    "rankVar":null,
                    "rankValue":null,
                    "varName":"totalAvgLatency",
                    "varValue":null,
                    "detailList":[
                        {
                        "rankVar":"serverIp",
                        "rankValue":"106.235.200.202",
                        "varName":"totalAvgLatency",
                        "varValue":null,
                        "detailList":[
                            {
                                "rankVar":null,
                                "rankValue":"2021-08-09T17:25:00.000+0000",
                                "varName":"totalAvgLatency",
                                "varValue":8.0
                            }
                            ]
                        },
                        {
                            "rankVar":"serverIp",
                            "rankValue":"109.7.150.69",
                            "varName":"totalAvgLatency",
                            "varValue":null,
                            "detailList":[
                                {
                                    "rankVar":null,
                                    "rankValue":"2021-08-09T17:25:00.000+0000",
                                    "varName":"totalAvgLatency",
                                    "varValue":8.0
                                }
                                ]
                            },
                            {
                                "rankVar":"serverIp",
                                "rankValue":"14.10.181.87",
                                "varName":"totalAvgLatency",
                                "varValue":null,
                                "detailList":[
                                    {
                                        "rankVar":null,
                                        "rankValue":"2021-08-09T17:25:00.000+0000",
                                        "varName":"totalAvgLatency",
                                        "varValue":8.0
                                    }
                                    ]
                            },
                            {
                                "rankVar":"serverIp",
                                "rankValue":"140.102.148.249",
                                "varName":"eapAvgLatency",
                                "varValue":null,
                                "detailList":[
                                   {
                                      "rankVar":null,
                                      "rankValue":"2021-08-09T17:25:00.000+0000",
                                      "varName":"eapAvgLatency",
                                      "varValue":7.0
                                    }
                                 ]
                             }
                    ]
                }
            ]
        },
        "TREND":
        {
                "totalTransactions":3,
                "successfulTransactions":3,
                "failedTransactions":0,
                "time":"2021-08-08T10:05:00.000+0000",
                "value":0.0
        },
        "TotalAvgLatency":{
            
                "rankVar":"fdcf51fe-c2b6-4e64-81a8-11a7bd04ef0d",
                "rankValue":"San Jose/SJC14/Floor 1",
                "varName":"aaaAvgLatency",
                "varValue":9
            
        },
        "IndividualServer":{
            "latencyTrends":{
                "detailList":[
                   {
                      "rankVar":null,
                      "rankValue":null,
                      "varName":"totalAvgLatency",
                      "varValue":null,
                      "detailList":[
                        {
                           "rankVar":"serverIp",
                           "rankValue":"111.97.109.102",
                           "varName":"totalAvgLatency",
                           "varValue":null,
                            "detailList":[
                            {
                                "rankVar":null,
                                "rankValue":"2021-10-04T04:00:00.000+0000",
                                "varName":"totalAvgLatency",
                                "varValue":null
                            }]
                        }]
                    }
                ]
            },
            "transactionTrends":{
                "detailList":[
                   {
                      "rankVar":null,
                      "rankValue":null,
                      "varName":"totalTransactions",
                      "varValue":null,
                      "detailList":[
                        {
                           "rankVar":"serverIp",
                           "rankValue":"111.97.109.102",
                           "varName":"totalTransactions",
                           "varValue":null,
                            "detailList":[
                            {
                                "rankVar":null,
                                "rankValue":"2021-10-04T04:00:00.000+0000",
                                "varName":"totalTransactions",
                                "varValue":null,
                                "detailList":[
                                    {
                                        "rankVar":null,
                                        "rankValue":"2021-10-08T15:00:00.000+0000",
                                        "varName":"totalFailedTransactions",
                                        "varValue":2.0
                                     },
                                     {
                                        "rankVar":null,
                                        "rankValue":"2021-10-08T15:00:00.000+0000",
                                        "varName":"totalSuccessTransactions",
                                        "varValue":4.0
                                     }
                                  ]
                            }]
                        }]
                    }
                ]
            }

        }
    

})