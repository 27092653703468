define([
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/database/SimLokiDatabaseActions',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/inventory/NetworkDeviceTemplate',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/utilities/UtilityFunctions',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/data-generation/configuration/SimTaskDataGenerator',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/data-generation/services/SimOnboardingData',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/workflow/ReplaceDeviceWorkflowTemplate',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/data-generation/services/SimNetworkDeviceData',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/inventory/RmaTemplate',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/data-generation/configuration/DefaultConfig'
], function (SimLokiDatabaseActions, NetworkDeviceTemplate, UtilityFunctions, SimTaskDataGenerator, SimOnboardingData, 
    ReplaceDeviceWorkflowTemplate, SimNetworkDeviceData, RmaTemplate, DefaultConfig) {

    return {
        init: function () {
            //
        },

        markForReplace: function(restPayload) {
            var resultObj = SimTaskDataGenerator.createTask('Device Replacement Service');
            var ids= [], status = "READY-FOR-REPLACEMENT";
            restPayload.forEach(device => {
                var devId = device.faultyDeviceId != undefined ? device.faultyDeviceId : device.deviceInstanceUuid;
                if(devId!=undefined) ids.push(devId);
            });
            if(ids.length>0) {
                //status = restPayload[0].replacementStatus != undefined ? restPayload[0].replacementStatus : restPayload[0].status;
            } else { return; }
            var existingRecords = SimLokiDatabaseActions.getFilteredRecordHandler('device-replacement', {'instanceUUId': {'$in':ids}});
            existingRecords.forEach(existing => {
                var idx = ids.indexOf(existing.instanceUUId);
                ids.splice(idx);
            });
            var ntwkDev = SimLokiDatabaseActions.getFilteredRecordHandler('network-device', {'id': {'$in':ids} } );
            ntwkDev.forEach(device => {
                var tJson;
                tJson = JSON.parse(JSON.stringify(NetworkDeviceTemplate.REPLACE_DEVICE_TEMPLATE));
                tJson.deviceName =device.hostname;                
                tJson.platform = device.platformId;
                tJson.deviceSerialNumber = device.serialNumber;
                tJson.instanceUUId = device.id;
                tJson.status = status;
                tJson.replacementDeviceSerialNumber = null;                
                tJson.id = UtilityFunctions.generate_uuid();

                tJson.networkDeviceInstanceUUId  = tJson.instanceUUId;//used in Replacement History
                tJson.replacementDate = null;//used in Replacement History

                tJson.faultyDeviceName =device.hostname;
                tJson.faultyDevicePlatform = device.platformId;
                tJson.faultyDeviceSerialNumber = device.serialNumber;
                tJson.faultyDeviceId = device.id;
                tJson.replacementStatus = status;
                tJson.creationTime = UtilityFunctions.getTimeStamp();
                tJson.replacementTime = null;
                tJson.networkReadinessTaskId = resultObj.taskId;

                SimLokiDatabaseActions.insert('device-replacement', tJson);
            });
            if(existingRecords.length > 0) {
                SimTaskDataGenerator.updateTaskCompletion(resultObj.taskId, {isError: true , errorCode:'NCRM10082', 
                    failureReason:"NCRM10082: The device has already been marked, scheduled, in-progress or errored out for replacement."})
                return {"isError": true, "errorCode":400,"message":"NCRM10082: The device has already been marked, scheduled, "+
                    "in-progress or errored out for replacement.","href":"/device-replacement"};
            } else {
                SimTaskDataGenerator.updateTaskCompletion(resultObj.taskId, { isError: false });
            }
            return resultObj;     
        },

        unmarkForReplace: function(restPayload) {
            var devIds = [], ids = [];
            restPayload.forEach(device => {
                var devId = device.faultyDeviceId != undefined ? device.id : device.instanceUuid;
                if(devId!=undefined) devIds.push(devId);
                if(device.id != undefined) ids.push(device.id);
            });
            if(devIds.length > 0) { 
                var ntwkDev = SimLokiDatabaseActions.getFilteredRecordHandler('device-replacement', {'faultyDeviceId': {'$in':devIds} } );
                SimLokiDatabaseActions.delete('device-replacement', ntwkDev);
            } else if(ids.length > 0) { 
                var ntwkDev = SimLokiDatabaseActions.getFilteredRecordHandler('device-replacement', {'id': {'$in':ids} } );
                SimLokiDatabaseActions.delete('device-replacement', ntwkDev);
            }
        },

        getReplacementHistory: function(urlAction) {
            var filterQuery = getFilters(urlAction.filter);
            var records = SimLokiDatabaseActions.getFilteredRecordHandler('device-replacement', filterQuery );
            if(urlAction.action.count) { return records.length; }
            return records;
        },

        getArchieveDetails: function(urlAction) {
            var tJson = JSON.parse(JSON.stringify(NetworkDeviceTemplate.ARCHIEVE_TEMPLATE));
            var deviceId = urlAction.filter['filterById'];//faultyDeviceId in case of RMA
            if(!deviceId) {
                let urlSplit = (urlAction.url.split('?'))[0].split('/');
                deviceId = urlSplit[urlSplit.indexOf('network-device')+1];
            }
            if(deviceId!=undefined) {
                let complianceRecord = SimLokiDatabaseActions.getFilteredRecordHandler('device-compliance', {'id':deviceId});
                var records = SimLokiDatabaseActions.getFilteredRecordHandler('network-device', {'id':deviceId});
                var temObj = SimLokiDatabaseActions.getFilteredRecordHandler("temporary-data", {"key":"deploy-config-status"});
                if(records.length>0 && complianceRecord.length>0) {
                    let timestamp = complianceRecord[0].lastSyncTime;
                    if(urlAction.service.indexOf('version')>-1 && urlAction.service.indexOf('file')>-1) {
                        tJson = SimNetworkDeviceData.getDeviceConfig(deviceId);
                        //request from Field Team: show Startup Vs Running Config Out of sync for atleast one device. Choosing p1.edge1-sda1.local
                        
                        if(records[0].hostname=="p1.edge1-sda1.local"||records[0].hostname=="IE3400_1") {
                            if(["6b3ad8f5-1e33-472b-9117-727360f2e9c0","96cff6d8-3ae0-4751-bfd0-533667cc8def","46bb29ab-27a7-47ef-b495-e830e0074cea",
                                "4a2ebaa1-920f-4457-b648-1c3a3a1e4ecf"].indexOf(urlAction.action.id) > -1) {
                                //running config of latest version and startup config of 2 versions prior will have complete config.
                                if(records[0].hostname=="p1.edge1-sda1.local"){
                                    let textToRemove = "!\nspanning-tree mode rapid-pvst\nspanning-tree extend system-id\n"
                                    //"!\nspanning-tree mode rapid-pvst\nspanning-tree extend system-id\nmemory free low-watermark processor 133879\n";
                                    // checking if the deploy config status changed or not
                                    if (temObj.length == 0){
                                        tJson = tJson.replace(textToRemove,"");
                                    }
                                }
                                else if(records[0].hostname=="IE3400_1"){
                                    textToRemove="mac access-list extended profinetacl\npermit any any 0x8892 0x0\n"
                                    tJson = tJson.replace(textToRemove,"");
                                    textToRemove="vlan 100,102,201"
                                    tJson = tJson.replace(textToRemove,"vlan 100-102,201");
                                    textToRemove="policy-map Trust-COS\n class cos-7\n set cos 7\n class cos-5\n set cos 5\nclass cos-3\n set cos 3\npolicy-map p1\nclass c1\n police cir percent 95\nclass class-default\npolice cir percent 85\npolicy-map PTP-Event-Priority\nclass class-0\npriority\nclass class-1\nbandwidth remaining percent 40\nclass class-2\nbandwidth remaining percent 20\nclass class-default\nbandwidth remaining percent 40\npolicy-map CIP-PTP-Traffic\nclass CIP-Implicit_dscp_55\nset ip dscp 55\nclass CIP-Implicit_dscp_47\n set ip dscp 47\nclass CIP-Implicit_dscp_43\n  set ip dscp 43\nclass CIP-Implicit_dscp_any\nset ip dscp 31\nclass CIP-Other\nset ip dscp 27\nclass 1588-PTP-Event\n set ip dscp 59\nclass 1588-PTP-General\nset ip dscp 47"
                                    tJson = tJson.replace(textToRemove,"");
                                    textToRemove="service-policy input p1"
                                    tJson = tJson.replace(textToRemove,"");
                                    textToRemove="interface GigabitEthernet1/10"
                                    tJson = tJson.replace(textToRemove,"interface GigabitEthernet1/10\ndescription 130 laptop\nswitchport access vlan 100\n switchport mode access\ndevice-tracking attach-policy IPDT_POLICY\nip access-group MONITOR in\naccess-session port-control auto\n mab\ndot1x pae authenticator\n macro description CS1-Apply_Dot1x\nspanning-tree portfast\n service-policy type control subscriber GM_DOT1X_MAB_POLICIES");
                                    textToRemove="ip access-list extended 100\n 10 permit ip host 10.13.49.16 host 100.0.0.25\nip access-list extended 101\n 10 permit udp any eq 2222 any dscp 55\n20 permit ip any host 10.20.25.112\n30 permit ip any host 10.20.25.255\n 40 permit ip any host 255.255.255.255\n50 permit ip any 224.0.0.0 15.255.255.255\nip access-list extended 102\n10 permit udp any eq 2222 any dscp 47\nip access-list extended 103\n 10 permit udp any eq 2222 any dscp 4\nip access-list extended 104\n 10 permit udp any eq 2222 any\nip access-list extended 105\n10 permit udp any eq 44818 any\n20 permit tcp any eq 44818 any\nip access-list extended 106\n10 permit udp any eq 319 any\nip access-list extended 107\n 10 permit udp any eq 320 any"
                                    tJson = tJson.replace(textToRemove,"");

                                }
                                
                            }
                        }
                    } else {
                        tJson.archiveResultlist[0].deviceId = deviceId;
                        tJson.archiveResultlist[0].deviceName = records[0].hostname;
                        tJson.archiveResultlist[0].ipAddress = records[0].managementIpAddress;
                        for(var i=0; i<tJson.archiveResultlist[0].versions.length; i++) {
                            tJson.archiveResultlist[0].versions[i].createdTime = timestamp - (i*3600000);//latest 1st
                            //request from Field Team: show Startup Vs Running Config Out of sync for atleast one device. Choosing p1.edge1-sda1.local
                            if(records[0].hostname=="IE3400_1"){
                                tJson.archiveResultlist[0].versions[i].createdTime = timestamp -7*86400000-(i*360000)
                            }
                            if(records[0].hostname=="p1.edge1-sda1.local") {
                                if(temObj.length == 0){
                                    if(i==0) {
                                    //version record of 0th and 2nd modified
                                        //adding 2 lines extra in running config when compared to startup. i.e. (420-418)/418 = 0.479
                                        tJson.archiveResultlist[0].versions[i].files[1].totalNoOfLines = "417";//in start up
                                        tJson.archiveResultlist[0].versions[i].files[2].changeMagnitude = "0.479";//in running config
                                        tJson.archiveResultlist[0].versions[i].files[2].linesAdded = "3";//in running config
                                        tJson.archiveResultlist[0].versions[i].files[2].linesAddedFromStartup = "3";//in running config
                                        tJson.archiveResultlist[0].versions[i].startupRunningStatus = "OUT_OF_SYNC";
                                    } else if(i==1) {//keeping the link between 0th and 2nd and in syc state
                                        tJson.archiveResultlist[0].versions[i].files[1].totalNoOfLines = "417";//in start up
                                        tJson.archiveResultlist[0].versions[i].files[2].totalNoOfLines = "417";//in running config
                                    } else if(i==2) {//version record of 0th and 2nd modified
                                        //adding 2 lines extra in running config when compared to startup. i.e. (420-418)/418 = 0.479
                                        tJson.archiveResultlist[0].versions[i].files[1].totalNoOfLines = "420";//in start up
                                        tJson.archiveResultlist[0].versions[i].files[2].totalNoOfLines = "417";//in running config
                                        tJson.archiveResultlist[0].versions[i].files[2].changeMagnitude = "0.479";//in running config
                                        tJson.archiveResultlist[0].versions[i].files[2].linesDeleted = "3";//in running config
                                        tJson.archiveResultlist[0].versions[i].files[2].linesDeletedFromStartup = "3";//in running config
                                        tJson.archiveResultlist[0].versions[i].startupRunningStatus = "OUT_OF_SYNC";
                                    }
                                }
                            }else if(records[0].hostname=="IE3400_1"){
                               
                                if(i==0) {//version record of 0th and 2nd modified
                                    //adding 2 lines extra in running config when compared to startup. i.e. (595-587)/587 = 0.131
                                    tJson.archiveResultlist[0].versions[i].files[1].totalNoOfLines = "595";//in start up
                                    tJson.archiveResultlist[0].versions[i].files[2].totalNoOfLines = "679";
                                    tJson.archiveResultlist[0].versions[i].files[2].changeMagnitude = "12.9030";//in running config
                                    tJson.archiveResultlist[0].versions[i].files[2].linesAdded = "8";//in running config
                                    tJson.archiveResultlist[0].versions[i].files[2].linesAddedFromStartup = "10";//in running config
                                    tJson.archiveResultlist[0].versions[i].files[2].linesDeletedFromStartup="88"
                                    tJson.archiveResultlist[0].versions[i].files[2].linesUpdatedFromStartup="2";
                                    tJson.archiveResultlist[0].versions[i].startupRunningStatus = "OUT_OF_SYNC";
                                } else if(i==1) {//keeping the link between 0th and 2nd and in syc state
                                    tJson.archiveResultlist[0].versions[i].files[1].totalNoOfLines = "594";//in start up
                                    tJson.archiveResultlist[0].versions[i].files[2].totalNoOfLines = "679";//in running config
                                } else if(i==2) {//version record of 0th and 2nd modified
                                    //adding 2 lines extra in running config when compared to startup. i.e. (595-587)/587 = 0.479
                                    tJson.archiveResultlist[0].versions[i].files[1].totalNoOfLines = "595";//in start up
                                    tJson.archiveResultlist[0].versions[i].files[2].totalNoOfLines = "679";//in running config
                                    tJson.archiveResultlist[0].versions[i].files[2].changeMagnitude = "12.9030";//in running config
                                    tJson.archiveResultlist[0].versions[i].files[2].linesDeleted = "88";//in running config
                                    tJson.archiveResultlist[0].versions[i].files[2].linesDeletedFromStartup = "88";//in running config
                                    tJson.archiveResultlist[0].versions[i].startupRunningStatus = "OUT_OF_SYNC";
                                }
                            }
                        }
                    }
                }
            }     
            return tJson;
        },

        replaceDevice: function(restPayload) {
            var workflowId = UtilityFunctions.generate_uuid();
            var progress = "RMA deploy workflow with workflowId " + workflowId + " is triggered successfully.";
            var resultObj = SimTaskDataGenerator.createTask('Device Replacement Service', {'progress':progress});
            var faultyDeviceSerialNumber = restPayload.faultyDeviceSerialNumber == undefined ? 
                restPayload.oldSerialNumber : restPayload.faultyDeviceSerialNumber;
            var replacementDeviceSerialNumber = restPayload.replacementDeviceSerialNumber == undefined ? 
                restPayload.newSerialNumber : restPayload.replacementDeviceSerialNumber;
            //update device replacement table
            var records = SimLokiDatabaseActions.getFilteredRecordHandler('device-replacement',
                {'faultyDeviceSerialNumber':faultyDeviceSerialNumber});
            //records[0].status = "REPLACED";
            records[0].status = "REPLACEMENT-IN-PROGRESS";
            records[0].workflowId = workflowId;
            records[0].replacementDeviceSerialNumber = replacementDeviceSerialNumber;
            records[0].replacementStatus = records[0].status;
            records[0].replacementTime = UtilityFunctions.getTimeStamp();
            records[0].replacementDate = records[0].replacementTime;
            SimLokiDatabaseActions.update('device-replacement', records);
            //update pnp table
            var pnpRecord = SimLokiDatabaseActions.getFilteredRecordHandler('onboarding-pnp-device', 
                {'deviceInfo.serialNumber':replacementDeviceSerialNumber});
            //update inventory
            var devRecord = SimLokiDatabaseActions.getFilteredRecordHandler('network-device', {'serialNumber':faultyDeviceSerialNumber});
            devRecord[0].serialNumber = replacementDeviceSerialNumber;
            devRecord[0].managementIpAddress = pnpRecord[0].deviceInfo.ipAddress;
            devRecord[0].errorCode = null;
            devRecord[0].errorDescription = null;
            devRecord[0].reachabilityFailureReason = null;
            devRecord[0].reachabilityStatus = 'Reachable';
            SimLokiDatabaseActions.update('network-device', devRecord);

            var siteRecord = SimLokiDatabaseActions.getFilteredRecordHandler('site', {'id':devRecord[0].siteId} );

            SimOnboardingData.claimAndProvisionPnP(replacementDeviceSerialNumber, siteRecord);

            insertWorkFlowDetails(workflowId);
            document.cookie = "rmaInititiated=false ; path=/";
            document.cookie = 'rmaDeviceToReplace=; path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';

            SimTaskDataGenerator.updateTaskCompletion(resultObj.taskId, { isError: false });
            return resultObj;
        },

        checkReplacementStatus: function() {
            var records = SimLokiDatabaseActions.getAll('device-replacement');
            if(records.length>0) {
                var simulationDuration = 180000;
                for(var i=0; i<records.length; i++) {
                    if(records[i].status == "REPLACEMENT-IN-PROGRESS" && 
                      UtilityFunctions.getTimeStamp() > records[i].replacementTime + simulationDuration){
                        records[i].status = "REPLACED";
                        records[i].replacementStatus = "REPLACED";
                    }
                }
                SimLokiDatabaseActions.update('device-replacement', records);
            }
        },

        getComplianceDetails: function(urlAction) {
            let timestamp = UtilityFunctions.getTimeStamp();
            let tJson = JSON.parse(JSON.stringify(NetworkDeviceTemplate.COMPLIANCE_SUMMARY));
            let id = urlAction.action.id!="" ? urlAction.action.id : urlAction.filter.deviceUuid;
            let deviceEntry = SimLokiDatabaseActions.getFilteredRecordHandler('network-device', {'id':id});
            let complianceEntry = SimLokiDatabaseActions.getFilteredRecordHandler('device-compliance', {'id':id});
            let temObj = SimLokiDatabaseActions.getFilteredRecordHandler("temporary-data", {"key":"deploy-config-status"});
                                    

            if(urlAction.action.id && urlAction.service.indexOf('detail')>-1) {
                if(deviceEntry[0].reachabilityStatus.toLowerCase() == 'unreachable' || deviceEntry[0].family == 'Unified AP' ) {
                    tJson = formatResponse([], {"deviceUuid":null});
                } else {
                    tJson = JSON.parse(JSON.stringify(NetworkDeviceTemplate.COMPLIANCE_DETAIL));
                    tJson.forEach(obj => {
                        obj.deviceUuid = id;
                        obj.lastSyncTime = complianceEntry[0].lastSyncTime;
                        obj.lastUpdateTime = timestamp;
                        if(deviceEntry[0].hostname=="IE3400_1"){
                          obj.lastSyncTime = complianceEntry[0].lastSyncTime-7*86400000; 
                          obj.lastUpdateTime = timestamp-7*86403000; 
                        }
                        
                        if(obj.complianceType=="IMAGE") obj.status = complianceEntry[0].complianceStatus;
                        if(obj.additionalDataURL) obj.additionalDataURL = obj.additionalDataURL.replace("simDevId", id);
                        //request from Field Team: show Startup Vs Running Config Out of sync for atleast one device. Choosing p1.edge1-sda1.local
                        if((deviceEntry[0].hostname=="p1.edge1-sda1.local" ||deviceEntry[0].hostname=="IE3400_1") && obj.complianceType=="RUNNING_CONFIG") {
                            if(deviceEntry[0].hostname=="p1.edge1-sda1.local" && temObj.length >0){
                                obj.status = "COMPLIANT";
                            }else{
                                obj.status = "NON_COMPLIANT";   
                            }
                        }
                    });
                    tJson = formatResponse(tJson, {"deviceUuid":id});
                }
            } else {
                if(deviceEntry[0].reachabilityStatus.toLowerCase() == 'unreachable') {
                    tJson = formatResponse([]);
                } else {
                    //since for p1.edge1-sda1.local, image will also be non-compliant, not appending the check for RUNNING_CONFIG here
                    tJson[0].complianceStatus = complianceEntry[0].complianceStatus;
                    tJson[0].deviceUuid = id;
                    tJson[0].lastUpdateTime = timestamp;
                    if(deviceEntry[0].hostname=="IE3400_1"){
                        tJson[0].lastUpdateTime = timestamp-7*86403000; 
                      }
                      
                    tJson = formatResponse(tJson);
                }
            }            
            return tJson;
            
        },

        getSecAdvForDevice: function(urlAction) {
            let response = [];
            if(urlAction.action.count) return response.length;
            return response;
        },
        getDeployConfigTaskStatus: function(urlAction){
            var jsonList = []
            var tJson = JSON.parse(JSON.stringify(NetworkDeviceTemplate.DeployConfigTaskTemplate));
            let deviceEntry = SimLokiDatabaseActions.getFilteredRecordHandler('network-device', {'hostname':"p1.edge1-sda1.local"});
            tJson.parentTaskId = urlAction.filter['parentTaskId'];
            tJson.taskId = urlAction.filter['parentTaskId'];
            tJson.startTime = new Date().getTime();
            tJson.completionTime = tJson.startTime+5;
            tJson.deviceId = deviceEntry[0].id;
            tJson.deviceIpAddress = deviceEntry[0].managementIpAddress;
            jsonList.push(tJson)
            return jsonList;  
        },

        getRmaPersistence: function() {
            if(UtilityFunctions.getCookievalue("rmaInititiated")=="true") {
                var devForRma = DefaultConfig.Devices_Down[0];
                var devDtls = (SimLokiDatabaseActions.getFilteredRecordHandler('network-device', {'hostname':devForRma}))[0];
                var siteDtls = (SimLokiDatabaseActions.getFilteredRecordHandler('site', {'id':devDtls.siteId}))[0];
                var resp = JSON.parse(JSON.stringify(RmaTemplate.RmaPersistence));
                resp[0].value = resp[0].value.replaceAll('$devSerialNo',devDtls.serialNumber).replaceAll('$devSiteId',siteDtls.id).
                    replaceAll('$devSite',siteDtls.groupNameHierarchy).replaceAll('$devParentSiteId',siteDtls.parentId).
                    replaceAll('$devName',devForRma).replaceAll('$devId',devDtls.id).
                    replaceAll('$devSwVersion',devDtls.softwareVersion).replaceAll('$devIp',devDtls.managementIpAddress).
                    replaceAll('$devPid',devDtls.platformId);
                if(UtilityFunctions.getCookievalue("rmaDeviceToReplace")) {
                    resp[0].value = JSON.parse(resp[0].value);
                    resp[0].value["instances"]["cisco.dna.rmaDeviceView/device-replacement/"+devDtls.serialNumber]["state"]
                        ["cisco.dna.rmaDeviceView/device-replacement-replacementdevice"] = JSON.parse(UtilityFunctions.getCookievalue("rmaDeviceToReplace"));
                    resp[0].value = JSON.stringify(resp[0].value);
                }
                return resp;
            } else {
                return [];
            }
        },

        postRmaPersistence: function(restPayload) {
            var resp = JSON.parse(JSON.stringify(RmaTemplate.RmaPersistence))[0];
            var payload = JSON.parse(restPayload.value);
            var devForRma = DefaultConfig.Devices_Down[0];
            var devDtls = (SimLokiDatabaseActions.getFilteredRecordHandler('network-device', {'hostname':devForRma}))[0];
            if(Object.keys(payload.instances).length==0) {
                document.cookie = "rmaInititiated=false ; path=/";
                document.cookie = 'rmaDeviceToReplace=; path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
                return [];
            } else {
                var siteDtls = (SimLokiDatabaseActions.getFilteredRecordHandler('site', {'id':devDtls.siteId}))[0];
                resp.value = resp.value.replaceAll('$devSerialNo',devDtls.serialNumber).replaceAll('$devSiteId',siteDtls.id).
                    replaceAll('$devSite',siteDtls.groupNameHierarchy).replaceAll('$devParentSiteId',siteDtls.parentId).
                    replaceAll('$devName',devForRma).replaceAll('$devId',devDtls.id).
                    replaceAll('$devSwVersion',devDtls.softwareVersion).replaceAll('$devIp',devDtls.managementIpAddress).
                    replaceAll('$devPid',devDtls.platformId);
                    document.cookie = "rmaInititiated=true ; path=/";
            }
            if(payload["instances"]["cisco.dna.rmaDeviceView/device-replacement/"+devDtls.serialNumber]["state"]
                ["cisco.dna.rmaDeviceView/device-replacement-replacementdevice"]["replaceDevice"] != "") {
                    var dataToAppend = payload["instances"]["cisco.dna.rmaDeviceView/device-replacement/"+devDtls.serialNumber]["state"]
                ["cisco.dna.rmaDeviceView/device-replacement-replacementdevice"];
                    document.cookie = "rmaDeviceToReplace="+JSON.stringify(dataToAppend)+" ; path=/";
                    resp.value = JSON.parse(resp.value);
                    resp.value["instances"]["cisco.dna.rmaDeviceView/device-replacement/"+devDtls.serialNumber]["state"]
                ["cisco.dna.rmaDeviceView/device-replacement-replacementdevice"] = dataToAppend;
                    resp.value = JSON.stringify(resp.value);
            } 
            return resp;
        }
    };

    function insertWorkFlowDetails(workflowId) {
        //Replace device workflow - In Progress for 3 min
        var data = JSON.parse(JSON.stringify(ReplaceDeviceWorkflowTemplate.WorkFlow_Template));
        var currTime = UtilityFunctions.getTimeStamp(), duration = 444991;
        data.workflow[0].id = workflowId;
        data.workflow[0].timestamp = currTime; //for ease of use
        data.workflow[0].startTime = UtilityFunctions.getGivenTimeInGivenFormat(currTime, 'YYYY-MM-DD, HH:mm:ss.SSS');
        data.workflow[0].endTime = UtilityFunctions.getGivenTimeInGivenFormat(currTime+duration, 'YYYY-MM-DD, HH:mm:ss.SSS');
        //to update some more fields. need to check if it is necessary.
        var stepDurationInMillSec = [181523, 1859, 6991, 10402, 1992, 2879, 34427, 136, 136289, 18650, 45620, 194, 123];
        //update the time again as the order has been updated in tempalate
        var steps = data.workflow[0].steps, k=0;
        for(var i=0; i<steps.length; i++) {
            for(var j=0; j<steps[i].tasks.length; j++) {
                steps[i].tasks[j].targets[0].startTime = UtilityFunctions.getGivenTimeInGivenFormat(currTime, 'YYYY-MM-DD, HH:mm:ss.SSS');
                if(stepDurationInMillSec.length > k) {
                    currTime += stepDurationInMillSec[k]; k++;
                }
                if(k == stepDurationInMillSec.length - 2) {
                    steps[i].tasks[j].targets[0].targetStatus = "RUNNING";
                    steps[i].tasks[j].targets[0].statusMessage_base = "Inventory Sync completed successfully";
                    steps[i].tasks[j].targets[0].statusMessage = "Inventory Sync in progress";
                } else if(k > stepDurationInMillSec.length - 3) {
                    steps[i].tasks[j].targets[0].targetStatus = "INIT";
                    steps[i].tasks[j].targets[0].statusMessage_base = steps[i].tasks[j].targets[0].statusMessage;
                    steps[i].tasks[j].targets[0].statusMessage = "";
                } 
                steps[i].tasks[j].targets[0].endTime = UtilityFunctions.getGivenTimeInGivenFormat(currTime, 'YYYY-MM-DD, HH:mm:ss.SSS');
                currTime++;
            }
        }
        data.workflow[0].steps = steps;
        SimLokiDatabaseActions.insert('replace-device-workflow', data);
    }

    function getFilters(filters) {
        var filterQuery = {'$and': []};
        filters.forEach(filterElement => {
            if(filterElement && typeof filterElement=='string') {
                switch(filterElement) {
                    case '_sort':
                    case '_order':
                    case '_page':
                    case '_limit':
                    case 'sortBy':
                    case 'sortOrder':
                    case 'offset':
                    case 'limit':
                        break;
                    default:
                        filterQuery['$and'].push( { [filterElement]: filters[filterElement] } );
                }
            }
        });
        return filterQuery;
    }

    function formatResponse(data, extra) {
        let response = {"response":data, "version":"1.0"}
        if(extra!=undefined) {
            response = UtilityFunctions.deepCloneAndMerge(response, extra);;
        }
        return response;
    }
});