define({
    "WIFI6_AT_ALL": {
        "records": [
            {
                "modificationtime": 1588617300000,
                "axATE": 39.3903919922401,
                "nonAxBytes": 165914189,
                "axBytes": 204182933,
                "axAirtime": 5183572,
                "nonAxAirtime": 5377556,
                "nonAxATE": 30.853084375132497,
                "timestamp": "2020-05-04T18:35:00.000+0000"
            },
            {
                "modificationtime": 1588617600000,
                "axATE": 39.01659038186983,
                "nonAxBytes": 318194272,
                "axBytes": 406047763,
                "axAirtime": 10407054,
                "nonAxAirtime": 9836944,
                "nonAxATE": 32.346862196226795,
                "timestamp": "2020-05-04T18:40:00.000+0000"
            },
            {
                "modificationtime": 1588617900000,
                "axATE": 39.18657666291505,
                "nonAxBytes": 162373456,
                "axBytes": 194142527,
                "axAirtime": 4954312,
                "nonAxAirtime": 4930992,
                "nonAxATE": 32.929166382748136,
                "timestamp": "2020-05-04T18:45:00.000+0000"
            },
            {
                "modificationtime": 1588618200000,
                "axATE": 38.655756691668294,
                "nonAxBytes": 340021161,
                "axBytes": 403849060,
                "axAirtime": 10447320,
                "nonAxAirtime": 10267016,
                "nonAxATE": 33.117817387252536,
                "timestamp": "2020-05-04T18:50:00.000+0000"
            },
            {
                "modificationtime": 1588618500000,
                "axATE": 40.02619046436709,
                "nonAxBytes": 490266332,
                "axBytes": 612585475,
                "axAirtime": 15304616,
                "nonAxAirtime": 14691276,
                "nonAxATE": 33.371255975314874,
                "timestamp": "2020-05-04T18:55:00.000+0000"
            },
            {
                "modificationtime": 1588618800000,
                "axATE": 38.834454064510574,
                "nonAxBytes": 291825027,
                "axBytes": 396684628,
                "axAirtime": 10214760,
                "nonAxAirtime": 9053600,
                "nonAxATE": 32.23303735530617,
                "timestamp": "2020-05-04T19:00:00.000+0000"
            },
            {
                "modificationtime": 1588619100000,
                "axATE": 39.07778760015214,
                "nonAxBytes": 313071041,
                "axBytes": 404386950,
                "axAirtime": 10348256,
                "nonAxAirtime": 9677724,
                "nonAxATE": 32.3496558694999,
                "timestamp": "2020-05-04T19:05:00.000+0000"
            },
            {
                "modificationtime": 1588619400000,
                "axATE": 38.460207591605545,
                "nonAxBytes": 457550260,
                "axBytes": 574414200,
                "axAirtime": 14935286,
                "nonAxAirtime": 14565340,
                "nonAxATE": 31.413634010603253,
                "timestamp": "2020-05-04T19:10:00.000+0000"
            },
            {
                "modificationtime": 1588619700000,
                "axATE": 37.923033300575774,
                "nonAxBytes": 158606739,
                "axBytes": 194800307,
                "axAirtime": 5136728,
                "nonAxAirtime": 4805492,
                "nonAxATE": 33.00530705284704,
                "timestamp": "2020-05-04T19:15:00.000+0000"
            },
            {
                "modificationtime": 1588620000000,
                "axATE": 38.858453398802205,
                "nonAxBytes": 330431510,
                "axBytes": 400822149,
                "axAirtime": 10314928,
                "nonAxAirtime": 10292180,
                "nonAxATE": 32.1051040693031,
                "timestamp": "2020-05-04T19:20:00.000+0000"
            },
            {
                "modificationtime": 1588620300000,
                "axATE": 39.138008965574016,
                "nonAxBytes": 466363570,
                "axBytes": 595226339,
                "axAirtime": 15208396,
                "nonAxAirtime": 14586296,
                "nonAxATE": 31.972720833308195,
                "timestamp": "2020-05-04T19:25:00.000+0000"
            },
            {
                "modificationtime": 1588620600000,
                "axATE": 42.93759175190956,
                "nonAxBytes": 72782713,
                "axBytes": 306523567,
                "axAirtime": 7138816,
                "nonAxAirtime": 3219744,
                "nonAxATE": 22.60512419620939,
                "timestamp": "2020-05-04T19:30:00.000+0000"
            },
            {
                "modificationtime": 1588620900000,
                "axATE": 37.93395008822788,
                "nonAxBytes": 160247453,
                "axBytes": 195629174,
                "axAirtime": 5157100,
                "nonAxAirtime": 5077136,
                "nonAxATE": 31.56256854257991,
                "timestamp": "2020-05-04T19:35:00.000+0000"
            },
            {
                "modificationtime": 1588621200000,
                "axATE": 38.99159853823907,
                "nonAxBytes": 322412382,
                "axBytes": 406560563,
                "axAirtime": 10426876,
                "nonAxAirtime": 10174104,
                "nonAxATE": 31.68951113532946,
                "timestamp": "2020-05-04T19:40:00.000+0000"
            },
            {
                "modificationtime": 1588621500000,
                "axATE": 38.18863943897752,
                "nonAxBytes": 154964327,
                "axBytes": 179943647,
                "axAirtime": 4711968,
                "nonAxAirtime": 4697040,
                "nonAxATE": 32.99191128881168,
                "timestamp": "2020-05-04T19:45:00.000+0000"
            },
            {
                "modificationtime": 1588621800000,
                "axATE": 38.933594912578045,
                "nonAxBytes": 157684409,
                "axBytes": 198841033,
                "axAirtime": 5107184,
                "nonAxAirtime": 4813068,
                "nonAxATE": 32.76172474604556,
                "timestamp": "2020-05-04T19:50:00.000+0000"
            },
            {
                "modificationtime": 1588622100000,
                "axATE": 38.8268087087895,
                "nonAxBytes": 294109943,
                "axBytes": 375214514,
                "axAirtime": 9663800,
                "nonAxAirtime": 9226240,
                "nonAxATE": 31.877551743722254,
                "timestamp": "2020-05-04T19:55:00.000+0000"
            },
            {
                "modificationtime": 1588622400000,
                "axATE": 39.50667722483632,
                "nonAxBytes": 129605638,
                "axBytes": 194923417,
                "axAirtime": 4933936,
                "nonAxAirtime": 4264872,
                "nonAxATE": 30.38910382304557,
                "timestamp": "2020-05-04T20:00:00.000+0000"
            },
            {
                "modificationtime": 1588622700000,
                "axATE": 46.19024164122472,
                "nonAxBytes": 4686,
                "axBytes": 406251859,
                "axAirtime": 8795188,
                "nonAxAirtime": 3300,
                "nonAxATE": 1.42,
                "timestamp": "2020-05-04T20:05:00.000+0000"
            },
            {
                "modificationtime": 1588623000000,
                "axATE": 44.968080942440366,
                "nonAxBytes": 5184,
                "axBytes": 367409367,
                "axAirtime": 8170448,
                "nonAxAirtime": 3588,
                "nonAxATE": 1.4448160535117056,
                "timestamp": "2020-05-04T20:10:00.000+0000"
            },
            {
                "modificationtime": 1588623300000,
                "axATE": 39.875658437903894,
                "nonAxBytes": 128409737,
                "axBytes": 184499089,
                "axAirtime": 4626860,
                "nonAxAirtime": 4086124,
                "nonAxATE": 31.425805237432833,
                "timestamp": "2020-05-04T20:15:00.000+0000"
            },
            {
                "modificationtime": 1588623600000,
                "axATE": 16.958494742667405,
                "nonAxBytes": 176881224,
                "axBytes": 122576,
                "axAirtime": 7228,
                "nonAxAirtime": 7654748,
                "nonAxATE": 23.107386944677994,
                "timestamp": "2020-05-04T20:20:00.000+0000"
            },
            {
                "modificationtime": 1588623900000,
                "axATE": 69.28583350823074,
                "nonAxBytes": 211075257,
                "axBytes": 69986729,
                "axAirtime": 1010116,
                "nonAxAirtime": 8829184,
                "nonAxATE": 23.906541872952246,
                "timestamp": "2020-05-04T20:25:00.000+0000"
            },
            {
                "modificationtime": 1588624200000,
                "axATE": 70.04379461483904,
                "nonAxBytes": 170548445,
                "axBytes": 54909572,
                "axAirtime": 783932,
                "nonAxAirtime": 7566540,
                "nonAxATE": 22.539819389047043,
                "timestamp": "2020-05-04T20:30:00.000+0000"
            },
            {
                "modificationtime": 1588624500000,
                "axATE": 38.42002246218778,
                "nonAxBytes": 311151904,
                "axBytes": 383273380,
                "axAirtime": 9975876,
                "nonAxAirtime": 9717944,
                "nonAxATE": 32.01828534924671,
                "timestamp": "2020-05-04T20:35:00.000+0000"
            },
            {
                "modificationtime": 1588624800000,
                "axATE": 40.99483078850932,
                "nonAxBytes": 227735045,
                "axBytes": 543585553,
                "axAirtime": 13259856,
                "nonAxAirtime": 8055300,
                "nonAxATE": 28.271454197857313,
                "timestamp": "2020-05-04T20:40:00.000+0000"
            },
            {
                "modificationtime": 1588625100000,
                "axATE": 40.055202648348526,
                "nonAxBytes": 315608755,
                "axBytes": 406307158,
                "axAirtime": 10143680,
                "nonAxAirtime": 9382680,
                "nonAxATE": 33.63737812650543,
                "timestamp": "2020-05-04T20:45:00.000+0000"
            },
            {
                "modificationtime": 1588625400000,
                "axATE": 38.76881686651318,
                "nonAxBytes": 338604700,
                "axBytes": 409726070,
                "axAirtime": 10568444,
                "nonAxAirtime": 10421408,
                "nonAxATE": 32.49126221715914,
                "timestamp": "2020-05-04T20:50:00.000+0000"
            },
            {
                "modificationtime": 1588625700000,
                "axATE": 39.69331871872944,
                "nonAxBytes": 166766849,
                "axBytes": 210617036,
                "axAirtime": 5306108,
                "nonAxAirtime": 5017820,
                "nonAxATE": 33.23492054318409,
                "timestamp": "2020-05-04T20:55:00.000+0000"
            },
            {
                "modificationtime": 1588626000000,
                "axATE": 39.53701587591213,
                "nonAxBytes": 343428637,
                "axBytes": 412565914,
                "axAirtime": 10434928,
                "nonAxAirtime": 10433804,
                "nonAxATE": 32.914997924055314,
                "timestamp": "2020-05-04T21:00:00.000+0000"
            },
            {
                "modificationtime": 1588626300000,
                "axATE": 60.507040115763445,
                "nonAxBytes": 432831530,
                "axBytes": 182979219,
                "axAirtime": 3024098,
                "nonAxAirtime": 17853348,
                "nonAxATE": 12.298364486210556,
                "timestamp": "2020-05-04T21:05:00.000+0000"
            },
            {
                "modificationtime": 1588626600000,
                "axATE": 38.35246145678488,
                "nonAxBytes": 162366262,
                "axBytes": 180601741,
                "axAirtime": 4709000,
                "nonAxAirtime": 5061402,
                "nonAxATE": 16.154866313433857,
                "timestamp": "2020-05-04T21:10:00.000+0000"
            },
            {
                "modificationtime": 1588626900000,
                "axATE": 38.24070915404122,
                "nonAxBytes": 169952316,
                "axBytes": 200924487,
                "axAirtime": 5254204,
                "nonAxAirtime": 5480371,
                "nonAxATE": 15.578432707248199,
                "timestamp": "2020-05-04T21:15:00.000+0000"
            },
            {
                "modificationtime": 1588627200000,
                "axATE": 39.04265393530648,
                "nonAxBytes": 157305017,
                "axBytes": 202660734,
                "axAirtime": 5190752,
                "nonAxAirtime": 5044178,
                "nonAxATE": 15.707895915316508,
                "timestamp": "2020-05-04T21:20:00.000+0000"
            },
            {
                "modificationtime": 1588627500000,
                "axATE": 39.00046061460342,
                "nonAxBytes": 158213131,
                "axBytes": 205410590,
                "axAirtime": 5266876,
                "nonAxAirtime": 5087172,
                "nonAxATE": 31.100409225400675,
                "timestamp": "2020-05-04T21:25:00.000+0000"
            },
            {
                "modificationtime": 1588627800000,
                "axATE": 38.03078542212487,
                "nonAxBytes": 169324847,
                "axBytes": 201132350,
                "axAirtime": 5288672,
                "nonAxAirtime": 5345672,
                "nonAxATE": 31.675128402939798,
                "timestamp": "2020-05-04T21:30:00.000+0000"
            },
            {
                "modificationtime": 1588628100000,
                "axATE": 43.448125129220415,
                "nonAxBytes": 152676491,
                "axBytes": 138695974,
                "axAirtime": 3192220,
                "nonAxAirtime": 6028908,
                "nonAxATE": 25.324070461848148,
                "timestamp": "2020-05-04T21:35:00.000+0000"
            },
            {
                "modificationtime": 1588628400000,
                "axATE": 42.15641654441119,
                "nonAxBytes": 264889232,
                "axBytes": 557347791,
                "axAirtime": 13220948,
                "nonAxAirtime": 9199280,
                "nonAxATE": 28.7945613134941,
                "timestamp": "2020-05-04T21:40:00.000+0000"
            },
            {
                "modificationtime": 1588628700000,
                "axATE": 40.67417414454977,
                "nonAxBytes": 166884074,
                "axBytes": 202032365,
                "axAirtime": 4967092,
                "nonAxAirtime": 5046632,
                "nonAxATE": 33.068405621808765,
                "timestamp": "2020-05-04T21:45:00.000+0000"
            },
            {
                "modificationtime": 1588629000000,
                "axATE": 34.91627447385649,
                "nonAxBytes": 525311117,
                "axBytes": 585836147,
                "axAirtime": 16778312,
                "nonAxAirtime": 15410744,
                "nonAxATE": 34.08733004714114,
                "timestamp": "2020-05-04T21:50:00.000+0000"
            },
            {
                "modificationtime": 1588629300000,
                "axATE": 38.75125989000828,
                "nonAxBytes": 441006830,
                "axBytes": 770263908,
                "axAirtime": 19877132,
                "nonAxAirtime": 14880716,
                "nonAxATE": 29.636129739993695,
                "timestamp": "2020-05-04T21:55:00.000+0000"
            },
            {
                "modificationtime": 1588629600000,
                "axATE": 38.676901749631334,
                "nonAxBytes": 161470806,
                "axBytes": 206989341,
                "axAirtime": 5351756,
                "nonAxAirtime": 4855900,
                "nonAxATE": 33.25249819806833,
                "timestamp": "2020-05-04T22:00:00.000+0000"
            },
            {
                "modificationtime": 1588629900000,
                "axATE": 29.425210118405307,
                "nonAxBytes": 360539341,
                "axBytes": 256107142,
                "axAirtime": 8703664,
                "nonAxAirtime": 10825572,
                "nonAxATE": 33.30441486140409,
                "timestamp": "2020-05-04T22:05:00.000+0000"
            },
            {
                "modificationtime": 1588630200000,
                "axATE": 37.41207924793247,
                "nonAxBytes": 151557079,
                "axBytes": 183302727,
                "axAirtime": 4899560,
                "nonAxAirtime": 4521568,
                "nonAxATE": 33.5186994865498,
                "timestamp": "2020-05-04T22:10:00.000+0000"
            },
            {
                "modificationtime": 1588630500000,
                "axATE": 38.81221638694516,
                "nonAxBytes": 475848345,
                "axBytes": 586689577,
                "axAirtime": 15116106,
                "nonAxAirtime": 13895896,
                "nonAxATE": 34.24380442973954,
                "timestamp": "2020-05-04T22:15:00.000+0000"
            },
            {
                "modificationtime": 1588630800000,
                "axATE": 31.10400772748789,
                "nonAxBytes": 167621660,
                "axBytes": 173208639,
                "axAirtime": 5568692,
                "nonAxAirtime": 4712836,
                "nonAxATE": 35.56704710284848,
                "timestamp": "2020-05-04T22:20:00.000+0000"
            },
            {
                "modificationtime": 1588631100000,
                "axATE": 38.78898657074636,
                "nonAxBytes": 167978456,
                "axBytes": 201309565,
                "axAirtime": 5189864,
                "nonAxAirtime": 4918892,
                "nonAxATE": 34.149653214585726,
                "timestamp": "2020-05-04T22:25:00.000+0000"
            },
            {
                "modificationtime": 1588631400000,
                "axATE": 37.50379578199842,
                "nonAxBytes": 320675621,
                "axBytes": 407743818,
                "axAirtime": 10872068,
                "nonAxAirtime": 9847984,
                "nonAxATE": 32.56256519100762,
                "timestamp": "2020-05-04T22:30:00.000+0000"
            },
            {
                "modificationtime": 1588631700000,
                "axATE": 34.5181107752471,
                "nonAxBytes": 170813296,
                "axBytes": 201391499,
                "axAirtime": 5834372,
                "nonAxAirtime": 5271056,
                "nonAxATE": 32.405896655243275,
                "timestamp": "2020-05-04T22:35:00.000+0000"
            },
            {
                "modificationtime": 1588632000000,
                "axATE": 39.23325921931814,
                "nonAxBytes": 161967559,
                "axBytes": 306325049,
                "axAirtime": 7807790,
                "nonAxAirtime": 5460428,
                "nonAxATE": 29.662062937191003,
                "timestamp": "2020-05-04T22:40:00.000+0000"
            },
            {
                "modificationtime": 1588632300000,
                "axATE": 40.178723522183304,
                "nonAxBytes": 431386827,
                "axBytes": 594422518,
                "axAirtime": 14794460,
                "nonAxAirtime": 13414992,
                "nonAxATE": 32.15706926996304,
                "timestamp": "2020-05-04T22:45:00.000+0000"
            },
            {
                "modificationtime": 1588632600000,
                "axATE": 41.063923062109104,
                "nonAxBytes": 603069224,
                "axBytes": 596187224,
                "axAirtime": 14518516,
                "nonAxAirtime": 16484544,
                "nonAxATE": 36.583919094152684,
                "timestamp": "2020-05-04T22:50:00.000+0000"
            },
            {
                "modificationtime": 1588632900000,
                "axATE": 40.51123397851656,
                "nonAxBytes": 146254396,
                "axBytes": 219238534,
                "axAirtime": 5411796,
                "nonAxAirtime": 4256840,
                "nonAxATE": 34.35750368818184,
                "timestamp": "2020-05-04T22:55:00.000+0000"
            },
            {
                "modificationtime": 1588633200000,
                "axATE": 39.679915021289595,
                "nonAxBytes": 151794048,
                "axBytes": 203193385,
                "axAirtime": 5120812,
                "nonAxAirtime": 4550992,
                "nonAxATE": 33.354057313218746,
                "timestamp": "2020-05-04T23:00:00.000+0000"
            },
            {
                "modificationtime": 1588633500000,
                "axATE": 60.66652765885104,
                "nonAxBytes": 306469658,
                "axBytes": 311171239,
                "axAirtime": 5129208,
                "nonAxAirtime": 12285168,
                "nonAxATE": 24.946313961681273,
                "timestamp": "2020-05-04T23:05:00.000+0000"
            },
            {
                "modificationtime": 1588633800000,
                "axATE": 17.055011642675698,
                "nonAxBytes": 292072174,
                "axBytes": 2578172,
                "axAirtime": 151168,
                "nonAxAirtime": 12645460,
                "nonAxATE": 23.096998764774078,
                "timestamp": "2020-05-04T23:10:00.000+0000"
            },
            {
                "modificationtime": 1588634100000,
                "axATE": 38.86841881652351,
                "nonAxBytes": 76349832,
                "axBytes": 290007845,
                "axAirtime": 7461272,
                "nonAxAirtime": 3372468,
                "nonAxATE": 22.63915684300044,
                "timestamp": "2020-05-04T23:15:00.000+0000"
            },
            {
                "modificationtime": 1588634400000,
                "axATE": 40.47878603687013,
                "nonAxBytes": 60072354,
                "axBytes": 298613138,
                "axAirtime": 7377028,
                "nonAxAirtime": 2924016,
                "nonAxATE": 20.544468292923156,
                "timestamp": "2020-05-04T23:20:00.000+0000"
            },
            {
                "modificationtime": 1588634700000,
                "axATE": 39.236002472922166,
                "nonAxBytes": 153353616,
                "axBytes": 635665458,
                "axAirtime": 16201076,
                "nonAxAirtime": 7457660,
                "nonAxATE": 20.563235116645167,
                "timestamp": "2020-05-04T23:25:00.000+0000"
            },
            {
                "modificationtime": 1588635000000,
                "axATE": 37.40810891365533,
                "nonAxBytes": 165903613,
                "axBytes": 198303378,
                "axAirtime": 5301080,
                "nonAxAirtime": 5036884,
                "nonAxATE": 32.93774742479676,
                "timestamp": "2020-05-04T23:30:00.000+0000"
            },
            {
                "modificationtime": 1588635300000,
                "axATE": 39.5113372574565,
                "nonAxBytes": 340266686,
                "axBytes": 423615903,
                "axAirtime": 10721376,
                "nonAxAirtime": 10073112,
                "nonAxATE": 33.77969846855669,
                "timestamp": "2020-05-04T23:35:00.000+0000"
            },
            {
                "modificationtime": 1588635600000,
                "axATE": 39.35677609189208,
                "nonAxBytes": 165924431,
                "axBytes": 205663084,
                "axAirtime": 5225608,
                "nonAxAirtime": 4777228,
                "nonAxATE": 34.73236592433938,
                "timestamp": "2020-05-04T23:40:00.000+0000"
            },
            {
                "modificationtime": 1588635900000,
                "axATE": 39.27284934527613,
                "nonAxBytes": 335478278,
                "axBytes": 420162778,
                "axAirtime": 10698556,
                "nonAxAirtime": 9707540,
                "nonAxATE": 34.55852646499525,
                "timestamp": "2020-05-04T23:45:00.000+0000"
            },
            {
                "modificationtime": 1588636200000,
                "axATE": 38.33271647381486,
                "nonAxBytes": 324230674,
                "axBytes": 408871857,
                "axAirtime": 10666394,
                "nonAxAirtime": 9707184,
                "nonAxATE": 33.40110520208538,
                "timestamp": "2020-05-04T23:50:00.000+0000"
            },
            {
                "modificationtime": 1588636500000,
                "axATE": 38.48433817066739,
                "nonAxBytes": 159917388,
                "axBytes": 197035963,
                "axAirtime": 5119900,
                "nonAxAirtime": 4798032,
                "nonAxATE": 33.329787712962315,
                "timestamp": "2020-05-04T23:55:00.000+0000"
            },
            {
                "modificationtime": 1588636800000,
                "axATE": 40.3140421639871,
                "nonAxBytes": 160630598,
                "axBytes": 205574524,
                "axAirtime": 5099328,
                "nonAxAirtime": 4983672,
                "nonAxATE": 32.2313743761628,
                "timestamp": "2020-05-05T00:00:00.000+0000"
            },
            {
                "modificationtime": 1588637100000,
                "axATE": 38.454209455250115,
                "nonAxBytes": 158305028,
                "axBytes": 200979849,
                "axAirtime": 5226472,
                "nonAxAirtime": 4749188,
                "nonAxATE": 33.33307251681761,
                "timestamp": "2020-05-05T00:05:00.000+0000"
            },
            {
                "modificationtime": 1588637400000,
                "axATE": 38.79204920944281,
                "nonAxBytes": 514537818,
                "axBytes": 635172014,
                "axAirtime": 16373768,
                "nonAxAirtime": 15199980,
                "nonAxATE": 33.85121677791681,
                "timestamp": "2020-05-05T00:10:00.000+0000"
            },
            {
                "modificationtime": 1588637700000,
                "axATE": 41.562472303726835,
                "nonAxBytes": 189354119,
                "axBytes": 190207662,
                "axAirtime": 4576428,
                "nonAxAirtime": 6264516,
                "nonAxATE": 30.22645628169838,
                "timestamp": "2020-05-05T00:15:00.000+0000"
            },
            {
                "modificationtime": 1588638000000,
                "axATE": 40.556821036099834,
                "nonAxBytes": 168986303,
                "axBytes": 184072648,
                "axAirtime": 4538636,
                "nonAxAirtime": 5716680,
                "nonAxATE": 29.560217293953833,
                "timestamp": "2020-05-05T00:20:00.000+0000"
            },
            {
                "modificationtime": 1588638300000,
                "axATE": 40.18936545753784,
                "nonAxBytes": 360736300,
                "axBytes": 377520412,
                "axAirtime": 9393540,
                "nonAxAirtime": 11754720,
                "nonAxATE": 30.68863401254985,
                "timestamp": "2020-05-05T00:25:00.000+0000"
            },
            {
                "modificationtime": 1588638600000,
                "axATE": 40.91404813366193,
                "nonAxBytes": 328993909,
                "axBytes": 371474354,
                "axAirtime": 9079384,
                "nonAxAirtime": 10853632,
                "nonAxATE": 30.31187246812864,
                "timestamp": "2020-05-05T00:30:00.000+0000"
            },
            {
                "modificationtime": 1588638900000,
                "axATE": 41.50793040149803,
                "nonAxBytes": 339741895,
                "axBytes": 373641439,
                "axAirtime": 9001688,
                "nonAxAirtime": 11611460,
                "nonAxATE": 29.25918833635047,
                "timestamp": "2020-05-05T00:35:00.000+0000"
            },
            {
                "modificationtime": 1588639200000,
                "axATE": 40.55875355600803,
                "nonAxBytes": 324548091,
                "axBytes": 381976825,
                "axAirtime": 9417864,
                "nonAxAirtime": 10914432,
                "nonAxATE": 29.7356830845618,
                "timestamp": "2020-05-05T00:40:00.000+0000"
            },
            {
                "modificationtime": 1588639500000,
                "axATE": 41.070284965331396,
                "nonAxBytes": 367955598,
                "axBytes": 181180905,
                "axAirtime": 4411484,
                "nonAxAirtime": 15192052,
                "nonAxATE": 24.220269783173464,
                "timestamp": "2020-05-05T00:45:00.000+0000"
            },
            {
                "modificationtime": 1588639800000,
                "axATE": 38.22267281305163,
                "nonAxBytes": 164455073,
                "axBytes": 201635913,
                "axAirtime": 5275296,
                "nonAxAirtime": 4882296,
                "nonAxATE": 33.68396201295456,
                "timestamp": "2020-05-05T00:50:00.000+0000"
            },
            {
                "modificationtime": 1588640100000,
                "axATE": 37.51389210683626,
                "nonAxBytes": 266015571,
                "axBytes": 493509656,
                "axAirtime": 13155384,
                "nonAxAirtime": 8823880,
                "nonAxATE": 30.147233529921078,
                "timestamp": "2020-05-05T00:55:00.000+0000"
            },
            {
                "modificationtime": 1588640400000,
                "axATE": 39.179773583330885,
                "nonAxBytes": 157213745,
                "axBytes": 202792314,
                "axAirtime": 5175944,
                "nonAxAirtime": 4631420,
                "nonAxATE": 33.9450416934763,
                "timestamp": "2020-05-05T01:00:00.000+0000"
            },
            {
                "modificationtime": 1588640700000,
                "axATE": 38.40930309678559,
                "nonAxBytes": 337227105,
                "axBytes": 407005563,
                "axAirtime": 10596536,
                "nonAxAirtime": 9940116,
                "nonAxATE": 33.9258721930408,
                "timestamp": "2020-05-05T01:05:00.000+0000"
            },
            {
                "modificationtime": 1588641000000,
                "axATE": 38.324469467296915,
                "nonAxBytes": 438491269,
                "axBytes": 671355639,
                "axAirtime": 17517676,
                "nonAxAirtime": 13986760,
                "nonAxATE": 31.350453500310294,
                "timestamp": "2020-05-05T01:10:00.000+0000"
            },
            {
                "modificationtime": 1588641300000,
                "axATE": 47.17933975727889,
                "nonAxBytes": 312655943,
                "axBytes": 288797194,
                "axAirtime": 6121264,
                "nonAxAirtime": 12462992,
                "nonAxATE": 25.086748270399276,
                "timestamp": "2020-05-05T01:15:00.000+0000"
            },
            {
                "modificationtime": 1588641600000,
                "axATE": 38.634532901214236,
                "nonAxBytes": 663885034,
                "axBytes": 842096051,
                "axAirtime": 21796460,
                "nonAxAirtime": 19529704,
                "nonAxATE": 33.99360451136382,
                "timestamp": "2020-05-05T01:20:00.000+0000"
            },
            {
                "modificationtime": 1588641900000,
                "axATE": 35.3167044478903,
                "nonAxBytes": 401466390,
                "axBytes": 368236541,
                "axAirtime": 10426696,
                "nonAxAirtime": 10773184,
                "nonAxATE": 37.2653423537554,
                "timestamp": "2020-05-05T01:25:00.000+0000"
            },
            {
                "modificationtime": 1588642200000,
                "axATE": 39.40691245959418,
                "nonAxBytes": 345645827,
                "axBytes": 363242358,
                "axAirtime": 9217732,
                "nonAxAirtime": 11077788,
                "nonAxATE": 31.20170082691599,
                "timestamp": "2020-05-05T01:30:00.000+0000"
            },
            {
                "modificationtime": 1588642500000,
                "axATE": 39.00814461769758,
                "nonAxBytes": 162487085,
                "axBytes": 213173113,
                "axAirtime": 5464836,
                "nonAxAirtime": 4821296,
                "nonAxATE": 33.70195171588718,
                "timestamp": "2020-05-05T01:35:00.000+0000"
            },
            {
                "modificationtime": 1588642800000,
                "axATE": 37.862139810168806,
                "nonAxBytes": 329183512,
                "axBytes": 410080520,
                "axAirtime": 10830886,
                "nonAxAirtime": 10352772,
                "nonAxATE": 31.796654268054972,
                "timestamp": "2020-05-05T01:40:00.000+0000"
            },
            {
                "modificationtime": 1588643100000,
                "axATE": 40.44575995133187,
                "nonAxBytes": 667320998,
                "axBytes": 744888833,
                "axAirtime": 18416982,
                "nonAxAirtime": 21644688,
                "nonAxATE": 30.830705344424462,
                "timestamp": "2020-05-05T01:45:00.000+0000"
            },
            {
                "modificationtime": 1588643400000,
                "axATE": 40.23684816451488,
                "nonAxBytes": 571737238,
                "axBytes": 853137707,
                "axAirtime": 21202896,
                "nonAxAirtime": 16817924,
                "nonAxATE": 33.995708269344064,
                "timestamp": "2020-05-05T01:50:00.000+0000"
            },
            {
                "modificationtime": 1588643700000,
                "axATE": 32.061186572379874,
                "nonAxBytes": 165386561,
                "axBytes": 177255528,
                "axAirtime": 5528664,
                "nonAxAirtime": 4869416,
                "nonAxATE": 33.96435239872707,
                "timestamp": "2020-05-05T01:55:00.000+0000"
            },
            {
                "modificationtime": 1588644000000,
                "axATE": 43.54635317300196,
                "nonAxBytes": 245554235,
                "axBytes": 533557092,
                "axAirtime": 12252624,
                "nonAxAirtime": 7504724,
                "nonAxATE": 32.71995545738924,
                "timestamp": "2020-05-05T02:00:00.000+0000"
            },
            {
                "modificationtime": 1588644300000,
                "axATE": 38.47139510602059,
                "nonAxBytes": 168715079,
                "axBytes": 190273057,
                "axAirtime": 4945832,
                "nonAxAirtime": 5255752,
                "nonAxATE": 32.10103501839508,
                "timestamp": "2020-05-05T02:05:00.000+0000"
            },
            {
                "modificationtime": 1588644600000,
                "axATE": 38.944247558389826,
                "nonAxBytes": 181582381,
                "axBytes": 193954815,
                "axAirtime": 4980320,
                "nonAxAirtime": 5481272,
                "nonAxATE": 33.127781471162166,
                "timestamp": "2020-05-05T02:10:00.000+0000"
            },
            {
                "modificationtime": 1588644900000,
                "axATE": 38.24688021066902,
                "nonAxBytes": 488169088,
                "axBytes": 653636735,
                "axAirtime": 17089936,
                "nonAxAirtime": 14841564,
                "nonAxATE": 32.89202458716615,
                "timestamp": "2020-05-05T02:15:00.000+0000"
            },
            {
                "modificationtime": 1588645200000,
                "axATE": 41.76918143493433,
                "nonAxBytes": 191944932,
                "axBytes": 164055895,
                "axAirtime": 3927678,
                "nonAxAirtime": 6688380,
                "nonAxATE": 28.698269536120854,
                "timestamp": "2020-05-05T02:20:00.000+0000"
            },
            {
                "modificationtime": 1588645500000,
                "axATE": 34.871609736951456,
                "nonAxBytes": 192615923,
                "axBytes": 181601161,
                "axAirtime": 5207708,
                "nonAxAirtime": 6150608,
                "nonAxATE": 31.31656626466847,
                "timestamp": "2020-05-05T02:25:00.000+0000"
            },
            {
                "modificationtime": 1588645800000,
                "axATE": 37.701767804523335,
                "nonAxBytes": 166702200,
                "axBytes": 214028110,
                "axAirtime": 5676872,
                "nonAxAirtime": 5053060,
                "nonAxATE": 32.99034644354114,
                "timestamp": "2020-05-05T02:30:00.000+0000"
            },
            {
                "modificationtime": 1588646100000,
                "axATE": 38.875330916464634,
                "nonAxBytes": 159799281,
                "axBytes": 207818189,
                "axAirtime": 5345760,
                "nonAxAirtime": 4741680,
                "nonAxATE": 33.70098382851648,
                "timestamp": "2020-05-05T02:35:00.000+0000"
            },
            {
                "modificationtime": 1588646400000,
                "axATE": 37.99638633035616,
                "nonAxBytes": 337883561,
                "axBytes": 409355436,
                "axAirtime": 10773536,
                "nonAxAirtime": 10116032,
                "nonAxATE": 33.40079993815757,
                "timestamp": "2020-05-05T02:40:00.000+0000"
            },
            {
                "modificationtime": 1588646700000,
                "axATE": 38.87579137991913,
                "nonAxBytes": 520685023,
                "axBytes": 610833073,
                "axAirtime": 15712428,
                "nonAxAirtime": 15648724,
                "nonAxATE": 33.273321390293546,
                "timestamp": "2020-05-05T02:45:00.000+0000"
            },
            {
                "modificationtime": 1588647000000,
                "axATE": 58.22545069961449,
                "nonAxBytes": 172813659,
                "axBytes": 82525959,
                "axAirtime": 1417352,
                "nonAxAirtime": 7344948,
                "nonAxATE": 23.528234508944106,
                "timestamp": "2020-05-05T02:50:00.000+0000"
            },
            {
                "modificationtime": 1588647300000,
                "axATE": 38.4598793476747,
                "nonAxBytes": 476129068,
                "axBytes": 663849670,
                "axAirtime": 17260836,
                "nonAxAirtime": 14510268,
                "nonAxATE": 32.813251140502715,
                "timestamp": "2020-05-05T02:55:00.000+0000"
            },
            {
                "modificationtime": 1588647600000,
                "axATE": 38.825181485733836,
                "nonAxBytes": 160216419,
                "axBytes": 299608490,
                "axAirtime": 7716860,
                "nonAxAirtime": 5326352,
                "nonAxATE": 30.07995322126664,
                "timestamp": "2020-05-05T03:00:00.000+0000"
            },
            {
                "modificationtime": 1588647900000,
                "axATE": 41.829708989182,
                "nonAxBytes": 65132260,
                "axBytes": 328358196,
                "axAirtime": 7849880,
                "nonAxAirtime": 2787204,
                "nonAxATE": 23.36831462641414,
                "timestamp": "2020-05-05T03:05:00.000+0000"
            },
            {
                "modificationtime": 1588648200000,
                "axATE": 49.92305174255901,
                "nonAxBytes": 206771884,
                "axBytes": 903305901,
                "axAirtime": 18093964,
                "nonAxAirtime": 8173084,
                "nonAxATE": 25.299126253933032,
                "timestamp": "2020-05-05T03:10:00.000+0000"
            },
            {
                "modificationtime": 1588648500000,
                "axATE": 38.377979593464744,
                "nonAxBytes": 488705584,
                "axBytes": 606177885,
                "axAirtime": 15794940,
                "nonAxAirtime": 14479820,
                "nonAxATE": 33.750805189567274,
                "timestamp": "2020-05-05T03:15:00.000+0000"
            },
            {
                "modificationtime": 1588648800000,
                "axATE": 38.25632074170339,
                "nonAxBytes": 170023734,
                "axBytes": 210772434,
                "axAirtime": 5509480,
                "nonAxAirtime": 5050844,
                "nonAxATE": 33.66244017831475,
                "timestamp": "2020-05-05T03:20:00.000+0000"
            },
            {
                "modificationtime": 1588649100000,
                "axATE": 38.170525925732136,
                "nonAxBytes": 348674222,
                "axBytes": 391022990,
                "axAirtime": 10244108,
                "nonAxAirtime": 10356636,
                "nonAxATE": 33.66674487739069,
                "timestamp": "2020-05-05T03:25:00.000+0000"
            },
            {
                "modificationtime": 1588649400000,
                "axATE": 37.906909992232656,
                "nonAxBytes": 484678984,
                "axBytes": 592467566,
                "axAirtime": 15629540,
                "nonAxAirtime": 14455976,
                "nonAxATE": 33.527932254453106,
                "timestamp": "2020-05-05T03:30:00.000+0000"
            },
            {
                "modificationtime": 1588649700000,
                "axATE": 37.777500126095354,
                "nonAxBytes": 165451641,
                "axBytes": 208218323,
                "axAirtime": 5511702,
                "nonAxAirtime": 5085188,
                "nonAxATE": 32.53599296623842,
                "timestamp": "2020-05-05T03:35:00.000+0000"
            },
            {
                "modificationtime": 1588650000000,
                "axATE": 39.49238706040859,
                "nonAxBytes": 169140625,
                "axBytes": 218317391,
                "axAirtime": 5528088,
                "nonAxAirtime": 4971492,
                "nonAxATE": 34.02210543635593,
                "timestamp": "2020-05-05T03:40:00.000+0000"
            },
            {
                "modificationtime": 1588650300000,
                "axATE": 38.163902342754234,
                "nonAxBytes": 170517352,
                "axBytes": 204207256,
                "axAirtime": 5350796,
                "nonAxAirtime": 5053800,
                "nonAxATE": 33.7404234437453,
                "timestamp": "2020-05-05T03:45:00.000+0000"
            },
            {
                "modificationtime": 1588650600000,
                "axATE": 38.23580850877635,
                "nonAxBytes": 167622102,
                "axBytes": 206228045,
                "axAirtime": 5393584,
                "nonAxAirtime": 4967108,
                "nonAxATE": 33.74641783508633,
                "timestamp": "2020-05-05T03:50:00.000+0000"
            },
            {
                "modificationtime": 1588650900000,
                "axATE": 59.12457974241612,
                "nonAxBytes": 427376195,
                "axBytes": 172059622,
                "axAirtime": 2910120,
                "nonAxAirtime": 17590580,
                "nonAxATE": 24.295742096053683,
                "timestamp": "2020-05-05T03:55:00.000+0000"
            },
            {
                "modificationtime": 1588651200000,
                "axATE": 38.60293615739811,
                "nonAxBytes": 164424602,
                "axBytes": 207663723,
                "axAirtime": 5379480,
                "nonAxAirtime": 4818432,
                "nonAxATE": 34.12408891523218,
                "timestamp": "2020-05-05T04:00:00.000+0000"
            },
            {
                "modificationtime": 1588651500000,
                "axATE": 38.718424774159416,
                "nonAxBytes": 152247135,
                "axBytes": 206106848,
                "axAirtime": 5323224,
                "nonAxAirtime": 4672164,
                "nonAxATE": 32.58599976370692,
                "timestamp": "2020-05-05T04:05:00.000+0000"
            },
            {
                "modificationtime": 1588651800000,
                "axATE": 38.880491284720094,
                "nonAxBytes": 337461277,
                "axBytes": 415746916,
                "axAirtime": 10692944,
                "nonAxAirtime": 10093128,
                "nonAxATE": 33.434756499669874,
                "timestamp": "2020-05-05T04:10:00.000+0000"
            },
            {
                "modificationtime": 1588652100000,
                "axATE": 39.092734414773446,
                "nonAxBytes": 155200370,
                "axBytes": 199955740,
                "axAirtime": 5114908,
                "nonAxAirtime": 4576088,
                "nonAxATE": 33.91551255133206,
                "timestamp": "2020-05-05T04:15:00.000+0000"
            },
            {
                "modificationtime": 1588652400000,
                "axATE": 38.40348668167353,
                "nonAxBytes": 493822249,
                "axBytes": 612707737,
                "axAirtime": 15954482,
                "nonAxAirtime": 14797832,
                "nonAxATE": 33.37125661380667,
                "timestamp": "2020-05-05T04:20:00.000+0000"
            },
            {
                "modificationtime": 1588652700000,
                "axATE": 40.07631222250777,
                "nonAxBytes": 104850487,
                "axBytes": 275090059,
                "axAirtime": 6864156,
                "nonAxAirtime": 3877316,
                "nonAxATE": 27.042027784168223,
                "timestamp": "2020-05-05T04:25:00.000+0000"
            },
            {
                "modificationtime": 1588653000000,
                "axATE": 29.926077879666742,
                "nonAxBytes": 165081447,
                "axBytes": 186722925,
                "axAirtime": 6239472,
                "nonAxAirtime": 4710820,
                "nonAxATE": 35.0430385792707,
                "timestamp": "2020-05-05T04:30:00.000+0000"
            },
            {
                "modificationtime": 1588653300000,
                "axATE": 40.26071463407297,
                "nonAxBytes": 154510481,
                "axBytes": 176660956,
                "axAirtime": 4387924,
                "nonAxAirtime": 5057072,
                "nonAxATE": 30.55334806385988,
                "timestamp": "2020-05-05T04:35:00.000+0000"
            },
            {
                "modificationtime": 1588653600000,
                "axATE": 41.65771119719341,
                "nonAxBytes": 345728692,
                "axBytes": 371141213,
                "axAirtime": 8909304,
                "nonAxAirtime": 11439088,
                "nonAxATE": 30.223448932292506,
                "timestamp": "2020-05-05T04:40:00.000+0000"
            },
            {
                "modificationtime": 1588653900000,
                "axATE": 41.20311973950342,
                "nonAxBytes": 362749727,
                "axBytes": 384774180,
                "axAirtime": 9338472,
                "nonAxAirtime": 12080236,
                "nonAxATE": 30.028364263744518,
                "timestamp": "2020-05-05T04:45:00.000+0000"
            },
            {
                "modificationtime": 1588654200000,
                "axATE": 42.03529152103054,
                "nonAxBytes": 525302458,
                "axBytes": 621256976,
                "axAirtime": 14779414,
                "nonAxAirtime": 17164400,
                "nonAxATE": 30.60418412528256,
                "timestamp": "2020-05-05T04:50:00.000+0000"
            },
            {
                "modificationtime": 1588654500000,
                "axATE": 52.362215469189316,
                "nonAxBytes": 216183218,
                "axBytes": 532260873,
                "axAirtime": 10164980,
                "nonAxAirtime": 7010456,
                "nonAxATE": 30.83725480910229,
                "timestamp": "2020-05-05T04:55:00.000+0000"
            },
            {
                "modificationtime": 1588654800000,
                "axATE": 46.630065061162206,
                "nonAxBytes": 213606993,
                "axBytes": 110746218,
                "axAirtime": 2374996,
                "nonAxAirtime": 8765160,
                "nonAxATE": 24.37000499705653,
                "timestamp": "2020-05-05T05:00:00.000+0000"
            },
            {
                "modificationtime": 1588655100000,
                "axATE": 17.362118678276914,
                "nonAxBytes": 270759675,
                "axBytes": 2408612,
                "axAirtime": 138728,
                "nonAxAirtime": 11941808,
                "nonAxATE": 22.67325642817235,
                "timestamp": "2020-05-05T05:05:00.000+0000"
            },
            {
                "modificationtime": 1588655400000,
                "axATE": 63.87579984541601,
                "nonAxBytes": 122085552,
                "axBytes": 80658783,
                "axAirtime": 1262744,
                "nonAxAirtime": 5569388,
                "nonAxATE": 21.92082002546779,
                "timestamp": "2020-05-05T05:10:00.000+0000"
            },
            {
                "modificationtime": 1588655700000,
                "axATE": 40.01005590993241,
                "nonAxBytes": 159301016,
                "axBytes": 207213840,
                "axAirtime": 5179044,
                "nonAxAirtime": 4666240,
                "nonAxATE": 34.13905328487176,
                "timestamp": "2020-05-05T05:15:00.000+0000"
            },
            {
                "modificationtime": 1588656000000,
                "axATE": 38.97919081182862,
                "nonAxBytes": 664896164,
                "axBytes": 837319170,
                "axAirtime": 21481184,
                "nonAxAirtime": 19486128,
                "nonAxATE": 34.121512698674664,
                "timestamp": "2020-05-05T05:20:00.000+0000"
            },
            {
                "modificationtime": 1588656300000,
                "axATE": 38.98787769465816,
                "nonAxBytes": 167583897,
                "axBytes": 207927966,
                "axAirtime": 5333144,
                "nonAxAirtime": 4800508,
                "nonAxATE": 34.90961727383852,
                "timestamp": "2020-05-05T05:25:00.000+0000"
            },
            {
                "modificationtime": 1588656600000,
                "axATE": 38.60580370920507,
                "nonAxBytes": 169471751,
                "axBytes": 208653405,
                "axAirtime": 5404716,
                "nonAxAirtime": 4979332,
                "nonAxATE": 34.03503743072364,
                "timestamp": "2020-05-05T05:30:00.000+0000"
            },
            {
                "modificationtime": 1588656900000,
                "axATE": 38.41012012603168,
                "nonAxBytes": 168552056,
                "axBytes": 207216222,
                "axAirtime": 5394834,
                "nonAxAirtime": 5044440,
                "nonAxATE": 33.41343261095384,
                "timestamp": "2020-05-05T05:35:00.000+0000"
            },
            {
                "modificationtime": 1588657200000,
                "axATE": 39.6358625946311,
                "nonAxBytes": 174015099,
                "axBytes": 211747620,
                "axAirtime": 5342324,
                "nonAxAirtime": 5016048,
                "nonAxATE": 34.69167340503919,
                "timestamp": "2020-05-05T05:40:00.000+0000"
            },
            {
                "modificationtime": 1588657500000,
                "axATE": 38.41939438473103,
                "nonAxBytes": 176966364,
                "axBytes": 204957480,
                "axAirtime": 5334740,
                "nonAxAirtime": 5052496,
                "nonAxATE": 35.025532726794836,
                "timestamp": "2020-05-05T05:45:00.000+0000"
            },
            {
                "modificationtime": 1588657800000,
                "axATE": 39.00985520884654,
                "nonAxBytes": 165370485,
                "axBytes": 207767113,
                "axAirtime": 5326016,
                "nonAxAirtime": 4819984,
                "nonAxATE": 34.309343143047776,
                "timestamp": "2020-05-05T05:50:00.000+0000"
            },
            {
                "modificationtime": 1588658100000,
                "axATE": 38.48863702930644,
                "nonAxBytes": 324506116,
                "axBytes": 399479414,
                "axAirtime": 10379152,
                "nonAxAirtime": 9571568,
                "nonAxATE": 33.90313018723787,
                "timestamp": "2020-05-05T05:55:00.000+0000"
            },
            {
                "modificationtime": 1588658400000,
                "axATE": 38.90214983926244,
                "nonAxBytes": 171963501,
                "axBytes": 190471928,
                "axAirtime": 4896180,
                "nonAxAirtime": 5404192,
                "nonAxATE": 31.820390726310244,
                "timestamp": "2020-05-05T06:00:00.000+0000"
            },
            {
                "modificationtime": 1588658700000,
                "axATE": 38.25982598254221,
                "nonAxBytes": 163801140,
                "axBytes": 204814643,
                "axAirtime": 5353256,
                "nonAxAirtime": 4858972,
                "nonAxATE": 33.711068925690455,
                "timestamp": "2020-05-05T06:05:00.000+0000"
            },
            {
                "modificationtime": 1588659000000,
                "axATE": 39.021222558178934,
                "nonAxBytes": 167247427,
                "axBytes": 209120819,
                "axAirtime": 5359156,
                "nonAxAirtime": 4888492,
                "nonAxATE": 34.21247840847443,
                "timestamp": "2020-05-05T06:10:00.000+0000"
            },
            {
                "modificationtime": 1588659300000,
                "axATE": 38.73639140381878,
                "nonAxBytes": 119884889,
                "axBytes": 292169697,
                "axAirtime": 7542512,
                "nonAxAirtime": 4418240,
                "nonAxATE": 27.134082575867314,
                "timestamp": "2020-05-05T06:15:00.000+0000"
            },
            {
                "modificationtime": 1588659600000,
                "axATE": 39.65021989459216,
                "nonAxBytes": 163716613,
                "axBytes": 219587200,
                "axAirtime": 5538108,
                "nonAxAirtime": 4755616,
                "nonAxATE": 34.42595302059712,
                "timestamp": "2020-05-05T06:20:00.000+0000"
            },
            {
                "modificationtime": 1588659900000,
                "axATE": 37.59579860394382,
                "nonAxBytes": 334250722,
                "axBytes": 394503016,
                "axAirtime": 10493274,
                "nonAxAirtime": 9834948,
                "nonAxATE": 33.98601822805774,
                "timestamp": "2020-05-05T06:25:00.000+0000"
            },
            {
                "modificationtime": 1588660200000,
                "axATE": 37.67868128112948,
                "nonAxBytes": 322261915,
                "axBytes": 400910061,
                "axAirtime": 10640236,
                "nonAxAirtime": 9730840,
                "nonAxATE": 33.11758440175771,
                "timestamp": "2020-05-05T06:30:00.000+0000"
            },
            {
                "modificationtime": 1588660500000,
                "axATE": 37.64835630732042,
                "nonAxBytes": 496159692,
                "axBytes": 613521831,
                "axAirtime": 16296112,
                "nonAxAirtime": 14940264,
                "nonAxATE": 33.20956657793999,
                "timestamp": "2020-05-05T06:35:00.000+0000"
            },
            {
                "modificationtime": 1588660800000,
                "axATE": 37.41830527422136,
                "nonAxBytes": 165684371,
                "axBytes": 196947957,
                "axAirtime": 5263412,
                "nonAxAirtime": 4926220,
                "nonAxATE": 33.6331651854769,
                "timestamp": "2020-05-05T06:40:00.000+0000"
            },
            {
                "modificationtime": 1588661100000,
                "axATE": 38.211568335500296,
                "nonAxBytes": 156040596,
                "axBytes": 194323708,
                "axAirtime": 5085468,
                "nonAxAirtime": 4572876,
                "nonAxATE": 34.123076156012104,
                "timestamp": "2020-05-05T06:45:00.000+0000"
            },
            {
                "modificationtime": 1588661400000,
                "axATE": 37.92740244191736,
                "nonAxBytes": 163533758,
                "axBytes": 204647161,
                "axAirtime": 5395760,
                "nonAxAirtime": 4946816,
                "nonAxATE": 33.05838705138821,
                "timestamp": "2020-05-05T06:50:00.000+0000"
            },
            {
                "modificationtime": 1588661700000,
                "axATE": 38.97938089027186,
                "nonAxBytes": 156541928,
                "axBytes": 212904287,
                "axAirtime": 5461972,
                "nonAxAirtime": 4690332,
                "nonAxATE": 33.37544719648844,
                "timestamp": "2020-05-05T06:55:00.000+0000"
            },
            {
                "modificationtime": 1588662000000,
                "axATE": 37.41599976683098,
                "nonAxBytes": 167928037,
                "axBytes": 205398124,
                "axAirtime": 5489580,
                "nonAxAirtime": 5138824,
                "nonAxATE": 32.67830091086988,
                "timestamp": "2020-05-05T07:00:00.000+0000"
            },
            {
                "modificationtime": 1588662300000,
                "axATE": 39.17238542801505,
                "nonAxBytes": 174024829,
                "axBytes": 206389114,
                "axAirtime": 5268740,
                "nonAxAirtime": 5141940,
                "nonAxATE": 33.84419674286359,
                "timestamp": "2020-05-05T07:05:00.000+0000"
            },
            {
                "modificationtime": 1588662600000,
                "axATE": 38.351355238521066,
                "nonAxBytes": 168422183,
                "axBytes": 201055906,
                "axAirtime": 5242472,
                "nonAxAirtime": 5023236,
                "nonAxATE": 33.52862238604756,
                "timestamp": "2020-05-05T07:10:00.000+0000"
            },
            {
                "modificationtime": 1588662900000,
                "axATE": 37.94986845628894,
                "nonAxBytes": 347621712,
                "axBytes": 103166262,
                "axAirtime": 2718488,
                "nonAxAirtime": 15232420,
                "nonAxATE": 22.821174311107495,
                "timestamp": "2020-05-05T07:15:00.000+0000"
            },
            {
                "modificationtime": 1588663200000,
                "axATE": 39.195419349508875,
                "nonAxBytes": 316143650,
                "axBytes": 411822038,
                "axAirtime": 10506892,
                "nonAxAirtime": 9368716,
                "nonAxATE": 33.744608119191575,
                "timestamp": "2020-05-05T07:20:00.000+0000"
            },
            {
                "modificationtime": 1588663500000,
                "axATE": 40.36331018114902,
                "nonAxBytes": 286893661,
                "axBytes": 481501354,
                "axAirtime": 11929184,
                "nonAxAirtime": 8851312,
                "nonAxATE": 32.41255770895885,
                "timestamp": "2020-05-05T07:25:00.000+0000"
            },
            {
                "modificationtime": 1588663800000,
                "axATE": 39.05041170198883,
                "nonAxBytes": 475256158,
                "axBytes": 634364566,
                "axAirtime": 16244760,
                "nonAxAirtime": 14362204,
                "nonAxATE": 33.09075389821785,
                "timestamp": "2020-05-05T07:30:00.000+0000"
            },
            {
                "modificationtime": 1588664100000,
                "axATE": 39.68287569661806,
                "nonAxBytes": 337102144,
                "axBytes": 415844791,
                "axAirtime": 10479200,
                "nonAxAirtime": 10138356,
                "nonAxATE": 33.25017823402532,
                "timestamp": "2020-05-05T07:35:00.000+0000"
            },
            {
                "modificationtime": 1588664400000,
                "axATE": 40.09304892806024,
                "nonAxBytes": 345467633,
                "axBytes": 362466020,
                "axAirtime": 9040620,
                "nonAxAirtime": 11016956,
                "nonAxATE": 31.35781181299081,
                "timestamp": "2020-05-05T07:40:00.000+0000"
            },
            {
                "modificationtime": 1588664700000,
                "axATE": 44.08760459200665,
                "nonAxBytes": 303746496,
                "axBytes": 520271385,
                "axAirtime": 11800854,
                "nonAxAirtime": 8885800,
                "nonAxATE": 34.18335951743231,
                "timestamp": "2020-05-05T07:45:00.000+0000"
            },
            {
                "modificationtime": 1588665000000,
                "axATE": 39.1772817772518,
                "nonAxBytes": 154781218,
                "axBytes": 199684568,
                "axAirtime": 5096948,
                "nonAxAirtime": 4495452,
                "nonAxATE": 34.43062410631901,
                "timestamp": "2020-05-05T07:50:00.000+0000"
            },
            {
                "modificationtime": 1588665300000,
                "axATE": 39.12524518092048,
                "nonAxBytes": 345323418,
                "axBytes": 431336735,
                "axAirtime": 11024512,
                "nonAxAirtime": 9997596,
                "nonAxATE": 34.54064537114722,
                "timestamp": "2020-05-05T07:55:00.000+0000"
            },
            {
                "modificationtime": 1588665600000,
                "axATE": 32.62129050953458,
                "nonAxBytes": 252362664,
                "axBytes": 150848024,
                "axAirtime": 4624220,
                "nonAxAirtime": 6494600,
                "nonAxATE": 38.85730668555415,
                "timestamp": "2020-05-05T08:00:00.000+0000"
            },
            {
                "modificationtime": 1588665900000,
                "axATE": 38.73519916282591,
                "nonAxBytes": 158313387,
                "axBytes": 201806824,
                "axAirtime": 5209908,
                "nonAxAirtime": 4729392,
                "nonAxATE": 33.47436351226543,
                "timestamp": "2020-05-05T08:05:00.000+0000"
            },
            {
                "modificationtime": 1588666200000,
                "axATE": 39.69147169794369,
                "nonAxBytes": 505196357,
                "axBytes": 636156174,
                "axAirtime": 16027528,
                "nonAxAirtime": 14714528,
                "nonAxATE": 34.33316766939449,
                "timestamp": "2020-05-05T08:10:00.000+0000"
            },
            {
                "modificationtime": 1588666500000,
                "axATE": 39.35147538058578,
                "nonAxBytes": 160247320,
                "axBytes": 203785393,
                "axAirtime": 5178596,
                "nonAxAirtime": 4632844,
                "nonAxATE": 34.5894055573639,
                "timestamp": "2020-05-05T08:15:00.000+0000"
            },
            {
                "modificationtime": 1588666800000,
                "axATE": 40.21912168049826,
                "nonAxBytes": 285690115,
                "axBytes": 400264560,
                "axAirtime": 9952096,
                "nonAxAirtime": 8606796,
                "nonAxATE": 33.193550189873214,
                "timestamp": "2020-05-05T08:20:00.000+0000"
            },
            {
                "modificationtime": 1588667100000,
                "axATE": 41.14722258820013,
                "nonAxBytes": 310387398,
                "axBytes": 418492765,
                "axAirtime": 10170620,
                "nonAxAirtime": 9106824,
                "nonAxATE": 34.08294680999655,
                "timestamp": "2020-05-05T08:25:00.000+0000"
            },
            {
                "modificationtime": 1588667400000,
                "axATE": 40.182799674905766,
                "nonAxBytes": 471442362,
                "axBytes": 621379742,
                "axAirtime": 15463824,
                "nonAxAirtime": 13567576,
                "nonAxATE": 34.74772221655512,
                "timestamp": "2020-05-05T08:30:00.000+0000"
            },
            {
                "modificationtime": 1588667700000,
                "axATE": 39.223897917910996,
                "nonAxBytes": 147906416,
                "axBytes": 209622238,
                "axAirtime": 5344248,
                "nonAxAirtime": 4431880,
                "nonAxATE": 33.3732898905205,
                "timestamp": "2020-05-05T08:35:00.000+0000"
            },
            {
                "modificationtime": 1588668000000,
                "axATE": 40.00089692555008,
                "nonAxBytes": 174362496,
                "axBytes": 211705707,
                "axAirtime": 5292524,
                "nonAxAirtime": 5022476,
                "nonAxATE": 34.71644185059321,
                "timestamp": "2020-05-05T08:40:00.000+0000"
            },
            {
                "modificationtime": 1588668300000,
                "axATE": 38.89097764098194,
                "nonAxBytes": 327922926,
                "axBytes": 407420015,
                "axAirtime": 10475952,
                "nonAxAirtime": 9597860,
                "nonAxATE": 34.166254352532754,
                "timestamp": "2020-05-05T08:45:00.000+0000"
            },
            {
                "modificationtime": 1588668600000,
                "axATE": 58.7388803667522,
                "nonAxBytes": 231463122,
                "axBytes": 66933894,
                "axAirtime": 1139516,
                "nonAxAirtime": 9670164,
                "nonAxATE": 23.93580108879229,
                "timestamp": "2020-05-05T08:50:00.000+0000"
            },
            {
                "modificationtime": 1588668900000,
                "axATE": 70.11404678842602,
                "nonAxBytes": 201786253,
                "axBytes": 58766509,
                "axAirtime": 838156,
                "nonAxAirtime": 8560472,
                "nonAxATE": 23.571860640394593,
                "timestamp": "2020-05-05T08:55:00.000+0000"
            },
            {
                "modificationtime": 1588669200000,
                "axATE": 50.51297546464127,
                "nonAxBytes": 211745360,
                "axBytes": 56237712,
                "axAirtime": 1113332,
                "nonAxAirtime": 8725504,
                "nonAxATE": 24.267407361225207,
                "timestamp": "2020-05-05T09:00:00.000+0000"
            },
            {
                "modificationtime": 1588669500000,
                "axATE": 61.44499493485028,
                "nonAxBytes": 256277497,
                "axBytes": 119853624,
                "axAirtime": 1950584,
                "nonAxAirtime": 11438184,
                "nonAxATE": 22.405435775469254,
                "timestamp": "2020-05-05T09:05:00.000+0000"
            },
            {
                "modificationtime": 1588669800000,
                "axATE": 30.73746670935118,
                "nonAxBytes": 313047750,
                "axBytes": 244260689,
                "axAirtime": 7946676,
                "nonAxAirtime": 9325924,
                "nonAxATE": 33.56747813943154,
                "timestamp": "2020-05-05T09:10:00.000+0000"
            },
            {
                "modificationtime": 1588670100000,
                "axATE": 40.6993034930847,
                "nonAxBytes": 308165482,
                "axBytes": 408917298,
                "axAirtime": 10047280,
                "nonAxAirtime": 9161012,
                "nonAxATE": 33.63880344223979,
                "timestamp": "2020-05-05T09:15:00.000+0000"
            },
            {
                "modificationtime": 1588670400000,
                "axATE": 39.11958155361225,
                "nonAxBytes": 171608042,
                "axBytes": 204274318,
                "axAirtime": 5221792,
                "nonAxAirtime": 4957744,
                "nonAxATE": 34.614139415024255,
                "timestamp": "2020-05-05T09:20:00.000+0000"
            },
            {
                "modificationtime": 1588670700000,
                "axATE": 40.092805035734955,
                "nonAxBytes": 182381139,
                "axBytes": 217792777,
                "axAirtime": 5432216,
                "nonAxAirtime": 5239752,
                "nonAxATE": 34.80720824191679,
                "timestamp": "2020-05-05T09:25:00.000+0000"
            },
            {
                "modificationtime": 1588671000000,
                "axATE": 39.543080021270875,
                "nonAxBytes": 175319832,
                "axBytes": 212969855,
                "axAirtime": 5385768,
                "nonAxAirtime": 5137452,
                "nonAxATE": 34.12583358443057,
                "timestamp": "2020-05-05T09:30:00.000+0000"
            },
            {
                "modificationtime": 1588671300000,
                "axATE": 39.712858068322205,
                "nonAxBytes": 171370060,
                "axBytes": 200334213,
                "axAirtime": 5044568,
                "nonAxAirtime": 5396624,
                "nonAxATE": 31.75504908253753,
                "timestamp": "2020-05-05T09:35:00.000+0000"
            },
            {
                "modificationtime": 1588671600000,
                "axATE": 39.36400179562521,
                "nonAxBytes": 162125089,
                "axBytes": 200631704,
                "axAirtime": 5096832,
                "nonAxAirtime": 4681992,
                "nonAxATE": 34.627374203116965,
                "timestamp": "2020-05-05T09:40:00.000+0000"
            },
            {
                "modificationtime": 1588671900000,
                "axATE": 39.54508740101153,
                "nonAxBytes": 339170438,
                "axBytes": 404205840,
                "axAirtime": 10221392,
                "nonAxAirtime": 10120248,
                "nonAxATE": 33.5140441222389,
                "timestamp": "2020-05-05T09:45:00.000+0000"
            },
            {
                "modificationtime": 1588672200000,
                "axATE": 38.79131068424165,
                "nonAxBytes": 170457864,
                "axBytes": 203355688,
                "axAirtime": 5242300,
                "nonAxAirtime": 4995768,
                "nonAxATE": 34.120452350869776,
                "timestamp": "2020-05-05T09:50:00.000+0000"
            },
            {
                "modificationtime": 1588672500000,
                "axATE": 39.33604467390159,
                "nonAxBytes": 168609499,
                "axBytes": 209069504,
                "axAirtime": 5314960,
                "nonAxAirtime": 4969048,
                "nonAxATE": 33.9319521566304,
                "timestamp": "2020-05-05T09:55:00.000+0000"
            },
            {
                "modificationtime": 1588672800000,
                "axATE": 38.811391249385494,
                "nonAxBytes": 172962277,
                "axBytes": 198948744,
                "axAirtime": 5126040,
                "nonAxAirtime": 5583648,
                "nonAxATE": 30.976572484511916,
                "timestamp": "2020-05-05T10:00:00.000+0000"
            },
            {
                "modificationtime": 1588673100000,
                "axATE": 38.543676399571176,
                "nonAxBytes": 164213978,
                "axBytes": 204644258,
                "axAirtime": 5309412,
                "nonAxAirtime": 4919820,
                "nonAxATE": 33.378045944770335,
                "timestamp": "2020-05-05T10:05:00.000+0000"
            },
            {
                "modificationtime": 1588673400000,
                "axATE": 38.880456485197165,
                "nonAxBytes": 326577072,
                "axBytes": 419213903,
                "axAirtime": 10782124,
                "nonAxAirtime": 9548532,
                "nonAxATE": 34.20180945091874,
                "timestamp": "2020-05-05T10:10:00.000+0000"
            },
            {
                "modificationtime": 1588673700000,
                "axATE": 38.57123301093127,
                "nonAxBytes": 161759925,
                "axBytes": 207067813,
                "axAirtime": 5368452,
                "nonAxAirtime": 4854064,
                "nonAxATE": 33.32463787045247,
                "timestamp": "2020-05-05T10:15:00.000+0000"
            },
            {
                "modificationtime": 1588674000000,
                "axATE": 39.76252004606247,
                "nonAxBytes": 336568801,
                "axBytes": 411588207,
                "axAirtime": 10351160,
                "nonAxAirtime": 9710220,
                "nonAxATE": 34.66129510968855,
                "timestamp": "2020-05-05T10:20:00.000+0000"
            },
            {
                "modificationtime": 1588674300000,
                "axATE": 39.03879070825536,
                "nonAxBytes": 171119340,
                "axBytes": 215537692,
                "axAirtime": 5521116,
                "nonAxAirtime": 5075156,
                "nonAxATE": 33.717060125836525,
                "timestamp": "2020-05-05T10:25:00.000+0000"
            },
            {
                "modificationtime": 1588674600000,
                "axATE": 38.78863188976378,
                "nonAxBytes": 180490254,
                "axBytes": 215332142,
                "axAirtime": 5551424,
                "nonAxAirtime": 5310956,
                "nonAxATE": 33.98451314603246,
                "timestamp": "2020-05-05T10:30:00.000+0000"
            },
            {
                "modificationtime": 1588674900000,
                "axATE": 40.998006174056925,
                "nonAxBytes": 136653371,
                "axBytes": 256105689,
                "axAirtime": 6246784,
                "nonAxAirtime": 4535856,
                "nonAxATE": 30.127360965603845,
                "timestamp": "2020-05-05T10:35:00.000+0000"
            },
            {
                "modificationtime": 1588675200000,
                "axATE": 52.16567941657985,
                "nonAxBytes": 208575584,
                "axBytes": 126180432,
                "axAirtime": 2418840,
                "nonAxAirtime": 8868108,
                "nonAxATE": 23.519738821403617,
                "timestamp": "2020-05-05T10:40:00.000+0000"
            },
            {
                "modificationtime": 1588675500000,
                "axATE": 17.57927712046328,
                "nonAxBytes": 443246844,
                "axBytes": 1408522,
                "axAirtime": 80124,
                "nonAxAirtime": 18886464,
                "nonAxATE": 23.469022258480994,
                "timestamp": "2020-05-05T10:45:00.000+0000"
            },
            {
                "modificationtime": 1588675800000,
                "axATE": 17.370417024808084,
                "nonAxBytes": 426172122,
                "axBytes": 2344242,
                "axAirtime": 134956,
                "nonAxAirtime": 18251712,
                "nonAxATE": 23.349706701486415,
                "timestamp": "2020-05-05T10:50:00.000+0000"
            },
            {
                "modificationtime": 1588676100000,
                "axATE": 66.72121348242655,
                "nonAxBytes": 402149860,
                "axBytes": 141700645,
                "axAirtime": 2123772,
                "nonAxAirtime": 17026576,
                "nonAxATE": 23.618950750873225,
                "timestamp": "2020-05-05T10:55:00.000+0000"
            },
            {
                "modificationtime": 1588676400000,
                "axATE": 39.12765770334015,
                "nonAxBytes": 332339934,
                "axBytes": 418225360,
                "axAirtime": 10688740,
                "nonAxAirtime": 9772928,
                "nonAxATE": 34.00617849635237,
                "timestamp": "2020-05-05T11:00:00.000+0000"
            },
            {
                "modificationtime": 1588676700000,
                "axATE": 39.585135436445775,
                "nonAxBytes": 144955358,
                "axBytes": 222984968,
                "axAirtime": 5633048,
                "nonAxAirtime": 4368004,
                "nonAxATE": 33.185720068021915,
                "timestamp": "2020-05-05T11:05:00.000+0000"
            },
            {
                "modificationtime": 1588677000000,
                "axATE": 37.64922033406706,
                "nonAxBytes": 158173300,
                "axBytes": 195227246,
                "axAirtime": 5185426,
                "nonAxAirtime": 4726620,
                "nonAxATE": 33.464357193935626,
                "timestamp": "2020-05-05T11:10:00.000+0000"
            },
            {
                "modificationtime": 1588677300000,
                "axATE": 37.89961287874507,
                "nonAxBytes": 163873813,
                "axBytes": 206551829,
                "axAirtime": 5449972,
                "nonAxAirtime": 4918612,
                "nonAxATE": 33.317084779201934,
                "timestamp": "2020-05-05T11:15:00.000+0000"
            },
            {
                "modificationtime": 1588677600000,
                "axATE": 40.407696655128056,
                "nonAxBytes": 88464804,
                "axBytes": 325168170,
                "axAirtime": 8047184,
                "nonAxAirtime": 4630664,
                "nonAxATE": 19.104129343005667,
                "timestamp": "2020-05-05T11:20:00.000+0000"
            },
            {
                "modificationtime": 1588677900000,
                "axATE": 38.20321450608586,
                "nonAxBytes": 356351222,
                "axBytes": 412097158,
                "axAirtime": 10786976,
                "nonAxAirtime": 10807112,
                "nonAxATE": 32.973769680558505,
                "timestamp": "2020-05-05T11:25:00.000+0000"
            },
            {
                "modificationtime": 1588678200000,
                "axATE": 48.32871864518712,
                "nonAxBytes": 203051746,
                "axBytes": 85475525,
                "axAirtime": 1768628,
                "nonAxAirtime": 8609636,
                "nonAxATE": 23.584242818163276,
                "timestamp": "2020-05-05T11:30:00.000+0000"
            },
            {
                "modificationtime": 1588678500000,
                "axATE": 34.904829079657844,
                "nonAxBytes": 534287356,
                "axBytes": 580752131,
                "axAirtime": 16638160,
                "nonAxAirtime": 15242692,
                "nonAxATE": 35.05203385333772,
                "timestamp": "2020-05-05T11:35:00.000+0000"
            },
            {
                "modificationtime": 1588678800000,
                "axATE": 39.83473257690177,
                "nonAxBytes": 160782744,
                "axBytes": 185255726,
                "axAirtime": 4650608,
                "nonAxAirtime": 5253680,
                "nonAxATE": 30.603832742001796,
                "timestamp": "2020-05-05T11:40:00.000+0000"
            },
            {
                "modificationtime": 1588679100000,
                "axATE": 38.540759709813706,
                "nonAxBytes": 333443931,
                "axBytes": 412147947,
                "axAirtime": 10693820,
                "nonAxAirtime": 10033736,
                "nonAxATE": 33.23228067790502,
                "timestamp": "2020-05-05T11:45:00.000+0000"
            },
            {
                "modificationtime": 1588679400000,
                "axATE": 39.94788229250405,
                "nonAxBytes": 281461937,
                "axBytes": 539672720,
                "axAirtime": 13509420,
                "nonAxAirtime": 9343756,
                "nonAxATE": 30.122997325700712,
                "timestamp": "2020-05-05T11:50:00.000+0000"
            },
            {
                "modificationtime": 1588679700000,
                "axATE": 38.408771989546615,
                "nonAxBytes": 87689909,
                "axBytes": 293355446,
                "axAirtime": 7637720,
                "nonAxAirtime": 3892344,
                "nonAxATE": 22.528817853714884,
                "timestamp": "2020-05-05T11:55:00.000+0000"
            },
            {
                "modificationtime": 1588680000000,
                "axATE": 40.80489858566533,
                "nonAxBytes": 133201076,
                "axBytes": 623792156,
                "axAirtime": 15287188,
                "nonAxAirtime": 6455348,
                "nonAxATE": 20.63422080420761,
                "timestamp": "2020-05-05T12:00:00.000+0000"
            },
            {
                "modificationtime": 1588680300000,
                "axATE": 38.97799848259256,
                "nonAxBytes": 163499989,
                "axBytes": 218855225,
                "axAirtime": 5614840,
                "nonAxAirtime": 5089744,
                "nonAxATE": 32.12342094219277,
                "timestamp": "2020-05-05T12:05:00.000+0000"
            },
            {
                "modificationtime": 1588680600000,
                "axATE": 38.72878193288795,
                "nonAxBytes": 161340179,
                "axBytes": 205748513,
                "axAirtime": 5312548,
                "nonAxAirtime": 4875364,
                "nonAxATE": 33.09295039303732,
                "timestamp": "2020-05-05T12:10:00.000+0000"
            },
            {
                "modificationtime": 1588680900000,
                "axATE": 37.94111508859181,
                "nonAxBytes": 344644473,
                "axBytes": 404028864,
                "axAirtime": 10648840,
                "nonAxAirtime": 10288124,
                "nonAxATE": 33.49925341102032,
                "timestamp": "2020-05-05T12:15:00.000+0000"
            },
            {
                "modificationtime": 1588681200000,
                "axATE": 39.13582560063597,
                "nonAxBytes": 327155150,
                "axBytes": 408412553,
                "axAirtime": 10435772,
                "nonAxAirtime": 9636676,
                "nonAxATE": 33.94896227703411,
                "timestamp": "2020-05-05T12:20:00.000+0000"
            },
            {
                "modificationtime": 1588681500000,
                "axATE": 41.31014795827898,
                "nonAxBytes": 152667697,
                "axBytes": 198489808,
                "axAirtime": 4804868,
                "nonAxAirtime": 4528232,
                "nonAxATE": 33.71463675006051,
                "timestamp": "2020-05-05T12:25:00.000+0000"
            },
            {
                "modificationtime": 1588681800000,
                "axATE": 38.913993663490885,
                "nonAxBytes": 161408511,
                "axBytes": 206885936,
                "axAirtime": 5316492,
                "nonAxAirtime": 4790376,
                "nonAxATE": 33.69433025716562,
                "timestamp": "2020-05-05T12:30:00.000+0000"
            },
            {
                "modificationtime": 1588682100000,
                "axATE": 38.11977074164253,
                "nonAxBytes": 220799956,
                "axBytes": 188435328,
                "axAirtime": 4943244,
                "nonAxAirtime": 6890792,
                "nonAxATE": 32.04275444680379,
                "timestamp": "2020-05-05T12:35:00.000+0000"
            },
            {
                "modificationtime": 1588682400000,
                "axATE": 35.346434811788015,
                "nonAxBytes": 480455656,
                "axBytes": 309263490,
                "axAirtime": 8749496,
                "nonAxAirtime": 12162672,
                "nonAxATE": 39.5024757717712,
                "timestamp": "2020-05-05T12:40:00.000+0000"
            },
            {
                "modificationtime": 1588682700000,
                "axATE": 40.47752335295317,
                "nonAxBytes": 138636039,
                "axBytes": 204025985,
                "axAirtime": 5040476,
                "nonAxAirtime": 4285672,
                "nonAxATE": 32.34872827411897,
                "timestamp": "2020-05-05T12:45:00.000+0000"
            },
            {
                "modificationtime": 1588683000000,
                "axATE": 39.061779351917814,
                "nonAxBytes": 169093111,
                "axBytes": 197613073,
                "axAirtime": 5058988,
                "nonAxAirtime": 5056796,
                "nonAxATE": 33.43878436069005,
                "timestamp": "2020-05-05T12:50:00.000+0000"
            },
            {
                "modificationtime": 1588683300000,
                "axATE": 38.74521604747795,
                "nonAxBytes": 157710260,
                "axBytes": 213457159,
                "axAirtime": 5509252,
                "nonAxAirtime": 4805128,
                "nonAxATE": 32.821240141781864,
                "timestamp": "2020-05-05T12:55:00.000+0000"
            },
            {
                "modificationtime": 1588683600000,
                "axATE": 38.77210276355617,
                "nonAxBytes": 491029149,
                "axBytes": 606900500,
                "axAirtime": 15653020,
                "nonAxAirtime": 14647904,
                "nonAxATE": 33.52214412382823,
                "timestamp": "2020-05-05T13:00:00.000+0000"
            },
            {
                "modificationtime": 1588683900000,
                "axATE": 40.14600806287594,
                "nonAxBytes": 134934056,
                "axBytes": 252660347,
                "axAirtime": 6293536,
                "nonAxAirtime": 4439612,
                "nonAxATE": 30.393209136293894,
                "timestamp": "2020-05-05T13:05:00.000+0000"
            },
            {
                "modificationtime": 1588684200000,
                "axATE": 39.191168149517395,
                "nonAxBytes": 163821571,
                "axBytes": 209727774,
                "axAirtime": 5351404,
                "nonAxAirtime": 4772864,
                "nonAxATE": 34.3235363505015,
                "timestamp": "2020-05-05T13:10:00.000+0000"
            },
            {
                "modificationtime": 1588684500000,
                "axATE": 35.14399422217235,
                "nonAxBytes": 241520092,
                "axBytes": 172890739,
                "axAirtime": 4919496,
                "nonAxAirtime": 6355924,
                "nonAxATE": 37.99921018564728,
                "timestamp": "2020-05-05T13:15:00.000+0000"
            },
            {
                "modificationtime": 1588684800000,
                "axATE": 39.480958349291235,
                "nonAxBytes": 347682029,
                "axBytes": 411520136,
                "axAirtime": 10423256,
                "nonAxAirtime": 10389548,
                "nonAxATE": 33.46459624615046,
                "timestamp": "2020-05-05T13:20:00.000+0000"
            },
            {
                "modificationtime": 1588685100000,
                "axATE": 40.03309867658074,
                "nonAxBytes": 171581580,
                "axBytes": 221476553,
                "axAirtime": 5532336,
                "nonAxAirtime": 4983140,
                "nonAxATE": 34.43242212741364,
                "timestamp": "2020-05-05T13:25:00.000+0000"
            },
            {
                "modificationtime": 1588685400000,
                "axATE": 40.202275965316275,
                "nonAxBytes": 132313313,
                "axBytes": 195602244,
                "axAirtime": 4865452,
                "nonAxAirtime": 4193628,
                "nonAxATE": 31.551037192616988,
                "timestamp": "2020-05-05T13:30:00.000+0000"
            },
            {
                "modificationtime": 1588685700000,
                "axATE": 38.55872023088841,
                "nonAxBytes": 129046418,
                "axBytes": 78343762,
                "axAirtime": 2031804,
                "nonAxAirtime": 5609228,
                "nonAxATE": 23.006092460495456,
                "timestamp": "2020-05-05T13:35:00.000+0000"
            },
            {
                "modificationtime": 1588686000000,
                "axATE": 39.91801944698247,
                "nonAxBytes": 514168526,
                "axBytes": 645372344,
                "axAirtime": 16167444,
                "nonAxAirtime": 14912592,
                "nonAxATE": 34.47881669397245,
                "timestamp": "2020-05-05T13:40:00.000+0000"
            },
            {
                "modificationtime": 1588686300000,
                "axATE": 40.61965049365264,
                "nonAxBytes": 146716629,
                "axBytes": 236549672,
                "axAirtime": 5823528,
                "nonAxAirtime": 4539856,
                "nonAxATE": 32.317463153016305,
                "timestamp": "2020-05-05T13:45:00.000+0000"
            },
            {
                "modificationtime": 1588686600000,
                "axATE": 39.19140011318794,
                "nonAxBytes": 329754665,
                "axBytes": 405528780,
                "axAirtime": 10347392,
                "nonAxAirtime": 9617700,
                "nonAxATE": 34.28622903604812,
                "timestamp": "2020-05-05T13:50:00.000+0000"
            },
            {
                "modificationtime": 1588686900000,
                "axATE": 41.00171092129926,
                "nonAxBytes": 172548516,
                "axBytes": 200728304,
                "axAirtime": 4895608,
                "nonAxAirtime": 5324592,
                "nonAxATE": 32.40596011863444,
                "timestamp": "2020-05-05T13:55:00.000+0000"
            },
            {
                "modificationtime": 1588687200000,
                "axATE": 39.609046608196934,
                "nonAxBytes": 167532244,
                "axBytes": 212712463,
                "axAirtime": 5370300,
                "nonAxAirtime": 4982304,
                "nonAxATE": 33.625456013924484,
                "timestamp": "2020-05-05T14:00:00.000+0000"
            },
            {
                "modificationtime": 1588687500000,
                "axATE": 40.23401953005772,
                "nonAxBytes": 326317112,
                "axBytes": 381746171,
                "axAirtime": 9488144,
                "nonAxAirtime": 10487328,
                "nonAxATE": 31.115371999426355,
                "timestamp": "2020-05-05T14:05:00.000+0000"
            },
            {
                "modificationtime": 1588687800000,
                "axATE": 40.37156104371308,
                "nonAxBytes": 122603166,
                "axBytes": 320053463,
                "axAirtime": 7927696,
                "nonAxAirtime": 5119348,
                "nonAxATE": 23.948980612374857,
                "timestamp": "2020-05-05T14:10:00.000+0000"
            },
            {
                "modificationtime": 1588688100000,
                "axATE": 40.42791328794971,
                "nonAxBytes": 173967481,
                "axBytes": 648647757,
                "axAirtime": 16044552,
                "nonAxAirtime": 7624112,
                "nonAxATE": 22.818064713634847,
                "timestamp": "2020-05-05T14:15:00.000+0000"
            },
            {
                "modificationtime": 1588688400000,
                "axATE": 40.166715462520685,
                "nonAxBytes": 179916647,
                "axBytes": 627521061,
                "axAirtime": 15622912,
                "nonAxAirtime": 7734844,
                "nonAxATE": 23.260539837648956,
                "timestamp": "2020-05-05T14:20:00.000+0000"
            },
            {
                "modificationtime": 1588688700000,
                "axATE": 39.370695750312166,
                "nonAxBytes": 342303186,
                "axBytes": 413176239,
                "axAirtime": 10494512,
                "nonAxAirtime": 9939776,
                "nonAxATE": 34.437716302661144,
                "timestamp": "2020-05-05T14:25:00.000+0000"
            },
            {
                "modificationtime": 1588689000000,
                "axATE": 39.24204733771756,
                "nonAxBytes": 167476586,
                "axBytes": 215581367,
                "axAirtime": 5493632,
                "nonAxAirtime": 5147600,
                "nonAxATE": 32.53488732613257,
                "timestamp": "2020-05-05T14:30:00.000+0000"
            },
            {
                "modificationtime": 1588689300000,
                "axATE": 38.646260910228044,
                "nonAxBytes": 324772979,
                "axBytes": 400658772,
                "axAirtime": 10367336,
                "nonAxAirtime": 9613388,
                "nonAxATE": 33.78340487245496,
                "timestamp": "2020-05-05T14:35:00.000+0000"
            },
            {
                "modificationtime": 1588689600000,
                "axATE": 41.33208417192402,
                "nonAxBytes": 146748550,
                "axBytes": 201339659,
                "axAirtime": 4871268,
                "nonAxAirtime": 4426724,
                "nonAxATE": 33.15059850128447,
                "timestamp": "2020-05-05T14:40:00.000+0000"
            },
            {
                "modificationtime": 1588689900000,
                "axATE": 39.94307688314974,
                "nonAxBytes": 136669197,
                "axBytes": 197001492,
                "axAirtime": 4932056,
                "nonAxAirtime": 4288700,
                "nonAxATE": 31.86727842936088,
                "timestamp": "2020-05-05T14:45:00.000+0000"
            },
            {
                "modificationtime": 1588690200000,
                "axATE": 41.204211716861565,
                "nonAxBytes": 140574470,
                "axBytes": 201828118,
                "axAirtime": 4898240,
                "nonAxAirtime": 4281948,
                "nonAxATE": 32.82956028424446,
                "timestamp": "2020-05-05T14:50:00.000+0000"
            },
            {
                "modificationtime": 1588690500000,
                "axATE": 40.48969059229719,
                "nonAxBytes": 344696566,
                "axBytes": 435644615,
                "axAirtime": 10759396,
                "nonAxAirtime": 9814392,
                "nonAxATE": 35.12154048870271,
                "timestamp": "2020-05-05T14:55:00.000+0000"
            },
            {
                "modificationtime": 1588690800000,
                "axATE": 38.68513820769376,
                "nonAxBytes": 340255698,
                "axBytes": 433182251,
                "axAirtime": 11197640,
                "nonAxAirtime": 10040256,
                "nonAxATE": 33.889145655250225,
                "timestamp": "2020-05-05T15:00:00.000+0000"
            },
            {
                "modificationtime": 1588691100000,
                "axATE": 38.73244192943068,
                "nonAxBytes": 159718197,
                "axBytes": 200877134,
                "axAirtime": 5186276,
                "nonAxAirtime": 4741812,
                "nonAxATE": 33.68294588650921,
                "timestamp": "2020-05-05T15:05:00.000+0000"
            },
            {
                "modificationtime": 1588691400000,
                "axATE": 41.15597673708464,
                "nonAxBytes": 183250521,
                "axBytes": 53372223,
                "axAirtime": 1296828,
                "nonAxAirtime": 7837964,
                "nonAxATE": 23.37986255104004,
                "timestamp": "2020-05-05T15:10:00.000+0000"
            },
            {
                "modificationtime": 1588691700000,
                "axATE": 39.6440155923456,
                "nonAxBytes": 303875063,
                "axBytes": 405340396,
                "axAirtime": 10224504,
                "nonAxAirtime": 9043460,
                "nonAxATE": 33.601637315806116,
                "timestamp": "2020-05-05T15:15:00.000+0000"
            },
            {
                "modificationtime": 1588692000000,
                "axATE": 38.22094441572508,
                "nonAxBytes": 158607693,
                "axBytes": 186317931,
                "axAirtime": 4874760,
                "nonAxAirtime": 4647468,
                "nonAxATE": 34.12776440849082,
                "timestamp": "2020-05-05T15:20:00.000+0000"
            },
            {
                "modificationtime": 1588692300000,
                "axATE": 40.53220065084354,
                "nonAxBytes": 143719457,
                "axBytes": 189768683,
                "axAirtime": 4681924,
                "nonAxAirtime": 4238552,
                "nonAxATE": 33.90767814102552,
                "timestamp": "2020-05-05T15:25:00.000+0000"
            },
            {
                "modificationtime": 1588692600000,
                "axATE": 38.839630784851316,
                "nonAxBytes": 309616792,
                "axBytes": 391098614,
                "axAirtime": 10069576,
                "nonAxAirtime": 9066336,
                "nonAxATE": 34.15015635864367,
                "timestamp": "2020-05-05T15:30:00.000+0000"
            },
            {
                "modificationtime": 1588692900000,
                "axATE": 38.65309226630845,
                "nonAxBytes": 292466278,
                "axBytes": 374295209,
                "axAirtime": 9683448,
                "nonAxAirtime": 8701012,
                "nonAxATE": 33.61290364844917,
                "timestamp": "2020-05-05T15:35:00.000+0000"
            },
            {
                "modificationtime": 1588693200000,
                "axATE": 38.87759929553071,
                "nonAxBytes": 305132681,
                "axBytes": 372623128,
                "axAirtime": 9584520,
                "nonAxAirtime": 9005624,
                "nonAxATE": 33.88245845040832,
                "timestamp": "2020-05-05T15:40:00.000+0000"
            },
            {
                "modificationtime": 1588693500000,
                "axATE": 38.93551799014686,
                "nonAxBytes": 124012815,
                "axBytes": 233459702,
                "axAirtime": 5996060,
                "nonAxAirtime": 4102052,
                "nonAxATE": 30.231897352837066,
                "timestamp": "2020-05-05T15:45:00.000+0000"
            },
            {
                "modificationtime": 1588693800000,
                "axATE": 38.449851729969794,
                "nonAxBytes": 158491090,
                "axBytes": 201338496,
                "axAirtime": 5236392,
                "nonAxAirtime": 4673848,
                "nonAxATE": 33.9101934851112,
                "timestamp": "2020-05-05T15:50:00.000+0000"
            },
            {
                "modificationtime": 1588694100000,
                "axATE": 38.489975604785656,
                "nonAxBytes": 622148236,
                "axBytes": 778154916,
                "axAirtime": 20217080,
                "nonAxAirtime": 18552908,
                "nonAxATE": 33.53373153146666,
                "timestamp": "2020-05-05T15:55:00.000+0000"
            },
            {
                "modificationtime": 1588694400000,
                "axATE": 40.89925718067903,
                "nonAxBytes": 160892991,
                "axBytes": 206880549,
                "axAirtime": 5058296,
                "nonAxAirtime": 4697556,
                "nonAxATE": 34.250361464557315,
                "timestamp": "2020-05-05T16:00:00.000+0000"
            },
            {
                "modificationtime": 1588694700000,
                "axATE": 40.45297796644398,
                "nonAxBytes": 151984685,
                "axBytes": 198710853,
                "axAirtime": 4912144,
                "nonAxAirtime": 4634548,
                "nonAxATE": 32.793852820167146,
                "timestamp": "2020-05-05T16:05:00.000+0000"
            },
            {
                "modificationtime": 1588695000000,
                "axATE": 44.98594120253218,
                "nonAxBytes": 153818382,
                "axBytes": 138700384,
                "axAirtime": 3083194,
                "nonAxAirtime": 5665912,
                "nonAxATE": 27.148035832536756,
                "timestamp": "2020-05-05T16:10:00.000+0000"
            },
            {
                "modificationtime": 1588695300000,
                "axATE": 40.99514617002478,
                "nonAxBytes": 157715785,
                "axBytes": 209729528,
                "axAirtime": 5115960,
                "nonAxAirtime": 4553624,
                "nonAxATE": 34.63522350549804,
                "timestamp": "2020-05-05T16:15:00.000+0000"
            },
            {
                "modificationtime": 1588695600000,
                "axATE": 39.74743604955696,
                "nonAxBytes": 313990312,
                "axBytes": 407341582,
                "axAirtime": 10248248,
                "nonAxAirtime": 9389420,
                "nonAxATE": 33.44086343991429,
                "timestamp": "2020-05-05T16:20:00.000+0000"
            },
            {
                "modificationtime": 1588695900000,
                "axATE": 39.60190313051327,
                "nonAxBytes": 149772706,
                "axBytes": 193043437,
                "axAirtime": 4874600,
                "nonAxAirtime": 4403432,
                "nonAxATE": 34.01272144091245,
                "timestamp": "2020-05-05T16:25:00.000+0000"
            },
            {
                "modificationtime": 1588696200000,
                "axATE": 42.00892424557032,
                "nonAxBytes": 144316676,
                "axBytes": 203933499,
                "axAirtime": 4854528,
                "nonAxAirtime": 4367612,
                "nonAxATE": 33.042467142227835,
                "timestamp": "2020-05-05T16:30:00.000+0000"
            },
            {
                "modificationtime": 1588696500000,
                "axATE": 40.0601676167984,
                "nonAxBytes": 299725896,
                "axBytes": 380504772,
                "axAirtime": 9498332,
                "nonAxAirtime": 9088356,
                "nonAxATE": 32.97911041336849,
                "timestamp": "2020-05-05T16:35:00.000+0000"
            },
            {
                "modificationtime": 1588696800000,
                "axATE": 30.99388939478525,
                "nonAxBytes": 156444770,
                "axBytes": 108371498,
                "axAirtime": 3496544,
                "nonAxAirtime": 4865760,
                "nonAxATE": 32.15217561079872,
                "timestamp": "2020-05-05T16:40:00.000+0000"
            },
            {
                "modificationtime": 1588697100000,
                "axATE": 39.62751932617233,
                "nonAxBytes": 169282538,
                "axBytes": 196885050,
                "axAirtime": 4968392,
                "nonAxAirtime": 5188460,
                "nonAxATE": 32.62674049718028,
                "timestamp": "2020-05-05T16:45:00.000+0000"
            },
            {
                "modificationtime": 1588697400000,
                "axATE": 40.308170303065715,
                "nonAxBytes": 141137091,
                "axBytes": 223532828,
                "axAirtime": 5545596,
                "nonAxAirtime": 4200064,
                "nonAxATE": 33.60355723150885,
                "timestamp": "2020-05-05T16:50:00.000+0000"
            },
            {
                "modificationtime": 1588697700000,
                "axATE": 41.05971513864439,
                "nonAxBytes": 173409817,
                "axBytes": 225128283,
                "axAirtime": 5482948,
                "nonAxAirtime": 5039856,
                "nonAxATE": 34.40769279915934,
                "timestamp": "2020-05-05T16:55:00.000+0000"
            },
            {
                "modificationtime": 1588698000000,
                "axATE": 38.79479649252558,
                "nonAxBytes": 330805277,
                "axBytes": 423417737,
                "axAirtime": 10914292,
                "nonAxAirtime": 9835580,
                "nonAxATE": 33.63353020360772,
                "timestamp": "2020-05-05T17:00:00.000+0000"
            },
            {
                "modificationtime": 1588698300000,
                "axATE": 39.3929666780734,
                "nonAxBytes": 528886718,
                "axBytes": 626231567,
                "axAirtime": 15897040,
                "nonAxAirtime": 15331972,
                "nonAxATE": 34.49567465946325,
                "timestamp": "2020-05-05T17:05:00.000+0000"
            },
            {
                "modificationtime": 1588698600000,
                "axATE": 40.348111069999504,
                "nonAxBytes": 166082418,
                "axBytes": 211552409,
                "axAirtime": 5243180,
                "nonAxAirtime": 4743584,
                "nonAxATE": 35.01201159292214,
                "timestamp": "2020-05-05T17:10:00.000+0000"
            },
            {
                "modificationtime": 1588698900000,
                "axATE": 39.213396618284875,
                "nonAxBytes": 321898955,
                "axBytes": 427835411,
                "axAirtime": 10910440,
                "nonAxAirtime": 9509912,
                "nonAxATE": 33.84878377423471,
                "timestamp": "2020-05-05T17:15:00.000+0000"
            },
            {
                "modificationtime": 1588699200000,
                "axATE": 39.86395532009122,
                "nonAxBytes": 150453245,
                "axBytes": 202346567,
                "axAirtime": 5075928,
                "nonAxAirtime": 4508812,
                "nonAxATE": 33.3687110928555,
                "timestamp": "2020-05-05T17:20:00.000+0000"
            },
            {
                "modificationtime": 1588699500000,
                "axATE": 42.27206760571616,
                "nonAxBytes": 146775595,
                "axBytes": 196641035,
                "axAirtime": 4651796,
                "nonAxAirtime": 4531620,
                "nonAxATE": 32.38921070169167,
                "timestamp": "2020-05-05T17:25:00.000+0000"
            },
            {
                "modificationtime": 1588699800000,
                "axATE": 40.64138904404555,
                "nonAxBytes": 142943520,
                "axBytes": 192669771,
                "axAirtime": 4740728,
                "nonAxAirtime": 4241104,
                "nonAxATE": 33.704318498202355,
                "timestamp": "2020-05-05T17:30:00.000+0000"
            },
            {
                "modificationtime": 1588700100000,
                "axATE": 42.17849247602996,
                "nonAxBytes": 321450192,
                "axBytes": 366478967,
                "axAirtime": 8688764,
                "nonAxAirtime": 10077312,
                "nonAxATE": 31.898406241664443,
                "timestamp": "2020-05-05T17:35:00.000+0000"
            },
            {
                "modificationtime": 1588700400000,
                "axATE": 41.21540779818034,
                "nonAxBytes": 161869966,
                "axBytes": 213943082,
                "axAirtime": 5190852,
                "nonAxAirtime": 4622268,
                "nonAxATE": 35.01959773859932,
                "timestamp": "2020-05-05T17:40:00.000+0000"
            },
            {
                "modificationtime": 1588700700000,
                "axATE": 37.72198223749169,
                "nonAxBytes": 313459871,
                "axBytes": 359429532,
                "axAirtime": 9528384,
                "nonAxAirtime": 9645880,
                "nonAxATE": 32.4967624519484,
                "timestamp": "2020-05-05T17:45:00.000+0000"
            },
            {
                "modificationtime": 1588701000000,
                "axATE": 40.099265735839815,
                "nonAxBytes": 444174153,
                "axBytes": 559374652,
                "axAirtime": 13949748,
                "nonAxAirtime": 13208472,
                "nonAxATE": 33.62797400032343,
                "timestamp": "2020-05-05T17:50:00.000+0000"
            },
            {
                "modificationtime": 1588701300000,
                "axATE": 40.85692992087383,
                "nonAxBytes": 147171189,
                "axBytes": 201686966,
                "axAirtime": 4936420,
                "nonAxAirtime": 4483484,
                "nonAxATE": 32.825184387855515,
                "timestamp": "2020-05-05T17:55:00.000+0000"
            },
            {
                "modificationtime": 1588701600000,
                "axATE": 40.83518699950159,
                "nonAxBytes": 147258794,
                "axBytes": 191061143,
                "axAirtime": 4678836,
                "nonAxAirtime": 4254952,
                "nonAxATE": 34.608802637491564,
                "timestamp": "2020-05-05T18:00:00.000+0000"
            },
            {
                "modificationtime": 1588701900000,
                "axATE": 41.47701060684925,
                "nonAxBytes": 154622194,
                "axBytes": 199852662,
                "axAirtime": 4818396,
                "nonAxAirtime": 4488316,
                "nonAxATE": 34.449934897631984,
                "timestamp": "2020-05-05T18:05:00.000+0000"
            },
            {
                "modificationtime": 1588702200000,
                "axATE": 42.62989054237765,
                "nonAxBytes": 408257122,
                "axBytes": 492758649,
                "axAirtime": 11558994,
                "nonAxAirtime": 13911012,
                "nonAxATE": 29.347765784401595,
                "timestamp": "2020-05-05T18:10:00.000+0000"
            },
            {
                "modificationtime": 1588702500000,
                "axATE": 30.48798186552552,
                "nonAxBytes": 147339341,
                "axBytes": 178625915,
                "axAirtime": 5858896,
                "nonAxAirtime": 4227972,
                "nonAxATE": 34.84870311345487,
                "timestamp": "2020-05-05T18:15:00.000+0000"
            },
            {
                "modificationtime": 1588702800000,
                "axATE": 39.440951354082905,
                "nonAxBytes": 158420761,
                "axBytes": 198555846,
                "axAirtime": 5034256,
                "nonAxAirtime": 4601780,
                "nonAxATE": 34.42597451420972,
                "timestamp": "2020-05-05T18:20:00.000+0000"
            },
            {
                "modificationtime": 1588703100000,
                "axATE": 38.79638724975698,
                "nonAxBytes": 158583026,
                "axBytes": 188220052,
                "axAirtime": 4851484,
                "nonAxAirtime": 4607984,
                "nonAxATE": 34.41483867999542,
                "timestamp": "2020-05-05T18:25:00.000+0000"
            }
        ],
        "insights": 12.73
    }
})