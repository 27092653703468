define({
"Template":
{
  "request": {
    "entity": {
      "intfName": "TenGigabitEthernet1/0/23",
      "managementIpAddr": "10.32.255.100",
      "rootCause": "EIGRP_PEERING",
      "type": "event"
    },
    "dimensions": [

    ],
    "timeRange": {
      "start": "2018-09-30T17:14:40.165Z",
      "end": "2018-10-03T10:11:52.976Z"
    },
    "values": [

    ]
  },
  "pagination": {
    "links": [
      {
        "rel": "self",
        "url": "http://queryengine.ndp.svc.cluster.local:8006/api/ndp/v1/data/triggers?page=1&pageSize=300&order=DESC",
        "method": "POST",
        "body": "{\"entity\":{\"intfName\":\"TenGigabitEthernet1/0/23\",\"managementIpAddr\":\"10.32.255.100\",\"rootCause\":\"EIGRP_PEERING\",\"type\":\"event\"},\"dimensions\":[],\"timeRange\":{\"start\":\"2018-09-30T17:14:40.165Z\",\"end\":\"2018-10-03T10:11:52.976Z\"},\"values\":[]}"
      },
      {
        "rel": "first",
        "url": "http://queryengine.ndp.svc.cluster.local:8006/api/ndp/v1/data/triggers?page=1&pageSize=300&order=DESC",
        "method": "POST",
        "body": "{\"entity\":{\"intfName\":\"TenGigabitEthernet1/0/23\",\"managementIpAddr\":\"10.32.255.100\",\"rootCause\":\"EIGRP_PEERING\",\"type\":\"event\"},\"dimensions\":[],\"timeRange\":{\"start\":\"2018-09-30T17:14:40.165Z\",\"end\":\"2018-10-03T10:11:52.976Z\"},\"values\":[]}"
      },
      {
        "rel": "last",
        "url": "http://queryengine.ndp.svc.cluster.local:8006/api/ndp/v1/data/triggers?page=1&pageSize=300&order=DESC",
        "method": "POST",
        "body": "{\"entity\":{\"intfName\":\"TenGigabitEthernet1/0/23\",\"managementIpAddr\":\"10.32.255.100\",\"rootCause\":\"EIGRP_PEERING\",\"type\":\"event\"},\"dimensions\":[],\"timeRange\":{\"start\":\"2018-09-30T17:14:40.165Z\",\"end\":\"2018-10-03T10:11:52.976Z\"},\"values\":[]}"
      }
    ],
    "page": 1,
    "pageSize": 300,
    "order": "DESC",
    "totalResults": 1
  },
  "warnings": null,
  "values": [
    {
      "name": "network_connectivity_trigger",
      "severity": "HIGH",
      "id": "AWYwoD-gWD9O_0nZZoWJ",
      "time": "2018-10-01T17:14:40.165+0000",
      "endTime": "2018-10-01T17:14:40.165+0000",
      "count": 1,
      "attributes": [
        {
          "key": "managementIpAddr",
          "value": "10.32.255.100"
        },
        {
          "key": "remoteIP",
          "value": "10.32.254.1"
        },
        {
          "key": "type",
          "value": "event"
        },
        {
          "key": "scope",
          "value": "global"
        },
        {
          "key": "rootCause",
          "value": "EIGRP_PEERING"
        },
        {
          "key": "syslogMsg",
          "value": "EIGRP-IPv4 10: Neighbor 10.32.254.1 (TenGigabitEthernet1/0/23) is down: interface down"
        },
        {
          "key": "entityType",
          "value": "network_device"
        },
        {
          "key": "__entity_type__",
          "value": "NetworkDevice"
        },
        {
          "key": "entityId",
          "value": "10.32.255.100"
        },
        {
          "key": "priority",
          "value": "P1"
        },
        {
          "key": "failureReason",
          "value": "NA"
        },
        {
          "key": "eventStatus",
          "value": "Down"
        },
        {
          "key": "siteId",
          "value": ""
        },
        {
          "key": "category",
          "value": "connectivity"
        },
        {
          "key": "intfName",
          "value": "TenGigabitEthernet1/0/23"
        },
        {
          "key": "status",
          "value": "active"
        }
      ],
      "source": "internal",
      "isActive": false
    }
  ]
}
})