define({

    "AP_RENAME":
        {'aes:eHmFaK7A298PEWZ2nD1C-r6jJ3gcbQ7kXW4HPs_05YQ=':'AP4800',
        'aes:HzBXpJfOYUdvKw1_97xOsklhD_F10pf8wWXVrbOkFQk=':'AP9136_1',
        'aes:dG0asPZOfnBhZdg8buRp3xvFG-u18kqCl613':'LAB-AP00F2.8B27.B788',
        'aes:iOKQQxkieIOcZ0ed1m-sfn0typds2t0enbzn1K6dx_I=':'SJC01_9136_1',
        'aes:fBkaeeM6K2nx3dxSHVtnNNlrGkF3TV4m8hV5XZTE_bw=':'SJC01_9136_11',
        'aes:cvEUxoujNpncCifjTlIJQqmemEefcRc3-zEM40bp8UY=':'AP9120_3',
        'aes:VggaQw8XgI-F_Dnf4K3d_F_f9qBILIhpnk35lILiYqk=':'SJC01_9136_10',
        'aes:ZUepECVTizXPipfNVV6hFBoGSxAcA84EazOe':'SJC01_9136_9',
        'aes:7pGayJL4hfrwTptYblVvxHVeItcLGX73o5m8IYhHVME=':'SFO15-C9124-03',
        'aes:x1S208peew0GtlAJSKmxgUPknrw83FEY-g==':'SFO15-C9136-04',
        'aes:YZ8-Fb5IwMTQUB1YRy5PZ1rXxSz1zQLUE0U4':'SFO15-C9120-04',
        'aes:-H1xx5RQAzQiJYJyLOx6OZAwsfScnfbC7Bc7c1Zk4G1OdQtHTa8=':'SFO10-AP3800-01',
        'aes:WQxxc9x0EoNRW5fWC846jAtZ3be12jq0RC96ATUGeiU=':'SFO10-AP3800-02',
        'aes:QRVsoX6BqqhGMDdozqXyPFP94zcp5OKSoZUY':'SJC01_9136_12',
        'aes:IBavb93h7uUd04a5154jAQvbFB1P-HkrU4iNvuycbOc=':'SJC01_9136_13',
        'aes:IN_tPEM16TY6EwShOtRT07wjtvBXe69AoTHKEv4vEf8=':'SFO11-AP4800-01',
        'aes:58lwJ1hQOWHiNV0_e-4C3OFS1IaRkWSLIBGbFGRNoC0=':'SFO11-AP4800-02',
        'aes:916o6tVuUW8gVoydE6ylFvDCEdFx4myzWprT36YVOgJd':'PLS06-AP3800-01',
        'aes:GcZTiH5KBdJS-aCjUH5rMk9s4jahCFdZNKs6RD_y57k=':'AP70B3.1733.D6D4',
        'aes:kfA5mVapVvK9n5dh3KOkroCNKjp8PE0sjSfusUOOAkU=':'APF4DB.E6CE.4F24',
        'aes:FuuWQvbhE-BMxMc6CTR-qQE1MORdy0NsDaaCymwzj5k=':'AP4800_1',
        'aes:AFBFVbbmqOeXFw0tQ3-eYu2xPBWVm1gLDQ==':'PLS06-9120-01',
        'aes:gdEo_mcoMr5VIK469X_GgRdGG3SGr-y01p7FYgaLDZ0=':'AP0081.C424.3CE2',
        'aes:jIFfyl3OdBUDAX2qftaFMM6E1KGxtzWs2VOoih3NAx8=':'SJC01_9136_12',
        'aes:M3Xu8bBQsjqVCIsUb4Ia9DLbAxJKOdVMQ70CNNmuUJk=':'SFO15-C9120-01',
        'aes:IDFDUCgIUFKwhf-1kOADcwEP8ZTO84QzpgpBBzMhEFs=':'SFO15-C9120-02',
        'aes:7lceT9hHJpggI_SXwFAu6MLRJ4suLgfrXpcIjvwF0Rg=':'SFO15-C9120-03',
        'aes:XFQtgxLuW6Dmz-Tzi9U7PVXoNxFXg5LOWrXNttZVygc=':'SFO15-C9124-01',
        'aes:FLOnCQERzOcP1WCgBANESdUBrzaJRgsOvz2hidhnhRc=':'SFO15-C9124-02',
        'aes:xE5zC0qyENWowqNZzmEZjHJSyHxfLdZcZYNA_a70U7g=':'SFO15-C9136-01',
        'aes:o2QqV__fvSjXR6Ag6eOm4wSa9rRkJHo0m3c8cjWIT-E=':'SFO15-C9136-02',
        'aes:auCXDe6WykVUiam_pY8OEMT38o-FpX563o4G':'SFO15-C9136-03',
        'aes:8BJtQlrncEK_O8OVfAAj87ChQ0Vr8CDOYV-1':'SJC01_9136_14',
        'aes:eb5WY70h1BwHUKSLTRRV7MBuP4UcAaLb0Oia1Ia55jpW3A==':'SJC01_9136_15',
        'aes:SvhqyuugnMzGfeoOyXx33RfYqJ6NVuDLMyyDjV6PQnk=':'SJC01_9136_16',
        'aes:qEcPyKWkA7io3LmgLNONa07ZUJ4XhhgDVrpH7bCb7Vs=':'SJC01_9136_17',
        'aes:gfKtK_5dAiPxuGI3XM8xL5ErPf86xDL8Hf3u':'SCJ01_9136_2',
        'aes:5B74w_9iSCly71B--dQi4Ino2YGv4SlRe_Ds84k=':'SCJ01_9136_3',
        'aes:adgdbxEbbrJ_If364qrCV9HYUHQC0Be7_6GpvgdY46U=':'SCJ01_9136_4',
        'aes:W9kg1hCB01ggRS9SmCisojsa9QDl58CzkoFqaZ9SWEE=':'SCJ01_9136_5',
        'aes:GEE-SbbXxmNW0pBtOwqPMxSlkT2uy2899hyY7oXp_NUpqiZivGU=':'SCJ01_9136_6',
        'aes:l6UpAImiO3e2IFz-0te8gzYAyXYwtW9VHI2llnb6J4Q=':'SCJ01_9136_7',
        'aes:5kqVM9XF3d6swytAYE93aFNBtBIeYlZVcHEF7owDCEA=':'SCJ01_9136_8',
        'aes:cPr46Q_mR_caBKv2U6b3B_Efrrn47evYfDWQNmMq8YI=':'SCJ01_9136_2',
        'aes:394MMMNI_6N1Wz_oDxrAUUjUQbeSd1GQg1m1Wvnc_1c=':'SCJ01_9136_3',
        'aes:em92DLDcsQ9nYwq9Nwuf8Y1q7Bafi-HY9DtJPoA-PE4=':'SJC01_9136_15',
        'aes:wlpgT-36ekBld8h6IbD4TxCZsx8a1mV2Yl4BTE53nQo=':'SFO15-C9136-02',
        'aes:j8IICjiGZbDyz3jHf2u253b1kPvFUCUSs3jq83MlsVI=':'SFO15-C9136-01',
        'aes:Q9XBblPduHIRRzDRSJNDcxeX-euO3AaqD0A9kK_sx7g=':'SJC01_9136_14',
        'aes:sYz2wR8FbimRXOzcDayAxF7nxJy-uzAtk80McJEeg34=':'SJC01_9136_15',
        'aes:RnQk7-0O3oUmP2gkRZ3u7iUCyzfHyaEbP78p':'PLS06-AP3800-01',
        'aes:-SjlfMY2McDv_859raoWYS1ZI7NJv-gqaewQIr4=':'SFO15-C9124-02',
        'aes:S_QaPpLlK3QaNalX_-zO5cb7-u4zh5Md4S77au02MxE=':'PLS06-9120-01',
        'aes:1ozolT-YxWcbmSQL8lu0hEerUYl7Fw8EHPMuWxPZF24=':'SJC01_9136_14',
        'aes:5-lDGvs-_GgGqmpE-59z7zh6Fw_1M4IziVtg':'AP4800',
        'aes:ePfTmM6bfkcaSVImlNVTpjr2o-aZVhy37hQC':'AP70B3.1733.D6D4',
        'aes:O72HesEx2S9EliY3qbYWTYpOmE4lum2Zy25Cxn4cfsw=':'APF4DB.E6CE.4F24',
        'aes:z-pAus82OoCLI2zjYWMm65KY-0yK96P9-3_J':'AP0081.C424.3CE2',
        'aes:BY7yp-glVO7emTRZMVVtviXyhbkv4aAeMgfhXutXjuw=':'SFO11-AP4800-02',
        'aes:AB1rBbWTBSHr5qqV5maykyMcaiJoIZYB4zH3nONxHlMRNw==':'SCJ01_9136_8',
        'aes:xSTS481-ELz1Co3EuFdH0sMvvN9bc1aSDpA_gXV7R_s=':'SCJ01_9136_2',
        'aes:6tAnqMn3PXKnhYg56rFIhrpfjNao6yCI3Q-fnSg4Ykk=':'SJC01_9136_15',
        'aes:1nzVeJxD2pcpMCpsEtUJPIZ89xVpIsYkMbSy':'PLS06-9120-01',
        'aes:8wMO6_VjmFB5qa55KLgrzucb8uyMy8lQFYpWUP6drYU=':'SCJ01_9136_5',
        'aes:9j_jH_R5N8pb4mttmDa5DizXTYxMlT56apN7':'SCJ01_9136_3',
        'aes:7r1SgQBDTL7OYpftYmJsLnL50DiSijcrC_8eyCtbQTg=':'SFO15-C9136-03',
        'aes:xgivuYyYjiIiA6FyIYLSSPecnbii4lt0P_3TnFTwBjY=':'AP0081.C424.3CE2',
        'aes:5dV2QvTpm3a2r_H_kdiMQs97rDY5JqEC232WsaAlXdo=':'AP4800_1',
        'aes:2rH90Eo05dQsflWMVFDqFjNd4a6xuWVokOLpmRFw0wBidw==':'AP9136_1',
        'aes:Ftuk2oUoNC1u3Achy6GYCfKiU8OGfgFM_w_hYQH5CAs=':'SJC01_9136_12',
        'aes:TQBSN_Rq1vGOaZ5DZkNEAMTKzfol_YYDVH3I1NSUzPU=':'SFO11-AP4800-01',
        'aes:uD9_bEhNN43o-7koIq9NpPcn7NLzxveIEazcZ21eqgk=':'SFO11-AP4800-02',
        'aes:THRrp0_8-o9nRQfyNOO_qAN5hUDLqzdKzbfUBZvRqg==':'SCJ01_9136_5',
        'aes:YSjpW1NYYOnDu2CpvGnQKKSdjx4hQqWoEd2AoqOqWcvTF4ixLkhMvg==':'AP4800',
        'aes:ZVgB4WM0qPovF1DHFedlrr3klOxLHLxmppTg94BcBPE=':'SCJ01_9136_5',
        'aes:OEiX9Fdh8W4H7UuZc29ltQHSlmYS4Q7w-fveLwiY0GfKuNnbyg==':'SFO11-AP4800-02',
        'aes:LXsgO_lsUyO_HpMqOCTbK7gK33SLkuldTewI':'SFO11-AP4800-01',
        'aes:U3dA-99DxETn4ggnyx75kCRH7TIxD6eFsSa4':'SJC01_9136_12',
        'aes:5he595gwEjSrsuC0gQRUA4dPPN9wvOI0vv286VG0U3Z4':'AP9136_1',
        'aes:5he595gwEjSrsuC0gQRUA4dPPN9wvOI0vv286VG0U3Z4':'AP4800_1',
        'aes:BALmaamvpKhH7KjMXbEMFCznlWj_Sss9IxkTXN4UIy24':'AP0081.C424.3CE2',
        'aes:wY6syrZGmWvwvJZwoZj368gTSxLSczdt4A0S_DrEzA==':'SFO11-AP4800-01',
        'aes:dHGeFOe40cu9Ia1OqH1z-sbqgKC6RTawa4-ngo9aimnOtNOegg==':'SCJ01_9136_3',
        'aes:DQ-2HjWXQebYY85Pi7ds6XVfKZfiSmtmNs1N':'PLS06-9120-01',
        'aes:jRUzkEgwn7SVXV53b_Npb368s0zGl54U_Wddc4CW91fzukJXOg==':'AP0081.C424.3CE2',
        'aes:tmuyVuwrWso9nZfMKTPl7A-Y48GvYKuv_IeMmIllaWBg':'SCJ01_9136_8',
        'aes:wrwM3mNw67QEdLhQ001sPTQEivS_-tCIP8QErQKT0w==':'SCJ01_9136_2',
        'aes:ovYxzrERvS8KR-kC7UYcVUIB-g_6dMyhmq628llTED_qbIzFRA==':'AP70B3.1733.D6D4',
        'aes:Xnm7GCa4Z7gVOzqKKp_gqw1sfsAUL9WFIQVfhxKmbMo=':'SJC01_9136_17',
        'aes:_NQNZpMKvXvf_tfo_ZepmJdfWwQVr_nKycFZ2hY1D-dP':'SCJ01_9136_5',
        'aes:LfVOfh91YOV_oEf91emjBEQLExS293r5X0PatJ5MAys=':'SFO15-C9136-03',
        'aes:vsVjG6i8jE_SVQU18CuBycqSxtmlLv4qleV75JWHPXA=':'AP4800_1',
        'aes:Ec6GyeKwjjSttl2PjDz70BU9S_S0CbIaAark1dbbew==':'SFO11-AP4800-01',
        'aes:NUfHRjqDJXvuELi-hmv2tEKuhCmBdEViRV6It54O525hbSaL':'SFO11-AP4800-02',
        'aes:r-6E1j46apCmDk_vUkviHC3HQQDz37WhWsGaDt8We8XUDivv':'SJC01_9136_17',
        'aes:jCkBO_xbr-il8a3jya4w5d_hVnNatAdy-zSlC1yEkMY=':'SFO11-AP4800-01',
    },

    "SITE_RENAME":
        {'aes:BDA5qlkDs5YhGDcVAgrJavu7vheG6Zc_':'Flr-SJC1-1','aes:52W2Lr8AZo5mB3oBZIsYLDjPiPhpQ8lY1A==':'Flr-SJC4-1','aes:NWPa-Z60qaLZas4n0aQFW0_iUQXZgpBG':'Flr-SJC5-1',
        'aes:mBeFDFbZlXe4WNMVpwYUEVTwLogCdJCjg0ZY':'Flr-SJC6-1','aes:LMUnetQ2PDbte-4n0reY0G2qK7w_vGrBI8hz':'Flr-SJC22-1','aes:Rr6pCCQgjoGdTqTLnebOiv4IRCOWd8AC7Fg0mQ==':'Flr-SJC23-1',
        'aes:XUAnMHyzHmckFBWFo_sNpL0YgnI5E-thVw==':'Flr-SFO10-1','aes:3KUEln-PxpyyGC3M0ULgy9Uz5WxMX9uT':'Flr-SFO11-1','aes:GEjNAxJs5lXCYMsa32hPVZ5ZS4o1lBsyIIjjsw==':'Flr-SFO12-1',
        'aes:nnAWTzzctgmOx98F03Ozv5aEjoc=':'Flr-SFO15-1','aes:3twwONOpqNNnRhuxK4Ex46VwfXJqTkqYWfSa':'Flr-PLS06-1','aes:VSaQij64dBAvS_LRxk4CUcsJ5-9RjbY=':'Flr-NY-1',
        'aes:bm9qLgrQhNYe4SMBp0Fzl3ccmI81txg=':'Flr-AU-1','aes:P2ZkQPXSy0AZIbJzB_cyx-IAphlZQxHEvKiAwGU=':'Flr-SA-1','aes:dxolu38EWpZlxS0A0dxhVEM24O0r7UE=':'Flr-SE-1',
        'aes:WjBT5bLS-5vD14rNU8T9rxsoIMEd':'Flr-SFO13-1','aes:1GutSgply2Pxxv4M38oBQf3Ad5MHd_m7':'Flr-SJC4-1','aes:VS9ghgsupebMbczFGQ2jMULnxH4LD44ZpQ==':'Flr-PLS06-1',
        'aes:r1SMVUxnf6-Cb3XnCe0EWhuVEKRfBo0p':'Flr-SFO12-1','aes:nQHg9eHkQU0jYrjkJDCVss-Gq1dN6XlQ':'Flr-SJC4-1','aes:fxD3jz29QgLH_epm365rmSgiNFLIDQ==':'Flr-SJC1-1',
        'aes:ZeclmBNFasrgGFnKi1ycovrnXzljlu5deA==':'Flr-SJC4-1','aes:fLZd-7A3RnhJsz4-EKN30mmHCPUKDwRZNw==':'Flr-SFO10-1','aes:0zQNygJuwf9FUkSZ2iVgOHkCMCQWMqLTfKf4-3TO9IWa':'Flr-SJC4-1',
        'aes:KScshoCVWCdLjShnBTKQwxp2UJr68POihhBlfsSY':'Flr-SJC1-1','aes:R4aoklkFqhmSc4aBa-t5ICxNYkezrb66uA==':'Flr-SJC4-1','aes:_KXEJnAjwGITP-2cs0eNWWU4vPmx-ptNEn5udGLo':'Flr-SJC1-1',
        'aes:1rpAc4_COqhmgRkImiicOokdgQ==':'Flr-SJC4-1','aes:HDDOvKO_PhiM7Ok85B97SxzJXA==':'Flr-SJC1-1','aes:s_7kzmx49kco9S9kj_3Ws_kNsQ==':'Flr-SJC4-1'},

    "INSIGHT_LASTDATE_MAPPING": {
        "a56d93781ec583dec614": {
            "old":["2019-10-21T00:00:00", "2019-10-14T00:00:00", "2019-10-07T00:00:00", "2019-09-30T00:00:00", "2019-09-23T00:00:00"],
            "new":[],
            "daysToSubtract" : 14
        },
        "a53cab48b5749f268399": {
            "old":["2019-10-28T00:00:00", "2019-10-21T00:00:00", "2019-10-14T00:00:00", "2019-10-07T00:00:00", "2019-09-30T00:00:00"],
            "new":[],
            "daysToSubtract" : 7
        },
        "a57dfabc02af794ede81": {
            "old":["2019-09-16T00:00:00", "2019-09-09T00:00:00", "2019-09-02T00:00:00", "2019-08-26T00:00:00", "2019-08-19T00:00:00"],
            "new":[],
            "daysToSubtract" : 21
        },
        "a5502c42bdcbd50da26e": {
            "old":["2019-12-30T00:00:00", "2019-12-23T00:00:00", "2019-12-16T00:00:00", "2019-12-09T00:00:00", "2019-12-02T00:00:00"],
            "new":[],
            "daysToSubtract" : 14
        },
        "a556c4d10c12faa3e714": {
            "old":["2020-03-02T00:00:00", "2020-02-24T00:00:00", "2020-02-17T00:00:00", "2020-02-10T00:00:00","2020-02-03T00:00:00"],
            "new":[],
            "daysToSubtract" : 2
        }
    }

});