define({
    "Template": {
        "version": "1.0",
        "response": {
            "id": "64a0f855-41b0-4796-9f19-073066271767",
            "globalParentId": "Unknown",
            "name": "switch_issue_power",
            "enabled": true,
            "flattened": true,
            "entityType": "network_device",
            "entityName": "Unknown",
            "entity": "52e035a6-b776-4e62-b20c-7c46adfab8d4",
            "groupBy": "Unknown",
            "category": "Device",
            "severity": "HIGH",
            "summary": "Power supply failure on device.",
            "groupId": "switch_power_supply_failure_grouping",
            "groupName": "Power supply failure on switch",
            "scope": "GLOBAL",
            "priority": "P2",
            "rootCause": "Unknown",
            "timestamp": 1623187704476,
            "description": "There is a Power Supply/s Failure in Network Device \"DeviceNamePlaceHolder\". ",
            "suggestions": [
                {
                    "message": "Check redundant power status",
                    "steps": [
                        {
                            "entityId": "DeviceIdPlaceHolder",
                            "description": "Check system power status",
                            "command": "show environment power all",
                            "stepType": "command-Runner",
                            "runButton": null
                        }
                    ]
                },
                {
                    "message": "Check device alarms"
                },
                {
                    "message": "Check/Replace power supply"
                },
                {
                    "message": "Contact Cisco TAC World Wide – https://www.cisco.com/c/en/us/support/web/tsd-cisco-worldwide-contacts.html"
                }
            ],
            "additionalParams": [
                {
                    "key": "managementIpAddr",
                    "value": "10.4.15.9"
                },
                {
                    "key": "_name",
                    "value": "switch_issue_power"
                },
                {
                    "key": "_entity_type",
                    "value": "NetworkDevice"
                },
                {
                    "key": "eventSource",
                    "value": "Network Device"
                },
                {
                    "key": "criticality",
                    "value": "Shutdown"
                },
                {
                    "key": "type",
                    "value": "Issue"
                },
                {
                    "key": "_key",
                    "value": "NetworkDevice:52e035a6-b776-4e62-b20c-7c46adfab8d4"
                },
                {
                    "key": "floorId",
                    "value": "55f403b2-95c9-4a8f-8c0d-2494e300ac35"
                },
                {
                    "key": "_count",
                    "value": "1"
                },
                {
                    "key": "areaName",
                    "value": "Global/Milpitas"
                },
                {
                    "key": "additionalDescription",
                    "value": ""
                },
                {
                    "key": "scope",
                    "value": "global"
                },
                {
                    "key": "_primary_key",
                    "value": "NetworkDevice:52e035a6-b776-4e62-b20c-7c46adfab8d4"
                },
                {
                    "key": "floorName",
                    "value": "Global/Milpitas/SJC23/Flr_1"
                },
                {
                    "key": "siteType",
                    "value": "floor"
                },
                {
                    "key": "deviceType",
                    "value": "Cisco Catalyst 9300 Switch"
                },
                {
                    "key": "severity",
                    "value": "HIGH"
                },
                {
                    "key": "deviceUuid",
                    "value": "52e035a6-b776-4e62-b20c-7c46adfab8d4"
                },
                {
                    "key": "deviceRole",
                    "value": "ACCESS"
                },
                {
                    "key": "_suppression",
                    "value": "3600"
                },
                {
                    "key": "trapDescription",
                    "value": "Switch 1 - Power Supply A, Shutdown"
                },
                {
                    "key": "deviceOS",
                    "value": "17.3.3prd3"
                },
                {
                    "key": "entityType",
                    "value": "network_device"
                },
                {
                    "key": "nwDeviceName",
                    "value": "AD1-C9300"
                },
                {
                    "key": "_eventTime",
                    "value": "1623187704476"
                },
                {
                    "key": "_mostRecent",
                    "value": "1623187704476"
                },
                {
                    "key": "entityId",
                    "value": "52e035a6-b776-4e62-b20c-7c46adfab8d4"
                },
                {
                    "key": "eventUniqueId",
                    "value": "52e035a6-b776-4e62-b20c-7c46adfab8d4"
                },
                {
                    "key": "deviceFamily",
                    "value": "Switches and Hubs"
                },
                {
                    "key": "priority",
                    "value": "P2"
                },
                {
                    "key": "buildingId",
                    "value": "a7c562a3-f0c3-48e1-88ca-0948dcf9b385"
                },
                {
                    "key": "buildingName",
                    "value": "Global/Milpitas/SJC23"
                },
                {
                    "key": "areaId",
                    "value": "c37b31ab-03b8-49c7-a742-3d1565d9c969"
                },
                {
                    "key": "siteHierarchy",
                    "value": "Global/Milpitas/SJC23/Flr_1"
                },
                {
                    "key": "deviceModel",
                    "value": "FJC2402U11T"
                },
                {
                    "key": "category",
                    "value": "Device"
                },
                {
                    "key": "timestring",
                    "value": "2021-06-08T21:28:24.476+0000"
                },
                {
                    "key": "status",
                    "value": "active"
                }
            ],
            "status": {
                "status": "ACTIVE",
                "updatedBy": "Unknown",
                "notes": "Unknown",
                "source": "Unknown",
                "updatedAt": null,
                "ignoreSource": "Unknown",
                "ignoreValue": "Unknown",
                "ignoreStartTime": null,
                "ignoreEndTime": null
            },
            "siteQulaifiedName": null
        },
        "poorRFImpactedInfo": null
    }
});