define([
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/database/SimLokiDatabaseActions',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/utilities/UtilityFunctions',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/assurance/pathtrace/PathTraceStart',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/assurance/pathtrace/PathTraceCompleted',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/assurance/device/AssuranceNetworkDeviceDetailTemplate',
], function (SimLokiDatabaseActions, UtilityFunctions, PathTraceStart, PathTraceCompleted, AssuranceNetworkDeviceDetailTemplate) {

        return {

            init: function () {
            },

            startPathTrace: function (urlAction) {
                var sourceIp = urlAction.restPayload.sourceIP, i, t = {};
                var destIp = urlAction.restPayload.destIP;

                var sourceDevice = SimLokiDatabaseActions.getFilteredRecordHandler('network-device', { 'managementIpAddress': sourceIp })
                var destDevice = SimLokiDatabaseActions.getFilteredRecordHandler('network-device', { 'managementIpAddress': destIp })

                if(sourceDevice.length == 0 && destDevice.length == 0) {
                    var sourceDevice = SimLokiDatabaseActions.getFilteredRecordHandler('host', { 'hostIpV4': sourceIp })
                    var destDevice = SimLokiDatabaseActions.getFilteredRecordHandler('host', { 'hostIpV4': destIp })
                }

                var objs = SimLokiDatabaseActions.getAll('pathtrace');
                if (objs && objs.length) {
                    for (i = 0; i < objs.length; i++) {
                        SimLokiDatabaseActions.delete('pathtrace', objs[i]);
                    }
                }

                if (sourceDevice.length > 0 && sourceDevice[0].name === "AP4800" && destDevice.length > 0 && destDevice[0].name === "WAN_EDGE1") {
                    t = JSON.parse(JSON.stringify(PathTraceCompleted.PathTrace_Completed_Template));
                    t.response.request.sourceIP = sourceIp;
                    t.response.request.destIP = destIp;                                        

                    //check for wired
                    //  var hostObj = SimLokiDatabaseActions.getFilteredRecordHandler('host',{'hostIpV4':sourceIp});
                    //  if(hostObj && hostObj.length>0 && hostObj[0].hostType=='WIRED') {
                    //     t.response.networkElementsInfo = PathTraceCompleted.Wired_NetworkElementsInfo;
                    //  }

                    //var pathTraceDevices = ["AP4800", "BLD1-FLR2-ACCESS", "BLD1-FLR2-DST1", "CAMPUS-CORE2", "WAN_EDGE2"]
                    var pathTraceDeviceNames = [];
                    var topologyData = SimLokiDatabaseActions.getAll('physical-topology');
                    var targetdevice = SimLokiDatabaseActions.getFilteredRecordHandler('network-device', { 'name': "AP4800" });
                    var targetDeviceId = targetdevice[0].id;
                    pathTraceDeviceNames.push(targetdevice[0].name);
                    var topologyLinksData = topologyData.filter((e) => e.topology_type === "link");

                    while (targetdevice.length > 0 && (targetdevice[0].name != "WAN_EDGE1")) {
                        var sourceNtargetlink = topologyLinksData.filter((e) => e.target === targetDeviceId);
                        var sourceDeviceId = sourceNtargetlink[0].source;
                        targetDeviceId = sourceDeviceId;
                        targetdevice = SimLokiDatabaseActions.getFilteredRecordHandler('network-device', { 'id': sourceDeviceId });
                        pathTraceDeviceNames.push(targetdevice ? targetdevice[0].name : "");
                    }

                    var networkElementsInfo = pathTraceDeviceNames.map(deviceName => {
                        var device = SimLokiDatabaseActions.getFilteredRecordHandler('network-device', { 'name': deviceName });
                        var networkElement = {}
                        networkElement.id = device[0].id;
                        networkElement.name = device[0].name;
                        networkElement.type = device[0].family;
                        networkElement.ip = device[0].managementIpAddress;
                        networkElement.role = device[0].role;
                        networkElement.linkInformationSource = "Switched";
                        return networkElement;
                    });
                    t.response.networkElementsInfo = networkElementsInfo;
                    var tTime = UtilityFunctions.getTimeStamp();
                    t.response.request.createTime = tTime;
                    t.response.request.lastUpdateTime = tTime;
                    t.response.lastUpdate = UtilityFunctions.getCurrentDateAndTimeInValidFormat();
                } else if(sourceDevice.length > 0 && sourceDevice[0].name === "Camera1" && destDevice.length > 0 && destDevice[0].name === "SEP191C5D21F520"){
                    t = JSON.parse(JSON.stringify(PathTraceCompleted.PathTrace_Completed_Template));
                    t.response.request.sourceIP = sourceIp;
                    t.response.request.destIP = destIp;

                    var pathTraceDeviceNames = ["Camera1", "IE-3300_6", "IE4K-ExtNode", "SJC06-C9300-01", "SJC06-C9600-02", "SJC06-C9300-02", "IE3400_1", "IE3400_2", "IE3400_3", "SEP191C5D21F520"]
                    var device = {};
                    var networkElementsInfo = pathTraceDeviceNames.map(function(deviceName, index) {
                        if(index == 0 || index == pathTraceDeviceNames.length-1){
                            device = SimLokiDatabaseActions.getFilteredRecordHandler('host', { 'name': deviceName });
                            var networkElement = {}                            
                            networkElement.ip = device[0].hostIpV4;                                                  
                        } else {
                            device = SimLokiDatabaseActions.getFilteredRecordHandler('network-device', { 'name': deviceName });
                            var networkElement = {}                            
                            networkElement.ip = device[0].managementIpAddress;                                    
                        }
                        networkElement.id = device[0].id;
                        networkElement.name = device[0].name;
                        networkElement.type = device[0].family;                        
                        networkElement.role = device[0].role;
                        networkElement.linkInformationSource = "Switched";
                        return networkElement;                          
                    });
                    t.response.networkElementsInfo = networkElementsInfo;
                    var tTime = UtilityFunctions.getTimeStamp();
                    t.response.request.createTime = tTime;
                    t.response.request.lastUpdateTime = tTime;
                    t.response.lastUpdate = UtilityFunctions.getCurrentDateAndTimeInValidFormat();                    

                }else if(sourceDevice.length > 0 && sourceIp === "10.30.100.27" && destDevice.length > 0 && destDevice[0].name === "Camera1"){
                    t = JSON.parse(JSON.stringify(PathTraceCompleted.PathTrace_Completed_Template));
                    t.response.request.sourceIP = sourceIp;
                    t.response.request.destIP = destIp;
                    var tTime = UtilityFunctions.getTimeStamp();
                    t.response.request.createTime = tTime;
                    t.response.request.lastUpdateTime = tTime;
                    t.response.networkElementsInfo[0].name = sourceIp;
                    t.response.networkElementsInfo[t.response.networkElementsInfo.length-1].name = destIp;
                    t.response.networkElementsInfo[t.response.networkElementsInfo.length-1].type = 'Camera';
                    t.response.lastUpdate = UtilityFunctions.getCurrentDateAndTimeInValidFormat();
                    var pathTraceDeviceNames = t.response.networkElementsInfo
                    var networkElementsInfo = pathTraceDeviceNames.map(function (deviceObj, index) {
                        if (index == 0 || index == pathTraceDeviceNames.length - 1) {
                            var device = SimLokiDatabaseActions.getFilteredRecordHandler('host', { 'name': deviceObj.name });
                            deviceObj.ip = device[0] ? device[0].hostIpV : deviceObj.ip;
                            deviceObj.id = device[0] ? device[0].id : deviceObj.id;
                            deviceObj.name = device[0] ? device[0].name : deviceObj.id;
                            deviceObj.type = device[0] ? device[0].family : deviceObj.type;
                            deviceObj.role = device[0] ? device[0].role : deviceObj.role;
                        } else {
                            var device = SimLokiDatabaseActions.getFilteredRecordHandler('network-device', { 'name': deviceObj.name });
                            deviceObj.ip = device[0].managementIpAddress;
                            deviceObj.id = device[0] ? device[0].id : deviceObj.id;
                            deviceObj.name = device[0] ? device[0].name : deviceObj.id;
                            deviceObj.type = device[0] ? device[0].family : deviceObj.type;
                            deviceObj.role = device[0] ? device[0].role : deviceObj.role;
                        }
                        deviceObj.linkInformationSource = "Switched";
                        return deviceObj;
                    });
                    t.response.networkElementsInfo = networkElementsInfo;
                } else {
                    t = JSON.parse(JSON.stringify(PathTraceCompleted.PathTrace_Completed_Template));
                    t.response.request.sourceIP = sourceIp;
                    t.response.request.destIP = destIp; 
                    var tTime = UtilityFunctions.getTimeStamp();
                    t.response.request.createTime = tTime;
                    t.response.request.lastUpdateTime = tTime;
                    t.response.networkElementsInfo[0].name = sourceIp;
                    t.response.networkElementsInfo[t.response.networkElementsInfo.length-1].name = destIp;
                    t.response.lastUpdate = UtilityFunctions.getCurrentDateAndTimeInValidFormat();
                    var pathTraceDeviceNames = t.response.networkElementsInfo;
                    var networkElementsInfo = pathTraceDeviceNames.map(function (deviceObj, index) {
                        if (index == 0 || index == pathTraceDeviceNames.length - 1) {
                            var device = SimLokiDatabaseActions.getFilteredRecordHandler('host', { 'name': deviceObj.name });
                            deviceObj.ip = device[0] ? device[0].hostIpV : deviceObj.ip;
                            deviceObj.id = device[0] ? device[0].id : deviceObj.id;
                            deviceObj.type = device[0] ? device[0].family : deviceObj.type;
                            deviceObj.role = device[0] ? device[0].role : deviceObj.role;
                        } else {
                            var device = SimLokiDatabaseActions.getFilteredRecordHandler('network-device', { 'name': deviceObj.name });
                            deviceObj.ip = device[0].managementIpAddress;
                            deviceObj.id = device[0] ? device[0].id : deviceObj.id;
                            deviceObj.name = device[0] ? device[0].name : deviceObj.name;
                            deviceObj.type = device[0] ? device[0].family : deviceObj.type;
                            deviceObj.role = device[0] ? device[0].role : deviceObj.role;
                        }
                        deviceObj.linkInformationSource = "Switched";
                        return deviceObj;
                    });
                    t.response.networkElementsInfo = networkElementsInfo; 
                }
                SimLokiDatabaseActions.insert('pathtrace', t);
                return PathTraceStart.PathTrace_Start_Template;
            },

            getPathTraceStatus: function (urlAction) {
                var objs = SimLokiDatabaseActions.getAll('pathtrace');
                return objs[0];
            },
            getNetworkPathTraceDetailData: function(urlAction){
                var urlSplit =urlAction.url.split(".networkDevices(");
                urlSplit=urlSplit[1].split(").as('entity')");
                urlSplit = urlSplit[0].replace(/["']/g, "");
                urlSplit = urlSplit.split(',');
                var jsonObj=[];
                for(var i=0;i< urlSplit.length;i++){
                    wlcDevice = SimLokiDatabaseActions.getFilteredRecordHandler('network-device',{'id':urlSplit[i]});
                    if(wlcDevice.length>0){
                        var obj =  JSON.parse(JSON.stringify(AssuranceNetworkDeviceDetailTemplate.Assurance_NetworkDevice_Detail_Template ));
                        obj.id = wlcDevice[0].id;
                        obj.timestamp = new Date().getTime();
                        obj.properties.nwDeviceRole[0].value = wlcDevice[0].role;
                        obj.properties.managementIpAddr[0].value = wlcDevice[0].managementIpAddress;
                        obj.properties.nwDeviceFamily[0].value = wlcDevice[0].family;
                        obj.properties.macAddress[0].value = wlcDevice[0].macAddress;
                        obj.properties.nwDeviceName[0].value = wlcDevice[0].hostname;
                        obj.properties.platformId[0].value = wlcDevice[0].platformId;
                        obj.properties.softwareVersion[0].value = wlcDevice[0].softwareVersion;
                        obj.properties.serialNumber[0].value = wlcDevice[0].serialNumber;
                        obj.properties.nwDeviceId[0].value = wlcDevice[0].id;
                        obj.properties.nwDeviceType[0].value = wlcDevice[0].type;
                        obj.properties.upTime[0].value = wlcDevice[0].upTime;
                        obj.properties.connectedTime[0].value = wlcDevice[0].upTime;

                        var entityData = {};
                        entityData.entity = obj;
                        entityData.metric = UtilityFunctions.getRandomIntForRangeValues(7,10);

                        jsonObj.push(entityData);
                    }else{
                        var obj =  JSON.parse(JSON.stringify(AssuranceNetworkDeviceDetailTemplate.Assurance_NetworkDevice_Detail_Template ));
                        var entityData = {};
                        obj.id = urlSplit[i];
                        obj.timestamp = new Date().getTime();
                        obj.timestamp = new Date().getTime();
                        entityData.entity = obj;
                        entityData.metric = UtilityFunctions.getRandomIntForRangeValues(7,10);
                        jsonObj.push(entityData);
                    }
                }
                return jsonObj
            },

            // in the path trace to get the health details -- Need to review 
            getNetworkPathTraceDetailData2: function(urlAction) {
                var urlSplit =urlAction.url.split("has('uuid',within(");
                var urlSplit1=urlSplit[1].split(")).valueMap('uuid','overallScore','stackType').toList()");
                var urlSplit2 = urlSplit1[0].replace(/["']/g, "");
                var urlSplit3 = urlSplit2.split(',');

                var jsonObj = [];
                for(var i=0;i< urlSplit3.length;i++){
                    var entityData = {};
                    entityData.stackType = ["NORMAL"];
                    entityData.uuid = [urlSplit3[i]];
                    entityData.overallScore = [UtilityFunctions.getRandomIntForRangeValues(7,10)];
                    jsonObj.push(entityData)
                }
                return jsonObj;
            }

        };

    });
