define({
    "Template":
    {
        
            "request": {
              "key": "ms-office-365",
              "type": "Application",
              "label": "ms-office-365",
              "collection": false,
              "componentContent": [
                {
                  "component": "fusion",
                  "additional": "05bf3506-92e2-42cc-a4a4-fc4d702f128a"
                }
              ]
            },
            "data": {
              "response": {
                "id": "05bf3506-92e2-42cc-a4a4-fc4d702f128a",
                "instanceId": 4783,
                "authEntityId": 4783,
                "displayName": "4783",
                "authEntityClass": -1909073334,
                "instanceTenantId": "SYS0",
                "deployPending": "NONE",
                "instanceVersion": 0,
                "createTime": 1528178810518,
                "deployed": false,
                "isSeeded": true,
                "isStale": false,
                "lastUpdateTime": 1528178810518,
                "name": "ms-office-365",
                "namespace": "scalablegroup:application",
                "provisioningState": "DEFINED",
                "qualifier": "application",
                "resourceVersion": 0,
                "targetIdList": [],
                "type": "scalablegroup",
                "cfsChangeInfo": [],
                "customProvisions": [],
                "scalableGroupExternalHandle": "ms-office-365",
                "scalableGroupType": "APPLICATION",
                "securityGroupTag": 0,
                "state": "ACTIVE",
                "vnAgnostic": true,
                "identitySource": {
                  "id": "2689858e-a1e6-4c66-85d1-8bd282f8cf29",
                  "instanceId": 10010,
                  "authEntityId": 10010,
                  "displayName": "10010",
                  "authEntityClass": 1738953278,
                  "instanceTenantId": "SYS0",
                  "deployPending": "NONE",
                  "instanceVersion": 0,
                  "state": "INACTIVE",
                  "type": "NBAR"
                },
                "indicativeNetworkIdentity": [],
                "networkApplications": [
                  {
                    "id": "3531d628-dfcd-4132-804f-48ed566d321f",
                    "instanceId": 11731,
                    "authEntityId": 11731,
                    "displayName": "11731",
                    "authEntityClass": -217092956,
                    "instanceTenantId": "SYS0",
                    "deployPending": "NONE",
                    "instanceVersion": 0,
                    "appProtocol": "tcp/udp",
                    "applicationGroup": "ms-cloud-group",
                    "applicationType": "DEFAULT",
                    "categoryId": "87be3d92-9fd1-4d11-aa75-f73db10746ba",
                    "createTime": 1528178810518,
                    "encrypted": "true",
                    "engineId": "13",
                    "helpString": "Microsoft Office 365",
                    "ignoreConflict": false,
                    "lastUpdateTime": 1528178810518,
                    "longDescription": "Microsoft Office 365 is a commercial software plus services that offers the Microsoft Office suite of desktop applications as well as hosted versions of Microsoft's Server products delivered and accessed over the Internet",
                    "name": "ms-office-365",
                    "nbarId": "1431",
                    "p2pTechnology": "false",
                    "popularity": 10,
                    "rank": 65535,
                    "references": "http://www.microsoft.com/en-us/office365/online-software.aspx",
                    "selectorId": "495",
                    "subCategory": "e9ce6c63-8f5c-4982-bf8f-9809939a426e",
                    "trafficClass": "TRANSACTIONAL_DATA",
                    "tunnel": "false"
                  }
                ],
                "networkIdentity": [
                  {
                    "id": "ff6ef0e1-937a-4d83-8483-724b70d9f035",
                    "instanceId": 13990,
                    "authEntityId": 13990,
                    "displayName": "13990",
                    "authEntityClass": 124090504,
                    "instanceTenantId": "SYS0",
                    "deployPending": "NONE",
                    "instanceVersion": 0,
                    "ipv4Subnet": [],
                    "ipv6Subnet": [],
                    "lowerPort": 0,
                    "ports": "53,5353",
                    "protocol": "UDP",
                    "upperPort": 0
                  },
                  {
                    "id": "788a30da-d3ac-406a-b8ac-bab92e97023a",
                    "instanceId": 13991,
                    "authEntityId": 13991,
                    "displayName": "13991",
                    "authEntityClass": 124090504,
                    "instanceTenantId": "SYS0",
                    "deployPending": "NONE",
                    "instanceVersion": 0,
                    "ipv4Subnet": [],
                    "ipv6Subnet": [],
                    "lowerPort": 0,
                    "ports": "53,80,443,5353",
                    "protocol": "TCP",
                    "upperPort": 0
                  }
                ],
                "parentScalableGroup": {
                  "idRef": "8e7847fd-3311-45d5-8833-1760f1c581c1"
                },
                "user": [],
                "userGroup": []
              }
            },
            "result": [
              {
                "bookID": {
                  "namespace": "fusion",
                  "name": "core",
                  "version": "1.0.1"
                },
                "response": {
                  "id": "05bf3506-92e2-42cc-a4a4-fc4d702f128a",
                  "instanceId": 4783,
                  "authEntityId": 4783,
                  "displayName": "4783",
                  "authEntityClass": -1909073334,
                  "instanceTenantId": "SYS0",
                  "deployPending": "NONE",
                  "instanceVersion": 0,
                  "createTime": 1528178810518,
                  "deployed": false,
                  "isSeeded": true,
                  "isStale": false,
                  "lastUpdateTime": 1528178810518,
                  "name": "ms-office-365",
                  "namespace": "scalablegroup:application",
                  "provisioningState": "DEFINED",
                  "qualifier": "application",
                  "resourceVersion": 0,
                  "targetIdList": [],
                  "type": "scalablegroup",
                  "cfsChangeInfo": [],
                  "customProvisions": [],
                  "scalableGroupExternalHandle": "ms-office-365",
                  "scalableGroupType": "APPLICATION",
                  "securityGroupTag": 0,
                  "state": "ACTIVE",
                  "vnAgnostic": true,
                  "identitySource": {
                    "id": "2689858e-a1e6-4c66-85d1-8bd282f8cf29",
                    "instanceId": 10010,
                    "authEntityId": 10010,
                    "displayName": "10010",
                    "authEntityClass": 1738953278,
                    "instanceTenantId": "SYS0",
                    "deployPending": "NONE",
                    "instanceVersion": 0,
                    "state": "INACTIVE",
                    "type": "NBAR"
                  },
                  "indicativeNetworkIdentity": [],
                  "networkApplications": [
                    {
                      "id": "3531d628-dfcd-4132-804f-48ed566d321f",
                      "instanceId": 11731,
                      "authEntityId": 11731,
                      "displayName": "11731",
                      "authEntityClass": -217092956,
                      "instanceTenantId": "SYS0",
                      "deployPending": "NONE",
                      "instanceVersion": 0,
                      "appProtocol": "tcp/udp",
                      "applicationGroup": "ms-cloud-group",
                      "applicationType": "DEFAULT",
                      "categoryId": "87be3d92-9fd1-4d11-aa75-f73db10746ba",
                      "createTime": 1528178810518,
                      "encrypted": "true",
                      "engineId": "13",
                      "helpString": "Microsoft Office 365",
                      "ignoreConflict": false,
                      "lastUpdateTime": 1528178810518,
                      "longDescription": "Microsoft Office 365 is a commercial software plus services that offers the Microsoft Office suite of desktop applications as well as hosted versions of Microsoft's Server products delivered and accessed over the Internet",
                      "name": "ms-office-365",
                      "nbarId": "1431",
                      "p2pTechnology": "false",
                      "popularity": 10,
                      "rank": 65535,
                      "references": "http://www.microsoft.com/en-us/office365/online-software.aspx",
                      "selectorId": "495",
                      "subCategory": "e9ce6c63-8f5c-4982-bf8f-9809939a426e",
                      "trafficClass": "TRANSACTIONAL_DATA",
                      "tunnel": "false"
                    }
                  ],
                  "networkIdentity": [
                    {
                      "id": "ff6ef0e1-937a-4d83-8483-724b70d9f035",
                      "instanceId": 13990,
                      "authEntityId": 13990,
                      "displayName": "13990",
                      "authEntityClass": 124090504,
                      "instanceTenantId": "SYS0",
                      "deployPending": "NONE",
                      "instanceVersion": 0,
                      "ipv4Subnet": [],
                      "ipv6Subnet": [],
                      "lowerPort": 0,
                      "ports": "53,5353",
                      "protocol": "UDP",
                      "upperPort": 0
                    },
                    {
                      "id": "788a30da-d3ac-406a-b8ac-bab92e97023a",
                      "instanceId": 13991,
                      "authEntityId": 13991,
                      "displayName": "13991",
                      "authEntityClass": 124090504,
                      "instanceTenantId": "SYS0",
                      "deployPending": "NONE",
                      "instanceVersion": 0,
                      "ipv4Subnet": [],
                      "ipv6Subnet": [],
                      "lowerPort": 0,
                      "ports": "53,80,443,5353",
                      "protocol": "TCP",
                      "upperPort": 0
                    }
                  ],
                  "parentScalableGroup": {
                    "idRef": "8e7847fd-3311-45d5-8833-1760f1c581c1"
                  },
                  "user": [],
                  "userGroup": []
                }
              }
            ]
        }
          
        });