define({
    "Network_Device_Template": {
        family: "Switches and Hubs",
        location: null,
        type: "Cisco..",
        errorCode: null,
        lastUpdateTime: 1492971051599,
        locationName: null,
        tagCount: "0",
        role: "",
        hostname: "",
        collectionInterval: "Global Default",
        serialNumber: "FCW1921D0",
        inventoryStatusDetail: "<status><general code=\"SUCCESS\"/></status>",
        macAddress: "b0:7d:47:e1:1d:00",
        series: "Cisco Catalyst 3850 Series Ethernet Stackable Switch",
        upTime: "105 days, 17:23:27.64",
        uptimeSeconds: 486347,
        roleSource: "AUTO",
        bootDateTime: "2017-01-08 00:47:44",
        apManagerInterfaceIp: "",
        collectionStatus: "Managed",
        interfaceCount: "19",
        lineCardCount: "1",
        lineCardId: "479dee1a-7ab5-4bd3-ad52-b2c666f0828d",
        managementIpAddress: "",
        memorySize: "536870912",
        platformId: "",
        reachabilityFailureReason: "",
        reachabilityStatus: "Reachable",
        snmpContact: "",
        snmpLocation: "",
        tunnelUdpPort: null,
        waasDeviceMode: null,
        softwareVersion: "03.07.01E",
        errorDescription: null,
        lastUpdated: "2017-04-23 18:10:51",
        instanceUuid: "",
        siteId: "",
        healthScore:[],
        deviceSupportLevel:'Supported',
        associatedWlcIp:"",  // Added by Karan for Graphql query, this will have value only for Unified AP
        stackCount: null,
        managementState: "",
        rmaStoreStatus: {"replacementStatus": ""},
        deviceSecurityAdvisories: {"advisoryCount": 10}
    },

    //fields are diff in diff releases
    /* 'REPLACE_DEVICE_TEMPLATE_1_3_1' : {
        'faultyDeviceName':'PLS06-C9300-01',
        'faultyDevicePlatform':'c9300-24P',
        'replacementDevicePlatform':null,
        'faultyDeviceSerialNumber':'FCW2125L0HY',
        'replacementDeviceSerialNumber':'FCW3125L0HY',
        'faultyDeviceId':'3486e786-02dc-c62a-ad46-eb0482cde452',
        'workflowId':null,
        'networkReadinessTaskId':null,
        'replacementStatus':'READY-FOR-REPLACEMENT',
        'id':'1124498e-3434-467f-aa45-aea701e926e8',
        "neighbourDeviceId": null
    }, */

    'REPLACE_DEVICE_TEMPLATE' : {
        'deviceName':'PLS06-C9300-01',                  //1.4 DNAC
        'faultyDeviceName':'PLS06-C9300-01',            //1.3.1 DNAC
        'platform':'c9300-24P',                         //1.4 DNAC
        'faultyDevicePlatform':'c9300-24P',             //1.3.1 DNAC
        'replacementDevicePlatform':null,
        'deviceSerialNumber':'FCW2125L0HY',             //1.4 DNAC
        'faultyDeviceSerialNumber':'FCW2125L0HY',       //1.3.1 DNAC
        'replacementDeviceSerialNumber':'FCW3125L0HY',
        'instanceUUId':'3486e786-02dc-c62a-ad46-eb0482cde452',  //1.4 DNAC
        'faultyDeviceId':'3486e786-02dc-c62a-ad46-eb0482cde452',//1.3.1 DNAC
        'workflowId':null,
        'networkReadinessTaskId':null,                  //1.3.1 DNAC
        'status':'READY-FOR-REPLACEMENT',               //1.4 DNAC
        'replacementStatus':'READY-FOR-REPLACEMENT',    //1.3.1 DNAC
        'id':'1124498e-3434-467f-aa45-aea701e926e8',
        "neighbourDeviceId": null                       //1.3.1 DNAC
    },

    'ARCHIEVE_TEMPLATE': {
        "archiveResultlist": [
          {
            "deviceId": "e9f081ed-ec06-499f-8ee3-9bee8989acd6",
            "deviceName": "C9300-Edge02.ibng.local",
            "ipAddress": "10.16.50.10",
            "versions": [
              {
                "id": "82c8022d-0eba-4dad-8730-cd5599896de2",
                "createdTime": 1603666905515,
                "files": [
                  {
                    "fileId": "b8b180b9-8050-43e6-8ae2-bdfb27454fed",
                    "downloadPath": "/file/b8b180b9-8050-43e6-8ae2-bdfb27454fed",
                    "fileType": "VLAN",
                    "name": null, "fileFormat": null, "md5Checksum": null, "sha1Checksum": null,
                    "changeMagnitude": "-1.0", "totalNoOfLines": "-1", "linesAdded": "-1", "linesUpdated": "-1", "linesDeleted": "-1",
                    "linesAddedFromStartup": "-1", "linesUpdatedFromStartup": "-1", "linesDeletedFromStartup": "-1"
                  }, {
                    "fileId": "6b3ad8f5-1e33-472b-9117-727360f2e9c0",
                    "downloadPath": "/file/6b3ad8f5-1e33-472b-9117-727360f2e9c0",
                    "fileType": "STARTUPCONFIG",
                    "name": null, "fileFormat": null, "md5Checksum": null, "sha1Checksum": null,
                    "changeMagnitude": "0.0", "totalNoOfLines": "420", "linesAdded": "0", "linesUpdated": "0", "linesDeleted": "0",
                    "linesAddedFromStartup": "0", "linesUpdatedFromStartup": "0", "linesDeletedFromStartup": "0"
                  }, {
                    "fileId": "70b30ce2-d979-4c5d-8240-177a3da406e0",
                    "downloadPath": "/file/70b30ce2-d979-4c5d-8240-177a3da406e0",
                    "fileType": "RUNNINGCONFIG",
                    "name": null, "fileFormat": null, "md5Checksum": null, "sha1Checksum": null,
                    "changeMagnitude": "0.0", "totalNoOfLines": "420", "linesAdded": "0", "linesUpdated": "0", "linesDeleted": "0",
                    "linesAddedFromStartup": "0", "linesUpdatedFromStartup": "0", "linesDeletedFromStartup": "0"
                  }
                ],
                "startupRunningStatus": "IN_SYNC",
                "createdBy": "SCHEDULED"
              }, {
                "id": "7ea0cb36-2484-4f7a-8545-726dd8a992f3",
                "createdTime": 1603062011501,
                "files": [
                  {
                    "fileId": "ad9fb3c9-9d9d-44d8-80ca-7f4eb771d5c5",
                    "downloadPath": "/file/ad9fb3c9-9d9d-44d8-80ca-7f4eb771d5c5",
                    "fileType": "VLAN",
                    "name": null, "fileFormat": null, "md5Checksum": null, "sha1Checksum": null,
                    "changeMagnitude": "-1.0", "totalNoOfLines": "-1", "linesAdded": "-1", "linesUpdated": "-1", "linesDeleted": "-1",
                    "linesAddedFromStartup": "-1", "linesUpdatedFromStartup": "-1", "linesDeletedFromStartup": "-1"
                  }, {
                    "fileId": "96cff6d8-3ae0-4751-bfd0-533667cc8def",
                    "downloadPath": "/file/96cff6d8-3ae0-4751-bfd0-533667cc8def",
                    "fileType": "STARTUPCONFIG",
                    "name": null, "fileFormat": null, "md5Checksum": null, "sha1Checksum": null,
                    "changeMagnitude": "0.0", "totalNoOfLines": "420", "linesAdded": "0", "linesUpdated": "0", "linesDeleted": "0",
                    "linesAddedFromStartup": "0", "linesUpdatedFromStartup": "0", "linesDeletedFromStartup": "0"
                  }, {
                    "fileId": "46bb29ab-27a7-47ef-b495-e830e0074cea",
                    "downloadPath": "/file/46bb29ab-27a7-47ef-b495-e830e0074cea",
                    "fileType": "RUNNINGCONFIG",
                    "name": null, "fileFormat": null, "md5Checksum": null, "sha1Checksum": null,
                    "changeMagnitude": "0.0", "totalNoOfLines": "420", "linesAdded": "0", "linesUpdated": "0", "linesDeleted": "0",
                    "linesAddedFromStartup": "0", "linesUpdatedFromStartup": "0", "linesDeletedFromStartup": "0"
                  }
                ],
                "startupRunningStatus": "IN_SYNC",
                "createdBy": "SCHEDULED"
              }, {
                "id": "2cf3b146-3f6f-4716-a07b-2c9c614c4cf9",
                "createdTime": 1602567127172,
                "files": [
                  {
                    "fileId": "3d048bdd-a863-41f2-9786-c64b82cff5ca",
                    "downloadPath": "/file/3d048bdd-a863-41f2-9786-c64b82cff5ca",
                    "fileType": "VLAN",
                    "name": null, "fileFormat": null, "md5Checksum": null, "sha1Checksum": null,
                    "changeMagnitude": "-1.0", "totalNoOfLines": "-1", "linesAdded": "-1", "linesUpdated": "-1", "linesDeleted": "-1",
                    "linesAddedFromStartup": "-1", "linesUpdatedFromStartup": "-1", "linesDeletedFromStartup": "-1"
                  }, {
                    "fileId": "a3708a75-5d6e-492a-9c51-dbc47fe712c0",
                    "downloadPath": "/file/a3708a75-5d6e-492a-9c51-dbc47fe712c0",
                    "fileType": "STARTUPCONFIG",
                    "name": null, "fileFormat": null, "md5Checksum": null, "sha1Checksum": null,
                    "changeMagnitude": "0.0", "totalNoOfLines": "420", "linesAdded": "0", "linesUpdated": "0", "linesDeleted": "0",
                    "linesAddedFromStartup": "0", "linesUpdatedFromStartup": "0", "linesDeletedFromStartup": "0"
                  }, {
                    "fileId": "4a2ebaa1-920f-4457-b648-1c3a3a1e4ecf",
                    "downloadPath": "/file/4a2ebaa1-920f-4457-b648-1c3a3a1e4ecf",
                    "fileType": "RUNNINGCONFIG",
                    "name": null, "fileFormat": null, "md5Checksum": null, "sha1Checksum": null,
                    "changeMagnitude": "0.0", "totalNoOfLines": "420", "linesAdded": "0", "linesUpdated": "0", "linesDeleted": "0",
                    "linesAddedFromStartup": "0", "linesUpdatedFromStartup": "0", "linesDeletedFromStartup": "0"
                  }
                ],
                "startupRunningStatus": "IN_SYNC",
                "createdBy": "SCHEDULED"
              }
            ]
          }
        ]
      },

      "COMPLIANCE_DETAIL": [
        {
          "deviceUuid":"e9f081ed-ec06-499f-8ee3-9bee8989acd6",
          "complianceType":"RUNNING_CONFIG",
          "sourceInfoList":[],
          "status":"COMPLIANT",
          "lastSyncTime":1602567127172,
          "lastUpdateTime":1603666937753
        },{
          "deviceUuid":"e9f081ed-ec06-499f-8ee3-9bee8989acd6",
          "complianceType":"PSIRT",
          "sourceInfoList":[],
          "status":"COMPLIANT",
          "lastSyncTime":1601720900102,
          "lastUpdateTime":1603580402145
        },{
          "deviceUuid":"e9f081ed-ec06-499f-8ee3-9bee8989acd6",
          "complianceType":"IMAGE",
          "sourceInfoList":[],
          "status":"COMPLIANT",
          "lastSyncTime":1602743378321,
          "lastUpdateTime":1603580402127,
          "additionalDataURL":"/api/v2/device-image/device?id=simDevId"
        }
      ],

      "COMPLIANCE_SUMMARY": [
        {
          "deviceUuid":"e9f081ed-ec06-499f-8ee3-9bee8989acd6",
          "complianceStatus":"COMPLIANT",
          "lastUpdateTime":1603666937774
        }
      ],

      "POWER_SUPPLY_TEMPLATE_SWITCH": [
        {
          "instanceUuid":"fadbd795-1207-4488-9ddd-e4e4fdda2512",
          "name":"Switch 1 - Power Supply B",
          "description":"Switch 1 - Power Supply B",
          "vendorEquipmentType":"cevPowerSupplyUnknown",
          "serialNumber":"",
          "operationalStateCode":"notPresent"
        },{
          "instanceUuid":"ff2c0243-c35f-4156-bbbb-a7d4ca31edb1",
          "name":"Switch 1 - Power Supply A",
          "description":"Switch 1 - Power Supply A",
          "vendorEquipmentType":"cevPowerSupplyCat3kCT57xxC1Pwr1100wAc",
          "serialNumber":"LIT22465XU4",
          "operationalStateCode":"normal"
        }
      ],

      "POWER_SUPPLY_TEMPLATE_ROUTER": [
        {
          "instanceUuid":"20037840-b758-4cff-bcef-ba10fabbc164",
          "name":"Power Supply 0",
          "description":"Power Supply",
          "vendorEquipmentType":"cevPowerSupply$PID_PARTPS",
          "serialNumber":"",
          "operationalStateCode":"enabled"
        },{
          "instanceUuid":"cc96c302-72e8-40e2-a258-5d6b7681c09a",
          "name":"Power Supply Module 0",
          "description":"250W AC Power Supply for Cisco $PID_PART",
          "vendorEquipmentType":"cevPowerSupply$PID_PARTPWR250",
          "serialNumber":"LIT224144LM",
          "operationalStateCode":"on"
        }
      ],

      "FAN_TEMPLATE_SWITCH": [
        {
          "instanceUuid":"09e99d63-ed88-47af-9961-6fafbee4b3a3",
          "name":"Switch 1 - FAN - T1 2",
          "description":"Switch 1 - $PID - FAN 2",
          "vendorEquipmentType":"cevFanCat3kCT57xxFan",
          "serialNumber":"",
          "operationalStateCode":"normal"
        },{
          "instanceUuid":"3d0d89f9-13a9-4261-bdf7-0e386269e9be",
          "name":"Switch 1 - FAN - T1 1",
          "description":"Switch 1 - $PID - FAN 1",
          "vendorEquipmentType":"cevFanCat3kCT57xxFan",
          "serialNumber":"",
          "operationalStateCode":"normal"
        },{
          "instanceUuid":"40d384ff-ab81-4a5d-a5a9-cdedb7dc9560",
          "name":"Switch 1 - FAN - T1 3",
          "description":"Switch 1 - $PID - FAN 3",
          "vendorEquipmentType":"cevFanCat3kCT57xxFan",
          "serialNumber":"",
          "operationalStateCode":"normal"
        }
      ],

      "FAN_TEMPLATE_ROUTER": [
        {
          "instanceUuid":"2d3d63b4-0ff8-42fd-9c4a-67bc92e2d441",
          "name":"Fan 0/0","description":"Fan",
          "vendorEquipmentType":"cevFan$PID_PARTFan",
          "serialNumber":"",
          "operationalStateCode":"enabled"
        },{
          "instanceUuid":"500bb831-6b75-4df3-a567-484419607af4",
          "name":"Fan 2/0",
          "description":"Fan",
          "vendorEquipmentType":"cevFan$PID_PARTFan",
          "serialNumber":"",
          "operationalStateCode":"enabled"
        },{
          "instanceUuid":"62b64067-5e69-4245-8731-aeb0b6c8e811",
          "name":"Fan Tray",
          "description":"Cisco $PID_PART Fan Assembly",
          "vendorEquipmentType":"cevFanTray$PID_PART",
          "serialNumber":"",
          "operationalStateCode":"up"
        },{
          "instanceUuid":"8703963c-bd97-434b-92a2-225e8b530b7b",
          "name":"Fan 2/1",
          "description":"Fan",
          "vendorEquipmentType":"cevFan$PID_PARTFan",
          "serialNumber":"",
          "operationalStateCode":"enabled"
        },{
          "instanceUuid":"e272b567-22ef-4d96-beb5-43b82025bc90",
          "name":"Fan 2/2",
          "description":"Fan",
          "vendorEquipmentType":"cevFan$PID_PARTFan",
          "serialNumber":"",
          "operationalStateCode":"enabled"
        }
      ],

      "STACK_DEVICE_CALL_TEMPLATE": {
        "deviceId": "9db901b1-8ac2-43d7-8e31-6e280c67d3fe",
        "stackSwitchInfo": [
          {
            "hwPriority": 0,
            "macAddress": "6c:5e:3b:9a:98:80",
            "numNextReload": 1,
            "role": "ACTIVE",
            "softwareImage": "CAT9K_IOSXE",
            "stackMemberNumber": 1,
            "state": "READY",
            "switchPriority": 10,
            "entPhysicalIndex": "1000",
            "serialNumber": "FCW2321C05U",
            "platformId": "C9300-24T"
          },{
            "hwPriority": 0,
            "macAddress": "ac:f5:e6:99:3c:00",
            "numNextReload": 2,
            "role": "STANDBY",
            "softwareImage": "CAT9K_IOSXE",
            "stackMemberNumber": 2,
            "state": "READY",
            "switchPriority": 1,
            "entPhysicalIndex": "2000",
            "serialNumber": "FCW2314L0Q2",
            "platformId": "C9300-24T"
          }
        ],
        "stackPortInfo": [
          {
            "isSynchOk": "Yes",
            "name": "StackSub-St1-2",
            "switchPort": "1/2",
            "neighborPort": "NONE/NONE",
            "nrLinkOkChanges": 0,
            "stackCableLengthInfo": "NO_CABLE",
            "stackPortOperStatusInfo": "DOWN",
            "linkActive": false,
            "linkOk": false
          },{
            "isSynchOk": "Yes",
            "name": "StackSub-St2-2",
            "switchPort": "2/2",
            "neighborPort": "NONE/NONE",
            "nrLinkOkChanges": 0,
            "stackCableLengthInfo": "NO_CABLE",
            "stackPortOperStatusInfo": "DOWN",
            "linkActive": false,
            "linkOk": false
          },{
            "isSynchOk": "Yes",
            "name": "StackSub-St1-1",
            "switchPort": "1/1",
            "neighborPort": "2/1",
            "nrLinkOkChanges": 1,
            "stackCableLengthInfo": "_50_CM",
            "stackPortOperStatusInfo": "OK",
            "linkActive": true,
            "linkOk": true
          },{
            "isSynchOk": "Yes",
            "name": "StackSub-St2-1",
            "switchPort": "2/1",
            "neighborPort": "1/1",
            "nrLinkOkChanges": 1,
            "stackCableLengthInfo": "_50_CM",
            "stackPortOperStatusInfo": "OK",
            "linkActive": true,
            "linkOk": true
          }
        ],
        "svlSwitchInfo": null
      },
      "DeployConfigTaskTemplate":{
        "parentTaskId": "218bbead-3369-47b4-b0b2-151023d210f5",
        "taskId": "218bbead-3369-47b4-b0b2-151023d210f5",
        "completionTime": 1633504278715,
        "deviceId": "9b4a675b-38c6-4beb-95f0-97643b7155ad",
        "deviceIpAddress": "10.16.50.14",
        "errorCode": "",
        "taskStatus": "SUCCESS",
        "failureMessage": "",
        "startTime": 1633504206025,
        "hostName": "p1.edge1-sda1.local"
      }

});
