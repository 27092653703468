define([
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/database/SimLokiDatabaseActions',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/utilities/UtilityFunctions'
], function (SimLokiDatabaseActions, UtilityFunctions) {

    function getNetworkDetailsDevices(filterValue) {
        var tObjs = [];
        var ntwObjs = SimLokiDatabaseActions.getAll('network-device');
        if (filterValue && filterValue.length > 0) {
            for (var i = 0; i < ntwObjs.length; i++) {
                if (ntwObjs[i].hostname.indexOf(filterValue) > -1) {
                    tObjs.push(ntwObjs[i].hostname);
                }
            }
        } else {
            for (var i = 0; i < ntwObjs.length; i++) {
                tObjs.push(ntwObjs[i].hostname);
            }
        }
        return tObjs;
    }

    function getSSIDs(filterValue) {
        var tObjs = [];
        var SSIDs = ["@CorpSSID", "c9800AP11AX", "c9800AP11AC"];//Not sure if we can pick from any table
        if (filterValue && filterValue.length > 0) {
            for (var i = 0; i < SSIDs.length; i++) {
                if (SSIDs[i].indexOf(filterValue) > -1) {
                    tObjs.push(SSIDs[i]);
                }
            }
        } else {
            for (var i = 0; i < SSIDs.length; i++) {
                tObjs.push(SSIDs[i]);
            }
        }
        return tObjs;
    }

    function getApGroups(filterValue) {
        var tObjs = [];
        var groupNames = ["Wave2_APs", "Wave1_APs"];//Not sure if we can pick from any table
        if (filterValue && filterValue.length > 0) {
            for (var i = 0; i < groupNames.length; i++) {
                if (groupNames[i].indexOf(filterValue) > -1) {
                    tObjs.push(groupNames[i]);
                }
            }
        } else {
            for (var i = 0; i < groupNames.length; i++) {
                tObjs.push(groupNames[i]);
            }
        }
        return tObjs;
    }

    function getDeviceTypes(filterValue) {
        var tObjs = [];
        var ntwObjs = SimLokiDatabaseActions.getAll('network-device');
        if (filterValue && filterValue.length > 0) {
            for (var i = 0; i < ntwObjs.length; i++) {
                if (ntwObjs[i].platformId.indexOf(filterValue) > -1 && tObjs.indexOf(ntwObjs[i].platformId) == -1) {
                    tObjs.push(ntwObjs[i].platformId);
                }
            }
        } else {
            for (var i = 0; i < ntwObjs.length; i++) {
                if(tObjs.indexOf(ntwObjs[i].platformId) == -1) {
                    tObjs.push(ntwObjs[i].platformId);
                }
            }
        }
        return tObjs;
    }

    function getMgmtIps(filterValue) {
        var tObjs = [];
        var ntwObjs = SimLokiDatabaseActions.getAll('network-device');
        if (filterValue && filterValue.length > 0) {
            for (var i = 0; i < ntwObjs.length; i++) {
                if (ntwObjs[i].managementIpAddress.indexOf(filterValue) > -1) {
                    tObjs.push(ntwObjs[i].managementIpAddress);
                }
            }
        } else {
            for (var i = 0; i < ntwObjs.length; i++) {
                tObjs.push(ntwObjs[i].managementIpAddress);
            }
        }
        return tObjs;
    }

    function getSoftwareVersions(filterValue) {
        var tObjs = [];
        var imageList = SimLokiDatabaseActions.getFilteredRecordHandler('images', {'deviceAvailable':true});//physical device images only
        var versionList = [];
        for(var i=0; i<imageList.length; i++) {
            for(var j=0; j<imageList[i].runningImageList.length; j++) {
                var version = imageList[i].runningImageList[j].version;
                if(versionList.indexOf(version) == -1) {
                    versionList.push(version);
                }
            }
        }
        if (filterValue && filterValue.length > 0) {
            for(var k=0; k<versionList.length; k++) {
                if (versionList[k].indexOf(filterValue) > -1) {
                    tObjs.push(versionList[k]);
                }
            }
        } else {
            return versionList;
        }
        return tObjs;
    }

    function getLocations(filterValue) {
        //need to check if this method is picking from the right fields
        var tObjs = [];
        var tsites = SimLokiDatabaseActions.getAll('site');
        if (filterValue && filterValue.length > 0) {
            for (var i = 0; i < tsites.length; i++) {
                if (tsites[i].groupNameHierarchy.indexOf(filterValue) > -1) {
                    tObjs.push(tsites[i].groupNameHierarchy);
                }
            }
        } else {
            for (var i = 0; i < tsites.length; i++) {
                if(tObjs.indexOf(tsites[i].groupNameHierarchy) == -1) {
                    tObjs.push(tsites[i].groupNameHierarchy);
                }
            }
        }
        return tObjs;
    }

    function getHostFilter(filtername,filterValue) {
        var tObjs=[]; 
        var tClients=SimLokiDatabaseActions.getAll('host');
        var tObj=[];tObj[0]="",j=1;
        if (filterValue && filterValue.length > 0) {
            for (var i = 0; i < tClients.length; i++) {
                if (tClients[i][filtername].indexOf(filterValue) > -1) {
                   tObj[j]=tClients[i][filtername];
                   j++;
                }
            }
        } else {
            for (var i = 0; i < tClients.length; i++) {
                tObj[j]=tClients[i][filtername];
                j++;
               }
        }
       var tObjLists = [...new Set(tObj)];
        for(var i=0;i<tObjLists.length;i++){
            tObjs.push({"value":tObjLists[i]});
        }
        return tObjs;
    }

    function getNetworkDeviceTypes(filterValue){
        var tObjs = [];
        var ntwObjs = SimLokiDatabaseActions.getAll('network-device');
        if (filterValue && filterValue.length > 0) {
            for (var i = 0; i < ntwObjs.length; i++) {
                if (ntwObjs[i].type.indexOf(filterValue) > -1) {
                    tObjs.push(ntwObjs[i].type);
                }
            }
        } else {
            for (var i = 0; i < ntwObjs.length; i++) {
                tObjs.push(ntwObjs[i].type);
            }
        }
        return [...new Set(tObjs)];
    }


    return {
        init: function () {
        },

        getAutoCompleteHost: function (urlAction) {
            //Used in Client Health
            var filter = urlAction.filter;
            var tObjs = [];
            if (filter['field'] == 'nwDeviceName') {
                tObjs = getNetworkDetailsDevices(filter['value'].toString());
            } else if (filter['field'] == 'SSID') {
                tObjs = getSSIDs(filter['value'].toString());
            }
            else if (filter['field'] == 'HOST_NAME') {
                tObjs = getHostFilter("hostName",filter['value'].toString());
            }
            else if (filter['field'] == 'HOST_TYPE') {
                tObjs = getHostFilter("subType",filter['value'].toString());
            }
            else if (filter['field'] == 'USER_NAME') {
                tObjs = getHostFilter("userId",filter['value'].toString());
            }
            else if (filter['field'] == 'IPV4') {
                tObjs = getHostFilter("hostIpV4",filter['value'].toString());
            }
            else if (filter['field'] == 'AP_NAME') {
                tObjs = getHostFilter("clientConnection",filter['value'].toString());
            }
            else if (filter['field'] == 'AUTHTYPE') {
                tObjs = getHostFilter("authType",filter['value'].toString());
            }
            else if (filter['field'] == 'DEVICE_NAME') {
                tObjs = getHostFilter("clientConnection",filter['value'].toString());
            }
             else if (filter['field'] == 'nwGroupName') {
                tObjs = getApGroups(filter['value'].toString());
            }
            return { "response": tObjs };
        },

        getAutoCompleteNetworkDevice: function(urlAction) {
            //Used in Network Health
            var filter = urlAction.filter;
            var tObjs = [];
            if (filter['field'] == 'nwDeviceName') {
                tObjs = getNetworkDetailsDevices(filter['value'].toString());
            }else if (filter['field'] == 'name') {
                tObjs = getNetworkDetailsDevices(filter['value'].toString());
            } else if (filter['field'] == 'platformId') {
                tObjs = getDeviceTypes(filter['value'].toString());
            } else if (filter['field'] == 'managementIpAddr') {
                tObjs = getMgmtIps(filter['value'].toString());
            } else if (filter['field'] == 'managementIpAddress') {
                tObjs = getMgmtIps(filter['value'].toString());
            } else if (filter['field'] == 'softwareVersion') {
                tObjs = getSoftwareVersions(filter['value'].toString());
            } else if (filter['field'] == 'osVersion') {
                tObjs = getSoftwareVersions(filter['value'].toString());
            } else if (filter['field'] == 'siteHierarchy') {
                tObjs = getLocations(filter['value'].toString());
            } else if (filter['field'] == 'nwDeviceType'){
                tObjs = getNetworkDeviceTypes(filter['value'].toString());
            } else if (filter['field'] == 'deviceModel'){
                tObjs = getNetworkDeviceTypes(filter['value'].toString());
            }
            return { "response": tObjs };
        },

        getAutoCompleteData: function (objs, field, value) {
            //This is called from Network Overall Health Page when a device type is selected
            var response = [];
            if(value!=undefined || value.trim()!='') {
                if(typeof(value)=='number') value = value.toString();
                value = value.toLowerCase();
            }
            switch (field) {
                case 'name':
                case 'nwDeviceName':
                    objs.filter(function(itm){ 
                        if(!value || itm.hostname.toLowerCase().indexOf(value)>-1) response.push(itm.hostname); 
                    });
                    break;
                case 'platformId':
                case 'deviceModel':
                    objs.filter(function(itm){ 
                        if((!value || itm.platformId.toLowerCase().indexOf(value)>-1) && response.indexOf(itm.platformId)==-1) 
                            response.push(itm.platformId); 
                    });
                    break;
                case 'managementIpAddr':
                case 'managementIpAddress':
                    objs.filter(function(itm){ 
                        if(!value || itm.managementIpAddress.toLowerCase().indexOf(value)>-1) response.push(itm.managementIpAddress); 
                    });
                    break;
                  case 'osVersion':
                    objs.filter(function(itm){ 
                        if((!value || itm.softwareVersion.toLowerCase().indexOf(value)>-1) && response.indexOf(itm.softwareVersion)==-1)
                            response.push(itm.softwareVersion); 
                    });
                    break;
            }
            return { "response": response };
        }

    }
});
