define({
  "Template": {
    "version": "1.0",
    "response": {
      "nodes": [
        {
          "role": "Client",
          "name": "2.4GHz Clients",
          "id": "client2.4ghz",
          "description": "2.4GHz Clients",
          "deviceType": null,
          "platformId": null,
          "family": "client2.4ghz",
          "ip": null,
          "softwareVersion": null,
          "userId": null,
          "nodeType": null,
          "radioFrequency": null,
          "clients": 0,
          "count": null,
          "healthScore": null,
          "level": 2,
          "fabricGroup": null,
          "connectedDevice": null
        },
        {
          "role": "Client",
          "name": "5GHz Clients",
          "id": "client5ghz",
          "description": "5GHz Clients",
          "deviceType": null,
          "platformId": null,
          "family": "client5ghz",
          "ip": null,
          "softwareVersion": null,
          "userId": null,
          "nodeType": null,
          "radioFrequency": null,
          "clients": 0,
          "count": null,
          "healthScore": null,
          "level": 2,
          "fabricGroup": null,
          "connectedDevice": null
        }
      ],
      "links": [
        {
          "source": "6ae9e19e-865c-48eb-813e-a1df5b7e658d",
          "linkStatus": null,
          "label": [],
          "target": "client2.4ghz",
          "id": "client2.4ghz",
          "portUtilization": null,          
          "sourceLinkStatus": null,
          "targetLinkStatus": null,
          "sourceInterfaceName": null,
          "targetInterfaceName": null,
          "sourceDuplexInfo": null,
          "targetDuplexInfo": null,
          "sourcePortMode": null,
          "targetPortMode": null,
          "sourceAdminStatus": null,
          "targetAdminStatus": null,
          "interfaceDetails": [],
          "apRadioAdminStatus": "UP",
          "apRadioOperStatus": "DOWN"
        },
        {
          "source": "6ae9e19e-865c-48eb-813e-a1df5b7e658d",
          "linkStatus": null,
          "label": [],
          "target": "client5ghz",
          "id": "client5ghz",
          "portUtilization": null,
          "portUtilization": null,          
          "sourceLinkStatus": null,
          "targetLinkStatus": null,
          "sourceInterfaceName": null,
          "targetInterfaceName": null,
          "sourceDuplexInfo": null,
          "targetDuplexInfo": null,
          "sourcePortMode": null,
          "targetPortMode": null,
          "sourceAdminStatus": null,
          "targetAdminStatus": null,
          "interfaceDetails": [],
          "apRadioAdminStatus": "UP",
          "apRadioOperStatus": "DOWN"
        }
      ]
    }
  }
});