define({
    "UpgradeImageData": [       
        {  
            "deviceUuid":"896fdb94-b1b5-4c4b-ae3b-50891ca3f5a6",
            "imageName":null,
            "imageUuid":null,
            "timeStamp":"06/06/2018 11:26:53",
            "deviceInformation":"Golden image not found for device.",
            "ramRequired":"Golden image not found for device.",
            "flashRequired":"Golden image not found for device.",
            "reloadRequired":false,
            "ramAvailable":false,
            "flashAvailable":false
        }       
    ],

    "VALIDATOR_DETAIL": [
        {
            "bindingUuid": "4bcaac0c-874b-42c5-89a3-8422ef4b7eee",
            "uniqueId": "dcf89288-ab40-4516-a396-5d7135a10c68",
            "validatorUuid": "df6883b4-2ada-4411-b483-94dac486d307",
            "validatorName": "Flash check",
            "operationUuid": "afcab09f-233f-4395-adf7-2395ec6a7e55",
            "operationName": "Distribution",
            "typeUuid": "ea36b60b-6f00-4e16-a060-d844e2610079",
            "typeName": "PRE",
            "selectionCriterias": null,
            "isActive": true,
            "executionSequence": 4,
            "createdBy": "system",
            "createTime": "2020-08-14 03:00:44.236",
            "updatedBy": "system",
            "lastUpdateTime": "2020-08-14 03:00:44.236",
            "validatorType": "JAVA_BEAN",
            "fixMeUrl": null,
            "description": "Validate if enough space is present on device. CLI: dir flash:",
            "cli": null,
            "systemDefined": true
        },
        {
            "bindingUuid": "f4985df8-7cc5-4387-bb3c-bcd37326f0a8",
            "uniqueId": "24a15ce9-7bd2-4ba0-b65d-0c47cee17eb1",
            "validatorUuid": "7b1bef9c-4bb7-4a40-813b-1d34be6c8b7a",
            "validatorName": "Spanning Tree Summary Check",
            "operationUuid": "c7a9415b-0e71-4be5-ac31-58914bf2f9ca",
            "operationName": "Activate",
            "typeUuid": "ea36b60b-6f00-4e16-a060-d844e2610079",
            "typeName": "PRE",
            "selectionCriterias": [
                {
                    "selectionCriteriaUuid": "006b1c76-7404-4b22-abf3-352698a3a358",
                    "selectionCriteriaName": "software_type",
                    "relationalOperator": "EQ",
                    "description": "software_type",
                    "createTime": "14-Aug-2020 03:01:16",
                    "lastUpdateTime": "14-Aug-2020 03:01:16",
                    "valueDTOList": [
                        {
                            "bindingExtendedUuid": "8c5a623a-ac0d-40cb-8a32-a4193ecb0907",
                            "value": "[\"IOS-XE\",\"IOS\"]"
                        }
                    ]
                },
                {
                    "selectionCriteriaUuid": "9273cd9b-0ce8-4baf-a790-ee5f3f5d81b8",
                    "selectionCriteriaName": "deviceFamily",
                    "relationalOperator": "EQ",
                    "description": "deviceFamily",
                    "createTime": "14-Aug-2020 03:00:20",
                    "lastUpdateTime": "14-Aug-2020 03:00:20",
                    "valueDTOList": [
                        {
                            "bindingExtendedUuid": "c38a645d-6415-4596-bba7-f6489ab25c81",
                            "value": "[\"Routers\",\"Switches and Hubs\"]"
                        }
                    ]
                }
            ],
            "isActive": true,
            "executionSequence": 1,
            "createdBy": "system",
            "createTime": "2020-08-14 03:01:19.427",
            "updatedBy": "system",
            "lastUpdateTime": "2020-08-14 03:01:19.427",
            "validatorType": "SCRIPT",
            "fixMeUrl": null,
            "description": "Validate spanning tree config on device. CLI: show spanning-tree summary",
            "cli": null,
            "systemDefined": true
        },
        {
            "bindingUuid": "27065249-9be0-4557-971b-f38d8e80ac22",
            "uniqueId": "9cbe630e-f1da-4882-ac5b-c54be9ccd0ba",
            "validatorUuid": "5ec17523-3131-400c-a96a-909ee2e2c79a",
            "validatorName": "CDP neighbors Check",
            "operationUuid": "c7a9415b-0e71-4be5-ac31-58914bf2f9ca",
            "operationName": "Activate",
            "typeUuid": "ea36b60b-6f00-4e16-a060-d844e2610079",
            "typeName": "PRE",
            "selectionCriterias": [
                {
                    "selectionCriteriaUuid": "006b1c76-7404-4b22-abf3-352698a3a358",
                    "selectionCriteriaName": "software_type",
                    "relationalOperator": "EQ",
                    "description": "software_type",
                    "createTime": "14-Aug-2020 03:01:16",
                    "lastUpdateTime": "14-Aug-2020 03:01:16",
                    "valueDTOList": [
                        {
                            "bindingExtendedUuid": "34c8b978-32d2-497b-87ec-a61f685d0889",
                            "value": "[\"IOS-XE\",\"IOS\"]"
                        }
                    ]
                },
                {
                    "selectionCriteriaUuid": "9273cd9b-0ce8-4baf-a790-ee5f3f5d81b8",
                    "selectionCriteriaName": "deviceFamily",
                    "relationalOperator": "EQ",
                    "description": "deviceFamily",
                    "createTime": "14-Aug-2020 03:00:20",
                    "lastUpdateTime": "14-Aug-2020 03:00:20",
                    "valueDTOList": [
                        {
                            "bindingExtendedUuid": "1923f70f-ea2a-4d05-8916-00eb38d1f715",
                            "value": "[\"Routers\",\"Switches and Hubs\"]"
                        }
                    ]
                }
            ],
            "isActive": true,
            "executionSequence": 2,
            "createdBy": "system",
            "createTime": "2020-08-14 03:01:17.056",
            "updatedBy": "system",
            "lastUpdateTime": "2020-08-14 03:01:17.056",
            "validatorType": "SCRIPT",
            "fixMeUrl": null,
            "description": "Validate status of connected neighbors. CLI: show cdp neighbors",
            "cli": null,
            "systemDefined": true
        },
        {
            "bindingUuid": "7c4eb8bf-569b-414f-b413-836d0bab4a86",
            "uniqueId": "e1433708-dc45-410c-910f-497cfb571059",
            "validatorUuid": "286bfdef-7db5-4940-aaa3-27b232711f1a",
            "validatorName": "Interface Check",
            "operationUuid": "c7a9415b-0e71-4be5-ac31-58914bf2f9ca",
            "operationName": "Activate",
            "typeUuid": "ea36b60b-6f00-4e16-a060-d844e2610079",
            "typeName": "PRE",
            "selectionCriterias": [
                {
                    "selectionCriteriaUuid": "006b1c76-7404-4b22-abf3-352698a3a358",
                    "selectionCriteriaName": "software_type",
                    "relationalOperator": "EQ",
                    "description": "software_type",
                    "createTime": "14-Aug-2020 03:01:16",
                    "lastUpdateTime": "14-Aug-2020 03:01:16",
                    "valueDTOList": [
                        {
                            "bindingExtendedUuid": "5945a099-c218-419d-8985-7eda6ef2f951",
                            "value": "[\"IOS-XE\",\"IOS\"]"
                        }
                    ]
                },
                {
                    "selectionCriteriaUuid": "9273cd9b-0ce8-4baf-a790-ee5f3f5d81b8",
                    "selectionCriteriaName": "deviceFamily",
                    "relationalOperator": "EQ",
                    "description": "deviceFamily",
                    "createTime": "14-Aug-2020 03:00:20",
                    "lastUpdateTime": "14-Aug-2020 03:00:20",
                    "valueDTOList": [
                        {
                            "bindingExtendedUuid": "3d603a0f-0606-4dec-9d4e-1b0640c46284",
                            "value": "[\"Routers\",\"Switches and Hubs\"]"
                        }
                    ]
                }
            ],
            "isActive": true,
            "executionSequence": 3,
            "createdBy": "system",
            "createTime": "2020-08-14 03:01:16.235",
            "updatedBy": "system",
            "lastUpdateTime": "2020-08-14 03:01:16.235",
            "validatorType": "SCRIPT",
            "fixMeUrl": null,
            "description": "Validate status of interfaces. CLI: show ip interface brief",
            "cli": null,
            "systemDefined": true
        },
        {
            "bindingUuid": "305748c5-0785-463f-8ce3-1fa3453836f9",
            "uniqueId": "34f668c6-e43f-4255-8b97-e2d8bc0a0b5c",
            "validatorUuid": "568b9567-e325-4af7-98a7-9eb4598d34b3",
            "validatorName": "Config register check",
            "operationUuid": "c7a9415b-0e71-4be5-ac31-58914bf2f9ca",
            "operationName": "Activate",
            "typeUuid": "ea36b60b-6f00-4e16-a060-d844e2610079",
            "typeName": "PRE",
            "selectionCriterias": [
                {
                    "selectionCriteriaUuid": "9273cd9b-0ce8-4baf-a790-ee5f3f5d81b8",
                    "selectionCriteriaName": "deviceFamily",
                    "relationalOperator": "EQ",
                    "description": "deviceFamily",
                    "createTime": "14-Aug-2020 03:00:20",
                    "lastUpdateTime": "14-Aug-2020 03:00:20",
                    "valueDTOList": [
                        {
                            "bindingExtendedUuid": "e7625521-6cec-4c99-9efe-182bd8b7e5cb",
                            "value": "[\"Routers\",\"Switches and Hubs\",\"Voice and Telephony\"]"
                        }
                    ]
                }
            ],
            "isActive": true,
            "executionSequence": 11,
            "createdBy": "system",
            "createTime": "2020-08-14 03:00:40.104",
            "updatedBy": "system",
            "lastUpdateTime": "2020-08-14 03:00:40.104",
            "validatorType": "XDE_RUNNER",
            "fixMeUrl": null,
            "description": "Validate if config register value on device is correct. CLI: show version",
            "cli": null,
            "systemDefined": true
        },
        {
            "bindingUuid": "ff000f8d-b378-4383-a148-8b2c3a55b348",
            "uniqueId": "5e7d8d61-dbc2-483c-8c7d-b0d2e6763860",
            "validatorUuid": "f6c60f2c-c2bf-4186-a06b-34703790eed0",
            "validatorName": "Startup config check",
            "operationUuid": "c7a9415b-0e71-4be5-ac31-58914bf2f9ca",
            "operationName": "Activate",
            "typeUuid": "ea36b60b-6f00-4e16-a060-d844e2610079",
            "typeName": "PRE",
            "selectionCriterias": [
                {
                    "selectionCriteriaUuid": "9273cd9b-0ce8-4baf-a790-ee5f3f5d81b8",
                    "selectionCriteriaName": "deviceFamily",
                    "relationalOperator": "EQ",
                    "description": "deviceFamily",
                    "createTime": "14-Aug-2020 03:00:20",
                    "lastUpdateTime": "14-Aug-2020 03:00:20",
                    "valueDTOList": [
                        {
                            "bindingExtendedUuid": "a8503d7f-c465-4026-8886-b4e999a12fa7",
                            "value": "[\"Routers\",\"Switches and Hubs\",\"Voice and Telephony\"]"
                        }
                    ]
                }
            ],
            "isActive": true,
            "executionSequence": 15,
            "createdBy": "system",
            "createTime": "2020-08-14 03:00:22.554",
            "updatedBy": "system",
            "lastUpdateTime": "2020-08-14 03:00:22.554",
            "validatorType": "XDE_RUNNER",
            "fixMeUrl": null,
            "description": "Validate startup config on device",
            "cli": null,
            "systemDefined": true
        },
        {
            "bindingUuid": "b8c4d9b4-897d-4498-ad66-ad2fddc381a0",
            "uniqueId": "f3181d5c-c81d-4a1b-8dce-ba781c37a15e",
            "validatorUuid": "7b1bef9c-4bb7-4a40-813b-1d34be6c8b7a",
            "validatorName": "Spanning Tree Summary Check",
            "operationUuid": "c7a9415b-0e71-4be5-ac31-58914bf2f9ca",
            "operationName": "Activate",
            "typeUuid": "eb968006-4b5a-44e5-b065-a5feee0d781e",
            "typeName": "POST",
            "selectionCriterias": [
                {
                    "selectionCriteriaUuid": "006b1c76-7404-4b22-abf3-352698a3a358",
                    "selectionCriteriaName": "software_type",
                    "relationalOperator": "EQ",
                    "description": "software_type",
                    "createTime": "14-Aug-2020 03:01:16",
                    "lastUpdateTime": "14-Aug-2020 03:01:16",
                    "valueDTOList": [
                        {
                            "bindingExtendedUuid": "987354e3-1321-46fd-abff-18725b035e0a",
                            "value": "[\"IOS-XE\",\"IOS\"]"
                        }
                    ]
                },
                {
                    "selectionCriteriaUuid": "9273cd9b-0ce8-4baf-a790-ee5f3f5d81b8",
                    "selectionCriteriaName": "deviceFamily",
                    "relationalOperator": "EQ",
                    "description": "deviceFamily",
                    "createTime": "14-Aug-2020 03:00:20",
                    "lastUpdateTime": "14-Aug-2020 03:00:20",
                    "valueDTOList": [
                        {
                            "bindingExtendedUuid": "6e29c72d-72df-4111-b0ac-5225a47ddf26",
                            "value": "[\"Routers\",\"Switches and Hubs\"]"
                        }
                    ]
                }
            ],
            "isActive": true,
            "executionSequence": 1,
            "createdBy": "system",
            "createTime": "2020-08-14 03:01:19.938",
            "updatedBy": "system",
            "lastUpdateTime": "2020-08-14 03:01:19.938",
            "validatorType": "SCRIPT",
            "fixMeUrl": null,
            "description": "Validate spanning tree config on device. CLI: show spanning-tree summary",
            "cli": null,
            "systemDefined": true
        },
        {
            "bindingUuid": "4c278ae9-7338-4694-bef1-b092fa023601",
            "uniqueId": "32bdfd2c-b1ef-473b-8cdd-a9e09677fbcd",
            "validatorUuid": "5ec17523-3131-400c-a96a-909ee2e2c79a",
            "validatorName": "CDP neighbors Check",
            "operationUuid": "c7a9415b-0e71-4be5-ac31-58914bf2f9ca",
            "operationName": "Activate",
            "typeUuid": "eb968006-4b5a-44e5-b065-a5feee0d781e",
            "typeName": "POST",
            "selectionCriterias": [
                {
                    "selectionCriteriaUuid": "006b1c76-7404-4b22-abf3-352698a3a358",
                    "selectionCriteriaName": "software_type",
                    "relationalOperator": "EQ",
                    "description": "software_type",
                    "createTime": "14-Aug-2020 03:01:16",
                    "lastUpdateTime": "14-Aug-2020 03:01:16",
                    "valueDTOList": [
                        {
                            "bindingExtendedUuid": "6befd091-b1e8-494d-a888-99b23a4d3c9c",
                            "value": "[\"IOS-XE\",\"IOS\"]"
                        }
                    ]
                },
                {
                    "selectionCriteriaUuid": "9273cd9b-0ce8-4baf-a790-ee5f3f5d81b8",
                    "selectionCriteriaName": "deviceFamily",
                    "relationalOperator": "EQ",
                    "description": "deviceFamily",
                    "createTime": "14-Aug-2020 03:00:20",
                    "lastUpdateTime": "14-Aug-2020 03:00:20",
                    "valueDTOList": [
                        {
                            "bindingExtendedUuid": "c05e54d9-1e32-49c0-830d-4f41a3c41447",
                            "value": "[\"Routers\",\"Switches and Hubs\"]"
                        }
                    ]
                }
            ],
            "isActive": true,
            "executionSequence": 2,
            "createdBy": "system",
            "createTime": "2020-08-14 03:01:17.539",
            "updatedBy": "system",
            "lastUpdateTime": "2020-08-14 03:01:17.539",
            "validatorType": "SCRIPT",
            "fixMeUrl": null,
            "description": "Validate status of connected neighbors. CLI: show cdp neighbors",
            "cli": null,
            "systemDefined": true
        },
        {
            "bindingUuid": "6659ad2f-d703-448e-9c79-6e3a9083af85",
            "uniqueId": "a1062939-e35d-4746-9570-199ef9d2bf89",
            "validatorUuid": "286bfdef-7db5-4940-aaa3-27b232711f1a",
            "validatorName": "Interface Check",
            "operationUuid": "c7a9415b-0e71-4be5-ac31-58914bf2f9ca",
            "operationName": "Activate",
            "typeUuid": "eb968006-4b5a-44e5-b065-a5feee0d781e",
            "typeName": "POST",
            "selectionCriterias": [
                {
                    "selectionCriteriaUuid": "006b1c76-7404-4b22-abf3-352698a3a358",
                    "selectionCriteriaName": "software_type",
                    "relationalOperator": "EQ",
                    "description": "software_type",
                    "createTime": "14-Aug-2020 03:01:16",
                    "lastUpdateTime": "14-Aug-2020 03:01:16",
                    "valueDTOList": [
                        {
                            "bindingExtendedUuid": "eeccf3c5-979d-4fc6-9082-3a830fce7e56",
                            "value": "[\"IOS-XE\",\"IOS\"]"
                        }
                    ]
                },
                {
                    "selectionCriteriaUuid": "9273cd9b-0ce8-4baf-a790-ee5f3f5d81b8",
                    "selectionCriteriaName": "deviceFamily",
                    "relationalOperator": "EQ",
                    "description": "deviceFamily",
                    "createTime": "14-Aug-2020 03:00:20",
                    "lastUpdateTime": "14-Aug-2020 03:00:20",
                    "valueDTOList": [
                        {
                            "bindingExtendedUuid": "63dbf69c-bc4f-4f5a-8ec0-8b38033802d8",
                            "value": "[\"Routers\",\"Switches and Hubs\"]"
                        }
                    ]
                }
            ],
            "isActive": true,
            "executionSequence": 3,
            "createdBy": "system",
            "createTime": "2020-08-14 03:01:16.741",
            "updatedBy": "system",
            "lastUpdateTime": "2020-08-14 03:01:16.741",
            "validatorType": "SCRIPT",
            "fixMeUrl": null,
            "description": "Validate status of interfaces. CLI: show ip interface brief",
            "cli": null,
            "systemDefined": true
        },
        {
            "bindingUuid": "b79edb82-d549-4a8a-a77a-6ea93e7f1bfe",
            "uniqueId": "ad9cc700-e82b-4731-9126-b73102c7196e",
            "validatorUuid": "f6c60f2c-c2bf-4186-a06b-34703790eed0",
            "validatorName": "Startup config check",
            "operationUuid": "c7a9415b-0e71-4be5-ac31-58914bf2f9ca",
            "operationName": "Activate",
            "typeUuid": "eb968006-4b5a-44e5-b065-a5feee0d781e",
            "typeName": "POST",
            "selectionCriterias": [
                {
                    "selectionCriteriaUuid": "9273cd9b-0ce8-4baf-a790-ee5f3f5d81b8",
                    "selectionCriteriaName": "deviceFamily",
                    "relationalOperator": "EQ",
                    "description": "deviceFamily",
                    "createTime": "14-Aug-2020 03:00:20",
                    "lastUpdateTime": "14-Aug-2020 03:00:20",
                    "valueDTOList": [
                        {
                            "bindingExtendedUuid": "193702aa-925d-4286-aadd-0690ce416a8b",
                            "value": "[\"Routers\",\"Switches and Hubs\",\"Voice and Telephony\"]"
                        }
                    ]
                }
            ],
            "isActive": true,
            "executionSequence": 14,
            "createdBy": "system",
            "createTime": "2020-08-14 03:00:23.253",
            "updatedBy": "system",
            "lastUpdateTime": "2020-08-14 03:00:23.253",
            "validatorType": "XDE_RUNNER",
            "fixMeUrl": null,
            "description": "Validate startup config on device",
            "cli": null,
            "systemDefined": true
        }
    ],

    //Now option in image upgrade
    "REQUEST_FORMAT": [
        {
            "description":"Distribute and Activate on $DevName",
            "startTime":-1, "timeZone":"Asia/Calcutta",
            "externalSchedule":{
                "notificationHeader":{"Content-Type":"application/json"},
                "notificationURL":"/api/v1/image/activation/device",
                "notificationBody":[
                    {
                        "deviceUuid":"$DevId",
                        "distributionLocation":"flash",
                        "activateLowerImageVersion":true,
                        "distributeIfNeeded":true,
                        "rommonRequired":false,
                        "hasUserSelectedPostActivationFlashCleanup":false,
                        "activateValidationBindingUuidList":[
                            "f4985df8-7cc5-4387-bb3c-bcd37326f0a8","b8c4d9b4-897d-4498-ad66-ad2fddc381a0",
                            "27065249-9be0-4557-971b-f38d8e80ac22","4c278ae9-7338-4694-bef1-b092fa023601",
                            "7c4eb8bf-569b-414f-b413-836d0bab4a86","6659ad2f-d703-448e-9c79-6e3a9083af85",
                            "305748c5-0785-463f-8ce3-1fa3453836f9","ff000f8d-b378-4383-a148-8b2c3a55b348",
                            "b79edb82-d549-4a8a-a77a-6ea93e7f1bfe"
                        ],
                        "distributeValidationBindingUuidList":["4bcaac0c-874b-42c5-89a3-8422ef4b7eee"],
                        "hasUserSelectedIssuUpgrade":false,
                        "isBulkFailedRetryFlow":false
                    }
                ],
                "notificationMethod":"POST",
                "module":"OS_UPDATE"
            }
        }
    ]

});
