define([
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/database/SimLokiDatabaseActions',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/workflow/WorkFlowStatusTemplate',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/workflow/WorkFlowTemplate',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/utilities/UtilityFunctions',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/udn/udnSummaryTemplate',
], function (SimLokiDatabaseActions, WorkFlowStatusTemplate, WorkFlowTemplate, UtilityFunctions, udnSummaryTemplate) {

    return {
        init: function() {
            createUpnDeviceInfo();
        },

        doProcessRestRequest: function(urlAction){
            var data;
            switch(urlAction.method) {
                case 'GET':
                    data = processGetRequest(urlAction);
                    break;

                case 'POST':
                    data = processPostRequest(urlAction);
                    break;

                case 'PUT':
                    break;

                case 'DELETE':
                    break;

                default:
                    break;
            }

            return data;
        }
     };

    function processGetRequest(urlAction){
        if(urlAction.service.indexOf('workflow-status') >= 0 ){
            if(urlAction.action.id != '') {
                var data = JSON.parse(JSON.stringify(WorkFlowStatusTemplate.WorkFlow_Status_Template));
                return data;
            }
        } else if(urlAction.service.indexOf('workflow') > -1 && urlAction.service.indexOf('TASK_MAJOR') > -1) {
            //Replace device workflow - In Progress for 3 min
            var simulationDuration = 180000;
            var records = SimLokiDatabaseActions.getFilteredRecordHandler('replace-device-workflow', {'workflow.id':urlAction.action.id});
            if(records.length > 0) {
                var chkTime = records[0].workflow[0].timestamp;
                chkTime += simulationDuration;
                if(UtilityFunctions.getTimeStamp() > chkTime) {
                    var steps = records[0].workflow[0].steps, k=0;
                    for(var i=0; i<steps.length; i++) {
                        for(var j=0; j<steps[i].tasks.length; j++) {
                            steps[i].tasks[j].targets[0].targetStatus = "SUCCESS";
                            if(steps[i].tasks[j].targets[0].statusMessage_base) steps[i].tasks[j].targets[0].statusMessage = steps[i].tasks[j].targets[0].statusMessage_base;
                            delete steps[i].tasks[j].targets[0].statusMessage_base;
                        }
                    }
                    records[0].workflow[0].steps = steps;
                    SimLokiDatabaseActions.update('replace-device-workflow', records);
                }
                return records[0];
            }
            return {};//should ideally not come here
        }
        //workflow from show task status in fabric
        if(urlAction.service.indexOf('orchestration-engine') >= 0 ){
                var workflowId = urlAction.filter['workflowId']
                var fabricDevice = 'BLD1-FLR2-ACCESS';
                var data = JSON.stringify(WorkFlowTemplate.WorkFlow_Template);
                var deviceIdRec = SimLokiDatabaseActions.getFilteredRecordHandler('DeviceConfigStatus', {'workflowId': workflowId});
                var deviceid = '';
                if(deviceIdRec.length>0) {
                    deviceid = deviceIdRec[0].deviceId;
                    data = data.replace(/Device@ToChange/g, deviceIdRec[0].hostname)
                } else {
                    data = data.replace(/Device@ToChange/g, fabricDevice);
                }
                data = JSON.parse(data)
                data.id = workflowId;
                for(var i=0;i<data.steps.length; i++){
	                if(data.steps[i].name == 'Cfs-Aggregator-Step'){
	                data.steps[i].tasks[0].targets[0].childWorkflows[0].parentWorkflow = workflowId
	                var record=data.steps[i].tasks[0].targets[0].childWorkflows[0].steps[3].tasks
                        for(let i=0; i<record.length;i++){
                            record[i].targets[0].id = deviceid!='' ? deviceid : record[i].targets[0].id;
                            var target = record[i].targets[0].userDisplayMessages
                            for(let j=0; j<target.length; j++){
                                target[j].time = new Date().getTime()
                            }
                         }
                    }
                }
             return data;
        }

        if(urlAction.service.indexOf('cisco.dna.wireless-workflows') > -1){
            let udnInfo = SimLokiDatabaseActions.getAll('udn-data');
            let tvalue  = {"upnConfig":{"structuredSSIDinfo":[],"scheduleInfo":{"scheduleOption":"","scheduleTime":"","scheduleTask":"","disableConfig":[]}}}
            if(udnInfo.length>0){
                tvalue.upnConfig.structuredSSIDinfo = udnInfo[0].upnConfig.structuredSSIDinfo
            }
            return [{"key":"PERSISTENCE-cisco.dna.provision/upn-workflow",
            "plugin":"cisco.dna.wireless-workflows",
            "value": JSON.stringify(tvalue)
            }]
        }

        if(urlAction.service.indexOf('registration') > -1 && urlAction.service.indexOf('status') > -1) {
            return {
                "registered": true,
                "member_id": "62619eb03c6088090fda2bf9",
                "cloud_url": "https://www.ciscoconnectdna.com",
                "oauth_connectivity_status": "connected",
                "cdna_connectivity_status": "connected",
                "registration_mode": "otp",
                "proxy_configuration_complete": true
               }  
        }
    }

    function processPostRequest(urlAction){
        if(urlAction.service.indexOf('cisco.dna.wireless-workflows') > -1){
            let tvalue = JSON.parse(urlAction.restPayload.value);
            let udnInfo = SimLokiDatabaseActions.getAll("udn-data");
            if(udnInfo.length>0){
                SimLokiDatabaseActions.delete("udn-data",udnInfo[0]);
            }
            SimLokiDatabaseActions.insert("udn-data",tvalue);

            return {
              "_id": "5f03232e1888f898d929ded2",
              "key": "PERSISTENCE-cisco.dna.provision/upn-workflow",
              "plugin": "cisco.dna.wireless-workflows",
              "user": "udn",
              "__v": 0,
              "value": urlAction.restPayload.value
            }
        }

    }

    function createUpnDeviceInfo(){
        //on intial loading it shows provisioned site-Global/North America/USA/California/San Jose/SJC01
		let device = SimLokiDatabaseActions.getFilteredRecordHandler('network-device',{'hostname':'WLC-9800'});
		let siteInfo =  SimLokiDatabaseActions.getFilteredRecordHandler('site',{'id': device[0].siteId});
        let Json = JSON.parse(JSON.stringify(udnSummaryTemplate.Template))
        Json.upnConfig.structuredSSIDinfo[0].siteId = device[0].siteId;
        Json.upnConfig.structuredSSIDinfo[0].siteName = siteInfo[0].groupNameHierarchy;
        Json.upnConfig.structuredSSIDinfo[0].devices[0].deviceName = device[0].hostname;
        Json.upnConfig.structuredSSIDinfo[0].devices[0].deviceId = device[0].id;
        Json.upnConfig.scheduleInfo.scheduleTime = new Date();
        SimLokiDatabaseActions.insert('udn-data',Json);
    }


});
