define({
  "Template": {
    request: {
      key: "microsoft-teams",
      type: "Application",
      label: "microsoft-teams",
      collection: false,
      componentContent: [
        {
          component: "fusion",
          additional: "49cbc4de-b44c-48d5-aad9-8b51161f287c",
          origType: "Application",
        },
      ],
    },
    data: {
      response: {
        id: "49cbc4de-b44c-48d5-aad9-8b51161f287c",
        instanceId: 5844,
        instanceCreatedOn: 1657037696506,
        instanceUpdatedOn: 1657037696506,
        instanceVersion: 0,
        createTime: 1657037684604,
        deployed: false,
        isSeeded: true,
        isStale: false,
        lastUpdateTime: 1657037684604,
        name: "ms-teams",
        namespace: "scalablegroup:application",
        provisioningState: "DEFINED",
        qualifier: "application",
        resourceVersion: 0,
        targetIdList: [],
        type: "scalablegroup",
        cfsChangeInfo: [],
        customProvisions: [],
        isDeleted: false,
        iseReserved: false,
        mdnacDeletionStatus: "ACTIVE",
        propagateToAci: false,
        pushed: false,
        scalableGroupExternalHandle: "ms-teams",
        scalableGroupType: "APPLICATION",
        securityGroupTag: 0,
        state: "ACTIVE",
        vnAgnostic: true,
        defaultContracts: [],
        identitySource: {
          id: "551bc1e2-bcde-46b3-9dff-9053ef4d272b",
          instanceId: 14014,
          instanceCreatedOn: 1657037668944,
          instanceUpdatedOn: 1657037668944,
          instanceVersion: 0,
          state: "INACTIVE",
          type: "NBAR",
          displayName: "14014",
        },
        indicativeNetworkIdentity: [],
        networkApplications: [
          {
            id: "0f08f0de-6fd1-48ee-9df9-9674f02a7225",
            instanceId: 16794,
            instanceCreatedOn: 1657037696506,
            instanceUpdatedOn: 1657037696506,
            instanceVersion: 0,
            appProtocol: "tcp/udp",
            applicationGroup: "ms-cloud-group",
            applicationSubType: "NONE",
            applicationType: "DEFAULT",
            categoryId: "0adcf602-f1ac-4f02-b84c-25cc1aa528ed",
            createTime: 1657037684604,
            encrypted: "true",
            engineId: "13",
            helpString:
              "A platform that combines workplace chat, meetings, notes, and attachments",
            ignoreConflict: false,
            lastUpdateTime: 1657037684604,
            longDescription:
              "Microsoft Teams is a platform that combines workplace chat, meetings, notes, and attachments.                       The service integrates with the company's Office 365 subscription office productivity suite,                       including Microsoft Office and Skype.",
            name: "ms-teams",
            nbarId: "1208",
            p2pTechnology: "true",
            popularity: 7,
            rank: 65535,
            references:
              "https://products.office.com/en-us/microsoft-teams/group-chat-software",
            selectorId: "1208",
            serverNames: [],
            subCategory: "5aee80d3-0ef6-40c3-9c4b-9d2f46b904ea",
            trafficClass: "MULTIMEDIA_CONFERENCING",
            tunnel: "false",
            displayName: "16794",
          },
        ],
        networkIdentity: [
          {
            id: "8605df5e-a2d5-4097-b7a7-d0c6f483979c",
            instanceId: 19083,
            instanceCreatedOn: 1657037696506,
            instanceUpdatedOn: 1657037696506,
            instanceVersion: 0,
            ipv4Subnet: [],
            ipv6Subnet: [],
            lowerPort: 0,
            ports: "53,5353",
            protocol: "UDP",
            upperPort: 0,
            displayName: "19083",
          },
          {
            id: "046304db-1073-476a-b276-0190b630ea5f",
            instanceId: 19084,
            instanceCreatedOn: 1657037696506,
            instanceUpdatedOn: 1657037696506,
            instanceVersion: 0,
            ipv4Subnet: [],
            ipv6Subnet: [],
            lowerPort: 0,
            ports: "53,80,443,5353",
            protocol: "TCP",
            upperPort: 0,
            displayName: "19084",
          },
        ],
        parentScalableGroup: {
          idRef: "579eb909-1c37-4515-abce-59b77638b6eb",
        },
        user: [],
        userGroup: [],
        displayName: "5844",
      },
    },
    result: [
      {
        bookID: {
          namespace: "fusion",
          name: "core",
          version: "1.0.1",
        },
        response: {
          id: "49cbc4de-b44c-48d5-aad9-8b51161f287c",
          instanceId: 5844,
          instanceCreatedOn: 1657037696506,
          instanceUpdatedOn: 1657037696506,
          instanceVersion: 0,
          createTime: 1657037684604,
          deployed: false,
          isSeeded: true,
          isStale: false,
          lastUpdateTime: 1657037684604,
          name: "ms-teams",
          namespace: "scalablegroup:application",
          provisioningState: "DEFINED",
          qualifier: "application",
          resourceVersion: 0,
          targetIdList: [],
          type: "scalablegroup",
          cfsChangeInfo: [],
          customProvisions: [],
          isDeleted: false,
          iseReserved: false,
          mdnacDeletionStatus: "ACTIVE",
          propagateToAci: false,
          pushed: false,
          scalableGroupExternalHandle: "ms-teams",
          scalableGroupType: "APPLICATION",
          securityGroupTag: 0,
          state: "ACTIVE",
          vnAgnostic: true,
          defaultContracts: [],
          identitySource: {
            id: "551bc1e2-bcde-46b3-9dff-9053ef4d272b",
            instanceId: 14014,
            instanceCreatedOn: 1657037668944,
            instanceUpdatedOn: 1657037668944,
            instanceVersion: 0,
            state: "INACTIVE",
            type: "NBAR",
            displayName: "14014",
          },
          indicativeNetworkIdentity: [],
          networkApplications: [
            {
              id: "0f08f0de-6fd1-48ee-9df9-9674f02a7225",
              instanceId: 16794,
              instanceCreatedOn: 1657037696506,
              instanceUpdatedOn: 1657037696506,
              instanceVersion: 0,
              appProtocol: "tcp/udp",
              applicationGroup: "ms-cloud-group",
              applicationSubType: "NONE",
              applicationType: "DEFAULT",
              categoryId: "0adcf602-f1ac-4f02-b84c-25cc1aa528ed",
              createTime: 1657037684604,
              encrypted: "true",
              engineId: "13",
              helpString:
                "A platform that combines workplace chat, meetings, notes, and attachments",
              ignoreConflict: false,
              lastUpdateTime: 1657037684604,
              longDescription:
                "Microsoft Teams is a platform that combines workplace chat, meetings, notes, and attachments.                       The service integrates with the company's Office 365 subscription office productivity suite,                       including Microsoft Office and Skype.",
              name: "ms-teams",
              nbarId: "1208",
              p2pTechnology: "true",
              popularity: 7,
              rank: 65535,
              references:
                "https://products.office.com/en-us/microsoft-teams/group-chat-software",
              selectorId: "1208",
              serverNames: [],
              subCategory: "5aee80d3-0ef6-40c3-9c4b-9d2f46b904ea",
              trafficClass: "MULTIMEDIA_CONFERENCING",
              tunnel: "false",
              displayName: "16794",
            },
          ],
          networkIdentity: [
            {
              id: "8605df5e-a2d5-4097-b7a7-d0c6f483979c",
              instanceId: 19083,
              instanceCreatedOn: 1657037696506,
              instanceUpdatedOn: 1657037696506,
              instanceVersion: 0,
              ipv4Subnet: [],
              ipv6Subnet: [],
              lowerPort: 0,
              ports: "53,5353",
              protocol: "UDP",
              upperPort: 0,
              displayName: "19083",
            },
            {
              id: "046304db-1073-476a-b276-0190b630ea5f",
              instanceId: 19084,
              instanceCreatedOn: 1657037696506,
              instanceUpdatedOn: 1657037696506,
              instanceVersion: 0,
              ipv4Subnet: [],
              ipv6Subnet: [],
              lowerPort: 0,
              ports: "53,80,443,5353",
              protocol: "TCP",
              upperPort: 0,
              displayName: "19084",
            },
          ],
          parentScalableGroup: {
            idRef: "579eb909-1c37-4515-abce-59b77638b6eb",
          },
          user: [],
          userGroup: [],
          displayName: "5844",
        },
      },
    ],
  },
});
