define({
"Template":
{
  "version": "1.0",
  "response": {
    "apList": [
      {
        "apMac": "08:4f:f9:2e:db:e0",
        "apName": "AP1800S_Sensor_01",
        "deviceType": "C9120AXI-B",
        "numTest": 5358,
        "numFailedTest": 9,
        "location": "Global/San Francisco/One Bush Plaza/Floor 13"
      },
      {
        "apMac": "08:4f:f9:2f:95:00",
        "apName": "AP1800S_Sensor_02",
        "deviceType": "C9120AXI-B",
        "numTest": 4675,
        "numFailedTest": 4,
        "location": "Global/San Francisco/One Bush Plaza/Floor 13"
      },
      {
        "apMac": "f4:db:e6:87:21:60",
        "apName": "AP1800S_Sensor_03",
        "deviceType": "AIR-AP4800-B-K9",
        "numTest": 4305,
        "numFailedTest": 0,
        "location": "Global/San Francisco/One Bush Plaza/Floor 13"
      },
      {
        "apMac": "f4:db:e6:86:f5:e0",
        "apName": "AP1800S_Sensor_04",
        "deviceType": "AIR-AP4800-B-K9",
        "numTest": 12,
        "numFailedTest": 0,
        "location": "Global/San Francisco/One Bush Plaza/Floor 13"
      }
    ]
  }
}
})