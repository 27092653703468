define([
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/database/SimLokiDatabaseActions',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/utilities/UtilityFunctions',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/data-generation/configuration/SimTaskDataGenerator',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/underlay/LanAutomationTemplate',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/data-generation/services/SimNetworkDeviceData',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/data-generation/configuration/SimDefaultDataGenerator',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/data-generation/services/SimTopologyData',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/data-generation/services/swim/SimSwimData'
 ], function(SimLokiDatabaseActions, UtilityFunctions, SimTaskDataGenerator, LanAutomationTemplate,
       SimNetworkDeviceData, SimDefaultDataGenerator, SimTopologyData, SimSwimData){

 return {
      init: function(){
      },

      createLanAutomation:function (urlAction) {
        if(JSON.stringify(urlAction.restPayload).includes('autoDiscoveryStatus":"disabled"'))          
        {
          window.localStorage.setItem('SimLanAutomationStatus', 'STOP')
          return []

        }
        
          // cleaning the previous record
          var tJson = SimLokiDatabaseActions.getAll("lan-network-orch-log");
          // SimLokiDatabaseActions.delete('lan-network-orch-log', tJson);

          var  tJson = SimLokiDatabaseActions.getAll("lan-network-orch-status");
          // SimLokiDatabaseActions.delete('lan-network-orch-status', tJson);

          var  tJson = SimLokiDatabaseActions.getAll("commonsetting-lan");
          // SimLokiDatabaseActions.delete('commonsetting-lan', tJson);
          var resultObj = SimTaskDataGenerator.createTask('Common Settings Service');
          var data =urlAction.restPayload;
          data = Array.isArray(data) ? data[0]: data;          
          data.instanceUuid = UtilityFunctions.generate_uuid();
          data.inheritedGroupName = "", data.inheritedGroupUuid = "";
          SimLokiDatabaseActions.insert( 'commonsetting-lan', data );
          var networkOrchId  = UtilityFunctions.generate_uuid();
          var tJson = JSON.parse(JSON.stringify(LanAutomationTemplate.Network_Orchestration_Template));
          
          var primarySeedDevice = SimLokiDatabaseActions.getFilteredRecordHandler('network-device',{'id':urlAction.restPayload.primarySeedDevice});
          var siteData = SimLokiDatabaseActions.getFilteredRecordHandler('site',{'id':urlAction.restPayload.siteId});
          var devicePrefix = data.value ? data.value[0].namingPrefix : data.namingPrefix;
          tJson.id = networkOrchId;
          
          tJson.primarySeedDeviceName = primarySeedDevice[0].name;
          tJson.siteName = siteData[0].groupNameHierarchy;

          tJson.primarySeedDevice = data.value ? data.value[0].primarySeedDevice : data.primarySeedDevice;
          tJson.secondarySeedDevice = data.value? data.value[0].secondarySeedDevice: data.secondarySeedDevice;
          tJson.ipPoolList = data.value ? data.value.ipPools: data.ipPools;
          tJson.ipPools = data.value ? data.value.ipPools: data.ipPools;
          var ipPoolData = SimLokiDatabaseActions.getFilteredRecordHandler('ippool',{'id':tJson.ipPools[0].ipPoolGroupId})
          tJson.ipPoolList[0].ipPoolName = ipPoolData[0].ipPoolName;
          tJson.ipPools[0].ipPoolName = ipPoolData[0].ipPoolName;
          tJson.discoveredDevicesCount = 0;
          tJson.provisionedDevicesCount = 0;

          tJson.primaryInterfaceList = data.value ? data.value[0].interfaces: data.interfaces;
          tJson.siteId =data.groupUuid ? data.groupUuid : data.siteId;
          tJson.creationTime = UtilityFunctions.getTimeCommaFormatted(new Date().getTime());
          tJson.namingPrefix = devicePrefix;

          var networkDeviceId = UtilityFunctions.generate_uuid();
          var pnpDeviceId = UtilityFunctions.generate_uuid();

          //adding device
          this.discoverDevice(tJson,networkDeviceId,devicePrefix,networkOrchId);
          var deviceRecord = SimLokiDatabaseActions.getFilteredRecordHandler('network-device', {'id': networkDeviceId});
          tJson.discoveredDeviceList.push({
                            "name": deviceRecord[0].hostname,
                            "serialNumber":deviceRecord[0].serialNumber,
                            "ipAddress" : deviceRecord[0].managementIpAddress,
                            "pnpDevice": networkDeviceId,
                            "inventoryDevice":networkDeviceId,
                            "state":"ADDED_TO_INVENTORY",
                            "managementIpAddress" : deviceRecord[0].managementIpAddress,
                            "multicastEnabled": "False",
                            "nwOrchId" : networkOrchId,
                            "order": 1,
                            "platform" : deviceRecord[0].platformId,
                            "siteId": deviceRecord[0].siteId
                            });

          SimLokiDatabaseActions.insert('lan-network-orch-status', tJson);

          SimTaskDataGenerator.updateTaskCompletion(resultObj.taskId, {isError: false });
          //Task 
          resultObj = {
            "endTime": 1686832848243,
            "startTime": UtilityFunctions.getTimeCommaFormatted(new Date().getTime()),
            "version": 1686832848243,
            "progress": "\"LAN Automation start request accepted\"",
            "data": "nwOrchId:"+networkOrchId,
            "isError": false,
            "serviceType": "NCSU",
            "username": "admin",
            "instanceTenantId": "5f35ea5f15de5300c6a0162a",
            "id": resultObj.taskId
        }
          return resultObj;        
      },

      discoverDevice :function(tJson,networkDeviceId,devicePrefix,networkOrchId) {
          // get the device record

          var deviceRecord = SimLokiDatabaseActions.getFilteredRecordHandler('network-device',{'id':tJson.primarySeedDevice});

          //getting the ippools record
          var ipPoolRecord = SimLokiDatabaseActions.getFilteredRecordHandler('ippool', {'id': tJson.ipPoolList[0].ipPoolGroupId});

          var ipPoolParent =SimLokiDatabaseActions.getFilteredRecordHandler('ippool', {'id': ipPoolRecord[0].parentUuid});

          var ipAddress = ipPoolParent[0].gateways[0];
          var ipAddressArray = ipAddress.split(".");
          ipAddressArray[3] =parseInt(ipAddressArray[3])+5;
          var newIpAddress =ipAddressArray.join(".");
          ipAddressArray[3] =parseInt(ipAddressArray[3])-1;
          var loopbackAddress = ipAddressArray.join(".");
          
          //add device to inventory
          var deviceDetails = SimDefaultDataGenerator.inventoryDevice(deviceRecord[0],newIpAddress,networkDeviceId,devicePrefix);
          SimNetworkDeviceData.addDevice(deviceDetails);

          //image assignment to device
          deviceDetails[0].softwareVersion = deviceDetails[0].swversion;
          deviceDetails[0].platformId = deviceDetails[0].platformid;
          SimSwimData.manualAssignmentDeviceToimage(deviceDetails[0]);

          //SimTopologyData.addDevice(tJson.primarySeedDevice,deviceDetails); //if this right - not sure of functionality?
          //device should be added to the site which is chosen or the primary seed device's location?
          if(tJson.siteId)  {
            SimNetworkDeviceData.UpdateSiteonDevices(tJson.siteId, [networkDeviceId]);
            if(deviceRecord[0].siteId == tJson.siteId) {
              SimTopologyData.addDevice(tJson.primarySeedDevice,deviceDetails);
            } else {
              SimTopologyData.addDevice(undefined,deviceDetails);
            }
          }
          //log
          var tJsonLog = JSON.parse(JSON.stringify(LanAutomationTemplate.Network_Orchestration_Log_Template));
          tJsonLog.nwOrchId = networkOrchId;
          tJsonLog.entry = [
                           {
                              "logLevel": 2,
                              "timeStamp": UtilityFunctions.getCurrentDateAndTimeInValidFormat(),
                              "record": "Network Orchestration Queue has been cleared",
                              "deviceId": null
                            },
                            {
                              "logLevel": 1,
                              "timeStamp": UtilityFunctions.getCurrentDateAndTimeInValidFormat(),
                              "record": "Ended Network Orchestration Session",
                              "deviceId": null
                            },
                            {
                              "logLevel": 3,
                              "timeStamp": UtilityFunctions.getCurrentDateAndTimeInValidFormat(),
                              "record": "Configuring L3 interfaces for the session's Tier 2 Devices",
                              "deviceId": null
                            },
                            {
                              "logLevel": 3,
                              "timeStamp": UtilityFunctions.getCurrentDateAndTimeInValidFormat(),
                              "record": "Configuring L3 interfaces for the session's Tier 3 Devices",
                              "deviceId": null
                            },
                            {
                              "logLevel": 3,
                              "timeStamp": UtilityFunctions.getCurrentDateAndTimeInValidFormat(),
                              "record": "Ending device discovery",
                              "deviceId": null
                            },
                            { "logLevel":1,
                               "timeStamp":UtilityFunctions.getCurrentDateAndTimeInValidFormat(),
                               "record":"Started the Network Orchestration Session with primary device:"+deviceRecord[0].hostname +"",
                               "deviceId":tJson.primarySeedDevice
                            },
                            {
                              "logLevel": 1,
                              "timeStamp": UtilityFunctions.getCurrentDateAndTimeInValidFormat(),
                              "record": "Loopback address "+ loopbackAddress +" was allocated to the Primary seed device.",
                              "deviceId": ""
                            }
                        ];
          SimLokiDatabaseActions.insert('lan-network-orch-log', tJsonLog);
      }
      };
});
