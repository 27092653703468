define({
"Template":
{
  "version": "1.0",
  "response": {
    "entities": [

    ]
  }
},
"BaseTemplate":
     {
        "rootCauseName": "",
        "instance": 141,
        "devices": [
          {
            "name": "",
            "ipAddr": "",
            "macAddr": "",
            "entityType": "",
            "deviceType": "",
            "deviceOs": "",
            "occurence": 72,
            "rootCause": "",
            "site": ""
          }
        ]
      }
})