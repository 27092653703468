define([
    'core/../../../public/app/devices/dnac_sinon_sim/lib/services/cfs/models/SimCfsModels'
], function (SimCfsModels) {

    return {
        init: function () {
        },
        cfsAudit: function (url, restCallbackFunc) {
            SimCfsModels.getAudit(url, function (data) {
                var result = {"response": data, "version": "1.0"};
                restCallbackFunc(result);
            });
        },
        cfsAuditCount: function (url, restCallbackFunc) {
            SimCfsModels.getAuditCount(url, function (data) {
                var t = {"response": data, "version": "1.0"};
                restCallbackFunc(t);
            });
        },

        getCfsCount: function (url, filter, restCallbackFunc) {
            SimCfsModels.getCfsCount(url, filter, function (data) {
                var t = {"response": data, "version": "1.0"};
                restCallbackFunc(t);
            });
        },

        getCfsDetails: function (url, filter, restCallbackFunc) {
            SimCfsModels.getCfsDetails(url, filter, function (data) {
                var result = {"response": data, "version": "1.0"};
                restCallbackFunc(result);
            });
        },

        CfsPost: function (url, jsonObj, restCallbackFunc) {
            SimCfsModels.CfsPost(url, jsonObj, function (data) {
                var result = {"response": data, "version": "1.0"};
                restCallbackFunc(result);
            });
        },
        CfsPut: function (url, jsonObj, restCallbackFunc) {
            SimCfsModels.CfsPut(url, jsonObj, function (data) {
                var result = {"response": data, "version": "1.0"};
                restCallbackFunc(result);
            });
        },
        CfsDelete: function (url, restCallbackFunc) {
            SimCfsModels.CfsDelete(url, function (data) {
                var result = {"response": data, "version": "1.0"};
                restCallbackFunc(result);
            });
        },
        getDeviceConfigStatusCount: function(filter, restCallbackFunc) {
            SimCfsModels.getDeviceConfigStatusCount(filter, function(data) {
                var result = { "response" : data, "version":"1.0" };
                restCallbackFunc( result );
            });
        },

         getDeviceConfigStatus: function(filter, restCallbackFunc) {
            SimCfsModels.getDeviceConfigStatus(filter, function(data) {
                var result = { "response" : data, "version":"1.0" };
                restCallbackFunc( result );
            });
        },

        getGroupNames : function(url, filter, restCallbackFunc){
            SimCfsModels.getGroupNames(url,filter, function(data) {
                var result = { "response" : data, "version":"1.0" };
                restCallbackFunc( result );
            });
        },

        CfsIntent: function (url, jsonObj, restCallbackFunc) {
            SimCfsModels.CfsIntent(url, jsonObj, function (data) {
                var result = {"response": data, "version": "1.0"};
                restCallbackFunc(result);
            });
        },
        
        CfsIntentScalableGroup: function (url, jsonObj, restCallbackFunc) {
            SimCfsModels.CfsIntentScalableGroup(url, jsonObj, function (data) {
                var result = {"response": data, "version": "1.0"};
                restCallbackFunc(result);
            });
        },

        CfsIntentCopyPolicy: function (url, jsonObj, restCallbackFunc) {
            SimCfsModels.CfsIntentCopyPolicy(url, jsonObj, function (data) {
                var result = {"response": data, "version": "1.0"};
                restCallbackFunc(result);
            });
        },

        CfsIntentApplicationPolicy: function (url, jsonObj, restCallbackFunc) {
            SimCfsModels.CfsIntentApplicationPolicy(url, jsonObj, function (data) {
                var result = {"response": data, "version": "1.0"};
                restCallbackFunc(result);
            });
        },

        CfsSsid: function(url, restCallbackFunc) {
            SimCfsModels.CfsSsid(url, function(data) {
                var result = {
                    "response": data,
                    "version": "1.0"
                };
                restCallbackFunc(result);
            });
        },

        CfsIntentContractAccess : function(url, jsonObj, restCallbackFunc) {
            SimCfsModels.CfsIntentContractAccess(url, jsonObj, function (data) {
                var result = {"response": data, "version": "1.0"};
                restCallbackFunc(result);
            });
        }

    };
});
