define({
    "Application_Template":  [
  {
    "id": "0019bbab-9211-4663-86d0-3e857225bcde",
    "instanceId": 4698,
    "authEntityId": 4698,
    "displayName": "4698",
    "authEntityClass": -1909073334,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "createTime": 1517966743307,
    "deployed": false,
    "isSeeded": true,
    "isStale": false,
    "lastUpdateTime": 1517966743307,
    "name": "liveperson",
    "namespace": "scalablegroup:application",
    "provisioningState": "DEFINED",
    "qualifier": "application",
    "resourceVersion": 0,
    "targetIdList": [

    ],
    "type": "scalablegroup",
    "cfsChangeInfo": [

    ],
    "customProvisions": [

    ],
    "scalableGroupExternalHandle": "liveperson",
    "scalableGroupType": "APPLICATION",
    "securityGroupTag": 0,
    "state": "ACTIVE",
    "vnAgnostic": true,
    "identitySource": {
      "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
      "instanceId": 10010,
      "authEntityId": 10010,
      "displayName": "10010",
      "authEntityClass": 1738953278,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "state": "INACTIVE",
      "type": "NBAR"
    },
    "indicativeNetworkIdentity": [

    ],
    "networkApplications": [
      {
        "id": "505b4cee-1cd9-47c4-bf62-2cece8bc0c27",
        "instanceId": 11648,
        "authEntityId": 11648,
        "displayName": "11648",
        "authEntityClass": -217092956,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "appProtocol": "tcp/udp",
        "applicationGroup": "other",
        "applicationType": "DEFAULT",
        "categoryId": "878644b5-fe91-45b4-9204-31c84a73ba48",
        "createTime": 1517966743307,
        "encrypted": "false",
        "engineId": "13",
        "helpString": "LivePerson - Online Marketing and Web analytics service provider.",
        "ignoreConflict": false,
        "lastUpdateTime": 1517966743307,
        "longDescription": "LivePerson - Online Marketing and Web analytics service provider.",
        "name": "liveperson",
        "nbarId": "1729",
        "p2pTechnology": "false",
        "popularity": 3,
        "rank": 65535,
        "references": "http://liveperson.com",
        "selectorId": "801",
        "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
        "trafficClass": "TRANSACTIONAL_DATA",
        "tunnel": "false"
      }
    ],
    "networkIdentity": [
      {
        "id": "23f9f5ed-e61d-4e1e-8b8a-69bf55a27964",
        "instanceId": 13762,
        "authEntityId": 13762,
        "displayName": "13762",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "53,80,443,5353",
        "protocol": "TCP",
        "upperPort": 0
      },
      {
        "id": "f262d177-0a47-4bd2-94cd-ac1bb8b29887",
        "instanceId": 13763,
        "authEntityId": 13763,
        "displayName": "13763",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "53,5353",
        "protocol": "UDP",
        "upperPort": 0
      }
    ],
    "parentScalableGroup": {
      "idRef": "28e08de4-d7b2-4521-84ce-bc1e5ee0b9cc"
    },
    "user": [

    ],
    "userGroup": [

    ]
  },
  {
    "id": "002060a3-963b-46f5-9629-01ec9e2421cb",
    "instanceId": 4124,
    "authEntityId": 4124,
    "displayName": "4124",
    "authEntityClass": -1909073334,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "createTime": 1517966741246,
    "deployed": false,
    "isSeeded": true,
    "isStale": false,
    "lastUpdateTime": 1517966741246,
    "name": "asa-appl-proto",
    "namespace": "scalablegroup:application",
    "provisioningState": "DEFINED",
    "qualifier": "application",
    "resourceVersion": 0,
    "targetIdList": [

    ],
    "type": "scalablegroup",
    "cfsChangeInfo": [

    ],
    "customProvisions": [

    ],
    "scalableGroupExternalHandle": "asa-appl-proto",
    "scalableGroupType": "APPLICATION",
    "securityGroupTag": 0,
    "state": "ACTIVE",
    "vnAgnostic": true,
    "identitySource": {
      "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
      "instanceId": 10010,
      "authEntityId": 10010,
      "displayName": "10010",
      "authEntityClass": 1738953278,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "state": "INACTIVE",
      "type": "NBAR"
    },
    "indicativeNetworkIdentity": [
      {
        "id": "e00e3c07-82f8-4d8d-95a0-262393fc5135",
        "instanceId": 12229,
        "authEntityId": 12229,
        "displayName": "12229",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "502",
        "protocol": "TCP",
        "upperPort": 0
      },
      {
        "id": "5938d556-1147-4d46-b59b-82dcd902da15",
        "instanceId": 12230,
        "authEntityId": 12230,
        "displayName": "12230",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "502",
        "protocol": "UDP",
        "upperPort": 0
      }
    ],
    "networkApplications": [
      {
        "id": "7c7654e8-0a13-4ff3-8818-bafe39729e67",
        "instanceId": 11086,
        "authEntityId": 11086,
        "displayName": "11086",
        "authEntityClass": -217092956,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "appProtocol": "tcp/udp",
        "applicationGroup": "other",
        "applicationType": "DEFAULT",
        "categoryId": "6daa3dfd-3526-407a-88f0-97824b3f3a24",
        "createTime": 1517966741246,
        "encrypted": "false",
        "engineId": "3",
        "helpString": "asa-appl-proto",
        "ignoreConflict": false,
        "lastUpdateTime": 1517966741246,
        "longDescription": "Registered with IANA on port 502 TCP/UDP",
        "name": "asa-appl-proto",
        "nbarId": "416",
        "p2pTechnology": "false",
        "popularity": 1,
        "rank": 65535,
        "references": "http://www.iana.org/assignments/service-names-port-numbers/service-names-port-numbers.xml",
        "selectorId": "502",
        "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
        "trafficClass": "BULK_DATA",
        "tunnel": "false"
      }
    ],
    "networkIdentity": [
      {
        "id": "00fed56b-eec2-4a90-b949-49ab7893248d",
        "instanceId": 12227,
        "authEntityId": 12227,
        "displayName": "12227",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "502",
        "protocol": "UDP",
        "upperPort": 0
      },
      {
        "id": "13d8fae1-b223-4d22-aa84-011d1502db19",
        "instanceId": 12228,
        "authEntityId": 12228,
        "displayName": "12228",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "502",
        "protocol": "TCP",
        "upperPort": 0
      }
    ],
    "parentScalableGroup": {
      "idRef": "000c15bf-28fe-4fbb-9b69-35e6502721f1"
    },
    "user": [

    ],
    "userGroup": [

    ]
  },
  {
    "id": "00256f79-9c0d-4600-bafa-2e7edb7b7060",
    "instanceId": 4125,
    "authEntityId": 4125,
    "displayName": "4125",
    "authEntityClass": -1909073334,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "createTime": 1517966741249,
    "deployed": false,
    "isSeeded": true,
    "isStale": false,
    "lastUpdateTime": 1517966741249,
    "name": "asip-webadmin",
    "namespace": "scalablegroup:application",
    "provisioningState": "DEFINED",
    "qualifier": "application",
    "resourceVersion": 0,
    "targetIdList": [

    ],
    "type": "scalablegroup",
    "cfsChangeInfo": [

    ],
    "customProvisions": [

    ],
    "scalableGroupExternalHandle": "asip-webadmin",
    "scalableGroupType": "APPLICATION",
    "securityGroupTag": 0,
    "state": "ACTIVE",
    "vnAgnostic": true,
    "identitySource": {
      "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
      "instanceId": 10010,
      "authEntityId": 10010,
      "displayName": "10010",
      "authEntityClass": 1738953278,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "state": "INACTIVE",
      "type": "NBAR"
    },
    "indicativeNetworkIdentity": [
      {
        "id": "0de520bb-1376-4790-8859-355bdd68efd5",
        "instanceId": 12233,
        "authEntityId": 12233,
        "displayName": "12233",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "311",
        "protocol": "UDP",
        "upperPort": 0
      },
      {
        "id": "5542b903-9b3c-4a58-82bb-b938a5f60f42",
        "instanceId": 12234,
        "authEntityId": 12234,
        "displayName": "12234",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "311",
        "protocol": "TCP",
        "upperPort": 0
      }
    ],
    "networkApplications": [
      {
        "id": "5c4d6b0f-9be7-4afd-a937-36ec098e1d04",
        "instanceId": 11087,
        "authEntityId": 11087,
        "displayName": "11087",
        "authEntityClass": -217092956,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "appProtocol": "tcp/udp",
        "applicationGroup": "other",
        "applicationType": "DEFAULT",
        "categoryId": "1fc17362-9334-4c7b-b752-5afdbb574017",
        "createTime": 1517966741249,
        "encrypted": "false",
        "engineId": "3",
        "helpString": "AppleShare IP WebAdmin",
        "ignoreConflict": false,
        "lastUpdateTime": 1517966741249,
        "longDescription": "AppleShare is a product from Apple which implemented various network services such as file server, a print server, web server, electronic mail server. AppleShare IP is a version of AppleShare that supports the use of TCP/IP protocol stack. AppleShare IP WebAdmin is the remote administration service of AppleShare IP where an administrator can perform administrative operations using a web browser.",
        "name": "asip-webadmin",
        "nbarId": "1151",
        "p2pTechnology": "false",
        "popularity": 1,
        "rank": 65535,
        "references": "http://support.apple.com/kb/TA26109?viewlocale=en_US",
        "selectorId": "311",
        "subCategory": "f3c0f761-d894-4112-ab89-b90fc84fd660",
        "trafficClass": "OPS_ADMIN_MGMT",
        "tunnel": "false"
      }
    ],
    "networkIdentity": [
      {
        "id": "fc6d0d8f-3fcb-499e-bb5f-00917919a62c",
        "instanceId": 12231,
        "authEntityId": 12231,
        "displayName": "12231",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "311",
        "protocol": "UDP",
        "upperPort": 0
      },
      {
        "id": "6b3d8d47-6161-48d4-8dc8-1bb5d735a41d",
        "instanceId": 12232,
        "authEntityId": 12232,
        "displayName": "12232",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "311",
        "protocol": "TCP",
        "upperPort": 0
      }
    ],
    "parentScalableGroup": {
      "idRef": "5fcd74d4-8726-47f7-a088-c457863da7ef"
    },
    "user": [

    ],
    "userGroup": [

    ]
  },
  {
    "id": "00389ab2-bd0b-4869-84ba-d2dee960d4a6",
    "instanceId": 4294,
    "authEntityId": 4294,
    "displayName": "4294",
    "authEntityClass": -1909073334,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "createTime": 1517966742181,
    "deployed": false,
    "isSeeded": true,
    "isStale": false,
    "lastUpdateTime": 1517966742181,
    "name": "coursera",
    "namespace": "scalablegroup:application",
    "provisioningState": "DEFINED",
    "qualifier": "application",
    "resourceVersion": 0,
    "targetIdList": [

    ],
    "type": "scalablegroup",
    "cfsChangeInfo": [

    ],
    "customProvisions": [

    ],
    "scalableGroupExternalHandle": "coursera",
    "scalableGroupType": "APPLICATION",
    "securityGroupTag": 0,
    "state": "ACTIVE",
    "vnAgnostic": true,
    "identitySource": {
      "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
      "instanceId": 10010,
      "authEntityId": 10010,
      "displayName": "10010",
      "authEntityClass": 1738953278,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "state": "INACTIVE",
      "type": "NBAR"
    },
    "indicativeNetworkIdentity": [

    ],
    "networkApplications": [
      {
        "id": "14ecd13e-39bb-4293-8b34-19a3f62c22e3",
        "instanceId": 11247,
        "authEntityId": 11247,
        "displayName": "11247",
        "authEntityClass": -217092956,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "appProtocol": "tcp/udp",
        "applicationGroup": "other",
        "applicationType": "DEFAULT",
        "categoryId": "878644b5-fe91-45b4-9204-31c84a73ba48",
        "createTime": 1517966742181,
        "encrypted": "false",
        "engineId": "13",
        "helpString": "Coursera - Educational site, offer online courses from top universities.",
        "ignoreConflict": false,
        "lastUpdateTime": 1517966742181,
        "longDescription": "Coursera - Educational site, offer online courses from top universities.",
        "name": "coursera",
        "nbarId": "1695",
        "p2pTechnology": "false",
        "popularity": 3,
        "rank": 65535,
        "references": "http://coursera.com",
        "selectorId": "765",
        "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
        "trafficClass": "TRANSACTIONAL_DATA",
        "tunnel": "false"
      }
    ],
    "networkIdentity": [
      {
        "id": "1b8c1269-adcb-4022-8763-81e4b8a3d886",
        "instanceId": 12656,
        "authEntityId": 12656,
        "displayName": "12656",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "53,80,443,5353",
        "protocol": "TCP",
        "upperPort": 0
      },
      {
        "id": "23de56fe-3cc2-4e99-ae79-e7e01d9509c0",
        "instanceId": 12655,
        "authEntityId": 12655,
        "displayName": "12655",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "53,5353",
        "protocol": "UDP",
        "upperPort": 0
      }
    ],
    "parentScalableGroup": {
      "idRef": "28e08de4-d7b2-4521-84ce-bc1e5ee0b9cc"
    },
    "user": [

    ],
    "userGroup": [

    ]
  },
  {
    "id": "0041d235-e046-49ee-b82c-21b8dfd0e20f",
    "instanceId": 15449,
    "authEntityId": 15449,
    "displayName": "15449",
    "authEntityClass": -1909073334,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "createTime": 1517966744985,
    "deployed": false,
    "isSeeded": true,
    "isStale": false,
    "lastUpdateTime": 1517966744985,
    "name": "xunlei-kankan",
    "namespace": "scalablegroup:application",
    "provisioningState": "DEFINED",
    "qualifier": "application",
    "resourceVersion": 0,
    "targetIdList": [

    ],
    "type": "scalablegroup",
    "cfsChangeInfo": [

    ],
    "customProvisions": [

    ],
    "scalableGroupExternalHandle": "xunlei-kankan",
    "scalableGroupType": "APPLICATION",
    "securityGroupTag": 0,
    "state": "ACTIVE",
    "vnAgnostic": true,
    "identitySource": {
      "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
      "instanceId": 10010,
      "authEntityId": 10010,
      "displayName": "10010",
      "authEntityClass": 1738953278,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "state": "INACTIVE",
      "type": "NBAR"
    },
    "indicativeNetworkIdentity": [

    ],
    "networkApplications": [
      {
        "id": "a5896123-bb0f-48ed-844d-fb45dccc5f70",
        "instanceId": 16392,
        "authEntityId": 16392,
        "displayName": "16392",
        "authEntityClass": -217092956,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "appProtocol": "tcp/udp",
        "applicationGroup": "xunlei-group",
        "applicationType": "DEFAULT",
        "categoryId": "a09ef1b3-ba26-4372-aee8-309d50697e76",
        "createTime": 1517966744985,
        "encrypted": "false",
        "engineId": "13",
        "helpString": "xunlei-kankan, xunlei-kankan is a chinese download manager and p2p client.",
        "ignoreConflict": false,
        "lastUpdateTime": 1517966744985,
        "longDescription": "Xunlei Kankan is a Chinese video sharing website and desktop application. It enables the user to watch high quality video content, available on windows android and iOS.",
        "name": "xunlei-kankan",
        "nbarId": "1472",
        "p2pTechnology": "true",
        "popularity": 8,
        "rank": 65535,
        "references": "http://www.kankan.com/",
        "selectorId": "538",
        "subCategory": "85a797c0-aef8-4284-91a7-677f33e29abb",
        "trafficClass": "MULTIMEDIA_STREAMING",
        "tunnel": "false"
      }
    ],
    "networkIdentity": [
      {
        "id": "f0efaaee-02df-435f-9ede-8442d167429d",
        "instanceId": 17931,
        "authEntityId": 17931,
        "displayName": "17931",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "53,80,5353",
        "protocol": "TCP",
        "upperPort": 0
      },
      {
        "id": "e207a9da-de75-4dcf-b0f3-acbe624d1ad1",
        "instanceId": 17932,
        "authEntityId": 17932,
        "displayName": "17932",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "53,5353",
        "protocol": "UDP",
        "upperPort": 0
      }
    ],
    "parentScalableGroup": {
      "idRef": "fd6264c9-c16a-4311-a335-a3db039256f3"
    },
    "user": [

    ],
    "userGroup": [

    ]
  },
  {
    "id": "00427648-2970-4c65-93d6-2d6b7ef70256",
    "instanceId": 4959,
    "authEntityId": 4959,
    "displayName": "4959",
    "authEntityClass": -1909073334,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "createTime": 1517966743906,
    "deployed": false,
    "isSeeded": true,
    "isStale": false,
    "lastUpdateTime": 1517966743906,
    "name": "pim",
    "namespace": "scalablegroup:application",
    "provisioningState": "DEFINED",
    "qualifier": "application",
    "resourceVersion": 0,
    "targetIdList": [

    ],
    "type": "scalablegroup",
    "cfsChangeInfo": [

    ],
    "customProvisions": [

    ],
    "scalableGroupExternalHandle": "pim",
    "scalableGroupType": "APPLICATION",
    "securityGroupTag": 0,
    "state": "ACTIVE",
    "vnAgnostic": true,
    "identitySource": {
      "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
      "instanceId": 10010,
      "authEntityId": 10010,
      "displayName": "10010",
      "authEntityClass": 1738953278,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "state": "INACTIVE",
      "type": "NBAR"
    },
    "indicativeNetworkIdentity": [
      {
        "id": "19042bf5-dd3f-4b9a-8bc0-709f93c76979",
        "instanceId": 14537,
        "authEntityId": 14537,
        "displayName": "14537",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipProtocolNumbers": "103",
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "protocol": "PIM",
        "upperPort": 0
      }
    ],
    "networkApplications": [
      {
        "id": "4342fc1b-43d9-40fa-9416-074d70080136",
        "instanceId": 11908,
        "authEntityId": 11908,
        "displayName": "11908",
        "authEntityClass": -217092956,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "appProtocol": "ip",
        "applicationGroup": "other",
        "applicationType": "DEFAULT",
        "categoryId": "1fc17362-9334-4c7b-b752-5afdbb574017",
        "createTime": 1517966743906,
        "encrypted": "unassigned",
        "engineId": "1",
        "helpString": "Protocol Independent Multicast",
        "ignoreConflict": false,
        "lastUpdateTime": 1517966743906,
        "longDescription": "Protocol-Independent Multicast (PIM) is a family of multicast routing protocols for Internet Protocol (IP) networks that provide one-to-many and many-to-many distribution of data over a LAN, WAN or the Internet. It is termed protocol-independent because PIM does not include its own topology discovery mechanism, but instead uses routing information supplied by other traditional routing protocols.",
        "name": "pim",
        "nbarId": "857",
        "p2pTechnology": "unassigned",
        "popularity": 2,
        "rank": 65535,
        "references": "http://www.ietf.org/rfc/rfc4601.txt",
        "selectorId": "103",
        "subCategory": "7cc1b3a6-4102-44e9-b522-0e71afe39a03",
        "trafficClass": "NETWORK_CONTROL",
        "tunnel": "unassigned"
      }
    ],
    "networkIdentity": [
      {
        "id": "1dc910d8-265b-4e3a-a57c-114a56eb8502",
        "instanceId": 14536,
        "authEntityId": 14536,
        "displayName": "14536",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipProtocolNumbers": "103",
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "protocol": "PIM",
        "upperPort": 0
      }
    ],
    "parentScalableGroup": {
      "idRef": "77e75842-e98b-4112-ad0c-300db4bb11be"
    },
    "user": [

    ],
    "userGroup": [

    ]
  },
  {
    "id": "008d3884-2210-4d22-90bf-7b0c46542c95",
    "instanceId": 4437,
    "authEntityId": 4437,
    "displayName": "4437",
    "authEntityClass": -1909073334,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "createTime": 1517966742656,
    "deployed": false,
    "isSeeded": true,
    "isStale": false,
    "lastUpdateTime": 1517966742656,
    "name": "facebook-media",
    "namespace": "scalablegroup:application",
    "provisioningState": "DEFINED",
    "qualifier": "application",
    "resourceVersion": 0,
    "targetIdList": [

    ],
    "type": "scalablegroup",
    "cfsChangeInfo": [

    ],
    "customProvisions": [

    ],
    "scalableGroupExternalHandle": "facebook-media",
    "scalableGroupType": "APPLICATION",
    "securityGroupTag": 0,
    "state": "ACTIVE",
    "vnAgnostic": true,
    "identitySource": {
      "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
      "instanceId": 10010,
      "authEntityId": 10010,
      "displayName": "10010",
      "authEntityClass": 1738953278,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "state": "INACTIVE",
      "type": "NBAR"
    },
    "indicativeNetworkIdentity": [

    ],
    "networkApplications": [
      {
        "id": "58166eeb-d184-4455-8aa6-0a20070372c7",
        "instanceId": 11388,
        "authEntityId": 11388,
        "displayName": "11388",
        "authEntityClass": -217092956,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "applicationGroup": "other",
        "applicationType": "DEFAULT",
        "categoryId": "9eb6d680-796f-478a-9106-1151f00f7eff",
        "createTime": 1517966742656,
        "encrypted": "false",
        "engineId": "13",
        "helpString": "Facebook Media",
        "ignoreConflict": false,
        "lastUpdateTime": 1517966742656,
        "longDescription": "Facebook media streaming services.",
        "name": "facebook-media",
        "nbarId": "1070",
        "p2pTechnology": "false",
        "popularity": 10,
        "rank": 65535,
        "references": "http://www.facebook.com",
        "selectorId": "1070",
        "subCategory": "118c10a2-e272-47c1-92b9-4814ebe97138",
        "trafficClass": "MULTIMEDIA_CONFERENCING",
        "tunnel": "false"
      }
    ],
    "networkIdentity": [

    ],
    "parentScalableGroup": {
      "idRef": "82348eb1-d8b8-4daf-8532-15e1a5f9cdd0"
    },
    "user": [

    ],
    "userGroup": [

    ]
  },
  {
    "id": "00d019ec-f541-46e1-b42e-9d8a05ddd65e",
    "instanceId": 4930,
    "authEntityId": 4930,
    "displayName": "4930",
    "authEntityClass": -1909073334,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "createTime": 1517966743830,
    "deployed": false,
    "isSeeded": true,
    "isStale": false,
    "lastUpdateTime": 1517966743830,
    "name": "outbrain",
    "namespace": "scalablegroup:application",
    "provisioningState": "DEFINED",
    "qualifier": "application",
    "resourceVersion": 0,
    "targetIdList": [

    ],
    "type": "scalablegroup",
    "cfsChangeInfo": [

    ],
    "customProvisions": [

    ],
    "scalableGroupExternalHandle": "outbrain",
    "scalableGroupType": "APPLICATION",
    "securityGroupTag": 0,
    "state": "ACTIVE",
    "vnAgnostic": true,
    "identitySource": {
      "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
      "instanceId": 10010,
      "authEntityId": 10010,
      "displayName": "10010",
      "authEntityClass": 1738953278,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "state": "INACTIVE",
      "type": "NBAR"
    },
    "indicativeNetworkIdentity": [

    ],
    "networkApplications": [
      {
        "id": "4ee41e35-9f2a-46c5-b3af-f4712cff6653",
        "instanceId": 11879,
        "authEntityId": 11879,
        "displayName": "11879",
        "authEntityClass": -217092956,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "appProtocol": "tcp/udp",
        "applicationGroup": "other",
        "applicationType": "DEFAULT",
        "categoryId": "878644b5-fe91-45b4-9204-31c84a73ba48",
        "createTime": 1517966743830,
        "encrypted": "false",
        "engineId": "13",
        "helpString": "Outbrain - Online help for publishers and bloggers.",
        "ignoreConflict": false,
        "lastUpdateTime": 1517966743830,
        "longDescription": "Outbrain - Online help for publishers and bloggers.",
        "name": "outbrain",
        "nbarId": "1566",
        "p2pTechnology": "false",
        "popularity": 3,
        "rank": 65535,
        "references": "http://outbrain.com",
        "selectorId": "637",
        "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
        "trafficClass": "TRANSACTIONAL_DATA",
        "tunnel": "false"
      }
    ],
    "networkIdentity": [
      {
        "id": "9b2317db-aa63-45c4-97b8-b3993064b4c4",
        "instanceId": 14455,
        "authEntityId": 14455,
        "displayName": "14455",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "53,80,443,5353",
        "protocol": "TCP",
        "upperPort": 0
      },
      {
        "id": "4d862cee-800b-45b5-942a-c7dfc02c19a3",
        "instanceId": 14456,
        "authEntityId": 14456,
        "displayName": "14456",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "53,5353",
        "protocol": "UDP",
        "upperPort": 0
      }
    ],
    "parentScalableGroup": {
      "idRef": "28e08de4-d7b2-4521-84ce-bc1e5ee0b9cc"
    },
    "user": [

    ],
    "userGroup": [

    ]
  },
  {
    "id": "00d063a6-d39a-42df-80f3-ec60221e31c7",
    "instanceId": 4553,
    "authEntityId": 4553,
    "displayName": "4553",
    "authEntityClass": -1909073334,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "createTime": 1517966742973,
    "deployed": false,
    "isSeeded": true,
    "isStale": false,
    "lastUpdateTime": 1517966742973,
    "name": "hp-collector",
    "namespace": "scalablegroup:application",
    "provisioningState": "DEFINED",
    "qualifier": "application",
    "resourceVersion": 0,
    "targetIdList": [

    ],
    "type": "scalablegroup",
    "cfsChangeInfo": [

    ],
    "customProvisions": [

    ],
    "scalableGroupExternalHandle": "hp-collector",
    "scalableGroupType": "APPLICATION",
    "securityGroupTag": 0,
    "state": "ACTIVE",
    "vnAgnostic": true,
    "identitySource": {
      "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
      "instanceId": 10010,
      "authEntityId": 10010,
      "displayName": "10010",
      "authEntityClass": 1738953278,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "state": "INACTIVE",
      "type": "NBAR"
    },
    "indicativeNetworkIdentity": [
      {
        "id": "780498cf-9378-485b-9de3-79635b9a6179",
        "instanceId": 13371,
        "authEntityId": 13371,
        "displayName": "13371",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "381",
        "protocol": "TCP",
        "upperPort": 0
      },
      {
        "id": "3d278868-5f8b-407c-bbaf-ad17a14adf3f",
        "instanceId": 13372,
        "authEntityId": 13372,
        "displayName": "13372",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "381",
        "protocol": "UDP",
        "upperPort": 0
      }
    ],
    "networkApplications": [
      {
        "id": "33d0803f-fc21-47aa-bdd9-ddf555bfa9ee",
        "instanceId": 11504,
        "authEntityId": 11504,
        "displayName": "11504",
        "authEntityClass": -217092956,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "appProtocol": "tcp/udp",
        "applicationGroup": "other",
        "applicationType": "DEFAULT",
        "categoryId": "1fc17362-9334-4c7b-b752-5afdbb574017",
        "createTime": 1517966742973,
        "encrypted": "false",
        "engineId": "3",
        "helpString": "hp performance data collector",
        "ignoreConflict": false,
        "lastUpdateTime": 1517966742973,
        "longDescription": "Registered with IANA on port 381 TCP/UDP",
        "name": "hp-collector",
        "nbarId": "297",
        "p2pTechnology": "false",
        "popularity": 2,
        "rank": 65535,
        "references": "http://www.iana.org/assignments/service-names-port-numbers/service-names-port-numbers.xml",
        "selectorId": "381",
        "subCategory": "f3c0f761-d894-4112-ab89-b90fc84fd660",
        "trafficClass": "OPS_ADMIN_MGMT",
        "tunnel": "false"
      }
    ],
    "networkIdentity": [
      {
        "id": "5d59938e-86bf-402c-849c-37fee3f6bbd7",
        "instanceId": 13369,
        "authEntityId": 13369,
        "displayName": "13369",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "381",
        "protocol": "UDP",
        "upperPort": 0
      },
      {
        "id": "10a71675-21e9-485e-84ea-e559612d4f96",
        "instanceId": 13370,
        "authEntityId": 13370,
        "displayName": "13370",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "381",
        "protocol": "TCP",
        "upperPort": 0
      }
    ],
    "parentScalableGroup": {
      "idRef": "5fcd74d4-8726-47f7-a088-c457863da7ef"
    },
    "user": [

    ],
    "userGroup": [

    ]
  },
  {
    "id": "00d74da8-1c5c-4cc8-b30a-442f537db54f",
    "instanceId": 4460,
    "authEntityId": 4460,
    "displayName": "4460",
    "authEntityClass": -1909073334,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "createTime": 1517966742726,
    "deployed": false,
    "isSeeded": true,
    "isStale": false,
    "lastUpdateTime": 1517966742726,
    "name": "font-service",
    "namespace": "scalablegroup:application",
    "provisioningState": "DEFINED",
    "qualifier": "application",
    "resourceVersion": 0,
    "targetIdList": [

    ],
    "type": "scalablegroup",
    "cfsChangeInfo": [

    ],
    "customProvisions": [

    ],
    "scalableGroupExternalHandle": "font-service",
    "scalableGroupType": "APPLICATION",
    "securityGroupTag": 0,
    "state": "ACTIVE",
    "vnAgnostic": true,
    "identitySource": {
      "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
      "instanceId": 10010,
      "authEntityId": 10010,
      "displayName": "10010",
      "authEntityClass": 1738953278,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "state": "INACTIVE",
      "type": "NBAR"
    },
    "indicativeNetworkIdentity": [
      {
        "id": "fb2fa221-c2c9-4220-b5d9-0e20c0b31f1b",
        "instanceId": 13147,
        "authEntityId": 13147,
        "displayName": "13147",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "7100",
        "protocol": "TCP",
        "upperPort": 0
      },
      {
        "id": "bee6dc99-ba0a-4a7e-9439-74f23b390a17",
        "instanceId": 13148,
        "authEntityId": 13148,
        "displayName": "13148",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "7100",
        "protocol": "UDP",
        "upperPort": 0
      }
    ],
    "networkApplications": [
      {
        "id": "df0f6ae1-43de-49c8-acc9-fb2b6f9b46e3",
        "instanceId": 11411,
        "authEntityId": 11411,
        "displayName": "11411",
        "authEntityClass": -217092956,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "appProtocol": "tcp/udp",
        "applicationGroup": "other",
        "applicationType": "DEFAULT",
        "categoryId": "6daa3dfd-3526-407a-88f0-97824b3f3a24",
        "createTime": 1517966742726,
        "encrypted": "false",
        "engineId": "3",
        "helpString": "X font service",
        "ignoreConflict": false,
        "lastUpdateTime": 1517966742726,
        "longDescription": "The X Font Server is a service that serves font files to its clients. Clients connect to the server to request a font set, and the server reads the font files off the disk and serves them to the clients.",
        "name": "font-service",
        "nbarId": "1370",
        "p2pTechnology": "false",
        "popularity": 1,
        "rank": 65535,
        "references": "http://docs.oracle.com/cd/E18728_01/html/821-2848/dsdl_sample-2.html",
        "selectorId": "7100",
        "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
        "trafficClass": "BULK_DATA",
        "tunnel": "false"
      }
    ],
    "networkIdentity": [
      {
        "id": "020750b6-f06a-42d7-9200-a6e47c79286f",
        "instanceId": 13145,
        "authEntityId": 13145,
        "displayName": "13145",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "7100",
        "protocol": "UDP",
        "upperPort": 0
      },
      {
        "id": "603276e1-e750-4a9f-a2c0-fd130e851d3e",
        "instanceId": 13146,
        "authEntityId": 13146,
        "displayName": "13146",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "7100",
        "protocol": "TCP",
        "upperPort": 0
      }
    ],
    "parentScalableGroup": {
      "idRef": "000c15bf-28fe-4fbb-9b69-35e6502721f1"
    },
    "user": [

    ],
    "userGroup": [

    ]
  },
  {
    "id": "00ee0338-5c69-4cb1-829f-a2c4e5d814d0",
    "instanceId": 15104,
    "authEntityId": 15104,
    "displayName": "15104",
    "authEntityClass": -1909073334,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "createTime": 1517966744243,
    "deployed": false,
    "isSeeded": true,
    "isStale": false,
    "lastUpdateTime": 1517966744243,
    "name": "scribd",
    "namespace": "scalablegroup:application",
    "provisioningState": "DEFINED",
    "qualifier": "application",
    "resourceVersion": 0,
    "targetIdList": [

    ],
    "type": "scalablegroup",
    "cfsChangeInfo": [

    ],
    "customProvisions": [

    ],
    "scalableGroupExternalHandle": "scribd",
    "scalableGroupType": "APPLICATION",
    "securityGroupTag": 0,
    "state": "ACTIVE",
    "vnAgnostic": true,
    "identitySource": {
      "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
      "instanceId": 10010,
      "authEntityId": 10010,
      "displayName": "10010",
      "authEntityClass": 1738953278,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "state": "INACTIVE",
      "type": "NBAR"
    },
    "indicativeNetworkIdentity": [

    ],
    "networkApplications": [
      {
        "id": "07e6af20-f6dc-4187-8c11-b57e77265ba7",
        "instanceId": 16047,
        "authEntityId": 16047,
        "displayName": "16047",
        "authEntityClass": -217092956,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "appProtocol": "tcp/udp",
        "applicationGroup": "other",
        "applicationType": "DEFAULT",
        "categoryId": "878644b5-fe91-45b4-9204-31c84a73ba48",
        "createTime": 1517966744243,
        "encrypted": "false",
        "engineId": "13",
        "helpString": "Scribd - Web based document posting and sharing service.",
        "ignoreConflict": false,
        "lastUpdateTime": 1517966744243,
        "longDescription": "Scribd - Web based document posting and sharing service.",
        "name": "scribd",
        "nbarId": "1630",
        "p2pTechnology": "false",
        "popularity": 3,
        "rank": 65535,
        "references": "http://scribd.com",
        "selectorId": "700",
        "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
        "trafficClass": "TRANSACTIONAL_DATA",
        "tunnel": "false"
      }
    ],
    "networkIdentity": [
      {
        "id": "afafea44-c877-4d45-a67b-264b98c00b90",
        "instanceId": 14950,
        "authEntityId": 14950,
        "displayName": "14950",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "53,5353",
        "protocol": "UDP",
        "upperPort": 0
      },
      {
        "id": "2f85d1c9-0898-4068-a6aa-52b008b805bb",
        "instanceId": 14951,
        "authEntityId": 14951,
        "displayName": "14951",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "53,80,443,5353",
        "protocol": "TCP",
        "upperPort": 0
      }
    ],
    "parentScalableGroup": {
      "idRef": "28e08de4-d7b2-4521-84ce-bc1e5ee0b9cc"
    },
    "user": [

    ],
    "userGroup": [

    ]
  },
  {
    "id": "00eee7bb-c8f5-4582-a966-e9f89e6b5d88",
    "instanceId": 15437,
    "authEntityId": 15437,
    "displayName": "15437",
    "authEntityClass": -1909073334,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "createTime": 1517966744964,
    "deployed": false,
    "isSeeded": true,
    "isStale": false,
    "lastUpdateTime": 1517966744964,
    "name": "xfire",
    "namespace": "scalablegroup:application",
    "provisioningState": "DEFINED",
    "qualifier": "application",
    "resourceVersion": 0,
    "targetIdList": [

    ],
    "type": "scalablegroup",
    "cfsChangeInfo": [

    ],
    "customProvisions": [

    ],
    "scalableGroupExternalHandle": "xfire",
    "scalableGroupType": "APPLICATION",
    "securityGroupTag": 0,
    "state": "ACTIVE",
    "vnAgnostic": true,
    "identitySource": {
      "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
      "instanceId": 10010,
      "authEntityId": 10010,
      "displayName": "10010",
      "authEntityClass": 1738953278,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "state": "INACTIVE",
      "type": "NBAR"
    },
    "indicativeNetworkIdentity": [
      {
        "id": "2ae5f822-d528-498e-9fe3-252ef7600240",
        "instanceId": 17896,
        "authEntityId": 17896,
        "displayName": "17896",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "25999",
        "protocol": "TCP",
        "upperPort": 0
      }
    ],
    "networkApplications": [
      {
        "id": "2de5380f-a83d-4349-9165-cc644886ff8d",
        "instanceId": 16380,
        "authEntityId": 16380,
        "displayName": "16380",
        "authEntityClass": -217092956,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "appProtocol": "tcp",
        "applicationGroup": "other",
        "applicationType": "DEFAULT",
        "categoryId": "3cd50dd8-1b2e-46c1-b672-41c14bfa364b",
        "createTime": 1517966744964,
        "encrypted": "false",
        "engineId": "3",
        "helpString": "Freeware instant messaging service",
        "ignoreConflict": false,
        "lastUpdateTime": 1517966744964,
        "longDescription": "Xfire is a proprietary freeware instant messaging service for gamers, that also serves as a game server browser and has various other features. It is currently available for Microsoft Windows.",
        "name": "xfire",
        "nbarId": "1371",
        "p2pTechnology": "false",
        "popularity": 1,
        "rank": 65535,
        "references": "http://www.xfire.com/",
        "selectorId": "25999",
        "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
        "trafficClass": "BULK_DATA",
        "tunnel": "false"
      }
    ],
    "networkIdentity": [
      {
        "id": "7307d628-e4ee-410f-aae6-47e651eb626e",
        "instanceId": 17895,
        "authEntityId": 17895,
        "displayName": "17895",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "25999",
        "protocol": "TCP",
        "upperPort": 0
      }
    ],
    "parentScalableGroup": {
      "idRef": "0bd0e6c9-5083-492b-97cb-a88928bee99c"
    },
    "user": [

    ],
    "userGroup": [

    ]
  },
  {
    "id": "00f9fd1d-8abc-4a48-bce7-4d64d82fa642",
    "instanceId": 4326,
    "authEntityId": 4326,
    "displayName": "4326",
    "authEntityClass": -1909073334,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "createTime": 1517966742288,
    "deployed": false,
    "isSeeded": true,
    "isStale": false,
    "lastUpdateTime": 1517966742288,
    "name": "datasurfsrvsec",
    "namespace": "scalablegroup:application",
    "provisioningState": "DEFINED",
    "qualifier": "application",
    "resourceVersion": 0,
    "targetIdList": [

    ],
    "type": "scalablegroup",
    "cfsChangeInfo": [

    ],
    "customProvisions": [

    ],
    "scalableGroupExternalHandle": "datasurfsrvsec",
    "scalableGroupType": "APPLICATION",
    "securityGroupTag": 0,
    "state": "ACTIVE",
    "vnAgnostic": true,
    "identitySource": {
      "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
      "instanceId": 10010,
      "authEntityId": 10010,
      "displayName": "10010",
      "authEntityClass": 1738953278,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "state": "INACTIVE",
      "type": "NBAR"
    },
    "indicativeNetworkIdentity": [
      {
        "id": "d451aa0d-2415-46ed-b3b7-4cfb4efa48ae",
        "instanceId": 12756,
        "authEntityId": 12756,
        "displayName": "12756",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "462",
        "protocol": "TCP",
        "upperPort": 0
      },
      {
        "id": "78ebb300-de43-4ade-8e6b-cda63dc351e7",
        "instanceId": 12757,
        "authEntityId": 12757,
        "displayName": "12757",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "462",
        "protocol": "UDP",
        "upperPort": 0
      }
    ],
    "networkApplications": [
      {
        "id": "74091d6d-dc40-4c3d-9e97-3afa010ccf73",
        "instanceId": 11278,
        "authEntityId": 11278,
        "displayName": "11278",
        "authEntityClass": -217092956,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "appProtocol": "tcp/udp",
        "applicationGroup": "other",
        "applicationType": "DEFAULT",
        "categoryId": "6daa3dfd-3526-407a-88f0-97824b3f3a24",
        "createTime": 1517966742288,
        "encrypted": "false",
        "engineId": "3",
        "helpString": "DataRamp Svr svs",
        "ignoreConflict": false,
        "lastUpdateTime": 1517966742288,
        "longDescription": "Registered with IANA on port 462 TCP/UDP",
        "name": "datasurfsrvsec",
        "nbarId": "376",
        "p2pTechnology": "false",
        "popularity": 1,
        "rank": 65535,
        "references": "http://www.iana.org/assignments/service-names-port-numbers/service-names-port-numbers.xml",
        "selectorId": "462",
        "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
        "trafficClass": "BULK_DATA",
        "tunnel": "false"
      }
    ],
    "networkIdentity": [
      {
        "id": "800b8d34-397d-4572-97cf-84c85acf4574",
        "instanceId": 12754,
        "authEntityId": 12754,
        "displayName": "12754",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "462",
        "protocol": "TCP",
        "upperPort": 0
      },
      {
        "id": "a09fea2c-121c-4ed5-b75c-cadd8969a58e",
        "instanceId": 12755,
        "authEntityId": 12755,
        "displayName": "12755",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "462",
        "protocol": "UDP",
        "upperPort": 0
      }
    ],
    "parentScalableGroup": {
      "idRef": "000c15bf-28fe-4fbb-9b69-35e6502721f1"
    },
    "user": [

    ],
    "userGroup": [

    ]
  },
  {
    "id": "01c5e1b3-5403-49c0-ad8d-d1f94cb63b9a",
    "instanceId": 4215,
    "authEntityId": 4215,
    "displayName": "4215",
    "authEntityClass": -1909073334,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "createTime": 1517966741765,
    "deployed": false,
    "isSeeded": true,
    "isStale": false,
    "lastUpdateTime": 1517966741765,
    "name": "cbs",
    "namespace": "scalablegroup:application",
    "provisioningState": "DEFINED",
    "qualifier": "application",
    "resourceVersion": 0,
    "targetIdList": [

    ],
    "type": "scalablegroup",
    "cfsChangeInfo": [

    ],
    "customProvisions": [

    ],
    "scalableGroupExternalHandle": "cbs",
    "scalableGroupType": "APPLICATION",
    "securityGroupTag": 0,
    "state": "ACTIVE",
    "vnAgnostic": true,
    "identitySource": {
      "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
      "instanceId": 10010,
      "authEntityId": 10010,
      "displayName": "10010",
      "authEntityClass": 1738953278,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "state": "INACTIVE",
      "type": "NBAR"
    },
    "indicativeNetworkIdentity": [

    ],
    "networkApplications": [
      {
        "id": "1dc4e65b-754e-455b-974f-9638308efa88",
        "instanceId": 11170,
        "authEntityId": 11170,
        "displayName": "11170",
        "authEntityClass": -217092956,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "appProtocol": "tcp/udp",
        "applicationGroup": "other",
        "applicationType": "DEFAULT",
        "categoryId": "878644b5-fe91-45b4-9204-31c84a73ba48",
        "createTime": 1517966741765,
        "encrypted": "false",
        "engineId": "13",
        "helpString": "CBS - CBS news website.",
        "ignoreConflict": false,
        "lastUpdateTime": 1517966741765,
        "longDescription": "CBS - CBS news website.",
        "name": "cbs",
        "nbarId": "1685",
        "p2pTechnology": "false",
        "popularity": 3,
        "rank": 65535,
        "references": "http://cbs.com",
        "selectorId": "755",
        "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
        "trafficClass": "TRANSACTIONAL_DATA",
        "tunnel": "false"
      }
    ],
    "networkIdentity": [
      {
        "id": "75eb745b-1e06-4763-8904-2742f9613369",
        "instanceId": 12465,
        "authEntityId": 12465,
        "displayName": "12465",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "53,80,443,5353",
        "protocol": "TCP",
        "upperPort": 0
      },
      {
        "id": "3c4cc9d1-94cc-4e7d-b2d3-3b834c239032",
        "instanceId": 12466,
        "authEntityId": 12466,
        "displayName": "12466",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "53,5353",
        "protocol": "UDP",
        "upperPort": 0
      }
    ],
    "parentScalableGroup": {
      "idRef": "28e08de4-d7b2-4521-84ce-bc1e5ee0b9cc"
    },
    "user": [

    ],
    "userGroup": [

    ]
  },
  {
    "id": "020302fb-3d3b-4a78-95d2-684d13eb3f81",
    "instanceId": 4438,
    "authEntityId": 4438,
    "displayName": "4438",
    "authEntityClass": -1909073334,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "createTime": 1517966742659,
    "deployed": false,
    "isSeeded": true,
    "isStale": false,
    "lastUpdateTime": 1517966742659,
    "name": "facebook-video",
    "namespace": "scalablegroup:application",
    "provisioningState": "DEFINED",
    "qualifier": "application",
    "resourceVersion": 0,
    "targetIdList": [

    ],
    "type": "scalablegroup",
    "cfsChangeInfo": [

    ],
    "customProvisions": [

    ],
    "scalableGroupExternalHandle": "facebook-video",
    "scalableGroupType": "APPLICATION",
    "securityGroupTag": 0,
    "state": "ACTIVE",
    "vnAgnostic": true,
    "identitySource": {
      "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
      "instanceId": 10010,
      "authEntityId": 10010,
      "displayName": "10010",
      "authEntityClass": 1738953278,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "state": "INACTIVE",
      "type": "NBAR"
    },
    "indicativeNetworkIdentity": [

    ],
    "networkApplications": [
      {
        "id": "e26ddb62-56eb-4c54-8333-91d209a52886",
        "instanceId": 11389,
        "authEntityId": 11389,
        "displayName": "11389",
        "authEntityClass": -217092956,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "applicationGroup": "other",
        "applicationType": "DEFAULT",
        "categoryId": "9eb6d680-796f-478a-9106-1151f00f7eff",
        "createTime": 1517966742659,
        "encrypted": "false",
        "engineId": "13",
        "helpString": "Facebook Video",
        "ignoreConflict": false,
        "lastUpdateTime": 1517966742659,
        "longDescription": "Facebook video streaming services.",
        "name": "facebook-video",
        "nbarId": "1065",
        "p2pTechnology": "false",
        "popularity": 10,
        "rank": 65535,
        "references": "http://www.facebook.com",
        "selectorId": "1065",
        "subCategory": "85a797c0-aef8-4284-91a7-677f33e29abb",
        "trafficClass": "MULTIMEDIA_STREAMING",
        "tunnel": "false"
      }
    ],
    "networkIdentity": [

    ],
    "parentScalableGroup": {
      "idRef": "82348eb1-d8b8-4daf-8532-15e1a5f9cdd0"
    },
    "user": [

    ],
    "userGroup": [

    ]
  },
  {
    "id": "0255be24-238d-4643-a883-47e247cde0a5",
    "instanceId": 15414,
    "authEntityId": 15414,
    "displayName": "15414",
    "authEntityClass": -1909073334,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "createTime": 1517966744923,
    "deployed": false,
    "isSeeded": true,
    "isStale": false,
    "lastUpdateTime": 1517966744923,
    "name": "wifi-calling",
    "namespace": "scalablegroup:application",
    "provisioningState": "DEFINED",
    "qualifier": "application",
    "resourceVersion": 0,
    "targetIdList": [

    ],
    "type": "scalablegroup",
    "cfsChangeInfo": [

    ],
    "customProvisions": [

    ],
    "scalableGroupExternalHandle": "wifi-calling",
    "scalableGroupType": "APPLICATION",
    "securityGroupTag": 0,
    "state": "ACTIVE",
    "vnAgnostic": true,
    "identitySource": {
      "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
      "instanceId": 10010,
      "authEntityId": 10010,
      "displayName": "10010",
      "authEntityClass": 1738953278,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "state": "INACTIVE",
      "type": "NBAR"
    },
    "indicativeNetworkIdentity": [

    ],
    "networkApplications": [
      {
        "id": "f6ee7ecc-b16e-4cf0-a460-fdb7a598a42d",
        "instanceId": 16357,
        "authEntityId": 16357,
        "displayName": "16357",
        "authEntityClass": -217092956,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "appProtocol": "tcp/udp",
        "applicationGroup": "ipsec-group",
        "applicationType": "DEFAULT",
        "categoryId": "38a8e354-e2fe-4f3c-b1c5-2bbb90fb0d99",
        "createTime": 1517966744923,
        "encrypted": "true",
        "engineId": "13",
        "helpString": "Wifi Calling, carrier voice calls through a Wi-Fi network",
        "ignoreConflict": false,
        "lastUpdateTime": 1517966744923,
        "longDescription": "Wi-Fi Calling uses Wi-Fi to provide better mobile phone coverage of mobile carrier.",
        "name": "wifi-calling",
        "nbarId": "1736",
        "p2pTechnology": "false",
        "popularity": 8,
        "rank": 65535,
        "references": "http://www.wificalling.net/",
        "selectorId": "808",
        "subCategory": "ab63e736-9aa7-4946-b55b-3b26ddca609c",
        "trafficClass": "BULK_DATA",
        "tunnel": "true"
      }
    ],
    "networkIdentity": [
      {
        "id": "dc1b45f9-e8f8-4861-8dd5-f3724400e0be",
        "instanceId": 17840,
        "authEntityId": 17840,
        "displayName": "17840",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "53,80,5353",
        "protocol": "TCP",
        "upperPort": 0
      },
      {
        "id": "686016ec-c7fb-43fe-aa7a-59ae73de7532",
        "instanceId": 17839,
        "authEntityId": 17839,
        "displayName": "17839",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "53,5353",
        "protocol": "UDP",
        "upperPort": 0
      }
    ],
    "parentScalableGroup": {
      "idRef": "aad510ce-fe95-4dfc-ad06-e494641cc016"
    },
    "user": [

    ],
    "userGroup": [

    ]
  },
  {
    "id": "0259d336-cc16-4bfc-9396-ae6789d23aa4",
    "instanceId": 4680,
    "authEntityId": 4680,
    "displayName": "4680",
    "authEntityClass": -1909073334,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "createTime": 1517966743270,
    "deployed": false,
    "isSeeded": true,
    "isStale": false,
    "lastUpdateTime": 1517966743270,
    "name": "kpasswd",
    "namespace": "scalablegroup:application",
    "provisioningState": "DEFINED",
    "qualifier": "application",
    "resourceVersion": 0,
    "targetIdList": [

    ],
    "type": "scalablegroup",
    "cfsChangeInfo": [

    ],
    "customProvisions": [

    ],
    "scalableGroupExternalHandle": "kpasswd",
    "scalableGroupType": "APPLICATION",
    "securityGroupTag": 0,
    "state": "ACTIVE",
    "vnAgnostic": true,
    "identitySource": {
      "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
      "instanceId": 10010,
      "authEntityId": 10010,
      "displayName": "10010",
      "authEntityClass": 1738953278,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "state": "INACTIVE",
      "type": "NBAR"
    },
    "indicativeNetworkIdentity": [
      {
        "id": "f32b188d-2438-493a-9de7-ebaa3bd23b39",
        "instanceId": 13714,
        "authEntityId": 13714,
        "displayName": "13714",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "464",
        "protocol": "TCP",
        "upperPort": 0
      },
      {
        "id": "d178026b-4348-456d-aab8-7d702bf3276e",
        "instanceId": 13715,
        "authEntityId": 13715,
        "displayName": "13715",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "464",
        "protocol": "UDP",
        "upperPort": 0
      }
    ],
    "networkApplications": [
      {
        "id": "244ccef9-8df7-445f-9afa-5959b60a2c40",
        "instanceId": 11630,
        "authEntityId": 11630,
        "displayName": "11630",
        "authEntityClass": -217092956,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "appProtocol": "tcp/udp",
        "applicationGroup": "kerberos-group",
        "applicationType": "DEFAULT",
        "categoryId": "1fc17362-9334-4c7b-b752-5afdbb574017",
        "createTime": 1517966743270,
        "encrypted": "true",
        "engineId": "3",
        "helpString": "kpasswd",
        "ignoreConflict": false,
        "lastUpdateTime": 1517966743270,
        "longDescription": "Kerberos change-password protocol (kpasswd) is a password changing service that is implemented on hosts in Kerberos realms.",
        "name": "kpasswd",
        "nbarId": "378",
        "p2pTechnology": "false",
        "popularity": 1,
        "rank": 65535,
        "references": "http://tools.ietf.org/html/draft-ietf-cat-kerb-chg-password-02",
        "selectorId": "464",
        "subCategory": "e390124a-0fd6-4253-b68e-4b0c1af8cb4b",
        "trafficClass": "OPS_ADMIN_MGMT",
        "tunnel": "false"
      }
    ],
    "networkIdentity": [
      {
        "id": "7af2b857-e0df-4dc7-b166-aab5aa883255",
        "instanceId": 13712,
        "authEntityId": 13712,
        "displayName": "13712",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "464",
        "protocol": "TCP",
        "upperPort": 0
      },
      {
        "id": "9b2e3def-09fb-4739-a30c-79cb11f6acf8",
        "instanceId": 13713,
        "authEntityId": 13713,
        "displayName": "13713",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "464",
        "protocol": "UDP",
        "upperPort": 0
      }
    ],
    "parentScalableGroup": {
      "idRef": "7788bf2d-e289-4597-b500-17aacb7e820e"
    },
    "user": [

    ],
    "userGroup": [

    ]
  },
  {
    "id": "02952bac-e89e-4900-a2a9-ffa373d11a0e",
    "instanceId": 4062,
    "authEntityId": 4062,
    "displayName": "4062",
    "authEntityClass": -1909073334,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "createTime": 1517966740722,
    "deployed": false,
    "isSeeded": true,
    "isStale": false,
    "lastUpdateTime": 1517966740722,
    "name": "adobe-connect",
    "namespace": "scalablegroup:application",
    "provisioningState": "DEFINED",
    "qualifier": "application",
    "resourceVersion": 0,
    "targetIdList": [

    ],
    "type": "scalablegroup",
    "cfsChangeInfo": [

    ],
    "customProvisions": [

    ],
    "scalableGroupExternalHandle": "adobe-connect",
    "scalableGroupType": "APPLICATION",
    "securityGroupTag": 0,
    "state": "ACTIVE",
    "vnAgnostic": true,
    "identitySource": {
      "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
      "instanceId": 10010,
      "authEntityId": 10010,
      "displayName": "10010",
      "authEntityClass": 1738953278,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "state": "INACTIVE",
      "type": "NBAR"
    },
    "indicativeNetworkIdentity": [

    ],
    "networkApplications": [
      {
        "id": "87b16462-1062-4365-b91b-120ea4e0e879",
        "instanceId": 11032,
        "authEntityId": 11032,
        "displayName": "11032",
        "authEntityClass": -217092956,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "appProtocol": "tcp/udp",
        "applicationGroup": "other",
        "applicationType": "DEFAULT",
        "categoryId": "38a8e354-e2fe-4f3c-b1c5-2bbb90fb0d99",
        "createTime": 1517966740722,
        "encrypted": "true",
        "engineId": "13",
        "helpString": "web conferencing software",
        "ignoreConflict": false,
        "lastUpdateTime": 1517966740722,
        "longDescription": "Adobe Connect is a web conferencing platform for web meetings, eLearning and webinars.",
        "name": "adobe-connect",
        "nbarId": "1441",
        "p2pTechnology": "false",
        "popularity": 7,
        "rank": 65535,
        "references": "http://www.adobe.com/products/adobeconnect.html",
        "selectorId": "505",
        "subCategory": "05178016-4555-4291-ab4e-43c2ab2a931a",
        "trafficClass": "MULTIMEDIA_CONFERENCING",
        "tunnel": "false"
      }
    ],
    "networkIdentity": [
      {
        "id": "9860b588-32be-4efe-afc0-66174dd3c442",
        "instanceId": 12077,
        "authEntityId": 12077,
        "displayName": "12077",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "53,80,443,5353",
        "protocol": "TCP",
        "upperPort": 0
      },
      {
        "id": "665833de-8574-4206-b898-9678bec5660d",
        "instanceId": 12078,
        "authEntityId": 12078,
        "displayName": "12078",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "53,5353",
        "protocol": "UDP",
        "upperPort": 0
      }
    ],
    "parentScalableGroup": {
      "idRef": "60f1364d-2a02-4063-8ba1-07817abe0612"
    },
    "user": [

    ],
    "userGroup": [

    ]
  },
  {
    "id": "02b92a28-f9f0-48f5-a9c5-5231d5a46512",
    "instanceId": 4374,
    "authEntityId": 4374,
    "displayName": "4374",
    "authEntityClass": -1909073334,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "createTime": 1517966742447,
    "deployed": false,
    "isSeeded": true,
    "isStale": false,
    "lastUpdateTime": 1517966742447,
    "name": "dixie",
    "namespace": "scalablegroup:application",
    "provisioningState": "DEFINED",
    "qualifier": "application",
    "resourceVersion": 0,
    "targetIdList": [

    ],
    "type": "scalablegroup",
    "cfsChangeInfo": [

    ],
    "customProvisions": [

    ],
    "scalableGroupExternalHandle": "dixie",
    "scalableGroupType": "APPLICATION",
    "securityGroupTag": 0,
    "state": "ACTIVE",
    "vnAgnostic": true,
    "identitySource": {
      "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
      "instanceId": 10010,
      "authEntityId": 10010,
      "displayName": "10010",
      "authEntityClass": 1738953278,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "state": "INACTIVE",
      "type": "NBAR"
    },
    "indicativeNetworkIdentity": [
      {
        "id": "52c45a16-e5e4-41fb-8a6f-ef003dfaa167",
        "instanceId": 12909,
        "authEntityId": 12909,
        "displayName": "12909",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "96",
        "protocol": "UDP",
        "upperPort": 0
      },
      {
        "id": "76026e51-beef-437c-aaca-bc3522ae2827",
        "instanceId": 12910,
        "authEntityId": 12910,
        "displayName": "12910",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "96",
        "protocol": "TCP",
        "upperPort": 0
      }
    ],
    "networkApplications": [
      {
        "id": "8699fcde-e89a-4fad-9e74-f709f3ce575f",
        "instanceId": 11325,
        "authEntityId": 11325,
        "displayName": "11325",
        "authEntityClass": -217092956,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "appProtocol": "tcp/udp",
        "applicationGroup": "other",
        "applicationType": "DEFAULT",
        "categoryId": "6daa3dfd-3526-407a-88f0-97824b3f3a24",
        "createTime": 1517966742447,
        "encrypted": "false",
        "engineId": "3",
        "helpString": "DIXIE Protocol Specification",
        "ignoreConflict": false,
        "lastUpdateTime": 1517966742447,
        "longDescription": "OSI Directory Service defines a powerful mechanism for storing and retrieving information about objects, and for arranging those objects in a hierarchical structure. Many types of objects and information can be stored in The Directory, including white pages information, application information, service information, etc. The OSI protocol defined to allow access to this information is the Directory Access Protocol (DAP). The DAP, being an OSI application-layer program, is fairly heavy-weight and requires a substantial amount of computing power and coding investment to implement. The DIXIE protocol is designed for use by smaller hosts (e.g., Macintoshes and PCs) that do not have the computing power or necessary software to implement a full OSI protocol stack. The DIXIE protocol is also useful for any Internet application that wants a simple interface to X.500 that requires very little coding investment.",
        "name": "dixie",
        "nbarId": "967",
        "p2pTechnology": "false",
        "popularity": 1,
        "rank": 65535,
        "references": "http://tools.ietf.org/rfc/rfc1249",
        "selectorId": "96",
        "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
        "trafficClass": "BULK_DATA",
        "tunnel": "false"
      }
    ],
    "networkIdentity": [
      {
        "id": "c2ab28da-e0c2-4eaf-8808-7b1c5da2fb4a",
        "instanceId": 12907,
        "authEntityId": 12907,
        "displayName": "12907",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "96",
        "protocol": "UDP",
        "upperPort": 0
      },
      {
        "id": "9c95effd-e7db-4a9e-bb18-608c0820aac7",
        "instanceId": 12908,
        "authEntityId": 12908,
        "displayName": "12908",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "96",
        "protocol": "TCP",
        "upperPort": 0
      }
    ],
    "parentScalableGroup": {
      "idRef": "000c15bf-28fe-4fbb-9b69-35e6502721f1"
    },
    "user": [

    ],
    "userGroup": [

    ]
  },
  {
    "id": "0362002c-7a51-42c1-878c-f6f51511efc7",
    "instanceId": 4805,
    "authEntityId": 4805,
    "displayName": "4805",
    "authEntityClass": -1909073334,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "createTime": 1517966743528,
    "deployed": false,
    "isSeeded": true,
    "isStale": false,
    "lastUpdateTime": 1517966743528,
    "name": "msp",
    "namespace": "scalablegroup:application",
    "provisioningState": "DEFINED",
    "qualifier": "application",
    "resourceVersion": 0,
    "targetIdList": [

    ],
    "type": "scalablegroup",
    "cfsChangeInfo": [

    ],
    "customProvisions": [

    ],
    "scalableGroupExternalHandle": "msp",
    "scalableGroupType": "APPLICATION",
    "securityGroupTag": 0,
    "state": "ACTIVE",
    "vnAgnostic": true,
    "identitySource": {
      "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
      "instanceId": 10010,
      "authEntityId": 10010,
      "displayName": "10010",
      "authEntityClass": 1738953278,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "state": "INACTIVE",
      "type": "NBAR"
    },
    "indicativeNetworkIdentity": [
      {
        "id": "4c069073-f741-4f6f-a84b-d072c02f684b",
        "instanceId": 14055,
        "authEntityId": 14055,
        "displayName": "14055",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "18",
        "protocol": "TCP",
        "upperPort": 0
      },
      {
        "id": "7e77b4ec-3d37-47a3-a9b1-9005b3644bbd",
        "instanceId": 14056,
        "authEntityId": 14056,
        "displayName": "14056",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "18",
        "protocol": "UDP",
        "upperPort": 0
      }
    ],
    "networkApplications": [
      {
        "id": "f27de2b6-aade-431f-a8b0-95a793cba3c4",
        "instanceId": 11754,
        "authEntityId": 11754,
        "displayName": "11754",
        "authEntityClass": -217092956,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "appProtocol": "tcp/udp",
        "applicationGroup": "other",
        "applicationType": "DEFAULT",
        "categoryId": "27953c60-b2ff-4e1a-bc52-0187d8295250",
        "createTime": 1517966743528,
        "encrypted": "false",
        "engineId": "3",
        "helpString": "Message Send Protocol",
        "ignoreConflict": false,
        "lastUpdateTime": 1517966743528,
        "longDescription": "The Message Send Protocol (SEND) is used to send a short message to a given user on a given terminal on a given host.",
        "name": "msp",
        "nbarId": "907",
        "p2pTechnology": "false",
        "popularity": 1,
        "rank": 65535,
        "references": "http://www.ietf.org/rfc/rfc1312",
        "selectorId": "18",
        "subCategory": "b90786f4-0190-45b8-9924-21b3e9682590",
        "trafficClass": "BULK_DATA",
        "tunnel": "false"
      }
    ],
    "networkIdentity": [
      {
        "id": "00846757-0786-4c91-9f4d-49bf42d4c816",
        "instanceId": 14053,
        "authEntityId": 14053,
        "displayName": "14053",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "18",
        "protocol": "UDP",
        "upperPort": 0
      },
      {
        "id": "aed324b5-bb17-4bad-aa91-8c41724ae467",
        "instanceId": 14054,
        "authEntityId": 14054,
        "displayName": "14054",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "18",
        "protocol": "TCP",
        "upperPort": 0
      }
    ],
    "parentScalableGroup": {
      "idRef": "8a7ca43a-32bd-40d2-871a-02220a165cb7"
    },
    "user": [

    ],
    "userGroup": [

    ]
  },
  {
    "id": "0388650b-b257-4192-b313-63d605c1d984",
    "instanceId": 4666,
    "authEntityId": 4666,
    "displayName": "4666",
    "authEntityClass": -1909073334,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "createTime": 1517966743240,
    "deployed": false,
    "isSeeded": true,
    "isStale": false,
    "lastUpdateTime": 1517966743240,
    "name": "k-block",
    "namespace": "scalablegroup:application",
    "provisioningState": "DEFINED",
    "qualifier": "application",
    "resourceVersion": 0,
    "targetIdList": [

    ],
    "type": "scalablegroup",
    "cfsChangeInfo": [

    ],
    "customProvisions": [

    ],
    "scalableGroupExternalHandle": "k-block",
    "scalableGroupType": "APPLICATION",
    "securityGroupTag": 0,
    "state": "ACTIVE",
    "vnAgnostic": true,
    "identitySource": {
      "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
      "instanceId": 10010,
      "authEntityId": 10010,
      "displayName": "10010",
      "authEntityClass": 1738953278,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "state": "INACTIVE",
      "type": "NBAR"
    },
    "indicativeNetworkIdentity": [
      {
        "id": "112567d4-efbb-4b90-bbeb-a2f742e9f1b6",
        "instanceId": 13672,
        "authEntityId": 13672,
        "displayName": "13672",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "287",
        "protocol": "UDP",
        "upperPort": 0
      },
      {
        "id": "76de5cac-9f75-4a65-9c74-0f84b8e1ac28",
        "instanceId": 13673,
        "authEntityId": 13673,
        "displayName": "13673",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "287",
        "protocol": "TCP",
        "upperPort": 0
      }
    ],
    "networkApplications": [
      {
        "id": "a22bc01c-8844-4197-98d1-7390caa71b12",
        "instanceId": 11616,
        "authEntityId": 11616,
        "displayName": "11616",
        "authEntityClass": -217092956,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "appProtocol": "tcp/udp",
        "applicationGroup": "other",
        "applicationType": "DEFAULT",
        "categoryId": "6daa3dfd-3526-407a-88f0-97824b3f3a24",
        "createTime": 1517966743240,
        "encrypted": "false",
        "engineId": "3",
        "helpString": "k-block",
        "ignoreConflict": false,
        "lastUpdateTime": 1517966743240,
        "longDescription": "Registered with IANA on port 287 TCP/UDP",
        "name": "k-block",
        "nbarId": "1147",
        "p2pTechnology": "false",
        "popularity": 1,
        "rank": 65535,
        "references": "http://www.iana.org/assignments/service-names-port-numbers/service-names-port-numbers.xml",
        "selectorId": "287",
        "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
        "trafficClass": "BULK_DATA",
        "tunnel": "false"
      }
    ],
    "networkIdentity": [
      {
        "id": "f1628526-d087-47d0-9448-40f9e277eba3",
        "instanceId": 13670,
        "authEntityId": 13670,
        "displayName": "13670",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "287",
        "protocol": "TCP",
        "upperPort": 0
      },
      {
        "id": "38c01c9f-950d-4377-9d4d-dcbe7da17d68",
        "instanceId": 13671,
        "authEntityId": 13671,
        "displayName": "13671",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "287",
        "protocol": "UDP",
        "upperPort": 0
      }
    ],
    "parentScalableGroup": {
      "idRef": "000c15bf-28fe-4fbb-9b69-35e6502721f1"
    },
    "user": [

    ],
    "userGroup": [

    ]
  },
  {
    "id": "03a6e281-61f7-4bbe-a92e-c961817bd237",
    "instanceId": 15378,
    "authEntityId": 15378,
    "displayName": "15378",
    "authEntityClass": -1909073334,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "createTime": 1517966744837,
    "deployed": false,
    "isSeeded": true,
    "isStale": false,
    "lastUpdateTime": 1517966744837,
    "name": "wap-vcal",
    "namespace": "scalablegroup:application",
    "provisioningState": "DEFINED",
    "qualifier": "application",
    "resourceVersion": 0,
    "targetIdList": [

    ],
    "type": "scalablegroup",
    "cfsChangeInfo": [

    ],
    "customProvisions": [

    ],
    "scalableGroupExternalHandle": "wap-vcal",
    "scalableGroupType": "APPLICATION",
    "securityGroupTag": 0,
    "state": "ACTIVE",
    "vnAgnostic": true,
    "identitySource": {
      "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
      "instanceId": 10010,
      "authEntityId": 10010,
      "displayName": "10010",
      "authEntityClass": 1738953278,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "state": "INACTIVE",
      "type": "NBAR"
    },
    "indicativeNetworkIdentity": [
      {
        "id": "6ae48065-e907-4499-a7f6-bfb8b2d50fe6",
        "instanceId": 17755,
        "authEntityId": 17755,
        "displayName": "17755",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "9205",
        "protocol": "UDP",
        "upperPort": 0
      },
      {
        "id": "1bcc388d-8996-43ef-9e11-4b41a8a17867",
        "instanceId": 17756,
        "authEntityId": 17756,
        "displayName": "17756",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "9205",
        "protocol": "TCP",
        "upperPort": 0
      }
    ],
    "networkApplications": [
      {
        "id": "8baab8aa-69e2-48b2-a71d-e298db2cf665",
        "instanceId": 16321,
        "authEntityId": 16321,
        "displayName": "16321",
        "authEntityClass": -217092956,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "appProtocol": "tcp/udp",
        "applicationGroup": "wap-group",
        "applicationType": "DEFAULT",
        "categoryId": "6daa3dfd-3526-407a-88f0-97824b3f3a24",
        "createTime": 1517966744837,
        "encrypted": "false",
        "engineId": "3",
        "helpString": "WAP vCal",
        "ignoreConflict": false,
        "lastUpdateTime": 1517966744837,
        "longDescription": "Registered with IANA on port 9205 TCP/UDP",
        "name": "wap-vcal",
        "nbarId": "734",
        "p2pTechnology": "false",
        "popularity": 1,
        "rank": 65535,
        "references": "http://www.iana.org/assignments/service-names-port-numbers/service-names-port-numbers.xml",
        "selectorId": "9205",
        "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
        "trafficClass": "BULK_DATA",
        "tunnel": "false"
      }
    ],
    "networkIdentity": [
      {
        "id": "a4677a49-52e6-4cc1-ae4e-01fcdc74ce2c",
        "instanceId": 17753,
        "authEntityId": 17753,
        "displayName": "17753",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "9205",
        "protocol": "TCP",
        "upperPort": 0
      },
      {
        "id": "fea6fe56-bfb2-4104-966b-37d640bf67a5",
        "instanceId": 17754,
        "authEntityId": 17754,
        "displayName": "17754",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "9205",
        "protocol": "UDP",
        "upperPort": 0
      }
    ],
    "parentScalableGroup": {
      "idRef": "000c15bf-28fe-4fbb-9b69-35e6502721f1"
    },
    "user": [

    ],
    "userGroup": [

    ]
  },
  {
    "id": "03bae41a-3603-4281-b9f7-e8864efb956f",
    "instanceId": 4912,
    "authEntityId": 4912,
    "displayName": "4912",
    "authEntityClass": -1909073334,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "createTime": 1517966743746,
    "deployed": false,
    "isSeeded": true,
    "isStale": false,
    "lastUpdateTime": 1517966743746,
    "name": "opc-job-track",
    "namespace": "scalablegroup:application",
    "provisioningState": "DEFINED",
    "qualifier": "application",
    "resourceVersion": 0,
    "targetIdList": [

    ],
    "type": "scalablegroup",
    "cfsChangeInfo": [

    ],
    "customProvisions": [

    ],
    "scalableGroupExternalHandle": "opc-job-track",
    "scalableGroupType": "APPLICATION",
    "securityGroupTag": 0,
    "state": "ACTIVE",
    "vnAgnostic": true,
    "identitySource": {
      "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
      "instanceId": 10010,
      "authEntityId": 10010,
      "displayName": "10010",
      "authEntityClass": 1738953278,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "state": "INACTIVE",
      "type": "NBAR"
    },
    "indicativeNetworkIdentity": [
      {
        "id": "d88663aa-d827-4fa9-8715-b0e2bd4017d3",
        "instanceId": 14400,
        "authEntityId": 14400,
        "displayName": "14400",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "424",
        "protocol": "UDP",
        "upperPort": 0
      },
      {
        "id": "1fcc4269-b609-4846-8b0e-4e30594704bc",
        "instanceId": 14401,
        "authEntityId": 14401,
        "displayName": "14401",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "424",
        "protocol": "TCP",
        "upperPort": 0
      }
    ],
    "networkApplications": [
      {
        "id": "b4279656-64e1-4378-b10c-3b96bd052ad8",
        "instanceId": 11861,
        "authEntityId": 11861,
        "displayName": "11861",
        "authEntityClass": -217092956,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "appProtocol": "tcp/udp",
        "applicationGroup": "other",
        "applicationType": "DEFAULT",
        "categoryId": "6daa3dfd-3526-407a-88f0-97824b3f3a24",
        "createTime": 1517966743745,
        "encrypted": "false",
        "engineId": "3",
        "helpString": "IBM Operations Planning and Control Track",
        "ignoreConflict": false,
        "lastUpdateTime": 1517966743745,
        "longDescription": "Used by IBM in their Tivoli Operations Planning and Control product for planning, managing, and automating production workloads from a single point of control.",
        "name": "opc-job-track",
        "nbarId": "339",
        "p2pTechnology": "false",
        "popularity": 1,
        "rank": 65535,
        "references": "http://www-01.ibm.com/common/ssi/cgi-bin/ssialias?infotype=an&subtype=ca&htmlfid=897/ENUS298-217&appname=isource&language=enus",
        "selectorId": "424",
        "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
        "trafficClass": "BULK_DATA",
        "tunnel": "false"
      }
    ],
    "networkIdentity": [
      {
        "id": "fd7dac12-cc89-4db6-a547-34dd332325dc",
        "instanceId": 14398,
        "authEntityId": 14398,
        "displayName": "14398",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "424",
        "protocol": "UDP",
        "upperPort": 0
      },
      {
        "id": "50ba00c8-2056-4525-a04b-e4cd8bce0b35",
        "instanceId": 14399,
        "authEntityId": 14399,
        "displayName": "14399",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "424",
        "protocol": "TCP",
        "upperPort": 0
      }
    ],
    "parentScalableGroup": {
      "idRef": "000c15bf-28fe-4fbb-9b69-35e6502721f1"
    },
    "user": [

    ],
    "userGroup": [

    ]
  },
  {
    "id": "047eac17-6ecf-4cad-8dbf-1865c1b139db",
    "instanceId": 15351,
    "authEntityId": 15351,
    "displayName": "15351",
    "authEntityClass": -1909073334,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "createTime": 1517966744787,
    "deployed": false,
    "isSeeded": true,
    "isStale": false,
    "lastUpdateTime": 1517966744787,
    "name": "video-over-http",
    "namespace": "scalablegroup:application",
    "provisioningState": "DEFINED",
    "qualifier": "application",
    "resourceVersion": 0,
    "targetIdList": [

    ],
    "type": "scalablegroup",
    "cfsChangeInfo": [

    ],
    "customProvisions": [

    ],
    "scalableGroupExternalHandle": "video-over-http",
    "scalableGroupType": "APPLICATION",
    "securityGroupTag": 0,
    "state": "ACTIVE",
    "vnAgnostic": true,
    "identitySource": {
      "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
      "instanceId": 10010,
      "authEntityId": 10010,
      "displayName": "10010",
      "authEntityClass": 1738953278,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "state": "INACTIVE",
      "type": "NBAR"
    },
    "indicativeNetworkIdentity": [

    ],
    "networkApplications": [
      {
        "id": "709170ac-4528-4795-bea9-eca79a7ce44c",
        "instanceId": 16294,
        "authEntityId": 16294,
        "displayName": "16294",
        "authEntityClass": -217092956,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "appProtocol": "tcp",
        "applicationGroup": "other",
        "applicationType": "DEFAULT",
        "categoryId": "38a8e354-e2fe-4f3c-b1c5-2bbb90fb0d99",
        "createTime": 1517966744787,
        "encrypted": "false",
        "engineId": "13",
        "helpString": "Video traffic over HTTP",
        "ignoreConflict": false,
        "lastUpdateTime": 1517966744787,
        "longDescription": "Video over HTTP represents the transfer of video data over HTTP protocol. The classification identifies different video formats. If a flow is classified as a more specific protocol, it will not be classified by video-over-http.",
        "name": "video-over-http",
        "nbarId": "122",
        "p2pTechnology": "false",
        "popularity": 10,
        "rank": 65535,
        "references": "N/A",
        "selectorId": "432",
        "subCategory": "fe150151-77ab-4ac6-805f-77d0d642b798",
        "trafficClass": "MULTIMEDIA_STREAMING",
        "tunnel": "false"
      }
    ],
    "networkIdentity": [
      {
        "id": "4099e210-241b-40dc-b143-86faccd122ff",
        "instanceId": 17670,
        "authEntityId": 17670,
        "displayName": "17670",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "80",
        "protocol": "TCP",
        "upperPort": 0
      }
    ],
    "parentScalableGroup": {
      "idRef": "aad510ce-fe95-4dfc-ad06-e494641cc016"
    },
    "user": [

    ],
    "userGroup": [

    ]
  },
  {
    "id": "04bedcd8-b92c-43f0-8ac0-0821baecbb0e",
    "instanceId": 15155,
    "authEntityId": 15155,
    "displayName": "15155",
    "authEntityClass": -1909073334,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "createTime": 1517966744360,
    "deployed": false,
    "isSeeded": true,
    "isStale": false,
    "lastUpdateTime": 1517966744360,
    "name": "skydrive",
    "namespace": "scalablegroup:application",
    "provisioningState": "DEFINED",
    "qualifier": "application",
    "resourceVersion": 0,
    "targetIdList": [

    ],
    "type": "scalablegroup",
    "cfsChangeInfo": [

    ],
    "customProvisions": [

    ],
    "scalableGroupExternalHandle": "skydrive",
    "scalableGroupType": "APPLICATION",
    "securityGroupTag": 0,
    "state": "ACTIVE",
    "vnAgnostic": true,
    "identitySource": {
      "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
      "instanceId": 10010,
      "authEntityId": 10010,
      "displayName": "10010",
      "authEntityClass": 1738953278,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "state": "INACTIVE",
      "type": "NBAR"
    },
    "indicativeNetworkIdentity": [

    ],
    "networkApplications": [
      {
        "id": "03cf8f1c-284a-48ac-b2e3-13c4acda20b9",
        "instanceId": 16098,
        "authEntityId": 16098,
        "displayName": "16098",
        "authEntityClass": -217092956,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "appProtocol": "tcp/udp",
        "applicationGroup": "ms-cloud-group",
        "applicationType": "DEFAULT",
        "categoryId": "f6ec0221-b82d-43e8-a64d-163cbc13263b",
        "createTime": 1517966744360,
        "encrypted": "true",
        "engineId": "13",
        "helpString": "SkyDrive Cloud Storage Server From Microsoft",
        "ignoreConflict": false,
        "lastUpdateTime": 1517966744360,
        "longDescription": "Microsoft SkyDrive is a file hosting service that allows users to upload and sync files to a cloud storage and then access them from a web browser or a mobile application",
        "name": "skydrive",
        "nbarId": "1499",
        "p2pTechnology": "false",
        "popularity": 10,
        "rank": 65535,
        "references": "http://windows.microsoft.com/en-us/skydrive/download",
        "selectorId": "562",
        "subCategory": "9fecfe24-2ee3-4b19-bbf7-3c992d1d25b4",
        "trafficClass": "BULK_DATA",
        "tunnel": "false"
      }
    ],
    "networkIdentity": [
      {
        "id": "ad4082f2-6d8c-404c-abbd-b6e72003a6db",
        "instanceId": 17112,
        "authEntityId": 17112,
        "displayName": "17112",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "53,80,443,5353",
        "protocol": "TCP",
        "upperPort": 0
      },
      {
        "id": "c0895f91-1453-4c15-9186-210f3bb2a3a1",
        "instanceId": 17113,
        "authEntityId": 17113,
        "displayName": "17113",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "53,5353",
        "protocol": "UDP",
        "upperPort": 0
      }
    ],
    "parentScalableGroup": {
      "idRef": "7a1a3bbb-dc84-4a35-82a4-7e650c4c4761"
    },
    "user": [

    ],
    "userGroup": [

    ]
  },
  {
    "id": "04dcb56a-da36-45c3-86a0-94ab85b512fd",
    "instanceId": 4840,
    "authEntityId": 4840,
    "displayName": "4840",
    "authEntityClass": -1909073334,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "createTime": 1517966743594,
    "deployed": false,
    "isSeeded": true,
    "isStale": false,
    "lastUpdateTime": 1517966743594,
    "name": "netflow",
    "namespace": "scalablegroup:application",
    "provisioningState": "DEFINED",
    "qualifier": "application",
    "resourceVersion": 0,
    "targetIdList": [

    ],
    "type": "scalablegroup",
    "cfsChangeInfo": [

    ],
    "customProvisions": [

    ],
    "scalableGroupExternalHandle": "netflow",
    "scalableGroupType": "APPLICATION",
    "securityGroupTag": 0,
    "state": "ACTIVE",
    "vnAgnostic": true,
    "identitySource": {
      "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
      "instanceId": 10010,
      "authEntityId": 10010,
      "displayName": "10010",
      "authEntityClass": 1738953278,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "state": "INACTIVE",
      "type": "NBAR"
    },
    "indicativeNetworkIdentity": [

    ],
    "networkApplications": [
      {
        "id": "e7c22114-3fdf-47c7-8af9-7c26e96fcd3f",
        "instanceId": 11789,
        "authEntityId": 11789,
        "displayName": "11789",
        "authEntityClass": -217092956,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "applicationGroup": "other",
        "applicationType": "DEFAULT",
        "categoryId": "1fc17362-9334-4c7b-b752-5afdbb574017",
        "createTime": 1517966743594,
        "encrypted": "false",
        "engineId": "13",
        "helpString": "A network traffic monitoring and IP information collection protocol",
        "ignoreConflict": false,
        "lastUpdateTime": 1517966743594,
        "longDescription": "NetFlow is a network protocol developed by Cisco Systems for collecting IP traffic information and monitoring network traffic. This protocol classifies version 9 of Netflow, while version 10 is classified as IPFIX.",
        "name": "netflow",
        "nbarId": "1543",
        "p2pTechnology": "false",
        "popularity": 8,
        "rank": 65535,
        "references": "http://www.oiseyer.com/index-e.php",
        "selectorId": "604",
        "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
        "trafficClass": "OPS_ADMIN_MGMT",
        "tunnel": "false"
      }
    ],
    "networkIdentity": [

    ],
    "parentScalableGroup": {
      "idRef": "5fcd74d4-8726-47f7-a088-c457863da7ef"
    },
    "user": [

    ],
    "userGroup": [

    ]
  },
  {
    "id": "0511b79d-e567-43e1-8a9f-1804efcca77f",
    "instanceId": 15194,
    "authEntityId": 15194,
    "displayName": "15194",
    "authEntityClass": -1909073334,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "createTime": 1517966744449,
    "deployed": false,
    "isSeeded": true,
    "isStale": false,
    "lastUpdateTime": 1517966744449,
    "name": "sql-net",
    "namespace": "scalablegroup:application",
    "provisioningState": "DEFINED",
    "qualifier": "application",
    "resourceVersion": 0,
    "targetIdList": [

    ],
    "type": "scalablegroup",
    "cfsChangeInfo": [

    ],
    "customProvisions": [

    ],
    "scalableGroupExternalHandle": "sql-net",
    "scalableGroupType": "APPLICATION",
    "securityGroupTag": 0,
    "state": "ACTIVE",
    "vnAgnostic": true,
    "identitySource": {
      "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
      "instanceId": 10010,
      "authEntityId": 10010,
      "displayName": "10010",
      "authEntityClass": 1738953278,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "state": "INACTIVE",
      "type": "NBAR"
    },
    "indicativeNetworkIdentity": [
      {
        "id": "233e2cc6-daf3-4bf9-861e-7d9f8f8709f7",
        "instanceId": 17220,
        "authEntityId": 17220,
        "displayName": "17220",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "150",
        "protocol": "TCP",
        "upperPort": 0
      },
      {
        "id": "3bc98f0c-91b4-4975-aeb6-2564e2ea112d",
        "instanceId": 17221,
        "authEntityId": 17221,
        "displayName": "17221",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "150",
        "protocol": "UDP",
        "upperPort": 0
      }
    ],
    "networkApplications": [
      {
        "id": "2bce981d-57a1-4c48-b5c1-0da7eb9c04f7",
        "instanceId": 16137,
        "authEntityId": 16137,
        "displayName": "16137",
        "authEntityClass": -217092956,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "appProtocol": "tcp/udp",
        "applicationGroup": "sqlsvr-group",
        "applicationType": "DEFAULT",
        "categoryId": "cb5915d1-afec-4c30-ac9e-069ff382272f",
        "createTime": 1517966744449,
        "encrypted": "false",
        "engineId": "3",
        "helpString": "SQL-NET",
        "ignoreConflict": false,
        "lastUpdateTime": 1517966744449,
        "longDescription": "SQL*Net (or Net8) is a networking software developed by Oracle. It allows remote data-access between programs and the Oracle Database.",
        "name": "sql-net",
        "nbarId": "978",
        "p2pTechnology": "false",
        "popularity": 1,
        "rank": 65535,
        "references": "http://www.orafaq.com/wiki/SQL*Net",
        "selectorId": "150",
        "subCategory": "b90786f4-0190-45b8-9924-21b3e9682590",
        "trafficClass": "TRANSACTIONAL_DATA",
        "tunnel": "false"
      }
    ],
    "networkIdentity": [
      {
        "id": "6fc83a7f-ac96-4591-ae29-468811cd7485",
        "instanceId": 17218,
        "authEntityId": 17218,
        "displayName": "17218",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "150",
        "protocol": "UDP",
        "upperPort": 0
      },
      {
        "id": "a7103713-8193-4608-8739-e935b8195332",
        "instanceId": 17219,
        "authEntityId": 17219,
        "displayName": "17219",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "150",
        "protocol": "TCP",
        "upperPort": 0
      }
    ],
    "parentScalableGroup": {
      "idRef": "8718395e-683e-4a2d-a232-100202751271"
    },
    "user": [

    ],
    "userGroup": [

    ]
  },
  {
    "id": "053589e2-cf4a-415c-bdd8-7651c3eae779",
    "instanceId": 15027,
    "authEntityId": 15027,
    "displayName": "15027",
    "authEntityClass": -1909073334,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "createTime": 1517966744059,
    "deployed": false,
    "isSeeded": true,
    "isStale": false,
    "lastUpdateTime": 1517966744059,
    "name": "rakuten",
    "namespace": "scalablegroup:application",
    "provisioningState": "DEFINED",
    "qualifier": "application",
    "resourceVersion": 0,
    "targetIdList": [

    ],
    "type": "scalablegroup",
    "cfsChangeInfo": [

    ],
    "customProvisions": [

    ],
    "scalableGroupExternalHandle": "rakuten",
    "scalableGroupType": "APPLICATION",
    "securityGroupTag": 0,
    "state": "ACTIVE",
    "vnAgnostic": true,
    "identitySource": {
      "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
      "instanceId": 10010,
      "authEntityId": 10010,
      "displayName": "10010",
      "authEntityClass": 1738953278,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "state": "INACTIVE",
      "type": "NBAR"
    },
    "indicativeNetworkIdentity": [

    ],
    "networkApplications": [
      {
        "id": "357f8eee-88fd-4d84-aba2-1a5f792132e9",
        "instanceId": 11966,
        "authEntityId": 11966,
        "displayName": "11966",
        "authEntityClass": -217092956,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "appProtocol": "tcp/udp",
        "applicationGroup": "other",
        "applicationType": "DEFAULT",
        "categoryId": "878644b5-fe91-45b4-9204-31c84a73ba48",
        "createTime": 1517966744059,
        "encrypted": "false",
        "engineId": "13",
        "helpString": "Rakuten - Japanese e-commerce site.",
        "ignoreConflict": false,
        "lastUpdateTime": 1517966744059,
        "longDescription": "Rakuten - Japanese e-commerce site.",
        "name": "rakuten",
        "nbarId": "1447",
        "p2pTechnology": "false",
        "popularity": 3,
        "rank": 65535,
        "references": "http://rakuten.co.jp",
        "selectorId": "620",
        "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
        "trafficClass": "TRANSACTIONAL_DATA",
        "tunnel": "false"
      }
    ],
    "networkIdentity": [
      {
        "id": "835eb382-bf55-4f53-afff-b81e2293de77",
        "instanceId": 14707,
        "authEntityId": 14707,
        "displayName": "14707",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "53,5353",
        "protocol": "UDP",
        "upperPort": 0
      },
      {
        "id": "5cedb2ee-83b5-4dbf-8481-fc65963efc2a",
        "instanceId": 14708,
        "authEntityId": 14708,
        "displayName": "14708",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "53,80,443,5353",
        "protocol": "TCP",
        "upperPort": 0
      }
    ],
    "parentScalableGroup": {
      "idRef": "28e08de4-d7b2-4521-84ce-bc1e5ee0b9cc"
    },
    "user": [

    ],
    "userGroup": [

    ]
  },
  {
    "id": "054e41a6-da94-4435-9d6d-829efa86aa18",
    "instanceId": 15072,
    "authEntityId": 15072,
    "displayName": "15072",
    "authEntityClass": -1909073334,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "createTime": 1517966744169,
    "deployed": false,
    "isSeeded": true,
    "isStale": false,
    "lastUpdateTime": 1517966744169,
    "name": "rtelnet",
    "namespace": "scalablegroup:application",
    "provisioningState": "DEFINED",
    "qualifier": "application",
    "resourceVersion": 0,
    "targetIdList": [

    ],
    "type": "scalablegroup",
    "cfsChangeInfo": [

    ],
    "customProvisions": [

    ],
    "scalableGroupExternalHandle": "rtelnet",
    "scalableGroupType": "APPLICATION",
    "securityGroupTag": 0,
    "state": "ACTIVE",
    "vnAgnostic": true,
    "identitySource": {
      "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
      "instanceId": 10010,
      "authEntityId": 10010,
      "displayName": "10010",
      "authEntityClass": 1738953278,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "state": "INACTIVE",
      "type": "NBAR"
    },
    "indicativeNetworkIdentity": [
      {
        "id": "9bce3eff-56af-420c-ad0e-f626fdb3804b",
        "instanceId": 14861,
        "authEntityId": 14861,
        "displayName": "14861",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "107",
        "protocol": "TCP",
        "upperPort": 0
      },
      {
        "id": "de10024a-b819-43f0-bf3d-5b33890f17ed",
        "instanceId": 14862,
        "authEntityId": 14862,
        "displayName": "14862",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "107",
        "protocol": "UDP",
        "upperPort": 0
      }
    ],
    "networkApplications": [
      {
        "id": "dd39717e-bb48-4625-be70-b7f636ae097c",
        "instanceId": 12011,
        "authEntityId": 12011,
        "displayName": "12011",
        "authEntityClass": -217092956,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "appProtocol": "tcp/udp",
        "applicationGroup": "other",
        "applicationType": "DEFAULT",
        "categoryId": "1fc17362-9334-4c7b-b752-5afdbb574017",
        "createTime": 1517966744169,
        "encrypted": "false",
        "engineId": "3",
        "helpString": "Remote Telnet Service",
        "ignoreConflict": false,
        "lastUpdateTime": 1517966744169,
        "longDescription": "Remote Telnet Service (Rtelnet) is a SOCKS client version of Telnet in Unix-like systems. The RTelnet utility provides a functionality similar to Telnet for hosts that are behind a firewall.",
        "name": "rtelnet",
        "nbarId": "107",
        "p2pTechnology": "false",
        "popularity": 1,
        "rank": 65535,
        "references": "http://www.ietf.org/rfc/rfc818.txt",
        "selectorId": "107",
        "subCategory": "787a7d77-977b-4974-89d3-379ba49e88b7",
        "trafficClass": "OPS_ADMIN_MGMT",
        "tunnel": "false"
      }
    ],
    "networkIdentity": [
      {
        "id": "15071a4a-2067-4fde-8afa-78e6e0a95e12",
        "instanceId": 14859,
        "authEntityId": 14859,
        "displayName": "14859",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "107",
        "protocol": "UDP",
        "upperPort": 0
      },
      {
        "id": "968ca611-5d7e-417c-8822-9853ae940039",
        "instanceId": 14860,
        "authEntityId": 14860,
        "displayName": "14860",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "107",
        "protocol": "TCP",
        "upperPort": 0
      }
    ],
    "parentScalableGroup": {
      "idRef": "e06dd89f-0a70-4422-ab0b-c2314650a9a7"
    },
    "user": [

    ],
    "userGroup": [

    ]
  },
  {
    "id": "0550010f-9551-4ba7-9ab3-3d1b3c0e397e",
    "instanceId": 15127,
    "authEntityId": 15127,
    "displayName": "15127",
    "authEntityClass": -1909073334,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "createTime": 1517966744295,
    "deployed": false,
    "isSeeded": true,
    "isStale": false,
    "lastUpdateTime": 1517966744295,
    "name": "sfs-config",
    "namespace": "scalablegroup:application",
    "provisioningState": "DEFINED",
    "qualifier": "application",
    "resourceVersion": 0,
    "targetIdList": [

    ],
    "type": "scalablegroup",
    "cfsChangeInfo": [

    ],
    "customProvisions": [

    ],
    "scalableGroupExternalHandle": "sfs-config",
    "scalableGroupType": "APPLICATION",
    "securityGroupTag": 0,
    "state": "ACTIVE",
    "vnAgnostic": true,
    "identitySource": {
      "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
      "instanceId": 10010,
      "authEntityId": 10010,
      "displayName": "10010",
      "authEntityClass": 1738953278,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "state": "INACTIVE",
      "type": "NBAR"
    },
    "indicativeNetworkIdentity": [
      {
        "id": "b104ae6e-e4a5-4af5-92e4-2834088f41c7",
        "instanceId": 17028,
        "authEntityId": 17028,
        "displayName": "17028",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "452",
        "protocol": "UDP",
        "upperPort": 0
      },
      {
        "id": "1d471f46-777b-4d77-9702-72b273b7cfec",
        "instanceId": 17029,
        "authEntityId": 17029,
        "displayName": "17029",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "452",
        "protocol": "TCP",
        "upperPort": 0
      }
    ],
    "networkApplications": [
      {
        "id": "6b419a14-d7a2-4c00-b836-f940dc189035",
        "instanceId": 16070,
        "authEntityId": 16070,
        "displayName": "16070",
        "authEntityClass": -217092956,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "appProtocol": "tcp/udp",
        "applicationGroup": "other",
        "applicationType": "DEFAULT",
        "categoryId": "6daa3dfd-3526-407a-88f0-97824b3f3a24",
        "createTime": 1517966744295,
        "encrypted": "false",
        "engineId": "3",
        "helpString": "Cray SFS config server",
        "ignoreConflict": false,
        "lastUpdateTime": 1517966744295,
        "longDescription": "SFS config server is part of the Shared File Systems (SFS) is a high performance, HIPPI-based common ?le system which is shared among CRI UNICOS systems only.",
        "name": "sfs-config",
        "nbarId": "367",
        "p2pTechnology": "false",
        "popularity": 1,
        "rank": 65535,
        "references": "https://cug.org/5-publications/proceedings_attendee_lists/1997CD/S96PROC/186_189.PDF",
        "selectorId": "452",
        "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
        "trafficClass": "BULK_DATA",
        "tunnel": "false"
      }
    ],
    "networkIdentity": [
      {
        "id": "2970d67d-7fdf-44cc-a278-f3b662623a37",
        "instanceId": 17026,
        "authEntityId": 17026,
        "displayName": "17026",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "452",
        "protocol": "UDP",
        "upperPort": 0
      },
      {
        "id": "5a2b4ddb-544f-419d-b20d-498720f899bc",
        "instanceId": 17027,
        "authEntityId": 17027,
        "displayName": "17027",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "452",
        "protocol": "TCP",
        "upperPort": 0
      }
    ],
    "parentScalableGroup": {
      "idRef": "000c15bf-28fe-4fbb-9b69-35e6502721f1"
    },
    "user": [

    ],
    "userGroup": [

    ]
  },
  {
    "id": "0550b719-115a-46ea-b5a4-71b02f673028",
    "instanceId": 4826,
    "authEntityId": 4826,
    "displayName": "4826",
    "authEntityClass": -1909073334,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "createTime": 1517966743567,
    "deployed": false,
    "isSeeded": true,
    "isStale": false,
    "lastUpdateTime": 1517966743567,
    "name": "ncp",
    "namespace": "scalablegroup:application",
    "provisioningState": "DEFINED",
    "qualifier": "application",
    "resourceVersion": 0,
    "targetIdList": [

    ],
    "type": "scalablegroup",
    "cfsChangeInfo": [

    ],
    "customProvisions": [

    ],
    "scalableGroupExternalHandle": "ncp",
    "scalableGroupType": "APPLICATION",
    "securityGroupTag": 0,
    "state": "ACTIVE",
    "vnAgnostic": true,
    "identitySource": {
      "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
      "instanceId": 10010,
      "authEntityId": 10010,
      "displayName": "10010",
      "authEntityClass": 1738953278,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "state": "INACTIVE",
      "type": "NBAR"
    },
    "indicativeNetworkIdentity": [
      {
        "id": "08490258-e3c3-459a-ae33-1681800266f5",
        "instanceId": 14112,
        "authEntityId": 14112,
        "displayName": "14112",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "524",
        "protocol": "UDP",
        "upperPort": 0
      },
      {
        "id": "afb5fccf-67fe-4565-aec1-7415a3defea8",
        "instanceId": 14113,
        "authEntityId": 14113,
        "displayName": "14113",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "524",
        "protocol": "TCP",
        "upperPort": 0
      }
    ],
    "networkApplications": [
      {
        "id": "6b00e887-c6da-422e-9bb0-0ed44b1fe55a",
        "instanceId": 11775,
        "authEntityId": 11775,
        "displayName": "11775",
        "authEntityClass": -217092956,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "appProtocol": "tcp/udp",
        "applicationGroup": "other",
        "applicationType": "DEFAULT",
        "categoryId": "1fc17362-9334-4c7b-b752-5afdbb574017",
        "createTime": 1517966743567,
        "encrypted": "false",
        "engineId": "3",
        "helpString": "Network services Protocol",
        "ignoreConflict": false,
        "lastUpdateTime": 1517966743567,
        "longDescription": "NetWare Core Protocol (NCP) is a protocol used to access network service functions (such as access file, print, clock synchronization) remotely. The protocol is developed by Novell Inc. and is used by NetWare operating systems, as well as being partially used in other OS. Typically, NCP uses TCP/UDP port 524.",
        "name": "ncp",
        "nbarId": "442",
        "p2pTechnology": "false",
        "popularity": 1,
        "rank": 65535,
        "references": "http://www.faqs.org/rfcs/rfc801.html",
        "selectorId": "524",
        "subCategory": "f3c0f761-d894-4112-ab89-b90fc84fd660",
        "trafficClass": "OPS_ADMIN_MGMT",
        "tunnel": "false"
      }
    ],
    "networkIdentity": [
      {
        "id": "01704d14-c351-43b4-bef9-f275c1bb3748",
        "instanceId": 14110,
        "authEntityId": 14110,
        "displayName": "14110",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "524",
        "protocol": "UDP",
        "upperPort": 0
      },
      {
        "id": "135daebc-1549-4b8b-84cf-6e5f9b5682ac",
        "instanceId": 14111,
        "authEntityId": 14111,
        "displayName": "14111",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "524",
        "protocol": "TCP",
        "upperPort": 0
      }
    ],
    "parentScalableGroup": {
      "idRef": "5fcd74d4-8726-47f7-a088-c457863da7ef"
    },
    "user": [

    ],
    "userGroup": [

    ]
  },
  {
    "id": "055eb1cb-a197-4f43-9434-88938e66ab0d",
    "instanceId": 15076,
    "authEntityId": 15076,
    "displayName": "15076",
    "authEntityClass": -1909073334,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "createTime": 1517966744179,
    "deployed": false,
    "isSeeded": true,
    "isStale": false,
    "lastUpdateTime": 1517966744179,
    "name": "rtmpt",
    "namespace": "scalablegroup:application",
    "provisioningState": "DEFINED",
    "qualifier": "application",
    "resourceVersion": 0,
    "targetIdList": [

    ],
    "type": "scalablegroup",
    "cfsChangeInfo": [

    ],
    "customProvisions": [

    ],
    "scalableGroupExternalHandle": "rtmpt",
    "scalableGroupType": "APPLICATION",
    "securityGroupTag": 0,
    "state": "ACTIVE",
    "vnAgnostic": true,
    "identitySource": {
      "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
      "instanceId": 10010,
      "authEntityId": 10010,
      "displayName": "10010",
      "authEntityClass": 1738953278,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "state": "INACTIVE",
      "type": "NBAR"
    },
    "indicativeNetworkIdentity": [

    ],
    "networkApplications": [
      {
        "id": "2f0a4284-1dbd-4a8a-a422-73fa388bcbb4",
        "instanceId": 16019,
        "authEntityId": 16019,
        "displayName": "16019",
        "authEntityClass": -217092956,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "appProtocol": "tcp",
        "applicationGroup": "flash-group",
        "applicationType": "DEFAULT",
        "categoryId": "a09ef1b3-ba26-4372-aee8-309d50697e76",
        "createTime": 1517966744179,
        "encrypted": "false",
        "engineId": "13",
        "helpString": "Real Time Messaging Protocol tunneled over HTTP",
        "ignoreConflict": false,
        "lastUpdateTime": 1517966744179,
        "longDescription": "Real Time Messaging Protocol Tunneled (RTMPT) is a variation of Real Time Messaging Protocol (RTMP), which can work behind firewalls on Port 80 and encapsulate the RTMP data in HTTP requests. The encapsulated session may carry plain RTMP, RTMPS (RTMP over secure SSL), or RTMPE (RTMP Encrypted) packets.",
        "name": "rtmpt",
        "nbarId": "1420",
        "p2pTechnology": "false",
        "popularity": 9,
        "rank": 65535,
        "references": "http://wiki.wireshark.org/RTMPT",
        "selectorId": "491",
        "subCategory": "85a797c0-aef8-4284-91a7-677f33e29abb",
        "trafficClass": "MULTIMEDIA_STREAMING",
        "tunnel": "true"
      }
    ],
    "networkIdentity": [
      {
        "id": "b6ea636e-b0cc-4f07-8040-8d1b65c7f3da",
        "instanceId": 14867,
        "authEntityId": 14867,
        "displayName": "14867",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "80",
        "protocol": "TCP",
        "upperPort": 0
      }
    ],
    "parentScalableGroup": {
      "idRef": "fd6264c9-c16a-4311-a335-a3db039256f3"
    },
    "user": [

    ],
    "userGroup": [

    ]
  },
  {
    "id": "057e5f33-7c0b-4437-8a26-1cb27bed883d",
    "instanceId": 15236,
    "authEntityId": 15236,
    "displayName": "15236",
    "authEntityClass": -1909073334,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "createTime": 1517966744552,
    "deployed": false,
    "isSeeded": true,
    "isStale": false,
    "lastUpdateTime": 1517966744552,
    "name": "svn",
    "namespace": "scalablegroup:application",
    "provisioningState": "DEFINED",
    "qualifier": "application",
    "resourceVersion": 0,
    "targetIdList": [

    ],
    "type": "scalablegroup",
    "cfsChangeInfo": [

    ],
    "customProvisions": [

    ],
    "scalableGroupExternalHandle": "svn",
    "scalableGroupType": "APPLICATION",
    "securityGroupTag": 0,
    "state": "ACTIVE",
    "vnAgnostic": true,
    "identitySource": {
      "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
      "instanceId": 10010,
      "authEntityId": 10010,
      "displayName": "10010",
      "authEntityClass": 1738953278,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "state": "INACTIVE",
      "type": "NBAR"
    },
    "indicativeNetworkIdentity": [
      {
        "id": "8fc9aa5a-c383-4ee7-bd68-de7753830a2d",
        "instanceId": 17346,
        "authEntityId": 17346,
        "displayName": "17346",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "3690",
        "protocol": "UDP",
        "upperPort": 0
      },
      {
        "id": "5c814fea-d62d-4a4c-9a22-e3d15e9a375e",
        "instanceId": 17347,
        "authEntityId": 17347,
        "displayName": "17347",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "3690",
        "protocol": "TCP",
        "upperPort": 0
      }
    ],
    "networkApplications": [
      {
        "id": "3e9cee45-4542-4435-afac-1d8f86c7c021",
        "instanceId": 16179,
        "authEntityId": 16179,
        "displayName": "16179",
        "authEntityClass": -217092956,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "appProtocol": "tcp/udp",
        "applicationGroup": "other",
        "applicationType": "DEFAULT",
        "categoryId": "6daa3dfd-3526-407a-88f0-97824b3f3a24",
        "createTime": 1517966744552,
        "encrypted": "false",
        "engineId": "3",
        "helpString": "Apache subversion software revision control",
        "ignoreConflict": false,
        "lastUpdateTime": 1517966744552,
        "longDescription": "Apache Subversion (SVN) is a software versioning and revision control system distributed under an open source license. Developers use Subversion to maintain current and historical versions of files such as source code, web pages, and documentation. Its goal is to be a mostly-compatible successor to the widely used Concurrent Versions System (CVS).",
        "name": "svn",
        "nbarId": "1366",
        "p2pTechnology": "false",
        "popularity": 1,
        "rank": 65535,
        "references": "http://subversion.apache.org/",
        "selectorId": "3690",
        "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
        "trafficClass": "BULK_DATA",
        "tunnel": "false"
      }
    ],
    "networkIdentity": [
      {
        "id": "df3555e3-19e7-45d2-97c8-340822002e48",
        "instanceId": 17344,
        "authEntityId": 17344,
        "displayName": "17344",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "3690",
        "protocol": "UDP",
        "upperPort": 0
      },
      {
        "id": "00ff6823-fec7-4fd8-aa65-2f54b52d6935",
        "instanceId": 17345,
        "authEntityId": 17345,
        "displayName": "17345",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "3690",
        "protocol": "TCP",
        "upperPort": 0
      }
    ],
    "parentScalableGroup": {
      "idRef": "000c15bf-28fe-4fbb-9b69-35e6502721f1"
    },
    "user": [

    ],
    "userGroup": [

    ]
  },
  {
    "id": "059737ca-174a-4e41-9180-208aa073f0de",
    "instanceId": 4042,
    "authEntityId": 4042,
    "displayName": "4042",
    "authEntityClass": -1909073334,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "createTime": 1517966740543,
    "deployed": false,
    "isSeeded": true,
    "isStale": false,
    "lastUpdateTime": 1517966740543,
    "name": "CAIlic",
    "namespace": "scalablegroup:application",
    "provisioningState": "DEFINED",
    "qualifier": "application",
    "resourceVersion": 0,
    "targetIdList": [

    ],
    "type": "scalablegroup",
    "cfsChangeInfo": [

    ],
    "customProvisions": [

    ],
    "scalableGroupExternalHandle": "CAIlic",
    "scalableGroupType": "APPLICATION",
    "securityGroupTag": 0,
    "state": "ACTIVE",
    "vnAgnostic": true,
    "identitySource": {
      "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
      "instanceId": 10010,
      "authEntityId": 10010,
      "displayName": "10010",
      "authEntityClass": 1738953278,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "state": "INACTIVE",
      "type": "NBAR"
    },
    "indicativeNetworkIdentity": [
      {
        "id": "38fed13e-3980-499c-a239-29a8d74d6e41",
        "instanceId": 12032,
        "authEntityId": 12032,
        "displayName": "12032",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "216",
        "protocol": "TCP",
        "upperPort": 0
      },
      {
        "id": "0eb338a6-8cc9-4fd5-a177-60394fe7fac8",
        "instanceId": 12033,
        "authEntityId": 12033,
        "displayName": "12033",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "216",
        "protocol": "UDP",
        "upperPort": 0
      }
    ],
    "networkApplications": [
      {
        "id": "b9df9358-9174-419f-b640-1dc1d72f475c",
        "instanceId": 11017,
        "authEntityId": 11017,
        "displayName": "11017",
        "authEntityClass": -217092956,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "appProtocol": "tcp/udp",
        "applicationGroup": "other",
        "applicationType": "DEFAULT",
        "categoryId": "6daa3dfd-3526-407a-88f0-97824b3f3a24",
        "createTime": 1517966740543,
        "encrypted": "false",
        "engineId": "3",
        "helpString": "Computer Associates Intl License Server",
        "ignoreConflict": false,
        "lastUpdateTime": 1517966740543,
        "longDescription": "Registered with IANA on port 216 TCP/UDP",
        "name": "CAIlic",
        "nbarId": "1113",
        "p2pTechnology": "false",
        "popularity": 1,
        "rank": 65535,
        "references": "http://www.iana.org/assignments/service-names-port-numbers/service-names-port-numbers.xml",
        "selectorId": "216",
        "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
        "trafficClass": "BULK_DATA",
        "tunnel": "false"
      }
    ],
    "networkIdentity": [
      {
        "id": "3408a2b5-8113-459b-a3c3-22046688b9d8",
        "instanceId": 12030,
        "authEntityId": 12030,
        "displayName": "12030",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "216",
        "protocol": "TCP",
        "upperPort": 0
      },
      {
        "id": "3d2af4fe-1d49-40ad-83ab-519a85117327",
        "instanceId": 12031,
        "authEntityId": 12031,
        "displayName": "12031",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "216",
        "protocol": "UDP",
        "upperPort": 0
      }
    ],
    "parentScalableGroup": {
      "idRef": "000c15bf-28fe-4fbb-9b69-35e6502721f1"
    },
    "user": [

    ],
    "userGroup": [

    ]
  },
  {
    "id": "059aab18-d8dc-4d9b-87aa-472efa27ee55",
    "instanceId": 15264,
    "authEntityId": 15264,
    "displayName": "15264",
    "authEntityClass": -1909073334,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "createTime": 1517966744609,
    "deployed": false,
    "isSeeded": true,
    "isStale": false,
    "lastUpdateTime": 1517966744609,
    "name": "telegraph",
    "namespace": "scalablegroup:application",
    "provisioningState": "DEFINED",
    "qualifier": "application",
    "resourceVersion": 0,
    "targetIdList": [

    ],
    "type": "scalablegroup",
    "cfsChangeInfo": [

    ],
    "customProvisions": [

    ],
    "scalableGroupExternalHandle": "telegraph",
    "scalableGroupType": "APPLICATION",
    "securityGroupTag": 0,
    "state": "ACTIVE",
    "vnAgnostic": true,
    "identitySource": {
      "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
      "instanceId": 10010,
      "authEntityId": 10010,
      "displayName": "10010",
      "authEntityClass": 1738953278,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "state": "INACTIVE",
      "type": "NBAR"
    },
    "indicativeNetworkIdentity": [

    ],
    "networkApplications": [
      {
        "id": "8f97352e-0078-4875-88d3-947405b7fb99",
        "instanceId": 16207,
        "authEntityId": 16207,
        "displayName": "16207",
        "authEntityClass": -217092956,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "appProtocol": "tcp/udp",
        "applicationGroup": "other",
        "applicationType": "DEFAULT",
        "categoryId": "878644b5-fe91-45b4-9204-31c84a73ba48",
        "createTime": 1517966744609,
        "encrypted": "false",
        "engineId": "13",
        "helpString": "The Telegraph - Online news portal.",
        "ignoreConflict": false,
        "lastUpdateTime": 1517966744609,
        "longDescription": "The Telegraph - Online news portal.",
        "name": "telegraph",
        "nbarId": "1595",
        "p2pTechnology": "false",
        "popularity": 3,
        "rank": 65535,
        "references": "http://telegraph.co.uk",
        "selectorId": "666",
        "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
        "trafficClass": "TRANSACTIONAL_DATA",
        "tunnel": "false"
      }
    ],
    "networkIdentity": [
      {
        "id": "27069596-f5e7-4eb6-92ee-a619879ac67e",
        "instanceId": 17427,
        "authEntityId": 17427,
        "displayName": "17427",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "53,5353",
        "protocol": "UDP",
        "upperPort": 0
      },
      {
        "id": "2c82be23-f5ed-4c2f-b964-760589fb0bad",
        "instanceId": 17428,
        "authEntityId": 17428,
        "displayName": "17428",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "53,80,443,5353",
        "protocol": "TCP",
        "upperPort": 0
      }
    ],
    "parentScalableGroup": {
      "idRef": "28e08de4-d7b2-4521-84ce-bc1e5ee0b9cc"
    },
    "user": [

    ],
    "userGroup": [

    ]
  },
  {
    "id": "05c6f33d-28bf-4571-980f-8d36f2556737",
    "instanceId": 4888,
    "authEntityId": 4888,
    "displayName": "4888",
    "authEntityClass": -1909073334,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "createTime": 1517966743687,
    "deployed": false,
    "isSeeded": true,
    "isStale": false,
    "lastUpdateTime": 1517966743687,
    "name": "ntalk",
    "namespace": "scalablegroup:application",
    "provisioningState": "DEFINED",
    "qualifier": "application",
    "resourceVersion": 0,
    "targetIdList": [

    ],
    "type": "scalablegroup",
    "cfsChangeInfo": [

    ],
    "customProvisions": [

    ],
    "scalableGroupExternalHandle": "ntalk",
    "scalableGroupType": "APPLICATION",
    "securityGroupTag": 0,
    "state": "ACTIVE",
    "vnAgnostic": true,
    "identitySource": {
      "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
      "instanceId": 10010,
      "authEntityId": 10010,
      "displayName": "10010",
      "authEntityClass": 1738953278,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "state": "INACTIVE",
      "type": "NBAR"
    },
    "indicativeNetworkIdentity": [
      {
        "id": "e45b99df-21a4-41eb-85e9-f151bc3833ad",
        "instanceId": 14316,
        "authEntityId": 14316,
        "displayName": "14316",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "518",
        "protocol": "TCP",
        "upperPort": 0
      },
      {
        "id": "b5d747c9-e3f6-4d9e-b7bb-948a2bcfec14",
        "instanceId": 14317,
        "authEntityId": 14317,
        "displayName": "14317",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "518",
        "protocol": "UDP",
        "upperPort": 0
      }
    ],
    "networkApplications": [
      {
        "id": "3ee941fd-9ae1-4ac0-a22f-f8d2c78f283a",
        "instanceId": 11837,
        "authEntityId": 11837,
        "displayName": "11837",
        "authEntityClass": -217092956,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "appProtocol": "tcp/udp",
        "applicationGroup": "other",
        "applicationType": "DEFAULT",
        "categoryId": "0f6cab47-be95-4124-9db3-360ccd55f983",
        "createTime": 1517966743687,
        "encrypted": "false",
        "engineId": "3",
        "helpString": "ntalk",
        "ignoreConflict": false,
        "lastUpdateTime": 1517966743687,
        "longDescription": "ntalk is a chat application. It works in a client-server model and it's designed to work in all kinds of networks. ntalk was written to be much more easy-to-use, friendly and functional than Unix talk.",
        "name": "ntalk",
        "nbarId": "435",
        "p2pTechnology": "false",
        "popularity": 1,
        "rank": 65535,
        "references": "http://sourceforge.net/projects/ntalk/",
        "selectorId": "518",
        "subCategory": "118c10a2-e272-47c1-92b9-4814ebe97138",
        "trafficClass": "MULTIMEDIA_CONFERENCING",
        "tunnel": "false"
      }
    ],
    "networkIdentity": [
      {
        "id": "c00abb9b-b15c-45e0-b91d-2344f83aebe3",
        "instanceId": 14314,
        "authEntityId": 14314,
        "displayName": "14314",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "518",
        "protocol": "TCP",
        "upperPort": 0
      },
      {
        "id": "bd9cea45-4349-4d15-a828-7a2bbb0644cd",
        "instanceId": 14315,
        "authEntityId": 14315,
        "displayName": "14315",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "518",
        "protocol": "UDP",
        "upperPort": 0
      }
    ],
    "parentScalableGroup": {
      "idRef": "fd6264c9-c16a-4311-a335-a3db039256f3"
    },
    "user": [

    ],
    "userGroup": [

    ]
  },
  {
    "id": "05d4edc1-137c-443a-818e-db836d866313",
    "instanceId": 15195,
    "authEntityId": 15195,
    "displayName": "15195",
    "authEntityClass": -1909073334,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "createTime": 1517966744452,
    "deployed": false,
    "isSeeded": true,
    "isStale": false,
    "lastUpdateTime": 1517966744452,
    "name": "sqlexec",
    "namespace": "scalablegroup:application",
    "provisioningState": "DEFINED",
    "qualifier": "application",
    "resourceVersion": 0,
    "targetIdList": [

    ],
    "type": "scalablegroup",
    "cfsChangeInfo": [

    ],
    "customProvisions": [

    ],
    "scalableGroupExternalHandle": "sqlexec",
    "scalableGroupType": "APPLICATION",
    "securityGroupTag": 0,
    "state": "ACTIVE",
    "vnAgnostic": true,
    "identitySource": {
      "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
      "instanceId": 10010,
      "authEntityId": 10010,
      "displayName": "10010",
      "authEntityClass": 1738953278,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "state": "INACTIVE",
      "type": "NBAR"
    },
    "indicativeNetworkIdentity": [
      {
        "id": "15efea13-742b-4b82-882d-067b6146f3b3",
        "instanceId": 17224,
        "authEntityId": 17224,
        "displayName": "17224",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "9088",
        "protocol": "TCP",
        "upperPort": 0
      },
      {
        "id": "69617b48-e104-4b16-8b2d-df3c00a2d90c",
        "instanceId": 17225,
        "authEntityId": 17225,
        "displayName": "17225",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "9088",
        "protocol": "UDP",
        "upperPort": 0
      }
    ],
    "networkApplications": [
      {
        "id": "76887322-4e5b-4e5d-8c0d-bb4bb4b119dc",
        "instanceId": 16138,
        "authEntityId": 16138,
        "displayName": "16138",
        "authEntityClass": -217092956,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "appProtocol": "tcp/udp",
        "applicationGroup": "sqlsvr-group",
        "applicationType": "DEFAULT",
        "categoryId": "cb5915d1-afec-4c30-ac9e-069ff382272f",
        "createTime": 1517966744452,
        "encrypted": "false",
        "engineId": "3",
        "helpString": "SQL Informix",
        "ignoreConflict": false,
        "lastUpdateTime": 1517966744452,
        "longDescription": "IBM Informix SQL is a database application development system that features a suite of application development tools for small and large database applications. The Informix SQL suite includes a schema editor, menu builder, SQL editor, forms builder, and report writer.",
        "name": "sqlexec",
        "nbarId": "90",
        "p2pTechnology": "false",
        "popularity": 1,
        "rank": 65535,
        "references": "http://www-01.ibm.com/software/data/informix/tools/isql/",
        "selectorId": "9088",
        "subCategory": "b90786f4-0190-45b8-9924-21b3e9682590",
        "trafficClass": "TRANSACTIONAL_DATA",
        "tunnel": "false"
      }
    ],
    "networkIdentity": [
      {
        "id": "a7dc3026-845c-4d8b-80f9-f26c6069db50",
        "instanceId": 17222,
        "authEntityId": 17222,
        "displayName": "17222",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "9088",
        "protocol": "TCP",
        "upperPort": 0
      },
      {
        "id": "b8188148-6d92-4526-b1f9-8d56360eb13b",
        "instanceId": 17223,
        "authEntityId": 17223,
        "displayName": "17223",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "9088",
        "protocol": "UDP",
        "upperPort": 0
      }
    ],
    "parentScalableGroup": {
      "idRef": "8718395e-683e-4a2d-a232-100202751271"
    },
    "user": [

    ],
    "userGroup": [

    ]
  },
  {
    "id": "05e1b3f5-94c5-4183-b547-31529c096d20",
    "instanceId": 4625,
    "authEntityId": 4625,
    "displayName": "4625",
    "authEntityClass": -1909073334,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "createTime": 1517966743153,
    "deployed": false,
    "isSeeded": true,
    "isStale": false,
    "lastUpdateTime": 1517966743153,
    "name": "iplt",
    "namespace": "scalablegroup:application",
    "provisioningState": "DEFINED",
    "qualifier": "application",
    "resourceVersion": 0,
    "targetIdList": [

    ],
    "type": "scalablegroup",
    "cfsChangeInfo": [

    ],
    "customProvisions": [

    ],
    "scalableGroupExternalHandle": "iplt",
    "scalableGroupType": "APPLICATION",
    "securityGroupTag": 0,
    "state": "ACTIVE",
    "vnAgnostic": true,
    "identitySource": {
      "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
      "instanceId": 10010,
      "authEntityId": 10010,
      "displayName": "10010",
      "authEntityClass": 1738953278,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "state": "INACTIVE",
      "type": "NBAR"
    },
    "indicativeNetworkIdentity": [
      {
        "id": "5c9c4c86-d4ac-453b-aee7-7395a6475f85",
        "instanceId": 13576,
        "authEntityId": 13576,
        "displayName": "13576",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipProtocolNumbers": "129",
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "protocol": "IPLT",
        "upperPort": 0
      }
    ],
    "networkApplications": [
      {
        "id": "f6a58540-865d-445f-8c2c-4c19cd5a8b4e",
        "instanceId": 11575,
        "authEntityId": 11575,
        "displayName": "11575",
        "authEntityClass": -217092956,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "appProtocol": "ip",
        "applicationGroup": "other",
        "applicationType": "DEFAULT",
        "categoryId": "6daa3dfd-3526-407a-88f0-97824b3f3a24",
        "createTime": 1517966743153,
        "encrypted": "false",
        "engineId": "1",
        "helpString": "IPLT",
        "ignoreConflict": false,
        "lastUpdateTime": 1517966743153,
        "longDescription": "Registered with IANA as IP Protocol 129",
        "name": "iplt",
        "nbarId": "1227",
        "p2pTechnology": "false",
        "popularity": 1,
        "rank": 65535,
        "references": "http://www.iana.org/assignments/protocol-numbers/protocol-numbers.xml",
        "selectorId": "129",
        "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
        "trafficClass": "BULK_DATA",
        "tunnel": "false"
      }
    ],
    "networkIdentity": [
      {
        "id": "8143f834-a71a-46fd-90f5-e4854359cdcf",
        "instanceId": 13575,
        "authEntityId": 13575,
        "displayName": "13575",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipProtocolNumbers": "129",
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "protocol": "IPLT",
        "upperPort": 0
      }
    ],
    "parentScalableGroup": {
      "idRef": "000c15bf-28fe-4fbb-9b69-35e6502721f1"
    },
    "user": [

    ],
    "userGroup": [

    ]
  },
  {
    "id": "05eabc04-0d4b-46a9-ac47-161481f3ddf9",
    "instanceId": 4630,
    "authEntityId": 4630,
    "displayName": "4630",
    "authEntityClass": -1909073334,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "createTime": 1517966743163,
    "deployed": false,
    "isSeeded": true,
    "isStale": false,
    "lastUpdateTime": 1517966743163,
    "name": "ipv6-icmp",
    "namespace": "scalablegroup:application",
    "provisioningState": "DEFINED",
    "qualifier": "application",
    "resourceVersion": 0,
    "targetIdList": [

    ],
    "type": "scalablegroup",
    "cfsChangeInfo": [

    ],
    "customProvisions": [

    ],
    "scalableGroupExternalHandle": "ipv6-icmp",
    "scalableGroupType": "APPLICATION",
    "securityGroupTag": 0,
    "state": "ACTIVE",
    "vnAgnostic": true,
    "identitySource": {
      "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
      "instanceId": 10010,
      "authEntityId": 10010,
      "displayName": "10010",
      "authEntityClass": 1738953278,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "state": "INACTIVE",
      "type": "NBAR"
    },
    "indicativeNetworkIdentity": [
      {
        "id": "3a1f49a1-9ecf-4a6f-b26b-c3a4e3575a9e",
        "instanceId": 13584,
        "authEntityId": 13584,
        "displayName": "13584",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipProtocolNumbers": "58",
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "protocol": "IPV6_ICMP",
        "upperPort": 0
      }
    ],
    "networkApplications": [
      {
        "id": "48a09186-3861-4949-a589-94aad8144604",
        "instanceId": 11580,
        "authEntityId": 11580,
        "displayName": "11580",
        "authEntityClass": -217092956,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "appProtocol": "ip",
        "applicationGroup": "other",
        "applicationType": "DEFAULT",
        "categoryId": "1fc17362-9334-4c7b-b752-5afdbb574017",
        "createTime": 1517966743163,
        "encrypted": "unassigned",
        "engineId": "1",
        "helpString": "ICMP for IPv6",
        "ignoreConflict": false,
        "lastUpdateTime": 1517966743163,
        "longDescription": "Internet Control Message Protocol version 6 (ICMPv6) is the implementation of the Internet Control Message Protocol (ICMP) for Internet Protocol version 6 (IPv6). ICMPv6 is an integral part of IPv6 and performs error reporting, diagnostic functions (e.g., ping), and a framework for extensions to implement future changes.",
        "name": "ipv6-icmp",
        "nbarId": "812",
        "p2pTechnology": "unassigned",
        "popularity": 9,
        "rank": 65535,
        "references": "http://tools.ietf.org/html/rfc4443",
        "selectorId": "58",
        "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
        "trafficClass": "OPS_ADMIN_MGMT",
        "tunnel": "unassigned"
      }
    ],
    "networkIdentity": [
      {
        "id": "6851ff59-e0ab-4b34-834c-877cbee8822b",
        "instanceId": 13583,
        "authEntityId": 13583,
        "displayName": "13583",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipProtocolNumbers": "58",
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "protocol": "IPV6_ICMP",
        "upperPort": 0
      }
    ],
    "parentScalableGroup": {
      "idRef": "5fcd74d4-8726-47f7-a088-c457863da7ef"
    },
    "user": [

    ],
    "userGroup": [

    ]
  },
  {
    "id": "05f3e22c-bedb-43c0-8a5f-aa2f0d4a782f",
    "instanceId": 4443,
    "authEntityId": 4443,
    "displayName": "4443",
    "authEntityClass": -1909073334,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "createTime": 1517966742674,
    "deployed": false,
    "isSeeded": true,
    "isStale": false,
    "lastUpdateTime": 1517966742674,
    "name": "fc",
    "namespace": "scalablegroup:application",
    "provisioningState": "DEFINED",
    "qualifier": "application",
    "resourceVersion": 0,
    "targetIdList": [

    ],
    "type": "scalablegroup",
    "cfsChangeInfo": [

    ],
    "customProvisions": [

    ],
    "scalableGroupExternalHandle": "fc",
    "scalableGroupType": "APPLICATION",
    "securityGroupTag": 0,
    "state": "ACTIVE",
    "vnAgnostic": true,
    "identitySource": {
      "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
      "instanceId": 10010,
      "authEntityId": 10010,
      "displayName": "10010",
      "authEntityClass": 1738953278,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "state": "INACTIVE",
      "type": "NBAR"
    },
    "indicativeNetworkIdentity": [
      {
        "id": "5352b49c-540b-496f-9683-da9d17956c16",
        "instanceId": 13107,
        "authEntityId": 13107,
        "displayName": "13107",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipProtocolNumbers": "133",
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "protocol": "FC",
        "upperPort": 0
      }
    ],
    "networkApplications": [
      {
        "id": "73778991-9a07-40ef-b302-482ccfe7530e",
        "instanceId": 11394,
        "authEntityId": 11394,
        "displayName": "11394",
        "authEntityClass": -217092956,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "appProtocol": "ip",
        "applicationGroup": "other",
        "applicationType": "DEFAULT",
        "categoryId": "6daa3dfd-3526-407a-88f0-97824b3f3a24",
        "createTime": 1517966742674,
        "encrypted": "false",
        "engineId": "1",
        "helpString": "Fibre Channel",
        "ignoreConflict": false,
        "lastUpdateTime": 1517966742674,
        "longDescription": "Internet Fibre Channel Protocol (iFCP) is a gateway-to-gateway network protocol standard which provides Fibre Channel (FC) fabric functionality to fibre channel devices over an IP network. Within the IP network, the fibre channel switching and routing infrastructure is replaced by IP components and technology.",
        "name": "fc",
        "nbarId": "1231",
        "p2pTechnology": "false",
        "popularity": 2,
        "rank": 65535,
        "references": "http://en.wikipedia.org/wiki/Internet_Fibre_Channel_Protocol",
        "selectorId": "133",
        "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
        "trafficClass": "BULK_DATA",
        "tunnel": "false"
      }
    ],
    "networkIdentity": [
      {
        "id": "114f469f-56bc-4d5b-b846-ebf42daf565a",
        "instanceId": 13106,
        "authEntityId": 13106,
        "displayName": "13106",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipProtocolNumbers": "133",
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "protocol": "FC",
        "upperPort": 0
      }
    ],
    "parentScalableGroup": {
      "idRef": "000c15bf-28fe-4fbb-9b69-35e6502721f1"
    },
    "user": [

    ],
    "userGroup": [

    ]
  },
  {
    "id": "06249721-24e9-41a4-980b-21178c851a36",
    "instanceId": 4350,
    "authEntityId": 4350,
    "displayName": "4350",
    "authEntityClass": -1909073334,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "createTime": 1517966742366,
    "deployed": false,
    "isSeeded": true,
    "isStale": false,
    "lastUpdateTime": 1517966742366,
    "name": "deos",
    "namespace": "scalablegroup:application",
    "provisioningState": "DEFINED",
    "qualifier": "application",
    "resourceVersion": 0,
    "targetIdList": [

    ],
    "type": "scalablegroup",
    "cfsChangeInfo": [

    ],
    "customProvisions": [

    ],
    "scalableGroupExternalHandle": "deos",
    "scalableGroupType": "APPLICATION",
    "securityGroupTag": 0,
    "state": "ACTIVE",
    "vnAgnostic": true,
    "identitySource": {
      "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
      "instanceId": 10010,
      "authEntityId": 10010,
      "displayName": "10010",
      "authEntityClass": 1738953278,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "state": "INACTIVE",
      "type": "NBAR"
    },
    "indicativeNetworkIdentity": [
      {
        "id": "fe72278d-f77f-4a78-b831-8379d58b4e8e",
        "instanceId": 12832,
        "authEntityId": 12832,
        "displayName": "12832",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "76",
        "protocol": "UDP",
        "upperPort": 0
      },
      {
        "id": "75c4bf68-7b50-45d7-bdb5-628290d5b99d",
        "instanceId": 12833,
        "authEntityId": 12833,
        "displayName": "12833",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "76",
        "protocol": "TCP",
        "upperPort": 0
      }
    ],
    "networkApplications": [
      {
        "id": "ec9848db-738d-4d14-8ac9-d8e0ab8ac4e5",
        "instanceId": 11301,
        "authEntityId": 11301,
        "displayName": "11301",
        "authEntityClass": -217092956,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "appProtocol": "tcp/udp",
        "applicationGroup": "other",
        "applicationType": "DEFAULT",
        "categoryId": "6daa3dfd-3526-407a-88f0-97824b3f3a24",
        "createTime": 1517966742366,
        "encrypted": "false",
        "engineId": "3",
        "helpString": "Distributed External Object Store",
        "ignoreConflict": false,
        "lastUpdateTime": 1517966742366,
        "longDescription": "Registered with IANA on port 76 TCP/UDP",
        "name": "deos",
        "nbarId": "952",
        "p2pTechnology": "false",
        "popularity": 1,
        "rank": 65535,
        "references": "http://www.iana.org/assignments/service-names-port-numbers/service-names-port-numbers.xml",
        "selectorId": "76",
        "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
        "trafficClass": "BULK_DATA",
        "tunnel": "false"
      }
    ],
    "networkIdentity": [
      {
        "id": "f58a3953-c710-40b8-bfd7-70f8ce82f277",
        "instanceId": 12830,
        "authEntityId": 12830,
        "displayName": "12830",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "76",
        "protocol": "UDP",
        "upperPort": 0
      },
      {
        "id": "114373f5-003b-438c-a264-0021d89427ed",
        "instanceId": 12831,
        "authEntityId": 12831,
        "displayName": "12831",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "76",
        "protocol": "TCP",
        "upperPort": 0
      }
    ],
    "parentScalableGroup": {
      "idRef": "000c15bf-28fe-4fbb-9b69-35e6502721f1"
    },
    "user": [

    ],
    "userGroup": [

    ]
  },
  {
    "id": "065a667b-5be4-4cb7-9624-be1080cb38e2",
    "instanceId": 4695,
    "authEntityId": 4695,
    "displayName": "4695",
    "authEntityClass": -1909073334,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "createTime": 1517966743301,
    "deployed": false,
    "isSeeded": true,
    "isStale": false,
    "lastUpdateTime": 1517966743301,
    "name": "linkedin",
    "namespace": "scalablegroup:application",
    "provisioningState": "DEFINED",
    "qualifier": "application",
    "resourceVersion": 0,
    "targetIdList": [

    ],
    "type": "scalablegroup",
    "cfsChangeInfo": [

    ],
    "customProvisions": [

    ],
    "scalableGroupExternalHandle": "linkedin",
    "scalableGroupType": "APPLICATION",
    "securityGroupTag": 0,
    "state": "ACTIVE",
    "vnAgnostic": true,
    "identitySource": {
      "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
      "instanceId": 10010,
      "authEntityId": 10010,
      "displayName": "10010",
      "authEntityClass": 1738953278,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "state": "INACTIVE",
      "type": "NBAR"
    },
    "indicativeNetworkIdentity": [

    ],
    "networkApplications": [
      {
        "id": "9fd582a4-17cc-4e94-9e53-d387c082bcad",
        "instanceId": 11645,
        "authEntityId": 11645,
        "displayName": "11645",
        "authEntityClass": -217092956,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "appProtocol": "tcp/udp",
        "applicationGroup": "other",
        "applicationType": "DEFAULT",
        "categoryId": "9eb6d680-796f-478a-9106-1151f00f7eff",
        "createTime": 1517966743301,
        "encrypted": "true",
        "engineId": "13",
        "helpString": "LinkedIn Professional Network",
        "ignoreConflict": false,
        "lastUpdateTime": 1517966743301,
        "longDescription": "LinkedIn is a business-oriented social networking site. It is mainly used for professional networking.",
        "name": "linkedin",
        "nbarId": "1463",
        "p2pTechnology": "false",
        "popularity": 10,
        "rank": 65535,
        "references": "http://www.linkedin.com/",
        "selectorId": "527",
        "subCategory": "aab99e59-8589-4a61-933e-f01bff556d78",
        "trafficClass": "TRANSACTIONAL_DATA",
        "tunnel": "false"
      }
    ],
    "networkIdentity": [
      {
        "id": "64355049-5d8d-4234-b019-fa52604feb7d",
        "instanceId": 13758,
        "authEntityId": 13758,
        "displayName": "13758",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "53,5353",
        "protocol": "UDP",
        "upperPort": 0
      },
      {
        "id": "5d588b48-75a3-4c5b-a3a6-f3c31b23af36",
        "instanceId": 13759,
        "authEntityId": 13759,
        "displayName": "13759",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "53,80,443,5353",
        "protocol": "TCP",
        "upperPort": 0
      }
    ],
    "parentScalableGroup": {
      "idRef": "82348eb1-d8b8-4daf-8532-15e1a5f9cdd0"
    },
    "user": [

    ],
    "userGroup": [

    ]
  },
  {
    "id": "066a126b-28ab-4208-9e6e-12fa32c385ef",
    "instanceId": 15171,
    "authEntityId": 15171,
    "displayName": "15171",
    "authEntityClass": -1909073334,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "createTime": 1517966744397,
    "deployed": false,
    "isSeeded": true,
    "isStale": false,
    "lastUpdateTime": 1517966744397,
    "name": "snare",
    "namespace": "scalablegroup:application",
    "provisioningState": "DEFINED",
    "qualifier": "application",
    "resourceVersion": 0,
    "targetIdList": [

    ],
    "type": "scalablegroup",
    "cfsChangeInfo": [

    ],
    "customProvisions": [

    ],
    "scalableGroupExternalHandle": "snare",
    "scalableGroupType": "APPLICATION",
    "securityGroupTag": 0,
    "state": "ACTIVE",
    "vnAgnostic": true,
    "identitySource": {
      "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
      "instanceId": 10010,
      "authEntityId": 10010,
      "displayName": "10010",
      "authEntityClass": 1738953278,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "state": "INACTIVE",
      "type": "NBAR"
    },
    "indicativeNetworkIdentity": [
      {
        "id": "db6f3c02-3248-4a99-8fd4-5965f3d38281",
        "instanceId": 17163,
        "authEntityId": 17163,
        "displayName": "17163",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "509",
        "protocol": "TCP",
        "upperPort": 0
      },
      {
        "id": "16db1f6e-7002-45c6-8e26-10cc589f6e66",
        "instanceId": 17164,
        "authEntityId": 17164,
        "displayName": "17164",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "509",
        "protocol": "UDP",
        "upperPort": 0
      }
    ],
    "networkApplications": [
      {
        "id": "8232e787-b911-4a00-a4a9-d44a78c33dd2",
        "instanceId": 16114,
        "authEntityId": 16114,
        "displayName": "16114",
        "authEntityClass": -217092956,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "appProtocol": "tcp/udp",
        "applicationGroup": "other",
        "applicationType": "DEFAULT",
        "categoryId": "1fc17362-9334-4c7b-b752-5afdbb574017",
        "createTime": 1517966744397,
        "encrypted": "false",
        "engineId": "3",
        "helpString": "snare",
        "ignoreConflict": false,
        "lastUpdateTime": 1517966744397,
        "longDescription": "System iNtrusion Analysis and Reporting Environment (SNARE) is a group of open-source agents, and a commercial server, used to collect audit log data from a variety of operating systems and applications to facilitate centralised log analysis. Agents are available for Linux, Windows, Solaris, IIS, Lotus Notes, Irix, AIX, ISA and more.",
        "name": "snare",
        "nbarId": "423",
        "p2pTechnology": "false",
        "popularity": 1,
        "rank": 65535,
        "references": "http://en.wikipedia.org/wiki/Snare_(software)",
        "selectorId": "509",
        "subCategory": "f3c0f761-d894-4112-ab89-b90fc84fd660",
        "trafficClass": "OPS_ADMIN_MGMT",
        "tunnel": "false"
      }
    ],
    "networkIdentity": [
      {
        "id": "03ebba7c-24cc-46f8-a348-c828e94533dd",
        "instanceId": 17161,
        "authEntityId": 17161,
        "displayName": "17161",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "509",
        "protocol": "TCP",
        "upperPort": 0
      },
      {
        "id": "93d33498-28d7-4e2f-b34a-cc217b9570d7",
        "instanceId": 17162,
        "authEntityId": 17162,
        "displayName": "17162",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "509",
        "protocol": "UDP",
        "upperPort": 0
      }
    ],
    "parentScalableGroup": {
      "idRef": "5fcd74d4-8726-47f7-a088-c457863da7ef"
    },
    "user": [

    ],
    "userGroup": [

    ]
  },
  {
    "id": "0696496b-2c10-4692-86f0-410093947ca9",
    "instanceId": 15017,
    "authEntityId": 15017,
    "displayName": "15017",
    "authEntityClass": -1909073334,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "createTime": 1517966744035,
    "deployed": false,
    "isSeeded": true,
    "isStale": false,
    "lastUpdateTime": 1517966744035,
    "name": "qq-accounts",
    "namespace": "scalablegroup:application",
    "provisioningState": "DEFINED",
    "qualifier": "application",
    "resourceVersion": 0,
    "targetIdList": [

    ],
    "type": "scalablegroup",
    "cfsChangeInfo": [

    ],
    "customProvisions": [

    ],
    "scalableGroupExternalHandle": "qq-accounts",
    "scalableGroupType": "APPLICATION",
    "securityGroupTag": 0,
    "state": "ACTIVE",
    "vnAgnostic": true,
    "identitySource": {
      "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
      "instanceId": 10010,
      "authEntityId": 10010,
      "displayName": "10010",
      "authEntityClass": 1738953278,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "state": "INACTIVE",
      "type": "NBAR"
    },
    "indicativeNetworkIdentity": [

    ],
    "networkApplications": [
      {
        "id": "a1f9e4ab-83f7-4ac5-9ace-3d8a07bb2589",
        "instanceId": 11956,
        "authEntityId": 11956,
        "displayName": "11956",
        "authEntityClass": -217092956,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "appProtocol": "tcp/udp",
        "applicationGroup": "qq-group",
        "applicationType": "DEFAULT",
        "categoryId": "f6ec0221-b82d-43e8-a64d-163cbc13263b",
        "createTime": 1517966744035,
        "encrypted": "false",
        "engineId": "13",
        "helpString": "QQ general account login and common traffic",
        "ignoreConflict": false,
        "lastUpdateTime": 1517966744035,
        "longDescription": "QQ-Accounts protocol is a general account login and common service. This protocol classifies login traffic over HTTP, and it's used by all QQ applications that require a login to the QQ accounts, such as QQLive.",
        "name": "qq-accounts",
        "nbarId": "1489",
        "p2pTechnology": "false",
        "popularity": 8,
        "rank": 65535,
        "references": "http://id.qq.com/",
        "selectorId": "554",
        "subCategory": "aab99e59-8589-4a61-933e-f01bff556d78",
        "trafficClass": "TRANSACTIONAL_DATA",
        "tunnel": "false"
      }
    ],
    "networkIdentity": [
      {
        "id": "dcd79eeb-f49b-442a-924d-0e8884866b52",
        "instanceId": 14682,
        "authEntityId": 14682,
        "displayName": "14682",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "53,80,5353",
        "protocol": "TCP",
        "upperPort": 0
      },
      {
        "id": "2b5f6946-d9da-42b2-971c-24545b2bee49",
        "instanceId": 14683,
        "authEntityId": 14683,
        "displayName": "14683",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "53,5353",
        "protocol": "UDP",
        "upperPort": 0
      }
    ],
    "parentScalableGroup": {
      "idRef": "28e08de4-d7b2-4521-84ce-bc1e5ee0b9cc"
    },
    "user": [

    ],
    "userGroup": [

    ]
  },
  {
    "id": "06b451f5-ac63-4b5b-972c-3b62f12f922d",
    "instanceId": 4820,
    "authEntityId": 4820,
    "displayName": "4820",
    "authEntityClass": -1909073334,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "createTime": 1517966743556,
    "deployed": false,
    "isSeeded": true,
    "isStale": false,
    "lastUpdateTime": 1517966743556,
    "name": "nate-com",
    "namespace": "scalablegroup:application",
    "provisioningState": "DEFINED",
    "qualifier": "application",
    "resourceVersion": 0,
    "targetIdList": [

    ],
    "type": "scalablegroup",
    "cfsChangeInfo": [

    ],
    "customProvisions": [

    ],
    "scalableGroupExternalHandle": "nate-com",
    "scalableGroupType": "APPLICATION",
    "securityGroupTag": 0,
    "state": "ACTIVE",
    "vnAgnostic": true,
    "identitySource": {
      "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
      "instanceId": 10010,
      "authEntityId": 10010,
      "displayName": "10010",
      "authEntityClass": 1738953278,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "state": "INACTIVE",
      "type": "NBAR"
    },
    "indicativeNetworkIdentity": [

    ],
    "networkApplications": [
      {
        "id": "d5083781-57c8-47a9-ae7b-6c7d9a06a519",
        "instanceId": 11769,
        "authEntityId": 11769,
        "displayName": "11769",
        "authEntityClass": -217092956,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "appProtocol": "tcp/udp",
        "applicationGroup": "other",
        "applicationType": "DEFAULT",
        "categoryId": "878644b5-fe91-45b4-9204-31c84a73ba48",
        "createTime": 1517966743556,
        "encrypted": "false",
        "engineId": "13",
        "helpString": "Nate - Web portal and Search engine.",
        "ignoreConflict": false,
        "lastUpdateTime": 1517966743556,
        "longDescription": "Nate - Web portal and Search engine.",
        "name": "nate-com",
        "nbarId": "1705",
        "p2pTechnology": "false",
        "popularity": 3,
        "rank": 65535,
        "references": "http://nate.com",
        "selectorId": "775",
        "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
        "trafficClass": "TRANSACTIONAL_DATA",
        "tunnel": "false"
      }
    ],
    "networkIdentity": [
      {
        "id": "18a16611-edd3-431e-b6d1-d0ce8ce55370",
        "instanceId": 14094,
        "authEntityId": 14094,
        "displayName": "14094",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "53,5353",
        "protocol": "UDP",
        "upperPort": 0
      },
      {
        "id": "9b3bf862-ccc2-4981-8dc0-57abdf6e31d3",
        "instanceId": 14095,
        "authEntityId": 14095,
        "displayName": "14095",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "53,80,443,5353",
        "protocol": "TCP",
        "upperPort": 0
      }
    ],
    "parentScalableGroup": {
      "idRef": "28e08de4-d7b2-4521-84ce-bc1e5ee0b9cc"
    },
    "user": [

    ],
    "userGroup": [

    ]
  },
  {
    "id": "06d40ec9-f023-4a94-93dc-c2abddc71a18",
    "instanceId": 15424,
    "authEntityId": 15424,
    "displayName": "15424",
    "authEntityClass": -1909073334,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "createTime": 1517966744941,
    "deployed": false,
    "isSeeded": true,
    "isStale": false,
    "lastUpdateTime": 1517966744941,
    "name": "wordreference-com",
    "namespace": "scalablegroup:application",
    "provisioningState": "DEFINED",
    "qualifier": "application",
    "resourceVersion": 0,
    "targetIdList": [

    ],
    "type": "scalablegroup",
    "cfsChangeInfo": [

    ],
    "customProvisions": [

    ],
    "scalableGroupExternalHandle": "wordreference-com",
    "scalableGroupType": "APPLICATION",
    "securityGroupTag": 0,
    "state": "ACTIVE",
    "vnAgnostic": true,
    "identitySource": {
      "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
      "instanceId": 10010,
      "authEntityId": 10010,
      "displayName": "10010",
      "authEntityClass": 1738953278,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "state": "INACTIVE",
      "type": "NBAR"
    },
    "indicativeNetworkIdentity": [

    ],
    "networkApplications": [
      {
        "id": "9033f8d0-bf29-49ca-a899-42b04da583a3",
        "instanceId": 16367,
        "authEntityId": 16367,
        "displayName": "16367",
        "authEntityClass": -217092956,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "appProtocol": "tcp/udp",
        "applicationGroup": "other",
        "applicationType": "DEFAULT",
        "categoryId": "878644b5-fe91-45b4-9204-31c84a73ba48",
        "createTime": 1517966744941,
        "encrypted": "false",
        "engineId": "13",
        "helpString": "WordReference.com - Online dictionaries, translator and word games.",
        "ignoreConflict": false,
        "lastUpdateTime": 1517966744941,
        "longDescription": "WordReference.com - Online dictionaries, translator and word games.",
        "name": "wordreference-com",
        "nbarId": "1604",
        "p2pTechnology": "false",
        "popularity": 3,
        "rank": 65535,
        "references": "http://wordreference.com",
        "selectorId": "674",
        "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
        "trafficClass": "TRANSACTIONAL_DATA",
        "tunnel": "false"
      }
    ],
    "networkIdentity": [
      {
        "id": "0eae2532-edfe-44d7-9812-c8793a15da92",
        "instanceId": 17856,
        "authEntityId": 17856,
        "displayName": "17856",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "53,5353",
        "protocol": "UDP",
        "upperPort": 0
      },
      {
        "id": "cef4c4dc-8b0a-416f-ac30-d95cfd0e579b",
        "instanceId": 17855,
        "authEntityId": 17855,
        "displayName": "17855",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "53,80,443,5353",
        "protocol": "TCP",
        "upperPort": 0
      }
    ],
    "parentScalableGroup": {
      "idRef": "28e08de4-d7b2-4521-84ce-bc1e5ee0b9cc"
    },
    "user": [

    ],
    "userGroup": [

    ]
  },
  {
    "id": "06f5e940-3f30-4a6e-a602-e59e20738464",
    "instanceId": 4086,
    "authEntityId": 4086,
    "displayName": "4086",
    "authEntityClass": -1909073334,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "createTime": 1517966740961,
    "deployed": false,
    "isSeeded": true,
    "isStale": false,
    "lastUpdateTime": 1517966740961,
    "name": "android-updates",
    "namespace": "scalablegroup:application",
    "provisioningState": "DEFINED",
    "qualifier": "application",
    "resourceVersion": 0,
    "targetIdList": [

    ],
    "type": "scalablegroup",
    "cfsChangeInfo": [

    ],
    "customProvisions": [

    ],
    "scalableGroupExternalHandle": "android-updates",
    "scalableGroupType": "APPLICATION",
    "securityGroupTag": 0,
    "state": "ACTIVE",
    "vnAgnostic": true,
    "identitySource": {
      "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
      "instanceId": 10010,
      "authEntityId": 10010,
      "displayName": "10010",
      "authEntityClass": 1738953278,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "state": "INACTIVE",
      "type": "NBAR"
    },
    "indicativeNetworkIdentity": [

    ],
    "networkApplications": [
      {
        "id": "4556d3d4-0820-4548-9a0c-baac0a1ee51b",
        "instanceId": 11051,
        "authEntityId": 11051,
        "displayName": "11051",
        "authEntityClass": -217092956,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "appProtocol": "tcp/udp",
        "applicationGroup": "google-group",
        "applicationType": "DEFAULT",
        "categoryId": "9d9132a2-d99b-49db-a414-9fc93dea6604",
        "createTime": 1517966740961,
        "encrypted": "true",
        "engineId": "13",
        "helpString": "Android OS updates",
        "ignoreConflict": false,
        "lastUpdateTime": 1517966740961,
        "longDescription": "Android updates is the OS updating service for Google's Android OS. Most devices are capable of receiving the updates OTA. Due to the extensive variation in hardware of Android devices and the Android OS versions, updates need to be specially tailored for each device or software. Another aspect of this situation is updates don't have a specific source. Currently we support SAMSUNG, LG, HTC and devices with cyanogenmod ROM.",
        "name": "android-updates",
        "nbarId": "1526",
        "p2pTechnology": "false",
        "popularity": 8,
        "rank": 65535,
        "references": "http://www.android.com/",
        "selectorId": "587",
        "subCategory": "98e625d1-8720-4d2b-a186-c821a5d86693",
        "trafficClass": "BULK_DATA",
        "tunnel": "false"
      }
    ],
    "networkIdentity": [
      {
        "id": "bd272977-64c8-4896-aa49-713d476f8a7e",
        "instanceId": 12126,
        "authEntityId": 12126,
        "displayName": "12126",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "53,80,443,5353",
        "protocol": "TCP",
        "upperPort": 0
      },
      {
        "id": "728269f2-6437-46fd-9b3c-7e6fdd0d801e",
        "instanceId": 12127,
        "authEntityId": 12127,
        "displayName": "12127",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "53,5353",
        "protocol": "UDP",
        "upperPort": 0
      }
    ],
    "parentScalableGroup": {
      "idRef": "4a68bc73-53c6-4deb-9983-784a0338aefe"
    },
    "user": [

    ],
    "userGroup": [

    ]
  },
  {
    "id": "0760c21c-2f75-4913-ae12-23773691078c",
    "instanceId": 4265,
    "authEntityId": 4265,
    "displayName": "4265",
    "authEntityClass": -1909073334,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "createTime": 1517966741985,
    "deployed": false,
    "isSeeded": true,
    "isStale": false,
    "lastUpdateTime": 1517966741985,
    "name": "clickbank",
    "namespace": "scalablegroup:application",
    "provisioningState": "DEFINED",
    "qualifier": "application",
    "resourceVersion": 0,
    "targetIdList": [

    ],
    "type": "scalablegroup",
    "cfsChangeInfo": [

    ],
    "customProvisions": [

    ],
    "scalableGroupExternalHandle": "clickbank",
    "scalableGroupType": "APPLICATION",
    "securityGroupTag": 0,
    "state": "ACTIVE",
    "vnAgnostic": true,
    "identitySource": {
      "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
      "instanceId": 10010,
      "authEntityId": 10010,
      "displayName": "10010",
      "authEntityClass": 1738953278,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "state": "INACTIVE",
      "type": "NBAR"
    },
    "indicativeNetworkIdentity": [

    ],
    "networkApplications": [
      {
        "id": "8bb61749-66e1-459e-87d1-633cef37ab31",
        "instanceId": 11218,
        "authEntityId": 11218,
        "displayName": "11218",
        "authEntityClass": -217092956,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "appProtocol": "tcp/udp",
        "applicationGroup": "other",
        "applicationType": "DEFAULT",
        "categoryId": "878644b5-fe91-45b4-9204-31c84a73ba48",
        "createTime": 1517966741985,
        "encrypted": "false",
        "engineId": "13",
        "helpString": "ClickBank - Online marketplace for Digital products.",
        "ignoreConflict": false,
        "lastUpdateTime": 1517966741985,
        "longDescription": "ClickBank - Online marketplace for Digital products.",
        "name": "clickbank",
        "nbarId": "1571",
        "p2pTechnology": "false",
        "popularity": 3,
        "rank": 65535,
        "references": "http://clickbank.com",
        "selectorId": "642",
        "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
        "trafficClass": "TRANSACTIONAL_DATA",
        "tunnel": "false"
      }
    ],
    "networkIdentity": [
      {
        "id": "3ccfbb0d-426f-423c-a9a1-c0d2b159777b",
        "instanceId": 12566,
        "authEntityId": 12566,
        "displayName": "12566",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "53,5353",
        "protocol": "UDP",
        "upperPort": 0
      },
      {
        "id": "036dfd50-21b9-408d-931a-2dc295712340",
        "instanceId": 12567,
        "authEntityId": 12567,
        "displayName": "12567",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "53,80,443,5353",
        "protocol": "TCP",
        "upperPort": 0
      }
    ],
    "parentScalableGroup": {
      "idRef": "28e08de4-d7b2-4521-84ce-bc1e5ee0b9cc"
    },
    "user": [

    ],
    "userGroup": [

    ]
  },
  {
    "id": "0761754d-812d-4bfa-bce4-a84c6227e4e2",
    "instanceId": 4791,
    "authEntityId": 4791,
    "displayName": "4791",
    "authEntityClass": -1909073334,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "createTime": 1517966743501,
    "deployed": false,
    "isSeeded": true,
    "isStale": false,
    "lastUpdateTime": 1517966743501,
    "name": "ms-streaming",
    "namespace": "scalablegroup:application",
    "provisioningState": "DEFINED",
    "qualifier": "application",
    "resourceVersion": 0,
    "targetIdList": [

    ],
    "type": "scalablegroup",
    "cfsChangeInfo": [

    ],
    "customProvisions": [

    ],
    "scalableGroupExternalHandle": "ms-streaming",
    "scalableGroupType": "APPLICATION",
    "securityGroupTag": 0,
    "state": "ACTIVE",
    "vnAgnostic": true,
    "identitySource": {
      "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
      "instanceId": 10010,
      "authEntityId": 10010,
      "displayName": "10010",
      "authEntityClass": 1738953278,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "state": "INACTIVE",
      "type": "NBAR"
    },
    "indicativeNetworkIdentity": [
      {
        "id": "2b16295f-47a2-408c-96e5-5d7e3110deaa",
        "instanceId": 14018,
        "authEntityId": 14018,
        "displayName": "14018",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "1755",
        "protocol": "UDP",
        "upperPort": 0
      },
      {
        "id": "625631f5-bca6-4d9a-b8af-95fa1eac9798",
        "instanceId": 14019,
        "authEntityId": 14019,
        "displayName": "14019",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "1755",
        "protocol": "TCP",
        "upperPort": 0
      }
    ],
    "networkApplications": [
      {
        "id": "6a4565a3-6507-4cf2-8ecd-b001db3274fe",
        "instanceId": 11740,
        "authEntityId": 11740,
        "displayName": "11740",
        "authEntityClass": -217092956,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "appProtocol": "tcp/udp",
        "applicationGroup": "other",
        "applicationType": "DEFAULT",
        "categoryId": "6daa3dfd-3526-407a-88f0-97824b3f3a24",
        "createTime": 1517966743501,
        "encrypted": "false",
        "engineId": "3",
        "helpString": "Microsoft media services",
        "ignoreConflict": false,
        "lastUpdateTime": 1517966743501,
        "longDescription": "Microsoft Media Server (MMS) is Microsoft's proprietary network streaming protocol used to transfer unicast data in Windows Media Services.",
        "name": "ms-streaming",
        "nbarId": "1355",
        "p2pTechnology": "false",
        "popularity": 3,
        "rank": 65535,
        "references": "http://msdn.microsoft.com/en-us/library/cc239490.aspx",
        "selectorId": "1755",
        "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
        "trafficClass": "BULK_DATA",
        "tunnel": "false"
      }
    ],
    "networkIdentity": [
      {
        "id": "18ef8de1-f59d-403a-b1dc-02b544709e69",
        "instanceId": 14016,
        "authEntityId": 14016,
        "displayName": "14016",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "1755",
        "protocol": "UDP",
        "upperPort": 0
      },
      {
        "id": "eb75d8b8-8dc8-4e25-9382-dadbe6ad328d",
        "instanceId": 14017,
        "authEntityId": 14017,
        "displayName": "14017",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "1755",
        "protocol": "TCP",
        "upperPort": 0
      }
    ],
    "parentScalableGroup": {
      "idRef": "000c15bf-28fe-4fbb-9b69-35e6502721f1"
    },
    "user": [

    ],
    "userGroup": [

    ]
  },
  {
    "id": "076a318b-8d23-44ed-bb27-a2a6269520c4",
    "instanceId": 4637,
    "authEntityId": 4637,
    "displayName": "4637",
    "authEntityClass": -1909073334,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "createTime": 1517966743178,
    "deployed": false,
    "isSeeded": true,
    "isStale": false,
    "lastUpdateTime": 1517966743178,
    "name": "irc-serv",
    "namespace": "scalablegroup:application",
    "provisioningState": "DEFINED",
    "qualifier": "application",
    "resourceVersion": 0,
    "targetIdList": [

    ],
    "type": "scalablegroup",
    "cfsChangeInfo": [

    ],
    "customProvisions": [

    ],
    "scalableGroupExternalHandle": "irc-serv",
    "scalableGroupType": "APPLICATION",
    "securityGroupTag": 0,
    "state": "ACTIVE",
    "vnAgnostic": true,
    "identitySource": {
      "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
      "instanceId": 10010,
      "authEntityId": 10010,
      "displayName": "10010",
      "authEntityClass": 1738953278,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "state": "INACTIVE",
      "type": "NBAR"
    },
    "indicativeNetworkIdentity": [
      {
        "id": "2dcd1ff0-208e-4a7c-89dd-f70044dd9d77",
        "instanceId": 13595,
        "authEntityId": 13595,
        "displayName": "13595",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "529",
        "protocol": "UDP",
        "upperPort": 0
      },
      {
        "id": "4bbfd62d-e34f-452a-9ad3-68c4365008cd",
        "instanceId": 13596,
        "authEntityId": 13596,
        "displayName": "13596",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "529",
        "protocol": "TCP",
        "upperPort": 0
      }
    ],
    "networkApplications": [
      {
        "id": "67dc213d-0d5a-4b59-85d9-7572c4b64a22",
        "instanceId": 11587,
        "authEntityId": 11587,
        "displayName": "11587",
        "authEntityClass": -217092956,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "appProtocol": "tcp/udp",
        "applicationGroup": "irc-group",
        "applicationType": "DEFAULT",
        "categoryId": "0f6cab47-be95-4124-9db3-360ccd55f983",
        "createTime": 1517966743178,
        "encrypted": "false",
        "engineId": "3",
        "helpString": "IRC-SERV",
        "ignoreConflict": false,
        "lastUpdateTime": 1517966743178,
        "longDescription": "An IRCd, short for Internet Relay Chat daemon, is a server software that implements the IRC \"Internet Relay Chat\" protocol, enabling people to talk to each other via the Internet (exchanging textual messages in real time). The server listens to connections from IRC clients on a set of TCP ports. When the server is part of an IRC network, it also keeps one or more established connections to other servers/daemons.",
        "name": "irc-serv",
        "nbarId": "447",
        "p2pTechnology": "false",
        "popularity": 1,
        "rank": 65535,
        "references": "http://www.ietf.org/rfc/rfc1459",
        "selectorId": "529",
        "subCategory": "118c10a2-e272-47c1-92b9-4814ebe97138",
        "trafficClass": "TRANSACTIONAL_DATA",
        "tunnel": "false"
      }
    ],
    "networkIdentity": [
      {
        "id": "d0c1d71d-7cf7-45b3-b49b-60ad767e1d28",
        "instanceId": 13593,
        "authEntityId": 13593,
        "displayName": "13593",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "529",
        "protocol": "TCP",
        "upperPort": 0
      },
      {
        "id": "452022aa-ef50-4f56-91e0-6a8476b206ea",
        "instanceId": 13594,
        "authEntityId": 13594,
        "displayName": "13594",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "529",
        "protocol": "UDP",
        "upperPort": 0
      }
    ],
    "parentScalableGroup": {
      "idRef": "fd6264c9-c16a-4311-a335-a3db039256f3"
    },
    "user": [

    ],
    "userGroup": [

    ]
  },
  {
    "id": "07c97750-f1bb-4819-870b-6065e4b2c08d",
    "instanceId": 15109,
    "authEntityId": 15109,
    "displayName": "15109",
    "authEntityClass": -1909073334,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "createTime": 1517966744254,
    "deployed": false,
    "isSeeded": true,
    "isStale": false,
    "lastUpdateTime": 1517966744254,
    "name": "secondlife",
    "namespace": "scalablegroup:application",
    "provisioningState": "DEFINED",
    "qualifier": "application",
    "resourceVersion": 0,
    "targetIdList": [

    ],
    "type": "scalablegroup",
    "cfsChangeInfo": [

    ],
    "customProvisions": [

    ],
    "scalableGroupExternalHandle": "secondlife",
    "scalableGroupType": "APPLICATION",
    "securityGroupTag": 0,
    "state": "ACTIVE",
    "vnAgnostic": true,
    "identitySource": {
      "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
      "instanceId": 10010,
      "authEntityId": 10010,
      "displayName": "10010",
      "authEntityClass": 1738953278,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "state": "INACTIVE",
      "type": "NBAR"
    },
    "indicativeNetworkIdentity": [

    ],
    "networkApplications": [
      {
        "id": "220e0f70-4034-4e4f-8a98-270ab71f000c",
        "instanceId": 16052,
        "authEntityId": 16052,
        "displayName": "16052",
        "authEntityClass": -217092956,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "appProtocol": "tcp/udp",
        "applicationGroup": "other",
        "applicationType": "DEFAULT",
        "categoryId": "3cd50dd8-1b2e-46c1-b672-41c14bfa364b",
        "createTime": 1517966744254,
        "encrypted": "true",
        "engineId": "13",
        "helpString": "SecondLife traffic",
        "ignoreConflict": false,
        "lastUpdateTime": 1517966744254,
        "longDescription": "Second Life is an online virtual world developed by Linden Lab. There are a number of client programs or viewers that enable Second Life users to interact with each other through avatars.",
        "name": "secondlife",
        "nbarId": "1041",
        "p2pTechnology": "false",
        "popularity": 6,
        "rank": 65535,
        "references": "http://secondlife.com/",
        "selectorId": "328",
        "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
        "trafficClass": "MULTIMEDIA_CONFERENCING",
        "tunnel": "false"
      }
    ],
    "networkIdentity": [
      {
        "id": "1b41f0b0-ab93-4e01-8588-3a2f3284655a",
        "instanceId": 14964,
        "authEntityId": 14964,
        "displayName": "14964",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "53,5353",
        "protocol": "UDP",
        "upperPort": 0
      },
      {
        "id": "e42ee6de-1e73-49e3-8a43-3832c2dec544",
        "instanceId": 14965,
        "authEntityId": 14965,
        "displayName": "14965",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "53,80,443,5353",
        "protocol": "TCP",
        "upperPort": 0
      }
    ],
    "parentScalableGroup": {
      "idRef": "0bd0e6c9-5083-492b-97cb-a88928bee99c"
    },
    "user": [

    ],
    "userGroup": [

    ]
  },
  {
    "id": "0801f72b-f476-4dc0-ad99-da6eb980e6cd",
    "instanceId": 4626,
    "authEntityId": 4626,
    "displayName": "4626",
    "authEntityClass": -1909073334,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "createTime": 1517966743155,
    "deployed": false,
    "isSeeded": true,
    "isStale": false,
    "lastUpdateTime": 1517966743155,
    "name": "ipp",
    "namespace": "scalablegroup:application",
    "provisioningState": "DEFINED",
    "qualifier": "application",
    "resourceVersion": 0,
    "targetIdList": [

    ],
    "type": "scalablegroup",
    "cfsChangeInfo": [

    ],
    "customProvisions": [

    ],
    "scalableGroupExternalHandle": "ipp",
    "scalableGroupType": "APPLICATION",
    "securityGroupTag": 0,
    "state": "ACTIVE",
    "vnAgnostic": true,
    "identitySource": {
      "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
      "instanceId": 10010,
      "authEntityId": 10010,
      "displayName": "10010",
      "authEntityClass": 1738953278,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "state": "INACTIVE",
      "type": "NBAR"
    },
    "indicativeNetworkIdentity": [
      {
        "id": "f186eb34-54a7-4766-9455-0a5e98b6582d",
        "instanceId": 13579,
        "authEntityId": 13579,
        "displayName": "13579",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "631",
        "protocol": "UDP",
        "upperPort": 0
      },
      {
        "id": "e3b87782-aeff-4c2f-9073-e8235fd8ee07",
        "instanceId": 13580,
        "authEntityId": 13580,
        "displayName": "13580",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "631",
        "protocol": "TCP",
        "upperPort": 0
      }
    ],
    "networkApplications": [
      {
        "id": "d37ef61b-7173-4062-9f96-b4163673958f",
        "instanceId": 11576,
        "authEntityId": 11576,
        "displayName": "11576",
        "authEntityClass": -217092956,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "appProtocol": "tcp/udp",
        "applicationGroup": "other",
        "applicationType": "DEFAULT",
        "categoryId": "6daa3dfd-3526-407a-88f0-97824b3f3a24",
        "createTime": 1517966743155,
        "encrypted": "false",
        "engineId": "3",
        "helpString": "Internet Printing Protocol",
        "ignoreConflict": false,
        "lastUpdateTime": 1517966743155,
        "longDescription": "Internet Printing Protocol (IPP) provides a standard network protocol for remote printing as well as for managing print jobs, media size, resolution, and so forth.  IPP can run locally or over the Internet to remote printers, and supports access control, authentication, and encryption, making it a much more capable and secure printing solution than older ones.",
        "name": "ipp",
        "nbarId": "540",
        "p2pTechnology": "false",
        "popularity": 1,
        "rank": 65535,
        "references": "http://tools.ietf.org/html/rfc2910",
        "selectorId": "631",
        "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
        "trafficClass": "BULK_DATA",
        "tunnel": "false"
      }
    ],
    "networkIdentity": [
      {
        "id": "535d910a-b3cb-4a35-8106-aeb343da82e3",
        "instanceId": 13577,
        "authEntityId": 13577,
        "displayName": "13577",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "631",
        "protocol": "TCP",
        "upperPort": 0
      },
      {
        "id": "4db737af-1011-49b9-ad20-43b75208d00f",
        "instanceId": 13578,
        "authEntityId": 13578,
        "displayName": "13578",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "631",
        "protocol": "UDP",
        "upperPort": 0
      }
    ],
    "parentScalableGroup": {
      "idRef": "000c15bf-28fe-4fbb-9b69-35e6502721f1"
    },
    "user": [

    ],
    "userGroup": [

    ]
  },
  {
    "id": "081705b9-4dfc-4aac-8a32-1ac233c480fe",
    "instanceId": 4359,
    "authEntityId": 4359,
    "displayName": "4359",
    "authEntityClass": -1909073334,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "createTime": 1517966742395,
    "deployed": false,
    "isSeeded": true,
    "isStale": false,
    "lastUpdateTime": 1517966742395,
    "name": "dht",
    "namespace": "scalablegroup:application",
    "provisioningState": "DEFINED",
    "qualifier": "application",
    "resourceVersion": 0,
    "targetIdList": [

    ],
    "type": "scalablegroup",
    "cfsChangeInfo": [

    ],
    "customProvisions": [

    ],
    "scalableGroupExternalHandle": "dht",
    "scalableGroupType": "APPLICATION",
    "securityGroupTag": 0,
    "state": "ACTIVE",
    "vnAgnostic": true,
    "identitySource": {
      "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
      "instanceId": 10010,
      "authEntityId": 10010,
      "displayName": "10010",
      "authEntityClass": 1738953278,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "state": "INACTIVE",
      "type": "NBAR"
    },
    "indicativeNetworkIdentity": [

    ],
    "networkApplications": [
      {
        "id": "092c46c2-250e-40a9-ba54-ddef762ae8ef",
        "instanceId": 11310,
        "authEntityId": 11310,
        "displayName": "11310",
        "authEntityClass": -217092956,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "applicationGroup": "other",
        "applicationType": "DEFAULT",
        "categoryId": "b33b2761-d981-4734-810a-a6a1c18ab352",
        "createTime": 1517966742395,
        "encrypted": "false",
        "engineId": "13",
        "helpString": "Distributed sloppy Hash Table protocol",
        "ignoreConflict": false,
        "lastUpdateTime": 1517966742395,
        "longDescription": "A distributed hash table (DHT) is a class of a decentralized distributed system that provides a lookup service similar to a  hash table. BitTorrent uses DHT for storing peer contact information for trackerless torrents. In effect, each peer becomes a tracker. The protocol is based on Kademila and is implemented over UDP.",
        "name": "dht",
        "nbarId": "886",
        "p2pTechnology": "true",
        "popularity": 10,
        "rank": 65535,
        "references": "http://www.ietf.org/proceedings/65/slides/plenaryt-2.pdf",
        "selectorId": "439",
        "subCategory": "79fe7198-9ce3-4c01-9b58-45a8df3d9513",
        "trafficClass": "BULK_DATA",
        "tunnel": "false"
      }
    ],
    "networkIdentity": [

    ],
    "parentScalableGroup": {
      "idRef": "7a1a3bbb-dc84-4a35-82a4-7e650c4c4761"
    },
    "user": [

    ],
    "userGroup": [

    ]
  },
  {
    "id": "082f7708-59f9-4b8a-9359-dd4c55c2d952",
    "instanceId": 4144,
    "authEntityId": 4144,
    "displayName": "4144",
    "authEntityClass": -1909073334,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "createTime": 1517966741372,
    "deployed": false,
    "isSeeded": true,
    "isStale": false,
    "lastUpdateTime": 1517966741372,
    "name": "aurora-cmgr",
    "namespace": "scalablegroup:application",
    "provisioningState": "DEFINED",
    "qualifier": "application",
    "resourceVersion": 0,
    "targetIdList": [

    ],
    "type": "scalablegroup",
    "cfsChangeInfo": [

    ],
    "customProvisions": [

    ],
    "scalableGroupExternalHandle": "aurora-cmgr",
    "scalableGroupType": "APPLICATION",
    "securityGroupTag": 0,
    "state": "ACTIVE",
    "vnAgnostic": true,
    "identitySource": {
      "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
      "instanceId": 10010,
      "authEntityId": 10010,
      "displayName": "10010",
      "authEntityClass": 1738953278,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "state": "INACTIVE",
      "type": "NBAR"
    },
    "indicativeNetworkIdentity": [
      {
        "id": "5813ce47-84ae-491a-a45d-9a12ae717bde",
        "instanceId": 12292,
        "authEntityId": 12292,
        "displayName": "12292",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "364",
        "protocol": "UDP",
        "upperPort": 0
      },
      {
        "id": "95075c89-75e0-4e26-b2db-bf9b76db8928",
        "instanceId": 12293,
        "authEntityId": 12293,
        "displayName": "12293",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "364",
        "protocol": "TCP",
        "upperPort": 0
      }
    ],
    "networkApplications": [
      {
        "id": "2fbb7273-8032-4b23-9e79-d4c6ba32b923",
        "instanceId": 11105,
        "authEntityId": 11105,
        "displayName": "11105",
        "authEntityClass": -217092956,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "appProtocol": "tcp/udp",
        "applicationGroup": "other",
        "applicationType": "DEFAULT",
        "categoryId": "6daa3dfd-3526-407a-88f0-97824b3f3a24",
        "createTime": 1517966741372,
        "encrypted": "false",
        "engineId": "3",
        "helpString": "Aurora CMGR",
        "ignoreConflict": false,
        "lastUpdateTime": 1517966741372,
        "longDescription": "Registered with IANA on port 364 TCP/UDP",
        "name": "aurora-cmgr",
        "nbarId": "280",
        "p2pTechnology": "false",
        "popularity": 1,
        "rank": 65535,
        "references": "http://www.iana.org/assignments/service-names-port-numbers/service-names-port-numbers.xml",
        "selectorId": "364",
        "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
        "trafficClass": "BULK_DATA",
        "tunnel": "false"
      }
    ],
    "networkIdentity": [
      {
        "id": "9e7db521-149f-4f49-ae1b-264d58b9483c",
        "instanceId": 12290,
        "authEntityId": 12290,
        "displayName": "12290",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "364",
        "protocol": "TCP",
        "upperPort": 0
      },
      {
        "id": "d2df16c8-d6c9-4023-a411-e1d4741d3581",
        "instanceId": 12291,
        "authEntityId": 12291,
        "displayName": "12291",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "364",
        "protocol": "UDP",
        "upperPort": 0
      }
    ],
    "parentScalableGroup": {
      "idRef": "000c15bf-28fe-4fbb-9b69-35e6502721f1"
    },
    "user": [

    ],
    "userGroup": [

    ]
  },
  {
    "id": "087c3bbf-216f-4935-b2a0-132750b9aa60",
    "instanceId": 4690,
    "authEntityId": 4690,
    "displayName": "4690",
    "authEntityClass": -1909073334,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "createTime": 1517966743291,
    "deployed": false,
    "isSeeded": true,
    "isStale": false,
    "lastUpdateTime": 1517966743291,
    "name": "leaf-1",
    "namespace": "scalablegroup:application",
    "provisioningState": "DEFINED",
    "qualifier": "application",
    "resourceVersion": 0,
    "targetIdList": [

    ],
    "type": "scalablegroup",
    "cfsChangeInfo": [

    ],
    "customProvisions": [

    ],
    "scalableGroupExternalHandle": "leaf-1",
    "scalableGroupType": "APPLICATION",
    "securityGroupTag": 0,
    "state": "ACTIVE",
    "vnAgnostic": true,
    "identitySource": {
      "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
      "instanceId": 10010,
      "authEntityId": 10010,
      "displayName": "10010",
      "authEntityClass": 1738953278,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "state": "INACTIVE",
      "type": "NBAR"
    },
    "indicativeNetworkIdentity": [
      {
        "id": "1f002a51-6d3b-447c-9093-be92418d494e",
        "instanceId": 13745,
        "authEntityId": 13745,
        "displayName": "13745",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipProtocolNumbers": "25",
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "protocol": "LEAF_1",
        "upperPort": 0
      }
    ],
    "networkApplications": [
      {
        "id": "f3cac1c3-fb42-463d-afe5-fe875df28e3d",
        "instanceId": 11640,
        "authEntityId": 11640,
        "displayName": "11640",
        "authEntityClass": -217092956,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "appProtocol": "ip",
        "applicationGroup": "other",
        "applicationType": "DEFAULT",
        "categoryId": "6daa3dfd-3526-407a-88f0-97824b3f3a24",
        "createTime": 1517966743291,
        "encrypted": "unassigned",
        "engineId": "1",
        "helpString": "Leaf-1",
        "ignoreConflict": false,
        "lastUpdateTime": 1517966743291,
        "longDescription": "The Leaf File Access Protocol is one of the first protocols to enable remote access to files.",
        "name": "leaf-1",
        "nbarId": "779",
        "p2pTechnology": "unassigned",
        "popularity": 2,
        "rank": 65535,
        "references": "http://infolab.stanford.edu/TR/CS-TR-86-1137.html",
        "selectorId": "25",
        "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
        "trafficClass": "BULK_DATA",
        "tunnel": "unassigned"
      }
    ],
    "networkIdentity": [
      {
        "id": "3df6930c-cf38-42a2-84f2-0b2e5b235f39",
        "instanceId": 13744,
        "authEntityId": 13744,
        "displayName": "13744",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipProtocolNumbers": "25",
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "protocol": "LEAF_1",
        "upperPort": 0
      }
    ],
    "parentScalableGroup": {
      "idRef": "000c15bf-28fe-4fbb-9b69-35e6502721f1"
    },
    "user": [

    ],
    "userGroup": [

    ]
  },
  {
    "id": "08988a91-6c9f-42b3-82a8-ccc47511fe3a",
    "instanceId": 15032,
    "authEntityId": 15032,
    "displayName": "15032",
    "authEntityClass": -1909073334,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "createTime": 1517966744071,
    "deployed": false,
    "isSeeded": true,
    "isStale": false,
    "lastUpdateTime": 1517966744071,
    "name": "rdp",
    "namespace": "scalablegroup:application",
    "provisioningState": "DEFINED",
    "qualifier": "application",
    "resourceVersion": 0,
    "targetIdList": [

    ],
    "type": "scalablegroup",
    "cfsChangeInfo": [

    ],
    "customProvisions": [

    ],
    "scalableGroupExternalHandle": "rdp",
    "scalableGroupType": "APPLICATION",
    "securityGroupTag": 0,
    "state": "ACTIVE",
    "vnAgnostic": true,
    "identitySource": {
      "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
      "instanceId": 10010,
      "authEntityId": 10010,
      "displayName": "10010",
      "authEntityClass": 1738953278,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "state": "INACTIVE",
      "type": "NBAR"
    },
    "indicativeNetworkIdentity": [
      {
        "id": "eaf398db-ec41-4172-855c-6f4d3231da50",
        "instanceId": 14726,
        "authEntityId": 14726,
        "displayName": "14726",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipProtocolNumbers": "27",
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "protocol": "RDP",
        "upperPort": 0
      }
    ],
    "networkApplications": [
      {
        "id": "1581d155-1808-4f7a-b2be-3d79f95bc1aa",
        "instanceId": 11971,
        "authEntityId": 11971,
        "displayName": "11971",
        "authEntityClass": -217092956,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "appProtocol": "ip",
        "applicationGroup": "other",
        "applicationType": "DEFAULT",
        "categoryId": "6daa3dfd-3526-407a-88f0-97824b3f3a24",
        "createTime": 1517966744071,
        "encrypted": "unassigned",
        "engineId": "1",
        "helpString": "Reliable Data Protocol",
        "ignoreConflict": false,
        "lastUpdateTime": 1517966744071,
        "longDescription": "Reliable Datagram Protocol (also known as RDP or RUDP) is a transport layer protocol designed at Bell Labs for the Plan 9 operating system. RUDP implements features that are similar to TCP with less overhead.",
        "name": "rdp",
        "nbarId": "781",
        "p2pTechnology": "unassigned",
        "popularity": 2,
        "rank": 65535,
        "references": "http://www.ietf.org/rfc/rfc908.txt",
        "selectorId": "27",
        "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
        "trafficClass": "BULK_DATA",
        "tunnel": "unassigned"
      }
    ],
    "networkIdentity": [
      {
        "id": "3a495e29-647a-44bc-9c71-b3eb32f39083",
        "instanceId": 14725,
        "authEntityId": 14725,
        "displayName": "14725",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipProtocolNumbers": "27",
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "protocol": "RDP",
        "upperPort": 0
      }
    ],
    "parentScalableGroup": {
      "idRef": "000c15bf-28fe-4fbb-9b69-35e6502721f1"
    },
    "user": [

    ],
    "userGroup": [

    ]
  },
  {
    "id": "08b89617-b515-469c-a86a-edb1ff01cf9c",
    "instanceId": 4257,
    "authEntityId": 4257,
    "displayName": "4257",
    "authEntityClass": -1909073334,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "createTime": 1517966741962,
    "deployed": false,
    "isSeeded": true,
    "isStale": false,
    "lastUpdateTime": 1517966741962,
    "name": "cisco-tdp",
    "namespace": "scalablegroup:application",
    "provisioningState": "DEFINED",
    "qualifier": "application",
    "resourceVersion": 0,
    "targetIdList": [

    ],
    "type": "scalablegroup",
    "cfsChangeInfo": [

    ],
    "customProvisions": [

    ],
    "scalableGroupExternalHandle": "cisco-tdp",
    "scalableGroupType": "APPLICATION",
    "securityGroupTag": 0,
    "state": "ACTIVE",
    "vnAgnostic": true,
    "identitySource": {
      "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
      "instanceId": 10010,
      "authEntityId": 10010,
      "displayName": "10010",
      "authEntityClass": 1738953278,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "state": "INACTIVE",
      "type": "NBAR"
    },
    "indicativeNetworkIdentity": [
      {
        "id": "725f17c0-410e-4902-a69f-5f6fc737254f",
        "instanceId": 12546,
        "authEntityId": 12546,
        "displayName": "12546",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "711",
        "protocol": "UDP",
        "upperPort": 0
      },
      {
        "id": "80b0ee2a-aff3-4cf6-ad21-366a2990877d",
        "instanceId": 12547,
        "authEntityId": 12547,
        "displayName": "12547",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "711",
        "protocol": "TCP",
        "upperPort": 0
      }
    ],
    "networkApplications": [
      {
        "id": "d92f7d41-1500-4a63-b055-92498ff14b12",
        "instanceId": 11211,
        "authEntityId": 11211,
        "displayName": "11211",
        "authEntityClass": -217092956,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "appProtocol": "tcp/udp",
        "applicationGroup": "other",
        "applicationType": "DEFAULT",
        "categoryId": "1fc17362-9334-4c7b-b752-5afdbb574017",
        "createTime": 1517966741962,
        "encrypted": "false",
        "engineId": "3",
        "helpString": "Cisco TDP",
        "ignoreConflict": false,
        "lastUpdateTime": 1517966741962,
        "longDescription": "Tag Distribution Protocol (TDP) is a two party protocol that runs over a connection oriented transport layer with guaranteed sequential delivery.  Tag Switching Routers use TDP to communicate tag binding information to their peers. TDP supports multiple network layer protocols including but not limited to IPv4, IPv6, IPX and AppleTalk.",
        "name": "cisco-tdp",
        "nbarId": "614",
        "p2pTechnology": "false",
        "popularity": 4,
        "rank": 65535,
        "references": "http://tools.ietf.org/id/draft-doolan-tdp-spec-00",
        "selectorId": "711",
        "subCategory": "7cc1b3a6-4102-44e9-b522-0e71afe39a03",
        "trafficClass": "NETWORK_CONTROL",
        "tunnel": "false"
      }
    ],
    "networkIdentity": [
      {
        "id": "c0fff30b-9367-4a99-8e7d-c88f74a0a5e5",
        "instanceId": 12544,
        "authEntityId": 12544,
        "displayName": "12544",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "711",
        "protocol": "TCP",
        "upperPort": 0
      },
      {
        "id": "3b52f0ef-79f0-4a41-86ed-de6e25eaa77f",
        "instanceId": 12545,
        "authEntityId": 12545,
        "displayName": "12545",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "711",
        "protocol": "UDP",
        "upperPort": 0
      }
    ],
    "parentScalableGroup": {
      "idRef": "77e75842-e98b-4112-ad0c-300db4bb11be"
    },
    "user": [

    ],
    "userGroup": [

    ]
  },
  {
    "id": "09217edc-fae4-41f6-be2c-9e5e53fdb7fd",
    "instanceId": 4878,
    "authEntityId": 4878,
    "displayName": "4878",
    "authEntityClass": -1909073334,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "createTime": 1517966743666,
    "deployed": false,
    "isSeeded": true,
    "isStale": false,
    "lastUpdateTime": 1517966743666,
    "name": "npmp-local",
    "namespace": "scalablegroup:application",
    "provisioningState": "DEFINED",
    "qualifier": "application",
    "resourceVersion": 0,
    "targetIdList": [

    ],
    "type": "scalablegroup",
    "cfsChangeInfo": [

    ],
    "customProvisions": [

    ],
    "scalableGroupExternalHandle": "npmp-local",
    "scalableGroupType": "APPLICATION",
    "securityGroupTag": 0,
    "state": "ACTIVE",
    "vnAgnostic": true,
    "identitySource": {
      "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
      "instanceId": 10010,
      "authEntityId": 10010,
      "displayName": "10010",
      "authEntityClass": 1738953278,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "state": "INACTIVE",
      "type": "NBAR"
    },
    "indicativeNetworkIdentity": [
      {
        "id": "1d94802e-ba6e-457b-b88c-faaf224d4ae3",
        "instanceId": 14278,
        "authEntityId": 14278,
        "displayName": "14278",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "610",
        "protocol": "TCP",
        "upperPort": 0
      },
      {
        "id": "b4574b1c-c29c-45b8-84e9-060b579bfc00",
        "instanceId": 14279,
        "authEntityId": 14279,
        "displayName": "14279",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "610",
        "protocol": "UDP",
        "upperPort": 0
      }
    ],
    "networkApplications": [
      {
        "id": "5f3fa5cb-8c5b-4e70-b1d7-86b6e2730e36",
        "instanceId": 11827,
        "authEntityId": 11827,
        "displayName": "11827",
        "authEntityClass": -217092956,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "appProtocol": "tcp/udp",
        "applicationGroup": "npmp-group",
        "applicationType": "DEFAULT",
        "categoryId": "1fc17362-9334-4c7b-b752-5afdbb574017",
        "createTime": 1517966743666,
        "encrypted": "false",
        "engineId": "3",
        "helpString": "npmp-local",
        "ignoreConflict": false,
        "lastUpdateTime": 1517966743666,
        "longDescription": "Registered with IANA on port 610 TCP/UDP",
        "name": "npmp-local",
        "nbarId": "519",
        "p2pTechnology": "false",
        "popularity": 1,
        "rank": 65535,
        "references": "http://www.iana.org/assignments/service-names-port-numbers/service-names-port-numbers.xml",
        "selectorId": "610",
        "subCategory": "f3c0f761-d894-4112-ab89-b90fc84fd660",
        "trafficClass": "OPS_ADMIN_MGMT",
        "tunnel": "false"
      }
    ],
    "networkIdentity": [
      {
        "id": "666c0eae-710a-4f60-8d40-68f9e60ae310",
        "instanceId": 14276,
        "authEntityId": 14276,
        "displayName": "14276",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "610",
        "protocol": "TCP",
        "upperPort": 0
      },
      {
        "id": "52a89fb8-5fc5-45fa-b7e1-ed8696a0cd06",
        "instanceId": 14277,
        "authEntityId": 14277,
        "displayName": "14277",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "610",
        "protocol": "UDP",
        "upperPort": 0
      }
    ],
    "parentScalableGroup": {
      "idRef": "5fcd74d4-8726-47f7-a088-c457863da7ef"
    },
    "user": [

    ],
    "userGroup": [

    ]
  },
  {
    "id": "0992cf9b-b125-4bd1-9cef-0663096a6c47",
    "instanceId": 15156,
    "authEntityId": 15156,
    "displayName": "15156",
    "authEntityClass": -1909073334,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "createTime": 1517966744362,
    "deployed": false,
    "isSeeded": true,
    "isStale": false,
    "lastUpdateTime": 1517966744362,
    "name": "skype",
    "namespace": "scalablegroup:application",
    "provisioningState": "DEFINED",
    "qualifier": "application",
    "resourceVersion": 0,
    "targetIdList": [

    ],
    "type": "scalablegroup",
    "cfsChangeInfo": [

    ],
    "customProvisions": [

    ],
    "scalableGroupExternalHandle": "skype",
    "scalableGroupType": "APPLICATION",
    "securityGroupTag": 0,
    "state": "ACTIVE",
    "vnAgnostic": true,
    "identitySource": {
      "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
      "instanceId": 10010,
      "authEntityId": 10010,
      "displayName": "10010",
      "authEntityClass": 1738953278,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "state": "INACTIVE",
      "type": "NBAR"
    },
    "indicativeNetworkIdentity": [
      {
        "id": "22f61e74-df89-4dd7-b3e4-a0c451803143",
        "instanceId": 17116,
        "authEntityId": 17116,
        "displayName": "17116",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "33033",
        "protocol": "TCP",
        "upperPort": 0
      }
    ],
    "networkApplications": [
      {
        "id": "39ea3447-dc9b-423e-a6f0-568f9bad7001",
        "instanceId": 16099,
        "authEntityId": 16099,
        "displayName": "16099",
        "authEntityClass": -217092956,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "appProtocol": "tcp/udp",
        "applicationGroup": "skype-group",
        "applicationType": "DEFAULT",
        "categoryId": "82a7b7fa-da5a-48f5-8b6a-a2546b947843",
        "createTime": 1517966744362,
        "encrypted": "true",
        "engineId": "13",
        "helpString": "Skype Peer-to-Peer Internet Telephony Protocol",
        "ignoreConflict": false,
        "lastUpdateTime": 1517966744362,
        "longDescription": "Skype software uses a proprietary Internet telephony (VoIP) network called the Skype protocol. Part of the Skype technology relies on the Global Index peer-to-peer protocol belonging to the Joltid Ltd. corporation. Skype is software that contains several features such as telephone calls over the Internet, instant messaging, file transfer and video conferencing.",
        "name": "skype",
        "nbarId": "83",
        "p2pTechnology": "true",
        "popularity": 10,
        "rank": 65535,
        "references": "http://www.skype.com",
        "selectorId": "83",
        "subCategory": "118c10a2-e272-47c1-92b9-4814ebe97138",
        "trafficClass": "MULTIMEDIA_CONFERENCING",
        "tunnel": "false"
      }
    ],
    "networkIdentity": [
      {
        "id": "1aa75c1f-3157-4d0b-9785-4fb0f099f32d",
        "instanceId": 17114,
        "authEntityId": 17114,
        "displayName": "17114",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "53,5353",
        "protocol": "UDP",
        "upperPort": 0
      },
      {
        "id": "53185318-1fb3-447d-966e-e33442c68877",
        "instanceId": 17115,
        "authEntityId": 17115,
        "displayName": "17115",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "53,80,443,5353,33033",
        "protocol": "TCP",
        "upperPort": 0
      }
    ],
    "parentScalableGroup": {
      "idRef": "fd6264c9-c16a-4311-a335-a3db039256f3"
    },
    "user": [

    ],
    "userGroup": [

    ]
  },
  {
    "id": "09c23bf7-04ce-42b6-9c11-45a0c22e7a98",
    "instanceId": 4106,
    "authEntityId": 4106,
    "displayName": "4106",
    "authEntityClass": -1909073334,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "createTime": 1517966741146,
    "deployed": false,
    "isSeeded": true,
    "isStale": false,
    "lastUpdateTime": 1517966741146,
    "name": "apple-services",
    "namespace": "scalablegroup:application",
    "provisioningState": "DEFINED",
    "qualifier": "application",
    "resourceVersion": 0,
    "targetIdList": [

    ],
    "type": "scalablegroup",
    "cfsChangeInfo": [

    ],
    "customProvisions": [

    ],
    "scalableGroupExternalHandle": "apple-services",
    "scalableGroupType": "APPLICATION",
    "securityGroupTag": 0,
    "state": "ACTIVE",
    "vnAgnostic": true,
    "identitySource": {
      "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
      "instanceId": 10010,
      "authEntityId": 10010,
      "displayName": "10010",
      "authEntityClass": 1738953278,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "state": "INACTIVE",
      "type": "NBAR"
    },
    "indicativeNetworkIdentity": [

    ],
    "networkApplications": [
      {
        "id": "31fecdfa-bf5e-420f-b047-2c09e406168d",
        "instanceId": 11068,
        "authEntityId": 11068,
        "displayName": "11068",
        "authEntityClass": -217092956,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "appProtocol": "tcp/udp",
        "applicationGroup": "apple-group",
        "applicationType": "DEFAULT",
        "categoryId": "f6ec0221-b82d-43e8-a64d-163cbc13263b",
        "createTime": 1517966741146,
        "encrypted": "true",
        "engineId": "13",
        "helpString": "Set of tools and APIs used by apple applications",
        "ignoreConflict": false,
        "lastUpdateTime": 1517966741146,
        "longDescription": "apple-services is a set of tools and APIs used by Apple applications. such as: AppStore, apple website and etc.",
        "name": "apple-services",
        "nbarId": "1516",
        "p2pTechnology": "false",
        "popularity": 8,
        "rank": 65535,
        "references": "http://www.apple.com",
        "selectorId": "577",
        "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
        "trafficClass": "BULK_DATA",
        "tunnel": "false"
      }
    ],
    "networkIdentity": [
      {
        "id": "20295087-e4c7-4daf-834e-2c8c99a6c872",
        "instanceId": 12170,
        "authEntityId": 12170,
        "displayName": "12170",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "53,5353",
        "protocol": "UDP",
        "upperPort": 0
      },
      {
        "id": "dde32e65-564f-4621-92e8-4eb9f81088f0",
        "instanceId": 12171,
        "authEntityId": 12171,
        "displayName": "12171",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "53,80,443,5353",
        "protocol": "TCP",
        "upperPort": 0
      }
    ],
    "parentScalableGroup": {
      "idRef": "28e08de4-d7b2-4521-84ce-bc1e5ee0b9cc"
    },
    "user": [

    ],
    "userGroup": [

    ]
  },
  {
    "id": "09d325d0-7389-4bd2-8b14-165581157cec",
    "instanceId": 4090,
    "authEntityId": 4090,
    "displayName": "4090",
    "authEntityClass": -1909073334,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "createTime": 1517966741013,
    "deployed": false,
    "isSeeded": true,
    "isStale": false,
    "lastUpdateTime": 1517966741013,
    "name": "any-host-internal",
    "namespace": "scalablegroup:application",
    "provisioningState": "DEFINED",
    "qualifier": "application",
    "resourceVersion": 0,
    "targetIdList": [

    ],
    "type": "scalablegroup",
    "cfsChangeInfo": [

    ],
    "customProvisions": [

    ],
    "scalableGroupExternalHandle": "any-host-internal",
    "scalableGroupType": "APPLICATION",
    "securityGroupTag": 0,
    "state": "ACTIVE",
    "vnAgnostic": true,
    "identitySource": {
      "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
      "instanceId": 10010,
      "authEntityId": 10010,
      "displayName": "10010",
      "authEntityClass": 1738953278,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "state": "INACTIVE",
      "type": "NBAR"
    },
    "indicativeNetworkIdentity": [
      {
        "id": "75290c13-2709-4875-b220-76b849900968",
        "instanceId": 12141,
        "authEntityId": 12141,
        "displayName": "12141",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipProtocolNumbers": "61",
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "protocol": "ANY_HOST_INTERNAL",
        "upperPort": 0
      }
    ],
    "networkApplications": [
      {
        "id": "2999be04-3efa-485a-80f8-0f5cbe75882c",
        "instanceId": 11055,
        "authEntityId": 11055,
        "displayName": "11055",
        "authEntityClass": -217092956,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "appProtocol": "ip",
        "applicationGroup": "other",
        "applicationType": "DEFAULT",
        "categoryId": "6daa3dfd-3526-407a-88f0-97824b3f3a24",
        "createTime": 1517966741013,
        "encrypted": "unassigned",
        "engineId": "1",
        "helpString": "any host internal protocol",
        "ignoreConflict": false,
        "lastUpdateTime": 1517966741013,
        "longDescription": "Registered with IANA as IP Protocol 61",
        "name": "any-host-internal",
        "nbarId": "815",
        "p2pTechnology": "unassigned",
        "popularity": 1,
        "rank": 65535,
        "references": "http://www.iana.org/assignments/protocol-numbers/protocol-numbers.xml",
        "selectorId": "61",
        "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
        "trafficClass": "BULK_DATA",
        "tunnel": "unassigned"
      }
    ],
    "networkIdentity": [
      {
        "id": "015c114b-6342-49a2-a015-4532e0d2c2b3",
        "instanceId": 12140,
        "authEntityId": 12140,
        "displayName": "12140",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipProtocolNumbers": "61",
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "protocol": "ANY_HOST_INTERNAL",
        "upperPort": 0
      }
    ],
    "parentScalableGroup": {
      "idRef": "000c15bf-28fe-4fbb-9b69-35e6502721f1"
    },
    "user": [

    ],
    "userGroup": [

    ]
  },
  {
    "id": "0a4078e6-5b1c-447f-9841-ad4a7fbe0798",
    "instanceId": 4251,
    "authEntityId": 4251,
    "displayName": "4251",
    "authEntityClass": -1909073334,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "createTime": 1517966741941,
    "deployed": false,
    "isSeeded": true,
    "isStale": false,
    "lastUpdateTime": 1517966741941,
    "name": "cisco-sd-avc",
    "namespace": "scalablegroup:application",
    "provisioningState": "DEFINED",
    "qualifier": "application",
    "resourceVersion": 0,
    "targetIdList": [

    ],
    "type": "scalablegroup",
    "cfsChangeInfo": [

    ],
    "customProvisions": [

    ],
    "scalableGroupExternalHandle": "cisco-sd-avc",
    "scalableGroupType": "APPLICATION",
    "securityGroupTag": 0,
    "state": "ACTIVE",
    "vnAgnostic": true,
    "identitySource": {
      "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
      "instanceId": 10010,
      "authEntityId": 10010,
      "displayName": "10010",
      "authEntityClass": 1738953278,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "state": "INACTIVE",
      "type": "NBAR"
    },
    "indicativeNetworkIdentity": [

    ],
    "networkApplications": [
      {
        "id": "f6a784af-2c32-4a82-a8bd-ca136144ef5b",
        "instanceId": 11205,
        "authEntityId": 11205,
        "displayName": "11205",
        "authEntityClass": -217092956,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "applicationGroup": "other",
        "applicationType": "DEFAULT",
        "categoryId": "1fc17362-9334-4c7b-b752-5afdbb574017",
        "createTime": 1517966741941,
        "encrypted": "false",
        "engineId": "13",
        "helpString": "Cisco software-defined AVC (SD-AVC)",
        "ignoreConflict": false,
        "lastUpdateTime": 1517966741941,
        "longDescription": "Cisco software-defined AVC (SD-AVC) is a network-level component of Cisco AVC that provides services to participating devices in a network.",
        "name": "cisco-sd-avc",
        "nbarId": "1146",
        "p2pTechnology": "false",
        "popularity": 7,
        "rank": 65535,
        "references": "https://www.cisco.com/c/en/us/td/docs/ios-xml/ios/avc/sd-avc/rn/1-0/sd-avc-rn-1-0.html",
        "selectorId": "1146",
        "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
        "trafficClass": "OPS_ADMIN_MGMT",
        "tunnel": "false"
      }
    ],
    "networkIdentity": [

    ],
    "parentScalableGroup": {
      "idRef": "77e75842-e98b-4112-ad0c-300db4bb11be"
    },
    "user": [

    ],
    "userGroup": [

    ]
  },
  {
    "id": "0a79aa0b-32e8-442e-b692-49bd1255c199",
    "instanceId": 15343,
    "authEntityId": 15343,
    "displayName": "15343",
    "authEntityClass": -1909073334,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "createTime": 1517966744773,
    "deployed": false,
    "isSeeded": true,
    "isStale": false,
    "lastUpdateTime": 1517966744773,
    "name": "vatp",
    "namespace": "scalablegroup:application",
    "provisioningState": "DEFINED",
    "qualifier": "application",
    "resourceVersion": 0,
    "targetIdList": [

    ],
    "type": "scalablegroup",
    "cfsChangeInfo": [

    ],
    "customProvisions": [

    ],
    "scalableGroupExternalHandle": "vatp",
    "scalableGroupType": "APPLICATION",
    "securityGroupTag": 0,
    "state": "ACTIVE",
    "vnAgnostic": true,
    "identitySource": {
      "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
      "instanceId": 10010,
      "authEntityId": 10010,
      "displayName": "10010",
      "authEntityClass": 1738953278,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "state": "INACTIVE",
      "type": "NBAR"
    },
    "indicativeNetworkIdentity": [
      {
        "id": "684ef88a-6b23-44be-b4b9-ce3a2d0f5222",
        "instanceId": 17653,
        "authEntityId": 17653,
        "displayName": "17653",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "690",
        "protocol": "UDP",
        "upperPort": 0
      },
      {
        "id": "8df7d69b-712c-42af-8e60-009e45423524",
        "instanceId": 17654,
        "authEntityId": 17654,
        "displayName": "17654",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "690",
        "protocol": "TCP",
        "upperPort": 0
      }
    ],
    "networkApplications": [
      {
        "id": "64a5efc2-ec6b-4275-b9f8-a17cfca02e75",
        "instanceId": 16286,
        "authEntityId": 16286,
        "displayName": "16286",
        "authEntityClass": -217092956,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "appProtocol": "tcp/udp",
        "applicationGroup": "other",
        "applicationType": "DEFAULT",
        "categoryId": "6daa3dfd-3526-407a-88f0-97824b3f3a24",
        "createTime": 1517966744773,
        "encrypted": "false",
        "engineId": "3",
        "helpString": "Velazquez Application Transfer Protocol",
        "ignoreConflict": false,
        "lastUpdateTime": 1517966744773,
        "longDescription": "Registered with IANA on port 690 TCP/UDP",
        "name": "vatp",
        "nbarId": "598",
        "p2pTechnology": "false",
        "popularity": 1,
        "rank": 65535,
        "references": "http://www.iana.org/assignments/service-names-port-numbers/service-names-port-numbers.xml",
        "selectorId": "690",
        "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
        "trafficClass": "BULK_DATA",
        "tunnel": "false"
      }
    ],
    "networkIdentity": [
      {
        "id": "7e61f488-d501-4192-93ec-80c2a66f7724",
        "instanceId": 17651,
        "authEntityId": 17651,
        "displayName": "17651",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "690",
        "protocol": "TCP",
        "upperPort": 0
      },
      {
        "id": "7d740646-7376-4f54-bc10-867e9cd2541a",
        "instanceId": 17652,
        "authEntityId": 17652,
        "displayName": "17652",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "690",
        "protocol": "UDP",
        "upperPort": 0
      }
    ],
    "parentScalableGroup": {
      "idRef": "000c15bf-28fe-4fbb-9b69-35e6502721f1"
    },
    "user": [

    ],
    "userGroup": [

    ]
  },
  {
    "id": "0ac5748e-559f-44a3-9bc3-aa7f78603e5c",
    "instanceId": 4587,
    "authEntityId": 4587,
    "displayName": "4587",
    "authEntityClass": -1909073334,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "createTime": 1517966743059,
    "deployed": false,
    "isSeeded": true,
    "isStale": false,
    "lastUpdateTime": 1517966743059,
    "name": "idpr-cmtp",
    "namespace": "scalablegroup:application",
    "provisioningState": "DEFINED",
    "qualifier": "application",
    "resourceVersion": 0,
    "targetIdList": [

    ],
    "type": "scalablegroup",
    "cfsChangeInfo": [

    ],
    "customProvisions": [

    ],
    "scalableGroupExternalHandle": "idpr-cmtp",
    "scalableGroupType": "APPLICATION",
    "securityGroupTag": 0,
    "state": "ACTIVE",
    "vnAgnostic": true,
    "identitySource": {
      "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
      "instanceId": 10010,
      "authEntityId": 10010,
      "displayName": "10010",
      "authEntityClass": 1738953278,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "state": "INACTIVE",
      "type": "NBAR"
    },
    "indicativeNetworkIdentity": [
      {
        "id": "a4bafeed-4fc1-4b5d-91c0-44d481dd7c2c",
        "instanceId": 13476,
        "authEntityId": 13476,
        "displayName": "13476",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipProtocolNumbers": "38",
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "protocol": "IDPR_CMPT",
        "upperPort": 0
      }
    ],
    "networkApplications": [
      {
        "id": "42d80652-373c-404b-a4d9-22bc61cccfed",
        "instanceId": 11537,
        "authEntityId": 11537,
        "displayName": "11537",
        "authEntityClass": -217092956,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "appProtocol": "ip",
        "applicationGroup": "other",
        "applicationType": "DEFAULT",
        "categoryId": "6daa3dfd-3526-407a-88f0-97824b3f3a24",
        "createTime": 1517966743059,
        "encrypted": "unassigned",
        "engineId": "1",
        "helpString": "IDPR Control Message Transport Proto",
        "ignoreConflict": false,
        "lastUpdateTime": 1517966743059,
        "longDescription": "IDPR Control Message Transport Protocol constructs and maintains routes between source and destination administrative domains. These domains provide user traffic with the services requested within the constraints stipulated for the domains transited.",
        "name": "idpr-cmtp",
        "nbarId": "792",
        "p2pTechnology": "unassigned",
        "popularity": 2,
        "rank": 65535,
        "references": "https://trac.tools.ietf.org/rfc/rfc1477.txt",
        "selectorId": "38",
        "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
        "trafficClass": "BULK_DATA",
        "tunnel": "unassigned"
      }
    ],
    "networkIdentity": [
      {
        "id": "ba99857d-26c5-42c4-95a8-cd2b20e0d32e",
        "instanceId": 13475,
        "authEntityId": 13475,
        "displayName": "13475",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipProtocolNumbers": "38",
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "protocol": "IDPR_CMPT",
        "upperPort": 0
      }
    ],
    "parentScalableGroup": {
      "idRef": "000c15bf-28fe-4fbb-9b69-35e6502721f1"
    },
    "user": [

    ],
    "userGroup": [

    ]
  },
  {
    "id": "0aea7864-9367-464a-875e-252a40073b0e",
    "instanceId": 4115,
    "authEntityId": 4115,
    "displayName": "4115",
    "authEntityClass": -1909073334,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "createTime": 1517966741221,
    "deployed": false,
    "isSeeded": true,
    "isStale": false,
    "lastUpdateTime": 1517966741221,
    "name": "argus",
    "namespace": "scalablegroup:application",
    "provisioningState": "DEFINED",
    "qualifier": "application",
    "resourceVersion": 0,
    "targetIdList": [

    ],
    "type": "scalablegroup",
    "cfsChangeInfo": [

    ],
    "customProvisions": [

    ],
    "scalableGroupExternalHandle": "argus",
    "scalableGroupType": "APPLICATION",
    "securityGroupTag": 0,
    "state": "ACTIVE",
    "vnAgnostic": true,
    "identitySource": {
      "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
      "instanceId": 10010,
      "authEntityId": 10010,
      "displayName": "10010",
      "authEntityClass": 1738953278,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "state": "INACTIVE",
      "type": "NBAR"
    },
    "indicativeNetworkIdentity": [
      {
        "id": "635d7bfb-49a1-49b5-a35b-8757c7905d1f",
        "instanceId": 12198,
        "authEntityId": 12198,
        "displayName": "12198",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipProtocolNumbers": "13",
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "protocol": "ARGUS",
        "upperPort": 0
      }
    ],
    "networkApplications": [
      {
        "id": "8eccea04-a108-4a58-8d4b-479658f23ae1",
        "instanceId": 11077,
        "authEntityId": 11077,
        "displayName": "11077",
        "authEntityClass": -217092956,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "appProtocol": "ip",
        "applicationGroup": "other",
        "applicationType": "DEFAULT",
        "categoryId": "6daa3dfd-3526-407a-88f0-97824b3f3a24",
        "createTime": 1517966741221,
        "encrypted": "unassigned",
        "engineId": "1",
        "helpString": "ARGUS",
        "ignoreConflict": false,
        "lastUpdateTime": 1517966741221,
        "longDescription": "Registered with IANA as IP Protocol 13",
        "name": "argus",
        "nbarId": "768",
        "p2pTechnology": "unassigned",
        "popularity": 1,
        "rank": 65535,
        "references": "http://www.iana.org/assignments/protocol-numbers/protocol-numbers.xml",
        "selectorId": "13",
        "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
        "trafficClass": "BULK_DATA",
        "tunnel": "unassigned"
      }
    ],
    "networkIdentity": [
      {
        "id": "fbf6b73f-49f7-4d6d-b862-11c5e9d2f531",
        "instanceId": 12197,
        "authEntityId": 12197,
        "displayName": "12197",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipProtocolNumbers": "13",
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "protocol": "ARGUS",
        "upperPort": 0
      }
    ],
    "parentScalableGroup": {
      "idRef": "000c15bf-28fe-4fbb-9b69-35e6502721f1"
    },
    "user": [

    ],
    "userGroup": [

    ]
  },
  {
    "id": "0b0afa88-5bef-4b75-83a1-fb3537d067fe",
    "instanceId": 4113,
    "authEntityId": 4113,
    "displayName": "4113",
    "authEntityClass": -1909073334,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "createTime": 1517966741214,
    "deployed": false,
    "isSeeded": true,
    "isStale": false,
    "lastUpdateTime": 1517966741214,
    "name": "arcisdms",
    "namespace": "scalablegroup:application",
    "provisioningState": "DEFINED",
    "qualifier": "application",
    "resourceVersion": 0,
    "targetIdList": [

    ],
    "type": "scalablegroup",
    "cfsChangeInfo": [

    ],
    "customProvisions": [

    ],
    "scalableGroupExternalHandle": "arcisdms",
    "scalableGroupType": "APPLICATION",
    "securityGroupTag": 0,
    "state": "ACTIVE",
    "vnAgnostic": true,
    "identitySource": {
      "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
      "instanceId": 10010,
      "authEntityId": 10010,
      "displayName": "10010",
      "authEntityClass": 1738953278,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "state": "INACTIVE",
      "type": "NBAR"
    },
    "indicativeNetworkIdentity": [
      {
        "id": "35ecf14d-194f-42b9-bd87-483d5f2f8f53",
        "instanceId": 12193,
        "authEntityId": 12193,
        "displayName": "12193",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "262",
        "protocol": "UDP",
        "upperPort": 0
      },
      {
        "id": "3e66ae56-176b-4d70-b4c1-82fb6edd0c49",
        "instanceId": 12194,
        "authEntityId": 12194,
        "displayName": "12194",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "262",
        "protocol": "TCP",
        "upperPort": 0
      }
    ],
    "networkApplications": [
      {
        "id": "fe48bc18-1cac-4d60-a09a-4689f81c1bc5",
        "instanceId": 11075,
        "authEntityId": 11075,
        "displayName": "11075",
        "authEntityClass": -217092956,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "appProtocol": "tcp/udp",
        "applicationGroup": "other",
        "applicationType": "DEFAULT",
        "categoryId": "6daa3dfd-3526-407a-88f0-97824b3f3a24",
        "createTime": 1517966741214,
        "encrypted": "false",
        "engineId": "3",
        "helpString": "arcisdms",
        "ignoreConflict": false,
        "lastUpdateTime": 1517966741214,
        "longDescription": "Registered with IANA on port 262 TCP/UDP",
        "name": "arcisdms",
        "nbarId": "1134",
        "p2pTechnology": "false",
        "popularity": 1,
        "rank": 65535,
        "references": "http://www.iana.org/assignments/service-names-port-numbers/service-names-port-numbers.xml",
        "selectorId": "262",
        "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
        "trafficClass": "BULK_DATA",
        "tunnel": "false"
      }
    ],
    "networkIdentity": [
      {
        "id": "b7747412-5832-40b1-90f0-7f90cc88dcef",
        "instanceId": 12192,
        "authEntityId": 12192,
        "displayName": "12192",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "262",
        "protocol": "UDP",
        "upperPort": 0
      },
      {
        "id": "17141562-25d9-4787-b3c2-170fc00cd6aa",
        "instanceId": 12191,
        "authEntityId": 12191,
        "displayName": "12191",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "262",
        "protocol": "TCP",
        "upperPort": 0
      }
    ],
    "parentScalableGroup": {
      "idRef": "000c15bf-28fe-4fbb-9b69-35e6502721f1"
    },
    "user": [

    ],
    "userGroup": [

    ]
  },
  {
    "id": "0b1b44c3-39aa-44b5-ad92-317770f94f91",
    "instanceId": 4184,
    "authEntityId": 4184,
    "displayName": "4184",
    "authEntityClass": -1909073334,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "createTime": 1517966741573,
    "deployed": false,
    "isSeeded": true,
    "isStale": false,
    "lastUpdateTime": 1517966741573,
    "name": "bittorrent",
    "namespace": "scalablegroup:application",
    "provisioningState": "DEFINED",
    "qualifier": "application",
    "resourceVersion": 0,
    "targetIdList": [

    ],
    "type": "scalablegroup",
    "cfsChangeInfo": [

    ],
    "customProvisions": [

    ],
    "scalableGroupExternalHandle": "bittorrent",
    "scalableGroupType": "APPLICATION",
    "securityGroupTag": 0,
    "state": "ACTIVE",
    "vnAgnostic": true,
    "identitySource": {
      "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
      "instanceId": 10010,
      "authEntityId": 10010,
      "displayName": "10010",
      "authEntityClass": 1738953278,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "state": "INACTIVE",
      "type": "NBAR"
    },
    "indicativeNetworkIdentity": [
      {
        "id": "4052d375-cd1c-4cd3-b50b-3adf30bde173",
        "instanceId": 12397,
        "authEntityId": 12397,
        "displayName": "12397",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "6881,6882,6883,6884,6885,6886,6887,6888,6889",
        "protocol": "TCP",
        "upperPort": 0
      }
    ],
    "networkApplications": [
      {
        "id": "061339c1-7452-4a16-8603-684a668e9701",
        "instanceId": 11143,
        "authEntityId": 11143,
        "displayName": "11143",
        "authEntityClass": -217092956,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "appProtocol": "tcp/udp",
        "applicationGroup": "bittorrent-group",
        "applicationType": "DEFAULT",
        "categoryId": "b33b2761-d981-4734-810a-a6a1c18ab352",
        "createTime": 1517966741573,
        "encrypted": "false",
        "engineId": "13",
        "helpString": "bittorrent p2p file sharing client",
        "ignoreConflict": false,
        "lastUpdateTime": 1517966741573,
        "longDescription": "BitTorrent is a p2p file sharing protocol used for distributing files over the internet. It identifies content by URL and is designed to integrate seamlessly with the web. The BitTorrent protocol is based on a BitTorrent tracker (server) that initializes the connections between the clients (peers).",
        "name": "bittorrent",
        "nbarId": "69",
        "p2pTechnology": "true",
        "popularity": 10,
        "rank": 65535,
        "references": "http://jonas.nitro.dk/bittorrent/bittorrent-rfc.html",
        "selectorId": "69",
        "subCategory": "79fe7198-9ce3-4c01-9b58-45a8df3d9513",
        "trafficClass": "BULK_DATA",
        "tunnel": "false"
      }
    ],
    "networkIdentity": [
      {
        "id": "dfd59e92-6afd-43d3-8a1d-0e545f48f71f",
        "instanceId": 12395,
        "authEntityId": 12395,
        "displayName": "12395",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "3544,3545",
        "protocol": "UDP",
        "upperPort": 0
      },
      {
        "id": "606987b5-3b17-422c-b928-dab0f835f07d",
        "instanceId": 12396,
        "authEntityId": 12396,
        "displayName": "12396",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "80,1080,3544,6881,6882,6883,6884,6885,6886,6887,6888,6889",
        "protocol": "TCP",
        "upperPort": 0
      }
    ],
    "parentScalableGroup": {
      "idRef": "7a1a3bbb-dc84-4a35-82a4-7e650c4c4761"
    },
    "user": [

    ],
    "userGroup": [

    ]
  },
  {
    "id": "0b4fa7a1-d1e4-4108-98c0-fbcd3aed2182",
    "instanceId": 4590,
    "authEntityId": 4590,
    "displayName": "4590",
    "authEntityClass": -1909073334,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "createTime": 1517966743066,
    "deployed": false,
    "isSeeded": true,
    "isStale": false,
    "lastUpdateTime": 1517966743066,
    "name": "ieee-mms-ssl",
    "namespace": "scalablegroup:application",
    "provisioningState": "DEFINED",
    "qualifier": "application",
    "resourceVersion": 0,
    "targetIdList": [

    ],
    "type": "scalablegroup",
    "cfsChangeInfo": [

    ],
    "customProvisions": [

    ],
    "scalableGroupExternalHandle": "ieee-mms-ssl",
    "scalableGroupType": "APPLICATION",
    "securityGroupTag": 0,
    "state": "ACTIVE",
    "vnAgnostic": true,
    "identitySource": {
      "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
      "instanceId": 10010,
      "authEntityId": 10010,
      "displayName": "10010",
      "authEntityClass": 1738953278,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "state": "INACTIVE",
      "type": "NBAR"
    },
    "indicativeNetworkIdentity": [
      {
        "id": "52f64852-02e7-4eca-801d-9d148221968e",
        "instanceId": 13485,
        "authEntityId": 13485,
        "displayName": "13485",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "695",
        "protocol": "TCP",
        "upperPort": 0
      },
      {
        "id": "555819f2-11d8-4ecd-98e2-963d74e458ea",
        "instanceId": 13486,
        "authEntityId": 13486,
        "displayName": "13486",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "695",
        "protocol": "UDP",
        "upperPort": 0
      }
    ],
    "networkApplications": [
      {
        "id": "8cd4b411-cd18-4c82-9d7f-c352fc24487d",
        "instanceId": 11540,
        "authEntityId": 11540,
        "displayName": "11540",
        "authEntityClass": -217092956,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "appProtocol": "tcp/udp",
        "applicationGroup": "other",
        "applicationType": "DEFAULT",
        "categoryId": "6daa3dfd-3526-407a-88f0-97824b3f3a24",
        "createTime": 1517966743066,
        "encrypted": "true",
        "engineId": "3",
        "helpString": "secure IEEE Media Management System MMS",
        "ignoreConflict": false,
        "lastUpdateTime": 1517966743066,
        "longDescription": "The IEEE Media Management System (MMS) is a distributed, multi-platform system for managing removable media. The IEEE MMS standards de?ne a software component model for working with removable media as well as a number of protocols that de?ne interfaces between the components. These standards enable vendors to construct applications that use removable media as well as components of an MMS that interoperate with other MMS components.",
        "name": "ieee-mms-ssl",
        "nbarId": "603",
        "p2pTechnology": "false",
        "popularity": 5,
        "rank": 65535,
        "references": "http://grouper.ieee.org/groups/1619/email/pdf00001.pdf",
        "selectorId": "695",
        "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
        "trafficClass": "BULK_DATA",
        "tunnel": "false"
      }
    ],
    "networkIdentity": [
      {
        "id": "e5dd19da-e8f5-4c64-ad2c-82219bcb64c0",
        "instanceId": 13483,
        "authEntityId": 13483,
        "displayName": "13483",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "695",
        "protocol": "UDP",
        "upperPort": 0
      },
      {
        "id": "848e3b56-450a-4582-bb07-a63ff39672b9",
        "instanceId": 13484,
        "authEntityId": 13484,
        "displayName": "13484",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "695",
        "protocol": "TCP",
        "upperPort": 0
      }
    ],
    "parentScalableGroup": {
      "idRef": "000c15bf-28fe-4fbb-9b69-35e6502721f1"
    },
    "user": [

    ],
    "userGroup": [

    ]
  },
  {
    "id": "0c5090a6-04d8-4811-a2e1-f63517403f49",
    "instanceId": 15031,
    "authEntityId": 15031,
    "displayName": "15031",
    "authEntityClass": -1909073334,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "createTime": 1517966744068,
    "deployed": false,
    "isSeeded": true,
    "isStale": false,
    "lastUpdateTime": 1517966744068,
    "name": "rdb-dbs-disp",
    "namespace": "scalablegroup:application",
    "provisioningState": "DEFINED",
    "qualifier": "application",
    "resourceVersion": 0,
    "targetIdList": [

    ],
    "type": "scalablegroup",
    "cfsChangeInfo": [

    ],
    "customProvisions": [

    ],
    "scalableGroupExternalHandle": "rdb-dbs-disp",
    "scalableGroupType": "APPLICATION",
    "securityGroupTag": 0,
    "state": "ACTIVE",
    "vnAgnostic": true,
    "identitySource": {
      "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
      "instanceId": 10010,
      "authEntityId": 10010,
      "displayName": "10010",
      "authEntityClass": 1738953278,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "state": "INACTIVE",
      "type": "NBAR"
    },
    "indicativeNetworkIdentity": [
      {
        "id": "5735e0d7-8943-483c-acb7-ae0e42ba478f",
        "instanceId": 14723,
        "authEntityId": 14723,
        "displayName": "14723",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "1571",
        "protocol": "TCP",
        "upperPort": 0
      },
      {
        "id": "d731f3f2-433c-4f40-879b-9427ba0beda1",
        "instanceId": 14724,
        "authEntityId": 14724,
        "displayName": "14724",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "1571",
        "protocol": "UDP",
        "upperPort": 0
      }
    ],
    "networkApplications": [
      {
        "id": "1ab32dc5-c911-40bc-ab48-179ede8cf7b2",
        "instanceId": 11970,
        "authEntityId": 11970,
        "displayName": "11970",
        "authEntityClass": -217092956,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "appProtocol": "tcp/udp",
        "applicationGroup": "sqlsvr-group",
        "applicationType": "DEFAULT",
        "categoryId": "cb5915d1-afec-4c30-ac9e-069ff382272f",
        "createTime": 1517966744068,
        "encrypted": "false",
        "engineId": "3",
        "helpString": "Oracle Remote Data Base",
        "ignoreConflict": false,
        "lastUpdateTime": 1517966744068,
        "longDescription": "Oracle Remote Data Base provides the user with a connection to a remote Oracle database.",
        "name": "rdb-dbs-disp",
        "nbarId": "694",
        "p2pTechnology": "false",
        "popularity": 1,
        "rank": 65535,
        "references": "http://www.oracle.com",
        "selectorId": "1571",
        "subCategory": "b90786f4-0190-45b8-9924-21b3e9682590",
        "trafficClass": "TRANSACTIONAL_DATA",
        "tunnel": "false"
      }
    ],
    "networkIdentity": [
      {
        "id": "8f7b2920-1d12-49bc-9bf7-56db71d44d52",
        "instanceId": 14721,
        "authEntityId": 14721,
        "displayName": "14721",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "1571",
        "protocol": "UDP",
        "upperPort": 0
      },
      {
        "id": "feb0c0ab-724d-4028-8fde-3593cf9c9920",
        "instanceId": 14722,
        "authEntityId": 14722,
        "displayName": "14722",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "1571",
        "protocol": "TCP",
        "upperPort": 0
      }
    ],
    "parentScalableGroup": {
      "idRef": "8718395e-683e-4a2d-a232-100202751271"
    },
    "user": [

    ],
    "userGroup": [

    ]
  },
  {
    "id": "0c60e0c6-52b7-4c45-8b9a-08c7b6dac1f6",
    "instanceId": 15166,
    "authEntityId": 15166,
    "displayName": "15166",
    "authEntityClass": -1909073334,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "createTime": 1517966744385,
    "deployed": false,
    "isSeeded": true,
    "isStale": false,
    "lastUpdateTime": 1517966744385,
    "name": "smsd",
    "namespace": "scalablegroup:application",
    "provisioningState": "DEFINED",
    "qualifier": "application",
    "resourceVersion": 0,
    "targetIdList": [

    ],
    "type": "scalablegroup",
    "cfsChangeInfo": [

    ],
    "customProvisions": [

    ],
    "scalableGroupExternalHandle": "smsd",
    "scalableGroupType": "APPLICATION",
    "securityGroupTag": 0,
    "state": "ACTIVE",
    "vnAgnostic": true,
    "identitySource": {
      "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
      "instanceId": 10010,
      "authEntityId": 10010,
      "displayName": "10010",
      "authEntityClass": 1738953278,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "state": "INACTIVE",
      "type": "NBAR"
    },
    "indicativeNetworkIdentity": [
      {
        "id": "db60d54e-736c-47e7-beb6-a1b1e0038052",
        "instanceId": 17145,
        "authEntityId": 17145,
        "displayName": "17145",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "596",
        "protocol": "UDP",
        "upperPort": 0
      },
      {
        "id": "c9a90d99-b14a-4efc-9fa5-793fabb52226",
        "instanceId": 17146,
        "authEntityId": 17146,
        "displayName": "17146",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "596",
        "protocol": "TCP",
        "upperPort": 0
      }
    ],
    "networkApplications": [
      {
        "id": "0cd2a8f2-3444-41d8-a6d7-83ad4ba31731",
        "instanceId": 16109,
        "authEntityId": 16109,
        "displayName": "16109",
        "authEntityClass": -217092956,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "appProtocol": "tcp/udp",
        "applicationGroup": "other",
        "applicationType": "DEFAULT",
        "categoryId": "6daa3dfd-3526-407a-88f0-97824b3f3a24",
        "createTime": 1517966744385,
        "encrypted": "false",
        "engineId": "3",
        "helpString": "smsd",
        "ignoreConflict": false,
        "lastUpdateTime": 1517966744385,
        "longDescription": "The SysMan Station is a client-server application consisting of a daemon (smsd) and the SysMan Station graphical user interface (sysman station). The SysMan Station provides the ability to monitor and manage a single system or a TruCluster system. The smsd server is responsible for gathering system management data from the host and presenting that information to the SysMan Station client.",
        "name": "smsd",
        "nbarId": "510",
        "p2pTechnology": "false",
        "popularity": 1,
        "rank": 65535,
        "references": "http://h30097.www3.hp.com/docs/base_doc/DOCUMENTATION/V51B_HTML/MAN/MAN8/0252____.HTM",
        "selectorId": "596",
        "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
        "trafficClass": "BULK_DATA",
        "tunnel": "false"
      }
    ],
    "networkIdentity": [
      {
        "id": "7c66a910-7749-431f-a322-79fb06f6ff0d",
        "instanceId": 17143,
        "authEntityId": 17143,
        "displayName": "17143",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "596",
        "protocol": "UDP",
        "upperPort": 0
      },
      {
        "id": "9b7de234-240f-451e-a92f-0c73e092541a",
        "instanceId": 17144,
        "authEntityId": 17144,
        "displayName": "17144",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "596",
        "protocol": "TCP",
        "upperPort": 0
      }
    ],
    "parentScalableGroup": {
      "idRef": "000c15bf-28fe-4fbb-9b69-35e6502721f1"
    },
    "user": [

    ],
    "userGroup": [

    ]
  },
  {
    "id": "0c6f01db-4989-40a5-8816-78880d7676d1",
    "instanceId": 4328,
    "authEntityId": 4328,
    "displayName": "4328",
    "authEntityClass": -1909073334,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "createTime": 1517966742297,
    "deployed": false,
    "isSeeded": true,
    "isStale": false,
    "lastUpdateTime": 1517966742297,
    "name": "datex-asn",
    "namespace": "scalablegroup:application",
    "provisioningState": "DEFINED",
    "qualifier": "application",
    "resourceVersion": 0,
    "targetIdList": [

    ],
    "type": "scalablegroup",
    "cfsChangeInfo": [

    ],
    "customProvisions": [

    ],
    "scalableGroupExternalHandle": "datex-asn",
    "scalableGroupType": "APPLICATION",
    "securityGroupTag": 0,
    "state": "ACTIVE",
    "vnAgnostic": true,
    "identitySource": {
      "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
      "instanceId": 10010,
      "authEntityId": 10010,
      "displayName": "10010",
      "authEntityClass": 1738953278,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "state": "INACTIVE",
      "type": "NBAR"
    },
    "indicativeNetworkIdentity": [
      {
        "id": "9f13a2b3-8fd3-470b-9d36-2bc5af026acb",
        "instanceId": 12760,
        "authEntityId": 12760,
        "displayName": "12760",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "355",
        "protocol": "UDP",
        "upperPort": 0
      },
      {
        "id": "5d03061b-f323-4662-aecc-5f94d5222b70",
        "instanceId": 12761,
        "authEntityId": 12761,
        "displayName": "12761",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "355",
        "protocol": "TCP",
        "upperPort": 0
      }
    ],
    "networkApplications": [
      {
        "id": "859e918d-342b-418b-9370-eaa55f6edf94",
        "instanceId": 11279,
        "authEntityId": 11279,
        "displayName": "11279",
        "authEntityClass": -217092956,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "appProtocol": "tcp/udp",
        "applicationGroup": "other",
        "applicationType": "DEFAULT",
        "categoryId": "d264d6f4-b014-43e6-acbc-516128d1c6c8",
        "createTime": 1517966742297,
        "encrypted": "false",
        "engineId": "3",
        "helpString": "datex-asn",
        "ignoreConflict": false,
        "lastUpdateTime": 1517966742297,
        "longDescription": "DATEX-ASN (AP-DATEX) provides a communications stack that supports routing, sequencing, and file transfer over point-to-point links, based on (sockets) TCP, IP, and PPP.",
        "name": "datex-asn",
        "nbarId": "271",
        "p2pTechnology": "false",
        "popularity": 1,
        "rank": 65535,
        "references": "http://www.standards.its.dot.gov/fact_sheet.asp?f=33",
        "selectorId": "355",
        "subCategory": "68c4f989-5735-4e16-8d1c-6a9d89d5df9c",
        "trafficClass": "SIGNALING",
        "tunnel": "false"
      }
    ],
    "networkIdentity": [
      {
        "id": "3d31da11-0046-4f71-a32b-7f32939866df",
        "instanceId": 12758,
        "authEntityId": 12758,
        "displayName": "12758",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "355",
        "protocol": "UDP",
        "upperPort": 0
      },
      {
        "id": "d319495a-9d1e-47c3-b3cc-76cb931ae61c",
        "instanceId": 12759,
        "authEntityId": 12759,
        "displayName": "12759",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "355",
        "protocol": "TCP",
        "upperPort": 0
      }
    ],
    "parentScalableGroup": {
      "idRef": "a3243a74-3864-4041-a927-0201030da17c"
    },
    "user": [

    ],
    "userGroup": [

    ]
  },
  {
    "id": "0c7bdc06-8e59-4f7f-882b-92ad8dc54892",
    "instanceId": 4068,
    "authEntityId": 4068,
    "displayName": "4068",
    "authEntityClass": -1909073334,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "createTime": 1517966740753,
    "deployed": false,
    "isSeeded": true,
    "isStale": false,
    "lastUpdateTime": 1517966740753,
    "name": "airbnb",
    "namespace": "scalablegroup:application",
    "provisioningState": "DEFINED",
    "qualifier": "application",
    "resourceVersion": 0,
    "targetIdList": [

    ],
    "type": "scalablegroup",
    "cfsChangeInfo": [

    ],
    "customProvisions": [

    ],
    "scalableGroupExternalHandle": "airbnb",
    "scalableGroupType": "APPLICATION",
    "securityGroupTag": 0,
    "state": "ACTIVE",
    "vnAgnostic": true,
    "identitySource": {
      "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
      "instanceId": 10010,
      "authEntityId": 10010,
      "displayName": "10010",
      "authEntityClass": 1738953278,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "state": "INACTIVE",
      "type": "NBAR"
    },
    "indicativeNetworkIdentity": [

    ],
    "networkApplications": [
      {
        "id": "9268ee8d-b79f-41bb-9c54-405b185314e1",
        "instanceId": 11037,
        "authEntityId": 11037,
        "displayName": "11037",
        "authEntityClass": -217092956,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "appProtocol": "tcp/udp",
        "applicationGroup": "other",
        "applicationType": "DEFAULT",
        "categoryId": "878644b5-fe91-45b4-9204-31c84a73ba48",
        "createTime": 1517966740753,
        "encrypted": "false",
        "engineId": "13",
        "helpString": "Airbnb - Online accommodation rental service.",
        "ignoreConflict": false,
        "lastUpdateTime": 1517966740753,
        "longDescription": "Airbnb - Online accommodation rental service.",
        "name": "airbnb",
        "nbarId": "1665",
        "p2pTechnology": "false",
        "popularity": 3,
        "rank": 65535,
        "references": "http://airbnb.com",
        "selectorId": "735",
        "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
        "trafficClass": "TRANSACTIONAL_DATA",
        "tunnel": "false"
      }
    ],
    "networkIdentity": [
      {
        "id": "4296769c-41da-4bd0-9e68-c82545cf93c7",
        "instanceId": 12093,
        "authEntityId": 12093,
        "displayName": "12093",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "53,5353",
        "protocol": "UDP",
        "upperPort": 0
      },
      {
        "id": "751c1c86-d8a4-4361-aae2-ab2d4ab430c5",
        "instanceId": 12094,
        "authEntityId": 12094,
        "displayName": "12094",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "53,80,443,5353",
        "protocol": "TCP",
        "upperPort": 0
      }
    ],
    "parentScalableGroup": {
      "idRef": "28e08de4-d7b2-4521-84ce-bc1e5ee0b9cc"
    },
    "user": [

    ],
    "userGroup": [

    ]
  },
  {
    "id": "0ca3627f-ec2d-4634-886d-b70c1299b57e",
    "instanceId": 15350,
    "authEntityId": 15350,
    "displayName": "15350",
    "authEntityClass": -1909073334,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "createTime": 1517966744786,
    "deployed": false,
    "isSeeded": true,
    "isStale": false,
    "lastUpdateTime": 1517966744786,
    "name": "vid",
    "namespace": "scalablegroup:application",
    "provisioningState": "DEFINED",
    "qualifier": "application",
    "resourceVersion": 0,
    "targetIdList": [

    ],
    "type": "scalablegroup",
    "cfsChangeInfo": [

    ],
    "customProvisions": [

    ],
    "scalableGroupExternalHandle": "vid",
    "scalableGroupType": "APPLICATION",
    "securityGroupTag": 0,
    "state": "ACTIVE",
    "vnAgnostic": true,
    "identitySource": {
      "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
      "instanceId": 10010,
      "authEntityId": 10010,
      "displayName": "10010",
      "authEntityClass": 1738953278,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "state": "INACTIVE",
      "type": "NBAR"
    },
    "indicativeNetworkIdentity": [
      {
        "id": "0fe3cdea-9168-4d5e-92df-608c762435ea",
        "instanceId": 17668,
        "authEntityId": 17668,
        "displayName": "17668",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "769",
        "protocol": "UDP",
        "upperPort": 0
      },
      {
        "id": "bfbf8d4f-2c89-402d-9149-1068808e047e",
        "instanceId": 17669,
        "authEntityId": 17669,
        "displayName": "17669",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "769",
        "protocol": "TCP",
        "upperPort": 0
      }
    ],
    "networkApplications": [
      {
        "id": "4539bbe5-a287-4238-a164-43eacb630560",
        "instanceId": 16293,
        "authEntityId": 16293,
        "displayName": "16293",
        "authEntityClass": -217092956,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "appProtocol": "tcp/udp",
        "applicationGroup": "other",
        "applicationType": "DEFAULT",
        "categoryId": "6daa3dfd-3526-407a-88f0-97824b3f3a24",
        "createTime": 1517966744785,
        "encrypted": "false",
        "engineId": "3",
        "helpString": "vid",
        "ignoreConflict": false,
        "lastUpdateTime": 1517966744785,
        "longDescription": "Logitech Vid is a Video-over-IP service based on SightSpeed. It is used by consumers with Logitech webcams.",
        "name": "vid",
        "nbarId": "639",
        "p2pTechnology": "false",
        "popularity": 1,
        "rank": 65535,
        "references": "http://www.sightspeed.com/",
        "selectorId": "769",
        "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
        "trafficClass": "BULK_DATA",
        "tunnel": "false"
      }
    ],
    "networkIdentity": [
      {
        "id": "21b856fb-0719-4177-ad52-d3c0de669ac8",
        "instanceId": 17666,
        "authEntityId": 17666,
        "displayName": "17666",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "769",
        "protocol": "UDP",
        "upperPort": 0
      },
      {
        "id": "b861e7ae-035b-4122-8483-da5c95e184be",
        "instanceId": 17667,
        "authEntityId": 17667,
        "displayName": "17667",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "769",
        "protocol": "TCP",
        "upperPort": 0
      }
    ],
    "parentScalableGroup": {
      "idRef": "000c15bf-28fe-4fbb-9b69-35e6502721f1"
    },
    "user": [

    ],
    "userGroup": [

    ]
  },
  {
    "id": "0d0d0050-f4c8-42d1-b2b9-f76ce01be03b",
    "instanceId": 15144,
    "authEntityId": 15144,
    "displayName": "15144",
    "authEntityClass": -1909073334,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "createTime": 1517966744335,
    "deployed": false,
    "isSeeded": true,
    "isStale": false,
    "lastUpdateTime": 1517966744335,
    "name": "sip",
    "namespace": "scalablegroup:application",
    "provisioningState": "DEFINED",
    "qualifier": "application",
    "resourceVersion": 0,
    "targetIdList": [

    ],
    "type": "scalablegroup",
    "cfsChangeInfo": [

    ],
    "customProvisions": [

    ],
    "scalableGroupExternalHandle": "sip",
    "scalableGroupType": "APPLICATION",
    "securityGroupTag": 0,
    "state": "ACTIVE",
    "vnAgnostic": true,
    "identitySource": {
      "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
      "instanceId": 10010,
      "authEntityId": 10010,
      "displayName": "10010",
      "authEntityClass": 1738953278,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "state": "INACTIVE",
      "type": "NBAR"
    },
    "indicativeNetworkIdentity": [
      {
        "id": "e336da24-12fc-40fa-9a60-1307deb1d82b",
        "instanceId": 17082,
        "authEntityId": 17082,
        "displayName": "17082",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "5060",
        "protocol": "UDP",
        "upperPort": 0
      },
      {
        "id": "4bd8424d-1588-4d26-ab8b-69486a27ffaa",
        "instanceId": 17083,
        "authEntityId": 17083,
        "displayName": "17083",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "5060",
        "protocol": "TCP",
        "upperPort": 0
      }
    ],
    "networkApplications": [
      {
        "id": "68519a33-a465-4143-ab3b-91ade74e9b38",
        "instanceId": 16087,
        "authEntityId": 16087,
        "displayName": "16087",
        "authEntityClass": -217092956,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "appProtocol": "tcp/udp",
        "applicationGroup": "other",
        "applicationType": "DEFAULT",
        "categoryId": "38a8e354-e2fe-4f3c-b1c5-2bbb90fb0d99",
        "createTime": 1517966744335,
        "encrypted": "false",
        "engineId": "3",
        "helpString": "Session Initiation Protocol",
        "ignoreConflict": false,
        "lastUpdateTime": 1517966744335,
        "longDescription": "Session Initiation Protocol is a text-based control protocol used for VoIP communications, Instant Messaging, presence information, file transfer and online games. It can be used for creating, modifying and terminating VoIP sessions through signaling.",
        "name": "sip",
        "nbarId": "65",
        "p2pTechnology": "false",
        "popularity": 8,
        "rank": 65535,
        "references": "http://www.ietf.org/rfc/rfc3261.txt",
        "selectorId": "5060",
        "subCategory": "68c4f989-5735-4e16-8d1c-6a9d89d5df9c",
        "trafficClass": "SIGNALING",
        "tunnel": "false"
      }
    ],
    "networkIdentity": [
      {
        "id": "ad9a23e9-6a4a-4aff-9e4c-c4c3a765507d",
        "instanceId": 17080,
        "authEntityId": 17080,
        "displayName": "17080",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "3478,5060",
        "protocol": "UDP",
        "upperPort": 0
      },
      {
        "id": "347839f3-3278-403d-8df5-fc00d8945160",
        "instanceId": 17081,
        "authEntityId": 17081,
        "displayName": "17081",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "80,443,3478,5060",
        "protocol": "TCP",
        "upperPort": 0
      }
    ],
    "parentScalableGroup": {
      "idRef": "a3243a74-3864-4041-a927-0201030da17c"
    },
    "user": [

    ],
    "userGroup": [

    ]
  },
  {
    "id": "0d61d07f-4555-4fbd-aa9a-2c188b72e899",
    "instanceId": 4688,
    "authEntityId": 4688,
    "displayName": "4688",
    "authEntityClass": -1909073334,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "createTime": 1517966743287,
    "deployed": false,
    "isSeeded": true,
    "isStale": false,
    "lastUpdateTime": 1517966743287,
    "name": "ldap",
    "namespace": "scalablegroup:application",
    "provisioningState": "DEFINED",
    "qualifier": "application",
    "resourceVersion": 0,
    "targetIdList": [

    ],
    "type": "scalablegroup",
    "cfsChangeInfo": [

    ],
    "customProvisions": [

    ],
    "scalableGroupExternalHandle": "ldap",
    "scalableGroupType": "APPLICATION",
    "securityGroupTag": 0,
    "state": "ACTIVE",
    "vnAgnostic": true,
    "identitySource": {
      "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
      "instanceId": 10010,
      "authEntityId": 10010,
      "displayName": "10010",
      "authEntityClass": 1738953278,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "state": "INACTIVE",
      "type": "NBAR"
    },
    "indicativeNetworkIdentity": [
      {
        "id": "0313b199-88d0-41bd-8af5-aa927118de1d",
        "instanceId": 13738,
        "authEntityId": 13738,
        "displayName": "13738",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "389",
        "protocol": "UDP",
        "upperPort": 0
      },
      {
        "id": "05f0fe29-3502-4ac9-a0f5-67f462aabf92",
        "instanceId": 13739,
        "authEntityId": 13739,
        "displayName": "13739",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "389",
        "protocol": "TCP",
        "upperPort": 0
      }
    ],
    "networkApplications": [
      {
        "id": "13b47d66-c527-44e7-a362-f8dde6509db3",
        "instanceId": 11638,
        "authEntityId": 11638,
        "displayName": "11638",
        "authEntityClass": -217092956,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "appProtocol": "tcp/udp",
        "applicationGroup": "ldap-group",
        "applicationType": "DEFAULT",
        "categoryId": "1fc17362-9334-4c7b-b752-5afdbb574017",
        "createTime": 1517966743286,
        "encrypted": "false",
        "engineId": "3",
        "helpString": "Lightweight Directory Access Protocol",
        "ignoreConflict": false,
        "lastUpdateTime": 1517966743286,
        "longDescription": "Lightweight Directory Access Protocol (LDAP) is a protocol designed to access distributed directory services. Typically it uses port 389 for TCP and UDP.",
        "name": "ldap",
        "nbarId": "23",
        "p2pTechnology": "false",
        "popularity": 8,
        "rank": 65535,
        "references": "http://tools.ietf.org/html/rfc4510",
        "selectorId": "389",
        "subCategory": "e390124a-0fd6-4253-b68e-4b0c1af8cb4b",
        "trafficClass": "OPS_ADMIN_MGMT",
        "tunnel": "false"
      }
    ],
    "networkIdentity": [
      {
        "id": "2a01cab6-f03e-4033-9f40-9cce4d945a7c",
        "instanceId": 13736,
        "authEntityId": 13736,
        "displayName": "13736",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "389",
        "protocol": "UDP",
        "upperPort": 0
      },
      {
        "id": "ae754fed-3c05-4c5a-aa8d-f0b7d371da19",
        "instanceId": 13737,
        "authEntityId": 13737,
        "displayName": "13737",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "389",
        "protocol": "TCP",
        "upperPort": 0
      }
    ],
    "parentScalableGroup": {
      "idRef": "7788bf2d-e289-4597-b500-17aacb7e820e"
    },
    "user": [

    ],
    "userGroup": [

    ]
  },
  {
    "id": "0d74668a-54da-47db-9fc8-bc481461c2e0",
    "instanceId": 4417,
    "authEntityId": 4417,
    "displayName": "4417",
    "authEntityClass": -1909073334,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "createTime": 1517966742584,
    "deployed": false,
    "isSeeded": true,
    "isStale": false,
    "lastUpdateTime": 1517966742584,
    "name": "entrust-kmsh",
    "namespace": "scalablegroup:application",
    "provisioningState": "DEFINED",
    "qualifier": "application",
    "resourceVersion": 0,
    "targetIdList": [

    ],
    "type": "scalablegroup",
    "cfsChangeInfo": [

    ],
    "customProvisions": [

    ],
    "scalableGroupExternalHandle": "entrust-kmsh",
    "scalableGroupType": "APPLICATION",
    "securityGroupTag": 0,
    "state": "ACTIVE",
    "vnAgnostic": true,
    "identitySource": {
      "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
      "instanceId": 10010,
      "authEntityId": 10010,
      "displayName": "10010",
      "authEntityClass": 1738953278,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "state": "INACTIVE",
      "type": "NBAR"
    },
    "indicativeNetworkIdentity": [
      {
        "id": "3ae7b9f9-9094-44c1-9dd3-0ad4c43dd941",
        "instanceId": 13046,
        "authEntityId": 13046,
        "displayName": "13046",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "709",
        "protocol": "UDP",
        "upperPort": 0
      },
      {
        "id": "dba46c43-1db4-4479-9d07-f0228581714f",
        "instanceId": 13047,
        "authEntityId": 13047,
        "displayName": "13047",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "709",
        "protocol": "TCP",
        "upperPort": 0
      }
    ],
    "networkApplications": [
      {
        "id": "8b9705cc-219b-4134-8982-d5dfd35de85f",
        "instanceId": 11368,
        "authEntityId": 11368,
        "displayName": "11368",
        "authEntityClass": -217092956,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "appProtocol": "tcp/udp",
        "applicationGroup": "other",
        "applicationType": "DEFAULT",
        "categoryId": "38a8e354-e2fe-4f3c-b1c5-2bbb90fb0d99",
        "createTime": 1517966742584,
        "encrypted": "false",
        "engineId": "3",
        "helpString": "Entrust Key Management Service Handler",
        "ignoreConflict": false,
        "lastUpdateTime": 1517966742584,
        "longDescription": "Entrust Key Management Service Handler (Entrust-KMSH) is a cryptographic key management service for Entrust, a network security company, authentication products.",
        "name": "entrust-kmsh",
        "nbarId": "612",
        "p2pTechnology": "false",
        "popularity": 1,
        "rank": 65535,
        "references": "http://www.entrust.com/",
        "selectorId": "709",
        "subCategory": "e390124a-0fd6-4253-b68e-4b0c1af8cb4b",
        "trafficClass": "OPS_ADMIN_MGMT",
        "tunnel": "false"
      }
    ],
    "networkIdentity": [
      {
        "id": "944b7edb-2944-4020-8f6a-3d65e23c538e",
        "instanceId": 13044,
        "authEntityId": 13044,
        "displayName": "13044",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "709",
        "protocol": "TCP",
        "upperPort": 0
      },
      {
        "id": "351ccac1-e4d5-4575-9249-1c612af468a2",
        "instanceId": 13045,
        "authEntityId": 13045,
        "displayName": "13045",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "709",
        "protocol": "UDP",
        "upperPort": 0
      }
    ],
    "parentScalableGroup": {
      "idRef": "7788bf2d-e289-4597-b500-17aacb7e820e"
    },
    "user": [

    ],
    "userGroup": [

    ]
  },
  {
    "id": "0d7a1d1b-2ecb-4623-8c07-7918ca495d08",
    "instanceId": 15118,
    "authEntityId": 15118,
    "displayName": "15118",
    "authEntityClass": -1909073334,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "createTime": 1517966744275,
    "deployed": false,
    "isSeeded": true,
    "isStale": false,
    "lastUpdateTime": 1517966744275,
    "name": "secure-telnet",
    "namespace": "scalablegroup:application",
    "provisioningState": "DEFINED",
    "qualifier": "application",
    "resourceVersion": 0,
    "targetIdList": [

    ],
    "type": "scalablegroup",
    "cfsChangeInfo": [

    ],
    "customProvisions": [

    ],
    "scalableGroupExternalHandle": "secure-telnet",
    "scalableGroupType": "APPLICATION",
    "securityGroupTag": 0,
    "state": "ACTIVE",
    "vnAgnostic": true,
    "identitySource": {
      "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
      "instanceId": 10010,
      "authEntityId": 10010,
      "displayName": "10010",
      "authEntityClass": 1738953278,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "state": "INACTIVE",
      "type": "NBAR"
    },
    "indicativeNetworkIdentity": [
      {
        "id": "1e421898-ee39-4466-a8c2-7a6b67d2eb02",
        "instanceId": 14994,
        "authEntityId": 14994,
        "displayName": "14994",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "992",
        "protocol": "TCP",
        "upperPort": 0
      },
      {
        "id": "e6ab92e3-373d-4805-bfec-0c048ef2be9f",
        "instanceId": 14995,
        "authEntityId": 14995,
        "displayName": "14995",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "992",
        "protocol": "UDP",
        "upperPort": 0
      }
    ],
    "networkApplications": [
      {
        "id": "d057b032-43c0-4354-98dc-2ad8b43c5e67",
        "instanceId": 16061,
        "authEntityId": 16061,
        "displayName": "16061",
        "authEntityClass": -217092956,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "appProtocol": "tcp/udp",
        "applicationGroup": "other",
        "applicationType": "DEFAULT",
        "categoryId": "1fc17362-9334-4c7b-b752-5afdbb574017",
        "createTime": 1517966744275,
        "encrypted": "true",
        "engineId": "3",
        "helpString": "Telnet protocol over SSL/TLS",
        "ignoreConflict": false,
        "lastUpdateTime": 1517966744275,
        "longDescription": "Secure Telnet is a cross-platform interactive text-based protocol used to connect remote clients over a the Transport Layer Security (TLS) protocol. Telnet participants can decide whether or not to attempt TLS negotiation, and how the two participants should process authentication credentials exchanged as a part of TLS startup.",
        "name": "secure-telnet",
        "nbarId": "43",
        "p2pTechnology": "false",
        "popularity": 3,
        "rank": 65535,
        "references": "http://tools.ietf.org/id/draft-ietf-tn3270e-telnet-tls-06.txt",
        "selectorId": "992",
        "subCategory": "787a7d77-977b-4974-89d3-379ba49e88b7",
        "trafficClass": "OPS_ADMIN_MGMT",
        "tunnel": "false"
      }
    ],
    "networkIdentity": [
      {
        "id": "a9067a2f-76a7-4637-8c64-9026ec4db1d0",
        "instanceId": 14992,
        "authEntityId": 14992,
        "displayName": "14992",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "992",
        "protocol": "UDP",
        "upperPort": 0
      },
      {
        "id": "3e21a798-4420-4205-8a44-e0bde1a75411",
        "instanceId": 14993,
        "authEntityId": 14993,
        "displayName": "14993",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "992",
        "protocol": "TCP",
        "upperPort": 0
      }
    ],
    "parentScalableGroup": {
      "idRef": "e06dd89f-0a70-4422-ab0b-c2314650a9a7"
    },
    "user": [

    ],
    "userGroup": [

    ]
  },
  {
    "id": "0d96db32-fcad-49ef-9c09-51465ad22203",
    "instanceId": 4802,
    "authEntityId": 4802,
    "displayName": "4802",
    "authEntityClass": -1909073334,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "createTime": 1517966743522,
    "deployed": false,
    "isSeeded": true,
    "isStale": false,
    "lastUpdateTime": 1517966743522,
    "name": "msn-messenger-ft",
    "namespace": "scalablegroup:application",
    "provisioningState": "DEFINED",
    "qualifier": "application",
    "resourceVersion": 0,
    "targetIdList": [

    ],
    "type": "scalablegroup",
    "cfsChangeInfo": [

    ],
    "customProvisions": [

    ],
    "scalableGroupExternalHandle": "msn-messenger-ft",
    "scalableGroupType": "APPLICATION",
    "securityGroupTag": 0,
    "state": "ACTIVE",
    "vnAgnostic": true,
    "identitySource": {
      "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
      "instanceId": 10010,
      "authEntityId": 10010,
      "displayName": "10010",
      "authEntityClass": 1738953278,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "state": "INACTIVE",
      "type": "NBAR"
    },
    "indicativeNetworkIdentity": [

    ],
    "networkApplications": [
      {
        "id": "a8f3a4fb-4b02-4b14-ab69-0ed13ff74d39",
        "instanceId": 11751,
        "authEntityId": 11751,
        "displayName": "11751",
        "authEntityClass": -217092956,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "applicationGroup": "msn-messenger-group",
        "applicationType": "DEFAULT",
        "categoryId": "b33b2761-d981-4734-810a-a6a1c18ab352",
        "createTime": 1517966743522,
        "encrypted": "true",
        "engineId": "13",
        "helpString": "DEPRECATED, traffic will not match",
        "ignoreConflict": false,
        "lastUpdateTime": 1517966743522,
        "longDescription": "MSN Messenger (recently renamed to Windows Live Messenger) is an instant messaging client created by Microsoft that is designed to Work with Windows XP, Vista and Windows 7 etc. In June 2009 Microsoft reported the service attracted over 330 million active users each month.MSN-Messenger-Video represents the MSN-Messenger-Video network traffic. MSN-video traffic underlying protocol is rtp over stun traffic. Windows live messenger was replaced by Skype except for China",
        "name": "msn-messenger-ft",
        "nbarId": "1202",
        "p2pTechnology": "false",
        "popularity": 6,
        "rank": 65535,
        "references": "http://explore.live.com/messenger",
        "selectorId": "309",
        "subCategory": "50b55e2e-d0d0-4404-bea4-819351565d90",
        "trafficClass": "BULK_DATA",
        "tunnel": "false"
      }
    ],
    "networkIdentity": [

    ],
    "parentScalableGroup": {
      "idRef": "7a1a3bbb-dc84-4a35-82a4-7e650c4c4761"
    },
    "user": [

    ],
    "userGroup": [

    ]
  },
  {
    "id": "0da87740-3009-48af-9a63-3b0ad1a3c688",
    "instanceId": 4446,
    "authEntityId": 4446,
    "displayName": "4446",
    "authEntityClass": -1909073334,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "createTime": 1517966742685,
    "deployed": false,
    "isSeeded": true,
    "isStale": false,
    "lastUpdateTime": 1517966742685,
    "name": "fedex",
    "namespace": "scalablegroup:application",
    "provisioningState": "DEFINED",
    "qualifier": "application",
    "resourceVersion": 0,
    "targetIdList": [

    ],
    "type": "scalablegroup",
    "cfsChangeInfo": [

    ],
    "customProvisions": [

    ],
    "scalableGroupExternalHandle": "fedex",
    "scalableGroupType": "APPLICATION",
    "securityGroupTag": 0,
    "state": "ACTIVE",
    "vnAgnostic": true,
    "identitySource": {
      "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
      "instanceId": 10010,
      "authEntityId": 10010,
      "displayName": "10010",
      "authEntityClass": 1738953278,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "state": "INACTIVE",
      "type": "NBAR"
    },
    "indicativeNetworkIdentity": [

    ],
    "networkApplications": [
      {
        "id": "55adeddb-bb7c-48fb-a4aa-4dadb258f011",
        "instanceId": 11397,
        "authEntityId": 11397,
        "displayName": "11397",
        "authEntityClass": -217092956,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "appProtocol": "tcp/udp",
        "applicationGroup": "other",
        "applicationType": "DEFAULT",
        "categoryId": "878644b5-fe91-45b4-9204-31c84a73ba48",
        "createTime": 1517966742685,
        "encrypted": "false",
        "engineId": "13",
        "helpString": "FedEx - Courier delivery services.",
        "ignoreConflict": false,
        "lastUpdateTime": 1517966742685,
        "longDescription": "FedEx - Courier delivery services.",
        "name": "fedex",
        "nbarId": "1611",
        "p2pTechnology": "false",
        "popularity": 3,
        "rank": 65535,
        "references": "http://fedex.com",
        "selectorId": "681",
        "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
        "trafficClass": "TRANSACTIONAL_DATA",
        "tunnel": "false"
      }
    ],
    "networkIdentity": [
      {
        "id": "7aa99440-7fc0-41dc-be27-76f42c6fe7dd",
        "instanceId": 13114,
        "authEntityId": 13114,
        "displayName": "13114",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "53,5353",
        "protocol": "UDP",
        "upperPort": 0
      },
      {
        "id": "f0e13074-1eb4-43fc-aa4d-d24c1faa7a76",
        "instanceId": 13115,
        "authEntityId": 13115,
        "displayName": "13115",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "53,80,443,5353",
        "protocol": "TCP",
        "upperPort": 0
      }
    ],
    "parentScalableGroup": {
      "idRef": "28e08de4-d7b2-4521-84ce-bc1e5ee0b9cc"
    },
    "user": [

    ],
    "userGroup": [

    ]
  },
  {
    "id": "0dd3f140-06b2-48ae-ba97-9de1a630ea21",
    "instanceId": 15406,
    "authEntityId": 15406,
    "displayName": "15406",
    "authEntityClass": -1909073334,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "createTime": 1517966744909,
    "deployed": false,
    "isSeeded": true,
    "isStale": false,
    "lastUpdateTime": 1517966744909,
    "name": "webthunder",
    "namespace": "scalablegroup:application",
    "provisioningState": "DEFINED",
    "qualifier": "application",
    "resourceVersion": 0,
    "targetIdList": [

    ],
    "type": "scalablegroup",
    "cfsChangeInfo": [

    ],
    "customProvisions": [

    ],
    "scalableGroupExternalHandle": "webthunder",
    "scalableGroupType": "APPLICATION",
    "securityGroupTag": 0,
    "state": "ACTIVE",
    "vnAgnostic": true,
    "identitySource": {
      "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
      "instanceId": 10010,
      "authEntityId": 10010,
      "displayName": "10010",
      "authEntityClass": 1738953278,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "state": "INACTIVE",
      "type": "NBAR"
    },
    "indicativeNetworkIdentity": [

    ],
    "networkApplications": [
      {
        "id": "bd717838-bdce-4d9c-9d5b-8fafda3ce67e",
        "instanceId": 16349,
        "authEntityId": 16349,
        "displayName": "16349",
        "authEntityClass": -217092956,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "appProtocol": "tcp",
        "applicationGroup": "xunlei-group",
        "applicationType": "DEFAULT",
        "categoryId": "b33b2761-d981-4734-810a-a6a1c18ab352",
        "createTime": 1517966744909,
        "encrypted": "false",
        "engineId": "13",
        "helpString": "Peer-to-Peer File-Sharing",
        "ignoreConflict": false,
        "lastUpdateTime": 1517966744909,
        "longDescription": "Webthunder is a peer to peer file sharing application. It works over the HTTP protocol and is popular in China.",
        "name": "webthunder",
        "nbarId": "1055",
        "p2pTechnology": "true",
        "popularity": 6,
        "rank": 65535,
        "references": "http://my.xunlei.com/setup.htm",
        "selectorId": "445",
        "subCategory": "79fe7198-9ce3-4c01-9b58-45a8df3d9513",
        "trafficClass": "BULK_DATA",
        "tunnel": "false"
      }
    ],
    "networkIdentity": [
      {
        "id": "263bc534-6f31-4ab3-bf7e-aa775b764d9c",
        "instanceId": 17822,
        "authEntityId": 17822,
        "displayName": "17822",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "80",
        "protocol": "TCP",
        "upperPort": 0
      }
    ],
    "parentScalableGroup": {
      "idRef": "7a1a3bbb-dc84-4a35-82a4-7e650c4c4761"
    },
    "user": [

    ],
    "userGroup": [

    ]
  },
  {
    "id": "0de9fd64-ae56-4088-8c2a-ab4d352f264f",
    "instanceId": 4748,
    "authEntityId": 4748,
    "displayName": "4748",
    "authEntityClass": -1909073334,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "createTime": 1517966743415,
    "deployed": false,
    "isSeeded": true,
    "isStale": false,
    "lastUpdateTime": 1517966743415,
    "name": "mikogo",
    "namespace": "scalablegroup:application",
    "provisioningState": "DEFINED",
    "qualifier": "application",
    "resourceVersion": 0,
    "targetIdList": [

    ],
    "type": "scalablegroup",
    "cfsChangeInfo": [

    ],
    "customProvisions": [

    ],
    "scalableGroupExternalHandle": "mikogo",
    "scalableGroupType": "APPLICATION",
    "securityGroupTag": 0,
    "state": "ACTIVE",
    "vnAgnostic": true,
    "identitySource": {
      "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
      "instanceId": 10010,
      "authEntityId": 10010,
      "displayName": "10010",
      "authEntityClass": 1738953278,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "state": "INACTIVE",
      "type": "NBAR"
    },
    "indicativeNetworkIdentity": [

    ],
    "networkApplications": [
      {
        "id": "1055994c-4a6a-496e-9195-1371abf145fd",
        "instanceId": 11698,
        "authEntityId": 11698,
        "displayName": "11698",
        "authEntityClass": -217092956,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "appProtocol": "tcp/udp",
        "applicationGroup": "other",
        "applicationType": "DEFAULT",
        "categoryId": "7e19fdea-6222-4c57-9e66-66c2340d6bc3",
        "createTime": 1517966743415,
        "encrypted": "true",
        "engineId": "13",
        "helpString": "Mikogo is a remote desktop sharing software",
        "ignoreConflict": false,
        "lastUpdateTime": 1517966743415,
        "longDescription": "mikogo is an online meeting and desktop sharing software that provides web conferencing between users, in addtion to file transfer, whiteboard, instant chat and other facilities.",
        "name": "mikogo",
        "nbarId": "1450",
        "p2pTechnology": "false",
        "popularity": 6,
        "rank": 65535,
        "references": "http://www.mikogo.com/",
        "selectorId": "514",
        "subCategory": "ff664205-64f3-447e-acff-1ec1ba9a6c31",
        "trafficClass": "MULTIMEDIA_STREAMING",
        "tunnel": "false"
      }
    ],
    "networkIdentity": [
      {
        "id": "18cac143-e6d0-4b8c-83c3-0ddb1fd5633d",
        "instanceId": 13908,
        "authEntityId": 13908,
        "displayName": "13908",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "53,80,443,5353",
        "protocol": "TCP",
        "upperPort": 0
      },
      {
        "id": "7bdc4c29-2ec2-4fbd-9fe6-2269d8899cb6",
        "instanceId": 13909,
        "authEntityId": 13909,
        "displayName": "13909",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "53,5353",
        "protocol": "UDP",
        "upperPort": 0
      }
    ],
    "parentScalableGroup": {
      "idRef": "4a6b37c6-7e11-40fe-a6b1-719b24813145"
    },
    "user": [

    ],
    "userGroup": [

    ]
  },
  {
    "id": "0df2a49c-feb7-432b-a730-924c23eb4cf6",
    "instanceId": 4402,
    "authEntityId": 4402,
    "displayName": "4402",
    "authEntityClass": -1909073334,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "createTime": 1517966742544,
    "deployed": false,
    "isSeeded": true,
    "isStale": false,
    "lastUpdateTime": 1517966742544,
    "name": "eigrp",
    "namespace": "scalablegroup:application",
    "provisioningState": "DEFINED",
    "qualifier": "application",
    "resourceVersion": 0,
    "targetIdList": [

    ],
    "type": "scalablegroup",
    "cfsChangeInfo": [

    ],
    "customProvisions": [

    ],
    "scalableGroupExternalHandle": "eigrp",
    "scalableGroupType": "APPLICATION",
    "securityGroupTag": 0,
    "state": "ACTIVE",
    "vnAgnostic": true,
    "identitySource": {
      "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
      "instanceId": 10010,
      "authEntityId": 10010,
      "displayName": "10010",
      "authEntityClass": 1738953278,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "state": "INACTIVE",
      "type": "NBAR"
    },
    "indicativeNetworkIdentity": [
      {
        "id": "6b94cc6f-c79e-40c0-a542-ff4389a09642",
        "instanceId": 13000,
        "authEntityId": 13000,
        "displayName": "13000",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipProtocolNumbers": "88",
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "protocol": "EIGRP",
        "upperPort": 0
      }
    ],
    "networkApplications": [
      {
        "id": "578c54bf-282b-4ece-a07d-785e261cb039",
        "instanceId": 11353,
        "authEntityId": 11353,
        "displayName": "11353",
        "authEntityClass": -217092956,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "appProtocol": "ip",
        "applicationGroup": "other",
        "applicationType": "DEFAULT",
        "categoryId": "1fc17362-9334-4c7b-b752-5afdbb574017",
        "createTime": 1517966742544,
        "encrypted": "false",
        "engineId": "1",
        "helpString": "Enhanced Interior Gateway Routing Protocol",
        "ignoreConflict": false,
        "lastUpdateTime": 1517966742544,
        "longDescription": "Enhanced Interior Gateway Routing Protocol (EIGRP) is an interior gateway protocol. It is an advanced distance-vector routing protocol, with optimizations to minimize both the routing instability incurred after topology changes, as well as the use of bandwidth and processing power in the router. The protocol is usually known as  IP protocol 88 as default.",
        "name": "eigrp",
        "nbarId": "7",
        "p2pTechnology": "false",
        "popularity": 5,
        "rank": 65535,
        "references": "http://www.cisco.com/en/US/tech/tk365/technologies_white_paper09186a0080094cb7.shtml",
        "selectorId": "88",
        "subCategory": "7cc1b3a6-4102-44e9-b522-0e71afe39a03",
        "trafficClass": "NETWORK_CONTROL",
        "tunnel": "false"
      }
    ],
    "networkIdentity": [
      {
        "id": "c95c4c1c-ae63-42a0-afc0-1e630a1e33ed",
        "instanceId": 12999,
        "authEntityId": 12999,
        "displayName": "12999",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipProtocolNumbers": "88",
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "protocol": "EIGRP",
        "upperPort": 0
      }
    ],
    "parentScalableGroup": {
      "idRef": "77e75842-e98b-4112-ad0c-300db4bb11be"
    },
    "user": [

    ],
    "userGroup": [

    ]
  },
  {
    "id": "0df7a1dd-43e7-4f11-82b3-4ef6d032df60",
    "instanceId": 4924,
    "authEntityId": 4924,
    "displayName": "4924",
    "authEntityClass": -1909073334,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "createTime": 1517966743777,
    "deployed": false,
    "isSeeded": true,
    "isStale": false,
    "lastUpdateTime": 1517966743777,
    "name": "orbix-config",
    "namespace": "scalablegroup:application",
    "provisioningState": "DEFINED",
    "qualifier": "application",
    "resourceVersion": 0,
    "targetIdList": [

    ],
    "type": "scalablegroup",
    "cfsChangeInfo": [

    ],
    "customProvisions": [

    ],
    "scalableGroupExternalHandle": "orbix-config",
    "scalableGroupType": "APPLICATION",
    "securityGroupTag": 0,
    "state": "ACTIVE",
    "vnAgnostic": true,
    "identitySource": {
      "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
      "instanceId": 10010,
      "authEntityId": 10010,
      "displayName": "10010",
      "authEntityClass": 1738953278,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "state": "INACTIVE",
      "type": "NBAR"
    },
    "indicativeNetworkIdentity": [
      {
        "id": "a3736b91-7dc2-4c9c-a165-f238d60dd8f2",
        "instanceId": 14439,
        "authEntityId": 14439,
        "displayName": "14439",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "3076",
        "protocol": "TCP",
        "upperPort": 0
      },
      {
        "id": "7021435c-17a0-4572-82a9-e6d5e14ac8a6",
        "instanceId": 14440,
        "authEntityId": 14440,
        "displayName": "14440",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "3076",
        "protocol": "UDP",
        "upperPort": 0
      }
    ],
    "networkApplications": [
      {
        "id": "368eda86-60a8-4451-a2d3-e717865b9d33",
        "instanceId": 11873,
        "authEntityId": 11873,
        "displayName": "11873",
        "authEntityClass": -217092956,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "appProtocol": "tcp/udp",
        "applicationGroup": "corba-group",
        "applicationType": "DEFAULT",
        "categoryId": "58aa61dc-dd95-4a6d-9c7c-0ebf16da6dde",
        "createTime": 1517966743777,
        "encrypted": "false",
        "engineId": "3",
        "helpString": "Orbix 2000 Config",
        "ignoreConflict": false,
        "lastUpdateTime": 1517966743777,
        "longDescription": "Registered with IANA on port 3076 TCP/UDP",
        "name": "orbix-config",
        "nbarId": "705",
        "p2pTechnology": "false",
        "popularity": 1,
        "rank": 65535,
        "references": "http://www.iana.org/assignments/service-names-port-numbers/service-names-port-numbers.xml",
        "selectorId": "3076",
        "subCategory": "b90786f4-0190-45b8-9924-21b3e9682590",
        "trafficClass": "TRANSACTIONAL_DATA",
        "tunnel": "false"
      }
    ],
    "networkIdentity": [
      {
        "id": "97c24cce-90fc-4aea-938d-25a9daa80afc",
        "instanceId": 14437,
        "authEntityId": 14437,
        "displayName": "14437",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "3076",
        "protocol": "UDP",
        "upperPort": 0
      },
      {
        "id": "a92cd7ba-fe73-4e34-954f-b7ab297c0824",
        "instanceId": 14438,
        "authEntityId": 14438,
        "displayName": "14438",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "3076",
        "protocol": "TCP",
        "upperPort": 0
      }
    ],
    "parentScalableGroup": {
      "idRef": "cff11752-fafb-4e22-9e6f-11f44f59a9a3"
    },
    "user": [

    ],
    "userGroup": [

    ]
  },
  {
    "id": "0e50517c-3399-4def-8053-1bdbd4eeb3fb",
    "instanceId": 4743,
    "authEntityId": 4743,
    "displayName": "4743",
    "authEntityClass": -1909073334,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "createTime": 1517966743405,
    "deployed": false,
    "isSeeded": true,
    "isStale": false,
    "lastUpdateTime": 1517966743405,
    "name": "mgcp",
    "namespace": "scalablegroup:application",
    "provisioningState": "DEFINED",
    "qualifier": "application",
    "resourceVersion": 0,
    "targetIdList": [

    ],
    "type": "scalablegroup",
    "cfsChangeInfo": [

    ],
    "customProvisions": [

    ],
    "scalableGroupExternalHandle": "mgcp",
    "scalableGroupType": "APPLICATION",
    "securityGroupTag": 0,
    "state": "ACTIVE",
    "vnAgnostic": true,
    "identitySource": {
      "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
      "instanceId": 10010,
      "authEntityId": 10010,
      "displayName": "10010",
      "authEntityClass": 1738953278,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "state": "INACTIVE",
      "type": "NBAR"
    },
    "indicativeNetworkIdentity": [
      {
        "id": "32052326-75be-4bea-a2a3-781eaafea3f3",
        "instanceId": 13892,
        "authEntityId": 13892,
        "displayName": "13892",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "2427,2727",
        "protocol": "UDP",
        "upperPort": 0
      },
      {
        "id": "a0e33dea-5086-4bdb-95ac-65fc3e281bba",
        "instanceId": 13893,
        "authEntityId": 13893,
        "displayName": "13893",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "2427,2428,2727",
        "protocol": "TCP",
        "upperPort": 0
      }
    ],
    "networkApplications": [
      {
        "id": "c6b43691-febc-402c-b5c4-e32b237d2a9f",
        "instanceId": 11693,
        "authEntityId": 11693,
        "displayName": "11693",
        "authEntityClass": -217092956,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "appProtocol": "tcp/udp",
        "applicationGroup": "other",
        "applicationType": "DEFAULT",
        "categoryId": "38a8e354-e2fe-4f3c-b1c5-2bbb90fb0d99",
        "createTime": 1517966743405,
        "encrypted": "false",
        "engineId": "13",
        "helpString": "Media Gateway Control Protocol",
        "ignoreConflict": false,
        "lastUpdateTime": 1517966743405,
        "longDescription": "Media Gateway Control Protocol (MGCP) 1.0 is a protocol for the control of Voice over IP (VoIP) calls by external call-control elements known as Media Gateway Controllers (MGCs) or Call Agents (CAs).",
        "name": "mgcp",
        "nbarId": "62",
        "p2pTechnology": "false",
        "popularity": 7,
        "rank": 65535,
        "references": "http://www.packetizer.com/rfc/rfc3435/",
        "selectorId": "62",
        "subCategory": "68c4f989-5735-4e16-8d1c-6a9d89d5df9c",
        "trafficClass": "SIGNALING",
        "tunnel": "false"
      }
    ],
    "networkIdentity": [
      {
        "id": "57af2642-33f8-4ea4-b3b7-c4c46002a227",
        "instanceId": 13890,
        "authEntityId": 13890,
        "displayName": "13890",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "2427,2428,2727",
        "protocol": "TCP",
        "upperPort": 0
      },
      {
        "id": "e364daa1-147c-4906-afc7-2b2069d3b048",
        "instanceId": 13891,
        "authEntityId": 13891,
        "displayName": "13891",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "2427,2727",
        "protocol": "UDP",
        "upperPort": 0
      }
    ],
    "parentScalableGroup": {
      "idRef": "a3243a74-3864-4041-a927-0201030da17c"
    },
    "user": [

    ],
    "userGroup": [

    ]
  },
  {
    "id": "0eba52bd-78e1-4be6-b539-cc571330febe",
    "instanceId": 4551,
    "authEntityId": 4551,
    "displayName": "4551",
    "authEntityClass": -1909073334,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "createTime": 1517966742967,
    "deployed": false,
    "isSeeded": true,
    "isStale": false,
    "lastUpdateTime": 1517966742967,
    "name": "hotmail",
    "namespace": "scalablegroup:application",
    "provisioningState": "DEFINED",
    "qualifier": "application",
    "resourceVersion": 0,
    "targetIdList": [

    ],
    "type": "scalablegroup",
    "cfsChangeInfo": [

    ],
    "customProvisions": [

    ],
    "scalableGroupExternalHandle": "hotmail",
    "scalableGroupType": "APPLICATION",
    "securityGroupTag": 0,
    "state": "ACTIVE",
    "vnAgnostic": true,
    "identitySource": {
      "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
      "instanceId": 10010,
      "authEntityId": 10010,
      "displayName": "10010",
      "authEntityClass": 1738953278,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "state": "INACTIVE",
      "type": "NBAR"
    },
    "indicativeNetworkIdentity": [

    ],
    "networkApplications": [
      {
        "id": "908fe2ae-2a24-409e-b51c-62b732e8cb04",
        "instanceId": 11502,
        "authEntityId": 11502,
        "displayName": "11502",
        "authEntityClass": -217092956,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "applicationGroup": "other",
        "applicationType": "DEFAULT",
        "categoryId": "6daa3dfd-3526-407a-88f0-97824b3f3a24",
        "createTime": 1517966742967,
        "encrypted": "unassigned",
        "engineId": "13",
        "helpString": "DEPRECATED, Please refer to outlook-web-service",
        "ignoreConflict": false,
        "lastUpdateTime": 1517966742967,
        "longDescription": "Hotmail is a well-known email service provider, it is also known as Microsoft Hotmail and Live Hotmail; it provides email services (send, receive, file attachments, ... etc.) and Hotmail Calendar service as well. Hotmail service is replaced with Outlook.com .",
        "name": "hotmail",
        "nbarId": "1446",
        "p2pTechnology": "unassigned",
        "popularity": 0,
        "rank": 65535,
        "references": "http://www.hotmail.com",
        "selectorId": "511",
        "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
        "trafficClass": "BULK_DATA",
        "tunnel": "unassigned"
      }
    ],
    "networkIdentity": [

    ],
    "parentScalableGroup": {
      "idRef": "28e08de4-d7b2-4521-84ce-bc1e5ee0b9cc"
    },
    "user": [

    ],
    "userGroup": [

    ]
  },
  {
    "id": "0ef81cc3-26de-4534-8409-0a8abff2e2bb",
    "instanceId": 15403,
    "authEntityId": 15403,
    "displayName": "15403",
    "authEntityClass": -1909073334,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "createTime": 1517966744881,
    "deployed": false,
    "isSeeded": true,
    "isStale": false,
    "lastUpdateTime": 1517966744881,
    "name": "webmd",
    "namespace": "scalablegroup:application",
    "provisioningState": "DEFINED",
    "qualifier": "application",
    "resourceVersion": 0,
    "targetIdList": [

    ],
    "type": "scalablegroup",
    "cfsChangeInfo": [

    ],
    "customProvisions": [

    ],
    "scalableGroupExternalHandle": "webmd",
    "scalableGroupType": "APPLICATION",
    "securityGroupTag": 0,
    "state": "ACTIVE",
    "vnAgnostic": true,
    "identitySource": {
      "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
      "instanceId": 10010,
      "authEntityId": 10010,
      "displayName": "10010",
      "authEntityClass": 1738953278,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "state": "INACTIVE",
      "type": "NBAR"
    },
    "indicativeNetworkIdentity": [

    ],
    "networkApplications": [
      {
        "id": "5f006dd9-a55a-4166-949f-61d7d4e0211f",
        "instanceId": 16346,
        "authEntityId": 16346,
        "displayName": "16346",
        "authEntityClass": -217092956,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "appProtocol": "tcp/udp",
        "applicationGroup": "other",
        "applicationType": "DEFAULT",
        "categoryId": "878644b5-fe91-45b4-9204-31c84a73ba48",
        "createTime": 1517966744881,
        "encrypted": "false",
        "engineId": "13",
        "helpString": "WebMD - Health information service.",
        "ignoreConflict": false,
        "lastUpdateTime": 1517966744881,
        "longDescription": "WebMD - Health information service.",
        "name": "webmd",
        "nbarId": "1607",
        "p2pTechnology": "false",
        "popularity": 3,
        "rank": 65535,
        "references": "http://webmd.com",
        "selectorId": "677",
        "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
        "trafficClass": "TRANSACTIONAL_DATA",
        "tunnel": "false"
      }
    ],
    "networkIdentity": [
      {
        "id": "6ebf3040-2ce3-4e1e-baf6-af7ea002fc8a",
        "instanceId": 17814,
        "authEntityId": 17814,
        "displayName": "17814",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "53,5353",
        "protocol": "UDP",
        "upperPort": 0
      },
      {
        "id": "c3fbf3be-1d1b-420b-8772-e05a4df2adf7",
        "instanceId": 17815,
        "authEntityId": 17815,
        "displayName": "17815",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "53,80,443,5353",
        "protocol": "TCP",
        "upperPort": 0
      }
    ],
    "parentScalableGroup": {
      "idRef": "28e08de4-d7b2-4521-84ce-bc1e5ee0b9cc"
    },
    "user": [

    ],
    "userGroup": [

    ]
  },
  {
    "id": "0efd3897-014a-4047-8c3d-b5ec32b875f1",
    "instanceId": 15070,
    "authEntityId": 15070,
    "displayName": "15070",
    "authEntityClass": -1909073334,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "createTime": 1517966744165,
    "deployed": false,
    "isSeeded": true,
    "isStale": false,
    "lastUpdateTime": 1517966744165,
    "name": "rsync",
    "namespace": "scalablegroup:application",
    "provisioningState": "DEFINED",
    "qualifier": "application",
    "resourceVersion": 0,
    "targetIdList": [

    ],
    "type": "scalablegroup",
    "cfsChangeInfo": [

    ],
    "customProvisions": [

    ],
    "scalableGroupExternalHandle": "rsync",
    "scalableGroupType": "APPLICATION",
    "securityGroupTag": 0,
    "state": "ACTIVE",
    "vnAgnostic": true,
    "identitySource": {
      "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
      "instanceId": 10010,
      "authEntityId": 10010,
      "displayName": "10010",
      "authEntityClass": 1738953278,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "state": "INACTIVE",
      "type": "NBAR"
    },
    "indicativeNetworkIdentity": [
      {
        "id": "3792daad-4fc0-446a-8737-dcf683e444a8",
        "instanceId": 14855,
        "authEntityId": 14855,
        "displayName": "14855",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "873",
        "protocol": "TCP",
        "upperPort": 0
      },
      {
        "id": "a9f57281-764e-4104-ad32-d5444616b686",
        "instanceId": 14856,
        "authEntityId": 14856,
        "displayName": "14856",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "873",
        "protocol": "UDP",
        "upperPort": 0
      }
    ],
    "networkApplications": [
      {
        "id": "bd46388b-bdaf-4878-bc6e-a90a190c12b9",
        "instanceId": 12009,
        "authEntityId": 12009,
        "displayName": "12009",
        "authEntityClass": -217092956,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "appProtocol": "tcp/udp",
        "applicationGroup": "other",
        "applicationType": "DEFAULT",
        "categoryId": "80909d4c-efe6-4878-9e1e-68281fd26edd",
        "createTime": 1517966744165,
        "encrypted": "false",
        "engineId": "3",
        "helpString": "rsync",
        "ignoreConflict": false,
        "lastUpdateTime": 1517966744165,
        "longDescription": "rsync is a software application and network protocol for Unix-like and Windows systems that synchronizes files and directories from one location to another while minimizing data transfer using delta encoding when appropriate.",
        "name": "rsync",
        "nbarId": "659",
        "p2pTechnology": "false",
        "popularity": 6,
        "rank": 65535,
        "references": "http://rsync.samba.org/",
        "selectorId": "873",
        "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
        "trafficClass": "BULK_DATA",
        "tunnel": "false"
      }
    ],
    "networkIdentity": [
      {
        "id": "063b8202-9efd-472e-ab71-3af3c385959a",
        "instanceId": 14853,
        "authEntityId": 14853,
        "displayName": "14853",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "873",
        "protocol": "UDP",
        "upperPort": 0
      },
      {
        "id": "1a82911b-bd53-4217-a067-095dd8f0901d",
        "instanceId": 14854,
        "authEntityId": 14854,
        "displayName": "14854",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "873",
        "protocol": "TCP",
        "upperPort": 0
      }
    ],
    "parentScalableGroup": {
      "idRef": "f09a36de-df38-4ff8-b0be-56738afc75f7"
    },
    "user": [

    ],
    "userGroup": [

    ]
  },
  {
    "id": "0f1057de-830f-438a-8b91-1480aae3cbdd",
    "instanceId": 15340,
    "authEntityId": 15340,
    "displayName": "15340",
    "authEntityClass": -1909073334,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "createTime": 1517966744768,
    "deployed": false,
    "isSeeded": true,
    "isStale": false,
    "lastUpdateTime": 1517966744768,
    "name": "uuidgen",
    "namespace": "scalablegroup:application",
    "provisioningState": "DEFINED",
    "qualifier": "application",
    "resourceVersion": 0,
    "targetIdList": [

    ],
    "type": "scalablegroup",
    "cfsChangeInfo": [

    ],
    "customProvisions": [

    ],
    "scalableGroupExternalHandle": "uuidgen",
    "scalableGroupType": "APPLICATION",
    "securityGroupTag": 0,
    "state": "ACTIVE",
    "vnAgnostic": true,
    "identitySource": {
      "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
      "instanceId": 10010,
      "authEntityId": 10010,
      "displayName": "10010",
      "authEntityClass": 1738953278,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "state": "INACTIVE",
      "type": "NBAR"
    },
    "indicativeNetworkIdentity": [
      {
        "id": "f38f062e-9b8b-4a83-b819-c2e173b765bf",
        "instanceId": 17641,
        "authEntityId": 17641,
        "displayName": "17641",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "697",
        "protocol": "TCP",
        "upperPort": 0
      },
      {
        "id": "82be84f6-6800-43df-87a5-65f19fdee504",
        "instanceId": 17642,
        "authEntityId": 17642,
        "displayName": "17642",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "697",
        "protocol": "UDP",
        "upperPort": 0
      }
    ],
    "networkApplications": [
      {
        "id": "bec49481-f93d-4f0d-8cd2-841fadab0bc0",
        "instanceId": 16283,
        "authEntityId": 16283,
        "displayName": "16283",
        "authEntityClass": -217092956,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "appProtocol": "tcp/udp",
        "applicationGroup": "other",
        "applicationType": "DEFAULT",
        "categoryId": "6daa3dfd-3526-407a-88f0-97824b3f3a24",
        "createTime": 1517966744768,
        "encrypted": "false",
        "engineId": "3",
        "helpString": "UUIDGEN",
        "ignoreConflict": false,
        "lastUpdateTime": 1517966744768,
        "longDescription": "A universally unique identifier (UUID) is an identifier standard used in software construction, standardized by the Open Software Foundation (OSF) as part of the Distributed Computing Environment (DCE). UUIDGEN is a program that generates a unique UUID for each system.",
        "name": "uuidgen",
        "nbarId": "605",
        "p2pTechnology": "false",
        "popularity": 1,
        "rank": 65535,
        "references": "http://en.wikipedia.org/wiki/Uuid",
        "selectorId": "697",
        "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
        "trafficClass": "BULK_DATA",
        "tunnel": "false"
      }
    ],
    "networkIdentity": [
      {
        "id": "05c200ef-6ee8-45ca-8f1d-02cb0b0368ed",
        "instanceId": 17639,
        "authEntityId": 17639,
        "displayName": "17639",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "697",
        "protocol": "UDP",
        "upperPort": 0
      },
      {
        "id": "18b5211e-056c-467a-8909-cb6ad9607723",
        "instanceId": 17640,
        "authEntityId": 17640,
        "displayName": "17640",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "697",
        "protocol": "TCP",
        "upperPort": 0
      }
    ],
    "parentScalableGroup": {
      "idRef": "000c15bf-28fe-4fbb-9b69-35e6502721f1"
    },
    "user": [

    ],
    "userGroup": [

    ]
  },
  {
    "id": "0f19f995-1fd3-4a27-a86f-973841ad5e42",
    "instanceId": 4882,
    "authEntityId": 4882,
    "displayName": "4882",
    "authEntityClass": -1909073334,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "createTime": 1517966743674,
    "deployed": false,
    "isSeeded": true,
    "isStale": false,
    "lastUpdateTime": 1517966743674,
    "name": "ns",
    "namespace": "scalablegroup:application",
    "provisioningState": "DEFINED",
    "qualifier": "application",
    "resourceVersion": 0,
    "targetIdList": [

    ],
    "type": "scalablegroup",
    "cfsChangeInfo": [

    ],
    "customProvisions": [

    ],
    "scalableGroupExternalHandle": "ns",
    "scalableGroupType": "APPLICATION",
    "securityGroupTag": 0,
    "state": "ACTIVE",
    "vnAgnostic": true,
    "identitySource": {
      "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
      "instanceId": 10010,
      "authEntityId": 10010,
      "displayName": "10010",
      "authEntityClass": 1738953278,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "state": "INACTIVE",
      "type": "NBAR"
    },
    "indicativeNetworkIdentity": [
      {
        "id": "34f33761-0a27-4df8-8e21-5cbd89b758d5",
        "instanceId": 14294,
        "authEntityId": 14294,
        "displayName": "14294",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "760",
        "protocol": "UDP",
        "upperPort": 0
      },
      {
        "id": "b0b80cb5-5600-4660-b385-df0de670f8f2",
        "instanceId": 14295,
        "authEntityId": 14295,
        "displayName": "14295",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "760",
        "protocol": "TCP",
        "upperPort": 0
      }
    ],
    "networkApplications": [
      {
        "id": "6c80529d-30f8-45ca-9849-649eff80d908",
        "instanceId": 11831,
        "authEntityId": 11831,
        "displayName": "11831",
        "authEntityClass": -217092956,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "appProtocol": "tcp/udp",
        "applicationGroup": "other",
        "applicationType": "DEFAULT",
        "categoryId": "6daa3dfd-3526-407a-88f0-97824b3f3a24",
        "createTime": 1517966743674,
        "encrypted": "false",
        "engineId": "3",
        "helpString": "ns",
        "ignoreConflict": false,
        "lastUpdateTime": 1517966743674,
        "longDescription": "The Network Systems (NS) protocol family is a collection of protocols layered atop the Internet Datagram Protocol (IDP) transport layer, using the Xerox Network Systems (XNS) address formats.",
        "name": "ns",
        "nbarId": "632",
        "p2pTechnology": "false",
        "popularity": 1,
        "rank": 65535,
        "references": "http://www.regatta.cs.msu.su/doc/usr/share/man/info/ru_RU/a_doc_lib/aixprggd/progcomc/xns_fam.htm",
        "selectorId": "760",
        "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
        "trafficClass": "BULK_DATA",
        "tunnel": "false"
      }
    ],
    "networkIdentity": [
      {
        "id": "3000bc96-4e1c-401b-aaf3-369782673a89",
        "instanceId": 14292,
        "authEntityId": 14292,
        "displayName": "14292",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "760",
        "protocol": "UDP",
        "upperPort": 0
      },
      {
        "id": "55b7f97b-650a-4594-8510-9fbd038b6441",
        "instanceId": 14293,
        "authEntityId": 14293,
        "displayName": "14293",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "760",
        "protocol": "TCP",
        "upperPort": 0
      }
    ],
    "parentScalableGroup": {
      "idRef": "000c15bf-28fe-4fbb-9b69-35e6502721f1"
    },
    "user": [

    ],
    "userGroup": [

    ]
  },
  {
    "id": "0f1b2abd-808e-4af4-a883-77cb4f41a9dd",
    "instanceId": 15334,
    "authEntityId": 15334,
    "displayName": "15334",
    "authEntityClass": -1909073334,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "createTime": 1517966744757,
    "deployed": false,
    "isSeeded": true,
    "isStale": false,
    "lastUpdateTime": 1517966744757,
    "name": "utime",
    "namespace": "scalablegroup:application",
    "provisioningState": "DEFINED",
    "qualifier": "application",
    "resourceVersion": 0,
    "targetIdList": [

    ],
    "type": "scalablegroup",
    "cfsChangeInfo": [

    ],
    "customProvisions": [

    ],
    "scalableGroupExternalHandle": "utime",
    "scalableGroupType": "APPLICATION",
    "securityGroupTag": 0,
    "state": "ACTIVE",
    "vnAgnostic": true,
    "identitySource": {
      "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
      "instanceId": 10010,
      "authEntityId": 10010,
      "displayName": "10010",
      "authEntityClass": 1738953278,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "state": "INACTIVE",
      "type": "NBAR"
    },
    "indicativeNetworkIdentity": [
      {
        "id": "804b6f09-8aa3-47ba-8a3e-0b38c0cb657d",
        "instanceId": 17617,
        "authEntityId": 17617,
        "displayName": "17617",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "519",
        "protocol": "UDP",
        "upperPort": 0
      },
      {
        "id": "6df8573e-81e0-42a4-b3e4-2d3d386cae47",
        "instanceId": 17618,
        "authEntityId": 17618,
        "displayName": "17618",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "519",
        "protocol": "TCP",
        "upperPort": 0
      }
    ],
    "networkApplications": [
      {
        "id": "3561bec0-cbc2-49df-a921-ae570c689664",
        "instanceId": 16277,
        "authEntityId": 16277,
        "displayName": "16277",
        "authEntityClass": -217092956,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "appProtocol": "tcp/udp",
        "applicationGroup": "other",
        "applicationType": "DEFAULT",
        "categoryId": "1fc17362-9334-4c7b-b752-5afdbb574017",
        "createTime": 1517966744757,
        "encrypted": "false",
        "engineId": "3",
        "helpString": "unixtime",
        "ignoreConflict": false,
        "lastUpdateTime": 1517966744757,
        "longDescription": "utime is a Linux system call that changes the access and modification times of the inode specified by filename to the actime and modtime fields of times respectively.",
        "name": "utime",
        "nbarId": "436",
        "p2pTechnology": "false",
        "popularity": 1,
        "rank": 65535,
        "references": "http://linux.die.net/man/2/utime",
        "selectorId": "519",
        "subCategory": "f3c0f761-d894-4112-ab89-b90fc84fd660",
        "trafficClass": "OPS_ADMIN_MGMT",
        "tunnel": "false"
      }
    ],
    "networkIdentity": [
      {
        "id": "8bb73ef7-4f46-40aa-b5e9-d405e543bea5",
        "instanceId": 17616,
        "authEntityId": 17616,
        "displayName": "17616",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "519",
        "protocol": "TCP",
        "upperPort": 0
      },
      {
        "id": "98bac516-ce36-4e93-89d3-13200916888c",
        "instanceId": 17615,
        "authEntityId": 17615,
        "displayName": "17615",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "519",
        "protocol": "UDP",
        "upperPort": 0
      }
    ],
    "parentScalableGroup": {
      "idRef": "5fcd74d4-8726-47f7-a088-c457863da7ef"
    },
    "user": [

    ],
    "userGroup": [

    ]
  },
  {
    "id": "0f3a4e6c-f33b-4f2d-bfa4-b25ad2a43cf8",
    "instanceId": 4148,
    "authEntityId": 4148,
    "displayName": "4148",
    "authEntityClass": -1909073334,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "createTime": 1517966741383,
    "deployed": false,
    "isSeeded": true,
    "isStale": false,
    "lastUpdateTime": 1517966741383,
    "name": "avast-antivirus",
    "namespace": "scalablegroup:application",
    "provisioningState": "DEFINED",
    "qualifier": "application",
    "resourceVersion": 0,
    "targetIdList": [

    ],
    "type": "scalablegroup",
    "cfsChangeInfo": [

    ],
    "customProvisions": [

    ],
    "scalableGroupExternalHandle": "avast-antivirus",
    "scalableGroupType": "APPLICATION",
    "securityGroupTag": 0,
    "state": "ACTIVE",
    "vnAgnostic": true,
    "identitySource": {
      "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
      "instanceId": 10010,
      "authEntityId": 10010,
      "displayName": "10010",
      "authEntityClass": 1738953278,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "state": "INACTIVE",
      "type": "NBAR"
    },
    "indicativeNetworkIdentity": [

    ],
    "networkApplications": [
      {
        "id": "98d42cd0-1bd7-44b0-b0f5-ec89caebc618",
        "instanceId": 11109,
        "authEntityId": 11109,
        "displayName": "11109",
        "authEntityClass": -217092956,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "appProtocol": "tcp/udp",
        "applicationGroup": "other",
        "applicationType": "DEFAULT",
        "categoryId": "7e19fdea-6222-4c57-9e66-66c2340d6bc3",
        "createTime": 1517966741383,
        "encrypted": "unassigned",
        "engineId": "13",
        "helpString": "Antivirus for PC and android",
        "ignoreConflict": false,
        "lastUpdateTime": 1517966741383,
        "longDescription": "Avast Antivirus and security",
        "name": "avast-antivirus",
        "nbarId": "1017",
        "p2pTechnology": "false",
        "popularity": 10,
        "rank": 65535,
        "references": "www.avast.com",
        "selectorId": "1017",
        "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
        "trafficClass": "BULK_DATA",
        "tunnel": "false"
      }
    ],
    "networkIdentity": [
      {
        "id": "11c503d9-e26e-4eb8-a949-d1b2e49db8c5",
        "instanceId": 12302,
        "authEntityId": 12302,
        "displayName": "12302",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "53,5353",
        "protocol": "UDP",
        "upperPort": 0
      },
      {
        "id": "753da1c4-679b-4069-a307-240f80e922a5",
        "instanceId": 12303,
        "authEntityId": 12303,
        "displayName": "12303",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "53,80,443,5353",
        "protocol": "TCP",
        "upperPort": 0
      }
    ],
    "parentScalableGroup": {
      "idRef": "4a68bc73-53c6-4deb-9983-784a0338aefe"
    },
    "user": [

    ],
    "userGroup": [

    ]
  },
  {
    "id": "0fa54a60-34de-43b5-bfe5-10b908ec169d",
    "instanceId": 15121,
    "authEntityId": 15121,
    "displayName": "15121",
    "authEntityClass": -1909073334,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "createTime": 1517966744282,
    "deployed": false,
    "isSeeded": true,
    "isStale": false,
    "lastUpdateTime": 1517966744282,
    "name": "send",
    "namespace": "scalablegroup:application",
    "provisioningState": "DEFINED",
    "qualifier": "application",
    "resourceVersion": 0,
    "targetIdList": [

    ],
    "type": "scalablegroup",
    "cfsChangeInfo": [

    ],
    "customProvisions": [

    ],
    "scalableGroupExternalHandle": "send",
    "scalableGroupType": "APPLICATION",
    "securityGroupTag": 0,
    "state": "ACTIVE",
    "vnAgnostic": true,
    "identitySource": {
      "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
      "instanceId": 10010,
      "authEntityId": 10010,
      "displayName": "10010",
      "authEntityClass": 1738953278,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "state": "INACTIVE",
      "type": "NBAR"
    },
    "indicativeNetworkIdentity": [
      {
        "id": "2e63f217-a788-49ff-a451-f90c5d5b5918",
        "instanceId": 15004,
        "authEntityId": 15004,
        "displayName": "15004",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "169",
        "protocol": "UDP",
        "upperPort": 0
      },
      {
        "id": "387698de-a52c-4e5f-8eda-823d3b4aede3",
        "instanceId": 15005,
        "authEntityId": 15005,
        "displayName": "15005",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "169",
        "protocol": "TCP",
        "upperPort": 0
      }
    ],
    "networkApplications": [
      {
        "id": "023c18e2-34c9-4fdb-9894-9099c74c3b35",
        "instanceId": 16064,
        "authEntityId": 16064,
        "displayName": "16064",
        "authEntityClass": -217092956,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "appProtocol": "tcp/udp",
        "applicationGroup": "other",
        "applicationType": "DEFAULT",
        "categoryId": "6daa3dfd-3526-407a-88f0-97824b3f3a24",
        "createTime": 1517966744282,
        "encrypted": "false",
        "engineId": "3",
        "helpString": "SEND",
        "ignoreConflict": false,
        "lastUpdateTime": 1517966744282,
        "longDescription": "The SEcure Neighbor Discovery (SEND) protocol is a security extension of the Neighbor Discovery Protocol (NDP) in IPv6. The Neighbor Discovery Protocol (NDP) is responsible in IPv6 for discovery of other network nodes on the local link, to determine the link layer addresses of other nodes, and to find available routers, and maintain reachability information about the paths to other active neighbor nodes.",
        "name": "send",
        "nbarId": "1014",
        "p2pTechnology": "false",
        "popularity": 1,
        "rank": 65535,
        "references": "http://www.ietf.org/rfc/rfc3971.txt",
        "selectorId": "169",
        "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
        "trafficClass": "BULK_DATA",
        "tunnel": "false"
      }
    ],
    "networkIdentity": [
      {
        "id": "ac923a11-2a35-46d1-b6bc-eaf228cb0b32",
        "instanceId": 15002,
        "authEntityId": 15002,
        "displayName": "15002",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "169",
        "protocol": "UDP",
        "upperPort": 0
      },
      {
        "id": "5e54ba0d-b0f6-4ebb-9ad5-c43b40f1e079",
        "instanceId": 15003,
        "authEntityId": 15003,
        "displayName": "15003",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "169",
        "protocol": "TCP",
        "upperPort": 0
      }
    ],
    "parentScalableGroup": {
      "idRef": "000c15bf-28fe-4fbb-9b69-35e6502721f1"
    },
    "user": [

    ],
    "userGroup": [

    ]
  },
  {
    "id": "0fa62e53-0f14-4ad9-a8e0-35d92c6256ff",
    "instanceId": 4311,
    "authEntityId": 4311,
    "displayName": "4311",
    "authEntityClass": -1909073334,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "createTime": 1517966742239,
    "deployed": false,
    "isSeeded": true,
    "isStale": false,
    "lastUpdateTime": 1517966742239,
    "name": "ctf",
    "namespace": "scalablegroup:application",
    "provisioningState": "DEFINED",
    "qualifier": "application",
    "resourceVersion": 0,
    "targetIdList": [

    ],
    "type": "scalablegroup",
    "cfsChangeInfo": [

    ],
    "customProvisions": [

    ],
    "scalableGroupExternalHandle": "ctf",
    "scalableGroupType": "APPLICATION",
    "securityGroupTag": 0,
    "state": "ACTIVE",
    "vnAgnostic": true,
    "identitySource": {
      "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
      "instanceId": 10010,
      "authEntityId": 10010,
      "displayName": "10010",
      "authEntityClass": 1738953278,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "state": "INACTIVE",
      "type": "NBAR"
    },
    "indicativeNetworkIdentity": [
      {
        "id": "e1764162-8940-408a-87fd-da9ac481821c",
        "instanceId": 12704,
        "authEntityId": 12704,
        "displayName": "12704",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "84",
        "protocol": "UDP",
        "upperPort": 0
      },
      {
        "id": "73785e0f-1e8c-4a73-a607-540cba3bf5f6",
        "instanceId": 12705,
        "authEntityId": 12705,
        "displayName": "12705",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "84",
        "protocol": "TCP",
        "upperPort": 0
      }
    ],
    "networkApplications": [
      {
        "id": "a6ef1e48-013b-4844-a307-93f69332c158",
        "instanceId": 11263,
        "authEntityId": 11263,
        "displayName": "11263",
        "authEntityClass": -217092956,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "appProtocol": "tcp/udp",
        "applicationGroup": "other",
        "applicationType": "DEFAULT",
        "categoryId": "1fc17362-9334-4c7b-b752-5afdbb574017",
        "createTime": 1517966742239,
        "encrypted": "false",
        "engineId": "3",
        "helpString": "Common Trace Facility",
        "ignoreConflict": false,
        "lastUpdateTime": 1517966742239,
        "longDescription": "Registered with IANA on port 84 TCP/UDP",
        "name": "ctf",
        "nbarId": "957",
        "p2pTechnology": "false",
        "popularity": 1,
        "rank": 65535,
        "references": "http://www.iana.org/assignments/service-names-port-numbers/service-names-port-numbers.xml",
        "selectorId": "84",
        "subCategory": "f3c0f761-d894-4112-ab89-b90fc84fd660",
        "trafficClass": "OPS_ADMIN_MGMT",
        "tunnel": "false"
      }
    ],
    "networkIdentity": [
      {
        "id": "0d4ccf43-815b-42ed-9fae-185f072543a4",
        "instanceId": 12702,
        "authEntityId": 12702,
        "displayName": "12702",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "84",
        "protocol": "TCP",
        "upperPort": 0
      },
      {
        "id": "7826c16d-4d0f-4e56-adc6-016f4dab4546",
        "instanceId": 12703,
        "authEntityId": 12703,
        "displayName": "12703",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "84",
        "protocol": "UDP",
        "upperPort": 0
      }
    ],
    "parentScalableGroup": {
      "idRef": "5fcd74d4-8726-47f7-a088-c457863da7ef"
    },
    "user": [

    ],
    "userGroup": [

    ]
  },
  {
    "id": "0fd89dd0-8d49-452b-acb3-a809ba0bc046",
    "instanceId": 4197,
    "authEntityId": 4197,
    "displayName": "4197",
    "authEntityClass": -1909073334,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "createTime": 1517966741655,
    "deployed": false,
    "isSeeded": true,
    "isStale": false,
    "lastUpdateTime": 1517966741655,
    "name": "bna",
    "namespace": "scalablegroup:application",
    "provisioningState": "DEFINED",
    "qualifier": "application",
    "resourceVersion": 0,
    "targetIdList": [

    ],
    "type": "scalablegroup",
    "cfsChangeInfo": [

    ],
    "customProvisions": [

    ],
    "scalableGroupExternalHandle": "bna",
    "scalableGroupType": "APPLICATION",
    "securityGroupTag": 0,
    "state": "ACTIVE",
    "vnAgnostic": true,
    "identitySource": {
      "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
      "instanceId": 10010,
      "authEntityId": 10010,
      "displayName": "10010",
      "authEntityClass": 1738953278,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "state": "INACTIVE",
      "type": "NBAR"
    },
    "indicativeNetworkIdentity": [
      {
        "id": "928955ee-7f01-4dd6-b7e3-c215265b77ad",
        "instanceId": 12422,
        "authEntityId": 12422,
        "displayName": "12422",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipProtocolNumbers": "49",
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "protocol": "BNA",
        "upperPort": 0
      }
    ],
    "networkApplications": [
      {
        "id": "5e5064bf-1a27-47a1-92d8-bd9f8a1cba6a",
        "instanceId": 11153,
        "authEntityId": 11153,
        "displayName": "11153",
        "authEntityClass": -217092956,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "appProtocol": "ip",
        "applicationGroup": "other",
        "applicationType": "DEFAULT",
        "categoryId": "6daa3dfd-3526-407a-88f0-97824b3f3a24",
        "createTime": 1517966741655,
        "encrypted": "unassigned",
        "engineId": "1",
        "helpString": "BNA",
        "ignoreConflict": false,
        "lastUpdateTime": 1517966741655,
        "longDescription": "BNA is a software architecture and associated products that connect enterprise servers, V Series information hubs, CTOS workstations, and other devices so that the resources at one can be used at another. These resources include files, programs, and processors.",
        "name": "bna",
        "nbarId": "803",
        "p2pTechnology": "unassigned",
        "popularity": 2,
        "rank": 65535,
        "references": "http://public.support.unisys.com/aseries/docs/clearpath-mcp-13.1/pdf/37897014-207.pdf",
        "selectorId": "49",
        "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
        "trafficClass": "BULK_DATA",
        "tunnel": "unassigned"
      }
    ],
    "networkIdentity": [
      {
        "id": "863611b6-a4cf-469b-add8-304b4b0f46c4",
        "instanceId": 12421,
        "authEntityId": 12421,
        "displayName": "12421",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipProtocolNumbers": "49",
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "protocol": "BNA",
        "upperPort": 0
      }
    ],
    "parentScalableGroup": {
      "idRef": "000c15bf-28fe-4fbb-9b69-35e6502721f1"
    },
    "user": [

    ],
    "userGroup": [

    ]
  },
  {
    "id": "10189579-851a-4f41-867e-976b338ae978",
    "instanceId": 15339,
    "authEntityId": 15339,
    "displayName": "15339",
    "authEntityClass": -1909073334,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "createTime": 1517966744766,
    "deployed": false,
    "isSeeded": true,
    "isStale": false,
    "lastUpdateTime": 1517966744766,
    "name": "uucp-rlogin",
    "namespace": "scalablegroup:application",
    "provisioningState": "DEFINED",
    "qualifier": "application",
    "resourceVersion": 0,
    "targetIdList": [

    ],
    "type": "scalablegroup",
    "cfsChangeInfo": [

    ],
    "customProvisions": [

    ],
    "scalableGroupExternalHandle": "uucp-rlogin",
    "scalableGroupType": "APPLICATION",
    "securityGroupTag": 0,
    "state": "ACTIVE",
    "vnAgnostic": true,
    "identitySource": {
      "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
      "instanceId": 10010,
      "authEntityId": 10010,
      "displayName": "10010",
      "authEntityClass": 1738953278,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "state": "INACTIVE",
      "type": "NBAR"
    },
    "indicativeNetworkIdentity": [
      {
        "id": "020a5924-1983-42bf-b4ed-788bb0408296",
        "instanceId": 17637,
        "authEntityId": 17637,
        "displayName": "17637",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "541",
        "protocol": "UDP",
        "upperPort": 0
      },
      {
        "id": "437142aa-df71-4fe9-9424-3a491b439811",
        "instanceId": 17638,
        "authEntityId": 17638,
        "displayName": "17638",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "541",
        "protocol": "TCP",
        "upperPort": 0
      }
    ],
    "networkApplications": [
      {
        "id": "bb0dcd88-7327-4313-9bf2-6bc959751ff1",
        "instanceId": 16282,
        "authEntityId": 16282,
        "displayName": "16282",
        "authEntityClass": -217092956,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "appProtocol": "tcp/udp",
        "applicationGroup": "other",
        "applicationType": "DEFAULT",
        "categoryId": "1fc17362-9334-4c7b-b752-5afdbb574017",
        "createTime": 1517966744766,
        "encrypted": "false",
        "engineId": "3",
        "helpString": "uucp-rlogin",
        "ignoreConflict": false,
        "lastUpdateTime": 1517966744766,
        "longDescription": "Rlogin is a part of UUCP (Unix-to-Unix Copy), a suite of computer programs and protocols allowing remote execution of commands and transfer of files, email and netnews between computers.",
        "name": "uucp-rlogin",
        "nbarId": "459",
        "p2pTechnology": "false",
        "popularity": 1,
        "rank": 65535,
        "references": "http://www.uucp.org/",
        "selectorId": "541",
        "subCategory": "787a7d77-977b-4974-89d3-379ba49e88b7",
        "trafficClass": "OPS_ADMIN_MGMT",
        "tunnel": "false"
      }
    ],
    "networkIdentity": [
      {
        "id": "83f4f92e-7f31-4855-8617-72daeb5d05bb",
        "instanceId": 17635,
        "authEntityId": 17635,
        "displayName": "17635",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "541",
        "protocol": "UDP",
        "upperPort": 0
      },
      {
        "id": "95ecfefd-86a9-4898-b08d-d57247576f31",
        "instanceId": 17636,
        "authEntityId": 17636,
        "displayName": "17636",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "541",
        "protocol": "TCP",
        "upperPort": 0
      }
    ],
    "parentScalableGroup": {
      "idRef": "e06dd89f-0a70-4422-ab0b-c2314650a9a7"
    },
    "user": [

    ],
    "userGroup": [

    ]
  },
  {
    "id": "101eb225-1a70-4078-8e93-ad52a276d301",
    "instanceId": 4454,
    "authEntityId": 4454,
    "displayName": "4454",
    "authEntityClass": -1909073334,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "createTime": 1517966742712,
    "deployed": false,
    "isSeeded": true,
    "isStale": false,
    "lastUpdateTime": 1517966742712,
    "name": "flash-video",
    "namespace": "scalablegroup:application",
    "provisioningState": "DEFINED",
    "qualifier": "application",
    "resourceVersion": 0,
    "targetIdList": [

    ],
    "type": "scalablegroup",
    "cfsChangeInfo": [

    ],
    "customProvisions": [

    ],
    "scalableGroupExternalHandle": "flash-video",
    "scalableGroupType": "APPLICATION",
    "securityGroupTag": 0,
    "state": "ACTIVE",
    "vnAgnostic": true,
    "identitySource": {
      "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
      "instanceId": 10010,
      "authEntityId": 10010,
      "displayName": "10010",
      "authEntityClass": 1738953278,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "state": "INACTIVE",
      "type": "NBAR"
    },
    "indicativeNetworkIdentity": [

    ],
    "networkApplications": [
      {
        "id": "59e1f074-1a0f-4d7a-a854-62f181001615",
        "instanceId": 11405,
        "authEntityId": 11405,
        "displayName": "11405",
        "authEntityClass": -217092956,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "appProtocol": "tcp",
        "applicationGroup": "flash-group",
        "applicationType": "DEFAULT",
        "categoryId": "878644b5-fe91-45b4-9204-31c84a73ba48",
        "createTime": 1517966742712,
        "encrypted": "false",
        "engineId": "13",
        "helpString": "Flash video streams",
        "ignoreConflict": false,
        "lastUpdateTime": 1517966742712,
        "longDescription": "Flash video is a format used to stream videos on the web; the user only needs a flash player in order to view a flash video. The format uses HTTP as its underlying protocol.",
        "name": "flash-video",
        "nbarId": "117",
        "p2pTechnology": "false",
        "popularity": 10,
        "rank": 65535,
        "references": "http://download.macromedia.com/f4v/video_file_format_spec_v10_1.pdf",
        "selectorId": "299",
        "subCategory": "fe150151-77ab-4ac6-805f-77d0d642b798",
        "trafficClass": "MULTIMEDIA_STREAMING",
        "tunnel": "false"
      }
    ],
    "networkIdentity": [
      {
        "id": "a7a95360-dae8-4abd-a6b6-de93931545d5",
        "instanceId": 13132,
        "authEntityId": 13132,
        "displayName": "13132",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "80",
        "protocol": "TCP",
        "upperPort": 0
      }
    ],
    "parentScalableGroup": {
      "idRef": "aad510ce-fe95-4dfc-ad06-e494641cc016"
    },
    "user": [

    ],
    "userGroup": [

    ]
  },
  {
    "id": "108154a8-7582-4be2-b9a6-282fc40d66cb",
    "instanceId": 4578,
    "authEntityId": 4578,
    "displayName": "4578",
    "authEntityClass": -1909073334,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "createTime": 1517966743039,
    "deployed": false,
    "isSeeded": true,
    "isStale": false,
    "lastUpdateTime": 1517966743039,
    "name": "ibprotocol",
    "namespace": "scalablegroup:application",
    "provisioningState": "DEFINED",
    "qualifier": "application",
    "resourceVersion": 0,
    "targetIdList": [

    ],
    "type": "scalablegroup",
    "cfsChangeInfo": [

    ],
    "customProvisions": [

    ],
    "scalableGroupExternalHandle": "ibprotocol",
    "scalableGroupType": "APPLICATION",
    "securityGroupTag": 0,
    "state": "ACTIVE",
    "vnAgnostic": true,
    "identitySource": {
      "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
      "instanceId": 10010,
      "authEntityId": 10010,
      "displayName": "10010",
      "authEntityClass": 1738953278,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "state": "INACTIVE",
      "type": "NBAR"
    },
    "indicativeNetworkIdentity": [
      {
        "id": "4c4aa539-2941-4f6e-a78f-d05147e7254c",
        "instanceId": 13453,
        "authEntityId": 13453,
        "displayName": "13453",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "6714",
        "protocol": "UDP",
        "upperPort": 0
      },
      {
        "id": "3efe08f3-6c26-4052-9324-be90f01562d5",
        "instanceId": 13454,
        "authEntityId": 13454,
        "displayName": "13454",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "6714",
        "protocol": "TCP",
        "upperPort": 0
      }
    ],
    "networkApplications": [
      {
        "id": "b0c3fbba-a3b6-4dba-9ae7-3f0f897d3bc7",
        "instanceId": 11528,
        "authEntityId": 11528,
        "displayName": "11528",
        "authEntityClass": -217092956,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "appProtocol": "tcp/udp",
        "applicationGroup": "other",
        "applicationType": "DEFAULT",
        "categoryId": "6daa3dfd-3526-407a-88f0-97824b3f3a24",
        "createTime": 1517966743039,
        "encrypted": "false",
        "engineId": "3",
        "helpString": "Internet Backplane Protocol",
        "ignoreConflict": false,
        "lastUpdateTime": 1517966743039,
        "longDescription": "Internet Backplane Protocol (IBP) is middleware for managing and using remote storage. It was invented to support Logistical Networking in large scale distributed systems and applications. IBP provides a mechanism for using distributed storage for logistical purposes.",
        "name": "ibprotocol",
        "nbarId": "737",
        "p2pTechnology": "false",
        "popularity": 1,
        "rank": 65535,
        "references": "http://loci.cs.utk.edu/ibp/",
        "selectorId": "6714",
        "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
        "trafficClass": "BULK_DATA",
        "tunnel": "false"
      }
    ],
    "networkIdentity": [
      {
        "id": "73e3e4a3-0f28-4d78-9cfe-2cdf3490cf96",
        "instanceId": 13451,
        "authEntityId": 13451,
        "displayName": "13451",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "6714",
        "protocol": "UDP",
        "upperPort": 0
      },
      {
        "id": "39f46b66-fb07-457c-b79d-6edee1558bf0",
        "instanceId": 13452,
        "authEntityId": 13452,
        "displayName": "13452",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "6714",
        "protocol": "TCP",
        "upperPort": 0
      }
    ],
    "parentScalableGroup": {
      "idRef": "000c15bf-28fe-4fbb-9b69-35e6502721f1"
    },
    "user": [

    ],
    "userGroup": [

    ]
  },
  {
    "id": "10ddcae8-0823-40f9-aae2-c33caff45c48",
    "instanceId": 4082,
    "authEntityId": 4082,
    "displayName": "4082",
    "authEntityClass": -1909073334,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "createTime": 1517966740941,
    "deployed": false,
    "isSeeded": true,
    "isStale": false,
    "lastUpdateTime": 1517966740941,
    "name": "aminet",
    "namespace": "scalablegroup:application",
    "provisioningState": "DEFINED",
    "qualifier": "application",
    "resourceVersion": 0,
    "targetIdList": [

    ],
    "type": "scalablegroup",
    "cfsChangeInfo": [

    ],
    "customProvisions": [

    ],
    "scalableGroupExternalHandle": "aminet",
    "scalableGroupType": "APPLICATION",
    "securityGroupTag": 0,
    "state": "ACTIVE",
    "vnAgnostic": true,
    "identitySource": {
      "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
      "instanceId": 10010,
      "authEntityId": 10010,
      "displayName": "10010",
      "authEntityClass": 1738953278,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "state": "INACTIVE",
      "type": "NBAR"
    },
    "indicativeNetworkIdentity": [
      {
        "id": "2a36a6aa-f3af-4157-8b4c-88328ebfebb8",
        "instanceId": 12120,
        "authEntityId": 12120,
        "displayName": "12120",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "2639",
        "protocol": "UDP",
        "upperPort": 0
      },
      {
        "id": "5d639925-0ca2-4ff8-8c8f-0e4172674d9e",
        "instanceId": 12121,
        "authEntityId": 12121,
        "displayName": "12121",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "2639",
        "protocol": "TCP",
        "upperPort": 0
      }
    ],
    "networkApplications": [
      {
        "id": "2fda91a2-d5f1-4bdb-84a1-f3bfa62a95b9",
        "instanceId": 11048,
        "authEntityId": 11048,
        "displayName": "11048",
        "authEntityClass": -217092956,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "appProtocol": "tcp/udp",
        "applicationGroup": "other",
        "applicationType": "DEFAULT",
        "categoryId": "1fc17362-9334-4c7b-b752-5afdbb574017",
        "createTime": 1517966740941,
        "encrypted": "false",
        "engineId": "3",
        "helpString": "AMInet",
        "ignoreConflict": false,
        "lastUpdateTime": 1517966740941,
        "longDescription": "AMInet Protocol is used for communication and control of Alcorn McBride Inc. products.",
        "name": "aminet",
        "nbarId": "558",
        "p2pTechnology": "false",
        "popularity": 1,
        "rank": 65535,
        "references": "http://www.alcorn.com/library/manuals/man_dvmhd.pdf",
        "selectorId": "2639",
        "subCategory": "f3c0f761-d894-4112-ab89-b90fc84fd660",
        "trafficClass": "OPS_ADMIN_MGMT",
        "tunnel": "false"
      }
    ],
    "networkIdentity": [
      {
        "id": "34b77681-7422-4cbb-b2a0-0042784b565f",
        "instanceId": 12118,
        "authEntityId": 12118,
        "displayName": "12118",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "2639",
        "protocol": "UDP",
        "upperPort": 0
      },
      {
        "id": "81dc729f-a696-41a4-b95f-d249199b665f",
        "instanceId": 12119,
        "authEntityId": 12119,
        "displayName": "12119",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "2639",
        "protocol": "TCP",
        "upperPort": 0
      }
    ],
    "parentScalableGroup": {
      "idRef": "5fcd74d4-8726-47f7-a088-c457863da7ef"
    },
    "user": [

    ],
    "userGroup": [

    ]
  },
  {
    "id": "11147033-480f-486e-ab40-3a8cfeb5f542",
    "instanceId": 4216,
    "authEntityId": 4216,
    "displayName": "4216",
    "authEntityClass": -1909073334,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "createTime": 1517966741769,
    "deployed": false,
    "isSeeded": true,
    "isStale": false,
    "lastUpdateTime": 1517966741769,
    "name": "cbs-sports",
    "namespace": "scalablegroup:application",
    "provisioningState": "DEFINED",
    "qualifier": "application",
    "resourceVersion": 0,
    "targetIdList": [

    ],
    "type": "scalablegroup",
    "cfsChangeInfo": [

    ],
    "customProvisions": [

    ],
    "scalableGroupExternalHandle": "cbs-sports",
    "scalableGroupType": "APPLICATION",
    "securityGroupTag": 0,
    "state": "ACTIVE",
    "vnAgnostic": true,
    "identitySource": {
      "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
      "instanceId": 10010,
      "authEntityId": 10010,
      "displayName": "10010",
      "authEntityClass": 1738953278,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "state": "INACTIVE",
      "type": "NBAR"
    },
    "indicativeNetworkIdentity": [

    ],
    "networkApplications": [
      {
        "id": "9d02603e-f6b2-4896-b903-fed51a645b68",
        "instanceId": 11171,
        "authEntityId": 11171,
        "displayName": "11171",
        "authEntityClass": -217092956,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "appProtocol": "tcp/udp",
        "applicationGroup": "other",
        "applicationType": "DEFAULT",
        "categoryId": "878644b5-fe91-45b4-9204-31c84a73ba48",
        "createTime": 1517966741769,
        "encrypted": "false",
        "engineId": "13",
        "helpString": "CBS Sports - Sports news website.",
        "ignoreConflict": false,
        "lastUpdateTime": 1517966741769,
        "longDescription": "CBS Sports - Sports news website.",
        "name": "cbs-sports",
        "nbarId": "1679",
        "p2pTechnology": "false",
        "popularity": 3,
        "rank": 65535,
        "references": "http://cbssports.com",
        "selectorId": "749",
        "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
        "trafficClass": "TRANSACTIONAL_DATA",
        "tunnel": "false"
      }
    ],
    "networkIdentity": [
      {
        "id": "134e8cfd-af43-4ecc-b582-df4601fff574",
        "instanceId": 12467,
        "authEntityId": 12467,
        "displayName": "12467",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "53,80,443,5353",
        "protocol": "TCP",
        "upperPort": 0
      },
      {
        "id": "f66e6d7f-6abf-4777-a586-d8b4ee3e1fb8",
        "instanceId": 12468,
        "authEntityId": 12468,
        "displayName": "12468",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "53,5353",
        "protocol": "UDP",
        "upperPort": 0
      }
    ],
    "parentScalableGroup": {
      "idRef": "28e08de4-d7b2-4521-84ce-bc1e5ee0b9cc"
    },
    "user": [

    ],
    "userGroup": [

    ]
  },
  {
    "id": "1147a3c9-8f82-48b4-829c-fb0ce07d2e33",
    "instanceId": 4629,
    "authEntityId": 4629,
    "displayName": "4629",
    "authEntityClass": -1909073334,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "createTime": 1517966743161,
    "deployed": false,
    "isSeeded": true,
    "isStale": false,
    "lastUpdateTime": 1517966743161,
    "name": "ipv6-frag",
    "namespace": "scalablegroup:application",
    "provisioningState": "DEFINED",
    "qualifier": "application",
    "resourceVersion": 0,
    "targetIdList": [

    ],
    "type": "scalablegroup",
    "cfsChangeInfo": [

    ],
    "customProvisions": [

    ],
    "scalableGroupExternalHandle": "ipv6-frag",
    "scalableGroupType": "APPLICATION",
    "securityGroupTag": 0,
    "state": "ACTIVE",
    "vnAgnostic": true,
    "identitySource": {
      "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
      "instanceId": 10010,
      "authEntityId": 10010,
      "displayName": "10010",
      "authEntityClass": 1738953278,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "state": "INACTIVE",
      "type": "NBAR"
    },
    "indicativeNetworkIdentity": [

    ],
    "networkApplications": [
      {
        "id": "d40d8912-a7a7-4b03-9142-9f9038e46520",
        "instanceId": 11579,
        "authEntityId": 11579,
        "displayName": "11579",
        "authEntityClass": -217092956,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "applicationGroup": "other",
        "applicationType": "DEFAULT",
        "categoryId": "6daa3dfd-3526-407a-88f0-97824b3f3a24",
        "createTime": 1517966743161,
        "encrypted": "unassigned",
        "engineId": "1",
        "helpString": "DEPRECATED, traffic will not match",
        "ignoreConflict": false,
        "lastUpdateTime": 1517966743161,
        "longDescription": "Deprecated: Fragment Header for IPv6",
        "name": "ipv6-frag",
        "nbarId": "798",
        "p2pTechnology": "unassigned",
        "popularity": 0,
        "rank": 65535,
        "references": "http://www.iana.org/assignments/protocol-numbers/protocol-numbers.xml",
        "selectorId": "44",
        "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
        "trafficClass": "BULK_DATA",
        "tunnel": "unassigned"
      }
    ],
    "networkIdentity": [

    ],
    "parentScalableGroup": {
      "idRef": "000c15bf-28fe-4fbb-9b69-35e6502721f1"
    },
    "user": [

    ],
    "userGroup": [

    ]
  },
  {
    "id": "11627431-5c39-4b86-ba1e-1cd48e5da753",
    "instanceId": 4903,
    "authEntityId": 4903,
    "displayName": "4903",
    "authEntityClass": -1909073334,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "createTime": 1517966743719,
    "deployed": false,
    "isSeeded": true,
    "isStale": false,
    "lastUpdateTime": 1517966743719,
    "name": "okcupid",
    "namespace": "scalablegroup:application",
    "provisioningState": "DEFINED",
    "qualifier": "application",
    "resourceVersion": 0,
    "targetIdList": [

    ],
    "type": "scalablegroup",
    "cfsChangeInfo": [

    ],
    "customProvisions": [

    ],
    "scalableGroupExternalHandle": "okcupid",
    "scalableGroupType": "APPLICATION",
    "securityGroupTag": 0,
    "state": "ACTIVE",
    "vnAgnostic": true,
    "identitySource": {
      "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
      "instanceId": 10010,
      "authEntityId": 10010,
      "displayName": "10010",
      "authEntityClass": 1738953278,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "state": "INACTIVE",
      "type": "NBAR"
    },
    "indicativeNetworkIdentity": [

    ],
    "networkApplications": [
      {
        "id": "1e621093-9a43-45b8-85ee-66865366ae1a",
        "instanceId": 11852,
        "authEntityId": 11852,
        "displayName": "11852",
        "authEntityClass": -217092956,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "appProtocol": "tcp/udp",
        "applicationGroup": "other",
        "applicationType": "DEFAULT",
        "categoryId": "878644b5-fe91-45b4-9204-31c84a73ba48",
        "createTime": 1517966743719,
        "encrypted": "false",
        "engineId": "13",
        "helpString": "OkCupid - Online Dating website.",
        "ignoreConflict": false,
        "lastUpdateTime": 1517966743719,
        "longDescription": "OkCupid - Online Dating website.",
        "name": "okcupid",
        "nbarId": "1633",
        "p2pTechnology": "false",
        "popularity": 3,
        "rank": 65535,
        "references": "http://okccdn.com",
        "selectorId": "703",
        "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
        "trafficClass": "TRANSACTIONAL_DATA",
        "tunnel": "false"
      }
    ],
    "networkIdentity": [
      {
        "id": "57f2f4ae-b3b7-416d-9047-dc98d7acc9b1",
        "instanceId": 14364,
        "authEntityId": 14364,
        "displayName": "14364",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "53,5353",
        "protocol": "UDP",
        "upperPort": 0
      },
      {
        "id": "fc5d03c1-731e-4ddc-beb3-f2246de4594d",
        "instanceId": 14365,
        "authEntityId": 14365,
        "displayName": "14365",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "53,80,443,5353",
        "protocol": "TCP",
        "upperPort": 0
      }
    ],
    "parentScalableGroup": {
      "idRef": "28e08de4-d7b2-4521-84ce-bc1e5ee0b9cc"
    },
    "user": [

    ],
    "userGroup": [

    ]
  },
  {
    "id": "119c67a1-df04-49a7-862a-1c807c6d10c8",
    "instanceId": 15327,
    "authEntityId": 15327,
    "displayName": "15327",
    "authEntityClass": -1909073334,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "createTime": 1517966744745,
    "deployed": false,
    "isSeeded": true,
    "isStale": false,
    "lastUpdateTime": 1517966744745,
    "name": "ups",
    "namespace": "scalablegroup:application",
    "provisioningState": "DEFINED",
    "qualifier": "application",
    "resourceVersion": 0,
    "targetIdList": [

    ],
    "type": "scalablegroup",
    "cfsChangeInfo": [

    ],
    "customProvisions": [

    ],
    "scalableGroupExternalHandle": "ups",
    "scalableGroupType": "APPLICATION",
    "securityGroupTag": 0,
    "state": "ACTIVE",
    "vnAgnostic": true,
    "identitySource": {
      "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
      "instanceId": 10010,
      "authEntityId": 10010,
      "displayName": "10010",
      "authEntityClass": 1738953278,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "state": "INACTIVE",
      "type": "NBAR"
    },
    "indicativeNetworkIdentity": [
      {
        "id": "29c0a158-fab8-475f-b1d7-4086d9d7bc51",
        "instanceId": 17600,
        "authEntityId": 17600,
        "displayName": "17600",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "401",
        "protocol": "TCP",
        "upperPort": 0
      },
      {
        "id": "fc9e4b4b-2009-4a39-ad47-726dedd1e0c4",
        "instanceId": 17599,
        "authEntityId": 17599,
        "displayName": "17599",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "401",
        "protocol": "UDP",
        "upperPort": 0
      }
    ],
    "networkApplications": [
      {
        "id": "bd5cbe05-6da3-4d4a-b0e7-5dbf6ceb862e",
        "instanceId": 16270,
        "authEntityId": 16270,
        "displayName": "16270",
        "authEntityClass": -217092956,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "appProtocol": "tcp/udp",
        "applicationGroup": "other",
        "applicationType": "DEFAULT",
        "categoryId": "d264d6f4-b014-43e6-acbc-516128d1c6c8",
        "createTime": 1517966744745,
        "encrypted": "false",
        "engineId": "3",
        "helpString": "Uninterruptible Power Supply",
        "ignoreConflict": false,
        "lastUpdateTime": 1517966744745,
        "longDescription": "Registered with IANA on port 401 TCP/UDP",
        "name": "ups",
        "nbarId": "316",
        "p2pTechnology": "false",
        "popularity": 1,
        "rank": 65535,
        "references": "http://www.iana.org/assignments/service-names-port-numbers/service-names-port-numbers.xml",
        "selectorId": "401",
        "subCategory": "68c4f989-5735-4e16-8d1c-6a9d89d5df9c",
        "trafficClass": "SIGNALING",
        "tunnel": "false"
      }
    ],
    "networkIdentity": [
      {
        "id": "190ecb11-a38e-469c-b307-6502c287937c",
        "instanceId": 17597,
        "authEntityId": 17597,
        "displayName": "17597",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "401",
        "protocol": "UDP",
        "upperPort": 0
      },
      {
        "id": "267402c4-6c68-4733-9218-ca9a0eef5489",
        "instanceId": 17598,
        "authEntityId": 17598,
        "displayName": "17598",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "401",
        "protocol": "TCP",
        "upperPort": 0
      }
    ],
    "parentScalableGroup": {
      "idRef": "a3243a74-3864-4041-a927-0201030da17c"
    },
    "user": [

    ],
    "userGroup": [

    ]
  },
  {
    "id": "11bc2c88-34b4-4d63-8705-690213822016",
    "instanceId": 15341,
    "authEntityId": 15341,
    "displayName": "15341",
    "authEntityClass": -1909073334,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "createTime": 1517966744770,
    "deployed": false,
    "isSeeded": true,
    "isStale": false,
    "lastUpdateTime": 1517966744770,
    "name": "vacdsm-app",
    "namespace": "scalablegroup:application",
    "provisioningState": "DEFINED",
    "qualifier": "application",
    "resourceVersion": 0,
    "targetIdList": [

    ],
    "type": "scalablegroup",
    "cfsChangeInfo": [

    ],
    "customProvisions": [

    ],
    "scalableGroupExternalHandle": "vacdsm-app",
    "scalableGroupType": "APPLICATION",
    "securityGroupTag": 0,
    "state": "ACTIVE",
    "vnAgnostic": true,
    "identitySource": {
      "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
      "instanceId": 10010,
      "authEntityId": 10010,
      "displayName": "10010",
      "authEntityClass": 1738953278,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "state": "INACTIVE",
      "type": "NBAR"
    },
    "indicativeNetworkIdentity": [
      {
        "id": "a5433094-4c27-4a36-8c7b-db120cbc3e0d",
        "instanceId": 17645,
        "authEntityId": 17645,
        "displayName": "17645",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "671",
        "protocol": "UDP",
        "upperPort": 0
      },
      {
        "id": "2fada98d-4d4e-4603-a382-f4f0affb4df0",
        "instanceId": 17646,
        "authEntityId": 17646,
        "displayName": "17646",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "671",
        "protocol": "TCP",
        "upperPort": 0
      }
    ],
    "networkApplications": [
      {
        "id": "a4dd9cce-a084-4091-ac1b-b25cd9494749",
        "instanceId": 16284,
        "authEntityId": 16284,
        "displayName": "16284",
        "authEntityClass": -217092956,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "appProtocol": "tcp/udp",
        "applicationGroup": "other",
        "applicationType": "DEFAULT",
        "categoryId": "6daa3dfd-3526-407a-88f0-97824b3f3a24",
        "createTime": 1517966744770,
        "encrypted": "false",
        "engineId": "3",
        "helpString": "VACDSM-APP",
        "ignoreConflict": false,
        "lastUpdateTime": 1517966744770,
        "longDescription": "Registered with IANA on port 671 TCP/UDP",
        "name": "vacdsm-app",
        "nbarId": "579",
        "p2pTechnology": "false",
        "popularity": 1,
        "rank": 65535,
        "references": "http://www.iana.org/assignments/service-names-port-numbers/service-names-port-numbers.xml",
        "selectorId": "671",
        "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
        "trafficClass": "BULK_DATA",
        "tunnel": "false"
      }
    ],
    "networkIdentity": [
      {
        "id": "2f694dda-3451-434c-bef5-91f4d685cd67",
        "instanceId": 17643,
        "authEntityId": 17643,
        "displayName": "17643",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "671",
        "protocol": "TCP",
        "upperPort": 0
      },
      {
        "id": "14dc8f04-9897-4222-9f60-5f65d04b85c3",
        "instanceId": 17644,
        "authEntityId": 17644,
        "displayName": "17644",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "671",
        "protocol": "UDP",
        "upperPort": 0
      }
    ],
    "parentScalableGroup": {
      "idRef": "000c15bf-28fe-4fbb-9b69-35e6502721f1"
    },
    "user": [

    ],
    "userGroup": [

    ]
  },
  {
    "id": "12944a2d-e177-449a-8f2a-2cbf74067550",
    "instanceId": 4266,
    "authEntityId": 4266,
    "displayName": "4266",
    "authEntityClass": -1909073334,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "createTime": 1517966741988,
    "deployed": false,
    "isSeeded": true,
    "isStale": false,
    "lastUpdateTime": 1517966741988,
    "name": "cloanto-net-1",
    "namespace": "scalablegroup:application",
    "provisioningState": "DEFINED",
    "qualifier": "application",
    "resourceVersion": 0,
    "targetIdList": [

    ],
    "type": "scalablegroup",
    "cfsChangeInfo": [

    ],
    "customProvisions": [

    ],
    "scalableGroupExternalHandle": "cloanto-net-1",
    "scalableGroupType": "APPLICATION",
    "securityGroupTag": 0,
    "state": "ACTIVE",
    "vnAgnostic": true,
    "identitySource": {
      "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
      "instanceId": 10010,
      "authEntityId": 10010,
      "displayName": "10010",
      "authEntityClass": 1738953278,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "state": "INACTIVE",
      "type": "NBAR"
    },
    "indicativeNetworkIdentity": [
      {
        "id": "f16c76db-c4a0-47b6-8a50-fa579835cf19",
        "instanceId": 12570,
        "authEntityId": 12570,
        "displayName": "12570",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "356",
        "protocol": "TCP",
        "upperPort": 0
      },
      {
        "id": "fde5ee69-ab8d-4d63-aa49-685be3c4392c",
        "instanceId": 12571,
        "authEntityId": 12571,
        "displayName": "12571",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "356",
        "protocol": "UDP",
        "upperPort": 0
      }
    ],
    "networkApplications": [
      {
        "id": "d041d3de-2a10-4d5c-b1e8-ddeffcc58cba",
        "instanceId": 11219,
        "authEntityId": 11219,
        "displayName": "11219",
        "authEntityClass": -217092956,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "appProtocol": "tcp/udp",
        "applicationGroup": "other",
        "applicationType": "DEFAULT",
        "categoryId": "6daa3dfd-3526-407a-88f0-97824b3f3a24",
        "createTime": 1517966741988,
        "encrypted": "unassigned",
        "engineId": "3",
        "helpString": "cloanto-net-1",
        "ignoreConflict": false,
        "lastUpdateTime": 1517966741988,
        "longDescription": "Cloanto is a service developed by the Cloanto Corporation for use in the company's software components for electronic marketing, publishing, commerce and internationalization.",
        "name": "cloanto-net-1",
        "nbarId": "272",
        "p2pTechnology": "false",
        "popularity": 1,
        "rank": 65535,
        "references": "http://www.cloanto.com/",
        "selectorId": "356",
        "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
        "trafficClass": "BULK_DATA",
        "tunnel": "false"
      }
    ],
    "networkIdentity": [
      {
        "id": "cd6ad9ee-2443-4341-b5de-00183c766af1",
        "instanceId": 12568,
        "authEntityId": 12568,
        "displayName": "12568",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "356",
        "protocol": "TCP",
        "upperPort": 0
      },
      {
        "id": "bbd158e3-ebb9-43bf-a62c-ee6e86cb389e",
        "instanceId": 12569,
        "authEntityId": 12569,
        "displayName": "12569",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "356",
        "protocol": "UDP",
        "upperPort": 0
      }
    ],
    "parentScalableGroup": {
      "idRef": "000c15bf-28fe-4fbb-9b69-35e6502721f1"
    },
    "user": [

    ],
    "userGroup": [

    ]
  },
  {
    "id": "12947ef5-283b-49df-a37b-d8b4117f6e59",
    "instanceId": 4879,
    "authEntityId": 4879,
    "displayName": "4879",
    "authEntityClass": -1909073334,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "createTime": 1517966743668,
    "deployed": false,
    "isSeeded": true,
    "isStale": false,
    "lastUpdateTime": 1517966743668,
    "name": "npmp-trap",
    "namespace": "scalablegroup:application",
    "provisioningState": "DEFINED",
    "qualifier": "application",
    "resourceVersion": 0,
    "targetIdList": [

    ],
    "type": "scalablegroup",
    "cfsChangeInfo": [

    ],
    "customProvisions": [

    ],
    "scalableGroupExternalHandle": "npmp-trap",
    "scalableGroupType": "APPLICATION",
    "securityGroupTag": 0,
    "state": "ACTIVE",
    "vnAgnostic": true,
    "identitySource": {
      "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
      "instanceId": 10010,
      "authEntityId": 10010,
      "displayName": "10010",
      "authEntityClass": 1738953278,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "state": "INACTIVE",
      "type": "NBAR"
    },
    "indicativeNetworkIdentity": [
      {
        "id": "fdff7875-b471-4f42-b8c8-afca91d29963",
        "instanceId": 14282,
        "authEntityId": 14282,
        "displayName": "14282",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "609",
        "protocol": "UDP",
        "upperPort": 0
      },
      {
        "id": "80ba6d13-3558-4ac7-85c2-9d38035679a9",
        "instanceId": 14283,
        "authEntityId": 14283,
        "displayName": "14283",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "609",
        "protocol": "TCP",
        "upperPort": 0
      }
    ],
    "networkApplications": [
      {
        "id": "f9022174-e89d-4ae0-897f-f1e834199058",
        "instanceId": 11828,
        "authEntityId": 11828,
        "displayName": "11828",
        "authEntityClass": -217092956,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "appProtocol": "tcp/udp",
        "applicationGroup": "npmp-group",
        "applicationType": "DEFAULT",
        "categoryId": "1fc17362-9334-4c7b-b752-5afdbb574017",
        "createTime": 1517966743668,
        "encrypted": "false",
        "engineId": "3",
        "helpString": "npmp-trap",
        "ignoreConflict": false,
        "lastUpdateTime": 1517966743668,
        "longDescription": "Registered with IANA on port 609 TCP/UDP",
        "name": "npmp-trap",
        "nbarId": "518",
        "p2pTechnology": "false",
        "popularity": 1,
        "rank": 65535,
        "references": "http://www.iana.org/assignments/service-names-port-numbers/service-names-port-numbers.xml",
        "selectorId": "609",
        "subCategory": "f3c0f761-d894-4112-ab89-b90fc84fd660",
        "trafficClass": "OPS_ADMIN_MGMT",
        "tunnel": "false"
      }
    ],
    "networkIdentity": [
      {
        "id": "712c8bec-a9b6-4422-9936-d6281daee996",
        "instanceId": 14280,
        "authEntityId": 14280,
        "displayName": "14280",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "609",
        "protocol": "UDP",
        "upperPort": 0
      },
      {
        "id": "e516b609-13a8-4460-86d8-d9b909bfe59c",
        "instanceId": 14281,
        "authEntityId": 14281,
        "displayName": "14281",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "609",
        "protocol": "TCP",
        "upperPort": 0
      }
    ],
    "parentScalableGroup": {
      "idRef": "5fcd74d4-8726-47f7-a088-c457863da7ef"
    },
    "user": [

    ],
    "userGroup": [

    ]
  },
  {
    "id": "1312faf8-3e56-4324-84d8-f276df7d005a",
    "instanceId": 15102,
    "authEntityId": 15102,
    "displayName": "15102",
    "authEntityClass": -1909073334,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "createTime": 1517966744238,
    "deployed": false,
    "isSeeded": true,
    "isStale": false,
    "lastUpdateTime": 1517966744238,
    "name": "scoi2odialog",
    "namespace": "scalablegroup:application",
    "provisioningState": "DEFINED",
    "qualifier": "application",
    "resourceVersion": 0,
    "targetIdList": [

    ],
    "type": "scalablegroup",
    "cfsChangeInfo": [

    ],
    "customProvisions": [

    ],
    "scalableGroupExternalHandle": "scoi2odialog",
    "scalableGroupType": "APPLICATION",
    "securityGroupTag": 0,
    "state": "ACTIVE",
    "vnAgnostic": true,
    "identitySource": {
      "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
      "instanceId": 10010,
      "authEntityId": 10010,
      "displayName": "10010",
      "authEntityClass": 1738953278,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "state": "INACTIVE",
      "type": "NBAR"
    },
    "indicativeNetworkIdentity": [
      {
        "id": "fe0b2be5-059a-461f-971a-b32b436bdec2",
        "instanceId": 14946,
        "authEntityId": 14946,
        "displayName": "14946",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "360",
        "protocol": "UDP",
        "upperPort": 0
      },
      {
        "id": "cf15035c-d086-4edd-ae88-3ba45f060dc9",
        "instanceId": 14947,
        "authEntityId": 14947,
        "displayName": "14947",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "360",
        "protocol": "TCP",
        "upperPort": 0
      }
    ],
    "networkApplications": [
      {
        "id": "2abe1a78-d904-4a49-8e68-5e517a3a3f3a",
        "instanceId": 16045,
        "authEntityId": 16045,
        "displayName": "16045",
        "authEntityClass": -217092956,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "appProtocol": "tcp/udp",
        "applicationGroup": "other",
        "applicationType": "DEFAULT",
        "categoryId": "6daa3dfd-3526-407a-88f0-97824b3f3a24",
        "createTime": 1517966744238,
        "encrypted": "false",
        "engineId": "3",
        "helpString": "scoi2odialog",
        "ignoreConflict": false,
        "lastUpdateTime": 1517966744238,
        "longDescription": "Registered with IANA on port 360 TCP/UDP",
        "name": "scoi2odialog",
        "nbarId": "276",
        "p2pTechnology": "false",
        "popularity": 1,
        "rank": 65535,
        "references": "http://www.iana.org/assignments/service-names-port-numbers/service-names-port-numbers.xml",
        "selectorId": "360",
        "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
        "trafficClass": "BULK_DATA",
        "tunnel": "false"
      }
    ],
    "networkIdentity": [
      {
        "id": "13732622-b103-4de1-9af6-59847423b26b",
        "instanceId": 14944,
        "authEntityId": 14944,
        "displayName": "14944",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "360",
        "protocol": "UDP",
        "upperPort": 0
      },
      {
        "id": "3b1d5d51-4837-414d-a10b-21306ac75232",
        "instanceId": 14945,
        "authEntityId": 14945,
        "displayName": "14945",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "360",
        "protocol": "TCP",
        "upperPort": 0
      }
    ],
    "parentScalableGroup": {
      "idRef": "000c15bf-28fe-4fbb-9b69-35e6502721f1"
    },
    "user": [

    ],
    "userGroup": [

    ]
  },
  {
    "id": "1344c8d7-4513-4d3f-84cf-ae3e65aa56fb",
    "instanceId": 15458,
    "authEntityId": 15458,
    "displayName": "15458",
    "authEntityClass": -1909073334,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "createTime": 1517966745001,
    "deployed": false,
    "isSeeded": true,
    "isStale": false,
    "lastUpdateTime": 1517966745001,
    "name": "yahoo-voip-messenger",
    "namespace": "scalablegroup:application",
    "provisioningState": "DEFINED",
    "qualifier": "application",
    "resourceVersion": 0,
    "targetIdList": [

    ],
    "type": "scalablegroup",
    "cfsChangeInfo": [

    ],
    "customProvisions": [

    ],
    "scalableGroupExternalHandle": "yahoo-voip-messenger",
    "scalableGroupType": "APPLICATION",
    "securityGroupTag": 0,
    "state": "ACTIVE",
    "vnAgnostic": true,
    "identitySource": {
      "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
      "instanceId": 10010,
      "authEntityId": 10010,
      "displayName": "10010",
      "authEntityClass": 1738953278,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "state": "INACTIVE",
      "type": "NBAR"
    },
    "indicativeNetworkIdentity": [

    ],
    "networkApplications": [
      {
        "id": "8cbb3481-6cd4-473d-8141-3e86f7030510",
        "instanceId": 16401,
        "authEntityId": 16401,
        "displayName": "16401",
        "authEntityClass": -217092956,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "appProtocol": "tcp/udp",
        "applicationGroup": "yahoo-messenger-group",
        "applicationType": "DEFAULT",
        "categoryId": "82a7b7fa-da5a-48f5-8b6a-a2546b947843",
        "createTime": 1517966745001,
        "encrypted": "false",
        "engineId": "13",
        "helpString": "Free P2P calls",
        "ignoreConflict": false,
        "lastUpdateTime": 1517966745001,
        "longDescription": "Yahoo VoIP Messenger is a feature included in Yahoo Messenger. It allows users to make calls PC to PC or PC to landline/mobile. The underlying protocols are SIP, SSL, STUN-NAT,RTP.",
        "name": "yahoo-voip-messenger",
        "nbarId": "674",
        "p2pTechnology": "false",
        "popularity": 9,
        "rank": 65535,
        "references": "http://messenger.yahoo.com/features/voice/",
        "selectorId": "422",
        "subCategory": "118c10a2-e272-47c1-92b9-4814ebe97138",
        "trafficClass": "VOIP_TELEPHONY",
        "tunnel": "false"
      }
    ],
    "networkIdentity": [
      {
        "id": "8b0fa74d-9c83-4ecb-baa9-1efac7d6667d",
        "instanceId": 17952,
        "authEntityId": 17952,
        "displayName": "17952",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "3478",
        "protocol": "TCP",
        "upperPort": 0
      },
      {
        "id": "ddecc168-bb35-4f5b-a71f-f2fbedd09a60",
        "instanceId": 17953,
        "authEntityId": 17953,
        "displayName": "17953",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "3478",
        "protocol": "UDP",
        "upperPort": 0
      }
    ],
    "parentScalableGroup": {
      "idRef": "fd6264c9-c16a-4311-a335-a3db039256f3"
    },
    "user": [

    ],
    "userGroup": [

    ]
  },
  {
    "id": "13670d17-5af1-464a-8ac9-62bec0582fa2",
    "instanceId": 15358,
    "authEntityId": 15358,
    "displayName": "15358",
    "authEntityClass": -1909073334,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "createTime": 1517966744799,
    "deployed": false,
    "isSeeded": true,
    "isStale": false,
    "lastUpdateTime": 1517966744799,
    "name": "vmpwscs",
    "namespace": "scalablegroup:application",
    "provisioningState": "DEFINED",
    "qualifier": "application",
    "resourceVersion": 0,
    "targetIdList": [

    ],
    "type": "scalablegroup",
    "cfsChangeInfo": [

    ],
    "customProvisions": [

    ],
    "scalableGroupExternalHandle": "vmpwscs",
    "scalableGroupType": "APPLICATION",
    "securityGroupTag": 0,
    "state": "ACTIVE",
    "vnAgnostic": true,
    "identitySource": {
      "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
      "instanceId": 10010,
      "authEntityId": 10010,
      "displayName": "10010",
      "authEntityClass": 1738953278,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "state": "INACTIVE",
      "type": "NBAR"
    },
    "indicativeNetworkIdentity": [
      {
        "id": "740e96ea-9689-40eb-b018-f80d6f3fa06e",
        "instanceId": 17691,
        "authEntityId": 17691,
        "displayName": "17691",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "214",
        "protocol": "UDP",
        "upperPort": 0
      },
      {
        "id": "1251ac2b-4a59-4889-9cda-22ff9971e7b2",
        "instanceId": 17692,
        "authEntityId": 17692,
        "displayName": "17692",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "214",
        "protocol": "TCP",
        "upperPort": 0
      }
    ],
    "networkApplications": [
      {
        "id": "382861de-1ca5-4dd2-bdba-e24d2f686775",
        "instanceId": 16301,
        "authEntityId": 16301,
        "displayName": "16301",
        "authEntityClass": -217092956,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "appProtocol": "tcp/udp",
        "applicationGroup": "other",
        "applicationType": "DEFAULT",
        "categoryId": "9d9132a2-d99b-49db-a414-9fc93dea6604",
        "createTime": 1517966744799,
        "encrypted": "unassigned",
        "engineId": "3",
        "helpString": "vmpwscs",
        "ignoreConflict": false,
        "lastUpdateTime": 1517966744799,
        "longDescription": "Registered with IANA on port 214 TCP/UDP",
        "name": "vmpwscs",
        "nbarId": "1111",
        "p2pTechnology": "unassigned",
        "popularity": 1,
        "rank": 65535,
        "references": "http://www.iana.org/assignments/service-names-port-numbers/service-names-port-numbers.xml",
        "selectorId": "214",
        "subCategory": "98ea6008-96b8-4920-b334-0763dc4ae269",
        "trafficClass": "BULK_DATA",
        "tunnel": "unassigned"
      }
    ],
    "networkIdentity": [
      {
        "id": "2284ee07-eb5b-4aa9-9487-1c0819283c16",
        "instanceId": 17689,
        "authEntityId": 17689,
        "displayName": "17689",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "214",
        "protocol": "TCP",
        "upperPort": 0
      },
      {
        "id": "b4eb2d0a-d3db-4c22-b135-bbfa6a1fc4cb",
        "instanceId": 17690,
        "authEntityId": 17690,
        "displayName": "17690",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "214",
        "protocol": "UDP",
        "upperPort": 0
      }
    ],
    "parentScalableGroup": {
      "idRef": "4a68bc73-53c6-4deb-9983-784a0338aefe"
    },
    "user": [

    ],
    "userGroup": [

    ]
  },
  {
    "id": "1373c285-32a2-4c1f-b1bf-c8330c75774d",
    "instanceId": 15228,
    "authEntityId": 15228,
    "displayName": "15228",
    "authEntityClass": -1909073334,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "createTime": 1517966744536,
    "deployed": false,
    "isSeeded": true,
    "isStale": false,
    "lastUpdateTime": 1517966744536,
    "name": "submit",
    "namespace": "scalablegroup:application",
    "provisioningState": "DEFINED",
    "qualifier": "application",
    "resourceVersion": 0,
    "targetIdList": [

    ],
    "type": "scalablegroup",
    "cfsChangeInfo": [

    ],
    "customProvisions": [

    ],
    "scalableGroupExternalHandle": "submit",
    "scalableGroupType": "APPLICATION",
    "securityGroupTag": 0,
    "state": "ACTIVE",
    "vnAgnostic": true,
    "identitySource": {
      "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
      "instanceId": 10010,
      "authEntityId": 10010,
      "displayName": "10010",
      "authEntityClass": 1738953278,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "state": "INACTIVE",
      "type": "NBAR"
    },
    "indicativeNetworkIdentity": [
      {
        "id": "9a5515df-45b2-4427-9f71-941631ed24b2",
        "instanceId": 17317,
        "authEntityId": 17317,
        "displayName": "17317",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "773",
        "protocol": "TCP",
        "upperPort": 0
      }
    ],
    "networkApplications": [
      {
        "id": "4b128b2c-08fb-4b5b-9bb5-c48fb5834634",
        "instanceId": 16171,
        "authEntityId": 16171,
        "displayName": "16171",
        "authEntityClass": -217092956,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "appProtocol": "tcp",
        "applicationGroup": "other",
        "applicationType": "DEFAULT",
        "categoryId": "6daa3dfd-3526-407a-88f0-97824b3f3a24",
        "createTime": 1517966744536,
        "encrypted": "false",
        "engineId": "3",
        "helpString": "Submit Protocol",
        "ignoreConflict": false,
        "lastUpdateTime": 1517966744536,
        "longDescription": "Registered with IANA on port 773 TCP",
        "name": "submit",
        "nbarId": "643",
        "p2pTechnology": "false",
        "popularity": 1,
        "rank": 65535,
        "references": "http://www.iana.org/assignments/service-names-port-numbers/service-names-port-numbers.xml",
        "selectorId": "773",
        "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
        "trafficClass": "BULK_DATA",
        "tunnel": "false"
      }
    ],
    "networkIdentity": [
      {
        "id": "b97dd783-c6cb-49d6-adc6-3bbfd267e30e",
        "instanceId": 17316,
        "authEntityId": 17316,
        "displayName": "17316",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "773",
        "protocol": "TCP",
        "upperPort": 0
      }
    ],
    "parentScalableGroup": {
      "idRef": "000c15bf-28fe-4fbb-9b69-35e6502721f1"
    },
    "user": [

    ],
    "userGroup": [

    ]
  },
  {
    "id": "13856753-a914-444c-91a5-8be9029f49d8",
    "instanceId": 4322,
    "authEntityId": 4322,
    "displayName": "4322",
    "authEntityClass": -1909073334,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "createTime": 1517966742279,
    "deployed": false,
    "isSeeded": true,
    "isStale": false,
    "lastUpdateTime": 1517966742279,
    "name": "dangdang",
    "namespace": "scalablegroup:application",
    "provisioningState": "DEFINED",
    "qualifier": "application",
    "resourceVersion": 0,
    "targetIdList": [

    ],
    "type": "scalablegroup",
    "cfsChangeInfo": [

    ],
    "customProvisions": [

    ],
    "scalableGroupExternalHandle": "dangdang",
    "scalableGroupType": "APPLICATION",
    "securityGroupTag": 0,
    "state": "ACTIVE",
    "vnAgnostic": true,
    "identitySource": {
      "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
      "instanceId": 10010,
      "authEntityId": 10010,
      "displayName": "10010",
      "authEntityClass": 1738953278,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "state": "INACTIVE",
      "type": "NBAR"
    },
    "indicativeNetworkIdentity": [

    ],
    "networkApplications": [
      {
        "id": "70e75259-8b9c-4bbb-ae35-4a01d49fde34",
        "instanceId": 11274,
        "authEntityId": 11274,
        "displayName": "11274",
        "authEntityClass": -217092956,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "appProtocol": "tcp/udp",
        "applicationGroup": "other",
        "applicationType": "DEFAULT",
        "categoryId": "878644b5-fe91-45b4-9204-31c84a73ba48",
        "createTime": 1517966742279,
        "encrypted": "false",
        "engineId": "13",
        "helpString": "Dangdang - Chinese general E-commerce company.",
        "ignoreConflict": false,
        "lastUpdateTime": 1517966742279,
        "longDescription": "Dangdang - Chinese general E-commerce company.",
        "name": "dangdang",
        "nbarId": "1723",
        "p2pTechnology": "false",
        "popularity": 3,
        "rank": 65535,
        "references": "http://dangdang.com",
        "selectorId": "795",
        "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
        "trafficClass": "TRANSACTIONAL_DATA",
        "tunnel": "false"
      }
    ],
    "networkIdentity": [
      {
        "id": "b3b5abe1-d93a-4db0-b39b-781811277635",
        "instanceId": 12740,
        "authEntityId": 12740,
        "displayName": "12740",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "53,80,443,5353",
        "protocol": "TCP",
        "upperPort": 0
      },
      {
        "id": "f37f2ec0-05b0-4f0f-a843-df8c9cd68ad1",
        "instanceId": 12741,
        "authEntityId": 12741,
        "displayName": "12741",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "53,5353",
        "protocol": "UDP",
        "upperPort": 0
      }
    ],
    "parentScalableGroup": {
      "idRef": "28e08de4-d7b2-4521-84ce-bc1e5ee0b9cc"
    },
    "user": [

    ],
    "userGroup": [

    ]
  },
  {
    "id": "13ad2abd-a5c1-4273-8276-0553a73f4df7",
    "instanceId": 5000,
    "authEntityId": 5000,
    "displayName": "5000",
    "authEntityClass": -1909073334,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "createTime": 1517966744018,
    "deployed": false,
    "isSeeded": true,
    "isStale": false,
    "lastUpdateTime": 1517966744018,
    "name": "pwdgen",
    "namespace": "scalablegroup:application",
    "provisioningState": "DEFINED",
    "qualifier": "application",
    "resourceVersion": 0,
    "targetIdList": [

    ],
    "type": "scalablegroup",
    "cfsChangeInfo": [

    ],
    "customProvisions": [

    ],
    "scalableGroupExternalHandle": "pwdgen",
    "scalableGroupType": "APPLICATION",
    "securityGroupTag": 0,
    "state": "ACTIVE",
    "vnAgnostic": true,
    "identitySource": {
      "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
      "instanceId": 10010,
      "authEntityId": 10010,
      "displayName": "10010",
      "authEntityClass": 1738953278,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "state": "INACTIVE",
      "type": "NBAR"
    },
    "indicativeNetworkIdentity": [
      {
        "id": "b3c0e22d-c31a-41c8-96ab-f6d52e0f488e",
        "instanceId": 14658,
        "authEntityId": 14658,
        "displayName": "14658",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "129",
        "protocol": "UDP",
        "upperPort": 0
      },
      {
        "id": "892e9a9f-1125-4b66-ba23-d6c9dfc97110",
        "instanceId": 14659,
        "authEntityId": 14659,
        "displayName": "14659",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "129",
        "protocol": "TCP",
        "upperPort": 0
      }
    ],
    "networkApplications": [
      {
        "id": "409f3e81-743b-4f28-bdec-ec1d73748c60",
        "instanceId": 11949,
        "authEntityId": 11949,
        "displayName": "11949",
        "authEntityClass": -217092956,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "appProtocol": "tcp/udp",
        "applicationGroup": "other",
        "applicationType": "DEFAULT",
        "categoryId": "1fc17362-9334-4c7b-b752-5afdbb574017",
        "createTime": 1517966744018,
        "encrypted": "false",
        "engineId": "3",
        "helpString": "Password Generator Protocol",
        "ignoreConflict": false,
        "lastUpdateTime": 1517966744018,
        "longDescription": "The PWDGEN Service provides a set of six randomly generated eight-character CRLF-delimited \"words\" with a reasonable level of pronounceability, using a multi-level algorithm.  An implementation of the algorithm is available in FORTRAN-77 for examination and possible implementation by system administrators only.",
        "name": "pwdgen",
        "nbarId": "998",
        "p2pTechnology": "false",
        "popularity": 1,
        "rank": 65535,
        "references": "http://www.ietf.org/rfc/rfc0972.txt",
        "selectorId": "129",
        "subCategory": "e390124a-0fd6-4253-b68e-4b0c1af8cb4b",
        "trafficClass": "OPS_ADMIN_MGMT",
        "tunnel": "false"
      }
    ],
    "networkIdentity": [
      {
        "id": "4e9960eb-09c7-4961-a871-3d13f8b086bc",
        "instanceId": 14656,
        "authEntityId": 14656,
        "displayName": "14656",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "129",
        "protocol": "UDP",
        "upperPort": 0
      },
      {
        "id": "c23c0880-5995-4658-a7a2-ea7c6602636f",
        "instanceId": 14657,
        "authEntityId": 14657,
        "displayName": "14657",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "129",
        "protocol": "TCP",
        "upperPort": 0
      }
    ],
    "parentScalableGroup": {
      "idRef": "7788bf2d-e289-4597-b500-17aacb7e820e"
    },
    "user": [

    ],
    "userGroup": [

    ]
  },
  {
    "id": "13d3d2d3-29df-4eed-b6bd-226bc324058e",
    "instanceId": 4239,
    "authEntityId": 4239,
    "displayName": "4239",
    "authEntityClass": -1909073334,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "createTime": 1517966741888,
    "deployed": false,
    "isSeeded": true,
    "isStale": false,
    "lastUpdateTime": 1517966741888,
    "name": "cisco-jabber-control",
    "namespace": "scalablegroup:application",
    "provisioningState": "DEFINED",
    "qualifier": "application",
    "resourceVersion": 0,
    "targetIdList": [

    ],
    "type": "scalablegroup",
    "cfsChangeInfo": [

    ],
    "customProvisions": [

    ],
    "scalableGroupExternalHandle": "cisco-jabber-control",
    "scalableGroupType": "APPLICATION",
    "securityGroupTag": 0,
    "state": "ACTIVE",
    "vnAgnostic": true,
    "identitySource": {
      "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
      "instanceId": 10010,
      "authEntityId": 10010,
      "displayName": "10010",
      "authEntityClass": 1738953278,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "state": "INACTIVE",
      "type": "NBAR"
    },
    "indicativeNetworkIdentity": [

    ],
    "networkApplications": [
      {
        "id": "7c7f241a-532e-412d-826e-c14e9a72fc79",
        "instanceId": 11193,
        "authEntityId": 11193,
        "displayName": "11193",
        "authEntityClass": -217092956,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "appProtocol": "tcp/udp",
        "applicationGroup": "cisco-jabber-group",
        "applicationType": "DEFAULT",
        "categoryId": "38a8e354-e2fe-4f3c-b1c5-2bbb90fb0d99",
        "createTime": 1517966741888,
        "encrypted": "false",
        "engineId": "13",
        "helpString": "Cisco Jabber Client; Control and Signaling",
        "ignoreConflict": false,
        "lastUpdateTime": 1517966741888,
        "longDescription": "Cisco Jabber is a unified communications client application that provides presence, instant messaging (IM), voice, and video calling capabilities on many platforms. This protocol classifies the control and signaling part of Cisco Jabber",
        "name": "cisco-jabber-control",
        "nbarId": "1498",
        "p2pTechnology": "false",
        "popularity": 10,
        "rank": 65535,
        "references": "http://www.cisco.com/web/products/voice/jabber.html",
        "selectorId": "556",
        "subCategory": "68c4f989-5735-4e16-8d1c-6a9d89d5df9c",
        "trafficClass": "SIGNALING",
        "tunnel": "false"
      }
    ],
    "networkIdentity": [
      {
        "id": "9fe3e321-91ba-4196-9556-0248c01e6cf2",
        "instanceId": 12518,
        "authEntityId": 12518,
        "displayName": "12518",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "53,80,443,5060,5353",
        "protocol": "TCP",
        "upperPort": 0
      },
      {
        "id": "cfd8c26e-c2b6-48c6-b14e-deef020dd224",
        "instanceId": 12519,
        "authEntityId": 12519,
        "displayName": "12519",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "53,5060,5353",
        "protocol": "UDP",
        "upperPort": 0
      }
    ],
    "parentScalableGroup": {
      "idRef": "60f1364d-2a02-4063-8ba1-07817abe0612"
    },
    "user": [

    ],
    "userGroup": [

    ]
  },
  {
    "id": "13dbbf3f-7693-495f-a350-4c1767280ce0",
    "instanceId": 15030,
    "authEntityId": 15030,
    "displayName": "15030",
    "authEntityClass": -1909073334,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "createTime": 1517966744066,
    "deployed": false,
    "isSeeded": true,
    "isStale": false,
    "lastUpdateTime": 1517966744066,
    "name": "rda",
    "namespace": "scalablegroup:application",
    "provisioningState": "DEFINED",
    "qualifier": "application",
    "resourceVersion": 0,
    "targetIdList": [

    ],
    "type": "scalablegroup",
    "cfsChangeInfo": [

    ],
    "customProvisions": [

    ],
    "scalableGroupExternalHandle": "rda",
    "scalableGroupType": "APPLICATION",
    "securityGroupTag": 0,
    "state": "ACTIVE",
    "vnAgnostic": true,
    "identitySource": {
      "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
      "instanceId": 10010,
      "authEntityId": 10010,
      "displayName": "10010",
      "authEntityClass": 1738953278,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "state": "INACTIVE",
      "type": "NBAR"
    },
    "indicativeNetworkIdentity": [
      {
        "id": "63771b9e-ee99-454d-b46e-5b33025d4d22",
        "instanceId": 14720,
        "authEntityId": 14720,
        "displayName": "14720",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "630",
        "protocol": "TCP",
        "upperPort": 0
      },
      {
        "id": "978e0009-efe5-44f7-8aea-f733eedab82d",
        "instanceId": 14719,
        "authEntityId": 14719,
        "displayName": "14719",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "630",
        "protocol": "UDP",
        "upperPort": 0
      }
    ],
    "networkApplications": [
      {
        "id": "5f988af9-27c5-45b1-bad6-a6847131a6a4",
        "instanceId": 11969,
        "authEntityId": 11969,
        "displayName": "11969",
        "authEntityClass": -217092956,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "appProtocol": "tcp/udp",
        "applicationGroup": "other",
        "applicationType": "DEFAULT",
        "categoryId": "cb5915d1-afec-4c30-ac9e-069ff382272f",
        "createTime": 1517966744066,
        "encrypted": "false",
        "engineId": "3",
        "helpString": "rda",
        "ignoreConflict": false,
        "lastUpdateTime": 1517966744066,
        "longDescription": "Remote Database Access (RDA) is a protocol standard for database access. RDA describes the connection of a database client to a database server. It includes features for communicating database operations and parameters from the client to the server, transporting result data from the server to the client, and database transaction management.",
        "name": "rda",
        "nbarId": "539",
        "p2pTechnology": "false",
        "popularity": 1,
        "rank": 65535,
        "references": "http://en.wikipedia.org/wiki/Remote_Database_Access",
        "selectorId": "630",
        "subCategory": "b90786f4-0190-45b8-9924-21b3e9682590",
        "trafficClass": "TRANSACTIONAL_DATA",
        "tunnel": "false"
      }
    ],
    "networkIdentity": [
      {
        "id": "93dc1cf6-fa45-4c37-8245-4b5851a6aa1d",
        "instanceId": 14717,
        "authEntityId": 14717,
        "displayName": "14717",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "630",
        "protocol": "TCP",
        "upperPort": 0
      },
      {
        "id": "905a55ec-ae05-42a3-8c5f-9a24eb72fc24",
        "instanceId": 14718,
        "authEntityId": 14718,
        "displayName": "14718",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "630",
        "protocol": "UDP",
        "upperPort": 0
      }
    ],
    "parentScalableGroup": {
      "idRef": "8718395e-683e-4a2d-a232-100202751271"
    },
    "user": [

    ],
    "userGroup": [

    ]
  },
  {
    "id": "145f9ba1-2877-4099-a72e-23f2d2f790d6",
    "instanceId": 15427,
    "authEntityId": 15427,
    "displayName": "15427",
    "authEntityClass": -1909073334,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "createTime": 1517966744946,
    "deployed": false,
    "isSeeded": true,
    "isStale": false,
    "lastUpdateTime": 1517966744946,
    "name": "wpgs",
    "namespace": "scalablegroup:application",
    "provisioningState": "DEFINED",
    "qualifier": "application",
    "resourceVersion": 0,
    "targetIdList": [

    ],
    "type": "scalablegroup",
    "cfsChangeInfo": [

    ],
    "customProvisions": [

    ],
    "scalableGroupExternalHandle": "wpgs",
    "scalableGroupType": "APPLICATION",
    "securityGroupTag": 0,
    "state": "ACTIVE",
    "vnAgnostic": true,
    "identitySource": {
      "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
      "instanceId": 10010,
      "authEntityId": 10010,
      "displayName": "10010",
      "authEntityClass": 1738953278,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "state": "INACTIVE",
      "type": "NBAR"
    },
    "indicativeNetworkIdentity": [
      {
        "id": "0da5fd9a-a248-496a-86e3-bd864b54b090",
        "instanceId": 17865,
        "authEntityId": 17865,
        "displayName": "17865",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "780",
        "protocol": "TCP",
        "upperPort": 0
      },
      {
        "id": "00454e69-980a-4183-b441-8383598689d6",
        "instanceId": 17866,
        "authEntityId": 17866,
        "displayName": "17866",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "780",
        "protocol": "UDP",
        "upperPort": 0
      }
    ],
    "networkApplications": [
      {
        "id": "56e892a9-4fce-48b1-b18f-0c18e0876352",
        "instanceId": 16370,
        "authEntityId": 16370,
        "displayName": "16370",
        "authEntityClass": -217092956,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "appProtocol": "tcp/udp",
        "applicationGroup": "other",
        "applicationType": "DEFAULT",
        "categoryId": "6daa3dfd-3526-407a-88f0-97824b3f3a24",
        "createTime": 1517966744946,
        "encrypted": "false",
        "engineId": "3",
        "helpString": "wpgs",
        "ignoreConflict": false,
        "lastUpdateTime": 1517966744946,
        "longDescription": "Registered with IANA on port 780 TCP/UDP",
        "name": "wpgs",
        "nbarId": "651",
        "p2pTechnology": "false",
        "popularity": 1,
        "rank": 65535,
        "references": "http://www.iana.org/assignments/service-names-port-numbers/service-names-port-numbers.xml",
        "selectorId": "780",
        "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
        "trafficClass": "BULK_DATA",
        "tunnel": "false"
      }
    ],
    "networkIdentity": [
      {
        "id": "888b8004-8041-41b9-bc56-12254ce213c5",
        "instanceId": 17863,
        "authEntityId": 17863,
        "displayName": "17863",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "780",
        "protocol": "TCP",
        "upperPort": 0
      },
      {
        "id": "ad9ac1e5-eb47-4a63-86c6-fc94cb1c36e9",
        "instanceId": 17864,
        "authEntityId": 17864,
        "displayName": "17864",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "780",
        "protocol": "UDP",
        "upperPort": 0
      }
    ],
    "parentScalableGroup": {
      "idRef": "000c15bf-28fe-4fbb-9b69-35e6502721f1"
    },
    "user": [

    ],
    "userGroup": [

    ]
  },
  {
    "id": "14b91da2-4d53-4213-9dc4-8c213c18ad45",
    "instanceId": 15390,
    "authEntityId": 15390,
    "displayName": "15390",
    "authEntityClass": -1909073334,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "createTime": 1517966744858,
    "deployed": false,
    "isSeeded": true,
    "isStale": false,
    "lastUpdateTime": 1517966744858,
    "name": "wb-expak",
    "namespace": "scalablegroup:application",
    "provisioningState": "DEFINED",
    "qualifier": "application",
    "resourceVersion": 0,
    "targetIdList": [

    ],
    "type": "scalablegroup",
    "cfsChangeInfo": [

    ],
    "customProvisions": [

    ],
    "scalableGroupExternalHandle": "wb-expak",
    "scalableGroupType": "APPLICATION",
    "securityGroupTag": 0,
    "state": "ACTIVE",
    "vnAgnostic": true,
    "identitySource": {
      "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
      "instanceId": 10010,
      "authEntityId": 10010,
      "displayName": "10010",
      "authEntityClass": 1738953278,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "state": "INACTIVE",
      "type": "NBAR"
    },
    "indicativeNetworkIdentity": [
      {
        "id": "a4269e71-d646-4d94-bed8-e7cb4edbc03a",
        "instanceId": 17792,
        "authEntityId": 17792,
        "displayName": "17792",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipProtocolNumbers": "79",
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "protocol": "WB_EXPAK",
        "upperPort": 0
      }
    ],
    "networkApplications": [
      {
        "id": "f074c1bd-d18f-4df1-ac08-fe1e10048257",
        "instanceId": 16333,
        "authEntityId": 16333,
        "displayName": "16333",
        "authEntityClass": -217092956,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "appProtocol": "ip",
        "applicationGroup": "other",
        "applicationType": "DEFAULT",
        "categoryId": "6daa3dfd-3526-407a-88f0-97824b3f3a24",
        "createTime": 1517966744858,
        "encrypted": "unassigned",
        "engineId": "1",
        "helpString": "WIDEBAND EXPAK",
        "ignoreConflict": false,
        "lastUpdateTime": 1517966744858,
        "longDescription": "Registered with IANA as IP Protocol 79",
        "name": "wb-expak",
        "nbarId": "833",
        "p2pTechnology": "unassigned",
        "popularity": 1,
        "rank": 65535,
        "references": "http://www.iana.org/assignments/protocol-numbers/protocol-numbers.xml",
        "selectorId": "79",
        "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
        "trafficClass": "BULK_DATA",
        "tunnel": "unassigned"
      }
    ],
    "networkIdentity": [
      {
        "id": "fa67bd46-6f76-4d4a-b30b-b13a5d94679c",
        "instanceId": 17791,
        "authEntityId": 17791,
        "displayName": "17791",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipProtocolNumbers": "79",
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "protocol": "WB_EXPAK",
        "upperPort": 0
      }
    ],
    "parentScalableGroup": {
      "idRef": "000c15bf-28fe-4fbb-9b69-35e6502721f1"
    },
    "user": [

    ],
    "userGroup": [

    ]
  },
  {
    "id": "14d675ff-62ab-498f-a13a-28fe25a12445",
    "instanceId": 4145,
    "authEntityId": 4145,
    "displayName": "4145",
    "authEntityClass": -1909073334,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "createTime": 1517966741375,
    "deployed": false,
    "isSeeded": true,
    "isStale": false,
    "lastUpdateTime": 1517966741375,
    "name": "aurp",
    "namespace": "scalablegroup:application",
    "provisioningState": "DEFINED",
    "qualifier": "application",
    "resourceVersion": 0,
    "targetIdList": [

    ],
    "type": "scalablegroup",
    "cfsChangeInfo": [

    ],
    "customProvisions": [

    ],
    "scalableGroupExternalHandle": "aurp",
    "scalableGroupType": "APPLICATION",
    "securityGroupTag": 0,
    "state": "ACTIVE",
    "vnAgnostic": true,
    "identitySource": {
      "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
      "instanceId": 10010,
      "authEntityId": 10010,
      "displayName": "10010",
      "authEntityClass": 1738953278,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "state": "INACTIVE",
      "type": "NBAR"
    },
    "indicativeNetworkIdentity": [
      {
        "id": "36aff085-7ce0-4289-87fc-f579e9b324ec",
        "instanceId": 12296,
        "authEntityId": 12296,
        "displayName": "12296",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "387",
        "protocol": "UDP",
        "upperPort": 0
      },
      {
        "id": "588e1392-c650-46a8-9a5d-abe6571e37f4",
        "instanceId": 12297,
        "authEntityId": 12297,
        "displayName": "12297",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "387",
        "protocol": "TCP",
        "upperPort": 0
      }
    ],
    "networkApplications": [
      {
        "id": "88965247-362a-4f65-bbf4-c401f010867f",
        "instanceId": 11106,
        "authEntityId": 11106,
        "displayName": "11106",
        "authEntityClass": -217092956,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "appProtocol": "tcp/udp",
        "applicationGroup": "other",
        "applicationType": "DEFAULT",
        "categoryId": "1fc17362-9334-4c7b-b752-5afdbb574017",
        "createTime": 1517966741375,
        "encrypted": "false",
        "engineId": "3",
        "helpString": "AppleTalk Update-Based Routing Pro.",
        "ignoreConflict": false,
        "lastUpdateTime": 1517966741375,
        "longDescription": "The AppleTalk Update-based Routing Protocol (AURP) provides wide area routing enhancements to the AppleTalk routing protocols and is fully compatible with AppleTalk Phase 2.",
        "name": "aurp",
        "nbarId": "303",
        "p2pTechnology": "false",
        "popularity": 1,
        "rank": 65535,
        "references": "http://tools.ietf.org/html/rfc1504",
        "selectorId": "387",
        "subCategory": "7cc1b3a6-4102-44e9-b522-0e71afe39a03",
        "trafficClass": "NETWORK_CONTROL",
        "tunnel": "true"
      }
    ],
    "networkIdentity": [
      {
        "id": "462a296c-a045-47f8-aeef-730cb61f4f0a",
        "instanceId": 12294,
        "authEntityId": 12294,
        "displayName": "12294",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "387",
        "protocol": "TCP",
        "upperPort": 0
      },
      {
        "id": "8e4707d0-8f22-4dcb-89d2-fed2ba25f3c2",
        "instanceId": 12295,
        "authEntityId": 12295,
        "displayName": "12295",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "387",
        "protocol": "UDP",
        "upperPort": 0
      }
    ],
    "parentScalableGroup": {
      "idRef": "77e75842-e98b-4112-ad0c-300db4bb11be"
    },
    "user": [

    ],
    "userGroup": [

    ]
  },
  {
    "id": "14d8aa0a-250d-4fb1-bc5b-8371e0319db2",
    "instanceId": 15040,
    "authEntityId": 15040,
    "displayName": "15040",
    "authEntityClass": -1909073334,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "createTime": 1517966744090,
    "deployed": false,
    "isSeeded": true,
    "isStale": false,
    "lastUpdateTime": 1517966744090,
    "name": "remotefs",
    "namespace": "scalablegroup:application",
    "provisioningState": "DEFINED",
    "qualifier": "application",
    "resourceVersion": 0,
    "targetIdList": [

    ],
    "type": "scalablegroup",
    "cfsChangeInfo": [

    ],
    "customProvisions": [

    ],
    "scalableGroupExternalHandle": "remotefs",
    "scalableGroupType": "APPLICATION",
    "securityGroupTag": 0,
    "state": "ACTIVE",
    "vnAgnostic": true,
    "identitySource": {
      "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
      "instanceId": 10010,
      "authEntityId": 10010,
      "displayName": "10010",
      "authEntityClass": 1738953278,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "state": "INACTIVE",
      "type": "NBAR"
    },
    "indicativeNetworkIdentity": [
      {
        "id": "65b1060e-3ac4-47e1-b26e-a8916e8a4294",
        "instanceId": 14749,
        "authEntityId": 14749,
        "displayName": "14749",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "556",
        "protocol": "UDP",
        "upperPort": 0
      },
      {
        "id": "7b3e77d0-d7de-44aa-a2ab-d5afdb9d8d93",
        "instanceId": 14750,
        "authEntityId": 14750,
        "displayName": "14750",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "556",
        "protocol": "TCP",
        "upperPort": 0
      }
    ],
    "networkApplications": [
      {
        "id": "f8d64688-43ec-4fdf-8c9b-3e176540efa1",
        "instanceId": 11979,
        "authEntityId": 11979,
        "displayName": "11979",
        "authEntityClass": -217092956,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "appProtocol": "tcp/udp",
        "applicationGroup": "other",
        "applicationType": "DEFAULT",
        "categoryId": "e6619ce8-97de-4c63-a53a-cceddbddc1a8",
        "createTime": 1517966744090,
        "encrypted": "false",
        "engineId": "3",
        "helpString": "rfs server",
        "ignoreConflict": false,
        "lastUpdateTime": 1517966744090,
        "longDescription": "RemoteFS is a network file system designed for use with home NAS. Simple to use, few dependencies, reasonable security, completely in user space.",
        "name": "remotefs",
        "nbarId": "471",
        "p2pTechnology": "false",
        "popularity": 1,
        "rank": 65535,
        "references": "http://remotefs.sourceforge.net/",
        "selectorId": "556",
        "subCategory": "50b55e2e-d0d0-4404-bea4-819351565d90",
        "trafficClass": "BULK_DATA",
        "tunnel": "false"
      }
    ],
    "networkIdentity": [
      {
        "id": "ed896f9a-fc8c-40db-ba40-76082d265f20",
        "instanceId": 14747,
        "authEntityId": 14747,
        "displayName": "14747",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "556",
        "protocol": "TCP",
        "upperPort": 0
      },
      {
        "id": "2b2f1944-bf2c-4cf0-9dc9-fc52900026df",
        "instanceId": 14748,
        "authEntityId": 14748,
        "displayName": "14748",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "556",
        "protocol": "UDP",
        "upperPort": 0
      }
    ],
    "parentScalableGroup": {
      "idRef": "383363b1-4dd5-49d1-bfe4-34daac437cfb"
    },
    "user": [

    ],
    "userGroup": [

    ]
  },
  {
    "id": "15168a14-4778-4130-b0eb-3f09570e9334",
    "instanceId": 4493,
    "authEntityId": 4493,
    "displayName": "4493",
    "authEntityClass": -1909073334,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "createTime": 1517966742807,
    "deployed": false,
    "isSeeded": true,
    "isStale": false,
    "lastUpdateTime": 1517966742807,
    "name": "goodreads",
    "namespace": "scalablegroup:application",
    "provisioningState": "DEFINED",
    "qualifier": "application",
    "resourceVersion": 0,
    "targetIdList": [

    ],
    "type": "scalablegroup",
    "cfsChangeInfo": [

    ],
    "customProvisions": [

    ],
    "scalableGroupExternalHandle": "goodreads",
    "scalableGroupType": "APPLICATION",
    "securityGroupTag": 0,
    "state": "ACTIVE",
    "vnAgnostic": true,
    "identitySource": {
      "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
      "instanceId": 10010,
      "authEntityId": 10010,
      "displayName": "10010",
      "authEntityClass": 1738953278,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "state": "INACTIVE",
      "type": "NBAR"
    },
    "indicativeNetworkIdentity": [

    ],
    "networkApplications": [
      {
        "id": "79c711c1-e293-4062-b988-a1c78ea63d62",
        "instanceId": 11444,
        "authEntityId": 11444,
        "displayName": "11444",
        "authEntityClass": -217092956,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "appProtocol": "tcp/udp",
        "applicationGroup": "other",
        "applicationType": "DEFAULT",
        "categoryId": "878644b5-fe91-45b4-9204-31c84a73ba48",
        "createTime": 1517966742807,
        "encrypted": "false",
        "engineId": "13",
        "helpString": "Goodreads - Book review and cataloging.",
        "ignoreConflict": false,
        "lastUpdateTime": 1517966742807,
        "longDescription": "Goodreads - Book review and cataloging.",
        "name": "goodreads",
        "nbarId": "1599",
        "p2pTechnology": "false",
        "popularity": 3,
        "rank": 65535,
        "references": "http://goodreads.com",
        "selectorId": "670",
        "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
        "trafficClass": "TRANSACTIONAL_DATA",
        "tunnel": "false"
      }
    ],
    "networkIdentity": [
      {
        "id": "e6d53def-4eb2-4579-b36f-cf8585576efe",
        "instanceId": 13230,
        "authEntityId": 13230,
        "displayName": "13230",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "53,80,443,5353",
        "protocol": "TCP",
        "upperPort": 0
      },
      {
        "id": "48210e7b-1f19-4063-8ab1-6aad596ef7a9",
        "instanceId": 13231,
        "authEntityId": 13231,
        "displayName": "13231",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "53,5353",
        "protocol": "UDP",
        "upperPort": 0
      }
    ],
    "parentScalableGroup": {
      "idRef": "28e08de4-d7b2-4521-84ce-bc1e5ee0b9cc"
    },
    "user": [

    ],
    "userGroup": [

    ]
  },
  {
    "id": "1546f33d-534a-4ecd-baef-f099cb170958",
    "instanceId": 15342,
    "authEntityId": 15342,
    "displayName": "15342",
    "authEntityClass": -1909073334,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "createTime": 1517966744772,
    "deployed": false,
    "isSeeded": true,
    "isStale": false,
    "lastUpdateTime": 1517966744772,
    "name": "vacdsm-sws",
    "namespace": "scalablegroup:application",
    "provisioningState": "DEFINED",
    "qualifier": "application",
    "resourceVersion": 0,
    "targetIdList": [

    ],
    "type": "scalablegroup",
    "cfsChangeInfo": [

    ],
    "customProvisions": [

    ],
    "scalableGroupExternalHandle": "vacdsm-sws",
    "scalableGroupType": "APPLICATION",
    "securityGroupTag": 0,
    "state": "ACTIVE",
    "vnAgnostic": true,
    "identitySource": {
      "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
      "instanceId": 10010,
      "authEntityId": 10010,
      "displayName": "10010",
      "authEntityClass": 1738953278,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "state": "INACTIVE",
      "type": "NBAR"
    },
    "indicativeNetworkIdentity": [
      {
        "id": "47292cd9-14b1-43fe-ace9-b308f9f00fc9",
        "instanceId": 17649,
        "authEntityId": 17649,
        "displayName": "17649",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "670",
        "protocol": "UDP",
        "upperPort": 0
      },
      {
        "id": "1bf09173-528d-479b-bc05-446148ac0446",
        "instanceId": 17650,
        "authEntityId": 17650,
        "displayName": "17650",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "670",
        "protocol": "TCP",
        "upperPort": 0
      }
    ],
    "networkApplications": [
      {
        "id": "427ebfbb-d779-415d-a9ee-fa1c40d901e0",
        "instanceId": 16285,
        "authEntityId": 16285,
        "displayName": "16285",
        "authEntityClass": -217092956,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "appProtocol": "tcp/udp",
        "applicationGroup": "other",
        "applicationType": "DEFAULT",
        "categoryId": "6daa3dfd-3526-407a-88f0-97824b3f3a24",
        "createTime": 1517966744772,
        "encrypted": "false",
        "engineId": "3",
        "helpString": "VACDSM-SWS",
        "ignoreConflict": false,
        "lastUpdateTime": 1517966744772,
        "longDescription": "Registered with IANA on port 670 TCP/UDP",
        "name": "vacdsm-sws",
        "nbarId": "578",
        "p2pTechnology": "false",
        "popularity": 1,
        "rank": 65535,
        "references": "http://www.iana.org/assignments/service-names-port-numbers/service-names-port-numbers.xml",
        "selectorId": "670",
        "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
        "trafficClass": "BULK_DATA",
        "tunnel": "false"
      }
    ],
    "networkIdentity": [
      {
        "id": "3c0c6ca5-1eed-4bc4-9dcf-4ec3dfc6038f",
        "instanceId": 17648,
        "authEntityId": 17648,
        "displayName": "17648",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "670",
        "protocol": "TCP",
        "upperPort": 0
      },
      {
        "id": "a600984f-7110-49b5-8a1b-8a5f7ba27ef4",
        "instanceId": 17647,
        "authEntityId": 17647,
        "displayName": "17647",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "670",
        "protocol": "UDP",
        "upperPort": 0
      }
    ],
    "parentScalableGroup": {
      "idRef": "000c15bf-28fe-4fbb-9b69-35e6502721f1"
    },
    "user": [

    ],
    "userGroup": [

    ]
  },
  {
    "id": "157f15c8-9be4-4760-9ea8-d618787b59d6",
    "instanceId": 15313,
    "authEntityId": 15313,
    "displayName": "15313",
    "authEntityClass": -1909073334,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "createTime": 1517966744720,
    "deployed": false,
    "isSeeded": true,
    "isStale": false,
    "lastUpdateTime": 1517966744720,
    "name": "typepad",
    "namespace": "scalablegroup:application",
    "provisioningState": "DEFINED",
    "qualifier": "application",
    "resourceVersion": 0,
    "targetIdList": [

    ],
    "type": "scalablegroup",
    "cfsChangeInfo": [

    ],
    "customProvisions": [

    ],
    "scalableGroupExternalHandle": "typepad",
    "scalableGroupType": "APPLICATION",
    "securityGroupTag": 0,
    "state": "ACTIVE",
    "vnAgnostic": true,
    "identitySource": {
      "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
      "instanceId": 10010,
      "authEntityId": 10010,
      "displayName": "10010",
      "authEntityClass": 1738953278,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "state": "INACTIVE",
      "type": "NBAR"
    },
    "indicativeNetworkIdentity": [

    ],
    "networkApplications": [
      {
        "id": "93342d32-63a8-4c09-a052-006c58a06d13",
        "instanceId": 16256,
        "authEntityId": 16256,
        "displayName": "16256",
        "authEntityClass": -217092956,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "appProtocol": "tcp/udp",
        "applicationGroup": "other",
        "applicationType": "DEFAULT",
        "categoryId": "878644b5-fe91-45b4-9204-31c84a73ba48",
        "createTime": 1517966744720,
        "encrypted": "false",
        "engineId": "13",
        "helpString": "TypePad - Blogging service website.",
        "ignoreConflict": false,
        "lastUpdateTime": 1517966744720,
        "longDescription": "TypePad - Blogging service website.",
        "name": "typepad",
        "nbarId": "1719",
        "p2pTechnology": "false",
        "popularity": 3,
        "rank": 65535,
        "references": "http://typepad.com",
        "selectorId": "791",
        "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
        "trafficClass": "TRANSACTIONAL_DATA",
        "tunnel": "false"
      }
    ],
    "networkIdentity": [
      {
        "id": "566597ae-f0da-4e6d-bc3e-579084256e01",
        "instanceId": 17555,
        "authEntityId": 17555,
        "displayName": "17555",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "53,80,443,5353",
        "protocol": "TCP",
        "upperPort": 0
      },
      {
        "id": "3485988e-90e4-49d9-8db3-6f6392fe9920",
        "instanceId": 17556,
        "authEntityId": 17556,
        "displayName": "17556",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "53,5353",
        "protocol": "UDP",
        "upperPort": 0
      }
    ],
    "parentScalableGroup": {
      "idRef": "28e08de4-d7b2-4521-84ce-bc1e5ee0b9cc"
    },
    "user": [

    ],
    "userGroup": [

    ]
  },
  {
    "id": "1580c71d-73a0-477e-bc44-e412ca018737",
    "instanceId": 15237,
    "authEntityId": 15237,
    "displayName": "15237",
    "authEntityClass": -1909073334,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "createTime": 1517966744554,
    "deployed": false,
    "isSeeded": true,
    "isStale": false,
    "lastUpdateTime": 1517966744554,
    "name": "svrloc",
    "namespace": "scalablegroup:application",
    "provisioningState": "DEFINED",
    "qualifier": "application",
    "resourceVersion": 0,
    "targetIdList": [

    ],
    "type": "scalablegroup",
    "cfsChangeInfo": [

    ],
    "customProvisions": [

    ],
    "scalableGroupExternalHandle": "svrloc",
    "scalableGroupType": "APPLICATION",
    "securityGroupTag": 0,
    "state": "ACTIVE",
    "vnAgnostic": true,
    "identitySource": {
      "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
      "instanceId": 10010,
      "authEntityId": 10010,
      "displayName": "10010",
      "authEntityClass": 1738953278,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "state": "INACTIVE",
      "type": "NBAR"
    },
    "indicativeNetworkIdentity": [
      {
        "id": "6973a2e6-322b-40b3-8253-9de42fb06281",
        "instanceId": 17350,
        "authEntityId": 17350,
        "displayName": "17350",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "427",
        "protocol": "TCP",
        "upperPort": 0
      },
      {
        "id": "50d56d17-cf1e-49fa-8de3-7605f4d99f1f",
        "instanceId": 17351,
        "authEntityId": 17351,
        "displayName": "17351",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "427",
        "protocol": "UDP",
        "upperPort": 0
      }
    ],
    "networkApplications": [
      {
        "id": "5eb87fed-b108-4c30-8200-83edbff108cb",
        "instanceId": 16180,
        "authEntityId": 16180,
        "displayName": "16180",
        "authEntityClass": -217092956,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "appProtocol": "tcp/udp",
        "applicationGroup": "other",
        "applicationType": "DEFAULT",
        "categoryId": "1fc17362-9334-4c7b-b752-5afdbb574017",
        "createTime": 1517966744554,
        "encrypted": "unassigned",
        "engineId": "3",
        "helpString": "Server Location",
        "ignoreConflict": false,
        "lastUpdateTime": 1517966744554,
        "longDescription": "The Service Location Protocol (SLP, srvloc) is a service discovery protocol that allows computers and other devices to find services in a local area network without prior configuration. SLP has been designed to scale from small, unmanaged networks to large enterprise networks.",
        "name": "svrloc",
        "nbarId": "342",
        "p2pTechnology": "unassigned",
        "popularity": 1,
        "rank": 65535,
        "references": "http://www.ietf.org/rfc/rfc2608.txt",
        "selectorId": "427",
        "subCategory": "68c4f989-5735-4e16-8d1c-6a9d89d5df9c",
        "trafficClass": "SIGNALING",
        "tunnel": "unassigned"
      }
    ],
    "networkIdentity": [
      {
        "id": "bd68aa5e-db6e-4789-882f-caf4e0444d18",
        "instanceId": 17348,
        "authEntityId": 17348,
        "displayName": "17348",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "427",
        "protocol": "TCP",
        "upperPort": 0
      },
      {
        "id": "5011b1e2-fc58-42a1-9ae0-50da695bf998",
        "instanceId": 17349,
        "authEntityId": 17349,
        "displayName": "17349",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "427",
        "protocol": "UDP",
        "upperPort": 0
      }
    ],
    "parentScalableGroup": {
      "idRef": "a3243a74-3864-4041-a927-0201030da17c"
    },
    "user": [

    ],
    "userGroup": [

    ]
  },
  {
    "id": "15f10982-d34c-4965-9b4d-09bf009baf12",
    "instanceId": 4308,
    "authEntityId": 4308,
    "displayName": "4308",
    "authEntityClass": -1909073334,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "createTime": 1517966742227,
    "deployed": false,
    "isSeeded": true,
    "isStale": false,
    "lastUpdateTime": 1517966742227,
    "name": "csi-sgwp",
    "namespace": "scalablegroup:application",
    "provisioningState": "DEFINED",
    "qualifier": "application",
    "resourceVersion": 0,
    "targetIdList": [

    ],
    "type": "scalablegroup",
    "cfsChangeInfo": [

    ],
    "customProvisions": [

    ],
    "scalableGroupExternalHandle": "csi-sgwp",
    "scalableGroupType": "APPLICATION",
    "securityGroupTag": 0,
    "state": "ACTIVE",
    "vnAgnostic": true,
    "identitySource": {
      "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
      "instanceId": 10010,
      "authEntityId": 10010,
      "displayName": "10010",
      "authEntityClass": 1738953278,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "state": "INACTIVE",
      "type": "NBAR"
    },
    "indicativeNetworkIdentity": [
      {
        "id": "4a6f4231-6dff-4bed-a57c-b272849caedb",
        "instanceId": 12696,
        "authEntityId": 12696,
        "displayName": "12696",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "348",
        "protocol": "UDP",
        "upperPort": 0
      },
      {
        "id": "98e883f3-89a4-4ab7-a1df-1518844acec1",
        "instanceId": 12697,
        "authEntityId": 12697,
        "displayName": "12697",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "348",
        "protocol": "TCP",
        "upperPort": 0
      }
    ],
    "networkApplications": [
      {
        "id": "3f2e8ddd-9762-4461-a861-e478aa61a22a",
        "instanceId": 11261,
        "authEntityId": 11261,
        "displayName": "11261",
        "authEntityClass": -217092956,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "appProtocol": "tcp/udp",
        "applicationGroup": "other",
        "applicationType": "DEFAULT",
        "categoryId": "6daa3dfd-3526-407a-88f0-97824b3f3a24",
        "createTime": 1517966742227,
        "encrypted": "false",
        "engineId": "3",
        "helpString": "Cabletron Management Protocol",
        "ignoreConflict": false,
        "lastUpdateTime": 1517966742227,
        "longDescription": "Registered with IANA on port 348 TCP/UDP",
        "name": "csi-sgwp",
        "nbarId": "915",
        "p2pTechnology": "false",
        "popularity": 1,
        "rank": 65535,
        "references": "http://www.iana.org/assignments/service-names-port-numbers/service-names-port-numbers.xml",
        "selectorId": "348",
        "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
        "trafficClass": "BULK_DATA",
        "tunnel": "false"
      }
    ],
    "networkIdentity": [
      {
        "id": "d0e0ba64-9938-4d2f-aae6-41f803cd4bfe",
        "instanceId": 12694,
        "authEntityId": 12694,
        "displayName": "12694",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "348",
        "protocol": "UDP",
        "upperPort": 0
      },
      {
        "id": "8674faed-5560-4ab7-83ec-9edb658ea0d2",
        "instanceId": 12695,
        "authEntityId": 12695,
        "displayName": "12695",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "348",
        "protocol": "TCP",
        "upperPort": 0
      }
    ],
    "parentScalableGroup": {
      "idRef": "000c15bf-28fe-4fbb-9b69-35e6502721f1"
    },
    "user": [

    ],
    "userGroup": [

    ]
  },
  {
    "id": "15f553c6-bc44-479e-899d-f5050394b366",
    "instanceId": 4207,
    "authEntityId": 4207,
    "displayName": "4207",
    "authEntityClass": -1909073334,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "createTime": 1517966741724,
    "deployed": false,
    "isSeeded": true,
    "isStale": false,
    "lastUpdateTime": 1517966741724,
    "name": "cadlock",
    "namespace": "scalablegroup:application",
    "provisioningState": "DEFINED",
    "qualifier": "application",
    "resourceVersion": 0,
    "targetIdList": [

    ],
    "type": "scalablegroup",
    "cfsChangeInfo": [

    ],
    "customProvisions": [

    ],
    "scalableGroupExternalHandle": "cadlock",
    "scalableGroupType": "APPLICATION",
    "securityGroupTag": 0,
    "state": "ACTIVE",
    "vnAgnostic": true,
    "identitySource": {
      "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
      "instanceId": 10010,
      "authEntityId": 10010,
      "displayName": "10010",
      "authEntityClass": 1738953278,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "state": "INACTIVE",
      "type": "NBAR"
    },
    "indicativeNetworkIdentity": [
      {
        "id": "8dd75722-ed01-40b4-825e-94a07a223037",
        "instanceId": 12451,
        "authEntityId": 12451,
        "displayName": "12451",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "770",
        "protocol": "TCP",
        "upperPort": 0
      },
      {
        "id": "a95b0eef-33d7-4205-b3b1-f777c77c34cb",
        "instanceId": 12452,
        "authEntityId": 12452,
        "displayName": "12452",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "770",
        "protocol": "UDP",
        "upperPort": 0
      }
    ],
    "networkApplications": [
      {
        "id": "a9c7689e-230f-4f35-92b6-44bfecec4137",
        "instanceId": 11163,
        "authEntityId": 11163,
        "displayName": "11163",
        "authEntityClass": -217092956,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "appProtocol": "tcp/udp",
        "applicationGroup": "other",
        "applicationType": "DEFAULT",
        "categoryId": "6daa3dfd-3526-407a-88f0-97824b3f3a24",
        "createTime": 1517966741724,
        "encrypted": "false",
        "engineId": "3",
        "helpString": "cadlock",
        "ignoreConflict": false,
        "lastUpdateTime": 1517966741724,
        "longDescription": "Cadlock is used to access AutoCad drawings protected by CadVault, a CadLock Incorporated product for digital rights management of graphical and non-graphical elements within AutoCAD drawing files.",
        "name": "cadlock",
        "nbarId": "640",
        "p2pTechnology": "false",
        "popularity": 1,
        "rank": 65535,
        "references": "http://www.cadlock.com/",
        "selectorId": "770",
        "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
        "trafficClass": "BULK_DATA",
        "tunnel": "false"
      }
    ],
    "networkIdentity": [
      {
        "id": "1185cea4-332e-4197-8f80-c8f54121d36f",
        "instanceId": 12449,
        "authEntityId": 12449,
        "displayName": "12449",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "770",
        "protocol": "UDP",
        "upperPort": 0
      },
      {
        "id": "ea542816-2757-4c6b-85a1-51098951e96d",
        "instanceId": 12450,
        "authEntityId": 12450,
        "displayName": "12450",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "770",
        "protocol": "TCP",
        "upperPort": 0
      }
    ],
    "parentScalableGroup": {
      "idRef": "000c15bf-28fe-4fbb-9b69-35e6502721f1"
    },
    "user": [

    ],
    "userGroup": [

    ]
  },
  {
    "id": "1635fe23-272f-49f6-b8ce-b666529c9309",
    "instanceId": 4839,
    "authEntityId": 4839,
    "displayName": "4839",
    "authEntityClass": -1909073334,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "createTime": 1517966743592,
    "deployed": false,
    "isSeeded": true,
    "isStale": false,
    "lastUpdateTime": 1517966743592,
    "name": "netflix",
    "namespace": "scalablegroup:application",
    "provisioningState": "DEFINED",
    "qualifier": "application",
    "resourceVersion": 0,
    "targetIdList": [

    ],
    "type": "scalablegroup",
    "cfsChangeInfo": [

    ],
    "customProvisions": [

    ],
    "scalableGroupExternalHandle": "netflix",
    "scalableGroupType": "APPLICATION",
    "securityGroupTag": 0,
    "state": "ACTIVE",
    "vnAgnostic": true,
    "identitySource": {
      "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
      "instanceId": 10010,
      "authEntityId": 10010,
      "displayName": "10010",
      "authEntityClass": 1738953278,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "state": "INACTIVE",
      "type": "NBAR"
    },
    "indicativeNetworkIdentity": [

    ],
    "networkApplications": [
      {
        "id": "7abcce54-8d1e-4c6a-acc0-94fead8c11d1",
        "instanceId": 11788,
        "authEntityId": 11788,
        "displayName": "11788",
        "authEntityClass": -217092956,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "appProtocol": "tcp/udp",
        "applicationGroup": "other",
        "applicationType": "DEFAULT",
        "categoryId": "a09ef1b3-ba26-4372-aee8-309d50697e76",
        "createTime": 1517966743592,
        "encrypted": "true",
        "engineId": "13",
        "helpString": "Netflix online video streaming and browsing",
        "ignoreConflict": false,
        "lastUpdateTime": 1517966743592,
        "longDescription": "Netflix is an American provider of on-demand internet streaming media in the United States, Canada, and Latin America. Netflix on-demand Internet video streaming (\"Watch Instantly\") works on Windows or Mac OS X and compatible devices. The services underlying protocols are HTTP and SSL.",
        "name": "netflix",
        "nbarId": "1316",
        "p2pTechnology": "false",
        "popularity": 10,
        "rank": 65535,
        "references": "http://www.netflix.com/",
        "selectorId": "457",
        "subCategory": "85a797c0-aef8-4284-91a7-677f33e29abb",
        "trafficClass": "MULTIMEDIA_STREAMING",
        "tunnel": "false"
      }
    ],
    "networkIdentity": [
      {
        "id": "593bdf40-dccf-43d5-87af-1980c029ce48",
        "instanceId": 14146,
        "authEntityId": 14146,
        "displayName": "14146",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "53,5353",
        "protocol": "UDP",
        "upperPort": 0
      },
      {
        "id": "8b1226dc-9667-447d-b26a-cb7fafd72a0b",
        "instanceId": 14147,
        "authEntityId": 14147,
        "displayName": "14147",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "53,80,443,5353",
        "protocol": "TCP",
        "upperPort": 0
      }
    ],
    "parentScalableGroup": {
      "idRef": "fd6264c9-c16a-4311-a335-a3db039256f3"
    },
    "user": [

    ],
    "userGroup": [

    ]
  },
  {
    "id": "16ac5df6-4ac8-43fa-93b8-c9438994b6f9",
    "instanceId": 4817,
    "authEntityId": 4817,
    "displayName": "4817",
    "authEntityClass": -1909073334,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "createTime": 1517966743550,
    "deployed": false,
    "isSeeded": true,
    "isStale": false,
    "lastUpdateTime": 1517966743550,
    "name": "napster",
    "namespace": "scalablegroup:application",
    "provisioningState": "DEFINED",
    "qualifier": "application",
    "resourceVersion": 0,
    "targetIdList": [

    ],
    "type": "scalablegroup",
    "cfsChangeInfo": [

    ],
    "customProvisions": [

    ],
    "scalableGroupExternalHandle": "napster",
    "scalableGroupType": "APPLICATION",
    "securityGroupTag": 0,
    "state": "ACTIVE",
    "vnAgnostic": true,
    "identitySource": {
      "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
      "instanceId": 10010,
      "authEntityId": 10010,
      "displayName": "10010",
      "authEntityClass": 1738953278,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "state": "INACTIVE",
      "type": "NBAR"
    },
    "indicativeNetworkIdentity": [

    ],
    "networkApplications": [
      {
        "id": "ed111d24-1174-4c74-a560-c29364f918b9",
        "instanceId": 11766,
        "authEntityId": 11766,
        "displayName": "11766",
        "authEntityClass": -217092956,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "appProtocol": "tcp",
        "applicationGroup": "other",
        "applicationType": "DEFAULT",
        "categoryId": "a09ef1b3-ba26-4372-aee8-309d50697e76",
        "createTime": 1517966743550,
        "encrypted": "false",
        "engineId": "13",
        "helpString": "Commercial Media Distribution Application",
        "ignoreConflict": false,
        "lastUpdateTime": 1517966743550,
        "longDescription": "Napster is an online music store. Users can hear online music and radio without downloading content to a local device. It's supported on various mobile platforms:  iOS products and Blackberry.",
        "name": "napster",
        "nbarId": "462",
        "p2pTechnology": "true",
        "popularity": 6,
        "rank": 65535,
        "references": "http://www.rhapsody.com/napster",
        "selectorId": "268",
        "subCategory": "bf1b9d2c-aa52-4f37-8860-40d4603319f5",
        "trafficClass": "MULTIMEDIA_STREAMING",
        "tunnel": "false"
      }
    ],
    "networkIdentity": [
      {
        "id": "bd31236a-8246-4c17-a411-f197b5908028",
        "instanceId": 14087,
        "authEntityId": 14087,
        "displayName": "14087",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "80",
        "protocol": "TCP",
        "upperPort": 0
      }
    ],
    "parentScalableGroup": {
      "idRef": "fd6264c9-c16a-4311-a335-a3db039256f3"
    },
    "user": [

    ],
    "userGroup": [

    ]
  },
  {
    "id": "16ba6f31-73fa-4c2e-a443-10052adcc20c",
    "instanceId": 4395,
    "authEntityId": 4395,
    "displayName": "4395",
    "authEntityClass": -1909073334,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "createTime": 1517966742528,
    "deployed": false,
    "isSeeded": true,
    "isStale": false,
    "lastUpdateTime": 1517966742528,
    "name": "dwr",
    "namespace": "scalablegroup:application",
    "provisioningState": "DEFINED",
    "qualifier": "application",
    "resourceVersion": 0,
    "targetIdList": [

    ],
    "type": "scalablegroup",
    "cfsChangeInfo": [

    ],
    "customProvisions": [

    ],
    "scalableGroupExternalHandle": "dwr",
    "scalableGroupType": "APPLICATION",
    "securityGroupTag": 0,
    "state": "ACTIVE",
    "vnAgnostic": true,
    "identitySource": {
      "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
      "instanceId": 10010,
      "authEntityId": 10010,
      "displayName": "10010",
      "authEntityClass": 1738953278,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "state": "INACTIVE",
      "type": "NBAR"
    },
    "indicativeNetworkIdentity": [
      {
        "id": "d5e08236-1f0f-4148-a9e1-ace3c4f4f3f7",
        "instanceId": 12981,
        "authEntityId": 12981,
        "displayName": "12981",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "644",
        "protocol": "UDP",
        "upperPort": 0
      },
      {
        "id": "da02f56d-780d-40bd-83d3-2ad13536d70e",
        "instanceId": 12982,
        "authEntityId": 12982,
        "displayName": "12982",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "644",
        "protocol": "TCP",
        "upperPort": 0
      }
    ],
    "networkApplications": [
      {
        "id": "e4ab0225-8c3c-46ef-a623-3f3574e39d39",
        "instanceId": 11346,
        "authEntityId": 11346,
        "displayName": "11346",
        "authEntityClass": -217092956,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "appProtocol": "tcp/udp",
        "applicationGroup": "other",
        "applicationType": "DEFAULT",
        "categoryId": "1fc17362-9334-4c7b-b752-5afdbb574017",
        "createTime": 1517966742528,
        "encrypted": "false",
        "engineId": "3",
        "helpString": "dwr",
        "ignoreConflict": false,
        "lastUpdateTime": 1517966742528,
        "longDescription": "Registered with IANA on port 644 TCP/UDP. Domain-Wide Multicast Group Membership Reports (DWRs) are a group membership protocol at the domain level.",
        "name": "dwr",
        "nbarId": "553",
        "p2pTechnology": "false",
        "popularity": 1,
        "rank": 65535,
        "references": "http://www.iana.org/assignments/service-names-port-numbers/service-names-port-numbers.xml",
        "selectorId": "644",
        "subCategory": "7cc1b3a6-4102-44e9-b522-0e71afe39a03",
        "trafficClass": "BULK_DATA",
        "tunnel": "false"
      }
    ],
    "networkIdentity": [
      {
        "id": "7aba7989-0209-46e3-bd7c-3ec834ae0a6c",
        "instanceId": 12979,
        "authEntityId": 12979,
        "displayName": "12979",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "644",
        "protocol": "UDP",
        "upperPort": 0
      },
      {
        "id": "76d19ea5-6df4-43be-8ee3-5cc27ff921e8",
        "instanceId": 12980,
        "authEntityId": 12980,
        "displayName": "12980",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "644",
        "protocol": "TCP",
        "upperPort": 0
      }
    ],
    "parentScalableGroup": {
      "idRef": "77e75842-e98b-4112-ad0c-300db4bb11be"
    },
    "user": [

    ],
    "userGroup": [

    ]
  },
  {
    "id": "16c9d171-b324-4316-82f4-b69ee6189ebd",
    "instanceId": 15084,
    "authEntityId": 15084,
    "displayName": "15084",
    "authEntityClass": -1909073334,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "createTime": 1517966744197,
    "deployed": false,
    "isSeeded": true,
    "isStale": false,
    "lastUpdateTime": 1517966744197,
    "name": "rxe",
    "namespace": "scalablegroup:application",
    "provisioningState": "DEFINED",
    "qualifier": "application",
    "resourceVersion": 0,
    "targetIdList": [

    ],
    "type": "scalablegroup",
    "cfsChangeInfo": [

    ],
    "customProvisions": [

    ],
    "scalableGroupExternalHandle": "rxe",
    "scalableGroupType": "APPLICATION",
    "securityGroupTag": 0,
    "state": "ACTIVE",
    "vnAgnostic": true,
    "identitySource": {
      "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
      "instanceId": 10010,
      "authEntityId": 10010,
      "displayName": "10010",
      "authEntityClass": 1738953278,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "state": "INACTIVE",
      "type": "NBAR"
    },
    "indicativeNetworkIdentity": [
      {
        "id": "6f3f9097-18e2-4b30-b5a7-573b1b8b61f6",
        "instanceId": 14888,
        "authEntityId": 14888,
        "displayName": "14888",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "761",
        "protocol": "UDP",
        "upperPort": 0
      },
      {
        "id": "e3f56d99-9e99-461a-867f-82cec570e822",
        "instanceId": 14889,
        "authEntityId": 14889,
        "displayName": "14889",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "761",
        "protocol": "TCP",
        "upperPort": 0
      }
    ],
    "networkApplications": [
      {
        "id": "e04ac771-67d4-496c-b679-de1947cddce6",
        "instanceId": 16027,
        "authEntityId": 16027,
        "displayName": "16027",
        "authEntityClass": -217092956,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "appProtocol": "tcp/udp",
        "applicationGroup": "other",
        "applicationType": "DEFAULT",
        "categoryId": "6daa3dfd-3526-407a-88f0-97824b3f3a24",
        "createTime": 1517966744197,
        "encrypted": "false",
        "engineId": "3",
        "helpString": "rxe",
        "ignoreConflict": false,
        "lastUpdateTime": 1517966744197,
        "longDescription": "Registered with IANA on port 761 TCP/UDP",
        "name": "rxe",
        "nbarId": "633",
        "p2pTechnology": "false",
        "popularity": 1,
        "rank": 65535,
        "references": "http://www.iana.org/assignments/service-names-port-numbers/service-names-port-numbers.xml",
        "selectorId": "761",
        "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
        "trafficClass": "BULK_DATA",
        "tunnel": "false"
      }
    ],
    "networkIdentity": [
      {
        "id": "aaee297f-a294-4d82-bfbb-e3fbe8633b8e",
        "instanceId": 14886,
        "authEntityId": 14886,
        "displayName": "14886",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "761",
        "protocol": "TCP",
        "upperPort": 0
      },
      {
        "id": "db0ba372-089a-437c-8b87-490a43048d9a",
        "instanceId": 14887,
        "authEntityId": 14887,
        "displayName": "14887",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "761",
        "protocol": "UDP",
        "upperPort": 0
      }
    ],
    "parentScalableGroup": {
      "idRef": "000c15bf-28fe-4fbb-9b69-35e6502721f1"
    },
    "user": [

    ],
    "userGroup": [

    ]
  },
  {
    "id": "17137869-960b-4705-b241-9859708172a3",
    "instanceId": 15167,
    "authEntityId": 15167,
    "displayName": "15167",
    "authEntityClass": -1909073334,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "createTime": 1517966744388,
    "deployed": false,
    "isSeeded": true,
    "isStale": false,
    "lastUpdateTime": 1517966744388,
    "name": "smsp",
    "namespace": "scalablegroup:application",
    "provisioningState": "DEFINED",
    "qualifier": "application",
    "resourceVersion": 0,
    "targetIdList": [

    ],
    "type": "scalablegroup",
    "cfsChangeInfo": [

    ],
    "customProvisions": [

    ],
    "scalableGroupExternalHandle": "smsp",
    "scalableGroupType": "APPLICATION",
    "securityGroupTag": 0,
    "state": "ACTIVE",
    "vnAgnostic": true,
    "identitySource": {
      "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
      "instanceId": 10010,
      "authEntityId": 10010,
      "displayName": "10010",
      "authEntityClass": 1738953278,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "state": "INACTIVE",
      "type": "NBAR"
    },
    "indicativeNetworkIdentity": [
      {
        "id": "1fb4003b-0317-4852-88da-46b89f2112d1",
        "instanceId": 17148,
        "authEntityId": 17148,
        "displayName": "17148",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "413",
        "protocol": "UDP",
        "upperPort": 0
      }
    ],
    "networkApplications": [
      {
        "id": "95288fa7-118b-4d4f-b148-30577366fc96",
        "instanceId": 16110,
        "authEntityId": 16110,
        "displayName": "16110",
        "authEntityClass": -217092956,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "appProtocol": "udp",
        "applicationGroup": "other",
        "applicationType": "DEFAULT",
        "categoryId": "1fc17362-9334-4c7b-b752-5afdbb574017",
        "createTime": 1517966744388,
        "encrypted": "false",
        "engineId": "3",
        "helpString": "Storage Management Services Protocol",
        "ignoreConflict": false,
        "lastUpdateTime": 1517966744388,
        "longDescription": "Registered with IANA on port 413 UDP",
        "name": "smsp",
        "nbarId": "328",
        "p2pTechnology": "false",
        "popularity": 1,
        "rank": 65535,
        "references": "http://www.iana.org/assignments/service-names-port-numbers/service-names-port-numbers.xml",
        "selectorId": "413",
        "subCategory": "f3c0f761-d894-4112-ab89-b90fc84fd660",
        "trafficClass": "OPS_ADMIN_MGMT",
        "tunnel": "false"
      }
    ],
    "networkIdentity": [
      {
        "id": "7ab0e61d-7dd1-4032-929d-778fe121babe",
        "instanceId": 17147,
        "authEntityId": 17147,
        "displayName": "17147",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "413",
        "protocol": "UDP",
        "upperPort": 0
      }
    ],
    "parentScalableGroup": {
      "idRef": "5fcd74d4-8726-47f7-a088-c457863da7ef"
    },
    "user": [

    ],
    "userGroup": [

    ]
  },
  {
    "id": "171d7fc9-8878-4c00-bb03-ae64f834c2e1",
    "instanceId": 15242,
    "authEntityId": 15242,
    "displayName": "15242",
    "authEntityClass": -1909073334,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "createTime": 1517966744564,
    "deployed": false,
    "isSeeded": true,
    "isStale": false,
    "lastUpdateTime": 1517966744564,
    "name": "synergy",
    "namespace": "scalablegroup:application",
    "provisioningState": "DEFINED",
    "qualifier": "application",
    "resourceVersion": 0,
    "targetIdList": [

    ],
    "type": "scalablegroup",
    "cfsChangeInfo": [

    ],
    "customProvisions": [

    ],
    "scalableGroupExternalHandle": "synergy",
    "scalableGroupType": "APPLICATION",
    "securityGroupTag": 0,
    "state": "ACTIVE",
    "vnAgnostic": true,
    "identitySource": {
      "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
      "instanceId": 10010,
      "authEntityId": 10010,
      "displayName": "10010",
      "authEntityClass": 1738953278,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "state": "INACTIVE",
      "type": "NBAR"
    },
    "indicativeNetworkIdentity": [
      {
        "id": "3c3514c9-e049-4bca-908c-42999d963a59",
        "instanceId": 17365,
        "authEntityId": 17365,
        "displayName": "17365",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "24800",
        "protocol": "TCP",
        "upperPort": 0
      }
    ],
    "networkApplications": [
      {
        "id": "2ff3b63b-0b5f-4dfe-8bba-3c99edb50a6d",
        "instanceId": 16185,
        "authEntityId": 16185,
        "displayName": "16185",
        "authEntityClass": -217092956,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "appProtocol": "tcp",
        "applicationGroup": "other",
        "applicationType": "DEFAULT",
        "categoryId": "7e19fdea-6222-4c57-9e66-66c2340d6bc3",
        "createTime": 1517966744564,
        "encrypted": "false",
        "engineId": "13",
        "helpString": "Computer resources sharing application",
        "ignoreConflict": false,
        "lastUpdateTime": 1517966744564,
        "longDescription": "Synergy is a computer resources sharing application that lets users easily share a mouse and keyboard between multiple computers. It works on Windows, Mac OS X and Linux",
        "name": "synergy",
        "nbarId": "1367",
        "p2pTechnology": "false",
        "popularity": 4,
        "rank": 65535,
        "references": "http://synergy-foss.org",
        "selectorId": "379",
        "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
        "trafficClass": "BULK_DATA",
        "tunnel": "false"
      }
    ],
    "networkIdentity": [
      {
        "id": "edd444ae-cccd-47c7-b44e-dff784e3fdef",
        "instanceId": 17364,
        "authEntityId": 17364,
        "displayName": "17364",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "24800",
        "protocol": "TCP",
        "upperPort": 0
      }
    ],
    "parentScalableGroup": {
      "idRef": "4a6b37c6-7e11-40fe-a6b1-719b24813145"
    },
    "user": [

    ],
    "userGroup": [

    ]
  },
  {
    "id": "172967f3-91e5-4307-a039-32206a6b92f4",
    "instanceId": 4449,
    "authEntityId": 4449,
    "displayName": "4449",
    "authEntityClass": -1909073334,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "createTime": 1517966742700,
    "deployed": false,
    "isSeeded": true,
    "isStale": false,
    "lastUpdateTime": 1517966742700,
    "name": "filetopia",
    "namespace": "scalablegroup:application",
    "provisioningState": "DEFINED",
    "qualifier": "application",
    "resourceVersion": 0,
    "targetIdList": [

    ],
    "type": "scalablegroup",
    "cfsChangeInfo": [

    ],
    "customProvisions": [

    ],
    "scalableGroupExternalHandle": "filetopia",
    "scalableGroupType": "APPLICATION",
    "securityGroupTag": 0,
    "state": "ACTIVE",
    "vnAgnostic": true,
    "identitySource": {
      "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
      "instanceId": 10010,
      "authEntityId": 10010,
      "displayName": "10010",
      "authEntityClass": 1738953278,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "state": "INACTIVE",
      "type": "NBAR"
    },
    "indicativeNetworkIdentity": [

    ],
    "networkApplications": [
      {
        "id": "45493e79-0443-4bc2-bb10-79883eb9dcf8",
        "instanceId": 11400,
        "authEntityId": 11400,
        "displayName": "11400",
        "authEntityClass": -217092956,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "applicationGroup": "other",
        "applicationType": "DEFAULT",
        "categoryId": "b33b2761-d981-4734-810a-a6a1c18ab352",
        "createTime": 1517966742700,
        "encrypted": "true",
        "engineId": "13",
        "helpString": "Secure P2P file sharing application",
        "ignoreConflict": false,
        "lastUpdateTime": 1517966742700,
        "longDescription": "Filetopia is a secured peer to peer file sharing software which enables the user to chat, message and share files with other users online. Filetopia provides high level of security and privacy which protect users IP",
        "name": "filetopia",
        "nbarId": "432",
        "p2pTechnology": "true",
        "popularity": 6,
        "rank": 65535,
        "references": "http://www.filetopia.org/",
        "selectorId": "433",
        "subCategory": "79fe7198-9ce3-4c01-9b58-45a8df3d9513",
        "trafficClass": "BULK_DATA",
        "tunnel": "false"
      }
    ],
    "networkIdentity": [

    ],
    "parentScalableGroup": {
      "idRef": "7a1a3bbb-dc84-4a35-82a4-7e650c4c4761"
    },
    "user": [

    ],
    "userGroup": [

    ]
  },
  {
    "id": "1741b62c-56b2-463b-aad7-4fbd4f00c2c4",
    "instanceId": 4452,
    "authEntityId": 4452,
    "displayName": "4452",
    "authEntityClass": -1909073334,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "createTime": 1517966742707,
    "deployed": false,
    "isSeeded": true,
    "isStale": false,
    "lastUpdateTime": 1517966742707,
    "name": "fiver",
    "namespace": "scalablegroup:application",
    "provisioningState": "DEFINED",
    "qualifier": "application",
    "resourceVersion": 0,
    "targetIdList": [

    ],
    "type": "scalablegroup",
    "cfsChangeInfo": [

    ],
    "customProvisions": [

    ],
    "scalableGroupExternalHandle": "fiver",
    "scalableGroupType": "APPLICATION",
    "securityGroupTag": 0,
    "state": "ACTIVE",
    "vnAgnostic": true,
    "identitySource": {
      "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
      "instanceId": 10010,
      "authEntityId": 10010,
      "displayName": "10010",
      "authEntityClass": 1738953278,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "state": "INACTIVE",
      "type": "NBAR"
    },
    "indicativeNetworkIdentity": [

    ],
    "networkApplications": [
      {
        "id": "647da1c8-d9ef-4a33-a7f1-03228ffc68e0",
        "instanceId": 11403,
        "authEntityId": 11403,
        "displayName": "11403",
        "authEntityClass": -217092956,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "appProtocol": "tcp/udp",
        "applicationGroup": "other",
        "applicationType": "DEFAULT",
        "categoryId": "878644b5-fe91-45b4-9204-31c84a73ba48",
        "createTime": 1517966742707,
        "encrypted": "false",
        "engineId": "13",
        "helpString": "Fiverr - E-Commerce site generally for $5.",
        "ignoreConflict": false,
        "lastUpdateTime": 1517966742707,
        "longDescription": "Fiverr - E-Commerce site generally for $5.",
        "name": "fiver",
        "nbarId": "1612",
        "p2pTechnology": "false",
        "popularity": 3,
        "rank": 65535,
        "references": "http://fiverr.com",
        "selectorId": "682",
        "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
        "trafficClass": "TRANSACTIONAL_DATA",
        "tunnel": "false"
      }
    ],
    "networkIdentity": [
      {
        "id": "64efcb5e-1b9c-479d-9ecd-1c44e3c7db24",
        "instanceId": 13128,
        "authEntityId": 13128,
        "displayName": "13128",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "53,80,443,5353",
        "protocol": "TCP",
        "upperPort": 0
      },
      {
        "id": "0f5eea54-a8ad-4c3d-bb01-c78d1777ba24",
        "instanceId": 13129,
        "authEntityId": 13129,
        "displayName": "13129",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "53,5353",
        "protocol": "UDP",
        "upperPort": 0
      }
    ],
    "parentScalableGroup": {
      "idRef": "28e08de4-d7b2-4521-84ce-bc1e5ee0b9cc"
    },
    "user": [

    ],
    "userGroup": [

    ]
  },
  {
    "id": "176c4bb8-6a1d-484d-a9e0-15d06f79f740",
    "instanceId": 4389,
    "authEntityId": 4389,
    "displayName": "4389",
    "authEntityClass": -1909073334,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "createTime": 1517966742501,
    "deployed": false,
    "isSeeded": true,
    "isStale": false,
    "lastUpdateTime": 1517966742501,
    "name": "dsp",
    "namespace": "scalablegroup:application",
    "provisioningState": "DEFINED",
    "qualifier": "application",
    "resourceVersion": 0,
    "targetIdList": [

    ],
    "type": "scalablegroup",
    "cfsChangeInfo": [

    ],
    "customProvisions": [

    ],
    "scalableGroupExternalHandle": "dsp",
    "scalableGroupType": "APPLICATION",
    "securityGroupTag": 0,
    "state": "ACTIVE",
    "vnAgnostic": true,
    "identitySource": {
      "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
      "instanceId": 10010,
      "authEntityId": 10010,
      "displayName": "10010",
      "authEntityClass": 1738953278,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "state": "INACTIVE",
      "type": "NBAR"
    },
    "indicativeNetworkIdentity": [
      {
        "id": "9ebf48ef-f045-443c-bb07-58644c410003",
        "instanceId": 12962,
        "authEntityId": 12962,
        "displayName": "12962",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "33",
        "protocol": "UDP",
        "upperPort": 0
      },
      {
        "id": "86de286b-91e6-4cc9-8e3c-1895841c9ae4",
        "instanceId": 12963,
        "authEntityId": 12963,
        "displayName": "12963",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "33",
        "protocol": "TCP",
        "upperPort": 0
      }
    ],
    "networkApplications": [
      {
        "id": "f9b90042-b447-44ce-bc6b-4e15ca01c6e3",
        "instanceId": 11340,
        "authEntityId": 11340,
        "displayName": "11340",
        "authEntityClass": -217092956,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "appProtocol": "tcp/udp",
        "applicationGroup": "other",
        "applicationType": "DEFAULT",
        "categoryId": "6daa3dfd-3526-407a-88f0-97824b3f3a24",
        "createTime": 1517966742501,
        "encrypted": "false",
        "engineId": "3",
        "helpString": "Display Support Protocol",
        "ignoreConflict": false,
        "lastUpdateTime": 1517966742501,
        "longDescription": "Registered with IANA on port 33 TCP/UDP",
        "name": "dsp",
        "nbarId": "917",
        "p2pTechnology": "false",
        "popularity": 1,
        "rank": 65535,
        "references": "http://www.iana.org/assignments/service-names-port-numbers/service-names-port-numbers.xml",
        "selectorId": "33",
        "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
        "trafficClass": "BULK_DATA",
        "tunnel": "false"
      }
    ],
    "networkIdentity": [
      {
        "id": "1003f25d-d396-4e5c-bbf5-03db561cc4b1",
        "instanceId": 12960,
        "authEntityId": 12960,
        "displayName": "12960",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "33",
        "protocol": "UDP",
        "upperPort": 0
      },
      {
        "id": "8217f059-3e40-4501-80d1-7d2b8f241af1",
        "instanceId": 12961,
        "authEntityId": 12961,
        "displayName": "12961",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "33",
        "protocol": "TCP",
        "upperPort": 0
      }
    ],
    "parentScalableGroup": {
      "idRef": "000c15bf-28fe-4fbb-9b69-35e6502721f1"
    },
    "user": [

    ],
    "userGroup": [

    ]
  },
  {
    "id": "17c07068-6d04-4841-a1ce-f7dfffab4305",
    "instanceId": 4896,
    "authEntityId": 4896,
    "displayName": "4896",
    "authEntityClass": -1909073334,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "createTime": 1517966743704,
    "deployed": false,
    "isSeeded": true,
    "isStale": false,
    "lastUpdateTime": 1517966743704,
    "name": "ocbinder",
    "namespace": "scalablegroup:application",
    "provisioningState": "DEFINED",
    "qualifier": "application",
    "resourceVersion": 0,
    "targetIdList": [

    ],
    "type": "scalablegroup",
    "cfsChangeInfo": [

    ],
    "customProvisions": [

    ],
    "scalableGroupExternalHandle": "ocbinder",
    "scalableGroupType": "APPLICATION",
    "securityGroupTag": 0,
    "state": "ACTIVE",
    "vnAgnostic": true,
    "identitySource": {
      "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
      "instanceId": 10010,
      "authEntityId": 10010,
      "displayName": "10010",
      "authEntityClass": 1738953278,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "state": "INACTIVE",
      "type": "NBAR"
    },
    "indicativeNetworkIdentity": [
      {
        "id": "f364fcde-23e5-4b16-ae70-910b79cd1f7b",
        "instanceId": 14341,
        "authEntityId": 14341,
        "displayName": "14341",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "183",
        "protocol": "TCP",
        "upperPort": 0
      },
      {
        "id": "04371e4b-9c51-4d57-a1b5-82dcc73a27aa",
        "instanceId": 14342,
        "authEntityId": 14342,
        "displayName": "14342",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "183",
        "protocol": "UDP",
        "upperPort": 0
      }
    ],
    "networkApplications": [
      {
        "id": "cd41a29f-81a3-4f5a-87cd-9838a5dad4d4",
        "instanceId": 11845,
        "authEntityId": 11845,
        "displayName": "11845",
        "authEntityClass": -217092956,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "appProtocol": "tcp/udp",
        "applicationGroup": "other",
        "applicationType": "DEFAULT",
        "categoryId": "6daa3dfd-3526-407a-88f0-97824b3f3a24",
        "createTime": 1517966743704,
        "encrypted": "false",
        "engineId": "3",
        "helpString": "ocbinder",
        "ignoreConflict": false,
        "lastUpdateTime": 1517966743704,
        "longDescription": "Registered with IANA on port 183 TCP/UDP",
        "name": "ocbinder",
        "nbarId": "1026",
        "p2pTechnology": "false",
        "popularity": 1,
        "rank": 65535,
        "references": "http://www.iana.org/assignments/service-names-port-numbers/service-names-port-numbers.xml",
        "selectorId": "183",
        "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
        "trafficClass": "BULK_DATA",
        "tunnel": "false"
      }
    ],
    "networkIdentity": [
      {
        "id": "d4fadfb3-029a-431a-8415-d2be3ec1a6a6",
        "instanceId": 14339,
        "authEntityId": 14339,
        "displayName": "14339",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "183",
        "protocol": "UDP",
        "upperPort": 0
      },
      {
        "id": "81ce579f-4b41-4ddc-b7d4-46189cb2fa94",
        "instanceId": 14340,
        "authEntityId": 14340,
        "displayName": "14340",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "183",
        "protocol": "TCP",
        "upperPort": 0
      }
    ],
    "parentScalableGroup": {
      "idRef": "000c15bf-28fe-4fbb-9b69-35e6502721f1"
    },
    "user": [

    ],
    "userGroup": [

    ]
  },
  {
    "id": "17c28392-f251-4ac6-9790-999182e78307",
    "instanceId": 15088,
    "authEntityId": 15088,
    "displayName": "15088",
    "authEntityClass": -1909073334,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "createTime": 1517966744206,
    "deployed": false,
    "isSeeded": true,
    "isStale": false,
    "lastUpdateTime": 1517966744206,
    "name": "samsung",
    "namespace": "scalablegroup:application",
    "provisioningState": "DEFINED",
    "qualifier": "application",
    "resourceVersion": 0,
    "targetIdList": [

    ],
    "type": "scalablegroup",
    "cfsChangeInfo": [

    ],
    "customProvisions": [

    ],
    "scalableGroupExternalHandle": "samsung",
    "scalableGroupType": "APPLICATION",
    "securityGroupTag": 0,
    "state": "ACTIVE",
    "vnAgnostic": true,
    "identitySource": {
      "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
      "instanceId": 10010,
      "authEntityId": 10010,
      "displayName": "10010",
      "authEntityClass": 1738953278,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "state": "INACTIVE",
      "type": "NBAR"
    },
    "indicativeNetworkIdentity": [

    ],
    "networkApplications": [
      {
        "id": "fee265e1-cbbb-455e-a9c8-c66fcde16ac2",
        "instanceId": 16031,
        "authEntityId": 16031,
        "displayName": "16031",
        "authEntityClass": -217092956,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "appProtocol": "tcp/udp",
        "applicationGroup": "other",
        "applicationType": "DEFAULT",
        "categoryId": "878644b5-fe91-45b4-9204-31c84a73ba48",
        "createTime": 1517966744206,
        "encrypted": "false",
        "engineId": "13",
        "helpString": "Samsung - Electronics retail site.",
        "ignoreConflict": false,
        "lastUpdateTime": 1517966744206,
        "longDescription": "Samsung - Electronics retail site.",
        "name": "samsung",
        "nbarId": "1619",
        "p2pTechnology": "false",
        "popularity": 3,
        "rank": 65535,
        "references": "http://samsung.com",
        "selectorId": "689",
        "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
        "trafficClass": "TRANSACTIONAL_DATA",
        "tunnel": "false"
      }
    ],
    "networkIdentity": [
      {
        "id": "34c815fe-5cc4-44d1-b494-4a5fa20c0089",
        "instanceId": 14900,
        "authEntityId": 14900,
        "displayName": "14900",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "53,80,443,5353",
        "protocol": "TCP",
        "upperPort": 0
      },
      {
        "id": "d1818aea-1491-4eb1-85f9-e069bb681861",
        "instanceId": 14901,
        "authEntityId": 14901,
        "displayName": "14901",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "53,5353",
        "protocol": "UDP",
        "upperPort": 0
      }
    ],
    "parentScalableGroup": {
      "idRef": "28e08de4-d7b2-4521-84ce-bc1e5ee0b9cc"
    },
    "user": [

    ],
    "userGroup": [

    ]
  },
  {
    "id": "1866ea1c-edff-445c-b813-b9cc27b29b2f",
    "instanceId": 15303,
    "authEntityId": 15303,
    "displayName": "15303",
    "authEntityClass": -1909073334,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "createTime": 1517966744700,
    "deployed": false,
    "isSeeded": true,
    "isStale": false,
    "lastUpdateTime": 1517966744700,
    "name": "trulia",
    "namespace": "scalablegroup:application",
    "provisioningState": "DEFINED",
    "qualifier": "application",
    "resourceVersion": 0,
    "targetIdList": [

    ],
    "type": "scalablegroup",
    "cfsChangeInfo": [

    ],
    "customProvisions": [

    ],
    "scalableGroupExternalHandle": "trulia",
    "scalableGroupType": "APPLICATION",
    "securityGroupTag": 0,
    "state": "ACTIVE",
    "vnAgnostic": true,
    "identitySource": {
      "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
      "instanceId": 10010,
      "authEntityId": 10010,
      "displayName": "10010",
      "authEntityClass": 1738953278,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "state": "INACTIVE",
      "type": "NBAR"
    },
    "indicativeNetworkIdentity": [

    ],
    "networkApplications": [
      {
        "id": "c358508a-5847-4cc5-a576-170398a89d11",
        "instanceId": 16246,
        "authEntityId": 16246,
        "displayName": "16246",
        "authEntityClass": -217092956,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "appProtocol": "tcp/udp",
        "applicationGroup": "other",
        "applicationType": "DEFAULT",
        "categoryId": "878644b5-fe91-45b4-9204-31c84a73ba48",
        "createTime": 1517966744700,
        "encrypted": "false",
        "engineId": "13",
        "helpString": "Trulia - Online portal for Real Estate.",
        "ignoreConflict": false,
        "lastUpdateTime": 1517966744700,
        "longDescription": "Trulia - Online portal for Real Estate.",
        "name": "trulia",
        "nbarId": "1640",
        "p2pTechnology": "false",
        "popularity": 3,
        "rank": 65535,
        "references": "http://trulia.com",
        "selectorId": "710",
        "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
        "trafficClass": "TRANSACTIONAL_DATA",
        "tunnel": "false"
      }
    ],
    "networkIdentity": [
      {
        "id": "b2cd7142-8744-43da-9ab2-c658c8de8cdf",
        "instanceId": 17534,
        "authEntityId": 17534,
        "displayName": "17534",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "53,5353",
        "protocol": "UDP",
        "upperPort": 0
      },
      {
        "id": "f88f6ea3-e21c-4d6e-a527-09bb2af2ee80",
        "instanceId": 17535,
        "authEntityId": 17535,
        "displayName": "17535",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "53,80,443,5353",
        "protocol": "TCP",
        "upperPort": 0
      }
    ],
    "parentScalableGroup": {
      "idRef": "28e08de4-d7b2-4521-84ce-bc1e5ee0b9cc"
    },
    "user": [

    ],
    "userGroup": [

    ]
  },
  {
    "id": "18bad9e1-2beb-4f29-b225-bfa800c4cf54",
    "instanceId": 4609,
    "authEntityId": 4609,
    "displayName": "4609",
    "authEntityClass": -1909073334,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "createTime": 1517966743113,
    "deployed": false,
    "isSeeded": true,
    "isStale": false,
    "lastUpdateTime": 1517966743113,
    "name": "integra-sme",
    "namespace": "scalablegroup:application",
    "provisioningState": "DEFINED",
    "qualifier": "application",
    "resourceVersion": 0,
    "targetIdList": [

    ],
    "type": "scalablegroup",
    "cfsChangeInfo": [

    ],
    "customProvisions": [

    ],
    "scalableGroupExternalHandle": "integra-sme",
    "scalableGroupType": "APPLICATION",
    "securityGroupTag": 0,
    "state": "ACTIVE",
    "vnAgnostic": true,
    "identitySource": {
      "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
      "instanceId": 10010,
      "authEntityId": 10010,
      "displayName": "10010",
      "authEntityClass": 1738953278,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "state": "INACTIVE",
      "type": "NBAR"
    },
    "indicativeNetworkIdentity": [
      {
        "id": "a362a18e-6198-45af-81f6-69e4619f7822",
        "instanceId": 13539,
        "authEntityId": 13539,
        "displayName": "13539",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "484",
        "protocol": "UDP",
        "upperPort": 0
      },
      {
        "id": "b8de92b7-08c3-4bef-b9e0-9b8323e60504",
        "instanceId": 13540,
        "authEntityId": 13540,
        "displayName": "13540",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "484",
        "protocol": "TCP",
        "upperPort": 0
      }
    ],
    "networkApplications": [
      {
        "id": "9f29419c-4b8f-4f06-a254-68ac854d9112",
        "instanceId": 11559,
        "authEntityId": 11559,
        "displayName": "11559",
        "authEntityClass": -217092956,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "appProtocol": "tcp/udp",
        "applicationGroup": "other",
        "applicationType": "DEFAULT",
        "categoryId": "6daa3dfd-3526-407a-88f0-97824b3f3a24",
        "createTime": 1517966743113,
        "encrypted": "false",
        "engineId": "3",
        "helpString": "Integra Software Management Environment",
        "ignoreConflict": false,
        "lastUpdateTime": 1517966743113,
        "longDescription": "Integra Software Management Environment is part of Symantec Management Platform, which provides a set of services that IT-related solutions can leverage. Solutions plug into the platform and take advantage of the platform services, such as security, reporting, communications, package deployment, and Configuration Management Database (CMDB) data.",
        "name": "integra-sme",
        "nbarId": "398",
        "p2pTechnology": "false",
        "popularity": 1,
        "rank": 65535,
        "references": "http://eval.symantec.com/mktginfo/enterprise/other_resources/b-symantec_management_platform_installation_guide_01-2009.en-us.pdf",
        "selectorId": "484",
        "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
        "trafficClass": "BULK_DATA",
        "tunnel": "false"
      }
    ],
    "networkIdentity": [
      {
        "id": "0cc572a6-01b7-492c-842f-fd3446c43054",
        "instanceId": 13537,
        "authEntityId": 13537,
        "displayName": "13537",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "484",
        "protocol": "UDP",
        "upperPort": 0
      },
      {
        "id": "9ebbaec9-d070-44fa-b002-a5c1e5a6124f",
        "instanceId": 13538,
        "authEntityId": 13538,
        "displayName": "13538",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "484",
        "protocol": "TCP",
        "upperPort": 0
      }
    ],
    "parentScalableGroup": {
      "idRef": "000c15bf-28fe-4fbb-9b69-35e6502721f1"
    },
    "user": [

    ],
    "userGroup": [

    ]
  },
  {
    "id": "18bd1a10-ff4b-4538-be67-35783f30dd2b",
    "instanceId": 15347,
    "authEntityId": 15347,
    "displayName": "15347",
    "authEntityClass": -1909073334,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "createTime": 1517966744780,
    "deployed": false,
    "isSeeded": true,
    "isStale": false,
    "lastUpdateTime": 1517966744780,
    "name": "verizon-web-services",
    "namespace": "scalablegroup:application",
    "provisioningState": "DEFINED",
    "qualifier": "application",
    "resourceVersion": 0,
    "targetIdList": [

    ],
    "type": "scalablegroup",
    "cfsChangeInfo": [

    ],
    "customProvisions": [

    ],
    "scalableGroupExternalHandle": "verizon-web-services",
    "scalableGroupType": "APPLICATION",
    "securityGroupTag": 0,
    "state": "ACTIVE",
    "vnAgnostic": true,
    "identitySource": {
      "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
      "instanceId": 10010,
      "authEntityId": 10010,
      "displayName": "10010",
      "authEntityClass": 1738953278,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "state": "INACTIVE",
      "type": "NBAR"
    },
    "indicativeNetworkIdentity": [

    ],
    "networkApplications": [
      {
        "id": "27dedd54-e0b2-483a-8ab1-54c5d037d001",
        "instanceId": 16290,
        "authEntityId": 16290,
        "displayName": "16290",
        "authEntityClass": -217092956,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "appProtocol": "tcp/udp",
        "applicationGroup": "other",
        "applicationType": "DEFAULT",
        "categoryId": "878644b5-fe91-45b4-9204-31c84a73ba48",
        "createTime": 1517966744780,
        "encrypted": "false",
        "engineId": "13",
        "helpString": "Verizon - Internet, TV and Phone service provider.",
        "ignoreConflict": false,
        "lastUpdateTime": 1517966744780,
        "longDescription": "Verizon - Internet, TV and Phone service provider.",
        "name": "verizon-web-services",
        "nbarId": "1669",
        "p2pTechnology": "false",
        "popularity": 3,
        "rank": 65535,
        "references": "http://verizon.com",
        "selectorId": "739",
        "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
        "trafficClass": "TRANSACTIONAL_DATA",
        "tunnel": "false"
      }
    ],
    "networkIdentity": [
      {
        "id": "46709491-824e-43e6-8905-8f3f956a0e4e",
        "instanceId": 17661,
        "authEntityId": 17661,
        "displayName": "17661",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "53,5353",
        "protocol": "UDP",
        "upperPort": 0
      },
      {
        "id": "329445b4-169e-4b38-885d-03d103848a95",
        "instanceId": 17662,
        "authEntityId": 17662,
        "displayName": "17662",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "53,80,443,5353",
        "protocol": "TCP",
        "upperPort": 0
      }
    ],
    "parentScalableGroup": {
      "idRef": "28e08de4-d7b2-4521-84ce-bc1e5ee0b9cc"
    },
    "user": [

    ],
    "userGroup": [

    ]
  },
  {
    "id": "18eccae9-81c9-4724-b226-a321c95200b8",
    "instanceId": 15247,
    "authEntityId": 15247,
    "displayName": "15247",
    "authEntityClass": -1909073334,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "createTime": 1517966744574,
    "deployed": false,
    "isSeeded": true,
    "isStale": false,
    "lastUpdateTime": 1517966744574,
    "name": "systat",
    "namespace": "scalablegroup:application",
    "provisioningState": "DEFINED",
    "qualifier": "application",
    "resourceVersion": 0,
    "targetIdList": [

    ],
    "type": "scalablegroup",
    "cfsChangeInfo": [

    ],
    "customProvisions": [

    ],
    "scalableGroupExternalHandle": "systat",
    "scalableGroupType": "APPLICATION",
    "securityGroupTag": 0,
    "state": "ACTIVE",
    "vnAgnostic": true,
    "identitySource": {
      "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
      "instanceId": 10010,
      "authEntityId": 10010,
      "displayName": "10010",
      "authEntityClass": 1738953278,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "state": "INACTIVE",
      "type": "NBAR"
    },
    "indicativeNetworkIdentity": [
      {
        "id": "26e00629-5d46-476c-8055-586b2726bf01",
        "instanceId": 17380,
        "authEntityId": 17380,
        "displayName": "17380",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "11",
        "protocol": "UDP",
        "upperPort": 0
      },
      {
        "id": "f76ee4dd-4455-4b14-841f-852ed14d3bcf",
        "instanceId": 17381,
        "authEntityId": 17381,
        "displayName": "17381",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "11",
        "protocol": "TCP",
        "upperPort": 0
      }
    ],
    "networkApplications": [
      {
        "id": "e8618b62-cd20-41a6-bc34-36f7a1b58165",
        "instanceId": 16190,
        "authEntityId": 16190,
        "displayName": "16190",
        "authEntityClass": -217092956,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "appProtocol": "tcp/udp",
        "applicationGroup": "other",
        "applicationType": "DEFAULT",
        "categoryId": "1fc17362-9334-4c7b-b752-5afdbb574017",
        "createTime": 1517966744574,
        "encrypted": "false",
        "engineId": "3",
        "helpString": "Active Users",
        "ignoreConflict": false,
        "lastUpdateTime": 1517966744574,
        "longDescription": "SYSTAT is a statistical analysis and graphics software package.",
        "name": "systat",
        "nbarId": "102",
        "p2pTechnology": "false",
        "popularity": 1,
        "rank": 65535,
        "references": "http://www.systat.com/",
        "selectorId": "11",
        "subCategory": "f3c0f761-d894-4112-ab89-b90fc84fd660",
        "trafficClass": "OPS_ADMIN_MGMT",
        "tunnel": "false"
      }
    ],
    "networkIdentity": [
      {
        "id": "b2921363-ec56-4507-a498-9968b4f4776d",
        "instanceId": 17378,
        "authEntityId": 17378,
        "displayName": "17378",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "11",
        "protocol": "UDP",
        "upperPort": 0
      },
      {
        "id": "302e3c5d-83bb-40a6-8cfe-c0969cf8943a",
        "instanceId": 17379,
        "authEntityId": 17379,
        "displayName": "17379",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "11",
        "protocol": "TCP",
        "upperPort": 0
      }
    ],
    "parentScalableGroup": {
      "idRef": "5fcd74d4-8726-47f7-a088-c457863da7ef"
    },
    "user": [

    ],
    "userGroup": [

    ]
  },
  {
    "id": "1935efce-ea4c-44c1-9313-7baddee3c374",
    "instanceId": 4961,
    "authEntityId": 4961,
    "displayName": "4961",
    "authEntityClass": -1909073334,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "createTime": 1517966743911,
    "deployed": false,
    "isSeeded": true,
    "isStale": false,
    "lastUpdateTime": 1517966743911,
    "name": "ping",
    "namespace": "scalablegroup:application",
    "provisioningState": "DEFINED",
    "qualifier": "application",
    "resourceVersion": 0,
    "targetIdList": [

    ],
    "type": "scalablegroup",
    "cfsChangeInfo": [

    ],
    "customProvisions": [

    ],
    "scalableGroupExternalHandle": "ping",
    "scalableGroupType": "APPLICATION",
    "securityGroupTag": 0,
    "state": "ACTIVE",
    "vnAgnostic": true,
    "identitySource": {
      "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
      "instanceId": 10010,
      "authEntityId": 10010,
      "displayName": "10010",
      "authEntityClass": 1738953278,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "state": "INACTIVE",
      "type": "NBAR"
    },
    "indicativeNetworkIdentity": [

    ],
    "networkApplications": [
      {
        "id": "be700628-b653-4d20-9553-efd6cf86f4c8",
        "instanceId": 11910,
        "authEntityId": 11910,
        "displayName": "11910",
        "authEntityClass": -217092956,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "applicationGroup": "other",
        "applicationType": "DEFAULT",
        "categoryId": "1fc17362-9334-4c7b-b752-5afdbb574017",
        "createTime": 1517966743911,
        "encrypted": "false",
        "engineId": "13",
        "helpString": "Ping is echo-req/echo-reply over ICMP",
        "ignoreConflict": false,
        "lastUpdateTime": 1517966743911,
        "longDescription": "Ping is a computer network administration utility used to test the reachability of a host on a network and to measure the round-trip time for messages sent from the host to the destination. Ping operates by sending ICMP echo request packets to the target host and waiting for an ICMP response. In the process it measures the round-trip time and records any packet loss. It prints a statistical summary when finished. Note that Ping will not classify any ICMP packet, only those used for echo.",
        "name": "ping",
        "nbarId": "1404",
        "p2pTechnology": "false",
        "popularity": 10,
        "rank": 65535,
        "references": "http://tools.ietf.org/html/rfc792",
        "selectorId": "479",
        "subCategory": "f3c0f761-d894-4112-ab89-b90fc84fd660",
        "trafficClass": "OPS_ADMIN_MGMT",
        "tunnel": "false"
      }
    ],
    "networkIdentity": [

    ],
    "parentScalableGroup": {
      "idRef": "5fcd74d4-8726-47f7-a088-c457863da7ef"
    },
    "user": [

    ],
    "userGroup": [

    ]
  },
  {
    "id": "193fe5fa-eadf-42f6-b21e-6704d26b8692",
    "instanceId": 4588,
    "authEntityId": 4588,
    "displayName": "4588",
    "authEntityClass": -1909073334,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "createTime": 1517966743062,
    "deployed": false,
    "isSeeded": true,
    "isStale": false,
    "lastUpdateTime": 1517966743062,
    "name": "idrp",
    "namespace": "scalablegroup:application",
    "provisioningState": "DEFINED",
    "qualifier": "application",
    "resourceVersion": 0,
    "targetIdList": [

    ],
    "type": "scalablegroup",
    "cfsChangeInfo": [

    ],
    "customProvisions": [

    ],
    "scalableGroupExternalHandle": "idrp",
    "scalableGroupType": "APPLICATION",
    "securityGroupTag": 0,
    "state": "ACTIVE",
    "vnAgnostic": true,
    "identitySource": {
      "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
      "instanceId": 10010,
      "authEntityId": 10010,
      "displayName": "10010",
      "authEntityClass": 1738953278,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "state": "INACTIVE",
      "type": "NBAR"
    },
    "indicativeNetworkIdentity": [
      {
        "id": "6594248e-2d6f-4b81-bcdd-16f1a6694e89",
        "instanceId": 13478,
        "authEntityId": 13478,
        "displayName": "13478",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipProtocolNumbers": "45",
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "protocol": "IDRP",
        "upperPort": 0
      }
    ],
    "networkApplications": [
      {
        "id": "15aca646-c918-4e89-b223-1ed3c653a9cf",
        "instanceId": 11538,
        "authEntityId": 11538,
        "displayName": "11538",
        "authEntityClass": -217092956,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "appProtocol": "ip",
        "applicationGroup": "other",
        "applicationType": "DEFAULT",
        "categoryId": "1fc17362-9334-4c7b-b752-5afdbb574017",
        "createTime": 1517966743062,
        "encrypted": "unassigned",
        "engineId": "1",
        "helpString": "Inter-Domain Routing Protocol",
        "ignoreConflict": false,
        "lastUpdateTime": 1517966743062,
        "longDescription": "Inter-Domain Routing Protocol (IDRP) permits a routing domain to exchange information with other routing domains to facilitate the operation of the routing and relaying functions of the Network Layer.",
        "name": "idrp",
        "nbarId": "799",
        "p2pTechnology": "unassigned",
        "popularity": 2,
        "rank": 65535,
        "references": "http://tools.ietf.org/html/draft-ietf-idr-idrp2-00",
        "selectorId": "45",
        "subCategory": "7cc1b3a6-4102-44e9-b522-0e71afe39a03",
        "trafficClass": "NETWORK_CONTROL",
        "tunnel": "unassigned"
      }
    ],
    "networkIdentity": [
      {
        "id": "bc1fb61c-0097-4980-ba9e-106caf6ba4ce",
        "instanceId": 13477,
        "authEntityId": 13477,
        "displayName": "13477",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipProtocolNumbers": "45",
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "protocol": "IDRP",
        "upperPort": 0
      }
    ],
    "parentScalableGroup": {
      "idRef": "77e75842-e98b-4112-ad0c-300db4bb11be"
    },
    "user": [

    ],
    "userGroup": [

    ]
  },
  {
    "id": "19552de8-ef36-41f9-b9e2-1147d6f600c5",
    "instanceId": 15018,
    "authEntityId": 15018,
    "displayName": "15018",
    "authEntityClass": -1909073334,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "createTime": 1517966744038,
    "deployed": false,
    "isSeeded": true,
    "isStale": false,
    "lastUpdateTime": 1517966744038,
    "name": "qq-games",
    "namespace": "scalablegroup:application",
    "provisioningState": "DEFINED",
    "qualifier": "application",
    "resourceVersion": 0,
    "targetIdList": [

    ],
    "type": "scalablegroup",
    "cfsChangeInfo": [

    ],
    "customProvisions": [

    ],
    "scalableGroupExternalHandle": "qq-games",
    "scalableGroupType": "APPLICATION",
    "securityGroupTag": 0,
    "state": "ACTIVE",
    "vnAgnostic": true,
    "identitySource": {
      "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
      "instanceId": 10010,
      "authEntityId": 10010,
      "displayName": "10010",
      "authEntityClass": 1738953278,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "state": "INACTIVE",
      "type": "NBAR"
    },
    "indicativeNetworkIdentity": [
      {
        "id": "e624cae2-6ce2-4381-b14d-913d3126081f",
        "instanceId": 14686,
        "authEntityId": 14686,
        "displayName": "14686",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "1863",
        "protocol": "UDP",
        "upperPort": 0
      }
    ],
    "networkApplications": [
      {
        "id": "efb4c373-3867-499f-a533-31ae3b2d78a6",
        "instanceId": 11957,
        "authEntityId": 11957,
        "displayName": "11957",
        "authEntityClass": -217092956,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "appProtocol": "tcp/udp",
        "applicationGroup": "qq-group",
        "applicationType": "DEFAULT",
        "categoryId": "3cd50dd8-1b2e-46c1-b672-41c14bfa364b",
        "createTime": 1517966744038,
        "encrypted": "true",
        "engineId": "13",
        "helpString": "QQ Games, a gaming platform",
        "ignoreConflict": false,
        "lastUpdateTime": 1517966744038,
        "longDescription": "QQ Games is a platform developed by Tencent America LLC which lets users play hundreds of casual multiplayer games with other people through the web. A Chinese version is available as well as an English version.",
        "name": "qq-games",
        "nbarId": "1509",
        "p2pTechnology": "false",
        "popularity": 8,
        "rank": 65535,
        "references": "http://qqgame.qq.com/",
        "selectorId": "570",
        "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
        "trafficClass": "MULTIMEDIA_CONFERENCING",
        "tunnel": "false"
      }
    ],
    "networkIdentity": [
      {
        "id": "4f02b0cd-b00a-4950-9e66-4eab61d6cb70",
        "instanceId": 14684,
        "authEntityId": 14684,
        "displayName": "14684",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "53,80,443,5353",
        "protocol": "TCP",
        "upperPort": 0
      },
      {
        "id": "7087dbe1-4d76-400c-98df-8c617aafc5dd",
        "instanceId": 14685,
        "authEntityId": 14685,
        "displayName": "14685",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "53,1863,5353",
        "protocol": "UDP",
        "upperPort": 0
      }
    ],
    "parentScalableGroup": {
      "idRef": "0bd0e6c9-5083-492b-97cb-a88928bee99c"
    },
    "user": [

    ],
    "userGroup": [

    ]
  },
  {
    "id": "1984f2a2-1817-417c-8f8a-3ff53aac6bff",
    "instanceId": 4467,
    "authEntityId": 4467,
    "displayName": "4467",
    "authEntityClass": -1909073334,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "createTime": 1517966742743,
    "deployed": false,
    "isSeeded": true,
    "isStale": false,
    "lastUpdateTime": 1517966742743,
    "name": "ftp",
    "namespace": "scalablegroup:application",
    "provisioningState": "DEFINED",
    "qualifier": "application",
    "resourceVersion": 0,
    "targetIdList": [

    ],
    "type": "scalablegroup",
    "cfsChangeInfo": [

    ],
    "customProvisions": [

    ],
    "scalableGroupExternalHandle": "ftp",
    "scalableGroupType": "APPLICATION",
    "securityGroupTag": 0,
    "state": "ACTIVE",
    "vnAgnostic": true,
    "identitySource": {
      "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
      "instanceId": 10010,
      "authEntityId": 10010,
      "displayName": "10010",
      "authEntityClass": 1738953278,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "state": "INACTIVE",
      "type": "NBAR"
    },
    "indicativeNetworkIdentity": [
      {
        "id": "6330fae3-0237-4e53-a97b-dd1cc60f8d70",
        "instanceId": 13156,
        "authEntityId": 13156,
        "displayName": "13156",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "21,21000",
        "protocol": "TCP",
        "upperPort": 0
      }
    ],
    "networkApplications": [
      {
        "id": "7cffa84a-2ee2-42d0-b6db-00c67a26a8ab",
        "instanceId": 11418,
        "authEntityId": 11418,
        "displayName": "11418",
        "authEntityClass": -217092956,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "appProtocol": "tcp",
        "applicationGroup": "ftp-group",
        "applicationType": "DEFAULT",
        "categoryId": "e6619ce8-97de-4c63-a53a-cceddbddc1a8",
        "createTime": 1517966742743,
        "encrypted": "false",
        "engineId": "3",
        "helpString": "File Transfer Protocol",
        "ignoreConflict": false,
        "lastUpdateTime": 1517966742743,
        "longDescription": "File Transfer Protocol (FTP) is used to transfer files between hosts over TCP networks and is based on client-server architecture. An FTP server usually listens on  port 21.",
        "name": "ftp",
        "nbarId": "2",
        "p2pTechnology": "false",
        "popularity": 10,
        "rank": 65535,
        "references": "http://www.ietf.org/rfc/rfc959.txt",
        "selectorId": "21",
        "subCategory": "50b55e2e-d0d0-4404-bea4-819351565d90",
        "trafficClass": "BULK_DATA",
        "tunnel": "false"
      }
    ],
    "networkIdentity": [
      {
        "id": "02e6962f-47b3-4fd4-98e6-b2ae036f9173",
        "instanceId": 13155,
        "authEntityId": 13155,
        "displayName": "13155",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "21,21000",
        "protocol": "TCP",
        "upperPort": 0
      }
    ],
    "parentScalableGroup": {
      "idRef": "383363b1-4dd5-49d1-bfe4-34daac437cfb"
    },
    "user": [

    ],
    "userGroup": [

    ]
  },
  {
    "id": "1a2e5146-9215-4aff-aec4-256a7cba3e86",
    "instanceId": 4099,
    "authEntityId": 4099,
    "displayName": "4099",
    "authEntityClass": -1909073334,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "createTime": 1517966741068,
    "deployed": false,
    "isSeeded": true,
    "isStale": false,
    "lastUpdateTime": 1517966741068,
    "name": "apertus-ldp",
    "namespace": "scalablegroup:application",
    "provisioningState": "DEFINED",
    "qualifier": "application",
    "resourceVersion": 0,
    "targetIdList": [

    ],
    "type": "scalablegroup",
    "cfsChangeInfo": [

    ],
    "customProvisions": [

    ],
    "scalableGroupExternalHandle": "apertus-ldp",
    "scalableGroupType": "APPLICATION",
    "securityGroupTag": 0,
    "state": "ACTIVE",
    "vnAgnostic": true,
    "identitySource": {
      "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
      "instanceId": 10010,
      "authEntityId": 10010,
      "displayName": "10010",
      "authEntityClass": 1738953278,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "state": "INACTIVE",
      "type": "NBAR"
    },
    "indicativeNetworkIdentity": [
      {
        "id": "ce2f8538-a1c6-4668-bea3-e207aa1e26d8",
        "instanceId": 12160,
        "authEntityId": 12160,
        "displayName": "12160",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "539",
        "protocol": "TCP",
        "upperPort": 0
      },
      {
        "id": "9bfa4aa3-c38b-4959-8ee3-66af6bc203fb",
        "instanceId": 12161,
        "authEntityId": 12161,
        "displayName": "12161",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "539",
        "protocol": "UDP",
        "upperPort": 0
      }
    ],
    "networkApplications": [
      {
        "id": "a6fd0df7-ea21-4850-b56e-3dc898d734d9",
        "instanceId": 11063,
        "authEntityId": 11063,
        "displayName": "11063",
        "authEntityClass": -217092956,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "appProtocol": "tcp/udp",
        "applicationGroup": "other",
        "applicationType": "DEFAULT",
        "categoryId": "6daa3dfd-3526-407a-88f0-97824b3f3a24",
        "createTime": 1517966741068,
        "encrypted": "false",
        "engineId": "3",
        "helpString": "Apertus Tech Load Distribution",
        "ignoreConflict": false,
        "lastUpdateTime": 1517966741068,
        "longDescription": "Registered with IANA on port 539 TCP/UDP",
        "name": "apertus-ldp",
        "nbarId": "457",
        "p2pTechnology": "false",
        "popularity": 1,
        "rank": 65535,
        "references": "http://www.iana.org/assignments/service-names-port-numbers/service-names-port-numbers.xml",
        "selectorId": "539",
        "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
        "trafficClass": "BULK_DATA",
        "tunnel": "false"
      }
    ],
    "networkIdentity": [
      {
        "id": "c4c273ae-4e08-44b9-a551-ec2db9125721",
        "instanceId": 12158,
        "authEntityId": 12158,
        "displayName": "12158",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "539",
        "protocol": "UDP",
        "upperPort": 0
      },
      {
        "id": "567861f8-4939-492e-ab03-321f299e71a6",
        "instanceId": 12159,
        "authEntityId": 12159,
        "displayName": "12159",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "539",
        "protocol": "TCP",
        "upperPort": 0
      }
    ],
    "parentScalableGroup": {
      "idRef": "000c15bf-28fe-4fbb-9b69-35e6502721f1"
    },
    "user": [

    ],
    "userGroup": [

    ]
  },
  {
    "id": "1ab64068-cd77-4bf2-9f88-34c12194e867",
    "instanceId": 4597,
    "authEntityId": 4597,
    "displayName": "4597",
    "authEntityClass": -1909073334,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "createTime": 1517966743083,
    "deployed": false,
    "isSeeded": true,
    "isStale": false,
    "lastUpdateTime": 1517966743083,
    "name": "imsp",
    "namespace": "scalablegroup:application",
    "provisioningState": "DEFINED",
    "qualifier": "application",
    "resourceVersion": 0,
    "targetIdList": [

    ],
    "type": "scalablegroup",
    "cfsChangeInfo": [

    ],
    "customProvisions": [

    ],
    "scalableGroupExternalHandle": "imsp",
    "scalableGroupType": "APPLICATION",
    "securityGroupTag": 0,
    "state": "ACTIVE",
    "vnAgnostic": true,
    "identitySource": {
      "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
      "instanceId": 10010,
      "authEntityId": 10010,
      "displayName": "10010",
      "authEntityClass": 1738953278,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "state": "INACTIVE",
      "type": "NBAR"
    },
    "indicativeNetworkIdentity": [
      {
        "id": "800cfa5c-d0ef-4acd-88a8-66e5dc91aa34",
        "instanceId": 13505,
        "authEntityId": 13505,
        "displayName": "13505",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "406",
        "protocol": "TCP",
        "upperPort": 0
      },
      {
        "id": "b7e2d4d7-2d32-4669-8e51-f4010b5546a4",
        "instanceId": 13506,
        "authEntityId": 13506,
        "displayName": "13506",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "406",
        "protocol": "UDP",
        "upperPort": 0
      }
    ],
    "networkApplications": [
      {
        "id": "bc98e462-c23a-4645-832b-a56b2acbfa0c",
        "instanceId": 11547,
        "authEntityId": 11547,
        "displayName": "11547",
        "authEntityClass": -217092956,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "appProtocol": "tcp/udp",
        "applicationGroup": "imap-group",
        "applicationType": "DEFAULT",
        "categoryId": "27953c60-b2ff-4e1a-bc52-0187d8295250",
        "createTime": 1517966743083,
        "encrypted": "false",
        "engineId": "3",
        "helpString": "Interactive Mail Support Protocol",
        "ignoreConflict": false,
        "lastUpdateTime": 1517966743083,
        "longDescription": "The Internet Message Support Protocol (IMSP) is designed to support the provision of mail in a medium to large scale operation. It is intended to be used as a companion to the IMAP4 protocol, providing services which are either outside the scope of mail access or which pertain to environments which must run more than one IMAP4 server in the same mail domain. The services that IMSP provides are extended mailbox management, configuration options, and address books.",
        "name": "imsp",
        "nbarId": "321",
        "p2pTechnology": "false",
        "popularity": 1,
        "rank": 65535,
        "references": "http://en.wikipedia.org/wiki/IMSP",
        "selectorId": "406",
        "subCategory": "b90786f4-0190-45b8-9924-21b3e9682590",
        "trafficClass": "BULK_DATA",
        "tunnel": "false"
      }
    ],
    "networkIdentity": [
      {
        "id": "9b0daf9a-d5f5-4df6-8c01-66c8d98ed502",
        "instanceId": 13504,
        "authEntityId": 13504,
        "displayName": "13504",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "406",
        "protocol": "TCP",
        "upperPort": 0
      },
      {
        "id": "56470d64-77f6-4de1-bee3-4af0949a8e75",
        "instanceId": 13503,
        "authEntityId": 13503,
        "displayName": "13503",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "406",
        "protocol": "UDP",
        "upperPort": 0
      }
    ],
    "parentScalableGroup": {
      "idRef": "8a7ca43a-32bd-40d2-871a-02220a165cb7"
    },
    "user": [

    ],
    "userGroup": [

    ]
  },
  {
    "id": "1ac986ce-4cf6-4a7e-8501-6c4719fced6f",
    "instanceId": 4811,
    "authEntityId": 4811,
    "displayName": "4811",
    "authEntityClass": -1909073334,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "createTime": 1517966743539,
    "deployed": false,
    "isSeeded": true,
    "isStale": false,
    "lastUpdateTime": 1517966743539,
    "name": "mux",
    "namespace": "scalablegroup:application",
    "provisioningState": "DEFINED",
    "qualifier": "application",
    "resourceVersion": 0,
    "targetIdList": [

    ],
    "type": "scalablegroup",
    "cfsChangeInfo": [

    ],
    "customProvisions": [

    ],
    "scalableGroupExternalHandle": "mux",
    "scalableGroupType": "APPLICATION",
    "securityGroupTag": 0,
    "state": "ACTIVE",
    "vnAgnostic": true,
    "identitySource": {
      "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
      "instanceId": 10010,
      "authEntityId": 10010,
      "displayName": "10010",
      "authEntityClass": 1738953278,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "state": "INACTIVE",
      "type": "NBAR"
    },
    "indicativeNetworkIdentity": [
      {
        "id": "1d38932c-ae4d-4ad5-92ec-9db00c814b8f",
        "instanceId": 14074,
        "authEntityId": 14074,
        "displayName": "14074",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipProtocolNumbers": "18",
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "protocol": "MUX",
        "upperPort": 0
      }
    ],
    "networkApplications": [
      {
        "id": "b379dd5f-808f-425c-9f81-045e9e1bbb2f",
        "instanceId": 11760,
        "authEntityId": 11760,
        "displayName": "11760",
        "authEntityClass": -217092956,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "appProtocol": "ip",
        "applicationGroup": "other",
        "applicationType": "DEFAULT",
        "categoryId": "6daa3dfd-3526-407a-88f0-97824b3f3a24",
        "createTime": 1517966743539,
        "encrypted": "unassigned",
        "engineId": "1",
        "helpString": "Multiplexing",
        "ignoreConflict": false,
        "lastUpdateTime": 1517966743539,
        "longDescription": "The Multiplexing Protocol is defined to allow the combining of transmission units of different higher level protocols in one transmission unit of a lower level protocol.",
        "name": "mux",
        "nbarId": "772",
        "p2pTechnology": "unassigned",
        "popularity": 2,
        "rank": 65535,
        "references": "http://www.rfc-editor.org/ien/ien90.txt",
        "selectorId": "18",
        "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
        "trafficClass": "BULK_DATA",
        "tunnel": "unassigned"
      }
    ],
    "networkIdentity": [
      {
        "id": "6ef4a48e-a48f-4b75-ac15-c77becc8f272",
        "instanceId": 14073,
        "authEntityId": 14073,
        "displayName": "14073",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipProtocolNumbers": "18",
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "protocol": "MUX",
        "upperPort": 0
      }
    ],
    "parentScalableGroup": {
      "idRef": "000c15bf-28fe-4fbb-9b69-35e6502721f1"
    },
    "user": [

    ],
    "userGroup": [

    ]
  },
  {
    "id": "1b2a8136-e483-419f-8cfc-7db6be734b64",
    "instanceId": 4162,
    "authEntityId": 4162,
    "displayName": "4162",
    "authEntityClass": -1909073334,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "createTime": 1517966741449,
    "deployed": false,
    "isSeeded": true,
    "isStale": false,
    "lastUpdateTime": 1517966741449,
    "name": "bank-of-america",
    "namespace": "scalablegroup:application",
    "provisioningState": "DEFINED",
    "qualifier": "application",
    "resourceVersion": 0,
    "targetIdList": [

    ],
    "type": "scalablegroup",
    "cfsChangeInfo": [

    ],
    "customProvisions": [

    ],
    "scalableGroupExternalHandle": "bank-of-america",
    "scalableGroupType": "APPLICATION",
    "securityGroupTag": 0,
    "state": "ACTIVE",
    "vnAgnostic": true,
    "identitySource": {
      "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
      "instanceId": 10010,
      "authEntityId": 10010,
      "displayName": "10010",
      "authEntityClass": 1738953278,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "state": "INACTIVE",
      "type": "NBAR"
    },
    "indicativeNetworkIdentity": [

    ],
    "networkApplications": [
      {
        "id": "986cfc79-4106-4ec5-aacd-452db55a0391",
        "instanceId": 11122,
        "authEntityId": 11122,
        "displayName": "11122",
        "authEntityClass": -217092956,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "appProtocol": "tcp/udp",
        "applicationGroup": "other",
        "applicationType": "DEFAULT",
        "categoryId": "878644b5-fe91-45b4-9204-31c84a73ba48",
        "createTime": 1517966741449,
        "encrypted": "false",
        "engineId": "13",
        "helpString": "Bank of America - Global financial services company.",
        "ignoreConflict": false,
        "lastUpdateTime": 1517966741449,
        "longDescription": "Bank of America - Global financial services company.",
        "name": "bank-of-america",
        "nbarId": "1572",
        "p2pTechnology": "false",
        "popularity": 3,
        "rank": 65535,
        "references": "http://bankofamerica.com",
        "selectorId": "643",
        "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
        "trafficClass": "TRANSACTIONAL_DATA",
        "tunnel": "false"
      }
    ],
    "networkIdentity": [
      {
        "id": "ac34507c-937d-4ff8-9708-d6d9395b17aa",
        "instanceId": 12334,
        "authEntityId": 12334,
        "displayName": "12334",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "53,5353",
        "protocol": "UDP",
        "upperPort": 0
      },
      {
        "id": "f82960e2-5b21-4b98-ab50-fdb3f8c3b41a",
        "instanceId": 12335,
        "authEntityId": 12335,
        "displayName": "12335",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "53,80,443,5353",
        "protocol": "TCP",
        "upperPort": 0
      }
    ],
    "parentScalableGroup": {
      "idRef": "28e08de4-d7b2-4521-84ce-bc1e5ee0b9cc"
    },
    "user": [

    ],
    "userGroup": [

    ]
  },
  {
    "id": "1b5287a7-b616-4881-abaf-e000cb4920c4",
    "instanceId": 4470,
    "authEntityId": 4470,
    "displayName": "4470",
    "authEntityClass": -1909073334,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "createTime": 1517966742750,
    "deployed": false,
    "isSeeded": true,
    "isStale": false,
    "lastUpdateTime": 1517966742750,
    "name": "ftps-data",
    "namespace": "scalablegroup:application",
    "provisioningState": "DEFINED",
    "qualifier": "application",
    "resourceVersion": 0,
    "targetIdList": [

    ],
    "type": "scalablegroup",
    "cfsChangeInfo": [

    ],
    "customProvisions": [

    ],
    "scalableGroupExternalHandle": "ftps-data",
    "scalableGroupType": "APPLICATION",
    "securityGroupTag": 0,
    "state": "ACTIVE",
    "vnAgnostic": true,
    "identitySource": {
      "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
      "instanceId": 10010,
      "authEntityId": 10010,
      "displayName": "10010",
      "authEntityClass": 1738953278,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "state": "INACTIVE",
      "type": "NBAR"
    },
    "indicativeNetworkIdentity": [
      {
        "id": "bfdf00b9-1e38-40b4-aa09-3db70a6d9cb2",
        "instanceId": 13168,
        "authEntityId": 13168,
        "displayName": "13168",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "989",
        "protocol": "TCP",
        "upperPort": 0
      },
      {
        "id": "60e98e9a-3a28-4dd3-b683-23932c93450d",
        "instanceId": 13167,
        "authEntityId": 13167,
        "displayName": "13167",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "989",
        "protocol": "UDP",
        "upperPort": 0
      }
    ],
    "networkApplications": [
      {
        "id": "56785265-1ea2-4882-b216-f30a5a8d42c0",
        "instanceId": 11421,
        "authEntityId": 11421,
        "displayName": "11421",
        "authEntityClass": -217092956,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "appProtocol": "tcp/udp",
        "applicationGroup": "ftp-group",
        "applicationType": "DEFAULT",
        "categoryId": "e6619ce8-97de-4c63-a53a-cceddbddc1a8",
        "createTime": 1517966742750,
        "encrypted": "true",
        "engineId": "3",
        "helpString": "Secure FTP (File Transfer Protocol) Data",
        "ignoreConflict": false,
        "lastUpdateTime": 1517966742750,
        "longDescription": "FTPS (also known as FTP Secure and FTP-SSL) is an extension to the commonly used File Transfer Protocol (FTP) that adds support for the Transport Layer Security (TLS) and the Secure Sockets Layer (SSL) cryptographic protocols.",
        "name": "ftps-data",
        "nbarId": "668",
        "p2pTechnology": "false",
        "popularity": 9,
        "rank": 65535,
        "references": "http://en.wikipedia.org/wiki/FTPS",
        "selectorId": "989",
        "subCategory": "50b55e2e-d0d0-4404-bea4-819351565d90",
        "trafficClass": "BULK_DATA",
        "tunnel": "false"
      }
    ],
    "networkIdentity": [
      {
        "id": "7369c14c-edc3-4956-8835-b93370cd2f27",
        "instanceId": 13165,
        "authEntityId": 13165,
        "displayName": "13165",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "989",
        "protocol": "UDP",
        "upperPort": 0
      },
      {
        "id": "4dc0d754-403c-4944-b9ed-0ca212847f25",
        "instanceId": 13166,
        "authEntityId": 13166,
        "displayName": "13166",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "989",
        "protocol": "TCP",
        "upperPort": 0
      }
    ],
    "parentScalableGroup": {
      "idRef": "383363b1-4dd5-49d1-bfe4-34daac437cfb"
    },
    "user": [

    ],
    "userGroup": [

    ]
  },
  {
    "id": "1b532cc5-7b50-46bd-82b1-da3785eeef00",
    "instanceId": 4130,
    "authEntityId": 4130,
    "displayName": "4130",
    "authEntityClass": -1909073334,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "createTime": 1517966741276,
    "deployed": false,
    "isSeeded": true,
    "isStale": false,
    "lastUpdateTime": 1517966741276,
    "name": "at-5",
    "namespace": "scalablegroup:application",
    "provisioningState": "DEFINED",
    "qualifier": "application",
    "resourceVersion": 0,
    "targetIdList": [

    ],
    "type": "scalablegroup",
    "cfsChangeInfo": [

    ],
    "customProvisions": [

    ],
    "scalableGroupExternalHandle": "at-5",
    "scalableGroupType": "APPLICATION",
    "securityGroupTag": 0,
    "state": "ACTIVE",
    "vnAgnostic": true,
    "identitySource": {
      "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
      "instanceId": 10010,
      "authEntityId": 10010,
      "displayName": "10010",
      "authEntityClass": 1738953278,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "state": "INACTIVE",
      "type": "NBAR"
    },
    "indicativeNetworkIdentity": [
      {
        "id": "d8813791-9aa5-4742-8f9d-2256afbc717f",
        "instanceId": 12249,
        "authEntityId": 12249,
        "displayName": "12249",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "205",
        "protocol": "UDP",
        "upperPort": 0
      },
      {
        "id": "b5a9c86c-0d25-42d0-bc60-aa83a4f328d4",
        "instanceId": 12250,
        "authEntityId": 12250,
        "displayName": "12250",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "205",
        "protocol": "TCP",
        "upperPort": 0
      }
    ],
    "networkApplications": [
      {
        "id": "156691bd-953c-4392-b982-6495eb1122d6",
        "instanceId": 11092,
        "authEntityId": 11092,
        "displayName": "11092",
        "authEntityClass": -217092956,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "appProtocol": "tcp/udp",
        "applicationGroup": "apple-talk-group",
        "applicationType": "DEFAULT",
        "categoryId": "70fce3cd-f2bd-48de-9020-7b7840b9e7a1",
        "createTime": 1517966741276,
        "encrypted": "false",
        "engineId": "3",
        "helpString": "AppleTalk Unused",
        "ignoreConflict": false,
        "lastUpdateTime": 1517966741276,
        "longDescription": "Registered with IANA on port 205 TCP/UDP",
        "name": "at-5",
        "nbarId": "1103",
        "p2pTechnology": "false",
        "popularity": 1,
        "rank": 65535,
        "references": "http://www.iana.org/assignments/service-names-port-numbers/service-names-port-numbers.xml",
        "selectorId": "205",
        "subCategory": "f3c0f761-d894-4112-ab89-b90fc84fd660",
        "trafficClass": "OPS_ADMIN_MGMT",
        "tunnel": "false"
      }
    ],
    "networkIdentity": [
      {
        "id": "43cc0705-600a-4d2e-b9d0-4b6a9af734a8",
        "instanceId": 12247,
        "authEntityId": 12247,
        "displayName": "12247",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "205",
        "protocol": "UDP",
        "upperPort": 0
      },
      {
        "id": "c98a91ef-1ca5-464a-a29a-88ff0486dc64",
        "instanceId": 12248,
        "authEntityId": 12248,
        "displayName": "12248",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "205",
        "protocol": "TCP",
        "upperPort": 0
      }
    ],
    "parentScalableGroup": {
      "idRef": "000c15bf-28fe-4fbb-9b69-35e6502721f1"
    },
    "user": [

    ],
    "userGroup": [

    ]
  },
  {
    "id": "1b5905fa-98d5-4cc4-bac9-1816fda53bfa",
    "instanceId": 4864,
    "authEntityId": 4864,
    "displayName": "4864",
    "authEntityClass": -1909073334,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "createTime": 1517966743640,
    "deployed": false,
    "isSeeded": true,
    "isStale": false,
    "lastUpdateTime": 1517966743640,
    "name": "ni-mail",
    "namespace": "scalablegroup:application",
    "provisioningState": "DEFINED",
    "qualifier": "application",
    "resourceVersion": 0,
    "targetIdList": [

    ],
    "type": "scalablegroup",
    "cfsChangeInfo": [

    ],
    "customProvisions": [

    ],
    "scalableGroupExternalHandle": "ni-mail",
    "scalableGroupType": "APPLICATION",
    "securityGroupTag": 0,
    "state": "ACTIVE",
    "vnAgnostic": true,
    "identitySource": {
      "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
      "instanceId": 10010,
      "authEntityId": 10010,
      "displayName": "10010",
      "authEntityClass": 1738953278,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "state": "INACTIVE",
      "type": "NBAR"
    },
    "indicativeNetworkIdentity": [
      {
        "id": "d698377a-cb22-407f-9d57-8bb4ba823e9b",
        "instanceId": 14234,
        "authEntityId": 14234,
        "displayName": "14234",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "61",
        "protocol": "UDP",
        "upperPort": 0
      },
      {
        "id": "d8924ee7-857f-449c-bda6-f9a41a286e8f",
        "instanceId": 14235,
        "authEntityId": 14235,
        "displayName": "14235",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "61",
        "protocol": "TCP",
        "upperPort": 0
      }
    ],
    "networkApplications": [
      {
        "id": "ccad631f-3060-415a-a209-f151b3652b29",
        "instanceId": 11813,
        "authEntityId": 11813,
        "displayName": "11813",
        "authEntityClass": -217092956,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "appProtocol": "tcp/udp",
        "applicationGroup": "other",
        "applicationType": "DEFAULT",
        "categoryId": "27953c60-b2ff-4e1a-bc52-0187d8295250",
        "createTime": 1517966743640,
        "encrypted": "false",
        "engineId": "3",
        "helpString": "NI MAIL",
        "ignoreConflict": false,
        "lastUpdateTime": 1517966743640,
        "longDescription": "NI MAIL is a program for email mass sending with SOCKS proxy support. It is free for noncommercial use and is most common in Russia.",
        "name": "ni-mail",
        "nbarId": "938",
        "p2pTechnology": "false",
        "popularity": 1,
        "rank": 65535,
        "references": "http://www.kivlab.com/soft/ma/",
        "selectorId": "61",
        "subCategory": "b90786f4-0190-45b8-9924-21b3e9682590",
        "trafficClass": "BULK_DATA",
        "tunnel": "false"
      }
    ],
    "networkIdentity": [
      {
        "id": "674d608a-6ef8-4726-a386-0634dafa7c9a",
        "instanceId": 14232,
        "authEntityId": 14232,
        "displayName": "14232",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "61",
        "protocol": "UDP",
        "upperPort": 0
      },
      {
        "id": "1915911f-d00f-4158-8e79-099bf0c2d807",
        "instanceId": 14233,
        "authEntityId": 14233,
        "displayName": "14233",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "61",
        "protocol": "TCP",
        "upperPort": 0
      }
    ],
    "parentScalableGroup": {
      "idRef": "8a7ca43a-32bd-40d2-871a-02220a165cb7"
    },
    "user": [

    ],
    "userGroup": [

    ]
  },
  {
    "id": "1b5fba3b-bfd9-43c2-b17c-60ca1c6ad8ff",
    "instanceId": 4921,
    "authEntityId": 4921,
    "displayName": "4921",
    "authEntityClass": -1909073334,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "createTime": 1517966743770,
    "deployed": false,
    "isSeeded": true,
    "isStale": false,
    "lastUpdateTime": 1517966743770,
    "name": "oraclenames",
    "namespace": "scalablegroup:application",
    "provisioningState": "DEFINED",
    "qualifier": "application",
    "resourceVersion": 0,
    "targetIdList": [

    ],
    "type": "scalablegroup",
    "cfsChangeInfo": [

    ],
    "customProvisions": [

    ],
    "scalableGroupExternalHandle": "oraclenames",
    "scalableGroupType": "APPLICATION",
    "securityGroupTag": 0,
    "state": "ACTIVE",
    "vnAgnostic": true,
    "identitySource": {
      "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
      "instanceId": 10010,
      "authEntityId": 10010,
      "displayName": "10010",
      "authEntityClass": 1738953278,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "state": "INACTIVE",
      "type": "NBAR"
    },
    "indicativeNetworkIdentity": [
      {
        "id": "c01267f6-49ec-42e2-bfd6-ca5f15e71258",
        "instanceId": 14427,
        "authEntityId": 14427,
        "displayName": "14427",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "1575",
        "protocol": "TCP",
        "upperPort": 0
      },
      {
        "id": "124a69b8-4312-49d5-973c-e5c1f1115bc6",
        "instanceId": 14428,
        "authEntityId": 14428,
        "displayName": "14428",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "1575",
        "protocol": "UDP",
        "upperPort": 0
      }
    ],
    "networkApplications": [
      {
        "id": "66244029-0ace-4266-8f53-2025744e05f7",
        "instanceId": 11870,
        "authEntityId": 11870,
        "displayName": "11870",
        "authEntityClass": -217092956,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "appProtocol": "tcp/udp",
        "applicationGroup": "sqlsvr-group",
        "applicationType": "DEFAULT",
        "categoryId": "cb5915d1-afec-4c30-ac9e-069ff382272f",
        "createTime": 1517966743770,
        "encrypted": "false",
        "engineId": "3",
        "helpString": "oraclenames",
        "ignoreConflict": false,
        "lastUpdateTime": 1517966743770,
        "longDescription": "Oracle Names is a distributed naming service developed to help simplify the setup and administration of global, client/server computing networks. Oracle Names establishes and maintains an integrated system of names servers that work together like a directory service, storing addresses for all the services on a network and making them available to clients wanting to make a connection.",
        "name": "oraclenames",
        "nbarId": "695",
        "p2pTechnology": "false",
        "popularity": 1,
        "rank": 65535,
        "references": "http://docs.oracle.com/cd/A58617_01/network.804/a58230/ch6.htm",
        "selectorId": "1575",
        "subCategory": "b90786f4-0190-45b8-9924-21b3e9682590",
        "trafficClass": "TRANSACTIONAL_DATA",
        "tunnel": "false"
      }
    ],
    "networkIdentity": [
      {
        "id": "d8a3295f-c117-4bf7-aa94-11e4b91932f4",
        "instanceId": 14425,
        "authEntityId": 14425,
        "displayName": "14425",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "1575",
        "protocol": "TCP",
        "upperPort": 0
      },
      {
        "id": "1f4a56d1-904e-4244-bc34-45594f481490",
        "instanceId": 14426,
        "authEntityId": 14426,
        "displayName": "14426",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "1575",
        "protocol": "UDP",
        "upperPort": 0
      }
    ],
    "parentScalableGroup": {
      "idRef": "8718395e-683e-4a2d-a232-100202751271"
    },
    "user": [

    ],
    "userGroup": [

    ]
  },
  {
    "id": "1b6ef55f-7cae-4648-b691-548931349d30",
    "instanceId": 15128,
    "authEntityId": 15128,
    "displayName": "15128",
    "authEntityClass": -1909073334,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "createTime": 1517966744298,
    "deployed": false,
    "isSeeded": true,
    "isStale": false,
    "lastUpdateTime": 1517966744298,
    "name": "sfs-smp-net",
    "namespace": "scalablegroup:application",
    "provisioningState": "DEFINED",
    "qualifier": "application",
    "resourceVersion": 0,
    "targetIdList": [

    ],
    "type": "scalablegroup",
    "cfsChangeInfo": [

    ],
    "customProvisions": [

    ],
    "scalableGroupExternalHandle": "sfs-smp-net",
    "scalableGroupType": "APPLICATION",
    "securityGroupTag": 0,
    "state": "ACTIVE",
    "vnAgnostic": true,
    "identitySource": {
      "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
      "instanceId": 10010,
      "authEntityId": 10010,
      "displayName": "10010",
      "authEntityClass": 1738953278,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "state": "INACTIVE",
      "type": "NBAR"
    },
    "indicativeNetworkIdentity": [
      {
        "id": "46eb6e41-207e-4fe9-9a6a-85ee6a7d9045",
        "instanceId": 17032,
        "authEntityId": 17032,
        "displayName": "17032",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "451",
        "protocol": "TCP",
        "upperPort": 0
      },
      {
        "id": "9df8b725-f932-4478-a5d9-35f93249d070",
        "instanceId": 17033,
        "authEntityId": 17033,
        "displayName": "17033",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "451",
        "protocol": "UDP",
        "upperPort": 0
      }
    ],
    "networkApplications": [
      {
        "id": "765ea8e3-b301-485b-b1ef-06c515edfb95",
        "instanceId": 16071,
        "authEntityId": 16071,
        "displayName": "16071",
        "authEntityClass": -217092956,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "appProtocol": "tcp/udp",
        "applicationGroup": "other",
        "applicationType": "DEFAULT",
        "categoryId": "6daa3dfd-3526-407a-88f0-97824b3f3a24",
        "createTime": 1517966744298,
        "encrypted": "false",
        "engineId": "3",
        "helpString": "Cray Network Semaphore server",
        "ignoreConflict": false,
        "lastUpdateTime": 1517966744298,
        "longDescription": "Registered with IANA on port 451 TCP/UDP",
        "name": "sfs-smp-net",
        "nbarId": "366",
        "p2pTechnology": "false",
        "popularity": 1,
        "rank": 65535,
        "references": "http://www.iana.org/assignments/service-names-port-numbers/service-names-port-numbers.xml",
        "selectorId": "451",
        "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
        "trafficClass": "BULK_DATA",
        "tunnel": "false"
      }
    ],
    "networkIdentity": [
      {
        "id": "2fc5a23f-142c-4c9e-82ea-774b9dc4c31b",
        "instanceId": 17030,
        "authEntityId": 17030,
        "displayName": "17030",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "451",
        "protocol": "UDP",
        "upperPort": 0
      },
      {
        "id": "313b0df5-4c20-402e-aa43-eadc99d95fa5",
        "instanceId": 17031,
        "authEntityId": 17031,
        "displayName": "17031",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "451",
        "protocol": "TCP",
        "upperPort": 0
      }
    ],
    "parentScalableGroup": {
      "idRef": "000c15bf-28fe-4fbb-9b69-35e6502721f1"
    },
    "user": [

    ],
    "userGroup": [

    ]
  },
  {
    "id": "1b7ac635-1efe-4a52-9d5f-ebdab3c9c011",
    "instanceId": 15385,
    "authEntityId": 15385,
    "displayName": "15385",
    "authEntityClass": -1909073334,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "createTime": 1517966744850,
    "deployed": false,
    "isSeeded": true,
    "isStale": false,
    "lastUpdateTime": 1517966744850,
    "name": "wap-wsp-wtp-s",
    "namespace": "scalablegroup:application",
    "provisioningState": "DEFINED",
    "qualifier": "application",
    "resourceVersion": 0,
    "targetIdList": [

    ],
    "type": "scalablegroup",
    "cfsChangeInfo": [

    ],
    "customProvisions": [

    ],
    "scalableGroupExternalHandle": "wap-wsp-wtp-s",
    "scalableGroupType": "APPLICATION",
    "securityGroupTag": 0,
    "state": "ACTIVE",
    "vnAgnostic": true,
    "identitySource": {
      "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
      "instanceId": 10010,
      "authEntityId": 10010,
      "displayName": "10010",
      "authEntityClass": 1738953278,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "state": "INACTIVE",
      "type": "NBAR"
    },
    "indicativeNetworkIdentity": [
      {
        "id": "a2607753-0bd9-4553-a8e3-14a4fe2f27d8",
        "instanceId": 17783,
        "authEntityId": 17783,
        "displayName": "17783",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "9203",
        "protocol": "TCP",
        "upperPort": 0
      },
      {
        "id": "a0124201-0a35-460a-9db6-888c2850b1a6",
        "instanceId": 17784,
        "authEntityId": 17784,
        "displayName": "17784",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "9203",
        "protocol": "UDP",
        "upperPort": 0
      }
    ],
    "networkApplications": [
      {
        "id": "1f23aaf6-f308-4f2c-9a7f-f49291d1af61",
        "instanceId": 16328,
        "authEntityId": 16328,
        "displayName": "16328",
        "authEntityClass": -217092956,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "appProtocol": "tcp/udp",
        "applicationGroup": "wap-group",
        "applicationType": "DEFAULT",
        "categoryId": "6daa3dfd-3526-407a-88f0-97824b3f3a24",
        "createTime": 1517966744850,
        "encrypted": "true",
        "engineId": "3",
        "helpString": "WAP secure session service",
        "ignoreConflict": false,
        "lastUpdateTime": 1517966744850,
        "longDescription": "Wireless Transaction Protocol (WTP) is a standard used in mobile telephony. It is a layer of the Wireless Application Protocol (WAP) that is intended to bring Internet access to mobile phones.",
        "name": "wap-wsp-wtp-s",
        "nbarId": "732",
        "p2pTechnology": "false",
        "popularity": 2,
        "rank": 65535,
        "references": "http://www.openmobilealliance.org/",
        "selectorId": "9203",
        "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
        "trafficClass": "BULK_DATA",
        "tunnel": "false"
      }
    ],
    "networkIdentity": [
      {
        "id": "1d4d8d04-64b1-4226-a6c0-79a47cc8510a",
        "instanceId": 17781,
        "authEntityId": 17781,
        "displayName": "17781",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "9203",
        "protocol": "UDP",
        "upperPort": 0
      },
      {
        "id": "6ae93ec1-f6e3-4bbc-92a9-d929458c05a4",
        "instanceId": 17782,
        "authEntityId": 17782,
        "displayName": "17782",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "9203",
        "protocol": "TCP",
        "upperPort": 0
      }
    ],
    "parentScalableGroup": {
      "idRef": "000c15bf-28fe-4fbb-9b69-35e6502721f1"
    },
    "user": [

    ],
    "userGroup": [

    ]
  },
  {
    "id": "1b815f5c-6833-43fc-8b07-4d6464795175",
    "instanceId": 4595,
    "authEntityId": 4595,
    "displayName": "4595",
    "authEntityClass": -1909073334,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "createTime": 1517966743078,
    "deployed": false,
    "isSeeded": true,
    "isStale": false,
    "lastUpdateTime": 1517966743078,
    "name": "imap",
    "namespace": "scalablegroup:application",
    "provisioningState": "DEFINED",
    "qualifier": "application",
    "resourceVersion": 0,
    "targetIdList": [

    ],
    "type": "scalablegroup",
    "cfsChangeInfo": [

    ],
    "customProvisions": [

    ],
    "scalableGroupExternalHandle": "imap",
    "scalableGroupType": "APPLICATION",
    "securityGroupTag": 0,
    "state": "ACTIVE",
    "vnAgnostic": true,
    "identitySource": {
      "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
      "instanceId": 10010,
      "authEntityId": 10010,
      "displayName": "10010",
      "authEntityClass": 1738953278,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "state": "INACTIVE",
      "type": "NBAR"
    },
    "indicativeNetworkIdentity": [
      {
        "id": "8cb62812-b9c9-4a50-b60e-f0d79f003f81",
        "instanceId": 13499,
        "authEntityId": 13499,
        "displayName": "13499",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "143,220",
        "protocol": "TCP",
        "upperPort": 0
      },
      {
        "id": "d794470f-818e-4967-9a1e-48f35727757f",
        "instanceId": 13500,
        "authEntityId": 13500,
        "displayName": "13500",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "143,220",
        "protocol": "UDP",
        "upperPort": 0
      }
    ],
    "networkApplications": [
      {
        "id": "ee30f0c9-5271-481f-b950-abbdd00bc682",
        "instanceId": 11545,
        "authEntityId": 11545,
        "displayName": "11545",
        "authEntityClass": -217092956,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "appProtocol": "tcp/udp",
        "applicationGroup": "imap-group",
        "applicationType": "DEFAULT",
        "categoryId": "27953c60-b2ff-4e1a-bc52-0187d8295250",
        "createTime": 1517966743078,
        "encrypted": "false",
        "engineId": "3",
        "helpString": "Internet Mail Access Protocol",
        "ignoreConflict": false,
        "lastUpdateTime": 1517966743078,
        "longDescription": "Internet Message Access protocol (IMAP) allows users to acess their email servers and  to receive and send emails. The protocol simulates a local use when in fact it is a connection to a server. An IMAP server usually listens on port 143.",
        "name": "imap",
        "nbarId": "17",
        "p2pTechnology": "false",
        "popularity": 9,
        "rank": 65535,
        "references": "http://tools.ietf.org/html/rfc3501",
        "selectorId": "143",
        "subCategory": "b90786f4-0190-45b8-9924-21b3e9682590",
        "trafficClass": "BULK_DATA",
        "tunnel": "false"
      }
    ],
    "networkIdentity": [
      {
        "id": "13a75305-beeb-4694-8b80-7117d7520e01",
        "instanceId": 13497,
        "authEntityId": 13497,
        "displayName": "13497",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "143,220",
        "protocol": "TCP",
        "upperPort": 0
      },
      {
        "id": "ac3201a6-af16-4cbe-a166-942b7c330971",
        "instanceId": 13498,
        "authEntityId": 13498,
        "displayName": "13498",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "143,220",
        "protocol": "UDP",
        "upperPort": 0
      }
    ],
    "parentScalableGroup": {
      "idRef": "8a7ca43a-32bd-40d2-871a-02220a165cb7"
    },
    "user": [

    ],
    "userGroup": [

    ]
  },
  {
    "id": "1becb9b4-feba-48e6-b9d6-0f049b898732",
    "instanceId": 4661,
    "authEntityId": 4661,
    "displayName": "4661",
    "authEntityClass": -1909073334,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "createTime": 1517966743229,
    "deployed": false,
    "isSeeded": true,
    "isStale": false,
    "lastUpdateTime": 1517966743229,
    "name": "jargon",
    "namespace": "scalablegroup:application",
    "provisioningState": "DEFINED",
    "qualifier": "application",
    "resourceVersion": 0,
    "targetIdList": [

    ],
    "type": "scalablegroup",
    "cfsChangeInfo": [

    ],
    "customProvisions": [

    ],
    "scalableGroupExternalHandle": "jargon",
    "scalableGroupType": "APPLICATION",
    "securityGroupTag": 0,
    "state": "ACTIVE",
    "vnAgnostic": true,
    "identitySource": {
      "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
      "instanceId": 10010,
      "authEntityId": 10010,
      "displayName": "10010",
      "authEntityClass": 1738953278,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "state": "INACTIVE",
      "type": "NBAR"
    },
    "indicativeNetworkIdentity": [
      {
        "id": "71fe065a-4e3c-4aee-bba6-1172607cdac1",
        "instanceId": 13660,
        "authEntityId": 13660,
        "displayName": "13660",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "148",
        "protocol": "UDP",
        "upperPort": 0
      },
      {
        "id": "ec2f083d-929f-4b4c-9ee9-bef197c605f4",
        "instanceId": 13661,
        "authEntityId": 13661,
        "displayName": "13661",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "148",
        "protocol": "TCP",
        "upperPort": 0
      }
    ],
    "networkApplications": [
      {
        "id": "86df40bd-c2c9-437c-9e63-019c5f6f43b9",
        "instanceId": 11611,
        "authEntityId": 11611,
        "displayName": "11611",
        "authEntityClass": -217092956,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "appProtocol": "tcp/udp",
        "applicationGroup": "other",
        "applicationType": "DEFAULT",
        "categoryId": "6daa3dfd-3526-407a-88f0-97824b3f3a24",
        "createTime": 1517966743229,
        "encrypted": "false",
        "engineId": "3",
        "helpString": "Jargon",
        "ignoreConflict": false,
        "lastUpdateTime": 1517966743229,
        "longDescription": "Registered with IANA on port 148 TCP/UDP",
        "name": "jargon",
        "nbarId": "959",
        "p2pTechnology": "false",
        "popularity": 1,
        "rank": 65535,
        "references": "http://www.iana.org/assignments/service-names-port-numbers/service-names-port-numbers.xml",
        "selectorId": "148",
        "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
        "trafficClass": "BULK_DATA",
        "tunnel": "false"
      }
    ],
    "networkIdentity": [
      {
        "id": "57f1a9b9-de85-4d53-a200-7d02a609f127",
        "instanceId": 13658,
        "authEntityId": 13658,
        "displayName": "13658",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "148",
        "protocol": "UDP",
        "upperPort": 0
      },
      {
        "id": "f2b36661-dbf6-47c9-b258-3b483f1d90a5",
        "instanceId": 13659,
        "authEntityId": 13659,
        "displayName": "13659",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "148",
        "protocol": "TCP",
        "upperPort": 0
      }
    ],
    "parentScalableGroup": {
      "idRef": "000c15bf-28fe-4fbb-9b69-35e6502721f1"
    },
    "user": [

    ],
    "userGroup": [

    ]
  },
  {
    "id": "1c15dc0d-90f5-46c7-a69c-bb2294985a6e",
    "instanceId": 4407,
    "authEntityId": 4407,
    "displayName": "4407",
    "authEntityClass": -1909073334,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "createTime": 1517966742559,
    "deployed": false,
    "isSeeded": true,
    "isStale": false,
    "lastUpdateTime": 1517966742559,
    "name": "emfis-data",
    "namespace": "scalablegroup:application",
    "provisioningState": "DEFINED",
    "qualifier": "application",
    "resourceVersion": 0,
    "targetIdList": [

    ],
    "type": "scalablegroup",
    "cfsChangeInfo": [

    ],
    "customProvisions": [

    ],
    "scalableGroupExternalHandle": "emfis-data",
    "scalableGroupType": "APPLICATION",
    "securityGroupTag": 0,
    "state": "ACTIVE",
    "vnAgnostic": true,
    "identitySource": {
      "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
      "instanceId": 10010,
      "authEntityId": 10010,
      "displayName": "10010",
      "authEntityClass": 1738953278,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "state": "INACTIVE",
      "type": "NBAR"
    },
    "indicativeNetworkIdentity": [
      {
        "id": "7345481a-d4c7-4076-8b9c-4e6761b07c1d",
        "instanceId": 13017,
        "authEntityId": 13017,
        "displayName": "13017",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "140",
        "protocol": "TCP",
        "upperPort": 0
      },
      {
        "id": "f776297a-ff7d-4eac-8521-ad88e6d5c39c",
        "instanceId": 13018,
        "authEntityId": 13018,
        "displayName": "13018",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "140",
        "protocol": "UDP",
        "upperPort": 0
      }
    ],
    "networkApplications": [
      {
        "id": "d4f74cb6-769e-4295-8af9-96700f724764",
        "instanceId": 11358,
        "authEntityId": 11358,
        "displayName": "11358",
        "authEntityClass": -217092956,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "appProtocol": "tcp/udp",
        "applicationGroup": "other",
        "applicationType": "DEFAULT",
        "categoryId": "6daa3dfd-3526-407a-88f0-97824b3f3a24",
        "createTime": 1517966742558,
        "encrypted": "false",
        "engineId": "3",
        "helpString": "EMFIS Data Service",
        "ignoreConflict": false,
        "lastUpdateTime": 1517966742558,
        "longDescription": "Registered with IANA on port 140 TCP/UDP",
        "name": "emfis-data",
        "nbarId": "929",
        "p2pTechnology": "false",
        "popularity": 1,
        "rank": 65535,
        "references": "http://www.iana.org/assignments/service-names-port-numbers/service-names-port-numbers.xml",
        "selectorId": "140",
        "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
        "trafficClass": "BULK_DATA",
        "tunnel": "false"
      }
    ],
    "networkIdentity": [
      {
        "id": "c5a05c8a-3b45-4c7f-afe7-2fe6ba7ce91a",
        "instanceId": 13015,
        "authEntityId": 13015,
        "displayName": "13015",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "140",
        "protocol": "TCP",
        "upperPort": 0
      },
      {
        "id": "e747d657-1a69-469b-8be3-717871b6b3f8",
        "instanceId": 13016,
        "authEntityId": 13016,
        "displayName": "13016",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "140",
        "protocol": "UDP",
        "upperPort": 0
      }
    ],
    "parentScalableGroup": {
      "idRef": "000c15bf-28fe-4fbb-9b69-35e6502721f1"
    },
    "user": [

    ],
    "userGroup": [

    ]
  },
  {
    "id": "1c4b5f3e-3ea1-4f7c-a535-830736094aa7",
    "instanceId": 4272,
    "authEntityId": 4272,
    "displayName": "4272",
    "authEntityClass": -1909073334,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "createTime": 1517966742031,
    "deployed": false,
    "isSeeded": true,
    "isStale": false,
    "lastUpdateTime": 1517966742031,
    "name": "codaauth2",
    "namespace": "scalablegroup:application",
    "provisioningState": "DEFINED",
    "qualifier": "application",
    "resourceVersion": 0,
    "targetIdList": [

    ],
    "type": "scalablegroup",
    "cfsChangeInfo": [

    ],
    "customProvisions": [

    ],
    "scalableGroupExternalHandle": "codaauth2",
    "scalableGroupType": "APPLICATION",
    "securityGroupTag": 0,
    "state": "ACTIVE",
    "vnAgnostic": true,
    "identitySource": {
      "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
      "instanceId": 10010,
      "authEntityId": 10010,
      "displayName": "10010",
      "authEntityClass": 1738953278,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "state": "INACTIVE",
      "type": "NBAR"
    },
    "indicativeNetworkIdentity": [
      {
        "id": "d3dddca1-794d-45fa-bccb-1e6cd292aed3",
        "instanceId": 12590,
        "authEntityId": 12590,
        "displayName": "12590",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "370",
        "protocol": "TCP",
        "upperPort": 0
      },
      {
        "id": "00026e48-3881-402c-b2bf-83ae81bd5604",
        "instanceId": 12591,
        "authEntityId": 12591,
        "displayName": "12591",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "370",
        "protocol": "UDP",
        "upperPort": 0
      }
    ],
    "networkApplications": [
      {
        "id": "88431b8f-1385-4f0d-98d0-18c044f63bda",
        "instanceId": 11225,
        "authEntityId": 11225,
        "displayName": "11225",
        "authEntityClass": -217092956,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "appProtocol": "tcp/udp",
        "applicationGroup": "other",
        "applicationType": "DEFAULT",
        "categoryId": "1fc17362-9334-4c7b-b752-5afdbb574017",
        "createTime": 1517966742031,
        "encrypted": "false",
        "engineId": "3",
        "helpString": "codaauth2",
        "ignoreConflict": false,
        "lastUpdateTime": 1517966742031,
        "longDescription": "Coda Authentication Server represents the authentication procedure, as part of Coda, a distributed file system.",
        "name": "codaauth2",
        "nbarId": "286",
        "p2pTechnology": "false",
        "popularity": 1,
        "rank": 65535,
        "references": "http://www.coda.cs.cmu.edu/",
        "selectorId": "370",
        "subCategory": "e390124a-0fd6-4253-b68e-4b0c1af8cb4b",
        "trafficClass": "OPS_ADMIN_MGMT",
        "tunnel": "false"
      }
    ],
    "networkIdentity": [
      {
        "id": "43408d0a-6650-4171-902c-14d62e7942e6",
        "instanceId": 12588,
        "authEntityId": 12588,
        "displayName": "12588",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "370",
        "protocol": "UDP",
        "upperPort": 0
      },
      {
        "id": "391957e7-3e6c-4fd0-83dd-424115d716ae",
        "instanceId": 12589,
        "authEntityId": 12589,
        "displayName": "12589",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "370",
        "protocol": "TCP",
        "upperPort": 0
      }
    ],
    "parentScalableGroup": {
      "idRef": "7788bf2d-e289-4597-b500-17aacb7e820e"
    },
    "user": [

    ],
    "userGroup": [

    ]
  },
  {
    "id": "1c5f7323-7788-4eb5-a245-4bccf5d477f3",
    "instanceId": 4475,
    "authEntityId": 4475,
    "displayName": "4475",
    "authEntityClass": -1909073334,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "createTime": 1517966742763,
    "deployed": false,
    "isSeeded": true,
    "isStale": false,
    "lastUpdateTime": 1517966742763,
    "name": "gdomap",
    "namespace": "scalablegroup:application",
    "provisioningState": "DEFINED",
    "qualifier": "application",
    "resourceVersion": 0,
    "targetIdList": [

    ],
    "type": "scalablegroup",
    "cfsChangeInfo": [

    ],
    "customProvisions": [

    ],
    "scalableGroupExternalHandle": "gdomap",
    "scalableGroupType": "APPLICATION",
    "securityGroupTag": 0,
    "state": "ACTIVE",
    "vnAgnostic": true,
    "identitySource": {
      "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
      "instanceId": 10010,
      "authEntityId": 10010,
      "displayName": "10010",
      "authEntityClass": 1738953278,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "state": "INACTIVE",
      "type": "NBAR"
    },
    "indicativeNetworkIdentity": [
      {
        "id": "204c073a-4fec-4254-92df-48c2eec6a473",
        "instanceId": 13184,
        "authEntityId": 13184,
        "displayName": "13184",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "538",
        "protocol": "TCP",
        "upperPort": 0
      },
      {
        "id": "e85f75b1-54e6-42e8-bec1-0c778c9fc641",
        "instanceId": 13183,
        "authEntityId": 13183,
        "displayName": "13183",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "538",
        "protocol": "UDP",
        "upperPort": 0
      }
    ],
    "networkApplications": [
      {
        "id": "9f9389a8-3f58-485a-a2e1-f353ff22d0bb",
        "instanceId": 11426,
        "authEntityId": 11426,
        "displayName": "11426",
        "authEntityClass": -217092956,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "appProtocol": "tcp/udp",
        "applicationGroup": "other",
        "applicationType": "DEFAULT",
        "categoryId": "6daa3dfd-3526-407a-88f0-97824b3f3a24",
        "createTime": 1517966742763,
        "encrypted": "false",
        "engineId": "3",
        "helpString": "gdomap",
        "ignoreConflict": false,
        "lastUpdateTime": 1517966742763,
        "longDescription": "The gdomap daemon is used by GNUstep programs to look up distributed objects of processes running across the network (and between different user accounts on a single machine). The deamon is not used for lookup where two processes belonging to the same user are using a host-local connection.",
        "name": "gdomap",
        "nbarId": "456",
        "p2pTechnology": "false",
        "popularity": 1,
        "rank": 65535,
        "references": "http://www.gnustep.org/resources/documentation/Developer/Tools/Reference/gdomap.html",
        "selectorId": "538",
        "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
        "trafficClass": "BULK_DATA",
        "tunnel": "false"
      }
    ],
    "networkIdentity": [
      {
        "id": "8f3314f6-eece-45e8-88a2-9829e18a5a39",
        "instanceId": 13181,
        "authEntityId": 13181,
        "displayName": "13181",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "538",
        "protocol": "TCP",
        "upperPort": 0
      },
      {
        "id": "7ac597b6-3dea-4db9-979d-20dfa61e722b",
        "instanceId": 13182,
        "authEntityId": 13182,
        "displayName": "13182",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "538",
        "protocol": "UDP",
        "upperPort": 0
      }
    ],
    "parentScalableGroup": {
      "idRef": "000c15bf-28fe-4fbb-9b69-35e6502721f1"
    },
    "user": [

    ],
    "userGroup": [

    ]
  },
  {
    "id": "1c8d5ad4-da76-4dd2-b3a9-a76cf11aa3a3",
    "instanceId": 4156,
    "authEntityId": 4156,
    "displayName": "4156",
    "authEntityClass": -1909073334,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "createTime": 1517966741421,
    "deployed": false,
    "isSeeded": true,
    "isStale": false,
    "lastUpdateTime": 1517966741421,
    "name": "backpage",
    "namespace": "scalablegroup:application",
    "provisioningState": "DEFINED",
    "qualifier": "application",
    "resourceVersion": 0,
    "targetIdList": [

    ],
    "type": "scalablegroup",
    "cfsChangeInfo": [

    ],
    "customProvisions": [

    ],
    "scalableGroupExternalHandle": "backpage",
    "scalableGroupType": "APPLICATION",
    "securityGroupTag": 0,
    "state": "ACTIVE",
    "vnAgnostic": true,
    "identitySource": {
      "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
      "instanceId": 10010,
      "authEntityId": 10010,
      "displayName": "10010",
      "authEntityClass": 1738953278,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "state": "INACTIVE",
      "type": "NBAR"
    },
    "indicativeNetworkIdentity": [

    ],
    "networkApplications": [
      {
        "id": "a0ba0c6f-7e9f-4d60-9d14-9f61d3d8ca71",
        "instanceId": 11116,
        "authEntityId": 11116,
        "displayName": "11116",
        "authEntityClass": -217092956,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "appProtocol": "tcp/udp",
        "applicationGroup": "other",
        "applicationType": "DEFAULT",
        "categoryId": "878644b5-fe91-45b4-9204-31c84a73ba48",
        "createTime": 1517966741421,
        "encrypted": "false",
        "engineId": "13",
        "helpString": "Backpage.com - Free classified ads.",
        "ignoreConflict": false,
        "lastUpdateTime": 1517966741421,
        "longDescription": "Backpage.com - Free classified ads.",
        "name": "backpage",
        "nbarId": "1690",
        "p2pTechnology": "false",
        "popularity": 3,
        "rank": 65535,
        "references": "http://backpage.com",
        "selectorId": "760",
        "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
        "trafficClass": "TRANSACTIONAL_DATA",
        "tunnel": "false"
      }
    ],
    "networkIdentity": [
      {
        "id": "dd88590b-b710-4896-9641-1d4410215203",
        "instanceId": 12322,
        "authEntityId": 12322,
        "displayName": "12322",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "53,5353",
        "protocol": "UDP",
        "upperPort": 0
      },
      {
        "id": "4bd5972c-730b-43e3-8668-da6e442f2ec9",
        "instanceId": 12323,
        "authEntityId": 12323,
        "displayName": "12323",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "53,80,443,5353",
        "protocol": "TCP",
        "upperPort": 0
      }
    ],
    "parentScalableGroup": {
      "idRef": "28e08de4-d7b2-4521-84ce-bc1e5ee0b9cc"
    },
    "user": [

    ],
    "userGroup": [

    ]
  },
  {
    "id": "1c95a344-a9e6-4832-a79e-24b63e999cd4",
    "instanceId": 4337,
    "authEntityId": 4337,
    "displayName": "4337",
    "authEntityClass": -1909073334,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "createTime": 1517966742329,
    "deployed": false,
    "isSeeded": true,
    "isStale": false,
    "lastUpdateTime": 1517966742329,
    "name": "dctp",
    "namespace": "scalablegroup:application",
    "provisioningState": "DEFINED",
    "qualifier": "application",
    "resourceVersion": 0,
    "targetIdList": [

    ],
    "type": "scalablegroup",
    "cfsChangeInfo": [

    ],
    "customProvisions": [

    ],
    "scalableGroupExternalHandle": "dctp",
    "scalableGroupType": "APPLICATION",
    "securityGroupTag": 0,
    "state": "ACTIVE",
    "vnAgnostic": true,
    "identitySource": {
      "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
      "instanceId": 10010,
      "authEntityId": 10010,
      "displayName": "10010",
      "authEntityClass": 1738953278,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "state": "INACTIVE",
      "type": "NBAR"
    },
    "indicativeNetworkIdentity": [
      {
        "id": "e99ff5ad-4fb2-4978-9661-56b6d95bd841",
        "instanceId": 12784,
        "authEntityId": 12784,
        "displayName": "12784",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "675",
        "protocol": "TCP",
        "upperPort": 0
      },
      {
        "id": "88b75cc9-3473-4391-a0e7-d537842011d2",
        "instanceId": 12785,
        "authEntityId": 12785,
        "displayName": "12785",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "675",
        "protocol": "UDP",
        "upperPort": 0
      }
    ],
    "networkApplications": [
      {
        "id": "5a619b8b-e8f4-429b-955d-c37dad948ab3",
        "instanceId": 11288,
        "authEntityId": 11288,
        "displayName": "11288",
        "authEntityClass": -217092956,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "appProtocol": "tcp/udp",
        "applicationGroup": "other",
        "applicationType": "DEFAULT",
        "categoryId": "6daa3dfd-3526-407a-88f0-97824b3f3a24",
        "createTime": 1517966742329,
        "encrypted": "false",
        "engineId": "3",
        "helpString": "dctp",
        "ignoreConflict": false,
        "lastUpdateTime": 1517966742329,
        "longDescription": "Registered with IANA on port 675 TCP/UDP",
        "name": "dctp",
        "nbarId": "583",
        "p2pTechnology": "false",
        "popularity": 1,
        "rank": 65535,
        "references": "http://www.iana.org/assignments/service-names-port-numbers/service-names-port-numbers.xml",
        "selectorId": "675",
        "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
        "trafficClass": "BULK_DATA",
        "tunnel": "false"
      }
    ],
    "networkIdentity": [
      {
        "id": "5bfdbf0c-323e-4bb9-bb84-fc2920a0b742",
        "instanceId": 12782,
        "authEntityId": 12782,
        "displayName": "12782",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "675",
        "protocol": "TCP",
        "upperPort": 0
      },
      {
        "id": "546e21c4-55b7-49fd-a834-11a63134eacf",
        "instanceId": 12783,
        "authEntityId": 12783,
        "displayName": "12783",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "675",
        "protocol": "UDP",
        "upperPort": 0
      }
    ],
    "parentScalableGroup": {
      "idRef": "000c15bf-28fe-4fbb-9b69-35e6502721f1"
    },
    "user": [

    ],
    "userGroup": [

    ]
  },
  {
    "id": "1ca3af7e-83a3-4ac1-8203-4bdb217a135b",
    "instanceId": 4512,
    "authEntityId": 4512,
    "displayName": "4512",
    "authEntityClass": -1909073334,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "createTime": 1517966742852,
    "deployed": false,
    "isSeeded": true,
    "isStale": false,
    "lastUpdateTime": 1517966742852,
    "name": "groupwise",
    "namespace": "scalablegroup:application",
    "provisioningState": "DEFINED",
    "qualifier": "application",
    "resourceVersion": 0,
    "targetIdList": [

    ],
    "type": "scalablegroup",
    "cfsChangeInfo": [

    ],
    "customProvisions": [

    ],
    "scalableGroupExternalHandle": "groupwise",
    "scalableGroupType": "APPLICATION",
    "securityGroupTag": 0,
    "state": "ACTIVE",
    "vnAgnostic": true,
    "identitySource": {
      "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
      "instanceId": 10010,
      "authEntityId": 10010,
      "displayName": "10010",
      "authEntityClass": 1738953278,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "state": "INACTIVE",
      "type": "NBAR"
    },
    "indicativeNetworkIdentity": [
      {
        "id": "423e17b1-1d76-4dfd-b5ed-147fc0c6fdb2",
        "instanceId": 13271,
        "authEntityId": 13271,
        "displayName": "13271",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "1677",
        "protocol": "TCP",
        "upperPort": 0
      },
      {
        "id": "ed72b105-ebc4-4c43-becd-1b37de0bdc04",
        "instanceId": 13272,
        "authEntityId": 13272,
        "displayName": "13272",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "1677",
        "protocol": "UDP",
        "upperPort": 0
      }
    ],
    "networkApplications": [
      {
        "id": "3210bb44-c271-4334-8c97-58df79844e25",
        "instanceId": 11463,
        "authEntityId": 11463,
        "displayName": "11463",
        "authEntityClass": -217092956,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "appProtocol": "tcp/udp",
        "applicationGroup": "other",
        "applicationType": "DEFAULT",
        "categoryId": "27953c60-b2ff-4e1a-bc52-0187d8295250",
        "createTime": 1517966742852,
        "encrypted": "false",
        "engineId": "3",
        "helpString": "Groupwise messaging and groupware platform",
        "ignoreConflict": false,
        "lastUpdateTime": 1517966742852,
        "longDescription": "Novell GroupWise is a messaging and collaborative (groupware) platform that provides email, instant messaging, calendar, documents and personal information management. The platform is based on a client-server architecture where client software is available for Windows, Mac OS X, and Linux  and  server software is supported on Windows Server, NetWare, and Linux. GroupWise usually uses TCP/UDP port 1677.",
        "name": "groupwise",
        "nbarId": "1347",
        "p2pTechnology": "false",
        "popularity": 1,
        "rank": 65535,
        "references": "http://www.novell.com/products/groupwise/",
        "selectorId": "1677",
        "subCategory": "b90786f4-0190-45b8-9924-21b3e9682590",
        "trafficClass": "BULK_DATA",
        "tunnel": "false"
      }
    ],
    "networkIdentity": [
      {
        "id": "04373aaf-f529-49e1-a4bc-0ac24453e7e6",
        "instanceId": 13269,
        "authEntityId": 13269,
        "displayName": "13269",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "1677",
        "protocol": "TCP",
        "upperPort": 0
      },
      {
        "id": "6ca26718-22ef-4644-bbcd-5c25a763e96a",
        "instanceId": 13270,
        "authEntityId": 13270,
        "displayName": "13270",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "1677",
        "protocol": "UDP",
        "upperPort": 0
      }
    ],
    "parentScalableGroup": {
      "idRef": "8a7ca43a-32bd-40d2-871a-02220a165cb7"
    },
    "user": [

    ],
    "userGroup": [

    ]
  },
  {
    "id": "1cf04b56-8bf5-4e94-862e-26ffb3dd735b",
    "instanceId": 4819,
    "authEntityId": 4819,
    "displayName": "4819",
    "authEntityClass": -1909073334,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "createTime": 1517966743554,
    "deployed": false,
    "isSeeded": true,
    "isStale": false,
    "lastUpdateTime": 1517966743554,
    "name": "nas",
    "namespace": "scalablegroup:application",
    "provisioningState": "DEFINED",
    "qualifier": "application",
    "resourceVersion": 0,
    "targetIdList": [

    ],
    "type": "scalablegroup",
    "cfsChangeInfo": [

    ],
    "customProvisions": [

    ],
    "scalableGroupExternalHandle": "nas",
    "scalableGroupType": "APPLICATION",
    "securityGroupTag": 0,
    "state": "ACTIVE",
    "vnAgnostic": true,
    "identitySource": {
      "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
      "instanceId": 10010,
      "authEntityId": 10010,
      "displayName": "10010",
      "authEntityClass": 1738953278,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "state": "INACTIVE",
      "type": "NBAR"
    },
    "indicativeNetworkIdentity": [
      {
        "id": "2c6c7cb9-f773-40ea-b892-aa2b2a1c042b",
        "instanceId": 14092,
        "authEntityId": 14092,
        "displayName": "14092",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "991",
        "protocol": "TCP",
        "upperPort": 0
      },
      {
        "id": "6a0fbb02-81d8-4332-8ef8-8fda8a27dc67",
        "instanceId": 14093,
        "authEntityId": 14093,
        "displayName": "14093",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "991",
        "protocol": "UDP",
        "upperPort": 0
      }
    ],
    "networkApplications": [
      {
        "id": "4a0ab977-63eb-4121-8283-3a8c79a18733",
        "instanceId": 11768,
        "authEntityId": 11768,
        "displayName": "11768",
        "authEntityClass": -217092956,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "appProtocol": "tcp/udp",
        "applicationGroup": "other",
        "applicationType": "DEFAULT",
        "categoryId": "1fc17362-9334-4c7b-b752-5afdbb574017",
        "createTime": 1517966743554,
        "encrypted": "false",
        "engineId": "3",
        "helpString": "Netnews Administration System",
        "ignoreConflict": false,
        "lastUpdateTime": 1517966743554,
        "longDescription": "The Netnews Administration System (NAS) is a framework to simplify the administration and usage of network news (also known as Netnews) on the Internet.  Data for the administration of newsgroups and hierarchies are kept in a distributed hierarchical database and are available through a client-server protocol.",
        "name": "nas",
        "nbarId": "670",
        "p2pTechnology": "false",
        "popularity": 1,
        "rank": 65535,
        "references": "http://tools.ietf.org/html/rfc4707",
        "selectorId": "991",
        "subCategory": "f3c0f761-d894-4112-ab89-b90fc84fd660",
        "trafficClass": "OPS_ADMIN_MGMT",
        "tunnel": "false"
      }
    ],
    "networkIdentity": [
      {
        "id": "c6991a5f-cb52-45d9-9043-51a69d719285",
        "instanceId": 14090,
        "authEntityId": 14090,
        "displayName": "14090",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "991",
        "protocol": "TCP",
        "upperPort": 0
      },
      {
        "id": "3b621144-87c4-4dbc-a76e-1dd5e6cbf7e3",
        "instanceId": 14091,
        "authEntityId": 14091,
        "displayName": "14091",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "991",
        "protocol": "UDP",
        "upperPort": 0
      }
    ],
    "parentScalableGroup": {
      "idRef": "5fcd74d4-8726-47f7-a088-c457863da7ef"
    },
    "user": [

    ],
    "userGroup": [

    ]
  },
  {
    "id": "1cf51b41-cfeb-4990-b45c-9e52c8c2923d",
    "instanceId": 4219,
    "authEntityId": 4219,
    "displayName": "4219",
    "authEntityClass": -1909073334,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "createTime": 1517966741778,
    "deployed": false,
    "isSeeded": true,
    "isStale": false,
    "lastUpdateTime": 1517966741778,
    "name": "cddbp-alt",
    "namespace": "scalablegroup:application",
    "provisioningState": "DEFINED",
    "qualifier": "application",
    "resourceVersion": 0,
    "targetIdList": [

    ],
    "type": "scalablegroup",
    "cfsChangeInfo": [

    ],
    "customProvisions": [

    ],
    "scalableGroupExternalHandle": "cddbp-alt",
    "scalableGroupType": "APPLICATION",
    "securityGroupTag": 0,
    "state": "ACTIVE",
    "vnAgnostic": true,
    "identitySource": {
      "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
      "instanceId": 10010,
      "authEntityId": 10010,
      "displayName": "10010",
      "authEntityClass": 1738953278,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "state": "INACTIVE",
      "type": "NBAR"
    },
    "indicativeNetworkIdentity": [
      {
        "id": "9360615c-184f-4f73-ac2a-a7e5be52958d",
        "instanceId": 12477,
        "authEntityId": 12477,
        "displayName": "12477",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "8880",
        "protocol": "UDP",
        "upperPort": 0
      },
      {
        "id": "f3020ca1-1d1a-4f18-ba01-7ae913425e27",
        "instanceId": 12478,
        "authEntityId": 12478,
        "displayName": "12478",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "8880",
        "protocol": "TCP",
        "upperPort": 0
      }
    ],
    "networkApplications": [
      {
        "id": "09cb28bf-75f4-4ab0-9969-b10caae986ec",
        "instanceId": 11174,
        "authEntityId": 11174,
        "displayName": "11174",
        "authEntityClass": -217092956,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "appProtocol": "tcp/udp",
        "applicationGroup": "other",
        "applicationType": "DEFAULT",
        "categoryId": "6daa3dfd-3526-407a-88f0-97824b3f3a24",
        "createTime": 1517966741778,
        "encrypted": "false",
        "engineId": "3",
        "helpString": "CD Data base Protocol",
        "ignoreConflict": false,
        "lastUpdateTime": 1517966741778,
        "longDescription": "Compact Disc Database (CDDB) is a database for software applications to look up audio CD (compact disc) information over the Internet. This is performed by a client which calculates a (nearly) unique disc ID and then queries the database. As a result, the client is able to display the artist name, CD title, track list and some additional information.",
        "name": "cddbp-alt",
        "nbarId": "1378",
        "p2pTechnology": "false",
        "popularity": 3,
        "rank": 65535,
        "references": "http://ftp.freedb.org/pub/freedb/latest/CDDBPROTO",
        "selectorId": "8880",
        "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
        "trafficClass": "BULK_DATA",
        "tunnel": "false"
      }
    ],
    "networkIdentity": [
      {
        "id": "d546745a-d0b9-48bf-8a15-8b744bd4ffc1",
        "instanceId": 12475,
        "authEntityId": 12475,
        "displayName": "12475",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "8880",
        "protocol": "TCP",
        "upperPort": 0
      },
      {
        "id": "2dd82ff4-61c1-437a-8721-3a489dcbc7d1",
        "instanceId": 12476,
        "authEntityId": 12476,
        "displayName": "12476",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "8880",
        "protocol": "UDP",
        "upperPort": 0
      }
    ],
    "parentScalableGroup": {
      "idRef": "000c15bf-28fe-4fbb-9b69-35e6502721f1"
    },
    "user": [

    ],
    "userGroup": [

    ]
  },
  {
    "id": "1cfc1597-0c94-449f-95a2-e5ab2a6deaed",
    "instanceId": 4702,
    "authEntityId": 4702,
    "displayName": "4702",
    "authEntityClass": -1909073334,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "createTime": 1517966743315,
    "deployed": false,
    "isSeeded": true,
    "isStale": false,
    "lastUpdateTime": 1517966743315,
    "name": "lockd",
    "namespace": "scalablegroup:application",
    "provisioningState": "DEFINED",
    "qualifier": "application",
    "resourceVersion": 0,
    "targetIdList": [

    ],
    "type": "scalablegroup",
    "cfsChangeInfo": [

    ],
    "customProvisions": [

    ],
    "scalableGroupExternalHandle": "lockd",
    "scalableGroupType": "APPLICATION",
    "securityGroupTag": 0,
    "state": "ACTIVE",
    "vnAgnostic": true,
    "identitySource": {
      "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
      "instanceId": 10010,
      "authEntityId": 10010,
      "displayName": "10010",
      "authEntityClass": 1738953278,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "state": "INACTIVE",
      "type": "NBAR"
    },
    "indicativeNetworkIdentity": [
      {
        "id": "5dfd33b0-d074-467f-bab5-4ce9bc274fc5",
        "instanceId": 13774,
        "authEntityId": 13774,
        "displayName": "13774",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "4045",
        "protocol": "UDP",
        "upperPort": 0
      },
      {
        "id": "99b46a71-ab0e-48d8-a64f-2a6c821a1f4f",
        "instanceId": 13775,
        "authEntityId": 13775,
        "displayName": "13775",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "4045",
        "protocol": "TCP",
        "upperPort": 0
      }
    ],
    "networkApplications": [
      {
        "id": "5b4f197f-cff4-41bb-b473-bdd82be2d9e5",
        "instanceId": 11652,
        "authEntityId": 11652,
        "displayName": "11652",
        "authEntityClass": -217092956,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "appProtocol": "tcp/udp",
        "applicationGroup": "other",
        "applicationType": "DEFAULT",
        "categoryId": "58aa61dc-dd95-4a6d-9c7c-0ebf16da6dde",
        "createTime": 1517966743315,
        "encrypted": "false",
        "engineId": "3",
        "helpString": "NFS Lock Daemon Manager",
        "ignoreConflict": false,
        "lastUpdateTime": 1517966743315,
        "longDescription": "NFS file locking is mainly provided by the RPC-based network lock manager (NLM) service. The program implementing NLM is usually called rpc.lockd, and must be running on both the NFS client and server machine. When a user process wants to lock a part or the whole of a file from an NFS-mounted volume, the kernel sends the lock request to the local lock daemon. This is usually done using the KLM (Kernel Lock Manager) protocol.",
        "name": "lockd",
        "nbarId": "96",
        "p2pTechnology": "false",
        "popularity": 1,
        "rank": 65535,
        "references": "http://www.swb.de/personal/okir/lockd.html",
        "selectorId": "4045",
        "subCategory": "b90786f4-0190-45b8-9924-21b3e9682590",
        "trafficClass": "TRANSACTIONAL_DATA",
        "tunnel": "false"
      }
    ],
    "networkIdentity": [
      {
        "id": "be38b2a2-23ff-4b9d-bd51-f49dc474654c",
        "instanceId": 13772,
        "authEntityId": 13772,
        "displayName": "13772",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "4045",
        "protocol": "TCP",
        "upperPort": 0
      },
      {
        "id": "772a0487-8310-4da5-bc4f-a07cc569d4a9",
        "instanceId": 13773,
        "authEntityId": 13773,
        "displayName": "13773",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "4045",
        "protocol": "UDP",
        "upperPort": 0
      }
    ],
    "parentScalableGroup": {
      "idRef": "cff11752-fafb-4e22-9e6f-11f44f59a9a3"
    },
    "user": [

    ],
    "userGroup": [

    ]
  },
  {
    "id": "1d125668-1818-47ab-9e6f-a4b21993ddbb",
    "instanceId": 4109,
    "authEntityId": 4109,
    "displayName": "4109",
    "authEntityClass": -1909073334,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "createTime": 1517966741155,
    "deployed": false,
    "isSeeded": true,
    "isStale": false,
    "lastUpdateTime": 1517966741155,
    "name": "applejuice",
    "namespace": "scalablegroup:application",
    "provisioningState": "DEFINED",
    "qualifier": "application",
    "resourceVersion": 0,
    "targetIdList": [

    ],
    "type": "scalablegroup",
    "cfsChangeInfo": [

    ],
    "customProvisions": [

    ],
    "scalableGroupExternalHandle": "applejuice",
    "scalableGroupType": "APPLICATION",
    "securityGroupTag": 0,
    "state": "ACTIVE",
    "vnAgnostic": true,
    "identitySource": {
      "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
      "instanceId": 10010,
      "authEntityId": 10010,
      "displayName": "10010",
      "authEntityClass": 1738953278,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "state": "INACTIVE",
      "type": "NBAR"
    },
    "indicativeNetworkIdentity": [
      {
        "id": "a3c2a823-c078-4b34-9e41-3bbea9821ec7",
        "instanceId": 12177,
        "authEntityId": 12177,
        "displayName": "12177",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "9022",
        "protocol": "UDP",
        "upperPort": 0
      },
      {
        "id": "8bf049e0-6f25-45e7-9937-eb8e9c42bd36",
        "instanceId": 12178,
        "authEntityId": 12178,
        "displayName": "12178",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "9022",
        "protocol": "TCP",
        "upperPort": 0
      }
    ],
    "networkApplications": [
      {
        "id": "a9bc6f93-fa85-49fa-9563-8ec71df83edb",
        "instanceId": 11071,
        "authEntityId": 11071,
        "displayName": "11071",
        "authEntityClass": -217092956,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "appProtocol": "tcp/udp",
        "applicationGroup": "other",
        "applicationType": "DEFAULT",
        "categoryId": "6daa3dfd-3526-407a-88f0-97824b3f3a24",
        "createTime": 1517966741155,
        "encrypted": "false",
        "engineId": "3",
        "helpString": "Apple juice P2P file sharing",
        "ignoreConflict": false,
        "lastUpdateTime": 1517966741155,
        "longDescription": "Apple juice P2P file sharing is a semi-centralized peer-to-peer file sharing network. The network is decentralized over many servers. It offers server and client software for Applejuicenet. The Applejuicenet is used for content distribution with a given Hashlink. The system has a built-in search tool to search servers for keywords.",
        "name": "applejuice",
        "nbarId": "1375",
        "p2pTechnology": "false",
        "popularity": 3,
        "rank": 65535,
        "references": "http://www.applejuicenet.de/",
        "selectorId": "9022",
        "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
        "trafficClass": "BULK_DATA",
        "tunnel": "false"
      }
    ],
    "networkIdentity": [
      {
        "id": "383997fe-02d6-470b-b133-fddb6d6aa21b",
        "instanceId": 12176,
        "authEntityId": 12176,
        "displayName": "12176",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "9022",
        "protocol": "UDP",
        "upperPort": 0
      },
      {
        "id": "651ae53d-628c-4d6f-9d45-b40e52f68114",
        "instanceId": 12175,
        "authEntityId": 12175,
        "displayName": "12175",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "9022",
        "protocol": "TCP",
        "upperPort": 0
      }
    ],
    "parentScalableGroup": {
      "idRef": "000c15bf-28fe-4fbb-9b69-35e6502721f1"
    },
    "user": [

    ],
    "userGroup": [

    ]
  },
  {
    "id": "1d527234-01b6-43db-8cbe-b3dc8232f504",
    "instanceId": 4056,
    "authEntityId": 4056,
    "displayName": "4056",
    "authEntityClass": -1909073334,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "createTime": 1517966740643,
    "deployed": false,
    "isSeeded": true,
    "isStale": false,
    "lastUpdateTime": 1517966740643,
    "name": "active-directory",
    "namespace": "scalablegroup:application",
    "provisioningState": "DEFINED",
    "qualifier": "application",
    "resourceVersion": 0,
    "targetIdList": [

    ],
    "type": "scalablegroup",
    "cfsChangeInfo": [

    ],
    "customProvisions": [

    ],
    "scalableGroupExternalHandle": "active-directory",
    "scalableGroupType": "APPLICATION",
    "securityGroupTag": 0,
    "state": "ACTIVE",
    "vnAgnostic": true,
    "identitySource": {
      "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
      "instanceId": 10010,
      "authEntityId": 10010,
      "displayName": "10010",
      "authEntityClass": 1738953278,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "state": "INACTIVE",
      "type": "NBAR"
    },
    "indicativeNetworkIdentity": [

    ],
    "networkApplications": [
      {
        "id": "23f854e3-4a19-46ff-b91b-a15421cf51de",
        "instanceId": 11028,
        "authEntityId": 11028,
        "displayName": "11028",
        "authEntityClass": -217092956,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "appProtocol": "tcp/udp",
        "applicationGroup": "other",
        "applicationType": "DEFAULT",
        "categoryId": "1fc17362-9334-4c7b-b752-5afdbb574017",
        "createTime": 1517966740642,
        "encrypted": "true",
        "engineId": "13",
        "helpString": "Active Directory Traffic",
        "ignoreConflict": false,
        "lastUpdateTime": 1517966740642,
        "longDescription": "Active-Directory (AD) is a directory service created by Microsoft for Windows domain networks, responsible for authenticating and authorizing all users and computers within a network of Windows domain type, assigning and enforcing security policies for all computers in a network and installing or updating software on network computers",
        "name": "active-directory",
        "nbarId": "1194",
        "p2pTechnology": "false",
        "popularity": 10,
        "rank": 65535,
        "references": "http://en.wikipedia.org/wiki/Active_Directory",
        "selectorId": "473",
        "subCategory": "e390124a-0fd6-4253-b68e-4b0c1af8cb4b",
        "trafficClass": "OPS_ADMIN_MGMT",
        "tunnel": "false"
      }
    ],
    "networkIdentity": [
      {
        "id": "5af66055-4cd5-4959-a13d-b1a42716d9d9",
        "instanceId": 12070,
        "authEntityId": 12070,
        "displayName": "12070",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "135,139,389,443,445",
        "protocol": "TCP",
        "upperPort": 0
      },
      {
        "id": "838f4db7-3a16-4b37-a1f0-aca014cdef9c",
        "instanceId": 12071,
        "authEntityId": 12071,
        "displayName": "12071",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "389",
        "protocol": "UDP",
        "upperPort": 0
      }
    ],
    "parentScalableGroup": {
      "idRef": "7788bf2d-e289-4597-b500-17aacb7e820e"
    },
    "user": [

    ],
    "userGroup": [

    ]
  },
  {
    "id": "1d5c2268-6f44-4124-bc86-23a06668cb44",
    "instanceId": 15022,
    "authEntityId": 15022,
    "displayName": "15022",
    "authEntityClass": -1909073334,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "createTime": 1517966744047,
    "deployed": false,
    "isSeeded": true,
    "isStale": false,
    "lastUpdateTime": 1517966744047,
    "name": "qrh",
    "namespace": "scalablegroup:application",
    "provisioningState": "DEFINED",
    "qualifier": "application",
    "resourceVersion": 0,
    "targetIdList": [

    ],
    "type": "scalablegroup",
    "cfsChangeInfo": [

    ],
    "customProvisions": [

    ],
    "scalableGroupExternalHandle": "qrh",
    "scalableGroupType": "APPLICATION",
    "securityGroupTag": 0,
    "state": "ACTIVE",
    "vnAgnostic": true,
    "identitySource": {
      "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
      "instanceId": 10010,
      "authEntityId": 10010,
      "displayName": "10010",
      "authEntityClass": 1738953278,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "state": "INACTIVE",
      "type": "NBAR"
    },
    "indicativeNetworkIdentity": [
      {
        "id": "1d75e920-119b-4a1b-94a7-481b0015cf57",
        "instanceId": 14695,
        "authEntityId": 14695,
        "displayName": "14695",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "752",
        "protocol": "TCP",
        "upperPort": 0
      },
      {
        "id": "a31b7b6a-d21c-4f37-b8ad-3278e92ddb31",
        "instanceId": 14696,
        "authEntityId": 14696,
        "displayName": "14696",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "752",
        "protocol": "UDP",
        "upperPort": 0
      }
    ],
    "networkApplications": [
      {
        "id": "05dd95ae-5d83-48c6-a6fe-88caaa48b665",
        "instanceId": 11961,
        "authEntityId": 11961,
        "displayName": "11961",
        "authEntityClass": -217092956,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "appProtocol": "tcp/udp",
        "applicationGroup": "other",
        "applicationType": "DEFAULT",
        "categoryId": "6daa3dfd-3526-407a-88f0-97824b3f3a24",
        "createTime": 1517966744047,
        "encrypted": "false",
        "engineId": "3",
        "helpString": "qrh",
        "ignoreConflict": false,
        "lastUpdateTime": 1517966744047,
        "longDescription": "Registered with IANA on port 752 TCP/UDP",
        "name": "qrh",
        "nbarId": "627",
        "p2pTechnology": "false",
        "popularity": 1,
        "rank": 65535,
        "references": "http://www.iana.org/assignments/service-names-port-numbers/service-names-port-numbers.xml",
        "selectorId": "752",
        "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
        "trafficClass": "BULK_DATA",
        "tunnel": "false"
      }
    ],
    "networkIdentity": [
      {
        "id": "f02a5cf3-7bc9-4aae-b6de-ddc06b2318e3",
        "instanceId": 14693,
        "authEntityId": 14693,
        "displayName": "14693",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "752",
        "protocol": "UDP",
        "upperPort": 0
      },
      {
        "id": "9fb8f63d-3c5a-4750-9588-2877d407e5ac",
        "instanceId": 14694,
        "authEntityId": 14694,
        "displayName": "14694",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "752",
        "protocol": "TCP",
        "upperPort": 0
      }
    ],
    "parentScalableGroup": {
      "idRef": "000c15bf-28fe-4fbb-9b69-35e6502721f1"
    },
    "user": [

    ],
    "userGroup": [

    ]
  },
  {
    "id": "1d90eb30-e281-45a9-ba04-6b614d1f4118",
    "instanceId": 4569,
    "authEntityId": 4569,
    "displayName": "4569",
    "authEntityClass": -1909073334,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "createTime": 1517966743011,
    "deployed": false,
    "isSeeded": true,
    "isStale": false,
    "lastUpdateTime": 1517966743011,
    "name": "i-nlsp",
    "namespace": "scalablegroup:application",
    "provisioningState": "DEFINED",
    "qualifier": "application",
    "resourceVersion": 0,
    "targetIdList": [

    ],
    "type": "scalablegroup",
    "cfsChangeInfo": [

    ],
    "customProvisions": [

    ],
    "scalableGroupExternalHandle": "i-nlsp",
    "scalableGroupType": "APPLICATION",
    "securityGroupTag": 0,
    "state": "ACTIVE",
    "vnAgnostic": true,
    "identitySource": {
      "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
      "instanceId": 10010,
      "authEntityId": 10010,
      "displayName": "10010",
      "authEntityClass": 1738953278,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "state": "INACTIVE",
      "type": "NBAR"
    },
    "indicativeNetworkIdentity": [
      {
        "id": "875c6a9d-0004-4284-9ecc-55c05675f2dd",
        "instanceId": 13420,
        "authEntityId": 13420,
        "displayName": "13420",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipProtocolNumbers": "52",
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "protocol": "I_NLSP",
        "upperPort": 0
      }
    ],
    "networkApplications": [
      {
        "id": "031fcf26-d954-4a10-b293-7d7c197c4081",
        "instanceId": 11519,
        "authEntityId": 11519,
        "displayName": "11519",
        "authEntityClass": -217092956,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "appProtocol": "ip",
        "applicationGroup": "other",
        "applicationType": "DEFAULT",
        "categoryId": "6daa3dfd-3526-407a-88f0-97824b3f3a24",
        "createTime": 1517966743011,
        "encrypted": "unassigned",
        "engineId": "1",
        "helpString": "Integrated Net Layer Security TUBA",
        "ignoreConflict": false,
        "lastUpdateTime": 1517966743011,
        "longDescription": "Integrated Net Layer Security Protocol (i-nlsp) was a proposition that might have been used by End Systems (ESs) and Intermediate Systems (ISs) in order to provide security services in support of TUBA (TCP and UDP with Bigger Addresses).",
        "name": "i-nlsp",
        "nbarId": "806",
        "p2pTechnology": "unassigned",
        "popularity": 1,
        "rank": 65535,
        "references": "http://tools.ietf.org/html/draft-ietf-tuba-inlsp-00#section-1",
        "selectorId": "52",
        "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
        "trafficClass": "BULK_DATA",
        "tunnel": "unassigned"
      }
    ],
    "networkIdentity": [
      {
        "id": "9fca8bed-26b3-4d85-bdf8-bc206956825b",
        "instanceId": 13419,
        "authEntityId": 13419,
        "displayName": "13419",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipProtocolNumbers": "52",
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "protocol": "I_NLSP",
        "upperPort": 0
      }
    ],
    "parentScalableGroup": {
      "idRef": "000c15bf-28fe-4fbb-9b69-35e6502721f1"
    },
    "user": [

    ],
    "userGroup": [

    ]
  },
  {
    "id": "1dbb8787-44eb-4968-818c-4b17db81f835",
    "instanceId": 4746,
    "authEntityId": 4746,
    "displayName": "4746",
    "authEntityClass": -1909073334,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "createTime": 1517966743411,
    "deployed": false,
    "isSeeded": true,
    "isStale": false,
    "lastUpdateTime": 1517966743411,
    "name": "micromuse-lm",
    "namespace": "scalablegroup:application",
    "provisioningState": "DEFINED",
    "qualifier": "application",
    "resourceVersion": 0,
    "targetIdList": [

    ],
    "type": "scalablegroup",
    "cfsChangeInfo": [

    ],
    "customProvisions": [

    ],
    "scalableGroupExternalHandle": "micromuse-lm",
    "scalableGroupType": "APPLICATION",
    "securityGroupTag": 0,
    "state": "ACTIVE",
    "vnAgnostic": true,
    "identitySource": {
      "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
      "instanceId": 10010,
      "authEntityId": 10010,
      "displayName": "10010",
      "authEntityClass": 1738953278,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "state": "INACTIVE",
      "type": "NBAR"
    },
    "indicativeNetworkIdentity": [
      {
        "id": "fc0f11be-3f4a-4dfb-96eb-ab9de0484a8e",
        "instanceId": 13902,
        "authEntityId": 13902,
        "displayName": "13902",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "1534",
        "protocol": "UDP",
        "upperPort": 0
      },
      {
        "id": "a8cd2051-1dcc-4e2d-a1b8-92a2f71f0dc7",
        "instanceId": 13903,
        "authEntityId": 13903,
        "displayName": "13903",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "1534",
        "protocol": "TCP",
        "upperPort": 0
      }
    ],
    "networkApplications": [
      {
        "id": "16e4b5d8-3855-4c92-8bd1-1daf37cd2180",
        "instanceId": 11696,
        "authEntityId": 11696,
        "displayName": "11696",
        "authEntityClass": -217092956,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "appProtocol": "tcp/udp",
        "applicationGroup": "other",
        "applicationType": "DEFAULT",
        "categoryId": "1fc17362-9334-4c7b-b752-5afdbb574017",
        "createTime": 1517966743411,
        "encrypted": "false",
        "engineId": "3",
        "helpString": "micromuse-lm",
        "ignoreConflict": false,
        "lastUpdateTime": 1517966743411,
        "longDescription": "Registered with IANA on port 1534 TCP/UDP",
        "name": "micromuse-lm",
        "nbarId": "702",
        "p2pTechnology": "false",
        "popularity": 1,
        "rank": 65535,
        "references": "http://www.iana.org/assignments/service-names-port-numbers/service-names-port-numbers.xml",
        "selectorId": "1534",
        "subCategory": "f3c0f761-d894-4112-ab89-b90fc84fd660",
        "trafficClass": "OPS_ADMIN_MGMT",
        "tunnel": "false"
      }
    ],
    "networkIdentity": [
      {
        "id": "fbfe300d-930d-4105-a5b9-5241eebe51c7",
        "instanceId": 13900,
        "authEntityId": 13900,
        "displayName": "13900",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "1534",
        "protocol": "UDP",
        "upperPort": 0
      },
      {
        "id": "f74c0d0b-f317-4692-b308-5f8025473421",
        "instanceId": 13901,
        "authEntityId": 13901,
        "displayName": "13901",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "1534",
        "protocol": "TCP",
        "upperPort": 0
      }
    ],
    "parentScalableGroup": {
      "idRef": "5fcd74d4-8726-47f7-a088-c457863da7ef"
    },
    "user": [

    ],
    "userGroup": [

    ]
  },
  {
    "id": "1e4023b3-a767-48c5-8d75-42637b5968bf",
    "instanceId": 15123,
    "authEntityId": 15123,
    "displayName": "15123",
    "authEntityClass": -1909073334,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "createTime": 1517966744286,
    "deployed": false,
    "isSeeded": true,
    "isStale": false,
    "lastUpdateTime": 1517966744286,
    "name": "servstat",
    "namespace": "scalablegroup:application",
    "provisioningState": "DEFINED",
    "qualifier": "application",
    "resourceVersion": 0,
    "targetIdList": [

    ],
    "type": "scalablegroup",
    "cfsChangeInfo": [

    ],
    "customProvisions": [

    ],
    "scalableGroupExternalHandle": "servstat",
    "scalableGroupType": "APPLICATION",
    "securityGroupTag": 0,
    "state": "ACTIVE",
    "vnAgnostic": true,
    "identitySource": {
      "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
      "instanceId": 10010,
      "authEntityId": 10010,
      "displayName": "10010",
      "authEntityClass": 1738953278,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "state": "INACTIVE",
      "type": "NBAR"
    },
    "indicativeNetworkIdentity": [
      {
        "id": "7ee02569-2cc7-423c-823c-b36e91578fd9",
        "instanceId": 15012,
        "authEntityId": 15012,
        "displayName": "15012",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "633",
        "protocol": "TCP",
        "upperPort": 0
      },
      {
        "id": "e5635ca4-002e-4199-8641-16728b0a4a12",
        "instanceId": 15013,
        "authEntityId": 15013,
        "displayName": "15013",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "633",
        "protocol": "UDP",
        "upperPort": 0
      }
    ],
    "networkApplications": [
      {
        "id": "a1834324-15a3-4522-bed0-cb94caba0f45",
        "instanceId": 16066,
        "authEntityId": 16066,
        "displayName": "16066",
        "authEntityClass": -217092956,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "appProtocol": "tcp/udp",
        "applicationGroup": "other",
        "applicationType": "DEFAULT",
        "categoryId": "6daa3dfd-3526-407a-88f0-97824b3f3a24",
        "createTime": 1517966744286,
        "encrypted": "false",
        "engineId": "3",
        "helpString": "Service Status update",
        "ignoreConflict": false,
        "lastUpdateTime": 1517966744286,
        "longDescription": "Registered with IANA on port 633 TCP/UDP",
        "name": "servstat",
        "nbarId": "542",
        "p2pTechnology": "false",
        "popularity": 1,
        "rank": 65535,
        "references": "http://www.iana.org/assignments/service-names-port-numbers/service-names-port-numbers.xml",
        "selectorId": "633",
        "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
        "trafficClass": "BULK_DATA",
        "tunnel": "false"
      }
    ],
    "networkIdentity": [
      {
        "id": "49c995de-7c35-43ba-bb11-1cc6737fd0b8",
        "instanceId": 15010,
        "authEntityId": 15010,
        "displayName": "15010",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "633",
        "protocol": "TCP",
        "upperPort": 0
      },
      {
        "id": "2be53b16-e0e9-4e97-b952-0454c7fa7bb9",
        "instanceId": 15011,
        "authEntityId": 15011,
        "displayName": "15011",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "633",
        "protocol": "UDP",
        "upperPort": 0
      }
    ],
    "parentScalableGroup": {
      "idRef": "000c15bf-28fe-4fbb-9b69-35e6502721f1"
    },
    "user": [

    ],
    "userGroup": [

    ]
  },
  {
    "id": "1e5968be-35e4-4093-b2a8-0afefb646eee",
    "instanceId": 4977,
    "authEntityId": 4977,
    "displayName": "4977",
    "authEntityClass": -1909073334,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "createTime": 1517966743955,
    "deployed": false,
    "isSeeded": true,
    "isStale": false,
    "lastUpdateTime": 1517966743955,
    "name": "powerburst",
    "namespace": "scalablegroup:application",
    "provisioningState": "DEFINED",
    "qualifier": "application",
    "resourceVersion": 0,
    "targetIdList": [

    ],
    "type": "scalablegroup",
    "cfsChangeInfo": [

    ],
    "customProvisions": [

    ],
    "scalableGroupExternalHandle": "powerburst",
    "scalableGroupType": "APPLICATION",
    "securityGroupTag": 0,
    "state": "ACTIVE",
    "vnAgnostic": true,
    "identitySource": {
      "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
      "instanceId": 10010,
      "authEntityId": 10010,
      "displayName": "10010",
      "authEntityClass": 1738953278,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "state": "INACTIVE",
      "type": "NBAR"
    },
    "indicativeNetworkIdentity": [
      {
        "id": "fbc6ebfb-6869-48f4-a8cd-bad06c082b3c",
        "instanceId": 14586,
        "authEntityId": 14586,
        "displayName": "14586",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "485",
        "protocol": "TCP",
        "upperPort": 0
      },
      {
        "id": "f8378c3c-7c25-4dea-929a-080718d5e1a8",
        "instanceId": 14587,
        "authEntityId": 14587,
        "displayName": "14587",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "485",
        "protocol": "UDP",
        "upperPort": 0
      }
    ],
    "networkApplications": [
      {
        "id": "6bebc7c9-7d35-403b-9497-95a85b840220",
        "instanceId": 11926,
        "authEntityId": 11926,
        "displayName": "11926",
        "authEntityClass": -217092956,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "appProtocol": "tcp/udp",
        "applicationGroup": "other",
        "applicationType": "DEFAULT",
        "categoryId": "6daa3dfd-3526-407a-88f0-97824b3f3a24",
        "createTime": 1517966743955,
        "encrypted": "false",
        "engineId": "3",
        "helpString": "Air Soft Power Burst",
        "ignoreConflict": false,
        "lastUpdateTime": 1517966743955,
        "longDescription": "Registered with IANA on port 485 TCP/UDP",
        "name": "powerburst",
        "nbarId": "399",
        "p2pTechnology": "false",
        "popularity": 1,
        "rank": 65535,
        "references": "http://www.iana.org/assignments/service-names-port-numbers/service-names-port-numbers.xml",
        "selectorId": "485",
        "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
        "trafficClass": "BULK_DATA",
        "tunnel": "false"
      }
    ],
    "networkIdentity": [
      {
        "id": "c0d8c1ee-e69c-454d-a3c1-854434d64385",
        "instanceId": 14584,
        "authEntityId": 14584,
        "displayName": "14584",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "485",
        "protocol": "UDP",
        "upperPort": 0
      },
      {
        "id": "f1464db1-9ffe-4a5e-a134-37659a1686b6",
        "instanceId": 14585,
        "authEntityId": 14585,
        "displayName": "14585",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "485",
        "protocol": "TCP",
        "upperPort": 0
      }
    ],
    "parentScalableGroup": {
      "idRef": "000c15bf-28fe-4fbb-9b69-35e6502721f1"
    },
    "user": [

    ],
    "userGroup": [

    ]
  },
  {
    "id": "1e602efd-e0b5-4675-8a86-ae8c4ce85c7d",
    "instanceId": 4457,
    "authEntityId": 4457,
    "displayName": "4457",
    "authEntityClass": -1909073334,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "createTime": 1517966742719,
    "deployed": false,
    "isSeeded": true,
    "isStale": false,
    "lastUpdateTime": 1517966742719,
    "name": "flexlm",
    "namespace": "scalablegroup:application",
    "provisioningState": "DEFINED",
    "qualifier": "application",
    "resourceVersion": 0,
    "targetIdList": [

    ],
    "type": "scalablegroup",
    "cfsChangeInfo": [

    ],
    "customProvisions": [

    ],
    "scalableGroupExternalHandle": "flexlm",
    "scalableGroupType": "APPLICATION",
    "securityGroupTag": 0,
    "state": "ACTIVE",
    "vnAgnostic": true,
    "identitySource": {
      "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
      "instanceId": 10010,
      "authEntityId": 10010,
      "displayName": "10010",
      "authEntityClass": 1738953278,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "state": "INACTIVE",
      "type": "NBAR"
    },
    "indicativeNetworkIdentity": [
      {
        "id": "780eee6c-a91e-4aa0-b3a1-8c31f4913cf5",
        "instanceId": 13137,
        "authEntityId": 13137,
        "displayName": "13137",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "744",
        "protocol": "UDP",
        "upperPort": 0
      },
      {
        "id": "3724bdf9-e493-4e41-b133-cf7fa8bb5460",
        "instanceId": 13138,
        "authEntityId": 13138,
        "displayName": "13138",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "744",
        "protocol": "TCP",
        "upperPort": 0
      }
    ],
    "networkApplications": [
      {
        "id": "8763b06d-985e-498a-b94d-6390d1d73795",
        "instanceId": 11408,
        "authEntityId": 11408,
        "displayName": "11408",
        "authEntityClass": -217092956,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "appProtocol": "tcp/udp",
        "applicationGroup": "other",
        "applicationType": "DEFAULT",
        "categoryId": "6daa3dfd-3526-407a-88f0-97824b3f3a24",
        "createTime": 1517966742719,
        "encrypted": "false",
        "engineId": "3",
        "helpString": "Flexible License Manager",
        "ignoreConflict": false,
        "lastUpdateTime": 1517966742719,
        "longDescription": "FlexNet Publisher (formerly known as FLEXlm) is a software license manager which implements license management and is intended to be used in corporate environments to provide floating licenses to multiple end users of computer software.",
        "name": "flexlm",
        "nbarId": "620",
        "p2pTechnology": "false",
        "popularity": 1,
        "rank": 65535,
        "references": "http://www.flexerasoftware.com/products/flexnet-publisher.htm",
        "selectorId": "744",
        "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
        "trafficClass": "BULK_DATA",
        "tunnel": "false"
      }
    ],
    "networkIdentity": [
      {
        "id": "a2ae27af-d6e5-49a5-bcd7-04039ce4116b",
        "instanceId": 13136,
        "authEntityId": 13136,
        "displayName": "13136",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "744",
        "protocol": "TCP",
        "upperPort": 0
      },
      {
        "id": "fe4855ab-88a2-485a-852a-f1d7287e6cb4",
        "instanceId": 13135,
        "authEntityId": 13135,
        "displayName": "13135",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "744",
        "protocol": "UDP",
        "upperPort": 0
      }
    ],
    "parentScalableGroup": {
      "idRef": "000c15bf-28fe-4fbb-9b69-35e6502721f1"
    },
    "user": [

    ],
    "userGroup": [

    ]
  },
  {
    "id": "1e8efa01-467a-404c-8a3e-0f218aa67e73",
    "instanceId": 15026,
    "authEntityId": 15026,
    "displayName": "15026",
    "authEntityClass": -1909073334,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "createTime": 1517966744057,
    "deployed": false,
    "isSeeded": true,
    "isStale": false,
    "lastUpdateTime": 1517966744057,
    "name": "radmin-port",
    "namespace": "scalablegroup:application",
    "provisioningState": "DEFINED",
    "qualifier": "application",
    "resourceVersion": 0,
    "targetIdList": [

    ],
    "type": "scalablegroup",
    "cfsChangeInfo": [

    ],
    "customProvisions": [

    ],
    "scalableGroupExternalHandle": "radmin-port",
    "scalableGroupType": "APPLICATION",
    "securityGroupTag": 0,
    "state": "ACTIVE",
    "vnAgnostic": true,
    "identitySource": {
      "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
      "instanceId": 10010,
      "authEntityId": 10010,
      "displayName": "10010",
      "authEntityClass": 1738953278,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "state": "INACTIVE",
      "type": "NBAR"
    },
    "indicativeNetworkIdentity": [
      {
        "id": "a0eb7933-67c6-44c7-9878-08829e8e948a",
        "instanceId": 14705,
        "authEntityId": 14705,
        "displayName": "14705",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "4899",
        "protocol": "UDP",
        "upperPort": 0
      },
      {
        "id": "0148f3dd-f631-46d5-95d4-fb0acf973772",
        "instanceId": 14706,
        "authEntityId": 14706,
        "displayName": "14706",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "4899",
        "protocol": "TCP",
        "upperPort": 0
      }
    ],
    "networkApplications": [
      {
        "id": "c20d3eb9-ac37-49e8-a198-7a3f1dd63e66",
        "instanceId": 11965,
        "authEntityId": 11965,
        "displayName": "11965",
        "authEntityClass": -217092956,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "appProtocol": "tcp/udp",
        "applicationGroup": "other",
        "applicationType": "DEFAULT",
        "categoryId": "7e19fdea-6222-4c57-9e66-66c2340d6bc3",
        "createTime": 1517966744057,
        "encrypted": "false",
        "engineId": "3",
        "helpString": "Remote Admin - access software solution",
        "ignoreConflict": false,
        "lastUpdateTime": 1517966744057,
        "longDescription": "Remote Admin (Radmin) is a remote access software solution designed for Windows, enabling a remote computer screen to be viewed on a local monitor.All mouse movements and keystrokes are transferred directly to the remote computer.Files can be transferred to and from the remote computer,and communication with the remote computer's user is possible by either Text Chat or Voice Chat.",
        "name": "radmin-port",
        "nbarId": "1362",
        "p2pTechnology": "false",
        "popularity": 5,
        "rank": 65535,
        "references": "http://www.radmin.com",
        "selectorId": "4899",
        "subCategory": "ff664205-64f3-447e-acff-1ec1ba9a6c31",
        "trafficClass": "MULTIMEDIA_STREAMING",
        "tunnel": "false"
      }
    ],
    "networkIdentity": [
      {
        "id": "ffa37fc7-2647-416a-b023-1a4307abe781",
        "instanceId": 14704,
        "authEntityId": 14704,
        "displayName": "14704",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "4899",
        "protocol": "TCP",
        "upperPort": 0
      },
      {
        "id": "4f356d97-f0dc-4e15-85b2-e2beb3011bfa",
        "instanceId": 14703,
        "authEntityId": 14703,
        "displayName": "14703",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "4899",
        "protocol": "UDP",
        "upperPort": 0
      }
    ],
    "parentScalableGroup": {
      "idRef": "4a6b37c6-7e11-40fe-a6b1-719b24813145"
    },
    "user": [

    ],
    "userGroup": [

    ]
  },
  {
    "id": "1eb5708c-0334-4cd1-9199-90f20f5bab72",
    "instanceId": 15176,
    "authEntityId": 15176,
    "displayName": "15176",
    "authEntityClass": -1909073334,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "createTime": 1517966744409,
    "deployed": false,
    "isSeeded": true,
    "isStale": false,
    "lastUpdateTime": 1517966744409,
    "name": "socks",
    "namespace": "scalablegroup:application",
    "provisioningState": "DEFINED",
    "qualifier": "application",
    "resourceVersion": 0,
    "targetIdList": [

    ],
    "type": "scalablegroup",
    "cfsChangeInfo": [

    ],
    "customProvisions": [

    ],
    "scalableGroupExternalHandle": "socks",
    "scalableGroupType": "APPLICATION",
    "securityGroupTag": 0,
    "state": "ACTIVE",
    "vnAgnostic": true,
    "identitySource": {
      "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
      "instanceId": 10010,
      "authEntityId": 10010,
      "displayName": "10010",
      "authEntityClass": 1738953278,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "state": "INACTIVE",
      "type": "NBAR"
    },
    "indicativeNetworkIdentity": [
      {
        "id": "1942b1a8-9c02-482a-9ef5-9294f4fd8f46",
        "instanceId": 17180,
        "authEntityId": 17180,
        "displayName": "17180",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "1080",
        "protocol": "TCP",
        "upperPort": 0
      }
    ],
    "networkApplications": [
      {
        "id": "d1109a2c-6735-47db-a655-64de05443c55",
        "instanceId": 16119,
        "authEntityId": 16119,
        "displayName": "16119",
        "authEntityClass": -217092956,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "appProtocol": "tcp",
        "applicationGroup": "other",
        "applicationType": "DEFAULT",
        "categoryId": "70fce3cd-f2bd-48de-9020-7b7840b9e7a1",
        "createTime": 1517966744409,
        "encrypted": "false",
        "engineId": "3",
        "helpString": "Generic proxy protocol for TCP/IP-based networking applications",
        "ignoreConflict": false,
        "lastUpdateTime": 1517966744409,
        "longDescription": "SOCKet Secure (SOCKS) is an Internet protocol that facilitates the routing of network packets between client server applications via a proxy server.",
        "name": "socks",
        "nbarId": "39",
        "p2pTechnology": "false",
        "popularity": 7,
        "rank": 65535,
        "references": "http://www.ietf.org/rfc/rfc1928.txt",
        "selectorId": "1080",
        "subCategory": "7cc1b3a6-4102-44e9-b522-0e71afe39a03",
        "trafficClass": "NETWORK_CONTROL",
        "tunnel": "true"
      }
    ],
    "networkIdentity": [
      {
        "id": "0ce7f468-2f4d-4759-ad13-5a803e784300",
        "instanceId": 17179,
        "authEntityId": 17179,
        "displayName": "17179",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "1080",
        "protocol": "TCP",
        "upperPort": 0
      }
    ],
    "parentScalableGroup": {
      "idRef": "79f9d505-477f-4ccd-bd2a-c5fc8097a2e1"
    },
    "user": [

    ],
    "userGroup": [

    ]
  },
  {
    "id": "1ec11bd7-ada5-403a-abd6-270fc49c1151",
    "instanceId": 15115,
    "authEntityId": 15115,
    "displayName": "15115",
    "authEntityClass": -1909073334,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "createTime": 1517966744268,
    "deployed": false,
    "isSeeded": true,
    "isStale": false,
    "lastUpdateTime": 1517966744268,
    "name": "secure-nntp",
    "namespace": "scalablegroup:application",
    "provisioningState": "DEFINED",
    "qualifier": "application",
    "resourceVersion": 0,
    "targetIdList": [

    ],
    "type": "scalablegroup",
    "cfsChangeInfo": [

    ],
    "customProvisions": [

    ],
    "scalableGroupExternalHandle": "secure-nntp",
    "scalableGroupType": "APPLICATION",
    "securityGroupTag": 0,
    "state": "ACTIVE",
    "vnAgnostic": true,
    "identitySource": {
      "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
      "instanceId": 10010,
      "authEntityId": 10010,
      "displayName": "10010",
      "authEntityClass": 1738953278,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "state": "INACTIVE",
      "type": "NBAR"
    },
    "indicativeNetworkIdentity": [
      {
        "id": "5abdbf20-c21a-4977-992f-9b950b5c3935",
        "instanceId": 14982,
        "authEntityId": 14982,
        "displayName": "14982",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "563",
        "protocol": "UDP",
        "upperPort": 0
      },
      {
        "id": "29fa20e0-e253-4db0-ba34-f1fbbd9e31bc",
        "instanceId": 14983,
        "authEntityId": 14983,
        "displayName": "14983",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "563",
        "protocol": "TCP",
        "upperPort": 0
      }
    ],
    "networkApplications": [
      {
        "id": "2e1b3ae6-6e7c-4ac2-8e06-6e5f33780a6e",
        "instanceId": 16058,
        "authEntityId": 16058,
        "displayName": "16058",
        "authEntityClass": -217092956,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "appProtocol": "tcp/udp",
        "applicationGroup": "nntp-group",
        "applicationType": "DEFAULT",
        "categoryId": "f6ec0221-b82d-43e8-a64d-163cbc13263b",
        "createTime": 1517966744268,
        "encrypted": "true",
        "engineId": "3",
        "helpString": "Secure Network News Transfer Protocol",
        "ignoreConflict": false,
        "lastUpdateTime": 1517966744268,
        "longDescription": "Secure Network News Transfer Protocol (SNNTP) is Network News Transfer Protocol (NNTP) over Transport Layer Security (TLS). NNTP is an Internet transfer protocol used for reading and posting Usenet articles and transferring them between news servers.",
        "name": "secure-nntp",
        "nbarId": "29",
        "p2pTechnology": "false",
        "popularity": 4,
        "rank": 65535,
        "references": "http://tools.ietf.org/html/rfc3977",
        "selectorId": "563",
        "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
        "trafficClass": "BULK_DATA",
        "tunnel": "false"
      }
    ],
    "networkIdentity": [
      {
        "id": "d22ed670-c6ba-4813-8017-da68e502cc47",
        "instanceId": 14980,
        "authEntityId": 14980,
        "displayName": "14980",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "563",
        "protocol": "TCP",
        "upperPort": 0
      },
      {
        "id": "ef545381-0a73-48ef-ade7-50f3f30c45ec",
        "instanceId": 14981,
        "authEntityId": 14981,
        "displayName": "14981",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "563",
        "protocol": "UDP",
        "upperPort": 0
      }
    ],
    "parentScalableGroup": {
      "idRef": "28e08de4-d7b2-4521-84ce-bc1e5ee0b9cc"
    },
    "user": [

    ],
    "userGroup": [

    ]
  },
  {
    "id": "1f015b0e-0173-4fc5-a4fb-435dfab64fbe",
    "instanceId": 4632,
    "authEntityId": 4632,
    "displayName": "4632",
    "authEntityClass": -1909073334,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "createTime": 1517966743168,
    "deployed": false,
    "isSeeded": true,
    "isStale": false,
    "lastUpdateTime": 1517966743168,
    "name": "ipv6-opts",
    "namespace": "scalablegroup:application",
    "provisioningState": "DEFINED",
    "qualifier": "application",
    "resourceVersion": 0,
    "targetIdList": [

    ],
    "type": "scalablegroup",
    "cfsChangeInfo": [

    ],
    "customProvisions": [

    ],
    "scalableGroupExternalHandle": "ipv6-opts",
    "scalableGroupType": "APPLICATION",
    "securityGroupTag": 0,
    "state": "ACTIVE",
    "vnAgnostic": true,
    "identitySource": {
      "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
      "instanceId": 10010,
      "authEntityId": 10010,
      "displayName": "10010",
      "authEntityClass": 1738953278,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "state": "INACTIVE",
      "type": "NBAR"
    },
    "indicativeNetworkIdentity": [

    ],
    "networkApplications": [
      {
        "id": "3e36809b-9234-432b-8f5e-ea5592291d14",
        "instanceId": 11582,
        "authEntityId": 11582,
        "displayName": "11582",
        "authEntityClass": -217092956,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "applicationGroup": "other",
        "applicationType": "DEFAULT",
        "categoryId": "6daa3dfd-3526-407a-88f0-97824b3f3a24",
        "createTime": 1517966743168,
        "encrypted": "unassigned",
        "engineId": "1",
        "helpString": "DEPRECATED, traffic will not match",
        "ignoreConflict": false,
        "lastUpdateTime": 1517966743168,
        "longDescription": "Deprecated: Destination Options for IPv6",
        "name": "ipv6-opts",
        "nbarId": "814",
        "p2pTechnology": "unassigned",
        "popularity": 0,
        "rank": 65535,
        "references": "http://www.iana.org/assignments/protocol-numbers/protocol-numbers.xml",
        "selectorId": "60",
        "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
        "trafficClass": "BULK_DATA",
        "tunnel": "unassigned"
      }
    ],
    "networkIdentity": [

    ],
    "parentScalableGroup": {
      "idRef": "000c15bf-28fe-4fbb-9b69-35e6502721f1"
    },
    "user": [

    ],
    "userGroup": [

    ]
  },
  {
    "id": "1f12b59e-33f3-452c-93d1-520b9f4366cf",
    "instanceId": 15174,
    "authEntityId": 15174,
    "displayName": "15174",
    "authEntityClass": -1909073334,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "createTime": 1517966744404,
    "deployed": false,
    "isSeeded": true,
    "isStale": false,
    "lastUpdateTime": 1517966744404,
    "name": "snpp",
    "namespace": "scalablegroup:application",
    "provisioningState": "DEFINED",
    "qualifier": "application",
    "resourceVersion": 0,
    "targetIdList": [

    ],
    "type": "scalablegroup",
    "cfsChangeInfo": [

    ],
    "customProvisions": [

    ],
    "scalableGroupExternalHandle": "snpp",
    "scalableGroupType": "APPLICATION",
    "securityGroupTag": 0,
    "state": "ACTIVE",
    "vnAgnostic": true,
    "identitySource": {
      "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
      "instanceId": 10010,
      "authEntityId": 10010,
      "displayName": "10010",
      "authEntityClass": 1738953278,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "state": "INACTIVE",
      "type": "NBAR"
    },
    "indicativeNetworkIdentity": [
      {
        "id": "8ec05fd5-4539-403c-a229-025481c8e560",
        "instanceId": 17173,
        "authEntityId": 17173,
        "displayName": "17173",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "444",
        "protocol": "UDP",
        "upperPort": 0
      },
      {
        "id": "fb36a2d6-769a-46eb-8dc0-9f4aa5b2b722",
        "instanceId": 17174,
        "authEntityId": 17174,
        "displayName": "17174",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "444",
        "protocol": "TCP",
        "upperPort": 0
      }
    ],
    "networkApplications": [
      {
        "id": "6a4958bc-781b-4f85-8c85-5da681663f83",
        "instanceId": 16117,
        "authEntityId": 16117,
        "displayName": "16117",
        "authEntityClass": -217092956,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "appProtocol": "tcp/udp",
        "applicationGroup": "other",
        "applicationType": "DEFAULT",
        "categoryId": "1fc17362-9334-4c7b-b752-5afdbb574017",
        "createTime": 1517966744404,
        "encrypted": "false",
        "engineId": "3",
        "helpString": "Simple Network Paging Protocol",
        "ignoreConflict": false,
        "lastUpdateTime": 1517966744404,
        "longDescription": "Simple Network Paging Protocol (SNPP) is a protocol that defines a method by which a pager can receive a message over the Internet. It is supported by most major paging providers, and serves as an alternative to the paging modems used by many telecommunications services.",
        "name": "snpp",
        "nbarId": "359",
        "p2pTechnology": "false",
        "popularity": 1,
        "rank": 65535,
        "references": "http://www.ietf.org/rfc/rfc1861.txt",
        "selectorId": "444",
        "subCategory": "68c4f989-5735-4e16-8d1c-6a9d89d5df9c",
        "trafficClass": "SIGNALING",
        "tunnel": "false"
      }
    ],
    "networkIdentity": [
      {
        "id": "38faaee8-e7d1-4575-bd47-fd2dd7a8e4aa",
        "instanceId": 17171,
        "authEntityId": 17171,
        "displayName": "17171",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "444",
        "protocol": "TCP",
        "upperPort": 0
      },
      {
        "id": "8afb5f7d-28c7-4948-ba91-e2749aed3396",
        "instanceId": 17172,
        "authEntityId": 17172,
        "displayName": "17172",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "444",
        "protocol": "UDP",
        "upperPort": 0
      }
    ],
    "parentScalableGroup": {
      "idRef": "a3243a74-3864-4041-a927-0201030da17c"
    },
    "user": [

    ],
    "userGroup": [

    ]
  },
  {
    "id": "1f376586-d2c0-4d21-af5f-e64dd84b4cdf",
    "instanceId": 4927,
    "authEntityId": 4927,
    "displayName": "4927",
    "authEntityClass": -1909073334,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "createTime": 1517966743816,
    "deployed": false,
    "isSeeded": true,
    "isStale": false,
    "lastUpdateTime": 1517966743816,
    "name": "oscar-filetransfer",
    "namespace": "scalablegroup:application",
    "provisioningState": "DEFINED",
    "qualifier": "application",
    "resourceVersion": 0,
    "targetIdList": [

    ],
    "type": "scalablegroup",
    "cfsChangeInfo": [

    ],
    "customProvisions": [

    ],
    "scalableGroupExternalHandle": "oscar-filetransfer",
    "scalableGroupType": "APPLICATION",
    "securityGroupTag": 0,
    "state": "ACTIVE",
    "vnAgnostic": true,
    "identitySource": {
      "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
      "instanceId": 10010,
      "authEntityId": 10010,
      "displayName": "10010",
      "authEntityClass": 1738953278,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "state": "INACTIVE",
      "type": "NBAR"
    },
    "indicativeNetworkIdentity": [

    ],
    "networkApplications": [
      {
        "id": "1f7f2a24-3565-4aa8-a9d9-0030970dd2fc",
        "instanceId": 11876,
        "authEntityId": 11876,
        "displayName": "11876",
        "authEntityClass": -217092956,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "applicationGroup": "aol-group",
        "applicationType": "DEFAULT",
        "categoryId": "b33b2761-d981-4734-810a-a6a1c18ab352",
        "createTime": 1517966743816,
        "encrypted": "false",
        "engineId": "13",
        "helpString": "OSCAR Protocol File-transfer classification",
        "ignoreConflict": false,
        "lastUpdateTime": 1517966743816,
        "longDescription": "Open System for CommunicAtion in Realtime (OSCAR) File Transfer traffic classification. This protocol is mainly used by AIM and ICQ application clients.",
        "name": "oscar-filetransfer",
        "nbarId": "1448",
        "p2pTechnology": "false",
        "popularity": 7,
        "rank": 65535,
        "references": "http://web.archive.org/web/20080308233204/http://dev.aol.com/aim/oscar/",
        "selectorId": "513",
        "subCategory": "50b55e2e-d0d0-4404-bea4-819351565d90",
        "trafficClass": "BULK_DATA",
        "tunnel": "false"
      }
    ],
    "networkIdentity": [

    ],
    "parentScalableGroup": {
      "idRef": "7a1a3bbb-dc84-4a35-82a4-7e650c4c4761"
    },
    "user": [

    ],
    "userGroup": [

    ]
  },
  {
    "id": "1f565515-590e-4ee5-a314-738577117711",
    "instanceId": 4988,
    "authEntityId": 4988,
    "displayName": "4988",
    "authEntityClass": -1909073334,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "createTime": 1517966743985,
    "deployed": false,
    "isSeeded": true,
    "isStale": false,
    "lastUpdateTime": 1517966743985,
    "name": "profile",
    "namespace": "scalablegroup:application",
    "provisioningState": "DEFINED",
    "qualifier": "application",
    "resourceVersion": 0,
    "targetIdList": [

    ],
    "type": "scalablegroup",
    "cfsChangeInfo": [

    ],
    "customProvisions": [

    ],
    "scalableGroupExternalHandle": "profile",
    "scalableGroupType": "APPLICATION",
    "securityGroupTag": 0,
    "state": "ACTIVE",
    "vnAgnostic": true,
    "identitySource": {
      "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
      "instanceId": 10010,
      "authEntityId": 10010,
      "displayName": "10010",
      "authEntityClass": 1738953278,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "state": "INACTIVE",
      "type": "NBAR"
    },
    "indicativeNetworkIdentity": [
      {
        "id": "86ac1f3a-6166-4177-b879-f073073cfc3c",
        "instanceId": 14618,
        "authEntityId": 14618,
        "displayName": "14618",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "136",
        "protocol": "UDP",
        "upperPort": 0
      },
      {
        "id": "09876552-6bb1-4c71-bcd8-496c5ddd7c5b",
        "instanceId": 14619,
        "authEntityId": 14619,
        "displayName": "14619",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "136",
        "protocol": "TCP",
        "upperPort": 0
      }
    ],
    "networkApplications": [
      {
        "id": "4c588867-bc9f-4f6e-a636-ee71d95cce78",
        "instanceId": 11937,
        "authEntityId": 11937,
        "displayName": "11937",
        "authEntityClass": -217092956,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "appProtocol": "tcp/udp",
        "applicationGroup": "other",
        "applicationType": "DEFAULT",
        "categoryId": "6daa3dfd-3526-407a-88f0-97824b3f3a24",
        "createTime": 1517966743985,
        "encrypted": "false",
        "engineId": "3",
        "helpString": "PROFILE Naming System",
        "ignoreConflict": false,
        "lastUpdateTime": 1517966743985,
        "longDescription": "Registered with IANA on port 136 TCP/UDP",
        "name": "profile",
        "nbarId": "923",
        "p2pTechnology": "false",
        "popularity": 1,
        "rank": 65535,
        "references": "http://www.iana.org/assignments/service-names-port-numbers/service-names-port-numbers.xml",
        "selectorId": "136",
        "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
        "trafficClass": "BULK_DATA",
        "tunnel": "false"
      }
    ],
    "networkIdentity": [
      {
        "id": "59fcdb5a-c3e3-4a8d-98c0-2cddc4408b84",
        "instanceId": 14616,
        "authEntityId": 14616,
        "displayName": "14616",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "136",
        "protocol": "TCP",
        "upperPort": 0
      },
      {
        "id": "3855e9d9-6a01-4ba0-aad4-b6b3e186a127",
        "instanceId": 14617,
        "authEntityId": 14617,
        "displayName": "14617",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "136",
        "protocol": "UDP",
        "upperPort": 0
      }
    ],
    "parentScalableGroup": {
      "idRef": "000c15bf-28fe-4fbb-9b69-35e6502721f1"
    },
    "user": [

    ],
    "userGroup": [

    ]
  },
  {
    "id": "1f8e9e4c-af46-44ac-b86a-f1ce81062073",
    "instanceId": 15059,
    "authEntityId": 15059,
    "displayName": "15059",
    "authEntityClass": -1909073334,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "createTime": 1517966744138,
    "deployed": false,
    "isSeeded": true,
    "isStale": false,
    "lastUpdateTime": 1517966744138,
    "name": "rotten-tomatoes",
    "namespace": "scalablegroup:application",
    "provisioningState": "DEFINED",
    "qualifier": "application",
    "resourceVersion": 0,
    "targetIdList": [

    ],
    "type": "scalablegroup",
    "cfsChangeInfo": [

    ],
    "customProvisions": [

    ],
    "scalableGroupExternalHandle": "rotten-tomatoes",
    "scalableGroupType": "APPLICATION",
    "securityGroupTag": 0,
    "state": "ACTIVE",
    "vnAgnostic": true,
    "identitySource": {
      "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
      "instanceId": 10010,
      "authEntityId": 10010,
      "displayName": "10010",
      "authEntityClass": 1738953278,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "state": "INACTIVE",
      "type": "NBAR"
    },
    "indicativeNetworkIdentity": [

    ],
    "networkApplications": [
      {
        "id": "1cd41396-d178-4581-92f1-f86c9bec11bd",
        "instanceId": 11998,
        "authEntityId": 11998,
        "displayName": "11998",
        "authEntityClass": -217092956,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "appProtocol": "tcp/udp",
        "applicationGroup": "other",
        "applicationType": "DEFAULT",
        "categoryId": "878644b5-fe91-45b4-9204-31c84a73ba48",
        "createTime": 1517966744138,
        "encrypted": "false",
        "engineId": "13",
        "helpString": "Rotten Tomatoes - Online information and reviews about new films.",
        "ignoreConflict": false,
        "lastUpdateTime": 1517966744138,
        "longDescription": "Rotten Tomatoes - Online information and reviews about new films.",
        "name": "rotten-tomatoes",
        "nbarId": "1656",
        "p2pTechnology": "false",
        "popularity": 3,
        "rank": 65535,
        "references": "http://rottentomatoes.com",
        "selectorId": "726",
        "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
        "trafficClass": "TRANSACTIONAL_DATA",
        "tunnel": "false"
      }
    ],
    "networkIdentity": [
      {
        "id": "4dd2db4c-215d-4d62-80c9-7430be284af0",
        "instanceId": 14816,
        "authEntityId": 14816,
        "displayName": "14816",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "53,80,443,5353",
        "protocol": "TCP",
        "upperPort": 0
      },
      {
        "id": "391099d5-0675-40be-9a39-0120ee277f87",
        "instanceId": 14815,
        "authEntityId": 14815,
        "displayName": "14815",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "53,5353",
        "protocol": "UDP",
        "upperPort": 0
      }
    ],
    "parentScalableGroup": {
      "idRef": "28e08de4-d7b2-4521-84ce-bc1e5ee0b9cc"
    },
    "user": [

    ],
    "userGroup": [

    ]
  },
  {
    "id": "1fb1c1f5-2e24-4c4b-9302-e9711c5a54d0",
    "instanceId": 4754,
    "authEntityId": 4754,
    "displayName": "4754",
    "authEntityClass": -1909073334,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "createTime": 1517966743426,
    "deployed": false,
    "isSeeded": true,
    "isStale": false,
    "lastUpdateTime": 1517966743426,
    "name": "mobile",
    "namespace": "scalablegroup:application",
    "provisioningState": "DEFINED",
    "qualifier": "application",
    "resourceVersion": 0,
    "targetIdList": [

    ],
    "type": "scalablegroup",
    "cfsChangeInfo": [

    ],
    "customProvisions": [

    ],
    "scalableGroupExternalHandle": "mobile",
    "scalableGroupType": "APPLICATION",
    "securityGroupTag": 0,
    "state": "ACTIVE",
    "vnAgnostic": true,
    "identitySource": {
      "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
      "instanceId": 10010,
      "authEntityId": 10010,
      "displayName": "10010",
      "authEntityClass": 1738953278,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "state": "INACTIVE",
      "type": "NBAR"
    },
    "indicativeNetworkIdentity": [
      {
        "id": "297ee581-6238-4646-94de-75d28b80c516",
        "instanceId": 13925,
        "authEntityId": 13925,
        "displayName": "13925",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipProtocolNumbers": "55",
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "protocol": "MOBILE",
        "upperPort": 0
      }
    ],
    "networkApplications": [
      {
        "id": "0ad69cef-6c4f-4dc2-a582-8dca5768d9a3",
        "instanceId": 11704,
        "authEntityId": 11704,
        "displayName": "11704",
        "authEntityClass": -217092956,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "appProtocol": "ip",
        "applicationGroup": "other",
        "applicationType": "DEFAULT",
        "categoryId": "6daa3dfd-3526-407a-88f0-97824b3f3a24",
        "createTime": 1517966743426,
        "encrypted": "false",
        "engineId": "1",
        "helpString": "IP Mobility",
        "ignoreConflict": false,
        "lastUpdateTime": 1517966743426,
        "longDescription": "Mobile IP (also known as IP mobility) is an Internet Engineering Task Force (IETF) standard communications protocol that is designed to allow mobile device users to move from one network to another while maintaining a permanent IP address.",
        "name": "mobile",
        "nbarId": "809",
        "p2pTechnology": "false",
        "popularity": 2,
        "rank": 65535,
        "references": "http://tools.ietf.org/html/rfc5944",
        "selectorId": "55",
        "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
        "trafficClass": "BULK_DATA",
        "tunnel": "true"
      }
    ],
    "networkIdentity": [
      {
        "id": "ec210be1-a252-40d3-849c-6bc1a56ec34c",
        "instanceId": 13924,
        "authEntityId": 13924,
        "displayName": "13924",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipProtocolNumbers": "55",
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "protocol": "MOBILE",
        "upperPort": 0
      }
    ],
    "parentScalableGroup": {
      "idRef": "000c15bf-28fe-4fbb-9b69-35e6502721f1"
    },
    "user": [

    ],
    "userGroup": [

    ]
  },
  {
    "id": "1fc0293d-4f65-40ee-b3bd-b4fc5cb0302e",
    "instanceId": 15239,
    "authEntityId": 15239,
    "displayName": "15239",
    "authEntityClass": -1909073334,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "createTime": 1517966744559,
    "deployed": false,
    "isSeeded": true,
    "isStale": false,
    "lastUpdateTime": 1517966744559,
    "name": "swift-rvf",
    "namespace": "scalablegroup:application",
    "provisioningState": "DEFINED",
    "qualifier": "application",
    "resourceVersion": 0,
    "targetIdList": [

    ],
    "type": "scalablegroup",
    "cfsChangeInfo": [

    ],
    "customProvisions": [

    ],
    "scalableGroupExternalHandle": "swift-rvf",
    "scalableGroupType": "APPLICATION",
    "securityGroupTag": 0,
    "state": "ACTIVE",
    "vnAgnostic": true,
    "identitySource": {
      "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
      "instanceId": 10010,
      "authEntityId": 10010,
      "displayName": "10010",
      "authEntityClass": 1738953278,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "state": "INACTIVE",
      "type": "NBAR"
    },
    "indicativeNetworkIdentity": [
      {
        "id": "24a30d59-c554-4325-9c74-f9bc840da147",
        "instanceId": 17356,
        "authEntityId": 17356,
        "displayName": "17356",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "97",
        "protocol": "TCP",
        "upperPort": 0
      },
      {
        "id": "fb3ac8cb-ea3c-4bee-bf81-e50a7fc471ed",
        "instanceId": 17357,
        "authEntityId": 17357,
        "displayName": "17357",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "97",
        "protocol": "UDP",
        "upperPort": 0
      }
    ],
    "networkApplications": [
      {
        "id": "a71cd5b5-65f9-469f-8a56-d92c8c4d1cb5",
        "instanceId": 16182,
        "authEntityId": 16182,
        "displayName": "16182",
        "authEntityClass": -217092956,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "appProtocol": "tcp/udp",
        "applicationGroup": "other",
        "applicationType": "DEFAULT",
        "categoryId": "6daa3dfd-3526-407a-88f0-97824b3f3a24",
        "createTime": 1517966744559,
        "encrypted": "false",
        "engineId": "3",
        "helpString": "Swift Remote Virtual File Protocol",
        "ignoreConflict": false,
        "lastUpdateTime": 1517966744559,
        "longDescription": "Registered with IANA on port 97 TCP/UDP",
        "name": "swift-rvf",
        "nbarId": "968",
        "p2pTechnology": "false",
        "popularity": 1,
        "rank": 65535,
        "references": "http://www.iana.org/assignments/service-names-port-numbers/service-names-port-numbers.xml",
        "selectorId": "97",
        "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
        "trafficClass": "BULK_DATA",
        "tunnel": "false"
      }
    ],
    "networkIdentity": [
      {
        "id": "e9bec815-81dc-47c8-9670-4bbab287eec3",
        "instanceId": 17354,
        "authEntityId": 17354,
        "displayName": "17354",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "97",
        "protocol": "UDP",
        "upperPort": 0
      },
      {
        "id": "538f8add-6c40-422b-b429-49f54bd5f454",
        "instanceId": 17355,
        "authEntityId": 17355,
        "displayName": "17355",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "97",
        "protocol": "TCP",
        "upperPort": 0
      }
    ],
    "parentScalableGroup": {
      "idRef": "000c15bf-28fe-4fbb-9b69-35e6502721f1"
    },
    "user": [

    ],
    "userGroup": [

    ]
  },
  {
    "id": "1fd5e31d-f3d8-44f4-8a44-b079621bfc3b",
    "instanceId": 4132,
    "authEntityId": 4132,
    "displayName": "4132",
    "authEntityClass": -1909073334,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "createTime": 1517966741281,
    "deployed": false,
    "isSeeded": true,
    "isStale": false,
    "lastUpdateTime": 1517966741281,
    "name": "at-8",
    "namespace": "scalablegroup:application",
    "provisioningState": "DEFINED",
    "qualifier": "application",
    "resourceVersion": 0,
    "targetIdList": [

    ],
    "type": "scalablegroup",
    "cfsChangeInfo": [

    ],
    "customProvisions": [

    ],
    "scalableGroupExternalHandle": "at-8",
    "scalableGroupType": "APPLICATION",
    "securityGroupTag": 0,
    "state": "ACTIVE",
    "vnAgnostic": true,
    "identitySource": {
      "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
      "instanceId": 10010,
      "authEntityId": 10010,
      "displayName": "10010",
      "authEntityClass": 1738953278,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "state": "INACTIVE",
      "type": "NBAR"
    },
    "indicativeNetworkIdentity": [
      {
        "id": "8e2fb5e4-3312-46ea-846b-ce973cd70321",
        "instanceId": 12257,
        "authEntityId": 12257,
        "displayName": "12257",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "208",
        "protocol": "TCP",
        "upperPort": 0
      },
      {
        "id": "0aa1982a-8139-4baf-8218-787be3c6322c",
        "instanceId": 12258,
        "authEntityId": 12258,
        "displayName": "12258",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "208",
        "protocol": "UDP",
        "upperPort": 0
      }
    ],
    "networkApplications": [
      {
        "id": "1db6600c-b1df-423c-8a97-1a9435887bc6",
        "instanceId": 11094,
        "authEntityId": 11094,
        "displayName": "11094",
        "authEntityClass": -217092956,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "appProtocol": "tcp/udp",
        "applicationGroup": "apple-talk-group",
        "applicationType": "DEFAULT",
        "categoryId": "70fce3cd-f2bd-48de-9020-7b7840b9e7a1",
        "createTime": 1517966741281,
        "encrypted": "false",
        "engineId": "3",
        "helpString": "AppleTalk Unused",
        "ignoreConflict": false,
        "lastUpdateTime": 1517966741281,
        "longDescription": "Registered with IANA on port 208 TCP/UDP",
        "name": "at-8",
        "nbarId": "1106",
        "p2pTechnology": "false",
        "popularity": 1,
        "rank": 65535,
        "references": "http://www.iana.org/assignments/service-names-port-numbers/service-names-port-numbers.xml",
        "selectorId": "208",
        "subCategory": "f3c0f761-d894-4112-ab89-b90fc84fd660",
        "trafficClass": "OPS_ADMIN_MGMT",
        "tunnel": "false"
      }
    ],
    "networkIdentity": [
      {
        "id": "d0fbce97-144a-4f18-a199-15a2ce6c209c",
        "instanceId": 12256,
        "authEntityId": 12256,
        "displayName": "12256",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "208",
        "protocol": "TCP",
        "upperPort": 0
      },
      {
        "id": "ea7cc2e6-b5df-437b-a147-096c01413834",
        "instanceId": 12255,
        "authEntityId": 12255,
        "displayName": "12255",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "208",
        "protocol": "UDP",
        "upperPort": 0
      }
    ],
    "parentScalableGroup": {
      "idRef": "000c15bf-28fe-4fbb-9b69-35e6502721f1"
    },
    "user": [

    ],
    "userGroup": [

    ]
  },
  {
    "id": "20a8f4fe-0643-46f6-9e67-e73bc4114b38",
    "instanceId": 4193,
    "authEntityId": 4193,
    "displayName": "4193",
    "authEntityClass": -1909073334,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "createTime": 1517966741638,
    "deployed": false,
    "isSeeded": true,
    "isStale": false,
    "lastUpdateTime": 1517966741638,
    "name": "blogger",
    "namespace": "scalablegroup:application",
    "provisioningState": "DEFINED",
    "qualifier": "application",
    "resourceVersion": 0,
    "targetIdList": [

    ],
    "type": "scalablegroup",
    "cfsChangeInfo": [

    ],
    "customProvisions": [

    ],
    "scalableGroupExternalHandle": "blogger",
    "scalableGroupType": "APPLICATION",
    "securityGroupTag": 0,
    "state": "ACTIVE",
    "vnAgnostic": true,
    "identitySource": {
      "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
      "instanceId": 10010,
      "authEntityId": 10010,
      "displayName": "10010",
      "authEntityClass": 1738953278,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "state": "INACTIVE",
      "type": "NBAR"
    },
    "indicativeNetworkIdentity": [

    ],
    "networkApplications": [
      {
        "id": "f831004f-d21c-4290-b5ac-8eca7241f2ec",
        "instanceId": 11150,
        "authEntityId": 11150,
        "displayName": "11150",
        "authEntityClass": -217092956,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "appProtocol": "tcp/udp",
        "applicationGroup": "google-group",
        "applicationType": "DEFAULT",
        "categoryId": "9eb6d680-796f-478a-9106-1151f00f7eff",
        "createTime": 1517966741638,
        "encrypted": "true",
        "engineId": "13",
        "helpString": "blogger, social blog-publishing service",
        "ignoreConflict": false,
        "lastUpdateTime": 1517966741638,
        "longDescription": "Blogger is a blog-publishing service that allows private or multi-user  blogs. The blogs are hosted by Google at a sub domain of blogspot.com. It enables to create or edit new or existing  blog and share it with viewers. in addition it enables features such as: label organization, drag-and-drop template editing interface, reading permissions for private blogs and more.",
        "name": "blogger",
        "nbarId": "1461",
        "p2pTechnology": "false",
        "popularity": 8,
        "rank": 65535,
        "references": "http://www.blogger.com/home",
        "selectorId": "525",
        "subCategory": "aab99e59-8589-4a61-933e-f01bff556d78",
        "trafficClass": "TRANSACTIONAL_DATA",
        "tunnel": "false"
      }
    ],
    "networkIdentity": [
      {
        "id": "098b6994-89d9-4591-a8a9-da8e9867e23e",
        "instanceId": 12413,
        "authEntityId": 12413,
        "displayName": "12413",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "53,80,443,5353",
        "protocol": "TCP",
        "upperPort": 0
      },
      {
        "id": "448442fe-4bfd-4c12-b864-03dd92ace9cc",
        "instanceId": 12414,
        "authEntityId": 12414,
        "displayName": "12414",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "53,5353",
        "protocol": "UDP",
        "upperPort": 0
      }
    ],
    "parentScalableGroup": {
      "idRef": "82348eb1-d8b8-4daf-8532-15e1a5f9cdd0"
    },
    "user": [

    ],
    "userGroup": [

    ]
  },
  {
    "id": "20cb303e-bfde-45d7-8a50-5007276a249f",
    "instanceId": 4491,
    "authEntityId": 4491,
    "displayName": "4491",
    "authEntityClass": -1909073334,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "createTime": 1517966742802,
    "deployed": false,
    "isSeeded": true,
    "isStale": false,
    "lastUpdateTime": 1517966742802,
    "name": "goboogy",
    "namespace": "scalablegroup:application",
    "provisioningState": "DEFINED",
    "qualifier": "application",
    "resourceVersion": 0,
    "targetIdList": [

    ],
    "type": "scalablegroup",
    "cfsChangeInfo": [

    ],
    "customProvisions": [

    ],
    "scalableGroupExternalHandle": "goboogy",
    "scalableGroupType": "APPLICATION",
    "securityGroupTag": 0,
    "state": "ACTIVE",
    "vnAgnostic": true,
    "identitySource": {
      "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
      "instanceId": 10010,
      "authEntityId": 10010,
      "displayName": "10010",
      "authEntityClass": 1738953278,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "state": "INACTIVE",
      "type": "NBAR"
    },
    "indicativeNetworkIdentity": [
      {
        "id": "28c5ea85-5d97-4b2c-9dbb-1853cf21f982",
        "instanceId": 13226,
        "authEntityId": 13226,
        "displayName": "13226",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "5325",
        "protocol": "TCP",
        "upperPort": 0
      },
      {
        "id": "9f98e22c-3fca-4175-bdb4-1115b062d52e",
        "instanceId": 13227,
        "authEntityId": 13227,
        "displayName": "13227",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "5325",
        "protocol": "UDP",
        "upperPort": 0
      }
    ],
    "networkApplications": [
      {
        "id": "8261e858-b82b-4013-aa72-e2cd1a1a5a2b",
        "instanceId": 11442,
        "authEntityId": 11442,
        "displayName": "11442",
        "authEntityClass": -217092956,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "appProtocol": "tcp/udp",
        "applicationGroup": "other",
        "applicationType": "DEFAULT",
        "categoryId": "b33b2761-d981-4734-810a-a6a1c18ab352",
        "createTime": 1517966742802,
        "encrypted": "false",
        "engineId": "3",
        "helpString": "P2P file sharing",
        "ignoreConflict": false,
        "lastUpdateTime": 1517966742802,
        "longDescription": "A korean P2P file sharing software.",
        "name": "goboogy",
        "nbarId": "1345",
        "p2pTechnology": "true",
        "popularity": 1,
        "rank": 65535,
        "references": "http://goboogy.com/",
        "selectorId": "5325",
        "subCategory": "50b55e2e-d0d0-4404-bea4-819351565d90",
        "trafficClass": "BULK_DATA",
        "tunnel": "false"
      }
    ],
    "networkIdentity": [
      {
        "id": "139befe3-eef0-41b8-b423-b7c1aaafce54",
        "instanceId": 13224,
        "authEntityId": 13224,
        "displayName": "13224",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "5325",
        "protocol": "TCP",
        "upperPort": 0
      },
      {
        "id": "991e291c-acab-4c14-b3c3-84259fa0c093",
        "instanceId": 13225,
        "authEntityId": 13225,
        "displayName": "13225",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "5325",
        "protocol": "UDP",
        "upperPort": 0
      }
    ],
    "parentScalableGroup": {
      "idRef": "7a1a3bbb-dc84-4a35-82a4-7e650c4c4761"
    },
    "user": [

    ],
    "userGroup": [

    ]
  },
  {
    "id": "2102fcfe-487c-41e6-8fef-1fb57dc2f02a",
    "instanceId": 4880,
    "authEntityId": 4880,
    "displayName": "4880",
    "authEntityClass": -1909073334,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "createTime": 1517966743670,
    "deployed": false,
    "isSeeded": true,
    "isStale": false,
    "lastUpdateTime": 1517966743670,
    "name": "npp",
    "namespace": "scalablegroup:application",
    "provisioningState": "DEFINED",
    "qualifier": "application",
    "resourceVersion": 0,
    "targetIdList": [

    ],
    "type": "scalablegroup",
    "cfsChangeInfo": [

    ],
    "customProvisions": [

    ],
    "scalableGroupExternalHandle": "npp",
    "scalableGroupType": "APPLICATION",
    "securityGroupTag": 0,
    "state": "ACTIVE",
    "vnAgnostic": true,
    "identitySource": {
      "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
      "instanceId": 10010,
      "authEntityId": 10010,
      "displayName": "10010",
      "authEntityClass": 1738953278,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "state": "INACTIVE",
      "type": "NBAR"
    },
    "indicativeNetworkIdentity": [
      {
        "id": "e0c27b26-ec88-458d-bafb-4900e657fd39",
        "instanceId": 14286,
        "authEntityId": 14286,
        "displayName": "14286",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "92",
        "protocol": "UDP",
        "upperPort": 0
      },
      {
        "id": "476bac42-ad10-410f-8ec0-1bd15f28cf52",
        "instanceId": 14287,
        "authEntityId": 14287,
        "displayName": "14287",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "92",
        "protocol": "TCP",
        "upperPort": 0
      }
    ],
    "networkApplications": [
      {
        "id": "78852e5a-ca4f-446d-9659-e5e6aa0110bd",
        "instanceId": 11829,
        "authEntityId": 11829,
        "displayName": "11829",
        "authEntityClass": -217092956,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "appProtocol": "tcp/udp",
        "applicationGroup": "other",
        "applicationType": "DEFAULT",
        "categoryId": "6daa3dfd-3526-407a-88f0-97824b3f3a24",
        "createTime": 1517966743670,
        "encrypted": "false",
        "engineId": "3",
        "helpString": "Network Printing Protocol",
        "ignoreConflict": false,
        "lastUpdateTime": 1517966743670,
        "longDescription": "Registered with IANA on port 92 TCP/UDP",
        "name": "npp",
        "nbarId": "97",
        "p2pTechnology": "false",
        "popularity": 1,
        "rank": 65535,
        "references": "http://www.iana.org/assignments/service-names-port-numbers/service-names-port-numbers.xml",
        "selectorId": "92",
        "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
        "trafficClass": "BULK_DATA",
        "tunnel": "false"
      }
    ],
    "networkIdentity": [
      {
        "id": "dc51642a-aa2d-425a-b8ba-57d82959cf1e",
        "instanceId": 14284,
        "authEntityId": 14284,
        "displayName": "14284",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "92",
        "protocol": "UDP",
        "upperPort": 0
      },
      {
        "id": "e691e5da-027b-41c5-9edd-1336881e9ed3",
        "instanceId": 14285,
        "authEntityId": 14285,
        "displayName": "14285",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "92",
        "protocol": "TCP",
        "upperPort": 0
      }
    ],
    "parentScalableGroup": {
      "idRef": "000c15bf-28fe-4fbb-9b69-35e6502721f1"
    },
    "user": [

    ],
    "userGroup": [

    ]
  },
  {
    "id": "210d7e2e-19ec-48cc-ba8b-e8c10c2b4eff",
    "instanceId": 4532,
    "authEntityId": 4532,
    "displayName": "4532",
    "authEntityClass": -1909073334,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "createTime": 1517966742918,
    "deployed": false,
    "isSeeded": true,
    "isStale": false,
    "lastUpdateTime": 1517966742918,
    "name": "hap",
    "namespace": "scalablegroup:application",
    "provisioningState": "DEFINED",
    "qualifier": "application",
    "resourceVersion": 0,
    "targetIdList": [

    ],
    "type": "scalablegroup",
    "cfsChangeInfo": [

    ],
    "customProvisions": [

    ],
    "scalableGroupExternalHandle": "hap",
    "scalableGroupType": "APPLICATION",
    "securityGroupTag": 0,
    "state": "ACTIVE",
    "vnAgnostic": true,
    "identitySource": {
      "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
      "instanceId": 10010,
      "authEntityId": 10010,
      "displayName": "10010",
      "authEntityClass": 1738953278,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "state": "INACTIVE",
      "type": "NBAR"
    },
    "indicativeNetworkIdentity": [
      {
        "id": "8a62c28f-a7cc-467a-9182-0691d5cb039d",
        "instanceId": 13315,
        "authEntityId": 13315,
        "displayName": "13315",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "661",
        "protocol": "TCP",
        "upperPort": 0
      },
      {
        "id": "613041d6-d9d8-4e19-a116-1451319d45fb",
        "instanceId": 13316,
        "authEntityId": 13316,
        "displayName": "13316",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "661",
        "protocol": "UDP",
        "upperPort": 0
      }
    ],
    "networkApplications": [
      {
        "id": "847007e7-3bf6-45df-a29e-c644bb705583",
        "instanceId": 11483,
        "authEntityId": 11483,
        "displayName": "11483",
        "authEntityClass": -217092956,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "appProtocol": "tcp/udp",
        "applicationGroup": "other",
        "applicationType": "DEFAULT",
        "categoryId": "01adfd0e-0c60-495e-91f8-afba613b2e09",
        "createTime": 1517966742918,
        "encrypted": "false",
        "engineId": "3",
        "helpString": "hap",
        "ignoreConflict": false,
        "lastUpdateTime": 1517966742918,
        "longDescription": "The Host Access Protocol (HAP) is a network layer protocol that defines the different types of host-to-network control messages and host-to-host data messages that may be exchanged over the access link connecting a host and the network packet switch node.  The protocol establishes formats for these messages, and describes procedures for determining when each type of message should be transmitted and what it means when one is received.",
        "name": "hap",
        "nbarId": "569",
        "p2pTechnology": "false",
        "popularity": 1,
        "rank": 65535,
        "references": "http://tools.ietf.org/html/rfc1221",
        "selectorId": "661",
        "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
        "trafficClass": "BULK_DATA",
        "tunnel": "false"
      }
    ],
    "networkIdentity": [
      {
        "id": "4d707dd5-114f-4be6-ba70-e411173914e5",
        "instanceId": 13313,
        "authEntityId": 13313,
        "displayName": "13313",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "661",
        "protocol": "TCP",
        "upperPort": 0
      },
      {
        "id": "2c5b80b8-460c-44d4-b9ff-080ed1a9ed04",
        "instanceId": 13314,
        "authEntityId": 13314,
        "displayName": "13314",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "661",
        "protocol": "UDP",
        "upperPort": 0
      }
    ],
    "parentScalableGroup": {
      "idRef": "5e66d25d-d517-482b-82a8-6f99f07fa786"
    },
    "user": [

    ],
    "userGroup": [

    ]
  },
  {
    "id": "21288781-c062-4a65-8c69-170d984cd61a",
    "instanceId": 15422,
    "authEntityId": 15422,
    "displayName": "15422",
    "authEntityClass": -1909073334,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "createTime": 1517966744937,
    "deployed": false,
    "isSeeded": true,
    "isStale": false,
    "lastUpdateTime": 1517966744937,
    "name": "wired-com",
    "namespace": "scalablegroup:application",
    "provisioningState": "DEFINED",
    "qualifier": "application",
    "resourceVersion": 0,
    "targetIdList": [

    ],
    "type": "scalablegroup",
    "cfsChangeInfo": [

    ],
    "customProvisions": [

    ],
    "scalableGroupExternalHandle": "wired-com",
    "scalableGroupType": "APPLICATION",
    "securityGroupTag": 0,
    "state": "ACTIVE",
    "vnAgnostic": true,
    "identitySource": {
      "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
      "instanceId": 10010,
      "authEntityId": 10010,
      "displayName": "10010",
      "authEntityClass": 1738953278,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "state": "INACTIVE",
      "type": "NBAR"
    },
    "indicativeNetworkIdentity": [

    ],
    "networkApplications": [
      {
        "id": "afe890a6-4a8d-4b3f-9102-7f3e46b7969e",
        "instanceId": 16365,
        "authEntityId": 16365,
        "displayName": "16365",
        "authEntityClass": -217092956,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "appProtocol": "tcp/udp",
        "applicationGroup": "other",
        "applicationType": "DEFAULT",
        "categoryId": "878644b5-fe91-45b4-9204-31c84a73ba48",
        "createTime": 1517966744937,
        "encrypted": "false",
        "engineId": "13",
        "helpString": "Wired.com - Online magazine.",
        "ignoreConflict": false,
        "lastUpdateTime": 1517966744937,
        "longDescription": "Wired.com - Online magazine.",
        "name": "wired-com",
        "nbarId": "1627",
        "p2pTechnology": "false",
        "popularity": 3,
        "rank": 65535,
        "references": "http://wired.com",
        "selectorId": "697",
        "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
        "trafficClass": "TRANSACTIONAL_DATA",
        "tunnel": "false"
      }
    ],
    "networkIdentity": [
      {
        "id": "b9fa6600-3a12-4446-9bb4-1cc023b55033",
        "instanceId": 17849,
        "authEntityId": 17849,
        "displayName": "17849",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "53,80,443,5353",
        "protocol": "TCP",
        "upperPort": 0
      },
      {
        "id": "52278445-6976-4f55-8e7f-00b14fbc0196",
        "instanceId": 17850,
        "authEntityId": 17850,
        "displayName": "17850",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "53,5353",
        "protocol": "UDP",
        "upperPort": 0
      }
    ],
    "parentScalableGroup": {
      "idRef": "28e08de4-d7b2-4521-84ce-bc1e5ee0b9cc"
    },
    "user": [

    ],
    "userGroup": [

    ]
  },
  {
    "id": "214892cd-9eb7-46d3-a24f-3a59ee6928be",
    "instanceId": 4214,
    "authEntityId": 4214,
    "displayName": "4214",
    "authEntityClass": -1909073334,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "createTime": 1517966741760,
    "deployed": false,
    "isSeeded": true,
    "isStale": false,
    "lastUpdateTime": 1517966741760,
    "name": "cassandra",
    "namespace": "scalablegroup:application",
    "provisioningState": "DEFINED",
    "qualifier": "application",
    "resourceVersion": 0,
    "targetIdList": [

    ],
    "type": "scalablegroup",
    "cfsChangeInfo": [

    ],
    "customProvisions": [

    ],
    "scalableGroupExternalHandle": "cassandra",
    "scalableGroupType": "APPLICATION",
    "securityGroupTag": 0,
    "state": "ACTIVE",
    "vnAgnostic": true,
    "identitySource": {
      "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
      "instanceId": 10010,
      "authEntityId": 10010,
      "displayName": "10010",
      "authEntityClass": 1738953278,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "state": "INACTIVE",
      "type": "NBAR"
    },
    "indicativeNetworkIdentity": [

    ],
    "networkApplications": [
      {
        "id": "290f29fd-27d3-4165-93a6-73c76430b0bd",
        "instanceId": 11169,
        "authEntityId": 11169,
        "displayName": "11169",
        "authEntityClass": -217092956,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "applicationGroup": "other",
        "applicationType": "DEFAULT",
        "categoryId": "cb5915d1-afec-4c30-ac9e-069ff382272f",
        "createTime": 1517966741760,
        "encrypted": "false",
        "engineId": "3",
        "helpString": "No SQL Database management system",
        "ignoreConflict": false,
        "lastUpdateTime": 1517966741760,
        "longDescription": "Apache Cassandra is an open source distributed database management system designed to handle large amounts of data across many commodity servers, providing high availability with no single point of failure.",
        "name": "cassandra",
        "nbarId": "1768",
        "p2pTechnology": "false",
        "popularity": 9,
        "rank": 65535,
        "references": "https://www.cassandradb.org/",
        "selectorId": "27018",
        "subCategory": "b90786f4-0190-45b8-9924-21b3e9682590",
        "trafficClass": "TRANSACTIONAL_DATA",
        "tunnel": "false"
      }
    ],
    "networkIdentity": [

    ],
    "parentScalableGroup": {
      "idRef": "8718395e-683e-4a2d-a232-100202751271"
    },
    "user": [

    ],
    "userGroup": [

    ]
  },
  {
    "id": "2150522f-a779-4904-b78d-8b352bdda99d",
    "instanceId": 4241,
    "authEntityId": 4241,
    "displayName": "4241",
    "authEntityClass": -1909073334,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "createTime": 1517966741913,
    "deployed": false,
    "isSeeded": true,
    "isStale": false,
    "lastUpdateTime": 1517966741913,
    "name": "cisco-jabber-video",
    "namespace": "scalablegroup:application",
    "provisioningState": "DEFINED",
    "qualifier": "application",
    "resourceVersion": 0,
    "targetIdList": [

    ],
    "type": "scalablegroup",
    "cfsChangeInfo": [

    ],
    "customProvisions": [

    ],
    "scalableGroupExternalHandle": "cisco-jabber-video",
    "scalableGroupType": "APPLICATION",
    "securityGroupTag": 0,
    "state": "ACTIVE",
    "vnAgnostic": true,
    "identitySource": {
      "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
      "instanceId": 10010,
      "authEntityId": 10010,
      "displayName": "10010",
      "authEntityClass": 1738953278,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "state": "INACTIVE",
      "type": "NBAR"
    },
    "indicativeNetworkIdentity": [

    ],
    "networkApplications": [
      {
        "id": "a8b478be-9ec3-4c01-a9b1-539cf4eb723e",
        "instanceId": 11195,
        "authEntityId": 11195,
        "displayName": "11195",
        "authEntityClass": -217092956,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "appProtocol": "tcp/udp",
        "applicationGroup": "cisco-jabber-group",
        "applicationType": "DEFAULT",
        "categoryId": "38a8e354-e2fe-4f3c-b1c5-2bbb90fb0d99",
        "createTime": 1517966741913,
        "encrypted": "false",
        "engineId": "13",
        "helpString": "Cisco Jabber Client; Video Calls",
        "ignoreConflict": false,
        "lastUpdateTime": 1517966741913,
        "longDescription": "Cisco Jabber is a unified communications client application that provides presence, instant messaging (IM), voice, and video calling capabilities on many platforms. This protocol classifies the video calls part of Cisco Jabber",
        "name": "cisco-jabber-video",
        "nbarId": "1495",
        "p2pTechnology": "false",
        "popularity": 10,
        "rank": 65535,
        "references": "http://www.cisco.com/web/products/voice/jabber.html",
        "selectorId": "561",
        "subCategory": "05178016-4555-4291-ab4e-43c2ab2a931a",
        "trafficClass": "MULTIMEDIA_CONFERENCING",
        "tunnel": "false"
      }
    ],
    "networkIdentity": [
      {
        "id": "f9babcb5-94e1-4b48-a9cd-8784a4194189",
        "instanceId": 12522,
        "authEntityId": 12522,
        "displayName": "12522",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "5060",
        "protocol": "UDP",
        "upperPort": 0
      },
      {
        "id": "cc0fb32c-3bf7-4f0c-bc28-b80f2d5ee7ad",
        "instanceId": 12523,
        "authEntityId": 12523,
        "displayName": "12523",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "5060",
        "protocol": "TCP",
        "upperPort": 0
      }
    ],
    "parentScalableGroup": {
      "idRef": "60f1364d-2a02-4063-8ba1-07817abe0612"
    },
    "user": [

    ],
    "userGroup": [

    ]
  },
  {
    "id": "215453dc-2362-4f80-ba41-bf25c48224df",
    "instanceId": 4868,
    "authEntityId": 4868,
    "displayName": "4868",
    "authEntityClass": -1909073334,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "createTime": 1517966743647,
    "deployed": false,
    "isSeeded": true,
    "isStale": false,
    "lastUpdateTime": 1517966743647,
    "name": "nlogin",
    "namespace": "scalablegroup:application",
    "provisioningState": "DEFINED",
    "qualifier": "application",
    "resourceVersion": 0,
    "targetIdList": [

    ],
    "type": "scalablegroup",
    "cfsChangeInfo": [

    ],
    "customProvisions": [

    ],
    "scalableGroupExternalHandle": "nlogin",
    "scalableGroupType": "APPLICATION",
    "securityGroupTag": 0,
    "state": "ACTIVE",
    "vnAgnostic": true,
    "identitySource": {
      "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
      "instanceId": 10010,
      "authEntityId": 10010,
      "displayName": "10010",
      "authEntityClass": 1738953278,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "state": "INACTIVE",
      "type": "NBAR"
    },
    "indicativeNetworkIdentity": [
      {
        "id": "3af704fc-e4ba-4062-b702-f27899aad90e",
        "instanceId": 14246,
        "authEntityId": 14246,
        "displayName": "14246",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "758",
        "protocol": "UDP",
        "upperPort": 0
      },
      {
        "id": "d0bbe2da-d2c0-4d5c-99d4-ef660c05f24f",
        "instanceId": 14247,
        "authEntityId": 14247,
        "displayName": "14247",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "758",
        "protocol": "TCP",
        "upperPort": 0
      }
    ],
    "networkApplications": [
      {
        "id": "73bd394b-85a1-43b3-a326-cb6c1a976f20",
        "instanceId": 11817,
        "authEntityId": 11817,
        "displayName": "11817",
        "authEntityClass": -217092956,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "appProtocol": "tcp/udp",
        "applicationGroup": "other",
        "applicationType": "DEFAULT",
        "categoryId": "1fc17362-9334-4c7b-b752-5afdbb574017",
        "createTime": 1517966743647,
        "encrypted": "false",
        "engineId": "3",
        "helpString": "nlogin",
        "ignoreConflict": false,
        "lastUpdateTime": 1517966743647,
        "longDescription": "Registered with IANA on port 758 TCP/UDP",
        "name": "nlogin",
        "nbarId": "630",
        "p2pTechnology": "false",
        "popularity": 1,
        "rank": 65535,
        "references": "http://www.iana.org/assignments/service-names-port-numbers/service-names-port-numbers.xml",
        "selectorId": "758",
        "subCategory": "787a7d77-977b-4974-89d3-379ba49e88b7",
        "trafficClass": "OPS_ADMIN_MGMT",
        "tunnel": "false"
      }
    ],
    "networkIdentity": [
      {
        "id": "0cce7214-7d31-4477-bcbe-41f371ab788a",
        "instanceId": 14244,
        "authEntityId": 14244,
        "displayName": "14244",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "758",
        "protocol": "UDP",
        "upperPort": 0
      },
      {
        "id": "ddf0c73e-8623-4291-b104-8fe6008ad2ff",
        "instanceId": 14245,
        "authEntityId": 14245,
        "displayName": "14245",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "758",
        "protocol": "TCP",
        "upperPort": 0
      }
    ],
    "parentScalableGroup": {
      "idRef": "e06dd89f-0a70-4422-ab0b-c2314650a9a7"
    },
    "user": [

    ],
    "userGroup": [

    ]
  },
  {
    "id": "2193a075-48dd-480b-ab01-23d72b2286a3",
    "instanceId": 4248,
    "authEntityId": 4248,
    "displayName": "4248",
    "authEntityClass": -1909073334,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "createTime": 1517966741933,
    "deployed": false,
    "isSeeded": true,
    "isStale": false,
    "lastUpdateTime": 1517966741933,
    "name": "cisco-phone-media",
    "namespace": "scalablegroup:application",
    "provisioningState": "DEFINED",
    "qualifier": "application",
    "resourceVersion": 0,
    "targetIdList": [

    ],
    "type": "scalablegroup",
    "cfsChangeInfo": [

    ],
    "customProvisions": [

    ],
    "scalableGroupExternalHandle": "cisco-phone-media",
    "scalableGroupType": "APPLICATION",
    "securityGroupTag": 0,
    "state": "ACTIVE",
    "vnAgnostic": true,
    "identitySource": {
      "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
      "instanceId": 10010,
      "authEntityId": 10010,
      "displayName": "10010",
      "authEntityClass": 1738953278,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "state": "INACTIVE",
      "type": "NBAR"
    },
    "indicativeNetworkIdentity": [

    ],
    "networkApplications": [
      {
        "id": "b411a0a1-e5ad-401d-92af-72aa5f62f3ab",
        "instanceId": 11202,
        "authEntityId": 11202,
        "displayName": "11202",
        "authEntityClass": -217092956,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "appProtocol": "tcp/udp",
        "applicationGroup": "cisco-phone-group",
        "applicationType": "DEFAULT",
        "categoryId": "38a8e354-e2fe-4f3c-b1c5-2bbb90fb0d99",
        "createTime": 1517966741933,
        "encrypted": "false",
        "engineId": "13",
        "helpString": "Cisco IP Phones and PC-based Unified Communicators",
        "ignoreConflict": false,
        "lastUpdateTime": 1517966741933,
        "longDescription": "Cisco-media is used mainly in corporations and can be used on or off site.",
        "name": "cisco-phone-media",
        "nbarId": "1745",
        "p2pTechnology": "false",
        "popularity": 10,
        "rank": 65535,
        "selectorId": "1745",
        "subCategory": "53ce0342-1e2d-455a-acc7-1b50db9d6845",
        "trafficClass": "MULTIMEDIA_CONFERENCING",
        "tunnel": "false"
      }
    ],
    "networkIdentity": [
      {
        "id": "412e6d64-770a-484e-805d-c018a01ca329",
        "instanceId": 12532,
        "authEntityId": 12532,
        "displayName": "12532",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "53,80,443,5353",
        "protocol": "TCP",
        "upperPort": 0
      },
      {
        "id": "c244d629-8eff-4f4d-9e25-89a45b02ec99",
        "instanceId": 12533,
        "authEntityId": 12533,
        "displayName": "12533",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "53,5353",
        "protocol": "UDP",
        "upperPort": 0
      }
    ],
    "parentScalableGroup": {
      "idRef": "60f1364d-2a02-4063-8ba1-07817abe0612"
    },
    "user": [

    ],
    "userGroup": [

    ]
  },
  {
    "id": "21a48c99-58b5-45d1-8abc-b04a618af418",
    "instanceId": 4790,
    "authEntityId": 4790,
    "displayName": "4790",
    "authEntityClass": -1909073334,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "createTime": 1517966743499,
    "deployed": false,
    "isSeeded": true,
    "isStale": false,
    "lastUpdateTime": 1517966743499,
    "name": "ms-sql-m",
    "namespace": "scalablegroup:application",
    "provisioningState": "DEFINED",
    "qualifier": "application",
    "resourceVersion": 0,
    "targetIdList": [

    ],
    "type": "scalablegroup",
    "cfsChangeInfo": [

    ],
    "customProvisions": [

    ],
    "scalableGroupExternalHandle": "ms-sql-m",
    "scalableGroupType": "APPLICATION",
    "securityGroupTag": 0,
    "state": "ACTIVE",
    "vnAgnostic": true,
    "identitySource": {
      "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
      "instanceId": 10010,
      "authEntityId": 10010,
      "displayName": "10010",
      "authEntityClass": 1738953278,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "state": "INACTIVE",
      "type": "NBAR"
    },
    "indicativeNetworkIdentity": [
      {
        "id": "03936587-cbd2-4214-8265-b571942806ea",
        "instanceId": 14014,
        "authEntityId": 14014,
        "displayName": "14014",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "1434",
        "protocol": "TCP",
        "upperPort": 0
      },
      {
        "id": "6a3f8834-e12a-43a1-b367-ec662840a507",
        "instanceId": 14015,
        "authEntityId": 14015,
        "displayName": "14015",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "1434",
        "protocol": "UDP",
        "upperPort": 0
      }
    ],
    "networkApplications": [
      {
        "id": "fd2b8383-1765-4591-a53a-47bdb7054092",
        "instanceId": 11739,
        "authEntityId": 11739,
        "displayName": "11739",
        "authEntityClass": -217092956,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "appProtocol": "tcp/udp",
        "applicationGroup": "sqlsvr-group",
        "applicationType": "DEFAULT",
        "categoryId": "cb5915d1-afec-4c30-ac9e-069ff382272f",
        "createTime": 1517966743499,
        "encrypted": "false",
        "engineId": "3",
        "helpString": "Microsoft-SQL-Monitor",
        "ignoreConflict": false,
        "lastUpdateTime": 1517966743499,
        "longDescription": "Microsoft SQL Monitor uses the SQL Server Resolution Protocol. It is an application-layer request/response protocol that facilitates connectivity to a database server and provides for Communication endpoint information and Database instance enumeration.",
        "name": "ms-sql-m",
        "nbarId": "685",
        "p2pTechnology": "false",
        "popularity": 7,
        "rank": 65535,
        "references": "http://msdn.microsoft.com/en-us/library/cc219703(v=prot.10)",
        "selectorId": "1434",
        "subCategory": "b90786f4-0190-45b8-9924-21b3e9682590",
        "trafficClass": "TRANSACTIONAL_DATA",
        "tunnel": "false"
      }
    ],
    "networkIdentity": [
      {
        "id": "37ced62a-01df-4c64-ac5f-ee82c769c45e",
        "instanceId": 14012,
        "authEntityId": 14012,
        "displayName": "14012",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "1434",
        "protocol": "TCP",
        "upperPort": 0
      },
      {
        "id": "411532e0-be2e-482e-8535-0754d5cac632",
        "instanceId": 14013,
        "authEntityId": 14013,
        "displayName": "14013",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "1434",
        "protocol": "UDP",
        "upperPort": 0
      }
    ],
    "parentScalableGroup": {
      "idRef": "8718395e-683e-4a2d-a232-100202751271"
    },
    "user": [

    ],
    "userGroup": [

    ]
  },
  {
    "id": "21bbce78-7b07-4ce0-81c7-17e9389fa680",
    "instanceId": 15154,
    "authEntityId": 15154,
    "displayName": "15154",
    "authEntityClass": -1909073334,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "createTime": 1517966744358,
    "deployed": false,
    "isSeeded": true,
    "isStale": false,
    "lastUpdateTime": 1517966744358,
    "name": "sky-news",
    "namespace": "scalablegroup:application",
    "provisioningState": "DEFINED",
    "qualifier": "application",
    "resourceVersion": 0,
    "targetIdList": [

    ],
    "type": "scalablegroup",
    "cfsChangeInfo": [

    ],
    "customProvisions": [

    ],
    "scalableGroupExternalHandle": "sky-news",
    "scalableGroupType": "APPLICATION",
    "securityGroupTag": 0,
    "state": "ACTIVE",
    "vnAgnostic": true,
    "identitySource": {
      "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
      "instanceId": 10010,
      "authEntityId": 10010,
      "displayName": "10010",
      "authEntityClass": 1738953278,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "state": "INACTIVE",
      "type": "NBAR"
    },
    "indicativeNetworkIdentity": [

    ],
    "networkApplications": [
      {
        "id": "475ed29b-a29d-4503-940c-1c8d0520f31e",
        "instanceId": 16097,
        "authEntityId": 16097,
        "displayName": "16097",
        "authEntityClass": -217092956,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "appProtocol": "tcp/udp",
        "applicationGroup": "other",
        "applicationType": "DEFAULT",
        "categoryId": "878644b5-fe91-45b4-9204-31c84a73ba48",
        "createTime": 1517966744357,
        "encrypted": "false",
        "engineId": "13",
        "helpString": "Sky.com - Web portal for news.",
        "ignoreConflict": false,
        "lastUpdateTime": 1517966744357,
        "longDescription": "Sky.com - Web portal for news.",
        "name": "sky-news",
        "nbarId": "1714",
        "p2pTechnology": "false",
        "popularity": 3,
        "rank": 65535,
        "references": "http://sky.com",
        "selectorId": "786",
        "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
        "trafficClass": "TRANSACTIONAL_DATA",
        "tunnel": "false"
      }
    ],
    "networkIdentity": [
      {
        "id": "9cbb6b65-cce6-4c08-b2cf-51b17d621867",
        "instanceId": 17110,
        "authEntityId": 17110,
        "displayName": "17110",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "53,5353",
        "protocol": "UDP",
        "upperPort": 0
      },
      {
        "id": "a0aaabe8-04cc-4eb5-bf55-9cc67d5c8f08",
        "instanceId": 17111,
        "authEntityId": 17111,
        "displayName": "17111",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "53,80,443,5353",
        "protocol": "TCP",
        "upperPort": 0
      }
    ],
    "parentScalableGroup": {
      "idRef": "28e08de4-d7b2-4521-84ce-bc1e5ee0b9cc"
    },
    "user": [

    ],
    "userGroup": [

    ]
  },
  {
    "id": "21c8b0f9-6975-4713-8e8d-7790ca8f2df2",
    "instanceId": 4964,
    "authEntityId": 4964,
    "displayName": "4964",
    "authEntityClass": -1909073334,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "createTime": 1517966743922,
    "deployed": false,
    "isSeeded": true,
    "isStale": false,
    "lastUpdateTime": 1517966743922,
    "name": "pipe",
    "namespace": "scalablegroup:application",
    "provisioningState": "DEFINED",
    "qualifier": "application",
    "resourceVersion": 0,
    "targetIdList": [

    ],
    "type": "scalablegroup",
    "cfsChangeInfo": [

    ],
    "customProvisions": [

    ],
    "scalableGroupExternalHandle": "pipe",
    "scalableGroupType": "APPLICATION",
    "securityGroupTag": 0,
    "state": "ACTIVE",
    "vnAgnostic": true,
    "identitySource": {
      "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
      "instanceId": 10010,
      "authEntityId": 10010,
      "displayName": "10010",
      "authEntityClass": 1738953278,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "state": "INACTIVE",
      "type": "NBAR"
    },
    "indicativeNetworkIdentity": [
      {
        "id": "803aa750-6f5a-42ea-a8a0-9fce850a0d16",
        "instanceId": 14549,
        "authEntityId": 14549,
        "displayName": "14549",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipProtocolNumbers": "131",
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "protocol": "PIPE",
        "upperPort": 0
      }
    ],
    "networkApplications": [
      {
        "id": "c3f762ed-b45e-49a4-9e09-b6b310ee75bf",
        "instanceId": 11913,
        "authEntityId": 11913,
        "displayName": "11913",
        "authEntityClass": -217092956,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "appProtocol": "ip",
        "applicationGroup": "other",
        "applicationType": "DEFAULT",
        "categoryId": "6daa3dfd-3526-407a-88f0-97824b3f3a24",
        "createTime": 1517966743922,
        "encrypted": "false",
        "engineId": "1",
        "helpString": "Private IP Encapsulation within IP",
        "ignoreConflict": false,
        "lastUpdateTime": 1517966743922,
        "longDescription": "Private IP Encapsulation within IP (PIPE) is a protocol enabling the encapsulation of an IP packet within another IP packet. This method is is used to change the routing of an IP packet by sending it through an intermediate destination that would not have been reached with the originial packet's IP destination address.",
        "name": "pipe",
        "nbarId": "1229",
        "p2pTechnology": "false",
        "popularity": 2,
        "rank": 65535,
        "references": "http://tools.ietf.org/html/draft-petri-mobileip-pipe-00",
        "selectorId": "131",
        "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
        "trafficClass": "BULK_DATA",
        "tunnel": "false"
      }
    ],
    "networkIdentity": [
      {
        "id": "66e370cc-1b71-435c-9c54-f226dc8e92ab",
        "instanceId": 14548,
        "authEntityId": 14548,
        "displayName": "14548",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipProtocolNumbers": "131",
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "protocol": "PIPE",
        "upperPort": 0
      }
    ],
    "parentScalableGroup": {
      "idRef": "000c15bf-28fe-4fbb-9b69-35e6502721f1"
    },
    "user": [

    ],
    "userGroup": [

    ]
  },
  {
    "id": "21ec5da0-cf64-464a-9952-a25bee07b1a3",
    "instanceId": 4039,
    "authEntityId": 4039,
    "displayName": "4039",
    "authEntityClass": -1909073334,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "createTime": 1517966740525,
    "deployed": false,
    "isSeeded": true,
    "isStale": false,
    "lastUpdateTime": 1517966740525,
    "name": "4chan",
    "namespace": "scalablegroup:application",
    "provisioningState": "DEFINED",
    "qualifier": "application",
    "resourceVersion": 0,
    "targetIdList": [

    ],
    "type": "scalablegroup",
    "cfsChangeInfo": [

    ],
    "customProvisions": [

    ],
    "scalableGroupExternalHandle": "4chan",
    "scalableGroupType": "APPLICATION",
    "securityGroupTag": 0,
    "state": "ACTIVE",
    "vnAgnostic": true,
    "identitySource": {
      "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
      "instanceId": 10010,
      "authEntityId": 10010,
      "displayName": "10010",
      "authEntityClass": 1738953278,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "state": "INACTIVE",
      "type": "NBAR"
    },
    "indicativeNetworkIdentity": [

    ],
    "networkApplications": [
      {
        "id": "7bed00f2-f93f-4cd1-bc1e-3ea6e1eef9a7",
        "instanceId": 11014,
        "authEntityId": 11014,
        "displayName": "11014",
        "authEntityClass": -217092956,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "appProtocol": "tcp/udp",
        "applicationGroup": "other",
        "applicationType": "DEFAULT",
        "categoryId": "878644b5-fe91-45b4-9204-31c84a73ba48",
        "createTime": 1517966740525,
        "encrypted": "false",
        "engineId": "13",
        "helpString": "4chan - Website that hosts found images and discussions on them.",
        "ignoreConflict": false,
        "lastUpdateTime": 1517966740525,
        "longDescription": "4chan - Website that hosts found images and discussions on them.",
        "name": "4chan",
        "nbarId": "1693",
        "p2pTechnology": "false",
        "popularity": 3,
        "rank": 65535,
        "references": "http://4chan.org",
        "selectorId": "763",
        "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
        "trafficClass": "TRANSACTIONAL_DATA",
        "tunnel": "false"
      }
    ],
    "networkIdentity": [
      {
        "id": "e5eaa1a0-adc3-40db-ab4a-b978c4a3d69d",
        "instanceId": 12022,
        "authEntityId": 12022,
        "displayName": "12022",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "53,80,443,5353",
        "protocol": "TCP",
        "upperPort": 0
      },
      {
        "id": "0acbe66c-3a0a-4974-89fe-712c5a095261",
        "instanceId": 12023,
        "authEntityId": 12023,
        "displayName": "12023",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "53,5353",
        "protocol": "UDP",
        "upperPort": 0
      }
    ],
    "parentScalableGroup": {
      "idRef": "28e08de4-d7b2-4521-84ce-bc1e5ee0b9cc"
    },
    "user": [

    ],
    "userGroup": [

    ]
  },
  {
    "id": "221055f7-d22a-405b-b72c-47e8192a1430",
    "instanceId": 4497,
    "authEntityId": 4497,
    "displayName": "4497",
    "authEntityClass": -1909073334,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "createTime": 1517966742819,
    "deployed": false,
    "isSeeded": true,
    "isStale": false,
    "lastUpdateTime": 1517966742819,
    "name": "google-earth",
    "namespace": "scalablegroup:application",
    "provisioningState": "DEFINED",
    "qualifier": "application",
    "resourceVersion": 0,
    "targetIdList": [

    ],
    "type": "scalablegroup",
    "cfsChangeInfo": [

    ],
    "customProvisions": [

    ],
    "scalableGroupExternalHandle": "google-earth",
    "scalableGroupType": "APPLICATION",
    "securityGroupTag": 0,
    "state": "ACTIVE",
    "vnAgnostic": true,
    "identitySource": {
      "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
      "instanceId": 10010,
      "authEntityId": 10010,
      "displayName": "10010",
      "authEntityClass": 1738953278,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "state": "INACTIVE",
      "type": "NBAR"
    },
    "indicativeNetworkIdentity": [

    ],
    "networkApplications": [
      {
        "id": "4c8160f2-0f3f-4fbf-9032-0acd86692bb1",
        "instanceId": 11448,
        "authEntityId": 11448,
        "displayName": "11448",
        "authEntityClass": -217092956,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "appProtocol": "tcp",
        "applicationGroup": "google-group",
        "applicationType": "DEFAULT",
        "categoryId": "f6ec0221-b82d-43e8-a64d-163cbc13263b",
        "createTime": 1517966742819,
        "encrypted": "false",
        "engineId": "13",
        "helpString": "Location Based Service",
        "ignoreConflict": false,
        "lastUpdateTime": 1517966742819,
        "longDescription": "Google Earth is an application that lets the user view the world virtually though satellite imagery, maps and 3D buildings, etc. The application runs on Windows, MAC and Linux OS. The underlying protocol of Google Earth is HTTP.",
        "name": "google-earth",
        "nbarId": "897",
        "p2pTechnology": "false",
        "popularity": 10,
        "rank": 65535,
        "references": "http://www.google.com/earth/index.html",
        "selectorId": "441",
        "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
        "trafficClass": "TRANSACTIONAL_DATA",
        "tunnel": "false"
      }
    ],
    "networkIdentity": [
      {
        "id": "803e603a-77cb-4b89-b48a-15688294f0e4",
        "instanceId": 13238,
        "authEntityId": 13238,
        "displayName": "13238",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "80",
        "protocol": "TCP",
        "upperPort": 0
      }
    ],
    "parentScalableGroup": {
      "idRef": "28e08de4-d7b2-4521-84ce-bc1e5ee0b9cc"
    },
    "user": [

    ],
    "userGroup": [

    ]
  },
  {
    "id": "2251e507-bd6b-47bf-a22d-d444f750a760",
    "instanceId": 15431,
    "authEntityId": 15431,
    "displayName": "15431",
    "authEntityClass": -1909073334,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "createTime": 1517966744953,
    "deployed": false,
    "isSeeded": true,
    "isStale": false,
    "lastUpdateTime": 1517966744953,
    "name": "xact-backup",
    "namespace": "scalablegroup:application",
    "provisioningState": "DEFINED",
    "qualifier": "application",
    "resourceVersion": 0,
    "targetIdList": [

    ],
    "type": "scalablegroup",
    "cfsChangeInfo": [

    ],
    "customProvisions": [

    ],
    "scalableGroupExternalHandle": "xact-backup",
    "scalableGroupType": "APPLICATION",
    "securityGroupTag": 0,
    "state": "ACTIVE",
    "vnAgnostic": true,
    "identitySource": {
      "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
      "instanceId": 10010,
      "authEntityId": 10010,
      "displayName": "10010",
      "authEntityClass": 1738953278,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "state": "INACTIVE",
      "type": "NBAR"
    },
    "indicativeNetworkIdentity": [
      {
        "id": "739b29e7-0fb9-44d6-b1b2-15b889350883",
        "instanceId": 17877,
        "authEntityId": 17877,
        "displayName": "17877",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "911",
        "protocol": "TCP",
        "upperPort": 0
      },
      {
        "id": "23c68abd-fcfd-49d0-b839-dbe23d838bf7",
        "instanceId": 17878,
        "authEntityId": 17878,
        "displayName": "17878",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "911",
        "protocol": "UDP",
        "upperPort": 0
      }
    ],
    "networkApplications": [
      {
        "id": "2a33bce8-1cc9-442e-a212-9c58b18d791b",
        "instanceId": 16374,
        "authEntityId": 16374,
        "displayName": "16374",
        "authEntityClass": -217092956,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "appProtocol": "tcp/udp",
        "applicationGroup": "other",
        "applicationType": "DEFAULT",
        "categoryId": "80909d4c-efe6-4878-9e1e-68281fd26edd",
        "createTime": 1517966744953,
        "encrypted": "false",
        "engineId": "3",
        "helpString": "xact-backup",
        "ignoreConflict": false,
        "lastUpdateTime": 1517966744953,
        "longDescription": "xact-backup, Registered with IANA on port 911 TCP/UDP",
        "name": "xact-backup",
        "nbarId": "667",
        "p2pTechnology": "false",
        "popularity": 1,
        "rank": 65535,
        "references": "http://www.iana.org/assignments/service-names-port-numbers/service-names-port-numbers.xml",
        "selectorId": "911",
        "subCategory": "4a197877-986d-4422-b48f-500fb4b3340f",
        "trafficClass": "BULK_DATA",
        "tunnel": "false"
      }
    ],
    "networkIdentity": [
      {
        "id": "78c1e9fd-775e-4670-847b-14ba136d8228",
        "instanceId": 17875,
        "authEntityId": 17875,
        "displayName": "17875",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "911",
        "protocol": "TCP",
        "upperPort": 0
      },
      {
        "id": "6213505a-129e-4d7e-9ad7-618a1340fd7c",
        "instanceId": 17876,
        "authEntityId": 17876,
        "displayName": "17876",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "911",
        "protocol": "UDP",
        "upperPort": 0
      }
    ],
    "parentScalableGroup": {
      "idRef": "f09a36de-df38-4ff8-b0be-56738afc75f7"
    },
    "user": [

    ],
    "userGroup": [

    ]
  },
  {
    "id": "22c990de-625f-4ccf-a700-a8174e7ee745",
    "instanceId": 4278,
    "authEntityId": 4278,
    "displayName": "4278",
    "authEntityClass": -1909073334,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "createTime": 1517966742117,
    "deployed": false,
    "isSeeded": true,
    "isStale": false,
    "lastUpdateTime": 1517966742117,
    "name": "con",
    "namespace": "scalablegroup:application",
    "provisioningState": "DEFINED",
    "qualifier": "application",
    "resourceVersion": 0,
    "targetIdList": [

    ],
    "type": "scalablegroup",
    "cfsChangeInfo": [

    ],
    "customProvisions": [

    ],
    "scalableGroupExternalHandle": "con",
    "scalableGroupType": "APPLICATION",
    "securityGroupTag": 0,
    "state": "ACTIVE",
    "vnAgnostic": true,
    "identitySource": {
      "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
      "instanceId": 10010,
      "authEntityId": 10010,
      "displayName": "10010",
      "authEntityClass": 1738953278,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "state": "INACTIVE",
      "type": "NBAR"
    },
    "indicativeNetworkIdentity": [
      {
        "id": "8e212e48-9f5b-4c3c-90c0-74aedfc69b0a",
        "instanceId": 12612,
        "authEntityId": 12612,
        "displayName": "12612",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "759",
        "protocol": "TCP",
        "upperPort": 0
      },
      {
        "id": "3f918d66-bdfa-4195-a75a-051466906ff8",
        "instanceId": 12613,
        "authEntityId": 12613,
        "displayName": "12613",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "759",
        "protocol": "UDP",
        "upperPort": 0
      }
    ],
    "networkApplications": [
      {
        "id": "c48604b7-0912-4d2b-b26e-a5424cdda466",
        "instanceId": 11231,
        "authEntityId": 11231,
        "displayName": "11231",
        "authEntityClass": -217092956,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "appProtocol": "tcp/udp",
        "applicationGroup": "other",
        "applicationType": "DEFAULT",
        "categoryId": "6daa3dfd-3526-407a-88f0-97824b3f3a24",
        "createTime": 1517966742117,
        "encrypted": "false",
        "engineId": "3",
        "helpString": "con",
        "ignoreConflict": false,
        "lastUpdateTime": 1517966742117,
        "longDescription": "Registered with IANA on port 759 TCP/UDP",
        "name": "con",
        "nbarId": "631",
        "p2pTechnology": "false",
        "popularity": 1,
        "rank": 65535,
        "references": "http://www.iana.org/assignments/service-names-port-numbers/service-names-port-numbers.xml",
        "selectorId": "759",
        "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
        "trafficClass": "BULK_DATA",
        "tunnel": "false"
      }
    ],
    "networkIdentity": [
      {
        "id": "0e1183ff-e54e-4c8d-b1a6-9ecba083569f",
        "instanceId": 12610,
        "authEntityId": 12610,
        "displayName": "12610",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "759",
        "protocol": "UDP",
        "upperPort": 0
      },
      {
        "id": "cb187a64-4201-41cc-9e43-75aec019d2c3",
        "instanceId": 12611,
        "authEntityId": 12611,
        "displayName": "12611",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "759",
        "protocol": "TCP",
        "upperPort": 0
      }
    ],
    "parentScalableGroup": {
      "idRef": "000c15bf-28fe-4fbb-9b69-35e6502721f1"
    },
    "user": [

    ],
    "userGroup": [

    ]
  },
  {
    "id": "22d2717d-f23f-4b9c-8cb2-5b9112cbe1aa",
    "instanceId": 4720,
    "authEntityId": 4720,
    "displayName": "4720",
    "authEntityClass": -1909073334,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "createTime": 1517966743353,
    "deployed": false,
    "isSeeded": true,
    "isStale": false,
    "lastUpdateTime": 1517966743353,
    "name": "manolito",
    "namespace": "scalablegroup:application",
    "provisioningState": "DEFINED",
    "qualifier": "application",
    "resourceVersion": 0,
    "targetIdList": [

    ],
    "type": "scalablegroup",
    "cfsChangeInfo": [

    ],
    "customProvisions": [

    ],
    "scalableGroupExternalHandle": "manolito",
    "scalableGroupType": "APPLICATION",
    "securityGroupTag": 0,
    "state": "ACTIVE",
    "vnAgnostic": true,
    "identitySource": {
      "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
      "instanceId": 10010,
      "authEntityId": 10010,
      "displayName": "10010",
      "authEntityClass": 1738953278,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "state": "INACTIVE",
      "type": "NBAR"
    },
    "indicativeNetworkIdentity": [
      {
        "id": "b283155b-debd-46bb-bb47-4d989eba24c0",
        "instanceId": 13827,
        "authEntityId": 13827,
        "displayName": "13827",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "41170",
        "protocol": "UDP",
        "upperPort": 0
      }
    ],
    "networkApplications": [
      {
        "id": "4b8baf3f-eba8-454b-bd7b-05d0be56008c",
        "instanceId": 11670,
        "authEntityId": 11670,
        "displayName": "11670",
        "authEntityClass": -217092956,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "appProtocol": "udp",
        "applicationGroup": "other",
        "applicationType": "DEFAULT",
        "categoryId": "b33b2761-d981-4734-810a-a6a1c18ab352",
        "createTime": 1517966743353,
        "encrypted": "false",
        "engineId": "13",
        "helpString": "Free secure P2P file sharing",
        "ignoreConflict": false,
        "lastUpdateTime": 1517966743353,
        "longDescription": "Manolito is a fast peer to peer filesharing and IM application. Manolito is the base protocol for RockItNet, Piolet and Blubster.",
        "name": "manolito",
        "nbarId": "383",
        "p2pTechnology": "true",
        "popularity": 6,
        "rank": 65535,
        "references": "http://www.manolito.com/",
        "selectorId": "266",
        "subCategory": "79fe7198-9ce3-4c01-9b58-45a8df3d9513",
        "trafficClass": "BULK_DATA",
        "tunnel": "false"
      }
    ],
    "networkIdentity": [
      {
        "id": "99a08076-8f2b-4e71-b7ea-750b6d5ebb33",
        "instanceId": 13826,
        "authEntityId": 13826,
        "displayName": "13826",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "41170",
        "protocol": "UDP",
        "upperPort": 0
      }
    ],
    "parentScalableGroup": {
      "idRef": "7a1a3bbb-dc84-4a35-82a4-7e650c4c4761"
    },
    "user": [

    ],
    "userGroup": [

    ]
  },
  {
    "id": "22d962ad-68b0-45de-8875-6089c252158a",
    "instanceId": 4612,
    "authEntityId": 4612,
    "displayName": "4612",
    "authEntityClass": -1909073334,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "createTime": 1517966743123,
    "deployed": false,
    "isSeeded": true,
    "isStale": false,
    "lastUpdateTime": 1517966743123,
    "name": "intrinsa",
    "namespace": "scalablegroup:application",
    "provisioningState": "DEFINED",
    "qualifier": "application",
    "resourceVersion": 0,
    "targetIdList": [

    ],
    "type": "scalablegroup",
    "cfsChangeInfo": [

    ],
    "customProvisions": [

    ],
    "scalableGroupExternalHandle": "intrinsa",
    "scalableGroupType": "APPLICATION",
    "securityGroupTag": 0,
    "state": "ACTIVE",
    "vnAgnostic": true,
    "identitySource": {
      "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
      "instanceId": 10010,
      "authEntityId": 10010,
      "displayName": "10010",
      "authEntityClass": 1738953278,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "state": "INACTIVE",
      "type": "NBAR"
    },
    "indicativeNetworkIdentity": [
      {
        "id": "8e7673c1-c7a1-449e-a3bf-20847c9db3fa",
        "instanceId": 13547,
        "authEntityId": 13547,
        "displayName": "13547",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "503",
        "protocol": "TCP",
        "upperPort": 0
      },
      {
        "id": "8d469313-3d91-4de3-968e-509b222be4c4",
        "instanceId": 13548,
        "authEntityId": 13548,
        "displayName": "13548",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "503",
        "protocol": "UDP",
        "upperPort": 0
      }
    ],
    "networkApplications": [
      {
        "id": "271934c6-5452-46c9-9a1b-f397100ceeb2",
        "instanceId": 11562,
        "authEntityId": 11562,
        "displayName": "11562",
        "authEntityClass": -217092956,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "appProtocol": "tcp/udp",
        "applicationGroup": "other",
        "applicationType": "DEFAULT",
        "categoryId": "6daa3dfd-3526-407a-88f0-97824b3f3a24",
        "createTime": 1517966743123,
        "encrypted": "false",
        "engineId": "3",
        "helpString": "intrinsa",
        "ignoreConflict": false,
        "lastUpdateTime": 1517966743123,
        "longDescription": "Registered with IANA on port 503 TCP/UDP",
        "name": "intrinsa",
        "nbarId": "417",
        "p2pTechnology": "false",
        "popularity": 1,
        "rank": 65535,
        "references": "http://www.iana.org/assignments/service-names-port-numbers/service-names-port-numbers.xml",
        "selectorId": "503",
        "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
        "trafficClass": "BULK_DATA",
        "tunnel": "false"
      }
    ],
    "networkIdentity": [
      {
        "id": "d7b7c268-5626-4212-8b12-9d2c47a8b407",
        "instanceId": 13545,
        "authEntityId": 13545,
        "displayName": "13545",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "503",
        "protocol": "TCP",
        "upperPort": 0
      },
      {
        "id": "1f933318-059c-4edd-8671-10699b4fffc3",
        "instanceId": 13546,
        "authEntityId": 13546,
        "displayName": "13546",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "503",
        "protocol": "UDP",
        "upperPort": 0
      }
    ],
    "parentScalableGroup": {
      "idRef": "000c15bf-28fe-4fbb-9b69-35e6502721f1"
    },
    "user": [

    ],
    "userGroup": [

    ]
  },
  {
    "id": "22dcabda-7344-4a87-88df-9ebe64a36d0a",
    "instanceId": 4236,
    "authEntityId": 4236,
    "displayName": "4236",
    "authEntityClass": -1909073334,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "createTime": 1517966741879,
    "deployed": false,
    "isSeeded": true,
    "isStale": false,
    "lastUpdateTime": 1517966741879,
    "name": "cisco-ip-camera",
    "namespace": "scalablegroup:application",
    "provisioningState": "DEFINED",
    "qualifier": "application",
    "resourceVersion": 0,
    "targetIdList": [

    ],
    "type": "scalablegroup",
    "cfsChangeInfo": [

    ],
    "customProvisions": [

    ],
    "scalableGroupExternalHandle": "cisco-ip-camera",
    "scalableGroupType": "APPLICATION",
    "securityGroupTag": 0,
    "state": "ACTIVE",
    "vnAgnostic": true,
    "identitySource": {
      "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
      "instanceId": 10010,
      "authEntityId": 10010,
      "displayName": "10010",
      "authEntityClass": 1738953278,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "state": "INACTIVE",
      "type": "NBAR"
    },
    "indicativeNetworkIdentity": [

    ],
    "networkApplications": [
      {
        "id": "e13f7159-857a-4164-ab59-f95ac5507f20",
        "instanceId": 11190,
        "authEntityId": 11190,
        "displayName": "11190",
        "authEntityClass": -217092956,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "appProtocol": "tcp",
        "applicationGroup": "other",
        "applicationType": "DEFAULT",
        "categoryId": "38a8e354-e2fe-4f3c-b1c5-2bbb90fb0d99",
        "createTime": 1517966741879,
        "encrypted": "false",
        "engineId": "13",
        "helpString": "Cisco video surveillance camera",
        "ignoreConflict": false,
        "lastUpdateTime": 1517966741879,
        "longDescription": "The Cisco Video Surveillance Solution relies on an IP network infrastructure to link all components. The designs of a highly available hierarchical network have been proven and tested for many years and allow applications to converge on an intelligent and resilient infrastructure.",
        "name": "cisco-ip-camera",
        "nbarId": "1315",
        "p2pTechnology": "false",
        "popularity": 10,
        "rank": 65535,
        "references": "http://www.cisco.com/en/US/products/ps6712/index.html",
        "selectorId": "456",
        "subCategory": "d0124db5-106b-46ed-a7f2-e2256010b2d8",
        "trafficClass": "BROADCAST_VIDEO",
        "tunnel": "false"
      }
    ],
    "networkIdentity": [
      {
        "id": "b5442604-8899-4719-b725-3452cbb04ddd",
        "instanceId": 12515,
        "authEntityId": 12515,
        "displayName": "12515",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "554,8554",
        "protocol": "TCP",
        "upperPort": 0
      }
    ],
    "parentScalableGroup": {
      "idRef": "961b652e-1019-451e-86da-f9ff537016d2"
    },
    "user": [

    ],
    "userGroup": [

    ]
  },
  {
    "id": "2346dbdd-a253-48a9-a6a0-c6d37b8ba887",
    "instanceId": 4558,
    "authEntityId": 4558,
    "displayName": "4558",
    "authEntityClass": -1909073334,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "createTime": 1517966742986,
    "deployed": false,
    "isSeeded": true,
    "isStale": false,
    "lastUpdateTime": 1517966742986,
    "name": "http-alt",
    "namespace": "scalablegroup:application",
    "provisioningState": "DEFINED",
    "qualifier": "application",
    "resourceVersion": 0,
    "targetIdList": [

    ],
    "type": "scalablegroup",
    "cfsChangeInfo": [

    ],
    "customProvisions": [

    ],
    "scalableGroupExternalHandle": "http-alt",
    "scalableGroupType": "APPLICATION",
    "securityGroupTag": 0,
    "state": "ACTIVE",
    "vnAgnostic": true,
    "identitySource": {
      "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
      "instanceId": 10010,
      "authEntityId": 10010,
      "displayName": "10010",
      "authEntityClass": 1738953278,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "state": "INACTIVE",
      "type": "NBAR"
    },
    "indicativeNetworkIdentity": [
      {
        "id": "b274928c-d370-49ac-96ca-bb0fcb327c48",
        "instanceId": 13387,
        "authEntityId": 13387,
        "displayName": "13387",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "591,8008,8080",
        "protocol": "UDP",
        "upperPort": 0
      },
      {
        "id": "b952a0bf-f4a2-40d2-a3d5-2584e9438ea7",
        "instanceId": 13388,
        "authEntityId": 13388,
        "displayName": "13388",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "591,8008,8080",
        "protocol": "TCP",
        "upperPort": 0
      }
    ],
    "networkApplications": [
      {
        "id": "d78207db-5e40-413c-be56-0e4093fa13c2",
        "instanceId": 11509,
        "authEntityId": 11509,
        "displayName": "11509",
        "authEntityClass": -217092956,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "appProtocol": "tcp/udp",
        "applicationGroup": "other",
        "applicationType": "DEFAULT",
        "categoryId": "6daa3dfd-3526-407a-88f0-97824b3f3a24",
        "createTime": 1517966742986,
        "encrypted": "false",
        "engineId": "3",
        "helpString": "HTTP Alternate",
        "ignoreConflict": false,
        "lastUpdateTime": 1517966742986,
        "longDescription": "HTTP Alternate is  alternative port to port 80 that is used by HTTP.",
        "name": "http-alt",
        "nbarId": "505",
        "p2pTechnology": "false",
        "popularity": 1,
        "rank": 65535,
        "references": "http://www.ietf.org/rfc/rfc2616.txt",
        "selectorId": "591",
        "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
        "trafficClass": "BULK_DATA",
        "tunnel": "false"
      }
    ],
    "networkIdentity": [
      {
        "id": "3b54dbaf-d7f8-49e6-9e3b-7449af8727e7",
        "instanceId": 13385,
        "authEntityId": 13385,
        "displayName": "13385",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "591,8008,8080",
        "protocol": "UDP",
        "upperPort": 0
      },
      {
        "id": "b6c64a45-105a-4635-828f-2c874c03477e",
        "instanceId": 13386,
        "authEntityId": 13386,
        "displayName": "13386",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "591,8008,8080",
        "protocol": "TCP",
        "upperPort": 0
      }
    ],
    "parentScalableGroup": {
      "idRef": "000c15bf-28fe-4fbb-9b69-35e6502721f1"
    },
    "user": [

    ],
    "userGroup": [

    ]
  },
  {
    "id": "2388f737-8e99-4184-a0d7-e8d3fb10fae8",
    "instanceId": 4975,
    "authEntityId": 4975,
    "displayName": "4975",
    "authEntityClass": -1909073334,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "createTime": 1517966743951,
    "deployed": false,
    "isSeeded": true,
    "isStale": false,
    "lastUpdateTime": 1517966743951,
    "name": "postgresql",
    "namespace": "scalablegroup:application",
    "provisioningState": "DEFINED",
    "qualifier": "application",
    "resourceVersion": 0,
    "targetIdList": [

    ],
    "type": "scalablegroup",
    "cfsChangeInfo": [

    ],
    "customProvisions": [

    ],
    "scalableGroupExternalHandle": "postgresql",
    "scalableGroupType": "APPLICATION",
    "securityGroupTag": 0,
    "state": "ACTIVE",
    "vnAgnostic": true,
    "identitySource": {
      "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
      "instanceId": 10010,
      "authEntityId": 10010,
      "displayName": "10010",
      "authEntityClass": 1738953278,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "state": "INACTIVE",
      "type": "NBAR"
    },
    "indicativeNetworkIdentity": [
      {
        "id": "2fae6ef1-601a-4e3e-bf34-99d46ad56b3f",
        "instanceId": 14578,
        "authEntityId": 14578,
        "displayName": "14578",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "5432",
        "protocol": "TCP",
        "upperPort": 0
      },
      {
        "id": "4f476776-d021-441c-b21f-496a6244f1cf",
        "instanceId": 14579,
        "authEntityId": 14579,
        "displayName": "14579",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "5432",
        "protocol": "UDP",
        "upperPort": 0
      }
    ],
    "networkApplications": [
      {
        "id": "42e9f340-decc-4773-92db-ff00dfa8f52c",
        "instanceId": 11924,
        "authEntityId": 11924,
        "displayName": "11924",
        "authEntityClass": -217092956,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "appProtocol": "tcp/udp",
        "applicationGroup": "other",
        "applicationType": "DEFAULT",
        "categoryId": "cb5915d1-afec-4c30-ac9e-069ff382272f",
        "createTime": 1517966743951,
        "encrypted": "false",
        "engineId": "3",
        "helpString": "PostgreSQL database",
        "ignoreConflict": false,
        "lastUpdateTime": 1517966743951,
        "longDescription": "PostgreSQL is an object-relational database management system (ORDBMS) available for many platforms including Linux, FreeBSD, Solaris, Microsoft Windows and Mac OS X. It is a free and open source software.",
        "name": "postgresql",
        "nbarId": "1361",
        "p2pTechnology": "false",
        "popularity": 1,
        "rank": 65535,
        "references": "http://www.postgresql.org/",
        "selectorId": "5432",
        "subCategory": "b90786f4-0190-45b8-9924-21b3e9682590",
        "trafficClass": "TRANSACTIONAL_DATA",
        "tunnel": "false"
      }
    ],
    "networkIdentity": [
      {
        "id": "ae11267d-fd6e-4626-8e00-114fb694a3f7",
        "instanceId": 14576,
        "authEntityId": 14576,
        "displayName": "14576",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "5432",
        "protocol": "UDP",
        "upperPort": 0
      },
      {
        "id": "483d2002-64f7-4494-a15a-38105f2feaf7",
        "instanceId": 14577,
        "authEntityId": 14577,
        "displayName": "14577",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "5432",
        "protocol": "TCP",
        "upperPort": 0
      }
    ],
    "parentScalableGroup": {
      "idRef": "8718395e-683e-4a2d-a232-100202751271"
    },
    "user": [

    ],
    "userGroup": [

    ]
  },
  {
    "id": "238af169-36c7-4614-a248-f274464757cd",
    "instanceId": 15130,
    "authEntityId": 15130,
    "displayName": "15130",
    "authEntityClass": -1909073334,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "createTime": 1517966744303,
    "deployed": false,
    "isSeeded": true,
    "isStale": false,
    "lastUpdateTime": 1517966744303,
    "name": "sgcp",
    "namespace": "scalablegroup:application",
    "provisioningState": "DEFINED",
    "qualifier": "application",
    "resourceVersion": 0,
    "targetIdList": [

    ],
    "type": "scalablegroup",
    "cfsChangeInfo": [

    ],
    "customProvisions": [

    ],
    "scalableGroupExternalHandle": "sgcp",
    "scalableGroupType": "APPLICATION",
    "securityGroupTag": 0,
    "state": "ACTIVE",
    "vnAgnostic": true,
    "identitySource": {
      "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
      "instanceId": 10010,
      "authEntityId": 10010,
      "displayName": "10010",
      "authEntityClass": 1738953278,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "state": "INACTIVE",
      "type": "NBAR"
    },
    "indicativeNetworkIdentity": [
      {
        "id": "422f1f70-de57-4fcf-b938-b3840e484453",
        "instanceId": 17040,
        "authEntityId": 17040,
        "displayName": "17040",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "440",
        "protocol": "UDP",
        "upperPort": 0
      },
      {
        "id": "43344df5-2417-49a7-ab72-51205e2e3d0b",
        "instanceId": 17041,
        "authEntityId": 17041,
        "displayName": "17041",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "440",
        "protocol": "TCP",
        "upperPort": 0
      }
    ],
    "networkApplications": [
      {
        "id": "4edd023a-ba89-4dc7-8023-6c280f19e8b9",
        "instanceId": 16073,
        "authEntityId": 16073,
        "displayName": "16073",
        "authEntityClass": -217092956,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "appProtocol": "tcp/udp",
        "applicationGroup": "other",
        "applicationType": "DEFAULT",
        "categoryId": "38a8e354-e2fe-4f3c-b1c5-2bbb90fb0d99",
        "createTime": 1517966744302,
        "encrypted": "false",
        "engineId": "3",
        "helpString": "sgcp",
        "ignoreConflict": false,
        "lastUpdateTime": 1517966744302,
        "longDescription": "sgcp, Registered with IANA on port 440 TCP/UDP",
        "name": "sgcp",
        "nbarId": "355",
        "p2pTechnology": "false",
        "popularity": 1,
        "rank": 65535,
        "references": "http://www.iana.org/assignments/service-names-port-numbers/service-names-port-numbers.xml",
        "selectorId": "440",
        "subCategory": "68c4f989-5735-4e16-8d1c-6a9d89d5df9c",
        "trafficClass": "SIGNALING",
        "tunnel": "false"
      }
    ],
    "networkIdentity": [
      {
        "id": "4c97b45e-5374-47b7-920d-67adaceb6b65",
        "instanceId": 17038,
        "authEntityId": 17038,
        "displayName": "17038",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "440",
        "protocol": "UDP",
        "upperPort": 0
      },
      {
        "id": "4b154ce1-969b-4637-8699-b05521911fd1",
        "instanceId": 17039,
        "authEntityId": 17039,
        "displayName": "17039",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "440",
        "protocol": "TCP",
        "upperPort": 0
      }
    ],
    "parentScalableGroup": {
      "idRef": "a3243a74-3864-4041-a927-0201030da17c"
    },
    "user": [

    ],
    "userGroup": [

    ]
  },
  {
    "id": "239d247e-00c3-4121-8688-2a9452cf01c5",
    "instanceId": 4413,
    "authEntityId": 4413,
    "displayName": "4413",
    "authEntityClass": -1909073334,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "createTime": 1517966742574,
    "deployed": false,
    "isSeeded": true,
    "isStale": false,
    "lastUpdateTime": 1517966742574,
    "name": "entomb",
    "namespace": "scalablegroup:application",
    "provisioningState": "DEFINED",
    "qualifier": "application",
    "resourceVersion": 0,
    "targetIdList": [

    ],
    "type": "scalablegroup",
    "cfsChangeInfo": [

    ],
    "customProvisions": [

    ],
    "scalableGroupExternalHandle": "entomb",
    "scalableGroupType": "APPLICATION",
    "securityGroupTag": 0,
    "state": "ACTIVE",
    "vnAgnostic": true,
    "identitySource": {
      "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
      "instanceId": 10010,
      "authEntityId": 10010,
      "displayName": "10010",
      "authEntityClass": 1738953278,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "state": "INACTIVE",
      "type": "NBAR"
    },
    "indicativeNetworkIdentity": [
      {
        "id": "162f3364-e7c2-45ec-9dc3-1cd8466ca43c",
        "instanceId": 13030,
        "authEntityId": 13030,
        "displayName": "13030",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "775",
        "protocol": "TCP",
        "upperPort": 0
      },
      {
        "id": "25f90856-2fa1-47f1-b84c-61581dbc7b2e",
        "instanceId": 13031,
        "authEntityId": 13031,
        "displayName": "13031",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "775",
        "protocol": "UDP",
        "upperPort": 0
      }
    ],
    "networkApplications": [
      {
        "id": "d6a56742-2407-4aa3-acd4-ca643cfdbab8",
        "instanceId": 11364,
        "authEntityId": 11364,
        "displayName": "11364",
        "authEntityClass": -217092956,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "appProtocol": "tcp/udp",
        "applicationGroup": "other",
        "applicationType": "DEFAULT",
        "categoryId": "6daa3dfd-3526-407a-88f0-97824b3f3a24",
        "createTime": 1517966742574,
        "encrypted": "false",
        "engineId": "3",
        "helpString": "entomb",
        "ignoreConflict": false,
        "lastUpdateTime": 1517966742574,
        "longDescription": "Registered with IANA on port 775 TCP/UDP",
        "name": "entomb",
        "nbarId": "647",
        "p2pTechnology": "false",
        "popularity": 1,
        "rank": 65535,
        "references": "http://www.iana.org/assignments/service-names-port-numbers/service-names-port-numbers.xml",
        "selectorId": "775",
        "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
        "trafficClass": "BULK_DATA",
        "tunnel": "false"
      }
    ],
    "networkIdentity": [
      {
        "id": "f5481cda-1739-44dc-a452-6b3fa8f040e2",
        "instanceId": 13028,
        "authEntityId": 13028,
        "displayName": "13028",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "775",
        "protocol": "UDP",
        "upperPort": 0
      },
      {
        "id": "379f138b-0ec8-4d18-a06e-b14565a80387",
        "instanceId": 13029,
        "authEntityId": 13029,
        "displayName": "13029",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "775",
        "protocol": "TCP",
        "upperPort": 0
      }
    ],
    "parentScalableGroup": {
      "idRef": "000c15bf-28fe-4fbb-9b69-35e6502721f1"
    },
    "user": [

    ],
    "userGroup": [

    ]
  },
  {
    "id": "23a77eb3-3bc3-4595-95be-f1cbff3e2dd7",
    "instanceId": 15276,
    "authEntityId": 15276,
    "displayName": "15276",
    "authEntityClass": -1909073334,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "createTime": 1517966744638,
    "deployed": false,
    "isSeeded": true,
    "isStale": false,
    "lastUpdateTime": 1517966744638,
    "name": "tftp",
    "namespace": "scalablegroup:application",
    "provisioningState": "DEFINED",
    "qualifier": "application",
    "resourceVersion": 0,
    "targetIdList": [

    ],
    "type": "scalablegroup",
    "cfsChangeInfo": [

    ],
    "customProvisions": [

    ],
    "scalableGroupExternalHandle": "tftp",
    "scalableGroupType": "APPLICATION",
    "securityGroupTag": 0,
    "state": "ACTIVE",
    "vnAgnostic": true,
    "identitySource": {
      "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
      "instanceId": 10010,
      "authEntityId": 10010,
      "displayName": "10010",
      "authEntityClass": 1738953278,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "state": "INACTIVE",
      "type": "NBAR"
    },
    "indicativeNetworkIdentity": [
      {
        "id": "c128883b-91a2-4ff9-874e-8b71e915484a",
        "instanceId": 17460,
        "authEntityId": 17460,
        "displayName": "17460",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "69",
        "protocol": "UDP",
        "upperPort": 0
      }
    ],
    "networkApplications": [
      {
        "id": "0b4a4aa1-5cbe-41cc-8f9e-364470829fea",
        "instanceId": 16219,
        "authEntityId": 16219,
        "displayName": "16219",
        "authEntityClass": -217092956,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "appProtocol": "udp",
        "applicationGroup": "tftp-group",
        "applicationType": "DEFAULT",
        "categoryId": "e6619ce8-97de-4c63-a53a-cceddbddc1a8",
        "createTime": 1517966744638,
        "encrypted": "false",
        "engineId": "3",
        "helpString": "Trivial File Transfer Protocol",
        "ignoreConflict": false,
        "lastUpdateTime": 1517966744638,
        "longDescription": "Trivial File Transfer Protocol (TFTP) is a file transfer protocol, with the functionality of a very basic form of FTP. It is useful for booting computers such as routers which do not have any data storage devices.",
        "name": "tftp",
        "nbarId": "48",
        "p2pTechnology": "false",
        "popularity": 9,
        "rank": 65535,
        "references": "http://www.ietf.org/rfc/rfc1350.txt",
        "selectorId": "69",
        "subCategory": "50b55e2e-d0d0-4404-bea4-819351565d90",
        "trafficClass": "OPS_ADMIN_MGMT",
        "tunnel": "false"
      }
    ],
    "networkIdentity": [
      {
        "id": "0fd9dda6-e5bf-4423-8fa2-ecaebe632aaa",
        "instanceId": 17459,
        "authEntityId": 17459,
        "displayName": "17459",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "67,68,69",
        "protocol": "UDP",
        "upperPort": 0
      }
    ],
    "parentScalableGroup": {
      "idRef": "383363b1-4dd5-49d1-bfe4-34daac437cfb"
    },
    "user": [

    ],
    "userGroup": [

    ]
  },
  {
    "id": "23b562ef-5faf-4ed1-9aa8-53773e4d677c",
    "instanceId": 4579,
    "authEntityId": 4579,
    "displayName": "4579",
    "authEntityClass": -1909073334,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "createTime": 1517966743041,
    "deployed": false,
    "isSeeded": true,
    "isStale": false,
    "lastUpdateTime": 1517966743041,
    "name": "iclcnet-locate",
    "namespace": "scalablegroup:application",
    "provisioningState": "DEFINED",
    "qualifier": "application",
    "resourceVersion": 0,
    "targetIdList": [

    ],
    "type": "scalablegroup",
    "cfsChangeInfo": [

    ],
    "customProvisions": [

    ],
    "scalableGroupExternalHandle": "iclcnet-locate",
    "scalableGroupType": "APPLICATION",
    "securityGroupTag": 0,
    "state": "ACTIVE",
    "vnAgnostic": true,
    "identitySource": {
      "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
      "instanceId": 10010,
      "authEntityId": 10010,
      "displayName": "10010",
      "authEntityClass": 1738953278,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "state": "INACTIVE",
      "type": "NBAR"
    },
    "indicativeNetworkIdentity": [
      {
        "id": "3259dae6-8559-4658-a8ac-d456b08aa7bf",
        "instanceId": 13457,
        "authEntityId": 13457,
        "displayName": "13457",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "886",
        "protocol": "TCP",
        "upperPort": 0
      },
      {
        "id": "937bdfec-4d39-4be8-8b93-b02798e8a789",
        "instanceId": 13458,
        "authEntityId": 13458,
        "displayName": "13458",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "886",
        "protocol": "UDP",
        "upperPort": 0
      }
    ],
    "networkApplications": [
      {
        "id": "c06f9872-5436-4c4b-8e08-0c586391e8d9",
        "instanceId": 11529,
        "authEntityId": 11529,
        "displayName": "11529",
        "authEntityClass": -217092956,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "appProtocol": "tcp/udp",
        "applicationGroup": "other",
        "applicationType": "DEFAULT",
        "categoryId": "6daa3dfd-3526-407a-88f0-97824b3f3a24",
        "createTime": 1517966743041,
        "encrypted": "false",
        "engineId": "3",
        "helpString": "ICL coNETion locate server",
        "ignoreConflict": false,
        "lastUpdateTime": 1517966743041,
        "longDescription": "Registered with IANA on port 886 TCP/UDP",
        "name": "iclcnet-locate",
        "nbarId": "660",
        "p2pTechnology": "false",
        "popularity": 1,
        "rank": 65535,
        "references": "http://www.iana.org/assignments/service-names-port-numbers/service-names-port-numbers.xml",
        "selectorId": "886",
        "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
        "trafficClass": "BULK_DATA",
        "tunnel": "false"
      }
    ],
    "networkIdentity": [
      {
        "id": "ba7d7358-79e2-46d4-95f1-b5c4da42fbc1",
        "instanceId": 13456,
        "authEntityId": 13456,
        "displayName": "13456",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "886",
        "protocol": "TCP",
        "upperPort": 0
      },
      {
        "id": "c921ee0f-a168-44cc-8638-269525b2c291",
        "instanceId": 13455,
        "authEntityId": 13455,
        "displayName": "13455",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "886",
        "protocol": "UDP",
        "upperPort": 0
      }
    ],
    "parentScalableGroup": {
      "idRef": "000c15bf-28fe-4fbb-9b69-35e6502721f1"
    },
    "user": [

    ],
    "userGroup": [

    ]
  },
  {
    "id": "243c2038-a331-4b52-8ad9-3e26b94394c9",
    "instanceId": 4347,
    "authEntityId": 4347,
    "displayName": "4347",
    "authEntityClass": -1909073334,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "createTime": 1517966742355,
    "deployed": false,
    "isSeeded": true,
    "isStale": false,
    "lastUpdateTime": 1517966742355,
    "name": "decladebug",
    "namespace": "scalablegroup:application",
    "provisioningState": "DEFINED",
    "qualifier": "application",
    "resourceVersion": 0,
    "targetIdList": [

    ],
    "type": "scalablegroup",
    "cfsChangeInfo": [

    ],
    "customProvisions": [

    ],
    "scalableGroupExternalHandle": "decladebug",
    "scalableGroupType": "APPLICATION",
    "securityGroupTag": 0,
    "state": "ACTIVE",
    "vnAgnostic": true,
    "identitySource": {
      "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
      "instanceId": 10010,
      "authEntityId": 10010,
      "displayName": "10010",
      "authEntityClass": 1738953278,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "state": "INACTIVE",
      "type": "NBAR"
    },
    "indicativeNetworkIdentity": [
      {
        "id": "031aa0c1-3a8b-4934-9e58-3ad94237d84f",
        "instanceId": 12820,
        "authEntityId": 12820,
        "displayName": "12820",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "410",
        "protocol": "UDP",
        "upperPort": 0
      },
      {
        "id": "87e1701c-4803-4928-bf70-9cde454cb839",
        "instanceId": 12821,
        "authEntityId": 12821,
        "displayName": "12821",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "410",
        "protocol": "TCP",
        "upperPort": 0
      }
    ],
    "networkApplications": [
      {
        "id": "914d19b2-6467-4fc4-9b05-b106210ac77d",
        "instanceId": 11298,
        "authEntityId": 11298,
        "displayName": "11298",
        "authEntityClass": -217092956,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "appProtocol": "tcp/udp",
        "applicationGroup": "other",
        "applicationType": "DEFAULT",
        "categoryId": "6daa3dfd-3526-407a-88f0-97824b3f3a24",
        "createTime": 1517966742355,
        "encrypted": "false",
        "engineId": "3",
        "helpString": "DECLadebug Remote Debug Protocol",
        "ignoreConflict": false,
        "lastUpdateTime": 1517966742355,
        "longDescription": "Registered with IANA on port 410 TCP/UDP",
        "name": "decladebug",
        "nbarId": "325",
        "p2pTechnology": "false",
        "popularity": 1,
        "rank": 65535,
        "references": "http://www.iana.org/assignments/service-names-port-numbers/service-names-port-numbers.xml",
        "selectorId": "410",
        "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
        "trafficClass": "BULK_DATA",
        "tunnel": "false"
      }
    ],
    "networkIdentity": [
      {
        "id": "b0f11f60-2d6f-4f0c-82f4-005f69006941",
        "instanceId": 12818,
        "authEntityId": 12818,
        "displayName": "12818",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "410",
        "protocol": "UDP",
        "upperPort": 0
      },
      {
        "id": "51946884-e0ce-4a2c-b8d9-248386e181c3",
        "instanceId": 12819,
        "authEntityId": 12819,
        "displayName": "12819",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "410",
        "protocol": "TCP",
        "upperPort": 0
      }
    ],
    "parentScalableGroup": {
      "idRef": "000c15bf-28fe-4fbb-9b69-35e6502721f1"
    },
    "user": [

    ],
    "userGroup": [

    ]
  },
  {
    "id": "24559336-9a85-4091-a731-8522974132d2",
    "instanceId": 4829,
    "authEntityId": 4829,
    "displayName": "4829",
    "authEntityClass": -1909073334,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "createTime": 1517966743573,
    "deployed": false,
    "isSeeded": true,
    "isStale": false,
    "lastUpdateTime": 1517966743573,
    "name": "ndsauth",
    "namespace": "scalablegroup:application",
    "provisioningState": "DEFINED",
    "qualifier": "application",
    "resourceVersion": 0,
    "targetIdList": [

    ],
    "type": "scalablegroup",
    "cfsChangeInfo": [

    ],
    "customProvisions": [

    ],
    "scalableGroupExternalHandle": "ndsauth",
    "scalableGroupType": "APPLICATION",
    "securityGroupTag": 0,
    "state": "ACTIVE",
    "vnAgnostic": true,
    "identitySource": {
      "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
      "instanceId": 10010,
      "authEntityId": 10010,
      "displayName": "10010",
      "authEntityClass": 1738953278,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "state": "INACTIVE",
      "type": "NBAR"
    },
    "indicativeNetworkIdentity": [
      {
        "id": "1505bb52-33db-49b0-80ab-1942b57ca893",
        "instanceId": 14122,
        "authEntityId": 14122,
        "displayName": "14122",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "353",
        "protocol": "UDP",
        "upperPort": 0
      },
      {
        "id": "a7cd3780-3802-4490-8fc0-4a0d6bec313c",
        "instanceId": 14123,
        "authEntityId": 14123,
        "displayName": "14123",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "353",
        "protocol": "TCP",
        "upperPort": 0
      }
    ],
    "networkApplications": [
      {
        "id": "3722e706-fad4-4332-9721-dab614edb863",
        "instanceId": 11778,
        "authEntityId": 11778,
        "displayName": "11778",
        "authEntityClass": -217092956,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "appProtocol": "tcp/udp",
        "applicationGroup": "other",
        "applicationType": "DEFAULT",
        "categoryId": "1fc17362-9334-4c7b-b752-5afdbb574017",
        "createTime": 1517966743573,
        "encrypted": "false",
        "engineId": "3",
        "helpString": "NDSAUTH",
        "ignoreConflict": false,
        "lastUpdateTime": 1517966743573,
        "longDescription": "NDS authentication is the process of proving one's identification to Network Domain Security. Authentication is accomplished by having an object in the NDS tree represent the user requesting access. This object contains or has access to all of the pertinent information about the user (i.e., password signature, encryption keys, etc.) that NDS needs to prove that users communicating from client machines are who they say they are.",
        "name": "ndsauth",
        "nbarId": "269",
        "p2pTechnology": "false",
        "popularity": 1,
        "rank": 65535,
        "references": "http://ldapwiki.willeke.com/wiki/NDS%20Authentication",
        "selectorId": "353",
        "subCategory": "e390124a-0fd6-4253-b68e-4b0c1af8cb4b",
        "trafficClass": "OPS_ADMIN_MGMT",
        "tunnel": "false"
      }
    ],
    "networkIdentity": [
      {
        "id": "d6db7f88-8dbd-41ae-9d79-eb4f66cfad95",
        "instanceId": 14120,
        "authEntityId": 14120,
        "displayName": "14120",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "353",
        "protocol": "UDP",
        "upperPort": 0
      },
      {
        "id": "7a27f920-328f-4873-acc8-e56676ddd30f",
        "instanceId": 14121,
        "authEntityId": 14121,
        "displayName": "14121",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "353",
        "protocol": "TCP",
        "upperPort": 0
      }
    ],
    "parentScalableGroup": {
      "idRef": "7788bf2d-e289-4597-b500-17aacb7e820e"
    },
    "user": [

    ],
    "userGroup": [

    ]
  },
  {
    "id": "24622a1b-aec2-4584-a442-984b9eb3191f",
    "instanceId": 4137,
    "authEntityId": 4137,
    "displayName": "4137",
    "authEntityClass": -1909073334,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "createTime": 1517966741346,
    "deployed": false,
    "isSeeded": true,
    "isStale": false,
    "lastUpdateTime": 1517966741346,
    "name": "atlassian",
    "namespace": "scalablegroup:application",
    "provisioningState": "DEFINED",
    "qualifier": "application",
    "resourceVersion": 0,
    "targetIdList": [

    ],
    "type": "scalablegroup",
    "cfsChangeInfo": [

    ],
    "customProvisions": [

    ],
    "scalableGroupExternalHandle": "atlassian",
    "scalableGroupType": "APPLICATION",
    "securityGroupTag": 0,
    "state": "ACTIVE",
    "vnAgnostic": true,
    "identitySource": {
      "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
      "instanceId": 10010,
      "authEntityId": 10010,
      "displayName": "10010",
      "authEntityClass": 1738953278,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "state": "INACTIVE",
      "type": "NBAR"
    },
    "indicativeNetworkIdentity": [

    ],
    "networkApplications": [
      {
        "id": "912eacd6-e67c-4b80-a1c2-414af4345f1a",
        "instanceId": 11099,
        "authEntityId": 11099,
        "displayName": "11099",
        "authEntityClass": -217092956,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "appProtocol": "tcp/udp",
        "applicationGroup": "other",
        "applicationType": "DEFAULT",
        "categoryId": "878644b5-fe91-45b4-9204-31c84a73ba48",
        "createTime": 1517966741346,
        "encrypted": "false",
        "engineId": "13",
        "helpString": "Atlassian - Project Control and Management Software.",
        "ignoreConflict": false,
        "lastUpdateTime": 1517966741346,
        "longDescription": "Atlassian - Project Control and Management Software.",
        "name": "atlassian",
        "nbarId": "1699",
        "p2pTechnology": "false",
        "popularity": 3,
        "rank": 65535,
        "references": "http://atlassian.com",
        "selectorId": "769",
        "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
        "trafficClass": "TRANSACTIONAL_DATA",
        "tunnel": "false"
      }
    ],
    "networkIdentity": [
      {
        "id": "81bad363-04fa-4bd3-adc2-0f5b61fd5917",
        "instanceId": 12275,
        "authEntityId": 12275,
        "displayName": "12275",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "53,5353",
        "protocol": "UDP",
        "upperPort": 0
      },
      {
        "id": "4fac5707-3bdb-4a31-b5cd-08118e7280e4",
        "instanceId": 12276,
        "authEntityId": 12276,
        "displayName": "12276",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "53,80,443,5353",
        "protocol": "TCP",
        "upperPort": 0
      }
    ],
    "parentScalableGroup": {
      "idRef": "28e08de4-d7b2-4521-84ce-bc1e5ee0b9cc"
    },
    "user": [

    ],
    "userGroup": [

    ]
  },
  {
    "id": "24690906-bb52-4c24-bac3-9c6066d4152f",
    "instanceId": 4946,
    "authEntityId": 4946,
    "displayName": "4946",
    "authEntityClass": -1909073334,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "createTime": 1517966743874,
    "deployed": false,
    "isSeeded": true,
    "isStale": false,
    "lastUpdateTime": 1517966743874,
    "name": "pcoip",
    "namespace": "scalablegroup:application",
    "provisioningState": "DEFINED",
    "qualifier": "application",
    "resourceVersion": 0,
    "targetIdList": [

    ],
    "type": "scalablegroup",
    "cfsChangeInfo": [

    ],
    "customProvisions": [

    ],
    "scalableGroupExternalHandle": "pcoip",
    "scalableGroupType": "APPLICATION",
    "securityGroupTag": 0,
    "state": "ACTIVE",
    "vnAgnostic": true,
    "identitySource": {
      "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
      "instanceId": 10010,
      "authEntityId": 10010,
      "displayName": "10010",
      "authEntityClass": 1738953278,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "state": "INACTIVE",
      "type": "NBAR"
    },
    "indicativeNetworkIdentity": [
      {
        "id": "3f3c6266-0458-4b57-bbd2-3e83ebc48ef8",
        "instanceId": 14500,
        "authEntityId": 14500,
        "displayName": "14500",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "4172",
        "protocol": "TCP",
        "upperPort": 0
      }
    ],
    "networkApplications": [
      {
        "id": "44e2e254-6013-4dd4-872a-f260f1940219",
        "instanceId": 11895,
        "authEntityId": 11895,
        "displayName": "11895",
        "authEntityClass": -217092956,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "appProtocol": "tcp",
        "applicationGroup": "other",
        "applicationType": "DEFAULT",
        "categoryId": "7e19fdea-6222-4c57-9e66-66c2340d6bc3",
        "createTime": 1517966743874,
        "encrypted": "true",
        "engineId": "3",
        "helpString": "PC-over-IP - Virtual Desktop Infrastructure",
        "ignoreConflict": false,
        "lastUpdateTime": 1517966743874,
        "longDescription": "PCoIP is a remote display protocol, developed by Teradici. It is used to remotely connect to desktops and applications sending compressed pixels across the IP network. PCoIP can be used through hardware zero clients or software VMware View clients. PCoIP can be used to extend non display elements of the PC across the network, such as USB and audio.",
        "name": "pcoip",
        "nbarId": "1427",
        "p2pTechnology": "false",
        "popularity": 10,
        "rank": 65535,
        "references": "http://www.teradici.com/",
        "selectorId": "4172",
        "subCategory": "ff664205-64f3-447e-acff-1ec1ba9a6c31",
        "trafficClass": "MULTIMEDIA_STREAMING",
        "tunnel": "false"
      }
    ],
    "networkIdentity": [
      {
        "id": "6d5c651d-f967-414e-b9c0-5f0f1f8789c1",
        "instanceId": 14499,
        "authEntityId": 14499,
        "displayName": "14499",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "80,443,4172",
        "protocol": "TCP",
        "upperPort": 0
      }
    ],
    "parentScalableGroup": {
      "idRef": "4a6b37c6-7e11-40fe-a6b1-719b24813145"
    },
    "user": [

    ],
    "userGroup": [

    ]
  },
  {
    "id": "24a0d461-5509-459b-88be-ad19d6e54521",
    "instanceId": 4384,
    "authEntityId": 4384,
    "displayName": "4384",
    "authEntityClass": -1909073334,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "createTime": 1517966742483,
    "deployed": false,
    "isSeeded": true,
    "isStale": false,
    "lastUpdateTime": 1517966742483,
    "name": "douban",
    "namespace": "scalablegroup:application",
    "provisioningState": "DEFINED",
    "qualifier": "application",
    "resourceVersion": 0,
    "targetIdList": [

    ],
    "type": "scalablegroup",
    "cfsChangeInfo": [

    ],
    "customProvisions": [

    ],
    "scalableGroupExternalHandle": "douban",
    "scalableGroupType": "APPLICATION",
    "securityGroupTag": 0,
    "state": "ACTIVE",
    "vnAgnostic": true,
    "identitySource": {
      "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
      "instanceId": 10010,
      "authEntityId": 10010,
      "displayName": "10010",
      "authEntityClass": 1738953278,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "state": "INACTIVE",
      "type": "NBAR"
    },
    "indicativeNetworkIdentity": [

    ],
    "networkApplications": [
      {
        "id": "8ad651e2-c77d-44e6-95ff-4af8760031e9",
        "instanceId": 11335,
        "authEntityId": 11335,
        "displayName": "11335",
        "authEntityClass": -217092956,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "appProtocol": "tcp/udp",
        "applicationGroup": "other",
        "applicationType": "DEFAULT",
        "categoryId": "878644b5-fe91-45b4-9204-31c84a73ba48",
        "createTime": 1517966742483,
        "encrypted": "false",
        "engineId": "13",
        "helpString": "Douban - Chinese social networking service.",
        "ignoreConflict": false,
        "lastUpdateTime": 1517966742483,
        "longDescription": "Douban - Chinese social networking service.",
        "name": "douban",
        "nbarId": "1568",
        "p2pTechnology": "false",
        "popularity": 3,
        "rank": 65535,
        "references": "http://douban.com",
        "selectorId": "639",
        "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
        "trafficClass": "TRANSACTIONAL_DATA",
        "tunnel": "false"
      }
    ],
    "networkIdentity": [
      {
        "id": "c941edb9-9286-47e0-acfc-b2059b8f12fa",
        "instanceId": 12946,
        "authEntityId": 12946,
        "displayName": "12946",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "53,80,443,5353",
        "protocol": "TCP",
        "upperPort": 0
      },
      {
        "id": "fce9f694-49f7-4d1b-921f-9cf40a3d1532",
        "instanceId": 12947,
        "authEntityId": 12947,
        "displayName": "12947",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "53,5353",
        "protocol": "UDP",
        "upperPort": 0
      }
    ],
    "parentScalableGroup": {
      "idRef": "28e08de4-d7b2-4521-84ce-bc1e5ee0b9cc"
    },
    "user": [

    ],
    "userGroup": [

    ]
  },
  {
    "id": "24e4ba54-1a5d-4e87-be87-c17353ede715",
    "instanceId": 15450,
    "authEntityId": 15450,
    "displayName": "15450",
    "authEntityClass": -1909073334,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "createTime": 1517966744987,
    "deployed": false,
    "isSeeded": true,
    "isStale": false,
    "lastUpdateTime": 1517966744987,
    "name": "xvttp",
    "namespace": "scalablegroup:application",
    "provisioningState": "DEFINED",
    "qualifier": "application",
    "resourceVersion": 0,
    "targetIdList": [

    ],
    "type": "scalablegroup",
    "cfsChangeInfo": [

    ],
    "customProvisions": [

    ],
    "scalableGroupExternalHandle": "xvttp",
    "scalableGroupType": "APPLICATION",
    "securityGroupTag": 0,
    "state": "ACTIVE",
    "vnAgnostic": true,
    "identitySource": {
      "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
      "instanceId": 10010,
      "authEntityId": 10010,
      "displayName": "10010",
      "authEntityClass": 1738953278,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "state": "INACTIVE",
      "type": "NBAR"
    },
    "indicativeNetworkIdentity": [
      {
        "id": "e6c8573d-8c98-4d14-b5de-797d39d4ac87",
        "instanceId": 17936,
        "authEntityId": 17936,
        "displayName": "17936",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "508",
        "protocol": "UDP",
        "upperPort": 0
      },
      {
        "id": "445d8d0b-0211-4f9d-a447-467c24cbf4aa",
        "instanceId": 17935,
        "authEntityId": 17935,
        "displayName": "17935",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "508",
        "protocol": "TCP",
        "upperPort": 0
      }
    ],
    "networkApplications": [
      {
        "id": "0bfe76ac-aaef-43e0-8c78-9bfe13459899",
        "instanceId": 16393,
        "authEntityId": 16393,
        "displayName": "16393",
        "authEntityClass": -217092956,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "appProtocol": "tcp/udp",
        "applicationGroup": "other",
        "applicationType": "DEFAULT",
        "categoryId": "6daa3dfd-3526-407a-88f0-97824b3f3a24",
        "createTime": 1517966744987,
        "encrypted": "false",
        "engineId": "3",
        "helpString": "xvttp",
        "ignoreConflict": false,
        "lastUpdateTime": 1517966744987,
        "longDescription": "Registered with IANA on port 508 TCP/UDP",
        "name": "xvttp",
        "nbarId": "422",
        "p2pTechnology": "false",
        "popularity": 1,
        "rank": 65535,
        "references": "http://www.iana.org/assignments/service-names-port-numbers/service-names-port-numbers.xml",
        "selectorId": "508",
        "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
        "trafficClass": "BULK_DATA",
        "tunnel": "false"
      }
    ],
    "networkIdentity": [
      {
        "id": "fe78a5fa-50a4-48ca-b009-4f9edf78ec27",
        "instanceId": 17933,
        "authEntityId": 17933,
        "displayName": "17933",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "508",
        "protocol": "TCP",
        "upperPort": 0
      },
      {
        "id": "29f680d2-894e-465d-a582-19faabca6c67",
        "instanceId": 17934,
        "authEntityId": 17934,
        "displayName": "17934",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "508",
        "protocol": "UDP",
        "upperPort": 0
      }
    ],
    "parentScalableGroup": {
      "idRef": "000c15bf-28fe-4fbb-9b69-35e6502721f1"
    },
    "user": [

    ],
    "userGroup": [

    ]
  },
  {
    "id": "254b455f-f32f-4f99-aca7-47d9a77d4ac1",
    "instanceId": 15262,
    "authEntityId": 15262,
    "displayName": "15262",
    "authEntityClass": -1909073334,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "createTime": 1517966744605,
    "deployed": false,
    "isSeeded": true,
    "isStale": false,
    "lastUpdateTime": 1517966744605,
    "name": "ted",
    "namespace": "scalablegroup:application",
    "provisioningState": "DEFINED",
    "qualifier": "application",
    "resourceVersion": 0,
    "targetIdList": [

    ],
    "type": "scalablegroup",
    "cfsChangeInfo": [

    ],
    "customProvisions": [

    ],
    "scalableGroupExternalHandle": "ted",
    "scalableGroupType": "APPLICATION",
    "securityGroupTag": 0,
    "state": "ACTIVE",
    "vnAgnostic": true,
    "identitySource": {
      "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
      "instanceId": 10010,
      "authEntityId": 10010,
      "displayName": "10010",
      "authEntityClass": 1738953278,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "state": "INACTIVE",
      "type": "NBAR"
    },
    "indicativeNetworkIdentity": [

    ],
    "networkApplications": [
      {
        "id": "3023126d-bea7-4de4-8b80-beb54e0e9af8",
        "instanceId": 16205,
        "authEntityId": 16205,
        "displayName": "16205",
        "authEntityClass": -217092956,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "appProtocol": "tcp/udp",
        "applicationGroup": "other",
        "applicationType": "DEFAULT",
        "categoryId": "878644b5-fe91-45b4-9204-31c84a73ba48",
        "createTime": 1517966744605,
        "encrypted": "false",
        "engineId": "13",
        "helpString": "TED - Conference and Talk show to share ideas.",
        "ignoreConflict": false,
        "lastUpdateTime": 1517966744605,
        "longDescription": "TED - Conference and Talk show to share ideas.",
        "name": "ted",
        "nbarId": "1698",
        "p2pTechnology": "false",
        "popularity": 3,
        "rank": 65535,
        "references": "http://ted.com",
        "selectorId": "768",
        "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
        "trafficClass": "TRANSACTIONAL_DATA",
        "tunnel": "false"
      }
    ],
    "networkIdentity": [
      {
        "id": "82261883-bb03-4495-babf-e1bd7f75cccb",
        "instanceId": 17421,
        "authEntityId": 17421,
        "displayName": "17421",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "53,80,443,5353",
        "protocol": "TCP",
        "upperPort": 0
      },
      {
        "id": "bf821e80-82c7-4ea4-9083-a14cc7a0826f",
        "instanceId": 17422,
        "authEntityId": 17422,
        "displayName": "17422",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "53,5353",
        "protocol": "UDP",
        "upperPort": 0
      }
    ],
    "parentScalableGroup": {
      "idRef": "28e08de4-d7b2-4521-84ce-bc1e5ee0b9cc"
    },
    "user": [

    ],
    "userGroup": [

    ]
  },
  {
    "id": "25506fb6-f2f3-4d45-9e1e-98f7ca9c3a5f",
    "instanceId": 4348,
    "authEntityId": 4348,
    "displayName": "4348",
    "authEntityClass": -1909073334,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "createTime": 1517966742360,
    "deployed": false,
    "isSeeded": true,
    "isStale": false,
    "lastUpdateTime": 1517966742360,
    "name": "decvms-sysmgt",
    "namespace": "scalablegroup:application",
    "provisioningState": "DEFINED",
    "qualifier": "application",
    "resourceVersion": 0,
    "targetIdList": [

    ],
    "type": "scalablegroup",
    "cfsChangeInfo": [

    ],
    "customProvisions": [

    ],
    "scalableGroupExternalHandle": "decvms-sysmgt",
    "scalableGroupType": "APPLICATION",
    "securityGroupTag": 0,
    "state": "ACTIVE",
    "vnAgnostic": true,
    "identitySource": {
      "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
      "instanceId": 10010,
      "authEntityId": 10010,
      "displayName": "10010",
      "authEntityClass": 1738953278,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "state": "INACTIVE",
      "type": "NBAR"
    },
    "indicativeNetworkIdentity": [
      {
        "id": "495c08d5-d773-4dab-b6af-3bedbdaf37f7",
        "instanceId": 12824,
        "authEntityId": 12824,
        "displayName": "12824",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "441",
        "protocol": "TCP",
        "upperPort": 0
      },
      {
        "id": "434602ce-1f86-476f-acf7-562887391fd3",
        "instanceId": 12825,
        "authEntityId": 12825,
        "displayName": "12825",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "441",
        "protocol": "UDP",
        "upperPort": 0
      }
    ],
    "networkApplications": [
      {
        "id": "76394b44-9ac0-46e0-90d4-a86579d7d51c",
        "instanceId": 11299,
        "authEntityId": 11299,
        "displayName": "11299",
        "authEntityClass": -217092956,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "appProtocol": "tcp/udp",
        "applicationGroup": "other",
        "applicationType": "DEFAULT",
        "categoryId": "1fc17362-9334-4c7b-b752-5afdbb574017",
        "createTime": 1517966742360,
        "encrypted": "false",
        "engineId": "3",
        "helpString": "decvms-sysmgt",
        "ignoreConflict": false,
        "lastUpdateTime": 1517966742360,
        "longDescription": "Registered with IANA on port 441 TCP/UDP",
        "name": "decvms-sysmgt",
        "nbarId": "356",
        "p2pTechnology": "false",
        "popularity": 1,
        "rank": 65535,
        "references": "http://www.iana.org/assignments/service-names-port-numbers/service-names-port-numbers.xml",
        "selectorId": "441",
        "subCategory": "f3c0f761-d894-4112-ab89-b90fc84fd660",
        "trafficClass": "OPS_ADMIN_MGMT",
        "tunnel": "false"
      }
    ],
    "networkIdentity": [
      {
        "id": "a0f0026b-358e-4525-97d3-e620b320436d",
        "instanceId": 12822,
        "authEntityId": 12822,
        "displayName": "12822",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "441",
        "protocol": "TCP",
        "upperPort": 0
      },
      {
        "id": "5244cbc3-bdf8-47af-afc7-e6f1a860110a",
        "instanceId": 12823,
        "authEntityId": 12823,
        "displayName": "12823",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "441",
        "protocol": "UDP",
        "upperPort": 0
      }
    ],
    "parentScalableGroup": {
      "idRef": "5fcd74d4-8726-47f7-a088-c457863da7ef"
    },
    "user": [

    ],
    "userGroup": [

    ]
  },
  {
    "id": "256a611c-3c9b-4020-9336-1d92c3eea004",
    "instanceId": 4133,
    "authEntityId": 4133,
    "displayName": "4133",
    "authEntityClass": -1909073334,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "createTime": 1517966741285,
    "deployed": false,
    "isSeeded": true,
    "isStale": false,
    "lastUpdateTime": 1517966741285,
    "name": "at-echo",
    "namespace": "scalablegroup:application",
    "provisioningState": "DEFINED",
    "qualifier": "application",
    "resourceVersion": 0,
    "targetIdList": [

    ],
    "type": "scalablegroup",
    "cfsChangeInfo": [

    ],
    "customProvisions": [

    ],
    "scalableGroupExternalHandle": "at-echo",
    "scalableGroupType": "APPLICATION",
    "securityGroupTag": 0,
    "state": "ACTIVE",
    "vnAgnostic": true,
    "identitySource": {
      "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
      "instanceId": 10010,
      "authEntityId": 10010,
      "displayName": "10010",
      "authEntityClass": 1738953278,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "state": "INACTIVE",
      "type": "NBAR"
    },
    "indicativeNetworkIdentity": [
      {
        "id": "bb0f1158-f472-45e8-adad-44cd7080cdc5",
        "instanceId": 12261,
        "authEntityId": 12261,
        "displayName": "12261",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "204",
        "protocol": "TCP",
        "upperPort": 0
      },
      {
        "id": "6221d597-fe14-4ff9-98b7-b3c392e85476",
        "instanceId": 12262,
        "authEntityId": 12262,
        "displayName": "12262",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "204",
        "protocol": "UDP",
        "upperPort": 0
      }
    ],
    "networkApplications": [
      {
        "id": "f131e177-f9ba-4749-b492-c33e8360bf0f",
        "instanceId": 11095,
        "authEntityId": 11095,
        "displayName": "11095",
        "authEntityClass": -217092956,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "appProtocol": "tcp/udp",
        "applicationGroup": "apple-talk-group",
        "applicationType": "DEFAULT",
        "categoryId": "1fc17362-9334-4c7b-b752-5afdbb574017",
        "createTime": 1517966741284,
        "encrypted": "false",
        "engineId": "3",
        "helpString": "AppleTalk Echo",
        "ignoreConflict": false,
        "lastUpdateTime": 1517966741284,
        "longDescription": "AppleTalk Echo Protocol (AEP) is a transport layer protocol designed to test the reachability of network nodes. AEP generates packets to be sent to the network node and is identified in the Type field of a packet as an AEP packet.",
        "name": "at-echo",
        "nbarId": "1102",
        "p2pTechnology": "false",
        "popularity": 1,
        "rank": 65535,
        "references": "http://en.wikipedia.org/wiki/AppleTalk#AppleTalk_Echo_Protocol",
        "selectorId": "204",
        "subCategory": "f3c0f761-d894-4112-ab89-b90fc84fd660",
        "trafficClass": "OPS_ADMIN_MGMT",
        "tunnel": "false"
      }
    ],
    "networkIdentity": [
      {
        "id": "0d8a9904-ff43-4c2a-9dba-bcfe82509c1e",
        "instanceId": 12259,
        "authEntityId": 12259,
        "displayName": "12259",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "204",
        "protocol": "TCP",
        "upperPort": 0
      },
      {
        "id": "7097d566-4ee4-484f-b7ef-51f979b56ed5",
        "instanceId": 12260,
        "authEntityId": 12260,
        "displayName": "12260",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "204",
        "protocol": "UDP",
        "upperPort": 0
      }
    ],
    "parentScalableGroup": {
      "idRef": "5fcd74d4-8726-47f7-a088-c457863da7ef"
    },
    "user": [

    ],
    "userGroup": [

    ]
  },
  {
    "id": "25ae958f-4f12-4abf-9589-bf5f10c1b601",
    "instanceId": 4469,
    "authEntityId": 4469,
    "displayName": "4469",
    "authEntityClass": -1909073334,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "createTime": 1517966742748,
    "deployed": false,
    "isSeeded": true,
    "isStale": false,
    "lastUpdateTime": 1517966742748,
    "name": "ftp-data",
    "namespace": "scalablegroup:application",
    "provisioningState": "DEFINED",
    "qualifier": "application",
    "resourceVersion": 0,
    "targetIdList": [

    ],
    "type": "scalablegroup",
    "cfsChangeInfo": [

    ],
    "customProvisions": [

    ],
    "scalableGroupExternalHandle": "ftp-data",
    "scalableGroupType": "APPLICATION",
    "securityGroupTag": 0,
    "state": "ACTIVE",
    "vnAgnostic": true,
    "identitySource": {
      "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
      "instanceId": 10010,
      "authEntityId": 10010,
      "displayName": "10010",
      "authEntityClass": 1738953278,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "state": "INACTIVE",
      "type": "NBAR"
    },
    "indicativeNetworkIdentity": [
      {
        "id": "8b4ceb93-1807-4da1-84ec-7b43abab09e9",
        "instanceId": 13163,
        "authEntityId": 13163,
        "displayName": "13163",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "20",
        "protocol": "TCP",
        "upperPort": 0
      },
      {
        "id": "128d2084-b570-44f7-b3be-f8eaa597bd4c",
        "instanceId": 13164,
        "authEntityId": 13164,
        "displayName": "13164",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "20",
        "protocol": "UDP",
        "upperPort": 0
      }
    ],
    "networkApplications": [
      {
        "id": "0ad8edf6-6fc4-4f63-b725-9c6e60974236",
        "instanceId": 11420,
        "authEntityId": 11420,
        "displayName": "11420",
        "authEntityClass": -217092956,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "appProtocol": "tcp/udp",
        "applicationGroup": "ftp-group",
        "applicationType": "DEFAULT",
        "categoryId": "e6619ce8-97de-4c63-a53a-cceddbddc1a8",
        "createTime": 1517966742748,
        "encrypted": "false",
        "engineId": "3",
        "helpString": "File Transfer Protocol, Data part only",
        "ignoreConflict": false,
        "lastUpdateTime": 1517966742748,
        "longDescription": "FTP is built on a client-server architecture and uses separate control and data connections between the client and the server. FTP users may authenticate themselves using a clear-text sign-in protocol, normally in the form of a username and password, but can connect anonymously if the server is configured to allow it.",
        "name": "ftp-data",
        "nbarId": "909",
        "p2pTechnology": "false",
        "popularity": 10,
        "rank": 65535,
        "references": "http://tools.ietf.org/html/rfc959",
        "selectorId": "20",
        "subCategory": "50b55e2e-d0d0-4404-bea4-819351565d90",
        "trafficClass": "BULK_DATA",
        "tunnel": "false"
      }
    ],
    "networkIdentity": [
      {
        "id": "898f3ec6-769f-4462-aadc-123f6e38e536",
        "instanceId": 13161,
        "authEntityId": 13161,
        "displayName": "13161",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "20",
        "protocol": "UDP",
        "upperPort": 0
      },
      {
        "id": "7f4e4d8b-d1b8-4db0-a4af-36fcc4176933",
        "instanceId": 13162,
        "authEntityId": 13162,
        "displayName": "13162",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "20,21,21000",
        "protocol": "TCP",
        "upperPort": 0
      }
    ],
    "parentScalableGroup": {
      "idRef": "383363b1-4dd5-49d1-bfe4-34daac437cfb"
    },
    "user": [

    ],
    "userGroup": [

    ]
  },
  {
    "id": "263c48b6-bd96-4778-8f7c-16f3b1cde42c",
    "instanceId": 4568,
    "authEntityId": 4568,
    "displayName": "4568",
    "authEntityClass": -1909073334,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "createTime": 1517966743008,
    "deployed": false,
    "isSeeded": true,
    "isStale": false,
    "lastUpdateTime": 1517966743008,
    "name": "hyperwave-isp",
    "namespace": "scalablegroup:application",
    "provisioningState": "DEFINED",
    "qualifier": "application",
    "resourceVersion": 0,
    "targetIdList": [

    ],
    "type": "scalablegroup",
    "cfsChangeInfo": [

    ],
    "customProvisions": [

    ],
    "scalableGroupExternalHandle": "hyperwave-isp",
    "scalableGroupType": "APPLICATION",
    "securityGroupTag": 0,
    "state": "ACTIVE",
    "vnAgnostic": true,
    "identitySource": {
      "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
      "instanceId": 10010,
      "authEntityId": 10010,
      "displayName": "10010",
      "authEntityClass": 1738953278,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "state": "INACTIVE",
      "type": "NBAR"
    },
    "indicativeNetworkIdentity": [
      {
        "id": "1d5f0163-76b4-4ee8-98e9-97c606e6dee4",
        "instanceId": 13417,
        "authEntityId": 13417,
        "displayName": "13417",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "692",
        "protocol": "TCP",
        "upperPort": 0
      },
      {
        "id": "4213e2d3-c8ab-408e-8b93-5d996838a09c",
        "instanceId": 13418,
        "authEntityId": 13418,
        "displayName": "13418",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "692",
        "protocol": "UDP",
        "upperPort": 0
      }
    ],
    "networkApplications": [
      {
        "id": "d3541c12-3473-4815-a067-3556bf01ea8a",
        "instanceId": 11518,
        "authEntityId": 11518,
        "displayName": "11518",
        "authEntityClass": -217092956,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "appProtocol": "tcp/udp",
        "applicationGroup": "other",
        "applicationType": "DEFAULT",
        "categoryId": "6daa3dfd-3526-407a-88f0-97824b3f3a24",
        "createTime": 1517966743008,
        "encrypted": "false",
        "engineId": "3",
        "helpString": "hyperwave-isp",
        "ignoreConflict": false,
        "lastUpdateTime": 1517966743008,
        "longDescription": "Hyperwave-ISP is part of the Hyperwave document management system, which focuses on document and knowledge management in intranet environments.",
        "name": "hyperwave-isp",
        "nbarId": "600",
        "p2pTechnology": "false",
        "popularity": 1,
        "rank": 65535,
        "references": "http://www.hyperwave.com/e/index.html",
        "selectorId": "692",
        "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
        "trafficClass": "BULK_DATA",
        "tunnel": "false"
      }
    ],
    "networkIdentity": [
      {
        "id": "d5d17c74-4b2e-42c5-a2a2-e0742750fc6c",
        "instanceId": 13415,
        "authEntityId": 13415,
        "displayName": "13415",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "692",
        "protocol": "UDP",
        "upperPort": 0
      },
      {
        "id": "46cd5f08-d9ba-46fb-8c57-e60dd53c8f96",
        "instanceId": 13416,
        "authEntityId": 13416,
        "displayName": "13416",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "692",
        "protocol": "TCP",
        "upperPort": 0
      }
    ],
    "parentScalableGroup": {
      "idRef": "000c15bf-28fe-4fbb-9b69-35e6502721f1"
    },
    "user": [

    ],
    "userGroup": [

    ]
  },
  {
    "id": "265b8c89-b708-4961-8793-6af78d2c45e5",
    "instanceId": 4284,
    "authEntityId": 4284,
    "displayName": "4284",
    "authEntityClass": -1909073334,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "createTime": 1517966742139,
    "deployed": false,
    "isSeeded": true,
    "isStale": false,
    "lastUpdateTime": 1517966742139,
    "name": "connendp",
    "namespace": "scalablegroup:application",
    "provisioningState": "DEFINED",
    "qualifier": "application",
    "resourceVersion": 0,
    "targetIdList": [

    ],
    "type": "scalablegroup",
    "cfsChangeInfo": [

    ],
    "customProvisions": [

    ],
    "scalableGroupExternalHandle": "connendp",
    "scalableGroupType": "APPLICATION",
    "securityGroupTag": 0,
    "state": "ACTIVE",
    "vnAgnostic": true,
    "identitySource": {
      "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
      "instanceId": 10010,
      "authEntityId": 10010,
      "displayName": "10010",
      "authEntityClass": 1738953278,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "state": "INACTIVE",
      "type": "NBAR"
    },
    "indicativeNetworkIdentity": [
      {
        "id": "d09ae28b-ab2d-41ca-afff-45786fcb97b3",
        "instanceId": 12625,
        "authEntityId": 12625,
        "displayName": "12625",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "693",
        "protocol": "TCP",
        "upperPort": 0
      },
      {
        "id": "bc678dea-2872-40eb-951a-efa4d6bb46e6",
        "instanceId": 12626,
        "authEntityId": 12626,
        "displayName": "12626",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "693",
        "protocol": "UDP",
        "upperPort": 0
      }
    ],
    "networkApplications": [
      {
        "id": "e684d558-8cfb-4183-8f22-e2802084121f",
        "instanceId": 11237,
        "authEntityId": 11237,
        "displayName": "11237",
        "authEntityClass": -217092956,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "appProtocol": "tcp/udp",
        "applicationGroup": "other",
        "applicationType": "DEFAULT",
        "categoryId": "6daa3dfd-3526-407a-88f0-97824b3f3a24",
        "createTime": 1517966742139,
        "encrypted": "false",
        "engineId": "3",
        "helpString": "almanid Connection Endpoint",
        "ignoreConflict": false,
        "lastUpdateTime": 1517966742139,
        "longDescription": "Almanid Connection Endpoint (connendp) is a common module that serves the Almanid IdentityProtector. IdentityProtector is a backup product for Novell Directory Services and Novell eDirectory.",
        "name": "connendp",
        "nbarId": "601",
        "p2pTechnology": "false",
        "popularity": 1,
        "rank": 65535,
        "references": "http://www.almanid.com/en/products/identityprotector/index.html",
        "selectorId": "693",
        "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
        "trafficClass": "BULK_DATA",
        "tunnel": "false"
      }
    ],
    "networkIdentity": [
      {
        "id": "ee53ff92-fd11-45cc-b5d2-c83f995a34ec",
        "instanceId": 12624,
        "authEntityId": 12624,
        "displayName": "12624",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "693",
        "protocol": "UDP",
        "upperPort": 0
      },
      {
        "id": "7a71c998-f118-4cb2-8856-d1b4cd90a133",
        "instanceId": 12623,
        "authEntityId": 12623,
        "displayName": "12623",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "693",
        "protocol": "TCP",
        "upperPort": 0
      }
    ],
    "parentScalableGroup": {
      "idRef": "000c15bf-28fe-4fbb-9b69-35e6502721f1"
    },
    "user": [

    ],
    "userGroup": [

    ]
  },
  {
    "id": "26686f5a-231f-4e31-aeb7-11d44fb47bfe",
    "instanceId": 4804,
    "authEntityId": 4804,
    "displayName": "4804",
    "authEntityClass": -1909073334,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "createTime": 1517966743526,
    "deployed": false,
    "isSeeded": true,
    "isStale": false,
    "lastUpdateTime": 1517966743526,
    "name": "msnp",
    "namespace": "scalablegroup:application",
    "provisioningState": "DEFINED",
    "qualifier": "application",
    "resourceVersion": 0,
    "targetIdList": [

    ],
    "type": "scalablegroup",
    "cfsChangeInfo": [

    ],
    "customProvisions": [

    ],
    "scalableGroupExternalHandle": "msnp",
    "scalableGroupType": "APPLICATION",
    "securityGroupTag": 0,
    "state": "ACTIVE",
    "vnAgnostic": true,
    "identitySource": {
      "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
      "instanceId": 10010,
      "authEntityId": 10010,
      "displayName": "10010",
      "authEntityClass": 1738953278,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "state": "INACTIVE",
      "type": "NBAR"
    },
    "indicativeNetworkIdentity": [
      {
        "id": "b22de787-2863-4a3d-bfdd-cf11ad1836c6",
        "instanceId": 14051,
        "authEntityId": 14051,
        "displayName": "14051",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "1863",
        "protocol": "UDP",
        "upperPort": 0
      },
      {
        "id": "f5736948-c981-4654-b2f3-92b55701e948",
        "instanceId": 14052,
        "authEntityId": 14052,
        "displayName": "14052",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "1863",
        "protocol": "TCP",
        "upperPort": 0
      }
    ],
    "networkApplications": [
      {
        "id": "90271951-5c57-4b76-853c-d27902f87f5c",
        "instanceId": 11753,
        "authEntityId": 11753,
        "displayName": "11753",
        "authEntityClass": -217092956,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "appProtocol": "tcp/udp",
        "applicationGroup": "msn-messenger-group",
        "applicationType": "DEFAULT",
        "categoryId": "0f6cab47-be95-4124-9db3-360ccd55f983",
        "createTime": 1517966743526,
        "encrypted": "false",
        "engineId": "3",
        "helpString": "msnp",
        "ignoreConflict": false,
        "lastUpdateTime": 1517966743526,
        "longDescription": "Microsoft Notification Protocol (MSNP) is an instant messaging protocol developed by Microsoft for use by the .NET Messenger Service and the instant messaging clients that connect to it, such as Windows Live Messenger, its earlier incarnations MSN Messenger and Windows Messenger, and Microsoft Messenger for Mac. Third-party clients such as Pidgin and Trillian can also communicate using the protocol.",
        "name": "msnp",
        "nbarId": "713",
        "p2pTechnology": "false",
        "popularity": 2,
        "rank": 65535,
        "references": "http://msnpiki.msnfanatic.com/",
        "selectorId": "1863",
        "subCategory": "118c10a2-e272-47c1-92b9-4814ebe97138",
        "trafficClass": "MULTIMEDIA_CONFERENCING",
        "tunnel": "false"
      }
    ],
    "networkIdentity": [
      {
        "id": "bae0c09f-5c19-4d49-b21c-7dbb6acc68e1",
        "instanceId": 14049,
        "authEntityId": 14049,
        "displayName": "14049",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "1863",
        "protocol": "UDP",
        "upperPort": 0
      },
      {
        "id": "19a0fa3f-3dfd-4b0e-9d6e-797260f41f18",
        "instanceId": 14050,
        "authEntityId": 14050,
        "displayName": "14050",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "1863",
        "protocol": "TCP",
        "upperPort": 0
      }
    ],
    "parentScalableGroup": {
      "idRef": "fd6264c9-c16a-4311-a335-a3db039256f3"
    },
    "user": [

    ],
    "userGroup": [

    ]
  },
  {
    "id": "267d7e2d-396d-4d34-8e68-49484ff6f972",
    "instanceId": 4231,
    "authEntityId": 4231,
    "displayName": "4231",
    "authEntityClass": -1909073334,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "createTime": 1517966741864,
    "deployed": false,
    "isSeeded": true,
    "isStale": false,
    "lastUpdateTime": 1517966741864,
    "name": "cisco-collab-video",
    "namespace": "scalablegroup:application",
    "provisioningState": "DEFINED",
    "qualifier": "application",
    "resourceVersion": 0,
    "targetIdList": [

    ],
    "type": "scalablegroup",
    "cfsChangeInfo": [

    ],
    "customProvisions": [

    ],
    "scalableGroupExternalHandle": "cisco-collab-video",
    "scalableGroupType": "APPLICATION",
    "securityGroupTag": 0,
    "state": "ACTIVE",
    "vnAgnostic": true,
    "identitySource": {
      "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
      "instanceId": 10010,
      "authEntityId": 10010,
      "displayName": "10010",
      "authEntityClass": 1738953278,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "state": "INACTIVE",
      "type": "NBAR"
    },
    "indicativeNetworkIdentity": [

    ],
    "networkApplications": [
      {
        "id": "2ac8221e-cbff-4c4b-b059-8c4f61f4da9b",
        "instanceId": 11186,
        "authEntityId": 11186,
        "displayName": "11186",
        "authEntityClass": -217092956,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "appProtocol": "tcp/udp",
        "applicationGroup": "webex-group",
        "applicationType": "DEFAULT",
        "categoryId": "38a8e354-e2fe-4f3c-b1c5-2bbb90fb0d99",
        "createTime": 1517966741864,
        "encrypted": "true",
        "engineId": "13",
        "helpString": "Cisco Collaboration Video by various Cisco unified communication clients.",
        "ignoreConflict": false,
        "lastUpdateTime": 1517966741864,
        "longDescription": "Cisco Collaboration Video by various Cisco unified communication clients.",
        "name": "cisco-collab-video",
        "nbarId": "1762",
        "p2pTechnology": "false",
        "popularity": 8,
        "rank": 65535,
        "references": "http://ciscospark.com",
        "selectorId": "1762",
        "subCategory": "05178016-4555-4291-ab4e-43c2ab2a931a",
        "trafficClass": "MULTIMEDIA_CONFERENCING",
        "tunnel": "false"
      }
    ],
    "networkIdentity": [
      {
        "id": "e3ecab7c-09be-4b5c-b630-d7a74aa1c33b",
        "instanceId": 12507,
        "authEntityId": 12507,
        "displayName": "12507",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "3478",
        "protocol": "TCP",
        "upperPort": 0
      },
      {
        "id": "d4333abf-d537-4211-86a4-5e0b3b51a909",
        "instanceId": 12508,
        "authEntityId": 12508,
        "displayName": "12508",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "3478",
        "protocol": "UDP",
        "upperPort": 0
      }
    ],
    "parentScalableGroup": {
      "idRef": "60f1364d-2a02-4063-8ba1-07817abe0612"
    },
    "user": [

    ],
    "userGroup": [

    ]
  },
  {
    "id": "267db06c-8259-46a0-a03e-f2b9e6aa2d9e",
    "instanceId": 4627,
    "authEntityId": 4627,
    "displayName": "4627",
    "authEntityClass": -1909073334,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "createTime": 1517966743157,
    "deployed": false,
    "isSeeded": true,
    "isStale": false,
    "lastUpdateTime": 1517966743157,
    "name": "ippc",
    "namespace": "scalablegroup:application",
    "provisioningState": "DEFINED",
    "qualifier": "application",
    "resourceVersion": 0,
    "targetIdList": [

    ],
    "type": "scalablegroup",
    "cfsChangeInfo": [

    ],
    "customProvisions": [

    ],
    "scalableGroupExternalHandle": "ippc",
    "scalableGroupType": "APPLICATION",
    "securityGroupTag": 0,
    "state": "ACTIVE",
    "vnAgnostic": true,
    "identitySource": {
      "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
      "instanceId": 10010,
      "authEntityId": 10010,
      "displayName": "10010",
      "authEntityClass": 1738953278,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "state": "INACTIVE",
      "type": "NBAR"
    },
    "indicativeNetworkIdentity": [
      {
        "id": "5dfa3b0c-9819-4ed1-b7ec-54f9ada5e2de",
        "instanceId": 13582,
        "authEntityId": 13582,
        "displayName": "13582",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipProtocolNumbers": "67",
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "protocol": "IPPC",
        "upperPort": 0
      }
    ],
    "networkApplications": [
      {
        "id": "e2058461-d50d-490b-86d7-29bd0896c7c1",
        "instanceId": 11577,
        "authEntityId": 11577,
        "displayName": "11577",
        "authEntityClass": -217092956,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "appProtocol": "ip",
        "applicationGroup": "other",
        "applicationType": "DEFAULT",
        "categoryId": "6daa3dfd-3526-407a-88f0-97824b3f3a24",
        "createTime": 1517966743157,
        "encrypted": "unassigned",
        "engineId": "1",
        "helpString": "Internet Pluribus Packet Core",
        "ignoreConflict": false,
        "lastUpdateTime": 1517966743157,
        "longDescription": "Registered with IANA as IP Protocol 67",
        "name": "ippc",
        "nbarId": "821",
        "p2pTechnology": "unassigned",
        "popularity": 1,
        "rank": 65535,
        "references": "http://www.iana.org/assignments/protocol-numbers/protocol-numbers.xml",
        "selectorId": "67",
        "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
        "trafficClass": "BULK_DATA",
        "tunnel": "unassigned"
      }
    ],
    "networkIdentity": [
      {
        "id": "021e7258-65df-4eb1-9ecd-10971d076ae3",
        "instanceId": 13581,
        "authEntityId": 13581,
        "displayName": "13581",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipProtocolNumbers": "67",
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "protocol": "IPPC",
        "upperPort": 0
      }
    ],
    "parentScalableGroup": {
      "idRef": "000c15bf-28fe-4fbb-9b69-35e6502721f1"
    },
    "user": [

    ],
    "userGroup": [

    ]
  },
  {
    "id": "26aecc37-e0ef-4239-9906-a66966560eac",
    "instanceId": 15332,
    "authEntityId": 15332,
    "displayName": "15332",
    "authEntityClass": -1909073334,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "createTime": 1517966744754,
    "deployed": false,
    "isSeeded": true,
    "isStale": false,
    "lastUpdateTime": 1517966744754,
    "name": "usps",
    "namespace": "scalablegroup:application",
    "provisioningState": "DEFINED",
    "qualifier": "application",
    "resourceVersion": 0,
    "targetIdList": [

    ],
    "type": "scalablegroup",
    "cfsChangeInfo": [

    ],
    "customProvisions": [

    ],
    "scalableGroupExternalHandle": "usps",
    "scalableGroupType": "APPLICATION",
    "securityGroupTag": 0,
    "state": "ACTIVE",
    "vnAgnostic": true,
    "identitySource": {
      "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
      "instanceId": 10010,
      "authEntityId": 10010,
      "displayName": "10010",
      "authEntityClass": 1738953278,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "state": "INACTIVE",
      "type": "NBAR"
    },
    "indicativeNetworkIdentity": [

    ],
    "networkApplications": [
      {
        "id": "d3627a95-ee10-414c-8871-2c8d27b7de6c",
        "instanceId": 16275,
        "authEntityId": 16275,
        "displayName": "16275",
        "authEntityClass": -217092956,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "appProtocol": "tcp/udp",
        "applicationGroup": "other",
        "applicationType": "DEFAULT",
        "categoryId": "878644b5-fe91-45b4-9204-31c84a73ba48",
        "createTime": 1517966744754,
        "encrypted": "false",
        "engineId": "13",
        "helpString": "USPS - US Postal Service website.",
        "ignoreConflict": false,
        "lastUpdateTime": 1517966744754,
        "longDescription": "USPS - US Postal Service website.",
        "name": "usps",
        "nbarId": "1589",
        "p2pTechnology": "false",
        "popularity": 3,
        "rank": 65535,
        "references": "http://usps.com",
        "selectorId": "660",
        "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
        "trafficClass": "TRANSACTIONAL_DATA",
        "tunnel": "false"
      }
    ],
    "networkIdentity": [
      {
        "id": "39b7e682-05a8-46ac-a1ea-856e30498a3e",
        "instanceId": 17611,
        "authEntityId": 17611,
        "displayName": "17611",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "53,80,443,5353",
        "protocol": "TCP",
        "upperPort": 0
      },
      {
        "id": "2e013143-71e1-485b-a105-1eac0c0656cd",
        "instanceId": 17612,
        "authEntityId": 17612,
        "displayName": "17612",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "53,5353",
        "protocol": "UDP",
        "upperPort": 0
      }
    ],
    "parentScalableGroup": {
      "idRef": "28e08de4-d7b2-4521-84ce-bc1e5ee0b9cc"
    },
    "user": [

    ],
    "userGroup": [

    ]
  },
  {
    "id": "26ece27e-d6b5-4a3e-9bf4-e7534190b009",
    "instanceId": 4127,
    "authEntityId": 4127,
    "displayName": "4127",
    "authEntityClass": -1909073334,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "createTime": 1517966741255,
    "deployed": false,
    "isSeeded": true,
    "isStale": false,
    "lastUpdateTime": 1517966741255,
    "name": "ask-com",
    "namespace": "scalablegroup:application",
    "provisioningState": "DEFINED",
    "qualifier": "application",
    "resourceVersion": 0,
    "targetIdList": [

    ],
    "type": "scalablegroup",
    "cfsChangeInfo": [

    ],
    "customProvisions": [

    ],
    "scalableGroupExternalHandle": "ask-com",
    "scalableGroupType": "APPLICATION",
    "securityGroupTag": 0,
    "state": "ACTIVE",
    "vnAgnostic": true,
    "identitySource": {
      "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
      "instanceId": 10010,
      "authEntityId": 10010,
      "displayName": "10010",
      "authEntityClass": 1738953278,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "state": "INACTIVE",
      "type": "NBAR"
    },
    "indicativeNetworkIdentity": [

    ],
    "networkApplications": [
      {
        "id": "d06a6662-9158-4013-b283-7f1eafea935b",
        "instanceId": 11089,
        "authEntityId": 11089,
        "displayName": "11089",
        "authEntityClass": -217092956,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "appProtocol": "tcp/udp",
        "applicationGroup": "other",
        "applicationType": "DEFAULT",
        "categoryId": "878644b5-fe91-45b4-9204-31c84a73ba48",
        "createTime": 1517966741255,
        "encrypted": "false",
        "engineId": "13",
        "helpString": "Ask.com - Search engine.",
        "ignoreConflict": false,
        "lastUpdateTime": 1517966741255,
        "longDescription": "Ask.com - Search engine.",
        "name": "ask-com",
        "nbarId": "755",
        "p2pTechnology": "false",
        "popularity": 3,
        "rank": 65535,
        "references": "http://ask.com",
        "selectorId": "607",
        "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
        "trafficClass": "TRANSACTIONAL_DATA",
        "tunnel": "false"
      }
    ],
    "networkIdentity": [
      {
        "id": "ac7edaee-68fa-4d94-89de-d040b850bd62",
        "instanceId": 12240,
        "authEntityId": 12240,
        "displayName": "12240",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "53,5353",
        "protocol": "UDP",
        "upperPort": 0
      },
      {
        "id": "34acc3d5-ede8-45c9-91dd-2b1f94694531",
        "instanceId": 12239,
        "authEntityId": 12239,
        "displayName": "12239",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "53,80,443,5353",
        "protocol": "TCP",
        "upperPort": 0
      }
    ],
    "parentScalableGroup": {
      "idRef": "28e08de4-d7b2-4521-84ce-bc1e5ee0b9cc"
    },
    "user": [

    ],
    "userGroup": [

    ]
  },
  {
    "id": "26fa60e8-85d8-4664-a72c-452b1dc41aee",
    "instanceId": 15366,
    "authEntityId": 15366,
    "displayName": "15366",
    "authEntityClass": -1909073334,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "createTime": 1517966744814,
    "deployed": false,
    "isSeeded": true,
    "isStale": false,
    "lastUpdateTime": 1517966744814,
    "name": "vnc-http",
    "namespace": "scalablegroup:application",
    "provisioningState": "DEFINED",
    "qualifier": "application",
    "resourceVersion": 0,
    "targetIdList": [

    ],
    "type": "scalablegroup",
    "cfsChangeInfo": [

    ],
    "customProvisions": [

    ],
    "scalableGroupExternalHandle": "vnc-http",
    "scalableGroupType": "APPLICATION",
    "securityGroupTag": 0,
    "state": "ACTIVE",
    "vnAgnostic": true,
    "identitySource": {
      "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
      "instanceId": 10010,
      "authEntityId": 10010,
      "displayName": "10010",
      "authEntityClass": 1738953278,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "state": "INACTIVE",
      "type": "NBAR"
    },
    "indicativeNetworkIdentity": [

    ],
    "networkApplications": [
      {
        "id": "90d4a180-80fa-4c8a-9bed-48a00cb2d9bf",
        "instanceId": 16309,
        "authEntityId": 16309,
        "displayName": "16309",
        "authEntityClass": -217092956,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "appProtocol": "tcp",
        "applicationGroup": "vnc-group",
        "applicationType": "DEFAULT",
        "categoryId": "7e19fdea-6222-4c57-9e66-66c2340d6bc3",
        "createTime": 1517966744814,
        "encrypted": "false",
        "engineId": "13",
        "helpString": "VNC-HTTP is a graphical desktop sharing system that runs over HTTP",
        "ignoreConflict": false,
        "lastUpdateTime": 1517966744814,
        "longDescription": "Virtual Network Computing (VNC) is a graphical desktop sharing system that uses the Remote FrameBuffer protocol (RFB protocol) to remotely control another computer. It transmits the keyboard and mouse events from one computer to another. VNC is platform-independent \"a VNC viewer on one operating system may connect to a VNC server on the same or any other operating system\". VNC-HTTP establishes a VNC session from a Java-capable browser .The underlying protocol is HTTP.",
        "name": "vnc-http",
        "nbarId": "1414",
        "p2pTechnology": "false",
        "popularity": 9,
        "rank": 65535,
        "references": "http://www.realvnc.com/",
        "selectorId": "485",
        "subCategory": "ff664205-64f3-447e-acff-1ec1ba9a6c31",
        "trafficClass": "MULTIMEDIA_STREAMING",
        "tunnel": "false"
      }
    ],
    "networkIdentity": [
      {
        "id": "ebaf13a4-4ef2-46a4-8a60-04e774618250",
        "instanceId": 17712,
        "authEntityId": 17712,
        "displayName": "17712",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "80",
        "protocol": "TCP",
        "upperPort": 0
      }
    ],
    "parentScalableGroup": {
      "idRef": "4a6b37c6-7e11-40fe-a6b1-719b24813145"
    },
    "user": [

    ],
    "userGroup": [

    ]
  },
  {
    "id": "271ef507-3da4-4881-8868-fa16e4a9dcef",
    "instanceId": 4849,
    "authEntityId": 4849,
    "displayName": "4849",
    "authEntityClass": -1909073334,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "createTime": 1517966743611,
    "deployed": false,
    "isSeeded": true,
    "isStale": false,
    "lastUpdateTime": 1517966743611,
    "name": "netsc-dev",
    "namespace": "scalablegroup:application",
    "provisioningState": "DEFINED",
    "qualifier": "application",
    "resourceVersion": 0,
    "targetIdList": [

    ],
    "type": "scalablegroup",
    "cfsChangeInfo": [

    ],
    "customProvisions": [

    ],
    "scalableGroupExternalHandle": "netsc-dev",
    "scalableGroupType": "APPLICATION",
    "securityGroupTag": 0,
    "state": "ACTIVE",
    "vnAgnostic": true,
    "identitySource": {
      "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
      "instanceId": 10010,
      "authEntityId": 10010,
      "displayName": "10010",
      "authEntityClass": 1738953278,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "state": "INACTIVE",
      "type": "NBAR"
    },
    "indicativeNetworkIdentity": [
      {
        "id": "56a48be2-3c3b-4387-81dc-96932061a02d",
        "instanceId": 14182,
        "authEntityId": 14182,
        "displayName": "14182",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "155",
        "protocol": "TCP",
        "upperPort": 0
      },
      {
        "id": "3ec500d5-2b21-49e4-869b-1ac9d19d0491",
        "instanceId": 14183,
        "authEntityId": 14183,
        "displayName": "14183",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "155",
        "protocol": "UDP",
        "upperPort": 0
      }
    ],
    "networkApplications": [
      {
        "id": "d8817c33-68fc-4c47-894c-491aa7c5b6c3",
        "instanceId": 11798,
        "authEntityId": 11798,
        "displayName": "11798",
        "authEntityClass": -217092956,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "appProtocol": "tcp/udp",
        "applicationGroup": "other",
        "applicationType": "DEFAULT",
        "categoryId": "6daa3dfd-3526-407a-88f0-97824b3f3a24",
        "createTime": 1517966743611,
        "encrypted": "false",
        "engineId": "3",
        "helpString": "NETSC",
        "ignoreConflict": false,
        "lastUpdateTime": 1517966743611,
        "longDescription": "Registered with IANA on port 155 TCP/UDP",
        "name": "netsc-dev",
        "nbarId": "1002",
        "p2pTechnology": "false",
        "popularity": 1,
        "rank": 65535,
        "references": "http://www.iana.org/assignments/service-names-port-numbers/service-names-port-numbers.xml",
        "selectorId": "155",
        "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
        "trafficClass": "BULK_DATA",
        "tunnel": "false"
      }
    ],
    "networkIdentity": [
      {
        "id": "35b2f69f-ab68-48e7-b333-1b133cc36b6c",
        "instanceId": 14180,
        "authEntityId": 14180,
        "displayName": "14180",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "155",
        "protocol": "TCP",
        "upperPort": 0
      },
      {
        "id": "a61f3065-154a-4dbd-ad79-876e28586b0a",
        "instanceId": 14181,
        "authEntityId": 14181,
        "displayName": "14181",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "155",
        "protocol": "UDP",
        "upperPort": 0
      }
    ],
    "parentScalableGroup": {
      "idRef": "000c15bf-28fe-4fbb-9b69-35e6502721f1"
    },
    "user": [

    ],
    "userGroup": [

    ]
  },
  {
    "id": "271fd1e0-7222-4321-bfb9-95d2c2c0c3f6",
    "instanceId": 4971,
    "authEntityId": 4971,
    "displayName": "4971",
    "authEntityClass": -1909073334,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "createTime": 1517966743941,
    "deployed": false,
    "isSeeded": true,
    "isStale": false,
    "lastUpdateTime": 1517966743941,
    "name": "pocket",
    "namespace": "scalablegroup:application",
    "provisioningState": "DEFINED",
    "qualifier": "application",
    "resourceVersion": 0,
    "targetIdList": [

    ],
    "type": "scalablegroup",
    "cfsChangeInfo": [

    ],
    "customProvisions": [

    ],
    "scalableGroupExternalHandle": "pocket",
    "scalableGroupType": "APPLICATION",
    "securityGroupTag": 0,
    "state": "ACTIVE",
    "vnAgnostic": true,
    "identitySource": {
      "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
      "instanceId": 10010,
      "authEntityId": 10010,
      "displayName": "10010",
      "authEntityClass": 1738953278,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "state": "INACTIVE",
      "type": "NBAR"
    },
    "indicativeNetworkIdentity": [

    ],
    "networkApplications": [
      {
        "id": "e9b1e7f0-f736-4e49-bf8b-75c244b34f51",
        "instanceId": 11920,
        "authEntityId": 11920,
        "displayName": "11920",
        "authEntityClass": -217092956,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "appProtocol": "tcp/udp",
        "applicationGroup": "other",
        "applicationType": "DEFAULT",
        "categoryId": "878644b5-fe91-45b4-9204-31c84a73ba48",
        "createTime": 1517966743941,
        "encrypted": "false",
        "engineId": "13",
        "helpString": "Pocket - App to save web pages.",
        "ignoreConflict": false,
        "lastUpdateTime": 1517966743941,
        "longDescription": "Pocket - App to save web pages.",
        "name": "pocket",
        "nbarId": "1731",
        "p2pTechnology": "false",
        "popularity": 3,
        "rank": 65535,
        "references": "http://getpocket.com",
        "selectorId": "803",
        "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
        "trafficClass": "TRANSACTIONAL_DATA",
        "tunnel": "false"
      }
    ],
    "networkIdentity": [
      {
        "id": "eb952793-89a0-49ce-9aba-f217832bb5dd",
        "instanceId": 14568,
        "authEntityId": 14568,
        "displayName": "14568",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "53,80,443,5353",
        "protocol": "TCP",
        "upperPort": 0
      },
      {
        "id": "8013fb53-cefa-4072-a4eb-131067f36458",
        "instanceId": 14569,
        "authEntityId": 14569,
        "displayName": "14569",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "53,5353",
        "protocol": "UDP",
        "upperPort": 0
      }
    ],
    "parentScalableGroup": {
      "idRef": "28e08de4-d7b2-4521-84ce-bc1e5ee0b9cc"
    },
    "user": [

    ],
    "userGroup": [

    ]
  },
  {
    "id": "274fbc73-bf29-4f03-93d3-16ce1929c02a",
    "instanceId": 4769,
    "authEntityId": 4769,
    "displayName": "4769",
    "authEntityClass": -1909073334,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "createTime": 1517966743456,
    "deployed": false,
    "isSeeded": true,
    "isStale": false,
    "lastUpdateTime": 1517966743456,
    "name": "mptn",
    "namespace": "scalablegroup:application",
    "provisioningState": "DEFINED",
    "qualifier": "application",
    "resourceVersion": 0,
    "targetIdList": [

    ],
    "type": "scalablegroup",
    "cfsChangeInfo": [

    ],
    "customProvisions": [

    ],
    "scalableGroupExternalHandle": "mptn",
    "scalableGroupType": "APPLICATION",
    "securityGroupTag": 0,
    "state": "ACTIVE",
    "vnAgnostic": true,
    "identitySource": {
      "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
      "instanceId": 10010,
      "authEntityId": 10010,
      "displayName": "10010",
      "authEntityClass": 1738953278,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "state": "INACTIVE",
      "type": "NBAR"
    },
    "indicativeNetworkIdentity": [
      {
        "id": "2aa5cd48-7fd8-4845-8995-103fe1480665",
        "instanceId": 13968,
        "authEntityId": 13968,
        "displayName": "13968",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "397",
        "protocol": "TCP",
        "upperPort": 0
      },
      {
        "id": "55b022f5-ff4e-4e3c-8c8f-837687c10047",
        "instanceId": 13969,
        "authEntityId": 13969,
        "displayName": "13969",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "397",
        "protocol": "UDP",
        "upperPort": 0
      }
    ],
    "networkApplications": [
      {
        "id": "ba375393-13da-4613-893e-288410b9b996",
        "instanceId": 11719,
        "authEntityId": 11719,
        "displayName": "11719",
        "authEntityClass": -217092956,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "appProtocol": "tcp/udp",
        "applicationGroup": "other",
        "applicationType": "DEFAULT",
        "categoryId": "1fc17362-9334-4c7b-b752-5afdbb574017",
        "createTime": 1517966743456,
        "encrypted": "false",
        "engineId": "3",
        "helpString": "Multi Protocol Trans. Net",
        "ignoreConflict": false,
        "lastUpdateTime": 1517966743456,
        "longDescription": "The Multiprotocol Transport Networking (MPTN) architecture is a general solution to providing interconnectivity for applications. The MPTN architecture provides a protocol-independent system interface that includes most functions provided by existing transport protocols.",
        "name": "mptn",
        "nbarId": "312",
        "p2pTechnology": "false",
        "popularity": 1,
        "rank": 65535,
        "references": "http://www.ieee-icnp.org/1993/papers/1993-2.pdf",
        "selectorId": "397",
        "subCategory": "f3c0f761-d894-4112-ab89-b90fc84fd660",
        "trafficClass": "OPS_ADMIN_MGMT",
        "tunnel": "false"
      }
    ],
    "networkIdentity": [
      {
        "id": "70d4f13f-09f9-4000-98e1-2238c246cc5e",
        "instanceId": 13966,
        "authEntityId": 13966,
        "displayName": "13966",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "397",
        "protocol": "UDP",
        "upperPort": 0
      },
      {
        "id": "251d3226-1987-489c-97e6-476a4f7ffa68",
        "instanceId": 13967,
        "authEntityId": 13967,
        "displayName": "13967",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "397",
        "protocol": "TCP",
        "upperPort": 0
      }
    ],
    "parentScalableGroup": {
      "idRef": "5fcd74d4-8726-47f7-a088-c457863da7ef"
    },
    "user": [

    ],
    "userGroup": [

    ]
  },
  {
    "id": "2760b212-afe2-46a8-b9d9-46a3963e8493",
    "instanceId": 4519,
    "authEntityId": 4519,
    "displayName": "4519",
    "authEntityClass": -1909073334,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "createTime": 1517966742872,
    "deployed": false,
    "isSeeded": true,
    "isStale": false,
    "lastUpdateTime": 1517966742872,
    "name": "gtalk-video",
    "namespace": "scalablegroup:application",
    "provisioningState": "DEFINED",
    "qualifier": "application",
    "resourceVersion": 0,
    "targetIdList": [

    ],
    "type": "scalablegroup",
    "cfsChangeInfo": [

    ],
    "customProvisions": [

    ],
    "scalableGroupExternalHandle": "gtalk-video",
    "scalableGroupType": "APPLICATION",
    "securityGroupTag": 0,
    "state": "ACTIVE",
    "vnAgnostic": true,
    "identitySource": {
      "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
      "instanceId": 10010,
      "authEntityId": 10010,
      "displayName": "10010",
      "authEntityClass": 1738953278,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "state": "INACTIVE",
      "type": "NBAR"
    },
    "indicativeNetworkIdentity": [

    ],
    "networkApplications": [
      {
        "id": "83826f50-9dc9-4c76-9577-6c641f26dda9",
        "instanceId": 11470,
        "authEntityId": 11470,
        "displayName": "11470",
        "authEntityClass": -217092956,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "appProtocol": "tcp/udp",
        "applicationGroup": "gtalk-group",
        "applicationType": "DEFAULT",
        "categoryId": "82a7b7fa-da5a-48f5-8b6a-a2546b947843",
        "createTime": 1517966742872,
        "encrypted": "true",
        "engineId": "13",
        "helpString": "Protocol integrated into hangouts-video",
        "ignoreConflict": false,
        "lastUpdateTime": 1517966742872,
        "longDescription": "Google talk-video is a feature of GTALK that allows users to make video calls via Google-talk (GTALK). The underlying protocols for GTALK-VIDEO are GTALK, STUN and HTTP. Google Talk is now being replaced by Google Hangouts",
        "name": "gtalk-video",
        "nbarId": "1403",
        "p2pTechnology": "false",
        "popularity": 10,
        "rank": 65535,
        "references": "http://en.wikipedia.org/wiki/Google_talk",
        "selectorId": "471",
        "subCategory": "118c10a2-e272-47c1-92b9-4814ebe97138",
        "trafficClass": "MULTIMEDIA_CONFERENCING",
        "tunnel": "false"
      }
    ],
    "networkIdentity": [
      {
        "id": "3ba9cb36-d1e7-4877-a1fa-8bc125a78a42",
        "instanceId": 13291,
        "authEntityId": 13291,
        "displayName": "13291",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "3478",
        "protocol": "UDP",
        "upperPort": 0
      },
      {
        "id": "76510d2d-9667-400e-aa3a-06414b9b7bbf",
        "instanceId": 13292,
        "authEntityId": 13292,
        "displayName": "13292",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "80,3478",
        "protocol": "TCP",
        "upperPort": 0
      }
    ],
    "parentScalableGroup": {
      "idRef": "fd6264c9-c16a-4311-a335-a3db039256f3"
    },
    "user": [

    ],
    "userGroup": [

    ]
  },
  {
    "id": "27cb2604-029b-4f20-a487-63e0191dca78",
    "instanceId": 4646,
    "authEntityId": 4646,
    "displayName": "4646",
    "authEntityClass": -1909073334,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "createTime": 1517966743198,
    "deployed": false,
    "isSeeded": true,
    "isStale": false,
    "lastUpdateTime": 1517966743198,
    "name": "isis",
    "namespace": "scalablegroup:application",
    "provisioningState": "DEFINED",
    "qualifier": "application",
    "resourceVersion": 0,
    "targetIdList": [

    ],
    "type": "scalablegroup",
    "cfsChangeInfo": [

    ],
    "customProvisions": [

    ],
    "scalableGroupExternalHandle": "isis",
    "scalableGroupType": "APPLICATION",
    "securityGroupTag": 0,
    "state": "ACTIVE",
    "vnAgnostic": true,
    "identitySource": {
      "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
      "instanceId": 10010,
      "authEntityId": 10010,
      "displayName": "10010",
      "authEntityClass": 1738953278,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "state": "INACTIVE",
      "type": "NBAR"
    },
    "indicativeNetworkIdentity": [
      {
        "id": "7685d3e1-f63f-41c0-ab63-2eaa38aa4964",
        "instanceId": 13622,
        "authEntityId": 13622,
        "displayName": "13622",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipProtocolNumbers": "124",
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "protocol": "ISIS",
        "upperPort": 0
      }
    ],
    "networkApplications": [
      {
        "id": "525540cf-2e4e-4049-932a-2cea32c27773",
        "instanceId": 11596,
        "authEntityId": 11596,
        "displayName": "11596",
        "authEntityClass": -217092956,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "appProtocol": "ip",
        "applicationGroup": "other",
        "applicationType": "DEFAULT",
        "categoryId": "1fc17362-9334-4c7b-b752-5afdbb574017",
        "createTime": 1517966743198,
        "encrypted": "unassigned",
        "engineId": "1",
        "helpString": "Intermediate System-to-Intermediate System (ISIS) over IPv4",
        "ignoreConflict": false,
        "lastUpdateTime": 1517966743198,
        "longDescription": "Intermediate System-to-Intermediate System (IS-IS) routing protocol is an Interior Gateway Protocol (IGP) commonly used in large Service Provider networks. IS-IS may also be deployed in extremely large Enterprise networks. IS-IS is a link-state routing protocol, intended to provide fast convergence and excellent scalability. IS-IS is known to be very efficient in its use of network bandwidth. IS-IS  is IP protocol number 124.",
        "name": "isis",
        "nbarId": "878",
        "p2pTechnology": "unassigned",
        "popularity": 5,
        "rank": 65535,
        "references": "http://tools.ietf.org/html/rfc1142",
        "selectorId": "124",
        "subCategory": "7cc1b3a6-4102-44e9-b522-0e71afe39a03",
        "trafficClass": "NETWORK_CONTROL",
        "tunnel": "unassigned"
      }
    ],
    "networkIdentity": [
      {
        "id": "d7106d56-cd3d-417c-8f39-4bcb9daab0e6",
        "instanceId": 13621,
        "authEntityId": 13621,
        "displayName": "13621",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipProtocolNumbers": "124",
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "protocol": "ISIS",
        "upperPort": 0
      }
    ],
    "parentScalableGroup": {
      "idRef": "77e75842-e98b-4112-ad0c-300db4bb11be"
    },
    "user": [

    ],
    "userGroup": [

    ]
  },
  {
    "id": "27def400-ce0a-420e-aa74-58a1c2f0ebdc",
    "instanceId": 4364,
    "authEntityId": 4364,
    "displayName": "4364",
    "authEntityClass": -1909073334,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "createTime": 1517966742411,
    "deployed": false,
    "isSeeded": true,
    "isStale": false,
    "lastUpdateTime": 1517966742411,
    "name": "directplay8",
    "namespace": "scalablegroup:application",
    "provisioningState": "DEFINED",
    "qualifier": "application",
    "resourceVersion": 0,
    "targetIdList": [

    ],
    "type": "scalablegroup",
    "cfsChangeInfo": [

    ],
    "customProvisions": [

    ],
    "scalableGroupExternalHandle": "directplay8",
    "scalableGroupType": "APPLICATION",
    "securityGroupTag": 0,
    "state": "ACTIVE",
    "vnAgnostic": true,
    "identitySource": {
      "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
      "instanceId": 10010,
      "authEntityId": 10010,
      "displayName": "10010",
      "authEntityClass": 1738953278,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "state": "INACTIVE",
      "type": "NBAR"
    },
    "indicativeNetworkIdentity": [
      {
        "id": "92495295-ef7c-4ec8-9302-1cc93eadf988",
        "instanceId": 12873,
        "authEntityId": 12873,
        "displayName": "12873",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "6073",
        "protocol": "UDP",
        "upperPort": 0
      },
      {
        "id": "0a7f0853-d4d3-4bf0-bec7-9f871c1d7be3",
        "instanceId": 12874,
        "authEntityId": 12874,
        "displayName": "12874",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "6073",
        "protocol": "TCP",
        "upperPort": 0
      }
    ],
    "networkApplications": [
      {
        "id": "cc4f9fa7-8e31-4ad1-9d67-5052b37abe1f",
        "instanceId": 11315,
        "authEntityId": 11315,
        "displayName": "11315",
        "authEntityClass": -217092956,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "appProtocol": "tcp/udp",
        "applicationGroup": "other",
        "applicationType": "DEFAULT",
        "categoryId": "3cd50dd8-1b2e-46c1-b672-41c14bfa364b",
        "createTime": 1517966742410,
        "encrypted": "false",
        "engineId": "3",
        "helpString": "DirectPlay8",
        "ignoreConflict": false,
        "lastUpdateTime": 1517966742410,
        "longDescription": "DirectPlay is part of Microsoft's DirectX API. DirectPlay is a network communication library intended for computer game development, although its general nature allows it to be used for other purposes.",
        "name": "directplay8",
        "nbarId": "717",
        "p2pTechnology": "false",
        "popularity": 2,
        "rank": 65535,
        "references": "http://en.wikipedia.org/wiki/Directplay",
        "selectorId": "6073",
        "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
        "trafficClass": "BULK_DATA",
        "tunnel": "false"
      }
    ],
    "networkIdentity": [
      {
        "id": "2a037334-488c-467c-bd1d-edf47578f790",
        "instanceId": 12871,
        "authEntityId": 12871,
        "displayName": "12871",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "6073",
        "protocol": "TCP",
        "upperPort": 0
      },
      {
        "id": "bcf23df8-7b33-4c3a-820f-f661f1149793",
        "instanceId": 12872,
        "authEntityId": 12872,
        "displayName": "12872",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "6073",
        "protocol": "UDP",
        "upperPort": 0
      }
    ],
    "parentScalableGroup": {
      "idRef": "0bd0e6c9-5083-492b-97cb-a88928bee99c"
    },
    "user": [

    ],
    "userGroup": [

    ]
  },
  {
    "id": "281a5659-39e1-4163-a88d-60c482ceffeb",
    "instanceId": 4285,
    "authEntityId": 4285,
    "displayName": "4285",
    "authEntityClass": -1909073334,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "createTime": 1517966742142,
    "deployed": false,
    "isSeeded": true,
    "isStale": false,
    "lastUpdateTime": 1517966742142,
    "name": "constant-contact-com",
    "namespace": "scalablegroup:application",
    "provisioningState": "DEFINED",
    "qualifier": "application",
    "resourceVersion": 0,
    "targetIdList": [

    ],
    "type": "scalablegroup",
    "cfsChangeInfo": [

    ],
    "customProvisions": [

    ],
    "scalableGroupExternalHandle": "constant-contact-com",
    "scalableGroupType": "APPLICATION",
    "securityGroupTag": 0,
    "state": "ACTIVE",
    "vnAgnostic": true,
    "identitySource": {
      "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
      "instanceId": 10010,
      "authEntityId": 10010,
      "displayName": "10010",
      "authEntityClass": 1738953278,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "state": "INACTIVE",
      "type": "NBAR"
    },
    "indicativeNetworkIdentity": [

    ],
    "networkApplications": [
      {
        "id": "bb28201b-69e5-473d-87e2-8923335644a1",
        "instanceId": 11238,
        "authEntityId": 11238,
        "displayName": "11238",
        "authEntityClass": -217092956,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "appProtocol": "tcp/udp",
        "applicationGroup": "other",
        "applicationType": "DEFAULT",
        "categoryId": "878644b5-fe91-45b4-9204-31c84a73ba48",
        "createTime": 1517966742142,
        "encrypted": "false",
        "engineId": "13",
        "helpString": "Constant Contact - Online marketing service.",
        "ignoreConflict": false,
        "lastUpdateTime": 1517966742142,
        "longDescription": "Constant Contact - Online marketing service.",
        "name": "constant-contact-com",
        "nbarId": "1644",
        "p2pTechnology": "false",
        "popularity": 3,
        "rank": 65535,
        "references": "http://constantcontact.com",
        "selectorId": "714",
        "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
        "trafficClass": "TRANSACTIONAL_DATA",
        "tunnel": "false"
      }
    ],
    "networkIdentity": [
      {
        "id": "d5ce2e45-b14c-4d74-808a-879e2f96f54f",
        "instanceId": 12627,
        "authEntityId": 12627,
        "displayName": "12627",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "53,80,443,5353",
        "protocol": "TCP",
        "upperPort": 0
      },
      {
        "id": "6a94f867-6d61-4e8b-bde9-7ac6e0c5c053",
        "instanceId": 12628,
        "authEntityId": 12628,
        "displayName": "12628",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "53,5353",
        "protocol": "UDP",
        "upperPort": 0
      }
    ],
    "parentScalableGroup": {
      "idRef": "28e08de4-d7b2-4521-84ce-bc1e5ee0b9cc"
    },
    "user": [

    ],
    "userGroup": [

    ]
  },
  {
    "id": "281c69b7-3903-4e2c-a8e7-03ccda020b91",
    "instanceId": 4404,
    "authEntityId": 4404,
    "displayName": "4404",
    "authEntityClass": -1909073334,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "createTime": 1517966742549,
    "deployed": false,
    "isSeeded": true,
    "isStale": false,
    "lastUpdateTime": 1517966742549,
    "name": "embl-ndt",
    "namespace": "scalablegroup:application",
    "provisioningState": "DEFINED",
    "qualifier": "application",
    "resourceVersion": 0,
    "targetIdList": [

    ],
    "type": "scalablegroup",
    "cfsChangeInfo": [

    ],
    "customProvisions": [

    ],
    "scalableGroupExternalHandle": "embl-ndt",
    "scalableGroupType": "APPLICATION",
    "securityGroupTag": 0,
    "state": "ACTIVE",
    "vnAgnostic": true,
    "identitySource": {
      "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
      "instanceId": 10010,
      "authEntityId": 10010,
      "displayName": "10010",
      "authEntityClass": 1738953278,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "state": "INACTIVE",
      "type": "NBAR"
    },
    "indicativeNetworkIdentity": [
      {
        "id": "cc839a0a-04de-4645-9baf-0c77d70c1038",
        "instanceId": 13008,
        "authEntityId": 13008,
        "displayName": "13008",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "394",
        "protocol": "TCP",
        "upperPort": 0
      },
      {
        "id": "28b6549e-bd57-45b3-9826-551cf507aa86",
        "instanceId": 13007,
        "authEntityId": 13007,
        "displayName": "13007",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "394",
        "protocol": "UDP",
        "upperPort": 0
      }
    ],
    "networkApplications": [
      {
        "id": "642cbbb1-f21b-494d-8b02-60a0cab8dddb",
        "instanceId": 11355,
        "authEntityId": 11355,
        "displayName": "11355",
        "authEntityClass": -217092956,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "appProtocol": "tcp/udp",
        "applicationGroup": "other",
        "applicationType": "DEFAULT",
        "categoryId": "6daa3dfd-3526-407a-88f0-97824b3f3a24",
        "createTime": 1517966742549,
        "encrypted": "false",
        "engineId": "3",
        "helpString": "EMBL Nucleic Data Transfer",
        "ignoreConflict": false,
        "lastUpdateTime": 1517966742549,
        "longDescription": "Registered with IANA on port 394 TCP/UDP",
        "name": "embl-ndt",
        "nbarId": "310",
        "p2pTechnology": "false",
        "popularity": 1,
        "rank": 65535,
        "references": "http://www.iana.org/assignments/service-names-port-numbers/service-names-port-numbers.xml",
        "selectorId": "394",
        "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
        "trafficClass": "BULK_DATA",
        "tunnel": "false"
      }
    ],
    "networkIdentity": [
      {
        "id": "2d85bc2c-3034-4492-a22f-817284a9fe00",
        "instanceId": 13005,
        "authEntityId": 13005,
        "displayName": "13005",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "394",
        "protocol": "TCP",
        "upperPort": 0
      },
      {
        "id": "010e2e91-d9df-45b4-ad14-756f077167d2",
        "instanceId": 13006,
        "authEntityId": 13006,
        "displayName": "13006",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "394",
        "protocol": "UDP",
        "upperPort": 0
      }
    ],
    "parentScalableGroup": {
      "idRef": "000c15bf-28fe-4fbb-9b69-35e6502721f1"
    },
    "user": [

    ],
    "userGroup": [

    ]
  },
  {
    "id": "28485f3f-ff5c-41b4-baf9-7c71e29d517d",
    "instanceId": 4640,
    "authEntityId": 4640,
    "displayName": "4640",
    "authEntityClass": -1909073334,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "createTime": 1517966743185,
    "deployed": false,
    "isSeeded": true,
    "isStale": false,
    "lastUpdateTime": 1517966743185,
    "name": "is99s",
    "namespace": "scalablegroup:application",
    "provisioningState": "DEFINED",
    "qualifier": "application",
    "resourceVersion": 0,
    "targetIdList": [

    ],
    "type": "scalablegroup",
    "cfsChangeInfo": [

    ],
    "customProvisions": [

    ],
    "scalableGroupExternalHandle": "is99s",
    "scalableGroupType": "APPLICATION",
    "securityGroupTag": 0,
    "state": "ACTIVE",
    "vnAgnostic": true,
    "identitySource": {
      "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
      "instanceId": 10010,
      "authEntityId": 10010,
      "displayName": "10010",
      "authEntityClass": 1738953278,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "state": "INACTIVE",
      "type": "NBAR"
    },
    "indicativeNetworkIdentity": [
      {
        "id": "f7cb3e10-0689-42f4-824a-9b5e6d9292bb",
        "instanceId": 13605,
        "authEntityId": 13605,
        "displayName": "13605",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "380",
        "protocol": "UDP",
        "upperPort": 0
      },
      {
        "id": "83f13866-7472-47b3-a84e-bbdbf84190d6",
        "instanceId": 13606,
        "authEntityId": 13606,
        "displayName": "13606",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "380",
        "protocol": "TCP",
        "upperPort": 0
      }
    ],
    "networkApplications": [
      {
        "id": "1a0643aa-3e6f-4c46-96ad-dd63e1b93e55",
        "instanceId": 11590,
        "authEntityId": 11590,
        "displayName": "11590",
        "authEntityClass": -217092956,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "appProtocol": "tcp/udp",
        "applicationGroup": "other",
        "applicationType": "DEFAULT",
        "categoryId": "6daa3dfd-3526-407a-88f0-97824b3f3a24",
        "createTime": 1517966743185,
        "encrypted": "false",
        "engineId": "3",
        "helpString": "TIA/EIA/IS-99 modem server",
        "ignoreConflict": false,
        "lastUpdateTime": 1517966743185,
        "longDescription": "TIA/EIA/IS-99 modem server (IS99C) is a data services option standard for wideband spread spectrum digital cellular systems.",
        "name": "is99s",
        "nbarId": "296",
        "p2pTechnology": "false",
        "popularity": 1,
        "rank": 65535,
        "references": "http://www.tiaonline.org/standards/technology/cdma2000/documents/TIA-EIA-IS-707-A.pdf",
        "selectorId": "380",
        "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
        "trafficClass": "BULK_DATA",
        "tunnel": "false"
      }
    ],
    "networkIdentity": [
      {
        "id": "6fe04b37-58c3-49d7-9526-9b4189909172",
        "instanceId": 13603,
        "authEntityId": 13603,
        "displayName": "13603",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "380",
        "protocol": "TCP",
        "upperPort": 0
      },
      {
        "id": "ce65082c-6f21-4fed-a4b5-56560fd6e5ad",
        "instanceId": 13604,
        "authEntityId": 13604,
        "displayName": "13604",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "380",
        "protocol": "UDP",
        "upperPort": 0
      }
    ],
    "parentScalableGroup": {
      "idRef": "000c15bf-28fe-4fbb-9b69-35e6502721f1"
    },
    "user": [

    ],
    "userGroup": [

    ]
  },
  {
    "id": "28ad51b1-4c46-4503-9720-9c4f86add826",
    "instanceId": 4386,
    "authEntityId": 4386,
    "displayName": "4386",
    "authEntityClass": -1909073334,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "createTime": 1517966742489,
    "deployed": false,
    "isSeeded": true,
    "isStale": false,
    "lastUpdateTime": 1517966742489,
    "name": "dropbox",
    "namespace": "scalablegroup:application",
    "provisioningState": "DEFINED",
    "qualifier": "application",
    "resourceVersion": 0,
    "targetIdList": [

    ],
    "type": "scalablegroup",
    "cfsChangeInfo": [

    ],
    "customProvisions": [

    ],
    "scalableGroupExternalHandle": "dropbox",
    "scalableGroupType": "APPLICATION",
    "securityGroupTag": 0,
    "state": "ACTIVE",
    "vnAgnostic": true,
    "identitySource": {
      "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
      "instanceId": 10010,
      "authEntityId": 10010,
      "displayName": "10010",
      "authEntityClass": 1738953278,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "state": "INACTIVE",
      "type": "NBAR"
    },
    "indicativeNetworkIdentity": [

    ],
    "networkApplications": [
      {
        "id": "9be45541-9c9f-43f0-b543-56903a30fe82",
        "instanceId": 11337,
        "authEntityId": 11337,
        "displayName": "11337",
        "authEntityClass": -217092956,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "appProtocol": "tcp/udp",
        "applicationGroup": "other",
        "applicationType": "DEFAULT",
        "categoryId": "f6ec0221-b82d-43e8-a64d-163cbc13263b",
        "createTime": 1517966742489,
        "encrypted": "true",
        "engineId": "3",
        "helpString": "Dropbox, file hosting service",
        "ignoreConflict": false,
        "lastUpdateTime": 1517966742489,
        "longDescription": "Dropbox is a free cloud storage service that lets users synchronize and share files and folders. It can be installed and viewed through multiple desktop and mobile platforms.",
        "name": "dropbox",
        "nbarId": "1485",
        "p2pTechnology": "false",
        "popularity": 10,
        "rank": 65535,
        "references": "http://www.dropbox.com/",
        "selectorId": "17500",
        "subCategory": "9fecfe24-2ee3-4b19-bbf7-3c992d1d25b4",
        "trafficClass": "BULK_DATA",
        "tunnel": "false"
      }
    ],
    "networkIdentity": [
      {
        "id": "ac5d0a2a-8793-4bbe-ad21-6f2f49ad5bf4",
        "instanceId": 12952,
        "authEntityId": 12952,
        "displayName": "12952",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "53,5353",
        "protocol": "UDP",
        "upperPort": 0
      },
      {
        "id": "1db78b9b-1b84-4093-a5d5-82c6b169b0bf",
        "instanceId": 12953,
        "authEntityId": 12953,
        "displayName": "12953",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "53,80,443,5353",
        "protocol": "TCP",
        "upperPort": 0
      }
    ],
    "parentScalableGroup": {
      "idRef": "7a1a3bbb-dc84-4a35-82a4-7e650c4c4761"
    },
    "user": [

    ],
    "userGroup": [

    ]
  },
  {
    "id": "28c5daf8-e7a3-4c16-a671-fe344507a31a",
    "instanceId": 15314,
    "authEntityId": 15314,
    "displayName": "15314",
    "authEntityClass": -1909073334,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "createTime": 1517966744722,
    "deployed": false,
    "isSeeded": true,
    "isStale": false,
    "lastUpdateTime": 1517966744722,
    "name": "uaac",
    "namespace": "scalablegroup:application",
    "provisioningState": "DEFINED",
    "qualifier": "application",
    "resourceVersion": 0,
    "targetIdList": [

    ],
    "type": "scalablegroup",
    "cfsChangeInfo": [

    ],
    "customProvisions": [

    ],
    "scalableGroupExternalHandle": "uaac",
    "scalableGroupType": "APPLICATION",
    "securityGroupTag": 0,
    "state": "ACTIVE",
    "vnAgnostic": true,
    "identitySource": {
      "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
      "instanceId": 10010,
      "authEntityId": 10010,
      "displayName": "10010",
      "authEntityClass": 1738953278,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "state": "INACTIVE",
      "type": "NBAR"
    },
    "indicativeNetworkIdentity": [
      {
        "id": "4d1db656-dfe2-46b8-a9a0-9816882ec44a",
        "instanceId": 17559,
        "authEntityId": 17559,
        "displayName": "17559",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "145",
        "protocol": "UDP",
        "upperPort": 0
      },
      {
        "id": "2cc3ae81-3a09-408a-8dd2-ffa48e163e16",
        "instanceId": 17560,
        "authEntityId": 17560,
        "displayName": "17560",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "145",
        "protocol": "TCP",
        "upperPort": 0
      }
    ],
    "networkApplications": [
      {
        "id": "c4f3086b-3886-4c40-9acd-76bb0c9532af",
        "instanceId": 16257,
        "authEntityId": 16257,
        "displayName": "16257",
        "authEntityClass": -217092956,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "appProtocol": "tcp/udp",
        "applicationGroup": "other",
        "applicationType": "DEFAULT",
        "categoryId": "6daa3dfd-3526-407a-88f0-97824b3f3a24",
        "createTime": 1517966744722,
        "encrypted": "false",
        "engineId": "3",
        "helpString": "UAAC Protocol",
        "ignoreConflict": false,
        "lastUpdateTime": 1517966744722,
        "longDescription": "Registered with IANA on port 145 TCP/UDP",
        "name": "uaac",
        "nbarId": "946",
        "p2pTechnology": "false",
        "popularity": 1,
        "rank": 65535,
        "references": "http://www.iana.org/assignments/service-names-port-numbers/service-names-port-numbers.xml",
        "selectorId": "145",
        "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
        "trafficClass": "BULK_DATA",
        "tunnel": "false"
      }
    ],
    "networkIdentity": [
      {
        "id": "864188c0-21a8-46f2-ac92-0cde6a26120d",
        "instanceId": 17557,
        "authEntityId": 17557,
        "displayName": "17557",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "145",
        "protocol": "UDP",
        "upperPort": 0
      },
      {
        "id": "a164799a-4c98-4f01-bf9e-b8a0b5e3090c",
        "instanceId": 17558,
        "authEntityId": 17558,
        "displayName": "17558",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "145",
        "protocol": "TCP",
        "upperPort": 0
      }
    ],
    "parentScalableGroup": {
      "idRef": "000c15bf-28fe-4fbb-9b69-35e6502721f1"
    },
    "user": [

    ],
    "userGroup": [

    ]
  },
  {
    "id": "28c9070e-4e89-4a73-80b2-1dae2d4ff72d",
    "instanceId": 4296,
    "authEntityId": 4296,
    "displayName": "4296",
    "authEntityClass": -1909073334,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "createTime": 1517966742188,
    "deployed": false,
    "isSeeded": true,
    "isStale": false,
    "lastUpdateTime": 1517966742188,
    "name": "cphb",
    "namespace": "scalablegroup:application",
    "provisioningState": "DEFINED",
    "qualifier": "application",
    "resourceVersion": 0,
    "targetIdList": [

    ],
    "type": "scalablegroup",
    "cfsChangeInfo": [

    ],
    "customProvisions": [

    ],
    "scalableGroupExternalHandle": "cphb",
    "scalableGroupType": "APPLICATION",
    "securityGroupTag": 0,
    "state": "ACTIVE",
    "vnAgnostic": true,
    "identitySource": {
      "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
      "instanceId": 10010,
      "authEntityId": 10010,
      "displayName": "10010",
      "authEntityClass": 1738953278,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "state": "INACTIVE",
      "type": "NBAR"
    },
    "indicativeNetworkIdentity": [
      {
        "id": "e9ec2b55-ecf0-4516-8c93-d7d6cf7d0b66",
        "instanceId": 12662,
        "authEntityId": 12662,
        "displayName": "12662",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipProtocolNumbers": "73",
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "protocol": "CPHB",
        "upperPort": 0
      }
    ],
    "networkApplications": [
      {
        "id": "80911789-3ef4-4363-9050-2eb53541ee09",
        "instanceId": 11249,
        "authEntityId": 11249,
        "displayName": "11249",
        "authEntityClass": -217092956,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "appProtocol": "ip",
        "applicationGroup": "other",
        "applicationType": "DEFAULT",
        "categoryId": "6daa3dfd-3526-407a-88f0-97824b3f3a24",
        "createTime": 1517966742188,
        "encrypted": "unassigned",
        "engineId": "1",
        "helpString": "Computer Protocol Heart Beat",
        "ignoreConflict": false,
        "lastUpdateTime": 1517966742188,
        "longDescription": "Registered with IANA as IP Protocol 73",
        "name": "cphb",
        "nbarId": "827",
        "p2pTechnology": "unassigned",
        "popularity": 1,
        "rank": 65535,
        "references": "http://www.iana.org/assignments/protocol-numbers/protocol-numbers.xml",
        "selectorId": "73",
        "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
        "trafficClass": "BULK_DATA",
        "tunnel": "unassigned"
      }
    ],
    "networkIdentity": [
      {
        "id": "83e11f31-b9ad-4694-b1f7-32bdba1e7bb2",
        "instanceId": 12661,
        "authEntityId": 12661,
        "displayName": "12661",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipProtocolNumbers": "73",
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "protocol": "CPHB",
        "upperPort": 0
      }
    ],
    "parentScalableGroup": {
      "idRef": "000c15bf-28fe-4fbb-9b69-35e6502721f1"
    },
    "user": [

    ],
    "userGroup": [

    ]
  },
  {
    "id": "28ee29cd-d4b6-4228-ab20-ed081940a432",
    "instanceId": 15337,
    "authEntityId": 15337,
    "displayName": "15337",
    "authEntityClass": -1909073334,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "createTime": 1517966744763,
    "deployed": false,
    "isSeeded": true,
    "isStale": false,
    "lastUpdateTime": 1517966744763,
    "name": "uucp",
    "namespace": "scalablegroup:application",
    "provisioningState": "DEFINED",
    "qualifier": "application",
    "resourceVersion": 0,
    "targetIdList": [

    ],
    "type": "scalablegroup",
    "cfsChangeInfo": [

    ],
    "customProvisions": [

    ],
    "scalableGroupExternalHandle": "uucp",
    "scalableGroupType": "APPLICATION",
    "securityGroupTag": 0,
    "state": "ACTIVE",
    "vnAgnostic": true,
    "identitySource": {
      "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
      "instanceId": 10010,
      "authEntityId": 10010,
      "displayName": "10010",
      "authEntityClass": 1738953278,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "state": "INACTIVE",
      "type": "NBAR"
    },
    "indicativeNetworkIdentity": [
      {
        "id": "99f80b80-0266-4049-8113-47d0b1ca2d0b",
        "instanceId": 17629,
        "authEntityId": 17629,
        "displayName": "17629",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "540",
        "protocol": "UDP",
        "upperPort": 0
      },
      {
        "id": "ddf47602-d474-4a87-8f0a-83d1c81e5e90",
        "instanceId": 17630,
        "authEntityId": 17630,
        "displayName": "17630",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "540",
        "protocol": "TCP",
        "upperPort": 0
      }
    ],
    "networkApplications": [
      {
        "id": "ffb1d336-0104-4345-b388-f2f9993d7266",
        "instanceId": 16280,
        "authEntityId": 16280,
        "displayName": "16280",
        "authEntityClass": -217092956,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "appProtocol": "tcp/udp",
        "applicationGroup": "other",
        "applicationType": "DEFAULT",
        "categoryId": "1fc17362-9334-4c7b-b752-5afdbb574017",
        "createTime": 1517966744763,
        "encrypted": "false",
        "engineId": "3",
        "helpString": "uucpd",
        "ignoreConflict": false,
        "lastUpdateTime": 1517966744763,
        "longDescription": "Unix-to-Unix Copy (UUCP) is a suite of computer programs and protocols allowing remote execution of commands and transfer of files, email and netnews between computers. Specifically, a command named uucp is one of the programs in the suite; it provides a user interface for requesting file copy operations.",
        "name": "uucp",
        "nbarId": "458",
        "p2pTechnology": "false",
        "popularity": 1,
        "rank": 65535,
        "references": "http://en.wikipedia.org/wiki/Uucp",
        "selectorId": "540",
        "subCategory": "787a7d77-977b-4974-89d3-379ba49e88b7",
        "trafficClass": "OPS_ADMIN_MGMT",
        "tunnel": "false"
      }
    ],
    "networkIdentity": [
      {
        "id": "7517bce7-2706-4b06-9eac-4492d6b99fb1",
        "instanceId": 17627,
        "authEntityId": 17627,
        "displayName": "17627",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "540",
        "protocol": "TCP",
        "upperPort": 0
      },
      {
        "id": "bf3f6b6d-ccb5-4136-9528-823dad5a7ef1",
        "instanceId": 17628,
        "authEntityId": 17628,
        "displayName": "17628",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "540",
        "protocol": "UDP",
        "upperPort": 0
      }
    ],
    "parentScalableGroup": {
      "idRef": "e06dd89f-0a70-4422-ab0b-c2314650a9a7"
    },
    "user": [

    ],
    "userGroup": [

    ]
  },
  {
    "id": "292fc58b-fe91-48a4-9300-6fe5d147385a",
    "instanceId": 4856,
    "authEntityId": 4856,
    "displayName": "4856",
    "authEntityClass": -1909073334,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "createTime": 1517966743624,
    "deployed": false,
    "isSeeded": true,
    "isStale": false,
    "lastUpdateTime": 1517966743624,
    "name": "netwall",
    "namespace": "scalablegroup:application",
    "provisioningState": "DEFINED",
    "qualifier": "application",
    "resourceVersion": 0,
    "targetIdList": [

    ],
    "type": "scalablegroup",
    "cfsChangeInfo": [

    ],
    "customProvisions": [

    ],
    "scalableGroupExternalHandle": "netwall",
    "scalableGroupType": "APPLICATION",
    "securityGroupTag": 0,
    "state": "ACTIVE",
    "vnAgnostic": true,
    "identitySource": {
      "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
      "instanceId": 10010,
      "authEntityId": 10010,
      "displayName": "10010",
      "authEntityClass": 1738953278,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "state": "INACTIVE",
      "type": "NBAR"
    },
    "indicativeNetworkIdentity": [
      {
        "id": "4d2a4cac-95b2-4d8c-aafe-4595042aa327",
        "instanceId": 14206,
        "authEntityId": 14206,
        "displayName": "14206",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "533",
        "protocol": "UDP",
        "upperPort": 0
      },
      {
        "id": "a1b6d73c-a3c7-4a8f-96e3-c818b68774fa",
        "instanceId": 14207,
        "authEntityId": 14207,
        "displayName": "14207",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "533",
        "protocol": "TCP",
        "upperPort": 0
      }
    ],
    "networkApplications": [
      {
        "id": "14f3e175-ea2a-439d-b9bb-cd75df7dfa7f",
        "instanceId": 11805,
        "authEntityId": 11805,
        "displayName": "11805",
        "authEntityClass": -217092956,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "appProtocol": "tcp/udp",
        "applicationGroup": "other",
        "applicationType": "DEFAULT",
        "categoryId": "6daa3dfd-3526-407a-88f0-97824b3f3a24",
        "createTime": 1517966743624,
        "encrypted": "false",
        "engineId": "3",
        "helpString": "for emergency broadcasts",
        "ignoreConflict": false,
        "lastUpdateTime": 1517966743624,
        "longDescription": "Registered with IANA on port 533 TCP/UDP",
        "name": "netwall",
        "nbarId": "451",
        "p2pTechnology": "false",
        "popularity": 1,
        "rank": 65535,
        "references": "http://www.iana.org/assignments/service-names-port-numbers/service-names-port-numbers.xml",
        "selectorId": "533",
        "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
        "trafficClass": "BULK_DATA",
        "tunnel": "false"
      }
    ],
    "networkIdentity": [
      {
        "id": "4753696b-f4e6-490b-90b9-645da7522703",
        "instanceId": 14204,
        "authEntityId": 14204,
        "displayName": "14204",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "533",
        "protocol": "TCP",
        "upperPort": 0
      },
      {
        "id": "95dfdf2b-492c-4054-9cd9-eddec8fbfee4",
        "instanceId": 14205,
        "authEntityId": 14205,
        "displayName": "14205",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "533",
        "protocol": "UDP",
        "upperPort": 0
      }
    ],
    "parentScalableGroup": {
      "idRef": "000c15bf-28fe-4fbb-9b69-35e6502721f1"
    },
    "user": [

    ],
    "userGroup": [

    ]
  },
  {
    "id": "294cd2fa-a6a2-41bb-b96c-3a514c9fcef3",
    "instanceId": 4383,
    "authEntityId": 4383,
    "displayName": "4383",
    "authEntityClass": -1909073334,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "createTime": 1517966742480,
    "deployed": false,
    "isSeeded": true,
    "isStale": false,
    "lastUpdateTime": 1517966742480,
    "name": "doom",
    "namespace": "scalablegroup:application",
    "provisioningState": "DEFINED",
    "qualifier": "application",
    "resourceVersion": 0,
    "targetIdList": [

    ],
    "type": "scalablegroup",
    "cfsChangeInfo": [

    ],
    "customProvisions": [

    ],
    "scalableGroupExternalHandle": "doom",
    "scalableGroupType": "APPLICATION",
    "securityGroupTag": 0,
    "state": "ACTIVE",
    "vnAgnostic": true,
    "identitySource": {
      "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
      "instanceId": 10010,
      "authEntityId": 10010,
      "displayName": "10010",
      "authEntityClass": 1738953278,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "state": "INACTIVE",
      "type": "NBAR"
    },
    "indicativeNetworkIdentity": [
      {
        "id": "d95c306e-8878-4113-9e26-87d268f55349",
        "instanceId": 12944,
        "authEntityId": 12944,
        "displayName": "12944",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "666",
        "protocol": "TCP",
        "upperPort": 0
      },
      {
        "id": "d4efcc05-1fff-4f83-88f4-9201801e98a2",
        "instanceId": 12945,
        "authEntityId": 12945,
        "displayName": "12945",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "666",
        "protocol": "UDP",
        "upperPort": 0
      }
    ],
    "networkApplications": [
      {
        "id": "b23978ee-b8bf-4bea-8633-f059219fe71a",
        "instanceId": 11334,
        "authEntityId": 11334,
        "displayName": "11334",
        "authEntityClass": -217092956,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "appProtocol": "tcp/udp",
        "applicationGroup": "other",
        "applicationType": "DEFAULT",
        "categoryId": "3cd50dd8-1b2e-46c1-b672-41c14bfa364b",
        "createTime": 1517966742480,
        "encrypted": "false",
        "engineId": "3",
        "helpString": "doom Id Software",
        "ignoreConflict": false,
        "lastUpdateTime": 1517966742480,
        "longDescription": "A first person shooter game with multiplayer support developed by Id Software",
        "name": "doom",
        "nbarId": "99",
        "p2pTechnology": "false",
        "popularity": 6,
        "rank": 65535,
        "references": "http://www.idsoftware.com/games/doom/",
        "selectorId": "666",
        "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
        "trafficClass": "MULTIMEDIA_CONFERENCING",
        "tunnel": "false"
      }
    ],
    "networkIdentity": [
      {
        "id": "ccf6771b-d2a3-4587-895c-73385f071939",
        "instanceId": 12942,
        "authEntityId": 12942,
        "displayName": "12942",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "666",
        "protocol": "UDP",
        "upperPort": 0
      },
      {
        "id": "06223dc1-56b3-4e55-9e18-32a2c9faf6e8",
        "instanceId": 12943,
        "authEntityId": 12943,
        "displayName": "12943",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "666",
        "protocol": "TCP",
        "upperPort": 0
      }
    ],
    "parentScalableGroup": {
      "idRef": "0bd0e6c9-5083-492b-97cb-a88928bee99c"
    },
    "user": [

    ],
    "userGroup": [

    ]
  },
  {
    "id": "29563494-cb59-4ea7-a777-c37b5990b2e1",
    "instanceId": 4773,
    "authEntityId": 4773,
    "displayName": "4773",
    "authEntityClass": -1909073334,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "createTime": 1517966743464,
    "deployed": false,
    "isSeeded": true,
    "isStale": false,
    "lastUpdateTime": 1517966743464,
    "name": "ms-iis",
    "namespace": "scalablegroup:application",
    "provisioningState": "DEFINED",
    "qualifier": "application",
    "resourceVersion": 0,
    "targetIdList": [

    ],
    "type": "scalablegroup",
    "cfsChangeInfo": [

    ],
    "customProvisions": [

    ],
    "scalableGroupExternalHandle": "ms-iis",
    "scalableGroupType": "APPLICATION",
    "securityGroupTag": 0,
    "state": "ACTIVE",
    "vnAgnostic": true,
    "identitySource": {
      "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
      "instanceId": 10010,
      "authEntityId": 10010,
      "displayName": "10010",
      "authEntityClass": 1738953278,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "state": "INACTIVE",
      "type": "NBAR"
    },
    "indicativeNetworkIdentity": [

    ],
    "networkApplications": [
      {
        "id": "e1908c48-4853-4806-8df9-17502c38cd30",
        "instanceId": 11722,
        "authEntityId": 11722,
        "displayName": "11722",
        "authEntityClass": -217092956,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "appProtocol": "tcp",
        "applicationGroup": "other",
        "applicationType": "DEFAULT",
        "categoryId": "58aa61dc-dd95-4a6d-9c7c-0ebf16da6dde",
        "createTime": 1517966743464,
        "encrypted": "false",
        "engineId": "13",
        "helpString": "MS-RPC Internet Information Services (IIS)",
        "ignoreConflict": false,
        "lastUpdateTime": 1517966743464,
        "longDescription": "Microsoft Internet Information Services (IIS) is a web server application and set of feature extension modules created by Microsoft for use with Microsoft Windows. The underlying protocol is Microsoft Remote Procedure Call (MS RPC).",
        "name": "ms-iis",
        "nbarId": "1411",
        "p2pTechnology": "false",
        "popularity": 9,
        "rank": 65535,
        "references": "http://www.iis.net/",
        "selectorId": "482",
        "subCategory": "b90786f4-0190-45b8-9924-21b3e9682590",
        "trafficClass": "TRANSACTIONAL_DATA",
        "tunnel": "false"
      }
    ],
    "networkIdentity": [
      {
        "id": "9c2d90f1-e44a-47dc-a549-6a0c0b984396",
        "instanceId": 13976,
        "authEntityId": 13976,
        "displayName": "13976",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "135",
        "protocol": "TCP",
        "upperPort": 0
      }
    ],
    "parentScalableGroup": {
      "idRef": "cff11752-fafb-4e22-9e6f-11f44f59a9a3"
    },
    "user": [

    ],
    "userGroup": [

    ]
  },
  {
    "id": "29a74099-0ac6-4553-92db-9f0088d4239a",
    "instanceId": 15029,
    "authEntityId": 15029,
    "displayName": "15029",
    "authEntityClass": -1909073334,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "createTime": 1517966744064,
    "deployed": false,
    "isSeeded": true,
    "isStale": false,
    "lastUpdateTime": 1517966744064,
    "name": "rcp",
    "namespace": "scalablegroup:application",
    "provisioningState": "DEFINED",
    "qualifier": "application",
    "resourceVersion": 0,
    "targetIdList": [

    ],
    "type": "scalablegroup",
    "cfsChangeInfo": [

    ],
    "customProvisions": [

    ],
    "scalableGroupExternalHandle": "rcp",
    "scalableGroupType": "APPLICATION",
    "securityGroupTag": 0,
    "state": "ACTIVE",
    "vnAgnostic": true,
    "identitySource": {
      "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
      "instanceId": 10010,
      "authEntityId": 10010,
      "displayName": "10010",
      "authEntityClass": 1738953278,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "state": "INACTIVE",
      "type": "NBAR"
    },
    "indicativeNetworkIdentity": [
      {
        "id": "2ad32d54-f806-4764-be0a-abeac2db2004",
        "instanceId": 14715,
        "authEntityId": 14715,
        "displayName": "14715",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "469",
        "protocol": "TCP",
        "upperPort": 0
      },
      {
        "id": "180e2c32-e419-475d-9122-bb9d5e945132",
        "instanceId": 14716,
        "authEntityId": 14716,
        "displayName": "14716",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "469",
        "protocol": "UDP",
        "upperPort": 0
      }
    ],
    "networkApplications": [
      {
        "id": "914c871d-f489-4e0f-abf9-9e60fa1c1b2b",
        "instanceId": 11968,
        "authEntityId": 11968,
        "displayName": "11968",
        "authEntityClass": -217092956,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "appProtocol": "tcp/udp",
        "applicationGroup": "other",
        "applicationType": "DEFAULT",
        "categoryId": "e6619ce8-97de-4c63-a53a-cceddbddc1a8",
        "createTime": 1517966744064,
        "encrypted": "false",
        "engineId": "3",
        "helpString": "Radio Control Protocol",
        "ignoreConflict": false,
        "lastUpdateTime": 1517966744064,
        "longDescription": "Registered with IANA on port 469 TCP/UDP",
        "name": "rcp",
        "nbarId": "93",
        "p2pTechnology": "false",
        "popularity": 1,
        "rank": 65535,
        "references": "http://www.iana.org/assignments/service-names-port-numbers/service-names-port-numbers.xml",
        "selectorId": "469",
        "subCategory": "50b55e2e-d0d0-4404-bea4-819351565d90",
        "trafficClass": "BULK_DATA",
        "tunnel": "false"
      }
    ],
    "networkIdentity": [
      {
        "id": "664c67bd-aef1-4755-be72-911dd9d5af1a",
        "instanceId": 14713,
        "authEntityId": 14713,
        "displayName": "14713",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "469",
        "protocol": "TCP",
        "upperPort": 0
      },
      {
        "id": "8f3fd76a-3c3b-4159-8da4-3f974d2e9a4a",
        "instanceId": 14714,
        "authEntityId": 14714,
        "displayName": "14714",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "469",
        "protocol": "UDP",
        "upperPort": 0
      }
    ],
    "parentScalableGroup": {
      "idRef": "383363b1-4dd5-49d1-bfe4-34daac437cfb"
    },
    "user": [

    ],
    "userGroup": [

    ]
  },
  {
    "id": "29da42fe-c8c3-4f69-87ad-da56dd63c5ee",
    "instanceId": 15112,
    "authEntityId": 15112,
    "displayName": "15112",
    "authEntityClass": -1909073334,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "createTime": 1517966744261,
    "deployed": false,
    "isSeeded": true,
    "isStale": false,
    "lastUpdateTime": 1517966744261,
    "name": "secure-imap",
    "namespace": "scalablegroup:application",
    "provisioningState": "DEFINED",
    "qualifier": "application",
    "resourceVersion": 0,
    "targetIdList": [

    ],
    "type": "scalablegroup",
    "cfsChangeInfo": [

    ],
    "customProvisions": [

    ],
    "scalableGroupExternalHandle": "secure-imap",
    "scalableGroupType": "APPLICATION",
    "securityGroupTag": 0,
    "state": "ACTIVE",
    "vnAgnostic": true,
    "identitySource": {
      "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
      "instanceId": 10010,
      "authEntityId": 10010,
      "displayName": "10010",
      "authEntityClass": 1738953278,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "state": "INACTIVE",
      "type": "NBAR"
    },
    "indicativeNetworkIdentity": [
      {
        "id": "af077ab3-c90c-45dc-8743-581e47a2f4cf",
        "instanceId": 14970,
        "authEntityId": 14970,
        "displayName": "14970",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "993",
        "protocol": "UDP",
        "upperPort": 0
      },
      {
        "id": "535b7826-fa56-4cae-a485-b2860f708424",
        "instanceId": 14971,
        "authEntityId": 14971,
        "displayName": "14971",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "993",
        "protocol": "TCP",
        "upperPort": 0
      }
    ],
    "networkApplications": [
      {
        "id": "eb9b124c-f8a3-4b9e-ad17-50c1548b860e",
        "instanceId": 16055,
        "authEntityId": 16055,
        "displayName": "16055",
        "authEntityClass": -217092956,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "appProtocol": "tcp/udp",
        "applicationGroup": "imap-group",
        "applicationType": "DEFAULT",
        "categoryId": "27953c60-b2ff-4e1a-bc52-0187d8295250",
        "createTime": 1517966744261,
        "encrypted": "true",
        "engineId": "3",
        "helpString": "Internet Message Access Protocol over TLS/SSL",
        "ignoreConflict": false,
        "lastUpdateTime": 1517966744261,
        "longDescription": "Internet Message Access Protocol (IMAP)over TLS/SSL allows users to securely access their email servers and to receive and send emails. The protocol simulates local use when in fact it is a connection to a server.",
        "name": "secure-imap",
        "nbarId": "18",
        "p2pTechnology": "false",
        "popularity": 8,
        "rank": 65535,
        "references": "http://tools.ietf.org/html/rfc2595",
        "selectorId": "993",
        "subCategory": "b90786f4-0190-45b8-9924-21b3e9682590",
        "trafficClass": "BULK_DATA",
        "tunnel": "false"
      }
    ],
    "networkIdentity": [
      {
        "id": "187fcbb4-5135-47a3-9756-0f5f67450217",
        "instanceId": 14968,
        "authEntityId": 14968,
        "displayName": "14968",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "993",
        "protocol": "UDP",
        "upperPort": 0
      },
      {
        "id": "0ba4bfef-8576-43fb-8f37-d4fe2a276279",
        "instanceId": 14969,
        "authEntityId": 14969,
        "displayName": "14969",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "80,993",
        "protocol": "TCP",
        "upperPort": 0
      }
    ],
    "parentScalableGroup": {
      "idRef": "8a7ca43a-32bd-40d2-871a-02220a165cb7"
    },
    "user": [

    ],
    "userGroup": [

    ]
  },
  {
    "id": "29f29b2b-7a67-448d-bee9-cca9c81faf6c",
    "instanceId": 4141,
    "authEntityId": 4141,
    "displayName": "4141",
    "authEntityClass": -1909073334,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "createTime": 1517966741363,
    "deployed": false,
    "isSeeded": true,
    "isStale": false,
    "lastUpdateTime": 1517966741363,
    "name": "audio-over-http",
    "namespace": "scalablegroup:application",
    "provisioningState": "DEFINED",
    "qualifier": "application",
    "resourceVersion": 0,
    "targetIdList": [

    ],
    "type": "scalablegroup",
    "cfsChangeInfo": [

    ],
    "customProvisions": [

    ],
    "scalableGroupExternalHandle": "audio-over-http",
    "scalableGroupType": "APPLICATION",
    "securityGroupTag": 0,
    "state": "ACTIVE",
    "vnAgnostic": true,
    "identitySource": {
      "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
      "instanceId": 10010,
      "authEntityId": 10010,
      "displayName": "10010",
      "authEntityClass": 1738953278,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "state": "INACTIVE",
      "type": "NBAR"
    },
    "indicativeNetworkIdentity": [

    ],
    "networkApplications": [
      {
        "id": "20dce3f8-09cc-4054-86ad-1ca7ff625630",
        "instanceId": 11102,
        "authEntityId": 11102,
        "displayName": "11102",
        "authEntityClass": -217092956,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "appProtocol": "tcp",
        "applicationGroup": "other",
        "applicationType": "DEFAULT",
        "categoryId": "38a8e354-e2fe-4f3c-b1c5-2bbb90fb0d99",
        "createTime": 1517966741363,
        "encrypted": "false",
        "engineId": "13",
        "helpString": "Audio traffic over HTTP",
        "ignoreConflict": false,
        "lastUpdateTime": 1517966741363,
        "longDescription": "Audio over HTTP represents a classification of transferring Audio data (such as radio and audio) streaming over the HTTP protocol. If a flow is classified as a more specific protocol, it will not be classified by audio-over-http.",
        "name": "audio-over-http",
        "nbarId": "120",
        "p2pTechnology": "false",
        "popularity": 10,
        "rank": 65535,
        "references": "N/A",
        "selectorId": "430",
        "subCategory": "fe150151-77ab-4ac6-805f-77d0d642b798",
        "trafficClass": "MULTIMEDIA_STREAMING",
        "tunnel": "false"
      }
    ],
    "networkIdentity": [
      {
        "id": "321738da-e1b1-4cf7-8ef5-c30834c03be8",
        "instanceId": 12281,
        "authEntityId": 12281,
        "displayName": "12281",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "80",
        "protocol": "TCP",
        "upperPort": 0
      }
    ],
    "parentScalableGroup": {
      "idRef": "aad510ce-fe95-4dfc-ad06-e494641cc016"
    },
    "user": [

    ],
    "userGroup": [

    ]
  },
  {
    "id": "2a670a78-c148-4486-b015-d086e499f049",
    "instanceId": 15209,
    "authEntityId": 15209,
    "displayName": "15209",
    "authEntityClass": -1909073334,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "createTime": 1517966744488,
    "deployed": false,
    "isSeeded": true,
    "isStale": false,
    "lastUpdateTime": 1517966744488,
    "name": "ssl-local-net",
    "namespace": "scalablegroup:application",
    "provisioningState": "DEFINED",
    "qualifier": "application",
    "resourceVersion": 0,
    "targetIdList": [

    ],
    "type": "scalablegroup",
    "cfsChangeInfo": [

    ],
    "customProvisions": [

    ],
    "scalableGroupExternalHandle": "ssl-local-net",
    "scalableGroupType": "APPLICATION",
    "securityGroupTag": 0,
    "state": "ACTIVE",
    "vnAgnostic": true,
    "identitySource": {
      "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
      "instanceId": 10010,
      "authEntityId": 10010,
      "displayName": "10010",
      "authEntityClass": 1738953278,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "state": "INACTIVE",
      "type": "NBAR"
    },
    "indicativeNetworkIdentity": [

    ],
    "networkApplications": [
      {
        "id": "bd151551-1b16-41fd-8cbf-0b1d3b2e4104",
        "instanceId": 16152,
        "authEntityId": 16152,
        "displayName": "16152",
        "authEntityClass": -217092956,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "appProtocol": "tcp/udp",
        "applicationGroup": "other",
        "applicationType": "DEFAULT",
        "categoryId": "878644b5-fe91-45b4-9204-31c84a73ba48",
        "createTime": 1517966744488,
        "encrypted": "true",
        "engineId": "13",
        "helpString": "Local network generic HTTP traffic.",
        "ignoreConflict": false,
        "lastUpdateTime": 1517966744488,
        "longDescription": "Local network generic HTTP traffic.",
        "name": "ssl-local-net",
        "nbarId": "1744",
        "p2pTechnology": "false",
        "popularity": 8,
        "rank": 65535,
        "selectorId": "813",
        "subCategory": "b90786f4-0190-45b8-9924-21b3e9682590",
        "trafficClass": "TRANSACTIONAL_DATA",
        "tunnel": "true"
      }
    ],
    "networkIdentity": [
      {
        "id": "bed7a90a-ff30-4b8f-9aec-5392759bbd95",
        "instanceId": 17268,
        "authEntityId": 17268,
        "displayName": "17268",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "53,5353",
        "protocol": "UDP",
        "upperPort": 0
      },
      {
        "id": "0e834d66-f63f-4ecf-919e-6556ba5b3d11",
        "instanceId": 17269,
        "authEntityId": 17269,
        "displayName": "17269",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "53,80,443,5353",
        "protocol": "TCP",
        "upperPort": 0
      }
    ],
    "parentScalableGroup": {
      "idRef": "e4e23452-2fdf-4d92-88d8-a7d24426ab72"
    },
    "user": [

    ],
    "userGroup": [

    ]
  },
  {
    "id": "2a867de4-babc-4cf5-b79c-052a49f330f8",
    "instanceId": 4126,
    "authEntityId": 4126,
    "displayName": "4126",
    "authEntityClass": -1909073334,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "createTime": 1517966741252,
    "deployed": false,
    "isSeeded": true,
    "isStale": false,
    "lastUpdateTime": 1517966741252,
    "name": "asipregistry",
    "namespace": "scalablegroup:application",
    "provisioningState": "DEFINED",
    "qualifier": "application",
    "resourceVersion": 0,
    "targetIdList": [

    ],
    "type": "scalablegroup",
    "cfsChangeInfo": [

    ],
    "customProvisions": [

    ],
    "scalableGroupExternalHandle": "asipregistry",
    "scalableGroupType": "APPLICATION",
    "securityGroupTag": 0,
    "state": "ACTIVE",
    "vnAgnostic": true,
    "identitySource": {
      "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
      "instanceId": 10010,
      "authEntityId": 10010,
      "displayName": "10010",
      "authEntityClass": 1738953278,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "state": "INACTIVE",
      "type": "NBAR"
    },
    "indicativeNetworkIdentity": [
      {
        "id": "651cd1a7-0eeb-45ad-a88c-a30e67bf5cde",
        "instanceId": 12237,
        "authEntityId": 12237,
        "displayName": "12237",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "687",
        "protocol": "TCP",
        "upperPort": 0
      },
      {
        "id": "ae153b5b-b6e7-478e-96f4-4bb881ce3c64",
        "instanceId": 12238,
        "authEntityId": 12238,
        "displayName": "12238",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "687",
        "protocol": "UDP",
        "upperPort": 0
      }
    ],
    "networkApplications": [
      {
        "id": "a9e31526-c19a-4ef6-9463-35ad937ffc85",
        "instanceId": 11088,
        "authEntityId": 11088,
        "displayName": "11088",
        "authEntityClass": -217092956,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "appProtocol": "tcp/udp",
        "applicationGroup": "other",
        "applicationType": "DEFAULT",
        "categoryId": "1fc17362-9334-4c7b-b752-5afdbb574017",
        "createTime": 1517966741252,
        "encrypted": "false",
        "engineId": "3",
        "helpString": "asipregistry",
        "ignoreConflict": false,
        "lastUpdateTime": 1517966741252,
        "longDescription": "Registered with IANA on port 687 TCP/UDP",
        "name": "asipregistry",
        "nbarId": "595",
        "p2pTechnology": "false",
        "popularity": 1,
        "rank": 65535,
        "references": "http://www.iana.org/assignments/service-names-port-numbers/service-names-port-numbers.xml",
        "selectorId": "687",
        "subCategory": "f3c0f761-d894-4112-ab89-b90fc84fd660",
        "trafficClass": "OPS_ADMIN_MGMT",
        "tunnel": "false"
      }
    ],
    "networkIdentity": [
      {
        "id": "beef6e82-3f8c-4657-b0be-70e43a32fb38",
        "instanceId": 12235,
        "authEntityId": 12235,
        "displayName": "12235",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "687",
        "protocol": "TCP",
        "upperPort": 0
      },
      {
        "id": "ee312053-62ee-4585-b221-dc759d7a7818",
        "instanceId": 12236,
        "authEntityId": 12236,
        "displayName": "12236",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "687",
        "protocol": "UDP",
        "upperPort": 0
      }
    ],
    "parentScalableGroup": {
      "idRef": "5fcd74d4-8726-47f7-a088-c457863da7ef"
    },
    "user": [

    ],
    "userGroup": [

    ]
  },
  {
    "id": "2a8e1833-2f49-4044-aaa8-d66145df3a6f",
    "instanceId": 4939,
    "authEntityId": 4939,
    "displayName": "4939",
    "authEntityClass": -1909073334,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "createTime": 1517966743854,
    "deployed": false,
    "isSeeded": true,
    "isStale": false,
    "lastUpdateTime": 1517966743854,
    "name": "patch-com",
    "namespace": "scalablegroup:application",
    "provisioningState": "DEFINED",
    "qualifier": "application",
    "resourceVersion": 0,
    "targetIdList": [

    ],
    "type": "scalablegroup",
    "cfsChangeInfo": [

    ],
    "customProvisions": [

    ],
    "scalableGroupExternalHandle": "patch-com",
    "scalableGroupType": "APPLICATION",
    "securityGroupTag": 0,
    "state": "ACTIVE",
    "vnAgnostic": true,
    "identitySource": {
      "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
      "instanceId": 10010,
      "authEntityId": 10010,
      "displayName": "10010",
      "authEntityClass": 1738953278,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "state": "INACTIVE",
      "type": "NBAR"
    },
    "indicativeNetworkIdentity": [

    ],
    "networkApplications": [
      {
        "id": "4d90ab30-81f4-46e4-8c66-1b49089c8f77",
        "instanceId": 11888,
        "authEntityId": 11888,
        "displayName": "11888",
        "authEntityClass": -217092956,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "appProtocol": "tcp/udp",
        "applicationGroup": "other",
        "applicationType": "DEFAULT",
        "categoryId": "878644b5-fe91-45b4-9204-31c84a73ba48",
        "createTime": 1517966743854,
        "encrypted": "false",
        "engineId": "13",
        "helpString": "Patch.com - Local news website.",
        "ignoreConflict": false,
        "lastUpdateTime": 1517966743854,
        "longDescription": "Patch.com - Local news website.",
        "name": "patch-com",
        "nbarId": "1694",
        "p2pTechnology": "false",
        "popularity": 3,
        "rank": 65535,
        "references": "http://assets0.patch-assets.com",
        "selectorId": "764",
        "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
        "trafficClass": "TRANSACTIONAL_DATA",
        "tunnel": "false"
      }
    ],
    "networkIdentity": [
      {
        "id": "48e6304e-56aa-4b2f-9c1f-5e1030cf28da",
        "instanceId": 14483,
        "authEntityId": 14483,
        "displayName": "14483",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "53,80,443,5353",
        "protocol": "TCP",
        "upperPort": 0
      },
      {
        "id": "9e72b24a-3355-480a-aa6c-97870fd69e9c",
        "instanceId": 14484,
        "authEntityId": 14484,
        "displayName": "14484",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "53,5353",
        "protocol": "UDP",
        "upperPort": 0
      }
    ],
    "parentScalableGroup": {
      "idRef": "28e08de4-d7b2-4521-84ce-bc1e5ee0b9cc"
    },
    "user": [

    ],
    "userGroup": [

    ]
  },
  {
    "id": "2ac69ce0-4f00-4080-a353-1972e36f6bb5",
    "instanceId": 4934,
    "authEntityId": 4934,
    "displayName": "4934",
    "authEntityClass": -1909073334,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "createTime": 1517966743842,
    "deployed": false,
    "isSeeded": true,
    "isStale": false,
    "lastUpdateTime": 1517966743842,
    "name": "pandora",
    "namespace": "scalablegroup:application",
    "provisioningState": "DEFINED",
    "qualifier": "application",
    "resourceVersion": 0,
    "targetIdList": [

    ],
    "type": "scalablegroup",
    "cfsChangeInfo": [

    ],
    "customProvisions": [

    ],
    "scalableGroupExternalHandle": "pandora",
    "scalableGroupType": "APPLICATION",
    "securityGroupTag": 0,
    "state": "ACTIVE",
    "vnAgnostic": true,
    "identitySource": {
      "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
      "instanceId": 10010,
      "authEntityId": 10010,
      "displayName": "10010",
      "authEntityClass": 1738953278,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "state": "INACTIVE",
      "type": "NBAR"
    },
    "indicativeNetworkIdentity": [

    ],
    "networkApplications": [
      {
        "id": "d16dbe63-68f9-46b7-9875-f4a311eb28ab",
        "instanceId": 11883,
        "authEntityId": 11883,
        "displayName": "11883",
        "authEntityClass": -217092956,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "appProtocol": "tcp/udp",
        "applicationGroup": "other",
        "applicationType": "DEFAULT",
        "categoryId": "a09ef1b3-ba26-4372-aee8-309d50697e76",
        "createTime": 1517966743842,
        "encrypted": "true",
        "engineId": "13",
        "helpString": "Pandora Internet Radio",
        "ignoreConflict": false,
        "lastUpdateTime": 1517966743842,
        "longDescription": "Pandora Internet Radio (also referred to as Pandora Radio or simply Pandora) is an automated music recommendation service and custodian of the Music Genome Project available only in the United States. The service plays musical selections similar to song suggestions entered by a user.",
        "name": "pandora",
        "nbarId": "1451",
        "p2pTechnology": "false",
        "popularity": 10,
        "rank": 65535,
        "references": "http://www.pandora.com",
        "selectorId": "515",
        "subCategory": "bf1b9d2c-aa52-4f37-8860-40d4603319f5",
        "trafficClass": "MULTIMEDIA_STREAMING",
        "tunnel": "false"
      }
    ],
    "networkIdentity": [
      {
        "id": "1fd11c2b-deb5-4d22-90c9-18e572e1930f",
        "instanceId": 14465,
        "authEntityId": 14465,
        "displayName": "14465",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "53,5353",
        "protocol": "UDP",
        "upperPort": 0
      },
      {
        "id": "f456bdb1-fe19-411d-8587-71cd87369bc6",
        "instanceId": 14466,
        "authEntityId": 14466,
        "displayName": "14466",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "53,80,443,5353",
        "protocol": "TCP",
        "upperPort": 0
      }
    ],
    "parentScalableGroup": {
      "idRef": "fd6264c9-c16a-4311-a335-a3db039256f3"
    },
    "user": [

    ],
    "userGroup": [

    ]
  },
  {
    "id": "2b54610e-e07c-4ffe-a6e9-52b9af64343c",
    "instanceId": 4166,
    "authEntityId": 4166,
    "displayName": "4166",
    "authEntityClass": -1909073334,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "createTime": 1517966741466,
    "deployed": false,
    "isSeeded": true,
    "isStale": false,
    "lastUpdateTime": 1517966741466,
    "name": "basecamp",
    "namespace": "scalablegroup:application",
    "provisioningState": "DEFINED",
    "qualifier": "application",
    "resourceVersion": 0,
    "targetIdList": [

    ],
    "type": "scalablegroup",
    "cfsChangeInfo": [

    ],
    "customProvisions": [

    ],
    "scalableGroupExternalHandle": "basecamp",
    "scalableGroupType": "APPLICATION",
    "securityGroupTag": 0,
    "state": "ACTIVE",
    "vnAgnostic": true,
    "identitySource": {
      "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
      "instanceId": 10010,
      "authEntityId": 10010,
      "displayName": "10010",
      "authEntityClass": 1738953278,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "state": "INACTIVE",
      "type": "NBAR"
    },
    "indicativeNetworkIdentity": [

    ],
    "networkApplications": [
      {
        "id": "fef49edb-5259-418b-9a28-026a2e95dec5",
        "instanceId": 11125,
        "authEntityId": 11125,
        "displayName": "11125",
        "authEntityClass": -217092956,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "appProtocol": "tcp/udp",
        "applicationGroup": "other",
        "applicationType": "DEFAULT",
        "categoryId": "878644b5-fe91-45b4-9204-31c84a73ba48",
        "createTime": 1517966741465,
        "encrypted": "false",
        "engineId": "13",
        "helpString": "Basecamp - Web based project management tool.",
        "ignoreConflict": false,
        "lastUpdateTime": 1517966741465,
        "longDescription": "Basecamp - Web based project management tool.",
        "name": "basecamp",
        "nbarId": "1668",
        "p2pTechnology": "false",
        "popularity": 3,
        "rank": 65535,
        "references": "http://basecamp.com",
        "selectorId": "738",
        "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
        "trafficClass": "TRANSACTIONAL_DATA",
        "tunnel": "false"
      }
    ],
    "networkIdentity": [
      {
        "id": "cf8589f0-5175-457b-9cb9-0768d829fb32",
        "instanceId": 12344,
        "authEntityId": 12344,
        "displayName": "12344",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "53,5353",
        "protocol": "UDP",
        "upperPort": 0
      },
      {
        "id": "89524450-0338-46fc-b4cb-bc33cc34a240",
        "instanceId": 12345,
        "authEntityId": 12345,
        "displayName": "12345",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "53,80,443,5353",
        "protocol": "TCP",
        "upperPort": 0
      }
    ],
    "parentScalableGroup": {
      "idRef": "28e08de4-d7b2-4521-84ce-bc1e5ee0b9cc"
    },
    "user": [

    ],
    "userGroup": [

    ]
  },
  {
    "id": "2b807154-dcb4-44b9-8219-532ba3be2729",
    "instanceId": 4616,
    "authEntityId": 4616,
    "displayName": "4616",
    "authEntityClass": -1909073334,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "createTime": 1517966743133,
    "deployed": false,
    "isSeeded": true,
    "isStale": false,
    "lastUpdateTime": 1517966743133,
    "name": "ipass",
    "namespace": "scalablegroup:application",
    "provisioningState": "DEFINED",
    "qualifier": "application",
    "resourceVersion": 0,
    "targetIdList": [

    ],
    "type": "scalablegroup",
    "cfsChangeInfo": [

    ],
    "customProvisions": [

    ],
    "scalableGroupExternalHandle": "ipass",
    "scalableGroupType": "APPLICATION",
    "securityGroupTag": 0,
    "state": "ACTIVE",
    "vnAgnostic": true,
    "identitySource": {
      "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
      "instanceId": 10010,
      "authEntityId": 10010,
      "displayName": "10010",
      "authEntityClass": 1738953278,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "state": "INACTIVE",
      "type": "NBAR"
    },
    "indicativeNetworkIdentity": [

    ],
    "networkApplications": [
      {
        "id": "c2ddf933-3c90-4cd8-a16e-49a63534c2cb",
        "instanceId": 11566,
        "authEntityId": 11566,
        "displayName": "11566",
        "authEntityClass": -217092956,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "appProtocol": "tcp/udp",
        "applicationGroup": "other",
        "applicationType": "DEFAULT",
        "categoryId": "7e19fdea-6222-4c57-9e66-66c2340d6bc3",
        "createTime": 1517966743133,
        "encrypted": "false",
        "engineId": "13",
        "helpString": "wifi password application",
        "ignoreConflict": false,
        "lastUpdateTime": 1517966743133,
        "longDescription": "ipass allow users to connect millions of hotspots around the world",
        "name": "ipass",
        "nbarId": "1007",
        "p2pTechnology": "false",
        "popularity": 10,
        "rank": 65535,
        "references": "http://ipass.com/",
        "selectorId": "1007",
        "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
        "trafficClass": "BULK_DATA",
        "tunnel": "false"
      }
    ],
    "networkIdentity": [
      {
        "id": "955add3b-bfa9-4b58-9f58-f97c8653b846",
        "instanceId": 13553,
        "authEntityId": 13553,
        "displayName": "13553",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "53,5353",
        "protocol": "UDP",
        "upperPort": 0
      },
      {
        "id": "7b343e37-a536-4748-9316-bff0a09f747a",
        "instanceId": 13554,
        "authEntityId": 13554,
        "displayName": "13554",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "53,80,443,5353",
        "protocol": "TCP",
        "upperPort": 0
      }
    ],
    "parentScalableGroup": {
      "idRef": "7788bf2d-e289-4597-b500-17aacb7e820e"
    },
    "user": [

    ],
    "userGroup": [

    ]
  },
  {
    "id": "2bbfd654-dd50-4596-80fd-e266a92cdcc8",
    "instanceId": 15316,
    "authEntityId": 15316,
    "displayName": "15316",
    "authEntityClass": -1909073334,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "createTime": 1517966744726,
    "deployed": false,
    "isSeeded": true,
    "isStale": false,
    "lastUpdateTime": 1517966744726,
    "name": "udplite",
    "namespace": "scalablegroup:application",
    "provisioningState": "DEFINED",
    "qualifier": "application",
    "resourceVersion": 0,
    "targetIdList": [

    ],
    "type": "scalablegroup",
    "cfsChangeInfo": [

    ],
    "customProvisions": [

    ],
    "scalableGroupExternalHandle": "udplite",
    "scalableGroupType": "APPLICATION",
    "securityGroupTag": 0,
    "state": "ACTIVE",
    "vnAgnostic": true,
    "identitySource": {
      "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
      "instanceId": 10010,
      "authEntityId": 10010,
      "displayName": "10010",
      "authEntityClass": 1738953278,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "state": "INACTIVE",
      "type": "NBAR"
    },
    "indicativeNetworkIdentity": [
      {
        "id": "d6e16b66-ec8d-46e0-af94-4ff4f1bad61e",
        "instanceId": 17566,
        "authEntityId": 17566,
        "displayName": "17566",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipProtocolNumbers": "136",
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "protocol": "UDPLITE",
        "upperPort": 0
      }
    ],
    "networkApplications": [
      {
        "id": "ac791235-c30a-4c25-be1b-06d66dec832e",
        "instanceId": 16259,
        "authEntityId": 16259,
        "displayName": "16259",
        "authEntityClass": -217092956,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "appProtocol": "ip",
        "applicationGroup": "other",
        "applicationType": "DEFAULT",
        "categoryId": "6daa3dfd-3526-407a-88f0-97824b3f3a24",
        "createTime": 1517966744725,
        "encrypted": "false",
        "engineId": "1",
        "helpString": "UDPLite, Lightweight connectionless User Datagram Protocol",
        "ignoreConflict": false,
        "lastUpdateTime": 1517966744725,
        "longDescription": "Lightweight User Datagram Protocol (UDP Lite) is a connectionless Transport layer protocol. UDP Lite provides partial checksums that cover only a segment of the datagrams. UDP Lite may be used in error-prone network environments that prefer to have partially damaged payloads delivered rather than discarded. UDP Lite is IP protocol number 136.",
        "name": "udplite",
        "nbarId": "1234",
        "p2pTechnology": "false",
        "popularity": 2,
        "rank": 65535,
        "references": "http://www.ietf.org/rfc/rfc3828.txt",
        "selectorId": "136",
        "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
        "trafficClass": "BULK_DATA",
        "tunnel": "false"
      }
    ],
    "networkIdentity": [
      {
        "id": "53fe91f1-d4ca-464d-b7bb-fcbca5ece80f",
        "instanceId": 17565,
        "authEntityId": 17565,
        "displayName": "17565",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipProtocolNumbers": "136",
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "protocol": "UDPLITE",
        "upperPort": 0
      }
    ],
    "parentScalableGroup": {
      "idRef": "000c15bf-28fe-4fbb-9b69-35e6502721f1"
    },
    "user": [

    ],
    "userGroup": [

    ]
  },
  {
    "id": "2bdc82d4-fcde-4b04-8f46-4387860023fb",
    "instanceId": 4476,
    "authEntityId": 4476,
    "displayName": "4476",
    "authEntityClass": -1909073334,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "createTime": 1517966742766,
    "deployed": false,
    "isSeeded": true,
    "isStale": false,
    "lastUpdateTime": 1517966742766,
    "name": "gds_db",
    "namespace": "scalablegroup:application",
    "provisioningState": "DEFINED",
    "qualifier": "application",
    "resourceVersion": 0,
    "targetIdList": [

    ],
    "type": "scalablegroup",
    "cfsChangeInfo": [

    ],
    "customProvisions": [

    ],
    "scalableGroupExternalHandle": "gds_db",
    "scalableGroupType": "APPLICATION",
    "securityGroupTag": 0,
    "state": "ACTIVE",
    "vnAgnostic": true,
    "identitySource": {
      "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
      "instanceId": 10010,
      "authEntityId": 10010,
      "displayName": "10010",
      "authEntityClass": 1738953278,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "state": "INACTIVE",
      "type": "NBAR"
    },
    "indicativeNetworkIdentity": [
      {
        "id": "90d7cb96-0b97-4d72-8cce-2239f35ff5f9",
        "instanceId": 13187,
        "authEntityId": 13187,
        "displayName": "13187",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "3050",
        "protocol": "UDP",
        "upperPort": 0
      },
      {
        "id": "e3fa28e4-8661-47d6-94c0-3beeaddde779",
        "instanceId": 13188,
        "authEntityId": 13188,
        "displayName": "13188",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "3050",
        "protocol": "TCP",
        "upperPort": 0
      }
    ],
    "networkApplications": [
      {
        "id": "af9e1181-9f19-4679-958c-967de5661adc",
        "instanceId": 11427,
        "authEntityId": 11427,
        "displayName": "11427",
        "authEntityClass": -217092956,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "appProtocol": "tcp/udp",
        "applicationGroup": "other",
        "applicationType": "DEFAULT",
        "categoryId": "cb5915d1-afec-4c30-ac9e-069ff382272f",
        "createTime": 1517966742766,
        "encrypted": "false",
        "engineId": "3",
        "helpString": "GDS DataBase",
        "ignoreConflict": false,
        "lastUpdateTime": 1517966742766,
        "longDescription": "Registered with IANA on port 3050 TCP/UDP",
        "name": "gds_db",
        "nbarId": "1343",
        "p2pTechnology": "false",
        "popularity": 1,
        "rank": 65535,
        "references": "http://www.iana.org/assignments/service-names-port-numbers/service-names-port-numbers.xml",
        "selectorId": "3050",
        "subCategory": "b90786f4-0190-45b8-9924-21b3e9682590",
        "trafficClass": "TRANSACTIONAL_DATA",
        "tunnel": "false"
      }
    ],
    "networkIdentity": [
      {
        "id": "65247482-cdaa-4f2f-aba8-8c9889c18684",
        "instanceId": 13185,
        "authEntityId": 13185,
        "displayName": "13185",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "3050",
        "protocol": "TCP",
        "upperPort": 0
      },
      {
        "id": "8d701991-eaf8-4dd6-949b-66410e1fd171",
        "instanceId": 13186,
        "authEntityId": 13186,
        "displayName": "13186",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "3050",
        "protocol": "UDP",
        "upperPort": 0
      }
    ],
    "parentScalableGroup": {
      "idRef": "8718395e-683e-4a2d-a232-100202751271"
    },
    "user": [

    ],
    "userGroup": [

    ]
  },
  {
    "id": "2be293a1-e410-4396-b6e1-db2198fd96bb",
    "instanceId": 4540,
    "authEntityId": 4540,
    "displayName": "4540",
    "authEntityClass": -1909073334,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "createTime": 1517966742936,
    "deployed": false,
    "isSeeded": true,
    "isStale": false,
    "lastUpdateTime": 1517966742936,
    "name": "hitachi-spc",
    "namespace": "scalablegroup:application",
    "provisioningState": "DEFINED",
    "qualifier": "application",
    "resourceVersion": 0,
    "targetIdList": [

    ],
    "type": "scalablegroup",
    "cfsChangeInfo": [

    ],
    "customProvisions": [

    ],
    "scalableGroupExternalHandle": "hitachi-spc",
    "scalableGroupType": "APPLICATION",
    "securityGroupTag": 0,
    "state": "ACTIVE",
    "vnAgnostic": true,
    "identitySource": {
      "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
      "instanceId": 10010,
      "authEntityId": 10010,
      "displayName": "10010",
      "authEntityClass": 1738953278,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "state": "INACTIVE",
      "type": "NBAR"
    },
    "indicativeNetworkIdentity": [
      {
        "id": "6b4b5421-b417-40b5-882e-6fe6387fd78e",
        "instanceId": 13342,
        "authEntityId": 13342,
        "displayName": "13342",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "20016",
        "protocol": "TCP",
        "upperPort": 0
      }
    ],
    "networkApplications": [
      {
        "id": "bda4db18-4efa-43e6-9e55-873f0f95c4d5",
        "instanceId": 11491,
        "authEntityId": 11491,
        "displayName": "11491",
        "authEntityClass": -217092956,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "appProtocol": "tcp",
        "applicationGroup": "other",
        "applicationType": "DEFAULT",
        "categoryId": "6daa3dfd-3526-407a-88f0-97824b3f3a24",
        "createTime": 1517966742936,
        "encrypted": "false",
        "engineId": "3",
        "helpString": "Hitachi Universal Storage Platform",
        "ignoreConflict": false,
        "lastUpdateTime": 1517966742936,
        "longDescription": "Hitachi Universal Storage Platformis the brand name for anHitachi Data Systemsline ofenterprise storagearrays.",
        "name": "hitachi-spc",
        "nbarId": "1348",
        "p2pTechnology": "false",
        "popularity": 1,
        "rank": 65535,
        "references": "http://en.wikipedia.org/wiki/Universal_Storage_Platform",
        "selectorId": "20016",
        "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
        "trafficClass": "BULK_DATA",
        "tunnel": "false"
      }
    ],
    "networkIdentity": [
      {
        "id": "8bdbbfc2-3525-45ef-b715-0091d58efaf7",
        "instanceId": 13341,
        "authEntityId": 13341,
        "displayName": "13341",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "20016",
        "protocol": "TCP",
        "upperPort": 0
      }
    ],
    "parentScalableGroup": {
      "idRef": "000c15bf-28fe-4fbb-9b69-35e6502721f1"
    },
    "user": [

    ],
    "userGroup": [

    ]
  },
  {
    "id": "2bf6cfb6-239e-402a-8b79-460351b12183",
    "instanceId": 15220,
    "authEntityId": 15220,
    "displayName": "15220",
    "authEntityClass": -1909073334,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "createTime": 1517966744521,
    "deployed": false,
    "isSeeded": true,
    "isStale": false,
    "lastUpdateTime": 1517966744521,
    "name": "stmf",
    "namespace": "scalablegroup:application",
    "provisioningState": "DEFINED",
    "qualifier": "application",
    "resourceVersion": 0,
    "targetIdList": [

    ],
    "type": "scalablegroup",
    "cfsChangeInfo": [

    ],
    "customProvisions": [

    ],
    "scalableGroupExternalHandle": "stmf",
    "scalableGroupType": "APPLICATION",
    "securityGroupTag": 0,
    "state": "ACTIVE",
    "vnAgnostic": true,
    "identitySource": {
      "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
      "instanceId": 10010,
      "authEntityId": 10010,
      "displayName": "10010",
      "authEntityClass": 1738953278,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "state": "INACTIVE",
      "type": "NBAR"
    },
    "indicativeNetworkIdentity": [
      {
        "id": "8f8bb10c-45ec-4ad0-be6c-5e2d84b21fc0",
        "instanceId": 17290,
        "authEntityId": 17290,
        "displayName": "17290",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "501",
        "protocol": "TCP",
        "upperPort": 0
      },
      {
        "id": "bf6c7232-4bc4-402b-a219-2dcf96e0661e",
        "instanceId": 17291,
        "authEntityId": 17291,
        "displayName": "17291",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "501",
        "protocol": "UDP",
        "upperPort": 0
      }
    ],
    "networkApplications": [
      {
        "id": "395bc917-727b-494e-8e10-65f2297244da",
        "instanceId": 16163,
        "authEntityId": 16163,
        "displayName": "16163",
        "authEntityClass": -217092956,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "appProtocol": "tcp/udp",
        "applicationGroup": "other",
        "applicationType": "DEFAULT",
        "categoryId": "6daa3dfd-3526-407a-88f0-97824b3f3a24",
        "createTime": 1517966744521,
        "encrypted": "false",
        "engineId": "3",
        "helpString": "stmf",
        "ignoreConflict": false,
        "lastUpdateTime": 1517966744521,
        "longDescription": "Registered with IANA on port 501 TCP/UDP",
        "name": "stmf",
        "nbarId": "415",
        "p2pTechnology": "false",
        "popularity": 1,
        "rank": 65535,
        "references": "http://www.iana.org/assignments/service-names-port-numbers/service-names-port-numbers.xml",
        "selectorId": "501",
        "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
        "trafficClass": "BULK_DATA",
        "tunnel": "false"
      }
    ],
    "networkIdentity": [
      {
        "id": "d7062948-6632-47af-8669-91c8014c11b0",
        "instanceId": 17288,
        "authEntityId": 17288,
        "displayName": "17288",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "501",
        "protocol": "TCP",
        "upperPort": 0
      },
      {
        "id": "38583469-59b3-4548-b27d-1c5e726bb066",
        "instanceId": 17289,
        "authEntityId": 17289,
        "displayName": "17289",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "501",
        "protocol": "UDP",
        "upperPort": 0
      }
    ],
    "parentScalableGroup": {
      "idRef": "000c15bf-28fe-4fbb-9b69-35e6502721f1"
    },
    "user": [

    ],
    "userGroup": [

    ]
  },
  {
    "id": "2bf989a4-dbdd-498a-a34a-d0db4f688d27",
    "instanceId": 4240,
    "authEntityId": 4240,
    "displayName": "4240",
    "authEntityClass": -1909073334,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "createTime": 1517966741892,
    "deployed": false,
    "isSeeded": true,
    "isStale": false,
    "lastUpdateTime": 1517966741892,
    "name": "cisco-jabber-im",
    "namespace": "scalablegroup:application",
    "provisioningState": "DEFINED",
    "qualifier": "application",
    "resourceVersion": 0,
    "targetIdList": [

    ],
    "type": "scalablegroup",
    "cfsChangeInfo": [

    ],
    "customProvisions": [

    ],
    "scalableGroupExternalHandle": "cisco-jabber-im",
    "scalableGroupType": "APPLICATION",
    "securityGroupTag": 0,
    "state": "ACTIVE",
    "vnAgnostic": true,
    "identitySource": {
      "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
      "instanceId": 10010,
      "authEntityId": 10010,
      "displayName": "10010",
      "authEntityClass": 1738953278,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "state": "INACTIVE",
      "type": "NBAR"
    },
    "indicativeNetworkIdentity": [

    ],
    "networkApplications": [
      {
        "id": "b8b1da72-9021-4d33-8a68-8b46bb8a284c",
        "instanceId": 11194,
        "authEntityId": 11194,
        "displayName": "11194",
        "authEntityClass": -217092956,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "appProtocol": "tcp/udp",
        "applicationGroup": "cisco-jabber-group",
        "applicationType": "DEFAULT",
        "categoryId": "38a8e354-e2fe-4f3c-b1c5-2bbb90fb0d99",
        "createTime": 1517966741892,
        "encrypted": "true",
        "engineId": "13",
        "helpString": "Cisco Jabber Client; Instant Messaging",
        "ignoreConflict": false,
        "lastUpdateTime": 1517966741892,
        "longDescription": "Cisco Jabber is a unified communications client application that provides presence, instant messaging (IM), voice, and video calling capabilities on many platforms. This protocol classifies the Text Messaging part of Cisco Jabber",
        "name": "cisco-jabber-im",
        "nbarId": "1493",
        "p2pTechnology": "false",
        "popularity": 10,
        "rank": 65535,
        "references": "http://www.cisco.com/web/products/voice/jabber.html",
        "selectorId": "557",
        "subCategory": "05178016-4555-4291-ab4e-43c2ab2a931a",
        "trafficClass": "TRANSACTIONAL_DATA",
        "tunnel": "false"
      }
    ],
    "networkIdentity": [
      {
        "id": "5fab1d6a-0425-43d3-96ab-e1828c36465c",
        "instanceId": 12520,
        "authEntityId": 12520,
        "displayName": "12520",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "53,80,443,5222,5353",
        "protocol": "TCP",
        "upperPort": 0
      },
      {
        "id": "ea6bc993-f0aa-4e4b-9626-6cf36f3928ec",
        "instanceId": 12521,
        "authEntityId": 12521,
        "displayName": "12521",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "53,5222,5353",
        "protocol": "UDP",
        "upperPort": 0
      }
    ],
    "parentScalableGroup": {
      "idRef": "60f1364d-2a02-4063-8ba1-07817abe0612"
    },
    "user": [

    ],
    "userGroup": [

    ]
  },
  {
    "id": "2c15b91e-c815-4b91-a2fa-175db61ac7c2",
    "instanceId": 15451,
    "authEntityId": 15451,
    "displayName": "15451",
    "authEntityClass": -1909073334,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "createTime": 1517966744988,
    "deployed": false,
    "isSeeded": true,
    "isStale": false,
    "lastUpdateTime": 1517966744988,
    "name": "xwindows",
    "namespace": "scalablegroup:application",
    "provisioningState": "DEFINED",
    "qualifier": "application",
    "resourceVersion": 0,
    "targetIdList": [

    ],
    "type": "scalablegroup",
    "cfsChangeInfo": [

    ],
    "customProvisions": [

    ],
    "scalableGroupExternalHandle": "xwindows",
    "scalableGroupType": "APPLICATION",
    "securityGroupTag": 0,
    "state": "ACTIVE",
    "vnAgnostic": true,
    "identitySource": {
      "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
      "instanceId": 10010,
      "authEntityId": 10010,
      "displayName": "10010",
      "authEntityClass": 1738953278,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "state": "INACTIVE",
      "type": "NBAR"
    },
    "indicativeNetworkIdentity": [
      {
        "id": "06e6539b-d7b8-4c52-b27b-52647e2d00ba",
        "instanceId": 17938,
        "authEntityId": 17938,
        "displayName": "17938",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "6000,6001,6002,6003",
        "protocol": "TCP",
        "upperPort": 0
      }
    ],
    "networkApplications": [
      {
        "id": "031ea539-24f0-4bb3-b400-847c96828875",
        "instanceId": 16394,
        "authEntityId": 16394,
        "displayName": "16394",
        "authEntityClass": -217092956,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "appProtocol": "tcp",
        "applicationGroup": "other",
        "applicationType": "DEFAULT",
        "categoryId": "7e19fdea-6222-4c57-9e66-66c2340d6bc3",
        "createTime": 1517966744988,
        "encrypted": "false",
        "engineId": "3",
        "helpString": "X-Windows remote access",
        "ignoreConflict": false,
        "lastUpdateTime": 1517966744988,
        "longDescription": "The X windows system is a computer software system and network protocol that provides a basis for graphical user interfaces (GUIs) and rich input device capability for networked computers. It creates a hardware abstraction layer where software is written to use a generalized set of commands, allowing for device independence and reuse of programs on any computer that implements X windows.",
        "name": "xwindows",
        "nbarId": "45",
        "p2pTechnology": "false",
        "popularity": 1,
        "rank": 65535,
        "references": "http://en.wikipedia.org/wiki/X_Window_System",
        "selectorId": "6000",
        "subCategory": "ff664205-64f3-447e-acff-1ec1ba9a6c31",
        "trafficClass": "MULTIMEDIA_STREAMING",
        "tunnel": "false"
      }
    ],
    "networkIdentity": [
      {
        "id": "3ba70eae-2b4e-4336-bef7-a46b5db3cb11",
        "instanceId": 17937,
        "authEntityId": 17937,
        "displayName": "17937",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "6000,6001,6002,6003",
        "protocol": "TCP",
        "upperPort": 0
      }
    ],
    "parentScalableGroup": {
      "idRef": "4a6b37c6-7e11-40fe-a6b1-719b24813145"
    },
    "user": [

    ],
    "userGroup": [

    ]
  },
  {
    "id": "2c216c86-c90e-41f7-9efb-01bb89dfa5ca",
    "instanceId": 4529,
    "authEntityId": 4529,
    "displayName": "4529",
    "authEntityClass": -1909073334,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "createTime": 1517966742901,
    "deployed": false,
    "isSeeded": true,
    "isStale": false,
    "lastUpdateTime": 1517966742901,
    "name": "hangouts-file-transfer",
    "namespace": "scalablegroup:application",
    "provisioningState": "DEFINED",
    "qualifier": "application",
    "resourceVersion": 0,
    "targetIdList": [

    ],
    "type": "scalablegroup",
    "cfsChangeInfo": [

    ],
    "customProvisions": [

    ],
    "scalableGroupExternalHandle": "hangouts-file-transfer",
    "scalableGroupType": "APPLICATION",
    "securityGroupTag": 0,
    "state": "ACTIVE",
    "vnAgnostic": true,
    "identitySource": {
      "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
      "instanceId": 10010,
      "authEntityId": 10010,
      "displayName": "10010",
      "authEntityClass": 1738953278,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "state": "INACTIVE",
      "type": "NBAR"
    },
    "indicativeNetworkIdentity": [

    ],
    "networkApplications": [
      {
        "id": "8124ad04-2e3c-48a6-ae66-4094111b148e",
        "instanceId": 11480,
        "authEntityId": 11480,
        "displayName": "11480",
        "authEntityClass": -217092956,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "applicationGroup": "hangouts-group",
        "applicationType": "DEFAULT",
        "categoryId": "b33b2761-d981-4734-810a-a6a1c18ab352",
        "createTime": 1517966742901,
        "encrypted": "true",
        "engineId": "13",
        "helpString": "Google Hangouts file transfer",
        "ignoreConflict": false,
        "lastUpdateTime": 1517966742901,
        "longDescription": "Google Hangouts file transfer",
        "name": "hangouts-file-transfer",
        "nbarId": "1092",
        "p2pTechnology": "true",
        "popularity": 10,
        "rank": 65535,
        "references": "https://hangouts.google.com/",
        "selectorId": "1092",
        "subCategory": "50b55e2e-d0d0-4404-bea4-819351565d90",
        "trafficClass": "BULK_DATA",
        "tunnel": "false"
      }
    ],
    "networkIdentity": [

    ],
    "parentScalableGroup": {
      "idRef": "7a1a3bbb-dc84-4a35-82a4-7e650c4c4761"
    },
    "user": [

    ],
    "userGroup": [

    ]
  },
  {
    "id": "2c56d026-5425-40d9-886d-8b5d2b19d8a4",
    "instanceId": 15465,
    "authEntityId": 15465,
    "displayName": "15465",
    "authEntityClass": -1909073334,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "createTime": 1517966745021,
    "deployed": false,
    "isSeeded": true,
    "isStale": false,
    "lastUpdateTime": 1517966745021,
    "name": "zattoo",
    "namespace": "scalablegroup:application",
    "provisioningState": "DEFINED",
    "qualifier": "application",
    "resourceVersion": 0,
    "targetIdList": [

    ],
    "type": "scalablegroup",
    "cfsChangeInfo": [

    ],
    "customProvisions": [

    ],
    "scalableGroupExternalHandle": "zattoo",
    "scalableGroupType": "APPLICATION",
    "securityGroupTag": 0,
    "state": "ACTIVE",
    "vnAgnostic": true,
    "identitySource": {
      "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
      "instanceId": 10010,
      "authEntityId": 10010,
      "displayName": "10010",
      "authEntityClass": 1738953278,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "state": "INACTIVE",
      "type": "NBAR"
    },
    "indicativeNetworkIdentity": [

    ],
    "networkApplications": [
      {
        "id": "6d962fac-0f41-4b10-ab0c-a53d2044a227",
        "instanceId": 16408,
        "authEntityId": 16408,
        "displayName": "16408",
        "authEntityClass": -217092956,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "applicationGroup": "other",
        "applicationType": "DEFAULT",
        "categoryId": "a09ef1b3-ba26-4372-aee8-309d50697e76",
        "createTime": 1517966745021,
        "encrypted": "false",
        "engineId": "13",
        "helpString": "Peer-to-Peer Internet Video Protocol",
        "ignoreConflict": false,
        "lastUpdateTime": 1517966745021,
        "longDescription": "Zattoo is an online TV service that works on peer to peer technology over a broadband connection. Zattoo works on multiple devices: computer, iPhone, iPod touch or iPad and on various OS: Windows XP, Mac and Linux.",
        "name": "zattoo",
        "nbarId": "115",
        "p2pTechnology": "false",
        "popularity": 7,
        "rank": 65535,
        "references": "http://zattoo.com/en/about-zattoo/what-is-zattoo",
        "selectorId": "428",
        "subCategory": "85a797c0-aef8-4284-91a7-677f33e29abb",
        "trafficClass": "MULTIMEDIA_STREAMING",
        "tunnel": "false"
      }
    ],
    "networkIdentity": [

    ],
    "parentScalableGroup": {
      "idRef": "fd6264c9-c16a-4311-a335-a3db039256f3"
    },
    "user": [

    ],
    "userGroup": [

    ]
  },
  {
    "id": "2c5dd409-faa1-4f0f-8162-fdcdb76afbeb",
    "instanceId": 4379,
    "authEntityId": 4379,
    "displayName": "4379",
    "authEntityClass": -1909073334,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "createTime": 1517966742460,
    "deployed": false,
    "isSeeded": true,
    "isStale": false,
    "lastUpdateTime": 1517966742460,
    "name": "dna-cml",
    "namespace": "scalablegroup:application",
    "provisioningState": "DEFINED",
    "qualifier": "application",
    "resourceVersion": 0,
    "targetIdList": [

    ],
    "type": "scalablegroup",
    "cfsChangeInfo": [

    ],
    "customProvisions": [

    ],
    "scalableGroupExternalHandle": "dna-cml",
    "scalableGroupType": "APPLICATION",
    "securityGroupTag": 0,
    "state": "ACTIVE",
    "vnAgnostic": true,
    "identitySource": {
      "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
      "instanceId": 10010,
      "authEntityId": 10010,
      "displayName": "10010",
      "authEntityClass": 1738953278,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "state": "INACTIVE",
      "type": "NBAR"
    },
    "indicativeNetworkIdentity": [
      {
        "id": "f7ad3c9b-8edf-47e7-87f8-18b0cc203b2d",
        "instanceId": 12928,
        "authEntityId": 12928,
        "displayName": "12928",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "436",
        "protocol": "TCP",
        "upperPort": 0
      },
      {
        "id": "d9a22576-4b7e-4eb3-9558-5817b77220d6",
        "instanceId": 12929,
        "authEntityId": 12929,
        "displayName": "12929",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "436",
        "protocol": "UDP",
        "upperPort": 0
      }
    ],
    "networkApplications": [
      {
        "id": "016bb62a-52e0-4dbd-b952-54bc030aa771",
        "instanceId": 11330,
        "authEntityId": 11330,
        "displayName": "11330",
        "authEntityClass": -217092956,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "appProtocol": "tcp/udp",
        "applicationGroup": "other",
        "applicationType": "DEFAULT",
        "categoryId": "6daa3dfd-3526-407a-88f0-97824b3f3a24",
        "createTime": 1517966742460,
        "encrypted": "false",
        "engineId": "3",
        "helpString": "DNA-CML",
        "ignoreConflict": false,
        "lastUpdateTime": 1517966742460,
        "longDescription": "Registered with IANA on port 436 TCP/UDP",
        "name": "dna-cml",
        "nbarId": "351",
        "p2pTechnology": "false",
        "popularity": 1,
        "rank": 65535,
        "references": "http://www.iana.org/assignments/service-names-port-numbers/service-names-port-numbers.xml",
        "selectorId": "436",
        "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
        "trafficClass": "BULK_DATA",
        "tunnel": "false"
      }
    ],
    "networkIdentity": [
      {
        "id": "0677f0c2-50ee-4c75-be3e-0e155febda7d",
        "instanceId": 12926,
        "authEntityId": 12926,
        "displayName": "12926",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "436",
        "protocol": "TCP",
        "upperPort": 0
      },
      {
        "id": "84004a1a-7d67-45ce-9f51-87a1c134cccd",
        "instanceId": 12927,
        "authEntityId": 12927,
        "displayName": "12927",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "436",
        "protocol": "UDP",
        "upperPort": 0
      }
    ],
    "parentScalableGroup": {
      "idRef": "000c15bf-28fe-4fbb-9b69-35e6502721f1"
    },
    "user": [

    ],
    "userGroup": [

    ]
  },
  {
    "id": "2c843047-d204-4286-8db4-737202113f4b",
    "instanceId": 15452,
    "authEntityId": 15452,
    "displayName": "15452",
    "authEntityClass": -1909073334,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "createTime": 1517966744990,
    "deployed": false,
    "isSeeded": true,
    "isStale": false,
    "lastUpdateTime": 1517966744990,
    "name": "xyplex-mux",
    "namespace": "scalablegroup:application",
    "provisioningState": "DEFINED",
    "qualifier": "application",
    "resourceVersion": 0,
    "targetIdList": [

    ],
    "type": "scalablegroup",
    "cfsChangeInfo": [

    ],
    "customProvisions": [

    ],
    "scalableGroupExternalHandle": "xyplex-mux",
    "scalableGroupType": "APPLICATION",
    "securityGroupTag": 0,
    "state": "ACTIVE",
    "vnAgnostic": true,
    "identitySource": {
      "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
      "instanceId": 10010,
      "authEntityId": 10010,
      "displayName": "10010",
      "authEntityClass": 1738953278,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "state": "INACTIVE",
      "type": "NBAR"
    },
    "indicativeNetworkIdentity": [
      {
        "id": "e8cdd5a8-5abf-49aa-9744-28906e42cc77",
        "instanceId": 17941,
        "authEntityId": 17941,
        "displayName": "17941",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "173",
        "protocol": "TCP",
        "upperPort": 0
      },
      {
        "id": "206f9e12-a74f-49e4-bba2-80fbbe749009",
        "instanceId": 17942,
        "authEntityId": 17942,
        "displayName": "17942",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "173",
        "protocol": "UDP",
        "upperPort": 0
      }
    ],
    "networkApplications": [
      {
        "id": "85b13535-5a1f-4753-ab7f-9ee0158d2855",
        "instanceId": 16395,
        "authEntityId": 16395,
        "displayName": "16395",
        "authEntityClass": -217092956,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "appProtocol": "tcp/udp",
        "applicationGroup": "other",
        "applicationType": "DEFAULT",
        "categoryId": "6daa3dfd-3526-407a-88f0-97824b3f3a24",
        "createTime": 1517966744990,
        "encrypted": "false",
        "engineId": "3",
        "helpString": "Xyplex",
        "ignoreConflict": false,
        "lastUpdateTime": 1517966744990,
        "longDescription": "Registered with IANA on port 173 TCP/UDP",
        "name": "xyplex-mux",
        "nbarId": "1018",
        "p2pTechnology": "false",
        "popularity": 1,
        "rank": 65535,
        "references": "http://www.iana.org/assignments/service-names-port-numbers/service-names-port-numbers.xml",
        "selectorId": "173",
        "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
        "trafficClass": "BULK_DATA",
        "tunnel": "false"
      }
    ],
    "networkIdentity": [
      {
        "id": "7fa07173-22d6-44bf-a3e7-646dee17571d",
        "instanceId": 17939,
        "authEntityId": 17939,
        "displayName": "17939",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "173",
        "protocol": "UDP",
        "upperPort": 0
      },
      {
        "id": "cd6b9432-d8c7-44db-813a-b9e6e6729e07",
        "instanceId": 17940,
        "authEntityId": 17940,
        "displayName": "17940",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "173",
        "protocol": "TCP",
        "upperPort": 0
      }
    ],
    "parentScalableGroup": {
      "idRef": "000c15bf-28fe-4fbb-9b69-35e6502721f1"
    },
    "user": [

    ],
    "userGroup": [

    ]
  },
  {
    "id": "2c8fc1bf-fe32-49fc-9e49-c00de858f546",
    "instanceId": 4100,
    "authEntityId": 4100,
    "displayName": "4100",
    "authEntityClass": -1909073334,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "createTime": 1517966741078,
    "deployed": false,
    "isSeeded": true,
    "isStale": false,
    "lastUpdateTime": 1517966741078,
    "name": "apple-app-store",
    "namespace": "scalablegroup:application",
    "provisioningState": "DEFINED",
    "qualifier": "application",
    "resourceVersion": 0,
    "targetIdList": [

    ],
    "type": "scalablegroup",
    "cfsChangeInfo": [

    ],
    "customProvisions": [

    ],
    "scalableGroupExternalHandle": "apple-app-store",
    "scalableGroupType": "APPLICATION",
    "securityGroupTag": 0,
    "state": "ACTIVE",
    "vnAgnostic": true,
    "identitySource": {
      "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
      "instanceId": 10010,
      "authEntityId": 10010,
      "displayName": "10010",
      "authEntityClass": 1738953278,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "state": "INACTIVE",
      "type": "NBAR"
    },
    "indicativeNetworkIdentity": [

    ],
    "networkApplications": [
      {
        "id": "056ee384-95db-4319-9d75-6d85b3498a1c",
        "instanceId": 11064,
        "authEntityId": 11064,
        "displayName": "11064",
        "authEntityClass": -217092956,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "appProtocol": "tcp",
        "applicationGroup": "apple-group",
        "applicationType": "DEFAULT",
        "categoryId": "9d9132a2-d99b-49db-a414-9fc93dea6604",
        "createTime": 1517966741078,
        "encrypted": "true",
        "engineId": "13",
        "helpString": "Apple digital distribution platform for applications",
        "ignoreConflict": false,
        "lastUpdateTime": 1517966741078,
        "longDescription": "Apple App Store is a digital distribution platform for iOS/OS X applications, developed by Apple Inc. The service allows users to browse, download and update applications into their MAC/Apple devices.",
        "name": "apple-app-store",
        "nbarId": "1527",
        "p2pTechnology": "false",
        "popularity": 8,
        "rank": 65535,
        "references": "http://www.apple.com/osx/apps/app-store.html,http://www.apple.com/itunes/features/#store",
        "selectorId": "588",
        "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
        "trafficClass": "BULK_DATA",
        "tunnel": "false"
      }
    ],
    "networkIdentity": [
      {
        "id": "277229ce-5572-4257-bef2-d00990c78842",
        "instanceId": 12162,
        "authEntityId": 12162,
        "displayName": "12162",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "80",
        "protocol": "TCP",
        "upperPort": 0
      }
    ],
    "parentScalableGroup": {
      "idRef": "4a68bc73-53c6-4deb-9983-784a0338aefe"
    },
    "user": [

    ],
    "userGroup": [

    ]
  },
  {
    "id": "2c90a078-6702-4f0c-98b7-ddcda91f9881",
    "instanceId": 4776,
    "authEntityId": 4776,
    "displayName": "4776",
    "authEntityClass": -1909073334,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "createTime": 1517966743470,
    "deployed": false,
    "isSeeded": true,
    "isStale": false,
    "lastUpdateTime": 1517966743470,
    "name": "ms-lync-audio",
    "namespace": "scalablegroup:application",
    "provisioningState": "DEFINED",
    "qualifier": "application",
    "resourceVersion": 0,
    "targetIdList": [

    ],
    "type": "scalablegroup",
    "cfsChangeInfo": [

    ],
    "customProvisions": [

    ],
    "scalableGroupExternalHandle": "ms-lync-audio",
    "scalableGroupType": "APPLICATION",
    "securityGroupTag": 0,
    "state": "ACTIVE",
    "vnAgnostic": true,
    "identitySource": {
      "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
      "instanceId": 10010,
      "authEntityId": 10010,
      "displayName": "10010",
      "authEntityClass": 1738953278,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "state": "INACTIVE",
      "type": "NBAR"
    },
    "indicativeNetworkIdentity": [

    ],
    "networkApplications": [
      {
        "id": "36aea754-5293-4565-b8e3-dc70d66e5158",
        "instanceId": 11725,
        "authEntityId": 11725,
        "displayName": "11725",
        "authEntityClass": -217092956,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "appProtocol": "tcp/udp",
        "applicationGroup": "ms-lync-group",
        "applicationType": "DEFAULT",
        "categoryId": "38a8e354-e2fe-4f3c-b1c5-2bbb90fb0d99",
        "createTime": 1517966743470,
        "encrypted": "false",
        "engineId": "13",
        "helpString": "Skype provides cost effective and collaborative tools for businesses",
        "ignoreConflict": false,
        "lastUpdateTime": 1517966743470,
        "longDescription": "Skype for Business (formerly Microsoft Lync) is a communications and                            collaboration platform that brings together an experience inspired by                            Skype with enterprise-grade security, compliance and control. It offers                            features including presence, IM, voice and video calls, and online meetings.                            Since it is built right in to Microsoft Office, initiating chats,                            calls and meetings is an integrated experience within Office.                           This protocol classifies the audio traffic of this app.",
        "name": "ms-lync-audio",
        "nbarId": "1496",
        "p2pTechnology": "false",
        "popularity": 10,
        "rank": 65535,
        "references": "http://www.skype.com/en/business/",
        "selectorId": "559",
        "subCategory": "53ce0342-1e2d-455a-acc7-1b50db9d6845",
        "trafficClass": "VOIP_TELEPHONY",
        "tunnel": "false"
      }
    ],
    "networkIdentity": [
      {
        "id": "d6785690-e8f4-4df4-95f3-057ccdeba2b1",
        "instanceId": 13981,
        "authEntityId": 13981,
        "displayName": "13981",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "3478",
        "protocol": "UDP",
        "upperPort": 0
      },
      {
        "id": "5e7c8565-c942-4320-8596-fafdb76f7a17",
        "instanceId": 13982,
        "authEntityId": 13982,
        "displayName": "13982",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "3478",
        "protocol": "TCP",
        "upperPort": 0
      }
    ],
    "parentScalableGroup": {
      "idRef": "60f1364d-2a02-4063-8ba1-07817abe0612"
    },
    "user": [

    ],
    "userGroup": [

    ]
  },
  {
    "id": "2cb5bbba-dfdc-466a-99ce-43bf024cc9c8",
    "instanceId": 4485,
    "authEntityId": 4485,
    "displayName": "4485",
    "authEntityClass": -1909073334,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "createTime": 1517966742789,
    "deployed": false,
    "isSeeded": true,
    "isStale": false,
    "lastUpdateTime": 1517966742789,
    "name": "gkrellm",
    "namespace": "scalablegroup:application",
    "provisioningState": "DEFINED",
    "qualifier": "application",
    "resourceVersion": 0,
    "targetIdList": [

    ],
    "type": "scalablegroup",
    "cfsChangeInfo": [

    ],
    "customProvisions": [

    ],
    "scalableGroupExternalHandle": "gkrellm",
    "scalableGroupType": "APPLICATION",
    "securityGroupTag": 0,
    "state": "ACTIVE",
    "vnAgnostic": true,
    "identitySource": {
      "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
      "instanceId": 10010,
      "authEntityId": 10010,
      "displayName": "10010",
      "authEntityClass": 1738953278,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "state": "INACTIVE",
      "type": "NBAR"
    },
    "indicativeNetworkIdentity": [
      {
        "id": "2b673229-1690-4e38-9e5e-8582f9e98009",
        "instanceId": 13212,
        "authEntityId": 13212,
        "displayName": "13212",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "19150",
        "protocol": "TCP",
        "upperPort": 0
      }
    ],
    "networkApplications": [
      {
        "id": "69783b2b-f2de-44b3-ad3e-d7593df9ea96",
        "instanceId": 11436,
        "authEntityId": 11436,
        "displayName": "11436",
        "authEntityClass": -217092956,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "appProtocol": "tcp",
        "applicationGroup": "other",
        "applicationType": "DEFAULT",
        "categoryId": "7e19fdea-6222-4c57-9e66-66c2340d6bc3",
        "createTime": 1517966742789,
        "encrypted": "false",
        "engineId": "3",
        "helpString": "Monitoring program",
        "ignoreConflict": false,
        "lastUpdateTime": 1517966742789,
        "longDescription": "GNU Krell Monitors (GKrellM) is a computer program based on the GTK+ toolkit that creates a single process stack of system monitors. It can be used to monitor the status of CPUs, main memory, hard disks, network interfaces, local and remote mailboxes, and many other things. Plugins are available for a multitude of tasks, for example, controlling the XMMS media player or a SETI@home client from within the stacked monitor. GKrellM is popular among users of Linux and other Unix-like operating systems.",
        "name": "gkrellm",
        "nbarId": "1344",
        "p2pTechnology": "false",
        "popularity": 1,
        "rank": 65535,
        "references": "http://www.gkrellm.net/",
        "selectorId": "19150",
        "subCategory": "f3c0f761-d894-4112-ab89-b90fc84fd660",
        "trafficClass": "OPS_ADMIN_MGMT",
        "tunnel": "false"
      }
    ],
    "networkIdentity": [
      {
        "id": "cc8f748e-36ad-413b-b3cb-0ce85808e9f4",
        "instanceId": 13211,
        "authEntityId": 13211,
        "displayName": "13211",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "19150",
        "protocol": "TCP",
        "upperPort": 0
      }
    ],
    "parentScalableGroup": {
      "idRef": "5fcd74d4-8726-47f7-a088-c457863da7ef"
    },
    "user": [

    ],
    "userGroup": [

    ]
  },
  {
    "id": "2cba818b-77c6-49fe-b4bd-9af1e132736f",
    "instanceId": 15043,
    "authEntityId": 15043,
    "displayName": "15043",
    "authEntityClass": -1909073334,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "createTime": 1517966744097,
    "deployed": false,
    "isSeeded": true,
    "isStale": false,
    "lastUpdateTime": 1517966744097,
    "name": "rescap",
    "namespace": "scalablegroup:application",
    "provisioningState": "DEFINED",
    "qualifier": "application",
    "resourceVersion": 0,
    "targetIdList": [

    ],
    "type": "scalablegroup",
    "cfsChangeInfo": [

    ],
    "customProvisions": [

    ],
    "scalableGroupExternalHandle": "rescap",
    "scalableGroupType": "APPLICATION",
    "securityGroupTag": 0,
    "state": "ACTIVE",
    "vnAgnostic": true,
    "identitySource": {
      "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
      "instanceId": 10010,
      "authEntityId": 10010,
      "displayName": "10010",
      "authEntityClass": 1738953278,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "state": "INACTIVE",
      "type": "NBAR"
    },
    "indicativeNetworkIdentity": [
      {
        "id": "4639eeb6-096e-46b2-8e6b-a034c4c3f039",
        "instanceId": 14761,
        "authEntityId": 14761,
        "displayName": "14761",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "283",
        "protocol": "UDP",
        "upperPort": 0
      },
      {
        "id": "88fd4036-2542-4fc0-8d20-5abcf4a45b70",
        "instanceId": 14762,
        "authEntityId": 14762,
        "displayName": "14762",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "283",
        "protocol": "TCP",
        "upperPort": 0
      }
    ],
    "networkApplications": [
      {
        "id": "f10f8c08-c98f-4806-953a-0de295f27fac",
        "instanceId": 11982,
        "authEntityId": 11982,
        "displayName": "11982",
        "authEntityClass": -217092956,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "appProtocol": "tcp/udp",
        "applicationGroup": "other",
        "applicationType": "DEFAULT",
        "categoryId": "1fc17362-9334-4c7b-b752-5afdbb574017",
        "createTime": 1517966744097,
        "encrypted": "false",
        "engineId": "3",
        "helpString": "rescap",
        "ignoreConflict": false,
        "lastUpdateTime": 1517966744097,
        "longDescription": "The rescap Resolution protocol is a general client-server resolution protocol that translates resource identifiers to a list of attributes. A rescap client can ask a rescap server for the attributes of a particular mail user. Rescap is very lightweight and acts only as a resolution protocol, not a directory service.",
        "name": "rescap",
        "nbarId": "1144",
        "p2pTechnology": "false",
        "popularity": 1,
        "rank": 65535,
        "references": "http://tools.ietf.org/html/draft-ietf-rescap-proto-main-01",
        "selectorId": "283",
        "subCategory": "f3c0f761-d894-4112-ab89-b90fc84fd660",
        "trafficClass": "OPS_ADMIN_MGMT",
        "tunnel": "false"
      }
    ],
    "networkIdentity": [
      {
        "id": "ead8bd6c-997c-4f28-9385-70096e348b52",
        "instanceId": 14759,
        "authEntityId": 14759,
        "displayName": "14759",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "283",
        "protocol": "UDP",
        "upperPort": 0
      },
      {
        "id": "a003d35e-5456-4a32-8fad-5bd2c447a54f",
        "instanceId": 14760,
        "authEntityId": 14760,
        "displayName": "14760",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "283",
        "protocol": "TCP",
        "upperPort": 0
      }
    ],
    "parentScalableGroup": {
      "idRef": "5fcd74d4-8726-47f7-a088-c457863da7ef"
    },
    "user": [

    ],
    "userGroup": [

    ]
  },
  {
    "id": "2d5112a2-340e-4bc4-988c-ae57eaa81cb0",
    "instanceId": 4097,
    "authEntityId": 4097,
    "displayName": "4097",
    "authEntityClass": -1909073334,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "createTime": 1517966741061,
    "deployed": false,
    "isSeeded": true,
    "isStale": false,
    "lastUpdateTime": 1517966741061,
    "name": "aol-protocol",
    "namespace": "scalablegroup:application",
    "provisioningState": "DEFINED",
    "qualifier": "application",
    "resourceVersion": 0,
    "targetIdList": [

    ],
    "type": "scalablegroup",
    "cfsChangeInfo": [

    ],
    "customProvisions": [

    ],
    "scalableGroupExternalHandle": "aol-protocol",
    "scalableGroupType": "APPLICATION",
    "securityGroupTag": 0,
    "state": "ACTIVE",
    "vnAgnostic": true,
    "identitySource": {
      "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
      "instanceId": 10010,
      "authEntityId": 10010,
      "displayName": "10010",
      "authEntityClass": 1738953278,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "state": "INACTIVE",
      "type": "NBAR"
    },
    "indicativeNetworkIdentity": [
      {
        "id": "1f29a109-82bb-42ce-9bb9-6fec81714ff3",
        "instanceId": 12153,
        "authEntityId": 12153,
        "displayName": "12153",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "5190",
        "protocol": "TCP",
        "upperPort": 0
      }
    ],
    "networkApplications": [
      {
        "id": "8a229a3f-c4e3-4d18-877e-95c26513e3b4",
        "instanceId": 11061,
        "authEntityId": 11061,
        "displayName": "11061",
        "authEntityClass": -217092956,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "appProtocol": "tcp",
        "applicationGroup": "aol-group",
        "applicationType": "DEFAULT",
        "categoryId": "0f6cab47-be95-4124-9db3-360ccd55f983",
        "createTime": 1517966741061,
        "encrypted": "false",
        "engineId": "3",
        "helpString": "America OnLine software suite",
        "ignoreConflict": false,
        "lastUpdateTime": 1517966741061,
        "longDescription": "AOL-Protocol (also known as OSCAR) is an underlying protocol used in AIM and ICQ.",
        "name": "aol-protocol",
        "nbarId": "1224",
        "p2pTechnology": "false",
        "popularity": 6,
        "rank": 65535,
        "references": "http://daol.aol.com/software/aoldesktop97",
        "selectorId": "5190",
        "subCategory": "118c10a2-e272-47c1-92b9-4814ebe97138",
        "trafficClass": "MULTIMEDIA_CONFERENCING",
        "tunnel": "false"
      }
    ],
    "networkIdentity": [
      {
        "id": "80e9334a-19ca-41a0-8cf0-e07901a85fd2",
        "instanceId": 12152,
        "authEntityId": 12152,
        "displayName": "12152",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "5190",
        "protocol": "TCP",
        "upperPort": 0
      }
    ],
    "parentScalableGroup": {
      "idRef": "fd6264c9-c16a-4311-a335-a3db039256f3"
    },
    "user": [

    ],
    "userGroup": [

    ]
  },
  {
    "id": "2d6c638b-e12e-4461-b3f4-8d26199fef2f",
    "instanceId": 4893,
    "authEntityId": 4893,
    "displayName": "4893",
    "authEntityClass": -1909073334,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "createTime": 1517966743698,
    "deployed": false,
    "isSeeded": true,
    "isStale": false,
    "lastUpdateTime": 1517966743698,
    "name": "nytimes",
    "namespace": "scalablegroup:application",
    "provisioningState": "DEFINED",
    "qualifier": "application",
    "resourceVersion": 0,
    "targetIdList": [

    ],
    "type": "scalablegroup",
    "cfsChangeInfo": [

    ],
    "customProvisions": [

    ],
    "scalableGroupExternalHandle": "nytimes",
    "scalableGroupType": "APPLICATION",
    "securityGroupTag": 0,
    "state": "ACTIVE",
    "vnAgnostic": true,
    "identitySource": {
      "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
      "instanceId": 10010,
      "authEntityId": 10010,
      "displayName": "10010",
      "authEntityClass": 1738953278,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "state": "INACTIVE",
      "type": "NBAR"
    },
    "indicativeNetworkIdentity": [

    ],
    "networkApplications": [
      {
        "id": "96bd699f-b834-4cbc-94fd-70ceff43d0e1",
        "instanceId": 11842,
        "authEntityId": 11842,
        "displayName": "11842",
        "authEntityClass": -217092956,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "appProtocol": "tcp/udp",
        "applicationGroup": "other",
        "applicationType": "DEFAULT",
        "categoryId": "878644b5-fe91-45b4-9204-31c84a73ba48",
        "createTime": 1517966743698,
        "encrypted": "false",
        "engineId": "13",
        "helpString": "The New York Times - Newspaper website.",
        "ignoreConflict": false,
        "lastUpdateTime": 1517966743698,
        "longDescription": "The New York Times - Newspaper website.",
        "name": "nytimes",
        "nbarId": "1558",
        "p2pTechnology": "false",
        "popularity": 3,
        "rank": 65535,
        "references": "http://nyt.com",
        "selectorId": "629",
        "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
        "trafficClass": "TRANSACTIONAL_DATA",
        "tunnel": "false"
      }
    ],
    "networkIdentity": [
      {
        "id": "3aa8aa9d-d68e-40b9-815c-86d9d44f3c89",
        "instanceId": 14329,
        "authEntityId": 14329,
        "displayName": "14329",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "53,5353",
        "protocol": "UDP",
        "upperPort": 0
      },
      {
        "id": "b924fa88-6b76-40ff-8b6b-9adafacc9ca7",
        "instanceId": 14330,
        "authEntityId": 14330,
        "displayName": "14330",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "53,80,443,5353",
        "protocol": "TCP",
        "upperPort": 0
      }
    ],
    "parentScalableGroup": {
      "idRef": "28e08de4-d7b2-4521-84ce-bc1e5ee0b9cc"
    },
    "user": [

    ],
    "userGroup": [

    ]
  },
  {
    "id": "2d6f4f95-59c6-4b36-bc92-68e514d865cc",
    "instanceId": 4592,
    "authEntityId": 4592,
    "displayName": "4592",
    "authEntityClass": -1909073334,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "createTime": 1517966743071,
    "deployed": false,
    "isSeeded": true,
    "isStale": false,
    "lastUpdateTime": 1517966743071,
    "name": "igrp",
    "namespace": "scalablegroup:application",
    "provisioningState": "DEFINED",
    "qualifier": "application",
    "resourceVersion": 0,
    "targetIdList": [

    ],
    "type": "scalablegroup",
    "cfsChangeInfo": [

    ],
    "customProvisions": [

    ],
    "scalableGroupExternalHandle": "igrp",
    "scalableGroupType": "APPLICATION",
    "securityGroupTag": 0,
    "state": "ACTIVE",
    "vnAgnostic": true,
    "identitySource": {
      "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
      "instanceId": 10010,
      "authEntityId": 10010,
      "displayName": "10010",
      "authEntityClass": 1738953278,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "state": "INACTIVE",
      "type": "NBAR"
    },
    "indicativeNetworkIdentity": [
      {
        "id": "92feeb59-59f2-446d-b7a1-038249b7147e",
        "instanceId": 13490,
        "authEntityId": 13490,
        "displayName": "13490",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipProtocolNumbers": "9",
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "protocol": "IGRP",
        "upperPort": 0
      }
    ],
    "networkApplications": [
      {
        "id": "8ce174a3-e5c8-462f-b8c4-5ccdbce413b8",
        "instanceId": 11542,
        "authEntityId": 11542,
        "displayName": "11542",
        "authEntityClass": -217092956,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "appProtocol": "ip",
        "applicationGroup": "other",
        "applicationType": "DEFAULT",
        "categoryId": "1fc17362-9334-4c7b-b752-5afdbb574017",
        "createTime": 1517966743071,
        "encrypted": "unassigned",
        "engineId": "1",
        "helpString": "Cisco interior gateway ",
        "ignoreConflict": false,
        "lastUpdateTime": 1517966743071,
        "longDescription": "Interior Gateway Routing Protocol (IGRP) is a distance vector interior routing protocol (IGP) invented by Cisco. It is used by routers to exchange routing data within an autonomous system. IGRP is a proprietary protocol. IGRP supports multiple metrics for each route, including bandwidth, delay, load, MTU, and reliability. IGRP is considered a classful routing protocol.",
        "name": "igrp",
        "nbarId": "764",
        "p2pTechnology": "unassigned",
        "popularity": 5,
        "rank": 65535,
        "references": "http://www.cisco.com/en/US/tech/tk365/technologies_white_paper09186a00800c8ae1.shtml",
        "selectorId": "9",
        "subCategory": "7cc1b3a6-4102-44e9-b522-0e71afe39a03",
        "trafficClass": "NETWORK_CONTROL",
        "tunnel": "unassigned"
      }
    ],
    "networkIdentity": [
      {
        "id": "8b4bce4e-0ab9-4565-8d99-e40da20ddd72",
        "instanceId": 13489,
        "authEntityId": 13489,
        "displayName": "13489",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipProtocolNumbers": "9",
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "protocol": "IGRP",
        "upperPort": 0
      }
    ],
    "parentScalableGroup": {
      "idRef": "77e75842-e98b-4112-ad0c-300db4bb11be"
    },
    "user": [

    ],
    "userGroup": [

    ]
  },
  {
    "id": "2d73d1b7-df46-466c-816e-66a5bf81a14a",
    "instanceId": 4158,
    "authEntityId": 4158,
    "displayName": "4158",
    "authEntityClass": -1909073334,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "createTime": 1517966741435,
    "deployed": false,
    "isSeeded": true,
    "isStale": false,
    "lastUpdateTime": 1517966741435,
    "name": "bacnet",
    "namespace": "scalablegroup:application",
    "provisioningState": "DEFINED",
    "qualifier": "application",
    "resourceVersion": 0,
    "targetIdList": [

    ],
    "type": "scalablegroup",
    "cfsChangeInfo": [

    ],
    "customProvisions": [

    ],
    "scalableGroupExternalHandle": "bacnet",
    "scalableGroupType": "APPLICATION",
    "securityGroupTag": 0,
    "state": "ACTIVE",
    "vnAgnostic": true,
    "identitySource": {
      "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
      "instanceId": 10010,
      "authEntityId": 10010,
      "displayName": "10010",
      "authEntityClass": 1738953278,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "state": "INACTIVE",
      "type": "NBAR"
    },
    "indicativeNetworkIdentity": [
      {
        "id": "65c8f48c-6bd3-4cfd-a97a-58902a525c77",
        "instanceId": 12326,
        "authEntityId": 12326,
        "displayName": "12326",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "47808",
        "protocol": "UDP",
        "upperPort": 0
      },
      {
        "id": "77314877-638d-468e-9833-02a307bf35d4",
        "instanceId": 12327,
        "authEntityId": 12327,
        "displayName": "12327",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "47808",
        "protocol": "TCP",
        "upperPort": 0
      }
    ],
    "networkApplications": [
      {
        "id": "41ac4001-3819-4429-9ac6-621741598e88",
        "instanceId": 11118,
        "authEntityId": 11118,
        "displayName": "11118",
        "authEntityClass": -217092956,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "appProtocol": "tcp/udp",
        "applicationGroup": "other",
        "applicationType": "DEFAULT",
        "categoryId": "7e19fdea-6222-4c57-9e66-66c2340d6bc3",
        "createTime": 1517966741435,
        "encrypted": "false",
        "engineId": "3",
        "helpString": "Building Automation and Control Networks",
        "ignoreConflict": false,
        "lastUpdateTime": 1517966741435,
        "longDescription": "Building Automation and Control Networks (BACnet) is a communications protocol designed to allow communication of building automation and control systems for applications such as heating, ventilating, air-conditioning control, lighting control, access control, and fire detection systems and their associated equipment.",
        "name": "bacnet",
        "nbarId": "1330",
        "p2pTechnology": "false",
        "popularity": 1,
        "rank": 65535,
        "references": "http://www.bacnet.org/",
        "selectorId": "47808",
        "subCategory": "f3c0f761-d894-4112-ab89-b90fc84fd660",
        "trafficClass": "OPS_ADMIN_MGMT",
        "tunnel": "false"
      }
    ],
    "networkIdentity": [
      {
        "id": "b063f4ca-707a-418d-897d-e0336a778835",
        "instanceId": 12324,
        "authEntityId": 12324,
        "displayName": "12324",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "47808",
        "protocol": "UDP",
        "upperPort": 0
      },
      {
        "id": "2bd3ba47-5f68-4d3f-a52b-00886cb98083",
        "instanceId": 12325,
        "authEntityId": 12325,
        "displayName": "12325",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "47808",
        "protocol": "TCP",
        "upperPort": 0
      }
    ],
    "parentScalableGroup": {
      "idRef": "5fcd74d4-8726-47f7-a088-c457863da7ef"
    },
    "user": [

    ],
    "userGroup": [

    ]
  },
  {
    "id": "2d772f4b-4622-4811-ae14-b2eb5f04610d",
    "instanceId": 15291,
    "authEntityId": 15291,
    "displayName": "15291",
    "authEntityClass": -1909073334,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "createTime": 1517966744669,
    "deployed": false,
    "isSeeded": true,
    "isStale": false,
    "lastUpdateTime": 1517966744669,
    "name": "tlisrv",
    "namespace": "scalablegroup:application",
    "provisioningState": "DEFINED",
    "qualifier": "application",
    "resourceVersion": 0,
    "targetIdList": [

    ],
    "type": "scalablegroup",
    "cfsChangeInfo": [

    ],
    "customProvisions": [

    ],
    "scalableGroupExternalHandle": "tlisrv",
    "scalableGroupType": "APPLICATION",
    "securityGroupTag": 0,
    "state": "ACTIVE",
    "vnAgnostic": true,
    "identitySource": {
      "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
      "instanceId": 10010,
      "authEntityId": 10010,
      "displayName": "10010",
      "authEntityClass": 1738953278,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "state": "INACTIVE",
      "type": "NBAR"
    },
    "indicativeNetworkIdentity": [
      {
        "id": "7ac6443c-80a3-43e4-ad89-6e2179a63336",
        "instanceId": 17504,
        "authEntityId": 17504,
        "displayName": "17504",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "1527",
        "protocol": "UDP",
        "upperPort": 0
      },
      {
        "id": "5502cfcd-af58-4315-99cf-5ed2545ae1a5",
        "instanceId": 17503,
        "authEntityId": 17503,
        "displayName": "17503",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "1527",
        "protocol": "TCP",
        "upperPort": 0
      }
    ],
    "networkApplications": [
      {
        "id": "bef915d7-4a1a-4275-9697-f66040b5e3cb",
        "instanceId": 16234,
        "authEntityId": 16234,
        "displayName": "16234",
        "authEntityClass": -217092956,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "appProtocol": "tcp/udp",
        "applicationGroup": "sqlsvr-group",
        "applicationType": "DEFAULT",
        "categoryId": "cb5915d1-afec-4c30-ac9e-069ff382272f",
        "createTime": 1517966744669,
        "encrypted": "false",
        "engineId": "3",
        "helpString": "oracle",
        "ignoreConflict": false,
        "lastUpdateTime": 1517966744669,
        "longDescription": "Registered with IANA on port 1527 TCP/UDP",
        "name": "tlisrv",
        "nbarId": "692",
        "p2pTechnology": "false",
        "popularity": 1,
        "rank": 65535,
        "references": "http://www.iana.org/assignments/service-names-port-numbers/service-names-port-numbers.xml",
        "selectorId": "1527",
        "subCategory": "b90786f4-0190-45b8-9924-21b3e9682590",
        "trafficClass": "TRANSACTIONAL_DATA",
        "tunnel": "false"
      }
    ],
    "networkIdentity": [
      {
        "id": "2e29aafd-d486-4730-b5b5-d7ca930329ca",
        "instanceId": 17501,
        "authEntityId": 17501,
        "displayName": "17501",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "1527",
        "protocol": "TCP",
        "upperPort": 0
      },
      {
        "id": "45be49cd-a58d-430c-8087-caf97f060738",
        "instanceId": 17502,
        "authEntityId": 17502,
        "displayName": "17502",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "1527",
        "protocol": "UDP",
        "upperPort": 0
      }
    ],
    "parentScalableGroup": {
      "idRef": "8718395e-683e-4a2d-a232-100202751271"
    },
    "user": [

    ],
    "userGroup": [

    ]
  },
  {
    "id": "2dbe0bda-99a3-4a75-a585-e9e94836cc37",
    "instanceId": 4077,
    "authEntityId": 4077,
    "displayName": "4077",
    "authEntityClass": -1909073334,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "createTime": 1517966740863,
    "deployed": false,
    "isSeeded": true,
    "isStale": false,
    "lastUpdateTime": 1517966740863,
    "name": "amazon-instant-video",
    "namespace": "scalablegroup:application",
    "provisioningState": "DEFINED",
    "qualifier": "application",
    "resourceVersion": 0,
    "targetIdList": [

    ],
    "type": "scalablegroup",
    "cfsChangeInfo": [

    ],
    "customProvisions": [

    ],
    "scalableGroupExternalHandle": "amazon-instant-video",
    "scalableGroupType": "APPLICATION",
    "securityGroupTag": 0,
    "state": "ACTIVE",
    "vnAgnostic": true,
    "identitySource": {
      "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
      "instanceId": 10010,
      "authEntityId": 10010,
      "displayName": "10010",
      "authEntityClass": 1738953278,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "state": "INACTIVE",
      "type": "NBAR"
    },
    "indicativeNetworkIdentity": [

    ],
    "networkApplications": [
      {
        "id": "a2e71387-2866-48e6-a8d1-9a0b3d8f31b3",
        "instanceId": 11044,
        "authEntityId": 11044,
        "displayName": "11044",
        "authEntityClass": -217092956,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "appProtocol": "tcp/udp",
        "applicationGroup": "other",
        "applicationType": "DEFAULT",
        "categoryId": "82a7b7fa-da5a-48f5-8b6a-a2546b947843",
        "createTime": 1517966740863,
        "encrypted": "false",
        "engineId": "13",
        "helpString": "VOD service by Amazon",
        "ignoreConflict": false,
        "lastUpdateTime": 1517966740863,
        "longDescription": "Amazon instant video is a VOD service by Amazon, which offers television shows and films for rental and purchase. The service is available only in the US, UK, Germany and Japan.",
        "name": "amazon-instant-video",
        "nbarId": "1541",
        "p2pTechnology": "false",
        "popularity": 9,
        "rank": 65535,
        "references": "http://www.amazon.com/Instant-Video/b?node=2858778011",
        "selectorId": "602",
        "subCategory": "85a797c0-aef8-4284-91a7-677f33e29abb",
        "trafficClass": "MULTIMEDIA_STREAMING",
        "tunnel": "false"
      }
    ],
    "networkIdentity": [
      {
        "id": "e571e4b4-7551-4f8c-b29a-6c39810e2907",
        "instanceId": 12110,
        "authEntityId": 12110,
        "displayName": "12110",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "53,80,5353",
        "protocol": "TCP",
        "upperPort": 0
      },
      {
        "id": "aaa35e75-8bb8-4105-9d73-51f63ac84455",
        "instanceId": 12111,
        "authEntityId": 12111,
        "displayName": "12111",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "53,5353",
        "protocol": "UDP",
        "upperPort": 0
      }
    ],
    "parentScalableGroup": {
      "idRef": "fd6264c9-c16a-4311-a335-a3db039256f3"
    },
    "user": [

    ],
    "userGroup": [

    ]
  },
  {
    "id": "2e15f0b4-8754-41be-b647-51f3e9779b68",
    "instanceId": 15298,
    "authEntityId": 15298,
    "displayName": "15298",
    "authEntityClass": -1909073334,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "createTime": 1517966744690,
    "deployed": false,
    "isSeeded": true,
    "isStale": false,
    "lastUpdateTime": 1517966744690,
    "name": "tor",
    "namespace": "scalablegroup:application",
    "provisioningState": "DEFINED",
    "qualifier": "application",
    "resourceVersion": 0,
    "targetIdList": [

    ],
    "type": "scalablegroup",
    "cfsChangeInfo": [

    ],
    "customProvisions": [

    ],
    "scalableGroupExternalHandle": "tor",
    "scalableGroupType": "APPLICATION",
    "securityGroupTag": 0,
    "state": "ACTIVE",
    "vnAgnostic": true,
    "identitySource": {
      "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
      "instanceId": 10010,
      "authEntityId": 10010,
      "displayName": "10010",
      "authEntityClass": 1738953278,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "state": "INACTIVE",
      "type": "NBAR"
    },
    "indicativeNetworkIdentity": [

    ],
    "networkApplications": [
      {
        "id": "8be5ad8d-a75e-49fa-9efa-81a1f2954358",
        "instanceId": 16241,
        "authEntityId": 16241,
        "displayName": "16241",
        "authEntityClass": -217092956,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "appProtocol": "tcp",
        "applicationGroup": "other",
        "applicationType": "DEFAULT",
        "categoryId": "cf6b10c2-40a5-4a1c-857c-4b6e0caae271",
        "createTime": 1517966744690,
        "encrypted": "true",
        "engineId": "13",
        "helpString": "The Onion Routing Protocol",
        "ignoreConflict": false,
        "lastUpdateTime": 1517966744690,
        "longDescription": "Tor (The Onion Router) is a system intended to enable online anonymity. Tor client software routes Internet traffic through a worldwide volunteer network of servers in order to conceal a user's location or usage from someone conducting network surveillance or traffic analysis. The software is \"free-software\" and the network is free of charge to use.",
        "name": "tor",
        "nbarId": "1319",
        "p2pTechnology": "true",
        "popularity": 7,
        "rank": 65535,
        "references": "http://www.torproject.org/",
        "selectorId": "460",
        "subCategory": "61b315f5-5e87-4de0-bc1a-5d084ea0b28d",
        "trafficClass": "BULK_DATA",
        "tunnel": "false"
      }
    ],
    "networkIdentity": [
      {
        "id": "c2a13ef4-fd9c-4e62-95cd-469348413822",
        "instanceId": 17521,
        "authEntityId": 17521,
        "displayName": "17521",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "80,443",
        "protocol": "TCP",
        "upperPort": 0
      }
    ],
    "parentScalableGroup": {
      "idRef": "5e66d25d-d517-482b-82a8-6f99f07fa786"
    },
    "user": [

    ],
    "userGroup": [

    ]
  },
  {
    "id": "2e3f7a62-665e-4a4d-82a4-1fcf04398381",
    "instanceId": 4922,
    "authEntityId": 4922,
    "displayName": "4922",
    "authEntityClass": -1909073334,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "createTime": 1517966743772,
    "deployed": false,
    "isSeeded": true,
    "isStale": false,
    "lastUpdateTime": 1517966743772,
    "name": "oraclenet8cman",
    "namespace": "scalablegroup:application",
    "provisioningState": "DEFINED",
    "qualifier": "application",
    "resourceVersion": 0,
    "targetIdList": [

    ],
    "type": "scalablegroup",
    "cfsChangeInfo": [

    ],
    "customProvisions": [

    ],
    "scalableGroupExternalHandle": "oraclenet8cman",
    "scalableGroupType": "APPLICATION",
    "securityGroupTag": 0,
    "state": "ACTIVE",
    "vnAgnostic": true,
    "identitySource": {
      "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
      "instanceId": 10010,
      "authEntityId": 10010,
      "displayName": "10010",
      "authEntityClass": 1738953278,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "state": "INACTIVE",
      "type": "NBAR"
    },
    "indicativeNetworkIdentity": [
      {
        "id": "d2e1ed5f-01db-473e-8913-667b84b9cbd0",
        "instanceId": 14432,
        "authEntityId": 14432,
        "displayName": "14432",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "1630",
        "protocol": "UDP",
        "upperPort": 0
      },
      {
        "id": "018de4ce-21de-4de1-afbe-a87e5e09050b",
        "instanceId": 14431,
        "authEntityId": 14431,
        "displayName": "14431",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "1630",
        "protocol": "TCP",
        "upperPort": 0
      }
    ],
    "networkApplications": [
      {
        "id": "5e524b18-3e51-41df-b299-59dffbd555f6",
        "instanceId": 11871,
        "authEntityId": 11871,
        "displayName": "11871",
        "authEntityClass": -217092956,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "appProtocol": "tcp/udp",
        "applicationGroup": "sqlsvr-group",
        "applicationType": "DEFAULT",
        "categoryId": "cb5915d1-afec-4c30-ac9e-069ff382272f",
        "createTime": 1517966743772,
        "encrypted": "false",
        "engineId": "3",
        "helpString": "Oracle Net8 Cman",
        "ignoreConflict": false,
        "lastUpdateTime": 1517966743772,
        "longDescription": "Oracle Connection Manager, a Net8 component that acts much like a router and provides protocol conversion, connection concentration, and access control.",
        "name": "oraclenet8cman",
        "nbarId": "696",
        "p2pTechnology": "false",
        "popularity": 1,
        "rank": 65535,
        "references": "http://dl.acm.org/citation.cfm?id=557584",
        "selectorId": "1630",
        "subCategory": "b90786f4-0190-45b8-9924-21b3e9682590",
        "trafficClass": "TRANSACTIONAL_DATA",
        "tunnel": "false"
      }
    ],
    "networkIdentity": [
      {
        "id": "73e5ca9f-509e-4df9-9412-206ecc7ed0d2",
        "instanceId": 14429,
        "authEntityId": 14429,
        "displayName": "14429",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "1630",
        "protocol": "TCP",
        "upperPort": 0
      },
      {
        "id": "7c1e7432-79f4-4aae-9abd-8d861e3b2c94",
        "instanceId": 14430,
        "authEntityId": 14430,
        "displayName": "14430",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "1630",
        "protocol": "UDP",
        "upperPort": 0
      }
    ],
    "parentScalableGroup": {
      "idRef": "8718395e-683e-4a2d-a232-100202751271"
    },
    "user": [

    ],
    "userGroup": [

    ]
  },
  {
    "id": "2e6e4532-0225-467e-b6c7-c9cb6ae0f925",
    "instanceId": 4246,
    "authEntityId": 4246,
    "displayName": "4246",
    "authEntityClass": -1909073334,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "createTime": 1517966741928,
    "deployed": false,
    "isSeeded": true,
    "isStale": false,
    "lastUpdateTime": 1517966741928,
    "name": "cisco-phone-audio",
    "namespace": "scalablegroup:application",
    "provisioningState": "DEFINED",
    "qualifier": "application",
    "resourceVersion": 0,
    "targetIdList": [

    ],
    "type": "scalablegroup",
    "cfsChangeInfo": [

    ],
    "customProvisions": [

    ],
    "scalableGroupExternalHandle": "cisco-phone-audio",
    "scalableGroupType": "APPLICATION",
    "securityGroupTag": 0,
    "state": "ACTIVE",
    "vnAgnostic": true,
    "identitySource": {
      "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
      "instanceId": 10010,
      "authEntityId": 10010,
      "displayName": "10010",
      "authEntityClass": 1738953278,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "state": "INACTIVE",
      "type": "NBAR"
    },
    "indicativeNetworkIdentity": [

    ],
    "networkApplications": [
      {
        "id": "8d78380f-8c41-45b2-8268-01a6738e1861",
        "instanceId": 11200,
        "authEntityId": 11200,
        "displayName": "11200",
        "authEntityClass": -217092956,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "appProtocol": "tcp/udp",
        "applicationGroup": "cisco-phone-group",
        "applicationType": "DEFAULT",
        "categoryId": "38a8e354-e2fe-4f3c-b1c5-2bbb90fb0d99",
        "createTime": 1517966741928,
        "encrypted": "false",
        "engineId": "13",
        "helpString": "Cisco IP Phones and PC-based Unified Communicators; Audio Calls",
        "ignoreConflict": false,
        "lastUpdateTime": 1517966741928,
        "longDescription": "Cisco-ip-phone is a VoIP telephone used mainly in corporations and can be used on or off site. Cisco Phone Audio is the audio part of Cisco-Phone calls when the signaling is over SIP and the media is over RTP.",
        "name": "cisco-phone-audio",
        "nbarId": "1551",
        "p2pTechnology": "false",
        "popularity": 8,
        "rank": 65535,
        "references": "http://www.cisco.com/en/US/prod/collateral/voicesw/ps6788/phones/ps379/ps1854/product_data_sheet09186a008008884a.html",
        "selectorId": "612",
        "subCategory": "53ce0342-1e2d-455a-acc7-1b50db9d6845",
        "trafficClass": "VOIP_TELEPHONY",
        "tunnel": "false"
      }
    ],
    "networkIdentity": [
      {
        "id": "7000f00f-6ef8-4a79-b88c-d54a8557522f",
        "instanceId": 12528,
        "authEntityId": 12528,
        "displayName": "12528",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "5060",
        "protocol": "UDP",
        "upperPort": 0
      },
      {
        "id": "44d52519-90b7-44d0-911f-75bfa50d2312",
        "instanceId": 12529,
        "authEntityId": 12529,
        "displayName": "12529",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "2000,2001,2002,5060",
        "protocol": "TCP",
        "upperPort": 0
      }
    ],
    "parentScalableGroup": {
      "idRef": "60f1364d-2a02-4063-8ba1-07817abe0612"
    },
    "user": [

    ],
    "userGroup": [

    ]
  },
  {
    "id": "2ea84533-9ba0-43a1-96bd-3219dcfb055d",
    "instanceId": 4735,
    "authEntityId": 4735,
    "displayName": "4735",
    "authEntityClass": -1909073334,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "createTime": 1517966743388,
    "deployed": false,
    "isSeeded": true,
    "isStale": false,
    "lastUpdateTime": 1517966743388,
    "name": "meregister",
    "namespace": "scalablegroup:application",
    "provisioningState": "DEFINED",
    "qualifier": "application",
    "resourceVersion": 0,
    "targetIdList": [

    ],
    "type": "scalablegroup",
    "cfsChangeInfo": [

    ],
    "customProvisions": [

    ],
    "scalableGroupExternalHandle": "meregister",
    "scalableGroupType": "APPLICATION",
    "securityGroupTag": 0,
    "state": "ACTIVE",
    "vnAgnostic": true,
    "identitySource": {
      "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
      "instanceId": 10010,
      "authEntityId": 10010,
      "displayName": "10010",
      "authEntityClass": 1738953278,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "state": "INACTIVE",
      "type": "NBAR"
    },
    "indicativeNetworkIdentity": [
      {
        "id": "a9a4dd2c-e4f9-431e-9555-bcd1ca6c33ec",
        "instanceId": 13864,
        "authEntityId": 13864,
        "displayName": "13864",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "669",
        "protocol": "TCP",
        "upperPort": 0
      },
      {
        "id": "9b06178f-73cf-4e1c-872f-9b0844d4244c",
        "instanceId": 13865,
        "authEntityId": 13865,
        "displayName": "13865",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "669",
        "protocol": "UDP",
        "upperPort": 0
      }
    ],
    "networkApplications": [
      {
        "id": "a45921a7-5426-4f64-aace-72639d2cac3b",
        "instanceId": 11685,
        "authEntityId": 11685,
        "displayName": "11685",
        "authEntityClass": -217092956,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "appProtocol": "tcp/udp",
        "applicationGroup": "other",
        "applicationType": "DEFAULT",
        "categoryId": "6daa3dfd-3526-407a-88f0-97824b3f3a24",
        "createTime": 1517966743388,
        "encrypted": "false",
        "engineId": "3",
        "helpString": "meregister",
        "ignoreConflict": false,
        "lastUpdateTime": 1517966743388,
        "longDescription": "Registered with IANA on port 669 TCP/UDP",
        "name": "meregister",
        "nbarId": "577",
        "p2pTechnology": "false",
        "popularity": 1,
        "rank": 65535,
        "references": "http://www.iana.org/assignments/service-names-port-numbers/service-names-port-numbers.xml",
        "selectorId": "669",
        "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
        "trafficClass": "BULK_DATA",
        "tunnel": "false"
      }
    ],
    "networkIdentity": [
      {
        "id": "febe001e-230a-46c7-a661-dbe9060a851c",
        "instanceId": 13862,
        "authEntityId": 13862,
        "displayName": "13862",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "669",
        "protocol": "UDP",
        "upperPort": 0
      },
      {
        "id": "3f08b0a3-ccc7-4d04-9136-75a8090d6a12",
        "instanceId": 13863,
        "authEntityId": 13863,
        "displayName": "13863",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "669",
        "protocol": "TCP",
        "upperPort": 0
      }
    ],
    "parentScalableGroup": {
      "idRef": "000c15bf-28fe-4fbb-9b69-35e6502721f1"
    },
    "user": [

    ],
    "userGroup": [

    ]
  },
  {
    "id": "2f2b84ed-d9e9-4ef3-9af8-9a04b4239a70",
    "instanceId": 15044,
    "authEntityId": 15044,
    "displayName": "15044",
    "authEntityClass": -1909073334,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "createTime": 1517966744099,
    "deployed": false,
    "isSeeded": true,
    "isStale": false,
    "lastUpdateTime": 1517966744099,
    "name": "retailmenot",
    "namespace": "scalablegroup:application",
    "provisioningState": "DEFINED",
    "qualifier": "application",
    "resourceVersion": 0,
    "targetIdList": [

    ],
    "type": "scalablegroup",
    "cfsChangeInfo": [

    ],
    "customProvisions": [

    ],
    "scalableGroupExternalHandle": "retailmenot",
    "scalableGroupType": "APPLICATION",
    "securityGroupTag": 0,
    "state": "ACTIVE",
    "vnAgnostic": true,
    "identitySource": {
      "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
      "instanceId": 10010,
      "authEntityId": 10010,
      "displayName": "10010",
      "authEntityClass": 1738953278,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "state": "INACTIVE",
      "type": "NBAR"
    },
    "indicativeNetworkIdentity": [

    ],
    "networkApplications": [
      {
        "id": "4224cef9-e343-4e8a-8683-03250281f702",
        "instanceId": 11983,
        "authEntityId": 11983,
        "displayName": "11983",
        "authEntityClass": -217092956,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "appProtocol": "tcp/udp",
        "applicationGroup": "other",
        "applicationType": "DEFAULT",
        "categoryId": "878644b5-fe91-45b4-9204-31c84a73ba48",
        "createTime": 1517966744099,
        "encrypted": "false",
        "engineId": "13",
        "helpString": "RetailMeNot - Online coupon and deals.",
        "ignoreConflict": false,
        "lastUpdateTime": 1517966744099,
        "longDescription": "RetailMeNot - Online coupon and deals.",
        "name": "retailmenot",
        "nbarId": "1637",
        "p2pTechnology": "false",
        "popularity": 3,
        "rank": 65535,
        "references": "http://retailmenot.com",
        "selectorId": "707",
        "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
        "trafficClass": "TRANSACTIONAL_DATA",
        "tunnel": "false"
      }
    ],
    "networkIdentity": [
      {
        "id": "540db837-85d9-4a3a-83ef-2962a02a5f00",
        "instanceId": 14763,
        "authEntityId": 14763,
        "displayName": "14763",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "53,80,443,5353",
        "protocol": "TCP",
        "upperPort": 0
      },
      {
        "id": "65ed86c1-5ff0-428e-b29f-0ea556b65dca",
        "instanceId": 14764,
        "authEntityId": 14764,
        "displayName": "14764",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "53,5353",
        "protocol": "UDP",
        "upperPort": 0
      }
    ],
    "parentScalableGroup": {
      "idRef": "28e08de4-d7b2-4521-84ce-bc1e5ee0b9cc"
    },
    "user": [

    ],
    "userGroup": [

    ]
  },
  {
    "id": "2f350570-322a-4df5-bd07-9aaec6ee9f5f",
    "instanceId": 4918,
    "authEntityId": 4918,
    "displayName": "4918",
    "authEntityClass": -1909073334,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "createTime": 1517966743762,
    "deployed": false,
    "isSeeded": true,
    "isStale": false,
    "lastUpdateTime": 1517966743762,
    "name": "oracle-bi",
    "namespace": "scalablegroup:application",
    "provisioningState": "DEFINED",
    "qualifier": "application",
    "resourceVersion": 0,
    "targetIdList": [

    ],
    "type": "scalablegroup",
    "cfsChangeInfo": [

    ],
    "customProvisions": [

    ],
    "scalableGroupExternalHandle": "oracle-bi",
    "scalableGroupType": "APPLICATION",
    "securityGroupTag": 0,
    "state": "ACTIVE",
    "vnAgnostic": true,
    "identitySource": {
      "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
      "instanceId": 10010,
      "authEntityId": 10010,
      "displayName": "10010",
      "authEntityClass": 1738953278,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "state": "INACTIVE",
      "type": "NBAR"
    },
    "indicativeNetworkIdentity": [
      {
        "id": "df73ae22-3147-4117-af2c-503e4bdad7de",
        "instanceId": 14421,
        "authEntityId": 14421,
        "displayName": "14421",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "9703,9704",
        "protocol": "TCP",
        "upperPort": 0
      }
    ],
    "networkApplications": [
      {
        "id": "91103172-1d22-4000-9073-422703963431",
        "instanceId": 11867,
        "authEntityId": 11867,
        "displayName": "11867",
        "authEntityClass": -217092956,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "appProtocol": "tcp",
        "applicationGroup": "other",
        "applicationType": "DEFAULT",
        "categoryId": "cb5915d1-afec-4c30-ac9e-069ff382272f",
        "createTime": 1517966743762,
        "encrypted": "false",
        "engineId": "3",
        "helpString": "Oracle Business Intelligence",
        "ignoreConflict": false,
        "lastUpdateTime": 1517966743762,
        "longDescription": "Used by Oracle in their Business Intelligence products.",
        "name": "oracle-bi",
        "nbarId": "1359",
        "p2pTechnology": "false",
        "popularity": 1,
        "rank": 65535,
        "references": "http://www.oracle.com/us/solutions/business-analytics/business-intelligence/index.html",
        "selectorId": "9703",
        "subCategory": "b90786f4-0190-45b8-9924-21b3e9682590",
        "trafficClass": "BULK_DATA",
        "tunnel": "false"
      }
    ],
    "networkIdentity": [
      {
        "id": "1b5c2b5c-b607-46e4-b876-d2ede02328d3",
        "instanceId": 14420,
        "authEntityId": 14420,
        "displayName": "14420",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "9703,9704",
        "protocol": "TCP",
        "upperPort": 0
      }
    ],
    "parentScalableGroup": {
      "idRef": "8718395e-683e-4a2d-a232-100202751271"
    },
    "user": [

    ],
    "userGroup": [

    ]
  },
  {
    "id": "2f8c1677-87b6-45c0-baee-2761618733d1",
    "instanceId": 15329,
    "authEntityId": 15329,
    "displayName": "15329",
    "authEntityClass": -1909073334,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "createTime": 1517966744748,
    "deployed": false,
    "isSeeded": true,
    "isStale": false,
    "lastUpdateTime": 1517966744748,
    "name": "usa-today",
    "namespace": "scalablegroup:application",
    "provisioningState": "DEFINED",
    "qualifier": "application",
    "resourceVersion": 0,
    "targetIdList": [

    ],
    "type": "scalablegroup",
    "cfsChangeInfo": [

    ],
    "customProvisions": [

    ],
    "scalableGroupExternalHandle": "usa-today",
    "scalableGroupType": "APPLICATION",
    "securityGroupTag": 0,
    "state": "ACTIVE",
    "vnAgnostic": true,
    "identitySource": {
      "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
      "instanceId": 10010,
      "authEntityId": 10010,
      "displayName": "10010",
      "authEntityClass": 1738953278,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "state": "INACTIVE",
      "type": "NBAR"
    },
    "indicativeNetworkIdentity": [

    ],
    "networkApplications": [
      {
        "id": "0f07e777-6894-40ca-b003-ba2e2937f102",
        "instanceId": 16272,
        "authEntityId": 16272,
        "displayName": "16272",
        "authEntityClass": -217092956,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "appProtocol": "tcp/udp",
        "applicationGroup": "other",
        "applicationType": "DEFAULT",
        "categoryId": "878644b5-fe91-45b4-9204-31c84a73ba48",
        "createTime": 1517966744748,
        "encrypted": "false",
        "engineId": "13",
        "helpString": "USA Today - Website for newspaper USA Today.",
        "ignoreConflict": false,
        "lastUpdateTime": 1517966744748,
        "longDescription": "USA Today - Website for newspaper USA Today.",
        "name": "usa-today",
        "nbarId": "1609",
        "p2pTechnology": "false",
        "popularity": 3,
        "rank": 65535,
        "references": "http://usatoday.com",
        "selectorId": "679",
        "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
        "trafficClass": "TRANSACTIONAL_DATA",
        "tunnel": "false"
      }
    ],
    "networkIdentity": [
      {
        "id": "bf834414-aeaf-4418-a558-efd09a00337f",
        "instanceId": 17605,
        "authEntityId": 17605,
        "displayName": "17605",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "53,80,443,5353",
        "protocol": "TCP",
        "upperPort": 0
      },
      {
        "id": "60313645-d68c-4a18-8af2-95cf5f3ab86b",
        "instanceId": 17606,
        "authEntityId": 17606,
        "displayName": "17606",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "53,5353",
        "protocol": "UDP",
        "upperPort": 0
      }
    ],
    "parentScalableGroup": {
      "idRef": "28e08de4-d7b2-4521-84ce-bc1e5ee0b9cc"
    },
    "user": [

    ],
    "userGroup": [

    ]
  },
  {
    "id": "2fdbbda6-ae87-4656-82a6-fddcd6468553",
    "instanceId": 15283,
    "authEntityId": 15283,
    "displayName": "15283",
    "authEntityClass": -1909073334,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "createTime": 1517966744652,
    "deployed": false,
    "isSeeded": true,
    "isStale": false,
    "lastUpdateTime": 1517966744652,
    "name": "ticf-1",
    "namespace": "scalablegroup:application",
    "provisioningState": "DEFINED",
    "qualifier": "application",
    "resourceVersion": 0,
    "targetIdList": [

    ],
    "type": "scalablegroup",
    "cfsChangeInfo": [

    ],
    "customProvisions": [

    ],
    "scalableGroupExternalHandle": "ticf-1",
    "scalableGroupType": "APPLICATION",
    "securityGroupTag": 0,
    "state": "ACTIVE",
    "vnAgnostic": true,
    "identitySource": {
      "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
      "instanceId": 10010,
      "authEntityId": 10010,
      "displayName": "10010",
      "authEntityClass": 1738953278,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "state": "INACTIVE",
      "type": "NBAR"
    },
    "indicativeNetworkIdentity": [
      {
        "id": "127020c9-6b20-4d45-be29-46d5f3d5e1a7",
        "instanceId": 17475,
        "authEntityId": 17475,
        "displayName": "17475",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "492",
        "protocol": "UDP",
        "upperPort": 0
      },
      {
        "id": "3f251e42-0783-4f9e-a5de-64e9fbc07ea5",
        "instanceId": 17476,
        "authEntityId": 17476,
        "displayName": "17476",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "492",
        "protocol": "TCP",
        "upperPort": 0
      }
    ],
    "networkApplications": [
      {
        "id": "112d0109-e3bc-4079-95b0-5299f15a09f4",
        "instanceId": 16226,
        "authEntityId": 16226,
        "displayName": "16226",
        "authEntityClass": -217092956,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "appProtocol": "tcp/udp",
        "applicationGroup": "other",
        "applicationType": "DEFAULT",
        "categoryId": "6daa3dfd-3526-407a-88f0-97824b3f3a24",
        "createTime": 1517966744652,
        "encrypted": "false",
        "engineId": "3",
        "helpString": "Transport Independent Convergence for FNA",
        "ignoreConflict": false,
        "lastUpdateTime": 1517966744652,
        "longDescription": "Registered with IANA on port 492 TCP/UDP",
        "name": "ticf-1",
        "nbarId": "406",
        "p2pTechnology": "false",
        "popularity": 1,
        "rank": 65535,
        "references": "http://www.iana.org/assignments/service-names-port-numbers/service-names-port-numbers.xml",
        "selectorId": "492",
        "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
        "trafficClass": "BULK_DATA",
        "tunnel": "false"
      }
    ],
    "networkIdentity": [
      {
        "id": "dd385b89-3d38-4e19-8476-f41cd995a8dc",
        "instanceId": 17473,
        "authEntityId": 17473,
        "displayName": "17473",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "492",
        "protocol": "TCP",
        "upperPort": 0
      },
      {
        "id": "c60bbb52-8bcd-4498-8f04-e3ff1cadb7eb",
        "instanceId": 17474,
        "authEntityId": 17474,
        "displayName": "17474",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "492",
        "protocol": "UDP",
        "upperPort": 0
      }
    ],
    "parentScalableGroup": {
      "idRef": "000c15bf-28fe-4fbb-9b69-35e6502721f1"
    },
    "user": [

    ],
    "userGroup": [

    ]
  },
  {
    "id": "3006c143-ec20-43c3-b72c-2a3449d82b98",
    "instanceId": 15393,
    "authEntityId": 15393,
    "displayName": "15393",
    "authEntityClass": -1909073334,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "createTime": 1517966744864,
    "deployed": false,
    "isSeeded": true,
    "isStale": false,
    "lastUpdateTime": 1517966744864,
    "name": "weather-com",
    "namespace": "scalablegroup:application",
    "provisioningState": "DEFINED",
    "qualifier": "application",
    "resourceVersion": 0,
    "targetIdList": [

    ],
    "type": "scalablegroup",
    "cfsChangeInfo": [

    ],
    "customProvisions": [

    ],
    "scalableGroupExternalHandle": "weather-com",
    "scalableGroupType": "APPLICATION",
    "securityGroupTag": 0,
    "state": "ACTIVE",
    "vnAgnostic": true,
    "identitySource": {
      "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
      "instanceId": 10010,
      "authEntityId": 10010,
      "displayName": "10010",
      "authEntityClass": 1738953278,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "state": "INACTIVE",
      "type": "NBAR"
    },
    "indicativeNetworkIdentity": [

    ],
    "networkApplications": [
      {
        "id": "b06592ab-f1ee-41b1-9314-29d9dd0f4719",
        "instanceId": 16336,
        "authEntityId": 16336,
        "displayName": "16336",
        "authEntityClass": -217092956,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "appProtocol": "tcp/udp",
        "applicationGroup": "other",
        "applicationType": "DEFAULT",
        "categoryId": "878644b5-fe91-45b4-9204-31c84a73ba48",
        "createTime": 1517966744864,
        "encrypted": "false",
        "engineId": "13",
        "helpString": "Weather.com - Weather Channel web portal.",
        "ignoreConflict": false,
        "lastUpdateTime": 1517966744864,
        "longDescription": "Weather.com - Weather Channel web portal.",
        "name": "weather-com",
        "nbarId": "1565",
        "p2pTechnology": "false",
        "popularity": 3,
        "rank": 65535,
        "references": "http://weather.com",
        "selectorId": "636",
        "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
        "trafficClass": "TRANSACTIONAL_DATA",
        "tunnel": "false"
      }
    ],
    "networkIdentity": [
      {
        "id": "e548f6f9-b196-4a8b-819a-107a4a93bf86",
        "instanceId": 17799,
        "authEntityId": 17799,
        "displayName": "17799",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "53,80,443,5353",
        "protocol": "TCP",
        "upperPort": 0
      },
      {
        "id": "8f330524-f2c0-4728-9ea6-4ad1cf8667ae",
        "instanceId": 17800,
        "authEntityId": 17800,
        "displayName": "17800",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "53,5353",
        "protocol": "UDP",
        "upperPort": 0
      }
    ],
    "parentScalableGroup": {
      "idRef": "28e08de4-d7b2-4521-84ce-bc1e5ee0b9cc"
    },
    "user": [

    ],
    "userGroup": [

    ]
  },
  {
    "id": "306462bb-2e78-4c78-98a3-10fc22201799",
    "instanceId": 15435,
    "authEntityId": 15435,
    "displayName": "15435",
    "authEntityClass": -1909073334,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "createTime": 1517966744960,
    "deployed": false,
    "isSeeded": true,
    "isStale": false,
    "lastUpdateTime": 1517966744960,
    "name": "xdtp",
    "namespace": "scalablegroup:application",
    "provisioningState": "DEFINED",
    "qualifier": "application",
    "resourceVersion": 0,
    "targetIdList": [

    ],
    "type": "scalablegroup",
    "cfsChangeInfo": [

    ],
    "customProvisions": [

    ],
    "scalableGroupExternalHandle": "xdtp",
    "scalableGroupType": "APPLICATION",
    "securityGroupTag": 0,
    "state": "ACTIVE",
    "vnAgnostic": true,
    "identitySource": {
      "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
      "instanceId": 10010,
      "authEntityId": 10010,
      "displayName": "10010",
      "authEntityClass": 1738953278,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "state": "INACTIVE",
      "type": "NBAR"
    },
    "indicativeNetworkIdentity": [
      {
        "id": "65f50003-b483-4404-95f8-202b61367c01",
        "instanceId": 17889,
        "authEntityId": 17889,
        "displayName": "17889",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "3088",
        "protocol": "TCP",
        "upperPort": 0
      },
      {
        "id": "2b71f6e2-76fb-44e4-8a40-e87ccdadfaff",
        "instanceId": 17890,
        "authEntityId": 17890,
        "displayName": "17890",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "3088",
        "protocol": "UDP",
        "upperPort": 0
      }
    ],
    "networkApplications": [
      {
        "id": "64b73b9a-6e7b-4a2d-b782-f0d0015a96d4",
        "instanceId": 16378,
        "authEntityId": 16378,
        "displayName": "16378",
        "authEntityClass": -217092956,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "appProtocol": "tcp/udp",
        "applicationGroup": "other",
        "applicationType": "DEFAULT",
        "categoryId": "6daa3dfd-3526-407a-88f0-97824b3f3a24",
        "createTime": 1517966744960,
        "encrypted": "false",
        "engineId": "3",
        "helpString": "eXtensible Data Transfer Protocol",
        "ignoreConflict": false,
        "lastUpdateTime": 1517966744960,
        "longDescription": "Xpress Transport Protocol (XTP) is a transport layer protocol for high-speed networks promoted by the XTP Forum developed to replace TCP. XTP provides protocol options for error control, flow control, and rate control. Instead of separate protocols for each type of communication, XTP controls packet exchange patterns to produce different models, e.g. reliable datagrams, transactions, unreliable streams, and reliable multicast connections.",
        "name": "xdtp",
        "nbarId": "741",
        "p2pTechnology": "false",
        "popularity": 1,
        "rank": 65535,
        "references": "http://en.wikipedia.org/wiki/Xpress_Transport_Protocol",
        "selectorId": "3088",
        "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
        "trafficClass": "BULK_DATA",
        "tunnel": "false"
      }
    ],
    "networkIdentity": [
      {
        "id": "895ce860-12ca-4fba-906b-f033ecbb3b8f",
        "instanceId": 17888,
        "authEntityId": 17888,
        "displayName": "17888",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "3088",
        "protocol": "TCP",
        "upperPort": 0
      },
      {
        "id": "de9505f8-a5a2-4df5-bc00-324b30ef6e7a",
        "instanceId": 17887,
        "authEntityId": 17887,
        "displayName": "17887",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "3088",
        "protocol": "UDP",
        "upperPort": 0
      }
    ],
    "parentScalableGroup": {
      "idRef": "000c15bf-28fe-4fbb-9b69-35e6502721f1"
    },
    "user": [

    ],
    "userGroup": [

    ]
  },
  {
    "id": "30a40795-6327-4b84-ae61-156b5f2ab6e1",
    "instanceId": 4110,
    "authEntityId": 4110,
    "displayName": "4110",
    "authEntityClass": -1909073334,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "createTime": 1517966741164,
    "deployed": false,
    "isSeeded": true,
    "isStale": false,
    "lastUpdateTime": 1517966741164,
    "name": "appleqtc",
    "namespace": "scalablegroup:application",
    "provisioningState": "DEFINED",
    "qualifier": "application",
    "resourceVersion": 0,
    "targetIdList": [

    ],
    "type": "scalablegroup",
    "cfsChangeInfo": [

    ],
    "customProvisions": [

    ],
    "scalableGroupExternalHandle": "appleqtc",
    "scalableGroupType": "APPLICATION",
    "securityGroupTag": 0,
    "state": "ACTIVE",
    "vnAgnostic": true,
    "identitySource": {
      "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
      "instanceId": 10010,
      "authEntityId": 10010,
      "displayName": "10010",
      "authEntityClass": 1738953278,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "state": "INACTIVE",
      "type": "NBAR"
    },
    "indicativeNetworkIdentity": [
      {
        "id": "be48fbeb-76f8-4e3b-968b-919c3fe2aa2d",
        "instanceId": 12181,
        "authEntityId": 12181,
        "displayName": "12181",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "458",
        "protocol": "UDP",
        "upperPort": 0
      },
      {
        "id": "a252821d-ae59-43cd-9bdb-c67019313ac4",
        "instanceId": 12182,
        "authEntityId": 12182,
        "displayName": "12182",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "458",
        "protocol": "TCP",
        "upperPort": 0
      }
    ],
    "networkApplications": [
      {
        "id": "9dff5ed7-0fb9-4f9a-ac6f-e04ac5540441",
        "instanceId": 11072,
        "authEntityId": 11072,
        "displayName": "11072",
        "authEntityClass": -217092956,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "appProtocol": "tcp/udp",
        "applicationGroup": "other",
        "applicationType": "DEFAULT",
        "categoryId": "a09ef1b3-ba26-4372-aee8-309d50697e76",
        "createTime": 1517966741164,
        "encrypted": "false",
        "engineId": "3",
        "helpString": "apple quick time",
        "ignoreConflict": false,
        "lastUpdateTime": 1517966741164,
        "longDescription": "Apple QuickTime is an extensible proprietary multimedia framework developed by Apple Inc., capable of handling various formats of digital video, picture, sound, panoramic images, and interactivity. QuickTime is available for Windows XP and later, as well as Mac OS X Leopard and later operating systems.",
        "name": "appleqtc",
        "nbarId": "92",
        "p2pTechnology": "false",
        "popularity": 1,
        "rank": 65535,
        "references": "http://www.apple.com/quicktime/",
        "selectorId": "458",
        "subCategory": "85a797c0-aef8-4284-91a7-677f33e29abb",
        "trafficClass": "MULTIMEDIA_STREAMING",
        "tunnel": "false"
      }
    ],
    "networkIdentity": [
      {
        "id": "d843a8bc-d90e-46bf-964b-02bb96329cc6",
        "instanceId": 12179,
        "authEntityId": 12179,
        "displayName": "12179",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "458",
        "protocol": "TCP",
        "upperPort": 0
      },
      {
        "id": "f22696f3-16b5-42d7-b136-df7201b5460e",
        "instanceId": 12180,
        "authEntityId": 12180,
        "displayName": "12180",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "458",
        "protocol": "UDP",
        "upperPort": 0
      }
    ],
    "parentScalableGroup": {
      "idRef": "fd6264c9-c16a-4311-a335-a3db039256f3"
    },
    "user": [

    ],
    "userGroup": [

    ]
  },
  {
    "id": "30ae802f-3913-4a69-a6f5-770aac81f856",
    "instanceId": 4645,
    "authEntityId": 4645,
    "displayName": "4645",
    "authEntityClass": -1909073334,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "createTime": 1517966743196,
    "deployed": false,
    "isSeeded": true,
    "isStale": false,
    "lastUpdateTime": 1517966743196,
    "name": "isi-gl",
    "namespace": "scalablegroup:application",
    "provisioningState": "DEFINED",
    "qualifier": "application",
    "resourceVersion": 0,
    "targetIdList": [

    ],
    "type": "scalablegroup",
    "cfsChangeInfo": [

    ],
    "customProvisions": [

    ],
    "scalableGroupExternalHandle": "isi-gl",
    "scalableGroupType": "APPLICATION",
    "securityGroupTag": 0,
    "state": "ACTIVE",
    "vnAgnostic": true,
    "identitySource": {
      "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
      "instanceId": 10010,
      "authEntityId": 10010,
      "displayName": "10010",
      "authEntityClass": 1738953278,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "state": "INACTIVE",
      "type": "NBAR"
    },
    "indicativeNetworkIdentity": [
      {
        "id": "23fd1f3d-4303-4769-ba16-2f2ddbc6499b",
        "instanceId": 13619,
        "authEntityId": 13619,
        "displayName": "13619",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "55",
        "protocol": "TCP",
        "upperPort": 0
      },
      {
        "id": "d6416780-1006-4a89-8fc7-2478818862a8",
        "instanceId": 13620,
        "authEntityId": 13620,
        "displayName": "13620",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "55",
        "protocol": "UDP",
        "upperPort": 0
      }
    ],
    "networkApplications": [
      {
        "id": "baf6bea9-c708-4bf2-b6a7-3dc221792959",
        "instanceId": 11595,
        "authEntityId": 11595,
        "displayName": "11595",
        "authEntityClass": -217092956,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "appProtocol": "tcp/udp",
        "applicationGroup": "other",
        "applicationType": "DEFAULT",
        "categoryId": "6daa3dfd-3526-407a-88f0-97824b3f3a24",
        "createTime": 1517966743195,
        "encrypted": "false",
        "engineId": "3",
        "helpString": "ISI Graphics Language",
        "ignoreConflict": false,
        "lastUpdateTime": 1517966743195,
        "longDescription": "Registered with IANA on port 55 TCP/UDP",
        "name": "isi-gl",
        "nbarId": "106",
        "p2pTechnology": "false",
        "popularity": 1,
        "rank": 65535,
        "references": "http://www.iana.org/assignments/service-names-port-numbers/service-names-port-numbers.xml",
        "selectorId": "55",
        "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
        "trafficClass": "BULK_DATA",
        "tunnel": "false"
      }
    ],
    "networkIdentity": [
      {
        "id": "a11039e7-c03c-4af6-8bf8-c4d5b9e90971",
        "instanceId": 13617,
        "authEntityId": 13617,
        "displayName": "13617",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "55",
        "protocol": "UDP",
        "upperPort": 0
      },
      {
        "id": "6ec0eb88-ab55-4211-910f-bf75526b4f7f",
        "instanceId": 13618,
        "authEntityId": 13618,
        "displayName": "13618",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "55",
        "protocol": "TCP",
        "upperPort": 0
      }
    ],
    "parentScalableGroup": {
      "idRef": "000c15bf-28fe-4fbb-9b69-35e6502721f1"
    },
    "user": [

    ],
    "userGroup": [

    ]
  },
  {
    "id": "30e2bce7-0302-4bea-8de8-5d0a08c4ee4e",
    "instanceId": 4752,
    "authEntityId": 4752,
    "displayName": "4752",
    "authEntityClass": -1909073334,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "createTime": 1517966743423,
    "deployed": false,
    "isSeeded": true,
    "isStale": false,
    "lastUpdateTime": 1517966743423,
    "name": "mixi",
    "namespace": "scalablegroup:application",
    "provisioningState": "DEFINED",
    "qualifier": "application",
    "resourceVersion": 0,
    "targetIdList": [

    ],
    "type": "scalablegroup",
    "cfsChangeInfo": [

    ],
    "customProvisions": [

    ],
    "scalableGroupExternalHandle": "mixi",
    "scalableGroupType": "APPLICATION",
    "securityGroupTag": 0,
    "state": "ACTIVE",
    "vnAgnostic": true,
    "identitySource": {
      "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
      "instanceId": 10010,
      "authEntityId": 10010,
      "displayName": "10010",
      "authEntityClass": 1738953278,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "state": "INACTIVE",
      "type": "NBAR"
    },
    "indicativeNetworkIdentity": [

    ],
    "networkApplications": [
      {
        "id": "b3c99cd1-59db-49b6-9070-fd970008cfc0",
        "instanceId": 11702,
        "authEntityId": 11702,
        "displayName": "11702",
        "authEntityClass": -217092956,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "appProtocol": "tcp/udp",
        "applicationGroup": "other",
        "applicationType": "DEFAULT",
        "categoryId": "9eb6d680-796f-478a-9106-1151f00f7eff",
        "createTime": 1517966743423,
        "encrypted": "true",
        "engineId": "13",
        "helpString": "Mixi, Japanese social network",
        "ignoreConflict": false,
        "lastUpdateTime": 1517966743423,
        "longDescription": "Mixi is an online Japanese social networking service",
        "name": "mixi",
        "nbarId": "1531",
        "p2pTechnology": "false",
        "popularity": 7,
        "rank": 65535,
        "references": "https://mixi.jp",
        "selectorId": "592",
        "subCategory": "aab99e59-8589-4a61-933e-f01bff556d78",
        "trafficClass": "TRANSACTIONAL_DATA",
        "tunnel": "false"
      }
    ],
    "networkIdentity": [
      {
        "id": "bf54bf4e-07f0-4f7e-a9d3-2093669f522a",
        "instanceId": 13920,
        "authEntityId": 13920,
        "displayName": "13920",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "53,5222,5353",
        "protocol": "UDP",
        "upperPort": 0
      },
      {
        "id": "bbde8138-83ae-4930-ab41-abdcb8008f4e",
        "instanceId": 13921,
        "authEntityId": 13921,
        "displayName": "13921",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "53,80,443,5222,5353",
        "protocol": "TCP",
        "upperPort": 0
      }
    ],
    "parentScalableGroup": {
      "idRef": "82348eb1-d8b8-4daf-8532-15e1a5f9cdd0"
    },
    "user": [

    ],
    "userGroup": [

    ]
  },
  {
    "id": "316e8f4b-acf9-4b66-964c-5217df237499",
    "instanceId": 4448,
    "authEntityId": 4448,
    "displayName": "4448",
    "authEntityClass": -1909073334,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "createTime": 1517966742694,
    "deployed": false,
    "isSeeded": true,
    "isStale": false,
    "lastUpdateTime": 1517966742694,
    "name": "filemaker-announcement",
    "namespace": "scalablegroup:application",
    "provisioningState": "DEFINED",
    "qualifier": "application",
    "resourceVersion": 0,
    "targetIdList": [

    ],
    "type": "scalablegroup",
    "cfsChangeInfo": [

    ],
    "customProvisions": [

    ],
    "scalableGroupExternalHandle": "filemaker-announcement",
    "scalableGroupType": "APPLICATION",
    "securityGroupTag": 0,
    "state": "ACTIVE",
    "vnAgnostic": true,
    "identitySource": {
      "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
      "instanceId": 10010,
      "authEntityId": 10010,
      "displayName": "10010",
      "authEntityClass": 1738953278,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "state": "INACTIVE",
      "type": "NBAR"
    },
    "indicativeNetworkIdentity": [
      {
        "id": "78e2728d-bc00-47a4-bb1e-222bef03e432",
        "instanceId": 13120,
        "authEntityId": 13120,
        "displayName": "13120",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "2399,5003,5013,5015,16000,16001,16016",
        "protocol": "TCP",
        "upperPort": 0
      },
      {
        "id": "0fe45257-b6c8-4902-a752-4419c77a2924",
        "instanceId": 13121,
        "authEntityId": 13121,
        "displayName": "13121",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "2399,5003,5013,5015,16000,16001,16016",
        "protocol": "UDP",
        "upperPort": 0
      }
    ],
    "networkApplications": [
      {
        "id": "994bca1b-36df-4bfc-ba02-04eeb7b985cb",
        "instanceId": 11399,
        "authEntityId": 11399,
        "displayName": "11399",
        "authEntityClass": -217092956,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "appProtocol": "tcp/udp",
        "applicationGroup": "other",
        "applicationType": "DEFAULT",
        "categoryId": "6daa3dfd-3526-407a-88f0-97824b3f3a24",
        "createTime": 1517966742694,
        "encrypted": "false",
        "engineId": "3",
        "helpString": "Filemaker relational database application",
        "ignoreConflict": false,
        "lastUpdateTime": 1517966742694,
        "longDescription": "FileMaker Pro is a cross-platform relational database application from FileMaker Inc., formerly Claris, a subsidiary of Apple Inc. It integrates a database engine with a GUI-based interface, allowing users to modify the database by dragging new elements into layouts, screens, or forms.",
        "name": "filemaker-announcement",
        "nbarId": "1381",
        "p2pTechnology": "false",
        "popularity": 2,
        "rank": 65535,
        "references": "http://www.filemaker.com/",
        "selectorId": "2399",
        "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
        "trafficClass": "BULK_DATA",
        "tunnel": "false"
      }
    ],
    "networkIdentity": [
      {
        "id": "4ca6bf0c-e4b4-44a9-8fa6-958ef49fa4a6",
        "instanceId": 13118,
        "authEntityId": 13118,
        "displayName": "13118",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "2399,5003,5013,5015,16000,16001,16016",
        "protocol": "TCP",
        "upperPort": 0
      },
      {
        "id": "c6327576-e45b-445c-95cb-91797c046f74",
        "instanceId": 13119,
        "authEntityId": 13119,
        "displayName": "13119",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "2399,5003,5013,5015,16000,16001,16016",
        "protocol": "UDP",
        "upperPort": 0
      }
    ],
    "parentScalableGroup": {
      "idRef": "000c15bf-28fe-4fbb-9b69-35e6502721f1"
    },
    "user": [

    ],
    "userGroup": [

    ]
  },
  {
    "id": "3175f36e-2ccb-4ea7-90b7-73186df9e5d4",
    "instanceId": 4730,
    "authEntityId": 4730,
    "displayName": "4730",
    "authEntityClass": -1909073334,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "createTime": 1517966743376,
    "deployed": false,
    "isSeeded": true,
    "isStale": false,
    "lastUpdateTime": 1517966743376,
    "name": "mcidas",
    "namespace": "scalablegroup:application",
    "provisioningState": "DEFINED",
    "qualifier": "application",
    "resourceVersion": 0,
    "targetIdList": [

    ],
    "type": "scalablegroup",
    "cfsChangeInfo": [

    ],
    "customProvisions": [

    ],
    "scalableGroupExternalHandle": "mcidas",
    "scalableGroupType": "APPLICATION",
    "securityGroupTag": 0,
    "state": "ACTIVE",
    "vnAgnostic": true,
    "identitySource": {
      "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
      "instanceId": 10010,
      "authEntityId": 10010,
      "displayName": "10010",
      "authEntityClass": 1738953278,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "state": "INACTIVE",
      "type": "NBAR"
    },
    "indicativeNetworkIdentity": [
      {
        "id": "053dd08d-c383-4e9a-b9c0-cfdb65c1df78",
        "instanceId": 13848,
        "authEntityId": 13848,
        "displayName": "13848",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "112",
        "protocol": "UDP",
        "upperPort": 0
      },
      {
        "id": "60f9e2f6-476b-4586-9636-6b758d0f3bd2",
        "instanceId": 13849,
        "authEntityId": 13849,
        "displayName": "13849",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "112",
        "protocol": "TCP",
        "upperPort": 0
      }
    ],
    "networkApplications": [
      {
        "id": "68d755b8-564f-4d60-89d2-df2b5b7d7972",
        "instanceId": 11680,
        "authEntityId": 11680,
        "displayName": "11680",
        "authEntityClass": -217092956,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "appProtocol": "tcp/udp",
        "applicationGroup": "other",
        "applicationType": "DEFAULT",
        "categoryId": "6daa3dfd-3526-407a-88f0-97824b3f3a24",
        "createTime": 1517966743375,
        "encrypted": "false",
        "engineId": "3",
        "helpString": "McIDAS Data Transmission Protocol",
        "ignoreConflict": false,
        "lastUpdateTime": 1517966743375,
        "longDescription": "McIDAS (Man computer Interactive Data Access System) is a suite of sophisticated software packages that perform a wide variety of functions with satellite imagery, observational reports, numerical forecasts, and other geophysical data. Those functions include displaying, analyzing, interpreting, acquiring and managing the data.",
        "name": "mcidas",
        "nbarId": "982",
        "p2pTechnology": "false",
        "popularity": 1,
        "rank": 65535,
        "references": "http://www.ssec.wisc.edu/mcidas/",
        "selectorId": "112",
        "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
        "trafficClass": "BULK_DATA",
        "tunnel": "false"
      }
    ],
    "networkIdentity": [
      {
        "id": "fd46e514-4faf-4c69-9c04-0f2031abc3f9",
        "instanceId": 13846,
        "authEntityId": 13846,
        "displayName": "13846",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "112",
        "protocol": "TCP",
        "upperPort": 0
      },
      {
        "id": "2d67c0a5-f52c-449c-9082-b2f63a29472a",
        "instanceId": 13847,
        "authEntityId": 13847,
        "displayName": "13847",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "112",
        "protocol": "UDP",
        "upperPort": 0
      }
    ],
    "parentScalableGroup": {
      "idRef": "000c15bf-28fe-4fbb-9b69-35e6502721f1"
    },
    "user": [

    ],
    "userGroup": [

    ]
  },
  {
    "id": "31c297d1-bf21-4b0b-93f0-27cf95feb78a",
    "instanceId": 4546,
    "authEntityId": 4546,
    "displayName": "4546",
    "authEntityClass": -1909073334,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "createTime": 1517966742952,
    "deployed": false,
    "isSeeded": true,
    "isStale": false,
    "lastUpdateTime": 1517966742952,
    "name": "hootsuite",
    "namespace": "scalablegroup:application",
    "provisioningState": "DEFINED",
    "qualifier": "application",
    "resourceVersion": 0,
    "targetIdList": [

    ],
    "type": "scalablegroup",
    "cfsChangeInfo": [

    ],
    "customProvisions": [

    ],
    "scalableGroupExternalHandle": "hootsuite",
    "scalableGroupType": "APPLICATION",
    "securityGroupTag": 0,
    "state": "ACTIVE",
    "vnAgnostic": true,
    "identitySource": {
      "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
      "instanceId": 10010,
      "authEntityId": 10010,
      "displayName": "10010",
      "authEntityClass": 1738953278,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "state": "INACTIVE",
      "type": "NBAR"
    },
    "indicativeNetworkIdentity": [

    ],
    "networkApplications": [
      {
        "id": "402929a8-732c-4053-a1c3-0d212dbe91e1",
        "instanceId": 11497,
        "authEntityId": 11497,
        "displayName": "11497",
        "authEntityClass": -217092956,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "appProtocol": "tcp/udp",
        "applicationGroup": "other",
        "applicationType": "DEFAULT",
        "categoryId": "878644b5-fe91-45b4-9204-31c84a73ba48",
        "createTime": 1517966742952,
        "encrypted": "false",
        "engineId": "13",
        "helpString": "HootSuite - Social Network management.",
        "ignoreConflict": false,
        "lastUpdateTime": 1517966742952,
        "longDescription": "HootSuite - Social Network management.",
        "name": "hootsuite",
        "nbarId": "1597",
        "p2pTechnology": "false",
        "popularity": 3,
        "rank": 65535,
        "references": "http://hootsuite.com",
        "selectorId": "668",
        "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
        "trafficClass": "TRANSACTIONAL_DATA",
        "tunnel": "false"
      }
    ],
    "networkIdentity": [
      {
        "id": "4418f7ee-e1ed-440a-9e7b-2af85868d1ad",
        "instanceId": 13355,
        "authEntityId": 13355,
        "displayName": "13355",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "53,5353",
        "protocol": "UDP",
        "upperPort": 0
      },
      {
        "id": "fb4ad900-f89b-4309-a57a-8169c0a2034c",
        "instanceId": 13356,
        "authEntityId": 13356,
        "displayName": "13356",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "53,80,443,5353",
        "protocol": "TCP",
        "upperPort": 0
      }
    ],
    "parentScalableGroup": {
      "idRef": "28e08de4-d7b2-4521-84ce-bc1e5ee0b9cc"
    },
    "user": [

    ],
    "userGroup": [

    ]
  },
  {
    "id": "31d1efa8-60b4-47c5-affe-64ea10efa3e8",
    "instanceId": 4969,
    "authEntityId": 4969,
    "displayName": "4969",
    "authEntityClass": -1909073334,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "createTime": 1517966743937,
    "deployed": false,
    "isSeeded": true,
    "isStale": false,
    "lastUpdateTime": 1517966743937,
    "name": "playstation-web-portal",
    "namespace": "scalablegroup:application",
    "provisioningState": "DEFINED",
    "qualifier": "application",
    "resourceVersion": 0,
    "targetIdList": [

    ],
    "type": "scalablegroup",
    "cfsChangeInfo": [

    ],
    "customProvisions": [

    ],
    "scalableGroupExternalHandle": "playstation-web-portal",
    "scalableGroupType": "APPLICATION",
    "securityGroupTag": 0,
    "state": "ACTIVE",
    "vnAgnostic": true,
    "identitySource": {
      "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
      "instanceId": 10010,
      "authEntityId": 10010,
      "displayName": "10010",
      "authEntityClass": 1738953278,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "state": "INACTIVE",
      "type": "NBAR"
    },
    "indicativeNetworkIdentity": [

    ],
    "networkApplications": [
      {
        "id": "97b3336d-987b-4b6e-a339-0152c4c80116",
        "instanceId": 11918,
        "authEntityId": 11918,
        "displayName": "11918",
        "authEntityClass": -217092956,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "appProtocol": "tcp/udp",
        "applicationGroup": "other",
        "applicationType": "DEFAULT",
        "categoryId": "878644b5-fe91-45b4-9204-31c84a73ba48",
        "createTime": 1517966743937,
        "encrypted": "false",
        "engineId": "13",
        "helpString": "Playstation.com - Sony Playstation related e-commerce.",
        "ignoreConflict": false,
        "lastUpdateTime": 1517966743937,
        "longDescription": "Playstation.com - Sony Playstation related e-commerce.",
        "name": "playstation-web-portal",
        "nbarId": "1702",
        "p2pTechnology": "false",
        "popularity": 3,
        "rank": 65535,
        "references": "http://playstation.com",
        "selectorId": "772",
        "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
        "trafficClass": "TRANSACTIONAL_DATA",
        "tunnel": "false"
      }
    ],
    "networkIdentity": [
      {
        "id": "7faaa3e6-42c5-447f-84a4-46a34354660c",
        "instanceId": 14564,
        "authEntityId": 14564,
        "displayName": "14564",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "53,5353",
        "protocol": "UDP",
        "upperPort": 0
      },
      {
        "id": "d278713f-dbcc-44f6-ba54-4a89c8e3405d",
        "instanceId": 14565,
        "authEntityId": 14565,
        "displayName": "14565",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "53,80,443,5353",
        "protocol": "TCP",
        "upperPort": 0
      }
    ],
    "parentScalableGroup": {
      "idRef": "28e08de4-d7b2-4521-84ce-bc1e5ee0b9cc"
    },
    "user": [

    ],
    "userGroup": [

    ]
  },
  {
    "id": "321b3f7e-05f7-4c02-9697-da254554a746",
    "instanceId": 4953,
    "authEntityId": 4953,
    "displayName": "4953",
    "authEntityClass": -1909073334,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "createTime": 1517966743892,
    "deployed": false,
    "isSeeded": true,
    "isStale": false,
    "lastUpdateTime": 1517966743892,
    "name": "pftp",
    "namespace": "scalablegroup:application",
    "provisioningState": "DEFINED",
    "qualifier": "application",
    "resourceVersion": 0,
    "targetIdList": [

    ],
    "type": "scalablegroup",
    "cfsChangeInfo": [

    ],
    "customProvisions": [

    ],
    "scalableGroupExternalHandle": "pftp",
    "scalableGroupType": "APPLICATION",
    "securityGroupTag": 0,
    "state": "ACTIVE",
    "vnAgnostic": true,
    "identitySource": {
      "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
      "instanceId": 10010,
      "authEntityId": 10010,
      "displayName": "10010",
      "authEntityClass": 1738953278,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "state": "INACTIVE",
      "type": "NBAR"
    },
    "indicativeNetworkIdentity": [
      {
        "id": "aa47fe46-732c-4d2a-96c8-8f9154e8dc58",
        "instanceId": 14518,
        "authEntityId": 14518,
        "displayName": "14518",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "662",
        "protocol": "UDP",
        "upperPort": 0
      },
      {
        "id": "c769fd4a-f06c-4505-9f5e-090e8a6262b4",
        "instanceId": 14519,
        "authEntityId": 14519,
        "displayName": "14519",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "662",
        "protocol": "TCP",
        "upperPort": 0
      }
    ],
    "networkApplications": [
      {
        "id": "317b7943-d713-4318-9327-2b140e386628",
        "instanceId": 11902,
        "authEntityId": 11902,
        "displayName": "11902",
        "authEntityClass": -217092956,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "appProtocol": "tcp/udp",
        "applicationGroup": "other",
        "applicationType": "DEFAULT",
        "categoryId": "e6619ce8-97de-4c63-a53a-cceddbddc1a8",
        "createTime": 1517966743892,
        "encrypted": "false",
        "engineId": "3",
        "helpString": "pftp",
        "ignoreConflict": false,
        "lastUpdateTime": 1517966743892,
        "longDescription": "Port File Transfer Program (PFTP) is a file transfer protocol that transfers files, directories and data from standard input to any host on the net running PFTP.",
        "name": "pftp",
        "nbarId": "570",
        "p2pTechnology": "false",
        "popularity": 1,
        "rank": 65535,
        "references": "http://www.pftp.de/",
        "selectorId": "662",
        "subCategory": "50b55e2e-d0d0-4404-bea4-819351565d90",
        "trafficClass": "BULK_DATA",
        "tunnel": "false"
      }
    ],
    "networkIdentity": [
      {
        "id": "d91d81fe-666a-48b8-baa1-6f5e9786dce9",
        "instanceId": 14516,
        "authEntityId": 14516,
        "displayName": "14516",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "662",
        "protocol": "TCP",
        "upperPort": 0
      },
      {
        "id": "04d3a0a5-9d30-4a30-943c-456af04df506",
        "instanceId": 14517,
        "authEntityId": 14517,
        "displayName": "14517",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "662",
        "protocol": "UDP",
        "upperPort": 0
      }
    ],
    "parentScalableGroup": {
      "idRef": "383363b1-4dd5-49d1-bfe4-34daac437cfb"
    },
    "user": [

    ],
    "userGroup": [

    ]
  },
  {
    "id": "329742b6-6daa-448f-b64e-85f4502ed428",
    "instanceId": 15433,
    "authEntityId": 15433,
    "displayName": "15433",
    "authEntityClass": -1909073334,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "createTime": 1517966744956,
    "deployed": false,
    "isSeeded": true,
    "isStale": false,
    "lastUpdateTime": 1517966744956,
    "name": "xda-developers",
    "namespace": "scalablegroup:application",
    "provisioningState": "DEFINED",
    "qualifier": "application",
    "resourceVersion": 0,
    "targetIdList": [

    ],
    "type": "scalablegroup",
    "cfsChangeInfo": [

    ],
    "customProvisions": [

    ],
    "scalableGroupExternalHandle": "xda-developers",
    "scalableGroupType": "APPLICATION",
    "securityGroupTag": 0,
    "state": "ACTIVE",
    "vnAgnostic": true,
    "identitySource": {
      "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
      "instanceId": 10010,
      "authEntityId": 10010,
      "displayName": "10010",
      "authEntityClass": 1738953278,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "state": "INACTIVE",
      "type": "NBAR"
    },
    "indicativeNetworkIdentity": [

    ],
    "networkApplications": [
      {
        "id": "5d8c95a0-36be-485a-be5a-40ed9ccc2b9c",
        "instanceId": 16376,
        "authEntityId": 16376,
        "displayName": "16376",
        "authEntityClass": -217092956,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "appProtocol": "tcp/udp",
        "applicationGroup": "other",
        "applicationType": "DEFAULT",
        "categoryId": "878644b5-fe91-45b4-9204-31c84a73ba48",
        "createTime": 1517966744956,
        "encrypted": "false",
        "engineId": "13",
        "helpString": "XDA-Developers - Large online community of smart phone and tablet developers.",
        "ignoreConflict": false,
        "lastUpdateTime": 1517966744956,
        "longDescription": "XDA-Developers - Large online community of smart phone and tablet developers.",
        "name": "xda-developers",
        "nbarId": "1635",
        "p2pTechnology": "false",
        "popularity": 3,
        "rank": 65535,
        "references": "http://xda-developers.com",
        "selectorId": "705",
        "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
        "trafficClass": "TRANSACTIONAL_DATA",
        "tunnel": "false"
      }
    ],
    "networkIdentity": [
      {
        "id": "0b061a58-ce19-443e-b54d-6d6025fcb33d",
        "instanceId": 17881,
        "authEntityId": 17881,
        "displayName": "17881",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "53,80,443,5353",
        "protocol": "TCP",
        "upperPort": 0
      },
      {
        "id": "e5f104be-4cbb-4d7b-afc6-d9bb7d1610bf",
        "instanceId": 17882,
        "authEntityId": 17882,
        "displayName": "17882",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "53,5353",
        "protocol": "UDP",
        "upperPort": 0
      }
    ],
    "parentScalableGroup": {
      "idRef": "28e08de4-d7b2-4521-84ce-bc1e5ee0b9cc"
    },
    "user": [

    ],
    "userGroup": [

    ]
  },
  {
    "id": "32b368ff-f5f8-407c-a217-adf3506bde2f",
    "instanceId": 4358,
    "authEntityId": 4358,
    "displayName": "4358",
    "authEntityClass": -1909073334,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "createTime": 1517966742392,
    "deployed": false,
    "isSeeded": true,
    "isStale": false,
    "lastUpdateTime": 1517966742392,
    "name": "dhcpv6-server",
    "namespace": "scalablegroup:application",
    "provisioningState": "DEFINED",
    "qualifier": "application",
    "resourceVersion": 0,
    "targetIdList": [

    ],
    "type": "scalablegroup",
    "cfsChangeInfo": [

    ],
    "customProvisions": [

    ],
    "scalableGroupExternalHandle": "dhcpv6-server",
    "scalableGroupType": "APPLICATION",
    "securityGroupTag": 0,
    "state": "ACTIVE",
    "vnAgnostic": true,
    "identitySource": {
      "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
      "instanceId": 10010,
      "authEntityId": 10010,
      "displayName": "10010",
      "authEntityClass": 1738953278,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "state": "INACTIVE",
      "type": "NBAR"
    },
    "indicativeNetworkIdentity": [
      {
        "id": "47e8e4dc-3021-4902-b5b9-f59fca236b74",
        "instanceId": 12858,
        "authEntityId": 12858,
        "displayName": "12858",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "547",
        "protocol": "UDP",
        "upperPort": 0
      },
      {
        "id": "c380cdd8-7f1a-4109-ad6e-e156467b0a89",
        "instanceId": 12859,
        "authEntityId": 12859,
        "displayName": "12859",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "547",
        "protocol": "TCP",
        "upperPort": 0
      }
    ],
    "networkApplications": [
      {
        "id": "10791500-ad77-48e2-a939-d629137cfa98",
        "instanceId": 11309,
        "authEntityId": 11309,
        "displayName": "11309",
        "authEntityClass": -217092956,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "appProtocol": "tcp/udp",
        "applicationGroup": "other",
        "applicationType": "DEFAULT",
        "categoryId": "1fc17362-9334-4c7b-b752-5afdbb574017",
        "createTime": 1517966742392,
        "encrypted": "false",
        "engineId": "3",
        "helpString": "DHCPv6 Server",
        "ignoreConflict": false,
        "lastUpdateTime": 1517966742392,
        "longDescription": "DHCPv6 is a network protocol that is used for configuring IPv6 hosts with IP addresses, IP prefixes and other configurations required to operate on an IPv6 network.",
        "name": "dhcpv6-server",
        "nbarId": "465",
        "p2pTechnology": "false",
        "popularity": 7,
        "rank": 65535,
        "references": "http://www.ietf.org/rfc/rfc3315.txt",
        "selectorId": "547",
        "subCategory": "f3c0f761-d894-4112-ab89-b90fc84fd660",
        "trafficClass": "OPS_ADMIN_MGMT",
        "tunnel": "false"
      }
    ],
    "networkIdentity": [
      {
        "id": "2c378488-e3a3-42c6-8355-b63985af0289",
        "instanceId": 12856,
        "authEntityId": 12856,
        "displayName": "12856",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "547",
        "protocol": "TCP",
        "upperPort": 0
      },
      {
        "id": "0f071a6e-d265-44ce-83d5-1c9d4ba805ab",
        "instanceId": 12857,
        "authEntityId": 12857,
        "displayName": "12857",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "547",
        "protocol": "UDP",
        "upperPort": 0
      }
    ],
    "parentScalableGroup": {
      "idRef": "5fcd74d4-8726-47f7-a088-c457863da7ef"
    },
    "user": [

    ],
    "userGroup": [

    ]
  },
  {
    "id": "32d83927-3f68-49b1-8226-c1e82e577cca",
    "instanceId": 4610,
    "authEntityId": 4610,
    "displayName": "4610",
    "authEntityClass": -1909073334,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "createTime": 1517966743119,
    "deployed": false,
    "isSeeded": true,
    "isStale": false,
    "lastUpdateTime": 1517966743119,
    "name": "internet-audio-streaming",
    "namespace": "scalablegroup:application",
    "provisioningState": "DEFINED",
    "qualifier": "application",
    "resourceVersion": 0,
    "targetIdList": [

    ],
    "type": "scalablegroup",
    "cfsChangeInfo": [

    ],
    "customProvisions": [

    ],
    "scalableGroupExternalHandle": "internet-audio-streaming",
    "scalableGroupType": "APPLICATION",
    "securityGroupTag": 0,
    "state": "ACTIVE",
    "vnAgnostic": true,
    "identitySource": {
      "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
      "instanceId": 10010,
      "authEntityId": 10010,
      "displayName": "10010",
      "authEntityClass": 1738953278,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "state": "INACTIVE",
      "type": "NBAR"
    },
    "indicativeNetworkIdentity": [

    ],
    "networkApplications": [
      {
        "id": "d14b2c65-e425-4573-9122-8bffad358980",
        "instanceId": 11560,
        "authEntityId": 11560,
        "displayName": "11560",
        "authEntityClass": -217092956,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "appProtocol": "tcp/udp",
        "applicationGroup": "other",
        "applicationType": "DEFAULT",
        "categoryId": "a09ef1b3-ba26-4372-aee8-309d50697e76",
        "createTime": 1517966743119,
        "encrypted": "true",
        "engineId": "13",
        "helpString": "Top internet audio streaming applications",
        "ignoreConflict": false,
        "lastUpdateTime": 1517966743119,
        "longDescription": "The internet audio streaming protocol gathers the top websites and web applications for audio streaming on the internet such as SoundCloud, Grooveshark and more.",
        "name": "internet-audio-streaming",
        "nbarId": "1514",
        "p2pTechnology": "false",
        "popularity": 10,
        "rank": 65535,
        "references": "http://www.rdio.com/,http://www.soundcloud.com/,http://www.grooveshark.com/,http://www.last.fm/,http://www.maestro.fm/,http://www.mog.com/,http://www.slacker.com/,http://www.songza.com/,http://www.siriusxm.com/,http://www.deezer.com/,http://www.iheart.com/,http://www.blinkboxmusic.com/",
        "selectorId": "575",
        "subCategory": "bf1b9d2c-aa52-4f37-8860-40d4603319f5",
        "trafficClass": "MULTIMEDIA_STREAMING",
        "tunnel": "false"
      }
    ],
    "networkIdentity": [
      {
        "id": "b9345e18-f965-4195-a5b7-cbb1a3453c88",
        "instanceId": 13541,
        "authEntityId": 13541,
        "displayName": "13541",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "53,5353",
        "protocol": "UDP",
        "upperPort": 0
      },
      {
        "id": "06a5dc48-4abc-451e-bbb1-bcb10c87a0f5",
        "instanceId": 13542,
        "authEntityId": 13542,
        "displayName": "13542",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "53,80,443,5353",
        "protocol": "TCP",
        "upperPort": 0
      }
    ],
    "parentScalableGroup": {
      "idRef": "fd6264c9-c16a-4311-a335-a3db039256f3"
    },
    "user": [

    ],
    "userGroup": [

    ]
  },
  {
    "id": "33bfe1b7-3624-49f4-b581-ac6acf66b73f",
    "instanceId": 4549,
    "authEntityId": 4549,
    "displayName": "4549",
    "authEntityClass": -1909073334,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "createTime": 1517966742961,
    "deployed": false,
    "isSeeded": true,
    "isStale": false,
    "lastUpdateTime": 1517966742961,
    "name": "hostname",
    "namespace": "scalablegroup:application",
    "provisioningState": "DEFINED",
    "qualifier": "application",
    "resourceVersion": 0,
    "targetIdList": [

    ],
    "type": "scalablegroup",
    "cfsChangeInfo": [

    ],
    "customProvisions": [

    ],
    "scalableGroupExternalHandle": "hostname",
    "scalableGroupType": "APPLICATION",
    "securityGroupTag": 0,
    "state": "ACTIVE",
    "vnAgnostic": true,
    "identitySource": {
      "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
      "instanceId": 10010,
      "authEntityId": 10010,
      "displayName": "10010",
      "authEntityClass": 1738953278,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "state": "INACTIVE",
      "type": "NBAR"
    },
    "indicativeNetworkIdentity": [
      {
        "id": "dbdaf8d2-8c35-449b-9a66-43ef75dc93f2",
        "instanceId": 13361,
        "authEntityId": 13361,
        "displayName": "13361",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "101",
        "protocol": "UDP",
        "upperPort": 0
      },
      {
        "id": "87ab37b9-37a2-4b2c-811f-67f821d37766",
        "instanceId": 13362,
        "authEntityId": 13362,
        "displayName": "13362",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "101",
        "protocol": "TCP",
        "upperPort": 0
      }
    ],
    "networkApplications": [
      {
        "id": "c7643ee5-823a-40c8-937c-e3b675eda687",
        "instanceId": 11500,
        "authEntityId": 11500,
        "displayName": "11500",
        "authEntityClass": -217092956,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "appProtocol": "tcp/udp",
        "applicationGroup": "other",
        "applicationType": "DEFAULT",
        "categoryId": "1fc17362-9334-4c7b-b752-5afdbb574017",
        "createTime": 1517966742961,
        "encrypted": "false",
        "engineId": "3",
        "helpString": "NIC Host Name Server",
        "ignoreConflict": false,
        "lastUpdateTime": 1517966742961,
        "longDescription": "NIC Internet Hostname Server is a TCP-based host information program and protocol running on the SRI-NIC machine. The function of this particular server is to deliver machine-readable name/address information describing networks, gateways, hosts and eventually domains, within the internet environment.  As currently implemented, the server provides the information outlined in the DoD Internet Host Table Specification.",
        "name": "hostname",
        "nbarId": "972",
        "p2pTechnology": "false",
        "popularity": 1,
        "rank": 65535,
        "references": "http://tools.ietf.org/html/rfc953",
        "selectorId": "101",
        "subCategory": "7f5fa07d-fcb4-4217-9908-b438066c0b60",
        "trafficClass": "OPS_ADMIN_MGMT",
        "tunnel": "false"
      }
    ],
    "networkIdentity": [
      {
        "id": "ae7f5d87-0169-40e9-81c3-97d6f3bf9643",
        "instanceId": 13360,
        "authEntityId": 13360,
        "displayName": "13360",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "101",
        "protocol": "UDP",
        "upperPort": 0
      },
      {
        "id": "07e11038-b565-433d-a83a-e0156e5481f2",
        "instanceId": 13359,
        "authEntityId": 13359,
        "displayName": "13359",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "101",
        "protocol": "TCP",
        "upperPort": 0
      }
    ],
    "parentScalableGroup": {
      "idRef": "38b9eb6b-f3b6-4e13-9b79-a11e0c6423d4"
    },
    "user": [

    ],
    "userGroup": [

    ]
  },
  {
    "id": "33ccd663-686d-414f-a762-250e7517f045",
    "instanceId": 4412,
    "authEntityId": 4412,
    "displayName": "4412",
    "authEntityClass": -1909073334,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "createTime": 1517966742571,
    "deployed": false,
    "isSeeded": true,
    "isStale": false,
    "lastUpdateTime": 1517966742571,
    "name": "entertainment-weekly",
    "namespace": "scalablegroup:application",
    "provisioningState": "DEFINED",
    "qualifier": "application",
    "resourceVersion": 0,
    "targetIdList": [

    ],
    "type": "scalablegroup",
    "cfsChangeInfo": [

    ],
    "customProvisions": [

    ],
    "scalableGroupExternalHandle": "entertainment-weekly",
    "scalableGroupType": "APPLICATION",
    "securityGroupTag": 0,
    "state": "ACTIVE",
    "vnAgnostic": true,
    "identitySource": {
      "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
      "instanceId": 10010,
      "authEntityId": 10010,
      "displayName": "10010",
      "authEntityClass": 1738953278,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "state": "INACTIVE",
      "type": "NBAR"
    },
    "indicativeNetworkIdentity": [

    ],
    "networkApplications": [
      {
        "id": "1f1b03e2-bfe4-4538-b658-7c9f146fe3ed",
        "instanceId": 11363,
        "authEntityId": 11363,
        "displayName": "11363",
        "authEntityClass": -217092956,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "appProtocol": "tcp/udp",
        "applicationGroup": "other",
        "applicationType": "DEFAULT",
        "categoryId": "878644b5-fe91-45b4-9204-31c84a73ba48",
        "createTime": 1517966742571,
        "encrypted": "false",
        "engineId": "13",
        "helpString": "Entertainment Weekly - Entertainment new and video clips.",
        "ignoreConflict": false,
        "lastUpdateTime": 1517966742571,
        "longDescription": "Entertainment Weekly - Entertainment new and video clips.",
        "name": "entertainment-weekly",
        "nbarId": "1728",
        "p2pTechnology": "false",
        "popularity": 3,
        "rank": 65535,
        "references": "http://ew.com",
        "selectorId": "800",
        "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
        "trafficClass": "TRANSACTIONAL_DATA",
        "tunnel": "false"
      }
    ],
    "networkIdentity": [
      {
        "id": "8ca4bad3-ab7f-4147-92dc-bd54feb5f3e2",
        "instanceId": 13026,
        "authEntityId": 13026,
        "displayName": "13026",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "53,80,443,5353",
        "protocol": "TCP",
        "upperPort": 0
      },
      {
        "id": "e571fae8-0cc9-45d7-95bf-3af1cf83e22a",
        "instanceId": 13027,
        "authEntityId": 13027,
        "displayName": "13027",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "53,5353",
        "protocol": "UDP",
        "upperPort": 0
      }
    ],
    "parentScalableGroup": {
      "idRef": "28e08de4-d7b2-4521-84ce-bc1e5ee0b9cc"
    },
    "user": [

    ],
    "userGroup": [

    ]
  },
  {
    "id": "33e70ba8-ba73-4d20-8c7d-2b30cc537a9d",
    "instanceId": 4509,
    "authEntityId": 4509,
    "displayName": "4509",
    "authEntityClass": -1909073334,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "createTime": 1517966742845,
    "deployed": false,
    "isSeeded": true,
    "isStale": false,
    "lastUpdateTime": 1517966742845,
    "name": "gree",
    "namespace": "scalablegroup:application",
    "provisioningState": "DEFINED",
    "qualifier": "application",
    "resourceVersion": 0,
    "targetIdList": [

    ],
    "type": "scalablegroup",
    "cfsChangeInfo": [

    ],
    "customProvisions": [

    ],
    "scalableGroupExternalHandle": "gree",
    "scalableGroupType": "APPLICATION",
    "securityGroupTag": 0,
    "state": "ACTIVE",
    "vnAgnostic": true,
    "identitySource": {
      "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
      "instanceId": 10010,
      "authEntityId": 10010,
      "displayName": "10010",
      "authEntityClass": 1738953278,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "state": "INACTIVE",
      "type": "NBAR"
    },
    "indicativeNetworkIdentity": [

    ],
    "networkApplications": [
      {
        "id": "1323abe7-02c5-403d-a76d-f3fad8356054",
        "instanceId": 11460,
        "authEntityId": 11460,
        "displayName": "11460",
        "authEntityClass": -217092956,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "appProtocol": "tcp/udp",
        "applicationGroup": "other",
        "applicationType": "DEFAULT",
        "categoryId": "3cd50dd8-1b2e-46c1-b672-41c14bfa364b",
        "createTime": 1517966742845,
        "encrypted": "true",
        "engineId": "13",
        "helpString": "Japanese social gaming platform",
        "ignoreConflict": false,
        "lastUpdateTime": 1517966742845,
        "longDescription": "GREE is a Japanese social gaming platform. Games developed for GREE offer the users a gaming intended social network on which they can share comments and likes, upload photos and game screenshots, create avatars, share games and more.",
        "name": "gree",
        "nbarId": "1536",
        "p2pTechnology": "false",
        "popularity": 7,
        "rank": 65535,
        "references": "http://gree.net",
        "selectorId": "597",
        "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
        "trafficClass": "BULK_DATA",
        "tunnel": "false"
      }
    ],
    "networkIdentity": [
      {
        "id": "fbdaf46b-7016-4171-a4af-c7607150c036",
        "instanceId": 13261,
        "authEntityId": 13261,
        "displayName": "13261",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "53,80,443,5353",
        "protocol": "TCP",
        "upperPort": 0
      },
      {
        "id": "aca9c74f-66a6-4a31-8b23-e38da9cd2149",
        "instanceId": 13262,
        "authEntityId": 13262,
        "displayName": "13262",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "53,5353",
        "protocol": "UDP",
        "upperPort": 0
      }
    ],
    "parentScalableGroup": {
      "idRef": "0bd0e6c9-5083-492b-97cb-a88928bee99c"
    },
    "user": [

    ],
    "userGroup": [

    ]
  },
  {
    "id": "33fc642e-1c83-4b54-863a-717968010d8f",
    "instanceId": 4940,
    "authEntityId": 4940,
    "displayName": "4940",
    "authEntityClass": -1909073334,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "createTime": 1517966743857,
    "deployed": false,
    "isSeeded": true,
    "isStale": false,
    "lastUpdateTime": 1517966743857,
    "name": "pawserv",
    "namespace": "scalablegroup:application",
    "provisioningState": "DEFINED",
    "qualifier": "application",
    "resourceVersion": 0,
    "targetIdList": [

    ],
    "type": "scalablegroup",
    "cfsChangeInfo": [

    ],
    "customProvisions": [

    ],
    "scalableGroupExternalHandle": "pawserv",
    "scalableGroupType": "APPLICATION",
    "securityGroupTag": 0,
    "state": "ACTIVE",
    "vnAgnostic": true,
    "identitySource": {
      "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
      "instanceId": 10010,
      "authEntityId": 10010,
      "displayName": "10010",
      "authEntityClass": 1738953278,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "state": "INACTIVE",
      "type": "NBAR"
    },
    "indicativeNetworkIdentity": [
      {
        "id": "691d136d-9205-4046-b1d0-212dd5306a3f",
        "instanceId": 14487,
        "authEntityId": 14487,
        "displayName": "14487",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "345",
        "protocol": "UDP",
        "upperPort": 0
      },
      {
        "id": "09de23ab-e6f1-4707-8b8e-99d4de17e19d",
        "instanceId": 14488,
        "authEntityId": 14488,
        "displayName": "14488",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "345",
        "protocol": "TCP",
        "upperPort": 0
      }
    ],
    "networkApplications": [
      {
        "id": "20a89017-c34f-4864-8fde-8f16e0dd114e",
        "instanceId": 11889,
        "authEntityId": 11889,
        "displayName": "11889",
        "authEntityClass": -217092956,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "appProtocol": "tcp/udp",
        "applicationGroup": "other",
        "applicationType": "DEFAULT",
        "categoryId": "6daa3dfd-3526-407a-88f0-97824b3f3a24",
        "createTime": 1517966743857,
        "encrypted": "unassigned",
        "engineId": "3",
        "helpString": "Perf Analysis Workbench",
        "ignoreConflict": false,
        "lastUpdateTime": 1517966743857,
        "longDescription": "Registered with IANA on port 345 TCP/UDP",
        "name": "pawserv",
        "nbarId": "800",
        "p2pTechnology": "unassigned",
        "popularity": 1,
        "rank": 65535,
        "references": "http://www.iana.org/assignments/service-names-port-numbers/service-names-port-numbers.xml",
        "selectorId": "345",
        "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
        "trafficClass": "BULK_DATA",
        "tunnel": "unassigned"
      }
    ],
    "networkIdentity": [
      {
        "id": "ceffdb8c-0b8e-4db7-ae6c-3de5b0690b2d",
        "instanceId": 14485,
        "authEntityId": 14485,
        "displayName": "14485",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "345",
        "protocol": "TCP",
        "upperPort": 0
      },
      {
        "id": "23beac5f-4f2a-4b59-997f-9918edc48ccf",
        "instanceId": 14486,
        "authEntityId": 14486,
        "displayName": "14486",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "345",
        "protocol": "UDP",
        "upperPort": 0
      }
    ],
    "parentScalableGroup": {
      "idRef": "000c15bf-28fe-4fbb-9b69-35e6502721f1"
    },
    "user": [

    ],
    "userGroup": [

    ]
  },
  {
    "id": "3445a01b-688d-48c3-aae5-933aede5f8e7",
    "instanceId": 4709,
    "authEntityId": 4709,
    "displayName": "4709",
    "authEntityClass": -1909073334,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "createTime": 1517966743330,
    "deployed": false,
    "isSeeded": true,
    "isStale": false,
    "lastUpdateTime": 1517966743330,
    "name": "lwapp",
    "namespace": "scalablegroup:application",
    "provisioningState": "DEFINED",
    "qualifier": "application",
    "resourceVersion": 0,
    "targetIdList": [

    ],
    "type": "scalablegroup",
    "cfsChangeInfo": [

    ],
    "customProvisions": [

    ],
    "scalableGroupExternalHandle": "lwapp",
    "scalableGroupType": "APPLICATION",
    "securityGroupTag": 0,
    "state": "ACTIVE",
    "vnAgnostic": true,
    "identitySource": {
      "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
      "instanceId": 10010,
      "authEntityId": 10010,
      "displayName": "10010",
      "authEntityClass": 1738953278,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "state": "INACTIVE",
      "type": "NBAR"
    },
    "indicativeNetworkIdentity": [
      {
        "id": "af5fc216-31b9-4097-b06f-5882f939a835",
        "instanceId": 13795,
        "authEntityId": 13795,
        "displayName": "13795",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "12222,12223",
        "protocol": "UDP",
        "upperPort": 0
      }
    ],
    "networkApplications": [
      {
        "id": "8d93bd82-dfde-437a-b0da-0367ff36a152",
        "instanceId": 11659,
        "authEntityId": 11659,
        "displayName": "11659",
        "authEntityClass": -217092956,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "appProtocol": "udp",
        "applicationGroup": "other",
        "applicationType": "DEFAULT",
        "categoryId": "6daa3dfd-3526-407a-88f0-97824b3f3a24",
        "createTime": 1517966743330,
        "encrypted": "false",
        "engineId": "13",
        "helpString": "Lightweight Access Point Protocol ",
        "ignoreConflict": false,
        "lastUpdateTime": 1517966743330,
        "longDescription": "Lightweight Access Point Protocol (LWAPP) is a protocol that can control multiple Wi-Fi access points at once. This can reduce the amount of time spent on configuring, monitoring or troubleshooting a large network. The system also allows network administrators to closely analyze the network.",
        "name": "lwapp",
        "nbarId": "1352",
        "p2pTechnology": "false",
        "popularity": 1,
        "rank": 65535,
        "references": "http://tools.ietf.org/html/RFC5412",
        "selectorId": "378",
        "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
        "trafficClass": "BULK_DATA",
        "tunnel": "false"
      }
    ],
    "networkIdentity": [
      {
        "id": "1e3a4530-f608-4a31-8ffd-0e70c594e26d",
        "instanceId": 13794,
        "authEntityId": 13794,
        "displayName": "13794",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "12222,12223",
        "protocol": "UDP",
        "upperPort": 0
      }
    ],
    "parentScalableGroup": {
      "idRef": "000c15bf-28fe-4fbb-9b69-35e6502721f1"
    },
    "user": [

    ],
    "userGroup": [

    ]
  },
  {
    "id": "344b03c0-6658-4440-9b51-aa0763a158ad",
    "instanceId": 4434,
    "authEntityId": 4434,
    "displayName": "4434",
    "authEntityClass": -1909073334,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "createTime": 1517966742645,
    "deployed": false,
    "isSeeded": true,
    "isStale": false,
    "lastUpdateTime": 1517966742645,
    "name": "exec",
    "namespace": "scalablegroup:application",
    "provisioningState": "DEFINED",
    "qualifier": "application",
    "resourceVersion": 0,
    "targetIdList": [

    ],
    "type": "scalablegroup",
    "cfsChangeInfo": [

    ],
    "customProvisions": [

    ],
    "scalableGroupExternalHandle": "exec",
    "scalableGroupType": "APPLICATION",
    "securityGroupTag": 0,
    "state": "ACTIVE",
    "vnAgnostic": true,
    "identitySource": {
      "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
      "instanceId": 10010,
      "authEntityId": 10010,
      "displayName": "10010",
      "authEntityClass": 1738953278,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "state": "INACTIVE",
      "type": "NBAR"
    },
    "indicativeNetworkIdentity": [
      {
        "id": "b99f91a6-1fa4-4a9a-823c-52a44a248532",
        "instanceId": 13093,
        "authEntityId": 13093,
        "displayName": "13093",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "512",
        "protocol": "TCP",
        "upperPort": 0
      }
    ],
    "networkApplications": [
      {
        "id": "2fce4a9b-60ca-4527-8ca8-dd0eb37fab0b",
        "instanceId": 11385,
        "authEntityId": 11385,
        "displayName": "11385",
        "authEntityClass": -217092956,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "appProtocol": "tcp",
        "applicationGroup": "other",
        "applicationType": "DEFAULT",
        "categoryId": "1fc17362-9334-4c7b-b752-5afdbb574017",
        "createTime": 1517966742645,
        "encrypted": "false",
        "engineId": "3",
        "helpString": "remote process execution",
        "ignoreConflict": false,
        "lastUpdateTime": 1517966742645,
        "longDescription": "Remote Execition (EXEC) protocol is used to run a program on a remote server as if it was being run on the local machine. This is acomplished by redirecting standard in/out and standard error through the connection.",
        "name": "exec",
        "nbarId": "426",
        "p2pTechnology": "false",
        "popularity": 7,
        "rank": 65535,
        "references": "http://wiki.wireshark.org/Exec",
        "selectorId": "512",
        "subCategory": "787a7d77-977b-4974-89d3-379ba49e88b7",
        "trafficClass": "OPS_ADMIN_MGMT",
        "tunnel": "false"
      }
    ],
    "networkIdentity": [
      {
        "id": "7c29a0b0-520d-4e2d-a59f-d02ef875e4c8",
        "instanceId": 13092,
        "authEntityId": 13092,
        "displayName": "13092",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "512",
        "protocol": "TCP",
        "upperPort": 0
      }
    ],
    "parentScalableGroup": {
      "idRef": "e06dd89f-0a70-4422-ab0b-c2314650a9a7"
    },
    "user": [

    ],
    "userGroup": [

    ]
  },
  {
    "id": "349c70d0-40ad-40b6-b8a9-e19d625d1631",
    "instanceId": 4405,
    "authEntityId": 4405,
    "displayName": "4405",
    "authEntityClass": -1909073334,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "createTime": 1517966742551,
    "deployed": false,
    "isSeeded": true,
    "isStale": false,
    "lastUpdateTime": 1517966742551,
    "name": "emcon",
    "namespace": "scalablegroup:application",
    "provisioningState": "DEFINED",
    "qualifier": "application",
    "resourceVersion": 0,
    "targetIdList": [

    ],
    "type": "scalablegroup",
    "cfsChangeInfo": [

    ],
    "customProvisions": [

    ],
    "scalableGroupExternalHandle": "emcon",
    "scalableGroupType": "APPLICATION",
    "securityGroupTag": 0,
    "state": "ACTIVE",
    "vnAgnostic": true,
    "identitySource": {
      "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
      "instanceId": 10010,
      "authEntityId": 10010,
      "displayName": "10010",
      "authEntityClass": 1738953278,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "state": "INACTIVE",
      "type": "NBAR"
    },
    "indicativeNetworkIdentity": [
      {
        "id": "87875ee0-0f0a-4808-b80e-cc4e4db88e2a",
        "instanceId": 13010,
        "authEntityId": 13010,
        "displayName": "13010",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipProtocolNumbers": "14",
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "protocol": "EMCON",
        "upperPort": 0
      }
    ],
    "networkApplications": [
      {
        "id": "e4d950c8-e586-449f-a4a7-b6977ceeb166",
        "instanceId": 11356,
        "authEntityId": 11356,
        "displayName": "11356",
        "authEntityClass": -217092956,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "appProtocol": "ip",
        "applicationGroup": "other",
        "applicationType": "DEFAULT",
        "categoryId": "6daa3dfd-3526-407a-88f0-97824b3f3a24",
        "createTime": 1517966742551,
        "encrypted": "unassigned",
        "engineId": "1",
        "helpString": "EMCON",
        "ignoreConflict": false,
        "lastUpdateTime": 1517966742551,
        "longDescription": "Registered with IANA as IP Protocol 14",
        "name": "emcon",
        "nbarId": "769",
        "p2pTechnology": "unassigned",
        "popularity": 1,
        "rank": 65535,
        "references": "http://www.iana.org/assignments/protocol-numbers/protocol-numbers.xml",
        "selectorId": "14",
        "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
        "trafficClass": "BULK_DATA",
        "tunnel": "unassigned"
      }
    ],
    "networkIdentity": [
      {
        "id": "1861b20e-32db-485d-8a56-92ad71973a3a",
        "instanceId": 13009,
        "authEntityId": 13009,
        "displayName": "13009",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipProtocolNumbers": "14",
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "protocol": "EMCON",
        "upperPort": 0
      }
    ],
    "parentScalableGroup": {
      "idRef": "000c15bf-28fe-4fbb-9b69-35e6502721f1"
    },
    "user": [

    ],
    "userGroup": [

    ]
  },
  {
    "id": "34a85162-7d83-44cd-b120-44262c3cac6e",
    "instanceId": 4672,
    "authEntityId": 4672,
    "displayName": "4672",
    "authEntityClass": -1909073334,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "createTime": 1517966743252,
    "deployed": false,
    "isSeeded": true,
    "isStale": false,
    "lastUpdateTime": 1517966743252,
    "name": "kerberos",
    "namespace": "scalablegroup:application",
    "provisioningState": "DEFINED",
    "qualifier": "application",
    "resourceVersion": 0,
    "targetIdList": [

    ],
    "type": "scalablegroup",
    "cfsChangeInfo": [

    ],
    "customProvisions": [

    ],
    "scalableGroupExternalHandle": "kerberos",
    "scalableGroupType": "APPLICATION",
    "securityGroupTag": 0,
    "state": "ACTIVE",
    "vnAgnostic": true,
    "identitySource": {
      "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
      "instanceId": 10010,
      "authEntityId": 10010,
      "displayName": "10010",
      "authEntityClass": 1738953278,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "state": "INACTIVE",
      "type": "NBAR"
    },
    "indicativeNetworkIdentity": [
      {
        "id": "42936ef3-fd70-4cc9-adc6-189998aabd0a",
        "instanceId": 13686,
        "authEntityId": 13686,
        "displayName": "13686",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "88",
        "protocol": "TCP",
        "upperPort": 0
      },
      {
        "id": "2ff8d001-ade4-4131-93e4-803cc4171874",
        "instanceId": 13687,
        "authEntityId": 13687,
        "displayName": "13687",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "88",
        "protocol": "UDP",
        "upperPort": 0
      }
    ],
    "networkApplications": [
      {
        "id": "dc0431d0-79c3-40e2-8a87-453e1c93a947",
        "instanceId": 11622,
        "authEntityId": 11622,
        "displayName": "11622",
        "authEntityClass": -217092956,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "appProtocol": "tcp/udp",
        "applicationGroup": "kerberos-group",
        "applicationType": "DEFAULT",
        "categoryId": "1fc17362-9334-4c7b-b752-5afdbb574017",
        "createTime": 1517966743252,
        "encrypted": "false",
        "engineId": "3",
        "helpString": "Kerberos Authentication Protocol",
        "ignoreConflict": false,
        "lastUpdateTime": 1517966743252,
        "longDescription": "Kerberos is a network authentication protocol. The protocol is used to verify identities over the internet using a trusted third party. Extensions of the protocol also use the exchange of cryptographic certification of a public key. Usually the protocol uses TCP/UDP ports 88/749 as default.",
        "name": "kerberos",
        "nbarId": "21",
        "p2pTechnology": "false",
        "popularity": 8,
        "rank": 65535,
        "references": "http://www.ietf.org/rfc/rfc4120.txt",
        "selectorId": "88",
        "subCategory": "e390124a-0fd6-4253-b68e-4b0c1af8cb4b",
        "trafficClass": "OPS_ADMIN_MGMT",
        "tunnel": "false"
      }
    ],
    "networkIdentity": [
      {
        "id": "b6624bdb-42b9-4654-aa45-1ff6878055e3",
        "instanceId": 13684,
        "authEntityId": 13684,
        "displayName": "13684",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "88",
        "protocol": "UDP",
        "upperPort": 0
      },
      {
        "id": "d5dbba86-178c-4987-baff-5e55a906db30",
        "instanceId": 13685,
        "authEntityId": 13685,
        "displayName": "13685",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "88",
        "protocol": "TCP",
        "upperPort": 0
      }
    ],
    "parentScalableGroup": {
      "idRef": "7788bf2d-e289-4597-b500-17aacb7e820e"
    },
    "user": [

    ],
    "userGroup": [

    ]
  },
  {
    "id": "34ec4bef-0608-4742-832f-f9fe41097d4b",
    "instanceId": 15212,
    "authEntityId": 15212,
    "displayName": "15212",
    "authEntityClass": -1909073334,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "createTime": 1517966744495,
    "deployed": false,
    "isSeeded": true,
    "isStale": false,
    "lastUpdateTime": 1517966744495,
    "name": "stack-overflow",
    "namespace": "scalablegroup:application",
    "provisioningState": "DEFINED",
    "qualifier": "application",
    "resourceVersion": 0,
    "targetIdList": [

    ],
    "type": "scalablegroup",
    "cfsChangeInfo": [

    ],
    "customProvisions": [

    ],
    "scalableGroupExternalHandle": "stack-overflow",
    "scalableGroupType": "APPLICATION",
    "securityGroupTag": 0,
    "state": "ACTIVE",
    "vnAgnostic": true,
    "identitySource": {
      "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
      "instanceId": 10010,
      "authEntityId": 10010,
      "displayName": "10010",
      "authEntityClass": 1738953278,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "state": "INACTIVE",
      "type": "NBAR"
    },
    "indicativeNetworkIdentity": [

    ],
    "networkApplications": [
      {
        "id": "78b6fcec-a27a-4143-ae86-141a1af5026c",
        "instanceId": 16155,
        "authEntityId": 16155,
        "displayName": "16155",
        "authEntityClass": -217092956,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "appProtocol": "tcp/udp",
        "applicationGroup": "other",
        "applicationType": "DEFAULT",
        "categoryId": "878644b5-fe91-45b4-9204-31c84a73ba48",
        "createTime": 1517966744495,
        "encrypted": "false",
        "engineId": "13",
        "helpString": "Stack Overflow - Question and Answering site for programmers.",
        "ignoreConflict": false,
        "lastUpdateTime": 1517966744495,
        "longDescription": "Stack Overflow - Question and Answering site for programmers.",
        "name": "stack-overflow",
        "nbarId": "264",
        "p2pTechnology": "false",
        "popularity": 3,
        "rank": 65535,
        "references": "http://stackoverflow.com",
        "selectorId": "600",
        "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
        "trafficClass": "TRANSACTIONAL_DATA",
        "tunnel": "false"
      }
    ],
    "networkIdentity": [
      {
        "id": "adbf04d2-ba04-43c6-9d12-db4d28b93b5b",
        "instanceId": 17276,
        "authEntityId": 17276,
        "displayName": "17276",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "53,5353",
        "protocol": "UDP",
        "upperPort": 0
      },
      {
        "id": "08d3336c-49b7-4395-9dfc-7e83e5d3168e",
        "instanceId": 17277,
        "authEntityId": 17277,
        "displayName": "17277",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "53,80,443,5353",
        "protocol": "TCP",
        "upperPort": 0
      }
    ],
    "parentScalableGroup": {
      "idRef": "28e08de4-d7b2-4521-84ce-bc1e5ee0b9cc"
    },
    "user": [

    ],
    "userGroup": [

    ]
  },
  {
    "id": "34f4ffb8-373c-438a-8eaa-60cba7d8d683",
    "instanceId": 4604,
    "authEntityId": 4604,
    "displayName": "4604",
    "authEntityClass": -1909073334,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "createTime": 1517966743100,
    "deployed": false,
    "isSeeded": true,
    "isStale": false,
    "lastUpdateTime": 1517966743100,
    "name": "infusionsoft",
    "namespace": "scalablegroup:application",
    "provisioningState": "DEFINED",
    "qualifier": "application",
    "resourceVersion": 0,
    "targetIdList": [

    ],
    "type": "scalablegroup",
    "cfsChangeInfo": [

    ],
    "customProvisions": [

    ],
    "scalableGroupExternalHandle": "infusionsoft",
    "scalableGroupType": "APPLICATION",
    "securityGroupTag": 0,
    "state": "ACTIVE",
    "vnAgnostic": true,
    "identitySource": {
      "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
      "instanceId": 10010,
      "authEntityId": 10010,
      "displayName": "10010",
      "authEntityClass": 1738953278,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "state": "INACTIVE",
      "type": "NBAR"
    },
    "indicativeNetworkIdentity": [

    ],
    "networkApplications": [
      {
        "id": "2e0f33ea-4150-47d0-a0fe-e26ec36b4565",
        "instanceId": 11554,
        "authEntityId": 11554,
        "displayName": "11554",
        "authEntityClass": -217092956,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "appProtocol": "tcp/udp",
        "applicationGroup": "other",
        "applicationType": "DEFAULT",
        "categoryId": "878644b5-fe91-45b4-9204-31c84a73ba48",
        "createTime": 1517966743100,
        "encrypted": "false",
        "engineId": "13",
        "helpString": "Infusionsoft - Software company providing solutions for sales and marketing.",
        "ignoreConflict": false,
        "lastUpdateTime": 1517966743100,
        "longDescription": "Infusionsoft - Software company providing solutions for sales and marketing.",
        "name": "infusionsoft",
        "nbarId": "1643",
        "p2pTechnology": "false",
        "popularity": 3,
        "rank": 65535,
        "references": "http://infusionsoft.com",
        "selectorId": "713",
        "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
        "trafficClass": "TRANSACTIONAL_DATA",
        "tunnel": "false"
      }
    ],
    "networkIdentity": [
      {
        "id": "3151df28-ed00-4a91-9078-a0d42c48ea03",
        "instanceId": 13523,
        "authEntityId": 13523,
        "displayName": "13523",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "53,80,443,5353",
        "protocol": "TCP",
        "upperPort": 0
      },
      {
        "id": "31d50df7-253f-400a-83c7-d32ccc6ef580",
        "instanceId": 13524,
        "authEntityId": 13524,
        "displayName": "13524",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "53,5353",
        "protocol": "UDP",
        "upperPort": 0
      }
    ],
    "parentScalableGroup": {
      "idRef": "28e08de4-d7b2-4521-84ce-bc1e5ee0b9cc"
    },
    "user": [

    ],
    "userGroup": [

    ]
  },
  {
    "id": "3501c98e-9d77-41a7-b99a-bc3c67e17e20",
    "instanceId": 15045,
    "authEntityId": 15045,
    "displayName": "15045",
    "authEntityClass": -1909073334,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "createTime": 1517966744103,
    "deployed": false,
    "isSeeded": true,
    "isStale": false,
    "lastUpdateTime": 1517966744103,
    "name": "reuters",
    "namespace": "scalablegroup:application",
    "provisioningState": "DEFINED",
    "qualifier": "application",
    "resourceVersion": 0,
    "targetIdList": [

    ],
    "type": "scalablegroup",
    "cfsChangeInfo": [

    ],
    "customProvisions": [

    ],
    "scalableGroupExternalHandle": "reuters",
    "scalableGroupType": "APPLICATION",
    "securityGroupTag": 0,
    "state": "ACTIVE",
    "vnAgnostic": true,
    "identitySource": {
      "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
      "instanceId": 10010,
      "authEntityId": 10010,
      "displayName": "10010",
      "authEntityClass": 1738953278,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "state": "INACTIVE",
      "type": "NBAR"
    },
    "indicativeNetworkIdentity": [

    ],
    "networkApplications": [
      {
        "id": "80b7f9c3-4e46-4b27-babb-944fdc1cf08e",
        "instanceId": 11984,
        "authEntityId": 11984,
        "displayName": "11984",
        "authEntityClass": -217092956,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "appProtocol": "tcp/udp",
        "applicationGroup": "other",
        "applicationType": "DEFAULT",
        "categoryId": "878644b5-fe91-45b4-9204-31c84a73ba48",
        "createTime": 1517966744103,
        "encrypted": "false",
        "engineId": "13",
        "helpString": "Reuters - News portal.",
        "ignoreConflict": false,
        "lastUpdateTime": 1517966744103,
        "longDescription": "Reuters - News portal.",
        "name": "reuters",
        "nbarId": "1621",
        "p2pTechnology": "false",
        "popularity": 3,
        "rank": 65535,
        "references": "http://reuters.com",
        "selectorId": "691",
        "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
        "trafficClass": "TRANSACTIONAL_DATA",
        "tunnel": "false"
      }
    ],
    "networkIdentity": [
      {
        "id": "7430c3be-efdc-4a68-bd2b-f3697433e507",
        "instanceId": 14765,
        "authEntityId": 14765,
        "displayName": "14765",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "53,80,443,5353",
        "protocol": "TCP",
        "upperPort": 0
      },
      {
        "id": "6ae35fe8-cc83-4d3e-b488-91fd47d5670e",
        "instanceId": 14766,
        "authEntityId": 14766,
        "displayName": "14766",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "53,5353",
        "protocol": "UDP",
        "upperPort": 0
      }
    ],
    "parentScalableGroup": {
      "idRef": "28e08de4-d7b2-4521-84ce-bc1e5ee0b9cc"
    },
    "user": [

    ],
    "userGroup": [

    ]
  },
  {
    "id": "3552617e-b66d-46e6-934c-8c01aa867b7b",
    "instanceId": 4450,
    "authEntityId": 4450,
    "displayName": "4450",
    "authEntityClass": -1909073334,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "createTime": 1517966742703,
    "deployed": false,
    "isSeeded": true,
    "isStale": false,
    "lastUpdateTime": 1517966742703,
    "name": "finger",
    "namespace": "scalablegroup:application",
    "provisioningState": "DEFINED",
    "qualifier": "application",
    "resourceVersion": 0,
    "targetIdList": [

    ],
    "type": "scalablegroup",
    "cfsChangeInfo": [

    ],
    "customProvisions": [

    ],
    "scalableGroupExternalHandle": "finger",
    "scalableGroupType": "APPLICATION",
    "securityGroupTag": 0,
    "state": "ACTIVE",
    "vnAgnostic": true,
    "identitySource": {
      "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
      "instanceId": 10010,
      "authEntityId": 10010,
      "displayName": "10010",
      "authEntityClass": 1738953278,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "state": "INACTIVE",
      "type": "NBAR"
    },
    "indicativeNetworkIdentity": [
      {
        "id": "fc114065-99a5-471f-8ea5-bda7a162c87c",
        "instanceId": 13124,
        "authEntityId": 13124,
        "displayName": "13124",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "79",
        "protocol": "UDP",
        "upperPort": 0
      },
      {
        "id": "4444099a-75c6-418d-a804-555635846564",
        "instanceId": 13125,
        "authEntityId": 13125,
        "displayName": "13125",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "79",
        "protocol": "TCP",
        "upperPort": 0
      }
    ],
    "networkApplications": [
      {
        "id": "b8520cbe-bfe9-4757-bacf-5e1dc9e7c628",
        "instanceId": 11401,
        "authEntityId": 11401,
        "displayName": "11401",
        "authEntityClass": -217092956,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "appProtocol": "tcp/udp",
        "applicationGroup": "other",
        "applicationType": "DEFAULT",
        "categoryId": "1fc17362-9334-4c7b-b752-5afdbb574017",
        "createTime": 1517966742703,
        "encrypted": "false",
        "engineId": "3",
        "helpString": "Finger",
        "ignoreConflict": false,
        "lastUpdateTime": 1517966742703,
        "longDescription": "The Finger/Name protocol provides an interface to the Name and Finger programs at several network sites.  These programs return a friendly, human-oriented status report on either the system at the moment or a particular person in depth.",
        "name": "finger",
        "nbarId": "14",
        "p2pTechnology": "false",
        "popularity": 4,
        "rank": 65535,
        "references": "http://www.ietf.org/rfc/rfc1288.txt",
        "selectorId": "79",
        "subCategory": "f3c0f761-d894-4112-ab89-b90fc84fd660",
        "trafficClass": "OPS_ADMIN_MGMT",
        "tunnel": "false"
      }
    ],
    "networkIdentity": [
      {
        "id": "be3bf863-ce52-4691-b7a1-d6ac01544825",
        "instanceId": 13122,
        "authEntityId": 13122,
        "displayName": "13122",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "79",
        "protocol": "UDP",
        "upperPort": 0
      },
      {
        "id": "f9a007ec-56c3-4150-a71d-8a325daaaeba",
        "instanceId": 13123,
        "authEntityId": 13123,
        "displayName": "13123",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "79",
        "protocol": "TCP",
        "upperPort": 0
      }
    ],
    "parentScalableGroup": {
      "idRef": "5fcd74d4-8726-47f7-a088-c457863da7ef"
    },
    "user": [

    ],
    "userGroup": [

    ]
  },
  {
    "id": "355595f1-d082-4575-b276-c0e665a210f6",
    "instanceId": 4070,
    "authEntityId": 4070,
    "displayName": "4070",
    "authEntityClass": -1909073334,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "createTime": 1517966740821,
    "deployed": false,
    "isSeeded": true,
    "isStale": false,
    "lastUpdateTime": 1517966740821,
    "name": "airplay",
    "namespace": "scalablegroup:application",
    "provisioningState": "DEFINED",
    "qualifier": "application",
    "resourceVersion": 0,
    "targetIdList": [

    ],
    "type": "scalablegroup",
    "cfsChangeInfo": [

    ],
    "customProvisions": [

    ],
    "scalableGroupExternalHandle": "airplay",
    "scalableGroupType": "APPLICATION",
    "securityGroupTag": 0,
    "state": "ACTIVE",
    "vnAgnostic": true,
    "identitySource": {
      "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
      "instanceId": 10010,
      "authEntityId": 10010,
      "displayName": "10010",
      "authEntityClass": 1738953278,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "state": "INACTIVE",
      "type": "NBAR"
    },
    "indicativeNetworkIdentity": [

    ],
    "networkApplications": [
      {
        "id": "edb67fc3-9f0c-42eb-a846-c798c2c60617",
        "instanceId": 11038,
        "authEntityId": 11038,
        "displayName": "11038",
        "authEntityClass": -217092956,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "appProtocol": "tcp",
        "applicationGroup": "other",
        "applicationType": "DEFAULT",
        "categoryId": "a09ef1b3-ba26-4372-aee8-309d50697e76",
        "createTime": 1517966740821,
        "encrypted": "false",
        "engineId": "13",
        "helpString": "Apple AirPlay wireless media streaming",
        "ignoreConflict": false,
        "lastUpdateTime": 1517966740821,
        "longDescription": "AirPlay (previously called AirTunes) is a proprietary protocol stack/suite developed by Apple Inc. that allows wireless streaming of audio, video and photos, together with related metadata between devices.",
        "name": "airplay",
        "nbarId": "1483",
        "p2pTechnology": "unassigned",
        "popularity": 9,
        "rank": 65535,
        "references": "http://www.apple.com/airplay/",
        "selectorId": "549",
        "subCategory": "85a797c0-aef8-4284-91a7-677f33e29abb",
        "trafficClass": "MULTIMEDIA_STREAMING",
        "tunnel": "false"
      }
    ],
    "networkIdentity": [
      {
        "id": "754a4db2-c537-4eff-9e16-732d03516d56",
        "instanceId": 12095,
        "authEntityId": 12095,
        "displayName": "12095",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "80,554,8554",
        "protocol": "TCP",
        "upperPort": 0
      }
    ],
    "parentScalableGroup": {
      "idRef": "fd6264c9-c16a-4311-a335-a3db039256f3"
    },
    "user": [

    ],
    "userGroup": [

    ]
  },
  {
    "id": "359b3953-af93-470e-9319-3be2a0693311",
    "instanceId": 4812,
    "authEntityId": 4812,
    "displayName": "4812",
    "authEntityClass": -1909073334,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "createTime": 1517966743541,
    "deployed": false,
    "isSeeded": true,
    "isStale": false,
    "lastUpdateTime": 1517966743541,
    "name": "my-jabber-ft",
    "namespace": "scalablegroup:application",
    "provisioningState": "DEFINED",
    "qualifier": "application",
    "resourceVersion": 0,
    "targetIdList": [

    ],
    "type": "scalablegroup",
    "cfsChangeInfo": [

    ],
    "customProvisions": [

    ],
    "scalableGroupExternalHandle": "my-jabber-ft",
    "scalableGroupType": "APPLICATION",
    "securityGroupTag": 0,
    "state": "ACTIVE",
    "vnAgnostic": true,
    "identitySource": {
      "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
      "instanceId": 10010,
      "authEntityId": 10010,
      "displayName": "10010",
      "authEntityClass": 1738953278,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "state": "INACTIVE",
      "type": "NBAR"
    },
    "indicativeNetworkIdentity": [

    ],
    "networkApplications": [
      {
        "id": "541643cb-21a5-4167-b12d-499ba85381a2",
        "instanceId": 11761,
        "authEntityId": 11761,
        "displayName": "11761",
        "authEntityClass": -217092956,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "applicationGroup": "other",
        "applicationType": "DEFAULT",
        "categoryId": "6daa3dfd-3526-407a-88f0-97824b3f3a24",
        "createTime": 1517966743541,
        "encrypted": "unassigned",
        "engineId": "13",
        "helpString": "DEPRECATED, traffic will not match",
        "ignoreConflict": false,
        "lastUpdateTime": 1517966743541,
        "longDescription": "My Jabber File Transfer (My Jabber FT) is a feature that allows users to transfer files  with each other. MyJabber interacts with AIM, ICQ, MSN, and Yahoo, and it has the ability to create conference rooms and person-to-person text talk. Transfers with Jabber can either be done direct client-to-client or via a proxy.",
        "name": "my-jabber-ft",
        "nbarId": "1205",
        "p2pTechnology": "unassigned",
        "popularity": 0,
        "rank": 65535,
        "references": "http://www.myjabber.com/",
        "selectorId": "312",
        "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
        "trafficClass": "BULK_DATA",
        "tunnel": "unassigned"
      }
    ],
    "networkIdentity": [

    ],
    "parentScalableGroup": {
      "idRef": "000c15bf-28fe-4fbb-9b69-35e6502721f1"
    },
    "user": [

    ],
    "userGroup": [

    ]
  },
  {
    "id": "364cb7c5-c6e0-45c0-a1b7-c6160da01e35",
    "instanceId": 4788,
    "authEntityId": 4788,
    "displayName": "4788",
    "authEntityClass": -1909073334,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "createTime": 1517966743493,
    "deployed": false,
    "isSeeded": true,
    "isStale": false,
    "lastUpdateTime": 1517966743493,
    "name": "ms-shuttle",
    "namespace": "scalablegroup:application",
    "provisioningState": "DEFINED",
    "qualifier": "application",
    "resourceVersion": 0,
    "targetIdList": [

    ],
    "type": "scalablegroup",
    "cfsChangeInfo": [

    ],
    "customProvisions": [

    ],
    "scalableGroupExternalHandle": "ms-shuttle",
    "scalableGroupType": "APPLICATION",
    "securityGroupTag": 0,
    "state": "ACTIVE",
    "vnAgnostic": true,
    "identitySource": {
      "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
      "instanceId": 10010,
      "authEntityId": 10010,
      "displayName": "10010",
      "authEntityClass": 1738953278,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "state": "INACTIVE",
      "type": "NBAR"
    },
    "indicativeNetworkIdentity": [
      {
        "id": "fcf7d4c6-7a8d-4631-b8e5-093bdbee30dd",
        "instanceId": 14008,
        "authEntityId": 14008,
        "displayName": "14008",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "568",
        "protocol": "TCP",
        "upperPort": 0
      },
      {
        "id": "e107adc5-e181-4cd4-86c6-62897c4bb508",
        "instanceId": 14009,
        "authEntityId": 14009,
        "displayName": "14009",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "568",
        "protocol": "UDP",
        "upperPort": 0
      }
    ],
    "networkApplications": [
      {
        "id": "a40ca76b-7ff7-4ca9-8694-e6a02dc46429",
        "instanceId": 11737,
        "authEntityId": 11737,
        "displayName": "11737",
        "authEntityClass": -217092956,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "appProtocol": "tcp/udp",
        "applicationGroup": "other",
        "applicationType": "DEFAULT",
        "categoryId": "6daa3dfd-3526-407a-88f0-97824b3f3a24",
        "createTime": 1517966743493,
        "encrypted": "false",
        "engineId": "3",
        "helpString": "microsoft shuttle",
        "ignoreConflict": false,
        "lastUpdateTime": 1517966743493,
        "longDescription": "Registered with IANA on port 568 TCP/UDP",
        "name": "ms-shuttle",
        "nbarId": "483",
        "p2pTechnology": "false",
        "popularity": 2,
        "rank": 65535,
        "references": "http://www.iana.org/assignments/service-names-port-numbers/service-names-port-numbers.xml",
        "selectorId": "568",
        "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
        "trafficClass": "BULK_DATA",
        "tunnel": "false"
      }
    ],
    "networkIdentity": [
      {
        "id": "862ad0ae-6b92-46e3-a11e-e547c05f894d",
        "instanceId": 14006,
        "authEntityId": 14006,
        "displayName": "14006",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "568",
        "protocol": "UDP",
        "upperPort": 0
      },
      {
        "id": "93127bf9-8bc5-43f3-b39e-91e16799390c",
        "instanceId": 14007,
        "authEntityId": 14007,
        "displayName": "14007",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "568",
        "protocol": "TCP",
        "upperPort": 0
      }
    ],
    "parentScalableGroup": {
      "idRef": "000c15bf-28fe-4fbb-9b69-35e6502721f1"
    },
    "user": [

    ],
    "userGroup": [

    ]
  },
  {
    "id": "368675ce-5df6-4d02-9d98-71d46908022b",
    "instanceId": 4479,
    "authEntityId": 4479,
    "displayName": "4479",
    "authEntityClass": -1909073334,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "createTime": 1517966742774,
    "deployed": false,
    "isSeeded": true,
    "isStale": false,
    "lastUpdateTime": 1517966742774,
    "name": "ggf-ncp",
    "namespace": "scalablegroup:application",
    "provisioningState": "DEFINED",
    "qualifier": "application",
    "resourceVersion": 0,
    "targetIdList": [

    ],
    "type": "scalablegroup",
    "cfsChangeInfo": [

    ],
    "customProvisions": [

    ],
    "scalableGroupExternalHandle": "ggf-ncp",
    "scalableGroupType": "APPLICATION",
    "securityGroupTag": 0,
    "state": "ACTIVE",
    "vnAgnostic": true,
    "identitySource": {
      "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
      "instanceId": 10010,
      "authEntityId": 10010,
      "displayName": "10010",
      "authEntityClass": 1738953278,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "state": "INACTIVE",
      "type": "NBAR"
    },
    "indicativeNetworkIdentity": [
      {
        "id": "440c3aca-f8ae-4c9c-b7cf-83c7567d495d",
        "instanceId": 13200,
        "authEntityId": 13200,
        "displayName": "13200",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "678",
        "protocol": "UDP",
        "upperPort": 0
      },
      {
        "id": "88ebef4a-7959-472f-9665-42b3feec9bfd",
        "instanceId": 13199,
        "authEntityId": 13199,
        "displayName": "13199",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "678",
        "protocol": "TCP",
        "upperPort": 0
      }
    ],
    "networkApplications": [
      {
        "id": "a4990567-ef01-4574-99b5-0b731ba23725",
        "instanceId": 11430,
        "authEntityId": 11430,
        "displayName": "11430",
        "authEntityClass": -217092956,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "appProtocol": "tcp/udp",
        "applicationGroup": "other",
        "applicationType": "DEFAULT",
        "categoryId": "6daa3dfd-3526-407a-88f0-97824b3f3a24",
        "createTime": 1517966742774,
        "encrypted": "false",
        "engineId": "3",
        "helpString": "GNU Generation Foundation NCP",
        "ignoreConflict": false,
        "lastUpdateTime": 1517966742774,
        "longDescription": "Registered with IANA on port 678 TCP/UDP",
        "name": "ggf-ncp",
        "nbarId": "586",
        "p2pTechnology": "false",
        "popularity": 1,
        "rank": 65535,
        "references": "http://www.iana.org/assignments/service-names-port-numbers/service-names-port-numbers.xml",
        "selectorId": "678",
        "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
        "trafficClass": "BULK_DATA",
        "tunnel": "false"
      }
    ],
    "networkIdentity": [
      {
        "id": "25cd7ea6-1d6e-403d-bc95-65a1d27ee9f3",
        "instanceId": 13197,
        "authEntityId": 13197,
        "displayName": "13197",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "678",
        "protocol": "UDP",
        "upperPort": 0
      },
      {
        "id": "80efff8c-0281-4e29-9e02-4140a65ab1d1",
        "instanceId": 13198,
        "authEntityId": 13198,
        "displayName": "13198",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "678",
        "protocol": "TCP",
        "upperPort": 0
      }
    ],
    "parentScalableGroup": {
      "idRef": "000c15bf-28fe-4fbb-9b69-35e6502721f1"
    },
    "user": [

    ],
    "userGroup": [

    ]
  },
  {
    "id": "36b94c53-0873-469b-b0c0-45e0dc7c1f21",
    "instanceId": 4952,
    "authEntityId": 4952,
    "displayName": "4952",
    "authEntityClass": -1909073334,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "createTime": 1517966743889,
    "deployed": false,
    "isSeeded": true,
    "isStale": false,
    "lastUpdateTime": 1517966743889,
    "name": "personal-link",
    "namespace": "scalablegroup:application",
    "provisioningState": "DEFINED",
    "qualifier": "application",
    "resourceVersion": 0,
    "targetIdList": [

    ],
    "type": "scalablegroup",
    "cfsChangeInfo": [

    ],
    "customProvisions": [

    ],
    "scalableGroupExternalHandle": "personal-link",
    "scalableGroupType": "APPLICATION",
    "securityGroupTag": 0,
    "state": "ACTIVE",
    "vnAgnostic": true,
    "identitySource": {
      "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
      "instanceId": 10010,
      "authEntityId": 10010,
      "displayName": "10010",
      "authEntityClass": 1738953278,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "state": "INACTIVE",
      "type": "NBAR"
    },
    "indicativeNetworkIdentity": [
      {
        "id": "eae4fbd8-6066-4cf2-9a8b-c2d371cffe3a",
        "instanceId": 14514,
        "authEntityId": 14514,
        "displayName": "14514",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "281",
        "protocol": "TCP",
        "upperPort": 0
      },
      {
        "id": "a8993292-6d36-4591-8108-6add0c49bdd4",
        "instanceId": 14515,
        "authEntityId": 14515,
        "displayName": "14515",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "281",
        "protocol": "UDP",
        "upperPort": 0
      }
    ],
    "networkApplications": [
      {
        "id": "a025bfb5-e629-48c6-ac98-1f9e452fd4f0",
        "instanceId": 11901,
        "authEntityId": 11901,
        "displayName": "11901",
        "authEntityClass": -217092956,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "appProtocol": "tcp/udp",
        "applicationGroup": "other",
        "applicationType": "DEFAULT",
        "categoryId": "6daa3dfd-3526-407a-88f0-97824b3f3a24",
        "createTime": 1517966743889,
        "encrypted": "false",
        "engineId": "3",
        "helpString": "personal-link",
        "ignoreConflict": false,
        "lastUpdateTime": 1517966743889,
        "longDescription": "Registered with IANA on port 281 TCP/UDP",
        "name": "personal-link",
        "nbarId": "1142",
        "p2pTechnology": "false",
        "popularity": 1,
        "rank": 65535,
        "references": "http://www.iana.org/assignments/service-names-port-numbers/service-names-port-numbers.xml",
        "selectorId": "281",
        "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
        "trafficClass": "BULK_DATA",
        "tunnel": "false"
      }
    ],
    "networkIdentity": [
      {
        "id": "eea14579-e4a5-420d-bc77-41e59c350196",
        "instanceId": 14512,
        "authEntityId": 14512,
        "displayName": "14512",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "281",
        "protocol": "TCP",
        "upperPort": 0
      },
      {
        "id": "956f9d57-64e8-4b88-a6ac-3ec46642a7a3",
        "instanceId": 14513,
        "authEntityId": 14513,
        "displayName": "14513",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "281",
        "protocol": "UDP",
        "upperPort": 0
      }
    ],
    "parentScalableGroup": {
      "idRef": "000c15bf-28fe-4fbb-9b69-35e6502721f1"
    },
    "user": [

    ],
    "userGroup": [

    ]
  },
  {
    "id": "36d962cf-57ff-43ce-a38a-056564d370ac",
    "instanceId": 4211,
    "authEntityId": 4211,
    "displayName": "4211",
    "authEntityClass": -1909073334,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "createTime": 1517966741742,
    "deployed": false,
    "isSeeded": true,
    "isStale": false,
    "lastUpdateTime": 1517966741742,
    "name": "capwap-control",
    "namespace": "scalablegroup:application",
    "provisioningState": "DEFINED",
    "qualifier": "application",
    "resourceVersion": 0,
    "targetIdList": [

    ],
    "type": "scalablegroup",
    "cfsChangeInfo": [

    ],
    "customProvisions": [

    ],
    "scalableGroupExternalHandle": "capwap-control",
    "scalableGroupType": "APPLICATION",
    "securityGroupTag": 0,
    "state": "ACTIVE",
    "vnAgnostic": true,
    "identitySource": {
      "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
      "instanceId": 10010,
      "authEntityId": 10010,
      "displayName": "10010",
      "authEntityClass": 1738953278,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "state": "INACTIVE",
      "type": "NBAR"
    },
    "indicativeNetworkIdentity": [
      {
        "id": "26ae523c-f869-4f6c-8640-3d7cf2d82e17",
        "instanceId": 12462,
        "authEntityId": 12462,
        "displayName": "12462",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "5246",
        "protocol": "UDP",
        "upperPort": 0
      }
    ],
    "networkApplications": [
      {
        "id": "10f0b72e-87da-4ea1-8972-77b1e8c48d4e",
        "instanceId": 11167,
        "authEntityId": 11167,
        "displayName": "11167",
        "authEntityClass": -217092956,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "appProtocol": "udp",
        "applicationGroup": "capwap-group",
        "applicationType": "DEFAULT",
        "categoryId": "1fc17362-9334-4c7b-b752-5afdbb574017",
        "createTime": 1517966741742,
        "encrypted": "true",
        "engineId": "3",
        "helpString": "Control And Provisioning of Wireless Access Points Control Protocol",
        "ignoreConflict": false,
        "lastUpdateTime": 1517966741742,
        "longDescription": "Control And Provisioning of Wireless Access Points (CAPWAP) is a protocol used for Access Controllers (AC) to manage and control Wireless Termination Points (WTPs). CAPWAP is designed to centralize WLANs. CAPWAP control represents the control traffic passed from the WTP to AC or vice versa.",
        "name": "capwap-control",
        "nbarId": "1221",
        "p2pTechnology": "false",
        "popularity": 6,
        "rank": 65535,
        "references": "http://tools.ietf.org/html/rfc5415",
        "selectorId": "5246",
        "subCategory": "7cc1b3a6-4102-44e9-b522-0e71afe39a03",
        "trafficClass": "NETWORK_CONTROL",
        "tunnel": "true"
      }
    ],
    "networkIdentity": [
      {
        "id": "45360bde-f981-4cfa-8810-473cbd8c151b",
        "instanceId": 12461,
        "authEntityId": 12461,
        "displayName": "12461",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "5246",
        "protocol": "UDP",
        "upperPort": 0
      }
    ],
    "parentScalableGroup": {
      "idRef": "77e75842-e98b-4112-ad0c-300db4bb11be"
    },
    "user": [

    ],
    "userGroup": [

    ]
  },
  {
    "id": "36dfe451-0752-4d8c-b3c9-d83c768f5571",
    "instanceId": 4279,
    "authEntityId": 4279,
    "displayName": "4279",
    "authEntityClass": -1909073334,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "createTime": 1517966742121,
    "deployed": false,
    "isSeeded": true,
    "isStale": false,
    "lastUpdateTime": 1517966742121,
    "name": "conduit-com",
    "namespace": "scalablegroup:application",
    "provisioningState": "DEFINED",
    "qualifier": "application",
    "resourceVersion": 0,
    "targetIdList": [

    ],
    "type": "scalablegroup",
    "cfsChangeInfo": [

    ],
    "customProvisions": [

    ],
    "scalableGroupExternalHandle": "conduit-com",
    "scalableGroupType": "APPLICATION",
    "securityGroupTag": 0,
    "state": "ACTIVE",
    "vnAgnostic": true,
    "identitySource": {
      "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
      "instanceId": 10010,
      "authEntityId": 10010,
      "displayName": "10010",
      "authEntityClass": 1738953278,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "state": "INACTIVE",
      "type": "NBAR"
    },
    "indicativeNetworkIdentity": [

    ],
    "networkApplications": [
      {
        "id": "98c9466b-e3f3-43ce-ae67-2033a5235255",
        "instanceId": 11232,
        "authEntityId": 11232,
        "displayName": "11232",
        "authEntityClass": -217092956,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "appProtocol": "tcp/udp",
        "applicationGroup": "other",
        "applicationType": "DEFAULT",
        "categoryId": "878644b5-fe91-45b4-9204-31c84a73ba48",
        "createTime": 1517966742121,
        "encrypted": "false",
        "engineId": "13",
        "helpString": "Conduit - Online website to create community toolbar.",
        "ignoreConflict": false,
        "lastUpdateTime": 1517966742121,
        "longDescription": "Conduit - Online website to create community toolbar.",
        "name": "conduit-com",
        "nbarId": "260",
        "p2pTechnology": "false",
        "popularity": 3,
        "rank": 65535,
        "references": "http://conduit.com",
        "selectorId": "468",
        "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
        "trafficClass": "TRANSACTIONAL_DATA",
        "tunnel": "false"
      }
    ],
    "networkIdentity": [
      {
        "id": "8501f688-0605-4413-8828-320f1659bd19",
        "instanceId": 12614,
        "authEntityId": 12614,
        "displayName": "12614",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "53,5353",
        "protocol": "UDP",
        "upperPort": 0
      },
      {
        "id": "7681f547-ba5f-4e25-843a-1440ab51ca71",
        "instanceId": 12615,
        "authEntityId": 12615,
        "displayName": "12615",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "53,80,443,5353",
        "protocol": "TCP",
        "upperPort": 0
      }
    ],
    "parentScalableGroup": {
      "idRef": "28e08de4-d7b2-4521-84ce-bc1e5ee0b9cc"
    },
    "user": [

    ],
    "userGroup": [

    ]
  },
  {
    "id": "36e0350b-b408-4d1e-925a-d34df2b2011d",
    "instanceId": 4978,
    "authEntityId": 4978,
    "displayName": "4978",
    "authEntityClass": -1909073334,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "createTime": 1517966743958,
    "deployed": false,
    "isSeeded": true,
    "isStale": false,
    "lastUpdateTime": 1517966743958,
    "name": "ppstream",
    "namespace": "scalablegroup:application",
    "provisioningState": "DEFINED",
    "qualifier": "application",
    "resourceVersion": 0,
    "targetIdList": [

    ],
    "type": "scalablegroup",
    "cfsChangeInfo": [

    ],
    "customProvisions": [

    ],
    "scalableGroupExternalHandle": "ppstream",
    "scalableGroupType": "APPLICATION",
    "securityGroupTag": 0,
    "state": "ACTIVE",
    "vnAgnostic": true,
    "identitySource": {
      "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
      "instanceId": 10010,
      "authEntityId": 10010,
      "displayName": "10010",
      "authEntityClass": 1738953278,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "state": "INACTIVE",
      "type": "NBAR"
    },
    "indicativeNetworkIdentity": [

    ],
    "networkApplications": [
      {
        "id": "9debeb0e-924d-4559-a5fb-6a2319cc1a5d",
        "instanceId": 11927,
        "authEntityId": 11927,
        "displayName": "11927",
        "authEntityClass": -217092956,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "appProtocol": "tcp/udp",
        "applicationGroup": "other",
        "applicationType": "DEFAULT",
        "categoryId": "a09ef1b3-ba26-4372-aee8-309d50697e76",
        "createTime": 1517966743958,
        "encrypted": "false",
        "engineId": "13",
        "helpString": "P2P video streaming application",
        "ignoreConflict": false,
        "lastUpdateTime": 1517966743958,
        "longDescription": "PPStream is a Chinese peer-to-peer (P2P) streaming video network software that broadcasts TV programs to broadband users. Compared to traditional stream media, PPStream adopts P2P streaming technology and supports full-scale visits with tens of thousands of users online.",
        "name": "ppstream",
        "nbarId": "698",
        "p2pTechnology": "true",
        "popularity": 7,
        "rank": 65535,
        "references": "http://www.pps.tv/en/",
        "selectorId": "423",
        "subCategory": "85a797c0-aef8-4284-91a7-677f33e29abb",
        "trafficClass": "MULTIMEDIA_STREAMING",
        "tunnel": "false"
      }
    ],
    "networkIdentity": [
      {
        "id": "4a100568-4a98-4be7-8ca3-2d30df20423b",
        "instanceId": 14588,
        "authEntityId": 14588,
        "displayName": "14588",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "53,5353",
        "protocol": "UDP",
        "upperPort": 0
      },
      {
        "id": "884c253f-8022-4051-9c7b-50330ec5d944",
        "instanceId": 14589,
        "authEntityId": 14589,
        "displayName": "14589",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "53,80,5353",
        "protocol": "TCP",
        "upperPort": 0
      }
    ],
    "parentScalableGroup": {
      "idRef": "fd6264c9-c16a-4311-a335-a3db039256f3"
    },
    "user": [

    ],
    "userGroup": [

    ]
  },
  {
    "id": "36ece161-b92c-4792-9694-686753ce78b9",
    "instanceId": 4728,
    "authEntityId": 4728,
    "displayName": "4728",
    "authEntityClass": -1909073334,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "createTime": 1517966743370,
    "deployed": false,
    "isSeeded": true,
    "isStale": false,
    "lastUpdateTime": 1517966743370,
    "name": "mcafee-antivirus",
    "namespace": "scalablegroup:application",
    "provisioningState": "DEFINED",
    "qualifier": "application",
    "resourceVersion": 0,
    "targetIdList": [

    ],
    "type": "scalablegroup",
    "cfsChangeInfo": [

    ],
    "customProvisions": [

    ],
    "scalableGroupExternalHandle": "mcafee-antivirus",
    "scalableGroupType": "APPLICATION",
    "securityGroupTag": 0,
    "state": "ACTIVE",
    "vnAgnostic": true,
    "identitySource": {
      "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
      "instanceId": 10010,
      "authEntityId": 10010,
      "displayName": "10010",
      "authEntityClass": 1738953278,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "state": "INACTIVE",
      "type": "NBAR"
    },
    "indicativeNetworkIdentity": [

    ],
    "networkApplications": [
      {
        "id": "c657d9d9-7a60-40ec-907b-350df697a991",
        "instanceId": 11678,
        "authEntityId": 11678,
        "displayName": "11678",
        "authEntityClass": -217092956,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "appProtocol": "tcp/udp",
        "applicationGroup": "other",
        "applicationType": "DEFAULT",
        "categoryId": "7e19fdea-6222-4c57-9e66-66c2340d6bc3",
        "createTime": 1517966743370,
        "encrypted": "unassigned",
        "engineId": "13",
        "helpString": "McAfee VirusScan is an antivirus program",
        "ignoreConflict": false,
        "lastUpdateTime": 1517966743370,
        "longDescription": "McAfee Antivirus and security",
        "name": "mcafee-antivirus",
        "nbarId": "1031",
        "p2pTechnology": "false",
        "popularity": 10,
        "rank": 65535,
        "references": "http://www.mcafee.com",
        "selectorId": "1031",
        "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
        "trafficClass": "BULK_DATA",
        "tunnel": "false"
      }
    ],
    "networkIdentity": [
      {
        "id": "9d4b9ed7-0305-430c-9944-7c23dfc91d47",
        "instanceId": 13844,
        "authEntityId": 13844,
        "displayName": "13844",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "53,80,443,5353",
        "protocol": "TCP",
        "upperPort": 0
      },
      {
        "id": "539863ca-0e55-41c6-aa71-9529a1294d4a",
        "instanceId": 13845,
        "authEntityId": 13845,
        "displayName": "13845",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "53,5353",
        "protocol": "UDP",
        "upperPort": 0
      }
    ],
    "parentScalableGroup": {
      "idRef": "4a68bc73-53c6-4deb-9983-784a0338aefe"
    },
    "user": [

    ],
    "userGroup": [

    ]
  },
  {
    "id": "37093f73-4249-403a-b087-84d3d5c26c95",
    "instanceId": 4212,
    "authEntityId": 4212,
    "displayName": "4212",
    "authEntityClass": -1909073334,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "createTime": 1517966741747,
    "deployed": false,
    "isSeeded": true,
    "isStale": false,
    "lastUpdateTime": 1517966741747,
    "name": "capwap-data",
    "namespace": "scalablegroup:application",
    "provisioningState": "DEFINED",
    "qualifier": "application",
    "resourceVersion": 0,
    "targetIdList": [

    ],
    "type": "scalablegroup",
    "cfsChangeInfo": [

    ],
    "customProvisions": [

    ],
    "scalableGroupExternalHandle": "capwap-data",
    "scalableGroupType": "APPLICATION",
    "securityGroupTag": 0,
    "state": "ACTIVE",
    "vnAgnostic": true,
    "identitySource": {
      "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
      "instanceId": 10010,
      "authEntityId": 10010,
      "displayName": "10010",
      "authEntityClass": 1738953278,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "state": "INACTIVE",
      "type": "NBAR"
    },
    "indicativeNetworkIdentity": [
      {
        "id": "627fa602-c115-47da-b603-4ab442090556",
        "instanceId": 12464,
        "authEntityId": 12464,
        "displayName": "12464",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "5247",
        "protocol": "UDP",
        "upperPort": 0
      }
    ],
    "networkApplications": [
      {
        "id": "64e0cbba-6d33-4487-a28f-5c0c7211e3eb",
        "instanceId": 11168,
        "authEntityId": 11168,
        "displayName": "11168",
        "authEntityClass": -217092956,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "appProtocol": "udp",
        "applicationGroup": "capwap-group",
        "applicationType": "DEFAULT",
        "categoryId": "70fce3cd-f2bd-48de-9020-7b7840b9e7a1",
        "createTime": 1517966741747,
        "encrypted": "true",
        "engineId": "3",
        "helpString": "Control And Provisioning of Wireless Access Points Data Protocol",
        "ignoreConflict": false,
        "lastUpdateTime": 1517966741747,
        "longDescription": "Control And Provisioning of Wireless Access Points (CAPWAP) is a protocol used for Access Controllers (AC) to manage and control Wireless Termination Points (WTPs). CAPWAP is designed to centralize WLANs. CAPWAP data represents the data traffic passed from the WTP to AC or vice versa.",
        "name": "capwap-data",
        "nbarId": "1325",
        "p2pTechnology": "false",
        "popularity": 6,
        "rank": 65535,
        "references": "http://tools.ietf.org/html/rfc5415",
        "selectorId": "5247",
        "subCategory": "ab63e736-9aa7-4946-b55b-3b26ddca609c",
        "trafficClass": "BULK_DATA",
        "tunnel": "true"
      }
    ],
    "networkIdentity": [
      {
        "id": "e2dc60d3-27b1-4a26-beca-78aa734275a8",
        "instanceId": 12463,
        "authEntityId": 12463,
        "displayName": "12463",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "5246,5247",
        "protocol": "UDP",
        "upperPort": 0
      }
    ],
    "parentScalableGroup": {
      "idRef": "79f9d505-477f-4ccd-bd2a-c5fc8097a2e1"
    },
    "user": [

    ],
    "userGroup": [

    ]
  },
  {
    "id": "37177848-0585-400b-914a-2e102e78dfc2",
    "instanceId": 15459,
    "authEntityId": 15459,
    "displayName": "15459",
    "authEntityClass": -1909073334,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "createTime": 1517966745009,
    "deployed": false,
    "isSeeded": true,
    "isStale": false,
    "lastUpdateTime": 1517966745009,
    "name": "yahoo-voip-over-sip",
    "namespace": "scalablegroup:application",
    "provisioningState": "DEFINED",
    "qualifier": "application",
    "resourceVersion": 0,
    "targetIdList": [

    ],
    "type": "scalablegroup",
    "cfsChangeInfo": [

    ],
    "customProvisions": [

    ],
    "scalableGroupExternalHandle": "yahoo-voip-over-sip",
    "scalableGroupType": "APPLICATION",
    "securityGroupTag": 0,
    "state": "ACTIVE",
    "vnAgnostic": true,
    "identitySource": {
      "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
      "instanceId": 10010,
      "authEntityId": 10010,
      "displayName": "10010",
      "authEntityClass": 1738953278,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "state": "INACTIVE",
      "type": "NBAR"
    },
    "indicativeNetworkIdentity": [

    ],
    "networkApplications": [
      {
        "id": "b5e1d319-e44e-4d18-9788-556285b68f03",
        "instanceId": 16402,
        "authEntityId": 16402,
        "displayName": "16402",
        "authEntityClass": -217092956,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "appProtocol": "tcp/udp",
        "applicationGroup": "yahoo-messenger-group",
        "applicationType": "DEFAULT",
        "categoryId": "82a7b7fa-da5a-48f5-8b6a-a2546b947843",
        "createTime": 1517966745009,
        "encrypted": "false",
        "engineId": "13",
        "helpString": "Yahoo VoIP over SIP",
        "ignoreConflict": false,
        "lastUpdateTime": 1517966745009,
        "longDescription": "As a feature of Yahoo messenger, starting from version 8 and above, Yahoo voice uses SIP protocol to commence VoIP calls.",
        "name": "yahoo-voip-over-sip",
        "nbarId": "1195",
        "p2pTechnology": "false",
        "popularity": 9,
        "rank": 65535,
        "references": "http://messenger.yahoo.com/",
        "selectorId": "302",
        "subCategory": "118c10a2-e272-47c1-92b9-4814ebe97138",
        "trafficClass": "VOIP_TELEPHONY",
        "tunnel": "false"
      }
    ],
    "networkIdentity": [
      {
        "id": "4b0a2237-715b-4a1d-8e09-bfe9bcf60d11",
        "instanceId": 17954,
        "authEntityId": 17954,
        "displayName": "17954",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "5060",
        "protocol": "UDP",
        "upperPort": 0
      },
      {
        "id": "1363f593-d466-4aa9-a925-df3fa59140a5",
        "instanceId": 17955,
        "authEntityId": 17955,
        "displayName": "17955",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "80,5060",
        "protocol": "TCP",
        "upperPort": 0
      }
    ],
    "parentScalableGroup": {
      "idRef": "fd6264c9-c16a-4311-a335-a3db039256f3"
    },
    "user": [

    ],
    "userGroup": [

    ]
  },
  {
    "id": "375b997d-5a07-4957-aed1-62602ef33428",
    "instanceId": 15391,
    "authEntityId": 15391,
    "displayName": "15391",
    "authEntityClass": -1909073334,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "createTime": 1517966744860,
    "deployed": false,
    "isSeeded": true,
    "isStale": false,
    "lastUpdateTime": 1517966744860,
    "name": "wb-mon",
    "namespace": "scalablegroup:application",
    "provisioningState": "DEFINED",
    "qualifier": "application",
    "resourceVersion": 0,
    "targetIdList": [

    ],
    "type": "scalablegroup",
    "cfsChangeInfo": [

    ],
    "customProvisions": [

    ],
    "scalableGroupExternalHandle": "wb-mon",
    "scalableGroupType": "APPLICATION",
    "securityGroupTag": 0,
    "state": "ACTIVE",
    "vnAgnostic": true,
    "identitySource": {
      "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
      "instanceId": 10010,
      "authEntityId": 10010,
      "displayName": "10010",
      "authEntityClass": 1738953278,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "state": "INACTIVE",
      "type": "NBAR"
    },
    "indicativeNetworkIdentity": [
      {
        "id": "1435f8f5-342b-45dd-9f66-db0774f4c73a",
        "instanceId": 17794,
        "authEntityId": 17794,
        "displayName": "17794",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipProtocolNumbers": "78",
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "protocol": "WB_MON",
        "upperPort": 0
      }
    ],
    "networkApplications": [
      {
        "id": "d124e992-7920-491b-9628-00f2cb6db21b",
        "instanceId": 16334,
        "authEntityId": 16334,
        "displayName": "16334",
        "authEntityClass": -217092956,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "appProtocol": "ip",
        "applicationGroup": "other",
        "applicationType": "DEFAULT",
        "categoryId": "6daa3dfd-3526-407a-88f0-97824b3f3a24",
        "createTime": 1517966744860,
        "encrypted": "unassigned",
        "engineId": "1",
        "helpString": "WIDEBAND Monitoring",
        "ignoreConflict": false,
        "lastUpdateTime": 1517966744860,
        "longDescription": "Registered with IANA as IP Protocol 78",
        "name": "wb-mon",
        "nbarId": "832",
        "p2pTechnology": "unassigned",
        "popularity": 1,
        "rank": 65535,
        "references": "http://www.iana.org/assignments/protocol-numbers/protocol-numbers.xml",
        "selectorId": "78",
        "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
        "trafficClass": "BULK_DATA",
        "tunnel": "unassigned"
      }
    ],
    "networkIdentity": [
      {
        "id": "80af2fed-32cf-4543-8a9b-517e48a596a7",
        "instanceId": 17793,
        "authEntityId": 17793,
        "displayName": "17793",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipProtocolNumbers": "78",
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "protocol": "WB_MON",
        "upperPort": 0
      }
    ],
    "parentScalableGroup": {
      "idRef": "000c15bf-28fe-4fbb-9b69-35e6502721f1"
    },
    "user": [

    ],
    "userGroup": [

    ]
  },
  {
    "id": "378be6bd-ef49-4718-9b75-15b2458290dc",
    "instanceId": 4699,
    "authEntityId": 4699,
    "displayName": "4699",
    "authEntityClass": -1909073334,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "createTime": 1517966743309,
    "deployed": false,
    "isSeeded": true,
    "isStale": false,
    "lastUpdateTime": 1517966743309,
    "name": "livestation",
    "namespace": "scalablegroup:application",
    "provisioningState": "DEFINED",
    "qualifier": "application",
    "resourceVersion": 0,
    "targetIdList": [

    ],
    "type": "scalablegroup",
    "cfsChangeInfo": [

    ],
    "customProvisions": [

    ],
    "scalableGroupExternalHandle": "livestation",
    "scalableGroupType": "APPLICATION",
    "securityGroupTag": 0,
    "state": "ACTIVE",
    "vnAgnostic": true,
    "identitySource": {
      "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
      "instanceId": 10010,
      "authEntityId": 10010,
      "displayName": "10010",
      "authEntityClass": 1738953278,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "state": "INACTIVE",
      "type": "NBAR"
    },
    "indicativeNetworkIdentity": [

    ],
    "networkApplications": [
      {
        "id": "37121c85-c659-40bd-8ee5-b0eadf9df667",
        "instanceId": 11649,
        "authEntityId": 11649,
        "displayName": "11649",
        "authEntityClass": -217092956,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "appProtocol": "tcp/udp",
        "applicationGroup": "other",
        "applicationType": "DEFAULT",
        "categoryId": "a09ef1b3-ba26-4372-aee8-309d50697e76",
        "createTime": 1517966743309,
        "encrypted": "true",
        "engineId": "13",
        "helpString": "Live television and radio broadcasts",
        "ignoreConflict": false,
        "lastUpdateTime": 1517966743309,
        "longDescription": "Livestation is a platform for distributing live television and radio broadcasts over a data network. It works on Windows, Macintosh, Linux and iPhone. The underlying protocols are RTMP, HTTP and SSL.",
        "name": "livestation",
        "nbarId": "1405",
        "p2pTechnology": "false",
        "popularity": 6,
        "rank": 65535,
        "references": "http://www.livestation.com/",
        "selectorId": "480",
        "subCategory": "85a797c0-aef8-4284-91a7-677f33e29abb",
        "trafficClass": "MULTIMEDIA_STREAMING",
        "tunnel": "false"
      }
    ],
    "networkIdentity": [
      {
        "id": "973a1b0c-1448-42f4-81af-2f3cc24d16a8",
        "instanceId": 13764,
        "authEntityId": 13764,
        "displayName": "13764",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "53,80,443,5353",
        "protocol": "TCP",
        "upperPort": 0
      },
      {
        "id": "5b4f7b19-38d4-438b-af67-53d09af3882d",
        "instanceId": 13765,
        "authEntityId": 13765,
        "displayName": "13765",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "53,5353",
        "protocol": "UDP",
        "upperPort": 0
      }
    ],
    "parentScalableGroup": {
      "idRef": "fd6264c9-c16a-4311-a335-a3db039256f3"
    },
    "user": [

    ],
    "userGroup": [

    ]
  },
  {
    "id": "37c90a49-e6e9-45c8-b94b-c1fc038ec2d7",
    "instanceId": 4249,
    "authEntityId": 4249,
    "displayName": "4249",
    "authEntityClass": -1909073334,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "createTime": 1517966741936,
    "deployed": false,
    "isSeeded": true,
    "isStale": false,
    "lastUpdateTime": 1517966741936,
    "name": "cisco-phone-video",
    "namespace": "scalablegroup:application",
    "provisioningState": "DEFINED",
    "qualifier": "application",
    "resourceVersion": 0,
    "targetIdList": [

    ],
    "type": "scalablegroup",
    "cfsChangeInfo": [

    ],
    "customProvisions": [

    ],
    "scalableGroupExternalHandle": "cisco-phone-video",
    "scalableGroupType": "APPLICATION",
    "securityGroupTag": 0,
    "state": "ACTIVE",
    "vnAgnostic": true,
    "identitySource": {
      "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
      "instanceId": 10010,
      "authEntityId": 10010,
      "displayName": "10010",
      "authEntityClass": 1738953278,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "state": "INACTIVE",
      "type": "NBAR"
    },
    "indicativeNetworkIdentity": [

    ],
    "networkApplications": [
      {
        "id": "0f407918-181c-44d9-8e27-eab289d959b0",
        "instanceId": 11203,
        "authEntityId": 11203,
        "displayName": "11203",
        "authEntityClass": -217092956,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "appProtocol": "tcp/udp",
        "applicationGroup": "cisco-phone-group",
        "applicationType": "DEFAULT",
        "categoryId": "38a8e354-e2fe-4f3c-b1c5-2bbb90fb0d99",
        "createTime": 1517966741936,
        "encrypted": "false",
        "engineId": "13",
        "helpString": "Cisco IP Phones and PC-based Unified Communicators; Video Calls",
        "ignoreConflict": false,
        "lastUpdateTime": 1517966741936,
        "longDescription": "Cisco-ip-phone is a VoIP telephone used mainly in corporations and can be used on or off site. Cisco Phone Video is the video part of Cisco-Phone calls when the signaling is over SIP and the media is over RTP.",
        "name": "cisco-phone-video",
        "nbarId": "1552",
        "p2pTechnology": "false",
        "popularity": 8,
        "rank": 65535,
        "references": "http://www.cisco.com/en/US/prod/collateral/voicesw/ps6788/phones/ps379/ps1854/product_data_sheet09186a008008884a.html",
        "selectorId": "613",
        "subCategory": "05178016-4555-4291-ab4e-43c2ab2a931a",
        "trafficClass": "MULTIMEDIA_CONFERENCING",
        "tunnel": "false"
      }
    ],
    "networkIdentity": [
      {
        "id": "fea9b41b-c9c0-4acf-b756-11d3e678e483",
        "instanceId": 12534,
        "authEntityId": 12534,
        "displayName": "12534",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "5060",
        "protocol": "UDP",
        "upperPort": 0
      },
      {
        "id": "46eea628-9342-4a4e-a597-2eaa981ba693",
        "instanceId": 12535,
        "authEntityId": 12535,
        "displayName": "12535",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "2000,2001,2002,5060",
        "protocol": "TCP",
        "upperPort": 0
      }
    ],
    "parentScalableGroup": {
      "idRef": "60f1364d-2a02-4063-8ba1-07817abe0612"
    },
    "user": [

    ],
    "userGroup": [

    ]
  },
  {
    "id": "382468e7-c21f-49ec-a055-a7e62fa57670",
    "instanceId": 4772,
    "authEntityId": 4772,
    "displayName": "4772",
    "authEntityClass": -1909073334,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "createTime": 1517966743462,
    "deployed": false,
    "isSeeded": true,
    "isStale": false,
    "lastUpdateTime": 1517966743462,
    "name": "ms-dynamics-crm-online",
    "namespace": "scalablegroup:application",
    "provisioningState": "DEFINED",
    "qualifier": "application",
    "resourceVersion": 0,
    "targetIdList": [

    ],
    "type": "scalablegroup",
    "cfsChangeInfo": [

    ],
    "customProvisions": [

    ],
    "scalableGroupExternalHandle": "ms-dynamics-crm-online",
    "scalableGroupType": "APPLICATION",
    "securityGroupTag": 0,
    "state": "ACTIVE",
    "vnAgnostic": true,
    "identitySource": {
      "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
      "instanceId": 10010,
      "authEntityId": 10010,
      "displayName": "10010",
      "authEntityClass": 1738953278,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "state": "INACTIVE",
      "type": "NBAR"
    },
    "indicativeNetworkIdentity": [

    ],
    "networkApplications": [
      {
        "id": "8ecc688f-2faa-4ec1-ab54-73c9041e2f5e",
        "instanceId": 11721,
        "authEntityId": 11721,
        "displayName": "11721",
        "authEntityClass": -217092956,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "appProtocol": "tcp/udp",
        "applicationGroup": "other",
        "applicationType": "DEFAULT",
        "categoryId": "7e19fdea-6222-4c57-9e66-66c2340d6bc3",
        "createTime": 1517966743462,
        "encrypted": "true",
        "engineId": "13",
        "helpString": "Microsoft Dynamics CRM Online",
        "ignoreConflict": false,
        "lastUpdateTime": 1517966743462,
        "longDescription": "Microsoft CRM Dynamics Online is a customer relationship software package delivered as a cloud service. It provides sales and marketing services and allows customers to customize the product using a .NET framework",
        "name": "ms-dynamics-crm-online",
        "nbarId": "1443",
        "p2pTechnology": "false",
        "popularity": 9,
        "rank": 65535,
        "references": "http://crm.dynamics.com/en-us/on-demand",
        "selectorId": "508",
        "subCategory": "21d2cdac-b44c-4cab-a279-af3ff88ed550",
        "trafficClass": "TRANSACTIONAL_DATA",
        "tunnel": "false"
      }
    ],
    "networkIdentity": [
      {
        "id": "1f3d89bb-9a34-42bf-84e0-df1139c050d2",
        "instanceId": 13974,
        "authEntityId": 13974,
        "displayName": "13974",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "53,5353",
        "protocol": "UDP",
        "upperPort": 0
      },
      {
        "id": "9380fd32-0acc-4eae-99c7-a3bee7199dce",
        "instanceId": 13975,
        "authEntityId": 13975,
        "displayName": "13975",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "53,80,443,5353",
        "protocol": "TCP",
        "upperPort": 0
      }
    ],
    "parentScalableGroup": {
      "idRef": "dc13f401-c34c-4d18-a5a1-6e9f8624d67e"
    },
    "user": [

    ],
    "userGroup": [

    ]
  },
  {
    "id": "3867c39c-b436-4707-b252-232d940a41b4",
    "instanceId": 4496,
    "authEntityId": 4496,
    "displayName": "4496",
    "authEntityClass": -1909073334,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "createTime": 1517966742817,
    "deployed": false,
    "isSeeded": true,
    "isStale": false,
    "lastUpdateTime": 1517966742817,
    "name": "google-downloads",
    "namespace": "scalablegroup:application",
    "provisioningState": "DEFINED",
    "qualifier": "application",
    "resourceVersion": 0,
    "targetIdList": [

    ],
    "type": "scalablegroup",
    "cfsChangeInfo": [

    ],
    "customProvisions": [

    ],
    "scalableGroupExternalHandle": "google-downloads",
    "scalableGroupType": "APPLICATION",
    "securityGroupTag": 0,
    "state": "ACTIVE",
    "vnAgnostic": true,
    "identitySource": {
      "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
      "instanceId": 10010,
      "authEntityId": 10010,
      "displayName": "10010",
      "authEntityClass": 1738953278,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "state": "INACTIVE",
      "type": "NBAR"
    },
    "indicativeNetworkIdentity": [

    ],
    "networkApplications": [
      {
        "id": "4d3312f0-832f-437d-bc5b-a0fbd1481eee",
        "instanceId": 11447,
        "authEntityId": 11447,
        "displayName": "11447",
        "authEntityClass": -217092956,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "appProtocol": "tcp/udp",
        "applicationGroup": "google-group",
        "applicationType": "DEFAULT",
        "categoryId": "9d9132a2-d99b-49db-a414-9fc93dea6604",
        "createTime": 1517966742817,
        "encrypted": "unassigned",
        "engineId": "13",
        "helpString": "Google downloads and updates services",
        "ignoreConflict": false,
        "lastUpdateTime": 1517966742817,
        "longDescription": "Google downloads and updates used by google applications.",
        "name": "google-downloads",
        "nbarId": "1081",
        "p2pTechnology": "false",
        "popularity": 10,
        "rank": 65535,
        "references": "http://dl.google.com/",
        "selectorId": "1081",
        "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
        "trafficClass": "BULK_DATA",
        "tunnel": "false"
      }
    ],
    "networkIdentity": [
      {
        "id": "5bd96dbd-250e-4f3c-b9d0-770ceb090d4a",
        "instanceId": 13236,
        "authEntityId": 13236,
        "displayName": "13236",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "53,5353",
        "protocol": "UDP",
        "upperPort": 0
      },
      {
        "id": "8e119c52-7335-4a11-a308-cc313c60e2d4",
        "instanceId": 13237,
        "authEntityId": 13237,
        "displayName": "13237",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "53,80,443,5353",
        "protocol": "TCP",
        "upperPort": 0
      }
    ],
    "parentScalableGroup": {
      "idRef": "4a68bc73-53c6-4deb-9983-784a0338aefe"
    },
    "user": [

    ],
    "userGroup": [

    ]
  },
  {
    "id": "388b9240-8135-4116-afe6-6c86bfb9e2e1",
    "instanceId": 15320,
    "authEntityId": 15320,
    "displayName": "15320",
    "authEntityClass": -1909073334,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "createTime": 1517966744733,
    "deployed": false,
    "isSeeded": true,
    "isStale": false,
    "lastUpdateTime": 1517966744733,
    "name": "ulpnet",
    "namespace": "scalablegroup:application",
    "provisioningState": "DEFINED",
    "qualifier": "application",
    "resourceVersion": 0,
    "targetIdList": [

    ],
    "type": "scalablegroup",
    "cfsChangeInfo": [

    ],
    "customProvisions": [

    ],
    "scalableGroupExternalHandle": "ulpnet",
    "scalableGroupType": "APPLICATION",
    "securityGroupTag": 0,
    "state": "ACTIVE",
    "vnAgnostic": true,
    "identitySource": {
      "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
      "instanceId": 10010,
      "authEntityId": 10010,
      "displayName": "10010",
      "authEntityClass": 1738953278,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "state": "INACTIVE",
      "type": "NBAR"
    },
    "indicativeNetworkIdentity": [
      {
        "id": "caeda418-a525-4de4-88c9-a0b1be5799dc",
        "instanceId": 17581,
        "authEntityId": 17581,
        "displayName": "17581",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "483",
        "protocol": "UDP",
        "upperPort": 0
      },
      {
        "id": "3811e7ea-86d6-4606-a0cf-35d410a79f4c",
        "instanceId": 17582,
        "authEntityId": 17582,
        "displayName": "17582",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "483",
        "protocol": "TCP",
        "upperPort": 0
      }
    ],
    "networkApplications": [
      {
        "id": "8fbe3b6d-9ae0-434f-be3a-918ce394d147",
        "instanceId": 16263,
        "authEntityId": 16263,
        "displayName": "16263",
        "authEntityClass": -217092956,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "appProtocol": "tcp/udp",
        "applicationGroup": "other",
        "applicationType": "DEFAULT",
        "categoryId": "6daa3dfd-3526-407a-88f0-97824b3f3a24",
        "createTime": 1517966744733,
        "encrypted": "false",
        "engineId": "3",
        "helpString": "ulpnet",
        "ignoreConflict": false,
        "lastUpdateTime": 1517966744733,
        "longDescription": "Registered with IANA on port 483 TCP/UDP",
        "name": "ulpnet",
        "nbarId": "397",
        "p2pTechnology": "false",
        "popularity": 1,
        "rank": 65535,
        "references": "http://www.iana.org/assignments/service-names-port-numbers/service-names-port-numbers.xml",
        "selectorId": "483",
        "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
        "trafficClass": "BULK_DATA",
        "tunnel": "false"
      }
    ],
    "networkIdentity": [
      {
        "id": "4e5e1474-2af5-4c3e-94ab-353782c4b2a8",
        "instanceId": 17579,
        "authEntityId": 17579,
        "displayName": "17579",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "483",
        "protocol": "UDP",
        "upperPort": 0
      },
      {
        "id": "377fa341-542c-4dee-8fbb-4bdb18d9d2ad",
        "instanceId": 17580,
        "authEntityId": 17580,
        "displayName": "17580",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "483",
        "protocol": "TCP",
        "upperPort": 0
      }
    ],
    "parentScalableGroup": {
      "idRef": "000c15bf-28fe-4fbb-9b69-35e6502721f1"
    },
    "user": [

    ],
    "userGroup": [

    ]
  },
  {
    "id": "390b7f6f-0b65-47a6-9329-20a285a5927d",
    "instanceId": 15106,
    "authEntityId": 15106,
    "displayName": "15106",
    "authEntityClass": -1909073334,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "createTime": 1517966744247,
    "deployed": false,
    "isSeeded": true,
    "isStale": false,
    "lastUpdateTime": 1517966744247,
    "name": "scx-proxy",
    "namespace": "scalablegroup:application",
    "provisioningState": "DEFINED",
    "qualifier": "application",
    "resourceVersion": 0,
    "targetIdList": [

    ],
    "type": "scalablegroup",
    "cfsChangeInfo": [

    ],
    "customProvisions": [

    ],
    "scalableGroupExternalHandle": "scx-proxy",
    "scalableGroupType": "APPLICATION",
    "securityGroupTag": 0,
    "state": "ACTIVE",
    "vnAgnostic": true,
    "identitySource": {
      "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
      "instanceId": 10010,
      "authEntityId": 10010,
      "displayName": "10010",
      "authEntityClass": 1738953278,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "state": "INACTIVE",
      "type": "NBAR"
    },
    "indicativeNetworkIdentity": [
      {
        "id": "b8b6d2a2-ea95-4208-8e37-abfd9e3f6d9f",
        "instanceId": 14956,
        "authEntityId": 14956,
        "displayName": "14956",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "470",
        "protocol": "UDP",
        "upperPort": 0
      },
      {
        "id": "ee0130cc-211c-43b6-b8ca-7be3862bbb4e",
        "instanceId": 14957,
        "authEntityId": 14957,
        "displayName": "14957",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "470",
        "protocol": "TCP",
        "upperPort": 0
      }
    ],
    "networkApplications": [
      {
        "id": "7d592dd9-4355-4d86-b55b-bfe75957bcf4",
        "instanceId": 16049,
        "authEntityId": 16049,
        "displayName": "16049",
        "authEntityClass": -217092956,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "appProtocol": "tcp/udp",
        "applicationGroup": "other",
        "applicationType": "DEFAULT",
        "categoryId": "6daa3dfd-3526-407a-88f0-97824b3f3a24",
        "createTime": 1517966744247,
        "encrypted": "false",
        "engineId": "3",
        "helpString": "scx-proxy",
        "ignoreConflict": false,
        "lastUpdateTime": 1517966744247,
        "longDescription": "Registered with IANA on port 470 TCP/UDP",
        "name": "scx-proxy",
        "nbarId": "384",
        "p2pTechnology": "false",
        "popularity": 1,
        "rank": 65535,
        "references": "http://www.iana.org/assignments/service-names-port-numbers/service-names-port-numbers.xml",
        "selectorId": "470",
        "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
        "trafficClass": "BULK_DATA",
        "tunnel": "false"
      }
    ],
    "networkIdentity": [
      {
        "id": "4cc03abe-80d4-4c3c-b154-54a4ce8ee092",
        "instanceId": 14954,
        "authEntityId": 14954,
        "displayName": "14954",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "470",
        "protocol": "TCP",
        "upperPort": 0
      },
      {
        "id": "a68ea4ed-788f-4159-95a6-2a9400f8f29a",
        "instanceId": 14955,
        "authEntityId": 14955,
        "displayName": "14955",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "470",
        "protocol": "UDP",
        "upperPort": 0
      }
    ],
    "parentScalableGroup": {
      "idRef": "000c15bf-28fe-4fbb-9b69-35e6502721f1"
    },
    "user": [

    ],
    "userGroup": [

    ]
  },
  {
    "id": "3919d777-0928-4e2b-867b-4c5cc5119110",
    "instanceId": 4765,
    "authEntityId": 4765,
    "displayName": "4765",
    "authEntityClass": -1909073334,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "createTime": 1517966743448,
    "deployed": false,
    "isSeeded": true,
    "isStale": false,
    "lastUpdateTime": 1517966743448,
    "name": "mpm",
    "namespace": "scalablegroup:application",
    "provisioningState": "DEFINED",
    "qualifier": "application",
    "resourceVersion": 0,
    "targetIdList": [

    ],
    "type": "scalablegroup",
    "cfsChangeInfo": [

    ],
    "customProvisions": [

    ],
    "scalableGroupExternalHandle": "mpm",
    "scalableGroupType": "APPLICATION",
    "securityGroupTag": 0,
    "state": "ACTIVE",
    "vnAgnostic": true,
    "identitySource": {
      "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
      "instanceId": 10010,
      "authEntityId": 10010,
      "displayName": "10010",
      "authEntityClass": 1738953278,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "state": "INACTIVE",
      "type": "NBAR"
    },
    "indicativeNetworkIdentity": [
      {
        "id": "da46c801-26f9-4118-a9cf-f2450100e9d7",
        "instanceId": 13952,
        "authEntityId": 13952,
        "displayName": "13952",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "45",
        "protocol": "UDP",
        "upperPort": 0
      },
      {
        "id": "4864866b-1043-4488-b3f0-9f164bbc78c8",
        "instanceId": 13953,
        "authEntityId": 13953,
        "displayName": "13953",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "45",
        "protocol": "TCP",
        "upperPort": 0
      }
    ],
    "networkApplications": [
      {
        "id": "3c3b48e5-19d0-4022-98a8-dd074c1fd4d2",
        "instanceId": 11715,
        "authEntityId": 11715,
        "displayName": "11715",
        "authEntityClass": -217092956,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "appProtocol": "tcp/udp",
        "applicationGroup": "other",
        "applicationType": "DEFAULT",
        "categoryId": "6daa3dfd-3526-407a-88f0-97824b3f3a24",
        "createTime": 1517966743448,
        "encrypted": "false",
        "engineId": "3",
        "helpString": "Message Processing Module",
        "ignoreConflict": false,
        "lastUpdateTime": 1517966743448,
        "longDescription": "Message Processing Module (MPM) is part of the Internet message system. It is responsible for routing and delivering messages.",
        "name": "mpm",
        "nbarId": "925",
        "p2pTechnology": "false",
        "popularity": 1,
        "rank": 65535,
        "references": "http://tools.ietf.org/html/rfc759",
        "selectorId": "45",
        "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
        "trafficClass": "BULK_DATA",
        "tunnel": "false"
      }
    ],
    "networkIdentity": [
      {
        "id": "456c9020-06fa-4d44-a98f-bade46e77aae",
        "instanceId": 13950,
        "authEntityId": 13950,
        "displayName": "13950",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "45",
        "protocol": "TCP",
        "upperPort": 0
      },
      {
        "id": "408c3002-d761-4150-81c0-b9c9b0bae6a9",
        "instanceId": 13951,
        "authEntityId": 13951,
        "displayName": "13951",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "45",
        "protocol": "UDP",
        "upperPort": 0
      }
    ],
    "parentScalableGroup": {
      "idRef": "000c15bf-28fe-4fbb-9b69-35e6502721f1"
    },
    "user": [

    ],
    "userGroup": [

    ]
  },
  {
    "id": "393a1863-1866-476a-bf75-76a8bf46ef1c",
    "instanceId": 4050,
    "authEntityId": 4050,
    "displayName": "4050",
    "authEntityClass": -1909073334,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "createTime": 1517966740600,
    "deployed": false,
    "isSeeded": true,
    "isStale": false,
    "lastUpdateTime": 1517966740600,
    "name": "accessbuilder",
    "namespace": "scalablegroup:application",
    "provisioningState": "DEFINED",
    "qualifier": "application",
    "resourceVersion": 0,
    "targetIdList": [

    ],
    "type": "scalablegroup",
    "cfsChangeInfo": [

    ],
    "customProvisions": [

    ],
    "scalableGroupExternalHandle": "accessbuilder",
    "scalableGroupType": "APPLICATION",
    "securityGroupTag": 0,
    "state": "ACTIVE",
    "vnAgnostic": true,
    "identitySource": {
      "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
      "instanceId": 10010,
      "authEntityId": 10010,
      "displayName": "10010",
      "authEntityClass": 1738953278,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "state": "INACTIVE",
      "type": "NBAR"
    },
    "indicativeNetworkIdentity": [
      {
        "id": "2488927e-ec80-402a-b984-b079693ab15b",
        "instanceId": 12054,
        "authEntityId": 12054,
        "displayName": "12054",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "888",
        "protocol": "TCP",
        "upperPort": 0
      },
      {
        "id": "41c96d1b-12b7-4db6-a610-fb9ec7ce146f",
        "instanceId": 12055,
        "authEntityId": 12055,
        "displayName": "12055",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "888",
        "protocol": "UDP",
        "upperPort": 0
      }
    ],
    "networkApplications": [
      {
        "id": "11f3cec9-149f-4574-b9c4-266c35fd156f",
        "instanceId": 11023,
        "authEntityId": 11023,
        "displayName": "11023",
        "authEntityClass": -217092956,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "appProtocol": "tcp/udp",
        "applicationGroup": "other",
        "applicationType": "DEFAULT",
        "categoryId": "6daa3dfd-3526-407a-88f0-97824b3f3a24",
        "createTime": 1517966740600,
        "encrypted": "false",
        "engineId": "3",
        "helpString": "Access Builder",
        "ignoreConflict": false,
        "lastUpdateTime": 1517966740600,
        "longDescription": "AccessBuilder (Access Builder) is a family of dial-in remote access servers that give mobile computer users and remote office workers full access to workgroup, departmental, and enterprise network resources. Remote users dial into AccessBuilder via analog or digital connections to get direct, transparent links to Ethernet and Token Ring LANs-just as if they were connected locally. AccessBuilder products support a broad range of computing platforms, network operating systems, and protocols to fit a variety of network environments. They provide multi-protocol bridging and routing for wide area Client-to-LAN connections and remote LAN extensions to the central site.",
        "name": "accessbuilder",
        "nbarId": "662",
        "p2pTechnology": "false",
        "popularity": 1,
        "rank": 65535,
        "references": "http://www.all3com.com/accessbuilder.html",
        "selectorId": "888",
        "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
        "trafficClass": "BULK_DATA",
        "tunnel": "false"
      }
    ],
    "networkIdentity": [
      {
        "id": "04f53fc7-d849-417c-b069-597f450c0836",
        "instanceId": 12052,
        "authEntityId": 12052,
        "displayName": "12052",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "888",
        "protocol": "UDP",
        "upperPort": 0
      },
      {
        "id": "dab994f2-45f1-4c86-bf8a-8c82953c8110",
        "instanceId": 12053,
        "authEntityId": 12053,
        "displayName": "12053",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "888",
        "protocol": "TCP",
        "upperPort": 0
      }
    ],
    "parentScalableGroup": {
      "idRef": "000c15bf-28fe-4fbb-9b69-35e6502721f1"
    },
    "user": [

    ],
    "userGroup": [

    ]
  },
  {
    "id": "399aedc7-482a-4ea3-9467-f1ee31360872",
    "instanceId": 15258,
    "authEntityId": 15258,
    "displayName": "15258",
    "authEntityClass": -1909073334,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "createTime": 1517966744596,
    "deployed": false,
    "isSeeded": true,
    "isStale": false,
    "lastUpdateTime": 1517966744596,
    "name": "td-service",
    "namespace": "scalablegroup:application",
    "provisioningState": "DEFINED",
    "qualifier": "application",
    "resourceVersion": 0,
    "targetIdList": [

    ],
    "type": "scalablegroup",
    "cfsChangeInfo": [

    ],
    "customProvisions": [

    ],
    "scalableGroupExternalHandle": "td-service",
    "scalableGroupType": "APPLICATION",
    "securityGroupTag": 0,
    "state": "ACTIVE",
    "vnAgnostic": true,
    "identitySource": {
      "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
      "instanceId": 10010,
      "authEntityId": 10010,
      "displayName": "10010",
      "authEntityClass": 1738953278,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "state": "INACTIVE",
      "type": "NBAR"
    },
    "indicativeNetworkIdentity": [
      {
        "id": "d9745130-612e-4af6-8396-c8b85507cc5a",
        "instanceId": 17414,
        "authEntityId": 17414,
        "displayName": "17414",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "267",
        "protocol": "UDP",
        "upperPort": 0
      },
      {
        "id": "27d694b3-79d8-4bef-8b14-fb60126782e2",
        "instanceId": 17415,
        "authEntityId": 17415,
        "displayName": "17415",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "267",
        "protocol": "TCP",
        "upperPort": 0
      }
    ],
    "networkApplications": [
      {
        "id": "a9b033ec-ce15-4cb5-9a4a-d5927573c9f2",
        "instanceId": 16201,
        "authEntityId": 16201,
        "displayName": "16201",
        "authEntityClass": -217092956,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "appProtocol": "tcp/udp",
        "applicationGroup": "other",
        "applicationType": "DEFAULT",
        "categoryId": "6daa3dfd-3526-407a-88f0-97824b3f3a24",
        "createTime": 1517966744596,
        "encrypted": "false",
        "engineId": "3",
        "helpString": "Tobit David Service Layer",
        "ignoreConflict": false,
        "lastUpdateTime": 1517966744596,
        "longDescription": "Tobit software provides different kinds of communication solutions, including Faxing software, email, fax, voice, SMS and more.",
        "name": "td-service",
        "nbarId": "1139",
        "p2pTechnology": "false",
        "popularity": 1,
        "rank": 65535,
        "references": "http://www.tobit.com/",
        "selectorId": "267",
        "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
        "trafficClass": "BULK_DATA",
        "tunnel": "false"
      }
    ],
    "networkIdentity": [
      {
        "id": "80515ddb-ced7-491c-831d-2db9462cfb7a",
        "instanceId": 17412,
        "authEntityId": 17412,
        "displayName": "17412",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "267",
        "protocol": "TCP",
        "upperPort": 0
      },
      {
        "id": "62498fbf-cc30-4ac3-9648-64569cb19b10",
        "instanceId": 17413,
        "authEntityId": 17413,
        "displayName": "17413",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "267",
        "protocol": "UDP",
        "upperPort": 0
      }
    ],
    "parentScalableGroup": {
      "idRef": "000c15bf-28fe-4fbb-9b69-35e6502721f1"
    },
    "user": [

    ],
    "userGroup": [

    ]
  },
  {
    "id": "39bb206b-9958-46e4-b12e-ae39b9e6bae8",
    "instanceId": 4036,
    "authEntityId": 4036,
    "displayName": "4036",
    "authEntityClass": -1909073334,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "createTime": 1517966740496,
    "deployed": false,
    "isSeeded": true,
    "isStale": false,
    "lastUpdateTime": 1517966740496,
    "name": "3com-tsmux",
    "namespace": "scalablegroup:application",
    "provisioningState": "DEFINED",
    "qualifier": "application",
    "resourceVersion": 0,
    "targetIdList": [

    ],
    "type": "scalablegroup",
    "cfsChangeInfo": [

    ],
    "customProvisions": [

    ],
    "scalableGroupExternalHandle": "3com-tsmux",
    "scalableGroupType": "APPLICATION",
    "securityGroupTag": 0,
    "state": "ACTIVE",
    "vnAgnostic": true,
    "identitySource": {
      "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
      "instanceId": 10010,
      "authEntityId": 10010,
      "displayName": "10010",
      "authEntityClass": 1738953278,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "state": "INACTIVE",
      "type": "NBAR"
    },
    "indicativeNetworkIdentity": [
      {
        "id": "35acb004-5283-4120-bbed-7b6b9f517ffd",
        "instanceId": 12018,
        "authEntityId": 12018,
        "displayName": "12018",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "106",
        "protocol": "TCP",
        "upperPort": 0
      },
      {
        "id": "857caffd-dc3a-41b9-b7f1-205e14a3743a",
        "instanceId": 12019,
        "authEntityId": 12019,
        "displayName": "12019",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "106",
        "protocol": "UDP",
        "upperPort": 0
      }
    ],
    "networkApplications": [
      {
        "id": "edc7e08c-68e5-4c11-80e5-9c591332a761",
        "instanceId": 11012,
        "authEntityId": 11012,
        "displayName": "11012",
        "authEntityClass": -217092956,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "appProtocol": "tcp/udp",
        "applicationGroup": "other",
        "applicationType": "DEFAULT",
        "categoryId": "6daa3dfd-3526-407a-88f0-97824b3f3a24",
        "createTime": 1517966740496,
        "encrypted": "false",
        "engineId": "3",
        "helpString": "3Com TSMUX",
        "ignoreConflict": false,
        "lastUpdateTime": 1517966740496,
        "longDescription": "Registered with IANA on port 106 TCP/UDP",
        "name": "3com-tsmux",
        "nbarId": "977",
        "p2pTechnology": "false",
        "popularity": 1,
        "rank": 65535,
        "references": "http://www.iana.org/assignments/service-names-port-numbers/service-names-port-numbers.xml",
        "selectorId": "106",
        "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
        "trafficClass": "BULK_DATA",
        "tunnel": "false"
      }
    ],
    "networkIdentity": [
      {
        "id": "8fe3dbe4-ffbc-420c-bc1d-a7192e3d9fbb",
        "instanceId": 12016,
        "authEntityId": 12016,
        "displayName": "12016",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "106",
        "protocol": "UDP",
        "upperPort": 0
      },
      {
        "id": "3c5ff685-e828-4fd0-aa62-5a34ae90f270",
        "instanceId": 12017,
        "authEntityId": 12017,
        "displayName": "12017",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "106",
        "protocol": "TCP",
        "upperPort": 0
      }
    ],
    "parentScalableGroup": {
      "idRef": "000c15bf-28fe-4fbb-9b69-35e6502721f1"
    },
    "user": [

    ],
    "userGroup": [

    ]
  },
  {
    "id": "39f98d09-70e5-4051-88ea-32420d9b8f42",
    "instanceId": 4877,
    "authEntityId": 4877,
    "displayName": "4877",
    "authEntityClass": -1909073334,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "createTime": 1517966743664,
    "deployed": false,
    "isSeeded": true,
    "isStale": false,
    "lastUpdateTime": 1517966743664,
    "name": "npmp-gui",
    "namespace": "scalablegroup:application",
    "provisioningState": "DEFINED",
    "qualifier": "application",
    "resourceVersion": 0,
    "targetIdList": [

    ],
    "type": "scalablegroup",
    "cfsChangeInfo": [

    ],
    "customProvisions": [

    ],
    "scalableGroupExternalHandle": "npmp-gui",
    "scalableGroupType": "APPLICATION",
    "securityGroupTag": 0,
    "state": "ACTIVE",
    "vnAgnostic": true,
    "identitySource": {
      "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
      "instanceId": 10010,
      "authEntityId": 10010,
      "displayName": "10010",
      "authEntityClass": 1738953278,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "state": "INACTIVE",
      "type": "NBAR"
    },
    "indicativeNetworkIdentity": [
      {
        "id": "74ed9135-f15e-4be8-8727-eef44d94c967",
        "instanceId": 14274,
        "authEntityId": 14274,
        "displayName": "14274",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "611",
        "protocol": "UDP",
        "upperPort": 0
      },
      {
        "id": "ca20165f-c3df-410c-bd4e-29865149bbce",
        "instanceId": 14275,
        "authEntityId": 14275,
        "displayName": "14275",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "611",
        "protocol": "TCP",
        "upperPort": 0
      }
    ],
    "networkApplications": [
      {
        "id": "6dd9abd4-4e99-4f1e-96e3-69d7321887d8",
        "instanceId": 11826,
        "authEntityId": 11826,
        "displayName": "11826",
        "authEntityClass": -217092956,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "appProtocol": "tcp/udp",
        "applicationGroup": "npmp-group",
        "applicationType": "DEFAULT",
        "categoryId": "1fc17362-9334-4c7b-b752-5afdbb574017",
        "createTime": 1517966743664,
        "encrypted": "false",
        "engineId": "3",
        "helpString": "npmp-gui",
        "ignoreConflict": false,
        "lastUpdateTime": 1517966743664,
        "longDescription": "Registered with IANA on port 611 TCP/UDP",
        "name": "npmp-gui",
        "nbarId": "520",
        "p2pTechnology": "false",
        "popularity": 1,
        "rank": 65535,
        "references": "http://www.iana.org/assignments/service-names-port-numbers/service-names-port-numbers.xml",
        "selectorId": "611",
        "subCategory": "f3c0f761-d894-4112-ab89-b90fc84fd660",
        "trafficClass": "OPS_ADMIN_MGMT",
        "tunnel": "false"
      }
    ],
    "networkIdentity": [
      {
        "id": "e9cc5504-6401-4e50-aed1-496ca3002a8a",
        "instanceId": 14272,
        "authEntityId": 14272,
        "displayName": "14272",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "611",
        "protocol": "UDP",
        "upperPort": 0
      },
      {
        "id": "e204bac3-6ebe-4e17-8abb-a35d243d3dc5",
        "instanceId": 14273,
        "authEntityId": 14273,
        "displayName": "14273",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "611",
        "protocol": "TCP",
        "upperPort": 0
      }
    ],
    "parentScalableGroup": {
      "idRef": "5fcd74d4-8726-47f7-a088-c457863da7ef"
    },
    "user": [

    ],
    "userGroup": [

    ]
  },
  {
    "id": "3a2bd2f5-c8c8-4f75-85ac-aeb4888ca654",
    "instanceId": 4297,
    "authEntityId": 4297,
    "displayName": "4297",
    "authEntityClass": -1909073334,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "createTime": 1517966742192,
    "deployed": false,
    "isSeeded": true,
    "isStale": false,
    "lastUpdateTime": 1517966742192,
    "name": "cpnx",
    "namespace": "scalablegroup:application",
    "provisioningState": "DEFINED",
    "qualifier": "application",
    "resourceVersion": 0,
    "targetIdList": [

    ],
    "type": "scalablegroup",
    "cfsChangeInfo": [

    ],
    "customProvisions": [

    ],
    "scalableGroupExternalHandle": "cpnx",
    "scalableGroupType": "APPLICATION",
    "securityGroupTag": 0,
    "state": "ACTIVE",
    "vnAgnostic": true,
    "identitySource": {
      "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
      "instanceId": 10010,
      "authEntityId": 10010,
      "displayName": "10010",
      "authEntityClass": 1738953278,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "state": "INACTIVE",
      "type": "NBAR"
    },
    "indicativeNetworkIdentity": [
      {
        "id": "ed09f8d4-eb50-4cca-9087-8abfe43663fe",
        "instanceId": 12664,
        "authEntityId": 12664,
        "displayName": "12664",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipProtocolNumbers": "72",
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "protocol": "CPNX",
        "upperPort": 0
      }
    ],
    "networkApplications": [
      {
        "id": "23b5ba08-4092-4692-ac1b-d887f5f8274b",
        "instanceId": 11250,
        "authEntityId": 11250,
        "displayName": "11250",
        "authEntityClass": -217092956,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "appProtocol": "ip",
        "applicationGroup": "other",
        "applicationType": "DEFAULT",
        "categoryId": "6daa3dfd-3526-407a-88f0-97824b3f3a24",
        "createTime": 1517966742192,
        "encrypted": "unassigned",
        "engineId": "1",
        "helpString": "Computer Protocol Network Executive",
        "ignoreConflict": false,
        "lastUpdateTime": 1517966742192,
        "longDescription": "Registered with IANA as IP Protocol 72",
        "name": "cpnx",
        "nbarId": "826",
        "p2pTechnology": "unassigned",
        "popularity": 1,
        "rank": 65535,
        "references": "http://www.iana.org/assignments/protocol-numbers/protocol-numbers.xml",
        "selectorId": "72",
        "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
        "trafficClass": "BULK_DATA",
        "tunnel": "unassigned"
      }
    ],
    "networkIdentity": [
      {
        "id": "2d397234-9e6a-4f51-8d12-193d096064e3",
        "instanceId": 12663,
        "authEntityId": 12663,
        "displayName": "12663",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipProtocolNumbers": "72",
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "protocol": "CPNX",
        "upperPort": 0
      }
    ],
    "parentScalableGroup": {
      "idRef": "000c15bf-28fe-4fbb-9b69-35e6502721f1"
    },
    "user": [

    ],
    "userGroup": [

    ]
  },
  {
    "id": "3a3052b3-72b8-4ac0-a7dd-6e6f7af5b877",
    "instanceId": 4353,
    "authEntityId": 4353,
    "displayName": "4353",
    "authEntityClass": -1909073334,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "createTime": 1517966742378,
    "deployed": false,
    "isSeeded": true,
    "isStale": false,
    "lastUpdateTime": 1517966742378,
    "name": "dgp",
    "namespace": "scalablegroup:application",
    "provisioningState": "DEFINED",
    "qualifier": "application",
    "resourceVersion": 0,
    "targetIdList": [

    ],
    "type": "scalablegroup",
    "cfsChangeInfo": [

    ],
    "customProvisions": [

    ],
    "scalableGroupExternalHandle": "dgp",
    "scalableGroupType": "APPLICATION",
    "securityGroupTag": 0,
    "state": "ACTIVE",
    "vnAgnostic": true,
    "identitySource": {
      "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
      "instanceId": 10010,
      "authEntityId": 10010,
      "displayName": "10010",
      "authEntityClass": 1738953278,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "state": "INACTIVE",
      "type": "NBAR"
    },
    "indicativeNetworkIdentity": [
      {
        "id": "923f4b93-7e83-47b0-b3fb-8acb5545e540",
        "instanceId": 12841,
        "authEntityId": 12841,
        "displayName": "12841",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipProtocolNumbers": "86",
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "protocol": "DGP",
        "upperPort": 0
      }
    ],
    "networkApplications": [
      {
        "id": "91e2a91f-c0d5-42a2-9e66-8a9a745294f7",
        "instanceId": 11304,
        "authEntityId": 11304,
        "displayName": "11304",
        "authEntityClass": -217092956,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "appProtocol": "ip",
        "applicationGroup": "other",
        "applicationType": "DEFAULT",
        "categoryId": "6daa3dfd-3526-407a-88f0-97824b3f3a24",
        "createTime": 1517966742378,
        "encrypted": "unassigned",
        "engineId": "1",
        "helpString": "Dissimilar Gateway Protocol",
        "ignoreConflict": false,
        "lastUpdateTime": 1517966742378,
        "longDescription": "Dissimilar Gateway Protocol",
        "name": "dgp",
        "nbarId": "840",
        "p2pTechnology": "unassigned",
        "popularity": 4,
        "rank": 65535,
        "references": "http://www.iana.org/assignments/service-names-port-numbers/service-names-port-numbers.xml",
        "selectorId": "86",
        "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
        "trafficClass": "BULK_DATA",
        "tunnel": "unassigned"
      }
    ],
    "networkIdentity": [
      {
        "id": "e81974aa-ef92-43b0-8238-5d79f1a36b82",
        "instanceId": 12840,
        "authEntityId": 12840,
        "displayName": "12840",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipProtocolNumbers": "86",
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "protocol": "DGP",
        "upperPort": 0
      }
    ],
    "parentScalableGroup": {
      "idRef": "000c15bf-28fe-4fbb-9b69-35e6502721f1"
    },
    "user": [

    ],
    "userGroup": [

    ]
  },
  {
    "id": "3a3bd9c2-3de0-4357-b019-c5a0760887c0",
    "instanceId": 4774,
    "authEntityId": 4774,
    "displayName": "4774",
    "authEntityClass": -1909073334,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "createTime": 1517966743466,
    "deployed": false,
    "isSeeded": true,
    "isStale": false,
    "lastUpdateTime": 1517966743466,
    "name": "ms-live-accounts",
    "namespace": "scalablegroup:application",
    "provisioningState": "DEFINED",
    "qualifier": "application",
    "resourceVersion": 0,
    "targetIdList": [

    ],
    "type": "scalablegroup",
    "cfsChangeInfo": [

    ],
    "customProvisions": [

    ],
    "scalableGroupExternalHandle": "ms-live-accounts",
    "scalableGroupType": "APPLICATION",
    "securityGroupTag": 0,
    "state": "ACTIVE",
    "vnAgnostic": true,
    "identitySource": {
      "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
      "instanceId": 10010,
      "authEntityId": 10010,
      "displayName": "10010",
      "authEntityClass": 1738953278,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "state": "INACTIVE",
      "type": "NBAR"
    },
    "indicativeNetworkIdentity": [

    ],
    "networkApplications": [
      {
        "id": "87960f19-ba1d-4aa2-8131-dc943236599c",
        "instanceId": 11723,
        "authEntityId": 11723,
        "displayName": "11723",
        "authEntityClass": -217092956,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "appProtocol": "tcp/udp",
        "applicationGroup": "ms-cloud-group",
        "applicationType": "DEFAULT",
        "categoryId": "f6ec0221-b82d-43e8-a64d-163cbc13263b",
        "createTime": 1517966743466,
        "encrypted": "true",
        "engineId": "13",
        "helpString": "Windows Live Services Authentication",
        "ignoreConflict": false,
        "lastUpdateTime": 1517966743466,
        "longDescription": "Ms-live-accounts is used for login to various microsoft services.",
        "name": "ms-live-accounts",
        "nbarId": "1434",
        "p2pTechnology": "false",
        "popularity": 10,
        "rank": 65535,
        "references": "http://login.live.com/",
        "selectorId": "498",
        "subCategory": "aab99e59-8589-4a61-933e-f01bff556d78",
        "trafficClass": "TRANSACTIONAL_DATA",
        "tunnel": "false"
      }
    ],
    "networkIdentity": [
      {
        "id": "2e69300f-43e1-4508-9ba7-a089fdfa6715",
        "instanceId": 13977,
        "authEntityId": 13977,
        "displayName": "13977",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "53,5353",
        "protocol": "UDP",
        "upperPort": 0
      },
      {
        "id": "211223c0-5ca6-42e9-85a3-b2733df9f50b",
        "instanceId": 13978,
        "authEntityId": 13978,
        "displayName": "13978",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "53,80,443,5353",
        "protocol": "TCP",
        "upperPort": 0
      }
    ],
    "parentScalableGroup": {
      "idRef": "28e08de4-d7b2-4521-84ce-bc1e5ee0b9cc"
    },
    "user": [

    ],
    "userGroup": [

    ]
  },
  {
    "id": "3a3e7817-e9ae-4de8-a09c-aa486b219c2b",
    "instanceId": 15244,
    "authEntityId": 15244,
    "displayName": "15244",
    "authEntityClass": -1909073334,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "createTime": 1517966744568,
    "deployed": false,
    "isSeeded": true,
    "isStale": false,
    "lastUpdateTime": 1517966744568,
    "name": "synotics-broker",
    "namespace": "scalablegroup:application",
    "provisioningState": "DEFINED",
    "qualifier": "application",
    "resourceVersion": 0,
    "targetIdList": [

    ],
    "type": "scalablegroup",
    "cfsChangeInfo": [

    ],
    "customProvisions": [

    ],
    "scalableGroupExternalHandle": "synotics-broker",
    "scalableGroupType": "APPLICATION",
    "securityGroupTag": 0,
    "state": "ACTIVE",
    "vnAgnostic": true,
    "identitySource": {
      "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
      "instanceId": 10010,
      "authEntityId": 10010,
      "displayName": "10010",
      "authEntityClass": 1738953278,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "state": "INACTIVE",
      "type": "NBAR"
    },
    "indicativeNetworkIdentity": [
      {
        "id": "dd09886b-9204-448d-99e5-5e6e9ceb56fe",
        "instanceId": 17370,
        "authEntityId": 17370,
        "displayName": "17370",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "392",
        "protocol": "TCP",
        "upperPort": 0
      },
      {
        "id": "7d063657-15a5-447c-bcad-05f2aea63200",
        "instanceId": 17371,
        "authEntityId": 17371,
        "displayName": "17371",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "392",
        "protocol": "UDP",
        "upperPort": 0
      }
    ],
    "networkApplications": [
      {
        "id": "9404a8ea-fbda-46bb-a269-edf6b96fbc96",
        "instanceId": 16187,
        "authEntityId": 16187,
        "displayName": "16187",
        "authEntityClass": -217092956,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "appProtocol": "tcp/udp",
        "applicationGroup": "snmp-group",
        "applicationType": "DEFAULT",
        "categoryId": "1fc17362-9334-4c7b-b752-5afdbb574017",
        "createTime": 1517966744568,
        "encrypted": "false",
        "engineId": "3",
        "helpString": "SynOptics Port Broker Port",
        "ignoreConflict": false,
        "lastUpdateTime": 1517966744568,
        "longDescription": "Registered with IANA on port 392 TCP/UDP",
        "name": "synotics-broker",
        "nbarId": "308",
        "p2pTechnology": "false",
        "popularity": 1,
        "rank": 65535,
        "references": "http://www.iana.org/assignments/service-names-port-numbers/service-names-port-numbers.xml",
        "selectorId": "392",
        "subCategory": "f3c0f761-d894-4112-ab89-b90fc84fd660",
        "trafficClass": "OPS_ADMIN_MGMT",
        "tunnel": "false"
      }
    ],
    "networkIdentity": [
      {
        "id": "0246214b-1862-48ad-9ea3-fb5f73d589a0",
        "instanceId": 17368,
        "authEntityId": 17368,
        "displayName": "17368",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "392",
        "protocol": "TCP",
        "upperPort": 0
      },
      {
        "id": "2596df04-01e0-4add-a1e6-4993ce8cd95d",
        "instanceId": 17369,
        "authEntityId": 17369,
        "displayName": "17369",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "392",
        "protocol": "UDP",
        "upperPort": 0
      }
    ],
    "parentScalableGroup": {
      "idRef": "5fcd74d4-8726-47f7-a088-c457863da7ef"
    },
    "user": [

    ],
    "userGroup": [

    ]
  },
  {
    "id": "3a3e8ab0-c584-4c5a-9e9d-100a5c534d73",
    "instanceId": 4897,
    "authEntityId": 4897,
    "displayName": "4897",
    "authEntityClass": -1909073334,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "createTime": 1517966743706,
    "deployed": false,
    "isSeeded": true,
    "isStale": false,
    "lastUpdateTime": 1517966743706,
    "name": "ocs_amu",
    "namespace": "scalablegroup:application",
    "provisioningState": "DEFINED",
    "qualifier": "application",
    "resourceVersion": 0,
    "targetIdList": [

    ],
    "type": "scalablegroup",
    "cfsChangeInfo": [

    ],
    "customProvisions": [

    ],
    "scalableGroupExternalHandle": "ocs_amu",
    "scalableGroupType": "APPLICATION",
    "securityGroupTag": 0,
    "state": "ACTIVE",
    "vnAgnostic": true,
    "identitySource": {
      "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
      "instanceId": 10010,
      "authEntityId": 10010,
      "displayName": "10010",
      "authEntityClass": 1738953278,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "state": "INACTIVE",
      "type": "NBAR"
    },
    "indicativeNetworkIdentity": [
      {
        "id": "3da13e8b-d04b-4cd4-83ce-8833197e5965",
        "instanceId": 14345,
        "authEntityId": 14345,
        "displayName": "14345",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "429",
        "protocol": "UDP",
        "upperPort": 0
      },
      {
        "id": "f0e9b972-7756-4a6d-ad26-4399ba439c23",
        "instanceId": 14346,
        "authEntityId": 14346,
        "displayName": "14346",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "429",
        "protocol": "TCP",
        "upperPort": 0
      }
    ],
    "networkApplications": [
      {
        "id": "46b6a874-07e4-47b4-ba04-142f36cb7b46",
        "instanceId": 11846,
        "authEntityId": 11846,
        "displayName": "11846",
        "authEntityClass": -217092956,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "appProtocol": "tcp/udp",
        "applicationGroup": "other",
        "applicationType": "DEFAULT",
        "categoryId": "6daa3dfd-3526-407a-88f0-97824b3f3a24",
        "createTime": 1517966743706,
        "encrypted": "false",
        "engineId": "3",
        "helpString": "ocs_amu",
        "ignoreConflict": false,
        "lastUpdateTime": 1517966743706,
        "longDescription": "Registered with IANA on port 429 TCP/UDP",
        "name": "ocs_amu",
        "nbarId": "344",
        "p2pTechnology": "false",
        "popularity": 1,
        "rank": 65535,
        "references": "http://www.iana.org/assignments/service-names-port-numbers/service-names-port-numbers.xml",
        "selectorId": "429",
        "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
        "trafficClass": "BULK_DATA",
        "tunnel": "false"
      }
    ],
    "networkIdentity": [
      {
        "id": "2c246dfb-ec71-4fc6-8ccd-e2e72a4da350",
        "instanceId": 14343,
        "authEntityId": 14343,
        "displayName": "14343",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "429",
        "protocol": "UDP",
        "upperPort": 0
      },
      {
        "id": "b0f23bc9-79c6-4e05-a38a-462bc414561a",
        "instanceId": 14344,
        "authEntityId": 14344,
        "displayName": "14344",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "429",
        "protocol": "TCP",
        "upperPort": 0
      }
    ],
    "parentScalableGroup": {
      "idRef": "000c15bf-28fe-4fbb-9b69-35e6502721f1"
    },
    "user": [

    ],
    "userGroup": [

    ]
  },
  {
    "id": "3a7de0b4-6d7c-413f-b952-c9660fe439dd",
    "instanceId": 15411,
    "authEntityId": 15411,
    "displayName": "15411",
    "authEntityClass": -1909073334,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "createTime": 1517966744918,
    "deployed": false,
    "isSeeded": true,
    "isStale": false,
    "lastUpdateTime": 1517966744918,
    "name": "whatsapp",
    "namespace": "scalablegroup:application",
    "provisioningState": "DEFINED",
    "qualifier": "application",
    "resourceVersion": 0,
    "targetIdList": [

    ],
    "type": "scalablegroup",
    "cfsChangeInfo": [

    ],
    "customProvisions": [

    ],
    "scalableGroupExternalHandle": "whatsapp",
    "scalableGroupType": "APPLICATION",
    "securityGroupTag": 0,
    "state": "ACTIVE",
    "vnAgnostic": true,
    "identitySource": {
      "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
      "instanceId": 10010,
      "authEntityId": 10010,
      "displayName": "10010",
      "authEntityClass": 1738953278,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "state": "INACTIVE",
      "type": "NBAR"
    },
    "indicativeNetworkIdentity": [

    ],
    "networkApplications": [
      {
        "id": "0cfdec42-916e-4f01-b6f2-5ebd3f334177",
        "instanceId": 16354,
        "authEntityId": 16354,
        "displayName": "16354",
        "authEntityClass": -217092956,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "appProtocol": "tcp/udp",
        "applicationGroup": "other",
        "applicationType": "DEFAULT",
        "categoryId": "0f6cab47-be95-4124-9db3-360ccd55f983",
        "createTime": 1517966744918,
        "encrypted": "true",
        "engineId": "13",
        "helpString": "WhatsApp, Text chat and multimedia sharing",
        "ignoreConflict": false,
        "lastUpdateTime": 1517966744918,
        "longDescription": "WhatsApp Messenger is a proprietary, cross-platform instant messaging application for smartphones. In addition to text messaging, users can send each other images, location, contacts, and video and audio media messages",
        "name": "whatsapp",
        "nbarId": "1488",
        "p2pTechnology": "false",
        "popularity": 9,
        "rank": 65535,
        "references": "http://www.whatsapp.com",
        "selectorId": "553",
        "subCategory": "118c10a2-e272-47c1-92b9-4814ebe97138",
        "trafficClass": "TRANSACTIONAL_DATA",
        "tunnel": "false"
      }
    ],
    "networkIdentity": [
      {
        "id": "4040bd72-eba7-4737-b71a-459f517e2d32",
        "instanceId": 17831,
        "authEntityId": 17831,
        "displayName": "17831",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "53,80,443,5353",
        "protocol": "TCP",
        "upperPort": 0
      },
      {
        "id": "819140c0-858f-4e81-9963-491432873085",
        "instanceId": 17832,
        "authEntityId": 17832,
        "displayName": "17832",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "53,5353",
        "protocol": "UDP",
        "upperPort": 0
      }
    ],
    "parentScalableGroup": {
      "idRef": "fd6264c9-c16a-4311-a335-a3db039256f3"
    },
    "user": [

    ],
    "userGroup": [

    ]
  },
  {
    "id": "3ac588b5-ef4a-4d1b-93ac-d3088177b647",
    "instanceId": 4270,
    "authEntityId": 4270,
    "displayName": "4270",
    "authEntityClass": -1909073334,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "createTime": 1517966742016,
    "deployed": false,
    "isSeeded": true,
    "isStale": false,
    "lastUpdateTime": 1517966742016,
    "name": "cnet",
    "namespace": "scalablegroup:application",
    "provisioningState": "DEFINED",
    "qualifier": "application",
    "resourceVersion": 0,
    "targetIdList": [

    ],
    "type": "scalablegroup",
    "cfsChangeInfo": [

    ],
    "customProvisions": [

    ],
    "scalableGroupExternalHandle": "cnet",
    "scalableGroupType": "APPLICATION",
    "securityGroupTag": 0,
    "state": "ACTIVE",
    "vnAgnostic": true,
    "identitySource": {
      "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
      "instanceId": 10010,
      "authEntityId": 10010,
      "displayName": "10010",
      "authEntityClass": 1738953278,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "state": "INACTIVE",
      "type": "NBAR"
    },
    "indicativeNetworkIdentity": [

    ],
    "networkApplications": [
      {
        "id": "ba775110-c99d-457a-8ed9-0aeb31662837",
        "instanceId": 11223,
        "authEntityId": 11223,
        "displayName": "11223",
        "authEntityClass": -217092956,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "appProtocol": "tcp/udp",
        "applicationGroup": "other",
        "applicationType": "DEFAULT",
        "categoryId": "878644b5-fe91-45b4-9204-31c84a73ba48",
        "createTime": 1517966742016,
        "encrypted": "false",
        "engineId": "13",
        "helpString": "CNET - Tech and gadget related news, reviews, and shopping.",
        "ignoreConflict": false,
        "lastUpdateTime": 1517966742016,
        "longDescription": "CNET - Tech and gadget related news, reviews, and shopping.",
        "name": "cnet",
        "nbarId": "1574",
        "p2pTechnology": "false",
        "popularity": 3,
        "rank": 65535,
        "references": "http://cnet.com",
        "selectorId": "645",
        "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
        "trafficClass": "TRANSACTIONAL_DATA",
        "tunnel": "false"
      }
    ],
    "networkIdentity": [
      {
        "id": "b29a57c6-2521-40e7-8ac6-4384db148e03",
        "instanceId": 12582,
        "authEntityId": 12582,
        "displayName": "12582",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "53,5353",
        "protocol": "UDP",
        "upperPort": 0
      },
      {
        "id": "f2f2a30a-61b1-4dc0-8e3d-fbec8623c0c1",
        "instanceId": 12583,
        "authEntityId": 12583,
        "displayName": "12583",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "53,80,443,5353",
        "protocol": "TCP",
        "upperPort": 0
      }
    ],
    "parentScalableGroup": {
      "idRef": "28e08de4-d7b2-4521-84ce-bc1e5ee0b9cc"
    },
    "user": [

    ],
    "userGroup": [

    ]
  },
  {
    "id": "3b289977-3a79-4f57-a7e1-e0a4b6a9c21e",
    "instanceId": 4950,
    "authEntityId": 4950,
    "displayName": "4950",
    "authEntityClass": -1909073334,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "createTime": 1517966743884,
    "deployed": false,
    "isSeeded": true,
    "isStale": false,
    "lastUpdateTime": 1517966743884,
    "name": "perfect-dark",
    "namespace": "scalablegroup:application",
    "provisioningState": "DEFINED",
    "qualifier": "application",
    "resourceVersion": 0,
    "targetIdList": [

    ],
    "type": "scalablegroup",
    "cfsChangeInfo": [

    ],
    "customProvisions": [

    ],
    "scalableGroupExternalHandle": "perfect-dark",
    "scalableGroupType": "APPLICATION",
    "securityGroupTag": 0,
    "state": "ACTIVE",
    "vnAgnostic": true,
    "identitySource": {
      "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
      "instanceId": 10010,
      "authEntityId": 10010,
      "displayName": "10010",
      "authEntityClass": 1738953278,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "state": "INACTIVE",
      "type": "NBAR"
    },
    "indicativeNetworkIdentity": [

    ],
    "networkApplications": [
      {
        "id": "9204f0f0-cde6-4e70-be2f-aa5ea4d3d6c6",
        "instanceId": 11899,
        "authEntityId": 11899,
        "displayName": "11899",
        "authEntityClass": -217092956,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "appProtocol": "tcp/udp",
        "applicationGroup": "other",
        "applicationType": "DEFAULT",
        "categoryId": "b33b2761-d981-4734-810a-a6a1c18ab352",
        "createTime": 1517966743884,
        "encrypted": "true",
        "engineId": "13",
        "helpString": "Perfect Dark P2P file sharing protocol",
        "ignoreConflict": false,
        "lastUpdateTime": 1517966743884,
        "longDescription": "Perfect-Dark is a P2P application being developed in Japan by an anonymous author. It was developed with the intention for it to be the successor to both Winny and Share. The network function has a huge distributed disk called 'Unity' and the minimum requirement is 40GB disk space",
        "name": "perfect-dark",
        "nbarId": "1517",
        "p2pTechnology": "true",
        "popularity": 6,
        "rank": 65535,
        "references": "http://perfectdark.benri-tool.net",
        "selectorId": "578",
        "subCategory": "79fe7198-9ce3-4c01-9b58-45a8df3d9513",
        "trafficClass": "BULK_DATA",
        "tunnel": "false"
      }
    ],
    "networkIdentity": [
      {
        "id": "493baadf-4438-475c-a3a7-223810f3e3dc",
        "instanceId": 14509,
        "authEntityId": 14509,
        "displayName": "14509",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "53,5353",
        "protocol": "UDP",
        "upperPort": 0
      },
      {
        "id": "4029f54b-8892-46d4-949f-4fa1c9c67fcf",
        "instanceId": 14510,
        "authEntityId": 14510,
        "displayName": "14510",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "53,80,5353",
        "protocol": "TCP",
        "upperPort": 0
      }
    ],
    "parentScalableGroup": {
      "idRef": "7a1a3bbb-dc84-4a35-82a4-7e650c4c4761"
    },
    "user": [

    ],
    "userGroup": [

    ]
  },
  {
    "id": "3b57a64b-1f1f-4d0d-aaae-7bd4532276ea",
    "instanceId": 4399,
    "authEntityId": 4399,
    "displayName": "4399",
    "authEntityClass": -1909073334,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "createTime": 1517966742537,
    "deployed": false,
    "isSeeded": true,
    "isStale": false,
    "lastUpdateTime": 1517966742537,
    "name": "edonkey-static",
    "namespace": "scalablegroup:application",
    "provisioningState": "DEFINED",
    "qualifier": "application",
    "resourceVersion": 0,
    "targetIdList": [

    ],
    "type": "scalablegroup",
    "cfsChangeInfo": [

    ],
    "customProvisions": [

    ],
    "scalableGroupExternalHandle": "edonkey-static",
    "scalableGroupType": "APPLICATION",
    "securityGroupTag": 0,
    "state": "ACTIVE",
    "vnAgnostic": true,
    "identitySource": {
      "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
      "instanceId": 10010,
      "authEntityId": 10010,
      "displayName": "10010",
      "authEntityClass": 1738953278,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "state": "INACTIVE",
      "type": "NBAR"
    },
    "indicativeNetworkIdentity": [
      {
        "id": "f32525e0-dd34-4444-a360-738181983975",
        "instanceId": 12993,
        "authEntityId": 12993,
        "displayName": "12993",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "4661,4662,4663,4664,4665,4672,4673,4711,5662,5773,5783",
        "protocol": "UDP",
        "upperPort": 0
      },
      {
        "id": "13600f78-1958-40d8-8dd0-10fa70bf92d8",
        "instanceId": 12994,
        "authEntityId": 12994,
        "displayName": "12994",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "4661,4662,4663,4664,4665,4672,4673,4711,5662,5773,5783",
        "protocol": "TCP",
        "upperPort": 0
      }
    ],
    "networkApplications": [
      {
        "id": "94c0d36c-259f-4de0-97ee-78aabeb7257f",
        "instanceId": 11350,
        "authEntityId": 11350,
        "displayName": "11350",
        "authEntityClass": -217092956,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "appProtocol": "tcp/udp",
        "applicationGroup": "edonkey-emule-group",
        "applicationType": "DEFAULT",
        "categoryId": "b33b2761-d981-4734-810a-a6a1c18ab352",
        "createTime": 1517966742537,
        "encrypted": "false",
        "engineId": "13",
        "helpString": "Static Edonkey",
        "ignoreConflict": false,
        "lastUpdateTime": 1517966742537,
        "longDescription": "eDonkey is a peer-to-peer file sharing addopted to share large files. The network is based on multiple decentralized servers ,each client must be connected to a server to enter the network. edonkey-static and eMule are also required to fully detect or prevent this application traffic.",
        "name": "edonkey-static",
        "nbarId": "1333",
        "p2pTechnology": "true",
        "popularity": 10,
        "rank": 65535,
        "references": "http://en.wikipedia.org/wiki/EDonkey_network",
        "selectorId": "416",
        "subCategory": "79fe7198-9ce3-4c01-9b58-45a8df3d9513",
        "trafficClass": "BULK_DATA",
        "tunnel": "false"
      }
    ],
    "networkIdentity": [
      {
        "id": "00300a15-38a5-419e-b296-c46ec8d30e8d",
        "instanceId": 12992,
        "authEntityId": 12992,
        "displayName": "12992",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "4661,4662,4663,4664,4665,4672,4673,4711,5662,5773,5783",
        "protocol": "TCP",
        "upperPort": 0
      },
      {
        "id": "85e93b9e-1287-48b4-9b3b-ce30c8a628c1",
        "instanceId": 12991,
        "authEntityId": 12991,
        "displayName": "12991",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "4661,4662,4663,4664,4665,4672,4673,4711,5662,5773,5783",
        "protocol": "UDP",
        "upperPort": 0
      }
    ],
    "parentScalableGroup": {
      "idRef": "7a1a3bbb-dc84-4a35-82a4-7e650c4c4761"
    },
    "user": [

    ],
    "userGroup": [

    ]
  },
  {
    "id": "3bb1bf93-d7b4-4060-80cf-e6a42b78236d",
    "instanceId": 4871,
    "authEntityId": 4871,
    "displayName": "4871",
    "authEntityClass": -1909073334,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "createTime": 1517966743653,
    "deployed": false,
    "isSeeded": true,
    "isStale": false,
    "lastUpdateTime": 1517966743653,
    "name": "nnsp",
    "namespace": "scalablegroup:application",
    "provisioningState": "DEFINED",
    "qualifier": "application",
    "resourceVersion": 0,
    "targetIdList": [

    ],
    "type": "scalablegroup",
    "cfsChangeInfo": [

    ],
    "customProvisions": [

    ],
    "scalableGroupExternalHandle": "nnsp",
    "scalableGroupType": "APPLICATION",
    "securityGroupTag": 0,
    "state": "ACTIVE",
    "vnAgnostic": true,
    "identitySource": {
      "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
      "instanceId": 10010,
      "authEntityId": 10010,
      "displayName": "10010",
      "authEntityClass": 1738953278,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "state": "INACTIVE",
      "type": "NBAR"
    },
    "indicativeNetworkIdentity": [
      {
        "id": "2ec3dcdf-c5df-448a-bba5-f321d47bb380",
        "instanceId": 14258,
        "authEntityId": 14258,
        "displayName": "14258",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "433",
        "protocol": "TCP",
        "upperPort": 0
      },
      {
        "id": "91af31a7-4305-4af1-8bc0-4cd447ab87ed",
        "instanceId": 14259,
        "authEntityId": 14259,
        "displayName": "14259",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "433",
        "protocol": "UDP",
        "upperPort": 0
      }
    ],
    "networkApplications": [
      {
        "id": "977190b5-3858-4f7a-97a0-45352d747e84",
        "instanceId": 11820,
        "authEntityId": 11820,
        "displayName": "11820",
        "authEntityClass": -217092956,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "appProtocol": "tcp/udp",
        "applicationGroup": "nntp-group",
        "applicationType": "DEFAULT",
        "categoryId": "6daa3dfd-3526-407a-88f0-97824b3f3a24",
        "createTime": 1517966743653,
        "encrypted": "false",
        "engineId": "3",
        "helpString": "nnsp",
        "ignoreConflict": false,
        "lastUpdateTime": 1517966743653,
        "longDescription": "Registered with IANA on port 433 TCP/UDP",
        "name": "nnsp",
        "nbarId": "348",
        "p2pTechnology": "false",
        "popularity": 1,
        "rank": 65535,
        "references": "http://www.iana.org/assignments/service-names-port-numbers/service-names-port-numbers.xml",
        "selectorId": "433",
        "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
        "trafficClass": "BULK_DATA",
        "tunnel": "false"
      }
    ],
    "networkIdentity": [
      {
        "id": "9c516678-beb2-46ec-a8c6-08e29d6dbdb4",
        "instanceId": 14256,
        "authEntityId": 14256,
        "displayName": "14256",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "433",
        "protocol": "TCP",
        "upperPort": 0
      },
      {
        "id": "5b67caf7-3d1a-41bd-8b78-ed0346b9ad7b",
        "instanceId": 14257,
        "authEntityId": 14257,
        "displayName": "14257",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "433",
        "protocol": "UDP",
        "upperPort": 0
      }
    ],
    "parentScalableGroup": {
      "idRef": "000c15bf-28fe-4fbb-9b69-35e6502721f1"
    },
    "user": [

    ],
    "userGroup": [

    ]
  },
  {
    "id": "3bb2acd8-89db-4a27-82d8-a263c3cd3b19",
    "instanceId": 15428,
    "authEntityId": 15428,
    "displayName": "15428",
    "authEntityClass": -1909073334,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "createTime": 1517966744948,
    "deployed": false,
    "isSeeded": true,
    "isStale": false,
    "lastUpdateTime": 1517966744948,
    "name": "wsn",
    "namespace": "scalablegroup:application",
    "provisioningState": "DEFINED",
    "qualifier": "application",
    "resourceVersion": 0,
    "targetIdList": [

    ],
    "type": "scalablegroup",
    "cfsChangeInfo": [

    ],
    "customProvisions": [

    ],
    "scalableGroupExternalHandle": "wsn",
    "scalableGroupType": "APPLICATION",
    "securityGroupTag": 0,
    "state": "ACTIVE",
    "vnAgnostic": true,
    "identitySource": {
      "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
      "instanceId": 10010,
      "authEntityId": 10010,
      "displayName": "10010",
      "authEntityClass": 1738953278,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "state": "INACTIVE",
      "type": "NBAR"
    },
    "indicativeNetworkIdentity": [
      {
        "id": "1bb56006-e86c-4345-8e90-61ecdf488432",
        "instanceId": 17868,
        "authEntityId": 17868,
        "displayName": "17868",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipProtocolNumbers": "74",
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "protocol": "WSN",
        "upperPort": 0
      }
    ],
    "networkApplications": [
      {
        "id": "c5a31934-56b9-49c2-a0a5-8a0e783346d9",
        "instanceId": 16371,
        "authEntityId": 16371,
        "displayName": "16371",
        "authEntityClass": -217092956,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "appProtocol": "ip",
        "applicationGroup": "other",
        "applicationType": "DEFAULT",
        "categoryId": "6daa3dfd-3526-407a-88f0-97824b3f3a24",
        "createTime": 1517966744948,
        "encrypted": "unassigned",
        "engineId": "1",
        "helpString": "Wang Span Network",
        "ignoreConflict": false,
        "lastUpdateTime": 1517966744948,
        "longDescription": "Registered with IANA as IP Protocol 74",
        "name": "wsn",
        "nbarId": "828",
        "p2pTechnology": "unassigned",
        "popularity": 1,
        "rank": 65535,
        "references": "http://www.iana.org/assignments/protocol-numbers/protocol-numbers.xml",
        "selectorId": "74",
        "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
        "trafficClass": "BULK_DATA",
        "tunnel": "unassigned"
      }
    ],
    "networkIdentity": [
      {
        "id": "296d8a0e-748d-4f61-8f08-c0c975662d4a",
        "instanceId": 17867,
        "authEntityId": 17867,
        "displayName": "17867",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipProtocolNumbers": "74",
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "protocol": "WSN",
        "upperPort": 0
      }
    ],
    "parentScalableGroup": {
      "idRef": "000c15bf-28fe-4fbb-9b69-35e6502721f1"
    },
    "user": [

    ],
    "userGroup": [

    ]
  },
  {
    "id": "3bd3b204-45f9-417b-a0b5-a00b1217c12e",
    "instanceId": 15046,
    "authEntityId": 15046,
    "displayName": "15046",
    "authEntityClass": -1909073334,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "createTime": 1517966744106,
    "deployed": false,
    "isSeeded": true,
    "isStale": false,
    "lastUpdateTime": 1517966744106,
    "name": "rhapsody",
    "namespace": "scalablegroup:application",
    "provisioningState": "DEFINED",
    "qualifier": "application",
    "resourceVersion": 0,
    "targetIdList": [

    ],
    "type": "scalablegroup",
    "cfsChangeInfo": [

    ],
    "customProvisions": [

    ],
    "scalableGroupExternalHandle": "rhapsody",
    "scalableGroupType": "APPLICATION",
    "securityGroupTag": 0,
    "state": "ACTIVE",
    "vnAgnostic": true,
    "identitySource": {
      "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
      "instanceId": 10010,
      "authEntityId": 10010,
      "displayName": "10010",
      "authEntityClass": 1738953278,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "state": "INACTIVE",
      "type": "NBAR"
    },
    "indicativeNetworkIdentity": [

    ],
    "networkApplications": [
      {
        "id": "6624d1f2-9b94-47d6-91ad-d97fb210ae49",
        "instanceId": 11985,
        "authEntityId": 11985,
        "displayName": "11985",
        "authEntityClass": -217092956,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "appProtocol": "tcp/udp",
        "applicationGroup": "other",
        "applicationType": "DEFAULT",
        "categoryId": "a09ef1b3-ba26-4372-aee8-309d50697e76",
        "createTime": 1517966744106,
        "encrypted": "true",
        "engineId": "13",
        "helpString": "Online Music Service",
        "ignoreConflict": false,
        "lastUpdateTime": 1517966744106,
        "longDescription": "Rhapsody is an online music store subscription service. The Rhapsody protocol is a client-server TCP software that enables the client to hear music online or download it to the client's device (e.g. PC, mobile, MP3 player). The application works on several platforms such as PC, BlackBerry and iPhone.",
        "name": "rhapsody",
        "nbarId": "1418",
        "p2pTechnology": "false",
        "popularity": 6,
        "rank": 65535,
        "references": "http://www.rhapsody.com/about/index.html",
        "selectorId": "489",
        "subCategory": "bf1b9d2c-aa52-4f37-8860-40d4603319f5",
        "trafficClass": "MULTIMEDIA_STREAMING",
        "tunnel": "false"
      }
    ],
    "networkIdentity": [
      {
        "id": "f22b420a-0097-46fe-aaeb-940a3e421e35",
        "instanceId": 14768,
        "authEntityId": 14768,
        "displayName": "14768",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "53,5353",
        "protocol": "UDP",
        "upperPort": 0
      },
      {
        "id": "addb0a5b-9007-4841-be80-acea7d9bf55c",
        "instanceId": 14767,
        "authEntityId": 14767,
        "displayName": "14767",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "53,80,443,5353",
        "protocol": "TCP",
        "upperPort": 0
      }
    ],
    "parentScalableGroup": {
      "idRef": "fd6264c9-c16a-4311-a335-a3db039256f3"
    },
    "user": [

    ],
    "userGroup": [

    ]
  },
  {
    "id": "3be25f73-290f-43f3-8a5b-e1182fd7890d",
    "instanceId": 15372,
    "authEntityId": 15372,
    "displayName": "15372",
    "authEntityClass": -1909073334,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "createTime": 1517966744827,
    "deployed": false,
    "isSeeded": true,
    "isStale": false,
    "lastUpdateTime": 1517966744827,
    "name": "vslmp",
    "namespace": "scalablegroup:application",
    "provisioningState": "DEFINED",
    "qualifier": "application",
    "resourceVersion": 0,
    "targetIdList": [

    ],
    "type": "scalablegroup",
    "cfsChangeInfo": [

    ],
    "customProvisions": [

    ],
    "scalableGroupExternalHandle": "vslmp",
    "scalableGroupType": "APPLICATION",
    "securityGroupTag": 0,
    "state": "ACTIVE",
    "vnAgnostic": true,
    "identitySource": {
      "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
      "instanceId": 10010,
      "authEntityId": 10010,
      "displayName": "10010",
      "authEntityClass": 1738953278,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "state": "INACTIVE",
      "type": "NBAR"
    },
    "indicativeNetworkIdentity": [
      {
        "id": "28e4faea-46e4-4015-baa2-026f6e7d51b3",
        "instanceId": 17733,
        "authEntityId": 17733,
        "displayName": "17733",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "312",
        "protocol": "UDP",
        "upperPort": 0
      },
      {
        "id": "d5ef0b78-abc4-4dc1-80fc-2c060056d6d4",
        "instanceId": 17734,
        "authEntityId": 17734,
        "displayName": "17734",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "312",
        "protocol": "TCP",
        "upperPort": 0
      }
    ],
    "networkApplications": [
      {
        "id": "3bafb955-bad5-4bd2-a19c-6afed656378b",
        "instanceId": 16315,
        "authEntityId": 16315,
        "displayName": "16315",
        "authEntityClass": -217092956,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "appProtocol": "tcp/udp",
        "applicationGroup": "other",
        "applicationType": "DEFAULT",
        "categoryId": "6daa3dfd-3526-407a-88f0-97824b3f3a24",
        "createTime": 1517966744827,
        "encrypted": "false",
        "engineId": "3",
        "helpString": "vslmp",
        "ignoreConflict": false,
        "lastUpdateTime": 1517966744827,
        "longDescription": "Registered with IANA on port 312 TCP/UDP",
        "name": "vslmp",
        "nbarId": "1152",
        "p2pTechnology": "false",
        "popularity": 1,
        "rank": 65535,
        "references": "http://www.iana.org/assignments/service-names-port-numbers/service-names-port-numbers.xml",
        "selectorId": "312",
        "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
        "trafficClass": "BULK_DATA",
        "tunnel": "false"
      }
    ],
    "networkIdentity": [
      {
        "id": "3ee28687-e2fc-4fa6-8591-33dae72537a2",
        "instanceId": 17731,
        "authEntityId": 17731,
        "displayName": "17731",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "312",
        "protocol": "TCP",
        "upperPort": 0
      },
      {
        "id": "58d1905e-20ad-4735-881c-981633583c42",
        "instanceId": 17732,
        "authEntityId": 17732,
        "displayName": "17732",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "312",
        "protocol": "UDP",
        "upperPort": 0
      }
    ],
    "parentScalableGroup": {
      "idRef": "000c15bf-28fe-4fbb-9b69-35e6502721f1"
    },
    "user": [

    ],
    "userGroup": [

    ]
  },
  {
    "id": "3bef23e5-a682-454b-bade-7d7d544ae82b",
    "instanceId": 15186,
    "authEntityId": 15186,
    "displayName": "15186",
    "authEntityClass": -1909073334,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "createTime": 1517966744431,
    "deployed": false,
    "isSeeded": true,
    "isStale": false,
    "lastUpdateTime": 1517966744431,
    "name": "spdy",
    "namespace": "scalablegroup:application",
    "provisioningState": "DEFINED",
    "qualifier": "application",
    "resourceVersion": 0,
    "targetIdList": [

    ],
    "type": "scalablegroup",
    "cfsChangeInfo": [

    ],
    "customProvisions": [

    ],
    "scalableGroupExternalHandle": "spdy",
    "scalableGroupType": "APPLICATION",
    "securityGroupTag": 0,
    "state": "ACTIVE",
    "vnAgnostic": true,
    "identitySource": {
      "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
      "instanceId": 10010,
      "authEntityId": 10010,
      "displayName": "10010",
      "authEntityClass": 1738953278,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "state": "INACTIVE",
      "type": "NBAR"
    },
    "indicativeNetworkIdentity": [

    ],
    "networkApplications": [
      {
        "id": "5706673a-5732-4728-8f6b-c08b271f694c",
        "instanceId": 16129,
        "authEntityId": 16129,
        "displayName": "16129",
        "authEntityClass": -217092956,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "appProtocol": "tcp",
        "applicationGroup": "other",
        "applicationType": "DEFAULT",
        "categoryId": "878644b5-fe91-45b4-9204-31c84a73ba48",
        "createTime": 1517966744431,
        "encrypted": "true",
        "engineId": "13",
        "helpString": "SPDY is a web content transporting protocol.",
        "ignoreConflict": false,
        "lastUpdateTime": 1517966744431,
        "longDescription": "SPDY (pronounced speedy) is an open networking protocol developed primarily at Google for transporting web content.  SPDY is similar to HTTP, with particular goals to reduce web page load latency and improve web security. SPDY achieves reduced latency through compression, multiplexing, and prioritization. The name \"SPDY\" is a trademark of Google.",
        "name": "spdy",
        "nbarId": "1474",
        "p2pTechnology": "false",
        "popularity": 9,
        "rank": 65535,
        "references": "http://dev.chromium.org/spdy",
        "selectorId": "541",
        "subCategory": "b90786f4-0190-45b8-9924-21b3e9682590",
        "trafficClass": "TRANSACTIONAL_DATA",
        "tunnel": "true"
      }
    ],
    "networkIdentity": [
      {
        "id": "e20f0004-5ab2-421e-92e4-2254e52b22d9",
        "instanceId": 17199,
        "authEntityId": 17199,
        "displayName": "17199",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "80,443",
        "protocol": "TCP",
        "upperPort": 0
      }
    ],
    "parentScalableGroup": {
      "idRef": "53f03553-069a-4046-ad7c-84610eb579c5"
    },
    "user": [

    ],
    "userGroup": [

    ]
  },
  {
    "id": "3bf9c87b-c3df-4f26-8ed8-4e10d9a518b3",
    "instanceId": 4600,
    "authEntityId": 4600,
    "displayName": "4600",
    "authEntityClass": -1909073334,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "createTime": 1517966743090,
    "deployed": false,
    "isSeeded": true,
    "isStale": false,
    "lastUpdateTime": 1517966743090,
    "name": "independent-news",
    "namespace": "scalablegroup:application",
    "provisioningState": "DEFINED",
    "qualifier": "application",
    "resourceVersion": 0,
    "targetIdList": [

    ],
    "type": "scalablegroup",
    "cfsChangeInfo": [

    ],
    "customProvisions": [

    ],
    "scalableGroupExternalHandle": "independent-news",
    "scalableGroupType": "APPLICATION",
    "securityGroupTag": 0,
    "state": "ACTIVE",
    "vnAgnostic": true,
    "identitySource": {
      "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
      "instanceId": 10010,
      "authEntityId": 10010,
      "displayName": "10010",
      "authEntityClass": 1738953278,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "state": "INACTIVE",
      "type": "NBAR"
    },
    "indicativeNetworkIdentity": [

    ],
    "networkApplications": [
      {
        "id": "14827917-b83a-4f5c-ab2a-8bb013a913d7",
        "instanceId": 11550,
        "authEntityId": 11550,
        "displayName": "11550",
        "authEntityClass": -217092956,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "appProtocol": "tcp/udp",
        "applicationGroup": "other",
        "applicationType": "DEFAULT",
        "categoryId": "878644b5-fe91-45b4-9204-31c84a73ba48",
        "createTime": 1517966743090,
        "encrypted": "false",
        "engineId": "13",
        "helpString": "Destructoid - An independent blog focused on video games.",
        "ignoreConflict": false,
        "lastUpdateTime": 1517966743090,
        "longDescription": "Destructoid - An independent blog focused on video games.",
        "name": "independent-news",
        "nbarId": "1654",
        "p2pTechnology": "false",
        "popularity": 3,
        "rank": 65535,
        "references": "http://independent.co.uk",
        "selectorId": "724",
        "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
        "trafficClass": "TRANSACTIONAL_DATA",
        "tunnel": "false"
      }
    ],
    "networkIdentity": [
      {
        "id": "a42a126a-be13-4c97-929e-0846968857f6",
        "instanceId": 13513,
        "authEntityId": 13513,
        "displayName": "13513",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "53,80,443,5353",
        "protocol": "TCP",
        "upperPort": 0
      },
      {
        "id": "e44f52cf-397c-4070-83ff-fa170a65f879",
        "instanceId": 13514,
        "authEntityId": 13514,
        "displayName": "13514",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "53,5353",
        "protocol": "UDP",
        "upperPort": 0
      }
    ],
    "parentScalableGroup": {
      "idRef": "28e08de4-d7b2-4521-84ce-bc1e5ee0b9cc"
    },
    "user": [

    ],
    "userGroup": [

    ]
  },
  {
    "id": "3ca3301d-908c-4ce4-9dc6-3ca0035a83d2",
    "instanceId": 15295,
    "authEntityId": 15295,
    "displayName": "15295",
    "authEntityClass": -1909073334,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "createTime": 1517966744678,
    "deployed": false,
    "isSeeded": true,
    "isStale": false,
    "lastUpdateTime": 1517966744678,
    "name": "tnETOS",
    "namespace": "scalablegroup:application",
    "provisioningState": "DEFINED",
    "qualifier": "application",
    "resourceVersion": 0,
    "targetIdList": [

    ],
    "type": "scalablegroup",
    "cfsChangeInfo": [

    ],
    "customProvisions": [

    ],
    "scalableGroupExternalHandle": "tnETOS",
    "scalableGroupType": "APPLICATION",
    "securityGroupTag": 0,
    "state": "ACTIVE",
    "vnAgnostic": true,
    "identitySource": {
      "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
      "instanceId": 10010,
      "authEntityId": 10010,
      "displayName": "10010",
      "authEntityClass": 1738953278,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "state": "INACTIVE",
      "type": "NBAR"
    },
    "indicativeNetworkIdentity": [
      {
        "id": "573d7322-f2e4-44f4-9bf9-a40d3ebc682c",
        "instanceId": 17515,
        "authEntityId": 17515,
        "displayName": "17515",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "377",
        "protocol": "TCP",
        "upperPort": 0
      },
      {
        "id": "78f06d4c-5bdc-402c-b32f-12da25b20ed8",
        "instanceId": 17516,
        "authEntityId": 17516,
        "displayName": "17516",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "377",
        "protocol": "UDP",
        "upperPort": 0
      }
    ],
    "networkApplications": [
      {
        "id": "2fb13e6f-e387-487b-917b-f1495766b81c",
        "instanceId": 16238,
        "authEntityId": 16238,
        "displayName": "16238",
        "authEntityClass": -217092956,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "appProtocol": "tcp/udp",
        "applicationGroup": "other",
        "applicationType": "DEFAULT",
        "categoryId": "6daa3dfd-3526-407a-88f0-97824b3f3a24",
        "createTime": 1517966744678,
        "encrypted": "false",
        "engineId": "3",
        "helpString": "NEC Corporation",
        "ignoreConflict": false,
        "lastUpdateTime": 1517966744678,
        "longDescription": "Registered with IANA on port 377 TCP/UDP",
        "name": "tnETOS",
        "nbarId": "293",
        "p2pTechnology": "false",
        "popularity": 1,
        "rank": 65535,
        "references": "http://www.iana.org/assignments/service-names-port-numbers/service-names-port-numbers.xml",
        "selectorId": "377",
        "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
        "trafficClass": "BULK_DATA",
        "tunnel": "false"
      }
    ],
    "networkIdentity": [
      {
        "id": "fe07e638-88be-46a1-b41d-92021f6e1b82",
        "instanceId": 17513,
        "authEntityId": 17513,
        "displayName": "17513",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "377",
        "protocol": "TCP",
        "upperPort": 0
      },
      {
        "id": "366fcb65-8aa3-49ce-8bb4-a52b4510cc27",
        "instanceId": 17514,
        "authEntityId": 17514,
        "displayName": "17514",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "377",
        "protocol": "UDP",
        "upperPort": 0
      }
    ],
    "parentScalableGroup": {
      "idRef": "000c15bf-28fe-4fbb-9b69-35e6502721f1"
    },
    "user": [

    ],
    "userGroup": [

    ]
  },
  {
    "id": "3cedf7c3-de44-40cb-a926-1893a4795cc7",
    "instanceId": 4243,
    "authEntityId": 4243,
    "displayName": "4243",
    "authEntityClass": -1909073334,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "createTime": 1517966741919,
    "deployed": false,
    "isSeeded": true,
    "isStale": false,
    "lastUpdateTime": 1517966741919,
    "name": "cisco-media-video",
    "namespace": "scalablegroup:application",
    "provisioningState": "DEFINED",
    "qualifier": "application",
    "resourceVersion": 0,
    "targetIdList": [

    ],
    "type": "scalablegroup",
    "cfsChangeInfo": [

    ],
    "customProvisions": [

    ],
    "scalableGroupExternalHandle": "cisco-media-video",
    "scalableGroupType": "APPLICATION",
    "securityGroupTag": 0,
    "state": "ACTIVE",
    "vnAgnostic": true,
    "identitySource": {
      "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
      "instanceId": 10010,
      "authEntityId": 10010,
      "displayName": "10010",
      "authEntityClass": 1738953278,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "state": "INACTIVE",
      "type": "NBAR"
    },
    "indicativeNetworkIdentity": [

    ],
    "networkApplications": [
      {
        "id": "333e245c-d906-43b4-98c4-bdec0b868c23",
        "instanceId": 11197,
        "authEntityId": 11197,
        "displayName": "11197",
        "authEntityClass": -217092956,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "applicationGroup": "cisco-phone-group",
        "applicationType": "DEFAULT",
        "categoryId": "38a8e354-e2fe-4f3c-b1c5-2bbb90fb0d99",
        "createTime": 1517966741919,
        "encrypted": "false",
        "engineId": "13",
        "helpString": "Cisco IP Phones and PC-based Unified Communicators",
        "ignoreConflict": false,
        "lastUpdateTime": 1517966741919,
        "longDescription": "Cisco-media is used mainly in corporations and can be used on or off site.",
        "name": "cisco-media-video",
        "nbarId": "1747",
        "p2pTechnology": "false",
        "popularity": 10,
        "rank": 65535,
        "selectorId": "1747",
        "subCategory": "53ce0342-1e2d-455a-acc7-1b50db9d6845",
        "trafficClass": "MULTIMEDIA_CONFERENCING",
        "tunnel": "false"
      }
    ],
    "networkIdentity": [

    ],
    "parentScalableGroup": {
      "idRef": "60f1364d-2a02-4063-8ba1-07817abe0612"
    },
    "user": [

    ],
    "userGroup": [

    ]
  },
  {
    "id": "3d2f0482-288a-4adf-8d5f-153d6f8a8b74",
    "instanceId": 15161,
    "authEntityId": 15161,
    "displayName": "15161",
    "authEntityClass": -1909073334,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "createTime": 1517966744374,
    "deployed": false,
    "isSeeded": true,
    "isStale": false,
    "lastUpdateTime": 1517966744374,
    "name": "smakynet",
    "namespace": "scalablegroup:application",
    "provisioningState": "DEFINED",
    "qualifier": "application",
    "resourceVersion": 0,
    "targetIdList": [

    ],
    "type": "scalablegroup",
    "cfsChangeInfo": [

    ],
    "customProvisions": [

    ],
    "scalableGroupExternalHandle": "smakynet",
    "scalableGroupType": "APPLICATION",
    "securityGroupTag": 0,
    "state": "ACTIVE",
    "vnAgnostic": true,
    "identitySource": {
      "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
      "instanceId": 10010,
      "authEntityId": 10010,
      "displayName": "10010",
      "authEntityClass": 1738953278,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "state": "INACTIVE",
      "type": "NBAR"
    },
    "indicativeNetworkIdentity": [
      {
        "id": "2bf94aab-a983-405f-8488-a06c5e31ee99",
        "instanceId": 17127,
        "authEntityId": 17127,
        "displayName": "17127",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "122",
        "protocol": "UDP",
        "upperPort": 0
      },
      {
        "id": "99e357b0-5677-495f-9be2-488abf20a352",
        "instanceId": 17128,
        "authEntityId": 17128,
        "displayName": "17128",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "122",
        "protocol": "TCP",
        "upperPort": 0
      }
    ],
    "networkApplications": [
      {
        "id": "e3579e39-73f6-4652-a0a0-7573bd529f1e",
        "instanceId": 16104,
        "authEntityId": 16104,
        "displayName": "16104",
        "authEntityClass": -217092956,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "appProtocol": "tcp/udp",
        "applicationGroup": "other",
        "applicationType": "DEFAULT",
        "categoryId": "6daa3dfd-3526-407a-88f0-97824b3f3a24",
        "createTime": 1517966744374,
        "encrypted": "false",
        "engineId": "3",
        "helpString": "smakynet",
        "ignoreConflict": false,
        "lastUpdateTime": 1517966744374,
        "longDescription": "Registered with IANA on port 122 TCP/UDP",
        "name": "smakynet",
        "nbarId": "991",
        "p2pTechnology": "false",
        "popularity": 1,
        "rank": 65535,
        "references": "http://www.iana.org/assignments/service-names-port-numbers/service-names-port-numbers.xml",
        "selectorId": "122",
        "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
        "trafficClass": "BULK_DATA",
        "tunnel": "false"
      }
    ],
    "networkIdentity": [
      {
        "id": "b0b47ccb-8c53-421b-a983-e12e7c9dfb45",
        "instanceId": 17125,
        "authEntityId": 17125,
        "displayName": "17125",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "122",
        "protocol": "UDP",
        "upperPort": 0
      },
      {
        "id": "501bf796-8a7f-457e-a66e-7051f93413a3",
        "instanceId": 17126,
        "authEntityId": 17126,
        "displayName": "17126",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "122",
        "protocol": "TCP",
        "upperPort": 0
      }
    ],
    "parentScalableGroup": {
      "idRef": "000c15bf-28fe-4fbb-9b69-35e6502721f1"
    },
    "user": [

    ],
    "userGroup": [

    ]
  },
  {
    "id": "3d5e5ee5-e345-4228-960f-f4f58247b0a3",
    "instanceId": 4823,
    "authEntityId": 4823,
    "displayName": "4823",
    "authEntityClass": -1909073334,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "createTime": 1517966743561,
    "deployed": false,
    "isSeeded": true,
    "isStale": false,
    "lastUpdateTime": 1517966743561,
    "name": "nbc-news",
    "namespace": "scalablegroup:application",
    "provisioningState": "DEFINED",
    "qualifier": "application",
    "resourceVersion": 0,
    "targetIdList": [

    ],
    "type": "scalablegroup",
    "cfsChangeInfo": [

    ],
    "customProvisions": [

    ],
    "scalableGroupExternalHandle": "nbc-news",
    "scalableGroupType": "APPLICATION",
    "securityGroupTag": 0,
    "state": "ACTIVE",
    "vnAgnostic": true,
    "identitySource": {
      "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
      "instanceId": 10010,
      "authEntityId": 10010,
      "displayName": "10010",
      "authEntityClass": 1738953278,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "state": "INACTIVE",
      "type": "NBAR"
    },
    "indicativeNetworkIdentity": [

    ],
    "networkApplications": [
      {
        "id": "9f88fcfc-4841-462f-be21-2930beafe463",
        "instanceId": 11772,
        "authEntityId": 11772,
        "displayName": "11772",
        "authEntityClass": -217092956,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "appProtocol": "tcp/udp",
        "applicationGroup": "other",
        "applicationType": "DEFAULT",
        "categoryId": "878644b5-fe91-45b4-9204-31c84a73ba48",
        "createTime": 1517966743561,
        "encrypted": "false",
        "engineId": "13",
        "helpString": "NBC News - NBC Universals news website.",
        "ignoreConflict": false,
        "lastUpdateTime": 1517966743561,
        "longDescription": "NBC News - NBC Universals news website.",
        "name": "nbc-news",
        "nbarId": "1649",
        "p2pTechnology": "false",
        "popularity": 3,
        "rank": 65535,
        "references": "http://nbcnews.com",
        "selectorId": "719",
        "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
        "trafficClass": "TRANSACTIONAL_DATA",
        "tunnel": "false"
      }
    ],
    "networkIdentity": [
      {
        "id": "6ccc81cf-23ff-4d6b-97f1-8fd2b8a87f3c",
        "instanceId": 14100,
        "authEntityId": 14100,
        "displayName": "14100",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "53,80,443,5353",
        "protocol": "TCP",
        "upperPort": 0
      },
      {
        "id": "4b2bf0e6-0f20-4b51-88e9-173302881b64",
        "instanceId": 14101,
        "authEntityId": 14101,
        "displayName": "14101",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "53,5353",
        "protocol": "UDP",
        "upperPort": 0
      }
    ],
    "parentScalableGroup": {
      "idRef": "28e08de4-d7b2-4521-84ce-bc1e5ee0b9cc"
    },
    "user": [

    ],
    "userGroup": [

    ]
  },
  {
    "id": "3d622cc9-1a7e-4936-8f4b-d01d973575fc",
    "instanceId": 4135,
    "authEntityId": 4135,
    "displayName": "4135",
    "authEntityClass": -1909073334,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "createTime": 1517966741313,
    "deployed": false,
    "isSeeded": true,
    "isStale": false,
    "lastUpdateTime": 1517966741313,
    "name": "at-rtmp",
    "namespace": "scalablegroup:application",
    "provisioningState": "DEFINED",
    "qualifier": "application",
    "resourceVersion": 0,
    "targetIdList": [

    ],
    "type": "scalablegroup",
    "cfsChangeInfo": [

    ],
    "customProvisions": [

    ],
    "scalableGroupExternalHandle": "at-rtmp",
    "scalableGroupType": "APPLICATION",
    "securityGroupTag": 0,
    "state": "ACTIVE",
    "vnAgnostic": true,
    "identitySource": {
      "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
      "instanceId": 10010,
      "authEntityId": 10010,
      "displayName": "10010",
      "authEntityClass": 1738953278,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "state": "INACTIVE",
      "type": "NBAR"
    },
    "indicativeNetworkIdentity": [
      {
        "id": "9b7ce68f-ed1d-4238-85a0-264a6a7531d0",
        "instanceId": 12269,
        "authEntityId": 12269,
        "displayName": "12269",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "201",
        "protocol": "TCP",
        "upperPort": 0
      },
      {
        "id": "3d77b39c-c587-405d-8c30-b70a7d00e976",
        "instanceId": 12270,
        "authEntityId": 12270,
        "displayName": "12270",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "201",
        "protocol": "UDP",
        "upperPort": 0
      }
    ],
    "networkApplications": [
      {
        "id": "729db36a-4942-4497-a3f1-8a5c8ff9dc41",
        "instanceId": 11097,
        "authEntityId": 11097,
        "displayName": "11097",
        "authEntityClass": -217092956,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "appProtocol": "tcp/udp",
        "applicationGroup": "apple-talk-group",
        "applicationType": "DEFAULT",
        "categoryId": "1fc17362-9334-4c7b-b752-5afdbb574017",
        "createTime": 1517966741313,
        "encrypted": "false",
        "engineId": "3",
        "helpString": "AppleTalk Routing Maintenance",
        "ignoreConflict": false,
        "lastUpdateTime": 1517966741313,
        "longDescription": "The AppleTalk Routing Table Maintenance Protocol (RTMP) was the protocol by which routers kept each other informed about the topology of the network.",
        "name": "at-rtmp",
        "nbarId": "1099",
        "p2pTechnology": "false",
        "popularity": 1,
        "rank": 65535,
        "references": "http://en.wikipedia.org/wiki/AppleTalk#Routing_Table_Maintenance_Protocol",
        "selectorId": "201",
        "subCategory": "7cc1b3a6-4102-44e9-b522-0e71afe39a03",
        "trafficClass": "NETWORK_CONTROL",
        "tunnel": "false"
      }
    ],
    "networkIdentity": [
      {
        "id": "50e08c5f-3f72-4169-be87-59dccfe95cd5",
        "instanceId": 12267,
        "authEntityId": 12267,
        "displayName": "12267",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "201",
        "protocol": "TCP",
        "upperPort": 0
      },
      {
        "id": "8c3afac3-1856-4cdf-a738-78654904b2bb",
        "instanceId": 12268,
        "authEntityId": 12268,
        "displayName": "12268",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "201",
        "protocol": "UDP",
        "upperPort": 0
      }
    ],
    "parentScalableGroup": {
      "idRef": "77e75842-e98b-4112-ad0c-300db4bb11be"
    },
    "user": [

    ],
    "userGroup": [

    ]
  },
  {
    "id": "3d74b177-d110-42d2-8cbe-414c19029352",
    "instanceId": 4724,
    "authEntityId": 4724,
    "displayName": "4724",
    "authEntityClass": -1909073334,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "createTime": 1517966743362,
    "deployed": false,
    "isSeeded": true,
    "isStale": false,
    "lastUpdateTime": 1517966743362,
    "name": "masqdialer",
    "namespace": "scalablegroup:application",
    "provisioningState": "DEFINED",
    "qualifier": "application",
    "resourceVersion": 0,
    "targetIdList": [

    ],
    "type": "scalablegroup",
    "cfsChangeInfo": [

    ],
    "customProvisions": [

    ],
    "scalableGroupExternalHandle": "masqdialer",
    "scalableGroupType": "APPLICATION",
    "securityGroupTag": 0,
    "state": "ACTIVE",
    "vnAgnostic": true,
    "identitySource": {
      "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
      "instanceId": 10010,
      "authEntityId": 10010,
      "displayName": "10010",
      "authEntityClass": 1738953278,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "state": "INACTIVE",
      "type": "NBAR"
    },
    "indicativeNetworkIdentity": [
      {
        "id": "0a0976d0-f999-4653-92d8-ec4b86718935",
        "instanceId": 13832,
        "authEntityId": 13832,
        "displayName": "13832",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "224",
        "protocol": "UDP",
        "upperPort": 0
      },
      {
        "id": "9626a8c2-75cb-44b5-b1f0-2341a77e6eff",
        "instanceId": 13833,
        "authEntityId": 13833,
        "displayName": "13833",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "224",
        "protocol": "TCP",
        "upperPort": 0
      }
    ],
    "networkApplications": [
      {
        "id": "40d01905-3d4d-4614-bb62-0001b4951f8b",
        "instanceId": 11674,
        "authEntityId": 11674,
        "displayName": "11674",
        "authEntityClass": -217092956,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "appProtocol": "tcp/udp",
        "applicationGroup": "other",
        "applicationType": "DEFAULT",
        "categoryId": "1fc17362-9334-4c7b-b752-5afdbb574017",
        "createTime": 1517966743362,
        "encrypted": "false",
        "engineId": "3",
        "helpString": "masqdialer",
        "ignoreConflict": false,
        "lastUpdateTime": 1517966743362,
        "longDescription": "The masqdialer system is designed to provide easily accessible control of multiple dialout modem connections to the members of a LAN using IP Masquerade for their internet connectivity. The system is a client/server design, so as long as a client can be written for a particular platform, that platform can take advantage of masqdialer's offerings.",
        "name": "masqdialer",
        "nbarId": "1121",
        "p2pTechnology": "false",
        "popularity": 1,
        "rank": 65535,
        "references": "http://archive.debian.net/potato/net/masqdialer",
        "selectorId": "224",
        "subCategory": "f3c0f761-d894-4112-ab89-b90fc84fd660",
        "trafficClass": "OPS_ADMIN_MGMT",
        "tunnel": "false"
      }
    ],
    "networkIdentity": [
      {
        "id": "1ce7b3fa-8211-44aa-b684-e3cb4a68ba87",
        "instanceId": 13830,
        "authEntityId": 13830,
        "displayName": "13830",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "224",
        "protocol": "UDP",
        "upperPort": 0
      },
      {
        "id": "e3d68ddd-958b-45b5-b1df-4062fdf731a6",
        "instanceId": 13831,
        "authEntityId": 13831,
        "displayName": "13831",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "224",
        "protocol": "TCP",
        "upperPort": 0
      }
    ],
    "parentScalableGroup": {
      "idRef": "5fcd74d4-8726-47f7-a088-c457863da7ef"
    },
    "user": [

    ],
    "userGroup": [

    ]
  },
  {
    "id": "3d94f473-8618-49cb-8dc7-c2a46e2e0ddc",
    "instanceId": 4909,
    "authEntityId": 4909,
    "displayName": "4909",
    "authEntityClass": -1909073334,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "createTime": 1517966743737,
    "deployed": false,
    "isSeeded": true,
    "isStale": false,
    "lastUpdateTime": 1517966743737,
    "name": "opalis-rdv",
    "namespace": "scalablegroup:application",
    "provisioningState": "DEFINED",
    "qualifier": "application",
    "resourceVersion": 0,
    "targetIdList": [

    ],
    "type": "scalablegroup",
    "cfsChangeInfo": [

    ],
    "customProvisions": [

    ],
    "scalableGroupExternalHandle": "opalis-rdv",
    "scalableGroupType": "APPLICATION",
    "securityGroupTag": 0,
    "state": "ACTIVE",
    "vnAgnostic": true,
    "identitySource": {
      "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
      "instanceId": 10010,
      "authEntityId": 10010,
      "displayName": "10010",
      "authEntityClass": 1738953278,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "state": "INACTIVE",
      "type": "NBAR"
    },
    "indicativeNetworkIdentity": [
      {
        "id": "f661f3c9-7159-4cfc-8932-333a391c0c49",
        "instanceId": 14388,
        "authEntityId": 14388,
        "displayName": "14388",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "536",
        "protocol": "TCP",
        "upperPort": 0
      },
      {
        "id": "8fd26819-5dac-4b30-87d7-c82ee7c9dcb7",
        "instanceId": 14389,
        "authEntityId": 14389,
        "displayName": "14389",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "536",
        "protocol": "UDP",
        "upperPort": 0
      }
    ],
    "networkApplications": [
      {
        "id": "efc88cb4-de6c-415b-9b4d-8fc94b0522c7",
        "instanceId": 11858,
        "authEntityId": 11858,
        "displayName": "11858",
        "authEntityClass": -217092956,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "appProtocol": "tcp/udp",
        "applicationGroup": "other",
        "applicationType": "DEFAULT",
        "categoryId": "6daa3dfd-3526-407a-88f0-97824b3f3a24",
        "createTime": 1517966743737,
        "encrypted": "false",
        "engineId": "3",
        "helpString": "opalis-rdv",
        "ignoreConflict": false,
        "lastUpdateTime": 1517966743737,
        "longDescription": "opalis-rdv, Registered with IANA on port 536 TCP/UDP",
        "name": "opalis-rdv",
        "nbarId": "454",
        "p2pTechnology": "false",
        "popularity": 1,
        "rank": 65535,
        "references": "http://www.iana.org/assignments/service-names-port-numbers/service-names-port-numbers.xml",
        "selectorId": "536",
        "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
        "trafficClass": "BULK_DATA",
        "tunnel": "false"
      }
    ],
    "networkIdentity": [
      {
        "id": "2dfa58c3-000b-402a-a016-434767fb4747",
        "instanceId": 14386,
        "authEntityId": 14386,
        "displayName": "14386",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "536",
        "protocol": "TCP",
        "upperPort": 0
      },
      {
        "id": "7d9e2971-26ac-4cdc-8950-a70d5e1eab87",
        "instanceId": 14387,
        "authEntityId": 14387,
        "displayName": "14387",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "536",
        "protocol": "UDP",
        "upperPort": 0
      }
    ],
    "parentScalableGroup": {
      "idRef": "000c15bf-28fe-4fbb-9b69-35e6502721f1"
    },
    "user": [

    ],
    "userGroup": [

    ]
  },
  {
    "id": "3d9a2495-ab45-465b-ac5e-7cf69500213a",
    "instanceId": 4721,
    "authEntityId": 4721,
    "displayName": "4721",
    "authEntityClass": -1909073334,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "createTime": 1517966743355,
    "deployed": false,
    "isSeeded": true,
    "isStale": false,
    "lastUpdateTime": 1517966743355,
    "name": "mapi",
    "namespace": "scalablegroup:application",
    "provisioningState": "DEFINED",
    "qualifier": "application",
    "resourceVersion": 0,
    "targetIdList": [

    ],
    "type": "scalablegroup",
    "cfsChangeInfo": [

    ],
    "customProvisions": [

    ],
    "scalableGroupExternalHandle": "mapi",
    "scalableGroupType": "APPLICATION",
    "securityGroupTag": 0,
    "state": "ACTIVE",
    "vnAgnostic": true,
    "identitySource": {
      "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
      "instanceId": 10010,
      "authEntityId": 10010,
      "displayName": "10010",
      "authEntityClass": 1738953278,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "state": "INACTIVE",
      "type": "NBAR"
    },
    "indicativeNetworkIdentity": [

    ],
    "networkApplications": [
      {
        "id": "395f696a-c431-4ef6-a20e-91dbc8e3efff",
        "instanceId": 11671,
        "authEntityId": 11671,
        "displayName": "11671",
        "authEntityClass": -217092956,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "applicationGroup": "other",
        "applicationType": "DEFAULT",
        "categoryId": "27953c60-b2ff-4e1a-bc52-0187d8295250",
        "createTime": 1517966743355,
        "encrypted": "false",
        "engineId": "13",
        "helpString": "Messaging Application Programming Interface",
        "ignoreConflict": false,
        "lastUpdateTime": 1517966743355,
        "longDescription": "Messaging Application Programming Interface (MAPI) is a built-in system in Microsoft Windows. It enables numerous email applications to work together. Microsoft Outlook uses MAPI to communicate with Microsoft Exchange.",
        "name": "mapi",
        "nbarId": "78",
        "p2pTechnology": "false",
        "popularity": 9,
        "rank": 65535,
        "references": "http://msdn.microsoft.com/en-us/library/aa142548(v=exchg.65).aspx",
        "selectorId": "78",
        "subCategory": "b90786f4-0190-45b8-9924-21b3e9682590",
        "trafficClass": "BULK_DATA",
        "tunnel": "false"
      }
    ],
    "networkIdentity": [

    ],
    "parentScalableGroup": {
      "idRef": "8a7ca43a-32bd-40d2-871a-02220a165cb7"
    },
    "user": [

    ],
    "userGroup": [

    ]
  },
  {
    "id": "3da234a0-6730-4970-b172-fec2f6d0b778",
    "instanceId": 15442,
    "authEntityId": 15442,
    "displayName": "15442",
    "authEntityClass": -1909073334,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "createTime": 1517966744972,
    "deployed": false,
    "isSeeded": true,
    "isStale": false,
    "lastUpdateTime": 1517966744972,
    "name": "xns-ch",
    "namespace": "scalablegroup:application",
    "provisioningState": "DEFINED",
    "qualifier": "application",
    "resourceVersion": 0,
    "targetIdList": [

    ],
    "type": "scalablegroup",
    "cfsChangeInfo": [

    ],
    "customProvisions": [

    ],
    "scalableGroupExternalHandle": "xns-ch",
    "scalableGroupType": "APPLICATION",
    "securityGroupTag": 0,
    "state": "ACTIVE",
    "vnAgnostic": true,
    "identitySource": {
      "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
      "instanceId": 10010,
      "authEntityId": 10010,
      "displayName": "10010",
      "authEntityClass": 1738953278,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "state": "INACTIVE",
      "type": "NBAR"
    },
    "indicativeNetworkIdentity": [
      {
        "id": "66b6690d-3450-4072-8958-b7a56bc47536",
        "instanceId": 17911,
        "authEntityId": 17911,
        "displayName": "17911",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "54",
        "protocol": "TCP",
        "upperPort": 0
      },
      {
        "id": "823621a2-b61e-4a65-8bc8-7dd4ad5444e6",
        "instanceId": 17912,
        "authEntityId": 17912,
        "displayName": "17912",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "54",
        "protocol": "UDP",
        "upperPort": 0
      }
    ],
    "networkApplications": [
      {
        "id": "c6ca7708-937e-4a12-b9b6-5da36f68102d",
        "instanceId": 16385,
        "authEntityId": 16385,
        "displayName": "16385",
        "authEntityClass": -217092956,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "appProtocol": "tcp/udp",
        "applicationGroup": "xns-xerox-group",
        "applicationType": "DEFAULT",
        "categoryId": "1fc17362-9334-4c7b-b752-5afdbb574017",
        "createTime": 1517966744972,
        "encrypted": "false",
        "engineId": "3",
        "helpString": "XNS Clearinghouse",
        "ignoreConflict": false,
        "lastUpdateTime": 1517966744972,
        "longDescription": "Xerox Network Services Clearinghouse (XNS Clearinghouse) is part of the XNS network protocol suite, used by Xerox to provide network services such as mail, filing, directory, and authentication for the installed base of Xerox workstations.",
        "name": "xns-ch",
        "nbarId": "934",
        "p2pTechnology": "false",
        "popularity": 1,
        "rank": 65535,
        "references": "http://tools.ietf.org/html/rfc1491",
        "selectorId": "54",
        "subCategory": "f3c0f761-d894-4112-ab89-b90fc84fd660",
        "trafficClass": "OPS_ADMIN_MGMT",
        "tunnel": "false"
      }
    ],
    "networkIdentity": [
      {
        "id": "4078163f-b896-4410-af7b-c4c7acac39c4",
        "instanceId": 17909,
        "authEntityId": 17909,
        "displayName": "17909",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "54",
        "protocol": "UDP",
        "upperPort": 0
      },
      {
        "id": "d074b927-de05-400d-8ee3-986bb8b90675",
        "instanceId": 17910,
        "authEntityId": 17910,
        "displayName": "17910",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "54",
        "protocol": "TCP",
        "upperPort": 0
      }
    ],
    "parentScalableGroup": {
      "idRef": "5fcd74d4-8726-47f7-a088-c457863da7ef"
    },
    "user": [

    ],
    "userGroup": [

    ]
  },
  {
    "id": "3dba7670-781f-4861-9dc0-59bd844046c1",
    "instanceId": 4225,
    "authEntityId": 4225,
    "displayName": "4225",
    "authEntityClass": -1909073334,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "createTime": 1517966741833,
    "deployed": false,
    "isSeeded": true,
    "isStale": false,
    "lastUpdateTime": 1517966741833,
    "name": "checkpoint-cpmi",
    "namespace": "scalablegroup:application",
    "provisioningState": "DEFINED",
    "qualifier": "application",
    "resourceVersion": 0,
    "targetIdList": [

    ],
    "type": "scalablegroup",
    "cfsChangeInfo": [

    ],
    "customProvisions": [

    ],
    "scalableGroupExternalHandle": "checkpoint-cpmi",
    "scalableGroupType": "APPLICATION",
    "securityGroupTag": 0,
    "state": "ACTIVE",
    "vnAgnostic": true,
    "identitySource": {
      "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
      "instanceId": 10010,
      "authEntityId": 10010,
      "displayName": "10010",
      "authEntityClass": 1738953278,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "state": "INACTIVE",
      "type": "NBAR"
    },
    "indicativeNetworkIdentity": [
      {
        "id": "aa5124cd-9bc1-4c9b-b5f7-ff1ce6520bed",
        "instanceId": 12494,
        "authEntityId": 12494,
        "displayName": "12494",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "18190",
        "protocol": "TCP",
        "upperPort": 0
      }
    ],
    "networkApplications": [
      {
        "id": "d65d9a72-d729-4af6-8cb2-758eedce3471",
        "instanceId": 11180,
        "authEntityId": 11180,
        "displayName": "11180",
        "authEntityClass": -217092956,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "appProtocol": "tcp",
        "applicationGroup": "other",
        "applicationType": "DEFAULT",
        "categoryId": "70fce3cd-f2bd-48de-9020-7b7840b9e7a1",
        "createTime": 1517966741833,
        "encrypted": "false",
        "engineId": "3",
        "helpString": "Check Point Management Interface",
        "ignoreConflict": false,
        "lastUpdateTime": 1517966741833,
        "longDescription": "Check Point Management Interface (CPMI) is a proprietary protocol of Check Point Software Technologies. CPMI provides security services for their VPN-1 virtual private network/firewall software. The protocol contributes to Check Point Software's Open Platform for Security (OPSEC), which is a framework for network security. Typically CPMI uses TCP port 18190 as default.",
        "name": "checkpoint-cpmi",
        "nbarId": "1332",
        "p2pTechnology": "false",
        "popularity": 1,
        "rank": 65535,
        "references": "http://read.pudn.com/downloads142/doc/614417/CPMI.pdf",
        "selectorId": "18190",
        "subCategory": "ab63e736-9aa7-4946-b55b-3b26ddca609c",
        "trafficClass": "BULK_DATA",
        "tunnel": "false"
      }
    ],
    "networkIdentity": [
      {
        "id": "524105e9-a07c-42aa-b412-f95e0671f1b1",
        "instanceId": 12493,
        "authEntityId": 12493,
        "displayName": "12493",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "18190",
        "protocol": "TCP",
        "upperPort": 0
      }
    ],
    "parentScalableGroup": {
      "idRef": "79f9d505-477f-4ccd-bd2a-c5fc8097a2e1"
    },
    "user": [

    ],
    "userGroup": [

    ]
  },
  {
    "id": "3dc96a66-9941-4660-b976-7d4df3705e72",
    "instanceId": 15275,
    "authEntityId": 15275,
    "displayName": "15275",
    "authEntityClass": -1909073334,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "createTime": 1517966744635,
    "deployed": false,
    "isSeeded": true,
    "isStale": false,
    "lastUpdateTime": 1517966744635,
    "name": "texar",
    "namespace": "scalablegroup:application",
    "provisioningState": "DEFINED",
    "qualifier": "application",
    "resourceVersion": 0,
    "targetIdList": [

    ],
    "type": "scalablegroup",
    "cfsChangeInfo": [

    ],
    "customProvisions": [

    ],
    "scalableGroupExternalHandle": "texar",
    "scalableGroupType": "APPLICATION",
    "securityGroupTag": 0,
    "state": "ACTIVE",
    "vnAgnostic": true,
    "identitySource": {
      "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
      "instanceId": 10010,
      "authEntityId": 10010,
      "displayName": "10010",
      "authEntityClass": 1738953278,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "state": "INACTIVE",
      "type": "NBAR"
    },
    "indicativeNetworkIdentity": [
      {
        "id": "48dac47d-fb22-44bb-9abc-c4380233d299",
        "instanceId": 17457,
        "authEntityId": 17457,
        "displayName": "17457",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "333",
        "protocol": "TCP",
        "upperPort": 0
      },
      {
        "id": "9d01c66e-8d5e-4898-b069-41ee20371a57",
        "instanceId": 17458,
        "authEntityId": 17458,
        "displayName": "17458",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "333",
        "protocol": "UDP",
        "upperPort": 0
      }
    ],
    "networkApplications": [
      {
        "id": "0835fdbe-8585-48ed-8e31-d98cd4b40492",
        "instanceId": 16218,
        "authEntityId": 16218,
        "displayName": "16218",
        "authEntityClass": -217092956,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "appProtocol": "tcp/udp",
        "applicationGroup": "other",
        "applicationType": "DEFAULT",
        "categoryId": "6daa3dfd-3526-407a-88f0-97824b3f3a24",
        "createTime": 1517966744635,
        "encrypted": "unassigned",
        "engineId": "3",
        "helpString": "Texar Security Port",
        "ignoreConflict": false,
        "lastUpdateTime": 1517966744635,
        "longDescription": "Registered with IANA on port 333 TCP/UDP",
        "name": "texar",
        "nbarId": "869",
        "p2pTechnology": "unassigned",
        "popularity": 1,
        "rank": 65535,
        "references": "http://www.iana.org/assignments/service-names-port-numbers/service-names-port-numbers.xml",
        "selectorId": "333",
        "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
        "trafficClass": "BULK_DATA",
        "tunnel": "unassigned"
      }
    ],
    "networkIdentity": [
      {
        "id": "67904e2c-1a4f-4e43-bbc3-2a3abcc12dc8",
        "instanceId": 17456,
        "authEntityId": 17456,
        "displayName": "17456",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "333",
        "protocol": "TCP",
        "upperPort": 0
      },
      {
        "id": "c9c21a6b-36e2-4de1-a1d0-7c67780cc5bc",
        "instanceId": 17455,
        "authEntityId": 17455,
        "displayName": "17455",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "333",
        "protocol": "UDP",
        "upperPort": 0
      }
    ],
    "parentScalableGroup": {
      "idRef": "000c15bf-28fe-4fbb-9b69-35e6502721f1"
    },
    "user": [

    ],
    "userGroup": [

    ]
  },
  {
    "id": "3e0dee37-1bfd-49e8-a36b-edae06d23569",
    "instanceId": 15253,
    "authEntityId": 15253,
    "displayName": "15253",
    "authEntityClass": -1909073334,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "createTime": 1517966744586,
    "deployed": false,
    "isSeeded": true,
    "isStale": false,
    "lastUpdateTime": 1517966744586,
    "name": "tapeware",
    "namespace": "scalablegroup:application",
    "provisioningState": "DEFINED",
    "qualifier": "application",
    "resourceVersion": 0,
    "targetIdList": [

    ],
    "type": "scalablegroup",
    "cfsChangeInfo": [

    ],
    "customProvisions": [

    ],
    "scalableGroupExternalHandle": "tapeware",
    "scalableGroupType": "APPLICATION",
    "securityGroupTag": 0,
    "state": "ACTIVE",
    "vnAgnostic": true,
    "identitySource": {
      "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
      "instanceId": 10010,
      "authEntityId": 10010,
      "displayName": "10010",
      "authEntityClass": 1738953278,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "state": "INACTIVE",
      "type": "NBAR"
    },
    "indicativeNetworkIdentity": [
      {
        "id": "4cbdacb6-7ef1-4ffa-b8f8-491a6011c14d",
        "instanceId": 17400,
        "authEntityId": 17400,
        "displayName": "17400",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "3817",
        "protocol": "TCP",
        "upperPort": 0
      },
      {
        "id": "16685a00-4b80-49e4-a65d-dc46b64ddcde",
        "instanceId": 17401,
        "authEntityId": 17401,
        "displayName": "17401",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "3817",
        "protocol": "UDP",
        "upperPort": 0
      }
    ],
    "networkApplications": [
      {
        "id": "077a5cc6-8f68-4078-bae7-d6c58705eb3c",
        "instanceId": 16196,
        "authEntityId": 16196,
        "displayName": "16196",
        "authEntityClass": -217092956,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "appProtocol": "tcp/udp",
        "applicationGroup": "other",
        "applicationType": "DEFAULT",
        "categoryId": "80909d4c-efe6-4878-9e1e-68281fd26edd",
        "createTime": 1517966744586,
        "encrypted": "false",
        "engineId": "3",
        "helpString": "Yosemite tech tapeware",
        "ignoreConflict": false,
        "lastUpdateTime": 1517966744586,
        "longDescription": "Tapeware is an automatic Backup program from Yosemite Technologies Inc. software vendor. Tapeware was discontinued at the end of 2006 and replaced by Yosemite's new Product: Yosemite Backup.",
        "name": "tapeware",
        "nbarId": "1372",
        "p2pTechnology": "false",
        "popularity": 1,
        "rank": 65535,
        "references": "http://en.wikipedia.org/wiki/Tapeware",
        "selectorId": "3817",
        "subCategory": "4a197877-986d-4422-b48f-500fb4b3340f",
        "trafficClass": "BULK_DATA",
        "tunnel": "false"
      }
    ],
    "networkIdentity": [
      {
        "id": "a8f9b00e-1b52-4c79-98b7-62aba13619f1",
        "instanceId": 17398,
        "authEntityId": 17398,
        "displayName": "17398",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "3817",
        "protocol": "TCP",
        "upperPort": 0
      },
      {
        "id": "aa4b51a3-0599-44d6-a4b4-9969f66045dd",
        "instanceId": 17399,
        "authEntityId": 17399,
        "displayName": "17399",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "3817",
        "protocol": "UDP",
        "upperPort": 0
      }
    ],
    "parentScalableGroup": {
      "idRef": "f09a36de-df38-4ff8-b0be-56738afc75f7"
    },
    "user": [

    ],
    "userGroup": [

    ]
  },
  {
    "id": "3eb7a822-3fd9-4012-8638-0e4fca13eeaf",
    "instanceId": 15215,
    "authEntityId": 15215,
    "displayName": "15215",
    "authEntityClass": -1909073334,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "createTime": 1517966744505,
    "deployed": false,
    "isSeeded": true,
    "isStale": false,
    "lastUpdateTime": 1517966744505,
    "name": "statistical-conf-video",
    "namespace": "scalablegroup:application",
    "provisioningState": "DEFINED",
    "qualifier": "application",
    "resourceVersion": 0,
    "targetIdList": [

    ],
    "type": "scalablegroup",
    "cfsChangeInfo": [

    ],
    "customProvisions": [

    ],
    "scalableGroupExternalHandle": "statistical-conf-video",
    "scalableGroupType": "APPLICATION",
    "securityGroupTag": 0,
    "state": "ACTIVE",
    "vnAgnostic": true,
    "identitySource": {
      "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
      "instanceId": 10010,
      "authEntityId": 10010,
      "displayName": "10010",
      "authEntityClass": 1738953278,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "state": "INACTIVE",
      "type": "NBAR"
    },
    "indicativeNetworkIdentity": [

    ],
    "networkApplications": [
      {
        "id": "45a1cac9-d076-4e77-9323-a2d934f2550e",
        "instanceId": 16158,
        "authEntityId": 16158,
        "displayName": "16158",
        "authEntityClass": -217092956,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "applicationGroup": "other",
        "applicationType": "DEFAULT",
        "categoryId": "6daa3dfd-3526-407a-88f0-97824b3f3a24",
        "createTime": 1517966744505,
        "encrypted": "unassigned",
        "engineId": "13",
        "helpString": "Statistical Conference Video",
        "ignoreConflict": false,
        "lastUpdateTime": 1517966744505,
        "longDescription": "Statistical Conference Video based on machine learning",
        "name": "statistical-conf-video",
        "nbarId": "1800",
        "p2pTechnology": "unassigned",
        "popularity": 8,
        "rank": 65535,
        "selectorId": "1800",
        "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
        "trafficClass": "BULK_DATA",
        "tunnel": "unassigned"
      }
    ],
    "networkIdentity": [

    ],
    "parentScalableGroup": {
      "idRef": "000c15bf-28fe-4fbb-9b69-35e6502721f1"
    },
    "user": [

    ],
    "userGroup": [

    ]
  },
  {
    "id": "3efc9eb0-8c9f-4ad9-a388-dfc7517cde44",
    "instanceId": 15370,
    "authEntityId": 15370,
    "displayName": "15370",
    "authEntityClass": -1909073334,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "createTime": 1517966744823,
    "deployed": false,
    "isSeeded": true,
    "isStale": false,
    "lastUpdateTime": 1517966744823,
    "name": "vrrp",
    "namespace": "scalablegroup:application",
    "provisioningState": "DEFINED",
    "qualifier": "application",
    "resourceVersion": 0,
    "targetIdList": [

    ],
    "type": "scalablegroup",
    "cfsChangeInfo": [

    ],
    "customProvisions": [

    ],
    "scalableGroupExternalHandle": "vrrp",
    "scalableGroupType": "APPLICATION",
    "securityGroupTag": 0,
    "state": "ACTIVE",
    "vnAgnostic": true,
    "identitySource": {
      "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
      "instanceId": 10010,
      "authEntityId": 10010,
      "displayName": "10010",
      "authEntityClass": 1738953278,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "state": "INACTIVE",
      "type": "NBAR"
    },
    "indicativeNetworkIdentity": [
      {
        "id": "173f0591-479d-49af-82b7-ff8c3e911e72",
        "instanceId": 17726,
        "authEntityId": 17726,
        "displayName": "17726",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipProtocolNumbers": "112",
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "protocol": "VRRP",
        "upperPort": 0
      }
    ],
    "networkApplications": [
      {
        "id": "4278b2c0-1544-4a06-a605-69dea413afbc",
        "instanceId": 16313,
        "authEntityId": 16313,
        "displayName": "16313",
        "authEntityClass": -217092956,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "appProtocol": "ip",
        "applicationGroup": "other",
        "applicationType": "DEFAULT",
        "categoryId": "1fc17362-9334-4c7b-b752-5afdbb574017",
        "createTime": 1517966744823,
        "encrypted": "unassigned",
        "engineId": "1",
        "helpString": "Virtual Router Redundancy Protocol",
        "ignoreConflict": false,
        "lastUpdateTime": 1517966744823,
        "longDescription": "Virtual Router Redundancy Protocol (VRRP) is a network protocol providing an automatic assignment of IP protocols to participating hosts. The protocol is designed to eliminate the single point of failure inherent in the static default routed environment. VRRP is IP protocol number 112.",
        "name": "vrrp",
        "nbarId": "866",
        "p2pTechnology": "unassigned",
        "popularity": 5,
        "rank": 65535,
        "references": "http://tools.ietf.org/html/rfc5798",
        "selectorId": "112",
        "subCategory": "7cc1b3a6-4102-44e9-b522-0e71afe39a03",
        "trafficClass": "NETWORK_CONTROL",
        "tunnel": "unassigned"
      }
    ],
    "networkIdentity": [
      {
        "id": "16760b01-2307-4a96-bab0-20679c758cd2",
        "instanceId": 17725,
        "authEntityId": 17725,
        "displayName": "17725",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipProtocolNumbers": "112",
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "protocol": "VRRP",
        "upperPort": 0
      }
    ],
    "parentScalableGroup": {
      "idRef": "77e75842-e98b-4112-ad0c-300db4bb11be"
    },
    "user": [

    ],
    "userGroup": [

    ]
  },
  {
    "id": "3f123c85-d3e8-4cdc-9ace-1b9f38166fea",
    "instanceId": 4598,
    "authEntityId": 4598,
    "displayName": "4598",
    "authEntityClass": -1909073334,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "createTime": 1517966743085,
    "deployed": false,
    "isSeeded": true,
    "isStale": false,
    "lastUpdateTime": 1517966743085,
    "name": "inbusiness",
    "namespace": "scalablegroup:application",
    "provisioningState": "DEFINED",
    "qualifier": "application",
    "resourceVersion": 0,
    "targetIdList": [

    ],
    "type": "scalablegroup",
    "cfsChangeInfo": [

    ],
    "customProvisions": [

    ],
    "scalableGroupExternalHandle": "inbusiness",
    "scalableGroupType": "APPLICATION",
    "securityGroupTag": 0,
    "state": "ACTIVE",
    "vnAgnostic": true,
    "identitySource": {
      "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
      "instanceId": 10010,
      "authEntityId": 10010,
      "displayName": "10010",
      "authEntityClass": 1738953278,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "state": "INACTIVE",
      "type": "NBAR"
    },
    "indicativeNetworkIdentity": [
      {
        "id": "a1c95071-27c9-4c5d-ba04-d5a72a8ff1fc",
        "instanceId": 13509,
        "authEntityId": 13509,
        "displayName": "13509",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "244",
        "protocol": "TCP",
        "upperPort": 0
      },
      {
        "id": "160dab15-02e6-48ec-82e3-8b9060c03a9e",
        "instanceId": 13510,
        "authEntityId": 13510,
        "displayName": "13510",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "244",
        "protocol": "UDP",
        "upperPort": 0
      }
    ],
    "networkApplications": [
      {
        "id": "fbedc71f-a30b-4568-a4bb-060502e426c1",
        "instanceId": 11548,
        "authEntityId": 11548,
        "displayName": "11548",
        "authEntityClass": -217092956,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "appProtocol": "tcp/udp",
        "applicationGroup": "other",
        "applicationType": "DEFAULT",
        "categoryId": "6daa3dfd-3526-407a-88f0-97824b3f3a24",
        "createTime": 1517966743085,
        "encrypted": "false",
        "engineId": "3",
        "helpString": "inbusiness",
        "ignoreConflict": false,
        "lastUpdateTime": 1517966743085,
        "longDescription": "The Intel InBusiness eMail Station is a highly integrated email server which provides small businesses with the ability to locally manage and configure their own email accounts.",
        "name": "inbusiness",
        "nbarId": "1124",
        "p2pTechnology": "false",
        "popularity": 1,
        "rank": 65535,
        "references": "http://www.intel.com/support/inbusiness/emailstation/sb/cs-014773.htm",
        "selectorId": "244",
        "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
        "trafficClass": "BULK_DATA",
        "tunnel": "false"
      }
    ],
    "networkIdentity": [
      {
        "id": "2e4a2c79-409f-4d5a-909e-2a8b69b32d52",
        "instanceId": 13507,
        "authEntityId": 13507,
        "displayName": "13507",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "244",
        "protocol": "TCP",
        "upperPort": 0
      },
      {
        "id": "b78f2c47-839f-4ba4-8f2b-ff85bab701f4",
        "instanceId": 13508,
        "authEntityId": 13508,
        "displayName": "13508",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "244",
        "protocol": "UDP",
        "upperPort": 0
      }
    ],
    "parentScalableGroup": {
      "idRef": "000c15bf-28fe-4fbb-9b69-35e6502721f1"
    },
    "user": [

    ],
    "userGroup": [

    ]
  },
  {
    "id": "3f2439d4-7b23-4efc-abdf-27ee78531e10",
    "instanceId": 4986,
    "authEntityId": 4986,
    "displayName": "4986",
    "authEntityClass": -1909073334,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "createTime": 1517966743978,
    "deployed": false,
    "isSeeded": true,
    "isStale": false,
    "lastUpdateTime": 1517966743978,
    "name": "prm-nm",
    "namespace": "scalablegroup:application",
    "provisioningState": "DEFINED",
    "qualifier": "application",
    "resourceVersion": 0,
    "targetIdList": [

    ],
    "type": "scalablegroup",
    "cfsChangeInfo": [

    ],
    "customProvisions": [

    ],
    "scalableGroupExternalHandle": "prm-nm",
    "scalableGroupType": "APPLICATION",
    "securityGroupTag": 0,
    "state": "ACTIVE",
    "vnAgnostic": true,
    "identitySource": {
      "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
      "instanceId": 10010,
      "authEntityId": 10010,
      "displayName": "10010",
      "authEntityClass": 1738953278,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "state": "INACTIVE",
      "type": "NBAR"
    },
    "indicativeNetworkIdentity": [
      {
        "id": "c35dc80b-26f0-4e87-8722-78dd83dd3e27",
        "instanceId": 14610,
        "authEntityId": 14610,
        "displayName": "14610",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "409",
        "protocol": "UDP",
        "upperPort": 0
      },
      {
        "id": "d398175b-66ad-436d-bc5a-dfaffc11e0b3",
        "instanceId": 14611,
        "authEntityId": 14611,
        "displayName": "14611",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "409",
        "protocol": "TCP",
        "upperPort": 0
      }
    ],
    "networkApplications": [
      {
        "id": "15f9fcb7-1638-450a-b5b8-3f14c5434e48",
        "instanceId": 11935,
        "authEntityId": 11935,
        "displayName": "11935",
        "authEntityClass": -217092956,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "appProtocol": "tcp/udp",
        "applicationGroup": "prm-group",
        "applicationType": "DEFAULT",
        "categoryId": "d264d6f4-b014-43e6-acbc-516128d1c6c8",
        "createTime": 1517966743978,
        "encrypted": "false",
        "engineId": "3",
        "helpString": "Prospero Resource Manager Node Man",
        "ignoreConflict": false,
        "lastUpdateTime": 1517966743978,
        "longDescription": "The Prospero Resource Manager (PRM) is a scalable resource allocation system that supports the allocation of processing resources in large networks and on multiprocessor systems.",
        "name": "prm-nm",
        "nbarId": "324",
        "p2pTechnology": "false",
        "popularity": 1,
        "rank": 65535,
        "references": "http://citeseerx.ist.psu.edu/viewdoc/summary?doi=10.1.1.54.6776",
        "selectorId": "409",
        "subCategory": "68c4f989-5735-4e16-8d1c-6a9d89d5df9c",
        "trafficClass": "SIGNALING",
        "tunnel": "false"
      }
    ],
    "networkIdentity": [
      {
        "id": "ab470f62-2c96-4036-b000-10072b394294",
        "instanceId": 14608,
        "authEntityId": 14608,
        "displayName": "14608",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "409",
        "protocol": "TCP",
        "upperPort": 0
      },
      {
        "id": "b9b8197a-50a1-4205-9ceb-e836470316e8",
        "instanceId": 14609,
        "authEntityId": 14609,
        "displayName": "14609",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "409",
        "protocol": "UDP",
        "upperPort": 0
      }
    ],
    "parentScalableGroup": {
      "idRef": "a3243a74-3864-4041-a927-0201030da17c"
    },
    "user": [

    ],
    "userGroup": [

    ]
  },
  {
    "id": "3f37d1c3-d037-48be-a77e-5185f0030a48",
    "instanceId": 4655,
    "authEntityId": 4655,
    "displayName": "4655",
    "authEntityClass": -1909073334,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "createTime": 1517966743217,
    "deployed": false,
    "isSeeded": true,
    "isStale": false,
    "lastUpdateTime": 1517966743217,
    "name": "itunes-audio",
    "namespace": "scalablegroup:application",
    "provisioningState": "DEFINED",
    "qualifier": "application",
    "resourceVersion": 0,
    "targetIdList": [

    ],
    "type": "scalablegroup",
    "cfsChangeInfo": [

    ],
    "customProvisions": [

    ],
    "scalableGroupExternalHandle": "itunes-audio",
    "scalableGroupType": "APPLICATION",
    "securityGroupTag": 0,
    "state": "ACTIVE",
    "vnAgnostic": true,
    "identitySource": {
      "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
      "instanceId": 10010,
      "authEntityId": 10010,
      "displayName": "10010",
      "authEntityClass": 1738953278,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "state": "INACTIVE",
      "type": "NBAR"
    },
    "indicativeNetworkIdentity": [

    ],
    "networkApplications": [
      {
        "id": "9e504310-a215-4456-9465-bc7d6718ac03",
        "instanceId": 11605,
        "authEntityId": 11605,
        "displayName": "11605",
        "authEntityClass": -217092956,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "appProtocol": "tcp/udp",
        "applicationGroup": "apple-group",
        "applicationType": "DEFAULT",
        "categoryId": "a09ef1b3-ba26-4372-aee8-309d50697e76",
        "createTime": 1517966743217,
        "encrypted": "true",
        "engineId": "13",
        "helpString": "iTunes audio streaming ",
        "ignoreConflict": false,
        "lastUpdateTime": 1517966743217,
        "longDescription": "iTunes is a media player and media library application developed by Apple Inc. It is used to play, download, and organize digital audio and video on personal computers running the OS X and Microsoft Windows operating systems. Through the iTunes Store, users can purchase and download music, music videos, television shows, audiobooks, podcasts, movies, etc. on their MAC/ Win PC/ iDevices running iTunes. iTunes-audio refers to all audio streaming media services generated by iTunes such as: play music, podcasts, audiobooks, etc.",
        "name": "itunes-audio",
        "nbarId": "1510",
        "p2pTechnology": "false",
        "popularity": 10,
        "rank": 65535,
        "references": "http://www.apple.com/itunes/",
        "selectorId": "571",
        "subCategory": "bf1b9d2c-aa52-4f37-8860-40d4603319f5",
        "trafficClass": "MULTIMEDIA_STREAMING",
        "tunnel": "false"
      }
    ],
    "networkIdentity": [
      {
        "id": "4cb8d9ee-49c2-4275-b37c-b3e8a63854c6",
        "instanceId": 13651,
        "authEntityId": 13651,
        "displayName": "13651",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "53,80,443,5353",
        "protocol": "TCP",
        "upperPort": 0
      },
      {
        "id": "c5ce38a1-2a9b-4d10-8947-b8c2194fb74a",
        "instanceId": 13652,
        "authEntityId": 13652,
        "displayName": "13652",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "53,5353",
        "protocol": "UDP",
        "upperPort": 0
      }
    ],
    "parentScalableGroup": {
      "idRef": "fd6264c9-c16a-4311-a335-a3db039256f3"
    },
    "user": [

    ],
    "userGroup": [

    ]
  },
  {
    "id": "3f42eb09-ba2e-4ac6-81f5-779cbe53aee4",
    "instanceId": 4733,
    "authEntityId": 4733,
    "displayName": "4733",
    "authEntityClass": -1909073334,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "createTime": 1517966743385,
    "deployed": false,
    "isSeeded": true,
    "isStale": false,
    "lastUpdateTime": 1517966743385,
    "name": "mecomm",
    "namespace": "scalablegroup:application",
    "provisioningState": "DEFINED",
    "qualifier": "application",
    "resourceVersion": 0,
    "targetIdList": [

    ],
    "type": "scalablegroup",
    "cfsChangeInfo": [

    ],
    "customProvisions": [

    ],
    "scalableGroupExternalHandle": "mecomm",
    "scalableGroupType": "APPLICATION",
    "securityGroupTag": 0,
    "state": "ACTIVE",
    "vnAgnostic": true,
    "identitySource": {
      "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
      "instanceId": 10010,
      "authEntityId": 10010,
      "displayName": "10010",
      "authEntityClass": 1738953278,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "state": "INACTIVE",
      "type": "NBAR"
    },
    "indicativeNetworkIdentity": [
      {
        "id": "a4684c26-16bb-4657-88f6-b9802cd45e95",
        "instanceId": 13860,
        "authEntityId": 13860,
        "displayName": "13860",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "668",
        "protocol": "TCP",
        "upperPort": 0
      },
      {
        "id": "48be035f-5bcf-46ba-befa-e85bd5b6ee13",
        "instanceId": 13861,
        "authEntityId": 13861,
        "displayName": "13861",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "668",
        "protocol": "UDP",
        "upperPort": 0
      }
    ],
    "networkApplications": [
      {
        "id": "8e8f7eda-a2cb-4eb7-b095-bc70d363b77e",
        "instanceId": 11683,
        "authEntityId": 11683,
        "displayName": "11683",
        "authEntityClass": -217092956,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "appProtocol": "tcp/udp",
        "applicationGroup": "other",
        "applicationType": "DEFAULT",
        "categoryId": "01adfd0e-0c60-495e-91f8-afba613b2e09",
        "createTime": 1517966743385,
        "encrypted": "false",
        "engineId": "3",
        "helpString": "mecomm",
        "ignoreConflict": false,
        "lastUpdateTime": 1517966743385,
        "longDescription": "Registered with IANA on port 668 TCP/UDP",
        "name": "mecomm",
        "nbarId": "576",
        "p2pTechnology": "false",
        "popularity": 1,
        "rank": 65535,
        "references": "http://www.iana.org/assignments/service-names-port-numbers/service-names-port-numbers.xml",
        "selectorId": "668",
        "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
        "trafficClass": "BULK_DATA",
        "tunnel": "false"
      }
    ],
    "networkIdentity": [
      {
        "id": "e0d45363-4d1c-40f2-8071-587dfcbce2bd",
        "instanceId": 13858,
        "authEntityId": 13858,
        "displayName": "13858",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "668",
        "protocol": "UDP",
        "upperPort": 0
      },
      {
        "id": "bd933fb3-2461-4c1c-83b1-8263a7f4c454",
        "instanceId": 13859,
        "authEntityId": 13859,
        "displayName": "13859",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "668",
        "protocol": "TCP",
        "upperPort": 0
      }
    ],
    "parentScalableGroup": {
      "idRef": "5e66d25d-d517-482b-82a8-6f99f07fa786"
    },
    "user": [

    ],
    "userGroup": [

    ]
  },
  {
    "id": "3f869621-47a5-45e1-8e0a-c7a510156f3b",
    "instanceId": 15466,
    "authEntityId": 15466,
    "displayName": "15466",
    "authEntityClass": -1909073334,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "createTime": 1517966745022,
    "deployed": false,
    "isSeeded": true,
    "isStale": false,
    "lastUpdateTime": 1517966745022,
    "name": "zendesk",
    "namespace": "scalablegroup:application",
    "provisioningState": "DEFINED",
    "qualifier": "application",
    "resourceVersion": 0,
    "targetIdList": [

    ],
    "type": "scalablegroup",
    "cfsChangeInfo": [

    ],
    "customProvisions": [

    ],
    "scalableGroupExternalHandle": "zendesk",
    "scalableGroupType": "APPLICATION",
    "securityGroupTag": 0,
    "state": "ACTIVE",
    "vnAgnostic": true,
    "identitySource": {
      "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
      "instanceId": 10010,
      "authEntityId": 10010,
      "displayName": "10010",
      "authEntityClass": 1738953278,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "state": "INACTIVE",
      "type": "NBAR"
    },
    "indicativeNetworkIdentity": [

    ],
    "networkApplications": [
      {
        "id": "b90a274c-03cd-47ab-9579-91d71a9556c1",
        "instanceId": 16409,
        "authEntityId": 16409,
        "displayName": "16409",
        "authEntityClass": -217092956,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "appProtocol": "tcp/udp",
        "applicationGroup": "other",
        "applicationType": "DEFAULT",
        "categoryId": "878644b5-fe91-45b4-9204-31c84a73ba48",
        "createTime": 1517966745022,
        "encrypted": "false",
        "engineId": "13",
        "helpString": "Zendesk - Customer support web application.",
        "ignoreConflict": false,
        "lastUpdateTime": 1517966745022,
        "longDescription": "Zendesk - Customer support web application.",
        "name": "zendesk",
        "nbarId": "1615",
        "p2pTechnology": "false",
        "popularity": 3,
        "rank": 65535,
        "references": "http://zendesk.com",
        "selectorId": "685",
        "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
        "trafficClass": "TRANSACTIONAL_DATA",
        "tunnel": "false"
      }
    ],
    "networkIdentity": [
      {
        "id": "fd7825ea-749d-4e74-a616-59d6fd5e25cb",
        "instanceId": 17968,
        "authEntityId": 17968,
        "displayName": "17968",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "53,5353",
        "protocol": "UDP",
        "upperPort": 0
      },
      {
        "id": "46479876-830c-4f87-8576-d9752c8e7828",
        "instanceId": 17969,
        "authEntityId": 17969,
        "displayName": "17969",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "53,80,443,5353",
        "protocol": "TCP",
        "upperPort": 0
      }
    ],
    "parentScalableGroup": {
      "idRef": "28e08de4-d7b2-4521-84ce-bc1e5ee0b9cc"
    },
    "user": [

    ],
    "userGroup": [

    ]
  },
  {
    "id": "3fa07954-2049-4d67-b2e1-4ee4c83270b0",
    "instanceId": 4995,
    "authEntityId": 4995,
    "displayName": "4995",
    "authEntityClass": -1909073334,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "createTime": 1517966744004,
    "deployed": false,
    "isSeeded": true,
    "isStale": false,
    "lastUpdateTime": 1517966744004,
    "name": "ptp-general",
    "namespace": "scalablegroup:application",
    "provisioningState": "DEFINED",
    "qualifier": "application",
    "resourceVersion": 0,
    "targetIdList": [

    ],
    "type": "scalablegroup",
    "cfsChangeInfo": [

    ],
    "customProvisions": [

    ],
    "scalableGroupExternalHandle": "ptp-general",
    "scalableGroupType": "APPLICATION",
    "securityGroupTag": 0,
    "state": "ACTIVE",
    "vnAgnostic": true,
    "identitySource": {
      "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
      "instanceId": 10010,
      "authEntityId": 10010,
      "displayName": "10010",
      "authEntityClass": 1738953278,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "state": "INACTIVE",
      "type": "NBAR"
    },
    "indicativeNetworkIdentity": [
      {
        "id": "473185d8-7f86-4f79-9027-a5d269fbbc42",
        "instanceId": 14642,
        "authEntityId": 14642,
        "displayName": "14642",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "320",
        "protocol": "UDP",
        "upperPort": 0
      },
      {
        "id": "a93c95c1-a6dd-4650-9a77-135162a4711e",
        "instanceId": 14643,
        "authEntityId": 14643,
        "displayName": "14643",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "320",
        "protocol": "TCP",
        "upperPort": 0
      }
    ],
    "networkApplications": [
      {
        "id": "2a44b8a1-1fb8-4f03-b8e6-5bc53a8eb6c5",
        "instanceId": 11944,
        "authEntityId": 11944,
        "displayName": "11944",
        "authEntityClass": -217092956,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "appProtocol": "tcp/udp",
        "applicationGroup": "other",
        "applicationType": "DEFAULT",
        "categoryId": "6daa3dfd-3526-407a-88f0-97824b3f3a24",
        "createTime": 1517966744004,
        "encrypted": "false",
        "engineId": "3",
        "helpString": "PTP General",
        "ignoreConflict": false,
        "lastUpdateTime": 1517966744004,
        "longDescription": "The Precision Time Protocol (PTP) is a protocol used to synchronize clocks throughout a computer network. On a local area network it achieves clock accuracy in the sub-microsecond range, making it suitable for measurement and control systems.",
        "name": "ptp-general",
        "nbarId": "884",
        "p2pTechnology": "false",
        "popularity": 1,
        "rank": 65535,
        "references": "http://www.nist.gov/el/isd/ieee/ieee1588.cfm",
        "selectorId": "320",
        "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
        "trafficClass": "BULK_DATA",
        "tunnel": "false"
      }
    ],
    "networkIdentity": [
      {
        "id": "48e097b1-0639-4381-8922-4563c30de50f",
        "instanceId": 14640,
        "authEntityId": 14640,
        "displayName": "14640",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "320",
        "protocol": "TCP",
        "upperPort": 0
      },
      {
        "id": "3ae33851-c4e7-47fa-8b22-32bc67913480",
        "instanceId": 14641,
        "authEntityId": 14641,
        "displayName": "14641",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "320",
        "protocol": "UDP",
        "upperPort": 0
      }
    ],
    "parentScalableGroup": {
      "idRef": "000c15bf-28fe-4fbb-9b69-35e6502721f1"
    },
    "user": [

    ],
    "userGroup": [

    ]
  },
  {
    "id": "3fdf2ee4-deec-4503-a573-cd6a273e86a9",
    "instanceId": 4797,
    "authEntityId": 4797,
    "displayName": "4797",
    "authEntityClass": -1909073334,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "createTime": 1517966743512,
    "deployed": false,
    "isSeeded": true,
    "isStale": false,
    "lastUpdateTime": 1517966743512,
    "name": "msft-gc",
    "namespace": "scalablegroup:application",
    "provisioningState": "DEFINED",
    "qualifier": "application",
    "resourceVersion": 0,
    "targetIdList": [

    ],
    "type": "scalablegroup",
    "cfsChangeInfo": [

    ],
    "customProvisions": [

    ],
    "scalableGroupExternalHandle": "msft-gc",
    "scalableGroupType": "APPLICATION",
    "securityGroupTag": 0,
    "state": "ACTIVE",
    "vnAgnostic": true,
    "identitySource": {
      "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
      "instanceId": 10010,
      "authEntityId": 10010,
      "displayName": "10010",
      "authEntityClass": 1738953278,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "state": "INACTIVE",
      "type": "NBAR"
    },
    "indicativeNetworkIdentity": [
      {
        "id": "216ed5dd-572c-4a68-b579-b02803d7e32f",
        "instanceId": 14035,
        "authEntityId": 14035,
        "displayName": "14035",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "3268",
        "protocol": "TCP",
        "upperPort": 0
      },
      {
        "id": "c5788be6-bc8a-4b49-bf53-d41a8913325f",
        "instanceId": 14036,
        "authEntityId": 14036,
        "displayName": "14036",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "3268",
        "protocol": "UDP",
        "upperPort": 0
      }
    ],
    "networkApplications": [
      {
        "id": "f62a3ea4-5712-4bd7-9139-99bfc82428ae",
        "instanceId": 11746,
        "authEntityId": 11746,
        "displayName": "11746",
        "authEntityClass": -217092956,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "appProtocol": "tcp/udp",
        "applicationGroup": "sqlsvr-group",
        "applicationType": "DEFAULT",
        "categoryId": "1fc17362-9334-4c7b-b752-5afdbb574017",
        "createTime": 1517966743512,
        "encrypted": "false",
        "engineId": "3",
        "helpString": "Microsoft Global Catalog",
        "ignoreConflict": false,
        "lastUpdateTime": 1517966743512,
        "longDescription": "The Microsoft Global Catalog is a distributed data repository that contains a searchable, partial representation of every object in every domain in a multidomain ActiveDirectory Domain Services (ADDS) forest. The global catalog is stored on domain controllers that have been designated as global catalog servers and is distributed through multimaster replication.",
        "name": "msft-gc",
        "nbarId": "687",
        "p2pTechnology": "false",
        "popularity": 2,
        "rank": 65535,
        "references": "http://technet.microsoft.com/en-us/library/cc728188(v=ws.10).aspx",
        "selectorId": "3268",
        "subCategory": "f3c0f761-d894-4112-ab89-b90fc84fd660",
        "trafficClass": "OPS_ADMIN_MGMT",
        "tunnel": "false"
      }
    ],
    "networkIdentity": [
      {
        "id": "db257159-a360-4b13-9cea-a462c20425f9",
        "instanceId": 14033,
        "authEntityId": 14033,
        "displayName": "14033",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "3268",
        "protocol": "TCP",
        "upperPort": 0
      },
      {
        "id": "aa948948-f46c-48f5-b714-fa513280c34c",
        "instanceId": 14034,
        "authEntityId": 14034,
        "displayName": "14034",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "3268",
        "protocol": "UDP",
        "upperPort": 0
      }
    ],
    "parentScalableGroup": {
      "idRef": "5fcd74d4-8726-47f7-a088-c457863da7ef"
    },
    "user": [

    ],
    "userGroup": [

    ]
  },
  {
    "id": "3feadf30-82f3-4e4c-acb0-c2cb65312a0a",
    "instanceId": 15055,
    "authEntityId": 15055,
    "displayName": "15055",
    "authEntityClass": -1909073334,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "createTime": 1517966744129,
    "deployed": false,
    "isSeeded": true,
    "isStale": false,
    "lastUpdateTime": 1517966744129,
    "name": "rmiactivation",
    "namespace": "scalablegroup:application",
    "provisioningState": "DEFINED",
    "qualifier": "application",
    "resourceVersion": 0,
    "targetIdList": [

    ],
    "type": "scalablegroup",
    "cfsChangeInfo": [

    ],
    "customProvisions": [

    ],
    "scalableGroupExternalHandle": "rmiactivation",
    "scalableGroupType": "APPLICATION",
    "securityGroupTag": 0,
    "state": "ACTIVE",
    "vnAgnostic": true,
    "identitySource": {
      "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
      "instanceId": 10010,
      "authEntityId": 10010,
      "displayName": "10010",
      "authEntityClass": 1738953278,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "state": "INACTIVE",
      "type": "NBAR"
    },
    "indicativeNetworkIdentity": [
      {
        "id": "7167f6f3-7fdc-4a70-a4f3-9f658af3e285",
        "instanceId": 14803,
        "authEntityId": 14803,
        "displayName": "14803",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "1098",
        "protocol": "UDP",
        "upperPort": 0
      },
      {
        "id": "d6ecd53a-9da7-4bc9-b977-2ffb9c3c9d81",
        "instanceId": 14804,
        "authEntityId": 14804,
        "displayName": "14804",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "1098",
        "protocol": "TCP",
        "upperPort": 0
      }
    ],
    "networkApplications": [
      {
        "id": "8344d7c8-1527-4584-9f8c-89f6df227e9c",
        "instanceId": 11994,
        "authEntityId": 11994,
        "displayName": "11994",
        "authEntityClass": -217092956,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "appProtocol": "tcp/udp",
        "applicationGroup": "other",
        "applicationType": "DEFAULT",
        "categoryId": "6daa3dfd-3526-407a-88f0-97824b3f3a24",
        "createTime": 1517966744129,
        "encrypted": "false",
        "engineId": "3",
        "helpString": "rmiactivation",
        "ignoreConflict": false,
        "lastUpdateTime": 1517966744129,
        "longDescription": "The Java Remote Method Invocation Application Programming Interface (API), or Java RMI, is a Java API that performs the object-oriented equivalent of remote procedure calls (RPC). The Class RMI.Activation allows the programs to be written to register information about remote object implementations that should be created and execute \"on demand\", rather than running all the time.",
        "name": "rmiactivation",
        "nbarId": "682",
        "p2pTechnology": "false",
        "popularity": 1,
        "rank": 65535,
        "references": "http://docs.oracle.com/javase/1.4.2/docs/guide/rmi/activation.html",
        "selectorId": "1098",
        "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
        "trafficClass": "BULK_DATA",
        "tunnel": "false"
      }
    ],
    "networkIdentity": [
      {
        "id": "688109c9-07a6-4f53-b2c7-6619a08d4c30",
        "instanceId": 14801,
        "authEntityId": 14801,
        "displayName": "14801",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "1098",
        "protocol": "TCP",
        "upperPort": 0
      },
      {
        "id": "a0a5d379-5f11-42a2-9528-d17428bc93da",
        "instanceId": 14802,
        "authEntityId": 14802,
        "displayName": "14802",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "1098",
        "protocol": "UDP",
        "upperPort": 0
      }
    ],
    "parentScalableGroup": {
      "idRef": "000c15bf-28fe-4fbb-9b69-35e6502721f1"
    },
    "user": [

    ],
    "userGroup": [

    ]
  },
  {
    "id": "40044a93-48e2-44e5-81fe-b20bbbe2a320",
    "instanceId": 4260,
    "authEntityId": 4260,
    "displayName": "4260",
    "authEntityClass": -1909073334,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "createTime": 1517966741971,
    "deployed": false,
    "isSeeded": true,
    "isStale": false,
    "lastUpdateTime": 1517966741971,
    "name": "citrix",
    "namespace": "scalablegroup:application",
    "provisioningState": "DEFINED",
    "qualifier": "application",
    "resourceVersion": 0,
    "targetIdList": [

    ],
    "type": "scalablegroup",
    "cfsChangeInfo": [

    ],
    "customProvisions": [

    ],
    "scalableGroupExternalHandle": "citrix",
    "scalableGroupType": "APPLICATION",
    "securityGroupTag": 0,
    "state": "ACTIVE",
    "vnAgnostic": true,
    "identitySource": {
      "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
      "instanceId": 10010,
      "authEntityId": 10010,
      "displayName": "10010",
      "authEntityClass": 1738953278,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "state": "INACTIVE",
      "type": "NBAR"
    },
    "indicativeNetworkIdentity": [
      {
        "id": "0ee75a2f-f625-4f1c-b9e0-34acae2658a2",
        "instanceId": 12556,
        "authEntityId": 12556,
        "displayName": "12556",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "1494,2598",
        "protocol": "TCP",
        "upperPort": 0
      }
    ],
    "networkApplications": [
      {
        "id": "963bad75-d7e1-470c-8b25-cebc3a853a5d",
        "instanceId": 11214,
        "authEntityId": 11214,
        "displayName": "11214",
        "authEntityClass": -217092956,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "appProtocol": "tcp/udp",
        "applicationGroup": "other",
        "applicationType": "DEFAULT",
        "categoryId": "7e19fdea-6222-4c57-9e66-66c2340d6bc3",
        "createTime": 1517966741971,
        "encrypted": "true",
        "engineId": "13",
        "helpString": "Citrix Systems Metaframe 3.0",
        "ignoreConflict": false,
        "lastUpdateTime": 1517966741971,
        "longDescription": "Citrix is an application that mediates users remotely to their corporate applications. ICA:  Independed Computing Architecture is a designated protocol for application server system; it is used for transferring data between clients and servers.CGP: CGP is a tunneling protocol, the latest addition to the family of Citrix protocol.As of today it encapsulates ICA protocol but will be extended to other Citrix protocol such as RDP, HTTP/HTTPS.IMA: used for server-server communication. Server-Browser: Used mainly a control connection which has Published Application Name and triggers an ICA connection",
        "name": "citrix",
        "nbarId": "56",
        "p2pTechnology": "false",
        "popularity": 10,
        "rank": 65535,
        "references": "http://www.citrix.com/site/resources/dynamic/additional/ICA_Acceleration_0709a.pdf",
        "selectorId": "56",
        "subCategory": "ff664205-64f3-447e-acff-1ec1ba9a6c31",
        "trafficClass": "MULTIMEDIA_STREAMING",
        "tunnel": "false"
      }
    ],
    "networkIdentity": [
      {
        "id": "392db787-7cb2-47f2-836b-25399ed9ce7e",
        "instanceId": 12554,
        "authEntityId": 12554,
        "displayName": "12554",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "53,5353",
        "protocol": "UDP",
        "upperPort": 0
      },
      {
        "id": "eabd370e-f71d-4106-8dbc-007b6f560751",
        "instanceId": 12555,
        "authEntityId": 12555,
        "displayName": "12555",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "53,80,1080,1494,2598,5353",
        "protocol": "TCP",
        "upperPort": 0
      }
    ],
    "parentScalableGroup": {
      "idRef": "4a6b37c6-7e11-40fe-a6b1-719b24813145"
    },
    "user": [

    ],
    "userGroup": [

    ]
  },
  {
    "id": "40170a88-eea9-47af-af21-7c66f7af5f61",
    "instanceId": 4834,
    "authEntityId": 4834,
    "displayName": "4834",
    "authEntityClass": -1909073334,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "createTime": 1517966743582,
    "deployed": false,
    "isSeeded": true,
    "isStale": false,
    "lastUpdateTime": 1517966743582,
    "name": "netbios",
    "namespace": "scalablegroup:application",
    "provisioningState": "DEFINED",
    "qualifier": "application",
    "resourceVersion": 0,
    "targetIdList": [

    ],
    "type": "scalablegroup",
    "cfsChangeInfo": [

    ],
    "customProvisions": [

    ],
    "scalableGroupExternalHandle": "netbios",
    "scalableGroupType": "APPLICATION",
    "securityGroupTag": 0,
    "state": "ACTIVE",
    "vnAgnostic": true,
    "identitySource": {
      "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
      "instanceId": 10010,
      "authEntityId": 10010,
      "displayName": "10010",
      "authEntityClass": 1738953278,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "state": "INACTIVE",
      "type": "NBAR"
    },
    "indicativeNetworkIdentity": [

    ],
    "networkApplications": [
      {
        "id": "9bfe7a13-f374-46c0-90eb-5add95cc77f5",
        "instanceId": 11783,
        "authEntityId": 11783,
        "displayName": "11783",
        "authEntityClass": -217092956,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "applicationGroup": "other",
        "applicationType": "DEFAULT",
        "categoryId": "6daa3dfd-3526-407a-88f0-97824b3f3a24",
        "createTime": 1517966743582,
        "encrypted": "unassigned",
        "engineId": "13",
        "helpString": "DEPRECATED, traffic will not match",
        "ignoreConflict": false,
        "lastUpdateTime": 1517966743582,
        "longDescription": "Deprecated: Network Basic Input/Output System (NetBIOS)",
        "name": "netbios",
        "nbarId": "26",
        "p2pTechnology": "unassigned",
        "popularity": 0,
        "rank": 65535,
        "references": "http://www.ietf.org/rfc/rfc1001.txt",
        "selectorId": "26",
        "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
        "trafficClass": "BULK_DATA",
        "tunnel": "unassigned"
      }
    ],
    "networkIdentity": [

    ],
    "parentScalableGroup": {
      "idRef": "000c15bf-28fe-4fbb-9b69-35e6502721f1"
    },
    "user": [

    ],
    "userGroup": [

    ]
  },
  {
    "id": "401ec8cb-8b19-40c6-acbc-0818ccf32b78",
    "instanceId": 4143,
    "authEntityId": 4143,
    "displayName": "4143",
    "authEntityClass": -1909073334,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "createTime": 1517966741369,
    "deployed": false,
    "isSeeded": true,
    "isStale": false,
    "lastUpdateTime": 1517966741369,
    "name": "auditd",
    "namespace": "scalablegroup:application",
    "provisioningState": "DEFINED",
    "qualifier": "application",
    "resourceVersion": 0,
    "targetIdList": [

    ],
    "type": "scalablegroup",
    "cfsChangeInfo": [

    ],
    "customProvisions": [

    ],
    "scalableGroupExternalHandle": "auditd",
    "scalableGroupType": "APPLICATION",
    "securityGroupTag": 0,
    "state": "ACTIVE",
    "vnAgnostic": true,
    "identitySource": {
      "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
      "instanceId": 10010,
      "authEntityId": 10010,
      "displayName": "10010",
      "authEntityClass": 1738953278,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "state": "INACTIVE",
      "type": "NBAR"
    },
    "indicativeNetworkIdentity": [
      {
        "id": "32722f48-7df1-40bf-8437-37ea80996512",
        "instanceId": 12288,
        "authEntityId": 12288,
        "displayName": "12288",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "48",
        "protocol": "TCP",
        "upperPort": 0
      },
      {
        "id": "57cfed52-5bf3-4d13-9377-0e538cc086f8",
        "instanceId": 12289,
        "authEntityId": 12289,
        "displayName": "12289",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "48",
        "protocol": "UDP",
        "upperPort": 0
      }
    ],
    "networkApplications": [
      {
        "id": "1d66032c-094f-4bb6-90a7-76f31d3454f7",
        "instanceId": 11104,
        "authEntityId": 11104,
        "displayName": "11104",
        "authEntityClass": -217092956,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "appProtocol": "tcp/udp",
        "applicationGroup": "other",
        "applicationType": "DEFAULT",
        "categoryId": "1fc17362-9334-4c7b-b752-5afdbb574017",
        "createTime": 1517966741369,
        "encrypted": "false",
        "engineId": "3",
        "helpString": "Digital Audit daemon",
        "ignoreConflict": false,
        "lastUpdateTime": 1517966741369,
        "longDescription": "The audit daemon, auditd, operates as a server, monitoring /dev/audit for local audit data, monitoring a known port for data from remote cooperating audit daemons, and monitoring an AF_UNIX socket for input from the system administrator.",
        "name": "auditd",
        "nbarId": "928",
        "p2pTechnology": "false",
        "popularity": 1,
        "rank": 65535,
        "references": "http://h30097.www3.hp.com/docs/base_doc/DOCUMENTATION/V51_HTML/MAN/MAN8/0030____.HTM",
        "selectorId": "48",
        "subCategory": "f3c0f761-d894-4112-ab89-b90fc84fd660",
        "trafficClass": "OPS_ADMIN_MGMT",
        "tunnel": "false"
      }
    ],
    "networkIdentity": [
      {
        "id": "348452e1-fada-4e9a-a086-1be97b2e8c2e",
        "instanceId": 12286,
        "authEntityId": 12286,
        "displayName": "12286",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "48",
        "protocol": "TCP",
        "upperPort": 0
      },
      {
        "id": "268970e4-fbf7-40d4-a639-e1af855362d8",
        "instanceId": 12287,
        "authEntityId": 12287,
        "displayName": "12287",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "48",
        "protocol": "UDP",
        "upperPort": 0
      }
    ],
    "parentScalableGroup": {
      "idRef": "5fcd74d4-8726-47f7-a088-c457863da7ef"
    },
    "user": [

    ],
    "userGroup": [

    ]
  },
  {
    "id": "40368e4f-dd2c-4f7c-addc-8ec690355453",
    "instanceId": 4854,
    "authEntityId": 4854,
    "displayName": "4854",
    "authEntityClass": -1909073334,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "createTime": 1517966743620,
    "deployed": false,
    "isSeeded": true,
    "isStale": false,
    "lastUpdateTime": 1517966743620,
    "name": "netviewdm3",
    "namespace": "scalablegroup:application",
    "provisioningState": "DEFINED",
    "qualifier": "application",
    "resourceVersion": 0,
    "targetIdList": [

    ],
    "type": "scalablegroup",
    "cfsChangeInfo": [

    ],
    "customProvisions": [

    ],
    "scalableGroupExternalHandle": "netviewdm3",
    "scalableGroupType": "APPLICATION",
    "securityGroupTag": 0,
    "state": "ACTIVE",
    "vnAgnostic": true,
    "identitySource": {
      "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
      "instanceId": 10010,
      "authEntityId": 10010,
      "displayName": "10010",
      "authEntityClass": 1738953278,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "state": "INACTIVE",
      "type": "NBAR"
    },
    "indicativeNetworkIdentity": [
      {
        "id": "4eca4f88-2be8-4c38-8b8b-2221a66a8164",
        "instanceId": 14200,
        "authEntityId": 14200,
        "displayName": "14200",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "731",
        "protocol": "UDP",
        "upperPort": 0
      },
      {
        "id": "252ab935-a92a-43f2-bfce-ba6e4e744d73",
        "instanceId": 14201,
        "authEntityId": 14201,
        "displayName": "14201",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "731",
        "protocol": "TCP",
        "upperPort": 0
      }
    ],
    "networkApplications": [
      {
        "id": "fa7dc914-d622-4dfd-b9e6-34ceca47e6f7",
        "instanceId": 11803,
        "authEntityId": 11803,
        "displayName": "11803",
        "authEntityClass": -217092956,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "appProtocol": "tcp/udp",
        "applicationGroup": "other",
        "applicationType": "DEFAULT",
        "categoryId": "1fc17362-9334-4c7b-b752-5afdbb574017",
        "createTime": 1517966743620,
        "encrypted": "false",
        "engineId": "3",
        "helpString": "IBM NetView DM",
        "ignoreConflict": false,
        "lastUpdateTime": 1517966743620,
        "longDescription": "IBM NetView Distribution Manager (IBM NetView DM) provides centralized management capabilities. It enables customers to automate the distribution of software and data to a large number of remote targets when utilizing the S/390 computers as the primary management system. It provides centralized tracking, automated error recovery, and efficient utilization of the network during the distribution process and shows the customer a history of the distribution process.",
        "name": "netviewdm3",
        "nbarId": "617",
        "p2pTechnology": "false",
        "popularity": 1,
        "rank": 65535,
        "references": "http://www-01.ibm.com/software/tivoli/products/netview_distmgr/",
        "selectorId": "731",
        "subCategory": "f3c0f761-d894-4112-ab89-b90fc84fd660",
        "trafficClass": "OPS_ADMIN_MGMT",
        "tunnel": "false"
      }
    ],
    "networkIdentity": [
      {
        "id": "ad6ddc25-9006-4696-bdb2-2aca6a54d083",
        "instanceId": 14198,
        "authEntityId": 14198,
        "displayName": "14198",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "731",
        "protocol": "UDP",
        "upperPort": 0
      },
      {
        "id": "a9ee58e0-1128-4fae-9e1d-0103643dfca6",
        "instanceId": 14199,
        "authEntityId": 14199,
        "displayName": "14199",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "731",
        "protocol": "TCP",
        "upperPort": 0
      }
    ],
    "parentScalableGroup": {
      "idRef": "5fcd74d4-8726-47f7-a088-c457863da7ef"
    },
    "user": [

    ],
    "userGroup": [

    ]
  },
  {
    "id": "403a21b7-4edd-40e3-8124-b4e510c23fb4",
    "instanceId": 15036,
    "authEntityId": 15036,
    "displayName": "15036",
    "authEntityClass": -1909073334,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "createTime": 1517966744080,
    "deployed": false,
    "isSeeded": true,
    "isStale": false,
    "lastUpdateTime": 1517966744080,
    "name": "realmedia",
    "namespace": "scalablegroup:application",
    "provisioningState": "DEFINED",
    "qualifier": "application",
    "resourceVersion": 0,
    "targetIdList": [

    ],
    "type": "scalablegroup",
    "cfsChangeInfo": [

    ],
    "customProvisions": [

    ],
    "scalableGroupExternalHandle": "realmedia",
    "scalableGroupType": "APPLICATION",
    "securityGroupTag": 0,
    "state": "ACTIVE",
    "vnAgnostic": true,
    "identitySource": {
      "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
      "instanceId": 10010,
      "authEntityId": 10010,
      "displayName": "10010",
      "authEntityClass": 1738953278,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "state": "INACTIVE",
      "type": "NBAR"
    },
    "indicativeNetworkIdentity": [

    ],
    "networkApplications": [
      {
        "id": "f93f22d0-e9cd-4a28-8996-c598172d7158",
        "instanceId": 11975,
        "authEntityId": 11975,
        "displayName": "11975",
        "authEntityClass": -217092956,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "appProtocol": "tcp/udp",
        "applicationGroup": "other",
        "applicationType": "DEFAULT",
        "categoryId": "a09ef1b3-ba26-4372-aee8-309d50697e76",
        "createTime": 1517966744080,
        "encrypted": "true",
        "engineId": "13",
        "helpString": "RealMedia traffic",
        "ignoreConflict": false,
        "lastUpdateTime": 1517966744080,
        "longDescription": "RealMedia is a proprietary multimedia container format created by RealNetworks. Its extension is (.rm). It is typically used in conjunction with RealVideo and RealAudio and is used for streaming content over the Internet.",
        "name": "realmedia",
        "nbarId": "1442",
        "p2pTechnology": "false",
        "popularity": 8,
        "rank": 65535,
        "references": "http://uk.real.com/realplayer",
        "selectorId": "507",
        "subCategory": "85a797c0-aef8-4284-91a7-677f33e29abb",
        "trafficClass": "MULTIMEDIA_STREAMING",
        "tunnel": "false"
      }
    ],
    "networkIdentity": [
      {
        "id": "35fc7f14-b8a6-4470-b036-f4a1bd65a888",
        "instanceId": 14737,
        "authEntityId": 14737,
        "displayName": "14737",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "53,5353",
        "protocol": "UDP",
        "upperPort": 0
      },
      {
        "id": "6736bae5-eeb3-47ac-8ffc-85f83990eca1",
        "instanceId": 14738,
        "authEntityId": 14738,
        "displayName": "14738",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "53,80,443,554,5353,8554",
        "protocol": "TCP",
        "upperPort": 0
      }
    ],
    "parentScalableGroup": {
      "idRef": "fd6264c9-c16a-4311-a335-a3db039256f3"
    },
    "user": [

    ],
    "userGroup": [

    ]
  },
  {
    "id": "404d0069-b59d-4c59-a542-e5ccebe3758e",
    "instanceId": 15197,
    "authEntityId": 15197,
    "displayName": "15197",
    "authEntityClass": -1909073334,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "createTime": 1517966744457,
    "deployed": false,
    "isSeeded": true,
    "isStale": false,
    "lastUpdateTime": 1517966744457,
    "name": "sqlserv",
    "namespace": "scalablegroup:application",
    "provisioningState": "DEFINED",
    "qualifier": "application",
    "resourceVersion": 0,
    "targetIdList": [

    ],
    "type": "scalablegroup",
    "cfsChangeInfo": [

    ],
    "customProvisions": [

    ],
    "scalableGroupExternalHandle": "sqlserv",
    "scalableGroupType": "APPLICATION",
    "securityGroupTag": 0,
    "state": "ACTIVE",
    "vnAgnostic": true,
    "identitySource": {
      "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
      "instanceId": 10010,
      "authEntityId": 10010,
      "displayName": "10010",
      "authEntityClass": 1738953278,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "state": "INACTIVE",
      "type": "NBAR"
    },
    "indicativeNetworkIdentity": [
      {
        "id": "6ba09331-f3f9-4d98-b046-e53cd5a82875",
        "instanceId": 17228,
        "authEntityId": 17228,
        "displayName": "17228",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "118",
        "protocol": "TCP",
        "upperPort": 0
      },
      {
        "id": "f51c8643-364d-45e4-8912-16ad872afac2",
        "instanceId": 17229,
        "authEntityId": 17229,
        "displayName": "17229",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "118",
        "protocol": "UDP",
        "upperPort": 0
      }
    ],
    "networkApplications": [
      {
        "id": "b691af01-2d44-401e-b2d1-be0ed3fb9a40",
        "instanceId": 16140,
        "authEntityId": 16140,
        "displayName": "16140",
        "authEntityClass": -217092956,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "appProtocol": "tcp/udp",
        "applicationGroup": "sqlsvr-group",
        "applicationType": "DEFAULT",
        "categoryId": "cb5915d1-afec-4c30-ac9e-069ff382272f",
        "createTime": 1517966744457,
        "encrypted": "false",
        "engineId": "3",
        "helpString": "SQL Services",
        "ignoreConflict": false,
        "lastUpdateTime": 1517966744457,
        "longDescription": "REAL Serveris arelational database management system(RDBMS) built on top of the sqlite database engine.",
        "name": "sqlserv",
        "nbarId": "988",
        "p2pTechnology": "false",
        "popularity": 1,
        "rank": 65535,
        "references": "http://en.wikipedia.org/wiki/REAL_Server",
        "selectorId": "118",
        "subCategory": "b90786f4-0190-45b8-9924-21b3e9682590",
        "trafficClass": "TRANSACTIONAL_DATA",
        "tunnel": "false"
      }
    ],
    "networkIdentity": [
      {
        "id": "fef99d29-8c7b-4460-99b4-3732221d1314",
        "instanceId": 17226,
        "authEntityId": 17226,
        "displayName": "17226",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "118",
        "protocol": "UDP",
        "upperPort": 0
      },
      {
        "id": "4c14e76c-b060-4beb-a796-205ea5a71cf9",
        "instanceId": 17227,
        "authEntityId": 17227,
        "displayName": "17227",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "118",
        "protocol": "TCP",
        "upperPort": 0
      }
    ],
    "parentScalableGroup": {
      "idRef": "8718395e-683e-4a2d-a232-100202751271"
    },
    "user": [

    ],
    "userGroup": [

    ]
  },
  {
    "id": "40542e31-d667-4e8e-b084-e3ab4c04c82a",
    "instanceId": 15110,
    "authEntityId": 15110,
    "displayName": "15110",
    "authEntityClass": -1909073334,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "createTime": 1517966744257,
    "deployed": false,
    "isSeeded": true,
    "isStale": false,
    "lastUpdateTime": 1517966744257,
    "name": "secure-ftp",
    "namespace": "scalablegroup:application",
    "provisioningState": "DEFINED",
    "qualifier": "application",
    "resourceVersion": 0,
    "targetIdList": [

    ],
    "type": "scalablegroup",
    "cfsChangeInfo": [

    ],
    "customProvisions": [

    ],
    "scalableGroupExternalHandle": "secure-ftp",
    "scalableGroupType": "APPLICATION",
    "securityGroupTag": 0,
    "state": "ACTIVE",
    "vnAgnostic": true,
    "identitySource": {
      "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
      "instanceId": 10010,
      "authEntityId": 10010,
      "displayName": "10010",
      "authEntityClass": 1738953278,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "state": "INACTIVE",
      "type": "NBAR"
    },
    "indicativeNetworkIdentity": [
      {
        "id": "2ce5df5e-364b-454d-b51c-a63d066fa034",
        "instanceId": 14967,
        "authEntityId": 14967,
        "displayName": "14967",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "990",
        "protocol": "TCP",
        "upperPort": 0
      }
    ],
    "networkApplications": [
      {
        "id": "faa8f6ba-2b2d-47c4-8666-7f5340117a52",
        "instanceId": 16053,
        "authEntityId": 16053,
        "displayName": "16053",
        "authEntityClass": -217092956,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "appProtocol": "tcp",
        "applicationGroup": "ftp-group",
        "applicationType": "DEFAULT",
        "categoryId": "e6619ce8-97de-4c63-a53a-cceddbddc1a8",
        "createTime": 1517966744256,
        "encrypted": "true",
        "engineId": "3",
        "helpString": "FTP - File Transfer Protocol control over TLS/SSL",
        "ignoreConflict": false,
        "lastUpdateTime": 1517966744256,
        "longDescription": "FTPS (Secure FTP) is an extension to the commonly used File Transfer Protocol (FTP) that adds support for the Transport Layer Security (TLS) and the Secure Sockets Layer (SSL) cryptographic protocols.",
        "name": "secure-ftp",
        "nbarId": "44",
        "p2pTechnology": "false",
        "popularity": 6,
        "rank": 65535,
        "references": "http://en.wikipedia.org/wiki/FTPS",
        "selectorId": "990",
        "subCategory": "50b55e2e-d0d0-4404-bea4-819351565d90",
        "trafficClass": "BULK_DATA",
        "tunnel": "false"
      }
    ],
    "networkIdentity": [
      {
        "id": "0e93e5b9-bddb-45c7-a1ce-7afcd490d0d9",
        "instanceId": 14966,
        "authEntityId": 14966,
        "displayName": "14966",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "990",
        "protocol": "TCP",
        "upperPort": 0
      }
    ],
    "parentScalableGroup": {
      "idRef": "383363b1-4dd5-49d1-bfe4-34daac437cfb"
    },
    "user": [

    ],
    "userGroup": [

    ]
  },
  {
    "id": "405c5e41-e88e-4b65-a271-38729fb4cb48",
    "instanceId": 4989,
    "authEntityId": 4989,
    "displayName": "4989",
    "authEntityClass": -1909073334,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "createTime": 1517966743989,
    "deployed": false,
    "isSeeded": true,
    "isStale": false,
    "lastUpdateTime": 1517966743989,
    "name": "prospero",
    "namespace": "scalablegroup:application",
    "provisioningState": "DEFINED",
    "qualifier": "application",
    "resourceVersion": 0,
    "targetIdList": [

    ],
    "type": "scalablegroup",
    "cfsChangeInfo": [

    ],
    "customProvisions": [

    ],
    "scalableGroupExternalHandle": "prospero",
    "scalableGroupType": "APPLICATION",
    "securityGroupTag": 0,
    "state": "ACTIVE",
    "vnAgnostic": true,
    "identitySource": {
      "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
      "instanceId": 10010,
      "authEntityId": 10010,
      "displayName": "10010",
      "authEntityClass": 1738953278,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "state": "INACTIVE",
      "type": "NBAR"
    },
    "indicativeNetworkIdentity": [
      {
        "id": "60b6a55e-bbbd-4172-ae16-5ca03a750c79",
        "instanceId": 14622,
        "authEntityId": 14622,
        "displayName": "14622",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "191",
        "protocol": "TCP",
        "upperPort": 0
      },
      {
        "id": "f1a42577-3b56-4a1f-8781-06a352eb00ea",
        "instanceId": 14623,
        "authEntityId": 14623,
        "displayName": "14623",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "191",
        "protocol": "UDP",
        "upperPort": 0
      }
    ],
    "networkApplications": [
      {
        "id": "03683b83-5f1b-4e57-8df7-485b47737b65",
        "instanceId": 11938,
        "authEntityId": 11938,
        "displayName": "11938",
        "authEntityClass": -217092956,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "appProtocol": "tcp/udp",
        "applicationGroup": "other",
        "applicationType": "DEFAULT",
        "categoryId": "e6619ce8-97de-4c63-a53a-cceddbddc1a8",
        "createTime": 1517966743989,
        "encrypted": "false",
        "engineId": "3",
        "helpString": "Prospero Directory Service",
        "ignoreConflict": false,
        "lastUpdateTime": 1517966743989,
        "longDescription": "The Prospero System is a collection of protocols and embedded software providing distributed directory services, file access services, naming etc.",
        "name": "prospero",
        "nbarId": "1035",
        "p2pTechnology": "false",
        "popularity": 1,
        "rank": 65535,
        "references": "http://www.prospero.org/",
        "selectorId": "191",
        "subCategory": "50b55e2e-d0d0-4404-bea4-819351565d90",
        "trafficClass": "BULK_DATA",
        "tunnel": "false"
      }
    ],
    "networkIdentity": [
      {
        "id": "f7b8792f-663f-4a4b-9681-95a2043581dc",
        "instanceId": 14620,
        "authEntityId": 14620,
        "displayName": "14620",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "191",
        "protocol": "TCP",
        "upperPort": 0
      },
      {
        "id": "0af9afc4-7268-4cf9-abf2-4d2eb4661299",
        "instanceId": 14621,
        "authEntityId": 14621,
        "displayName": "14621",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "191",
        "protocol": "UDP",
        "upperPort": 0
      }
    ],
    "parentScalableGroup": {
      "idRef": "383363b1-4dd5-49d1-bfe4-34daac437cfb"
    },
    "user": [

    ],
    "userGroup": [

    ]
  },
  {
    "id": "4082ffd0-6926-4b97-90a7-45b27b0bbc1f",
    "instanceId": 15124,
    "authEntityId": 15124,
    "displayName": "15124",
    "authEntityClass": -1909073334,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "createTime": 1517966744289,
    "deployed": false,
    "isSeeded": true,
    "isStale": false,
    "lastUpdateTime": 1517966744289,
    "name": "set",
    "namespace": "scalablegroup:application",
    "provisioningState": "DEFINED",
    "qualifier": "application",
    "resourceVersion": 0,
    "targetIdList": [

    ],
    "type": "scalablegroup",
    "cfsChangeInfo": [

    ],
    "customProvisions": [

    ],
    "scalableGroupExternalHandle": "set",
    "scalableGroupType": "APPLICATION",
    "securityGroupTag": 0,
    "state": "ACTIVE",
    "vnAgnostic": true,
    "identitySource": {
      "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
      "instanceId": 10010,
      "authEntityId": 10010,
      "displayName": "10010",
      "authEntityClass": 1738953278,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "state": "INACTIVE",
      "type": "NBAR"
    },
    "indicativeNetworkIdentity": [
      {
        "id": "d61a1bf0-943f-4e53-868f-7c0ca04dcb70",
        "instanceId": 17018,
        "authEntityId": 17018,
        "displayName": "17018",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "257",
        "protocol": "TCP",
        "upperPort": 0
      },
      {
        "id": "bdda8299-8d22-445e-a3fb-a92facffb1b9",
        "instanceId": 17019,
        "authEntityId": 17019,
        "displayName": "17019",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "257",
        "protocol": "UDP",
        "upperPort": 0
      }
    ],
    "networkApplications": [
      {
        "id": "cb71e541-1a2b-4f48-863d-472f45647b2e",
        "instanceId": 16067,
        "authEntityId": 16067,
        "displayName": "16067",
        "authEntityClass": -217092956,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "appProtocol": "tcp/udp",
        "applicationGroup": "other",
        "applicationType": "DEFAULT",
        "categoryId": "6daa3dfd-3526-407a-88f0-97824b3f3a24",
        "createTime": 1517966744289,
        "encrypted": "false",
        "engineId": "3",
        "helpString": "Secure Electronic Transaction",
        "ignoreConflict": false,
        "lastUpdateTime": 1517966744289,
        "longDescription": "Secure Electronic Transaction (SET) was a standard protocol for securing credit card transactions over insecure networks, specifically, the Internet. SET was not itself a payment system, but rather a set of security protocols and formats that enable users to employ the existing credit card payment infrastructure on an open network in a secure fashion.",
        "name": "set",
        "nbarId": "1129",
        "p2pTechnology": "false",
        "popularity": 1,
        "rank": 65535,
        "references": "http://en.wikipedia.org/wiki/Secure_Electronic_Transaction",
        "selectorId": "257",
        "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
        "trafficClass": "BULK_DATA",
        "tunnel": "false"
      }
    ],
    "networkIdentity": [
      {
        "id": "4f6a998e-6bb5-4f4b-b3f4-3b3c43222c8f",
        "instanceId": 15014,
        "authEntityId": 15014,
        "displayName": "15014",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "257",
        "protocol": "UDP",
        "upperPort": 0
      },
      {
        "id": "3d1c793b-77bd-43e0-ad4e-a2d8e8c46b71",
        "instanceId": 17017,
        "authEntityId": 17017,
        "displayName": "17017",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "257",
        "protocol": "TCP",
        "upperPort": 0
      }
    ],
    "parentScalableGroup": {
      "idRef": "000c15bf-28fe-4fbb-9b69-35e6502721f1"
    },
    "user": [

    ],
    "userGroup": [

    ]
  },
  {
    "id": "40ae7520-0e46-43c8-87a6-df04caf10e5e",
    "instanceId": 4063,
    "authEntityId": 4063,
    "displayName": "4063",
    "authEntityClass": -1909073334,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "createTime": 1517966740725,
    "deployed": false,
    "isSeeded": true,
    "isStale": false,
    "lastUpdateTime": 1517966740725,
    "name": "adweek",
    "namespace": "scalablegroup:application",
    "provisioningState": "DEFINED",
    "qualifier": "application",
    "resourceVersion": 0,
    "targetIdList": [

    ],
    "type": "scalablegroup",
    "cfsChangeInfo": [

    ],
    "customProvisions": [

    ],
    "scalableGroupExternalHandle": "adweek",
    "scalableGroupType": "APPLICATION",
    "securityGroupTag": 0,
    "state": "ACTIVE",
    "vnAgnostic": true,
    "identitySource": {
      "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
      "instanceId": 10010,
      "authEntityId": 10010,
      "displayName": "10010",
      "authEntityClass": 1738953278,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "state": "INACTIVE",
      "type": "NBAR"
    },
    "indicativeNetworkIdentity": [

    ],
    "networkApplications": [
      {
        "id": "9f66a11a-9133-4732-babf-684b7db2a336",
        "instanceId": 11033,
        "authEntityId": 11033,
        "displayName": "11033",
        "authEntityClass": -217092956,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "appProtocol": "tcp/udp",
        "applicationGroup": "other",
        "applicationType": "DEFAULT",
        "categoryId": "878644b5-fe91-45b4-9204-31c84a73ba48",
        "createTime": 1517966740725,
        "encrypted": "false",
        "engineId": "13",
        "helpString": "Adweek - Marketing, Media and advertising news.",
        "ignoreConflict": false,
        "lastUpdateTime": 1517966740725,
        "longDescription": "Adweek - Marketing, Media and advertising news.",
        "name": "adweek",
        "nbarId": "1648",
        "p2pTechnology": "false",
        "popularity": 3,
        "rank": 65535,
        "references": "http://adweek.com",
        "selectorId": "718",
        "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
        "trafficClass": "TRANSACTIONAL_DATA",
        "tunnel": "false"
      }
    ],
    "networkIdentity": [
      {
        "id": "d5fe3403-b5b1-49b2-9091-60e646b730ef",
        "instanceId": 12080,
        "authEntityId": 12080,
        "displayName": "12080",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "53,80,443,5353",
        "protocol": "TCP",
        "upperPort": 0
      },
      {
        "id": "9808081e-2abf-4e1b-9952-c352a4046a23",
        "instanceId": 12079,
        "authEntityId": 12079,
        "displayName": "12079",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "53,5353",
        "protocol": "UDP",
        "upperPort": 0
      }
    ],
    "parentScalableGroup": {
      "idRef": "28e08de4-d7b2-4521-84ce-bc1e5ee0b9cc"
    },
    "user": [

    ],
    "userGroup": [

    ]
  },
  {
    "id": "40b3ed13-f016-47af-8398-4636fa08c355",
    "instanceId": 4867,
    "authEntityId": 4867,
    "displayName": "4867",
    "authEntityClass": -1909073334,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "createTime": 1517966743645,
    "deployed": false,
    "isSeeded": true,
    "isStale": false,
    "lastUpdateTime": 1517966743645,
    "name": "nike",
    "namespace": "scalablegroup:application",
    "provisioningState": "DEFINED",
    "qualifier": "application",
    "resourceVersion": 0,
    "targetIdList": [

    ],
    "type": "scalablegroup",
    "cfsChangeInfo": [

    ],
    "customProvisions": [

    ],
    "scalableGroupExternalHandle": "nike",
    "scalableGroupType": "APPLICATION",
    "securityGroupTag": 0,
    "state": "ACTIVE",
    "vnAgnostic": true,
    "identitySource": {
      "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
      "instanceId": 10010,
      "authEntityId": 10010,
      "displayName": "10010",
      "authEntityClass": 1738953278,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "state": "INACTIVE",
      "type": "NBAR"
    },
    "indicativeNetworkIdentity": [

    ],
    "networkApplications": [
      {
        "id": "594ee264-f3e0-4993-859f-6b5d61251af7",
        "instanceId": 11816,
        "authEntityId": 11816,
        "displayName": "11816",
        "authEntityClass": -217092956,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "appProtocol": "tcp/udp",
        "applicationGroup": "other",
        "applicationType": "DEFAULT",
        "categoryId": "878644b5-fe91-45b4-9204-31c84a73ba48",
        "createTime": 1517966743645,
        "encrypted": "false",
        "engineId": "13",
        "helpString": "Nike - Shoe and sports apparel manufacturer.",
        "ignoreConflict": false,
        "lastUpdateTime": 1517966743645,
        "longDescription": "Nike - Shoe and sports apparel manufacturer.",
        "name": "nike",
        "nbarId": "1664",
        "p2pTechnology": "false",
        "popularity": 3,
        "rank": 65535,
        "references": "http://nike.com",
        "selectorId": "734",
        "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
        "trafficClass": "TRANSACTIONAL_DATA",
        "tunnel": "false"
      }
    ],
    "networkIdentity": [
      {
        "id": "cf9976f9-5061-4c5c-9599-6ce3d5bcf3c2",
        "instanceId": 14242,
        "authEntityId": 14242,
        "displayName": "14242",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "53,80,443,5353",
        "protocol": "TCP",
        "upperPort": 0
      },
      {
        "id": "2141db00-2ef3-46c7-b909-e3fc4d55fcee",
        "instanceId": 14243,
        "authEntityId": 14243,
        "displayName": "14243",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "53,5353",
        "protocol": "UDP",
        "upperPort": 0
      }
    ],
    "parentScalableGroup": {
      "idRef": "28e08de4-d7b2-4521-84ce-bc1e5ee0b9cc"
    },
    "user": [

    ],
    "userGroup": [

    ]
  },
  {
    "id": "40bc187d-d16b-46c4-a4e9-3491b202ac9d",
    "instanceId": 15398,
    "authEntityId": 15398,
    "displayName": "15398",
    "authEntityClass": -1909073334,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "createTime": 1517966744872,
    "deployed": false,
    "isSeeded": true,
    "isStale": false,
    "lastUpdateTime": 1517966744872,
    "name": "web-rtc-video",
    "namespace": "scalablegroup:application",
    "provisioningState": "DEFINED",
    "qualifier": "application",
    "resourceVersion": 0,
    "targetIdList": [

    ],
    "type": "scalablegroup",
    "cfsChangeInfo": [

    ],
    "customProvisions": [

    ],
    "scalableGroupExternalHandle": "web-rtc-video",
    "scalableGroupType": "APPLICATION",
    "securityGroupTag": 0,
    "state": "ACTIVE",
    "vnAgnostic": true,
    "identitySource": {
      "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
      "instanceId": 10010,
      "authEntityId": 10010,
      "displayName": "10010",
      "authEntityClass": 1738953278,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "state": "INACTIVE",
      "type": "NBAR"
    },
    "indicativeNetworkIdentity": [

    ],
    "networkApplications": [
      {
        "id": "35582135-3422-4939-a72a-cddc63c84b5e",
        "instanceId": 16341,
        "authEntityId": 16341,
        "displayName": "16341",
        "authEntityClass": -217092956,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "applicationGroup": "other",
        "applicationType": "DEFAULT",
        "categoryId": "38a8e354-e2fe-4f3c-b1c5-2bbb90fb0d99",
        "createTime": 1517966744872,
        "encrypted": "false",
        "engineId": "13",
        "helpString": "web rtc conferencing video",
        "ignoreConflict": false,
        "lastUpdateTime": 1517966744872,
        "longDescription": "WebRTC provides browsers and mobile applications with Real-Time Communications (RTC) capabilities.",
        "name": "web-rtc-video",
        "nbarId": "1057",
        "p2pTechnology": "false",
        "popularity": 10,
        "rank": 65535,
        "references": "www.webrtc.org",
        "selectorId": "1766",
        "subCategory": "53ce0342-1e2d-455a-acc7-1b50db9d6845",
        "trafficClass": "MULTIMEDIA_CONFERENCING",
        "tunnel": "false"
      }
    ],
    "networkIdentity": [

    ],
    "parentScalableGroup": {
      "idRef": "aad510ce-fe95-4dfc-ad06-e494641cc016"
    },
    "user": [

    ],
    "userGroup": [

    ]
  },
  {
    "id": "4165fc62-7f99-49d8-81b4-f2d2cdb460e3",
    "instanceId": 15439,
    "authEntityId": 15439,
    "displayName": "15439",
    "authEntityClass": -1909073334,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "createTime": 1517966744967,
    "deployed": false,
    "isSeeded": true,
    "isStale": false,
    "lastUpdateTime": 1517966744967,
    "name": "xmpp-client",
    "namespace": "scalablegroup:application",
    "provisioningState": "DEFINED",
    "qualifier": "application",
    "resourceVersion": 0,
    "targetIdList": [

    ],
    "type": "scalablegroup",
    "cfsChangeInfo": [

    ],
    "customProvisions": [

    ],
    "scalableGroupExternalHandle": "xmpp-client",
    "scalableGroupType": "APPLICATION",
    "securityGroupTag": 0,
    "state": "ACTIVE",
    "vnAgnostic": true,
    "identitySource": {
      "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
      "instanceId": 10010,
      "authEntityId": 10010,
      "displayName": "10010",
      "authEntityClass": 1738953278,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "state": "INACTIVE",
      "type": "NBAR"
    },
    "indicativeNetworkIdentity": [
      {
        "id": "4fd14e68-96c8-4822-8a9f-76e892c0d064",
        "instanceId": 17901,
        "authEntityId": 17901,
        "displayName": "17901",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "5222",
        "protocol": "TCP",
        "upperPort": 0
      },
      {
        "id": "037d33d5-1d13-4ff3-a531-d27b7747a08e",
        "instanceId": 17902,
        "authEntityId": 17902,
        "displayName": "17902",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "5222",
        "protocol": "UDP",
        "upperPort": 0
      }
    ],
    "networkApplications": [
      {
        "id": "1cfb038d-ccc9-4e23-b4ff-2e829e6b86d3",
        "instanceId": 16382,
        "authEntityId": 16382,
        "displayName": "16382",
        "authEntityClass": -217092956,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "appProtocol": "tcp/udp",
        "applicationGroup": "other",
        "applicationType": "DEFAULT",
        "categoryId": "0f6cab47-be95-4124-9db3-360ccd55f983",
        "createTime": 1517966744967,
        "encrypted": "false",
        "engineId": "3",
        "helpString": "Extensible Messaging and Presence Protocol (XMPP) Client Connection",
        "ignoreConflict": false,
        "lastUpdateTime": 1517966744967,
        "longDescription": "Extensible Messaging and Presence Protocol (XMPP) is an open-standard communications protocol for message-oriented middleware based on XML. The protocol was originally named Jabber, and was developed for near-real-time, extensible instant messaging (IM), presence information, and contact list maintenance. The protocol today is also used in VoIP and file transfer signaling.",
        "name": "xmpp-client",
        "nbarId": "1323",
        "p2pTechnology": "false",
        "popularity": 9,
        "rank": 65535,
        "references": "http://xmpp.org/xmpp-software/clients/",
        "selectorId": "5222",
        "subCategory": "118c10a2-e272-47c1-92b9-4814ebe97138",
        "trafficClass": "TRANSACTIONAL_DATA",
        "tunnel": "false"
      }
    ],
    "networkIdentity": [
      {
        "id": "e10a1a5e-ea5f-4ff0-bed5-63bdf8971a09",
        "instanceId": 17899,
        "authEntityId": 17899,
        "displayName": "17899",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "5222",
        "protocol": "UDP",
        "upperPort": 0
      },
      {
        "id": "2baf7130-c464-4bbe-81ab-194d7c43f46e",
        "instanceId": 17900,
        "authEntityId": 17900,
        "displayName": "17900",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "5222",
        "protocol": "TCP",
        "upperPort": 0
      }
    ],
    "parentScalableGroup": {
      "idRef": "60f1364d-2a02-4063-8ba1-07817abe0612"
    },
    "user": [

    ],
    "userGroup": [

    ]
  },
  {
    "id": "41e2ae30-ed60-4cd5-9047-1ce05bc07435",
    "instanceId": 4378,
    "authEntityId": 4378,
    "displayName": "4378",
    "authEntityClass": -1909073334,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "createTime": 1517966742458,
    "deployed": false,
    "isSeeded": true,
    "isStale": false,
    "lastUpdateTime": 1517966742458,
    "name": "dn6-nlm-aud",
    "namespace": "scalablegroup:application",
    "provisioningState": "DEFINED",
    "qualifier": "application",
    "resourceVersion": 0,
    "targetIdList": [

    ],
    "type": "scalablegroup",
    "cfsChangeInfo": [

    ],
    "customProvisions": [

    ],
    "scalableGroupExternalHandle": "dn6-nlm-aud",
    "scalableGroupType": "APPLICATION",
    "securityGroupTag": 0,
    "state": "ACTIVE",
    "vnAgnostic": true,
    "identitySource": {
      "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
      "instanceId": 10010,
      "authEntityId": 10010,
      "displayName": "10010",
      "authEntityClass": 1738953278,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "state": "INACTIVE",
      "type": "NBAR"
    },
    "indicativeNetworkIdentity": [
      {
        "id": "d5ed7942-d701-4eda-8646-fc02fa9cf5f0",
        "instanceId": 12924,
        "authEntityId": 12924,
        "displayName": "12924",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "195",
        "protocol": "UDP",
        "upperPort": 0
      },
      {
        "id": "e16e3ee8-a3aa-44aa-800b-a765ee5d6f1e",
        "instanceId": 12925,
        "authEntityId": 12925,
        "displayName": "12925",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "195",
        "protocol": "TCP",
        "upperPort": 0
      }
    ],
    "networkApplications": [
      {
        "id": "b4d6c005-81d6-47c1-b1fd-78dcd42dabf2",
        "instanceId": 11329,
        "authEntityId": 11329,
        "displayName": "11329",
        "authEntityClass": -217092956,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "appProtocol": "tcp/udp",
        "applicationGroup": "other",
        "applicationType": "DEFAULT",
        "categoryId": "1fc17362-9334-4c7b-b752-5afdbb574017",
        "createTime": 1517966742457,
        "encrypted": "false",
        "engineId": "3",
        "helpString": "DNSIX Network Level Module Audit",
        "ignoreConflict": false,
        "lastUpdateTime": 1517966742457,
        "longDescription": "Registered with IANA on port 195 TCP/UDP",
        "name": "dn6-nlm-aud",
        "nbarId": "1044",
        "p2pTechnology": "false",
        "popularity": 1,
        "rank": 65535,
        "references": "http://www.iana.org/assignments/service-names-port-numbers/service-names-port-numbers.xml",
        "selectorId": "195",
        "subCategory": "7f5fa07d-fcb4-4217-9908-b438066c0b60",
        "trafficClass": "OPS_ADMIN_MGMT",
        "tunnel": "false"
      }
    ],
    "networkIdentity": [
      {
        "id": "10248ec9-1724-4095-9238-62cfc770fa61",
        "instanceId": 12922,
        "authEntityId": 12922,
        "displayName": "12922",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "195",
        "protocol": "TCP",
        "upperPort": 0
      },
      {
        "id": "1c5c7339-c2c8-4cec-961e-57eec6b71e51",
        "instanceId": 12923,
        "authEntityId": 12923,
        "displayName": "12923",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "195",
        "protocol": "UDP",
        "upperPort": 0
      }
    ],
    "parentScalableGroup": {
      "idRef": "38b9eb6b-f3b6-4e13-9b79-a11e0c6423d4"
    },
    "user": [

    ],
    "userGroup": [

    ]
  },
  {
    "id": "4234aa5f-3450-4534-95da-ec127384df40",
    "instanceId": 15098,
    "authEntityId": 15098,
    "displayName": "15098",
    "authEntityClass": -1909073334,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "createTime": 1517966744229,
    "deployed": false,
    "isSeeded": true,
    "isStale": false,
    "lastUpdateTime": 1517966744229,
    "name": "sco-sysmgr",
    "namespace": "scalablegroup:application",
    "provisioningState": "DEFINED",
    "qualifier": "application",
    "resourceVersion": 0,
    "targetIdList": [

    ],
    "type": "scalablegroup",
    "cfsChangeInfo": [

    ],
    "customProvisions": [

    ],
    "scalableGroupExternalHandle": "sco-sysmgr",
    "scalableGroupType": "APPLICATION",
    "securityGroupTag": 0,
    "state": "ACTIVE",
    "vnAgnostic": true,
    "identitySource": {
      "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
      "instanceId": 10010,
      "authEntityId": 10010,
      "displayName": "10010",
      "authEntityClass": 1738953278,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "state": "INACTIVE",
      "type": "NBAR"
    },
    "indicativeNetworkIdentity": [
      {
        "id": "30d8fabf-4516-4776-8a73-4305ef8e27fc",
        "instanceId": 14930,
        "authEntityId": 14930,
        "displayName": "14930",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "616",
        "protocol": "TCP",
        "upperPort": 0
      },
      {
        "id": "c855d0dc-05ae-4f02-9e75-cb19467c5f74",
        "instanceId": 14931,
        "authEntityId": 14931,
        "displayName": "14931",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "616",
        "protocol": "UDP",
        "upperPort": 0
      }
    ],
    "networkApplications": [
      {
        "id": "c79e25e7-d19f-4df4-b16e-24bfc4dafe10",
        "instanceId": 16041,
        "authEntityId": 16041,
        "displayName": "16041",
        "authEntityClass": -217092956,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "appProtocol": "tcp/udp",
        "applicationGroup": "other",
        "applicationType": "DEFAULT",
        "categoryId": "1fc17362-9334-4c7b-b752-5afdbb574017",
        "createTime": 1517966744229,
        "encrypted": "false",
        "engineId": "3",
        "helpString": "SCO System Administration Server",
        "ignoreConflict": false,
        "lastUpdateTime": 1517966744229,
        "longDescription": "Registered with IANA on port 616 TCP/UDP",
        "name": "sco-sysmgr",
        "nbarId": "525",
        "p2pTechnology": "false",
        "popularity": 1,
        "rank": 65535,
        "references": "http://www.iana.org/assignments/service-names-port-numbers/service-names-port-numbers.xml",
        "selectorId": "616",
        "subCategory": "f3c0f761-d894-4112-ab89-b90fc84fd660",
        "trafficClass": "OPS_ADMIN_MGMT",
        "tunnel": "false"
      }
    ],
    "networkIdentity": [
      {
        "id": "9f2a338b-ba70-4595-be75-450079e14662",
        "instanceId": 14928,
        "authEntityId": 14928,
        "displayName": "14928",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "616",
        "protocol": "TCP",
        "upperPort": 0
      },
      {
        "id": "ea2ded52-9d82-4afe-96bf-f6bfd3fa4d4a",
        "instanceId": 14929,
        "authEntityId": 14929,
        "displayName": "14929",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "616",
        "protocol": "UDP",
        "upperPort": 0
      }
    ],
    "parentScalableGroup": {
      "idRef": "5fcd74d4-8726-47f7-a088-c457863da7ef"
    },
    "user": [

    ],
    "userGroup": [

    ]
  },
  {
    "id": "42409a01-12b7-4c53-9d64-60f82ebe0397",
    "instanceId": 4654,
    "authEntityId": 4654,
    "displayName": "4654",
    "authEntityClass": -1909073334,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "createTime": 1517966743215,
    "deployed": false,
    "isSeeded": true,
    "isStale": false,
    "lastUpdateTime": 1517966743215,
    "name": "itunes",
    "namespace": "scalablegroup:application",
    "provisioningState": "DEFINED",
    "qualifier": "application",
    "resourceVersion": 0,
    "targetIdList": [

    ],
    "type": "scalablegroup",
    "cfsChangeInfo": [

    ],
    "customProvisions": [

    ],
    "scalableGroupExternalHandle": "itunes",
    "scalableGroupType": "APPLICATION",
    "securityGroupTag": 0,
    "state": "ACTIVE",
    "vnAgnostic": true,
    "identitySource": {
      "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
      "instanceId": 10010,
      "authEntityId": 10010,
      "displayName": "10010",
      "authEntityClass": 1738953278,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "state": "INACTIVE",
      "type": "NBAR"
    },
    "indicativeNetworkIdentity": [

    ],
    "networkApplications": [
      {
        "id": "8c269754-3742-473b-97f0-4b0731d41eec",
        "instanceId": 11604,
        "authEntityId": 11604,
        "displayName": "11604",
        "authEntityClass": -217092956,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "appProtocol": "tcp/udp",
        "applicationGroup": "apple-group",
        "applicationType": "DEFAULT",
        "categoryId": "b33b2761-d981-4734-810a-a6a1c18ab352",
        "createTime": 1517966743215,
        "encrypted": "true",
        "engineId": "13",
        "helpString": "Media player and media library application",
        "ignoreConflict": false,
        "lastUpdateTime": 1517966743215,
        "longDescription": "iTunes is a media player and media library application developed by Apple Inc. It is used to play, download, and organize digital audio and video on personal computers running the OS X and Microsoft Windows operating systems.Through the iTunes Store, users can purchase and download music, music videos, television shows, audiobooks, podcasts, movies and etc. in their MAC/ Win PC/ iDevices running iTunes.iTunes refers to all general data generated by iTunes except streaming audio and video",
        "name": "itunes",
        "nbarId": "461",
        "p2pTechnology": "false",
        "popularity": 10,
        "rank": 65535,
        "references": "http://www.apple.com/itunes/",
        "selectorId": "434",
        "subCategory": "98e625d1-8720-4d2b-a186-c821a5d86693",
        "trafficClass": "MULTIMEDIA_STREAMING",
        "tunnel": "false"
      }
    ],
    "networkIdentity": [
      {
        "id": "ed50c520-7ca5-4f5e-b9e8-684f7d70620a",
        "instanceId": 13649,
        "authEntityId": 13649,
        "displayName": "13649",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "53,80,443,5353",
        "protocol": "TCP",
        "upperPort": 0
      },
      {
        "id": "a5af5e49-f8fa-4089-aa4f-ff5a29ac0086",
        "instanceId": 13650,
        "authEntityId": 13650,
        "displayName": "13650",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "53,5353",
        "protocol": "UDP",
        "upperPort": 0
      }
    ],
    "parentScalableGroup": {
      "idRef": "7a1a3bbb-dc84-4a35-82a4-7e650c4c4761"
    },
    "user": [

    ],
    "userGroup": [

    ]
  },
  {
    "id": "425f54d7-112b-4c6f-9d87-573421cc89fb",
    "instanceId": 4901,
    "authEntityId": 4901,
    "displayName": "4901",
    "authEntityClass": -1909073334,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "createTime": 1517966743715,
    "deployed": false,
    "isSeeded": true,
    "isStale": false,
    "lastUpdateTime": 1517966743715,
    "name": "odmr",
    "namespace": "scalablegroup:application",
    "provisioningState": "DEFINED",
    "qualifier": "application",
    "resourceVersion": 0,
    "targetIdList": [

    ],
    "type": "scalablegroup",
    "cfsChangeInfo": [

    ],
    "customProvisions": [

    ],
    "scalableGroupExternalHandle": "odmr",
    "scalableGroupType": "APPLICATION",
    "securityGroupTag": 0,
    "state": "ACTIVE",
    "vnAgnostic": true,
    "identitySource": {
      "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
      "instanceId": 10010,
      "authEntityId": 10010,
      "displayName": "10010",
      "authEntityClass": 1738953278,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "state": "INACTIVE",
      "type": "NBAR"
    },
    "indicativeNetworkIdentity": [
      {
        "id": "6d27e8de-0723-40ac-a20e-4fb105b338b6",
        "instanceId": 14358,
        "authEntityId": 14358,
        "displayName": "14358",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "366",
        "protocol": "TCP",
        "upperPort": 0
      },
      {
        "id": "b202a95d-0d6d-43ce-850a-047c7467daf2",
        "instanceId": 14359,
        "authEntityId": 14359,
        "displayName": "14359",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "366",
        "protocol": "UDP",
        "upperPort": 0
      }
    ],
    "networkApplications": [
      {
        "id": "f0dd5259-52ec-4848-b7d6-ebc39b8dc126",
        "instanceId": 11850,
        "authEntityId": 11850,
        "displayName": "11850",
        "authEntityClass": -217092956,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "appProtocol": "tcp/udp",
        "applicationGroup": "smtp-group",
        "applicationType": "DEFAULT",
        "categoryId": "27953c60-b2ff-4e1a-bc52-0187d8295250",
        "createTime": 1517966743715,
        "encrypted": "false",
        "engineId": "3",
        "helpString": "odmr",
        "ignoreConflict": false,
        "lastUpdateTime": 1517966743715,
        "longDescription": "On-Demand Mail Relay (ODMR) is an SMTP extension that allows e-mail to be relayed once the recipient has been authenticated. It uses the Extended SMTP (ESMTP) command ATRN, similar to the ETRN command but available for dynamically assigned IP addresses.",
        "name": "odmr",
        "nbarId": "282",
        "p2pTechnology": "false",
        "popularity": 1,
        "rank": 65535,
        "references": "http://tools.ietf.org/html/rfc2645",
        "selectorId": "366",
        "subCategory": "b90786f4-0190-45b8-9924-21b3e9682590",
        "trafficClass": "BULK_DATA",
        "tunnel": "false"
      }
    ],
    "networkIdentity": [
      {
        "id": "27c9fb63-b036-47b8-9b30-8368068c623b",
        "instanceId": 14356,
        "authEntityId": 14356,
        "displayName": "14356",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "366",
        "protocol": "TCP",
        "upperPort": 0
      },
      {
        "id": "3bf7b01a-2e33-45bc-bc98-674566771b98",
        "instanceId": 14357,
        "authEntityId": 14357,
        "displayName": "14357",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "366",
        "protocol": "UDP",
        "upperPort": 0
      }
    ],
    "parentScalableGroup": {
      "idRef": "8a7ca43a-32bd-40d2-871a-02220a165cb7"
    },
    "user": [

    ],
    "userGroup": [

    ]
  },
  {
    "id": "428ce438-9b5f-471b-b31b-518a3dec508e",
    "instanceId": 15025,
    "authEntityId": 15025,
    "displayName": "15025",
    "authEntityClass": -1909073334,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "createTime": 1517966744054,
    "deployed": false,
    "isSeeded": true,
    "isStale": false,
    "lastUpdateTime": 1517966744054,
    "name": "radius",
    "namespace": "scalablegroup:application",
    "provisioningState": "DEFINED",
    "qualifier": "application",
    "resourceVersion": 0,
    "targetIdList": [

    ],
    "type": "scalablegroup",
    "cfsChangeInfo": [

    ],
    "customProvisions": [

    ],
    "scalableGroupExternalHandle": "radius",
    "scalableGroupType": "APPLICATION",
    "securityGroupTag": 0,
    "state": "ACTIVE",
    "vnAgnostic": true,
    "identitySource": {
      "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
      "instanceId": 10010,
      "authEntityId": 10010,
      "displayName": "10010",
      "authEntityClass": 1738953278,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "state": "INACTIVE",
      "type": "NBAR"
    },
    "indicativeNetworkIdentity": [
      {
        "id": "10c37476-2149-4e6f-9e08-7d3371f6229a",
        "instanceId": 14702,
        "authEntityId": 14702,
        "displayName": "14702",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "1812,1813",
        "protocol": "UDP",
        "upperPort": 0
      }
    ],
    "networkApplications": [
      {
        "id": "b90f4a5f-6162-4616-bbf9-db817f08d2d2",
        "instanceId": 11964,
        "authEntityId": 11964,
        "displayName": "11964",
        "authEntityClass": -217092956,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "appProtocol": "udp",
        "applicationGroup": "other",
        "applicationType": "DEFAULT",
        "categoryId": "1fc17362-9334-4c7b-b752-5afdbb574017",
        "createTime": 1517966744054,
        "encrypted": "false",
        "engineId": "3",
        "helpString": "Remote Authentication Dial In User Service protocol",
        "ignoreConflict": false,
        "lastUpdateTime": 1517966744054,
        "longDescription": "Remote Authentication Dial In User Service (RADIUS) is a networking protocol that provides centralized Authentication, Authorization and Accounting (AAA) management for computers to connect and use a network service. It is a UDP based protocol.",
        "name": "radius",
        "nbarId": "738",
        "p2pTechnology": "false",
        "popularity": 7,
        "rank": 65535,
        "references": "http://tools.ietf.org/html/rfc2865",
        "selectorId": "1812",
        "subCategory": "e390124a-0fd6-4253-b68e-4b0c1af8cb4b",
        "trafficClass": "OPS_ADMIN_MGMT",
        "tunnel": "false"
      }
    ],
    "networkIdentity": [
      {
        "id": "63ef69b3-d579-4acf-9488-619f2695248a",
        "instanceId": 14701,
        "authEntityId": 14701,
        "displayName": "14701",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "1812,1813",
        "protocol": "UDP",
        "upperPort": 0
      }
    ],
    "parentScalableGroup": {
      "idRef": "7788bf2d-e289-4597-b500-17aacb7e820e"
    },
    "user": [

    ],
    "userGroup": [

    ]
  },
  {
    "id": "42c4cf07-271b-49cc-9746-c202978e4b45",
    "instanceId": 15068,
    "authEntityId": 15068,
    "displayName": "15068",
    "authEntityClass": -1909073334,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "createTime": 1517966744158,
    "deployed": false,
    "isSeeded": true,
    "isStale": false,
    "lastUpdateTime": 1517966744158,
    "name": "rsvp-encap-2",
    "namespace": "scalablegroup:application",
    "provisioningState": "DEFINED",
    "qualifier": "application",
    "resourceVersion": 0,
    "targetIdList": [

    ],
    "type": "scalablegroup",
    "cfsChangeInfo": [

    ],
    "customProvisions": [

    ],
    "scalableGroupExternalHandle": "rsvp-encap-2",
    "scalableGroupType": "APPLICATION",
    "securityGroupTag": 0,
    "state": "ACTIVE",
    "vnAgnostic": true,
    "identitySource": {
      "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
      "instanceId": 10010,
      "authEntityId": 10010,
      "displayName": "10010",
      "authEntityClass": 1738953278,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "state": "INACTIVE",
      "type": "NBAR"
    },
    "indicativeNetworkIdentity": [
      {
        "id": "0e282efe-b1fc-4dd8-8ed0-3454cc9a03ec",
        "instanceId": 14848,
        "authEntityId": 14848,
        "displayName": "14848",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "1699",
        "protocol": "UDP",
        "upperPort": 0
      },
      {
        "id": "d40762e5-c54e-436b-ab71-ffc8abe845de",
        "instanceId": 14847,
        "authEntityId": 14847,
        "displayName": "14847",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "1699",
        "protocol": "TCP",
        "upperPort": 0
      }
    ],
    "networkApplications": [
      {
        "id": "4f157482-a3af-4226-9bd0-7675ea9270e4",
        "instanceId": 12007,
        "authEntityId": 12007,
        "displayName": "12007",
        "authEntityClass": -217092956,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "appProtocol": "tcp/udp",
        "applicationGroup": "other",
        "applicationType": "DEFAULT",
        "categoryId": "1fc17362-9334-4c7b-b752-5afdbb574017",
        "createTime": 1517966744158,
        "encrypted": "false",
        "engineId": "3",
        "helpString": "Resource Reservation Protocol",
        "ignoreConflict": false,
        "lastUpdateTime": 1517966744158,
        "longDescription": "Resource Reservation Protocol (RSVP) is a Transport Layer protocol designed to reserve resources across a network for an integrated services internet. RSVP operates over an IPv4 or IPv6 internet Layer and provides receiver-initiated setup of resource reservations for multicast or unicast data flows with scaling and robustness. It does not transport application data but is similar to a control protocol, like ICMP or IGMP.",
        "name": "rsvp-encap-2",
        "nbarId": "1424",
        "p2pTechnology": "false",
        "popularity": 5,
        "rank": 65535,
        "references": "http://www.ietf.org/rfc/rfc2205.txt",
        "selectorId": "1699",
        "subCategory": "f3c0f761-d894-4112-ab89-b90fc84fd660",
        "trafficClass": "OPS_ADMIN_MGMT",
        "tunnel": "false"
      }
    ],
    "networkIdentity": [
      {
        "id": "a0a7369a-555b-4157-8bd5-425f7a485e9c",
        "instanceId": 14845,
        "authEntityId": 14845,
        "displayName": "14845",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "1699",
        "protocol": "TCP",
        "upperPort": 0
      },
      {
        "id": "9b36f917-0a95-4728-aac9-02cd9d562ecf",
        "instanceId": 14846,
        "authEntityId": 14846,
        "displayName": "14846",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "1699",
        "protocol": "UDP",
        "upperPort": 0
      }
    ],
    "parentScalableGroup": {
      "idRef": "5fcd74d4-8726-47f7-a088-c457863da7ef"
    },
    "user": [

    ],
    "userGroup": [

    ]
  },
  {
    "id": "4337d5eb-94c3-4e7c-af4f-28fa36cf6e79",
    "instanceId": 4845,
    "authEntityId": 4845,
    "displayName": "4845",
    "authEntityClass": -1909073334,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "createTime": 1517966743603,
    "deployed": false,
    "isSeeded": true,
    "isStale": false,
    "lastUpdateTime": 1517966743603,
    "name": "netrjs-1",
    "namespace": "scalablegroup:application",
    "provisioningState": "DEFINED",
    "qualifier": "application",
    "resourceVersion": 0,
    "targetIdList": [

    ],
    "type": "scalablegroup",
    "cfsChangeInfo": [

    ],
    "customProvisions": [

    ],
    "scalableGroupExternalHandle": "netrjs-1",
    "scalableGroupType": "APPLICATION",
    "securityGroupTag": 0,
    "state": "ACTIVE",
    "vnAgnostic": true,
    "identitySource": {
      "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
      "instanceId": 10010,
      "authEntityId": 10010,
      "displayName": "10010",
      "authEntityClass": 1738953278,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "state": "INACTIVE",
      "type": "NBAR"
    },
    "indicativeNetworkIdentity": [
      {
        "id": "90607aa9-1018-4cb9-8d53-1f4309a29323",
        "instanceId": 14166,
        "authEntityId": 14166,
        "displayName": "14166",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "71",
        "protocol": "TCP",
        "upperPort": 0
      },
      {
        "id": "f4c354ce-22d2-48d3-998f-877447a45ad5",
        "instanceId": 14167,
        "authEntityId": 14167,
        "displayName": "14167",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "71",
        "protocol": "UDP",
        "upperPort": 0
      }
    ],
    "networkApplications": [
      {
        "id": "714c3e3a-6e29-4c1e-ba44-28b2e40aa6fa",
        "instanceId": 11794,
        "authEntityId": 11794,
        "displayName": "11794",
        "authEntityClass": -217092956,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "appProtocol": "tcp/udp",
        "applicationGroup": "other",
        "applicationType": "DEFAULT",
        "categoryId": "1fc17362-9334-4c7b-b752-5afdbb574017",
        "createTime": 1517966743603,
        "encrypted": "false",
        "engineId": "3",
        "helpString": "Remote Job Service",
        "ignoreConflict": false,
        "lastUpdateTime": 1517966743603,
        "longDescription": "NETRJS is a private protocol for remote job entry service, was defined and implemented by the UCLA Campus Computing Network (CCN). CCN's NETRJS server allows a remote user, or a daemon process working in behalf of a user, to access CCN's Remote Job Service (RJS) subsystem.",
        "name": "netrjs-1",
        "nbarId": "948",
        "p2pTechnology": "false",
        "popularity": 1,
        "rank": 65535,
        "references": "http://tools.ietf.org/html/rfc740",
        "selectorId": "71",
        "subCategory": "f3c0f761-d894-4112-ab89-b90fc84fd660",
        "trafficClass": "OPS_ADMIN_MGMT",
        "tunnel": "false"
      }
    ],
    "networkIdentity": [
      {
        "id": "56306181-a93c-4f1d-a5ad-ac874173cbf6",
        "instanceId": 14164,
        "authEntityId": 14164,
        "displayName": "14164",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "71",
        "protocol": "UDP",
        "upperPort": 0
      },
      {
        "id": "79b4750b-c6ba-4384-950a-751ccf97be8a",
        "instanceId": 14165,
        "authEntityId": 14165,
        "displayName": "14165",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "71",
        "protocol": "TCP",
        "upperPort": 0
      }
    ],
    "parentScalableGroup": {
      "idRef": "5fcd74d4-8726-47f7-a088-c457863da7ef"
    },
    "user": [

    ],
    "userGroup": [

    ]
  },
  {
    "id": "4368a8ec-0048-4490-a5d7-601d9b7dcb6d",
    "instanceId": 15373,
    "authEntityId": 15373,
    "displayName": "15373",
    "authEntityClass": -1909073334,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "createTime": 1517966744829,
    "deployed": false,
    "isSeeded": true,
    "isStale": false,
    "lastUpdateTime": 1517966744829,
    "name": "wall-street-journal",
    "namespace": "scalablegroup:application",
    "provisioningState": "DEFINED",
    "qualifier": "application",
    "resourceVersion": 0,
    "targetIdList": [

    ],
    "type": "scalablegroup",
    "cfsChangeInfo": [

    ],
    "customProvisions": [

    ],
    "scalableGroupExternalHandle": "wall-street-journal",
    "scalableGroupType": "APPLICATION",
    "securityGroupTag": 0,
    "state": "ACTIVE",
    "vnAgnostic": true,
    "identitySource": {
      "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
      "instanceId": 10010,
      "authEntityId": 10010,
      "displayName": "10010",
      "authEntityClass": 1738953278,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "state": "INACTIVE",
      "type": "NBAR"
    },
    "indicativeNetworkIdentity": [

    ],
    "networkApplications": [
      {
        "id": "56b02b97-2a57-44ab-b463-ed324158e836",
        "instanceId": 16316,
        "authEntityId": 16316,
        "displayName": "16316",
        "authEntityClass": -217092956,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "appProtocol": "tcp/udp",
        "applicationGroup": "other",
        "applicationType": "DEFAULT",
        "categoryId": "878644b5-fe91-45b4-9204-31c84a73ba48",
        "createTime": 1517966744829,
        "encrypted": "false",
        "engineId": "13",
        "helpString": "Wall Street Journal - Web Portal for news update.",
        "ignoreConflict": false,
        "lastUpdateTime": 1517966744829,
        "longDescription": "Wall Street Journal - Web Portal for news update.",
        "name": "wall-street-journal",
        "nbarId": "1598",
        "p2pTechnology": "false",
        "popularity": 3,
        "rank": 65535,
        "references": "http://wsj.com",
        "selectorId": "669",
        "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
        "trafficClass": "TRANSACTIONAL_DATA",
        "tunnel": "false"
      }
    ],
    "networkIdentity": [
      {
        "id": "8c7e291b-6f5a-4905-a08a-32709f7fb7aa",
        "instanceId": 17735,
        "authEntityId": 17735,
        "displayName": "17735",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "53,80,443,5353",
        "protocol": "TCP",
        "upperPort": 0
      },
      {
        "id": "39a458e9-0562-42f2-bd2a-6487d1b8c956",
        "instanceId": 17736,
        "authEntityId": 17736,
        "displayName": "17736",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "53,5353",
        "protocol": "UDP",
        "upperPort": 0
      }
    ],
    "parentScalableGroup": {
      "idRef": "28e08de4-d7b2-4521-84ce-bc1e5ee0b9cc"
    },
    "user": [

    ],
    "userGroup": [

    ]
  },
  {
    "id": "43e47bc4-6b6d-4ecf-ada8-db2852c00062",
    "instanceId": 4660,
    "authEntityId": 4660,
    "displayName": "4660",
    "authEntityClass": -1909073334,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "createTime": 1517966743227,
    "deployed": false,
    "isSeeded": true,
    "isStale": false,
    "lastUpdateTime": 1517966743227,
    "name": "jabber-video",
    "namespace": "scalablegroup:application",
    "provisioningState": "DEFINED",
    "qualifier": "application",
    "resourceVersion": 0,
    "targetIdList": [

    ],
    "type": "scalablegroup",
    "cfsChangeInfo": [

    ],
    "customProvisions": [

    ],
    "scalableGroupExternalHandle": "jabber-video",
    "scalableGroupType": "APPLICATION",
    "securityGroupTag": 0,
    "state": "ACTIVE",
    "vnAgnostic": true,
    "identitySource": {
      "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
      "instanceId": 10010,
      "authEntityId": 10010,
      "displayName": "10010",
      "authEntityClass": 1738953278,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "state": "INACTIVE",
      "type": "NBAR"
    },
    "indicativeNetworkIdentity": [

    ],
    "networkApplications": [
      {
        "id": "47792d26-5e02-4e8c-99bc-4157986e8637",
        "instanceId": 11610,
        "authEntityId": 11610,
        "displayName": "11610",
        "authEntityClass": -217092956,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "appProtocol": "tcp/udp",
        "applicationGroup": "other",
        "applicationType": "DEFAULT",
        "categoryId": "38a8e354-e2fe-4f3c-b1c5-2bbb90fb0d99",
        "createTime": 1517966743227,
        "encrypted": "false",
        "engineId": "13",
        "helpString": "Communications protocol for message-oriented middleware based on XML",
        "ignoreConflict": false,
        "lastUpdateTime": 1517966743227,
        "longDescription": "Communications protocol for message-oriented middleware based on XML (Extensible Markup Language). It enables the near-real-time exchange of structured yet extensible data between any two or more network entities.",
        "name": "jabber-video",
        "nbarId": "1046",
        "p2pTechnology": "false",
        "popularity": 10,
        "rank": 65535,
        "references": "http://www.jabber.org/",
        "selectorId": "1046",
        "subCategory": "05178016-4555-4291-ab4e-43c2ab2a931a",
        "trafficClass": "MULTIMEDIA_CONFERENCING",
        "tunnel": "false"
      }
    ],
    "networkIdentity": [
      {
        "id": "aa68b9bc-0183-473a-968c-e54b1c514050",
        "instanceId": 13656,
        "authEntityId": 13656,
        "displayName": "13656",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "5222",
        "protocol": "TCP",
        "upperPort": 0
      },
      {
        "id": "c3273c30-b8ba-4e3b-bea5-babdff4b8286",
        "instanceId": 13657,
        "authEntityId": 13657,
        "displayName": "13657",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "5222",
        "protocol": "UDP",
        "upperPort": 0
      }
    ],
    "parentScalableGroup": {
      "idRef": "60f1364d-2a02-4063-8ba1-07817abe0612"
    },
    "user": [

    ],
    "userGroup": [

    ]
  },
  {
    "id": "43f35010-ee26-47df-85e9-ee025ab585a3",
    "instanceId": 4937,
    "authEntityId": 4937,
    "displayName": "4937",
    "authEntityClass": -1909073334,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "createTime": 1517966743849,
    "deployed": false,
    "isSeeded": true,
    "isStale": false,
    "lastUpdateTime": 1517966743849,
    "name": "passgo-tivoli",
    "namespace": "scalablegroup:application",
    "provisioningState": "DEFINED",
    "qualifier": "application",
    "resourceVersion": 0,
    "targetIdList": [

    ],
    "type": "scalablegroup",
    "cfsChangeInfo": [

    ],
    "customProvisions": [

    ],
    "scalableGroupExternalHandle": "passgo-tivoli",
    "scalableGroupType": "APPLICATION",
    "securityGroupTag": 0,
    "state": "ACTIVE",
    "vnAgnostic": true,
    "identitySource": {
      "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
      "instanceId": 10010,
      "authEntityId": 10010,
      "displayName": "10010",
      "authEntityClass": 1738953278,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "state": "INACTIVE",
      "type": "NBAR"
    },
    "indicativeNetworkIdentity": [
      {
        "id": "ab8e0fb0-4e39-41d7-90ea-4f6f18c84c8b",
        "instanceId": 14477,
        "authEntityId": 14477,
        "displayName": "14477",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "627",
        "protocol": "TCP",
        "upperPort": 0
      },
      {
        "id": "789f68e6-786f-4581-937c-1c365ccfcf6d",
        "instanceId": 14478,
        "authEntityId": 14478,
        "displayName": "14478",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "627",
        "protocol": "UDP",
        "upperPort": 0
      }
    ],
    "networkApplications": [
      {
        "id": "d5903aaa-0048-4ed7-ae60-872a2672935e",
        "instanceId": 11886,
        "authEntityId": 11886,
        "displayName": "11886",
        "authEntityClass": -217092956,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "appProtocol": "tcp/udp",
        "applicationGroup": "other",
        "applicationType": "DEFAULT",
        "categoryId": "1fc17362-9334-4c7b-b752-5afdbb574017",
        "createTime": 1517966743849,
        "encrypted": "false",
        "engineId": "3",
        "helpString": "passgo-tivoli",
        "ignoreConflict": false,
        "lastUpdateTime": 1517966743849,
        "longDescription": "PassGo Technologies Service is a service used by PassGo. PassGo is a product manufactured by PassGo Technologies, a company that developed software for web access management, privilege management and one-time password token products. In 2008 they were aquired by Quest Software Inc.",
        "name": "passgo-tivoli",
        "nbarId": "536",
        "p2pTechnology": "false",
        "popularity": 1,
        "rank": 65535,
        "references": "http://www.quest.com/",
        "selectorId": "627",
        "subCategory": "f3c0f761-d894-4112-ab89-b90fc84fd660",
        "trafficClass": "OPS_ADMIN_MGMT",
        "tunnel": "false"
      }
    ],
    "networkIdentity": [
      {
        "id": "96b33950-1b2c-414b-8767-a00806b8c639",
        "instanceId": 14475,
        "authEntityId": 14475,
        "displayName": "14475",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "627",
        "protocol": "UDP",
        "upperPort": 0
      },
      {
        "id": "3b3c63bb-5e71-4e44-8273-f80e83e75bc9",
        "instanceId": 14476,
        "authEntityId": 14476,
        "displayName": "14476",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "627",
        "protocol": "TCP",
        "upperPort": 0
      }
    ],
    "parentScalableGroup": {
      "idRef": "5fcd74d4-8726-47f7-a088-c457863da7ef"
    },
    "user": [

    ],
    "userGroup": [

    ]
  },
  {
    "id": "447168f4-3d7f-43a2-b85c-94b8dc5e1e53",
    "instanceId": 15324,
    "authEntityId": 15324,
    "displayName": "15324",
    "authEntityClass": -1909073334,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "createTime": 1517966744740,
    "deployed": false,
    "isSeeded": true,
    "isStale": false,
    "lastUpdateTime": 1517966744740,
    "name": "unite-airlines",
    "namespace": "scalablegroup:application",
    "provisioningState": "DEFINED",
    "qualifier": "application",
    "resourceVersion": 0,
    "targetIdList": [

    ],
    "type": "scalablegroup",
    "cfsChangeInfo": [

    ],
    "customProvisions": [

    ],
    "scalableGroupExternalHandle": "unite-airlines",
    "scalableGroupType": "APPLICATION",
    "securityGroupTag": 0,
    "state": "ACTIVE",
    "vnAgnostic": true,
    "identitySource": {
      "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
      "instanceId": 10010,
      "authEntityId": 10010,
      "displayName": "10010",
      "authEntityClass": 1738953278,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "state": "INACTIVE",
      "type": "NBAR"
    },
    "indicativeNetworkIdentity": [

    ],
    "networkApplications": [
      {
        "id": "71c65122-58bb-4155-b901-c9be55880851",
        "instanceId": 16267,
        "authEntityId": 16267,
        "displayName": "16267",
        "authEntityClass": -217092956,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "appProtocol": "tcp/udp",
        "applicationGroup": "other",
        "applicationType": "DEFAULT",
        "categoryId": "878644b5-fe91-45b4-9204-31c84a73ba48",
        "createTime": 1517966744740,
        "encrypted": "false",
        "engineId": "13",
        "helpString": "United Airlines - Online Flight reservation from United Airlines.",
        "ignoreConflict": false,
        "lastUpdateTime": 1517966744740,
        "longDescription": "United Airlines - Online Flight reservation from United Airlines.",
        "name": "unite-airlines",
        "nbarId": "1683",
        "p2pTechnology": "false",
        "popularity": 3,
        "rank": 65535,
        "references": "http://united.com",
        "selectorId": "753",
        "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
        "trafficClass": "TRANSACTIONAL_DATA",
        "tunnel": "false"
      }
    ],
    "networkIdentity": [
      {
        "id": "8e53f830-f318-4def-9f17-81e096d4ffbf",
        "instanceId": 17593,
        "authEntityId": 17593,
        "displayName": "17593",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "53,80,443,5353",
        "protocol": "TCP",
        "upperPort": 0
      },
      {
        "id": "315c6fdc-e7f8-4117-9bfd-89db0c3930b7",
        "instanceId": 17594,
        "authEntityId": 17594,
        "displayName": "17594",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "53,5353",
        "protocol": "UDP",
        "upperPort": 0
      }
    ],
    "parentScalableGroup": {
      "idRef": "28e08de4-d7b2-4521-84ce-bc1e5ee0b9cc"
    },
    "user": [

    ],
    "userGroup": [

    ]
  },
  {
    "id": "447713ad-9ee8-4857-a561-c8af169a0049",
    "instanceId": 4527,
    "authEntityId": 4527,
    "displayName": "4527",
    "authEntityClass": -1909073334,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "createTime": 1517966742895,
    "deployed": false,
    "isSeeded": true,
    "isStale": false,
    "lastUpdateTime": 1517966742895,
    "name": "hangouts-audio",
    "namespace": "scalablegroup:application",
    "provisioningState": "DEFINED",
    "qualifier": "application",
    "resourceVersion": 0,
    "targetIdList": [

    ],
    "type": "scalablegroup",
    "cfsChangeInfo": [

    ],
    "customProvisions": [

    ],
    "scalableGroupExternalHandle": "hangouts-audio",
    "scalableGroupType": "APPLICATION",
    "securityGroupTag": 0,
    "state": "ACTIVE",
    "vnAgnostic": true,
    "identitySource": {
      "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
      "instanceId": 10010,
      "authEntityId": 10010,
      "displayName": "10010",
      "authEntityClass": 1738953278,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "state": "INACTIVE",
      "type": "NBAR"
    },
    "indicativeNetworkIdentity": [

    ],
    "networkApplications": [
      {
        "id": "121827c0-031a-4575-afb8-5b8808990755",
        "instanceId": 11478,
        "authEntityId": 11478,
        "displayName": "11478",
        "authEntityClass": -217092956,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "applicationGroup": "hangouts-group",
        "applicationType": "DEFAULT",
        "categoryId": "82a7b7fa-da5a-48f5-8b6a-a2546b947843",
        "createTime": 1517966742895,
        "encrypted": "true",
        "engineId": "13",
        "helpString": "Google Hangouts audio",
        "ignoreConflict": false,
        "lastUpdateTime": 1517966742895,
        "longDescription": "Google Hangouts audio",
        "name": "hangouts-audio",
        "nbarId": "1090",
        "p2pTechnology": "true",
        "popularity": 10,
        "rank": 65535,
        "references": "https://hangouts.google.com/",
        "selectorId": "1090",
        "subCategory": "118c10a2-e272-47c1-92b9-4814ebe97138",
        "trafficClass": "VOIP_TELEPHONY",
        "tunnel": "false"
      }
    ],
    "networkIdentity": [

    ],
    "parentScalableGroup": {
      "idRef": "fd6264c9-c16a-4311-a335-a3db039256f3"
    },
    "user": [

    ],
    "userGroup": [

    ]
  },
  {
    "id": "449fd2be-40bf-44f2-985f-57cff57a005e",
    "instanceId": 15063,
    "authEntityId": 15063,
    "displayName": "15063",
    "authEntityClass": -1909073334,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "createTime": 1517966744147,
    "deployed": false,
    "isSeeded": true,
    "isStale": false,
    "lastUpdateTime": 1517966744147,
    "name": "rsh-spx",
    "namespace": "scalablegroup:application",
    "provisioningState": "DEFINED",
    "qualifier": "application",
    "resourceVersion": 0,
    "targetIdList": [

    ],
    "type": "scalablegroup",
    "cfsChangeInfo": [

    ],
    "customProvisions": [

    ],
    "scalableGroupExternalHandle": "rsh-spx",
    "scalableGroupType": "APPLICATION",
    "securityGroupTag": 0,
    "state": "ACTIVE",
    "vnAgnostic": true,
    "identitySource": {
      "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
      "instanceId": 10010,
      "authEntityId": 10010,
      "displayName": "10010",
      "authEntityClass": 1738953278,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "state": "INACTIVE",
      "type": "NBAR"
    },
    "indicativeNetworkIdentity": [
      {
        "id": "5e0648bb-beeb-4f76-95bc-1659090f62e1",
        "instanceId": 14832,
        "authEntityId": 14832,
        "displayName": "14832",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "222",
        "protocol": "UDP",
        "upperPort": 0
      },
      {
        "id": "52d05b8a-6ff1-4bcc-8390-48f96c1a19ef",
        "instanceId": 14831,
        "authEntityId": 14831,
        "displayName": "14831",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "222",
        "protocol": "TCP",
        "upperPort": 0
      }
    ],
    "networkApplications": [
      {
        "id": "1d422a60-4045-4cac-88b2-6697d4b4d87e",
        "instanceId": 12002,
        "authEntityId": 12002,
        "displayName": "12002",
        "authEntityClass": -217092956,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "appProtocol": "tcp/udp",
        "applicationGroup": "other",
        "applicationType": "DEFAULT",
        "categoryId": "1fc17362-9334-4c7b-b752-5afdbb574017",
        "createTime": 1517966744147,
        "encrypted": "false",
        "engineId": "3",
        "helpString": "Berkeley rshd with SPX auth",
        "ignoreConflict": false,
        "lastUpdateTime": 1517966744147,
        "longDescription": "RSH-SPX is an implementation of RSH (Remote Shell) over an IPX/SPX network.",
        "name": "rsh-spx",
        "nbarId": "1119",
        "p2pTechnology": "false",
        "popularity": 1,
        "rank": 65535,
        "references": "http://rshd.sourceforge.net/",
        "selectorId": "222",
        "subCategory": "787a7d77-977b-4974-89d3-379ba49e88b7",
        "trafficClass": "OPS_ADMIN_MGMT",
        "tunnel": "false"
      }
    ],
    "networkIdentity": [
      {
        "id": "d29dd85b-e9e6-4caa-9dd1-d2b6ad017111",
        "instanceId": 14829,
        "authEntityId": 14829,
        "displayName": "14829",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "222",
        "protocol": "UDP",
        "upperPort": 0
      },
      {
        "id": "de362956-e2ff-4575-aa83-6db4030f20e9",
        "instanceId": 14830,
        "authEntityId": 14830,
        "displayName": "14830",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "222",
        "protocol": "TCP",
        "upperPort": 0
      }
    ],
    "parentScalableGroup": {
      "idRef": "e06dd89f-0a70-4422-ab0b-c2314650a9a7"
    },
    "user": [

    ],
    "userGroup": [

    ]
  },
  {
    "id": "44d68a69-eb60-4c48-98c7-a2d9516cb5b2",
    "instanceId": 4477,
    "authEntityId": 4477,
    "displayName": "4477",
    "authEntityClass": -1909073334,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "createTime": 1517966742768,
    "deployed": false,
    "isSeeded": true,
    "isStale": false,
    "lastUpdateTime": 1517966742768,
    "name": "genie",
    "namespace": "scalablegroup:application",
    "provisioningState": "DEFINED",
    "qualifier": "application",
    "resourceVersion": 0,
    "targetIdList": [

    ],
    "type": "scalablegroup",
    "cfsChangeInfo": [

    ],
    "customProvisions": [

    ],
    "scalableGroupExternalHandle": "genie",
    "scalableGroupType": "APPLICATION",
    "securityGroupTag": 0,
    "state": "ACTIVE",
    "vnAgnostic": true,
    "identitySource": {
      "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
      "instanceId": 10010,
      "authEntityId": 10010,
      "displayName": "10010",
      "authEntityClass": 1738953278,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "state": "INACTIVE",
      "type": "NBAR"
    },
    "indicativeNetworkIdentity": [
      {
        "id": "b8751bde-2c62-4c66-b8d8-4e5bf2ac7446",
        "instanceId": 13191,
        "authEntityId": 13191,
        "displayName": "13191",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "402",
        "protocol": "UDP",
        "upperPort": 0
      },
      {
        "id": "719be0b1-f9b5-4ee9-a879-5e3b8c677aa8",
        "instanceId": 13192,
        "authEntityId": 13192,
        "displayName": "13192",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "402",
        "protocol": "TCP",
        "upperPort": 0
      }
    ],
    "networkApplications": [
      {
        "id": "3988baca-af5d-46bd-8dea-d806a712173d",
        "instanceId": 11428,
        "authEntityId": 11428,
        "displayName": "11428",
        "authEntityClass": -217092956,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "appProtocol": "tcp/udp",
        "applicationGroup": "other",
        "applicationType": "DEFAULT",
        "categoryId": "6daa3dfd-3526-407a-88f0-97824b3f3a24",
        "createTime": 1517966742768,
        "encrypted": "false",
        "engineId": "3",
        "helpString": "Genie Protocol",
        "ignoreConflict": false,
        "lastUpdateTime": 1517966742768,
        "longDescription": "Registered with IANA on port 402 TCP/UDP",
        "name": "genie",
        "nbarId": "317",
        "p2pTechnology": "false",
        "popularity": 1,
        "rank": 65535,
        "references": "http://www.iana.org/assignments/service-names-port-numbers/service-names-port-numbers.xml",
        "selectorId": "402",
        "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
        "trafficClass": "BULK_DATA",
        "tunnel": "false"
      }
    ],
    "networkIdentity": [
      {
        "id": "56af3208-fa34-4112-a89d-352bdeff1581",
        "instanceId": 13189,
        "authEntityId": 13189,
        "displayName": "13189",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "402",
        "protocol": "UDP",
        "upperPort": 0
      },
      {
        "id": "9cd4d870-e25b-41f8-8ae9-88620aef009b",
        "instanceId": 13190,
        "authEntityId": 13190,
        "displayName": "13190",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "402",
        "protocol": "TCP",
        "upperPort": 0
      }
    ],
    "parentScalableGroup": {
      "idRef": "000c15bf-28fe-4fbb-9b69-35e6502721f1"
    },
    "user": [

    ],
    "userGroup": [

    ]
  },
  {
    "id": "452fc97a-e53e-4ec1-a412-336cb02cdc1e",
    "instanceId": 4533,
    "authEntityId": 4533,
    "displayName": "4533",
    "authEntityClass": -1909073334,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "createTime": 1517966742920,
    "deployed": false,
    "isSeeded": true,
    "isStale": false,
    "lastUpdateTime": 1517966742920,
    "name": "hassle",
    "namespace": "scalablegroup:application",
    "provisioningState": "DEFINED",
    "qualifier": "application",
    "resourceVersion": 0,
    "targetIdList": [

    ],
    "type": "scalablegroup",
    "cfsChangeInfo": [

    ],
    "customProvisions": [

    ],
    "scalableGroupExternalHandle": "hassle",
    "scalableGroupType": "APPLICATION",
    "securityGroupTag": 0,
    "state": "ACTIVE",
    "vnAgnostic": true,
    "identitySource": {
      "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
      "instanceId": 10010,
      "authEntityId": 10010,
      "displayName": "10010",
      "authEntityClass": 1738953278,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "state": "INACTIVE",
      "type": "NBAR"
    },
    "indicativeNetworkIdentity": [
      {
        "id": "d3da5918-0762-49ae-a8d4-d035d436d619",
        "instanceId": 13319,
        "authEntityId": 13319,
        "displayName": "13319",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "375",
        "protocol": "UDP",
        "upperPort": 0
      },
      {
        "id": "187aafb7-c399-47a2-b89e-d7d05f198991",
        "instanceId": 13320,
        "authEntityId": 13320,
        "displayName": "13320",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "375",
        "protocol": "TCP",
        "upperPort": 0
      }
    ],
    "networkApplications": [
      {
        "id": "d67e15e7-41fb-4292-b823-024e7b8efde9",
        "instanceId": 11484,
        "authEntityId": 11484,
        "displayName": "11484",
        "authEntityClass": -217092956,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "appProtocol": "tcp/udp",
        "applicationGroup": "other",
        "applicationType": "DEFAULT",
        "categoryId": "1fc17362-9334-4c7b-b752-5afdbb574017",
        "createTime": 1517966742920,
        "encrypted": "false",
        "engineId": "3",
        "helpString": "hassle",
        "ignoreConflict": false,
        "lastUpdateTime": 1517966742920,
        "longDescription": "Registered with IANA on port 375 TCP/UDP",
        "name": "hassle",
        "nbarId": "291",
        "p2pTechnology": "false",
        "popularity": 1,
        "rank": 65535,
        "references": "http://www.iana.org/assignments/service-names-port-numbers/service-names-port-numbers.xml",
        "selectorId": "375",
        "subCategory": "787a7d77-977b-4974-89d3-379ba49e88b7",
        "trafficClass": "OPS_ADMIN_MGMT",
        "tunnel": "false"
      }
    ],
    "networkIdentity": [
      {
        "id": "1e04908d-8eba-4034-9894-ffaaaa5f33fc",
        "instanceId": 13317,
        "authEntityId": 13317,
        "displayName": "13317",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "375",
        "protocol": "TCP",
        "upperPort": 0
      },
      {
        "id": "d27071fa-445c-48eb-902d-f8e91e8ea626",
        "instanceId": 13318,
        "authEntityId": 13318,
        "displayName": "13318",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "375",
        "protocol": "UDP",
        "upperPort": 0
      }
    ],
    "parentScalableGroup": {
      "idRef": "e06dd89f-0a70-4422-ab0b-c2314650a9a7"
    },
    "user": [

    ],
    "userGroup": [

    ]
  },
  {
    "id": "4559ea51-664e-447c-9320-c8035c43dc6d",
    "instanceId": 4556,
    "authEntityId": 4556,
    "displayName": "4556",
    "authEntityClass": -1909073334,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "createTime": 1517966742981,
    "deployed": false,
    "isSeeded": true,
    "isStale": false,
    "lastUpdateTime": 1517966742981,
    "name": "htc-services",
    "namespace": "scalablegroup:application",
    "provisioningState": "DEFINED",
    "qualifier": "application",
    "resourceVersion": 0,
    "targetIdList": [

    ],
    "type": "scalablegroup",
    "cfsChangeInfo": [

    ],
    "customProvisions": [

    ],
    "scalableGroupExternalHandle": "htc-services",
    "scalableGroupType": "APPLICATION",
    "securityGroupTag": 0,
    "state": "ACTIVE",
    "vnAgnostic": true,
    "identitySource": {
      "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
      "instanceId": 10010,
      "authEntityId": 10010,
      "displayName": "10010",
      "authEntityClass": 1738953278,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "state": "INACTIVE",
      "type": "NBAR"
    },
    "indicativeNetworkIdentity": [

    ],
    "networkApplications": [
      {
        "id": "01b2f0c7-7ec2-43b1-8ff6-8c4e18982663",
        "instanceId": 11507,
        "authEntityId": 11507,
        "displayName": "11507",
        "authEntityClass": -217092956,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "appProtocol": "tcp/udp",
        "applicationGroup": "other",
        "applicationType": "DEFAULT",
        "categoryId": "878644b5-fe91-45b4-9204-31c84a73ba48",
        "createTime": 1517966742981,
        "encrypted": "true",
        "engineId": "13",
        "helpString": "HTC software services",
        "ignoreConflict": false,
        "lastUpdateTime": 1517966742981,
        "longDescription": "HTC Services are HTC mobile devices software support and HTC website traffic.",
        "name": "htc-services",
        "nbarId": "1534",
        "p2pTechnology": "false",
        "popularity": 8,
        "rank": 65535,
        "references": "http://www.htc.com",
        "selectorId": "595",
        "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
        "trafficClass": "BULK_DATA",
        "tunnel": "false"
      }
    ],
    "networkIdentity": [
      {
        "id": "f0c4efe7-965a-4b6c-96b7-2f20b8d6970a",
        "instanceId": 13381,
        "authEntityId": 13381,
        "displayName": "13381",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "53,5353",
        "protocol": "UDP",
        "upperPort": 0
      },
      {
        "id": "d343fd50-0d12-4e03-92f2-3510c14d8210",
        "instanceId": 13382,
        "authEntityId": 13382,
        "displayName": "13382",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "53,80,443,5353",
        "protocol": "TCP",
        "upperPort": 0
      }
    ],
    "parentScalableGroup": {
      "idRef": "28e08de4-d7b2-4521-84ce-bc1e5ee0b9cc"
    },
    "user": [

    ],
    "userGroup": [

    ]
  },
  {
    "id": "4565dbf0-aa43-46fc-bf22-6856651b7ce9",
    "instanceId": 15470,
    "authEntityId": 15470,
    "displayName": "15470",
    "authEntityClass": -1909073334,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "createTime": 1517966745029,
    "deployed": false,
    "isSeeded": true,
    "isStale": false,
    "lastUpdateTime": 1517966745029,
    "name": "zserv",
    "namespace": "scalablegroup:application",
    "provisioningState": "DEFINED",
    "qualifier": "application",
    "resourceVersion": 0,
    "targetIdList": [

    ],
    "type": "scalablegroup",
    "cfsChangeInfo": [

    ],
    "customProvisions": [

    ],
    "scalableGroupExternalHandle": "zserv",
    "scalableGroupType": "APPLICATION",
    "securityGroupTag": 0,
    "state": "ACTIVE",
    "vnAgnostic": true,
    "identitySource": {
      "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
      "instanceId": 10010,
      "authEntityId": 10010,
      "displayName": "10010",
      "authEntityClass": 1738953278,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "state": "INACTIVE",
      "type": "NBAR"
    },
    "indicativeNetworkIdentity": [
      {
        "id": "cf435848-42b2-4222-bc1f-c62432e8d263",
        "instanceId": 17980,
        "authEntityId": 17980,
        "displayName": "17980",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "346",
        "protocol": "UDP",
        "upperPort": 0
      },
      {
        "id": "29910616-b28c-4d00-b931-ec5dcf11ac54",
        "instanceId": 17981,
        "authEntityId": 17981,
        "displayName": "17981",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "346",
        "protocol": "TCP",
        "upperPort": 0
      }
    ],
    "networkApplications": [
      {
        "id": "310df241-0c40-4a82-905b-cb36c8ebf302",
        "instanceId": 16413,
        "authEntityId": 16413,
        "displayName": "16413",
        "authEntityClass": -217092956,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "appProtocol": "tcp/udp",
        "applicationGroup": "other",
        "applicationType": "DEFAULT",
        "categoryId": "6daa3dfd-3526-407a-88f0-97824b3f3a24",
        "createTime": 1517966745029,
        "encrypted": "false",
        "engineId": "3",
        "helpString": "Zebra server",
        "ignoreConflict": false,
        "lastUpdateTime": 1517966745029,
        "longDescription": "zserv is the server for the ZFTP file transfer program which supports the transfer of ZEBRA RZ formatted files.",
        "name": "zserv",
        "nbarId": "763",
        "p2pTechnology": "false",
        "popularity": 1,
        "rank": 65535,
        "references": "http://linux.die.net/man/8/zserv",
        "selectorId": "346",
        "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
        "trafficClass": "BULK_DATA",
        "tunnel": "false"
      }
    ],
    "networkIdentity": [
      {
        "id": "ca390aac-01ac-4c98-849c-f8470c68d774",
        "instanceId": 17978,
        "authEntityId": 17978,
        "displayName": "17978",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "346",
        "protocol": "UDP",
        "upperPort": 0
      },
      {
        "id": "b77dc53c-d790-41ee-aa55-212af7441dbc",
        "instanceId": 17979,
        "authEntityId": 17979,
        "displayName": "17979",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "346",
        "protocol": "TCP",
        "upperPort": 0
      }
    ],
    "parentScalableGroup": {
      "idRef": "000c15bf-28fe-4fbb-9b69-35e6502721f1"
    },
    "user": [

    ],
    "userGroup": [

    ]
  },
  {
    "id": "457f8979-dee5-42ea-86f7-a798e3e2caa3",
    "instanceId": 15090,
    "authEntityId": 15090,
    "displayName": "15090",
    "authEntityClass": -1909073334,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "createTime": 1517966744211,
    "deployed": false,
    "isSeeded": true,
    "isStale": false,
    "lastUpdateTime": 1517966744211,
    "name": "sap",
    "namespace": "scalablegroup:application",
    "provisioningState": "DEFINED",
    "qualifier": "application",
    "resourceVersion": 0,
    "targetIdList": [

    ],
    "type": "scalablegroup",
    "cfsChangeInfo": [

    ],
    "customProvisions": [

    ],
    "scalableGroupExternalHandle": "sap",
    "scalableGroupType": "APPLICATION",
    "securityGroupTag": 0,
    "state": "ACTIVE",
    "vnAgnostic": true,
    "identitySource": {
      "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
      "instanceId": 10010,
      "authEntityId": 10010,
      "displayName": "10010",
      "authEntityClass": 1738953278,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "state": "INACTIVE",
      "type": "NBAR"
    },
    "indicativeNetworkIdentity": [
      {
        "id": "133fd826-17da-4525-9c21-18cbbc5f0e92",
        "instanceId": 14907,
        "authEntityId": 14907,
        "displayName": "14907",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "3200,3300,3600",
        "protocol": "TCP",
        "upperPort": 0
      }
    ],
    "networkApplications": [
      {
        "id": "60300018-5bad-4cdf-88d0-eafa72fa5996",
        "instanceId": 16033,
        "authEntityId": 16033,
        "displayName": "16033",
        "authEntityClass": -217092956,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "appProtocol": "tcp",
        "applicationGroup": "other",
        "applicationType": "DEFAULT",
        "categoryId": "7e19fdea-6222-4c57-9e66-66c2340d6bc3",
        "createTime": 1517966744211,
        "encrypted": "false",
        "engineId": "13",
        "helpString": "SAP Systems Applications Product in Data processing",
        "ignoreConflict": false,
        "lastUpdateTime": 1517966744211,
        "longDescription": "SAP offers various software applications and solutions for businesses and business productivity. It provides solutions for IT management, data bases and business analysis. Typically, SAP uses 3200, 3300 and 3600 TCP ports as default",
        "name": "sap",
        "nbarId": "84",
        "p2pTechnology": "false",
        "popularity": 10,
        "rank": 65535,
        "references": "http://www.sap.com",
        "selectorId": "84",
        "subCategory": "b90786f4-0190-45b8-9924-21b3e9682590",
        "trafficClass": "TRANSACTIONAL_DATA",
        "tunnel": "false"
      }
    ],
    "networkIdentity": [
      {
        "id": "019154e7-fe75-47be-b8b2-afbf6a085bc4",
        "instanceId": 14906,
        "authEntityId": 14906,
        "displayName": "14906",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "3200,3300,3600",
        "protocol": "TCP",
        "upperPort": 0
      }
    ],
    "parentScalableGroup": {
      "idRef": "dc13f401-c34c-4d18-a5a1-6e9f8624d67e"
    },
    "user": [

    ],
    "userGroup": [

    ]
  },
  {
    "id": "45aa33de-c8cc-4523-ad8b-8cb80e4a0f9a",
    "instanceId": 4411,
    "authEntityId": 4411,
    "displayName": "4411",
    "authEntityClass": -1909073334,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "createTime": 1517966742569,
    "deployed": false,
    "isSeeded": true,
    "isStale": false,
    "lastUpdateTime": 1517966742569,
    "name": "engadget",
    "namespace": "scalablegroup:application",
    "provisioningState": "DEFINED",
    "qualifier": "application",
    "resourceVersion": 0,
    "targetIdList": [

    ],
    "type": "scalablegroup",
    "cfsChangeInfo": [

    ],
    "customProvisions": [

    ],
    "scalableGroupExternalHandle": "engadget",
    "scalableGroupType": "APPLICATION",
    "securityGroupTag": 0,
    "state": "ACTIVE",
    "vnAgnostic": true,
    "identitySource": {
      "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
      "instanceId": 10010,
      "authEntityId": 10010,
      "displayName": "10010",
      "authEntityClass": 1738953278,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "state": "INACTIVE",
      "type": "NBAR"
    },
    "indicativeNetworkIdentity": [

    ],
    "networkApplications": [
      {
        "id": "8de92cac-fe5b-4485-a512-a9434fc88df4",
        "instanceId": 11362,
        "authEntityId": 11362,
        "displayName": "11362",
        "authEntityClass": -217092956,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "appProtocol": "tcp/udp",
        "applicationGroup": "other",
        "applicationType": "DEFAULT",
        "categoryId": "878644b5-fe91-45b4-9204-31c84a73ba48",
        "createTime": 1517966742569,
        "encrypted": "false",
        "engineId": "13",
        "helpString": "Engadget - E-commerce for gadgets and electronics.",
        "ignoreConflict": false,
        "lastUpdateTime": 1517966742569,
        "longDescription": "Engadget - E-commerce for gadgets and electronics.",
        "name": "engadget",
        "nbarId": "1596",
        "p2pTechnology": "false",
        "popularity": 3,
        "rank": 65535,
        "references": "http://engadget.com",
        "selectorId": "667",
        "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
        "trafficClass": "TRANSACTIONAL_DATA",
        "tunnel": "false"
      }
    ],
    "networkIdentity": [
      {
        "id": "be071f15-278b-481b-ad01-2c785a180f1e",
        "instanceId": 13024,
        "authEntityId": 13024,
        "displayName": "13024",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "53,80,443,5353",
        "protocol": "TCP",
        "upperPort": 0
      },
      {
        "id": "2b1163d0-9eff-49d4-bd15-bcd999cff18d",
        "instanceId": 13025,
        "authEntityId": 13025,
        "displayName": "13025",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "53,5353",
        "protocol": "UDP",
        "upperPort": 0
      }
    ],
    "parentScalableGroup": {
      "idRef": "28e08de4-d7b2-4521-84ce-bc1e5ee0b9cc"
    },
    "user": [

    ],
    "userGroup": [

    ]
  },
  {
    "id": "45b37331-421e-46bc-9bfb-5c4a6dbc9b7c",
    "instanceId": 15041,
    "authEntityId": 15041,
    "displayName": "15041",
    "authEntityClass": -1909073334,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "createTime": 1517966744092,
    "deployed": false,
    "isSeeded": true,
    "isStale": false,
    "lastUpdateTime": 1517966744092,
    "name": "repcmd",
    "namespace": "scalablegroup:application",
    "provisioningState": "DEFINED",
    "qualifier": "application",
    "resourceVersion": 0,
    "targetIdList": [

    ],
    "type": "scalablegroup",
    "cfsChangeInfo": [

    ],
    "customProvisions": [

    ],
    "scalableGroupExternalHandle": "repcmd",
    "scalableGroupType": "APPLICATION",
    "securityGroupTag": 0,
    "state": "ACTIVE",
    "vnAgnostic": true,
    "identitySource": {
      "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
      "instanceId": 10010,
      "authEntityId": 10010,
      "displayName": "10010",
      "authEntityClass": 1738953278,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "state": "INACTIVE",
      "type": "NBAR"
    },
    "indicativeNetworkIdentity": [
      {
        "id": "14c10c82-d6d9-44cc-8632-7acba9c417e4",
        "instanceId": 14753,
        "authEntityId": 14753,
        "displayName": "14753",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "641",
        "protocol": "TCP",
        "upperPort": 0
      },
      {
        "id": "8313b825-11bb-4531-9f09-5dad7b2b025c",
        "instanceId": 14754,
        "authEntityId": 14754,
        "displayName": "14754",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "641",
        "protocol": "UDP",
        "upperPort": 0
      }
    ],
    "networkApplications": [
      {
        "id": "737652b7-1e89-43c4-b82f-6c088f777f35",
        "instanceId": 11980,
        "authEntityId": 11980,
        "displayName": "11980",
        "authEntityClass": -217092956,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "appProtocol": "tcp/udp",
        "applicationGroup": "other",
        "applicationType": "DEFAULT",
        "categoryId": "6daa3dfd-3526-407a-88f0-97824b3f3a24",
        "createTime": 1517966744092,
        "encrypted": "false",
        "engineId": "3",
        "helpString": "repcmd",
        "ignoreConflict": false,
        "lastUpdateTime": 1517966744092,
        "longDescription": "Repcmd is a protocol used by the SupportSoft remote support solution to enable support representatives to connect to a remote PC and provide assitance.",
        "name": "repcmd",
        "nbarId": "550",
        "p2pTechnology": "false",
        "popularity": 1,
        "rank": 65535,
        "references": "http://www.consona.com/supportsoft/",
        "selectorId": "641",
        "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
        "trafficClass": "BULK_DATA",
        "tunnel": "false"
      }
    ],
    "networkIdentity": [
      {
        "id": "9e4adf35-7313-4a2e-a2bf-e841285d2e5d",
        "instanceId": 14752,
        "authEntityId": 14752,
        "displayName": "14752",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "641",
        "protocol": "TCP",
        "upperPort": 0
      },
      {
        "id": "6ceb498e-4698-4b2d-b6a3-8d6a76e7a5af",
        "instanceId": 14751,
        "authEntityId": 14751,
        "displayName": "14751",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "641",
        "protocol": "UDP",
        "upperPort": 0
      }
    ],
    "parentScalableGroup": {
      "idRef": "000c15bf-28fe-4fbb-9b69-35e6502721f1"
    },
    "user": [

    ],
    "userGroup": [

    ]
  },
  {
    "id": "462d8fa3-a9c2-4219-a631-271411315f3b",
    "instanceId": 4134,
    "authEntityId": 4134,
    "displayName": "4134",
    "authEntityClass": -1909073334,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "createTime": 1517966741310,
    "deployed": false,
    "isSeeded": true,
    "isStale": false,
    "lastUpdateTime": 1517966741310,
    "name": "at-nbp",
    "namespace": "scalablegroup:application",
    "provisioningState": "DEFINED",
    "qualifier": "application",
    "resourceVersion": 0,
    "targetIdList": [

    ],
    "type": "scalablegroup",
    "cfsChangeInfo": [

    ],
    "customProvisions": [

    ],
    "scalableGroupExternalHandle": "at-nbp",
    "scalableGroupType": "APPLICATION",
    "securityGroupTag": 0,
    "state": "ACTIVE",
    "vnAgnostic": true,
    "identitySource": {
      "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
      "instanceId": 10010,
      "authEntityId": 10010,
      "displayName": "10010",
      "authEntityClass": 1738953278,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "state": "INACTIVE",
      "type": "NBAR"
    },
    "indicativeNetworkIdentity": [
      {
        "id": "3756a810-4bc9-4618-84ad-fa06cac17487",
        "instanceId": 12265,
        "authEntityId": 12265,
        "displayName": "12265",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "202",
        "protocol": "TCP",
        "upperPort": 0
      },
      {
        "id": "ab29ab3b-5e17-4661-b818-923c80ec9ca3",
        "instanceId": 12266,
        "authEntityId": 12266,
        "displayName": "12266",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "202",
        "protocol": "UDP",
        "upperPort": 0
      }
    ],
    "networkApplications": [
      {
        "id": "809b0399-7cfe-4b42-ac28-bf8e8bf4af69",
        "instanceId": 11096,
        "authEntityId": 11096,
        "displayName": "11096",
        "authEntityClass": -217092956,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "appProtocol": "tcp/udp",
        "applicationGroup": "apple-talk-group",
        "applicationType": "DEFAULT",
        "categoryId": "1fc17362-9334-4c7b-b752-5afdbb574017",
        "createTime": 1517966741310,
        "encrypted": "false",
        "engineId": "3",
        "helpString": "AppleTalk Name Binding",
        "ignoreConflict": false,
        "lastUpdateTime": 1517966741310,
        "longDescription": "AppleTalk Name Binding (NBP) was a dynamic, distributed system for managing AppleTalk names. When a service started up on a machine, it registered a name for itself as chosen by a human administrator.",
        "name": "at-nbp",
        "nbarId": "1100",
        "p2pTechnology": "false",
        "popularity": 4,
        "rank": 65535,
        "references": "http://en.wikipedia.org/wiki/AppleTalk#Name_Binding_Protocol",
        "selectorId": "202",
        "subCategory": "f3c0f761-d894-4112-ab89-b90fc84fd660",
        "trafficClass": "OPS_ADMIN_MGMT",
        "tunnel": "false"
      }
    ],
    "networkIdentity": [
      {
        "id": "a56ae91e-6eef-4372-af90-ae6372dadc92",
        "instanceId": 12263,
        "authEntityId": 12263,
        "displayName": "12263",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "202",
        "protocol": "UDP",
        "upperPort": 0
      },
      {
        "id": "d0b13168-705d-4a6e-b1ce-ef347db569a5",
        "instanceId": 12264,
        "authEntityId": 12264,
        "displayName": "12264",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "202",
        "protocol": "TCP",
        "upperPort": 0
      }
    ],
    "parentScalableGroup": {
      "idRef": "5fcd74d4-8726-47f7-a088-c457863da7ef"
    },
    "user": [

    ],
    "userGroup": [

    ]
  },
  {
    "id": "463a1edd-91d1-4c0e-8798-60bcfd9c5b78",
    "instanceId": 15306,
    "authEntityId": 15306,
    "displayName": "15306",
    "authEntityClass": -1909073334,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "createTime": 1517966744705,
    "deployed": false,
    "isSeeded": true,
    "isStale": false,
    "lastUpdateTime": 1517966744705,
    "name": "tserver",
    "namespace": "scalablegroup:application",
    "provisioningState": "DEFINED",
    "qualifier": "application",
    "resourceVersion": 0,
    "targetIdList": [

    ],
    "type": "scalablegroup",
    "cfsChangeInfo": [

    ],
    "customProvisions": [

    ],
    "scalableGroupExternalHandle": "tserver",
    "scalableGroupType": "APPLICATION",
    "securityGroupTag": 0,
    "state": "ACTIVE",
    "vnAgnostic": true,
    "identitySource": {
      "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
      "instanceId": 10010,
      "authEntityId": 10010,
      "displayName": "10010",
      "authEntityClass": 1738953278,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "state": "INACTIVE",
      "type": "NBAR"
    },
    "indicativeNetworkIdentity": [
      {
        "id": "8be85f90-398b-4e9b-8d3b-44aad79b3b27",
        "instanceId": 17542,
        "authEntityId": 17542,
        "displayName": "17542",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "450",
        "protocol": "UDP",
        "upperPort": 0
      },
      {
        "id": "c61b0395-6d1d-4c52-a269-3b5294e7f54b",
        "instanceId": 17543,
        "authEntityId": 17543,
        "displayName": "17543",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "450",
        "protocol": "TCP",
        "upperPort": 0
      }
    ],
    "networkApplications": [
      {
        "id": "40bf16ca-313b-4320-87c4-36577bb2fde6",
        "instanceId": 16249,
        "authEntityId": 16249,
        "displayName": "16249",
        "authEntityClass": -217092956,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "appProtocol": "tcp/udp",
        "applicationGroup": "other",
        "applicationType": "DEFAULT",
        "categoryId": "6daa3dfd-3526-407a-88f0-97824b3f3a24",
        "createTime": 1517966744705,
        "encrypted": "false",
        "engineId": "3",
        "helpString": "Computer Supported Telecommunication Applications",
        "ignoreConflict": false,
        "lastUpdateTime": 1517966744705,
        "longDescription": "Registered with IANA on port 450 TCP/UDP",
        "name": "tserver",
        "nbarId": "365",
        "p2pTechnology": "false",
        "popularity": 1,
        "rank": 65535,
        "references": "http://www.iana.org/assignments/service-names-port-numbers/service-names-port-numbers.xml",
        "selectorId": "450",
        "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
        "trafficClass": "BULK_DATA",
        "tunnel": "false"
      }
    ],
    "networkIdentity": [
      {
        "id": "a0a2d68f-5dac-425e-ad0b-362a7d053ee1",
        "instanceId": 17540,
        "authEntityId": 17540,
        "displayName": "17540",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "450",
        "protocol": "UDP",
        "upperPort": 0
      },
      {
        "id": "9d36db1c-77ba-4c0a-8b5f-5bb05b28bead",
        "instanceId": 17541,
        "authEntityId": 17541,
        "displayName": "17541",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "450",
        "protocol": "TCP",
        "upperPort": 0
      }
    ],
    "parentScalableGroup": {
      "idRef": "000c15bf-28fe-4fbb-9b69-35e6502721f1"
    },
    "user": [

    ],
    "userGroup": [

    ]
  },
  {
    "id": "463a9130-3e46-492b-8a2a-148ed7c92616",
    "instanceId": 4722,
    "authEntityId": 4722,
    "displayName": "4722",
    "authEntityClass": -1909073334,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "createTime": 1517966743357,
    "deployed": false,
    "isSeeded": true,
    "isStale": false,
    "lastUpdateTime": 1517966743357,
    "name": "maplestory",
    "namespace": "scalablegroup:application",
    "provisioningState": "DEFINED",
    "qualifier": "application",
    "resourceVersion": 0,
    "targetIdList": [

    ],
    "type": "scalablegroup",
    "cfsChangeInfo": [

    ],
    "customProvisions": [

    ],
    "scalableGroupExternalHandle": "maplestory",
    "scalableGroupType": "APPLICATION",
    "securityGroupTag": 0,
    "state": "ACTIVE",
    "vnAgnostic": true,
    "identitySource": {
      "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
      "instanceId": 10010,
      "authEntityId": 10010,
      "displayName": "10010",
      "authEntityClass": 1738953278,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "state": "INACTIVE",
      "type": "NBAR"
    },
    "indicativeNetworkIdentity": [

    ],
    "networkApplications": [
      {
        "id": "e745b9fb-65cf-42ea-8757-0f8c24f913a4",
        "instanceId": 11672,
        "authEntityId": 11672,
        "displayName": "11672",
        "authEntityClass": -217092956,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "applicationGroup": "other",
        "applicationType": "DEFAULT",
        "categoryId": "3cd50dd8-1b2e-46c1-b672-41c14bfa364b",
        "createTime": 1517966743357,
        "encrypted": "false",
        "engineId": "13",
        "helpString": "Maple Story Gaming Protocol",
        "ignoreConflict": false,
        "lastUpdateTime": 1517966743357,
        "longDescription": "Maplestory is a massively multiplayer online (2D) role playing game (MMORPG) developed by the South Korean company Wizet. The protocol runs over TCP traffic and is divided into control traffic and data traffic.",
        "name": "maplestory",
        "nbarId": "1086",
        "p2pTechnology": "false",
        "popularity": 6,
        "rank": 65535,
        "references": "http://maplestory.nexon.net/",
        "selectorId": "448",
        "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
        "trafficClass": "MULTIMEDIA_CONFERENCING",
        "tunnel": "false"
      }
    ],
    "networkIdentity": [

    ],
    "parentScalableGroup": {
      "idRef": "0bd0e6c9-5083-492b-97cb-a88928bee99c"
    },
    "user": [

    ],
    "userGroup": [

    ]
  },
  {
    "id": "465c0a6d-38cb-4d07-8fc1-2fcebe49fc05",
    "instanceId": 4518,
    "authEntityId": 4518,
    "displayName": "4518",
    "authEntityClass": -1909073334,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "createTime": 1517966742869,
    "deployed": false,
    "isSeeded": true,
    "isStale": false,
    "lastUpdateTime": 1517966742869,
    "name": "gtalk-ft",
    "namespace": "scalablegroup:application",
    "provisioningState": "DEFINED",
    "qualifier": "application",
    "resourceVersion": 0,
    "targetIdList": [

    ],
    "type": "scalablegroup",
    "cfsChangeInfo": [

    ],
    "customProvisions": [

    ],
    "scalableGroupExternalHandle": "gtalk-ft",
    "scalableGroupType": "APPLICATION",
    "securityGroupTag": 0,
    "state": "ACTIVE",
    "vnAgnostic": true,
    "identitySource": {
      "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
      "instanceId": 10010,
      "authEntityId": 10010,
      "displayName": "10010",
      "authEntityClass": 1738953278,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "state": "INACTIVE",
      "type": "NBAR"
    },
    "indicativeNetworkIdentity": [

    ],
    "networkApplications": [
      {
        "id": "ed9e5479-6ec3-40c8-a8d5-5e9a52dd2eec",
        "instanceId": 11469,
        "authEntityId": 11469,
        "displayName": "11469",
        "authEntityClass": -217092956,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "appProtocol": "tcp/udp",
        "applicationGroup": "gtalk-group",
        "applicationType": "DEFAULT",
        "categoryId": "b33b2761-d981-4734-810a-a6a1c18ab352",
        "createTime": 1517966742869,
        "encrypted": "true",
        "engineId": "13",
        "helpString": "Protocol integrated into hangouts-file-transfer",
        "ignoreConflict": false,
        "lastUpdateTime": 1517966742869,
        "longDescription": "Google Talk File Transfer (FT) is a feature of Google Talk (GTALK) that allows users to transfer files via GTALK. The underlying protocols for Google Talk File Transfer are Google Talk (GTALK), STUN and HTTP. Google Talk is now being replaced by Google Hangouts",
        "name": "gtalk-ft",
        "nbarId": "1201",
        "p2pTechnology": "false",
        "popularity": 10,
        "rank": 65535,
        "references": "http://en.wikipedia.org/wiki/Google_talk",
        "selectorId": "308",
        "subCategory": "50b55e2e-d0d0-4404-bea4-819351565d90",
        "trafficClass": "BULK_DATA",
        "tunnel": "false"
      }
    ],
    "networkIdentity": [
      {
        "id": "48a6f6df-8eb9-47d7-a67f-5f684c7aa67a",
        "instanceId": 13289,
        "authEntityId": 13289,
        "displayName": "13289",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "3478",
        "protocol": "TCP",
        "upperPort": 0
      },
      {
        "id": "6348bfbf-c508-4079-b0fe-dc210c1d7683",
        "instanceId": 13290,
        "authEntityId": 13290,
        "displayName": "13290",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "3478",
        "protocol": "UDP",
        "upperPort": 0
      }
    ],
    "parentScalableGroup": {
      "idRef": "7a1a3bbb-dc84-4a35-82a4-7e650c4c4761"
    },
    "user": [

    ],
    "userGroup": [

    ]
  },
  {
    "id": "465f679d-ef82-484e-8e47-09bb57acd150",
    "instanceId": 4169,
    "authEntityId": 4169,
    "displayName": "4169",
    "authEntityClass": -1909073334,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "createTime": 1517966741473,
    "deployed": false,
    "isSeeded": true,
    "isStale": false,
    "lastUpdateTime": 1517966741473,
    "name": "bbnrccmon",
    "namespace": "scalablegroup:application",
    "provisioningState": "DEFINED",
    "qualifier": "application",
    "resourceVersion": 0,
    "targetIdList": [

    ],
    "type": "scalablegroup",
    "cfsChangeInfo": [

    ],
    "customProvisions": [

    ],
    "scalableGroupExternalHandle": "bbnrccmon",
    "scalableGroupType": "APPLICATION",
    "securityGroupTag": 0,
    "state": "ACTIVE",
    "vnAgnostic": true,
    "identitySource": {
      "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
      "instanceId": 10010,
      "authEntityId": 10010,
      "displayName": "10010",
      "authEntityClass": 1738953278,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "state": "INACTIVE",
      "type": "NBAR"
    },
    "indicativeNetworkIdentity": [
      {
        "id": "eb2d9f05-572a-4ebc-926b-5af1542e2e48",
        "instanceId": 12353,
        "authEntityId": 12353,
        "displayName": "12353",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipProtocolNumbers": "10",
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "protocol": "BBN_RCC_MON",
        "upperPort": 0
      }
    ],
    "networkApplications": [
      {
        "id": "e9df6eca-a6d2-4489-ae8d-d6c8ea68fdef",
        "instanceId": 11128,
        "authEntityId": 11128,
        "displayName": "11128",
        "authEntityClass": -217092956,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "appProtocol": "ip",
        "applicationGroup": "other",
        "applicationType": "DEFAULT",
        "categoryId": "6daa3dfd-3526-407a-88f0-97824b3f3a24",
        "createTime": 1517966741473,
        "encrypted": "unassigned",
        "engineId": "1",
        "helpString": "BBN RCC Monitoring",
        "ignoreConflict": false,
        "lastUpdateTime": 1517966741473,
        "longDescription": "Registered with IANA as IP Protocol 10",
        "name": "bbnrccmon",
        "nbarId": "765",
        "p2pTechnology": "unassigned",
        "popularity": 1,
        "rank": 65535,
        "references": "http://www.iana.org/assignments/protocol-numbers/protocol-numbers.xml",
        "selectorId": "10",
        "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
        "trafficClass": "BULK_DATA",
        "tunnel": "unassigned"
      }
    ],
    "networkIdentity": [
      {
        "id": "1e2f3f70-1b03-42f1-8c2b-f1828ad2fe53",
        "instanceId": 12352,
        "authEntityId": 12352,
        "displayName": "12352",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipProtocolNumbers": "10",
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "protocol": "BBN_RCC_MON",
        "upperPort": 0
      }
    ],
    "parentScalableGroup": {
      "idRef": "000c15bf-28fe-4fbb-9b69-35e6502721f1"
    },
    "user": [

    ],
    "userGroup": [

    ]
  },
  {
    "id": "46647cb6-f366-4eb9-be09-8f491a7207ec",
    "instanceId": 15353,
    "authEntityId": 15353,
    "displayName": "15353",
    "authEntityClass": -1909073334,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "createTime": 1517966744791,
    "deployed": false,
    "isSeeded": true,
    "isStale": false,
    "lastUpdateTime": 1517966744791,
    "name": "vine",
    "namespace": "scalablegroup:application",
    "provisioningState": "DEFINED",
    "qualifier": "application",
    "resourceVersion": 0,
    "targetIdList": [

    ],
    "type": "scalablegroup",
    "cfsChangeInfo": [

    ],
    "customProvisions": [

    ],
    "scalableGroupExternalHandle": "vine",
    "scalableGroupType": "APPLICATION",
    "securityGroupTag": 0,
    "state": "ACTIVE",
    "vnAgnostic": true,
    "identitySource": {
      "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
      "instanceId": 10010,
      "authEntityId": 10010,
      "displayName": "10010",
      "authEntityClass": 1738953278,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "state": "INACTIVE",
      "type": "NBAR"
    },
    "indicativeNetworkIdentity": [

    ],
    "networkApplications": [
      {
        "id": "39a2f491-c661-4cc2-8cc2-dbd7f80fdc46",
        "instanceId": 16296,
        "authEntityId": 16296,
        "displayName": "16296",
        "authEntityClass": -217092956,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "appProtocol": "tcp/udp",
        "applicationGroup": "other",
        "applicationType": "DEFAULT",
        "categoryId": "878644b5-fe91-45b4-9204-31c84a73ba48",
        "createTime": 1517966744791,
        "encrypted": "false",
        "engineId": "13",
        "helpString": "Vine - Mobile App for sharing photos and videos clips.",
        "ignoreConflict": false,
        "lastUpdateTime": 1517966744791,
        "longDescription": "Vine - Mobile App for sharing photos and videos clips.",
        "name": "vine",
        "nbarId": "1674",
        "p2pTechnology": "false",
        "popularity": 3,
        "rank": 65535,
        "references": "http://vineapp.com",
        "selectorId": "744",
        "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
        "trafficClass": "TRANSACTIONAL_DATA",
        "tunnel": "false"
      }
    ],
    "networkIdentity": [
      {
        "id": "905351cb-121c-4b5e-bae0-0b78fc51cb38",
        "instanceId": 17675,
        "authEntityId": 17675,
        "displayName": "17675",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "53,80,443,5353",
        "protocol": "TCP",
        "upperPort": 0
      },
      {
        "id": "a7e87d55-34db-4722-8012-d4de30cc8520",
        "instanceId": 17676,
        "authEntityId": 17676,
        "displayName": "17676",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "53,5353",
        "protocol": "UDP",
        "upperPort": 0
      }
    ],
    "parentScalableGroup": {
      "idRef": "28e08de4-d7b2-4521-84ce-bc1e5ee0b9cc"
    },
    "user": [

    ],
    "userGroup": [

    ]
  },
  {
    "id": "46a5cd59-7841-40f5-904d-0dbfac508507",
    "instanceId": 15293,
    "authEntityId": 15293,
    "displayName": "15293",
    "authEntityClass": -1909073334,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "createTime": 1517966744673,
    "deployed": false,
    "isSeeded": true,
    "isStale": false,
    "lastUpdateTime": 1517966744673,
    "name": "tmz",
    "namespace": "scalablegroup:application",
    "provisioningState": "DEFINED",
    "qualifier": "application",
    "resourceVersion": 0,
    "targetIdList": [

    ],
    "type": "scalablegroup",
    "cfsChangeInfo": [

    ],
    "customProvisions": [

    ],
    "scalableGroupExternalHandle": "tmz",
    "scalableGroupType": "APPLICATION",
    "securityGroupTag": 0,
    "state": "ACTIVE",
    "vnAgnostic": true,
    "identitySource": {
      "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
      "instanceId": 10010,
      "authEntityId": 10010,
      "displayName": "10010",
      "authEntityClass": 1738953278,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "state": "INACTIVE",
      "type": "NBAR"
    },
    "indicativeNetworkIdentity": [

    ],
    "networkApplications": [
      {
        "id": "2e5ddd2d-1afb-40dc-a8e7-bbfe467288f5",
        "instanceId": 16236,
        "authEntityId": 16236,
        "displayName": "16236",
        "authEntityClass": -217092956,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "appProtocol": "tcp/udp",
        "applicationGroup": "other",
        "applicationType": "DEFAULT",
        "categoryId": "878644b5-fe91-45b4-9204-31c84a73ba48",
        "createTime": 1517966744673,
        "encrypted": "false",
        "engineId": "13",
        "helpString": "TMZ - Entertainment news.",
        "ignoreConflict": false,
        "lastUpdateTime": 1517966744673,
        "longDescription": "TMZ - Entertainment news.",
        "name": "tmz",
        "nbarId": "1631",
        "p2pTechnology": "false",
        "popularity": 3,
        "rank": 65535,
        "references": "http://tmz.com",
        "selectorId": "701",
        "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
        "trafficClass": "TRANSACTIONAL_DATA",
        "tunnel": "false"
      }
    ],
    "networkIdentity": [
      {
        "id": "5ab49615-f2ad-415e-9398-2b6e723957f7",
        "instanceId": 17507,
        "authEntityId": 17507,
        "displayName": "17507",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "53,5353",
        "protocol": "UDP",
        "upperPort": 0
      },
      {
        "id": "50c5319d-0113-407b-a982-d6a9f5bad94e",
        "instanceId": 17508,
        "authEntityId": 17508,
        "displayName": "17508",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "53,80,443,5353",
        "protocol": "TCP",
        "upperPort": 0
      }
    ],
    "parentScalableGroup": {
      "idRef": "28e08de4-d7b2-4521-84ce-bc1e5ee0b9cc"
    },
    "user": [

    ],
    "userGroup": [

    ]
  },
  {
    "id": "46b72eb2-adc5-4049-a8f8-a434cfed18ed",
    "instanceId": 4991,
    "authEntityId": 4991,
    "displayName": "4991",
    "authEntityClass": -1909073334,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "createTime": 1517966743994,
    "deployed": false,
    "isSeeded": true,
    "isStale": false,
    "lastUpdateTime": 1517966743994,
    "name": "psrserver",
    "namespace": "scalablegroup:application",
    "provisioningState": "DEFINED",
    "qualifier": "application",
    "resourceVersion": 0,
    "targetIdList": [

    ],
    "type": "scalablegroup",
    "cfsChangeInfo": [

    ],
    "customProvisions": [

    ],
    "scalableGroupExternalHandle": "psrserver",
    "scalableGroupType": "APPLICATION",
    "securityGroupTag": 0,
    "state": "ACTIVE",
    "vnAgnostic": true,
    "identitySource": {
      "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
      "instanceId": 10010,
      "authEntityId": 10010,
      "displayName": "10010",
      "authEntityClass": 1738953278,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "state": "INACTIVE",
      "type": "NBAR"
    },
    "indicativeNetworkIdentity": [
      {
        "id": "4053cbdb-c90f-42da-bd95-2f22ad7e5c2d",
        "instanceId": 14628,
        "authEntityId": 14628,
        "displayName": "14628",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "2351",
        "protocol": "TCP",
        "upperPort": 0
      },
      {
        "id": "71e31bb8-8c13-4acd-86e2-95024a327b2b",
        "instanceId": 14629,
        "authEntityId": 14629,
        "displayName": "14629",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "2351",
        "protocol": "UDP",
        "upperPort": 0
      }
    ],
    "networkApplications": [
      {
        "id": "42edf799-15f6-469f-a131-db0e79726db6",
        "instanceId": 11940,
        "authEntityId": 11940,
        "displayName": "11940",
        "authEntityClass": -217092956,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "appProtocol": "tcp/udp",
        "applicationGroup": "other",
        "applicationType": "DEFAULT",
        "categoryId": "6daa3dfd-3526-407a-88f0-97824b3f3a24",
        "createTime": 1517966743994,
        "encrypted": "false",
        "engineId": "3",
        "helpString": "Pharos psrserver",
        "ignoreConflict": false,
        "lastUpdateTime": 1517966743994,
        "longDescription": "Registered with IANA on port 2351 TCP/UDP",
        "name": "psrserver",
        "nbarId": "1360",
        "p2pTechnology": "false",
        "popularity": 1,
        "rank": 65535,
        "references": "http://www.iana.org/assignments/service-names-port-numbers/service-names-port-numbers.xml",
        "selectorId": "2351",
        "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
        "trafficClass": "BULK_DATA",
        "tunnel": "false"
      }
    ],
    "networkIdentity": [
      {
        "id": "c70d11f2-ca33-45db-8daf-a9807b354650",
        "instanceId": 14626,
        "authEntityId": 14626,
        "displayName": "14626",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "2351",
        "protocol": "TCP",
        "upperPort": 0
      },
      {
        "id": "4b482018-2d92-4c8b-a28c-26a7fe24b72d",
        "instanceId": 14627,
        "authEntityId": 14627,
        "displayName": "14627",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "2351",
        "protocol": "UDP",
        "upperPort": 0
      }
    ],
    "parentScalableGroup": {
      "idRef": "000c15bf-28fe-4fbb-9b69-35e6502721f1"
    },
    "user": [

    ],
    "userGroup": [

    ]
  },
  {
    "id": "46cb842e-e14a-4bec-ab76-85ff211c9cf5",
    "instanceId": 4691,
    "authEntityId": 4691,
    "displayName": "4691",
    "authEntityClass": -1909073334,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "createTime": 1517966743293,
    "deployed": false,
    "isSeeded": true,
    "isStale": false,
    "lastUpdateTime": 1517966743293,
    "name": "leaf-2",
    "namespace": "scalablegroup:application",
    "provisioningState": "DEFINED",
    "qualifier": "application",
    "resourceVersion": 0,
    "targetIdList": [

    ],
    "type": "scalablegroup",
    "cfsChangeInfo": [

    ],
    "customProvisions": [

    ],
    "scalableGroupExternalHandle": "leaf-2",
    "scalableGroupType": "APPLICATION",
    "securityGroupTag": 0,
    "state": "ACTIVE",
    "vnAgnostic": true,
    "identitySource": {
      "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
      "instanceId": 10010,
      "authEntityId": 10010,
      "displayName": "10010",
      "authEntityClass": 1738953278,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "state": "INACTIVE",
      "type": "NBAR"
    },
    "indicativeNetworkIdentity": [
      {
        "id": "c17ad854-0853-43d5-bb89-ddae7cc073fe",
        "instanceId": 13747,
        "authEntityId": 13747,
        "displayName": "13747",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipProtocolNumbers": "26",
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "protocol": "LEAF_2",
        "upperPort": 0
      }
    ],
    "networkApplications": [
      {
        "id": "2fe92786-c8b5-42c8-964e-4f9be4b6f13e",
        "instanceId": 11641,
        "authEntityId": 11641,
        "displayName": "11641",
        "authEntityClass": -217092956,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "appProtocol": "ip",
        "applicationGroup": "other",
        "applicationType": "DEFAULT",
        "categoryId": "6daa3dfd-3526-407a-88f0-97824b3f3a24",
        "createTime": 1517966743293,
        "encrypted": "unassigned",
        "engineId": "1",
        "helpString": "Leaf-2",
        "ignoreConflict": false,
        "lastUpdateTime": 1517966743293,
        "longDescription": "The Leaf File Access Protocol is one of the first protocols to enable remote access to files.",
        "name": "leaf-2",
        "nbarId": "780",
        "p2pTechnology": "unassigned",
        "popularity": 2,
        "rank": 65535,
        "references": "http://infolab.stanford.edu/TR/CS-TR-86-1137.html",
        "selectorId": "26",
        "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
        "trafficClass": "BULK_DATA",
        "tunnel": "unassigned"
      }
    ],
    "networkIdentity": [
      {
        "id": "4f1a760f-9d81-4537-acb2-a88080938a01",
        "instanceId": 13746,
        "authEntityId": 13746,
        "displayName": "13746",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipProtocolNumbers": "26",
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "protocol": "LEAF_2",
        "upperPort": 0
      }
    ],
    "parentScalableGroup": {
      "idRef": "000c15bf-28fe-4fbb-9b69-35e6502721f1"
    },
    "user": [

    ],
    "userGroup": [

    ]
  },
  {
    "id": "46ee2cd8-f55c-44be-ba18-168b284c9078",
    "instanceId": 15410,
    "authEntityId": 15410,
    "displayName": "15410",
    "authEntityClass": -1909073334,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "createTime": 1517966744916,
    "deployed": false,
    "isSeeded": true,
    "isStale": false,
    "lastUpdateTime": 1517966744916,
    "name": "wetransfer",
    "namespace": "scalablegroup:application",
    "provisioningState": "DEFINED",
    "qualifier": "application",
    "resourceVersion": 0,
    "targetIdList": [

    ],
    "type": "scalablegroup",
    "cfsChangeInfo": [

    ],
    "customProvisions": [

    ],
    "scalableGroupExternalHandle": "wetransfer",
    "scalableGroupType": "APPLICATION",
    "securityGroupTag": 0,
    "state": "ACTIVE",
    "vnAgnostic": true,
    "identitySource": {
      "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
      "instanceId": 10010,
      "authEntityId": 10010,
      "displayName": "10010",
      "authEntityClass": 1738953278,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "state": "INACTIVE",
      "type": "NBAR"
    },
    "indicativeNetworkIdentity": [

    ],
    "networkApplications": [
      {
        "id": "5999af42-bbda-4227-a7f5-324ef0972824",
        "instanceId": 16353,
        "authEntityId": 16353,
        "displayName": "16353",
        "authEntityClass": -217092956,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "appProtocol": "tcp/udp",
        "applicationGroup": "other",
        "applicationType": "DEFAULT",
        "categoryId": "878644b5-fe91-45b4-9204-31c84a73ba48",
        "createTime": 1517966744916,
        "encrypted": "false",
        "engineId": "13",
        "helpString": "WeTransfer - Online file transferring platform.",
        "ignoreConflict": false,
        "lastUpdateTime": 1517966744916,
        "longDescription": "WeTransfer - Online file transferring platform.",
        "name": "wetransfer",
        "nbarId": "1642",
        "p2pTechnology": "false",
        "popularity": 3,
        "rank": 65535,
        "references": "http://wetransfer.com",
        "selectorId": "712",
        "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
        "trafficClass": "TRANSACTIONAL_DATA",
        "tunnel": "false"
      }
    ],
    "networkIdentity": [
      {
        "id": "663775ab-0357-4a61-b339-f4586251a7f2",
        "instanceId": 17829,
        "authEntityId": 17829,
        "displayName": "17829",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "53,80,443,5353",
        "protocol": "TCP",
        "upperPort": 0
      },
      {
        "id": "dce80bcc-9ea4-40b4-9040-9a258a9dcd52",
        "instanceId": 17830,
        "authEntityId": 17830,
        "displayName": "17830",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "53,5353",
        "protocol": "UDP",
        "upperPort": 0
      }
    ],
    "parentScalableGroup": {
      "idRef": "28e08de4-d7b2-4521-84ce-bc1e5ee0b9cc"
    },
    "user": [

    ],
    "userGroup": [

    ]
  },
  {
    "id": "475b1748-de17-4fc3-b1ae-a15e6a46abd8",
    "instanceId": 4344,
    "authEntityId": 4344,
    "displayName": "4344",
    "authEntityClass": -1909073334,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "createTime": 1517966742348,
    "deployed": false,
    "isSeeded": true,
    "isStale": false,
    "lastUpdateTime": 1517966742348,
    "name": "decap",
    "namespace": "scalablegroup:application",
    "provisioningState": "DEFINED",
    "qualifier": "application",
    "resourceVersion": 0,
    "targetIdList": [

    ],
    "type": "scalablegroup",
    "cfsChangeInfo": [

    ],
    "customProvisions": [

    ],
    "scalableGroupExternalHandle": "decap",
    "scalableGroupType": "APPLICATION",
    "securityGroupTag": 0,
    "state": "ACTIVE",
    "vnAgnostic": true,
    "identitySource": {
      "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
      "instanceId": 10010,
      "authEntityId": 10010,
      "displayName": "10010",
      "authEntityClass": 1738953278,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "state": "INACTIVE",
      "type": "NBAR"
    },
    "indicativeNetworkIdentity": [
      {
        "id": "6e6cf34d-d2b3-413f-b065-4e8f7e3eff3e",
        "instanceId": 12808,
        "authEntityId": 12808,
        "displayName": "12808",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "403",
        "protocol": "TCP",
        "upperPort": 0
      },
      {
        "id": "d5f00c04-1242-42a7-bef0-19d1be165bfd",
        "instanceId": 12809,
        "authEntityId": 12809,
        "displayName": "12809",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "403",
        "protocol": "UDP",
        "upperPort": 0
      }
    ],
    "networkApplications": [
      {
        "id": "e992adc2-9a38-4d34-816f-de4761f5888e",
        "instanceId": 11295,
        "authEntityId": 11295,
        "displayName": "11295",
        "authEntityClass": -217092956,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "appProtocol": "tcp/udp",
        "applicationGroup": "other",
        "applicationType": "DEFAULT",
        "categoryId": "6daa3dfd-3526-407a-88f0-97824b3f3a24",
        "createTime": 1517966742348,
        "encrypted": "false",
        "engineId": "3",
        "helpString": "decap",
        "ignoreConflict": false,
        "lastUpdateTime": 1517966742348,
        "longDescription": "Registered with IANA on port 403 TCP/UDP",
        "name": "decap",
        "nbarId": "318",
        "p2pTechnology": "false",
        "popularity": 1,
        "rank": 65535,
        "references": "http://www.iana.org/assignments/service-names-port-numbers/service-names-port-numbers.xml",
        "selectorId": "403",
        "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
        "trafficClass": "BULK_DATA",
        "tunnel": "false"
      }
    ],
    "networkIdentity": [
      {
        "id": "50777305-98d2-4776-b6ee-028139328947",
        "instanceId": 12806,
        "authEntityId": 12806,
        "displayName": "12806",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "403",
        "protocol": "TCP",
        "upperPort": 0
      },
      {
        "id": "46990d48-bfa3-4e62-915d-edfe09e2bb0e",
        "instanceId": 12807,
        "authEntityId": 12807,
        "displayName": "12807",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "403",
        "protocol": "UDP",
        "upperPort": 0
      }
    ],
    "parentScalableGroup": {
      "idRef": "000c15bf-28fe-4fbb-9b69-35e6502721f1"
    },
    "user": [

    ],
    "userGroup": [

    ]
  },
  {
    "id": "4799268a-40f8-4ae6-8cf4-68ca4c12b6de",
    "instanceId": 4517,
    "authEntityId": 4517,
    "displayName": "4517",
    "authEntityClass": -1909073334,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "createTime": 1517966742866,
    "deployed": false,
    "isSeeded": true,
    "isStale": false,
    "lastUpdateTime": 1517966742866,
    "name": "gtalk-chat",
    "namespace": "scalablegroup:application",
    "provisioningState": "DEFINED",
    "qualifier": "application",
    "resourceVersion": 0,
    "targetIdList": [

    ],
    "type": "scalablegroup",
    "cfsChangeInfo": [

    ],
    "customProvisions": [

    ],
    "scalableGroupExternalHandle": "gtalk-chat",
    "scalableGroupType": "APPLICATION",
    "securityGroupTag": 0,
    "state": "ACTIVE",
    "vnAgnostic": true,
    "identitySource": {
      "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
      "instanceId": 10010,
      "authEntityId": 10010,
      "displayName": "10010",
      "authEntityClass": 1738953278,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "state": "INACTIVE",
      "type": "NBAR"
    },
    "indicativeNetworkIdentity": [

    ],
    "networkApplications": [
      {
        "id": "2b44937c-5745-4c0c-b42f-8bfd0c55e432",
        "instanceId": 11468,
        "authEntityId": 11468,
        "displayName": "11468",
        "authEntityClass": -217092956,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "appProtocol": "tcp/udp",
        "applicationGroup": "gtalk-group",
        "applicationType": "DEFAULT",
        "categoryId": "0f6cab47-be95-4124-9db3-360ccd55f983",
        "createTime": 1517966742866,
        "encrypted": "true",
        "engineId": "13",
        "helpString": "Protocol integrated into hangouts-chat",
        "ignoreConflict": false,
        "lastUpdateTime": 1517966742866,
        "longDescription": "Google Talk Chat is the instant messaging feature of Google Talk. The underlying protocol for Google Talk Chat is Extensible Messaging and Presence Protocol (XMPP), which allows users of other XMPP clients to communicate with Google Talk users. Google Talk is now being replaced by Google Hangouts",
        "name": "gtalk-chat",
        "nbarId": "1324",
        "p2pTechnology": "false",
        "popularity": 10,
        "rank": 65535,
        "references": "http://en.wikipedia.org/wiki/Google_talk",
        "selectorId": "464",
        "subCategory": "118c10a2-e272-47c1-92b9-4814ebe97138",
        "trafficClass": "TRANSACTIONAL_DATA",
        "tunnel": "false"
      }
    ],
    "networkIdentity": [
      {
        "id": "78105815-3308-480e-b059-994011a67c46",
        "instanceId": 13287,
        "authEntityId": 13287,
        "displayName": "13287",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "443,5222",
        "protocol": "TCP",
        "upperPort": 0
      },
      {
        "id": "ba828ac7-0a98-422f-8718-6260f0cef037",
        "instanceId": 13288,
        "authEntityId": 13288,
        "displayName": "13288",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "5222",
        "protocol": "UDP",
        "upperPort": 0
      }
    ],
    "parentScalableGroup": {
      "idRef": "fd6264c9-c16a-4311-a335-a3db039256f3"
    },
    "user": [

    ],
    "userGroup": [

    ]
  },
  {
    "id": "47a8a1a2-47d1-43a7-b7b0-21fa1f6e96a5",
    "instanceId": 4074,
    "authEntityId": 4074,
    "displayName": "4074",
    "authEntityClass": -1909073334,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "createTime": 1517966740844,
    "deployed": false,
    "isSeeded": true,
    "isStale": false,
    "lastUpdateTime": 1517966740844,
    "name": "alpes",
    "namespace": "scalablegroup:application",
    "provisioningState": "DEFINED",
    "qualifier": "application",
    "resourceVersion": 0,
    "targetIdList": [

    ],
    "type": "scalablegroup",
    "cfsChangeInfo": [

    ],
    "customProvisions": [

    ],
    "scalableGroupExternalHandle": "alpes",
    "scalableGroupType": "APPLICATION",
    "securityGroupTag": 0,
    "state": "ACTIVE",
    "vnAgnostic": true,
    "identitySource": {
      "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
      "instanceId": 10010,
      "authEntityId": 10010,
      "displayName": "10010",
      "authEntityClass": 1738953278,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "state": "INACTIVE",
      "type": "NBAR"
    },
    "indicativeNetworkIdentity": [
      {
        "id": "df76405e-c6b3-409c-88c5-4d8919b8c9a9",
        "instanceId": 12102,
        "authEntityId": 12102,
        "displayName": "12102",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "463",
        "protocol": "TCP",
        "upperPort": 0
      },
      {
        "id": "b0d3b0b2-6cc9-446d-977d-6207524f915c",
        "instanceId": 12103,
        "authEntityId": 12103,
        "displayName": "12103",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "463",
        "protocol": "UDP",
        "upperPort": 0
      }
    ],
    "networkApplications": [
      {
        "id": "a7696581-5511-4a01-92fb-ab781035b592",
        "instanceId": 11041,
        "authEntityId": 11041,
        "displayName": "11041",
        "authEntityClass": -217092956,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "appProtocol": "tcp/udp",
        "applicationGroup": "other",
        "applicationType": "DEFAULT",
        "categoryId": "1fc17362-9334-4c7b-b752-5afdbb574017",
        "createTime": 1517966740844,
        "encrypted": "false",
        "engineId": "3",
        "helpString": "Alpes",
        "ignoreConflict": false,
        "lastUpdateTime": 1517966740844,
        "longDescription": "ALPES is a client server protocol build on top of TCP. Its main goal is to secure the administration of a network of computers by transferring configuration text files between an information server and its clients and executing programs on them.",
        "name": "alpes",
        "nbarId": "377",
        "p2pTechnology": "false",
        "popularity": 1,
        "rank": 65535,
        "references": "http://tools.ietf.org/id/draft-durand-alpes-00.txt",
        "selectorId": "463",
        "subCategory": "787a7d77-977b-4974-89d3-379ba49e88b7",
        "trafficClass": "OPS_ADMIN_MGMT",
        "tunnel": "false"
      }
    ],
    "networkIdentity": [
      {
        "id": "6fae0e08-5247-43a8-9c85-4c4714bd788d",
        "instanceId": 12100,
        "authEntityId": 12100,
        "displayName": "12100",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "463",
        "protocol": "TCP",
        "upperPort": 0
      },
      {
        "id": "40bcf171-bfe3-4971-bfbc-68034916ced6",
        "instanceId": 12101,
        "authEntityId": 12101,
        "displayName": "12101",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "463",
        "protocol": "UDP",
        "upperPort": 0
      }
    ],
    "parentScalableGroup": {
      "idRef": "e06dd89f-0a70-4422-ab0b-c2314650a9a7"
    },
    "user": [

    ],
    "userGroup": [

    ]
  },
  {
    "id": "47ab8966-3eb2-4794-9c6b-80ebd85e0f67",
    "instanceId": 4416,
    "authEntityId": 4416,
    "displayName": "4416",
    "authEntityClass": -1909073334,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "createTime": 1517966742582,
    "deployed": false,
    "isSeeded": true,
    "isStale": false,
    "lastUpdateTime": 1517966742582,
    "name": "entrust-ash",
    "namespace": "scalablegroup:application",
    "provisioningState": "DEFINED",
    "qualifier": "application",
    "resourceVersion": 0,
    "targetIdList": [

    ],
    "type": "scalablegroup",
    "cfsChangeInfo": [

    ],
    "customProvisions": [

    ],
    "scalableGroupExternalHandle": "entrust-ash",
    "scalableGroupType": "APPLICATION",
    "securityGroupTag": 0,
    "state": "ACTIVE",
    "vnAgnostic": true,
    "identitySource": {
      "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
      "instanceId": 10010,
      "authEntityId": 10010,
      "displayName": "10010",
      "authEntityClass": 1738953278,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "state": "INACTIVE",
      "type": "NBAR"
    },
    "indicativeNetworkIdentity": [
      {
        "id": "c7fa5c63-4481-41c2-9d6c-a888c1a95aac",
        "instanceId": 13042,
        "authEntityId": 13042,
        "displayName": "13042",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "710",
        "protocol": "UDP",
        "upperPort": 0
      },
      {
        "id": "eaabf3e8-d2d1-463a-86d0-1032ba045fd9",
        "instanceId": 13043,
        "authEntityId": 13043,
        "displayName": "13043",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "710",
        "protocol": "TCP",
        "upperPort": 0
      }
    ],
    "networkApplications": [
      {
        "id": "c5c2e1e7-98c2-4f27-b235-f1014680284d",
        "instanceId": 11367,
        "authEntityId": 11367,
        "displayName": "11367",
        "authEntityClass": -217092956,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "appProtocol": "tcp/udp",
        "applicationGroup": "other",
        "applicationType": "DEFAULT",
        "categoryId": "38a8e354-e2fe-4f3c-b1c5-2bbb90fb0d99",
        "createTime": 1517966742582,
        "encrypted": "false",
        "engineId": "3",
        "helpString": "Entrust Administration Service Handler",
        "ignoreConflict": false,
        "lastUpdateTime": 1517966742582,
        "longDescription": "Registered with IANA on port 710 TCP/UDP",
        "name": "entrust-ash",
        "nbarId": "613",
        "p2pTechnology": "false",
        "popularity": 1,
        "rank": 65535,
        "references": "http://www.iana.org/assignments/service-names-port-numbers/service-names-port-numbers.xml",
        "selectorId": "710",
        "subCategory": "e390124a-0fd6-4253-b68e-4b0c1af8cb4b",
        "trafficClass": "OPS_ADMIN_MGMT",
        "tunnel": "false"
      }
    ],
    "networkIdentity": [
      {
        "id": "410d8809-0b46-4f3c-b479-9faa509ac3fa",
        "instanceId": 13040,
        "authEntityId": 13040,
        "displayName": "13040",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "710",
        "protocol": "UDP",
        "upperPort": 0
      },
      {
        "id": "c50f18b6-06a4-4e52-af6b-b68371dea38f",
        "instanceId": 13041,
        "authEntityId": 13041,
        "displayName": "13041",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "710",
        "protocol": "TCP",
        "upperPort": 0
      }
    ],
    "parentScalableGroup": {
      "idRef": "7788bf2d-e289-4597-b500-17aacb7e820e"
    },
    "user": [

    ],
    "userGroup": [

    ]
  },
  {
    "id": "47c92047-be7e-4aad-9480-cd678ecb47e4",
    "instanceId": 4872,
    "authEntityId": 4872,
    "displayName": "4872",
    "authEntityClass": -1909073334,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "createTime": 1517966743655,
    "deployed": false,
    "isSeeded": true,
    "isStale": false,
    "lastUpdateTime": 1517966743655,
    "name": "nntp",
    "namespace": "scalablegroup:application",
    "provisioningState": "DEFINED",
    "qualifier": "application",
    "resourceVersion": 0,
    "targetIdList": [

    ],
    "type": "scalablegroup",
    "cfsChangeInfo": [

    ],
    "customProvisions": [

    ],
    "scalableGroupExternalHandle": "nntp",
    "scalableGroupType": "APPLICATION",
    "securityGroupTag": 0,
    "state": "ACTIVE",
    "vnAgnostic": true,
    "identitySource": {
      "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
      "instanceId": 10010,
      "authEntityId": 10010,
      "displayName": "10010",
      "authEntityClass": 1738953278,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "state": "INACTIVE",
      "type": "NBAR"
    },
    "indicativeNetworkIdentity": [
      {
        "id": "05339c41-97b5-4ca2-9108-93edaa26cc0f",
        "instanceId": 14261,
        "authEntityId": 14261,
        "displayName": "14261",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "119",
        "protocol": "TCP",
        "upperPort": 0
      }
    ],
    "networkApplications": [
      {
        "id": "8b43c677-c8ab-4045-b495-aed0e81943d5",
        "instanceId": 11821,
        "authEntityId": 11821,
        "displayName": "11821",
        "authEntityClass": -217092956,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "appProtocol": "tcp",
        "applicationGroup": "nntp-group",
        "applicationType": "DEFAULT",
        "categoryId": "f6ec0221-b82d-43e8-a64d-163cbc13263b",
        "createTime": 1517966743655,
        "encrypted": "false",
        "engineId": "3",
        "helpString": "Network news transfer protocol",
        "ignoreConflict": false,
        "lastUpdateTime": 1517966743655,
        "longDescription": "Network News Transfer Protocol (NNTP) is an Internet transfer protocol used for reading and posting Usenet articles and transferring them between news servers. Usually the TCP port is 119, and NNTP over SSL TCP/UDP port is 563.",
        "name": "nntp",
        "nbarId": "28",
        "p2pTechnology": "false",
        "popularity": 8,
        "rank": 65535,
        "references": "http://tools.ietf.org/html/rfc3977",
        "selectorId": "119",
        "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
        "trafficClass": "BULK_DATA",
        "tunnel": "false"
      }
    ],
    "networkIdentity": [
      {
        "id": "ff07d73f-0ec5-4cd9-a969-24be5b9ede0c",
        "instanceId": 14260,
        "authEntityId": 14260,
        "displayName": "14260",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "119",
        "protocol": "TCP",
        "upperPort": 0
      }
    ],
    "parentScalableGroup": {
      "idRef": "28e08de4-d7b2-4521-84ce-bc1e5ee0b9cc"
    },
    "user": [

    ],
    "userGroup": [

    ]
  },
  {
    "id": "47d27fbc-3002-4a5a-9716-772e9d057d24",
    "instanceId": 4755,
    "authEntityId": 4755,
    "displayName": "4755",
    "authEntityClass": -1909073334,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "createTime": 1517966743428,
    "deployed": false,
    "isSeeded": true,
    "isStale": false,
    "lastUpdateTime": 1517966743428,
    "name": "mobileip-agent",
    "namespace": "scalablegroup:application",
    "provisioningState": "DEFINED",
    "qualifier": "application",
    "resourceVersion": 0,
    "targetIdList": [

    ],
    "type": "scalablegroup",
    "cfsChangeInfo": [

    ],
    "customProvisions": [

    ],
    "scalableGroupExternalHandle": "mobileip-agent",
    "scalableGroupType": "APPLICATION",
    "securityGroupTag": 0,
    "state": "ACTIVE",
    "vnAgnostic": true,
    "identitySource": {
      "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
      "instanceId": 10010,
      "authEntityId": 10010,
      "displayName": "10010",
      "authEntityClass": 1738953278,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "state": "INACTIVE",
      "type": "NBAR"
    },
    "indicativeNetworkIdentity": [
      {
        "id": "8701a4d3-4bc4-4ea7-89a1-2e0f2942a9e7",
        "instanceId": 13928,
        "authEntityId": 13928,
        "displayName": "13928",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "434",
        "protocol": "UDP",
        "upperPort": 0
      },
      {
        "id": "493e506b-d6f0-4d2c-a624-9cc5ea4a6f97",
        "instanceId": 13929,
        "authEntityId": 13929,
        "displayName": "13929",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "434",
        "protocol": "TCP",
        "upperPort": 0
      }
    ],
    "networkApplications": [
      {
        "id": "8dac9d71-9fdc-4f72-b6f4-a01963356b57",
        "instanceId": 11705,
        "authEntityId": 11705,
        "displayName": "11705",
        "authEntityClass": -217092956,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "appProtocol": "tcp/udp",
        "applicationGroup": "other",
        "applicationType": "DEFAULT",
        "categoryId": "1fc17362-9334-4c7b-b752-5afdbb574017",
        "createTime": 1517966743428,
        "encrypted": "false",
        "engineId": "3",
        "helpString": "mobileip-agent",
        "ignoreConflict": false,
        "lastUpdateTime": 1517966743428,
        "longDescription": "Mobile IP (or IP mobility) is an Internet Engineering Task Force(IETF) standard communications protocol that is designed to allow mobile device users to move from one network to another while maintaining a permanent IP address.",
        "name": "mobileip-agent",
        "nbarId": "349",
        "p2pTechnology": "false",
        "popularity": 1,
        "rank": 65535,
        "references": "http://tools.ietf.org/html/rfc5944",
        "selectorId": "434",
        "subCategory": "7cc1b3a6-4102-44e9-b522-0e71afe39a03",
        "trafficClass": "NETWORK_CONTROL",
        "tunnel": "false"
      }
    ],
    "networkIdentity": [
      {
        "id": "b94bfaf1-0536-48ab-9518-c5a8a9becd28",
        "instanceId": 13926,
        "authEntityId": 13926,
        "displayName": "13926",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "434",
        "protocol": "UDP",
        "upperPort": 0
      },
      {
        "id": "7ae02577-e050-4f4a-9ca0-6a04ea7e8c48",
        "instanceId": 13927,
        "authEntityId": 13927,
        "displayName": "13927",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "434",
        "protocol": "TCP",
        "upperPort": 0
      }
    ],
    "parentScalableGroup": {
      "idRef": "77e75842-e98b-4112-ad0c-300db4bb11be"
    },
    "user": [

    ],
    "userGroup": [

    ]
  },
  {
    "id": "47e4cfbc-c8e5-41bc-8e8c-1f60d190c5fc",
    "instanceId": 15231,
    "authEntityId": 15231,
    "displayName": "15231",
    "authEntityClass": -1909073334,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "createTime": 1517966744543,
    "deployed": false,
    "isSeeded": true,
    "isStale": false,
    "lastUpdateTime": 1517966744543,
    "name": "sun-nd",
    "namespace": "scalablegroup:application",
    "provisioningState": "DEFINED",
    "qualifier": "application",
    "resourceVersion": 0,
    "targetIdList": [

    ],
    "type": "scalablegroup",
    "cfsChangeInfo": [

    ],
    "customProvisions": [

    ],
    "scalableGroupExternalHandle": "sun-nd",
    "scalableGroupType": "APPLICATION",
    "securityGroupTag": 0,
    "state": "ACTIVE",
    "vnAgnostic": true,
    "identitySource": {
      "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
      "instanceId": 10010,
      "authEntityId": 10010,
      "displayName": "10010",
      "authEntityClass": 1738953278,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "state": "INACTIVE",
      "type": "NBAR"
    },
    "indicativeNetworkIdentity": [
      {
        "id": "58d4d68c-6519-49b7-9e02-3e0b9ce9c6ff",
        "instanceId": 17327,
        "authEntityId": 17327,
        "displayName": "17327",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipProtocolNumbers": "77",
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "protocol": "SUN_ND",
        "upperPort": 0
      }
    ],
    "networkApplications": [
      {
        "id": "d602e7ff-0fd3-4455-a81b-98d1123a70ad",
        "instanceId": 16174,
        "authEntityId": 16174,
        "displayName": "16174",
        "authEntityClass": -217092956,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "appProtocol": "ip",
        "applicationGroup": "other",
        "applicationType": "DEFAULT",
        "categoryId": "6daa3dfd-3526-407a-88f0-97824b3f3a24",
        "createTime": 1517966744542,
        "encrypted": "unassigned",
        "engineId": "1",
        "helpString": "SUN ND PROTOCOL-Temporary",
        "ignoreConflict": false,
        "lastUpdateTime": 1517966744542,
        "longDescription": "Registered with IANA as IP Protocol 77",
        "name": "sun-nd",
        "nbarId": "831",
        "p2pTechnology": "unassigned",
        "popularity": 1,
        "rank": 65535,
        "references": "http://www.iana.org/assignments/protocol-numbers/protocol-numbers.xml",
        "selectorId": "77",
        "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
        "trafficClass": "BULK_DATA",
        "tunnel": "unassigned"
      }
    ],
    "networkIdentity": [
      {
        "id": "bf40f3b1-903f-4c17-8e2b-d23f842dca1c",
        "instanceId": 17326,
        "authEntityId": 17326,
        "displayName": "17326",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipProtocolNumbers": "77",
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "protocol": "SUN_ND",
        "upperPort": 0
      }
    ],
    "parentScalableGroup": {
      "idRef": "000c15bf-28fe-4fbb-9b69-35e6502721f1"
    },
    "user": [

    ],
    "userGroup": [

    ]
  },
  {
    "id": "4815974d-276e-4892-b729-e2443729dae9",
    "instanceId": 4264,
    "authEntityId": 4264,
    "displayName": "4264",
    "authEntityClass": -1909073334,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "createTime": 1517966741982,
    "deployed": false,
    "isSeeded": true,
    "isStale": false,
    "lastUpdateTime": 1517966741982,
    "name": "clearcase",
    "namespace": "scalablegroup:application",
    "provisioningState": "DEFINED",
    "qualifier": "application",
    "resourceVersion": 0,
    "targetIdList": [

    ],
    "type": "scalablegroup",
    "cfsChangeInfo": [

    ],
    "customProvisions": [

    ],
    "scalableGroupExternalHandle": "clearcase",
    "scalableGroupType": "APPLICATION",
    "securityGroupTag": 0,
    "state": "ACTIVE",
    "vnAgnostic": true,
    "identitySource": {
      "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
      "instanceId": 10010,
      "authEntityId": 10010,
      "displayName": "10010",
      "authEntityClass": 1738953278,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "state": "INACTIVE",
      "type": "NBAR"
    },
    "indicativeNetworkIdentity": [
      {
        "id": "01b40eed-f88c-48d1-8851-350f4b84f011",
        "instanceId": 12564,
        "authEntityId": 12564,
        "displayName": "12564",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "371",
        "protocol": "TCP",
        "upperPort": 0
      },
      {
        "id": "8c9018a7-1c1f-4d62-a4d4-3945fb2ef8d0",
        "instanceId": 12565,
        "authEntityId": 12565,
        "displayName": "12565",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "371",
        "protocol": "UDP",
        "upperPort": 0
      }
    ],
    "networkApplications": [
      {
        "id": "83926f3e-1cf2-459f-b79f-34520fcf8be7",
        "instanceId": 11217,
        "authEntityId": 11217,
        "displayName": "11217",
        "authEntityClass": -217092956,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "appProtocol": "tcp/udp",
        "applicationGroup": "other",
        "applicationType": "DEFAULT",
        "categoryId": "7e19fdea-6222-4c57-9e66-66c2340d6bc3",
        "createTime": 1517966741982,
        "encrypted": "false",
        "engineId": "3",
        "helpString": "Rational ClearCase",
        "ignoreConflict": false,
        "lastUpdateTime": 1517966741982,
        "longDescription": "Rational ClearCase is a software configuration management system that provides version control, workspace management, parallel development support and build auditing.",
        "name": "clearcase",
        "nbarId": "91",
        "p2pTechnology": "false",
        "popularity": 8,
        "rank": 65535,
        "references": "http://www-01.ibm.com/software/awdtools/clearcase/",
        "selectorId": "371",
        "subCategory": "b90786f4-0190-45b8-9924-21b3e9682590",
        "trafficClass": "TRANSACTIONAL_DATA",
        "tunnel": "false"
      }
    ],
    "networkIdentity": [
      {
        "id": "4da6a850-709d-4c7f-a16c-bee8d4ae1111",
        "instanceId": 12562,
        "authEntityId": 12562,
        "displayName": "12562",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "111,371,2049",
        "protocol": "TCP",
        "upperPort": 0
      },
      {
        "id": "6f5bf8d4-24fa-4b7e-9fa7-3d99c0183a5c",
        "instanceId": 12563,
        "authEntityId": 12563,
        "displayName": "12563",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "111,371,2049",
        "protocol": "UDP",
        "upperPort": 0
      }
    ],
    "parentScalableGroup": {
      "idRef": "fdbaf614-da09-4ba2-89bb-d1ca9e379597"
    },
    "user": [

    ],
    "userGroup": [

    ]
  },
  {
    "id": "482201f1-2e6b-4f56-a1d1-397668f7d672",
    "instanceId": 15388,
    "authEntityId": 15388,
    "displayName": "15388",
    "authEntityClass": -1909073334,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "createTime": 1517966744855,
    "deployed": false,
    "isSeeded": true,
    "isStale": false,
    "lastUpdateTime": 1517966744855,
    "name": "washington-post",
    "namespace": "scalablegroup:application",
    "provisioningState": "DEFINED",
    "qualifier": "application",
    "resourceVersion": 0,
    "targetIdList": [

    ],
    "type": "scalablegroup",
    "cfsChangeInfo": [

    ],
    "customProvisions": [

    ],
    "scalableGroupExternalHandle": "washington-post",
    "scalableGroupType": "APPLICATION",
    "securityGroupTag": 0,
    "state": "ACTIVE",
    "vnAgnostic": true,
    "identitySource": {
      "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
      "instanceId": 10010,
      "authEntityId": 10010,
      "displayName": "10010",
      "authEntityClass": 1738953278,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "state": "INACTIVE",
      "type": "NBAR"
    },
    "indicativeNetworkIdentity": [

    ],
    "networkApplications": [
      {
        "id": "e1b98e33-18f0-4390-b71e-f61667f56fee",
        "instanceId": 16331,
        "authEntityId": 16331,
        "displayName": "16331",
        "authEntityClass": -217092956,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "appProtocol": "tcp/udp",
        "applicationGroup": "other",
        "applicationType": "DEFAULT",
        "categoryId": "878644b5-fe91-45b4-9204-31c84a73ba48",
        "createTime": 1517966744855,
        "encrypted": "false",
        "engineId": "13",
        "helpString": "The Washington Post - American daily newspaper.",
        "ignoreConflict": false,
        "lastUpdateTime": 1517966744855,
        "longDescription": "The Washington Post - American daily newspaper.",
        "name": "washington-post",
        "nbarId": "1585",
        "p2pTechnology": "false",
        "popularity": 3,
        "rank": 65535,
        "references": "http://washingtonpost.com",
        "selectorId": "656",
        "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
        "trafficClass": "TRANSACTIONAL_DATA",
        "tunnel": "false"
      }
    ],
    "networkIdentity": [
      {
        "id": "3f1d1ed2-9c3c-49f9-94b8-b7c677c89b24",
        "instanceId": 17789,
        "authEntityId": 17789,
        "displayName": "17789",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "53,80,443,5353",
        "protocol": "TCP",
        "upperPort": 0
      },
      {
        "id": "cd28bee3-db6f-4a72-a2d5-c2af1864c260",
        "instanceId": 17790,
        "authEntityId": 17790,
        "displayName": "17790",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "53,5353",
        "protocol": "UDP",
        "upperPort": 0
      }
    ],
    "parentScalableGroup": {
      "idRef": "28e08de4-d7b2-4521-84ce-bc1e5ee0b9cc"
    },
    "user": [

    ],
    "userGroup": [

    ]
  },
  {
    "id": "485a93e6-024f-42cf-91b6-c25bb1a56f78",
    "instanceId": 15453,
    "authEntityId": 15453,
    "displayName": "15453",
    "authEntityClass": -1909073334,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "createTime": 1517966744992,
    "deployed": false,
    "isSeeded": true,
    "isStale": false,
    "lastUpdateTime": 1517966744992,
    "name": "yahoo",
    "namespace": "scalablegroup:application",
    "provisioningState": "DEFINED",
    "qualifier": "application",
    "resourceVersion": 0,
    "targetIdList": [

    ],
    "type": "scalablegroup",
    "cfsChangeInfo": [

    ],
    "customProvisions": [

    ],
    "scalableGroupExternalHandle": "yahoo",
    "scalableGroupType": "APPLICATION",
    "securityGroupTag": 0,
    "state": "ACTIVE",
    "vnAgnostic": true,
    "identitySource": {
      "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
      "instanceId": 10010,
      "authEntityId": 10010,
      "displayName": "10010",
      "authEntityClass": 1738953278,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "state": "INACTIVE",
      "type": "NBAR"
    },
    "indicativeNetworkIdentity": [

    ],
    "networkApplications": [
      {
        "id": "91ff7c0c-c922-494d-bcb4-5f045ada49fb",
        "instanceId": 16396,
        "authEntityId": 16396,
        "displayName": "16396",
        "authEntityClass": -217092956,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "appProtocol": "tcp/udp",
        "applicationGroup": "other",
        "applicationType": "DEFAULT",
        "categoryId": "878644b5-fe91-45b4-9204-31c84a73ba48",
        "createTime": 1517966744992,
        "encrypted": "true",
        "engineId": "13",
        "helpString": "Yahoo",
        "ignoreConflict": false,
        "lastUpdateTime": 1517966744992,
        "longDescription": "yahoo.com site browsing",
        "name": "yahoo",
        "nbarId": "1761",
        "p2pTechnology": "false",
        "popularity": 7,
        "rank": 65535,
        "references": "http://www.yahoo.com",
        "selectorId": "831",
        "subCategory": "aab99e59-8589-4a61-933e-f01bff556d78",
        "trafficClass": "BULK_DATA",
        "tunnel": "false"
      }
    ],
    "networkIdentity": [
      {
        "id": "e074b536-cf01-44c5-a44f-6325cebd3663",
        "instanceId": 17943,
        "authEntityId": 17943,
        "displayName": "17943",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "53,80,443,5353",
        "protocol": "TCP",
        "upperPort": 0
      },
      {
        "id": "7189ed0e-9a77-4467-8b77-a3ddd4aa0270",
        "instanceId": 17944,
        "authEntityId": 17944,
        "displayName": "17944",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "53,5353",
        "protocol": "UDP",
        "upperPort": 0
      }
    ],
    "parentScalableGroup": {
      "idRef": "28e08de4-d7b2-4521-84ce-bc1e5ee0b9cc"
    },
    "user": [

    ],
    "userGroup": [

    ]
  },
  {
    "id": "48a628b9-1067-498b-9c6c-646e4653e05a",
    "instanceId": 4926,
    "authEntityId": 4926,
    "displayName": "4926",
    "authEntityClass": -1909073334,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "createTime": 1517966743783,
    "deployed": false,
    "isSeeded": true,
    "isStale": false,
    "lastUpdateTime": 1517966743783,
    "name": "orbix-locator",
    "namespace": "scalablegroup:application",
    "provisioningState": "DEFINED",
    "qualifier": "application",
    "resourceVersion": 0,
    "targetIdList": [

    ],
    "type": "scalablegroup",
    "cfsChangeInfo": [

    ],
    "customProvisions": [

    ],
    "scalableGroupExternalHandle": "orbix-locator",
    "scalableGroupType": "APPLICATION",
    "securityGroupTag": 0,
    "state": "ACTIVE",
    "vnAgnostic": true,
    "identitySource": {
      "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
      "instanceId": 10010,
      "authEntityId": 10010,
      "displayName": "10010",
      "authEntityClass": 1738953278,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "state": "INACTIVE",
      "type": "NBAR"
    },
    "indicativeNetworkIdentity": [
      {
        "id": "a7d252ab-650a-4570-a7cd-5063090704dd",
        "instanceId": 14448,
        "authEntityId": 14448,
        "displayName": "14448",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "3075",
        "protocol": "UDP",
        "upperPort": 0
      },
      {
        "id": "e0436c6e-52fa-4211-ac7b-d01f03ea50af",
        "instanceId": 14447,
        "authEntityId": 14447,
        "displayName": "14447",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "3075",
        "protocol": "TCP",
        "upperPort": 0
      }
    ],
    "networkApplications": [
      {
        "id": "28747f76-445b-4063-8b56-e740ca3c3a61",
        "instanceId": 11875,
        "authEntityId": 11875,
        "displayName": "11875",
        "authEntityClass": -217092956,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "appProtocol": "tcp/udp",
        "applicationGroup": "corba-group",
        "applicationType": "DEFAULT",
        "categoryId": "58aa61dc-dd95-4a6d-9c7c-0ebf16da6dde",
        "createTime": 1517966743782,
        "encrypted": "false",
        "engineId": "3",
        "helpString": "Orbix 2000 Locator",
        "ignoreConflict": false,
        "lastUpdateTime": 1517966743782,
        "longDescription": "Used by Progree Software Corporation in their Orbix software for enterprise COBRA solutions.",
        "name": "orbix-locator",
        "nbarId": "704",
        "p2pTechnology": "false",
        "popularity": 1,
        "rank": 65535,
        "references": "http://www.progress.com/en/orbix/index.html",
        "selectorId": "3075",
        "subCategory": "b90786f4-0190-45b8-9924-21b3e9682590",
        "trafficClass": "TRANSACTIONAL_DATA",
        "tunnel": "false"
      }
    ],
    "networkIdentity": [
      {
        "id": "a0627cd5-b8fc-4f7f-932c-6af3f5e338fd",
        "instanceId": 14445,
        "authEntityId": 14445,
        "displayName": "14445",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "3075",
        "protocol": "UDP",
        "upperPort": 0
      },
      {
        "id": "be31f6fb-7ebc-4ff5-bb37-f26dfce07291",
        "instanceId": 14446,
        "authEntityId": 14446,
        "displayName": "14446",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "3075",
        "protocol": "TCP",
        "upperPort": 0
      }
    ],
    "parentScalableGroup": {
      "idRef": "cff11752-fafb-4e22-9e6f-11f44f59a9a3"
    },
    "user": [

    ],
    "userGroup": [

    ]
  },
  {
    "id": "48eeeb64-ac4d-43da-8a56-8f42bad765e4",
    "instanceId": 4923,
    "authEntityId": 4923,
    "displayName": "4923",
    "authEntityClass": -1909073334,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "createTime": 1517966743775,
    "deployed": false,
    "isSeeded": true,
    "isStale": false,
    "lastUpdateTime": 1517966743775,
    "name": "orbix-cfg-ssl",
    "namespace": "scalablegroup:application",
    "provisioningState": "DEFINED",
    "qualifier": "application",
    "resourceVersion": 0,
    "targetIdList": [

    ],
    "type": "scalablegroup",
    "cfsChangeInfo": [

    ],
    "customProvisions": [

    ],
    "scalableGroupExternalHandle": "orbix-cfg-ssl",
    "scalableGroupType": "APPLICATION",
    "securityGroupTag": 0,
    "state": "ACTIVE",
    "vnAgnostic": true,
    "identitySource": {
      "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
      "instanceId": 10010,
      "authEntityId": 10010,
      "displayName": "10010",
      "authEntityClass": 1738953278,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "state": "INACTIVE",
      "type": "NBAR"
    },
    "indicativeNetworkIdentity": [
      {
        "id": "023ee61a-7a9c-4283-a9b8-adf77fa6750b",
        "instanceId": 14435,
        "authEntityId": 14435,
        "displayName": "14435",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "3078",
        "protocol": "UDP",
        "upperPort": 0
      },
      {
        "id": "cc0e2557-2603-4e42-a900-b75d60824a4b",
        "instanceId": 14436,
        "authEntityId": 14436,
        "displayName": "14436",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "3078",
        "protocol": "TCP",
        "upperPort": 0
      }
    ],
    "networkApplications": [
      {
        "id": "46aa376b-39bd-4d9d-adb1-ec64eb114647",
        "instanceId": 11872,
        "authEntityId": 11872,
        "displayName": "11872",
        "authEntityClass": -217092956,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "appProtocol": "tcp/udp",
        "applicationGroup": "corba-group",
        "applicationType": "DEFAULT",
        "categoryId": "58aa61dc-dd95-4a6d-9c7c-0ebf16da6dde",
        "createTime": 1517966743775,
        "encrypted": "true",
        "engineId": "3",
        "helpString": "Orbix 2000 Locator configuration over SSL",
        "ignoreConflict": false,
        "lastUpdateTime": 1517966743775,
        "longDescription": "Orbix is a CORBA ORB (Object Request Broker) from Micro Focus  which helps programmers build distributed applications. Orbix  cfg (config) works over SSL typically on port 3078.",
        "name": "orbix-cfg-ssl",
        "nbarId": "1600",
        "p2pTechnology": "false",
        "popularity": 6,
        "rank": 65535,
        "references": "http://en.wikipedia.org/wiki/Orbix_(software)",
        "selectorId": "3078",
        "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
        "trafficClass": "TRANSACTIONAL_DATA",
        "tunnel": "false"
      }
    ],
    "networkIdentity": [
      {
        "id": "db655f6a-0b95-491e-af5c-fe5888824437",
        "instanceId": 14433,
        "authEntityId": 14433,
        "displayName": "14433",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "3078",
        "protocol": "TCP",
        "upperPort": 0
      },
      {
        "id": "6ba9f8c4-5193-4be6-b461-cf54f5131f73",
        "instanceId": 14434,
        "authEntityId": 14434,
        "displayName": "14434",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "3078",
        "protocol": "UDP",
        "upperPort": 0
      }
    ],
    "parentScalableGroup": {
      "idRef": "cff11752-fafb-4e22-9e6f-11f44f59a9a3"
    },
    "user": [

    ],
    "userGroup": [

    ]
  },
  {
    "id": "4969141f-9339-403d-8533-5c0e36c4391d",
    "instanceId": 4146,
    "authEntityId": 4146,
    "displayName": "4146",
    "authEntityClass": -1909073334,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "createTime": 1517966741378,
    "deployed": false,
    "isSeeded": true,
    "isStale": false,
    "lastUpdateTime": 1517966741378,
    "name": "auth",
    "namespace": "scalablegroup:application",
    "provisioningState": "DEFINED",
    "qualifier": "application",
    "resourceVersion": 0,
    "targetIdList": [

    ],
    "type": "scalablegroup",
    "cfsChangeInfo": [

    ],
    "customProvisions": [

    ],
    "scalableGroupExternalHandle": "auth",
    "scalableGroupType": "APPLICATION",
    "securityGroupTag": 0,
    "state": "ACTIVE",
    "vnAgnostic": true,
    "identitySource": {
      "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
      "instanceId": 10010,
      "authEntityId": 10010,
      "displayName": "10010",
      "authEntityClass": 1738953278,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "state": "INACTIVE",
      "type": "NBAR"
    },
    "indicativeNetworkIdentity": [
      {
        "id": "39bae9eb-20d5-403c-b6bf-f6e752fd83eb",
        "instanceId": 12300,
        "authEntityId": 12300,
        "displayName": "12300",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "113",
        "protocol": "TCP",
        "upperPort": 0
      },
      {
        "id": "8632b5ae-0cec-414b-87e7-90c3a670d363",
        "instanceId": 12301,
        "authEntityId": 12301,
        "displayName": "12301",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "113",
        "protocol": "UDP",
        "upperPort": 0
      }
    ],
    "networkApplications": [
      {
        "id": "a5604aad-6138-4d98-9ef1-1f1b1c0d0053",
        "instanceId": 11107,
        "authEntityId": 11107,
        "displayName": "11107",
        "authEntityClass": -217092956,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "appProtocol": "tcp/udp",
        "applicationGroup": "other",
        "applicationType": "DEFAULT",
        "categoryId": "1fc17362-9334-4c7b-b752-5afdbb574017",
        "createTime": 1517966741378,
        "encrypted": "false",
        "engineId": "3",
        "helpString": "Authentication Service",
        "ignoreConflict": false,
        "lastUpdateTime": 1517966741378,
        "longDescription": "The Identification Protocol (formerly called the Authentication Server Protocol) provides a means to determine the identity of a user of a particular TCP connection.  Given a TCP port number pair, it returns a character string which identifies the owner of that connection on the server's system.",
        "name": "auth",
        "nbarId": "983",
        "p2pTechnology": "false",
        "popularity": 1,
        "rank": 65535,
        "references": "http://www.ietf.org/rfc/rfc1413.txt",
        "selectorId": "113",
        "subCategory": "f3c0f761-d894-4112-ab89-b90fc84fd660",
        "trafficClass": "OPS_ADMIN_MGMT",
        "tunnel": "false"
      }
    ],
    "networkIdentity": [
      {
        "id": "334bd291-f258-40d5-82d2-0e9c57bbbd47",
        "instanceId": 12298,
        "authEntityId": 12298,
        "displayName": "12298",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "113",
        "protocol": "UDP",
        "upperPort": 0
      },
      {
        "id": "1ba1f569-bf18-414e-8360-8166f150002c",
        "instanceId": 12299,
        "authEntityId": 12299,
        "displayName": "12299",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "113",
        "protocol": "TCP",
        "upperPort": 0
      }
    ],
    "parentScalableGroup": {
      "idRef": "5fcd74d4-8726-47f7-a088-c457863da7ef"
    },
    "user": [

    ],
    "userGroup": [

    ]
  },
  {
    "id": "49de9fc8-feea-42c3-8569-9aec91bcb418",
    "instanceId": 15142,
    "authEntityId": 15142,
    "displayName": "15142",
    "authEntityClass": -1909073334,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "createTime": 1517966744330,
    "deployed": false,
    "isSeeded": true,
    "isStale": false,
    "lastUpdateTime": 1517966744330,
    "name": "sift-uft",
    "namespace": "scalablegroup:application",
    "provisioningState": "DEFINED",
    "qualifier": "application",
    "resourceVersion": 0,
    "targetIdList": [

    ],
    "type": "scalablegroup",
    "cfsChangeInfo": [

    ],
    "customProvisions": [

    ],
    "scalableGroupExternalHandle": "sift-uft",
    "scalableGroupType": "APPLICATION",
    "securityGroupTag": 0,
    "state": "ACTIVE",
    "vnAgnostic": true,
    "identitySource": {
      "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
      "instanceId": 10010,
      "authEntityId": 10010,
      "displayName": "10010",
      "authEntityClass": 1738953278,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "state": "INACTIVE",
      "type": "NBAR"
    },
    "indicativeNetworkIdentity": [
      {
        "id": "1ccbd39f-4d79-440e-a4ad-3f8c6d80ca17",
        "instanceId": 17074,
        "authEntityId": 17074,
        "displayName": "17074",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "608",
        "protocol": "UDP",
        "upperPort": 0
      },
      {
        "id": "4acb4fe5-a8d8-4f15-9b88-fbd986aa4b8f",
        "instanceId": 17075,
        "authEntityId": 17075,
        "displayName": "17075",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "608",
        "protocol": "TCP",
        "upperPort": 0
      }
    ],
    "networkApplications": [
      {
        "id": "0d387813-9bb7-44f5-8476-2b217bc78ec7",
        "instanceId": 16085,
        "authEntityId": 16085,
        "displayName": "16085",
        "authEntityClass": -217092956,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "appProtocol": "tcp/udp",
        "applicationGroup": "other",
        "applicationType": "DEFAULT",
        "categoryId": "e6619ce8-97de-4c63-a53a-cceddbddc1a8",
        "createTime": 1517966744330,
        "encrypted": "false",
        "engineId": "3",
        "helpString": "Sender-Initiated/Unsolicited File Transfer",
        "ignoreConflict": false,
        "lastUpdateTime": 1517966744330,
        "longDescription": "Sender-Initiated File Transfer (SIFT) protocol, also commonly called Unsolicited File Transfer (UFT) protocol is a file transfer protocol. It's method  contrasts with other file transfer methods in that the sender need not have an account or any registration on the target host system, and the receiving user may have less steps to take to retrieve the file(s) sent.  Unlike traditional file transfer, UFT lends itself handily to background ordeferred operation, though it may be carried out immediately, eveninteractively.",
        "name": "sift-uft",
        "nbarId": "517",
        "p2pTechnology": "false",
        "popularity": 1,
        "rank": 65535,
        "references": "http://tools.ietf.org/html/rfc1440",
        "selectorId": "608",
        "subCategory": "50b55e2e-d0d0-4404-bea4-819351565d90",
        "trafficClass": "BULK_DATA",
        "tunnel": "false"
      }
    ],
    "networkIdentity": [
      {
        "id": "59dec334-34bd-47f6-bf4b-609ebbc1d81c",
        "instanceId": 17072,
        "authEntityId": 17072,
        "displayName": "17072",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "608",
        "protocol": "TCP",
        "upperPort": 0
      },
      {
        "id": "ae533aec-0851-4e2b-b04e-439a289b0d5b",
        "instanceId": 17073,
        "authEntityId": 17073,
        "displayName": "17073",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "608",
        "protocol": "UDP",
        "upperPort": 0
      }
    ],
    "parentScalableGroup": {
      "idRef": "383363b1-4dd5-49d1-bfe4-34daac437cfb"
    },
    "user": [

    ],
    "userGroup": [

    ]
  },
  {
    "id": "4a09793a-bd57-4230-a135-8112fe7870fd",
    "instanceId": 4076,
    "authEntityId": 4076,
    "displayName": "4076",
    "authEntityClass": -1909073334,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "createTime": 1517966740852,
    "deployed": false,
    "isSeeded": true,
    "isStale": false,
    "lastUpdateTime": 1517966740852,
    "name": "amazon",
    "namespace": "scalablegroup:application",
    "provisioningState": "DEFINED",
    "qualifier": "application",
    "resourceVersion": 0,
    "targetIdList": [

    ],
    "type": "scalablegroup",
    "cfsChangeInfo": [

    ],
    "customProvisions": [

    ],
    "scalableGroupExternalHandle": "amazon",
    "scalableGroupType": "APPLICATION",
    "securityGroupTag": 0,
    "state": "ACTIVE",
    "vnAgnostic": true,
    "identitySource": {
      "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
      "instanceId": 10010,
      "authEntityId": 10010,
      "displayName": "10010",
      "authEntityClass": 1738953278,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "state": "INACTIVE",
      "type": "NBAR"
    },
    "indicativeNetworkIdentity": [

    ],
    "networkApplications": [
      {
        "id": "27b5299d-3ab3-4909-849e-665c5bd153ec",
        "instanceId": 11043,
        "authEntityId": 11043,
        "displayName": "11043",
        "authEntityClass": -217092956,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "appProtocol": "tcp/udp",
        "applicationGroup": "other",
        "applicationType": "DEFAULT",
        "categoryId": "878644b5-fe91-45b4-9204-31c84a73ba48",
        "createTime": 1517966740852,
        "encrypted": "true",
        "engineId": "13",
        "helpString": "Amazon",
        "ignoreConflict": false,
        "lastUpdateTime": 1517966740852,
        "longDescription": "amazon.com site browsing",
        "name": "amazon",
        "nbarId": "1760",
        "p2pTechnology": "false",
        "popularity": 7,
        "rank": 65535,
        "references": "http://www.amazon.com",
        "selectorId": "830",
        "subCategory": "aab99e59-8589-4a61-933e-f01bff556d78",
        "trafficClass": "BULK_DATA",
        "tunnel": "false"
      }
    ],
    "networkIdentity": [
      {
        "id": "4e4ed781-46ee-46be-9002-194e8f9a46a6",
        "instanceId": 12108,
        "authEntityId": 12108,
        "displayName": "12108",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "53,5353",
        "protocol": "UDP",
        "upperPort": 0
      },
      {
        "id": "fd382e9c-35c4-46f7-809c-13bc1d0ce298",
        "instanceId": 12109,
        "authEntityId": 12109,
        "displayName": "12109",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "53,80,443,5353",
        "protocol": "TCP",
        "upperPort": 0
      }
    ],
    "parentScalableGroup": {
      "idRef": "28e08de4-d7b2-4521-84ce-bc1e5ee0b9cc"
    },
    "user": [

    ],
    "userGroup": [

    ]
  },
  {
    "id": "4b2f58da-3b43-4a3c-825b-1c8b9c74e6e3",
    "instanceId": 4571,
    "authEntityId": 4571,
    "displayName": "4571",
    "authEntityClass": -1909073334,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "createTime": 1517966743017,
    "deployed": false,
    "isSeeded": true,
    "isStale": false,
    "lastUpdateTime": 1517966743017,
    "name": "iafserver",
    "namespace": "scalablegroup:application",
    "provisioningState": "DEFINED",
    "qualifier": "application",
    "resourceVersion": 0,
    "targetIdList": [

    ],
    "type": "scalablegroup",
    "cfsChangeInfo": [

    ],
    "customProvisions": [

    ],
    "scalableGroupExternalHandle": "iafserver",
    "scalableGroupType": "APPLICATION",
    "securityGroupTag": 0,
    "state": "ACTIVE",
    "vnAgnostic": true,
    "identitySource": {
      "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
      "instanceId": 10010,
      "authEntityId": 10010,
      "displayName": "10010",
      "authEntityClass": 1738953278,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "state": "INACTIVE",
      "type": "NBAR"
    },
    "indicativeNetworkIdentity": [
      {
        "id": "a36e609b-cc38-4b32-b143-23d92c2396d6",
        "instanceId": 13427,
        "authEntityId": 13427,
        "displayName": "13427",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "479",
        "protocol": "UDP",
        "upperPort": 0
      },
      {
        "id": "9304b950-2bfe-424a-a0b1-a9bd3b63c222",
        "instanceId": 13428,
        "authEntityId": 13428,
        "displayName": "13428",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "479",
        "protocol": "TCP",
        "upperPort": 0
      }
    ],
    "networkApplications": [
      {
        "id": "63c6dc5a-f4fc-481f-a986-bc0a2cd9fd99",
        "instanceId": 11521,
        "authEntityId": 11521,
        "displayName": "11521",
        "authEntityClass": -217092956,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "appProtocol": "tcp/udp",
        "applicationGroup": "other",
        "applicationType": "DEFAULT",
        "categoryId": "6daa3dfd-3526-407a-88f0-97824b3f3a24",
        "createTime": 1517966743017,
        "encrypted": "false",
        "engineId": "3",
        "helpString": "iafserver",
        "ignoreConflict": false,
        "lastUpdateTime": 1517966743017,
        "longDescription": "IAFServer is part of the Integrated Authentication Framework (IAF), a token-based infrastructure that enables Software AG's enterprise single sign-on. In addition, it allows usage of a configurable authentication system (user database) with Software AG products across platforms.",
        "name": "iafserver",
        "nbarId": "393",
        "p2pTechnology": "false",
        "popularity": 1,
        "rank": 65535,
        "references": "http://documentation.softwareag.com/webmethods/wmsuites/wmsuite8-2_ga/EntireX/8-2-SP1_EntireX/security/iaf.htm",
        "selectorId": "479",
        "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
        "trafficClass": "BULK_DATA",
        "tunnel": "false"
      }
    ],
    "networkIdentity": [
      {
        "id": "cbebe9fc-ed00-429d-9d09-8eacd401ccd3",
        "instanceId": 13425,
        "authEntityId": 13425,
        "displayName": "13425",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "479",
        "protocol": "TCP",
        "upperPort": 0
      },
      {
        "id": "97712989-9f89-4a71-9e84-0b2445986051",
        "instanceId": 13426,
        "authEntityId": 13426,
        "displayName": "13426",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "479",
        "protocol": "UDP",
        "upperPort": 0
      }
    ],
    "parentScalableGroup": {
      "idRef": "000c15bf-28fe-4fbb-9b69-35e6502721f1"
    },
    "user": [

    ],
    "userGroup": [

    ]
  },
  {
    "id": "4b346370-bc33-4b6b-b794-a85ddee337ff",
    "instanceId": 15371,
    "authEntityId": 15371,
    "displayName": "15371",
    "authEntityClass": -1909073334,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "createTime": 1517966744825,
    "deployed": false,
    "isSeeded": true,
    "isStale": false,
    "lastUpdateTime": 1517966744825,
    "name": "vsinet",
    "namespace": "scalablegroup:application",
    "provisioningState": "DEFINED",
    "qualifier": "application",
    "resourceVersion": 0,
    "targetIdList": [

    ],
    "type": "scalablegroup",
    "cfsChangeInfo": [

    ],
    "customProvisions": [

    ],
    "scalableGroupExternalHandle": "vsinet",
    "scalableGroupType": "APPLICATION",
    "securityGroupTag": 0,
    "state": "ACTIVE",
    "vnAgnostic": true,
    "identitySource": {
      "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
      "instanceId": 10010,
      "authEntityId": 10010,
      "displayName": "10010",
      "authEntityClass": 1738953278,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "state": "INACTIVE",
      "type": "NBAR"
    },
    "indicativeNetworkIdentity": [
      {
        "id": "51a4effb-5525-4b0c-9464-824556d8ad51",
        "instanceId": 17729,
        "authEntityId": 17729,
        "displayName": "17729",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "996",
        "protocol": "TCP",
        "upperPort": 0
      },
      {
        "id": "ad98644b-8864-4477-8efa-bab40e680a9a",
        "instanceId": 17730,
        "authEntityId": 17730,
        "displayName": "17730",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "996",
        "protocol": "UDP",
        "upperPort": 0
      }
    ],
    "networkApplications": [
      {
        "id": "c72a06c6-eadf-49b0-8d64-5028740b5b77",
        "instanceId": 16314,
        "authEntityId": 16314,
        "displayName": "16314",
        "authEntityClass": -217092956,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "appProtocol": "tcp/udp",
        "applicationGroup": "other",
        "applicationType": "DEFAULT",
        "categoryId": "6daa3dfd-3526-407a-88f0-97824b3f3a24",
        "createTime": 1517966744825,
        "encrypted": "false",
        "engineId": "3",
        "helpString": "vsinet",
        "ignoreConflict": false,
        "lastUpdateTime": 1517966744825,
        "longDescription": "Registered with IANA on port 996 TCP/UDP",
        "name": "vsinet",
        "nbarId": "675",
        "p2pTechnology": "false",
        "popularity": 1,
        "rank": 65535,
        "references": "http://www.iana.org/assignments/service-names-port-numbers/service-names-port-numbers.xml",
        "selectorId": "996",
        "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
        "trafficClass": "BULK_DATA",
        "tunnel": "false"
      }
    ],
    "networkIdentity": [
      {
        "id": "85541a0e-bab1-41fa-b8ca-d0dff359d4b7",
        "instanceId": 17728,
        "authEntityId": 17728,
        "displayName": "17728",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "996",
        "protocol": "TCP",
        "upperPort": 0
      },
      {
        "id": "3e197c2a-2571-4e60-8732-bd3330e58c59",
        "instanceId": 17727,
        "authEntityId": 17727,
        "displayName": "17727",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "996",
        "protocol": "UDP",
        "upperPort": 0
      }
    ],
    "parentScalableGroup": {
      "idRef": "000c15bf-28fe-4fbb-9b69-35e6502721f1"
    },
    "user": [

    ],
    "userGroup": [

    ]
  },
  {
    "id": "4b52aecc-afcc-4a59-b0e3-62eae812b85f",
    "instanceId": 15067,
    "authEntityId": 15067,
    "displayName": "15067",
    "authEntityClass": -1909073334,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "createTime": 1517966744156,
    "deployed": false,
    "isSeeded": true,
    "isStale": false,
    "lastUpdateTime": 1517966744156,
    "name": "rsvp-encap-1",
    "namespace": "scalablegroup:application",
    "provisioningState": "DEFINED",
    "qualifier": "application",
    "resourceVersion": 0,
    "targetIdList": [

    ],
    "type": "scalablegroup",
    "cfsChangeInfo": [

    ],
    "customProvisions": [

    ],
    "scalableGroupExternalHandle": "rsvp-encap-1",
    "scalableGroupType": "APPLICATION",
    "securityGroupTag": 0,
    "state": "ACTIVE",
    "vnAgnostic": true,
    "identitySource": {
      "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
      "instanceId": 10010,
      "authEntityId": 10010,
      "displayName": "10010",
      "authEntityClass": 1738953278,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "state": "INACTIVE",
      "type": "NBAR"
    },
    "indicativeNetworkIdentity": [
      {
        "id": "6fc088e0-0f04-4ec0-96c7-c106bbbdd70f",
        "instanceId": 14843,
        "authEntityId": 14843,
        "displayName": "14843",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "1698",
        "protocol": "UDP",
        "upperPort": 0
      },
      {
        "id": "fdf2cdf6-df25-4513-8ad3-ec78c74e2c5c",
        "instanceId": 14844,
        "authEntityId": 14844,
        "displayName": "14844",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "1698",
        "protocol": "TCP",
        "upperPort": 0
      }
    ],
    "networkApplications": [
      {
        "id": "3bfe8d98-2356-44aa-b298-48f87702d13d",
        "instanceId": 12006,
        "authEntityId": 12006,
        "displayName": "12006",
        "authEntityClass": -217092956,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "appProtocol": "tcp/udp",
        "applicationGroup": "other",
        "applicationType": "DEFAULT",
        "categoryId": "1fc17362-9334-4c7b-b752-5afdbb574017",
        "createTime": 1517966744156,
        "encrypted": "false",
        "engineId": "3",
        "helpString": "Resource Reservation Protocol",
        "ignoreConflict": false,
        "lastUpdateTime": 1517966744156,
        "longDescription": "Resource Reservation Protocol (RSVP) is a Transport Layer protocol designed to reserve resources across a network for an integrated services internet. RSVP operates over an IPv4 or IPv6 internet Layer and provides receiver-initiated setup of resource reservations for multicast or unicast data flows with scaling and robustness. It does not transport application data but is similar to a control protocol, like ICMP or IGMP.",
        "name": "rsvp-encap-1",
        "nbarId": "1423",
        "p2pTechnology": "false",
        "popularity": 5,
        "rank": 65535,
        "references": "http://www.ietf.org/rfc/rfc2205.txt",
        "selectorId": "1698",
        "subCategory": "f3c0f761-d894-4112-ab89-b90fc84fd660",
        "trafficClass": "OPS_ADMIN_MGMT",
        "tunnel": "false"
      }
    ],
    "networkIdentity": [
      {
        "id": "98ef2f56-5f30-4f92-81cf-cf51600caa3d",
        "instanceId": 14841,
        "authEntityId": 14841,
        "displayName": "14841",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "1698",
        "protocol": "UDP",
        "upperPort": 0
      },
      {
        "id": "bb39d41a-1c2c-4abc-8469-9befb142e944",
        "instanceId": 14842,
        "authEntityId": 14842,
        "displayName": "14842",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "1698",
        "protocol": "TCP",
        "upperPort": 0
      }
    ],
    "parentScalableGroup": {
      "idRef": "5fcd74d4-8726-47f7-a088-c457863da7ef"
    },
    "user": [

    ],
    "userGroup": [

    ]
  },
  {
    "id": "4b960e06-ae66-42a7-843c-0583e28546d8",
    "instanceId": 4889,
    "authEntityId": 4889,
    "displayName": "4889",
    "authEntityClass": -1909073334,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "createTime": 1517966743689,
    "deployed": false,
    "isSeeded": true,
    "isStale": false,
    "lastUpdateTime": 1517966743689,
    "name": "ntp",
    "namespace": "scalablegroup:application",
    "provisioningState": "DEFINED",
    "qualifier": "application",
    "resourceVersion": 0,
    "targetIdList": [

    ],
    "type": "scalablegroup",
    "cfsChangeInfo": [

    ],
    "customProvisions": [

    ],
    "scalableGroupExternalHandle": "ntp",
    "scalableGroupType": "APPLICATION",
    "securityGroupTag": 0,
    "state": "ACTIVE",
    "vnAgnostic": true,
    "identitySource": {
      "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
      "instanceId": 10010,
      "authEntityId": 10010,
      "displayName": "10010",
      "authEntityClass": 1738953278,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "state": "INACTIVE",
      "type": "NBAR"
    },
    "indicativeNetworkIdentity": [
      {
        "id": "0ff2ea16-abc9-4c97-b834-e402fa819d44",
        "instanceId": 14320,
        "authEntityId": 14320,
        "displayName": "14320",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "123",
        "protocol": "UDP",
        "upperPort": 0
      }
    ],
    "networkApplications": [
      {
        "id": "58429ff9-5598-41d7-9671-28e46036be14",
        "instanceId": 11838,
        "authEntityId": 11838,
        "displayName": "11838",
        "authEntityClass": -217092956,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "appProtocol": "tcp/udp",
        "applicationGroup": "other",
        "applicationType": "DEFAULT",
        "categoryId": "1fc17362-9334-4c7b-b752-5afdbb574017",
        "createTime": 1517966743689,
        "encrypted": "false",
        "engineId": "3",
        "helpString": "Network Time Protocol",
        "ignoreConflict": false,
        "lastUpdateTime": 1517966743689,
        "longDescription": "Network Time Protocol (NTP) is a protocol for synchronizing the system clocks of distributed computer systems over packet-switched, variable-latency data networks. Usually the UDP port used is 123.",
        "name": "ntp",
        "nbarId": "31",
        "p2pTechnology": "false",
        "popularity": 9,
        "rank": 65535,
        "references": "http://www.ntp.org/",
        "selectorId": "123",
        "subCategory": "f3c0f761-d894-4112-ab89-b90fc84fd660",
        "trafficClass": "OPS_ADMIN_MGMT",
        "tunnel": "false"
      }
    ],
    "networkIdentity": [
      {
        "id": "a717ea11-e883-4442-82db-721d803ecb53",
        "instanceId": 14318,
        "authEntityId": 14318,
        "displayName": "14318",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "53,80,443,5353",
        "protocol": "TCP",
        "upperPort": 0
      },
      {
        "id": "9e4c26a4-3f60-4ade-89a1-532732219a1a",
        "instanceId": 14319,
        "authEntityId": 14319,
        "displayName": "14319",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "53,123,5353",
        "protocol": "UDP",
        "upperPort": 0
      }
    ],
    "parentScalableGroup": {
      "idRef": "5fcd74d4-8726-47f7-a088-c457863da7ef"
    },
    "user": [

    ],
    "userGroup": [

    ]
  },
  {
    "id": "4ba8a71a-2996-4215-b825-5739f23b4162",
    "instanceId": 15446,
    "authEntityId": 15446,
    "displayName": "15446",
    "authEntityClass": -1909073334,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "createTime": 1517966744980,
    "deployed": false,
    "isSeeded": true,
    "isStale": false,
    "lastUpdateTime": 1517966744980,
    "name": "xns-time",
    "namespace": "scalablegroup:application",
    "provisioningState": "DEFINED",
    "qualifier": "application",
    "resourceVersion": 0,
    "targetIdList": [

    ],
    "type": "scalablegroup",
    "cfsChangeInfo": [

    ],
    "customProvisions": [

    ],
    "scalableGroupExternalHandle": "xns-time",
    "scalableGroupType": "APPLICATION",
    "securityGroupTag": 0,
    "state": "ACTIVE",
    "vnAgnostic": true,
    "identitySource": {
      "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
      "instanceId": 10010,
      "authEntityId": 10010,
      "displayName": "10010",
      "authEntityClass": 1738953278,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "state": "INACTIVE",
      "type": "NBAR"
    },
    "indicativeNetworkIdentity": [
      {
        "id": "f4d54d6b-6309-4b2d-844c-abc4782744aa",
        "instanceId": 17925,
        "authEntityId": 17925,
        "displayName": "17925",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "52",
        "protocol": "UDP",
        "upperPort": 0
      },
      {
        "id": "338d351e-380d-48d5-aca4-79986f4d99a6",
        "instanceId": 17926,
        "authEntityId": 17926,
        "displayName": "17926",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "52",
        "protocol": "TCP",
        "upperPort": 0
      }
    ],
    "networkApplications": [
      {
        "id": "6151db44-705a-499f-9466-aef158121f35",
        "instanceId": 16389,
        "authEntityId": 16389,
        "displayName": "16389",
        "authEntityClass": -217092956,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "appProtocol": "tcp/udp",
        "applicationGroup": "xns-xerox-group",
        "applicationType": "DEFAULT",
        "categoryId": "1fc17362-9334-4c7b-b752-5afdbb574017",
        "createTime": 1517966744980,
        "encrypted": "false",
        "engineId": "3",
        "helpString": "XNS Time Protocol",
        "ignoreConflict": false,
        "lastUpdateTime": 1517966744980,
        "longDescription": "Xerox Network Services Time Protocol (XNS Time Protocol) provides the time at the specific server, along with additional information enabling the client to deduce the time in his or her own time zone. XNS Time Protocol is part of the XNS network protocol suite, used by Xerox to provide mail, filing, directory, authentication, etc. network services for the installed base of Xerox workstations.",
        "name": "xns-time",
        "nbarId": "932",
        "p2pTechnology": "false",
        "popularity": 1,
        "rank": 65535,
        "references": "http://citeseerx.ist.psu.edu/viewdoc/summary?doi=10.1.1.15.423",
        "selectorId": "52",
        "subCategory": "f3c0f761-d894-4112-ab89-b90fc84fd660",
        "trafficClass": "OPS_ADMIN_MGMT",
        "tunnel": "false"
      }
    ],
    "networkIdentity": [
      {
        "id": "9cf2aeba-3aac-4e92-b704-bb83aeead13f",
        "instanceId": 17923,
        "authEntityId": 17923,
        "displayName": "17923",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "52",
        "protocol": "UDP",
        "upperPort": 0
      },
      {
        "id": "0850223f-181b-47cd-960a-c276783bf6f1",
        "instanceId": 17924,
        "authEntityId": 17924,
        "displayName": "17924",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "52",
        "protocol": "TCP",
        "upperPort": 0
      }
    ],
    "parentScalableGroup": {
      "idRef": "5fcd74d4-8726-47f7-a088-c457863da7ef"
    },
    "user": [

    ],
    "userGroup": [

    ]
  },
  {
    "id": "4beb9e0b-7482-4eaa-bd38-1cfd7dce2d22",
    "instanceId": 15050,
    "authEntityId": 15050,
    "displayName": "15050",
    "authEntityClass": -1909073334,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "createTime": 1517966744117,
    "deployed": false,
    "isSeeded": true,
    "isStale": false,
    "lastUpdateTime": 1517966744117,
    "name": "ris-cm",
    "namespace": "scalablegroup:application",
    "provisioningState": "DEFINED",
    "qualifier": "application",
    "resourceVersion": 0,
    "targetIdList": [

    ],
    "type": "scalablegroup",
    "cfsChangeInfo": [

    ],
    "customProvisions": [

    ],
    "scalableGroupExternalHandle": "ris-cm",
    "scalableGroupType": "APPLICATION",
    "securityGroupTag": 0,
    "state": "ACTIVE",
    "vnAgnostic": true,
    "identitySource": {
      "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
      "instanceId": 10010,
      "authEntityId": 10010,
      "displayName": "10010",
      "authEntityClass": 1738953278,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "state": "INACTIVE",
      "type": "NBAR"
    },
    "indicativeNetworkIdentity": [
      {
        "id": "9f3b5be8-548d-41c4-8a15-2802f5eca011",
        "instanceId": 14784,
        "authEntityId": 14784,
        "displayName": "14784",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "748",
        "protocol": "UDP",
        "upperPort": 0
      },
      {
        "id": "1e3af7f7-f217-47b0-b966-a87c4bdf3a96",
        "instanceId": 14783,
        "authEntityId": 14783,
        "displayName": "14783",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "748",
        "protocol": "TCP",
        "upperPort": 0
      }
    ],
    "networkApplications": [
      {
        "id": "200b1e9a-2066-48f5-84c1-2d7cfeaf5b69",
        "instanceId": 11989,
        "authEntityId": 11989,
        "displayName": "11989",
        "authEntityClass": -217092956,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "appProtocol": "tcp/udp",
        "applicationGroup": "other",
        "applicationType": "DEFAULT",
        "categoryId": "6daa3dfd-3526-407a-88f0-97824b3f3a24",
        "createTime": 1517966744117,
        "encrypted": "false",
        "engineId": "3",
        "helpString": "Russell Info Sci Calendar Manager",
        "ignoreConflict": false,
        "lastUpdateTime": 1517966744117,
        "longDescription": "Registered with IANA on port 748 TCP/UDP",
        "name": "ris-cm",
        "nbarId": "622",
        "p2pTechnology": "false",
        "popularity": 1,
        "rank": 65535,
        "references": "http://www.iana.org/assignments/service-names-port-numbers/service-names-port-numbers.xml",
        "selectorId": "748",
        "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
        "trafficClass": "BULK_DATA",
        "tunnel": "false"
      }
    ],
    "networkIdentity": [
      {
        "id": "8af50757-df41-4a90-b754-057f3ed127e3",
        "instanceId": 14781,
        "authEntityId": 14781,
        "displayName": "14781",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "748",
        "protocol": "TCP",
        "upperPort": 0
      },
      {
        "id": "727111d9-1212-42af-8130-2281bcf854f1",
        "instanceId": 14782,
        "authEntityId": 14782,
        "displayName": "14782",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "748",
        "protocol": "UDP",
        "upperPort": 0
      }
    ],
    "parentScalableGroup": {
      "idRef": "000c15bf-28fe-4fbb-9b69-35e6502721f1"
    },
    "user": [

    ],
    "userGroup": [

    ]
  },
  {
    "id": "4c079f66-4f04-478f-9fc4-0a1bfae646fe",
    "instanceId": 15265,
    "authEntityId": 15265,
    "displayName": "15265",
    "authEntityClass": -1909073334,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "createTime": 1517966744614,
    "deployed": false,
    "isSeeded": true,
    "isStale": false,
    "lastUpdateTime": 1517966744614,
    "name": "telepresence-audio",
    "namespace": "scalablegroup:application",
    "provisioningState": "DEFINED",
    "qualifier": "application",
    "resourceVersion": 0,
    "targetIdList": [

    ],
    "type": "scalablegroup",
    "cfsChangeInfo": [

    ],
    "customProvisions": [

    ],
    "scalableGroupExternalHandle": "telepresence-audio",
    "scalableGroupType": "APPLICATION",
    "securityGroupTag": 0,
    "state": "ACTIVE",
    "vnAgnostic": true,
    "identitySource": {
      "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
      "instanceId": 10010,
      "authEntityId": 10010,
      "displayName": "10010",
      "authEntityClass": 1738953278,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "state": "INACTIVE",
      "type": "NBAR"
    },
    "indicativeNetworkIdentity": [

    ],
    "networkApplications": [
      {
        "id": "d5a2d9d0-b8d1-4525-925e-3c16c92df65d",
        "instanceId": 16208,
        "authEntityId": 16208,
        "displayName": "16208",
        "authEntityClass": -217092956,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "applicationGroup": "telepresence-group",
        "applicationType": "DEFAULT",
        "categoryId": "38a8e354-e2fe-4f3c-b1c5-2bbb90fb0d99",
        "createTime": 1517966744614,
        "encrypted": "false",
        "engineId": "13",
        "helpString": "Telepresentce Voice by various Cisco unified communication clients.",
        "ignoreConflict": false,
        "lastUpdateTime": 1517966744614,
        "longDescription": "Telepresentce Voice by various Cisco unified communication clients.",
        "name": "telepresence-audio",
        "nbarId": "1758",
        "p2pTechnology": "false",
        "popularity": 8,
        "rank": 65535,
        "references": "http://ciscospark.com",
        "selectorId": "816",
        "subCategory": "d29e5109-f6b1-436b-b23d-69672b608d13",
        "trafficClass": "VOIP_TELEPHONY",
        "tunnel": "false"
      }
    ],
    "networkIdentity": [

    ],
    "parentScalableGroup": {
      "idRef": "60f1364d-2a02-4063-8ba1-07817abe0612"
    },
    "user": [

    ],
    "userGroup": [

    ]
  },
  {
    "id": "4c0ad2c3-65bd-4018-81e7-3d5021b8a870",
    "instanceId": 4717,
    "authEntityId": 4717,
    "displayName": "4717",
    "authEntityClass": -1909073334,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "createTime": 1517966743346,
    "deployed": false,
    "isSeeded": true,
    "isStale": false,
    "lastUpdateTime": 1517966743346,
    "name": "mailq",
    "namespace": "scalablegroup:application",
    "provisioningState": "DEFINED",
    "qualifier": "application",
    "resourceVersion": 0,
    "targetIdList": [

    ],
    "type": "scalablegroup",
    "cfsChangeInfo": [

    ],
    "customProvisions": [

    ],
    "scalableGroupExternalHandle": "mailq",
    "scalableGroupType": "APPLICATION",
    "securityGroupTag": 0,
    "state": "ACTIVE",
    "vnAgnostic": true,
    "identitySource": {
      "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
      "instanceId": 10010,
      "authEntityId": 10010,
      "displayName": "10010",
      "authEntityClass": 1738953278,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "state": "INACTIVE",
      "type": "NBAR"
    },
    "indicativeNetworkIdentity": [
      {
        "id": "17b7c0a2-3275-4d44-a2e0-a27dd4a939e5",
        "instanceId": 13818,
        "authEntityId": 13818,
        "displayName": "13818",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "174",
        "protocol": "TCP",
        "upperPort": 0
      },
      {
        "id": "34393c03-6ae3-4b3b-a9ee-f2657a4ba37d",
        "instanceId": 13819,
        "authEntityId": 13819,
        "displayName": "13819",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "174",
        "protocol": "UDP",
        "upperPort": 0
      }
    ],
    "networkApplications": [
      {
        "id": "97b23652-c0a3-4058-9b55-4e7fddcd9dc2",
        "instanceId": 11667,
        "authEntityId": 11667,
        "displayName": "11667",
        "authEntityClass": -217092956,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "appProtocol": "tcp/udp",
        "applicationGroup": "other",
        "applicationType": "DEFAULT",
        "categoryId": "27953c60-b2ff-4e1a-bc52-0187d8295250",
        "createTime": 1517966743346,
        "encrypted": "false",
        "engineId": "3",
        "helpString": "MAILQ",
        "ignoreConflict": false,
        "lastUpdateTime": 1517966743346,
        "longDescription": "Mailer transport queue for Zmailer,  a mail transfer agent for Linux, BSD and other Unix-like systems.",
        "name": "mailq",
        "nbarId": "1019",
        "p2pTechnology": "false",
        "popularity": 1,
        "rank": 65535,
        "references": "http://www.zmailer.org/",
        "selectorId": "174",
        "subCategory": "b90786f4-0190-45b8-9924-21b3e9682590",
        "trafficClass": "BULK_DATA",
        "tunnel": "false"
      }
    ],
    "networkIdentity": [
      {
        "id": "1f872666-5db9-4f2f-b0b8-bc135f307a98",
        "instanceId": 13816,
        "authEntityId": 13816,
        "displayName": "13816",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "174",
        "protocol": "UDP",
        "upperPort": 0
      },
      {
        "id": "a8d6b281-95ee-447e-9554-d1190ec37fb4",
        "instanceId": 13817,
        "authEntityId": 13817,
        "displayName": "13817",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "174",
        "protocol": "TCP",
        "upperPort": 0
      }
    ],
    "parentScalableGroup": {
      "idRef": "8a7ca43a-32bd-40d2-871a-02220a165cb7"
    },
    "user": [

    ],
    "userGroup": [

    ]
  },
  {
    "id": "4c370f5a-2b15-4071-b625-91732d372f59",
    "instanceId": 15272,
    "authEntityId": 15272,
    "displayName": "15272",
    "authEntityClass": -1909073334,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "createTime": 1517966744629,
    "deployed": false,
    "isSeeded": true,
    "isStale": false,
    "lastUpdateTime": 1517966744629,
    "name": "teredo-ipv6-tunneled",
    "namespace": "scalablegroup:application",
    "provisioningState": "DEFINED",
    "qualifier": "application",
    "resourceVersion": 0,
    "targetIdList": [

    ],
    "type": "scalablegroup",
    "cfsChangeInfo": [

    ],
    "customProvisions": [

    ],
    "scalableGroupExternalHandle": "teredo-ipv6-tunneled",
    "scalableGroupType": "APPLICATION",
    "securityGroupTag": 0,
    "state": "ACTIVE",
    "vnAgnostic": true,
    "identitySource": {
      "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
      "instanceId": 10010,
      "authEntityId": 10010,
      "displayName": "10010",
      "authEntityClass": 1738953278,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "state": "INACTIVE",
      "type": "NBAR"
    },
    "indicativeNetworkIdentity": [
      {
        "id": "5dfeca85-288b-4e3e-90c4-d2c4a87316a2",
        "instanceId": 17449,
        "authEntityId": 17449,
        "displayName": "17449",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "3544",
        "protocol": "TCP",
        "upperPort": 0
      },
      {
        "id": "bd30060b-a38a-43dd-a522-9eb3c1458069",
        "instanceId": 17450,
        "authEntityId": 17450,
        "displayName": "17450",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "3544,3545",
        "protocol": "UDP",
        "upperPort": 0
      }
    ],
    "networkApplications": [
      {
        "id": "95167e30-af02-4164-b27d-959a9985ce16",
        "instanceId": 16215,
        "authEntityId": 16215,
        "displayName": "16215",
        "authEntityClass": -217092956,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "appProtocol": "tcp/udp",
        "applicationGroup": "other",
        "applicationType": "DEFAULT",
        "categoryId": "70fce3cd-f2bd-48de-9020-7b7840b9e7a1",
        "createTime": 1517966744629,
        "encrypted": "false",
        "engineId": "13",
        "helpString": "Teredo IPv6 over IPv4 tunneling protocol",
        "ignoreConflict": false,
        "lastUpdateTime": 1517966744629,
        "longDescription": "Teredo is a tunneling protocol designed to grant IPv6 connectivity to nodes that are located behind IPv6-unaware NAT devices. It defines a way of encapsulating IPv6 packets within IPv4 UDP datagrams that can be routed through NAT devices and on the IPv4 internet.",
        "name": "teredo-ipv6-tunneled",
        "nbarId": "1219",
        "p2pTechnology": "false",
        "popularity": 7,
        "rank": 65535,
        "references": "http://tools.ietf.org/html/rfc4380",
        "selectorId": "326",
        "subCategory": "ab63e736-9aa7-4946-b55b-3b26ddca609c",
        "trafficClass": "BULK_DATA",
        "tunnel": "true"
      }
    ],
    "networkIdentity": [
      {
        "id": "061bb739-6a2b-41de-8bb7-7c3af89aa269",
        "instanceId": 17447,
        "authEntityId": 17447,
        "displayName": "17447",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "3544,3545",
        "protocol": "UDP",
        "upperPort": 0
      },
      {
        "id": "590e63e8-4e15-4cc7-bd44-72291a1372d6",
        "instanceId": 17448,
        "authEntityId": 17448,
        "displayName": "17448",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "3544",
        "protocol": "TCP",
        "upperPort": 0
      }
    ],
    "parentScalableGroup": {
      "idRef": "79f9d505-477f-4ccd-bd2a-c5fc8097a2e1"
    },
    "user": [

    ],
    "userGroup": [

    ]
  },
  {
    "id": "4c48d2a8-e32a-47b9-b08d-02ed39758796",
    "instanceId": 15028,
    "authEntityId": 15028,
    "displayName": "15028",
    "authEntityClass": -1909073334,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "createTime": 1517966744061,
    "deployed": false,
    "isSeeded": true,
    "isStale": false,
    "lastUpdateTime": 1517966744061,
    "name": "rap",
    "namespace": "scalablegroup:application",
    "provisioningState": "DEFINED",
    "qualifier": "application",
    "resourceVersion": 0,
    "targetIdList": [

    ],
    "type": "scalablegroup",
    "cfsChangeInfo": [

    ],
    "customProvisions": [

    ],
    "scalableGroupExternalHandle": "rap",
    "scalableGroupType": "APPLICATION",
    "securityGroupTag": 0,
    "state": "ACTIVE",
    "vnAgnostic": true,
    "identitySource": {
      "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
      "instanceId": 10010,
      "authEntityId": 10010,
      "displayName": "10010",
      "authEntityClass": 1738953278,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "state": "INACTIVE",
      "type": "NBAR"
    },
    "indicativeNetworkIdentity": [
      {
        "id": "2532dcb3-eb7d-42bf-b939-3956e4df21a2",
        "instanceId": 14711,
        "authEntityId": 14711,
        "displayName": "14711",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "38",
        "protocol": "TCP",
        "upperPort": 0
      },
      {
        "id": "1289203a-057a-43c8-be6e-c94366c069ec",
        "instanceId": 14712,
        "authEntityId": 14712,
        "displayName": "14712",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "38",
        "protocol": "UDP",
        "upperPort": 0
      }
    ],
    "networkApplications": [
      {
        "id": "bf1c9ba4-cb1e-498e-b379-c78dacc60d70",
        "instanceId": 11967,
        "authEntityId": 11967,
        "displayName": "11967",
        "authEntityClass": -217092956,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "appProtocol": "tcp/udp",
        "applicationGroup": "other",
        "applicationType": "DEFAULT",
        "categoryId": "6daa3dfd-3526-407a-88f0-97824b3f3a24",
        "createTime": 1517966744061,
        "encrypted": "false",
        "engineId": "3",
        "helpString": "Route Access Protocol",
        "ignoreConflict": false,
        "lastUpdateTime": 1517966744061,
        "longDescription": "Route Access Protocol (RAP) is a general protocol for distributing routing information at all levels of the Internet, from private LANs to the widest-flung international carrier networks.  It does not distinguish between \"interior\" and \"exterior\" routing (except as restricted by specific policy), and therefore is not as restricted nor complex as those protocols that have strict level and area definitions in their models.",
        "name": "rap",
        "nbarId": "919",
        "p2pTechnology": "false",
        "popularity": 1,
        "rank": 65535,
        "references": "http://tools.ietf.org/html/rfc1476",
        "selectorId": "38",
        "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
        "trafficClass": "BULK_DATA",
        "tunnel": "false"
      }
    ],
    "networkIdentity": [
      {
        "id": "cb2f4fac-234b-487d-abdc-a0465b45d627",
        "instanceId": 14709,
        "authEntityId": 14709,
        "displayName": "14709",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "38",
        "protocol": "UDP",
        "upperPort": 0
      },
      {
        "id": "b23a1afc-ff5f-47f3-a27f-f41ccedee3d4",
        "instanceId": 14710,
        "authEntityId": 14710,
        "displayName": "14710",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "38",
        "protocol": "TCP",
        "upperPort": 0
      }
    ],
    "parentScalableGroup": {
      "idRef": "000c15bf-28fe-4fbb-9b69-35e6502721f1"
    },
    "user": [

    ],
    "userGroup": [

    ]
  },
  {
    "id": "4c4ae8c2-adba-497a-b074-0d57bb04ae1c",
    "instanceId": 4631,
    "authEntityId": 4631,
    "displayName": "4631",
    "authEntityClass": -1909073334,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "createTime": 1517966743165,
    "deployed": false,
    "isSeeded": true,
    "isStale": false,
    "lastUpdateTime": 1517966743165,
    "name": "ipv6-nonxt",
    "namespace": "scalablegroup:application",
    "provisioningState": "DEFINED",
    "qualifier": "application",
    "resourceVersion": 0,
    "targetIdList": [

    ],
    "type": "scalablegroup",
    "cfsChangeInfo": [

    ],
    "customProvisions": [

    ],
    "scalableGroupExternalHandle": "ipv6-nonxt",
    "scalableGroupType": "APPLICATION",
    "securityGroupTag": 0,
    "state": "ACTIVE",
    "vnAgnostic": true,
    "identitySource": {
      "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
      "instanceId": 10010,
      "authEntityId": 10010,
      "displayName": "10010",
      "authEntityClass": 1738953278,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "state": "INACTIVE",
      "type": "NBAR"
    },
    "indicativeNetworkIdentity": [

    ],
    "networkApplications": [
      {
        "id": "6177b99b-0c65-4e62-a841-81a80dc5fd9d",
        "instanceId": 11581,
        "authEntityId": 11581,
        "displayName": "11581",
        "authEntityClass": -217092956,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "applicationGroup": "other",
        "applicationType": "DEFAULT",
        "categoryId": "6daa3dfd-3526-407a-88f0-97824b3f3a24",
        "createTime": 1517966743165,
        "encrypted": "unassigned",
        "engineId": "1",
        "helpString": "DEPRECATED, traffic will not match",
        "ignoreConflict": false,
        "lastUpdateTime": 1517966743165,
        "longDescription": "Deprecated: No Next Header for IPv6",
        "name": "ipv6-nonxt",
        "nbarId": "813",
        "p2pTechnology": "unassigned",
        "popularity": 0,
        "rank": 65535,
        "references": "http://www.iana.org/assignments/protocol-numbers/protocol-numbers.xml",
        "selectorId": "59",
        "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
        "trafficClass": "BULK_DATA",
        "tunnel": "unassigned"
      }
    ],
    "networkIdentity": [

    ],
    "parentScalableGroup": {
      "idRef": "000c15bf-28fe-4fbb-9b69-35e6502721f1"
    },
    "user": [

    ],
    "userGroup": [

    ]
  },
  {
    "id": "4c600141-2bb1-4868-9149-9164555c511d",
    "instanceId": 4503,
    "authEntityId": 4503,
    "displayName": "4503",
    "authEntityClass": -1909073334,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "createTime": 1517966742832,
    "deployed": false,
    "isSeeded": true,
    "isStale": false,
    "lastUpdateTime": 1517966742832,
    "name": "google-services-video",
    "namespace": "scalablegroup:application",
    "provisioningState": "DEFINED",
    "qualifier": "application",
    "resourceVersion": 0,
    "targetIdList": [

    ],
    "type": "scalablegroup",
    "cfsChangeInfo": [

    ],
    "customProvisions": [

    ],
    "scalableGroupExternalHandle": "google-services-video",
    "scalableGroupType": "APPLICATION",
    "securityGroupTag": 0,
    "state": "ACTIVE",
    "vnAgnostic": true,
    "identitySource": {
      "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
      "instanceId": 10010,
      "authEntityId": 10010,
      "displayName": "10010",
      "authEntityClass": 1738953278,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "state": "INACTIVE",
      "type": "NBAR"
    },
    "indicativeNetworkIdentity": [

    ],
    "networkApplications": [
      {
        "id": "22bc7311-1758-4e48-b7a6-58f3467e87cd",
        "instanceId": 11454,
        "authEntityId": 11454,
        "displayName": "11454",
        "authEntityClass": -217092956,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "applicationGroup": "other",
        "applicationType": "DEFAULT",
        "categoryId": "38a8e354-e2fe-4f3c-b1c5-2bbb90fb0d99",
        "createTime": 1517966742832,
        "encrypted": "false",
        "engineId": "13",
        "helpString": "Google Services Video",
        "ignoreConflict": false,
        "lastUpdateTime": 1517966742832,
        "longDescription": "Video streaming related to various Google services, APIs and collaboration software.",
        "name": "google-services-video",
        "nbarId": "1062",
        "p2pTechnology": "false",
        "popularity": 10,
        "rank": 65535,
        "references": "http://www.google.com",
        "selectorId": "1062",
        "subCategory": "53ce0342-1e2d-455a-acc7-1b50db9d6845",
        "trafficClass": "MULTIMEDIA_CONFERENCING",
        "tunnel": "false"
      }
    ],
    "networkIdentity": [

    ],
    "parentScalableGroup": {
      "idRef": "aad510ce-fe95-4dfc-ad06-e494641cc016"
    },
    "user": [

    ],
    "userGroup": [

    ]
  },
  {
    "id": "4c6730ff-dd77-4eae-bb35-eec5015a77c6",
    "instanceId": 4321,
    "authEntityId": 4321,
    "displayName": "4321",
    "authEntityClass": -1909073334,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "createTime": 1517966742277,
    "deployed": false,
    "isSeeded": true,
    "isStale": false,
    "lastUpdateTime": 1517966742277,
    "name": "dameware-mrc",
    "namespace": "scalablegroup:application",
    "provisioningState": "DEFINED",
    "qualifier": "application",
    "resourceVersion": 0,
    "targetIdList": [

    ],
    "type": "scalablegroup",
    "cfsChangeInfo": [

    ],
    "customProvisions": [

    ],
    "scalableGroupExternalHandle": "dameware-mrc",
    "scalableGroupType": "APPLICATION",
    "securityGroupTag": 0,
    "state": "ACTIVE",
    "vnAgnostic": true,
    "identitySource": {
      "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
      "instanceId": 10010,
      "authEntityId": 10010,
      "displayName": "10010",
      "authEntityClass": 1738953278,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "state": "INACTIVE",
      "type": "NBAR"
    },
    "indicativeNetworkIdentity": [

    ],
    "networkApplications": [
      {
        "id": "17cb0a12-8199-400a-857e-6f48674d2d5e",
        "instanceId": 11273,
        "authEntityId": 11273,
        "displayName": "11273",
        "authEntityClass": -217092956,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "applicationGroup": "dameware-group",
        "applicationType": "DEFAULT",
        "categoryId": "7e19fdea-6222-4c57-9e66-66c2340d6bc3",
        "createTime": 1517966742277,
        "encrypted": "false",
        "engineId": "13",
        "helpString": "DameWare Mini Remote Control",
        "ignoreConflict": false,
        "lastUpdateTime": 1517966742277,
        "longDescription": "DameWare Mini Remote Control provides powerful remote control software for connecting to remote desktops, laptops and servers to troubleshoot and solve issues. MRC lets users remotely control Mac OS X, Windows and Linux systems, either by using the proprietary MRC protocol, or using other protocols like Microsoft RDP, VNC, and Intel AMT KVM.",
        "name": "dameware-mrc",
        "nbarId": "1481",
        "p2pTechnology": "false",
        "popularity": 6,
        "rank": 65535,
        "references": "http://www.dameware.com/products/mini-remote-control/product-overview.aspx",
        "selectorId": "547",
        "subCategory": "ff664205-64f3-447e-acff-1ec1ba9a6c31",
        "trafficClass": "MULTIMEDIA_STREAMING",
        "tunnel": "false"
      }
    ],
    "networkIdentity": [

    ],
    "parentScalableGroup": {
      "idRef": "4a6b37c6-7e11-40fe-a6b1-719b24813145"
    },
    "user": [

    ],
    "userGroup": [

    ]
  },
  {
    "id": "4c771b64-bfa3-46b9-ab2f-9a31fc637699",
    "instanceId": 15355,
    "authEntityId": 15355,
    "displayName": "15355",
    "authEntityClass": -1909073334,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "createTime": 1517966744794,
    "deployed": false,
    "isSeeded": true,
    "isStale": false,
    "lastUpdateTime": 1517966744794,
    "name": "visa",
    "namespace": "scalablegroup:application",
    "provisioningState": "DEFINED",
    "qualifier": "application",
    "resourceVersion": 0,
    "targetIdList": [

    ],
    "type": "scalablegroup",
    "cfsChangeInfo": [

    ],
    "customProvisions": [

    ],
    "scalableGroupExternalHandle": "visa",
    "scalableGroupType": "APPLICATION",
    "securityGroupTag": 0,
    "state": "ACTIVE",
    "vnAgnostic": true,
    "identitySource": {
      "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
      "instanceId": 10010,
      "authEntityId": 10010,
      "displayName": "10010",
      "authEntityClass": 1738953278,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "state": "INACTIVE",
      "type": "NBAR"
    },
    "indicativeNetworkIdentity": [
      {
        "id": "f1c033cc-a1bb-4ee6-81be-7a467c91ef1a",
        "instanceId": 17682,
        "authEntityId": 17682,
        "displayName": "17682",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipProtocolNumbers": "70",
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "protocol": "VISA",
        "upperPort": 0
      }
    ],
    "networkApplications": [
      {
        "id": "4b23f31b-a700-42cd-afe0-7f92673fd2a3",
        "instanceId": 16298,
        "authEntityId": 16298,
        "displayName": "16298",
        "authEntityClass": -217092956,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "appProtocol": "ip",
        "applicationGroup": "other",
        "applicationType": "DEFAULT",
        "categoryId": "6daa3dfd-3526-407a-88f0-97824b3f3a24",
        "createTime": 1517966744794,
        "encrypted": "unassigned",
        "engineId": "1",
        "helpString": "VISA Protocol",
        "ignoreConflict": false,
        "lastUpdateTime": 1517966744794,
        "longDescription": "Visa protocol is used for communication with Point-of-Sale Terminals. The protocol defines the data mode and error recovery for the POS data. The protocol uses async half-duplex transmissions and supports three modes: Single-thred, Multi-thread and Data Capture modes.",
        "name": "visa",
        "nbarId": "824",
        "p2pTechnology": "unassigned",
        "popularity": 2,
        "rank": 65535,
        "references": "http://www.sixnet.com/content/faq-visa-protocol-support-128.cfm",
        "selectorId": "70",
        "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
        "trafficClass": "BULK_DATA",
        "tunnel": "unassigned"
      }
    ],
    "networkIdentity": [
      {
        "id": "6a089af3-54ac-410c-b6d3-2a1ea4f0e808",
        "instanceId": 17681,
        "authEntityId": 17681,
        "displayName": "17681",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipProtocolNumbers": "70",
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "protocol": "VISA",
        "upperPort": 0
      }
    ],
    "parentScalableGroup": {
      "idRef": "000c15bf-28fe-4fbb-9b69-35e6502721f1"
    },
    "user": [

    ],
    "userGroup": [

    ]
  },
  {
    "id": "4c93df3b-ab02-4589-82c4-c4f6c236ebde",
    "instanceId": 4289,
    "authEntityId": 4289,
    "displayName": "4289",
    "authEntityClass": -1909073334,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "createTime": 1517966742167,
    "deployed": false,
    "isSeeded": true,
    "isStale": false,
    "lastUpdateTime": 1517966742167,
    "name": "corba-iiop",
    "namespace": "scalablegroup:application",
    "provisioningState": "DEFINED",
    "qualifier": "application",
    "resourceVersion": 0,
    "targetIdList": [

    ],
    "type": "scalablegroup",
    "cfsChangeInfo": [

    ],
    "customProvisions": [

    ],
    "scalableGroupExternalHandle": "corba-iiop",
    "scalableGroupType": "APPLICATION",
    "securityGroupTag": 0,
    "state": "ACTIVE",
    "vnAgnostic": true,
    "identitySource": {
      "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
      "instanceId": 10010,
      "authEntityId": 10010,
      "displayName": "10010",
      "authEntityClass": 1738953278,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "state": "INACTIVE",
      "type": "NBAR"
    },
    "indicativeNetworkIdentity": [
      {
        "id": "34dbdb56-2083-4370-9c15-2328b7ef1d99",
        "instanceId": 12640,
        "authEntityId": 12640,
        "displayName": "12640",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "683",
        "protocol": "UDP",
        "upperPort": 0
      },
      {
        "id": "76ae847d-1daf-4a28-baff-27bea5610659",
        "instanceId": 12639,
        "authEntityId": 12639,
        "displayName": "12639",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "683",
        "protocol": "TCP",
        "upperPort": 0
      }
    ],
    "networkApplications": [
      {
        "id": "a1e73522-6c36-4600-8048-7a508255e6be",
        "instanceId": 11242,
        "authEntityId": 11242,
        "displayName": "11242",
        "authEntityClass": -217092956,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "appProtocol": "tcp/udp",
        "applicationGroup": "corba-group",
        "applicationType": "DEFAULT",
        "categoryId": "58aa61dc-dd95-4a6d-9c7c-0ebf16da6dde",
        "createTime": 1517966742166,
        "encrypted": "false",
        "engineId": "3",
        "helpString": "CORBA Internet Inter-ORB Protocol",
        "ignoreConflict": false,
        "lastUpdateTime": 1517966742166,
        "longDescription": "The Common Object Request Broker Architecture (CORBA) and the Internet Inter-ORB Protocol (IIOP) are two technologies that enable distributed computing across heterogeneous systems. CORBA defines the total architecture required for communication between distributed objects and IIOP is the most important specification of CORBA. IIOP focuses on interoperability of distributed objects in heterogeneous environments. CORBA enables an application's components to communicate without regard for their locations on a network. A CORBA-compliant object is guaranteed to be able to communicate with other distributed objects because the technology defines a common interface.",
        "name": "corba-iiop",
        "nbarId": "111",
        "p2pTechnology": "false",
        "popularity": 1,
        "rank": 65535,
        "references": "http://cyberobject.com/co/whitepaper/Corba_IIOP.PDF",
        "selectorId": "683",
        "subCategory": "b90786f4-0190-45b8-9924-21b3e9682590",
        "trafficClass": "TRANSACTIONAL_DATA",
        "tunnel": "false"
      }
    ],
    "networkIdentity": [
      {
        "id": "2d1e4c6d-9234-440f-8b74-992941bc11ae",
        "instanceId": 12637,
        "authEntityId": 12637,
        "displayName": "12637",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "683",
        "protocol": "UDP",
        "upperPort": 0
      },
      {
        "id": "02c8b481-83ef-4df8-9d0a-235dc7a83b7f",
        "instanceId": 12638,
        "authEntityId": 12638,
        "displayName": "12638",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "683",
        "protocol": "TCP",
        "upperPort": 0
      }
    ],
    "parentScalableGroup": {
      "idRef": "cff11752-fafb-4e22-9e6f-11f44f59a9a3"
    },
    "user": [

    ],
    "userGroup": [

    ]
  },
  {
    "id": "4cac7211-69f8-49cd-9366-7de09cc6e79e",
    "instanceId": 15312,
    "authEntityId": 15312,
    "displayName": "15312",
    "authEntityClass": -1909073334,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "createTime": 1517966744718,
    "deployed": false,
    "isSeeded": true,
    "isStale": false,
    "lastUpdateTime": 1517966744718,
    "name": "twitter",
    "namespace": "scalablegroup:application",
    "provisioningState": "DEFINED",
    "qualifier": "application",
    "resourceVersion": 0,
    "targetIdList": [

    ],
    "type": "scalablegroup",
    "cfsChangeInfo": [

    ],
    "customProvisions": [

    ],
    "scalableGroupExternalHandle": "twitter",
    "scalableGroupType": "APPLICATION",
    "securityGroupTag": 0,
    "state": "ACTIVE",
    "vnAgnostic": true,
    "identitySource": {
      "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
      "instanceId": 10010,
      "authEntityId": 10010,
      "displayName": "10010",
      "authEntityClass": 1738953278,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "state": "INACTIVE",
      "type": "NBAR"
    },
    "indicativeNetworkIdentity": [

    ],
    "networkApplications": [
      {
        "id": "92f90faf-7c49-4b38-8230-fb56bca23c31",
        "instanceId": 16255,
        "authEntityId": 16255,
        "displayName": "16255",
        "authEntityClass": -217092956,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "appProtocol": "tcp/udp",
        "applicationGroup": "other",
        "applicationType": "DEFAULT",
        "categoryId": "9eb6d680-796f-478a-9106-1151f00f7eff",
        "createTime": 1517966744718,
        "encrypted": "true",
        "engineId": "13",
        "helpString": "Twitter Microblogging Social Network",
        "ignoreConflict": false,
        "lastUpdateTime": 1517966744718,
        "longDescription": "Twitter is an online social networking service and microblogging service that enables its users to send and read text-based posts of up to 140 characters, known as tweets. It was created in March 2006 by Jack Dorsey and launched that July. The service rapidly gained worldwide popularity, with over 140 million active users as of 2012, generating over 340 millions tweets daily and handling over 1.6 billion search queries per day. It has been described as the SMS of the Internet.",
        "name": "twitter",
        "nbarId": "1453",
        "p2pTechnology": "false",
        "popularity": 10,
        "rank": 65535,
        "references": "http://twitter.com/about",
        "selectorId": "517",
        "subCategory": "aab99e59-8589-4a61-933e-f01bff556d78",
        "trafficClass": "TRANSACTIONAL_DATA",
        "tunnel": "false"
      }
    ],
    "networkIdentity": [
      {
        "id": "3ed54e19-4115-4d68-9070-48b66cce7a12",
        "instanceId": 17553,
        "authEntityId": 17553,
        "displayName": "17553",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "53,80,443,5353",
        "protocol": "TCP",
        "upperPort": 0
      },
      {
        "id": "1d553607-e644-436f-9259-b03af908e053",
        "instanceId": 17554,
        "authEntityId": 17554,
        "displayName": "17554",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "53,5353",
        "protocol": "UDP",
        "upperPort": 0
      }
    ],
    "parentScalableGroup": {
      "idRef": "82348eb1-d8b8-4daf-8532-15e1a5f9cdd0"
    },
    "user": [

    ],
    "userGroup": [

    ]
  },
  {
    "id": "4cb117dc-1e76-4791-8cbd-65ce7af43285",
    "instanceId": 15073,
    "authEntityId": 15073,
    "displayName": "15073",
    "authEntityClass": -1909073334,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "createTime": 1517966744172,
    "deployed": false,
    "isSeeded": true,
    "isStale": false,
    "lastUpdateTime": 1517966744172,
    "name": "rtip",
    "namespace": "scalablegroup:application",
    "provisioningState": "DEFINED",
    "qualifier": "application",
    "resourceVersion": 0,
    "targetIdList": [

    ],
    "type": "scalablegroup",
    "cfsChangeInfo": [

    ],
    "customProvisions": [

    ],
    "scalableGroupExternalHandle": "rtip",
    "scalableGroupType": "APPLICATION",
    "securityGroupTag": 0,
    "state": "ACTIVE",
    "vnAgnostic": true,
    "identitySource": {
      "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
      "instanceId": 10010,
      "authEntityId": 10010,
      "displayName": "10010",
      "authEntityClass": 1738953278,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "state": "INACTIVE",
      "type": "NBAR"
    },
    "indicativeNetworkIdentity": [
      {
        "id": "feab6d93-6810-4162-a9b1-bc0420203ed8",
        "instanceId": 14865,
        "authEntityId": 14865,
        "displayName": "14865",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "771",
        "protocol": "UDP",
        "upperPort": 0
      },
      {
        "id": "fc5b47f0-7897-40fd-8ea0-be9a881532ae",
        "instanceId": 14866,
        "authEntityId": 14866,
        "displayName": "14866",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "771",
        "protocol": "TCP",
        "upperPort": 0
      }
    ],
    "networkApplications": [
      {
        "id": "243e9d96-7e16-4597-a050-68b967abf2d0",
        "instanceId": 16016,
        "authEntityId": 16016,
        "displayName": "16016",
        "authEntityClass": -217092956,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "appProtocol": "tcp/udp",
        "applicationGroup": "other",
        "applicationType": "DEFAULT",
        "categoryId": "6daa3dfd-3526-407a-88f0-97824b3f3a24",
        "createTime": 1517966744172,
        "encrypted": "false",
        "engineId": "3",
        "helpString": "rtip",
        "ignoreConflict": false,
        "lastUpdateTime": 1517966744172,
        "longDescription": "Registered with IANA on port 771 TCP/UDP",
        "name": "rtip",
        "nbarId": "641",
        "p2pTechnology": "false",
        "popularity": 1,
        "rank": 65535,
        "references": "http://www.iana.org/assignments/service-names-port-numbers/service-names-port-numbers.xml",
        "selectorId": "771",
        "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
        "trafficClass": "BULK_DATA",
        "tunnel": "false"
      }
    ],
    "networkIdentity": [
      {
        "id": "420b7d20-07d5-4497-84a7-623d24e13fcb",
        "instanceId": 14864,
        "authEntityId": 14864,
        "displayName": "14864",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "771",
        "protocol": "TCP",
        "upperPort": 0
      },
      {
        "id": "c36057d0-a333-4f0e-b8a0-eb3733b8c27d",
        "instanceId": 14863,
        "authEntityId": 14863,
        "displayName": "14863",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "771",
        "protocol": "UDP",
        "upperPort": 0
      }
    ],
    "parentScalableGroup": {
      "idRef": "000c15bf-28fe-4fbb-9b69-35e6502721f1"
    },
    "user": [

    ],
    "userGroup": [

    ]
  },
  {
    "id": "4ceafb66-b1e2-4d3d-ae78-2f63c0abf8dd",
    "instanceId": 4664,
    "authEntityId": 4664,
    "displayName": "4664",
    "authEntityClass": -1909073334,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "createTime": 1517966743235,
    "deployed": false,
    "isSeeded": true,
    "isStale": false,
    "lastUpdateTime": 1517966743235,
    "name": "jingdong-360buy",
    "namespace": "scalablegroup:application",
    "provisioningState": "DEFINED",
    "qualifier": "application",
    "resourceVersion": 0,
    "targetIdList": [

    ],
    "type": "scalablegroup",
    "cfsChangeInfo": [

    ],
    "customProvisions": [

    ],
    "scalableGroupExternalHandle": "jingdong-360buy",
    "scalableGroupType": "APPLICATION",
    "securityGroupTag": 0,
    "state": "ACTIVE",
    "vnAgnostic": true,
    "identitySource": {
      "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
      "instanceId": 10010,
      "authEntityId": 10010,
      "displayName": "10010",
      "authEntityClass": 1738953278,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "state": "INACTIVE",
      "type": "NBAR"
    },
    "indicativeNetworkIdentity": [

    ],
    "networkApplications": [
      {
        "id": "35674341-1843-4400-929b-becbff3a815a",
        "instanceId": 11614,
        "authEntityId": 11614,
        "displayName": "11614",
        "authEntityClass": -217092956,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "appProtocol": "tcp/udp",
        "applicationGroup": "other",
        "applicationType": "DEFAULT",
        "categoryId": "878644b5-fe91-45b4-9204-31c84a73ba48",
        "createTime": 1517966743235,
        "encrypted": "false",
        "engineId": "13",
        "helpString": "Jingdong (360buy.com) - Chinese e-commerce site.",
        "ignoreConflict": false,
        "lastUpdateTime": 1517966743235,
        "longDescription": "Jingdong (360buy.com) - Chinese e-commerce site.",
        "name": "jingdong-360buy",
        "nbarId": "1550",
        "p2pTechnology": "false",
        "popularity": 3,
        "rank": 65535,
        "references": "http://360buy.com",
        "selectorId": "624",
        "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
        "trafficClass": "TRANSACTIONAL_DATA",
        "tunnel": "false"
      }
    ],
    "networkIdentity": [
      {
        "id": "61da46ad-1586-4702-8cdf-ec57e93f8f03",
        "instanceId": 13666,
        "authEntityId": 13666,
        "displayName": "13666",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "53,5353",
        "protocol": "UDP",
        "upperPort": 0
      },
      {
        "id": "a710a836-e014-475e-b63e-ed38fe450a47",
        "instanceId": 13667,
        "authEntityId": 13667,
        "displayName": "13667",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "53,80,443,5353",
        "protocol": "TCP",
        "upperPort": 0
      }
    ],
    "parentScalableGroup": {
      "idRef": "28e08de4-d7b2-4521-84ce-bc1e5ee0b9cc"
    },
    "user": [

    ],
    "userGroup": [

    ]
  },
  {
    "id": "4cf22244-7ea5-4736-bd84-140b8eec6109",
    "instanceId": 4401,
    "authEntityId": 4401,
    "displayName": "4401",
    "authEntityClass": -1909073334,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "createTime": 1517966742542,
    "deployed": false,
    "isSeeded": true,
    "isStale": false,
    "lastUpdateTime": 1517966742542,
    "name": "ehow-web-portal",
    "namespace": "scalablegroup:application",
    "provisioningState": "DEFINED",
    "qualifier": "application",
    "resourceVersion": 0,
    "targetIdList": [

    ],
    "type": "scalablegroup",
    "cfsChangeInfo": [

    ],
    "customProvisions": [

    ],
    "scalableGroupExternalHandle": "ehow-web-portal",
    "scalableGroupType": "APPLICATION",
    "securityGroupTag": 0,
    "state": "ACTIVE",
    "vnAgnostic": true,
    "identitySource": {
      "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
      "instanceId": 10010,
      "authEntityId": 10010,
      "displayName": "10010",
      "authEntityClass": 1738953278,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "state": "INACTIVE",
      "type": "NBAR"
    },
    "indicativeNetworkIdentity": [

    ],
    "networkApplications": [
      {
        "id": "8f7ec58f-4c49-4c3b-86da-0a8d484a27fb",
        "instanceId": 11352,
        "authEntityId": 11352,
        "displayName": "11352",
        "authEntityClass": -217092956,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "appProtocol": "tcp/udp",
        "applicationGroup": "other",
        "applicationType": "DEFAULT",
        "categoryId": "878644b5-fe91-45b4-9204-31c84a73ba48",
        "createTime": 1517966742542,
        "encrypted": "false",
        "engineId": "13",
        "helpString": "eHow - Website featuring tutorials on a wide variety of subjects.",
        "ignoreConflict": false,
        "lastUpdateTime": 1517966742542,
        "longDescription": "eHow - Website featuring tutorials on a wide variety of subjects.",
        "name": "ehow-web-portal",
        "nbarId": "1657",
        "p2pTechnology": "false",
        "popularity": 3,
        "rank": 65535,
        "references": "http://ehow.com",
        "selectorId": "727",
        "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
        "trafficClass": "TRANSACTIONAL_DATA",
        "tunnel": "false"
      }
    ],
    "networkIdentity": [
      {
        "id": "610e7bd2-d004-4411-99b7-59ef9ac4f80d",
        "instanceId": 12997,
        "authEntityId": 12997,
        "displayName": "12997",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "53,5353",
        "protocol": "UDP",
        "upperPort": 0
      },
      {
        "id": "c092f886-2d03-44c5-806f-2f56f5b9a8c4",
        "instanceId": 12998,
        "authEntityId": 12998,
        "displayName": "12998",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "53,80,443,5353",
        "protocol": "TCP",
        "upperPort": 0
      }
    ],
    "parentScalableGroup": {
      "idRef": "28e08de4-d7b2-4521-84ce-bc1e5ee0b9cc"
    },
    "user": [

    ],
    "userGroup": [

    ]
  },
  {
    "id": "4cf58e83-1c20-46bd-803a-9b56581975eb",
    "instanceId": 4747,
    "authEntityId": 4747,
    "displayName": "4747",
    "authEntityClass": -1909073334,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "createTime": 1517966743413,
    "deployed": false,
    "isSeeded": true,
    "isStale": false,
    "lastUpdateTime": 1517966743413,
    "name": "microsoftds",
    "namespace": "scalablegroup:application",
    "provisioningState": "DEFINED",
    "qualifier": "application",
    "resourceVersion": 0,
    "targetIdList": [

    ],
    "type": "scalablegroup",
    "cfsChangeInfo": [

    ],
    "customProvisions": [

    ],
    "scalableGroupExternalHandle": "microsoftds",
    "scalableGroupType": "APPLICATION",
    "securityGroupTag": 0,
    "state": "ACTIVE",
    "vnAgnostic": true,
    "identitySource": {
      "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
      "instanceId": 10010,
      "authEntityId": 10010,
      "displayName": "10010",
      "authEntityClass": 1738953278,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "state": "INACTIVE",
      "type": "NBAR"
    },
    "indicativeNetworkIdentity": [
      {
        "id": "981e3d4d-9faa-40af-a06f-f8c1209521ba",
        "instanceId": 13906,
        "authEntityId": 13906,
        "displayName": "13906",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "445",
        "protocol": "UDP",
        "upperPort": 0
      },
      {
        "id": "9f81ea1b-3656-44bd-9410-ccff94ad9ce6",
        "instanceId": 13907,
        "authEntityId": 13907,
        "displayName": "13907",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "445",
        "protocol": "TCP",
        "upperPort": 0
      }
    ],
    "networkApplications": [
      {
        "id": "c340a428-6fb6-4395-bd43-52cd1c8fefae",
        "instanceId": 11697,
        "authEntityId": 11697,
        "displayName": "11697",
        "authEntityClass": -217092956,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "appProtocol": "tcp/udp",
        "applicationGroup": "netbios-group",
        "applicationType": "DEFAULT",
        "categoryId": "e6619ce8-97de-4c63-a53a-cceddbddc1a8",
        "createTime": 1517966743413,
        "encrypted": "false",
        "engineId": "3",
        "helpString": "Microsoft-DS",
        "ignoreConflict": false,
        "lastUpdateTime": 1517966743413,
        "longDescription": "Microsoft-DS is a port that replaces the Windows NetBIOS ports, for all versions of Windows after NT, as the preferred port for carrying Windows file sharing and other services.",
        "name": "microsoftds",
        "nbarId": "98",
        "p2pTechnology": "false",
        "popularity": 7,
        "rank": 65535,
        "references": "http://support.microsoft.com/default.aspx?scid=kb;en-us;832017",
        "selectorId": "445",
        "subCategory": "50b55e2e-d0d0-4404-bea4-819351565d90",
        "trafficClass": "BULK_DATA",
        "tunnel": "false"
      }
    ],
    "networkIdentity": [
      {
        "id": "80f8757f-6496-4ebb-ac40-20632c3c4712",
        "instanceId": 13904,
        "authEntityId": 13904,
        "displayName": "13904",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "445",
        "protocol": "UDP",
        "upperPort": 0
      },
      {
        "id": "aa65e164-04f9-4a95-accb-7d172290097d",
        "instanceId": 13905,
        "authEntityId": 13905,
        "displayName": "13905",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "445",
        "protocol": "TCP",
        "upperPort": 0
      }
    ],
    "parentScalableGroup": {
      "idRef": "383363b1-4dd5-49d1-bfe4-34daac437cfb"
    },
    "user": [

    ],
    "userGroup": [

    ]
  },
  {
    "id": "4d094634-91d9-425b-a246-9044b6fbcc3d",
    "instanceId": 4370,
    "authEntityId": 4370,
    "displayName": "4370",
    "authEntityClass": -1909073334,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "createTime": 1517966742435,
    "deployed": false,
    "isSeeded": true,
    "isStale": false,
    "lastUpdateTime": 1517966742435,
    "name": "disclose",
    "namespace": "scalablegroup:application",
    "provisioningState": "DEFINED",
    "qualifier": "application",
    "resourceVersion": 0,
    "targetIdList": [

    ],
    "type": "scalablegroup",
    "cfsChangeInfo": [

    ],
    "customProvisions": [

    ],
    "scalableGroupExternalHandle": "disclose",
    "scalableGroupType": "APPLICATION",
    "securityGroupTag": 0,
    "state": "ACTIVE",
    "vnAgnostic": true,
    "identitySource": {
      "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
      "instanceId": 10010,
      "authEntityId": 10010,
      "displayName": "10010",
      "authEntityClass": 1738953278,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "state": "INACTIVE",
      "type": "NBAR"
    },
    "indicativeNetworkIdentity": [
      {
        "id": "d50515a2-2c18-4871-88b2-86f8dc3fbccd",
        "instanceId": 12897,
        "authEntityId": 12897,
        "displayName": "12897",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "667",
        "protocol": "TCP",
        "upperPort": 0
      },
      {
        "id": "4ca60ad5-1c42-4cbe-8765-0c47303e7b26",
        "instanceId": 12898,
        "authEntityId": 12898,
        "displayName": "12898",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "667",
        "protocol": "UDP",
        "upperPort": 0
      }
    ],
    "networkApplications": [
      {
        "id": "16656ebe-dfb0-4b7a-bd70-986e7be1a0dd",
        "instanceId": 11321,
        "authEntityId": 11321,
        "displayName": "11321",
        "authEntityClass": -217092956,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "appProtocol": "tcp/udp",
        "applicationGroup": "other",
        "applicationType": "DEFAULT",
        "categoryId": "6daa3dfd-3526-407a-88f0-97824b3f3a24",
        "createTime": 1517966742435,
        "encrypted": "false",
        "engineId": "3",
        "helpString": "campaign contribution disclosures",
        "ignoreConflict": false,
        "lastUpdateTime": 1517966742435,
        "longDescription": "Registered with IANA on port 667 TCP/UDP",
        "name": "disclose",
        "nbarId": "575",
        "p2pTechnology": "false",
        "popularity": 1,
        "rank": 65535,
        "references": "http://www.iana.org/assignments/service-names-port-numbers/service-names-port-numbers.xml",
        "selectorId": "667",
        "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
        "trafficClass": "BULK_DATA",
        "tunnel": "false"
      }
    ],
    "networkIdentity": [
      {
        "id": "20dad9e9-8ce4-4bb7-a702-f2008f9964c3",
        "instanceId": 12896,
        "authEntityId": 12896,
        "displayName": "12896",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "667",
        "protocol": "UDP",
        "upperPort": 0
      },
      {
        "id": "a85526b2-4fd4-4e89-8e2d-18212223cad4",
        "instanceId": 12895,
        "authEntityId": 12895,
        "displayName": "12895",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "667",
        "protocol": "TCP",
        "upperPort": 0
      }
    ],
    "parentScalableGroup": {
      "idRef": "000c15bf-28fe-4fbb-9b69-35e6502721f1"
    },
    "user": [

    ],
    "userGroup": [

    ]
  },
  {
    "id": "4d398c4f-d9e5-44d4-9657-62404fd559c1",
    "instanceId": 4602,
    "authEntityId": 4602,
    "displayName": "4602",
    "authEntityClass": -1909073334,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "createTime": 1517966743095,
    "deployed": false,
    "isSeeded": true,
    "isStale": false,
    "lastUpdateTime": 1517966743095,
    "name": "indiegogo",
    "namespace": "scalablegroup:application",
    "provisioningState": "DEFINED",
    "qualifier": "application",
    "resourceVersion": 0,
    "targetIdList": [

    ],
    "type": "scalablegroup",
    "cfsChangeInfo": [

    ],
    "customProvisions": [

    ],
    "scalableGroupExternalHandle": "indiegogo",
    "scalableGroupType": "APPLICATION",
    "securityGroupTag": 0,
    "state": "ACTIVE",
    "vnAgnostic": true,
    "identitySource": {
      "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
      "instanceId": 10010,
      "authEntityId": 10010,
      "displayName": "10010",
      "authEntityClass": 1738953278,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "state": "INACTIVE",
      "type": "NBAR"
    },
    "indicativeNetworkIdentity": [

    ],
    "networkApplications": [
      {
        "id": "3de7e308-7037-48d6-91dc-7ad80953b951",
        "instanceId": 11552,
        "authEntityId": 11552,
        "displayName": "11552",
        "authEntityClass": -217092956,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "appProtocol": "tcp/udp",
        "applicationGroup": "other",
        "applicationType": "DEFAULT",
        "categoryId": "878644b5-fe91-45b4-9204-31c84a73ba48",
        "createTime": 1517966743095,
        "encrypted": "false",
        "engineId": "13",
        "helpString": "Indiegogo - Online Fund raiser for new ideas/products.",
        "ignoreConflict": false,
        "lastUpdateTime": 1517966743095,
        "longDescription": "Indiegogo - Online Fund raiser for new ideas/products.",
        "name": "indiegogo",
        "nbarId": "1733",
        "p2pTechnology": "false",
        "popularity": 3,
        "rank": 65535,
        "references": "http://indiegogo.com",
        "selectorId": "805",
        "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
        "trafficClass": "TRANSACTIONAL_DATA",
        "tunnel": "false"
      }
    ],
    "networkIdentity": [
      {
        "id": "ab483169-48d1-49d0-a207-df2945cf2099",
        "instanceId": 13517,
        "authEntityId": 13517,
        "displayName": "13517",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "53,5353",
        "protocol": "UDP",
        "upperPort": 0
      },
      {
        "id": "a449d599-bf33-403f-b1f7-74d5e207fd62",
        "instanceId": 13518,
        "authEntityId": 13518,
        "displayName": "13518",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "53,80,443,5353",
        "protocol": "TCP",
        "upperPort": 0
      }
    ],
    "parentScalableGroup": {
      "idRef": "28e08de4-d7b2-4521-84ce-bc1e5ee0b9cc"
    },
    "user": [

    ],
    "userGroup": [

    ]
  },
  {
    "id": "4d58e79c-1058-424e-ae93-d783375ce390",
    "instanceId": 4423,
    "authEntityId": 4423,
    "displayName": "4423",
    "authEntityClass": -1909073334,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "createTime": 1517966742604,
    "deployed": false,
    "isSeeded": true,
    "isStale": false,
    "lastUpdateTime": 1517966742604,
    "name": "espn-browsing",
    "namespace": "scalablegroup:application",
    "provisioningState": "DEFINED",
    "qualifier": "application",
    "resourceVersion": 0,
    "targetIdList": [

    ],
    "type": "scalablegroup",
    "cfsChangeInfo": [

    ],
    "customProvisions": [

    ],
    "scalableGroupExternalHandle": "espn-browsing",
    "scalableGroupType": "APPLICATION",
    "securityGroupTag": 0,
    "state": "ACTIVE",
    "vnAgnostic": true,
    "identitySource": {
      "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
      "instanceId": 10010,
      "authEntityId": 10010,
      "displayName": "10010",
      "authEntityClass": 1738953278,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "state": "INACTIVE",
      "type": "NBAR"
    },
    "indicativeNetworkIdentity": [

    ],
    "networkApplications": [
      {
        "id": "e423141b-63d7-4322-8063-5e85a542f470",
        "instanceId": 11374,
        "authEntityId": 11374,
        "displayName": "11374",
        "authEntityClass": -217092956,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "appProtocol": "tcp/udp",
        "applicationGroup": "espn-group",
        "applicationType": "DEFAULT",
        "categoryId": "f6ec0221-b82d-43e8-a64d-163cbc13263b",
        "createTime": 1517966742604,
        "encrypted": "true",
        "engineId": "13",
        "helpString": "ESPN related websites browsing and mobile applications",
        "ignoreConflict": false,
        "lastUpdateTime": 1517966742604,
        "longDescription": "ESPN is a global television network focused on sports-related programming. Espn-browsing protocol is used for accessing and browsing the network's websites and mobile applications for iPhone, iPad, Android and  WinRT. There is a separate protocol, espn-video, for the network's video streaming services.",
        "name": "espn-browsing",
        "nbarId": "1486",
        "p2pTechnology": "false",
        "popularity": 9,
        "rank": 65535,
        "references": "http://www.espn.com/",
        "selectorId": "551",
        "subCategory": "aab99e59-8589-4a61-933e-f01bff556d78",
        "trafficClass": "TRANSACTIONAL_DATA",
        "tunnel": "false"
      }
    ],
    "networkIdentity": [
      {
        "id": "d8d05871-69de-438c-a4af-acb43d31ac3f",
        "instanceId": 13066,
        "authEntityId": 13066,
        "displayName": "13066",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "53,5353",
        "protocol": "UDP",
        "upperPort": 0
      },
      {
        "id": "93e5a22d-18e8-449c-b9cc-c1f5c4611218",
        "instanceId": 13067,
        "authEntityId": 13067,
        "displayName": "13067",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "53,80,443,5353",
        "protocol": "TCP",
        "upperPort": 0
      }
    ],
    "parentScalableGroup": {
      "idRef": "28e08de4-d7b2-4521-84ce-bc1e5ee0b9cc"
    },
    "user": [

    ],
    "userGroup": [

    ]
  },
  {
    "id": "4d8054f3-f1d4-4fc7-a41d-57beb6f3f15d",
    "instanceId": 15189,
    "authEntityId": 15189,
    "displayName": "15189",
    "authEntityClass": -1909073334,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "createTime": 1517966744438,
    "deployed": false,
    "isSeeded": true,
    "isStale": false,
    "lastUpdateTime": 1517966744438,
    "name": "splunk",
    "namespace": "scalablegroup:application",
    "provisioningState": "DEFINED",
    "qualifier": "application",
    "resourceVersion": 0,
    "targetIdList": [

    ],
    "type": "scalablegroup",
    "cfsChangeInfo": [

    ],
    "customProvisions": [

    ],
    "scalableGroupExternalHandle": "splunk",
    "scalableGroupType": "APPLICATION",
    "securityGroupTag": 0,
    "state": "ACTIVE",
    "vnAgnostic": true,
    "identitySource": {
      "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
      "instanceId": 10010,
      "authEntityId": 10010,
      "displayName": "10010",
      "authEntityClass": 1738953278,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "state": "INACTIVE",
      "type": "NBAR"
    },
    "indicativeNetworkIdentity": [

    ],
    "networkApplications": [
      {
        "id": "d2e55c79-dbb9-4631-8bcb-b0e25eb559dd",
        "instanceId": 16132,
        "authEntityId": 16132,
        "displayName": "16132",
        "authEntityClass": -217092956,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "appProtocol": "tcp/udp",
        "applicationGroup": "other",
        "applicationType": "DEFAULT",
        "categoryId": "6daa3dfd-3526-407a-88f0-97824b3f3a24",
        "createTime": 1517966744438,
        "encrypted": "false",
        "engineId": "13",
        "helpString": "Splunk, platform for collecting and analyzing machine-generated big data",
        "ignoreConflict": false,
        "lastUpdateTime": 1517966744438,
        "longDescription": "Splunk is a platform for collecting and analyzing machine-generated big data in the enterprise IT environment.",
        "name": "splunk",
        "nbarId": "1084",
        "p2pTechnology": "false",
        "popularity": 10,
        "rank": 65535,
        "references": "http://www.splunk.com",
        "selectorId": "1084",
        "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
        "trafficClass": "BULK_DATA",
        "tunnel": "false"
      }
    ],
    "networkIdentity": [
      {
        "id": "ce4f98e8-23dc-495f-995c-ff42fd3cc42f",
        "instanceId": 17204,
        "authEntityId": 17204,
        "displayName": "17204",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "53,5353",
        "protocol": "UDP",
        "upperPort": 0
      },
      {
        "id": "7cae4f1f-b21b-4fcf-bd66-7a437cccb0c5",
        "instanceId": 17205,
        "authEntityId": 17205,
        "displayName": "17205",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "53,80,443,5353",
        "protocol": "TCP",
        "upperPort": 0
      }
    ],
    "parentScalableGroup": {
      "idRef": "000c15bf-28fe-4fbb-9b69-35e6502721f1"
    },
    "user": [

    ],
    "userGroup": [

    ]
  },
  {
    "id": "4e043f7d-be34-44b7-9b6c-e2c66bc01d2c",
    "instanceId": 4763,
    "authEntityId": 4763,
    "displayName": "4763",
    "authEntityClass": -1909073334,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "createTime": 1517966743444,
    "deployed": false,
    "isSeeded": true,
    "isStale": false,
    "lastUpdateTime": 1517966743444,
    "name": "mpeg2-ts",
    "namespace": "scalablegroup:application",
    "provisioningState": "DEFINED",
    "qualifier": "application",
    "resourceVersion": 0,
    "targetIdList": [

    ],
    "type": "scalablegroup",
    "cfsChangeInfo": [

    ],
    "customProvisions": [

    ],
    "scalableGroupExternalHandle": "mpeg2-ts",
    "scalableGroupType": "APPLICATION",
    "securityGroupTag": 0,
    "state": "ACTIVE",
    "vnAgnostic": true,
    "identitySource": {
      "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
      "instanceId": 10010,
      "authEntityId": 10010,
      "displayName": "10010",
      "authEntityClass": 1738953278,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "state": "INACTIVE",
      "type": "NBAR"
    },
    "indicativeNetworkIdentity": [

    ],
    "networkApplications": [
      {
        "id": "3e38663f-6c56-4cb0-966a-92f2afe7a683",
        "instanceId": 11713,
        "authEntityId": 11713,
        "displayName": "11713",
        "authEntityClass": -217092956,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "applicationGroup": "other",
        "applicationType": "DEFAULT",
        "categoryId": "82a7b7fa-da5a-48f5-8b6a-a2546b947843",
        "createTime": 1517966743444,
        "encrypted": "false",
        "engineId": "13",
        "helpString": "MPEG-2 Transport Stream",
        "ignoreConflict": false,
        "lastUpdateTime": 1517966743444,
        "longDescription": "MPEG2-TS (MPEG2 transport stream, MTS or TS) is a standard                      format for transmission and storage of audio, video, and                      Program and System Information Protocol (PSIP) data.                      It is used in broadcast systems such as DVB, ATSC and IPTV.",
        "name": "mpeg2-ts",
        "nbarId": "1549",
        "p2pTechnology": "false",
        "popularity": 8,
        "rank": 65535,
        "references": "http://mpeg.chiariglione.org/standards/mpeg-2",
        "selectorId": "610",
        "subCategory": "85a797c0-aef8-4284-91a7-677f33e29abb",
        "trafficClass": "MULTIMEDIA_STREAMING",
        "tunnel": "false"
      }
    ],
    "networkIdentity": [

    ],
    "parentScalableGroup": {
      "idRef": "fd6264c9-c16a-4311-a335-a3db039256f3"
    },
    "user": [

    ],
    "userGroup": [

    ]
  },
  {
    "id": "4ec576db-00a5-4a0f-a3eb-c113cea9b9d6",
    "instanceId": 15416,
    "authEntityId": 15416,
    "displayName": "15416",
    "authEntityClass": -1909073334,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "createTime": 1517966744927,
    "deployed": false,
    "isSeeded": true,
    "isStale": false,
    "lastUpdateTime": 1517966744927,
    "name": "wikipedia",
    "namespace": "scalablegroup:application",
    "provisioningState": "DEFINED",
    "qualifier": "application",
    "resourceVersion": 0,
    "targetIdList": [

    ],
    "type": "scalablegroup",
    "cfsChangeInfo": [

    ],
    "customProvisions": [

    ],
    "scalableGroupExternalHandle": "wikipedia",
    "scalableGroupType": "APPLICATION",
    "securityGroupTag": 0,
    "state": "ACTIVE",
    "vnAgnostic": true,
    "identitySource": {
      "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
      "instanceId": 10010,
      "authEntityId": 10010,
      "displayName": "10010",
      "authEntityClass": 1738953278,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "state": "INACTIVE",
      "type": "NBAR"
    },
    "indicativeNetworkIdentity": [

    ],
    "networkApplications": [
      {
        "id": "a6877e57-0e06-40c6-a137-407bdbf47cf2",
        "instanceId": 16359,
        "authEntityId": 16359,
        "displayName": "16359",
        "authEntityClass": -217092956,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "appProtocol": "tcp/udp",
        "applicationGroup": "other",
        "applicationType": "DEFAULT",
        "categoryId": "878644b5-fe91-45b4-9204-31c84a73ba48",
        "createTime": 1517966744927,
        "encrypted": "true",
        "engineId": "13",
        "helpString": "Wikipedia related websites",
        "ignoreConflict": false,
        "lastUpdateTime": 1517966744927,
        "longDescription": "Wikipedia is a multilingual and free-access Internet encyclopedia that is supported and hosted by the non-profit Wikimedia Foundation.",
        "name": "wikipedia",
        "nbarId": "1547",
        "p2pTechnology": "false",
        "popularity": 8,
        "rank": 65535,
        "references": "http://en.wikipedia.org/wiki/Wikipedia",
        "selectorId": "608",
        "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
        "trafficClass": "BULK_DATA",
        "tunnel": "false"
      }
    ],
    "networkIdentity": [
      {
        "id": "2d73f1d4-8dac-4f10-8b25-d78b85e4430f",
        "instanceId": 17843,
        "authEntityId": 17843,
        "displayName": "17843",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "53,5353",
        "protocol": "UDP",
        "upperPort": 0
      },
      {
        "id": "ee696c1d-200e-4733-9bac-c67aec2e1360",
        "instanceId": 17844,
        "authEntityId": 17844,
        "displayName": "17844",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "53,80,443,5353",
        "protocol": "TCP",
        "upperPort": 0
      }
    ],
    "parentScalableGroup": {
      "idRef": "53f03553-069a-4046-ad7c-84610eb579c5"
    },
    "user": [

    ],
    "userGroup": [

    ]
  },
  {
    "id": "4f114e78-2e93-4a62-8fa9-ca323286fe7a",
    "instanceId": 4566,
    "authEntityId": 4566,
    "displayName": "4566",
    "authEntityClass": -1909073334,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "createTime": 1517966743003,
    "deployed": false,
    "isSeeded": true,
    "isStale": false,
    "lastUpdateTime": 1517966743003,
    "name": "hybrid-pop",
    "namespace": "scalablegroup:application",
    "provisioningState": "DEFINED",
    "qualifier": "application",
    "resourceVersion": 0,
    "targetIdList": [

    ],
    "type": "scalablegroup",
    "cfsChangeInfo": [

    ],
    "customProvisions": [

    ],
    "scalableGroupExternalHandle": "hybrid-pop",
    "scalableGroupType": "APPLICATION",
    "securityGroupTag": 0,
    "state": "ACTIVE",
    "vnAgnostic": true,
    "identitySource": {
      "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
      "instanceId": 10010,
      "authEntityId": 10010,
      "displayName": "10010",
      "authEntityClass": 1738953278,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "state": "INACTIVE",
      "type": "NBAR"
    },
    "indicativeNetworkIdentity": [
      {
        "id": "12b1e002-2da4-4c0c-8f4a-f1c2ca20247f",
        "instanceId": 13409,
        "authEntityId": 13409,
        "displayName": "13409",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "473",
        "protocol": "TCP",
        "upperPort": 0
      },
      {
        "id": "ff765228-9325-4836-891f-f2fb3f76bcdc",
        "instanceId": 13410,
        "authEntityId": 13410,
        "displayName": "13410",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "473",
        "protocol": "UDP",
        "upperPort": 0
      }
    ],
    "networkApplications": [
      {
        "id": "0c69b919-2220-4e4c-b34c-4cc9159f16a9",
        "instanceId": 11516,
        "authEntityId": 11516,
        "displayName": "11516",
        "authEntityClass": -217092956,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "appProtocol": "tcp/udp",
        "applicationGroup": "other",
        "applicationType": "DEFAULT",
        "categoryId": "6daa3dfd-3526-407a-88f0-97824b3f3a24",
        "createTime": 1517966743003,
        "encrypted": "false",
        "engineId": "3",
        "helpString": "hybrid-pop",
        "ignoreConflict": false,
        "lastUpdateTime": 1517966743003,
        "longDescription": "A Hybrid Point of Presence (PoP) is an Internet router with T1 lines into the internet. The POP takes TCP/IP packets from the Internet, modulates them into  a standard TV channels and feeds them to a TV system.",
        "name": "hybrid-pop",
        "nbarId": "387",
        "p2pTechnology": "false",
        "popularity": 1,
        "rank": 65535,
        "references": "http://cookreport.com/hybrid.shtml",
        "selectorId": "473",
        "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
        "trafficClass": "BULK_DATA",
        "tunnel": "false"
      }
    ],
    "networkIdentity": [
      {
        "id": "a23dddc6-963e-44d2-b7ac-406085e1f8ea",
        "instanceId": 13408,
        "authEntityId": 13408,
        "displayName": "13408",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "473",
        "protocol": "UDP",
        "upperPort": 0
      },
      {
        "id": "fae5c11f-880e-4101-9809-9112f878e479",
        "instanceId": 13407,
        "authEntityId": 13407,
        "displayName": "13407",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "473",
        "protocol": "TCP",
        "upperPort": 0
      }
    ],
    "parentScalableGroup": {
      "idRef": "000c15bf-28fe-4fbb-9b69-35e6502721f1"
    },
    "user": [

    ],
    "userGroup": [

    ]
  },
  {
    "id": "4f3f717a-d3f0-44ff-95ed-d1e6401a6105",
    "instanceId": 4400,
    "authEntityId": 4400,
    "displayName": "4400",
    "authEntityClass": -1909073334,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "createTime": 1517966742539,
    "deployed": false,
    "isSeeded": true,
    "isStale": false,
    "lastUpdateTime": 1517966742539,
    "name": "egp",
    "namespace": "scalablegroup:application",
    "provisioningState": "DEFINED",
    "qualifier": "application",
    "resourceVersion": 0,
    "targetIdList": [

    ],
    "type": "scalablegroup",
    "cfsChangeInfo": [

    ],
    "customProvisions": [

    ],
    "scalableGroupExternalHandle": "egp",
    "scalableGroupType": "APPLICATION",
    "securityGroupTag": 0,
    "state": "ACTIVE",
    "vnAgnostic": true,
    "identitySource": {
      "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
      "instanceId": 10010,
      "authEntityId": 10010,
      "displayName": "10010",
      "authEntityClass": 1738953278,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "state": "INACTIVE",
      "type": "NBAR"
    },
    "indicativeNetworkIdentity": [
      {
        "id": "38305c68-fd16-4ff8-a70b-f0296af09f4f",
        "instanceId": 12996,
        "authEntityId": 12996,
        "displayName": "12996",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipProtocolNumbers": "8",
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "protocol": "EGP",
        "upperPort": 0
      }
    ],
    "networkApplications": [
      {
        "id": "65025af7-6332-4789-adc2-92aaa64be920",
        "instanceId": 11351,
        "authEntityId": 11351,
        "displayName": "11351",
        "authEntityClass": -217092956,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "appProtocol": "ip",
        "applicationGroup": "other",
        "applicationType": "DEFAULT",
        "categoryId": "1fc17362-9334-4c7b-b752-5afdbb574017",
        "createTime": 1517966742539,
        "encrypted": "false",
        "engineId": "1",
        "helpString": "Exterior Gateway Protocol",
        "ignoreConflict": false,
        "lastUpdateTime": 1517966742539,
        "longDescription": "Exterior Gateway Protocol (EGP) is a protocol used to convey network information between neighboring gateways, or autonomic systems. This way the gateways acquire neighbors, monitor neighbor reachability and exchange net-reachability information in the form of update messages. EGP is IP protocol number 8.",
        "name": "egp",
        "nbarId": "4",
        "p2pTechnology": "false",
        "popularity": 4,
        "rank": 65535,
        "references": "http://tools.ietf.org/html/rfc904",
        "selectorId": "8",
        "subCategory": "7cc1b3a6-4102-44e9-b522-0e71afe39a03",
        "trafficClass": "NETWORK_CONTROL",
        "tunnel": "false"
      }
    ],
    "networkIdentity": [
      {
        "id": "eea3c9e8-021d-4281-9a14-b4b23724a96c",
        "instanceId": 12995,
        "authEntityId": 12995,
        "displayName": "12995",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipProtocolNumbers": "8",
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "protocol": "EGP",
        "upperPort": 0
      }
    ],
    "parentScalableGroup": {
      "idRef": "77e75842-e98b-4112-ad0c-300db4bb11be"
    },
    "user": [

    ],
    "userGroup": [

    ]
  },
  {
    "id": "4f601f96-1ce0-4235-8af8-58b9b4f902cf",
    "instanceId": 15268,
    "authEntityId": 15268,
    "displayName": "15268",
    "authEntityClass": -1909073334,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "createTime": 1517966744621,
    "deployed": false,
    "isSeeded": true,
    "isStale": false,
    "lastUpdateTime": 1517966744621,
    "name": "tell",
    "namespace": "scalablegroup:application",
    "provisioningState": "DEFINED",
    "qualifier": "application",
    "resourceVersion": 0,
    "targetIdList": [

    ],
    "type": "scalablegroup",
    "cfsChangeInfo": [

    ],
    "customProvisions": [

    ],
    "scalableGroupExternalHandle": "tell",
    "scalableGroupType": "APPLICATION",
    "securityGroupTag": 0,
    "state": "ACTIVE",
    "vnAgnostic": true,
    "identitySource": {
      "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
      "instanceId": 10010,
      "authEntityId": 10010,
      "displayName": "10010",
      "authEntityClass": 1738953278,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "state": "INACTIVE",
      "type": "NBAR"
    },
    "indicativeNetworkIdentity": [
      {
        "id": "fff43610-f1d9-4fae-963f-7ea08146577b",
        "instanceId": 17435,
        "authEntityId": 17435,
        "displayName": "17435",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "754",
        "protocol": "TCP",
        "upperPort": 0
      },
      {
        "id": "365eb42c-e733-41ad-ac31-edcb0c4bcd07",
        "instanceId": 17436,
        "authEntityId": 17436,
        "displayName": "17436",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "754",
        "protocol": "UDP",
        "upperPort": 0
      }
    ],
    "networkApplications": [
      {
        "id": "9b0079df-4ea0-495f-8607-fe1735a3ee05",
        "instanceId": 16211,
        "authEntityId": 16211,
        "displayName": "16211",
        "authEntityClass": -217092956,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "appProtocol": "tcp/udp",
        "applicationGroup": "other",
        "applicationType": "DEFAULT",
        "categoryId": "6daa3dfd-3526-407a-88f0-97824b3f3a24",
        "createTime": 1517966744621,
        "encrypted": "false",
        "engineId": "3",
        "helpString": "send",
        "ignoreConflict": false,
        "lastUpdateTime": 1517966744621,
        "longDescription": "Registered with IANA on port 754 TCP/UDP",
        "name": "tell",
        "nbarId": "629",
        "p2pTechnology": "false",
        "popularity": 1,
        "rank": 65535,
        "references": "http://www.iana.org/assignments/service-names-port-numbers/service-names-port-numbers.xml",
        "selectorId": "754",
        "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
        "trafficClass": "BULK_DATA",
        "tunnel": "false"
      }
    ],
    "networkIdentity": [
      {
        "id": "28d126d2-7845-4391-a01b-d446bdf13d30",
        "instanceId": 17433,
        "authEntityId": 17433,
        "displayName": "17433",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "754",
        "protocol": "UDP",
        "upperPort": 0
      },
      {
        "id": "5b72a74f-7f14-4679-89b2-682c5e679664",
        "instanceId": 17434,
        "authEntityId": 17434,
        "displayName": "17434",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "754",
        "protocol": "TCP",
        "upperPort": 0
      }
    ],
    "parentScalableGroup": {
      "idRef": "000c15bf-28fe-4fbb-9b69-35e6502721f1"
    },
    "user": [

    ],
    "userGroup": [

    ]
  },
  {
    "id": "4f75268f-9a25-4255-8270-ca48d13c7b5e",
    "instanceId": 4725,
    "authEntityId": 4725,
    "displayName": "4725",
    "authEntityClass": -1909073334,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "createTime": 1517966743364,
    "deployed": false,
    "isSeeded": true,
    "isStale": false,
    "lastUpdateTime": 1517966743364,
    "name": "matip-type-a",
    "namespace": "scalablegroup:application",
    "provisioningState": "DEFINED",
    "qualifier": "application",
    "resourceVersion": 0,
    "targetIdList": [

    ],
    "type": "scalablegroup",
    "cfsChangeInfo": [

    ],
    "customProvisions": [

    ],
    "scalableGroupExternalHandle": "matip-type-a",
    "scalableGroupType": "APPLICATION",
    "securityGroupTag": 0,
    "state": "ACTIVE",
    "vnAgnostic": true,
    "identitySource": {
      "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
      "instanceId": 10010,
      "authEntityId": 10010,
      "displayName": "10010",
      "authEntityClass": 1738953278,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "state": "INACTIVE",
      "type": "NBAR"
    },
    "indicativeNetworkIdentity": [
      {
        "id": "84e44b4a-ddf5-4ce5-b3d1-2cd8f3878ce2",
        "instanceId": 13836,
        "authEntityId": 13836,
        "displayName": "13836",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "350",
        "protocol": "TCP",
        "upperPort": 0
      },
      {
        "id": "7b042510-e2b9-4920-b381-8f2a5ece9a4a",
        "instanceId": 13837,
        "authEntityId": 13837,
        "displayName": "13837",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "350",
        "protocol": "UDP",
        "upperPort": 0
      }
    ],
    "networkApplications": [
      {
        "id": "e4ffabbc-3200-4548-9811-c53860486a6a",
        "instanceId": 11675,
        "authEntityId": 11675,
        "displayName": "11675",
        "authEntityClass": -217092956,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "appProtocol": "tcp/udp",
        "applicationGroup": "other",
        "applicationType": "DEFAULT",
        "categoryId": "6daa3dfd-3526-407a-88f0-97824b3f3a24",
        "createTime": 1517966743364,
        "encrypted": "false",
        "engineId": "3",
        "helpString": "MATIP Type A",
        "ignoreConflict": false,
        "lastUpdateTime": 1517966743364,
        "longDescription": "Mapping of Airline Traffic over IP Type A (MATIP) is a communication application that connects an airline office or travel agency to a central computer system for seat reservations and ticket issuing. A dumb terminal or a PC accesses the central system (IBM or UNISYS) through a data network.",
        "name": "matip-type-a",
        "nbarId": "266",
        "p2pTechnology": "false",
        "popularity": 1,
        "rank": 65535,
        "references": "http://tools.ietf.org/html/rfc2351",
        "selectorId": "350",
        "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
        "trafficClass": "BULK_DATA",
        "tunnel": "false"
      }
    ],
    "networkIdentity": [
      {
        "id": "d0c60231-5868-4ae5-868b-ac3068aeb4f1",
        "instanceId": 13834,
        "authEntityId": 13834,
        "displayName": "13834",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "350",
        "protocol": "TCP",
        "upperPort": 0
      },
      {
        "id": "3abcbc5c-da38-4135-a764-a4d7e84947fc",
        "instanceId": 13835,
        "authEntityId": 13835,
        "displayName": "13835",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "350",
        "protocol": "UDP",
        "upperPort": 0
      }
    ],
    "parentScalableGroup": {
      "idRef": "000c15bf-28fe-4fbb-9b69-35e6502721f1"
    },
    "user": [

    ],
    "userGroup": [

    ]
  },
  {
    "id": "4fb1ca48-39dd-474a-a932-7d58945271bb",
    "instanceId": 4651,
    "authEntityId": 4651,
    "displayName": "4651",
    "authEntityClass": -1909073334,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "createTime": 1517966743208,
    "deployed": false,
    "isSeeded": true,
    "isStale": false,
    "lastUpdateTime": 1517966743208,
    "name": "iso-tsap",
    "namespace": "scalablegroup:application",
    "provisioningState": "DEFINED",
    "qualifier": "application",
    "resourceVersion": 0,
    "targetIdList": [

    ],
    "type": "scalablegroup",
    "cfsChangeInfo": [

    ],
    "customProvisions": [

    ],
    "scalableGroupExternalHandle": "iso-tsap",
    "scalableGroupType": "APPLICATION",
    "securityGroupTag": 0,
    "state": "ACTIVE",
    "vnAgnostic": true,
    "identitySource": {
      "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
      "instanceId": 10010,
      "authEntityId": 10010,
      "displayName": "10010",
      "authEntityClass": 1738953278,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "state": "INACTIVE",
      "type": "NBAR"
    },
    "indicativeNetworkIdentity": [
      {
        "id": "7d8558ee-6300-4fe6-a563-38c453a8cc1a",
        "instanceId": 13639,
        "authEntityId": 13639,
        "displayName": "13639",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "102",
        "protocol": "UDP",
        "upperPort": 0
      },
      {
        "id": "d3ac8452-3ab1-41b9-898c-6778e3294175",
        "instanceId": 13640,
        "authEntityId": 13640,
        "displayName": "13640",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "102",
        "protocol": "TCP",
        "upperPort": 0
      }
    ],
    "networkApplications": [
      {
        "id": "e7e7b53d-0d04-4d09-9d23-bdb981251491",
        "instanceId": 11601,
        "authEntityId": 11601,
        "displayName": "11601",
        "authEntityClass": -217092956,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "appProtocol": "tcp/udp",
        "applicationGroup": "other",
        "applicationType": "DEFAULT",
        "categoryId": "1fc17362-9334-4c7b-b752-5afdbb574017",
        "createTime": 1517966743208,
        "encrypted": "unassigned",
        "engineId": "3",
        "helpString": "ISO-TSAP Class 0",
        "ignoreConflict": false,
        "lastUpdateTime": 1517966743208,
        "longDescription": "A Service Access Point (SAP) is an identifying label for network endpoints used in Open Systems Interconnection (OSI) networking. The Transport Services Access Point (TSAP) is a label for for the transport layer. This protocol is an implementation of TSAP over TCP.",
        "name": "iso-tsap",
        "nbarId": "973",
        "p2pTechnology": "unassigned",
        "popularity": 1,
        "rank": 65535,
        "references": "http://tools.ietf.org/html/rfc1006",
        "selectorId": "102",
        "subCategory": "f3c0f761-d894-4112-ab89-b90fc84fd660",
        "trafficClass": "OPS_ADMIN_MGMT",
        "tunnel": "unassigned"
      }
    ],
    "networkIdentity": [
      {
        "id": "293317ec-b872-4a2c-b8f4-c86a7e81d39c",
        "instanceId": 13637,
        "authEntityId": 13637,
        "displayName": "13637",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "102",
        "protocol": "TCP",
        "upperPort": 0
      },
      {
        "id": "06f095ef-a073-41ce-b47b-a07d71b78f49",
        "instanceId": 13638,
        "authEntityId": 13638,
        "displayName": "13638",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "102",
        "protocol": "UDP",
        "upperPort": 0
      }
    ],
    "parentScalableGroup": {
      "idRef": "5fcd74d4-8726-47f7-a088-c457863da7ef"
    },
    "user": [

    ],
    "userGroup": [

    ]
  },
  {
    "id": "4fbe5a4c-8f4b-4bdf-8bac-97aeebdf73b3",
    "instanceId": 4220,
    "authEntityId": 4220,
    "displayName": "4220",
    "authEntityClass": -1909073334,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "createTime": 1517966741810,
    "deployed": false,
    "isSeeded": true,
    "isStale": false,
    "lastUpdateTime": 1517966741810,
    "name": "cfdptkt",
    "namespace": "scalablegroup:application",
    "provisioningState": "DEFINED",
    "qualifier": "application",
    "resourceVersion": 0,
    "targetIdList": [

    ],
    "type": "scalablegroup",
    "cfsChangeInfo": [

    ],
    "customProvisions": [

    ],
    "scalableGroupExternalHandle": "cfdptkt",
    "scalableGroupType": "APPLICATION",
    "securityGroupTag": 0,
    "state": "ACTIVE",
    "vnAgnostic": true,
    "identitySource": {
      "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
      "instanceId": 10010,
      "authEntityId": 10010,
      "displayName": "10010",
      "authEntityClass": 1738953278,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "state": "INACTIVE",
      "type": "NBAR"
    },
    "indicativeNetworkIdentity": [
      {
        "id": "4ab85ee5-dc1e-4e8c-809e-b52d05d0b295",
        "instanceId": 12481,
        "authEntityId": 12481,
        "displayName": "12481",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "120",
        "protocol": "TCP",
        "upperPort": 0
      },
      {
        "id": "b6207d55-a657-47d9-bdef-7d6a010a40ad",
        "instanceId": 12482,
        "authEntityId": 12482,
        "displayName": "12482",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "120",
        "protocol": "UDP",
        "upperPort": 0
      }
    ],
    "networkApplications": [
      {
        "id": "9ab6440a-6823-46c8-80a2-d0c871d1150c",
        "instanceId": 11175,
        "authEntityId": 11175,
        "displayName": "11175",
        "authEntityClass": -217092956,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "appProtocol": "tcp/udp",
        "applicationGroup": "other",
        "applicationType": "DEFAULT",
        "categoryId": "6daa3dfd-3526-407a-88f0-97824b3f3a24",
        "createTime": 1517966741810,
        "encrypted": "false",
        "engineId": "3",
        "helpString": "cfdptkt",
        "ignoreConflict": false,
        "lastUpdateTime": 1517966741810,
        "longDescription": "The Coherent File Distribution Protocol (CFDP) has been designed to speed up one-to-many file transfer operations that exhibit traffic coherence on media with broadcast capability.  Examples of such coherent file transfers are identical diskless workstations booting simultaneously, software upgrades being distributed to more than one machines at a site, a certain \"object\" (bitmap, graph, plain text, etc.) that is being discussed in a real-time electronic conference or class being sent to all participants, and so on. A CFDP client that wants to receive a file first contacts a server to acquire a \"ticket\" for the file in question.  This server could be a suitably modified BOOTP server, the equivalent of the tftpd daemon, etc. The server responds with a 32-bit ticket that will be used in the actual file transfers.",
        "name": "cfdptkt",
        "nbarId": "989",
        "p2pTechnology": "false",
        "popularity": 1,
        "rank": 65535,
        "references": "http://tools.ietf.org/html/rfc1235",
        "selectorId": "120",
        "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
        "trafficClass": "BULK_DATA",
        "tunnel": "false"
      }
    ],
    "networkIdentity": [
      {
        "id": "c61dbf1e-366e-4284-8848-ddf256b193c8",
        "instanceId": 12480,
        "authEntityId": 12480,
        "displayName": "12480",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "120",
        "protocol": "UDP",
        "upperPort": 0
      },
      {
        "id": "e297eccc-b0e3-42e9-b3b8-61d8b6980061",
        "instanceId": 12479,
        "authEntityId": 12479,
        "displayName": "12479",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "120",
        "protocol": "TCP",
        "upperPort": 0
      }
    ],
    "parentScalableGroup": {
      "idRef": "000c15bf-28fe-4fbb-9b69-35e6502721f1"
    },
    "user": [

    ],
    "userGroup": [

    ]
  },
  {
    "id": "4ff3603a-13aa-49fa-8c4a-4f65226054b6",
    "instanceId": 4624,
    "authEntityId": 4624,
    "displayName": "4624",
    "authEntityClass": -1909073334,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "createTime": 1517966743150,
    "deployed": false,
    "isSeeded": true,
    "isStale": false,
    "lastUpdateTime": 1517966743150,
    "name": "ipip",
    "namespace": "scalablegroup:application",
    "provisioningState": "DEFINED",
    "qualifier": "application",
    "resourceVersion": 0,
    "targetIdList": [

    ],
    "type": "scalablegroup",
    "cfsChangeInfo": [

    ],
    "customProvisions": [

    ],
    "scalableGroupExternalHandle": "ipip",
    "scalableGroupType": "APPLICATION",
    "securityGroupTag": 0,
    "state": "ACTIVE",
    "vnAgnostic": true,
    "identitySource": {
      "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
      "instanceId": 10010,
      "authEntityId": 10010,
      "displayName": "10010",
      "authEntityClass": 1738953278,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "state": "INACTIVE",
      "type": "NBAR"
    },
    "indicativeNetworkIdentity": [
      {
        "id": "7ae61802-6a12-4b5a-97d2-178667705edc",
        "instanceId": 13574,
        "authEntityId": 13574,
        "displayName": "13574",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipProtocolNumbers": "94",
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "protocol": "IPIP",
        "upperPort": 0
      }
    ],
    "networkApplications": [
      {
        "id": "ede77b2a-eaba-4d5a-a719-91d5d4277c87",
        "instanceId": 11574,
        "authEntityId": 11574,
        "displayName": "11574",
        "authEntityClass": -217092956,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "appProtocol": "ip",
        "applicationGroup": "other",
        "applicationType": "DEFAULT",
        "categoryId": "70fce3cd-f2bd-48de-9020-7b7840b9e7a1",
        "createTime": 1517966743150,
        "encrypted": "false",
        "engineId": "1",
        "helpString": "IP-within-IP Encapsulation Protocol",
        "ignoreConflict": false,
        "lastUpdateTime": 1517966743150,
        "longDescription": "IP-within-IP Encapsulation is a method by which an IP datagram may be encapsulated (carried as payload) within an IP datagram. Encapsulation is suggested as a means to alter the normal IP routing for datagrams, by delivering them to an intermediate destination that would otherwise not be selected by the (network part of the) IP Destination Address field in the original IP header.",
        "name": "ipip",
        "nbarId": "848",
        "p2pTechnology": "false",
        "popularity": 5,
        "rank": 65535,
        "references": "https://tools.ietf.org/rfc/rfc2003",
        "selectorId": "94",
        "subCategory": "ab63e736-9aa7-4946-b55b-3b26ddca609c",
        "trafficClass": "BULK_DATA",
        "tunnel": "true"
      }
    ],
    "networkIdentity": [
      {
        "id": "0ae23e64-64c9-4f20-8d2e-3d8f1407038b",
        "instanceId": 13573,
        "authEntityId": 13573,
        "displayName": "13573",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipProtocolNumbers": "94",
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "protocol": "IPIP",
        "upperPort": 0
      }
    ],
    "parentScalableGroup": {
      "idRef": "79f9d505-477f-4ccd-bd2a-c5fc8097a2e1"
    },
    "user": [

    ],
    "userGroup": [

    ]
  },
  {
    "id": "505d7018-53e8-4473-b1a2-18b48f2058f7",
    "instanceId": 4185,
    "authEntityId": 4185,
    "displayName": "4185",
    "authEntityClass": -1909073334,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "createTime": 1517966741578,
    "deployed": false,
    "isSeeded": true,
    "isStale": false,
    "lastUpdateTime": 1517966741578,
    "name": "bittorrent-networking",
    "namespace": "scalablegroup:application",
    "provisioningState": "DEFINED",
    "qualifier": "application",
    "resourceVersion": 0,
    "targetIdList": [

    ],
    "type": "scalablegroup",
    "cfsChangeInfo": [

    ],
    "customProvisions": [

    ],
    "scalableGroupExternalHandle": "bittorrent-networking",
    "scalableGroupType": "APPLICATION",
    "securityGroupTag": 0,
    "state": "ACTIVE",
    "vnAgnostic": true,
    "identitySource": {
      "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
      "instanceId": 10010,
      "authEntityId": 10010,
      "displayName": "10010",
      "authEntityClass": 1738953278,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "state": "INACTIVE",
      "type": "NBAR"
    },
    "indicativeNetworkIdentity": [

    ],
    "networkApplications": [
      {
        "id": "0c5634c8-d1b3-4c2d-80a4-060a84583d54",
        "instanceId": 11144,
        "authEntityId": 11144,
        "displayName": "11144",
        "authEntityClass": -217092956,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "appProtocol": "tcp",
        "applicationGroup": "bittorrent-group",
        "applicationType": "DEFAULT",
        "categoryId": "b33b2761-d981-4734-810a-a6a1c18ab352",
        "createTime": 1517966741578,
        "encrypted": "false",
        "engineId": "13",
        "helpString": "bittorrent p2p file sharing client networking",
        "ignoreConflict": false,
        "lastUpdateTime": 1517966741578,
        "longDescription": "BitTorrent Networking is the part of the BitTorrent protocol responsible for acquiring peers from the tracker, DHT network or any other means, and initiating data transfer sessions between the client and these peers. ",
        "name": "bittorrent-networking",
        "nbarId": "1477",
        "p2pTechnology": "true",
        "popularity": 10,
        "rank": 65535,
        "references": "http://jonas.nitro.dk/bittorrent/bittorrent-rfc.html",
        "selectorId": "543",
        "subCategory": "61b315f5-5e87-4de0-bc1a-5d084ea0b28d",
        "trafficClass": "BULK_DATA",
        "tunnel": "false"
      }
    ],
    "networkIdentity": [
      {
        "id": "a46cea4f-f425-4d99-b37c-42d7a86899b5",
        "instanceId": 12398,
        "authEntityId": 12398,
        "displayName": "12398",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "80,6881,6882,6883,6884,6885,6886,6887,6888,6889",
        "protocol": "TCP",
        "upperPort": 0
      }
    ],
    "parentScalableGroup": {
      "idRef": "7a1a3bbb-dc84-4a35-82a4-7e650c4c4761"
    },
    "user": [

    ],
    "userGroup": [

    ]
  },
  {
    "id": "50a3e7b0-eebe-4cf1-90fb-687c1cd13993",
    "instanceId": 4531,
    "authEntityId": 4531,
    "displayName": "4531",
    "authEntityClass": -1909073334,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "createTime": 1517966742905,
    "deployed": false,
    "isSeeded": true,
    "isStale": false,
    "lastUpdateTime": 1517966742905,
    "name": "hangouts-video",
    "namespace": "scalablegroup:application",
    "provisioningState": "DEFINED",
    "qualifier": "application",
    "resourceVersion": 0,
    "targetIdList": [

    ],
    "type": "scalablegroup",
    "cfsChangeInfo": [

    ],
    "customProvisions": [

    ],
    "scalableGroupExternalHandle": "hangouts-video",
    "scalableGroupType": "APPLICATION",
    "securityGroupTag": 0,
    "state": "ACTIVE",
    "vnAgnostic": true,
    "identitySource": {
      "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
      "instanceId": 10010,
      "authEntityId": 10010,
      "displayName": "10010",
      "authEntityClass": 1738953278,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "state": "INACTIVE",
      "type": "NBAR"
    },
    "indicativeNetworkIdentity": [

    ],
    "networkApplications": [
      {
        "id": "b86e93b8-d9df-421d-ab38-388027c3a188",
        "instanceId": 11482,
        "authEntityId": 11482,
        "displayName": "11482",
        "authEntityClass": -217092956,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "applicationGroup": "hangouts-group",
        "applicationType": "DEFAULT",
        "categoryId": "82a7b7fa-da5a-48f5-8b6a-a2546b947843",
        "createTime": 1517966742905,
        "encrypted": "true",
        "engineId": "13",
        "helpString": "Google Hangouts video",
        "ignoreConflict": false,
        "lastUpdateTime": 1517966742905,
        "longDescription": "Google Hangouts video",
        "name": "hangouts-video",
        "nbarId": "1089",
        "p2pTechnology": "true",
        "popularity": 7,
        "rank": 65535,
        "references": "https://hangouts.google.com/",
        "selectorId": "1089",
        "subCategory": "118c10a2-e272-47c1-92b9-4814ebe97138",
        "trafficClass": "MULTIMEDIA_CONFERENCING",
        "tunnel": "false"
      }
    ],
    "networkIdentity": [

    ],
    "parentScalableGroup": {
      "idRef": "fd6264c9-c16a-4311-a335-a3db039256f3"
    },
    "user": [

    ],
    "userGroup": [

    ]
  },
  {
    "id": "50b55fc3-f803-40e7-8e38-f09f10f60e5c",
    "instanceId": 4707,
    "authEntityId": 4707,
    "displayName": "4707",
    "authEntityClass": -1909073334,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "createTime": 1517966743326,
    "deployed": false,
    "isSeeded": true,
    "isStale": false,
    "lastUpdateTime": 1517966743326,
    "name": "logmein",
    "namespace": "scalablegroup:application",
    "provisioningState": "DEFINED",
    "qualifier": "application",
    "resourceVersion": 0,
    "targetIdList": [

    ],
    "type": "scalablegroup",
    "cfsChangeInfo": [

    ],
    "customProvisions": [

    ],
    "scalableGroupExternalHandle": "logmein",
    "scalableGroupType": "APPLICATION",
    "securityGroupTag": 0,
    "state": "ACTIVE",
    "vnAgnostic": true,
    "identitySource": {
      "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
      "instanceId": 10010,
      "authEntityId": 10010,
      "displayName": "10010",
      "authEntityClass": 1738953278,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "state": "INACTIVE",
      "type": "NBAR"
    },
    "indicativeNetworkIdentity": [

    ],
    "networkApplications": [
      {
        "id": "30a98c81-abb2-4ea8-b37b-3b16badedbf1",
        "instanceId": 11657,
        "authEntityId": 11657,
        "displayName": "11657",
        "authEntityClass": -217092956,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "appProtocol": "tcp/udp",
        "applicationGroup": "other",
        "applicationType": "DEFAULT",
        "categoryId": "a09ef1b3-ba26-4372-aee8-309d50697e76",
        "createTime": 1517966743326,
        "encrypted": "true",
        "engineId": "13",
        "helpString": "remote-access software for PC and Mobile",
        "ignoreConflict": false,
        "lastUpdateTime": 1517966743326,
        "longDescription": "LogMeIn is a remote-access software that allows a user to access a PC through a web browser (logmein.com) or through mobile phones (iPhone and Android).",
        "name": "logmein",
        "nbarId": "1455",
        "p2pTechnology": "false",
        "popularity": 6,
        "rank": 65535,
        "references": "https://secure.logmein.com/welcome/products/",
        "selectorId": "519",
        "subCategory": "ff664205-64f3-447e-acff-1ec1ba9a6c31",
        "trafficClass": "MULTIMEDIA_STREAMING",
        "tunnel": "false"
      }
    ],
    "networkIdentity": [
      {
        "id": "ff117e47-f3c9-42cf-ad38-e59da4c7c72a",
        "instanceId": 13788,
        "authEntityId": 13788,
        "displayName": "13788",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "53,80,443,5353",
        "protocol": "TCP",
        "upperPort": 0
      },
      {
        "id": "72b03e21-8bb5-4688-8234-17469bf193c0",
        "instanceId": 13789,
        "authEntityId": 13789,
        "displayName": "13789",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "53,5353",
        "protocol": "UDP",
        "upperPort": 0
      }
    ],
    "parentScalableGroup": {
      "idRef": "fd6264c9-c16a-4311-a335-a3db039256f3"
    },
    "user": [

    ],
    "userGroup": [

    ]
  },
  {
    "id": "51027221-6d55-455a-b326-ca4836bf3143",
    "instanceId": 4929,
    "authEntityId": 4929,
    "displayName": "4929",
    "authEntityClass": -1909073334,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "createTime": 1517966743826,
    "deployed": false,
    "isSeeded": true,
    "isStale": false,
    "lastUpdateTime": 1517966743826,
    "name": "osu-nms",
    "namespace": "scalablegroup:application",
    "provisioningState": "DEFINED",
    "qualifier": "application",
    "resourceVersion": 0,
    "targetIdList": [

    ],
    "type": "scalablegroup",
    "cfsChangeInfo": [

    ],
    "customProvisions": [

    ],
    "scalableGroupExternalHandle": "osu-nms",
    "scalableGroupType": "APPLICATION",
    "securityGroupTag": 0,
    "state": "ACTIVE",
    "vnAgnostic": true,
    "identitySource": {
      "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
      "instanceId": 10010,
      "authEntityId": 10010,
      "displayName": "10010",
      "authEntityClass": 1738953278,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "state": "INACTIVE",
      "type": "NBAR"
    },
    "indicativeNetworkIdentity": [
      {
        "id": "c1a1ed2d-f292-437d-b159-5b605bcd3bc8",
        "instanceId": 14453,
        "authEntityId": 14453,
        "displayName": "14453",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "192",
        "protocol": "UDP",
        "upperPort": 0
      },
      {
        "id": "109596bb-5eef-4d1e-a8bb-c91893fe99d3",
        "instanceId": 14454,
        "authEntityId": 14454,
        "displayName": "14454",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "192",
        "protocol": "TCP",
        "upperPort": 0
      }
    ],
    "networkApplications": [
      {
        "id": "79c5d441-b2dd-47b4-ba66-a31971ea53ce",
        "instanceId": 11878,
        "authEntityId": 11878,
        "displayName": "11878",
        "authEntityClass": -217092956,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "appProtocol": "tcp/udp",
        "applicationGroup": "other",
        "applicationType": "DEFAULT",
        "categoryId": "1fc17362-9334-4c7b-b752-5afdbb574017",
        "createTime": 1517966743825,
        "encrypted": "false",
        "engineId": "3",
        "helpString": "OSU Network Monitoring System",
        "ignoreConflict": false,
        "lastUpdateTime": 1517966743825,
        "longDescription": "Registered with IANA on port 192 TCP/UDP",
        "name": "osu-nms",
        "nbarId": "1036",
        "p2pTechnology": "false",
        "popularity": 1,
        "rank": 65535,
        "references": "http://www.iana.org/assignments/service-names-port-numbers/service-names-port-numbers.xml",
        "selectorId": "192",
        "subCategory": "f3c0f761-d894-4112-ab89-b90fc84fd660",
        "trafficClass": "OPS_ADMIN_MGMT",
        "tunnel": "false"
      }
    ],
    "networkIdentity": [
      {
        "id": "4d7c7fe1-8d83-4e53-9323-4710a6f610fd",
        "instanceId": 14451,
        "authEntityId": 14451,
        "displayName": "14451",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "192",
        "protocol": "UDP",
        "upperPort": 0
      },
      {
        "id": "797f5835-e5a9-4e6a-9d0e-0bc22085c619",
        "instanceId": 14452,
        "authEntityId": 14452,
        "displayName": "14452",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "192",
        "protocol": "TCP",
        "upperPort": 0
      }
    ],
    "parentScalableGroup": {
      "idRef": "5fcd74d4-8726-47f7-a088-c457863da7ef"
    },
    "user": [

    ],
    "userGroup": [

    ]
  },
  {
    "id": "510b9e95-122b-434a-935e-9c41bceeb0c9",
    "instanceId": 15035,
    "authEntityId": 15035,
    "displayName": "15035",
    "authEntityClass": -1909073334,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "createTime": 1517966744078,
    "deployed": false,
    "isSeeded": true,
    "isStale": false,
    "lastUpdateTime": 1517966744078,
    "name": "realm-rusd",
    "namespace": "scalablegroup:application",
    "provisioningState": "DEFINED",
    "qualifier": "application",
    "resourceVersion": 0,
    "targetIdList": [

    ],
    "type": "scalablegroup",
    "cfsChangeInfo": [

    ],
    "customProvisions": [

    ],
    "scalableGroupExternalHandle": "realm-rusd",
    "scalableGroupType": "APPLICATION",
    "securityGroupTag": 0,
    "state": "ACTIVE",
    "vnAgnostic": true,
    "identitySource": {
      "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
      "instanceId": 10010,
      "authEntityId": 10010,
      "displayName": "10010",
      "authEntityClass": 1738953278,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "state": "INACTIVE",
      "type": "NBAR"
    },
    "indicativeNetworkIdentity": [
      {
        "id": "e55777f2-cde1-4351-9c49-dd8a0e4dd3cd",
        "instanceId": 14736,
        "authEntityId": 14736,
        "displayName": "14736",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "688",
        "protocol": "TCP",
        "upperPort": 0
      },
      {
        "id": "450f453a-89b5-4297-906a-421c9f951d9d",
        "instanceId": 14735,
        "authEntityId": 14735,
        "displayName": "14735",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "688",
        "protocol": "UDP",
        "upperPort": 0
      }
    ],
    "networkApplications": [
      {
        "id": "1688a2ab-690a-4538-a9f8-5bd42218d94e",
        "instanceId": 11974,
        "authEntityId": 11974,
        "displayName": "11974",
        "authEntityClass": -217092956,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "appProtocol": "tcp/udp",
        "applicationGroup": "other",
        "applicationType": "DEFAULT",
        "categoryId": "6daa3dfd-3526-407a-88f0-97824b3f3a24",
        "createTime": 1517966744078,
        "encrypted": "false",
        "engineId": "3",
        "helpString": "ApplianceWare management protocol",
        "ignoreConflict": false,
        "lastUpdateTime": 1517966744078,
        "longDescription": "Registered with IANA on port 688 TCP/UDP",
        "name": "realm-rusd",
        "nbarId": "596",
        "p2pTechnology": "false",
        "popularity": 1,
        "rank": 65535,
        "references": "http://www.iana.org/assignments/service-names-port-numbers/service-names-port-numbers.xml",
        "selectorId": "688",
        "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
        "trafficClass": "BULK_DATA",
        "tunnel": "false"
      }
    ],
    "networkIdentity": [
      {
        "id": "c03d4797-40c5-468b-9789-2d5a739b952d",
        "instanceId": 14733,
        "authEntityId": 14733,
        "displayName": "14733",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "688",
        "protocol": "UDP",
        "upperPort": 0
      },
      {
        "id": "c17af81a-7329-41f1-b6c2-50eca4aa67e0",
        "instanceId": 14734,
        "authEntityId": 14734,
        "displayName": "14734",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "688",
        "protocol": "TCP",
        "upperPort": 0
      }
    ],
    "parentScalableGroup": {
      "idRef": "000c15bf-28fe-4fbb-9b69-35e6502721f1"
    },
    "user": [

    ],
    "userGroup": [

    ]
  },
  {
    "id": "512a959b-53ec-4d00-8e5d-f62ccb60e2d3",
    "instanceId": 4723,
    "authEntityId": 4723,
    "displayName": "4723",
    "authEntityClass": -1909073334,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "createTime": 1517966743359,
    "deployed": false,
    "isSeeded": true,
    "isStale": false,
    "lastUpdateTime": 1517966743359,
    "name": "mashable",
    "namespace": "scalablegroup:application",
    "provisioningState": "DEFINED",
    "qualifier": "application",
    "resourceVersion": 0,
    "targetIdList": [

    ],
    "type": "scalablegroup",
    "cfsChangeInfo": [

    ],
    "customProvisions": [

    ],
    "scalableGroupExternalHandle": "mashable",
    "scalableGroupType": "APPLICATION",
    "securityGroupTag": 0,
    "state": "ACTIVE",
    "vnAgnostic": true,
    "identitySource": {
      "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
      "instanceId": 10010,
      "authEntityId": 10010,
      "displayName": "10010",
      "authEntityClass": 1738953278,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "state": "INACTIVE",
      "type": "NBAR"
    },
    "indicativeNetworkIdentity": [

    ],
    "networkApplications": [
      {
        "id": "4e395404-5998-4337-8b67-9d2f24e2b212",
        "instanceId": 11673,
        "authEntityId": 11673,
        "displayName": "11673",
        "authEntityClass": -217092956,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "appProtocol": "tcp/udp",
        "applicationGroup": "other",
        "applicationType": "DEFAULT",
        "categoryId": "878644b5-fe91-45b4-9204-31c84a73ba48",
        "createTime": 1517966743359,
        "encrypted": "false",
        "engineId": "13",
        "helpString": "Mashable - News blog website for social network and new technology.",
        "ignoreConflict": false,
        "lastUpdateTime": 1517966743359,
        "longDescription": "Mashable - News blog website for social network and new technology.",
        "name": "mashable",
        "nbarId": "1587",
        "p2pTechnology": "false",
        "popularity": 3,
        "rank": 65535,
        "references": "http://mashable.com",
        "selectorId": "658",
        "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
        "trafficClass": "TRANSACTIONAL_DATA",
        "tunnel": "false"
      }
    ],
    "networkIdentity": [
      {
        "id": "968eb460-b41b-4a81-b388-280d247e2faa",
        "instanceId": 13828,
        "authEntityId": 13828,
        "displayName": "13828",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "53,5353",
        "protocol": "UDP",
        "upperPort": 0
      },
      {
        "id": "eaabb787-b93e-4ea0-8a90-e96451a0440d",
        "instanceId": 13829,
        "authEntityId": 13829,
        "displayName": "13829",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "53,80,443,5353",
        "protocol": "TCP",
        "upperPort": 0
      }
    ],
    "parentScalableGroup": {
      "idRef": "28e08de4-d7b2-4521-84ce-bc1e5ee0b9cc"
    },
    "user": [

    ],
    "userGroup": [

    ]
  },
  {
    "id": "51368f19-6315-4630-b2de-c25346dbb110",
    "instanceId": 4293,
    "authEntityId": 4293,
    "displayName": "4293",
    "authEntityClass": -1909073334,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "createTime": 1517966742178,
    "deployed": false,
    "isSeeded": true,
    "isStale": false,
    "lastUpdateTime": 1517966742178,
    "name": "courier",
    "namespace": "scalablegroup:application",
    "provisioningState": "DEFINED",
    "qualifier": "application",
    "resourceVersion": 0,
    "targetIdList": [

    ],
    "type": "scalablegroup",
    "cfsChangeInfo": [

    ],
    "customProvisions": [

    ],
    "scalableGroupExternalHandle": "courier",
    "scalableGroupType": "APPLICATION",
    "securityGroupTag": 0,
    "state": "ACTIVE",
    "vnAgnostic": true,
    "identitySource": {
      "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
      "instanceId": 10010,
      "authEntityId": 10010,
      "displayName": "10010",
      "authEntityClass": 1738953278,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "state": "INACTIVE",
      "type": "NBAR"
    },
    "indicativeNetworkIdentity": [
      {
        "id": "82a9f584-5395-4eb5-8a9c-783b3fa0676d",
        "instanceId": 12653,
        "authEntityId": 12653,
        "displayName": "12653",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "530",
        "protocol": "UDP",
        "upperPort": 0
      },
      {
        "id": "d645256c-47c6-44aa-992c-64e1a4cfa54e",
        "instanceId": 12654,
        "authEntityId": 12654,
        "displayName": "12654",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "530",
        "protocol": "TCP",
        "upperPort": 0
      }
    ],
    "networkApplications": [
      {
        "id": "6e3355a1-e465-4946-b3f2-13d4bb79f574",
        "instanceId": 11246,
        "authEntityId": 11246,
        "displayName": "11246",
        "authEntityClass": -217092956,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "appProtocol": "tcp/udp",
        "applicationGroup": "other",
        "applicationType": "DEFAULT",
        "categoryId": "6daa3dfd-3526-407a-88f0-97824b3f3a24",
        "createTime": 1517966742178,
        "encrypted": "false",
        "engineId": "3",
        "helpString": "rpc",
        "ignoreConflict": false,
        "lastUpdateTime": 1517966742178,
        "longDescription": "Courier Mail Server is a mail transfer agent (MTA) server that provides ESMTP, IMAP, POP3, SMAP, webmail, and mailing list services with individual components. It is best known for its IMAP server component. Individual components can be enabled or disabled at will. The Courier Mail Server implements basic web-based calendaring and scheduling services integrated in the webmail module.",
        "name": "courier",
        "nbarId": "448",
        "p2pTechnology": "false",
        "popularity": 1,
        "rank": 65535,
        "references": "http://www.courier-mta.org/",
        "selectorId": "530",
        "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
        "trafficClass": "BULK_DATA",
        "tunnel": "false"
      }
    ],
    "networkIdentity": [
      {
        "id": "b4e7c7a2-d846-4054-832d-10ae6f3ca369",
        "instanceId": 12651,
        "authEntityId": 12651,
        "displayName": "12651",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "530",
        "protocol": "TCP",
        "upperPort": 0
      },
      {
        "id": "8755c29d-8ba8-41a8-b52c-1cd27c344745",
        "instanceId": 12652,
        "authEntityId": 12652,
        "displayName": "12652",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "530",
        "protocol": "UDP",
        "upperPort": 0
      }
    ],
    "parentScalableGroup": {
      "idRef": "000c15bf-28fe-4fbb-9b69-35e6502721f1"
    },
    "user": [

    ],
    "userGroup": [

    ]
  },
  {
    "id": "51eaedb5-5065-4280-8833-13b6938d2c2a",
    "instanceId": 4488,
    "authEntityId": 4488,
    "displayName": "4488",
    "authEntityClass": -1909073334,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "createTime": 1517966742795,
    "deployed": false,
    "isSeeded": true,
    "isStale": false,
    "lastUpdateTime": 1517966742795,
    "name": "gmx-mail",
    "namespace": "scalablegroup:application",
    "provisioningState": "DEFINED",
    "qualifier": "application",
    "resourceVersion": 0,
    "targetIdList": [

    ],
    "type": "scalablegroup",
    "cfsChangeInfo": [

    ],
    "customProvisions": [

    ],
    "scalableGroupExternalHandle": "gmx-mail",
    "scalableGroupType": "APPLICATION",
    "securityGroupTag": 0,
    "state": "ACTIVE",
    "vnAgnostic": true,
    "identitySource": {
      "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
      "instanceId": 10010,
      "authEntityId": 10010,
      "displayName": "10010",
      "authEntityClass": 1738953278,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "state": "INACTIVE",
      "type": "NBAR"
    },
    "indicativeNetworkIdentity": [

    ],
    "networkApplications": [
      {
        "id": "6ca80540-3a60-4407-9c14-4e9d115e7b94",
        "instanceId": 11439,
        "authEntityId": 11439,
        "displayName": "11439",
        "authEntityClass": -217092956,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "appProtocol": "tcp/udp",
        "applicationGroup": "other",
        "applicationType": "DEFAULT",
        "categoryId": "878644b5-fe91-45b4-9204-31c84a73ba48",
        "createTime": 1517966742795,
        "encrypted": "false",
        "engineId": "13",
        "helpString": "GMX Mail - German based webmail service.",
        "ignoreConflict": false,
        "lastUpdateTime": 1517966742795,
        "longDescription": "GMX Mail - German based webmail service.",
        "name": "gmx-mail",
        "nbarId": "1591",
        "p2pTechnology": "false",
        "popularity": 3,
        "rank": 65535,
        "references": "http://gmx.co",
        "selectorId": "662",
        "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
        "trafficClass": "TRANSACTIONAL_DATA",
        "tunnel": "false"
      }
    ],
    "networkIdentity": [
      {
        "id": "07159c12-fa78-44e0-ae7c-4e94934cb18a",
        "instanceId": 13217,
        "authEntityId": 13217,
        "displayName": "13217",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "53,80,443,5353",
        "protocol": "TCP",
        "upperPort": 0
      },
      {
        "id": "8cd67e22-d7ce-41c7-9e2c-f74413bad116",
        "instanceId": 13218,
        "authEntityId": 13218,
        "displayName": "13218",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "53,5353",
        "protocol": "UDP",
        "upperPort": 0
      }
    ],
    "parentScalableGroup": {
      "idRef": "28e08de4-d7b2-4521-84ce-bc1e5ee0b9cc"
    },
    "user": [

    ],
    "userGroup": [

    ]
  },
  {
    "id": "51fddd65-c560-4868-8246-d7a51c6adaca",
    "instanceId": 4810,
    "authEntityId": 4810,
    "displayName": "4810",
    "authEntityClass": -1909073334,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "createTime": 1517966743537,
    "deployed": false,
    "isSeeded": true,
    "isStale": false,
    "lastUpdateTime": 1517966743537,
    "name": "mumps",
    "namespace": "scalablegroup:application",
    "provisioningState": "DEFINED",
    "qualifier": "application",
    "resourceVersion": 0,
    "targetIdList": [

    ],
    "type": "scalablegroup",
    "cfsChangeInfo": [

    ],
    "customProvisions": [

    ],
    "scalableGroupExternalHandle": "mumps",
    "scalableGroupType": "APPLICATION",
    "securityGroupTag": 0,
    "state": "ACTIVE",
    "vnAgnostic": true,
    "identitySource": {
      "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
      "instanceId": 10010,
      "authEntityId": 10010,
      "displayName": "10010",
      "authEntityClass": 1738953278,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "state": "INACTIVE",
      "type": "NBAR"
    },
    "indicativeNetworkIdentity": [
      {
        "id": "07fc710e-2891-4afa-9933-294802783b80",
        "instanceId": 14071,
        "authEntityId": 14071,
        "displayName": "14071",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "188",
        "protocol": "UDP",
        "upperPort": 0
      },
      {
        "id": "7bf4fca9-2ed3-4148-9b4b-e63348587cf2",
        "instanceId": 14072,
        "authEntityId": 14072,
        "displayName": "14072",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "188",
        "protocol": "TCP",
        "upperPort": 0
      }
    ],
    "networkApplications": [
      {
        "id": "37c544b5-59cf-422c-bf98-ac116105cfcc",
        "instanceId": 11759,
        "authEntityId": 11759,
        "displayName": "11759",
        "authEntityClass": -217092956,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "appProtocol": "tcp/udp",
        "applicationGroup": "other",
        "applicationType": "DEFAULT",
        "categoryId": "cb5915d1-afec-4c30-ac9e-069ff382272f",
        "createTime": 1517966743537,
        "encrypted": "false",
        "engineId": "3",
        "helpString": "Plus Fives MUMPS",
        "ignoreConflict": false,
        "lastUpdateTime": 1517966743537,
        "longDescription": "Registered with IANA on port 188 TCP/UDP",
        "name": "mumps",
        "nbarId": "1032",
        "p2pTechnology": "false",
        "popularity": 1,
        "rank": 65535,
        "references": "http://www.iana.org/assignments/service-names-port-numbers/service-names-port-numbers.xml",
        "selectorId": "188",
        "subCategory": "b90786f4-0190-45b8-9924-21b3e9682590",
        "trafficClass": "TRANSACTIONAL_DATA",
        "tunnel": "false"
      }
    ],
    "networkIdentity": [
      {
        "id": "33403921-f643-49eb-a509-61d212848749",
        "instanceId": 14069,
        "authEntityId": 14069,
        "displayName": "14069",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "188",
        "protocol": "UDP",
        "upperPort": 0
      },
      {
        "id": "d7c86abf-0271-48d0-a212-ea2708f4bcc2",
        "instanceId": 14070,
        "authEntityId": 14070,
        "displayName": "14070",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "188",
        "protocol": "TCP",
        "upperPort": 0
      }
    ],
    "parentScalableGroup": {
      "idRef": "8718395e-683e-4a2d-a232-100202751271"
    },
    "user": [

    ],
    "userGroup": [

    ]
  },
  {
    "id": "520a8ba8-fe6f-4571-a370-6820ba287898",
    "instanceId": 4561,
    "authEntityId": 4561,
    "displayName": "4561",
    "authEntityClass": -1909073334,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "createTime": 1517966742991,
    "deployed": false,
    "isSeeded": true,
    "isStale": false,
    "lastUpdateTime": 1517966742991,
    "name": "http-mgmt",
    "namespace": "scalablegroup:application",
    "provisioningState": "DEFINED",
    "qualifier": "application",
    "resourceVersion": 0,
    "targetIdList": [

    ],
    "type": "scalablegroup",
    "cfsChangeInfo": [

    ],
    "customProvisions": [

    ],
    "scalableGroupExternalHandle": "http-mgmt",
    "scalableGroupType": "APPLICATION",
    "securityGroupTag": 0,
    "state": "ACTIVE",
    "vnAgnostic": true,
    "identitySource": {
      "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
      "instanceId": 10010,
      "authEntityId": 10010,
      "displayName": "10010",
      "authEntityClass": 1738953278,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "state": "INACTIVE",
      "type": "NBAR"
    },
    "indicativeNetworkIdentity": [
      {
        "id": "d42bfde1-712a-4d1d-a2c5-928f2e07570f",
        "instanceId": 13393,
        "authEntityId": 13393,
        "displayName": "13393",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "280",
        "protocol": "UDP",
        "upperPort": 0
      },
      {
        "id": "04894184-524b-44c4-8096-4b2dbc694354",
        "instanceId": 13394,
        "authEntityId": 13394,
        "displayName": "13394",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "280",
        "protocol": "TCP",
        "upperPort": 0
      }
    ],
    "networkApplications": [
      {
        "id": "08b8ea38-58a5-4bbc-b5b6-83ff4bb5afc6",
        "instanceId": 11511,
        "authEntityId": 11511,
        "displayName": "11511",
        "authEntityClass": -217092956,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "appProtocol": "tcp/udp",
        "applicationGroup": "other",
        "applicationType": "DEFAULT",
        "categoryId": "1fc17362-9334-4c7b-b752-5afdbb574017",
        "createTime": 1517966742991,
        "encrypted": "false",
        "engineId": "3",
        "helpString": "http-mgmt",
        "ignoreConflict": false,
        "lastUpdateTime": 1517966742991,
        "longDescription": "Registered with IANA on port 280 TCP/UDP",
        "name": "http-mgmt",
        "nbarId": "1141",
        "p2pTechnology": "false",
        "popularity": 1,
        "rank": 65535,
        "references": "http://www.iana.org/assignments/service-names-port-numbers/service-names-port-numbers.xml",
        "selectorId": "280",
        "subCategory": "f3c0f761-d894-4112-ab89-b90fc84fd660",
        "trafficClass": "OPS_ADMIN_MGMT",
        "tunnel": "false"
      }
    ],
    "networkIdentity": [
      {
        "id": "cdeaf026-dc6a-4a98-9860-ef76f7f53d7a",
        "instanceId": 13392,
        "authEntityId": 13392,
        "displayName": "13392",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "280",
        "protocol": "UDP",
        "upperPort": 0
      },
      {
        "id": "cbbd95a6-4e1b-4efe-b939-b35db6535aeb",
        "instanceId": 13391,
        "authEntityId": 13391,
        "displayName": "13391",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "280",
        "protocol": "TCP",
        "upperPort": 0
      }
    ],
    "parentScalableGroup": {
      "idRef": "5fcd74d4-8726-47f7-a088-c457863da7ef"
    },
    "user": [

    ],
    "userGroup": [

    ]
  },
  {
    "id": "5211517b-69d5-4c03-b9d9-97c256b1d950",
    "instanceId": 15292,
    "authEntityId": 15292,
    "displayName": "15292",
    "authEntityClass": -1909073334,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "createTime": 1517966744671,
    "deployed": false,
    "isSeeded": true,
    "isStale": false,
    "lastUpdateTime": 1517966744671,
    "name": "tlsp",
    "namespace": "scalablegroup:application",
    "provisioningState": "DEFINED",
    "qualifier": "application",
    "resourceVersion": 0,
    "targetIdList": [

    ],
    "type": "scalablegroup",
    "cfsChangeInfo": [

    ],
    "customProvisions": [

    ],
    "scalableGroupExternalHandle": "tlsp",
    "scalableGroupType": "APPLICATION",
    "securityGroupTag": 0,
    "state": "ACTIVE",
    "vnAgnostic": true,
    "identitySource": {
      "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
      "instanceId": 10010,
      "authEntityId": 10010,
      "displayName": "10010",
      "authEntityClass": 1738953278,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "state": "INACTIVE",
      "type": "NBAR"
    },
    "indicativeNetworkIdentity": [
      {
        "id": "ba8951e1-d6d0-41ee-b1f6-d432e6120288",
        "instanceId": 17506,
        "authEntityId": 17506,
        "displayName": "17506",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipProtocolNumbers": "56",
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "protocol": "TLSP",
        "upperPort": 0
      }
    ],
    "networkApplications": [
      {
        "id": "2080081c-3843-4b12-b1e1-bd362d057fa4",
        "instanceId": 16235,
        "authEntityId": 16235,
        "displayName": "16235",
        "authEntityClass": -217092956,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "appProtocol": "ip",
        "applicationGroup": "other",
        "applicationType": "DEFAULT",
        "categoryId": "6daa3dfd-3526-407a-88f0-97824b3f3a24",
        "createTime": 1517966744671,
        "encrypted": "unassigned",
        "engineId": "1",
        "helpString": "Transport Layer Security Protocol",
        "ignoreConflict": false,
        "lastUpdateTime": 1517966744671,
        "longDescription": "Transport Layer Security (TLS) and its predecessor, Secure Sockets Layer (SSL), are cryptographic protocols that provide communication security over the Internet. TLS and SSL encrypt the segments of network connections at the Application Layer for the Transport Layer, using asymmetric cryptography for key exchange, symmetric encryption for privacy, and message authentication codes for message integrity.",
        "name": "tlsp",
        "nbarId": "810",
        "p2pTechnology": "unassigned",
        "popularity": 2,
        "rank": 65535,
        "references": "http://www.ietf.org/rfc/rfc5246.txt",
        "selectorId": "56",
        "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
        "trafficClass": "BULK_DATA",
        "tunnel": "unassigned"
      }
    ],
    "networkIdentity": [
      {
        "id": "33b4a25c-9f0e-483d-96ae-50d0ba8430d0",
        "instanceId": 17505,
        "authEntityId": 17505,
        "displayName": "17505",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipProtocolNumbers": "56",
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "protocol": "TLSP",
        "upperPort": 0
      }
    ],
    "parentScalableGroup": {
      "idRef": "000c15bf-28fe-4fbb-9b69-35e6502721f1"
    },
    "user": [

    ],
    "userGroup": [

    ]
  },
  {
    "id": "53b4d04b-2635-4bc3-b19f-25f044c7fd73",
    "instanceId": 4516,
    "authEntityId": 4516,
    "displayName": "4516",
    "authEntityClass": -1909073334,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "createTime": 1517966742863,
    "deployed": false,
    "isSeeded": true,
    "isStale": false,
    "lastUpdateTime": 1517966742863,
    "name": "gtalk",
    "namespace": "scalablegroup:application",
    "provisioningState": "DEFINED",
    "qualifier": "application",
    "resourceVersion": 0,
    "targetIdList": [

    ],
    "type": "scalablegroup",
    "cfsChangeInfo": [

    ],
    "customProvisions": [

    ],
    "scalableGroupExternalHandle": "gtalk",
    "scalableGroupType": "APPLICATION",
    "securityGroupTag": 0,
    "state": "ACTIVE",
    "vnAgnostic": true,
    "identitySource": {
      "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
      "instanceId": 10010,
      "authEntityId": 10010,
      "displayName": "10010",
      "authEntityClass": 1738953278,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "state": "INACTIVE",
      "type": "NBAR"
    },
    "indicativeNetworkIdentity": [

    ],
    "networkApplications": [
      {
        "id": "2fc3af3f-a8d7-413f-a129-1e3a9f0a0ddd",
        "instanceId": 11467,
        "authEntityId": 11467,
        "displayName": "11467",
        "authEntityClass": -217092956,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "appProtocol": "tcp/udp",
        "applicationGroup": "gtalk-group",
        "applicationType": "DEFAULT",
        "categoryId": "0f6cab47-be95-4124-9db3-360ccd55f983",
        "createTime": 1517966742863,
        "encrypted": "true",
        "engineId": "13",
        "helpString": "Protocol integrated into hangouts",
        "ignoreConflict": false,
        "lastUpdateTime": 1517966742863,
        "longDescription": "Google Talk (GTALK) is an instant messaging service that provides both text and voice communication. Gtalk is available as an application or as a plugin in gmail. Gtalk service is  being replaced with \"Hangouts\" service.",
        "name": "gtalk",
        "nbarId": "1030",
        "p2pTechnology": "false",
        "popularity": 10,
        "rank": 65535,
        "references": "http://en.wikipedia.org/wiki/Google_talk",
        "selectorId": "470",
        "subCategory": "118c10a2-e272-47c1-92b9-4814ebe97138",
        "trafficClass": "MULTIMEDIA_CONFERENCING",
        "tunnel": "false"
      }
    ],
    "networkIdentity": [
      {
        "id": "789e1cf2-3ac2-4c92-9efe-574a16d548ae",
        "instanceId": 13285,
        "authEntityId": 13285,
        "displayName": "13285",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "53,3478,5353",
        "protocol": "UDP",
        "upperPort": 0
      },
      {
        "id": "649f6d15-9033-4ffe-bcb7-84799e419933",
        "instanceId": 13286,
        "authEntityId": 13286,
        "displayName": "13286",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "53,80,443,3478,5353",
        "protocol": "TCP",
        "upperPort": 0
      }
    ],
    "parentScalableGroup": {
      "idRef": "fd6264c9-c16a-4311-a335-a3db039256f3"
    },
    "user": [

    ],
    "userGroup": [

    ]
  },
  {
    "id": "53c40940-ece6-4645-8f7d-7bad6ed1d796",
    "instanceId": 15074,
    "authEntityId": 15074,
    "displayName": "15074",
    "authEntityClass": -1909073334,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "createTime": 1517966744174,
    "deployed": false,
    "isSeeded": true,
    "isStale": false,
    "lastUpdateTime": 1517966744174,
    "name": "rtmp",
    "namespace": "scalablegroup:application",
    "provisioningState": "DEFINED",
    "qualifier": "application",
    "resourceVersion": 0,
    "targetIdList": [

    ],
    "type": "scalablegroup",
    "cfsChangeInfo": [

    ],
    "customProvisions": [

    ],
    "scalableGroupExternalHandle": "rtmp",
    "scalableGroupType": "APPLICATION",
    "securityGroupTag": 0,
    "state": "ACTIVE",
    "vnAgnostic": true,
    "identitySource": {
      "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
      "instanceId": 10010,
      "authEntityId": 10010,
      "displayName": "10010",
      "authEntityClass": 1738953278,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "state": "INACTIVE",
      "type": "NBAR"
    },
    "indicativeNetworkIdentity": [

    ],
    "networkApplications": [
      {
        "id": "12f667f7-9bdf-49a6-b1dc-152efc4b9792",
        "instanceId": 16017,
        "authEntityId": 16017,
        "displayName": "16017",
        "authEntityClass": -217092956,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "applicationGroup": "flash-group",
        "applicationType": "DEFAULT",
        "categoryId": "a09ef1b3-ba26-4372-aee8-309d50697e76",
        "createTime": 1517966744174,
        "encrypted": "false",
        "engineId": "13",
        "helpString": "Real Time Messaging Protocol",
        "ignoreConflict": false,
        "lastUpdateTime": 1517966744174,
        "longDescription": "Real Time Messaging Protocol (RTMP) is used for streaming audio, video, and data between a server and a Flash Player. The protocol fragments the data and can be multiplexed (several channels) over a single TCP connection.",
        "name": "rtmp",
        "nbarId": "1067",
        "p2pTechnology": "false",
        "popularity": 9,
        "rank": 65535,
        "references": "http://www.adobe.com/devnet/rtmp.html",
        "selectorId": "418",
        "subCategory": "85a797c0-aef8-4284-91a7-677f33e29abb",
        "trafficClass": "MULTIMEDIA_STREAMING",
        "tunnel": "false"
      }
    ],
    "networkIdentity": [

    ],
    "parentScalableGroup": {
      "idRef": "fd6264c9-c16a-4311-a335-a3db039256f3"
    },
    "user": [

    ],
    "userGroup": [

    ]
  },
  {
    "id": "53dd77f8-613e-4afb-9c4f-5b27031f320f",
    "instanceId": 15210,
    "authEntityId": 15210,
    "displayName": "15210",
    "authEntityClass": -1909073334,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "createTime": 1517966744490,
    "deployed": false,
    "isSeeded": true,
    "isStale": false,
    "lastUpdateTime": 1517966744490,
    "name": "sst",
    "namespace": "scalablegroup:application",
    "provisioningState": "DEFINED",
    "qualifier": "application",
    "resourceVersion": 0,
    "targetIdList": [

    ],
    "type": "scalablegroup",
    "cfsChangeInfo": [

    ],
    "customProvisions": [

    ],
    "scalableGroupExternalHandle": "sst",
    "scalableGroupType": "APPLICATION",
    "securityGroupTag": 0,
    "state": "ACTIVE",
    "vnAgnostic": true,
    "identitySource": {
      "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
      "instanceId": 10010,
      "authEntityId": 10010,
      "displayName": "10010",
      "authEntityClass": 1738953278,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "state": "INACTIVE",
      "type": "NBAR"
    },
    "indicativeNetworkIdentity": [
      {
        "id": "66c534e2-2db3-42f2-88eb-001ee55f4e54",
        "instanceId": 17272,
        "authEntityId": 17272,
        "displayName": "17272",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "266",
        "protocol": "TCP",
        "upperPort": 0
      },
      {
        "id": "a9e9cb86-b6cd-4a5c-9f4d-591132900e09",
        "instanceId": 17273,
        "authEntityId": 17273,
        "displayName": "17273",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "266",
        "protocol": "UDP",
        "upperPort": 0
      }
    ],
    "networkApplications": [
      {
        "id": "f819adbd-2e6d-49ce-8797-f61c8db9b68d",
        "instanceId": 16153,
        "authEntityId": 16153,
        "displayName": "16153",
        "authEntityClass": -217092956,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "appProtocol": "tcp/udp",
        "applicationGroup": "other",
        "applicationType": "DEFAULT",
        "categoryId": "7e19fdea-6222-4c57-9e66-66c2340d6bc3",
        "createTime": 1517966744490,
        "encrypted": "false",
        "engineId": "3",
        "helpString": "SCSI on ST",
        "ignoreConflict": false,
        "lastUpdateTime": 1517966744490,
        "longDescription": "SCSI on Scheduled Transfer (ST) standard (SST) defines a  method of encapsulating SCSI packets inside STP (ST Protocol), providing a possibility for high-performance network-attached storage. SCSI (Small Computer System Interface) is a set of standards for physically connecting and transferring data between computers and peripheral devices.",
        "name": "sst",
        "nbarId": "1138",
        "p2pTechnology": "false",
        "popularity": 1,
        "rank": 65535,
        "references": "https://www.kernel.org/doc/mirror/ols2001/stlinux.pdf",
        "selectorId": "266",
        "subCategory": "697101ea-4e72-4b89-872a-e2d494155c78",
        "trafficClass": "BULK_DATA",
        "tunnel": "false"
      }
    ],
    "networkIdentity": [
      {
        "id": "b6344910-5821-4b28-96ac-15b8b50e6e67",
        "instanceId": 17270,
        "authEntityId": 17270,
        "displayName": "17270",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "266",
        "protocol": "TCP",
        "upperPort": 0
      },
      {
        "id": "8e9926c7-f437-46ee-b9c8-3cbf1c6ae06c",
        "instanceId": 17271,
        "authEntityId": 17271,
        "displayName": "17271",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "266",
        "protocol": "UDP",
        "upperPort": 0
      }
    ],
    "parentScalableGroup": {
      "idRef": "383363b1-4dd5-49d1-bfe4-34daac437cfb"
    },
    "user": [

    ],
    "userGroup": [

    ]
  },
  {
    "id": "53f09027-c183-43d0-b14a-a487f0bad7ae",
    "instanceId": 4247,
    "authEntityId": 4247,
    "displayName": "4247",
    "authEntityClass": -1909073334,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "createTime": 1517966741931,
    "deployed": false,
    "isSeeded": true,
    "isStale": false,
    "lastUpdateTime": 1517966741931,
    "name": "cisco-phone-control",
    "namespace": "scalablegroup:application",
    "provisioningState": "DEFINED",
    "qualifier": "application",
    "resourceVersion": 0,
    "targetIdList": [

    ],
    "type": "scalablegroup",
    "cfsChangeInfo": [

    ],
    "customProvisions": [

    ],
    "scalableGroupExternalHandle": "cisco-phone-control",
    "scalableGroupType": "APPLICATION",
    "securityGroupTag": 0,
    "state": "ACTIVE",
    "vnAgnostic": true,
    "identitySource": {
      "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
      "instanceId": 10010,
      "authEntityId": 10010,
      "displayName": "10010",
      "authEntityClass": 1738953278,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "state": "INACTIVE",
      "type": "NBAR"
    },
    "indicativeNetworkIdentity": [

    ],
    "networkApplications": [
      {
        "id": "91a18bb1-ad4d-4a61-a95b-08a681642936",
        "instanceId": 11201,
        "authEntityId": 11201,
        "displayName": "11201",
        "authEntityClass": -217092956,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "appProtocol": "tcp/udp",
        "applicationGroup": "cisco-phone-group",
        "applicationType": "DEFAULT",
        "categoryId": "38a8e354-e2fe-4f3c-b1c5-2bbb90fb0d99",
        "createTime": 1517966741930,
        "encrypted": "false",
        "engineId": "13",
        "helpString": "Cisco IP Phones and PC-based Unified Communicators Control flow",
        "ignoreConflict": false,
        "lastUpdateTime": 1517966741930,
        "longDescription": "Control flow of Cisco-Ip-phone.",
        "name": "cisco-phone-control",
        "nbarId": "1763",
        "p2pTechnology": "false",
        "popularity": 10,
        "rank": 65535,
        "references": "http://www.cisco.com/en/US/prod/collateral/voicesw/ps6788/phones/ps379/ps1854/product_data_sheet09186a008008884a.html",
        "selectorId": "1763",
        "subCategory": "68c4f989-5735-4e16-8d1c-6a9d89d5df9c",
        "trafficClass": "SIGNALING",
        "tunnel": "false"
      }
    ],
    "networkIdentity": [
      {
        "id": "c9766379-99a8-443f-ac38-47d2de11c5b5",
        "instanceId": 12530,
        "authEntityId": 12530,
        "displayName": "12530",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "5060",
        "protocol": "UDP",
        "upperPort": 0
      },
      {
        "id": "5fcf945c-1a71-4791-9852-5fb2b3f7c7c8",
        "instanceId": 12531,
        "authEntityId": 12531,
        "displayName": "12531",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "2000,2001,2002,5060",
        "protocol": "TCP",
        "upperPort": 0
      }
    ],
    "parentScalableGroup": {
      "idRef": "60f1364d-2a02-4063-8ba1-07817abe0612"
    },
    "user": [

    ],
    "userGroup": [

    ]
  },
  {
    "id": "542620b0-76db-4ea8-9751-27af5c46266b",
    "instanceId": 4362,
    "authEntityId": 4362,
    "displayName": "4362",
    "authEntityClass": -1909073334,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "createTime": 1517966742403,
    "deployed": false,
    "isSeeded": true,
    "isStale": false,
    "lastUpdateTime": 1517966742403,
    "name": "directconnect",
    "namespace": "scalablegroup:application",
    "provisioningState": "DEFINED",
    "qualifier": "application",
    "resourceVersion": 0,
    "targetIdList": [

    ],
    "type": "scalablegroup",
    "cfsChangeInfo": [

    ],
    "customProvisions": [

    ],
    "scalableGroupExternalHandle": "directconnect",
    "scalableGroupType": "APPLICATION",
    "securityGroupTag": 0,
    "state": "ACTIVE",
    "vnAgnostic": true,
    "identitySource": {
      "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
      "instanceId": 10010,
      "authEntityId": 10010,
      "displayName": "10010",
      "authEntityClass": 1738953278,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "state": "INACTIVE",
      "type": "NBAR"
    },
    "indicativeNetworkIdentity": [
      {
        "id": "08804789-e3bf-4492-9369-0e3210de37f7",
        "instanceId": 12866,
        "authEntityId": 12866,
        "displayName": "12866",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "411,412,413",
        "protocol": "TCP",
        "upperPort": 0
      }
    ],
    "networkApplications": [
      {
        "id": "ee1e07d4-c974-4c83-bb41-1957a631d8fb",
        "instanceId": 11313,
        "authEntityId": 11313,
        "displayName": "11313",
        "authEntityClass": -217092956,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "appProtocol": "tcp",
        "applicationGroup": "other",
        "applicationType": "DEFAULT",
        "categoryId": "b33b2761-d981-4734-810a-a6a1c18ab352",
        "createTime": 1517966742403,
        "encrypted": "false",
        "engineId": "13",
        "helpString": "Direct Connect Version 2.0, peer-to-peer file sharing protocol",
        "ignoreConflict": false,
        "lastUpdateTime": 1517966742403,
        "longDescription": "Direct Connect is a peer-to-peer (P2P) file sharing protocol. Clients connect to a main hub that mediates them to other clients in order to download files. The hubs hold a database of clients and files and mediate the clients. Once clients are connected in a P2P manner, they can download files and chat with one another.",
        "name": "directconnect",
        "nbarId": "70",
        "p2pTechnology": "true",
        "popularity": 5,
        "rank": 65535,
        "references": "http://wiki.gusari.org",
        "selectorId": "70",
        "subCategory": "79fe7198-9ce3-4c01-9b58-45a8df3d9513",
        "trafficClass": "BULK_DATA",
        "tunnel": "false"
      }
    ],
    "networkIdentity": [
      {
        "id": "6b83a201-57ce-45e9-ab0d-2d202334d955",
        "instanceId": 12865,
        "authEntityId": 12865,
        "displayName": "12865",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "80,411,412,413",
        "protocol": "TCP",
        "upperPort": 0
      }
    ],
    "parentScalableGroup": {
      "idRef": "7a1a3bbb-dc84-4a35-82a4-7e650c4c4761"
    },
    "user": [

    ],
    "userGroup": [

    ]
  },
  {
    "id": "54273d4a-4b24-4d1c-827d-cb28fdc09d82",
    "instanceId": 15075,
    "authEntityId": 15075,
    "displayName": "15075",
    "authEntityClass": -1909073334,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "createTime": 1517966744177,
    "deployed": false,
    "isSeeded": true,
    "isStale": false,
    "lastUpdateTime": 1517966744177,
    "name": "rtmpe",
    "namespace": "scalablegroup:application",
    "provisioningState": "DEFINED",
    "qualifier": "application",
    "resourceVersion": 0,
    "targetIdList": [

    ],
    "type": "scalablegroup",
    "cfsChangeInfo": [

    ],
    "customProvisions": [

    ],
    "scalableGroupExternalHandle": "rtmpe",
    "scalableGroupType": "APPLICATION",
    "securityGroupTag": 0,
    "state": "ACTIVE",
    "vnAgnostic": true,
    "identitySource": {
      "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
      "instanceId": 10010,
      "authEntityId": 10010,
      "displayName": "10010",
      "authEntityClass": 1738953278,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "state": "INACTIVE",
      "type": "NBAR"
    },
    "indicativeNetworkIdentity": [

    ],
    "networkApplications": [
      {
        "id": "e33ec259-1961-4b0c-8b88-21a117706080",
        "instanceId": 16018,
        "authEntityId": 16018,
        "displayName": "16018",
        "authEntityClass": -217092956,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "applicationGroup": "flash-group",
        "applicationType": "DEFAULT",
        "categoryId": "a09ef1b3-ba26-4372-aee8-309d50697e76",
        "createTime": 1517966744177,
        "encrypted": "true",
        "engineId": "13",
        "helpString": "Encrypted Real Time Messaging Protocol",
        "ignoreConflict": false,
        "lastUpdateTime": 1517966744177,
        "longDescription": "Real Time Messaging Protocol Encrypted (RTMPE) is a 128-bit encrypted RTMP protocol developed by Adobe systems for securing the stream data between flash client and server.  Adobe developed RTMPE as a lighter weight alternative to SSL, to make it more practical for high-traffic sites to serve encrypted content.",
        "name": "rtmpe",
        "nbarId": "1416",
        "p2pTechnology": "false",
        "popularity": 9,
        "rank": 65535,
        "references": "http://help.adobe.com/en_US/flashlite/dev/4/WSa2ec538c80d45833-4e519ada123e088b6aa-8000.html",
        "selectorId": "487",
        "subCategory": "85a797c0-aef8-4284-91a7-677f33e29abb",
        "trafficClass": "MULTIMEDIA_STREAMING",
        "tunnel": "false"
      }
    ],
    "networkIdentity": [

    ],
    "parentScalableGroup": {
      "idRef": "fd6264c9-c16a-4311-a335-a3db039256f3"
    },
    "user": [

    ],
    "userGroup": [

    ]
  },
  {
    "id": "5462c11a-0604-49ae-b1fd-1c17bac107ff",
    "instanceId": 4499,
    "authEntityId": 4499,
    "displayName": "4499",
    "authEntityClass": -1909073334,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "createTime": 1517966742823,
    "deployed": false,
    "isSeeded": true,
    "isStale": false,
    "lastUpdateTime": 1517966742823,
    "name": "google-plus",
    "namespace": "scalablegroup:application",
    "provisioningState": "DEFINED",
    "qualifier": "application",
    "resourceVersion": 0,
    "targetIdList": [

    ],
    "type": "scalablegroup",
    "cfsChangeInfo": [

    ],
    "customProvisions": [

    ],
    "scalableGroupExternalHandle": "google-plus",
    "scalableGroupType": "APPLICATION",
    "securityGroupTag": 0,
    "state": "ACTIVE",
    "vnAgnostic": true,
    "identitySource": {
      "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
      "instanceId": 10010,
      "authEntityId": 10010,
      "displayName": "10010",
      "authEntityClass": 1738953278,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "state": "INACTIVE",
      "type": "NBAR"
    },
    "indicativeNetworkIdentity": [

    ],
    "networkApplications": [
      {
        "id": "bb108995-ff53-4e84-a15f-98519e7911fc",
        "instanceId": 11450,
        "authEntityId": 11450,
        "displayName": "11450",
        "authEntityClass": -217092956,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "appProtocol": "tcp/udp",
        "applicationGroup": "google-group",
        "applicationType": "DEFAULT",
        "categoryId": "9eb6d680-796f-478a-9106-1151f00f7eff",
        "createTime": 1517966742823,
        "encrypted": "true",
        "engineId": "13",
        "helpString": "Google-plus, social networking web and mobile application",
        "ignoreConflict": false,
        "lastUpdateTime": 1517966742823,
        "longDescription": "Google plus is a social networking web and mobile application provided by Google Inc. It enables the user to open account, invite other friends and sort them into circles. It also has features such as chat, video chat, games, apps, photos sharing and more.",
        "name": "google-plus",
        "nbarId": "1457",
        "p2pTechnology": "true",
        "popularity": 10,
        "rank": 65535,
        "references": "https://plus.google.com/",
        "selectorId": "521",
        "subCategory": "aab99e59-8589-4a61-933e-f01bff556d78",
        "trafficClass": "TRANSACTIONAL_DATA",
        "tunnel": "false"
      }
    ],
    "networkIdentity": [
      {
        "id": "dcbfb46a-09e2-460b-a165-106a69d239fe",
        "instanceId": 13241,
        "authEntityId": 13241,
        "displayName": "13241",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "53,5353",
        "protocol": "UDP",
        "upperPort": 0
      },
      {
        "id": "baa3ef5a-e28c-466a-abd8-6e202dc3fabb",
        "instanceId": 13242,
        "authEntityId": 13242,
        "displayName": "13242",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "53,80,443,5353",
        "protocol": "TCP",
        "upperPort": 0
      }
    ],
    "parentScalableGroup": {
      "idRef": "82348eb1-d8b8-4daf-8532-15e1a5f9cdd0"
    },
    "user": [

    ],
    "userGroup": [

    ]
  },
  {
    "id": "5466341a-eab5-4834-91c7-bce08740fec8",
    "instanceId": 4363,
    "authEntityId": 4363,
    "displayName": "4363",
    "authEntityClass": -1909073334,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "createTime": 1517966742406,
    "deployed": false,
    "isSeeded": true,
    "isStale": false,
    "lastUpdateTime": 1517966742406,
    "name": "directplay",
    "namespace": "scalablegroup:application",
    "provisioningState": "DEFINED",
    "qualifier": "application",
    "resourceVersion": 0,
    "targetIdList": [

    ],
    "type": "scalablegroup",
    "cfsChangeInfo": [

    ],
    "customProvisions": [

    ],
    "scalableGroupExternalHandle": "directplay",
    "scalableGroupType": "APPLICATION",
    "securityGroupTag": 0,
    "state": "ACTIVE",
    "vnAgnostic": true,
    "identitySource": {
      "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
      "instanceId": 10010,
      "authEntityId": 10010,
      "displayName": "10010",
      "authEntityClass": 1738953278,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "state": "INACTIVE",
      "type": "NBAR"
    },
    "indicativeNetworkIdentity": [
      {
        "id": "031cbf3e-3efc-4c7a-87e8-5ce9f79f4277",
        "instanceId": 12869,
        "authEntityId": 12869,
        "displayName": "12869",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "2234",
        "protocol": "UDP",
        "upperPort": 0
      },
      {
        "id": "29a75322-5de8-4cb1-93e9-6f393b1aaad8",
        "instanceId": 12870,
        "authEntityId": 12870,
        "displayName": "12870",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "2234",
        "protocol": "TCP",
        "upperPort": 0
      }
    ],
    "networkApplications": [
      {
        "id": "cc9b7a8e-b9fe-471b-b6ef-7b8bad7e7ea1",
        "instanceId": 11314,
        "authEntityId": 11314,
        "displayName": "11314",
        "authEntityClass": -217092956,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "appProtocol": "tcp/udp",
        "applicationGroup": "other",
        "applicationType": "DEFAULT",
        "categoryId": "3cd50dd8-1b2e-46c1-b672-41c14bfa364b",
        "createTime": 1517966742406,
        "encrypted": "false",
        "engineId": "3",
        "helpString": "DirectPlay",
        "ignoreConflict": false,
        "lastUpdateTime": 1517966742406,
        "longDescription": "DirectPlay is part of Microsoft's DirectX API. It is a network communication library intended for computer game development. DirectPlay features a set of tools that allow players to find game sessions and sites to manage the flow of information between hosts and players. It provides a way for applications to communicate with each other, regardless of the underlying online service or protocol.",
        "name": "directplay",
        "nbarId": "716",
        "p2pTechnology": "false",
        "popularity": 2,
        "rank": 65535,
        "references": "http://en.wikipedia.org/wiki/Directplay",
        "selectorId": "2234",
        "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
        "trafficClass": "BULK_DATA",
        "tunnel": "false"
      }
    ],
    "networkIdentity": [
      {
        "id": "cd22b14f-e750-4b27-9426-c556b87dd8ba",
        "instanceId": 12867,
        "authEntityId": 12867,
        "displayName": "12867",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "2234",
        "protocol": "TCP",
        "upperPort": 0
      },
      {
        "id": "6320b56b-6f16-4323-8d0a-0d82ff33e710",
        "instanceId": 12868,
        "authEntityId": 12868,
        "displayName": "12868",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "2234",
        "protocol": "UDP",
        "upperPort": 0
      }
    ],
    "parentScalableGroup": {
      "idRef": "0bd0e6c9-5083-492b-97cb-a88928bee99c"
    },
    "user": [

    ],
    "userGroup": [

    ]
  },
  {
    "id": "547e61e9-3a8a-4777-bc66-7c9070761892",
    "instanceId": 4593,
    "authEntityId": 4593,
    "displayName": "4593",
    "authEntityClass": -1909073334,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "createTime": 1517966743074,
    "deployed": false,
    "isSeeded": true,
    "isStale": false,
    "lastUpdateTime": 1517966743074,
    "name": "iiop",
    "namespace": "scalablegroup:application",
    "provisioningState": "DEFINED",
    "qualifier": "application",
    "resourceVersion": 0,
    "targetIdList": [

    ],
    "type": "scalablegroup",
    "cfsChangeInfo": [

    ],
    "customProvisions": [

    ],
    "scalableGroupExternalHandle": "iiop",
    "scalableGroupType": "APPLICATION",
    "securityGroupTag": 0,
    "state": "ACTIVE",
    "vnAgnostic": true,
    "identitySource": {
      "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
      "instanceId": 10010,
      "authEntityId": 10010,
      "displayName": "10010",
      "authEntityClass": 1738953278,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "state": "INACTIVE",
      "type": "NBAR"
    },
    "indicativeNetworkIdentity": [
      {
        "id": "3c1c0ed2-cf02-4313-ad9f-5d32cad7a752",
        "instanceId": 13493,
        "authEntityId": 13493,
        "displayName": "13493",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "535",
        "protocol": "TCP",
        "upperPort": 0
      },
      {
        "id": "6b5af749-2080-4585-bd14-97f91d16d9e5",
        "instanceId": 13494,
        "authEntityId": 13494,
        "displayName": "13494",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "535",
        "protocol": "UDP",
        "upperPort": 0
      }
    ],
    "networkApplications": [
      {
        "id": "a59bc330-5c60-4037-9a81-1cabacf4308c",
        "instanceId": 11543,
        "authEntityId": 11543,
        "displayName": "11543",
        "authEntityClass": -217092956,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "appProtocol": "tcp/udp",
        "applicationGroup": "corba-group",
        "applicationType": "DEFAULT",
        "categoryId": "58aa61dc-dd95-4a6d-9c7c-0ebf16da6dde",
        "createTime": 1517966743074,
        "encrypted": "false",
        "engineId": "3",
        "helpString": "iiop",
        "ignoreConflict": false,
        "lastUpdateTime": 1517966743074,
        "longDescription": "General Inter-ORB Protocol (GIOP) is the abstract protocol by which object request brokers (ORBs) communicate. Standards associated with the protocol are maintained by the Object Management Group (OMG).",
        "name": "iiop",
        "nbarId": "453",
        "p2pTechnology": "false",
        "popularity": 4,
        "rank": 65535,
        "references": "http://www2.informatik.hu-berlin.de/~obecker/Lehre/SS2001/CORBA/specs/01-02-51.pdf",
        "selectorId": "535",
        "subCategory": "b90786f4-0190-45b8-9924-21b3e9682590",
        "trafficClass": "TRANSACTIONAL_DATA",
        "tunnel": "false"
      }
    ],
    "networkIdentity": [
      {
        "id": "743e47ce-43a2-4118-9837-f2b88eefd238",
        "instanceId": 13491,
        "authEntityId": 13491,
        "displayName": "13491",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "535",
        "protocol": "TCP",
        "upperPort": 0
      },
      {
        "id": "5e2cfc87-e081-4aa0-98fd-bb922499d63c",
        "instanceId": 13492,
        "authEntityId": 13492,
        "displayName": "13492",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "535",
        "protocol": "UDP",
        "upperPort": 0
      }
    ],
    "parentScalableGroup": {
      "idRef": "cff11752-fafb-4e22-9e6f-11f44f59a9a3"
    },
    "user": [

    ],
    "userGroup": [

    ]
  },
  {
    "id": "549581b9-7b8f-40a0-befd-4624b87d6b63",
    "instanceId": 4900,
    "authEntityId": 4900,
    "displayName": "4900",
    "authEntityClass": -1909073334,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "createTime": 1517966743713,
    "deployed": false,
    "isSeeded": true,
    "isStale": false,
    "lastUpdateTime": 1517966743713,
    "name": "ocsp",
    "namespace": "scalablegroup:application",
    "provisioningState": "DEFINED",
    "qualifier": "application",
    "resourceVersion": 0,
    "targetIdList": [

    ],
    "type": "scalablegroup",
    "cfsChangeInfo": [

    ],
    "customProvisions": [

    ],
    "scalableGroupExternalHandle": "ocsp",
    "scalableGroupType": "APPLICATION",
    "securityGroupTag": 0,
    "state": "ACTIVE",
    "vnAgnostic": true,
    "identitySource": {
      "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
      "instanceId": 10010,
      "authEntityId": 10010,
      "displayName": "10010",
      "authEntityClass": 1738953278,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "state": "INACTIVE",
      "type": "NBAR"
    },
    "indicativeNetworkIdentity": [

    ],
    "networkApplications": [
      {
        "id": "250521fb-c862-420c-b523-4cd3c1348bd5",
        "instanceId": 11849,
        "authEntityId": 11849,
        "displayName": "11849",
        "authEntityClass": -217092956,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "appProtocol": "tcp",
        "applicationGroup": "other",
        "applicationType": "DEFAULT",
        "categoryId": "1fc17362-9334-4c7b-b752-5afdbb574017",
        "createTime": 1517966743713,
        "encrypted": "false",
        "engineId": "13",
        "helpString": "OCSP - Online Certificate Status Protocol",
        "ignoreConflict": false,
        "lastUpdateTime": 1517966743713,
        "longDescription": "OCSP - Online Certificate Status Protocol",
        "name": "ocsp",
        "nbarId": "1750",
        "p2pTechnology": "false",
        "popularity": 3,
        "rank": 65535,
        "references": "https://tools.ietf.org/html/rfc6960",
        "selectorId": "819",
        "subCategory": "e390124a-0fd6-4253-b68e-4b0c1af8cb4b",
        "trafficClass": "TRANSACTIONAL_DATA",
        "tunnel": "false"
      }
    ],
    "networkIdentity": [
      {
        "id": "777c9e92-7470-4b30-a9da-d9e01f221854",
        "instanceId": 14355,
        "authEntityId": 14355,
        "displayName": "14355",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "80",
        "protocol": "TCP",
        "upperPort": 0
      }
    ],
    "parentScalableGroup": {
      "idRef": "7788bf2d-e289-4597-b500-17aacb7e820e"
    },
    "user": [

    ],
    "userGroup": [

    ]
  },
  {
    "id": "54a059b6-d295-45ad-b34d-76934b9153b2",
    "instanceId": 15250,
    "authEntityId": 15250,
    "displayName": "15250",
    "authEntityClass": -1909073334,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "createTime": 1517966744580,
    "deployed": false,
    "isSeeded": true,
    "isStale": false,
    "lastUpdateTime": 1517966744580,
    "name": "tacnews",
    "namespace": "scalablegroup:application",
    "provisioningState": "DEFINED",
    "qualifier": "application",
    "resourceVersion": 0,
    "targetIdList": [

    ],
    "type": "scalablegroup",
    "cfsChangeInfo": [

    ],
    "customProvisions": [

    ],
    "scalableGroupExternalHandle": "tacnews",
    "scalableGroupType": "APPLICATION",
    "securityGroupTag": 0,
    "state": "ACTIVE",
    "vnAgnostic": true,
    "identitySource": {
      "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
      "instanceId": 10010,
      "authEntityId": 10010,
      "displayName": "10010",
      "authEntityClass": 1738953278,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "state": "INACTIVE",
      "type": "NBAR"
    },
    "indicativeNetworkIdentity": [
      {
        "id": "b063774e-4b0c-44c9-ae91-dd6607d383b6",
        "instanceId": 17390,
        "authEntityId": 17390,
        "displayName": "17390",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "98",
        "protocol": "TCP",
        "upperPort": 0
      },
      {
        "id": "c56739c7-d224-46b6-8c18-4ae06f581df4",
        "instanceId": 17391,
        "authEntityId": 17391,
        "displayName": "17391",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "98",
        "protocol": "UDP",
        "upperPort": 0
      }
    ],
    "networkApplications": [
      {
        "id": "bcd75907-c6b7-4ec2-97c6-e70cdaab9a5d",
        "instanceId": 16193,
        "authEntityId": 16193,
        "displayName": "16193",
        "authEntityClass": -217092956,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "appProtocol": "tcp/udp",
        "applicationGroup": "other",
        "applicationType": "DEFAULT",
        "categoryId": "6daa3dfd-3526-407a-88f0-97824b3f3a24",
        "createTime": 1517966744580,
        "encrypted": "false",
        "engineId": "3",
        "helpString": "TAC News",
        "ignoreConflict": false,
        "lastUpdateTime": 1517966744580,
        "longDescription": "Registered with IANA on port 98 TCP/UDP",
        "name": "tacnews",
        "nbarId": "969",
        "p2pTechnology": "false",
        "popularity": 1,
        "rank": 65535,
        "references": "http://www.iana.org/assignments/service-names-port-numbers/service-names-port-numbers.xml",
        "selectorId": "98",
        "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
        "trafficClass": "BULK_DATA",
        "tunnel": "false"
      }
    ],
    "networkIdentity": [
      {
        "id": "a41b0a59-4eac-4f0e-8bf2-749388490869",
        "instanceId": 17388,
        "authEntityId": 17388,
        "displayName": "17388",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "98",
        "protocol": "UDP",
        "upperPort": 0
      },
      {
        "id": "98013f85-6682-46d3-9e0f-0ff2e8d962cb",
        "instanceId": 17389,
        "authEntityId": 17389,
        "displayName": "17389",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "98",
        "protocol": "TCP",
        "upperPort": 0
      }
    ],
    "parentScalableGroup": {
      "idRef": "000c15bf-28fe-4fbb-9b69-35e6502721f1"
    },
    "user": [

    ],
    "userGroup": [

    ]
  },
  {
    "id": "54a64a47-da7b-4383-9196-17e8e2aef0e4",
    "instanceId": 4302,
    "authEntityId": 4302,
    "displayName": "4302",
    "authEntityClass": -1909073334,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "createTime": 1517966742208,
    "deployed": false,
    "isSeeded": true,
    "isStale": false,
    "lastUpdateTime": 1517966742208,
    "name": "creativeserver",
    "namespace": "scalablegroup:application",
    "provisioningState": "DEFINED",
    "qualifier": "application",
    "resourceVersion": 0,
    "targetIdList": [

    ],
    "type": "scalablegroup",
    "cfsChangeInfo": [

    ],
    "customProvisions": [

    ],
    "scalableGroupExternalHandle": "creativeserver",
    "scalableGroupType": "APPLICATION",
    "securityGroupTag": 0,
    "state": "ACTIVE",
    "vnAgnostic": true,
    "identitySource": {
      "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
      "instanceId": 10010,
      "authEntityId": 10010,
      "displayName": "10010",
      "authEntityClass": 1738953278,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "state": "INACTIVE",
      "type": "NBAR"
    },
    "indicativeNetworkIdentity": [
      {
        "id": "a587714a-c926-4043-abde-fb28a7b628c0",
        "instanceId": 12678,
        "authEntityId": 12678,
        "displayName": "12678",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "453",
        "protocol": "TCP",
        "upperPort": 0
      },
      {
        "id": "66e4e45b-3d67-4b94-bbd2-4ac3abb3d614",
        "instanceId": 12679,
        "authEntityId": 12679,
        "displayName": "12679",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "453",
        "protocol": "UDP",
        "upperPort": 0
      }
    ],
    "networkApplications": [
      {
        "id": "c596a902-523b-461a-ada7-adc144eb10d3",
        "instanceId": 11255,
        "authEntityId": 11255,
        "displayName": "11255",
        "authEntityClass": -217092956,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "appProtocol": "tcp/udp",
        "applicationGroup": "other",
        "applicationType": "DEFAULT",
        "categoryId": "6daa3dfd-3526-407a-88f0-97824b3f3a24",
        "createTime": 1517966742207,
        "encrypted": "false",
        "engineId": "3",
        "helpString": "creativeserver",
        "ignoreConflict": false,
        "lastUpdateTime": 1517966742207,
        "longDescription": "Registered with IANA on port 453 TCP/UDP",
        "name": "creativeserver",
        "nbarId": "368",
        "p2pTechnology": "false",
        "popularity": 1,
        "rank": 65535,
        "references": "http://www.iana.org/assignments/service-names-port-numbers/service-names-port-numbers.xml",
        "selectorId": "453",
        "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
        "trafficClass": "BULK_DATA",
        "tunnel": "false"
      }
    ],
    "networkIdentity": [
      {
        "id": "677572b0-b38d-4733-a31c-e485914138f4",
        "instanceId": 12676,
        "authEntityId": 12676,
        "displayName": "12676",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "453",
        "protocol": "TCP",
        "upperPort": 0
      },
      {
        "id": "7554d7b1-a1ce-4109-8e77-80f08a081df5",
        "instanceId": 12677,
        "authEntityId": 12677,
        "displayName": "12677",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "453",
        "protocol": "UDP",
        "upperPort": 0
      }
    ],
    "parentScalableGroup": {
      "idRef": "000c15bf-28fe-4fbb-9b69-35e6502721f1"
    },
    "user": [

    ],
    "userGroup": [

    ]
  },
  {
    "id": "54bef908-0807-479a-b8db-7623fd1a8e4a",
    "instanceId": 4342,
    "authEntityId": 4342,
    "displayName": "4342",
    "authEntityClass": -1909073334,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "createTime": 1517966742343,
    "deployed": false,
    "isSeeded": true,
    "isStale": false,
    "lastUpdateTime": 1517966742343,
    "name": "ddx",
    "namespace": "scalablegroup:application",
    "provisioningState": "DEFINED",
    "qualifier": "application",
    "resourceVersion": 0,
    "targetIdList": [

    ],
    "type": "scalablegroup",
    "cfsChangeInfo": [

    ],
    "customProvisions": [

    ],
    "scalableGroupExternalHandle": "ddx",
    "scalableGroupType": "APPLICATION",
    "securityGroupTag": 0,
    "state": "ACTIVE",
    "vnAgnostic": true,
    "identitySource": {
      "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
      "instanceId": 10010,
      "authEntityId": 10010,
      "displayName": "10010",
      "authEntityClass": 1738953278,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "state": "INACTIVE",
      "type": "NBAR"
    },
    "indicativeNetworkIdentity": [
      {
        "id": "3c0c589f-026e-464c-a175-97281a8c1145",
        "instanceId": 12801,
        "authEntityId": 12801,
        "displayName": "12801",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipProtocolNumbers": "116",
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "protocol": "DDX",
        "upperPort": 0
      }
    ],
    "networkApplications": [
      {
        "id": "3d0c0465-9381-44fb-94e2-c86798d28615",
        "instanceId": 11293,
        "authEntityId": 11293,
        "displayName": "11293",
        "authEntityClass": -217092956,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "appProtocol": "ip",
        "applicationGroup": "other",
        "applicationType": "DEFAULT",
        "categoryId": "6daa3dfd-3526-407a-88f0-97824b3f3a24",
        "createTime": 1517966742343,
        "encrypted": "unassigned",
        "engineId": "1",
        "helpString": "D-II Data Exchange",
        "ignoreConflict": false,
        "lastUpdateTime": 1517966742343,
        "longDescription": "Registered with IANA as IP Protocol 116",
        "name": "ddx",
        "nbarId": "870",
        "p2pTechnology": "unassigned",
        "popularity": 1,
        "rank": 65535,
        "references": "http://www.iana.org/assignments/protocol-numbers/protocol-numbers.xml",
        "selectorId": "116",
        "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
        "trafficClass": "BULK_DATA",
        "tunnel": "unassigned"
      }
    ],
    "networkIdentity": [
      {
        "id": "dbf32169-d748-419d-a91c-0add4d3c131b",
        "instanceId": 12800,
        "authEntityId": 12800,
        "displayName": "12800",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipProtocolNumbers": "116",
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "protocol": "DDX",
        "upperPort": 0
      }
    ],
    "parentScalableGroup": {
      "idRef": "000c15bf-28fe-4fbb-9b69-35e6502721f1"
    },
    "user": [

    ],
    "userGroup": [

    ]
  },
  {
    "id": "551ff48a-f476-4e80-aa07-7ec9185e3c4a",
    "instanceId": 5001,
    "authEntityId": 5001,
    "displayName": "5001",
    "authEntityClass": -1909073334,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "createTime": 1517966744021,
    "deployed": false,
    "isSeeded": true,
    "isStale": false,
    "lastUpdateTime": 1517966744021,
    "name": "qbikgdp",
    "namespace": "scalablegroup:application",
    "provisioningState": "DEFINED",
    "qualifier": "application",
    "resourceVersion": 0,
    "targetIdList": [

    ],
    "type": "scalablegroup",
    "cfsChangeInfo": [

    ],
    "customProvisions": [

    ],
    "scalableGroupExternalHandle": "qbikgdp",
    "scalableGroupType": "APPLICATION",
    "securityGroupTag": 0,
    "state": "ACTIVE",
    "vnAgnostic": true,
    "identitySource": {
      "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
      "instanceId": 10010,
      "authEntityId": 10010,
      "displayName": "10010",
      "authEntityClass": 1738953278,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "state": "INACTIVE",
      "type": "NBAR"
    },
    "indicativeNetworkIdentity": [
      {
        "id": "e08d5299-4bc0-4e4a-8ecc-6f73beac810c",
        "instanceId": 14662,
        "authEntityId": 14662,
        "displayName": "14662",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "368",
        "protocol": "UDP",
        "upperPort": 0
      },
      {
        "id": "a88ed038-aea0-4bd8-9f70-de2b8545d30e",
        "instanceId": 14663,
        "authEntityId": 14663,
        "displayName": "14663",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "368",
        "protocol": "TCP",
        "upperPort": 0
      }
    ],
    "networkApplications": [
      {
        "id": "b9d2bebe-7e2e-48af-811d-06a2bc5f7ab8",
        "instanceId": 11950,
        "authEntityId": 11950,
        "displayName": "11950",
        "authEntityClass": -217092956,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "appProtocol": "tcp/udp",
        "applicationGroup": "other",
        "applicationType": "DEFAULT",
        "categoryId": "1fc17362-9334-4c7b-b752-5afdbb574017",
        "createTime": 1517966744021,
        "encrypted": "false",
        "engineId": "3",
        "helpString": "qbikgdp",
        "ignoreConflict": false,
        "lastUpdateTime": 1517966744021,
        "longDescription": "Generic Discovery Protocol (GDP) is a protocol developed for finding or discovering Internet connectivity servers (such as WinGate). It is used by both the WinGate Internet Client (WGIC) and GateKeeper for finding WinGate. It is designed to be fully automatic, requiring no user intervention.",
        "name": "qbikgdp",
        "nbarId": "284",
        "p2pTechnology": "false",
        "popularity": 1,
        "rank": 65535,
        "references": "http://www.redline-software.com/eng/support/docs/wingate/GenericDiscoveryProtocol.php",
        "selectorId": "368",
        "subCategory": "f3c0f761-d894-4112-ab89-b90fc84fd660",
        "trafficClass": "OPS_ADMIN_MGMT",
        "tunnel": "false"
      }
    ],
    "networkIdentity": [
      {
        "id": "cf6a0797-ff04-4113-a671-d0a55c8c5415",
        "instanceId": 14660,
        "authEntityId": 14660,
        "displayName": "14660",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "368",
        "protocol": "TCP",
        "upperPort": 0
      },
      {
        "id": "e071dff8-a88a-48c8-8063-ef33055a3803",
        "instanceId": 14661,
        "authEntityId": 14661,
        "displayName": "14661",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "368",
        "protocol": "UDP",
        "upperPort": 0
      }
    ],
    "parentScalableGroup": {
      "idRef": "5fcd74d4-8726-47f7-a088-c457863da7ef"
    },
    "user": [

    ],
    "userGroup": [

    ]
  },
  {
    "id": "552cb45a-5d7d-4042-b54a-cfb8c05dc122",
    "instanceId": 4787,
    "authEntityId": 4787,
    "displayName": "4787",
    "authEntityClass": -1909073334,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "createTime": 1517966743491,
    "deployed": false,
    "isSeeded": true,
    "isStale": false,
    "lastUpdateTime": 1517966743491,
    "name": "ms-services",
    "namespace": "scalablegroup:application",
    "provisioningState": "DEFINED",
    "qualifier": "application",
    "resourceVersion": 0,
    "targetIdList": [

    ],
    "type": "scalablegroup",
    "cfsChangeInfo": [

    ],
    "customProvisions": [

    ],
    "scalableGroupExternalHandle": "ms-services",
    "scalableGroupType": "APPLICATION",
    "securityGroupTag": 0,
    "state": "ACTIVE",
    "vnAgnostic": true,
    "identitySource": {
      "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
      "instanceId": 10010,
      "authEntityId": 10010,
      "displayName": "10010",
      "authEntityClass": 1738953278,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "state": "INACTIVE",
      "type": "NBAR"
    },
    "indicativeNetworkIdentity": [

    ],
    "networkApplications": [
      {
        "id": "f0216705-f369-46fd-9f48-5bb901069f49",
        "instanceId": 11736,
        "authEntityId": 11736,
        "displayName": "11736",
        "authEntityClass": -217092956,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "appProtocol": "tcp/udp",
        "applicationGroup": "ms-cloud-group",
        "applicationType": "DEFAULT",
        "categoryId": "6daa3dfd-3526-407a-88f0-97824b3f3a24",
        "createTime": 1517966743491,
        "encrypted": "true",
        "engineId": "13",
        "helpString": "Microsoft Services",
        "ignoreConflict": false,
        "lastUpdateTime": 1517966743491,
        "longDescription": "ms-services is a set of tools, APIs and web services used by Microsoft applications.",
        "name": "ms-services",
        "nbarId": "1737",
        "p2pTechnology": "false",
        "popularity": 7,
        "rank": 65535,
        "references": "http://www.microsoft.com",
        "selectorId": "777",
        "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
        "trafficClass": "TRANSACTIONAL_DATA",
        "tunnel": "false"
      }
    ],
    "networkIdentity": [
      {
        "id": "b9ba83fd-5548-4659-95f3-60101c108a9a",
        "instanceId": 14004,
        "authEntityId": 14004,
        "displayName": "14004",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "53,80,443,5353",
        "protocol": "TCP",
        "upperPort": 0
      },
      {
        "id": "d038f1dc-cc8e-484a-b4bc-3ac96d5a7f17",
        "instanceId": 14005,
        "authEntityId": 14005,
        "displayName": "14005",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "53,5353",
        "protocol": "UDP",
        "upperPort": 0
      }
    ],
    "parentScalableGroup": {
      "idRef": "000c15bf-28fe-4fbb-9b69-35e6502721f1"
    },
    "user": [

    ],
    "userGroup": [

    ]
  },
  {
    "id": "5537250c-bf5d-49c4-ac6d-aa534aab59c3",
    "instanceId": 15338,
    "authEntityId": 15338,
    "displayName": "15338",
    "authEntityClass": -1909073334,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "createTime": 1517966744764,
    "deployed": false,
    "isSeeded": true,
    "isStale": false,
    "lastUpdateTime": 1517966744764,
    "name": "uucp-path",
    "namespace": "scalablegroup:application",
    "provisioningState": "DEFINED",
    "qualifier": "application",
    "resourceVersion": 0,
    "targetIdList": [

    ],
    "type": "scalablegroup",
    "cfsChangeInfo": [

    ],
    "customProvisions": [

    ],
    "scalableGroupExternalHandle": "uucp-path",
    "scalableGroupType": "APPLICATION",
    "securityGroupTag": 0,
    "state": "ACTIVE",
    "vnAgnostic": true,
    "identitySource": {
      "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
      "instanceId": 10010,
      "authEntityId": 10010,
      "displayName": "10010",
      "authEntityClass": 1738953278,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "state": "INACTIVE",
      "type": "NBAR"
    },
    "indicativeNetworkIdentity": [
      {
        "id": "1c4e73fe-c8ce-4ab5-bad9-0203897c5447",
        "instanceId": 17633,
        "authEntityId": 17633,
        "displayName": "17633",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "117",
        "protocol": "UDP",
        "upperPort": 0
      },
      {
        "id": "3c1ede8b-b88d-49e1-a89d-e291d94ed5ff",
        "instanceId": 17634,
        "authEntityId": 17634,
        "displayName": "17634",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "117",
        "protocol": "TCP",
        "upperPort": 0
      }
    ],
    "networkApplications": [
      {
        "id": "521c2869-4cb6-4422-aa5e-123de516230b",
        "instanceId": 16281,
        "authEntityId": 16281,
        "displayName": "16281",
        "authEntityClass": -217092956,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "appProtocol": "tcp/udp",
        "applicationGroup": "other",
        "applicationType": "DEFAULT",
        "categoryId": "e6619ce8-97de-4c63-a53a-cceddbddc1a8",
        "createTime": 1517966744764,
        "encrypted": "false",
        "engineId": "3",
        "helpString": "UUCP Path Service",
        "ignoreConflict": false,
        "lastUpdateTime": 1517966744764,
        "longDescription": "Path Service is part of UUCP (Unix To Unix Copy Protocol) suite. It fills the need of people to determine mailbox addresses for hosts that are not part of the ARPA-Internet but can be reached by one or more relay hosts.",
        "name": "uucp-path",
        "nbarId": "987",
        "p2pTechnology": "false",
        "popularity": 1,
        "rank": 65535,
        "references": "http://tools.ietf.org/html/rfc915",
        "selectorId": "117",
        "subCategory": "50b55e2e-d0d0-4404-bea4-819351565d90",
        "trafficClass": "BULK_DATA",
        "tunnel": "false"
      }
    ],
    "networkIdentity": [
      {
        "id": "d5fbdea6-421c-4b3d-a8bc-346c048d2bd0",
        "instanceId": 17632,
        "authEntityId": 17632,
        "displayName": "17632",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "117",
        "protocol": "UDP",
        "upperPort": 0
      },
      {
        "id": "66915fab-e3a1-4383-ada4-c4299d9db242",
        "instanceId": 17631,
        "authEntityId": 17631,
        "displayName": "17631",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "117",
        "protocol": "TCP",
        "upperPort": 0
      }
    ],
    "parentScalableGroup": {
      "idRef": "383363b1-4dd5-49d1-bfe4-34daac437cfb"
    },
    "user": [

    ],
    "userGroup": [

    ]
  },
  {
    "id": "5545b93d-43a8-4c49-a9dd-97e78548840e",
    "instanceId": 4899,
    "authEntityId": 4899,
    "displayName": "4899",
    "authEntityClass": -1909073334,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "createTime": 1517966743711,
    "deployed": false,
    "isSeeded": true,
    "isStale": false,
    "lastUpdateTime": 1517966743711,
    "name": "ocserver",
    "namespace": "scalablegroup:application",
    "provisioningState": "DEFINED",
    "qualifier": "application",
    "resourceVersion": 0,
    "targetIdList": [

    ],
    "type": "scalablegroup",
    "cfsChangeInfo": [

    ],
    "customProvisions": [

    ],
    "scalableGroupExternalHandle": "ocserver",
    "scalableGroupType": "APPLICATION",
    "securityGroupTag": 0,
    "state": "ACTIVE",
    "vnAgnostic": true,
    "identitySource": {
      "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
      "instanceId": 10010,
      "authEntityId": 10010,
      "displayName": "10010",
      "authEntityClass": 1738953278,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "state": "INACTIVE",
      "type": "NBAR"
    },
    "indicativeNetworkIdentity": [
      {
        "id": "a0a2527e-5702-4bde-b277-fccaebb16d39",
        "instanceId": 14353,
        "authEntityId": 14353,
        "displayName": "14353",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "184",
        "protocol": "UDP",
        "upperPort": 0
      },
      {
        "id": "50b52314-46fe-4d04-ba04-0145c80e8086",
        "instanceId": 14354,
        "authEntityId": 14354,
        "displayName": "14354",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "184",
        "protocol": "TCP",
        "upperPort": 0
      }
    ],
    "networkApplications": [
      {
        "id": "57875cb9-0540-431f-9ae2-bd2b36b99be0",
        "instanceId": 11848,
        "authEntityId": 11848,
        "displayName": "11848",
        "authEntityClass": -217092956,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "appProtocol": "tcp/udp",
        "applicationGroup": "other",
        "applicationType": "DEFAULT",
        "categoryId": "6daa3dfd-3526-407a-88f0-97824b3f3a24",
        "createTime": 1517966743711,
        "encrypted": "false",
        "engineId": "3",
        "helpString": "ocserver",
        "ignoreConflict": false,
        "lastUpdateTime": 1517966743711,
        "longDescription": "Registered with IANA on port 184 TCP/UDP",
        "name": "ocserver",
        "nbarId": "1027",
        "p2pTechnology": "false",
        "popularity": 1,
        "rank": 65535,
        "references": "http://www.iana.org/assignments/service-names-port-numbers/service-names-port-numbers.xml",
        "selectorId": "184",
        "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
        "trafficClass": "BULK_DATA",
        "tunnel": "false"
      }
    ],
    "networkIdentity": [
      {
        "id": "ccb5dc43-b1ff-47f3-ac0e-7e7a4cec721a",
        "instanceId": 14352,
        "authEntityId": 14352,
        "displayName": "14352",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "184",
        "protocol": "UDP",
        "upperPort": 0
      },
      {
        "id": "86f17382-d891-4cd9-9dbd-b33d71b5a592",
        "instanceId": 14351,
        "authEntityId": 14351,
        "displayName": "14351",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "184",
        "protocol": "TCP",
        "upperPort": 0
      }
    ],
    "parentScalableGroup": {
      "idRef": "000c15bf-28fe-4fbb-9b69-35e6502721f1"
    },
    "user": [

    ],
    "userGroup": [

    ]
  },
  {
    "id": "5575a875-0e5e-4eea-a6e9-b32b8b0361f1",
    "instanceId": 4524,
    "authEntityId": 4524,
    "displayName": "4524",
    "authEntityClass": -1909073334,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "createTime": 1517966742887,
    "deployed": false,
    "isSeeded": true,
    "isStale": false,
    "lastUpdateTime": 1517966742887,
    "name": "ha-cluster",
    "namespace": "scalablegroup:application",
    "provisioningState": "DEFINED",
    "qualifier": "application",
    "resourceVersion": 0,
    "targetIdList": [

    ],
    "type": "scalablegroup",
    "cfsChangeInfo": [

    ],
    "customProvisions": [

    ],
    "scalableGroupExternalHandle": "ha-cluster",
    "scalableGroupType": "APPLICATION",
    "securityGroupTag": 0,
    "state": "ACTIVE",
    "vnAgnostic": true,
    "identitySource": {
      "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
      "instanceId": 10010,
      "authEntityId": 10010,
      "displayName": "10010",
      "authEntityClass": 1738953278,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "state": "INACTIVE",
      "type": "NBAR"
    },
    "indicativeNetworkIdentity": [
      {
        "id": "de3b7161-7431-48f8-bc06-c2c87e7b833f",
        "instanceId": 13305,
        "authEntityId": 13305,
        "displayName": "13305",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "694",
        "protocol": "UDP",
        "upperPort": 0
      },
      {
        "id": "b46722c1-be9c-4141-bd1b-0626c8c409d1",
        "instanceId": 13306,
        "authEntityId": 13306,
        "displayName": "13306",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "694",
        "protocol": "TCP",
        "upperPort": 0
      }
    ],
    "networkApplications": [
      {
        "id": "8ea679ea-3dce-4d4b-ad7c-2352816856f3",
        "instanceId": 11475,
        "authEntityId": 11475,
        "displayName": "11475",
        "authEntityClass": -217092956,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "appProtocol": "tcp/udp",
        "applicationGroup": "other",
        "applicationType": "DEFAULT",
        "categoryId": "1fc17362-9334-4c7b-b752-5afdbb574017",
        "createTime": 1517966742887,
        "encrypted": "false",
        "engineId": "3",
        "helpString": "ha-cluster",
        "ignoreConflict": false,
        "lastUpdateTime": 1517966742887,
        "longDescription": "High-availability clusters (also known as HA clusters or failover clusters) are groups of computers that supportserverapplications that can be reliably utilized with a minimum of downtime. They operate by harnessing redundant computers in groups or clusters that provide continued service when system components fail. HA clusters usually use a private network connection to monitor the health and status of each node in the cluster.",
        "name": "ha-cluster",
        "nbarId": "602",
        "p2pTechnology": "false",
        "popularity": 1,
        "rank": 65535,
        "references": "http://en.wikipedia.org/wiki/Ha-cluster",
        "selectorId": "694",
        "subCategory": "f3c0f761-d894-4112-ab89-b90fc84fd660",
        "trafficClass": "OPS_ADMIN_MGMT",
        "tunnel": "false"
      }
    ],
    "networkIdentity": [
      {
        "id": "4363af2d-35c8-434b-9426-7d203c64f778",
        "instanceId": 13303,
        "authEntityId": 13303,
        "displayName": "13303",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "694",
        "protocol": "UDP",
        "upperPort": 0
      },
      {
        "id": "a85bb85b-43a2-4a32-bc3a-445f0217f9e0",
        "instanceId": 13304,
        "authEntityId": 13304,
        "displayName": "13304",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "694",
        "protocol": "TCP",
        "upperPort": 0
      }
    ],
    "parentScalableGroup": {
      "idRef": "5fcd74d4-8726-47f7-a088-c457863da7ef"
    },
    "user": [

    ],
    "userGroup": [

    ]
  },
  {
    "id": "5595cb97-5a3e-4470-8aed-4ac5fb98483b",
    "instanceId": 15447,
    "authEntityId": 15447,
    "displayName": "15447",
    "authEntityClass": -1909073334,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "createTime": 1517966744981,
    "deployed": false,
    "isSeeded": true,
    "isStale": false,
    "lastUpdateTime": 1517966744981,
    "name": "xtp",
    "namespace": "scalablegroup:application",
    "provisioningState": "DEFINED",
    "qualifier": "application",
    "resourceVersion": 0,
    "targetIdList": [

    ],
    "type": "scalablegroup",
    "cfsChangeInfo": [

    ],
    "customProvisions": [

    ],
    "scalableGroupExternalHandle": "xtp",
    "scalableGroupType": "APPLICATION",
    "securityGroupTag": 0,
    "state": "ACTIVE",
    "vnAgnostic": true,
    "identitySource": {
      "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
      "instanceId": 10010,
      "authEntityId": 10010,
      "displayName": "10010",
      "authEntityClass": 1738953278,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "state": "INACTIVE",
      "type": "NBAR"
    },
    "indicativeNetworkIdentity": [
      {
        "id": "c9977c7e-ec8d-4bb5-8ef3-4a4a5c29e0a0",
        "instanceId": 17928,
        "authEntityId": 17928,
        "displayName": "17928",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipProtocolNumbers": "36",
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "protocol": "XTP",
        "upperPort": 0
      }
    ],
    "networkApplications": [
      {
        "id": "c49fead0-70a9-46c9-ad4c-97cffad67205",
        "instanceId": 16390,
        "authEntityId": 16390,
        "displayName": "16390",
        "authEntityClass": -217092956,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "appProtocol": "ip",
        "applicationGroup": "other",
        "applicationType": "DEFAULT",
        "categoryId": "6daa3dfd-3526-407a-88f0-97824b3f3a24",
        "createTime": 1517966744981,
        "encrypted": "unassigned",
        "engineId": "1",
        "helpString": "XTP",
        "ignoreConflict": false,
        "lastUpdateTime": 1517966744981,
        "longDescription": "Xpress Transport Protocol (XTP) is a transport layer protocol developed by the XTP Forum for high-speed networks, to replace TCP. XTP provides protocol options for error control, flow control, and rate control. Instead of separate protocols for each type of communication, XTP controls packet exchange patterns to produce different models, e.g. reliable datagrams, transactions, unreliable streams, and reliable multicast connections.",
        "name": "xtp",
        "nbarId": "790",
        "p2pTechnology": "unassigned",
        "popularity": 2,
        "rank": 65535,
        "references": "http://www.ccii.co.za/products/xtp.html",
        "selectorId": "36",
        "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
        "trafficClass": "BULK_DATA",
        "tunnel": "unassigned"
      }
    ],
    "networkIdentity": [
      {
        "id": "6ae4d666-97fe-46d6-96b0-348a1b6c7803",
        "instanceId": 17927,
        "authEntityId": 17927,
        "displayName": "17927",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipProtocolNumbers": "36",
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "protocol": "XTP",
        "upperPort": 0
      }
    ],
    "parentScalableGroup": {
      "idRef": "000c15bf-28fe-4fbb-9b69-35e6502721f1"
    },
    "user": [

    ],
    "userGroup": [

    ]
  },
  {
    "id": "55b167d3-71eb-4a1c-8675-87529690d777",
    "instanceId": 4167,
    "authEntityId": 4167,
    "displayName": "4167",
    "authEntityClass": -1909073334,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "createTime": 1517966741468,
    "deployed": false,
    "isSeeded": true,
    "isStale": false,
    "lastUpdateTime": 1517966741468,
    "name": "bb",
    "namespace": "scalablegroup:application",
    "provisioningState": "DEFINED",
    "qualifier": "application",
    "resourceVersion": 0,
    "targetIdList": [

    ],
    "type": "scalablegroup",
    "cfsChangeInfo": [

    ],
    "customProvisions": [

    ],
    "scalableGroupExternalHandle": "bb",
    "scalableGroupType": "APPLICATION",
    "securityGroupTag": 0,
    "state": "ACTIVE",
    "vnAgnostic": true,
    "identitySource": {
      "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
      "instanceId": 10010,
      "authEntityId": 10010,
      "displayName": "10010",
      "authEntityClass": 1738953278,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "state": "INACTIVE",
      "type": "NBAR"
    },
    "indicativeNetworkIdentity": [
      {
        "id": "68b413bd-74f3-4452-b8f5-cca039acbb9a",
        "instanceId": 12348,
        "authEntityId": 12348,
        "displayName": "12348",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "1984",
        "protocol": "UDP",
        "upperPort": 0
      },
      {
        "id": "19483f0e-10d5-46a6-8e18-2e71da9d4175",
        "instanceId": 12349,
        "authEntityId": 12349,
        "displayName": "12349",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "1984",
        "protocol": "TCP",
        "upperPort": 0
      }
    ],
    "networkApplications": [
      {
        "id": "dbba1c03-a1dc-4273-bc8c-81130bb505f8",
        "instanceId": 11126,
        "authEntityId": 11126,
        "displayName": "11126",
        "authEntityClass": -217092956,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "appProtocol": "tcp/udp",
        "applicationGroup": "other",
        "applicationType": "DEFAULT",
        "categoryId": "1fc17362-9334-4c7b-b752-5afdbb574017",
        "createTime": 1517966741468,
        "encrypted": "false",
        "engineId": "3",
        "helpString": "Big Brother network monitoring tool",
        "ignoreConflict": false,
        "lastUpdateTime": 1517966741468,
        "longDescription": "Big Brother (BB) is a tool for systems and network monitoring, generally used by system administrators. Big Brother produces HTML pages containing a simple matrix of hosts and tests with red and green dots to denote system status.",
        "name": "bb",
        "nbarId": "1331",
        "p2pTechnology": "false",
        "popularity": 1,
        "rank": 65535,
        "references": "http://www.bb4.com/",
        "selectorId": "1984",
        "subCategory": "f3c0f761-d894-4112-ab89-b90fc84fd660",
        "trafficClass": "OPS_ADMIN_MGMT",
        "tunnel": "false"
      }
    ],
    "networkIdentity": [
      {
        "id": "67afca39-93c0-4a14-b3e9-da5f3b8d6cfe",
        "instanceId": 12346,
        "authEntityId": 12346,
        "displayName": "12346",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "1984",
        "protocol": "TCP",
        "upperPort": 0
      },
      {
        "id": "3f914633-ca22-4219-b162-08b16c599ebc",
        "instanceId": 12347,
        "authEntityId": 12347,
        "displayName": "12347",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "1984",
        "protocol": "UDP",
        "upperPort": 0
      }
    ],
    "parentScalableGroup": {
      "idRef": "5fcd74d4-8726-47f7-a088-c457863da7ef"
    },
    "user": [

    ],
    "userGroup": [

    ]
  },
  {
    "id": "55f81e78-d8bf-4adf-86ba-71120faf9ad3",
    "instanceId": 4390,
    "authEntityId": 4390,
    "displayName": "4390",
    "authEntityClass": -1909073334,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "createTime": 1517966742509,
    "deployed": false,
    "isSeeded": true,
    "isStale": false,
    "lastUpdateTime": 1517966742509,
    "name": "dsp3270",
    "namespace": "scalablegroup:application",
    "provisioningState": "DEFINED",
    "qualifier": "application",
    "resourceVersion": 0,
    "targetIdList": [

    ],
    "type": "scalablegroup",
    "cfsChangeInfo": [

    ],
    "customProvisions": [

    ],
    "scalableGroupExternalHandle": "dsp3270",
    "scalableGroupType": "APPLICATION",
    "securityGroupTag": 0,
    "state": "ACTIVE",
    "vnAgnostic": true,
    "identitySource": {
      "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
      "instanceId": 10010,
      "authEntityId": 10010,
      "displayName": "10010",
      "authEntityClass": 1738953278,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "state": "INACTIVE",
      "type": "NBAR"
    },
    "indicativeNetworkIdentity": [
      {
        "id": "553cd947-6cb6-4d0d-b929-d0247fc4358c",
        "instanceId": 12966,
        "authEntityId": 12966,
        "displayName": "12966",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "246",
        "protocol": "UDP",
        "upperPort": 0
      },
      {
        "id": "6d473b11-c65b-456f-b9d2-ec58d812522c",
        "instanceId": 12967,
        "authEntityId": 12967,
        "displayName": "12967",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "246",
        "protocol": "TCP",
        "upperPort": 0
      }
    ],
    "networkApplications": [
      {
        "id": "a9974658-f7e1-42c1-a729-77f50eac0de5",
        "instanceId": 11341,
        "authEntityId": 11341,
        "displayName": "11341",
        "authEntityClass": -217092956,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "appProtocol": "tcp/udp",
        "applicationGroup": "other",
        "applicationType": "DEFAULT",
        "categoryId": "6daa3dfd-3526-407a-88f0-97824b3f3a24",
        "createTime": 1517966742509,
        "encrypted": "false",
        "engineId": "3",
        "helpString": "Display Systems Protocol",
        "ignoreConflict": false,
        "lastUpdateTime": 1517966742509,
        "longDescription": "DSP3270 was created in 1980 in order to allow bisynchronous (BSC) 3270 terminals to communicate through an X.25 network. A PAD (packet assembler-disassembler) connected to a BSC 3270 type device communicates over the packet switched network with another PAD connected to a host computer. The protocol defines the communication between the two PADs, it is carried above the X.25 level 3 layer and so it could be called a level 4 protocol although it is not compatible with the OSI Transport layer.",
        "name": "dsp3270",
        "nbarId": "1126",
        "p2pTechnology": "false",
        "popularity": 1,
        "rank": 65535,
        "references": "http://www.euclideanspace.com/coms/protocol/bi_sync/systems/dsp/index.htm",
        "selectorId": "246",
        "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
        "trafficClass": "BULK_DATA",
        "tunnel": "false"
      }
    ],
    "networkIdentity": [
      {
        "id": "926757d6-f4d3-4024-b392-1702e3a7bcc6",
        "instanceId": 12964,
        "authEntityId": 12964,
        "displayName": "12964",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "246",
        "protocol": "TCP",
        "upperPort": 0
      },
      {
        "id": "57d92601-554e-4460-b1b6-489734e579dd",
        "instanceId": 12965,
        "authEntityId": 12965,
        "displayName": "12965",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "246",
        "protocol": "UDP",
        "upperPort": 0
      }
    ],
    "parentScalableGroup": {
      "idRef": "000c15bf-28fe-4fbb-9b69-35e6502721f1"
    },
    "user": [

    ],
    "userGroup": [

    ]
  },
  {
    "id": "56451edf-c796-4e0c-bb58-d3cb88de8592",
    "instanceId": 4492,
    "authEntityId": 4492,
    "displayName": "4492",
    "authEntityClass": -1909073334,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "createTime": 1517966742804,
    "deployed": false,
    "isSeeded": true,
    "isStale": false,
    "lastUpdateTime": 1517966742804,
    "name": "godaddy",
    "namespace": "scalablegroup:application",
    "provisioningState": "DEFINED",
    "qualifier": "application",
    "resourceVersion": 0,
    "targetIdList": [

    ],
    "type": "scalablegroup",
    "cfsChangeInfo": [

    ],
    "customProvisions": [

    ],
    "scalableGroupExternalHandle": "godaddy",
    "scalableGroupType": "APPLICATION",
    "securityGroupTag": 0,
    "state": "ACTIVE",
    "vnAgnostic": true,
    "identitySource": {
      "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
      "instanceId": 10010,
      "authEntityId": 10010,
      "displayName": "10010",
      "authEntityClass": 1738953278,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "state": "INACTIVE",
      "type": "NBAR"
    },
    "indicativeNetworkIdentity": [

    ],
    "networkApplications": [
      {
        "id": "9e9a832d-e2b8-4d32-9cf8-61311848938b",
        "instanceId": 11443,
        "authEntityId": 11443,
        "displayName": "11443",
        "authEntityClass": -217092956,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "appProtocol": "tcp/udp",
        "applicationGroup": "other",
        "applicationType": "DEFAULT",
        "categoryId": "878644b5-fe91-45b4-9204-31c84a73ba48",
        "createTime": 1517966742804,
        "encrypted": "false",
        "engineId": "13",
        "helpString": "GoDaddy - Domain registrar.",
        "ignoreConflict": false,
        "lastUpdateTime": 1517966742804,
        "longDescription": "GoDaddy - Domain registrar.",
        "name": "godaddy",
        "nbarId": "1569",
        "p2pTechnology": "false",
        "popularity": 3,
        "rank": 65535,
        "references": "http://godaddy.com",
        "selectorId": "640",
        "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
        "trafficClass": "TRANSACTIONAL_DATA",
        "tunnel": "false"
      }
    ],
    "networkIdentity": [
      {
        "id": "3729fb93-d47a-4e92-9d8f-b941499620d8",
        "instanceId": 13228,
        "authEntityId": 13228,
        "displayName": "13228",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "53,80,443,5353",
        "protocol": "TCP",
        "upperPort": 0
      },
      {
        "id": "e2ebafb5-d287-46e8-8609-ac4c4b342135",
        "instanceId": 13229,
        "authEntityId": 13229,
        "displayName": "13229",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "53,5353",
        "protocol": "UDP",
        "upperPort": 0
      }
    ],
    "parentScalableGroup": {
      "idRef": "28e08de4-d7b2-4521-84ce-bc1e5ee0b9cc"
    },
    "user": [

    ],
    "userGroup": [

    ]
  },
  {
    "id": "5654374f-84f0-4528-b2fa-5a642e2e70f7",
    "instanceId": 15400,
    "authEntityId": 15400,
    "displayName": "15400",
    "authEntityClass": -1909073334,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "createTime": 1517966744876,
    "deployed": false,
    "isSeeded": true,
    "isStale": false,
    "lastUpdateTime": 1517966744876,
    "name": "webex-control",
    "namespace": "scalablegroup:application",
    "provisioningState": "DEFINED",
    "qualifier": "application",
    "resourceVersion": 0,
    "targetIdList": [

    ],
    "type": "scalablegroup",
    "cfsChangeInfo": [

    ],
    "customProvisions": [

    ],
    "scalableGroupExternalHandle": "webex-control",
    "scalableGroupType": "APPLICATION",
    "securityGroupTag": 0,
    "state": "ACTIVE",
    "vnAgnostic": true,
    "identitySource": {
      "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
      "instanceId": 10010,
      "authEntityId": 10010,
      "displayName": "10010",
      "authEntityClass": 1738953278,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "state": "INACTIVE",
      "type": "NBAR"
    },
    "indicativeNetworkIdentity": [

    ],
    "networkApplications": [
      {
        "id": "2ff49098-ed3a-4f63-bc02-3920fffbdaeb",
        "instanceId": 16343,
        "authEntityId": 16343,
        "displayName": "16343",
        "authEntityClass": -217092956,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "appProtocol": "tcp/udp",
        "applicationGroup": "webex-group",
        "applicationType": "DEFAULT",
        "categoryId": "38a8e354-e2fe-4f3c-b1c5-2bbb90fb0d99",
        "createTime": 1517966744876,
        "encrypted": "false",
        "engineId": "13",
        "helpString": "Online conferencing application control",
        "ignoreConflict": false,
        "lastUpdateTime": 1517966744876,
        "longDescription": "WebEx-Control is a granular classification of WebEx protocol control and signaling traffic.",
        "name": "webex-control",
        "nbarId": "1082",
        "p2pTechnology": "false",
        "popularity": 10,
        "rank": 65535,
        "references": "http://www.webex.com/overview/index.html/",
        "selectorId": "1082",
        "subCategory": "68c4f989-5735-4e16-8d1c-6a9d89d5df9c",
        "trafficClass": "SIGNALING",
        "tunnel": "false"
      }
    ],
    "networkIdentity": [
      {
        "id": "4525647f-5b95-4d25-9520-bc4f2f8fe71e",
        "instanceId": 17808,
        "authEntityId": 17808,
        "displayName": "17808",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "5060",
        "protocol": "UDP",
        "upperPort": 0
      },
      {
        "id": "ee4088c0-b31e-4b14-8f0e-4c0c00d4e860",
        "instanceId": 17807,
        "authEntityId": 17807,
        "displayName": "17807",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "80,5060",
        "protocol": "TCP",
        "upperPort": 0
      }
    ],
    "parentScalableGroup": {
      "idRef": "60f1364d-2a02-4063-8ba1-07817abe0612"
    },
    "user": [

    ],
    "userGroup": [

    ]
  },
  {
    "id": "5689660d-d7e3-4602-b55b-5537307152e6",
    "instanceId": 4500,
    "authEntityId": 4500,
    "displayName": "4500",
    "authEntityClass": -1909073334,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "createTime": 1517966742825,
    "deployed": false,
    "isSeeded": true,
    "isStale": false,
    "lastUpdateTime": 1517966742825,
    "name": "google-services",
    "namespace": "scalablegroup:application",
    "provisioningState": "DEFINED",
    "qualifier": "application",
    "resourceVersion": 0,
    "targetIdList": [

    ],
    "type": "scalablegroup",
    "cfsChangeInfo": [

    ],
    "customProvisions": [

    ],
    "scalableGroupExternalHandle": "google-services",
    "scalableGroupType": "APPLICATION",
    "securityGroupTag": 0,
    "state": "ACTIVE",
    "vnAgnostic": true,
    "identitySource": {
      "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
      "instanceId": 10010,
      "authEntityId": 10010,
      "displayName": "10010",
      "authEntityClass": 1738953278,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "state": "INACTIVE",
      "type": "NBAR"
    },
    "indicativeNetworkIdentity": [

    ],
    "networkApplications": [
      {
        "id": "c333abe7-5024-47c1-947e-8711b1fc7b51",
        "instanceId": 11451,
        "authEntityId": 11451,
        "displayName": "11451",
        "authEntityClass": -217092956,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "appProtocol": "tcp/udp",
        "applicationGroup": "google-group",
        "applicationType": "DEFAULT",
        "categoryId": "6daa3dfd-3526-407a-88f0-97824b3f3a24",
        "createTime": 1517966742825,
        "encrypted": "true",
        "engineId": "13",
        "helpString": "google-services, set of tools and apis used by google applications",
        "ignoreConflict": false,
        "lastUpdateTime": 1517966742825,
        "longDescription": "google-services is a set of tools and APIs used by Google applications.",
        "name": "google-services",
        "nbarId": "1456",
        "p2pTechnology": "true",
        "popularity": 10,
        "rank": 65535,
        "references": "https://www.google.com/",
        "selectorId": "520",
        "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
        "trafficClass": "TRANSACTIONAL_DATA",
        "tunnel": "false"
      }
    ],
    "networkIdentity": [
      {
        "id": "a97abac1-deec-4741-892a-84fc629361a1",
        "instanceId": 13243,
        "authEntityId": 13243,
        "displayName": "13243",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "53,80,443,5353",
        "protocol": "TCP",
        "upperPort": 0
      },
      {
        "id": "a50245e4-8ef6-4a9a-8820-4616ab717cef",
        "instanceId": 13244,
        "authEntityId": 13244,
        "displayName": "13244",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "53,5353",
        "protocol": "UDP",
        "upperPort": 0
      }
    ],
    "parentScalableGroup": {
      "idRef": "53f03553-069a-4046-ad7c-84610eb579c5"
    },
    "user": [

    ],
    "userGroup": [

    ]
  },
  {
    "id": "569cf6db-031a-4306-9e64-d25ea73abc6d",
    "instanceId": 4683,
    "authEntityId": 4683,
    "displayName": "4683",
    "authEntityClass": -1909073334,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "createTime": 1517966743276,
    "deployed": false,
    "isSeeded": true,
    "isStale": false,
    "lastUpdateTime": 1517966743276,
    "name": "kuro",
    "namespace": "scalablegroup:application",
    "provisioningState": "DEFINED",
    "qualifier": "application",
    "resourceVersion": 0,
    "targetIdList": [

    ],
    "type": "scalablegroup",
    "cfsChangeInfo": [

    ],
    "customProvisions": [

    ],
    "scalableGroupExternalHandle": "kuro",
    "scalableGroupType": "APPLICATION",
    "securityGroupTag": 0,
    "state": "ACTIVE",
    "vnAgnostic": true,
    "identitySource": {
      "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
      "instanceId": 10010,
      "authEntityId": 10010,
      "displayName": "10010",
      "authEntityClass": 1738953278,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "state": "INACTIVE",
      "type": "NBAR"
    },
    "indicativeNetworkIdentity": [

    ],
    "networkApplications": [
      {
        "id": "74ed0b9b-3792-42b7-b774-8cec5778701d",
        "instanceId": 11633,
        "authEntityId": 11633,
        "displayName": "11633",
        "authEntityClass": -217092956,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "applicationGroup": "other",
        "applicationType": "DEFAULT",
        "categoryId": "a09ef1b3-ba26-4372-aee8-309d50697e76",
        "createTime": 1517966743276,
        "encrypted": "unassigned",
        "engineId": "13",
        "helpString": "Japanese file sharing client",
        "ignoreConflict": false,
        "lastUpdateTime": 1517966743276,
        "longDescription": "The Kuro protocol is used by the Kuro file-sharing application popular in Japan.",
        "name": "kuro",
        "nbarId": "801",
        "p2pTechnology": "unassigned",
        "popularity": 6,
        "rank": 65535,
        "references": "http://www.kuro.cn",
        "selectorId": "437",
        "subCategory": "bf1b9d2c-aa52-4f37-8860-40d4603319f5",
        "trafficClass": "MULTIMEDIA_STREAMING",
        "tunnel": "unassigned"
      }
    ],
    "networkIdentity": [

    ],
    "parentScalableGroup": {
      "idRef": "fd6264c9-c16a-4311-a335-a3db039256f3"
    },
    "user": [

    ],
    "userGroup": [

    ]
  },
  {
    "id": "571e3df3-498a-4789-9fe2-f4a8bf21dcbb",
    "instanceId": 4292,
    "authEntityId": 4292,
    "displayName": "4292",
    "authEntityClass": -1909073334,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "createTime": 1517966742175,
    "deployed": false,
    "isSeeded": true,
    "isStale": false,
    "lastUpdateTime": 1517966742175,
    "name": "coupons-com",
    "namespace": "scalablegroup:application",
    "provisioningState": "DEFINED",
    "qualifier": "application",
    "resourceVersion": 0,
    "targetIdList": [

    ],
    "type": "scalablegroup",
    "cfsChangeInfo": [

    ],
    "customProvisions": [

    ],
    "scalableGroupExternalHandle": "coupons-com",
    "scalableGroupType": "APPLICATION",
    "securityGroupTag": 0,
    "state": "ACTIVE",
    "vnAgnostic": true,
    "identitySource": {
      "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
      "instanceId": 10010,
      "authEntityId": 10010,
      "displayName": "10010",
      "authEntityClass": 1738953278,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "state": "INACTIVE",
      "type": "NBAR"
    },
    "indicativeNetworkIdentity": [

    ],
    "networkApplications": [
      {
        "id": "d43de662-a677-41e5-b856-1fab3b4c9a16",
        "instanceId": 11245,
        "authEntityId": 11245,
        "displayName": "11245",
        "authEntityClass": -217092956,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "appProtocol": "tcp/udp",
        "applicationGroup": "other",
        "applicationType": "DEFAULT",
        "categoryId": "878644b5-fe91-45b4-9204-31c84a73ba48",
        "createTime": 1517966742175,
        "encrypted": "false",
        "engineId": "13",
        "helpString": "Coupons.com - An online coupons and deals website.",
        "ignoreConflict": false,
        "lastUpdateTime": 1517966742175,
        "longDescription": "Coupons.com - An online coupons and deals website.",
        "name": "coupons-com",
        "nbarId": "1725",
        "p2pTechnology": "false",
        "popularity": 3,
        "rank": 65535,
        "references": "http://coupons.com",
        "selectorId": "797",
        "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
        "trafficClass": "TRANSACTIONAL_DATA",
        "tunnel": "false"
      }
    ],
    "networkIdentity": [
      {
        "id": "0674db4d-5acb-4280-a640-ea640b3b9549",
        "instanceId": 12649,
        "authEntityId": 12649,
        "displayName": "12649",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "53,80,443,5353",
        "protocol": "TCP",
        "upperPort": 0
      },
      {
        "id": "05fc47e6-ea9d-4ca9-86c6-50ae488adfb5",
        "instanceId": 12650,
        "authEntityId": 12650,
        "displayName": "12650",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "53,5353",
        "protocol": "UDP",
        "upperPort": 0
      }
    ],
    "parentScalableGroup": {
      "idRef": "28e08de4-d7b2-4521-84ce-bc1e5ee0b9cc"
    },
    "user": [

    ],
    "userGroup": [

    ]
  },
  {
    "id": "573b3e07-f268-47b5-8899-6d7e247073ba",
    "instanceId": 4227,
    "authEntityId": 4227,
    "displayName": "4227",
    "authEntityClass": -1909073334,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "createTime": 1517966741842,
    "deployed": false,
    "isSeeded": true,
    "isStale": false,
    "lastUpdateTime": 1517966741842,
    "name": "cifs",
    "namespace": "scalablegroup:application",
    "provisioningState": "DEFINED",
    "qualifier": "application",
    "resourceVersion": 0,
    "targetIdList": [

    ],
    "type": "scalablegroup",
    "cfsChangeInfo": [

    ],
    "customProvisions": [

    ],
    "scalableGroupExternalHandle": "cifs",
    "scalableGroupType": "APPLICATION",
    "securityGroupTag": 0,
    "state": "ACTIVE",
    "vnAgnostic": true,
    "identitySource": {
      "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
      "instanceId": 10010,
      "authEntityId": 10010,
      "displayName": "10010",
      "authEntityClass": 1738953278,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "state": "INACTIVE",
      "type": "NBAR"
    },
    "indicativeNetworkIdentity": [
      {
        "id": "d7b3e2cb-e534-40bb-b489-ad9d0c3846d7",
        "instanceId": 12500,
        "authEntityId": 12500,
        "displayName": "12500",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "139,445",
        "protocol": "TCP",
        "upperPort": 0
      }
    ],
    "networkApplications": [
      {
        "id": "84b5f83a-e331-4698-b018-0faacb388cdf",
        "instanceId": 11182,
        "authEntityId": 11182,
        "displayName": "11182",
        "authEntityClass": -217092956,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "appProtocol": "tcp",
        "applicationGroup": "other",
        "applicationType": "DEFAULT",
        "categoryId": "e6619ce8-97de-4c63-a53a-cceddbddc1a8",
        "createTime": 1517966741842,
        "encrypted": "false",
        "engineId": "13",
        "helpString": "Common Internet File System",
        "ignoreConflict": false,
        "lastUpdateTime": 1517966741842,
        "longDescription": "Common Internet File System (CIFS) is a standard protocol that allows users to share files across intranets and the internet. It is cross platform since it works in Windows OS and is also supported in MAC OS and Linux OS. CIFS protocol defines a remote file system protocol. It is implemented over TCP/IP and utilizes the DNS for scalability and is using Microsoft NetBIOS protocol. The protocols origin is from Microsofts SMB protocol group.",
        "name": "cifs",
        "nbarId": "80",
        "p2pTechnology": "false",
        "popularity": 10,
        "rank": 65535,
        "references": "http://technet.microsoft.com/en-us/library/cc939973.aspx",
        "selectorId": "80",
        "subCategory": "50b55e2e-d0d0-4404-bea4-819351565d90",
        "trafficClass": "BULK_DATA",
        "tunnel": "false"
      }
    ],
    "networkIdentity": [
      {
        "id": "a28cf4ed-3436-46c0-becf-d1e76d88de93",
        "instanceId": 12499,
        "authEntityId": 12499,
        "displayName": "12499",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "139,445",
        "protocol": "TCP",
        "upperPort": 0
      }
    ],
    "parentScalableGroup": {
      "idRef": "383363b1-4dd5-49d1-bfe4-34daac437cfb"
    },
    "user": [

    ],
    "userGroup": [

    ]
  },
  {
    "id": "57ac3436-76f1-46af-85d0-50a091731bf2",
    "instanceId": 4306,
    "authEntityId": 4306,
    "displayName": "4306",
    "authEntityClass": -1909073334,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "createTime": 1517966742222,
    "deployed": false,
    "isSeeded": true,
    "isStale": false,
    "lastUpdateTime": 1517966742222,
    "name": "cryptoadmin",
    "namespace": "scalablegroup:application",
    "provisioningState": "DEFINED",
    "qualifier": "application",
    "resourceVersion": 0,
    "targetIdList": [

    ],
    "type": "scalablegroup",
    "cfsChangeInfo": [

    ],
    "customProvisions": [

    ],
    "scalableGroupExternalHandle": "cryptoadmin",
    "scalableGroupType": "APPLICATION",
    "securityGroupTag": 0,
    "state": "ACTIVE",
    "vnAgnostic": true,
    "identitySource": {
      "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
      "instanceId": 10010,
      "authEntityId": 10010,
      "displayName": "10010",
      "authEntityClass": 1738953278,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "state": "INACTIVE",
      "type": "NBAR"
    },
    "indicativeNetworkIdentity": [
      {
        "id": "d614758d-9b97-4575-bbab-3c8abd597918",
        "instanceId": 12690,
        "authEntityId": 12690,
        "displayName": "12690",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "624",
        "protocol": "UDP",
        "upperPort": 0
      },
      {
        "id": "def153c1-f63a-49f9-b254-d1eb3d918724",
        "instanceId": 12691,
        "authEntityId": 12691,
        "displayName": "12691",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "624",
        "protocol": "TCP",
        "upperPort": 0
      }
    ],
    "networkApplications": [
      {
        "id": "776f36a7-a3c9-49b0-abef-b24c49e80d96",
        "instanceId": 11259,
        "authEntityId": 11259,
        "displayName": "11259",
        "authEntityClass": -217092956,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "appProtocol": "tcp/udp",
        "applicationGroup": "other",
        "applicationType": "DEFAULT",
        "categoryId": "38a8e354-e2fe-4f3c-b1c5-2bbb90fb0d99",
        "createTime": 1517966742222,
        "encrypted": "false",
        "engineId": "3",
        "helpString": "Crypto Admin",
        "ignoreConflict": false,
        "lastUpdateTime": 1517966742222,
        "longDescription": "CRYPTOAdmin a remote authentication solution, enabling its users to access a remote enterprise's LAN. The solution is built of two parts: the CRYPTOAdmin authentication server and CRYPTOCard tokens and smartcards. Together, these provide strongly secured access to a remote enterprise network.",
        "name": "cryptoadmin",
        "nbarId": "533",
        "p2pTechnology": "false",
        "popularity": 1,
        "rank": 65535,
        "references": "http://www.cryptocard.com/",
        "selectorId": "624",
        "subCategory": "e390124a-0fd6-4253-b68e-4b0c1af8cb4b",
        "trafficClass": "OPS_ADMIN_MGMT",
        "tunnel": "false"
      }
    ],
    "networkIdentity": [
      {
        "id": "071b1dac-662f-432d-8690-e9bab389cb66",
        "instanceId": 12688,
        "authEntityId": 12688,
        "displayName": "12688",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "624",
        "protocol": "TCP",
        "upperPort": 0
      },
      {
        "id": "1451aa12-e0bb-4828-b293-e979394b665e",
        "instanceId": 12689,
        "authEntityId": 12689,
        "displayName": "12689",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "624",
        "protocol": "UDP",
        "upperPort": 0
      }
    ],
    "parentScalableGroup": {
      "idRef": "7788bf2d-e289-4597-b500-17aacb7e820e"
    },
    "user": [

    ],
    "userGroup": [

    ]
  },
  {
    "id": "57eb1ad7-ae7a-4e85-9960-a6efc1938433",
    "instanceId": 4543,
    "authEntityId": 4543,
    "displayName": "4543",
    "authEntityClass": -1909073334,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "createTime": 1517966742944,
    "deployed": false,
    "isSeeded": true,
    "isStale": false,
    "lastUpdateTime": 1517966742944,
    "name": "hmmp-op",
    "namespace": "scalablegroup:application",
    "provisioningState": "DEFINED",
    "qualifier": "application",
    "resourceVersion": 0,
    "targetIdList": [

    ],
    "type": "scalablegroup",
    "cfsChangeInfo": [

    ],
    "customProvisions": [

    ],
    "scalableGroupExternalHandle": "hmmp-op",
    "scalableGroupType": "APPLICATION",
    "securityGroupTag": 0,
    "state": "ACTIVE",
    "vnAgnostic": true,
    "identitySource": {
      "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
      "instanceId": 10010,
      "authEntityId": 10010,
      "displayName": "10010",
      "authEntityClass": 1738953278,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "state": "INACTIVE",
      "type": "NBAR"
    },
    "indicativeNetworkIdentity": [
      {
        "id": "080246d9-511f-4576-8bf1-541507e659ba",
        "instanceId": 13349,
        "authEntityId": 13349,
        "displayName": "13349",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "613",
        "protocol": "UDP",
        "upperPort": 0
      },
      {
        "id": "df05b5a0-5605-4dcc-b45d-2b2b048b9329",
        "instanceId": 13350,
        "authEntityId": 13350,
        "displayName": "13350",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "613",
        "protocol": "TCP",
        "upperPort": 0
      }
    ],
    "networkApplications": [
      {
        "id": "1b1c99f4-cff7-4451-9e52-0709f69d3549",
        "instanceId": 11494,
        "authEntityId": 11494,
        "displayName": "11494",
        "authEntityClass": -217092956,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "appProtocol": "tcp/udp",
        "applicationGroup": "other",
        "applicationType": "DEFAULT",
        "categoryId": "1fc17362-9334-4c7b-b752-5afdbb574017",
        "createTime": 1517966742944,
        "encrypted": "false",
        "engineId": "3",
        "helpString": "HMMP Operation",
        "ignoreConflict": false,
        "lastUpdateTime": 1517966742944,
        "longDescription": "Registered with IANA on port 613 TCP/UDP",
        "name": "hmmp-op",
        "nbarId": "522",
        "p2pTechnology": "false",
        "popularity": 1,
        "rank": 65535,
        "references": "http://www.iana.org/assignments/service-names-port-numbers/service-names-port-numbers.xml",
        "selectorId": "613",
        "subCategory": "f3c0f761-d894-4112-ab89-b90fc84fd660",
        "trafficClass": "OPS_ADMIN_MGMT",
        "tunnel": "false"
      }
    ],
    "networkIdentity": [
      {
        "id": "6d2c8f56-5c7a-4ba6-b8f3-89a0a310ddb9",
        "instanceId": 13347,
        "authEntityId": 13347,
        "displayName": "13347",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "613",
        "protocol": "UDP",
        "upperPort": 0
      },
      {
        "id": "1008194b-88f8-4d08-b54c-a0ad4e8e81c6",
        "instanceId": 13348,
        "authEntityId": 13348,
        "displayName": "13348",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "613",
        "protocol": "TCP",
        "upperPort": 0
      }
    ],
    "parentScalableGroup": {
      "idRef": "5fcd74d4-8726-47f7-a088-c457863da7ef"
    },
    "user": [

    ],
    "userGroup": [

    ]
  },
  {
    "id": "58025825-4951-4a86-91e3-fd142f757a7c",
    "instanceId": 4925,
    "authEntityId": 4925,
    "displayName": "4925",
    "authEntityClass": -1909073334,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "createTime": 1517966743780,
    "deployed": false,
    "isSeeded": true,
    "isStale": false,
    "lastUpdateTime": 1517966743780,
    "name": "orbix-loc-ssl",
    "namespace": "scalablegroup:application",
    "provisioningState": "DEFINED",
    "qualifier": "application",
    "resourceVersion": 0,
    "targetIdList": [

    ],
    "type": "scalablegroup",
    "cfsChangeInfo": [

    ],
    "customProvisions": [

    ],
    "scalableGroupExternalHandle": "orbix-loc-ssl",
    "scalableGroupType": "APPLICATION",
    "securityGroupTag": 0,
    "state": "ACTIVE",
    "vnAgnostic": true,
    "identitySource": {
      "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
      "instanceId": 10010,
      "authEntityId": 10010,
      "displayName": "10010",
      "authEntityClass": 1738953278,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "state": "INACTIVE",
      "type": "NBAR"
    },
    "indicativeNetworkIdentity": [
      {
        "id": "4cd43c52-d77a-4c36-b0d1-2122f7c46bce",
        "instanceId": 14443,
        "authEntityId": 14443,
        "displayName": "14443",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "3077",
        "protocol": "TCP",
        "upperPort": 0
      },
      {
        "id": "04cab8c2-ba9f-46eb-8ea2-b7dea27c9372",
        "instanceId": 14444,
        "authEntityId": 14444,
        "displayName": "14444",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "3077",
        "protocol": "UDP",
        "upperPort": 0
      }
    ],
    "networkApplications": [
      {
        "id": "76e87165-9d58-4076-ad41-7b4dfbe4ad9b",
        "instanceId": 11874,
        "authEntityId": 11874,
        "displayName": "11874",
        "authEntityClass": -217092956,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "appProtocol": "tcp/udp",
        "applicationGroup": "corba-group",
        "applicationType": "DEFAULT",
        "categoryId": "58aa61dc-dd95-4a6d-9c7c-0ebf16da6dde",
        "createTime": 1517966743780,
        "encrypted": "true",
        "engineId": "3",
        "helpString": "Orbix 2000 Locator over SSL",
        "ignoreConflict": false,
        "lastUpdateTime": 1517966743780,
        "longDescription": "Used by Progree Software Corporation in their Orbix software for enterprise COBRA solutions.",
        "name": "orbix-loc-ssl",
        "nbarId": "706",
        "p2pTechnology": "false",
        "popularity": 6,
        "rank": 65535,
        "references": "http://www.progress.com/en/orbix/index.html",
        "selectorId": "3077",
        "subCategory": "b90786f4-0190-45b8-9924-21b3e9682590",
        "trafficClass": "TRANSACTIONAL_DATA",
        "tunnel": "false"
      }
    ],
    "networkIdentity": [
      {
        "id": "9c6298d9-2de3-44cf-b3c4-37264848e31a",
        "instanceId": 14441,
        "authEntityId": 14441,
        "displayName": "14441",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "3077",
        "protocol": "UDP",
        "upperPort": 0
      },
      {
        "id": "13eab9b1-fb65-4234-8b47-5bbe0f181b76",
        "instanceId": 14442,
        "authEntityId": 14442,
        "displayName": "14442",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "3077",
        "protocol": "TCP",
        "upperPort": 0
      }
    ],
    "parentScalableGroup": {
      "idRef": "cff11752-fafb-4e22-9e6f-11f44f59a9a3"
    },
    "user": [

    ],
    "userGroup": [

    ]
  },
  {
    "id": "5837e81f-f6f1-4f29-bba2-6b3426ed3e00",
    "instanceId": 4515,
    "authEntityId": 4515,
    "displayName": "4515",
    "authEntityClass": -1909073334,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "createTime": 1517966742860,
    "deployed": false,
    "isSeeded": true,
    "isStale": false,
    "lastUpdateTime": 1517966742860,
    "name": "gss-xlicen",
    "namespace": "scalablegroup:application",
    "provisioningState": "DEFINED",
    "qualifier": "application",
    "resourceVersion": 0,
    "targetIdList": [

    ],
    "type": "scalablegroup",
    "cfsChangeInfo": [

    ],
    "customProvisions": [

    ],
    "scalableGroupExternalHandle": "gss-xlicen",
    "scalableGroupType": "APPLICATION",
    "securityGroupTag": 0,
    "state": "ACTIVE",
    "vnAgnostic": true,
    "identitySource": {
      "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
      "instanceId": 10010,
      "authEntityId": 10010,
      "displayName": "10010",
      "authEntityClass": 1738953278,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "state": "INACTIVE",
      "type": "NBAR"
    },
    "indicativeNetworkIdentity": [
      {
        "id": "0d04d6d9-1b83-401a-b6a5-756899b7d7fe",
        "instanceId": 13283,
        "authEntityId": 13283,
        "displayName": "13283",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "128",
        "protocol": "UDP",
        "upperPort": 0
      },
      {
        "id": "e4b7fc32-5ba9-43ee-b6f8-1237fc3c1917",
        "instanceId": 13284,
        "authEntityId": 13284,
        "displayName": "13284",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "128",
        "protocol": "TCP",
        "upperPort": 0
      }
    ],
    "networkApplications": [
      {
        "id": "f08a6668-a2c5-4eab-ab38-911b5adb465f",
        "instanceId": 11466,
        "authEntityId": 11466,
        "displayName": "11466",
        "authEntityClass": -217092956,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "appProtocol": "tcp/udp",
        "applicationGroup": "other",
        "applicationType": "DEFAULT",
        "categoryId": "6daa3dfd-3526-407a-88f0-97824b3f3a24",
        "createTime": 1517966742860,
        "encrypted": "false",
        "engineId": "3",
        "helpString": "GNU Generation Foundation NCP",
        "ignoreConflict": false,
        "lastUpdateTime": 1517966742860,
        "longDescription": "Registered with IANA on port 128 TCP/UDP",
        "name": "gss-xlicen",
        "nbarId": "997",
        "p2pTechnology": "false",
        "popularity": 1,
        "rank": 65535,
        "references": "http://www.iana.org/assignments/service-names-port-numbers/service-names-port-numbers.xml",
        "selectorId": "128",
        "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
        "trafficClass": "BULK_DATA",
        "tunnel": "false"
      }
    ],
    "networkIdentity": [
      {
        "id": "77f626d7-4ca3-4851-b1bf-63d245d2eac6",
        "instanceId": 13281,
        "authEntityId": 13281,
        "displayName": "13281",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "128",
        "protocol": "TCP",
        "upperPort": 0
      },
      {
        "id": "a7fb4d7d-06a2-43a2-a35d-08fa4048136e",
        "instanceId": 13282,
        "authEntityId": 13282,
        "displayName": "13282",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "128",
        "protocol": "UDP",
        "upperPort": 0
      }
    ],
    "parentScalableGroup": {
      "idRef": "000c15bf-28fe-4fbb-9b69-35e6502721f1"
    },
    "user": [

    ],
    "userGroup": [

    ]
  },
  {
    "id": "5913b0c6-6860-4559-8da9-7e1b7f0efe18",
    "instanceId": 15179,
    "authEntityId": 15179,
    "displayName": "15179",
    "authEntityClass": -1909073334,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "createTime": 1517966744416,
    "deployed": false,
    "isSeeded": true,
    "isStale": false,
    "lastUpdateTime": 1517966744416,
    "name": "sohu-com",
    "namespace": "scalablegroup:application",
    "provisioningState": "DEFINED",
    "qualifier": "application",
    "resourceVersion": 0,
    "targetIdList": [

    ],
    "type": "scalablegroup",
    "cfsChangeInfo": [

    ],
    "customProvisions": [

    ],
    "scalableGroupExternalHandle": "sohu-com",
    "scalableGroupType": "APPLICATION",
    "securityGroupTag": 0,
    "state": "ACTIVE",
    "vnAgnostic": true,
    "identitySource": {
      "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
      "instanceId": 10010,
      "authEntityId": 10010,
      "displayName": "10010",
      "authEntityClass": 1738953278,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "state": "INACTIVE",
      "type": "NBAR"
    },
    "indicativeNetworkIdentity": [

    ],
    "networkApplications": [
      {
        "id": "c64055aa-fc8c-490c-a546-025e01709536",
        "instanceId": 16122,
        "authEntityId": 16122,
        "displayName": "16122",
        "authEntityClass": -217092956,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "appProtocol": "tcp/udp",
        "applicationGroup": "other",
        "applicationType": "DEFAULT",
        "categoryId": "878644b5-fe91-45b4-9204-31c84a73ba48",
        "createTime": 1517966744416,
        "encrypted": "false",
        "engineId": "13",
        "helpString": "Sohu.com - Chinese search engine with other services like games and advertising",
        "ignoreConflict": false,
        "lastUpdateTime": 1517966744416,
        "longDescription": "Sohu.com - Chinese search engine with other services like games and advertising",
        "name": "sohu-com",
        "nbarId": "259",
        "p2pTechnology": "false",
        "popularity": 3,
        "rank": 65535,
        "references": "http://sohu.com",
        "selectorId": "466",
        "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
        "trafficClass": "TRANSACTIONAL_DATA",
        "tunnel": "false"
      }
    ],
    "networkIdentity": [
      {
        "id": "48de0f1f-86db-44de-9a98-b5ed39e59767",
        "instanceId": 17187,
        "authEntityId": 17187,
        "displayName": "17187",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "53,80,443,5353",
        "protocol": "TCP",
        "upperPort": 0
      },
      {
        "id": "11ef801e-cae4-4ce2-a4bf-5fa622ccdba8",
        "instanceId": 17188,
        "authEntityId": 17188,
        "displayName": "17188",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "53,5353",
        "protocol": "UDP",
        "upperPort": 0
      }
    ],
    "parentScalableGroup": {
      "idRef": "28e08de4-d7b2-4521-84ce-bc1e5ee0b9cc"
    },
    "user": [

    ],
    "userGroup": [

    ]
  },
  {
    "id": "592534b4-a8c1-481d-a511-9c4fa6b40e98",
    "instanceId": 15246,
    "authEntityId": 15246,
    "displayName": "15246",
    "authEntityClass": -1909073334,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "createTime": 1517966744572,
    "deployed": false,
    "isSeeded": true,
    "isStale": false,
    "lastUpdateTime": 1517966744572,
    "name": "syslog",
    "namespace": "scalablegroup:application",
    "provisioningState": "DEFINED",
    "qualifier": "application",
    "resourceVersion": 0,
    "targetIdList": [

    ],
    "type": "scalablegroup",
    "cfsChangeInfo": [

    ],
    "customProvisions": [

    ],
    "scalableGroupExternalHandle": "syslog",
    "scalableGroupType": "APPLICATION",
    "securityGroupTag": 0,
    "state": "ACTIVE",
    "vnAgnostic": true,
    "identitySource": {
      "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
      "instanceId": 10010,
      "authEntityId": 10010,
      "displayName": "10010",
      "authEntityClass": 1738953278,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "state": "INACTIVE",
      "type": "NBAR"
    },
    "indicativeNetworkIdentity": [
      {
        "id": "73828ef3-3fbb-4697-bb1b-8cf1b726ec9b",
        "instanceId": 17377,
        "authEntityId": 17377,
        "displayName": "17377",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "514",
        "protocol": "UDP",
        "upperPort": 0
      }
    ],
    "networkApplications": [
      {
        "id": "638357f6-d107-410f-9967-833b148a8360",
        "instanceId": 16189,
        "authEntityId": 16189,
        "displayName": "16189",
        "authEntityClass": -217092956,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "appProtocol": "udp",
        "applicationGroup": "other",
        "applicationType": "DEFAULT",
        "categoryId": "1fc17362-9334-4c7b-b752-5afdbb574017",
        "createTime": 1517966744572,
        "encrypted": "false",
        "engineId": "13",
        "helpString": "syslog protocol",
        "ignoreConflict": false,
        "lastUpdateTime": 1517966744572,
        "longDescription": "syslog is a standard for message logging. It permits separation of the software thatgenerates messages, the system that stores them, and the software that reports and analyzes them.Each message is labeled with a facility code, indicating the software type generating the message, and assigned a severity label.",
        "name": "syslog",
        "nbarId": "41",
        "p2pTechnology": "false",
        "popularity": 5,
        "rank": 65535,
        "references": "https://tools.ietf.org/html/rfc3164,http://tools.ietf.org/html/rfc5424",
        "selectorId": "41",
        "subCategory": "787a7d77-977b-4974-89d3-379ba49e88b7",
        "trafficClass": "OPS_ADMIN_MGMT",
        "tunnel": "false"
      }
    ],
    "networkIdentity": [
      {
        "id": "c14b7d75-5925-4cfd-aae1-51d8d0b00acf",
        "instanceId": 17376,
        "authEntityId": 17376,
        "displayName": "17376",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "514",
        "protocol": "UDP",
        "upperPort": 0
      }
    ],
    "parentScalableGroup": {
      "idRef": "e06dd89f-0a70-4422-ab0b-c2314650a9a7"
    },
    "user": [

    ],
    "userGroup": [

    ]
  },
  {
    "id": "592926a5-cd45-4bc1-9b66-d088fdbd513d",
    "instanceId": 4710,
    "authEntityId": 4710,
    "displayName": "4710",
    "authEntityClass": -1909073334,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "createTime": 1517966743332,
    "deployed": false,
    "isSeeded": true,
    "isStale": false,
    "lastUpdateTime": 1517966743332,
    "name": "mac-os-x-updates",
    "namespace": "scalablegroup:application",
    "provisioningState": "DEFINED",
    "qualifier": "application",
    "resourceVersion": 0,
    "targetIdList": [

    ],
    "type": "scalablegroup",
    "cfsChangeInfo": [

    ],
    "customProvisions": [

    ],
    "scalableGroupExternalHandle": "mac-os-x-updates",
    "scalableGroupType": "APPLICATION",
    "securityGroupTag": 0,
    "state": "ACTIVE",
    "vnAgnostic": true,
    "identitySource": {
      "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
      "instanceId": 10010,
      "authEntityId": 10010,
      "displayName": "10010",
      "authEntityClass": 1738953278,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "state": "INACTIVE",
      "type": "NBAR"
    },
    "indicativeNetworkIdentity": [

    ],
    "networkApplications": [
      {
        "id": "440a4328-21b4-47b4-a555-eb6ddb9be210",
        "instanceId": 11660,
        "authEntityId": 11660,
        "displayName": "11660",
        "authEntityClass": -217092956,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "appProtocol": "tcp/udp",
        "applicationGroup": "apple-group",
        "applicationType": "DEFAULT",
        "categoryId": "9d9132a2-d99b-49db-a414-9fc93dea6604",
        "createTime": 1517966743332,
        "encrypted": "true",
        "engineId": "13",
        "helpString": "MAC operating system updates",
        "ignoreConflict": false,
        "lastUpdateTime": 1517966743332,
        "longDescription": "MAC OS X updates is a service that provides updates for the MAC OS X operating system and its installed components.",
        "name": "mac-os-x-updates",
        "nbarId": "1524",
        "p2pTechnology": "false",
        "popularity": 8,
        "rank": 65535,
        "references": "http://www.apple.com/softwareupdate/",
        "selectorId": "585",
        "subCategory": "98e625d1-8720-4d2b-a186-c821a5d86693",
        "trafficClass": "BULK_DATA",
        "tunnel": "false"
      }
    ],
    "networkIdentity": [
      {
        "id": "30f981ab-7d17-49ad-957d-7fd4f1742ad2",
        "instanceId": 13796,
        "authEntityId": 13796,
        "displayName": "13796",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "53,5353",
        "protocol": "UDP",
        "upperPort": 0
      },
      {
        "id": "25102b0e-d7fe-4125-a681-d5c2d805142c",
        "instanceId": 13797,
        "authEntityId": 13797,
        "displayName": "13797",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "53,80,443,5353",
        "protocol": "TCP",
        "upperPort": 0
      }
    ],
    "parentScalableGroup": {
      "idRef": "4a68bc73-53c6-4deb-9983-784a0338aefe"
    },
    "user": [

    ],
    "userGroup": [

    ]
  },
  {
    "id": "5934015d-a2d2-4ae8-97d6-430716461638",
    "instanceId": 15259,
    "authEntityId": 15259,
    "displayName": "15259",
    "authEntityClass": -1909073334,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "createTime": 1517966744598,
    "deployed": false,
    "isSeeded": true,
    "isStale": false,
    "lastUpdateTime": 1517966744598,
    "name": "teamsound",
    "namespace": "scalablegroup:application",
    "provisioningState": "DEFINED",
    "qualifier": "application",
    "resourceVersion": 0,
    "targetIdList": [

    ],
    "type": "scalablegroup",
    "cfsChangeInfo": [

    ],
    "customProvisions": [

    ],
    "scalableGroupExternalHandle": "teamsound",
    "scalableGroupType": "APPLICATION",
    "securityGroupTag": 0,
    "state": "ACTIVE",
    "vnAgnostic": true,
    "identitySource": {
      "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
      "instanceId": 10010,
      "authEntityId": 10010,
      "displayName": "10010",
      "authEntityClass": 1738953278,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "state": "INACTIVE",
      "type": "NBAR"
    },
    "indicativeNetworkIdentity": [
      {
        "id": "5b296b2d-2d99-42ce-bdb9-d545fa4d4cf4",
        "instanceId": 17418,
        "authEntityId": 17418,
        "displayName": "17418",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "40001,40011",
        "protocol": "TCP",
        "upperPort": 0
      },
      {
        "id": "61e57b1f-f8a3-4f6a-b915-0da5250882a3",
        "instanceId": 17419,
        "authEntityId": 17419,
        "displayName": "17419",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "40001,40002,40003,40004,40011",
        "protocol": "UDP",
        "upperPort": 0
      }
    ],
    "networkApplications": [
      {
        "id": "ad935d91-7dc6-414d-9f4a-d5ff11e72ff4",
        "instanceId": 16202,
        "authEntityId": 16202,
        "displayName": "16202",
        "authEntityClass": -217092956,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "appProtocol": "tcp/udp",
        "applicationGroup": "other",
        "applicationType": "DEFAULT",
        "categoryId": "6daa3dfd-3526-407a-88f0-97824b3f3a24",
        "createTime": 1517966744598,
        "encrypted": "false",
        "engineId": "3",
        "helpString": "Voice conferencing software for online game",
        "ignoreConflict": false,
        "lastUpdateTime": 1517966744598,
        "longDescription": "TeamSound is a voice conferencing software for online game players. It can operate as a dedicated or non-dedicated peer-to-peer voice communication system, geared for use in multiplayer, networked Internet games.",
        "name": "teamsound",
        "nbarId": "1391",
        "p2pTechnology": "false",
        "popularity": 3,
        "rank": 65535,
        "references": "http://www.fileol.com/audio-and-video/teamsound-5.6.html",
        "selectorId": "40001",
        "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
        "trafficClass": "BULK_DATA",
        "tunnel": "false"
      }
    ],
    "networkIdentity": [
      {
        "id": "a5f18fd6-2927-48db-83e1-df8f7c73ed5d",
        "instanceId": 17416,
        "authEntityId": 17416,
        "displayName": "17416",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "40001,40011",
        "protocol": "TCP",
        "upperPort": 0
      },
      {
        "id": "d6b3dd6e-df07-42c0-96b1-e2deb5993d51",
        "instanceId": 17417,
        "authEntityId": 17417,
        "displayName": "17417",
        "authEntityClass": 124090504,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "ipv4Subnet": [

        ],
        "ipv6Subnet": [

        ],
        "lowerPort": 0,
        "ports": "40001,40002,40003,40004,40011",
        "protocol": "UDP",
        "upperPort": 0
      }
    ],
    "parentScalableGroup": {
      "idRef": "000c15bf-28fe-4fbb-9b69-35e6502721f1"
    },
    "user": [

    ],
    "userGroup": [

    ]
  },{
  "id": "594a7549-a097-4f65-a91d-2f3821ff2678",
  "instanceId": 15461,
  "authEntityId": 15461,
  "displayName": "15461",
  "authEntityClass": -1909073334,
  "deployPending": "NONE",
  "instanceVersion": 0,
  "createTime": 1517966745013,
  "deployed": false,
  "isSeeded": true,
  "isStale": false,
  "lastUpdateTime": 1517966745013,
  "name": "yellowpages-us",
  "namespace": "scalablegroup:application",
  "provisioningState": "DEFINED",
  "qualifier": "application",
  "resourceVersion": 0,
  "targetIdList": [

  ],
  "type": "scalablegroup",
  "cfsChangeInfo": [

  ],
  "customProvisions": [

  ],
  "scalableGroupExternalHandle": "yellowpages-us",
  "scalableGroupType": "APPLICATION",
  "securityGroupTag": 0,
  "state": "ACTIVE",
  "vnAgnostic": true,
  "identitySource": {
    "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
    "instanceId": 10010,
    "authEntityId": 10010,
    "displayName": "10010",
    "authEntityClass": 1738953278,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "state": "INACTIVE",
    "type": "NBAR"
  },
  "indicativeNetworkIdentity": [

  ],
  "networkApplications": [
    {
      "id": "ce73b33c-7a20-4357-9901-e2d562cfdaee",
      "instanceId": 16404,
      "authEntityId": 16404,
      "displayName": "16404",
      "authEntityClass": -217092956,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "appProtocol": "tcp/udp",
      "applicationGroup": "other",
      "applicationType": "DEFAULT",
      "categoryId": "878644b5-fe91-45b4-9204-31c84a73ba48",
      "createTime": 1517966745012,
      "encrypted": "false",
      "engineId": "13",
      "helpString": "Yellow Pages - Online directory and Mapping services.",
      "ignoreConflict": false,
      "lastUpdateTime": 1517966745012,
      "longDescription": "Yellow Pages - Online directory and Mapping services.",
      "name": "yellowpages-us",
      "nbarId": "1727",
      "p2pTechnology": "false",
      "popularity": 3,
      "rank": 65535,
      "references": "http://yellowpages.com",
      "selectorId": "799",
      "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
      "trafficClass": "TRANSACTIONAL_DATA",
      "tunnel": "false"
    }
  ],
  "networkIdentity": [
    {
      "id": "774c9610-d9d1-4e2f-9fd7-cd44630470f4",
      "instanceId": 17958,
      "authEntityId": 17958,
      "displayName": "17958",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "53,5353",
      "protocol": "UDP",
      "upperPort": 0
    },
    {
      "id": "4c4984ef-0ff8-4f1a-9e74-29691f6e9898",
      "instanceId": 17959,
      "authEntityId": 17959,
      "displayName": "17959",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "53,80,443,5353",
      "protocol": "TCP",
      "upperPort": 0
    }
  ],
  "parentScalableGroup": {
    "idRef": "28e08de4-d7b2-4521-84ce-bc1e5ee0b9cc"
  },
  "user": [

  ],
  "userGroup": [

  ]
},
{
  "id": "59aedb0e-ac72-4b6d-9e27-939027675da9",
  "instanceId": 4677,
  "authEntityId": 4677,
  "displayName": "4677",
  "authEntityClass": -1909073334,
  "deployPending": "NONE",
  "instanceVersion": 0,
  "createTime": 1517966743263,
  "deployed": false,
  "isSeeded": true,
  "isStale": false,
  "lastUpdateTime": 1517966743263,
  "name": "kis",
  "namespace": "scalablegroup:application",
  "provisioningState": "DEFINED",
  "qualifier": "application",
  "resourceVersion": 0,
  "targetIdList": [

  ],
  "type": "scalablegroup",
  "cfsChangeInfo": [

  ],
  "customProvisions": [

  ],
  "scalableGroupExternalHandle": "kis",
  "scalableGroupType": "APPLICATION",
  "securityGroupTag": 0,
  "state": "ACTIVE",
  "vnAgnostic": true,
  "identitySource": {
    "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
    "instanceId": 10010,
    "authEntityId": 10010,
    "displayName": "10010",
    "authEntityClass": 1738953278,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "state": "INACTIVE",
    "type": "NBAR"
  },
  "indicativeNetworkIdentity": [
    {
      "id": "1fc4e0b3-76d2-4473-a91c-863fe782e7e7",
      "instanceId": 13702,
      "authEntityId": 13702,
      "displayName": "13702",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "186",
      "protocol": "UDP",
      "upperPort": 0
    },
    {
      "id": "7430e808-2834-4d51-b4a3-4105f6fecb63",
      "instanceId": 13703,
      "authEntityId": 13703,
      "displayName": "13703",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "186",
      "protocol": "TCP",
      "upperPort": 0
    }
  ],
  "networkApplications": [
    {
      "id": "ed2aa402-ea5d-4915-a8da-db54eea8bcc4",
      "instanceId": 11627,
      "authEntityId": 11627,
      "displayName": "11627",
      "authEntityClass": -217092956,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "appProtocol": "tcp/udp",
      "applicationGroup": "other",
      "applicationType": "DEFAULT",
      "categoryId": "6daa3dfd-3526-407a-88f0-97824b3f3a24",
      "createTime": 1517966743263,
      "encrypted": "false",
      "engineId": "3",
      "helpString": "KIS Protocol",
      "ignoreConflict": false,
      "lastUpdateTime": 1517966743263,
      "longDescription": "Registered with IANA on port 186 TCP/UDP",
      "name": "kis",
      "nbarId": "1029",
      "p2pTechnology": "false",
      "popularity": 1,
      "rank": 65535,
      "references": "http://www.iana.org/assignments/service-names-port-numbers/service-names-port-numbers.xml",
      "selectorId": "186",
      "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
      "trafficClass": "BULK_DATA",
      "tunnel": "false"
    }
  ],
  "networkIdentity": [
    {
      "id": "15c709e4-16fb-4dff-801a-491c880278ea",
      "instanceId": 13700,
      "authEntityId": 13700,
      "displayName": "13700",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "186",
      "protocol": "UDP",
      "upperPort": 0
    },
    {
      "id": "05889836-9971-4053-81d5-4a43890dd0ee",
      "instanceId": 13701,
      "authEntityId": 13701,
      "displayName": "13701",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "186",
      "protocol": "TCP",
      "upperPort": 0
    }
  ],
  "parentScalableGroup": {
    "idRef": "000c15bf-28fe-4fbb-9b69-35e6502721f1"
  },
  "user": [

  ],
  "userGroup": [

  ]
},
{
  "id": "59f059a4-7598-4eb9-b2be-14ce930d29e4",
  "instanceId": 15216,
  "authEntityId": 15216,
  "displayName": "15216",
  "authEntityClass": -1909073334,
  "deployPending": "NONE",
  "instanceVersion": 0,
  "createTime": 1517966744508,
  "deployed": false,
  "isSeeded": true,
  "isStale": false,
  "lastUpdateTime": 1517966744508,
  "name": "statistical-download",
  "namespace": "scalablegroup:application",
  "provisioningState": "DEFINED",
  "qualifier": "application",
  "resourceVersion": 0,
  "targetIdList": [

  ],
  "type": "scalablegroup",
  "cfsChangeInfo": [

  ],
  "customProvisions": [

  ],
  "scalableGroupExternalHandle": "statistical-download",
  "scalableGroupType": "APPLICATION",
  "securityGroupTag": 0,
  "state": "ACTIVE",
  "vnAgnostic": true,
  "identitySource": {
    "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
    "instanceId": 10010,
    "authEntityId": 10010,
    "displayName": "10010",
    "authEntityClass": 1738953278,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "state": "INACTIVE",
    "type": "NBAR"
  },
  "indicativeNetworkIdentity": [

  ],
  "networkApplications": [
    {
      "id": "7216a344-c8ab-4fc6-b566-7d9b3705962c",
      "instanceId": 16159,
      "authEntityId": 16159,
      "displayName": "16159",
      "authEntityClass": -217092956,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "applicationGroup": "other",
      "applicationType": "DEFAULT",
      "categoryId": "6daa3dfd-3526-407a-88f0-97824b3f3a24",
      "createTime": 1517966744508,
      "encrypted": "unassigned",
      "engineId": "13",
      "helpString": "Statistical Download",
      "ignoreConflict": false,
      "lastUpdateTime": 1517966744508,
      "longDescription": "Statistical Download based on machine learning",
      "name": "statistical-download",
      "nbarId": "1888",
      "p2pTechnology": "unassigned",
      "popularity": 8,
      "rank": 65535,
      "selectorId": "1888",
      "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
      "trafficClass": "BULK_DATA",
      "tunnel": "unassigned"
    }
  ],
  "networkIdentity": [

  ],
  "parentScalableGroup": {
    "idRef": "000c15bf-28fe-4fbb-9b69-35e6502721f1"
  },
  "user": [

  ],
  "userGroup": [

  ]
},
{
  "id": "5a47f753-d2f7-4944-a0c6-3c1fcc386c88",
  "instanceId": 4154,
  "authEntityId": 4154,
  "displayName": "4154",
  "authEntityClass": -1909073334,
  "deployPending": "NONE",
  "instanceVersion": 0,
  "createTime": 1517966741414,
  "deployed": false,
  "isSeeded": true,
  "isStale": false,
  "lastUpdateTime": 1517966741414,
  "name": "ayiya-ipv6-tunneled",
  "namespace": "scalablegroup:application",
  "provisioningState": "DEFINED",
  "qualifier": "application",
  "resourceVersion": 0,
  "targetIdList": [

  ],
  "type": "scalablegroup",
  "cfsChangeInfo": [

  ],
  "customProvisions": [

  ],
  "scalableGroupExternalHandle": "ayiya-ipv6-tunneled",
  "scalableGroupType": "APPLICATION",
  "securityGroupTag": 0,
  "state": "ACTIVE",
  "vnAgnostic": true,
  "identitySource": {
    "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
    "instanceId": 10010,
    "authEntityId": 10010,
    "displayName": "10010",
    "authEntityClass": 1738953278,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "state": "INACTIVE",
    "type": "NBAR"
  },
  "indicativeNetworkIdentity": [
    {
      "id": "e469cf88-a03d-4ac4-a5f5-3ef75cb36819",
      "instanceId": 12318,
      "authEntityId": 12318,
      "displayName": "12318",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "5072",
      "protocol": "UDP",
      "upperPort": 0
    },
    {
      "id": "ff64376c-acf1-4c9d-9691-735016e52358",
      "instanceId": 12319,
      "authEntityId": 12319,
      "displayName": "12319",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "5072",
      "protocol": "TCP",
      "upperPort": 0
    }
  ],
  "networkApplications": [
    {
      "id": "1b7e03b7-3a9f-4b71-8acc-2db135f58621",
      "instanceId": 11114,
      "authEntityId": 11114,
      "displayName": "11114",
      "authEntityClass": -217092956,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "appProtocol": "tcp/udp",
      "applicationGroup": "other",
      "applicationType": "DEFAULT",
      "categoryId": "70fce3cd-f2bd-48de-9020-7b7840b9e7a1",
      "createTime": 1517966741414,
      "encrypted": "false",
      "engineId": "13",
      "helpString": "Ayiya IPv6 tunneling protocol",
      "ignoreConflict": false,
      "lastUpdateTime": 1517966741414,
      "longDescription": "Anything In Anything (AYIYA) is a tunneling protocol that connects islands of IP traffic. The protocol in NBAR supports only IPV6 tunneled over IPV4 (not IPV6 over IPV6).",
      "name": "ayiya-ipv6-tunneled",
      "nbarId": "1220",
      "p2pTechnology": "false",
      "popularity": 7,
      "rank": 65535,
      "references": "http://www.sixxs.net/tools/ayiya/",
      "selectorId": "327",
      "subCategory": "f3c0f761-d894-4112-ab89-b90fc84fd660",
      "trafficClass": "BULK_DATA",
      "tunnel": "true"
    }
  ],
  "networkIdentity": [
    {
      "id": "d449737b-348b-40d4-9100-0f9a79986339",
      "instanceId": 12316,
      "authEntityId": 12316,
      "displayName": "12316",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "5072",
      "protocol": "UDP",
      "upperPort": 0
    },
    {
      "id": "e1ca2f20-e8bf-44a8-848c-477a63ea65b7",
      "instanceId": 12317,
      "authEntityId": 12317,
      "displayName": "12317",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "5072",
      "protocol": "TCP",
      "upperPort": 0
    }
  ],
  "parentScalableGroup": {
    "idRef": "79f9d505-477f-4ccd-bd2a-c5fc8097a2e1"
  },
  "user": [

  ],
  "userGroup": [

  ]
},
{
  "id": "5a485c0f-e667-4bba-b96a-f09e5ddd9bff",
  "instanceId": 15348,
  "authEntityId": 15348,
  "displayName": "15348",
  "authEntityClass": -1909073334,
  "deployPending": "NONE",
  "instanceVersion": 0,
  "createTime": 1517966744782,
  "deployed": false,
  "isSeeded": true,
  "isStale": false,
  "lastUpdateTime": 1517966744782,
  "name": "verizon-wireless-web",
  "namespace": "scalablegroup:application",
  "provisioningState": "DEFINED",
  "qualifier": "application",
  "resourceVersion": 0,
  "targetIdList": [

  ],
  "type": "scalablegroup",
  "cfsChangeInfo": [

  ],
  "customProvisions": [

  ],
  "scalableGroupExternalHandle": "verizon-wireless-web",
  "scalableGroupType": "APPLICATION",
  "securityGroupTag": 0,
  "state": "ACTIVE",
  "vnAgnostic": true,
  "identitySource": {
    "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
    "instanceId": 10010,
    "authEntityId": 10010,
    "displayName": "10010",
    "authEntityClass": 1738953278,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "state": "INACTIVE",
    "type": "NBAR"
  },
  "indicativeNetworkIdentity": [

  ],
  "networkApplications": [
    {
      "id": "52dd5c5a-95d6-41f6-828d-52b3a170c3da",
      "instanceId": 16291,
      "authEntityId": 16291,
      "displayName": "16291",
      "authEntityClass": -217092956,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "appProtocol": "tcp/udp",
      "applicationGroup": "other",
      "applicationType": "DEFAULT",
      "categoryId": "878644b5-fe91-45b4-9204-31c84a73ba48",
      "createTime": 1517966744782,
      "encrypted": "false",
      "engineId": "13",
      "helpString": "Verizon Wireless - Telecom and Internet provider.",
      "ignoreConflict": false,
      "lastUpdateTime": 1517966744782,
      "longDescription": "Verizon Wireless - Telecom and Internet provider.",
      "name": "verizon-wireless-web",
      "nbarId": "1629",
      "p2pTechnology": "false",
      "popularity": 3,
      "rank": 65535,
      "references": "http://verizonwireless.com",
      "selectorId": "699",
      "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
      "trafficClass": "TRANSACTIONAL_DATA",
      "tunnel": "false"
    }
  ],
  "networkIdentity": [
    {
      "id": "d1065900-4eb5-42c5-ad0b-38423a35a9a6",
      "instanceId": 17664,
      "authEntityId": 17664,
      "displayName": "17664",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "53,5353",
      "protocol": "UDP",
      "upperPort": 0
    },
    {
      "id": "571c501e-5d73-4155-8df0-440a090ead7d",
      "instanceId": 17663,
      "authEntityId": 17663,
      "displayName": "17663",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "53,80,443,5353",
      "protocol": "TCP",
      "upperPort": 0
    }
  ],
  "parentScalableGroup": {
    "idRef": "28e08de4-d7b2-4521-84ce-bc1e5ee0b9cc"
  },
  "user": [

  ],
  "userGroup": [

  ]
},
{
  "id": "5a6e53d8-3afc-402a-9462-a33f3790880f",
  "instanceId": 4317,
  "authEntityId": 4317,
  "displayName": "4317",
  "authEntityClass": -1909073334,
  "deployPending": "NONE",
  "instanceVersion": 0,
  "createTime": 1517966742268,
  "deployed": false,
  "isSeeded": true,
  "isStale": false,
  "lastUpdateTime": 1517966742268,
  "name": "cybercash",
  "namespace": "scalablegroup:application",
  "provisioningState": "DEFINED",
  "qualifier": "application",
  "resourceVersion": 0,
  "targetIdList": [

  ],
  "type": "scalablegroup",
  "cfsChangeInfo": [

  ],
  "customProvisions": [

  ],
  "scalableGroupExternalHandle": "cybercash",
  "scalableGroupType": "APPLICATION",
  "securityGroupTag": 0,
  "state": "ACTIVE",
  "vnAgnostic": true,
  "identitySource": {
    "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
    "instanceId": 10010,
    "authEntityId": 10010,
    "displayName": "10010",
    "authEntityClass": 1738953278,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "state": "INACTIVE",
    "type": "NBAR"
  },
  "indicativeNetworkIdentity": [
    {
      "id": "3be30523-f765-4684-b54e-560baa8bd71c",
      "instanceId": 12728,
      "authEntityId": 12728,
      "displayName": "12728",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "551",
      "protocol": "UDP",
      "upperPort": 0
    },
    {
      "id": "465b23ca-d867-4bd4-8e8c-b944dfdbd373",
      "instanceId": 12729,
      "authEntityId": 12729,
      "displayName": "12729",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "551",
      "protocol": "TCP",
      "upperPort": 0
    }
  ],
  "networkApplications": [
    {
      "id": "002c5cea-f0ba-4e96-9efb-afb910a69fa7",
      "instanceId": 11269,
      "authEntityId": 11269,
      "displayName": "11269",
      "authEntityClass": -217092956,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "appProtocol": "tcp/udp",
      "applicationGroup": "other",
      "applicationType": "DEFAULT",
      "categoryId": "91a59054-00c9-4445-96e9-4cb77e86dcd7",
      "createTime": 1517966742268,
      "encrypted": "false",
      "engineId": "3",
      "helpString": "cybercash",
      "ignoreConflict": false,
      "lastUpdateTime": 1517966742268,
      "longDescription": "CyberCash serves as a conduit through which payments can be transported quickly, easily and safely between buyers, sellers and their banks. The CyberCash system provides several separate payment services on the Internet including credit card and electronic cash.",
      "name": "cybercash",
      "nbarId": "468",
      "p2pTechnology": "false",
      "popularity": 1,
      "rank": 65535,
      "references": "http://tools.ietf.org/html/rfc1898",
      "selectorId": "551",
      "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
      "trafficClass": "TRANSACTIONAL_DATA",
      "tunnel": "false"
    }
  ],
  "networkIdentity": [
    {
      "id": "8efc4718-d002-48bd-a754-7c9f9f548d30",
      "instanceId": 12726,
      "authEntityId": 12726,
      "displayName": "12726",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "551",
      "protocol": "TCP",
      "upperPort": 0
    },
    {
      "id": "6fe136bf-b813-4cbe-9e49-a07431c56dce",
      "instanceId": 12727,
      "authEntityId": 12727,
      "displayName": "12727",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "551",
      "protocol": "UDP",
      "upperPort": 0
    }
  ],
  "parentScalableGroup": {
    "idRef": "000c15bf-28fe-4fbb-9b69-35e6502721f1"
  },
  "user": [

  ],
  "userGroup": [

  ]
},
{
  "id": "5a9daa44-b940-4ac5-acc2-1962127f999a",
  "instanceId": 15241,
  "authEntityId": 15241,
  "displayName": "15241",
  "authEntityClass": -1909073334,
  "deployPending": "NONE",
  "instanceVersion": 0,
  "createTime": 1517966744563,
  "deployed": false,
  "isSeeded": true,
  "isStale": false,
  "lastUpdateTime": 1517966744563,
  "name": "sybase",
  "namespace": "scalablegroup:application",
  "provisioningState": "DEFINED",
  "qualifier": "application",
  "resourceVersion": 0,
  "targetIdList": [

  ],
  "type": "scalablegroup",
  "cfsChangeInfo": [

  ],
  "customProvisions": [

  ],
  "scalableGroupExternalHandle": "sybase",
  "scalableGroupType": "APPLICATION",
  "securityGroupTag": 0,
  "state": "ACTIVE",
  "vnAgnostic": true,
  "identitySource": {
    "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
    "instanceId": 10010,
    "authEntityId": 10010,
    "displayName": "10010",
    "authEntityClass": 1738953278,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "state": "INACTIVE",
    "type": "NBAR"
  },
  "indicativeNetworkIdentity": [
    {
      "id": "d2fa1b32-b7f8-4326-9ff8-e8be5efa2fc8",
      "instanceId": 17362,
      "authEntityId": 17362,
      "displayName": "17362",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "1498,2439,2638,4950",
      "protocol": "UDP",
      "upperPort": 0
    },
    {
      "id": "4cc42d9c-12b4-48cb-8112-6fc0fc1ce14b",
      "instanceId": 17363,
      "authEntityId": 17363,
      "displayName": "17363",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "1498,2439,2638,4950",
      "protocol": "TCP",
      "upperPort": 0
    }
  ],
  "networkApplications": [
    {
      "id": "31d4a4de-2296-486d-b33f-572dfc6fad1c",
      "instanceId": 16184,
      "authEntityId": 16184,
      "displayName": "16184",
      "authEntityClass": -217092956,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "appProtocol": "tcp/udp",
      "applicationGroup": "other",
      "applicationType": "DEFAULT",
      "categoryId": "6daa3dfd-3526-407a-88f0-97824b3f3a24",
      "createTime": 1517966744563,
      "encrypted": "false",
      "engineId": "3",
      "helpString": "Database management system",
      "ignoreConflict": false,
      "lastUpdateTime": 1517966744563,
      "longDescription": "Sybase Database Management Software is a suite that offers business and enterprise-level database management services. The software is based on client-server architecture that works on multiple platforms such as Windows and Linux. The software usually uses TCP/UDP ports 1498, 2439, 2638, and 4950.",
      "name": "sybase",
      "nbarId": "1390",
      "p2pTechnology": "false",
      "popularity": 3,
      "rank": 65535,
      "references": "http://www.sybase.com/products/databasemanagement",
      "selectorId": "1498",
      "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
      "trafficClass": "BULK_DATA",
      "tunnel": "false"
    }
  ],
  "networkIdentity": [
    {
      "id": "ecaa9dc1-d86c-44b1-9bc5-b83030b25acd",
      "instanceId": 17360,
      "authEntityId": 17360,
      "displayName": "17360",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "1498,2439,2638,4950",
      "protocol": "UDP",
      "upperPort": 0
    },
    {
      "id": "3f49fcd3-e8bf-4cb8-9ae9-b188a88a5a43",
      "instanceId": 17361,
      "authEntityId": 17361,
      "displayName": "17361",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "1498,2439,2638,4950",
      "protocol": "TCP",
      "upperPort": 0
    }
  ],
  "parentScalableGroup": {
    "idRef": "000c15bf-28fe-4fbb-9b69-35e6502721f1"
  },
  "user": [

  ],
  "userGroup": [

  ]
},
{
  "id": "5ac25252-07b8-485b-a173-03f0a47e3625",
  "instanceId": 4738,
  "authEntityId": 4738,
  "displayName": "4738",
  "authEntityClass": -1909073334,
  "deployPending": "NONE",
  "instanceVersion": 0,
  "createTime": 1517966743395,
  "deployed": false,
  "isSeeded": true,
  "isStale": false,
  "lastUpdateTime": 1517966743395,
  "name": "metagram",
  "namespace": "scalablegroup:application",
  "provisioningState": "DEFINED",
  "qualifier": "application",
  "resourceVersion": 0,
  "targetIdList": [

  ],
  "type": "scalablegroup",
  "cfsChangeInfo": [

  ],
  "customProvisions": [

  ],
  "scalableGroupExternalHandle": "metagram",
  "scalableGroupType": "APPLICATION",
  "securityGroupTag": 0,
  "state": "ACTIVE",
  "vnAgnostic": true,
  "identitySource": {
    "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
    "instanceId": 10010,
    "authEntityId": 10010,
    "displayName": "10010",
    "authEntityClass": 1738953278,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "state": "INACTIVE",
    "type": "NBAR"
  },
  "indicativeNetworkIdentity": [
    {
      "id": "30b70baf-5d73-4929-8635-45fcafe37d8d",
      "instanceId": 13874,
      "authEntityId": 13874,
      "displayName": "13874",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "99",
      "protocol": "UDP",
      "upperPort": 0
    },
    {
      "id": "b434bc5f-c1cc-4bb0-af88-57ceff13418a",
      "instanceId": 13875,
      "authEntityId": 13875,
      "displayName": "13875",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "99",
      "protocol": "TCP",
      "upperPort": 0
    }
  ],
  "networkApplications": [
    {
      "id": "d5b54e0b-272f-4f52-8bd6-a51da99e0eb8",
      "instanceId": 11688,
      "authEntityId": 11688,
      "displayName": "11688",
      "authEntityClass": -217092956,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "appProtocol": "tcp/udp",
      "applicationGroup": "other",
      "applicationType": "DEFAULT",
      "categoryId": "6daa3dfd-3526-407a-88f0-97824b3f3a24",
      "createTime": 1517966743395,
      "encrypted": "false",
      "engineId": "3",
      "helpString": "metagram",
      "ignoreConflict": false,
      "lastUpdateTime": 1517966743395,
      "longDescription": "Registered with IANA on port 99 TCP/UDP",
      "name": "metagram",
      "nbarId": "970",
      "p2pTechnology": "false",
      "popularity": 1,
      "rank": 65535,
      "references": "http://www.iana.org/assignments/service-names-port-numbers/service-names-port-numbers.xml",
      "selectorId": "99",
      "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
      "trafficClass": "BULK_DATA",
      "tunnel": "false"
    }
  ],
  "networkIdentity": [
    {
      "id": "f7d9d216-e2f3-438f-afc0-41a74e9952f1",
      "instanceId": 13872,
      "authEntityId": 13872,
      "displayName": "13872",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "99",
      "protocol": "TCP",
      "upperPort": 0
    },
    {
      "id": "5d03f270-3595-4fb8-84bb-0660091e7393",
      "instanceId": 13873,
      "authEntityId": 13873,
      "displayName": "13873",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "99",
      "protocol": "UDP",
      "upperPort": 0
    }
  ],
  "parentScalableGroup": {
    "idRef": "000c15bf-28fe-4fbb-9b69-35e6502721f1"
  },
  "user": [

  ],
  "userGroup": [

  ]
},
{
  "id": "5ac273b7-8f92-45ec-b76d-9500c8873aa2",
  "instanceId": 4966,
  "authEntityId": 4966,
  "displayName": "4966",
  "authEntityClass": -1909073334,
  "deployPending": "NONE",
  "instanceVersion": 0,
  "createTime": 1517966743928,
  "deployed": false,
  "isSeeded": true,
  "isStale": false,
  "lastUpdateTime": 1517966743928,
  "name": "pkix-3-ca-ra",
  "namespace": "scalablegroup:application",
  "provisioningState": "DEFINED",
  "qualifier": "application",
  "resourceVersion": 0,
  "targetIdList": [

  ],
  "type": "scalablegroup",
  "cfsChangeInfo": [

  ],
  "customProvisions": [

  ],
  "scalableGroupExternalHandle": "pkix-3-ca-ra",
  "scalableGroupType": "APPLICATION",
  "securityGroupTag": 0,
  "state": "ACTIVE",
  "vnAgnostic": true,
  "identitySource": {
    "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
    "instanceId": 10010,
    "authEntityId": 10010,
    "displayName": "10010",
    "authEntityClass": 1738953278,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "state": "INACTIVE",
    "type": "NBAR"
  },
  "indicativeNetworkIdentity": [
    {
      "id": "fc7cd903-79a4-45f6-a78f-3eb89877770c",
      "instanceId": 14556,
      "authEntityId": 14556,
      "displayName": "14556",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "829",
      "protocol": "UDP",
      "upperPort": 0
    },
    {
      "id": "282395ae-f1e6-4f44-a29e-35278fc25d57",
      "instanceId": 14557,
      "authEntityId": 14557,
      "displayName": "14557",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "829",
      "protocol": "TCP",
      "upperPort": 0
    }
  ],
  "networkApplications": [
    {
      "id": "ba957615-ecc9-49be-8cdf-990d1ee40f5d",
      "instanceId": 11915,
      "authEntityId": 11915,
      "displayName": "11915",
      "authEntityClass": -217092956,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "appProtocol": "tcp/udp",
      "applicationGroup": "other",
      "applicationType": "DEFAULT",
      "categoryId": "1fc17362-9334-4c7b-b752-5afdbb574017",
      "createTime": 1517966743928,
      "encrypted": "false",
      "engineId": "3",
      "helpString": "PKIX-3 CA/RA",
      "ignoreConflict": false,
      "lastUpdateTime": 1517966743928,
      "longDescription": "Registered with IANA on port 829 TCP/UDP",
      "name": "pkix-3-ca-ra",
      "nbarId": "657",
      "p2pTechnology": "false",
      "popularity": 1,
      "rank": 65535,
      "references": "http://www.iana.org/assignments/service-names-port-numbers/service-names-port-numbers.xml",
      "selectorId": "829",
      "subCategory": "e390124a-0fd6-4253-b68e-4b0c1af8cb4b",
      "trafficClass": "OPS_ADMIN_MGMT",
      "tunnel": "false"
    }
  ],
  "networkIdentity": [
    {
      "id": "63b2dd87-2943-490d-849f-6ec6eb898ed4",
      "instanceId": 14554,
      "authEntityId": 14554,
      "displayName": "14554",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "829",
      "protocol": "TCP",
      "upperPort": 0
    },
    {
      "id": "2e7357b3-8784-4c34-b1b2-da1009fbc076",
      "instanceId": 14555,
      "authEntityId": 14555,
      "displayName": "14555",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "829",
      "protocol": "UDP",
      "upperPort": 0
    }
  ],
  "parentScalableGroup": {
    "idRef": "7788bf2d-e289-4597-b500-17aacb7e820e"
  },
  "user": [

  ],
  "userGroup": [

  ]
},
{
  "id": "5b017cfd-31be-4380-9a13-9620713feb65",
  "instanceId": 4230,
  "authEntityId": 4230,
  "displayName": "4230",
  "authEntityClass": -1909073334,
  "deployPending": "NONE",
  "instanceVersion": 0,
  "createTime": 1517966741861,
  "deployed": false,
  "isSeeded": true,
  "isStale": false,
  "lastUpdateTime": 1517966741861,
  "name": "cisco-collab-control",
  "namespace": "scalablegroup:application",
  "provisioningState": "DEFINED",
  "qualifier": "application",
  "resourceVersion": 0,
  "targetIdList": [

  ],
  "type": "scalablegroup",
  "cfsChangeInfo": [

  ],
  "customProvisions": [

  ],
  "scalableGroupExternalHandle": "cisco-collab-control",
  "scalableGroupType": "APPLICATION",
  "securityGroupTag": 0,
  "state": "ACTIVE",
  "vnAgnostic": true,
  "identitySource": {
    "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
    "instanceId": 10010,
    "authEntityId": 10010,
    "displayName": "10010",
    "authEntityClass": 1738953278,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "state": "INACTIVE",
    "type": "NBAR"
  },
  "indicativeNetworkIdentity": [

  ],
  "networkApplications": [
    {
      "id": "7ed5e94d-4e06-4e33-a36f-718cdbb4e7c4",
      "instanceId": 11185,
      "authEntityId": 11185,
      "displayName": "11185",
      "authEntityClass": -217092956,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "applicationGroup": "cisco-phone-group",
      "applicationType": "DEFAULT",
      "categoryId": "38a8e354-e2fe-4f3c-b1c5-2bbb90fb0d99",
      "createTime": 1517966741861,
      "encrypted": "false",
      "engineId": "13",
      "helpString": "Cisco Collaboration Control messages by various Cisco clients.",
      "ignoreConflict": false,
      "lastUpdateTime": 1517966741861,
      "longDescription": "Cisco Collaboration Control messages by various Cisco clients.",
      "name": "cisco-collab-control",
      "nbarId": "1764",
      "p2pTechnology": "false",
      "popularity": 8,
      "rank": 65535,
      "references": "http://ciscospark.com",
      "selectorId": "1764",
      "subCategory": "68c4f989-5735-4e16-8d1c-6a9d89d5df9c",
      "trafficClass": "SIGNALING",
      "tunnel": "false"
    }
  ],
  "networkIdentity": [

  ],
  "parentScalableGroup": {
    "idRef": "60f1364d-2a02-4063-8ba1-07817abe0612"
  },
  "user": [

  ],
  "userGroup": [

  ]
},
{
  "id": "5b67dd75-0d4d-4a02-a95e-c7ca7b25d717",
  "instanceId": 4608,
  "authEntityId": 4608,
  "displayName": "4608",
  "authEntityClass": -1909073334,
  "deployPending": "NONE",
  "instanceVersion": 0,
  "createTime": 1517966743110,
  "deployed": false,
  "isSeeded": true,
  "isStale": false,
  "lastUpdateTime": 1517966743110,
  "name": "intecourier",
  "namespace": "scalablegroup:application",
  "provisioningState": "DEFINED",
  "qualifier": "application",
  "resourceVersion": 0,
  "targetIdList": [

  ],
  "type": "scalablegroup",
  "cfsChangeInfo": [

  ],
  "customProvisions": [

  ],
  "scalableGroupExternalHandle": "intecourier",
  "scalableGroupType": "APPLICATION",
  "securityGroupTag": 0,
  "state": "ACTIVE",
  "vnAgnostic": true,
  "identitySource": {
    "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
    "instanceId": 10010,
    "authEntityId": 10010,
    "displayName": "10010",
    "authEntityClass": 1738953278,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "state": "INACTIVE",
    "type": "NBAR"
  },
  "indicativeNetworkIdentity": [
    {
      "id": "3e903b0b-5c84-49b4-90ff-6b8de5e49eec",
      "instanceId": 13536,
      "authEntityId": 13536,
      "displayName": "13536",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "495",
      "protocol": "TCP",
      "upperPort": 0
    },
    {
      "id": "25cfd1ad-3fd8-4421-b61a-ca4c6aa0c3b6",
      "instanceId": 13535,
      "authEntityId": 13535,
      "displayName": "13535",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "495",
      "protocol": "UDP",
      "upperPort": 0
    }
  ],
  "networkApplications": [
    {
      "id": "2390a6d8-ac9f-4562-a60a-7695085ef0e1",
      "instanceId": 11558,
      "authEntityId": 11558,
      "displayName": "11558",
      "authEntityClass": -217092956,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "appProtocol": "tcp/udp",
      "applicationGroup": "other",
      "applicationType": "DEFAULT",
      "categoryId": "6daa3dfd-3526-407a-88f0-97824b3f3a24",
      "createTime": 1517966743110,
      "encrypted": "false",
      "engineId": "3",
      "helpString": "intecourier",
      "ignoreConflict": false,
      "lastUpdateTime": 1517966743110,
      "longDescription": "Registered with IANA on port 495 TCP/UDP",
      "name": "intecourier",
      "nbarId": "409",
      "p2pTechnology": "false",
      "popularity": 1,
      "rank": 65535,
      "references": "http://www.iana.org/assignments/service-names-port-numbers/service-names-port-numbers.xml",
      "selectorId": "495",
      "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
      "trafficClass": "BULK_DATA",
      "tunnel": "false"
    }
  ],
  "networkIdentity": [
    {
      "id": "eeee05e2-b38c-407b-8f47-a75e8e12c4bd",
      "instanceId": 13533,
      "authEntityId": 13533,
      "displayName": "13533",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "495",
      "protocol": "TCP",
      "upperPort": 0
    },
    {
      "id": "10915421-6aec-484c-9dd3-23691b18ebf1",
      "instanceId": 13534,
      "authEntityId": 13534,
      "displayName": "13534",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "495",
      "protocol": "UDP",
      "upperPort": 0
    }
  ],
  "parentScalableGroup": {
    "idRef": "000c15bf-28fe-4fbb-9b69-35e6502721f1"
  },
  "user": [

  ],
  "userGroup": [

  ]
},
{
  "id": "5b9a871f-7ebb-42de-a5b4-1f2b33c28fb4",
  "instanceId": 15203,
  "authEntityId": 15203,
  "displayName": "15203",
  "authEntityClass": -1909073334,
  "deployPending": "NONE",
  "instanceVersion": 0,
  "createTime": 1517966744475,
  "deployed": false,
  "isSeeded": true,
  "isStale": false,
  "lastUpdateTime": 1517966744475,
  "name": "srssend",
  "namespace": "scalablegroup:application",
  "provisioningState": "DEFINED",
  "qualifier": "application",
  "resourceVersion": 0,
  "targetIdList": [

  ],
  "type": "scalablegroup",
  "cfsChangeInfo": [

  ],
  "customProvisions": [

  ],
  "scalableGroupExternalHandle": "srssend",
  "scalableGroupType": "APPLICATION",
  "securityGroupTag": 0,
  "state": "ACTIVE",
  "vnAgnostic": true,
  "identitySource": {
    "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
    "instanceId": 10010,
    "authEntityId": 10010,
    "displayName": "10010",
    "authEntityClass": 1738953278,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "state": "INACTIVE",
    "type": "NBAR"
  },
  "indicativeNetworkIdentity": [
    {
      "id": "be9b7dad-f931-45c4-bf33-9d683e125469",
      "instanceId": 17250,
      "authEntityId": 17250,
      "displayName": "17250",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "362",
      "protocol": "UDP",
      "upperPort": 0
    },
    {
      "id": "ba84d308-d9fb-4516-8665-5c804d2cfd93",
      "instanceId": 17251,
      "authEntityId": 17251,
      "displayName": "17251",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "362",
      "protocol": "TCP",
      "upperPort": 0
    }
  ],
  "networkApplications": [
    {
      "id": "3a73e7d0-067d-4045-a59f-318648cacd81",
      "instanceId": 16146,
      "authEntityId": 16146,
      "displayName": "16146",
      "authEntityClass": -217092956,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "appProtocol": "tcp/udp",
      "applicationGroup": "other",
      "applicationType": "DEFAULT",
      "categoryId": "6daa3dfd-3526-407a-88f0-97824b3f3a24",
      "createTime": 1517966744475,
      "encrypted": "false",
      "engineId": "3",
      "helpString": "srssend",
      "ignoreConflict": false,
      "lastUpdateTime": 1517966744475,
      "longDescription": "Registered with IANA on port 362 TCP/UDP",
      "name": "srssend",
      "nbarId": "278",
      "p2pTechnology": "false",
      "popularity": 1,
      "rank": 65535,
      "references": "http://www.iana.org/assignments/service-names-port-numbers/service-names-port-numbers.xml",
      "selectorId": "362",
      "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
      "trafficClass": "BULK_DATA",
      "tunnel": "false"
    }
  ],
  "networkIdentity": [
    {
      "id": "ddfd70c6-78ba-47b3-a289-eb2e927dc873",
      "instanceId": 17248,
      "authEntityId": 17248,
      "displayName": "17248",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "362",
      "protocol": "UDP",
      "upperPort": 0
    },
    {
      "id": "658a5d65-f69e-4e07-86ea-08ad854a5de3",
      "instanceId": 17249,
      "authEntityId": 17249,
      "displayName": "17249",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "362",
      "protocol": "TCP",
      "upperPort": 0
    }
  ],
  "parentScalableGroup": {
    "idRef": "000c15bf-28fe-4fbb-9b69-35e6502721f1"
  },
  "user": [

  ],
  "userGroup": [

  ]
},
{
  "id": "5bdc4dc1-395d-4510-9384-de65236d48a5",
  "instanceId": 4221,
  "authEntityId": 4221,
  "displayName": "4221",
  "authEntityClass": -1909073334,
  "deployPending": "NONE",
  "instanceVersion": 0,
  "createTime": 1517966741815,
  "deployed": false,
  "isSeeded": true,
  "isStale": false,
  "lastUpdateTime": 1517966741815,
  "name": "cftp",
  "namespace": "scalablegroup:application",
  "provisioningState": "DEFINED",
  "qualifier": "application",
  "resourceVersion": 0,
  "targetIdList": [

  ],
  "type": "scalablegroup",
  "cfsChangeInfo": [

  ],
  "customProvisions": [

  ],
  "scalableGroupExternalHandle": "cftp",
  "scalableGroupType": "APPLICATION",
  "securityGroupTag": 0,
  "state": "ACTIVE",
  "vnAgnostic": true,
  "identitySource": {
    "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
    "instanceId": 10010,
    "authEntityId": 10010,
    "displayName": "10010",
    "authEntityClass": 1738953278,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "state": "INACTIVE",
    "type": "NBAR"
  },
  "indicativeNetworkIdentity": [
    {
      "id": "7064292a-6bf2-4caa-a606-de810653b53f",
      "instanceId": 12484,
      "authEntityId": 12484,
      "displayName": "12484",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipProtocolNumbers": "62",
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "protocol": "CFTP",
      "upperPort": 0
    }
  ],
  "networkApplications": [
    {
      "id": "70b81049-a59c-4e7a-b130-d7c8ad2a37cb",
      "instanceId": 11176,
      "authEntityId": 11176,
      "displayName": "11176",
      "authEntityClass": -217092956,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "appProtocol": "ip",
      "applicationGroup": "other",
      "applicationType": "DEFAULT",
      "categoryId": "6daa3dfd-3526-407a-88f0-97824b3f3a24",
      "createTime": 1517966741815,
      "encrypted": "unassigned",
      "engineId": "1",
      "helpString": "CFTP",
      "ignoreConflict": false,
      "lastUpdateTime": 1517966741815,
      "longDescription": "Clients-Oriented File Transfer Protocol (cFTP) is a client-oriented PHP-based file transfer protocol that allows the user to create a repository to send/receive files with multiple clients. The user can create multiple clients accounts with a very easy to use front end, and upload an unlimited number of files under each account, with the ability to add a title and description to each one.",
      "name": "cftp",
      "nbarId": "816",
      "p2pTechnology": "unassigned",
      "popularity": 2,
      "rank": 65535,
      "references": "http://blog.dreamcss.com/dev-tools/cftp-clients-oriented-file-transfer-protocol-ftp/",
      "selectorId": "62",
      "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
      "trafficClass": "BULK_DATA",
      "tunnel": "unassigned"
    }
  ],
  "networkIdentity": [
    {
      "id": "0484d91d-ca7a-4f21-9dd2-387aa590a143",
      "instanceId": 12483,
      "authEntityId": 12483,
      "displayName": "12483",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipProtocolNumbers": "62",
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "protocol": "CFTP",
      "upperPort": 0
    }
  ],
  "parentScalableGroup": {
    "idRef": "000c15bf-28fe-4fbb-9b69-35e6502721f1"
  },
  "user": [

  ],
  "userGroup": [

  ]
},
{
  "id": "5c7b83b4-8fc0-4755-a518-9999249ed966",
  "instanceId": 4779,
  "authEntityId": 4779,
  "displayName": "4779",
  "authEntityClass": -1909073334,
  "deployPending": "NONE",
  "instanceVersion": 0,
  "createTime": 1517966743475,
  "deployed": false,
  "isSeeded": true,
  "isStale": false,
  "lastUpdateTime": 1517966743475,
  "name": "ms-lync-video",
  "namespace": "scalablegroup:application",
  "provisioningState": "DEFINED",
  "qualifier": "application",
  "resourceVersion": 0,
  "targetIdList": [

  ],
  "type": "scalablegroup",
  "cfsChangeInfo": [

  ],
  "customProvisions": [

  ],
  "scalableGroupExternalHandle": "ms-lync-video",
  "scalableGroupType": "APPLICATION",
  "securityGroupTag": 0,
  "state": "ACTIVE",
  "vnAgnostic": true,
  "identitySource": {
    "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
    "instanceId": 10010,
    "authEntityId": 10010,
    "displayName": "10010",
    "authEntityClass": 1738953278,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "state": "INACTIVE",
    "type": "NBAR"
  },
  "indicativeNetworkIdentity": [

  ],
  "networkApplications": [
    {
      "id": "5e13eb5d-5512-4069-9496-cabd97c777b6",
      "instanceId": 11728,
      "authEntityId": 11728,
      "displayName": "11728",
      "authEntityClass": -217092956,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "appProtocol": "tcp/udp",
      "applicationGroup": "ms-lync-group",
      "applicationType": "DEFAULT",
      "categoryId": "38a8e354-e2fe-4f3c-b1c5-2bbb90fb0d99",
      "createTime": 1517966743475,
      "encrypted": "false",
      "engineId": "13",
      "helpString": "Skype provides cost effective and collaborative tools for businesses",
      "ignoreConflict": false,
      "lastUpdateTime": 1517966743475,
      "longDescription": "Skype for Business (formerly Microsoft Lync) is a communications and                            collaboration platform that brings together an experience inspired by                            Skype with enterprise-grade security, compliance and control. It offers                            features including presence, IM, voice and video calls, and online meetings.                            Since it is built right in to Microsoft Office, initiating chats,                            calls and meetings is an integrated experience within Office.                           This protocol classifies the video traffic of this app.",
      "name": "ms-lync-video",
      "nbarId": "1497",
      "p2pTechnology": "false",
      "popularity": 10,
      "rank": 65535,
      "references": "http://www.skype.com/en/business/",
      "selectorId": "560",
      "subCategory": "05178016-4555-4291-ab4e-43c2ab2a931a",
      "trafficClass": "MULTIMEDIA_CONFERENCING",
      "tunnel": "false"
    }
  ],
  "networkIdentity": [
    {
      "id": "73778e9d-014f-487d-bb58-d260b7b9522c",
      "instanceId": 13985,
      "authEntityId": 13985,
      "displayName": "13985",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "3478",
      "protocol": "TCP",
      "upperPort": 0
    },
    {
      "id": "8e3f2b2a-a0ad-45c6-a68d-811cc9530057",
      "instanceId": 13986,
      "authEntityId": 13986,
      "displayName": "13986",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "3478",
      "protocol": "UDP",
      "upperPort": 0
    }
  ],
  "parentScalableGroup": {
    "idRef": "60f1364d-2a02-4063-8ba1-07817abe0612"
  },
  "user": [

  ],
  "userGroup": [

  ]
},
{
  "id": "5ca33f96-22ec-47a1-8ce4-db995aef9b54",
  "instanceId": 4885,
  "authEntityId": 4885,
  "displayName": "4885",
  "authEntityClass": -1909073334,
  "deployPending": "NONE",
  "instanceVersion": 0,
  "createTime": 1517966743681,
  "deployed": false,
  "isSeeded": true,
  "isStale": false,
  "lastUpdateTime": 1517966743681,
  "name": "nsrmp",
  "namespace": "scalablegroup:application",
  "provisioningState": "DEFINED",
  "qualifier": "application",
  "resourceVersion": 0,
  "targetIdList": [

  ],
  "type": "scalablegroup",
  "cfsChangeInfo": [

  ],
  "customProvisions": [

  ],
  "scalableGroupExternalHandle": "nsrmp",
  "scalableGroupType": "APPLICATION",
  "securityGroupTag": 0,
  "state": "ACTIVE",
  "vnAgnostic": true,
  "identitySource": {
    "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
    "instanceId": 10010,
    "authEntityId": 10010,
    "displayName": "10010",
    "authEntityClass": 1738953278,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "state": "INACTIVE",
    "type": "NBAR"
  },
  "indicativeNetworkIdentity": [
    {
      "id": "f75efd00-326d-4923-999d-adbc7be57067",
      "instanceId": 14304,
      "authEntityId": 14304,
      "displayName": "14304",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "359",
      "protocol": "TCP",
      "upperPort": 0
    },
    {
      "id": "97ce1923-3742-443d-b278-beaf6995421e",
      "instanceId": 14305,
      "authEntityId": 14305,
      "displayName": "14305",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "359",
      "protocol": "UDP",
      "upperPort": 0
    }
  ],
  "networkApplications": [
    {
      "id": "9cb0075c-dc2b-4233-b76e-a5209f8bca40",
      "instanceId": 11834,
      "authEntityId": 11834,
      "displayName": "11834",
      "authEntityClass": -217092956,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "appProtocol": "tcp/udp",
      "applicationGroup": "other",
      "applicationType": "DEFAULT",
      "categoryId": "1fc17362-9334-4c7b-b752-5afdbb574017",
      "createTime": 1517966743681,
      "encrypted": "false",
      "engineId": "3",
      "helpString": "Network Security Risk Management Protocol",
      "ignoreConflict": false,
      "lastUpdateTime": 1517966743681,
      "longDescription": "Registered with IANA on port 359 TCP/UDP",
      "name": "nsrmp",
      "nbarId": "275",
      "p2pTechnology": "false",
      "popularity": 1,
      "rank": 65535,
      "references": "http://www.iana.org/assignments/service-names-port-numbers/service-names-port-numbers.xml",
      "selectorId": "359",
      "subCategory": "f3c0f761-d894-4112-ab89-b90fc84fd660",
      "trafficClass": "OPS_ADMIN_MGMT",
      "tunnel": "false"
    }
  ],
  "networkIdentity": [
    {
      "id": "64e7e32c-2dd4-4540-a9ab-35ef881c17d5",
      "instanceId": 14302,
      "authEntityId": 14302,
      "displayName": "14302",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "359",
      "protocol": "TCP",
      "upperPort": 0
    },
    {
      "id": "41b40477-feb1-4eea-b6cd-d0d0c215561c",
      "instanceId": 14303,
      "authEntityId": 14303,
      "displayName": "14303",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "359",
      "protocol": "UDP",
      "upperPort": 0
    }
  ],
  "parentScalableGroup": {
    "idRef": "5fcd74d4-8726-47f7-a088-c457863da7ef"
  },
  "user": [

  ],
  "userGroup": [

  ]
},
{
  "id": "5cca2a1d-4276-444d-a842-f06a6742e7bc",
  "instanceId": 15020,
  "authEntityId": 15020,
  "displayName": "15020",
  "authEntityClass": -1909073334,
  "deployPending": "NONE",
  "instanceVersion": 0,
  "createTime": 1517966744043,
  "deployed": false,
  "isSeeded": true,
  "isStale": false,
  "lastUpdateTime": 1517966744043,
  "name": "qq-services",
  "namespace": "scalablegroup:application",
  "provisioningState": "DEFINED",
  "qualifier": "application",
  "resourceVersion": 0,
  "targetIdList": [

  ],
  "type": "scalablegroup",
  "cfsChangeInfo": [

  ],
  "customProvisions": [

  ],
  "scalableGroupExternalHandle": "qq-services",
  "scalableGroupType": "APPLICATION",
  "securityGroupTag": 0,
  "state": "ACTIVE",
  "vnAgnostic": true,
  "identitySource": {
    "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
    "instanceId": 10010,
    "authEntityId": 10010,
    "displayName": "10010",
    "authEntityClass": 1738953278,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "state": "INACTIVE",
    "type": "NBAR"
  },
  "indicativeNetworkIdentity": [

  ],
  "networkApplications": [
    {
      "id": "8bf8ce49-2e27-4fd0-8ede-de5aa1b0bb69",
      "instanceId": 11959,
      "authEntityId": 11959,
      "displayName": "11959",
      "authEntityClass": -217092956,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "appProtocol": "tcp/udp",
      "applicationGroup": "qq-group",
      "applicationType": "DEFAULT",
      "categoryId": "82a7b7fa-da5a-48f5-8b6a-a2546b947843",
      "createTime": 1517966744043,
      "encrypted": "false",
      "engineId": "13",
      "helpString": "Set of tools and APIs used by QQ applications",
      "ignoreConflict": false,
      "lastUpdateTime": 1517966744043,
      "longDescription": "qq-services is a set of tools and APIs used by QQReader, QQwebsite, and other QQ applications.",
      "name": "qq-services",
      "nbarId": "1540",
      "p2pTechnology": "false",
      "popularity": 8,
      "rank": 65535,
      "references": "http://www.qq.com/",
      "selectorId": "601",
      "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
      "trafficClass": "BULK_DATA",
      "tunnel": "false"
    }
  ],
  "networkIdentity": [
    {
      "id": "b1010997-06f5-474c-b754-a04a37ce51b4",
      "instanceId": 14689,
      "authEntityId": 14689,
      "displayName": "14689",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "53,80,5353",
      "protocol": "TCP",
      "upperPort": 0
    },
    {
      "id": "bf4527f6-98dd-4733-82f5-afdaa894bfda",
      "instanceId": 14690,
      "authEntityId": 14690,
      "displayName": "14690",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "53,5353",
      "protocol": "UDP",
      "upperPort": 0
    }
  ],
  "parentScalableGroup": {
    "idRef": "fd6264c9-c16a-4311-a335-a3db039256f3"
  },
  "user": [

  ],
  "userGroup": [

  ]
},
{
  "id": "5cd68cb2-b792-4a25-b86d-f7eca291919e",
  "instanceId": 15172,
  "authEntityId": 15172,
  "displayName": "15172",
  "authEntityClass": -1909073334,
  "deployPending": "NONE",
  "instanceVersion": 0,
  "createTime": 1517966744399,
  "deployed": false,
  "isSeeded": true,
  "isStale": false,
  "lastUpdateTime": 1517966744399,
  "name": "snmp",
  "namespace": "scalablegroup:application",
  "provisioningState": "DEFINED",
  "qualifier": "application",
  "resourceVersion": 0,
  "targetIdList": [

  ],
  "type": "scalablegroup",
  "cfsChangeInfo": [

  ],
  "customProvisions": [

  ],
  "scalableGroupExternalHandle": "snmp",
  "scalableGroupType": "APPLICATION",
  "securityGroupTag": 0,
  "state": "ACTIVE",
  "vnAgnostic": true,
  "identitySource": {
    "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
    "instanceId": 10010,
    "authEntityId": 10010,
    "displayName": "10010",
    "authEntityClass": 1738953278,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "state": "INACTIVE",
    "type": "NBAR"
  },
  "indicativeNetworkIdentity": [
    {
      "id": "dda09141-c589-4a41-9fe3-8ed4d33d057d",
      "instanceId": 17168,
      "authEntityId": 17168,
      "displayName": "17168",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "161,162",
      "protocol": "UDP",
      "upperPort": 0
    },
    {
      "id": "ef7c8fc2-16ed-4890-ac26-0fb3931fd860",
      "instanceId": 17167,
      "authEntityId": 17167,
      "displayName": "17167",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "161,162,1161,1162",
      "protocol": "TCP",
      "upperPort": 0
    }
  ],
  "networkApplications": [
    {
      "id": "2b228ce5-7344-4c02-bbfe-c4508b4d6a79",
      "instanceId": 16115,
      "authEntityId": 16115,
      "displayName": "16115",
      "authEntityClass": -217092956,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "appProtocol": "tcp/udp",
      "applicationGroup": "snmp-group",
      "applicationType": "DEFAULT",
      "categoryId": "1fc17362-9334-4c7b-b752-5afdbb574017",
      "createTime": 1517966744399,
      "encrypted": "false",
      "engineId": "3",
      "helpString": "Simple Network Messaging Protocol",
      "ignoreConflict": false,
      "lastUpdateTime": 1517966744399,
      "longDescription": "Simple Network Management Protocol (SNMP) is a protocol used for a TCP/IP network management. It collects data about the nework enteties and distributes it among them.  Typically the protocol uses TCP/UDP ports 161-162.",
      "name": "snmp",
      "nbarId": "38",
      "p2pTechnology": "false",
      "popularity": 9,
      "rank": 65535,
      "references": "http://www.ietf.org/rfc/rfc1157.txt",
      "selectorId": "161",
      "subCategory": "f3c0f761-d894-4112-ab89-b90fc84fd660",
      "trafficClass": "OPS_ADMIN_MGMT",
      "tunnel": "false"
    }
  ],
  "networkIdentity": [
    {
      "id": "59055c89-0ec3-4232-8d0a-2f03d59b9402",
      "instanceId": 17165,
      "authEntityId": 17165,
      "displayName": "17165",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "161,162",
      "protocol": "UDP",
      "upperPort": 0
    },
    {
      "id": "95be345f-5790-4092-bbdc-28ffba5ef701",
      "instanceId": 17166,
      "authEntityId": 17166,
      "displayName": "17166",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "161,162,1161,1162",
      "protocol": "TCP",
      "upperPort": 0
    }
  ],
  "parentScalableGroup": {
    "idRef": "5fcd74d4-8726-47f7-a088-c457863da7ef"
  },
  "user": [

  ],
  "userGroup": [

  ]
},
{
  "id": "5cfbbdc1-89be-444c-a820-69964df09bc5",
  "instanceId": 15436,
  "authEntityId": 15436,
  "displayName": "15436",
  "authEntityClass": -1909073334,
  "deployPending": "NONE",
  "instanceVersion": 0,
  "createTime": 1517966744962,
  "deployed": false,
  "isSeeded": true,
  "isStale": false,
  "lastUpdateTime": 1517966744962,
  "name": "xfer",
  "namespace": "scalablegroup:application",
  "provisioningState": "DEFINED",
  "qualifier": "application",
  "resourceVersion": 0,
  "targetIdList": [

  ],
  "type": "scalablegroup",
  "cfsChangeInfo": [

  ],
  "customProvisions": [

  ],
  "scalableGroupExternalHandle": "xfer",
  "scalableGroupType": "APPLICATION",
  "securityGroupTag": 0,
  "state": "ACTIVE",
  "vnAgnostic": true,
  "identitySource": {
    "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
    "instanceId": 10010,
    "authEntityId": 10010,
    "displayName": "10010",
    "authEntityClass": 1738953278,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "state": "INACTIVE",
    "type": "NBAR"
  },
  "indicativeNetworkIdentity": [
    {
      "id": "6f9429a3-0a07-4abb-8c1d-305ac2b25b44",
      "instanceId": 17893,
      "authEntityId": 17893,
      "displayName": "17893",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "82",
      "protocol": "TCP",
      "upperPort": 0
    },
    {
      "id": "68b68e79-fdb5-495e-89f0-15113ef23f23",
      "instanceId": 17894,
      "authEntityId": 17894,
      "displayName": "17894",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "82",
      "protocol": "UDP",
      "upperPort": 0
    }
  ],
  "networkApplications": [
    {
      "id": "ec1d1269-d932-4088-9f4c-8a79da204ba4",
      "instanceId": 16379,
      "authEntityId": 16379,
      "displayName": "16379",
      "authEntityClass": -217092956,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "appProtocol": "tcp/udp",
      "applicationGroup": "other",
      "applicationType": "DEFAULT",
      "categoryId": "6daa3dfd-3526-407a-88f0-97824b3f3a24",
      "createTime": 1517966744962,
      "encrypted": "false",
      "engineId": "3",
      "helpString": "XFER Utility",
      "ignoreConflict": false,
      "lastUpdateTime": 1517966744962,
      "longDescription": "The Xfer utility is used by domain name servers (DNS) to perform zone transfers.",
      "name": "xfer",
      "nbarId": "955",
      "p2pTechnology": "false",
      "popularity": 1,
      "rank": 65535,
      "references": "http://docs.oracle.com/cd/E19048-01/chorus5/806-7012/6jftqmea6/index.html",
      "selectorId": "82",
      "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
      "trafficClass": "BULK_DATA",
      "tunnel": "false"
    }
  ],
  "networkIdentity": [
    {
      "id": "35beaffe-98d3-4bdf-b871-ea58b2d402fd",
      "instanceId": 17891,
      "authEntityId": 17891,
      "displayName": "17891",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "82",
      "protocol": "TCP",
      "upperPort": 0
    },
    {
      "id": "7f5722f3-f001-44eb-a532-d9f1f46aa000",
      "instanceId": 17892,
      "authEntityId": 17892,
      "displayName": "17892",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "82",
      "protocol": "UDP",
      "upperPort": 0
    }
  ],
  "parentScalableGroup": {
    "idRef": "000c15bf-28fe-4fbb-9b69-35e6502721f1"
  },
  "user": [

  ],
  "userGroup": [

  ]
},
{
  "id": "5d4005ef-ffb0-4878-b04c-2f5c81d28f28",
  "instanceId": 4801,
  "authEntityId": 4801,
  "displayName": "4801",
  "authEntityClass": -1909073334,
  "deployPending": "NONE",
  "instanceVersion": 0,
  "createTime": 1517966743520,
  "deployed": false,
  "isSeeded": true,
  "isStale": false,
  "lastUpdateTime": 1517966743520,
  "name": "msn-messenger",
  "namespace": "scalablegroup:application",
  "provisioningState": "DEFINED",
  "qualifier": "application",
  "resourceVersion": 0,
  "targetIdList": [

  ],
  "type": "scalablegroup",
  "cfsChangeInfo": [

  ],
  "customProvisions": [

  ],
  "scalableGroupExternalHandle": "msn-messenger",
  "scalableGroupType": "APPLICATION",
  "securityGroupTag": 0,
  "state": "ACTIVE",
  "vnAgnostic": true,
  "identitySource": {
    "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
    "instanceId": 10010,
    "authEntityId": 10010,
    "displayName": "10010",
    "authEntityClass": 1738953278,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "state": "INACTIVE",
    "type": "NBAR"
  },
  "indicativeNetworkIdentity": [

  ],
  "networkApplications": [
    {
      "id": "301b2313-7c48-414e-bf7d-6978fd2355eb",
      "instanceId": 11750,
      "authEntityId": 11750,
      "displayName": "11750",
      "authEntityClass": -217092956,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "applicationGroup": "msn-messenger-group",
      "applicationType": "DEFAULT",
      "categoryId": "0f6cab47-be95-4124-9db3-360ccd55f983",
      "createTime": 1517966743520,
      "encrypted": "true",
      "engineId": "13",
      "helpString": "DEPRECATED, traffic will not match",
      "ignoreConflict": false,
      "lastUpdateTime": 1517966743520,
      "longDescription": "MSN-Messenger (recently renamed to Windows Live Messenger) software is used to connect clients to each other. The clients can chat and make audio and video calls with one-another. The software uses multiple underlying protocols: HTTP, SOCKS and MS-WBT. Windows live messenger was replaced by Skype except for China",
      "name": "msn-messenger",
      "nbarId": "75",
      "p2pTechnology": "false",
      "popularity": 6,
      "rank": 65535,
      "references": "http://explore.live.com/messenger",
      "selectorId": "75",
      "subCategory": "118c10a2-e272-47c1-92b9-4814ebe97138",
      "trafficClass": "MULTIMEDIA_CONFERENCING",
      "tunnel": "false"
    }
  ],
  "networkIdentity": [

  ],
  "parentScalableGroup": {
    "idRef": "fd6264c9-c16a-4311-a335-a3db039256f3"
  },
  "user": [

  ],
  "userGroup": [

  ]
},
{
  "id": "5d41b378-2ec2-4538-aaed-5df5708b27b4",
  "instanceId": 15432,
  "authEntityId": 15432,
  "displayName": "15432",
  "authEntityClass": -1909073334,
  "deployPending": "NONE",
  "instanceVersion": 0,
  "createTime": 1517966744955,
  "deployed": false,
  "isSeeded": true,
  "isStale": false,
  "lastUpdateTime": 1517966744955,
  "name": "xbox-web-portal",
  "namespace": "scalablegroup:application",
  "provisioningState": "DEFINED",
  "qualifier": "application",
  "resourceVersion": 0,
  "targetIdList": [

  ],
  "type": "scalablegroup",
  "cfsChangeInfo": [

  ],
  "customProvisions": [

  ],
  "scalableGroupExternalHandle": "xbox-web-portal",
  "scalableGroupType": "APPLICATION",
  "securityGroupTag": 0,
  "state": "ACTIVE",
  "vnAgnostic": true,
  "identitySource": {
    "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
    "instanceId": 10010,
    "authEntityId": 10010,
    "displayName": "10010",
    "authEntityClass": 1738953278,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "state": "INACTIVE",
    "type": "NBAR"
  },
  "indicativeNetworkIdentity": [

  ],
  "networkApplications": [
    {
      "id": "7cb0a57c-fe79-4e7b-a5a4-2f34991d2822",
      "instanceId": 16375,
      "authEntityId": 16375,
      "displayName": "16375",
      "authEntityClass": -217092956,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "appProtocol": "tcp/udp",
      "applicationGroup": "other",
      "applicationType": "DEFAULT",
      "categoryId": "878644b5-fe91-45b4-9204-31c84a73ba48",
      "createTime": 1517966744955,
      "encrypted": "false",
      "engineId": "13",
      "helpString": "Xbox Live - Microsoft online gaming service.",
      "ignoreConflict": false,
      "lastUpdateTime": 1517966744955,
      "longDescription": "Xbox Live - Microsoft online gaming service.",
      "name": "xbox-web-portal",
      "nbarId": "1707",
      "p2pTechnology": "false",
      "popularity": 3,
      "rank": 65535,
      "references": "http://xbox.com",
      "selectorId": "779",
      "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
      "trafficClass": "TRANSACTIONAL_DATA",
      "tunnel": "false"
    }
  ],
  "networkIdentity": [
    {
      "id": "47565ede-10af-48ba-8f42-b9fc90c77427",
      "instanceId": 17879,
      "authEntityId": 17879,
      "displayName": "17879",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "53,80,443,5353",
      "protocol": "TCP",
      "upperPort": 0
    },
    {
      "id": "28382fa0-4e99-4e85-9845-e1cb6a7e369b",
      "instanceId": 17880,
      "authEntityId": 17880,
      "displayName": "17880",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "53,5353",
      "protocol": "UDP",
      "upperPort": 0
    }
  ],
  "parentScalableGroup": {
    "idRef": "28e08de4-d7b2-4521-84ce-bc1e5ee0b9cc"
  },
  "user": [

  ],
  "userGroup": [

  ]
},
{
  "id": "5d4fc523-4b43-4915-a251-a8f6fc099bb1",
  "instanceId": 4170,
  "authEntityId": 4170,
  "displayName": "4170",
  "authEntityClass": -1909073334,
  "deployPending": "NONE",
  "instanceVersion": 0,
  "createTime": 1517966741510,
  "deployed": false,
  "isSeeded": true,
  "isStale": false,
  "lastUpdateTime": 1517966741510,
  "name": "bdp",
  "namespace": "scalablegroup:application",
  "provisioningState": "DEFINED",
  "qualifier": "application",
  "resourceVersion": 0,
  "targetIdList": [

  ],
  "type": "scalablegroup",
  "cfsChangeInfo": [

  ],
  "customProvisions": [

  ],
  "scalableGroupExternalHandle": "bdp",
  "scalableGroupType": "APPLICATION",
  "securityGroupTag": 0,
  "state": "ACTIVE",
  "vnAgnostic": true,
  "identitySource": {
    "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
    "instanceId": 10010,
    "authEntityId": 10010,
    "displayName": "10010",
    "authEntityClass": 1738953278,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "state": "INACTIVE",
    "type": "NBAR"
  },
  "indicativeNetworkIdentity": [
    {
      "id": "9c71d5eb-8b20-4a26-9a9d-c702c0ebcfb7",
      "instanceId": 12356,
      "authEntityId": 12356,
      "displayName": "12356",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "581",
      "protocol": "TCP",
      "upperPort": 0
    },
    {
      "id": "b65c698a-aad2-4dd5-9309-9e783ed172cb",
      "instanceId": 12357,
      "authEntityId": 12357,
      "displayName": "12357",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "581",
      "protocol": "UDP",
      "upperPort": 0
    }
  ],
  "networkApplications": [
    {
      "id": "301eed7d-5233-4a78-8053-98ec1d439c30",
      "instanceId": 11129,
      "authEntityId": 11129,
      "displayName": "11129",
      "authEntityClass": -217092956,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "appProtocol": "tcp/udp",
      "applicationGroup": "other",
      "applicationType": "DEFAULT",
      "categoryId": "6daa3dfd-3526-407a-88f0-97824b3f3a24",
      "createTime": 1517966741510,
      "encrypted": "false",
      "engineId": "3",
      "helpString": "Bundle Discovery protocol",
      "ignoreConflict": false,
      "lastUpdateTime": 1517966741510,
      "longDescription": "The Bundle Discovery Protocol is used to determine where the Bundle Head is in a Multi-link PPP (MP) Link Control Protocol (LCP) phase. When a user dials into a Remote Access Server (RAS) and negotiates an MP connection in the LCP phase, the RAS must determine if a Bundle Head exists, and if not create it.",
      "name": "bdp",
      "nbarId": "495",
      "p2pTechnology": "false",
      "popularity": 1,
      "rank": 65535,
      "references": "http://tools.ietf.org/html/rfc2701",
      "selectorId": "581",
      "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
      "trafficClass": "BULK_DATA",
      "tunnel": "false"
    }
  ],
  "networkIdentity": [
    {
      "id": "f01cb869-59a4-4830-b898-22fb37c28926",
      "instanceId": 12354,
      "authEntityId": 12354,
      "displayName": "12354",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "581",
      "protocol": "TCP",
      "upperPort": 0
    },
    {
      "id": "79aa8c1c-4c9d-4e50-9113-4299e6477408",
      "instanceId": 12355,
      "authEntityId": 12355,
      "displayName": "12355",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "581",
      "protocol": "UDP",
      "upperPort": 0
    }
  ],
  "parentScalableGroup": {
    "idRef": "000c15bf-28fe-4fbb-9b69-35e6502721f1"
  },
  "user": [

  ],
  "userGroup": [

  ]
},
{
  "id": "5db0e3c0-9d94-427f-98d3-60849c8b1e00",
  "instanceId": 5004,
  "authEntityId": 5004,
  "displayName": "5004",
  "authEntityClass": -1909073334,
  "deployPending": "NONE",
  "instanceVersion": 0,
  "createTime": 1517966744028,
  "deployed": false,
  "isSeeded": true,
  "isStale": false,
  "lastUpdateTime": 1517966744028,
  "name": "qmtp",
  "namespace": "scalablegroup:application",
  "provisioningState": "DEFINED",
  "qualifier": "application",
  "resourceVersion": 0,
  "targetIdList": [

  ],
  "type": "scalablegroup",
  "cfsChangeInfo": [

  ],
  "customProvisions": [

  ],
  "scalableGroupExternalHandle": "qmtp",
  "scalableGroupType": "APPLICATION",
  "securityGroupTag": 0,
  "state": "ACTIVE",
  "vnAgnostic": true,
  "identitySource": {
    "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
    "instanceId": 10010,
    "authEntityId": 10010,
    "displayName": "10010",
    "authEntityClass": 1738953278,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "state": "INACTIVE",
    "type": "NBAR"
  },
  "indicativeNetworkIdentity": [
    {
      "id": "73e8bfcd-231c-4801-a8e7-42b8ab8edaf1",
      "instanceId": 14674,
      "authEntityId": 14674,
      "displayName": "14674",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "209",
      "protocol": "UDP",
      "upperPort": 0
    },
    {
      "id": "6ca1e333-ed2a-41e9-b3c9-582c10a65668",
      "instanceId": 14675,
      "authEntityId": 14675,
      "displayName": "14675",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "209",
      "protocol": "TCP",
      "upperPort": 0
    }
  ],
  "networkApplications": [
    {
      "id": "307d05e0-bf44-4293-906c-b233875a1062",
      "instanceId": 11953,
      "authEntityId": 11953,
      "displayName": "11953",
      "authEntityClass": -217092956,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "appProtocol": "tcp/udp",
      "applicationGroup": "other",
      "applicationType": "DEFAULT",
      "categoryId": "27953c60-b2ff-4e1a-bc52-0187d8295250",
      "createTime": 1517966744028,
      "encrypted": "false",
      "engineId": "3",
      "helpString": "The Quick Mail Transfer Protocol",
      "ignoreConflict": false,
      "lastUpdateTime": 1517966744028,
      "longDescription": "The Quick Mail Transfer Protocol (QMTP) is an e-mail transmission protocol that is designed to have better performance than Simple Mail Transfer Protocol (SMTP), the de facto standard. It was designed and implemented in qmail.",
      "name": "qmtp",
      "nbarId": "1107",
      "p2pTechnology": "false",
      "popularity": 1,
      "rank": 65535,
      "references": "http://cr.yp.to/proto/qmtp.txt",
      "selectorId": "209",
      "subCategory": "b90786f4-0190-45b8-9924-21b3e9682590",
      "trafficClass": "BULK_DATA",
      "tunnel": "false"
    }
  ],
  "networkIdentity": [
    {
      "id": "ffdb1d7d-eeed-43e3-983c-dfe8662d7d63",
      "instanceId": 14672,
      "authEntityId": 14672,
      "displayName": "14672",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "209",
      "protocol": "TCP",
      "upperPort": 0
    },
    {
      "id": "f2236330-b1f1-4890-9256-2dfbb5dd8dd4",
      "instanceId": 14673,
      "authEntityId": 14673,
      "displayName": "14673",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "209",
      "protocol": "UDP",
      "upperPort": 0
    }
  ],
  "parentScalableGroup": {
    "idRef": "8a7ca43a-32bd-40d2-871a-02220a165cb7"
  },
  "user": [

  ],
  "userGroup": [

  ]
},
{
  "id": "5dc5c834-99cf-49f4-b20e-657536cedfb3",
  "instanceId": 4291,
  "authEntityId": 4291,
  "displayName": "4291",
  "authEntityClass": -1909073334,
  "deployPending": "NONE",
  "instanceVersion": 0,
  "createTime": 1517966742173,
  "deployed": false,
  "isSeeded": true,
  "isStale": false,
  "lastUpdateTime": 1517966742173,
  "name": "corerjd",
  "namespace": "scalablegroup:application",
  "provisioningState": "DEFINED",
  "qualifier": "application",
  "resourceVersion": 0,
  "targetIdList": [

  ],
  "type": "scalablegroup",
  "cfsChangeInfo": [

  ],
  "customProvisions": [

  ],
  "scalableGroupExternalHandle": "corerjd",
  "scalableGroupType": "APPLICATION",
  "securityGroupTag": 0,
  "state": "ACTIVE",
  "vnAgnostic": true,
  "identitySource": {
    "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
    "instanceId": 10010,
    "authEntityId": 10010,
    "displayName": "10010",
    "authEntityClass": 1738953278,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "state": "INACTIVE",
    "type": "NBAR"
  },
  "indicativeNetworkIdentity": [
    {
      "id": "ef1e45ba-0f9b-4cf9-b1a7-d3edd4fbc3ee",
      "instanceId": 12647,
      "authEntityId": 12647,
      "displayName": "12647",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "284",
      "protocol": "UDP",
      "upperPort": 0
    },
    {
      "id": "236ec236-8803-47b4-a615-9df03d7988d3",
      "instanceId": 12648,
      "authEntityId": 12648,
      "displayName": "12648",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "284",
      "protocol": "TCP",
      "upperPort": 0
    }
  ],
  "networkApplications": [
    {
      "id": "cd2efa8d-50cb-489f-81c6-7c549a3c1b82",
      "instanceId": 11244,
      "authEntityId": 11244,
      "displayName": "11244",
      "authEntityClass": -217092956,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "appProtocol": "tcp/udp",
      "applicationGroup": "other",
      "applicationType": "DEFAULT",
      "categoryId": "6daa3dfd-3526-407a-88f0-97824b3f3a24",
      "createTime": 1517966742173,
      "encrypted": "false",
      "engineId": "3",
      "helpString": "corerjd",
      "ignoreConflict": false,
      "lastUpdateTime": 1517966742173,
      "longDescription": "Registered with IANA on port 284 TCP/UDP",
      "name": "corerjd",
      "nbarId": "1145",
      "p2pTechnology": "false",
      "popularity": 1,
      "rank": 65535,
      "references": "http://www.iana.org/assignments/service-names-port-numbers/service-names-port-numbers.xml",
      "selectorId": "284",
      "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
      "trafficClass": "BULK_DATA",
      "tunnel": "false"
    }
  ],
  "networkIdentity": [
    {
      "id": "847ac6d2-0e29-4518-b9ba-f2a2e79586ef",
      "instanceId": 12645,
      "authEntityId": 12645,
      "displayName": "12645",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "284",
      "protocol": "UDP",
      "upperPort": 0
    },
    {
      "id": "ce5f7fb5-6fa4-46a0-bc0a-646265cb4cbe",
      "instanceId": 12646,
      "authEntityId": 12646,
      "displayName": "12646",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "284",
      "protocol": "TCP",
      "upperPort": 0
    }
  ],
  "parentScalableGroup": {
    "idRef": "000c15bf-28fe-4fbb-9b69-35e6502721f1"
  },
  "user": [

  ],
  "userGroup": [

  ]
},
{
  "id": "5dfeead9-c631-4801-bb21-b2e29a967cba",
  "instanceId": 4858,
  "authEntityId": 4858,
  "displayName": "4858",
  "authEntityClass": -1909073334,
  "deployPending": "NONE",
  "instanceVersion": 0,
  "createTime": 1517966743628,
  "deployed": false,
  "isSeeded": true,
  "isStale": false,
  "lastUpdateTime": 1517966743628,
  "name": "networking-gnutella",
  "namespace": "scalablegroup:application",
  "provisioningState": "DEFINED",
  "qualifier": "application",
  "resourceVersion": 0,
  "targetIdList": [

  ],
  "type": "scalablegroup",
  "cfsChangeInfo": [

  ],
  "customProvisions": [

  ],
  "scalableGroupExternalHandle": "networking-gnutella",
  "scalableGroupType": "APPLICATION",
  "securityGroupTag": 0,
  "state": "ACTIVE",
  "vnAgnostic": true,
  "identitySource": {
    "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
    "instanceId": 10010,
    "authEntityId": 10010,
    "displayName": "10010",
    "authEntityClass": 1738953278,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "state": "INACTIVE",
    "type": "NBAR"
  },
  "indicativeNetworkIdentity": [
    {
      "id": "5dd0d721-c6e9-4177-b2fd-763e9e420002",
      "instanceId": 14213,
      "authEntityId": 14213,
      "displayName": "14213",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "6346,6347,6348",
      "protocol": "UDP",
      "upperPort": 0
    }
  ],
  "networkApplications": [
    {
      "id": "d9be4aa9-17cb-4220-b342-3c55a4b11d27",
      "instanceId": 11807,
      "authEntityId": 11807,
      "displayName": "11807",
      "authEntityClass": -217092956,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "appProtocol": "udp",
      "applicationGroup": "gnutella-group",
      "applicationType": "DEFAULT",
      "categoryId": "b33b2761-d981-4734-810a-a6a1c18ab352",
      "createTime": 1517966743628,
      "encrypted": "false",
      "engineId": "13",
      "helpString": "Gnutella-networking Version2 Traffic",
      "ignoreConflict": false,
      "lastUpdateTime": 1517966743628,
      "longDescription": "Gnutella-network represents the control traffic for Gnutella, which is a peer to peer application used for file sharing.",
      "name": "networking-gnutella",
      "nbarId": "358",
      "p2pTechnology": "true",
      "popularity": 7,
      "rank": 65535,
      "references": "http://www.gnutellaforums.com/",
      "selectorId": "420",
      "subCategory": "61b315f5-5e87-4de0-bc1a-5d084ea0b28d",
      "trafficClass": "BULK_DATA",
      "tunnel": "false"
    }
  ],
  "networkIdentity": [
    {
      "id": "a31ceb9c-fb2f-4bca-b5b6-057536004514",
      "instanceId": 14212,
      "authEntityId": 14212,
      "displayName": "14212",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "6346,6347,6348",
      "protocol": "UDP",
      "upperPort": 0
    }
  ],
  "parentScalableGroup": {
    "idRef": "7a1a3bbb-dc84-4a35-82a4-7e650c4c4761"
  },
  "user": [

  ],
  "userGroup": [

  ]
},
{
  "id": "5e3bc91c-6d69-4683-9a1f-3388184561ec",
  "instanceId": 15024,
  "authEntityId": 15024,
  "displayName": "15024",
  "authEntityClass": -1909073334,
  "deployPending": "NONE",
  "instanceVersion": 0,
  "createTime": 1517966744052,
  "deployed": false,
  "isSeeded": true,
  "isStale": false,
  "lastUpdateTime": 1517966744052,
  "name": "quotad",
  "namespace": "scalablegroup:application",
  "provisioningState": "DEFINED",
  "qualifier": "application",
  "resourceVersion": 0,
  "targetIdList": [

  ],
  "type": "scalablegroup",
  "cfsChangeInfo": [

  ],
  "customProvisions": [

  ],
  "scalableGroupExternalHandle": "quotad",
  "scalableGroupType": "APPLICATION",
  "securityGroupTag": 0,
  "state": "ACTIVE",
  "vnAgnostic": true,
  "identitySource": {
    "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
    "instanceId": 10010,
    "authEntityId": 10010,
    "displayName": "10010",
    "authEntityClass": 1738953278,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "state": "INACTIVE",
    "type": "NBAR"
  },
  "indicativeNetworkIdentity": [
    {
      "id": "b2e00613-6c1c-48af-8ceb-39154edee25f",
      "instanceId": 14699,
      "authEntityId": 14699,
      "displayName": "14699",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "762",
      "protocol": "TCP",
      "upperPort": 0
    },
    {
      "id": "621ad7d8-089a-431e-8513-2517b6f8cbaa",
      "instanceId": 14700,
      "authEntityId": 14700,
      "displayName": "14700",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "762",
      "protocol": "UDP",
      "upperPort": 0
    }
  ],
  "networkApplications": [
    {
      "id": "598446ac-0428-4d0e-ab35-733404091c6c",
      "instanceId": 11963,
      "authEntityId": 11963,
      "displayName": "11963",
      "authEntityClass": -217092956,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "appProtocol": "tcp/udp",
      "applicationGroup": "other",
      "applicationType": "DEFAULT",
      "categoryId": "1fc17362-9334-4c7b-b752-5afdbb574017",
      "createTime": 1517966744052,
      "encrypted": "false",
      "engineId": "3",
      "helpString": "quotad",
      "ignoreConflict": false,
      "lastUpdateTime": 1517966744052,
      "longDescription": "Registered with IANA on port 762 TCP/UDP",
      "name": "quotad",
      "nbarId": "634",
      "p2pTechnology": "false",
      "popularity": 1,
      "rank": 65535,
      "references": "http://www.iana.org/assignments/service-names-port-numbers/service-names-port-numbers.xml",
      "selectorId": "762",
      "subCategory": "f3c0f761-d894-4112-ab89-b90fc84fd660",
      "trafficClass": "OPS_ADMIN_MGMT",
      "tunnel": "false"
    }
  ],
  "networkIdentity": [
    {
      "id": "be2619b0-3287-45af-9bcc-9644b481d9b4",
      "instanceId": 14697,
      "authEntityId": 14697,
      "displayName": "14697",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "762",
      "protocol": "TCP",
      "upperPort": 0
    },
    {
      "id": "5baf20ad-6b02-4a44-be60-b5f2e1f42798",
      "instanceId": 14698,
      "authEntityId": 14698,
      "displayName": "14698",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "762",
      "protocol": "UDP",
      "upperPort": 0
    }
  ],
  "parentScalableGroup": {
    "idRef": "5fcd74d4-8726-47f7-a088-c457863da7ef"
  },
  "user": [

  ],
  "userGroup": [

  ]
},
{
  "id": "5e4ae645-6eeb-4a25-a558-b25976d099a7",
  "instanceId": 15425,
  "authEntityId": 15425,
  "displayName": "15425",
  "authEntityClass": -1909073334,
  "deployPending": "NONE",
  "instanceVersion": 0,
  "createTime": 1517966744943,
  "deployed": false,
  "isSeeded": true,
  "isStale": false,
  "lastUpdateTime": 1517966744943,
  "name": "worldfusion",
  "namespace": "scalablegroup:application",
  "provisioningState": "DEFINED",
  "qualifier": "application",
  "resourceVersion": 0,
  "targetIdList": [

  ],
  "type": "scalablegroup",
  "cfsChangeInfo": [

  ],
  "customProvisions": [

  ],
  "scalableGroupExternalHandle": "worldfusion",
  "scalableGroupType": "APPLICATION",
  "securityGroupTag": 0,
  "state": "ACTIVE",
  "vnAgnostic": true,
  "identitySource": {
    "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
    "instanceId": 10010,
    "authEntityId": 10010,
    "displayName": "10010",
    "authEntityClass": 1738953278,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "state": "INACTIVE",
    "type": "NBAR"
  },
  "indicativeNetworkIdentity": [
    {
      "id": "049e3f20-5943-4c00-8812-501e15ecb8fd",
      "instanceId": 17859,
      "authEntityId": 17859,
      "displayName": "17859",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "2595",
      "protocol": "UDP",
      "upperPort": 0
    },
    {
      "id": "2c07ad72-28ec-49c8-b818-95df87aa04b4",
      "instanceId": 17860,
      "authEntityId": 17860,
      "displayName": "17860",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "2595",
      "protocol": "TCP",
      "upperPort": 0
    }
  ],
  "networkApplications": [
    {
      "id": "11221a47-396e-43df-a93d-ffb67b07147e",
      "instanceId": 16368,
      "authEntityId": 16368,
      "displayName": "16368",
      "authEntityClass": -217092956,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "appProtocol": "tcp/udp",
      "applicationGroup": "other",
      "applicationType": "DEFAULT",
      "categoryId": "6daa3dfd-3526-407a-88f0-97824b3f3a24",
      "createTime": 1517966744943,
      "encrypted": "false",
      "engineId": "3",
      "helpString": "World Fusion",
      "ignoreConflict": false,
      "lastUpdateTime": 1517966744943,
      "longDescription": "Registered with IANA on port 2595 TCP/UDP",
      "name": "worldfusion",
      "nbarId": "719",
      "p2pTechnology": "false",
      "popularity": 1,
      "rank": 65535,
      "references": "http://www.iana.org/assignments/service-names-port-numbers/service-names-port-numbers.xml",
      "selectorId": "2595",
      "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
      "trafficClass": "BULK_DATA",
      "tunnel": "false"
    }
  ],
  "networkIdentity": [
    {
      "id": "8ded2dbc-40de-4607-8da2-e0b477b48be6",
      "instanceId": 17857,
      "authEntityId": 17857,
      "displayName": "17857",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "2595",
      "protocol": "TCP",
      "upperPort": 0
    },
    {
      "id": "1ca3db2d-00ec-45b9-841b-8623370593b2",
      "instanceId": 17858,
      "authEntityId": 17858,
      "displayName": "17858",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "2595",
      "protocol": "UDP",
      "upperPort": 0
    }
  ],
  "parentScalableGroup": {
    "idRef": "000c15bf-28fe-4fbb-9b69-35e6502721f1"
  },
  "user": [

  ],
  "userGroup": [

  ]
},
{
  "id": "5e628aa9-864f-4c6f-9cfc-415a7c717ffc",
  "instanceId": 15462,
  "authEntityId": 15462,
  "displayName": "15462",
  "authEntityClass": -1909073334,
  "deployPending": "NONE",
  "instanceVersion": 0,
  "createTime": 1517966745015,
  "deployed": false,
  "isSeeded": true,
  "isStale": false,
  "lastUpdateTime": 1517966745015,
  "name": "youku",
  "namespace": "scalablegroup:application",
  "provisioningState": "DEFINED",
  "qualifier": "application",
  "resourceVersion": 0,
  "targetIdList": [

  ],
  "type": "scalablegroup",
  "cfsChangeInfo": [

  ],
  "customProvisions": [

  ],
  "scalableGroupExternalHandle": "youku",
  "scalableGroupType": "APPLICATION",
  "securityGroupTag": 0,
  "state": "ACTIVE",
  "vnAgnostic": true,
  "identitySource": {
    "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
    "instanceId": 10010,
    "authEntityId": 10010,
    "displayName": "10010",
    "authEntityClass": 1738953278,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "state": "INACTIVE",
    "type": "NBAR"
  },
  "indicativeNetworkIdentity": [

  ],
  "networkApplications": [
    {
      "id": "f369abd1-7263-46fb-aa12-27a15941996f",
      "instanceId": 16405,
      "authEntityId": 16405,
      "displayName": "16405",
      "authEntityClass": -217092956,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "appProtocol": "tcp/udp",
      "applicationGroup": "other",
      "applicationType": "DEFAULT",
      "categoryId": "878644b5-fe91-45b4-9204-31c84a73ba48",
      "createTime": 1517966745015,
      "encrypted": "false",
      "engineId": "13",
      "helpString": "Youku - Chinese video hosting and sharing service.",
      "ignoreConflict": false,
      "lastUpdateTime": 1517966745015,
      "longDescription": "Youku - Chinese video hosting and sharing service.",
      "name": "youku",
      "nbarId": "1546",
      "p2pTechnology": "false",
      "popularity": 3,
      "rank": 65535,
      "references": "http://youku.com",
      "selectorId": "623",
      "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
      "trafficClass": "TRANSACTIONAL_DATA",
      "tunnel": "false"
    }
  ],
  "networkIdentity": [
    {
      "id": "f5198175-58bb-4fee-9fde-975f02bd82a1",
      "instanceId": 17960,
      "authEntityId": 17960,
      "displayName": "17960",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "53,5353",
      "protocol": "UDP",
      "upperPort": 0
    },
    {
      "id": "1737e22d-6909-4fbb-ba00-d7c899aba30f",
      "instanceId": 17961,
      "authEntityId": 17961,
      "displayName": "17961",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "53,80,443,5353",
      "protocol": "TCP",
      "upperPort": 0
    }
  ],
  "parentScalableGroup": {
    "idRef": "28e08de4-d7b2-4521-84ce-bc1e5ee0b9cc"
  },
  "user": [

  ],
  "userGroup": [

  ]
},
{
  "id": "5e7c529b-c80b-4f3e-bb97-56fae833c78d",
  "instanceId": 4046,
  "authEntityId": 4046,
  "displayName": "4046",
  "authEntityClass": -1909073334,
  "deployPending": "NONE",
  "instanceVersion": 0,
  "createTime": 1517966740572,
  "deployed": false,
  "isSeeded": true,
  "isStale": false,
  "lastUpdateTime": 1517966740572,
  "name": "abc-news",
  "namespace": "scalablegroup:application",
  "provisioningState": "DEFINED",
  "qualifier": "application",
  "resourceVersion": 0,
  "targetIdList": [

  ],
  "type": "scalablegroup",
  "cfsChangeInfo": [

  ],
  "customProvisions": [

  ],
  "scalableGroupExternalHandle": "abc-news",
  "scalableGroupType": "APPLICATION",
  "securityGroupTag": 0,
  "state": "ACTIVE",
  "vnAgnostic": true,
  "identitySource": {
    "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
    "instanceId": 10010,
    "authEntityId": 10010,
    "displayName": "10010",
    "authEntityClass": 1738953278,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "state": "INACTIVE",
    "type": "NBAR"
  },
  "indicativeNetworkIdentity": [

  ],
  "networkApplications": [
    {
      "id": "cff2d097-22d1-4c09-a822-16bea24b18f4",
      "instanceId": 11020,
      "authEntityId": 11020,
      "displayName": "11020",
      "authEntityClass": -217092956,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "appProtocol": "tcp/udp",
      "applicationGroup": "other",
      "applicationType": "DEFAULT",
      "categoryId": "878644b5-fe91-45b4-9204-31c84a73ba48",
      "createTime": 1517966740571,
      "encrypted": "false",
      "engineId": "13",
      "helpString": "ABC - Web Portal for television network.",
      "ignoreConflict": false,
      "lastUpdateTime": 1517966740571,
      "longDescription": "ABC - Web Portal for television network.",
      "name": "abc-news",
      "nbarId": "1651",
      "p2pTechnology": "false",
      "popularity": 3,
      "rank": 65535,
      "references": "http://abc.com",
      "selectorId": "721",
      "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
      "trafficClass": "TRANSACTIONAL_DATA",
      "tunnel": "false"
    }
  ],
  "networkIdentity": [
    {
      "id": "4771528a-cbc2-43e7-9478-db930f689b06",
      "instanceId": 12042,
      "authEntityId": 12042,
      "displayName": "12042",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "53,5353",
      "protocol": "UDP",
      "upperPort": 0
    },
    {
      "id": "26bd58ef-5a5d-4bd6-8225-0ef64fe8f035",
      "instanceId": 12043,
      "authEntityId": 12043,
      "displayName": "12043",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "53,80,443,5353",
      "protocol": "TCP",
      "upperPort": 0
    }
  ],
  "parentScalableGroup": {
    "idRef": "28e08de4-d7b2-4521-84ce-bc1e5ee0b9cc"
  },
  "user": [

  ],
  "userGroup": [

  ]
},
{
  "id": "5ea05837-3fba-4d0d-9fac-d1570e868aa2",
  "instanceId": 15107,
  "authEntityId": 15107,
  "displayName": "15107",
  "authEntityClass": -1909073334,
  "deployPending": "NONE",
  "instanceVersion": 0,
  "createTime": 1517966744250,
  "deployed": false,
  "isSeeded": true,
  "isStale": false,
  "lastUpdateTime": 1517966744250,
  "name": "sdnskmp",
  "namespace": "scalablegroup:application",
  "provisioningState": "DEFINED",
  "qualifier": "application",
  "resourceVersion": 0,
  "targetIdList": [

  ],
  "type": "scalablegroup",
  "cfsChangeInfo": [

  ],
  "customProvisions": [

  ],
  "scalableGroupExternalHandle": "sdnskmp",
  "scalableGroupType": "APPLICATION",
  "securityGroupTag": 0,
  "state": "ACTIVE",
  "vnAgnostic": true,
  "identitySource": {
    "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
    "instanceId": 10010,
    "authEntityId": 10010,
    "displayName": "10010",
    "authEntityClass": 1738953278,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "state": "INACTIVE",
    "type": "NBAR"
  },
  "indicativeNetworkIdentity": [
    {
      "id": "ad7d3c3c-f9c6-4935-a1e0-66f8bef6338c",
      "instanceId": 14960,
      "authEntityId": 14960,
      "displayName": "14960",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "558",
      "protocol": "TCP",
      "upperPort": 0
    },
    {
      "id": "291c46cb-4e6b-48c6-8ac2-66f259b25887",
      "instanceId": 14961,
      "authEntityId": 14961,
      "displayName": "14961",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "558",
      "protocol": "UDP",
      "upperPort": 0
    }
  ],
  "networkApplications": [
    {
      "id": "62a4ccf6-b4ad-437b-aabd-3780f983d5ae",
      "instanceId": 16050,
      "authEntityId": 16050,
      "displayName": "16050",
      "authEntityClass": -217092956,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "appProtocol": "tcp/udp",
      "applicationGroup": "other",
      "applicationType": "DEFAULT",
      "categoryId": "38a8e354-e2fe-4f3c-b1c5-2bbb90fb0d99",
      "createTime": 1517966744250,
      "encrypted": "false",
      "engineId": "3",
      "helpString": "SDNSKMP",
      "ignoreConflict": false,
      "lastUpdateTime": 1517966744250,
      "longDescription": "Secure Data Network System Key Management Protocol (SDNS-KMP) is a key management protocol for SDNS. SDNS implements computer to computer communications security for distributed applications. The key managament section implements the exchange of credentials and the traffic encryption key attributes.",
      "name": "sdnskmp",
      "nbarId": "473",
      "p2pTechnology": "false",
      "popularity": 1,
      "rank": 65535,
      "references": "http://articles-abstracts.com/article.php?id=67588&content=The_SDNS_KMP_provides_a_useful_example_of_the_choices_required_in_the_design_of_an_OSI_key",
      "selectorId": "558",
      "subCategory": "e390124a-0fd6-4253-b68e-4b0c1af8cb4b",
      "trafficClass": "OPS_ADMIN_MGMT",
      "tunnel": "false"
    }
  ],
  "networkIdentity": [
    {
      "id": "88079b66-8ca8-4a6b-925e-78aaf59ede3e",
      "instanceId": 14958,
      "authEntityId": 14958,
      "displayName": "14958",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "558",
      "protocol": "TCP",
      "upperPort": 0
    },
    {
      "id": "f212bc02-7606-4984-92e3-b8adc5615af6",
      "instanceId": 14959,
      "authEntityId": 14959,
      "displayName": "14959",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "558",
      "protocol": "UDP",
      "upperPort": 0
    }
  ],
  "parentScalableGroup": {
    "idRef": "7788bf2d-e289-4597-b500-17aacb7e820e"
  },
  "user": [

  ],
  "userGroup": [

  ]
},
{
  "id": "5eacb262-169d-41c0-8020-3d47d7ab7fc1",
  "instanceId": 15290,
  "authEntityId": 15290,
  "displayName": "15290",
  "authEntityClass": -1909073334,
  "deployPending": "NONE",
  "instanceVersion": 0,
  "createTime": 1517966744667,
  "deployed": false,
  "isSeeded": true,
  "isStale": false,
  "lastUpdateTime": 1517966744667,
  "name": "tinyurl",
  "namespace": "scalablegroup:application",
  "provisioningState": "DEFINED",
  "qualifier": "application",
  "resourceVersion": 0,
  "targetIdList": [

  ],
  "type": "scalablegroup",
  "cfsChangeInfo": [

  ],
  "customProvisions": [

  ],
  "scalableGroupExternalHandle": "tinyurl",
  "scalableGroupType": "APPLICATION",
  "securityGroupTag": 0,
  "state": "ACTIVE",
  "vnAgnostic": true,
  "identitySource": {
    "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
    "instanceId": 10010,
    "authEntityId": 10010,
    "displayName": "10010",
    "authEntityClass": 1738953278,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "state": "INACTIVE",
    "type": "NBAR"
  },
  "indicativeNetworkIdentity": [

  ],
  "networkApplications": [
    {
      "id": "aafd2c84-ec1c-423e-ae77-60c0d6deb546",
      "instanceId": 16233,
      "authEntityId": 16233,
      "displayName": "16233",
      "authEntityClass": -217092956,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "appProtocol": "tcp/udp",
      "applicationGroup": "other",
      "applicationType": "DEFAULT",
      "categoryId": "878644b5-fe91-45b4-9204-31c84a73ba48",
      "createTime": 1517966744667,
      "encrypted": "false",
      "engineId": "13",
      "helpString": "TinyURL - Shortens the long URL.",
      "ignoreConflict": false,
      "lastUpdateTime": 1517966744667,
      "longDescription": "TinyURL - Shortens the long URL.",
      "name": "tinyurl",
      "nbarId": "1696",
      "p2pTechnology": "false",
      "popularity": 3,
      "rank": 65535,
      "references": "http://tinyurl.com",
      "selectorId": "766",
      "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
      "trafficClass": "TRANSACTIONAL_DATA",
      "tunnel": "false"
    }
  ],
  "networkIdentity": [
    {
      "id": "52530fc9-302d-49f3-bcdd-9e32b04e3c5b",
      "instanceId": 17499,
      "authEntityId": 17499,
      "displayName": "17499",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "53,80,443,5353",
      "protocol": "TCP",
      "upperPort": 0
    },
    {
      "id": "1dd2c742-4f2a-4c0f-8a46-b6925b53ef87",
      "instanceId": 17500,
      "authEntityId": 17500,
      "displayName": "17500",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "53,5353",
      "protocol": "UDP",
      "upperPort": 0
    }
  ],
  "parentScalableGroup": {
    "idRef": "28e08de4-d7b2-4521-84ce-bc1e5ee0b9cc"
  },
  "user": [

  ],
  "userGroup": [

  ]
},
{
  "id": "5f17b2b8-75b3-40c9-beb0-4aaa317338bd",
  "instanceId": 4300,
  "authEntityId": 4300,
  "displayName": "4300",
  "authEntityClass": -1909073334,
  "deployPending": "NONE",
  "instanceVersion": 0,
  "createTime": 1517966742202,
  "deployed": false,
  "isSeeded": true,
  "isStale": false,
  "lastUpdateTime": 1517966742202,
  "name": "crashplan",
  "namespace": "scalablegroup:application",
  "provisioningState": "DEFINED",
  "qualifier": "application",
  "resourceVersion": 0,
  "targetIdList": [

  ],
  "type": "scalablegroup",
  "cfsChangeInfo": [

  ],
  "customProvisions": [

  ],
  "scalableGroupExternalHandle": "crashplan",
  "scalableGroupType": "APPLICATION",
  "securityGroupTag": 0,
  "state": "ACTIVE",
  "vnAgnostic": true,
  "identitySource": {
    "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
    "instanceId": 10010,
    "authEntityId": 10010,
    "displayName": "10010",
    "authEntityClass": 1738953278,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "state": "INACTIVE",
    "type": "NBAR"
  },
  "indicativeNetworkIdentity": [

  ],
  "networkApplications": [
    {
      "id": "8343ed46-703f-4628-ab14-e229c7dcb8f5",
      "instanceId": 11253,
      "authEntityId": 11253,
      "displayName": "11253",
      "authEntityClass": -217092956,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "appProtocol": "tcp",
      "applicationGroup": "other",
      "applicationType": "DEFAULT",
      "categoryId": "80909d4c-efe6-4878-9e1e-68281fd26edd",
      "createTime": 1517966742202,
      "encrypted": "true",
      "engineId": "13",
      "helpString": "online cloud backup data software",
      "ignoreConflict": false,
      "lastUpdateTime": 1517966742202,
      "longDescription": "Online data backup software. Provides continuous, silent backup of end user data.",
      "name": "crashplan",
      "nbarId": "1749",
      "p2pTechnology": "false",
      "popularity": 10,
      "rank": 65535,
      "references": "http://www.code42.com/crashplan/",
      "selectorId": "820",
      "subCategory": "697101ea-4e72-4b89-872a-e2d494155c78",
      "trafficClass": "BULK_DATA",
      "tunnel": "false"
    }
  ],
  "networkIdentity": [
    {
      "id": "2e45afcc-10f8-4c75-9879-a986af7c7fa7",
      "instanceId": 12671,
      "authEntityId": 12671,
      "displayName": "12671",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "80",
      "protocol": "TCP",
      "upperPort": 0
    }
  ],
  "parentScalableGroup": {
    "idRef": "f09a36de-df38-4ff8-b0be-56738afc75f7"
  },
  "user": [

  ],
  "userGroup": [

  ]
},
{
  "id": "5f5b0cfc-ddfb-4f4e-b3b4-fa1ab057c927",
  "instanceId": 4107,
  "authEntityId": 4107,
  "displayName": "4107",
  "authEntityClass": -1909073334,
  "deployPending": "NONE",
  "instanceVersion": 0,
  "createTime": 1517966741149,
  "deployed": false,
  "isSeeded": true,
  "isStale": false,
  "lastUpdateTime": 1517966741149,
  "name": "apple-tv-updates",
  "namespace": "scalablegroup:application",
  "provisioningState": "DEFINED",
  "qualifier": "application",
  "resourceVersion": 0,
  "targetIdList": [

  ],
  "type": "scalablegroup",
  "cfsChangeInfo": [

  ],
  "customProvisions": [

  ],
  "scalableGroupExternalHandle": "apple-tv-updates",
  "scalableGroupType": "APPLICATION",
  "securityGroupTag": 0,
  "state": "ACTIVE",
  "vnAgnostic": true,
  "identitySource": {
    "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
    "instanceId": 10010,
    "authEntityId": 10010,
    "displayName": "10010",
    "authEntityClass": 1738953278,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "state": "INACTIVE",
    "type": "NBAR"
  },
  "indicativeNetworkIdentity": [

  ],
  "networkApplications": [
    {
      "id": "eda959e4-6a6f-4c0d-bec7-597bd0784663",
      "instanceId": 11069,
      "authEntityId": 11069,
      "displayName": "11069",
      "authEntityClass": -217092956,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "appProtocol": "tcp",
      "applicationGroup": "apple-group",
      "applicationType": "DEFAULT",
      "categoryId": "9d9132a2-d99b-49db-a414-9fc93dea6604",
      "createTime": 1517966741149,
      "encrypted": "true",
      "engineId": "13",
      "helpString": "AppleTV operating system updates",
      "ignoreConflict": false,
      "lastUpdateTime": 1517966741149,
      "longDescription": "AppleTV updates is a service that provides updates for the AppleTV operating system and its installed components.",
      "name": "apple-tv-updates",
      "nbarId": "1532",
      "p2pTechnology": "false",
      "popularity": 8,
      "rank": 65535,
      "references": "http://www.apple.com/softwareupdate/",
      "selectorId": "593",
      "subCategory": "98e625d1-8720-4d2b-a186-c821a5d86693",
      "trafficClass": "BULK_DATA",
      "tunnel": "false"
    }
  ],
  "networkIdentity": [
    {
      "id": "668b4bfe-1d3c-408c-8b57-2a761cf04e6e",
      "instanceId": 12172,
      "authEntityId": 12172,
      "displayName": "12172",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "80",
      "protocol": "TCP",
      "upperPort": 0
    }
  ],
  "parentScalableGroup": {
    "idRef": "4a68bc73-53c6-4deb-9983-784a0338aefe"
  },
  "user": [

  ],
  "userGroup": [

  ]
},
{
  "id": "5ffaeace-3a4f-436a-a8af-b4dfe592a088",
  "instanceId": 4766,
  "authEntityId": 4766,
  "displayName": "4766",
  "authEntityClass": -1909073334,
  "deployPending": "NONE",
  "instanceVersion": 0,
  "createTime": 1517966743450,
  "deployed": false,
  "isSeeded": true,
  "isStale": false,
  "lastUpdateTime": 1517966743450,
  "name": "mpm-flags",
  "namespace": "scalablegroup:application",
  "provisioningState": "DEFINED",
  "qualifier": "application",
  "resourceVersion": 0,
  "targetIdList": [

  ],
  "type": "scalablegroup",
  "cfsChangeInfo": [

  ],
  "customProvisions": [

  ],
  "scalableGroupExternalHandle": "mpm-flags",
  "scalableGroupType": "APPLICATION",
  "securityGroupTag": 0,
  "state": "ACTIVE",
  "vnAgnostic": true,
  "identitySource": {
    "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
    "instanceId": 10010,
    "authEntityId": 10010,
    "displayName": "10010",
    "authEntityClass": 1738953278,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "state": "INACTIVE",
    "type": "NBAR"
  },
  "indicativeNetworkIdentity": [
    {
      "id": "d04e3786-ab63-4f3d-ade4-7b857c203d4e",
      "instanceId": 13956,
      "authEntityId": 13956,
      "displayName": "13956",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "44",
      "protocol": "UDP",
      "upperPort": 0
    },
    {
      "id": "11e58fe9-a208-4749-9826-b92525773339",
      "instanceId": 13957,
      "authEntityId": 13957,
      "displayName": "13957",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "44",
      "protocol": "TCP",
      "upperPort": 0
    }
  ],
  "networkApplications": [
    {
      "id": "c391d8b5-4d30-4baa-a85a-516b6789cb0b",
      "instanceId": 11716,
      "authEntityId": 11716,
      "displayName": "11716",
      "authEntityClass": -217092956,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "appProtocol": "tcp/udp",
      "applicationGroup": "other",
      "applicationType": "DEFAULT",
      "categoryId": "6daa3dfd-3526-407a-88f0-97824b3f3a24",
      "createTime": 1517966743450,
      "encrypted": "false",
      "engineId": "3",
      "helpString": "MPM FLAGS Protocol",
      "ignoreConflict": false,
      "lastUpdateTime": 1517966743450,
      "longDescription": "Registered with IANA on port 44 TCP/UDP",
      "name": "mpm-flags",
      "nbarId": "924",
      "p2pTechnology": "false",
      "popularity": 1,
      "rank": 65535,
      "references": "http://www.iana.org/assignments/service-names-port-numbers/service-names-port-numbers.xml",
      "selectorId": "44",
      "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
      "trafficClass": "BULK_DATA",
      "tunnel": "false"
    }
  ],
  "networkIdentity": [
    {
      "id": "e057b87d-996a-48fa-875c-cb6019d034a7",
      "instanceId": 13954,
      "authEntityId": 13954,
      "displayName": "13954",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "44",
      "protocol": "TCP",
      "upperPort": 0
    },
    {
      "id": "2ad3499f-ffa7-4945-beb4-a4bda6cf15f3",
      "instanceId": 13955,
      "authEntityId": 13955,
      "displayName": "13955",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "44",
      "protocol": "UDP",
      "upperPort": 0
    }
  ],
  "parentScalableGroup": {
    "idRef": "000c15bf-28fe-4fbb-9b69-35e6502721f1"
  },
  "user": [

  ],
  "userGroup": [

  ]
},
{
  "id": "6073dafb-e2d1-4fac-baa5-3bc1d9acb3db",
  "instanceId": 4510,
  "authEntityId": 4510,
  "displayName": "4510",
  "authEntityClass": -1909073334,
  "deployPending": "NONE",
  "instanceVersion": 0,
  "createTime": 1517966742847,
  "deployed": false,
  "isSeeded": true,
  "isStale": false,
  "lastUpdateTime": 1517966742847,
  "name": "gridftp",
  "namespace": "scalablegroup:application",
  "provisioningState": "DEFINED",
  "qualifier": "application",
  "resourceVersion": 0,
  "targetIdList": [

  ],
  "type": "scalablegroup",
  "cfsChangeInfo": [

  ],
  "customProvisions": [

  ],
  "scalableGroupExternalHandle": "gridftp",
  "scalableGroupType": "APPLICATION",
  "securityGroupTag": 0,
  "state": "ACTIVE",
  "vnAgnostic": true,
  "identitySource": {
    "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
    "instanceId": 10010,
    "authEntityId": 10010,
    "displayName": "10010",
    "authEntityClass": 1738953278,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "state": "INACTIVE",
    "type": "NBAR"
  },
  "indicativeNetworkIdentity": [
    {
      "id": "5f54297c-e56d-4bfc-a068-9113a6911b53",
      "instanceId": 13264,
      "authEntityId": 13264,
      "displayName": "13264",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "2811",
      "protocol": "TCP",
      "upperPort": 0
    }
  ],
  "networkApplications": [
    {
      "id": "b2a3b71e-07ac-49cf-93a1-ecf1a01ed9aa",
      "instanceId": 11461,
      "authEntityId": 11461,
      "displayName": "11461",
      "authEntityClass": -217092956,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "appProtocol": "tcp",
      "applicationGroup": "ftp-group",
      "applicationType": "DEFAULT",
      "categoryId": "e6619ce8-97de-4c63-a53a-cceddbddc1a8",
      "createTime": 1517966742847,
      "encrypted": "true",
      "engineId": "13",
      "helpString": "File Transfer Protocol over the Grid",
      "ignoreConflict": false,
      "lastUpdateTime": 1517966742847,
      "longDescription": "GridFTP is an extension of the standard File Transfer Protocol (FTP) for use with Grid computing. It is defined as part of the Globus toolkit, under the organization of the Global Grid Forum. The aim of GridFTP is to provide a more reliable and high performance file transfer for Grid computing applications.",
      "name": "gridftp",
      "nbarId": "1309",
      "p2pTechnology": "false",
      "popularity": 4,
      "rank": 65535,
      "references": "http://www.globus.org/grid_software/data/gridftp.php",
      "selectorId": "451",
      "subCategory": "50b55e2e-d0d0-4404-bea4-819351565d90",
      "trafficClass": "BULK_DATA",
      "tunnel": "false"
    }
  ],
  "networkIdentity": [
    {
      "id": "a3399586-944b-471c-b817-7dc63d4d0d1b",
      "instanceId": 13263,
      "authEntityId": 13263,
      "displayName": "13263",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "21,2811,21000",
      "protocol": "TCP",
      "upperPort": 0
    }
  ],
  "parentScalableGroup": {
    "idRef": "383363b1-4dd5-49d1-bfe4-34daac437cfb"
  },
  "user": [

  ],
  "userGroup": [

  ]
},
{
  "id": "60cfa3aa-2d08-41eb-9c58-5498b22a75d7",
  "instanceId": 4596,
  "authEntityId": 4596,
  "displayName": "4596",
  "authEntityClass": -1909073334,
  "deployPending": "NONE",
  "instanceVersion": 0,
  "createTime": 1517966743081,
  "deployed": false,
  "isSeeded": true,
  "isStale": false,
  "lastUpdateTime": 1517966743081,
  "name": "imdb",
  "namespace": "scalablegroup:application",
  "provisioningState": "DEFINED",
  "qualifier": "application",
  "resourceVersion": 0,
  "targetIdList": [

  ],
  "type": "scalablegroup",
  "cfsChangeInfo": [

  ],
  "customProvisions": [

  ],
  "scalableGroupExternalHandle": "imdb",
  "scalableGroupType": "APPLICATION",
  "securityGroupTag": 0,
  "state": "ACTIVE",
  "vnAgnostic": true,
  "identitySource": {
    "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
    "instanceId": 10010,
    "authEntityId": 10010,
    "displayName": "10010",
    "authEntityClass": 1738953278,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "state": "INACTIVE",
    "type": "NBAR"
  },
  "indicativeNetworkIdentity": [

  ],
  "networkApplications": [
    {
      "id": "839c03bd-670b-42e0-90a2-727e368a925b",
      "instanceId": 11546,
      "authEntityId": 11546,
      "displayName": "11546",
      "authEntityClass": -217092956,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "appProtocol": "tcp/udp",
      "applicationGroup": "other",
      "applicationType": "DEFAULT",
      "categoryId": "878644b5-fe91-45b4-9204-31c84a73ba48",
      "createTime": 1517966743081,
      "encrypted": "false",
      "engineId": "13",
      "helpString": "IMDB - Movie information, reviews and previews.",
      "ignoreConflict": false,
      "lastUpdateTime": 1517966743081,
      "longDescription": "IMDB - Movie information, reviews and previews.",
      "name": "imdb",
      "nbarId": "262",
      "p2pTechnology": "false",
      "popularity": 3,
      "rank": 65535,
      "references": "http://imdb.com",
      "selectorId": "529",
      "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
      "trafficClass": "TRANSACTIONAL_DATA",
      "tunnel": "false"
    }
  ],
  "networkIdentity": [
    {
      "id": "b9a367c4-82de-40ca-b1e4-ebe0f8fc2330",
      "instanceId": 13501,
      "authEntityId": 13501,
      "displayName": "13501",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "53,80,443,5353",
      "protocol": "TCP",
      "upperPort": 0
    },
    {
      "id": "83cd2938-e078-4111-9c77-39536b76fc34",
      "instanceId": 13502,
      "authEntityId": 13502,
      "displayName": "13502",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "53,5353",
      "protocol": "UDP",
      "upperPort": 0
    }
  ],
  "parentScalableGroup": {
    "idRef": "28e08de4-d7b2-4521-84ce-bc1e5ee0b9cc"
  },
  "user": [

  ],
  "userGroup": [

  ]
},
{
  "id": "61551725-f0d0-4818-8d7f-62ab31a9bd74",
  "instanceId": 4052,
  "authEntityId": 4052,
  "displayName": "4052",
  "authEntityClass": -1909073334,
  "deployPending": "NONE",
  "instanceVersion": 0,
  "createTime": 1517966740618,
  "deployed": false,
  "isSeeded": true,
  "isStale": false,
  "lastUpdateTime": 1517966740618,
  "name": "accuweather",
  "namespace": "scalablegroup:application",
  "provisioningState": "DEFINED",
  "qualifier": "application",
  "resourceVersion": 0,
  "targetIdList": [

  ],
  "type": "scalablegroup",
  "cfsChangeInfo": [

  ],
  "customProvisions": [

  ],
  "scalableGroupExternalHandle": "accuweather",
  "scalableGroupType": "APPLICATION",
  "securityGroupTag": 0,
  "state": "ACTIVE",
  "vnAgnostic": true,
  "identitySource": {
    "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
    "instanceId": 10010,
    "authEntityId": 10010,
    "displayName": "10010",
    "authEntityClass": 1738953278,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "state": "INACTIVE",
    "type": "NBAR"
  },
  "indicativeNetworkIdentity": [

  ],
  "networkApplications": [
    {
      "id": "4057481b-a209-4f5f-923d-6e6262c868a9",
      "instanceId": 11025,
      "authEntityId": 11025,
      "displayName": "11025",
      "authEntityClass": -217092956,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "appProtocol": "tcp/udp",
      "applicationGroup": "other",
      "applicationType": "DEFAULT",
      "categoryId": "878644b5-fe91-45b4-9204-31c84a73ba48",
      "createTime": 1517966740618,
      "encrypted": "false",
      "engineId": "13",
      "helpString": "AccuWeather - Weather forecasting website.",
      "ignoreConflict": false,
      "lastUpdateTime": 1517966740618,
      "longDescription": "AccuWeather - Weather forecasting website.",
      "name": "accuweather",
      "nbarId": "1610",
      "p2pTechnology": "false",
      "popularity": 3,
      "rank": 65535,
      "references": "http://accuweather.com",
      "selectorId": "680",
      "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
      "trafficClass": "TRANSACTIONAL_DATA",
      "tunnel": "false"
    }
  ],
  "networkIdentity": [
    {
      "id": "b55f04fc-8e4d-46a4-8e55-be0590acc4f5",
      "instanceId": 12060,
      "authEntityId": 12060,
      "displayName": "12060",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "53,80,443,5353",
      "protocol": "TCP",
      "upperPort": 0
    },
    {
      "id": "a2531392-95b6-456a-958b-f1479d438c4b",
      "instanceId": 12061,
      "authEntityId": 12061,
      "displayName": "12061",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "53,5353",
      "protocol": "UDP",
      "upperPort": 0
    }
  ],
  "parentScalableGroup": {
    "idRef": "28e08de4-d7b2-4521-84ce-bc1e5ee0b9cc"
  },
  "user": [

  ],
  "userGroup": [

  ]
},
{
  "id": "616ee36e-1d5a-470c-bf39-02aca0fb102c",
  "instanceId": 4367,
  "authEntityId": 4367,
  "displayName": "4367",
  "authEntityClass": -1909073334,
  "deployPending": "NONE",
  "instanceVersion": 0,
  "createTime": 1517966742423,
  "deployed": false,
  "isSeeded": true,
  "isStale": false,
  "lastUpdateTime": 1517966742423,
  "name": "directv-tick",
  "namespace": "scalablegroup:application",
  "provisioningState": "DEFINED",
  "qualifier": "application",
  "resourceVersion": 0,
  "targetIdList": [

  ],
  "type": "scalablegroup",
  "cfsChangeInfo": [

  ],
  "customProvisions": [

  ],
  "scalableGroupExternalHandle": "directv-tick",
  "scalableGroupType": "APPLICATION",
  "securityGroupTag": 0,
  "state": "ACTIVE",
  "vnAgnostic": true,
  "identitySource": {
    "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
    "instanceId": 10010,
    "authEntityId": 10010,
    "displayName": "10010",
    "authEntityClass": 1738953278,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "state": "INACTIVE",
    "type": "NBAR"
  },
  "indicativeNetworkIdentity": [
    {
      "id": "9b0e9132-fe2a-427e-8ca4-5c7245705b30",
      "instanceId": 12885,
      "authEntityId": 12885,
      "displayName": "12885",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "3336",
      "protocol": "TCP",
      "upperPort": 0
    },
    {
      "id": "4c82f7b4-4313-4fe9-9c6a-1b96283d41e9",
      "instanceId": 12886,
      "authEntityId": 12886,
      "displayName": "12886",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "3336",
      "protocol": "UDP",
      "upperPort": 0
    }
  ],
  "networkApplications": [
    {
      "id": "07cdda76-c76e-422c-a316-2a1e87cfe92e",
      "instanceId": 11318,
      "authEntityId": 11318,
      "displayName": "11318",
      "authEntityClass": -217092956,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "appProtocol": "tcp/udp",
      "applicationGroup": "other",
      "applicationType": "DEFAULT",
      "categoryId": "a09ef1b3-ba26-4372-aee8-309d50697e76",
      "createTime": 1517966742423,
      "encrypted": "false",
      "engineId": "3",
      "helpString": "Direct TV Tickers",
      "ignoreConflict": false,
      "lastUpdateTime": 1517966742423,
      "longDescription": "Registered with IANA on port 3336 TCP/UDP",
      "name": "directv-tick",
      "nbarId": "722",
      "p2pTechnology": "false",
      "popularity": 1,
      "rank": 65535,
      "references": "http://www.iana.org/assignments/service-names-port-numbers/service-names-port-numbers.xml",
      "selectorId": "3336",
      "subCategory": "85a797c0-aef8-4284-91a7-677f33e29abb",
      "trafficClass": "MULTIMEDIA_STREAMING",
      "tunnel": "false"
    }
  ],
  "networkIdentity": [
    {
      "id": "efa8c5fe-9d8b-4dd1-bb95-ad4a5763dfb0",
      "instanceId": 12883,
      "authEntityId": 12883,
      "displayName": "12883",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "3336",
      "protocol": "TCP",
      "upperPort": 0
    },
    {
      "id": "0b0cf1cb-a07b-4cec-8c24-d47c28bc6ff7",
      "instanceId": 12884,
      "authEntityId": 12884,
      "displayName": "12884",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "3336",
      "protocol": "UDP",
      "upperPort": 0
    }
  ],
  "parentScalableGroup": {
    "idRef": "fd6264c9-c16a-4311-a335-a3db039256f3"
  },
  "user": [

  ],
  "userGroup": [

  ]
},
{
  "id": "618cff30-f345-4e0e-947a-cea64a2f2c83",
  "instanceId": 4385,
  "authEntityId": 4385,
  "displayName": "4385",
  "authEntityClass": -1909073334,
  "deployPending": "NONE",
  "instanceVersion": 0,
  "createTime": 1517966742486,
  "deployed": false,
  "isSeeded": true,
  "isStale": false,
  "lastUpdateTime": 1517966742486,
  "name": "dpsi",
  "namespace": "scalablegroup:application",
  "provisioningState": "DEFINED",
  "qualifier": "application",
  "resourceVersion": 0,
  "targetIdList": [

  ],
  "type": "scalablegroup",
  "cfsChangeInfo": [

  ],
  "customProvisions": [

  ],
  "scalableGroupExternalHandle": "dpsi",
  "scalableGroupType": "APPLICATION",
  "securityGroupTag": 0,
  "state": "ACTIVE",
  "vnAgnostic": true,
  "identitySource": {
    "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
    "instanceId": 10010,
    "authEntityId": 10010,
    "displayName": "10010",
    "authEntityClass": 1738953278,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "state": "INACTIVE",
    "type": "NBAR"
  },
  "indicativeNetworkIdentity": [
    {
      "id": "cf0f8a75-5f8f-44d0-8390-df657886c4e2",
      "instanceId": 12950,
      "authEntityId": 12950,
      "displayName": "12950",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "315",
      "protocol": "UDP",
      "upperPort": 0
    },
    {
      "id": "6991ebd0-c51c-4a11-8ec2-c1e6b0bc57d4",
      "instanceId": 12951,
      "authEntityId": 12951,
      "displayName": "12951",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "315",
      "protocol": "TCP",
      "upperPort": 0
    }
  ],
  "networkApplications": [
    {
      "id": "3a93811d-1639-4d8d-9ee3-8000865f617b",
      "instanceId": 11336,
      "authEntityId": 11336,
      "displayName": "11336",
      "authEntityClass": -217092956,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "appProtocol": "tcp/udp",
      "applicationGroup": "other",
      "applicationType": "DEFAULT",
      "categoryId": "6daa3dfd-3526-407a-88f0-97824b3f3a24",
      "createTime": 1517966742486,
      "encrypted": "false",
      "engineId": "3",
      "helpString": "dpsi",
      "ignoreConflict": false,
      "lastUpdateTime": 1517966742486,
      "longDescription": "Registered with IANA on port 315 TCP/UDP",
      "name": "dpsi",
      "nbarId": "1155",
      "p2pTechnology": "false",
      "popularity": 1,
      "rank": 65535,
      "references": "http://www.iana.org/assignments/service-names-port-numbers/service-names-port-numbers.xml",
      "selectorId": "315",
      "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
      "trafficClass": "BULK_DATA",
      "tunnel": "false"
    }
  ],
  "networkIdentity": [
    {
      "id": "4e802359-f436-40ba-ab42-61467f261a2f",
      "instanceId": 12948,
      "authEntityId": 12948,
      "displayName": "12948",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "315",
      "protocol": "UDP",
      "upperPort": 0
    },
    {
      "id": "20fb3715-c459-4df6-ba82-5b741d067eb5",
      "instanceId": 12949,
      "authEntityId": 12949,
      "displayName": "12949",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "315",
      "protocol": "TCP",
      "upperPort": 0
    }
  ],
  "parentScalableGroup": {
    "idRef": "000c15bf-28fe-4fbb-9b69-35e6502721f1"
  },
  "user": [

  ],
  "userGroup": [

  ]
},
{
  "id": "620a5c03-81e6-4245-9096-9d51687b27b6",
  "instanceId": 4570,
  "authEntityId": 4570,
  "displayName": "4570",
  "authEntityClass": -1909073334,
  "deployPending": "NONE",
  "instanceVersion": 0,
  "createTime": 1517966743014,
  "deployed": false,
  "isSeeded": true,
  "isStale": false,
  "lastUpdateTime": 1517966743014,
  "name": "iafdbase",
  "namespace": "scalablegroup:application",
  "provisioningState": "DEFINED",
  "qualifier": "application",
  "resourceVersion": 0,
  "targetIdList": [

  ],
  "type": "scalablegroup",
  "cfsChangeInfo": [

  ],
  "customProvisions": [

  ],
  "scalableGroupExternalHandle": "iafdbase",
  "scalableGroupType": "APPLICATION",
  "securityGroupTag": 0,
  "state": "ACTIVE",
  "vnAgnostic": true,
  "identitySource": {
    "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
    "instanceId": 10010,
    "authEntityId": 10010,
    "displayName": "10010",
    "authEntityClass": 1738953278,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "state": "INACTIVE",
    "type": "NBAR"
  },
  "indicativeNetworkIdentity": [
    {
      "id": "e0dcde83-3699-473b-8531-34d1a35ec3c0",
      "instanceId": 13424,
      "authEntityId": 13424,
      "displayName": "13424",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "480",
      "protocol": "TCP",
      "upperPort": 0
    },
    {
      "id": "d6c29afd-b81d-4fbb-9e1f-80614a114e21",
      "instanceId": 13423,
      "authEntityId": 13423,
      "displayName": "13423",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "480",
      "protocol": "UDP",
      "upperPort": 0
    }
  ],
  "networkApplications": [
    {
      "id": "8df7e3a3-d2c4-498f-845e-b51c8f740226",
      "instanceId": 11520,
      "authEntityId": 11520,
      "displayName": "11520",
      "authEntityClass": -217092956,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "appProtocol": "tcp/udp",
      "applicationGroup": "other",
      "applicationType": "DEFAULT",
      "categoryId": "6daa3dfd-3526-407a-88f0-97824b3f3a24",
      "createTime": 1517966743014,
      "encrypted": "false",
      "engineId": "3",
      "helpString": "iafdbase",
      "ignoreConflict": false,
      "lastUpdateTime": 1517966743014,
      "longDescription": "Registered with IANA on port 480 TCP/UDP",
      "name": "iafdbase",
      "nbarId": "394",
      "p2pTechnology": "false",
      "popularity": 1,
      "rank": 65535,
      "references": "http://www.iana.org/assignments/service-names-port-numbers/service-names-port-numbers.xml",
      "selectorId": "480",
      "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
      "trafficClass": "BULK_DATA",
      "tunnel": "false"
    }
  ],
  "networkIdentity": [
    {
      "id": "2edfc056-89fe-4289-98cd-ffdf368f40f4",
      "instanceId": 13421,
      "authEntityId": 13421,
      "displayName": "13421",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "480",
      "protocol": "UDP",
      "upperPort": 0
    },
    {
      "id": "0ae9f65d-73cf-4e96-a4ab-bd2c16d824d9",
      "instanceId": 13422,
      "authEntityId": 13422,
      "displayName": "13422",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "480",
      "protocol": "TCP",
      "upperPort": 0
    }
  ],
  "parentScalableGroup": {
    "idRef": "000c15bf-28fe-4fbb-9b69-35e6502721f1"
  },
  "user": [

  ],
  "userGroup": [

  ]
},
{
  "id": "621bdb33-d7b7-481d-9f09-f97493450a45",
  "instanceId": 4060,
  "authEntityId": 4060,
  "displayName": "4060",
  "authEntityClass": -1909073334,
  "deployPending": "NONE",
  "instanceVersion": 0,
  "createTime": 1517966740666,
  "deployed": false,
  "isSeeded": true,
  "isStale": false,
  "lastUpdateTime": 1517966740666,
  "name": "addthis",
  "namespace": "scalablegroup:application",
  "provisioningState": "DEFINED",
  "qualifier": "application",
  "resourceVersion": 0,
  "targetIdList": [

  ],
  "type": "scalablegroup",
  "cfsChangeInfo": [

  ],
  "customProvisions": [

  ],
  "scalableGroupExternalHandle": "addthis",
  "scalableGroupType": "APPLICATION",
  "securityGroupTag": 0,
  "state": "ACTIVE",
  "vnAgnostic": true,
  "identitySource": {
    "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
    "instanceId": 10010,
    "authEntityId": 10010,
    "displayName": "10010",
    "authEntityClass": 1738953278,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "state": "INACTIVE",
    "type": "NBAR"
  },
  "indicativeNetworkIdentity": [

  ],
  "networkApplications": [
    {
      "id": "67f85c72-d47e-4eda-a4ee-bdc96f5cdce6",
      "instanceId": 11031,
      "authEntityId": 11031,
      "displayName": "11031",
      "authEntityClass": -217092956,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "appProtocol": "tcp/udp",
      "applicationGroup": "other",
      "applicationType": "DEFAULT",
      "categoryId": "878644b5-fe91-45b4-9204-31c84a73ba48",
      "createTime": 1517966740666,
      "encrypted": "false",
      "engineId": "13",
      "helpString": "AddThis - Social bookmarking service.",
      "ignoreConflict": false,
      "lastUpdateTime": 1517966740666,
      "longDescription": "AddThis - Social bookmarking service.",
      "name": "addthis",
      "nbarId": "1613",
      "p2pTechnology": "false",
      "popularity": 3,
      "rank": 65535,
      "references": "http://addthis.com",
      "selectorId": "683",
      "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
      "trafficClass": "TRANSACTIONAL_DATA",
      "tunnel": "false"
    }
  ],
  "networkIdentity": [
    {
      "id": "614ca72d-0d93-4fa0-8d38-d70637bf7955",
      "instanceId": 12075,
      "authEntityId": 12075,
      "displayName": "12075",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "53,80,443,5353",
      "protocol": "TCP",
      "upperPort": 0
    },
    {
      "id": "12e8492a-533e-4120-b865-8e5981bac196",
      "instanceId": 12076,
      "authEntityId": 12076,
      "displayName": "12076",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "53,5353",
      "protocol": "UDP",
      "upperPort": 0
    }
  ],
  "parentScalableGroup": {
    "idRef": "28e08de4-d7b2-4521-84ce-bc1e5ee0b9cc"
  },
  "user": [

  ],
  "userGroup": [

  ]
},
{
  "id": "62212ca9-677d-4885-aa1d-e4a36d3b7507",
  "instanceId": 4049,
  "authEntityId": 4049,
  "displayName": "4049",
  "authEntityClass": -1909073334,
  "deployPending": "NONE",
  "instanceVersion": 0,
  "createTime": 1517966740595,
  "deployed": false,
  "isSeeded": true,
  "isStale": false,
  "lastUpdateTime": 1517966740595,
  "name": "acas",
  "namespace": "scalablegroup:application",
  "provisioningState": "DEFINED",
  "qualifier": "application",
  "resourceVersion": 0,
  "targetIdList": [

  ],
  "type": "scalablegroup",
  "cfsChangeInfo": [

  ],
  "customProvisions": [

  ],
  "scalableGroupExternalHandle": "acas",
  "scalableGroupType": "APPLICATION",
  "securityGroupTag": 0,
  "state": "ACTIVE",
  "vnAgnostic": true,
  "identitySource": {
    "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
    "instanceId": 10010,
    "authEntityId": 10010,
    "displayName": "10010",
    "authEntityClass": 1738953278,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "state": "INACTIVE",
    "type": "NBAR"
  },
  "indicativeNetworkIdentity": [
    {
      "id": "b4ce200f-a84b-4594-82c2-e1e971f7afe8",
      "instanceId": 12050,
      "authEntityId": 12050,
      "displayName": "12050",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "62",
      "protocol": "TCP",
      "upperPort": 0
    },
    {
      "id": "786ad5f5-a7b5-4c1d-909a-b1d0ff28f44b",
      "instanceId": 12051,
      "authEntityId": 12051,
      "displayName": "12051",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "62",
      "protocol": "UDP",
      "upperPort": 0
    }
  ],
  "networkApplications": [
    {
      "id": "d856bc44-3f4e-4460-9973-f97625071d7b",
      "instanceId": 11022,
      "authEntityId": 11022,
      "displayName": "11022",
      "authEntityClass": -217092956,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "appProtocol": "tcp/udp",
      "applicationGroup": "other",
      "applicationType": "DEFAULT",
      "categoryId": "6daa3dfd-3526-407a-88f0-97824b3f3a24",
      "createTime": 1517966740595,
      "encrypted": "false",
      "engineId": "3",
      "helpString": "ACA Services",
      "ignoreConflict": false,
      "lastUpdateTime": 1517966740595,
      "longDescription": "Registered with IANA on port 62 TCP/UDP",
      "name": "acas",
      "nbarId": "939",
      "p2pTechnology": "false",
      "popularity": 1,
      "rank": 65535,
      "references": "http://www.iana.org/assignments/service-names-port-numbers/service-names-port-numbers.xml",
      "selectorId": "62",
      "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
      "trafficClass": "BULK_DATA",
      "tunnel": "false"
    }
  ],
  "networkIdentity": [
    {
      "id": "b05d85dc-82b7-40e7-9465-db6b7212b5ff",
      "instanceId": 12048,
      "authEntityId": 12048,
      "displayName": "12048",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "62",
      "protocol": "UDP",
      "upperPort": 0
    },
    {
      "id": "1bac4b51-6b1b-4b20-be2e-5543d2f1c9f0",
      "instanceId": 12049,
      "authEntityId": 12049,
      "displayName": "12049",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "62",
      "protocol": "TCP",
      "upperPort": 0
    }
  ],
  "parentScalableGroup": {
    "idRef": "000c15bf-28fe-4fbb-9b69-35e6502721f1"
  },
  "user": [

  ],
  "userGroup": [

  ]
},
{
  "id": "623c9acb-82a6-4c5f-9a56-735f0e9577d8",
  "instanceId": 4088,
  "authEntityId": 4088,
  "displayName": "4088",
  "authEntityClass": -1909073334,
  "deployPending": "NONE",
  "instanceVersion": 0,
  "createTime": 1517966740969,
  "deployed": false,
  "isSeeded": true,
  "isStale": false,
  "lastUpdateTime": 1517966740969,
  "name": "ansanotify",
  "namespace": "scalablegroup:application",
  "provisioningState": "DEFINED",
  "qualifier": "application",
  "resourceVersion": 0,
  "targetIdList": [

  ],
  "type": "scalablegroup",
  "cfsChangeInfo": [

  ],
  "customProvisions": [

  ],
  "scalableGroupExternalHandle": "ansanotify",
  "scalableGroupType": "APPLICATION",
  "securityGroupTag": 0,
  "state": "ACTIVE",
  "vnAgnostic": true,
  "identitySource": {
    "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
    "instanceId": 10010,
    "authEntityId": 10010,
    "displayName": "10010",
    "authEntityClass": 1738953278,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "state": "INACTIVE",
    "type": "NBAR"
  },
  "indicativeNetworkIdentity": [
    {
      "id": "91409a0d-d2c2-4f36-b331-99c2a5632bd2",
      "instanceId": 12134,
      "authEntityId": 12134,
      "displayName": "12134",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "116",
      "protocol": "TCP",
      "upperPort": 0
    },
    {
      "id": "b144d7e5-8e9f-46f4-aed4-e8ecc439e800",
      "instanceId": 12135,
      "authEntityId": 12135,
      "displayName": "12135",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "116",
      "protocol": "UDP",
      "upperPort": 0
    }
  ],
  "networkApplications": [
    {
      "id": "bbb33cf9-01d7-4a9f-8418-c6c441e8fd8f",
      "instanceId": 11053,
      "authEntityId": 11053,
      "displayName": "11053",
      "authEntityClass": -217092956,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "appProtocol": "tcp/udp",
      "applicationGroup": "other",
      "applicationType": "DEFAULT",
      "categoryId": "6daa3dfd-3526-407a-88f0-97824b3f3a24",
      "createTime": 1517966740969,
      "encrypted": "false",
      "engineId": "3",
      "helpString": "ANSA REX Notify",
      "ignoreConflict": false,
      "lastUpdateTime": 1517966740969,
      "longDescription": "The Remote EXecution Protocol (REX) provides a simple service for process-to-process interactions across a network, as part of ANSA Engineering Module.",
      "name": "ansanotify",
      "nbarId": "986",
      "p2pTechnology": "false",
      "popularity": 1,
      "rank": 65535,
      "references": "http://www.ansa.co.uk/ANSATech/89/ANSAREF/Aref_07b.pdf",
      "selectorId": "116",
      "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
      "trafficClass": "BULK_DATA",
      "tunnel": "false"
    }
  ],
  "networkIdentity": [
    {
      "id": "2fab552c-aeb6-4db2-9d93-f3e2951f9569",
      "instanceId": 12132,
      "authEntityId": 12132,
      "displayName": "12132",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "116",
      "protocol": "UDP",
      "upperPort": 0
    },
    {
      "id": "cfa1a782-2cc1-420d-b351-903d012b19c7",
      "instanceId": 12133,
      "authEntityId": 12133,
      "displayName": "12133",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "116",
      "protocol": "TCP",
      "upperPort": 0
    }
  ],
  "parentScalableGroup": {
    "idRef": "000c15bf-28fe-4fbb-9b69-35e6502721f1"
  },
  "user": [

  ],
  "userGroup": [

  ]
},
{
  "id": "627663c4-3e5d-4026-a805-8b1b210dbf9c",
  "instanceId": 4481,
  "authEntityId": 4481,
  "displayName": "4481",
  "authEntityClass": -1909073334,
  "deployPending": "NONE",
  "instanceVersion": 0,
  "createTime": 1517966742780,
  "deployed": false,
  "isSeeded": true,
  "isStale": false,
  "lastUpdateTime": 1517966742780,
  "name": "ghostsurf",
  "namespace": "scalablegroup:application",
  "provisioningState": "DEFINED",
  "qualifier": "application",
  "resourceVersion": 0,
  "targetIdList": [

  ],
  "type": "scalablegroup",
  "cfsChangeInfo": [

  ],
  "customProvisions": [

  ],
  "scalableGroupExternalHandle": "ghostsurf",
  "scalableGroupType": "APPLICATION",
  "securityGroupTag": 0,
  "state": "ACTIVE",
  "vnAgnostic": true,
  "identitySource": {
    "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
    "instanceId": 10010,
    "authEntityId": 10010,
    "displayName": "10010",
    "authEntityClass": 1738953278,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "state": "INACTIVE",
    "type": "NBAR"
  },
  "indicativeNetworkIdentity": [

  ],
  "networkApplications": [
    {
      "id": "fa2a6059-e6aa-4cd1-8dfd-ec2eb7eb06a5",
      "instanceId": 11432,
      "authEntityId": 11432,
      "displayName": "11432",
      "authEntityClass": -217092956,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "applicationGroup": "other",
      "applicationType": "DEFAULT",
      "categoryId": "6daa3dfd-3526-407a-88f0-97824b3f3a24",
      "createTime": 1517966742780,
      "encrypted": "unassigned",
      "engineId": "13",
      "helpString": "DEPRECATED, traffic will not match",
      "ignoreConflict": false,
      "lastUpdateTime": 1517966742780,
      "longDescription": "GhostSurf is an Internet privacy product that makes users virtually invisible on the Internet and ensures a secure surfing experience. GhostSurf uses an advanced encryption model to encrypt the Internet connection",
      "name": "ghostsurf",
      "nbarId": "1439",
      "p2pTechnology": "unassigned",
      "popularity": 0,
      "rank": 65535,
      "references": "http://www.ghostsurf-pro.com/",
      "selectorId": "503",
      "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
      "trafficClass": "BULK_DATA",
      "tunnel": "unassigned"
    }
  ],
  "networkIdentity": [

  ],
  "parentScalableGroup": {
    "idRef": "000c15bf-28fe-4fbb-9b69-35e6502721f1"
  },
  "user": [

  ],
  "userGroup": [

  ]
},
{
  "id": "628aa282-dc36-4dab-9de9-4a43f704e575",
  "instanceId": 4334,
  "authEntityId": 4334,
  "displayName": "4334",
  "authEntityClass": -1909073334,
  "deployPending": "NONE",
  "instanceVersion": 0,
  "createTime": 1517966742316,
  "deployed": false,
  "isSeeded": true,
  "isStale": false,
  "lastUpdateTime": 1517966742316,
  "name": "dclink",
  "namespace": "scalablegroup:application",
  "provisioningState": "DEFINED",
  "qualifier": "application",
  "resourceVersion": 0,
  "targetIdList": [

  ],
  "type": "scalablegroup",
  "cfsChangeInfo": [

  ],
  "customProvisions": [

  ],
  "scalableGroupExternalHandle": "dclink",
  "scalableGroupType": "APPLICATION",
  "securityGroupTag": 0,
  "state": "ACTIVE",
  "vnAgnostic": true,
  "identitySource": {
    "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
    "instanceId": 10010,
    "authEntityId": 10010,
    "displayName": "10010",
    "authEntityClass": 1738953278,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "state": "INACTIVE",
    "type": "NBAR"
  },
  "indicativeNetworkIdentity": [
    {
      "id": "9e3c6053-68c1-4685-b943-aeb590114d9a",
      "instanceId": 12775,
      "authEntityId": 12775,
      "displayName": "12775",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "6305,6800",
      "protocol": "TCP",
      "upperPort": 0
    }
  ],
  "networkApplications": [
    {
      "id": "7842d408-ef37-4103-b64e-1f7e126527df",
      "instanceId": 11285,
      "authEntityId": 11285,
      "displayName": "11285",
      "authEntityClass": -217092956,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "appProtocol": "tcp",
      "applicationGroup": "other",
      "applicationType": "DEFAULT",
      "categoryId": "6daa3dfd-3526-407a-88f0-97824b3f3a24",
      "createTime": 1517966742316,
      "encrypted": "false",
      "engineId": "3",
      "helpString": "Automated Data Collection Solution",
      "ignoreConflict": false,
      "lastUpdateTime": 1517966742316,
      "longDescription": "dcLINK Data Collection is an inventory management software. It offers pre-built, standard or customizable workflows for processing data.",
      "name": "dclink",
      "nbarId": "1379",
      "p2pTechnology": "false",
      "popularity": 1,
      "rank": 65535,
      "references": "http://www.dsionline.com/en/DATACOLLECTION.aspx",
      "selectorId": "6305",
      "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
      "trafficClass": "BULK_DATA",
      "tunnel": "false"
    }
  ],
  "networkIdentity": [
    {
      "id": "80f3c744-ec4a-459f-8c8e-c23fad363ccc",
      "instanceId": 12774,
      "authEntityId": 12774,
      "displayName": "12774",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "6305,6800",
      "protocol": "TCP",
      "upperPort": 0
    }
  ],
  "parentScalableGroup": {
    "idRef": "000c15bf-28fe-4fbb-9b69-35e6502721f1"
  },
  "user": [

  ],
  "userGroup": [

  ]
},
{
  "id": "62984346-bcd4-46e3-98cf-4414c65081fc",
  "instanceId": 15120,
  "authEntityId": 15120,
  "displayName": "15120",
  "authEntityClass": -1909073334,
  "deployPending": "NONE",
  "instanceVersion": 0,
  "createTime": 1517966744280,
  "deployed": false,
  "isSeeded": true,
  "isStale": false,
  "lastUpdateTime": 1517966744280,
  "name": "semantix",
  "namespace": "scalablegroup:application",
  "provisioningState": "DEFINED",
  "qualifier": "application",
  "resourceVersion": 0,
  "targetIdList": [

  ],
  "type": "scalablegroup",
  "cfsChangeInfo": [

  ],
  "customProvisions": [

  ],
  "scalableGroupExternalHandle": "semantix",
  "scalableGroupType": "APPLICATION",
  "securityGroupTag": 0,
  "state": "ACTIVE",
  "vnAgnostic": true,
  "identitySource": {
    "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
    "instanceId": 10010,
    "authEntityId": 10010,
    "displayName": "10010",
    "authEntityClass": 1738953278,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "state": "INACTIVE",
    "type": "NBAR"
  },
  "indicativeNetworkIdentity": [
    {
      "id": "f857f81b-07a6-4c6e-91ee-470773c65661",
      "instanceId": 15000,
      "authEntityId": 15000,
      "displayName": "15000",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "361",
      "protocol": "UDP",
      "upperPort": 0
    },
    {
      "id": "f3214b1e-546a-48c2-8257-1a9110b70aff",
      "instanceId": 15001,
      "authEntityId": 15001,
      "displayName": "15001",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "361",
      "protocol": "TCP",
      "upperPort": 0
    }
  ],
  "networkApplications": [
    {
      "id": "263283e2-83d8-4c6a-9d8a-748d53d77b85",
      "instanceId": 16063,
      "authEntityId": 16063,
      "displayName": "16063",
      "authEntityClass": -217092956,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "appProtocol": "tcp/udp",
      "applicationGroup": "corba-group",
      "applicationType": "DEFAULT",
      "categoryId": "58aa61dc-dd95-4a6d-9c7c-0ebf16da6dde",
      "createTime": 1517966744280,
      "encrypted": "false",
      "engineId": "3",
      "helpString": "semantix",
      "ignoreConflict": false,
      "lastUpdateTime": 1517966744280,
      "longDescription": "Registered with IANA on port 361 TCP/UDP",
      "name": "semantix",
      "nbarId": "277",
      "p2pTechnology": "false",
      "popularity": 1,
      "rank": 65535,
      "references": "http://www.iana.org/assignments/service-names-port-numbers/service-names-port-numbers.xml",
      "selectorId": "361",
      "subCategory": "b90786f4-0190-45b8-9924-21b3e9682590",
      "trafficClass": "TRANSACTIONAL_DATA",
      "tunnel": "false"
    }
  ],
  "networkIdentity": [
    {
      "id": "457a78f1-baec-4ddf-a80b-41ce2d0b91b0",
      "instanceId": 14998,
      "authEntityId": 14998,
      "displayName": "14998",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "361",
      "protocol": "UDP",
      "upperPort": 0
    },
    {
      "id": "26511350-6b87-4fc2-aa94-dcc7ec863055",
      "instanceId": 14999,
      "authEntityId": 14999,
      "displayName": "14999",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "361",
      "protocol": "TCP",
      "upperPort": 0
    }
  ],
  "parentScalableGroup": {
    "idRef": "cff11752-fafb-4e22-9e6f-11f44f59a9a3"
  },
  "user": [

  ],
  "userGroup": [

  ]
},
{
  "id": "62a0dbc7-be0b-4b8e-9db5-aaefd68d06f0",
  "instanceId": 4636,
  "authEntityId": 4636,
  "displayName": "4636",
  "authEntityClass": -1909073334,
  "deployPending": "NONE",
  "instanceVersion": 0,
  "createTime": 1517966743176,
  "deployed": false,
  "isSeeded": true,
  "isStale": false,
  "lastUpdateTime": 1517966743176,
  "name": "irc",
  "namespace": "scalablegroup:application",
  "provisioningState": "DEFINED",
  "qualifier": "application",
  "resourceVersion": 0,
  "targetIdList": [

  ],
  "type": "scalablegroup",
  "cfsChangeInfo": [

  ],
  "customProvisions": [

  ],
  "scalableGroupExternalHandle": "irc",
  "scalableGroupType": "APPLICATION",
  "securityGroupTag": 0,
  "state": "ACTIVE",
  "vnAgnostic": true,
  "identitySource": {
    "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
    "instanceId": 10010,
    "authEntityId": 10010,
    "displayName": "10010",
    "authEntityClass": 1738953278,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "state": "INACTIVE",
    "type": "NBAR"
  },
  "indicativeNetworkIdentity": [
    {
      "id": "9a7c083d-1d93-40f3-8dcf-a47e9bc6c0f1",
      "instanceId": 13591,
      "authEntityId": 13591,
      "displayName": "13591",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "194",
      "protocol": "TCP",
      "upperPort": 0
    },
    {
      "id": "145936cc-914b-4b1f-bb1a-bc78f5e6922f",
      "instanceId": 13592,
      "authEntityId": 13592,
      "displayName": "13592",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "194",
      "protocol": "UDP",
      "upperPort": 0
    }
  ],
  "networkApplications": [
    {
      "id": "75d3bdd9-aa4d-4388-bfbb-6d998d485405",
      "instanceId": 11586,
      "authEntityId": 11586,
      "displayName": "11586",
      "authEntityClass": -217092956,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "appProtocol": "tcp/udp",
      "applicationGroup": "irc-group",
      "applicationType": "DEFAULT",
      "categoryId": "0f6cab47-be95-4124-9db3-360ccd55f983",
      "createTime": 1517966743176,
      "encrypted": "false",
      "engineId": "3",
      "helpString": "Internet Relay Chat",
      "ignoreConflict": false,
      "lastUpdateTime": 1517966743176,
      "longDescription": "Internet Relay Chat (IRC) protocol is used for chat messaging in real time. It can be used for conferencing or one-on-one chatting. The protocol works on client-server architecture with a distributed manner. An IRC server usually listens on TCP port 194.",
      "name": "irc",
      "nbarId": "19",
      "p2pTechnology": "false",
      "popularity": 7,
      "rank": 65535,
      "references": "http://www.irchelp.org/irchelp/rfc/rfc.html",
      "selectorId": "194",
      "subCategory": "118c10a2-e272-47c1-92b9-4814ebe97138",
      "trafficClass": "TRANSACTIONAL_DATA",
      "tunnel": "false"
    }
  ],
  "networkIdentity": [
    {
      "id": "0d0ff481-ef22-4391-b1b7-4d0e984f0d07",
      "instanceId": 13589,
      "authEntityId": 13589,
      "displayName": "13589",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "80,194",
      "protocol": "TCP",
      "upperPort": 0
    },
    {
      "id": "16b0e114-29b5-4c0b-878c-d9cddb24b106",
      "instanceId": 13590,
      "authEntityId": 13590,
      "displayName": "13590",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "194",
      "protocol": "UDP",
      "upperPort": 0
    }
  ],
  "parentScalableGroup": {
    "idRef": "fd6264c9-c16a-4311-a335-a3db039256f3"
  },
  "user": [

  ],
  "userGroup": [

  ]
},
{
  "id": "633c37c6-347d-4a29-8699-042e172fb1de",
  "instanceId": 4229,
  "authEntityId": 4229,
  "displayName": "4229",
  "authEntityClass": -1909073334,
  "deployPending": "NONE",
  "instanceVersion": 0,
  "createTime": 1517966741853,
  "deployed": false,
  "isSeeded": true,
  "isStale": false,
  "lastUpdateTime": 1517966741853,
  "name": "cisco-collab-audio",
  "namespace": "scalablegroup:application",
  "provisioningState": "DEFINED",
  "qualifier": "application",
  "resourceVersion": 0,
  "targetIdList": [

  ],
  "type": "scalablegroup",
  "cfsChangeInfo": [

  ],
  "customProvisions": [

  ],
  "scalableGroupExternalHandle": "cisco-collab-audio",
  "scalableGroupType": "APPLICATION",
  "securityGroupTag": 0,
  "state": "ACTIVE",
  "vnAgnostic": true,
  "identitySource": {
    "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
    "instanceId": 10010,
    "authEntityId": 10010,
    "displayName": "10010",
    "authEntityClass": 1738953278,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "state": "INACTIVE",
    "type": "NBAR"
  },
  "indicativeNetworkIdentity": [

  ],
  "networkApplications": [
    {
      "id": "3c118b7c-97d4-4eda-9c86-a9d324665803",
      "instanceId": 11184,
      "authEntityId": 11184,
      "displayName": "11184",
      "authEntityClass": -217092956,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "appProtocol": "tcp/udp",
      "applicationGroup": "webex-group",
      "applicationType": "DEFAULT",
      "categoryId": "38a8e354-e2fe-4f3c-b1c5-2bbb90fb0d99",
      "createTime": 1517966741853,
      "encrypted": "false",
      "engineId": "13",
      "helpString": "Cisco Collaboration Voice by various Cisco unified communication clients.",
      "ignoreConflict": false,
      "lastUpdateTime": 1517966741853,
      "longDescription": "Cisco Collaboration Voice by various Cisco unified communication clients.",
      "name": "cisco-collab-audio",
      "nbarId": "1759",
      "p2pTechnology": "false",
      "popularity": 8,
      "rank": 65535,
      "references": "http://ciscospark.com",
      "selectorId": "815",
      "subCategory": "53ce0342-1e2d-455a-acc7-1b50db9d6845",
      "trafficClass": "VOIP_TELEPHONY",
      "tunnel": "false"
    }
  ],
  "networkIdentity": [
    {
      "id": "a26dc034-f1d3-4e90-9e6b-76ac53590685",
      "instanceId": 12505,
      "authEntityId": 12505,
      "displayName": "12505",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "3478",
      "protocol": "UDP",
      "upperPort": 0
    },
    {
      "id": "bc117f3a-eb45-403c-9222-242bc2613713",
      "instanceId": 12506,
      "authEntityId": 12506,
      "displayName": "12506",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "3478",
      "protocol": "TCP",
      "upperPort": 0
    }
  ],
  "parentScalableGroup": {
    "idRef": "60f1364d-2a02-4063-8ba1-07817abe0612"
  },
  "user": [

  ],
  "userGroup": [

  ]
},
{
  "id": "6343a00f-9b46-41a1-913e-81b14e332150",
  "instanceId": 15113,
  "authEntityId": 15113,
  "displayName": "15113",
  "authEntityClass": -1909073334,
  "deployPending": "NONE",
  "instanceVersion": 0,
  "createTime": 1517966744263,
  "deployed": false,
  "isSeeded": true,
  "isStale": false,
  "lastUpdateTime": 1517966744263,
  "name": "secure-irc",
  "namespace": "scalablegroup:application",
  "provisioningState": "DEFINED",
  "qualifier": "application",
  "resourceVersion": 0,
  "targetIdList": [

  ],
  "type": "scalablegroup",
  "cfsChangeInfo": [

  ],
  "customProvisions": [

  ],
  "scalableGroupExternalHandle": "secure-irc",
  "scalableGroupType": "APPLICATION",
  "securityGroupTag": 0,
  "state": "ACTIVE",
  "vnAgnostic": true,
  "identitySource": {
    "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
    "instanceId": 10010,
    "authEntityId": 10010,
    "displayName": "10010",
    "authEntityClass": 1738953278,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "state": "INACTIVE",
    "type": "NBAR"
  },
  "indicativeNetworkIdentity": [
    {
      "id": "1a8084cb-e94f-4074-889f-6fa93e022999",
      "instanceId": 14974,
      "authEntityId": 14974,
      "displayName": "14974",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "994",
      "protocol": "TCP",
      "upperPort": 0
    },
    {
      "id": "acfb21e3-3037-4b1d-90b0-a2ff00ac7d57",
      "instanceId": 14975,
      "authEntityId": 14975,
      "displayName": "14975",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "994",
      "protocol": "UDP",
      "upperPort": 0
    }
  ],
  "networkApplications": [
    {
      "id": "1d9fd197-79bd-4141-bd23-3545c693a6b3",
      "instanceId": 16056,
      "authEntityId": 16056,
      "displayName": "16056",
      "authEntityClass": -217092956,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "appProtocol": "tcp/udp",
      "applicationGroup": "irc-group",
      "applicationType": "DEFAULT",
      "categoryId": "0f6cab47-be95-4124-9db3-360ccd55f983",
      "createTime": 1517966744263,
      "encrypted": "true",
      "engineId": "3",
      "helpString": "Secure Internet Relay Chat",
      "ignoreConflict": false,
      "lastUpdateTime": 1517966744263,
      "longDescription": "Registered with IANA on port 994 TCP/UDP",
      "name": "secure-irc",
      "nbarId": "20",
      "p2pTechnology": "false",
      "popularity": 1,
      "rank": 65535,
      "references": "http://www.iana.org/assignments/service-names-port-numbers/service-names-port-numbers.xml",
      "selectorId": "994",
      "subCategory": "118c10a2-e272-47c1-92b9-4814ebe97138",
      "trafficClass": "TRANSACTIONAL_DATA",
      "tunnel": "false"
    }
  ],
  "networkIdentity": [
    {
      "id": "68929a69-c4a4-4eb0-a2f4-f4067e622438",
      "instanceId": 14972,
      "authEntityId": 14972,
      "displayName": "14972",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "994",
      "protocol": "TCP",
      "upperPort": 0
    },
    {
      "id": "17632065-f95d-4e61-9887-b0ee156bc7ae",
      "instanceId": 14973,
      "authEntityId": 14973,
      "displayName": "14973",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "994",
      "protocol": "UDP",
      "upperPort": 0
    }
  ],
  "parentScalableGroup": {
    "idRef": "fd6264c9-c16a-4311-a335-a3db039256f3"
  },
  "user": [

  ],
  "userGroup": [

  ]
},
{
  "id": "63a38b21-90ce-462d-b347-73ce026d1e05",
  "instanceId": 4835,
  "authEntityId": 4835,
  "displayName": "4835",
  "authEntityClass": -1909073334,
  "deployPending": "NONE",
  "instanceVersion": 0,
  "createTime": 1517966743584,
  "deployed": false,
  "isSeeded": true,
  "isStale": false,
  "lastUpdateTime": 1517966743584,
  "name": "netbios-dgm",
  "namespace": "scalablegroup:application",
  "provisioningState": "DEFINED",
  "qualifier": "application",
  "resourceVersion": 0,
  "targetIdList": [

  ],
  "type": "scalablegroup",
  "cfsChangeInfo": [

  ],
  "customProvisions": [

  ],
  "scalableGroupExternalHandle": "netbios-dgm",
  "scalableGroupType": "APPLICATION",
  "securityGroupTag": 0,
  "state": "ACTIVE",
  "vnAgnostic": true,
  "identitySource": {
    "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
    "instanceId": 10010,
    "authEntityId": 10010,
    "displayName": "10010",
    "authEntityClass": 1738953278,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "state": "INACTIVE",
    "type": "NBAR"
  },
  "indicativeNetworkIdentity": [
    {
      "id": "47aa59f1-0aff-48d4-8536-b151162d49ae",
      "instanceId": 14134,
      "authEntityId": 14134,
      "displayName": "14134",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "138",
      "protocol": "TCP",
      "upperPort": 0
    },
    {
      "id": "99686a7b-7a00-40f4-be4c-b3305421e381",
      "instanceId": 14135,
      "authEntityId": 14135,
      "displayName": "14135",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "138",
      "protocol": "UDP",
      "upperPort": 0
    }
  ],
  "networkApplications": [
    {
      "id": "c3f498a3-fe8b-45c4-ba77-fa61b3ca31cf",
      "instanceId": 11784,
      "authEntityId": 11784,
      "displayName": "11784",
      "authEntityClass": -217092956,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "appProtocol": "tcp/udp",
      "applicationGroup": "netbios-group",
      "applicationType": "DEFAULT",
      "categoryId": "1fc17362-9334-4c7b-b752-5afdbb574017",
      "createTime": 1517966743584,
      "encrypted": "false",
      "engineId": "3",
      "helpString": "NETBIOS Datagram Service",
      "ignoreConflict": false,
      "lastUpdateTime": 1517966743584,
      "longDescription": "NetBIOS's datagram distribution service is the part of the NetBIOS-over-TCP/UDP protocol suite for connectionless communication.  NetBIOS provides services related to the session layer of the OSI model allowing applications on separate computers to communicate over a local area network. NetBIOS's datagram distribution service provides a connectionless service which means that the error detection and recovery are the application responsibility.",
      "name": "netbios-dgm",
      "nbarId": "1505",
      "p2pTechnology": "false",
      "popularity": 7,
      "rank": 65535,
      "references": "http://tools.ietf.org/html/rfc1002",
      "selectorId": "138",
      "subCategory": "7f5fa07d-fcb4-4217-9908-b438066c0b60",
      "trafficClass": "OPS_ADMIN_MGMT",
      "tunnel": "false"
    }
  ],
  "networkIdentity": [
    {
      "id": "e42770f6-8897-41da-99e9-e903578607e0",
      "instanceId": 14132,
      "authEntityId": 14132,
      "displayName": "14132",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "138",
      "protocol": "UDP",
      "upperPort": 0
    },
    {
      "id": "e9d85b8a-9aba-4fb7-bc90-55a829fd10df",
      "instanceId": 14133,
      "authEntityId": 14133,
      "displayName": "14133",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "138",
      "protocol": "TCP",
      "upperPort": 0
    }
  ],
  "parentScalableGroup": {
    "idRef": "38b9eb6b-f3b6-4e13-9b79-a11e0c6423d4"
  },
  "user": [

  ],
  "userGroup": [

  ]
},
{
  "id": "63afe9ad-567b-496e-94da-a1b42d3a5f92",
  "instanceId": 4642,
  "authEntityId": 4642,
  "displayName": "4642",
  "authEntityClass": -1909073334,
  "deployPending": "NONE",
  "instanceVersion": 0,
  "createTime": 1517966743189,
  "deployed": false,
  "isSeeded": true,
  "isStale": false,
  "lastUpdateTime": 1517966743189,
  "name": "isatap-ipv6-tunneled",
  "namespace": "scalablegroup:application",
  "provisioningState": "DEFINED",
  "qualifier": "application",
  "resourceVersion": 0,
  "targetIdList": [

  ],
  "type": "scalablegroup",
  "cfsChangeInfo": [

  ],
  "customProvisions": [

  ],
  "scalableGroupExternalHandle": "isatap-ipv6-tunneled",
  "scalableGroupType": "APPLICATION",
  "securityGroupTag": 0,
  "state": "ACTIVE",
  "vnAgnostic": true,
  "identitySource": {
    "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
    "instanceId": 10010,
    "authEntityId": 10010,
    "displayName": "10010",
    "authEntityClass": 1738953278,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "state": "INACTIVE",
    "type": "NBAR"
  },
  "indicativeNetworkIdentity": [

  ],
  "networkApplications": [
    {
      "id": "303a9552-1133-4fec-807b-012fee650506",
      "instanceId": 11592,
      "authEntityId": 11592,
      "displayName": "11592",
      "authEntityClass": -217092956,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "applicationGroup": "other",
      "applicationType": "DEFAULT",
      "categoryId": "70fce3cd-f2bd-48de-9020-7b7840b9e7a1",
      "createTime": 1517966743189,
      "encrypted": "false",
      "engineId": "13",
      "helpString": "Intra-Site Automatic Tunnel Addressing Protocol IPv6 over IPv4",
      "ignoreConflict": false,
      "lastUpdateTime": 1517966743189,
      "longDescription": "ISATAP is an automatic overlay tunneling mechanism that uses the underlying IPv4 network as an non-broadcast multiple access network (NBMA) link layer for IPv6. ISATAP is designed for transporting IPv6 packets within a site where a native IPv6 infrastructure is not yet available; for example, when sparse IPv6 hosts are deployed for testing. ISATAP tunnels allow individual IPv4 or IPv6 dual-stack hosts within a site to communicate with other such hosts on the same virtual link, basically creating an IPv6 network using the IPv4 infrastructure.",
      "name": "isatap-ipv6-tunneled",
      "nbarId": "1222",
      "p2pTechnology": "false",
      "popularity": 7,
      "rank": 65535,
      "references": "http://en.wikipedia.org/wiki/ISATAP",
      "selectorId": "329",
      "subCategory": "f3c0f761-d894-4112-ab89-b90fc84fd660",
      "trafficClass": "BULK_DATA",
      "tunnel": "true"
    }
  ],
  "networkIdentity": [

  ],
  "parentScalableGroup": {
    "idRef": "79f9d505-477f-4ccd-bd2a-c5fc8097a2e1"
  },
  "user": [

  ],
  "userGroup": [

  ]
},
{
  "id": "63c3ec91-ce25-4052-a153-576dfd4f8f9d",
  "instanceId": 15288,
  "authEntityId": 15288,
  "displayName": "15288",
  "authEntityClass": -1909073334,
  "deployPending": "NONE",
  "instanceVersion": 0,
  "createTime": 1517966744663,
  "deployed": false,
  "isSeeded": true,
  "isStale": false,
  "lastUpdateTime": 1517966744663,
  "name": "timed",
  "namespace": "scalablegroup:application",
  "provisioningState": "DEFINED",
  "qualifier": "application",
  "resourceVersion": 0,
  "targetIdList": [

  ],
  "type": "scalablegroup",
  "cfsChangeInfo": [

  ],
  "customProvisions": [

  ],
  "scalableGroupExternalHandle": "timed",
  "scalableGroupType": "APPLICATION",
  "securityGroupTag": 0,
  "state": "ACTIVE",
  "vnAgnostic": true,
  "identitySource": {
    "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
    "instanceId": 10010,
    "authEntityId": 10010,
    "displayName": "10010",
    "authEntityClass": 1738953278,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "state": "INACTIVE",
    "type": "NBAR"
  },
  "indicativeNetworkIdentity": [
    {
      "id": "51b75ebd-5d8c-4849-a440-ab7984958d61",
      "instanceId": 17493,
      "authEntityId": 17493,
      "displayName": "17493",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "525",
      "protocol": "UDP",
      "upperPort": 0
    },
    {
      "id": "4f746bcc-b4ab-40c5-a203-9498b08e8e97",
      "instanceId": 17494,
      "authEntityId": 17494,
      "displayName": "17494",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "525",
      "protocol": "TCP",
      "upperPort": 0
    }
  ],
  "networkApplications": [
    {
      "id": "c8d1c6a1-e0e7-4d85-b62c-7f3a47d99539",
      "instanceId": 16231,
      "authEntityId": 16231,
      "displayName": "16231",
      "authEntityClass": -217092956,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "appProtocol": "tcp/udp",
      "applicationGroup": "other",
      "applicationType": "DEFAULT",
      "categoryId": "1fc17362-9334-4c7b-b752-5afdbb574017",
      "createTime": 1517966744663,
      "encrypted": "false",
      "engineId": "3",
      "helpString": "timeserver",
      "ignoreConflict": false,
      "lastUpdateTime": 1517966744663,
      "longDescription": "A time server is a server computer that reads the actual time from a reference clock and distributes this information to its clients using a computer network. The time server may be a local network time server or an Internet time server.",
      "name": "timed",
      "nbarId": "443",
      "p2pTechnology": "false",
      "popularity": 1,
      "rank": 65535,
      "references": "http://en.wikipedia.org/wiki/Time_server",
      "selectorId": "525",
      "subCategory": "f3c0f761-d894-4112-ab89-b90fc84fd660",
      "trafficClass": "OPS_ADMIN_MGMT",
      "tunnel": "false"
    }
  ],
  "networkIdentity": [
    {
      "id": "86f7bd7e-2172-49f3-acb1-4b32bd31f0d6",
      "instanceId": 17491,
      "authEntityId": 17491,
      "displayName": "17491",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "525",
      "protocol": "TCP",
      "upperPort": 0
    },
    {
      "id": "d18318a0-593c-48c3-80ba-1e5ca394a110",
      "instanceId": 17492,
      "authEntityId": 17492,
      "displayName": "17492",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "525",
      "protocol": "UDP",
      "upperPort": 0
    }
  ],
  "parentScalableGroup": {
    "idRef": "5fcd74d4-8726-47f7-a088-c457863da7ef"
  },
  "user": [

  ],
  "userGroup": [

  ]
},
{
  "id": "63ca848f-8766-4eb3-9c55-dde7c9cc3229",
  "instanceId": 4066,
  "authEntityId": 4066,
  "displayName": "4066",
  "authEntityClass": -1909073334,
  "deployPending": "NONE",
  "instanceVersion": 0,
  "createTime": 1517966740746,
  "deployed": false,
  "isSeeded": true,
  "isStale": false,
  "lastUpdateTime": 1517966740746,
  "name": "afpovertcp",
  "namespace": "scalablegroup:application",
  "provisioningState": "DEFINED",
  "qualifier": "application",
  "resourceVersion": 0,
  "targetIdList": [

  ],
  "type": "scalablegroup",
  "cfsChangeInfo": [

  ],
  "customProvisions": [

  ],
  "scalableGroupExternalHandle": "afpovertcp",
  "scalableGroupType": "APPLICATION",
  "securityGroupTag": 0,
  "state": "ACTIVE",
  "vnAgnostic": true,
  "identitySource": {
    "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
    "instanceId": 10010,
    "authEntityId": 10010,
    "displayName": "10010",
    "authEntityClass": 1738953278,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "state": "INACTIVE",
    "type": "NBAR"
  },
  "indicativeNetworkIdentity": [
    {
      "id": "47e9c6d9-8910-4c9b-94e6-5776e8929f4d",
      "instanceId": 12087,
      "authEntityId": 12087,
      "displayName": "12087",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "548",
      "protocol": "TCP",
      "upperPort": 0
    },
    {
      "id": "4151b485-7e76-4acf-8a54-211444ef76e1",
      "instanceId": 12088,
      "authEntityId": 12088,
      "displayName": "12088",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "548",
      "protocol": "UDP",
      "upperPort": 0
    }
  ],
  "networkApplications": [
    {
      "id": "022b678a-e1a7-4894-bf40-6fc64e984efd",
      "instanceId": 11035,
      "authEntityId": 11035,
      "displayName": "11035",
      "authEntityClass": -217092956,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "appProtocol": "tcp/udp",
      "applicationGroup": "other",
      "applicationType": "DEFAULT",
      "categoryId": "80909d4c-efe6-4878-9e1e-68281fd26edd",
      "createTime": 1517966740746,
      "encrypted": "false",
      "engineId": "3",
      "helpString": "AFP over TCP",
      "ignoreConflict": false,
      "lastUpdateTime": 1517966740746,
      "longDescription": "Apple Filing Protocol (AFP) is a proprietary network protocol that offers file services for Mac OS X and original Mac OS.",
      "name": "afpovertcp",
      "nbarId": "1327",
      "p2pTechnology": "false",
      "popularity": 1,
      "rank": 65535,
      "references": "https://developer.apple.com/library/mac/#documentation/Networking/Conceptual/AFP/Introduction/Introduction.html",
      "selectorId": "548",
      "subCategory": "4a197877-986d-4422-b48f-500fb4b3340f",
      "trafficClass": "BULK_DATA",
      "tunnel": "false"
    }
  ],
  "networkIdentity": [
    {
      "id": "76de4077-a0b3-44db-9847-d8b742434793",
      "instanceId": 12085,
      "authEntityId": 12085,
      "displayName": "12085",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "548",
      "protocol": "UDP",
      "upperPort": 0
    },
    {
      "id": "f265c78b-77f7-4745-bedb-e93d63992940",
      "instanceId": 12086,
      "authEntityId": 12086,
      "displayName": "12086",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "548",
      "protocol": "TCP",
      "upperPort": 0
    }
  ],
  "parentScalableGroup": {
    "idRef": "f09a36de-df38-4ff8-b0be-56738afc75f7"
  },
  "user": [

  ],
  "userGroup": [

  ]
},
{
  "id": "63decabb-c188-427a-9b53-90d83bade5d3",
  "instanceId": 4981,
  "authEntityId": 4981,
  "displayName": "4981",
  "authEntityClass": -1909073334,
  "deployPending": "NONE",
  "instanceVersion": 0,
  "createTime": 1517966743965,
  "deployed": false,
  "isSeeded": true,
  "isStale": false,
  "lastUpdateTime": 1517966743965,
  "name": "prezi",
  "namespace": "scalablegroup:application",
  "provisioningState": "DEFINED",
  "qualifier": "application",
  "resourceVersion": 0,
  "targetIdList": [

  ],
  "type": "scalablegroup",
  "cfsChangeInfo": [

  ],
  "customProvisions": [

  ],
  "scalableGroupExternalHandle": "prezi",
  "scalableGroupType": "APPLICATION",
  "securityGroupTag": 0,
  "state": "ACTIVE",
  "vnAgnostic": true,
  "identitySource": {
    "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
    "instanceId": 10010,
    "authEntityId": 10010,
    "displayName": "10010",
    "authEntityClass": 1738953278,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "state": "INACTIVE",
    "type": "NBAR"
  },
  "indicativeNetworkIdentity": [

  ],
  "networkApplications": [
    {
      "id": "238b193b-b507-4e7f-b0c8-f08fa69f659b",
      "instanceId": 11930,
      "authEntityId": 11930,
      "displayName": "11930",
      "authEntityClass": -217092956,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "appProtocol": "tcp/udp",
      "applicationGroup": "other",
      "applicationType": "DEFAULT",
      "categoryId": "878644b5-fe91-45b4-9204-31c84a73ba48",
      "createTime": 1517966743965,
      "encrypted": "false",
      "engineId": "13",
      "helpString": "Prezi - Presentation tool.",
      "ignoreConflict": false,
      "lastUpdateTime": 1517966743965,
      "longDescription": "Prezi - Presentation tool.",
      "name": "prezi",
      "nbarId": "263",
      "p2pTechnology": "false",
      "popularity": 3,
      "rank": 65535,
      "references": "http://prezi.com",
      "selectorId": "536",
      "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
      "trafficClass": "TRANSACTIONAL_DATA",
      "tunnel": "false"
    }
  ],
  "networkIdentity": [
    {
      "id": "b183de2a-dda8-4d43-a8b6-b661f0111662",
      "instanceId": 14594,
      "authEntityId": 14594,
      "displayName": "14594",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "53,80,443,5353",
      "protocol": "TCP",
      "upperPort": 0
    },
    {
      "id": "909dc7ac-4719-4553-bf91-ca39f9029347",
      "instanceId": 14595,
      "authEntityId": 14595,
      "displayName": "14595",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "53,5353",
      "protocol": "UDP",
      "upperPort": 0
    }
  ],
  "parentScalableGroup": {
    "idRef": "28e08de4-d7b2-4521-84ce-bc1e5ee0b9cc"
  },
  "user": [

  ],
  "userGroup": [

  ]
},
{
  "id": "63f57c7c-db58-411d-b36e-dc2015a11eb8",
  "instanceId": 4376,
  "authEntityId": 4376,
  "displayName": "4376",
  "authEntityClass": -1909073334,
  "deployPending": "NONE",
  "instanceVersion": 0,
  "createTime": 1517966742452,
  "deployed": false,
  "isSeeded": true,
  "isStale": false,
  "lastUpdateTime": 1517966742452,
  "name": "dls-mon",
  "namespace": "scalablegroup:application",
  "provisioningState": "DEFINED",
  "qualifier": "application",
  "resourceVersion": 0,
  "targetIdList": [

  ],
  "type": "scalablegroup",
  "cfsChangeInfo": [

  ],
  "customProvisions": [

  ],
  "scalableGroupExternalHandle": "dls-mon",
  "scalableGroupType": "APPLICATION",
  "securityGroupTag": 0,
  "state": "ACTIVE",
  "vnAgnostic": true,
  "identitySource": {
    "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
    "instanceId": 10010,
    "authEntityId": 10010,
    "displayName": "10010",
    "authEntityClass": 1738953278,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "state": "INACTIVE",
    "type": "NBAR"
  },
  "indicativeNetworkIdentity": [
    {
      "id": "99dbb7c7-2d54-475a-93ef-143f80563b41",
      "instanceId": 12917,
      "authEntityId": 12917,
      "displayName": "12917",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "198",
      "protocol": "UDP",
      "upperPort": 0
    },
    {
      "id": "0f45eb6d-7551-4de5-9b83-61e01aa9cd9f",
      "instanceId": 12918,
      "authEntityId": 12918,
      "displayName": "12918",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "198",
      "protocol": "TCP",
      "upperPort": 0
    }
  ],
  "networkApplications": [
    {
      "id": "c45c603a-490b-496e-b7c4-aa39ce37680b",
      "instanceId": 11327,
      "authEntityId": 11327,
      "displayName": "11327",
      "authEntityClass": -217092956,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "appProtocol": "tcp/udp",
      "applicationGroup": "other",
      "applicationType": "DEFAULT",
      "categoryId": "6daa3dfd-3526-407a-88f0-97824b3f3a24",
      "createTime": 1517966742452,
      "encrypted": "false",
      "engineId": "3",
      "helpString": "Directory Location Service Monitor",
      "ignoreConflict": false,
      "lastUpdateTime": 1517966742452,
      "longDescription": "Registered with IANA on port 198 TCP/UDP",
      "name": "dls-mon",
      "nbarId": "1085",
      "p2pTechnology": "false",
      "popularity": 1,
      "rank": 65535,
      "references": "http://www.iana.org/assignments/service-names-port-numbers/service-names-port-numbers.xml",
      "selectorId": "198",
      "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
      "trafficClass": "BULK_DATA",
      "tunnel": "false"
    }
  ],
  "networkIdentity": [
    {
      "id": "6a00d66a-3be6-40f8-a6eb-ade5f7e67024",
      "instanceId": 12915,
      "authEntityId": 12915,
      "displayName": "12915",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "198",
      "protocol": "UDP",
      "upperPort": 0
    },
    {
      "id": "76f43542-51fc-43b5-bb60-1dd5cdf91b04",
      "instanceId": 12916,
      "authEntityId": 12916,
      "displayName": "12916",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "198",
      "protocol": "TCP",
      "upperPort": 0
    }
  ],
  "parentScalableGroup": {
    "idRef": "000c15bf-28fe-4fbb-9b69-35e6502721f1"
  },
  "user": [

  ],
  "userGroup": [

  ]
},
{
  "id": "643956f2-a22d-45a2-9f30-e438813005cb",
  "instanceId": 4388,
  "authEntityId": 4388,
  "displayName": "4388",
  "authEntityClass": -1909073334,
  "deployPending": "NONE",
  "instanceVersion": 0,
  "createTime": 1517966742497,
  "deployed": false,
  "isSeeded": true,
  "isStale": false,
  "lastUpdateTime": 1517966742497,
  "name": "dsfgw",
  "namespace": "scalablegroup:application",
  "provisioningState": "DEFINED",
  "qualifier": "application",
  "resourceVersion": 0,
  "targetIdList": [

  ],
  "type": "scalablegroup",
  "cfsChangeInfo": [

  ],
  "customProvisions": [

  ],
  "scalableGroupExternalHandle": "dsfgw",
  "scalableGroupType": "APPLICATION",
  "securityGroupTag": 0,
  "state": "ACTIVE",
  "vnAgnostic": true,
  "identitySource": {
    "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
    "instanceId": 10010,
    "authEntityId": 10010,
    "displayName": "10010",
    "authEntityClass": 1738953278,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "state": "INACTIVE",
    "type": "NBAR"
  },
  "indicativeNetworkIdentity": [
    {
      "id": "eb97facd-952e-49d8-bd85-31158cc91d19",
      "instanceId": 12958,
      "authEntityId": 12958,
      "displayName": "12958",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "438",
      "protocol": "TCP",
      "upperPort": 0
    },
    {
      "id": "e7ece84d-0026-4bf2-a5e8-d36f0ab6f104",
      "instanceId": 12959,
      "authEntityId": 12959,
      "displayName": "12959",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "438",
      "protocol": "UDP",
      "upperPort": 0
    }
  ],
  "networkApplications": [
    {
      "id": "4f22cafd-c314-405b-8696-571ea46c627e",
      "instanceId": 11339,
      "authEntityId": 11339,
      "displayName": "11339",
      "authEntityClass": -217092956,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "appProtocol": "tcp/udp",
      "applicationGroup": "other",
      "applicationType": "DEFAULT",
      "categoryId": "6daa3dfd-3526-407a-88f0-97824b3f3a24",
      "createTime": 1517966742497,
      "encrypted": "false",
      "engineId": "3",
      "helpString": "dsfgw",
      "ignoreConflict": false,
      "lastUpdateTime": 1517966742497,
      "longDescription": "Registered with IANA on port 438 TCP/UDP",
      "name": "dsfgw",
      "nbarId": "353",
      "p2pTechnology": "false",
      "popularity": 1,
      "rank": 65535,
      "references": "http://www.iana.org/assignments/service-names-port-numbers/service-names-port-numbers.xml",
      "selectorId": "438",
      "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
      "trafficClass": "BULK_DATA",
      "tunnel": "false"
    }
  ],
  "networkIdentity": [
    {
      "id": "fc269b5b-9cc0-41c9-a7af-a09daa029433",
      "instanceId": 12956,
      "authEntityId": 12956,
      "displayName": "12956",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "438",
      "protocol": "TCP",
      "upperPort": 0
    },
    {
      "id": "4aee04f9-1494-48dd-929e-ad2ae6cfb029",
      "instanceId": 12957,
      "authEntityId": 12957,
      "displayName": "12957",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "438",
      "protocol": "UDP",
      "upperPort": 0
    }
  ],
  "parentScalableGroup": {
    "idRef": "000c15bf-28fe-4fbb-9b69-35e6502721f1"
  },
  "user": [

  ],
  "userGroup": [

  ]
},
{
  "id": "645f274e-a026-4edb-8f0e-a013f37d546e",
  "instanceId": 4420,
  "authEntityId": 4420,
  "displayName": "4420",
  "authEntityClass": -1909073334,
  "deployPending": "NONE",
  "instanceVersion": 0,
  "createTime": 1517966742595,
  "deployed": false,
  "isSeeded": true,
  "isStale": false,
  "lastUpdateTime": 1517966742595,
  "name": "erpc",
  "namespace": "scalablegroup:application",
  "provisioningState": "DEFINED",
  "qualifier": "application",
  "resourceVersion": 0,
  "targetIdList": [

  ],
  "type": "scalablegroup",
  "cfsChangeInfo": [

  ],
  "customProvisions": [

  ],
  "scalableGroupExternalHandle": "erpc",
  "scalableGroupType": "APPLICATION",
  "securityGroupTag": 0,
  "state": "ACTIVE",
  "vnAgnostic": true,
  "identitySource": {
    "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
    "instanceId": 10010,
    "authEntityId": 10010,
    "displayName": "10010",
    "authEntityClass": 1738953278,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "state": "INACTIVE",
    "type": "NBAR"
  },
  "indicativeNetworkIdentity": [
    {
      "id": "199c18f3-f943-442e-9dc5-15d46ad610a6",
      "instanceId": 13058,
      "authEntityId": 13058,
      "displayName": "13058",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "121",
      "protocol": "UDP",
      "upperPort": 0
    },
    {
      "id": "33bcc4d5-71f6-4599-82f3-0b8111c33efd",
      "instanceId": 13059,
      "authEntityId": 13059,
      "displayName": "13059",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "121",
      "protocol": "TCP",
      "upperPort": 0
    }
  ],
  "networkApplications": [
    {
      "id": "a13ff569-0837-47bb-a551-88999c7a83b3",
      "instanceId": 11371,
      "authEntityId": 11371,
      "displayName": "11371",
      "authEntityClass": -217092956,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "appProtocol": "tcp/udp",
      "applicationGroup": "other",
      "applicationType": "DEFAULT",
      "categoryId": "58aa61dc-dd95-4a6d-9c7c-0ebf16da6dde",
      "createTime": 1517966742595,
      "encrypted": "false",
      "engineId": "3",
      "helpString": "Encore Expedited Remote Pro.Call",
      "ignoreConflict": false,
      "lastUpdateTime": 1517966742595,
      "longDescription": "Registered with IANA on port 121 TCP/UDP",
      "name": "erpc",
      "nbarId": "990",
      "p2pTechnology": "false",
      "popularity": 1,
      "rank": 65535,
      "references": "http://www.iana.org/assignments/service-names-port-numbers/service-names-port-numbers.xml",
      "selectorId": "121",
      "subCategory": "b90786f4-0190-45b8-9924-21b3e9682590",
      "trafficClass": "TRANSACTIONAL_DATA",
      "tunnel": "false"
    }
  ],
  "networkIdentity": [
    {
      "id": "904031f2-c42c-4e92-95ac-f098b4a32b07",
      "instanceId": 13056,
      "authEntityId": 13056,
      "displayName": "13056",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "121",
      "protocol": "TCP",
      "upperPort": 0
    },
    {
      "id": "12cc8ee6-9426-4a0c-bf16-8dcd2cfc7207",
      "instanceId": 13057,
      "authEntityId": 13057,
      "displayName": "13057",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "121",
      "protocol": "UDP",
      "upperPort": 0
    }
  ],
  "parentScalableGroup": {
    "idRef": "cff11752-fafb-4e22-9e6f-11f44f59a9a3"
  },
  "user": [

  ],
  "userGroup": [

  ]
},
{
  "id": "646996e9-8113-41c7-ba96-28e9d4070981",
  "instanceId": 4224,
  "authEntityId": 4224,
  "displayName": "4224",
  "authEntityClass": -1909073334,
  "deployPending": "NONE",
  "instanceVersion": 0,
  "createTime": 1517966741827,
  "deployed": false,
  "isSeeded": true,
  "isStale": false,
  "lastUpdateTime": 1517966741827,
  "name": "chase-bank",
  "namespace": "scalablegroup:application",
  "provisioningState": "DEFINED",
  "qualifier": "application",
  "resourceVersion": 0,
  "targetIdList": [

  ],
  "type": "scalablegroup",
  "cfsChangeInfo": [

  ],
  "customProvisions": [

  ],
  "scalableGroupExternalHandle": "chase-bank",
  "scalableGroupType": "APPLICATION",
  "securityGroupTag": 0,
  "state": "ACTIVE",
  "vnAgnostic": true,
  "identitySource": {
    "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
    "instanceId": 10010,
    "authEntityId": 10010,
    "displayName": "10010",
    "authEntityClass": 1738953278,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "state": "INACTIVE",
    "type": "NBAR"
  },
  "indicativeNetworkIdentity": [

  ],
  "networkApplications": [
    {
      "id": "9dedce79-6d4d-4168-aee2-1612ffda6f2e",
      "instanceId": 11179,
      "authEntityId": 11179,
      "displayName": "11179",
      "authEntityClass": -217092956,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "appProtocol": "tcp/udp",
      "applicationGroup": "other",
      "applicationType": "DEFAULT",
      "categoryId": "878644b5-fe91-45b4-9204-31c84a73ba48",
      "createTime": 1517966741827,
      "encrypted": "false",
      "engineId": "13",
      "helpString": "Chase - Consumer and commercial banking company.",
      "ignoreConflict": false,
      "lastUpdateTime": 1517966741827,
      "longDescription": "Chase - Consumer and commercial banking company.",
      "name": "chase-bank",
      "nbarId": "1564",
      "p2pTechnology": "false",
      "popularity": 3,
      "rank": 65535,
      "references": "http://chase.com",
      "selectorId": "635",
      "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
      "trafficClass": "TRANSACTIONAL_DATA",
      "tunnel": "false"
    }
  ],
  "networkIdentity": [
    {
      "id": "ef5f0744-1fe6-4e68-9223-578675667ddd",
      "instanceId": 12491,
      "authEntityId": 12491,
      "displayName": "12491",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "53,80,443,5353",
      "protocol": "TCP",
      "upperPort": 0
    },
    {
      "id": "3792fb54-283c-437e-8b64-0b62b3aa9ceb",
      "instanceId": 12492,
      "authEntityId": 12492,
      "displayName": "12492",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "53,5353",
      "protocol": "UDP",
      "upperPort": 0
    }
  ],
  "parentScalableGroup": {
    "idRef": "28e08de4-d7b2-4521-84ce-bc1e5ee0b9cc"
  },
  "user": [

  ],
  "userGroup": [

  ]
},
{
  "id": "646d4766-3387-4b9f-b913-a013680d6622",
  "instanceId": 4397,
  "authEntityId": 4397,
  "displayName": "4397",
  "authEntityClass": -1909073334,
  "deployPending": "NONE",
  "instanceVersion": 0,
  "createTime": 1517966742533,
  "deployed": false,
  "isSeeded": true,
  "isStale": false,
  "lastUpdateTime": 1517966742533,
  "name": "echo",
  "namespace": "scalablegroup:application",
  "provisioningState": "DEFINED",
  "qualifier": "application",
  "resourceVersion": 0,
  "targetIdList": [

  ],
  "type": "scalablegroup",
  "cfsChangeInfo": [

  ],
  "customProvisions": [

  ],
  "scalableGroupExternalHandle": "echo",
  "scalableGroupType": "APPLICATION",
  "securityGroupTag": 0,
  "state": "ACTIVE",
  "vnAgnostic": true,
  "identitySource": {
    "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
    "instanceId": 10010,
    "authEntityId": 10010,
    "displayName": "10010",
    "authEntityClass": 1738953278,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "state": "INACTIVE",
    "type": "NBAR"
  },
  "indicativeNetworkIdentity": [
    {
      "id": "c1c4bd8f-5aee-4cad-b71e-8b5c127293b4",
      "instanceId": 12987,
      "authEntityId": 12987,
      "displayName": "12987",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "7",
      "protocol": "UDP",
      "upperPort": 0
    },
    {
      "id": "7f3058ff-ede8-4419-ab53-e7339895bd36",
      "instanceId": 12988,
      "authEntityId": 12988,
      "displayName": "12988",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "7",
      "protocol": "TCP",
      "upperPort": 0
    }
  ],
  "networkApplications": [
    {
      "id": "c03d2c63-cd02-418c-815b-14653b111d8f",
      "instanceId": 11348,
      "authEntityId": 11348,
      "displayName": "11348",
      "authEntityClass": -217092956,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "appProtocol": "tcp/udp",
      "applicationGroup": "other",
      "applicationType": "DEFAULT",
      "categoryId": "1fc17362-9334-4c7b-b752-5afdbb574017",
      "createTime": 1517966742533,
      "encrypted": "false",
      "engineId": "3",
      "helpString": "Echo debugging and measurement tool",
      "ignoreConflict": false,
      "lastUpdateTime": 1517966742533,
      "longDescription": "Echo is a protocol that is used for debugging and measurement. It works by sending back all the data that was received from the source. The protocol works on TCP and UDP, typically on port 7.",
      "name": "echo",
      "nbarId": "101",
      "p2pTechnology": "false",
      "popularity": 4,
      "rank": 65535,
      "references": "http://www.faqs.org/rfcs/rfc862.html",
      "selectorId": "7",
      "subCategory": "f3c0f761-d894-4112-ab89-b90fc84fd660",
      "trafficClass": "OPS_ADMIN_MGMT",
      "tunnel": "false"
    }
  ],
  "networkIdentity": [
    {
      "id": "4f7ea672-52c2-4622-babd-f6cca7054f7f",
      "instanceId": 12985,
      "authEntityId": 12985,
      "displayName": "12985",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "7",
      "protocol": "TCP",
      "upperPort": 0
    },
    {
      "id": "640d34a3-b378-4f71-85b9-42c128d6df8e",
      "instanceId": 12986,
      "authEntityId": 12986,
      "displayName": "12986",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "7",
      "protocol": "UDP",
      "upperPort": 0
    }
  ],
  "parentScalableGroup": {
    "idRef": "5fcd74d4-8726-47f7-a088-c457863da7ef"
  },
  "user": [

  ],
  "userGroup": [

  ]
},
{
  "id": "650acb7b-662a-491c-894f-918eb02a4f99",
  "instanceId": 15153,
  "authEntityId": 15153,
  "displayName": "15153",
  "authEntityClass": -1909073334,
  "deployPending": "NONE",
  "instanceVersion": 0,
  "createTime": 1517966744355,
  "deployed": false,
  "isSeeded": true,
  "isStale": false,
  "lastUpdateTime": 1517966744355,
  "name": "skronk",
  "namespace": "scalablegroup:application",
  "provisioningState": "DEFINED",
  "qualifier": "application",
  "resourceVersion": 0,
  "targetIdList": [

  ],
  "type": "scalablegroup",
  "cfsChangeInfo": [

  ],
  "customProvisions": [

  ],
  "scalableGroupExternalHandle": "skronk",
  "scalableGroupType": "APPLICATION",
  "securityGroupTag": 0,
  "state": "ACTIVE",
  "vnAgnostic": true,
  "identitySource": {
    "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
    "instanceId": 10010,
    "authEntityId": 10010,
    "displayName": "10010",
    "authEntityClass": 1738953278,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "state": "INACTIVE",
    "type": "NBAR"
  },
  "indicativeNetworkIdentity": [
    {
      "id": "bb6e7382-e978-46e0-8c26-977e17688fb0",
      "instanceId": 17108,
      "authEntityId": 17108,
      "displayName": "17108",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "460",
      "protocol": "TCP",
      "upperPort": 0
    },
    {
      "id": "18d364d7-ea0f-428e-ae70-8325713397e6",
      "instanceId": 17109,
      "authEntityId": 17109,
      "displayName": "17109",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "460",
      "protocol": "UDP",
      "upperPort": 0
    }
  ],
  "networkApplications": [
    {
      "id": "82306b19-d035-4199-8cdd-6be57582fbbb",
      "instanceId": 16096,
      "authEntityId": 16096,
      "displayName": "16096",
      "authEntityClass": -217092956,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "appProtocol": "tcp/udp",
      "applicationGroup": "other",
      "applicationType": "DEFAULT",
      "categoryId": "6daa3dfd-3526-407a-88f0-97824b3f3a24",
      "createTime": 1517966744355,
      "encrypted": "false",
      "engineId": "3",
      "helpString": "skronk",
      "ignoreConflict": false,
      "lastUpdateTime": 1517966744355,
      "longDescription": "Registered with IANA on port 460 TCP/UDP",
      "name": "skronk",
      "nbarId": "374",
      "p2pTechnology": "false",
      "popularity": 1,
      "rank": 65535,
      "references": "http://www.iana.org/assignments/service-names-port-numbers/service-names-port-numbers.xml",
      "selectorId": "460",
      "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
      "trafficClass": "BULK_DATA",
      "tunnel": "false"
    }
  ],
  "networkIdentity": [
    {
      "id": "33ef26a9-43f5-4218-a868-1a72540b7ab2",
      "instanceId": 17106,
      "authEntityId": 17106,
      "displayName": "17106",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "460",
      "protocol": "TCP",
      "upperPort": 0
    },
    {
      "id": "feeb3772-3274-4153-bbf5-1f6a51831a29",
      "instanceId": 17107,
      "authEntityId": 17107,
      "displayName": "17107",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "460",
      "protocol": "UDP",
      "upperPort": 0
    }
  ],
  "parentScalableGroup": {
    "idRef": "000c15bf-28fe-4fbb-9b69-35e6502721f1"
  },
  "user": [

  ],
  "userGroup": [

  ]
},
{
  "id": "656fac56-edbb-4d66-bcf7-4eba2e791621",
  "instanceId": 15132,
  "authEntityId": 15132,
  "displayName": "15132",
  "authEntityClass": -1909073334,
  "deployPending": "NONE",
  "instanceVersion": 0,
  "createTime": 1517966744307,
  "deployed": false,
  "isSeeded": true,
  "isStale": false,
  "lastUpdateTime": 1517966744307,
  "name": "sgmp-traps",
  "namespace": "scalablegroup:application",
  "provisioningState": "DEFINED",
  "qualifier": "application",
  "resourceVersion": 0,
  "targetIdList": [

  ],
  "type": "scalablegroup",
  "cfsChangeInfo": [

  ],
  "customProvisions": [

  ],
  "scalableGroupExternalHandle": "sgmp-traps",
  "scalableGroupType": "APPLICATION",
  "securityGroupTag": 0,
  "state": "ACTIVE",
  "vnAgnostic": true,
  "identitySource": {
    "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
    "instanceId": 10010,
    "authEntityId": 10010,
    "displayName": "10010",
    "authEntityClass": 1738953278,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "state": "INACTIVE",
    "type": "NBAR"
  },
  "indicativeNetworkIdentity": [
    {
      "id": "f15b1ef1-c41d-43af-805f-1539e1dc32a4",
      "instanceId": 17048,
      "authEntityId": 17048,
      "displayName": "17048",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "160",
      "protocol": "TCP",
      "upperPort": 0
    },
    {
      "id": "3e30942e-cc1d-44b9-ac84-37dde6a5dbd2",
      "instanceId": 17049,
      "authEntityId": 17049,
      "displayName": "17049",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "160",
      "protocol": "UDP",
      "upperPort": 0
    }
  ],
  "networkApplications": [
    {
      "id": "2ff26911-7957-4efe-9020-d22c59d1fa86",
      "instanceId": 16075,
      "authEntityId": 16075,
      "displayName": "16075",
      "authEntityClass": -217092956,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "appProtocol": "tcp/udp",
      "applicationGroup": "other",
      "applicationType": "DEFAULT",
      "categoryId": "1fc17362-9334-4c7b-b752-5afdbb574017",
      "createTime": 1517966744307,
      "encrypted": "false",
      "engineId": "3",
      "helpString": "sgmp-traps",
      "ignoreConflict": false,
      "lastUpdateTime": 1517966744307,
      "longDescription": "SGMP-TRAPS, Registered with IANA on port 160 TCP/UDP",
      "name": "sgmp-traps",
      "nbarId": "1006",
      "p2pTechnology": "false",
      "popularity": 1,
      "rank": 65535,
      "references": "http://www.iana.org/assignments/service-names-port-numbers/service-names-port-numbers.xml",
      "selectorId": "160",
      "subCategory": "f3c0f761-d894-4112-ab89-b90fc84fd660",
      "trafficClass": "OPS_ADMIN_MGMT",
      "tunnel": "false"
    }
  ],
  "networkIdentity": [
    {
      "id": "26305141-a16a-4715-8971-7b3a4b954d03",
      "instanceId": 17046,
      "authEntityId": 17046,
      "displayName": "17046",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "160",
      "protocol": "TCP",
      "upperPort": 0
    },
    {
      "id": "5a1d904f-12f6-4e31-8959-a0e70f9eff5d",
      "instanceId": 17047,
      "authEntityId": 17047,
      "displayName": "17047",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "160",
      "protocol": "UDP",
      "upperPort": 0
    }
  ],
  "parentScalableGroup": {
    "idRef": "5fcd74d4-8726-47f7-a088-c457863da7ef"
  },
  "user": [

  ],
  "userGroup": [

  ]
},
{
  "id": "6580714f-e4f8-48e5-962f-3546a1ce3dfe",
  "instanceId": 4393,
  "authEntityId": 4393,
  "displayName": "4393",
  "authEntityClass": -1909073334,
  "deployPending": "NONE",
  "instanceVersion": 0,
  "createTime": 1517966742522,
  "deployed": false,
  "isSeeded": true,
  "isStale": false,
  "lastUpdateTime": 1517966742522,
  "name": "dtk",
  "namespace": "scalablegroup:application",
  "provisioningState": "DEFINED",
  "qualifier": "application",
  "resourceVersion": 0,
  "targetIdList": [

  ],
  "type": "scalablegroup",
  "cfsChangeInfo": [

  ],
  "customProvisions": [

  ],
  "scalableGroupExternalHandle": "dtk",
  "scalableGroupType": "APPLICATION",
  "securityGroupTag": 0,
  "state": "ACTIVE",
  "vnAgnostic": true,
  "identitySource": {
    "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
    "instanceId": 10010,
    "authEntityId": 10010,
    "displayName": "10010",
    "authEntityClass": 1738953278,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "state": "INACTIVE",
    "type": "NBAR"
  },
  "indicativeNetworkIdentity": [
    {
      "id": "5ab49015-381d-43cc-8910-ae0e0a9a2aba",
      "instanceId": 12976,
      "authEntityId": 12976,
      "displayName": "12976",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "365",
      "protocol": "TCP",
      "upperPort": 0
    },
    {
      "id": "29c4d8b6-4fa1-4a26-bc72-5f1fb24e066f",
      "instanceId": 12977,
      "authEntityId": 12977,
      "displayName": "12977",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "365",
      "protocol": "UDP",
      "upperPort": 0
    }
  ],
  "networkApplications": [
    {
      "id": "ced3b121-63ba-40b3-a53d-655d61ecf63f",
      "instanceId": 11344,
      "authEntityId": 11344,
      "displayName": "11344",
      "authEntityClass": -217092956,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "appProtocol": "tcp/udp",
      "applicationGroup": "other",
      "applicationType": "DEFAULT",
      "categoryId": "6daa3dfd-3526-407a-88f0-97824b3f3a24",
      "createTime": 1517966742522,
      "encrypted": "false",
      "engineId": "3",
      "helpString": "dtk",
      "ignoreConflict": false,
      "lastUpdateTime": 1517966742522,
      "longDescription": "The Deception ToolKit (DTK) is a toolkit designed to give defenders a couple of orders of magnitude advantage over attackers. In DTK, the deception is intended to make it appear to attackers as if the system running DTK has a large number of widely known vulnerabilities.",
      "name": "dtk",
      "nbarId": "281",
      "p2pTechnology": "false",
      "popularity": 1,
      "rank": 65535,
      "references": "http://all.net/dtk/index.html",
      "selectorId": "365",
      "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
      "trafficClass": "BULK_DATA",
      "tunnel": "false"
    }
  ],
  "networkIdentity": [
    {
      "id": "c745a334-6ebd-48a1-ae97-31348ac82918",
      "instanceId": 12974,
      "authEntityId": 12974,
      "displayName": "12974",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "365",
      "protocol": "TCP",
      "upperPort": 0
    },
    {
      "id": "1d370257-ec2b-4ec5-b213-3c3cccbe5550",
      "instanceId": 12975,
      "authEntityId": 12975,
      "displayName": "12975",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "365",
      "protocol": "UDP",
      "upperPort": 0
    }
  ],
  "parentScalableGroup": {
    "idRef": "000c15bf-28fe-4fbb-9b69-35e6502721f1"
  },
  "user": [

  ],
  "userGroup": [

  ]
},
{
  "id": "65a94129-b600-4848-8f5e-c86644fec8a9",
  "instanceId": 4315,
  "authEntityId": 4315,
  "displayName": "4315",
  "authEntityClass": -1909073334,
  "deployPending": "NONE",
  "instanceVersion": 0,
  "createTime": 1517966742258,
  "deployed": false,
  "isSeeded": true,
  "isStale": false,
  "lastUpdateTime": 1517966742258,
  "name": "cvspserver",
  "namespace": "scalablegroup:application",
  "provisioningState": "DEFINED",
  "qualifier": "application",
  "resourceVersion": 0,
  "targetIdList": [

  ],
  "type": "scalablegroup",
  "cfsChangeInfo": [

  ],
  "customProvisions": [

  ],
  "scalableGroupExternalHandle": "cvspserver",
  "scalableGroupType": "APPLICATION",
  "securityGroupTag": 0,
  "state": "ACTIVE",
  "vnAgnostic": true,
  "identitySource": {
    "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
    "instanceId": 10010,
    "authEntityId": 10010,
    "displayName": "10010",
    "authEntityClass": 1738953278,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "state": "INACTIVE",
    "type": "NBAR"
  },
  "indicativeNetworkIdentity": [
    {
      "id": "a166871f-e221-49da-b112-eddbb1c17c18",
      "instanceId": 12720,
      "authEntityId": 12720,
      "displayName": "12720",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "2401",
      "protocol": "UDP",
      "upperPort": 0
    },
    {
      "id": "8ff8b720-d290-41db-b9c7-61649a25d262",
      "instanceId": 12721,
      "authEntityId": 12721,
      "displayName": "12721",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "2401",
      "protocol": "TCP",
      "upperPort": 0
    }
  ],
  "networkApplications": [
    {
      "id": "5dfcb62d-3507-445b-97d7-044f3d29db4d",
      "instanceId": 11267,
      "authEntityId": 11267,
      "displayName": "11267",
      "authEntityClass": -217092956,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "appProtocol": "tcp/udp",
      "applicationGroup": "other",
      "applicationType": "DEFAULT",
      "categoryId": "6daa3dfd-3526-407a-88f0-97824b3f3a24",
      "createTime": 1517966742258,
      "encrypted": "false",
      "engineId": "3",
      "helpString": "CVS pserver",
      "ignoreConflict": false,
      "lastUpdateTime": 1517966742258,
      "longDescription": "CVS pserver is an insecure method of giving remote access to a Concurrent Versions System (CVS) repository.",
      "name": "cvspserver",
      "nbarId": "1337",
      "p2pTechnology": "false",
      "popularity": 1,
      "rank": 65535,
      "references": "http://www.nongnu.org/cvs/",
      "selectorId": "2401",
      "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
      "trafficClass": "BULK_DATA",
      "tunnel": "false"
    }
  ],
  "networkIdentity": [
    {
      "id": "79d244d0-4c52-47bc-ab10-3dcaa9523d52",
      "instanceId": 12718,
      "authEntityId": 12718,
      "displayName": "12718",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "2401",
      "protocol": "TCP",
      "upperPort": 0
    },
    {
      "id": "34addd24-b5ce-4147-b333-561032ff16bf",
      "instanceId": 12719,
      "authEntityId": 12719,
      "displayName": "12719",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "2401",
      "protocol": "UDP",
      "upperPort": 0
    }
  ],
  "parentScalableGroup": {
    "idRef": "000c15bf-28fe-4fbb-9b69-35e6502721f1"
  },
  "user": [

  ],
  "userGroup": [

  ]
},
{
  "id": "65ca6e22-7443-4961-b5f2-4af19de21c2f",
  "instanceId": 15456,
  "authEntityId": 15456,
  "displayName": "15456",
  "authEntityClass": -1909073334,
  "deployPending": "NONE",
  "instanceVersion": 0,
  "createTime": 1517966744997,
  "deployed": false,
  "isSeeded": true,
  "isStale": false,
  "lastUpdateTime": 1517966744997,
  "name": "yahoo-messenger",
  "namespace": "scalablegroup:application",
  "provisioningState": "DEFINED",
  "qualifier": "application",
  "resourceVersion": 0,
  "targetIdList": [

  ],
  "type": "scalablegroup",
  "cfsChangeInfo": [

  ],
  "customProvisions": [

  ],
  "scalableGroupExternalHandle": "yahoo-messenger",
  "scalableGroupType": "APPLICATION",
  "securityGroupTag": 0,
  "state": "ACTIVE",
  "vnAgnostic": true,
  "identitySource": {
    "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
    "instanceId": 10010,
    "authEntityId": 10010,
    "displayName": "10010",
    "authEntityClass": 1738953278,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "state": "INACTIVE",
    "type": "NBAR"
  },
  "indicativeNetworkIdentity": [
    {
      "id": "8d9a6bcb-5cd6-44a5-a6d5-6b44832388e4",
      "instanceId": 17951,
      "authEntityId": 17951,
      "displayName": "17951",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "5050,5101",
      "protocol": "TCP",
      "upperPort": 0
    }
  ],
  "networkApplications": [
    {
      "id": "9a9a8063-e284-4b14-9d3e-e3d4a5b77fcc",
      "instanceId": 16399,
      "authEntityId": 16399,
      "displayName": "16399",
      "authEntityClass": -217092956,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "appProtocol": "tcp/udp",
      "applicationGroup": "yahoo-messenger-group",
      "applicationType": "DEFAULT",
      "categoryId": "0f6cab47-be95-4124-9db3-360ccd55f983",
      "createTime": 1517966744997,
      "encrypted": "false",
      "engineId": "13",
      "helpString": "Yahoo Messenger, Instant Messaging Client",
      "ignoreConflict": false,
      "lastUpdateTime": 1517966744997,
      "longDescription": "Yahoo messenger is an all-in-one communication tool that uses client-server architecture. The application includes multiple features: instant messaging, phone calls, synchronized online address book, webcam, gaming, emailing and online radio stations.",
      "name": "yahoo-messenger",
      "nbarId": "77",
      "p2pTechnology": "false",
      "popularity": 9,
      "rank": 65535,
      "references": "http://messenger.yahoo.com/web/",
      "selectorId": "77",
      "subCategory": "118c10a2-e272-47c1-92b9-4814ebe97138",
      "trafficClass": "MULTIMEDIA_CONFERENCING",
      "tunnel": "false"
    }
  ],
  "networkIdentity": [
    {
      "id": "f29324a3-8466-4579-9aef-bb73e7b33153",
      "instanceId": 17949,
      "authEntityId": 17949,
      "displayName": "17949",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "53,5353",
      "protocol": "UDP",
      "upperPort": 0
    },
    {
      "id": "322825e6-30ae-4d91-893e-8833d92d3e6c",
      "instanceId": 17950,
      "authEntityId": 17950,
      "displayName": "17950",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "53,80,1080,5050,5101,5353",
      "protocol": "TCP",
      "upperPort": 0
    }
  ],
  "parentScalableGroup": {
    "idRef": "fd6264c9-c16a-4311-a335-a3db039256f3"
  },
  "user": [

  ],
  "userGroup": [

  ]
},
{
  "id": "65dc1cd2-5adb-4966-a1a6-a5c164aa16cb",
  "instanceId": 4474,
  "authEntityId": 4474,
  "displayName": "4474",
  "authEntityClass": -1909073334,
  "deployPending": "NONE",
  "instanceVersion": 0,
  "createTime": 1517966742761,
  "deployed": false,
  "isSeeded": true,
  "isStale": false,
  "lastUpdateTime": 1517966742761,
  "name": "gbridge",
  "namespace": "scalablegroup:application",
  "provisioningState": "DEFINED",
  "qualifier": "application",
  "resourceVersion": 0,
  "targetIdList": [

  ],
  "type": "scalablegroup",
  "cfsChangeInfo": [

  ],
  "customProvisions": [

  ],
  "scalableGroupExternalHandle": "gbridge",
  "scalableGroupType": "APPLICATION",
  "securityGroupTag": 0,
  "state": "ACTIVE",
  "vnAgnostic": true,
  "identitySource": {
    "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
    "instanceId": 10010,
    "authEntityId": 10010,
    "displayName": "10010",
    "authEntityClass": 1738953278,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "state": "INACTIVE",
    "type": "NBAR"
  },
  "indicativeNetworkIdentity": [

  ],
  "networkApplications": [
    {
      "id": "af0fa913-bd51-4de1-9b84-8904ceeebe6c",
      "instanceId": 11425,
      "authEntityId": 11425,
      "displayName": "11425",
      "authEntityClass": -217092956,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "applicationGroup": "other",
      "applicationType": "DEFAULT",
      "categoryId": "a09ef1b3-ba26-4372-aee8-309d50697e76",
      "createTime": 1517966742761,
      "encrypted": "true",
      "engineId": "13",
      "helpString": "DEPRECATED, traffic will not match",
      "ignoreConflict": false,
      "lastUpdateTime": 1517966742761,
      "longDescription": "Deprecated: Gbridge is a free software that allows users to control PCs remotely, sync folders, share files and chat securely using a Google Account.",
      "name": "gbridge",
      "nbarId": "1465",
      "p2pTechnology": "false",
      "popularity": 5,
      "rank": 65535,
      "references": "http://www.gbridge.com",
      "selectorId": "530",
      "subCategory": "118c10a2-e272-47c1-92b9-4814ebe97138",
      "trafficClass": "MULTIMEDIA_CONFERENCING",
      "tunnel": "false"
    }
  ],
  "networkIdentity": [

  ],
  "parentScalableGroup": {
    "idRef": "fd6264c9-c16a-4311-a335-a3db039256f3"
  },
  "user": [

  ],
  "userGroup": [

  ]
},
{
  "id": "65f9e304-41e2-42ab-903b-846e621bb5a3",
  "instanceId": 15125,
  "authEntityId": 15125,
  "displayName": "15125",
  "authEntityClass": -1909073334,
  "deployPending": "NONE",
  "instanceVersion": 0,
  "createTime": 1517966744291,
  "deployed": false,
  "isSeeded": true,
  "isStale": false,
  "lastUpdateTime": 1517966744291,
  "name": "sfgate",
  "namespace": "scalablegroup:application",
  "provisioningState": "DEFINED",
  "qualifier": "application",
  "resourceVersion": 0,
  "targetIdList": [

  ],
  "type": "scalablegroup",
  "cfsChangeInfo": [

  ],
  "customProvisions": [

  ],
  "scalableGroupExternalHandle": "sfgate",
  "scalableGroupType": "APPLICATION",
  "securityGroupTag": 0,
  "state": "ACTIVE",
  "vnAgnostic": true,
  "identitySource": {
    "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
    "instanceId": 10010,
    "authEntityId": 10010,
    "displayName": "10010",
    "authEntityClass": 1738953278,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "state": "INACTIVE",
    "type": "NBAR"
  },
  "indicativeNetworkIdentity": [

  ],
  "networkApplications": [
    {
      "id": "80644204-bb63-4dca-9505-77f51eb89fa0",
      "instanceId": 16068,
      "authEntityId": 16068,
      "displayName": "16068",
      "authEntityClass": -217092956,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "appProtocol": "tcp/udp",
      "applicationGroup": "other",
      "applicationType": "DEFAULT",
      "categoryId": "878644b5-fe91-45b4-9204-31c84a73ba48",
      "createTime": 1517966744291,
      "encrypted": "false",
      "engineId": "13",
      "helpString": "SFGate - Bay area news portal.",
      "ignoreConflict": false,
      "lastUpdateTime": 1517966744291,
      "longDescription": "SFGate - Bay area news portal.",
      "name": "sfgate",
      "nbarId": "1704",
      "p2pTechnology": "false",
      "popularity": 3,
      "rank": 65535,
      "references": "http://sfgate.com",
      "selectorId": "774",
      "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
      "trafficClass": "TRANSACTIONAL_DATA",
      "tunnel": "false"
    }
  ],
  "networkIdentity": [
    {
      "id": "aa40076f-1dde-49bf-ba8b-4b95099b5882",
      "instanceId": 17020,
      "authEntityId": 17020,
      "displayName": "17020",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "53,5353",
      "protocol": "UDP",
      "upperPort": 0
    },
    {
      "id": "94d19a6b-4467-48c9-b5cc-6034ada86e81",
      "instanceId": 17021,
      "authEntityId": 17021,
      "displayName": "17021",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "53,80,443,5353",
      "protocol": "TCP",
      "upperPort": 0
    }
  ],
  "parentScalableGroup": {
    "idRef": "28e08de4-d7b2-4521-84ce-bc1e5ee0b9cc"
  },
  "user": [

  ],
  "userGroup": [

  ]
},
{
  "id": "660ada1d-4288-46a2-a81d-248ac6a257ad",
  "instanceId": 4778,
  "authEntityId": 4778,
  "displayName": "4778",
  "authEntityClass": -1909073334,
  "deployPending": "NONE",
  "instanceVersion": 0,
  "createTime": 1517966743474,
  "deployed": false,
  "isSeeded": true,
  "isStale": false,
  "lastUpdateTime": 1517966743474,
  "name": "ms-lync-media",
  "namespace": "scalablegroup:application",
  "provisioningState": "DEFINED",
  "qualifier": "application",
  "resourceVersion": 0,
  "targetIdList": [

  ],
  "type": "scalablegroup",
  "cfsChangeInfo": [

  ],
  "customProvisions": [

  ],
  "scalableGroupExternalHandle": "ms-lync-media",
  "scalableGroupType": "APPLICATION",
  "securityGroupTag": 0,
  "state": "ACTIVE",
  "vnAgnostic": true,
  "identitySource": {
    "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
    "instanceId": 10010,
    "authEntityId": 10010,
    "displayName": "10010",
    "authEntityClass": 1738953278,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "state": "INACTIVE",
    "type": "NBAR"
  },
  "indicativeNetworkIdentity": [

  ],
  "networkApplications": [
    {
      "id": "49360342-b729-4b4a-9ff4-e6859494be10",
      "instanceId": 11727,
      "authEntityId": 11727,
      "displayName": "11727",
      "authEntityClass": -217092956,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "applicationGroup": "other",
      "applicationType": "DEFAULT",
      "categoryId": "6daa3dfd-3526-407a-88f0-97824b3f3a24",
      "createTime": 1517966743474,
      "encrypted": "unassigned",
      "engineId": "13",
      "helpString": "DEPRECATED, Please refer to ms-lync-video and ms-lync-audio",
      "ignoreConflict": false,
      "lastUpdateTime": 1517966743474,
      "longDescription": "Microsoft Lync Media is the audio and video calls support in Microsoft Lync. Based on STUN and RTP.",
      "name": "ms-lync-media",
      "nbarId": "1467",
      "p2pTechnology": "unassigned",
      "popularity": 0,
      "rank": 65535,
      "references": "http://lync.microsoft.com",
      "selectorId": "532",
      "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
      "trafficClass": "BULK_DATA",
      "tunnel": "unassigned"
    }
  ],
  "networkIdentity": [

  ],
  "parentScalableGroup": {
    "idRef": "000c15bf-28fe-4fbb-9b69-35e6502721f1"
  },
  "user": [

  ],
  "userGroup": [

  ]
},
{
  "id": "6653f867-a44c-488e-ad28-8100a5b9264d",
  "instanceId": 15201,
  "authEntityId": 15201,
  "displayName": "15201",
  "authEntityClass": -1909073334,
  "deployPending": "NONE",
  "instanceVersion": 0,
  "createTime": 1517966744471,
  "deployed": false,
  "isSeeded": true,
  "isStale": false,
  "lastUpdateTime": 1517966744471,
  "name": "srmp",
  "namespace": "scalablegroup:application",
  "provisioningState": "DEFINED",
  "qualifier": "application",
  "resourceVersion": 0,
  "targetIdList": [

  ],
  "type": "scalablegroup",
  "cfsChangeInfo": [

  ],
  "customProvisions": [

  ],
  "scalableGroupExternalHandle": "srmp",
  "scalableGroupType": "APPLICATION",
  "securityGroupTag": 0,
  "state": "ACTIVE",
  "vnAgnostic": true,
  "identitySource": {
    "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
    "instanceId": 10010,
    "authEntityId": 10010,
    "displayName": "10010",
    "authEntityClass": 1738953278,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "state": "INACTIVE",
    "type": "NBAR"
  },
  "indicativeNetworkIdentity": [
    {
      "id": "4dcd0934-577a-433e-b092-a082cce1d957",
      "instanceId": 17244,
      "authEntityId": 17244,
      "displayName": "17244",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "193",
      "protocol": "TCP",
      "upperPort": 0
    },
    {
      "id": "36b2e666-c076-449c-85a4-d6a468e9bc09",
      "instanceId": 17245,
      "authEntityId": 17245,
      "displayName": "17245",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "193",
      "protocol": "UDP",
      "upperPort": 0
    }
  ],
  "networkApplications": [
    {
      "id": "07b30964-9fa4-4627-aa11-16ca7ff6deb5",
      "instanceId": 16144,
      "authEntityId": 16144,
      "displayName": "16144",
      "authEntityClass": -217092956,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "appProtocol": "tcp/udp",
      "applicationGroup": "other",
      "applicationType": "DEFAULT",
      "categoryId": "1fc17362-9334-4c7b-b752-5afdbb574017",
      "createTime": 1517966744471,
      "encrypted": "false",
      "engineId": "3",
      "helpString": "Spider Remote Monitoring Protocol",
      "ignoreConflict": false,
      "lastUpdateTime": 1517966744471,
      "longDescription": "Registered with IANA on port 193 TCP/UDP",
      "name": "srmp",
      "nbarId": "1039",
      "p2pTechnology": "false",
      "popularity": 1,
      "rank": 65535,
      "references": "http://www.iana.org/assignments/service-names-port-numbers/service-names-port-numbers.xml",
      "selectorId": "193",
      "subCategory": "f3c0f761-d894-4112-ab89-b90fc84fd660",
      "trafficClass": "OPS_ADMIN_MGMT",
      "tunnel": "false"
    }
  ],
  "networkIdentity": [
    {
      "id": "af15b34d-76af-4ae5-a1a7-e30ee12548aa",
      "instanceId": 17242,
      "authEntityId": 17242,
      "displayName": "17242",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "193",
      "protocol": "TCP",
      "upperPort": 0
    },
    {
      "id": "529d1c1d-da2e-4771-87f2-cbe5ebce3496",
      "instanceId": 17243,
      "authEntityId": 17243,
      "displayName": "17243",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "193",
      "protocol": "UDP",
      "upperPort": 0
    }
  ],
  "parentScalableGroup": {
    "idRef": "5fcd74d4-8726-47f7-a088-c457863da7ef"
  },
  "user": [

  ],
  "userGroup": [

  ]
},
{
  "id": "666fa24d-20d1-484b-ae58-c36e9bbe43b9",
  "instanceId": 4398,
  "authEntityId": 4398,
  "displayName": "4398",
  "authEntityClass": -1909073334,
  "deployPending": "NONE",
  "instanceVersion": 0,
  "createTime": 1517966742535,
  "deployed": false,
  "isSeeded": true,
  "isStale": false,
  "lastUpdateTime": 1517966742535,
  "name": "edonkey",
  "namespace": "scalablegroup:application",
  "provisioningState": "DEFINED",
  "qualifier": "application",
  "resourceVersion": 0,
  "targetIdList": [

  ],
  "type": "scalablegroup",
  "cfsChangeInfo": [

  ],
  "customProvisions": [

  ],
  "scalableGroupExternalHandle": "edonkey",
  "scalableGroupType": "APPLICATION",
  "securityGroupTag": 0,
  "state": "ACTIVE",
  "vnAgnostic": true,
  "identitySource": {
    "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
    "instanceId": 10010,
    "authEntityId": 10010,
    "displayName": "10010",
    "authEntityClass": 1738953278,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "state": "INACTIVE",
    "type": "NBAR"
  },
  "indicativeNetworkIdentity": [
    {
      "id": "42bf39ea-133f-4137-bd5c-e98b58b229f1",
      "instanceId": 12990,
      "authEntityId": 12990,
      "displayName": "12990",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "4662",
      "protocol": "TCP",
      "upperPort": 0
    }
  ],
  "networkApplications": [
    {
      "id": "e71820a3-013d-4426-be43-05446e5e55e5",
      "instanceId": 11349,
      "authEntityId": 11349,
      "displayName": "11349",
      "authEntityClass": -217092956,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "appProtocol": "tcp",
      "applicationGroup": "edonkey-emule-group",
      "applicationType": "DEFAULT",
      "categoryId": "b33b2761-d981-4734-810a-a6a1c18ab352",
      "createTime": 1517966742535,
      "encrypted": "false",
      "engineId": "13",
      "helpString": "eDonkey p2p file sharing client",
      "ignoreConflict": false,
      "lastUpdateTime": 1517966742535,
      "longDescription": "eDonkey is peer-to-peer file sharing adopted to share large files. The network is based on multiple decentralized servers. Each client must be connected to a server to enter the network. edonkey-static and eMule are also required to fully detect or prevent this application traffic.",
      "name": "edonkey",
      "nbarId": "67",
      "p2pTechnology": "true",
      "popularity": 10,
      "rank": 65535,
      "references": "http://web.archive.org/web/20010213200827/www.edonkey2000.com/overview.html",
      "selectorId": "67",
      "subCategory": "79fe7198-9ce3-4c01-9b58-45a8df3d9513",
      "trafficClass": "BULK_DATA",
      "tunnel": "false"
    }
  ],
  "networkIdentity": [
    {
      "id": "3450b099-c571-48c7-906b-5cf034ce14eb",
      "instanceId": 12989,
      "authEntityId": 12989,
      "displayName": "12989",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "80,1080,4662",
      "protocol": "TCP",
      "upperPort": 0
    }
  ],
  "parentScalableGroup": {
    "idRef": "7a1a3bbb-dc84-4a35-82a4-7e650c4c4761"
  },
  "user": [

  ],
  "userGroup": [

  ]
},
{
  "id": "668b0090-d0a7-4fb4-a8f0-e3c479665974",
  "instanceId": 4305,
  "authEntityId": 4305,
  "displayName": "4305",
  "authEntityClass": -1909073334,
  "deployPending": "NONE",
  "instanceVersion": 0,
  "createTime": 1517966742219,
  "deployed": false,
  "isSeeded": true,
  "isStale": false,
  "lastUpdateTime": 1517966742219,
  "name": "crudp",
  "namespace": "scalablegroup:application",
  "provisioningState": "DEFINED",
  "qualifier": "application",
  "resourceVersion": 0,
  "targetIdList": [

  ],
  "type": "scalablegroup",
  "cfsChangeInfo": [

  ],
  "customProvisions": [

  ],
  "scalableGroupExternalHandle": "crudp",
  "scalableGroupType": "APPLICATION",
  "securityGroupTag": 0,
  "state": "ACTIVE",
  "vnAgnostic": true,
  "identitySource": {
    "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
    "instanceId": 10010,
    "authEntityId": 10010,
    "displayName": "10010",
    "authEntityClass": 1738953278,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "state": "INACTIVE",
    "type": "NBAR"
  },
  "indicativeNetworkIdentity": [
    {
      "id": "e55aab77-ab6d-4209-be1e-80f522968b96",
      "instanceId": 12687,
      "authEntityId": 12687,
      "displayName": "12687",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipProtocolNumbers": "127",
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "protocol": "CRUDP",
      "upperPort": 0
    }
  ],
  "networkApplications": [
    {
      "id": "cf015c54-6a40-441a-8db2-82d596dcec06",
      "instanceId": 11258,
      "authEntityId": 11258,
      "displayName": "11258",
      "authEntityClass": -217092956,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "appProtocol": "ip",
      "applicationGroup": "other",
      "applicationType": "DEFAULT",
      "categoryId": "6daa3dfd-3526-407a-88f0-97824b3f3a24",
      "createTime": 1517966742219,
      "encrypted": "false",
      "engineId": "1",
      "helpString": "Combat Radio User Datagram",
      "ignoreConflict": false,
      "lastUpdateTime": 1517966742219,
      "longDescription": "Registered with IANA as IP Protocol 127",
      "name": "crudp",
      "nbarId": "1225",
      "p2pTechnology": "false",
      "popularity": 1,
      "rank": 65535,
      "references": "http://www.iana.org/assignments/protocol-numbers/protocol-numbers.xml",
      "selectorId": "127",
      "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
      "trafficClass": "BULK_DATA",
      "tunnel": "false"
    }
  ],
  "networkIdentity": [
    {
      "id": "8b8e683f-9ae7-40c8-9481-4324ef8fb826",
      "instanceId": 12686,
      "authEntityId": 12686,
      "displayName": "12686",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipProtocolNumbers": "127",
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "protocol": "CRUDP",
      "upperPort": 0
    }
  ],
  "parentScalableGroup": {
    "idRef": "000c15bf-28fe-4fbb-9b69-35e6502721f1"
  },
  "user": [

  ],
  "userGroup": [

  ]
},
{
  "id": "669063b8-d909-4bfa-a06d-2eaaa1f678c7",
  "instanceId": 15413,
  "authEntityId": 15413,
  "displayName": "15413",
  "authEntityClass": -1909073334,
  "deployPending": "NONE",
  "instanceVersion": 0,
  "createTime": 1517966744922,
  "deployed": false,
  "isSeeded": true,
  "isStale": false,
  "lastUpdateTime": 1517966744922,
  "name": "whoami",
  "namespace": "scalablegroup:application",
  "provisioningState": "DEFINED",
  "qualifier": "application",
  "resourceVersion": 0,
  "targetIdList": [

  ],
  "type": "scalablegroup",
  "cfsChangeInfo": [

  ],
  "customProvisions": [

  ],
  "scalableGroupExternalHandle": "whoami",
  "scalableGroupType": "APPLICATION",
  "securityGroupTag": 0,
  "state": "ACTIVE",
  "vnAgnostic": true,
  "identitySource": {
    "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
    "instanceId": 10010,
    "authEntityId": 10010,
    "displayName": "10010",
    "authEntityClass": 1738953278,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "state": "INACTIVE",
    "type": "NBAR"
  },
  "indicativeNetworkIdentity": [
    {
      "id": "da41b7c8-788e-41bf-842b-3f510329b5de",
      "instanceId": 17837,
      "authEntityId": 17837,
      "displayName": "17837",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "565",
      "protocol": "TCP",
      "upperPort": 0
    },
    {
      "id": "b4d94fea-bba8-458e-a990-3489ec2c962f",
      "instanceId": 17838,
      "authEntityId": 17838,
      "displayName": "17838",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "565",
      "protocol": "UDP",
      "upperPort": 0
    }
  ],
  "networkApplications": [
    {
      "id": "e90e93ff-57d1-449c-b3da-669290bf03c4",
      "instanceId": 16356,
      "authEntityId": 16356,
      "displayName": "16356",
      "authEntityClass": -217092956,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "appProtocol": "tcp/udp",
      "applicationGroup": "other",
      "applicationType": "DEFAULT",
      "categoryId": "1fc17362-9334-4c7b-b752-5afdbb574017",
      "createTime": 1517966744922,
      "encrypted": "false",
      "engineId": "3",
      "helpString": "whoami",
      "ignoreConflict": false,
      "lastUpdateTime": 1517966744922,
      "longDescription": "Registered with IANA on port 565 TCP/UDP",
      "name": "whoami",
      "nbarId": "480",
      "p2pTechnology": "false",
      "popularity": 1,
      "rank": 65535,
      "references": "http://www.iana.org/assignments/service-names-port-numbers/service-names-port-numbers.xml",
      "selectorId": "565",
      "subCategory": "f3c0f761-d894-4112-ab89-b90fc84fd660",
      "trafficClass": "OPS_ADMIN_MGMT",
      "tunnel": "false"
    }
  ],
  "networkIdentity": [
    {
      "id": "e89470e2-ad41-4375-8750-86b539722ec7",
      "instanceId": 17835,
      "authEntityId": 17835,
      "displayName": "17835",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "565",
      "protocol": "TCP",
      "upperPort": 0
    },
    {
      "id": "87a79fd0-8e93-4c60-bc30-c8e627461fce",
      "instanceId": 17836,
      "authEntityId": 17836,
      "displayName": "17836",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "565",
      "protocol": "UDP",
      "upperPort": 0
    }
  ],
  "parentScalableGroup": {
    "idRef": "5fcd74d4-8726-47f7-a088-c457863da7ef"
  },
  "user": [

  ],
  "userGroup": [

  ]
},
{
  "id": "6697ef71-5b5c-4cd9-b2a3-d404d326852e",
  "instanceId": 15233,
  "authEntityId": 15233,
  "displayName": "15233",
  "authEntityClass": -1909073334,
  "deployPending": "NONE",
  "instanceVersion": 0,
  "createTime": 1517966744547,
  "deployed": false,
  "isSeeded": true,
  "isStale": false,
  "lastUpdateTime": 1517966744547,
  "name": "supdup",
  "namespace": "scalablegroup:application",
  "provisioningState": "DEFINED",
  "qualifier": "application",
  "resourceVersion": 0,
  "targetIdList": [

  ],
  "type": "scalablegroup",
  "cfsChangeInfo": [

  ],
  "customProvisions": [

  ],
  "scalableGroupExternalHandle": "supdup",
  "scalableGroupType": "APPLICATION",
  "securityGroupTag": 0,
  "state": "ACTIVE",
  "vnAgnostic": true,
  "identitySource": {
    "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
    "instanceId": 10010,
    "authEntityId": 10010,
    "displayName": "10010",
    "authEntityClass": 1738953278,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "state": "INACTIVE",
    "type": "NBAR"
  },
  "indicativeNetworkIdentity": [
    {
      "id": "b35f71ca-1574-4929-9349-059e80f86787",
      "instanceId": 17334,
      "authEntityId": 17334,
      "displayName": "17334",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "95",
      "protocol": "UDP",
      "upperPort": 0
    },
    {
      "id": "923f11c9-4161-45f8-b75f-4b9f2e742070",
      "instanceId": 17335,
      "authEntityId": 17335,
      "displayName": "17335",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "95",
      "protocol": "TCP",
      "upperPort": 0
    }
  ],
  "networkApplications": [
    {
      "id": "5b97300d-7e24-4669-a869-ad58a52e4429",
      "instanceId": 16176,
      "authEntityId": 16176,
      "displayName": "16176",
      "authEntityClass": -217092956,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "appProtocol": "tcp/udp",
      "applicationGroup": "other",
      "applicationType": "DEFAULT",
      "categoryId": "1fc17362-9334-4c7b-b752-5afdbb574017",
      "createTime": 1517966744547,
      "encrypted": "false",
      "engineId": "3",
      "helpString": "SUPDUP",
      "ignoreConflict": false,
      "lastUpdateTime": 1517966744547,
      "longDescription": "The SUPDUP protocol provides means for login to a remote system over a network with terminal-independent output, so that only the local system need know how to handle the user's terminal. It offers facilities for graphics and for local assistance to remote text editors.",
      "name": "supdup",
      "nbarId": "966",
      "p2pTechnology": "false",
      "popularity": 1,
      "rank": 65535,
      "references": "http://dspace.mit.edu/handle/1721.1/5694",
      "selectorId": "95",
      "subCategory": "787a7d77-977b-4974-89d3-379ba49e88b7",
      "trafficClass": "OPS_ADMIN_MGMT",
      "tunnel": "false"
    }
  ],
  "networkIdentity": [
    {
      "id": "f221890a-e31f-467d-bcf6-0f099624f66b",
      "instanceId": 17332,
      "authEntityId": 17332,
      "displayName": "17332",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "95",
      "protocol": "TCP",
      "upperPort": 0
    },
    {
      "id": "b22ee2dc-9634-4d47-9044-e0971781f31b",
      "instanceId": 17333,
      "authEntityId": 17333,
      "displayName": "17333",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "95",
      "protocol": "UDP",
      "upperPort": 0
    }
  ],
  "parentScalableGroup": {
    "idRef": "e06dd89f-0a70-4422-ab0b-c2314650a9a7"
  },
  "user": [

  ],
  "userGroup": [

  ]
},
{
  "id": "67115af5-034f-45e4-b993-c812c6222b4f",
  "instanceId": 15181,
  "authEntityId": 15181,
  "displayName": "15181",
  "authEntityClass": -1909073334,
  "deployPending": "NONE",
  "instanceVersion": 0,
  "createTime": 1517966744420,
  "deployed": false,
  "isSeeded": true,
  "isStale": false,
  "lastUpdateTime": 1517966744420,
  "name": "songsari",
  "namespace": "scalablegroup:application",
  "provisioningState": "DEFINED",
  "qualifier": "application",
  "resourceVersion": 0,
  "targetIdList": [

  ],
  "type": "scalablegroup",
  "cfsChangeInfo": [

  ],
  "customProvisions": [

  ],
  "scalableGroupExternalHandle": "songsari",
  "scalableGroupType": "APPLICATION",
  "securityGroupTag": 0,
  "state": "ACTIVE",
  "vnAgnostic": true,
  "identitySource": {
    "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
    "instanceId": 10010,
    "authEntityId": 10010,
    "displayName": "10010",
    "authEntityClass": 1738953278,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "state": "INACTIVE",
    "type": "NBAR"
  },
  "indicativeNetworkIdentity": [

  ],
  "networkApplications": [
    {
      "id": "bfdf7eda-3cba-4f44-b0da-3c0edab45919",
      "instanceId": 16124,
      "authEntityId": 16124,
      "displayName": "16124",
      "authEntityClass": -217092956,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "appProtocol": "tcp",
      "applicationGroup": "other",
      "applicationType": "DEFAULT",
      "categoryId": "a09ef1b3-ba26-4372-aee8-309d50697e76",
      "createTime": 1517966744420,
      "encrypted": "false",
      "engineId": "13",
      "helpString": "Songsari P2P File Sharing",
      "ignoreConflict": false,
      "lastUpdateTime": 1517966744420,
      "longDescription": "Songsari is a commercial media distribution website popular in south Korea. Users registered to the website can download media files such as video and audio.",
      "name": "songsari",
      "nbarId": "1094",
      "p2pTechnology": "false",
      "popularity": 7,
      "rank": 65535,
      "references": "http://www.songsari.com/",
      "selectorId": "450",
      "subCategory": "85a797c0-aef8-4284-91a7-677f33e29abb",
      "trafficClass": "MULTIMEDIA_STREAMING",
      "tunnel": "false"
    }
  ],
  "networkIdentity": [
    {
      "id": "d5eb3e65-dd99-4bb6-bb46-93eee06e5225",
      "instanceId": 17193,
      "authEntityId": 17193,
      "displayName": "17193",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "80",
      "protocol": "TCP",
      "upperPort": 0
    }
  ],
  "parentScalableGroup": {
    "idRef": "fd6264c9-c16a-4311-a335-a3db039256f3"
  },
  "user": [

  ],
  "userGroup": [

  ]
},
{
  "id": "67750769-ed8f-433c-aea2-cbb354c9292e",
  "instanceId": 15297,
  "authEntityId": 15297,
  "displayName": "15297",
  "authEntityClass": -1909073334,
  "deployPending": "NONE",
  "instanceVersion": 0,
  "createTime": 1517966744682,
  "deployed": false,
  "isSeeded": true,
  "isStale": false,
  "lastUpdateTime": 1517966744682,
  "name": "tomatopang",
  "namespace": "scalablegroup:application",
  "provisioningState": "DEFINED",
  "qualifier": "application",
  "resourceVersion": 0,
  "targetIdList": [

  ],
  "type": "scalablegroup",
  "cfsChangeInfo": [

  ],
  "customProvisions": [

  ],
  "scalableGroupExternalHandle": "tomatopang",
  "scalableGroupType": "APPLICATION",
  "securityGroupTag": 0,
  "state": "ACTIVE",
  "vnAgnostic": true,
  "identitySource": {
    "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
    "instanceId": 10010,
    "authEntityId": 10010,
    "displayName": "10010",
    "authEntityClass": 1738953278,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "state": "INACTIVE",
    "type": "NBAR"
  },
  "indicativeNetworkIdentity": [

  ],
  "networkApplications": [
    {
      "id": "301aec7d-5c26-49a2-8134-c06aa28c0e52",
      "instanceId": 16240,
      "authEntityId": 16240,
      "displayName": "16240",
      "authEntityClass": -217092956,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "applicationGroup": "other",
      "applicationType": "DEFAULT",
      "categoryId": "b33b2761-d981-4734-810a-a6a1c18ab352",
      "createTime": 1517966744682,
      "encrypted": "false",
      "engineId": "13",
      "helpString": "Tomatopang Peer-to-Peer File Sharing",
      "ignoreConflict": false,
      "lastUpdateTime": 1517966744682,
      "longDescription": "Tomatopang is a Korean peer to peer file-sharing application. It is based on peer to peer architecture.",
      "name": "tomatopang",
      "nbarId": "1093",
      "p2pTechnology": "true",
      "popularity": 5,
      "rank": 65535,
      "references": "http://www.tomatopang.net/",
      "selectorId": "449",
      "subCategory": "79fe7198-9ce3-4c01-9b58-45a8df3d9513",
      "trafficClass": "BULK_DATA",
      "tunnel": "false"
    }
  ],
  "networkIdentity": [

  ],
  "parentScalableGroup": {
    "idRef": "7a1a3bbb-dc84-4a35-82a4-7e650c4c4761"
  },
  "user": [

  ],
  "userGroup": [

  ]
},
{
  "id": "67aaefa8-46f6-4267-b84a-ad6894c1076f",
  "instanceId": 15407,
  "authEntityId": 15407,
  "displayName": "15407",
  "authEntityClass": -1909073334,
  "deployPending": "NONE",
  "instanceVersion": 0,
  "createTime": 1517966744911,
  "deployed": false,
  "isSeeded": true,
  "isStale": false,
  "lastUpdateTime": 1517966744911,
  "name": "wechat",
  "namespace": "scalablegroup:application",
  "provisioningState": "DEFINED",
  "qualifier": "application",
  "resourceVersion": 0,
  "targetIdList": [

  ],
  "type": "scalablegroup",
  "cfsChangeInfo": [

  ],
  "customProvisions": [

  ],
  "scalableGroupExternalHandle": "wechat",
  "scalableGroupType": "APPLICATION",
  "securityGroupTag": 0,
  "state": "ACTIVE",
  "vnAgnostic": true,
  "identitySource": {
    "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
    "instanceId": 10010,
    "authEntityId": 10010,
    "displayName": "10010",
    "authEntityClass": 1738953278,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "state": "INACTIVE",
    "type": "NBAR"
  },
  "indicativeNetworkIdentity": [

  ],
  "networkApplications": [
    {
      "id": "a88053d0-2f1d-48a3-a8d7-36feb203e5b4",
      "instanceId": 16350,
      "authEntityId": 16350,
      "displayName": "16350",
      "authEntityClass": -217092956,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "appProtocol": "tcp/udp",
      "applicationGroup": "other",
      "applicationType": "DEFAULT",
      "categoryId": "0f6cab47-be95-4124-9db3-360ccd55f983",
      "createTime": 1517966744911,
      "encrypted": "true",
      "engineId": "13",
      "helpString": "WeChat is a mobile text and voice messaging communication service",
      "ignoreConflict": false,
      "lastUpdateTime": 1517966744911,
      "longDescription": "WeChat is a mobile text and voice messaging communication service. The app is available on Android, iPhone, BlackBerry, Windows Phone and Symbian phones",
      "name": "wechat",
      "nbarId": "1037",
      "p2pTechnology": "false",
      "popularity": 10,
      "rank": 65535,
      "references": "http://www.wechat.com/en/",
      "selectorId": "1037",
      "subCategory": "118c10a2-e272-47c1-92b9-4814ebe97138",
      "trafficClass": "TRANSACTIONAL_DATA",
      "tunnel": "false"
    }
  ],
  "networkIdentity": [
    {
      "id": "137546e2-404f-4448-9eba-7387d3f90655",
      "instanceId": 17824,
      "authEntityId": 17824,
      "displayName": "17824",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "53,80,443,5353",
      "protocol": "TCP",
      "upperPort": 0
    },
    {
      "id": "477f4d5e-285c-4555-a2cf-ca251259a684",
      "instanceId": 17823,
      "authEntityId": 17823,
      "displayName": "17823",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "53,5353",
      "protocol": "UDP",
      "upperPort": 0
    }
  ],
  "parentScalableGroup": {
    "idRef": "fd6264c9-c16a-4311-a335-a3db039256f3"
  },
  "user": [

  ],
  "userGroup": [

  ]
},
{
  "id": "67bb4949-cc19-4776-978b-4088320eac97",
  "instanceId": 4275,
  "authEntityId": 4275,
  "displayName": "4275",
  "authEntityClass": -1909073334,
  "deployPending": "NONE",
  "instanceVersion": 0,
  "createTime": 1517966742040,
  "deployed": false,
  "isSeeded": true,
  "isStale": false,
  "lastUpdateTime": 1517966742040,
  "name": "compaq-peer",
  "namespace": "scalablegroup:application",
  "provisioningState": "DEFINED",
  "qualifier": "application",
  "resourceVersion": 0,
  "targetIdList": [

  ],
  "type": "scalablegroup",
  "cfsChangeInfo": [

  ],
  "customProvisions": [

  ],
  "scalableGroupExternalHandle": "compaq-peer",
  "scalableGroupType": "APPLICATION",
  "securityGroupTag": 0,
  "state": "ACTIVE",
  "vnAgnostic": true,
  "identitySource": {
    "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
    "instanceId": 10010,
    "authEntityId": 10010,
    "displayName": "10010",
    "authEntityClass": 1738953278,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "state": "INACTIVE",
    "type": "NBAR"
  },
  "indicativeNetworkIdentity": [
    {
      "id": "5d445c61-37e8-4b37-b953-f1977ef0b789",
      "instanceId": 12601,
      "authEntityId": 12601,
      "displayName": "12601",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipProtocolNumbers": "110",
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "protocol": "COMPAQ_PEER",
      "upperPort": 0
    }
  ],
  "networkApplications": [
    {
      "id": "38710046-4a2f-44f0-8a2e-bb5ebe8ce246",
      "instanceId": 11228,
      "authEntityId": 11228,
      "displayName": "11228",
      "authEntityClass": -217092956,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "appProtocol": "ip",
      "applicationGroup": "other",
      "applicationType": "DEFAULT",
      "categoryId": "6daa3dfd-3526-407a-88f0-97824b3f3a24",
      "createTime": 1517966742040,
      "encrypted": "unassigned",
      "engineId": "1",
      "helpString": "Compaq Peer Protocol",
      "ignoreConflict": false,
      "lastUpdateTime": 1517966742040,
      "longDescription": "Compaq-Peer Protocol is a specific proprietary protocol used by HP to set up peer-to-peer networks within a network infrastructure.",
      "name": "compaq-peer",
      "nbarId": "864",
      "p2pTechnology": "unassigned",
      "popularity": 2,
      "rank": 65535,
      "references": "http://www.compaq.com/info/SP5108/SP5108PF.PDF",
      "selectorId": "110",
      "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
      "trafficClass": "BULK_DATA",
      "tunnel": "unassigned"
    }
  ],
  "networkIdentity": [
    {
      "id": "d254fdbe-badd-491f-81ca-29d97e002c5d",
      "instanceId": 12600,
      "authEntityId": 12600,
      "displayName": "12600",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipProtocolNumbers": "110",
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "protocol": "COMPAQ_PEER",
      "upperPort": 0
    }
  ],
  "parentScalableGroup": {
    "idRef": "000c15bf-28fe-4fbb-9b69-35e6502721f1"
  },
  "user": [

  ],
  "userGroup": [

  ]
},
{
  "id": "67c2ad93-c173-4e06-ab8b-944554eef789",
  "instanceId": 15117,
  "authEntityId": 15117,
  "displayName": "15117",
  "authEntityClass": -1909073334,
  "deployPending": "NONE",
  "instanceVersion": 0,
  "createTime": 1517966744273,
  "deployed": false,
  "isSeeded": true,
  "isStale": false,
  "lastUpdateTime": 1517966744273,
  "name": "secure-smtp",
  "namespace": "scalablegroup:application",
  "provisioningState": "DEFINED",
  "qualifier": "application",
  "resourceVersion": 0,
  "targetIdList": [

  ],
  "type": "scalablegroup",
  "cfsChangeInfo": [

  ],
  "customProvisions": [

  ],
  "scalableGroupExternalHandle": "secure-smtp",
  "scalableGroupType": "APPLICATION",
  "securityGroupTag": 0,
  "state": "ACTIVE",
  "vnAgnostic": true,
  "identitySource": {
    "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
    "instanceId": 10010,
    "authEntityId": 10010,
    "displayName": "10010",
    "authEntityClass": 1738953278,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "state": "INACTIVE",
    "type": "NBAR"
  },
  "indicativeNetworkIdentity": [
    {
      "id": "105b770c-7c79-40d9-9d11-959d5648abca",
      "instanceId": 14990,
      "authEntityId": 14990,
      "displayName": "14990",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "465",
      "protocol": "UDP",
      "upperPort": 0
    },
    {
      "id": "6263392f-2594-4e6b-865d-6d1c720bfb12",
      "instanceId": 14991,
      "authEntityId": 14991,
      "displayName": "14991",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "465",
      "protocol": "TCP",
      "upperPort": 0
    }
  ],
  "networkApplications": [
    {
      "id": "7a54a7b8-1221-4383-a8eb-63fdbc6f5815",
      "instanceId": 16060,
      "authEntityId": 16060,
      "displayName": "16060",
      "authEntityClass": -217092956,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "appProtocol": "tcp/udp",
      "applicationGroup": "smtp-group",
      "applicationType": "DEFAULT",
      "categoryId": "27953c60-b2ff-4e1a-bc52-0187d8295250",
      "createTime": 1517966744273,
      "encrypted": "true",
      "engineId": "13",
      "helpString": "secure SMTP - Simple Mail Transfer Protocol",
      "ignoreConflict": false,
      "lastUpdateTime": 1517966744273,
      "longDescription": "Simple Mail Transfer Protocol (SMTP) is an Internet standard for electronic mail transmission across networks. Secure-smtp refers to a method for securing SMTP with transport layer security, typically works on TCP port 461.",
      "name": "secure-smtp",
      "nbarId": "1507",
      "p2pTechnology": "false",
      "popularity": 7,
      "rank": 65535,
      "references": "http://en.wikipedia.org/wiki/SMTPS",
      "selectorId": "568",
      "subCategory": "b90786f4-0190-45b8-9924-21b3e9682590",
      "trafficClass": "BULK_DATA",
      "tunnel": "false"
    }
  ],
  "networkIdentity": [
    {
      "id": "e6159e86-6952-4d4c-8f01-8395129d4530",
      "instanceId": 14988,
      "authEntityId": 14988,
      "displayName": "14988",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "465",
      "protocol": "TCP",
      "upperPort": 0
    },
    {
      "id": "8a2d4e7a-cdeb-4604-9150-7e9a2ff2d4b3",
      "instanceId": 14989,
      "authEntityId": 14989,
      "displayName": "14989",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "465",
      "protocol": "UDP",
      "upperPort": 0
    }
  ],
  "parentScalableGroup": {
    "idRef": "8a7ca43a-32bd-40d2-871a-02220a165cb7"
  },
  "user": [

  ],
  "userGroup": [

  ]
},
{
  "id": "6813e96a-69bc-46dc-889b-0a053373d6f0",
  "instanceId": 15131,
  "authEntityId": 15131,
  "displayName": "15131",
  "authEntityClass": -1909073334,
  "deployPending": "NONE",
  "instanceVersion": 0,
  "createTime": 1517966744305,
  "deployed": false,
  "isSeeded": true,
  "isStale": false,
  "lastUpdateTime": 1517966744305,
  "name": "sgmp",
  "namespace": "scalablegroup:application",
  "provisioningState": "DEFINED",
  "qualifier": "application",
  "resourceVersion": 0,
  "targetIdList": [

  ],
  "type": "scalablegroup",
  "cfsChangeInfo": [

  ],
  "customProvisions": [

  ],
  "scalableGroupExternalHandle": "sgmp",
  "scalableGroupType": "APPLICATION",
  "securityGroupTag": 0,
  "state": "ACTIVE",
  "vnAgnostic": true,
  "identitySource": {
    "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
    "instanceId": 10010,
    "authEntityId": 10010,
    "displayName": "10010",
    "authEntityClass": 1738953278,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "state": "INACTIVE",
    "type": "NBAR"
  },
  "indicativeNetworkIdentity": [
    {
      "id": "69e524fa-59e9-4a56-a258-07baefaca0c5",
      "instanceId": 17044,
      "authEntityId": 17044,
      "displayName": "17044",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "153",
      "protocol": "UDP",
      "upperPort": 0
    },
    {
      "id": "c4ffd794-245b-4a04-ba8b-e2968a8c4a3b",
      "instanceId": 17045,
      "authEntityId": 17045,
      "displayName": "17045",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "153",
      "protocol": "TCP",
      "upperPort": 0
    }
  ],
  "networkApplications": [
    {
      "id": "717f0377-4466-4665-884e-a3cc99475916",
      "instanceId": 16074,
      "authEntityId": 16074,
      "displayName": "16074",
      "authEntityClass": -217092956,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "appProtocol": "tcp/udp",
      "applicationGroup": "other",
      "applicationType": "DEFAULT",
      "categoryId": "1fc17362-9334-4c7b-b752-5afdbb574017",
      "createTime": 1517966744305,
      "encrypted": "false",
      "engineId": "3",
      "helpString": "sgmp",
      "ignoreConflict": false,
      "lastUpdateTime": 1517966744305,
      "longDescription": "Simple Gateway Monitoring Protocol (SGMP) allows commands to be issued to application protocol entities to set or retrieve values (integer or octet string types) for use in monitoring the gateways on which the application protocol entities reside. Messages are exchanged using UDP and utilize unreliable transport methods.",
      "name": "sgmp",
      "nbarId": "1000",
      "p2pTechnology": "false",
      "popularity": 1,
      "rank": 65535,
      "references": "http://www.ietf.org/rfc/rfc1028.txt",
      "selectorId": "153",
      "subCategory": "f3c0f761-d894-4112-ab89-b90fc84fd660",
      "trafficClass": "OPS_ADMIN_MGMT",
      "tunnel": "false"
    }
  ],
  "networkIdentity": [
    {
      "id": "371739a6-fdde-4959-8e71-4157b72eeaa2",
      "instanceId": 17042,
      "authEntityId": 17042,
      "displayName": "17042",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "153",
      "protocol": "TCP",
      "upperPort": 0
    },
    {
      "id": "a9c8cb5b-8fe2-44fb-bd81-887a74a08448",
      "instanceId": 17043,
      "authEntityId": 17043,
      "displayName": "17043",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "153",
      "protocol": "UDP",
      "upperPort": 0
    }
  ],
  "parentScalableGroup": {
    "idRef": "5fcd74d4-8726-47f7-a088-c457863da7ef"
  },
  "user": [

  ],
  "userGroup": [

  ]
},
{
  "id": "682c5f1d-9f2f-4fc5-844a-4e4358cdc810",
  "instanceId": 15182,
  "authEntityId": 15182,
  "displayName": "15182",
  "authEntityClass": -1909073334,
  "deployPending": "NONE",
  "instanceVersion": 0,
  "createTime": 1517966744422,
  "deployed": false,
  "isSeeded": true,
  "isStale": false,
  "lastUpdateTime": 1517966744422,
  "name": "sopcast",
  "namespace": "scalablegroup:application",
  "provisioningState": "DEFINED",
  "qualifier": "application",
  "resourceVersion": 0,
  "targetIdList": [

  ],
  "type": "scalablegroup",
  "cfsChangeInfo": [

  ],
  "customProvisions": [

  ],
  "scalableGroupExternalHandle": "sopcast",
  "scalableGroupType": "APPLICATION",
  "securityGroupTag": 0,
  "state": "ACTIVE",
  "vnAgnostic": true,
  "identitySource": {
    "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
    "instanceId": 10010,
    "authEntityId": 10010,
    "displayName": "10010",
    "authEntityClass": 1738953278,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "state": "INACTIVE",
    "type": "NBAR"
  },
  "indicativeNetworkIdentity": [

  ],
  "networkApplications": [
    {
      "id": "e0f071b2-5692-496c-9608-ea9ccadd27b4",
      "instanceId": 16125,
      "authEntityId": 16125,
      "displayName": "16125",
      "authEntityClass": -217092956,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "appProtocol": "tcp/udp",
      "applicationGroup": "other",
      "applicationType": "DEFAULT",
      "categoryId": "a09ef1b3-ba26-4372-aee8-309d50697e76",
      "createTime": 1517966744422,
      "encrypted": "false",
      "engineId": "13",
      "helpString": "Free P2P internet TV",
      "ignoreConflict": false,
      "lastUpdateTime": 1517966744422,
      "longDescription": "SopCast is a simple, free way to broadcast or watch video and audio on the Internet.  It uses Peer to Peer (P2P) technology that enables anyone become a broadcaster without requiring a powerful server or vast bandwidth.",
      "name": "sopcast",
      "nbarId": "116",
      "p2pTechnology": "true",
      "popularity": 7,
      "rank": 65535,
      "references": "http://www.sopcast.org/",
      "selectorId": "429",
      "subCategory": "85a797c0-aef8-4284-91a7-677f33e29abb",
      "trafficClass": "MULTIMEDIA_STREAMING",
      "tunnel": "false"
    }
  ],
  "networkIdentity": [
    {
      "id": "869fca55-a1fe-477b-bf78-6d129346ff82",
      "instanceId": 17194,
      "authEntityId": 17194,
      "displayName": "17194",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "53,80,5353",
      "protocol": "TCP",
      "upperPort": 0
    },
    {
      "id": "c0e15d09-3560-451e-abaa-924a296d0b23",
      "instanceId": 17195,
      "authEntityId": 17195,
      "displayName": "17195",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "53,5353",
      "protocol": "UDP",
      "upperPort": 0
    }
  ],
  "parentScalableGroup": {
    "idRef": "fd6264c9-c16a-4311-a335-a3db039256f3"
  },
  "user": [

  ],
  "userGroup": [

  ]
},
{
  "id": "686dab7d-a760-4dfc-a10e-dabda9ef1335",
  "instanceId": 4650,
  "authEntityId": 4650,
  "displayName": "4650",
  "authEntityClass": -1909073334,
  "deployPending": "NONE",
  "instanceVersion": 0,
  "createTime": 1517966743206,
  "deployed": false,
  "isSeeded": true,
  "isStale": false,
  "lastUpdateTime": 1517966743206,
  "name": "iso-tp4",
  "namespace": "scalablegroup:application",
  "provisioningState": "DEFINED",
  "qualifier": "application",
  "resourceVersion": 0,
  "targetIdList": [

  ],
  "type": "scalablegroup",
  "cfsChangeInfo": [

  ],
  "customProvisions": [

  ],
  "scalableGroupExternalHandle": "iso-tp4",
  "scalableGroupType": "APPLICATION",
  "securityGroupTag": 0,
  "state": "ACTIVE",
  "vnAgnostic": true,
  "identitySource": {
    "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
    "instanceId": 10010,
    "authEntityId": 10010,
    "displayName": "10010",
    "authEntityClass": 1738953278,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "state": "INACTIVE",
    "type": "NBAR"
  },
  "indicativeNetworkIdentity": [
    {
      "id": "3def3d94-3ca2-4a7a-a3ac-0e4c18d09cfa",
      "instanceId": 13636,
      "authEntityId": 13636,
      "displayName": "13636",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipProtocolNumbers": "29",
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "protocol": "ISO_TP4",
      "upperPort": 0
    }
  ],
  "networkApplications": [
    {
      "id": "a04ddecd-2f19-4090-9610-da3c7240a677",
      "instanceId": 11600,
      "authEntityId": 11600,
      "displayName": "11600",
      "authEntityClass": -217092956,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "appProtocol": "ip",
      "applicationGroup": "other",
      "applicationType": "DEFAULT",
      "categoryId": "6daa3dfd-3526-407a-88f0-97824b3f3a24",
      "createTime": 1517966743206,
      "encrypted": "unassigned",
      "engineId": "1",
      "helpString": "ISO Transport Protocol Class 4",
      "ignoreConflict": false,
      "lastUpdateTime": 1517966743206,
      "longDescription": "Transport Protocol Class 4 (TP4), one of the five transport layer protocols existing in the OSI suite, offers error recovery, performs segmentation and reassembly, and supplies multiplexing and demultiplexing of data streams over a single virtual circuit.",
      "name": "iso-tp4",
      "nbarId": "783",
      "p2pTechnology": "unassigned",
      "popularity": 2,
      "rank": 65535,
      "references": "http://www.javvin.com/protocol/TP4.html",
      "selectorId": "29",
      "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
      "trafficClass": "BULK_DATA",
      "tunnel": "unassigned"
    }
  ],
  "networkIdentity": [
    {
      "id": "1a4befc2-3df0-4316-9da1-12ef2dcce74e",
      "instanceId": 13635,
      "authEntityId": 13635,
      "displayName": "13635",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipProtocolNumbers": "29",
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "protocol": "ISO_TP4",
      "upperPort": 0
    }
  ],
  "parentScalableGroup": {
    "idRef": "000c15bf-28fe-4fbb-9b69-35e6502721f1"
  },
  "user": [

  ],
  "userGroup": [

  ]
},
{
  "id": "688afcef-7321-41c9-b8f5-8d87ebd9ad73",
  "instanceId": 15310,
  "authEntityId": 15310,
  "displayName": "15310",
  "authEntityClass": -1909073334,
  "deployPending": "NONE",
  "instanceVersion": 0,
  "createTime": 1517966744715,
  "deployed": false,
  "isSeeded": true,
  "isStale": false,
  "lastUpdateTime": 1517966744715,
  "name": "tus-files",
  "namespace": "scalablegroup:application",
  "provisioningState": "DEFINED",
  "qualifier": "application",
  "resourceVersion": 0,
  "targetIdList": [

  ],
  "type": "scalablegroup",
  "cfsChangeInfo": [

  ],
  "customProvisions": [

  ],
  "scalableGroupExternalHandle": "tus-files",
  "scalableGroupType": "APPLICATION",
  "securityGroupTag": 0,
  "state": "ACTIVE",
  "vnAgnostic": true,
  "identitySource": {
    "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
    "instanceId": 10010,
    "authEntityId": 10010,
    "displayName": "10010",
    "authEntityClass": 1738953278,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "state": "INACTIVE",
    "type": "NBAR"
  },
  "indicativeNetworkIdentity": [

  ],
  "networkApplications": [
    {
      "id": "27ba3e5b-d8d0-4868-a22c-0b94641099ee",
      "instanceId": 16253,
      "authEntityId": 16253,
      "displayName": "16253",
      "authEntityClass": -217092956,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "appProtocol": "tcp/udp",
      "applicationGroup": "other",
      "applicationType": "DEFAULT",
      "categoryId": "f6ec0221-b82d-43e8-a64d-163cbc13263b",
      "createTime": 1517966744715,
      "encrypted": "true",
      "engineId": "13",
      "helpString": "TusFiles cloud storage",
      "ignoreConflict": false,
      "lastUpdateTime": 1517966744715,
      "longDescription": "TusFiles is a cloud storage provider for online hosting and sharing of files.",
      "name": "tus-files",
      "nbarId": "1058",
      "p2pTechnology": "false",
      "popularity": 10,
      "rank": 65535,
      "references": "http://www.tusfiles.net",
      "selectorId": "1058",
      "subCategory": "9fecfe24-2ee3-4b19-bbf7-3c992d1d25b4",
      "trafficClass": "BULK_DATA",
      "tunnel": "false"
    }
  ],
  "networkIdentity": [
    {
      "id": "4de03e96-09d4-4836-ba18-e92eb6ae0c86",
      "instanceId": 17549,
      "authEntityId": 17549,
      "displayName": "17549",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "53,80,443,5353",
      "protocol": "TCP",
      "upperPort": 0
    },
    {
      "id": "3c54fbe2-4ac0-474c-8a12-efd0d480cf77",
      "instanceId": 17550,
      "authEntityId": 17550,
      "displayName": "17550",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "53,5353",
      "protocol": "UDP",
      "upperPort": 0
    }
  ],
  "parentScalableGroup": {
    "idRef": "28e08de4-d7b2-4521-84ce-bc1e5ee0b9cc"
  },
  "user": [

  ],
  "userGroup": [

  ]
},
{
  "id": "68970708-4c70-42ac-8ea1-5d5485713e3a",
  "instanceId": 15271,
  "authEntityId": 15271,
  "displayName": "15271",
  "authEntityClass": -1909073334,
  "deployPending": "NONE",
  "instanceVersion": 0,
  "createTime": 1517966744627,
  "deployed": false,
  "isSeeded": true,
  "isStale": false,
  "lastUpdateTime": 1517966744627,
  "name": "tenfold",
  "namespace": "scalablegroup:application",
  "provisioningState": "DEFINED",
  "qualifier": "application",
  "resourceVersion": 0,
  "targetIdList": [

  ],
  "type": "scalablegroup",
  "cfsChangeInfo": [

  ],
  "customProvisions": [

  ],
  "scalableGroupExternalHandle": "tenfold",
  "scalableGroupType": "APPLICATION",
  "securityGroupTag": 0,
  "state": "ACTIVE",
  "vnAgnostic": true,
  "identitySource": {
    "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
    "instanceId": 10010,
    "authEntityId": 10010,
    "displayName": "10010",
    "authEntityClass": 1738953278,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "state": "INACTIVE",
    "type": "NBAR"
  },
  "indicativeNetworkIdentity": [
    {
      "id": "e9c4edb3-94ed-4d83-8966-0a3a6623ff84",
      "instanceId": 17445,
      "authEntityId": 17445,
      "displayName": "17445",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "658",
      "protocol": "TCP",
      "upperPort": 0
    },
    {
      "id": "9f60d315-437d-4869-bb65-e4e3db0b9acb",
      "instanceId": 17446,
      "authEntityId": 17446,
      "displayName": "17446",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "658",
      "protocol": "UDP",
      "upperPort": 0
    }
  ],
  "networkApplications": [
    {
      "id": "f03f88aa-d823-4faf-9f2e-415ddb40bc93",
      "instanceId": 16214,
      "authEntityId": 16214,
      "displayName": "16214",
      "authEntityClass": -217092956,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "appProtocol": "tcp/udp",
      "applicationGroup": "other",
      "applicationType": "DEFAULT",
      "categoryId": "6daa3dfd-3526-407a-88f0-97824b3f3a24",
      "createTime": 1517966744627,
      "encrypted": "false",
      "engineId": "3",
      "helpString": "tenfold",
      "ignoreConflict": false,
      "lastUpdateTime": 1517966744627,
      "longDescription": "Registered with IANA on port 658 TCP/UDP",
      "name": "tenfold",
      "nbarId": "567",
      "p2pTechnology": "false",
      "popularity": 1,
      "rank": 65535,
      "references": "http://www.iana.org/assignments/service-names-port-numbers/service-names-port-numbers.xml",
      "selectorId": "658",
      "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
      "trafficClass": "BULK_DATA",
      "tunnel": "false"
    }
  ],
  "networkIdentity": [
    {
      "id": "6df27acb-9496-4fc8-b509-83af56919fbe",
      "instanceId": 17443,
      "authEntityId": 17443,
      "displayName": "17443",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "658",
      "protocol": "UDP",
      "upperPort": 0
    },
    {
      "id": "3d111194-a6ff-480a-a497-c9d583a32c54",
      "instanceId": 17444,
      "authEntityId": 17444,
      "displayName": "17444",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "658",
      "protocol": "TCP",
      "upperPort": 0
    }
  ],
  "parentScalableGroup": {
    "idRef": "000c15bf-28fe-4fbb-9b69-35e6502721f1"
  },
  "user": [

  ],
  "userGroup": [

  ]
},
{
  "id": "694d7a98-5450-46e9-a7b8-5c3d9c157ed2",
  "instanceId": 15023,
  "authEntityId": 15023,
  "displayName": "15023",
  "authEntityClass": -1909073334,
  "deployPending": "NONE",
  "instanceVersion": 0,
  "createTime": 1517966744050,
  "deployed": false,
  "isSeeded": true,
  "isStale": false,
  "lastUpdateTime": 1517966744050,
  "name": "quic",
  "namespace": "scalablegroup:application",
  "provisioningState": "DEFINED",
  "qualifier": "application",
  "resourceVersion": 0,
  "targetIdList": [

  ],
  "type": "scalablegroup",
  "cfsChangeInfo": [

  ],
  "customProvisions": [

  ],
  "scalableGroupExternalHandle": "quic",
  "scalableGroupType": "APPLICATION",
  "securityGroupTag": 0,
  "state": "ACTIVE",
  "vnAgnostic": true,
  "identitySource": {
    "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
    "instanceId": 10010,
    "authEntityId": 10010,
    "displayName": "10010",
    "authEntityClass": 1738953278,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "state": "INACTIVE",
    "type": "NBAR"
  },
  "indicativeNetworkIdentity": [

  ],
  "networkApplications": [
    {
      "id": "4946e1d9-fda0-4f8d-9198-17691004408d",
      "instanceId": 11962,
      "authEntityId": 11962,
      "displayName": "11962",
      "authEntityClass": -217092956,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "applicationGroup": "other",
      "applicationType": "DEFAULT",
      "categoryId": "878644b5-fe91-45b4-9204-31c84a73ba48",
      "createTime": 1517966744050,
      "encrypted": "true",
      "engineId": "13",
      "helpString": "A multiplexed stream transport over UDP, developed by Google",
      "ignoreConflict": false,
      "lastUpdateTime": 1517966744050,
      "longDescription": "QUIC (pronounced Quick) is a UDP based protocol developed by Google to accelerate web communications, saving round trip time and protocol overhead incurred by the traditional TCP connections. QUIC traffic is always encrypted.",
      "name": "quic",
      "nbarId": "1752",
      "p2pTechnology": "false",
      "popularity": 8,
      "rank": 65535,
      "references": "http://dev.chromium.org/quic",
      "selectorId": "778",
      "subCategory": "b90786f4-0190-45b8-9924-21b3e9682590",
      "trafficClass": "TRANSACTIONAL_DATA",
      "tunnel": "true"
    }
  ],
  "networkIdentity": [

  ],
  "parentScalableGroup": {
    "idRef": "53f03553-069a-4046-ad7c-84610eb579c5"
  },
  "user": [

  ],
  "userGroup": [

  ]
},
{
  "id": "699d300d-c1b1-477a-a6c9-48823515787a",
  "instanceId": 4920,
  "authEntityId": 4920,
  "displayName": "4920",
  "authEntityClass": -1909073334,
  "deployPending": "NONE",
  "instanceVersion": 0,
  "createTime": 1517966743767,
  "deployed": false,
  "isSeeded": true,
  "isStale": false,
  "lastUpdateTime": 1517966743767,
  "name": "oracle-sqlnet",
  "namespace": "scalablegroup:application",
  "provisioningState": "DEFINED",
  "qualifier": "application",
  "resourceVersion": 0,
  "targetIdList": [

  ],
  "type": "scalablegroup",
  "cfsChangeInfo": [

  ],
  "customProvisions": [

  ],
  "scalableGroupExternalHandle": "oracle-sqlnet",
  "scalableGroupType": "APPLICATION",
  "securityGroupTag": 0,
  "state": "ACTIVE",
  "vnAgnostic": true,
  "identitySource": {
    "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
    "instanceId": 10010,
    "authEntityId": 10010,
    "displayName": "10010",
    "authEntityClass": 1738953278,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "state": "INACTIVE",
    "type": "NBAR"
  },
  "indicativeNetworkIdentity": [
    {
      "id": "3b74237f-33f2-4181-9ccb-e11c4ca5d140",
      "instanceId": 14424,
      "authEntityId": 14424,
      "displayName": "14424",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "66",
      "protocol": "TCP",
      "upperPort": 0
    }
  ],
  "networkApplications": [
    {
      "id": "9ec38c3b-8444-4236-b776-4834386e9377",
      "instanceId": 11869,
      "authEntityId": 11869,
      "displayName": "11869",
      "authEntityClass": -217092956,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "appProtocol": "tcp",
      "applicationGroup": "sqlsvr-group",
      "applicationType": "DEFAULT",
      "categoryId": "cb5915d1-afec-4c30-ac9e-069ff382272f",
      "createTime": 1517966743767,
      "encrypted": "false",
      "engineId": "3",
      "helpString": "SQL*NET for Oracle",
      "ignoreConflict": false,
      "lastUpdateTime": 1517966743767,
      "longDescription": "SQL*NET is a client-server middleware used to transfer information between databases and between database to clients.",
      "name": "oracle-sqlnet",
      "nbarId": "1425",
      "p2pTechnology": "false",
      "popularity": 9,
      "rank": 65535,
      "references": "http://www.orafaq.com/wiki/SQL*Net",
      "selectorId": "66",
      "subCategory": "b90786f4-0190-45b8-9924-21b3e9682590",
      "trafficClass": "TRANSACTIONAL_DATA",
      "tunnel": "false"
    }
  ],
  "networkIdentity": [
    {
      "id": "e05f891f-0198-498f-bac9-914a0a212042",
      "instanceId": 14423,
      "authEntityId": 14423,
      "displayName": "14423",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "66",
      "protocol": "TCP",
      "upperPort": 0
    }
  ],
  "parentScalableGroup": {
    "idRef": "8718395e-683e-4a2d-a232-100202751271"
  },
  "user": [

  ],
  "userGroup": [

  ]
},
{
  "id": "69c320d0-e94e-4f19-9c7e-8bf396458efb",
  "instanceId": 15423,
  "authEntityId": 15423,
  "displayName": "15423",
  "authEntityClass": -1909073334,
  "deployPending": "NONE",
  "instanceVersion": 0,
  "createTime": 1517966744939,
  "deployed": false,
  "isSeeded": true,
  "isStale": false,
  "lastUpdateTime": 1517966744939,
  "name": "wlccp",
  "namespace": "scalablegroup:application",
  "provisioningState": "DEFINED",
  "qualifier": "application",
  "resourceVersion": 0,
  "targetIdList": [

  ],
  "type": "scalablegroup",
  "cfsChangeInfo": [

  ],
  "customProvisions": [

  ],
  "scalableGroupExternalHandle": "wlccp",
  "scalableGroupType": "APPLICATION",
  "securityGroupTag": 0,
  "state": "ACTIVE",
  "vnAgnostic": true,
  "identitySource": {
    "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
    "instanceId": 10010,
    "authEntityId": 10010,
    "displayName": "10010",
    "authEntityClass": 1738953278,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "state": "INACTIVE",
    "type": "NBAR"
  },
  "indicativeNetworkIdentity": [
    {
      "id": "70709729-a160-4cdf-b28b-d3f6ab9338a2",
      "instanceId": 17853,
      "authEntityId": 17853,
      "displayName": "17853",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "2887",
      "protocol": "TCP",
      "upperPort": 0
    },
    {
      "id": "3066bd52-3aad-4707-9571-07b675c09e34",
      "instanceId": 17854,
      "authEntityId": 17854,
      "displayName": "17854",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "2887",
      "protocol": "UDP",
      "upperPort": 0
    }
  ],
  "networkApplications": [
    {
      "id": "e581c449-2602-4a10-8732-aa87794d737d",
      "instanceId": 16366,
      "authEntityId": 16366,
      "displayName": "16366",
      "authEntityClass": -217092956,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "appProtocol": "tcp/udp",
      "applicationGroup": "other",
      "applicationType": "DEFAULT",
      "categoryId": "6daa3dfd-3526-407a-88f0-97824b3f3a24",
      "createTime": 1517966744939,
      "encrypted": "false",
      "engineId": "3",
      "helpString": "Wireless LAN Context Control Protocol",
      "ignoreConflict": false,
      "lastUpdateTime": 1517966744939,
      "longDescription": "Wireless LAN Context Control Protocol (WLCCP) is used by Cisco wireless access points and servers to manage a multiple access point wireless infrastructure called Wireless Domain Services (WDS).",
      "name": "wlccp",
      "nbarId": "1397",
      "p2pTechnology": "false",
      "popularity": 1,
      "rank": 65535,
      "references": "http://www.google.com/patents/US20050220054",
      "selectorId": "2887",
      "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
      "trafficClass": "BULK_DATA",
      "tunnel": "false"
    }
  ],
  "networkIdentity": [
    {
      "id": "b1a2c453-7724-47cd-9f3b-900988bb5ced",
      "instanceId": 17851,
      "authEntityId": 17851,
      "displayName": "17851",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "2887",
      "protocol": "UDP",
      "upperPort": 0
    },
    {
      "id": "12b067c9-cbbb-4dfd-a80f-88bd56fe5b03",
      "instanceId": 17852,
      "authEntityId": 17852,
      "displayName": "17852",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "2887",
      "protocol": "TCP",
      "upperPort": 0
    }
  ],
  "parentScalableGroup": {
    "idRef": "000c15bf-28fe-4fbb-9b69-35e6502721f1"
  },
  "user": [

  ],
  "userGroup": [

  ]
},
{
  "id": "6a58dd0e-9caa-48a5-849a-014228778856",
  "instanceId": 4259,
  "authEntityId": 4259,
  "displayName": "4259",
  "authEntityClass": -1909073334,
  "deployPending": "NONE",
  "instanceVersion": 0,
  "createTime": 1517966741968,
  "deployed": false,
  "isSeeded": true,
  "isStale": false,
  "lastUpdateTime": 1517966741968,
  "name": "citi-bank",
  "namespace": "scalablegroup:application",
  "provisioningState": "DEFINED",
  "qualifier": "application",
  "resourceVersion": 0,
  "targetIdList": [

  ],
  "type": "scalablegroup",
  "cfsChangeInfo": [

  ],
  "customProvisions": [

  ],
  "scalableGroupExternalHandle": "citi-bank",
  "scalableGroupType": "APPLICATION",
  "securityGroupTag": 0,
  "state": "ACTIVE",
  "vnAgnostic": true,
  "identitySource": {
    "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
    "instanceId": 10010,
    "authEntityId": 10010,
    "displayName": "10010",
    "authEntityClass": 1738953278,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "state": "INACTIVE",
    "type": "NBAR"
  },
  "indicativeNetworkIdentity": [

  ],
  "networkApplications": [
    {
      "id": "533eccc9-e13c-4615-9b14-6afc7c44bd1e",
      "instanceId": 11213,
      "authEntityId": 11213,
      "displayName": "11213",
      "authEntityClass": -217092956,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "appProtocol": "tcp/udp",
      "applicationGroup": "other",
      "applicationType": "DEFAULT",
      "categoryId": "878644b5-fe91-45b4-9204-31c84a73ba48",
      "createTime": 1517966741968,
      "encrypted": "false",
      "engineId": "13",
      "helpString": "Citi - Financial services company.",
      "ignoreConflict": false,
      "lastUpdateTime": 1517966741968,
      "longDescription": "Citi - Financial services company.",
      "name": "citi-bank",
      "nbarId": "1636",
      "p2pTechnology": "false",
      "popularity": 3,
      "rank": 65535,
      "references": "http://citibank.com",
      "selectorId": "706",
      "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
      "trafficClass": "TRANSACTIONAL_DATA",
      "tunnel": "false"
    }
  ],
  "networkIdentity": [
    {
      "id": "76b5c269-95c8-42f6-9f81-a5bc70b00c60",
      "instanceId": 12552,
      "authEntityId": 12552,
      "displayName": "12552",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "53,5353",
      "protocol": "UDP",
      "upperPort": 0
    },
    {
      "id": "c7b21e70-79cc-4585-8e42-664935061ca9",
      "instanceId": 12553,
      "authEntityId": 12553,
      "displayName": "12553",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "53,80,443,5353",
      "protocol": "TCP",
      "upperPort": 0
    }
  ],
  "parentScalableGroup": {
    "idRef": "28e08de4-d7b2-4521-84ce-bc1e5ee0b9cc"
  },
  "user": [

  ],
  "userGroup": [

  ]
},
{
  "id": "6ada256f-3f0e-4b76-9a59-76e6a02a796d",
  "instanceId": 4658,
  "authEntityId": 4658,
  "displayName": "4658",
  "authEntityClass": -1909073334,
  "deployPending": "NONE",
  "instanceVersion": 0,
  "createTime": 1517966743223,
  "deployed": false,
  "isSeeded": true,
  "isStale": false,
  "lastUpdateTime": 1517966743223,
  "name": "jabber",
  "namespace": "scalablegroup:application",
  "provisioningState": "DEFINED",
  "qualifier": "application",
  "resourceVersion": 0,
  "targetIdList": [

  ],
  "type": "scalablegroup",
  "cfsChangeInfo": [

  ],
  "customProvisions": [

  ],
  "scalableGroupExternalHandle": "jabber",
  "scalableGroupType": "APPLICATION",
  "securityGroupTag": 0,
  "state": "ACTIVE",
  "vnAgnostic": true,
  "identitySource": {
    "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
    "instanceId": 10010,
    "authEntityId": 10010,
    "displayName": "10010",
    "authEntityClass": 1738953278,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "state": "INACTIVE",
    "type": "NBAR"
  },
  "indicativeNetworkIdentity": [

  ],
  "networkApplications": [
    {
      "id": "c166dbf4-cf72-4337-aecd-dc8f8b1d813f",
      "instanceId": 11608,
      "authEntityId": 11608,
      "displayName": "11608",
      "authEntityClass": -217092956,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "appProtocol": "tcp/udp",
      "applicationGroup": "other",
      "applicationType": "DEFAULT",
      "categoryId": "0f6cab47-be95-4124-9db3-360ccd55f983",
      "createTime": 1517966743223,
      "encrypted": "true",
      "engineId": "13",
      "helpString": "Communications protocol for message-oriented middleware based on XML",
      "ignoreConflict": false,
      "lastUpdateTime": 1517966743223,
      "longDescription": "Communications protocol for message-oriented middleware based on XML (Extensible Markup Language). It enables the near-real-time exchange of structured yet extensible data between any two or more network entities.",
      "name": "jabber",
      "nbarId": "1038",
      "p2pTechnology": "false",
      "popularity": 10,
      "rank": 65535,
      "references": "http://www.jabber.org/",
      "selectorId": "1038",
      "subCategory": "118c10a2-e272-47c1-92b9-4814ebe97138",
      "trafficClass": "TRANSACTIONAL_DATA",
      "tunnel": "false"
    }
  ],
  "networkIdentity": [
    {
      "id": "a4d1c6b3-37d1-400d-b69e-4dd0e30c3c61",
      "instanceId": 13654,
      "authEntityId": 13654,
      "displayName": "13654",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "3478,5222",
      "protocol": "TCP",
      "upperPort": 0
    },
    {
      "id": "3ecccfc0-4148-44db-8b42-cdea744f78ff",
      "instanceId": 13655,
      "authEntityId": 13655,
      "displayName": "13655",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "3478,5222",
      "protocol": "UDP",
      "upperPort": 0
    }
  ],
  "parentScalableGroup": {
    "idRef": "60f1364d-2a02-4063-8ba1-07817abe0612"
  },
  "user": [

  ],
  "userGroup": [

  ]
},
{
  "id": "6ae0a571-fa7b-4639-ade8-230842a8c169",
  "instanceId": 4037,
  "authEntityId": 4037,
  "displayName": "4037",
  "authEntityClass": -1909073334,
  "deployPending": "NONE",
  "instanceVersion": 0,
  "createTime": 1517966740510,
  "deployed": false,
  "isSeeded": true,
  "isStale": false,
  "lastUpdateTime": 1517966740510,
  "name": "3pc",
  "namespace": "scalablegroup:application",
  "provisioningState": "DEFINED",
  "qualifier": "application",
  "resourceVersion": 0,
  "targetIdList": [

  ],
  "type": "scalablegroup",
  "cfsChangeInfo": [

  ],
  "customProvisions": [

  ],
  "scalableGroupExternalHandle": "3pc",
  "scalableGroupType": "APPLICATION",
  "securityGroupTag": 0,
  "state": "ACTIVE",
  "vnAgnostic": true,
  "identitySource": {
    "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
    "instanceId": 10010,
    "authEntityId": 10010,
    "displayName": "10010",
    "authEntityClass": 1738953278,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "state": "INACTIVE",
    "type": "NBAR"
  },
  "indicativeNetworkIdentity": [
    {
      "id": "3da85f93-ceeb-43d9-a5ed-d86c9d3baf8f",
      "instanceId": 12021,
      "authEntityId": 12021,
      "displayName": "12021",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipProtocolNumbers": "34",
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "protocol": "THREE_PC",
      "upperPort": 0
    }
  ],
  "networkApplications": [
    {
      "id": "046249ad-0d5e-48a6-9ef1-d5387d3de7a2",
      "instanceId": 11013,
      "authEntityId": 11013,
      "displayName": "11013",
      "authEntityClass": -217092956,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "appProtocol": "ip",
      "applicationGroup": "other",
      "applicationType": "DEFAULT",
      "categoryId": "6daa3dfd-3526-407a-88f0-97824b3f3a24",
      "createTime": 1517966740510,
      "encrypted": "unassigned",
      "engineId": "1",
      "helpString": "Third Party Connect Protocol",
      "ignoreConflict": false,
      "lastUpdateTime": 1517966740510,
      "longDescription": "Registered with IANA as IP Protocol 34",
      "name": "3pc",
      "nbarId": "788",
      "p2pTechnology": "unassigned",
      "popularity": 1,
      "rank": 65535,
      "references": "http://www.iana.org/assignments/protocol-numbers/protocol-numbers.xml",
      "selectorId": "34",
      "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
      "trafficClass": "BULK_DATA",
      "tunnel": "unassigned"
    }
  ],
  "networkIdentity": [
    {
      "id": "b429f983-5a93-4754-b9d6-bc8eecdee3f4",
      "instanceId": 12020,
      "authEntityId": 12020,
      "displayName": "12020",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipProtocolNumbers": "34",
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "protocol": "THREE_PC",
      "upperPort": 0
    }
  ],
  "parentScalableGroup": {
    "idRef": "000c15bf-28fe-4fbb-9b69-35e6502721f1"
  },
  "user": [

  ],
  "userGroup": [

  ]
},
{
  "id": "6afb9829-9862-43c5-8050-a73177b8544a",
  "instanceId": 4583,
  "authEntityId": 4583,
  "displayName": "4583",
  "authEntityClass": -1909073334,
  "deployPending": "NONE",
  "instanceVersion": 0,
  "createTime": 1517966743050,
  "deployed": false,
  "isSeeded": true,
  "isStale": false,
  "lastUpdateTime": 1517966743050,
  "name": "icq",
  "namespace": "scalablegroup:application",
  "provisioningState": "DEFINED",
  "qualifier": "application",
  "resourceVersion": 0,
  "targetIdList": [

  ],
  "type": "scalablegroup",
  "cfsChangeInfo": [

  ],
  "customProvisions": [

  ],
  "scalableGroupExternalHandle": "icq",
  "scalableGroupType": "APPLICATION",
  "securityGroupTag": 0,
  "state": "ACTIVE",
  "vnAgnostic": true,
  "identitySource": {
    "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
    "instanceId": 10010,
    "authEntityId": 10010,
    "displayName": "10010",
    "authEntityClass": 1738953278,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "state": "INACTIVE",
    "type": "NBAR"
  },
  "indicativeNetworkIdentity": [

  ],
  "networkApplications": [
    {
      "id": "6c174e13-2cdb-4813-b230-15c2cedb9912",
      "instanceId": 11533,
      "authEntityId": 11533,
      "displayName": "11533",
      "authEntityClass": -217092956,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "appProtocol": "tcp/udp",
      "applicationGroup": "icq-group",
      "applicationType": "DEFAULT",
      "categoryId": "0f6cab47-be95-4124-9db3-360ccd55f983",
      "createTime": 1517966743050,
      "encrypted": "false",
      "engineId": "13",
      "helpString": "I seek you (ICQ), Instant Messaging Protocol",
      "ignoreConflict": false,
      "lastUpdateTime": 1517966743050,
      "longDescription": "I seek you (ICQ) software is used for IM, text messaging, email, phone, and paging. The software runs on multiple platforms including PC, MAC, UNIX, pocket PC and Palm OS. ICQ is using AOL's OSCAR (Open System for CommunicAtion in Realtime). It was the first IM program and was developed by Mirabilis, then bought by AOL and currently owned by Digital Sky Technologies.",
      "name": "icq",
      "nbarId": "902",
      "p2pTechnology": "false",
      "popularity": 6,
      "rank": 65535,
      "references": "http://www.icq.com/en",
      "selectorId": "269",
      "subCategory": "118c10a2-e272-47c1-92b9-4814ebe97138",
      "trafficClass": "MULTIMEDIA_CONFERENCING",
      "tunnel": "false"
    }
  ],
  "networkIdentity": [
    {
      "id": "90360862-5756-48f4-b655-f455d888169d",
      "instanceId": 13467,
      "authEntityId": 13467,
      "displayName": "13467",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "53,80,443,5353",
      "protocol": "TCP",
      "upperPort": 0
    },
    {
      "id": "a582cc87-ba54-4a9c-a6de-91c92531c0b7",
      "instanceId": 13468,
      "authEntityId": 13468,
      "displayName": "13468",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "53,5353",
      "protocol": "UDP",
      "upperPort": 0
    }
  ],
  "parentScalableGroup": {
    "idRef": "fd6264c9-c16a-4311-a335-a3db039256f3"
  },
  "user": [

  ],
  "userGroup": [

  ]
},
{
  "id": "6b37a2f7-eb36-496f-bfba-906e79171ace",
  "instanceId": 4425,
  "authEntityId": 4425,
  "displayName": "4425",
  "authEntityClass": -1909073334,
  "deployPending": "NONE",
  "instanceVersion": 0,
  "createTime": 1517966742612,
  "deployed": false,
  "isSeeded": true,
  "isStale": false,
  "lastUpdateTime": 1517966742612,
  "name": "esro-emsdp",
  "namespace": "scalablegroup:application",
  "provisioningState": "DEFINED",
  "qualifier": "application",
  "resourceVersion": 0,
  "targetIdList": [

  ],
  "type": "scalablegroup",
  "cfsChangeInfo": [

  ],
  "customProvisions": [

  ],
  "scalableGroupExternalHandle": "esro-emsdp",
  "scalableGroupType": "APPLICATION",
  "securityGroupTag": 0,
  "state": "ACTIVE",
  "vnAgnostic": true,
  "identitySource": {
    "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
    "instanceId": 10010,
    "authEntityId": 10010,
    "displayName": "10010",
    "authEntityClass": 1738953278,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "state": "INACTIVE",
    "type": "NBAR"
  },
  "indicativeNetworkIdentity": [
    {
      "id": "74ba3251-3ff5-4c46-bf2c-c8ce85ef3907",
      "instanceId": 13072,
      "authEntityId": 13072,
      "displayName": "13072",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "642",
      "protocol": "UDP",
      "upperPort": 0
    },
    {
      "id": "84792bc0-c598-4ff6-b052-003ffc66c4a2",
      "instanceId": 13071,
      "authEntityId": 13071,
      "displayName": "13071",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "642",
      "protocol": "TCP",
      "upperPort": 0
    }
  ],
  "networkApplications": [
    {
      "id": "20c66a38-bd9c-4a28-ae5c-bc857c9bc6a7",
      "instanceId": 11376,
      "authEntityId": 11376,
      "displayName": "11376",
      "authEntityClass": -217092956,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "appProtocol": "tcp/udp",
      "applicationGroup": "other",
      "applicationType": "DEFAULT",
      "categoryId": "6daa3dfd-3526-407a-88f0-97824b3f3a24",
      "createTime": 1517966742612,
      "encrypted": "false",
      "engineId": "3",
      "helpString": "ESRO-EMSDP V1.3",
      "ignoreConflict": false,
      "lastUpdateTime": 1517966742612,
      "longDescription": "Registered with IANA on port 642 TCP/UDP",
      "name": "esro-emsdp",
      "nbarId": "551",
      "p2pTechnology": "false",
      "popularity": 1,
      "rank": 65535,
      "references": "http://www.iana.org/assignments/service-names-port-numbers/service-names-port-numbers.xml",
      "selectorId": "642",
      "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
      "trafficClass": "BULK_DATA",
      "tunnel": "false"
    }
  ],
  "networkIdentity": [
    {
      "id": "b772eeee-e94d-4986-bae4-93570a98c4c2",
      "instanceId": 13069,
      "authEntityId": 13069,
      "displayName": "13069",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "642",
      "protocol": "TCP",
      "upperPort": 0
    },
    {
      "id": "07627055-5251-4671-af4b-b1de5b919666",
      "instanceId": 13070,
      "authEntityId": 13070,
      "displayName": "13070",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "642",
      "protocol": "UDP",
      "upperPort": 0
    }
  ],
  "parentScalableGroup": {
    "idRef": "000c15bf-28fe-4fbb-9b69-35e6502721f1"
  },
  "user": [

  ],
  "userGroup": [

  ]
},
{
  "id": "6b86fbe5-0b74-40f3-a2f7-3a508d8017e6",
  "instanceId": 4843,
  "authEntityId": 4843,
  "displayName": "4843",
  "authEntityClass": -1909073334,
  "deployPending": "NONE",
  "instanceVersion": 0,
  "createTime": 1517966743599,
  "deployed": false,
  "isSeeded": true,
  "isStale": false,
  "lastUpdateTime": 1517966743599,
  "name": "netop-remote-control",
  "namespace": "scalablegroup:application",
  "provisioningState": "DEFINED",
  "qualifier": "application",
  "resourceVersion": 0,
  "targetIdList": [

  ],
  "type": "scalablegroup",
  "cfsChangeInfo": [

  ],
  "customProvisions": [

  ],
  "scalableGroupExternalHandle": "netop-remote-control",
  "scalableGroupType": "APPLICATION",
  "securityGroupTag": 0,
  "state": "ACTIVE",
  "vnAgnostic": true,
  "identitySource": {
    "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
    "instanceId": 10010,
    "authEntityId": 10010,
    "displayName": "10010",
    "authEntityClass": 1738953278,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "state": "INACTIVE",
    "type": "NBAR"
  },
  "indicativeNetworkIdentity": [
    {
      "id": "e0f40f9c-3013-4b1e-92f2-3f54a9403dd9",
      "instanceId": 14158,
      "authEntityId": 14158,
      "displayName": "14158",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "1970,1971,6502,26137",
      "protocol": "UDP",
      "upperPort": 0
    },
    {
      "id": "0136ac1e-e99b-4d20-939e-e691c5ef9f41",
      "instanceId": 14159,
      "authEntityId": 14159,
      "displayName": "14159",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "1970,1971,6502",
      "protocol": "TCP",
      "upperPort": 0
    }
  ],
  "networkApplications": [
    {
      "id": "ae125603-f515-45da-bad5-d9e1e5cf67f4",
      "instanceId": 11792,
      "authEntityId": 11792,
      "displayName": "11792",
      "authEntityClass": -217092956,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "appProtocol": "tcp/udp",
      "applicationGroup": "other",
      "applicationType": "DEFAULT",
      "categoryId": "6daa3dfd-3526-407a-88f0-97824b3f3a24",
      "createTime": 1517966743599,
      "encrypted": "false",
      "engineId": "3",
      "helpString": "Remote control management and support software",
      "ignoreConflict": false,
      "lastUpdateTime": 1517966743599,
      "longDescription": "Netop Remote Control  provides  solutions for the remote management and support of enterprise IT infrastructure, help desk, customer service, kiosk and POS devices. The cross-platform capabilities make it possible to access the most common operating systems from any operating system of the same selection. Connection over the Internet is possible to both pre-installed clients as well as on-demand clients. Netop supports access to computers not having loaded the operating system for maintenance of BIOS etc.",
      "name": "netop-remote-control",
      "nbarId": "1399",
      "p2pTechnology": "false",
      "popularity": 1,
      "rank": 65535,
      "references": "http://www.netop.com/products/administration/remote.htm",
      "selectorId": "1970",
      "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
      "trafficClass": "BULK_DATA",
      "tunnel": "false"
    }
  ],
  "networkIdentity": [
    {
      "id": "e8efeafa-0635-4c1b-ae90-8832af1dbbbd",
      "instanceId": 14156,
      "authEntityId": 14156,
      "displayName": "14156",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "1970,1971,6502,26137",
      "protocol": "UDP",
      "upperPort": 0
    },
    {
      "id": "97f95559-6607-4760-8a9a-b5a4a4959ae2",
      "instanceId": 14157,
      "authEntityId": 14157,
      "displayName": "14157",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "1970,1971,6502",
      "protocol": "TCP",
      "upperPort": 0
    }
  ],
  "parentScalableGroup": {
    "idRef": "000c15bf-28fe-4fbb-9b69-35e6502721f1"
  },
  "user": [

  ],
  "userGroup": [

  ]
},
{
  "id": "6b911722-0fed-4013-91e9-939980d00985",
  "instanceId": 15222,
  "authEntityId": 15222,
  "displayName": "15222",
  "authEntityClass": -1909073334,
  "deployPending": "NONE",
  "instanceVersion": 0,
  "createTime": 1517966744525,
  "deployed": false,
  "isSeeded": true,
  "isStale": false,
  "lastUpdateTime": 1517966744525,
  "name": "streettalk",
  "namespace": "scalablegroup:application",
  "provisioningState": "DEFINED",
  "qualifier": "application",
  "resourceVersion": 0,
  "targetIdList": [

  ],
  "type": "scalablegroup",
  "cfsChangeInfo": [

  ],
  "customProvisions": [

  ],
  "scalableGroupExternalHandle": "streettalk",
  "scalableGroupType": "APPLICATION",
  "securityGroupTag": 0,
  "state": "ACTIVE",
  "vnAgnostic": true,
  "identitySource": {
    "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
    "instanceId": 10010,
    "authEntityId": 10010,
    "displayName": "10010",
    "authEntityClass": 1738953278,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "state": "INACTIVE",
    "type": "NBAR"
  },
  "indicativeNetworkIdentity": [
    {
      "id": "7c60e819-a08e-4664-bb3b-fea5e5ed8f02",
      "instanceId": 17294,
      "authEntityId": 17294,
      "displayName": "17294",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "566",
      "protocol": "UDP",
      "upperPort": 0
    },
    {
      "id": "28d98814-78b7-4c41-944a-6c5a430ec0de",
      "instanceId": 17295,
      "authEntityId": 17295,
      "displayName": "17295",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "566",
      "protocol": "TCP",
      "upperPort": 0
    }
  ],
  "networkApplications": [
    {
      "id": "e42d53eb-2f57-4fe0-bd9b-0ff5341ce7b0",
      "instanceId": 16165,
      "authEntityId": 16165,
      "displayName": "16165",
      "authEntityClass": -217092956,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "appProtocol": "tcp/udp",
      "applicationGroup": "banyan-group",
      "applicationType": "DEFAULT",
      "categoryId": "6daa3dfd-3526-407a-88f0-97824b3f3a24",
      "createTime": 1517966744525,
      "encrypted": "false",
      "engineId": "3",
      "helpString": "streettalk",
      "ignoreConflict": false,
      "lastUpdateTime": 1517966744525,
      "longDescription": "Registered with IANA on port 566 TCP/UDP",
      "name": "streettalk",
      "nbarId": "481",
      "p2pTechnology": "false",
      "popularity": 1,
      "rank": 65535,
      "references": "http://www.iana.org/assignments/service-names-port-numbers/service-names-port-numbers.xml",
      "selectorId": "566",
      "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
      "trafficClass": "BULK_DATA",
      "tunnel": "false"
    }
  ],
  "networkIdentity": [
    {
      "id": "83aacebb-fb3a-41af-abec-eee7d9e212b6",
      "instanceId": 17292,
      "authEntityId": 17292,
      "displayName": "17292",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "566",
      "protocol": "TCP",
      "upperPort": 0
    },
    {
      "id": "39e4afa2-badf-489f-800e-4213d548a080",
      "instanceId": 17293,
      "authEntityId": 17293,
      "displayName": "17293",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "566",
      "protocol": "UDP",
      "upperPort": 0
    }
  ],
  "parentScalableGroup": {
    "idRef": "000c15bf-28fe-4fbb-9b69-35e6502721f1"
  },
  "user": [

  ],
  "userGroup": [

  ]
},
{
  "id": "6bbc1474-cd8f-4d79-a697-7863fea0a4b5",
  "instanceId": 4902,
  "authEntityId": 4902,
  "displayName": "4902",
  "authEntityClass": -1909073334,
  "deployPending": "NONE",
  "instanceVersion": 0,
  "createTime": 1517966743717,
  "deployed": false,
  "isSeeded": true,
  "isStale": false,
  "lastUpdateTime": 1517966743717,
  "name": "ohimsrv",
  "namespace": "scalablegroup:application",
  "provisioningState": "DEFINED",
  "qualifier": "application",
  "resourceVersion": 0,
  "targetIdList": [

  ],
  "type": "scalablegroup",
  "cfsChangeInfo": [

  ],
  "customProvisions": [

  ],
  "scalableGroupExternalHandle": "ohimsrv",
  "scalableGroupType": "APPLICATION",
  "securityGroupTag": 0,
  "state": "ACTIVE",
  "vnAgnostic": true,
  "identitySource": {
    "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
    "instanceId": 10010,
    "authEntityId": 10010,
    "displayName": "10010",
    "authEntityClass": 1738953278,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "state": "INACTIVE",
    "type": "NBAR"
  },
  "indicativeNetworkIdentity": [
    {
      "id": "3aa56296-8b0a-48bc-b37a-9b494c94ac5f",
      "instanceId": 14362,
      "authEntityId": 14362,
      "displayName": "14362",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "506",
      "protocol": "TCP",
      "upperPort": 0
    },
    {
      "id": "308b6d18-6773-488a-a503-4ff9a79e984b",
      "instanceId": 14363,
      "authEntityId": 14363,
      "displayName": "14363",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "506",
      "protocol": "UDP",
      "upperPort": 0
    }
  ],
  "networkApplications": [
    {
      "id": "50f3b24a-98fa-4413-9ec7-d32a2050237f",
      "instanceId": 11851,
      "authEntityId": 11851,
      "displayName": "11851",
      "authEntityClass": -217092956,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "appProtocol": "tcp/udp",
      "applicationGroup": "other",
      "applicationType": "DEFAULT",
      "categoryId": "6daa3dfd-3526-407a-88f0-97824b3f3a24",
      "createTime": 1517966743717,
      "encrypted": "false",
      "engineId": "3",
      "helpString": "ohimsrv",
      "ignoreConflict": false,
      "lastUpdateTime": 1517966743717,
      "longDescription": "Registered with IANA on port 506 TCP/UDP",
      "name": "ohimsrv",
      "nbarId": "420",
      "p2pTechnology": "false",
      "popularity": 1,
      "rank": 65535,
      "references": "http://www.iana.org/assignments/service-names-port-numbers/service-names-port-numbers.xml",
      "selectorId": "506",
      "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
      "trafficClass": "BULK_DATA",
      "tunnel": "false"
    }
  ],
  "networkIdentity": [
    {
      "id": "6f108a41-7e8e-4969-a0c0-617940bdf201",
      "instanceId": 14360,
      "authEntityId": 14360,
      "displayName": "14360",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "506",
      "protocol": "UDP",
      "upperPort": 0
    },
    {
      "id": "fe93a085-05b9-4060-b673-462765e49d36",
      "instanceId": 14361,
      "authEntityId": 14361,
      "displayName": "14361",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "506",
      "protocol": "TCP",
      "upperPort": 0
    }
  ],
  "parentScalableGroup": {
    "idRef": "000c15bf-28fe-4fbb-9b69-35e6502721f1"
  },
  "user": [

  ],
  "userGroup": [

  ]
},
{
  "id": "6c116c29-4cdf-4264-900f-b09387acd316",
  "instanceId": 4870,
  "authEntityId": 4870,
  "displayName": "4870",
  "authEntityClass": -1909073334,
  "deployPending": "NONE",
  "instanceVersion": 0,
  "createTime": 1517966743651,
  "deployed": false,
  "isSeeded": true,
  "isStale": false,
  "lastUpdateTime": 1517966743651,
  "name": "nmsp",
  "namespace": "scalablegroup:application",
  "provisioningState": "DEFINED",
  "qualifier": "application",
  "resourceVersion": 0,
  "targetIdList": [

  ],
  "type": "scalablegroup",
  "cfsChangeInfo": [

  ],
  "customProvisions": [

  ],
  "scalableGroupExternalHandle": "nmsp",
  "scalableGroupType": "APPLICATION",
  "securityGroupTag": 0,
  "state": "ACTIVE",
  "vnAgnostic": true,
  "identitySource": {
    "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
    "instanceId": 10010,
    "authEntityId": 10010,
    "displayName": "10010",
    "authEntityClass": 1738953278,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "state": "INACTIVE",
    "type": "NBAR"
  },
  "indicativeNetworkIdentity": [
    {
      "id": "15647cfc-e3b0-4c1f-b531-450a1f33cce0",
      "instanceId": 14254,
      "authEntityId": 14254,
      "displayName": "14254",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "537",
      "protocol": "UDP",
      "upperPort": 0
    },
    {
      "id": "ced7386e-7b81-4f2a-80e9-411406a859b4",
      "instanceId": 14255,
      "authEntityId": 14255,
      "displayName": "14255",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "537",
      "protocol": "TCP",
      "upperPort": 0
    }
  ],
  "networkApplications": [
    {
      "id": "2eb82d59-1b6e-4354-9748-8a29eac79809",
      "instanceId": 11819,
      "authEntityId": 11819,
      "displayName": "11819",
      "authEntityClass": -217092956,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "appProtocol": "tcp/udp",
      "applicationGroup": "other",
      "applicationType": "DEFAULT",
      "categoryId": "a09ef1b3-ba26-4372-aee8-309d50697e76",
      "createTime": 1517966743651,
      "encrypted": "false",
      "engineId": "3",
      "helpString": "Networked Media Streaming Protocol",
      "ignoreConflict": false,
      "lastUpdateTime": 1517966743651,
      "longDescription": "Registered with IANA on port 537 TCP/UDP",
      "name": "nmsp",
      "nbarId": "455",
      "p2pTechnology": "false",
      "popularity": 1,
      "rank": 65535,
      "references": "http://www.iana.org/assignments/service-names-port-numbers/service-names-port-numbers.xml",
      "selectorId": "537",
      "subCategory": "85a797c0-aef8-4284-91a7-677f33e29abb",
      "trafficClass": "MULTIMEDIA_STREAMING",
      "tunnel": "false"
    }
  ],
  "networkIdentity": [
    {
      "id": "b60dfed8-9d3e-4033-9537-764f83e8241a",
      "instanceId": 14252,
      "authEntityId": 14252,
      "displayName": "14252",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "537",
      "protocol": "TCP",
      "upperPort": 0
    },
    {
      "id": "ca9151c9-7657-4708-b06c-5ecc98e9a86b",
      "instanceId": 14253,
      "authEntityId": 14253,
      "displayName": "14253",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "537",
      "protocol": "UDP",
      "upperPort": 0
    }
  ],
  "parentScalableGroup": {
    "idRef": "fd6264c9-c16a-4311-a335-a3db039256f3"
  },
  "user": [

  ],
  "userGroup": [

  ]
},
{
  "id": "6c2af226-6189-434b-b2ef-dd45c531ed1f",
  "instanceId": 4187,
  "authEntityId": 4187,
  "displayName": "4187",
  "authEntityClass": -1909073334,
  "deployPending": "NONE",
  "instanceVersion": 0,
  "createTime": 1517966741610,
  "deployed": false,
  "isSeeded": true,
  "isStale": false,
  "lastUpdateTime": 1517966741610,
  "name": "blackboard-com",
  "namespace": "scalablegroup:application",
  "provisioningState": "DEFINED",
  "qualifier": "application",
  "resourceVersion": 0,
  "targetIdList": [

  ],
  "type": "scalablegroup",
  "cfsChangeInfo": [

  ],
  "customProvisions": [

  ],
  "scalableGroupExternalHandle": "blackboard-com",
  "scalableGroupType": "APPLICATION",
  "securityGroupTag": 0,
  "state": "ACTIVE",
  "vnAgnostic": true,
  "identitySource": {
    "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
    "instanceId": 10010,
    "authEntityId": 10010,
    "displayName": "10010",
    "authEntityClass": 1738953278,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "state": "INACTIVE",
    "type": "NBAR"
  },
  "indicativeNetworkIdentity": [

  ],
  "networkApplications": [
    {
      "id": "48c6d9a3-d9fe-4e98-bc07-472d66fa120f",
      "instanceId": 11146,
      "authEntityId": 11146,
      "displayName": "11146",
      "authEntityClass": -217092956,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "appProtocol": "tcp/udp",
      "applicationGroup": "other",
      "applicationType": "DEFAULT",
      "categoryId": "878644b5-fe91-45b4-9204-31c84a73ba48",
      "createTime": 1517966741610,
      "encrypted": "false",
      "engineId": "13",
      "helpString": "Blackboard - Online course management system.",
      "ignoreConflict": false,
      "lastUpdateTime": 1517966741610,
      "longDescription": "Blackboard - Online course management system.",
      "name": "blackboard-com",
      "nbarId": "1677",
      "p2pTechnology": "false",
      "popularity": 3,
      "rank": 65535,
      "references": "http://blackboard.com",
      "selectorId": "747",
      "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
      "trafficClass": "TRANSACTIONAL_DATA",
      "tunnel": "false"
    }
  ],
  "networkIdentity": [
    {
      "id": "38145372-07f8-4d9a-bc59-cef3fdfb1afc",
      "instanceId": 12403,
      "authEntityId": 12403,
      "displayName": "12403",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "53,5353",
      "protocol": "UDP",
      "upperPort": 0
    },
    {
      "id": "6a146dc3-c34f-4e3c-b2c3-4dbe3397e6ac",
      "instanceId": 12404,
      "authEntityId": 12404,
      "displayName": "12404",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "53,80,443,5353",
      "protocol": "TCP",
      "upperPort": 0
    }
  ],
  "parentScalableGroup": {
    "idRef": "28e08de4-d7b2-4521-84ce-bc1e5ee0b9cc"
  },
  "user": [

  ],
  "userGroup": [

  ]
},
{
  "id": "6c3b067d-e9c9-4225-a995-a6849baa7b3b",
  "instanceId": 15443,
  "authEntityId": 15443,
  "displayName": "15443",
  "authEntityClass": -1909073334,
  "deployPending": "NONE",
  "instanceVersion": 0,
  "createTime": 1517966744974,
  "deployed": false,
  "isSeeded": true,
  "isStale": false,
  "lastUpdateTime": 1517966744974,
  "name": "xns-courier",
  "namespace": "scalablegroup:application",
  "provisioningState": "DEFINED",
  "qualifier": "application",
  "resourceVersion": 0,
  "targetIdList": [

  ],
  "type": "scalablegroup",
  "cfsChangeInfo": [

  ],
  "customProvisions": [

  ],
  "scalableGroupExternalHandle": "xns-courier",
  "scalableGroupType": "APPLICATION",
  "securityGroupTag": 0,
  "state": "ACTIVE",
  "vnAgnostic": true,
  "identitySource": {
    "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
    "instanceId": 10010,
    "authEntityId": 10010,
    "displayName": "10010",
    "authEntityClass": 1738953278,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "state": "INACTIVE",
    "type": "NBAR"
  },
  "indicativeNetworkIdentity": [
    {
      "id": "f019e38f-5ad1-4322-a848-ee300a1431a9",
      "instanceId": 17915,
      "authEntityId": 17915,
      "displayName": "17915",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "165",
      "protocol": "TCP",
      "upperPort": 0
    },
    {
      "id": "60c1f1e7-bb9c-44bf-b8cd-7acf2d03841b",
      "instanceId": 17916,
      "authEntityId": 17916,
      "displayName": "17916",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "165",
      "protocol": "UDP",
      "upperPort": 0
    }
  ],
  "networkApplications": [
    {
      "id": "c1eceb36-9a75-489b-8e6e-5c7129f69db0",
      "instanceId": 16386,
      "authEntityId": 16386,
      "displayName": "16386",
      "authEntityClass": -217092956,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "appProtocol": "tcp/udp",
      "applicationGroup": "xns-xerox-group",
      "applicationType": "DEFAULT",
      "categoryId": "27953c60-b2ff-4e1a-bc52-0187d8295250",
      "createTime": 1517966744974,
      "encrypted": "false",
      "engineId": "3",
      "helpString": "Xerox",
      "ignoreConflict": false,
      "lastUpdateTime": 1517966744974,
      "longDescription": "Xerox Network Services (XNS) Courier is a remote procedure call protocol above which run Application protocols of XNS protocol suite.This suite provided general purpose network communications, internetwork routing and packet delivery, including higher level functions such as a reliable stream, and remote procedure calls. XNS predated and influenced the development of the Open Systems Interconnect (OSI) networking model.",
      "name": "xns-courier",
      "nbarId": "1010",
      "p2pTechnology": "false",
      "popularity": 1,
      "rank": 65535,
      "references": "http://www.pulsewan.com/data101/pdfs/xns.pdf",
      "selectorId": "165",
      "subCategory": "b90786f4-0190-45b8-9924-21b3e9682590",
      "trafficClass": "BULK_DATA",
      "tunnel": "false"
    }
  ],
  "networkIdentity": [
    {
      "id": "27118bd7-0624-4a6f-8b02-fe741eb0da1b",
      "instanceId": 17913,
      "authEntityId": 17913,
      "displayName": "17913",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "165",
      "protocol": "TCP",
      "upperPort": 0
    },
    {
      "id": "3bfe351a-c694-4959-ae52-766b7e8ddcd6",
      "instanceId": 17914,
      "authEntityId": 17914,
      "displayName": "17914",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "165",
      "protocol": "UDP",
      "upperPort": 0
    }
  ],
  "parentScalableGroup": {
    "idRef": "8a7ca43a-32bd-40d2-871a-02220a165cb7"
  },
  "user": [

  ],
  "userGroup": [

  ]
},
{
  "id": "6c55faaf-476c-47f7-9eaa-5786abd34111",
  "instanceId": 4844,
  "authEntityId": 4844,
  "displayName": "4844",
  "authEntityClass": -1909073334,
  "deployPending": "NONE",
  "instanceVersion": 0,
  "createTime": 1517966743601,
  "deployed": false,
  "isSeeded": true,
  "isStale": false,
  "lastUpdateTime": 1517966743601,
  "name": "netrcs",
  "namespace": "scalablegroup:application",
  "provisioningState": "DEFINED",
  "qualifier": "application",
  "resourceVersion": 0,
  "targetIdList": [

  ],
  "type": "scalablegroup",
  "cfsChangeInfo": [

  ],
  "customProvisions": [

  ],
  "scalableGroupExternalHandle": "netrcs",
  "scalableGroupType": "APPLICATION",
  "securityGroupTag": 0,
  "state": "ACTIVE",
  "vnAgnostic": true,
  "identitySource": {
    "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
    "instanceId": 10010,
    "authEntityId": 10010,
    "displayName": "10010",
    "authEntityClass": 1738953278,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "state": "INACTIVE",
    "type": "NBAR"
  },
  "indicativeNetworkIdentity": [
    {
      "id": "743719ba-c8d5-44ea-b93e-9385c0cfafb8",
      "instanceId": 14162,
      "authEntityId": 14162,
      "displayName": "14162",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "742",
      "protocol": "TCP",
      "upperPort": 0
    },
    {
      "id": "12db4b1c-e7ee-4421-ae1f-2da2b51bee6f",
      "instanceId": 14163,
      "authEntityId": 14163,
      "displayName": "14163",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "742",
      "protocol": "UDP",
      "upperPort": 0
    }
  ],
  "networkApplications": [
    {
      "id": "92bec8a2-a9c2-4fcd-a454-03f90c80d215",
      "instanceId": 11793,
      "authEntityId": 11793,
      "displayName": "11793",
      "authEntityClass": -217092956,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "appProtocol": "tcp/udp",
      "applicationGroup": "other",
      "applicationType": "DEFAULT",
      "categoryId": "6daa3dfd-3526-407a-88f0-97824b3f3a24",
      "createTime": 1517966743601,
      "encrypted": "false",
      "engineId": "3",
      "helpString": "Network based RCS",
      "ignoreConflict": false,
      "lastUpdateTime": 1517966743601,
      "longDescription": "Registered with IANA on port 742 TCP/UDP",
      "name": "netrcs",
      "nbarId": "619",
      "p2pTechnology": "false",
      "popularity": 1,
      "rank": 65535,
      "references": "http://www.iana.org/assignments/service-names-port-numbers/service-names-port-numbers.xml",
      "selectorId": "742",
      "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
      "trafficClass": "BULK_DATA",
      "tunnel": "false"
    }
  ],
  "networkIdentity": [
    {
      "id": "613e3720-6505-4641-b0f4-d58ee079d63e",
      "instanceId": 14160,
      "authEntityId": 14160,
      "displayName": "14160",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "742",
      "protocol": "TCP",
      "upperPort": 0
    },
    {
      "id": "74d89e95-d7d5-431c-b019-f5d8a26dabd3",
      "instanceId": 14161,
      "authEntityId": 14161,
      "displayName": "14161",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "742",
      "protocol": "UDP",
      "upperPort": 0
    }
  ],
  "parentScalableGroup": {
    "idRef": "000c15bf-28fe-4fbb-9b69-35e6502721f1"
  },
  "user": [

  ],
  "userGroup": [

  ]
},
{
  "id": "6ce1a44b-b8cc-4081-9746-eed0620bb3cf",
  "instanceId": 4535,
  "authEntityId": 4535,
  "displayName": "4535",
  "authEntityClass": -1909073334,
  "deployPending": "NONE",
  "instanceVersion": 0,
  "createTime": 1517966742925,
  "deployed": false,
  "isSeeded": true,
  "isStale": false,
  "lastUpdateTime": 1517966742925,
  "name": "hdap",
  "namespace": "scalablegroup:application",
  "provisioningState": "DEFINED",
  "qualifier": "application",
  "resourceVersion": 0,
  "targetIdList": [

  ],
  "type": "scalablegroup",
  "cfsChangeInfo": [

  ],
  "customProvisions": [

  ],
  "scalableGroupExternalHandle": "hdap",
  "scalableGroupType": "APPLICATION",
  "securityGroupTag": 0,
  "state": "ACTIVE",
  "vnAgnostic": true,
  "identitySource": {
    "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
    "instanceId": 10010,
    "authEntityId": 10010,
    "displayName": "10010",
    "authEntityClass": 1738953278,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "state": "INACTIVE",
    "type": "NBAR"
  },
  "indicativeNetworkIdentity": [
    {
      "id": "a4054b4c-7f92-4512-aaeb-e8afd9d8e518",
      "instanceId": 13328,
      "authEntityId": 13328,
      "displayName": "13328",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "263",
      "protocol": "UDP",
      "upperPort": 0
    },
    {
      "id": "5ffd1faf-653a-4353-b93a-48f5a2bd2f6d",
      "instanceId": 13327,
      "authEntityId": 13327,
      "displayName": "13327",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "263",
      "protocol": "TCP",
      "upperPort": 0
    }
  ],
  "networkApplications": [
    {
      "id": "8554cd53-5f2b-4556-bf9c-c9298c1903aa",
      "instanceId": 11486,
      "authEntityId": 11486,
      "displayName": "11486",
      "authEntityClass": -217092956,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "appProtocol": "tcp/udp",
      "applicationGroup": "other",
      "applicationType": "DEFAULT",
      "categoryId": "1fc17362-9334-4c7b-b752-5afdbb574017",
      "createTime": 1517966742925,
      "encrypted": "false",
      "engineId": "3",
      "helpString": "hdap",
      "ignoreConflict": false,
      "lastUpdateTime": 1517966742925,
      "longDescription": "Registered with IANA on port 263 TCP/UDP",
      "name": "hdap",
      "nbarId": "1135",
      "p2pTechnology": "false",
      "popularity": 1,
      "rank": 65535,
      "references": "http://www.iana.org/assignments/service-names-port-numbers/service-names-port-numbers.xml",
      "selectorId": "263",
      "subCategory": "e390124a-0fd6-4253-b68e-4b0c1af8cb4b",
      "trafficClass": "OPS_ADMIN_MGMT",
      "tunnel": "false"
    }
  ],
  "networkIdentity": [
    {
      "id": "bb8bf3da-a940-4ee0-bc90-37795aea00f5",
      "instanceId": 13325,
      "authEntityId": 13325,
      "displayName": "13325",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "263",
      "protocol": "TCP",
      "upperPort": 0
    },
    {
      "id": "24fa81b9-a4d5-442b-89eb-931f8d89bf60",
      "instanceId": 13326,
      "authEntityId": 13326,
      "displayName": "13326",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "263",
      "protocol": "UDP",
      "upperPort": 0
    }
  ],
  "parentScalableGroup": {
    "idRef": "7788bf2d-e289-4597-b500-17aacb7e820e"
  },
  "user": [

  ],
  "userGroup": [

  ]
},
{
  "id": "6d088c5d-22a1-42fa-823f-66069bd42bc9",
  "instanceId": 4226,
  "authEntityId": 4226,
  "displayName": "4226",
  "authEntityClass": -1909073334,
  "deployPending": "NONE",
  "instanceVersion": 0,
  "createTime": 1517966741837,
  "deployed": false,
  "isSeeded": true,
  "isStale": false,
  "lastUpdateTime": 1517966741837,
  "name": "chshell",
  "namespace": "scalablegroup:application",
  "provisioningState": "DEFINED",
  "qualifier": "application",
  "resourceVersion": 0,
  "targetIdList": [

  ],
  "type": "scalablegroup",
  "cfsChangeInfo": [

  ],
  "customProvisions": [

  ],
  "scalableGroupExternalHandle": "chshell",
  "scalableGroupType": "APPLICATION",
  "securityGroupTag": 0,
  "state": "ACTIVE",
  "vnAgnostic": true,
  "identitySource": {
    "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
    "instanceId": 10010,
    "authEntityId": 10010,
    "displayName": "10010",
    "authEntityClass": 1738953278,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "state": "INACTIVE",
    "type": "NBAR"
  },
  "indicativeNetworkIdentity": [
    {
      "id": "7f085bb6-24ff-4f73-a200-1632fd113627",
      "instanceId": 12497,
      "authEntityId": 12497,
      "displayName": "12497",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "562",
      "protocol": "UDP",
      "upperPort": 0
    },
    {
      "id": "25c347c6-0f19-44ae-820c-3f90c4b8bf47",
      "instanceId": 12498,
      "authEntityId": 12498,
      "displayName": "12498",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "562",
      "protocol": "TCP",
      "upperPort": 0
    }
  ],
  "networkApplications": [
    {
      "id": "d5d17b1e-04c1-4c44-a019-59f60fc1b572",
      "instanceId": 11181,
      "authEntityId": 11181,
      "displayName": "11181",
      "authEntityClass": -217092956,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "appProtocol": "tcp/udp",
      "applicationGroup": "other",
      "applicationType": "DEFAULT",
      "categoryId": "1fc17362-9334-4c7b-b752-5afdbb574017",
      "createTime": 1517966741837,
      "encrypted": "false",
      "engineId": "3",
      "helpString": "chcmd",
      "ignoreConflict": false,
      "lastUpdateTime": 1517966741837,
      "longDescription": "Registered with IANA on port 562 TCP/UDP",
      "name": "chshell",
      "nbarId": "477",
      "p2pTechnology": "false",
      "popularity": 1,
      "rank": 65535,
      "references": "http://www.iana.org/assignments/service-names-port-numbers/service-names-port-numbers.xml",
      "selectorId": "562",
      "subCategory": "787a7d77-977b-4974-89d3-379ba49e88b7",
      "trafficClass": "OPS_ADMIN_MGMT",
      "tunnel": "false"
    }
  ],
  "networkIdentity": [
    {
      "id": "d254270b-fe2a-4252-9592-506095cf7969",
      "instanceId": 12496,
      "authEntityId": 12496,
      "displayName": "12496",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "562",
      "protocol": "UDP",
      "upperPort": 0
    },
    {
      "id": "8c598116-66fe-4724-8abc-28095ce45e44",
      "instanceId": 12495,
      "authEntityId": 12495,
      "displayName": "12495",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "562",
      "protocol": "TCP",
      "upperPort": 0
    }
  ],
  "parentScalableGroup": {
    "idRef": "e06dd89f-0a70-4422-ab0b-c2314650a9a7"
  },
  "user": [

  ],
  "userGroup": [

  ]
},
{
  "id": "6d0c7d33-4017-4062-ac14-ec99761b6910",
  "instanceId": 15257,
  "authEntityId": 15257,
  "displayName": "15257",
  "authEntityClass": -1909073334,
  "deployPending": "NONE",
  "instanceVersion": 0,
  "createTime": 1517966744594,
  "deployed": false,
  "isSeeded": true,
  "isStale": false,
  "lastUpdateTime": 1517966744594,
  "name": "td-replica",
  "namespace": "scalablegroup:application",
  "provisioningState": "DEFINED",
  "qualifier": "application",
  "resourceVersion": 0,
  "targetIdList": [

  ],
  "type": "scalablegroup",
  "cfsChangeInfo": [

  ],
  "customProvisions": [

  ],
  "scalableGroupExternalHandle": "td-replica",
  "scalableGroupType": "APPLICATION",
  "securityGroupTag": 0,
  "state": "ACTIVE",
  "vnAgnostic": true,
  "identitySource": {
    "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
    "instanceId": 10010,
    "authEntityId": 10010,
    "displayName": "10010",
    "authEntityClass": 1738953278,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "state": "INACTIVE",
    "type": "NBAR"
  },
  "indicativeNetworkIdentity": [
    {
      "id": "e5592eda-ba80-44c3-b06b-8b9cde5f34d2",
      "instanceId": 17410,
      "authEntityId": 17410,
      "displayName": "17410",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "268",
      "protocol": "UDP",
      "upperPort": 0
    },
    {
      "id": "9a0a98fa-5f22-4f90-9e02-086343202881",
      "instanceId": 17411,
      "authEntityId": 17411,
      "displayName": "17411",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "268",
      "protocol": "TCP",
      "upperPort": 0
    }
  ],
  "networkApplications": [
    {
      "id": "6d1e4095-e5e2-465d-bbbf-d0158c06cdb7",
      "instanceId": 16200,
      "authEntityId": 16200,
      "displayName": "16200",
      "authEntityClass": -217092956,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "appProtocol": "tcp/udp",
      "applicationGroup": "other",
      "applicationType": "DEFAULT",
      "categoryId": "80909d4c-efe6-4878-9e1e-68281fd26edd",
      "createTime": 1517966744594,
      "encrypted": "false",
      "engineId": "3",
      "helpString": "Tobit David Replica",
      "ignoreConflict": false,
      "lastUpdateTime": 1517966744594,
      "longDescription": "The Replication Services for David enable a replication of the contents of any archives that are stored on different David Servers. David (from Tobit Software) os a mail access server that also provides other services, such as: fax, voice and document management.",
      "name": "td-replica",
      "nbarId": "1140",
      "p2pTechnology": "false",
      "popularity": 1,
      "rank": 65535,
      "references": "http://www.tobit.com",
      "selectorId": "268",
      "subCategory": "4a197877-986d-4422-b48f-500fb4b3340f",
      "trafficClass": "BULK_DATA",
      "tunnel": "false"
    }
  ],
  "networkIdentity": [
    {
      "id": "0e240b32-e55c-4557-87ea-05987d4bd694",
      "instanceId": 17408,
      "authEntityId": 17408,
      "displayName": "17408",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "268",
      "protocol": "TCP",
      "upperPort": 0
    },
    {
      "id": "8b177a8f-9241-4d18-9486-a37d7b803905",
      "instanceId": 17409,
      "authEntityId": 17409,
      "displayName": "17409",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "268",
      "protocol": "UDP",
      "upperPort": 0
    }
  ],
  "parentScalableGroup": {
    "idRef": "f09a36de-df38-4ff8-b0be-56738afc75f7"
  },
  "user": [

  ],
  "userGroup": [

  ]
},
{
  "id": "6d3dc5b7-7850-49b9-9c4f-31ca7535e555",
  "instanceId": 4577,
  "authEntityId": 4577,
  "displayName": "4577",
  "authEntityClass": -1909073334,
  "deployPending": "NONE",
  "instanceVersion": 0,
  "createTime": 1517966743036,
  "deployed": false,
  "isSeeded": true,
  "isStale": false,
  "lastUpdateTime": 1517966743036,
  "name": "ibm-director",
  "namespace": "scalablegroup:application",
  "provisioningState": "DEFINED",
  "qualifier": "application",
  "resourceVersion": 0,
  "targetIdList": [

  ],
  "type": "scalablegroup",
  "cfsChangeInfo": [

  ],
  "customProvisions": [

  ],
  "scalableGroupExternalHandle": "ibm-director",
  "scalableGroupType": "APPLICATION",
  "securityGroupTag": 0,
  "state": "ACTIVE",
  "vnAgnostic": true,
  "identitySource": {
    "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
    "instanceId": 10010,
    "authEntityId": 10010,
    "displayName": "10010",
    "authEntityClass": 1738953278,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "state": "INACTIVE",
    "type": "NBAR"
  },
  "indicativeNetworkIdentity": [
    {
      "id": "9a7f72ac-a166-431c-a0e7-7c7c2de09647",
      "instanceId": 13449,
      "authEntityId": 13449,
      "displayName": "13449",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "4490,4491,6090,13991,14247,14248,15988,15989,34572",
      "protocol": "UDP",
      "upperPort": 0
    },
    {
      "id": "b47f9b98-02b9-451a-b811-4878ae2c0f42",
      "instanceId": 13450,
      "authEntityId": 13450,
      "displayName": "13450",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "4490,4491,6090,14247,14248,14249,15988,15989,34572",
      "protocol": "TCP",
      "upperPort": 0
    }
  ],
  "networkApplications": [
    {
      "id": "66ca46a0-b515-46c8-b8c5-86a338eec3fa",
      "instanceId": 11527,
      "authEntityId": 11527,
      "displayName": "11527",
      "authEntityClass": -217092956,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "appProtocol": "tcp/udp",
      "applicationGroup": "other",
      "applicationType": "DEFAULT",
      "categoryId": "6daa3dfd-3526-407a-88f0-97824b3f3a24",
      "createTime": 1517966743036,
      "encrypted": "false",
      "engineId": "3",
      "helpString": "Element management system",
      "ignoreConflict": false,
      "lastUpdateTime": 1517966743036,
      "longDescription": "IBM Director is an element management system that manages the operation of a set of connected network resources and monitors their performance. IBM Director works on multiple server platforms including Windows and Linux. The software typically uses the TCP/UDP ports 15988, 15989, 34572, 4491, 6090, 13991, 14247-14249.",
      "name": "ibm-director",
      "nbarId": "1398",
      "p2pTechnology": "false",
      "popularity": 3,
      "rank": 65535,
      "references": "http://www.ibm.com/systems/management/director",
      "selectorId": "4490",
      "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
      "trafficClass": "BULK_DATA",
      "tunnel": "false"
    }
  ],
  "networkIdentity": [
    {
      "id": "152af8db-2978-4013-92bf-9f9dcaacacee",
      "instanceId": 13447,
      "authEntityId": 13447,
      "displayName": "13447",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "4490,4491,6090,13991,14247,14248,15988,15989,34572",
      "protocol": "UDP",
      "upperPort": 0
    },
    {
      "id": "902aec01-1a0a-43d6-923d-02463ecd15d8",
      "instanceId": 13448,
      "authEntityId": 13448,
      "displayName": "13448",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "4490,4491,6090,14247,14248,14249,15988,15989,34572",
      "protocol": "TCP",
      "upperPort": 0
    }
  ],
  "parentScalableGroup": {
    "idRef": "000c15bf-28fe-4fbb-9b69-35e6502721f1"
  },
  "user": [

  ],
  "userGroup": [

  ]
},
{
  "id": "6d5052f1-3987-4bb5-9034-0716330c916b",
  "instanceId": 4262,
  "authEntityId": 4262,
  "displayName": "4262",
  "authEntityClass": -1909073334,
  "deployPending": "NONE",
  "instanceVersion": 0,
  "createTime": 1517966741980,
  "deployed": false,
  "isSeeded": true,
  "isStale": false,
  "lastUpdateTime": 1517966741980,
  "name": "citrix-static",
  "namespace": "scalablegroup:application",
  "provisioningState": "DEFINED",
  "qualifier": "application",
  "resourceVersion": 0,
  "targetIdList": [

  ],
  "type": "scalablegroup",
  "cfsChangeInfo": [

  ],
  "customProvisions": [

  ],
  "scalableGroupExternalHandle": "citrix-static",
  "scalableGroupType": "APPLICATION",
  "securityGroupTag": 0,
  "state": "ACTIVE",
  "vnAgnostic": true,
  "identitySource": {
    "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
    "instanceId": 10010,
    "authEntityId": 10010,
    "displayName": "10010",
    "authEntityClass": 1738953278,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "state": "INACTIVE",
    "type": "NBAR"
  },
  "indicativeNetworkIdentity": [
    {
      "id": "07cd272c-127d-4825-8930-99982563b122",
      "instanceId": 12560,
      "authEntityId": 12560,
      "displayName": "12560",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "1604,2512,2513",
      "protocol": "UDP",
      "upperPort": 0
    },
    {
      "id": "3ff52c9f-ad12-4b57-9a40-842104c4a15f",
      "instanceId": 12561,
      "authEntityId": 12561,
      "displayName": "12561",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "1494,1604,2512,2513,2598",
      "protocol": "TCP",
      "upperPort": 0
    }
  ],
  "networkApplications": [
    {
      "id": "1753a07e-58be-499d-969a-56f6c6e47695",
      "instanceId": 11216,
      "authEntityId": 11216,
      "displayName": "11216",
      "authEntityClass": -217092956,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "appProtocol": "tcp/udp",
      "applicationGroup": "other",
      "applicationType": "DEFAULT",
      "categoryId": "7e19fdea-6222-4c57-9e66-66c2340d6bc3",
      "createTime": 1517966741979,
      "encrypted": "false",
      "engineId": "3",
      "helpString": "Citrix Static",
      "ignoreConflict": false,
      "lastUpdateTime": 1517966741979,
      "longDescription": "Citrix is an application that mediates users remotely to their corporate applications. ICA:  Independed Computing Architecture is a designated protocol for application server system; it is used for transferring data between clients and servers.CGP: CGP is a tunneling protocol, the latest addition to the family of Citrix protocol.As of today it encapsulates ICA protocol but will be extended to other Citrix protocol such as RDP, HTTP/HTTPS.IMA: used for server-server communication. Server-Browser: Used mainly a control connection which has Published Application Name and triggers an ICA connection",
      "name": "citrix-static",
      "nbarId": "1433",
      "p2pTechnology": "false",
      "popularity": 7,
      "rank": 65535,
      "references": "http://www.citrix.com/site/resources/dynamic/additional/ICA_Acceleration_0709a.pdf",
      "selectorId": "1604",
      "subCategory": "ff664205-64f3-447e-acff-1ec1ba9a6c31",
      "trafficClass": "MULTIMEDIA_STREAMING",
      "tunnel": "false"
    }
  ],
  "networkIdentity": [
    {
      "id": "995ea279-d5c6-4adc-ad29-a342599b974c",
      "instanceId": 12558,
      "authEntityId": 12558,
      "displayName": "12558",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "1494,1604,2512,2513,2598",
      "protocol": "TCP",
      "upperPort": 0
    },
    {
      "id": "1b42401a-9179-4b33-b51c-7a0ca7db81f2",
      "instanceId": 12559,
      "authEntityId": 12559,
      "displayName": "12559",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "1604,2512,2513",
      "protocol": "UDP",
      "upperPort": 0
    }
  ],
  "parentScalableGroup": {
    "idRef": "4a6b37c6-7e11-40fe-a6b1-719b24813145"
  },
  "user": [

  ],
  "userGroup": [

  ]
},
{
  "id": "6d60573d-6cc5-46cb-ba4c-c5b1bd4186af",
  "instanceId": 4440,
  "authEntityId": 4440,
  "displayName": "4440",
  "authEntityClass": -1909073334,
  "deployPending": "NONE",
  "instanceVersion": 0,
  "createTime": 1517966742664,
  "deployed": false,
  "isSeeded": true,
  "isStale": false,
  "lastUpdateTime": 1517966742664,
  "name": "fasttrack",
  "namespace": "scalablegroup:application",
  "provisioningState": "DEFINED",
  "qualifier": "application",
  "resourceVersion": 0,
  "targetIdList": [

  ],
  "type": "scalablegroup",
  "cfsChangeInfo": [

  ],
  "customProvisions": [

  ],
  "scalableGroupExternalHandle": "fasttrack",
  "scalableGroupType": "APPLICATION",
  "securityGroupTag": 0,
  "state": "ACTIVE",
  "vnAgnostic": true,
  "identitySource": {
    "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
    "instanceId": 10010,
    "authEntityId": 10010,
    "displayName": "10010",
    "authEntityClass": 1738953278,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "state": "INACTIVE",
    "type": "NBAR"
  },
  "indicativeNetworkIdentity": [

  ],
  "networkApplications": [
    {
      "id": "a800c297-c192-4d14-8d99-991a7cd36790",
      "instanceId": 11391,
      "authEntityId": 11391,
      "displayName": "11391",
      "authEntityClass": -217092956,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "applicationGroup": "other",
      "applicationType": "DEFAULT",
      "categoryId": "6daa3dfd-3526-407a-88f0-97824b3f3a24",
      "createTime": 1517966742664,
      "encrypted": "unassigned",
      "engineId": "13",
      "helpString": "DEPRECATED, traffic will not match",
      "ignoreConflict": false,
      "lastUpdateTime": 1517966742664,
      "longDescription": "FastTrack is a file sharing client software that is based on peer-to-peer connection. FastTrack is used by multiple file sharing applications such as Kazaa, Grokster, iMesh, and Morpheus. Initialization: Initial the connection with FastTrack server over HTTP. Search: Searching for files in FastTrack server. Download: Download request from FastTracker server.",
      "name": "fasttrack",
      "nbarId": "57",
      "p2pTechnology": "unassigned",
      "popularity": 0,
      "rank": 65535,
      "references": "http://developer.berlios.de/projects/gift-fasttrack/",
      "selectorId": "57",
      "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
      "trafficClass": "BULK_DATA",
      "tunnel": "unassigned"
    }
  ],
  "networkIdentity": [

  ],
  "parentScalableGroup": {
    "idRef": "000c15bf-28fe-4fbb-9b69-35e6502721f1"
  },
  "user": [

  ],
  "userGroup": [

  ]
},
{
  "id": "6d63a1dc-3b09-4278-8d21-4149634dc012",
  "instanceId": 4161,
  "authEntityId": 4161,
  "displayName": "4161",
  "authEntityClass": -1909073334,
  "deployPending": "NONE",
  "instanceVersion": 0,
  "createTime": 1517966741446,
  "deployed": false,
  "isSeeded": true,
  "isStale": false,
  "lastUpdateTime": 1517966741446,
  "name": "bandcamp-com",
  "namespace": "scalablegroup:application",
  "provisioningState": "DEFINED",
  "qualifier": "application",
  "resourceVersion": 0,
  "targetIdList": [

  ],
  "type": "scalablegroup",
  "cfsChangeInfo": [

  ],
  "customProvisions": [

  ],
  "scalableGroupExternalHandle": "bandcamp-com",
  "scalableGroupType": "APPLICATION",
  "securityGroupTag": 0,
  "state": "ACTIVE",
  "vnAgnostic": true,
  "identitySource": {
    "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
    "instanceId": 10010,
    "authEntityId": 10010,
    "displayName": "10010",
    "authEntityClass": 1738953278,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "state": "INACTIVE",
    "type": "NBAR"
  },
  "indicativeNetworkIdentity": [

  ],
  "networkApplications": [
    {
      "id": "7709de59-ccff-40f8-8b95-401952e1b3df",
      "instanceId": 11121,
      "authEntityId": 11121,
      "displayName": "11121",
      "authEntityClass": -217092956,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "appProtocol": "tcp/udp",
      "applicationGroup": "other",
      "applicationType": "DEFAULT",
      "categoryId": "878644b5-fe91-45b4-9204-31c84a73ba48",
      "createTime": 1517966741446,
      "encrypted": "false",
      "engineId": "13",
      "helpString": "Bandcamp - Explore online music posted by independent artists.",
      "ignoreConflict": false,
      "lastUpdateTime": 1517966741446,
      "longDescription": "Bandcamp - Explore online music posted by independent artists.",
      "name": "bandcamp-com",
      "nbarId": "1567",
      "p2pTechnology": "false",
      "popularity": 3,
      "rank": 65535,
      "references": "http://bandcamp.com",
      "selectorId": "638",
      "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
      "trafficClass": "TRANSACTIONAL_DATA",
      "tunnel": "false"
    }
  ],
  "networkIdentity": [
    {
      "id": "05d73485-6eff-4828-8cc9-1d10edd8a11e",
      "instanceId": 12332,
      "authEntityId": 12332,
      "displayName": "12332",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "53,5353",
      "protocol": "UDP",
      "upperPort": 0
    },
    {
      "id": "c7bb5109-4152-47c8-95fc-5374655fa912",
      "instanceId": 12333,
      "authEntityId": 12333,
      "displayName": "12333",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "53,80,443,5353",
      "protocol": "TCP",
      "upperPort": 0
    }
  ],
  "parentScalableGroup": {
    "idRef": "28e08de4-d7b2-4521-84ce-bc1e5ee0b9cc"
  },
  "user": [

  ],
  "userGroup": [

  ]
},
{
  "id": "6da05575-9434-4b64-b7ee-de300cc0a8c7",
  "instanceId": 15328,
  "authEntityId": 15328,
  "displayName": "15328",
  "authEntityClass": -1909073334,
  "deployPending": "NONE",
  "instanceVersion": 0,
  "createTime": 1517966744747,
  "deployed": false,
  "isSeeded": true,
  "isStale": false,
  "lastUpdateTime": 1517966744747,
  "name": "urm",
  "namespace": "scalablegroup:application",
  "provisioningState": "DEFINED",
  "qualifier": "application",
  "resourceVersion": 0,
  "targetIdList": [

  ],
  "type": "scalablegroup",
  "cfsChangeInfo": [

  ],
  "customProvisions": [

  ],
  "scalableGroupExternalHandle": "urm",
  "scalableGroupType": "APPLICATION",
  "securityGroupTag": 0,
  "state": "ACTIVE",
  "vnAgnostic": true,
  "identitySource": {
    "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
    "instanceId": 10010,
    "authEntityId": 10010,
    "displayName": "10010",
    "authEntityClass": 1738953278,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "state": "INACTIVE",
    "type": "NBAR"
  },
  "indicativeNetworkIdentity": [
    {
      "id": "8cd13723-d16e-40a2-9de7-5573666f87f9",
      "instanceId": 17603,
      "authEntityId": 17603,
      "displayName": "17603",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "606",
      "protocol": "UDP",
      "upperPort": 0
    },
    {
      "id": "38c14828-1386-42af-a21e-582025f1fdca",
      "instanceId": 17604,
      "authEntityId": 17604,
      "displayName": "17604",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "606",
      "protocol": "TCP",
      "upperPort": 0
    }
  ],
  "networkApplications": [
    {
      "id": "b8874fdb-6745-4d04-a456-2bb984e5ed8a",
      "instanceId": 16271,
      "authEntityId": 16271,
      "displayName": "16271",
      "authEntityClass": -217092956,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "appProtocol": "tcp/udp",
      "applicationGroup": "other",
      "applicationType": "DEFAULT",
      "categoryId": "1fc17362-9334-4c7b-b752-5afdbb574017",
      "createTime": 1517966744747,
      "encrypted": "false",
      "engineId": "3",
      "helpString": "Cray Unified Resource Manager",
      "ignoreConflict": false,
      "lastUpdateTime": 1517966744747,
      "longDescription": "Registered with IANA on port 606 TCP/UDP",
      "name": "urm",
      "nbarId": "515",
      "p2pTechnology": "false",
      "popularity": 1,
      "rank": 65535,
      "references": "http://www.iana.org/assignments/service-names-port-numbers/service-names-port-numbers.xml",
      "selectorId": "606",
      "subCategory": "f3c0f761-d894-4112-ab89-b90fc84fd660",
      "trafficClass": "OPS_ADMIN_MGMT",
      "tunnel": "false"
    }
  ],
  "networkIdentity": [
    {
      "id": "eaa0ce3a-60d7-4349-839a-8566c3558f14",
      "instanceId": 17601,
      "authEntityId": 17601,
      "displayName": "17601",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "606",
      "protocol": "UDP",
      "upperPort": 0
    },
    {
      "id": "d275927f-8c4d-47b5-a5fa-b23ac2a064b5",
      "instanceId": 17602,
      "authEntityId": 17602,
      "displayName": "17602",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "606",
      "protocol": "TCP",
      "upperPort": 0
    }
  ],
  "parentScalableGroup": {
    "idRef": "5fcd74d4-8726-47f7-a088-c457863da7ef"
  },
  "user": [

  ],
  "userGroup": [

  ]
},
{
  "id": "6e147741-dff4-46b8-8920-65641e41fa3b",
  "instanceId": 15381,
  "authEntityId": 15381,
  "displayName": "15381",
  "authEntityClass": -1909073334,
  "deployPending": "NONE",
  "instanceVersion": 0,
  "createTime": 1517966744843,
  "deployed": false,
  "isSeeded": true,
  "isStale": false,
  "lastUpdateTime": 1517966744843,
  "name": "wap-vcard-s",
  "namespace": "scalablegroup:application",
  "provisioningState": "DEFINED",
  "qualifier": "application",
  "resourceVersion": 0,
  "targetIdList": [

  ],
  "type": "scalablegroup",
  "cfsChangeInfo": [

  ],
  "customProvisions": [

  ],
  "scalableGroupExternalHandle": "wap-vcard-s",
  "scalableGroupType": "APPLICATION",
  "securityGroupTag": 0,
  "state": "ACTIVE",
  "vnAgnostic": true,
  "identitySource": {
    "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
    "instanceId": 10010,
    "authEntityId": 10010,
    "displayName": "10010",
    "authEntityClass": 1738953278,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "state": "INACTIVE",
    "type": "NBAR"
  },
  "indicativeNetworkIdentity": [
    {
      "id": "4a39e8a0-1f12-413e-846c-00687d2afd1d",
      "instanceId": 17767,
      "authEntityId": 17767,
      "displayName": "17767",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "9206",
      "protocol": "TCP",
      "upperPort": 0
    },
    {
      "id": "fdaf7b17-61ea-4349-b0ba-2c11666f0752",
      "instanceId": 17768,
      "authEntityId": 17768,
      "displayName": "17768",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "9206",
      "protocol": "UDP",
      "upperPort": 0
    }
  ],
  "networkApplications": [
    {
      "id": "411147cf-f448-4cff-bb10-7452636ff80a",
      "instanceId": 16324,
      "authEntityId": 16324,
      "displayName": "16324",
      "authEntityClass": -217092956,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "appProtocol": "tcp/udp",
      "applicationGroup": "wap-group",
      "applicationType": "DEFAULT",
      "categoryId": "6daa3dfd-3526-407a-88f0-97824b3f3a24",
      "createTime": 1517966744843,
      "encrypted": "true",
      "engineId": "3",
      "helpString": "WAP vCard Secure",
      "ignoreConflict": false,
      "lastUpdateTime": 1517966744843,
      "longDescription": "Registered with IANA on port 9206 TCP/UDP",
      "name": "wap-vcard-s",
      "nbarId": "735",
      "p2pTechnology": "false",
      "popularity": 1,
      "rank": 65535,
      "references": "http://www.iana.org/assignments/service-names-port-numbers/service-names-port-numbers.xml",
      "selectorId": "9206",
      "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
      "trafficClass": "BULK_DATA",
      "tunnel": "false"
    }
  ],
  "networkIdentity": [
    {
      "id": "aab69862-2ed2-4aef-8da1-b3fd2771eab8",
      "instanceId": 17765,
      "authEntityId": 17765,
      "displayName": "17765",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "9206",
      "protocol": "TCP",
      "upperPort": 0
    },
    {
      "id": "d8ccd376-dd9f-4386-8ebc-b5845bfc4293",
      "instanceId": 17766,
      "authEntityId": 17766,
      "displayName": "17766",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "9206",
      "protocol": "UDP",
      "upperPort": 0
    }
  ],
  "parentScalableGroup": {
    "idRef": "000c15bf-28fe-4fbb-9b69-35e6502721f1"
  },
  "user": [

  ],
  "userGroup": [

  ]
},
{
  "id": "6e1a5cae-8656-49b2-92b4-1be70f35f9ab",
  "instanceId": 4623,
  "authEntityId": 4623,
  "displayName": "4623",
  "authEntityClass": -1909073334,
  "deployPending": "NONE",
  "instanceVersion": 0,
  "createTime": 1517966743148,
  "deployed": false,
  "isSeeded": true,
  "isStale": false,
  "lastUpdateTime": 1517966743148,
  "name": "ipinip",
  "namespace": "scalablegroup:application",
  "provisioningState": "DEFINED",
  "qualifier": "application",
  "resourceVersion": 0,
  "targetIdList": [

  ],
  "type": "scalablegroup",
  "cfsChangeInfo": [

  ],
  "customProvisions": [

  ],
  "scalableGroupExternalHandle": "ipinip",
  "scalableGroupType": "APPLICATION",
  "securityGroupTag": 0,
  "state": "ACTIVE",
  "vnAgnostic": true,
  "identitySource": {
    "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
    "instanceId": 10010,
    "authEntityId": 10010,
    "displayName": "10010",
    "authEntityClass": 1738953278,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "state": "INACTIVE",
    "type": "NBAR"
  },
  "indicativeNetworkIdentity": [
    {
      "id": "77e99091-fa34-4e0d-84e0-48d4eb76b442",
      "instanceId": 13572,
      "authEntityId": 13572,
      "displayName": "13572",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipProtocolNumbers": "4",
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "protocol": "IP",
      "upperPort": 0
    }
  ],
  "networkApplications": [
    {
      "id": "a4377bea-c965-4e86-88cc-0975034fde6c",
      "instanceId": 11573,
      "authEntityId": 11573,
      "displayName": "11573",
      "authEntityClass": -217092956,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "appProtocol": "ip",
      "applicationGroup": "other",
      "applicationType": "DEFAULT",
      "categoryId": "6daa3dfd-3526-407a-88f0-97824b3f3a24",
      "createTime": 1517966743148,
      "encrypted": "false",
      "engineId": "1",
      "helpString": "IP in IP",
      "ignoreConflict": false,
      "lastUpdateTime": 1517966743148,
      "longDescription": "IP in IP tunneling is a protocol used to encapsulate IP headers  to a different IP header to share information between endpoints in different internet-networks (for example forwarding traffic from one intranet to another).",
      "name": "ipinip",
      "nbarId": "8",
      "p2pTechnology": "false",
      "popularity": 5,
      "rank": 65535,
      "references": "http://tools.ietf.org/html/rfc1853",
      "selectorId": "4",
      "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
      "trafficClass": "BULK_DATA",
      "tunnel": "true"
    }
  ],
  "networkIdentity": [
    {
      "id": "8a245eaa-fee6-4145-987c-185e9e2910ff",
      "instanceId": 13571,
      "authEntityId": 13571,
      "displayName": "13571",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipProtocolNumbers": "4",
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "protocol": "IP",
      "upperPort": 0
    }
  ],
  "parentScalableGroup": {
    "idRef": "000c15bf-28fe-4fbb-9b69-35e6502721f1"
  },
  "user": [

  ],
  "userGroup": [

  ]
},
{
  "id": "6e2d74ee-eb25-475f-bb9e-73d6029764cf",
  "instanceId": 4447,
  "authEntityId": 4447,
  "displayName": "4447",
  "authEntityClass": -1909073334,
  "deployPending": "NONE",
  "instanceVersion": 0,
  "createTime": 1517966742690,
  "deployed": false,
  "isSeeded": true,
  "isStale": false,
  "lastUpdateTime": 1517966742690,
  "name": "feedly",
  "namespace": "scalablegroup:application",
  "provisioningState": "DEFINED",
  "qualifier": "application",
  "resourceVersion": 0,
  "targetIdList": [

  ],
  "type": "scalablegroup",
  "cfsChangeInfo": [

  ],
  "customProvisions": [

  ],
  "scalableGroupExternalHandle": "feedly",
  "scalableGroupType": "APPLICATION",
  "securityGroupTag": 0,
  "state": "ACTIVE",
  "vnAgnostic": true,
  "identitySource": {
    "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
    "instanceId": 10010,
    "authEntityId": 10010,
    "displayName": "10010",
    "authEntityClass": 1738953278,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "state": "INACTIVE",
    "type": "NBAR"
  },
  "indicativeNetworkIdentity": [

  ],
  "networkApplications": [
    {
      "id": "6894c326-232a-4119-80f7-0e56538be5c9",
      "instanceId": 11398,
      "authEntityId": 11398,
      "displayName": "11398",
      "authEntityClass": -217092956,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "appProtocol": "tcp/udp",
      "applicationGroup": "other",
      "applicationType": "DEFAULT",
      "categoryId": "878644b5-fe91-45b4-9204-31c84a73ba48",
      "createTime": 1517966742690,
      "encrypted": "false",
      "engineId": "13",
      "helpString": "Feedly - News Aggregator.",
      "ignoreConflict": false,
      "lastUpdateTime": 1517966742690,
      "longDescription": "Feedly - News Aggregator.",
      "name": "feedly",
      "nbarId": "1581",
      "p2pTechnology": "false",
      "popularity": 3,
      "rank": 65535,
      "references": "http://feedly.com",
      "selectorId": "652",
      "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
      "trafficClass": "TRANSACTIONAL_DATA",
      "tunnel": "false"
    }
  ],
  "networkIdentity": [
    {
      "id": "b0416a9c-5b05-4760-b973-7886b6c6a0f1",
      "instanceId": 13116,
      "authEntityId": 13116,
      "displayName": "13116",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "53,80,443,5353",
      "protocol": "TCP",
      "upperPort": 0
    },
    {
      "id": "3183d3b0-81af-485b-8ac0-18aeb04d3bf9",
      "instanceId": 13117,
      "authEntityId": 13117,
      "displayName": "13117",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "53,5353",
      "protocol": "UDP",
      "upperPort": 0
    }
  ],
  "parentScalableGroup": {
    "idRef": "28e08de4-d7b2-4521-84ce-bc1e5ee0b9cc"
  },
  "user": [

  ],
  "userGroup": [

  ]
},
{
  "id": "6e5664c9-1f38-4a6e-a07f-76ae01548b1d",
  "instanceId": 4792,
  "authEntityId": 4792,
  "displayName": "4792",
  "authEntityClass": -1909073334,
  "deployPending": "NONE",
  "instanceVersion": 0,
  "createTime": 1517966743503,
  "deployed": false,
  "isSeeded": true,
  "isStale": false,
  "lastUpdateTime": 1517966743503,
  "name": "ms-update",
  "namespace": "scalablegroup:application",
  "provisioningState": "DEFINED",
  "qualifier": "application",
  "resourceVersion": 0,
  "targetIdList": [

  ],
  "type": "scalablegroup",
  "cfsChangeInfo": [

  ],
  "customProvisions": [

  ],
  "scalableGroupExternalHandle": "ms-update",
  "scalableGroupType": "APPLICATION",
  "securityGroupTag": 0,
  "state": "ACTIVE",
  "vnAgnostic": true,
  "identitySource": {
    "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
    "instanceId": 10010,
    "authEntityId": 10010,
    "displayName": "10010",
    "authEntityClass": 1738953278,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "state": "INACTIVE",
    "type": "NBAR"
  },
  "indicativeNetworkIdentity": [

  ],
  "networkApplications": [
    {
      "id": "cb737ea5-75f4-4bab-b9ab-400f2d2e9aa1",
      "instanceId": 11741,
      "authEntityId": 11741,
      "displayName": "11741",
      "authEntityClass": -217092956,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "appProtocol": "tcp/udp",
      "applicationGroup": "other",
      "applicationType": "DEFAULT",
      "categoryId": "9d9132a2-d99b-49db-a414-9fc93dea6604",
      "createTime": 1517966743503,
      "encrypted": "true",
      "engineId": "13",
      "helpString": "Microsoft Update Service",
      "ignoreConflict": false,
      "lastUpdateTime": 1517966743503,
      "longDescription": "Microsoft Windows Update Service provides updates for the Microsoft Windows operating system and its installed components.",
      "name": "ms-update",
      "nbarId": "1432",
      "p2pTechnology": "false",
      "popularity": 8,
      "rank": 65535,
      "references": "http://windows.microsoft.com/en-us/windows/help/windows-update",
      "selectorId": "497",
      "subCategory": "98e625d1-8720-4d2b-a186-c821a5d86693",
      "trafficClass": "BULK_DATA",
      "tunnel": "false"
    }
  ],
  "networkIdentity": [
    {
      "id": "d63c5091-ebec-44e3-9d54-5471c5d28079",
      "instanceId": 14020,
      "authEntityId": 14020,
      "displayName": "14020",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "53,80,443,5353",
      "protocol": "TCP",
      "upperPort": 0
    },
    {
      "id": "01eea97f-fe0f-4e90-8fb4-bee08eeae38e",
      "instanceId": 14021,
      "authEntityId": 14021,
      "displayName": "14021",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "53,5353",
      "protocol": "UDP",
      "upperPort": 0
    }
  ],
  "parentScalableGroup": {
    "idRef": "4a68bc73-53c6-4deb-9983-784a0338aefe"
  },
  "user": [

  ],
  "userGroup": [

  ]
},
{
  "id": "6e5d17ef-7882-4bef-ba94-9c1884d626bc",
  "instanceId": 4369,
  "authEntityId": 4369,
  "displayName": "4369",
  "authEntityClass": -1909073334,
  "deployPending": "NONE",
  "instanceVersion": 0,
  "createTime": 1517966742431,
  "deployed": false,
  "isSeeded": true,
  "isStale": false,
  "lastUpdateTime": 1517966742431,
  "name": "discard",
  "namespace": "scalablegroup:application",
  "provisioningState": "DEFINED",
  "qualifier": "application",
  "resourceVersion": 0,
  "targetIdList": [

  ],
  "type": "scalablegroup",
  "cfsChangeInfo": [

  ],
  "customProvisions": [

  ],
  "scalableGroupExternalHandle": "discard",
  "scalableGroupType": "APPLICATION",
  "securityGroupTag": 0,
  "state": "ACTIVE",
  "vnAgnostic": true,
  "identitySource": {
    "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
    "instanceId": 10010,
    "authEntityId": 10010,
    "displayName": "10010",
    "authEntityClass": 1738953278,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "state": "INACTIVE",
    "type": "NBAR"
  },
  "indicativeNetworkIdentity": [
    {
      "id": "1cca735b-89b7-463e-9a8c-c2cf54978b07",
      "instanceId": 12893,
      "authEntityId": 12893,
      "displayName": "12893",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "9",
      "protocol": "UDP",
      "upperPort": 0
    },
    {
      "id": "b2b92314-57f3-4ab4-a440-db9b5e548fa9",
      "instanceId": 12894,
      "authEntityId": 12894,
      "displayName": "12894",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "9",
      "protocol": "TCP",
      "upperPort": 0
    }
  ],
  "networkApplications": [
    {
      "id": "b22f52b0-9eb5-4768-bb72-4a82c102941e",
      "instanceId": 11320,
      "authEntityId": 11320,
      "displayName": "11320",
      "authEntityClass": -217092956,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "appProtocol": "tcp/udp",
      "applicationGroup": "other",
      "applicationType": "DEFAULT",
      "categoryId": "6daa3dfd-3526-407a-88f0-97824b3f3a24",
      "createTime": 1517966742431,
      "encrypted": "false",
      "engineId": "3",
      "helpString": "Discard",
      "ignoreConflict": false,
      "lastUpdateTime": 1517966742431,
      "longDescription": "The Discard Protocol is a service  intended for testing, debugging, and measurement purposes. A host sends data to another host that supports the Discard Protocol. The data is simply discarded by the recieving host, and no response is returned.",
      "name": "discard",
      "nbarId": "903",
      "p2pTechnology": "false",
      "popularity": 1,
      "rank": 65535,
      "references": "http://tools.ietf.org/html/rfc863",
      "selectorId": "9",
      "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
      "trafficClass": "BULK_DATA",
      "tunnel": "false"
    }
  ],
  "networkIdentity": [
    {
      "id": "0e6fc2de-ef50-4ea0-bc3e-6bc4de10cf8b",
      "instanceId": 12891,
      "authEntityId": 12891,
      "displayName": "12891",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "9",
      "protocol": "UDP",
      "upperPort": 0
    },
    {
      "id": "91ca4ce3-286c-4ce5-92cb-b41a96ac1bb2",
      "instanceId": 12892,
      "authEntityId": 12892,
      "displayName": "12892",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "9",
      "protocol": "TCP",
      "upperPort": 0
    }
  ],
  "parentScalableGroup": {
    "idRef": "000c15bf-28fe-4fbb-9b69-35e6502721f1"
  },
  "user": [

  ],
  "userGroup": [

  ]
},
{
  "id": "6e9f526e-66ac-4f72-b9ef-718d7cd7eb24",
  "instanceId": 15099,
  "authEntityId": 15099,
  "displayName": "15099",
  "authEntityClass": -1909073334,
  "deployPending": "NONE",
  "instanceVersion": 0,
  "createTime": 1517966744232,
  "deployed": false,
  "isSeeded": true,
  "isStale": false,
  "lastUpdateTime": 1517966744232,
  "name": "sco-websrvrmg3",
  "namespace": "scalablegroup:application",
  "provisioningState": "DEFINED",
  "qualifier": "application",
  "resourceVersion": 0,
  "targetIdList": [

  ],
  "type": "scalablegroup",
  "cfsChangeInfo": [

  ],
  "customProvisions": [

  ],
  "scalableGroupExternalHandle": "sco-websrvrmg3",
  "scalableGroupType": "APPLICATION",
  "securityGroupTag": 0,
  "state": "ACTIVE",
  "vnAgnostic": true,
  "identitySource": {
    "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
    "instanceId": 10010,
    "authEntityId": 10010,
    "displayName": "10010",
    "authEntityClass": 1738953278,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "state": "INACTIVE",
    "type": "NBAR"
  },
  "indicativeNetworkIdentity": [
    {
      "id": "d62a4b0d-653e-4e40-9968-e0e3d10056e7",
      "instanceId": 14934,
      "authEntityId": 14934,
      "displayName": "14934",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "598",
      "protocol": "TCP",
      "upperPort": 0
    },
    {
      "id": "a24c6428-5985-4d48-a3f3-3f12aebe41b3",
      "instanceId": 14935,
      "authEntityId": 14935,
      "displayName": "14935",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "598",
      "protocol": "UDP",
      "upperPort": 0
    }
  ],
  "networkApplications": [
    {
      "id": "f5d96a6e-6e8d-4671-a2e5-1ad21b7629e9",
      "instanceId": 16042,
      "authEntityId": 16042,
      "displayName": "16042",
      "authEntityClass": -217092956,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "appProtocol": "tcp/udp",
      "applicationGroup": "other",
      "applicationType": "DEFAULT",
      "categoryId": "1fc17362-9334-4c7b-b752-5afdbb574017",
      "createTime": 1517966744232,
      "encrypted": "false",
      "engineId": "3",
      "helpString": "SCO Web Server Manager 3",
      "ignoreConflict": false,
      "lastUpdateTime": 1517966744232,
      "longDescription": "Registered with IANA on port 598 TCP/UDP",
      "name": "sco-websrvrmg3",
      "nbarId": "512",
      "p2pTechnology": "unassigned",
      "popularity": 1,
      "rank": 65535,
      "references": "http://www.iana.org/assignments/service-names-port-numbers/service-names-port-numbers.xml",
      "selectorId": "598",
      "subCategory": "f3c0f761-d894-4112-ab89-b90fc84fd660",
      "trafficClass": "OPS_ADMIN_MGMT",
      "tunnel": "false"
    }
  ],
  "networkIdentity": [
    {
      "id": "d283bb2a-29e9-4c69-9ec3-540dba4ebc98",
      "instanceId": 14932,
      "authEntityId": 14932,
      "displayName": "14932",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "598",
      "protocol": "TCP",
      "upperPort": 0
    },
    {
      "id": "d09a59ed-7b21-4900-ad66-77eb8e64b8f1",
      "instanceId": 14933,
      "authEntityId": 14933,
      "displayName": "14933",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "598",
      "protocol": "UDP",
      "upperPort": 0
    }
  ],
  "parentScalableGroup": {
    "idRef": "5fcd74d4-8726-47f7-a088-c457863da7ef"
  },
  "user": [

  ],
  "userGroup": [

  ]
},
{
  "id": "6ea89e55-666b-491b-9717-ed4dd5d74816",
  "instanceId": 4704,
  "authEntityId": 4704,
  "displayName": "4704",
  "authEntityClass": -1909073334,
  "deployPending": "NONE",
  "instanceVersion": 0,
  "createTime": 1517966743319,
  "deployed": false,
  "isSeeded": true,
  "isStale": false,
  "lastUpdateTime": 1517966743319,
  "name": "locus-map",
  "namespace": "scalablegroup:application",
  "provisioningState": "DEFINED",
  "qualifier": "application",
  "resourceVersion": 0,
  "targetIdList": [

  ],
  "type": "scalablegroup",
  "cfsChangeInfo": [

  ],
  "customProvisions": [

  ],
  "scalableGroupExternalHandle": "locus-map",
  "scalableGroupType": "APPLICATION",
  "securityGroupTag": 0,
  "state": "ACTIVE",
  "vnAgnostic": true,
  "identitySource": {
    "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
    "instanceId": 10010,
    "authEntityId": 10010,
    "displayName": "10010",
    "authEntityClass": 1738953278,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "state": "INACTIVE",
    "type": "NBAR"
  },
  "indicativeNetworkIdentity": [
    {
      "id": "42af5e6f-3552-4600-aad0-795f75c46b26",
      "instanceId": 13782,
      "authEntityId": 13782,
      "displayName": "13782",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "125",
      "protocol": "UDP",
      "upperPort": 0
    },
    {
      "id": "daa4b7bc-16c7-4e72-8b43-327b7b138833",
      "instanceId": 13783,
      "authEntityId": 13783,
      "displayName": "13783",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "125",
      "protocol": "TCP",
      "upperPort": 0
    }
  ],
  "networkApplications": [
    {
      "id": "2920d420-f6da-44a7-bfbe-510b926a8add",
      "instanceId": 11654,
      "authEntityId": 11654,
      "displayName": "11654",
      "authEntityClass": -217092956,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "appProtocol": "tcp/udp",
      "applicationGroup": "other",
      "applicationType": "DEFAULT",
      "categoryId": "1fc17362-9334-4c7b-b752-5afdbb574017",
      "createTime": 1517966743319,
      "encrypted": "false",
      "engineId": "3",
      "helpString": "Locus PC-Interface Net Map Server",
      "ignoreConflict": false,
      "lastUpdateTime": 1517966743319,
      "longDescription": "Registered with IANA on port 125 TCP/UDP",
      "name": "locus-map",
      "nbarId": "994",
      "p2pTechnology": "false",
      "popularity": 1,
      "rank": 65535,
      "references": "http://www.iana.org/assignments/service-names-port-numbers/service-names-port-numbers.xml",
      "selectorId": "125",
      "subCategory": "f3c0f761-d894-4112-ab89-b90fc84fd660",
      "trafficClass": "OPS_ADMIN_MGMT",
      "tunnel": "false"
    }
  ],
  "networkIdentity": [
    {
      "id": "90fb2b68-871b-497c-84b8-f449758eeb03",
      "instanceId": 13780,
      "authEntityId": 13780,
      "displayName": "13780",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "125",
      "protocol": "UDP",
      "upperPort": 0
    },
    {
      "id": "f7312520-69ee-418b-ae37-99cd88b6c0a2",
      "instanceId": 13781,
      "authEntityId": 13781,
      "displayName": "13781",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "125",
      "protocol": "TCP",
      "upperPort": 0
    }
  ],
  "parentScalableGroup": {
    "idRef": "5fcd74d4-8726-47f7-a088-c457863da7ef"
  },
  "user": [

  ],
  "userGroup": [

  ]
},
{
  "id": "6ef7bb3d-f6de-412e-886a-5f8240302dea",
  "instanceId": 4803,
  "authEntityId": 4803,
  "displayName": "4803",
  "authEntityClass": -1909073334,
  "deployPending": "NONE",
  "instanceVersion": 0,
  "createTime": 1517966743524,
  "deployed": false,
  "isSeeded": true,
  "isStale": false,
  "lastUpdateTime": 1517966743524,
  "name": "msn-messenger-video",
  "namespace": "scalablegroup:application",
  "provisioningState": "DEFINED",
  "qualifier": "application",
  "resourceVersion": 0,
  "targetIdList": [

  ],
  "type": "scalablegroup",
  "cfsChangeInfo": [

  ],
  "customProvisions": [

  ],
  "scalableGroupExternalHandle": "msn-messenger-video",
  "scalableGroupType": "APPLICATION",
  "securityGroupTag": 0,
  "state": "ACTIVE",
  "vnAgnostic": true,
  "identitySource": {
    "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
    "instanceId": 10010,
    "authEntityId": 10010,
    "displayName": "10010",
    "authEntityClass": 1738953278,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "state": "INACTIVE",
    "type": "NBAR"
  },
  "indicativeNetworkIdentity": [

  ],
  "networkApplications": [
    {
      "id": "cc7aabe9-230a-4271-9bb9-8a885350948e",
      "instanceId": 11752,
      "authEntityId": 11752,
      "displayName": "11752",
      "authEntityClass": -217092956,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "applicationGroup": "msn-messenger-group",
      "applicationType": "DEFAULT",
      "categoryId": "82a7b7fa-da5a-48f5-8b6a-a2546b947843",
      "createTime": 1517966743524,
      "encrypted": "true",
      "engineId": "13",
      "helpString": "DEPRECATED, traffic will not match",
      "ignoreConflict": false,
      "lastUpdateTime": 1517966743524,
      "longDescription": "Msn-Messenger-Video represents the video traffic within the MS-Live-Messenger client. Windows live messenger was replaced by Skype except for China",
      "name": "msn-messenger-video",
      "nbarId": "1216",
      "p2pTechnology": "false",
      "popularity": 6,
      "rank": 65535,
      "references": "http://explore.live.com/messenger",
      "selectorId": "323",
      "subCategory": "118c10a2-e272-47c1-92b9-4814ebe97138",
      "trafficClass": "MULTIMEDIA_CONFERENCING",
      "tunnel": "false"
    }
  ],
  "networkIdentity": [

  ],
  "parentScalableGroup": {
    "idRef": "fd6264c9-c16a-4311-a335-a3db039256f3"
  },
  "user": [

  ],
  "userGroup": [

  ]
},
{
  "id": "6f372085-d711-400f-b763-c13c6d26d6cd",
  "instanceId": 15168,
  "authEntityId": 15168,
  "displayName": "15168",
  "authEntityClass": -1909073334,
  "deployPending": "NONE",
  "instanceVersion": 0,
  "createTime": 1517966744390,
  "deployed": false,
  "isSeeded": true,
  "isStale": false,
  "lastUpdateTime": 1517966744390,
  "name": "smtp",
  "namespace": "scalablegroup:application",
  "provisioningState": "DEFINED",
  "qualifier": "application",
  "resourceVersion": 0,
  "targetIdList": [

  ],
  "type": "scalablegroup",
  "cfsChangeInfo": [

  ],
  "customProvisions": [

  ],
  "scalableGroupExternalHandle": "smtp",
  "scalableGroupType": "APPLICATION",
  "securityGroupTag": 0,
  "state": "ACTIVE",
  "vnAgnostic": true,
  "identitySource": {
    "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
    "instanceId": 10010,
    "authEntityId": 10010,
    "displayName": "10010",
    "authEntityClass": 1738953278,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "state": "INACTIVE",
    "type": "NBAR"
  },
  "indicativeNetworkIdentity": [
    {
      "id": "13d1569c-f378-4813-b51a-c96dadeed3c0",
      "instanceId": 17152,
      "authEntityId": 17152,
      "displayName": "17152",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "25,587",
      "protocol": "TCP",
      "upperPort": 0
    },
    {
      "id": "c451ca43-ac46-4047-929b-9628f381e016",
      "instanceId": 17151,
      "authEntityId": 17151,
      "displayName": "17151",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "25",
      "protocol": "UDP",
      "upperPort": 0
    }
  ],
  "networkApplications": [
    {
      "id": "9704937e-0c82-44fb-8d45-75cde24fd341",
      "instanceId": 16111,
      "authEntityId": 16111,
      "displayName": "16111",
      "authEntityClass": -217092956,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "appProtocol": "tcp/udp",
      "applicationGroup": "smtp-group",
      "applicationType": "DEFAULT",
      "categoryId": "27953c60-b2ff-4e1a-bc52-0187d8295250",
      "createTime": 1517966744390,
      "encrypted": "false",
      "engineId": "3",
      "helpString": "Simple Mail Transfer Protocol",
      "ignoreConflict": false,
      "lastUpdateTime": 1517966744390,
      "longDescription": "Simple Mail Transfer Protocol (SMTP) is used for sending email messages between servers. Most e-mail systems that send mail over the internet use SMTP to send messages from one server to another, the messages can then be retrieved with an email client using either POP or IMAP protocols. In addition, SMTP is also used to send messages from a mail client to a mail server",
      "name": "smtp",
      "nbarId": "71",
      "p2pTechnology": "false",
      "popularity": 9,
      "rank": 65535,
      "references": "http://james.apache.org/server/rfclist/smtp/rfc0821.txt",
      "selectorId": "25",
      "subCategory": "b90786f4-0190-45b8-9924-21b3e9682590",
      "trafficClass": "BULK_DATA",
      "tunnel": "false"
    }
  ],
  "networkIdentity": [
    {
      "id": "481ea909-c038-443a-9536-67c92705dae4",
      "instanceId": 17149,
      "authEntityId": 17149,
      "displayName": "17149",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "21,25,587,21000",
      "protocol": "TCP",
      "upperPort": 0
    },
    {
      "id": "77226bfd-c8ce-43fc-9159-bbe5f4436b9e",
      "instanceId": 17150,
      "authEntityId": 17150,
      "displayName": "17150",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "25",
      "protocol": "UDP",
      "upperPort": 0
    }
  ],
  "parentScalableGroup": {
    "idRef": "8a7ca43a-32bd-40d2-871a-02220a165cb7"
  },
  "user": [

  ],
  "userGroup": [

  ]
},
{
  "id": "6fbb1ac3-e251-4cad-98b1-0491ffc68fc5",
  "instanceId": 4794,
  "authEntityId": 4794,
  "displayName": "4794",
  "authEntityClass": -1909073334,
  "deployPending": "NONE",
  "instanceVersion": 0,
  "createTime": 1517966743507,
  "deployed": false,
  "isSeeded": true,
  "isStale": false,
  "lastUpdateTime": 1517966743507,
  "name": "ms-win-dns",
  "namespace": "scalablegroup:application",
  "provisioningState": "DEFINED",
  "qualifier": "application",
  "resourceVersion": 0,
  "targetIdList": [

  ],
  "type": "scalablegroup",
  "cfsChangeInfo": [

  ],
  "customProvisions": [

  ],
  "scalableGroupExternalHandle": "ms-win-dns",
  "scalableGroupType": "APPLICATION",
  "securityGroupTag": 0,
  "state": "ACTIVE",
  "vnAgnostic": true,
  "identitySource": {
    "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
    "instanceId": 10010,
    "authEntityId": 10010,
    "displayName": "10010",
    "authEntityClass": 1738953278,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "state": "INACTIVE",
    "type": "NBAR"
  },
  "indicativeNetworkIdentity": [

  ],
  "networkApplications": [
    {
      "id": "a9e9f025-d034-4191-b95e-abc71ce3cd9d",
      "instanceId": 11743,
      "authEntityId": 11743,
      "displayName": "11743",
      "authEntityClass": -217092956,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "appProtocol": "tcp",
      "applicationGroup": "other",
      "applicationType": "DEFAULT",
      "categoryId": "1fc17362-9334-4c7b-b752-5afdbb574017",
      "createTime": 1517966743507,
      "encrypted": "false",
      "engineId": "13",
      "helpString": "MS-RPC windows Domain Naming System",
      "ignoreConflict": false,
      "lastUpdateTime": 1517966743507,
      "longDescription": "Microsoft Windows Internet Name Service (WINS) name resolution method is the implementation of NetBios name system services provided in Microsoft Windows operating systems. The underlying protocol is Microsoft Remote Procedure Call (MS RPC).",
      "name": "ms-win-dns",
      "nbarId": "1410",
      "p2pTechnology": "false",
      "popularity": 8,
      "rank": 65535,
      "references": "http://technet.microsoft.com/en-us/library/cc784180%28WS.10%29.aspx",
      "selectorId": "481",
      "subCategory": "7f5fa07d-fcb4-4217-9908-b438066c0b60",
      "trafficClass": "OPS_ADMIN_MGMT",
      "tunnel": "false"
    }
  ],
  "networkIdentity": [
    {
      "id": "212cab45-a072-44b5-970a-1f6bdfc24e54",
      "instanceId": 14024,
      "authEntityId": 14024,
      "displayName": "14024",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "135",
      "protocol": "TCP",
      "upperPort": 0
    }
  ],
  "parentScalableGroup": {
    "idRef": "38b9eb6b-f3b6-4e13-9b79-a11e0c6423d4"
  },
  "user": [

  ],
  "userGroup": [

  ]
},
{
  "id": "6fc72694-0462-4402-8c13-957db5d9af7e",
  "instanceId": 4426,
  "authEntityId": 4426,
  "displayName": "4426",
  "authEntityClass": -1909073334,
  "deployPending": "NONE",
  "instanceVersion": 0,
  "createTime": 1517966742618,
  "deployed": false,
  "isSeeded": true,
  "isStale": false,
  "lastUpdateTime": 1517966742618,
  "name": "esro-gen",
  "namespace": "scalablegroup:application",
  "provisioningState": "DEFINED",
  "qualifier": "application",
  "resourceVersion": 0,
  "targetIdList": [

  ],
  "type": "scalablegroup",
  "cfsChangeInfo": [

  ],
  "customProvisions": [

  ],
  "scalableGroupExternalHandle": "esro-gen",
  "scalableGroupType": "APPLICATION",
  "securityGroupTag": 0,
  "state": "ACTIVE",
  "vnAgnostic": true,
  "identitySource": {
    "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
    "instanceId": 10010,
    "authEntityId": 10010,
    "displayName": "10010",
    "authEntityClass": 1738953278,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "state": "INACTIVE",
    "type": "NBAR"
  },
  "indicativeNetworkIdentity": [
    {
      "id": "ea2d5fdc-8d77-471d-949c-4925aa65336c",
      "instanceId": 13075,
      "authEntityId": 13075,
      "displayName": "13075",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "259",
      "protocol": "TCP",
      "upperPort": 0
    },
    {
      "id": "8a3c0b30-ef45-422c-b55c-4be857c9e376",
      "instanceId": 13076,
      "authEntityId": 13076,
      "displayName": "13076",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "259",
      "protocol": "UDP",
      "upperPort": 0
    }
  ],
  "networkApplications": [
    {
      "id": "82a18b62-1b2b-45c9-895c-8e1c3f3b047c",
      "instanceId": 11377,
      "authEntityId": 11377,
      "displayName": "11377",
      "authEntityClass": -217092956,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "appProtocol": "tcp/udp",
      "applicationGroup": "other",
      "applicationType": "DEFAULT",
      "categoryId": "58aa61dc-dd95-4a6d-9c7c-0ebf16da6dde",
      "createTime": 1517966742618,
      "encrypted": "false",
      "engineId": "3",
      "helpString": "Efficient Short Remote Operations",
      "ignoreConflict": false,
      "lastUpdateTime": 1517966742618,
      "longDescription": "Efficient Short Remote Operations (ESRO) provide an efficent mechanism for realization of Remote Procedure Call.",
      "name": "esro-gen",
      "nbarId": "1131",
      "p2pTechnology": "false",
      "popularity": 1,
      "rank": 65535,
      "references": "http://tools.ietf.org/html/rfc2188",
      "selectorId": "259",
      "subCategory": "b90786f4-0190-45b8-9924-21b3e9682590",
      "trafficClass": "TRANSACTIONAL_DATA",
      "tunnel": "false"
    }
  ],
  "networkIdentity": [
    {
      "id": "98fa53f5-ad02-4de1-9163-715b5c0cac95",
      "instanceId": 13073,
      "authEntityId": 13073,
      "displayName": "13073",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "259",
      "protocol": "UDP",
      "upperPort": 0
    },
    {
      "id": "5d8d38ca-201f-4bb9-ba2c-9ce52038e065",
      "instanceId": 13074,
      "authEntityId": 13074,
      "displayName": "13074",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "259",
      "protocol": "TCP",
      "upperPort": 0
    }
  ],
  "parentScalableGroup": {
    "idRef": "cff11752-fafb-4e22-9e6f-11f44f59a9a3"
  },
  "user": [

  ],
  "userGroup": [

  ]
},
{
  "id": "6ffbdfee-2d15-45c2-b9db-d2a1a3957299",
  "instanceId": 4833,
  "authEntityId": 4833,
  "displayName": "4833",
  "authEntityClass": -1909073334,
  "deployPending": "NONE",
  "instanceVersion": 0,
  "createTime": 1517966743580,
  "deployed": false,
  "isSeeded": true,
  "isStale": false,
  "lastUpdateTime": 1517966743580,
  "name": "netapp-snapmirror",
  "namespace": "scalablegroup:application",
  "provisioningState": "DEFINED",
  "qualifier": "application",
  "resourceVersion": 0,
  "targetIdList": [

  ],
  "type": "scalablegroup",
  "cfsChangeInfo": [

  ],
  "customProvisions": [

  ],
  "scalableGroupExternalHandle": "netapp-snapmirror",
  "scalableGroupType": "APPLICATION",
  "securityGroupTag": 0,
  "state": "ACTIVE",
  "vnAgnostic": true,
  "identitySource": {
    "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
    "instanceId": 10010,
    "authEntityId": 10010,
    "displayName": "10010",
    "authEntityClass": 1738953278,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "state": "INACTIVE",
    "type": "NBAR"
  },
  "indicativeNetworkIdentity": [

  ],
  "networkApplications": [
    {
      "id": "48b660bc-b862-4b3d-8e68-c9557cb3e487",
      "instanceId": 11782,
      "authEntityId": 11782,
      "displayName": "11782",
      "authEntityClass": -217092956,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "applicationGroup": "other",
      "applicationType": "DEFAULT",
      "categoryId": "80909d4c-efe6-4878-9e1e-68281fd26edd",
      "createTime": 1517966743580,
      "encrypted": "false",
      "engineId": "13",
      "helpString": "Data replication Enterprise Solution",
      "ignoreConflict": false,
      "lastUpdateTime": 1517966743580,
      "longDescription": "NetApp SnapMirror software is an enterprise-level disaster recovery and data distribution solution. SnapMirror mirrors data to one or more network filers at high speed over LAN or WAN connections.",
      "name": "netapp-snapmirror",
      "nbarId": "1293",
      "p2pTechnology": "false",
      "popularity": 7,
      "rank": 65535,
      "references": "http://www.netapp.com/us/products/protection-software/snapmirror.html",
      "selectorId": "401",
      "subCategory": "4a197877-986d-4422-b48f-500fb4b3340f",
      "trafficClass": "BULK_DATA",
      "tunnel": "false"
    }
  ],
  "networkIdentity": [

  ],
  "parentScalableGroup": {
    "idRef": "f09a36de-df38-4ff8-b0be-56738afc75f7"
  },
  "user": [

  ],
  "userGroup": [

  ]
},
{
  "id": "70233ac6-7aa5-4bdb-bf5a-4eae106d5a8b",
  "instanceId": 4613,
  "authEntityId": 4613,
  "displayName": "4613",
  "authEntityClass": -1909073334,
  "deployPending": "NONE",
  "instanceVersion": 0,
  "createTime": 1517966743126,
  "deployed": false,
  "isSeeded": true,
  "isStale": false,
  "lastUpdateTime": 1517966743126,
  "name": "intuit",
  "namespace": "scalablegroup:application",
  "provisioningState": "DEFINED",
  "qualifier": "application",
  "resourceVersion": 0,
  "targetIdList": [

  ],
  "type": "scalablegroup",
  "cfsChangeInfo": [

  ],
  "customProvisions": [

  ],
  "scalableGroupExternalHandle": "intuit",
  "scalableGroupType": "APPLICATION",
  "securityGroupTag": 0,
  "state": "ACTIVE",
  "vnAgnostic": true,
  "identitySource": {
    "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
    "instanceId": 10010,
    "authEntityId": 10010,
    "displayName": "10010",
    "authEntityClass": 1738953278,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "state": "INACTIVE",
    "type": "NBAR"
  },
  "indicativeNetworkIdentity": [

  ],
  "networkApplications": [
    {
      "id": "20d1973a-b550-4503-97f8-210131043c7e",
      "instanceId": 11563,
      "authEntityId": 11563,
      "displayName": "11563",
      "authEntityClass": -217092956,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "appProtocol": "tcp/udp",
      "applicationGroup": "other",
      "applicationType": "DEFAULT",
      "categoryId": "878644b5-fe91-45b4-9204-31c84a73ba48",
      "createTime": 1517966743126,
      "encrypted": "false",
      "engineId": "13",
      "helpString": "Intuit - Software company for financial and tax related services.",
      "ignoreConflict": false,
      "lastUpdateTime": 1517966743126,
      "longDescription": "Intuit - Software company for financial and tax related services.",
      "name": "intuit",
      "nbarId": "1594",
      "p2pTechnology": "false",
      "popularity": 3,
      "rank": 65535,
      "references": "http://intuit.com",
      "selectorId": "665",
      "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
      "trafficClass": "TRANSACTIONAL_DATA",
      "tunnel": "false"
    }
  ],
  "networkIdentity": [
    {
      "id": "971efdd5-303a-407c-9f70-5f872be32f07",
      "instanceId": 13549,
      "authEntityId": 13549,
      "displayName": "13549",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "53,80,443,5353",
      "protocol": "TCP",
      "upperPort": 0
    },
    {
      "id": "f1d2b5dc-7be9-4144-a227-47be537d15ef",
      "instanceId": 13550,
      "authEntityId": 13550,
      "displayName": "13550",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "53,5353",
      "protocol": "UDP",
      "upperPort": 0
    }
  ],
  "parentScalableGroup": {
    "idRef": "28e08de4-d7b2-4521-84ce-bc1e5ee0b9cc"
  },
  "user": [

  ],
  "userGroup": [

  ]
},
{
  "id": "702fbe1a-323c-41b0-a26e-2e6ab1eaa310",
  "instanceId": 15101,
  "authEntityId": 15101,
  "displayName": "15101",
  "authEntityClass": -1909073334,
  "deployPending": "NONE",
  "instanceVersion": 0,
  "createTime": 1517966744236,
  "deployed": false,
  "isSeeded": true,
  "isStale": false,
  "lastUpdateTime": 1517966744236,
  "name": "scohelp",
  "namespace": "scalablegroup:application",
  "provisioningState": "DEFINED",
  "qualifier": "application",
  "resourceVersion": 0,
  "targetIdList": [

  ],
  "type": "scalablegroup",
  "cfsChangeInfo": [

  ],
  "customProvisions": [

  ],
  "scalableGroupExternalHandle": "scohelp",
  "scalableGroupType": "APPLICATION",
  "securityGroupTag": 0,
  "state": "ACTIVE",
  "vnAgnostic": true,
  "identitySource": {
    "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
    "instanceId": 10010,
    "authEntityId": 10010,
    "displayName": "10010",
    "authEntityClass": 1738953278,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "state": "INACTIVE",
    "type": "NBAR"
  },
  "indicativeNetworkIdentity": [
    {
      "id": "c02fd85e-5ac9-4cff-aee6-4ff4ea726c27",
      "instanceId": 14942,
      "authEntityId": 14942,
      "displayName": "14942",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "457",
      "protocol": "TCP",
      "upperPort": 0
    },
    {
      "id": "f609dfe6-3cb7-46e0-9b05-69da06300b1a",
      "instanceId": 14943,
      "authEntityId": 14943,
      "displayName": "14943",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "457",
      "protocol": "UDP",
      "upperPort": 0
    }
  ],
  "networkApplications": [
    {
      "id": "d3b7774d-b21b-4e28-92ac-1afec4bbaacd",
      "instanceId": 16044,
      "authEntityId": 16044,
      "displayName": "16044",
      "authEntityClass": -217092956,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "appProtocol": "tcp/udp",
      "applicationGroup": "other",
      "applicationType": "DEFAULT",
      "categoryId": "6daa3dfd-3526-407a-88f0-97824b3f3a24",
      "createTime": 1517966744236,
      "encrypted": "false",
      "engineId": "3",
      "helpString": "scohelp",
      "ignoreConflict": false,
      "lastUpdateTime": 1517966744236,
      "longDescription": "Registered with IANA on port 457 TCP/UDP",
      "name": "scohelp",
      "nbarId": "371",
      "p2pTechnology": "false",
      "popularity": 1,
      "rank": 65535,
      "references": "http://www.iana.org/assignments/service-names-port-numbers/service-names-port-numbers.xml",
      "selectorId": "457",
      "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
      "trafficClass": "BULK_DATA",
      "tunnel": "false"
    }
  ],
  "networkIdentity": [
    {
      "id": "f4e3033c-b0d2-4b35-b85e-5c2480a8c583",
      "instanceId": 14940,
      "authEntityId": 14940,
      "displayName": "14940",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "457",
      "protocol": "UDP",
      "upperPort": 0
    },
    {
      "id": "07d3fbe9-0423-45e9-942c-ad8dc8d384e5",
      "instanceId": 14941,
      "authEntityId": 14941,
      "displayName": "14941",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "457",
      "protocol": "TCP",
      "upperPort": 0
    }
  ],
  "parentScalableGroup": {
    "idRef": "000c15bf-28fe-4fbb-9b69-35e6502721f1"
  },
  "user": [

  ],
  "userGroup": [

  ]
},
{
  "id": "7032b195-2ede-46f0-8758-232e9ba02f31",
  "instanceId": 4138,
  "authEntityId": 4138,
  "displayName": "4138",
  "authEntityClass": -1909073334,
  "deployPending": "NONE",
  "instanceVersion": 0,
  "createTime": 1517966741350,
  "deployed": false,
  "isSeeded": true,
  "isStale": false,
  "lastUpdateTime": 1517966741350,
  "name": "att-web-services",
  "namespace": "scalablegroup:application",
  "provisioningState": "DEFINED",
  "qualifier": "application",
  "resourceVersion": 0,
  "targetIdList": [

  ],
  "type": "scalablegroup",
  "cfsChangeInfo": [

  ],
  "customProvisions": [

  ],
  "scalableGroupExternalHandle": "att-web-services",
  "scalableGroupType": "APPLICATION",
  "securityGroupTag": 0,
  "state": "ACTIVE",
  "vnAgnostic": true,
  "identitySource": {
    "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
    "instanceId": 10010,
    "authEntityId": 10010,
    "displayName": "10010",
    "authEntityClass": 1738953278,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "state": "INACTIVE",
    "type": "NBAR"
  },
  "indicativeNetworkIdentity": [

  ],
  "networkApplications": [
    {
      "id": "24d91239-de3b-4b59-bd24-3fbd7677f9f7",
      "instanceId": 11100,
      "authEntityId": 11100,
      "displayName": "11100",
      "authEntityClass": -217092956,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "appProtocol": "tcp/udp",
      "applicationGroup": "other",
      "applicationType": "DEFAULT",
      "categoryId": "878644b5-fe91-45b4-9204-31c84a73ba48",
      "createTime": 1517966741350,
      "encrypted": "false",
      "engineId": "13",
      "helpString": "ATT - Telecom and Internet provider.",
      "ignoreConflict": false,
      "lastUpdateTime": 1517966741350,
      "longDescription": "ATT - Telecom and Internet provider.",
      "name": "att-web-services",
      "nbarId": "1628",
      "p2pTechnology": "false",
      "popularity": 3,
      "rank": 65535,
      "references": "http://att.com",
      "selectorId": "698",
      "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
      "trafficClass": "TRANSACTIONAL_DATA",
      "tunnel": "false"
    }
  ],
  "networkIdentity": [
    {
      "id": "e571fafe-02e7-4dc9-b838-df65b29d66b9",
      "instanceId": 12277,
      "authEntityId": 12277,
      "displayName": "12277",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "53,5353",
      "protocol": "UDP",
      "upperPort": 0
    },
    {
      "id": "34456f05-46a8-456d-92ac-431f2db547ef",
      "instanceId": 12278,
      "authEntityId": 12278,
      "displayName": "12278",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "53,80,443,5353",
      "protocol": "TCP",
      "upperPort": 0
    }
  ],
  "parentScalableGroup": {
    "idRef": "28e08de4-d7b2-4521-84ce-bc1e5ee0b9cc"
  },
  "user": [

  ],
  "userGroup": [

  ]
},
{
  "id": "705c3a0e-37b7-4f7b-9785-ce5f5240d31d",
  "instanceId": 15326,
  "authEntityId": 15326,
  "displayName": "15326",
  "authEntityClass": -1909073334,
  "deployPending": "NONE",
  "instanceVersion": 0,
  "createTime": 1517966744743,
  "deployed": false,
  "isSeeded": true,
  "isStale": false,
  "lastUpdateTime": 1517966744743,
  "name": "uol",
  "namespace": "scalablegroup:application",
  "provisioningState": "DEFINED",
  "qualifier": "application",
  "resourceVersion": 0,
  "targetIdList": [

  ],
  "type": "scalablegroup",
  "cfsChangeInfo": [

  ],
  "customProvisions": [

  ],
  "scalableGroupExternalHandle": "uol",
  "scalableGroupType": "APPLICATION",
  "securityGroupTag": 0,
  "state": "ACTIVE",
  "vnAgnostic": true,
  "identitySource": {
    "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
    "instanceId": 10010,
    "authEntityId": 10010,
    "displayName": "10010",
    "authEntityClass": 1738953278,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "state": "INACTIVE",
    "type": "NBAR"
  },
  "indicativeNetworkIdentity": [

  ],
  "networkApplications": [
    {
      "id": "99063f99-298f-41fb-96d8-a3d152119a17",
      "instanceId": 16269,
      "authEntityId": 16269,
      "displayName": "16269",
      "authEntityClass": -217092956,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "appProtocol": "tcp/udp",
      "applicationGroup": "other",
      "applicationType": "DEFAULT",
      "categoryId": "878644b5-fe91-45b4-9204-31c84a73ba48",
      "createTime": 1517966744743,
      "encrypted": "false",
      "engineId": "13",
      "helpString": "UOL - Brazilian web portal for news and entertainment.",
      "ignoreConflict": false,
      "lastUpdateTime": 1517966744743,
      "longDescription": "UOL - Brazilian web portal for news and entertainment.",
      "name": "uol",
      "nbarId": "1556",
      "p2pTechnology": "false",
      "popularity": 3,
      "rank": 65535,
      "references": "http://uol.com",
      "selectorId": "627",
      "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
      "trafficClass": "TRANSACTIONAL_DATA",
      "tunnel": "false"
    }
  ],
  "networkIdentity": [
    {
      "id": "2a5dda02-2876-43b9-9437-02204449888d",
      "instanceId": 17595,
      "authEntityId": 17595,
      "displayName": "17595",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "53,5353",
      "protocol": "UDP",
      "upperPort": 0
    },
    {
      "id": "2f6b5efc-629b-466a-9221-4315b7e00876",
      "instanceId": 17596,
      "authEntityId": 17596,
      "displayName": "17596",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "53,80,443,5353",
      "protocol": "TCP",
      "upperPort": 0
    }
  ],
  "parentScalableGroup": {
    "idRef": "28e08de4-d7b2-4521-84ce-bc1e5ee0b9cc"
  },
  "user": [

  ],
  "userGroup": [

  ]
},
{
  "id": "705dcad6-3fe0-4919-a625-46b644266919",
  "instanceId": 4784,
  "authEntityId": 4784,
  "displayName": "4784",
  "authEntityClass": -1909073334,
  "deployPending": "NONE",
  "instanceVersion": 0,
  "createTime": 1517966743485,
  "deployed": false,
  "isSeeded": true,
  "isStale": false,
  "lastUpdateTime": 1517966743485,
  "name": "ms-olap",
  "namespace": "scalablegroup:application",
  "provisioningState": "DEFINED",
  "qualifier": "application",
  "resourceVersion": 0,
  "targetIdList": [

  ],
  "type": "scalablegroup",
  "cfsChangeInfo": [

  ],
  "customProvisions": [

  ],
  "scalableGroupExternalHandle": "ms-olap",
  "scalableGroupType": "APPLICATION",
  "securityGroupTag": 0,
  "state": "ACTIVE",
  "vnAgnostic": true,
  "identitySource": {
    "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
    "instanceId": 10010,
    "authEntityId": 10010,
    "displayName": "10010",
    "authEntityClass": 1738953278,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "state": "INACTIVE",
    "type": "NBAR"
  },
  "indicativeNetworkIdentity": [
    {
      "id": "9a8f3eb0-e5f5-434f-bb8c-60a6ed229cc9",
      "instanceId": 13996,
      "authEntityId": 13996,
      "displayName": "13996",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "2393",
      "protocol": "UDP",
      "upperPort": 0
    },
    {
      "id": "cfbe995c-8178-420e-b9f6-3c537a945caf",
      "instanceId": 13997,
      "authEntityId": 13997,
      "displayName": "13997",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "2393",
      "protocol": "TCP",
      "upperPort": 0
    }
  ],
  "networkApplications": [
    {
      "id": "603bb09d-c298-4ff1-bac6-4deff28ae7ec",
      "instanceId": 11733,
      "authEntityId": 11733,
      "displayName": "11733",
      "authEntityClass": -217092956,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "appProtocol": "tcp/udp",
      "applicationGroup": "sqlsvr-group",
      "applicationType": "DEFAULT",
      "categoryId": "cb5915d1-afec-4c30-ac9e-069ff382272f",
      "createTime": 1517966743485,
      "encrypted": "false",
      "engineId": "3",
      "helpString": "Microsoft OLAP",
      "ignoreConflict": false,
      "lastUpdateTime": 1517966743485,
      "longDescription": "Online Analytical Processing (OLAP) is part of the Microsoft SQL Server database management system. It is a technology that is used to organize large business databases in order to support business intelligence and swiftly answer multi-dimensional analytical (MDA) queries.",
      "name": "ms-olap",
      "nbarId": "686",
      "p2pTechnology": "false",
      "popularity": 1,
      "rank": 65535,
      "references": "http://office.microsoft.com/en-us/excel-help/overview-of-online-analytical-processing-olap-HP010177437.aspx#BMwhat_is_on-line_analytical_processing",
      "selectorId": "2393",
      "subCategory": "b90786f4-0190-45b8-9924-21b3e9682590",
      "trafficClass": "TRANSACTIONAL_DATA",
      "tunnel": "false"
    }
  ],
  "networkIdentity": [
    {
      "id": "e301d010-e94e-42d7-90de-686d6dc2617e",
      "instanceId": 13994,
      "authEntityId": 13994,
      "displayName": "13994",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "2393",
      "protocol": "UDP",
      "upperPort": 0
    },
    {
      "id": "e4c1d7bf-b719-4f41-92f4-ef5767c69f6e",
      "instanceId": 13995,
      "authEntityId": 13995,
      "displayName": "13995",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "2393",
      "protocol": "TCP",
      "upperPort": 0
    }
  ],
  "parentScalableGroup": {
    "idRef": "8718395e-683e-4a2d-a232-100202751271"
  },
  "user": [

  ],
  "userGroup": [

  ]
},
{
  "id": "70973847-fa82-4d1a-a855-6d194a1f7553",
  "instanceId": 15455,
  "authEntityId": 15455,
  "displayName": "15455",
  "authEntityClass": -1909073334,
  "deployPending": "NONE",
  "instanceVersion": 0,
  "createTime": 1517966744995,
  "deployed": false,
  "isSeeded": true,
  "isStale": false,
  "lastUpdateTime": 1517966744995,
  "name": "yahoo-mail",
  "namespace": "scalablegroup:application",
  "provisioningState": "DEFINED",
  "qualifier": "application",
  "resourceVersion": 0,
  "targetIdList": [

  ],
  "type": "scalablegroup",
  "cfsChangeInfo": [

  ],
  "customProvisions": [

  ],
  "scalableGroupExternalHandle": "yahoo-mail",
  "scalableGroupType": "APPLICATION",
  "securityGroupTag": 0,
  "state": "ACTIVE",
  "vnAgnostic": true,
  "identitySource": {
    "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
    "instanceId": 10010,
    "authEntityId": 10010,
    "displayName": "10010",
    "authEntityClass": 1738953278,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "state": "INACTIVE",
    "type": "NBAR"
  },
  "indicativeNetworkIdentity": [

  ],
  "networkApplications": [
    {
      "id": "826b1604-c694-44bd-93f7-b3b7e17704d7",
      "instanceId": 16398,
      "authEntityId": 16398,
      "displayName": "16398",
      "authEntityClass": -217092956,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "appProtocol": "tcp/udp",
      "applicationGroup": "yahoo-group",
      "applicationType": "DEFAULT",
      "categoryId": "f6ec0221-b82d-43e8-a64d-163cbc13263b",
      "createTime": 1517966744995,
      "encrypted": "true",
      "engineId": "13",
      "helpString": "Yahoo! Mail",
      "ignoreConflict": false,
      "lastUpdateTime": 1517966744995,
      "longDescription": "Yahoo! Mail is a free e-mail service offered by the American search engine company Yahoo!. It was inaugurated in 1997.Yahoo! Mail was the second largest web-based email service with 310 million users as of October 2011, and the most popular webmail service in the United States.",
      "name": "yahoo-mail",
      "nbarId": "1462",
      "p2pTechnology": "false",
      "popularity": 10,
      "rank": 65535,
      "references": "http://mail.yahoo.com",
      "selectorId": "526",
      "subCategory": "aab99e59-8589-4a61-933e-f01bff556d78",
      "trafficClass": "BULK_DATA",
      "tunnel": "false"
    }
  ],
  "networkIdentity": [
    {
      "id": "a0b59ebe-cc08-4c37-9957-957dce1530f1",
      "instanceId": 17947,
      "authEntityId": 17947,
      "displayName": "17947",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "53,80,443,5353",
      "protocol": "TCP",
      "upperPort": 0
    },
    {
      "id": "0b5e49b8-6629-471f-b615-59747902ec27",
      "instanceId": 17948,
      "authEntityId": 17948,
      "displayName": "17948",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "53,5353",
      "protocol": "UDP",
      "upperPort": 0
    }
  ],
  "parentScalableGroup": {
    "idRef": "28e08de4-d7b2-4521-84ce-bc1e5ee0b9cc"
  },
  "user": [

  ],
  "userGroup": [

  ]
},
{
  "id": "7121718b-f3df-4b6d-b695-90d0793a94cc",
  "instanceId": 15464,
  "authEntityId": 15464,
  "displayName": "15464",
  "authEntityClass": -1909073334,
  "deployPending": "NONE",
  "instanceVersion": 0,
  "createTime": 1517966745019,
  "deployed": false,
  "isSeeded": true,
  "isStale": false,
  "lastUpdateTime": 1517966745019,
  "name": "zannet",
  "namespace": "scalablegroup:application",
  "provisioningState": "DEFINED",
  "qualifier": "application",
  "resourceVersion": 0,
  "targetIdList": [

  ],
  "type": "scalablegroup",
  "cfsChangeInfo": [

  ],
  "customProvisions": [

  ],
  "scalableGroupExternalHandle": "zannet",
  "scalableGroupType": "APPLICATION",
  "securityGroupTag": 0,
  "state": "ACTIVE",
  "vnAgnostic": true,
  "identitySource": {
    "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
    "instanceId": 10010,
    "authEntityId": 10010,
    "displayName": "10010",
    "authEntityClass": 1738953278,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "state": "INACTIVE",
    "type": "NBAR"
  },
  "indicativeNetworkIdentity": [
    {
      "id": "ffe03431-6235-4918-9f2d-6b5164db14f3",
      "instanceId": 17966,
      "authEntityId": 17966,
      "displayName": "17966",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "317",
      "protocol": "TCP",
      "upperPort": 0
    },
    {
      "id": "55b42720-971d-44b3-8173-e546dd8c9f0a",
      "instanceId": 17967,
      "authEntityId": 17967,
      "displayName": "17967",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "317",
      "protocol": "UDP",
      "upperPort": 0
    }
  ],
  "networkApplications": [
    {
      "id": "407f74ec-65c1-4843-ae8e-5db5088fea6b",
      "instanceId": 16407,
      "authEntityId": 16407,
      "displayName": "16407",
      "authEntityClass": -217092956,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "appProtocol": "tcp/udp",
      "applicationGroup": "other",
      "applicationType": "DEFAULT",
      "categoryId": "e6619ce8-97de-4c63-a53a-cceddbddc1a8",
      "createTime": 1517966745019,
      "encrypted": "false",
      "engineId": "3",
      "helpString": "zannet",
      "ignoreConflict": false,
      "lastUpdateTime": 1517966745019,
      "longDescription": "Registered with IANA on port 317 TCP/UDP",
      "name": "zannet",
      "nbarId": "1157",
      "p2pTechnology": "false",
      "popularity": 1,
      "rank": 65535,
      "references": "http://www.iana.org/assignments/service-names-port-numbers/service-names-port-numbers.xml",
      "selectorId": "317",
      "subCategory": "50b55e2e-d0d0-4404-bea4-819351565d90",
      "trafficClass": "BULK_DATA",
      "tunnel": "false"
    }
  ],
  "networkIdentity": [
    {
      "id": "ff35ea9f-1684-4b46-b24e-df53ff09e1e1",
      "instanceId": 17964,
      "authEntityId": 17964,
      "displayName": "17964",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "317",
      "protocol": "UDP",
      "upperPort": 0
    },
    {
      "id": "105ede92-583d-46ae-8545-72458a80624e",
      "instanceId": 17965,
      "authEntityId": 17965,
      "displayName": "17965",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "317",
      "protocol": "TCP",
      "upperPort": 0
    }
  ],
  "parentScalableGroup": {
    "idRef": "383363b1-4dd5-49d1-bfe4-34daac437cfb"
  },
  "user": [

  ],
  "userGroup": [

  ]
},
{
  "id": "71b7319a-ed26-4fe3-a06e-415b42dbf951",
  "instanceId": 4865,
  "authEntityId": 4865,
  "displayName": "4865",
  "authEntityClass": -1909073334,
  "deployPending": "NONE",
  "instanceVersion": 0,
  "createTime": 1517966743641,
  "deployed": false,
  "isSeeded": true,
  "isStale": false,
  "lastUpdateTime": 1517966743641,
  "name": "nicname",
  "namespace": "scalablegroup:application",
  "provisioningState": "DEFINED",
  "qualifier": "application",
  "resourceVersion": 0,
  "targetIdList": [

  ],
  "type": "scalablegroup",
  "cfsChangeInfo": [

  ],
  "customProvisions": [

  ],
  "scalableGroupExternalHandle": "nicname",
  "scalableGroupType": "APPLICATION",
  "securityGroupTag": 0,
  "state": "ACTIVE",
  "vnAgnostic": true,
  "identitySource": {
    "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
    "instanceId": 10010,
    "authEntityId": 10010,
    "displayName": "10010",
    "authEntityClass": 1738953278,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "state": "INACTIVE",
    "type": "NBAR"
  },
  "indicativeNetworkIdentity": [
    {
      "id": "9f69381b-ee26-421a-b9f8-a06d4e2a7510",
      "instanceId": 14238,
      "authEntityId": 14238,
      "displayName": "14238",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "43",
      "protocol": "TCP",
      "upperPort": 0
    },
    {
      "id": "32b38038-c775-4e6b-866e-7c2e3cff4cbe",
      "instanceId": 14239,
      "authEntityId": 14239,
      "displayName": "14239",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "43",
      "protocol": "UDP",
      "upperPort": 0
    }
  ],
  "networkApplications": [
    {
      "id": "2cc91122-30cb-43fd-8a15-4ecb9a2dea5a",
      "instanceId": 11814,
      "authEntityId": 11814,
      "displayName": "11814",
      "authEntityClass": -217092956,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "appProtocol": "tcp/udp",
      "applicationGroup": "other",
      "applicationType": "DEFAULT",
      "categoryId": "1fc17362-9334-4c7b-b752-5afdbb574017",
      "createTime": 1517966743641,
      "encrypted": "false",
      "engineId": "3",
      "helpString": "Who Is",
      "ignoreConflict": false,
      "lastUpdateTime": 1517966743641,
      "longDescription": "The NICNAME/WHOIS Server is an NCP/TCP transaction based query/response server, running on the SRI-NIC machine, that provides netwide directory service to ARPANET users.",
      "name": "nicname",
      "nbarId": "110",
      "p2pTechnology": "false",
      "popularity": 1,
      "rank": 65535,
      "references": "http://tools.ietf.org/rfc/rfc812",
      "selectorId": "43",
      "subCategory": "f3c0f761-d894-4112-ab89-b90fc84fd660",
      "trafficClass": "OPS_ADMIN_MGMT",
      "tunnel": "false"
    }
  ],
  "networkIdentity": [
    {
      "id": "cb327565-8724-41a2-ba63-432380448845",
      "instanceId": 14236,
      "authEntityId": 14236,
      "displayName": "14236",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "43",
      "protocol": "UDP",
      "upperPort": 0
    },
    {
      "id": "f5da16a5-31b5-4430-8b13-47f55ff45abf",
      "instanceId": 14237,
      "authEntityId": 14237,
      "displayName": "14237",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "43",
      "protocol": "TCP",
      "upperPort": 0
    }
  ],
  "parentScalableGroup": {
    "idRef": "5fcd74d4-8726-47f7-a088-c457863da7ef"
  },
  "user": [

  ],
  "userGroup": [

  ]
},
{
  "id": "71b963fe-46d0-4c8a-9b92-67475518bbd3",
  "instanceId": 15281,
  "authEntityId": 15281,
  "displayName": "15281",
  "authEntityClass": -1909073334,
  "deployPending": "NONE",
  "instanceVersion": 0,
  "createTime": 1517966744648,
  "deployed": false,
  "isSeeded": true,
  "isStale": false,
  "lastUpdateTime": 1517966744648,
  "name": "thefreedictionary-com",
  "namespace": "scalablegroup:application",
  "provisioningState": "DEFINED",
  "qualifier": "application",
  "resourceVersion": 0,
  "targetIdList": [

  ],
  "type": "scalablegroup",
  "cfsChangeInfo": [

  ],
  "customProvisions": [

  ],
  "scalableGroupExternalHandle": "thefreedictionary-com",
  "scalableGroupType": "APPLICATION",
  "securityGroupTag": 0,
  "state": "ACTIVE",
  "vnAgnostic": true,
  "identitySource": {
    "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
    "instanceId": 10010,
    "authEntityId": 10010,
    "displayName": "10010",
    "authEntityClass": 1738953278,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "state": "INACTIVE",
    "type": "NBAR"
  },
  "indicativeNetworkIdentity": [

  ],
  "networkApplications": [
    {
      "id": "62f373a7-34a3-4264-a596-9db7becd6434",
      "instanceId": 16224,
      "authEntityId": 16224,
      "displayName": "16224",
      "authEntityClass": -217092956,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "appProtocol": "tcp/udp",
      "applicationGroup": "other",
      "applicationType": "DEFAULT",
      "categoryId": "878644b5-fe91-45b4-9204-31c84a73ba48",
      "createTime": 1517966744648,
      "encrypted": "false",
      "engineId": "13",
      "helpString": "The Free Dictionary - Online dictionary aggregator.",
      "ignoreConflict": false,
      "lastUpdateTime": 1517966744648,
      "longDescription": "The Free Dictionary - Online dictionary aggregator.",
      "name": "thefreedictionary-com",
      "nbarId": "1624",
      "p2pTechnology": "false",
      "popularity": 3,
      "rank": 65535,
      "references": "http://thefreedictionary.com",
      "selectorId": "694",
      "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
      "trafficClass": "TRANSACTIONAL_DATA",
      "tunnel": "false"
    }
  ],
  "networkIdentity": [
    {
      "id": "d3b7a85c-8c92-4b0b-b9f2-87f8928f87df",
      "instanceId": 17469,
      "authEntityId": 17469,
      "displayName": "17469",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "53,5353",
      "protocol": "UDP",
      "upperPort": 0
    },
    {
      "id": "f7276960-de4c-4e96-bb5f-5846ce646d50",
      "instanceId": 17470,
      "authEntityId": 17470,
      "displayName": "17470",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "53,80,443,5353",
      "protocol": "TCP",
      "upperPort": 0
    }
  ],
  "parentScalableGroup": {
    "idRef": "28e08de4-d7b2-4521-84ce-bc1e5ee0b9cc"
  },
  "user": [

  ],
  "userGroup": [

  ]
},
{
  "id": "7213a0d5-8287-4bdb-9ecb-d4cccf18b1b8",
  "instanceId": 4997,
  "authEntityId": 4997,
  "displayName": "4997",
  "authEntityClass": -1909073334,
  "deployPending": "NONE",
  "instanceVersion": 0,
  "createTime": 1517966744009,
  "deployed": false,
  "isSeeded": true,
  "isStale": false,
  "lastUpdateTime": 1517966744009,
  "name": "pup",
  "namespace": "scalablegroup:application",
  "provisioningState": "DEFINED",
  "qualifier": "application",
  "resourceVersion": 0,
  "targetIdList": [

  ],
  "type": "scalablegroup",
  "cfsChangeInfo": [

  ],
  "customProvisions": [

  ],
  "scalableGroupExternalHandle": "pup",
  "scalableGroupType": "APPLICATION",
  "securityGroupTag": 0,
  "state": "ACTIVE",
  "vnAgnostic": true,
  "identitySource": {
    "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
    "instanceId": 10010,
    "authEntityId": 10010,
    "displayName": "10010",
    "authEntityClass": 1738953278,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "state": "INACTIVE",
    "type": "NBAR"
  },
  "indicativeNetworkIdentity": [
    {
      "id": "b909b4e4-9c5c-435c-a25f-8ad35d0da253",
      "instanceId": 14649,
      "authEntityId": 14649,
      "displayName": "14649",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipProtocolNumbers": "12",
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "protocol": "PUP",
      "upperPort": 0
    }
  ],
  "networkApplications": [
    {
      "id": "084f86f5-6e3c-4646-ab9b-77b14e2df054",
      "instanceId": 11946,
      "authEntityId": 11946,
      "displayName": "11946",
      "authEntityClass": -217092956,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "appProtocol": "ip",
      "applicationGroup": "other",
      "applicationType": "DEFAULT",
      "categoryId": "6daa3dfd-3526-407a-88f0-97824b3f3a24",
      "createTime": 1517966744009,
      "encrypted": "unassigned",
      "engineId": "1",
      "helpString": "PUP",
      "ignoreConflict": false,
      "lastUpdateTime": 1517966744009,
      "longDescription": "The PARC Universal Packet was one of the two earliest internetwork protocol suites; it was created by researchers at Xerox PARC in the mid-1970s. The entire suite provided routing and packet delivery, as well as higher level functions such as a reliable byte stream, along with numerous applications.",
      "name": "pup",
      "nbarId": "767",
      "p2pTechnology": "unassigned",
      "popularity": 2,
      "rank": 65535,
      "references": "http://en.wikipedia.org/wiki/PARC_Universal_Packet",
      "selectorId": "12",
      "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
      "trafficClass": "BULK_DATA",
      "tunnel": "unassigned"
    }
  ],
  "networkIdentity": [
    {
      "id": "36bd58dd-066a-4018-ae9a-8b6e10311e24",
      "instanceId": 14648,
      "authEntityId": 14648,
      "displayName": "14648",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipProtocolNumbers": "12",
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "protocol": "PUP",
      "upperPort": 0
    }
  ],
  "parentScalableGroup": {
    "idRef": "000c15bf-28fe-4fbb-9b69-35e6502721f1"
  },
  "user": [

  ],
  "userGroup": [

  ]
},
{
  "id": "724078d2-e9f4-46c1-a595-167d72c9370a",
  "instanceId": 4461,
  "authEntityId": 4461,
  "displayName": "4461",
  "authEntityClass": -1909073334,
  "deployPending": "NONE",
  "instanceVersion": 0,
  "createTime": 1517966742729,
  "deployed": false,
  "isSeeded": true,
  "isStale": false,
  "lastUpdateTime": 1517966742729,
  "name": "foodnetwork",
  "namespace": "scalablegroup:application",
  "provisioningState": "DEFINED",
  "qualifier": "application",
  "resourceVersion": 0,
  "targetIdList": [

  ],
  "type": "scalablegroup",
  "cfsChangeInfo": [

  ],
  "customProvisions": [

  ],
  "scalableGroupExternalHandle": "foodnetwork",
  "scalableGroupType": "APPLICATION",
  "securityGroupTag": 0,
  "state": "ACTIVE",
  "vnAgnostic": true,
  "identitySource": {
    "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
    "instanceId": 10010,
    "authEntityId": 10010,
    "displayName": "10010",
    "authEntityClass": 1738953278,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "state": "INACTIVE",
    "type": "NBAR"
  },
  "indicativeNetworkIdentity": [

  ],
  "networkApplications": [
    {
      "id": "9ccf122b-ae46-4479-91a5-42df1cbc9fad",
      "instanceId": 11412,
      "authEntityId": 11412,
      "displayName": "11412",
      "authEntityClass": -217092956,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "appProtocol": "tcp/udp",
      "applicationGroup": "other",
      "applicationType": "DEFAULT",
      "categoryId": "878644b5-fe91-45b4-9204-31c84a73ba48",
      "createTime": 1517966742729,
      "encrypted": "false",
      "engineId": "13",
      "helpString": "Food Network - Official website for the TV network about food and cooking.",
      "ignoreConflict": false,
      "lastUpdateTime": 1517966742729,
      "longDescription": "Food Network - Official website for the TV network about food and cooking.",
      "name": "foodnetwork",
      "nbarId": "1688",
      "p2pTechnology": "false",
      "popularity": 3,
      "rank": 65535,
      "references": "http://foodnetwork.com",
      "selectorId": "758",
      "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
      "trafficClass": "TRANSACTIONAL_DATA",
      "tunnel": "false"
    }
  ],
  "networkIdentity": [
    {
      "id": "027392ef-1ea1-4263-b44a-48b20400f80f",
      "instanceId": 13149,
      "authEntityId": 13149,
      "displayName": "13149",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "53,80,443,5353",
      "protocol": "TCP",
      "upperPort": 0
    },
    {
      "id": "e7348c46-11ac-4419-968b-c5b86188afbc",
      "instanceId": 13150,
      "authEntityId": 13150,
      "displayName": "13150",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "53,5353",
      "protocol": "UDP",
      "upperPort": 0
    }
  ],
  "parentScalableGroup": {
    "idRef": "28e08de4-d7b2-4521-84ce-bc1e5ee0b9cc"
  },
  "user": [

  ],
  "userGroup": [

  ]
},
{
  "id": "72407c22-0cfa-471e-a2c4-9035181402c7",
  "instanceId": 15402,
  "authEntityId": 15402,
  "displayName": "15402",
  "authEntityClass": -1909073334,
  "deployPending": "NONE",
  "instanceVersion": 0,
  "createTime": 1517966744879,
  "deployed": false,
  "isSeeded": true,
  "isStale": false,
  "lastUpdateTime": 1517966744879,
  "name": "webex-meeting",
  "namespace": "scalablegroup:application",
  "provisioningState": "DEFINED",
  "qualifier": "application",
  "resourceVersion": 0,
  "targetIdList": [

  ],
  "type": "scalablegroup",
  "cfsChangeInfo": [

  ],
  "customProvisions": [

  ],
  "scalableGroupExternalHandle": "webex-meeting",
  "scalableGroupType": "APPLICATION",
  "securityGroupTag": 0,
  "state": "ACTIVE",
  "vnAgnostic": true,
  "identitySource": {
    "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
    "instanceId": 10010,
    "authEntityId": 10010,
    "displayName": "10010",
    "authEntityClass": 1738953278,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "state": "INACTIVE",
    "type": "NBAR"
  },
  "indicativeNetworkIdentity": [
    {
      "id": "2ec414fd-b1f8-4504-bf4a-29b1724f2dde",
      "instanceId": 17813,
      "authEntityId": 17813,
      "displayName": "17813",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "9000",
      "protocol": "UDP",
      "upperPort": 0
    }
  ],
  "networkApplications": [
    {
      "id": "3eb2a398-f5d7-43d5-859e-4f8c5c4e29a7",
      "instanceId": 16345,
      "authEntityId": 16345,
      "displayName": "16345",
      "authEntityClass": -217092956,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "appProtocol": "tcp/udp",
      "applicationGroup": "webex-group",
      "applicationType": "DEFAULT",
      "categoryId": "38a8e354-e2fe-4f3c-b1c5-2bbb90fb0d99",
      "createTime": 1517966744879,
      "encrypted": "true",
      "engineId": "13",
      "helpString": "Online conferencing application",
      "ignoreConflict": false,
      "lastUpdateTime": 1517966744879,
      "longDescription": "WebEx is an application that provides online meeting, web conferencing and video conferencing services. It recreates face-to-face meetings with real-time data, application-, audio- and video-sharing capabilities. The underlying protocol Webex uses is SSL. Audio over webex: represents the transfer of audio traffic over the Webex application. Video over webex: represents the transfer of video traffic over the Webex application. Specifies the data type: represents which payload webex is transferring- audio/video.",
      "name": "webex-meeting",
      "nbarId": "1306",
      "p2pTechnology": "false",
      "popularity": 10,
      "rank": 65535,
      "references": "http://www.webex.com/overview/index.html",
      "selectorId": "414",
      "subCategory": "05178016-4555-4291-ab4e-43c2ab2a931a",
      "trafficClass": "MULTIMEDIA_CONFERENCING",
      "tunnel": "false"
    }
  ],
  "networkIdentity": [
    {
      "id": "55427d44-a44d-4c30-974e-4defb68e731a",
      "instanceId": 17811,
      "authEntityId": 17811,
      "displayName": "17811",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "53,80,443,5353",
      "protocol": "TCP",
      "upperPort": 0
    },
    {
      "id": "4b1d58fb-fcfa-4db0-b2df-4f59f8447af9",
      "instanceId": 17812,
      "authEntityId": 17812,
      "displayName": "17812",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "53,5353,9000",
      "protocol": "UDP",
      "upperPort": 0
    }
  ],
  "parentScalableGroup": {
    "idRef": "60f1364d-2a02-4063-8ba1-07817abe0612"
  },
  "user": [

  ],
  "userGroup": [

  ]
},
{
  "id": "724dab94-58f4-465c-aad8-52dfb5415dbe",
  "instanceId": 4675,
  "authEntityId": 4675,
  "displayName": "4675",
  "authEntityClass": -1909073334,
  "deployPending": "NONE",
  "instanceVersion": 0,
  "createTime": 1517966743259,
  "deployed": false,
  "isSeeded": true,
  "isStale": false,
  "lastUpdateTime": 1517966743259,
  "name": "keyserver",
  "namespace": "scalablegroup:application",
  "provisioningState": "DEFINED",
  "qualifier": "application",
  "resourceVersion": 0,
  "targetIdList": [

  ],
  "type": "scalablegroup",
  "cfsChangeInfo": [

  ],
  "customProvisions": [

  ],
  "scalableGroupExternalHandle": "keyserver",
  "scalableGroupType": "APPLICATION",
  "securityGroupTag": 0,
  "state": "ACTIVE",
  "vnAgnostic": true,
  "identitySource": {
    "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
    "instanceId": 10010,
    "authEntityId": 10010,
    "displayName": "10010",
    "authEntityClass": 1738953278,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "state": "INACTIVE",
    "type": "NBAR"
  },
  "indicativeNetworkIdentity": [
    {
      "id": "0159d9cc-e737-4b78-a281-437836eff299",
      "instanceId": 13696,
      "authEntityId": 13696,
      "displayName": "13696",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "584",
      "protocol": "TCP",
      "upperPort": 0
    },
    {
      "id": "3fa21906-5872-4503-b574-1a0d0879a3a3",
      "instanceId": 13697,
      "authEntityId": 13697,
      "displayName": "13697",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "584",
      "protocol": "UDP",
      "upperPort": 0
    }
  ],
  "networkApplications": [
    {
      "id": "72f9927d-4156-4887-a2d0-81c40a59bdda",
      "instanceId": 11625,
      "authEntityId": 11625,
      "displayName": "11625",
      "authEntityClass": -217092956,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "appProtocol": "tcp/udp",
      "applicationGroup": "other",
      "applicationType": "DEFAULT",
      "categoryId": "1fc17362-9334-4c7b-b752-5afdbb574017",
      "createTime": 1517966743259,
      "encrypted": "false",
      "engineId": "3",
      "helpString": "Key Server",
      "ignoreConflict": false,
      "lastUpdateTime": 1517966743259,
      "longDescription": "Akey serveris a computer that receives and then serves existing cryptographickeysto users or other programs. The users' programs can be working on the same network as the key server or on another networked computer.",
      "name": "keyserver",
      "nbarId": "498",
      "p2pTechnology": "false",
      "popularity": 1,
      "rank": 65535,
      "references": "http://en.wikipedia.org/wiki/Key_server_(cryptographic)",
      "selectorId": "584",
      "subCategory": "e390124a-0fd6-4253-b68e-4b0c1af8cb4b",
      "trafficClass": "OPS_ADMIN_MGMT",
      "tunnel": "false"
    }
  ],
  "networkIdentity": [
    {
      "id": "b1b005c3-5c89-4f4b-961c-ed7e76d27a18",
      "instanceId": 13694,
      "authEntityId": 13694,
      "displayName": "13694",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "584",
      "protocol": "UDP",
      "upperPort": 0
    },
    {
      "id": "d843d166-468c-4c6a-9551-2b72d3a4fa7b",
      "instanceId": 13695,
      "authEntityId": 13695,
      "displayName": "13695",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "584",
      "protocol": "TCP",
      "upperPort": 0
    }
  ],
  "parentScalableGroup": {
    "idRef": "7788bf2d-e289-4597-b500-17aacb7e820e"
  },
  "user": [

  ],
  "userGroup": [

  ]
},
{
  "id": "7280a30c-d95f-4d89-a179-f3e157bf9e29",
  "instanceId": 4758,
  "authEntityId": 4758,
  "displayName": "4758",
  "authEntityClass": -1909073334,
  "deployPending": "NONE",
  "instanceVersion": 0,
  "createTime": 1517966743435,
  "deployed": false,
  "isSeeded": true,
  "isStale": false,
  "lastUpdateTime": 1517966743435,
  "name": "mondex",
  "namespace": "scalablegroup:application",
  "provisioningState": "DEFINED",
  "qualifier": "application",
  "resourceVersion": 0,
  "targetIdList": [

  ],
  "type": "scalablegroup",
  "cfsChangeInfo": [

  ],
  "customProvisions": [

  ],
  "scalableGroupExternalHandle": "mondex",
  "scalableGroupType": "APPLICATION",
  "securityGroupTag": 0,
  "state": "ACTIVE",
  "vnAgnostic": true,
  "identitySource": {
    "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
    "instanceId": 10010,
    "authEntityId": 10010,
    "displayName": "10010",
    "authEntityClass": 1738953278,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "state": "INACTIVE",
    "type": "NBAR"
  },
  "indicativeNetworkIdentity": [
    {
      "id": "3047f995-23ff-4944-88c8-36ec11270a8f",
      "instanceId": 13936,
      "authEntityId": 13936,
      "displayName": "13936",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "471",
      "protocol": "TCP",
      "upperPort": 0
    },
    {
      "id": "6deeb975-a059-463e-a3b2-a3683858363d",
      "instanceId": 13937,
      "authEntityId": 13937,
      "displayName": "13937",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "471",
      "protocol": "UDP",
      "upperPort": 0
    }
  ],
  "networkApplications": [
    {
      "id": "6cc33e2f-52e1-4231-bf5a-ca1827c6a058",
      "instanceId": 11708,
      "authEntityId": 11708,
      "displayName": "11708",
      "authEntityClass": -217092956,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "appProtocol": "tcp/udp",
      "applicationGroup": "other",
      "applicationType": "DEFAULT",
      "categoryId": "91a59054-00c9-4445-96e9-4cb77e86dcd7",
      "createTime": 1517966743435,
      "encrypted": "false",
      "engineId": "3",
      "helpString": "mondex",
      "ignoreConflict": false,
      "lastUpdateTime": 1517966743435,
      "longDescription": "Mondex is a smart card electronic cash system. The main protocol of Mondex implements electronic cash transfer, using either a device (wallet) with two slots, or an Internet connection.",
      "name": "mondex",
      "nbarId": "385",
      "p2pTechnology": "false",
      "popularity": 1,
      "rank": 65535,
      "references": "http://vsr.sourceforge.net/mondex.htm",
      "selectorId": "471",
      "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
      "trafficClass": "TRANSACTIONAL_DATA",
      "tunnel": "false"
    }
  ],
  "networkIdentity": [
    {
      "id": "5ed7e778-13ac-4b61-9502-d95a139f4f3a",
      "instanceId": 13934,
      "authEntityId": 13934,
      "displayName": "13934",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "471",
      "protocol": "TCP",
      "upperPort": 0
    },
    {
      "id": "d4a8b006-1fbf-4151-b276-7a02430a6fbf",
      "instanceId": 13935,
      "authEntityId": 13935,
      "displayName": "13935",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "471",
      "protocol": "UDP",
      "upperPort": 0
    }
  ],
  "parentScalableGroup": {
    "idRef": "000c15bf-28fe-4fbb-9b69-35e6502721f1"
  },
  "user": [

  ],
  "userGroup": [

  ]
},
{
  "id": "72c5ca46-f621-4d29-b107-40656037663f",
  "instanceId": 15086,
  "authEntityId": 15086,
  "displayName": "15086",
  "authEntityClass": -1909073334,
  "deployPending": "NONE",
  "instanceVersion": 0,
  "createTime": 1517966744201,
  "deployed": false,
  "isSeeded": true,
  "isStale": false,
  "lastUpdateTime": 1517966744201,
  "name": "saft",
  "namespace": "scalablegroup:application",
  "provisioningState": "DEFINED",
  "qualifier": "application",
  "resourceVersion": 0,
  "targetIdList": [

  ],
  "type": "scalablegroup",
  "cfsChangeInfo": [

  ],
  "customProvisions": [

  ],
  "scalableGroupExternalHandle": "saft",
  "scalableGroupType": "APPLICATION",
  "securityGroupTag": 0,
  "state": "ACTIVE",
  "vnAgnostic": true,
  "identitySource": {
    "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
    "instanceId": 10010,
    "authEntityId": 10010,
    "displayName": "10010",
    "authEntityClass": 1738953278,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "state": "INACTIVE",
    "type": "NBAR"
  },
  "indicativeNetworkIdentity": [
    {
      "id": "1bffbdce-75be-496e-9859-619d7721c828",
      "instanceId": 14896,
      "authEntityId": 14896,
      "displayName": "14896",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "487",
      "protocol": "TCP",
      "upperPort": 0
    },
    {
      "id": "d483c0d6-a661-4b79-8cf0-396a5b6ba624",
      "instanceId": 14897,
      "authEntityId": 14897,
      "displayName": "14897",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "487",
      "protocol": "UDP",
      "upperPort": 0
    }
  ],
  "networkApplications": [
    {
      "id": "89bc5040-729c-495d-b466-39dc159b34c4",
      "instanceId": 16029,
      "authEntityId": 16029,
      "displayName": "16029",
      "authEntityClass": -217092956,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "appProtocol": "tcp/udp",
      "applicationGroup": "other",
      "applicationType": "DEFAULT",
      "categoryId": "e6619ce8-97de-4c63-a53a-cceddbddc1a8",
      "createTime": 1517966744201,
      "encrypted": "false",
      "engineId": "3",
      "helpString": "saft Simple Asynchronous File Transfer",
      "ignoreConflict": false,
      "lastUpdateTime": 1517966744201,
      "longDescription": "Simple Asynchronous File Transfer (SAFT) is an internet protocol designed to do asynchronous file transfer: any user A can send a file to another user B without B doing any action. Is used by sendfile software.",
      "name": "saft",
      "nbarId": "401",
      "p2pTechnology": "false",
      "popularity": 1,
      "rank": 65535,
      "references": "http://fex.rus.uni-stuttgart.de/saft/index.html",
      "selectorId": "487",
      "subCategory": "50b55e2e-d0d0-4404-bea4-819351565d90",
      "trafficClass": "BULK_DATA",
      "tunnel": "false"
    }
  ],
  "networkIdentity": [
    {
      "id": "9d9eff3f-8c89-418f-8f6e-423294eed97f",
      "instanceId": 14894,
      "authEntityId": 14894,
      "displayName": "14894",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "487",
      "protocol": "TCP",
      "upperPort": 0
    },
    {
      "id": "7271cfa9-e99c-448d-8b65-bf32f6288bc4",
      "instanceId": 14895,
      "authEntityId": 14895,
      "displayName": "14895",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "487",
      "protocol": "UDP",
      "upperPort": 0
    }
  ],
  "parentScalableGroup": {
    "idRef": "383363b1-4dd5-49d1-bfe4-34daac437cfb"
  },
  "user": [

  ],
  "userGroup": [

  ]
},
{
  "id": "72c6661c-7ed9-4c40-9727-7bb9d2a28f67",
  "instanceId": 4697,
  "authEntityId": 4697,
  "displayName": "4697",
  "authEntityClass": -1909073334,
  "deployPending": "NONE",
  "instanceVersion": 0,
  "createTime": 1517966743305,
  "deployed": false,
  "isSeeded": true,
  "isStale": false,
  "lastUpdateTime": 1517966743305,
  "name": "livemeeting",
  "namespace": "scalablegroup:application",
  "provisioningState": "DEFINED",
  "qualifier": "application",
  "resourceVersion": 0,
  "targetIdList": [

  ],
  "type": "scalablegroup",
  "cfsChangeInfo": [

  ],
  "customProvisions": [

  ],
  "scalableGroupExternalHandle": "livemeeting",
  "scalableGroupType": "APPLICATION",
  "securityGroupTag": 0,
  "state": "ACTIVE",
  "vnAgnostic": true,
  "identitySource": {
    "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
    "instanceId": 10010,
    "authEntityId": 10010,
    "displayName": "10010",
    "authEntityClass": 1738953278,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "state": "INACTIVE",
    "type": "NBAR"
  },
  "indicativeNetworkIdentity": [

  ],
  "networkApplications": [
    {
      "id": "5a497669-f4b1-4ab6-9d76-6b7b068f99f8",
      "instanceId": 11647,
      "authEntityId": 11647,
      "displayName": "11647",
      "authEntityClass": -217092956,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "applicationGroup": "other",
      "applicationType": "DEFAULT",
      "categoryId": "6daa3dfd-3526-407a-88f0-97824b3f3a24",
      "createTime": 1517966743305,
      "encrypted": "unassigned",
      "engineId": "13",
      "helpString": "DEPRECATED, traffic will not match",
      "ignoreConflict": false,
      "lastUpdateTime": 1517966743305,
      "longDescription": "Microsoft Office Live Meeting is a web conferencing service operated by Microsoft. Live Meeting includes software that is installed on client PCs, and uses a central server for all clients to connect to.",
      "name": "livemeeting",
      "nbarId": "1402",
      "p2pTechnology": "unassigned",
      "popularity": 0,
      "rank": 65535,
      "references": "http://en.wikipedia.org/wiki/Microsoft_Office_Live_Meeting",
      "selectorId": "474",
      "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
      "trafficClass": "BULK_DATA",
      "tunnel": "unassigned"
    }
  ],
  "networkIdentity": [

  ],
  "parentScalableGroup": {
    "idRef": "000c15bf-28fe-4fbb-9b69-35e6502721f1"
  },
  "user": [

  ],
  "userGroup": [

  ]
},
{
  "id": "72e74a83-5117-440c-90e3-475b449d7741",
  "instanceId": 4196,
  "authEntityId": 4196,
  "displayName": "4196",
  "authEntityClass": -1909073334,
  "deployPending": "NONE",
  "instanceVersion": 0,
  "createTime": 1517966741651,
  "deployed": false,
  "isSeeded": true,
  "isStale": false,
  "lastUpdateTime": 1517966741651,
  "name": "bmpp",
  "namespace": "scalablegroup:application",
  "provisioningState": "DEFINED",
  "qualifier": "application",
  "resourceVersion": 0,
  "targetIdList": [

  ],
  "type": "scalablegroup",
  "cfsChangeInfo": [

  ],
  "customProvisions": [

  ],
  "scalableGroupExternalHandle": "bmpp",
  "scalableGroupType": "APPLICATION",
  "securityGroupTag": 0,
  "state": "ACTIVE",
  "vnAgnostic": true,
  "identitySource": {
    "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
    "instanceId": 10010,
    "authEntityId": 10010,
    "displayName": "10010",
    "authEntityClass": 1738953278,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "state": "INACTIVE",
    "type": "NBAR"
  },
  "indicativeNetworkIdentity": [
    {
      "id": "d24eb8e1-280e-4a07-9d5c-b1c8b4ca10d1",
      "instanceId": 12419,
      "authEntityId": 12419,
      "displayName": "12419",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "632",
      "protocol": "TCP",
      "upperPort": 0
    },
    {
      "id": "dfe2f247-278b-4ca8-a436-c2cb0a0e3702",
      "instanceId": 12420,
      "authEntityId": 12420,
      "displayName": "12420",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "632",
      "protocol": "UDP",
      "upperPort": 0
    }
  ],
  "networkApplications": [
    {
      "id": "59e87105-8f32-40ae-9cb9-16ab89fb2833",
      "instanceId": 11152,
      "authEntityId": 11152,
      "displayName": "11152",
      "authEntityClass": -217092956,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "appProtocol": "tcp/udp",
      "applicationGroup": "other",
      "applicationType": "DEFAULT",
      "categoryId": "27953c60-b2ff-4e1a-bc52-0187d8295250",
      "createTime": 1517966741651,
      "encrypted": "false",
      "engineId": "3",
      "helpString": "bmpp",
      "ignoreConflict": false,
      "lastUpdateTime": 1517966741651,
      "longDescription": "BMPP is a protocol for identifying the bulk mail receipt preferences of an e-mail address, which allows bulk e-mailers to discover if a mailbox is willing to accept bulk email.",
      "name": "bmpp",
      "nbarId": "541",
      "p2pTechnology": "false",
      "popularity": 1,
      "rank": 65535,
      "references": "http://tools.ietf.org/html/draft-rollo-bmpp-03",
      "selectorId": "632",
      "subCategory": "b90786f4-0190-45b8-9924-21b3e9682590",
      "trafficClass": "BULK_DATA",
      "tunnel": "false"
    }
  ],
  "networkIdentity": [
    {
      "id": "0e454958-c8b9-43dd-a13e-4dda69234b57",
      "instanceId": 12417,
      "authEntityId": 12417,
      "displayName": "12417",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "632",
      "protocol": "TCP",
      "upperPort": 0
    },
    {
      "id": "eee71b2e-e487-4069-980f-a5c89db7816e",
      "instanceId": 12418,
      "authEntityId": 12418,
      "displayName": "12418",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "632",
      "protocol": "UDP",
      "upperPort": 0
    }
  ],
  "parentScalableGroup": {
    "idRef": "8a7ca43a-32bd-40d2-871a-02220a165cb7"
  },
  "user": [

  ],
  "userGroup": [

  ]
},
{
  "id": "7346dae8-728b-4960-8c99-45924f317afb",
  "instanceId": 4718,
  "authEntityId": 4718,
  "displayName": "4718",
  "authEntityClass": -1909073334,
  "deployPending": "NONE",
  "instanceVersion": 0,
  "createTime": 1517966743348,
  "deployed": false,
  "isSeeded": true,
  "isStale": false,
  "lastUpdateTime": 1517966743348,
  "name": "maitrd",
  "namespace": "scalablegroup:application",
  "provisioningState": "DEFINED",
  "qualifier": "application",
  "resourceVersion": 0,
  "targetIdList": [

  ],
  "type": "scalablegroup",
  "cfsChangeInfo": [

  ],
  "customProvisions": [

  ],
  "scalableGroupExternalHandle": "maitrd",
  "scalableGroupType": "APPLICATION",
  "securityGroupTag": 0,
  "state": "ACTIVE",
  "vnAgnostic": true,
  "identitySource": {
    "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
    "instanceId": 10010,
    "authEntityId": 10010,
    "displayName": "10010",
    "authEntityClass": 1738953278,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "state": "INACTIVE",
    "type": "NBAR"
  },
  "indicativeNetworkIdentity": [
    {
      "id": "c8b9647b-d84f-4f43-b746-a3b62af73b89",
      "instanceId": 13822,
      "authEntityId": 13822,
      "displayName": "13822",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "997",
      "protocol": "TCP",
      "upperPort": 0
    },
    {
      "id": "93b27c1c-5fe9-4749-8436-8e9f6e21bfa4",
      "instanceId": 13823,
      "authEntityId": 13823,
      "displayName": "13823",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "997",
      "protocol": "UDP",
      "upperPort": 0
    }
  ],
  "networkApplications": [
    {
      "id": "b90f7ea2-2048-404c-b357-a1c870416326",
      "instanceId": 11668,
      "authEntityId": 11668,
      "displayName": "11668",
      "authEntityClass": -217092956,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "appProtocol": "tcp/udp",
      "applicationGroup": "other",
      "applicationType": "DEFAULT",
      "categoryId": "6daa3dfd-3526-407a-88f0-97824b3f3a24",
      "createTime": 1517966743348,
      "encrypted": "false",
      "engineId": "3",
      "helpString": "maitrd",
      "ignoreConflict": false,
      "lastUpdateTime": 1517966743348,
      "longDescription": "Registered with IANA on port 997 TCP/UDP",
      "name": "maitrd",
      "nbarId": "676",
      "p2pTechnology": "false",
      "popularity": 1,
      "rank": 65535,
      "references": "http://www.iana.org/assignments/service-names-port-numbers/service-names-port-numbers.xml",
      "selectorId": "997",
      "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
      "trafficClass": "BULK_DATA",
      "tunnel": "false"
    }
  ],
  "networkIdentity": [
    {
      "id": "da6f8b5c-03e2-46ff-b5c7-5cef800ea029",
      "instanceId": 13820,
      "authEntityId": 13820,
      "displayName": "13820",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "997",
      "protocol": "UDP",
      "upperPort": 0
    },
    {
      "id": "eea64c30-893a-4a89-88cc-329786dd975f",
      "instanceId": 13821,
      "authEntityId": 13821,
      "displayName": "13821",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "997",
      "protocol": "TCP",
      "upperPort": 0
    }
  ],
  "parentScalableGroup": {
    "idRef": "000c15bf-28fe-4fbb-9b69-35e6502721f1"
  },
  "user": [

  ],
  "userGroup": [

  ]
},
{
  "id": "73844139-c222-4a59-9e85-0488de826fe1",
  "instanceId": 4058,
  "authEntityId": 4058,
  "displayName": "4058",
  "authEntityClass": -1909073334,
  "deployPending": "NONE",
  "instanceVersion": 0,
  "createTime": 1517966740658,
  "deployed": false,
  "isSeeded": true,
  "isStale": false,
  "lastUpdateTime": 1517966740658,
  "name": "activesync",
  "namespace": "scalablegroup:application",
  "provisioningState": "DEFINED",
  "qualifier": "application",
  "resourceVersion": 0,
  "targetIdList": [

  ],
  "type": "scalablegroup",
  "cfsChangeInfo": [

  ],
  "customProvisions": [

  ],
  "scalableGroupExternalHandle": "activesync",
  "scalableGroupType": "APPLICATION",
  "securityGroupTag": 0,
  "state": "ACTIVE",
  "vnAgnostic": true,
  "identitySource": {
    "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
    "instanceId": 10010,
    "authEntityId": 10010,
    "displayName": "10010",
    "authEntityClass": 1738953278,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "state": "INACTIVE",
    "type": "NBAR"
  },
  "indicativeNetworkIdentity": [

  ],
  "networkApplications": [
    {
      "id": "61625d99-8caf-46e9-b175-ed999304069e",
      "instanceId": 11029,
      "authEntityId": 11029,
      "displayName": "11029",
      "authEntityClass": -217092956,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "appProtocol": "tcp",
      "applicationGroup": "other",
      "applicationType": "DEFAULT",
      "categoryId": "7e19fdea-6222-4c57-9e66-66c2340d6bc3",
      "createTime": 1517966740658,
      "encrypted": "true",
      "engineId": "13",
      "helpString": "Microsoft Activesync protocol mobile data synchronization based on HTTP",
      "ignoreConflict": false,
      "lastUpdateTime": 1517966740658,
      "longDescription": "ActiveSync is a mobile data synchronization technology and protocol based on HTTP, developed by Microsoft. One implementation of the technology is that it synchronizes data and information with handheld devices and a specific desktop computer. The other technology, commonly known as Exchange ActiveSync (EAS), provides push synchronization of contacts, calendars, tasks, and email between ActiveSync-enabled servers and devices.",
      "name": "activesync",
      "nbarId": "1419",
      "p2pTechnology": "false",
      "popularity": 6,
      "rank": 65535,
      "references": "http://msdn.microsoft.com/en-us/library/dd299446(v=exchg.80).aspx",
      "selectorId": "490",
      "subCategory": "b90786f4-0190-45b8-9924-21b3e9682590",
      "trafficClass": "TRANSACTIONAL_DATA",
      "tunnel": "false"
    }
  ],
  "networkIdentity": [
    {
      "id": "5c8f7fbe-9d54-4603-8074-4c10841c8722",
      "instanceId": 12072,
      "authEntityId": 12072,
      "displayName": "12072",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "80",
      "protocol": "TCP",
      "upperPort": 0
    }
  ],
  "parentScalableGroup": {
    "idRef": "383363b1-4dd5-49d1-bfe4-34daac437cfb"
  },
  "user": [

  ],
  "userGroup": [

  ]
},
{
  "id": "73a045fc-63b7-4651-a82c-6d9f16b1fcf1",
  "instanceId": 4605,
  "authEntityId": 4605,
  "displayName": "4605",
  "authEntityClass": -1909073334,
  "deployPending": "NONE",
  "instanceVersion": 0,
  "createTime": 1517966743102,
  "deployed": false,
  "isSeeded": true,
  "isStale": false,
  "lastUpdateTime": 1517966743102,
  "name": "ingres-net",
  "namespace": "scalablegroup:application",
  "provisioningState": "DEFINED",
  "qualifier": "application",
  "resourceVersion": 0,
  "targetIdList": [

  ],
  "type": "scalablegroup",
  "cfsChangeInfo": [

  ],
  "customProvisions": [

  ],
  "scalableGroupExternalHandle": "ingres-net",
  "scalableGroupType": "APPLICATION",
  "securityGroupTag": 0,
  "state": "ACTIVE",
  "vnAgnostic": true,
  "identitySource": {
    "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
    "instanceId": 10010,
    "authEntityId": 10010,
    "displayName": "10010",
    "authEntityClass": 1738953278,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "state": "INACTIVE",
    "type": "NBAR"
  },
  "indicativeNetworkIdentity": [
    {
      "id": "0bb7e8d7-d749-4da5-ab67-14cfb694f357",
      "instanceId": 13527,
      "authEntityId": 13527,
      "displayName": "13527",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "134",
      "protocol": "TCP",
      "upperPort": 0
    },
    {
      "id": "7d7fdb79-dc96-41e9-b55e-4e405224cf80",
      "instanceId": 13528,
      "authEntityId": 13528,
      "displayName": "13528",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "134",
      "protocol": "UDP",
      "upperPort": 0
    }
  ],
  "networkApplications": [
    {
      "id": "c5735de1-531d-43e2-a409-e78dd794aa7c",
      "instanceId": 11555,
      "authEntityId": 11555,
      "displayName": "11555",
      "authEntityClass": -217092956,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "appProtocol": "tcp/udp",
      "applicationGroup": "other",
      "applicationType": "DEFAULT",
      "categoryId": "cb5915d1-afec-4c30-ac9e-069ff382272f",
      "createTime": 1517966743102,
      "encrypted": "false",
      "engineId": "3",
      "helpString": "INGRES-NET Service",
      "ignoreConflict": false,
      "lastUpdateTime": 1517966743102,
      "longDescription": "Ingres/Net allows services and applications to access Ingres databases over the network.",
      "name": "ingres-net",
      "nbarId": "1163",
      "p2pTechnology": "false",
      "popularity": 1,
      "rank": 65535,
      "references": "http://www.actian.com/products/ingres",
      "selectorId": "134",
      "subCategory": "b90786f4-0190-45b8-9924-21b3e9682590",
      "trafficClass": "TRANSACTIONAL_DATA",
      "tunnel": "false"
    }
  ],
  "networkIdentity": [
    {
      "id": "e3c91d82-135c-436b-8cdd-1736640925d2",
      "instanceId": 13525,
      "authEntityId": 13525,
      "displayName": "13525",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "134",
      "protocol": "TCP",
      "upperPort": 0
    },
    {
      "id": "8091b0b8-3123-4c18-b765-22ff4a171107",
      "instanceId": 13526,
      "authEntityId": 13526,
      "displayName": "13526",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "134",
      "protocol": "UDP",
      "upperPort": 0
    }
  ],
  "parentScalableGroup": {
    "idRef": "8718395e-683e-4a2d-a232-100202751271"
  },
  "user": [

  ],
  "userGroup": [

  ]
},
{
  "id": "73b475b8-c93e-49e0-bc88-460b73e67d7b",
  "instanceId": 15057,
  "authEntityId": 15057,
  "displayName": "15057",
  "authEntityClass": -1909073334,
  "deployPending": "NONE",
  "instanceVersion": 0,
  "createTime": 1517966744133,
  "deployed": false,
  "isSeeded": true,
  "isStale": false,
  "lastUpdateTime": 1517966744133,
  "name": "rmonitor",
  "namespace": "scalablegroup:application",
  "provisioningState": "DEFINED",
  "qualifier": "application",
  "resourceVersion": 0,
  "targetIdList": [

  ],
  "type": "scalablegroup",
  "cfsChangeInfo": [

  ],
  "customProvisions": [

  ],
  "scalableGroupExternalHandle": "rmonitor",
  "scalableGroupType": "APPLICATION",
  "securityGroupTag": 0,
  "state": "ACTIVE",
  "vnAgnostic": true,
  "identitySource": {
    "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
    "instanceId": 10010,
    "authEntityId": 10010,
    "displayName": "10010",
    "authEntityClass": 1738953278,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "state": "INACTIVE",
    "type": "NBAR"
  },
  "indicativeNetworkIdentity": [
    {
      "id": "a88c6b60-45a6-449d-bc5b-ddf2c60a5343",
      "instanceId": 14811,
      "authEntityId": 14811,
      "displayName": "14811",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "560",
      "protocol": "UDP",
      "upperPort": 0
    },
    {
      "id": "cfc3f42a-f4dc-4565-ace6-da8d29e24344",
      "instanceId": 14812,
      "authEntityId": 14812,
      "displayName": "14812",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "560",
      "protocol": "TCP",
      "upperPort": 0
    }
  ],
  "networkApplications": [
    {
      "id": "620bd021-3261-40fe-bf0f-f9af78704a62",
      "instanceId": 11996,
      "authEntityId": 11996,
      "displayName": "11996",
      "authEntityClass": -217092956,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "appProtocol": "tcp/udp",
      "applicationGroup": "other",
      "applicationType": "DEFAULT",
      "categoryId": "1fc17362-9334-4c7b-b752-5afdbb574017",
      "createTime": 1517966744133,
      "encrypted": "false",
      "engineId": "3",
      "helpString": "rmonitord",
      "ignoreConflict": false,
      "lastUpdateTime": 1517966744133,
      "longDescription": "Rmonitor is a protocol used by remote network monitoring devices, often called monitors or probes, that exist for the purpose of managing a network. Often these remote probes are stand-alone devices and devote significant internal resources for the sole purpose of managing a network.  An organization may employ many of these devices, one per network segment, to manage its internet.",
      "name": "rmonitor",
      "nbarId": "475",
      "p2pTechnology": "false",
      "popularity": 1,
      "rank": 65535,
      "references": "http://tools.ietf.org/html/rfc2819",
      "selectorId": "560",
      "subCategory": "f3c0f761-d894-4112-ab89-b90fc84fd660",
      "trafficClass": "OPS_ADMIN_MGMT",
      "tunnel": "false"
    }
  ],
  "networkIdentity": [
    {
      "id": "1e1b51d6-c1d6-446d-bd62-530036d4ee84",
      "instanceId": 14809,
      "authEntityId": 14809,
      "displayName": "14809",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "560",
      "protocol": "UDP",
      "upperPort": 0
    },
    {
      "id": "00050c4c-da72-4471-a907-540b7d1727e7",
      "instanceId": 14810,
      "authEntityId": 14810,
      "displayName": "14810",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "560",
      "protocol": "TCP",
      "upperPort": 0
    }
  ],
  "parentScalableGroup": {
    "idRef": "5fcd74d4-8726-47f7-a088-c457863da7ef"
  },
  "user": [

  ],
  "userGroup": [

  ]
},
{
  "id": "73bfdc06-3143-45cd-9e74-a07abba8c7a2",
  "instanceId": 4941,
  "authEntityId": 4941,
  "displayName": "4941",
  "authEntityClass": -1909073334,
  "deployPending": "NONE",
  "instanceVersion": 0,
  "createTime": 1517966743859,
  "deployed": false,
  "isSeeded": true,
  "isStale": false,
  "lastUpdateTime": 1517966743859,
  "name": "paypal",
  "namespace": "scalablegroup:application",
  "provisioningState": "DEFINED",
  "qualifier": "application",
  "resourceVersion": 0,
  "targetIdList": [

  ],
  "type": "scalablegroup",
  "cfsChangeInfo": [

  ],
  "customProvisions": [

  ],
  "scalableGroupExternalHandle": "paypal",
  "scalableGroupType": "APPLICATION",
  "securityGroupTag": 0,
  "state": "ACTIVE",
  "vnAgnostic": true,
  "identitySource": {
    "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
    "instanceId": 10010,
    "authEntityId": 10010,
    "displayName": "10010",
    "authEntityClass": 1738953278,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "state": "INACTIVE",
    "type": "NBAR"
  },
  "indicativeNetworkIdentity": [

  ],
  "networkApplications": [
    {
      "id": "7a6bf3d0-e8da-4856-b250-2dbecbabb273",
      "instanceId": 11890,
      "authEntityId": 11890,
      "displayName": "11890",
      "authEntityClass": -217092956,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "appProtocol": "tcp/udp",
      "applicationGroup": "other",
      "applicationType": "DEFAULT",
      "categoryId": "878644b5-fe91-45b4-9204-31c84a73ba48",
      "createTime": 1517966743859,
      "encrypted": "false",
      "engineId": "13",
      "helpString": "PayPal - E-commerce website for handling online transactions.",
      "ignoreConflict": false,
      "lastUpdateTime": 1517966743859,
      "longDescription": "PayPal - E-commerce website for handling online transactions.",
      "name": "paypal",
      "nbarId": "261",
      "p2pTechnology": "false",
      "popularity": 3,
      "rank": 65535,
      "references": "http://paypal.com",
      "selectorId": "512",
      "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
      "trafficClass": "TRANSACTIONAL_DATA",
      "tunnel": "false"
    }
  ],
  "networkIdentity": [
    {
      "id": "e4dcbedb-a9b2-4ceb-a8a2-2342eab07715",
      "instanceId": 14489,
      "authEntityId": 14489,
      "displayName": "14489",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "53,5353",
      "protocol": "UDP",
      "upperPort": 0
    },
    {
      "id": "f7306470-f891-401a-93e6-6e4cfea3e1d6",
      "instanceId": 14490,
      "authEntityId": 14490,
      "displayName": "14490",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "53,80,443,5353",
      "protocol": "TCP",
      "upperPort": 0
    }
  ],
  "parentScalableGroup": {
    "idRef": "28e08de4-d7b2-4521-84ce-bc1e5ee0b9cc"
  },
  "user": [

  ],
  "userGroup": [

  ]
},
{
  "id": "74198f96-dce7-4072-9c81-7ce523286642",
  "instanceId": 4186,
  "authEntityId": 4186,
  "displayName": "4186",
  "authEntityClass": -1909073334,
  "deployPending": "NONE",
  "instanceVersion": 0,
  "createTime": 1517966741581,
  "deployed": false,
  "isSeeded": true,
  "isStale": false,
  "lastUpdateTime": 1517966741581,
  "name": "bl-idm",
  "namespace": "scalablegroup:application",
  "provisioningState": "DEFINED",
  "qualifier": "application",
  "resourceVersion": 0,
  "targetIdList": [

  ],
  "type": "scalablegroup",
  "cfsChangeInfo": [

  ],
  "customProvisions": [

  ],
  "scalableGroupExternalHandle": "bl-idm",
  "scalableGroupType": "APPLICATION",
  "securityGroupTag": 0,
  "state": "ACTIVE",
  "vnAgnostic": true,
  "identitySource": {
    "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
    "instanceId": 10010,
    "authEntityId": 10010,
    "displayName": "10010",
    "authEntityClass": 1738953278,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "state": "INACTIVE",
    "type": "NBAR"
  },
  "indicativeNetworkIdentity": [
    {
      "id": "1b118af3-a010-4ebc-b063-28c92df3da3b",
      "instanceId": 12401,
      "authEntityId": 12401,
      "displayName": "12401",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "142",
      "protocol": "TCP",
      "upperPort": 0
    },
    {
      "id": "a3356e10-9f17-4de6-bb52-c8ab244e1715",
      "instanceId": 12402,
      "authEntityId": 12402,
      "displayName": "12402",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "142",
      "protocol": "UDP",
      "upperPort": 0
    }
  ],
  "networkApplications": [
    {
      "id": "64c2ebe8-8629-44f9-8b3d-46a16bdc670f",
      "instanceId": 11145,
      "authEntityId": 11145,
      "displayName": "11145",
      "authEntityClass": -217092956,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "appProtocol": "tcp/udp",
      "applicationGroup": "other",
      "applicationType": "DEFAULT",
      "categoryId": "6daa3dfd-3526-407a-88f0-97824b3f3a24",
      "createTime": 1517966741581,
      "encrypted": "false",
      "engineId": "3",
      "helpString": "Britton Lee IDM",
      "ignoreConflict": false,
      "lastUpdateTime": 1517966741581,
      "longDescription": "Britton Lee IDM is a protocol developed in the late 1980's as part of the Britton Lee company's relational database system.",
      "name": "bl-idm",
      "nbarId": "935",
      "p2pTechnology": "false",
      "popularity": 1,
      "rank": 65535,
      "references": "http://archive.computerhistory.org/resources/access/text/2011/09/102685092-05-01.acc.pdf",
      "selectorId": "142",
      "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
      "trafficClass": "BULK_DATA",
      "tunnel": "false"
    }
  ],
  "networkIdentity": [
    {
      "id": "7bac1301-8ed0-4423-a00a-e02f6ccdf7ab",
      "instanceId": 12400,
      "authEntityId": 12400,
      "displayName": "12400",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "142",
      "protocol": "UDP",
      "upperPort": 0
    },
    {
      "id": "9756c0da-6dfb-4c99-82e8-3f7a7583e159",
      "instanceId": 12399,
      "authEntityId": 12399,
      "displayName": "12399",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "142",
      "protocol": "TCP",
      "upperPort": 0
    }
  ],
  "parentScalableGroup": {
    "idRef": "000c15bf-28fe-4fbb-9b69-35e6502721f1"
  },
  "user": [

  ],
  "userGroup": [

  ]
},
{
  "id": "743f4114-60e1-480b-a917-839209b0e7e4",
  "instanceId": 4756,
  "authEntityId": 4756,
  "displayName": "4756",
  "authEntityClass": -1909073334,
  "deployPending": "NONE",
  "instanceVersion": 0,
  "createTime": 1517966743431,
  "deployed": false,
  "isSeeded": true,
  "isStale": false,
  "lastUpdateTime": 1517966743431,
  "name": "mobilip-mn",
  "namespace": "scalablegroup:application",
  "provisioningState": "DEFINED",
  "qualifier": "application",
  "resourceVersion": 0,
  "targetIdList": [

  ],
  "type": "scalablegroup",
  "cfsChangeInfo": [

  ],
  "customProvisions": [

  ],
  "scalableGroupExternalHandle": "mobilip-mn",
  "scalableGroupType": "APPLICATION",
  "securityGroupTag": 0,
  "state": "ACTIVE",
  "vnAgnostic": true,
  "identitySource": {
    "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
    "instanceId": 10010,
    "authEntityId": 10010,
    "displayName": "10010",
    "authEntityClass": 1738953278,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "state": "INACTIVE",
    "type": "NBAR"
  },
  "indicativeNetworkIdentity": [
    {
      "id": "686df959-f7e7-4c78-b2e4-89c2a81d52fc",
      "instanceId": 13932,
      "authEntityId": 13932,
      "displayName": "13932",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "435",
      "protocol": "UDP",
      "upperPort": 0
    },
    {
      "id": "4ad0c052-2c23-4424-a2bc-6bed675c20dc",
      "instanceId": 13933,
      "authEntityId": 13933,
      "displayName": "13933",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "435",
      "protocol": "TCP",
      "upperPort": 0
    }
  ],
  "networkApplications": [
    {
      "id": "c564ca8d-00c0-4632-9dce-29c4badb5db4",
      "instanceId": 11706,
      "authEntityId": 11706,
      "displayName": "11706",
      "authEntityClass": -217092956,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "appProtocol": "tcp/udp",
      "applicationGroup": "other",
      "applicationType": "DEFAULT",
      "categoryId": "6daa3dfd-3526-407a-88f0-97824b3f3a24",
      "createTime": 1517966743431,
      "encrypted": "false",
      "engineId": "3",
      "helpString": "mobilip-mn",
      "ignoreConflict": false,
      "lastUpdateTime": 1517966743431,
      "longDescription": "Registered with IANA on port 435 TCP/UDP",
      "name": "mobilip-mn",
      "nbarId": "350",
      "p2pTechnology": "false",
      "popularity": 1,
      "rank": 65535,
      "references": "http://www.iana.org/assignments/service-names-port-numbers/service-names-port-numbers.xml",
      "selectorId": "435",
      "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
      "trafficClass": "BULK_DATA",
      "tunnel": "false"
    }
  ],
  "networkIdentity": [
    {
      "id": "906d2633-b64d-45be-8256-b85b1cd30157",
      "instanceId": 13930,
      "authEntityId": 13930,
      "displayName": "13930",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "435",
      "protocol": "UDP",
      "upperPort": 0
    },
    {
      "id": "d4d7b0ba-62f3-4bb0-a6ae-b6658e3bab15",
      "instanceId": 13931,
      "authEntityId": 13931,
      "displayName": "13931",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "435",
      "protocol": "TCP",
      "upperPort": 0
    }
  ],
  "parentScalableGroup": {
    "idRef": "000c15bf-28fe-4fbb-9b69-35e6502721f1"
  },
  "user": [

  ],
  "userGroup": [

  ]
},
{
  "id": "7443fbda-1317-4cb6-a36a-113808f75512",
  "instanceId": 15206,
  "authEntityId": 15206,
  "displayName": "15206",
  "authEntityClass": -1909073334,
  "deployPending": "NONE",
  "instanceVersion": 0,
  "createTime": 1517966744481,
  "deployed": false,
  "isSeeded": true,
  "isStale": false,
  "lastUpdateTime": 1517966744481,
  "name": "ssh",
  "namespace": "scalablegroup:application",
  "provisioningState": "DEFINED",
  "qualifier": "application",
  "resourceVersion": 0,
  "targetIdList": [

  ],
  "type": "scalablegroup",
  "cfsChangeInfo": [

  ],
  "customProvisions": [

  ],
  "scalableGroupExternalHandle": "ssh",
  "scalableGroupType": "APPLICATION",
  "securityGroupTag": 0,
  "state": "ACTIVE",
  "vnAgnostic": true,
  "identitySource": {
    "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
    "instanceId": 10010,
    "authEntityId": 10010,
    "displayName": "10010",
    "authEntityClass": 1738953278,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "state": "INACTIVE",
    "type": "NBAR"
  },
  "indicativeNetworkIdentity": [
    {
      "id": "fd5c0a78-b74f-48d0-b6c2-558d09686e68",
      "instanceId": 17259,
      "authEntityId": 17259,
      "displayName": "17259",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "22",
      "protocol": "TCP",
      "upperPort": 0
    }
  ],
  "networkApplications": [
    {
      "id": "1945107b-50c1-4518-8f93-553f4af98485",
      "instanceId": 16149,
      "authEntityId": 16149,
      "displayName": "16149",
      "authEntityClass": -217092956,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "appProtocol": "tcp",
      "applicationGroup": "other",
      "applicationType": "DEFAULT",
      "categoryId": "1fc17362-9334-4c7b-b752-5afdbb574017",
      "createTime": 1517966744481,
      "encrypted": "true",
      "engineId": "3",
      "helpString": "Secure Shell",
      "ignoreConflict": false,
      "lastUpdateTime": 1517966744481,
      "longDescription": "Secure Shell Protocol (SSH) is a protocol used to secure login and other secure network services over an unsecure network. The protocol based on a client-server architecture has three steps for the connection: First the server has to be authenticated to the client over a reliable transport connection (usually TCP/IP), then the client side is authenticated-only then the connection is established and the client-server encrypted connection can transfer data between them. Typically the protocol uses TCP port 22.",
      "name": "ssh",
      "nbarId": "40",
      "p2pTechnology": "false",
      "popularity": 9,
      "rank": 65535,
      "references": "http://www.ietf.org/rfc/rfc4251.txt",
      "selectorId": "22",
      "subCategory": "787a7d77-977b-4974-89d3-379ba49e88b7",
      "trafficClass": "OPS_ADMIN_MGMT",
      "tunnel": "true"
    }
  ],
  "networkIdentity": [
    {
      "id": "dfcbb43e-4a5d-46fb-b824-411779584623",
      "instanceId": 17258,
      "authEntityId": 17258,
      "displayName": "17258",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "22",
      "protocol": "TCP",
      "upperPort": 0
    }
  ],
  "parentScalableGroup": {
    "idRef": "e06dd89f-0a70-4422-ab0b-c2314650a9a7"
  },
  "user": [

  ],
  "userGroup": [

  ]
},
{
  "id": "74ba467a-4ea7-4149-a945-85001a8f3fd7",
  "instanceId": 4466,
  "authEntityId": 4466,
  "displayName": "4466",
  "authEntityClass": -1909073334,
  "deployPending": "NONE",
  "instanceVersion": 0,
  "createTime": 1517966742741,
  "deployed": false,
  "isSeeded": true,
  "isStale": false,
  "lastUpdateTime": 1517966742741,
  "name": "fring-voip",
  "namespace": "scalablegroup:application",
  "provisioningState": "DEFINED",
  "qualifier": "application",
  "resourceVersion": 0,
  "targetIdList": [

  ],
  "type": "scalablegroup",
  "cfsChangeInfo": [

  ],
  "customProvisions": [

  ],
  "scalableGroupExternalHandle": "fring-voip",
  "scalableGroupType": "APPLICATION",
  "securityGroupTag": 0,
  "state": "ACTIVE",
  "vnAgnostic": true,
  "identitySource": {
    "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
    "instanceId": 10010,
    "authEntityId": 10010,
    "displayName": "10010",
    "authEntityClass": 1738953278,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "state": "INACTIVE",
    "type": "NBAR"
  },
  "indicativeNetworkIdentity": [

  ],
  "networkApplications": [
    {
      "id": "62929e4c-7e43-4f74-82bb-97d15adab770",
      "instanceId": 11417,
      "authEntityId": 11417,
      "displayName": "11417",
      "authEntityClass": -217092956,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "applicationGroup": "fring-group",
      "applicationType": "DEFAULT",
      "categoryId": "82a7b7fa-da5a-48f5-8b6a-a2546b947843",
      "createTime": 1517966742741,
      "encrypted": "false",
      "engineId": "13",
      "helpString": "DEPRECATED, traffic will not match",
      "ignoreConflict": false,
      "lastUpdateTime": 1517966742741,
      "longDescription": "Deprecated: Fring VoIP refers to Fring audio calls. Fring is a peer-to-peer mobile service enabling live chat, audio and video calls from mobile phones. Fring technology is a Mobile VoIP based internet telephony service. Fring runs on various mobile devices including iPhone, Android and Nokia.",
      "name": "fring-voip",
      "nbarId": "1053",
      "p2pTechnology": "true",
      "popularity": 7,
      "rank": 65535,
      "references": "http://www.fring.com/what-is-fring",
      "selectorId": "444",
      "subCategory": "118c10a2-e272-47c1-92b9-4814ebe97138",
      "trafficClass": "VOIP_TELEPHONY",
      "tunnel": "false"
    }
  ],
  "networkIdentity": [

  ],
  "parentScalableGroup": {
    "idRef": "fd6264c9-c16a-4311-a335-a3db039256f3"
  },
  "user": [

  ],
  "userGroup": [

  ]
},
{
  "id": "755fc430-b119-4dac-8114-ef7aa7ec8791",
  "instanceId": 4391,
  "authEntityId": 4391,
  "displayName": "4391",
  "authEntityClass": -1909073334,
  "deployPending": "NONE",
  "instanceVersion": 0,
  "createTime": 1517966742514,
  "deployed": false,
  "isSeeded": true,
  "isStale": false,
  "lastUpdateTime": 1517966742514,
  "name": "dsr",
  "namespace": "scalablegroup:application",
  "provisioningState": "DEFINED",
  "qualifier": "application",
  "resourceVersion": 0,
  "targetIdList": [

  ],
  "type": "scalablegroup",
  "cfsChangeInfo": [

  ],
  "customProvisions": [

  ],
  "scalableGroupExternalHandle": "dsr",
  "scalableGroupType": "APPLICATION",
  "securityGroupTag": 0,
  "state": "ACTIVE",
  "vnAgnostic": true,
  "identitySource": {
    "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
    "instanceId": 10010,
    "authEntityId": 10010,
    "displayName": "10010",
    "authEntityClass": 1738953278,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "state": "INACTIVE",
    "type": "NBAR"
  },
  "indicativeNetworkIdentity": [
    {
      "id": "804e8b54-696b-41f8-a462-ecbb0cc15419",
      "instanceId": 12969,
      "authEntityId": 12969,
      "displayName": "12969",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipProtocolNumbers": "48",
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "protocol": "DSR",
      "upperPort": 0
    }
  ],
  "networkApplications": [
    {
      "id": "287ad7f5-05a3-4966-bcc8-fbdeef05b57c",
      "instanceId": 11342,
      "authEntityId": 11342,
      "displayName": "11342",
      "authEntityClass": -217092956,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "appProtocol": "ip",
      "applicationGroup": "other",
      "applicationType": "DEFAULT",
      "categoryId": "6daa3dfd-3526-407a-88f0-97824b3f3a24",
      "createTime": 1517966742514,
      "encrypted": "false",
      "engineId": "1",
      "helpString": "Dynamic Source Routing Protocol",
      "ignoreConflict": false,
      "lastUpdateTime": 1517966742514,
      "longDescription": "Dynamic Source Routing (DSR) is a routing protocol for wireless mesh networks. It forms a route on-demand when a transmitting computer requests one, and usessource routing instead of relying on the routing table at each intermediate device.",
      "name": "dsr",
      "nbarId": "1240",
      "p2pTechnology": "false",
      "popularity": 2,
      "rank": 65535,
      "references": "http://tools.ietf.org/html/rfc4728",
      "selectorId": "48",
      "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
      "trafficClass": "BULK_DATA",
      "tunnel": "false"
    }
  ],
  "networkIdentity": [
    {
      "id": "a6f61b7c-2e5f-4d46-8dc7-90b9025a163d",
      "instanceId": 12968,
      "authEntityId": 12968,
      "displayName": "12968",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipProtocolNumbers": "48",
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "protocol": "DSR",
      "upperPort": 0
    }
  ],
  "parentScalableGroup": {
    "idRef": "000c15bf-28fe-4fbb-9b69-35e6502721f1"
  },
  "user": [

  ],
  "userGroup": [

  ]
},
{
  "id": "7567801c-bec3-4c16-9f24-03fe20e7860e",
  "instanceId": 4201,
  "authEntityId": 4201,
  "displayName": "4201",
  "authEntityClass": -1909073334,
  "deployPending": "NONE",
  "instanceVersion": 0,
  "createTime": 1517966741670,
  "deployed": false,
  "isSeeded": true,
  "isStale": false,
  "lastUpdateTime": 1517966741670,
  "name": "br-sat-mon",
  "namespace": "scalablegroup:application",
  "provisioningState": "DEFINED",
  "qualifier": "application",
  "resourceVersion": 0,
  "targetIdList": [

  ],
  "type": "scalablegroup",
  "cfsChangeInfo": [

  ],
  "customProvisions": [

  ],
  "scalableGroupExternalHandle": "br-sat-mon",
  "scalableGroupType": "APPLICATION",
  "securityGroupTag": 0,
  "state": "ACTIVE",
  "vnAgnostic": true,
  "identitySource": {
    "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
    "instanceId": 10010,
    "authEntityId": 10010,
    "displayName": "10010",
    "authEntityClass": 1738953278,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "state": "INACTIVE",
    "type": "NBAR"
  },
  "indicativeNetworkIdentity": [
    {
      "id": "46779916-9745-4501-a329-57fac73cb21b",
      "instanceId": 12434,
      "authEntityId": 12434,
      "displayName": "12434",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipProtocolNumbers": "76",
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "protocol": "BR_SAT_MON",
      "upperPort": 0
    }
  ],
  "networkApplications": [
    {
      "id": "63c493da-cbfc-4f5c-a0c9-d42f5fea695b",
      "instanceId": 11157,
      "authEntityId": 11157,
      "displayName": "11157",
      "authEntityClass": -217092956,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "appProtocol": "ip",
      "applicationGroup": "other",
      "applicationType": "DEFAULT",
      "categoryId": "6daa3dfd-3526-407a-88f0-97824b3f3a24",
      "createTime": 1517966741670,
      "encrypted": "unassigned",
      "engineId": "1",
      "helpString": "Backroom SATNET Monitoring",
      "ignoreConflict": false,
      "lastUpdateTime": 1517966741670,
      "longDescription": "Registered with IANA as IP Protocol 76",
      "name": "br-sat-mon",
      "nbarId": "830",
      "p2pTechnology": "unassigned",
      "popularity": 1,
      "rank": 65535,
      "references": "http://www.iana.org/assignments/protocol-numbers/protocol-numbers.xml",
      "selectorId": "76",
      "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
      "trafficClass": "BULK_DATA",
      "tunnel": "unassigned"
    }
  ],
  "networkIdentity": [
    {
      "id": "b1933555-3580-402e-a7cd-b415761df8ab",
      "instanceId": 12433,
      "authEntityId": 12433,
      "displayName": "12433",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipProtocolNumbers": "76",
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "protocol": "BR_SAT_MON",
      "upperPort": 0
    }
  ],
  "parentScalableGroup": {
    "idRef": "000c15bf-28fe-4fbb-9b69-35e6502721f1"
  },
  "user": [

  ],
  "userGroup": [

  ]
},
{
  "id": "75947bce-3433-42b8-a1a3-34a4f347e294",
  "instanceId": 4745,
  "authEntityId": 4745,
  "displayName": "4745",
  "authEntityClass": -1909073334,
  "deployPending": "NONE",
  "instanceVersion": 0,
  "createTime": 1517966743409,
  "deployed": false,
  "isSeeded": true,
  "isStale": false,
  "lastUpdateTime": 1517966743409,
  "name": "micp",
  "namespace": "scalablegroup:application",
  "provisioningState": "DEFINED",
  "qualifier": "application",
  "resourceVersion": 0,
  "targetIdList": [

  ],
  "type": "scalablegroup",
  "cfsChangeInfo": [

  ],
  "customProvisions": [

  ],
  "scalableGroupExternalHandle": "micp",
  "scalableGroupType": "APPLICATION",
  "securityGroupTag": 0,
  "state": "ACTIVE",
  "vnAgnostic": true,
  "identitySource": {
    "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
    "instanceId": 10010,
    "authEntityId": 10010,
    "displayName": "10010",
    "authEntityClass": 1738953278,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "state": "INACTIVE",
    "type": "NBAR"
  },
  "indicativeNetworkIdentity": [
    {
      "id": "6f639966-d38f-462d-83b3-32b98a3fae43",
      "instanceId": 13899,
      "authEntityId": 13899,
      "displayName": "13899",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipProtocolNumbers": "95",
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "protocol": "MICP",
      "upperPort": 0
    }
  ],
  "networkApplications": [
    {
      "id": "291a78c5-3cf5-4b84-851b-e90eba3a1890",
      "instanceId": 11695,
      "authEntityId": 11695,
      "displayName": "11695",
      "authEntityClass": -217092956,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "appProtocol": "ip",
      "applicationGroup": "other",
      "applicationType": "DEFAULT",
      "categoryId": "6daa3dfd-3526-407a-88f0-97824b3f3a24",
      "createTime": 1517966743409,
      "encrypted": "unassigned",
      "engineId": "1",
      "helpString": "Mobile Internetworking Control Pro.",
      "ignoreConflict": false,
      "lastUpdateTime": 1517966743409,
      "longDescription": "The Mobile Internetworking Control Protocol is used by the MSSs (Mobile Support Stations) to exchange control information, and by the MHs (mobile hosts) to signal to their MSSs that they have changed cells.",
      "name": "micp",
      "nbarId": "849",
      "p2pTechnology": "unassigned",
      "popularity": 2,
      "rank": 65535,
      "references": "http://citeseerx.ist.psu.edu/viewdoc/summary?doi=10.1.1.30.8911",
      "selectorId": "95",
      "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
      "trafficClass": "BULK_DATA",
      "tunnel": "unassigned"
    }
  ],
  "networkIdentity": [
    {
      "id": "70a04f99-38fd-4d2b-9ed7-e986985c32f7",
      "instanceId": 13898,
      "authEntityId": 13898,
      "displayName": "13898",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipProtocolNumbers": "95",
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "protocol": "MICP",
      "upperPort": 0
    }
  ],
  "parentScalableGroup": {
    "idRef": "000c15bf-28fe-4fbb-9b69-35e6502721f1"
  },
  "user": [

  ],
  "userGroup": [

  ]
},
{
  "id": "75f83d65-170e-41cb-a3e9-42bf9ebdfff7",
  "instanceId": 15126,
  "authEntityId": 15126,
  "displayName": "15126",
  "authEntityClass": -1909073334,
  "deployPending": "NONE",
  "instanceVersion": 0,
  "createTime": 1517966744293,
  "deployed": false,
  "isSeeded": true,
  "isStale": false,
  "lastUpdateTime": 1517966744293,
  "name": "sflow",
  "namespace": "scalablegroup:application",
  "provisioningState": "DEFINED",
  "qualifier": "application",
  "resourceVersion": 0,
  "targetIdList": [

  ],
  "type": "scalablegroup",
  "cfsChangeInfo": [

  ],
  "customProvisions": [

  ],
  "scalableGroupExternalHandle": "sflow",
  "scalableGroupType": "APPLICATION",
  "securityGroupTag": 0,
  "state": "ACTIVE",
  "vnAgnostic": true,
  "identitySource": {
    "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
    "instanceId": 10010,
    "authEntityId": 10010,
    "displayName": "10010",
    "authEntityClass": 1738953278,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "state": "INACTIVE",
    "type": "NBAR"
  },
  "indicativeNetworkIdentity": [
    {
      "id": "859cfa1c-a201-4c22-a508-9a275c188345",
      "instanceId": 17024,
      "authEntityId": 17024,
      "displayName": "17024",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "6343",
      "protocol": "TCP",
      "upperPort": 0
    },
    {
      "id": "975da1b0-54cd-4231-97aa-3175d94ed431",
      "instanceId": 17025,
      "authEntityId": 17025,
      "displayName": "17025",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "6343",
      "protocol": "UDP",
      "upperPort": 0
    }
  ],
  "networkApplications": [
    {
      "id": "8287f328-a5ad-4185-ac4f-1d2cff266133",
      "instanceId": 16069,
      "authEntityId": 16069,
      "displayName": "16069",
      "authEntityClass": -217092956,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "appProtocol": "tcp/udp",
      "applicationGroup": "other",
      "applicationType": "DEFAULT",
      "categoryId": "1fc17362-9334-4c7b-b752-5afdbb574017",
      "createTime": 1517966744293,
      "encrypted": "false",
      "engineId": "3",
      "helpString": "Sflow traffic monitoring",
      "ignoreConflict": false,
      "lastUpdateTime": 1517966744293,
      "longDescription": "sFlow is a technology for monitoring network, wireless, and host devices.",
      "name": "sflow",
      "nbarId": "1364",
      "p2pTechnology": "false",
      "popularity": 1,
      "rank": 65535,
      "references": "http://en.wikipedia.org/wiki/SFlow",
      "selectorId": "6343",
      "subCategory": "68c4f989-5735-4e16-8d1c-6a9d89d5df9c",
      "trafficClass": "SIGNALING",
      "tunnel": "false"
    }
  ],
  "networkIdentity": [
    {
      "id": "eaca89a1-4b9a-4231-831d-58880b085379",
      "instanceId": 17022,
      "authEntityId": 17022,
      "displayName": "17022",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "6343",
      "protocol": "UDP",
      "upperPort": 0
    },
    {
      "id": "3ff031eb-0a3f-4214-a14c-0c88c00072a9",
      "instanceId": 17023,
      "authEntityId": 17023,
      "displayName": "17023",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "6343",
      "protocol": "TCP",
      "upperPort": 0
    }
  ],
  "parentScalableGroup": {
    "idRef": "a3243a74-3864-4041-a927-0201030da17c"
  },
  "user": [

  ],
  "userGroup": [

  ]
},
{
  "id": "762878e3-0e8d-41e8-9f6c-1045d0a52fed",
  "instanceId": 15111,
  "authEntityId": 15111,
  "displayName": "15111",
  "authEntityClass": -1909073334,
  "deployPending": "NONE",
  "instanceVersion": 0,
  "createTime": 1517966744259,
  "deployed": false,
  "isSeeded": true,
  "isStale": false,
  "lastUpdateTime": 1517966744259,
  "name": "secure-http",
  "namespace": "scalablegroup:application",
  "provisioningState": "DEFINED",
  "qualifier": "application",
  "resourceVersion": 0,
  "targetIdList": [

  ],
  "type": "scalablegroup",
  "cfsChangeInfo": [

  ],
  "customProvisions": [

  ],
  "scalableGroupExternalHandle": "secure-http",
  "scalableGroupType": "APPLICATION",
  "securityGroupTag": 0,
  "state": "ACTIVE",
  "vnAgnostic": true,
  "identitySource": {
    "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
    "instanceId": 10010,
    "authEntityId": 10010,
    "displayName": "10010",
    "authEntityClass": 1738953278,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "state": "INACTIVE",
    "type": "NBAR"
  },
  "indicativeNetworkIdentity": [

  ],
  "networkApplications": [
    {
      "id": "cd215b01-9dcf-4294-b5a8-9fcbd55cb1a3",
      "instanceId": 16054,
      "authEntityId": 16054,
      "displayName": "16054",
      "authEntityClass": -217092956,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "applicationGroup": "other",
      "applicationType": "DEFAULT",
      "categoryId": "878644b5-fe91-45b4-9204-31c84a73ba48",
      "createTime": 1517966744259,
      "encrypted": "true",
      "engineId": "3",
      "helpString": "DEPRECATED, traffic will not match",
      "ignoreConflict": false,
      "lastUpdateTime": 1517966744259,
      "longDescription": "DEPRECATED: Secure Hypertext Transfer Protocol(S-HTTP) is a little-used alternative to the HTTPS URI scheme for encrypting web communications carried over HTTP.",
      "name": "secure-http",
      "nbarId": "16",
      "p2pTechnology": "false",
      "popularity": 0,
      "rank": 65535,
      "references": "http://tools.ietf.org/html/rfc2660",
      "selectorId": "443",
      "subCategory": "b90786f4-0190-45b8-9924-21b3e9682590",
      "trafficClass": "TRANSACTIONAL_DATA",
      "tunnel": "false"
    }
  ],
  "networkIdentity": [

  ],
  "parentScalableGroup": {
    "idRef": "000c15bf-28fe-4fbb-9b69-35e6502721f1"
  },
  "user": [

  ],
  "userGroup": [

  ]
},
{
  "id": "76324c20-dc57-4c75-9f14-fc5bfd46855e",
  "instanceId": 15269,
  "authEntityId": 15269,
  "displayName": "15269",
  "authEntityClass": -1909073334,
  "deployPending": "NONE",
  "instanceVersion": 0,
  "createTime": 1517966744623,
  "deployed": false,
  "isSeeded": true,
  "isStale": false,
  "lastUpdateTime": 1517966744623,
  "name": "telnet",
  "namespace": "scalablegroup:application",
  "provisioningState": "DEFINED",
  "qualifier": "application",
  "resourceVersion": 0,
  "targetIdList": [

  ],
  "type": "scalablegroup",
  "cfsChangeInfo": [

  ],
  "customProvisions": [

  ],
  "scalableGroupExternalHandle": "telnet",
  "scalableGroupType": "APPLICATION",
  "securityGroupTag": 0,
  "state": "ACTIVE",
  "vnAgnostic": true,
  "identitySource": {
    "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
    "instanceId": 10010,
    "authEntityId": 10010,
    "displayName": "10010",
    "authEntityClass": 1738953278,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "state": "INACTIVE",
    "type": "NBAR"
  },
  "indicativeNetworkIdentity": [
    {
      "id": "b31e29f9-cc75-4cd7-b070-9a401de46c7e",
      "instanceId": 17438,
      "authEntityId": 17438,
      "displayName": "17438",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "23",
      "protocol": "TCP",
      "upperPort": 0
    }
  ],
  "networkApplications": [
    {
      "id": "291f36e6-e28d-4a86-91fd-3b13fc7fd34e",
      "instanceId": 16212,
      "authEntityId": 16212,
      "displayName": "16212",
      "authEntityClass": -217092956,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "appProtocol": "tcp",
      "applicationGroup": "other",
      "applicationType": "DEFAULT",
      "categoryId": "1fc17362-9334-4c7b-b752-5afdbb574017",
      "createTime": 1517966744623,
      "encrypted": "false",
      "engineId": "3",
      "helpString": "Telnet - virtual text-oriented terminal over network",
      "ignoreConflict": false,
      "lastUpdateTime": 1517966744623,
      "longDescription": "Telnet is a cross-platform interactive text-based protocol used to connect remote clients over a TCP/IP network. The telnet client connects to a host and becomes a Network Virtual Terminal (NVT) allowing the user to communicate remotely with the host. Typically, the protocol uses TCP port 23.",
      "name": "telnet",
      "nbarId": "42",
      "p2pTechnology": "false",
      "popularity": 9,
      "rank": 65535,
      "references": "http://www.faqs.org/rfcs/rfc854.html",
      "selectorId": "23",
      "subCategory": "787a7d77-977b-4974-89d3-379ba49e88b7",
      "trafficClass": "OPS_ADMIN_MGMT",
      "tunnel": "false"
    }
  ],
  "networkIdentity": [
    {
      "id": "ecda3130-f64a-4208-b684-8577efb99441",
      "instanceId": 17437,
      "authEntityId": 17437,
      "displayName": "17437",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "23",
      "protocol": "TCP",
      "upperPort": 0
    }
  ],
  "parentScalableGroup": {
    "idRef": "e06dd89f-0a70-4422-ab0b-c2314650a9a7"
  },
  "user": [

  ],
  "userGroup": [

  ]
},
{
  "id": "76610f51-c361-4d9a-804f-3c2f81098d22",
  "instanceId": 15116,
  "authEntityId": 15116,
  "displayName": "15116",
  "authEntityClass": -1909073334,
  "deployPending": "NONE",
  "instanceVersion": 0,
  "createTime": 1517966744270,
  "deployed": false,
  "isSeeded": true,
  "isStale": false,
  "lastUpdateTime": 1517966744270,
  "name": "secure-pop3",
  "namespace": "scalablegroup:application",
  "provisioningState": "DEFINED",
  "qualifier": "application",
  "resourceVersion": 0,
  "targetIdList": [

  ],
  "type": "scalablegroup",
  "cfsChangeInfo": [

  ],
  "customProvisions": [

  ],
  "scalableGroupExternalHandle": "secure-pop3",
  "scalableGroupType": "APPLICATION",
  "securityGroupTag": 0,
  "state": "ACTIVE",
  "vnAgnostic": true,
  "identitySource": {
    "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
    "instanceId": 10010,
    "authEntityId": 10010,
    "displayName": "10010",
    "authEntityClass": 1738953278,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "state": "INACTIVE",
    "type": "NBAR"
  },
  "indicativeNetworkIdentity": [
    {
      "id": "96c51753-6c5a-4248-847d-1ed6263c6122",
      "instanceId": 14986,
      "authEntityId": 14986,
      "displayName": "14986",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "995",
      "protocol": "TCP",
      "upperPort": 0
    },
    {
      "id": "acff8458-1200-40c0-a50c-c381f860e62c",
      "instanceId": 14987,
      "authEntityId": 14987,
      "displayName": "14987",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "995",
      "protocol": "UDP",
      "upperPort": 0
    }
  ],
  "networkApplications": [
    {
      "id": "01631dd6-de6c-4d00-b934-54f922db7be9",
      "instanceId": 16059,
      "authEntityId": 16059,
      "displayName": "16059",
      "authEntityClass": -217092956,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "appProtocol": "tcp/udp",
      "applicationGroup": "pop3-group",
      "applicationType": "DEFAULT",
      "categoryId": "27953c60-b2ff-4e1a-bc52-0187d8295250",
      "createTime": 1517966744270,
      "encrypted": "true",
      "engineId": "3",
      "helpString": "Secure POP3 (Post Office Protocol), standard for e-mail retrieval over SSL",
      "ignoreConflict": false,
      "lastUpdateTime": 1517966744270,
      "longDescription": "Secure Post Office Protocol 3 is an application-layer Internet standard over TLS/SSL protocol used by local e-mail clients to securely retrieve e-mail from a remote server over a TCP/IP connection.",
      "name": "secure-pop3",
      "nbarId": "34",
      "p2pTechnology": "false",
      "popularity": 7,
      "rank": 65535,
      "references": "http://tools.ietf.org/html/rfc2595",
      "selectorId": "995",
      "subCategory": "b90786f4-0190-45b8-9924-21b3e9682590",
      "trafficClass": "BULK_DATA",
      "tunnel": "false"
    }
  ],
  "networkIdentity": [
    {
      "id": "d1134faf-02c4-45d8-8539-addf0e87e0f6",
      "instanceId": 14984,
      "authEntityId": 14984,
      "displayName": "14984",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "995",
      "protocol": "UDP",
      "upperPort": 0
    },
    {
      "id": "22550086-f922-47d5-9ceb-e74db2d5be8a",
      "instanceId": 14985,
      "authEntityId": 14985,
      "displayName": "14985",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "995",
      "protocol": "TCP",
      "upperPort": 0
    }
  ],
  "parentScalableGroup": {
    "idRef": "8a7ca43a-32bd-40d2-871a-02220a165cb7"
  },
  "user": [

  ],
  "userGroup": [

  ]
},
{
  "id": "76976449-b220-4f01-8db9-312a571d7a27",
  "instanceId": 4894,
  "authEntityId": 4894,
  "displayName": "4894",
  "authEntityClass": -1909073334,
  "deployPending": "NONE",
  "instanceVersion": 0,
  "createTime": 1517966743700,
  "deployed": false,
  "isSeeded": true,
  "isStale": false,
  "lastUpdateTime": 1517966743700,
  "name": "obex",
  "namespace": "scalablegroup:application",
  "provisioningState": "DEFINED",
  "qualifier": "application",
  "resourceVersion": 0,
  "targetIdList": [

  ],
  "type": "scalablegroup",
  "cfsChangeInfo": [

  ],
  "customProvisions": [

  ],
  "scalableGroupExternalHandle": "obex",
  "scalableGroupType": "APPLICATION",
  "securityGroupTag": 0,
  "state": "ACTIVE",
  "vnAgnostic": true,
  "identitySource": {
    "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
    "instanceId": 10010,
    "authEntityId": 10010,
    "displayName": "10010",
    "authEntityClass": 1738953278,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "state": "INACTIVE",
    "type": "NBAR"
  },
  "indicativeNetworkIdentity": [
    {
      "id": "459b196b-f36a-4730-80b4-fa6b0b2196d9",
      "instanceId": 14333,
      "authEntityId": 14333,
      "displayName": "14333",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "650",
      "protocol": "TCP",
      "upperPort": 0
    },
    {
      "id": "e7b124f8-8b5a-4b96-80c7-e96db008567f",
      "instanceId": 14334,
      "authEntityId": 14334,
      "displayName": "14334",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "650",
      "protocol": "UDP",
      "upperPort": 0
    }
  ],
  "networkApplications": [
    {
      "id": "42002590-34bb-4e92-8fc3-1c4f7b9dfc25",
      "instanceId": 11843,
      "authEntityId": 11843,
      "displayName": "11843",
      "authEntityClass": -217092956,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "appProtocol": "tcp/udp",
      "applicationGroup": "other",
      "applicationType": "DEFAULT",
      "categoryId": "6daa3dfd-3526-407a-88f0-97824b3f3a24",
      "createTime": 1517966743700,
      "encrypted": "false",
      "engineId": "3",
      "helpString": "obex",
      "ignoreConflict": false,
      "lastUpdateTime": 1517966743700,
      "longDescription": "OBject Exchange (OBEX) is a communications protocol that facilitates the exchange of binary objects between devices.",
      "name": "obex",
      "nbarId": "559",
      "p2pTechnology": "false",
      "popularity": 1,
      "rank": 65535,
      "references": "http://www.irda.org/",
      "selectorId": "650",
      "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
      "trafficClass": "BULK_DATA",
      "tunnel": "false"
    }
  ],
  "networkIdentity": [
    {
      "id": "64faa6ec-a14f-4abc-a97e-982d0e271692",
      "instanceId": 14331,
      "authEntityId": 14331,
      "displayName": "14331",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "650",
      "protocol": "UDP",
      "upperPort": 0
    },
    {
      "id": "45faff3c-0f68-4324-8c85-a1632f1cd177",
      "instanceId": 14332,
      "authEntityId": 14332,
      "displayName": "14332",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "650",
      "protocol": "TCP",
      "upperPort": 0
    }
  ],
  "parentScalableGroup": {
    "idRef": "000c15bf-28fe-4fbb-9b69-35e6502721f1"
  },
  "user": [

  ],
  "userGroup": [

  ]
},
{
  "id": "769e4668-9f1d-4ef1-959b-305feda9a6d7",
  "instanceId": 4679,
  "authEntityId": 4679,
  "displayName": "4679",
  "authEntityClass": -1909073334,
  "deployPending": "NONE",
  "instanceVersion": 0,
  "createTime": 1517966743267,
  "deployed": false,
  "isSeeded": true,
  "isStale": false,
  "lastUpdateTime": 1517966743267,
  "name": "knet-cmp",
  "namespace": "scalablegroup:application",
  "provisioningState": "DEFINED",
  "qualifier": "application",
  "resourceVersion": 0,
  "targetIdList": [

  ],
  "type": "scalablegroup",
  "cfsChangeInfo": [

  ],
  "customProvisions": [

  ],
  "scalableGroupExternalHandle": "knet-cmp",
  "scalableGroupType": "APPLICATION",
  "securityGroupTag": 0,
  "state": "ACTIVE",
  "vnAgnostic": true,
  "identitySource": {
    "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
    "instanceId": 10010,
    "authEntityId": 10010,
    "displayName": "10010",
    "authEntityClass": 1738953278,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "state": "INACTIVE",
    "type": "NBAR"
  },
  "indicativeNetworkIdentity": [
    {
      "id": "2d0b5b28-2e19-4882-8392-b46b7a97be7d",
      "instanceId": 13710,
      "authEntityId": 13710,
      "displayName": "13710",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "157",
      "protocol": "TCP",
      "upperPort": 0
    },
    {
      "id": "b3968af9-f508-47ef-8713-3d05418894b4",
      "instanceId": 13711,
      "authEntityId": 13711,
      "displayName": "13711",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "157",
      "protocol": "UDP",
      "upperPort": 0
    }
  ],
  "networkApplications": [
    {
      "id": "1f4732ac-82f0-4407-a40b-530c2db2f86d",
      "instanceId": 11629,
      "authEntityId": 11629,
      "displayName": "11629",
      "authEntityClass": -217092956,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "appProtocol": "tcp/udp",
      "applicationGroup": "other",
      "applicationType": "DEFAULT",
      "categoryId": "6daa3dfd-3526-407a-88f0-97824b3f3a24",
      "createTime": 1517966743267,
      "encrypted": "false",
      "engineId": "3",
      "helpString": "KNET/VM Command/Message Protocol",
      "ignoreConflict": false,
      "lastUpdateTime": 1517966743267,
      "longDescription": "Registered with IANA on port 157 TCP/UDP",
      "name": "knet-cmp",
      "nbarId": "1003",
      "p2pTechnology": "false",
      "popularity": 1,
      "rank": 65535,
      "references": "http://www.iana.org/assignments/service-names-port-numbers/service-names-port-numbers.xml",
      "selectorId": "157",
      "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
      "trafficClass": "BULK_DATA",
      "tunnel": "false"
    }
  ],
  "networkIdentity": [
    {
      "id": "93fc2e90-e864-4014-ab2a-7e3ec13bf47d",
      "instanceId": 13708,
      "authEntityId": 13708,
      "displayName": "13708",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "157",
      "protocol": "TCP",
      "upperPort": 0
    },
    {
      "id": "6a09cad0-924e-41af-a718-3c6f9a58a536",
      "instanceId": 13709,
      "authEntityId": 13709,
      "displayName": "13709",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "157",
      "protocol": "UDP",
      "upperPort": 0
    }
  ],
  "parentScalableGroup": {
    "idRef": "000c15bf-28fe-4fbb-9b69-35e6502721f1"
  },
  "user": [

  ],
  "userGroup": [

  ]
},
{
  "id": "76e4a0bf-0311-43c8-ac3e-35a48c722354",
  "instanceId": 4255,
  "authEntityId": 4255,
  "displayName": "4255",
  "authEntityClass": -1909073334,
  "deployPending": "NONE",
  "instanceVersion": 0,
  "createTime": 1517966741955,
  "deployed": false,
  "isSeeded": true,
  "isStale": false,
  "lastUpdateTime": 1517966741955,
  "name": "cisco-spark-video",
  "namespace": "scalablegroup:application",
  "provisioningState": "DEFINED",
  "qualifier": "application",
  "resourceVersion": 0,
  "targetIdList": [

  ],
  "type": "scalablegroup",
  "cfsChangeInfo": [

  ],
  "customProvisions": [

  ],
  "scalableGroupExternalHandle": "cisco-spark-video",
  "scalableGroupType": "APPLICATION",
  "securityGroupTag": 0,
  "state": "ACTIVE",
  "vnAgnostic": true,
  "identitySource": {
    "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
    "instanceId": 10010,
    "authEntityId": 10010,
    "displayName": "10010",
    "authEntityClass": 1738953278,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "state": "INACTIVE",
    "type": "NBAR"
  },
  "indicativeNetworkIdentity": [

  ],
  "networkApplications": [
    {
      "id": "129567b3-c05c-4150-898e-2c57ac1280d8",
      "instanceId": 11209,
      "authEntityId": 11209,
      "displayName": "11209",
      "authEntityClass": -217092956,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "applicationGroup": "other",
      "applicationType": "DEFAULT",
      "categoryId": "38a8e354-e2fe-4f3c-b1c5-2bbb90fb0d99",
      "createTime": 1517966741955,
      "encrypted": "false",
      "engineId": "13",
      "helpString": "Cisco Spark Video - unified communications client video",
      "ignoreConflict": false,
      "lastUpdateTime": 1517966741955,
      "longDescription": "Cisco Spark Video - Video of unified communications client and SaaS with mobile team communication: group chat, private chat, video calls with screen sharing and file sharing.",
      "name": "cisco-spark-video",
      "nbarId": "1048",
      "p2pTechnology": "false",
      "popularity": 10,
      "rank": 65535,
      "references": "http://ciscospark.com",
      "selectorId": "1048",
      "subCategory": "53ce0342-1e2d-455a-acc7-1b50db9d6845",
      "trafficClass": "MULTIMEDIA_CONFERENCING",
      "tunnel": "false"
    }
  ],
  "networkIdentity": [

  ],
  "parentScalableGroup": {
    "idRef": "60f1364d-2a02-4063-8ba1-07817abe0612"
  },
  "user": [

  ],
  "userGroup": [

  ]
},
{
  "id": "77178d7b-5354-4502-ae47-529d6df0311c",
  "instanceId": 4892,
  "authEntityId": 4892,
  "displayName": "4892",
  "authEntityClass": -1909073334,
  "deployPending": "NONE",
  "instanceVersion": 0,
  "createTime": 1517966743696,
  "deployed": false,
  "isSeeded": true,
  "isStale": false,
  "lastUpdateTime": 1517966743696,
  "name": "ny-daily-news",
  "namespace": "scalablegroup:application",
  "provisioningState": "DEFINED",
  "qualifier": "application",
  "resourceVersion": 0,
  "targetIdList": [

  ],
  "type": "scalablegroup",
  "cfsChangeInfo": [

  ],
  "customProvisions": [

  ],
  "scalableGroupExternalHandle": "ny-daily-news",
  "scalableGroupType": "APPLICATION",
  "securityGroupTag": 0,
  "state": "ACTIVE",
  "vnAgnostic": true,
  "identitySource": {
    "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
    "instanceId": 10010,
    "authEntityId": 10010,
    "displayName": "10010",
    "authEntityClass": 1738953278,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "state": "INACTIVE",
    "type": "NBAR"
  },
  "indicativeNetworkIdentity": [

  ],
  "networkApplications": [
    {
      "id": "a44c1038-a750-4a9e-8387-84ed82e90519",
      "instanceId": 11841,
      "authEntityId": 11841,
      "displayName": "11841",
      "authEntityClass": -217092956,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "appProtocol": "tcp/udp",
      "applicationGroup": "other",
      "applicationType": "DEFAULT",
      "categoryId": "878644b5-fe91-45b4-9204-31c84a73ba48",
      "createTime": 1517966743696,
      "encrypted": "false",
      "engineId": "13",
      "helpString": "NY Daily News - News portal.",
      "ignoreConflict": false,
      "lastUpdateTime": 1517966743696,
      "longDescription": "NY Daily News - News portal.",
      "name": "ny-daily-news",
      "nbarId": "1661",
      "p2pTechnology": "false",
      "popularity": 3,
      "rank": 65535,
      "references": "http://nydailynews.com",
      "selectorId": "731",
      "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
      "trafficClass": "TRANSACTIONAL_DATA",
      "tunnel": "false"
    }
  ],
  "networkIdentity": [
    {
      "id": "32095c1b-d9d4-43b1-b953-5a7c60a0afa8",
      "instanceId": 14327,
      "authEntityId": 14327,
      "displayName": "14327",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "53,80,443,5353",
      "protocol": "TCP",
      "upperPort": 0
    },
    {
      "id": "40c596f4-2340-4024-811d-00d2d0844698",
      "instanceId": 14328,
      "authEntityId": 14328,
      "displayName": "14328",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "53,5353",
      "protocol": "UDP",
      "upperPort": 0
    }
  ],
  "parentScalableGroup": {
    "idRef": "28e08de4-d7b2-4521-84ce-bc1e5ee0b9cc"
  },
  "user": [

  ],
  "userGroup": [

  ]
},
{
  "id": "772812e0-0652-4995-89b6-0fdd65c94a76",
  "instanceId": 15299,
  "authEntityId": 15299,
  "displayName": "15299",
  "authEntityClass": -1909073334,
  "deployPending": "NONE",
  "instanceVersion": 0,
  "createTime": 1517966744693,
  "deployed": false,
  "isSeeded": true,
  "isStale": false,
  "lastUpdateTime": 1517966744693,
  "name": "torrentz",
  "namespace": "scalablegroup:application",
  "provisioningState": "DEFINED",
  "qualifier": "application",
  "resourceVersion": 0,
  "targetIdList": [

  ],
  "type": "scalablegroup",
  "cfsChangeInfo": [

  ],
  "customProvisions": [

  ],
  "scalableGroupExternalHandle": "torrentz",
  "scalableGroupType": "APPLICATION",
  "securityGroupTag": 0,
  "state": "ACTIVE",
  "vnAgnostic": true,
  "identitySource": {
    "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
    "instanceId": 10010,
    "authEntityId": 10010,
    "displayName": "10010",
    "authEntityClass": 1738953278,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "state": "INACTIVE",
    "type": "NBAR"
  },
  "indicativeNetworkIdentity": [

  ],
  "networkApplications": [
    {
      "id": "60351aef-4916-4604-9055-5923b2909dce",
      "instanceId": 16242,
      "authEntityId": 16242,
      "displayName": "16242",
      "authEntityClass": -217092956,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "appProtocol": "tcp/udp",
      "applicationGroup": "other",
      "applicationType": "DEFAULT",
      "categoryId": "878644b5-fe91-45b4-9204-31c84a73ba48",
      "createTime": 1517966744693,
      "encrypted": "false",
      "engineId": "13",
      "helpString": "Torrentz - BitTorrent metasearch engine.",
      "ignoreConflict": false,
      "lastUpdateTime": 1517966744693,
      "longDescription": "Torrentz - BitTorrent metasearch engine.",
      "name": "torrentz",
      "nbarId": "1586",
      "p2pTechnology": "false",
      "popularity": 3,
      "rank": 65535,
      "references": "http://torrentz.eu",
      "selectorId": "657",
      "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
      "trafficClass": "TRANSACTIONAL_DATA",
      "tunnel": "false"
    }
  ],
  "networkIdentity": [
    {
      "id": "1686af4d-23a8-4362-99c5-7056821ba11d",
      "instanceId": 17522,
      "authEntityId": 17522,
      "displayName": "17522",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "53,80,443,5353",
      "protocol": "TCP",
      "upperPort": 0
    },
    {
      "id": "0edc35b8-f3f5-4c22-a32b-b2f4404d16ff",
      "instanceId": 17523,
      "authEntityId": 17523,
      "displayName": "17523",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "53,5353",
      "protocol": "UDP",
      "upperPort": 0
    }
  ],
  "parentScalableGroup": {
    "idRef": "28e08de4-d7b2-4521-84ce-bc1e5ee0b9cc"
  },
  "user": [

  ],
  "userGroup": [

  ]
},
{
  "id": "7732fd33-4f4b-4691-b64e-67b0111466c8",
  "instanceId": 15190,
  "authEntityId": 15190,
  "displayName": "15190",
  "authEntityClass": -1909073334,
  "deployPending": "NONE",
  "instanceVersion": 0,
  "createTime": 1517966744440,
  "deployed": false,
  "isSeeded": true,
  "isStale": false,
  "lastUpdateTime": 1517966744440,
  "name": "spmp",
  "namespace": "scalablegroup:application",
  "provisioningState": "DEFINED",
  "qualifier": "application",
  "resourceVersion": 0,
  "targetIdList": [

  ],
  "type": "scalablegroup",
  "cfsChangeInfo": [

  ],
  "customProvisions": [

  ],
  "scalableGroupExternalHandle": "spmp",
  "scalableGroupType": "APPLICATION",
  "securityGroupTag": 0,
  "state": "ACTIVE",
  "vnAgnostic": true,
  "identitySource": {
    "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
    "instanceId": 10010,
    "authEntityId": 10010,
    "displayName": "10010",
    "authEntityClass": 1738953278,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "state": "INACTIVE",
    "type": "NBAR"
  },
  "indicativeNetworkIdentity": [
    {
      "id": "e059756f-5986-4551-af1b-ebf9e630e09b",
      "instanceId": 17208,
      "authEntityId": 17208,
      "displayName": "17208",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "656",
      "protocol": "TCP",
      "upperPort": 0
    },
    {
      "id": "256caf8e-09ff-4cbb-99d7-9a87eef94aae",
      "instanceId": 17209,
      "authEntityId": 17209,
      "displayName": "17209",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "656",
      "protocol": "UDP",
      "upperPort": 0
    }
  ],
  "networkApplications": [
    {
      "id": "e44aea5e-db7d-45a3-98a2-8511f431f175",
      "instanceId": 16133,
      "authEntityId": 16133,
      "displayName": "16133",
      "authEntityClass": -217092956,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "appProtocol": "tcp/udp",
      "applicationGroup": "other",
      "applicationType": "DEFAULT",
      "categoryId": "6daa3dfd-3526-407a-88f0-97824b3f3a24",
      "createTime": 1517966744440,
      "encrypted": "false",
      "engineId": "3",
      "helpString": "spmp",
      "ignoreConflict": false,
      "lastUpdateTime": 1517966744440,
      "longDescription": "Registered with IANA on port 656 TCP/UDP",
      "name": "spmp",
      "nbarId": "565",
      "p2pTechnology": "false",
      "popularity": 1,
      "rank": 65535,
      "references": "http://www.iana.org/assignments/service-names-port-numbers/service-names-port-numbers.xml",
      "selectorId": "656",
      "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
      "trafficClass": "BULK_DATA",
      "tunnel": "false"
    }
  ],
  "networkIdentity": [
    {
      "id": "f2cfc145-589b-4273-be71-016eb13f2d0c",
      "instanceId": 17206,
      "authEntityId": 17206,
      "displayName": "17206",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "656",
      "protocol": "UDP",
      "upperPort": 0
    },
    {
      "id": "b259981c-503f-42df-8642-07517f0d612f",
      "instanceId": 17207,
      "authEntityId": 17207,
      "displayName": "17207",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "656",
      "protocol": "TCP",
      "upperPort": 0
    }
  ],
  "parentScalableGroup": {
    "idRef": "000c15bf-28fe-4fbb-9b69-35e6502721f1"
  },
  "user": [

  ],
  "userGroup": [

  ]
},
{
  "id": "7736b527-e7bd-4fee-8f1d-f80f3e535d0c",
  "instanceId": 4866,
  "authEntityId": 4866,
  "displayName": "4866",
  "authEntityClass": -1909073334,
  "deployPending": "NONE",
  "instanceVersion": 0,
  "createTime": 1517966743643,
  "deployed": false,
  "isSeeded": true,
  "isStale": false,
  "lastUpdateTime": 1517966743643,
  "name": "nico-video",
  "namespace": "scalablegroup:application",
  "provisioningState": "DEFINED",
  "qualifier": "application",
  "resourceVersion": 0,
  "targetIdList": [

  ],
  "type": "scalablegroup",
  "cfsChangeInfo": [

  ],
  "customProvisions": [

  ],
  "scalableGroupExternalHandle": "nico-video",
  "scalableGroupType": "APPLICATION",
  "securityGroupTag": 0,
  "state": "ACTIVE",
  "vnAgnostic": true,
  "identitySource": {
    "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
    "instanceId": 10010,
    "authEntityId": 10010,
    "displayName": "10010",
    "authEntityClass": 1738953278,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "state": "INACTIVE",
    "type": "NBAR"
  },
  "indicativeNetworkIdentity": [

  ],
  "networkApplications": [
    {
      "id": "45c8bc8d-d068-424e-8995-61184c2db873",
      "instanceId": 11815,
      "authEntityId": 11815,
      "displayName": "11815",
      "authEntityClass": -217092956,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "appProtocol": "tcp/udp",
      "applicationGroup": "flash-group",
      "applicationType": "DEFAULT",
      "categoryId": "82a7b7fa-da5a-48f5-8b6a-a2546b947843",
      "createTime": 1517966743643,
      "encrypted": "true",
      "engineId": "13",
      "helpString": "Video sharing website in Japan",
      "ignoreConflict": false,
      "lastUpdateTime": 1517966743643,
      "longDescription": "Nico-video (also known as nico-nico) is a video sharing website in Japan managed by Niwango, a subsidiary of Dwango. Users can upload, view and share video clips. Unlike other video sharing sites, comments are overlaid directly onto the video, synced to a specific playback time.",
      "name": "nico-video",
      "nbarId": "1535",
      "p2pTechnology": "false",
      "popularity": 8,
      "rank": 65535,
      "references": "http://www.nicovideo.jp/",
      "selectorId": "596",
      "subCategory": "85a797c0-aef8-4284-91a7-677f33e29abb",
      "trafficClass": "MULTIMEDIA_STREAMING",
      "tunnel": "false"
    }
  ],
  "networkIdentity": [
    {
      "id": "18741876-fa3a-462c-a101-2240bbe40057",
      "instanceId": 14240,
      "authEntityId": 14240,
      "displayName": "14240",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "53,5353",
      "protocol": "UDP",
      "upperPort": 0
    },
    {
      "id": "e7507feb-f5f2-41c5-b469-22e296353064",
      "instanceId": 14241,
      "authEntityId": 14241,
      "displayName": "14241",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "53,80,443,5353",
      "protocol": "TCP",
      "upperPort": 0
    }
  ],
  "parentScalableGroup": {
    "idRef": "fd6264c9-c16a-4311-a335-a3db039256f3"
  },
  "user": [

  ],
  "userGroup": [

  ]
},
{
  "id": "774acf23-39b2-4884-b927-2d743e404916",
  "instanceId": 4580,
  "authEntityId": 4580,
  "displayName": "4580",
  "authEntityClass": -1909073334,
  "deployPending": "NONE",
  "instanceVersion": 0,
  "createTime": 1517966743043,
  "deployed": false,
  "isSeeded": true,
  "isStale": false,
  "lastUpdateTime": 1517966743043,
  "name": "iclcnet_svinfo",
  "namespace": "scalablegroup:application",
  "provisioningState": "DEFINED",
  "qualifier": "application",
  "resourceVersion": 0,
  "targetIdList": [

  ],
  "type": "scalablegroup",
  "cfsChangeInfo": [

  ],
  "customProvisions": [

  ],
  "scalableGroupExternalHandle": "iclcnet_svinfo",
  "scalableGroupType": "APPLICATION",
  "securityGroupTag": 0,
  "state": "ACTIVE",
  "vnAgnostic": true,
  "identitySource": {
    "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
    "instanceId": 10010,
    "authEntityId": 10010,
    "displayName": "10010",
    "authEntityClass": 1738953278,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "state": "INACTIVE",
    "type": "NBAR"
  },
  "indicativeNetworkIdentity": [
    {
      "id": "f735cf19-bb4e-40e8-a9f7-a15a54c69e11",
      "instanceId": 13461,
      "authEntityId": 13461,
      "displayName": "13461",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "887",
      "protocol": "UDP",
      "upperPort": 0
    },
    {
      "id": "f9a9acda-611a-4898-97dd-af2a45087d49",
      "instanceId": 13462,
      "authEntityId": 13462,
      "displayName": "13462",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "887",
      "protocol": "TCP",
      "upperPort": 0
    }
  ],
  "networkApplications": [
    {
      "id": "001a5570-7656-4e20-9771-8476fbfa0b32",
      "instanceId": 11530,
      "authEntityId": 11530,
      "displayName": "11530",
      "authEntityClass": -217092956,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "appProtocol": "tcp/udp",
      "applicationGroup": "other",
      "applicationType": "DEFAULT",
      "categoryId": "6daa3dfd-3526-407a-88f0-97824b3f3a24",
      "createTime": 1517966743043,
      "encrypted": "false",
      "engineId": "3",
      "helpString": "ICL coNETion server info",
      "ignoreConflict": false,
      "lastUpdateTime": 1517966743043,
      "longDescription": "Registered with IANA on port 887 TCP/UDP",
      "name": "iclcnet_svinfo",
      "nbarId": "661",
      "p2pTechnology": "false",
      "popularity": 1,
      "rank": 65535,
      "references": "http://www.iana.org/assignments/service-names-port-numbers/service-names-port-numbers.xml",
      "selectorId": "887",
      "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
      "trafficClass": "BULK_DATA",
      "tunnel": "false"
    }
  ],
  "networkIdentity": [
    {
      "id": "92e1074a-982a-4bb4-af1b-16cddcbc261e",
      "instanceId": 13459,
      "authEntityId": 13459,
      "displayName": "13459",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "887",
      "protocol": "TCP",
      "upperPort": 0
    },
    {
      "id": "908f5fc0-f1c7-4d82-94e6-d05b2a2c31cf",
      "instanceId": 13460,
      "authEntityId": 13460,
      "displayName": "13460",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "887",
      "protocol": "UDP",
      "upperPort": 0
    }
  ],
  "parentScalableGroup": {
    "idRef": "000c15bf-28fe-4fbb-9b69-35e6502721f1"
  },
  "user": [

  ],
  "userGroup": [

  ]
},
{
  "id": "7753f1f9-6b42-4f2a-970f-5f0bd3c4aa44",
  "instanceId": 4949,
  "authEntityId": 4949,
  "displayName": "4949",
  "authEntityClass": -1909073334,
  "deployPending": "NONE",
  "instanceVersion": 0,
  "createTime": 1517966743882,
  "deployed": false,
  "isSeeded": true,
  "isStale": false,
  "lastUpdateTime": 1517966743882,
  "name": "people-web",
  "namespace": "scalablegroup:application",
  "provisioningState": "DEFINED",
  "qualifier": "application",
  "resourceVersion": 0,
  "targetIdList": [

  ],
  "type": "scalablegroup",
  "cfsChangeInfo": [

  ],
  "customProvisions": [

  ],
  "scalableGroupExternalHandle": "people-web",
  "scalableGroupType": "APPLICATION",
  "securityGroupTag": 0,
  "state": "ACTIVE",
  "vnAgnostic": true,
  "identitySource": {
    "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
    "instanceId": 10010,
    "authEntityId": 10010,
    "displayName": "10010",
    "authEntityClass": 1738953278,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "state": "INACTIVE",
    "type": "NBAR"
  },
  "indicativeNetworkIdentity": [

  ],
  "networkApplications": [
    {
      "id": "ddcc052d-c13f-4f33-8ac6-7173ebb90893",
      "instanceId": 11898,
      "authEntityId": 11898,
      "displayName": "11898",
      "authEntityClass": -217092956,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "appProtocol": "tcp/udp",
      "applicationGroup": "other",
      "applicationType": "DEFAULT",
      "categoryId": "878644b5-fe91-45b4-9204-31c84a73ba48",
      "createTime": 1517966743882,
      "encrypted": "false",
      "engineId": "13",
      "helpString": "People.com - Web portal for the Weekly magazine People.",
      "ignoreConflict": false,
      "lastUpdateTime": 1517966743882,
      "longDescription": "People.com - Web portal for the Weekly magazine People.",
      "name": "people-web",
      "nbarId": "1673",
      "p2pTechnology": "false",
      "popularity": 3,
      "rank": 65535,
      "references": "http://people.com",
      "selectorId": "743",
      "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
      "trafficClass": "TRANSACTIONAL_DATA",
      "tunnel": "false"
    }
  ],
  "networkIdentity": [
    {
      "id": "01c22f8b-a0b6-4daa-9205-25af6c4ad348",
      "instanceId": 14507,
      "authEntityId": 14507,
      "displayName": "14507",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "53,5353",
      "protocol": "UDP",
      "upperPort": 0
    },
    {
      "id": "414e1b09-657b-483f-882e-2f29f11b3108",
      "instanceId": 14508,
      "authEntityId": 14508,
      "displayName": "14508",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "53,80,443,5353",
      "protocol": "TCP",
      "upperPort": 0
    }
  ],
  "parentScalableGroup": {
    "idRef": "28e08de4-d7b2-4521-84ce-bc1e5ee0b9cc"
  },
  "user": [

  ],
  "userGroup": [

  ]
},
{
  "id": "77636627-9358-49ff-a5e9-c910e502ca4b",
  "instanceId": 4198,
  "authEntityId": 4198,
  "displayName": "4198",
  "authEntityClass": -1909073334,
  "deployPending": "NONE",
  "instanceVersion": 0,
  "createTime": 1517966741662,
  "deployed": false,
  "isSeeded": true,
  "isStale": false,
  "lastUpdateTime": 1517966741662,
  "name": "bnet",
  "namespace": "scalablegroup:application",
  "provisioningState": "DEFINED",
  "qualifier": "application",
  "resourceVersion": 0,
  "targetIdList": [

  ],
  "type": "scalablegroup",
  "cfsChangeInfo": [

  ],
  "customProvisions": [

  ],
  "scalableGroupExternalHandle": "bnet",
  "scalableGroupType": "APPLICATION",
  "securityGroupTag": 0,
  "state": "ACTIVE",
  "vnAgnostic": true,
  "identitySource": {
    "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
    "instanceId": 10010,
    "authEntityId": 10010,
    "displayName": "10010",
    "authEntityClass": 1738953278,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "state": "INACTIVE",
    "type": "NBAR"
  },
  "indicativeNetworkIdentity": [
    {
      "id": "a623f4c0-2319-491b-ba24-2710693cbc6b",
      "instanceId": 12425,
      "authEntityId": 12425,
      "displayName": "12425",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "415",
      "protocol": "UDP",
      "upperPort": 0
    },
    {
      "id": "cd73550b-db17-4430-9ce9-ab459a0c9fe1",
      "instanceId": 12426,
      "authEntityId": 12426,
      "displayName": "12426",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "415",
      "protocol": "TCP",
      "upperPort": 0
    }
  ],
  "networkApplications": [
    {
      "id": "dad02aa0-24b9-4ba0-b0a2-53ddb70daf83",
      "instanceId": 11154,
      "authEntityId": 11154,
      "displayName": "11154",
      "authEntityClass": -217092956,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "appProtocol": "tcp/udp",
      "applicationGroup": "other",
      "applicationType": "DEFAULT",
      "categoryId": "3cd50dd8-1b2e-46c1-b672-41c14bfa364b",
      "createTime": 1517966741662,
      "encrypted": "false",
      "engineId": "3",
      "helpString": "bnet",
      "ignoreConflict": false,
      "lastUpdateTime": 1517966741662,
      "longDescription": "BNet, Registered with IANA on port 415 TCP/UDP",
      "name": "bnet",
      "nbarId": "330",
      "p2pTechnology": "false",
      "popularity": 1,
      "rank": 65535,
      "references": "http://www.iana.org/assignments/service-names-port-numbers/service-names-port-numbers.xml",
      "selectorId": "415",
      "subCategory": "118c10a2-e272-47c1-92b9-4814ebe97138",
      "trafficClass": "MULTIMEDIA_CONFERENCING",
      "tunnel": "false"
    }
  ],
  "networkIdentity": [
    {
      "id": "07532209-e91a-4e15-b456-2e7f387e1de5",
      "instanceId": 12423,
      "authEntityId": 12423,
      "displayName": "12423",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "415",
      "protocol": "UDP",
      "upperPort": 0
    },
    {
      "id": "89c51078-e692-4b1f-9c7b-b3efd18b9084",
      "instanceId": 12424,
      "authEntityId": 12424,
      "displayName": "12424",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "415",
      "protocol": "TCP",
      "upperPort": 0
    }
  ],
  "parentScalableGroup": {
    "idRef": "0bd0e6c9-5083-492b-97cb-a88928bee99c"
  },
  "user": [

  ],
  "userGroup": [

  ]
},
{
  "id": "77a59627-e715-4993-8100-a04283403288",
  "instanceId": 4209,
  "authEntityId": 4209,
  "displayName": "4209",
  "authEntityClass": -1909073334,
  "deployPending": "NONE",
  "instanceVersion": 0,
  "createTime": 1517966741732,
  "deployed": false,
  "isSeeded": true,
  "isStale": false,
  "lastUpdateTime": 1517966741732,
  "name": "call-of-duty",
  "namespace": "scalablegroup:application",
  "provisioningState": "DEFINED",
  "qualifier": "application",
  "resourceVersion": 0,
  "targetIdList": [

  ],
  "type": "scalablegroup",
  "cfsChangeInfo": [

  ],
  "customProvisions": [

  ],
  "scalableGroupExternalHandle": "call-of-duty",
  "scalableGroupType": "APPLICATION",
  "securityGroupTag": 0,
  "state": "ACTIVE",
  "vnAgnostic": true,
  "identitySource": {
    "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
    "instanceId": 10010,
    "authEntityId": 10010,
    "displayName": "10010",
    "authEntityClass": 1738953278,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "state": "INACTIVE",
    "type": "NBAR"
  },
  "indicativeNetworkIdentity": [
    {
      "id": "2d643aa7-51b4-4e72-bbc1-8b026416d8c6",
      "instanceId": 12457,
      "authEntityId": 12457,
      "displayName": "12457",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "20500,20510,28960",
      "protocol": "TCP",
      "upperPort": 0
    },
    {
      "id": "31e50aad-0ea7-49a5-8c82-a38b183a4538",
      "instanceId": 12458,
      "authEntityId": 12458,
      "displayName": "12458",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "20500",
      "protocol": "UDP",
      "upperPort": 0
    }
  ],
  "networkApplications": [
    {
      "id": "1ec92037-dc00-4c74-894e-7c08b44b77b3",
      "instanceId": 11165,
      "authEntityId": 11165,
      "displayName": "11165",
      "authEntityClass": -217092956,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "appProtocol": "tcp/udp",
      "applicationGroup": "other",
      "applicationType": "DEFAULT",
      "categoryId": "3cd50dd8-1b2e-46c1-b672-41c14bfa364b",
      "createTime": 1517966741731,
      "encrypted": "false",
      "engineId": "3",
      "helpString": "Call of duty online gaming",
      "ignoreConflict": false,
      "lastUpdateTime": 1517966741731,
      "longDescription": "Call of Duty is a first-person and third-person shooter video game series franchise. The series began on the PC, and later expanded to consoles and handhelds. The Call of Duty games are published and owned by Activision. Users can play together online.",
      "name": "call-of-duty",
      "nbarId": "1377",
      "p2pTechnology": "false",
      "popularity": 7,
      "rank": 65535,
      "references": "http://www.callofduty.com/",
      "selectorId": "20500",
      "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
      "trafficClass": "BULK_DATA",
      "tunnel": "false"
    }
  ],
  "networkIdentity": [
    {
      "id": "2e076873-a030-4170-b7b6-e11968c32eab",
      "instanceId": 12455,
      "authEntityId": 12455,
      "displayName": "12455",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "20500",
      "protocol": "UDP",
      "upperPort": 0
    },
    {
      "id": "33cdd1e7-c2a8-4565-a7a9-062bddd876e1",
      "instanceId": 12456,
      "authEntityId": 12456,
      "displayName": "12456",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "20500,20510,28960",
      "protocol": "TCP",
      "upperPort": 0
    }
  ],
  "parentScalableGroup": {
    "idRef": "0bd0e6c9-5083-492b-97cb-a88928bee99c"
  },
  "user": [

  ],
  "userGroup": [

  ]
},
{
  "id": "77d6c0ab-a4ad-416b-bcb9-963686d5e76d",
  "instanceId": 15302,
  "authEntityId": 15302,
  "displayName": "15302",
  "authEntityClass": -1909073334,
  "deployPending": "NONE",
  "instanceVersion": 0,
  "createTime": 1517966744698,
  "deployed": false,
  "isSeeded": true,
  "isStale": false,
  "lastUpdateTime": 1517966744698,
  "name": "trinoo",
  "namespace": "scalablegroup:application",
  "provisioningState": "DEFINED",
  "qualifier": "application",
  "resourceVersion": 0,
  "targetIdList": [

  ],
  "type": "scalablegroup",
  "cfsChangeInfo": [

  ],
  "customProvisions": [

  ],
  "scalableGroupExternalHandle": "trinoo",
  "scalableGroupType": "APPLICATION",
  "securityGroupTag": 0,
  "state": "ACTIVE",
  "vnAgnostic": true,
  "identitySource": {
    "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
    "instanceId": 10010,
    "authEntityId": 10010,
    "displayName": "10010",
    "authEntityClass": 1738953278,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "state": "INACTIVE",
    "type": "NBAR"
  },
  "indicativeNetworkIdentity": [
    {
      "id": "283960c6-6884-4d4c-9a44-a69d85aacc82",
      "instanceId": 17532,
      "authEntityId": 17532,
      "displayName": "17532",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "27665",
      "protocol": "TCP",
      "upperPort": 0
    },
    {
      "id": "2d7138f5-1e1c-498c-b32a-df3ab0d8e4ba",
      "instanceId": 17533,
      "authEntityId": 17533,
      "displayName": "17533",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "27444,31335",
      "protocol": "UDP",
      "upperPort": 0
    }
  ],
  "networkApplications": [
    {
      "id": "a68e8b54-241d-4238-80ed-dced8abad8f2",
      "instanceId": 16245,
      "authEntityId": 16245,
      "displayName": "16245",
      "authEntityClass": -217092956,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "appProtocol": "tcp/udp",
      "applicationGroup": "other",
      "applicationType": "DEFAULT",
      "categoryId": "01adfd0e-0c60-495e-91f8-afba613b2e09",
      "createTime": 1517966744698,
      "encrypted": "false",
      "engineId": "3",
      "helpString": "Set of computer programs to conduct a DDoS attack",
      "ignoreConflict": false,
      "lastUpdateTime": 1517966744698,
      "longDescription": "The trinoo or trin00 is a set of computer programs to conduct a DDoS attack. It is believed that trinoo networks has been set up on thousands of systems on the Internet that have been compromised by remote buffer overrun exploits.",
      "name": "trinoo",
      "nbarId": "1368",
      "p2pTechnology": "false",
      "popularity": 1,
      "rank": 65535,
      "references": "http://en.wikipedia.org/wiki/Trinoo",
      "selectorId": "27665",
      "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
      "trafficClass": "BULK_DATA",
      "tunnel": "false"
    }
  ],
  "networkIdentity": [
    {
      "id": "f8ec6791-dfb2-4d4a-829f-b05d65954801",
      "instanceId": 17530,
      "authEntityId": 17530,
      "displayName": "17530",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "27444,31335",
      "protocol": "UDP",
      "upperPort": 0
    },
    {
      "id": "cf456de8-dcd3-4fa0-a028-8e8600caf39c",
      "instanceId": 17531,
      "authEntityId": 17531,
      "displayName": "17531",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "27665",
      "protocol": "TCP",
      "upperPort": 0
    }
  ],
  "parentScalableGroup": {
    "idRef": "5e66d25d-d517-482b-82a8-6f99f07fa786"
  },
  "user": [

  ],
  "userGroup": [

  ]
},
{
  "id": "77eb76d8-ef9a-41a5-bce8-a2701ea8b8e0",
  "instanceId": 4983,
  "authEntityId": 4983,
  "displayName": "4983",
  "authEntityClass": -1909073334,
  "deployPending": "NONE",
  "instanceVersion": 0,
  "createTime": 1517966743970,
  "deployed": false,
  "isSeeded": true,
  "isStale": false,
  "lastUpdateTime": 1517966743970,
  "name": "print-srv",
  "namespace": "scalablegroup:application",
  "provisioningState": "DEFINED",
  "qualifier": "application",
  "resourceVersion": 0,
  "targetIdList": [

  ],
  "type": "scalablegroup",
  "cfsChangeInfo": [

  ],
  "customProvisions": [

  ],
  "scalableGroupExternalHandle": "print-srv",
  "scalableGroupType": "APPLICATION",
  "securityGroupTag": 0,
  "state": "ACTIVE",
  "vnAgnostic": true,
  "identitySource": {
    "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
    "instanceId": 10010,
    "authEntityId": 10010,
    "displayName": "10010",
    "authEntityClass": 1738953278,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "state": "INACTIVE",
    "type": "NBAR"
  },
  "indicativeNetworkIdentity": [
    {
      "id": "6c9ae5be-56ad-4c9a-aee9-83f62b3cc05b",
      "instanceId": 14600,
      "authEntityId": 14600,
      "displayName": "14600",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "170",
      "protocol": "UDP",
      "upperPort": 0
    },
    {
      "id": "4849afd6-de51-4cb3-9abd-ee67d3fbdbc8",
      "instanceId": 14601,
      "authEntityId": 14601,
      "displayName": "14601",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "170",
      "protocol": "TCP",
      "upperPort": 0
    }
  ],
  "networkApplications": [
    {
      "id": "af021887-9888-465c-bff3-12b42aabd8a6",
      "instanceId": 11932,
      "authEntityId": 11932,
      "displayName": "11932",
      "authEntityClass": -217092956,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "appProtocol": "tcp/udp",
      "applicationGroup": "other",
      "applicationType": "DEFAULT",
      "categoryId": "6daa3dfd-3526-407a-88f0-97824b3f3a24",
      "createTime": 1517966743970,
      "encrypted": "false",
      "engineId": "3",
      "helpString": "Network PostScript",
      "ignoreConflict": false,
      "lastUpdateTime": 1517966743970,
      "longDescription": "Adobe PostScript is the worldwide printing and imaging standard. Used by print service providers, publishers, corporations, and government agencies around the globe",
      "name": "print-srv",
      "nbarId": "1015",
      "p2pTechnology": "false",
      "popularity": 1,
      "rank": 65535,
      "references": "http://www.adobe.com/products/postscript/",
      "selectorId": "170",
      "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
      "trafficClass": "BULK_DATA",
      "tunnel": "false"
    }
  ],
  "networkIdentity": [
    {
      "id": "f5d1f39e-1c67-4b3f-9a79-ca58917a2533",
      "instanceId": 14598,
      "authEntityId": 14598,
      "displayName": "14598",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "170",
      "protocol": "TCP",
      "upperPort": 0
    },
    {
      "id": "edd9d1a9-600f-42a3-803b-641ff55435a7",
      "instanceId": 14599,
      "authEntityId": 14599,
      "displayName": "14599",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "170",
      "protocol": "UDP",
      "upperPort": 0
    }
  ],
  "parentScalableGroup": {
    "idRef": "000c15bf-28fe-4fbb-9b69-35e6502721f1"
  },
  "user": [

  ],
  "userGroup": [

  ]
},
{
  "id": "7858da06-67f3-45d5-be90-5b2c98f35a97",
  "instanceId": 4807,
  "authEntityId": 4807,
  "displayName": "4807",
  "authEntityClass": -1909073334,
  "deployPending": "NONE",
  "instanceVersion": 0,
  "createTime": 1517966743531,
  "deployed": false,
  "isSeeded": true,
  "isStale": false,
  "lastUpdateTime": 1517966743531,
  "name": "mtv",
  "namespace": "scalablegroup:application",
  "provisioningState": "DEFINED",
  "qualifier": "application",
  "resourceVersion": 0,
  "targetIdList": [

  ],
  "type": "scalablegroup",
  "cfsChangeInfo": [

  ],
  "customProvisions": [

  ],
  "scalableGroupExternalHandle": "mtv",
  "scalableGroupType": "APPLICATION",
  "securityGroupTag": 0,
  "state": "ACTIVE",
  "vnAgnostic": true,
  "identitySource": {
    "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
    "instanceId": 10010,
    "authEntityId": 10010,
    "displayName": "10010",
    "authEntityClass": 1738953278,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "state": "INACTIVE",
    "type": "NBAR"
  },
  "indicativeNetworkIdentity": [

  ],
  "networkApplications": [
    {
      "id": "6c2aac18-31ad-4811-a0ef-02ddb848ce76",
      "instanceId": 11756,
      "authEntityId": 11756,
      "displayName": "11756",
      "authEntityClass": -217092956,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "appProtocol": "tcp/udp",
      "applicationGroup": "other",
      "applicationType": "DEFAULT",
      "categoryId": "878644b5-fe91-45b4-9204-31c84a73ba48",
      "createTime": 1517966743531,
      "encrypted": "false",
      "engineId": "13",
      "helpString": "MTV - Official website for MTV.",
      "ignoreConflict": false,
      "lastUpdateTime": 1517966743531,
      "longDescription": "MTV - Official website for MTV.",
      "name": "mtv",
      "nbarId": "1193",
      "p2pTechnology": "false",
      "popularity": 3,
      "rank": 65535,
      "references": "http://mtv.com",
      "selectorId": "619",
      "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
      "trafficClass": "TRANSACTIONAL_DATA",
      "tunnel": "false"
    }
  ],
  "networkIdentity": [
    {
      "id": "c768b3cd-f446-4e09-87dd-f2bd7e2cd9c5",
      "instanceId": 14059,
      "authEntityId": 14059,
      "displayName": "14059",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "53,80,443,5353",
      "protocol": "TCP",
      "upperPort": 0
    },
    {
      "id": "8aa46eff-3799-449e-b43a-0bbd8129ed8b",
      "instanceId": 14060,
      "authEntityId": 14060,
      "displayName": "14060",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "53,5353",
      "protocol": "UDP",
      "upperPort": 0
    }
  ],
  "parentScalableGroup": {
    "idRef": "28e08de4-d7b2-4521-84ce-bc1e5ee0b9cc"
  },
  "user": [

  ],
  "userGroup": [

  ]
},
{
  "id": "788bad35-e124-4a6f-8bb4-e3688f033f51",
  "instanceId": 4471,
  "authEntityId": 4471,
  "displayName": "4471",
  "authEntityClass": -1909073334,
  "deployPending": "NONE",
  "instanceVersion": 0,
  "createTime": 1517966742753,
  "deployed": false,
  "isSeeded": true,
  "isStale": false,
  "lastUpdateTime": 1517966742753,
  "name": "fujitsu-dev",
  "namespace": "scalablegroup:application",
  "provisioningState": "DEFINED",
  "qualifier": "application",
  "resourceVersion": 0,
  "targetIdList": [

  ],
  "type": "scalablegroup",
  "cfsChangeInfo": [

  ],
  "customProvisions": [

  ],
  "scalableGroupExternalHandle": "fujitsu-dev",
  "scalableGroupType": "APPLICATION",
  "securityGroupTag": 0,
  "state": "ACTIVE",
  "vnAgnostic": true,
  "identitySource": {
    "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
    "instanceId": 10010,
    "authEntityId": 10010,
    "displayName": "10010",
    "authEntityClass": 1738953278,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "state": "INACTIVE",
    "type": "NBAR"
  },
  "indicativeNetworkIdentity": [
    {
      "id": "776f038b-789e-447b-af4b-d1b1a857ad8e",
      "instanceId": 13171,
      "authEntityId": 13171,
      "displayName": "13171",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "747",
      "protocol": "TCP",
      "upperPort": 0
    },
    {
      "id": "9abd17ac-b8f8-4cde-83dc-84d0c82e7727",
      "instanceId": 13172,
      "authEntityId": 13172,
      "displayName": "13172",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "747",
      "protocol": "UDP",
      "upperPort": 0
    }
  ],
  "networkApplications": [
    {
      "id": "912ef054-156f-427d-81ab-bc14227cb5cc",
      "instanceId": 11422,
      "authEntityId": 11422,
      "displayName": "11422",
      "authEntityClass": -217092956,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "appProtocol": "tcp/udp",
      "applicationGroup": "other",
      "applicationType": "DEFAULT",
      "categoryId": "6daa3dfd-3526-407a-88f0-97824b3f3a24",
      "createTime": 1517966742753,
      "encrypted": "false",
      "engineId": "3",
      "helpString": "Fujitsu Device Control",
      "ignoreConflict": false,
      "lastUpdateTime": 1517966742753,
      "longDescription": "Fujitsu Device Control is a system that controls devices within a house, to reduce the effort required of a user during authentication, as well as to prevent unauthorized access by a third party. By Fujitsu Limited, a Japanese IT services provider.",
      "name": "fujitsu-dev",
      "nbarId": "621",
      "p2pTechnology": "false",
      "popularity": 1,
      "rank": 65535,
      "references": "http://www.fujitsu.com/global/",
      "selectorId": "747",
      "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
      "trafficClass": "BULK_DATA",
      "tunnel": "false"
    }
  ],
  "networkIdentity": [
    {
      "id": "7c5d2892-01da-4f43-819b-ef8197be4712",
      "instanceId": 13169,
      "authEntityId": 13169,
      "displayName": "13169",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "747",
      "protocol": "TCP",
      "upperPort": 0
    },
    {
      "id": "5b47ac71-c3b0-41c0-9d71-ae5b3db20020",
      "instanceId": 13170,
      "authEntityId": 13170,
      "displayName": "13170",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "747",
      "protocol": "UDP",
      "upperPort": 0
    }
  ],
  "parentScalableGroup": {
    "idRef": "000c15bf-28fe-4fbb-9b69-35e6502721f1"
  },
  "user": [

  ],
  "userGroup": [

  ]
},
{
  "id": "788cae9f-2178-4ffc-8985-90b52cb66a86",
  "instanceId": 4244,
  "authEntityId": 4244,
  "displayName": "4244",
  "authEntityClass": -1909073334,
  "deployPending": "NONE",
  "instanceVersion": 0,
  "createTime": 1517966741922,
  "deployed": false,
  "isSeeded": true,
  "isStale": false,
  "lastUpdateTime": 1517966741922,
  "name": "cisco-nac",
  "namespace": "scalablegroup:application",
  "provisioningState": "DEFINED",
  "qualifier": "application",
  "resourceVersion": 0,
  "targetIdList": [

  ],
  "type": "scalablegroup",
  "cfsChangeInfo": [

  ],
  "customProvisions": [

  ],
  "scalableGroupExternalHandle": "cisco-nac",
  "scalableGroupType": "APPLICATION",
  "securityGroupTag": 0,
  "state": "ACTIVE",
  "vnAgnostic": true,
  "identitySource": {
    "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
    "instanceId": 10010,
    "authEntityId": 10010,
    "displayName": "10010",
    "authEntityClass": 1738953278,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "state": "INACTIVE",
    "type": "NBAR"
  },
  "indicativeNetworkIdentity": [
    {
      "id": "d2cfd8e1-cce2-4a3a-99b8-37ed3f8ab61d",
      "instanceId": 12525,
      "authEntityId": 12525,
      "displayName": "12525",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "8905,8906",
      "protocol": "UDP",
      "upperPort": 0
    }
  ],
  "networkApplications": [
    {
      "id": "684834ff-50c8-41ce-bfe1-7021165b3fc0",
      "instanceId": 11198,
      "authEntityId": 11198,
      "displayName": "11198",
      "authEntityClass": -217092956,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "appProtocol": "udp",
      "applicationGroup": "other",
      "applicationType": "DEFAULT",
      "categoryId": "38a8e354-e2fe-4f3c-b1c5-2bbb90fb0d99",
      "createTime": 1517966741922,
      "encrypted": "false",
      "engineId": "3",
      "helpString": "Cisco Network Access Control (NAC) system",
      "ignoreConflict": false,
      "lastUpdateTime": 1517966741922,
      "longDescription": "Cisco Network Admission Control (NAC) is a system that provides integrated network security solutions. It offers authentication and authorization of wired, wireless, and VPN users and devices (access control), end-point security policies, simple guest access control, audition and information logging of network activity. Typically NAC uses UDP ports 8905 and 8906.",
      "name": "cisco-nac",
      "nbarId": "1334",
      "p2pTechnology": "false",
      "popularity": 1,
      "rank": 65535,
      "references": "http://www.cisco.com/go/nac",
      "selectorId": "8905",
      "subCategory": "e390124a-0fd6-4253-b68e-4b0c1af8cb4b",
      "trafficClass": "OPS_ADMIN_MGMT",
      "tunnel": "false"
    }
  ],
  "networkIdentity": [
    {
      "id": "daecc821-a4a0-47be-8e41-fbf5b710f893",
      "instanceId": 12524,
      "authEntityId": 12524,
      "displayName": "12524",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "8905,8906",
      "protocol": "UDP",
      "upperPort": 0
    }
  ],
  "parentScalableGroup": {
    "idRef": "7788bf2d-e289-4597-b500-17aacb7e820e"
  },
  "user": [

  ],
  "userGroup": [

  ]
},
{
  "id": "78bda86b-416c-44d6-9213-7c898a0187de",
  "instanceId": 15419,
  "authEntityId": 15419,
  "displayName": "15419",
  "authEntityClass": -1909073334,
  "deployPending": "NONE",
  "instanceVersion": 0,
  "createTime": 1517966744932,
  "deployed": false,
  "isSeeded": true,
  "isStale": false,
  "lastUpdateTime": 1517966744932,
  "name": "windows-update",
  "namespace": "scalablegroup:application",
  "provisioningState": "DEFINED",
  "qualifier": "application",
  "resourceVersion": 0,
  "targetIdList": [

  ],
  "type": "scalablegroup",
  "cfsChangeInfo": [

  ],
  "customProvisions": [

  ],
  "scalableGroupExternalHandle": "windows-update",
  "scalableGroupType": "APPLICATION",
  "securityGroupTag": 0,
  "state": "ACTIVE",
  "vnAgnostic": true,
  "identitySource": {
    "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
    "instanceId": 10010,
    "authEntityId": 10010,
    "displayName": "10010",
    "authEntityClass": 1738953278,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "state": "INACTIVE",
    "type": "NBAR"
  },
  "indicativeNetworkIdentity": [

  ],
  "networkApplications": [
    {
      "id": "f5895560-0c8c-4ce4-9fbe-42f5831437ae",
      "instanceId": 16362,
      "authEntityId": 16362,
      "displayName": "16362",
      "authEntityClass": -217092956,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "applicationGroup": "other",
      "applicationType": "DEFAULT",
      "categoryId": "6daa3dfd-3526-407a-88f0-97824b3f3a24",
      "createTime": 1517966744932,
      "encrypted": "unassigned",
      "engineId": "13",
      "helpString": "DEPRECATED, Please refer to ms-update",
      "ignoreConflict": false,
      "lastUpdateTime": 1517966744932,
      "longDescription": "Windows Update is a Control Panel applet found in recent versions of Microsoft Windows that provides updates for the operating system and related components, such as definition updates to the Windows Defender anti-spyware product and Junk Mail filter updates for Windows Mail. It uses HTTP and SSL protocols as underlying protocols.",
      "name": "windows-update",
      "nbarId": "1307",
      "p2pTechnology": "unassigned",
      "popularity": 0,
      "rank": 65535,
      "references": "http://technet.microsoft.com/en-us/library/cc732448%28WS.10%29.aspx",
      "selectorId": "415",
      "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
      "trafficClass": "BULK_DATA",
      "tunnel": "unassigned"
    }
  ],
  "networkIdentity": [

  ],
  "parentScalableGroup": {
    "idRef": "000c15bf-28fe-4fbb-9b69-35e6502721f1"
  },
  "user": [

  ],
  "userGroup": [

  ]
},
{
  "id": "78c34dcf-7991-4ef7-88b7-91dd00466a4d",
  "instanceId": 4436,
  "authEntityId": 4436,
  "displayName": "4436",
  "authEntityClass": -1909073334,
  "deployPending": "NONE",
  "instanceVersion": 0,
  "createTime": 1517966742654,
  "deployed": false,
  "isSeeded": true,
  "isStale": false,
  "lastUpdateTime": 1517966742654,
  "name": "facebook-audio",
  "namespace": "scalablegroup:application",
  "provisioningState": "DEFINED",
  "qualifier": "application",
  "resourceVersion": 0,
  "targetIdList": [

  ],
  "type": "scalablegroup",
  "cfsChangeInfo": [

  ],
  "customProvisions": [

  ],
  "scalableGroupExternalHandle": "facebook-audio",
  "scalableGroupType": "APPLICATION",
  "securityGroupTag": 0,
  "state": "ACTIVE",
  "vnAgnostic": true,
  "identitySource": {
    "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
    "instanceId": 10010,
    "authEntityId": 10010,
    "displayName": "10010",
    "authEntityClass": 1738953278,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "state": "INACTIVE",
    "type": "NBAR"
  },
  "indicativeNetworkIdentity": [

  ],
  "networkApplications": [
    {
      "id": "5c64f79a-1b50-4fac-b1ec-9436be140e98",
      "instanceId": 11387,
      "authEntityId": 11387,
      "displayName": "11387",
      "authEntityClass": -217092956,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "applicationGroup": "other",
      "applicationType": "DEFAULT",
      "categoryId": "9eb6d680-796f-478a-9106-1151f00f7eff",
      "createTime": 1517966742654,
      "encrypted": "false",
      "engineId": "13",
      "helpString": "Facebook Audio",
      "ignoreConflict": false,
      "lastUpdateTime": 1517966742654,
      "longDescription": "Facebook audio streaming services.",
      "name": "facebook-audio",
      "nbarId": "1063",
      "p2pTechnology": "false",
      "popularity": 10,
      "rank": 65535,
      "references": "http://www.facebook.com",
      "selectorId": "1063",
      "subCategory": "118c10a2-e272-47c1-92b9-4814ebe97138",
      "trafficClass": "VOIP_TELEPHONY",
      "tunnel": "false"
    }
  ],
  "networkIdentity": [

  ],
  "parentScalableGroup": {
    "idRef": "82348eb1-d8b8-4daf-8532-15e1a5f9cdd0"
  },
  "user": [

  ],
  "userGroup": [

  ]
},
{
  "id": "79362232-b390-473c-8b5f-815138be5f73",
  "instanceId": 15175,
  "authEntityId": 15175,
  "displayName": "15175",
  "authEntityClass": -1909073334,
  "deployPending": "NONE",
  "instanceVersion": 0,
  "createTime": 1517966744406,
  "deployed": false,
  "isSeeded": true,
  "isStale": false,
  "lastUpdateTime": 1517966744406,
  "name": "sntp-heartbeat",
  "namespace": "scalablegroup:application",
  "provisioningState": "DEFINED",
  "qualifier": "application",
  "resourceVersion": 0,
  "targetIdList": [

  ],
  "type": "scalablegroup",
  "cfsChangeInfo": [

  ],
  "customProvisions": [

  ],
  "scalableGroupExternalHandle": "sntp-heartbeat",
  "scalableGroupType": "APPLICATION",
  "securityGroupTag": 0,
  "state": "ACTIVE",
  "vnAgnostic": true,
  "identitySource": {
    "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
    "instanceId": 10010,
    "authEntityId": 10010,
    "displayName": "10010",
    "authEntityClass": 1738953278,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "state": "INACTIVE",
    "type": "NBAR"
  },
  "indicativeNetworkIdentity": [
    {
      "id": "f89f0df2-c995-4768-bca7-70824d632a4b",
      "instanceId": 17177,
      "authEntityId": 17177,
      "displayName": "17177",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "580",
      "protocol": "TCP",
      "upperPort": 0
    },
    {
      "id": "83190be3-1f42-4de9-9789-c3bc61fa52fc",
      "instanceId": 17178,
      "authEntityId": 17178,
      "displayName": "17178",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "580",
      "protocol": "UDP",
      "upperPort": 0
    }
  ],
  "networkApplications": [
    {
      "id": "f31b8ad5-1924-4700-92f9-34239a16d14f",
      "instanceId": 16118,
      "authEntityId": 16118,
      "displayName": "16118",
      "authEntityClass": -217092956,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "appProtocol": "tcp/udp",
      "applicationGroup": "other",
      "applicationType": "DEFAULT",
      "categoryId": "1fc17362-9334-4c7b-b752-5afdbb574017",
      "createTime": 1517966744406,
      "encrypted": "false",
      "engineId": "3",
      "helpString": "SNTP HEARTBEAT",
      "ignoreConflict": false,
      "lastUpdateTime": 1517966744406,
      "longDescription": "Simple Network Time Protocol Heartbeat (SNTP-HEARTBEAT) is used to  provide a multicast heartbeat in a network. It can be used by network operators as well as application developers to alert themselves to losses of multicast connectivity in portions of the network.",
      "name": "sntp-heartbeat",
      "nbarId": "494",
      "p2pTechnology": "false",
      "popularity": 1,
      "rank": 65535,
      "references": "http://tools.ietf.org/html/draft-ietf-mboned-sntp-heart-00",
      "selectorId": "580",
      "subCategory": "f3c0f761-d894-4112-ab89-b90fc84fd660",
      "trafficClass": "OPS_ADMIN_MGMT",
      "tunnel": "false"
    }
  ],
  "networkIdentity": [
    {
      "id": "259d6bd4-326b-4669-90f7-0634fde52544",
      "instanceId": 17175,
      "authEntityId": 17175,
      "displayName": "17175",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "580",
      "protocol": "UDP",
      "upperPort": 0
    },
    {
      "id": "4041b4dd-45e5-4964-a3e3-8bc2e0eaad26",
      "instanceId": 17176,
      "authEntityId": 17176,
      "displayName": "17176",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "580",
      "protocol": "TCP",
      "upperPort": 0
    }
  ],
  "parentScalableGroup": {
    "idRef": "5fcd74d4-8726-47f7-a088-c457863da7ef"
  },
  "user": [

  ],
  "userGroup": [

  ]
},
{
  "id": "794894cc-2fdc-490c-b6bd-7b5d4752df77",
  "instanceId": 4618,
  "authEntityId": 4618,
  "displayName": "4618",
  "authEntityClass": -1909073334,
  "deployPending": "NONE",
  "instanceVersion": 0,
  "createTime": 1517966743137,
  "deployed": false,
  "isSeeded": true,
  "isStale": false,
  "lastUpdateTime": 1517966743137,
  "name": "ipcomp",
  "namespace": "scalablegroup:application",
  "provisioningState": "DEFINED",
  "qualifier": "application",
  "resourceVersion": 0,
  "targetIdList": [

  ],
  "type": "scalablegroup",
  "cfsChangeInfo": [

  ],
  "customProvisions": [

  ],
  "scalableGroupExternalHandle": "ipcomp",
  "scalableGroupType": "APPLICATION",
  "securityGroupTag": 0,
  "state": "ACTIVE",
  "vnAgnostic": true,
  "identitySource": {
    "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
    "instanceId": 10010,
    "authEntityId": 10010,
    "displayName": "10010",
    "authEntityClass": 1738953278,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "state": "INACTIVE",
    "type": "NBAR"
  },
  "indicativeNetworkIdentity": [
    {
      "id": "8863763d-8698-4d8b-bb67-daadd32767d3",
      "instanceId": 13560,
      "authEntityId": 13560,
      "displayName": "13560",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipProtocolNumbers": "108",
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "protocol": "IPCOMP",
      "upperPort": 0
    }
  ],
  "networkApplications": [
    {
      "id": "70550fe5-5f34-4df1-88bf-619c7f463787",
      "instanceId": 11568,
      "authEntityId": 11568,
      "displayName": "11568",
      "authEntityClass": -217092956,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "appProtocol": "ip",
      "applicationGroup": "other",
      "applicationType": "DEFAULT",
      "categoryId": "6daa3dfd-3526-407a-88f0-97824b3f3a24",
      "createTime": 1517966743137,
      "encrypted": "unassigned",
      "engineId": "1",
      "helpString": "IP Payload Compression Protocol",
      "ignoreConflict": false,
      "lastUpdateTime": 1517966743137,
      "longDescription": "IP payload compression is a protocol to reduce the size of IP datagrams. IPComp protocol will increase the overall communication performance by compressing the datagrams, provided the nodes have sufficient computation power and the communication is over slow or congested links.",
      "name": "ipcomp",
      "nbarId": "862",
      "p2pTechnology": "unassigned",
      "popularity": 2,
      "rank": 65535,
      "references": "http://tools.ietf.org/html/rfc3173",
      "selectorId": "108",
      "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
      "trafficClass": "BULK_DATA",
      "tunnel": "unassigned"
    }
  ],
  "networkIdentity": [
    {
      "id": "661e75de-c476-4360-ae00-3dc007acecd4",
      "instanceId": 13559,
      "authEntityId": 13559,
      "displayName": "13559",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipProtocolNumbers": "108",
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "protocol": "IPCOMP",
      "upperPort": 0
    }
  ],
  "parentScalableGroup": {
    "idRef": "000c15bf-28fe-4fbb-9b69-35e6502721f1"
  },
  "user": [

  ],
  "userGroup": [

  ]
},
{
  "id": "79598a40-30c7-4413-b36b-e21ec264f94f",
  "instanceId": 4617,
  "authEntityId": 4617,
  "displayName": "4617",
  "authEntityClass": -1909073334,
  "deployPending": "NONE",
  "instanceVersion": 0,
  "createTime": 1517966743135,
  "deployed": false,
  "isSeeded": true,
  "isStale": false,
  "lastUpdateTime": 1517966743135,
  "name": "ipcd",
  "namespace": "scalablegroup:application",
  "provisioningState": "DEFINED",
  "qualifier": "application",
  "resourceVersion": 0,
  "targetIdList": [

  ],
  "type": "scalablegroup",
  "cfsChangeInfo": [

  ],
  "customProvisions": [

  ],
  "scalableGroupExternalHandle": "ipcd",
  "scalableGroupType": "APPLICATION",
  "securityGroupTag": 0,
  "state": "ACTIVE",
  "vnAgnostic": true,
  "identitySource": {
    "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
    "instanceId": 10010,
    "authEntityId": 10010,
    "displayName": "10010",
    "authEntityClass": 1738953278,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "state": "INACTIVE",
    "type": "NBAR"
  },
  "indicativeNetworkIdentity": [
    {
      "id": "1cefdcb4-b2e5-4082-8a37-50f068db0ded",
      "instanceId": 13557,
      "authEntityId": 13557,
      "displayName": "13557",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "576",
      "protocol": "TCP",
      "upperPort": 0
    },
    {
      "id": "e9a64cba-6109-4bf6-89e9-1724516c0a86",
      "instanceId": 13558,
      "authEntityId": 13558,
      "displayName": "13558",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "576",
      "protocol": "UDP",
      "upperPort": 0
    }
  ],
  "networkApplications": [
    {
      "id": "a7b3c7d3-a22c-41da-b931-44a16464e7b4",
      "instanceId": 11567,
      "authEntityId": 11567,
      "displayName": "11567",
      "authEntityClass": -217092956,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "appProtocol": "tcp/udp",
      "applicationGroup": "other",
      "applicationType": "DEFAULT",
      "categoryId": "6daa3dfd-3526-407a-88f0-97824b3f3a24",
      "createTime": 1517966743135,
      "encrypted": "false",
      "engineId": "3",
      "helpString": "ipcd",
      "ignoreConflict": false,
      "lastUpdateTime": 1517966743135,
      "longDescription": "Registered with IANA on port 576 TCP/UDP",
      "name": "ipcd",
      "nbarId": "490",
      "p2pTechnology": "false",
      "popularity": 1,
      "rank": 65535,
      "references": "http://www.iana.org/assignments/service-names-port-numbers/service-names-port-numbers.xml",
      "selectorId": "576",
      "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
      "trafficClass": "BULK_DATA",
      "tunnel": "false"
    }
  ],
  "networkIdentity": [
    {
      "id": "a083a3c6-879d-4aaf-bbb9-9fdeab660580",
      "instanceId": 13555,
      "authEntityId": 13555,
      "displayName": "13555",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "576",
      "protocol": "UDP",
      "upperPort": 0
    },
    {
      "id": "aa337053-c919-4135-80fd-74ed42014078",
      "instanceId": 13556,
      "authEntityId": 13556,
      "displayName": "13556",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "576",
      "protocol": "TCP",
      "upperPort": 0
    }
  ],
  "parentScalableGroup": {
    "idRef": "000c15bf-28fe-4fbb-9b69-35e6502721f1"
  },
  "user": [

  ],
  "userGroup": [

  ]
},
{
  "id": "7a4e06b9-8585-459e-a6fd-443264acd758",
  "instanceId": 4181,
  "authEntityId": 4181,
  "displayName": "4181",
  "authEntityClass": -1909073334,
  "deployPending": "NONE",
  "instanceVersion": 0,
  "createTime": 1517966741558,
  "deployed": false,
  "isSeeded": true,
  "isStale": false,
  "lastUpdateTime": 1517966741558,
  "name": "bitbucket",
  "namespace": "scalablegroup:application",
  "provisioningState": "DEFINED",
  "qualifier": "application",
  "resourceVersion": 0,
  "targetIdList": [

  ],
  "type": "scalablegroup",
  "cfsChangeInfo": [

  ],
  "customProvisions": [

  ],
  "scalableGroupExternalHandle": "bitbucket",
  "scalableGroupType": "APPLICATION",
  "securityGroupTag": 0,
  "state": "ACTIVE",
  "vnAgnostic": true,
  "identitySource": {
    "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
    "instanceId": 10010,
    "authEntityId": 10010,
    "displayName": "10010",
    "authEntityClass": 1738953278,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "state": "INACTIVE",
    "type": "NBAR"
  },
  "indicativeNetworkIdentity": [

  ],
  "networkApplications": [
    {
      "id": "8b0fa200-1a3e-4a97-bef1-3605bc7f720d",
      "instanceId": 11140,
      "authEntityId": 11140,
      "displayName": "11140",
      "authEntityClass": -217092956,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "appProtocol": "tcp/udp",
      "applicationGroup": "other",
      "applicationType": "DEFAULT",
      "categoryId": "878644b5-fe91-45b4-9204-31c84a73ba48",
      "createTime": 1517966741558,
      "encrypted": "false",
      "engineId": "13",
      "helpString": "Bitbucket - Source code hosting site.",
      "ignoreConflict": false,
      "lastUpdateTime": 1517966741558,
      "longDescription": "Bitbucket - Source code hosting site.",
      "name": "bitbucket",
      "nbarId": "1676",
      "p2pTechnology": "false",
      "popularity": 3,
      "rank": 65535,
      "references": "http://bitbucket.org",
      "selectorId": "746",
      "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
      "trafficClass": "TRANSACTIONAL_DATA",
      "tunnel": "false"
    }
  ],
  "networkIdentity": [
    {
      "id": "c4f05c8e-60e0-4b4c-b054-7076455a1fdd",
      "instanceId": 12391,
      "authEntityId": 12391,
      "displayName": "12391",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "53,5353",
      "protocol": "UDP",
      "upperPort": 0
    },
    {
      "id": "b2016d52-7d06-4ac2-8633-f0e3ff3fcea8",
      "instanceId": 12392,
      "authEntityId": 12392,
      "displayName": "12392",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "53,80,443,5353",
      "protocol": "TCP",
      "upperPort": 0
    }
  ],
  "parentScalableGroup": {
    "idRef": "28e08de4-d7b2-4521-84ce-bc1e5ee0b9cc"
  },
  "user": [

  ],
  "userGroup": [

  ]
},
{
  "id": "7a625a11-476f-46e7-bc48-310e56c888e2",
  "instanceId": 15208,
  "authEntityId": 15208,
  "displayName": "15208",
  "authEntityClass": -1909073334,
  "deployPending": "NONE",
  "instanceVersion": 0,
  "createTime": 1517966744486,
  "deployed": false,
  "isSeeded": true,
  "isStale": false,
  "lastUpdateTime": 1517966744486,
  "name": "ssl",
  "namespace": "scalablegroup:application",
  "provisioningState": "DEFINED",
  "qualifier": "application",
  "resourceVersion": 0,
  "targetIdList": [

  ],
  "type": "scalablegroup",
  "cfsChangeInfo": [

  ],
  "customProvisions": [

  ],
  "scalableGroupExternalHandle": "ssl",
  "scalableGroupType": "APPLICATION",
  "securityGroupTag": 0,
  "state": "ACTIVE",
  "vnAgnostic": true,
  "identitySource": {
    "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
    "instanceId": 10010,
    "authEntityId": 10010,
    "displayName": "10010",
    "authEntityClass": 1738953278,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "state": "INACTIVE",
    "type": "NBAR"
  },
  "indicativeNetworkIdentity": [
    {
      "id": "2e6d4a6b-e6e4-4b1a-a97e-2ac2d76f7600",
      "instanceId": 17266,
      "authEntityId": 17266,
      "displayName": "17266",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "443",
      "protocol": "UDP",
      "upperPort": 0
    },
    {
      "id": "f2d4274c-1def-4a87-acc4-9eac6d14e6c8",
      "instanceId": 17267,
      "authEntityId": 17267,
      "displayName": "17267",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "443",
      "protocol": "TCP",
      "upperPort": 0
    }
  ],
  "networkApplications": [
    {
      "id": "ad07b627-420c-411d-9e45-90e05c88227b",
      "instanceId": 16151,
      "authEntityId": 16151,
      "displayName": "16151",
      "authEntityClass": -217092956,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "appProtocol": "tcp/udp",
      "applicationGroup": "other",
      "applicationType": "DEFAULT",
      "categoryId": "878644b5-fe91-45b4-9204-31c84a73ba48",
      "createTime": 1517966744486,
      "encrypted": "true",
      "engineId": "13",
      "helpString": "Secure Socket Layer Protocol",
      "ignoreConflict": false,
      "lastUpdateTime": 1517966744486,
      "longDescription": "Secure Socket Layer (SSL) is a cryptographic protocol that provides communication security over the Internet. SSL encrypts the segments of network connections above the Transport Layer, using asymmetric cryptography for key exchange, symmetric encryption for privacy, and a keyed message authentication code for message reliability.",
      "name": "ssl",
      "nbarId": "1312",
      "p2pTechnology": "false",
      "popularity": 10,
      "rank": 65535,
      "references": "http://tools.ietf.org/html/rfc6101",
      "selectorId": "453",
      "subCategory": "b90786f4-0190-45b8-9924-21b3e9682590",
      "trafficClass": "TRANSACTIONAL_DATA",
      "tunnel": "true"
    }
  ],
  "networkIdentity": [
    {
      "id": "8a01f900-cb7d-4d16-8ed5-194cc050a359",
      "instanceId": 17264,
      "authEntityId": 17264,
      "displayName": "17264",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "80,110,443,5222",
      "protocol": "TCP",
      "upperPort": 0
    },
    {
      "id": "556601d6-d6cf-4219-b335-525b606ce807",
      "instanceId": 17265,
      "authEntityId": 17265,
      "displayName": "17265",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "443,5222",
      "protocol": "UDP",
      "upperPort": 0
    }
  ],
  "parentScalableGroup": {
    "idRef": "53f03553-069a-4046-ad7c-84610eb579c5"
  },
  "user": [

  ],
  "userGroup": [

  ]
},
{
  "id": "7af52cc6-caa4-496d-ad3c-081700e6a014",
  "instanceId": 4982,
  "authEntityId": 4982,
  "displayName": "4982",
  "authEntityClass": -1909073334,
  "deployPending": "NONE",
  "instanceVersion": 0,
  "createTime": 1517966743968,
  "deployed": false,
  "isSeeded": true,
  "isStale": false,
  "lastUpdateTime": 1517966743968,
  "name": "priceline-com",
  "namespace": "scalablegroup:application",
  "provisioningState": "DEFINED",
  "qualifier": "application",
  "resourceVersion": 0,
  "targetIdList": [

  ],
  "type": "scalablegroup",
  "cfsChangeInfo": [

  ],
  "customProvisions": [

  ],
  "scalableGroupExternalHandle": "priceline-com",
  "scalableGroupType": "APPLICATION",
  "securityGroupTag": 0,
  "state": "ACTIVE",
  "vnAgnostic": true,
  "identitySource": {
    "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
    "instanceId": 10010,
    "authEntityId": 10010,
    "displayName": "10010",
    "authEntityClass": 1738953278,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "state": "INACTIVE",
    "type": "NBAR"
  },
  "indicativeNetworkIdentity": [

  ],
  "networkApplications": [
    {
      "id": "5cc428fa-50ec-4ab2-bb8b-fa6b75691a71",
      "instanceId": 11931,
      "authEntityId": 11931,
      "displayName": "11931",
      "authEntityClass": -217092956,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "appProtocol": "tcp/udp",
      "applicationGroup": "other",
      "applicationType": "DEFAULT",
      "categoryId": "878644b5-fe91-45b4-9204-31c84a73ba48",
      "createTime": 1517966743968,
      "encrypted": "false",
      "engineId": "13",
      "helpString": "Priceline.com - Travel services company focused on offering discount rates.",
      "ignoreConflict": false,
      "lastUpdateTime": 1517966743968,
      "longDescription": "Priceline.com - Travel services company focused on offering discount rates.",
      "name": "priceline-com",
      "nbarId": "1666",
      "p2pTechnology": "false",
      "popularity": 3,
      "rank": 65535,
      "references": "http://priceline.com",
      "selectorId": "736",
      "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
      "trafficClass": "TRANSACTIONAL_DATA",
      "tunnel": "false"
    }
  ],
  "networkIdentity": [
    {
      "id": "bc66c38c-e066-4d47-9dfc-ebb707fff2ed",
      "instanceId": 14596,
      "authEntityId": 14596,
      "displayName": "14596",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "53,80,443,5353",
      "protocol": "TCP",
      "upperPort": 0
    },
    {
      "id": "e40c4dfa-42c5-4f64-82cd-84b67f1b3fd2",
      "instanceId": 14597,
      "authEntityId": 14597,
      "displayName": "14597",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "53,5353",
      "protocol": "UDP",
      "upperPort": 0
    }
  ],
  "parentScalableGroup": {
    "idRef": "28e08de4-d7b2-4521-84ce-bc1e5ee0b9cc"
  },
  "user": [

  ],
  "userGroup": [

  ]
},
{
  "id": "7afe5f8c-381d-4c5d-9713-44829dcc9b62",
  "instanceId": 4253,
  "authEntityId": 4253,
  "displayName": "4253",
  "authEntityClass": -1909073334,
  "deployPending": "NONE",
  "instanceVersion": 0,
  "createTime": 1517966741947,
  "deployed": false,
  "isSeeded": true,
  "isStale": false,
  "lastUpdateTime": 1517966741947,
  "name": "cisco-spark-audio",
  "namespace": "scalablegroup:application",
  "provisioningState": "DEFINED",
  "qualifier": "application",
  "resourceVersion": 0,
  "targetIdList": [

  ],
  "type": "scalablegroup",
  "cfsChangeInfo": [

  ],
  "customProvisions": [

  ],
  "scalableGroupExternalHandle": "cisco-spark-audio",
  "scalableGroupType": "APPLICATION",
  "securityGroupTag": 0,
  "state": "ACTIVE",
  "vnAgnostic": true,
  "identitySource": {
    "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
    "instanceId": 10010,
    "authEntityId": 10010,
    "displayName": "10010",
    "authEntityClass": 1738953278,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "state": "INACTIVE",
    "type": "NBAR"
  },
  "indicativeNetworkIdentity": [

  ],
  "networkApplications": [
    {
      "id": "f0567b9f-cb3b-4ba9-a800-5e06d583dff6",
      "instanceId": 11207,
      "authEntityId": 11207,
      "displayName": "11207",
      "authEntityClass": -217092956,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "applicationGroup": "other",
      "applicationType": "DEFAULT",
      "categoryId": "38a8e354-e2fe-4f3c-b1c5-2bbb90fb0d99",
      "createTime": 1517966741947,
      "encrypted": "false",
      "engineId": "13",
      "helpString": "Cisco Spark Audio - unified communications client audio",
      "ignoreConflict": false,
      "lastUpdateTime": 1517966741947,
      "longDescription": "Cisco Spark Audio - Audio of unified communications client and SaaS with mobile team communication: group chat, private chat, video calls with screen sharing and file sharing.",
      "name": "cisco-spark-audio",
      "nbarId": "1050",
      "p2pTechnology": "false",
      "popularity": 10,
      "rank": 65535,
      "references": "http://ciscospark.com",
      "selectorId": "1050",
      "subCategory": "05178016-4555-4291-ab4e-43c2ab2a931a",
      "trafficClass": "VOIP_TELEPHONY",
      "tunnel": "false"
    }
  ],
  "networkIdentity": [

  ],
  "parentScalableGroup": {
    "idRef": "60f1364d-2a02-4063-8ba1-07817abe0612"
  },
  "user": [

  ],
  "userGroup": [

  ]
},
{
  "id": "7b685a45-d36e-4503-bf23-fe54f2e02570",
  "instanceId": 4737,
  "authEntityId": 4737,
  "displayName": "4737",
  "authEntityClass": -1909073334,
  "deployPending": "NONE",
  "instanceVersion": 0,
  "createTime": 1517966743393,
  "deployed": false,
  "isSeeded": true,
  "isStale": false,
  "lastUpdateTime": 1517966743393,
  "name": "meta5",
  "namespace": "scalablegroup:application",
  "provisioningState": "DEFINED",
  "qualifier": "application",
  "resourceVersion": 0,
  "targetIdList": [

  ],
  "type": "scalablegroup",
  "cfsChangeInfo": [

  ],
  "customProvisions": [

  ],
  "scalableGroupExternalHandle": "meta5",
  "scalableGroupType": "APPLICATION",
  "securityGroupTag": 0,
  "state": "ACTIVE",
  "vnAgnostic": true,
  "identitySource": {
    "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
    "instanceId": 10010,
    "authEntityId": 10010,
    "displayName": "10010",
    "authEntityClass": 1738953278,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "state": "INACTIVE",
    "type": "NBAR"
  },
  "indicativeNetworkIdentity": [
    {
      "id": "e5e148d4-5b6a-4af4-852e-33b32e66bb70",
      "instanceId": 13870,
      "authEntityId": 13870,
      "displayName": "13870",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "393",
      "protocol": "TCP",
      "upperPort": 0
    },
    {
      "id": "be9627cf-b5f3-4399-88b6-367851ee220f",
      "instanceId": 13871,
      "authEntityId": 13871,
      "displayName": "13871",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "393",
      "protocol": "UDP",
      "upperPort": 0
    }
  ],
  "networkApplications": [
    {
      "id": "09a76396-25bf-4ca3-8d18-a3b1f3c87965",
      "instanceId": 11687,
      "authEntityId": 11687,
      "displayName": "11687",
      "authEntityClass": -217092956,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "appProtocol": "tcp/udp",
      "applicationGroup": "other",
      "applicationType": "DEFAULT",
      "categoryId": "6daa3dfd-3526-407a-88f0-97824b3f3a24",
      "createTime": 1517966743393,
      "encrypted": "false",
      "engineId": "3",
      "helpString": "meta5",
      "ignoreConflict": false,
      "lastUpdateTime": 1517966743393,
      "longDescription": "Used by Meta5 in their products.",
      "name": "meta5",
      "nbarId": "309",
      "p2pTechnology": "false",
      "popularity": 1,
      "rank": 65535,
      "references": "http://www.meta5.com/",
      "selectorId": "393",
      "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
      "trafficClass": "BULK_DATA",
      "tunnel": "false"
    }
  ],
  "networkIdentity": [
    {
      "id": "4df8c6f8-1528-4ad5-bb98-656d84f5c6a9",
      "instanceId": 13868,
      "authEntityId": 13868,
      "displayName": "13868",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "393",
      "protocol": "UDP",
      "upperPort": 0
    },
    {
      "id": "c41cd753-7dc4-41e4-b053-f90874eb09ef",
      "instanceId": 13869,
      "authEntityId": 13869,
      "displayName": "13869",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "393",
      "protocol": "TCP",
      "upperPort": 0
    }
  ],
  "parentScalableGroup": {
    "idRef": "000c15bf-28fe-4fbb-9b69-35e6502721f1"
  },
  "user": [

  ],
  "userGroup": [

  ]
},
{
  "id": "7be2ed24-5d60-409d-902e-86de2981f87b",
  "instanceId": 4574,
  "authEntityId": 4574,
  "displayName": "4574",
  "authEntityClass": -1909073334,
  "deployPending": "NONE",
  "instanceVersion": 0,
  "createTime": 1517966743030,
  "deployed": false,
  "isSeeded": true,
  "isStale": false,
  "lastUpdateTime": 1517966743030,
  "name": "iax",
  "namespace": "scalablegroup:application",
  "provisioningState": "DEFINED",
  "qualifier": "application",
  "resourceVersion": 0,
  "targetIdList": [

  ],
  "type": "scalablegroup",
  "cfsChangeInfo": [

  ],
  "customProvisions": [

  ],
  "scalableGroupExternalHandle": "iax",
  "scalableGroupType": "APPLICATION",
  "securityGroupTag": 0,
  "state": "ACTIVE",
  "vnAgnostic": true,
  "identitySource": {
    "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
    "instanceId": 10010,
    "authEntityId": 10010,
    "displayName": "10010",
    "authEntityClass": 1738953278,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "state": "INACTIVE",
    "type": "NBAR"
  },
  "indicativeNetworkIdentity": [
    {
      "id": "64d71322-96f4-4f9a-9d5a-1875e762893f",
      "instanceId": 13437,
      "authEntityId": 13437,
      "displayName": "13437",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "4569",
      "protocol": "UDP",
      "upperPort": 0
    },
    {
      "id": "8351c5e6-f5bb-45f0-b9a2-9583774bf5c9",
      "instanceId": 13438,
      "authEntityId": 13438,
      "displayName": "13438",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "4569",
      "protocol": "TCP",
      "upperPort": 0
    }
  ],
  "networkApplications": [
    {
      "id": "d931ed74-65fb-4bd9-92bf-5e5c8c2b8314",
      "instanceId": 11524,
      "authEntityId": 11524,
      "displayName": "11524",
      "authEntityClass": -217092956,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "appProtocol": "tcp/udp",
      "applicationGroup": "other",
      "applicationType": "DEFAULT",
      "categoryId": "6daa3dfd-3526-407a-88f0-97824b3f3a24",
      "createTime": 1517966743029,
      "encrypted": "false",
      "engineId": "3",
      "helpString": "Inter-Asterisk eXchange",
      "ignoreConflict": false,
      "lastUpdateTime": 1517966743029,
      "longDescription": "Inter-Asterisk eXchange protocol (IAX) is native to Asterisk PBX and is supported by a number of other softswitches and PBXs. It is used for enabling VoIP connections between servers beside client-server communication.",
      "name": "iax",
      "nbarId": "1329",
      "p2pTechnology": "true",
      "popularity": 1,
      "rank": 65535,
      "references": "http://tools.ietf.org/html/rfc5456",
      "selectorId": "4569",
      "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
      "trafficClass": "BULK_DATA",
      "tunnel": "false"
    }
  ],
  "networkIdentity": [
    {
      "id": "cbd89324-b87e-4ab0-a9dd-c8bca91021b8",
      "instanceId": 13435,
      "authEntityId": 13435,
      "displayName": "13435",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "4569",
      "protocol": "TCP",
      "upperPort": 0
    },
    {
      "id": "33d31e67-6f79-41f0-ae32-b4db89c68ee5",
      "instanceId": 13436,
      "authEntityId": 13436,
      "displayName": "13436",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "4569",
      "protocol": "UDP",
      "upperPort": 0
    }
  ],
  "parentScalableGroup": {
    "idRef": "000c15bf-28fe-4fbb-9b69-35e6502721f1"
  },
  "user": [

  ],
  "userGroup": [

  ]
},
{
  "id": "7c3334e7-13b3-4134-ae8c-2df4f4c416a9",
  "instanceId": 4424,
  "authEntityId": 4424,
  "displayName": "4424",
  "authEntityClass": -1909073334,
  "deployPending": "NONE",
  "instanceVersion": 0,
  "createTime": 1517966742606,
  "deployed": false,
  "isSeeded": true,
  "isStale": false,
  "lastUpdateTime": 1517966742606,
  "name": "espn-video",
  "namespace": "scalablegroup:application",
  "provisioningState": "DEFINED",
  "qualifier": "application",
  "resourceVersion": 0,
  "targetIdList": [

  ],
  "type": "scalablegroup",
  "cfsChangeInfo": [

  ],
  "customProvisions": [

  ],
  "scalableGroupExternalHandle": "espn-video",
  "scalableGroupType": "APPLICATION",
  "securityGroupTag": 0,
  "state": "ACTIVE",
  "vnAgnostic": true,
  "identitySource": {
    "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
    "instanceId": 10010,
    "authEntityId": 10010,
    "displayName": "10010",
    "authEntityClass": 1738953278,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "state": "INACTIVE",
    "type": "NBAR"
  },
  "indicativeNetworkIdentity": [

  ],
  "networkApplications": [
    {
      "id": "b88e032c-ac94-4780-9ee9-a2342d017fc5",
      "instanceId": 11375,
      "authEntityId": 11375,
      "displayName": "11375",
      "authEntityClass": -217092956,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "appProtocol": "tcp",
      "applicationGroup": "espn-group",
      "applicationType": "DEFAULT",
      "categoryId": "a09ef1b3-ba26-4372-aee8-309d50697e76",
      "createTime": 1517966742606,
      "encrypted": "true",
      "engineId": "13",
      "helpString": "ESPN related websites and mobile applications video content",
      "ignoreConflict": false,
      "lastUpdateTime": 1517966742606,
      "longDescription": "ESPN is a global television network focused on sports-related programming. Espn-video is the protocol used for watching video streams using browser or mobile applications for iPhone, iPad, Android and WinRT.",
      "name": "espn-video",
      "nbarId": "1487",
      "p2pTechnology": "false",
      "popularity": 9,
      "rank": 65535,
      "references": "http://www.espn.com/",
      "selectorId": "552",
      "subCategory": "85a797c0-aef8-4284-91a7-677f33e29abb",
      "trafficClass": "MULTIMEDIA_STREAMING",
      "tunnel": "false"
    }
  ],
  "networkIdentity": [
    {
      "id": "b925d006-1859-402a-a38c-7df69ea0f9a6",
      "instanceId": 13068,
      "authEntityId": 13068,
      "displayName": "13068",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "80,443,554,8554",
      "protocol": "TCP",
      "upperPort": 0
    }
  ],
  "parentScalableGroup": {
    "idRef": "fd6264c9-c16a-4311-a335-a3db039256f3"
  },
  "user": [

  ],
  "userGroup": [

  ]
},
{
  "id": "7c5cea75-ca41-41f8-a4a6-1276e2134e21",
  "instanceId": 15260,
  "authEntityId": 15260,
  "displayName": "15260",
  "authEntityClass": -1909073334,
  "deployPending": "NONE",
  "instanceVersion": 0,
  "createTime": 1517966744600,
  "deployed": false,
  "isSeeded": true,
  "isStale": false,
  "lastUpdateTime": 1517966744600,
  "name": "teamspeak",
  "namespace": "scalablegroup:application",
  "provisioningState": "DEFINED",
  "qualifier": "application",
  "resourceVersion": 0,
  "targetIdList": [

  ],
  "type": "scalablegroup",
  "cfsChangeInfo": [

  ],
  "customProvisions": [

  ],
  "scalableGroupExternalHandle": "teamspeak",
  "scalableGroupType": "APPLICATION",
  "securityGroupTag": 0,
  "state": "ACTIVE",
  "vnAgnostic": true,
  "identitySource": {
    "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
    "instanceId": 10010,
    "authEntityId": 10010,
    "displayName": "10010",
    "authEntityClass": 1738953278,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "state": "INACTIVE",
    "type": "NBAR"
  },
  "indicativeNetworkIdentity": [

  ],
  "networkApplications": [
    {
      "id": "82c903ec-303e-4447-b342-9a11769d43c8",
      "instanceId": 16203,
      "authEntityId": 16203,
      "displayName": "16203",
      "authEntityClass": -217092956,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "applicationGroup": "other",
      "applicationType": "DEFAULT",
      "categoryId": "82a7b7fa-da5a-48f5-8b6a-a2546b947843",
      "createTime": 1517966744600,
      "encrypted": "false",
      "engineId": "13",
      "helpString": "Voice communication protocol",
      "ignoreConflict": false,
      "lastUpdateTime": 1517966744600,
      "longDescription": "TeamSpeak software  is an Internet based conferencing solution that enables users to speak with one another over the Internet. The software is based on client-server architecture and is firewall and router friendly.",
      "name": "teamspeak",
      "nbarId": "1072",
      "p2pTechnology": "false",
      "popularity": 6,
      "rank": 65535,
      "references": "http://teamspeak.com/",
      "selectorId": "447",
      "subCategory": "118c10a2-e272-47c1-92b9-4814ebe97138",
      "trafficClass": "MULTIMEDIA_CONFERENCING",
      "tunnel": "false"
    }
  ],
  "networkIdentity": [

  ],
  "parentScalableGroup": {
    "idRef": "fd6264c9-c16a-4311-a335-a3db039256f3"
  },
  "user": [

  ],
  "userGroup": [

  ]
},
{
  "id": "7c95fbf5-879f-4de2-8264-093de120ca04",
  "instanceId": 15274,
  "authEntityId": 15274,
  "displayName": "15274",
  "authEntityClass": -1909073334,
  "deployPending": "NONE",
  "instanceVersion": 0,
  "createTime": 1517966744633,
  "deployed": false,
  "isSeeded": true,
  "isStale": false,
  "lastUpdateTime": 1517966744633,
  "name": "tesla-sys-msg",
  "namespace": "scalablegroup:application",
  "provisioningState": "DEFINED",
  "qualifier": "application",
  "resourceVersion": 0,
  "targetIdList": [

  ],
  "type": "scalablegroup",
  "cfsChangeInfo": [

  ],
  "customProvisions": [

  ],
  "scalableGroupExternalHandle": "tesla-sys-msg",
  "scalableGroupType": "APPLICATION",
  "securityGroupTag": 0,
  "state": "ACTIVE",
  "vnAgnostic": true,
  "identitySource": {
    "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
    "instanceId": 10010,
    "authEntityId": 10010,
    "displayName": "10010",
    "authEntityClass": 1738953278,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "state": "INACTIVE",
    "type": "NBAR"
  },
  "indicativeNetworkIdentity": [
    {
      "id": "73415f56-9069-4a38-a5fc-b3b24f5fadc9",
      "instanceId": 17454,
      "authEntityId": 17454,
      "displayName": "17454",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "7631",
      "protocol": "TCP",
      "upperPort": 0
    }
  ],
  "networkApplications": [
    {
      "id": "4d59aead-b109-462b-abe5-45660aa556ad",
      "instanceId": 16217,
      "authEntityId": 16217,
      "displayName": "16217",
      "authEntityClass": -217092956,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "appProtocol": "tcp",
      "applicationGroup": "other",
      "applicationType": "DEFAULT",
      "categoryId": "6daa3dfd-3526-407a-88f0-97824b3f3a24",
      "createTime": 1517966744633,
      "encrypted": "false",
      "engineId": "3",
      "helpString": "TESLA System Messaging",
      "ignoreConflict": false,
      "lastUpdateTime": 1517966744633,
      "longDescription": "Registered with IANA on port 7631 TCP",
      "name": "tesla-sys-msg",
      "nbarId": "1392",
      "p2pTechnology": "false",
      "popularity": 1,
      "rank": 65535,
      "references": "http://www.iana.org/assignments/service-names-port-numbers/service-names-port-numbers.xml",
      "selectorId": "7631",
      "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
      "trafficClass": "BULK_DATA",
      "tunnel": "false"
    }
  ],
  "networkIdentity": [
    {
      "id": "122938e9-c535-4bfb-9288-001cc73de11d",
      "instanceId": 17453,
      "authEntityId": 17453,
      "displayName": "17453",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "7631",
      "protocol": "TCP",
      "upperPort": 0
    }
  ],
  "parentScalableGroup": {
    "idRef": "000c15bf-28fe-4fbb-9b69-35e6502721f1"
  },
  "user": [

  ],
  "userGroup": [

  ]
},
{
  "id": "7ce38bb6-bb01-4734-8d23-51380320a0d7",
  "instanceId": 15469,
  "authEntityId": 15469,
  "displayName": "15469",
  "authEntityClass": -1909073334,
  "deployPending": "NONE",
  "instanceVersion": 0,
  "createTime": 1517966745028,
  "deployed": false,
  "isSeeded": true,
  "isStale": false,
  "lastUpdateTime": 1517966745028,
  "name": "zoom-meetings",
  "namespace": "scalablegroup:application",
  "provisioningState": "DEFINED",
  "qualifier": "application",
  "resourceVersion": 0,
  "targetIdList": [

  ],
  "type": "scalablegroup",
  "cfsChangeInfo": [

  ],
  "customProvisions": [

  ],
  "scalableGroupExternalHandle": "zoom-meetings",
  "scalableGroupType": "APPLICATION",
  "securityGroupTag": 0,
  "state": "ACTIVE",
  "vnAgnostic": true,
  "identitySource": {
    "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
    "instanceId": 10010,
    "authEntityId": 10010,
    "displayName": "10010",
    "authEntityClass": 1738953278,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "state": "INACTIVE",
    "type": "NBAR"
  },
  "indicativeNetworkIdentity": [
    {
      "id": "904ef6ae-79fe-47f8-b7e2-a8c8f467e1b0",
      "instanceId": 17976,
      "authEntityId": 17976,
      "displayName": "17976",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "8801,8802",
      "protocol": "UDP",
      "upperPort": 0
    },
    {
      "id": "f8868458-ac20-4bc7-8a50-c159ce73032c",
      "instanceId": 17977,
      "authEntityId": 17977,
      "displayName": "17977",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "8801,8802",
      "protocol": "TCP",
      "upperPort": 0
    }
  ],
  "networkApplications": [
    {
      "id": "5203bc12-b422-45b6-adfe-c57f4f64b842",
      "instanceId": 16412,
      "authEntityId": 16412,
      "displayName": "16412",
      "authEntityClass": -217092956,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "appProtocol": "tcp/udp",
      "applicationGroup": "other",
      "applicationType": "DEFAULT",
      "categoryId": "38a8e354-e2fe-4f3c-b1c5-2bbb90fb0d99",
      "createTime": 1517966745028,
      "encrypted": "true",
      "engineId": "13",
      "helpString": "Zoom Meetings Video Conferencing",
      "ignoreConflict": false,
      "lastUpdateTime": 1517966745028,
      "longDescription": "Zoom Meetings - Enterprise Video Conferencing and Web Conferencing",
      "name": "zoom-meetings",
      "nbarId": "1130",
      "p2pTechnology": "false",
      "popularity": 10,
      "rank": 65535,
      "references": "https://zoom.us/",
      "selectorId": "1130",
      "subCategory": "05178016-4555-4291-ab4e-43c2ab2a931a",
      "trafficClass": "MULTIMEDIA_CONFERENCING",
      "tunnel": "false"
    }
  ],
  "networkIdentity": [
    {
      "id": "99852b1a-f9fb-45c3-b29e-607ceb7bdffb",
      "instanceId": 17974,
      "authEntityId": 17974,
      "displayName": "17974",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "53,80,443,5353,8801,8802",
      "protocol": "TCP",
      "upperPort": 0
    },
    {
      "id": "bafa0874-8a26-40fd-9484-35e424f73f95",
      "instanceId": 17975,
      "authEntityId": 17975,
      "displayName": "17975",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "53,5353,8801,8802",
      "protocol": "UDP",
      "upperPort": 0
    }
  ],
  "parentScalableGroup": {
    "idRef": "60f1364d-2a02-4063-8ba1-07817abe0612"
  },
  "user": [

  ],
  "userGroup": [

  ]
},
{
  "id": "7d217ce7-3b22-40e2-86cf-953f484960a4",
  "instanceId": 4041,
  "authEntityId": 4041,
  "displayName": "4041",
  "authEntityClass": -1909073334,
  "deployPending": "NONE",
  "instanceVersion": 0,
  "createTime": 1517966740540,
  "deployed": false,
  "isSeeded": true,
  "isStale": false,
  "lastUpdateTime": 1517966740540,
  "name": "9pfs",
  "namespace": "scalablegroup:application",
  "provisioningState": "DEFINED",
  "qualifier": "application",
  "resourceVersion": 0,
  "targetIdList": [

  ],
  "type": "scalablegroup",
  "cfsChangeInfo": [

  ],
  "customProvisions": [

  ],
  "scalableGroupExternalHandle": "9pfs",
  "scalableGroupType": "APPLICATION",
  "securityGroupTag": 0,
  "state": "ACTIVE",
  "vnAgnostic": true,
  "identitySource": {
    "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
    "instanceId": 10010,
    "authEntityId": 10010,
    "displayName": "10010",
    "authEntityClass": 1738953278,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "state": "INACTIVE",
    "type": "NBAR"
  },
  "indicativeNetworkIdentity": [
    {
      "id": "c379a25d-e894-43ce-acb7-ea5b05555c33",
      "instanceId": 12028,
      "authEntityId": 12028,
      "displayName": "12028",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "564",
      "protocol": "UDP",
      "upperPort": 0
    },
    {
      "id": "fbfa03cf-a12b-4e8e-8f10-ef11d6a7ef5c",
      "instanceId": 12029,
      "authEntityId": 12029,
      "displayName": "12029",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "564",
      "protocol": "TCP",
      "upperPort": 0
    }
  ],
  "networkApplications": [
    {
      "id": "45621f6b-5c8d-49cf-8881-f84faae3c359",
      "instanceId": 11016,
      "authEntityId": 11016,
      "displayName": "11016",
      "authEntityClass": -217092956,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "appProtocol": "tcp/udp",
      "applicationGroup": "other",
      "applicationType": "DEFAULT",
      "categoryId": "6daa3dfd-3526-407a-88f0-97824b3f3a24",
      "createTime": 1517966740540,
      "encrypted": "false",
      "engineId": "3",
      "helpString": "Plan 9 file service",
      "ignoreConflict": false,
      "lastUpdateTime": 1517966740540,
      "longDescription": "9P(or thePlan 9 Filesystem ProtocolorStyx) is anetwork protocoldeveloped for thePlan 9 from Bell Labsdistributed operating systemas the means of connecting the components of a Plan 9 system. Files are key objects in Plan 9. They representwindows, networkconnections,processes, and almost anything else available in the operating system. UnlikeNFS, 9P encouragescachingand also servingsynthetic files.",
      "name": "9pfs",
      "nbarId": "479",
      "p2pTechnology": "false",
      "popularity": 1,
      "rank": 65535,
      "references": "http://en.wikipedia.org/wiki/9P",
      "selectorId": "564",
      "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
      "trafficClass": "BULK_DATA",
      "tunnel": "false"
    }
  ],
  "networkIdentity": [
    {
      "id": "dd3b13f8-18a1-4747-b0a9-0d9e819d38ef",
      "instanceId": 12026,
      "authEntityId": 12026,
      "displayName": "12026",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "564",
      "protocol": "TCP",
      "upperPort": 0
    },
    {
      "id": "dc6aaa37-6624-4a58-952a-e2f098697fd4",
      "instanceId": 12027,
      "authEntityId": 12027,
      "displayName": "12027",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "564",
      "protocol": "UDP",
      "upperPort": 0
    }
  ],
  "parentScalableGroup": {
    "idRef": "000c15bf-28fe-4fbb-9b69-35e6502721f1"
  },
  "user": [

  ],
  "userGroup": [

  ]
},
{
  "id": "7d243ccc-60ef-45ca-b143-1aeb70c03b8c",
  "instanceId": 4548,
  "authEntityId": 4548,
  "displayName": "4548",
  "authEntityClass": -1909073334,
  "deployPending": "NONE",
  "instanceVersion": 0,
  "createTime": 1517966742958,
  "deployed": false,
  "isSeeded": true,
  "isStale": false,
  "lastUpdateTime": 1517966742958,
  "name": "hostgator",
  "namespace": "scalablegroup:application",
  "provisioningState": "DEFINED",
  "qualifier": "application",
  "resourceVersion": 0,
  "targetIdList": [

  ],
  "type": "scalablegroup",
  "cfsChangeInfo": [

  ],
  "customProvisions": [

  ],
  "scalableGroupExternalHandle": "hostgator",
  "scalableGroupType": "APPLICATION",
  "securityGroupTag": 0,
  "state": "ACTIVE",
  "vnAgnostic": true,
  "identitySource": {
    "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
    "instanceId": 10010,
    "authEntityId": 10010,
    "displayName": "10010",
    "authEntityClass": 1738953278,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "state": "INACTIVE",
    "type": "NBAR"
  },
  "indicativeNetworkIdentity": [

  ],
  "networkApplications": [
    {
      "id": "51ace476-8af2-4cff-9f84-149a4659368a",
      "instanceId": 11499,
      "authEntityId": 11499,
      "displayName": "11499",
      "authEntityClass": -217092956,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "appProtocol": "tcp/udp",
      "applicationGroup": "other",
      "applicationType": "DEFAULT",
      "categoryId": "878644b5-fe91-45b4-9204-31c84a73ba48",
      "createTime": 1517966742958,
      "encrypted": "false",
      "engineId": "13",
      "helpString": "HostGator - Web hosting portal.",
      "ignoreConflict": false,
      "lastUpdateTime": 1517966742958,
      "longDescription": "HostGator - Web hosting portal.",
      "name": "hostgator",
      "nbarId": "1639",
      "p2pTechnology": "false",
      "popularity": 3,
      "rank": 65535,
      "references": "http://hostgator.com",
      "selectorId": "709",
      "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
      "trafficClass": "TRANSACTIONAL_DATA",
      "tunnel": "false"
    }
  ],
  "networkIdentity": [
    {
      "id": "15426616-23ec-40e7-8b9d-323b6adb6fcb",
      "instanceId": 13357,
      "authEntityId": 13357,
      "displayName": "13357",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "53,80,443,5353",
      "protocol": "TCP",
      "upperPort": 0
    },
    {
      "id": "271ea901-0337-4b7f-b790-a46ed5a9afda",
      "instanceId": 13358,
      "authEntityId": 13358,
      "displayName": "13358",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "53,5353",
      "protocol": "UDP",
      "upperPort": 0
    }
  ],
  "parentScalableGroup": {
    "idRef": "28e08de4-d7b2-4521-84ce-bc1e5ee0b9cc"
  },
  "user": [

  ],
  "userGroup": [

  ]
},
{
  "id": "7d76ba1d-d034-4132-9099-5920992aadce",
  "instanceId": 4674,
  "authEntityId": 4674,
  "displayName": "4674",
  "authEntityClass": -1909073334,
  "deployPending": "NONE",
  "instanceVersion": 0,
  "createTime": 1517966743257,
  "deployed": false,
  "isSeeded": true,
  "isStale": false,
  "lastUpdateTime": 1517966743257,
  "name": "keyholetv",
  "namespace": "scalablegroup:application",
  "provisioningState": "DEFINED",
  "qualifier": "application",
  "resourceVersion": 0,
  "targetIdList": [

  ],
  "type": "scalablegroup",
  "cfsChangeInfo": [

  ],
  "customProvisions": [

  ],
  "scalableGroupExternalHandle": "keyholetv",
  "scalableGroupType": "APPLICATION",
  "securityGroupTag": 0,
  "state": "ACTIVE",
  "vnAgnostic": true,
  "identitySource": {
    "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
    "instanceId": 10010,
    "authEntityId": 10010,
    "displayName": "10010",
    "authEntityClass": 1738953278,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "state": "INACTIVE",
    "type": "NBAR"
  },
  "indicativeNetworkIdentity": [

  ],
  "networkApplications": [
    {
      "id": "24fd10c2-b3bb-4f99-a6f0-8d190681a922",
      "instanceId": 11624,
      "authEntityId": 11624,
      "displayName": "11624",
      "authEntityClass": -217092956,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "appProtocol": "tcp/udp",
      "applicationGroup": "other",
      "applicationType": "DEFAULT",
      "categoryId": "82a7b7fa-da5a-48f5-8b6a-a2546b947843",
      "createTime": 1517966743257,
      "encrypted": "true",
      "engineId": "13",
      "helpString": "KeyHoleTV, a Japanese P2PTV program",
      "ignoreConflict": false,
      "lastUpdateTime": 1517966743257,
      "longDescription": "KeyHoleTV is an online television portal that links to a limited selection of Japanese television channels, radio stations, and user-made channels. It has clients on Windows, Mac, Linux and iOS.",
      "name": "keyholetv",
      "nbarId": "1530",
      "p2pTechnology": "true",
      "popularity": 7,
      "rank": 65535,
      "references": "http://www.oiseyer.com/index-e.php",
      "selectorId": "591",
      "subCategory": "85a797c0-aef8-4284-91a7-677f33e29abb",
      "trafficClass": "MULTIMEDIA_STREAMING",
      "tunnel": "false"
    }
  ],
  "networkIdentity": [
    {
      "id": "b496fcad-2a7a-4e6a-a0df-069624df0e64",
      "instanceId": 13692,
      "authEntityId": 13692,
      "displayName": "13692",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "53,5353",
      "protocol": "UDP",
      "upperPort": 0
    },
    {
      "id": "c28827dd-6add-44b9-b5f2-e1595dc58567",
      "instanceId": 13693,
      "authEntityId": 13693,
      "displayName": "13693",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "53,80,443,5353",
      "protocol": "TCP",
      "upperPort": 0
    }
  ],
  "parentScalableGroup": {
    "idRef": "fd6264c9-c16a-4311-a335-a3db039256f3"
  },
  "user": [

  ],
  "userGroup": [

  ]
},
{
  "id": "7da21ec5-a316-4c98-93ba-742d85720570",
  "instanceId": 4396,
  "authEntityId": 4396,
  "displayName": "4396",
  "authEntityClass": -1909073334,
  "deployPending": "NONE",
  "instanceVersion": 0,
  "createTime": 1517966742530,
  "deployed": false,
  "isSeeded": true,
  "isStale": false,
  "lastUpdateTime": 1517966742530,
  "name": "e-online",
  "namespace": "scalablegroup:application",
  "provisioningState": "DEFINED",
  "qualifier": "application",
  "resourceVersion": 0,
  "targetIdList": [

  ],
  "type": "scalablegroup",
  "cfsChangeInfo": [

  ],
  "customProvisions": [

  ],
  "scalableGroupExternalHandle": "e-online",
  "scalableGroupType": "APPLICATION",
  "securityGroupTag": 0,
  "state": "ACTIVE",
  "vnAgnostic": true,
  "identitySource": {
    "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
    "instanceId": 10010,
    "authEntityId": 10010,
    "displayName": "10010",
    "authEntityClass": 1738953278,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "state": "INACTIVE",
    "type": "NBAR"
  },
  "indicativeNetworkIdentity": [

  ],
  "networkApplications": [
    {
      "id": "1a98ce24-7459-4b37-beac-c941454afa2e",
      "instanceId": 11347,
      "authEntityId": 11347,
      "displayName": "11347",
      "authEntityClass": -217092956,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "appProtocol": "tcp/udp",
      "applicationGroup": "other",
      "applicationType": "DEFAULT",
      "categoryId": "878644b5-fe91-45b4-9204-31c84a73ba48",
      "createTime": 1517966742530,
      "encrypted": "false",
      "engineId": "13",
      "helpString": "E! Online - Online entertainment news.",
      "ignoreConflict": false,
      "lastUpdateTime": 1517966742530,
      "longDescription": "E! Online - Online entertainment news.",
      "name": "e-online",
      "nbarId": "1653",
      "p2pTechnology": "false",
      "popularity": 3,
      "rank": 65535,
      "references": "http://eonline.com",
      "selectorId": "723",
      "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
      "trafficClass": "TRANSACTIONAL_DATA",
      "tunnel": "false"
    }
  ],
  "networkIdentity": [
    {
      "id": "e2ff1b46-92af-4835-8b3c-a88418c0970a",
      "instanceId": 12983,
      "authEntityId": 12983,
      "displayName": "12983",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "53,80,443,5353",
      "protocol": "TCP",
      "upperPort": 0
    },
    {
      "id": "34b704d6-0c42-4ffc-aa2f-9b3923309dfa",
      "instanceId": 12984,
      "authEntityId": 12984,
      "displayName": "12984",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "53,5353",
      "protocol": "UDP",
      "upperPort": 0
    }
  ],
  "parentScalableGroup": {
    "idRef": "28e08de4-d7b2-4521-84ce-bc1e5ee0b9cc"
  },
  "user": [

  ],
  "userGroup": [

  ]
},
{
  "id": "7db014b4-a78b-46a5-882a-9ffdf87964b8",
  "instanceId": 4044,
  "authEntityId": 4044,
  "displayName": "4044",
  "authEntityClass": -1909073334,
  "deployPending": "NONE",
  "instanceVersion": 0,
  "createTime": 1517966740563,
  "deployed": false,
  "isSeeded": true,
  "isStale": false,
  "lastUpdateTime": 1517966740563,
  "name": "Konspire2b",
  "namespace": "scalablegroup:application",
  "provisioningState": "DEFINED",
  "qualifier": "application",
  "resourceVersion": 0,
  "targetIdList": [

  ],
  "type": "scalablegroup",
  "cfsChangeInfo": [

  ],
  "customProvisions": [

  ],
  "scalableGroupExternalHandle": "Konspire2b",
  "scalableGroupType": "APPLICATION",
  "securityGroupTag": 0,
  "state": "ACTIVE",
  "vnAgnostic": true,
  "identitySource": {
    "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
    "instanceId": 10010,
    "authEntityId": 10010,
    "displayName": "10010",
    "authEntityClass": 1738953278,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "state": "INACTIVE",
    "type": "NBAR"
  },
  "indicativeNetworkIdentity": [
    {
      "id": "ca1d9059-ff4c-4996-b88e-07bee121ba3d",
      "instanceId": 12036,
      "authEntityId": 12036,
      "displayName": "12036",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "6085",
      "protocol": "TCP",
      "upperPort": 0
    },
    {
      "id": "039ca6ff-d58d-42c9-ac70-ce3c8a41abde",
      "instanceId": 12037,
      "authEntityId": 12037,
      "displayName": "12037",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "6085",
      "protocol": "UDP",
      "upperPort": 0
    }
  ],
  "networkApplications": [
    {
      "id": "44fb72e8-14b2-438e-969d-65cf196b8d98",
      "instanceId": 11018,
      "authEntityId": 11018,
      "displayName": "11018",
      "authEntityClass": -217092956,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "appProtocol": "tcp/udp",
      "applicationGroup": "other",
      "applicationType": "DEFAULT",
      "categoryId": "b33b2761-d981-4734-810a-a6a1c18ab352",
      "createTime": 1517966740563,
      "encrypted": "false",
      "engineId": "3",
      "helpString": "konspire2b p2p network",
      "ignoreConflict": false,
      "lastUpdateTime": 1517966740563,
      "longDescription": "konspire2b is a content distribution system that takes the standard peer-to-peer model and turns it upside-down. Instead of downloaders broadcasting search requests, content sources send out announcements for the content that they have. This simple change gives the konspire2b network several nice properties, including log-bounded distribution times.",
      "name": "Konspire2b",
      "nbarId": "1190",
      "p2pTechnology": "true",
      "popularity": 3,
      "rank": 65535,
      "references": "http://konspire.sourceforge.net/",
      "selectorId": "6085",
      "subCategory": "79fe7198-9ce3-4c01-9b58-45a8df3d9513",
      "trafficClass": "BULK_DATA",
      "tunnel": "false"
    }
  ],
  "networkIdentity": [
    {
      "id": "2122684c-957b-42b0-95dd-b540ced15042",
      "instanceId": 12034,
      "authEntityId": 12034,
      "displayName": "12034",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "6085",
      "protocol": "TCP",
      "upperPort": 0
    },
    {
      "id": "d673cbc4-9422-4c33-b007-5a2da4db8742",
      "instanceId": 12035,
      "authEntityId": 12035,
      "displayName": "12035",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "6085",
      "protocol": "UDP",
      "upperPort": 0
    }
  ],
  "parentScalableGroup": {
    "idRef": "7a1a3bbb-dc84-4a35-82a4-7e650c4c4761"
  },
  "user": [

  ],
  "userGroup": [

  ]
},
{
  "id": "7e2727d6-2961-415f-8e4b-5a58a46f8107",
  "instanceId": 4288,
  "authEntityId": 4288,
  "displayName": "4288",
  "authEntityClass": -1909073334,
  "deployPending": "NONE",
  "instanceVersion": 0,
  "createTime": 1517966742159,
  "deployed": false,
  "isSeeded": true,
  "isStale": false,
  "lastUpdateTime": 1517966742159,
  "name": "cooltalk",
  "namespace": "scalablegroup:application",
  "provisioningState": "DEFINED",
  "qualifier": "application",
  "resourceVersion": 0,
  "targetIdList": [

  ],
  "type": "scalablegroup",
  "cfsChangeInfo": [

  ],
  "customProvisions": [

  ],
  "scalableGroupExternalHandle": "cooltalk",
  "scalableGroupType": "APPLICATION",
  "securityGroupTag": 0,
  "state": "ACTIVE",
  "vnAgnostic": true,
  "identitySource": {
    "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
    "instanceId": 10010,
    "authEntityId": 10010,
    "displayName": "10010",
    "authEntityClass": 1738953278,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "state": "INACTIVE",
    "type": "NBAR"
  },
  "indicativeNetworkIdentity": [
    {
      "id": "61e71bcd-29d3-4c4b-ac08-c53844e431bd",
      "instanceId": 12636,
      "authEntityId": 12636,
      "displayName": "12636",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "6499",
      "protocol": "TCP",
      "upperPort": 0
    }
  ],
  "networkApplications": [
    {
      "id": "8af652cc-c436-4ce8-85aa-bbf1128fd0c2",
      "instanceId": 11241,
      "authEntityId": 11241,
      "displayName": "11241",
      "authEntityClass": -217092956,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "appProtocol": "tcp",
      "applicationGroup": "other",
      "applicationType": "DEFAULT",
      "categoryId": "a09ef1b3-ba26-4372-aee8-309d50697e76",
      "createTime": 1517966742159,
      "encrypted": "false",
      "engineId": "3",
      "helpString": "Internet telephony tool",
      "ignoreConflict": false,
      "lastUpdateTime": 1517966742159,
      "longDescription": "An earlier set of data conferencing and telephony extensions for Netscape Navigator from Netscape. It included an Internet phone, chat window, whiteboard and application sharing.",
      "name": "cooltalk",
      "nbarId": "1335",
      "p2pTechnology": "false",
      "popularity": 1,
      "rank": 65535,
      "references": "http://besser.tsoa.nyu.edu/impact/f96/Reviews/djiang/",
      "selectorId": "6499",
      "subCategory": "85a797c0-aef8-4284-91a7-677f33e29abb",
      "trafficClass": "MULTIMEDIA_CONFERENCING",
      "tunnel": "false"
    }
  ],
  "networkIdentity": [
    {
      "id": "9b92ebd5-d48d-4ce3-bf06-a45f106d3d0e",
      "instanceId": 12635,
      "authEntityId": 12635,
      "displayName": "12635",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "6499",
      "protocol": "TCP",
      "upperPort": 0
    }
  ],
  "parentScalableGroup": {
    "idRef": "fd6264c9-c16a-4311-a335-a3db039256f3"
  },
  "user": [

  ],
  "userGroup": [

  ]
},
{
  "id": "7e2ad306-ce6b-4326-8fbc-0204fdd3a56f",
  "instanceId": 4908,
  "authEntityId": 4908,
  "displayName": "4908",
  "authEntityClass": -1909073334,
  "deployPending": "NONE",
  "instanceVersion": 0,
  "createTime": 1517966743733,
  "deployed": false,
  "isSeeded": true,
  "isStale": false,
  "lastUpdateTime": 1517966743733,
  "name": "onmux",
  "namespace": "scalablegroup:application",
  "provisioningState": "DEFINED",
  "qualifier": "application",
  "resourceVersion": 0,
  "targetIdList": [

  ],
  "type": "scalablegroup",
  "cfsChangeInfo": [

  ],
  "customProvisions": [

  ],
  "scalableGroupExternalHandle": "onmux",
  "scalableGroupType": "APPLICATION",
  "securityGroupTag": 0,
  "state": "ACTIVE",
  "vnAgnostic": true,
  "identitySource": {
    "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
    "instanceId": 10010,
    "authEntityId": 10010,
    "displayName": "10010",
    "authEntityClass": 1738953278,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "state": "INACTIVE",
    "type": "NBAR"
  },
  "indicativeNetworkIdentity": [
    {
      "id": "68acfb83-c404-4957-b6f5-f56c61ff734b",
      "instanceId": 14384,
      "authEntityId": 14384,
      "displayName": "14384",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "417",
      "protocol": "UDP",
      "upperPort": 0
    },
    {
      "id": "f63ca8da-16f0-42d6-9b84-89bc0c0e24e8",
      "instanceId": 14385,
      "authEntityId": 14385,
      "displayName": "14385",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "417",
      "protocol": "TCP",
      "upperPort": 0
    }
  ],
  "networkApplications": [
    {
      "id": "0f938105-6211-4e7e-99f2-7d6dc61576eb",
      "instanceId": 11857,
      "authEntityId": 11857,
      "displayName": "11857",
      "authEntityClass": -217092956,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "appProtocol": "tcp/udp",
      "applicationGroup": "other",
      "applicationType": "DEFAULT",
      "categoryId": "6daa3dfd-3526-407a-88f0-97824b3f3a24",
      "createTime": 1517966743732,
      "encrypted": "false",
      "engineId": "3",
      "helpString": "onmux",
      "ignoreConflict": false,
      "lastUpdateTime": 1517966743732,
      "longDescription": "Registered with IANA on port 417 TCP/UDP",
      "name": "onmux",
      "nbarId": "332",
      "p2pTechnology": "false",
      "popularity": 1,
      "rank": 65535,
      "references": "http://www.iana.org/assignments/service-names-port-numbers/service-names-port-numbers.xml",
      "selectorId": "417",
      "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
      "trafficClass": "BULK_DATA",
      "tunnel": "false"
    }
  ],
  "networkIdentity": [
    {
      "id": "6011dd85-0482-4bfa-8630-4c074e3a7692",
      "instanceId": 14382,
      "authEntityId": 14382,
      "displayName": "14382",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "417",
      "protocol": "TCP",
      "upperPort": 0
    },
    {
      "id": "b13d0c70-bb35-4f6d-95bf-20f4ba7a91de",
      "instanceId": 14383,
      "authEntityId": 14383,
      "displayName": "14383",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "417",
      "protocol": "UDP",
      "upperPort": 0
    }
  ],
  "parentScalableGroup": {
    "idRef": "000c15bf-28fe-4fbb-9b69-35e6502721f1"
  },
  "user": [

  ],
  "userGroup": [

  ]
},
{
  "id": "7e5e9d67-9e68-4b01-82be-855eb8fbb9f8",
  "instanceId": 15374,
  "authEntityId": 15374,
  "displayName": "15374",
  "authEntityClass": -1909073334,
  "deployPending": "NONE",
  "instanceVersion": 0,
  "createTime": 1517966744830,
  "deployed": false,
  "isSeeded": true,
  "isStale": false,
  "lastUpdateTime": 1517966744830,
  "name": "wap-push",
  "namespace": "scalablegroup:application",
  "provisioningState": "DEFINED",
  "qualifier": "application",
  "resourceVersion": 0,
  "targetIdList": [

  ],
  "type": "scalablegroup",
  "cfsChangeInfo": [

  ],
  "customProvisions": [

  ],
  "scalableGroupExternalHandle": "wap-push",
  "scalableGroupType": "APPLICATION",
  "securityGroupTag": 0,
  "state": "ACTIVE",
  "vnAgnostic": true,
  "identitySource": {
    "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
    "instanceId": 10010,
    "authEntityId": 10010,
    "displayName": "10010",
    "authEntityClass": 1738953278,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "state": "INACTIVE",
    "type": "NBAR"
  },
  "indicativeNetworkIdentity": [
    {
      "id": "489de020-4af3-4477-a627-e4b4a670d193",
      "instanceId": 17739,
      "authEntityId": 17739,
      "displayName": "17739",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "2948",
      "protocol": "TCP",
      "upperPort": 0
    },
    {
      "id": "8f0972cd-adbf-4465-93f0-806f10802620",
      "instanceId": 17740,
      "authEntityId": 17740,
      "displayName": "17740",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "2948",
      "protocol": "UDP",
      "upperPort": 0
    }
  ],
  "networkApplications": [
    {
      "id": "6e725309-4640-4b1a-abdb-ef7ee3709d89",
      "instanceId": 16317,
      "authEntityId": 16317,
      "displayName": "16317",
      "authEntityClass": -217092956,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "appProtocol": "tcp/udp",
      "applicationGroup": "wap-group",
      "applicationType": "DEFAULT",
      "categoryId": "6daa3dfd-3526-407a-88f0-97824b3f3a24",
      "createTime": 1517966744830,
      "encrypted": "false",
      "engineId": "3",
      "helpString": "WAP PUSH",
      "ignoreConflict": false,
      "lastUpdateTime": 1517966744830,
      "longDescription": "A WAP Push is a specially encoded message which includes a link to a Wirless Application Protocol (WAP) address. WAP Push was specified on top of WAP Datagram Protocol (WDP). As such, it can be delivered over any WDP-supported bearer, such as GPRS or SMS. WAP Push is an ideal technology for reaching users of legacy devices that have limited message content, formatting and viewing capabilities.  This technology can deliver WAP Push messages to the user which in turn may be viewed by them in their device browser.",
      "name": "wap-push",
      "nbarId": "725",
      "p2pTechnology": "false",
      "popularity": 2,
      "rank": 65535,
      "references": "http://en.wikipedia.org/wiki/Wireless_Application_Protocol",
      "selectorId": "2948",
      "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
      "trafficClass": "BULK_DATA",
      "tunnel": "false"
    }
  ],
  "networkIdentity": [
    {
      "id": "54a71471-a673-4b2c-8da6-f022acf17d25",
      "instanceId": 17737,
      "authEntityId": 17737,
      "displayName": "17737",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "2948",
      "protocol": "UDP",
      "upperPort": 0
    },
    {
      "id": "7c3d2920-27e2-42ca-8ae0-d07d38685cec",
      "instanceId": 17738,
      "authEntityId": 17738,
      "displayName": "17738",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "2948",
      "protocol": "TCP",
      "upperPort": 0
    }
  ],
  "parentScalableGroup": {
    "idRef": "000c15bf-28fe-4fbb-9b69-35e6502721f1"
  },
  "user": [

  ],
  "userGroup": [

  ]
},
{
  "id": "7e831547-d0db-4329-9a68-a2acfa8ca080",
  "instanceId": 4093,
  "authEntityId": 4093,
  "displayName": "4093",
  "authEntityClass": -1909073334,
  "deployPending": "NONE",
  "instanceVersion": 0,
  "createTime": 1517966741034,
  "deployed": false,
  "isSeeded": true,
  "isStale": false,
  "lastUpdateTime": 1517966741034,
  "name": "aol-messenger",
  "namespace": "scalablegroup:application",
  "provisioningState": "DEFINED",
  "qualifier": "application",
  "resourceVersion": 0,
  "targetIdList": [

  ],
  "type": "scalablegroup",
  "cfsChangeInfo": [

  ],
  "customProvisions": [

  ],
  "scalableGroupExternalHandle": "aol-messenger",
  "scalableGroupType": "APPLICATION",
  "securityGroupTag": 0,
  "state": "ACTIVE",
  "vnAgnostic": true,
  "identitySource": {
    "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
    "instanceId": 10010,
    "authEntityId": 10010,
    "displayName": "10010",
    "authEntityClass": 1738953278,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "state": "INACTIVE",
    "type": "NBAR"
  },
  "indicativeNetworkIdentity": [

  ],
  "networkApplications": [
    {
      "id": "418726df-a975-466c-b1f3-75d37fa71eb6",
      "instanceId": 11057,
      "authEntityId": 11057,
      "displayName": "11057",
      "authEntityClass": -217092956,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "appProtocol": "tcp/udp",
      "applicationGroup": "aol-group",
      "applicationType": "DEFAULT",
      "categoryId": "0f6cab47-be95-4124-9db3-360ccd55f983",
      "createTime": 1517966741034,
      "encrypted": "true",
      "engineId": "13",
      "helpString": "AOL Instant Messenger Text Chat",
      "ignoreConflict": false,
      "lastUpdateTime": 1517966741034,
      "longDescription": "AOL Instant Messenger Text Chat is the instant messsaging part of AOL Instant Messenger, a software that allows users to communicate either through AIM contacts or Facebook/Google-talk contacts and share photos. AIM uses mixed protocols to support its rich feature sets, which include OSCAR, HTTP, STUN, UDP, TCP as well as SIP / RTP.",
      "name": "aol-messenger",
      "nbarId": "79",
      "p2pTechnology": "false",
      "popularity": 7,
      "rank": 65535,
      "references": "http://www.aim.com/",
      "selectorId": "79",
      "subCategory": "118c10a2-e272-47c1-92b9-4814ebe97138",
      "trafficClass": "MULTIMEDIA_CONFERENCING",
      "tunnel": "false"
    }
  ],
  "networkIdentity": [
    {
      "id": "050efc0d-f6ac-4364-b578-0f24d0bfe672",
      "instanceId": 12146,
      "authEntityId": 12146,
      "displayName": "12146",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "53,80,443,1080,5190,5353",
      "protocol": "TCP",
      "upperPort": 0
    },
    {
      "id": "ca78aa70-5bda-4aa2-9a17-ea132728c0e2",
      "instanceId": 12147,
      "authEntityId": 12147,
      "displayName": "12147",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "53,5353",
      "protocol": "UDP",
      "upperPort": 0
    }
  ],
  "parentScalableGroup": {
    "idRef": "fd6264c9-c16a-4311-a335-a3db039256f3"
  },
  "user": [

  ],
  "userGroup": [

  ]
},
{
  "id": "7e88d8cf-8706-40c6-bc14-dd02a4c267d1",
  "instanceId": 4643,
  "authEntityId": 4643,
  "displayName": "4643",
  "authEntityClass": -1909073334,
  "deployPending": "NONE",
  "instanceVersion": 0,
  "createTime": 1517966743191,
  "deployed": false,
  "isSeeded": true,
  "isStale": false,
  "lastUpdateTime": 1517966743191,
  "name": "iscsi",
  "namespace": "scalablegroup:application",
  "provisioningState": "DEFINED",
  "qualifier": "application",
  "resourceVersion": 0,
  "targetIdList": [

  ],
  "type": "scalablegroup",
  "cfsChangeInfo": [

  ],
  "customProvisions": [

  ],
  "scalableGroupExternalHandle": "iscsi",
  "scalableGroupType": "APPLICATION",
  "securityGroupTag": 0,
  "state": "ACTIVE",
  "vnAgnostic": true,
  "identitySource": {
    "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
    "instanceId": 10010,
    "authEntityId": 10010,
    "displayName": "10010",
    "authEntityClass": 1738953278,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "state": "INACTIVE",
    "type": "NBAR"
  },
  "indicativeNetworkIdentity": [
    {
      "id": "57984afa-a60c-4c16-9557-857758325d9a",
      "instanceId": 13613,
      "authEntityId": 13613,
      "displayName": "13613",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "860",
      "protocol": "TCP",
      "upperPort": 0
    },
    {
      "id": "cbc7a998-d9b3-4111-8f08-7fc4d80fa0ea",
      "instanceId": 13614,
      "authEntityId": 13614,
      "displayName": "13614",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "860,3260",
      "protocol": "UDP",
      "upperPort": 0
    }
  ],
  "networkApplications": [
    {
      "id": "094be6d6-f654-44fd-a440-34dd75c80cd6",
      "instanceId": 11593,
      "authEntityId": 11593,
      "displayName": "11593",
      "authEntityClass": -217092956,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "appProtocol": "tcp/udp",
      "applicationGroup": "other",
      "applicationType": "DEFAULT",
      "categoryId": "80909d4c-efe6-4878-9e1e-68281fd26edd",
      "createTime": 1517966743191,
      "encrypted": "false",
      "engineId": "3",
      "helpString": "Internet Small Computer System Interface",
      "ignoreConflict": false,
      "lastUpdateTime": 1517966743191,
      "longDescription": "Internet Small Computer System Interface (iSCSI) is an IP-based storage networking standard for linking data storage facilities.",
      "name": "iscsi",
      "nbarId": "1449",
      "p2pTechnology": "false",
      "popularity": 7,
      "rank": 65535,
      "references": "http://www.ietf.org/rfc/rfc3720.txt",
      "selectorId": "860",
      "subCategory": "697101ea-4e72-4b89-872a-e2d494155c78",
      "trafficClass": "BULK_DATA",
      "tunnel": "false"
    }
  ],
  "networkIdentity": [
    {
      "id": "41058e9b-1a3e-45fd-8645-dbf3438fbcd3",
      "instanceId": 13611,
      "authEntityId": 13611,
      "displayName": "13611",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "860",
      "protocol": "TCP",
      "upperPort": 0
    },
    {
      "id": "e9b3c337-5ebb-482f-8ee8-444a9b7f92e9",
      "instanceId": 13612,
      "authEntityId": 13612,
      "displayName": "13612",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "860,3260",
      "protocol": "UDP",
      "upperPort": 0
    }
  ],
  "parentScalableGroup": {
    "idRef": "f09a36de-df38-4ff8-b0be-56738afc75f7"
  },
  "user": [

  ],
  "userGroup": [

  ]
},
{
  "id": "7e96a769-2cc1-45fc-adc8-38e80111199d",
  "instanceId": 4286,
  "authEntityId": 4286,
  "displayName": "4286",
  "authEntityClass": -1909073334,
  "deployPending": "NONE",
  "instanceVersion": 0,
  "createTime": 1517966742154,
  "deployed": false,
  "isSeeded": true,
  "isStale": false,
  "lastUpdateTime": 1517966742154,
  "name": "consumer-cloud-storage",
  "namespace": "scalablegroup:application",
  "provisioningState": "DEFINED",
  "qualifier": "application",
  "resourceVersion": 0,
  "targetIdList": [

  ],
  "type": "scalablegroup",
  "cfsChangeInfo": [

  ],
  "customProvisions": [

  ],
  "scalableGroupExternalHandle": "consumer-cloud-storage",
  "scalableGroupType": "APPLICATION",
  "securityGroupTag": 0,
  "state": "ACTIVE",
  "vnAgnostic": true,
  "identitySource": {
    "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
    "instanceId": 10010,
    "authEntityId": 10010,
    "displayName": "10010",
    "authEntityClass": 1738953278,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "state": "INACTIVE",
    "type": "NBAR"
  },
  "indicativeNetworkIdentity": [

  ],
  "networkApplications": [
    {
      "id": "e8f88c98-8a43-4fe7-97d2-2587d8b74305",
      "instanceId": 11239,
      "authEntityId": 11239,
      "displayName": "11239",
      "authEntityClass": -217092956,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "appProtocol": "tcp/udp",
      "applicationGroup": "other",
      "applicationType": "DEFAULT",
      "categoryId": "b33b2761-d981-4734-810a-a6a1c18ab352",
      "createTime": 1517966742154,
      "encrypted": "true",
      "engineId": "13",
      "helpString": "Top consumer cloud storage websites",
      "ignoreConflict": false,
      "lastUpdateTime": 1517966742154,
      "longDescription": "The Consumer Cloud Storage protocol gathers the leading websites on the internet that offer cloud storage services, mainly for media consumers, such as PutLocker, Rapidshare and more.",
      "name": "consumer-cloud-storage",
      "nbarId": "1521",
      "p2pTechnology": "false",
      "popularity": 10,
      "rank": 65535,
      "references": "http://www.rapidshare.com/,http://www.easyshare.com/,http://www.megashare.com/,http://www.ultrashare.net/,http://www.filefactory.com/,http://www.filefront.com/,http://www.massmirror.com/,http://www.filer.net/,http://www.upload.com/,http://www.download.com/,http://www.thepiratebay.org/,http://www.torrentz.com/,http://www.filestube.com/,http://www.4shared.com/,http://www.hotfile.com/,http://www.zshares.net/,http://www.mediafire.com/,http://www.easynews.com/,http://www.putlocker.com/,http://clouddrive.com",
      "selectorId": "582",
      "subCategory": "9fecfe24-2ee3-4b19-bbf7-3c992d1d25b4",
      "trafficClass": "BULK_DATA",
      "tunnel": "false"
    }
  ],
  "networkIdentity": [
    {
      "id": "a017d292-ecde-4650-b114-f7aa3bfcc62b",
      "instanceId": 12629,
      "authEntityId": 12629,
      "displayName": "12629",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "53,80,443,5353",
      "protocol": "TCP",
      "upperPort": 0
    },
    {
      "id": "17f41672-c731-4d72-a6f2-8871850a22a8",
      "instanceId": 12630,
      "authEntityId": 12630,
      "displayName": "12630",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "53,5353",
      "protocol": "UDP",
      "upperPort": 0
    }
  ],
  "parentScalableGroup": {
    "idRef": "7a1a3bbb-dc84-4a35-82a4-7e650c4c4761"
  },
  "user": [

  ],
  "userGroup": [

  ]
},
{
  "id": "7f028fb1-1ef3-4dff-bbca-804ec4f07ece",
  "instanceId": 15214,
  "authEntityId": 15214,
  "displayName": "15214",
  "authEntityClass": -1909073334,
  "deployPending": "NONE",
  "instanceVersion": 0,
  "createTime": 1517966744501,
  "deployed": false,
  "isSeeded": true,
  "isStale": false,
  "lastUpdateTime": 1517966744501,
  "name": "statistical-conf-audio",
  "namespace": "scalablegroup:application",
  "provisioningState": "DEFINED",
  "qualifier": "application",
  "resourceVersion": 0,
  "targetIdList": [

  ],
  "type": "scalablegroup",
  "cfsChangeInfo": [

  ],
  "customProvisions": [

  ],
  "scalableGroupExternalHandle": "statistical-conf-audio",
  "scalableGroupType": "APPLICATION",
  "securityGroupTag": 0,
  "state": "ACTIVE",
  "vnAgnostic": true,
  "identitySource": {
    "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
    "instanceId": 10010,
    "authEntityId": 10010,
    "displayName": "10010",
    "authEntityClass": 1738953278,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "state": "INACTIVE",
    "type": "NBAR"
  },
  "indicativeNetworkIdentity": [

  ],
  "networkApplications": [
    {
      "id": "afee005c-4179-4ca9-be85-018de4122ca1",
      "instanceId": 16157,
      "authEntityId": 16157,
      "displayName": "16157",
      "authEntityClass": -217092956,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "applicationGroup": "other",
      "applicationType": "DEFAULT",
      "categoryId": "6daa3dfd-3526-407a-88f0-97824b3f3a24",
      "createTime": 1517966744501,
      "encrypted": "unassigned",
      "engineId": "13",
      "helpString": "Statistical Conference Audio",
      "ignoreConflict": false,
      "lastUpdateTime": 1517966744501,
      "longDescription": "Statistical Conference Audio based on machine learning",
      "name": "statistical-conf-audio",
      "nbarId": "1801",
      "p2pTechnology": "unassigned",
      "popularity": 8,
      "rank": 65535,
      "selectorId": "1801",
      "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
      "trafficClass": "BULK_DATA",
      "tunnel": "unassigned"
    }
  ],
  "networkIdentity": [

  ],
  "parentScalableGroup": {
    "idRef": "000c15bf-28fe-4fbb-9b69-35e6502721f1"
  },
  "user": [

  ],
  "userGroup": [

  ]
},
{
  "id": "7f245eb6-50bb-4598-b291-9c6e5b4472c1",
  "instanceId": 15448,
  "authEntityId": 15448,
  "displayName": "15448",
  "authEntityClass": -1909073334,
  "deployPending": "NONE",
  "instanceVersion": 0,
  "createTime": 1517966744983,
  "deployed": false,
  "isSeeded": true,
  "isStale": false,
  "lastUpdateTime": 1517966744983,
  "name": "xunlei",
  "namespace": "scalablegroup:application",
  "provisioningState": "DEFINED",
  "qualifier": "application",
  "resourceVersion": 0,
  "targetIdList": [

  ],
  "type": "scalablegroup",
  "cfsChangeInfo": [

  ],
  "customProvisions": [

  ],
  "scalableGroupExternalHandle": "xunlei",
  "scalableGroupType": "APPLICATION",
  "securityGroupTag": 0,
  "state": "ACTIVE",
  "vnAgnostic": true,
  "identitySource": {
    "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
    "instanceId": 10010,
    "authEntityId": 10010,
    "displayName": "10010",
    "authEntityClass": 1738953278,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "state": "INACTIVE",
    "type": "NBAR"
  },
  "indicativeNetworkIdentity": [

  ],
  "networkApplications": [
    {
      "id": "aa90cf61-d830-42d8-8dbe-a59228a7cca5",
      "instanceId": 16391,
      "authEntityId": 16391,
      "displayName": "16391",
      "authEntityClass": -217092956,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "appProtocol": "tcp/udp",
      "applicationGroup": "xunlei-group",
      "applicationType": "DEFAULT",
      "categoryId": "b33b2761-d981-4734-810a-a6a1c18ab352",
      "createTime": 1517966744983,
      "encrypted": "false",
      "engineId": "13",
      "helpString": "Xunlei is a Chinese download manager and p2p client.",
      "ignoreConflict": false,
      "lastUpdateTime": 1517966744983,
      "longDescription": "Xunlei is a download manager and p2p sharing application. it supports bittorent, edonkey, http and ftp. xunlei also boost download speed using multi source technology.",
      "name": "xunlei",
      "nbarId": "1471",
      "p2pTechnology": "true",
      "popularity": 8,
      "rank": 65535,
      "references": "http://www.kankan.com/",
      "selectorId": "537",
      "subCategory": "79fe7198-9ce3-4c01-9b58-45a8df3d9513",
      "trafficClass": "BULK_DATA",
      "tunnel": "false"
    }
  ],
  "networkIdentity": [
    {
      "id": "98a69e3c-ad74-4f7a-ade1-82d8725b5017",
      "instanceId": 17929,
      "authEntityId": 17929,
      "displayName": "17929",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "53,5353",
      "protocol": "UDP",
      "upperPort": 0
    },
    {
      "id": "20057930-6c34-4bdc-8901-9731cfd2bb1c",
      "instanceId": 17930,
      "authEntityId": 17930,
      "displayName": "17930",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "53,80,5353",
      "protocol": "TCP",
      "upperPort": 0
    }
  ],
  "parentScalableGroup": {
    "idRef": "7a1a3bbb-dc84-4a35-82a4-7e650c4c4761"
  },
  "user": [

  ],
  "userGroup": [

  ]
},
{
  "id": "7f92c7ec-9304-46c2-b7e9-befdfeacb054",
  "instanceId": 4770,
  "authEntityId": 4770,
  "displayName": "4770",
  "authEntityClass": -1909073334,
  "deployPending": "NONE",
  "instanceVersion": 0,
  "createTime": 1517966743458,
  "deployed": false,
  "isSeeded": true,
  "isStale": false,
  "lastUpdateTime": 1517966743458,
  "name": "mrm",
  "namespace": "scalablegroup:application",
  "provisioningState": "DEFINED",
  "qualifier": "application",
  "resourceVersion": 0,
  "targetIdList": [

  ],
  "type": "scalablegroup",
  "cfsChangeInfo": [

  ],
  "customProvisions": [

  ],
  "scalableGroupExternalHandle": "mrm",
  "scalableGroupType": "APPLICATION",
  "securityGroupTag": 0,
  "state": "ACTIVE",
  "vnAgnostic": true,
  "identitySource": {
    "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
    "instanceId": 10010,
    "authEntityId": 10010,
    "displayName": "10010",
    "authEntityClass": 1738953278,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "state": "INACTIVE",
    "type": "NBAR"
  },
  "indicativeNetworkIdentity": [
    {
      "id": "3b1d0b59-2555-49ac-9143-1027d053a352",
      "instanceId": 13972,
      "authEntityId": 13972,
      "displayName": "13972",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "679",
      "protocol": "UDP",
      "upperPort": 0
    },
    {
      "id": "99c0481e-0418-4e67-afd9-7bb3493a02a4",
      "instanceId": 13973,
      "authEntityId": 13973,
      "displayName": "13973",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "679",
      "protocol": "TCP",
      "upperPort": 0
    }
  ],
  "networkApplications": [
    {
      "id": "bd02d8a2-b1cd-42d4-b31c-e9bfec41b8c9",
      "instanceId": 11720,
      "authEntityId": 11720,
      "displayName": "11720",
      "authEntityClass": -217092956,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "appProtocol": "tcp/udp",
      "applicationGroup": "other",
      "applicationType": "DEFAULT",
      "categoryId": "1fc17362-9334-4c7b-b752-5afdbb574017",
      "createTime": 1517966743458,
      "encrypted": "false",
      "engineId": "3",
      "helpString": "mrm",
      "ignoreConflict": false,
      "lastUpdateTime": 1517966743458,
      "longDescription": "The Multicast Routing Monitor (MRM) is a feature is Cisco products. It is a management diagnostic tool that provides network fault detection and isolation in a large multicast routing infrastructure. It is designed to notify a network administrator of multicast routing problems in near real time.",
      "name": "mrm",
      "nbarId": "587",
      "p2pTechnology": "false",
      "popularity": 1,
      "rank": 65535,
      "references": "http://www.cisco.com/en/US/docs/ios/12_1/iproute/configuration/guide/1cdtools.html",
      "selectorId": "679",
      "subCategory": "f3c0f761-d894-4112-ab89-b90fc84fd660",
      "trafficClass": "OPS_ADMIN_MGMT",
      "tunnel": "false"
    }
  ],
  "networkIdentity": [
    {
      "id": "10c9fce6-e638-4d6f-bb29-4944cde92c11",
      "instanceId": 13970,
      "authEntityId": 13970,
      "displayName": "13970",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "679",
      "protocol": "TCP",
      "upperPort": 0
    },
    {
      "id": "6db9f30e-133a-4f14-9865-2f903362e952",
      "instanceId": 13971,
      "authEntityId": 13971,
      "displayName": "13971",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "679",
      "protocol": "UDP",
      "upperPort": 0
    }
  ],
  "parentScalableGroup": {
    "idRef": "5fcd74d4-8726-47f7-a088-c457863da7ef"
  },
  "user": [

  ],
  "userGroup": [

  ]
},
{
  "id": "7fd7ea9a-d44e-481f-8e46-4c9f3d93edf4",
  "instanceId": 4458,
  "authEntityId": 4458,
  "displayName": "4458",
  "authEntityClass": -1909073334,
  "deployPending": "NONE",
  "instanceVersion": 0,
  "createTime": 1517966742721,
  "deployed": false,
  "isSeeded": true,
  "isStale": false,
  "lastUpdateTime": 1517966742721,
  "name": "flickr",
  "namespace": "scalablegroup:application",
  "provisioningState": "DEFINED",
  "qualifier": "application",
  "resourceVersion": 0,
  "targetIdList": [

  ],
  "type": "scalablegroup",
  "cfsChangeInfo": [

  ],
  "customProvisions": [

  ],
  "scalableGroupExternalHandle": "flickr",
  "scalableGroupType": "APPLICATION",
  "securityGroupTag": 0,
  "state": "ACTIVE",
  "vnAgnostic": true,
  "identitySource": {
    "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
    "instanceId": 10010,
    "authEntityId": 10010,
    "displayName": "10010",
    "authEntityClass": 1738953278,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "state": "INACTIVE",
    "type": "NBAR"
  },
  "indicativeNetworkIdentity": [

  ],
  "networkApplications": [
    {
      "id": "2d915ef5-dddc-4a0c-a4c1-45b76806c94e",
      "instanceId": 11409,
      "authEntityId": 11409,
      "displayName": "11409",
      "authEntityClass": -217092956,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "appProtocol": "tcp/udp",
      "applicationGroup": "other",
      "applicationType": "DEFAULT",
      "categoryId": "878644b5-fe91-45b4-9204-31c84a73ba48",
      "createTime": 1517966742721,
      "encrypted": "false",
      "engineId": "13",
      "helpString": "Flickr - Image and video hosting website, web services and online community",
      "ignoreConflict": false,
      "lastUpdateTime": 1517966742721,
      "longDescription": "Flickr - Image and video hosting website, web services and online community",
      "name": "flickr",
      "nbarId": "1570",
      "p2pTechnology": "false",
      "popularity": 3,
      "rank": 65535,
      "references": "http://flickr.com",
      "selectorId": "641",
      "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
      "trafficClass": "TRANSACTIONAL_DATA",
      "tunnel": "false"
    }
  ],
  "networkIdentity": [
    {
      "id": "15e769d4-a254-4656-9f22-a3143d689644",
      "instanceId": 13139,
      "authEntityId": 13139,
      "displayName": "13139",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "53,80,443,5353",
      "protocol": "TCP",
      "upperPort": 0
    },
    {
      "id": "f7e8af17-e254-408b-8075-e3378238057b",
      "instanceId": 13140,
      "authEntityId": 13140,
      "displayName": "13140",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "53,5353",
      "protocol": "UDP",
      "upperPort": 0
    }
  ],
  "parentScalableGroup": {
    "idRef": "28e08de4-d7b2-4521-84ce-bc1e5ee0b9cc"
  },
  "user": [

  ],
  "userGroup": [

  ]
},
{
  "id": "8008db1f-ccc0-4a66-82fb-553c3814b3d7",
  "instanceId": 15412,
  "authEntityId": 15412,
  "displayName": "15412",
  "authEntityClass": -1909073334,
  "deployPending": "NONE",
  "instanceVersion": 0,
  "createTime": 1517966744920,
  "deployed": false,
  "isSeeded": true,
  "isStale": false,
  "lastUpdateTime": 1517966744920,
  "name": "whitepages",
  "namespace": "scalablegroup:application",
  "provisioningState": "DEFINED",
  "qualifier": "application",
  "resourceVersion": 0,
  "targetIdList": [

  ],
  "type": "scalablegroup",
  "cfsChangeInfo": [

  ],
  "customProvisions": [

  ],
  "scalableGroupExternalHandle": "whitepages",
  "scalableGroupType": "APPLICATION",
  "securityGroupTag": 0,
  "state": "ACTIVE",
  "vnAgnostic": true,
  "identitySource": {
    "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
    "instanceId": 10010,
    "authEntityId": 10010,
    "displayName": "10010",
    "authEntityClass": 1738953278,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "state": "INACTIVE",
    "type": "NBAR"
  },
  "indicativeNetworkIdentity": [

  ],
  "networkApplications": [
    {
      "id": "e151fbde-099e-4529-97b8-0f01b37d8359",
      "instanceId": 16355,
      "authEntityId": 16355,
      "displayName": "16355",
      "authEntityClass": -217092956,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "appProtocol": "tcp/udp",
      "applicationGroup": "other",
      "applicationType": "DEFAULT",
      "categoryId": "878644b5-fe91-45b4-9204-31c84a73ba48",
      "createTime": 1517966744920,
      "encrypted": "false",
      "engineId": "13",
      "helpString": "WhitePages Inc - Business and Peoples Contact directory in United States.",
      "ignoreConflict": false,
      "lastUpdateTime": 1517966744920,
      "longDescription": "WhitePages Inc - Business and Peoples Contact directory in United States.",
      "name": "whitepages",
      "nbarId": "1735",
      "p2pTechnology": "false",
      "popularity": 3,
      "rank": 65535,
      "references": "http://whitepages.com",
      "selectorId": "807",
      "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
      "trafficClass": "TRANSACTIONAL_DATA",
      "tunnel": "false"
    }
  ],
  "networkIdentity": [
    {
      "id": "28c8382a-320d-46f9-a900-1983d332f2fa",
      "instanceId": 17833,
      "authEntityId": 17833,
      "displayName": "17833",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "53,5353",
      "protocol": "UDP",
      "upperPort": 0
    },
    {
      "id": "e936ff8f-abc2-47f7-ba38-4b1280c5a749",
      "instanceId": 17834,
      "authEntityId": 17834,
      "displayName": "17834",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "53,80,443,5353",
      "protocol": "TCP",
      "upperPort": 0
    }
  ],
  "parentScalableGroup": {
    "idRef": "28e08de4-d7b2-4521-84ce-bc1e5ee0b9cc"
  },
  "user": [

  ],
  "userGroup": [

  ]
},
{
  "id": "80163152-92ae-456f-bb1e-a64ec5f65f0b",
  "instanceId": 4071,
  "authEntityId": 4071,
  "displayName": "4071",
  "authEntityClass": -1909073334,
  "deployPending": "NONE",
  "instanceVersion": 0,
  "createTime": 1517966740835,
  "deployed": false,
  "isSeeded": true,
  "isStale": false,
  "lastUpdateTime": 1517966740835,
  "name": "aliwangwang",
  "namespace": "scalablegroup:application",
  "provisioningState": "DEFINED",
  "qualifier": "application",
  "resourceVersion": 0,
  "targetIdList": [

  ],
  "type": "scalablegroup",
  "cfsChangeInfo": [

  ],
  "customProvisions": [

  ],
  "scalableGroupExternalHandle": "aliwangwang",
  "scalableGroupType": "APPLICATION",
  "securityGroupTag": 0,
  "state": "ACTIVE",
  "vnAgnostic": true,
  "identitySource": {
    "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
    "instanceId": 10010,
    "authEntityId": 10010,
    "displayName": "10010",
    "authEntityClass": 1738953278,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "state": "INACTIVE",
    "type": "NBAR"
  },
  "indicativeNetworkIdentity": [

  ],
  "networkApplications": [
    {
      "id": "e7bef1cc-34ab-414d-acbb-bad331c7f19c",
      "instanceId": 11039,
      "authEntityId": 11039,
      "displayName": "11039",
      "authEntityClass": -217092956,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "appProtocol": "tcp/udp",
      "applicationGroup": "other",
      "applicationType": "DEFAULT",
      "categoryId": "0f6cab47-be95-4124-9db3-360ccd55f983",
      "createTime": 1517966740835,
      "encrypted": "true",
      "engineId": "13",
      "helpString": "AliWangwang instant messaging service for Alibaba members",
      "ignoreConflict": false,
      "lastUpdateTime": 1517966740835,
      "longDescription": "AliWangwang is a free instant messenger for Alibaba.com and Taobao.com members. It allows text, voice and video chat between buyers and sellers who use Alibaba web e-commerce services. The English version of AliWangwang is called TradeManager.",
      "name": "aliwangwang",
      "nbarId": "1520",
      "p2pTechnology": "false",
      "popularity": 7,
      "rank": 65535,
      "references": "http://en.wikipedia.org/wiki/Taobao",
      "selectorId": "581",
      "subCategory": "118c10a2-e272-47c1-92b9-4814ebe97138",
      "trafficClass": "TRANSACTIONAL_DATA",
      "tunnel": "false"
    }
  ],
  "networkIdentity": [
    {
      "id": "07a6fa01-5ccb-4c40-a582-c8ac9f6f5a4b",
      "instanceId": 12096,
      "authEntityId": 12096,
      "displayName": "12096",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "53,80,443,3478,5353",
      "protocol": "TCP",
      "upperPort": 0
    },
    {
      "id": "497b0bc1-7cb0-459e-8d18-71815eb9cfc4",
      "instanceId": 12097,
      "authEntityId": 12097,
      "displayName": "12097",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "53,3478,5353",
      "protocol": "UDP",
      "upperPort": 0
    }
  ],
  "parentScalableGroup": {
    "idRef": "fd6264c9-c16a-4311-a335-a3db039256f3"
  },
  "user": [

  ],
  "userGroup": [

  ]
},
{
  "id": "801d5723-ad36-4e3a-b211-14a33fcd9e04",
  "instanceId": 4917,
  "authEntityId": 4917,
  "displayName": "4917",
  "authEntityClass": -1909073334,
  "deployPending": "NONE",
  "instanceVersion": 0,
  "createTime": 1517966743759,
  "deployed": false,
  "isSeeded": true,
  "isStale": false,
  "lastUpdateTime": 1517966743759,
  "name": "ora-srv",
  "namespace": "scalablegroup:application",
  "provisioningState": "DEFINED",
  "qualifier": "application",
  "resourceVersion": 0,
  "targetIdList": [

  ],
  "type": "scalablegroup",
  "cfsChangeInfo": [

  ],
  "customProvisions": [

  ],
  "scalableGroupExternalHandle": "ora-srv",
  "scalableGroupType": "APPLICATION",
  "securityGroupTag": 0,
  "state": "ACTIVE",
  "vnAgnostic": true,
  "identitySource": {
    "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
    "instanceId": 10010,
    "authEntityId": 10010,
    "displayName": "10010",
    "authEntityClass": 1738953278,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "state": "INACTIVE",
    "type": "NBAR"
  },
  "indicativeNetworkIdentity": [
    {
      "id": "d2541960-da0c-45ae-9f76-f4eda89d9166",
      "instanceId": 14418,
      "authEntityId": 14418,
      "displayName": "14418",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "1525",
      "protocol": "TCP",
      "upperPort": 0
    },
    {
      "id": "f4cd1a8a-77f7-467d-9137-da98726b716c",
      "instanceId": 14419,
      "authEntityId": 14419,
      "displayName": "14419",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "1525",
      "protocol": "UDP",
      "upperPort": 0
    }
  ],
  "networkApplications": [
    {
      "id": "8c920c12-212a-4846-b35d-fc50b59cc583",
      "instanceId": 11866,
      "authEntityId": 11866,
      "displayName": "11866",
      "authEntityClass": -217092956,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "appProtocol": "tcp/udp",
      "applicationGroup": "sqlsvr-group",
      "applicationType": "DEFAULT",
      "categoryId": "cb5915d1-afec-4c30-ac9e-069ff382272f",
      "createTime": 1517966743759,
      "encrypted": "false",
      "engineId": "3",
      "helpString": "Oracle TCP/IP Listener",
      "ignoreConflict": false,
      "lastUpdateTime": 1517966743759,
      "longDescription": "Registered with IANA on port 1525 TCP/UDP",
      "name": "ora-srv",
      "nbarId": "89",
      "p2pTechnology": "false",
      "popularity": 1,
      "rank": 65535,
      "references": "http://www.iana.org/assignments/service-names-port-numbers/service-names-port-numbers.xml",
      "selectorId": "1525",
      "subCategory": "b90786f4-0190-45b8-9924-21b3e9682590",
      "trafficClass": "TRANSACTIONAL_DATA",
      "tunnel": "false"
    }
  ],
  "networkIdentity": [
    {
      "id": "ba34f7b1-b08d-4918-b4f3-f832b8d0bc8a",
      "instanceId": 14416,
      "authEntityId": 14416,
      "displayName": "14416",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "1525",
      "protocol": "TCP",
      "upperPort": 0
    },
    {
      "id": "90dc8a9d-b349-455f-936c-36a554daaf3b",
      "instanceId": 14417,
      "authEntityId": 14417,
      "displayName": "14417",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "1525",
      "protocol": "UDP",
      "upperPort": 0
    }
  ],
  "parentScalableGroup": {
    "idRef": "8718395e-683e-4a2d-a232-100202751271"
  },
  "user": [

  ],
  "userGroup": [

  ]
},
{
  "id": "805fee22-e5b5-4427-ada7-e02373092250",
  "instanceId": 4303,
  "authEntityId": 4303,
  "displayName": "4303",
  "authEntityClass": -1909073334,
  "deployPending": "NONE",
  "instanceVersion": 0,
  "createTime": 1517966742214,
  "deployed": false,
  "isSeeded": true,
  "isStale": false,
  "lastUpdateTime": 1517966742214,
  "name": "crs",
  "namespace": "scalablegroup:application",
  "provisioningState": "DEFINED",
  "qualifier": "application",
  "resourceVersion": 0,
  "targetIdList": [

  ],
  "type": "scalablegroup",
  "cfsChangeInfo": [

  ],
  "customProvisions": [

  ],
  "scalableGroupExternalHandle": "crs",
  "scalableGroupType": "APPLICATION",
  "securityGroupTag": 0,
  "state": "ACTIVE",
  "vnAgnostic": true,
  "identitySource": {
    "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
    "instanceId": 10010,
    "authEntityId": 10010,
    "displayName": "10010",
    "authEntityClass": 1738953278,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "state": "INACTIVE",
    "type": "NBAR"
  },
  "indicativeNetworkIdentity": [
    {
      "id": "98b4908b-a582-41e7-b4e2-05e0c4fc83e2",
      "instanceId": 12682,
      "authEntityId": 12682,
      "displayName": "12682",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "507",
      "protocol": "TCP",
      "upperPort": 0
    },
    {
      "id": "c17f3e56-d2ce-43b2-8e6d-953285745a4c",
      "instanceId": 12683,
      "authEntityId": 12683,
      "displayName": "12683",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "507",
      "protocol": "UDP",
      "upperPort": 0
    }
  ],
  "networkApplications": [
    {
      "id": "3196c285-7c9f-4414-95c1-96d7917c281c",
      "instanceId": 11256,
      "authEntityId": 11256,
      "displayName": "11256",
      "authEntityClass": -217092956,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "appProtocol": "tcp/udp",
      "applicationGroup": "other",
      "applicationType": "DEFAULT",
      "categoryId": "1fc17362-9334-4c7b-b752-5afdbb574017",
      "createTime": 1517966742214,
      "encrypted": "false",
      "engineId": "3",
      "helpString": "crs",
      "ignoreConflict": false,
      "lastUpdateTime": 1517966742214,
      "longDescription": "Registered with IANA on port 507 TCP/UDP",
      "name": "crs",
      "nbarId": "421",
      "p2pTechnology": "false",
      "popularity": 1,
      "rank": 65535,
      "references": "http://www.iana.org/assignments/service-names-port-numbers/service-names-port-numbers.xml",
      "selectorId": "507",
      "subCategory": "e390124a-0fd6-4253-b68e-4b0c1af8cb4b",
      "trafficClass": "OPS_ADMIN_MGMT",
      "tunnel": "false"
    }
  ],
  "networkIdentity": [
    {
      "id": "21911114-bbae-41ba-b5a2-101009fcbcfd",
      "instanceId": 12680,
      "authEntityId": 12680,
      "displayName": "12680",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "507",
      "protocol": "TCP",
      "upperPort": 0
    },
    {
      "id": "c5321259-53b6-4713-81eb-b29e646fed24",
      "instanceId": 12681,
      "authEntityId": 12681,
      "displayName": "12681",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "507",
      "protocol": "UDP",
      "upperPort": 0
    }
  ],
  "parentScalableGroup": {
    "idRef": "7788bf2d-e289-4597-b500-17aacb7e820e"
  },
  "user": [

  ],
  "userGroup": [

  ]
},
{
  "id": "8072edf7-6f48-41ba-a8fb-74508a05c92d",
  "instanceId": 15149,
  "authEntityId": 15149,
  "displayName": "15149",
  "authEntityClass": -1909073334,
  "deployPending": "NONE",
  "instanceVersion": 0,
  "createTime": 1517966744346,
  "deployed": false,
  "isSeeded": true,
  "isStale": false,
  "lastUpdateTime": 1517966744346,
  "name": "sitaraserver",
  "namespace": "scalablegroup:application",
  "provisioningState": "DEFINED",
  "qualifier": "application",
  "resourceVersion": 0,
  "targetIdList": [

  ],
  "type": "scalablegroup",
  "cfsChangeInfo": [

  ],
  "customProvisions": [

  ],
  "scalableGroupExternalHandle": "sitaraserver",
  "scalableGroupType": "APPLICATION",
  "securityGroupTag": 0,
  "state": "ACTIVE",
  "vnAgnostic": true,
  "identitySource": {
    "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
    "instanceId": 10010,
    "authEntityId": 10010,
    "displayName": "10010",
    "authEntityClass": 1738953278,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "state": "INACTIVE",
    "type": "NBAR"
  },
  "indicativeNetworkIdentity": [
    {
      "id": "a2234c73-e233-46c6-a59d-a94cc8624549",
      "instanceId": 17100,
      "authEntityId": 17100,
      "displayName": "17100",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "2629",
      "protocol": "TCP",
      "upperPort": 0
    },
    {
      "id": "8d0cf486-c9a2-4c76-b89b-502440d37a03",
      "instanceId": 17101,
      "authEntityId": 17101,
      "displayName": "17101",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "2629",
      "protocol": "UDP",
      "upperPort": 0
    }
  ],
  "networkApplications": [
    {
      "id": "3116473d-4f72-40b3-9ab3-be84f2bfa266",
      "instanceId": 16092,
      "authEntityId": 16092,
      "displayName": "16092",
      "authEntityClass": -217092956,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "appProtocol": "tcp/udp",
      "applicationGroup": "other",
      "applicationType": "DEFAULT",
      "categoryId": "6daa3dfd-3526-407a-88f0-97824b3f3a24",
      "createTime": 1517966744346,
      "encrypted": "false",
      "engineId": "3",
      "helpString": "sitaraserver",
      "ignoreConflict": false,
      "lastUpdateTime": 1517966744346,
      "longDescription": "The Sitara Network Protocol (SNP) addresses performance problems over the Internet, intranets, and extranet, providing a higher quality of service to traffic between the end points of a Sitara-enhanced connection.",
      "name": "sitaraserver",
      "nbarId": "708",
      "p2pTechnology": "false",
      "popularity": 1,
      "rank": 65535,
      "references": "http://www.dejean.com/mc/sitara/tech_snp-paper.html",
      "selectorId": "2629",
      "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
      "trafficClass": "BULK_DATA",
      "tunnel": "false"
    }
  ],
  "networkIdentity": [
    {
      "id": "20d59b2e-80cf-45bb-af1b-22eaeb068d78",
      "instanceId": 17098,
      "authEntityId": 17098,
      "displayName": "17098",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "2629",
      "protocol": "UDP",
      "upperPort": 0
    },
    {
      "id": "812c2d3f-b7fb-4477-8a81-cf0b1a63c129",
      "instanceId": 17099,
      "authEntityId": 17099,
      "displayName": "17099",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "2629",
      "protocol": "TCP",
      "upperPort": 0
    }
  ],
  "parentScalableGroup": {
    "idRef": "000c15bf-28fe-4fbb-9b69-35e6502721f1"
  },
  "user": [

  ],
  "userGroup": [

  ]
},
{
  "id": "810fe4fe-3acb-4f29-a5fd-96367f352548",
  "instanceId": 15309,
  "authEntityId": 15309,
  "displayName": "15309",
  "authEntityClass": -1909073334,
  "deployPending": "NONE",
  "instanceVersion": 0,
  "createTime": 1517966744713,
  "deployed": false,
  "isSeeded": true,
  "isStale": false,
  "lastUpdateTime": 1517966744713,
  "name": "tunnel-http",
  "namespace": "scalablegroup:application",
  "provisioningState": "DEFINED",
  "qualifier": "application",
  "resourceVersion": 0,
  "targetIdList": [

  ],
  "type": "scalablegroup",
  "cfsChangeInfo": [

  ],
  "customProvisions": [

  ],
  "scalableGroupExternalHandle": "tunnel-http",
  "scalableGroupType": "APPLICATION",
  "securityGroupTag": 0,
  "state": "ACTIVE",
  "vnAgnostic": true,
  "identitySource": {
    "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
    "instanceId": 10010,
    "authEntityId": 10010,
    "displayName": "10010",
    "authEntityClass": 1738953278,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "state": "INACTIVE",
    "type": "NBAR"
  },
  "indicativeNetworkIdentity": [

  ],
  "networkApplications": [
    {
      "id": "7c581bad-05db-4787-b4f7-b426ef71d119",
      "instanceId": 16252,
      "authEntityId": 16252,
      "displayName": "16252",
      "authEntityClass": -217092956,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "appProtocol": "tcp",
      "applicationGroup": "other",
      "applicationType": "DEFAULT",
      "categoryId": "cf6b10c2-40a5-4a1c-857c-4b6e0caae271",
      "createTime": 1517966744713,
      "encrypted": "false",
      "engineId": "13",
      "helpString": "HTTP Tunneling",
      "ignoreConflict": false,
      "lastUpdateTime": 1517966744713,
      "longDescription": "Tunnel HTTP represents the granular protocols that are tunneled over HTTP.",
      "name": "tunnel-http",
      "nbarId": "739",
      "p2pTechnology": "false",
      "popularity": 4,
      "rank": 65535,
      "references": "N/A",
      "selectorId": "435",
      "subCategory": "ab63e736-9aa7-4946-b55b-3b26ddca609c",
      "trafficClass": "BULK_DATA",
      "tunnel": "true"
    }
  ],
  "networkIdentity": [
    {
      "id": "9cf1a801-a988-4935-b184-977ba0cacb5f",
      "instanceId": 17548,
      "authEntityId": 17548,
      "displayName": "17548",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "80",
      "protocol": "TCP",
      "upperPort": 0
    }
  ],
  "parentScalableGroup": {
    "idRef": "79f9d505-477f-4ccd-bd2a-c5fc8097a2e1"
  },
  "user": [

  ],
  "userGroup": [

  ]
},
{
  "id": "812ffb5d-0244-4fc5-adc3-5f6c25e59b42",
  "instanceId": 4990,
  "authEntityId": 4990,
  "displayName": "4990",
  "authEntityClass": -1909073334,
  "deployPending": "NONE",
  "instanceVersion": 0,
  "createTime": 1517966743991,
  "deployed": false,
  "isSeeded": true,
  "isStale": false,
  "lastUpdateTime": 1517966743991,
  "name": "proxy-server",
  "namespace": "scalablegroup:application",
  "provisioningState": "DEFINED",
  "qualifier": "application",
  "resourceVersion": 0,
  "targetIdList": [

  ],
  "type": "scalablegroup",
  "cfsChangeInfo": [

  ],
  "customProvisions": [

  ],
  "scalableGroupExternalHandle": "proxy-server",
  "scalableGroupType": "APPLICATION",
  "securityGroupTag": 0,
  "state": "ACTIVE",
  "vnAgnostic": true,
  "identitySource": {
    "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
    "instanceId": 10010,
    "authEntityId": 10010,
    "displayName": "10010",
    "authEntityClass": 1738953278,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "state": "INACTIVE",
    "type": "NBAR"
  },
  "indicativeNetworkIdentity": [

  ],
  "networkApplications": [
    {
      "id": "c26b3c3e-0e79-4adb-a53f-ce3a43e89452",
      "instanceId": 11939,
      "authEntityId": 11939,
      "displayName": "11939",
      "authEntityClass": -217092956,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "appProtocol": "tcp/udp",
      "applicationGroup": "other",
      "applicationType": "DEFAULT",
      "categoryId": "6daa3dfd-3526-407a-88f0-97824b3f3a24",
      "createTime": 1517966743991,
      "encrypted": "false",
      "engineId": "13",
      "helpString": "Proxy server",
      "ignoreConflict": false,
      "lastUpdateTime": 1517966743991,
      "longDescription": "Proxy server is a server that acts as an intermediary for requests from clients seeking resources from other servers.",
      "name": "proxy-server",
      "nbarId": "1080",
      "p2pTechnology": "false",
      "popularity": 10,
      "rank": 65535,
      "references": "https://en.wikipedia.org/wiki/Proxy_server",
      "selectorId": "1080",
      "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
      "trafficClass": "BULK_DATA",
      "tunnel": "false"
    }
  ],
  "networkIdentity": [
    {
      "id": "ebd31489-725a-45b5-b62d-524b00a18aea",
      "instanceId": 14624,
      "authEntityId": 14624,
      "displayName": "14624",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "53,67,68,5353",
      "protocol": "UDP",
      "upperPort": 0
    },
    {
      "id": "dfd9e184-05d2-465b-aa59-a52ce4ecad41",
      "instanceId": 14625,
      "authEntityId": 14625,
      "displayName": "14625",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "53,80,5353",
      "protocol": "TCP",
      "upperPort": 0
    }
  ],
  "parentScalableGroup": {
    "idRef": "000c15bf-28fe-4fbb-9b69-35e6502721f1"
  },
  "user": [

  ],
  "userGroup": [

  ]
},
{
  "id": "813ae8a5-3452-428c-bee6-601edc10df44",
  "instanceId": 4606,
  "authEntityId": 4606,
  "displayName": "4606",
  "authEntityClass": -1909073334,
  "deployPending": "NONE",
  "instanceVersion": 0,
  "createTime": 1517966743105,
  "deployed": false,
  "isSeeded": true,
  "isStale": false,
  "lastUpdateTime": 1517966743105,
  "name": "instagram",
  "namespace": "scalablegroup:application",
  "provisioningState": "DEFINED",
  "qualifier": "application",
  "resourceVersion": 0,
  "targetIdList": [

  ],
  "type": "scalablegroup",
  "cfsChangeInfo": [

  ],
  "customProvisions": [

  ],
  "scalableGroupExternalHandle": "instagram",
  "scalableGroupType": "APPLICATION",
  "securityGroupTag": 0,
  "state": "ACTIVE",
  "vnAgnostic": true,
  "identitySource": {
    "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
    "instanceId": 10010,
    "authEntityId": 10010,
    "displayName": "10010",
    "authEntityClass": 1738953278,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "state": "INACTIVE",
    "type": "NBAR"
  },
  "indicativeNetworkIdentity": [

  ],
  "networkApplications": [
    {
      "id": "5e313ab0-c365-437d-9cac-1ef2c0a9f4ec",
      "instanceId": 11556,
      "authEntityId": 11556,
      "displayName": "11556",
      "authEntityClass": -217092956,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "appProtocol": "tcp/udp",
      "applicationGroup": "other",
      "applicationType": "DEFAULT",
      "categoryId": "9eb6d680-796f-478a-9106-1151f00f7eff",
      "createTime": 1517966743105,
      "encrypted": "true",
      "engineId": "13",
      "helpString": "Social network for sharing photos and videos",
      "ignoreConflict": false,
      "lastUpdateTime": 1517966743105,
      "longDescription": "Instagram is an online photo-sharing, video-sharing and social networking service that enables its users to take pictures and videos, apply digital filters to them, and share them on a variety of social networking services.",
      "name": "instagram",
      "nbarId": "1537",
      "p2pTechnology": "false",
      "popularity": 10,
      "rank": 65535,
      "references": "http://instagram.com/",
      "selectorId": "598",
      "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
      "trafficClass": "TRANSACTIONAL_DATA",
      "tunnel": "false"
    }
  ],
  "networkIdentity": [
    {
      "id": "2c229499-068b-4e81-bd3a-1863b8596c95",
      "instanceId": 13529,
      "authEntityId": 13529,
      "displayName": "13529",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "53,5353",
      "protocol": "UDP",
      "upperPort": 0
    },
    {
      "id": "ddf80b54-49cc-47f5-af86-33103adcc16d",
      "instanceId": 13530,
      "authEntityId": 13530,
      "displayName": "13530",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "53,80,443,5353",
      "protocol": "TCP",
      "upperPort": 0
    }
  ],
  "parentScalableGroup": {
    "idRef": "82348eb1-d8b8-4daf-8532-15e1a5f9cdd0"
  },
  "user": [

  ],
  "userGroup": [

  ]
},
{
  "id": "814fd0ba-e4bc-45ff-bdf6-0752b0a8a0b3",
  "instanceId": 4414,
  "authEntityId": 4414,
  "displayName": "4414",
  "authEntityClass": -1909073334,
  "deployPending": "NONE",
  "instanceVersion": 0,
  "createTime": 1517966742576,
  "deployed": false,
  "isSeeded": true,
  "isStale": false,
  "lastUpdateTime": 1517966742576,
  "name": "entrust-aaas",
  "namespace": "scalablegroup:application",
  "provisioningState": "DEFINED",
  "qualifier": "application",
  "resourceVersion": 0,
  "targetIdList": [

  ],
  "type": "scalablegroup",
  "cfsChangeInfo": [

  ],
  "customProvisions": [

  ],
  "scalableGroupExternalHandle": "entrust-aaas",
  "scalableGroupType": "APPLICATION",
  "securityGroupTag": 0,
  "state": "ACTIVE",
  "vnAgnostic": true,
  "identitySource": {
    "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
    "instanceId": 10010,
    "authEntityId": 10010,
    "displayName": "10010",
    "authEntityClass": 1738953278,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "state": "INACTIVE",
    "type": "NBAR"
  },
  "indicativeNetworkIdentity": [
    {
      "id": "6ab0d7d0-3bfa-43a5-8ed8-efedf79966f0",
      "instanceId": 13034,
      "authEntityId": 13034,
      "displayName": "13034",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "680",
      "protocol": "UDP",
      "upperPort": 0
    },
    {
      "id": "4897a179-f012-4fe0-87a0-727a4b6beb22",
      "instanceId": 13035,
      "authEntityId": 13035,
      "displayName": "13035",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "680",
      "protocol": "TCP",
      "upperPort": 0
    }
  ],
  "networkApplications": [
    {
      "id": "5a78aed2-41cf-439f-914f-807c85bed5a3",
      "instanceId": 11365,
      "authEntityId": 11365,
      "displayName": "11365",
      "authEntityClass": -217092956,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "appProtocol": "tcp/udp",
      "applicationGroup": "other",
      "applicationType": "DEFAULT",
      "categoryId": "1fc17362-9334-4c7b-b752-5afdbb574017",
      "createTime": 1517966742576,
      "encrypted": "false",
      "engineId": "3",
      "helpString": "entrust-aaas",
      "ignoreConflict": false,
      "lastUpdateTime": 1517966742576,
      "longDescription": "Registered with IANA on port 680 TCP/UDP",
      "name": "entrust-aaas",
      "nbarId": "588",
      "p2pTechnology": "false",
      "popularity": 1,
      "rank": 65535,
      "references": "http://www.iana.org/assignments/service-names-port-numbers/service-names-port-numbers.xml",
      "selectorId": "680",
      "subCategory": "e390124a-0fd6-4253-b68e-4b0c1af8cb4b",
      "trafficClass": "OPS_ADMIN_MGMT",
      "tunnel": "false"
    }
  ],
  "networkIdentity": [
    {
      "id": "c0fc4384-c91b-4b95-b0f4-5fa714c374f4",
      "instanceId": 13032,
      "authEntityId": 13032,
      "displayName": "13032",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "680",
      "protocol": "TCP",
      "upperPort": 0
    },
    {
      "id": "a721cfce-4ea4-48c6-b379-fade6d02657b",
      "instanceId": 13033,
      "authEntityId": 13033,
      "displayName": "13033",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "680",
      "protocol": "UDP",
      "upperPort": 0
    }
  ],
  "parentScalableGroup": {
    "idRef": "7788bf2d-e289-4597-b500-17aacb7e820e"
  },
  "user": [

  ],
  "userGroup": [

  ]
},
{
  "id": "81914fa8-dc6d-4db2-a85b-297a9445fc26",
  "instanceId": 15399,
  "authEntityId": 15399,
  "displayName": "15399",
  "authEntityClass": -1909073334,
  "deployPending": "NONE",
  "instanceVersion": 0,
  "createTime": 1517966744874,
  "deployed": false,
  "isSeeded": true,
  "isStale": false,
  "lastUpdateTime": 1517966744874,
  "name": "webex-app-sharing",
  "namespace": "scalablegroup:application",
  "provisioningState": "DEFINED",
  "qualifier": "application",
  "resourceVersion": 0,
  "targetIdList": [

  ],
  "type": "scalablegroup",
  "cfsChangeInfo": [

  ],
  "customProvisions": [

  ],
  "scalableGroupExternalHandle": "webex-app-sharing",
  "scalableGroupType": "APPLICATION",
  "securityGroupTag": 0,
  "state": "ACTIVE",
  "vnAgnostic": true,
  "identitySource": {
    "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
    "instanceId": 10010,
    "authEntityId": 10010,
    "displayName": "10010",
    "authEntityClass": 1738953278,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "state": "INACTIVE",
    "type": "NBAR"
  },
  "indicativeNetworkIdentity": [

  ],
  "networkApplications": [
    {
      "id": "bfb6b1b5-42e6-4237-a5e5-614a69bb1181",
      "instanceId": 16342,
      "authEntityId": 16342,
      "displayName": "16342",
      "authEntityClass": -217092956,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "appProtocol": "tcp/udp",
      "applicationGroup": "webex-group",
      "applicationType": "DEFAULT",
      "categoryId": "38a8e354-e2fe-4f3c-b1c5-2bbb90fb0d99",
      "createTime": 1517966744874,
      "encrypted": "true",
      "engineId": "13",
      "helpString": "Webex application sharing with HTTP-Proxy configured",
      "ignoreConflict": false,
      "lastUpdateTime": 1517966744874,
      "longDescription": "WebEx-App-Sharing is granular classification of WebEx protocol application sharing traffic, configured with HTTP-proxy.",
      "name": "webex-app-sharing",
      "nbarId": "1480",
      "p2pTechnology": "false",
      "popularity": 10,
      "rank": 65535,
      "references": "http://www.webex.com/",
      "selectorId": "546",
      "subCategory": "05178016-4555-4291-ab4e-43c2ab2a931a",
      "trafficClass": "TRANSACTIONAL_DATA",
      "tunnel": "false"
    }
  ],
  "networkIdentity": [
    {
      "id": "bd0e81e2-8f94-46b8-9e51-7bc6b90e2d64",
      "instanceId": 17805,
      "authEntityId": 17805,
      "displayName": "17805",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "53,80,443,5353",
      "protocol": "TCP",
      "upperPort": 0
    },
    {
      "id": "a94438cb-6d93-4ab0-b411-989341142592",
      "instanceId": 17806,
      "authEntityId": 17806,
      "displayName": "17806",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "53,5353,9000",
      "protocol": "UDP",
      "upperPort": 0
    }
  ],
  "parentScalableGroup": {
    "idRef": "60f1364d-2a02-4063-8ba1-07817abe0612"
  },
  "user": [

  ],
  "userGroup": [

  ]
},
{
  "id": "81c73c98-5ee7-4c61-b3a2-0d10eeb02813",
  "instanceId": 4463,
  "authEntityId": 4463,
  "displayName": "4463",
  "authEntityClass": -1909073334,
  "deployPending": "NONE",
  "instanceVersion": 0,
  "createTime": 1517966742734,
  "deployed": false,
  "isSeeded": true,
  "isStale": false,
  "lastUpdateTime": 1517966742734,
  "name": "fox-news",
  "namespace": "scalablegroup:application",
  "provisioningState": "DEFINED",
  "qualifier": "application",
  "resourceVersion": 0,
  "targetIdList": [

  ],
  "type": "scalablegroup",
  "cfsChangeInfo": [

  ],
  "customProvisions": [

  ],
  "scalableGroupExternalHandle": "fox-news",
  "scalableGroupType": "APPLICATION",
  "securityGroupTag": 0,
  "state": "ACTIVE",
  "vnAgnostic": true,
  "identitySource": {
    "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
    "instanceId": 10010,
    "authEntityId": 10010,
    "displayName": "10010",
    "authEntityClass": 1738953278,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "state": "INACTIVE",
    "type": "NBAR"
  },
  "indicativeNetworkIdentity": [

  ],
  "networkApplications": [
    {
      "id": "b0c865e8-90be-4177-851b-44ef9192ded7",
      "instanceId": 11414,
      "authEntityId": 11414,
      "displayName": "11414",
      "authEntityClass": -217092956,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "appProtocol": "tcp/udp",
      "applicationGroup": "other",
      "applicationType": "DEFAULT",
      "categoryId": "878644b5-fe91-45b4-9204-31c84a73ba48",
      "createTime": 1517966742734,
      "encrypted": "false",
      "engineId": "13",
      "helpString": "Fox News - Web Portal for news update.",
      "ignoreConflict": false,
      "lastUpdateTime": 1517966742734,
      "longDescription": "Fox News - Web Portal for news update.",
      "name": "fox-news",
      "nbarId": "1588",
      "p2pTechnology": "false",
      "popularity": 3,
      "rank": 65535,
      "references": "http://foxnews.com",
      "selectorId": "659",
      "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
      "trafficClass": "TRANSACTIONAL_DATA",
      "tunnel": "false"
    }
  ],
  "networkIdentity": [
    {
      "id": "b00595c5-cc17-4d36-b520-eec71aad90bc",
      "instanceId": 13153,
      "authEntityId": 13153,
      "displayName": "13153",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "53,80,443,5353",
      "protocol": "TCP",
      "upperPort": 0
    },
    {
      "id": "a7dedea9-3976-4d14-a03d-8617080f0d0b",
      "instanceId": 13154,
      "authEntityId": 13154,
      "displayName": "13154",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "53,5353",
      "protocol": "UDP",
      "upperPort": 0
    }
  ],
  "parentScalableGroup": {
    "idRef": "28e08de4-d7b2-4521-84ce-bc1e5ee0b9cc"
  },
  "user": [

  ],
  "userGroup": [

  ]
},
{
  "id": "82174e6c-5c69-4695-a59c-fea7dc714b2c",
  "instanceId": 15147,
  "authEntityId": 15147,
  "displayName": "15147",
  "authEntityClass": -1909073334,
  "deployPending": "NONE",
  "instanceVersion": 0,
  "createTime": 1517966744341,
  "deployed": false,
  "isSeeded": true,
  "isStale": false,
  "lastUpdateTime": 1517966744341,
  "name": "sitaradir",
  "namespace": "scalablegroup:application",
  "provisioningState": "DEFINED",
  "qualifier": "application",
  "resourceVersion": 0,
  "targetIdList": [

  ],
  "type": "scalablegroup",
  "cfsChangeInfo": [

  ],
  "customProvisions": [

  ],
  "scalableGroupExternalHandle": "sitaradir",
  "scalableGroupType": "APPLICATION",
  "securityGroupTag": 0,
  "state": "ACTIVE",
  "vnAgnostic": true,
  "identitySource": {
    "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
    "instanceId": 10010,
    "authEntityId": 10010,
    "displayName": "10010",
    "authEntityClass": 1738953278,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "state": "INACTIVE",
    "type": "NBAR"
  },
  "indicativeNetworkIdentity": [
    {
      "id": "4981d3ca-37c9-4c7b-9f5a-46a046cfc5a2",
      "instanceId": 17092,
      "authEntityId": 17092,
      "displayName": "17092",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "2631",
      "protocol": "TCP",
      "upperPort": 0
    },
    {
      "id": "1a369b72-ba77-49a5-890b-75bbb067ad80",
      "instanceId": 17093,
      "authEntityId": 17093,
      "displayName": "17093",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "2631",
      "protocol": "UDP",
      "upperPort": 0
    }
  ],
  "networkApplications": [
    {
      "id": "cdf1a9cd-2221-41bb-a84d-fdd4e9ba4c2a",
      "instanceId": 16090,
      "authEntityId": 16090,
      "displayName": "16090",
      "authEntityClass": -217092956,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "appProtocol": "tcp/udp",
      "applicationGroup": "other",
      "applicationType": "DEFAULT",
      "categoryId": "6daa3dfd-3526-407a-88f0-97824b3f3a24",
      "createTime": 1517966744341,
      "encrypted": "false",
      "engineId": "3",
      "helpString": "sitaradir",
      "ignoreConflict": false,
      "lastUpdateTime": 1517966744341,
      "longDescription": "The Sitara Network Protocol (SNP) addresses performance problems over the Internet, intranets, and extranet, providing a higher quality of service to traffic between the end points of a Sitara-enhanced connection.",
      "name": "sitaradir",
      "nbarId": "710",
      "p2pTechnology": "false",
      "popularity": 1,
      "rank": 65535,
      "references": "http://www.dejean.com/mc/sitara/tech_snp-paper.html",
      "selectorId": "2631",
      "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
      "trafficClass": "BULK_DATA",
      "tunnel": "false"
    }
  ],
  "networkIdentity": [
    {
      "id": "9dcc42b3-e63d-4c3b-b07b-6c03a9385720",
      "instanceId": 17090,
      "authEntityId": 17090,
      "displayName": "17090",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "2631",
      "protocol": "TCP",
      "upperPort": 0
    },
    {
      "id": "ca8a6634-b030-4ae8-ac59-927b3b033aae",
      "instanceId": 17091,
      "authEntityId": 17091,
      "displayName": "17091",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "2631",
      "protocol": "UDP",
      "upperPort": 0
    }
  ],
  "parentScalableGroup": {
    "idRef": "000c15bf-28fe-4fbb-9b69-35e6502721f1"
  },
  "user": [

  ],
  "userGroup": [

  ]
},
{
  "id": "82eb70d0-4c08-45d7-8b81-22b99e91349a",
  "instanceId": 4786,
  "authEntityId": 4786,
  "displayName": "4786",
  "authEntityClass": -1909073334,
  "deployPending": "NONE",
  "instanceVersion": 0,
  "createTime": 1517966743489,
  "deployed": false,
  "isSeeded": true,
  "isStale": false,
  "lastUpdateTime": 1517966743489,
  "name": "ms-rpc",
  "namespace": "scalablegroup:application",
  "provisioningState": "DEFINED",
  "qualifier": "application",
  "resourceVersion": 0,
  "targetIdList": [

  ],
  "type": "scalablegroup",
  "cfsChangeInfo": [

  ],
  "customProvisions": [

  ],
  "scalableGroupExternalHandle": "ms-rpc",
  "scalableGroupType": "APPLICATION",
  "securityGroupTag": 0,
  "state": "ACTIVE",
  "vnAgnostic": true,
  "identitySource": {
    "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
    "instanceId": 10010,
    "authEntityId": 10010,
    "displayName": "10010",
    "authEntityClass": 1738953278,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "state": "INACTIVE",
    "type": "NBAR"
  },
  "indicativeNetworkIdentity": [
    {
      "id": "6693b405-39dc-436f-a646-99e7d90105ef",
      "instanceId": 14003,
      "authEntityId": 14003,
      "displayName": "14003",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "135",
      "protocol": "TCP",
      "upperPort": 0
    }
  ],
  "networkApplications": [
    {
      "id": "d12fb3f2-5bb5-48e6-b925-ccf89ff9685e",
      "instanceId": 11735,
      "authEntityId": 11735,
      "displayName": "11735",
      "authEntityClass": -217092956,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "appProtocol": "tcp",
      "applicationGroup": "other",
      "applicationType": "DEFAULT",
      "categoryId": "58aa61dc-dd95-4a6d-9c7c-0ebf16da6dde",
      "createTime": 1517966743489,
      "encrypted": "false",
      "engineId": "13",
      "helpString": "Microsoft Remote Procedure Call",
      "ignoreConflict": false,
      "lastUpdateTime": 1517966743489,
      "longDescription": "Microsoft Remote Procedure Call (MS-RPC) is an interprocess communication (IPC) mechanism that enables data exchange and invocation of functionality residing in a different process. That process can be on the same computer, on the local area network (LAN) or across the Internet.",
      "name": "ms-rpc",
      "nbarId": "1310",
      "p2pTechnology": "false",
      "popularity": 9,
      "rank": 65535,
      "references": "http://technet.microsoft.com/en-us/library/cc958781.aspx",
      "selectorId": "1310",
      "subCategory": "b90786f4-0190-45b8-9924-21b3e9682590",
      "trafficClass": "TRANSACTIONAL_DATA",
      "tunnel": "false"
    }
  ],
  "networkIdentity": [
    {
      "id": "fbeed477-33e7-4875-8c9f-bc62d658202d",
      "instanceId": 14002,
      "authEntityId": 14002,
      "displayName": "14002",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "135",
      "protocol": "TCP",
      "upperPort": 0
    }
  ],
  "parentScalableGroup": {
    "idRef": "cff11752-fafb-4e22-9e6f-11f44f59a9a3"
  },
  "user": [

  ],
  "userGroup": [

  ]
},
{
  "id": "831660e0-f9d6-45f5-a510-7afcd4c6d5df",
  "instanceId": 15218,
  "authEntityId": 15218,
  "displayName": "15218",
  "authEntityClass": -1909073334,
  "deployPending": "NONE",
  "instanceVersion": 0,
  "createTime": 1517966744513,
  "deployed": false,
  "isSeeded": true,
  "isStale": false,
  "lastUpdateTime": 1517966744513,
  "name": "statsrv",
  "namespace": "scalablegroup:application",
  "provisioningState": "DEFINED",
  "qualifier": "application",
  "resourceVersion": 0,
  "targetIdList": [

  ],
  "type": "scalablegroup",
  "cfsChangeInfo": [

  ],
  "customProvisions": [

  ],
  "scalableGroupExternalHandle": "statsrv",
  "scalableGroupType": "APPLICATION",
  "securityGroupTag": 0,
  "state": "ACTIVE",
  "vnAgnostic": true,
  "identitySource": {
    "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
    "instanceId": 10010,
    "authEntityId": 10010,
    "displayName": "10010",
    "authEntityClass": 1738953278,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "state": "INACTIVE",
    "type": "NBAR"
  },
  "indicativeNetworkIdentity": [
    {
      "id": "bb37f74e-d5b6-432f-927d-3477b53b3437",
      "instanceId": 17282,
      "authEntityId": 17282,
      "displayName": "17282",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "133",
      "protocol": "TCP",
      "upperPort": 0
    },
    {
      "id": "38b87f94-45be-4706-9620-84968cf9ae44",
      "instanceId": 17283,
      "authEntityId": 17283,
      "displayName": "17283",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "133",
      "protocol": "UDP",
      "upperPort": 0
    }
  ],
  "networkApplications": [
    {
      "id": "f575ca2d-4f7e-4e62-9440-e4205f115b11",
      "instanceId": 16161,
      "authEntityId": 16161,
      "displayName": "16161",
      "authEntityClass": -217092956,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "appProtocol": "tcp/udp",
      "applicationGroup": "other",
      "applicationType": "DEFAULT",
      "categoryId": "1fc17362-9334-4c7b-b752-5afdbb574017",
      "createTime": 1517966744512,
      "encrypted": "false",
      "engineId": "3",
      "helpString": "Statistics Service",
      "ignoreConflict": false,
      "lastUpdateTime": 1517966744512,
      "longDescription": "The Statistics Server (STATSRV) protocol is intended as a lightweight mechanism similar in spirit to NETSTAT and complementary to it. STATSRV is designed to capture statistics data with minimal intrusion on existing systems or networks. It is intended for use with existing hosts and gateways primarily for casual monitoring and debugging purposes.",
      "name": "statsrv",
      "nbarId": "1162",
      "p2pTechnology": "false",
      "popularity": 1,
      "rank": 65535,
      "references": "http://tools.ietf.org/rfc/rfc996",
      "selectorId": "133",
      "subCategory": "f3c0f761-d894-4112-ab89-b90fc84fd660",
      "trafficClass": "OPS_ADMIN_MGMT",
      "tunnel": "false"
    }
  ],
  "networkIdentity": [
    {
      "id": "baa89fe3-783d-4a60-8136-da3c3884a481",
      "instanceId": 17280,
      "authEntityId": 17280,
      "displayName": "17280",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "133",
      "protocol": "UDP",
      "upperPort": 0
    },
    {
      "id": "3b0acc57-a848-43b3-8050-5357d509fc06",
      "instanceId": 17281,
      "authEntityId": 17281,
      "displayName": "17281",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "133",
      "protocol": "TCP",
      "upperPort": 0
    }
  ],
  "parentScalableGroup": {
    "idRef": "5fcd74d4-8726-47f7-a088-c457863da7ef"
  },
  "user": [

  ],
  "userGroup": [

  ]
},
{
  "id": "8375fd50-2722-4863-9f90-3ca490e04ca2",
  "instanceId": 4681,
  "authEntityId": 4681,
  "displayName": "4681",
  "authEntityClass": -1909073334,
  "deployPending": "NONE",
  "instanceVersion": 0,
  "createTime": 1517966743272,
  "deployed": false,
  "isSeeded": true,
  "isStale": false,
  "lastUpdateTime": 1517966743272,
  "name": "kryptolan",
  "namespace": "scalablegroup:application",
  "provisioningState": "DEFINED",
  "qualifier": "application",
  "resourceVersion": 0,
  "targetIdList": [

  ],
  "type": "scalablegroup",
  "cfsChangeInfo": [

  ],
  "customProvisions": [

  ],
  "scalableGroupExternalHandle": "kryptolan",
  "scalableGroupType": "APPLICATION",
  "securityGroupTag": 0,
  "state": "ACTIVE",
  "vnAgnostic": true,
  "identitySource": {
    "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
    "instanceId": 10010,
    "authEntityId": 10010,
    "displayName": "10010",
    "authEntityClass": 1738953278,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "state": "INACTIVE",
    "type": "NBAR"
  },
  "indicativeNetworkIdentity": [
    {
      "id": "8e2dc59a-6606-471d-8bb4-76a01af63eba",
      "instanceId": 13718,
      "authEntityId": 13718,
      "displayName": "13718",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "398",
      "protocol": "UDP",
      "upperPort": 0
    },
    {
      "id": "b17afaca-efb2-4e32-9e58-e5231ff9c6fc",
      "instanceId": 13719,
      "authEntityId": 13719,
      "displayName": "13719",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "398",
      "protocol": "TCP",
      "upperPort": 0
    }
  ],
  "networkApplications": [
    {
      "id": "5fb11ad2-e304-48af-8928-e3ee71b8b22d",
      "instanceId": 11631,
      "authEntityId": 11631,
      "displayName": "11631",
      "authEntityClass": -217092956,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "appProtocol": "tcp/udp",
      "applicationGroup": "other",
      "applicationType": "DEFAULT",
      "categoryId": "6daa3dfd-3526-407a-88f0-97824b3f3a24",
      "createTime": 1517966743272,
      "encrypted": "false",
      "engineId": "3",
      "helpString": "kryptolan",
      "ignoreConflict": false,
      "lastUpdateTime": 1517966743272,
      "longDescription": "LAN/WAN Krypto (LWK) is a infrastructure that provides two secure functions; transport protection and data object protection. It was developed by Sectra.",
      "name": "kryptolan",
      "nbarId": "313",
      "p2pTechnology": "false",
      "popularity": 1,
      "rank": 65535,
      "references": "http://liu.diva-portal.org/smash/get/diva2:16958/FULLTEXT01",
      "selectorId": "398",
      "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
      "trafficClass": "BULK_DATA",
      "tunnel": "false"
    }
  ],
  "networkIdentity": [
    {
      "id": "b084e831-f75d-4fa2-9d69-8a1e098d58a3",
      "instanceId": 13716,
      "authEntityId": 13716,
      "displayName": "13716",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "398",
      "protocol": "TCP",
      "upperPort": 0
    },
    {
      "id": "f998c371-32a7-4019-bba0-ba794f414fa6",
      "instanceId": 13717,
      "authEntityId": 13717,
      "displayName": "13717",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "398",
      "protocol": "UDP",
      "upperPort": 0
    }
  ],
  "parentScalableGroup": {
    "idRef": "000c15bf-28fe-4fbb-9b69-35e6502721f1"
  },
  "user": [

  ],
  "userGroup": [

  ]
},
{
  "id": "838456d1-1f5a-46e3-87bf-54d05cba0c8e",
  "instanceId": 4177,
  "authEntityId": 4177,
  "displayName": "4177",
  "authEntityClass": -1909073334,
  "deployPending": "NONE",
  "instanceVersion": 0,
  "createTime": 1517966741540,
  "deployed": false,
  "isSeeded": true,
  "isStale": false,
  "lastUpdateTime": 1517966741540,
  "name": "bhmds",
  "namespace": "scalablegroup:application",
  "provisioningState": "DEFINED",
  "qualifier": "application",
  "resourceVersion": 0,
  "targetIdList": [

  ],
  "type": "scalablegroup",
  "cfsChangeInfo": [

  ],
  "customProvisions": [

  ],
  "scalableGroupExternalHandle": "bhmds",
  "scalableGroupType": "APPLICATION",
  "securityGroupTag": 0,
  "state": "ACTIVE",
  "vnAgnostic": true,
  "identitySource": {
    "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
    "instanceId": 10010,
    "authEntityId": 10010,
    "displayName": "10010",
    "authEntityClass": 1738953278,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "state": "INACTIVE",
    "type": "NBAR"
  },
  "indicativeNetworkIdentity": [
    {
      "id": "751a9a15-1b53-4922-981d-86456d0dca45",
      "instanceId": 12384,
      "authEntityId": 12384,
      "displayName": "12384",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "310",
      "protocol": "UDP",
      "upperPort": 0
    },
    {
      "id": "9277be99-fc5b-4cda-a6f1-0c89fd99084f",
      "instanceId": 12385,
      "authEntityId": 12385,
      "displayName": "12385",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "310",
      "protocol": "TCP",
      "upperPort": 0
    }
  ],
  "networkApplications": [
    {
      "id": "5aafb54b-4f5c-4217-b5e2-f25f11cb5b46",
      "instanceId": 11136,
      "authEntityId": 11136,
      "displayName": "11136",
      "authEntityClass": -217092956,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "appProtocol": "tcp/udp",
      "applicationGroup": "other",
      "applicationType": "DEFAULT",
      "categoryId": "6daa3dfd-3526-407a-88f0-97824b3f3a24",
      "createTime": 1517966741540,
      "encrypted": "false",
      "engineId": "3",
      "helpString": "bhmds",
      "ignoreConflict": false,
      "lastUpdateTime": 1517966741540,
      "longDescription": "Registered with IANA on port 310 TCP/UDP",
      "name": "bhmds",
      "nbarId": "1150",
      "p2pTechnology": "false",
      "popularity": 1,
      "rank": 65535,
      "references": "http://www.iana.org/assignments/service-names-port-numbers/service-names-port-numbers.xml",
      "selectorId": "310",
      "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
      "trafficClass": "BULK_DATA",
      "tunnel": "false"
    }
  ],
  "networkIdentity": [
    {
      "id": "53d13a3e-4b90-4da0-ad5a-9a64c294183a",
      "instanceId": 12382,
      "authEntityId": 12382,
      "displayName": "12382",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "310",
      "protocol": "UDP",
      "upperPort": 0
    },
    {
      "id": "1eb472ee-004e-4e2e-8b84-98f4ae7fc033",
      "instanceId": 12383,
      "authEntityId": 12383,
      "displayName": "12383",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "310",
      "protocol": "TCP",
      "upperPort": 0
    }
  ],
  "parentScalableGroup": {
    "idRef": "000c15bf-28fe-4fbb-9b69-35e6502721f1"
  },
  "user": [

  ],
  "userGroup": [

  ]
},
{
  "id": "838fc795-be2a-44e7-b21a-c5747eedcc70",
  "instanceId": 15429,
  "authEntityId": 15429,
  "displayName": "15429",
  "authEntityClass": -1909073334,
  "deployPending": "NONE",
  "instanceVersion": 0,
  "createTime": 1517966744950,
  "deployed": false,
  "isSeeded": true,
  "isStale": false,
  "lastUpdateTime": 1517966744950,
  "name": "wunderground-com",
  "namespace": "scalablegroup:application",
  "provisioningState": "DEFINED",
  "qualifier": "application",
  "resourceVersion": 0,
  "targetIdList": [

  ],
  "type": "scalablegroup",
  "cfsChangeInfo": [

  ],
  "customProvisions": [

  ],
  "scalableGroupExternalHandle": "wunderground-com",
  "scalableGroupType": "APPLICATION",
  "securityGroupTag": 0,
  "state": "ACTIVE",
  "vnAgnostic": true,
  "identitySource": {
    "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
    "instanceId": 10010,
    "authEntityId": 10010,
    "displayName": "10010",
    "authEntityClass": 1738953278,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "state": "INACTIVE",
    "type": "NBAR"
  },
  "indicativeNetworkIdentity": [

  ],
  "networkApplications": [
    {
      "id": "1e7f4d45-14bc-43dd-9348-fc40f3f4ee6c",
      "instanceId": 16372,
      "authEntityId": 16372,
      "displayName": "16372",
      "authEntityClass": -217092956,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "appProtocol": "tcp/udp",
      "applicationGroup": "other",
      "applicationType": "DEFAULT",
      "categoryId": "878644b5-fe91-45b4-9204-31c84a73ba48",
      "createTime": 1517966744950,
      "encrypted": "false",
      "engineId": "13",
      "helpString": "Weather Underground - Weather web portal.",
      "ignoreConflict": false,
      "lastUpdateTime": 1517966744950,
      "longDescription": "Weather Underground - Weather web portal.",
      "name": "wunderground-com",
      "nbarId": "1632",
      "p2pTechnology": "false",
      "popularity": 3,
      "rank": 65535,
      "references": "http://wunderground.com",
      "selectorId": "702",
      "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
      "trafficClass": "TRANSACTIONAL_DATA",
      "tunnel": "false"
    }
  ],
  "networkIdentity": [
    {
      "id": "01affe0a-a137-48d3-ad09-96be4c711823",
      "instanceId": 17869,
      "authEntityId": 17869,
      "displayName": "17869",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "53,5353",
      "protocol": "UDP",
      "upperPort": 0
    },
    {
      "id": "8fb38cf0-2cfc-4c4c-b4f9-0a55adbba51e",
      "instanceId": 17870,
      "authEntityId": 17870,
      "displayName": "17870",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "53,80,443,5353",
      "protocol": "TCP",
      "upperPort": 0
    }
  ],
  "parentScalableGroup": {
    "idRef": "28e08de4-d7b2-4521-84ce-bc1e5ee0b9cc"
  },
  "user": [

  ],
  "userGroup": [

  ]
},
{
  "id": "839aafea-a6c7-48c2-b248-8bd252e95dea",
  "instanceId": 4669,
  "authEntityId": 4669,
  "displayName": "4669",
  "authEntityClass": -1909073334,
  "deployPending": "NONE",
  "instanceVersion": 0,
  "createTime": 1517966743246,
  "deployed": false,
  "isSeeded": true,
  "isStale": false,
  "lastUpdateTime": 1517966743246,
  "name": "kali",
  "namespace": "scalablegroup:application",
  "provisioningState": "DEFINED",
  "qualifier": "application",
  "resourceVersion": 0,
  "targetIdList": [

  ],
  "type": "scalablegroup",
  "cfsChangeInfo": [

  ],
  "customProvisions": [

  ],
  "scalableGroupExternalHandle": "kali",
  "scalableGroupType": "APPLICATION",
  "securityGroupTag": 0,
  "state": "ACTIVE",
  "vnAgnostic": true,
  "identitySource": {
    "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
    "instanceId": 10010,
    "authEntityId": 10010,
    "displayName": "10010",
    "authEntityClass": 1738953278,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "state": "INACTIVE",
    "type": "NBAR"
  },
  "indicativeNetworkIdentity": [
    {
      "id": "54fe0ebc-1add-47d3-ab7b-12e953d83f4f",
      "instanceId": 13680,
      "authEntityId": 13680,
      "displayName": "13680",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "2213",
      "protocol": "UDP",
      "upperPort": 0
    },
    {
      "id": "f763b4db-c6c6-4304-b581-e5edbee4926b",
      "instanceId": 13681,
      "authEntityId": 13681,
      "displayName": "13681",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "2213",
      "protocol": "TCP",
      "upperPort": 0
    }
  ],
  "networkApplications": [
    {
      "id": "13f2440d-f2fc-40ad-9400-8ba61f3f8e0e",
      "instanceId": 11619,
      "authEntityId": 11619,
      "displayName": "11619",
      "authEntityClass": -217092956,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "appProtocol": "tcp/udp",
      "applicationGroup": "other",
      "applicationType": "DEFAULT",
      "categoryId": "3cd50dd8-1b2e-46c1-b672-41c14bfa364b",
      "createTime": 1517966743246,
      "encrypted": "false",
      "engineId": "3",
      "helpString": "kali",
      "ignoreConflict": false,
      "lastUpdateTime": 1517966743246,
      "longDescription": "Kali is an IPX network emulator for DOS and Windows, enabling legacy multiplayer games to work over a modern TCP/IP network such as the Internet. Later versions of the software also functioned as a server browser for games that natively supported TCP/IP.",
      "name": "kali",
      "nbarId": "718",
      "p2pTechnology": "false",
      "popularity": 1,
      "rank": 65535,
      "references": "http://www.kali.net/",
      "selectorId": "2213",
      "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
      "trafficClass": "BULK_DATA",
      "tunnel": "false"
    }
  ],
  "networkIdentity": [
    {
      "id": "f1102660-07d4-4414-92a6-17b94c05452d",
      "instanceId": 13678,
      "authEntityId": 13678,
      "displayName": "13678",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "2213",
      "protocol": "TCP",
      "upperPort": 0
    },
    {
      "id": "5a4d6a36-2c20-4c8a-808d-bac560ed24ba",
      "instanceId": 13679,
      "authEntityId": 13679,
      "displayName": "13679",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "2213",
      "protocol": "UDP",
      "upperPort": 0
    }
  ],
  "parentScalableGroup": {
    "idRef": "0bd0e6c9-5083-492b-97cb-a88928bee99c"
  },
  "user": [

  ],
  "userGroup": [

  ]
},
{
  "id": "83c1ea48-61fc-45bb-840b-cf2dc1b2b8c8",
  "instanceId": 15089,
  "authEntityId": 15089,
  "displayName": "15089",
  "authEntityClass": -1909073334,
  "deployPending": "NONE",
  "instanceVersion": 0,
  "createTime": 1517966744208,
  "deployed": false,
  "isSeeded": true,
  "isStale": false,
  "lastUpdateTime": 1517966744208,
  "name": "sanity",
  "namespace": "scalablegroup:application",
  "provisioningState": "DEFINED",
  "qualifier": "application",
  "resourceVersion": 0,
  "targetIdList": [

  ],
  "type": "scalablegroup",
  "cfsChangeInfo": [

  ],
  "customProvisions": [

  ],
  "scalableGroupExternalHandle": "sanity",
  "scalableGroupType": "APPLICATION",
  "securityGroupTag": 0,
  "state": "ACTIVE",
  "vnAgnostic": true,
  "identitySource": {
    "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
    "instanceId": 10010,
    "authEntityId": 10010,
    "displayName": "10010",
    "authEntityClass": 1738953278,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "state": "INACTIVE",
    "type": "NBAR"
  },
  "indicativeNetworkIdentity": [
    {
      "id": "7a8ffbdf-bf77-4756-a80e-8559ff67eeaa",
      "instanceId": 14904,
      "authEntityId": 14904,
      "displayName": "14904",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "643",
      "protocol": "TCP",
      "upperPort": 0
    },
    {
      "id": "75b7861c-5866-4e71-a498-3db612eff71d",
      "instanceId": 14905,
      "authEntityId": 14905,
      "displayName": "14905",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "643",
      "protocol": "UDP",
      "upperPort": 0
    }
  ],
  "networkApplications": [
    {
      "id": "2e62e773-c077-4d97-9eb1-f058f4621272",
      "instanceId": 16032,
      "authEntityId": 16032,
      "displayName": "16032",
      "authEntityClass": -217092956,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "appProtocol": "tcp/udp",
      "applicationGroup": "other",
      "applicationType": "DEFAULT",
      "categoryId": "6daa3dfd-3526-407a-88f0-97824b3f3a24",
      "createTime": 1517966744208,
      "encrypted": "false",
      "engineId": "3",
      "helpString": "sanity",
      "ignoreConflict": false,
      "lastUpdateTime": 1517966744208,
      "longDescription": "SANity, Registered with IANA on port 643 TCP/UDP",
      "name": "sanity",
      "nbarId": "552",
      "p2pTechnology": "false",
      "popularity": 1,
      "rank": 65535,
      "references": "http://www.iana.org/assignments/service-names-port-numbers/service-names-port-numbers.xml",
      "selectorId": "643",
      "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
      "trafficClass": "BULK_DATA",
      "tunnel": "false"
    }
  ],
  "networkIdentity": [
    {
      "id": "73616557-b681-46f2-b949-6a9f981743d4",
      "instanceId": 14902,
      "authEntityId": 14902,
      "displayName": "14902",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "643",
      "protocol": "UDP",
      "upperPort": 0
    },
    {
      "id": "e8122259-29df-4e2d-8d50-bdffd94fe554",
      "instanceId": 14903,
      "authEntityId": 14903,
      "displayName": "14903",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "643",
      "protocol": "TCP",
      "upperPort": 0
    }
  ],
  "parentScalableGroup": {
    "idRef": "000c15bf-28fe-4fbb-9b69-35e6502721f1"
  },
  "user": [

  ],
  "userGroup": [

  ]
},
{
  "id": "83e77358-0cdd-4190-829e-bc8b0397d157",
  "instanceId": 4855,
  "authEntityId": 4855,
  "displayName": "4855",
  "authEntityClass": -1909073334,
  "deployPending": "NONE",
  "instanceVersion": 0,
  "createTime": 1517966743622,
  "deployed": false,
  "isSeeded": true,
  "isStale": false,
  "lastUpdateTime": 1517966743622,
  "name": "netvmg-traceroute",
  "namespace": "scalablegroup:application",
  "provisioningState": "DEFINED",
  "qualifier": "application",
  "resourceVersion": 0,
  "targetIdList": [

  ],
  "type": "scalablegroup",
  "cfsChangeInfo": [

  ],
  "customProvisions": [

  ],
  "scalableGroupExternalHandle": "netvmg-traceroute",
  "scalableGroupType": "APPLICATION",
  "securityGroupTag": 0,
  "state": "ACTIVE",
  "vnAgnostic": true,
  "identitySource": {
    "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
    "instanceId": 10010,
    "authEntityId": 10010,
    "displayName": "10010",
    "authEntityClass": 1738953278,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "state": "INACTIVE",
    "type": "NBAR"
  },
  "indicativeNetworkIdentity": [
    {
      "id": "15b3b979-8503-404b-b20b-fd5f54fd1693",
      "instanceId": 14203,
      "authEntityId": 14203,
      "displayName": "14203",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "33435",
      "protocol": "UDP",
      "upperPort": 0
    }
  ],
  "networkApplications": [
    {
      "id": "daee60dd-872c-4b5b-9a05-7e482d7c549f",
      "instanceId": 11804,
      "authEntityId": 11804,
      "displayName": "11804",
      "authEntityClass": -217092956,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "appProtocol": "udp",
      "applicationGroup": "other",
      "applicationType": "DEFAULT",
      "categoryId": "1fc17362-9334-4c7b-b752-5afdbb574017",
      "createTime": 1517966743622,
      "encrypted": "false",
      "engineId": "3",
      "helpString": "Routing diagnostics tool",
      "ignoreConflict": false,
      "lastUpdateTime": 1517966743622,
      "longDescription": "netvmg-traceroute is a network diagnostic tool used by NetVMG (who were acquired by Internap in 2003) products to optimize routing.",
      "name": "netvmg-traceroute",
      "nbarId": "1358",
      "p2pTechnology": "false",
      "popularity": 1,
      "rank": 65535,
      "references": "http://venturebeatprofiles.com/company/profile/netvmg",
      "selectorId": "33435",
      "subCategory": "68c4f989-5735-4e16-8d1c-6a9d89d5df9c",
      "trafficClass": "SIGNALING",
      "tunnel": "false"
    }
  ],
  "networkIdentity": [
    {
      "id": "a6361de2-359a-4eff-a277-7d15bdf73e90",
      "instanceId": 14202,
      "authEntityId": 14202,
      "displayName": "14202",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "33435",
      "protocol": "UDP",
      "upperPort": 0
    }
  ],
  "parentScalableGroup": {
    "idRef": "a3243a74-3864-4041-a927-0201030da17c"
  },
  "user": [

  ],
  "userGroup": [

  ]
},
{
  "id": "83e936ba-96f3-4ad9-80aa-1e3ce4117bde",
  "instanceId": 15193,
  "authEntityId": 15193,
  "displayName": "15193",
  "authEntityClass": -1909073334,
  "deployPending": "NONE",
  "instanceVersion": 0,
  "createTime": 1517966744447,
  "deployed": false,
  "isSeeded": true,
  "isStale": false,
  "lastUpdateTime": 1517966744447,
  "name": "spsc",
  "namespace": "scalablegroup:application",
  "provisioningState": "DEFINED",
  "qualifier": "application",
  "resourceVersion": 0,
  "targetIdList": [

  ],
  "type": "scalablegroup",
  "cfsChangeInfo": [

  ],
  "customProvisions": [

  ],
  "scalableGroupExternalHandle": "spsc",
  "scalableGroupType": "APPLICATION",
  "securityGroupTag": 0,
  "state": "ACTIVE",
  "vnAgnostic": true,
  "identitySource": {
    "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
    "instanceId": 10010,
    "authEntityId": 10010,
    "displayName": "10010",
    "authEntityClass": 1738953278,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "state": "INACTIVE",
    "type": "NBAR"
  },
  "indicativeNetworkIdentity": [
    {
      "id": "139533dd-5d40-4924-8ea4-99345f9386d7",
      "instanceId": 17216,
      "authEntityId": 17216,
      "displayName": "17216",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "478",
      "protocol": "UDP",
      "upperPort": 0
    },
    {
      "id": "16484fa7-de4e-4f0a-8680-86f9c4f0581e",
      "instanceId": 17217,
      "authEntityId": 17217,
      "displayName": "17217",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "478",
      "protocol": "TCP",
      "upperPort": 0
    }
  ],
  "networkApplications": [
    {
      "id": "654588f7-bae5-4f96-b316-482d5db227ef",
      "instanceId": 16136,
      "authEntityId": 16136,
      "displayName": "16136",
      "authEntityClass": -217092956,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "appProtocol": "tcp/udp",
      "applicationGroup": "other",
      "applicationType": "DEFAULT",
      "categoryId": "d264d6f4-b014-43e6-acbc-516128d1c6c8",
      "createTime": 1517966744447,
      "encrypted": "false",
      "engineId": "3",
      "helpString": "spsc",
      "ignoreConflict": false,
      "lastUpdateTime": 1517966744447,
      "longDescription": "Registered with IANA on port 478 TCP/UDP",
      "name": "spsc",
      "nbarId": "392",
      "p2pTechnology": "false",
      "popularity": 1,
      "rank": 65535,
      "references": "http://www.iana.org/assignments/service-names-port-numbers/service-names-port-numbers.xml",
      "selectorId": "478",
      "subCategory": "68c4f989-5735-4e16-8d1c-6a9d89d5df9c",
      "trafficClass": "SIGNALING",
      "tunnel": "false"
    }
  ],
  "networkIdentity": [
    {
      "id": "b72ba3f0-b098-405b-affa-9bd743699c0a",
      "instanceId": 17214,
      "authEntityId": 17214,
      "displayName": "17214",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "478",
      "protocol": "UDP",
      "upperPort": 0
    },
    {
      "id": "f96d8e9d-9568-44b0-b3ec-8dc8773ae6c5",
      "instanceId": 17215,
      "authEntityId": 17215,
      "displayName": "17215",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "478",
      "protocol": "TCP",
      "upperPort": 0
    }
  ],
  "parentScalableGroup": {
    "idRef": "a3243a74-3864-4041-a927-0201030da17c"
  },
  "user": [

  ],
  "userGroup": [

  ]
},
{
  "id": "83f1aaef-07d1-435f-883d-6c31052d6beb",
  "instanceId": 4332,
  "authEntityId": 4332,
  "displayName": "4332",
  "authEntityClass": -1909073334,
  "deployPending": "NONE",
  "instanceVersion": 0,
  "createTime": 1517966742307,
  "deployed": false,
  "isSeeded": true,
  "isStale": false,
  "lastUpdateTime": 1517966742307,
  "name": "dbase",
  "namespace": "scalablegroup:application",
  "provisioningState": "DEFINED",
  "qualifier": "application",
  "resourceVersion": 0,
  "targetIdList": [

  ],
  "type": "scalablegroup",
  "cfsChangeInfo": [

  ],
  "customProvisions": [

  ],
  "scalableGroupExternalHandle": "dbase",
  "scalableGroupType": "APPLICATION",
  "securityGroupTag": 0,
  "state": "ACTIVE",
  "vnAgnostic": true,
  "identitySource": {
    "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
    "instanceId": 10010,
    "authEntityId": 10010,
    "displayName": "10010",
    "authEntityClass": 1738953278,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "state": "INACTIVE",
    "type": "NBAR"
  },
  "indicativeNetworkIdentity": [
    {
      "id": "9de8b5f5-09c8-41ac-9782-b59bc2ef73ca",
      "instanceId": 12770,
      "authEntityId": 12770,
      "displayName": "12770",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "217",
      "protocol": "TCP",
      "upperPort": 0
    },
    {
      "id": "70da1d0e-fba3-41f8-8bb3-81aebe0d386c",
      "instanceId": 12771,
      "authEntityId": 12771,
      "displayName": "12771",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "217",
      "protocol": "UDP",
      "upperPort": 0
    }
  ],
  "networkApplications": [
    {
      "id": "0359a44d-9d39-43f1-8154-5209505bc864",
      "instanceId": 11283,
      "authEntityId": 11283,
      "displayName": "11283",
      "authEntityClass": -217092956,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "appProtocol": "tcp/udp",
      "applicationGroup": "other",
      "applicationType": "DEFAULT",
      "categoryId": "cb5915d1-afec-4c30-ac9e-069ff382272f",
      "createTime": 1517966742307,
      "encrypted": "false",
      "engineId": "3",
      "helpString": "dBASE Unix",
      "ignoreConflict": false,
      "lastUpdateTime": 1517966742307,
      "longDescription": "dBase is a relational database management system. dBase II was the first widely used database management system (DBMS) for microcomputers. A major upgrade was released as dBase III, and ported to a wider variety of platforms, adding UNIX and VMS.",
      "name": "dbase",
      "nbarId": "1114",
      "p2pTechnology": "false",
      "popularity": 1,
      "rank": 65535,
      "references": "http://www.dbase.com/",
      "selectorId": "217",
      "subCategory": "b90786f4-0190-45b8-9924-21b3e9682590",
      "trafficClass": "TRANSACTIONAL_DATA",
      "tunnel": "false"
    }
  ],
  "networkIdentity": [
    {
      "id": "b8fd7936-3b64-4868-8a0e-630360255a7a",
      "instanceId": 12768,
      "authEntityId": 12768,
      "displayName": "12768",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "217",
      "protocol": "UDP",
      "upperPort": 0
    },
    {
      "id": "a69acee9-8d75-44d8-b742-6d27faf3b973",
      "instanceId": 12769,
      "authEntityId": 12769,
      "displayName": "12769",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "217",
      "protocol": "TCP",
      "upperPort": 0
    }
  ],
  "parentScalableGroup": {
    "idRef": "8718395e-683e-4a2d-a232-100202751271"
  },
  "user": [

  ],
  "userGroup": [

  ]
},
{
  "id": "846cd66b-720a-4317-a744-21df8e4ef37e",
  "instanceId": 4357,
  "authEntityId": 4357,
  "displayName": "4357",
  "authEntityClass": -1909073334,
  "deployPending": "NONE",
  "instanceVersion": 0,
  "createTime": 1517966742390,
  "deployed": false,
  "isSeeded": true,
  "isStale": false,
  "lastUpdateTime": 1517966742390,
  "name": "dhcpv6-client",
  "namespace": "scalablegroup:application",
  "provisioningState": "DEFINED",
  "qualifier": "application",
  "resourceVersion": 0,
  "targetIdList": [

  ],
  "type": "scalablegroup",
  "cfsChangeInfo": [

  ],
  "customProvisions": [

  ],
  "scalableGroupExternalHandle": "dhcpv6-client",
  "scalableGroupType": "APPLICATION",
  "securityGroupTag": 0,
  "state": "ACTIVE",
  "vnAgnostic": true,
  "identitySource": {
    "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
    "instanceId": 10010,
    "authEntityId": 10010,
    "displayName": "10010",
    "authEntityClass": 1738953278,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "state": "INACTIVE",
    "type": "NBAR"
  },
  "indicativeNetworkIdentity": [
    {
      "id": "a0b02a4b-e381-4a0c-8f89-dfb53baaad97",
      "instanceId": 12854,
      "authEntityId": 12854,
      "displayName": "12854",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "546",
      "protocol": "TCP",
      "upperPort": 0
    },
    {
      "id": "ab174d50-ea6a-4f5c-a1fb-cfac288507a4",
      "instanceId": 12855,
      "authEntityId": 12855,
      "displayName": "12855",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "546",
      "protocol": "UDP",
      "upperPort": 0
    }
  ],
  "networkApplications": [
    {
      "id": "bb768334-e3f6-4953-9f40-bce235264c7c",
      "instanceId": 11308,
      "authEntityId": 11308,
      "displayName": "11308",
      "authEntityClass": -217092956,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "appProtocol": "tcp/udp",
      "applicationGroup": "other",
      "applicationType": "DEFAULT",
      "categoryId": "1fc17362-9334-4c7b-b752-5afdbb574017",
      "createTime": 1517966742390,
      "encrypted": "false",
      "engineId": "3",
      "helpString": "DHCPv6 Client",
      "ignoreConflict": false,
      "lastUpdateTime": 1517966742390,
      "longDescription": "DHCPv6 is a network protocol that is used for configuring IPv6 hosts with IP addresses, IP prefixes and/or other configuration required to operate on an IPv6 network. IPv6 hosts can acquire IP addresses using stateless address autoconfiguration, or by using DHCPv6. DHCP tends to be preferred at sites where central management of hosts is valued; stateless autoconfiguration does not require any sort of central management, and is therefore preferable in networks where no management is readily available, such as a typical home network.",
      "name": "dhcpv6-client",
      "nbarId": "464",
      "p2pTechnology": "false",
      "popularity": 7,
      "rank": 65535,
      "references": "http://tools.ietf.org/html/rfc3315",
      "selectorId": "546",
      "subCategory": "f3c0f761-d894-4112-ab89-b90fc84fd660",
      "trafficClass": "OPS_ADMIN_MGMT",
      "tunnel": "false"
    }
  ],
  "networkIdentity": [
    {
      "id": "9630690f-0f9c-4f4d-b634-825926cd0660",
      "instanceId": 12852,
      "authEntityId": 12852,
      "displayName": "12852",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "546",
      "protocol": "TCP",
      "upperPort": 0
    },
    {
      "id": "885dd05f-4664-4ccc-8251-9e79b77cec5d",
      "instanceId": 12853,
      "authEntityId": 12853,
      "displayName": "12853",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "546",
      "protocol": "UDP",
      "upperPort": 0
    }
  ],
  "parentScalableGroup": {
    "idRef": "5fcd74d4-8726-47f7-a088-c457863da7ef"
  },
  "user": [

  ],
  "userGroup": [

  ]
},
{
  "id": "848ef281-857a-444b-8d7e-04a04e432694",
  "instanceId": 4282,
  "authEntityId": 4282,
  "displayName": "4282",
  "authEntityClass": -1909073334,
  "deployPending": "NONE",
  "instanceVersion": 0,
  "createTime": 1517966742131,
  "deployed": false,
  "isSeeded": true,
  "isStale": false,
  "lastUpdateTime": 1517966742131,
  "name": "conferencing",
  "namespace": "scalablegroup:application",
  "provisioningState": "DEFINED",
  "qualifier": "application",
  "resourceVersion": 0,
  "targetIdList": [

  ],
  "type": "scalablegroup",
  "cfsChangeInfo": [

  ],
  "customProvisions": [

  ],
  "scalableGroupExternalHandle": "conferencing",
  "scalableGroupType": "APPLICATION",
  "securityGroupTag": 0,
  "state": "ACTIVE",
  "vnAgnostic": true,
  "identitySource": {
    "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
    "instanceId": 10010,
    "authEntityId": 10010,
    "displayName": "10010",
    "authEntityClass": 1738953278,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "state": "INACTIVE",
    "type": "NBAR"
  },
  "indicativeNetworkIdentity": [

  ],
  "networkApplications": [
    {
      "id": "08e994f2-d8b4-4c81-b2f1-3a0e6a82be50",
      "instanceId": 11235,
      "authEntityId": 11235,
      "displayName": "11235",
      "authEntityClass": -217092956,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "appProtocol": "tcp",
      "applicationGroup": "telepresence-group",
      "applicationType": "DEFAULT",
      "categoryId": "38a8e354-e2fe-4f3c-b1c5-2bbb90fb0d99",
      "createTime": 1517966742131,
      "encrypted": "false",
      "engineId": "13",
      "helpString": "Audio-Video Conferencing and Collaboration Solutions",
      "ignoreConflict": false,
      "lastUpdateTime": 1517966742131,
      "longDescription": "Audio-Video Conferencing, Videotelephony, Telepresence and Collaboration Solutions",
      "name": "conferencing",
      "nbarId": "1753",
      "p2pTechnology": "false",
      "popularity": 10,
      "rank": 65535,
      "references": "https://en.wikipedia.org/wiki/Videoconferencing",
      "selectorId": "822",
      "subCategory": "53ce0342-1e2d-455a-acc7-1b50db9d6845",
      "trafficClass": "MULTIMEDIA_CONFERENCING",
      "tunnel": "false"
    }
  ],
  "networkIdentity": [
    {
      "id": "c5142893-9adf-4325-aada-795021c1a928",
      "instanceId": 12622,
      "authEntityId": 12622,
      "displayName": "12622",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "80",
      "protocol": "TCP",
      "upperPort": 0
    }
  ],
  "parentScalableGroup": {
    "idRef": "60f1364d-2a02-4063-8ba1-07817abe0612"
  },
  "user": [

  ],
  "userGroup": [

  ]
},
{
  "id": "84b8ed4f-6727-4cbc-abe0-ba03e6a6a82f",
  "instanceId": 4715,
  "authEntityId": 4715,
  "displayName": "4715",
  "authEntityClass": -1909073334,
  "deployPending": "NONE",
  "instanceVersion": 0,
  "createTime": 1517966743342,
  "deployed": false,
  "isSeeded": true,
  "isStale": false,
  "lastUpdateTime": 1517966743342,
  "name": "mailbox-lm",
  "namespace": "scalablegroup:application",
  "provisioningState": "DEFINED",
  "qualifier": "application",
  "resourceVersion": 0,
  "targetIdList": [

  ],
  "type": "scalablegroup",
  "cfsChangeInfo": [

  ],
  "customProvisions": [

  ],
  "scalableGroupExternalHandle": "mailbox-lm",
  "scalableGroupType": "APPLICATION",
  "securityGroupTag": 0,
  "state": "ACTIVE",
  "vnAgnostic": true,
  "identitySource": {
    "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
    "instanceId": 10010,
    "authEntityId": 10010,
    "displayName": "10010",
    "authEntityClass": 1738953278,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "state": "INACTIVE",
    "type": "NBAR"
  },
  "indicativeNetworkIdentity": [
    {
      "id": "5f3c898f-6f70-4e2a-a2ae-c48bd0e8392a",
      "instanceId": 13812,
      "authEntityId": 13812,
      "displayName": "13812",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "505",
      "protocol": "TCP",
      "upperPort": 0
    },
    {
      "id": "6c552490-f98a-4b9f-a20c-5cba90da26e4",
      "instanceId": 13813,
      "authEntityId": 13813,
      "displayName": "13813",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "505",
      "protocol": "UDP",
      "upperPort": 0
    }
  ],
  "networkApplications": [
    {
      "id": "22246f11-9e99-4d39-a835-dc6d877b3c6b",
      "instanceId": 11665,
      "authEntityId": 11665,
      "displayName": "11665",
      "authEntityClass": -217092956,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "appProtocol": "tcp/udp",
      "applicationGroup": "other",
      "applicationType": "DEFAULT",
      "categoryId": "6daa3dfd-3526-407a-88f0-97824b3f3a24",
      "createTime": 1517966743342,
      "encrypted": "false",
      "engineId": "3",
      "helpString": "mailbox-lm",
      "ignoreConflict": false,
      "lastUpdateTime": 1517966743342,
      "longDescription": "Mailbox-LM is a used by FTP Daemon, a file transfer protocol server designed to work with IBM Sterling Gentran:Server for UNIX with ADD. The daemon is a tool that provides the user's trading partners direct access to their mailboxes while enabling the user to limit their access to other components of the user's EDI system.",
      "name": "mailbox-lm",
      "nbarId": "419",
      "p2pTechnology": "false",
      "popularity": 1,
      "rank": 65535,
      "references": "http://www-01.ibm.com/support/docview.wss?uid=swg21532169",
      "selectorId": "505",
      "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
      "trafficClass": "BULK_DATA",
      "tunnel": "false"
    }
  ],
  "networkIdentity": [
    {
      "id": "488c3816-2a2e-45e0-ba89-cd1d4e028c67",
      "instanceId": 13810,
      "authEntityId": 13810,
      "displayName": "13810",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "505",
      "protocol": "UDP",
      "upperPort": 0
    },
    {
      "id": "7a7e8757-22f3-4213-bc5c-cabcf789448b",
      "instanceId": 13811,
      "authEntityId": 13811,
      "displayName": "13811",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "505",
      "protocol": "TCP",
      "upperPort": 0
    }
  ],
  "parentScalableGroup": {
    "idRef": "000c15bf-28fe-4fbb-9b69-35e6502721f1"
  },
  "user": [

  ],
  "userGroup": [

  ]
},
{
  "id": "84cb3ca7-c19a-4060-9672-2da90e825748",
  "instanceId": 15279,
  "authEntityId": 15279,
  "displayName": "15279",
  "authEntityClass": -1909073334,
  "deployPending": "NONE",
  "instanceVersion": 0,
  "createTime": 1517966744644,
  "deployed": false,
  "isSeeded": true,
  "isStale": false,
  "lastUpdateTime": 1517966744644,
  "name": "the-pirate-bay",
  "namespace": "scalablegroup:application",
  "provisioningState": "DEFINED",
  "qualifier": "application",
  "resourceVersion": 0,
  "targetIdList": [

  ],
  "type": "scalablegroup",
  "cfsChangeInfo": [

  ],
  "customProvisions": [

  ],
  "scalableGroupExternalHandle": "the-pirate-bay",
  "scalableGroupType": "APPLICATION",
  "securityGroupTag": 0,
  "state": "ACTIVE",
  "vnAgnostic": true,
  "identitySource": {
    "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
    "instanceId": 10010,
    "authEntityId": 10010,
    "displayName": "10010",
    "authEntityClass": 1738953278,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "state": "INACTIVE",
    "type": "NBAR"
  },
  "indicativeNetworkIdentity": [

  ],
  "networkApplications": [
    {
      "id": "7b41a2da-1427-4176-a4da-4aa0359faf0e",
      "instanceId": 16222,
      "authEntityId": 16222,
      "displayName": "16222",
      "authEntityClass": -217092956,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "appProtocol": "tcp/udp",
      "applicationGroup": "other",
      "applicationType": "DEFAULT",
      "categoryId": "878644b5-fe91-45b4-9204-31c84a73ba48",
      "createTime": 1517966744644,
      "encrypted": "false",
      "engineId": "13",
      "helpString": "The Pirate Bay - BitTorrent index and search engine.",
      "ignoreConflict": false,
      "lastUpdateTime": 1517966744644,
      "longDescription": "The Pirate Bay - BitTorrent index and search engine.",
      "name": "the-pirate-bay",
      "nbarId": "1584",
      "p2pTechnology": "false",
      "popularity": 3,
      "rank": 65535,
      "references": "http://thepiratebay.com",
      "selectorId": "655",
      "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
      "trafficClass": "TRANSACTIONAL_DATA",
      "tunnel": "false"
    }
  ],
  "networkIdentity": [
    {
      "id": "6db16864-51e7-4588-85c9-e21acff13122",
      "instanceId": 17465,
      "authEntityId": 17465,
      "displayName": "17465",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "53,80,443,5353",
      "protocol": "TCP",
      "upperPort": 0
    },
    {
      "id": "9ee85a01-ffd3-4cc0-97b5-e0bdb2980f11",
      "instanceId": 17466,
      "authEntityId": 17466,
      "displayName": "17466",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "53,5353",
      "protocol": "UDP",
      "upperPort": 0
    }
  ],
  "parentScalableGroup": {
    "idRef": "28e08de4-d7b2-4521-84ce-bc1e5ee0b9cc"
  },
  "user": [

  ],
  "userGroup": [

  ]
},
{
  "id": "84ddc2f9-b2ef-441b-9387-83a3edb1f54f",
  "instanceId": 4860,
  "authEntityId": 4860,
  "displayName": "4860",
  "authEntityClass": -1909073334,
  "deployPending": "NONE",
  "instanceVersion": 0,
  "createTime": 1517966743632,
  "deployed": false,
  "isSeeded": true,
  "isStale": false,
  "lastUpdateTime": 1517966743632,
  "name": "nextstep",
  "namespace": "scalablegroup:application",
  "provisioningState": "DEFINED",
  "qualifier": "application",
  "resourceVersion": 0,
  "targetIdList": [

  ],
  "type": "scalablegroup",
  "cfsChangeInfo": [

  ],
  "customProvisions": [

  ],
  "scalableGroupExternalHandle": "nextstep",
  "scalableGroupType": "APPLICATION",
  "securityGroupTag": 0,
  "state": "ACTIVE",
  "vnAgnostic": true,
  "identitySource": {
    "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
    "instanceId": 10010,
    "authEntityId": 10010,
    "displayName": "10010",
    "authEntityClass": 1738953278,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "state": "INACTIVE",
    "type": "NBAR"
  },
  "indicativeNetworkIdentity": [
    {
      "id": "4132a49e-a1d6-401a-93de-13b1b6592964",
      "instanceId": 14220,
      "authEntityId": 14220,
      "displayName": "14220",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "178",
      "protocol": "TCP",
      "upperPort": 0
    },
    {
      "id": "1e5c9c95-27db-49c7-8f28-befcbe7f0b92",
      "instanceId": 14221,
      "authEntityId": 14221,
      "displayName": "14221",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "178",
      "protocol": "UDP",
      "upperPort": 0
    }
  ],
  "networkApplications": [
    {
      "id": "a5e294f5-1907-4067-9860-ebb414fdf9e8",
      "instanceId": 11809,
      "authEntityId": 11809,
      "displayName": "11809",
      "authEntityClass": -217092956,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "appProtocol": "tcp/udp",
      "applicationGroup": "other",
      "applicationType": "DEFAULT",
      "categoryId": "1fc17362-9334-4c7b-b752-5afdbb574017",
      "createTime": 1517966743632,
      "encrypted": "false",
      "engineId": "3",
      "helpString": "NextStep Window Server",
      "ignoreConflict": false,
      "lastUpdateTime": 1517966743632,
      "longDescription": "NeXTSTEP (also written NeXTstep, NeXTStep, and NEXTSTEP) was the object-oriented, multitasking operating system developed by NeXT Computer to run on its range of proprietary workstation computers, such as the NeXTcube. It was later ported to several other computer architectures.",
      "name": "nextstep",
      "nbarId": "1022",
      "p2pTechnology": "false",
      "popularity": 1,
      "rank": 65535,
      "references": "http://en.wikipedia.org/wiki/Nextstep",
      "selectorId": "178",
      "subCategory": "787a7d77-977b-4974-89d3-379ba49e88b7",
      "trafficClass": "OPS_ADMIN_MGMT",
      "tunnel": "false"
    }
  ],
  "networkIdentity": [
    {
      "id": "0b947570-5fc9-4198-8c34-d763687d5214",
      "instanceId": 14218,
      "authEntityId": 14218,
      "displayName": "14218",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "178",
      "protocol": "UDP",
      "upperPort": 0
    },
    {
      "id": "f851f3ab-ab56-4060-b400-f643aa064963",
      "instanceId": 14219,
      "authEntityId": 14219,
      "displayName": "14219",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "178",
      "protocol": "TCP",
      "upperPort": 0
    }
  ],
  "parentScalableGroup": {
    "idRef": "e06dd89f-0a70-4422-ab0b-c2314650a9a7"
  },
  "user": [

  ],
  "userGroup": [

  ]
},
{
  "id": "84e16ccf-3867-4f21-a3ab-b773285d64c5",
  "instanceId": 4151,
  "authEntityId": 4151,
  "displayName": "4151",
  "authEntityClass": -1909073334,
  "deployPending": "NONE",
  "instanceVersion": 0,
  "createTime": 1517966741392,
  "deployed": false,
  "isSeeded": true,
  "isStale": false,
  "lastUpdateTime": 1517966741392,
  "name": "aweber",
  "namespace": "scalablegroup:application",
  "provisioningState": "DEFINED",
  "qualifier": "application",
  "resourceVersion": 0,
  "targetIdList": [

  ],
  "type": "scalablegroup",
  "cfsChangeInfo": [

  ],
  "customProvisions": [

  ],
  "scalableGroupExternalHandle": "aweber",
  "scalableGroupType": "APPLICATION",
  "securityGroupTag": 0,
  "state": "ACTIVE",
  "vnAgnostic": true,
  "identitySource": {
    "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
    "instanceId": 10010,
    "authEntityId": 10010,
    "displayName": "10010",
    "authEntityClass": 1738953278,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "state": "INACTIVE",
    "type": "NBAR"
  },
  "indicativeNetworkIdentity": [

  ],
  "networkApplications": [
    {
      "id": "cfd3a03c-a03f-4898-a942-06b744135c60",
      "instanceId": 11112,
      "authEntityId": 11112,
      "displayName": "11112",
      "authEntityClass": -217092956,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "appProtocol": "tcp/udp",
      "applicationGroup": "other",
      "applicationType": "DEFAULT",
      "categoryId": "878644b5-fe91-45b4-9204-31c84a73ba48",
      "createTime": 1517966741392,
      "encrypted": "false",
      "engineId": "13",
      "helpString": "Aweber - Email marketing Service.",
      "ignoreConflict": false,
      "lastUpdateTime": 1517966741392,
      "longDescription": "Aweber - Email marketing Service.",
      "name": "aweber",
      "nbarId": "1658",
      "p2pTechnology": "false",
      "popularity": 3,
      "rank": 65535,
      "references": "http://aweber.com",
      "selectorId": "728",
      "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
      "trafficClass": "TRANSACTIONAL_DATA",
      "tunnel": "false"
    }
  ],
  "networkIdentity": [
    {
      "id": "487815d8-d97e-4427-a384-19a8a35718c2",
      "instanceId": 12312,
      "authEntityId": 12312,
      "displayName": "12312",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "53,80,443,5353",
      "protocol": "TCP",
      "upperPort": 0
    },
    {
      "id": "337afcc0-f010-4aec-857c-310a74308e20",
      "instanceId": 12313,
      "authEntityId": 12313,
      "displayName": "12313",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "53,5353",
      "protocol": "UDP",
      "upperPort": 0
    }
  ],
  "parentScalableGroup": {
    "idRef": "28e08de4-d7b2-4521-84ce-bc1e5ee0b9cc"
  },
  "user": [

  ],
  "userGroup": [

  ]
},
{
  "id": "84ecf58a-0df6-468f-ab02-751d35028536",
  "instanceId": 15082,
  "authEntityId": 15082,
  "displayName": "15082",
  "authEntityClass": -1909073334,
  "deployPending": "NONE",
  "instanceVersion": 0,
  "createTime": 1517966744192,
  "deployed": false,
  "isSeeded": true,
  "isStale": false,
  "lastUpdateTime": 1517966744192,
  "name": "rushd",
  "namespace": "scalablegroup:application",
  "provisioningState": "DEFINED",
  "qualifier": "application",
  "resourceVersion": 0,
  "targetIdList": [

  ],
  "type": "scalablegroup",
  "cfsChangeInfo": [

  ],
  "customProvisions": [

  ],
  "scalableGroupExternalHandle": "rushd",
  "scalableGroupType": "APPLICATION",
  "securityGroupTag": 0,
  "state": "ACTIVE",
  "vnAgnostic": true,
  "identitySource": {
    "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
    "instanceId": 10010,
    "authEntityId": 10010,
    "displayName": "10010",
    "authEntityClass": 1738953278,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "state": "INACTIVE",
    "type": "NBAR"
  },
  "indicativeNetworkIdentity": [
    {
      "id": "214508c3-b64c-4d09-973f-85a6cf90a71e",
      "instanceId": 14882,
      "authEntityId": 14882,
      "displayName": "14882",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "696",
      "protocol": "UDP",
      "upperPort": 0
    },
    {
      "id": "1ec9c377-8a9a-4c7f-a71b-f517603266b1",
      "instanceId": 14883,
      "authEntityId": 14883,
      "displayName": "14883",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "696",
      "protocol": "TCP",
      "upperPort": 0
    }
  ],
  "networkApplications": [
    {
      "id": "e38c137a-a27b-4964-bb7f-cc7824e3d097",
      "instanceId": 16025,
      "authEntityId": 16025,
      "displayName": "16025",
      "authEntityClass": -217092956,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "appProtocol": "tcp/udp",
      "applicationGroup": "other",
      "applicationType": "DEFAULT",
      "categoryId": "6daa3dfd-3526-407a-88f0-97824b3f3a24",
      "createTime": 1517966744192,
      "encrypted": "false",
      "engineId": "3",
      "helpString": "rushd",
      "ignoreConflict": false,
      "lastUpdateTime": 1517966744192,
      "longDescription": "The Rush render queue allows users to manage jobs. A 'job' is usually just a range of frames that need to be rendered. The render queue consists of two executables: rush(1) is the command line oriented user front end tooland rushd(8) is the network daemon that runs on each host, one daemon per host.",
      "name": "rushd",
      "nbarId": "604",
      "p2pTechnology": "false",
      "popularity": 1,
      "rank": 65535,
      "references": "http://seriss.com/rush-current/rush/",
      "selectorId": "696",
      "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
      "trafficClass": "BULK_DATA",
      "tunnel": "false"
    }
  ],
  "networkIdentity": [
    {
      "id": "a22147d8-6454-474d-8dcc-b4f025cbabde",
      "instanceId": 14880,
      "authEntityId": 14880,
      "displayName": "14880",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "696",
      "protocol": "UDP",
      "upperPort": 0
    },
    {
      "id": "c3dc8cd0-822c-462d-adce-87a28859f739",
      "instanceId": 14881,
      "authEntityId": 14881,
      "displayName": "14881",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "696",
      "protocol": "TCP",
      "upperPort": 0
    }
  ],
  "parentScalableGroup": {
    "idRef": "000c15bf-28fe-4fbb-9b69-35e6502721f1"
  },
  "user": [

  ],
  "userGroup": [

  ]
},
{
  "id": "85098ca3-3f53-4d25-af6e-07692270837d",
  "instanceId": 4647,
  "authEntityId": 4647,
  "displayName": "4647",
  "authEntityClass": -1909073334,
  "deployPending": "NONE",
  "instanceVersion": 0,
  "createTime": 1517966743200,
  "deployed": false,
  "isSeeded": true,
  "isStale": false,
  "lastUpdateTime": 1517966743200,
  "name": "iso-ill",
  "namespace": "scalablegroup:application",
  "provisioningState": "DEFINED",
  "qualifier": "application",
  "resourceVersion": 0,
  "targetIdList": [

  ],
  "type": "scalablegroup",
  "cfsChangeInfo": [

  ],
  "customProvisions": [

  ],
  "scalableGroupExternalHandle": "iso-ill",
  "scalableGroupType": "APPLICATION",
  "securityGroupTag": 0,
  "state": "ACTIVE",
  "vnAgnostic": true,
  "identitySource": {
    "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
    "instanceId": 10010,
    "authEntityId": 10010,
    "displayName": "10010",
    "authEntityClass": 1738953278,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "state": "INACTIVE",
    "type": "NBAR"
  },
  "indicativeNetworkIdentity": [
    {
      "id": "78023714-376f-4e4f-806a-da3da111a427",
      "instanceId": 13625,
      "authEntityId": 13625,
      "displayName": "13625",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "499",
      "protocol": "TCP",
      "upperPort": 0
    },
    {
      "id": "eaa47ecb-f50e-4df9-b52c-eef74eb8abb8",
      "instanceId": 13626,
      "authEntityId": 13626,
      "displayName": "13626",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "499",
      "protocol": "UDP",
      "upperPort": 0
    }
  ],
  "networkApplications": [
    {
      "id": "dcb6ed6d-3bb8-4e17-81ec-40eaa859e110",
      "instanceId": 11597,
      "authEntityId": 11597,
      "displayName": "11597",
      "authEntityClass": -217092956,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "appProtocol": "tcp/udp",
      "applicationGroup": "other",
      "applicationType": "DEFAULT",
      "categoryId": "6daa3dfd-3526-407a-88f0-97824b3f3a24",
      "createTime": 1517966743200,
      "encrypted": "false",
      "engineId": "3",
      "helpString": "ISO ILL Protocol",
      "ignoreConflict": false,
      "lastUpdateTime": 1517966743200,
      "longDescription": "Interlibrary Loan (ILL) protocol is used for communication between various document exchange systems. It allows ILL systems at different libraries that are residing on different hardware platforms and using different software packages such as VDX to communicate with each other to request and receive electronic documents.",
      "name": "iso-ill",
      "nbarId": "413",
      "p2pTechnology": "false",
      "popularity": 1,
      "rank": 65535,
      "references": "http://www.lac-bac.gc.ca/iso/ill/main.htm",
      "selectorId": "499",
      "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
      "trafficClass": "BULK_DATA",
      "tunnel": "false"
    }
  ],
  "networkIdentity": [
    {
      "id": "0fb5e49d-266b-4742-bcf5-8368037d4987",
      "instanceId": 13623,
      "authEntityId": 13623,
      "displayName": "13623",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "499",
      "protocol": "TCP",
      "upperPort": 0
    },
    {
      "id": "a9d97d6e-32a6-4055-8517-9674f6fc4c8c",
      "instanceId": 13624,
      "authEntityId": 13624,
      "displayName": "13624",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "499",
      "protocol": "UDP",
      "upperPort": 0
    }
  ],
  "parentScalableGroup": {
    "idRef": "000c15bf-28fe-4fbb-9b69-35e6502721f1"
  },
  "user": [

  ],
  "userGroup": [

  ]
},
{
  "id": "8512a5e0-0dd0-4513-b6f8-035288836dfb",
  "instanceId": 4641,
  "authEntityId": 4641,
  "displayName": "4641",
  "authEntityClass": -1909073334,
  "deployPending": "NONE",
  "instanceVersion": 0,
  "createTime": 1517966743187,
  "deployed": false,
  "isSeeded": true,
  "isStale": false,
  "lastUpdateTime": 1517966743187,
  "name": "isakmp",
  "namespace": "scalablegroup:application",
  "provisioningState": "DEFINED",
  "qualifier": "application",
  "resourceVersion": 0,
  "targetIdList": [

  ],
  "type": "scalablegroup",
  "cfsChangeInfo": [

  ],
  "customProvisions": [

  ],
  "scalableGroupExternalHandle": "isakmp",
  "scalableGroupType": "APPLICATION",
  "securityGroupTag": 0,
  "state": "ACTIVE",
  "vnAgnostic": true,
  "identitySource": {
    "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
    "instanceId": 10010,
    "authEntityId": 10010,
    "displayName": "10010",
    "authEntityClass": 1738953278,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "state": "INACTIVE",
    "type": "NBAR"
  },
  "indicativeNetworkIdentity": [
    {
      "id": "af24f3b5-bfcd-455a-b691-5e3faa2c039a",
      "instanceId": 13609,
      "authEntityId": 13609,
      "displayName": "13609",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "500,4500",
      "protocol": "UDP",
      "upperPort": 0
    },
    {
      "id": "96608d10-96b8-4db2-a6b3-f4b0c0bb5fd9",
      "instanceId": 13610,
      "authEntityId": 13610,
      "displayName": "13610",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "500",
      "protocol": "TCP",
      "upperPort": 0
    }
  ],
  "networkApplications": [
    {
      "id": "85c93288-b434-4b34-9e88-2348b9e814ac",
      "instanceId": 11591,
      "authEntityId": 11591,
      "displayName": "11591",
      "authEntityClass": -217092956,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "appProtocol": "tcp/udp",
      "applicationGroup": "ipsec-group",
      "applicationType": "DEFAULT",
      "categoryId": "38a8e354-e2fe-4f3c-b1c5-2bbb90fb0d99",
      "createTime": 1517966743187,
      "encrypted": "true",
      "engineId": "3",
      "helpString": "Internet Security Association and Key Management Protocol",
      "ignoreConflict": false,
      "lastUpdateTime": 1517966743187,
      "longDescription": "Internet Security Association and Key Management Protocol (ISAKMP) is used for establishing Security Associations and cryptographic keys in an Internet environment.  Besides standard ports, the protocol also works behind NAT. The protocol usually uses UDP port 500.",
      "name": "isakmp",
      "nbarId": "94",
      "p2pTechnology": "false",
      "popularity": 8,
      "rank": 65535,
      "references": "http://www.ietf.org/rfc/rfc2408.txt",
      "selectorId": "500",
      "subCategory": "ab63e736-9aa7-4946-b55b-3b26ddca609c",
      "trafficClass": "NETWORK_CONTROL",
      "tunnel": "true"
    }
  ],
  "networkIdentity": [
    {
      "id": "86b33858-b35b-4007-89ab-f2d99bae4885",
      "instanceId": 13607,
      "authEntityId": 13607,
      "displayName": "13607",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "500,4500",
      "protocol": "UDP",
      "upperPort": 0
    },
    {
      "id": "1f5db39a-1501-4a91-b372-ccf7a07bb5d5",
      "instanceId": 13608,
      "authEntityId": 13608,
      "displayName": "13608",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "500",
      "protocol": "TCP",
      "upperPort": 0
    }
  ],
  "parentScalableGroup": {
    "idRef": "7788bf2d-e289-4597-b500-17aacb7e820e"
  },
  "user": [

  ],
  "userGroup": [

  ]
},
{
  "id": "85532db1-7dc0-47fc-adfa-f433c729ea58",
  "instanceId": 4789,
  "authEntityId": 4789,
  "displayName": "4789",
  "authEntityClass": -1909073334,
  "deployPending": "NONE",
  "instanceVersion": 0,
  "createTime": 1517966743498,
  "deployed": false,
  "isSeeded": true,
  "isStale": false,
  "lastUpdateTime": 1517966743498,
  "name": "ms-sms",
  "namespace": "scalablegroup:application",
  "provisioningState": "DEFINED",
  "qualifier": "application",
  "resourceVersion": 0,
  "targetIdList": [

  ],
  "type": "scalablegroup",
  "cfsChangeInfo": [

  ],
  "customProvisions": [

  ],
  "scalableGroupExternalHandle": "ms-sms",
  "scalableGroupType": "APPLICATION",
  "securityGroupTag": 0,
  "state": "ACTIVE",
  "vnAgnostic": true,
  "identitySource": {
    "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
    "instanceId": 10010,
    "authEntityId": 10010,
    "displayName": "10010",
    "authEntityClass": 1738953278,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "state": "INACTIVE",
    "type": "NBAR"
  },
  "indicativeNetworkIdentity": [
    {
      "id": "18830166-88ad-46b8-a960-ea6f6b7b72af",
      "instanceId": 14011,
      "authEntityId": 14011,
      "displayName": "14011",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "2701,2702,2703,2704",
      "protocol": "TCP",
      "upperPort": 0
    }
  ],
  "networkApplications": [
    {
      "id": "6b100eba-94d0-410a-a359-01a25f948b73",
      "instanceId": 11738,
      "authEntityId": 11738,
      "displayName": "11738",
      "authEntityClass": -217092956,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "appProtocol": "tcp",
      "applicationGroup": "other",
      "applicationType": "DEFAULT",
      "categoryId": "9d9132a2-d99b-49db-a414-9fc93dea6604",
      "createTime": 1517966743498,
      "encrypted": "false",
      "engineId": "13",
      "helpString": "Microsoft Systems Management Server",
      "ignoreConflict": false,
      "lastUpdateTime": 1517966743498,
      "longDescription": "Systems Management Server (SMS) is a systems management software product made by Microsoft for managing large groups of Windows-based computer systems. SMS provides remote control, patch management, software distribution, and hardware and software inventory. Its based on Microsoft Remote Procedure Call (MS RPC).",
      "name": "ms-sms",
      "nbarId": "1413",
      "p2pTechnology": "false",
      "popularity": 8,
      "rank": 65535,
      "references": "http://technet.microsoft.com/en-us/library/cc751130.aspx",
      "selectorId": "484",
      "subCategory": "98ea6008-96b8-4920-b334-0763dc4ae269",
      "trafficClass": "BULK_DATA",
      "tunnel": "false"
    }
  ],
  "networkIdentity": [
    {
      "id": "b09f4491-cd8a-433c-a8d3-222e3da27d96",
      "instanceId": 14010,
      "authEntityId": 14010,
      "displayName": "14010",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "80,2701,2702,2703,2704",
      "protocol": "TCP",
      "upperPort": 0
    }
  ],
  "parentScalableGroup": {
    "idRef": "4a68bc73-53c6-4deb-9983-784a0338aefe"
  },
  "user": [

  ],
  "userGroup": [

  ]
},
{
  "id": "8562a151-34b4-4ac0-bd1c-72e5bea3a9a9",
  "instanceId": 4736,
  "authEntityId": 4736,
  "displayName": "4736",
  "authEntityClass": -1909073334,
  "deployPending": "NONE",
  "instanceVersion": 0,
  "createTime": 1517966743391,
  "deployed": false,
  "isSeeded": true,
  "isStale": false,
  "lastUpdateTime": 1517966743391,
  "name": "merit-inp",
  "namespace": "scalablegroup:application",
  "provisioningState": "DEFINED",
  "qualifier": "application",
  "resourceVersion": 0,
  "targetIdList": [

  ],
  "type": "scalablegroup",
  "cfsChangeInfo": [

  ],
  "customProvisions": [

  ],
  "scalableGroupExternalHandle": "merit-inp",
  "scalableGroupType": "APPLICATION",
  "securityGroupTag": 0,
  "state": "ACTIVE",
  "vnAgnostic": true,
  "identitySource": {
    "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
    "instanceId": 10010,
    "authEntityId": 10010,
    "displayName": "10010",
    "authEntityClass": 1738953278,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "state": "INACTIVE",
    "type": "NBAR"
  },
  "indicativeNetworkIdentity": [
    {
      "id": "0c33057c-a73c-4b5a-90f2-986a4daaac8b",
      "instanceId": 13867,
      "authEntityId": 13867,
      "displayName": "13867",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipProtocolNumbers": "32",
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "protocol": "MERIT_INP",
      "upperPort": 0
    }
  ],
  "networkApplications": [
    {
      "id": "b306c1a1-5b16-4093-a924-92af82a119b2",
      "instanceId": 11686,
      "authEntityId": 11686,
      "displayName": "11686",
      "authEntityClass": -217092956,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "appProtocol": "ip",
      "applicationGroup": "other",
      "applicationType": "DEFAULT",
      "categoryId": "6daa3dfd-3526-407a-88f0-97824b3f3a24",
      "createTime": 1517966743391,
      "encrypted": "unassigned",
      "engineId": "1",
      "helpString": "MERIT Internodal Protocol",
      "ignoreConflict": false,
      "lastUpdateTime": 1517966743391,
      "longDescription": "MERIT Internodal Protocol",
      "name": "merit-inp",
      "nbarId": "786",
      "p2pTechnology": "unassigned",
      "popularity": 2,
      "rank": 65535,
      "references": "http://www.iana.org/assignments/service-names-port-numbers/service-names-port-numbers.xml",
      "selectorId": "32",
      "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
      "trafficClass": "BULK_DATA",
      "tunnel": "unassigned"
    }
  ],
  "networkIdentity": [
    {
      "id": "5310f23f-9cbc-474c-9264-a48f3942a7c7",
      "instanceId": 13866,
      "authEntityId": 13866,
      "displayName": "13866",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipProtocolNumbers": "32",
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "protocol": "MERIT_INP",
      "upperPort": 0
    }
  ],
  "parentScalableGroup": {
    "idRef": "000c15bf-28fe-4fbb-9b69-35e6502721f1"
  },
  "user": [

  ],
  "userGroup": [

  ]
},
{
  "id": "856826c0-2f4a-4c42-93f3-5c2537ad15a7",
  "instanceId": 15083,
  "authEntityId": 15083,
  "displayName": "15083",
  "authEntityClass": -1909073334,
  "deployPending": "NONE",
  "instanceVersion": 0,
  "createTime": 1517966744195,
  "deployed": false,
  "isSeeded": true,
  "isStale": false,
  "lastUpdateTime": 1517966744195,
  "name": "rvd",
  "namespace": "scalablegroup:application",
  "provisioningState": "DEFINED",
  "qualifier": "application",
  "resourceVersion": 0,
  "targetIdList": [

  ],
  "type": "scalablegroup",
  "cfsChangeInfo": [

  ],
  "customProvisions": [

  ],
  "scalableGroupExternalHandle": "rvd",
  "scalableGroupType": "APPLICATION",
  "securityGroupTag": 0,
  "state": "ACTIVE",
  "vnAgnostic": true,
  "identitySource": {
    "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
    "instanceId": 10010,
    "authEntityId": 10010,
    "displayName": "10010",
    "authEntityClass": 1738953278,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "state": "INACTIVE",
    "type": "NBAR"
  },
  "indicativeNetworkIdentity": [
    {
      "id": "8fe1143b-8fe5-4a24-8bfb-b36e1365572e",
      "instanceId": 14885,
      "authEntityId": 14885,
      "displayName": "14885",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipProtocolNumbers": "66",
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "protocol": "RVD",
      "upperPort": 0
    }
  ],
  "networkApplications": [
    {
      "id": "0ea87ed4-7df6-41f8-90e0-77fc950d6143",
      "instanceId": 16026,
      "authEntityId": 16026,
      "displayName": "16026",
      "authEntityClass": -217092956,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "appProtocol": "ip",
      "applicationGroup": "other",
      "applicationType": "DEFAULT",
      "categoryId": "6daa3dfd-3526-407a-88f0-97824b3f3a24",
      "createTime": 1517966744195,
      "encrypted": "unassigned",
      "engineId": "1",
      "helpString": "MIT Remote Virtual Disk Protocol",
      "ignoreConflict": false,
      "lastUpdateTime": 1517966744195,
      "longDescription": "Remote Virtual Disk protocol (RVD) is implemented as a device driver that allows one to read and write individual disk blocks on a remote machine as if they were on a local disk.",
      "name": "rvd",
      "nbarId": "820",
      "p2pTechnology": "unassigned",
      "popularity": 2,
      "rank": 65535,
      "references": "http://groups.csail.mit.edu/ana/Publications/PubPDFs/The%20Desktop%20Computer%20as%20a%20Network%20Participant.pdf",
      "selectorId": "66",
      "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
      "trafficClass": "BULK_DATA",
      "tunnel": "unassigned"
    }
  ],
  "networkIdentity": [
    {
      "id": "65da4f7b-d29c-413d-9236-ceb19a2c82f3",
      "instanceId": 14884,
      "authEntityId": 14884,
      "displayName": "14884",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipProtocolNumbers": "66",
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "protocol": "RVD",
      "upperPort": 0
    }
  ],
  "parentScalableGroup": {
    "idRef": "000c15bf-28fe-4fbb-9b69-35e6502721f1"
  },
  "user": [

  ],
  "userGroup": [

  ]
},
{
  "id": "859e1c01-5102-4152-a350-5c8ea29adf10",
  "instanceId": 15224,
  "authEntityId": 15224,
  "displayName": "15224",
  "authEntityClass": -1909073334,
  "deployPending": "NONE",
  "instanceVersion": 0,
  "createTime": 1517966744529,
  "deployed": false,
  "isSeeded": true,
  "isStale": false,
  "lastUpdateTime": 1517966744529,
  "name": "stuns",
  "namespace": "scalablegroup:application",
  "provisioningState": "DEFINED",
  "qualifier": "application",
  "resourceVersion": 0,
  "targetIdList": [

  ],
  "type": "scalablegroup",
  "cfsChangeInfo": [

  ],
  "customProvisions": [

  ],
  "scalableGroupExternalHandle": "stuns",
  "scalableGroupType": "APPLICATION",
  "securityGroupTag": 0,
  "state": "ACTIVE",
  "vnAgnostic": true,
  "identitySource": {
    "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
    "instanceId": 10010,
    "authEntityId": 10010,
    "displayName": "10010",
    "authEntityClass": 1738953278,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "state": "INACTIVE",
    "type": "NBAR"
  },
  "indicativeNetworkIdentity": [
    {
      "id": "b8bfbd94-0211-410f-8290-2a9788699ecd",
      "instanceId": 17302,
      "authEntityId": 17302,
      "displayName": "17302",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "5349",
      "protocol": "TCP",
      "upperPort": 0
    },
    {
      "id": "95fcb79c-0bf4-49ac-ab85-eb6cd9abf6c8",
      "instanceId": 17303,
      "authEntityId": 17303,
      "displayName": "17303",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "5349",
      "protocol": "UDP",
      "upperPort": 0
    }
  ],
  "networkApplications": [
    {
      "id": "79e389a7-30af-411d-b746-3a936cebf741",
      "instanceId": 16167,
      "authEntityId": 16167,
      "displayName": "16167",
      "authEntityClass": -217092956,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "appProtocol": "tcp/udp",
      "applicationGroup": "stun-group",
      "applicationType": "DEFAULT",
      "categoryId": "1fc17362-9334-4c7b-b752-5afdbb574017",
      "createTime": 1517966744529,
      "encrypted": "true",
      "engineId": "3",
      "helpString": "STUN over TLS",
      "ignoreConflict": false,
      "lastUpdateTime": 1517966744529,
      "longDescription": "Session Traversal Utilities for NAT provides a means for an endpoint to determine the IP address and port allocated by a NAT that corresponds to its private IP address and port.  It also provides a way for an endpoint to keep a NAT binding alive.  With some extensions, the protocol can be used to do connectivity checks between two endpoints [MMUSIC-ICE], or to relay packets between two endpoints.",
      "name": "stuns",
      "nbarId": "1321",
      "p2pTechnology": "false",
      "popularity": 1,
      "rank": 65535,
      "references": "http://tools.ietf.org/html/rfc5389",
      "selectorId": "5349",
      "subCategory": "f3c0f761-d894-4112-ab89-b90fc84fd660",
      "trafficClass": "OPS_ADMIN_MGMT",
      "tunnel": "false"
    }
  ],
  "networkIdentity": [
    {
      "id": "843c4407-9dde-4108-a2d2-9ff4f56c8d85",
      "instanceId": 17300,
      "authEntityId": 17300,
      "displayName": "17300",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "5349",
      "protocol": "TCP",
      "upperPort": 0
    },
    {
      "id": "27e1a265-4c1a-461a-99c6-09e025056be9",
      "instanceId": 17301,
      "authEntityId": 17301,
      "displayName": "17301",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "5349",
      "protocol": "UDP",
      "upperPort": 0
    }
  ],
  "parentScalableGroup": {
    "idRef": "5fcd74d4-8726-47f7-a088-c457863da7ef"
  },
  "user": [

  ],
  "userGroup": [

  ]
},
{
  "id": "85a66aa0-d42b-4338-ab09-a9308042bc73",
  "instanceId": 4962,
  "authEntityId": 4962,
  "displayName": "4962",
  "authEntityClass": -1909073334,
  "deployPending": "NONE",
  "instanceVersion": 0,
  "createTime": 1517966743916,
  "deployed": false,
  "isSeeded": true,
  "isStale": false,
  "lastUpdateTime": 1517966743916,
  "name": "pinterest",
  "namespace": "scalablegroup:application",
  "provisioningState": "DEFINED",
  "qualifier": "application",
  "resourceVersion": 0,
  "targetIdList": [

  ],
  "type": "scalablegroup",
  "cfsChangeInfo": [

  ],
  "customProvisions": [

  ],
  "scalableGroupExternalHandle": "pinterest",
  "scalableGroupType": "APPLICATION",
  "securityGroupTag": 0,
  "state": "ACTIVE",
  "vnAgnostic": true,
  "identitySource": {
    "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
    "instanceId": 10010,
    "authEntityId": 10010,
    "displayName": "10010",
    "authEntityClass": 1738953278,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "state": "INACTIVE",
    "type": "NBAR"
  },
  "indicativeNetworkIdentity": [

  ],
  "networkApplications": [
    {
      "id": "04293f74-c521-4649-96ca-1d02c4d819f4",
      "instanceId": 11911,
      "authEntityId": 11911,
      "displayName": "11911",
      "authEntityClass": -217092956,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "appProtocol": "tcp/udp",
      "applicationGroup": "other",
      "applicationType": "DEFAULT",
      "categoryId": "878644b5-fe91-45b4-9204-31c84a73ba48",
      "createTime": 1517966743916,
      "encrypted": "false",
      "engineId": "13",
      "helpString": "Pinterest - Social photo sharing website.",
      "ignoreConflict": false,
      "lastUpdateTime": 1517966743916,
      "longDescription": "Pinterest - Social photo sharing website.",
      "name": "pinterest",
      "nbarId": "1682",
      "p2pTechnology": "false",
      "popularity": 3,
      "rank": 65535,
      "references": "http://pinterest.com",
      "selectorId": "752",
      "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
      "trafficClass": "TRANSACTIONAL_DATA",
      "tunnel": "false"
    }
  ],
  "networkIdentity": [
    {
      "id": "e435779b-f689-48c9-9416-76a53b7b779d",
      "instanceId": 14542,
      "authEntityId": 14542,
      "displayName": "14542",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "53,80,443,5353",
      "protocol": "TCP",
      "upperPort": 0
    },
    {
      "id": "d58b5a64-1e31-4788-87d7-b78def46f582",
      "instanceId": 14543,
      "authEntityId": 14543,
      "displayName": "14543",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "53,5353",
      "protocol": "UDP",
      "upperPort": 0
    }
  ],
  "parentScalableGroup": {
    "idRef": "28e08de4-d7b2-4521-84ce-bc1e5ee0b9cc"
  },
  "user": [

  ],
  "userGroup": [

  ]
},
{
  "id": "85c241f2-f7f0-4cd8-afc5-d9e2ba8ca47e",
  "instanceId": 4316,
  "authEntityId": 4316,
  "displayName": "4316",
  "authEntityClass": -1909073334,
  "deployPending": "NONE",
  "instanceVersion": 0,
  "createTime": 1517966742261,
  "deployed": false,
  "isSeeded": true,
  "isStale": false,
  "lastUpdateTime": 1517966742261,
  "name": "cvsup",
  "namespace": "scalablegroup:application",
  "provisioningState": "DEFINED",
  "qualifier": "application",
  "resourceVersion": 0,
  "targetIdList": [

  ],
  "type": "scalablegroup",
  "cfsChangeInfo": [

  ],
  "customProvisions": [

  ],
  "scalableGroupExternalHandle": "cvsup",
  "scalableGroupType": "APPLICATION",
  "securityGroupTag": 0,
  "state": "ACTIVE",
  "vnAgnostic": true,
  "identitySource": {
    "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
    "instanceId": 10010,
    "authEntityId": 10010,
    "displayName": "10010",
    "authEntityClass": 1738953278,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "state": "INACTIVE",
    "type": "NBAR"
  },
  "indicativeNetworkIdentity": [
    {
      "id": "6d3eedd6-b263-46cf-8c9c-3751e5545465",
      "instanceId": 12724,
      "authEntityId": 12724,
      "displayName": "12724",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "5999",
      "protocol": "TCP",
      "upperPort": 0
    },
    {
      "id": "7c22b18c-80f4-405a-a7b7-cfc2c2a55f25",
      "instanceId": 12725,
      "authEntityId": 12725,
      "displayName": "12725",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "5999",
      "protocol": "UDP",
      "upperPort": 0
    }
  ],
  "networkApplications": [
    {
      "id": "a195d2d2-636e-4e61-9975-913d12c7cb72",
      "instanceId": 11268,
      "authEntityId": 11268,
      "displayName": "11268",
      "authEntityClass": -217092956,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "appProtocol": "tcp/udp",
      "applicationGroup": "other",
      "applicationType": "DEFAULT",
      "categoryId": "e6619ce8-97de-4c63-a53a-cceddbddc1a8",
      "createTime": 1517966742261,
      "encrypted": "false",
      "engineId": "3",
      "helpString": "Cvsup",
      "ignoreConflict": false,
      "lastUpdateTime": 1517966742261,
      "longDescription": "CVSup is a computer program written for Unix/Linux based systems that synchronizes files and directories from one location to another while minimizing data transfer using file-type specific delta encoding when appropriate. CVSup was designed for keeping source code repositories - such as CVS - synchronized, but has been extended to support synchronizing any type of file.",
      "name": "cvsup",
      "nbarId": "1338",
      "p2pTechnology": "false",
      "popularity": 1,
      "rank": 65535,
      "references": "http://www.cvsup.org/",
      "selectorId": "5999",
      "subCategory": "50b55e2e-d0d0-4404-bea4-819351565d90",
      "trafficClass": "BULK_DATA",
      "tunnel": "false"
    }
  ],
  "networkIdentity": [
    {
      "id": "43a76fd9-15c5-4f77-b64e-4d1f6e06f5ad",
      "instanceId": 12722,
      "authEntityId": 12722,
      "displayName": "12722",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "5999",
      "protocol": "TCP",
      "upperPort": 0
    },
    {
      "id": "94fcf0e7-a3e9-4a2d-a46a-6dd9ea18861c",
      "instanceId": 12723,
      "authEntityId": 12723,
      "displayName": "12723",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "5999",
      "protocol": "UDP",
      "upperPort": 0
    }
  ],
  "parentScalableGroup": {
    "idRef": "383363b1-4dd5-49d1-bfe4-34daac437cfb"
  },
  "user": [

  ],
  "userGroup": [

  ]
},
{
  "id": "85e1a1fc-1c08-408c-aed1-ca62feb93d36",
  "instanceId": 4960,
  "authEntityId": 4960,
  "displayName": "4960",
  "authEntityClass": -1909073334,
  "deployPending": "NONE",
  "instanceVersion": 0,
  "createTime": 1517966743909,
  "deployed": false,
  "isSeeded": true,
  "isStale": false,
  "lastUpdateTime": 1517966743909,
  "name": "pim-rp-disc",
  "namespace": "scalablegroup:application",
  "provisioningState": "DEFINED",
  "qualifier": "application",
  "resourceVersion": 0,
  "targetIdList": [

  ],
  "type": "scalablegroup",
  "cfsChangeInfo": [

  ],
  "customProvisions": [

  ],
  "scalableGroupExternalHandle": "pim-rp-disc",
  "scalableGroupType": "APPLICATION",
  "securityGroupTag": 0,
  "state": "ACTIVE",
  "vnAgnostic": true,
  "identitySource": {
    "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
    "instanceId": 10010,
    "authEntityId": 10010,
    "displayName": "10010",
    "authEntityClass": 1738953278,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "state": "INACTIVE",
    "type": "NBAR"
  },
  "indicativeNetworkIdentity": [
    {
      "id": "81c07a37-7301-4395-8891-2cd81fdee073",
      "instanceId": 14540,
      "authEntityId": 14540,
      "displayName": "14540",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "496",
      "protocol": "TCP",
      "upperPort": 0
    },
    {
      "id": "3fc4af22-6e9e-4039-b2fd-7932d7bbefa8",
      "instanceId": 14541,
      "authEntityId": 14541,
      "displayName": "14541",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "496",
      "protocol": "UDP",
      "upperPort": 0
    }
  ],
  "networkApplications": [
    {
      "id": "e2cacdc5-a498-49fb-a013-154da9efecde",
      "instanceId": 11909,
      "authEntityId": 11909,
      "displayName": "11909",
      "authEntityClass": -217092956,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "appProtocol": "tcp/udp",
      "applicationGroup": "other",
      "applicationType": "DEFAULT",
      "categoryId": "1fc17362-9334-4c7b-b752-5afdbb574017",
      "createTime": 1517966743909,
      "encrypted": "false",
      "engineId": "3",
      "helpString": "PIM-RP-DISC",
      "ignoreConflict": false,
      "lastUpdateTime": 1517966743909,
      "longDescription": "Registered with IANA on port 496 TCP/UDP",
      "name": "pim-rp-disc",
      "nbarId": "410",
      "p2pTechnology": "false",
      "popularity": 1,
      "rank": 65535,
      "references": "http://www.iana.org/assignments/service-names-port-numbers/service-names-port-numbers.xml",
      "selectorId": "496",
      "subCategory": "7cc1b3a6-4102-44e9-b522-0e71afe39a03",
      "trafficClass": "NETWORK_CONTROL",
      "tunnel": "false"
    }
  ],
  "networkIdentity": [
    {
      "id": "824467b5-2c88-4f3d-ab25-12da7fde867a",
      "instanceId": 14538,
      "authEntityId": 14538,
      "displayName": "14538",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "496",
      "protocol": "UDP",
      "upperPort": 0
    },
    {
      "id": "6b0032fa-7c00-4054-8865-a87f935ffe5d",
      "instanceId": 14539,
      "authEntityId": 14539,
      "displayName": "14539",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "496",
      "protocol": "TCP",
      "upperPort": 0
    }
  ],
  "parentScalableGroup": {
    "idRef": "77e75842-e98b-4112-ad0c-300db4bb11be"
  },
  "user": [

  ],
  "userGroup": [

  ]
},
{
  "id": "85fe50bc-0914-43c9-a49c-d7d06607d556",
  "instanceId": 4861,
  "authEntityId": 4861,
  "displayName": "4861",
  "authEntityClass": -1909073334,
  "deployPending": "NONE",
  "instanceVersion": 0,
  "createTime": 1517966743634,
  "deployed": false,
  "isSeeded": true,
  "isStale": false,
  "lastUpdateTime": 1517966743634,
  "name": "nfs",
  "namespace": "scalablegroup:application",
  "provisioningState": "DEFINED",
  "qualifier": "application",
  "resourceVersion": 0,
  "targetIdList": [

  ],
  "type": "scalablegroup",
  "cfsChangeInfo": [

  ],
  "customProvisions": [

  ],
  "scalableGroupExternalHandle": "nfs",
  "scalableGroupType": "APPLICATION",
  "securityGroupTag": 0,
  "state": "ACTIVE",
  "vnAgnostic": true,
  "identitySource": {
    "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
    "instanceId": 10010,
    "authEntityId": 10010,
    "displayName": "10010",
    "authEntityClass": 1738953278,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "state": "INACTIVE",
    "type": "NBAR"
  },
  "indicativeNetworkIdentity": [
    {
      "id": "ae8c193d-bc45-4ccc-ba89-6f93ff1d6cbe",
      "instanceId": 14224,
      "authEntityId": 14224,
      "displayName": "14224",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "2049",
      "protocol": "UDP",
      "upperPort": 0
    },
    {
      "id": "e29f9fd5-4fe0-4b13-93b9-7ed3d8a2420b",
      "instanceId": 14225,
      "authEntityId": 14225,
      "displayName": "14225",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "2049",
      "protocol": "TCP",
      "upperPort": 0
    }
  ],
  "networkApplications": [
    {
      "id": "e218e55d-d45f-4244-b57f-c323373cefd6",
      "instanceId": 11810,
      "authEntityId": 11810,
      "displayName": "11810",
      "authEntityClass": -217092956,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "appProtocol": "tcp/udp",
      "applicationGroup": "other",
      "applicationType": "DEFAULT",
      "categoryId": "e6619ce8-97de-4c63-a53a-cceddbddc1a8",
      "createTime": 1517966743634,
      "encrypted": "false",
      "engineId": "3",
      "helpString": "Network File System",
      "ignoreConflict": false,
      "lastUpdateTime": 1517966743634,
      "longDescription": "Network File System (NFS) is a distributed file system that allows users to access and modify files remotely as if they were local files. The protocol is based on client server architecture and typically uses TCP/UDP port 2049.",
      "name": "nfs",
      "nbarId": "27",
      "p2pTechnology": "false",
      "popularity": 10,
      "rank": 65535,
      "references": "http://www.ietf.org/rfc/rfc3530.txt",
      "selectorId": "2049",
      "subCategory": "50b55e2e-d0d0-4404-bea4-819351565d90",
      "trafficClass": "BULK_DATA",
      "tunnel": "false"
    }
  ],
  "networkIdentity": [
    {
      "id": "b1f9af0b-110d-4a44-8716-4e7f8fe023b5",
      "instanceId": 14222,
      "authEntityId": 14222,
      "displayName": "14222",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "111,371,2049",
      "protocol": "UDP",
      "upperPort": 0
    },
    {
      "id": "bc792f8e-7693-4d87-b81f-d61b37987b54",
      "instanceId": 14223,
      "authEntityId": 14223,
      "displayName": "14223",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "111,371,2049",
      "protocol": "TCP",
      "upperPort": 0
    }
  ],
  "parentScalableGroup": {
    "idRef": "383363b1-4dd5-49d1-bfe4-34daac437cfb"
  },
  "user": [

  ],
  "userGroup": [

  ]
},
{
  "id": "86640db9-8209-491c-95d2-817db95de796",
  "instanceId": 4459,
  "authEntityId": 4459,
  "displayName": "4459",
  "authEntityClass": -1909073334,
  "deployPending": "NONE",
  "instanceVersion": 0,
  "createTime": 1517966742724,
  "deployed": false,
  "isSeeded": true,
  "isStale": false,
  "lastUpdateTime": 1517966742724,
  "name": "fln-spx",
  "namespace": "scalablegroup:application",
  "provisioningState": "DEFINED",
  "qualifier": "application",
  "resourceVersion": 0,
  "targetIdList": [

  ],
  "type": "scalablegroup",
  "cfsChangeInfo": [

  ],
  "customProvisions": [

  ],
  "scalableGroupExternalHandle": "fln-spx",
  "scalableGroupType": "APPLICATION",
  "securityGroupTag": 0,
  "state": "ACTIVE",
  "vnAgnostic": true,
  "identitySource": {
    "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
    "instanceId": 10010,
    "authEntityId": 10010,
    "displayName": "10010",
    "authEntityClass": 1738953278,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "state": "INACTIVE",
    "type": "NBAR"
  },
  "indicativeNetworkIdentity": [
    {
      "id": "32944c03-6a79-4a68-a0bc-b32126ff3a0e",
      "instanceId": 13143,
      "authEntityId": 13143,
      "displayName": "13143",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "221",
      "protocol": "UDP",
      "upperPort": 0
    },
    {
      "id": "63e1b44f-1457-4690-abae-12b3a86220ae",
      "instanceId": 13144,
      "authEntityId": 13144,
      "displayName": "13144",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "221",
      "protocol": "TCP",
      "upperPort": 0
    }
  ],
  "networkApplications": [
    {
      "id": "672116e0-f3e0-4e86-afb0-69a5438f9709",
      "instanceId": 11410,
      "authEntityId": 11410,
      "displayName": "11410",
      "authEntityClass": -217092956,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "appProtocol": "tcp/udp",
      "applicationGroup": "other",
      "applicationType": "DEFAULT",
      "categoryId": "1fc17362-9334-4c7b-b752-5afdbb574017",
      "createTime": 1517966742724,
      "encrypted": "false",
      "engineId": "3",
      "helpString": "Berkeley rlogind with SPX auth",
      "ignoreConflict": false,
      "lastUpdateTime": 1517966742724,
      "longDescription": "Registered with IANA on port 221 TCP/UDP",
      "name": "fln-spx",
      "nbarId": "1118",
      "p2pTechnology": "false",
      "popularity": 1,
      "rank": 65535,
      "references": "http://www.iana.org/assignments/service-names-port-numbers/service-names-port-numbers.xml",
      "selectorId": "221",
      "subCategory": "787a7d77-977b-4974-89d3-379ba49e88b7",
      "trafficClass": "OPS_ADMIN_MGMT",
      "tunnel": "false"
    }
  ],
  "networkIdentity": [
    {
      "id": "73d1d64d-6f4c-4ffd-9a0e-ec8377d002a0",
      "instanceId": 13141,
      "authEntityId": 13141,
      "displayName": "13141",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "221",
      "protocol": "UDP",
      "upperPort": 0
    },
    {
      "id": "0f13e959-a287-48a7-8f92-82d7d04e1e60",
      "instanceId": 13142,
      "authEntityId": 13142,
      "displayName": "13142",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "221",
      "protocol": "TCP",
      "upperPort": 0
    }
  ],
  "parentScalableGroup": {
    "idRef": "e06dd89f-0a70-4422-ab0b-c2314650a9a7"
  },
  "user": [

  ],
  "userGroup": [

  ]
},
{
  "id": "86932939-38e2-4d73-9218-17c421b3c8e5",
  "instanceId": 4714,
  "authEntityId": 4714,
  "displayName": "4714",
  "authEntityClass": -1909073334,
  "deployPending": "NONE",
  "instanceVersion": 0,
  "createTime": 1517966743340,
  "deployed": false,
  "isSeeded": true,
  "isStale": false,
  "lastUpdateTime": 1517966743340,
  "name": "mail-service",
  "namespace": "scalablegroup:application",
  "provisioningState": "DEFINED",
  "qualifier": "application",
  "resourceVersion": 0,
  "targetIdList": [

  ],
  "type": "scalablegroup",
  "cfsChangeInfo": [

  ],
  "customProvisions": [

  ],
  "scalableGroupExternalHandle": "mail-service",
  "scalableGroupType": "APPLICATION",
  "securityGroupTag": 0,
  "state": "ACTIVE",
  "vnAgnostic": true,
  "identitySource": {
    "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
    "instanceId": 10010,
    "authEntityId": 10010,
    "displayName": "10010",
    "authEntityClass": 1738953278,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "state": "INACTIVE",
    "type": "NBAR"
  },
  "indicativeNetworkIdentity": [

  ],
  "networkApplications": [
    {
      "id": "25c6aaa7-4f0a-4851-b6ad-8cbd71722a83",
      "instanceId": 11664,
      "authEntityId": 11664,
      "displayName": "11664",
      "authEntityClass": -217092956,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "appProtocol": "tcp/udp",
      "applicationGroup": "other",
      "applicationType": "DEFAULT",
      "categoryId": "27953c60-b2ff-4e1a-bc52-0187d8295250",
      "createTime": 1517966743340,
      "encrypted": "true",
      "engineId": "13",
      "helpString": "Mail service",
      "ignoreConflict": false,
      "lastUpdateTime": 1517966743340,
      "longDescription": "Electronic mail Services",
      "name": "mail-service",
      "nbarId": "1754",
      "p2pTechnology": "false",
      "popularity": 10,
      "rank": 65535,
      "references": "https://en.wikipedia.org/wiki/Email",
      "selectorId": "1754",
      "subCategory": "b90786f4-0190-45b8-9924-21b3e9682590",
      "trafficClass": "BULK_DATA",
      "tunnel": "false"
    }
  ],
  "networkIdentity": [
    {
      "id": "368765e9-04ad-4fab-8e2f-a65e59994ad5",
      "instanceId": 13808,
      "authEntityId": 13808,
      "displayName": "13808",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "53,80,5353",
      "protocol": "TCP",
      "upperPort": 0
    },
    {
      "id": "2691ea22-353d-4c7b-9e51-39204bb20117",
      "instanceId": 13809,
      "authEntityId": 13809,
      "displayName": "13809",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "53,67,68,5353",
      "protocol": "UDP",
      "upperPort": 0
    }
  ],
  "parentScalableGroup": {
    "idRef": "8a7ca43a-32bd-40d2-871a-02220a165cb7"
  },
  "user": [

  ],
  "userGroup": [

  ]
},
{
  "id": "869c68d8-e04b-46fc-98a2-d3f4f12f20ac",
  "instanceId": 4973,
  "authEntityId": 4973,
  "displayName": "4973",
  "authEntityClass": -1909073334,
  "deployPending": "NONE",
  "instanceVersion": 0,
  "createTime": 1517966743946,
  "deployed": false,
  "isSeeded": true,
  "isStale": false,
  "lastUpdateTime": 1517966743946,
  "name": "pop2",
  "namespace": "scalablegroup:application",
  "provisioningState": "DEFINED",
  "qualifier": "application",
  "resourceVersion": 0,
  "targetIdList": [

  ],
  "type": "scalablegroup",
  "cfsChangeInfo": [

  ],
  "customProvisions": [

  ],
  "scalableGroupExternalHandle": "pop2",
  "scalableGroupType": "APPLICATION",
  "securityGroupTag": 0,
  "state": "ACTIVE",
  "vnAgnostic": true,
  "identitySource": {
    "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
    "instanceId": 10010,
    "authEntityId": 10010,
    "displayName": "10010",
    "authEntityClass": 1738953278,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "state": "INACTIVE",
    "type": "NBAR"
  },
  "indicativeNetworkIdentity": [
    {
      "id": "fad9e406-f2fe-4a9b-b429-aa1dde207c84",
      "instanceId": 14572,
      "authEntityId": 14572,
      "displayName": "14572",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "109",
      "protocol": "TCP",
      "upperPort": 0
    },
    {
      "id": "64a81678-a62b-4cfa-85ec-c58ebb33f3b5",
      "instanceId": 14573,
      "authEntityId": 14573,
      "displayName": "14573",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "109",
      "protocol": "UDP",
      "upperPort": 0
    }
  ],
  "networkApplications": [
    {
      "id": "60064394-771a-4e37-81dc-5e6d31a9e33c",
      "instanceId": 11922,
      "authEntityId": 11922,
      "displayName": "11922",
      "authEntityClass": -217092956,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "appProtocol": "tcp/udp",
      "applicationGroup": "other",
      "applicationType": "DEFAULT",
      "categoryId": "27953c60-b2ff-4e1a-bc52-0187d8295250",
      "createTime": 1517966743946,
      "encrypted": "false",
      "engineId": "3",
      "helpString": "Post Office Protocol - Version 2",
      "ignoreConflict": false,
      "lastUpdateTime": 1517966743946,
      "longDescription": "Post Office Protocol Version 2 (POP2) enables a user's workstation to access mail from a mailbox server.",
      "name": "pop2",
      "nbarId": "980",
      "p2pTechnology": "false",
      "popularity": 1,
      "rank": 65535,
      "references": "http://tools.ietf.org/html/rfc937",
      "selectorId": "109",
      "subCategory": "b90786f4-0190-45b8-9924-21b3e9682590",
      "trafficClass": "BULK_DATA",
      "tunnel": "false"
    }
  ],
  "networkIdentity": [
    {
      "id": "cdf34168-808d-492b-9647-6ed083c98470",
      "instanceId": 14570,
      "authEntityId": 14570,
      "displayName": "14570",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "109",
      "protocol": "TCP",
      "upperPort": 0
    },
    {
      "id": "7524085c-df45-4381-856f-76e3fed9dbd7",
      "instanceId": 14571,
      "authEntityId": 14571,
      "displayName": "14571",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "109",
      "protocol": "UDP",
      "upperPort": 0
    }
  ],
  "parentScalableGroup": {
    "idRef": "8a7ca43a-32bd-40d2-871a-02220a165cb7"
  },
  "user": [

  ],
  "userGroup": [

  ]
},
{
  "id": "86f323f7-0e1b-4001-ba37-6707ccd2ac79",
  "instanceId": 4560,
  "authEntityId": 4560,
  "displayName": "4560",
  "authEntityClass": -1909073334,
  "deployPending": "NONE",
  "instanceVersion": 0,
  "createTime": 1517966742989,
  "deployed": false,
  "isSeeded": true,
  "isStale": false,
  "lastUpdateTime": 1517966742989,
  "name": "http-local-net",
  "namespace": "scalablegroup:application",
  "provisioningState": "DEFINED",
  "qualifier": "application",
  "resourceVersion": 0,
  "targetIdList": [

  ],
  "type": "scalablegroup",
  "cfsChangeInfo": [

  ],
  "customProvisions": [

  ],
  "scalableGroupExternalHandle": "http-local-net",
  "scalableGroupType": "APPLICATION",
  "securityGroupTag": 0,
  "state": "ACTIVE",
  "vnAgnostic": true,
  "identitySource": {
    "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
    "instanceId": 10010,
    "authEntityId": 10010,
    "displayName": "10010",
    "authEntityClass": 1738953278,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "state": "INACTIVE",
    "type": "NBAR"
  },
  "indicativeNetworkIdentity": [

  ],
  "networkApplications": [
    {
      "id": "0bb65ff2-8f8c-4b9c-b240-5ded8edad671",
      "instanceId": 11510,
      "authEntityId": 11510,
      "displayName": "11510",
      "authEntityClass": -217092956,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "appProtocol": "tcp/udp",
      "applicationGroup": "other",
      "applicationType": "DEFAULT",
      "categoryId": "878644b5-fe91-45b4-9204-31c84a73ba48",
      "createTime": 1517966742989,
      "encrypted": "false",
      "engineId": "13",
      "helpString": "Local network generic HTTP traffic.",
      "ignoreConflict": false,
      "lastUpdateTime": 1517966742989,
      "longDescription": "Local network generic HTTP traffic.",
      "name": "http-local-net",
      "nbarId": "1743",
      "p2pTechnology": "false",
      "popularity": 8,
      "rank": 65535,
      "selectorId": "812",
      "subCategory": "b90786f4-0190-45b8-9924-21b3e9682590",
      "trafficClass": "TRANSACTIONAL_DATA",
      "tunnel": "false"
    }
  ],
  "networkIdentity": [
    {
      "id": "106cc518-b4e8-488e-9e19-f095886f16aa",
      "instanceId": 13389,
      "authEntityId": 13389,
      "displayName": "13389",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "53,80,5353",
      "protocol": "TCP",
      "upperPort": 0
    },
    {
      "id": "4f02829f-45e6-455a-b9cc-ed15fd1319eb",
      "instanceId": 13390,
      "authEntityId": 13390,
      "displayName": "13390",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "53,5353",
      "protocol": "UDP",
      "upperPort": 0
    }
  ],
  "parentScalableGroup": {
    "idRef": "e4e23452-2fdf-4d92-88d8-a7d24426ab72"
  },
  "user": [

  ],
  "userGroup": [

  ]
},
{
  "id": "872e20bb-4a1a-4747-a7d2-112b3b317ab2",
  "instanceId": 15047,
  "authEntityId": 15047,
  "displayName": "15047",
  "authEntityClass": -1909073334,
  "deployPending": "NONE",
  "instanceVersion": 0,
  "createTime": 1517966744110,
  "deployed": false,
  "isSeeded": true,
  "isStale": false,
  "lastUpdateTime": 1517966744110,
  "name": "rip",
  "namespace": "scalablegroup:application",
  "provisioningState": "DEFINED",
  "qualifier": "application",
  "resourceVersion": 0,
  "targetIdList": [

  ],
  "type": "scalablegroup",
  "cfsChangeInfo": [

  ],
  "customProvisions": [

  ],
  "scalableGroupExternalHandle": "rip",
  "scalableGroupType": "APPLICATION",
  "securityGroupTag": 0,
  "state": "ACTIVE",
  "vnAgnostic": true,
  "identitySource": {
    "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
    "instanceId": 10010,
    "authEntityId": 10010,
    "displayName": "10010",
    "authEntityClass": 1738953278,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "state": "INACTIVE",
    "type": "NBAR"
  },
  "indicativeNetworkIdentity": [
    {
      "id": "2b4bdb55-b999-45dd-88db-4330062cd5ba",
      "instanceId": 14771,
      "authEntityId": 14771,
      "displayName": "14771",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "520",
      "protocol": "TCP",
      "upperPort": 0
    },
    {
      "id": "306cb531-b946-4c50-80c7-81d2ee1d79dc",
      "instanceId": 14772,
      "authEntityId": 14772,
      "displayName": "14772",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "520",
      "protocol": "UDP",
      "upperPort": 0
    }
  ],
  "networkApplications": [
    {
      "id": "b0a020dd-ab2e-4ce4-8ad4-96f15d405606",
      "instanceId": 11986,
      "authEntityId": 11986,
      "displayName": "11986",
      "authEntityClass": -217092956,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "appProtocol": "tcp/udp",
      "applicationGroup": "other",
      "applicationType": "DEFAULT",
      "categoryId": "1fc17362-9334-4c7b-b752-5afdbb574017",
      "createTime": 1517966744110,
      "encrypted": "false",
      "engineId": "3",
      "helpString": "Routing Information Protocol",
      "ignoreConflict": false,
      "lastUpdateTime": 1517966744110,
      "longDescription": "Routing Information Protocol (RIP) is a routing protocol used in IP based networks, based on the Distance Vector routing algorithm. RIP is designed to be used in an Autonomous System (AS) as an Interior Gateway Protocol (IGP).",
      "name": "rip",
      "nbarId": "36",
      "p2pTechnology": "false",
      "popularity": 1,
      "rank": 65535,
      "references": "http://tools.ietf.org/html/rfc2453",
      "selectorId": "520",
      "subCategory": "7cc1b3a6-4102-44e9-b522-0e71afe39a03",
      "trafficClass": "NETWORK_CONTROL",
      "tunnel": "false"
    }
  ],
  "networkIdentity": [
    {
      "id": "65f1359c-6337-4523-9d9c-150bb3092921",
      "instanceId": 14769,
      "authEntityId": 14769,
      "displayName": "14769",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "520",
      "protocol": "TCP",
      "upperPort": 0
    },
    {
      "id": "d601ccbf-a26e-4668-8d39-e1bd8e04b302",
      "instanceId": 14770,
      "authEntityId": 14770,
      "displayName": "14770",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "520",
      "protocol": "UDP",
      "upperPort": 0
    }
  ],
  "parentScalableGroup": {
    "idRef": "77e75842-e98b-4112-ad0c-300db4bb11be"
  },
  "user": [

  ],
  "userGroup": [

  ]
},
{
  "id": "874b645a-aeb0-4b64-95ee-781080b89cba",
  "instanceId": 15143,
  "authEntityId": 15143,
  "displayName": "15143",
  "authEntityClass": -1909073334,
  "deployPending": "NONE",
  "instanceVersion": 0,
  "createTime": 1517966744332,
  "deployed": false,
  "isSeeded": true,
  "isStale": false,
  "lastUpdateTime": 1517966744332,
  "name": "silc",
  "namespace": "scalablegroup:application",
  "provisioningState": "DEFINED",
  "qualifier": "application",
  "resourceVersion": 0,
  "targetIdList": [

  ],
  "type": "scalablegroup",
  "cfsChangeInfo": [

  ],
  "customProvisions": [

  ],
  "scalableGroupExternalHandle": "silc",
  "scalableGroupType": "APPLICATION",
  "securityGroupTag": 0,
  "state": "ACTIVE",
  "vnAgnostic": true,
  "identitySource": {
    "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
    "instanceId": 10010,
    "authEntityId": 10010,
    "displayName": "10010",
    "authEntityClass": 1738953278,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "state": "INACTIVE",
    "type": "NBAR"
  },
  "indicativeNetworkIdentity": [
    {
      "id": "cc248339-bd2b-4109-b65d-e48c143c1185",
      "instanceId": 17078,
      "authEntityId": 17078,
      "displayName": "17078",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "706",
      "protocol": "UDP",
      "upperPort": 0
    },
    {
      "id": "3f3b15d1-8b7b-416b-89bd-f538b8d6c9b0",
      "instanceId": 17079,
      "authEntityId": 17079,
      "displayName": "17079",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "706",
      "protocol": "TCP",
      "upperPort": 0
    }
  ],
  "networkApplications": [
    {
      "id": "4c03e9b4-6540-4f4d-9a0e-52136ac58d94",
      "instanceId": 16086,
      "authEntityId": 16086,
      "displayName": "16086",
      "authEntityClass": -217092956,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "appProtocol": "tcp/udp",
      "applicationGroup": "other",
      "applicationType": "DEFAULT",
      "categoryId": "82a7b7fa-da5a-48f5-8b6a-a2546b947843",
      "createTime": 1517966744332,
      "encrypted": "false",
      "engineId": "3",
      "helpString": "silc",
      "ignoreConflict": false,
      "lastUpdateTime": 1517966744332,
      "longDescription": "Secure Internet Live Conferencing (SILC) is a protocol that provides secure synchronous conferencing services (very much like IRC) over the Internet.",
      "name": "silc",
      "nbarId": "610",
      "p2pTechnology": "false",
      "popularity": 1,
      "rank": 65535,
      "references": "http://www.silcnet.org/",
      "selectorId": "706",
      "subCategory": "118c10a2-e272-47c1-92b9-4814ebe97138",
      "trafficClass": "MULTIMEDIA_CONFERENCING",
      "tunnel": "false"
    }
  ],
  "networkIdentity": [
    {
      "id": "46f73055-a0a4-4929-a76f-60489a284d29",
      "instanceId": 17076,
      "authEntityId": 17076,
      "displayName": "17076",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "706",
      "protocol": "TCP",
      "upperPort": 0
    },
    {
      "id": "e797c13d-596d-4439-abf0-91843532610e",
      "instanceId": 17077,
      "authEntityId": 17077,
      "displayName": "17077",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "706",
      "protocol": "UDP",
      "upperPort": 0
    }
  ],
  "parentScalableGroup": {
    "idRef": "fd6264c9-c16a-4311-a335-a3db039256f3"
  },
  "user": [

  ],
  "userGroup": [

  ]
},
{
  "id": "877d37c0-c15f-4b07-ae6a-2c01cf1f9e92",
  "instanceId": 4985,
  "authEntityId": 4985,
  "displayName": "4985",
  "authEntityClass": -1909073334,
  "deployPending": "NONE",
  "instanceVersion": 0,
  "createTime": 1517966743976,
  "deployed": false,
  "isSeeded": true,
  "isStale": false,
  "lastUpdateTime": 1517966743976,
  "name": "prm",
  "namespace": "scalablegroup:application",
  "provisioningState": "DEFINED",
  "qualifier": "application",
  "resourceVersion": 0,
  "targetIdList": [

  ],
  "type": "scalablegroup",
  "cfsChangeInfo": [

  ],
  "customProvisions": [

  ],
  "scalableGroupExternalHandle": "prm",
  "scalableGroupType": "APPLICATION",
  "securityGroupTag": 0,
  "state": "ACTIVE",
  "vnAgnostic": true,
  "identitySource": {
    "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
    "instanceId": 10010,
    "authEntityId": 10010,
    "displayName": "10010",
    "authEntityClass": 1738953278,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "state": "INACTIVE",
    "type": "NBAR"
  },
  "indicativeNetworkIdentity": [
    {
      "id": "6e18ff35-c08a-46d9-a6a8-500fdd428c6f",
      "instanceId": 14607,
      "authEntityId": 14607,
      "displayName": "14607",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipProtocolNumbers": "21",
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "protocol": "PRM",
      "upperPort": 0
    }
  ],
  "networkApplications": [
    {
      "id": "a52037f2-e22f-4b12-8fe7-9ced69a6ca3d",
      "instanceId": 11934,
      "authEntityId": 11934,
      "displayName": "11934",
      "authEntityClass": -217092956,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "appProtocol": "ip",
      "applicationGroup": "other",
      "applicationType": "DEFAULT",
      "categoryId": "6daa3dfd-3526-407a-88f0-97824b3f3a24",
      "createTime": 1517966743976,
      "encrypted": "unassigned",
      "engineId": "1",
      "helpString": "Packet Radio Measurement",
      "ignoreConflict": false,
      "lastUpdateTime": 1517966743976,
      "longDescription": "Registered with IANA as IP Protocol 21",
      "name": "prm",
      "nbarId": "775",
      "p2pTechnology": "unassigned",
      "popularity": 1,
      "rank": 65535,
      "references": "http://www.iana.org/assignments/protocol-numbers/protocol-numbers.xml",
      "selectorId": "21",
      "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
      "trafficClass": "BULK_DATA",
      "tunnel": "unassigned"
    }
  ],
  "networkIdentity": [
    {
      "id": "539a2d95-29a5-4274-a409-afec9252e55d",
      "instanceId": 14606,
      "authEntityId": 14606,
      "displayName": "14606",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipProtocolNumbers": "21",
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "protocol": "PRM",
      "upperPort": 0
    }
  ],
  "parentScalableGroup": {
    "idRef": "000c15bf-28fe-4fbb-9b69-35e6502721f1"
  },
  "user": [

  ],
  "userGroup": [

  ]
},
{
  "id": "87865f86-ef4c-4478-b1f4-7c9f3bb1fc06",
  "instanceId": 15284,
  "authEntityId": 15284,
  "displayName": "15284",
  "authEntityClass": -1909073334,
  "deployPending": "NONE",
  "instanceVersion": 0,
  "createTime": 1517966744654,
  "deployed": false,
  "isSeeded": true,
  "isStale": false,
  "lastUpdateTime": 1517966744654,
  "name": "ticf-2",
  "namespace": "scalablegroup:application",
  "provisioningState": "DEFINED",
  "qualifier": "application",
  "resourceVersion": 0,
  "targetIdList": [

  ],
  "type": "scalablegroup",
  "cfsChangeInfo": [

  ],
  "customProvisions": [

  ],
  "scalableGroupExternalHandle": "ticf-2",
  "scalableGroupType": "APPLICATION",
  "securityGroupTag": 0,
  "state": "ACTIVE",
  "vnAgnostic": true,
  "identitySource": {
    "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
    "instanceId": 10010,
    "authEntityId": 10010,
    "displayName": "10010",
    "authEntityClass": 1738953278,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "state": "INACTIVE",
    "type": "NBAR"
  },
  "indicativeNetworkIdentity": [
    {
      "id": "0d3868b8-d829-458d-826a-8a029730c1ad",
      "instanceId": 17479,
      "authEntityId": 17479,
      "displayName": "17479",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "493",
      "protocol": "TCP",
      "upperPort": 0
    },
    {
      "id": "ed8f1ac8-e3f7-45ab-8e04-0accc8d54f02",
      "instanceId": 17480,
      "authEntityId": 17480,
      "displayName": "17480",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "493",
      "protocol": "UDP",
      "upperPort": 0
    }
  ],
  "networkApplications": [
    {
      "id": "a8918ee4-37fb-4703-9df5-4178f5009369",
      "instanceId": 16227,
      "authEntityId": 16227,
      "displayName": "16227",
      "authEntityClass": -217092956,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "appProtocol": "tcp/udp",
      "applicationGroup": "other",
      "applicationType": "DEFAULT",
      "categoryId": "6daa3dfd-3526-407a-88f0-97824b3f3a24",
      "createTime": 1517966744654,
      "encrypted": "false",
      "engineId": "3",
      "helpString": "Transport Independent Convergence for FNA",
      "ignoreConflict": false,
      "lastUpdateTime": 1517966744654,
      "longDescription": "Registered with IANA on port 493 TCP/UDP",
      "name": "ticf-2",
      "nbarId": "407",
      "p2pTechnology": "false",
      "popularity": 1,
      "rank": 65535,
      "references": "http://www.iana.org/assignments/service-names-port-numbers/service-names-port-numbers.xml",
      "selectorId": "493",
      "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
      "trafficClass": "BULK_DATA",
      "tunnel": "false"
    }
  ],
  "networkIdentity": [
    {
      "id": "4b369560-198a-4e26-9d57-772fb06306a6",
      "instanceId": 17477,
      "authEntityId": 17477,
      "displayName": "17477",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "493",
      "protocol": "TCP",
      "upperPort": 0
    },
    {
      "id": "ddaf4125-7764-4ff8-adf6-52c70ade4783",
      "instanceId": 17478,
      "authEntityId": 17478,
      "displayName": "17478",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "493",
      "protocol": "UDP",
      "upperPort": 0
    }
  ],
  "parentScalableGroup": {
    "idRef": "000c15bf-28fe-4fbb-9b69-35e6502721f1"
  },
  "user": [

  ],
  "userGroup": [

  ]
},
{
  "id": "8787e427-f814-4939-9751-640e77963b81",
  "instanceId": 4968,
  "authEntityId": 4968,
  "displayName": "4968",
  "authEntityClass": -1909073334,
  "deployPending": "NONE",
  "instanceVersion": 0,
  "createTime": 1517966743934,
  "deployed": false,
  "isSeeded": true,
  "isStale": false,
  "lastUpdateTime": 1517966743934,
  "name": "playstation-store",
  "namespace": "scalablegroup:application",
  "provisioningState": "DEFINED",
  "qualifier": "application",
  "resourceVersion": 0,
  "targetIdList": [

  ],
  "type": "scalablegroup",
  "cfsChangeInfo": [

  ],
  "customProvisions": [

  ],
  "scalableGroupExternalHandle": "playstation-store",
  "scalableGroupType": "APPLICATION",
  "securityGroupTag": 0,
  "state": "ACTIVE",
  "vnAgnostic": true,
  "identitySource": {
    "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
    "instanceId": 10010,
    "authEntityId": 10010,
    "displayName": "10010",
    "authEntityClass": 1738953278,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "state": "INACTIVE",
    "type": "NBAR"
  },
  "indicativeNetworkIdentity": [

  ],
  "networkApplications": [
    {
      "id": "3921e929-e57f-4bc1-ab3b-df015d8a1629",
      "instanceId": 11917,
      "authEntityId": 11917,
      "displayName": "11917",
      "authEntityClass": -217092956,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "appProtocol": "tcp/udp",
      "applicationGroup": "other",
      "applicationType": "DEFAULT",
      "categoryId": "878644b5-fe91-45b4-9204-31c84a73ba48",
      "createTime": 1517966743934,
      "encrypted": "false",
      "engineId": "13",
      "helpString": "Playstation Store - Sony Playstation online marketplace.",
      "ignoreConflict": false,
      "lastUpdateTime": 1517966743934,
      "longDescription": "Playstation Store - Sony Playstation online marketplace.",
      "name": "playstation-store",
      "nbarId": "1192",
      "p2pTechnology": "false",
      "popularity": 3,
      "rank": 65535,
      "references": "http://sonyentertainmentnetwork.com",
      "selectorId": "618",
      "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
      "trafficClass": "TRANSACTIONAL_DATA",
      "tunnel": "false"
    }
  ],
  "networkIdentity": [
    {
      "id": "91e4ebae-d2ad-474c-81fe-d3024ed6ceef",
      "instanceId": 14562,
      "authEntityId": 14562,
      "displayName": "14562",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "53,5353",
      "protocol": "UDP",
      "upperPort": 0
    },
    {
      "id": "ca42acde-bf87-4fe2-ad31-df6910c4f24a",
      "instanceId": 14563,
      "authEntityId": 14563,
      "displayName": "14563",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "53,80,443,5353",
      "protocol": "TCP",
      "upperPort": 0
    }
  ],
  "parentScalableGroup": {
    "idRef": "28e08de4-d7b2-4521-84ce-bc1e5ee0b9cc"
  },
  "user": [

  ],
  "userGroup": [

  ]
},
{
  "id": "87b4031d-e05b-4697-b8bd-03d0ae9a2ff4",
  "instanceId": 4121,
  "authEntityId": 4121,
  "displayName": "4121",
  "authEntityClass": -1909073334,
  "deployPending": "NONE",
  "instanceVersion": 0,
  "createTime": 1517966741237,
  "deployed": false,
  "isSeeded": true,
  "isStale": false,
  "lastUpdateTime": 1517966741237,
  "name": "aruba-papi",
  "namespace": "scalablegroup:application",
  "provisioningState": "DEFINED",
  "qualifier": "application",
  "resourceVersion": 0,
  "targetIdList": [

  ],
  "type": "scalablegroup",
  "cfsChangeInfo": [

  ],
  "customProvisions": [

  ],
  "scalableGroupExternalHandle": "aruba-papi",
  "scalableGroupType": "APPLICATION",
  "securityGroupTag": 0,
  "state": "ACTIVE",
  "vnAgnostic": true,
  "identitySource": {
    "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
    "instanceId": 10010,
    "authEntityId": 10010,
    "displayName": "10010",
    "authEntityClass": 1738953278,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "state": "INACTIVE",
    "type": "NBAR"
  },
  "indicativeNetworkIdentity": [
    {
      "id": "7966a91b-34c0-464c-9e4c-bfe891b99f53",
      "instanceId": 12218,
      "authEntityId": 12218,
      "displayName": "12218",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "8211",
      "protocol": "UDP",
      "upperPort": 0
    }
  ],
  "networkApplications": [
    {
      "id": "57588f21-ad30-4951-b0aa-1c528db04dfd",
      "instanceId": 11083,
      "authEntityId": 11083,
      "displayName": "11083",
      "authEntityClass": -217092956,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "appProtocol": "udp",
      "applicationGroup": "other",
      "applicationType": "DEFAULT",
      "categoryId": "1fc17362-9334-4c7b-b752-5afdbb574017",
      "createTime": 1517966741237,
      "encrypted": "false",
      "engineId": "3",
      "helpString": "Aruba access point manager",
      "ignoreConflict": false,
      "lastUpdateTime": 1517966741237,
      "longDescription": "Process Application Programming Interface (PAPI) is used by Aruba Networks in their network management tools to control and manage access points.",
      "name": "aruba-papi",
      "nbarId": "1328",
      "p2pTechnology": "false",
      "popularity": 1,
      "rank": 65535,
      "references": "http://papi.rediris.es/",
      "selectorId": "8211",
      "subCategory": "f3c0f761-d894-4112-ab89-b90fc84fd660",
      "trafficClass": "OPS_ADMIN_MGMT",
      "tunnel": "false"
    }
  ],
  "networkIdentity": [
    {
      "id": "38b49971-07a7-4f13-84e5-2241212a33f2",
      "instanceId": 12217,
      "authEntityId": 12217,
      "displayName": "12217",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "8211",
      "protocol": "UDP",
      "upperPort": 0
    }
  ],
  "parentScalableGroup": {
    "idRef": "5fcd74d4-8726-47f7-a088-c457863da7ef"
  },
  "user": [

  ],
  "userGroup": [

  ]
},
{
  "id": "87bb2b98-2830-4073-ad4a-ffa27ff52043",
  "instanceId": 4853,
  "authEntityId": 4853,
  "displayName": "4853",
  "authEntityClass": -1909073334,
  "deployPending": "NONE",
  "instanceVersion": 0,
  "createTime": 1517966743618,
  "deployed": false,
  "isSeeded": true,
  "isStale": false,
  "lastUpdateTime": 1517966743618,
  "name": "netviewdm2",
  "namespace": "scalablegroup:application",
  "provisioningState": "DEFINED",
  "qualifier": "application",
  "resourceVersion": 0,
  "targetIdList": [

  ],
  "type": "scalablegroup",
  "cfsChangeInfo": [

  ],
  "customProvisions": [

  ],
  "scalableGroupExternalHandle": "netviewdm2",
  "scalableGroupType": "APPLICATION",
  "securityGroupTag": 0,
  "state": "ACTIVE",
  "vnAgnostic": true,
  "identitySource": {
    "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
    "instanceId": 10010,
    "authEntityId": 10010,
    "displayName": "10010",
    "authEntityClass": 1738953278,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "state": "INACTIVE",
    "type": "NBAR"
  },
  "indicativeNetworkIdentity": [
    {
      "id": "b1b8b27b-c999-4533-846d-37a6207af788",
      "instanceId": 14196,
      "authEntityId": 14196,
      "displayName": "14196",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "730",
      "protocol": "TCP",
      "upperPort": 0
    },
    {
      "id": "e9b9afd5-48ad-44d8-892e-c37b97279995",
      "instanceId": 14197,
      "authEntityId": 14197,
      "displayName": "14197",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "730",
      "protocol": "UDP",
      "upperPort": 0
    }
  ],
  "networkApplications": [
    {
      "id": "7043523d-3001-43a0-8d29-8730171434ab",
      "instanceId": 11802,
      "authEntityId": 11802,
      "displayName": "11802",
      "authEntityClass": -217092956,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "appProtocol": "tcp/udp",
      "applicationGroup": "other",
      "applicationType": "DEFAULT",
      "categoryId": "1fc17362-9334-4c7b-b752-5afdbb574017",
      "createTime": 1517966743618,
      "encrypted": "false",
      "engineId": "3",
      "helpString": "IBM NetView DM",
      "ignoreConflict": false,
      "lastUpdateTime": 1517966743618,
      "longDescription": "IBM NetView Distribution Manager (IBM NetView DM) provides centralized management capabilities. It enables customers to automate the distribution of software and data to a large number of remote targets when utilizing the S/390 computers as the primary management system. It provides centralized tracking, automated error recovery, and efficient utilization of the network during the distribution process and shows the customer a history of the distribution process.",
      "name": "netviewdm2",
      "nbarId": "616",
      "p2pTechnology": "false",
      "popularity": 1,
      "rank": 65535,
      "references": "http://www-01.ibm.com/software/tivoli/products/netview_distmgr/",
      "selectorId": "730",
      "subCategory": "f3c0f761-d894-4112-ab89-b90fc84fd660",
      "trafficClass": "OPS_ADMIN_MGMT",
      "tunnel": "false"
    }
  ],
  "networkIdentity": [
    {
      "id": "3b6829bb-52e8-483c-b5c1-29aa547a30de",
      "instanceId": 14194,
      "authEntityId": 14194,
      "displayName": "14194",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "730",
      "protocol": "TCP",
      "upperPort": 0
    },
    {
      "id": "e7837360-bcc9-4f73-8759-27f9fca06927",
      "instanceId": 14195,
      "authEntityId": 14195,
      "displayName": "14195",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "730",
      "protocol": "UDP",
      "upperPort": 0
    }
  ],
  "parentScalableGroup": {
    "idRef": "5fcd74d4-8726-47f7-a088-c457863da7ef"
  },
  "user": [

  ],
  "userGroup": [

  ]
},
{
  "id": "87bde7bd-34e5-406f-9d20-e317af661ebf",
  "instanceId": 15217,
  "authEntityId": 15217,
  "displayName": "15217",
  "authEntityClass": -1909073334,
  "deployPending": "NONE",
  "instanceVersion": 0,
  "createTime": 1517966744511,
  "deployed": false,
  "isSeeded": true,
  "isStale": false,
  "lastUpdateTime": 1517966744511,
  "name": "statistical-p2p",
  "namespace": "scalablegroup:application",
  "provisioningState": "DEFINED",
  "qualifier": "application",
  "resourceVersion": 0,
  "targetIdList": [

  ],
  "type": "scalablegroup",
  "cfsChangeInfo": [

  ],
  "customProvisions": [

  ],
  "scalableGroupExternalHandle": "statistical-p2p",
  "scalableGroupType": "APPLICATION",
  "securityGroupTag": 0,
  "state": "ACTIVE",
  "vnAgnostic": true,
  "identitySource": {
    "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
    "instanceId": 10010,
    "authEntityId": 10010,
    "displayName": "10010",
    "authEntityClass": 1738953278,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "state": "INACTIVE",
    "type": "NBAR"
  },
  "indicativeNetworkIdentity": [

  ],
  "networkApplications": [
    {
      "id": "6632f436-bc5f-4279-bf39-baab187a220c",
      "instanceId": 16160,
      "authEntityId": 16160,
      "displayName": "16160",
      "authEntityClass": -217092956,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "applicationGroup": "other",
      "applicationType": "DEFAULT",
      "categoryId": "6daa3dfd-3526-407a-88f0-97824b3f3a24",
      "createTime": 1517966744511,
      "encrypted": "unassigned",
      "engineId": "13",
      "helpString": "Statistical Peer-To-Peer",
      "ignoreConflict": false,
      "lastUpdateTime": 1517966744511,
      "longDescription": "Statistical Peer-To-Peer based on machine learning",
      "name": "statistical-p2p",
      "nbarId": "1889",
      "p2pTechnology": "unassigned",
      "popularity": 8,
      "rank": 65535,
      "selectorId": "1889",
      "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
      "trafficClass": "BULK_DATA",
      "tunnel": "unassigned"
    }
  ],
  "networkIdentity": [

  ],
  "parentScalableGroup": {
    "idRef": "000c15bf-28fe-4fbb-9b69-35e6502721f1"
  },
  "user": [

  ],
  "userGroup": [

  ]
},
{
  "id": "884acc6d-28e6-49b4-a4f4-1d6f0817285e",
  "instanceId": 4116,
  "authEntityId": 4116,
  "displayName": "4116",
  "authEntityClass": -1909073334,
  "deployPending": "NONE",
  "instanceVersion": 0,
  "createTime": 1517966741224,
  "deployed": false,
  "isSeeded": true,
  "isStale": false,
  "lastUpdateTime": 1517966741224,
  "name": "ariel1",
  "namespace": "scalablegroup:application",
  "provisioningState": "DEFINED",
  "qualifier": "application",
  "resourceVersion": 0,
  "targetIdList": [

  ],
  "type": "scalablegroup",
  "cfsChangeInfo": [

  ],
  "customProvisions": [

  ],
  "scalableGroupExternalHandle": "ariel1",
  "scalableGroupType": "APPLICATION",
  "securityGroupTag": 0,
  "state": "ACTIVE",
  "vnAgnostic": true,
  "identitySource": {
    "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
    "instanceId": 10010,
    "authEntityId": 10010,
    "displayName": "10010",
    "authEntityClass": 1738953278,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "state": "INACTIVE",
    "type": "NBAR"
  },
  "indicativeNetworkIdentity": [
    {
      "id": "86ee3f28-2349-4b0a-8537-1ffbb82daab4",
      "instanceId": 12201,
      "authEntityId": 12201,
      "displayName": "12201",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "419",
      "protocol": "UDP",
      "upperPort": 0
    },
    {
      "id": "e0f270c6-49bd-46ff-90b6-f7a5b06a8ec2",
      "instanceId": 12202,
      "authEntityId": 12202,
      "displayName": "12202",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "419",
      "protocol": "TCP",
      "upperPort": 0
    }
  ],
  "networkApplications": [
    {
      "id": "b280f48b-da3f-4965-9a60-e453be7ef1e1",
      "instanceId": 11078,
      "authEntityId": 11078,
      "displayName": "11078",
      "authEntityClass": -217092956,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "appProtocol": "tcp/udp",
      "applicationGroup": "other",
      "applicationType": "DEFAULT",
      "categoryId": "6daa3dfd-3526-407a-88f0-97824b3f3a24",
      "createTime": 1517966741224,
      "encrypted": "false",
      "engineId": "3",
      "helpString": "Ariel1",
      "ignoreConflict": false,
      "lastUpdateTime": 1517966741224,
      "longDescription": "Ariel is a client/server application developed to facilitate transfer of documents between libraries that are located in different geographical locations.",
      "name": "ariel1",
      "nbarId": "334",
      "p2pTechnology": "false",
      "popularity": 1,
      "rank": 65535,
      "references": "http://www.infotrieve.com/sites/default/files/ariel-userguide-4-1.pdf",
      "selectorId": "419",
      "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
      "trafficClass": "BULK_DATA",
      "tunnel": "false"
    }
  ],
  "networkIdentity": [
    {
      "id": "8a59cf0d-18b3-4650-9217-83e4ab940994",
      "instanceId": 12199,
      "authEntityId": 12199,
      "displayName": "12199",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "419",
      "protocol": "UDP",
      "upperPort": 0
    },
    {
      "id": "bf025902-ddf0-4670-9624-f32229207a42",
      "instanceId": 12200,
      "authEntityId": 12200,
      "displayName": "12200",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "419",
      "protocol": "TCP",
      "upperPort": 0
    }
  ],
  "parentScalableGroup": {
    "idRef": "000c15bf-28fe-4fbb-9b69-35e6502721f1"
  },
  "user": [

  ],
  "userGroup": [

  ]
},
{
  "id": "88647c62-7bee-446b-8233-0609f4ae9221",
  "instanceId": 15021,
  "authEntityId": 15021,
  "displayName": "15021",
  "authEntityClass": -1909073334,
  "deployPending": "NONE",
  "instanceVersion": 0,
  "createTime": 1517966744045,
  "deployed": false,
  "isSeeded": true,
  "isStale": false,
  "lastUpdateTime": 1517966744045,
  "name": "qqlive",
  "namespace": "scalablegroup:application",
  "provisioningState": "DEFINED",
  "qualifier": "application",
  "resourceVersion": 0,
  "targetIdList": [

  ],
  "type": "scalablegroup",
  "cfsChangeInfo": [

  ],
  "customProvisions": [

  ],
  "scalableGroupExternalHandle": "qqlive",
  "scalableGroupType": "APPLICATION",
  "securityGroupTag": 0,
  "state": "ACTIVE",
  "vnAgnostic": true,
  "identitySource": {
    "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
    "instanceId": 10010,
    "authEntityId": 10010,
    "displayName": "10010",
    "authEntityClass": 1738953278,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "state": "INACTIVE",
    "type": "NBAR"
  },
  "indicativeNetworkIdentity": [

  ],
  "networkApplications": [
    {
      "id": "da0cffdf-aac1-4dae-ad9d-3d56e5320185",
      "instanceId": 11960,
      "authEntityId": 11960,
      "displayName": "11960",
      "authEntityClass": -217092956,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "appProtocol": "tcp/udp",
      "applicationGroup": "qq-group",
      "applicationType": "DEFAULT",
      "categoryId": "a09ef1b3-ba26-4372-aee8-309d50697e76",
      "createTime": 1517966744045,
      "encrypted": "false",
      "engineId": "13",
      "helpString": "QQLIVE, a video streaming freeware",
      "ignoreConflict": false,
      "lastUpdateTime": 1517966744045,
      "longDescription": "QQLive is a live streaming video freeware created by Tencent, which takes advantages of advanced P2P streaming media to ensure program keep fluency with many peaople viewing at the same time.",
      "name": "qqlive",
      "nbarId": "1476",
      "p2pTechnology": "true",
      "popularity": 8,
      "rank": 65535,
      "references": "http://live.qq.com/",
      "selectorId": "540",
      "subCategory": "85a797c0-aef8-4284-91a7-677f33e29abb",
      "trafficClass": "MULTIMEDIA_STREAMING",
      "tunnel": "false"
    }
  ],
  "networkIdentity": [
    {
      "id": "7bec1040-53f0-4d41-b19b-c6509f2c23fc",
      "instanceId": 14691,
      "authEntityId": 14691,
      "displayName": "14691",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "53,5353",
      "protocol": "UDP",
      "upperPort": 0
    },
    {
      "id": "3635011d-0317-4376-a572-72d3cac40cc1",
      "instanceId": 14692,
      "authEntityId": 14692,
      "displayName": "14692",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "53,80,5353",
      "protocol": "TCP",
      "upperPort": 0
    }
  ],
  "parentScalableGroup": {
    "idRef": "fd6264c9-c16a-4311-a335-a3db039256f3"
  },
  "user": [

  ],
  "userGroup": [

  ]
},
{
  "id": "892e07e5-5874-4988-9704-4a79692c086a",
  "instanceId": 15336,
  "authEntityId": 15336,
  "displayName": "15336",
  "authEntityClass": -1909073334,
  "deployPending": "NONE",
  "instanceVersion": 0,
  "createTime": 1517966744761,
  "deployed": false,
  "isSeeded": true,
  "isStale": false,
  "lastUpdateTime": 1517966744761,
  "name": "utmpsd",
  "namespace": "scalablegroup:application",
  "provisioningState": "DEFINED",
  "qualifier": "application",
  "resourceVersion": 0,
  "targetIdList": [

  ],
  "type": "scalablegroup",
  "cfsChangeInfo": [

  ],
  "customProvisions": [

  ],
  "scalableGroupExternalHandle": "utmpsd",
  "scalableGroupType": "APPLICATION",
  "securityGroupTag": 0,
  "state": "ACTIVE",
  "vnAgnostic": true,
  "identitySource": {
    "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
    "instanceId": 10010,
    "authEntityId": 10010,
    "displayName": "10010",
    "authEntityClass": 1738953278,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "state": "INACTIVE",
    "type": "NBAR"
  },
  "indicativeNetworkIdentity": [
    {
      "id": "021623f0-84ca-4068-99c5-25ea98030c4f",
      "instanceId": 17625,
      "authEntityId": 17625,
      "displayName": "17625",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "430",
      "protocol": "TCP",
      "upperPort": 0
    },
    {
      "id": "daa9dba4-30b7-412b-a131-20a0866f404e",
      "instanceId": 17626,
      "authEntityId": 17626,
      "displayName": "17626",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "430",
      "protocol": "UDP",
      "upperPort": 0
    }
  ],
  "networkApplications": [
    {
      "id": "5e76ea59-95a2-44e8-acf9-08d64a2985ad",
      "instanceId": 16279,
      "authEntityId": 16279,
      "displayName": "16279",
      "authEntityClass": -217092956,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "appProtocol": "tcp/udp",
      "applicationGroup": "other",
      "applicationType": "DEFAULT",
      "categoryId": "6daa3dfd-3526-407a-88f0-97824b3f3a24",
      "createTime": 1517966744761,
      "encrypted": "false",
      "engineId": "3",
      "helpString": "utmpsd",
      "ignoreConflict": false,
      "lastUpdateTime": 1517966744761,
      "longDescription": "Registered with IANA on port 430 TCP/UDP",
      "name": "utmpsd",
      "nbarId": "345",
      "p2pTechnology": "false",
      "popularity": 1,
      "rank": 65535,
      "references": "http://www.iana.org/assignments/service-names-port-numbers/service-names-port-numbers.xml",
      "selectorId": "430",
      "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
      "trafficClass": "BULK_DATA",
      "tunnel": "false"
    }
  ],
  "networkIdentity": [
    {
      "id": "886e9eb2-8ecb-4c69-b6d4-8d72ce001be3",
      "instanceId": 17623,
      "authEntityId": 17623,
      "displayName": "17623",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "430",
      "protocol": "TCP",
      "upperPort": 0
    },
    {
      "id": "87afbfb9-5ad5-40b3-9897-740287dc7650",
      "instanceId": 17624,
      "authEntityId": 17624,
      "displayName": "17624",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "430",
      "protocol": "UDP",
      "upperPort": 0
    }
  ],
  "parentScalableGroup": {
    "idRef": "000c15bf-28fe-4fbb-9b69-35e6502721f1"
  },
  "user": [

  ],
  "userGroup": [

  ]
},
{
  "id": "8971ea55-f70f-42ec-992f-3d7030add9c7",
  "instanceId": 15087,
  "authEntityId": 15087,
  "displayName": "15087",
  "authEntityClass": -1909073334,
  "deployPending": "NONE",
  "instanceVersion": 0,
  "createTime": 1517966744204,
  "deployed": false,
  "isSeeded": true,
  "isStale": false,
  "lastUpdateTime": 1517966744204,
  "name": "salesforce",
  "namespace": "scalablegroup:application",
  "provisioningState": "DEFINED",
  "qualifier": "application",
  "resourceVersion": 0,
  "targetIdList": [

  ],
  "type": "scalablegroup",
  "cfsChangeInfo": [

  ],
  "customProvisions": [

  ],
  "scalableGroupExternalHandle": "salesforce",
  "scalableGroupType": "APPLICATION",
  "securityGroupTag": 0,
  "state": "ACTIVE",
  "vnAgnostic": true,
  "identitySource": {
    "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
    "instanceId": 10010,
    "authEntityId": 10010,
    "displayName": "10010",
    "authEntityClass": 1738953278,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "state": "INACTIVE",
    "type": "NBAR"
  },
  "indicativeNetworkIdentity": [

  ],
  "networkApplications": [
    {
      "id": "a61c32fa-3bba-4f10-ba3a-e84f70ac0542",
      "instanceId": 16030,
      "authEntityId": 16030,
      "displayName": "16030",
      "authEntityClass": -217092956,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "appProtocol": "tcp/udp",
      "applicationGroup": "other",
      "applicationType": "DEFAULT",
      "categoryId": "7e19fdea-6222-4c57-9e66-66c2340d6bc3",
      "createTime": 1517966744204,
      "encrypted": "true",
      "engineId": "13",
      "helpString": "Customer relationship management web application",
      "ignoreConflict": false,
      "lastUpdateTime": 1517966744204,
      "longDescription": "Salesforce.com is a CRM (Customer Relationship Management) web application that allows users to manage their relationships with customers, using a combination of people, processes, and technology.",
      "name": "salesforce",
      "nbarId": "1444",
      "p2pTechnology": "false",
      "popularity": 10,
      "rank": 65535,
      "references": "http://www.salesforce.com",
      "selectorId": "509",
      "subCategory": "21d2cdac-b44c-4cab-a279-af3ff88ed550",
      "trafficClass": "TRANSACTIONAL_DATA",
      "tunnel": "false"
    }
  ],
  "networkIdentity": [
    {
      "id": "a1ad5848-0844-4f14-a48f-a6617cc1e257",
      "instanceId": 14898,
      "authEntityId": 14898,
      "displayName": "14898",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "53,5353",
      "protocol": "UDP",
      "upperPort": 0
    },
    {
      "id": "2ba2a193-d179-451d-a90d-e50f3a06b838",
      "instanceId": 14899,
      "authEntityId": 14899,
      "displayName": "14899",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "53,80,443,5353",
      "protocol": "TCP",
      "upperPort": 0
    }
  ],
  "parentScalableGroup": {
    "idRef": "dc13f401-c34c-4d18-a5a1-6e9f8624d67e"
  },
  "user": [

  ],
  "userGroup": [

  ]
},
{
  "id": "89bb24c3-0972-4def-a318-1ce9b52df61d",
  "instanceId": 15114,
  "authEntityId": 15114,
  "displayName": "15114",
  "authEntityClass": -1909073334,
  "deployPending": "NONE",
  "instanceVersion": 0,
  "createTime": 1517966744265,
  "deployed": false,
  "isSeeded": true,
  "isStale": false,
  "lastUpdateTime": 1517966744265,
  "name": "secure-ldap",
  "namespace": "scalablegroup:application",
  "provisioningState": "DEFINED",
  "qualifier": "application",
  "resourceVersion": 0,
  "targetIdList": [

  ],
  "type": "scalablegroup",
  "cfsChangeInfo": [

  ],
  "customProvisions": [

  ],
  "scalableGroupExternalHandle": "secure-ldap",
  "scalableGroupType": "APPLICATION",
  "securityGroupTag": 0,
  "state": "ACTIVE",
  "vnAgnostic": true,
  "identitySource": {
    "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
    "instanceId": 10010,
    "authEntityId": 10010,
    "displayName": "10010",
    "authEntityClass": 1738953278,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "state": "INACTIVE",
    "type": "NBAR"
  },
  "indicativeNetworkIdentity": [
    {
      "id": "0a068cce-2a60-4834-a683-685c01f9d599",
      "instanceId": 14978,
      "authEntityId": 14978,
      "displayName": "14978",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "636",
      "protocol": "UDP",
      "upperPort": 0
    },
    {
      "id": "ee304cc0-3d54-4856-b404-2a0d5a222103",
      "instanceId": 14979,
      "authEntityId": 14979,
      "displayName": "14979",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "636",
      "protocol": "TCP",
      "upperPort": 0
    }
  ],
  "networkApplications": [
    {
      "id": "a7fc8f3a-5b6b-4873-a3e4-cd150f293cad",
      "instanceId": 16057,
      "authEntityId": 16057,
      "displayName": "16057",
      "authEntityClass": -217092956,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "appProtocol": "tcp/udp",
      "applicationGroup": "ldap-group",
      "applicationType": "DEFAULT",
      "categoryId": "1fc17362-9334-4c7b-b752-5afdbb574017",
      "createTime": 1517966744265,
      "encrypted": "true",
      "engineId": "3",
      "helpString": "secure LDAP - Lightweight Directory Access Protocol",
      "ignoreConflict": false,
      "lastUpdateTime": 1517966744265,
      "longDescription": "The Lightweight Directory Access Protocol (LDAP) is used to read from and write to Active Directory. By default, LDAP traffic is transmitted unsecured. You can make LDAP traffic confidential and secure by using Secure Sockets Layer (SSL) / Transport Layer Security (TLS) technology. You can enable LDAP over SSL (LDAPS) by installing a properly formatted certificate from either a Microsoft certification authority (CA) or a non-Microsoft CA.",
      "name": "secure-ldap",
      "nbarId": "24",
      "p2pTechnology": "false",
      "popularity": 7,
      "rank": 65535,
      "references": "http://support.microsoft.com/kb/321051",
      "selectorId": "636",
      "subCategory": "e390124a-0fd6-4253-b68e-4b0c1af8cb4b",
      "trafficClass": "OPS_ADMIN_MGMT",
      "tunnel": "false"
    }
  ],
  "networkIdentity": [
    {
      "id": "a30c3167-fa42-4efa-8902-1bf039919023",
      "instanceId": 14976,
      "authEntityId": 14976,
      "displayName": "14976",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "636",
      "protocol": "UDP",
      "upperPort": 0
    },
    {
      "id": "1b912dca-e6e8-46d9-b1f2-01ba7df8c029",
      "instanceId": 14977,
      "authEntityId": 14977,
      "displayName": "14977",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "636",
      "protocol": "TCP",
      "upperPort": 0
    }
  ],
  "parentScalableGroup": {
    "idRef": "7788bf2d-e289-4597-b500-17aacb7e820e"
  },
  "user": [

  ],
  "userGroup": [

  ]
},
{
  "id": "89dc0999-04db-4cf5-abe0-15532e189a1a",
  "instanceId": 15331,
  "authEntityId": 15331,
  "displayName": "15331",
  "authEntityClass": -1909073334,
  "deployPending": "NONE",
  "instanceVersion": 0,
  "createTime": 1517966744752,
  "deployed": false,
  "isSeeded": true,
  "isStale": false,
  "lastUpdateTime": 1517966744752,
  "name": "usbank",
  "namespace": "scalablegroup:application",
  "provisioningState": "DEFINED",
  "qualifier": "application",
  "resourceVersion": 0,
  "targetIdList": [

  ],
  "type": "scalablegroup",
  "cfsChangeInfo": [

  ],
  "customProvisions": [

  ],
  "scalableGroupExternalHandle": "usbank",
  "scalableGroupType": "APPLICATION",
  "securityGroupTag": 0,
  "state": "ACTIVE",
  "vnAgnostic": true,
  "identitySource": {
    "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
    "instanceId": 10010,
    "authEntityId": 10010,
    "displayName": "10010",
    "authEntityClass": 1738953278,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "state": "INACTIVE",
    "type": "NBAR"
  },
  "indicativeNetworkIdentity": [

  ],
  "networkApplications": [
    {
      "id": "8a334b75-7399-4413-8987-3d657e44c71c",
      "instanceId": 16274,
      "authEntityId": 16274,
      "displayName": "16274",
      "authEntityClass": -217092956,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "appProtocol": "tcp/udp",
      "applicationGroup": "other",
      "applicationType": "DEFAULT",
      "categoryId": "878644b5-fe91-45b4-9204-31c84a73ba48",
      "createTime": 1517966744752,
      "encrypted": "false",
      "engineId": "13",
      "helpString": "U.S.Bank - Online banking web portal for U.S Bank.",
      "ignoreConflict": false,
      "lastUpdateTime": 1517966744752,
      "longDescription": "U.S.Bank - Online banking web portal for U.S Bank.",
      "name": "usbank",
      "nbarId": "1716",
      "p2pTechnology": "false",
      "popularity": 3,
      "rank": 65535,
      "references": "http://usbank.com",
      "selectorId": "788",
      "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
      "trafficClass": "TRANSACTIONAL_DATA",
      "tunnel": "false"
    }
  ],
  "networkIdentity": [
    {
      "id": "b0887660-5609-4550-b1b3-f0322b03da1b",
      "instanceId": 17609,
      "authEntityId": 17609,
      "displayName": "17609",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "53,5353",
      "protocol": "UDP",
      "upperPort": 0
    },
    {
      "id": "f5944bb0-2b3e-4af8-80ae-5f174416a057",
      "instanceId": 17610,
      "authEntityId": 17610,
      "displayName": "17610",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "53,80,443,5353",
      "protocol": "TCP",
      "upperPort": 0
    }
  ],
  "parentScalableGroup": {
    "idRef": "28e08de4-d7b2-4521-84ce-bc1e5ee0b9cc"
  },
  "user": [

  ],
  "userGroup": [

  ]
},
{
  "id": "8a6fda8e-0faa-45c8-9c72-d90d0da522b9",
  "instanceId": 15134,
  "authEntityId": 15134,
  "displayName": "15134",
  "authEntityClass": -1909073334,
  "deployPending": "NONE",
  "instanceVersion": 0,
  "createTime": 1517966744312,
  "deployed": false,
  "isSeeded": true,
  "isStale": false,
  "lastUpdateTime": 1517966744312,
  "name": "share-point",
  "namespace": "scalablegroup:application",
  "provisioningState": "DEFINED",
  "qualifier": "application",
  "resourceVersion": 0,
  "targetIdList": [

  ],
  "type": "scalablegroup",
  "cfsChangeInfo": [

  ],
  "customProvisions": [

  ],
  "scalableGroupExternalHandle": "share-point",
  "scalableGroupType": "APPLICATION",
  "securityGroupTag": 0,
  "state": "ACTIVE",
  "vnAgnostic": true,
  "identitySource": {
    "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
    "instanceId": 10010,
    "authEntityId": 10010,
    "displayName": "10010",
    "authEntityClass": 1738953278,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "state": "INACTIVE",
    "type": "NBAR"
  },
  "indicativeNetworkIdentity": [

  ],
  "networkApplications": [
    {
      "id": "58c677b7-9c5b-4da8-9e06-71324951f772",
      "instanceId": 16077,
      "authEntityId": 16077,
      "displayName": "16077",
      "authEntityClass": -217092956,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "appProtocol": "tcp/udp",
      "applicationGroup": "other",
      "applicationType": "DEFAULT",
      "categoryId": "7e19fdea-6222-4c57-9e66-66c2340d6bc3",
      "createTime": 1517966744312,
      "encrypted": "true",
      "engineId": "13",
      "helpString": "SharePoint web application protocol",
      "ignoreConflict": false,
      "lastUpdateTime": 1517966744312,
      "longDescription": "Microsoft SharePoint is a web application platform (based on HTTP) developed by Microsoft. SharePoint is mainly used for web content management and document management systems, but it is actually a much broader platform of web technologies, and can be used for host web sites, access shared workspaces and documents, as well as specialized applications such as wikis and blogs, from within a browser.",
      "name": "share-point",
      "nbarId": "1417",
      "p2pTechnology": "false",
      "popularity": 9,
      "rank": 65535,
      "references": "http://sharepoint.microsoft.com/en-us/product/Pages/default.aspx",
      "selectorId": "488",
      "subCategory": "b90786f4-0190-45b8-9924-21b3e9682590",
      "trafficClass": "TRANSACTIONAL_DATA",
      "tunnel": "false"
    }
  ],
  "networkIdentity": [
    {
      "id": "71ad6681-14cb-4b3f-a08c-e2d84a63ef14",
      "instanceId": 17050,
      "authEntityId": 17050,
      "displayName": "17050",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "53,5353",
      "protocol": "UDP",
      "upperPort": 0
    },
    {
      "id": "7db328df-bcc3-4b32-bfa5-a823b6c7fd86",
      "instanceId": 17051,
      "authEntityId": 17051,
      "displayName": "17051",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "53,80,443,5353",
      "protocol": "TCP",
      "upperPort": 0
    }
  ],
  "parentScalableGroup": {
    "idRef": "dc13f401-c34c-4d18-a5a1-6e9f8624d67e"
  },
  "user": [

  ],
  "userGroup": [

  ]
},
{
  "id": "8a771922-6270-42f7-b3f0-a765f09d6cb7",
  "instanceId": 4687,
  "authEntityId": 4687,
  "displayName": "4687",
  "authEntityClass": -1909073334,
  "deployPending": "NONE",
  "instanceVersion": 0,
  "createTime": 1517966743284,
  "deployed": false,
  "isSeeded": true,
  "isStale": false,
  "lastUpdateTime": 1517966743284,
  "name": "larp",
  "namespace": "scalablegroup:application",
  "provisioningState": "DEFINED",
  "qualifier": "application",
  "resourceVersion": 0,
  "targetIdList": [

  ],
  "type": "scalablegroup",
  "cfsChangeInfo": [

  ],
  "customProvisions": [

  ],
  "scalableGroupExternalHandle": "larp",
  "scalableGroupType": "APPLICATION",
  "securityGroupTag": 0,
  "state": "ACTIVE",
  "vnAgnostic": true,
  "identitySource": {
    "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
    "instanceId": 10010,
    "authEntityId": 10010,
    "displayName": "10010",
    "authEntityClass": 1738953278,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "state": "INACTIVE",
    "type": "NBAR"
  },
  "indicativeNetworkIdentity": [
    {
      "id": "1286af15-8d82-4a2f-9796-0ed08d86f590",
      "instanceId": 13735,
      "authEntityId": 13735,
      "displayName": "13735",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipProtocolNumbers": "91",
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "protocol": "LARP",
      "upperPort": 0
    }
  ],
  "networkApplications": [
    {
      "id": "d91c6358-3e74-4da6-a94e-f432b0681e16",
      "instanceId": 11637,
      "authEntityId": 11637,
      "displayName": "11637",
      "authEntityClass": -217092956,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "appProtocol": "ip",
      "applicationGroup": "other",
      "applicationType": "DEFAULT",
      "categoryId": "6daa3dfd-3526-407a-88f0-97824b3f3a24",
      "createTime": 1517966743284,
      "encrypted": "unassigned",
      "engineId": "1",
      "helpString": "Locus Address Resolution Protocol",
      "ignoreConflict": false,
      "lastUpdateTime": 1517966743284,
      "longDescription": "Registered with IANA as IP Protocol 91",
      "name": "larp",
      "nbarId": "845",
      "p2pTechnology": "unassigned",
      "popularity": 1,
      "rank": 65535,
      "references": "http://www.iana.org/assignments/protocol-numbers/protocol-numbers.xml",
      "selectorId": "91",
      "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
      "trafficClass": "BULK_DATA",
      "tunnel": "unassigned"
    }
  ],
  "networkIdentity": [
    {
      "id": "9fcbfd53-a4ed-4d43-806e-368ef9e6f802",
      "instanceId": 13734,
      "authEntityId": 13734,
      "displayName": "13734",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipProtocolNumbers": "91",
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "protocol": "LARP",
      "upperPort": 0
    }
  ],
  "parentScalableGroup": {
    "idRef": "000c15bf-28fe-4fbb-9b69-35e6502721f1"
  },
  "user": [

  ],
  "userGroup": [

  ]
},
{
  "id": "8a8d7a37-3835-4fe3-94b3-583027d92aac",
  "instanceId": 4373,
  "authEntityId": 4373,
  "displayName": "4373",
  "authEntityClass": -1909073334,
  "deployPending": "NONE",
  "instanceVersion": 0,
  "createTime": 1517966742444,
  "deployed": false,
  "isSeeded": true,
  "isStale": false,
  "lastUpdateTime": 1517966742444,
  "name": "distcc",
  "namespace": "scalablegroup:application",
  "provisioningState": "DEFINED",
  "qualifier": "application",
  "resourceVersion": 0,
  "targetIdList": [

  ],
  "type": "scalablegroup",
  "cfsChangeInfo": [

  ],
  "customProvisions": [

  ],
  "scalableGroupExternalHandle": "distcc",
  "scalableGroupType": "APPLICATION",
  "securityGroupTag": 0,
  "state": "ACTIVE",
  "vnAgnostic": true,
  "identitySource": {
    "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
    "instanceId": 10010,
    "authEntityId": 10010,
    "displayName": "10010",
    "authEntityClass": 1738953278,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "state": "INACTIVE",
    "type": "NBAR"
  },
  "indicativeNetworkIdentity": [
    {
      "id": "2a0a5b4c-64b0-487a-bc83-5f6b653dffde",
      "instanceId": 12905,
      "authEntityId": 12905,
      "displayName": "12905",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "3632",
      "protocol": "UDP",
      "upperPort": 0
    },
    {
      "id": "986462f2-c283-496f-b9ad-e8dc25282d74",
      "instanceId": 12906,
      "authEntityId": 12906,
      "displayName": "12906",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "3632",
      "protocol": "TCP",
      "upperPort": 0
    }
  ],
  "networkApplications": [
    {
      "id": "40e02503-3098-4a78-95a4-d3d7490b7da5",
      "instanceId": 11324,
      "authEntityId": 11324,
      "displayName": "11324",
      "authEntityClass": -217092956,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "appProtocol": "tcp/udp",
      "applicationGroup": "other",
      "applicationType": "DEFAULT",
      "categoryId": "7e19fdea-6222-4c57-9e66-66c2340d6bc3",
      "createTime": 1517966742444,
      "encrypted": "false",
      "engineId": "3",
      "helpString": "Distributed Compiler",
      "ignoreConflict": false,
      "lastUpdateTime": 1517966742444,
      "longDescription": "Distributed Compiler Protocol (distcc) is a tool used in software development for speeding up compilation of source code by using distributed computing over a computer network.",
      "name": "distcc",
      "nbarId": "1340",
      "p2pTechnology": "false",
      "popularity": 1,
      "rank": 65535,
      "references": "http://distcc.googlecode.com/",
      "selectorId": "3632",
      "subCategory": "b90786f4-0190-45b8-9924-21b3e9682590",
      "trafficClass": "TRANSACTIONAL_DATA",
      "tunnel": "false"
    }
  ],
  "networkIdentity": [
    {
      "id": "7c41d82f-a461-4c31-a609-26c97495f593",
      "instanceId": 12903,
      "authEntityId": 12903,
      "displayName": "12903",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "3632",
      "protocol": "TCP",
      "upperPort": 0
    },
    {
      "id": "73a66b1b-a9fe-4e0c-91b2-dae2313999b3",
      "instanceId": 12904,
      "authEntityId": 12904,
      "displayName": "12904",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "3632",
      "protocol": "UDP",
      "upperPort": 0
    }
  ],
  "parentScalableGroup": {
    "idRef": "fdbaf614-da09-4ba2-89bb-d1ca9e379597"
  },
  "user": [

  ],
  "userGroup": [

  ]
},
{
  "id": "8aefe5f9-0908-4ba0-b4eb-72a9b6e2f04a",
  "instanceId": 4368,
  "authEntityId": 4368,
  "displayName": "4368",
  "authEntityClass": -1909073334,
  "deployPending": "NONE",
  "instanceVersion": 0,
  "createTime": 1517966742427,
  "deployed": false,
  "isSeeded": true,
  "isStale": false,
  "lastUpdateTime": 1517966742427,
  "name": "directv-web",
  "namespace": "scalablegroup:application",
  "provisioningState": "DEFINED",
  "qualifier": "application",
  "resourceVersion": 0,
  "targetIdList": [

  ],
  "type": "scalablegroup",
  "cfsChangeInfo": [

  ],
  "customProvisions": [

  ],
  "scalableGroupExternalHandle": "directv-web",
  "scalableGroupType": "APPLICATION",
  "securityGroupTag": 0,
  "state": "ACTIVE",
  "vnAgnostic": true,
  "identitySource": {
    "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
    "instanceId": 10010,
    "authEntityId": 10010,
    "displayName": "10010",
    "authEntityClass": 1738953278,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "state": "INACTIVE",
    "type": "NBAR"
  },
  "indicativeNetworkIdentity": [
    {
      "id": "b061f159-eb26-4324-9c04-16940ea6404a",
      "instanceId": 12889,
      "authEntityId": 12889,
      "displayName": "12889",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "3334",
      "protocol": "TCP",
      "upperPort": 0
    },
    {
      "id": "a0976eb0-7d9c-4faf-af0e-351d0c0909c3",
      "instanceId": 12890,
      "authEntityId": 12890,
      "displayName": "12890",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "3334",
      "protocol": "UDP",
      "upperPort": 0
    }
  ],
  "networkApplications": [
    {
      "id": "43868088-f603-45c7-8d23-ea8c029a3b39",
      "instanceId": 11319,
      "authEntityId": 11319,
      "displayName": "11319",
      "authEntityClass": -217092956,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "appProtocol": "tcp/udp",
      "applicationGroup": "other",
      "applicationType": "DEFAULT",
      "categoryId": "a09ef1b3-ba26-4372-aee8-309d50697e76",
      "createTime": 1517966742427,
      "encrypted": "false",
      "engineId": "3",
      "helpString": "Direct TV Webcasting",
      "ignoreConflict": false,
      "lastUpdateTime": 1517966742427,
      "longDescription": "A DirecTV service - Webcasting. DirecTV (DIRECTV) is an Americandirect broadcast satelliteservice provider and broadcaster.",
      "name": "directv-web",
      "nbarId": "720",
      "p2pTechnology": "false",
      "popularity": 1,
      "rank": 65535,
      "references": "http://www.directv.com",
      "selectorId": "3334",
      "subCategory": "85a797c0-aef8-4284-91a7-677f33e29abb",
      "trafficClass": "MULTIMEDIA_STREAMING",
      "tunnel": "false"
    }
  ],
  "networkIdentity": [
    {
      "id": "2ab520d0-3673-4c55-b1a4-aa55b0b08937",
      "instanceId": 12887,
      "authEntityId": 12887,
      "displayName": "12887",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "3334",
      "protocol": "TCP",
      "upperPort": 0
    },
    {
      "id": "ee5b1cd8-6a76-4323-9e82-0d82a38b96da",
      "instanceId": 12888,
      "authEntityId": 12888,
      "displayName": "12888",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "3334",
      "protocol": "UDP",
      "upperPort": 0
    }
  ],
  "parentScalableGroup": {
    "idRef": "fd6264c9-c16a-4311-a335-a3db039256f3"
  },
  "user": [

  ],
  "userGroup": [

  ]
},
{
  "id": "8af47069-3ff7-49a8-a1e0-c37d0f0ca212",
  "instanceId": 4713,
  "authEntityId": 4713,
  "displayName": "4713",
  "authEntityClass": -1909073334,
  "deployPending": "NONE",
  "instanceVersion": 0,
  "createTime": 1517966743338,
  "deployed": false,
  "isSeeded": true,
  "isStale": false,
  "lastUpdateTime": 1517966743338,
  "name": "mail-ru",
  "namespace": "scalablegroup:application",
  "provisioningState": "DEFINED",
  "qualifier": "application",
  "resourceVersion": 0,
  "targetIdList": [

  ],
  "type": "scalablegroup",
  "cfsChangeInfo": [

  ],
  "customProvisions": [

  ],
  "scalableGroupExternalHandle": "mail-ru",
  "scalableGroupType": "APPLICATION",
  "securityGroupTag": 0,
  "state": "ACTIVE",
  "vnAgnostic": true,
  "identitySource": {
    "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
    "instanceId": 10010,
    "authEntityId": 10010,
    "displayName": "10010",
    "authEntityClass": 1738953278,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "state": "INACTIVE",
    "type": "NBAR"
  },
  "indicativeNetworkIdentity": [

  ],
  "networkApplications": [
    {
      "id": "b0992b75-4c15-481e-a3e4-7e55e11feb25",
      "instanceId": 11663,
      "authEntityId": 11663,
      "displayName": "11663",
      "authEntityClass": -217092956,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "appProtocol": "tcp/udp",
      "applicationGroup": "other",
      "applicationType": "DEFAULT",
      "categoryId": "f6ec0221-b82d-43e8-a64d-163cbc13263b",
      "createTime": 1517966743338,
      "encrypted": "true",
      "engineId": "13",
      "helpString": "mail.ru, Russian communication web portal, Email and cloud storage services.",
      "ignoreConflict": false,
      "lastUpdateTime": 1517966743338,
      "longDescription": "mail.ru, Russian communication web portal, Email and cloud storage services.",
      "name": "mail-ru",
      "nbarId": "1553",
      "p2pTechnology": "false",
      "popularity": 8,
      "rank": 65535,
      "references": "http://mail.ru/",
      "selectorId": "614",
      "subCategory": "9fecfe24-2ee3-4b19-bbf7-3c992d1d25b4",
      "trafficClass": "BULK_DATA",
      "tunnel": "false"
    }
  ],
  "networkIdentity": [
    {
      "id": "c1f234c6-8760-4124-9f64-c16a8294209c",
      "instanceId": 13806,
      "authEntityId": 13806,
      "displayName": "13806",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "25,53,5353",
      "protocol": "UDP",
      "upperPort": 0
    },
    {
      "id": "9cc9f4e0-6b2a-4070-997b-6b925f04cf5b",
      "instanceId": 13807,
      "authEntityId": 13807,
      "displayName": "13807",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "25,53,80,110,443,587,5353",
      "protocol": "TCP",
      "upperPort": 0
    }
  ],
  "parentScalableGroup": {
    "idRef": "28e08de4-d7b2-4521-84ce-bc1e5ee0b9cc"
  },
  "user": [

  ],
  "userGroup": [

  ]
},
{
  "id": "8af5b86f-1139-4916-9555-b5e26d1f4a50",
  "instanceId": 4589,
  "authEntityId": 4589,
  "displayName": "4589",
  "authEntityClass": -1909073334,
  "deployPending": "NONE",
  "instanceVersion": 0,
  "createTime": 1517966743064,
  "deployed": false,
  "isSeeded": true,
  "isStale": false,
  "lastUpdateTime": 1517966743064,
  "name": "ieee-mms",
  "namespace": "scalablegroup:application",
  "provisioningState": "DEFINED",
  "qualifier": "application",
  "resourceVersion": 0,
  "targetIdList": [

  ],
  "type": "scalablegroup",
  "cfsChangeInfo": [

  ],
  "customProvisions": [

  ],
  "scalableGroupExternalHandle": "ieee-mms",
  "scalableGroupType": "APPLICATION",
  "securityGroupTag": 0,
  "state": "ACTIVE",
  "vnAgnostic": true,
  "identitySource": {
    "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
    "instanceId": 10010,
    "authEntityId": 10010,
    "displayName": "10010",
    "authEntityClass": 1738953278,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "state": "INACTIVE",
    "type": "NBAR"
  },
  "indicativeNetworkIdentity": [
    {
      "id": "1f120853-d66d-4b4a-92f6-9569eb9a7b76",
      "instanceId": 13481,
      "authEntityId": 13481,
      "displayName": "13481",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "651",
      "protocol": "TCP",
      "upperPort": 0
    },
    {
      "id": "f9448abd-6891-4686-970d-112566c282aa",
      "instanceId": 13482,
      "authEntityId": 13482,
      "displayName": "13482",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "651",
      "protocol": "UDP",
      "upperPort": 0
    }
  ],
  "networkApplications": [
    {
      "id": "740d912d-95c3-4372-8f8c-c3fd07bf9478",
      "instanceId": 11539,
      "authEntityId": 11539,
      "displayName": "11539",
      "authEntityClass": -217092956,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "appProtocol": "tcp/udp",
      "applicationGroup": "other",
      "applicationType": "DEFAULT",
      "categoryId": "6daa3dfd-3526-407a-88f0-97824b3f3a24",
      "createTime": 1517966743064,
      "encrypted": "false",
      "engineId": "3",
      "helpString": "ieee-mms",
      "ignoreConflict": false,
      "lastUpdateTime": 1517966743064,
      "longDescription": "The IEEE Media Management System (MMS) is a distributed, multi-platform system for managing removable media. The IEEE MMS standards de?ne a software component model for working with removable media as well as a number of protocols that de?ne interfaces between the components. These standards enable vendors to construct applications that use removable media as well as components of an MMS that interoperate with other MMS components.",
      "name": "ieee-mms",
      "nbarId": "560",
      "p2pTechnology": "false",
      "popularity": 1,
      "rank": 65535,
      "references": "http://grouper.ieee.org/groups/1619/email/pdf00001.pdf",
      "selectorId": "651",
      "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
      "trafficClass": "BULK_DATA",
      "tunnel": "false"
    }
  ],
  "networkIdentity": [
    {
      "id": "c1c2746c-a6db-45d7-ab9e-05c44eecfa66",
      "instanceId": 13479,
      "authEntityId": 13479,
      "displayName": "13479",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "651",
      "protocol": "UDP",
      "upperPort": 0
    },
    {
      "id": "46d6c6df-c85d-44a2-a052-a777362214f8",
      "instanceId": 13480,
      "authEntityId": 13480,
      "displayName": "13480",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "651",
      "protocol": "TCP",
      "upperPort": 0
    }
  ],
  "parentScalableGroup": {
    "idRef": "000c15bf-28fe-4fbb-9b69-35e6502721f1"
  },
  "user": [

  ],
  "userGroup": [

  ]
},
{
  "id": "8b44a4cc-9461-4a99-b430-0084dc24b041",
  "instanceId": 4174,
  "authEntityId": 4174,
  "displayName": "4174",
  "authEntityClass": -1909073334,
  "deployPending": "NONE",
  "instanceVersion": 0,
  "createTime": 1517966741532,
  "deployed": false,
  "isSeeded": true,
  "isStale": false,
  "lastUpdateTime": 1517966741532,
  "name": "bgs-nsi",
  "namespace": "scalablegroup:application",
  "provisioningState": "DEFINED",
  "qualifier": "application",
  "resourceVersion": 0,
  "targetIdList": [

  ],
  "type": "scalablegroup",
  "cfsChangeInfo": [

  ],
  "customProvisions": [

  ],
  "scalableGroupExternalHandle": "bgs-nsi",
  "scalableGroupType": "APPLICATION",
  "securityGroupTag": 0,
  "state": "ACTIVE",
  "vnAgnostic": true,
  "identitySource": {
    "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
    "instanceId": 10010,
    "authEntityId": 10010,
    "displayName": "10010",
    "authEntityClass": 1738953278,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "state": "INACTIVE",
    "type": "NBAR"
  },
  "indicativeNetworkIdentity": [
    {
      "id": "2fac3b0c-8c7d-48bd-aeca-381aa7d6f900",
      "instanceId": 12372,
      "authEntityId": 12372,
      "displayName": "12372",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "482",
      "protocol": "UDP",
      "upperPort": 0
    },
    {
      "id": "5761f487-fc54-41fd-a7e1-ec42afbd1b91",
      "instanceId": 12373,
      "authEntityId": 12373,
      "displayName": "12373",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "482",
      "protocol": "TCP",
      "upperPort": 0
    }
  ],
  "networkApplications": [
    {
      "id": "6960f4f3-0fd6-44c7-bfb7-4a6a999b81fa",
      "instanceId": 11133,
      "authEntityId": 11133,
      "displayName": "11133",
      "authEntityClass": -217092956,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "appProtocol": "tcp/udp",
      "applicationGroup": "other",
      "applicationType": "DEFAULT",
      "categoryId": "6daa3dfd-3526-407a-88f0-97824b3f3a24",
      "createTime": 1517966741532,
      "encrypted": "false",
      "engineId": "3",
      "helpString": "bgs-nsi",
      "ignoreConflict": false,
      "lastUpdateTime": 1517966741532,
      "longDescription": "Registered with IANA on port 482 TCP/UDP",
      "name": "bgs-nsi",
      "nbarId": "396",
      "p2pTechnology": "false",
      "popularity": 1,
      "rank": 65535,
      "references": "http://www.iana.org/assignments/service-names-port-numbers/service-names-port-numbers.xml",
      "selectorId": "482",
      "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
      "trafficClass": "BULK_DATA",
      "tunnel": "false"
    }
  ],
  "networkIdentity": [
    {
      "id": "271639bc-c80c-407e-9eb8-1f5bcd6e55ce",
      "instanceId": 12370,
      "authEntityId": 12370,
      "displayName": "12370",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "482",
      "protocol": "UDP",
      "upperPort": 0
    },
    {
      "id": "ccff9c44-4037-4b34-88f4-a7a0604b5025",
      "instanceId": 12371,
      "authEntityId": 12371,
      "displayName": "12371",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "482",
      "protocol": "TCP",
      "upperPort": 0
    }
  ],
  "parentScalableGroup": {
    "idRef": "000c15bf-28fe-4fbb-9b69-35e6502721f1"
  },
  "user": [

  ],
  "userGroup": [

  ]
},
{
  "id": "8b6b6313-f462-439a-be29-ab718776ff4d",
  "instanceId": 15054,
  "authEntityId": 15054,
  "displayName": "15054",
  "authEntityClass": -1909073334,
  "deployPending": "NONE",
  "instanceVersion": 0,
  "createTime": 1517966744127,
  "deployed": false,
  "isSeeded": true,
  "isStale": false,
  "lastUpdateTime": 1517966744127,
  "name": "rmc",
  "namespace": "scalablegroup:application",
  "provisioningState": "DEFINED",
  "qualifier": "application",
  "resourceVersion": 0,
  "targetIdList": [

  ],
  "type": "scalablegroup",
  "cfsChangeInfo": [

  ],
  "customProvisions": [

  ],
  "scalableGroupExternalHandle": "rmc",
  "scalableGroupType": "APPLICATION",
  "securityGroupTag": 0,
  "state": "ACTIVE",
  "vnAgnostic": true,
  "identitySource": {
    "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
    "instanceId": 10010,
    "authEntityId": 10010,
    "displayName": "10010",
    "authEntityClass": 1738953278,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "state": "INACTIVE",
    "type": "NBAR"
  },
  "indicativeNetworkIdentity": [
    {
      "id": "d4085f52-4695-43cf-a1c0-80d6ecef4e06",
      "instanceId": 14800,
      "authEntityId": 14800,
      "displayName": "14800",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "657",
      "protocol": "TCP",
      "upperPort": 0
    },
    {
      "id": "80e80661-b86e-4db6-8489-107f7d3a5e7d",
      "instanceId": 14799,
      "authEntityId": 14799,
      "displayName": "14799",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "657",
      "protocol": "UDP",
      "upperPort": 0
    }
  ],
  "networkApplications": [
    {
      "id": "b5c36832-3893-465a-8dac-235e83146d95",
      "instanceId": 11993,
      "authEntityId": 11993,
      "displayName": "11993",
      "authEntityClass": -217092956,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "appProtocol": "tcp/udp",
      "applicationGroup": "other",
      "applicationType": "DEFAULT",
      "categoryId": "6daa3dfd-3526-407a-88f0-97824b3f3a24",
      "createTime": 1517966744127,
      "encrypted": "false",
      "engineId": "3",
      "helpString": "rmc",
      "ignoreConflict": false,
      "lastUpdateTime": 1517966744127,
      "longDescription": "Rational Method Composeris a tool platform that enables process engineers and managers to implement, deploy, and maintain processes for organizations or individual projects.",
      "name": "rmc",
      "nbarId": "566",
      "p2pTechnology": "false",
      "popularity": 1,
      "rank": 65535,
      "references": "http://www-01.ibm.com/software/awdtools/rmc/",
      "selectorId": "657",
      "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
      "trafficClass": "BULK_DATA",
      "tunnel": "false"
    }
  ],
  "networkIdentity": [
    {
      "id": "70d156fd-04dc-405b-8330-fc9d15321a1a",
      "instanceId": 14797,
      "authEntityId": 14797,
      "displayName": "14797",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "657",
      "protocol": "UDP",
      "upperPort": 0
    },
    {
      "id": "dee28e75-2a63-4ddc-920f-d840d003b07b",
      "instanceId": 14798,
      "authEntityId": 14798,
      "displayName": "14798",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "657",
      "protocol": "TCP",
      "upperPort": 0
    }
  ],
  "parentScalableGroup": {
    "idRef": "000c15bf-28fe-4fbb-9b69-35e6502721f1"
  },
  "user": [

  ],
  "userGroup": [

  ]
},
{
  "id": "8bc549ce-465b-4903-b713-90740e8c564b",
  "instanceId": 4942,
  "authEntityId": 4942,
  "displayName": "4942",
  "authEntityClass": -1909073334,
  "deployPending": "NONE",
  "instanceVersion": 0,
  "createTime": 1517966743863,
  "deployed": false,
  "isSeeded": true,
  "isStale": false,
  "lastUpdateTime": 1517966743863,
  "name": "pbs-web-portal",
  "namespace": "scalablegroup:application",
  "provisioningState": "DEFINED",
  "qualifier": "application",
  "resourceVersion": 0,
  "targetIdList": [

  ],
  "type": "scalablegroup",
  "cfsChangeInfo": [

  ],
  "customProvisions": [

  ],
  "scalableGroupExternalHandle": "pbs-web-portal",
  "scalableGroupType": "APPLICATION",
  "securityGroupTag": 0,
  "state": "ACTIVE",
  "vnAgnostic": true,
  "identitySource": {
    "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
    "instanceId": 10010,
    "authEntityId": 10010,
    "displayName": "10010",
    "authEntityClass": 1738953278,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "state": "INACTIVE",
    "type": "NBAR"
  },
  "indicativeNetworkIdentity": [

  ],
  "networkApplications": [
    {
      "id": "795bfb47-7456-487d-8241-f1cdfe685757",
      "instanceId": 11891,
      "authEntityId": 11891,
      "displayName": "11891",
      "authEntityClass": -217092956,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "appProtocol": "tcp/udp",
      "applicationGroup": "other",
      "applicationType": "DEFAULT",
      "categoryId": "878644b5-fe91-45b4-9204-31c84a73ba48",
      "createTime": 1517966743863,
      "encrypted": "false",
      "engineId": "13",
      "helpString": "Public Broadcasting Service - an American television network.",
      "ignoreConflict": false,
      "lastUpdateTime": 1517966743863,
      "longDescription": "Public Broadcasting Service - an American television network.",
      "name": "pbs-web-portal",
      "nbarId": "1730",
      "p2pTechnology": "false",
      "popularity": 3,
      "rank": 65535,
      "references": "http://pbs.org",
      "selectorId": "802",
      "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
      "trafficClass": "TRANSACTIONAL_DATA",
      "tunnel": "false"
    }
  ],
  "networkIdentity": [
    {
      "id": "7ae69a7b-5170-4849-be5b-b175aeaabf9e",
      "instanceId": 14491,
      "authEntityId": 14491,
      "displayName": "14491",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "53,80,443,5353",
      "protocol": "TCP",
      "upperPort": 0
    },
    {
      "id": "66298552-d921-4569-b808-f77b6334d940",
      "instanceId": 14492,
      "authEntityId": 14492,
      "displayName": "14492",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "53,5353",
      "protocol": "UDP",
      "upperPort": 0
    }
  ],
  "parentScalableGroup": {
    "idRef": "28e08de4-d7b2-4521-84ce-bc1e5ee0b9cc"
  },
  "user": [

  ],
  "userGroup": [

  ]
},
{
  "id": "8be70c2e-ed74-46ad-b5cb-f4662e4888d6",
  "instanceId": 15368,
  "authEntityId": 15368,
  "displayName": "15368",
  "authEntityClass": -1909073334,
  "deployPending": "NONE",
  "instanceVersion": 0,
  "createTime": 1517966744820,
  "deployed": false,
  "isSeeded": true,
  "isStale": false,
  "lastUpdateTime": 1517966744820,
  "name": "vpps-qua",
  "namespace": "scalablegroup:application",
  "provisioningState": "DEFINED",
  "qualifier": "application",
  "resourceVersion": 0,
  "targetIdList": [

  ],
  "type": "scalablegroup",
  "cfsChangeInfo": [

  ],
  "customProvisions": [

  ],
  "scalableGroupExternalHandle": "vpps-qua",
  "scalableGroupType": "APPLICATION",
  "securityGroupTag": 0,
  "state": "ACTIVE",
  "vnAgnostic": true,
  "identitySource": {
    "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
    "instanceId": 10010,
    "authEntityId": 10010,
    "displayName": "10010",
    "authEntityClass": 1738953278,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "state": "INACTIVE",
    "type": "NBAR"
  },
  "indicativeNetworkIdentity": [
    {
      "id": "06511a6c-9fd3-4fba-a134-b9cc2de84b9a",
      "instanceId": 17719,
      "authEntityId": 17719,
      "displayName": "17719",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "672",
      "protocol": "TCP",
      "upperPort": 0
    },
    {
      "id": "bad31f05-3a6f-46a1-a2fc-cd7740b39654",
      "instanceId": 17720,
      "authEntityId": 17720,
      "displayName": "17720",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "672",
      "protocol": "UDP",
      "upperPort": 0
    }
  ],
  "networkApplications": [
    {
      "id": "45206f9a-5780-4093-ab52-d0221f8a6344",
      "instanceId": 16311,
      "authEntityId": 16311,
      "displayName": "16311",
      "authEntityClass": -217092956,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "appProtocol": "tcp/udp",
      "applicationGroup": "other",
      "applicationType": "DEFAULT",
      "categoryId": "cb5915d1-afec-4c30-ac9e-069ff382272f",
      "createTime": 1517966744820,
      "encrypted": "false",
      "engineId": "3",
      "helpString": "vpps-qua",
      "ignoreConflict": false,
      "lastUpdateTime": 1517966744820,
      "longDescription": "Registered with IANA on port 672 TCP/UDP",
      "name": "vpps-qua",
      "nbarId": "580",
      "p2pTechnology": "false",
      "popularity": 1,
      "rank": 65535,
      "references": "http://www.iana.org/assignments/service-names-port-numbers/service-names-port-numbers.xml",
      "selectorId": "672",
      "subCategory": "b90786f4-0190-45b8-9924-21b3e9682590",
      "trafficClass": "TRANSACTIONAL_DATA",
      "tunnel": "false"
    }
  ],
  "networkIdentity": [
    {
      "id": "e3a04412-70a8-40c5-95ad-acca39e1ac5f",
      "instanceId": 17717,
      "authEntityId": 17717,
      "displayName": "17717",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "672",
      "protocol": "UDP",
      "upperPort": 0
    },
    {
      "id": "41a532fe-fe34-4416-9a42-5f10c63fb858",
      "instanceId": 17718,
      "authEntityId": 17718,
      "displayName": "17718",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "672",
      "protocol": "TCP",
      "upperPort": 0
    }
  ],
  "parentScalableGroup": {
    "idRef": "8718395e-683e-4a2d-a232-100202751271"
  },
  "user": [

  ],
  "userGroup": [

  ]
},
{
  "id": "8c5dfff9-199e-44b7-98a1-e3d67f12563f",
  "instanceId": 4563,
  "authEntityId": 4563,
  "displayName": "4563",
  "authEntityClass": -1909073334,
  "deployPending": "NONE",
  "instanceVersion": 0,
  "createTime": 1517966742996,
  "deployed": false,
  "isSeeded": true,
  "isStale": false,
  "lastUpdateTime": 1517966742996,
  "name": "https",
  "namespace": "scalablegroup:application",
  "provisioningState": "DEFINED",
  "qualifier": "application",
  "resourceVersion": 0,
  "targetIdList": [

  ],
  "type": "scalablegroup",
  "cfsChangeInfo": [

  ],
  "customProvisions": [

  ],
  "scalableGroupExternalHandle": "https",
  "scalableGroupType": "APPLICATION",
  "securityGroupTag": 0,
  "state": "ACTIVE",
  "vnAgnostic": true,
  "identitySource": {
    "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
    "instanceId": 10010,
    "authEntityId": 10010,
    "displayName": "10010",
    "authEntityClass": 1738953278,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "state": "INACTIVE",
    "type": "NBAR"
  },
  "indicativeNetworkIdentity": [
    {
      "id": "45eda9e0-f7eb-403b-87b9-221bbd03ddb4",
      "instanceId": 13401,
      "authEntityId": 13401,
      "displayName": "13401",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "443",
      "protocol": "UDP",
      "upperPort": 0
    },
    {
      "id": "c2838cde-da2e-4a2e-8327-5fcd381b1df4",
      "instanceId": 13402,
      "authEntityId": 13402,
      "displayName": "13402",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "443",
      "protocol": "TCP",
      "upperPort": 0
    }
  ],
  "networkApplications": [
    {
      "id": "2ae75db9-4d7e-490c-aa39-731c25e1e900",
      "instanceId": 11513,
      "authEntityId": 11513,
      "displayName": "11513",
      "authEntityClass": -217092956,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "appProtocol": "tcp/udp",
      "applicationGroup": "other",
      "applicationType": "DEFAULT",
      "categoryId": "878644b5-fe91-45b4-9204-31c84a73ba48",
      "createTime": 1517966742996,
      "encrypted": "true",
      "engineId": "3",
      "helpString": "Hypertext Transfer Protocol Secure (HTTPS)",
      "ignoreConflict": false,
      "lastUpdateTime": 1517966742996,
      "longDescription": "Hypertext Transfer Protocol Secure (HTTPS)",
      "name": "https",
      "nbarId": "1738",
      "p2pTechnology": "false",
      "popularity": 5,
      "rank": 65535,
      "references": "https://tools.ietf.org/html/rfc2818",
      "selectorId": "1443",
      "subCategory": "b90786f4-0190-45b8-9924-21b3e9682590",
      "trafficClass": "TRANSACTIONAL_DATA",
      "tunnel": "false"
    }
  ],
  "networkIdentity": [
    {
      "id": "d492b89e-204e-4739-aff9-31d95162d656",
      "instanceId": 13399,
      "authEntityId": 13399,
      "displayName": "13399",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "443",
      "protocol": "TCP",
      "upperPort": 0
    },
    {
      "id": "03557be3-d60f-4db2-b1a5-346417c94757",
      "instanceId": 13400,
      "authEntityId": 13400,
      "displayName": "13400",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "443",
      "protocol": "UDP",
      "upperPort": 0
    }
  ],
  "parentScalableGroup": {
    "idRef": "53f03553-069a-4046-ad7c-84610eb579c5"
  },
  "user": [

  ],
  "userGroup": [

  ]
},
{
  "id": "8c7ce781-3a75-4c4d-ae93-bfdc187b37e9",
  "instanceId": 4087,
  "authEntityId": 4087,
  "displayName": "4087",
  "authEntityClass": -1909073334,
  "deployPending": "NONE",
  "instanceVersion": 0,
  "createTime": 1517966740965,
  "deployed": false,
  "isSeeded": true,
  "isStale": false,
  "lastUpdateTime": 1517966740965,
  "name": "anet",
  "namespace": "scalablegroup:application",
  "provisioningState": "DEFINED",
  "qualifier": "application",
  "resourceVersion": 0,
  "targetIdList": [

  ],
  "type": "scalablegroup",
  "cfsChangeInfo": [

  ],
  "customProvisions": [

  ],
  "scalableGroupExternalHandle": "anet",
  "scalableGroupType": "APPLICATION",
  "securityGroupTag": 0,
  "state": "ACTIVE",
  "vnAgnostic": true,
  "identitySource": {
    "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
    "instanceId": 10010,
    "authEntityId": 10010,
    "displayName": "10010",
    "authEntityClass": 1738953278,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "state": "INACTIVE",
    "type": "NBAR"
  },
  "indicativeNetworkIdentity": [
    {
      "id": "2b44393c-93cd-44da-ac32-4009a1c64682",
      "instanceId": 12130,
      "authEntityId": 12130,
      "displayName": "12130",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "212",
      "protocol": "TCP",
      "upperPort": 0
    },
    {
      "id": "c46dee87-1915-4691-8798-3ddd6d13d080",
      "instanceId": 12131,
      "authEntityId": 12131,
      "displayName": "12131",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "212",
      "protocol": "UDP",
      "upperPort": 0
    }
  ],
  "networkApplications": [
    {
      "id": "f5f7bf5d-0eaf-4fc0-ba85-5234d0f249dc",
      "instanceId": 11052,
      "authEntityId": 11052,
      "displayName": "11052",
      "authEntityClass": -217092956,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "appProtocol": "tcp/udp",
      "applicationGroup": "other",
      "applicationType": "DEFAULT",
      "categoryId": "6daa3dfd-3526-407a-88f0-97824b3f3a24",
      "createTime": 1517966740964,
      "encrypted": "false",
      "engineId": "3",
      "helpString": "ATEXSSTR",
      "ignoreConflict": false,
      "lastUpdateTime": 1517966740964,
      "longDescription": "Registered with IANA on port 212 TCP/UDP",
      "name": "anet",
      "nbarId": "1110",
      "p2pTechnology": "false",
      "popularity": 1,
      "rank": 65535,
      "references": "http://www.iana.org/assignments/service-names-port-numbers/service-names-port-numbers.xml",
      "selectorId": "212",
      "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
      "trafficClass": "BULK_DATA",
      "tunnel": "false"
    }
  ],
  "networkIdentity": [
    {
      "id": "e5d133ea-598d-42a6-a09c-ae1b1930ec1c",
      "instanceId": 12128,
      "authEntityId": 12128,
      "displayName": "12128",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "212",
      "protocol": "UDP",
      "upperPort": 0
    },
    {
      "id": "76a50e2a-17dd-4989-ab42-7d359472c48a",
      "instanceId": 12129,
      "authEntityId": 12129,
      "displayName": "12129",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "212",
      "protocol": "TCP",
      "upperPort": 0
    }
  ],
  "parentScalableGroup": {
    "idRef": "000c15bf-28fe-4fbb-9b69-35e6502721f1"
  },
  "user": [

  ],
  "userGroup": [

  ]
},
{
  "id": "8c7d3c5c-5363-4192-8712-5c811dddbf93",
  "instanceId": 4271,
  "authEntityId": 4271,
  "displayName": "4271",
  "authEntityClass": -1909073334,
  "deployPending": "NONE",
  "instanceVersion": 0,
  "createTime": 1517966742028,
  "deployed": false,
  "isSeeded": true,
  "isStale": false,
  "lastUpdateTime": 1517966742028,
  "name": "coauthor",
  "namespace": "scalablegroup:application",
  "provisioningState": "DEFINED",
  "qualifier": "application",
  "resourceVersion": 0,
  "targetIdList": [

  ],
  "type": "scalablegroup",
  "cfsChangeInfo": [

  ],
  "customProvisions": [

  ],
  "scalableGroupExternalHandle": "coauthor",
  "scalableGroupType": "APPLICATION",
  "securityGroupTag": 0,
  "state": "ACTIVE",
  "vnAgnostic": true,
  "identitySource": {
    "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
    "instanceId": 10010,
    "authEntityId": 10010,
    "displayName": "10010",
    "authEntityClass": 1738953278,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "state": "INACTIVE",
    "type": "NBAR"
  },
  "indicativeNetworkIdentity": [
    {
      "id": "b5a90301-f211-4eea-a282-1f7a61cc5f42",
      "instanceId": 12586,
      "authEntityId": 12586,
      "displayName": "12586",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "1529",
      "protocol": "UDP",
      "upperPort": 0
    },
    {
      "id": "71a2f6e6-b56a-49b3-8a5a-31b8e8b83c86",
      "instanceId": 12587,
      "authEntityId": 12587,
      "displayName": "12587",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "1529",
      "protocol": "TCP",
      "upperPort": 0
    }
  ],
  "networkApplications": [
    {
      "id": "e4484e7e-41e5-43d1-83fe-e48a60352776",
      "instanceId": 11224,
      "authEntityId": 11224,
      "displayName": "11224",
      "authEntityClass": -217092956,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "appProtocol": "tcp/udp",
      "applicationGroup": "sqlsvr-group",
      "applicationType": "DEFAULT",
      "categoryId": "cb5915d1-afec-4c30-ac9e-069ff382272f",
      "createTime": 1517966742028,
      "encrypted": "false",
      "engineId": "3",
      "helpString": "oracle",
      "ignoreConflict": false,
      "lastUpdateTime": 1517966742028,
      "longDescription": "Registered with IANA on port 1529 TCP/UDP",
      "name": "coauthor",
      "nbarId": "693",
      "p2pTechnology": "false",
      "popularity": 1,
      "rank": 65535,
      "references": "http://www.iana.org/assignments/service-names-port-numbers/service-names-port-numbers.xml",
      "selectorId": "1529",
      "subCategory": "b90786f4-0190-45b8-9924-21b3e9682590",
      "trafficClass": "TRANSACTIONAL_DATA",
      "tunnel": "false"
    }
  ],
  "networkIdentity": [
    {
      "id": "ba7c8dc8-040d-4250-9044-44af4e901248",
      "instanceId": 12584,
      "authEntityId": 12584,
      "displayName": "12584",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "1529",
      "protocol": "TCP",
      "upperPort": 0
    },
    {
      "id": "39eb1074-ea4d-4e0e-86bd-29012057abd7",
      "instanceId": 12585,
      "authEntityId": 12585,
      "displayName": "12585",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "1529",
      "protocol": "UDP",
      "upperPort": 0
    }
  ],
  "parentScalableGroup": {
    "idRef": "8718395e-683e-4a2d-a232-100202751271"
  },
  "user": [

  ],
  "userGroup": [

  ]
},
{
  "id": "8d02d694-f353-4704-84c1-31e608c16cab",
  "instanceId": 4701,
  "authEntityId": 4701,
  "displayName": "4701",
  "authEntityClass": -1909073334,
  "deployPending": "NONE",
  "instanceVersion": 0,
  "createTime": 1517966743313,
  "deployed": false,
  "isSeeded": true,
  "isStale": false,
  "lastUpdateTime": 1517966743313,
  "name": "ljk-login",
  "namespace": "scalablegroup:application",
  "provisioningState": "DEFINED",
  "qualifier": "application",
  "resourceVersion": 0,
  "targetIdList": [

  ],
  "type": "scalablegroup",
  "cfsChangeInfo": [

  ],
  "customProvisions": [

  ],
  "scalableGroupExternalHandle": "ljk-login",
  "scalableGroupType": "APPLICATION",
  "securityGroupTag": 0,
  "state": "ACTIVE",
  "vnAgnostic": true,
  "identitySource": {
    "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
    "instanceId": 10010,
    "authEntityId": 10010,
    "displayName": "10010",
    "authEntityClass": 1738953278,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "state": "INACTIVE",
    "type": "NBAR"
  },
  "indicativeNetworkIdentity": [
    {
      "id": "7eecaecd-bb8f-412c-b5f5-0bb49a9fce27",
      "instanceId": 13770,
      "authEntityId": 13770,
      "displayName": "13770",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "472",
      "protocol": "UDP",
      "upperPort": 0
    },
    {
      "id": "cf4568bd-4c9b-408e-8daa-cd7300ca854b",
      "instanceId": 13771,
      "authEntityId": 13771,
      "displayName": "13771",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "472",
      "protocol": "TCP",
      "upperPort": 0
    }
  ],
  "networkApplications": [
    {
      "id": "523bd27e-6920-435d-8514-c3bf88bd8980",
      "instanceId": 11651,
      "authEntityId": 11651,
      "displayName": "11651",
      "authEntityClass": -217092956,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "appProtocol": "tcp/udp",
      "applicationGroup": "other",
      "applicationType": "DEFAULT",
      "categoryId": "6daa3dfd-3526-407a-88f0-97824b3f3a24",
      "createTime": 1517966743313,
      "encrypted": "false",
      "engineId": "3",
      "helpString": "ljk-login",
      "ignoreConflict": false,
      "lastUpdateTime": 1517966743313,
      "longDescription": "Registered with IANA on port 472 TCP/UDP",
      "name": "ljk-login",
      "nbarId": "386",
      "p2pTechnology": "false",
      "popularity": 1,
      "rank": 65535,
      "references": "http://www.iana.org/assignments/service-names-port-numbers/service-names-port-numbers.xml",
      "selectorId": "472",
      "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
      "trafficClass": "BULK_DATA",
      "tunnel": "false"
    }
  ],
  "networkIdentity": [
    {
      "id": "e9cc94f4-32b8-4a17-8826-822d405de425",
      "instanceId": 13768,
      "authEntityId": 13768,
      "displayName": "13768",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "472",
      "protocol": "TCP",
      "upperPort": 0
    },
    {
      "id": "0a914741-f559-47ee-bf42-2b452407e66f",
      "instanceId": 13769,
      "authEntityId": 13769,
      "displayName": "13769",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "472",
      "protocol": "UDP",
      "upperPort": 0
    }
  ],
  "parentScalableGroup": {
    "idRef": "000c15bf-28fe-4fbb-9b69-35e6502721f1"
  },
  "user": [

  ],
  "userGroup": [

  ]
},
{
  "id": "8d05728c-e0f7-44be-bd84-6a2e0d2c5a60",
  "instanceId": 4421,
  "authEntityId": 4421,
  "displayName": "4421",
  "authEntityClass": -1909073334,
  "deployPending": "NONE",
  "instanceVersion": 0,
  "createTime": 1517966742599,
  "deployed": false,
  "isSeeded": true,
  "isStale": false,
  "lastUpdateTime": 1517966742599,
  "name": "escp-ip",
  "namespace": "scalablegroup:application",
  "provisioningState": "DEFINED",
  "qualifier": "application",
  "resourceVersion": 0,
  "targetIdList": [

  ],
  "type": "scalablegroup",
  "cfsChangeInfo": [

  ],
  "customProvisions": [

  ],
  "scalableGroupExternalHandle": "escp-ip",
  "scalableGroupType": "APPLICATION",
  "securityGroupTag": 0,
  "state": "ACTIVE",
  "vnAgnostic": true,
  "identitySource": {
    "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
    "instanceId": 10010,
    "authEntityId": 10010,
    "displayName": "10010",
    "authEntityClass": 1738953278,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "state": "INACTIVE",
    "type": "NBAR"
  },
  "indicativeNetworkIdentity": [
    {
      "id": "41d528df-0f31-4768-9d00-d689e75ca24a",
      "instanceId": 13062,
      "authEntityId": 13062,
      "displayName": "13062",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "621",
      "protocol": "UDP",
      "upperPort": 0
    },
    {
      "id": "f450fd8b-149f-4992-95de-5e9cec75a7c2",
      "instanceId": 13063,
      "authEntityId": 13063,
      "displayName": "13063",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "621",
      "protocol": "TCP",
      "upperPort": 0
    }
  ],
  "networkApplications": [
    {
      "id": "9265232c-275d-490c-8471-d743a5b19570",
      "instanceId": 11372,
      "authEntityId": 11372,
      "displayName": "11372",
      "authEntityClass": -217092956,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "appProtocol": "tcp/udp",
      "applicationGroup": "other",
      "applicationType": "DEFAULT",
      "categoryId": "6daa3dfd-3526-407a-88f0-97824b3f3a24",
      "createTime": 1517966742599,
      "encrypted": "false",
      "engineId": "3",
      "helpString": "escp-ip",
      "ignoreConflict": false,
      "lastUpdateTime": 1517966742599,
      "longDescription": "Registered with IANA on port 621 TCP/UDP",
      "name": "escp-ip",
      "nbarId": "530",
      "p2pTechnology": "false",
      "popularity": 1,
      "rank": 65535,
      "references": "http://www.iana.org/assignments/service-names-port-numbers/service-names-port-numbers.xml",
      "selectorId": "621",
      "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
      "trafficClass": "BULK_DATA",
      "tunnel": "false"
    }
  ],
  "networkIdentity": [
    {
      "id": "d06c2089-bf89-4031-b1df-fd87dda97399",
      "instanceId": 13060,
      "authEntityId": 13060,
      "displayName": "13060",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "621",
      "protocol": "UDP",
      "upperPort": 0
    },
    {
      "id": "48e5937a-730d-46c4-82d9-93a8468498ab",
      "instanceId": 13061,
      "authEntityId": 13061,
      "displayName": "13061",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "621",
      "protocol": "TCP",
      "upperPort": 0
    }
  ],
  "parentScalableGroup": {
    "idRef": "000c15bf-28fe-4fbb-9b69-35e6502721f1"
  },
  "user": [

  ],
  "userGroup": [

  ]
},
{
  "id": "8d56abd9-147f-4957-983d-2fec54a699d3",
  "instanceId": 4734,
  "authEntityId": 4734,
  "displayName": "4734",
  "authEntityClass": -1909073334,
  "deployPending": "NONE",
  "instanceVersion": 0,
  "createTime": 1517966743387,
  "deployed": false,
  "isSeeded": true,
  "isStale": false,
  "lastUpdateTime": 1517966743387,
  "name": "megavideo",
  "namespace": "scalablegroup:application",
  "provisioningState": "DEFINED",
  "qualifier": "application",
  "resourceVersion": 0,
  "targetIdList": [

  ],
  "type": "scalablegroup",
  "cfsChangeInfo": [

  ],
  "customProvisions": [

  ],
  "scalableGroupExternalHandle": "megavideo",
  "scalableGroupType": "APPLICATION",
  "securityGroupTag": 0,
  "state": "ACTIVE",
  "vnAgnostic": true,
  "identitySource": {
    "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
    "instanceId": 10010,
    "authEntityId": 10010,
    "displayName": "10010",
    "authEntityClass": 1738953278,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "state": "INACTIVE",
    "type": "NBAR"
  },
  "indicativeNetworkIdentity": [

  ],
  "networkApplications": [
    {
      "id": "e9f91f1a-3b0d-4887-8db8-c953f973c7bb",
      "instanceId": 11684,
      "authEntityId": 11684,
      "displayName": "11684",
      "authEntityClass": -217092956,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "applicationGroup": "other",
      "applicationType": "DEFAULT",
      "categoryId": "6daa3dfd-3526-407a-88f0-97824b3f3a24",
      "createTime": 1517966743387,
      "encrypted": "unassigned",
      "engineId": "13",
      "helpString": "DEPRECATED, traffic will not match",
      "ignoreConflict": false,
      "lastUpdateTime": 1517966743387,
      "longDescription": "MegaVideo is a video sharing website run by the creators of Megaupload, the website officially claims to aim replacing YouTube as the leader in online video. MegaVideo is a website, therefore the underlying protocol is HTTP.",
      "name": "megavideo",
      "nbarId": "1318",
      "p2pTechnology": "unassigned",
      "popularity": 0,
      "rank": 65535,
      "references": "http://www.megavideo.com/",
      "selectorId": "459",
      "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
      "trafficClass": "BULK_DATA",
      "tunnel": "unassigned"
    }
  ],
  "networkIdentity": [

  ],
  "parentScalableGroup": {
    "idRef": "000c15bf-28fe-4fbb-9b69-35e6502721f1"
  },
  "user": [

  ],
  "userGroup": [

  ]
},
{
  "id": "8d58741e-21db-4c9e-ac32-ff19ac233fbc",
  "instanceId": 4671,
  "authEntityId": 4671,
  "displayName": "4671",
  "authEntityClass": -1909073334,
  "deployPending": "NONE",
  "instanceVersion": 0,
  "createTime": 1517966743250,
  "deployed": false,
  "isSeeded": true,
  "isStale": false,
  "lastUpdateTime": 1517966743250,
  "name": "kazaa2",
  "namespace": "scalablegroup:application",
  "provisioningState": "DEFINED",
  "qualifier": "application",
  "resourceVersion": 0,
  "targetIdList": [

  ],
  "type": "scalablegroup",
  "cfsChangeInfo": [

  ],
  "customProvisions": [

  ],
  "scalableGroupExternalHandle": "kazaa2",
  "scalableGroupType": "APPLICATION",
  "securityGroupTag": 0,
  "state": "ACTIVE",
  "vnAgnostic": true,
  "identitySource": {
    "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
    "instanceId": 10010,
    "authEntityId": 10010,
    "displayName": "10010",
    "authEntityClass": 1738953278,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "state": "INACTIVE",
    "type": "NBAR"
  },
  "indicativeNetworkIdentity": [

  ],
  "networkApplications": [
    {
      "id": "9a16891d-8e3f-4b7a-a2ae-22f244ee5c35",
      "instanceId": 11621,
      "authEntityId": 11621,
      "displayName": "11621",
      "authEntityClass": -217092956,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "applicationGroup": "other",
      "applicationType": "DEFAULT",
      "categoryId": "6daa3dfd-3526-407a-88f0-97824b3f3a24",
      "createTime": 1517966743250,
      "encrypted": "unassigned",
      "engineId": "13",
      "helpString": "DEPRECATED, traffic will not match",
      "ignoreConflict": false,
      "lastUpdateTime": 1517966743250,
      "longDescription": "Kazaa is an online music subscription service that is based on second generation peer-to-peer technology FastTracker.",
      "name": "kazaa2",
      "nbarId": "59",
      "p2pTechnology": "unassigned",
      "popularity": 0,
      "rank": 65535,
      "references": "http://www.kazaa.com/#!/about",
      "selectorId": "59",
      "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
      "trafficClass": "BULK_DATA",
      "tunnel": "unassigned"
    }
  ],
  "networkIdentity": [

  ],
  "parentScalableGroup": {
    "idRef": "000c15bf-28fe-4fbb-9b69-35e6502721f1"
  },
  "user": [

  ],
  "userGroup": [

  ]
},
{
  "id": "8d609ffd-5ae7-4397-9e04-5a25fcf0baba",
  "instanceId": 4495,
  "authEntityId": 4495,
  "displayName": "4495",
  "authEntityClass": -1909073334,
  "deployPending": "NONE",
  "instanceVersion": 0,
  "createTime": 1517966742815,
  "deployed": false,
  "isSeeded": true,
  "isStale": false,
  "lastUpdateTime": 1517966742815,
  "name": "google-docs",
  "namespace": "scalablegroup:application",
  "provisioningState": "DEFINED",
  "qualifier": "application",
  "resourceVersion": 0,
  "targetIdList": [

  ],
  "type": "scalablegroup",
  "cfsChangeInfo": [

  ],
  "customProvisions": [

  ],
  "scalableGroupExternalHandle": "google-docs",
  "scalableGroupType": "APPLICATION",
  "securityGroupTag": 0,
  "state": "ACTIVE",
  "vnAgnostic": true,
  "identitySource": {
    "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
    "instanceId": 10010,
    "authEntityId": 10010,
    "displayName": "10010",
    "authEntityClass": 1738953278,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "state": "INACTIVE",
    "type": "NBAR"
  },
  "indicativeNetworkIdentity": [

  ],
  "networkApplications": [
    {
      "id": "4e9c620b-4533-463a-9102-75b32fe3423b",
      "instanceId": 11446,
      "authEntityId": 11446,
      "displayName": "11446",
      "authEntityClass": -217092956,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "appProtocol": "tcp/udp",
      "applicationGroup": "google-group",
      "applicationType": "DEFAULT",
      "categoryId": "b33b2761-d981-4734-810a-a6a1c18ab352",
      "createTime": 1517966742815,
      "encrypted": "true",
      "engineId": "13",
      "helpString": "google-docs, web-based office suite and data storage",
      "ignoreConflict": false,
      "lastUpdateTime": 1517966742815,
      "longDescription": "Google Docs also known as Google Drive, is a free, web-based office suite and data storage service, offered by Google Inc. It enables the user to create and edit documents in different formats. In addition, it allows to upload, host view and share files with other users.",
      "name": "google-docs",
      "nbarId": "1458",
      "p2pTechnology": "true",
      "popularity": 10,
      "rank": 65535,
      "references": "https://docs.google.com/#home",
      "selectorId": "522",
      "subCategory": "9fecfe24-2ee3-4b19-bbf7-3c992d1d25b4",
      "trafficClass": "TRANSACTIONAL_DATA",
      "tunnel": "false"
    }
  ],
  "networkIdentity": [
    {
      "id": "7f7684ef-fac1-49b9-99ed-be0fba84192f",
      "instanceId": 13234,
      "authEntityId": 13234,
      "displayName": "13234",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "53,5353",
      "protocol": "UDP",
      "upperPort": 0
    },
    {
      "id": "611d6654-064d-46e7-8876-234677ec65e9",
      "instanceId": 13235,
      "authEntityId": 13235,
      "displayName": "13235",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "53,80,443,5353",
      "protocol": "TCP",
      "upperPort": 0
    }
  ],
  "parentScalableGroup": {
    "idRef": "7a1a3bbb-dc84-4a35-82a4-7e650c4c4761"
  },
  "user": [

  ],
  "userGroup": [

  ]
},
{
  "id": "8d61d453-fa5f-4d8a-9731-f1cb0cc722d8",
  "instanceId": 4410,
  "authEntityId": 4410,
  "displayName": "4410",
  "authEntityClass": -1909073334,
  "deployPending": "NONE",
  "instanceVersion": 0,
  "createTime": 1517966742566,
  "deployed": false,
  "isSeeded": true,
  "isStale": false,
  "lastUpdateTime": 1517966742566,
  "name": "encrypted-emule",
  "namespace": "scalablegroup:application",
  "provisioningState": "DEFINED",
  "qualifier": "application",
  "resourceVersion": 0,
  "targetIdList": [

  ],
  "type": "scalablegroup",
  "cfsChangeInfo": [

  ],
  "customProvisions": [

  ],
  "scalableGroupExternalHandle": "encrypted-emule",
  "scalableGroupType": "APPLICATION",
  "securityGroupTag": 0,
  "state": "ACTIVE",
  "vnAgnostic": true,
  "identitySource": {
    "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
    "instanceId": 10010,
    "authEntityId": 10010,
    "displayName": "10010",
    "authEntityClass": 1738953278,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "state": "INACTIVE",
    "type": "NBAR"
  },
  "indicativeNetworkIdentity": [
    {
      "id": "889b9cc1-6db8-4aaa-a804-eb5fa0f97209",
      "instanceId": 13023,
      "authEntityId": 13023,
      "displayName": "13023",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "443",
      "protocol": "TCP",
      "upperPort": 0
    }
  ],
  "networkApplications": [
    {
      "id": "fd5dd87a-cfff-476d-89fc-b8670516f5aa",
      "instanceId": 11361,
      "authEntityId": 11361,
      "displayName": "11361",
      "authEntityClass": -217092956,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "appProtocol": "tcp",
      "applicationGroup": "edonkey-emule-group",
      "applicationType": "DEFAULT",
      "categoryId": "b33b2761-d981-4734-810a-a6a1c18ab352",
      "createTime": 1517966742566,
      "encrypted": "true",
      "engineId": "13",
      "helpString": "P2P file sharing encrypted protocol",
      "ignoreConflict": false,
      "lastUpdateTime": 1517966742566,
      "longDescription": "encrypted-emule, represents the encrypted traffic of eMule which is a peer-to-peer file sharing application based on eDonkey, eDonkey2000 and Kad network",
      "name": "encrypted-emule",
      "nbarId": "885",
      "p2pTechnology": "true",
      "popularity": 10,
      "rank": 65535,
      "references": "http://www.emule-project.net/",
      "selectorId": "417",
      "subCategory": "79fe7198-9ce3-4c01-9b58-45a8df3d9513",
      "trafficClass": "BULK_DATA",
      "tunnel": "false"
    }
  ],
  "networkIdentity": [
    {
      "id": "ed7b8169-7e31-4b34-a01f-03a0b62c7b51",
      "instanceId": 13022,
      "authEntityId": 13022,
      "displayName": "13022",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "443,4662",
      "protocol": "TCP",
      "upperPort": 0
    }
  ],
  "parentScalableGroup": {
    "idRef": "7a1a3bbb-dc84-4a35-82a4-7e650c4c4761"
  },
  "user": [

  ],
  "userGroup": [

  ]
},
{
  "id": "8d652ffb-4f8b-429b-99a4-cfbb137117ae",
  "instanceId": 4727,
  "authEntityId": 4727,
  "displayName": "4727",
  "authEntityClass": -1909073334,
  "deployPending": "NONE",
  "instanceVersion": 0,
  "createTime": 1517966743368,
  "deployed": false,
  "isSeeded": true,
  "isStale": false,
  "lastUpdateTime": 1517966743368,
  "name": "maxdb",
  "namespace": "scalablegroup:application",
  "provisioningState": "DEFINED",
  "qualifier": "application",
  "resourceVersion": 0,
  "targetIdList": [

  ],
  "type": "scalablegroup",
  "cfsChangeInfo": [

  ],
  "customProvisions": [

  ],
  "scalableGroupExternalHandle": "maxdb",
  "scalableGroupType": "APPLICATION",
  "securityGroupTag": 0,
  "state": "ACTIVE",
  "vnAgnostic": true,
  "identitySource": {
    "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
    "instanceId": 10010,
    "authEntityId": 10010,
    "displayName": "10010",
    "authEntityClass": 1738953278,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "state": "INACTIVE",
    "type": "NBAR"
  },
  "indicativeNetworkIdentity": [
    {
      "id": "04487b41-be0b-42b9-9f9c-352fae5629cc",
      "instanceId": 13843,
      "authEntityId": 13843,
      "displayName": "13843",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "7210",
      "protocol": "TCP",
      "upperPort": 0
    }
  ],
  "networkApplications": [
    {
      "id": "98249bed-9541-41da-b1ed-fb15993dd9fa",
      "instanceId": 11677,
      "authEntityId": 11677,
      "displayName": "11677",
      "authEntityClass": -217092956,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "appProtocol": "tcp",
      "applicationGroup": "other",
      "applicationType": "DEFAULT",
      "categoryId": "6daa3dfd-3526-407a-88f0-97824b3f3a24",
      "createTime": 1517966743368,
      "encrypted": "false",
      "engineId": "3",
      "helpString": "MaxDB relational database management system",
      "ignoreConflict": false,
      "lastUpdateTime": 1517966743368,
      "longDescription": "MaxDB is an ANSI SQL-92 (entry level) compliant relational database management system (RDBMS) from SAP AG, which was delivered also by MySQL AB from 2003 to 2007. MaxDB is targeted for large SAP environments e.g. mySAP Business Suite and other applications that require enterprise-level database functionality. It is able to run terabyte-range data in continuous operation.",
      "name": "maxdb",
      "nbarId": "1353",
      "p2pTechnology": "false",
      "popularity": 2,
      "rank": 65535,
      "references": "http://maxdb.sap.com/",
      "selectorId": "7210",
      "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
      "trafficClass": "BULK_DATA",
      "tunnel": "false"
    }
  ],
  "networkIdentity": [
    {
      "id": "8fa50674-36c2-41ae-bb00-d6b085ae746f",
      "instanceId": 13842,
      "authEntityId": 13842,
      "displayName": "13842",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "7210",
      "protocol": "TCP",
      "upperPort": 0
    }
  ],
  "parentScalableGroup": {
    "idRef": "000c15bf-28fe-4fbb-9b69-35e6502721f1"
  },
  "user": [

  ],
  "userGroup": [

  ]
},
{
  "id": "8da44069-08b5-43c3-b4fa-23933236f5fb",
  "instanceId": 15061,
  "authEntityId": 15061,
  "displayName": "15061",
  "authEntityClass": -1909073334,
  "deployPending": "NONE",
  "instanceVersion": 0,
  "createTime": 1517966744143,
  "deployed": false,
  "isSeeded": true,
  "isStale": false,
  "lastUpdateTime": 1517966744143,
  "name": "rrh",
  "namespace": "scalablegroup:application",
  "provisioningState": "DEFINED",
  "qualifier": "application",
  "resourceVersion": 0,
  "targetIdList": [

  ],
  "type": "scalablegroup",
  "cfsChangeInfo": [

  ],
  "customProvisions": [

  ],
  "scalableGroupExternalHandle": "rrh",
  "scalableGroupType": "APPLICATION",
  "securityGroupTag": 0,
  "state": "ACTIVE",
  "vnAgnostic": true,
  "identitySource": {
    "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
    "instanceId": 10010,
    "authEntityId": 10010,
    "displayName": "10010",
    "authEntityClass": 1738953278,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "state": "INACTIVE",
    "type": "NBAR"
  },
  "indicativeNetworkIdentity": [
    {
      "id": "1270dde1-3495-419f-881e-ffd8dfc6d654",
      "instanceId": 14823,
      "authEntityId": 14823,
      "displayName": "14823",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "753",
      "protocol": "TCP",
      "upperPort": 0
    },
    {
      "id": "bcb3a964-6ae3-4153-8a2d-405e18178ffb",
      "instanceId": 14824,
      "authEntityId": 14824,
      "displayName": "14824",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "753",
      "protocol": "UDP",
      "upperPort": 0
    }
  ],
  "networkApplications": [
    {
      "id": "28e9da7a-5adf-4f09-8ca5-2b09b3bb5d78",
      "instanceId": 12000,
      "authEntityId": 12000,
      "displayName": "12000",
      "authEntityClass": -217092956,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "appProtocol": "tcp/udp",
      "applicationGroup": "other",
      "applicationType": "DEFAULT",
      "categoryId": "1fc17362-9334-4c7b-b752-5afdbb574017",
      "createTime": 1517966744143,
      "encrypted": "false",
      "engineId": "3",
      "helpString": "rrh",
      "ignoreConflict": false,
      "lastUpdateTime": 1517966744143,
      "longDescription": "Reverse Routing Header (RRH) is a variable size reverse route header used to learn a path back hop-by-hop.  It is formed of addresses that are located on the egress interface of the packets.",
      "name": "rrh",
      "nbarId": "628",
      "p2pTechnology": "false",
      "popularity": 1,
      "rank": 65535,
      "references": "http://tools.ietf.org/html/draft-thubert-6man-reverse-routing-header-00",
      "selectorId": "753",
      "subCategory": "7cc1b3a6-4102-44e9-b522-0e71afe39a03",
      "trafficClass": "NETWORK_CONTROL",
      "tunnel": "false"
    }
  ],
  "networkIdentity": [
    {
      "id": "30e89471-fa15-4d03-bcd7-23c2f560e5d0",
      "instanceId": 14821,
      "authEntityId": 14821,
      "displayName": "14821",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "753",
      "protocol": "TCP",
      "upperPort": 0
    },
    {
      "id": "ed66c457-2f71-4a71-843a-ea5f5dc45cbc",
      "instanceId": 14822,
      "authEntityId": 14822,
      "displayName": "14822",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "753",
      "protocol": "UDP",
      "upperPort": 0
    }
  ],
  "parentScalableGroup": {
    "idRef": "77e75842-e98b-4112-ad0c-300db4bb11be"
  },
  "user": [

  ],
  "userGroup": [

  ]
},
{
  "id": "8e185826-93e3-4927-abf8-fec68bfb7c48",
  "instanceId": 4203,
  "authEntityId": 4203,
  "displayName": "4203",
  "authEntityClass": -1909073334,
  "deployPending": "NONE",
  "instanceVersion": 0,
  "createTime": 1517966741710,
  "deployed": false,
  "isSeeded": true,
  "isStale": false,
  "lastUpdateTime": 1517966741710,
  "name": "business-insider",
  "namespace": "scalablegroup:application",
  "provisioningState": "DEFINED",
  "qualifier": "application",
  "resourceVersion": 0,
  "targetIdList": [

  ],
  "type": "scalablegroup",
  "cfsChangeInfo": [

  ],
  "customProvisions": [

  ],
  "scalableGroupExternalHandle": "business-insider",
  "scalableGroupType": "APPLICATION",
  "securityGroupTag": 0,
  "state": "ACTIVE",
  "vnAgnostic": true,
  "identitySource": {
    "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
    "instanceId": 10010,
    "authEntityId": 10010,
    "displayName": "10010",
    "authEntityClass": 1738953278,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "state": "INACTIVE",
    "type": "NBAR"
  },
  "indicativeNetworkIdentity": [

  ],
  "networkApplications": [
    {
      "id": "26304334-8bda-4cb9-a8fd-b77c67e23795",
      "instanceId": 11159,
      "authEntityId": 11159,
      "displayName": "11159",
      "authEntityClass": -217092956,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "appProtocol": "tcp/udp",
      "applicationGroup": "other",
      "applicationType": "DEFAULT",
      "categoryId": "878644b5-fe91-45b4-9204-31c84a73ba48",
      "createTime": 1517966741710,
      "encrypted": "false",
      "engineId": "13",
      "helpString": "Business Insider - Online news web portal.",
      "ignoreConflict": false,
      "lastUpdateTime": 1517966741710,
      "longDescription": "Business Insider - Online news web portal.",
      "name": "business-insider",
      "nbarId": "1582",
      "p2pTechnology": "false",
      "popularity": 3,
      "rank": 65535,
      "references": "http://businessinsider.com",
      "selectorId": "653",
      "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
      "trafficClass": "TRANSACTIONAL_DATA",
      "tunnel": "false"
    }
  ],
  "networkIdentity": [
    {
      "id": "45b05fe0-3f14-45fb-82ad-e801ffc5dea2",
      "instanceId": 12437,
      "authEntityId": 12437,
      "displayName": "12437",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "53,5353",
      "protocol": "UDP",
      "upperPort": 0
    },
    {
      "id": "f01545f5-5860-47cc-a9ff-04f1f24aca74",
      "instanceId": 12438,
      "authEntityId": 12438,
      "displayName": "12438",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "53,80,443,5353",
      "protocol": "TCP",
      "upperPort": 0
    }
  ],
  "parentScalableGroup": {
    "idRef": "28e08de4-d7b2-4521-84ce-bc1e5ee0b9cc"
  },
  "user": [

  ],
  "userGroup": [

  ]
},
{
  "id": "8e1db609-4ee9-412c-848d-4d1fafc113c3",
  "instanceId": 15234,
  "authEntityId": 15234,
  "displayName": "15234",
  "authEntityClass": -1909073334,
  "deployPending": "NONE",
  "instanceVersion": 0,
  "createTime": 1517966744549,
  "deployed": false,
  "isSeeded": true,
  "isStale": false,
  "lastUpdateTime": 1517966744549,
  "name": "sur-meas",
  "namespace": "scalablegroup:application",
  "provisioningState": "DEFINED",
  "qualifier": "application",
  "resourceVersion": 0,
  "targetIdList": [

  ],
  "type": "scalablegroup",
  "cfsChangeInfo": [

  ],
  "customProvisions": [

  ],
  "scalableGroupExternalHandle": "sur-meas",
  "scalableGroupType": "APPLICATION",
  "securityGroupTag": 0,
  "state": "ACTIVE",
  "vnAgnostic": true,
  "identitySource": {
    "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
    "instanceId": 10010,
    "authEntityId": 10010,
    "displayName": "10010",
    "authEntityClass": 1738953278,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "state": "INACTIVE",
    "type": "NBAR"
  },
  "indicativeNetworkIdentity": [
    {
      "id": "98285496-9c06-4e5e-b925-6140240d331c",
      "instanceId": 17338,
      "authEntityId": 17338,
      "displayName": "17338",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "243",
      "protocol": "UDP",
      "upperPort": 0
    },
    {
      "id": "1f110d57-aaef-4bf8-b867-817e7f4a8fe2",
      "instanceId": 17339,
      "authEntityId": 17339,
      "displayName": "17339",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "243",
      "protocol": "TCP",
      "upperPort": 0
    }
  ],
  "networkApplications": [
    {
      "id": "73d3dbe0-4182-49af-a453-285cc8dedbf4",
      "instanceId": 16177,
      "authEntityId": 16177,
      "displayName": "16177",
      "authEntityClass": -217092956,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "appProtocol": "tcp/udp",
      "applicationGroup": "other",
      "applicationType": "DEFAULT",
      "categoryId": "6daa3dfd-3526-407a-88f0-97824b3f3a24",
      "createTime": 1517966744549,
      "encrypted": "false",
      "engineId": "3",
      "helpString": "Survey Measurement",
      "ignoreConflict": false,
      "lastUpdateTime": 1517966744549,
      "longDescription": "Registered with IANA on port 243 TCP/UDP",
      "name": "sur-meas",
      "nbarId": "1123",
      "p2pTechnology": "false",
      "popularity": 1,
      "rank": 65535,
      "references": "http://www.iana.org/assignments/service-names-port-numbers/service-names-port-numbers.xml",
      "selectorId": "243",
      "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
      "trafficClass": "BULK_DATA",
      "tunnel": "false"
    }
  ],
  "networkIdentity": [
    {
      "id": "0baea5c9-e3a9-48f0-8059-3c2bd90f7d09",
      "instanceId": 17336,
      "authEntityId": 17336,
      "displayName": "17336",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "243",
      "protocol": "TCP",
      "upperPort": 0
    },
    {
      "id": "c71b2fdd-adb9-413c-9c0a-d78b3cd9b174",
      "instanceId": 17337,
      "authEntityId": 17337,
      "displayName": "17337",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "243",
      "protocol": "UDP",
      "upperPort": 0
    }
  ],
  "parentScalableGroup": {
    "idRef": "000c15bf-28fe-4fbb-9b69-35e6502721f1"
  },
  "user": [

  ],
  "userGroup": [

  ]
},
{
  "id": "8e267ee7-d57d-4ffe-b910-fa163cb7ffdd",
  "instanceId": 4815,
  "authEntityId": 4815,
  "displayName": "4815",
  "authEntityClass": -1909073334,
  "deployPending": "NONE",
  "instanceVersion": 0,
  "createTime": 1517966743546,
  "deployed": false,
  "isSeeded": true,
  "isStale": false,
  "lastUpdateTime": 1517966743546,
  "name": "name",
  "namespace": "scalablegroup:application",
  "provisioningState": "DEFINED",
  "qualifier": "application",
  "resourceVersion": 0,
  "targetIdList": [

  ],
  "type": "scalablegroup",
  "cfsChangeInfo": [

  ],
  "customProvisions": [

  ],
  "scalableGroupExternalHandle": "name",
  "scalableGroupType": "APPLICATION",
  "securityGroupTag": 0,
  "state": "ACTIVE",
  "vnAgnostic": true,
  "identitySource": {
    "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
    "instanceId": 10010,
    "authEntityId": 10010,
    "displayName": "10010",
    "authEntityClass": 1738953278,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "state": "INACTIVE",
    "type": "NBAR"
  },
  "indicativeNetworkIdentity": [
    {
      "id": "a0ca5d33-a286-4a0d-9888-8b2034dc8512",
      "instanceId": 14081,
      "authEntityId": 14081,
      "displayName": "14081",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "42",
      "protocol": "TCP",
      "upperPort": 0
    },
    {
      "id": "639abe43-4a27-48de-8901-27fb01b2c867",
      "instanceId": 14082,
      "authEntityId": 14082,
      "displayName": "14082",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "42",
      "protocol": "UDP",
      "upperPort": 0
    }
  ],
  "networkApplications": [
    {
      "id": "9fe0f4e1-fab7-4ddf-af17-99cc18694155",
      "instanceId": 11764,
      "authEntityId": 11764,
      "displayName": "11764",
      "authEntityClass": -217092956,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "appProtocol": "tcp/udp",
      "applicationGroup": "other",
      "applicationType": "DEFAULT",
      "categoryId": "1fc17362-9334-4c7b-b752-5afdbb574017",
      "createTime": 1517966743546,
      "encrypted": "false",
      "engineId": "3",
      "helpString": "Host Name Server",
      "ignoreConflict": false,
      "lastUpdateTime": 1517966743546,
      "longDescription": "The ARPA Host Name Server Protocol (NAMESERVER), is an obsolete network protocol used in translating a host name to an Internet address. IANA Transmission Control Protocol (TCP) and User Datagram Protocol (UDP) port 42 for NAMESERVER; this port is more commonly used by the Windows Internet Name Service (WINS) on Microsoft operating systems.",
      "name": "name",
      "nbarId": "922",
      "p2pTechnology": "false",
      "popularity": 1,
      "rank": 65535,
      "references": "http://en.wikipedia.org/wiki/ARPA_Host_Name_Server_Protocol",
      "selectorId": "42",
      "subCategory": "f3c0f761-d894-4112-ab89-b90fc84fd660",
      "trafficClass": "OPS_ADMIN_MGMT",
      "tunnel": "false"
    }
  ],
  "networkIdentity": [
    {
      "id": "71517809-114e-47ba-ad52-149db27d5fc5",
      "instanceId": 14080,
      "authEntityId": 14080,
      "displayName": "14080",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "42",
      "protocol": "TCP",
      "upperPort": 0
    },
    {
      "id": "8b2cc7d3-d92b-4e8e-bd37-07b1febc501d",
      "instanceId": 14079,
      "authEntityId": 14079,
      "displayName": "14079",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "42",
      "protocol": "UDP",
      "upperPort": 0
    }
  ],
  "parentScalableGroup": {
    "idRef": "5fcd74d4-8726-47f7-a088-c457863da7ef"
  },
  "user": [

  ],
  "userGroup": [

  ]
},
{
  "id": "8e333d53-7dea-4eb3-9bb7-9baabf3652be",
  "instanceId": 4976,
  "authEntityId": 4976,
  "displayName": "4976",
  "authEntityClass": -1909073334,
  "deployPending": "NONE",
  "instanceVersion": 0,
  "createTime": 1517966743953,
  "deployed": false,
  "isSeeded": true,
  "isStale": false,
  "lastUpdateTime": 1517966743953,
  "name": "pov-ray",
  "namespace": "scalablegroup:application",
  "provisioningState": "DEFINED",
  "qualifier": "application",
  "resourceVersion": 0,
  "targetIdList": [

  ],
  "type": "scalablegroup",
  "cfsChangeInfo": [

  ],
  "customProvisions": [

  ],
  "scalableGroupExternalHandle": "pov-ray",
  "scalableGroupType": "APPLICATION",
  "securityGroupTag": 0,
  "state": "ACTIVE",
  "vnAgnostic": true,
  "identitySource": {
    "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
    "instanceId": 10010,
    "authEntityId": 10010,
    "displayName": "10010",
    "authEntityClass": 1738953278,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "state": "INACTIVE",
    "type": "NBAR"
  },
  "indicativeNetworkIdentity": [
    {
      "id": "2888c3a2-b433-4767-b8c7-470e35d215e2",
      "instanceId": 14582,
      "authEntityId": 14582,
      "displayName": "14582",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "494",
      "protocol": "UDP",
      "upperPort": 0
    },
    {
      "id": "90d4287e-1567-465b-8007-813e1fa34d61",
      "instanceId": 14583,
      "authEntityId": 14583,
      "displayName": "14583",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "494",
      "protocol": "TCP",
      "upperPort": 0
    }
  ],
  "networkApplications": [
    {
      "id": "0d5993d7-57c1-472a-88dc-fbc6384c3f86",
      "instanceId": 11925,
      "authEntityId": 11925,
      "displayName": "11925",
      "authEntityClass": -217092956,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "appProtocol": "tcp/udp",
      "applicationGroup": "other",
      "applicationType": "DEFAULT",
      "categoryId": "6daa3dfd-3526-407a-88f0-97824b3f3a24",
      "createTime": 1517966743953,
      "encrypted": "false",
      "engineId": "3",
      "helpString": "pov-ray",
      "ignoreConflict": false,
      "lastUpdateTime": 1517966743953,
      "longDescription": "Persistence of Vision Raytracer (POV-Ray) is a ray tracing program available for a variety of computer platforms. It was originally based on DKBTrace. There are also influences from the earlier Polyray raytracer. POV-Ray is freeware with the source code available.",
      "name": "pov-ray",
      "nbarId": "408",
      "p2pTechnology": "false",
      "popularity": 1,
      "rank": 65535,
      "references": "http://www.povray.org/",
      "selectorId": "494",
      "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
      "trafficClass": "BULK_DATA",
      "tunnel": "false"
    }
  ],
  "networkIdentity": [
    {
      "id": "5b0fb93a-e30d-4754-9d01-336f0b9623c3",
      "instanceId": 14580,
      "authEntityId": 14580,
      "displayName": "14580",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "494",
      "protocol": "UDP",
      "upperPort": 0
    },
    {
      "id": "e352c84b-4e33-49b2-9b24-87826aa69674",
      "instanceId": 14581,
      "authEntityId": 14581,
      "displayName": "14581",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "494",
      "protocol": "TCP",
      "upperPort": 0
    }
  ],
  "parentScalableGroup": {
    "idRef": "000c15bf-28fe-4fbb-9b69-35e6502721f1"
  },
  "user": [

  ],
  "userGroup": [

  ]
},
{
  "id": "8e59ab7a-fe5b-479b-9703-9884b0adecfe",
  "instanceId": 4824,
  "authEntityId": 4824,
  "displayName": "4824",
  "authEntityClass": -1909073334,
  "deployPending": "NONE",
  "instanceVersion": 0,
  "createTime": 1517966743563,
  "deployed": false,
  "isSeeded": true,
  "isStale": false,
  "lastUpdateTime": 1517966743563,
  "name": "nced",
  "namespace": "scalablegroup:application",
  "provisioningState": "DEFINED",
  "qualifier": "application",
  "resourceVersion": 0,
  "targetIdList": [

  ],
  "type": "scalablegroup",
  "cfsChangeInfo": [

  ],
  "customProvisions": [

  ],
  "scalableGroupExternalHandle": "nced",
  "scalableGroupType": "APPLICATION",
  "securityGroupTag": 0,
  "state": "ACTIVE",
  "vnAgnostic": true,
  "identitySource": {
    "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
    "instanceId": 10010,
    "authEntityId": 10010,
    "displayName": "10010",
    "authEntityClass": 1738953278,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "state": "INACTIVE",
    "type": "NBAR"
  },
  "indicativeNetworkIdentity": [
    {
      "id": "a888b5ae-37f2-4b5c-afa4-9ea7f524fedd",
      "instanceId": 14104,
      "authEntityId": 14104,
      "displayName": "14104",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "404",
      "protocol": "TCP",
      "upperPort": 0
    },
    {
      "id": "b6eecc11-2533-4e6c-ba99-28818e6348e9",
      "instanceId": 14105,
      "authEntityId": 14105,
      "displayName": "14105",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "404",
      "protocol": "UDP",
      "upperPort": 0
    }
  ],
  "networkApplications": [
    {
      "id": "a9f20ae6-5f21-4dd4-8e46-a161679650e3",
      "instanceId": 11773,
      "authEntityId": 11773,
      "displayName": "11773",
      "authEntityClass": -217092956,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "appProtocol": "tcp/udp",
      "applicationGroup": "other",
      "applicationType": "DEFAULT",
      "categoryId": "6daa3dfd-3526-407a-88f0-97824b3f3a24",
      "createTime": 1517966743563,
      "encrypted": "false",
      "engineId": "3",
      "helpString": "nced",
      "ignoreConflict": false,
      "lastUpdateTime": 1517966743563,
      "longDescription": "Registered with IANA on port 404 TCP/UDP",
      "name": "nced",
      "nbarId": "319",
      "p2pTechnology": "false",
      "popularity": 1,
      "rank": 65535,
      "references": "http://www.iana.org/assignments/service-names-port-numbers/service-names-port-numbers.xml",
      "selectorId": "404",
      "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
      "trafficClass": "BULK_DATA",
      "tunnel": "false"
    }
  ],
  "networkIdentity": [
    {
      "id": "bfb5a531-0e6c-47b3-885e-33e2905e8e55",
      "instanceId": 14102,
      "authEntityId": 14102,
      "displayName": "14102",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "404",
      "protocol": "UDP",
      "upperPort": 0
    },
    {
      "id": "67467253-55bf-4db1-891d-a4755509b050",
      "instanceId": 14103,
      "authEntityId": 14103,
      "displayName": "14103",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "404",
      "protocol": "TCP",
      "upperPort": 0
    }
  ],
  "parentScalableGroup": {
    "idRef": "000c15bf-28fe-4fbb-9b69-35e6502721f1"
  },
  "user": [

  ],
  "userGroup": [

  ]
},
{
  "id": "8e59c521-160e-4ed6-b1d5-34a66de992de",
  "instanceId": 4250,
  "authEntityId": 4250,
  "displayName": "4250",
  "authEntityClass": -1909073334,
  "deployPending": "NONE",
  "instanceVersion": 0,
  "createTime": 1517966741939,
  "deployed": false,
  "isSeeded": true,
  "isStale": false,
  "lastUpdateTime": 1517966741939,
  "name": "cisco-rtmt",
  "namespace": "scalablegroup:application",
  "provisioningState": "DEFINED",
  "qualifier": "application",
  "resourceVersion": 0,
  "targetIdList": [

  ],
  "type": "scalablegroup",
  "cfsChangeInfo": [

  ],
  "customProvisions": [

  ],
  "scalableGroupExternalHandle": "cisco-rtmt",
  "scalableGroupType": "APPLICATION",
  "securityGroupTag": 0,
  "state": "ACTIVE",
  "vnAgnostic": true,
  "identitySource": {
    "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
    "instanceId": 10010,
    "authEntityId": 10010,
    "displayName": "10010",
    "authEntityClass": 1738953278,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "state": "INACTIVE",
    "type": "NBAR"
  },
  "indicativeNetworkIdentity": [
    {
      "id": "321c17df-a933-481b-a25a-414447285e3a",
      "instanceId": 12537,
      "authEntityId": 12537,
      "displayName": "12537",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "1090",
      "protocol": "TCP",
      "upperPort": 0
    }
  ],
  "networkApplications": [
    {
      "id": "f2cd3290-2086-4a00-a80d-cf46d06255d5",
      "instanceId": 11204,
      "authEntityId": 11204,
      "displayName": "11204",
      "authEntityClass": -217092956,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "appProtocol": "tcp",
      "applicationGroup": "cisco-phone-group",
      "applicationType": "DEFAULT",
      "categoryId": "1fc17362-9334-4c7b-b752-5afdbb574017",
      "createTime": 1517966741939,
      "encrypted": "false",
      "engineId": "13",
      "helpString": "Cisco Real-Time Monitoring Tool",
      "ignoreConflict": false,
      "lastUpdateTime": 1517966741939,
      "longDescription": "Cisco Unified Real-Time Monitoring Tool",
      "name": "cisco-rtmt",
      "nbarId": "1060",
      "p2pTechnology": "false",
      "popularity": 10,
      "rank": 65535,
      "references": "http://www.cisco.com/c/en/us/td/docs/voice_ip_comm/cucm/service/7_1_2/rtmt/RTMT/rtinst.html,http://www.cisco.com/c/en/us/support/unified-communications/unified-communications-manager-callmanager/products-maintenance-guides-list.html",
      "selectorId": "1060",
      "subCategory": "f3c0f761-d894-4112-ab89-b90fc84fd660",
      "trafficClass": "OPS_ADMIN_MGMT",
      "tunnel": "false"
    }
  ],
  "networkIdentity": [
    {
      "id": "b24ba1bf-6bcf-4c23-a093-fbd59471f553",
      "instanceId": 12536,
      "authEntityId": 12536,
      "displayName": "12536",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "1090",
      "protocol": "TCP",
      "upperPort": 0
    }
  ],
  "parentScalableGroup": {
    "idRef": "5fcd74d4-8726-47f7-a088-c457863da7ef"
  },
  "user": [

  ],
  "userGroup": [

  ]
},
{
  "id": "8e647833-0ea1-49f5-b4b1-7b4351592931",
  "instanceId": 4907,
  "authEntityId": 4907,
  "displayName": "4907",
  "authEntityClass": -1909073334,
  "deployPending": "NONE",
  "instanceVersion": 0,
  "createTime": 1517966743729,
  "deployed": false,
  "isSeeded": true,
  "isStale": false,
  "lastUpdateTime": 1517966743729,
  "name": "omserv",
  "namespace": "scalablegroup:application",
  "provisioningState": "DEFINED",
  "qualifier": "application",
  "resourceVersion": 0,
  "targetIdList": [

  ],
  "type": "scalablegroup",
  "cfsChangeInfo": [

  ],
  "customProvisions": [

  ],
  "scalableGroupExternalHandle": "omserv",
  "scalableGroupType": "APPLICATION",
  "securityGroupTag": 0,
  "state": "ACTIVE",
  "vnAgnostic": true,
  "identitySource": {
    "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
    "instanceId": 10010,
    "authEntityId": 10010,
    "displayName": "10010",
    "authEntityClass": 1738953278,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "state": "INACTIVE",
    "type": "NBAR"
  },
  "indicativeNetworkIdentity": [
    {
      "id": "abb8731f-9e75-42e2-9849-20411c7be151",
      "instanceId": 14380,
      "authEntityId": 14380,
      "displayName": "14380",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "764",
      "protocol": "UDP",
      "upperPort": 0
    },
    {
      "id": "afd25a87-b6f4-4993-834a-2fab6d870bc7",
      "instanceId": 14381,
      "authEntityId": 14381,
      "displayName": "14381",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "764",
      "protocol": "TCP",
      "upperPort": 0
    }
  ],
  "networkApplications": [
    {
      "id": "6a32ff89-a66a-446b-a84b-d6905cef01a3",
      "instanceId": 11856,
      "authEntityId": 11856,
      "displayName": "11856",
      "authEntityClass": -217092956,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "appProtocol": "tcp/udp",
      "applicationGroup": "other",
      "applicationType": "DEFAULT",
      "categoryId": "6daa3dfd-3526-407a-88f0-97824b3f3a24",
      "createTime": 1517966743728,
      "encrypted": "false",
      "engineId": "3",
      "helpString": "omserv",
      "ignoreConflict": false,
      "lastUpdateTime": 1517966743728,
      "longDescription": "Registered with IANA on port 764 TCP/UDP",
      "name": "omserv",
      "nbarId": "636",
      "p2pTechnology": "false",
      "popularity": 1,
      "rank": 65535,
      "references": "http://www.iana.org/assignments/service-names-port-numbers/service-names-port-numbers.xml",
      "selectorId": "764",
      "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
      "trafficClass": "BULK_DATA",
      "tunnel": "false"
    }
  ],
  "networkIdentity": [
    {
      "id": "47e169de-e69d-44a5-a872-657db7391110",
      "instanceId": 14378,
      "authEntityId": 14378,
      "displayName": "14378",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "764",
      "protocol": "UDP",
      "upperPort": 0
    },
    {
      "id": "bcdb3ea7-da9b-49e0-a2b0-6e27ac4c7777",
      "instanceId": 14379,
      "authEntityId": 14379,
      "displayName": "14379",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "764",
      "protocol": "TCP",
      "upperPort": 0
    }
  ],
  "parentScalableGroup": {
    "idRef": "000c15bf-28fe-4fbb-9b69-35e6502721f1"
  },
  "user": [

  ],
  "userGroup": [

  ]
},
{
  "id": "8e64b247-1e2b-41a9-ac81-06277e7d0160",
  "instanceId": 15184,
  "authEntityId": 15184,
  "displayName": "15184",
  "authEntityClass": -1909073334,
  "deployPending": "NONE",
  "instanceVersion": 0,
  "createTime": 1517966744427,
  "deployed": false,
  "isSeeded": true,
  "isStale": false,
  "lastUpdateTime": 1517966744427,
  "name": "soulseek",
  "namespace": "scalablegroup:application",
  "provisioningState": "DEFINED",
  "qualifier": "application",
  "resourceVersion": 0,
  "targetIdList": [

  ],
  "type": "scalablegroup",
  "cfsChangeInfo": [

  ],
  "customProvisions": [

  ],
  "scalableGroupExternalHandle": "soulseek",
  "scalableGroupType": "APPLICATION",
  "securityGroupTag": 0,
  "state": "ACTIVE",
  "vnAgnostic": true,
  "identitySource": {
    "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
    "instanceId": 10010,
    "authEntityId": 10010,
    "displayName": "10010",
    "authEntityClass": 1738953278,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "state": "INACTIVE",
    "type": "NBAR"
  },
  "indicativeNetworkIdentity": [

  ],
  "networkApplications": [
    {
      "id": "219bb1b7-3789-4f13-bbf6-2296ea893928",
      "instanceId": 16127,
      "authEntityId": 16127,
      "displayName": "16127",
      "authEntityClass": -217092956,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "applicationGroup": "other",
      "applicationType": "DEFAULT",
      "categoryId": "b33b2761-d981-4734-810a-a6a1c18ab352",
      "createTime": 1517966744427,
      "encrypted": "false",
      "engineId": "13",
      "helpString": "P2P file sharing application",
      "ignoreConflict": false,
      "lastUpdateTime": 1517966744427,
      "longDescription": "SoulSeek is a peer to peer file sharing application. It has its own network that is also named Soulseek. The application is based on the Windows platform. The browsing for downloads is done by users with folder trees.",
      "name": "soulseek",
      "nbarId": "431",
      "p2pTechnology": "true",
      "popularity": 3,
      "rank": 65535,
      "references": "http://www.slsknet.org/",
      "selectorId": "267",
      "subCategory": "79fe7198-9ce3-4c01-9b58-45a8df3d9513",
      "trafficClass": "BULK_DATA",
      "tunnel": "false"
    }
  ],
  "networkIdentity": [

  ],
  "parentScalableGroup": {
    "idRef": "7a1a3bbb-dc84-4a35-82a4-7e650c4c4761"
  },
  "user": [

  ],
  "userGroup": [

  ]
},
{
  "id": "8e6f6067-dbc1-46e6-87ad-1b142faeea0c",
  "instanceId": 4268,
  "authEntityId": 4268,
  "displayName": "4268",
  "authEntityClass": -1909073334,
  "deployPending": "NONE",
  "instanceVersion": 0,
  "createTime": 1517966741994,
  "deployed": false,
  "isSeeded": true,
  "isStale": false,
  "lastUpdateTime": 1517966741994,
  "name": "cmip-man",
  "namespace": "scalablegroup:application",
  "provisioningState": "DEFINED",
  "qualifier": "application",
  "resourceVersion": 0,
  "targetIdList": [

  ],
  "type": "scalablegroup",
  "cfsChangeInfo": [

  ],
  "customProvisions": [

  ],
  "scalableGroupExternalHandle": "cmip-man",
  "scalableGroupType": "APPLICATION",
  "securityGroupTag": 0,
  "state": "ACTIVE",
  "vnAgnostic": true,
  "identitySource": {
    "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
    "instanceId": 10010,
    "authEntityId": 10010,
    "displayName": "10010",
    "authEntityClass": 1738953278,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "state": "INACTIVE",
    "type": "NBAR"
  },
  "indicativeNetworkIdentity": [
    {
      "id": "f36e9e4d-4ce1-47b7-81c2-608ca8e56176",
      "instanceId": 12578,
      "authEntityId": 12578,
      "displayName": "12578",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "163",
      "protocol": "TCP",
      "upperPort": 0
    },
    {
      "id": "6c724b94-39db-419f-9a85-77e5ec8505df",
      "instanceId": 12579,
      "authEntityId": 12579,
      "displayName": "12579",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "163",
      "protocol": "UDP",
      "upperPort": 0
    }
  ],
  "networkApplications": [
    {
      "id": "11ec5988-29a9-40b3-a062-f0161cfea888",
      "instanceId": 11221,
      "authEntityId": 11221,
      "displayName": "11221",
      "authEntityClass": -217092956,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "appProtocol": "tcp/udp",
      "applicationGroup": "other",
      "applicationType": "DEFAULT",
      "categoryId": "1fc17362-9334-4c7b-b752-5afdbb574017",
      "createTime": 1517966741994,
      "encrypted": "false",
      "engineId": "3",
      "helpString": "CMIP/TCP Manager",
      "ignoreConflict": false,
      "lastUpdateTime": 1517966741994,
      "longDescription": "The Common Management Information Protocol (CMIP) is the OSI specified network management protocol. Defined in ITU-T Recommendation X.711, ISO/IEC International Standard 9596-1. It provides an implementation for the services defined by the Common Management Information Service (CMIS) specified in ITU-T Recommendation X.710, ISO/IEC International Standard 9595, allowing communication between network management applications and management agents. CMIS/CMIP is the network management protocol specified by the ISO/OSI Network management model and is further defined by the ITU-T in the X.700 series of recommendations.",
      "name": "cmip-man",
      "nbarId": "1008",
      "p2pTechnology": "false",
      "popularity": 1,
      "rank": 65535,
      "references": "http://en.wikipedia.org/wiki/Common_Management_Information_Protocol",
      "selectorId": "163",
      "subCategory": "f3c0f761-d894-4112-ab89-b90fc84fd660",
      "trafficClass": "OPS_ADMIN_MGMT",
      "tunnel": "false"
    }
  ],
  "networkIdentity": [
    {
      "id": "93a05d3e-769c-475a-ad60-c5d4b1c6429c",
      "instanceId": 12576,
      "authEntityId": 12576,
      "displayName": "12576",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "163",
      "protocol": "TCP",
      "upperPort": 0
    },
    {
      "id": "a898dac0-f8c1-4347-b847-d2ca55a9f181",
      "instanceId": 12577,
      "authEntityId": 12577,
      "displayName": "12577",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "163",
      "protocol": "UDP",
      "upperPort": 0
    }
  ],
  "parentScalableGroup": {
    "idRef": "5fcd74d4-8726-47f7-a088-c457863da7ef"
  },
  "user": [

  ],
  "userGroup": [

  ]
},
{
  "id": "8e861776-4dd9-44b3-a242-9739c0aafd87",
  "instanceId": 4108,
  "authEntityId": 4108,
  "displayName": "4108",
  "authEntityClass": -1909073334,
  "deployPending": "NONE",
  "instanceVersion": 0,
  "createTime": 1517966741152,
  "deployed": false,
  "isSeeded": true,
  "isStale": false,
  "lastUpdateTime": 1517966741152,
  "name": "apple-updates",
  "namespace": "scalablegroup:application",
  "provisioningState": "DEFINED",
  "qualifier": "application",
  "resourceVersion": 0,
  "targetIdList": [

  ],
  "type": "scalablegroup",
  "cfsChangeInfo": [

  ],
  "customProvisions": [

  ],
  "scalableGroupExternalHandle": "apple-updates",
  "scalableGroupType": "APPLICATION",
  "securityGroupTag": 0,
  "state": "ACTIVE",
  "vnAgnostic": true,
  "identitySource": {
    "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
    "instanceId": 10010,
    "authEntityId": 10010,
    "displayName": "10010",
    "authEntityClass": 1738953278,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "state": "INACTIVE",
    "type": "NBAR"
  },
  "indicativeNetworkIdentity": [

  ],
  "networkApplications": [
    {
      "id": "8cf5273c-5fb1-45dc-81da-cf0066d6ad7c",
      "instanceId": 11070,
      "authEntityId": 11070,
      "displayName": "11070",
      "authEntityClass": -217092956,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "appProtocol": "tcp/udp",
      "applicationGroup": "apple-group",
      "applicationType": "DEFAULT",
      "categoryId": "9d9132a2-d99b-49db-a414-9fc93dea6604",
      "createTime": 1517966741152,
      "encrypted": "true",
      "engineId": "13",
      "helpString": "Apple software updates",
      "ignoreConflict": false,
      "lastUpdateTime": 1517966741152,
      "longDescription": "Apple updates is the service that provides updates for Apple platforms and applications.",
      "name": "apple-updates",
      "nbarId": "1095",
      "p2pTechnology": "false",
      "popularity": 7,
      "rank": 65535,
      "references": "https://support.apple.com/downloads/",
      "selectorId": "1095",
      "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
      "trafficClass": "BULK_DATA",
      "tunnel": "false"
    }
  ],
  "networkIdentity": [
    {
      "id": "de51240e-a91f-4752-ae73-09b504a85407",
      "instanceId": 12173,
      "authEntityId": 12173,
      "displayName": "12173",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "53,80,443,5353",
      "protocol": "TCP",
      "upperPort": 0
    },
    {
      "id": "309cbac1-e69d-4972-84ac-63d3f0aef91c",
      "instanceId": 12174,
      "authEntityId": 12174,
      "displayName": "12174",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "53,5353",
      "protocol": "UDP",
      "upperPort": 0
    }
  ],
  "parentScalableGroup": {
    "idRef": "4a68bc73-53c6-4deb-9983-784a0338aefe"
  },
  "user": [

  ],
  "userGroup": [

  ]
},
{
  "id": "8e8adae3-6340-4d68-b796-bb91a443c0ef",
  "instanceId": 4122,
  "authEntityId": 4122,
  "displayName": "4122",
  "authEntityClass": -1909073334,
  "deployPending": "NONE",
  "instanceVersion": 0,
  "createTime": 1517966741241,
  "deployed": false,
  "isSeeded": true,
  "isStale": false,
  "lastUpdateTime": 1517966741241,
  "name": "as-servermap",
  "namespace": "scalablegroup:application",
  "provisioningState": "DEFINED",
  "qualifier": "application",
  "resourceVersion": 0,
  "targetIdList": [

  ],
  "type": "scalablegroup",
  "cfsChangeInfo": [

  ],
  "customProvisions": [

  ],
  "scalableGroupExternalHandle": "as-servermap",
  "scalableGroupType": "APPLICATION",
  "securityGroupTag": 0,
  "state": "ACTIVE",
  "vnAgnostic": true,
  "identitySource": {
    "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
    "instanceId": 10010,
    "authEntityId": 10010,
    "displayName": "10010",
    "authEntityClass": 1738953278,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "state": "INACTIVE",
    "type": "NBAR"
  },
  "indicativeNetworkIdentity": [
    {
      "id": "7953d803-2b97-4656-a446-2bf05d73f43a",
      "instanceId": 12221,
      "authEntityId": 12221,
      "displayName": "12221",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "449",
      "protocol": "UDP",
      "upperPort": 0
    },
    {
      "id": "1b34c694-c549-4204-ae81-b9fc4a8fa2aa",
      "instanceId": 12222,
      "authEntityId": 12222,
      "displayName": "12222",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "449",
      "protocol": "TCP",
      "upperPort": 0
    }
  ],
  "networkApplications": [
    {
      "id": "87fe5be9-fb9c-41c2-bf38-6dc5c0018356",
      "instanceId": 11084,
      "authEntityId": 11084,
      "displayName": "11084",
      "authEntityClass": -217092956,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "appProtocol": "tcp/udp",
      "applicationGroup": "other",
      "applicationType": "DEFAULT",
      "categoryId": "6daa3dfd-3526-407a-88f0-97824b3f3a24",
      "createTime": 1517966741241,
      "encrypted": "false",
      "engineId": "3",
      "helpString": "AS Server Mapper",
      "ignoreConflict": false,
      "lastUpdateTime": 1517966741241,
      "longDescription": "The server mapper daemon is a batch job that runs in some IBM subsystems. It provides a method for client applications to determine the port number associated with a particular server.",
      "name": "as-servermap",
      "nbarId": "364",
      "p2pTechnology": "false",
      "popularity": 2,
      "rank": 65535,
      "references": "http://publib.boulder.ibm.com/infocenter/iseries/v5r4/index.jsp?topic=%2Frzaii%2Frzaiidaemon.htm",
      "selectorId": "449",
      "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
      "trafficClass": "BULK_DATA",
      "tunnel": "false"
    }
  ],
  "networkIdentity": [
    {
      "id": "8c1795a6-c13f-4e2d-8e42-041ab5d6c8a3",
      "instanceId": 12219,
      "authEntityId": 12219,
      "displayName": "12219",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "449",
      "protocol": "UDP",
      "upperPort": 0
    },
    {
      "id": "ecf9e766-ad0f-4361-9f0a-0e42c3033cfe",
      "instanceId": 12220,
      "authEntityId": 12220,
      "displayName": "12220",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "449",
      "protocol": "TCP",
      "upperPort": 0
    }
  ],
  "parentScalableGroup": {
    "idRef": "000c15bf-28fe-4fbb-9b69-35e6502721f1"
  },
  "user": [

  ],
  "userGroup": [

  ]
},
{
  "id": "8e91558c-4f3a-4115-b6ed-1b29c66c840e",
  "instanceId": 15383,
  "authEntityId": 15383,
  "displayName": "15383",
  "authEntityClass": -1909073334,
  "deployPending": "NONE",
  "instanceVersion": 0,
  "createTime": 1517966744846,
  "deployed": false,
  "isSeeded": true,
  "isStale": false,
  "lastUpdateTime": 1517966744846,
  "name": "wap-wsp-s",
  "namespace": "scalablegroup:application",
  "provisioningState": "DEFINED",
  "qualifier": "application",
  "resourceVersion": 0,
  "targetIdList": [

  ],
  "type": "scalablegroup",
  "cfsChangeInfo": [

  ],
  "customProvisions": [

  ],
  "scalableGroupExternalHandle": "wap-wsp-s",
  "scalableGroupType": "APPLICATION",
  "securityGroupTag": 0,
  "state": "ACTIVE",
  "vnAgnostic": true,
  "identitySource": {
    "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
    "instanceId": 10010,
    "authEntityId": 10010,
    "displayName": "10010",
    "authEntityClass": 1738953278,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "state": "INACTIVE",
    "type": "NBAR"
  },
  "indicativeNetworkIdentity": [
    {
      "id": "6f8a17c7-de97-466f-9ab5-3d6d08e20269",
      "instanceId": 17776,
      "authEntityId": 17776,
      "displayName": "17776",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "9202",
      "protocol": "UDP",
      "upperPort": 0
    },
    {
      "id": "77c18b2d-8ec4-4817-94d9-3580f14c2d49",
      "instanceId": 17775,
      "authEntityId": 17775,
      "displayName": "17775",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "9202",
      "protocol": "TCP",
      "upperPort": 0
    }
  ],
  "networkApplications": [
    {
      "id": "63b51fd6-5f25-4e7f-81de-ff1b692d9c58",
      "instanceId": 16326,
      "authEntityId": 16326,
      "displayName": "16326",
      "authEntityClass": -217092956,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "appProtocol": "tcp/udp",
      "applicationGroup": "wap-group",
      "applicationType": "DEFAULT",
      "categoryId": "6daa3dfd-3526-407a-88f0-97824b3f3a24",
      "createTime": 1517966744846,
      "encrypted": "false",
      "engineId": "3",
      "helpString": "WAP secure connectionless session service",
      "ignoreConflict": false,
      "lastUpdateTime": 1517966744846,
      "longDescription": "Registered with IANA on port 9202 TCP/UDP",
      "name": "wap-wsp-s",
      "nbarId": "731",
      "p2pTechnology": "false",
      "popularity": 1,
      "rank": 65535,
      "references": "http://www.iana.org/assignments/service-names-port-numbers/service-names-port-numbers.xml",
      "selectorId": "9202",
      "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
      "trafficClass": "BULK_DATA",
      "tunnel": "false"
    }
  ],
  "networkIdentity": [
    {
      "id": "469ecc4a-23f9-4602-817f-04fa76fda01a",
      "instanceId": 17773,
      "authEntityId": 17773,
      "displayName": "17773",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "9202",
      "protocol": "UDP",
      "upperPort": 0
    },
    {
      "id": "e0b66ce6-4763-4815-8b98-465e7a54a28f",
      "instanceId": 17774,
      "authEntityId": 17774,
      "displayName": "17774",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "9202",
      "protocol": "TCP",
      "upperPort": 0
    }
  ],
  "parentScalableGroup": {
    "idRef": "000c15bf-28fe-4fbb-9b69-35e6502721f1"
  },
  "user": [

  ],
  "userGroup": [

  ]
},
{
  "id": "8eb46ea7-d013-47c8-817e-964aebfcd69b",
  "instanceId": 4700,
  "authEntityId": 4700,
  "displayName": "4700",
  "authEntityClass": -1909073334,
  "deployPending": "NONE",
  "instanceVersion": 0,
  "createTime": 1517966743311,
  "deployed": false,
  "isSeeded": true,
  "isStale": false,
  "lastUpdateTime": 1517966743311,
  "name": "livestrong-com",
  "namespace": "scalablegroup:application",
  "provisioningState": "DEFINED",
  "qualifier": "application",
  "resourceVersion": 0,
  "targetIdList": [

  ],
  "type": "scalablegroup",
  "cfsChangeInfo": [

  ],
  "customProvisions": [

  ],
  "scalableGroupExternalHandle": "livestrong-com",
  "scalableGroupType": "APPLICATION",
  "securityGroupTag": 0,
  "state": "ACTIVE",
  "vnAgnostic": true,
  "identitySource": {
    "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
    "instanceId": 10010,
    "authEntityId": 10010,
    "displayName": "10010",
    "authEntityClass": 1738953278,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "state": "INACTIVE",
    "type": "NBAR"
  },
  "indicativeNetworkIdentity": [

  ],
  "networkApplications": [
    {
      "id": "f1ecf31d-1528-436e-9b6f-0be74fb837b3",
      "instanceId": 11650,
      "authEntityId": 11650,
      "displayName": "11650",
      "authEntityClass": -217092956,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "appProtocol": "tcp/udp",
      "applicationGroup": "other",
      "applicationType": "DEFAULT",
      "categoryId": "878644b5-fe91-45b4-9204-31c84a73ba48",
      "createTime": 1517966743311,
      "encrypted": "false",
      "engineId": "13",
      "helpString": "LiveStrong.com - Health and fitness information.",
      "ignoreConflict": false,
      "lastUpdateTime": 1517966743311,
      "longDescription": "LiveStrong.com - Health and fitness information.",
      "name": "livestrong-com",
      "nbarId": "1708",
      "p2pTechnology": "false",
      "popularity": 3,
      "rank": 65535,
      "references": "http://livestrong.com",
      "selectorId": "780",
      "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
      "trafficClass": "TRANSACTIONAL_DATA",
      "tunnel": "false"
    }
  ],
  "networkIdentity": [
    {
      "id": "465132fe-6734-422f-85d6-c759183cff2e",
      "instanceId": 13766,
      "authEntityId": 13766,
      "displayName": "13766",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "53,80,443,5353",
      "protocol": "TCP",
      "upperPort": 0
    },
    {
      "id": "ad8c3b61-fb7c-4b10-bd9b-2a305936b3d8",
      "instanceId": 13767,
      "authEntityId": 13767,
      "displayName": "13767",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "53,5353",
      "protocol": "UDP",
      "upperPort": 0
    }
  ],
  "parentScalableGroup": {
    "idRef": "28e08de4-d7b2-4521-84ce-bc1e5ee0b9cc"
  },
  "user": [

  ],
  "userGroup": [

  ]
},
{
  "id": "8ec7449f-90dc-4b94-aa9e-3191ab5bc5db",
  "instanceId": 15034,
  "authEntityId": 15034,
  "displayName": "15034",
  "authEntityClass": -1909073334,
  "deployPending": "NONE",
  "instanceVersion": 0,
  "createTime": 1517966744075,
  "deployed": false,
  "isSeeded": true,
  "isStale": false,
  "lastUpdateTime": 1517966744075,
  "name": "re-mail-ck",
  "namespace": "scalablegroup:application",
  "provisioningState": "DEFINED",
  "qualifier": "application",
  "resourceVersion": 0,
  "targetIdList": [

  ],
  "type": "scalablegroup",
  "cfsChangeInfo": [

  ],
  "customProvisions": [

  ],
  "scalableGroupExternalHandle": "re-mail-ck",
  "scalableGroupType": "APPLICATION",
  "securityGroupTag": 0,
  "state": "ACTIVE",
  "vnAgnostic": true,
  "identitySource": {
    "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
    "instanceId": 10010,
    "authEntityId": 10010,
    "displayName": "10010",
    "authEntityClass": 1738953278,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "state": "INACTIVE",
    "type": "NBAR"
  },
  "indicativeNetworkIdentity": [
    {
      "id": "79a7599b-3aac-4f30-8516-4454c7fb05cf",
      "instanceId": 14731,
      "authEntityId": 14731,
      "displayName": "14731",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "50",
      "protocol": "TCP",
      "upperPort": 0
    },
    {
      "id": "7b54cf8c-9a77-4c2b-ad73-29e6eeab3a74",
      "instanceId": 14732,
      "authEntityId": 14732,
      "displayName": "14732",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "50",
      "protocol": "UDP",
      "upperPort": 0
    }
  ],
  "networkApplications": [
    {
      "id": "6db56f3e-dae9-4b34-b3bd-c7aa5af72bd6",
      "instanceId": 11973,
      "authEntityId": 11973,
      "displayName": "11973",
      "authEntityClass": -217092956,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "appProtocol": "tcp/udp",
      "applicationGroup": "other",
      "applicationType": "DEFAULT",
      "categoryId": "27953c60-b2ff-4e1a-bc52-0187d8295250",
      "createTime": 1517966744075,
      "encrypted": "false",
      "engineId": "3",
      "helpString": "Remote Mail Checking Protocol",
      "ignoreConflict": false,
      "lastUpdateTime": 1517966744075,
      "longDescription": "Remote Mail Checking Protocol (RMCP) is a mail checking service that is used between a client and a server pair. Client queries server in order to find out whether new mail has arrived for a specified user. The protocol provides for both non-authenticated and authenticated polling.",
      "name": "re-mail-ck",
      "nbarId": "930",
      "p2pTechnology": "false",
      "popularity": 1,
      "rank": 65535,
      "references": "http://tools.ietf.org/html/rfc1339",
      "selectorId": "50",
      "subCategory": "b90786f4-0190-45b8-9924-21b3e9682590",
      "trafficClass": "BULK_DATA",
      "tunnel": "false"
    }
  ],
  "networkIdentity": [
    {
      "id": "96ee207d-612f-49e4-b869-09632beebe85",
      "instanceId": 14729,
      "authEntityId": 14729,
      "displayName": "14729",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "50",
      "protocol": "UDP",
      "upperPort": 0
    },
    {
      "id": "bb2f24c8-2109-48f8-8934-d004e177d785",
      "instanceId": 14730,
      "authEntityId": 14730,
      "displayName": "14730",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "50",
      "protocol": "TCP",
      "upperPort": 0
    }
  ],
  "parentScalableGroup": {
    "idRef": "8a7ca43a-32bd-40d2-871a-02220a165cb7"
  },
  "user": [

  ],
  "userGroup": [

  ]
},
{
  "id": "8ed5cb87-a865-4fff-9042-769328b8367f",
  "instanceId": 4081,
  "authEntityId": 4081,
  "displayName": "4081",
  "authEntityClass": -1909073334,
  "deployPending": "NONE",
  "instanceVersion": 0,
  "createTime": 1517966740938,
  "deployed": false,
  "isSeeded": true,
  "isStale": false,
  "lastUpdateTime": 1517966740938,
  "name": "american-express",
  "namespace": "scalablegroup:application",
  "provisioningState": "DEFINED",
  "qualifier": "application",
  "resourceVersion": 0,
  "targetIdList": [

  ],
  "type": "scalablegroup",
  "cfsChangeInfo": [

  ],
  "customProvisions": [

  ],
  "scalableGroupExternalHandle": "american-express",
  "scalableGroupType": "APPLICATION",
  "securityGroupTag": 0,
  "state": "ACTIVE",
  "vnAgnostic": true,
  "identitySource": {
    "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
    "instanceId": 10010,
    "authEntityId": 10010,
    "displayName": "10010",
    "authEntityClass": 1738953278,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "state": "INACTIVE",
    "type": "NBAR"
  },
  "indicativeNetworkIdentity": [

  ],
  "networkApplications": [
    {
      "id": "1abbe194-8638-4b50-846f-655f15ddad28",
      "instanceId": 11047,
      "authEntityId": 11047,
      "displayName": "11047",
      "authEntityClass": -217092956,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "appProtocol": "tcp/udp",
      "applicationGroup": "other",
      "applicationType": "DEFAULT",
      "categoryId": "878644b5-fe91-45b4-9204-31c84a73ba48",
      "createTime": 1517966740938,
      "encrypted": "false",
      "engineId": "13",
      "helpString": "American Express - Financial services company.",
      "ignoreConflict": false,
      "lastUpdateTime": 1517966740938,
      "longDescription": "American Express - Financial services company.",
      "name": "american-express",
      "nbarId": "1593",
      "p2pTechnology": "false",
      "popularity": 3,
      "rank": 65535,
      "references": "http://americanexpress.com",
      "selectorId": "664",
      "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
      "trafficClass": "TRANSACTIONAL_DATA",
      "tunnel": "false"
    }
  ],
  "networkIdentity": [
    {
      "id": "d22cb255-1910-4781-9e86-9a092fe2f9b2",
      "instanceId": 12116,
      "authEntityId": 12116,
      "displayName": "12116",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "53,80,443,5353",
      "protocol": "TCP",
      "upperPort": 0
    },
    {
      "id": "5b1cb25e-2c83-4f05-a994-80c95c2d13b2",
      "instanceId": 12117,
      "authEntityId": 12117,
      "displayName": "12117",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "53,5353",
      "protocol": "UDP",
      "upperPort": 0
    }
  ],
  "parentScalableGroup": {
    "idRef": "28e08de4-d7b2-4521-84ce-bc1e5ee0b9cc"
  },
  "user": [

  ],
  "userGroup": [

  ]
},
{
  "id": "8efe176a-f61a-4970-aeb7-306a22abc5b1",
  "instanceId": 15146,
  "authEntityId": 15146,
  "displayName": "15146",
  "authEntityClass": -1909073334,
  "deployPending": "NONE",
  "instanceVersion": 0,
  "createTime": 1517966744339,
  "deployed": false,
  "isSeeded": true,
  "isStale": false,
  "lastUpdateTime": 1517966744339,
  "name": "siri",
  "namespace": "scalablegroup:application",
  "provisioningState": "DEFINED",
  "qualifier": "application",
  "resourceVersion": 0,
  "targetIdList": [

  ],
  "type": "scalablegroup",
  "cfsChangeInfo": [

  ],
  "customProvisions": [

  ],
  "scalableGroupExternalHandle": "siri",
  "scalableGroupType": "APPLICATION",
  "securityGroupTag": 0,
  "state": "ACTIVE",
  "vnAgnostic": true,
  "identitySource": {
    "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
    "instanceId": 10010,
    "authEntityId": 10010,
    "displayName": "10010",
    "authEntityClass": 1738953278,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "state": "INACTIVE",
    "type": "NBAR"
  },
  "indicativeNetworkIdentity": [

  ],
  "networkApplications": [
    {
      "id": "7e7a443c-9d17-47ca-8d96-57e7774e245f",
      "instanceId": 16089,
      "authEntityId": 16089,
      "displayName": "16089",
      "authEntityClass": -217092956,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "appProtocol": "tcp/udp",
      "applicationGroup": "apple-group",
      "applicationType": "DEFAULT",
      "categoryId": "f6ec0221-b82d-43e8-a64d-163cbc13263b",
      "createTime": 1517966744339,
      "encrypted": "true",
      "engineId": "13",
      "helpString": "Siri - Apple's personal assistant",
      "ignoreConflict": false,
      "lastUpdateTime": 1517966744339,
      "longDescription": "Siri is a personal voice assistant. It uses a natural language user interface to answer questions, make recommendations, and perform actions by delegating requests to a set of web services.",
      "name": "siri",
      "nbarId": "1096",
      "p2pTechnology": "false",
      "popularity": 7,
      "rank": 65535,
      "references": "https://www.apple.com/siri/,https://www.theiphonewiki.com/wiki/Siri_Protocol",
      "selectorId": "1096",
      "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
      "trafficClass": "TRANSACTIONAL_DATA",
      "tunnel": "false"
    }
  ],
  "networkIdentity": [
    {
      "id": "ec316632-52ea-4217-ada5-b612fdfe46ed",
      "instanceId": 17088,
      "authEntityId": 17088,
      "displayName": "17088",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "53,5353",
      "protocol": "UDP",
      "upperPort": 0
    },
    {
      "id": "f158b022-7480-4bcd-9730-01c79bb64bfb",
      "instanceId": 17089,
      "authEntityId": 17089,
      "displayName": "17089",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "53,80,443,5353",
      "protocol": "TCP",
      "upperPort": 0
    }
  ],
  "parentScalableGroup": {
    "idRef": "5e66d25d-d517-482b-82a8-6f99f07fa786"
  },
  "user": [

  ],
  "userGroup": [

  ]
},
{
  "id": "8f321057-d7ef-4732-b4ff-2e2cd941461d",
  "instanceId": 4406,
  "authEntityId": 4406,
  "displayName": "4406",
  "authEntityClass": -1909073334,
  "deployPending": "NONE",
  "instanceVersion": 0,
  "createTime": 1517966742554,
  "deployed": false,
  "isSeeded": true,
  "isStale": false,
  "lastUpdateTime": 1517966742554,
  "name": "emfis-cntl",
  "namespace": "scalablegroup:application",
  "provisioningState": "DEFINED",
  "qualifier": "application",
  "resourceVersion": 0,
  "targetIdList": [

  ],
  "type": "scalablegroup",
  "cfsChangeInfo": [

  ],
  "customProvisions": [

  ],
  "scalableGroupExternalHandle": "emfis-cntl",
  "scalableGroupType": "APPLICATION",
  "securityGroupTag": 0,
  "state": "ACTIVE",
  "vnAgnostic": true,
  "identitySource": {
    "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
    "instanceId": 10010,
    "authEntityId": 10010,
    "displayName": "10010",
    "authEntityClass": 1738953278,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "state": "INACTIVE",
    "type": "NBAR"
  },
  "indicativeNetworkIdentity": [
    {
      "id": "435a3c92-645c-4903-ac8a-60de2840216c",
      "instanceId": 13013,
      "authEntityId": 13013,
      "displayName": "13013",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "141",
      "protocol": "UDP",
      "upperPort": 0
    },
    {
      "id": "05905274-bf5c-4a30-af28-999d8f0d8c78",
      "instanceId": 13014,
      "authEntityId": 13014,
      "displayName": "13014",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "141",
      "protocol": "TCP",
      "upperPort": 0
    }
  ],
  "networkApplications": [
    {
      "id": "d32b56df-e87e-4680-b18a-294fb58ac5b1",
      "instanceId": 11357,
      "authEntityId": 11357,
      "displayName": "11357",
      "authEntityClass": -217092956,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "appProtocol": "tcp/udp",
      "applicationGroup": "other",
      "applicationType": "DEFAULT",
      "categoryId": "6daa3dfd-3526-407a-88f0-97824b3f3a24",
      "createTime": 1517966742554,
      "encrypted": "false",
      "engineId": "3",
      "helpString": "EMFIS Control Service",
      "ignoreConflict": false,
      "lastUpdateTime": 1517966742554,
      "longDescription": "Registered with IANA on port 141 TCP/UDP",
      "name": "emfis-cntl",
      "nbarId": "933",
      "p2pTechnology": "false",
      "popularity": 1,
      "rank": 65535,
      "references": "http://www.iana.org/assignments/service-names-port-numbers/service-names-port-numbers.xml",
      "selectorId": "141",
      "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
      "trafficClass": "BULK_DATA",
      "tunnel": "false"
    }
  ],
  "networkIdentity": [
    {
      "id": "e91fc9c1-9fc2-48a9-b308-bb25a1337c1a",
      "instanceId": 13011,
      "authEntityId": 13011,
      "displayName": "13011",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "141",
      "protocol": "TCP",
      "upperPort": 0
    },
    {
      "id": "e0e03c3e-c370-4147-9142-31fd9217bd62",
      "instanceId": 13012,
      "authEntityId": 13012,
      "displayName": "13012",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "141",
      "protocol": "UDP",
      "upperPort": 0
    }
  ],
  "parentScalableGroup": {
    "idRef": "000c15bf-28fe-4fbb-9b69-35e6502721f1"
  },
  "user": [

  ],
  "userGroup": [

  ]
},
{
  "id": "8f333b3d-fde1-4d00-8f54-fe392aec90dc",
  "instanceId": 4199,
  "authEntityId": 4199,
  "displayName": "4199",
  "authEntityClass": -1909073334,
  "deployPending": "NONE",
  "instanceVersion": 0,
  "createTime": 1517966741665,
  "deployed": false,
  "isSeeded": true,
  "isStale": false,
  "lastUpdateTime": 1517966741665,
  "name": "borland-dsj",
  "namespace": "scalablegroup:application",
  "provisioningState": "DEFINED",
  "qualifier": "application",
  "resourceVersion": 0,
  "targetIdList": [

  ],
  "type": "scalablegroup",
  "cfsChangeInfo": [

  ],
  "customProvisions": [

  ],
  "scalableGroupExternalHandle": "borland-dsj",
  "scalableGroupType": "APPLICATION",
  "securityGroupTag": 0,
  "state": "ACTIVE",
  "vnAgnostic": true,
  "identitySource": {
    "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
    "instanceId": 10010,
    "authEntityId": 10010,
    "displayName": "10010",
    "authEntityClass": 1738953278,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "state": "INACTIVE",
    "type": "NBAR"
  },
  "indicativeNetworkIdentity": [
    {
      "id": "bd473b57-5d9b-4692-a444-8a8deab9c4db",
      "instanceId": 12429,
      "authEntityId": 12429,
      "displayName": "12429",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "707",
      "protocol": "TCP",
      "upperPort": 0
    },
    {
      "id": "be521262-a9c1-4e42-9846-d364f6cc95f6",
      "instanceId": 12430,
      "authEntityId": 12430,
      "displayName": "12430",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "707",
      "protocol": "UDP",
      "upperPort": 0
    }
  ],
  "networkApplications": [
    {
      "id": "f4b67962-270a-40f7-aec6-0e61be6b4a3e",
      "instanceId": 11155,
      "authEntityId": 11155,
      "displayName": "11155",
      "authEntityClass": -217092956,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "appProtocol": "tcp/udp",
      "applicationGroup": "other",
      "applicationType": "DEFAULT",
      "categoryId": "6daa3dfd-3526-407a-88f0-97824b3f3a24",
      "createTime": 1517966741665,
      "encrypted": "false",
      "engineId": "3",
      "helpString": "borland-dsj",
      "ignoreConflict": false,
      "lastUpdateTime": 1517966741665,
      "longDescription": "Deployment Server for Java (DSJ) is a deployment service. It is a part of Jbuilder, an integrated development environment (IDE) for the programming language Java, originally developed by Borland Software Corporation.",
      "name": "borland-dsj",
      "nbarId": "611",
      "p2pTechnology": "false",
      "popularity": 2,
      "rank": 65535,
      "references": "http://edn.embarcadero.com/article/10158",
      "selectorId": "707",
      "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
      "trafficClass": "BULK_DATA",
      "tunnel": "false"
    }
  ],
  "networkIdentity": [
    {
      "id": "6357262b-18d3-4610-abeb-192f667a9f83",
      "instanceId": 12427,
      "authEntityId": 12427,
      "displayName": "12427",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "707",
      "protocol": "TCP",
      "upperPort": 0
    },
    {
      "id": "0aaa855c-374a-46d2-8ce4-45a50c472bf5",
      "instanceId": 12428,
      "authEntityId": 12428,
      "displayName": "12428",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "707",
      "protocol": "UDP",
      "upperPort": 0
    }
  ],
  "parentScalableGroup": {
    "idRef": "000c15bf-28fe-4fbb-9b69-35e6502721f1"
  },
  "user": [

  ],
  "userGroup": [

  ]
},
{
  "id": "8f3bba79-0227-4ed3-a643-1e2e25a97926",
  "instanceId": 15415,
  "authEntityId": 15415,
  "displayName": "15415",
  "authEntityClass": -1909073334,
  "deployPending": "NONE",
  "instanceVersion": 0,
  "createTime": 1517966744925,
  "deployed": false,
  "isSeeded": true,
  "isStale": false,
  "lastUpdateTime": 1517966744925,
  "name": "wikia",
  "namespace": "scalablegroup:application",
  "provisioningState": "DEFINED",
  "qualifier": "application",
  "resourceVersion": 0,
  "targetIdList": [

  ],
  "type": "scalablegroup",
  "cfsChangeInfo": [

  ],
  "customProvisions": [

  ],
  "scalableGroupExternalHandle": "wikia",
  "scalableGroupType": "APPLICATION",
  "securityGroupTag": 0,
  "state": "ACTIVE",
  "vnAgnostic": true,
  "identitySource": {
    "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
    "instanceId": 10010,
    "authEntityId": 10010,
    "displayName": "10010",
    "authEntityClass": 1738953278,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "state": "INACTIVE",
    "type": "NBAR"
  },
  "indicativeNetworkIdentity": [

  ],
  "networkApplications": [
    {
      "id": "a5b0f63e-7f49-4ce1-9e94-4480fc9ae4de",
      "instanceId": 16358,
      "authEntityId": 16358,
      "displayName": "16358",
      "authEntityClass": -217092956,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "appProtocol": "tcp/udp",
      "applicationGroup": "other",
      "applicationType": "DEFAULT",
      "categoryId": "878644b5-fe91-45b4-9204-31c84a73ba48",
      "createTime": 1517966744925,
      "encrypted": "false",
      "engineId": "13",
      "helpString": "Wikia - Web portal to contribute and share the knowledge.",
      "ignoreConflict": false,
      "lastUpdateTime": 1517966744925,
      "longDescription": "Wikia - Web portal to contribute and share the knowledge.",
      "name": "wikia",
      "nbarId": "1563",
      "p2pTechnology": "false",
      "popularity": 3,
      "rank": 65535,
      "references": "http://wikia.com",
      "selectorId": "634",
      "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
      "trafficClass": "TRANSACTIONAL_DATA",
      "tunnel": "false"
    }
  ],
  "networkIdentity": [
    {
      "id": "a4e292cd-21c1-4cfa-bedb-2b9e98eab7ef",
      "instanceId": 17841,
      "authEntityId": 17841,
      "displayName": "17841",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "53,80,443,5353",
      "protocol": "TCP",
      "upperPort": 0
    },
    {
      "id": "add6a068-1c1e-48b1-bcf7-70589a8c71d0",
      "instanceId": 17842,
      "authEntityId": 17842,
      "displayName": "17842",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "53,5353",
      "protocol": "UDP",
      "upperPort": 0
    }
  ],
  "parentScalableGroup": {
    "idRef": "28e08de4-d7b2-4521-84ce-bc1e5ee0b9cc"
  },
  "user": [

  ],
  "userGroup": [

  ]
},
{
  "id": "8f543fd1-86f8-4425-bb73-7cbe101ce923",
  "instanceId": 4825,
  "authEntityId": 4825,
  "displayName": "4825",
  "authEntityClass": -1909073334,
  "deployPending": "NONE",
  "instanceVersion": 0,
  "createTime": 1517966743565,
  "deployed": false,
  "isSeeded": true,
  "isStale": false,
  "lastUpdateTime": 1517966743565,
  "name": "ncld",
  "namespace": "scalablegroup:application",
  "provisioningState": "DEFINED",
  "qualifier": "application",
  "resourceVersion": 0,
  "targetIdList": [

  ],
  "type": "scalablegroup",
  "cfsChangeInfo": [

  ],
  "customProvisions": [

  ],
  "scalableGroupExternalHandle": "ncld",
  "scalableGroupType": "APPLICATION",
  "securityGroupTag": 0,
  "state": "ACTIVE",
  "vnAgnostic": true,
  "identitySource": {
    "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
    "instanceId": 10010,
    "authEntityId": 10010,
    "displayName": "10010",
    "authEntityClass": 1738953278,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "state": "INACTIVE",
    "type": "NBAR"
  },
  "indicativeNetworkIdentity": [
    {
      "id": "82489ae9-b27f-441a-8740-bafaf93da0a5",
      "instanceId": 14108,
      "authEntityId": 14108,
      "displayName": "14108",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "405",
      "protocol": "UDP",
      "upperPort": 0
    },
    {
      "id": "ed8def66-8b56-4749-b056-ce8cae8d74b4",
      "instanceId": 14109,
      "authEntityId": 14109,
      "displayName": "14109",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "405",
      "protocol": "TCP",
      "upperPort": 0
    }
  ],
  "networkApplications": [
    {
      "id": "3745c1cf-2595-4691-a39d-d920f314bd2e",
      "instanceId": 11774,
      "authEntityId": 11774,
      "displayName": "11774",
      "authEntityClass": -217092956,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "appProtocol": "tcp/udp",
      "applicationGroup": "other",
      "applicationType": "DEFAULT",
      "categoryId": "6daa3dfd-3526-407a-88f0-97824b3f3a24",
      "createTime": 1517966743565,
      "encrypted": "false",
      "engineId": "3",
      "helpString": "ncld",
      "ignoreConflict": false,
      "lastUpdateTime": 1517966743565,
      "longDescription": "Registered with IANA on port 405 TCP/UDP",
      "name": "ncld",
      "nbarId": "320",
      "p2pTechnology": "false",
      "popularity": 1,
      "rank": 65535,
      "references": "http://www.iana.org/assignments/service-names-port-numbers/service-names-port-numbers.xml",
      "selectorId": "405",
      "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
      "trafficClass": "BULK_DATA",
      "tunnel": "false"
    }
  ],
  "networkIdentity": [
    {
      "id": "f8110f1c-ae03-4bc1-aa48-97159a4238e1",
      "instanceId": 14106,
      "authEntityId": 14106,
      "displayName": "14106",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "405",
      "protocol": "TCP",
      "upperPort": 0
    },
    {
      "id": "89eb4508-a1e3-43ea-b6a2-d792220de4af",
      "instanceId": 14107,
      "authEntityId": 14107,
      "displayName": "14107",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "405",
      "protocol": "UDP",
      "upperPort": 0
    }
  ],
  "parentScalableGroup": {
    "idRef": "000c15bf-28fe-4fbb-9b69-35e6502721f1"
  },
  "user": [

  ],
  "userGroup": [

  ]
},
{
  "id": "8f9e2bea-eb4d-4cfc-b4bf-eecd28ba6e15",
  "instanceId": 4521,
  "authEntityId": 4521,
  "displayName": "4521",
  "authEntityClass": -1909073334,
  "deployPending": "NONE",
  "instanceVersion": 0,
  "createTime": 1517966742878,
  "deployed": false,
  "isSeeded": true,
  "isStale": false,
  "lastUpdateTime": 1517966742878,
  "name": "gtp-user",
  "namespace": "scalablegroup:application",
  "provisioningState": "DEFINED",
  "qualifier": "application",
  "resourceVersion": 0,
  "targetIdList": [

  ],
  "type": "scalablegroup",
  "cfsChangeInfo": [

  ],
  "customProvisions": [

  ],
  "scalableGroupExternalHandle": "gtp-user",
  "scalableGroupType": "APPLICATION",
  "securityGroupTag": 0,
  "state": "ACTIVE",
  "vnAgnostic": true,
  "identitySource": {
    "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
    "instanceId": 10010,
    "authEntityId": 10010,
    "displayName": "10010",
    "authEntityClass": 1738953278,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "state": "INACTIVE",
    "type": "NBAR"
  },
  "indicativeNetworkIdentity": [
    {
      "id": "270c9b24-35fb-4991-824d-e1b2bb154f56",
      "instanceId": 13297,
      "authEntityId": 13297,
      "displayName": "13297",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "2152",
      "protocol": "UDP",
      "upperPort": 0
    },
    {
      "id": "619cd83b-db13-4390-aaba-13784e26535d",
      "instanceId": 13298,
      "authEntityId": 13298,
      "displayName": "13298",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "2152",
      "protocol": "TCP",
      "upperPort": 0
    }
  ],
  "networkApplications": [
    {
      "id": "6fbf30e0-2dbc-4106-84db-2133363351a6",
      "instanceId": 11472,
      "authEntityId": 11472,
      "displayName": "11472",
      "authEntityClass": -217092956,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "appProtocol": "tcp/udp",
      "applicationGroup": "other",
      "applicationType": "DEFAULT",
      "categoryId": "1fc17362-9334-4c7b-b752-5afdbb574017",
      "createTime": 1517966742878,
      "encrypted": "false",
      "engineId": "3",
      "helpString": "GTP-User Plane",
      "ignoreConflict": false,
      "lastUpdateTime": 1517966742878,
      "longDescription": "GTP-U is a GPRS Tunneling Protocol used for carrying user data within the GPRS Core Network and between theRadio Access Networkand the core network. The user data transported can be packets in any ofIPv4,IPv6, orPPP formats.",
      "name": "gtp-user",
      "nbarId": "740",
      "p2pTechnology": "false",
      "popularity": 6,
      "rank": 65535,
      "references": "http://en.wikipedia.org/wiki/GPRS_Tunnelling_Protocol",
      "selectorId": "2152",
      "subCategory": "ab63e736-9aa7-4946-b55b-3b26ddca609c",
      "trafficClass": "BULK_DATA",
      "tunnel": "true"
    }
  ],
  "networkIdentity": [
    {
      "id": "76796cdf-3a66-409c-895b-1b32b0fca7f9",
      "instanceId": 13296,
      "authEntityId": 13296,
      "displayName": "13296",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "2152",
      "protocol": "UDP",
      "upperPort": 0
    },
    {
      "id": "8e52227a-3839-4d85-9fe5-aecf2d3d830b",
      "instanceId": 13295,
      "authEntityId": 13295,
      "displayName": "13295",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "2152",
      "protocol": "TCP",
      "upperPort": 0
    }
  ],
  "parentScalableGroup": {
    "idRef": "79f9d505-477f-4ccd-bd2a-c5fc8097a2e1"
  },
  "user": [

  ],
  "userGroup": [

  ]
},
{
  "id": "8f9e2e77-da66-4918-8a8f-111d63634f11",
  "instanceId": 4287,
  "authEntityId": 4287,
  "displayName": "4287",
  "authEntityClass": -1909073334,
  "deployPending": "NONE",
  "instanceVersion": 0,
  "createTime": 1517966742156,
  "deployed": false,
  "isSeeded": true,
  "isStale": false,
  "lastUpdateTime": 1517966742156,
  "name": "contentserver",
  "namespace": "scalablegroup:application",
  "provisioningState": "DEFINED",
  "qualifier": "application",
  "resourceVersion": 0,
  "targetIdList": [

  ],
  "type": "scalablegroup",
  "cfsChangeInfo": [

  ],
  "customProvisions": [

  ],
  "scalableGroupExternalHandle": "contentserver",
  "scalableGroupType": "APPLICATION",
  "securityGroupTag": 0,
  "state": "ACTIVE",
  "vnAgnostic": true,
  "identitySource": {
    "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
    "instanceId": 10010,
    "authEntityId": 10010,
    "displayName": "10010",
    "authEntityClass": 1738953278,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "state": "INACTIVE",
    "type": "NBAR"
  },
  "indicativeNetworkIdentity": [
    {
      "id": "f53de8ab-6eab-48ab-8fbb-6993be5bde1b",
      "instanceId": 12633,
      "authEntityId": 12633,
      "displayName": "12633",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "3365",
      "protocol": "UDP",
      "upperPort": 0
    },
    {
      "id": "439fbca4-9a60-4fd0-9f7e-d26b06c5eec5",
      "instanceId": 12634,
      "authEntityId": 12634,
      "displayName": "12634",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "3365",
      "protocol": "TCP",
      "upperPort": 0
    }
  ],
  "networkApplications": [
    {
      "id": "9655625c-71a8-4783-8004-f4328cf0043e",
      "instanceId": 11240,
      "authEntityId": 11240,
      "displayName": "11240",
      "authEntityClass": -217092956,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "appProtocol": "tcp/udp",
      "applicationGroup": "other",
      "applicationType": "DEFAULT",
      "categoryId": "6daa3dfd-3526-407a-88f0-97824b3f3a24",
      "createTime": 1517966742156,
      "encrypted": "false",
      "engineId": "3",
      "helpString": "contentserver",
      "ignoreConflict": false,
      "lastUpdateTime": 1517966742156,
      "longDescription": "ContentServer is a Frontier 6 application that allows members of a website team to contribute to a Frontier-managed site, even if they don't use Frontier.",
      "name": "contentserver",
      "nbarId": "369",
      "p2pTechnology": "false",
      "popularity": 1,
      "rank": 65535,
      "references": "http://contentserver.userland.com/",
      "selectorId": "3365",
      "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
      "trafficClass": "BULK_DATA",
      "tunnel": "false"
    }
  ],
  "networkIdentity": [
    {
      "id": "00e4ba27-c386-4916-a0ab-d8710fc00a9b",
      "instanceId": 12631,
      "authEntityId": 12631,
      "displayName": "12631",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "3365",
      "protocol": "TCP",
      "upperPort": 0
    },
    {
      "id": "041c95b3-621c-4286-8d70-adf1bbdbfc4d",
      "instanceId": 12632,
      "authEntityId": 12632,
      "displayName": "12632",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "3365",
      "protocol": "UDP",
      "upperPort": 0
    }
  ],
  "parentScalableGroup": {
    "idRef": "000c15bf-28fe-4fbb-9b69-35e6502721f1"
  },
  "user": [

  ],
  "userGroup": [

  ]
},
{
  "id": "8fc9454e-0915-4b06-988b-9a0e1ac6fbd0",
  "instanceId": 15408,
  "authEntityId": 15408,
  "displayName": "15408",
  "authEntityClass": -1909073334,
  "deployPending": "NONE",
  "instanceVersion": 0,
  "createTime": 1517966744913,
  "deployed": false,
  "isSeeded": true,
  "isStale": false,
  "lastUpdateTime": 1517966744913,
  "name": "weibo",
  "namespace": "scalablegroup:application",
  "provisioningState": "DEFINED",
  "qualifier": "application",
  "resourceVersion": 0,
  "targetIdList": [

  ],
  "type": "scalablegroup",
  "cfsChangeInfo": [

  ],
  "customProvisions": [

  ],
  "scalableGroupExternalHandle": "weibo",
  "scalableGroupType": "APPLICATION",
  "securityGroupTag": 0,
  "state": "ACTIVE",
  "vnAgnostic": true,
  "identitySource": {
    "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
    "instanceId": 10010,
    "authEntityId": 10010,
    "displayName": "10010",
    "authEntityClass": 1738953278,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "state": "INACTIVE",
    "type": "NBAR"
  },
  "indicativeNetworkIdentity": [

  ],
  "networkApplications": [
    {
      "id": "036bda0a-7111-408a-929b-9a1413b2eb8d",
      "instanceId": 16351,
      "authEntityId": 16351,
      "displayName": "16351",
      "authEntityClass": -217092956,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "appProtocol": "tcp/udp",
      "applicationGroup": "other",
      "applicationType": "DEFAULT",
      "categoryId": "878644b5-fe91-45b4-9204-31c84a73ba48",
      "createTime": 1517966744913,
      "encrypted": "false",
      "engineId": "13",
      "helpString": "Sina Weibo - Chinese microblogging site.",
      "ignoreConflict": false,
      "lastUpdateTime": 1517966744913,
      "longDescription": "Sina Weibo - Chinese microblogging site.",
      "name": "weibo",
      "nbarId": "256",
      "p2pTechnology": "false",
      "popularity": 3,
      "rank": 65535,
      "references": "http://weibo.com",
      "selectorId": "461",
      "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
      "trafficClass": "TRANSACTIONAL_DATA",
      "tunnel": "false"
    }
  ],
  "networkIdentity": [
    {
      "id": "34170f44-cab3-447b-b84d-d4ca1140c733",
      "instanceId": 17825,
      "authEntityId": 17825,
      "displayName": "17825",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "53,80,443,5353",
      "protocol": "TCP",
      "upperPort": 0
    },
    {
      "id": "c02d0142-de80-4860-a58c-fb352c84b9ba",
      "instanceId": 17826,
      "authEntityId": 17826,
      "displayName": "17826",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "53,5353",
      "protocol": "UDP",
      "upperPort": 0
    }
  ],
  "parentScalableGroup": {
    "idRef": "28e08de4-d7b2-4521-84ce-bc1e5ee0b9cc"
  },
  "user": [

  ],
  "userGroup": [

  ]
},
{
  "id": "9036753c-cc7c-4999-92ce-17deaa8f621c",
  "instanceId": 15039,
  "authEntityId": 15039,
  "displayName": "15039",
  "authEntityClass": -1909073334,
  "deployPending": "NONE",
  "instanceVersion": 0,
  "createTime": 1517966744087,
  "deployed": false,
  "isSeeded": true,
  "isStale": false,
  "lastUpdateTime": 1517966744087,
  "name": "remote-kis",
  "namespace": "scalablegroup:application",
  "provisioningState": "DEFINED",
  "qualifier": "application",
  "resourceVersion": 0,
  "targetIdList": [

  ],
  "type": "scalablegroup",
  "cfsChangeInfo": [

  ],
  "customProvisions": [

  ],
  "scalableGroupExternalHandle": "remote-kis",
  "scalableGroupType": "APPLICATION",
  "securityGroupTag": 0,
  "state": "ACTIVE",
  "vnAgnostic": true,
  "identitySource": {
    "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
    "instanceId": 10010,
    "authEntityId": 10010,
    "displayName": "10010",
    "authEntityClass": 1738953278,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "state": "INACTIVE",
    "type": "NBAR"
  },
  "indicativeNetworkIdentity": [
    {
      "id": "b10f5349-e240-46a0-b5c1-14810a91c43c",
      "instanceId": 14745,
      "authEntityId": 14745,
      "displayName": "14745",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "185",
      "protocol": "TCP",
      "upperPort": 0
    },
    {
      "id": "fcc99f36-4c58-4a58-90e6-35724d607d49",
      "instanceId": 14746,
      "authEntityId": 14746,
      "displayName": "14746",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "185",
      "protocol": "UDP",
      "upperPort": 0
    }
  ],
  "networkApplications": [
    {
      "id": "d9e50c9c-666c-47d2-9c06-ce844180e462",
      "instanceId": 11978,
      "authEntityId": 11978,
      "displayName": "11978",
      "authEntityClass": -217092956,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "appProtocol": "tcp/udp",
      "applicationGroup": "other",
      "applicationType": "DEFAULT",
      "categoryId": "6daa3dfd-3526-407a-88f0-97824b3f3a24",
      "createTime": 1517966744087,
      "encrypted": "false",
      "engineId": "3",
      "helpString": "remote-kis",
      "ignoreConflict": false,
      "lastUpdateTime": 1517966744087,
      "longDescription": "Registered with IANA on port 185 TCP/UDP",
      "name": "remote-kis",
      "nbarId": "1028",
      "p2pTechnology": "false",
      "popularity": 1,
      "rank": 65535,
      "references": "http://www.iana.org/assignments/service-names-port-numbers/service-names-port-numbers.xml",
      "selectorId": "185",
      "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
      "trafficClass": "BULK_DATA",
      "tunnel": "false"
    }
  ],
  "networkIdentity": [
    {
      "id": "7beec734-b59b-4c0c-8115-1d128bd8a85b",
      "instanceId": 14743,
      "authEntityId": 14743,
      "displayName": "14743",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "185",
      "protocol": "UDP",
      "upperPort": 0
    },
    {
      "id": "fbcdfcad-3f1e-4b78-95a4-aebfbb7c44a3",
      "instanceId": 14744,
      "authEntityId": 14744,
      "displayName": "14744",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "185",
      "protocol": "TCP",
      "upperPort": 0
    }
  ],
  "parentScalableGroup": {
    "idRef": "000c15bf-28fe-4fbb-9b69-35e6502721f1"
  },
  "user": [

  ],
  "userGroup": [

  ]
},
{
  "id": "90b6a889-deb3-4136-ba18-4364b9658c79",
  "instanceId": 4984,
  "authEntityId": 4984,
  "displayName": "4984",
  "authEntityClass": -1909073334,
  "deployPending": "NONE",
  "instanceVersion": 0,
  "createTime": 1517966743973,
  "deployed": false,
  "isSeeded": true,
  "isStale": false,
  "lastUpdateTime": 1517966743973,
  "name": "printer",
  "namespace": "scalablegroup:application",
  "provisioningState": "DEFINED",
  "qualifier": "application",
  "resourceVersion": 0,
  "targetIdList": [

  ],
  "type": "scalablegroup",
  "cfsChangeInfo": [

  ],
  "customProvisions": [

  ],
  "scalableGroupExternalHandle": "printer",
  "scalableGroupType": "APPLICATION",
  "securityGroupTag": 0,
  "state": "ACTIVE",
  "vnAgnostic": true,
  "identitySource": {
    "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
    "instanceId": 10010,
    "authEntityId": 10010,
    "displayName": "10010",
    "authEntityClass": 1738953278,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "state": "INACTIVE",
    "type": "NBAR"
  },
  "indicativeNetworkIdentity": [
    {
      "id": "6a999d49-e09e-4287-98b5-4e2fc5a9f4c6",
      "instanceId": 14604,
      "authEntityId": 14604,
      "displayName": "14604",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "515",
      "protocol": "TCP",
      "upperPort": 0
    },
    {
      "id": "4199bf35-b4a3-4160-ad50-65a70cbbd695",
      "instanceId": 14605,
      "authEntityId": 14605,
      "displayName": "14605",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "515",
      "protocol": "UDP",
      "upperPort": 0
    }
  ],
  "networkApplications": [
    {
      "id": "2fef6733-a268-446a-95fa-da98256ae808",
      "instanceId": 11933,
      "authEntityId": 11933,
      "displayName": "11933",
      "authEntityClass": -217092956,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "appProtocol": "tcp/udp",
      "applicationGroup": "other",
      "applicationType": "DEFAULT",
      "categoryId": "6daa3dfd-3526-407a-88f0-97824b3f3a24",
      "createTime": 1517966743973,
      "encrypted": "false",
      "engineId": "3",
      "helpString": "spooler",
      "ignoreConflict": false,
      "lastUpdateTime": 1517966743973,
      "longDescription": "Line Printer Daemon protocol (LPD) or Line Printer Remote protocol (LPR) is a network protocol for submitting print jobs to a remote printer.",
      "name": "printer",
      "nbarId": "46",
      "p2pTechnology": "false",
      "popularity": 1,
      "rank": 65535,
      "references": "http://www.ietf.org/rfc/rfc1179.txt",
      "selectorId": "515",
      "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
      "trafficClass": "BULK_DATA",
      "tunnel": "false"
    }
  ],
  "networkIdentity": [
    {
      "id": "7a0c4a41-8626-4e43-a407-6bc7f0dc6ccc",
      "instanceId": 14602,
      "authEntityId": 14602,
      "displayName": "14602",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "515",
      "protocol": "UDP",
      "upperPort": 0
    },
    {
      "id": "a8620d57-effa-4a5e-83e0-e32f6577f949",
      "instanceId": 14603,
      "authEntityId": 14603,
      "displayName": "14603",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "515",
      "protocol": "TCP",
      "upperPort": 0
    }
  ],
  "parentScalableGroup": {
    "idRef": "000c15bf-28fe-4fbb-9b69-35e6502721f1"
  },
  "user": [

  ],
  "userGroup": [

  ]
},
{
  "id": "90c2fc88-2d56-4a5f-b20a-9f5959668570",
  "instanceId": 4494,
  "authEntityId": 4494,
  "displayName": "4494",
  "authEntityClass": -1909073334,
  "deployPending": "NONE",
  "instanceVersion": 0,
  "createTime": 1517966742812,
  "deployed": false,
  "isSeeded": true,
  "isStale": false,
  "lastUpdateTime": 1517966742812,
  "name": "google-accounts",
  "namespace": "scalablegroup:application",
  "provisioningState": "DEFINED",
  "qualifier": "application",
  "resourceVersion": 0,
  "targetIdList": [

  ],
  "type": "scalablegroup",
  "cfsChangeInfo": [

  ],
  "customProvisions": [

  ],
  "scalableGroupExternalHandle": "google-accounts",
  "scalableGroupType": "APPLICATION",
  "securityGroupTag": 0,
  "state": "ACTIVE",
  "vnAgnostic": true,
  "identitySource": {
    "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
    "instanceId": 10010,
    "authEntityId": 10010,
    "displayName": "10010",
    "authEntityClass": 1738953278,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "state": "INACTIVE",
    "type": "NBAR"
  },
  "indicativeNetworkIdentity": [

  ],
  "networkApplications": [
    {
      "id": "31cbc7cf-98cd-4f8d-b207-b219a8519f17",
      "instanceId": 11445,
      "authEntityId": 11445,
      "displayName": "11445",
      "authEntityClass": -217092956,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "appProtocol": "tcp/udp",
      "applicationGroup": "google-group",
      "applicationType": "DEFAULT",
      "categoryId": "f6ec0221-b82d-43e8-a64d-163cbc13263b",
      "createTime": 1517966742812,
      "encrypted": "true",
      "engineId": "13",
      "helpString": "Google Services Authentication",
      "ignoreConflict": false,
      "lastUpdateTime": 1517966742812,
      "longDescription": "Google Accounts Authentication protocol covers the traffic logging into Google services using Google certificates.",
      "name": "google-accounts",
      "nbarId": "1440",
      "p2pTechnology": "false",
      "popularity": 10,
      "rank": 65535,
      "references": "http://en.wikipedia.org/wiki/Google_Account",
      "selectorId": "528",
      "subCategory": "aab99e59-8589-4a61-933e-f01bff556d78",
      "trafficClass": "TRANSACTIONAL_DATA",
      "tunnel": "false"
    }
  ],
  "networkIdentity": [
    {
      "id": "1e1847a5-bd00-4615-b373-7f09930a10ed",
      "instanceId": 13232,
      "authEntityId": 13232,
      "displayName": "13232",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "53,5353",
      "protocol": "UDP",
      "upperPort": 0
    },
    {
      "id": "1f8d2a5c-99c1-4b37-89bd-7f4706938cac",
      "instanceId": 13233,
      "authEntityId": 13233,
      "displayName": "13233",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "53,80,443,5353",
      "protocol": "TCP",
      "upperPort": 0
    }
  ],
  "parentScalableGroup": {
    "idRef": "28e08de4-d7b2-4521-84ce-bc1e5ee0b9cc"
  },
  "user": [

  ],
  "userGroup": [

  ]
},
{
  "id": "91149efa-0e54-4dca-94e3-0714ddd7a98b",
  "instanceId": 4188,
  "authEntityId": 4188,
  "displayName": "4188",
  "authEntityClass": -1909073334,
  "deployPending": "NONE",
  "instanceVersion": 0,
  "createTime": 1517966741615,
  "deployed": false,
  "isSeeded": true,
  "isStale": false,
  "lastUpdateTime": 1517966741615,
  "name": "blaze-news",
  "namespace": "scalablegroup:application",
  "provisioningState": "DEFINED",
  "qualifier": "application",
  "resourceVersion": 0,
  "targetIdList": [

  ],
  "type": "scalablegroup",
  "cfsChangeInfo": [

  ],
  "customProvisions": [

  ],
  "scalableGroupExternalHandle": "blaze-news",
  "scalableGroupType": "APPLICATION",
  "securityGroupTag": 0,
  "state": "ACTIVE",
  "vnAgnostic": true,
  "identitySource": {
    "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
    "instanceId": 10010,
    "authEntityId": 10010,
    "displayName": "10010",
    "authEntityClass": 1738953278,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "state": "INACTIVE",
    "type": "NBAR"
  },
  "indicativeNetworkIdentity": [

  ],
  "networkApplications": [
    {
      "id": "474bdf74-c666-4537-9af8-1adfeeffe0b2",
      "instanceId": 11147,
      "authEntityId": 11147,
      "displayName": "11147",
      "authEntityClass": -217092956,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "appProtocol": "tcp/udp",
      "applicationGroup": "other",
      "applicationType": "DEFAULT",
      "categoryId": "878644b5-fe91-45b4-9204-31c84a73ba48",
      "createTime": 1517966741615,
      "encrypted": "false",
      "engineId": "13",
      "helpString": "The Blaze - News and Opinion website.",
      "ignoreConflict": false,
      "lastUpdateTime": 1517966741615,
      "longDescription": "The Blaze - News and Opinion website.",
      "name": "blaze-news",
      "nbarId": "1703",
      "p2pTechnology": "false",
      "popularity": 3,
      "rank": 65535,
      "references": "http://theblaze.com",
      "selectorId": "773",
      "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
      "trafficClass": "TRANSACTIONAL_DATA",
      "tunnel": "false"
    }
  ],
  "networkIdentity": [
    {
      "id": "055e838d-1b11-497a-a15d-970c1eff3924",
      "instanceId": 12405,
      "authEntityId": 12405,
      "displayName": "12405",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "53,80,443,5353",
      "protocol": "TCP",
      "upperPort": 0
    },
    {
      "id": "2f01d4a2-1507-421b-bd33-eac4d3e43bfa",
      "instanceId": 12406,
      "authEntityId": 12406,
      "displayName": "12406",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "53,5353",
      "protocol": "UDP",
      "upperPort": 0
    }
  ],
  "parentScalableGroup": {
    "idRef": "28e08de4-d7b2-4521-84ce-bc1e5ee0b9cc"
  },
  "user": [

  ],
  "userGroup": [

  ]
},
{
  "id": "91808d12-fd39-42a9-b48f-e1bc6c100656",
  "instanceId": 4202,
  "authEntityId": 4202,
  "displayName": "4202",
  "authEntityClass": -1909073334,
  "deployPending": "NONE",
  "instanceVersion": 0,
  "createTime": 1517966741675,
  "deployed": false,
  "isSeeded": true,
  "isStale": false,
  "lastUpdateTime": 1517966741675,
  "name": "buffer-com",
  "namespace": "scalablegroup:application",
  "provisioningState": "DEFINED",
  "qualifier": "application",
  "resourceVersion": 0,
  "targetIdList": [

  ],
  "type": "scalablegroup",
  "cfsChangeInfo": [

  ],
  "customProvisions": [

  ],
  "scalableGroupExternalHandle": "buffer-com",
  "scalableGroupType": "APPLICATION",
  "securityGroupTag": 0,
  "state": "ACTIVE",
  "vnAgnostic": true,
  "identitySource": {
    "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
    "instanceId": 10010,
    "authEntityId": 10010,
    "displayName": "10010",
    "authEntityClass": 1738953278,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "state": "INACTIVE",
    "type": "NBAR"
  },
  "indicativeNetworkIdentity": [

  ],
  "networkApplications": [
    {
      "id": "c1c1a8e5-367f-4bd1-a5d7-bd0436c422e0",
      "instanceId": 11158,
      "authEntityId": 11158,
      "displayName": "11158",
      "authEntityClass": -217092956,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "appProtocol": "tcp/udp",
      "applicationGroup": "other",
      "applicationType": "DEFAULT",
      "categoryId": "878644b5-fe91-45b4-9204-31c84a73ba48",
      "createTime": 1517966741675,
      "encrypted": "false",
      "engineId": "13",
      "helpString": "Buffer - App to share web pages with social media.",
      "ignoreConflict": false,
      "lastUpdateTime": 1517966741675,
      "longDescription": "Buffer - App to share web pages with social media.",
      "name": "buffer-com",
      "nbarId": "1724",
      "p2pTechnology": "false",
      "popularity": 3,
      "rank": 65535,
      "references": "http://bufferapp.com",
      "selectorId": "796",
      "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
      "trafficClass": "TRANSACTIONAL_DATA",
      "tunnel": "false"
    }
  ],
  "networkIdentity": [
    {
      "id": "9656f80a-c0bd-4c74-b603-817ebce22dd2",
      "instanceId": 12435,
      "authEntityId": 12435,
      "displayName": "12435",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "53,80,443,5353",
      "protocol": "TCP",
      "upperPort": 0
    },
    {
      "id": "1dc4d1da-7f64-48e5-811a-9619f61a6f8a",
      "instanceId": 12436,
      "authEntityId": 12436,
      "displayName": "12436",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "53,5353",
      "protocol": "UDP",
      "upperPort": 0
    }
  ],
  "parentScalableGroup": {
    "idRef": "28e08de4-d7b2-4521-84ce-bc1e5ee0b9cc"
  },
  "user": [

  ],
  "userGroup": [

  ]
},
{
  "id": "918eb438-0616-4d5d-877e-4a7a88a20a31",
  "instanceId": 15280,
  "authEntityId": 15280,
  "displayName": "15280",
  "authEntityClass": -1909073334,
  "deployPending": "NONE",
  "instanceVersion": 0,
  "createTime": 1517966744646,
  "deployed": false,
  "isSeeded": true,
  "isStale": false,
  "lastUpdateTime": 1517966744646,
  "name": "thechive",
  "namespace": "scalablegroup:application",
  "provisioningState": "DEFINED",
  "qualifier": "application",
  "resourceVersion": 0,
  "targetIdList": [

  ],
  "type": "scalablegroup",
  "cfsChangeInfo": [

  ],
  "customProvisions": [

  ],
  "scalableGroupExternalHandle": "thechive",
  "scalableGroupType": "APPLICATION",
  "securityGroupTag": 0,
  "state": "ACTIVE",
  "vnAgnostic": true,
  "identitySource": {
    "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
    "instanceId": 10010,
    "authEntityId": 10010,
    "displayName": "10010",
    "authEntityClass": 1738953278,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "state": "INACTIVE",
    "type": "NBAR"
  },
  "indicativeNetworkIdentity": [

  ],
  "networkApplications": [
    {
      "id": "9a407a94-9c21-4d77-8a99-1d6168091a9c",
      "instanceId": 16223,
      "authEntityId": 16223,
      "displayName": "16223",
      "authEntityClass": -217092956,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "appProtocol": "tcp/udp",
      "applicationGroup": "other",
      "applicationType": "DEFAULT",
      "categoryId": "878644b5-fe91-45b4-9204-31c84a73ba48",
      "createTime": 1517966744646,
      "encrypted": "false",
      "engineId": "13",
      "helpString": "theCHIVE - Funny photos and videos.",
      "ignoreConflict": false,
      "lastUpdateTime": 1517966744646,
      "longDescription": "theCHIVE - Funny photos and videos.",
      "name": "thechive",
      "nbarId": "257",
      "p2pTechnology": "false",
      "popularity": 3,
      "rank": 65535,
      "references": "http://thechive.com",
      "selectorId": "463",
      "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
      "trafficClass": "TRANSACTIONAL_DATA",
      "tunnel": "false"
    }
  ],
  "networkIdentity": [
    {
      "id": "24a50f2a-6a47-4de0-8b0e-37996e56ad38",
      "instanceId": 17467,
      "authEntityId": 17467,
      "displayName": "17467",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "53,5353",
      "protocol": "UDP",
      "upperPort": 0
    },
    {
      "id": "ac8c95bc-e5a4-443b-847a-7ecf1664fef2",
      "instanceId": 17468,
      "authEntityId": 17468,
      "displayName": "17468",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "53,80,443,5353",
      "protocol": "TCP",
      "upperPort": 0
    }
  ],
  "parentScalableGroup": {
    "idRef": "28e08de4-d7b2-4521-84ce-bc1e5ee0b9cc"
  },
  "user": [

  ],
  "userGroup": [

  ]
},
{
  "id": "919375f6-fcfd-4a07-8324-a99999138fe1",
  "instanceId": 4972,
  "authEntityId": 4972,
  "displayName": "4972",
  "authEntityClass": -1909073334,
  "deployPending": "NONE",
  "instanceVersion": 0,
  "createTime": 1517966743944,
  "deployed": false,
  "isSeeded": true,
  "isStale": false,
  "lastUpdateTime": 1517966743944,
  "name": "poco",
  "namespace": "scalablegroup:application",
  "provisioningState": "DEFINED",
  "qualifier": "application",
  "resourceVersion": 0,
  "targetIdList": [

  ],
  "type": "scalablegroup",
  "cfsChangeInfo": [

  ],
  "customProvisions": [

  ],
  "scalableGroupExternalHandle": "poco",
  "scalableGroupType": "APPLICATION",
  "securityGroupTag": 0,
  "state": "ACTIVE",
  "vnAgnostic": true,
  "identitySource": {
    "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
    "instanceId": 10010,
    "authEntityId": 10010,
    "displayName": "10010",
    "authEntityClass": 1738953278,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "state": "INACTIVE",
    "type": "NBAR"
  },
  "indicativeNetworkIdentity": [

  ],
  "networkApplications": [
    {
      "id": "b2954c34-ac4d-4880-a459-6e4daa93cb94",
      "instanceId": 11921,
      "authEntityId": 11921,
      "displayName": "11921",
      "authEntityClass": -217092956,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "applicationGroup": "other",
      "applicationType": "DEFAULT",
      "categoryId": "b33b2761-d981-4734-810a-a6a1c18ab352",
      "createTime": 1517966743944,
      "encrypted": "false",
      "engineId": "13",
      "helpString": "P2P file sharing Software",
      "ignoreConflict": false,
      "lastUpdateTime": 1517966743944,
      "longDescription": "Poco is a peer-to peer client popular in China. The protocol is based on GnucDNA library and uses Gnutella network architecture. Typically, Poco uses TCP port 5354 to download files, UDP ports 9099 9091 to login and chat respectively.",
      "name": "poco",
      "nbarId": "700",
      "p2pTechnology": "true",
      "popularity": 7,
      "rank": 65535,
      "references": "http://www.poco.cn/",
      "selectorId": "424",
      "subCategory": "79fe7198-9ce3-4c01-9b58-45a8df3d9513",
      "trafficClass": "BULK_DATA",
      "tunnel": "false"
    }
  ],
  "networkIdentity": [

  ],
  "parentScalableGroup": {
    "idRef": "7a1a3bbb-dc84-4a35-82a4-7e650c4c4761"
  },
  "user": [

  ],
  "userGroup": [

  ]
},
{
  "id": "91bb06a4-3c18-4f85-ae98-4ba084a86a38",
  "instanceId": 4906,
  "authEntityId": 4906,
  "displayName": "4906",
  "authEntityClass": -1909073334,
  "deployPending": "NONE",
  "instanceVersion": 0,
  "createTime": 1517966743726,
  "deployed": false,
  "isSeeded": true,
  "isStale": false,
  "lastUpdateTime": 1517966743726,
  "name": "omhs",
  "namespace": "scalablegroup:application",
  "provisioningState": "DEFINED",
  "qualifier": "application",
  "resourceVersion": 0,
  "targetIdList": [

  ],
  "type": "scalablegroup",
  "cfsChangeInfo": [

  ],
  "customProvisions": [

  ],
  "scalableGroupExternalHandle": "omhs",
  "scalableGroupType": "APPLICATION",
  "securityGroupTag": 0,
  "state": "ACTIVE",
  "vnAgnostic": true,
  "identitySource": {
    "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
    "instanceId": 10010,
    "authEntityId": 10010,
    "displayName": "10010",
    "authEntityClass": 1738953278,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "state": "INACTIVE",
    "type": "NBAR"
  },
  "indicativeNetworkIdentity": [
    {
      "id": "950b3a8a-3488-4d67-b4d3-11d7ac7a78f9",
      "instanceId": 14376,
      "authEntityId": 14376,
      "displayName": "14376",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "5723",
      "protocol": "TCP",
      "upperPort": 0
    },
    {
      "id": "5f4ee091-cfae-4430-bbb8-370cdbca4a10",
      "instanceId": 14377,
      "authEntityId": 14377,
      "displayName": "14377",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "5723",
      "protocol": "UDP",
      "upperPort": 0
    }
  ],
  "networkApplications": [
    {
      "id": "0d35f2f9-5eaf-4dec-aead-758fbf460b68",
      "instanceId": 11855,
      "authEntityId": 11855,
      "displayName": "11855",
      "authEntityClass": -217092956,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "appProtocol": "tcp/udp",
      "applicationGroup": "other",
      "applicationType": "DEFAULT",
      "categoryId": "6daa3dfd-3526-407a-88f0-97824b3f3a24",
      "createTime": 1517966743726,
      "encrypted": "false",
      "engineId": "3",
      "helpString": "Operations Manager - Health Service",
      "ignoreConflict": false,
      "lastUpdateTime": 1517966743726,
      "longDescription": "The Health Monitoring Service is used to monitor web services installed in one or multiple sites. The Health Monitoring Service can highlight issues that cause downtime or poor performance, such as unavailable services, long response time, and security breaches.",
      "name": "omhs",
      "nbarId": "1388",
      "p2pTechnology": "false",
      "popularity": 1,
      "rank": 65535,
      "references": "http://msdn.microsoft.com/en-us/library/dd464428.aspx",
      "selectorId": "5723",
      "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
      "trafficClass": "BULK_DATA",
      "tunnel": "false"
    }
  ],
  "networkIdentity": [
    {
      "id": "695b2475-0f6f-4fe9-b05f-1a8bccd5ac4b",
      "instanceId": 14374,
      "authEntityId": 14374,
      "displayName": "14374",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "5723",
      "protocol": "UDP",
      "upperPort": 0
    },
    {
      "id": "cf40cb9b-91de-4632-aa76-4e4e996305cc",
      "instanceId": 14375,
      "authEntityId": 14375,
      "displayName": "14375",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "5723",
      "protocol": "TCP",
      "upperPort": 0
    }
  ],
  "parentScalableGroup": {
    "idRef": "000c15bf-28fe-4fbb-9b69-35e6502721f1"
  },
  "user": [

  ],
  "userGroup": [

  ]
},
{
  "id": "91df9c04-16c3-433a-9b1c-1a8def36960e",
  "instanceId": 4237,
  "authEntityId": 4237,
  "displayName": "4237",
  "authEntityClass": -1909073334,
  "deployPending": "NONE",
  "instanceVersion": 0,
  "createTime": 1517966741881,
  "deployed": false,
  "isSeeded": true,
  "isStale": false,
  "lastUpdateTime": 1517966741881,
  "name": "cisco-ip-sla",
  "namespace": "scalablegroup:application",
  "provisioningState": "DEFINED",
  "qualifier": "application",
  "resourceVersion": 0,
  "targetIdList": [

  ],
  "type": "scalablegroup",
  "cfsChangeInfo": [

  ],
  "customProvisions": [

  ],
  "scalableGroupExternalHandle": "cisco-ip-sla",
  "scalableGroupType": "APPLICATION",
  "securityGroupTag": 0,
  "state": "ACTIVE",
  "vnAgnostic": true,
  "identitySource": {
    "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
    "instanceId": 10010,
    "authEntityId": 10010,
    "displayName": "10010",
    "authEntityClass": 1738953278,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "state": "INACTIVE",
    "type": "NBAR"
  },
  "indicativeNetworkIdentity": [

  ],
  "networkApplications": [
    {
      "id": "50013ed7-be22-49a1-b9d6-3b89224a7f5b",
      "instanceId": 11191,
      "authEntityId": 11191,
      "displayName": "11191",
      "authEntityClass": -217092956,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "applicationGroup": "other",
      "applicationType": "DEFAULT",
      "categoryId": "38a8e354-e2fe-4f3c-b1c5-2bbb90fb0d99",
      "createTime": 1517966741881,
      "encrypted": "false",
      "engineId": "13",
      "helpString": "Cisco IP SLA",
      "ignoreConflict": false,
      "lastUpdateTime": 1517966741881,
      "longDescription": "IP SLA (Internet Protocol Service Level Agreement) is an active computer network measurement technology",
      "name": "cisco-ip-sla",
      "nbarId": "1076",
      "p2pTechnology": "false",
      "popularity": 10,
      "rank": 65535,
      "references": "https://en.wikipedia.org/wiki/Service_Assurance_Agent",
      "selectorId": "1076",
      "subCategory": "53ce0342-1e2d-455a-acc7-1b50db9d6845",
      "trafficClass": "MULTIMEDIA_CONFERENCING",
      "tunnel": "false"
    }
  ],
  "networkIdentity": [

  ],
  "parentScalableGroup": {
    "idRef": "5fcd74d4-8726-47f7-a088-c457863da7ef"
  },
  "user": [

  ],
  "userGroup": [

  ]
},
{
  "id": "91e2b92b-8a80-4ca5-95da-cf17bc90ce2b",
  "instanceId": 4276,
  "authEntityId": 4276,
  "displayName": "4276",
  "authEntityClass": -1909073334,
  "deployPending": "NONE",
  "instanceVersion": 0,
  "createTime": 1517966742043,
  "deployed": false,
  "isSeeded": true,
  "isStale": false,
  "lastUpdateTime": 1517966742043,
  "name": "compressnet",
  "namespace": "scalablegroup:application",
  "provisioningState": "DEFINED",
  "qualifier": "application",
  "resourceVersion": 0,
  "targetIdList": [

  ],
  "type": "scalablegroup",
  "cfsChangeInfo": [

  ],
  "customProvisions": [

  ],
  "scalableGroupExternalHandle": "compressnet",
  "scalableGroupType": "APPLICATION",
  "securityGroupTag": 0,
  "state": "ACTIVE",
  "vnAgnostic": true,
  "identitySource": {
    "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
    "instanceId": 10010,
    "authEntityId": 10010,
    "displayName": "10010",
    "authEntityClass": 1738953278,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "state": "INACTIVE",
    "type": "NBAR"
  },
  "indicativeNetworkIdentity": [
    {
      "id": "96d6a08b-92fc-4c61-861b-2fa3fd62cdbf",
      "instanceId": 12604,
      "authEntityId": 12604,
      "displayName": "12604",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "2",
      "protocol": "UDP",
      "upperPort": 0
    },
    {
      "id": "042ba8ae-1d37-4d73-b9a3-3c5ed65bffd4",
      "instanceId": 12605,
      "authEntityId": 12605,
      "displayName": "12605",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "2",
      "protocol": "TCP",
      "upperPort": 0
    }
  ],
  "networkApplications": [
    {
      "id": "fb3141c2-a65d-4e5b-b10b-2a87acca798d",
      "instanceId": 11229,
      "authEntityId": 11229,
      "displayName": "11229",
      "authEntityClass": -217092956,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "appProtocol": "tcp/udp",
      "applicationGroup": "other",
      "applicationType": "DEFAULT",
      "categoryId": "1fc17362-9334-4c7b-b752-5afdbb574017",
      "createTime": 1517966742043,
      "encrypted": "false",
      "engineId": "3",
      "helpString": "Management Utility",
      "ignoreConflict": false,
      "lastUpdateTime": 1517966742043,
      "longDescription": "Registered with IANA on port 2 TCP/UDP",
      "name": "compressnet",
      "nbarId": "900",
      "p2pTechnology": "false",
      "popularity": 1,
      "rank": 65535,
      "references": "http://www.iana.org/assignments/service-names-port-numbers/service-names-port-numbers.xml",
      "selectorId": "2",
      "subCategory": "f3c0f761-d894-4112-ab89-b90fc84fd660",
      "trafficClass": "OPS_ADMIN_MGMT",
      "tunnel": "false"
    }
  ],
  "networkIdentity": [
    {
      "id": "2eb1ebe8-7895-405c-8f51-769f422ff1d2",
      "instanceId": 12602,
      "authEntityId": 12602,
      "displayName": "12602",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "2",
      "protocol": "TCP",
      "upperPort": 0
    },
    {
      "id": "e4b21fbf-5b32-4560-b605-cf1cf46c5338",
      "instanceId": 12603,
      "authEntityId": 12603,
      "displayName": "12603",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "2",
      "protocol": "UDP",
      "upperPort": 0
    }
  ],
  "parentScalableGroup": {
    "idRef": "5fcd74d4-8726-47f7-a088-c457863da7ef"
  },
  "user": [

  ],
  "userGroup": [

  ]
},
{
  "id": "91fa7495-0d95-4beb-b3e9-a3c652200bf5",
  "instanceId": 4178,
  "authEntityId": 4178,
  "displayName": "4178",
  "authEntityClass": -1909073334,
  "deployPending": "NONE",
  "instanceVersion": 0,
  "createTime": 1517966741544,
  "deployed": false,
  "isSeeded": true,
  "isStale": false,
  "lastUpdateTime": 1517966741544,
  "name": "bild-de",
  "namespace": "scalablegroup:application",
  "provisioningState": "DEFINED",
  "qualifier": "application",
  "resourceVersion": 0,
  "targetIdList": [

  ],
  "type": "scalablegroup",
  "cfsChangeInfo": [

  ],
  "customProvisions": [

  ],
  "scalableGroupExternalHandle": "bild-de",
  "scalableGroupType": "APPLICATION",
  "securityGroupTag": 0,
  "state": "ACTIVE",
  "vnAgnostic": true,
  "identitySource": {
    "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
    "instanceId": 10010,
    "authEntityId": 10010,
    "displayName": "10010",
    "authEntityClass": 1738953278,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "state": "INACTIVE",
    "type": "NBAR"
  },
  "indicativeNetworkIdentity": [

  ],
  "networkApplications": [
    {
      "id": "325d6f9f-c3b0-42f8-bcb0-cc4d9182d456",
      "instanceId": 11137,
      "authEntityId": 11137,
      "displayName": "11137",
      "authEntityClass": -217092956,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "appProtocol": "tcp/udp",
      "applicationGroup": "other",
      "applicationType": "DEFAULT",
      "categoryId": "878644b5-fe91-45b4-9204-31c84a73ba48",
      "createTime": 1517966741544,
      "encrypted": "false",
      "engineId": "13",
      "helpString": "Bild.de - Online edition of German tabloid.",
      "ignoreConflict": false,
      "lastUpdateTime": 1517966741544,
      "longDescription": "Bild.de - Online edition of German tabloid.",
      "name": "bild-de",
      "nbarId": "1605",
      "p2pTechnology": "false",
      "popularity": 3,
      "rank": 65535,
      "references": "http://bild.de",
      "selectorId": "675",
      "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
      "trafficClass": "TRANSACTIONAL_DATA",
      "tunnel": "false"
    }
  ],
  "networkIdentity": [
    {
      "id": "f26c843c-eb6a-47a7-8355-5b113a908509",
      "instanceId": 12386,
      "authEntityId": 12386,
      "displayName": "12386",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "53,80,443,5353",
      "protocol": "TCP",
      "upperPort": 0
    },
    {
      "id": "8cc4cd87-c9c5-47dd-b20f-bcab3c547605",
      "instanceId": 12387,
      "authEntityId": 12387,
      "displayName": "12387",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "53,5353",
      "protocol": "UDP",
      "upperPort": 0
    }
  ],
  "parentScalableGroup": {
    "idRef": "28e08de4-d7b2-4521-84ce-bc1e5ee0b9cc"
  },
  "user": [

  ],
  "userGroup": [

  ]
},
{
  "id": "92675006-ac35-4efe-98ea-c699ee400ee2",
  "instanceId": 15363,
  "authEntityId": 15363,
  "displayName": "15363",
  "authEntityClass": -1909073334,
  "deployPending": "NONE",
  "instanceVersion": 0,
  "createTime": 1517966744808,
  "deployed": false,
  "isSeeded": true,
  "isStale": false,
  "lastUpdateTime": 1517966744808,
  "name": "vmware-vsphere",
  "namespace": "scalablegroup:application",
  "provisioningState": "DEFINED",
  "qualifier": "application",
  "resourceVersion": 0,
  "targetIdList": [

  ],
  "type": "scalablegroup",
  "cfsChangeInfo": [

  ],
  "customProvisions": [

  ],
  "scalableGroupExternalHandle": "vmware-vsphere",
  "scalableGroupType": "APPLICATION",
  "securityGroupTag": 0,
  "state": "ACTIVE",
  "vnAgnostic": true,
  "identitySource": {
    "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
    "instanceId": 10010,
    "authEntityId": 10010,
    "displayName": "10010",
    "authEntityClass": 1738953278,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "state": "INACTIVE",
    "type": "NBAR"
  },
  "indicativeNetworkIdentity": [

  ],
  "networkApplications": [
    {
      "id": "f27ee5fe-e49e-4633-8ca4-3695bf39e4f3",
      "instanceId": 16306,
      "authEntityId": 16306,
      "displayName": "16306",
      "authEntityClass": -217092956,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "appProtocol": "tcp/udp",
      "applicationGroup": "vmware-group",
      "applicationType": "DEFAULT",
      "categoryId": "7e19fdea-6222-4c57-9e66-66c2340d6bc3",
      "createTime": 1517966744808,
      "encrypted": "true",
      "engineId": "13",
      "helpString": "VMware cloud infrastructure virtualization platform",
      "ignoreConflict": false,
      "lastUpdateTime": 1517966744808,
      "longDescription": "VMware cloud infrastructure virtualization platform. Concentration of user interface communication with ESX and vCenter infrastructure. For other types of traffic generated by VMware infrastructure please refer to the following protocols: vmware-vmotion, vmware-view and iscsi",
      "name": "vmware-vsphere",
      "nbarId": "1538",
      "p2pTechnology": "false",
      "popularity": 9,
      "rank": 65535,
      "references": "http://www.vmware.com/products/vsphere/",
      "selectorId": "599",
      "subCategory": "ff664205-64f3-447e-acff-1ec1ba9a6c31",
      "trafficClass": "MULTIMEDIA_STREAMING",
      "tunnel": "false"
    }
  ],
  "networkIdentity": [
    {
      "id": "9d665a66-6824-4b4c-9344-064cb124271f",
      "instanceId": 17702,
      "authEntityId": 17702,
      "displayName": "17702",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "53,80,443,5353",
      "protocol": "TCP",
      "upperPort": 0
    },
    {
      "id": "70d31754-a6ba-4334-8b5d-16b33e35c7c8",
      "instanceId": 17703,
      "authEntityId": 17703,
      "displayName": "17703",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "53,5353",
      "protocol": "UDP",
      "upperPort": 0
    }
  ],
  "parentScalableGroup": {
    "idRef": "4a6b37c6-7e11-40fe-a6b1-719b24813145"
  },
  "user": [

  ],
  "userGroup": [

  ]
},
{
  "id": "928b2781-194f-4861-aaed-495618863f05",
  "instanceId": 4307,
  "authEntityId": 4307,
  "displayName": "4307",
  "authEntityClass": -1909073334,
  "deployPending": "NONE",
  "instanceVersion": 0,
  "createTime": 1517966742224,
  "deployed": false,
  "isSeeded": true,
  "isStale": false,
  "lastUpdateTime": 1517966742224,
  "name": "csdn",
  "namespace": "scalablegroup:application",
  "provisioningState": "DEFINED",
  "qualifier": "application",
  "resourceVersion": 0,
  "targetIdList": [

  ],
  "type": "scalablegroup",
  "cfsChangeInfo": [

  ],
  "customProvisions": [

  ],
  "scalableGroupExternalHandle": "csdn",
  "scalableGroupType": "APPLICATION",
  "securityGroupTag": 0,
  "state": "ACTIVE",
  "vnAgnostic": true,
  "identitySource": {
    "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
    "instanceId": 10010,
    "authEntityId": 10010,
    "displayName": "10010",
    "authEntityClass": 1738953278,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "state": "INACTIVE",
    "type": "NBAR"
  },
  "indicativeNetworkIdentity": [

  ],
  "networkApplications": [
    {
      "id": "0d0b0b72-c28a-4e51-b388-9e66af6a24f3",
      "instanceId": 11260,
      "authEntityId": 11260,
      "displayName": "11260",
      "authEntityClass": -217092956,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "appProtocol": "tcp/udp",
      "applicationGroup": "other",
      "applicationType": "DEFAULT",
      "categoryId": "878644b5-fe91-45b4-9204-31c84a73ba48",
      "createTime": 1517966742224,
      "encrypted": "false",
      "engineId": "13",
      "helpString": "CSDN - Chinese IT community/forum for Software related issues.",
      "ignoreConflict": false,
      "lastUpdateTime": 1517966742224,
      "longDescription": "CSDN - Chinese IT community/forum for Software related issues.",
      "name": "csdn",
      "nbarId": "1667",
      "p2pTechnology": "false",
      "popularity": 3,
      "rank": 65535,
      "references": "http://csdn.net",
      "selectorId": "737",
      "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
      "trafficClass": "TRANSACTIONAL_DATA",
      "tunnel": "false"
    }
  ],
  "networkIdentity": [
    {
      "id": "6a77246f-85e1-489b-9b71-d617f23d7824",
      "instanceId": 12692,
      "authEntityId": 12692,
      "displayName": "12692",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "53,80,443,5353",
      "protocol": "TCP",
      "upperPort": 0
    },
    {
      "id": "463f8190-8c30-4f6d-9cbd-2c870fd5eead",
      "instanceId": 12693,
      "authEntityId": 12693,
      "displayName": "12693",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "53,5353",
      "protocol": "UDP",
      "upperPort": 0
    }
  ],
  "parentScalableGroup": {
    "idRef": "28e08de4-d7b2-4521-84ce-bc1e5ee0b9cc"
  },
  "user": [

  ],
  "userGroup": [

  ]
},
{
  "id": "92acd236-72a9-4baa-bb46-52fa9bb5228c",
  "instanceId": 15230,
  "authEntityId": 15230,
  "displayName": "15230",
  "authEntityClass": -1909073334,
  "deployPending": "NONE",
  "instanceVersion": 0,
  "createTime": 1517966744540,
  "deployed": false,
  "isSeeded": true,
  "isStale": false,
  "lastUpdateTime": 1517966744540,
  "name": "sun-dr",
  "namespace": "scalablegroup:application",
  "provisioningState": "DEFINED",
  "qualifier": "application",
  "resourceVersion": 0,
  "targetIdList": [

  ],
  "type": "scalablegroup",
  "cfsChangeInfo": [

  ],
  "customProvisions": [

  ],
  "scalableGroupExternalHandle": "sun-dr",
  "scalableGroupType": "APPLICATION",
  "securityGroupTag": 0,
  "state": "ACTIVE",
  "vnAgnostic": true,
  "identitySource": {
    "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
    "instanceId": 10010,
    "authEntityId": 10010,
    "displayName": "10010",
    "authEntityClass": 1738953278,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "state": "INACTIVE",
    "type": "NBAR"
  },
  "indicativeNetworkIdentity": [
    {
      "id": "2754c780-4dbf-414d-b29e-6709322a4261",
      "instanceId": 17324,
      "authEntityId": 17324,
      "displayName": "17324",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "665",
      "protocol": "UDP",
      "upperPort": 0
    },
    {
      "id": "0e93b77b-6f74-46aa-b8fc-aa2eeea80dbe",
      "instanceId": 17325,
      "authEntityId": 17325,
      "displayName": "17325",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "665",
      "protocol": "TCP",
      "upperPort": 0
    }
  ],
  "networkApplications": [
    {
      "id": "2ffdeb73-56c5-4733-8baa-b56c2c460f40",
      "instanceId": 16173,
      "authEntityId": 16173,
      "displayName": "16173",
      "authEntityClass": -217092956,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "appProtocol": "tcp/udp",
      "applicationGroup": "other",
      "applicationType": "DEFAULT",
      "categoryId": "1fc17362-9334-4c7b-b752-5afdbb574017",
      "createTime": 1517966744540,
      "encrypted": "false",
      "engineId": "3",
      "helpString": "sun-dr",
      "ignoreConflict": false,
      "lastUpdateTime": 1517966744540,
      "longDescription": "SUNDR is a network file system designed to store data securely on untrusted servers. SUNDR lets clients detect any attempts at unauthorized file modification by malicious server operators or users. SUNDRs protocol achieves a property called fork consistency, which guarantees that clients can detect any integrity or consistency failures as long as they see each others file modifications. An implementation is described that performs comparably with NFS (sometimes better and sometimes worse), while offering significantly stronger security.",
      "name": "sun-dr",
      "nbarId": "573",
      "p2pTechnology": "false",
      "popularity": 1,
      "rank": 65535,
      "references": "http://static.usenix.org/event/osdi04/tech/full_papers/li_j/li_j.pdf",
      "selectorId": "665",
      "subCategory": "f3c0f761-d894-4112-ab89-b90fc84fd660",
      "trafficClass": "OPS_ADMIN_MGMT",
      "tunnel": "false"
    }
  ],
  "networkIdentity": [
    {
      "id": "3c230269-cf69-4756-9aae-acf6da35d64f",
      "instanceId": 17322,
      "authEntityId": 17322,
      "displayName": "17322",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "665",
      "protocol": "UDP",
      "upperPort": 0
    },
    {
      "id": "36fae565-91f7-44c3-8e55-1720be33cfe8",
      "instanceId": 17323,
      "authEntityId": 17323,
      "displayName": "17323",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "665",
      "protocol": "TCP",
      "upperPort": 0
    }
  ],
  "parentScalableGroup": {
    "idRef": "5fcd74d4-8726-47f7-a088-c457863da7ef"
  },
  "user": [

  ],
  "userGroup": [

  ]
},
{
  "id": "92af735e-7add-4bed-ba32-7e1f4c56f7b9",
  "instanceId": 4684,
  "authEntityId": 4684,
  "displayName": "4684",
  "authEntityClass": -1909073334,
  "deployPending": "NONE",
  "instanceVersion": 0,
  "createTime": 1517966743278,
  "deployed": false,
  "isSeeded": true,
  "isStale": false,
  "lastUpdateTime": 1517966743278,
  "name": "l2tp",
  "namespace": "scalablegroup:application",
  "provisioningState": "DEFINED",
  "qualifier": "application",
  "resourceVersion": 0,
  "targetIdList": [

  ],
  "type": "scalablegroup",
  "cfsChangeInfo": [

  ],
  "customProvisions": [

  ],
  "scalableGroupExternalHandle": "l2tp",
  "scalableGroupType": "APPLICATION",
  "securityGroupTag": 0,
  "state": "ACTIVE",
  "vnAgnostic": true,
  "identitySource": {
    "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
    "instanceId": 10010,
    "authEntityId": 10010,
    "displayName": "10010",
    "authEntityClass": 1738953278,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "state": "INACTIVE",
    "type": "NBAR"
  },
  "indicativeNetworkIdentity": [
    {
      "id": "85c1b605-a290-437b-a73a-046494e37a28",
      "instanceId": 13725,
      "authEntityId": 13725,
      "displayName": "13725",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "1701",
      "protocol": "UDP",
      "upperPort": 0
    }
  ],
  "networkApplications": [
    {
      "id": "4ed7f749-1c51-4ee1-a5c5-3ea6e29b11f2",
      "instanceId": 11634,
      "authEntityId": 11634,
      "displayName": "11634",
      "authEntityClass": -217092956,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "appProtocol": "udp",
      "applicationGroup": "other",
      "applicationType": "DEFAULT",
      "categoryId": "70fce3cd-f2bd-48de-9020-7b7840b9e7a1",
      "createTime": 1517966743278,
      "encrypted": "false",
      "engineId": "1",
      "helpString": "Layer 2 Tunneling Protocol",
      "ignoreConflict": false,
      "lastUpdateTime": 1517966743278,
      "longDescription": "Layer 2 Tunneling Protocol (L2TP) is a tunneling protocol used to support virtual private networks (VPNs) or as part of the delivery of services by ISPs. It does not provide any encryption or confidentiality by itself; it relies on an encryption protocol that it passes within the tunnel to provide privacy.",
      "name": "l2tp",
      "nbarId": "22",
      "p2pTechnology": "false",
      "popularity": 5,
      "rank": 65535,
      "references": "http://tools.ietf.org/html/rfc2661",
      "selectorId": "115",
      "subCategory": "ab63e736-9aa7-4946-b55b-3b26ddca609c",
      "trafficClass": "BULK_DATA",
      "tunnel": "true"
    }
  ],
  "networkIdentity": [
    {
      "id": "9f717276-530b-43ee-8050-abd41f708db8",
      "instanceId": 13724,
      "authEntityId": 13724,
      "displayName": "13724",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "1701",
      "protocol": "UDP",
      "upperPort": 0
    }
  ],
  "parentScalableGroup": {
    "idRef": "79f9d505-477f-4ccd-bd2a-c5fc8097a2e1"
  },
  "user": [

  ],
  "userGroup": [

  ]
},
{
  "id": "92b91036-f802-4fa2-b136-f518464f76d0",
  "instanceId": 4428,
  "authEntityId": 4428,
  "displayName": "4428",
  "authEntityClass": -1909073334,
  "deployPending": "NONE",
  "instanceVersion": 0,
  "createTime": 1517966742624,
  "deployed": false,
  "isSeeded": true,
  "isStale": false,
  "lastUpdateTime": 1517966742624,
  "name": "etsy",
  "namespace": "scalablegroup:application",
  "provisioningState": "DEFINED",
  "qualifier": "application",
  "resourceVersion": 0,
  "targetIdList": [

  ],
  "type": "scalablegroup",
  "cfsChangeInfo": [

  ],
  "customProvisions": [

  ],
  "scalableGroupExternalHandle": "etsy",
  "scalableGroupType": "APPLICATION",
  "securityGroupTag": 0,
  "state": "ACTIVE",
  "vnAgnostic": true,
  "identitySource": {
    "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
    "instanceId": 10010,
    "authEntityId": 10010,
    "displayName": "10010",
    "authEntityClass": 1738953278,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "state": "INACTIVE",
    "type": "NBAR"
  },
  "indicativeNetworkIdentity": [

  ],
  "networkApplications": [
    {
      "id": "98cfbd91-f4e4-465f-a7ea-64c5e765e799",
      "instanceId": 11379,
      "authEntityId": 11379,
      "displayName": "11379",
      "authEntityClass": -217092956,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "appProtocol": "tcp/udp",
      "applicationGroup": "other",
      "applicationType": "DEFAULT",
      "categoryId": "878644b5-fe91-45b4-9204-31c84a73ba48",
      "createTime": 1517966742624,
      "encrypted": "false",
      "engineId": "13",
      "helpString": "Etsy - E-commerce website for homemade or vintage items.",
      "ignoreConflict": false,
      "lastUpdateTime": 1517966742624,
      "longDescription": "Etsy - E-commerce website for homemade or vintage items.",
      "name": "etsy",
      "nbarId": "1575",
      "p2pTechnology": "false",
      "popularity": 3,
      "rank": 65535,
      "references": "http://etsy.com",
      "selectorId": "646",
      "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
      "trafficClass": "TRANSACTIONAL_DATA",
      "tunnel": "false"
    }
  ],
  "networkIdentity": [
    {
      "id": "54cf24dd-1534-4704-bc81-368d41fe2a6c",
      "instanceId": 13079,
      "authEntityId": 13079,
      "displayName": "13079",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "53,5353",
      "protocol": "UDP",
      "upperPort": 0
    },
    {
      "id": "11a1aa6b-c597-4c97-b851-671b69076dc2",
      "instanceId": 13080,
      "authEntityId": 13080,
      "displayName": "13080",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "53,80,443,5353",
      "protocol": "TCP",
      "upperPort": 0
    }
  ],
  "parentScalableGroup": {
    "idRef": "28e08de4-d7b2-4521-84ce-bc1e5ee0b9cc"
  },
  "user": [

  ],
  "userGroup": [

  ]
},
{
  "id": "92de6db4-9524-4640-9e61-70cd3a90d373",
  "instanceId": 4080,
  "authEntityId": 4080,
  "displayName": "4080",
  "authEntityClass": -1909073334,
  "deployPending": "NONE",
  "instanceVersion": 0,
  "createTime": 1517966740932,
  "deployed": false,
  "isSeeded": true,
  "isStale": false,
  "lastUpdateTime": 1517966740932,
  "name": "ameba",
  "namespace": "scalablegroup:application",
  "provisioningState": "DEFINED",
  "qualifier": "application",
  "resourceVersion": 0,
  "targetIdList": [

  ],
  "type": "scalablegroup",
  "cfsChangeInfo": [

  ],
  "customProvisions": [

  ],
  "scalableGroupExternalHandle": "ameba",
  "scalableGroupType": "APPLICATION",
  "securityGroupTag": 0,
  "state": "ACTIVE",
  "vnAgnostic": true,
  "identitySource": {
    "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
    "instanceId": 10010,
    "authEntityId": 10010,
    "displayName": "10010",
    "authEntityClass": 1738953278,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "state": "INACTIVE",
    "type": "NBAR"
  },
  "indicativeNetworkIdentity": [

  ],
  "networkApplications": [
    {
      "id": "f82766d0-b22f-4449-b4fe-254e242403ed",
      "instanceId": 11046,
      "authEntityId": 11046,
      "displayName": "11046",
      "authEntityClass": -217092956,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "appProtocol": "tcp/udp",
      "applicationGroup": "other",
      "applicationType": "DEFAULT",
      "categoryId": "878644b5-fe91-45b4-9204-31c84a73ba48",
      "createTime": 1517966740932,
      "encrypted": "false",
      "engineId": "13",
      "helpString": "Ameba - Japanese blogging and social networking website.",
      "ignoreConflict": false,
      "lastUpdateTime": 1517966740932,
      "longDescription": "Ameba - Japanese blogging and social networking website.",
      "name": "ameba",
      "nbarId": "1625",
      "p2pTechnology": "false",
      "popularity": 3,
      "rank": 65535,
      "references": "http://ameba.jp",
      "selectorId": "695",
      "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
      "trafficClass": "TRANSACTIONAL_DATA",
      "tunnel": "false"
    }
  ],
  "networkIdentity": [
    {
      "id": "974a92eb-af0e-49fa-80eb-89e7c6e0386d",
      "instanceId": 12114,
      "authEntityId": 12114,
      "displayName": "12114",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "53,5353",
      "protocol": "UDP",
      "upperPort": 0
    },
    {
      "id": "52a18c68-ec61-4c89-bb4f-26e1e0a443ab",
      "instanceId": 12115,
      "authEntityId": 12115,
      "displayName": "12115",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "53,80,443,5353",
      "protocol": "TCP",
      "upperPort": 0
    }
  ],
  "parentScalableGroup": {
    "idRef": "28e08de4-d7b2-4521-84ce-bc1e5ee0b9cc"
  },
  "user": [

  ],
  "userGroup": [

  ]
},
{
  "id": "92dedbf4-d103-40fb-acd4-2021e749321f",
  "instanceId": 4462,
  "authEntityId": 4462,
  "displayName": "4462",
  "authEntityClass": -1909073334,
  "deployPending": "NONE",
  "instanceVersion": 0,
  "createTime": 1517966742732,
  "deployed": false,
  "isSeeded": true,
  "isStale": false,
  "lastUpdateTime": 1517966742732,
  "name": "foursquare",
  "namespace": "scalablegroup:application",
  "provisioningState": "DEFINED",
  "qualifier": "application",
  "resourceVersion": 0,
  "targetIdList": [

  ],
  "type": "scalablegroup",
  "cfsChangeInfo": [

  ],
  "customProvisions": [

  ],
  "scalableGroupExternalHandle": "foursquare",
  "scalableGroupType": "APPLICATION",
  "securityGroupTag": 0,
  "state": "ACTIVE",
  "vnAgnostic": true,
  "identitySource": {
    "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
    "instanceId": 10010,
    "authEntityId": 10010,
    "displayName": "10010",
    "authEntityClass": 1738953278,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "state": "INACTIVE",
    "type": "NBAR"
  },
  "indicativeNetworkIdentity": [

  ],
  "networkApplications": [
    {
      "id": "31aaf7a4-e8dc-4853-b75e-7c258cb5b6cf",
      "instanceId": 11413,
      "authEntityId": 11413,
      "displayName": "11413",
      "authEntityClass": -217092956,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "appProtocol": "tcp/udp",
      "applicationGroup": "other",
      "applicationType": "DEFAULT",
      "categoryId": "878644b5-fe91-45b4-9204-31c84a73ba48",
      "createTime": 1517966742731,
      "encrypted": "false",
      "engineId": "13",
      "helpString": "Foursquare - helps you find the perfect places to go with friends.",
      "ignoreConflict": false,
      "lastUpdateTime": 1517966742731,
      "longDescription": "Foursquare - helps you find the perfect places to go with friends.",
      "name": "foursquare",
      "nbarId": "1734",
      "p2pTechnology": "false",
      "popularity": 3,
      "rank": 65535,
      "references": "http://foursquare.com",
      "selectorId": "806",
      "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
      "trafficClass": "TRANSACTIONAL_DATA",
      "tunnel": "false"
    }
  ],
  "networkIdentity": [
    {
      "id": "c581fb26-bc25-4522-b7d1-a8fdb69bd0aa",
      "instanceId": 13152,
      "authEntityId": 13152,
      "displayName": "13152",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "53,5353",
      "protocol": "UDP",
      "upperPort": 0
    },
    {
      "id": "27789829-f213-4c91-85ae-06e622b84c82",
      "instanceId": 13151,
      "authEntityId": 13151,
      "displayName": "13151",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "53,80,443,5353",
      "protocol": "TCP",
      "upperPort": 0
    }
  ],
  "parentScalableGroup": {
    "idRef": "28e08de4-d7b2-4521-84ce-bc1e5ee0b9cc"
  },
  "user": [

  ],
  "userGroup": [

  ]
},
{
  "id": "931c0548-819f-4276-8fc1-158569c77ec4",
  "instanceId": 15240,
  "authEntityId": 15240,
  "displayName": "15240",
  "authEntityClass": -1909073334,
  "deployPending": "NONE",
  "instanceVersion": 0,
  "createTime": 1517966744561,
  "deployed": false,
  "isSeeded": true,
  "isStale": false,
  "lastUpdateTime": 1517966744561,
  "name": "swipe",
  "namespace": "scalablegroup:application",
  "provisioningState": "DEFINED",
  "qualifier": "application",
  "resourceVersion": 0,
  "targetIdList": [

  ],
  "type": "scalablegroup",
  "cfsChangeInfo": [

  ],
  "customProvisions": [

  ],
  "scalableGroupExternalHandle": "swipe",
  "scalableGroupType": "APPLICATION",
  "securityGroupTag": 0,
  "state": "ACTIVE",
  "vnAgnostic": true,
  "identitySource": {
    "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
    "instanceId": 10010,
    "authEntityId": 10010,
    "displayName": "10010",
    "authEntityClass": 1738953278,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "state": "INACTIVE",
    "type": "NBAR"
  },
  "indicativeNetworkIdentity": [
    {
      "id": "831e197e-548c-4c57-bd47-d332db062402",
      "instanceId": 17359,
      "authEntityId": 17359,
      "displayName": "17359",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipProtocolNumbers": "53",
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "protocol": "SWIPE",
      "upperPort": 0
    }
  ],
  "networkApplications": [
    {
      "id": "18fbb200-e099-46aa-8ea6-d9f5744124f3",
      "instanceId": 16183,
      "authEntityId": 16183,
      "displayName": "16183",
      "authEntityClass": -217092956,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "appProtocol": "ip",
      "applicationGroup": "other",
      "applicationType": "DEFAULT",
      "categoryId": "6daa3dfd-3526-407a-88f0-97824b3f3a24",
      "createTime": 1517966744561,
      "encrypted": "unassigned",
      "engineId": "1",
      "helpString": "IP with Encryption",
      "ignoreConflict": false,
      "lastUpdateTime": 1517966744561,
      "longDescription": "The swIPe IP Security Protocol (swIPe) is an experimental Internet Protocol (IP) security protocol that was specified in 1993. It operates at the Internet Layer of the Internet Protocol Suite.",
      "name": "swipe",
      "nbarId": "807",
      "p2pTechnology": "unassigned",
      "popularity": 2,
      "rank": 65535,
      "references": "http://www.crypto.com/papers/swipe.id.txt",
      "selectorId": "53",
      "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
      "trafficClass": "BULK_DATA",
      "tunnel": "unassigned"
    }
  ],
  "networkIdentity": [
    {
      "id": "b10dbf8d-e1c0-43f2-8f7f-7d82f43b12b9",
      "instanceId": 17358,
      "authEntityId": 17358,
      "displayName": "17358",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipProtocolNumbers": "53",
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "protocol": "SWIPE",
      "upperPort": 0
    }
  ],
  "parentScalableGroup": {
    "idRef": "000c15bf-28fe-4fbb-9b69-35e6502721f1"
  },
  "user": [

  ],
  "userGroup": [

  ]
},
{
  "id": "93492d14-c311-4adb-9ecf-66455c287359",
  "instanceId": 4340,
  "authEntityId": 4340,
  "displayName": "4340",
  "authEntityClass": -1909073334,
  "deployPending": "NONE",
  "instanceVersion": 0,
  "createTime": 1517966742337,
  "deployed": false,
  "isSeeded": true,
  "isStale": false,
  "lastUpdateTime": 1517966742337,
  "name": "ddm-ssl",
  "namespace": "scalablegroup:application",
  "provisioningState": "DEFINED",
  "qualifier": "application",
  "resourceVersion": 0,
  "targetIdList": [

  ],
  "type": "scalablegroup",
  "cfsChangeInfo": [

  ],
  "customProvisions": [

  ],
  "scalableGroupExternalHandle": "ddm-ssl",
  "scalableGroupType": "APPLICATION",
  "securityGroupTag": 0,
  "state": "ACTIVE",
  "vnAgnostic": true,
  "identitySource": {
    "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
    "instanceId": 10010,
    "authEntityId": 10010,
    "displayName": "10010",
    "authEntityClass": 1738953278,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "state": "INACTIVE",
    "type": "NBAR"
  },
  "indicativeNetworkIdentity": [
    {
      "id": "c3a1c9e2-2b75-4342-a1e7-ba27d7048cd6",
      "instanceId": 12796,
      "authEntityId": 12796,
      "displayName": "12796",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "448",
      "protocol": "UDP",
      "upperPort": 0
    },
    {
      "id": "527056da-9073-402e-a306-9201ae484acb",
      "instanceId": 12797,
      "authEntityId": 12797,
      "displayName": "12797",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "448",
      "protocol": "TCP",
      "upperPort": 0
    }
  ],
  "networkApplications": [
    {
      "id": "4160e097-8e83-4143-8217-6576c26f6117",
      "instanceId": 11291,
      "authEntityId": 11291,
      "displayName": "11291",
      "authEntityClass": -217092956,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "appProtocol": "tcp/udp",
      "applicationGroup": "other",
      "applicationType": "DEFAULT",
      "categoryId": "cb5915d1-afec-4c30-ac9e-069ff382272f",
      "createTime": 1517966742337,
      "encrypted": "true",
      "engineId": "3",
      "helpString": "Domino Domain Monitor database - Remote DB Access Using Secure Sockets",
      "ignoreConflict": false,
      "lastUpdateTime": 1517966742337,
      "longDescription": "IBM Lotus Domino domain monitoring (DDM) provides one location in the Domino Administrator client that you can use to view the overall status of multiple servers across one or more domains, and then use the information provided by DDM to quickly resolve problems.",
      "name": "ddm-ssl",
      "nbarId": "363",
      "p2pTechnology": "false",
      "popularity": 5,
      "rank": 65535,
      "references": "http://www-10.lotus.com/ldd/dominowiki.nsf/dx/domino-domain-monitoring",
      "selectorId": "448",
      "subCategory": "b90786f4-0190-45b8-9924-21b3e9682590",
      "trafficClass": "TRANSACTIONAL_DATA",
      "tunnel": "false"
    }
  ],
  "networkIdentity": [
    {
      "id": "ff807957-1613-4856-adf4-6577c99c7f13",
      "instanceId": 12794,
      "authEntityId": 12794,
      "displayName": "12794",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "448",
      "protocol": "UDP",
      "upperPort": 0
    },
    {
      "id": "69c42706-aa68-4506-9144-37c35b73ce77",
      "instanceId": 12795,
      "authEntityId": 12795,
      "displayName": "12795",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "448",
      "protocol": "TCP",
      "upperPort": 0
    }
  ],
  "parentScalableGroup": {
    "idRef": "8718395e-683e-4a2d-a232-100202751271"
  },
  "user": [

  ],
  "userGroup": [

  ]
},
{
  "id": "934ba907-9dc9-49fe-bc98-3daab215aa61",
  "instanceId": 4464,
  "authEntityId": 4464,
  "displayName": "4464",
  "authEntityClass": -1909073334,
  "deployPending": "NONE",
  "instanceVersion": 0,
  "createTime": 1517966742736,
  "deployed": false,
  "isSeeded": true,
  "isStale": false,
  "lastUpdateTime": 1517966742736,
  "name": "fring",
  "namespace": "scalablegroup:application",
  "provisioningState": "DEFINED",
  "qualifier": "application",
  "resourceVersion": 0,
  "targetIdList": [

  ],
  "type": "scalablegroup",
  "cfsChangeInfo": [

  ],
  "customProvisions": [

  ],
  "scalableGroupExternalHandle": "fring",
  "scalableGroupType": "APPLICATION",
  "securityGroupTag": 0,
  "state": "ACTIVE",
  "vnAgnostic": true,
  "identitySource": {
    "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
    "instanceId": 10010,
    "authEntityId": 10010,
    "displayName": "10010",
    "authEntityClass": 1738953278,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "state": "INACTIVE",
    "type": "NBAR"
  },
  "indicativeNetworkIdentity": [

  ],
  "networkApplications": [
    {
      "id": "55cacb36-63d6-4d9e-9209-71defddeece5",
      "instanceId": 11415,
      "authEntityId": 11415,
      "displayName": "11415",
      "authEntityClass": -217092956,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "applicationGroup": "fring-group",
      "applicationType": "DEFAULT",
      "categoryId": "0f6cab47-be95-4124-9db3-360ccd55f983",
      "createTime": 1517966742736,
      "encrypted": "false",
      "engineId": "13",
      "helpString": "DEPRECATED, traffic will not match",
      "ignoreConflict": false,
      "lastUpdateTime": 1517966742736,
      "longDescription": "Deprecated: Fring is a peer-to-peer mobile service enabling live chat, audio and video calls from mobile phones. Fring technology is a Mobile VoIP based internet telephony service. Fring runs on various mobile devices including iPhone, Android and Nokia. Fring refers to session initiation, IM and Ads.",
      "name": "fring",
      "nbarId": "1052",
      "p2pTechnology": "true",
      "popularity": 7,
      "rank": 65535,
      "references": "http://www.fring.com/what-is-fring",
      "selectorId": "54",
      "subCategory": "118c10a2-e272-47c1-92b9-4814ebe97138",
      "trafficClass": "MULTIMEDIA_CONFERENCING",
      "tunnel": "false"
    }
  ],
  "networkIdentity": [

  ],
  "parentScalableGroup": {
    "idRef": "fd6264c9-c16a-4311-a335-a3db039256f3"
  },
  "user": [

  ],
  "userGroup": [

  ]
},
{
  "id": "939031b6-c216-42e7-bb1d-3165383d589f",
  "instanceId": 4394,
  "authEntityId": 4394,
  "displayName": "4394",
  "authEntityClass": -1909073334,
  "deployPending": "NONE",
  "instanceVersion": 0,
  "createTime": 1517966742525,
  "deployed": false,
  "isSeeded": true,
  "isStale": false,
  "lastUpdateTime": 1517966742525,
  "name": "dtls",
  "namespace": "scalablegroup:application",
  "provisioningState": "DEFINED",
  "qualifier": "application",
  "resourceVersion": 0,
  "targetIdList": [

  ],
  "type": "scalablegroup",
  "cfsChangeInfo": [

  ],
  "customProvisions": [

  ],
  "scalableGroupExternalHandle": "dtls",
  "scalableGroupType": "APPLICATION",
  "securityGroupTag": 0,
  "state": "ACTIVE",
  "vnAgnostic": true,
  "identitySource": {
    "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
    "instanceId": 10010,
    "authEntityId": 10010,
    "displayName": "10010",
    "authEntityClass": 1738953278,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "state": "INACTIVE",
    "type": "NBAR"
  },
  "indicativeNetworkIdentity": [

  ],
  "networkApplications": [
    {
      "id": "50c4dff1-1c65-496f-b900-690b7b7240f9",
      "instanceId": 11345,
      "authEntityId": 11345,
      "displayName": "11345",
      "authEntityClass": -217092956,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "appProtocol": "tcp",
      "applicationGroup": "other",
      "applicationType": "DEFAULT",
      "categoryId": "878644b5-fe91-45b4-9204-31c84a73ba48",
      "createTime": 1517966742525,
      "encrypted": "true",
      "engineId": "13",
      "helpString": "Datagram Transport Layer",
      "ignoreConflict": false,
      "lastUpdateTime": 1517966742525,
      "longDescription": "The DTLS protocol provides communications privacy for datagram protocols.  The protocol allows client/serverapplications to communicate in a way that is designed to preventeavesdropping, tampering, or message forgery.  The DTLS protocol isbased on the Transport Layer Security (TLS) protocol and providesequivalent security guarantees.  Datagram semantics of the underlyingtransport are preserved by the DTLS protocol.",
      "name": "dtls",
      "nbarId": "1077",
      "p2pTechnology": "false",
      "popularity": 10,
      "rank": 65535,
      "references": "https://tools.ietf.org/html/rfc4347",
      "selectorId": "1077",
      "subCategory": "b90786f4-0190-45b8-9924-21b3e9682590",
      "trafficClass": "TRANSACTIONAL_DATA",
      "tunnel": "true"
    }
  ],
  "networkIdentity": [
    {
      "id": "3149aef8-ff87-4b29-9ff4-df0cca9be5c5",
      "instanceId": 12978,
      "authEntityId": 12978,
      "displayName": "12978",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "443",
      "protocol": "TCP",
      "upperPort": 0
    }
  ],
  "parentScalableGroup": {
    "idRef": "79f9d505-477f-4ccd-bd2a-c5fc8097a2e1"
  },
  "user": [

  ],
  "userGroup": [

  ]
},
{
  "id": "939c4872-9bb1-4bf7-8e45-c91149088e20",
  "instanceId": 4673,
  "authEntityId": 4673,
  "displayName": "4673",
  "authEntityClass": -1909073334,
  "deployPending": "NONE",
  "instanceVersion": 0,
  "createTime": 1517966743254,
  "deployed": false,
  "isSeeded": true,
  "isStale": false,
  "lastUpdateTime": 1517966743254,
  "name": "kerberos-adm",
  "namespace": "scalablegroup:application",
  "provisioningState": "DEFINED",
  "qualifier": "application",
  "resourceVersion": 0,
  "targetIdList": [

  ],
  "type": "scalablegroup",
  "cfsChangeInfo": [

  ],
  "customProvisions": [

  ],
  "scalableGroupExternalHandle": "kerberos-adm",
  "scalableGroupType": "APPLICATION",
  "securityGroupTag": 0,
  "state": "ACTIVE",
  "vnAgnostic": true,
  "identitySource": {
    "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
    "instanceId": 10010,
    "authEntityId": 10010,
    "displayName": "10010",
    "authEntityClass": 1738953278,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "state": "INACTIVE",
    "type": "NBAR"
  },
  "indicativeNetworkIdentity": [
    {
      "id": "9e547893-6c41-4e1a-8568-bd916d1402da",
      "instanceId": 13690,
      "authEntityId": 13690,
      "displayName": "13690",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "749",
      "protocol": "UDP",
      "upperPort": 0
    },
    {
      "id": "2cbb428e-3f44-4db4-b243-10a3d4d8400a",
      "instanceId": 13691,
      "authEntityId": 13691,
      "displayName": "13691",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "749",
      "protocol": "TCP",
      "upperPort": 0
    }
  ],
  "networkApplications": [
    {
      "id": "fa1e07d9-167b-4910-80d0-d04437e8acbd",
      "instanceId": 11623,
      "authEntityId": 11623,
      "displayName": "11623",
      "authEntityClass": -217092956,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "appProtocol": "tcp/udp",
      "applicationGroup": "other",
      "applicationType": "DEFAULT",
      "categoryId": "1fc17362-9334-4c7b-b752-5afdbb574017",
      "createTime": 1517966743254,
      "encrypted": "false",
      "engineId": "3",
      "helpString": "kerberos administration",
      "ignoreConflict": false,
      "lastUpdateTime": 1517966743254,
      "longDescription": "Kerberos is a network authentication protocol. The protocol is used to verify identities over the internet using a trusted third party. Extensions of the protocol also use the exchange of cryptographic certification of a public key. Usually the protocol uses TCP/UDP ports 88/749 as default.",
      "name": "kerberos-adm",
      "nbarId": "623",
      "p2pTechnology": "false",
      "popularity": 1,
      "rank": 65535,
      "references": "http://www.ietf.org/rfc/rfc4120",
      "selectorId": "749",
      "subCategory": "e390124a-0fd6-4253-b68e-4b0c1af8cb4b",
      "trafficClass": "OPS_ADMIN_MGMT",
      "tunnel": "false"
    }
  ],
  "networkIdentity": [
    {
      "id": "f17726fd-9036-47ea-a0c9-3dfff3abc0b3",
      "instanceId": 13688,
      "authEntityId": 13688,
      "displayName": "13688",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "749",
      "protocol": "UDP",
      "upperPort": 0
    },
    {
      "id": "382701cd-97c9-4eba-a717-40158f5df44e",
      "instanceId": 13689,
      "authEntityId": 13689,
      "displayName": "13689",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "749",
      "protocol": "TCP",
      "upperPort": 0
    }
  ],
  "parentScalableGroup": {
    "idRef": "7788bf2d-e289-4597-b500-17aacb7e820e"
  },
  "user": [

  ],
  "userGroup": [

  ]
},
{
  "id": "93b48e04-7745-4056-b720-35281a5022d4",
  "instanceId": 4514,
  "authEntityId": 4514,
  "displayName": "4514",
  "authEntityClass": -1909073334,
  "deployPending": "NONE",
  "instanceVersion": 0,
  "createTime": 1517966742857,
  "deployed": false,
  "isSeeded": true,
  "isStale": false,
  "lastUpdateTime": 1517966742857,
  "name": "gss-http",
  "namespace": "scalablegroup:application",
  "provisioningState": "DEFINED",
  "qualifier": "application",
  "resourceVersion": 0,
  "targetIdList": [

  ],
  "type": "scalablegroup",
  "cfsChangeInfo": [

  ],
  "customProvisions": [

  ],
  "scalableGroupExternalHandle": "gss-http",
  "scalableGroupType": "APPLICATION",
  "securityGroupTag": 0,
  "state": "ACTIVE",
  "vnAgnostic": true,
  "identitySource": {
    "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
    "instanceId": 10010,
    "authEntityId": 10010,
    "displayName": "10010",
    "authEntityClass": 1738953278,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "state": "INACTIVE",
    "type": "NBAR"
  },
  "indicativeNetworkIdentity": [
    {
      "id": "476aed7e-a309-4bab-9434-3e6c8e3fefca",
      "instanceId": 13280,
      "authEntityId": 13280,
      "displayName": "13280",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "488",
      "protocol": "TCP",
      "upperPort": 0
    },
    {
      "id": "6cb940c3-0865-4471-a5df-0ba9684b4925",
      "instanceId": 13279,
      "authEntityId": 13279,
      "displayName": "13279",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "488",
      "protocol": "UDP",
      "upperPort": 0
    }
  ],
  "networkApplications": [
    {
      "id": "3a4227ef-03a4-4c05-9b5d-5f6a7da02273",
      "instanceId": 11465,
      "authEntityId": 11465,
      "displayName": "11465",
      "authEntityClass": -217092956,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "appProtocol": "tcp/udp",
      "applicationGroup": "other",
      "applicationType": "DEFAULT",
      "categoryId": "878644b5-fe91-45b4-9204-31c84a73ba48",
      "createTime": 1517966742857,
      "encrypted": "unassigned",
      "engineId": "3",
      "helpString": "gss-http",
      "ignoreConflict": false,
      "lastUpdateTime": 1517966742857,
      "longDescription": "GSS-HTTP is an authentication mechanism for HTTP based on a multi-roundrip handshake using base64-encoded GSS-API tokens encoded in the WWW-Authenticate Response Header and the Authorization Request Header.",
      "name": "gss-http",
      "nbarId": "402",
      "p2pTechnology": "unassigned",
      "popularity": 1,
      "rank": 65535,
      "references": "http://tools.ietf.org/html/draft-johansson-http-gss-04#page-4",
      "selectorId": "488",
      "subCategory": "e390124a-0fd6-4253-b68e-4b0c1af8cb4b",
      "trafficClass": "OPS_ADMIN_MGMT",
      "tunnel": "unassigned"
    }
  ],
  "networkIdentity": [
    {
      "id": "a54dbd15-d9b5-47b1-8ebd-83b78a4fa8a3",
      "instanceId": 13277,
      "authEntityId": 13277,
      "displayName": "13277",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "488",
      "protocol": "UDP",
      "upperPort": 0
    },
    {
      "id": "90eabcb5-ac2b-4ff4-83eb-3d6f5500c20b",
      "instanceId": 13278,
      "authEntityId": 13278,
      "displayName": "13278",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "488",
      "protocol": "TCP",
      "upperPort": 0
    }
  ],
  "parentScalableGroup": {
    "idRef": "7788bf2d-e289-4597-b500-17aacb7e820e"
  },
  "user": [

  ],
  "userGroup": [

  ]
},
{
  "id": "93e90281-05d4-414f-9df6-eb017b424365",
  "instanceId": 15445,
  "authEntityId": 15445,
  "displayName": "15445",
  "authEntityClass": -1909073334,
  "deployPending": "NONE",
  "instanceVersion": 0,
  "createTime": 1517966744978,
  "deployed": false,
  "isSeeded": true,
  "isStale": false,
  "lastUpdateTime": 1517966744978,
  "name": "xns-mail",
  "namespace": "scalablegroup:application",
  "provisioningState": "DEFINED",
  "qualifier": "application",
  "resourceVersion": 0,
  "targetIdList": [

  ],
  "type": "scalablegroup",
  "cfsChangeInfo": [

  ],
  "customProvisions": [

  ],
  "scalableGroupExternalHandle": "xns-mail",
  "scalableGroupType": "APPLICATION",
  "securityGroupTag": 0,
  "state": "ACTIVE",
  "vnAgnostic": true,
  "identitySource": {
    "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
    "instanceId": 10010,
    "authEntityId": 10010,
    "displayName": "10010",
    "authEntityClass": 1738953278,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "state": "INACTIVE",
    "type": "NBAR"
  },
  "indicativeNetworkIdentity": [
    {
      "id": "a5fbbe79-1bac-4467-8a30-a5db53fc13fc",
      "instanceId": 17921,
      "authEntityId": 17921,
      "displayName": "17921",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "58",
      "protocol": "TCP",
      "upperPort": 0
    },
    {
      "id": "583fc885-3185-4421-8562-06c5d3f9dffb",
      "instanceId": 17922,
      "authEntityId": 17922,
      "displayName": "17922",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "58",
      "protocol": "UDP",
      "upperPort": 0
    }
  ],
  "networkApplications": [
    {
      "id": "b106e43e-fe56-4788-834d-186b04032570",
      "instanceId": 16388,
      "authEntityId": 16388,
      "displayName": "16388",
      "authEntityClass": -217092956,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "appProtocol": "tcp/udp",
      "applicationGroup": "xns-xerox-group",
      "applicationType": "DEFAULT",
      "categoryId": "27953c60-b2ff-4e1a-bc52-0187d8295250",
      "createTime": 1517966744978,
      "encrypted": "false",
      "engineId": "3",
      "helpString": "XNS mail",
      "ignoreConflict": false,
      "lastUpdateTime": 1517966744978,
      "longDescription": "XNS Mail is part of the Xerox Network Services (XNS) protocol suit, which provides general purpose network communications.",
      "name": "xns-mail",
      "nbarId": "937",
      "p2pTechnology": "false",
      "popularity": 1,
      "rank": 65535,
      "references": "http://www.pulsewan.com/data101/pdfs/xns.pdf",
      "selectorId": "58",
      "subCategory": "b90786f4-0190-45b8-9924-21b3e9682590",
      "trafficClass": "BULK_DATA",
      "tunnel": "false"
    }
  ],
  "networkIdentity": [
    {
      "id": "45d8a5b8-98d9-4a43-a059-2188a0f02986",
      "instanceId": 17920,
      "authEntityId": 17920,
      "displayName": "17920",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "58",
      "protocol": "UDP",
      "upperPort": 0
    },
    {
      "id": "849cb5f7-c01e-4b39-982b-4d731f3b702b",
      "instanceId": 17919,
      "authEntityId": 17919,
      "displayName": "17919",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "58",
      "protocol": "TCP",
      "upperPort": 0
    }
  ],
  "parentScalableGroup": {
    "idRef": "8a7ca43a-32bd-40d2-871a-02220a165cb7"
  },
  "user": [

  ],
  "userGroup": [

  ]
},
{
  "id": "93efc4b0-a6e6-405d-bf9b-a91b0510dd76",
  "instanceId": 4726,
  "authEntityId": 4726,
  "displayName": "4726",
  "authEntityClass": -1909073334,
  "deployPending": "NONE",
  "instanceVersion": 0,
  "createTime": 1517966743366,
  "deployed": false,
  "isSeeded": true,
  "isStale": false,
  "lastUpdateTime": 1517966743366,
  "name": "matip-type-b",
  "namespace": "scalablegroup:application",
  "provisioningState": "DEFINED",
  "qualifier": "application",
  "resourceVersion": 0,
  "targetIdList": [

  ],
  "type": "scalablegroup",
  "cfsChangeInfo": [

  ],
  "customProvisions": [

  ],
  "scalableGroupExternalHandle": "matip-type-b",
  "scalableGroupType": "APPLICATION",
  "securityGroupTag": 0,
  "state": "ACTIVE",
  "vnAgnostic": true,
  "identitySource": {
    "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
    "instanceId": 10010,
    "authEntityId": 10010,
    "displayName": "10010",
    "authEntityClass": 1738953278,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "state": "INACTIVE",
    "type": "NBAR"
  },
  "indicativeNetworkIdentity": [
    {
      "id": "7c47cd86-9a5f-4bf3-9c9a-6512f821f7e1",
      "instanceId": 13840,
      "authEntityId": 13840,
      "displayName": "13840",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "351",
      "protocol": "UDP",
      "upperPort": 0
    },
    {
      "id": "1c3cf037-939a-4adf-84c9-ef5fca5ae4d9",
      "instanceId": 13841,
      "authEntityId": 13841,
      "displayName": "13841",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "351",
      "protocol": "TCP",
      "upperPort": 0
    }
  ],
  "networkApplications": [
    {
      "id": "3a0514b3-5da4-46a0-9aa0-565b3b5b1644",
      "instanceId": 11676,
      "authEntityId": 11676,
      "displayName": "11676",
      "authEntityClass": -217092956,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "appProtocol": "tcp/udp",
      "applicationGroup": "other",
      "applicationType": "DEFAULT",
      "categoryId": "6daa3dfd-3526-407a-88f0-97824b3f3a24",
      "createTime": 1517966743366,
      "encrypted": "false",
      "engineId": "3",
      "helpString": "MATIP Type B",
      "ignoreConflict": false,
      "lastUpdateTime": 1517966743366,
      "longDescription": "Mapping of Airline Traffic over IP Type B (MATIP) is an e-mail application where real-time is not needed. The addressing scheme uses an international format defined by IATA and contains the city and airline codes. It is transmitted with a high level of protection, multi-addressing and four levels of priority.",
      "name": "matip-type-b",
      "nbarId": "267",
      "p2pTechnology": "false",
      "popularity": 1,
      "rank": 65535,
      "references": "http://tools.ietf.org/html/rfc2351",
      "selectorId": "351",
      "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
      "trafficClass": "BULK_DATA",
      "tunnel": "false"
    }
  ],
  "networkIdentity": [
    {
      "id": "ebb34c25-2d23-40bb-ab96-e9934112513b",
      "instanceId": 13838,
      "authEntityId": 13838,
      "displayName": "13838",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "351",
      "protocol": "UDP",
      "upperPort": 0
    },
    {
      "id": "8891574a-f310-46ba-93dd-bb713744f2f7",
      "instanceId": 13839,
      "authEntityId": 13839,
      "displayName": "13839",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "351",
      "protocol": "TCP",
      "upperPort": 0
    }
  ],
  "parentScalableGroup": {
    "idRef": "000c15bf-28fe-4fbb-9b69-35e6502721f1"
  },
  "user": [

  ],
  "userGroup": [

  ]
},
{
  "id": "94375514-fb01-4f5d-bc4c-52f060e61b82",
  "instanceId": 15137,
  "authEntityId": 15137,
  "displayName": "15137",
  "authEntityClass": -1909073334,
  "deployPending": "NONE",
  "instanceVersion": 0,
  "createTime": 1517966744319,
  "deployed": false,
  "isSeeded": true,
  "isStale": false,
  "lastUpdateTime": 1517966744319,
  "name": "shoutcast",
  "namespace": "scalablegroup:application",
  "provisioningState": "DEFINED",
  "qualifier": "application",
  "resourceVersion": 0,
  "targetIdList": [

  ],
  "type": "scalablegroup",
  "cfsChangeInfo": [

  ],
  "customProvisions": [

  ],
  "scalableGroupExternalHandle": "shoutcast",
  "scalableGroupType": "APPLICATION",
  "securityGroupTag": 0,
  "state": "ACTIVE",
  "vnAgnostic": true,
  "identitySource": {
    "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
    "instanceId": 10010,
    "authEntityId": 10010,
    "displayName": "10010",
    "authEntityClass": 1738953278,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "state": "INACTIVE",
    "type": "NBAR"
  },
  "indicativeNetworkIdentity": [

  ],
  "networkApplications": [
    {
      "id": "32249947-897b-4544-a0db-f09f17463600",
      "instanceId": 16080,
      "authEntityId": 16080,
      "displayName": "16080",
      "authEntityClass": -217092956,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "appProtocol": "tcp/udp",
      "applicationGroup": "other",
      "applicationType": "DEFAULT",
      "categoryId": "a09ef1b3-ba26-4372-aee8-309d50697e76",
      "createTime": 1517966744319,
      "encrypted": "false",
      "engineId": "13",
      "helpString": "SHOUTcast Internet Radio",
      "ignoreConflict": false,
      "lastUpdateTime": 1517966744319,
      "longDescription": "SHOUTcast is cross-platform proprietary software for streaming media over the Internet. The software, developed by Nullsoft (purchased by AOL on June 1, 1999), allows digital audio content, primarily in MP3 or HE-AAC format, to be broadcast to and from media player software, enabling the creation of Internet radio stations. SHOUTcast Radio is a related website which provides a directory of SHOUTcast stations. This protocol supports the traffic of listening to a radio channel on the different platforms. The traffic of broadcasting a radio channel is not included.",
      "name": "shoutcast",
      "nbarId": "1478",
      "p2pTechnology": "false",
      "popularity": 9,
      "rank": 65535,
      "references": "http://www.shoutcast.com/",
      "selectorId": "544",
      "subCategory": "bf1b9d2c-aa52-4f37-8860-40d4603319f5",
      "trafficClass": "MULTIMEDIA_STREAMING",
      "tunnel": "false"
    }
  ],
  "networkIdentity": [
    {
      "id": "6ff0922c-df2a-4d0f-a541-c883d674cad9",
      "instanceId": 17058,
      "authEntityId": 17058,
      "displayName": "17058",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "53,80,5353",
      "protocol": "TCP",
      "upperPort": 0
    },
    {
      "id": "23101a6f-ea33-4589-b774-fb22ebbd9a93",
      "instanceId": 17059,
      "authEntityId": 17059,
      "displayName": "17059",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "53,5353",
      "protocol": "UDP",
      "upperPort": 0
    }
  ],
  "parentScalableGroup": {
    "idRef": "fd6264c9-c16a-4311-a335-a3db039256f3"
  },
  "user": [

  ],
  "userGroup": [

  ]
},
{
  "id": "946d8de6-4199-4e3c-927c-0c6cccae517e",
  "instanceId": 15335,
  "authEntityId": 15335,
  "displayName": "15335",
  "authEntityClass": -1909073334,
  "deployPending": "NONE",
  "instanceVersion": 0,
  "createTime": 1517966744759,
  "deployed": false,
  "isSeeded": true,
  "isStale": false,
  "lastUpdateTime": 1517966744759,
  "name": "utmpcd",
  "namespace": "scalablegroup:application",
  "provisioningState": "DEFINED",
  "qualifier": "application",
  "resourceVersion": 0,
  "targetIdList": [

  ],
  "type": "scalablegroup",
  "cfsChangeInfo": [

  ],
  "customProvisions": [

  ],
  "scalableGroupExternalHandle": "utmpcd",
  "scalableGroupType": "APPLICATION",
  "securityGroupTag": 0,
  "state": "ACTIVE",
  "vnAgnostic": true,
  "identitySource": {
    "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
    "instanceId": 10010,
    "authEntityId": 10010,
    "displayName": "10010",
    "authEntityClass": 1738953278,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "state": "INACTIVE",
    "type": "NBAR"
  },
  "indicativeNetworkIdentity": [
    {
      "id": "c656ca15-d38a-49d3-b28f-bacfe589898c",
      "instanceId": 17621,
      "authEntityId": 17621,
      "displayName": "17621",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "431",
      "protocol": "TCP",
      "upperPort": 0
    },
    {
      "id": "20537fc6-4856-4454-b7d6-90a166e029e2",
      "instanceId": 17622,
      "authEntityId": 17622,
      "displayName": "17622",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "431",
      "protocol": "UDP",
      "upperPort": 0
    }
  ],
  "networkApplications": [
    {
      "id": "0a57c560-0131-4869-81ef-54405afdf92d",
      "instanceId": 16278,
      "authEntityId": 16278,
      "displayName": "16278",
      "authEntityClass": -217092956,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "appProtocol": "tcp/udp",
      "applicationGroup": "other",
      "applicationType": "DEFAULT",
      "categoryId": "6daa3dfd-3526-407a-88f0-97824b3f3a24",
      "createTime": 1517966744759,
      "encrypted": "false",
      "engineId": "3",
      "helpString": "utmpcd",
      "ignoreConflict": false,
      "lastUpdateTime": 1517966744759,
      "longDescription": "Registered with IANA on port 431 TCP/UDP",
      "name": "utmpcd",
      "nbarId": "346",
      "p2pTechnology": "false",
      "popularity": 1,
      "rank": 65535,
      "references": "http://www.iana.org/assignments/service-names-port-numbers/service-names-port-numbers.xml",
      "selectorId": "431",
      "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
      "trafficClass": "BULK_DATA",
      "tunnel": "false"
    }
  ],
  "networkIdentity": [
    {
      "id": "fa93e972-3614-46f6-849d-f4106361f9be",
      "instanceId": 17619,
      "authEntityId": 17619,
      "displayName": "17619",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "431",
      "protocol": "UDP",
      "upperPort": 0
    },
    {
      "id": "a36dbde0-c178-49f4-b2a8-9a403b8556a7",
      "instanceId": 17620,
      "authEntityId": 17620,
      "displayName": "17620",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "431",
      "protocol": "TCP",
      "upperPort": 0
    }
  ],
  "parentScalableGroup": {
    "idRef": "000c15bf-28fe-4fbb-9b69-35e6502721f1"
  },
  "user": [

  ],
  "userGroup": [

  ]
},
{
  "id": "949323d6-fe3d-40b1-aae9-7f9d4e08eb4a",
  "instanceId": 4444,
  "authEntityId": 4444,
  "displayName": "4444",
  "authEntityClass": -1909073334,
  "deployPending": "NONE",
  "instanceVersion": 0,
  "createTime": 1517966742677,
  "deployed": false,
  "isSeeded": true,
  "isStale": false,
  "lastUpdateTime": 1517966742677,
  "name": "fc2",
  "namespace": "scalablegroup:application",
  "provisioningState": "DEFINED",
  "qualifier": "application",
  "resourceVersion": 0,
  "targetIdList": [

  ],
  "type": "scalablegroup",
  "cfsChangeInfo": [

  ],
  "customProvisions": [

  ],
  "scalableGroupExternalHandle": "fc2",
  "scalableGroupType": "APPLICATION",
  "securityGroupTag": 0,
  "state": "ACTIVE",
  "vnAgnostic": true,
  "identitySource": {
    "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
    "instanceId": 10010,
    "authEntityId": 10010,
    "displayName": "10010",
    "authEntityClass": 1738953278,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "state": "INACTIVE",
    "type": "NBAR"
  },
  "indicativeNetworkIdentity": [

  ],
  "networkApplications": [
    {
      "id": "9f01d45f-1fd6-4530-a492-41c8c1fd52df",
      "instanceId": 11395,
      "authEntityId": 11395,
      "displayName": "11395",
      "authEntityClass": -217092956,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "appProtocol": "tcp/udp",
      "applicationGroup": "other",
      "applicationType": "DEFAULT",
      "categoryId": "b33b2761-d981-4734-810a-a6a1c18ab352",
      "createTime": 1517966742677,
      "encrypted": "false",
      "engineId": "13",
      "helpString": "Japanese web hosting service",
      "ignoreConflict": false,
      "lastUpdateTime": 1517966742677,
      "longDescription": "FC2 is a popular Japanese blogging host and the third most popular video hosting service in Japan. Among its other services are also websites tools such as ad-free hosting, analyzer, counters and more.",
      "name": "fc2",
      "nbarId": "1519",
      "p2pTechnology": "false",
      "popularity": 7,
      "rank": 65535,
      "references": "http://www.fc2.com",
      "selectorId": "580",
      "subCategory": "9fecfe24-2ee3-4b19-bbf7-3c992d1d25b4",
      "trafficClass": "BULK_DATA",
      "tunnel": "false"
    }
  ],
  "networkIdentity": [
    {
      "id": "30f0e7be-0e92-489d-831f-dc8114b65821",
      "instanceId": 13108,
      "authEntityId": 13108,
      "displayName": "13108",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "53,5353",
      "protocol": "UDP",
      "upperPort": 0
    },
    {
      "id": "146dd76c-a4a9-49d2-a770-bd2924a8cf09",
      "instanceId": 13109,
      "authEntityId": 13109,
      "displayName": "13109",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "53,80,5353",
      "protocol": "TCP",
      "upperPort": 0
    }
  ],
  "parentScalableGroup": {
    "idRef": "7a1a3bbb-dc84-4a35-82a4-7e650c4c4761"
  },
  "user": [

  ],
  "userGroup": [

  ]
},
{
  "id": "94a2b54c-d11e-4f34-8d79-3a8a6b0b4b1a",
  "instanceId": 5003,
  "authEntityId": 5003,
  "displayName": "5003",
  "authEntityClass": -1909073334,
  "deployPending": "NONE",
  "instanceVersion": 0,
  "createTime": 1517966744026,
  "deployed": false,
  "isSeeded": true,
  "isStale": false,
  "lastUpdateTime": 1517966744026,
  "name": "qmqp",
  "namespace": "scalablegroup:application",
  "provisioningState": "DEFINED",
  "qualifier": "application",
  "resourceVersion": 0,
  "targetIdList": [

  ],
  "type": "scalablegroup",
  "cfsChangeInfo": [

  ],
  "customProvisions": [

  ],
  "scalableGroupExternalHandle": "qmqp",
  "scalableGroupType": "APPLICATION",
  "securityGroupTag": 0,
  "state": "ACTIVE",
  "vnAgnostic": true,
  "identitySource": {
    "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
    "instanceId": 10010,
    "authEntityId": 10010,
    "displayName": "10010",
    "authEntityClass": 1738953278,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "state": "INACTIVE",
    "type": "NBAR"
  },
  "indicativeNetworkIdentity": [
    {
      "id": "a6d9a2c8-08be-4dfe-afb6-a3999b1ea794",
      "instanceId": 14670,
      "authEntityId": 14670,
      "displayName": "14670",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "628",
      "protocol": "TCP",
      "upperPort": 0
    },
    {
      "id": "f97b5436-769c-43b9-a0a0-092b34e35423",
      "instanceId": 14671,
      "authEntityId": 14671,
      "displayName": "14671",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "628",
      "protocol": "UDP",
      "upperPort": 0
    }
  ],
  "networkApplications": [
    {
      "id": "54206024-221f-4746-86d7-31348539e415",
      "instanceId": 11952,
      "authEntityId": 11952,
      "displayName": "11952",
      "authEntityClass": -217092956,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "appProtocol": "tcp/udp",
      "applicationGroup": "other",
      "applicationType": "DEFAULT",
      "categoryId": "27953c60-b2ff-4e1a-bc52-0187d8295250",
      "createTime": 1517966744026,
      "encrypted": "false",
      "engineId": "3",
      "helpString": "qmqp",
      "ignoreConflict": false,
      "lastUpdateTime": 1517966744026,
      "longDescription": "Quick Mail Queuing Protocol (QMQP) is a network protocol designed to share e-mail queues between several hosts. It is designed and implemented in qmail.",
      "name": "qmqp",
      "nbarId": "537",
      "p2pTechnology": "false",
      "popularity": 1,
      "rank": 65535,
      "references": "http://cr.yp.to/proto/qmqp.html",
      "selectorId": "628",
      "subCategory": "b90786f4-0190-45b8-9924-21b3e9682590",
      "trafficClass": "BULK_DATA",
      "tunnel": "false"
    }
  ],
  "networkIdentity": [
    {
      "id": "28b52409-982a-485c-aea8-92ba92a65c62",
      "instanceId": 14668,
      "authEntityId": 14668,
      "displayName": "14668",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "628",
      "protocol": "UDP",
      "upperPort": 0
    },
    {
      "id": "fc537e6e-15d8-49af-bd5b-637ad0134e71",
      "instanceId": 14669,
      "authEntityId": 14669,
      "displayName": "14669",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "628",
      "protocol": "TCP",
      "upperPort": 0
    }
  ],
  "parentScalableGroup": {
    "idRef": "8a7ca43a-32bd-40d2-871a-02220a165cb7"
  },
  "user": [

  ],
  "userGroup": [

  ]
},
{
  "id": "94d67421-ea2a-4029-b6ce-4db99929c584",
  "instanceId": 4884,
  "authEntityId": 4884,
  "displayName": "4884",
  "authEntityClass": -1909073334,
  "deployPending": "NONE",
  "instanceVersion": 0,
  "createTime": 1517966743679,
  "deployed": false,
  "isSeeded": true,
  "isStale": false,
  "lastUpdateTime": 1517966743679,
  "name": "nsiiops",
  "namespace": "scalablegroup:application",
  "provisioningState": "DEFINED",
  "qualifier": "application",
  "resourceVersion": 0,
  "targetIdList": [

  ],
  "type": "scalablegroup",
  "cfsChangeInfo": [

  ],
  "customProvisions": [

  ],
  "scalableGroupExternalHandle": "nsiiops",
  "scalableGroupType": "APPLICATION",
  "securityGroupTag": 0,
  "state": "ACTIVE",
  "vnAgnostic": true,
  "identitySource": {
    "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
    "instanceId": 10010,
    "authEntityId": 10010,
    "displayName": "10010",
    "authEntityClass": 1738953278,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "state": "INACTIVE",
    "type": "NBAR"
  },
  "indicativeNetworkIdentity": [
    {
      "id": "1788b6cf-f0b4-4e43-8a0d-9561208150df",
      "instanceId": 14300,
      "authEntityId": 14300,
      "displayName": "14300",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "261",
      "protocol": "UDP",
      "upperPort": 0
    },
    {
      "id": "54afcf20-c723-4cdb-84e6-7f34a37745b3",
      "instanceId": 14301,
      "authEntityId": 14301,
      "displayName": "14301",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "261",
      "protocol": "TCP",
      "upperPort": 0
    }
  ],
  "networkApplications": [
    {
      "id": "b60e68b8-d077-42bf-966c-61d84fe04519",
      "instanceId": 11833,
      "authEntityId": 11833,
      "displayName": "11833",
      "authEntityClass": -217092956,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "appProtocol": "tcp/udp",
      "applicationGroup": "corba-group",
      "applicationType": "DEFAULT",
      "categoryId": "58aa61dc-dd95-4a6d-9c7c-0ebf16da6dde",
      "createTime": 1517966743679,
      "encrypted": "true",
      "engineId": "3",
      "helpString": "secure IIOP - Internet Inter-ORB Protocol",
      "ignoreConflict": false,
      "lastUpdateTime": 1517966743679,
      "longDescription": "Internet Inter-ORB Protocol (IIOP) is used to allow different computer programs which use different programming code to communicate with one another on the Internet. IIOP Name Service operates in Secure Socket Layer and Transport Layer Security(SSL/TLS).",
      "name": "nsiiops",
      "nbarId": "1133",
      "p2pTechnology": "false",
      "popularity": 6,
      "rank": 65535,
      "references": "http://www2.informatik.hu-berlin.de/~obecker/Lehre/SS2001/CORBA/specs/01-02-51.pdf",
      "selectorId": "261",
      "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
      "trafficClass": "OPS_ADMIN_MGMT",
      "tunnel": "false"
    }
  ],
  "networkIdentity": [
    {
      "id": "983f24be-2f12-46ea-a4f5-02ce04cf67a2",
      "instanceId": 14298,
      "authEntityId": 14298,
      "displayName": "14298",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "261",
      "protocol": "TCP",
      "upperPort": 0
    },
    {
      "id": "b05d4dd5-e257-4d3c-931d-6f21046cb5fb",
      "instanceId": 14299,
      "authEntityId": 14299,
      "displayName": "14299",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "261",
      "protocol": "UDP",
      "upperPort": 0
    }
  ],
  "parentScalableGroup": {
    "idRef": "38b9eb6b-f3b6-4e13-9b79-a11e0c6423d4"
  },
  "user": [

  ],
  "userGroup": [

  ]
},
{
  "id": "94d7105c-031d-4570-b4a2-95655656e1fe",
  "instanceId": 4205,
  "authEntityId": 4205,
  "displayName": "4205",
  "authEntityClass": -1909073334,
  "deployPending": "NONE",
  "instanceVersion": 0,
  "createTime": 1517966741719,
  "deployed": false,
  "isSeeded": true,
  "isStale": false,
  "lastUpdateTime": 1517966741719,
  "name": "cab-protocol",
  "namespace": "scalablegroup:application",
  "provisioningState": "DEFINED",
  "qualifier": "application",
  "resourceVersion": 0,
  "targetIdList": [

  ],
  "type": "scalablegroup",
  "cfsChangeInfo": [

  ],
  "customProvisions": [

  ],
  "scalableGroupExternalHandle": "cab-protocol",
  "scalableGroupType": "APPLICATION",
  "securityGroupTag": 0,
  "state": "ACTIVE",
  "vnAgnostic": true,
  "identitySource": {
    "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
    "instanceId": 10010,
    "authEntityId": 10010,
    "displayName": "10010",
    "authEntityClass": 1738953278,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "state": "INACTIVE",
    "type": "NBAR"
  },
  "indicativeNetworkIdentity": [
    {
      "id": "c9630594-7ac2-42e5-bf3f-57f18e545d1e",
      "instanceId": 12443,
      "authEntityId": 12443,
      "displayName": "12443",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "595",
      "protocol": "UDP",
      "upperPort": 0
    },
    {
      "id": "82c0f62b-e4b2-4dd6-a267-7ba03998d1fd",
      "instanceId": 12444,
      "authEntityId": 12444,
      "displayName": "12444",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "595",
      "protocol": "TCP",
      "upperPort": 0
    }
  ],
  "networkApplications": [
    {
      "id": "1b260a6a-f929-4a99-9905-7975296af8ed",
      "instanceId": 11161,
      "authEntityId": 11161,
      "displayName": "11161",
      "authEntityClass": -217092956,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "appProtocol": "tcp/udp",
      "applicationGroup": "other",
      "applicationType": "DEFAULT",
      "categoryId": "6daa3dfd-3526-407a-88f0-97824b3f3a24",
      "createTime": 1517966741719,
      "encrypted": "unassigned",
      "engineId": "3",
      "helpString": "CAB Protocol",
      "ignoreConflict": false,
      "lastUpdateTime": 1517966741719,
      "longDescription": "CAB Protocol provides a real estate developer and/or a facility manager with a suite of standardized methods for exchanging real-time data between building automation systems.",
      "name": "cab-protocol",
      "nbarId": "509",
      "p2pTechnology": "unassigned",
      "popularity": 1,
      "rank": 65535,
      "references": "http://tech-env.com/cab.html",
      "selectorId": "595",
      "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
      "trafficClass": "BULK_DATA",
      "tunnel": "unassigned"
    }
  ],
  "networkIdentity": [
    {
      "id": "9a7adaf4-f28a-49df-8d73-127dc484c916",
      "instanceId": 12441,
      "authEntityId": 12441,
      "displayName": "12441",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "595",
      "protocol": "UDP",
      "upperPort": 0
    },
    {
      "id": "6eef3f91-d4c9-46ef-ab1d-3f92dd808e85",
      "instanceId": 12442,
      "authEntityId": 12442,
      "displayName": "12442",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "595",
      "protocol": "TCP",
      "upperPort": 0
    }
  ],
  "parentScalableGroup": {
    "idRef": "000c15bf-28fe-4fbb-9b69-35e6502721f1"
  },
  "user": [

  ],
  "userGroup": [

  ]
},
{
  "id": "952f47e1-af13-4c15-b47d-668779ab714e",
  "instanceId": 15354,
  "authEntityId": 15354,
  "displayName": "15354",
  "authEntityClass": -1909073334,
  "deployPending": "NONE",
  "instanceVersion": 0,
  "createTime": 1517966744792,
  "deployed": false,
  "isSeeded": true,
  "isStale": false,
  "lastUpdateTime": 1517966744792,
  "name": "virtual-places",
  "namespace": "scalablegroup:application",
  "provisioningState": "DEFINED",
  "qualifier": "application",
  "resourceVersion": 0,
  "targetIdList": [

  ],
  "type": "scalablegroup",
  "cfsChangeInfo": [

  ],
  "customProvisions": [

  ],
  "scalableGroupExternalHandle": "virtual-places",
  "scalableGroupType": "APPLICATION",
  "securityGroupTag": 0,
  "state": "ACTIVE",
  "vnAgnostic": true,
  "identitySource": {
    "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
    "instanceId": 10010,
    "authEntityId": 10010,
    "displayName": "10010",
    "authEntityClass": 1738953278,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "state": "INACTIVE",
    "type": "NBAR"
  },
  "indicativeNetworkIdentity": [
    {
      "id": "e7a8109e-c469-4023-84fe-1e1cc1273b58",
      "instanceId": 17680,
      "authEntityId": 17680,
      "displayName": "17680",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "1533",
      "protocol": "UDP",
      "upperPort": 0
    },
    {
      "id": "c6c00348-b702-4fa2-8a85-75c6ac1e5d56",
      "instanceId": 17679,
      "authEntityId": 17679,
      "displayName": "17679",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "1533",
      "protocol": "TCP",
      "upperPort": 0
    }
  ],
  "networkApplications": [
    {
      "id": "c5d73c0e-d1af-43e1-a390-ac1657b50370",
      "instanceId": 16297,
      "authEntityId": 16297,
      "displayName": "16297",
      "authEntityClass": -217092956,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "appProtocol": "tcp/udp",
      "applicationGroup": "other",
      "applicationType": "DEFAULT",
      "categoryId": "6daa3dfd-3526-407a-88f0-97824b3f3a24",
      "createTime": 1517966744792,
      "encrypted": "false",
      "engineId": "3",
      "helpString": "Virtual places software",
      "ignoreConflict": false,
      "lastUpdateTime": 1517966744792,
      "longDescription": "Virtual Places Chat is software that uses the paradigm that any web page on the Internet is a chat room, a Virtual Place, if one or more people are viewing the page with the VPchat program. A web browser is an integral part of VPchat. Below the browser window there is a chat pane in which the conversation text is displayed. Below the chat pane is a box for entering text for the conversation. To the right of the browser window is a list of people in the room. In their haste to make money, they separated hundreds of friends who would not pay for chat.",
      "name": "virtual-places",
      "nbarId": "1385",
      "p2pTechnology": "false",
      "popularity": 7,
      "rank": 65535,
      "references": "http://en.wikipedia.org/wiki/Virtual_Places_Chat",
      "selectorId": "1533",
      "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
      "trafficClass": "BULK_DATA",
      "tunnel": "false"
    }
  ],
  "networkIdentity": [
    {
      "id": "913739ac-035f-4b96-968a-5de03e9de85c",
      "instanceId": 17677,
      "authEntityId": 17677,
      "displayName": "17677",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "1533",
      "protocol": "TCP",
      "upperPort": 0
    },
    {
      "id": "4c1837c0-a73f-4b8f-a536-6a6b8eb31bc0",
      "instanceId": 17678,
      "authEntityId": 17678,
      "displayName": "17678",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "1533",
      "protocol": "UDP",
      "upperPort": 0
    }
  ],
  "parentScalableGroup": {
    "idRef": "000c15bf-28fe-4fbb-9b69-35e6502721f1"
  },
  "user": [

  ],
  "userGroup": [

  ]
},
{
  "id": "955f57c1-7dd7-4f2b-8936-4aafeca1ab49",
  "instanceId": 4545,
  "authEntityId": 4545,
  "displayName": "4545",
  "authEntityClass": -1909073334,
  "deployPending": "NONE",
  "instanceVersion": 0,
  "createTime": 1517966742950,
  "deployed": false,
  "isSeeded": true,
  "isStale": false,
  "lastUpdateTime": 1517966742950,
  "name": "hollywood-reporter",
  "namespace": "scalablegroup:application",
  "provisioningState": "DEFINED",
  "qualifier": "application",
  "resourceVersion": 0,
  "targetIdList": [

  ],
  "type": "scalablegroup",
  "cfsChangeInfo": [

  ],
  "customProvisions": [

  ],
  "scalableGroupExternalHandle": "hollywood-reporter",
  "scalableGroupType": "APPLICATION",
  "securityGroupTag": 0,
  "state": "ACTIVE",
  "vnAgnostic": true,
  "identitySource": {
    "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
    "instanceId": 10010,
    "authEntityId": 10010,
    "displayName": "10010",
    "authEntityClass": 1738953278,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "state": "INACTIVE",
    "type": "NBAR"
  },
  "indicativeNetworkIdentity": [

  ],
  "networkApplications": [
    {
      "id": "eef94c59-4059-4c22-a7dd-ed67392f039f",
      "instanceId": 11496,
      "authEntityId": 11496,
      "displayName": "11496",
      "authEntityClass": -217092956,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "appProtocol": "tcp/udp",
      "applicationGroup": "other",
      "applicationType": "DEFAULT",
      "categoryId": "878644b5-fe91-45b4-9204-31c84a73ba48",
      "createTime": 1517966742950,
      "encrypted": "false",
      "engineId": "13",
      "helpString": "The Hollywood Reporter - News related to the entertainment industry.",
      "ignoreConflict": false,
      "lastUpdateTime": 1517966742950,
      "longDescription": "The Hollywood Reporter - News related to the entertainment industry.",
      "name": "hollywood-reporter",
      "nbarId": "1718",
      "p2pTechnology": "false",
      "popularity": 3,
      "rank": 65535,
      "references": "http://hollywoodreporter.com",
      "selectorId": "790",
      "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
      "trafficClass": "TRANSACTIONAL_DATA",
      "tunnel": "false"
    }
  ],
  "networkIdentity": [
    {
      "id": "7dfec854-9756-4168-bee6-c2b440eb194f",
      "instanceId": 13353,
      "authEntityId": 13353,
      "displayName": "13353",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "53,80,443,5353",
      "protocol": "TCP",
      "upperPort": 0
    },
    {
      "id": "2b18be59-4501-4492-98f1-5404ba56e485",
      "instanceId": 13354,
      "authEntityId": 13354,
      "displayName": "13354",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "53,5353",
      "protocol": "UDP",
      "upperPort": 0
    }
  ],
  "parentScalableGroup": {
    "idRef": "28e08de4-d7b2-4521-84ce-bc1e5ee0b9cc"
  },
  "user": [

  ],
  "userGroup": [

  ]
},
{
  "id": "956d9ae5-03b9-4800-aca4-3b37432de874",
  "instanceId": 4131,
  "authEntityId": 4131,
  "displayName": "4131",
  "authEntityClass": -1909073334,
  "deployPending": "NONE",
  "instanceVersion": 0,
  "createTime": 1517966741278,
  "deployed": false,
  "isSeeded": true,
  "isStale": false,
  "lastUpdateTime": 1517966741278,
  "name": "at-7",
  "namespace": "scalablegroup:application",
  "provisioningState": "DEFINED",
  "qualifier": "application",
  "resourceVersion": 0,
  "targetIdList": [

  ],
  "type": "scalablegroup",
  "cfsChangeInfo": [

  ],
  "customProvisions": [

  ],
  "scalableGroupExternalHandle": "at-7",
  "scalableGroupType": "APPLICATION",
  "securityGroupTag": 0,
  "state": "ACTIVE",
  "vnAgnostic": true,
  "identitySource": {
    "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
    "instanceId": 10010,
    "authEntityId": 10010,
    "displayName": "10010",
    "authEntityClass": 1738953278,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "state": "INACTIVE",
    "type": "NBAR"
  },
  "indicativeNetworkIdentity": [
    {
      "id": "0028aa3f-eee5-4f6d-a2a9-fb911475fae6",
      "instanceId": 12253,
      "authEntityId": 12253,
      "displayName": "12253",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "207",
      "protocol": "TCP",
      "upperPort": 0
    },
    {
      "id": "e6685530-0355-4494-98e5-b901a059fd25",
      "instanceId": 12254,
      "authEntityId": 12254,
      "displayName": "12254",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "207",
      "protocol": "UDP",
      "upperPort": 0
    }
  ],
  "networkApplications": [
    {
      "id": "860855a4-f57b-403e-ab11-fd85e2eef39f",
      "instanceId": 11093,
      "authEntityId": 11093,
      "displayName": "11093",
      "authEntityClass": -217092956,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "appProtocol": "tcp/udp",
      "applicationGroup": "apple-talk-group",
      "applicationType": "DEFAULT",
      "categoryId": "70fce3cd-f2bd-48de-9020-7b7840b9e7a1",
      "createTime": 1517966741278,
      "encrypted": "false",
      "engineId": "3",
      "helpString": "AppleTalk Unused",
      "ignoreConflict": false,
      "lastUpdateTime": 1517966741278,
      "longDescription": "Registered with IANA on port 207 TCP/UDP",
      "name": "at-7",
      "nbarId": "1105",
      "p2pTechnology": "false",
      "popularity": 1,
      "rank": 65535,
      "references": "http://www.iana.org/assignments/service-names-port-numbers/service-names-port-numbers.xml",
      "selectorId": "207",
      "subCategory": "f3c0f761-d894-4112-ab89-b90fc84fd660",
      "trafficClass": "OPS_ADMIN_MGMT",
      "tunnel": "false"
    }
  ],
  "networkIdentity": [
    {
      "id": "7cf0ca54-9be0-4087-82f9-ddf7bfd6a3bb",
      "instanceId": 12251,
      "authEntityId": 12251,
      "displayName": "12251",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "207",
      "protocol": "TCP",
      "upperPort": 0
    },
    {
      "id": "082bb160-27f6-4099-b9de-cde704ade3f4",
      "instanceId": 12252,
      "authEntityId": 12252,
      "displayName": "12252",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "207",
      "protocol": "UDP",
      "upperPort": 0
    }
  ],
  "parentScalableGroup": {
    "idRef": "000c15bf-28fe-4fbb-9b69-35e6502721f1"
  },
  "user": [

  ],
  "userGroup": [

  ]
},
{
  "id": "95d732cc-6271-48fb-8579-064439a68d6e",
  "instanceId": 15177,
  "authEntityId": 15177,
  "displayName": "15177",
  "authEntityClass": -1909073334,
  "deployPending": "NONE",
  "instanceVersion": 0,
  "createTime": 1517966744411,
  "deployed": false,
  "isSeeded": true,
  "isStale": false,
  "lastUpdateTime": 1517966744411,
  "name": "softpc",
  "namespace": "scalablegroup:application",
  "provisioningState": "DEFINED",
  "qualifier": "application",
  "resourceVersion": 0,
  "targetIdList": [

  ],
  "type": "scalablegroup",
  "cfsChangeInfo": [

  ],
  "customProvisions": [

  ],
  "scalableGroupExternalHandle": "softpc",
  "scalableGroupType": "APPLICATION",
  "securityGroupTag": 0,
  "state": "ACTIVE",
  "vnAgnostic": true,
  "identitySource": {
    "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
    "instanceId": 10010,
    "authEntityId": 10010,
    "displayName": "10010",
    "authEntityClass": 1738953278,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "state": "INACTIVE",
    "type": "NBAR"
  },
  "indicativeNetworkIdentity": [
    {
      "id": "1723af0c-4e49-4cf4-8594-6b6bc1d27358",
      "instanceId": 17184,
      "authEntityId": 17184,
      "displayName": "17184",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "215",
      "protocol": "UDP",
      "upperPort": 0
    },
    {
      "id": "58980e72-b2b1-43c5-be68-4aabd2d4bfe7",
      "instanceId": 17183,
      "authEntityId": 17183,
      "displayName": "17183",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "215",
      "protocol": "TCP",
      "upperPort": 0
    }
  ],
  "networkApplications": [
    {
      "id": "95e0a2dc-1ceb-4e06-9ee4-977c667d4b2a",
      "instanceId": 16120,
      "authEntityId": 16120,
      "displayName": "16120",
      "authEntityClass": -217092956,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "appProtocol": "tcp/udp",
      "applicationGroup": "other",
      "applicationType": "DEFAULT",
      "categoryId": "6daa3dfd-3526-407a-88f0-97824b3f3a24",
      "createTime": 1517966744411,
      "encrypted": "false",
      "engineId": "3",
      "helpString": "Insignia Solutions",
      "ignoreConflict": false,
      "lastUpdateTime": 1517966744411,
      "longDescription": "SoftPC is  a software emulator of x86 hardware by Insignia that runs MS-DOS on UNIX workstations and Windows on MAC OS.",
      "name": "softpc",
      "nbarId": "1112",
      "p2pTechnology": "false",
      "popularity": 1,
      "rank": 65535,
      "references": "http://en.wikipedia.org/wiki/SoftPC",
      "selectorId": "215",
      "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
      "trafficClass": "BULK_DATA",
      "tunnel": "false"
    }
  ],
  "networkIdentity": [
    {
      "id": "03646451-4663-46f8-97c9-f84794983354",
      "instanceId": 17181,
      "authEntityId": 17181,
      "displayName": "17181",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "215",
      "protocol": "UDP",
      "upperPort": 0
    },
    {
      "id": "95a1fec3-0f81-49ff-ab94-e5e5998f29fd",
      "instanceId": 17182,
      "authEntityId": 17182,
      "displayName": "17182",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "215",
      "protocol": "TCP",
      "upperPort": 0
    }
  ],
  "parentScalableGroup": {
    "idRef": "000c15bf-28fe-4fbb-9b69-35e6502721f1"
  },
  "user": [

  ],
  "userGroup": [

  ]
},
{
  "id": "9619f024-9b3a-4853-b20b-5bcb00efc38f",
  "instanceId": 4806,
  "authEntityId": 4806,
  "displayName": "4806",
  "authEntityClass": -1909073334,
  "deployPending": "NONE",
  "instanceVersion": 0,
  "createTime": 1517966743530,
  "deployed": false,
  "isSeeded": true,
  "isStale": false,
  "lastUpdateTime": 1517966743530,
  "name": "mtp",
  "namespace": "scalablegroup:application",
  "provisioningState": "DEFINED",
  "qualifier": "application",
  "resourceVersion": 0,
  "targetIdList": [

  ],
  "type": "scalablegroup",
  "cfsChangeInfo": [

  ],
  "customProvisions": [

  ],
  "scalableGroupExternalHandle": "mtp",
  "scalableGroupType": "APPLICATION",
  "securityGroupTag": 0,
  "state": "ACTIVE",
  "vnAgnostic": true,
  "identitySource": {
    "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
    "instanceId": 10010,
    "authEntityId": 10010,
    "displayName": "10010",
    "authEntityClass": 1738953278,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "state": "INACTIVE",
    "type": "NBAR"
  },
  "indicativeNetworkIdentity": [
    {
      "id": "079347cc-dee3-4765-a811-53d67b322774",
      "instanceId": 14058,
      "authEntityId": 14058,
      "displayName": "14058",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipProtocolNumbers": "92",
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "protocol": "MTP",
      "upperPort": 0
    }
  ],
  "networkApplications": [
    {
      "id": "4e1d1f4d-9349-46a3-aa8a-49371a7e53a3",
      "instanceId": 11755,
      "authEntityId": 11755,
      "displayName": "11755",
      "authEntityClass": -217092956,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "appProtocol": "ip",
      "applicationGroup": "other",
      "applicationType": "DEFAULT",
      "categoryId": "6daa3dfd-3526-407a-88f0-97824b3f3a24",
      "createTime": 1517966743530,
      "encrypted": "unassigned",
      "engineId": "1",
      "helpString": "Multicast Transport Protocol",
      "ignoreConflict": false,
      "lastUpdateTime": 1517966743530,
      "longDescription": "Media Transfer Protocol is a set of custom extensions to the Picture Transfer Protocol (PTP). PTP is used for downloading photographs from digital cameras, whereas MTP adds support for the transfer of music files on digital audio players, media files on portable media players and personal information on personal digital assistants. Media Transfer Protocol (commonly referred to as MTP) is part of the \"Windows Media\" framework and thus closely related to Windows Media Player. Windows Vista has built-in support for MTP. Support for Media Transfer Protocol in Windows XP requires the installation of Windows Media Player 10 or higher. Mac and Linux systems have software packages to support it.",
      "name": "mtp",
      "nbarId": "846",
      "p2pTechnology": "unassigned",
      "popularity": 2,
      "rank": 65535,
      "references": "http://en.wikipedia.org/wiki/Media_Transfer_Protocol",
      "selectorId": "92",
      "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
      "trafficClass": "BULK_DATA",
      "tunnel": "unassigned"
    }
  ],
  "networkIdentity": [
    {
      "id": "19ce3ce7-86d1-4b5d-a0c2-f7b6b946605a",
      "instanceId": 14057,
      "authEntityId": 14057,
      "displayName": "14057",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipProtocolNumbers": "92",
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "protocol": "MTP",
      "upperPort": 0
    }
  ],
  "parentScalableGroup": {
    "idRef": "000c15bf-28fe-4fbb-9b69-35e6502721f1"
  },
  "user": [

  ],
  "userGroup": [

  ]
},
{
  "id": "962972c9-033e-470d-af87-4ab8b3dcc0db",
  "instanceId": 4433,
  "authEntityId": 4433,
  "displayName": "4433",
  "authEntityClass": -1909073334,
  "deployPending": "NONE",
  "instanceVersion": 0,
  "createTime": 1517966742642,
  "deployed": false,
  "isSeeded": true,
  "isStale": false,
  "lastUpdateTime": 1517966742642,
  "name": "exchange",
  "namespace": "scalablegroup:application",
  "provisioningState": "DEFINED",
  "qualifier": "application",
  "resourceVersion": 0,
  "targetIdList": [

  ],
  "type": "scalablegroup",
  "cfsChangeInfo": [

  ],
  "customProvisions": [

  ],
  "scalableGroupExternalHandle": "exchange",
  "scalableGroupType": "APPLICATION",
  "securityGroupTag": 0,
  "state": "ACTIVE",
  "vnAgnostic": true,
  "identitySource": {
    "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
    "instanceId": 10010,
    "authEntityId": 10010,
    "displayName": "10010",
    "authEntityClass": 1738953278,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "state": "INACTIVE",
    "type": "NBAR"
  },
  "indicativeNetworkIdentity": [

  ],
  "networkApplications": [
    {
      "id": "ba4325f3-345b-4e85-bda6-f4190912d114",
      "instanceId": 11384,
      "authEntityId": 11384,
      "displayName": "11384",
      "authEntityClass": -217092956,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "appProtocol": "tcp",
      "applicationGroup": "other",
      "applicationType": "DEFAULT",
      "categoryId": "27953c60-b2ff-4e1a-bc52-0187d8295250",
      "createTime": 1517966742642,
      "encrypted": "true",
      "engineId": "13",
      "helpString": "MS-Exchange",
      "ignoreConflict": false,
      "lastUpdateTime": 1517966742642,
      "longDescription": "Exchange is a protocol that allows users to synchronize and connect to their exchange server",
      "name": "exchange",
      "nbarId": "49",
      "p2pTechnology": "false",
      "popularity": 10,
      "rank": 65535,
      "references": "http://support.microsoft.com/kb/262986",
      "selectorId": "49",
      "subCategory": "b90786f4-0190-45b8-9924-21b3e9682590",
      "trafficClass": "BULK_DATA",
      "tunnel": "false"
    }
  ],
  "networkIdentity": [
    {
      "id": "97207470-32a1-44c8-8f75-e6680881a77a",
      "instanceId": 13091,
      "authEntityId": 13091,
      "displayName": "13091",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "80,135,443",
      "protocol": "TCP",
      "upperPort": 0
    }
  ],
  "parentScalableGroup": {
    "idRef": "8a7ca43a-32bd-40d2-871a-02220a165cb7"
  },
  "user": [

  ],
  "userGroup": [

  ]
},
{
  "id": "968eab2f-95b9-4ee8-aa2b-411181c7bf83",
  "instanceId": 15273,
  "authEntityId": 15273,
  "displayName": "15273",
  "authEntityClass": -1909073334,
  "deployPending": "NONE",
  "instanceVersion": 0,
  "createTime": 1517966744631,
  "deployed": false,
  "isSeeded": true,
  "isStale": false,
  "lastUpdateTime": 1517966744631,
  "name": "tesco",
  "namespace": "scalablegroup:application",
  "provisioningState": "DEFINED",
  "qualifier": "application",
  "resourceVersion": 0,
  "targetIdList": [

  ],
  "type": "scalablegroup",
  "cfsChangeInfo": [

  ],
  "customProvisions": [

  ],
  "scalableGroupExternalHandle": "tesco",
  "scalableGroupType": "APPLICATION",
  "securityGroupTag": 0,
  "state": "ACTIVE",
  "vnAgnostic": true,
  "identitySource": {
    "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
    "instanceId": 10010,
    "authEntityId": 10010,
    "displayName": "10010",
    "authEntityClass": 1738953278,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "state": "INACTIVE",
    "type": "NBAR"
  },
  "indicativeNetworkIdentity": [

  ],
  "networkApplications": [
    {
      "id": "fec12ebf-36a3-41b3-acad-bb47357de036",
      "instanceId": 16216,
      "authEntityId": 16216,
      "displayName": "16216",
      "authEntityClass": -217092956,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "appProtocol": "tcp/udp",
      "applicationGroup": "other",
      "applicationType": "DEFAULT",
      "categoryId": "878644b5-fe91-45b4-9204-31c84a73ba48",
      "createTime": 1517966744631,
      "encrypted": "false",
      "engineId": "13",
      "helpString": "Tesco.com - General E-commerce website.",
      "ignoreConflict": false,
      "lastUpdateTime": 1517966744631,
      "longDescription": "Tesco.com - General E-commerce website.",
      "name": "tesco",
      "nbarId": "1573",
      "p2pTechnology": "false",
      "popularity": 3,
      "rank": 65535,
      "references": "http://tesco.com",
      "selectorId": "644",
      "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
      "trafficClass": "TRANSACTIONAL_DATA",
      "tunnel": "false"
    }
  ],
  "networkIdentity": [
    {
      "id": "92f64ea4-86dd-46f0-ae1f-2a48816ec0ba",
      "instanceId": 17451,
      "authEntityId": 17451,
      "displayName": "17451",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "53,5353",
      "protocol": "UDP",
      "upperPort": 0
    },
    {
      "id": "cec14371-1a47-4598-87a4-59f81892f183",
      "instanceId": 17452,
      "authEntityId": 17452,
      "displayName": "17452",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "53,80,443,5353",
      "protocol": "TCP",
      "upperPort": 0
    }
  ],
  "parentScalableGroup": {
    "idRef": "28e08de4-d7b2-4521-84ce-bc1e5ee0b9cc"
  },
  "user": [

  ],
  "userGroup": [

  ]
},
{
  "id": "96922bea-b20d-4d28-817b-36c46453e433",
  "instanceId": 4487,
  "authEntityId": 4487,
  "displayName": "4487",
  "authEntityClass": -1909073334,
  "deployPending": "NONE",
  "instanceVersion": 0,
  "createTime": 1517966742793,
  "deployed": false,
  "isSeeded": true,
  "isStale": false,
  "lastUpdateTime": 1517966742793,
  "name": "gmtp",
  "namespace": "scalablegroup:application",
  "provisioningState": "DEFINED",
  "qualifier": "application",
  "resourceVersion": 0,
  "targetIdList": [

  ],
  "type": "scalablegroup",
  "cfsChangeInfo": [

  ],
  "customProvisions": [

  ],
  "scalableGroupExternalHandle": "gmtp",
  "scalableGroupType": "APPLICATION",
  "securityGroupTag": 0,
  "state": "ACTIVE",
  "vnAgnostic": true,
  "identitySource": {
    "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
    "instanceId": 10010,
    "authEntityId": 10010,
    "displayName": "10010",
    "authEntityClass": 1738953278,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "state": "INACTIVE",
    "type": "NBAR"
  },
  "indicativeNetworkIdentity": [
    {
      "id": "13bf1eaa-fb47-427c-9396-2e56d5c38bde",
      "instanceId": 13216,
      "authEntityId": 13216,
      "displayName": "13216",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipProtocolNumbers": "100",
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "protocol": "GMTP",
      "upperPort": 0
    }
  ],
  "networkApplications": [
    {
      "id": "7ed9aa7d-24d0-48b9-a1f4-7ab0e3904d8f",
      "instanceId": 11438,
      "authEntityId": 11438,
      "displayName": "11438",
      "authEntityClass": -217092956,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "appProtocol": "ip",
      "applicationGroup": "other",
      "applicationType": "DEFAULT",
      "categoryId": "6daa3dfd-3526-407a-88f0-97824b3f3a24",
      "createTime": 1517966742793,
      "encrypted": "unassigned",
      "engineId": "1",
      "helpString": "graphical Media Transfer Protocol ",
      "ignoreConflict": false,
      "lastUpdateTime": 1517966742793,
      "longDescription": "Graphical Media Transfer Protocol (gMTP) is a lightweight graphical MTP media client for UNIX and UNIX-like systems. It supports all MTP-based devices including MP3 players, Media Players, Tablets and Mobile Phones. gMTP Is IP protocol number 100.",
      "name": "gmtp",
      "nbarId": "854",
      "p2pTechnology": "unassigned",
      "popularity": 2,
      "rank": 65535,
      "references": "http://gmtp.sourceforge.net",
      "selectorId": "100",
      "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
      "trafficClass": "BULK_DATA",
      "tunnel": "unassigned"
    }
  ],
  "networkIdentity": [
    {
      "id": "ad86d91c-7f9e-498b-b539-c0723b468912",
      "instanceId": 13215,
      "authEntityId": 13215,
      "displayName": "13215",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipProtocolNumbers": "100",
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "protocol": "GMTP",
      "upperPort": 0
    }
  ],
  "parentScalableGroup": {
    "idRef": "000c15bf-28fe-4fbb-9b69-35e6502721f1"
  },
  "user": [

  ],
  "userGroup": [

  ]
},
{
  "id": "96bc07dc-41d9-448f-9f44-ef632967527e",
  "instanceId": 15232,
  "authEntityId": 15232,
  "displayName": "15232",
  "authEntityClass": -1909073334,
  "deployPending": "NONE",
  "instanceVersion": 0,
  "createTime": 1517966744545,
  "deployed": false,
  "isSeeded": true,
  "isStale": false,
  "lastUpdateTime": 1517966744545,
  "name": "sunrpc",
  "namespace": "scalablegroup:application",
  "provisioningState": "DEFINED",
  "qualifier": "application",
  "resourceVersion": 0,
  "targetIdList": [

  ],
  "type": "scalablegroup",
  "cfsChangeInfo": [

  ],
  "customProvisions": [

  ],
  "scalableGroupExternalHandle": "sunrpc",
  "scalableGroupType": "APPLICATION",
  "securityGroupTag": 0,
  "state": "ACTIVE",
  "vnAgnostic": true,
  "identitySource": {
    "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
    "instanceId": 10010,
    "authEntityId": 10010,
    "displayName": "10010",
    "authEntityClass": 1738953278,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "state": "INACTIVE",
    "type": "NBAR"
  },
  "indicativeNetworkIdentity": [
    {
      "id": "994a15f1-08d5-4996-8616-65bdba96b257",
      "instanceId": 17330,
      "authEntityId": 17330,
      "displayName": "17330",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "111,371,2049",
      "protocol": "UDP",
      "upperPort": 0
    },
    {
      "id": "d2ec9578-7dab-4828-b971-3dd9d042b1d5",
      "instanceId": 17331,
      "authEntityId": 17331,
      "displayName": "17331",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "111,371,2049",
      "protocol": "TCP",
      "upperPort": 0
    }
  ],
  "networkApplications": [
    {
      "id": "40cca829-81bc-461d-81a4-ac458752f7bf",
      "instanceId": 16175,
      "authEntityId": 16175,
      "displayName": "16175",
      "authEntityClass": -217092956,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "appProtocol": "tcp/udp",
      "applicationGroup": "other",
      "applicationType": "DEFAULT",
      "categoryId": "58aa61dc-dd95-4a6d-9c7c-0ebf16da6dde",
      "createTime": 1517966744545,
      "encrypted": "false",
      "engineId": "3",
      "helpString": "SUN Remote Procedure Call",
      "ignoreConflict": false,
      "lastUpdateTime": 1517966744545,
      "longDescription": "Sun Microsystems Remote Procedure Call (SunRPC) is a client-server protocol that allows users to call procedures remotely, which means the procedure is actually done at the server and not at the local user. The server holds a port mapper that listens to queries, usually on port 111.",
      "name": "sunrpc",
      "nbarId": "54",
      "p2pTechnology": "false",
      "popularity": 9,
      "rank": 65535,
      "references": "http://www.ietf.org/rfc/rfc1057.txt",
      "selectorId": "111",
      "subCategory": "b90786f4-0190-45b8-9924-21b3e9682590",
      "trafficClass": "TRANSACTIONAL_DATA",
      "tunnel": "false"
    }
  ],
  "networkIdentity": [
    {
      "id": "62b712bb-32f0-4956-a07a-cea109aa0b4c",
      "instanceId": 17328,
      "authEntityId": 17328,
      "displayName": "17328",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "111,371,2049",
      "protocol": "UDP",
      "upperPort": 0
    },
    {
      "id": "4568181a-6b00-41f4-a5f7-e82b050ac699",
      "instanceId": 17329,
      "authEntityId": 17329,
      "displayName": "17329",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "111,371,2049",
      "protocol": "TCP",
      "upperPort": 0
    }
  ],
  "parentScalableGroup": {
    "idRef": "cff11752-fafb-4e22-9e6f-11f44f59a9a3"
  },
  "user": [

  ],
  "userGroup": [

  ]
},
{
  "id": "96c79c0f-0007-4913-8191-035c2af395ea",
  "instanceId": 15318,
  "authEntityId": 15318,
  "displayName": "15318",
  "authEntityClass": -1909073334,
  "deployPending": "NONE",
  "instanceVersion": 0,
  "createTime": 1517966744729,
  "deployed": false,
  "isSeeded": true,
  "isStale": false,
  "lastUpdateTime": 1517966744729,
  "name": "ulistproc",
  "namespace": "scalablegroup:application",
  "provisioningState": "DEFINED",
  "qualifier": "application",
  "resourceVersion": 0,
  "targetIdList": [

  ],
  "type": "scalablegroup",
  "cfsChangeInfo": [

  ],
  "customProvisions": [

  ],
  "scalableGroupExternalHandle": "ulistproc",
  "scalableGroupType": "APPLICATION",
  "securityGroupTag": 0,
  "state": "ACTIVE",
  "vnAgnostic": true,
  "identitySource": {
    "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
    "instanceId": 10010,
    "authEntityId": 10010,
    "displayName": "10010",
    "authEntityClass": 1738953278,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "state": "INACTIVE",
    "type": "NBAR"
  },
  "indicativeNetworkIdentity": [
    {
      "id": "3c777a94-85e5-4b09-8e33-81a1f5d36892",
      "instanceId": 17573,
      "authEntityId": 17573,
      "displayName": "17573",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "372",
      "protocol": "TCP",
      "upperPort": 0
    },
    {
      "id": "85f15237-29ff-4d7f-b5e3-627706730662",
      "instanceId": 17574,
      "authEntityId": 17574,
      "displayName": "17574",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "372",
      "protocol": "UDP",
      "upperPort": 0
    }
  ],
  "networkApplications": [
    {
      "id": "09bc6bda-b3ff-4ed5-b033-81c4a070f3c5",
      "instanceId": 16261,
      "authEntityId": 16261,
      "displayName": "16261",
      "authEntityClass": -217092956,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "appProtocol": "tcp/udp",
      "applicationGroup": "other",
      "applicationType": "DEFAULT",
      "categoryId": "27953c60-b2ff-4e1a-bc52-0187d8295250",
      "createTime": 1517966744729,
      "encrypted": "false",
      "engineId": "3",
      "helpString": "List Processor",
      "ignoreConflict": false,
      "lastUpdateTime": 1517966744729,
      "longDescription": "Registered with IANA on port 372 TCP/UDP",
      "name": "ulistproc",
      "nbarId": "288",
      "p2pTechnology": "false",
      "popularity": 1,
      "rank": 65535,
      "references": "http://www.iana.org/assignments/service-names-port-numbers/service-names-port-numbers.xml",
      "selectorId": "372",
      "subCategory": "b90786f4-0190-45b8-9924-21b3e9682590",
      "trafficClass": "BULK_DATA",
      "tunnel": "false"
    }
  ],
  "networkIdentity": [
    {
      "id": "6e170340-99f5-4fff-a9f8-23d216256516",
      "instanceId": 17571,
      "authEntityId": 17571,
      "displayName": "17571",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "372",
      "protocol": "UDP",
      "upperPort": 0
    },
    {
      "id": "0a7e6cd1-11fe-4755-9394-052b9a7b7bf0",
      "instanceId": 17572,
      "authEntityId": 17572,
      "displayName": "17572",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "372",
      "protocol": "TCP",
      "upperPort": 0
    }
  ],
  "parentScalableGroup": {
    "idRef": "8a7ca43a-32bd-40d2-871a-02220a165cb7"
  },
  "user": [

  ],
  "userGroup": [

  ]
},


{
  "id": "974abc15-1799-401f-9850-2efcf8b9f6ac",
  "instanceId": 15133,
  "authEntityId": 15133,
  "displayName": "15133",
  "authEntityClass": -1909073334,
  "deployPending": "NONE",
  "instanceVersion": 0,
  "createTime": 1517966744309,
  "deployed": false,
  "isSeeded": true,
  "isStale": false,
  "lastUpdateTime": 1517966744309,
  "name": "share",
  "namespace": "scalablegroup:application",
  "provisioningState": "DEFINED",
  "qualifier": "application",
  "resourceVersion": 0,
  "targetIdList": [

  ],
  "type": "scalablegroup",
  "cfsChangeInfo": [

  ],
  "customProvisions": [

  ],
  "scalableGroupExternalHandle": "share",
  "scalableGroupType": "APPLICATION",
  "securityGroupTag": 0,
  "state": "ACTIVE",
  "vnAgnostic": true,
  "identitySource": {
    "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
    "instanceId": 10010,
    "authEntityId": 10010,
    "displayName": "10010",
    "authEntityClass": 1738953278,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "state": "INACTIVE",
    "type": "NBAR"
  },
  "indicativeNetworkIdentity": [

  ],
  "networkApplications": [
    {
      "id": "ec85f660-c252-4e53-a830-5efda891f675",
      "instanceId": 16076,
      "authEntityId": 16076,
      "displayName": "16076",
      "authEntityClass": -217092956,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "applicationGroup": "other",
      "applicationType": "DEFAULT",
      "categoryId": "b33b2761-d981-4734-810a-a6a1c18ab352",
      "createTime": 1517966744309,
      "encrypted": "false",
      "engineId": "13",
      "helpString": "Share Protocol, P2P File-Sharing Protocol",
      "ignoreConflict": false,
      "lastUpdateTime": 1517966744309,
      "longDescription": "Share is a closed-source P2P application being developed in Japan by an anonymous author. It was developed as the successor of Winny and focuses on higher security. Share uses encrypted caches, file names and IP addresses, and is based on node-organized architecture.",
      "name": "share",
      "nbarId": "1515",
      "p2pTechnology": "true",
      "popularity": 8,
      "rank": 65535,
      "references": "http://en.wikipedia.org/wiki/Share_%28P2P%29",
      "selectorId": "576",
      "subCategory": "79fe7198-9ce3-4c01-9b58-45a8df3d9513",
      "trafficClass": "BULK_DATA",
      "tunnel": "false"
    }
  ],
  "networkIdentity": [

  ],
  "parentScalableGroup": {
    "idRef": "7a1a3bbb-dc84-4a35-82a4-7e650c4c4761"
  },
  "user": [

  ],
  "userGroup": [

  ]
},





{
  "id": "974df15a-839d-4f91-8544-10a08bf05f8b",
  "instanceId": 15042,
  "authEntityId": 15042,
  "displayName": "15042",
  "authEntityClass": -1909073334,
  "deployPending": "NONE",
  "instanceVersion": 0,
  "createTime": 1517966744094,
  "deployed": false,
  "isSeeded": true,
  "isStale": false,
  "lastUpdateTime": 1517966744094,
  "name": "repscmd",
  "namespace": "scalablegroup:application",
  "provisioningState": "DEFINED",
  "qualifier": "application",
  "resourceVersion": 0,
  "targetIdList": [

  ],
  "type": "scalablegroup",
  "cfsChangeInfo": [

  ],
  "customProvisions": [

  ],
  "scalableGroupExternalHandle": "repscmd",
  "scalableGroupType": "APPLICATION",
  "securityGroupTag": 0,
  "state": "ACTIVE",
  "vnAgnostic": true,
  "identitySource": {
    "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
    "instanceId": 10010,
    "authEntityId": 10010,
    "displayName": "10010",
    "authEntityClass": 1738953278,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "state": "INACTIVE",
    "type": "NBAR"
  },
  "indicativeNetworkIdentity": [
    {
      "id": "0b8344ed-a172-4495-b562-1402e6474ecf",
      "instanceId": 14757,
      "authEntityId": 14757,
      "displayName": "14757",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "653",
      "protocol": "TCP",
      "upperPort": 0
    },
    {
      "id": "44761b5e-a36a-469c-b8f6-5bbffc990228",
      "instanceId": 14758,
      "authEntityId": 14758,
      "displayName": "14758",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "653",
      "protocol": "UDP",
      "upperPort": 0
    }
  ],
  "networkApplications": [
    {
      "id": "a905cc79-ab26-4d3e-b0d6-ac0e6d1f208b",
      "instanceId": 11981,
      "authEntityId": 11981,
      "displayName": "11981",
      "authEntityClass": -217092956,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "appProtocol": "tcp/udp",
      "applicationGroup": "other",
      "applicationType": "DEFAULT",
      "categoryId": "6daa3dfd-3526-407a-88f0-97824b3f3a24",
      "createTime": 1517966744094,
      "encrypted": "false",
      "engineId": "3",
      "helpString": "repscmd",
      "ignoreConflict": false,
      "lastUpdateTime": 1517966744094,
      "longDescription": "Repscmd is a protocol used by the SupportSoft remote support solution to enable support representatives to connect to a remote PC and provide assitance",
      "name": "repscmd",
      "nbarId": "562",
      "p2pTechnology": "false",
      "popularity": 1,
      "rank": 65535,
      "references": "http://www.consona.com/supportsoft/",
      "selectorId": "653",
      "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
      "trafficClass": "BULK_DATA",
      "tunnel": "false"
    }
  ],
  "networkIdentity": [
    {
      "id": "486d74da-dbe0-48b1-b343-3de68510278e",
      "instanceId": 14755,
      "authEntityId": 14755,
      "displayName": "14755",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "653",
      "protocol": "UDP",
      "upperPort": 0
    },
    {
      "id": "f7ac7b98-c026-456c-a531-177c6ab27606",
      "instanceId": 14756,
      "authEntityId": 14756,
      "displayName": "14756",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "653",
      "protocol": "TCP",
      "upperPort": 0
    }
  ],
  "parentScalableGroup": {
    "idRef": "000c15bf-28fe-4fbb-9b69-35e6502721f1"
  },
  "user": [

  ],
  "userGroup": [

  ]
},
{
  "id": "97d07951-1543-4fde-814e-86237ceae239",
  "instanceId": 15311,
  "authEntityId": 15311,
  "displayName": "15311",
  "authEntityClass": -1909073334,
  "deployPending": "NONE",
  "instanceVersion": 0,
  "createTime": 1517966744717,
  "deployed": false,
  "isSeeded": true,
  "isStale": false,
  "lastUpdateTime": 1517966744717,
  "name": "twitch-tv",
  "namespace": "scalablegroup:application",
  "provisioningState": "DEFINED",
  "qualifier": "application",
  "resourceVersion": 0,
  "targetIdList": [

  ],
  "type": "scalablegroup",
  "cfsChangeInfo": [

  ],
  "customProvisions": [

  ],
  "scalableGroupExternalHandle": "twitch-tv",
  "scalableGroupType": "APPLICATION",
  "securityGroupTag": 0,
  "state": "ACTIVE",
  "vnAgnostic": true,
  "identitySource": {
    "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
    "instanceId": 10010,
    "authEntityId": 10010,
    "displayName": "10010",
    "authEntityClass": 1738953278,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "state": "INACTIVE",
    "type": "NBAR"
  },
  "indicativeNetworkIdentity": [

  ],
  "networkApplications": [
    {
      "id": "8365fc19-5f70-4547-841b-2843c55c7e26",
      "instanceId": 16254,
      "authEntityId": 16254,
      "displayName": "16254",
      "authEntityClass": -217092956,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "appProtocol": "tcp/udp",
      "applicationGroup": "other",
      "applicationType": "DEFAULT",
      "categoryId": "878644b5-fe91-45b4-9204-31c84a73ba48",
      "createTime": 1517966744717,
      "encrypted": "false",
      "engineId": "13",
      "helpString": "TwitchTV - Justin.tv gaming specific livestreaming platform.",
      "ignoreConflict": false,
      "lastUpdateTime": 1517966744717,
      "longDescription": "TwitchTV - Justin.tv gaming specific livestreaming platform.",
      "name": "twitch-tv",
      "nbarId": "1577",
      "p2pTechnology": "false",
      "popularity": 3,
      "rank": 65535,
      "references": "http://twitch.tv",
      "selectorId": "648",
      "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
      "trafficClass": "TRANSACTIONAL_DATA",
      "tunnel": "false"
    }
  ],
  "networkIdentity": [
    {
      "id": "3f658190-5025-4b8b-a074-af21a9fbb6ae",
      "instanceId": 17552,
      "authEntityId": 17552,
      "displayName": "17552",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "53,80,443,5353",
      "protocol": "TCP",
      "upperPort": 0
    },
    {
      "id": "103c2cf5-077c-4d8e-8710-6a6230553901",
      "instanceId": 17551,
      "authEntityId": 17551,
      "displayName": "17551",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "53,5353",
      "protocol": "UDP",
      "upperPort": 0
    }
  ],
  "parentScalableGroup": {
    "idRef": "28e08de4-d7b2-4521-84ce-bc1e5ee0b9cc"
  },
  "user": [

  ],
  "userGroup": [

  ]
},
{
  "id": "97e35ab2-5ad1-4704-bdae-4d5953dc94a8",
  "instanceId": 4354,
  "authEntityId": 4354,
  "displayName": "4354",
  "authEntityClass": -1909073334,
  "deployPending": "NONE",
  "instanceVersion": 0,
  "createTime": 1517966742381,
  "deployed": false,
  "isSeeded": true,
  "isStale": false,
  "lastUpdateTime": 1517966742381,
  "name": "dhcp",
  "namespace": "scalablegroup:application",
  "provisioningState": "DEFINED",
  "qualifier": "application",
  "resourceVersion": 0,
  "targetIdList": [

  ],
  "type": "scalablegroup",
  "cfsChangeInfo": [

  ],
  "customProvisions": [

  ],
  "scalableGroupExternalHandle": "dhcp",
  "scalableGroupType": "APPLICATION",
  "securityGroupTag": 0,
  "state": "ACTIVE",
  "vnAgnostic": true,
  "identitySource": {
    "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
    "instanceId": 10010,
    "authEntityId": 10010,
    "displayName": "10010",
    "authEntityClass": 1738953278,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "state": "INACTIVE",
    "type": "NBAR"
  },
  "indicativeNetworkIdentity": [
    {
      "id": "c9b1f031-ce2e-4a49-8b7e-056ed5dd8115",
      "instanceId": 12843,
      "authEntityId": 12843,
      "displayName": "12843",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "67,68",
      "protocol": "UDP",
      "upperPort": 0
    }
  ],
  "networkApplications": [
    {
      "id": "92a49132-d1a3-4840-8ce7-65c04110e2a2",
      "instanceId": 11305,
      "authEntityId": 11305,
      "displayName": "11305",
      "authEntityClass": -217092956,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "appProtocol": "udp",
      "applicationGroup": "other",
      "applicationType": "DEFAULT",
      "categoryId": "1fc17362-9334-4c7b-b752-5afdbb574017",
      "createTime": 1517966742381,
      "encrypted": "false",
      "engineId": "13",
      "helpString": "Dynamic Host Configuration Protocol",
      "ignoreConflict": false,
      "lastUpdateTime": 1517966742381,
      "longDescription": "Dynamic Host Configuration Protocol (DHCP) provides a framework for passing configuration information to hosts on a TCP/IP network. The information given by designated DHCP servers include: IP address, subnet mask and default gateway.  A DHCP server usually listens on UDP port 67 and DHCP client usually listens on UDP 68.",
      "name": "dhcp",
      "nbarId": "13",
      "p2pTechnology": "false",
      "popularity": 8,
      "rank": 65535,
      "references": "http://www.ietf.org/rfc/rfc2131.txt",
      "selectorId": "13",
      "subCategory": "f3c0f761-d894-4112-ab89-b90fc84fd660",
      "trafficClass": "OPS_ADMIN_MGMT",
      "tunnel": "false"
    }
  ],
  "networkIdentity": [
    {
      "id": "acda4ff0-ca2c-4ea9-9a13-906f607232d0",
      "instanceId": 12842,
      "authEntityId": 12842,
      "displayName": "12842",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "67,68",
      "protocol": "UDP",
      "upperPort": 0
    }
  ],
  "parentScalableGroup": {
    "idRef": "5fcd74d4-8726-47f7-a088-c457863da7ef"
  },
  "user": [

  ],
  "userGroup": [

  ]
},
{
  "id": "982244d3-9ed6-4310-b9ee-6067e98b8c52",
  "instanceId": 15085,
  "authEntityId": 15085,
  "displayName": "15085",
  "authEntityClass": -1909073334,
  "deployPending": "NONE",
  "instanceVersion": 0,
  "createTime": 1517966744199,
  "deployed": false,
  "isSeeded": true,
  "isStale": false,
  "lastUpdateTime": 1517966744199,
  "name": "s-net",
  "namespace": "scalablegroup:application",
  "provisioningState": "DEFINED",
  "qualifier": "application",
  "resourceVersion": 0,
  "targetIdList": [

  ],
  "type": "scalablegroup",
  "cfsChangeInfo": [

  ],
  "customProvisions": [

  ],
  "scalableGroupExternalHandle": "s-net",
  "scalableGroupType": "APPLICATION",
  "securityGroupTag": 0,
  "state": "ACTIVE",
  "vnAgnostic": true,
  "identitySource": {
    "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
    "instanceId": 10010,
    "authEntityId": 10010,
    "displayName": "10010",
    "authEntityClass": 1738953278,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "state": "INACTIVE",
    "type": "NBAR"
  },
  "indicativeNetworkIdentity": [
    {
      "id": "660da711-5c66-4257-932f-0f50b3e7ad26",
      "instanceId": 14892,
      "authEntityId": 14892,
      "displayName": "14892",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "166",
      "protocol": "TCP",
      "upperPort": 0
    },
    {
      "id": "dc7dda09-ff57-4f97-a58b-a434772c56ba",
      "instanceId": 14893,
      "authEntityId": 14893,
      "displayName": "14893",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "166",
      "protocol": "UDP",
      "upperPort": 0
    }
  ],
  "networkApplications": [
    {
      "id": "3ae5e1a2-63a0-43b7-8358-6222ed2123d5",
      "instanceId": 16028,
      "authEntityId": 16028,
      "displayName": "16028",
      "authEntityClass": -217092956,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "appProtocol": "tcp/udp",
      "applicationGroup": "other",
      "applicationType": "DEFAULT",
      "categoryId": "6daa3dfd-3526-407a-88f0-97824b3f3a24",
      "createTime": 1517966744199,
      "encrypted": "false",
      "engineId": "3",
      "helpString": "Sirius Systems",
      "ignoreConflict": false,
      "lastUpdateTime": 1517966744199,
      "longDescription": "Sirius Systems Software is a software for business needs. It provides business solutions for customers along with mobile devices support, connecting offices together, and ceating new customer database. It also provides remote monitoring and and email protection service.",
      "name": "s-net",
      "nbarId": "1011",
      "p2pTechnology": "false",
      "popularity": 1,
      "rank": 65535,
      "references": "http://www.siriussystems.net/",
      "selectorId": "166",
      "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
      "trafficClass": "BULK_DATA",
      "tunnel": "false"
    }
  ],
  "networkIdentity": [
    {
      "id": "2834f396-44c7-404c-a8d0-e6a0023d980f",
      "instanceId": 14890,
      "authEntityId": 14890,
      "displayName": "14890",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "166",
      "protocol": "TCP",
      "upperPort": 0
    },
    {
      "id": "811d0e92-24c8-49a9-8859-3d6334b18105",
      "instanceId": 14891,
      "authEntityId": 14891,
      "displayName": "14891",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "166",
      "protocol": "UDP",
      "upperPort": 0
    }
  ],
  "parentScalableGroup": {
    "idRef": "000c15bf-28fe-4fbb-9b69-35e6502721f1"
  },
  "user": [

  ],
  "userGroup": [

  ]
},
{
  "id": "98287f78-aae8-4722-8dd1-a8539ce88de0",
  "instanceId": 4544,
  "authEntityId": 4544,
  "displayName": "4544",
  "authEntityClass": -1909073334,
  "deployPending": "NONE",
  "instanceVersion": 0,
  "createTime": 1517966742947,
  "deployed": false,
  "isSeeded": true,
  "isStale": false,
  "lastUpdateTime": 1517966742947,
  "name": "hmp",
  "namespace": "scalablegroup:application",
  "provisioningState": "DEFINED",
  "qualifier": "application",
  "resourceVersion": 0,
  "targetIdList": [

  ],
  "type": "scalablegroup",
  "cfsChangeInfo": [

  ],
  "customProvisions": [

  ],
  "scalableGroupExternalHandle": "hmp",
  "scalableGroupType": "APPLICATION",
  "securityGroupTag": 0,
  "state": "ACTIVE",
  "vnAgnostic": true,
  "identitySource": {
    "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
    "instanceId": 10010,
    "authEntityId": 10010,
    "displayName": "10010",
    "authEntityClass": 1738953278,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "state": "INACTIVE",
    "type": "NBAR"
  },
  "indicativeNetworkIdentity": [
    {
      "id": "7bc831d2-2bec-49d2-9209-0cba7674e9ba",
      "instanceId": 13352,
      "authEntityId": 13352,
      "displayName": "13352",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipProtocolNumbers": "20",
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "protocol": "HMP",
      "upperPort": 0
    }
  ],
  "networkApplications": [
    {
      "id": "9f5b5233-d14b-47cb-b7ff-5e058efbcc69",
      "instanceId": 11495,
      "authEntityId": 11495,
      "displayName": "11495",
      "authEntityClass": -217092956,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "appProtocol": "ip",
      "applicationGroup": "other",
      "applicationType": "DEFAULT",
      "categoryId": "6daa3dfd-3526-407a-88f0-97824b3f3a24",
      "createTime": 1517966742947,
      "encrypted": "unassigned",
      "engineId": "1",
      "helpString": "Host Monitoring",
      "ignoreConflict": false,
      "lastUpdateTime": 1517966742947,
      "longDescription": "The Host Monitoring Protocol (HMP) is a connection less transport protocol. It was designed to facilitate certain simple interactions between two internet entities, one of which may be considered to be monitoring the other. It is used to collect information from Internet Gateways and TACs, and from hosts in various networks.",
      "name": "hmp",
      "nbarId": "774",
      "p2pTechnology": "unassigned",
      "popularity": 2,
      "rank": 65535,
      "references": "http://tools.ietf.org/html/rfc869",
      "selectorId": "20",
      "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
      "trafficClass": "BULK_DATA",
      "tunnel": "unassigned"
    }
  ],
  "networkIdentity": [
    {
      "id": "7cb2bfab-9b04-4dd3-b01b-5491a68385d7",
      "instanceId": 13351,
      "authEntityId": 13351,
      "displayName": "13351",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipProtocolNumbers": "20",
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "protocol": "HMP",
      "upperPort": 0
    }
  ],
  "parentScalableGroup": {
    "idRef": "000c15bf-28fe-4fbb-9b69-35e6502721f1"
  },
  "user": [

  ],
  "userGroup": [

  ]
},
{
  "id": "983cb744-e7cc-4300-aa11-112827f9513b",
  "instanceId": 15033,
  "authEntityId": 15033,
  "displayName": "15033",
  "authEntityClass": -1909073334,
  "deployPending": "NONE",
  "instanceVersion": 0,
  "createTime": 1517966744073,
  "deployed": false,
  "isSeeded": true,
  "isStale": false,
  "lastUpdateTime": 1517966744073,
  "name": "rdt",
  "namespace": "scalablegroup:application",
  "provisioningState": "DEFINED",
  "qualifier": "application",
  "resourceVersion": 0,
  "targetIdList": [

  ],
  "type": "scalablegroup",
  "cfsChangeInfo": [

  ],
  "customProvisions": [

  ],
  "scalableGroupExternalHandle": "rdt",
  "scalableGroupType": "APPLICATION",
  "securityGroupTag": 0,
  "state": "ACTIVE",
  "vnAgnostic": true,
  "identitySource": {
    "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
    "instanceId": 10010,
    "authEntityId": 10010,
    "displayName": "10010",
    "authEntityClass": 1738953278,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "state": "INACTIVE",
    "type": "NBAR"
  },
  "indicativeNetworkIdentity": [
    {
      "id": "b02104b1-f412-47f0-960f-48ec06225ab2",
      "instanceId": 14728,
      "authEntityId": 14728,
      "displayName": "14728",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "6970",
      "protocol": "TCP",
      "upperPort": 0
    }
  ],
  "networkApplications": [
    {
      "id": "d44c81f2-4a9b-4ab8-a80a-7e8e76dd912c",
      "instanceId": 11972,
      "authEntityId": 11972,
      "displayName": "11972",
      "authEntityClass": -217092956,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "appProtocol": "tcp",
      "applicationGroup": "other",
      "applicationType": "DEFAULT",
      "categoryId": "a09ef1b3-ba26-4372-aee8-309d50697e76",
      "createTime": 1517966744073,
      "encrypted": "false",
      "engineId": "3",
      "helpString": "Real Data Transport",
      "ignoreConflict": false,
      "lastUpdateTime": 1517966744073,
      "longDescription": "Real Data Transport (RDT) is a proprietary transport protocol for the actual audio/video data. It is commonly used in companion with a control protocol for streaming media like the IETF's based Real Time Streaming Protocol (RTSP). RDT is now included as part of the Helix Community project.",
      "name": "rdt",
      "nbarId": "1363",
      "p2pTechnology": "false",
      "popularity": 1,
      "rank": 65535,
      "references": "https://helixcommunity.org/viewcvs/server/protocol/transport/rdt/",
      "selectorId": "6970",
      "subCategory": "85a797c0-aef8-4284-91a7-677f33e29abb",
      "trafficClass": "MULTIMEDIA_STREAMING",
      "tunnel": "false"
    }
  ],
  "networkIdentity": [
    {
      "id": "65116b28-89d3-4941-bf00-8436f7c392f4",
      "instanceId": 14727,
      "authEntityId": 14727,
      "displayName": "14727",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "6970",
      "protocol": "TCP",
      "upperPort": 0
    }
  ],
  "parentScalableGroup": {
    "idRef": "fd6264c9-c16a-4311-a335-a3db039256f3"
  },
  "user": [

  ],
  "userGroup": [

  ]
},
{
  "id": "987f62e7-3c8e-4c4f-9329-6b7f96baa118",
  "instanceId": 4584,
  "authEntityId": 4584,
  "displayName": "4584",
  "authEntityClass": -1909073334,
  "deployPending": "NONE",
  "instanceVersion": 0,
  "createTime": 1517966743052,
  "deployed": false,
  "isSeeded": true,
  "isStale": false,
  "lastUpdateTime": 1517966743052,
  "name": "icq-filetransfer",
  "namespace": "scalablegroup:application",
  "provisioningState": "DEFINED",
  "qualifier": "application",
  "resourceVersion": 0,
  "targetIdList": [

  ],
  "type": "scalablegroup",
  "cfsChangeInfo": [

  ],
  "customProvisions": [

  ],
  "scalableGroupExternalHandle": "icq-filetransfer",
  "scalableGroupType": "APPLICATION",
  "securityGroupTag": 0,
  "state": "ACTIVE",
  "vnAgnostic": true,
  "identitySource": {
    "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
    "instanceId": 10010,
    "authEntityId": 10010,
    "displayName": "10010",
    "authEntityClass": 1738953278,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "state": "INACTIVE",
    "type": "NBAR"
  },
  "indicativeNetworkIdentity": [

  ],
  "networkApplications": [
    {
      "id": "d31a0ffd-da2c-4da8-abe3-d1e6a8f4c725",
      "instanceId": 11534,
      "authEntityId": 11534,
      "displayName": "11534",
      "authEntityClass": -217092956,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "applicationGroup": "other",
      "applicationType": "DEFAULT",
      "categoryId": "6daa3dfd-3526-407a-88f0-97824b3f3a24",
      "createTime": 1517966743052,
      "encrypted": "unassigned",
      "engineId": "13",
      "helpString": "DEPRECATED, Please refer to oscar-filetransfer",
      "ignoreConflict": false,
      "lastUpdateTime": 1517966743052,
      "longDescription": "ICQ File Transfer is a file transfer feature in client ICQ (I Seek You). It is based on the Open System for CommunicAtion in Realtime (OSCAR) File Transfer protocol.",
      "name": "icq-filetransfer",
      "nbarId": "1204",
      "p2pTechnology": "unassigned",
      "popularity": 0,
      "rank": 65535,
      "references": "http://www.icq.com/support/icq_7/file_transfer/en",
      "selectorId": "311",
      "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
      "trafficClass": "BULK_DATA",
      "tunnel": "unassigned"
    }
  ],
  "networkIdentity": [

  ],
  "parentScalableGroup": {
    "idRef": "000c15bf-28fe-4fbb-9b69-35e6502721f1"
  },
  "user": [

  ],
  "userGroup": [

  ]
},
{
  "id": "98c6c031-3c35-4db6-a447-53023895b0a2",
  "instanceId": 4451,
  "authEntityId": 4451,
  "displayName": "4451",
  "authEntityClass": -1909073334,
  "deployPending": "NONE",
  "instanceVersion": 0,
  "createTime": 1517966742705,
  "deployed": false,
  "isSeeded": true,
  "isStale": false,
  "lastUpdateTime": 1517966742705,
  "name": "fire",
  "namespace": "scalablegroup:application",
  "provisioningState": "DEFINED",
  "qualifier": "application",
  "resourceVersion": 0,
  "targetIdList": [

  ],
  "type": "scalablegroup",
  "cfsChangeInfo": [

  ],
  "customProvisions": [

  ],
  "scalableGroupExternalHandle": "fire",
  "scalableGroupType": "APPLICATION",
  "securityGroupTag": 0,
  "state": "ACTIVE",
  "vnAgnostic": true,
  "identitySource": {
    "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
    "instanceId": 10010,
    "authEntityId": 10010,
    "displayName": "10010",
    "authEntityClass": 1738953278,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "state": "INACTIVE",
    "type": "NBAR"
  },
  "indicativeNetworkIdentity": [
    {
      "id": "f118172c-28fb-4d4d-88da-d1c4fb4316a2",
      "instanceId": 13127,
      "authEntityId": 13127,
      "displayName": "13127",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipProtocolNumbers": "125",
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "protocol": "FIRE",
      "upperPort": 0
    }
  ],
  "networkApplications": [
    {
      "id": "8d6c81d2-855a-43eb-b437-16c282418ddf",
      "instanceId": 11402,
      "authEntityId": 11402,
      "displayName": "11402",
      "authEntityClass": -217092956,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "appProtocol": "ip",
      "applicationGroup": "other",
      "applicationType": "DEFAULT",
      "categoryId": "6daa3dfd-3526-407a-88f0-97824b3f3a24",
      "createTime": 1517966742705,
      "encrypted": "unassigned",
      "engineId": "1",
      "helpString": "FIRE",
      "ignoreConflict": false,
      "lastUpdateTime": 1517966742705,
      "longDescription": "Registered with IANA as IP Protocol 125",
      "name": "fire",
      "nbarId": "879",
      "p2pTechnology": "unassigned",
      "popularity": 1,
      "rank": 65535,
      "references": "http://www.iana.org/assignments/protocol-numbers/protocol-numbers.xml",
      "selectorId": "125",
      "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
      "trafficClass": "BULK_DATA",
      "tunnel": "unassigned"
    }
  ],
  "networkIdentity": [
    {
      "id": "251f5dd4-93b3-4a01-a900-5aab99a329ef",
      "instanceId": 13126,
      "authEntityId": 13126,
      "displayName": "13126",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipProtocolNumbers": "125",
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "protocol": "FIRE",
      "upperPort": 0
    }
  ],
  "parentScalableGroup": {
    "idRef": "000c15bf-28fe-4fbb-9b69-35e6502721f1"
  },
  "user": [

  ],
  "userGroup": [

  ]
},
{
  "id": "98cb556d-045d-42b6-a678-0098c7a27bf0",
  "instanceId": 4881,
  "authEntityId": 4881,
  "displayName": "4881",
  "authEntityClass": -1909073334,
  "deployPending": "NONE",
  "instanceVersion": 0,
  "createTime": 1517966743672,
  "deployed": false,
  "isSeeded": true,
  "isStale": false,
  "lastUpdateTime": 1517966743672,
  "name": "nqs",
  "namespace": "scalablegroup:application",
  "provisioningState": "DEFINED",
  "qualifier": "application",
  "resourceVersion": 0,
  "targetIdList": [

  ],
  "type": "scalablegroup",
  "cfsChangeInfo": [

  ],
  "customProvisions": [

  ],
  "scalableGroupExternalHandle": "nqs",
  "scalableGroupType": "APPLICATION",
  "securityGroupTag": 0,
  "state": "ACTIVE",
  "vnAgnostic": true,
  "identitySource": {
    "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
    "instanceId": 10010,
    "authEntityId": 10010,
    "displayName": "10010",
    "authEntityClass": 1738953278,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "state": "INACTIVE",
    "type": "NBAR"
  },
  "indicativeNetworkIdentity": [
    {
      "id": "355a4da7-8f9d-497c-9f39-6d57eb15321b",
      "instanceId": 14290,
      "authEntityId": 14290,
      "displayName": "14290",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "607",
      "protocol": "UDP",
      "upperPort": 0
    },
    {
      "id": "61e5192e-e395-4266-86a2-90882dfa7b2a",
      "instanceId": 14291,
      "authEntityId": 14291,
      "displayName": "14291",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "607",
      "protocol": "TCP",
      "upperPort": 0
    }
  ],
  "networkApplications": [
    {
      "id": "1c6ac74f-4177-42e5-acc1-c8f968ab22b4",
      "instanceId": 11830,
      "authEntityId": 11830,
      "displayName": "11830",
      "authEntityClass": -217092956,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "appProtocol": "tcp/udp",
      "applicationGroup": "other",
      "applicationType": "DEFAULT",
      "categoryId": "1fc17362-9334-4c7b-b752-5afdbb574017",
      "createTime": 1517966743672,
      "encrypted": "false",
      "engineId": "3",
      "helpString": "nqs",
      "ignoreConflict": false,
      "lastUpdateTime": 1517966743672,
      "longDescription": "The Network Queueing System (NQS) allows users to submit batch jobs to queues on local or remote machines for execution. The NQS supports returning log and error files to the machine that originated the job.",
      "name": "nqs",
      "nbarId": "516",
      "p2pTechnology": "false",
      "popularity": 1,
      "rank": 65535,
      "references": "http://gnqs.sourceforge.net/docs/papers/mnqs_papers/original_cosmic_nqs_paper.htm",
      "selectorId": "607",
      "subCategory": "f3c0f761-d894-4112-ab89-b90fc84fd660",
      "trafficClass": "OPS_ADMIN_MGMT",
      "tunnel": "false"
    }
  ],
  "networkIdentity": [
    {
      "id": "6c26aca3-7dd6-42a0-8cab-558404b1db3d",
      "instanceId": 14288,
      "authEntityId": 14288,
      "displayName": "14288",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "607",
      "protocol": "TCP",
      "upperPort": 0
    },
    {
      "id": "917057dd-c047-4400-ad06-a8f39f941433",
      "instanceId": 14289,
      "authEntityId": 14289,
      "displayName": "14289",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "607",
      "protocol": "UDP",
      "upperPort": 0
    }
  ],
  "parentScalableGroup": {
    "idRef": "5fcd74d4-8726-47f7-a088-c457863da7ef"
  },
  "user": [

  ],
  "userGroup": [

  ]
},
{
  "id": "98cb846a-7cf5-417d-b557-4802326a155f",
  "instanceId": 4935,
  "authEntityId": 4935,
  "displayName": "4935",
  "authEntityClass": -1909073334,
  "deployPending": "NONE",
  "instanceVersion": 0,
  "createTime": 1517966743845,
  "deployed": false,
  "isSeeded": true,
  "isStale": false,
  "lastUpdateTime": 1517966743845,
  "name": "parsec-game",
  "namespace": "scalablegroup:application",
  "provisioningState": "DEFINED",
  "qualifier": "application",
  "resourceVersion": 0,
  "targetIdList": [

  ],
  "type": "scalablegroup",
  "cfsChangeInfo": [

  ],
  "customProvisions": [

  ],
  "scalableGroupExternalHandle": "parsec-game",
  "scalableGroupType": "APPLICATION",
  "securityGroupTag": 0,
  "state": "ACTIVE",
  "vnAgnostic": true,
  "identitySource": {
    "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
    "instanceId": 10010,
    "authEntityId": 10010,
    "displayName": "10010",
    "authEntityClass": 1738953278,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "state": "INACTIVE",
    "type": "NBAR"
  },
  "indicativeNetworkIdentity": [
    {
      "id": "e6eb38cc-c133-4dc0-80c1-fac610e84365",
      "instanceId": 14469,
      "authEntityId": 14469,
      "displayName": "14469",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "6582",
      "protocol": "TCP",
      "upperPort": 0
    },
    {
      "id": "04d7c0e6-013f-4eae-8a68-ddec5192923a",
      "instanceId": 14470,
      "authEntityId": 14470,
      "displayName": "14470",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "6582",
      "protocol": "UDP",
      "upperPort": 0
    }
  ],
  "networkApplications": [
    {
      "id": "0fbc0968-dff3-47b6-9d34-2ea01d73f4df",
      "instanceId": 11884,
      "authEntityId": 11884,
      "displayName": "11884",
      "authEntityClass": -217092956,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "appProtocol": "tcp/udp",
      "applicationGroup": "other",
      "applicationType": "DEFAULT",
      "categoryId": "3cd50dd8-1b2e-46c1-b672-41c14bfa364b",
      "createTime": 1517966743845,
      "encrypted": "false",
      "engineId": "3",
      "helpString": "Parsec Gameserver",
      "ignoreConflict": false,
      "lastUpdateTime": 1517966743845,
      "longDescription": "Parsec is a fast-paced non-commercial network space-shooter.",
      "name": "parsec-game",
      "nbarId": "744",
      "p2pTechnology": "false",
      "popularity": 1,
      "rank": 65535,
      "references": "http://www.parsec.org/",
      "selectorId": "6582",
      "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
      "trafficClass": "BULK_DATA",
      "tunnel": "false"
    }
  ],
  "networkIdentity": [
    {
      "id": "e1a943e6-9926-4711-aa89-4f5dd96cbe26",
      "instanceId": 14467,
      "authEntityId": 14467,
      "displayName": "14467",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "6582",
      "protocol": "UDP",
      "upperPort": 0
    },
    {
      "id": "aa563513-05fb-4eb6-b24e-799e7448e49b",
      "instanceId": 14468,
      "authEntityId": 14468,
      "displayName": "14468",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "6582",
      "protocol": "TCP",
      "upperPort": 0
    }
  ],
  "parentScalableGroup": {
    "idRef": "0bd0e6c9-5083-492b-97cb-a88928bee99c"
  },
  "user": [

  ],
  "userGroup": [

  ]
},
{
  "id": "98f12591-b365-482d-9708-13f64277851a",
  "instanceId": 4682,
  "authEntityId": 4682,
  "displayName": "4682",
  "authEntityClass": -1909073334,
  "deployPending": "NONE",
  "instanceVersion": 0,
  "createTime": 1517966743274,
  "deployed": false,
  "isSeeded": true,
  "isStale": false,
  "lastUpdateTime": 1517966743274,
  "name": "kshell",
  "namespace": "scalablegroup:application",
  "provisioningState": "DEFINED",
  "qualifier": "application",
  "resourceVersion": 0,
  "targetIdList": [

  ],
  "type": "scalablegroup",
  "cfsChangeInfo": [

  ],
  "customProvisions": [

  ],
  "scalableGroupExternalHandle": "kshell",
  "scalableGroupType": "APPLICATION",
  "securityGroupTag": 0,
  "state": "ACTIVE",
  "vnAgnostic": true,
  "identitySource": {
    "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
    "instanceId": 10010,
    "authEntityId": 10010,
    "displayName": "10010",
    "authEntityClass": 1738953278,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "state": "INACTIVE",
    "type": "NBAR"
  },
  "indicativeNetworkIdentity": [
    {
      "id": "406ca5d5-2aad-4649-8ef8-550c76541229",
      "instanceId": 13722,
      "authEntityId": 13722,
      "displayName": "13722",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "544",
      "protocol": "TCP",
      "upperPort": 0
    },
    {
      "id": "8584d40b-b0e4-4197-ab36-9f6eb5e6aa6b",
      "instanceId": 13723,
      "authEntityId": 13723,
      "displayName": "13723",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "544",
      "protocol": "UDP",
      "upperPort": 0
    }
  ],
  "networkApplications": [
    {
      "id": "d4cd873b-103e-4ddc-aec0-649e7e185162",
      "instanceId": 11632,
      "authEntityId": 11632,
      "displayName": "11632",
      "authEntityClass": -217092956,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "appProtocol": "tcp/udp",
      "applicationGroup": "other",
      "applicationType": "DEFAULT",
      "categoryId": "1fc17362-9334-4c7b-b752-5afdbb574017",
      "createTime": 1517966743274,
      "encrypted": "false",
      "engineId": "3",
      "helpString": "krcmd",
      "ignoreConflict": false,
      "lastUpdateTime": 1517966743274,
      "longDescription": "Registered with IANA on port 544 TCP/UDP",
      "name": "kshell",
      "nbarId": "88",
      "p2pTechnology": "false",
      "popularity": 1,
      "rank": 65535,
      "references": "http://www.iana.org/assignments/service-names-port-numbers/service-names-port-numbers.xml",
      "selectorId": "544",
      "subCategory": "787a7d77-977b-4974-89d3-379ba49e88b7",
      "trafficClass": "OPS_ADMIN_MGMT",
      "tunnel": "false"
    }
  ],
  "networkIdentity": [
    {
      "id": "a0708e2a-1a4b-49a9-8cbb-7253e604af50",
      "instanceId": 13720,
      "authEntityId": 13720,
      "displayName": "13720",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "544",
      "protocol": "UDP",
      "upperPort": 0
    },
    {
      "id": "4e13615d-d17d-4f30-82a7-e1691bd4a50b",
      "instanceId": 13721,
      "authEntityId": 13721,
      "displayName": "13721",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "544",
      "protocol": "TCP",
      "upperPort": 0
    }
  ],
  "parentScalableGroup": {
    "idRef": "e06dd89f-0a70-4422-ab0b-c2314650a9a7"
  },
  "user": [

  ],
  "userGroup": [

  ]
},
{
  "id": "993fc53c-ec9f-4fdd-aa16-80305f2f976a",
  "instanceId": 4712,
  "authEntityId": 4712,
  "displayName": "4712",
  "authEntityClass": -1909073334,
  "deployPending": "NONE",
  "instanceVersion": 0,
  "createTime": 1517966743336,
  "deployed": false,
  "isSeeded": true,
  "isStale": false,
  "lastUpdateTime": 1517966743336,
  "name": "magenta-logic",
  "namespace": "scalablegroup:application",
  "provisioningState": "DEFINED",
  "qualifier": "application",
  "resourceVersion": 0,
  "targetIdList": [

  ],
  "type": "scalablegroup",
  "cfsChangeInfo": [

  ],
  "customProvisions": [

  ],
  "scalableGroupExternalHandle": "magenta-logic",
  "scalableGroupType": "APPLICATION",
  "securityGroupTag": 0,
  "state": "ACTIVE",
  "vnAgnostic": true,
  "identitySource": {
    "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
    "instanceId": 10010,
    "authEntityId": 10010,
    "displayName": "10010",
    "authEntityClass": 1738953278,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "state": "INACTIVE",
    "type": "NBAR"
  },
  "indicativeNetworkIdentity": [
    {
      "id": "0fc9c976-8bba-4776-8905-3695bc3f4fd9",
      "instanceId": 13804,
      "authEntityId": 13804,
      "displayName": "13804",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "313",
      "protocol": "TCP",
      "upperPort": 0
    },
    {
      "id": "219083a7-b46d-4588-a987-21b2340eae6e",
      "instanceId": 13805,
      "authEntityId": 13805,
      "displayName": "13805",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "313",
      "protocol": "UDP",
      "upperPort": 0
    }
  ],
  "networkApplications": [
    {
      "id": "0a86ec92-2d41-4433-aba6-6474c9e18be6",
      "instanceId": 11662,
      "authEntityId": 11662,
      "displayName": "11662",
      "authEntityClass": -217092956,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "appProtocol": "tcp/udp",
      "applicationGroup": "other",
      "applicationType": "DEFAULT",
      "categoryId": "6daa3dfd-3526-407a-88f0-97824b3f3a24",
      "createTime": 1517966743336,
      "encrypted": "false",
      "engineId": "3",
      "helpString": "magenta-logic",
      "ignoreConflict": false,
      "lastUpdateTime": 1517966743336,
      "longDescription": "Registered with IANA on port 313 TCP/UDP",
      "name": "magenta-logic",
      "nbarId": "1153",
      "p2pTechnology": "false",
      "popularity": 1,
      "rank": 65535,
      "references": "http://www.iana.org/assignments/service-names-port-numbers/service-names-port-numbers.xml",
      "selectorId": "313",
      "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
      "trafficClass": "BULK_DATA",
      "tunnel": "false"
    }
  ],
  "networkIdentity": [
    {
      "id": "e214ed64-6350-4c3a-b4c6-516537a5075d",
      "instanceId": 13802,
      "authEntityId": 13802,
      "displayName": "13802",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "313",
      "protocol": "UDP",
      "upperPort": 0
    },
    {
      "id": "4b9acc97-cb9c-4486-9cec-2680300b4b27",
      "instanceId": 13803,
      "authEntityId": 13803,
      "displayName": "13803",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "313",
      "protocol": "TCP",
      "upperPort": 0
    }
  ],
  "parentScalableGroup": {
    "idRef": "000c15bf-28fe-4fbb-9b69-35e6502721f1"
  },
  "user": [

  ],
  "userGroup": [

  ]
},
{
  "id": "9987a85f-b61e-43e4-8d5f-d82ee467dc2d",
  "instanceId": 4821,
  "authEntityId": 4821,
  "displayName": "4821",
  "authEntityClass": -1909073334,
  "deployPending": "NONE",
  "instanceVersion": 0,
  "createTime": 1517966743558,
  "deployed": false,
  "isSeeded": true,
  "isStale": false,
  "lastUpdateTime": 1517966743558,
  "name": "national-public-radio",
  "namespace": "scalablegroup:application",
  "provisioningState": "DEFINED",
  "qualifier": "application",
  "resourceVersion": 0,
  "targetIdList": [

  ],
  "type": "scalablegroup",
  "cfsChangeInfo": [

  ],
  "customProvisions": [

  ],
  "scalableGroupExternalHandle": "national-public-radio",
  "scalableGroupType": "APPLICATION",
  "securityGroupTag": 0,
  "state": "ACTIVE",
  "vnAgnostic": true,
  "identitySource": {
    "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
    "instanceId": 10010,
    "authEntityId": 10010,
    "displayName": "10010",
    "authEntityClass": 1738953278,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "state": "INACTIVE",
    "type": "NBAR"
  },
  "indicativeNetworkIdentity": [

  ],
  "networkApplications": [
    {
      "id": "97cf8f57-2d11-41c7-afc9-42ab7fef4cfc",
      "instanceId": 11770,
      "authEntityId": 11770,
      "displayName": "11770",
      "authEntityClass": -217092956,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "appProtocol": "tcp/udp",
      "applicationGroup": "other",
      "applicationType": "DEFAULT",
      "categoryId": "878644b5-fe91-45b4-9204-31c84a73ba48",
      "createTime": 1517966743558,
      "encrypted": "false",
      "engineId": "13",
      "helpString": "National Public Radio - US radio station to provide news and other programs.",
      "ignoreConflict": false,
      "lastUpdateTime": 1517966743558,
      "longDescription": "National Public Radio - US radio station to provide news and other programs.",
      "name": "national-public-radio",
      "nbarId": "1641",
      "p2pTechnology": "false",
      "popularity": 3,
      "rank": 65535,
      "references": "http://npr.org",
      "selectorId": "711",
      "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
      "trafficClass": "TRANSACTIONAL_DATA",
      "tunnel": "false"
    }
  ],
  "networkIdentity": [
    {
      "id": "cf0b11d0-fa5f-426c-986d-9c1bb1aa6874",
      "instanceId": 14096,
      "authEntityId": 14096,
      "displayName": "14096",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "53,80,443,5353",
      "protocol": "TCP",
      "upperPort": 0
    },
    {
      "id": "bf0931ad-1872-4168-8512-5f519194012f",
      "instanceId": 14097,
      "authEntityId": 14097,
      "displayName": "14097",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "53,5353",
      "protocol": "UDP",
      "upperPort": 0
    }
  ],
  "parentScalableGroup": {
    "idRef": "28e08de4-d7b2-4521-84ce-bc1e5ee0b9cc"
  },
  "user": [

  ],
  "userGroup": [

  ]
},
{
  "id": "998acd55-9e71-4ad2-a2f3-f033f2938bdc",
  "instanceId": 4847,
  "authEntityId": 4847,
  "displayName": "4847",
  "authEntityClass": -1909073334,
  "deployPending": "NONE",
  "instanceVersion": 0,
  "createTime": 1517966743607,
  "deployed": false,
  "isSeeded": true,
  "isStale": false,
  "lastUpdateTime": 1517966743607,
  "name": "netrjs-3",
  "namespace": "scalablegroup:application",
  "provisioningState": "DEFINED",
  "qualifier": "application",
  "resourceVersion": 0,
  "targetIdList": [

  ],
  "type": "scalablegroup",
  "cfsChangeInfo": [

  ],
  "customProvisions": [

  ],
  "scalableGroupExternalHandle": "netrjs-3",
  "scalableGroupType": "APPLICATION",
  "securityGroupTag": 0,
  "state": "ACTIVE",
  "vnAgnostic": true,
  "identitySource": {
    "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
    "instanceId": 10010,
    "authEntityId": 10010,
    "displayName": "10010",
    "authEntityClass": 1738953278,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "state": "INACTIVE",
    "type": "NBAR"
  },
  "indicativeNetworkIdentity": [
    {
      "id": "c0cb82d2-5380-4b16-8ea7-fa2a33fae455",
      "instanceId": 14174,
      "authEntityId": 14174,
      "displayName": "14174",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "73",
      "protocol": "TCP",
      "upperPort": 0
    },
    {
      "id": "175694a6-8973-42e4-9834-001c9ca641d5",
      "instanceId": 14175,
      "authEntityId": 14175,
      "displayName": "14175",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "73",
      "protocol": "UDP",
      "upperPort": 0
    }
  ],
  "networkApplications": [
    {
      "id": "66fe7201-aeac-403c-97d8-afe237dc143a",
      "instanceId": 11796,
      "authEntityId": 11796,
      "displayName": "11796",
      "authEntityClass": -217092956,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "appProtocol": "tcp/udp",
      "applicationGroup": "other",
      "applicationType": "DEFAULT",
      "categoryId": "1fc17362-9334-4c7b-b752-5afdbb574017",
      "createTime": 1517966743607,
      "encrypted": "false",
      "engineId": "3",
      "helpString": "Remote Job Service",
      "ignoreConflict": false,
      "lastUpdateTime": 1517966743607,
      "longDescription": "NETRJS is a private protocol for remote job entry service, was defined and implemented by the UCLA Campus Computing Network (CCN). CCN's NETRJS server allows a remote user, or a daemon process working in behalf of a user, to access CCN's Remote Job Service (RJS) subsystem.",
      "name": "netrjs-3",
      "nbarId": "950",
      "p2pTechnology": "false",
      "popularity": 1,
      "rank": 65535,
      "references": "http://tools.ietf.org/html/rfc740",
      "selectorId": "73",
      "subCategory": "f3c0f761-d894-4112-ab89-b90fc84fd660",
      "trafficClass": "OPS_ADMIN_MGMT",
      "tunnel": "false"
    }
  ],
  "networkIdentity": [
    {
      "id": "8bdbb87f-4c41-4fa7-aab6-9112c25ef5a0",
      "instanceId": 14172,
      "authEntityId": 14172,
      "displayName": "14172",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "73",
      "protocol": "TCP",
      "upperPort": 0
    },
    {
      "id": "dcba8051-a396-434d-9afc-bccb84e76b2c",
      "instanceId": 14173,
      "authEntityId": 14173,
      "displayName": "14173",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "73",
      "protocol": "UDP",
      "upperPort": 0
    }
  ],
  "parentScalableGroup": {
    "idRef": "5fcd74d4-8726-47f7-a088-c457863da7ef"
  },
  "user": [

  ],
  "userGroup": [

  ]
},
{
  "id": "99ad471a-3f18-4a82-a983-29ec542ec023",
  "instanceId": 4254,
  "authEntityId": 4254,
  "displayName": "4254",
  "authEntityClass": -1909073334,
  "deployPending": "NONE",
  "instanceVersion": 0,
  "createTime": 1517966741951,
  "deployed": false,
  "isSeeded": true,
  "isStale": false,
  "lastUpdateTime": 1517966741951,
  "name": "cisco-spark-media",
  "namespace": "scalablegroup:application",
  "provisioningState": "DEFINED",
  "qualifier": "application",
  "resourceVersion": 0,
  "targetIdList": [

  ],
  "type": "scalablegroup",
  "cfsChangeInfo": [

  ],
  "customProvisions": [

  ],
  "scalableGroupExternalHandle": "cisco-spark-media",
  "scalableGroupType": "APPLICATION",
  "securityGroupTag": 0,
  "state": "ACTIVE",
  "vnAgnostic": true,
  "identitySource": {
    "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
    "instanceId": 10010,
    "authEntityId": 10010,
    "displayName": "10010",
    "authEntityClass": 1738953278,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "state": "INACTIVE",
    "type": "NBAR"
  },
  "indicativeNetworkIdentity": [

  ],
  "networkApplications": [
    {
      "id": "a83f2613-f2e8-484c-8adf-ed000c50e27c",
      "instanceId": 11208,
      "authEntityId": 11208,
      "displayName": "11208",
      "authEntityClass": -217092956,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "applicationGroup": "other",
      "applicationType": "DEFAULT",
      "categoryId": "38a8e354-e2fe-4f3c-b1c5-2bbb90fb0d99",
      "createTime": 1517966741951,
      "encrypted": "false",
      "engineId": "13",
      "helpString": "Cisco Spark Media - unified communications client media",
      "ignoreConflict": false,
      "lastUpdateTime": 1517966741951,
      "longDescription": "Cisco Spark Media - Media of unified communications client and SaaS with mobile team communication: group chat, private chat, video calls with screen sharing and file sharing.",
      "name": "cisco-spark-media",
      "nbarId": "1051",
      "p2pTechnology": "false",
      "popularity": 10,
      "rank": 65535,
      "references": "http://ciscospark.com",
      "selectorId": "1051",
      "subCategory": "53ce0342-1e2d-455a-acc7-1b50db9d6845",
      "trafficClass": "MULTIMEDIA_CONFERENCING",
      "tunnel": "false"
    }
  ],
  "networkIdentity": [

  ],
  "parentScalableGroup": {
    "idRef": "60f1364d-2a02-4063-8ba1-07817abe0612"
  },
  "user": [

  ],
  "userGroup": [

  ]
},
{
  "id": "99e73e2b-a28d-45de-9b25-7aa11d7126fc",
  "instanceId": 4325,
  "authEntityId": 4325,
  "displayName": "4325",
  "authEntityClass": -1909073334,
  "deployPending": "NONE",
  "instanceVersion": 0,
  "createTime": 1517966742286,
  "deployed": false,
  "isSeeded": true,
  "isStale": false,
  "lastUpdateTime": 1517966742286,
  "name": "datasurfsrv",
  "namespace": "scalablegroup:application",
  "provisioningState": "DEFINED",
  "qualifier": "application",
  "resourceVersion": 0,
  "targetIdList": [

  ],
  "type": "scalablegroup",
  "cfsChangeInfo": [

  ],
  "customProvisions": [

  ],
  "scalableGroupExternalHandle": "datasurfsrv",
  "scalableGroupType": "APPLICATION",
  "securityGroupTag": 0,
  "state": "ACTIVE",
  "vnAgnostic": true,
  "identitySource": {
    "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
    "instanceId": 10010,
    "authEntityId": 10010,
    "displayName": "10010",
    "authEntityClass": 1738953278,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "state": "INACTIVE",
    "type": "NBAR"
  },
  "indicativeNetworkIdentity": [
    {
      "id": "5312d2f4-c6fe-4768-8a47-b208ebd4b35d",
      "instanceId": 12752,
      "authEntityId": 12752,
      "displayName": "12752",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "461",
      "protocol": "UDP",
      "upperPort": 0
    },
    {
      "id": "8762cb59-bed1-45b6-819d-22e387217f42",
      "instanceId": 12753,
      "authEntityId": 12753,
      "displayName": "12753",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "461",
      "protocol": "TCP",
      "upperPort": 0
    }
  ],
  "networkApplications": [
    {
      "id": "6028e2ae-deca-4e83-ba98-120187b630e0",
      "instanceId": 11277,
      "authEntityId": 11277,
      "displayName": "11277",
      "authEntityClass": -217092956,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "appProtocol": "tcp/udp",
      "applicationGroup": "other",
      "applicationType": "DEFAULT",
      "categoryId": "6daa3dfd-3526-407a-88f0-97824b3f3a24",
      "createTime": 1517966742286,
      "encrypted": "false",
      "engineId": "3",
      "helpString": "DataRamp Svr",
      "ignoreConflict": false,
      "lastUpdateTime": 1517966742286,
      "longDescription": "Registered with IANA on port 461 TCP/UDP",
      "name": "datasurfsrv",
      "nbarId": "375",
      "p2pTechnology": "false",
      "popularity": 1,
      "rank": 65535,
      "references": "http://www.iana.org/assignments/service-names-port-numbers/service-names-port-numbers.xml",
      "selectorId": "461",
      "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
      "trafficClass": "BULK_DATA",
      "tunnel": "false"
    }
  ],
  "networkIdentity": [
    {
      "id": "c12e5fc4-e636-48d0-909b-9941cde2177e",
      "instanceId": 12750,
      "authEntityId": 12750,
      "displayName": "12750",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "461",
      "protocol": "TCP",
      "upperPort": 0
    },
    {
      "id": "29844b8f-d659-429d-83d4-446e25fcb0ba",
      "instanceId": 12751,
      "authEntityId": 12751,
      "displayName": "12751",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "461",
      "protocol": "UDP",
      "upperPort": 0
    }
  ],
  "parentScalableGroup": {
    "idRef": "000c15bf-28fe-4fbb-9b69-35e6502721f1"
  },
  "user": [

  ],
  "userGroup": [

  ]
},
{
  "id": "99f44857-a76e-4378-af70-0c1ef668973d",
  "instanceId": 4530,
  "authEntityId": 4530,
  "displayName": "4530",
  "authEntityClass": -1909073334,
  "deployPending": "NONE",
  "instanceVersion": 0,
  "createTime": 1517966742903,
  "deployed": false,
  "isSeeded": true,
  "isStale": false,
  "lastUpdateTime": 1517966742903,
  "name": "hangouts-media",
  "namespace": "scalablegroup:application",
  "provisioningState": "DEFINED",
  "qualifier": "application",
  "resourceVersion": 0,
  "targetIdList": [

  ],
  "type": "scalablegroup",
  "cfsChangeInfo": [

  ],
  "customProvisions": [

  ],
  "scalableGroupExternalHandle": "hangouts-media",
  "scalableGroupType": "APPLICATION",
  "securityGroupTag": 0,
  "state": "ACTIVE",
  "vnAgnostic": true,
  "identitySource": {
    "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
    "instanceId": 10010,
    "authEntityId": 10010,
    "displayName": "10010",
    "authEntityClass": 1738953278,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "state": "INACTIVE",
    "type": "NBAR"
  },
  "indicativeNetworkIdentity": [

  ],
  "networkApplications": [
    {
      "id": "2de6c1a5-fb05-4230-b983-8ed4dc051daa",
      "instanceId": 11481,
      "authEntityId": 11481,
      "displayName": "11481",
      "authEntityClass": -217092956,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "appProtocol": "tcp/udp",
      "applicationGroup": "hangouts-group",
      "applicationType": "DEFAULT",
      "categoryId": "82a7b7fa-da5a-48f5-8b6a-a2546b947843",
      "createTime": 1517966742903,
      "encrypted": "true",
      "engineId": "13",
      "helpString": "Google Hangouts media",
      "ignoreConflict": false,
      "lastUpdateTime": 1517966742903,
      "longDescription": "Google Hangouts media",
      "name": "hangouts-media",
      "nbarId": "1088",
      "p2pTechnology": "true",
      "popularity": 10,
      "rank": 65535,
      "references": "https://hangouts.google.com/",
      "selectorId": "1088",
      "subCategory": "118c10a2-e272-47c1-92b9-4814ebe97138",
      "trafficClass": "MULTIMEDIA_CONFERENCING",
      "tunnel": "false"
    }
  ],
  "networkIdentity": [
    {
      "id": "11ef15bd-9a5a-47da-951a-d45b26ea1ae0",
      "instanceId": 13312,
      "authEntityId": 13312,
      "displayName": "13312",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "3478",
      "protocol": "UDP",
      "upperPort": 0
    },
    {
      "id": "e95db666-7679-4187-bdbd-da4985c19975",
      "instanceId": 13311,
      "authEntityId": 13311,
      "displayName": "13311",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "3478",
      "protocol": "TCP",
      "upperPort": 0
    }
  ],
  "parentScalableGroup": {
    "idRef": "fd6264c9-c16a-4311-a335-a3db039256f3"
  },
  "user": [

  ],
  "userGroup": [

  ]
},
{
  "id": "9a3b0f13-66de-443e-9f12-3ce4d7d1d56e",
  "instanceId": 4045,
  "authEntityId": 4045,
  "displayName": "4045",
  "authEntityClass": -1909073334,
  "deployPending": "NONE",
  "instanceVersion": 0,
  "createTime": 1517966740567,
  "deployed": false,
  "isSeeded": true,
  "isStale": false,
  "lastUpdateTime": 1517966740567,
  "name": "MobilitySrv",
  "namespace": "scalablegroup:application",
  "provisioningState": "DEFINED",
  "qualifier": "application",
  "resourceVersion": 0,
  "targetIdList": [

  ],
  "type": "scalablegroup",
  "cfsChangeInfo": [

  ],
  "customProvisions": [

  ],
  "scalableGroupExternalHandle": "MobilitySrv",
  "scalableGroupType": "APPLICATION",
  "securityGroupTag": 0,
  "state": "ACTIVE",
  "vnAgnostic": true,
  "identitySource": {
    "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
    "instanceId": 10010,
    "authEntityId": 10010,
    "displayName": "10010",
    "authEntityClass": 1738953278,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "state": "INACTIVE",
    "type": "NBAR"
  },
  "indicativeNetworkIdentity": [
    {
      "id": "d37f7bd7-4ac2-4075-8445-ffe387d717f0",
      "instanceId": 12040,
      "authEntityId": 12040,
      "displayName": "12040",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "6997",
      "protocol": "TCP",
      "upperPort": 0
    },
    {
      "id": "2754f2ce-465f-4fef-bb48-a7ee93554480",
      "instanceId": 12041,
      "authEntityId": 12041,
      "displayName": "12041",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "6997",
      "protocol": "UDP",
      "upperPort": 0
    }
  ],
  "networkApplications": [
    {
      "id": "777fbbd2-258b-4e85-bb90-1153296cf42f",
      "instanceId": 11019,
      "authEntityId": 11019,
      "displayName": "11019",
      "authEntityClass": -217092956,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "appProtocol": "tcp/udp",
      "applicationGroup": "other",
      "applicationType": "DEFAULT",
      "categoryId": "6daa3dfd-3526-407a-88f0-97824b3f3a24",
      "createTime": 1517966740567,
      "encrypted": "false",
      "engineId": "3",
      "helpString": "Mobility XE protocol",
      "ignoreConflict": false,
      "lastUpdateTime": 1517966740567,
      "longDescription": "Mobility XE is a mobile VPN that provides authentication of a user, device or both, and FIPS 140-2 validated encryption. It is designed specifically for mobile workers using wireless networks. Mobility XE is optimized for cellular data networks, public and private Wi-Fi hotspots, or any other IP-based network that mobile workers use for remote access including Ethernet LANs, home networks and even dial-up connections.",
      "name": "MobilitySrv",
      "nbarId": "1386",
      "p2pTechnology": "false",
      "popularity": 1,
      "rank": 65535,
      "references": "http://www.ttmarketingservices.com/nmw/images/stories/pdf/netmotion%20mobility%20xe%20security%202009.pdf",
      "selectorId": "6997",
      "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
      "trafficClass": "BULK_DATA",
      "tunnel": "false"
    }
  ],
  "networkIdentity": [
    {
      "id": "16f83955-7d46-46be-9e9a-51eff3b71219",
      "instanceId": 12038,
      "authEntityId": 12038,
      "displayName": "12038",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "6997",
      "protocol": "UDP",
      "upperPort": 0
    },
    {
      "id": "8ff123cf-1f2c-45d0-a2dd-f57bdd05b01f",
      "instanceId": 12039,
      "authEntityId": 12039,
      "displayName": "12039",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "6997",
      "protocol": "TCP",
      "upperPort": 0
    }
  ],
  "parentScalableGroup": {
    "idRef": "000c15bf-28fe-4fbb-9b69-35e6502721f1"
  },
  "user": [

  ],
  "userGroup": [

  ]
},
{
  "id": "9a845006-ff0b-4185-81b8-c7d96fb971dd",
  "instanceId": 15460,
  "authEntityId": 15460,
  "displayName": "15460",
  "authEntityClass": -1909073334,
  "deployPending": "NONE",
  "instanceVersion": 0,
  "createTime": 1517966745011,
  "deployed": false,
  "isSeeded": true,
  "isStale": false,
  "lastUpdateTime": 1517966745011,
  "name": "yandex",
  "namespace": "scalablegroup:application",
  "provisioningState": "DEFINED",
  "qualifier": "application",
  "resourceVersion": 0,
  "targetIdList": [

  ],
  "type": "scalablegroup",
  "cfsChangeInfo": [

  ],
  "customProvisions": [

  ],
  "scalableGroupExternalHandle": "yandex",
  "scalableGroupType": "APPLICATION",
  "securityGroupTag": 0,
  "state": "ACTIVE",
  "vnAgnostic": true,
  "identitySource": {
    "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
    "instanceId": 10010,
    "authEntityId": 10010,
    "displayName": "10010",
    "authEntityClass": 1738953278,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "state": "INACTIVE",
    "type": "NBAR"
  },
  "indicativeNetworkIdentity": [

  ],
  "networkApplications": [
    {
      "id": "c8a9052d-073a-4514-bf4c-308e71ac78f9",
      "instanceId": 16403,
      "authEntityId": 16403,
      "displayName": "16403",
      "authEntityClass": -217092956,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "appProtocol": "tcp/udp",
      "applicationGroup": "other",
      "applicationType": "DEFAULT",
      "categoryId": "878644b5-fe91-45b4-9204-31c84a73ba48",
      "createTime": 1517966745011,
      "encrypted": "false",
      "engineId": "13",
      "helpString": "Yandex - Russian search engine.",
      "ignoreConflict": false,
      "lastUpdateTime": 1517966745011,
      "longDescription": "Yandex - Russian search engine.",
      "name": "yandex",
      "nbarId": "760",
      "p2pTechnology": "false",
      "popularity": 3,
      "rank": 65535,
      "references": "http://yandex.ru",
      "selectorId": "611",
      "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
      "trafficClass": "TRANSACTIONAL_DATA",
      "tunnel": "false"
    }
  ],
  "networkIdentity": [
    {
      "id": "b0891fa7-81b0-49ff-9965-6fb32d4ce92a",
      "instanceId": 17956,
      "authEntityId": 17956,
      "displayName": "17956",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "53,5353",
      "protocol": "UDP",
      "upperPort": 0
    },
    {
      "id": "be039159-c94a-4e28-a806-e3b4548b6625",
      "instanceId": 17957,
      "authEntityId": 17957,
      "displayName": "17957",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "53,80,443,5353",
      "protocol": "TCP",
      "upperPort": 0
    }
  ],
  "parentScalableGroup": {
    "idRef": "28e08de4-d7b2-4521-84ce-bc1e5ee0b9cc"
  },
  "user": [

  ],
  "userGroup": [

  ]
},
{
  "id": "9ac33990-3368-4e9c-870a-111b4f5c666d",
  "instanceId": 15263,
  "authEntityId": 15263,
  "displayName": "15263",
  "authEntityClass": -1909073334,
  "deployPending": "NONE",
  "instanceVersion": 0,
  "createTime": 1517966744607,
  "deployed": false,
  "isSeeded": true,
  "isStale": false,
  "lastUpdateTime": 1517966744607,
  "name": "teedtap",
  "namespace": "scalablegroup:application",
  "provisioningState": "DEFINED",
  "qualifier": "application",
  "resourceVersion": 0,
  "targetIdList": [

  ],
  "type": "scalablegroup",
  "cfsChangeInfo": [

  ],
  "customProvisions": [

  ],
  "scalableGroupExternalHandle": "teedtap",
  "scalableGroupType": "APPLICATION",
  "securityGroupTag": 0,
  "state": "ACTIVE",
  "vnAgnostic": true,
  "identitySource": {
    "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
    "instanceId": 10010,
    "authEntityId": 10010,
    "displayName": "10010",
    "authEntityClass": 1738953278,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "state": "INACTIVE",
    "type": "NBAR"
  },
  "indicativeNetworkIdentity": [
    {
      "id": "c560b791-4b6a-4ff2-ad83-ebe5e13a1143",
      "instanceId": 17425,
      "authEntityId": 17425,
      "displayName": "17425",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "559",
      "protocol": "TCP",
      "upperPort": 0
    },
    {
      "id": "f60297d6-7304-405b-af49-58622801a8bc",
      "instanceId": 17426,
      "authEntityId": 17426,
      "displayName": "17426",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "559",
      "protocol": "UDP",
      "upperPort": 0
    }
  ],
  "networkApplications": [
    {
      "id": "b818d79d-c133-4e35-a839-1a9b502b730a",
      "instanceId": 16206,
      "authEntityId": 16206,
      "displayName": "16206",
      "authEntityClass": -217092956,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "appProtocol": "tcp/udp",
      "applicationGroup": "other",
      "applicationType": "DEFAULT",
      "categoryId": "6daa3dfd-3526-407a-88f0-97824b3f3a24",
      "createTime": 1517966744607,
      "encrypted": "false",
      "engineId": "3",
      "helpString": "teedtap",
      "ignoreConflict": false,
      "lastUpdateTime": 1517966744607,
      "longDescription": "Registered with IANA on port 559 TCP/UDP",
      "name": "teedtap",
      "nbarId": "474",
      "p2pTechnology": "false",
      "popularity": 1,
      "rank": 65535,
      "references": "http://www.iana.org/assignments/service-names-port-numbers/service-names-port-numbers.xml",
      "selectorId": "559",
      "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
      "trafficClass": "BULK_DATA",
      "tunnel": "false"
    }
  ],
  "networkIdentity": [
    {
      "id": "1296f113-bbd0-4552-b3a5-03fa4ca7b313",
      "instanceId": 17424,
      "authEntityId": 17424,
      "displayName": "17424",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "559",
      "protocol": "UDP",
      "upperPort": 0
    },
    {
      "id": "8380c3ec-fa9c-4a8a-8a83-54f42d33d58e",
      "instanceId": 17423,
      "authEntityId": 17423,
      "displayName": "17423",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "559",
      "protocol": "TCP",
      "upperPort": 0
    }
  ],
  "parentScalableGroup": {
    "idRef": "000c15bf-28fe-4fbb-9b69-35e6502721f1"
  },
  "user": [

  ],
  "userGroup": [

  ]
},
{
  "id": "9afb39da-3417-4c5c-8f2a-70caa8533fa6",
  "instanceId": 15219,
  "authEntityId": 15219,
  "displayName": "15219",
  "authEntityClass": -1909073334,
  "deployPending": "NONE",
  "instanceVersion": 0,
  "createTime": 1517966744519,
  "deployed": false,
  "isSeeded": true,
  "isStale": false,
  "lastUpdateTime": 1517966744519,
  "name": "steam",
  "namespace": "scalablegroup:application",
  "provisioningState": "DEFINED",
  "qualifier": "application",
  "resourceVersion": 0,
  "targetIdList": [

  ],
  "type": "scalablegroup",
  "cfsChangeInfo": [

  ],
  "customProvisions": [

  ],
  "scalableGroupExternalHandle": "steam",
  "scalableGroupType": "APPLICATION",
  "securityGroupTag": 0,
  "state": "ACTIVE",
  "vnAgnostic": true,
  "identitySource": {
    "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
    "instanceId": 10010,
    "authEntityId": 10010,
    "displayName": "10010",
    "authEntityClass": 1738953278,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "state": "INACTIVE",
    "type": "NBAR"
  },
  "indicativeNetworkIdentity": [
    {
      "id": "3d1b0c20-2450-4a26-b7d3-43a5a18572c8",
      "instanceId": 17286,
      "authEntityId": 17286,
      "displayName": "17286",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "27000,27001,27002,27003,27004,27005,27006,27007,27008,27009,27010,27011,27012,27013,27014,27015,27016,27017,27018,27019,27020,27021,27022,27023,27024,27025,27026,27027,27028,27029,27030,27031,27032,27033,27034,27035,27036,27037,27038,27039,27040,27041,27042,27043,27044,27045,27046,27047,27048,27049,27050",
      "protocol": "UDP",
      "upperPort": 0
    },
    {
      "id": "2150b10b-ea7d-42b6-ae92-b1c8d63d1b8e",
      "instanceId": 17287,
      "authEntityId": 17287,
      "displayName": "17287",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "27000,27001,27002,27003,27004,27005,27006,27007,27008,27009,27010,27011,27012,27013,27014,27015,27016,27017,27018,27019,27020,27021,27022,27023,27024,27025,27026,27027,27028,27029,27030,27031,27032,27033,27034,27035,27036,27037,27038,27039,27040,27041,27042,27043,27044,27045,27046,27047,27048,27049,27050",
      "protocol": "TCP",
      "upperPort": 0
    }
  ],
  "networkApplications": [
    {
      "id": "bc09c18c-6993-4715-beac-d4656fbabc50",
      "instanceId": 16162,
      "authEntityId": 16162,
      "displayName": "16162",
      "authEntityClass": -217092956,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "appProtocol": "tcp/udp",
      "applicationGroup": "other",
      "applicationType": "DEFAULT",
      "categoryId": "3cd50dd8-1b2e-46c1-b672-41c14bfa364b",
      "createTime": 1517966744519,
      "encrypted": "false",
      "engineId": "13",
      "helpString": "Online game platform",
      "ignoreConflict": false,
      "lastUpdateTime": 1517966744519,
      "longDescription": "Steam is a massive gaming community and an online gaming platform. It delivers games to users' desktops and it allows gamers to communicate while playing online using text chatting or audio.",
      "name": "steam",
      "nbarId": "1401",
      "p2pTechnology": "false",
      "popularity": 7,
      "rank": 65535,
      "references": "http://store.steampowered.com/about/",
      "selectorId": "472",
      "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
      "trafficClass": "BULK_DATA",
      "tunnel": "false"
    }
  ],
  "networkIdentity": [
    {
      "id": "e1e4b9c6-d970-4097-b985-c0b0596a7ec0",
      "instanceId": 17284,
      "authEntityId": 17284,
      "displayName": "17284",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "80,27000,27001,27002,27003,27004,27005,27006,27007,27008,27009,27010,27011,27012,27013,27014,27015,27016,27017,27018,27019,27020,27021,27022,27023,27024,27025,27026,27027,27028,27029,27030,27031,27032,27033,27034,27035,27036,27037,27038,27039,27040,27041,27042,27043,27044,27045,27046,27047,27048,27049,27050",
      "protocol": "TCP",
      "upperPort": 0
    },
    {
      "id": "4d0734b3-2916-4460-b280-09892d2e7c83",
      "instanceId": 17285,
      "authEntityId": 17285,
      "displayName": "17285",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "27000,27001,27002,27003,27004,27005,27006,27007,27008,27009,27010,27011,27012,27013,27014,27015,27016,27017,27018,27019,27020,27021,27022,27023,27024,27025,27026,27027,27028,27029,27030,27031,27032,27033,27034,27035,27036,27037,27038,27039,27040,27041,27042,27043,27044,27045,27046,27047,27048,27049,27050",
      "protocol": "UDP",
      "upperPort": 0
    }
  ],
  "parentScalableGroup": {
    "idRef": "0bd0e6c9-5083-492b-97cb-a88928bee99c"
  },
  "user": [

  ],
  "userGroup": [

  ]
},
{
  "id": "9b31823c-a651-417d-8bcb-e8c962cf0741",
  "instanceId": 4112,
  "authEntityId": 4112,
  "displayName": "4112",
  "authEntityClass": -1909073334,
  "deployPending": "NONE",
  "instanceVersion": 0,
  "createTime": 1517966741180,
  "deployed": false,
  "isSeeded": true,
  "isStale": false,
  "lastUpdateTime": 1517966741180,
  "name": "applix",
  "namespace": "scalablegroup:application",
  "provisioningState": "DEFINED",
  "qualifier": "application",
  "resourceVersion": 0,
  "targetIdList": [

  ],
  "type": "scalablegroup",
  "cfsChangeInfo": [

  ],
  "customProvisions": [

  ],
  "scalableGroupExternalHandle": "applix",
  "scalableGroupType": "APPLICATION",
  "securityGroupTag": 0,
  "state": "ACTIVE",
  "vnAgnostic": true,
  "identitySource": {
    "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
    "instanceId": 10010,
    "authEntityId": 10010,
    "displayName": "10010",
    "authEntityClass": 1738953278,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "state": "INACTIVE",
    "type": "NBAR"
  },
  "indicativeNetworkIdentity": [
    {
      "id": "2af39593-46ff-4258-aca7-e6847ecde07e",
      "instanceId": 12189,
      "authEntityId": 12189,
      "displayName": "12189",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "999",
      "protocol": "UDP",
      "upperPort": 0
    },
    {
      "id": "571759dc-a96b-441a-b06a-7a5ede507a0d",
      "instanceId": 12190,
      "authEntityId": 12190,
      "displayName": "12190",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "999",
      "protocol": "TCP",
      "upperPort": 0
    }
  ],
  "networkApplications": [
    {
      "id": "f71498f3-2507-465d-97a5-9cbbc54dbf43",
      "instanceId": 11074,
      "authEntityId": 11074,
      "displayName": "11074",
      "authEntityClass": -217092956,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "appProtocol": "tcp/udp",
      "applicationGroup": "other",
      "applicationType": "DEFAULT",
      "categoryId": "6daa3dfd-3526-407a-88f0-97824b3f3a24",
      "createTime": 1517966741180,
      "encrypted": "false",
      "engineId": "13",
      "helpString": "Applix ac",
      "ignoreConflict": false,
      "lastUpdateTime": 1517966741180,
      "longDescription": "Registered with IANA on port 999 UDP",
      "name": "applix",
      "nbarId": "680",
      "p2pTechnology": "false",
      "popularity": 5,
      "rank": 65535,
      "references": "http://www.iana.org/assignments/service-names-port-numbers/service-names-port-numbers.xml",
      "selectorId": "264",
      "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
      "trafficClass": "BULK_DATA",
      "tunnel": "false"
    }
  ],
  "networkIdentity": [
    {
      "id": "1f5302e6-0831-4574-8242-d88f1b575441",
      "instanceId": 12187,
      "authEntityId": 12187,
      "displayName": "12187",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "999",
      "protocol": "TCP",
      "upperPort": 0
    },
    {
      "id": "3fe954ae-0710-46f5-b56f-57c564111abd",
      "instanceId": 12188,
      "authEntityId": 12188,
      "displayName": "12188",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "999",
      "protocol": "UDP",
      "upperPort": 0
    }
  ],
  "parentScalableGroup": {
    "idRef": "000c15bf-28fe-4fbb-9b69-35e6502721f1"
  },
  "user": [

  ],
  "userGroup": [

  ]
},
{
  "id": "9b6316cb-8c31-4b3d-a01a-c323983275c4",
  "instanceId": 4732,
  "authEntityId": 4732,
  "displayName": "4732",
  "authEntityClass": -1909073334,
  "deployPending": "NONE",
  "instanceVersion": 0,
  "createTime": 1517966743380,
  "deployed": false,
  "isSeeded": true,
  "isStale": false,
  "lastUpdateTime": 1517966743380,
  "name": "mdc-portmapper",
  "namespace": "scalablegroup:application",
  "provisioningState": "DEFINED",
  "qualifier": "application",
  "resourceVersion": 0,
  "targetIdList": [

  ],
  "type": "scalablegroup",
  "cfsChangeInfo": [

  ],
  "customProvisions": [

  ],
  "scalableGroupExternalHandle": "mdc-portmapper",
  "scalableGroupType": "APPLICATION",
  "securityGroupTag": 0,
  "state": "ACTIVE",
  "vnAgnostic": true,
  "identitySource": {
    "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
    "instanceId": 10010,
    "authEntityId": 10010,
    "displayName": "10010",
    "authEntityClass": 1738953278,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "state": "INACTIVE",
    "type": "NBAR"
  },
  "indicativeNetworkIdentity": [
    {
      "id": "d4e9c8bd-86c6-439e-8f73-13bdc54b7a35",
      "instanceId": 13856,
      "authEntityId": 13856,
      "displayName": "13856",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "685",
      "protocol": "TCP",
      "upperPort": 0
    },
    {
      "id": "642e8af9-bd4d-499e-9c76-4e6546b86a2e",
      "instanceId": 13857,
      "authEntityId": 13857,
      "displayName": "13857",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "685",
      "protocol": "UDP",
      "upperPort": 0
    }
  ],
  "networkApplications": [
    {
      "id": "13f68e75-37b3-4a08-8825-822dfa4e5393",
      "instanceId": 11682,
      "authEntityId": 11682,
      "displayName": "11682",
      "authEntityClass": -217092956,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "appProtocol": "tcp/udp",
      "applicationGroup": "other",
      "applicationType": "DEFAULT",
      "categoryId": "6daa3dfd-3526-407a-88f0-97824b3f3a24",
      "createTime": 1517966743380,
      "encrypted": "false",
      "engineId": "3",
      "helpString": "mdc-portmapper",
      "ignoreConflict": false,
      "lastUpdateTime": 1517966743380,
      "longDescription": "Registered with IANA on port 685 TCP/UDP",
      "name": "mdc-portmapper",
      "nbarId": "593",
      "p2pTechnology": "false",
      "popularity": 1,
      "rank": 65535,
      "references": "http://www.iana.org/assignments/service-names-port-numbers/service-names-port-numbers.xml",
      "selectorId": "685",
      "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
      "trafficClass": "BULK_DATA",
      "tunnel": "false"
    }
  ],
  "networkIdentity": [
    {
      "id": "661265e6-c88e-4651-81f7-ca50e8ca564c",
      "instanceId": 13854,
      "authEntityId": 13854,
      "displayName": "13854",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "685",
      "protocol": "UDP",
      "upperPort": 0
    },
    {
      "id": "198a7153-9dfe-4c30-8fd1-494643c99e81",
      "instanceId": 13855,
      "authEntityId": 13855,
      "displayName": "13855",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "685",
      "protocol": "TCP",
      "upperPort": 0
    }
  ],
  "parentScalableGroup": {
    "idRef": "000c15bf-28fe-4fbb-9b69-35e6502721f1"
  },
  "user": [

  ],
  "userGroup": [

  ]
},
{
  "id": "9b846687-062b-4f2c-9230-2957d536c7f8",
  "instanceId": 4572,
  "authEntityId": 4572,
  "displayName": "4572",
  "authEntityClass": -1909073334,
  "deployPending": "NONE",
  "instanceVersion": 0,
  "createTime": 1517966743020,
  "deployed": false,
  "isSeeded": true,
  "isStale": false,
  "lastUpdateTime": 1517966743020,
  "name": "iasd",
  "namespace": "scalablegroup:application",
  "provisioningState": "DEFINED",
  "qualifier": "application",
  "resourceVersion": 0,
  "targetIdList": [

  ],
  "type": "scalablegroup",
  "cfsChangeInfo": [

  ],
  "customProvisions": [

  ],
  "scalableGroupExternalHandle": "iasd",
  "scalableGroupType": "APPLICATION",
  "securityGroupTag": 0,
  "state": "ACTIVE",
  "vnAgnostic": true,
  "identitySource": {
    "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
    "instanceId": 10010,
    "authEntityId": 10010,
    "displayName": "10010",
    "authEntityClass": 1738953278,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "state": "INACTIVE",
    "type": "NBAR"
  },
  "indicativeNetworkIdentity": [
    {
      "id": "bb136454-67f8-4bae-b140-78df09cec8d4",
      "instanceId": 13431,
      "authEntityId": 13431,
      "displayName": "13431",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "432",
      "protocol": "TCP",
      "upperPort": 0
    },
    {
      "id": "edb79910-df4b-4d72-8a35-57815cfd950b",
      "instanceId": 13432,
      "authEntityId": 13432,
      "displayName": "13432",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "432",
      "protocol": "UDP",
      "upperPort": 0
    }
  ],
  "networkApplications": [
    {
      "id": "eeb89af6-cc5e-4eda-b9fe-ed58c1d5fdc5",
      "instanceId": 11522,
      "authEntityId": 11522,
      "displayName": "11522",
      "authEntityClass": -217092956,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "appProtocol": "tcp/udp",
      "applicationGroup": "other",
      "applicationType": "DEFAULT",
      "categoryId": "6daa3dfd-3526-407a-88f0-97824b3f3a24",
      "createTime": 1517966743020,
      "encrypted": "false",
      "engineId": "3",
      "helpString": "iasd",
      "ignoreConflict": false,
      "lastUpdateTime": 1517966743020,
      "longDescription": "Registered with IANA on port 432 TCP/UDP",
      "name": "iasd",
      "nbarId": "347",
      "p2pTechnology": "false",
      "popularity": 1,
      "rank": 65535,
      "references": "http://www.iana.org/assignments/service-names-port-numbers/service-names-port-numbers.xml",
      "selectorId": "432",
      "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
      "trafficClass": "BULK_DATA",
      "tunnel": "false"
    }
  ],
  "networkIdentity": [
    {
      "id": "3d336adb-828e-4f0b-aec9-7e3443eee59d",
      "instanceId": 13429,
      "authEntityId": 13429,
      "displayName": "13429",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "432",
      "protocol": "UDP",
      "upperPort": 0
    },
    {
      "id": "7a6cdc3a-d23d-4a78-8c1c-bdaeb5908657",
      "instanceId": 13430,
      "authEntityId": 13430,
      "displayName": "13430",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "432",
      "protocol": "TCP",
      "upperPort": 0
    }
  ],
  "parentScalableGroup": {
    "idRef": "000c15bf-28fe-4fbb-9b69-35e6502721f1"
  },
  "user": [

  ],
  "userGroup": [

  ]
},
{
  "id": "9b8e8d88-abe5-4cbb-9547-77686b58263f",
  "instanceId": 4523,
  "authEntityId": 4523,
  "displayName": "4523",
  "authEntityClass": -1909073334,
  "deployPending": "NONE",
  "instanceVersion": 0,
  "createTime": 1517966742884,
  "deployed": false,
  "isSeeded": true,
  "isStale": false,
  "lastUpdateTime": 1517966742884,
  "name": "h323",
  "namespace": "scalablegroup:application",
  "provisioningState": "DEFINED",
  "qualifier": "application",
  "resourceVersion": 0,
  "targetIdList": [

  ],
  "type": "scalablegroup",
  "cfsChangeInfo": [

  ],
  "customProvisions": [

  ],
  "scalableGroupExternalHandle": "h323",
  "scalableGroupType": "APPLICATION",
  "securityGroupTag": 0,
  "state": "ACTIVE",
  "vnAgnostic": true,
  "identitySource": {
    "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
    "instanceId": 10010,
    "authEntityId": 10010,
    "displayName": "10010",
    "authEntityClass": 1738953278,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "state": "INACTIVE",
    "type": "NBAR"
  },
  "indicativeNetworkIdentity": [
    {
      "id": "a76cc090-41f8-4b9a-9aa8-d38a1bef1327",
      "instanceId": 13301,
      "authEntityId": 13301,
      "displayName": "13301",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "1300,1718,1719,1720,11720",
      "protocol": "TCP",
      "upperPort": 0
    },
    {
      "id": "1cdc097b-84e4-4364-b48c-9786c93db618",
      "instanceId": 13302,
      "authEntityId": 13302,
      "displayName": "13302",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "1300,1718,1719,1720,11720",
      "protocol": "UDP",
      "upperPort": 0
    }
  ],
  "networkApplications": [
    {
      "id": "ab1f2cf0-a669-467e-8151-22ec73137865",
      "instanceId": 11474,
      "authEntityId": 11474,
      "displayName": "11474",
      "authEntityClass": -217092956,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "appProtocol": "tcp/udp",
      "applicationGroup": "other",
      "applicationType": "DEFAULT",
      "categoryId": "38a8e354-e2fe-4f3c-b1c5-2bbb90fb0d99",
      "createTime": 1517966742884,
      "encrypted": "false",
      "engineId": "13",
      "helpString": "H.323 Protocol",
      "ignoreConflict": false,
      "lastUpdateTime": 1517966742884,
      "longDescription": "H.323 is a recommendation from the ITU Telecommunication Standardization Sector (ITU-T) that defines the protocols to provide audio-visual communication sessions on any packet network. The H.323 standard addresses call signaling and control, multimedia transport and control, and bandwidth control for point-to-point and multi-point conferences.",
      "name": "h323",
      "nbarId": "64",
      "p2pTechnology": "false",
      "popularity": 9,
      "rank": 65535,
      "references": "http://www.h323forum.org/",
      "selectorId": "64",
      "subCategory": "68c4f989-5735-4e16-8d1c-6a9d89d5df9c",
      "trafficClass": "SIGNALING",
      "tunnel": "false"
    }
  ],
  "networkIdentity": [
    {
      "id": "8c8af4e2-e2a9-4218-a5d9-7f741b5df59a",
      "instanceId": 13299,
      "authEntityId": 13299,
      "displayName": "13299",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "1300,1718,1719,1720,11720",
      "protocol": "UDP",
      "upperPort": 0
    },
    {
      "id": "7d982ea8-bf5e-4f41-8bee-a111e8394e69",
      "instanceId": 13300,
      "authEntityId": 13300,
      "displayName": "13300",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "1300,1718,1719,1720,11720",
      "protocol": "TCP",
      "upperPort": 0
    }
  ],
  "parentScalableGroup": {
    "idRef": "a3243a74-3864-4041-a927-0201030da17c"
  },
  "user": [

  ],
  "userGroup": [

  ]
},
{
  "id": "9bacb4b0-cde5-466f-b698-8667373748f8",
  "instanceId": 15140,
  "authEntityId": 15140,
  "displayName": "15140",
  "authEntityClass": -1909073334,
  "deployPending": "NONE",
  "instanceVersion": 0,
  "createTime": 1517966744325,
  "deployed": false,
  "isSeeded": true,
  "isStale": false,
  "lastUpdateTime": 1517966744325,
  "name": "shutterstock",
  "namespace": "scalablegroup:application",
  "provisioningState": "DEFINED",
  "qualifier": "application",
  "resourceVersion": 0,
  "targetIdList": [

  ],
  "type": "scalablegroup",
  "cfsChangeInfo": [

  ],
  "customProvisions": [

  ],
  "scalableGroupExternalHandle": "shutterstock",
  "scalableGroupType": "APPLICATION",
  "securityGroupTag": 0,
  "state": "ACTIVE",
  "vnAgnostic": true,
  "identitySource": {
    "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
    "instanceId": 10010,
    "authEntityId": 10010,
    "displayName": "10010",
    "authEntityClass": 1738953278,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "state": "INACTIVE",
    "type": "NBAR"
  },
  "indicativeNetworkIdentity": [

  ],
  "networkApplications": [
    {
      "id": "23acb6c5-d2d5-42fe-ba21-07b2c8310b03",
      "instanceId": 16083,
      "authEntityId": 16083,
      "displayName": "16083",
      "authEntityClass": -217092956,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "appProtocol": "tcp/udp",
      "applicationGroup": "other",
      "applicationType": "DEFAULT",
      "categoryId": "878644b5-fe91-45b4-9204-31c84a73ba48",
      "createTime": 1517966744325,
      "encrypted": "false",
      "engineId": "13",
      "helpString": "Shutterstock - Online collection of Stock photographs and illustrations.",
      "ignoreConflict": false,
      "lastUpdateTime": 1517966744325,
      "longDescription": "Shutterstock - Online collection of Stock photographs and illustrations.",
      "name": "shutterstock",
      "nbarId": "1592",
      "p2pTechnology": "false",
      "popularity": 3,
      "rank": 65535,
      "references": "http://shutterstock.com",
      "selectorId": "663",
      "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
      "trafficClass": "TRANSACTIONAL_DATA",
      "tunnel": "false"
    }
  ],
  "networkIdentity": [
    {
      "id": "3bcdd96f-1aa9-476b-a0a8-954192c39859",
      "instanceId": 17066,
      "authEntityId": 17066,
      "displayName": "17066",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "53,5353",
      "protocol": "UDP",
      "upperPort": 0
    },
    {
      "id": "21e324ef-0857-4f60-beb3-5c7becc7c66f",
      "instanceId": 17067,
      "authEntityId": 17067,
      "displayName": "17067",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "53,80,443,5353",
      "protocol": "TCP",
      "upperPort": 0
    }
  ],
  "parentScalableGroup": {
    "idRef": "28e08de4-d7b2-4521-84ce-bc1e5ee0b9cc"
  },
  "user": [

  ],
  "userGroup": [

  ]
},
{
  "id": "9be5bd35-50d2-427f-9fbf-52b3eb607a6a",
  "instanceId": 4182,
  "authEntityId": 4182,
  "displayName": "4182",
  "authEntityClass": -1909073334,
  "deployPending": "NONE",
  "instanceVersion": 0,
  "createTime": 1517966741567,
  "deployed": false,
  "isSeeded": true,
  "isStale": false,
  "lastUpdateTime": 1517966741567,
  "name": "bitcoin",
  "namespace": "scalablegroup:application",
  "provisioningState": "DEFINED",
  "qualifier": "application",
  "resourceVersion": 0,
  "targetIdList": [

  ],
  "type": "scalablegroup",
  "cfsChangeInfo": [

  ],
  "customProvisions": [

  ],
  "scalableGroupExternalHandle": "bitcoin",
  "scalableGroupType": "APPLICATION",
  "securityGroupTag": 0,
  "state": "ACTIVE",
  "vnAgnostic": true,
  "identitySource": {
    "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
    "instanceId": 10010,
    "authEntityId": 10010,
    "displayName": "10010",
    "authEntityClass": 1738953278,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "state": "INACTIVE",
    "type": "NBAR"
  },
  "indicativeNetworkIdentity": [

  ],
  "networkApplications": [
    {
      "id": "fb8287ec-8a27-4099-98f7-eb85cf7bd7af",
      "instanceId": 11141,
      "authEntityId": 11141,
      "displayName": "11141",
      "authEntityClass": -217092956,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "applicationGroup": "other",
      "applicationType": "DEFAULT",
      "categoryId": "91a59054-00c9-4445-96e9-4cb77e86dcd7",
      "createTime": 1517966741567,
      "encrypted": "true",
      "engineId": "13",
      "helpString": "Bitcoin cryptocurrency",
      "ignoreConflict": false,
      "lastUpdateTime": 1517966741567,
      "longDescription": "Bitcoin is a worldwide cryptocurrency and digital payment system.",
      "name": "bitcoin",
      "nbarId": "1125",
      "p2pTechnology": "true",
      "popularity": 6,
      "rank": 65535,
      "references": "https://en.wikipedia.org/wiki/Bitcoin",
      "selectorId": "1125",
      "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
      "trafficClass": "TRANSACTIONAL_DATA",
      "tunnel": "false"
    }
  ],
  "networkIdentity": [

  ],
  "parentScalableGroup": {
    "idRef": "5e66d25d-d517-482b-82a8-6f99f07fa786"
  },
  "user": [

  ],
  "userGroup": [

  ]
},
{
  "id": "9c284d2a-51a9-4769-9e3c-deed283764a3",
  "instanceId": 4277,
  "authEntityId": 4277,
  "displayName": "4277",
  "authEntityClass": -1909073334,
  "deployPending": "NONE",
  "instanceVersion": 0,
  "createTime": 1517966742047,
  "deployed": false,
  "isSeeded": true,
  "isStale": false,
  "lastUpdateTime": 1517966742047,
  "name": "comscm",
  "namespace": "scalablegroup:application",
  "provisioningState": "DEFINED",
  "qualifier": "application",
  "resourceVersion": 0,
  "targetIdList": [

  ],
  "type": "scalablegroup",
  "cfsChangeInfo": [

  ],
  "customProvisions": [

  ],
  "scalableGroupExternalHandle": "comscm",
  "scalableGroupType": "APPLICATION",
  "securityGroupTag": 0,
  "state": "ACTIVE",
  "vnAgnostic": true,
  "identitySource": {
    "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
    "instanceId": 10010,
    "authEntityId": 10010,
    "displayName": "10010",
    "authEntityClass": 1738953278,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "state": "INACTIVE",
    "type": "NBAR"
  },
  "indicativeNetworkIdentity": [
    {
      "id": "31ace525-db2a-4104-9599-d691bb79d6dc",
      "instanceId": 12608,
      "authEntityId": 12608,
      "displayName": "12608",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "437",
      "protocol": "UDP",
      "upperPort": 0
    },
    {
      "id": "89955623-f862-435e-801e-84eeafcc105d",
      "instanceId": 12609,
      "authEntityId": 12609,
      "displayName": "12609",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "437",
      "protocol": "TCP",
      "upperPort": 0
    }
  ],
  "networkApplications": [
    {
      "id": "cdf92cc5-ba15-4d63-86a9-28db96303ac5",
      "instanceId": 11230,
      "authEntityId": 11230,
      "displayName": "11230",
      "authEntityClass": -217092956,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "appProtocol": "tcp/udp",
      "applicationGroup": "other",
      "applicationType": "DEFAULT",
      "categoryId": "6daa3dfd-3526-407a-88f0-97824b3f3a24",
      "createTime": 1517966742047,
      "encrypted": "false",
      "engineId": "3",
      "helpString": "comscm",
      "ignoreConflict": false,
      "lastUpdateTime": 1517966742047,
      "longDescription": "Registered with IANA on port 437 TCP/UDP",
      "name": "comscm",
      "nbarId": "352",
      "p2pTechnology": "false",
      "popularity": 1,
      "rank": 65535,
      "references": "http://www.iana.org/assignments/service-names-port-numbers/service-names-port-numbers.xml",
      "selectorId": "437",
      "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
      "trafficClass": "BULK_DATA",
      "tunnel": "false"
    }
  ],
  "networkIdentity": [
    {
      "id": "0a2232ca-0e55-4822-b330-3909fb279852",
      "instanceId": 12606,
      "authEntityId": 12606,
      "displayName": "12606",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "437",
      "protocol": "TCP",
      "upperPort": 0
    },
    {
      "id": "083e134d-5396-4ba9-8fa3-376524a959ec",
      "instanceId": 12607,
      "authEntityId": 12607,
      "displayName": "12607",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "437",
      "protocol": "UDP",
      "upperPort": 0
    }
  ],
  "parentScalableGroup": {
    "idRef": "000c15bf-28fe-4fbb-9b69-35e6502721f1"
  },
  "user": [

  ],
  "userGroup": [

  ]
},
{
  "id": "9c28e0c2-1b61-4e15-bec5-242a0dd7f7c1",
  "instanceId": 4204,
  "authEntityId": 4204,
  "displayName": "4204",
  "authEntityClass": -1909073334,
  "deployPending": "NONE",
  "instanceVersion": 0,
  "createTime": 1517966741715,
  "deployed": false,
  "isSeeded": true,
  "isStale": false,
  "lastUpdateTime": 1517966741715,
  "name": "buzzfeed",
  "namespace": "scalablegroup:application",
  "provisioningState": "DEFINED",
  "qualifier": "application",
  "resourceVersion": 0,
  "targetIdList": [

  ],
  "type": "scalablegroup",
  "cfsChangeInfo": [

  ],
  "customProvisions": [

  ],
  "scalableGroupExternalHandle": "buzzfeed",
  "scalableGroupType": "APPLICATION",
  "securityGroupTag": 0,
  "state": "ACTIVE",
  "vnAgnostic": true,
  "identitySource": {
    "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
    "instanceId": 10010,
    "authEntityId": 10010,
    "displayName": "10010",
    "authEntityClass": 1738953278,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "state": "INACTIVE",
    "type": "NBAR"
  },
  "indicativeNetworkIdentity": [

  ],
  "networkApplications": [
    {
      "id": "16345e1e-84bd-4a7b-906f-93e23e4578e5",
      "instanceId": 11160,
      "authEntityId": 11160,
      "displayName": "11160",
      "authEntityClass": -217092956,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "appProtocol": "tcp/udp",
      "applicationGroup": "other",
      "applicationType": "DEFAULT",
      "categoryId": "878644b5-fe91-45b4-9204-31c84a73ba48",
      "createTime": 1517966741715,
      "encrypted": "false",
      "engineId": "13",
      "helpString": "BuzzFeed - News portal.",
      "ignoreConflict": false,
      "lastUpdateTime": 1517966741715,
      "longDescription": "BuzzFeed - News portal.",
      "name": "buzzfeed",
      "nbarId": "1555",
      "p2pTechnology": "false",
      "popularity": 3,
      "rank": 65535,
      "references": "http://buzzfed.com",
      "selectorId": "626",
      "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
      "trafficClass": "TRANSACTIONAL_DATA",
      "tunnel": "false"
    }
  ],
  "networkIdentity": [
    {
      "id": "7a010101-6cd5-4219-ba8a-94d245139d30",
      "instanceId": 12439,
      "authEntityId": 12439,
      "displayName": "12439",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "53,80,443,5353",
      "protocol": "TCP",
      "upperPort": 0
    },
    {
      "id": "f3d34167-7269-4bdb-84fd-4589d5e3dbbb",
      "instanceId": 12440,
      "authEntityId": 12440,
      "displayName": "12440",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "53,5353",
      "protocol": "UDP",
      "upperPort": 0
    }
  ],
  "parentScalableGroup": {
    "idRef": "28e08de4-d7b2-4521-84ce-bc1e5ee0b9cc"
  },
  "user": [

  ],
  "userGroup": [

  ]
},
{
  "id": "9c428330-c593-4318-90a2-7b82faacf970",
  "instanceId": 15375,
  "authEntityId": 15375,
  "displayName": "15375",
  "authEntityClass": -1909073334,
  "deployPending": "NONE",
  "instanceVersion": 0,
  "createTime": 1517966744832,
  "deployed": false,
  "isSeeded": true,
  "isStale": false,
  "lastUpdateTime": 1517966744832,
  "name": "wap-push-http",
  "namespace": "scalablegroup:application",
  "provisioningState": "DEFINED",
  "qualifier": "application",
  "resourceVersion": 0,
  "targetIdList": [

  ],
  "type": "scalablegroup",
  "cfsChangeInfo": [

  ],
  "customProvisions": [

  ],
  "scalableGroupExternalHandle": "wap-push-http",
  "scalableGroupType": "APPLICATION",
  "securityGroupTag": 0,
  "state": "ACTIVE",
  "vnAgnostic": true,
  "identitySource": {
    "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
    "instanceId": 10010,
    "authEntityId": 10010,
    "displayName": "10010",
    "authEntityClass": 1738953278,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "state": "INACTIVE",
    "type": "NBAR"
  },
  "indicativeNetworkIdentity": [
    {
      "id": "d2781703-a517-41e8-9838-148109724704",
      "instanceId": 17744,
      "authEntityId": 17744,
      "displayName": "17744",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "4035",
      "protocol": "UDP",
      "upperPort": 0
    },
    {
      "id": "057862bd-ee68-48be-a639-c4b4150183f7",
      "instanceId": 17743,
      "authEntityId": 17743,
      "displayName": "17743",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "4035",
      "protocol": "TCP",
      "upperPort": 0
    }
  ],
  "networkApplications": [
    {
      "id": "a442536b-5289-415b-b487-2c19836e55c1",
      "instanceId": 16318,
      "authEntityId": 16318,
      "displayName": "16318",
      "authEntityClass": -217092956,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "appProtocol": "tcp/udp",
      "applicationGroup": "wap-group",
      "applicationType": "DEFAULT",
      "categoryId": "6daa3dfd-3526-407a-88f0-97824b3f3a24",
      "createTime": 1517966744832,
      "encrypted": "false",
      "engineId": "3",
      "helpString": "WAP Push OTA-HTTP port",
      "ignoreConflict": false,
      "lastUpdateTime": 1517966744832,
      "longDescription": "WAP-Push OTA-HTTP is used for asynchronous communication between a PPG (Push Proxy Gateway) and a WAP client, utilizing HTTP services.",
      "name": "wap-push-http",
      "nbarId": "727",
      "p2pTechnology": "false",
      "popularity": 2,
      "rank": 65535,
      "references": "http://www.openmobilealliance.org/tech/affiliates/wap/wap-235-pushota-20010425-a.pdf",
      "selectorId": "4035",
      "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
      "trafficClass": "BULK_DATA",
      "tunnel": "false"
    }
  ],
  "networkIdentity": [
    {
      "id": "af522093-effc-4d26-a63f-fe83ad9b471e",
      "instanceId": 17741,
      "authEntityId": 17741,
      "displayName": "17741",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "4035",
      "protocol": "UDP",
      "upperPort": 0
    },
    {
      "id": "0b70ee25-c995-4f78-a379-a7216a6ead4b",
      "instanceId": 17742,
      "authEntityId": 17742,
      "displayName": "17742",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "4035",
      "protocol": "TCP",
      "upperPort": 0
    }
  ],
  "parentScalableGroup": {
    "idRef": "000c15bf-28fe-4fbb-9b69-35e6502721f1"
  },
  "user": [

  ],
  "userGroup": [

  ]
},
{
  "id": "9c93e7d4-00fd-4289-800a-0ba311e04565",
  "instanceId": 4040,
  "authEntityId": 4040,
  "displayName": "4040",
  "authEntityClass": -1909073334,
  "deployPending": "NONE",
  "instanceVersion": 0,
  "createTime": 1517966740537,
  "deployed": false,
  "isSeeded": true,
  "isStale": false,
  "lastUpdateTime": 1517966740537,
  "name": "58-city",
  "namespace": "scalablegroup:application",
  "provisioningState": "DEFINED",
  "qualifier": "application",
  "resourceVersion": 0,
  "targetIdList": [

  ],
  "type": "scalablegroup",
  "cfsChangeInfo": [

  ],
  "customProvisions": [

  ],
  "scalableGroupExternalHandle": "58-city",
  "scalableGroupType": "APPLICATION",
  "securityGroupTag": 0,
  "state": "ACTIVE",
  "vnAgnostic": true,
  "identitySource": {
    "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
    "instanceId": 10010,
    "authEntityId": 10010,
    "displayName": "10010",
    "authEntityClass": 1738953278,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "state": "INACTIVE",
    "type": "NBAR"
  },
  "indicativeNetworkIdentity": [

  ],
  "networkApplications": [
    {
      "id": "1437bd58-c757-454e-bbfc-fe8d5967545f",
      "instanceId": 11015,
      "authEntityId": 11015,
      "displayName": "11015",
      "authEntityClass": -217092956,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "appProtocol": "tcp/udp",
      "applicationGroup": "other",
      "applicationType": "DEFAULT",
      "categoryId": "878644b5-fe91-45b4-9204-31c84a73ba48",
      "createTime": 1517966740537,
      "encrypted": "false",
      "engineId": "13",
      "helpString": "58 City - Classified information about 58 cities in China.",
      "ignoreConflict": false,
      "lastUpdateTime": 1517966740537,
      "longDescription": "58 City - Classified information about 58 cities in China.",
      "name": "58-city",
      "nbarId": "1634",
      "p2pTechnology": "false",
      "popularity": 3,
      "rank": 65535,
      "references": "http://58.com",
      "selectorId": "704",
      "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
      "trafficClass": "TRANSACTIONAL_DATA",
      "tunnel": "false"
    }
  ],
  "networkIdentity": [
    {
      "id": "339e83c5-4e08-4571-b9cb-d19a137d3d0c",
      "instanceId": 12024,
      "authEntityId": 12024,
      "displayName": "12024",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "53,80,443,5353",
      "protocol": "TCP",
      "upperPort": 0
    },
    {
      "id": "9bb3c197-899f-4c72-9903-96c678ff8b93",
      "instanceId": 12025,
      "authEntityId": 12025,
      "displayName": "12025",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "53,5353",
      "protocol": "UDP",
      "upperPort": 0
    }
  ],
  "parentScalableGroup": {
    "idRef": "28e08de4-d7b2-4521-84ce-bc1e5ee0b9cc"
  },
  "user": [

  ],
  "userGroup": [

  ]
},


{
  "id": "9cae0601-4865-4339-acf2-5e209d1ebfee",
  "instanceId": 4603,
  "authEntityId": 4603,
  "displayName": "4603",
  "authEntityClass": -1909073334,
  "deployPending": "NONE",
  "instanceVersion": 0,
  "createTime": 1517966743097,
  "deployed": false,
  "isSeeded": true,
  "isStale": false,
  "lastUpdateTime": 1517966743097,
  "name": "infoseek",
  "namespace": "scalablegroup:application",
  "provisioningState": "DEFINED",
  "qualifier": "application",
  "resourceVersion": 0,
  "targetIdList": [

  ],
  "type": "scalablegroup",
  "cfsChangeInfo": [

  ],
  "customProvisions": [

  ],
  "scalableGroupExternalHandle": "infoseek",
  "scalableGroupType": "APPLICATION",
  "securityGroupTag": 0,
  "state": "ACTIVE",
  "vnAgnostic": true,
  "identitySource": {
    "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
    "instanceId": 10010,
    "authEntityId": 10010,
    "displayName": "10010",
    "authEntityClass": 1738953278,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "state": "INACTIVE",
    "type": "NBAR"
  },
  "indicativeNetworkIdentity": [
    {
      "id": "2a4fae4d-3b4d-4a7e-bd71-d874dab87959",
      "instanceId": 13521,
      "authEntityId": 13521,
      "displayName": "13521",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "414",
      "protocol": "TCP",
      "upperPort": 0
    },
    {
      "id": "e2bcef87-b279-4e78-bc98-8915c6987b12",
      "instanceId": 13522,
      "authEntityId": 13522,
      "displayName": "13522",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "414",
      "protocol": "UDP",
      "upperPort": 0
    }
  ],
  "networkApplications": [
    {
      "id": "6244bf4c-6c85-42c9-8428-227b82fdd758",
      "instanceId": 11553,
      "authEntityId": 11553,
      "displayName": "11553",
      "authEntityClass": -217092956,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "appProtocol": "tcp/udp",
      "applicationGroup": "other",
      "applicationType": "DEFAULT",
      "categoryId": "6daa3dfd-3526-407a-88f0-97824b3f3a24",
      "createTime": 1517966743097,
      "encrypted": "false",
      "engineId": "3",
      "helpString": "InfoSeek",
      "ignoreConflict": false,
      "lastUpdateTime": 1517966743097,
      "longDescription": "A popular search engine founded in 1994, bought later by The Walt Disney Company, now it's no longer in use",
      "name": "infoseek",
      "nbarId": "329",
      "p2pTechnology": "false",
      "popularity": 1,
      "rank": 65535,
      "references": "http://en.wikipedia.org/wiki/Infoseek",
      "selectorId": "414",
      "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
      "trafficClass": "BULK_DATA",
      "tunnel": "false"
    }
  ],
  "networkIdentity": [
    {
      "id": "f19a0e50-3bcf-4fff-a869-ae53bd911410",
      "instanceId": 13520,
      "authEntityId": 13520,
      "displayName": "13520",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "414",
      "protocol": "TCP",
      "upperPort": 0
    },
    {
      "id": "bd555d72-ebc3-4b5e-9e92-6f9274c16df9",
      "instanceId": 13519,
      "authEntityId": 13519,
      "displayName": "13519",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "414",
      "protocol": "UDP",
      "upperPort": 0
    }
  ],
  "parentScalableGroup": {
    "idRef": "000c15bf-28fe-4fbb-9b69-35e6502721f1"
  },
  "user": [

  ],
  "userGroup": [

  ]
},
{
  "id": "9cb5bfe7-901e-4a53-8188-52e356a225cc",
  "instanceId": 15404,
  "authEntityId": 15404,
  "displayName": "15404",
  "authEntityClass": -1909073334,
  "deployPending": "NONE",
  "instanceVersion": 0,
  "createTime": 1517966744883,
  "deployed": false,
  "isSeeded": true,
  "isStale": false,
  "lastUpdateTime": 1517966744883,
  "name": "websense",
  "namespace": "scalablegroup:application",
  "provisioningState": "DEFINED",
  "qualifier": "application",
  "resourceVersion": 0,
  "targetIdList": [

  ],
  "type": "scalablegroup",
  "cfsChangeInfo": [

  ],
  "customProvisions": [

  ],
  "scalableGroupExternalHandle": "websense",
  "scalableGroupType": "APPLICATION",
  "securityGroupTag": 0,
  "state": "ACTIVE",
  "vnAgnostic": true,
  "identitySource": {
    "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
    "instanceId": 10010,
    "authEntityId": 10010,
    "displayName": "10010",
    "authEntityClass": 1738953278,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "state": "INACTIVE",
    "type": "NBAR"
  },
  "indicativeNetworkIdentity": [
    {
      "id": "8bb951a7-265c-4640-bba7-2b7aa3d2b8bd",
      "instanceId": 17817,
      "authEntityId": 17817,
      "displayName": "17817",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "15868,15871",
      "protocol": "TCP",
      "upperPort": 0
    }
  ],
  "networkApplications": [
    {
      "id": "7a42a8f7-3582-42b7-b2ec-3c5bf1c7789b",
      "instanceId": 16347,
      "authEntityId": 16347,
      "displayName": "16347",
      "authEntityClass": -217092956,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "appProtocol": "tcp",
      "applicationGroup": "other",
      "applicationType": "DEFAULT",
      "categoryId": "1fc17362-9334-4c7b-b752-5afdbb574017",
      "createTime": 1517966744883,
      "encrypted": "false",
      "engineId": "3",
      "helpString": "Web filtering software",
      "ignoreConflict": false,
      "lastUpdateTime": 1517966744883,
      "longDescription": "Websense is a company specializing in web filtering software.  Their software is used by customers to protect their networks from unwanted or inappropriate usage.",
      "name": "websense",
      "nbarId": "1369",
      "p2pTechnology": "false",
      "popularity": 2,
      "rank": 65535,
      "references": "http://www.websense.com/",
      "selectorId": "15868",
      "subCategory": "f3c0f761-d894-4112-ab89-b90fc84fd660",
      "trafficClass": "OPS_ADMIN_MGMT",
      "tunnel": "false"
    }
  ],
  "networkIdentity": [
    {
      "id": "419967dc-f509-4941-a42e-e3b212b0f820",
      "instanceId": 17816,
      "authEntityId": 17816,
      "displayName": "17816",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "15868,15871",
      "protocol": "TCP",
      "upperPort": 0
    }
  ],
  "parentScalableGroup": {
    "idRef": "5fcd74d4-8726-47f7-a088-c457863da7ef"
  },
  "user": [

  ],
  "userGroup": [

  ]
},
{
  "id": "9d448d1c-ddeb-4afb-bf2e-58e0ba2a8d38",
  "instanceId": 4339,
  "authEntityId": 4339,
  "displayName": "4339",
  "authEntityClass": -1909073334,
  "deployPending": "NONE",
  "instanceVersion": 0,
  "createTime": 1517966742335,
  "deployed": false,
  "isSeeded": true,
  "isStale": false,
  "lastUpdateTime": 1517966742335,
  "name": "ddm-rdb",
  "namespace": "scalablegroup:application",
  "provisioningState": "DEFINED",
  "qualifier": "application",
  "resourceVersion": 0,
  "targetIdList": [

  ],
  "type": "scalablegroup",
  "cfsChangeInfo": [

  ],
  "customProvisions": [

  ],
  "scalableGroupExternalHandle": "ddm-rdb",
  "scalableGroupType": "APPLICATION",
  "securityGroupTag": 0,
  "state": "ACTIVE",
  "vnAgnostic": true,
  "identitySource": {
    "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
    "instanceId": 10010,
    "authEntityId": 10010,
    "displayName": "10010",
    "authEntityClass": 1738953278,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "state": "INACTIVE",
    "type": "NBAR"
  },
  "indicativeNetworkIdentity": [
    {
      "id": "95e5e030-ef2f-4047-a363-b374ebf172a8",
      "instanceId": 12792,
      "authEntityId": 12792,
      "displayName": "12792",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "446",
      "protocol": "UDP",
      "upperPort": 0
    },
    {
      "id": "68dc0a4d-f551-4c55-895c-f41140bbea9b",
      "instanceId": 12793,
      "authEntityId": 12793,
      "displayName": "12793",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "446",
      "protocol": "TCP",
      "upperPort": 0
    }
  ],
  "networkApplications": [
    {
      "id": "7b729e59-41fe-41c2-81c3-0fbff7513484",
      "instanceId": 11290,
      "authEntityId": 11290,
      "displayName": "11290",
      "authEntityClass": -217092956,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "appProtocol": "tcp/udp",
      "applicationGroup": "other",
      "applicationType": "DEFAULT",
      "categoryId": "cb5915d1-afec-4c30-ac9e-069ff382272f",
      "createTime": 1517966742334,
      "encrypted": "false",
      "engineId": "3",
      "helpString": "DDM-Remote Relational Database Access",
      "ignoreConflict": false,
      "lastUpdateTime": 1517966742334,
      "longDescription": "Distributed Data Management (DDM) is a function of the operating system that allows an application program or user on one system to use database files stored on remote systems. The systems must be connected by a communications network, and the remote systems must also be using DDM. With distributed relational database, information the application requester (AR) needs to connect to a database is provided in the relational database directory.",
      "name": "ddm-rdb",
      "nbarId": "361",
      "p2pTechnology": "false",
      "popularity": 1,
      "rank": 65535,
      "references": "http://publib.boulder.ibm.com/infocenter/iseries/v5r4/index.jsp?topic=%2Frzajt%2Frzajtrzajtddm.htm",
      "selectorId": "446",
      "subCategory": "b90786f4-0190-45b8-9924-21b3e9682590",
      "trafficClass": "TRANSACTIONAL_DATA",
      "tunnel": "false"
    }
  ],
  "networkIdentity": [
    {
      "id": "71357287-188c-4fc7-aa4e-c8e28f9d4689",
      "instanceId": 12790,
      "authEntityId": 12790,
      "displayName": "12790",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "446",
      "protocol": "UDP",
      "upperPort": 0
    },
    {
      "id": "54dd9eb7-aee1-4057-9d84-2f2f31e59e2a",
      "instanceId": 12791,
      "authEntityId": 12791,
      "displayName": "12791",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "446",
      "protocol": "TCP",
      "upperPort": 0
    }
  ],
  "parentScalableGroup": {
    "idRef": "8718395e-683e-4a2d-a232-100202751271"
  },
  "user": [

  ],
  "userGroup": [

  ]
},
{
  "id": "9d570472-eecb-4590-9d9a-a841a1346523",
  "instanceId": 15122,
  "authEntityId": 15122,
  "displayName": "15122",
  "authEntityClass": -1909073334,
  "deployPending": "NONE",
  "instanceVersion": 0,
  "createTime": 1517966744284,
  "deployed": false,
  "isSeeded": true,
  "isStale": false,
  "lastUpdateTime": 1517966744284,
  "name": "server-ipx",
  "namespace": "scalablegroup:application",
  "provisioningState": "DEFINED",
  "qualifier": "application",
  "resourceVersion": 0,
  "targetIdList": [

  ],
  "type": "scalablegroup",
  "cfsChangeInfo": [

  ],
  "customProvisions": [

  ],
  "scalableGroupExternalHandle": "server-ipx",
  "scalableGroupType": "APPLICATION",
  "securityGroupTag": 0,
  "state": "ACTIVE",
  "vnAgnostic": true,
  "identitySource": {
    "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
    "instanceId": 10010,
    "authEntityId": 10010,
    "displayName": "10010",
    "authEntityClass": 1738953278,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "state": "INACTIVE",
    "type": "NBAR"
  },
  "indicativeNetworkIdentity": [
    {
      "id": "854d370e-946e-4042-b9ad-542e8711b740",
      "instanceId": 15008,
      "authEntityId": 15008,
      "displayName": "15008",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "213",
      "protocol": "UDP",
      "upperPort": 0
    },
    {
      "id": "04eceb80-c56c-448e-a367-ebe9e1e7cd31",
      "instanceId": 15009,
      "authEntityId": 15009,
      "displayName": "15009",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "213",
      "protocol": "TCP",
      "upperPort": 0
    }
  ],
  "networkApplications": [
    {
      "id": "e80423ba-b5c8-4b11-b3b3-63bef8db03f2",
      "instanceId": 16065,
      "authEntityId": 16065,
      "displayName": "16065",
      "authEntityClass": -217092956,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "appProtocol": "tcp/udp",
      "applicationGroup": "other",
      "applicationType": "DEFAULT",
      "categoryId": "1fc17362-9334-4c7b-b752-5afdbb574017",
      "createTime": 1517966744284,
      "encrypted": "false",
      "engineId": "3",
      "helpString": "Internetwork Packet Exchange Protocol",
      "ignoreConflict": false,
      "lastUpdateTime": 1517966744284,
      "longDescription": "Internetwork Packet Exchange (IPX) is the OSI-model Network layer protocol in the IPX/SPX protocol stack. IPX and SPX are networking protocols used primarily on networks using the Novell NetWare operating systems.",
      "name": "server-ipx",
      "nbarId": "108",
      "p2pTechnology": "false",
      "popularity": 1,
      "rank": 65535,
      "references": "http://tools.ietf.org/html/rfc1132",
      "selectorId": "213",
      "subCategory": "f3c0f761-d894-4112-ab89-b90fc84fd660",
      "trafficClass": "OPS_ADMIN_MGMT",
      "tunnel": "false"
    }
  ],
  "networkIdentity": [
    {
      "id": "783def7b-d3a2-446b-9350-5fbc887e8078",
      "instanceId": 15006,
      "authEntityId": 15006,
      "displayName": "15006",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "213",
      "protocol": "TCP",
      "upperPort": 0
    },
    {
      "id": "684c5e9f-92cd-4a09-a9af-dd904806bd58",
      "instanceId": 15007,
      "authEntityId": 15007,
      "displayName": "15007",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "213",
      "protocol": "UDP",
      "upperPort": 0
    }
  ],
  "parentScalableGroup": {
    "idRef": "5fcd74d4-8726-47f7-a088-c457863da7ef"
  },
  "user": [

  ],
  "userGroup": [

  ]
},
{
  "id": "9d66d6da-5bc1-4ca5-a0ae-cf97eab01758",
  "instanceId": 15163,
  "authEntityId": 15163,
  "displayName": "15163",
  "authEntityClass": -1909073334,
  "deployPending": "NONE",
  "instanceVersion": 0,
  "createTime": 1517966744378,
  "deployed": false,
  "isSeeded": true,
  "isStale": false,
  "lastUpdateTime": 1517966744378,
  "name": "smartsdp",
  "namespace": "scalablegroup:application",
  "provisioningState": "DEFINED",
  "qualifier": "application",
  "resourceVersion": 0,
  "targetIdList": [

  ],
  "type": "scalablegroup",
  "cfsChangeInfo": [

  ],
  "customProvisions": [

  ],
  "scalableGroupExternalHandle": "smartsdp",
  "scalableGroupType": "APPLICATION",
  "securityGroupTag": 0,
  "state": "ACTIVE",
  "vnAgnostic": true,
  "identitySource": {
    "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
    "instanceId": 10010,
    "authEntityId": 10010,
    "displayName": "10010",
    "authEntityClass": 1738953278,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "state": "INACTIVE",
    "type": "NBAR"
  },
  "indicativeNetworkIdentity": [
    {
      "id": "c155de0c-edd9-4de1-908a-f0a1dca2e0d4",
      "instanceId": 17136,
      "authEntityId": 17136,
      "displayName": "17136",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "426",
      "protocol": "TCP",
      "upperPort": 0
    },
    {
      "id": "edfc1ed2-3875-41ad-bde7-eca3e67503c6",
      "instanceId": 17135,
      "authEntityId": 17135,
      "displayName": "17135",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "426",
      "protocol": "UDP",
      "upperPort": 0
    }
  ],
  "networkApplications": [
    {
      "id": "7c9c6258-4518-4893-a406-31294f5a0cad",
      "instanceId": 16106,
      "authEntityId": 16106,
      "displayName": "16106",
      "authEntityClass": -217092956,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "appProtocol": "tcp/udp",
      "applicationGroup": "other",
      "applicationType": "DEFAULT",
      "categoryId": "6daa3dfd-3526-407a-88f0-97824b3f3a24",
      "createTime": 1517966744378,
      "encrypted": "false",
      "engineId": "3",
      "helpString": "smartsdp",
      "ignoreConflict": false,
      "lastUpdateTime": 1517966744378,
      "longDescription": "Registered with IANA on port 426 TCP/UDP",
      "name": "smartsdp",
      "nbarId": "341",
      "p2pTechnology": "false",
      "popularity": 1,
      "rank": 65535,
      "references": "http://www.iana.org/assignments/service-names-port-numbers/service-names-port-numbers.xml",
      "selectorId": "426",
      "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
      "trafficClass": "BULK_DATA",
      "tunnel": "false"
    }
  ],
  "networkIdentity": [
    {
      "id": "2f4e2d1e-edb2-4cf4-857b-4038de4dec58",
      "instanceId": 17133,
      "authEntityId": 17133,
      "displayName": "17133",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "426",
      "protocol": "UDP",
      "upperPort": 0
    },
    {
      "id": "e9579ca2-011f-4bce-8c78-7a96f102bc46",
      "instanceId": 17134,
      "authEntityId": 17134,
      "displayName": "17134",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "426",
      "protocol": "TCP",
      "upperPort": 0
    }
  ],
  "parentScalableGroup": {
    "idRef": "000c15bf-28fe-4fbb-9b69-35e6502721f1"
  },
  "user": [

  ],
  "userGroup": [

  ]
},
{
  "id": "9d728526-18f0-4a89-ac18-9b92d7ae3be5",
  "instanceId": 4998,
  "authEntityId": 4998,
  "displayName": "4998",
  "authEntityClass": -1909073334,
  "deployPending": "NONE",
  "instanceVersion": 0,
  "createTime": 1517966744013,
  "deployed": false,
  "isSeeded": true,
  "isStale": false,
  "lastUpdateTime": 1517966744013,
  "name": "purenoise",
  "namespace": "scalablegroup:application",
  "provisioningState": "DEFINED",
  "qualifier": "application",
  "resourceVersion": 0,
  "targetIdList": [

  ],
  "type": "scalablegroup",
  "cfsChangeInfo": [

  ],
  "customProvisions": [

  ],
  "scalableGroupExternalHandle": "purenoise",
  "scalableGroupType": "APPLICATION",
  "securityGroupTag": 0,
  "state": "ACTIVE",
  "vnAgnostic": true,
  "identitySource": {
    "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
    "instanceId": 10010,
    "authEntityId": 10010,
    "displayName": "10010",
    "authEntityClass": 1738953278,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "state": "INACTIVE",
    "type": "NBAR"
  },
  "indicativeNetworkIdentity": [
    {
      "id": "1268ba16-d511-48af-a0de-f331603776f5",
      "instanceId": 14652,
      "authEntityId": 14652,
      "displayName": "14652",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "663",
      "protocol": "UDP",
      "upperPort": 0
    },
    {
      "id": "2dcf98d2-ee0f-4a4d-b8aa-f6db7e956eae",
      "instanceId": 14653,
      "authEntityId": 14653,
      "displayName": "14653",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "663",
      "protocol": "TCP",
      "upperPort": 0
    }
  ],
  "networkApplications": [
    {
      "id": "d6cd86f9-3730-4c88-a39b-e0118e512c0b",
      "instanceId": 11947,
      "authEntityId": 11947,
      "displayName": "11947",
      "authEntityClass": -217092956,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "appProtocol": "tcp/udp",
      "applicationGroup": "other",
      "applicationType": "DEFAULT",
      "categoryId": "1fc17362-9334-4c7b-b752-5afdbb574017",
      "createTime": 1517966744013,
      "encrypted": "true",
      "engineId": "3",
      "helpString": "purenoise",
      "ignoreConflict": false,
      "lastUpdateTime": 1517966744013,
      "longDescription": "Registered with IANA on port 663 TCP/UDP",
      "name": "purenoise",
      "nbarId": "571",
      "p2pTechnology": "false",
      "popularity": 1,
      "rank": 65535,
      "references": "http://www.iana.org/assignments/service-names-port-numbers/service-names-port-numbers.xml",
      "selectorId": "663",
      "subCategory": "787a7d77-977b-4974-89d3-379ba49e88b7",
      "trafficClass": "OPS_ADMIN_MGMT",
      "tunnel": "false"
    }
  ],
  "networkIdentity": [
    {
      "id": "9ce0b00a-535e-4ba3-a699-0e9c64f108d6",
      "instanceId": 14650,
      "authEntityId": 14650,
      "displayName": "14650",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "663",
      "protocol": "TCP",
      "upperPort": 0
    },
    {
      "id": "2cc6911f-8504-4db3-a95b-a853dd7c511c",
      "instanceId": 14651,
      "authEntityId": 14651,
      "displayName": "14651",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "663",
      "protocol": "UDP",
      "upperPort": 0
    }
  ],
  "parentScalableGroup": {
    "idRef": "e06dd89f-0a70-4422-ab0b-c2314650a9a7"
  },
  "user": [

  ],
  "userGroup": [

  ]
},
{
  "id": "9d9eb8c7-7161-482e-a0b5-dc1024ec4507",
  "instanceId": 4965,
  "authEntityId": 4965,
  "displayName": "4965",
  "authEntityClass": -1909073334,
  "deployPending": "NONE",
  "instanceVersion": 0,
  "createTime": 1517966743924,
  "deployed": false,
  "isSeeded": true,
  "isStale": false,
  "lastUpdateTime": 1517966743924,
  "name": "pirp",
  "namespace": "scalablegroup:application",
  "provisioningState": "DEFINED",
  "qualifier": "application",
  "resourceVersion": 0,
  "targetIdList": [

  ],
  "type": "scalablegroup",
  "cfsChangeInfo": [

  ],
  "customProvisions": [

  ],
  "scalableGroupExternalHandle": "pirp",
  "scalableGroupType": "APPLICATION",
  "securityGroupTag": 0,
  "state": "ACTIVE",
  "vnAgnostic": true,
  "identitySource": {
    "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
    "instanceId": 10010,
    "authEntityId": 10010,
    "displayName": "10010",
    "authEntityClass": 1738953278,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "state": "INACTIVE",
    "type": "NBAR"
  },
  "indicativeNetworkIdentity": [
    {
      "id": "63a10ff7-6587-4559-bc30-90cfbcf1bf06",
      "instanceId": 14552,
      "authEntityId": 14552,
      "displayName": "14552",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "553",
      "protocol": "UDP",
      "upperPort": 0
    },
    {
      "id": "c5ef2dff-31e2-40e1-8ff5-70170de26eb8",
      "instanceId": 14553,
      "authEntityId": 14553,
      "displayName": "14553",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "553",
      "protocol": "TCP",
      "upperPort": 0
    }
  ],
  "networkApplications": [
    {
      "id": "52f503ec-2736-4c9e-811d-793f835cc022",
      "instanceId": 11914,
      "authEntityId": 11914,
      "displayName": "11914",
      "authEntityClass": -217092956,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "appProtocol": "tcp/udp",
      "applicationGroup": "other",
      "applicationType": "DEFAULT",
      "categoryId": "6daa3dfd-3526-407a-88f0-97824b3f3a24",
      "createTime": 1517966743924,
      "encrypted": "false",
      "engineId": "3",
      "helpString": "pirp",
      "ignoreConflict": false,
      "lastUpdateTime": 1517966743924,
      "longDescription": "The Public Information Retrieval Protocol (PIRP) gives Internet hosts a simple, uniform, efficient, extensible, easily implemented method of publishing information.",
      "name": "pirp",
      "nbarId": "470",
      "p2pTechnology": "false",
      "popularity": 1,
      "rank": 65535,
      "references": "http://cr.yp.to/proto/pirp.txt",
      "selectorId": "553",
      "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
      "trafficClass": "BULK_DATA",
      "tunnel": "false"
    }
  ],
  "networkIdentity": [
    {
      "id": "09c82c40-b072-4884-b0a7-c0b9e5f48de8",
      "instanceId": 14550,
      "authEntityId": 14550,
      "displayName": "14550",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "553",
      "protocol": "TCP",
      "upperPort": 0
    },
    {
      "id": "230aac8a-857e-4a2b-9e2b-98548e755d95",
      "instanceId": 14551,
      "authEntityId": 14551,
      "displayName": "14551",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "553",
      "protocol": "UDP",
      "upperPort": 0
    }
  ],
  "parentScalableGroup": {
    "idRef": "000c15bf-28fe-4fbb-9b69-35e6502721f1"
  },
  "user": [

  ],
  "userGroup": [

  ]
},
{
  "id": "9dc52aa0-8fb8-4cbd-b3e8-471614dfe043",
  "instanceId": 4267,
  "authEntityId": 4267,
  "displayName": "4267",
  "authEntityClass": -1909073334,
  "deployPending": "NONE",
  "instanceVersion": 0,
  "createTime": 1517966741991,
  "deployed": false,
  "isSeeded": true,
  "isStale": false,
  "lastUpdateTime": 1517966741991,
  "name": "cmip-agent",
  "namespace": "scalablegroup:application",
  "provisioningState": "DEFINED",
  "qualifier": "application",
  "resourceVersion": 0,
  "targetIdList": [

  ],
  "type": "scalablegroup",
  "cfsChangeInfo": [

  ],
  "customProvisions": [

  ],
  "scalableGroupExternalHandle": "cmip-agent",
  "scalableGroupType": "APPLICATION",
  "securityGroupTag": 0,
  "state": "ACTIVE",
  "vnAgnostic": true,
  "identitySource": {
    "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
    "instanceId": 10010,
    "authEntityId": 10010,
    "displayName": "10010",
    "authEntityClass": 1738953278,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "state": "INACTIVE",
    "type": "NBAR"
  },
  "indicativeNetworkIdentity": [
    {
      "id": "2844c0f3-27e2-43e3-9498-323d35c5ebf1",
      "instanceId": 12574,
      "authEntityId": 12574,
      "displayName": "12574",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "164",
      "protocol": "TCP",
      "upperPort": 0
    },
    {
      "id": "ce42a8d8-15c3-434c-8883-1f3e5720e458",
      "instanceId": 12575,
      "authEntityId": 12575,
      "displayName": "12575",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "164",
      "protocol": "UDP",
      "upperPort": 0
    }
  ],
  "networkApplications": [
    {
      "id": "4bc159ad-5bb9-46f1-a225-f08caf82f738",
      "instanceId": 11220,
      "authEntityId": 11220,
      "displayName": "11220",
      "authEntityClass": -217092956,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "appProtocol": "tcp/udp",
      "applicationGroup": "other",
      "applicationType": "DEFAULT",
      "categoryId": "1fc17362-9334-4c7b-b752-5afdbb574017",
      "createTime": 1517966741991,
      "encrypted": "false",
      "engineId": "3",
      "helpString": "CMIP/TCP Agent",
      "ignoreConflict": false,
      "lastUpdateTime": 1517966741991,
      "longDescription": "The Common Management Information Protocol (CMIP) is the OSI specified network management protocol. Defined in ITU-T Recommendation X.711, ISO/IEC International Standard 9596-1. It provides an implementation for the services defined by the Common Management Information Service (CMIS) specified in ITU-T Recommendation X.710, ISO/IEC International Standard 9595, allowing communication between network management applications and management agents. CMIS/CMIP is the network management protocol specified by the ISO/OSI Network management model and is further defined by the ITU-T in the X.700 series of recommendations.",
      "name": "cmip-agent",
      "nbarId": "1009",
      "p2pTechnology": "false",
      "popularity": 1,
      "rank": 65535,
      "references": "http://en.wikipedia.org/wiki/Common_Management_Information_Protocol",
      "selectorId": "164",
      "subCategory": "f3c0f761-d894-4112-ab89-b90fc84fd660",
      "trafficClass": "OPS_ADMIN_MGMT",
      "tunnel": "false"
    }
  ],
  "networkIdentity": [
    {
      "id": "9401f1fd-c19a-4643-8951-a5d516af91a7",
      "instanceId": 12572,
      "authEntityId": 12572,
      "displayName": "12572",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "164",
      "protocol": "TCP",
      "upperPort": 0
    },
    {
      "id": "1d8ca6fe-fd89-49a4-8d3b-08006f34671f",
      "instanceId": 12573,
      "authEntityId": 12573,
      "displayName": "12573",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "164",
      "protocol": "UDP",
      "upperPort": 0
    }
  ],
  "parentScalableGroup": {
    "idRef": "5fcd74d4-8726-47f7-a088-c457863da7ef"
  },
  "user": [

  ],
  "userGroup": [

  ]
},
{
  "id": "9e2cce89-62f4-4f94-82c3-6f8c72741540",
  "instanceId": 15202,
  "authEntityId": 15202,
  "displayName": "15202",
  "authEntityClass": -1909073334,
  "deployPending": "NONE",
  "instanceVersion": 0,
  "createTime": 1517966744473,
  "deployed": false,
  "isSeeded": true,
  "isStale": false,
  "lastUpdateTime": 1517966744473,
  "name": "srp",
  "namespace": "scalablegroup:application",
  "provisioningState": "DEFINED",
  "qualifier": "application",
  "resourceVersion": 0,
  "targetIdList": [

  ],
  "type": "scalablegroup",
  "cfsChangeInfo": [

  ],
  "customProvisions": [

  ],
  "scalableGroupExternalHandle": "srp",
  "scalableGroupType": "APPLICATION",
  "securityGroupTag": 0,
  "state": "ACTIVE",
  "vnAgnostic": true,
  "identitySource": {
    "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
    "instanceId": 10010,
    "authEntityId": 10010,
    "displayName": "10010",
    "authEntityClass": 1738953278,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "state": "INACTIVE",
    "type": "NBAR"
  },
  "indicativeNetworkIdentity": [
    {
      "id": "9f2c338e-b17a-4eef-8d59-d13ad86a02ae",
      "instanceId": 17247,
      "authEntityId": 17247,
      "displayName": "17247",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipProtocolNumbers": "119",
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "protocol": "SRP",
      "upperPort": 0
    }
  ],
  "networkApplications": [
    {
      "id": "563c139a-7778-4d32-957c-69f0fbb5bb3a",
      "instanceId": 16145,
      "authEntityId": 16145,
      "displayName": "16145",
      "authEntityClass": -217092956,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "appProtocol": "ip",
      "applicationGroup": "other",
      "applicationType": "DEFAULT",
      "categoryId": "6daa3dfd-3526-407a-88f0-97824b3f3a24",
      "createTime": 1517966744473,
      "encrypted": "unassigned",
      "engineId": "1",
      "helpString": "SpectraLink Radio Protocol",
      "ignoreConflict": false,
      "lastUpdateTime": 1517966744473,
      "longDescription": "SpectraLink Radio Protocol (SRP) is a proprietary protocol used in conjunction with SpectraLink Voice Priority (SVP) in SpectraLink NetLink telephones for all communications among themselves and other pieces of SpectraLink VoWLAN infrastructures.",
      "name": "srp",
      "nbarId": "873",
      "p2pTechnology": "unassigned",
      "popularity": 2,
      "rank": 65535,
      "references": "http://www.cisco.com/en/US/tech/tk722/tk809/technologies_tech_note09186a00806d11cb.shtml",
      "selectorId": "119",
      "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
      "trafficClass": "BULK_DATA",
      "tunnel": "unassigned"
    }
  ],
  "networkIdentity": [
    {
      "id": "6fe4ddfa-0748-4f10-bfc3-b6a526e90995",
      "instanceId": 17246,
      "authEntityId": 17246,
      "displayName": "17246",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipProtocolNumbers": "119",
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "protocol": "SRP",
      "upperPort": 0
    }
  ],
  "parentScalableGroup": {
    "idRef": "000c15bf-28fe-4fbb-9b69-35e6502721f1"
  },
  "user": [

  ],
  "userGroup": [

  ]
},
{
  "id": "9e94c9c1-5be6-44a1-85e9-42a27f05ebc9",
  "instanceId": 15379,
  "authEntityId": 15379,
  "displayName": "15379",
  "authEntityClass": -1909073334,
  "deployPending": "NONE",
  "instanceVersion": 0,
  "createTime": 1517966744839,
  "deployed": false,
  "isSeeded": true,
  "isStale": false,
  "lastUpdateTime": 1517966744839,
  "name": "wap-vcal-s",
  "namespace": "scalablegroup:application",
  "provisioningState": "DEFINED",
  "qualifier": "application",
  "resourceVersion": 0,
  "targetIdList": [

  ],
  "type": "scalablegroup",
  "cfsChangeInfo": [

  ],
  "customProvisions": [

  ],
  "scalableGroupExternalHandle": "wap-vcal-s",
  "scalableGroupType": "APPLICATION",
  "securityGroupTag": 0,
  "state": "ACTIVE",
  "vnAgnostic": true,
  "identitySource": {
    "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
    "instanceId": 10010,
    "authEntityId": 10010,
    "displayName": "10010",
    "authEntityClass": 1738953278,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "state": "INACTIVE",
    "type": "NBAR"
  },
  "indicativeNetworkIdentity": [
    {
      "id": "97c55cfe-fb8d-4764-af6a-bfec071f0993",
      "instanceId": 17760,
      "authEntityId": 17760,
      "displayName": "17760",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "9207",
      "protocol": "UDP",
      "upperPort": 0
    },
    {
      "id": "4a1abb71-3cf8-4dac-8bd6-f40916110ab5",
      "instanceId": 17759,
      "authEntityId": 17759,
      "displayName": "17759",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "9207",
      "protocol": "TCP",
      "upperPort": 0
    }
  ],
  "networkApplications": [
    {
      "id": "7b1176cf-546e-476b-bf45-b16854918d8f",
      "instanceId": 16322,
      "authEntityId": 16322,
      "displayName": "16322",
      "authEntityClass": -217092956,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "appProtocol": "tcp/udp",
      "applicationGroup": "wap-group",
      "applicationType": "DEFAULT",
      "categoryId": "6daa3dfd-3526-407a-88f0-97824b3f3a24",
      "createTime": 1517966744839,
      "encrypted": "true",
      "engineId": "3",
      "helpString": "WAP vCal Secure",
      "ignoreConflict": false,
      "lastUpdateTime": 1517966744839,
      "longDescription": "Registered with IANA on port 9207 TCP/UDP",
      "name": "wap-vcal-s",
      "nbarId": "736",
      "p2pTechnology": "false",
      "popularity": 1,
      "rank": 65535,
      "references": "http://www.iana.org/assignments/service-names-port-numbers/service-names-port-numbers.xml",
      "selectorId": "9207",
      "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
      "trafficClass": "BULK_DATA",
      "tunnel": "false"
    }
  ],
  "networkIdentity": [
    {
      "id": "aa48c079-5ca2-4602-b274-c5715c4694cd",
      "instanceId": 17757,
      "authEntityId": 17757,
      "displayName": "17757",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "9207",
      "protocol": "TCP",
      "upperPort": 0
    },
    {
      "id": "231592ca-d9e6-4338-8efa-decd4433b906",
      "instanceId": 17758,
      "authEntityId": 17758,
      "displayName": "17758",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "9207",
      "protocol": "UDP",
      "upperPort": 0
    }
  ],
  "parentScalableGroup": {
    "idRef": "000c15bf-28fe-4fbb-9b69-35e6502721f1"
  },
  "user": [

  ],
  "userGroup": [

  ]
},
{
  "id": "9e9df15f-cc2a-4b0a-9c96-c6dfb9fb1c86",
  "instanceId": 15365,
  "authEntityId": 15365,
  "displayName": "15365",
  "authEntityClass": -1909073334,
  "deployPending": "NONE",
  "instanceVersion": 0,
  "createTime": 1517966744812,
  "deployed": false,
  "isSeeded": true,
  "isStale": false,
  "lastUpdateTime": 1517966744812,
  "name": "vnc",
  "namespace": "scalablegroup:application",
  "provisioningState": "DEFINED",
  "qualifier": "application",
  "resourceVersion": 0,
  "targetIdList": [

  ],
  "type": "scalablegroup",
  "cfsChangeInfo": [

  ],
  "customProvisions": [

  ],
  "scalableGroupExternalHandle": "vnc",
  "scalableGroupType": "APPLICATION",
  "securityGroupTag": 0,
  "state": "ACTIVE",
  "vnAgnostic": true,
  "identitySource": {
    "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
    "instanceId": 10010,
    "authEntityId": 10010,
    "displayName": "10010",
    "authEntityClass": 1738953278,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "state": "INACTIVE",
    "type": "NBAR"
  },
  "indicativeNetworkIdentity": [
    {
      "id": "6a417cf4-40a9-4efd-967e-1f8212a6fa3e",
      "instanceId": 17710,
      "authEntityId": 17710,
      "displayName": "17710",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "5800,5900,5901",
      "protocol": "UDP",
      "upperPort": 0
    },
    {
      "id": "d7c6337d-0f8f-41cf-b3d6-02374d9b7177",
      "instanceId": 17711,
      "authEntityId": 17711,
      "displayName": "17711",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "5800,5900,5901",
      "protocol": "TCP",
      "upperPort": 0
    }
  ],
  "networkApplications": [
    {
      "id": "8da3a718-822c-4873-8113-fd6696796565",
      "instanceId": 16308,
      "authEntityId": 16308,
      "displayName": "16308",
      "authEntityClass": -217092956,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "appProtocol": "tcp/udp",
      "applicationGroup": "vnc-group",
      "applicationType": "DEFAULT",
      "categoryId": "7e19fdea-6222-4c57-9e66-66c2340d6bc3",
      "createTime": 1517966744812,
      "encrypted": "false",
      "engineId": "13",
      "helpString": "Virtual Network Computing",
      "ignoreConflict": false,
      "lastUpdateTime": 1517966744812,
      "longDescription": "Virtual Network Computing (VNC) is a graphical desktop sharing system that uses the Remote Framebuffer (RFB) protocol to remotely control another computer. The VNC session is established through an installed application.",
      "name": "vnc",
      "nbarId": "100",
      "p2pTechnology": "false",
      "popularity": 9,
      "rank": 65535,
      "references": "http://www.realvnc.com/",
      "selectorId": "100",
      "subCategory": "ff664205-64f3-447e-acff-1ec1ba9a6c31",
      "trafficClass": "MULTIMEDIA_STREAMING",
      "tunnel": "false"
    }
  ],
  "networkIdentity": [
    {
      "id": "0b8e2f1e-18ac-4977-8c85-98c3fa89a1b1",
      "instanceId": 17708,
      "authEntityId": 17708,
      "displayName": "17708",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "5800,5900,5901",
      "protocol": "TCP",
      "upperPort": 0
    },
    {
      "id": "cfc10b20-6979-4c8f-ac22-c8bb8e517cd5",
      "instanceId": 17709,
      "authEntityId": 17709,
      "displayName": "17709",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "5800,5900,5901",
      "protocol": "UDP",
      "upperPort": 0
    }
  ],
  "parentScalableGroup": {
    "idRef": "4a6b37c6-7e11-40fe-a6b1-719b24813145"
  },
  "user": [

  ],
  "userGroup": [

  ]
},
{
  "id": "9ead401a-77c4-40a6-b902-ca0aef1f1158",
  "instanceId": 4852,
  "authEntityId": 4852,
  "displayName": "4852",
  "authEntityClass": -1909073334,
  "deployPending": "NONE",
  "instanceVersion": 0,
  "createTime": 1517966743616,
  "deployed": false,
  "isSeeded": true,
  "isStale": false,
  "lastUpdateTime": 1517966743616,
  "name": "netviewdm1",
  "namespace": "scalablegroup:application",
  "provisioningState": "DEFINED",
  "qualifier": "application",
  "resourceVersion": 0,
  "targetIdList": [

  ],
  "type": "scalablegroup",
  "cfsChangeInfo": [

  ],
  "customProvisions": [

  ],
  "scalableGroupExternalHandle": "netviewdm1",
  "scalableGroupType": "APPLICATION",
  "securityGroupTag": 0,
  "state": "ACTIVE",
  "vnAgnostic": true,
  "identitySource": {
    "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
    "instanceId": 10010,
    "authEntityId": 10010,
    "displayName": "10010",
    "authEntityClass": 1738953278,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "state": "INACTIVE",
    "type": "NBAR"
  },
  "indicativeNetworkIdentity": [
    {
      "id": "45407731-e8df-44d0-b609-22cc3694ac4a",
      "instanceId": 14192,
      "authEntityId": 14192,
      "displayName": "14192",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "729",
      "protocol": "TCP",
      "upperPort": 0
    },
    {
      "id": "642667fd-b8b5-471d-87f7-47affc57fc21",
      "instanceId": 14193,
      "authEntityId": 14193,
      "displayName": "14193",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "729",
      "protocol": "UDP",
      "upperPort": 0
    }
  ],
  "networkApplications": [
    {
      "id": "bf3c13d3-eb4d-466d-bc7f-4aa365b56637",
      "instanceId": 11801,
      "authEntityId": 11801,
      "displayName": "11801",
      "authEntityClass": -217092956,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "appProtocol": "tcp/udp",
      "applicationGroup": "other",
      "applicationType": "DEFAULT",
      "categoryId": "1fc17362-9334-4c7b-b752-5afdbb574017",
      "createTime": 1517966743616,
      "encrypted": "false",
      "engineId": "3",
      "helpString": "IBM NetView DM",
      "ignoreConflict": false,
      "lastUpdateTime": 1517966743616,
      "longDescription": "IBM NetView Distribution Manager provides centralized management capabilities. It enables customers to automate the distribution of software and data to a large number of remote targets when utilizing the S/390 computers as the primary management system, and provides centralized tracking, automated error recovery, and efficient utilization of the network during the distribution process and shows the customer a history of the distribution process",
      "name": "netviewdm1",
      "nbarId": "615",
      "p2pTechnology": "false",
      "popularity": 1,
      "rank": 65535,
      "references": "http://www-01.ibm.com/software/tivoli/products/netview_distmgr/",
      "selectorId": "729",
      "subCategory": "f3c0f761-d894-4112-ab89-b90fc84fd660",
      "trafficClass": "OPS_ADMIN_MGMT",
      "tunnel": "false"
    }
  ],
  "networkIdentity": [
    {
      "id": "dfc768af-5f72-43e0-9c3c-4fc298805ba7",
      "instanceId": 14190,
      "authEntityId": 14190,
      "displayName": "14190",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "729",
      "protocol": "TCP",
      "upperPort": 0
    },
    {
      "id": "1327667a-5fb7-4207-9211-4893529432ac",
      "instanceId": 14191,
      "authEntityId": 14191,
      "displayName": "14191",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "729",
      "protocol": "UDP",
      "upperPort": 0
    }
  ],
  "parentScalableGroup": {
    "idRef": "5fcd74d4-8726-47f7-a088-c457863da7ef"
  },
  "user": [

  ],
  "userGroup": [

  ]
},
{
  "id": "9eafcdda-5572-4881-8ae6-4accceb90e03",
  "instanceId": 4118,
  "authEntityId": 4118,
  "displayName": "4118",
  "authEntityClass": -1909073334,
  "deployPending": "NONE",
  "instanceVersion": 0,
  "createTime": 1517966741229,
  "deployed": false,
  "isSeeded": true,
  "isStale": false,
  "lastUpdateTime": 1517966741229,
  "name": "ariel3",
  "namespace": "scalablegroup:application",
  "provisioningState": "DEFINED",
  "qualifier": "application",
  "resourceVersion": 0,
  "targetIdList": [

  ],
  "type": "scalablegroup",
  "cfsChangeInfo": [

  ],
  "customProvisions": [

  ],
  "scalableGroupExternalHandle": "ariel3",
  "scalableGroupType": "APPLICATION",
  "securityGroupTag": 0,
  "state": "ACTIVE",
  "vnAgnostic": true,
  "identitySource": {
    "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
    "instanceId": 10010,
    "authEntityId": 10010,
    "displayName": "10010",
    "authEntityClass": 1738953278,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "state": "INACTIVE",
    "type": "NBAR"
  },
  "indicativeNetworkIdentity": [
    {
      "id": "4f60c37f-770e-4cb9-acfc-f3326fe17fd3",
      "instanceId": 12209,
      "authEntityId": 12209,
      "displayName": "12209",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "422",
      "protocol": "UDP",
      "upperPort": 0
    },
    {
      "id": "5f0f0f73-80fb-4543-8941-5cc0993d3a0c",
      "instanceId": 12210,
      "authEntityId": 12210,
      "displayName": "12210",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "422",
      "protocol": "TCP",
      "upperPort": 0
    }
  ],
  "networkApplications": [
    {
      "id": "4dc53e8a-c520-40e4-90a8-59ad7e1c8b0d",
      "instanceId": 11080,
      "authEntityId": 11080,
      "displayName": "11080",
      "authEntityClass": -217092956,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "appProtocol": "tcp/udp",
      "applicationGroup": "other",
      "applicationType": "DEFAULT",
      "categoryId": "6daa3dfd-3526-407a-88f0-97824b3f3a24",
      "createTime": 1517966741229,
      "encrypted": "false",
      "engineId": "3",
      "helpString": "Ariel3",
      "ignoreConflict": false,
      "lastUpdateTime": 1517966741229,
      "longDescription": "Ariel allows users to send high-detail electronic images to other Ariel workstations anywhere in the world, using either FTP or email, converting the images to PDF files for easy delivery.",
      "name": "ariel3",
      "nbarId": "337",
      "p2pTechnology": "false",
      "popularity": 1,
      "rank": 65535,
      "references": "http://www.infotrieve.com/ariel-interlibrary-loan-software",
      "selectorId": "422",
      "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
      "trafficClass": "BULK_DATA",
      "tunnel": "false"
    }
  ],
  "networkIdentity": [
    {
      "id": "8961ff22-1f2a-4084-a096-68698bfe6ddd",
      "instanceId": 12208,
      "authEntityId": 12208,
      "displayName": "12208",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "422",
      "protocol": "UDP",
      "upperPort": 0
    },
    {
      "id": "a8719136-450c-4fbb-8fd2-cf9b80395d80",
      "instanceId": 12207,
      "authEntityId": 12207,
      "displayName": "12207",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "422",
      "protocol": "TCP",
      "upperPort": 0
    }
  ],
  "parentScalableGroup": {
    "idRef": "000c15bf-28fe-4fbb-9b69-35e6502721f1"
  },
  "user": [

  ],
  "userGroup": [

  ]
},
{
  "id": "9ebee2b3-2359-4a9c-8eda-b89effbe26f1",
  "instanceId": 4891,
  "authEntityId": 4891,
  "displayName": "4891",
  "authEntityClass": -1909073334,
  "deployPending": "NONE",
  "instanceVersion": 0,
  "createTime": 1517966743694,
  "deployed": false,
  "isSeeded": true,
  "isStale": false,
  "lastUpdateTime": 1517966743694,
  "name": "nxedit",
  "namespace": "scalablegroup:application",
  "provisioningState": "DEFINED",
  "qualifier": "application",
  "resourceVersion": 0,
  "targetIdList": [

  ],
  "type": "scalablegroup",
  "cfsChangeInfo": [

  ],
  "customProvisions": [

  ],
  "scalableGroupExternalHandle": "nxedit",
  "scalableGroupType": "APPLICATION",
  "securityGroupTag": 0,
  "state": "ACTIVE",
  "vnAgnostic": true,
  "identitySource": {
    "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
    "instanceId": 10010,
    "authEntityId": 10010,
    "displayName": "10010",
    "authEntityClass": 1738953278,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "state": "INACTIVE",
    "type": "NBAR"
  },
  "indicativeNetworkIdentity": [
    {
      "id": "fd71e160-2c7d-4c7c-a25e-15cccab342be",
      "instanceId": 14325,
      "authEntityId": 14325,
      "displayName": "14325",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "126",
      "protocol": "UDP",
      "upperPort": 0
    },
    {
      "id": "663f9006-abfb-4c81-a2e6-f7933ab0d68d",
      "instanceId": 14326,
      "authEntityId": 14326,
      "displayName": "14326",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "126",
      "protocol": "TCP",
      "upperPort": 0
    }
  ],
  "networkApplications": [
    {
      "id": "335cc1c8-1532-4df6-87d8-bdcbdc1c7ba9",
      "instanceId": 11840,
      "authEntityId": 11840,
      "displayName": "11840",
      "authEntityClass": -217092956,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "appProtocol": "tcp/udp",
      "applicationGroup": "other",
      "applicationType": "DEFAULT",
      "categoryId": "1fc17362-9334-4c7b-b752-5afdbb574017",
      "createTime": 1517966743694,
      "encrypted": "false",
      "engineId": "3",
      "helpString": "nxedit",
      "ignoreConflict": false,
      "lastUpdateTime": 1517966743694,
      "longDescription": "Registered with IANA on port 126 TCP/UDP",
      "name": "nxedit",
      "nbarId": "995",
      "p2pTechnology": "false",
      "popularity": 1,
      "rank": 65535,
      "references": "http://www.iana.org/assignments/service-names-port-numbers/service-names-port-numbers.xml",
      "selectorId": "126",
      "subCategory": "e390124a-0fd6-4253-b68e-4b0c1af8cb4b",
      "trafficClass": "OPS_ADMIN_MGMT",
      "tunnel": "false"
    }
  ],
  "networkIdentity": [
    {
      "id": "65647540-cada-4ba5-bb84-d940fc36d43c",
      "instanceId": 14323,
      "authEntityId": 14323,
      "displayName": "14323",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "126",
      "protocol": "TCP",
      "upperPort": 0
    },
    {
      "id": "b7797c12-25c9-43ba-b50d-de496705a046",
      "instanceId": 14324,
      "authEntityId": 14324,
      "displayName": "14324",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "126",
      "protocol": "UDP",
      "upperPort": 0
    }
  ],
  "parentScalableGroup": {
    "idRef": "7788bf2d-e289-4597-b500-17aacb7e820e"
  },
  "user": [

  ],
  "userGroup": [

  ]
},
{
  "id": "9f1ed493-5967-4399-9498-0efe3909ce74",
  "instanceId": 15359,
  "authEntityId": 15359,
  "displayName": "15359",
  "authEntityClass": -1909073334,
  "deployPending": "NONE",
  "instanceVersion": 0,
  "createTime": 1517966744801,
  "deployed": false,
  "isSeeded": true,
  "isStale": false,
  "lastUpdateTime": 1517966744801,
  "name": "vmtp",
  "namespace": "scalablegroup:application",
  "provisioningState": "DEFINED",
  "qualifier": "application",
  "resourceVersion": 0,
  "targetIdList": [

  ],
  "type": "scalablegroup",
  "cfsChangeInfo": [

  ],
  "customProvisions": [

  ],
  "scalableGroupExternalHandle": "vmtp",
  "scalableGroupType": "APPLICATION",
  "securityGroupTag": 0,
  "state": "ACTIVE",
  "vnAgnostic": true,
  "identitySource": {
    "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
    "instanceId": 10010,
    "authEntityId": 10010,
    "displayName": "10010",
    "authEntityClass": 1738953278,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "state": "INACTIVE",
    "type": "NBAR"
  },
  "indicativeNetworkIdentity": [
    {
      "id": "fd1eca33-20ce-469b-94cd-314dcdd9f866",
      "instanceId": 17694,
      "authEntityId": 17694,
      "displayName": "17694",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipProtocolNumbers": "81",
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "protocol": "VMTP",
      "upperPort": 0
    }
  ],
  "networkApplications": [
    {
      "id": "d73c5709-7711-4d58-82b6-47aca1f9478d",
      "instanceId": 16302,
      "authEntityId": 16302,
      "displayName": "16302",
      "authEntityClass": -217092956,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "appProtocol": "ip",
      "applicationGroup": "other",
      "applicationType": "DEFAULT",
      "categoryId": "6daa3dfd-3526-407a-88f0-97824b3f3a24",
      "createTime": 1517966744801,
      "encrypted": "unassigned",
      "engineId": "1",
      "helpString": "VMTP",
      "ignoreConflict": false,
      "lastUpdateTime": 1517966744801,
      "longDescription": "Versatile Message Transaction Protocol (VMTP) is a transport protocol specifically designed to support the transaction model of communication, as exemplified by remote procedure call (RPC).  The full function of VMTP, including support for security, real-time, asynchronous message exchanges, streaming, multicast and idempotency, provides a rich selection to the VMTP user level.",
      "name": "vmtp",
      "nbarId": "835",
      "p2pTechnology": "unassigned",
      "popularity": 2,
      "rank": 65535,
      "references": "http://tools.ietf.org/html/rfc1045",
      "selectorId": "81",
      "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
      "trafficClass": "BULK_DATA",
      "tunnel": "unassigned"
    }
  ],
  "networkIdentity": [
    {
      "id": "e9a95b98-373b-41f7-8d8f-8fbbb4e1efc6",
      "instanceId": 17693,
      "authEntityId": 17693,
      "displayName": "17693",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipProtocolNumbers": "81",
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "protocol": "VMTP",
      "upperPort": 0
    }
  ],
  "parentScalableGroup": {
    "idRef": "000c15bf-28fe-4fbb-9b69-35e6502721f1"
  },
  "user": [

  ],
  "userGroup": [

  ]
},
{
  "id": "9f272c32-07b2-4ea9-af93-fca43ce626f1",
  "instanceId": 4435,
  "authEntityId": 4435,
  "displayName": "4435",
  "authEntityClass": -1909073334,
  "deployPending": "NONE",
  "instanceVersion": 0,
  "createTime": 1517966742651,
  "deployed": false,
  "isSeeded": true,
  "isStale": false,
  "lastUpdateTime": 1517966742651,
  "name": "facebook",
  "namespace": "scalablegroup:application",
  "provisioningState": "DEFINED",
  "qualifier": "application",
  "resourceVersion": 0,
  "targetIdList": [

  ],
  "type": "scalablegroup",
  "cfsChangeInfo": [

  ],
  "customProvisions": [

  ],
  "scalableGroupExternalHandle": "facebook",
  "scalableGroupType": "APPLICATION",
  "securityGroupTag": 0,
  "state": "ACTIVE",
  "vnAgnostic": true,
  "identitySource": {
    "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
    "instanceId": 10010,
    "authEntityId": 10010,
    "displayName": "10010",
    "authEntityClass": 1738953278,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "state": "INACTIVE",
    "type": "NBAR"
  },
  "indicativeNetworkIdentity": [

  ],
  "networkApplications": [
    {
      "id": "280a68d1-be0d-40c2-b6bc-135f2a43c4c1",
      "instanceId": 11386,
      "authEntityId": 11386,
      "displayName": "11386",
      "authEntityClass": -217092956,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "appProtocol": "tcp/udp",
      "applicationGroup": "other",
      "applicationType": "DEFAULT",
      "categoryId": "9eb6d680-796f-478a-9106-1151f00f7eff",
      "createTime": 1517966742651,
      "encrypted": "true",
      "engineId": "13",
      "helpString": "Facebook, social web application and service",
      "ignoreConflict": false,
      "lastUpdateTime": 1517966742651,
      "longDescription": "Facebook is a social network service and website.",
      "name": "facebook",
      "nbarId": "1454",
      "p2pTechnology": "false",
      "popularity": 10,
      "rank": 65535,
      "references": "http://www.facebook.com/",
      "selectorId": "518",
      "subCategory": "aab99e59-8589-4a61-933e-f01bff556d78",
      "trafficClass": "TRANSACTIONAL_DATA",
      "tunnel": "false"
    }
  ],
  "networkIdentity": [
    {
      "id": "7d77d7d9-e535-43e9-b0e5-8782aead8e1b",
      "instanceId": 13094,
      "authEntityId": 13094,
      "displayName": "13094",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "53,3478,5353",
      "protocol": "UDP",
      "upperPort": 0
    },
    {
      "id": "21f72154-1b85-491c-bbf9-051f17082904",
      "instanceId": 13095,
      "authEntityId": 13095,
      "displayName": "13095",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "53,80,443,3478,5353",
      "protocol": "TCP",
      "upperPort": 0
    }
  ],
  "parentScalableGroup": {
    "idRef": "82348eb1-d8b8-4daf-8532-15e1a5f9cdd0"
  },
  "user": [

  ],
  "userGroup": [

  ]
},
{
  "id": "9f29c60d-6e0d-4531-a13a-f1f07cf3f115",
  "instanceId": 4814,
  "authEntityId": 4814,
  "displayName": "4814",
  "authEntityClass": -1909073334,
  "deployPending": "NONE",
  "instanceVersion": 0,
  "createTime": 1517966743545,
  "deployed": false,
  "isSeeded": true,
  "isStale": false,
  "lastUpdateTime": 1517966743545,
  "name": "mysql",
  "namespace": "scalablegroup:application",
  "provisioningState": "DEFINED",
  "qualifier": "application",
  "resourceVersion": 0,
  "targetIdList": [

  ],
  "type": "scalablegroup",
  "cfsChangeInfo": [

  ],
  "customProvisions": [

  ],
  "scalableGroupExternalHandle": "mysql",
  "scalableGroupType": "APPLICATION",
  "securityGroupTag": 0,
  "state": "ACTIVE",
  "vnAgnostic": true,
  "identitySource": {
    "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
    "instanceId": 10010,
    "authEntityId": 10010,
    "displayName": "10010",
    "authEntityClass": 1738953278,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "state": "INACTIVE",
    "type": "NBAR"
  },
  "indicativeNetworkIdentity": [

  ],
  "networkApplications": [
    {
      "id": "36732371-02b0-4cae-850c-c2f25b325398",
      "instanceId": 11763,
      "authEntityId": 11763,
      "displayName": "11763",
      "authEntityClass": -217092956,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "applicationGroup": "sqlsvr-group",
      "applicationType": "DEFAULT",
      "categoryId": "cb5915d1-afec-4c30-ac9e-069ff382272f",
      "createTime": 1517966743545,
      "encrypted": "false",
      "engineId": "3",
      "helpString": "Database management system",
      "ignoreConflict": false,
      "lastUpdateTime": 1517966743545,
      "longDescription": "MySQL is a relational database management system (RDBMS) that runs as a server, providing multi-user access to a number of databases. The SQL phrase stands for Structured Query Language.",
      "name": "mysql",
      "nbarId": "711",
      "p2pTechnology": "false",
      "popularity": 9,
      "rank": 65535,
      "references": "http://www.mysql.com/",
      "selectorId": "3306",
      "subCategory": "b90786f4-0190-45b8-9924-21b3e9682590",
      "trafficClass": "TRANSACTIONAL_DATA",
      "tunnel": "false"
    }
  ],
  "networkIdentity": [

  ],
  "parentScalableGroup": {
    "idRef": "8718395e-683e-4a2d-a232-100202751271"
  },
  "user": [

  ],
  "userGroup": [

  ]
},
{
  "id": "9f6cf3ce-8ca9-41cf-a141-4457da30ba13",
  "instanceId": 4473,
  "authEntityId": 4473,
  "displayName": "4473",
  "authEntityClass": -1909073334,
  "deployPending": "NONE",
  "instanceVersion": 0,
  "createTime": 1517966742758,
  "deployed": false,
  "isSeeded": true,
  "isStale": false,
  "lastUpdateTime": 1517966742758,
  "name": "game-spy",
  "namespace": "scalablegroup:application",
  "provisioningState": "DEFINED",
  "qualifier": "application",
  "resourceVersion": 0,
  "targetIdList": [

  ],
  "type": "scalablegroup",
  "cfsChangeInfo": [

  ],
  "customProvisions": [

  ],
  "scalableGroupExternalHandle": "game-spy",
  "scalableGroupType": "APPLICATION",
  "securityGroupTag": 0,
  "state": "ACTIVE",
  "vnAgnostic": true,
  "identitySource": {
    "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
    "instanceId": 10010,
    "authEntityId": 10010,
    "displayName": "10010",
    "authEntityClass": 1738953278,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "state": "INACTIVE",
    "type": "NBAR"
  },
  "indicativeNetworkIdentity": [
    {
      "id": "60a6e515-1db0-4185-9c58-fdbcac25fc04",
      "instanceId": 13179,
      "authEntityId": 13179,
      "displayName": "13179",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "6500,28900",
      "protocol": "TCP",
      "upperPort": 0
    },
    {
      "id": "090100f2-8b59-4201-97a5-7f389a18e444",
      "instanceId": 13180,
      "authEntityId": 13180,
      "displayName": "13180",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "6515,27900",
      "protocol": "UDP",
      "upperPort": 0
    }
  ],
  "networkApplications": [
    {
      "id": "f4c8ee8d-cece-442b-ada1-5ae7ecbb7c78",
      "instanceId": 11424,
      "authEntityId": 11424,
      "displayName": "11424",
      "authEntityClass": -217092956,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "appProtocol": "tcp/udp",
      "applicationGroup": "other",
      "applicationType": "DEFAULT",
      "categoryId": "3cd50dd8-1b2e-46c1-b672-41c14bfa364b",
      "createTime": 1517966742758,
      "encrypted": "false",
      "engineId": "13",
      "helpString": "Game-spy online gaming",
      "ignoreConflict": false,
      "lastUpdateTime": 1517966742758,
      "longDescription": "GameSpy is network of game websites that provides online video game related services and software. GameSpy is available on PC and many other game platforms.",
      "name": "game-spy",
      "nbarId": "1349",
      "p2pTechnology": "false",
      "popularity": 6,
      "rank": 65535,
      "references": "http://www.gamespy.com/",
      "selectorId": "506",
      "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
      "trafficClass": "MULTIMEDIA_STREAMING",
      "tunnel": "false"
    }
  ],
  "networkIdentity": [
    {
      "id": "5bc90c18-3c3b-4c76-adac-ce5eff8ae165",
      "instanceId": 13177,
      "authEntityId": 13177,
      "displayName": "13177",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "6515,27900",
      "protocol": "UDP",
      "upperPort": 0
    },
    {
      "id": "afe390ad-8937-4c40-b36f-a1cacb83c5a1",
      "instanceId": 13178,
      "authEntityId": 13178,
      "displayName": "13178",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "6500,28900",
      "protocol": "TCP",
      "upperPort": 0
    }
  ],
  "parentScalableGroup": {
    "idRef": "0bd0e6c9-5083-492b-97cb-a88928bee99c"
  },
  "user": [

  ],
  "userGroup": [

  ]
},
{
  "id": "9f7f0c4d-0b05-46c5-ba21-2f4684fd0da5",
  "instanceId": 4662,
  "authEntityId": 4662,
  "displayName": "4662",
  "authEntityClass": -1909073334,
  "deployPending": "NONE",
  "instanceVersion": 0,
  "createTime": 1517966743231,
  "deployed": false,
  "isSeeded": true,
  "isStale": false,
  "lastUpdateTime": 1517966743231,
  "name": "java-web-portal",
  "namespace": "scalablegroup:application",
  "provisioningState": "DEFINED",
  "qualifier": "application",
  "resourceVersion": 0,
  "targetIdList": [

  ],
  "type": "scalablegroup",
  "cfsChangeInfo": [

  ],
  "customProvisions": [

  ],
  "scalableGroupExternalHandle": "java-web-portal",
  "scalableGroupType": "APPLICATION",
  "securityGroupTag": 0,
  "state": "ACTIVE",
  "vnAgnostic": true,
  "identitySource": {
    "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
    "instanceId": 10010,
    "authEntityId": 10010,
    "displayName": "10010",
    "authEntityClass": 1738953278,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "state": "INACTIVE",
    "type": "NBAR"
  },
  "indicativeNetworkIdentity": [

  ],
  "networkApplications": [
    {
      "id": "6349ed43-655c-49ff-8fb7-0531529327ee",
      "instanceId": 11612,
      "authEntityId": 11612,
      "displayName": "11612",
      "authEntityClass": -217092956,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "appProtocol": "tcp/udp",
      "applicationGroup": "other",
      "applicationType": "DEFAULT",
      "categoryId": "878644b5-fe91-45b4-9204-31c84a73ba48",
      "createTime": 1517966743231,
      "encrypted": "false",
      "engineId": "13",
      "helpString": "Java Update - Java update software service.",
      "ignoreConflict": false,
      "lastUpdateTime": 1517966743231,
      "longDescription": "Java Update - Java update software service.",
      "name": "java-web-portal",
      "nbarId": "1646",
      "p2pTechnology": "false",
      "popularity": 3,
      "rank": 65535,
      "references": "http://java.com",
      "selectorId": "716",
      "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
      "trafficClass": "TRANSACTIONAL_DATA",
      "tunnel": "false"
    }
  ],
  "networkIdentity": [
    {
      "id": "1bf75d08-315b-4568-a35a-86cf8e71fc5d",
      "instanceId": 13662,
      "authEntityId": 13662,
      "displayName": "13662",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "53,5353",
      "protocol": "UDP",
      "upperPort": 0
    },
    {
      "id": "cba3de78-e164-4a89-8373-bb88d9ce3389",
      "instanceId": 13663,
      "authEntityId": 13663,
      "displayName": "13663",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "53,80,443,5353",
      "protocol": "TCP",
      "upperPort": 0
    }
  ],
  "parentScalableGroup": {
    "idRef": "28e08de4-d7b2-4521-84ce-bc1e5ee0b9cc"
  },
  "user": [

  ],
  "userGroup": [

  ]
},
{
  "id": "9fb294a3-a50f-407f-bd3e-27a46755dd2b",
  "instanceId": 4490,
  "authEntityId": 4490,
  "displayName": "4490",
  "authEntityClass": -1909073334,
  "deployPending": "NONE",
  "instanceVersion": 0,
  "createTime": 1517966742799,
  "deployed": false,
  "isSeeded": true,
  "isStale": false,
  "lastUpdateTime": 1517966742799,
  "name": "go-login",
  "namespace": "scalablegroup:application",
  "provisioningState": "DEFINED",
  "qualifier": "application",
  "resourceVersion": 0,
  "targetIdList": [

  ],
  "type": "scalablegroup",
  "cfsChangeInfo": [

  ],
  "customProvisions": [

  ],
  "scalableGroupExternalHandle": "go-login",
  "scalableGroupType": "APPLICATION",
  "securityGroupTag": 0,
  "state": "ACTIVE",
  "vnAgnostic": true,
  "identitySource": {
    "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
    "instanceId": 10010,
    "authEntityId": 10010,
    "displayName": "10010",
    "authEntityClass": 1738953278,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "state": "INACTIVE",
    "type": "NBAR"
  },
  "indicativeNetworkIdentity": [
    {
      "id": "ffbe2c1b-dca4-48a1-8791-4242312dbba9",
      "instanceId": 13222,
      "authEntityId": 13222,
      "displayName": "13222",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "491",
      "protocol": "TCP",
      "upperPort": 0
    },
    {
      "id": "aff0d9fe-14ee-4580-b10a-c1d871465407",
      "instanceId": 13223,
      "authEntityId": 13223,
      "displayName": "13223",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "491",
      "protocol": "UDP",
      "upperPort": 0
    }
  ],
  "networkApplications": [
    {
      "id": "2383c045-5783-4678-82fc-07be342ee5e7",
      "instanceId": 11441,
      "authEntityId": 11441,
      "displayName": "11441",
      "authEntityClass": -217092956,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "appProtocol": "tcp/udp",
      "applicationGroup": "other",
      "applicationType": "DEFAULT",
      "categoryId": "1fc17362-9334-4c7b-b752-5afdbb574017",
      "createTime": 1517966742799,
      "encrypted": "false",
      "engineId": "3",
      "helpString": "go-login",
      "ignoreConflict": false,
      "lastUpdateTime": 1517966742799,
      "longDescription": "GraphOn Login is a protocol used by GraphOn, a company that develops secure cloud application delivery solutions.",
      "name": "go-login",
      "nbarId": "405",
      "p2pTechnology": "false",
      "popularity": 1,
      "rank": 65535,
      "references": "http://www.graphon.com/",
      "selectorId": "491",
      "subCategory": "e390124a-0fd6-4253-b68e-4b0c1af8cb4b",
      "trafficClass": "OPS_ADMIN_MGMT",
      "tunnel": "false"
    }
  ],
  "networkIdentity": [
    {
      "id": "37c8189b-b82d-4f34-9fe4-1d6c6555ba83",
      "instanceId": 13220,
      "authEntityId": 13220,
      "displayName": "13220",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "491",
      "protocol": "TCP",
      "upperPort": 0
    },
    {
      "id": "3316195b-1aae-48b8-a5a5-fb5d274988ad",
      "instanceId": 13221,
      "authEntityId": 13221,
      "displayName": "13221",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "491",
      "protocol": "UDP",
      "upperPort": 0
    }
  ],
  "parentScalableGroup": {
    "idRef": "7788bf2d-e289-4597-b500-17aacb7e820e"
  },
  "user": [

  ],
  "userGroup": [

  ]
},
{
  "id": "9fb7f251-13b7-48da-896e-64a82193339f",
  "instanceId": 4656,
  "authEntityId": 4656,
  "displayName": "4656",
  "authEntityClass": -1909073334,
  "deployPending": "NONE",
  "instanceVersion": 0,
  "createTime": 1517966743219,
  "deployed": false,
  "isSeeded": true,
  "isStale": false,
  "lastUpdateTime": 1517966743219,
  "name": "itunes-media",
  "namespace": "scalablegroup:application",
  "provisioningState": "DEFINED",
  "qualifier": "application",
  "resourceVersion": 0,
  "targetIdList": [

  ],
  "type": "scalablegroup",
  "cfsChangeInfo": [

  ],
  "customProvisions": [

  ],
  "scalableGroupExternalHandle": "itunes-media",
  "scalableGroupType": "APPLICATION",
  "securityGroupTag": 0,
  "state": "ACTIVE",
  "vnAgnostic": true,
  "identitySource": {
    "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
    "instanceId": 10010,
    "authEntityId": 10010,
    "displayName": "10010",
    "authEntityClass": 1738953278,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "state": "INACTIVE",
    "type": "NBAR"
  },
  "indicativeNetworkIdentity": [

  ],
  "networkApplications": [
    {
      "id": "76423d47-5e3e-45ca-89c4-eeacd22e8190",
      "instanceId": 11606,
      "authEntityId": 11606,
      "displayName": "11606",
      "authEntityClass": -217092956,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "applicationGroup": "other",
      "applicationType": "DEFAULT",
      "categoryId": "38a8e354-e2fe-4f3c-b1c5-2bbb90fb0d99",
      "createTime": 1517966743219,
      "encrypted": "false",
      "engineId": "13",
      "helpString": "Media player and media library application - Media streaming",
      "ignoreConflict": false,
      "lastUpdateTime": 1517966743219,
      "longDescription": "Media streaming for iTunes media player and media library application.",
      "name": "itunes-media",
      "nbarId": "1075",
      "p2pTechnology": "false",
      "popularity": 10,
      "rank": 65535,
      "references": "http://www.apple.com",
      "selectorId": "1075",
      "subCategory": "53ce0342-1e2d-455a-acc7-1b50db9d6845",
      "trafficClass": "MULTIMEDIA_CONFERENCING",
      "tunnel": "false"
    }
  ],
  "networkIdentity": [

  ],
  "parentScalableGroup": {
    "idRef": "aad510ce-fe95-4dfc-ad06-e494641cc016"
  },
  "user": [

  ],
  "userGroup": [

  ]
},
{
  "id": "9ff72dea-8dae-4155-bcb4-c3c19704c3b2",
  "instanceId": 4828,
  "authEntityId": 4828,
  "displayName": "4828",
  "authEntityClass": -1909073334,
  "deployPending": "NONE",
  "instanceVersion": 0,
  "createTime": 1517966743571,
  "deployed": false,
  "isSeeded": true,
  "isStale": false,
  "lastUpdateTime": 1517966743571,
  "name": "ndmp",
  "namespace": "scalablegroup:application",
  "provisioningState": "DEFINED",
  "qualifier": "application",
  "resourceVersion": 0,
  "targetIdList": [

  ],
  "type": "scalablegroup",
  "cfsChangeInfo": [

  ],
  "customProvisions": [

  ],
  "scalableGroupExternalHandle": "ndmp",
  "scalableGroupType": "APPLICATION",
  "securityGroupTag": 0,
  "state": "ACTIVE",
  "vnAgnostic": true,
  "identitySource": {
    "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
    "instanceId": 10010,
    "authEntityId": 10010,
    "displayName": "10010",
    "authEntityClass": 1738953278,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "state": "INACTIVE",
    "type": "NBAR"
  },
  "indicativeNetworkIdentity": [
    {
      "id": "17855b28-817b-4e4a-83fe-fc0bafebd767",
      "instanceId": 14119,
      "authEntityId": 14119,
      "displayName": "14119",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "10000",
      "protocol": "TCP",
      "upperPort": 0
    }
  ],
  "networkApplications": [
    {
      "id": "dd64f5b6-ba4d-40d0-a1bc-731a8b589af1",
      "instanceId": 11777,
      "authEntityId": 11777,
      "displayName": "11777",
      "authEntityClass": -217092956,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "appProtocol": "tcp",
      "applicationGroup": "other",
      "applicationType": "DEFAULT",
      "categoryId": "80909d4c-efe6-4878-9e1e-68281fd26edd",
      "createTime": 1517966743571,
      "encrypted": "false",
      "engineId": "3",
      "helpString": "Network Data Management Protocol",
      "ignoreConflict": false,
      "lastUpdateTime": 1517966743571,
      "longDescription": "Network Data Management Protocol (NDMP) is a protocol invented by the NetApp and Legato companies, meant to transport data between Network Attached Storage (NAS) devices and backup devices. This removes the need for transporting the data through the backup server itself, thus enhancing speed and removing load from the backup server.",
      "name": "ndmp",
      "nbarId": "1357",
      "p2pTechnology": "true",
      "popularity": 1,
      "rank": 65535,
      "references": "http://www.ndmp.org/",
      "selectorId": "10000",
      "subCategory": "4a197877-986d-4422-b48f-500fb4b3340f",
      "trafficClass": "BULK_DATA",
      "tunnel": "false"
    }
  ],
  "networkIdentity": [
    {
      "id": "44c44406-5ac3-4ff6-98a2-d5420581ab62",
      "instanceId": 14118,
      "authEntityId": 14118,
      "displayName": "14118",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "10000",
      "protocol": "TCP",
      "upperPort": 0
    }
  ],
  "parentScalableGroup": {
    "idRef": "f09a36de-df38-4ff8-b0be-56738afc75f7"
  },
  "user": [

  ],
  "userGroup": [

  ]
},
{
  "id": "a038c21d-100f-4953-bbf5-0cc08dea1802",
  "instanceId": 4136,
  "authEntityId": 4136,
  "displayName": "4136",
  "authEntityClass": -1909073334,
  "deployPending": "NONE",
  "instanceVersion": 0,
  "createTime": 1517966741316,
  "deployed": false,
  "isSeeded": true,
  "isStale": false,
  "lastUpdateTime": 1517966741316,
  "name": "at-zis",
  "namespace": "scalablegroup:application",
  "provisioningState": "DEFINED",
  "qualifier": "application",
  "resourceVersion": 0,
  "targetIdList": [

  ],
  "type": "scalablegroup",
  "cfsChangeInfo": [

  ],
  "customProvisions": [

  ],
  "scalableGroupExternalHandle": "at-zis",
  "scalableGroupType": "APPLICATION",
  "securityGroupTag": 0,
  "state": "ACTIVE",
  "vnAgnostic": true,
  "identitySource": {
    "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
    "instanceId": 10010,
    "authEntityId": 10010,
    "displayName": "10010",
    "authEntityClass": 1738953278,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "state": "INACTIVE",
    "type": "NBAR"
  },
  "indicativeNetworkIdentity": [
    {
      "id": "d313053d-ea19-46fb-91ff-1191c04a95c2",
      "instanceId": 12273,
      "authEntityId": 12273,
      "displayName": "12273",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "206",
      "protocol": "TCP",
      "upperPort": 0
    },
    {
      "id": "478375c1-247f-428d-8401-b4b27f1500d2",
      "instanceId": 12274,
      "authEntityId": 12274,
      "displayName": "12274",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "206",
      "protocol": "UDP",
      "upperPort": 0
    }
  ],
  "networkApplications": [
    {
      "id": "8476f379-d18c-450b-9baf-03e51994e0c3",
      "instanceId": 11098,
      "authEntityId": 11098,
      "displayName": "11098",
      "authEntityClass": -217092956,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "appProtocol": "tcp/udp",
      "applicationGroup": "apple-talk-group",
      "applicationType": "DEFAULT",
      "categoryId": "1fc17362-9334-4c7b-b752-5afdbb574017",
      "createTime": 1517966741316,
      "encrypted": "false",
      "engineId": "3",
      "helpString": "AppleTalk Zone Information",
      "ignoreConflict": false,
      "lastUpdateTime": 1517966741316,
      "longDescription": "The Zone Information Protocol was the protocol by which AppleTalk network numbers were associated with zone names. Azonewas a subdivision of the network that made sense to humans. While a network number had to be assigned to a topologically-contiguous section of the network, a zone could include several different discontiguous portions of the network.",
      "name": "at-zis",
      "nbarId": "1104",
      "p2pTechnology": "false",
      "popularity": 1,
      "rank": 65535,
      "references": "http://en.wikipedia.org/wiki/AppleTalk#Zone_Information_Protocol",
      "selectorId": "206",
      "subCategory": "f3c0f761-d894-4112-ab89-b90fc84fd660",
      "trafficClass": "OPS_ADMIN_MGMT",
      "tunnel": "false"
    }
  ],
  "networkIdentity": [
    {
      "id": "9ae78773-66a8-474f-b7b0-95943abe4ff5",
      "instanceId": 12272,
      "authEntityId": 12272,
      "displayName": "12272",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "206",
      "protocol": "TCP",
      "upperPort": 0
    },
    {
      "id": "b349f9b5-c4a7-4532-abf0-fee8db811f41",
      "instanceId": 12271,
      "authEntityId": 12271,
      "displayName": "12271",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "206",
      "protocol": "UDP",
      "upperPort": 0
    }
  ],
  "parentScalableGroup": {
    "idRef": "5fcd74d4-8726-47f7-a088-c457863da7ef"
  },
  "user": [

  ],
  "userGroup": [

  ]
},
{
  "id": "a087e59c-b684-4115-b31c-ed5a1a7cff7c",
  "instanceId": 4335,
  "authEntityId": 4335,
  "displayName": "4335",
  "authEntityClass": -1909073334,
  "deployPending": "NONE",
  "instanceVersion": 0,
  "createTime": 1517966742322,
  "deployed": false,
  "isSeeded": true,
  "isStale": false,
  "lastUpdateTime": 1517966742322,
  "name": "dcn-meas",
  "namespace": "scalablegroup:application",
  "provisioningState": "DEFINED",
  "qualifier": "application",
  "resourceVersion": 0,
  "targetIdList": [

  ],
  "type": "scalablegroup",
  "cfsChangeInfo": [

  ],
  "customProvisions": [

  ],
  "scalableGroupExternalHandle": "dcn-meas",
  "scalableGroupType": "APPLICATION",
  "securityGroupTag": 0,
  "state": "ACTIVE",
  "vnAgnostic": true,
  "identitySource": {
    "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
    "instanceId": 10010,
    "authEntityId": 10010,
    "displayName": "10010",
    "authEntityClass": 1738953278,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "state": "INACTIVE",
    "type": "NBAR"
  },
  "indicativeNetworkIdentity": [
    {
      "id": "2e01a270-3ac0-4caf-a310-48e293be087f",
      "instanceId": 12777,
      "authEntityId": 12777,
      "displayName": "12777",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipProtocolNumbers": "19",
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "protocol": "DCN_MEAS",
      "upperPort": 0
    }
  ],
  "networkApplications": [
    {
      "id": "d17dfc64-979c-4435-a40c-ac7d38657749",
      "instanceId": 11286,
      "authEntityId": 11286,
      "displayName": "11286",
      "authEntityClass": -217092956,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "appProtocol": "ip",
      "applicationGroup": "other",
      "applicationType": "DEFAULT",
      "categoryId": "6daa3dfd-3526-407a-88f0-97824b3f3a24",
      "createTime": 1517966742322,
      "encrypted": "unassigned",
      "engineId": "1",
      "helpString": "DCN Measurement Subsystems",
      "ignoreConflict": false,
      "lastUpdateTime": 1517966742322,
      "longDescription": "Registered with IANA as IP Protocol 19",
      "name": "dcn-meas",
      "nbarId": "773",
      "p2pTechnology": "unassigned",
      "popularity": 1,
      "rank": 65535,
      "references": "http://www.iana.org/assignments/protocol-numbers/protocol-numbers.xml",
      "selectorId": "19",
      "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
      "trafficClass": "BULK_DATA",
      "tunnel": "unassigned"
    }
  ],
  "networkIdentity": [
    {
      "id": "7cbc512b-0ba3-49f9-8686-f0e63ab8a9b0",
      "instanceId": 12776,
      "authEntityId": 12776,
      "displayName": "12776",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipProtocolNumbers": "19",
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "protocol": "DCN_MEAS",
      "upperPort": 0
    }
  ],
  "parentScalableGroup": {
    "idRef": "000c15bf-28fe-4fbb-9b69-35e6502721f1"
  },
  "user": [

  ],
  "userGroup": [

  ]
},
{
  "id": "a0eb553c-597b-43fd-a633-8a4c325aaa5d",
  "instanceId": 15249,
  "authEntityId": 15249,
  "displayName": "15249",
  "authEntityClass": -1909073334,
  "deployPending": "NONE",
  "instanceVersion": 0,
  "createTime": 1517966744578,
  "deployed": false,
  "isSeeded": true,
  "isStale": false,
  "lastUpdateTime": 1517966744578,
  "name": "tacacs",
  "namespace": "scalablegroup:application",
  "provisioningState": "DEFINED",
  "qualifier": "application",
  "resourceVersion": 0,
  "targetIdList": [

  ],
  "type": "scalablegroup",
  "cfsChangeInfo": [

  ],
  "customProvisions": [

  ],
  "scalableGroupExternalHandle": "tacacs",
  "scalableGroupType": "APPLICATION",
  "securityGroupTag": 0,
  "state": "ACTIVE",
  "vnAgnostic": true,
  "identitySource": {
    "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
    "instanceId": 10010,
    "authEntityId": 10010,
    "displayName": "10010",
    "authEntityClass": 1738953278,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "state": "INACTIVE",
    "type": "NBAR"
  },
  "indicativeNetworkIdentity": [
    {
      "id": "92efa98a-cfd3-4c43-aee5-099b205f7bc0",
      "instanceId": 17386,
      "authEntityId": 17386,
      "displayName": "17386",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "49,65",
      "protocol": "TCP",
      "upperPort": 0
    },
    {
      "id": "f4a10563-6e9d-4336-a92a-ef0ff0dc8c5f",
      "instanceId": 17387,
      "authEntityId": 17387,
      "displayName": "17387",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "49,65",
      "protocol": "UDP",
      "upperPort": 0
    }
  ],
  "networkApplications": [
    {
      "id": "b0f49e1f-5c3b-42b7-a242-82ec2ef9c25d",
      "instanceId": 16192,
      "authEntityId": 16192,
      "displayName": "16192",
      "authEntityClass": -217092956,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "appProtocol": "tcp/udp",
      "applicationGroup": "other",
      "applicationType": "DEFAULT",
      "categoryId": "1fc17362-9334-4c7b-b752-5afdbb574017",
      "createTime": 1517966744578,
      "encrypted": "false",
      "engineId": "3",
      "helpString": "Login Host Protocol",
      "ignoreConflict": false,
      "lastUpdateTime": 1517966744578,
      "longDescription": "Terminal Access Controller Access-Control System (TACACS) is client that provides an access control to network entities such as routers and network access servers. Users can log in to the entities as if they were hosts. Typically TACACS uses TCP/UDP ports 49, 65.",
      "name": "tacacs",
      "nbarId": "112",
      "p2pTechnology": "false",
      "popularity": 7,
      "rank": 65535,
      "references": "http://tools.ietf.org/html/draft-grant-tacacs-02",
      "selectorId": "49",
      "subCategory": "e390124a-0fd6-4253-b68e-4b0c1af8cb4b",
      "trafficClass": "OPS_ADMIN_MGMT",
      "tunnel": "false"
    }
  ],
  "networkIdentity": [
    {
      "id": "d10f55a9-f6b9-48a3-9ec0-f25186d0a7c5",
      "instanceId": 17384,
      "authEntityId": 17384,
      "displayName": "17384",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "49,65",
      "protocol": "UDP",
      "upperPort": 0
    },
    {
      "id": "907de537-80b4-41b6-8b69-354d6c6be112",
      "instanceId": 17385,
      "authEntityId": 17385,
      "displayName": "17385",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "49,65",
      "protocol": "TCP",
      "upperPort": 0
    }
  ],
  "parentScalableGroup": {
    "idRef": "7788bf2d-e289-4597-b500-17aacb7e820e"
  },
  "user": [

  ],
  "userGroup": [

  ]
},
{
  "id": "a0f81fe4-a5c1-4356-b00e-b9361e1f0f63",
  "instanceId": 4536,
  "authEntityId": 4536,
  "displayName": "4536",
  "authEntityClass": -1909073334,
  "deployPending": "NONE",
  "instanceVersion": 0,
  "createTime": 1517966742927,
  "deployed": false,
  "isSeeded": true,
  "isStale": false,
  "lastUpdateTime": 1517966742927,
  "name": "hello-port",
  "namespace": "scalablegroup:application",
  "provisioningState": "DEFINED",
  "qualifier": "application",
  "resourceVersion": 0,
  "targetIdList": [

  ],
  "type": "scalablegroup",
  "cfsChangeInfo": [

  ],
  "customProvisions": [

  ],
  "scalableGroupExternalHandle": "hello-port",
  "scalableGroupType": "APPLICATION",
  "securityGroupTag": 0,
  "state": "ACTIVE",
  "vnAgnostic": true,
  "identitySource": {
    "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
    "instanceId": 10010,
    "authEntityId": 10010,
    "displayName": "10010",
    "authEntityClass": 1738953278,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "state": "INACTIVE",
    "type": "NBAR"
  },
  "indicativeNetworkIdentity": [
    {
      "id": "61271b26-6d37-4784-800b-b8d13fd03e65",
      "instanceId": 13331,
      "authEntityId": 13331,
      "displayName": "13331",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "652",
      "protocol": "TCP",
      "upperPort": 0
    },
    {
      "id": "b8c87a09-beef-4362-89d9-821245f7a25b",
      "instanceId": 13332,
      "authEntityId": 13332,
      "displayName": "13332",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "652",
      "protocol": "UDP",
      "upperPort": 0
    }
  ],
  "networkApplications": [
    {
      "id": "80dbd1c4-bcae-41a6-b364-a7bef8fc60e8",
      "instanceId": 11487,
      "authEntityId": 11487,
      "displayName": "11487",
      "authEntityClass": -217092956,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "appProtocol": "tcp/udp",
      "applicationGroup": "other",
      "applicationType": "DEFAULT",
      "categoryId": "1fc17362-9334-4c7b-b752-5afdbb574017",
      "createTime": 1517966742927,
      "encrypted": "false",
      "engineId": "3",
      "helpString": "HELLO_PORT",
      "ignoreConflict": false,
      "lastUpdateTime": 1517966742927,
      "longDescription": "The Dynamic Tunnel Configuration Protocol (DTCP) protocol provides a means for receivers to dynamically discover the presence of feeds and to maintain a list of operational tunnel end-points. Feeds periodically announce their tunnel end-point addresses over the unidirectional link using the HELLO message.",
      "name": "hello-port",
      "nbarId": "561",
      "p2pTechnology": "false",
      "popularity": 1,
      "rank": 65535,
      "references": "http://tools.ietf.org/html/rfc3077",
      "selectorId": "652",
      "subCategory": "7cc1b3a6-4102-44e9-b522-0e71afe39a03",
      "trafficClass": "OPS_ADMIN_MGMT",
      "tunnel": "false"
    }
  ],
  "networkIdentity": [
    {
      "id": "ac8b6fd9-02b8-4cd6-9a95-da1ff7dd651e",
      "instanceId": 13329,
      "authEntityId": 13329,
      "displayName": "13329",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "652",
      "protocol": "UDP",
      "upperPort": 0
    },
    {
      "id": "3f7425cb-f04b-4dfe-88e4-5318263bd7b7",
      "instanceId": 13330,
      "authEntityId": 13330,
      "displayName": "13330",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "652",
      "protocol": "TCP",
      "upperPort": 0
    }
  ],
  "parentScalableGroup": {
    "idRef": "77e75842-e98b-4112-ad0c-300db4bb11be"
  },
  "user": [

  ],
  "userGroup": [

  ]
},
{
  "id": "a0fdf317-41f8-4214-a193-4b1ce9a08114",
  "instanceId": 4768,
  "authEntityId": 4768,
  "displayName": "4768",
  "authEntityClass": -1909073334,
  "deployPending": "NONE",
  "instanceVersion": 0,
  "createTime": 1517966743454,
  "deployed": false,
  "isSeeded": true,
  "isStale": false,
  "lastUpdateTime": 1517966743454,
  "name": "mpp",
  "namespace": "scalablegroup:application",
  "provisioningState": "DEFINED",
  "qualifier": "application",
  "resourceVersion": 0,
  "targetIdList": [

  ],
  "type": "scalablegroup",
  "cfsChangeInfo": [

  ],
  "customProvisions": [

  ],
  "scalableGroupExternalHandle": "mpp",
  "scalableGroupType": "APPLICATION",
  "securityGroupTag": 0,
  "state": "ACTIVE",
  "vnAgnostic": true,
  "identitySource": {
    "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
    "instanceId": 10010,
    "authEntityId": 10010,
    "displayName": "10010",
    "authEntityClass": 1738953278,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "state": "INACTIVE",
    "type": "NBAR"
  },
  "indicativeNetworkIdentity": [
    {
      "id": "0a168b32-b5c6-48b7-a4cd-977115c2b31e",
      "instanceId": 13964,
      "authEntityId": 13964,
      "displayName": "13964",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "218",
      "protocol": "UDP",
      "upperPort": 0
    },
    {
      "id": "7fce19e0-eb8b-4dd0-ba4e-738f08642032",
      "instanceId": 13965,
      "authEntityId": 13965,
      "displayName": "13965",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "218",
      "protocol": "TCP",
      "upperPort": 0
    }
  ],
  "networkApplications": [
    {
      "id": "d03e072a-2c01-4119-859d-f901f06c9c8b",
      "instanceId": 11718,
      "authEntityId": 11718,
      "displayName": "11718",
      "authEntityClass": -217092956,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "appProtocol": "tcp/udp",
      "applicationGroup": "other",
      "applicationType": "DEFAULT",
      "categoryId": "27953c60-b2ff-4e1a-bc52-0187d8295250",
      "createTime": 1517966743454,
      "encrypted": "false",
      "engineId": "3",
      "helpString": "Netix Message Posting Protocol",
      "ignoreConflict": false,
      "lastUpdateTime": 1517966743454,
      "longDescription": "Message Posting Protocol (MPP) is a network protocol that is used for posting messages from a computer to a mail service host. It is based on 2 basic structures: command and reply.",
      "name": "mpp",
      "nbarId": "1115",
      "p2pTechnology": "false",
      "popularity": 1,
      "rank": 65535,
      "references": "http://tools.ietf.org/html/rfc1204",
      "selectorId": "218",
      "subCategory": "b90786f4-0190-45b8-9924-21b3e9682590",
      "trafficClass": "BULK_DATA",
      "tunnel": "false"
    }
  ],
  "networkIdentity": [
    {
      "id": "a72941c5-32cb-4e97-b373-606f1eb74965",
      "instanceId": 13962,
      "authEntityId": 13962,
      "displayName": "13962",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "218",
      "protocol": "TCP",
      "upperPort": 0
    },
    {
      "id": "4630bb6e-dedb-4134-a548-32064465baee",
      "instanceId": 13963,
      "authEntityId": 13963,
      "displayName": "13963",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "218",
      "protocol": "UDP",
      "upperPort": 0
    }
  ],
  "parentScalableGroup": {
    "idRef": "8a7ca43a-32bd-40d2-871a-02220a165cb7"
  },
  "user": [

  ],
  "userGroup": [

  ]
},
{
  "id": "a1440f71-47ce-41af-af42-27475afbefdf",
  "instanceId": 4189,
  "authEntityId": 4189,
  "displayName": "4189",
  "authEntityClass": -1909073334,
  "deployPending": "NONE",
  "instanceVersion": 0,
  "createTime": 1517966741618,
  "deployed": false,
  "isSeeded": true,
  "isStale": false,
  "lastUpdateTime": 1517966741618,
  "name": "bleacher-report",
  "namespace": "scalablegroup:application",
  "provisioningState": "DEFINED",
  "qualifier": "application",
  "resourceVersion": 0,
  "targetIdList": [

  ],
  "type": "scalablegroup",
  "cfsChangeInfo": [

  ],
  "customProvisions": [

  ],
  "scalableGroupExternalHandle": "bleacher-report",
  "scalableGroupType": "APPLICATION",
  "securityGroupTag": 0,
  "state": "ACTIVE",
  "vnAgnostic": true,
  "identitySource": {
    "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
    "instanceId": 10010,
    "authEntityId": 10010,
    "displayName": "10010",
    "authEntityClass": 1738953278,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "state": "INACTIVE",
    "type": "NBAR"
  },
  "indicativeNetworkIdentity": [

  ],
  "networkApplications": [
    {
      "id": "5053e927-160e-4d1e-8ed2-d6e2f54391c7",
      "instanceId": 11148,
      "authEntityId": 11148,
      "displayName": "11148",
      "authEntityClass": -217092956,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "appProtocol": "tcp/udp",
      "applicationGroup": "other",
      "applicationType": "DEFAULT",
      "categoryId": "878644b5-fe91-45b4-9204-31c84a73ba48",
      "createTime": 1517966741618,
      "encrypted": "false",
      "engineId": "13",
      "helpString": "Bleacher Report - Web Portal for Sports news update.",
      "ignoreConflict": false,
      "lastUpdateTime": 1517966741618,
      "longDescription": "Bleacher Report - Web Portal for Sports news update.",
      "name": "bleacher-report",
      "nbarId": "1606",
      "p2pTechnology": "false",
      "popularity": 3,
      "rank": 65535,
      "references": "http://bleacherreport.com",
      "selectorId": "676",
      "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
      "trafficClass": "TRANSACTIONAL_DATA",
      "tunnel": "false"
    }
  ],
  "networkIdentity": [
    {
      "id": "fe67635d-3a1f-4976-9cf1-8b4cb672ea46",
      "instanceId": 12407,
      "authEntityId": 12407,
      "displayName": "12407",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "53,80,443,5353",
      "protocol": "TCP",
      "upperPort": 0
    },
    {
      "id": "0153ba83-c315-455a-9f85-1223c8dd5365",
      "instanceId": 12408,
      "authEntityId": 12408,
      "displayName": "12408",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "53,5353",
      "protocol": "UDP",
      "upperPort": 0
    }
  ],
  "parentScalableGroup": {
    "idRef": "28e08de4-d7b2-4521-84ce-bc1e5ee0b9cc"
  },
  "user": [

  ],
  "userGroup": [

  ]
},
{
  "id": "a15c4b83-fdcd-46fb-8f0a-3be307ff855c",
  "instanceId": 15367,
  "authEntityId": 15367,
  "displayName": "15367",
  "authEntityClass": -1909073334,
  "deployPending": "NONE",
  "instanceVersion": 0,
  "createTime": 1517966744818,
  "deployed": false,
  "isSeeded": true,
  "isStale": false,
  "lastUpdateTime": 1517966744818,
  "name": "vpp",
  "namespace": "scalablegroup:application",
  "provisioningState": "DEFINED",
  "qualifier": "application",
  "resourceVersion": 0,
  "targetIdList": [

  ],
  "type": "scalablegroup",
  "cfsChangeInfo": [

  ],
  "customProvisions": [

  ],
  "scalableGroupExternalHandle": "vpp",
  "scalableGroupType": "APPLICATION",
  "securityGroupTag": 0,
  "state": "ACTIVE",
  "vnAgnostic": true,
  "identitySource": {
    "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
    "instanceId": 10010,
    "authEntityId": 10010,
    "displayName": "10010",
    "authEntityClass": 1738953278,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "state": "INACTIVE",
    "type": "NBAR"
  },
  "indicativeNetworkIdentity": [
    {
      "id": "1e63053a-a86b-4bd3-b621-b447c5ae8e74",
      "instanceId": 17715,
      "authEntityId": 17715,
      "displayName": "17715",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "677",
      "protocol": "TCP",
      "upperPort": 0
    },
    {
      "id": "5ced0cf3-e5b4-4ce0-a018-d0d327b17cc9",
      "instanceId": 17716,
      "authEntityId": 17716,
      "displayName": "17716",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "677",
      "protocol": "UDP",
      "upperPort": 0
    }
  ],
  "networkApplications": [
    {
      "id": "4a00cda9-76c2-46f4-b7f9-7ce63208f685",
      "instanceId": 16310,
      "authEntityId": 16310,
      "displayName": "16310",
      "authEntityClass": -217092956,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "appProtocol": "tcp/udp",
      "applicationGroup": "other",
      "applicationType": "DEFAULT",
      "categoryId": "6daa3dfd-3526-407a-88f0-97824b3f3a24",
      "createTime": 1517966744818,
      "encrypted": "false",
      "engineId": "3",
      "helpString": "Virtual Presence Protocol",
      "ignoreConflict": false,
      "lastUpdateTime": 1517966744818,
      "longDescription": "The Virtual Presence Protocol (VPP) is used to enable the exchange of document based virtual presence information. Virtual presence information is the foundation for virtual neighborhood services which provide users with information about virtual neighbors, i.e. other users who are close within the virtual document space. VPP enables the creation of dynamic vicinities based on hypertext references. It is not meant to replace or supersede presence notification protocols, but it augments online presence with location information. The purpose of presence notification protocols is to tell whether another individual is online or arrives online, etc.",
      "name": "vpp",
      "nbarId": "585",
      "p2pTechnology": "false",
      "popularity": 1,
      "rank": 65535,
      "references": "http://tools.ietf.org/id/draft-wolf-vpp-01.txt",
      "selectorId": "677",
      "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
      "trafficClass": "BULK_DATA",
      "tunnel": "false"
    }
  ],
  "networkIdentity": [
    {
      "id": "607cefc8-5de4-4140-b1c5-a2ee96101d7a",
      "instanceId": 17713,
      "authEntityId": 17713,
      "displayName": "17713",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "677",
      "protocol": "UDP",
      "upperPort": 0
    },
    {
      "id": "6fdd778e-3cee-4be1-a15e-38fbe1e6f913",
      "instanceId": 17714,
      "authEntityId": 17714,
      "displayName": "17714",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "677",
      "protocol": "TCP",
      "upperPort": 0
    }
  ],
  "parentScalableGroup": {
    "idRef": "000c15bf-28fe-4fbb-9b69-35e6502721f1"
  },
  "user": [

  ],
  "userGroup": [

  ]
},
{
  "id": "a164850a-af33-41f9-ba0c-921b8bcc77d1",
  "instanceId": 15307,
  "authEntityId": 15307,
  "displayName": "15307",
  "authEntityClass": -1909073334,
  "deployPending": "NONE",
  "instanceVersion": 0,
  "createTime": 1517966744707,
  "deployed": false,
  "isSeeded": true,
  "isStale": false,
  "lastUpdateTime": 1517966744707,
  "name": "ttp",
  "namespace": "scalablegroup:application",
  "provisioningState": "DEFINED",
  "qualifier": "application",
  "resourceVersion": 0,
  "targetIdList": [

  ],
  "type": "scalablegroup",
  "cfsChangeInfo": [

  ],
  "customProvisions": [

  ],
  "scalableGroupExternalHandle": "ttp",
  "scalableGroupType": "APPLICATION",
  "securityGroupTag": 0,
  "state": "ACTIVE",
  "vnAgnostic": true,
  "identitySource": {
    "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
    "instanceId": 10010,
    "authEntityId": 10010,
    "displayName": "10010",
    "authEntityClass": 1738953278,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "state": "INACTIVE",
    "type": "NBAR"
  },
  "indicativeNetworkIdentity": [
    {
      "id": "abfc4c1d-4230-4c66-ab50-69ec11766308",
      "instanceId": 17545,
      "authEntityId": 17545,
      "displayName": "17545",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipProtocolNumbers": "84",
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "protocol": "TTP",
      "upperPort": 0
    }
  ],
  "networkApplications": [
    {
      "id": "03183994-118a-46a6-88c1-2e8a4d3fc353",
      "instanceId": 16250,
      "authEntityId": 16250,
      "displayName": "16250",
      "authEntityClass": -217092956,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "appProtocol": "ip",
      "applicationGroup": "other",
      "applicationType": "DEFAULT",
      "categoryId": "6daa3dfd-3526-407a-88f0-97824b3f3a24",
      "createTime": 1517966744707,
      "encrypted": "unassigned",
      "engineId": "1",
      "helpString": "TTP",
      "ignoreConflict": false,
      "lastUpdateTime": 1517966744707,
      "longDescription": "Registered with IANA as IP Protocol 84",
      "name": "ttp",
      "nbarId": "838",
      "p2pTechnology": "unassigned",
      "popularity": 1,
      "rank": 65535,
      "references": "http://www.iana.org/assignments/protocol-numbers/protocol-numbers.xml",
      "selectorId": "84",
      "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
      "trafficClass": "BULK_DATA",
      "tunnel": "unassigned"
    }
  ],
  "networkIdentity": [
    {
      "id": "eb886eb8-0cea-4946-bb59-8aed0cd2243e",
      "instanceId": 17544,
      "authEntityId": 17544,
      "displayName": "17544",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipProtocolNumbers": "84",
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "protocol": "TTP",
      "upperPort": 0
    }
  ],
  "parentScalableGroup": {
    "idRef": "000c15bf-28fe-4fbb-9b69-35e6502721f1"
  },
  "user": [

  ],
  "userGroup": [

  ]
},
{
  "id": "a1b85049-7df2-4012-9f3d-de80a2a7f1c0",
  "instanceId": 4079,
  "authEntityId": 4079,
  "displayName": "4079",
  "authEntityClass": -1909073334,
  "deployPending": "NONE",
  "instanceVersion": 0,
  "createTime": 1517966740927,
  "deployed": false,
  "isSeeded": true,
  "isStale": false,
  "lastUpdateTime": 1517966740927,
  "name": "amazon-web-services",
  "namespace": "scalablegroup:application",
  "provisioningState": "DEFINED",
  "qualifier": "application",
  "resourceVersion": 0,
  "targetIdList": [

  ],
  "type": "scalablegroup",
  "cfsChangeInfo": [

  ],
  "customProvisions": [

  ],
  "scalableGroupExternalHandle": "amazon-web-services",
  "scalableGroupType": "APPLICATION",
  "securityGroupTag": 0,
  "state": "ACTIVE",
  "vnAgnostic": true,
  "identitySource": {
    "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
    "instanceId": 10010,
    "authEntityId": 10010,
    "displayName": "10010",
    "authEntityClass": 1738953278,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "state": "INACTIVE",
    "type": "NBAR"
  },
  "indicativeNetworkIdentity": [

  ],
  "networkApplications": [
    {
      "id": "22e7fe07-77e5-4b49-bc2c-9033e5211dee",
      "instanceId": 11045,
      "authEntityId": 11045,
      "displayName": "11045",
      "authEntityClass": -217092956,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "appProtocol": "tcp/udp",
      "applicationGroup": "other",
      "applicationType": "DEFAULT",
      "categoryId": "80909d4c-efe6-4878-9e1e-68281fd26edd",
      "createTime": 1517966740921,
      "encrypted": "true",
      "engineId": "13",
      "helpString": "Amazon collection of remote computing services",
      "ignoreConflict": false,
      "lastUpdateTime": 1517966740921,
      "longDescription": "Amazon web services is a collection of remote computing services that together make up a cloud computing platform. Amongst its most popular services are EC2 and S3. The service is known for having large capacity and being faster and cheaper than a physical server farm.",
      "name": "amazon-web-services",
      "nbarId": "1542",
      "p2pTechnology": "false",
      "popularity": 7,
      "rank": 65535,
      "references": "http://aws.amazon.com/",
      "selectorId": "603",
      "subCategory": "8320328d-5e88-43b4-a6f1-d148749ef1a3",
      "trafficClass": "BULK_DATA",
      "tunnel": "false"
    }
  ],
  "networkIdentity": [
    {
      "id": "2aa2565f-9957-4061-95c7-0a73d4abc0b2",
      "instanceId": 12112,
      "authEntityId": 12112,
      "displayName": "12112",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "53,80,443,5353",
      "protocol": "TCP",
      "upperPort": 0
    },
    {
      "id": "0f02431d-e50c-4856-baf6-165114809cc5",
      "instanceId": 12113,
      "authEntityId": 12113,
      "displayName": "12113",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "53,5353",
      "protocol": "UDP",
      "upperPort": 0
    }
  ],
  "parentScalableGroup": {
    "idRef": "53f03553-069a-4046-ad7c-84610eb579c5"
  },
  "user": [

  ],
  "userGroup": [

  ]
},
{
  "id": "a1cb18e0-9cc9-43d4-bf56-79e09802ed13",
  "instanceId": 4478,
  "authEntityId": 4478,
  "displayName": "4478",
  "authEntityClass": -1909073334,
  "deployPending": "NONE",
  "instanceVersion": 0,
  "createTime": 1517966742771,
  "deployed": false,
  "isSeeded": true,
  "isStale": false,
  "lastUpdateTime": 1517966742771,
  "name": "genrad-mux",
  "namespace": "scalablegroup:application",
  "provisioningState": "DEFINED",
  "qualifier": "application",
  "resourceVersion": 0,
  "targetIdList": [

  ],
  "type": "scalablegroup",
  "cfsChangeInfo": [

  ],
  "customProvisions": [

  ],
  "scalableGroupExternalHandle": "genrad-mux",
  "scalableGroupType": "APPLICATION",
  "securityGroupTag": 0,
  "state": "ACTIVE",
  "vnAgnostic": true,
  "identitySource": {
    "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
    "instanceId": 10010,
    "authEntityId": 10010,
    "displayName": "10010",
    "authEntityClass": 1738953278,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "state": "INACTIVE",
    "type": "NBAR"
  },
  "indicativeNetworkIdentity": [
    {
      "id": "d5bcba60-8c93-4c25-b6ce-f619ab407a96",
      "instanceId": 13195,
      "authEntityId": 13195,
      "displayName": "13195",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "176",
      "protocol": "UDP",
      "upperPort": 0
    },
    {
      "id": "a6b151e2-f608-411c-a1e0-1a75e099d98b",
      "instanceId": 13196,
      "authEntityId": 13196,
      "displayName": "13196",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "176",
      "protocol": "TCP",
      "upperPort": 0
    }
  ],
  "networkApplications": [
    {
      "id": "bd138db0-b786-40d0-8519-b67489d29137",
      "instanceId": 11429,
      "authEntityId": 11429,
      "displayName": "11429",
      "authEntityClass": -217092956,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "appProtocol": "tcp/udp",
      "applicationGroup": "other",
      "applicationType": "DEFAULT",
      "categoryId": "6daa3dfd-3526-407a-88f0-97824b3f3a24",
      "createTime": 1517966742771,
      "encrypted": "false",
      "engineId": "3",
      "helpString": "genrad-mux",
      "ignoreConflict": false,
      "lastUpdateTime": 1517966742771,
      "longDescription": "Registered with IANA on port 176 TCP/UDP",
      "name": "genrad-mux",
      "nbarId": "1021",
      "p2pTechnology": "false",
      "popularity": 1,
      "rank": 65535,
      "references": "http://www.iana.org/assignments/service-names-port-numbers/service-names-port-numbers.xml",
      "selectorId": "176",
      "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
      "trafficClass": "BULK_DATA",
      "tunnel": "false"
    }
  ],
  "networkIdentity": [
    {
      "id": "d145dac2-8198-49b0-aa07-fc7cd9773893",
      "instanceId": 13193,
      "authEntityId": 13193,
      "displayName": "13193",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "176",
      "protocol": "TCP",
      "upperPort": 0
    },
    {
      "id": "f7aec9d4-cf31-48d8-8d0f-7c17a57a2118",
      "instanceId": 13194,
      "authEntityId": 13194,
      "displayName": "13194",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "176",
      "protocol": "UDP",
      "upperPort": 0
    }
  ],
  "parentScalableGroup": {
    "idRef": "000c15bf-28fe-4fbb-9b69-35e6502721f1"
  },
  "user": [

  ],
  "userGroup": [

  ]
},
{
  "id": "a23147b2-f9ef-40fa-b0d3-9d9137035ea7",
  "instanceId": 4667,
  "authEntityId": 4667,
  "displayName": "4667",
  "authEntityClass": -1909073334,
  "deployPending": "NONE",
  "instanceVersion": 0,
  "createTime": 1517966743242,
  "deployed": false,
  "isSeeded": true,
  "isStale": false,
  "lastUpdateTime": 1517966743242,
  "name": "kakao-services",
  "namespace": "scalablegroup:application",
  "provisioningState": "DEFINED",
  "qualifier": "application",
  "resourceVersion": 0,
  "targetIdList": [

  ],
  "type": "scalablegroup",
  "cfsChangeInfo": [

  ],
  "customProvisions": [

  ],
  "scalableGroupExternalHandle": "kakao-services",
  "scalableGroupType": "APPLICATION",
  "securityGroupTag": 0,
  "state": "ACTIVE",
  "vnAgnostic": true,
  "identitySource": {
    "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
    "instanceId": 10010,
    "authEntityId": 10010,
    "displayName": "10010",
    "authEntityClass": 1738953278,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "state": "INACTIVE",
    "type": "NBAR"
  },
  "indicativeNetworkIdentity": [

  ],
  "networkApplications": [
    {
      "id": "a21f584d-df3c-43c9-ba34-3431d465c45b",
      "instanceId": 11617,
      "authEntityId": 11617,
      "displayName": "11617",
      "authEntityClass": -217092956,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "appProtocol": "tcp/udp",
      "applicationGroup": "kakao-group",
      "applicationType": "DEFAULT",
      "categoryId": "82a7b7fa-da5a-48f5-8b6a-a2546b947843",
      "createTime": 1517966743242,
      "encrypted": "true",
      "engineId": "13",
      "helpString": "Set of tools and APIs used by Kakao applications",
      "ignoreConflict": false,
      "lastUpdateTime": 1517966743242,
      "longDescription": "kakao-services is a set of tools and APIs used by Kakao applications such as: kakao story, kakao music, kakao website and etc.",
      "name": "kakao-services",
      "nbarId": "1522",
      "p2pTechnology": "false",
      "popularity": 8,
      "rank": 65535,
      "references": "http://www.kakao.com/",
      "selectorId": "583",
      "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
      "trafficClass": "BULK_DATA",
      "tunnel": "false"
    }
  ],
  "networkIdentity": [
    {
      "id": "a5a11acb-1447-49f9-becf-8571bf6fe933",
      "instanceId": 13674,
      "authEntityId": 13674,
      "displayName": "13674",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "53,5353",
      "protocol": "UDP",
      "upperPort": 0
    },
    {
      "id": "48661605-8362-4c83-8ea3-8ad23dede5e5",
      "instanceId": 13675,
      "authEntityId": 13675,
      "displayName": "13675",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "53,80,443,5353",
      "protocol": "TCP",
      "upperPort": 0
    }
  ],
  "parentScalableGroup": {
    "idRef": "fd6264c9-c16a-4311-a335-a3db039256f3"
  },
  "user": [

  ],
  "userGroup": [

  ]
},
{
  "id": "a2864168-85e8-4139-a29c-5d4a15d2728a",
  "instanceId": 15434,
  "authEntityId": 15434,
  "displayName": "15434",
  "authEntityClass": -1909073334,
  "deployPending": "NONE",
  "instanceVersion": 0,
  "createTime": 1517966744958,
  "deployed": false,
  "isSeeded": true,
  "isStale": false,
  "lastUpdateTime": 1517966744958,
  "name": "xdmcp",
  "namespace": "scalablegroup:application",
  "provisioningState": "DEFINED",
  "qualifier": "application",
  "resourceVersion": 0,
  "targetIdList": [

  ],
  "type": "scalablegroup",
  "cfsChangeInfo": [

  ],
  "customProvisions": [

  ],
  "scalableGroupExternalHandle": "xdmcp",
  "scalableGroupType": "APPLICATION",
  "securityGroupTag": 0,
  "state": "ACTIVE",
  "vnAgnostic": true,
  "identitySource": {
    "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
    "instanceId": 10010,
    "authEntityId": 10010,
    "displayName": "10010",
    "authEntityClass": 1738953278,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "state": "INACTIVE",
    "type": "NBAR"
  },
  "indicativeNetworkIdentity": [
    {
      "id": "9ea2bf17-4a18-4993-ba35-1d2bf615d91d",
      "instanceId": 17885,
      "authEntityId": 17885,
      "displayName": "17885",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "177",
      "protocol": "TCP",
      "upperPort": 0
    },
    {
      "id": "23780969-c3cc-4ade-8b8c-d54e720f7b1d",
      "instanceId": 17886,
      "authEntityId": 17886,
      "displayName": "17886",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "177",
      "protocol": "UDP",
      "upperPort": 0
    }
  ],
  "networkApplications": [
    {
      "id": "5017bef2-0224-4d67-9caf-bdb78fc21077",
      "instanceId": 16377,
      "authEntityId": 16377,
      "displayName": "16377",
      "authEntityClass": -217092956,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "appProtocol": "tcp/udp",
      "applicationGroup": "other",
      "applicationType": "DEFAULT",
      "categoryId": "1fc17362-9334-4c7b-b752-5afdbb574017",
      "createTime": 1517966744958,
      "encrypted": "false",
      "engineId": "3",
      "helpString": "X Display Manager Control Protocol",
      "ignoreConflict": false,
      "lastUpdateTime": 1517966744958,
      "longDescription": "In the X Window System, an X display manager runs as a program that allows the starting of a session on an X server from the same or another computer.",
      "name": "xdmcp",
      "nbarId": "109",
      "p2pTechnology": "false",
      "popularity": 1,
      "rank": 65535,
      "references": "http://en.wikipedia.org/wiki/X_display_manager_(program_type)",
      "selectorId": "177",
      "subCategory": "787a7d77-977b-4974-89d3-379ba49e88b7",
      "trafficClass": "OPS_ADMIN_MGMT",
      "tunnel": "false"
    }
  ],
  "networkIdentity": [
    {
      "id": "aed708d2-b443-4ecc-a8e7-8e806d3fdb04",
      "instanceId": 17883,
      "authEntityId": 17883,
      "displayName": "17883",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "177",
      "protocol": "TCP",
      "upperPort": 0
    },
    {
      "id": "32098aa3-2562-4117-b632-a076cc469556",
      "instanceId": 17884,
      "authEntityId": 17884,
      "displayName": "17884",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "177",
      "protocol": "UDP",
      "upperPort": 0
    }
  ],
  "parentScalableGroup": {
    "idRef": "e06dd89f-0a70-4422-ab0b-c2314650a9a7"
  },
  "user": [

  ],
  "userGroup": [

  ]
},
{
  "id": "a2d1a2c8-3d48-42eb-9eb3-37c043af64c4",
  "instanceId": 4562,
  "authEntityId": 4562,
  "displayName": "4562",
  "authEntityClass": -1909073334,
  "deployPending": "NONE",
  "instanceVersion": 0,
  "createTime": 1517966742994,
  "deployed": false,
  "isSeeded": true,
  "isStale": false,
  "lastUpdateTime": 1517966742994,
  "name": "http-rpc-epmap",
  "namespace": "scalablegroup:application",
  "provisioningState": "DEFINED",
  "qualifier": "application",
  "resourceVersion": 0,
  "targetIdList": [

  ],
  "type": "scalablegroup",
  "cfsChangeInfo": [

  ],
  "customProvisions": [

  ],
  "scalableGroupExternalHandle": "http-rpc-epmap",
  "scalableGroupType": "APPLICATION",
  "securityGroupTag": 0,
  "state": "ACTIVE",
  "vnAgnostic": true,
  "identitySource": {
    "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
    "instanceId": 10010,
    "authEntityId": 10010,
    "displayName": "10010",
    "authEntityClass": 1738953278,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "state": "INACTIVE",
    "type": "NBAR"
  },
  "indicativeNetworkIdentity": [
    {
      "id": "27528915-89eb-4904-9727-21b805498c85",
      "instanceId": 13397,
      "authEntityId": 13397,
      "displayName": "13397",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "593",
      "protocol": "UDP",
      "upperPort": 0
    },
    {
      "id": "789f15ff-71f0-445d-b069-1415ee600dcf",
      "instanceId": 13398,
      "authEntityId": 13398,
      "displayName": "13398",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "593",
      "protocol": "TCP",
      "upperPort": 0
    }
  ],
  "networkApplications": [
    {
      "id": "66abca9f-05c0-4410-af41-75152179ffa2",
      "instanceId": 11512,
      "authEntityId": 11512,
      "displayName": "11512",
      "authEntityClass": -217092956,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "appProtocol": "tcp/udp",
      "applicationGroup": "other",
      "applicationType": "DEFAULT",
      "categoryId": "58aa61dc-dd95-4a6d-9c7c-0ebf16da6dde",
      "createTime": 1517966742993,
      "encrypted": "false",
      "engineId": "3",
      "helpString": "HTTP RPC Ep Map",
      "ignoreConflict": false,
      "lastUpdateTime": 1517966742993,
      "longDescription": "The http-rpc-epmap endpoint mapper provides CIS (COM+ Internet Services) parameters for RPC (Remote Procedure Call).",
      "name": "http-rpc-epmap",
      "nbarId": "507",
      "p2pTechnology": "false",
      "popularity": 1,
      "rank": 65535,
      "references": "http://www.cavionplus.com/pdfs/RVA_Sample.pdf",
      "selectorId": "593",
      "subCategory": "b90786f4-0190-45b8-9924-21b3e9682590",
      "trafficClass": "TRANSACTIONAL_DATA",
      "tunnel": "false"
    }
  ],
  "networkIdentity": [
    {
      "id": "1d402f47-43bf-4a62-a8e1-32091b72b828",
      "instanceId": 13395,
      "authEntityId": 13395,
      "displayName": "13395",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "593",
      "protocol": "TCP",
      "upperPort": 0
    },
    {
      "id": "5116d667-d7c3-43b9-a1b5-f0d0920eafa6",
      "instanceId": 13396,
      "authEntityId": 13396,
      "displayName": "13396",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "593",
      "protocol": "UDP",
      "upperPort": 0
    }
  ],
  "parentScalableGroup": {
    "idRef": "cff11752-fafb-4e22-9e6f-11f44f59a9a3"
  },
  "user": [

  ],
  "userGroup": [

  ]
},
{
  "id": "a2d8febf-9984-465d-978f-3b33d4b4ed9a",
  "instanceId": 15266,
  "authEntityId": 15266,
  "displayName": "15266",
  "authEntityClass": -1909073334,
  "deployPending": "NONE",
  "instanceVersion": 0,
  "createTime": 1517966744616,
  "deployed": false,
  "isSeeded": true,
  "isStale": false,
  "lastUpdateTime": 1517966744616,
  "name": "telepresence-control",
  "namespace": "scalablegroup:application",
  "provisioningState": "DEFINED",
  "qualifier": "application",
  "resourceVersion": 0,
  "targetIdList": [

  ],
  "type": "scalablegroup",
  "cfsChangeInfo": [

  ],
  "customProvisions": [

  ],
  "scalableGroupExternalHandle": "telepresence-control",
  "scalableGroupType": "APPLICATION",
  "securityGroupTag": 0,
  "state": "ACTIVE",
  "vnAgnostic": true,
  "identitySource": {
    "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
    "instanceId": 10010,
    "authEntityId": 10010,
    "displayName": "10010",
    "authEntityClass": 1738953278,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "state": "INACTIVE",
    "type": "NBAR"
  },
  "indicativeNetworkIdentity": [

  ],
  "networkApplications": [
    {
      "id": "25760ce1-d906-429c-989b-13056684b1f7",
      "instanceId": 16209,
      "authEntityId": 16209,
      "displayName": "16209",
      "authEntityClass": -217092956,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "appProtocol": "tcp/udp",
      "applicationGroup": "telepresence-group",
      "applicationType": "DEFAULT",
      "categoryId": "38a8e354-e2fe-4f3c-b1c5-2bbb90fb0d99",
      "createTime": 1517966744616,
      "encrypted": "false",
      "engineId": "13",
      "helpString": "Cisco Telepresence-control",
      "ignoreConflict": false,
      "lastUpdateTime": 1517966744616,
      "longDescription": "Cisco TelePresence Control protocol is used for the audio and HD video interactive conferencing of the telepresence device. The communication is done over the Internet. The control protocol includes control packets over SIP and RTCP protocols, and works simultaneously with Cisco telepresence media protocol.",
      "name": "telepresence-control",
      "nbarId": "114",
      "p2pTechnology": "false",
      "popularity": 10,
      "rank": 65535,
      "references": "http://www.cisco.com/en/US/solutions/ns669/public_telepresence.html",
      "selectorId": "114",
      "subCategory": "68c4f989-5735-4e16-8d1c-6a9d89d5df9c",
      "trafficClass": "SIGNALING",
      "tunnel": "false"
    }
  ],
  "networkIdentity": [
    {
      "id": "eb8431f4-86be-4b1a-b09b-eadeb506d43a",
      "instanceId": 17429,
      "authEntityId": 17429,
      "displayName": "17429",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "53,80,443,5060,5353",
      "protocol": "TCP",
      "upperPort": 0
    },
    {
      "id": "ffd0cda3-3614-4581-af74-2b5025ac0767",
      "instanceId": 17430,
      "authEntityId": 17430,
      "displayName": "17430",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "53,5060,5353",
      "protocol": "UDP",
      "upperPort": 0
    }
  ],
  "parentScalableGroup": {
    "idRef": "60f1364d-2a02-4063-8ba1-07817abe0612"
  },
  "user": [

  ],
  "userGroup": [

  ]
},
{
  "id": "a2e485b6-ba17-4c2e-99a7-a02f8fe48ac2",
  "instanceId": 15069,
  "authEntityId": 15069,
  "displayName": "15069",
  "authEntityClass": -1909073334,
  "deployPending": "NONE",
  "instanceVersion": 0,
  "createTime": 1517966744161,
  "deployed": false,
  "isSeeded": true,
  "isStale": false,
  "lastUpdateTime": 1517966744161,
  "name": "rsvp_tunnel",
  "namespace": "scalablegroup:application",
  "provisioningState": "DEFINED",
  "qualifier": "application",
  "resourceVersion": 0,
  "targetIdList": [

  ],
  "type": "scalablegroup",
  "cfsChangeInfo": [

  ],
  "customProvisions": [

  ],
  "scalableGroupExternalHandle": "rsvp_tunnel",
  "scalableGroupType": "APPLICATION",
  "securityGroupTag": 0,
  "state": "ACTIVE",
  "vnAgnostic": true,
  "identitySource": {
    "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
    "instanceId": 10010,
    "authEntityId": 10010,
    "displayName": "10010",
    "authEntityClass": 1738953278,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "state": "INACTIVE",
    "type": "NBAR"
  },
  "indicativeNetworkIdentity": [
    {
      "id": "15d0824b-a2cd-49fe-9de9-9cab761f0bc9",
      "instanceId": 14851,
      "authEntityId": 14851,
      "displayName": "14851",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "363",
      "protocol": "UDP",
      "upperPort": 0
    },
    {
      "id": "e8c0df8e-b72f-446b-94af-19fbf179c4e7",
      "instanceId": 14852,
      "authEntityId": 14852,
      "displayName": "14852",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "363",
      "protocol": "TCP",
      "upperPort": 0
    }
  ],
  "networkApplications": [
    {
      "id": "770e0c9b-688f-4e23-80bb-fe38caa53424",
      "instanceId": 12008,
      "authEntityId": 12008,
      "displayName": "12008",
      "authEntityClass": -217092956,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "appProtocol": "tcp/udp",
      "applicationGroup": "other",
      "applicationType": "DEFAULT",
      "categoryId": "1fc17362-9334-4c7b-b752-5afdbb574017",
      "createTime": 1517966744161,
      "encrypted": "false",
      "engineId": "3",
      "helpString": "rsvp_tunnel",
      "ignoreConflict": false,
      "lastUpdateTime": 1517966744161,
      "longDescription": "TheResource Reservation Protocol(RSVP) is aTransport Layerprotocoldesigned to reserve resources across anetworkfor anintegrated servicesInternet. It is one component of a framework designed to extend IP to support multiple, controlled classes of service over a wide variety of link-level technologies. To deploy this technology with maximum flexibility, it is desirable for tunnels to act as RSVP-controllable links within the network.",
      "name": "rsvp_tunnel",
      "nbarId": "279",
      "p2pTechnology": "false",
      "popularity": 5,
      "rank": 65535,
      "references": "http://tools.ietf.org/html/rfc2746",
      "selectorId": "363",
      "subCategory": "68c4f989-5735-4e16-8d1c-6a9d89d5df9c",
      "trafficClass": "SIGNALING",
      "tunnel": "true"
    }
  ],
  "networkIdentity": [
    {
      "id": "f10cc241-934e-43aa-ac64-677f6057fe64",
      "instanceId": 14849,
      "authEntityId": 14849,
      "displayName": "14849",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "363",
      "protocol": "UDP",
      "upperPort": 0
    },
    {
      "id": "d61097df-20e7-4232-84e5-d9e278044b75",
      "instanceId": 14850,
      "authEntityId": 14850,
      "displayName": "14850",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "363",
      "protocol": "TCP",
      "upperPort": 0
    }
  ],
  "parentScalableGroup": {
    "idRef": "a3243a74-3864-4041-a927-0201030da17c"
  },
  "user": [

  ],
  "userGroup": [

  ]
},
{
  "id": "a323e717-9739-449f-9f92-6e89bd438e72",
  "instanceId": 4599,
  "authEntityId": 4599,
  "displayName": "4599",
  "authEntityClass": -1909073334,
  "deployPending": "NONE",
  "instanceVersion": 0,
  "createTime": 1517966743088,
  "deployed": false,
  "isSeeded": true,
  "isStale": false,
  "lastUpdateTime": 1517966743088,
  "name": "indeed-com",
  "namespace": "scalablegroup:application",
  "provisioningState": "DEFINED",
  "qualifier": "application",
  "resourceVersion": 0,
  "targetIdList": [

  ],
  "type": "scalablegroup",
  "cfsChangeInfo": [

  ],
  "customProvisions": [

  ],
  "scalableGroupExternalHandle": "indeed-com",
  "scalableGroupType": "APPLICATION",
  "securityGroupTag": 0,
  "state": "ACTIVE",
  "vnAgnostic": true,
  "identitySource": {
    "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
    "instanceId": 10010,
    "authEntityId": 10010,
    "displayName": "10010",
    "authEntityClass": 1738953278,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "state": "INACTIVE",
    "type": "NBAR"
  },
  "indicativeNetworkIdentity": [

  ],
  "networkApplications": [
    {
      "id": "01cf94c5-c7d8-4731-9042-9422dd411a5a",
      "instanceId": 11549,
      "authEntityId": 11549,
      "displayName": "11549",
      "authEntityClass": -217092956,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "appProtocol": "tcp/udp",
      "applicationGroup": "other",
      "applicationType": "DEFAULT",
      "categoryId": "878644b5-fe91-45b4-9204-31c84a73ba48",
      "createTime": 1517966743088,
      "encrypted": "false",
      "engineId": "13",
      "helpString": "Indeed - The job search engine.",
      "ignoreConflict": false,
      "lastUpdateTime": 1517966743088,
      "longDescription": "Indeed - The job search engine.",
      "name": "indeed-com",
      "nbarId": "1578",
      "p2pTechnology": "false",
      "popularity": 3,
      "rank": 65535,
      "references": "http://indeed.com",
      "selectorId": "649",
      "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
      "trafficClass": "TRANSACTIONAL_DATA",
      "tunnel": "false"
    }
  ],
  "networkIdentity": [
    {
      "id": "cfab3f2d-bb3b-4ea3-9017-de196bae2719",
      "instanceId": 13511,
      "authEntityId": 13511,
      "displayName": "13511",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "53,80,443,5353",
      "protocol": "TCP",
      "upperPort": 0
    },
    {
      "id": "16948778-107e-49f2-ade2-01e56378f0cb",
      "instanceId": 13512,
      "authEntityId": 13512,
      "displayName": "13512",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "53,5353",
      "protocol": "UDP",
      "upperPort": 0
    }
  ],
  "parentScalableGroup": {
    "idRef": "28e08de4-d7b2-4521-84ce-bc1e5ee0b9cc"
  },
  "user": [

  ],
  "userGroup": [

  ]
},
{
  "id": "a331de15-8f66-46a7-baf5-e254914ae82f",
  "instanceId": 4513,
  "authEntityId": 4513,
  "displayName": "4513",
  "authEntityClass": -1909073334,
  "deployPending": "NONE",
  "instanceVersion": 0,
  "createTime": 1517966742855,
  "deployed": false,
  "isSeeded": true,
  "isStale": false,
  "lastUpdateTime": 1517966742855,
  "name": "gsiftp",
  "namespace": "scalablegroup:application",
  "provisioningState": "DEFINED",
  "qualifier": "application",
  "resourceVersion": 0,
  "targetIdList": [

  ],
  "type": "scalablegroup",
  "cfsChangeInfo": [

  ],
  "customProvisions": [

  ],
  "scalableGroupExternalHandle": "gsiftp",
  "scalableGroupType": "APPLICATION",
  "securityGroupTag": 0,
  "state": "ACTIVE",
  "vnAgnostic": true,
  "identitySource": {
    "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
    "instanceId": 10010,
    "authEntityId": 10010,
    "displayName": "10010",
    "authEntityClass": 1738953278,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "state": "INACTIVE",
    "type": "NBAR"
  },
  "indicativeNetworkIdentity": [
    {
      "id": "d36de0f3-6261-4be2-8ca6-8fe258a690c1",
      "instanceId": 13275,
      "authEntityId": 13275,
      "displayName": "13275",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "2811",
      "protocol": "UDP",
      "upperPort": 0
    },
    {
      "id": "2732a4e0-d91d-4b08-820e-768d3561a451",
      "instanceId": 13276,
      "authEntityId": 13276,
      "displayName": "13276",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "2811",
      "protocol": "TCP",
      "upperPort": 0
    }
  ],
  "networkApplications": [
    {
      "id": "ec69aaff-14f1-4173-9deb-956d0ff70f56",
      "instanceId": 11464,
      "authEntityId": 11464,
      "displayName": "11464",
      "authEntityClass": -217092956,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "appProtocol": "tcp/udp",
      "applicationGroup": "ftp-group",
      "applicationType": "DEFAULT",
      "categoryId": "e6619ce8-97de-4c63-a53a-cceddbddc1a8",
      "createTime": 1517966742855,
      "encrypted": "true",
      "engineId": "3",
      "helpString": "GSI FTP",
      "ignoreConflict": false,
      "lastUpdateTime": 1517966742855,
      "longDescription": "The Globus GridFTP (GSI-FTP, Grid Security Infrastructure) server and client tools and libraries make up a robust product suite designed to move large amounts of data faster, more securely, and more reliably than standard FTP.",
      "name": "gsiftp",
      "nbarId": "1313",
      "p2pTechnology": "false",
      "popularity": 4,
      "rank": 65535,
      "references": "http://www.globus.org/toolkit/data/gridftp/",
      "selectorId": "2811",
      "subCategory": "50b55e2e-d0d0-4404-bea4-819351565d90",
      "trafficClass": "BULK_DATA",
      "tunnel": "false"
    }
  ],
  "networkIdentity": [
    {
      "id": "cb925f07-853e-4a85-97b7-cb9fed5bfcb6",
      "instanceId": 13273,
      "authEntityId": 13273,
      "displayName": "13273",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "2811",
      "protocol": "UDP",
      "upperPort": 0
    },
    {
      "id": "9c9be1ad-ad1e-4617-bc19-e3aa9192c878",
      "instanceId": 13274,
      "authEntityId": 13274,
      "displayName": "13274",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "2811",
      "protocol": "TCP",
      "upperPort": 0
    }
  ],
  "parentScalableGroup": {
    "idRef": "383363b1-4dd5-49d1-bfe4-34daac437cfb"
  },
  "user": [

  ],
  "userGroup": [

  ]
},
{
  "id": "a363a239-6f9b-4024-a6c9-9457059b6497",
  "instanceId": 4152,
  "authEntityId": 4152,
  "displayName": "4152",
  "authEntityClass": -1909073334,
  "deployPending": "NONE",
  "instanceVersion": 0,
  "createTime": 1517966741409,
  "deployed": false,
  "isSeeded": true,
  "isStale": false,
  "lastUpdateTime": 1517966741409,
  "name": "ax25",
  "namespace": "scalablegroup:application",
  "provisioningState": "DEFINED",
  "qualifier": "application",
  "resourceVersion": 0,
  "targetIdList": [

  ],
  "type": "scalablegroup",
  "cfsChangeInfo": [

  ],
  "customProvisions": [

  ],
  "scalableGroupExternalHandle": "ax25",
  "scalableGroupType": "APPLICATION",
  "securityGroupTag": 0,
  "state": "ACTIVE",
  "vnAgnostic": true,
  "identitySource": {
    "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
    "instanceId": 10010,
    "authEntityId": 10010,
    "displayName": "10010",
    "authEntityClass": 1738953278,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "state": "INACTIVE",
    "type": "NBAR"
  },
  "indicativeNetworkIdentity": [
    {
      "id": "008db042-aafb-4c45-a76e-be6face5d60c",
      "instanceId": 12315,
      "authEntityId": 12315,
      "displayName": "12315",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipProtocolNumbers": "93",
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "protocol": "AX25",
      "upperPort": 0
    }
  ],
  "networkApplications": [
    {
      "id": "295d9080-f567-429f-b712-8e39bded05df",
      "instanceId": 11113,
      "authEntityId": 11113,
      "displayName": "11113",
      "authEntityClass": -217092956,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "appProtocol": "ip",
      "applicationGroup": "other",
      "applicationType": "DEFAULT",
      "categoryId": "6daa3dfd-3526-407a-88f0-97824b3f3a24",
      "createTime": 1517966741409,
      "encrypted": "unassigned",
      "engineId": "1",
      "helpString": "AX.25 Frames",
      "ignoreConflict": false,
      "lastUpdateTime": 1517966741409,
      "longDescription": "AX.25 is a data link layer protocol derived from the X.25 protocol suite and designed for use by amateur radio operators. It is used extensively on amateur packet radio networks.It occupies the first, second, and often the third layers of the OSI networking model, and is responsible for transferring data (encapsulated in packets) between nodes and detecting errors introduced by the communications channel.",
      "name": "ax25",
      "nbarId": "847",
      "p2pTechnology": "unassigned",
      "popularity": 2,
      "rank": 65535,
      "references": "http://en.wikipedia.org/wiki/AX.25",
      "selectorId": "93",
      "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
      "trafficClass": "BULK_DATA",
      "tunnel": "unassigned"
    }
  ],
  "networkIdentity": [
    {
      "id": "cf3cff38-2750-4caf-8845-8539cb96a4a1",
      "instanceId": 12314,
      "authEntityId": 12314,
      "displayName": "12314",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipProtocolNumbers": "93",
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "protocol": "AX25",
      "upperPort": 0
    }
  ],
  "parentScalableGroup": {
    "idRef": "000c15bf-28fe-4fbb-9b69-35e6502721f1"
  },
  "user": [

  ],
  "userGroup": [

  ]
},
{
  "id": "a36a2ef4-5f5d-47f1-ae3e-9ef1d27ec400",
  "instanceId": 15394,
  "authEntityId": 15394,
  "displayName": "15394",
  "authEntityClass": -1909073334,
  "deployPending": "NONE",
  "instanceVersion": 0,
  "createTime": 1517966744865,
  "deployed": false,
  "isSeeded": true,
  "isStale": false,
  "lastUpdateTime": 1517966744865,
  "name": "weather-gov-web-portal",
  "namespace": "scalablegroup:application",
  "provisioningState": "DEFINED",
  "qualifier": "application",
  "resourceVersion": 0,
  "targetIdList": [

  ],
  "type": "scalablegroup",
  "cfsChangeInfo": [

  ],
  "customProvisions": [

  ],
  "scalableGroupExternalHandle": "weather-gov-web-portal",
  "scalableGroupType": "APPLICATION",
  "securityGroupTag": 0,
  "state": "ACTIVE",
  "vnAgnostic": true,
  "identitySource": {
    "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
    "instanceId": 10010,
    "authEntityId": 10010,
    "displayName": "10010",
    "authEntityClass": 1738953278,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "state": "INACTIVE",
    "type": "NBAR"
  },
  "indicativeNetworkIdentity": [

  ],
  "networkApplications": [
    {
      "id": "a28beec5-d7c0-4ff9-9a4d-d9a50970cc8e",
      "instanceId": 16337,
      "authEntityId": 16337,
      "displayName": "16337",
      "authEntityClass": -217092956,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "appProtocol": "tcp/udp",
      "applicationGroup": "other",
      "applicationType": "DEFAULT",
      "categoryId": "878644b5-fe91-45b4-9204-31c84a73ba48",
      "createTime": 1517966744865,
      "encrypted": "false",
      "engineId": "13",
      "helpString": "Weather.gov - Weather web portal.",
      "ignoreConflict": false,
      "lastUpdateTime": 1517966744865,
      "longDescription": "Weather.gov - Weather web portal.",
      "name": "weather-gov-web-portal",
      "nbarId": "1700",
      "p2pTechnology": "false",
      "popularity": 3,
      "rank": 65535,
      "references": "http://weather.gov",
      "selectorId": "770",
      "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
      "trafficClass": "TRANSACTIONAL_DATA",
      "tunnel": "false"
    }
  ],
  "networkIdentity": [
    {
      "id": "bcc80f17-1707-4847-ad51-a3879366db71",
      "instanceId": 17801,
      "authEntityId": 17801,
      "displayName": "17801",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "53,5353",
      "protocol": "UDP",
      "upperPort": 0
    },
    {
      "id": "1e717757-5135-4722-8470-8968ef00d559",
      "instanceId": 17802,
      "authEntityId": 17802,
      "displayName": "17802",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "53,80,443,5353",
      "protocol": "TCP",
      "upperPort": 0
    }
  ],
  "parentScalableGroup": {
    "idRef": "28e08de4-d7b2-4521-84ce-bc1e5ee0b9cc"
  },
  "user": [

  ],
  "userGroup": [

  ]
},
{
  "id": "a3d7ea07-49e9-4f64-8e18-810af71b2f5d",
  "instanceId": 4693,
  "authEntityId": 4693,
  "displayName": "4693",
  "authEntityClass": -1909073334,
  "deployPending": "NONE",
  "instanceVersion": 0,
  "createTime": 1517966743297,
  "deployed": false,
  "isSeeded": true,
  "isStale": false,
  "lastUpdateTime": 1517966743297,
  "name": "legent-2",
  "namespace": "scalablegroup:application",
  "provisioningState": "DEFINED",
  "qualifier": "application",
  "resourceVersion": 0,
  "targetIdList": [

  ],
  "type": "scalablegroup",
  "cfsChangeInfo": [

  ],
  "customProvisions": [

  ],
  "scalableGroupExternalHandle": "legent-2",
  "scalableGroupType": "APPLICATION",
  "securityGroupTag": 0,
  "state": "ACTIVE",
  "vnAgnostic": true,
  "identitySource": {
    "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
    "instanceId": 10010,
    "authEntityId": 10010,
    "displayName": "10010",
    "authEntityClass": 1738953278,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "state": "INACTIVE",
    "type": "NBAR"
  },
  "indicativeNetworkIdentity": [
    {
      "id": "ee469f61-e436-4731-975e-462f0204e0b0",
      "instanceId": 13754,
      "authEntityId": 13754,
      "displayName": "13754",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "374",
      "protocol": "TCP",
      "upperPort": 0
    },
    {
      "id": "f1030691-2f54-462d-9603-cdda62bdea67",
      "instanceId": 13755,
      "authEntityId": 13755,
      "displayName": "13755",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "374",
      "protocol": "UDP",
      "upperPort": 0
    }
  ],
  "networkApplications": [
    {
      "id": "dcac0670-f615-4aa7-996f-664ca525ed91",
      "instanceId": 11643,
      "authEntityId": 11643,
      "displayName": "11643",
      "authEntityClass": -217092956,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "appProtocol": "tcp/udp",
      "applicationGroup": "other",
      "applicationType": "DEFAULT",
      "categoryId": "6daa3dfd-3526-407a-88f0-97824b3f3a24",
      "createTime": 1517966743297,
      "encrypted": "false",
      "engineId": "3",
      "helpString": "Legent Corporation",
      "ignoreConflict": false,
      "lastUpdateTime": 1517966743297,
      "longDescription": "Registered with IANA on port 374 TCP/UDP",
      "name": "legent-2",
      "nbarId": "290",
      "p2pTechnology": "false",
      "popularity": 1,
      "rank": 65535,
      "references": "http://www.iana.org/assignments/service-names-port-numbers/service-names-port-numbers.xml",
      "selectorId": "374",
      "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
      "trafficClass": "BULK_DATA",
      "tunnel": "false"
    }
  ],
  "networkIdentity": [
    {
      "id": "1c41bcd4-4c51-4838-b25f-f6c232dbf900",
      "instanceId": 13752,
      "authEntityId": 13752,
      "displayName": "13752",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "374",
      "protocol": "TCP",
      "upperPort": 0
    },
    {
      "id": "a8f9a8d7-6df5-42d2-8ab5-c656e6e566bb",
      "instanceId": 13753,
      "authEntityId": 13753,
      "displayName": "13753",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "374",
      "protocol": "UDP",
      "upperPort": 0
    }
  ],
  "parentScalableGroup": {
    "idRef": "000c15bf-28fe-4fbb-9b69-35e6502721f1"
  },
  "user": [

  ],
  "userGroup": [

  ]
},
{
  "id": "a435f3b6-60e1-4a31-a827-702c8e36c804",
  "instanceId": 4175,
  "authEntityId": 4175,
  "displayName": "4175",
  "authEntityClass": -1909073334,
  "deployPending": "NONE",
  "instanceVersion": 0,
  "createTime": 1517966741535,
  "deployed": false,
  "isSeeded": true,
  "isStale": false,
  "lastUpdateTime": 1517966741535,
  "name": "bhevent",
  "namespace": "scalablegroup:application",
  "provisioningState": "DEFINED",
  "qualifier": "application",
  "resourceVersion": 0,
  "targetIdList": [

  ],
  "type": "scalablegroup",
  "cfsChangeInfo": [

  ],
  "customProvisions": [

  ],
  "scalableGroupExternalHandle": "bhevent",
  "scalableGroupType": "APPLICATION",
  "securityGroupTag": 0,
  "state": "ACTIVE",
  "vnAgnostic": true,
  "identitySource": {
    "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
    "instanceId": 10010,
    "authEntityId": 10010,
    "displayName": "10010",
    "authEntityClass": 1738953278,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "state": "INACTIVE",
    "type": "NBAR"
  },
  "indicativeNetworkIdentity": [
    {
      "id": "f9d57b38-899a-475a-85f8-eadacd699703",
      "instanceId": 12376,
      "authEntityId": 12376,
      "displayName": "12376",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "357",
      "protocol": "UDP",
      "upperPort": 0
    },
    {
      "id": "3d2c38c8-719e-4f99-bdf5-31a7bf7b257d",
      "instanceId": 12377,
      "authEntityId": 12377,
      "displayName": "12377",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "357",
      "protocol": "TCP",
      "upperPort": 0
    }
  ],
  "networkApplications": [
    {
      "id": "9d12e2ef-48f7-4487-83f5-f8c573d07ace",
      "instanceId": 11134,
      "authEntityId": 11134,
      "displayName": "11134",
      "authEntityClass": -217092956,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "appProtocol": "tcp/udp",
      "applicationGroup": "other",
      "applicationType": "DEFAULT",
      "categoryId": "6daa3dfd-3526-407a-88f0-97824b3f3a24",
      "createTime": 1517966741534,
      "encrypted": "false",
      "engineId": "3",
      "helpString": "bhevent",
      "ignoreConflict": false,
      "lastUpdateTime": 1517966741534,
      "longDescription": "Registered with IANA on port 357 TCP/UDP",
      "name": "bhevent",
      "nbarId": "273",
      "p2pTechnology": "false",
      "popularity": 1,
      "rank": 65535,
      "references": "http://www.iana.org/assignments/service-names-port-numbers/service-names-port-numbers.xml",
      "selectorId": "357",
      "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
      "trafficClass": "BULK_DATA",
      "tunnel": "false"
    }
  ],
  "networkIdentity": [
    {
      "id": "8cb7c4af-a110-4334-a854-4b0ecb0d331e",
      "instanceId": 12374,
      "authEntityId": 12374,
      "displayName": "12374",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "357",
      "protocol": "TCP",
      "upperPort": 0
    },
    {
      "id": "9d3e2c09-fe70-49d3-9ee8-cb8aa77bb5a8",
      "instanceId": 12375,
      "authEntityId": 12375,
      "displayName": "12375",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "357",
      "protocol": "UDP",
      "upperPort": 0
    }
  ],
  "parentScalableGroup": {
    "idRef": "000c15bf-28fe-4fbb-9b69-35e6502721f1"
  },
  "user": [

  ],
  "userGroup": [

  ]
},
{
  "id": "a4374a68-6da7-4315-8fc7-59902c0ad089",
  "instanceId": 4150,
  "authEntityId": 4150,
  "displayName": "4150",
  "authEntityClass": -1909073334,
  "deployPending": "NONE",
  "instanceVersion": 0,
  "createTime": 1517966741389,
  "deployed": false,
  "isSeeded": true,
  "isStale": false,
  "lastUpdateTime": 1517966741389,
  "name": "avocent",
  "namespace": "scalablegroup:application",
  "provisioningState": "DEFINED",
  "qualifier": "application",
  "resourceVersion": 0,
  "targetIdList": [

  ],
  "type": "scalablegroup",
  "cfsChangeInfo": [

  ],
  "customProvisions": [

  ],
  "scalableGroupExternalHandle": "avocent",
  "scalableGroupType": "APPLICATION",
  "securityGroupTag": 0,
  "state": "ACTIVE",
  "vnAgnostic": true,
  "identitySource": {
    "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
    "instanceId": 10010,
    "authEntityId": 10010,
    "displayName": "10010",
    "authEntityClass": 1738953278,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "state": "INACTIVE",
    "type": "NBAR"
  },
  "indicativeNetworkIdentity": [
    {
      "id": "5bcb45e7-3fdd-449b-a527-c47342269892",
      "instanceId": 12310,
      "authEntityId": 12310,
      "displayName": "12310",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "3211,3502,3871",
      "protocol": "TCP",
      "upperPort": 0
    },
    {
      "id": "74a87472-05d1-475e-9b66-d701b269593c",
      "instanceId": 12311,
      "authEntityId": 12311,
      "displayName": "12311",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "3211,3502,3871",
      "protocol": "UDP",
      "upperPort": 0
    }
  ],
  "networkApplications": [
    {
      "id": "b22c9ed1-d33b-4d07-afec-17edec31189c",
      "instanceId": 11111,
      "authEntityId": 11111,
      "displayName": "11111",
      "authEntityClass": -217092956,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "appProtocol": "tcp/udp",
      "applicationGroup": "other",
      "applicationType": "DEFAULT",
      "categoryId": "6daa3dfd-3526-407a-88f0-97824b3f3a24",
      "createTime": 1517966741389,
      "encrypted": "false",
      "engineId": "3",
      "helpString": "Secure management and installation discovery",
      "ignoreConflict": false,
      "lastUpdateTime": 1517966741389,
      "longDescription": "Registered with IANA on ports 3211,3502,3871 TCP/UDP",
      "name": "avocent",
      "nbarId": "1376",
      "p2pTechnology": "false",
      "popularity": 1,
      "rank": 65535,
      "references": "http://www.iana.org/assignments/service-names-port-numbers/service-names-port-numbers.xml",
      "selectorId": "3211",
      "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
      "trafficClass": "BULK_DATA",
      "tunnel": "false"
    }
  ],
  "networkIdentity": [
    {
      "id": "949109fc-2fd9-47b7-b6c6-e5ecd7b055a2",
      "instanceId": 12308,
      "authEntityId": 12308,
      "displayName": "12308",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "3211,3502,3871",
      "protocol": "UDP",
      "upperPort": 0
    },
    {
      "id": "fed4cc4b-305c-458a-95a8-871d58cea20c",
      "instanceId": 12309,
      "authEntityId": 12309,
      "displayName": "12309",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "3211,3502,3871",
      "protocol": "TCP",
      "upperPort": 0
    }
  ],
  "parentScalableGroup": {
    "idRef": "000c15bf-28fe-4fbb-9b69-35e6502721f1"
  },
  "user": [

  ],
  "userGroup": [

  ]
},
{
  "id": "a478478d-9f25-4f88-94f1-dd1a5b0fea29",
  "instanceId": 4084,
  "authEntityId": 4084,
  "displayName": "4084",
  "authEntityClass": -1909073334,
  "deployPending": "NONE",
  "instanceVersion": 0,
  "createTime": 1517966740947,
  "deployed": false,
  "isSeeded": true,
  "isStale": false,
  "lastUpdateTime": 1517966740947,
  "name": "ancestry-com",
  "namespace": "scalablegroup:application",
  "provisioningState": "DEFINED",
  "qualifier": "application",
  "resourceVersion": 0,
  "targetIdList": [

  ],
  "type": "scalablegroup",
  "cfsChangeInfo": [

  ],
  "customProvisions": [

  ],
  "scalableGroupExternalHandle": "ancestry-com",
  "scalableGroupType": "APPLICATION",
  "securityGroupTag": 0,
  "state": "ACTIVE",
  "vnAgnostic": true,
  "identitySource": {
    "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
    "instanceId": 10010,
    "authEntityId": 10010,
    "displayName": "10010",
    "authEntityClass": 1738953278,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "state": "INACTIVE",
    "type": "NBAR"
  },
  "indicativeNetworkIdentity": [

  ],
  "networkApplications": [
    {
      "id": "10b457bc-4ecd-4e69-ba2d-4363daa95942",
      "instanceId": 11050,
      "authEntityId": 11050,
      "displayName": "11050",
      "authEntityClass": -217092956,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "appProtocol": "tcp/udp",
      "applicationGroup": "other",
      "applicationType": "DEFAULT",
      "categoryId": "878644b5-fe91-45b4-9204-31c84a73ba48",
      "createTime": 1517966740947,
      "encrypted": "false",
      "engineId": "13",
      "helpString": "Ancestry.com - Online family history resource.",
      "ignoreConflict": false,
      "lastUpdateTime": 1517966740947,
      "longDescription": "Ancestry.com - Online family history resource.",
      "name": "ancestry-com",
      "nbarId": "1672",
      "p2pTechnology": "false",
      "popularity": 3,
      "rank": 65535,
      "references": "http://ancestry.com",
      "selectorId": "742",
      "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
      "trafficClass": "TRANSACTIONAL_DATA",
      "tunnel": "false"
    }
  ],
  "networkIdentity": [
    {
      "id": "3e162e28-8a9a-4e77-bfe9-b52d264f6719",
      "instanceId": 12124,
      "authEntityId": 12124,
      "displayName": "12124",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "53,80,443,5353",
      "protocol": "TCP",
      "upperPort": 0
    },
    {
      "id": "0f2f8e20-da43-42f6-a772-73464ea4f690",
      "instanceId": 12125,
      "authEntityId": 12125,
      "displayName": "12125",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "53,5353",
      "protocol": "UDP",
      "upperPort": 0
    }
  ],
  "parentScalableGroup": {
    "idRef": "28e08de4-d7b2-4521-84ce-bc1e5ee0b9cc"
  },
  "user": [

  ],
  "userGroup": [

  ]
},
{
  "id": "a4d7115e-c86c-404f-9f5c-0a15f432e88d",
  "instanceId": 4051,
  "authEntityId": 4051,
  "displayName": "4051",
  "authEntityClass": -1909073334,
  "deployPending": "NONE",
  "instanceVersion": 0,
  "createTime": 1517966740613,
  "deployed": false,
  "isSeeded": true,
  "isStale": false,
  "lastUpdateTime": 1517966740613,
  "name": "accessnetwork",
  "namespace": "scalablegroup:application",
  "provisioningState": "DEFINED",
  "qualifier": "application",
  "resourceVersion": 0,
  "targetIdList": [

  ],
  "type": "scalablegroup",
  "cfsChangeInfo": [

  ],
  "customProvisions": [

  ],
  "scalableGroupExternalHandle": "accessnetwork",
  "scalableGroupType": "APPLICATION",
  "securityGroupTag": 0,
  "state": "ACTIVE",
  "vnAgnostic": true,
  "identitySource": {
    "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
    "instanceId": 10010,
    "authEntityId": 10010,
    "displayName": "10010",
    "authEntityClass": 1738953278,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "state": "INACTIVE",
    "type": "NBAR"
  },
  "indicativeNetworkIdentity": [
    {
      "id": "6451cfdc-e315-4c42-9cc2-ba5ad7e55424",
      "instanceId": 12058,
      "authEntityId": 12058,
      "displayName": "12058",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "699",
      "protocol": "TCP",
      "upperPort": 0
    },
    {
      "id": "5129a31c-6a25-4b50-b277-99052bcf3646",
      "instanceId": 12059,
      "authEntityId": 12059,
      "displayName": "12059",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "699",
      "protocol": "UDP",
      "upperPort": 0
    }
  ],
  "networkApplications": [
    {
      "id": "20439de4-ef6b-47bf-8300-b2ef4c9e7438",
      "instanceId": 11024,
      "authEntityId": 11024,
      "displayName": "11024",
      "authEntityClass": -217092956,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "appProtocol": "tcp/udp",
      "applicationGroup": "other",
      "applicationType": "DEFAULT",
      "categoryId": "6daa3dfd-3526-407a-88f0-97824b3f3a24",
      "createTime": 1517966740613,
      "encrypted": "false",
      "engineId": "3",
      "helpString": "Access Network",
      "ignoreConflict": false,
      "lastUpdateTime": 1517966740613,
      "longDescription": "Registered with IANA on port 699 TCP/UDP",
      "name": "accessnetwork",
      "nbarId": "607",
      "p2pTechnology": "false",
      "popularity": 1,
      "rank": 65535,
      "references": "http://www.iana.org/assignments/service-names-port-numbers/service-names-port-numbers.xml",
      "selectorId": "699",
      "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
      "trafficClass": "BULK_DATA",
      "tunnel": "false"
    }
  ],
  "networkIdentity": [
    {
      "id": "ed88e9f5-56b5-416d-99e3-a9c22b92530b",
      "instanceId": 12056,
      "authEntityId": 12056,
      "displayName": "12056",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "699",
      "protocol": "TCP",
      "upperPort": 0
    },
    {
      "id": "4bdf7008-a412-4477-ac91-bbab4aa515d2",
      "instanceId": 12057,
      "authEntityId": 12057,
      "displayName": "12057",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "699",
      "protocol": "UDP",
      "upperPort": 0
    }
  ],
  "parentScalableGroup": {
    "idRef": "000c15bf-28fe-4fbb-9b69-35e6502721f1"
  },
  "user": [

  ],
  "userGroup": [

  ]
},
{
  "id": "a4f6d177-533c-42da-93c3-dfdeb69990f1",
  "instanceId": 15141,
  "authEntityId": 15141,
  "displayName": "15141",
  "authEntityClass": -1909073334,
  "deployPending": "NONE",
  "instanceVersion": 0,
  "createTime": 1517966744328,
  "deployed": false,
  "isSeeded": true,
  "isStale": false,
  "lastUpdateTime": 1517966744328,
  "name": "siam",
  "namespace": "scalablegroup:application",
  "provisioningState": "DEFINED",
  "qualifier": "application",
  "resourceVersion": 0,
  "targetIdList": [

  ],
  "type": "scalablegroup",
  "cfsChangeInfo": [

  ],
  "customProvisions": [

  ],
  "scalableGroupExternalHandle": "siam",
  "scalableGroupType": "APPLICATION",
  "securityGroupTag": 0,
  "state": "ACTIVE",
  "vnAgnostic": true,
  "identitySource": {
    "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
    "instanceId": 10010,
    "authEntityId": 10010,
    "displayName": "10010",
    "authEntityClass": 1738953278,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "state": "INACTIVE",
    "type": "NBAR"
  },
  "indicativeNetworkIdentity": [
    {
      "id": "9e5c0de1-d5f5-4bef-b4fd-f8d773099811",
      "instanceId": 17070,
      "authEntityId": 17070,
      "displayName": "17070",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "498",
      "protocol": "TCP",
      "upperPort": 0
    },
    {
      "id": "2a4e609f-9c58-4306-aeb8-470c724579d8",
      "instanceId": 17071,
      "authEntityId": 17071,
      "displayName": "17071",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "498",
      "protocol": "UDP",
      "upperPort": 0
    }
  ],
  "networkApplications": [
    {
      "id": "054fae09-93ae-49d0-8df4-a5f5371bb191",
      "instanceId": 16084,
      "authEntityId": 16084,
      "displayName": "16084",
      "authEntityClass": -217092956,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "appProtocol": "tcp/udp",
      "applicationGroup": "other",
      "applicationType": "DEFAULT",
      "categoryId": "6daa3dfd-3526-407a-88f0-97824b3f3a24",
      "createTime": 1517966744328,
      "encrypted": "false",
      "engineId": "3",
      "helpString": "siam",
      "ignoreConflict": false,
      "lastUpdateTime": 1517966744328,
      "longDescription": "Registered with IANA on port 498 TCP/UDP",
      "name": "siam",
      "nbarId": "412",
      "p2pTechnology": "false",
      "popularity": 1,
      "rank": 65535,
      "references": "http://www.iana.org/assignments/service-names-port-numbers/service-names-port-numbers.xml",
      "selectorId": "498",
      "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
      "trafficClass": "BULK_DATA",
      "tunnel": "false"
    }
  ],
  "networkIdentity": [
    {
      "id": "a871cb54-9752-4272-9364-06f296cc1409",
      "instanceId": 17068,
      "authEntityId": 17068,
      "displayName": "17068",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "498",
      "protocol": "TCP",
      "upperPort": 0
    },
    {
      "id": "9d7cd56f-645c-4a75-a5d7-774bd0bde1e0",
      "instanceId": 17069,
      "authEntityId": 17069,
      "displayName": "17069",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "498",
      "protocol": "UDP",
      "upperPort": 0
    }
  ],
  "parentScalableGroup": {
    "idRef": "000c15bf-28fe-4fbb-9b69-35e6502721f1"
  },
  "user": [

  ],
  "userGroup": [

  ]
},
{
  "id": "a5860b1c-c2be-443b-ac5b-40e5107f9903",
  "instanceId": 4816,
  "authEntityId": 4816,
  "displayName": "4816",
  "authEntityClass": -1909073334,
  "deployPending": "NONE",
  "instanceVersion": 0,
  "createTime": 1517966743548,
  "deployed": false,
  "isSeeded": true,
  "isStale": false,
  "lastUpdateTime": 1517966743548,
  "name": "namp",
  "namespace": "scalablegroup:application",
  "provisioningState": "DEFINED",
  "qualifier": "application",
  "resourceVersion": 0,
  "targetIdList": [

  ],
  "type": "scalablegroup",
  "cfsChangeInfo": [

  ],
  "customProvisions": [

  ],
  "scalableGroupExternalHandle": "namp",
  "scalableGroupType": "APPLICATION",
  "securityGroupTag": 0,
  "state": "ACTIVE",
  "vnAgnostic": true,
  "identitySource": {
    "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
    "instanceId": 10010,
    "authEntityId": 10010,
    "displayName": "10010",
    "authEntityClass": 1738953278,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "state": "INACTIVE",
    "type": "NBAR"
  },
  "indicativeNetworkIdentity": [
    {
      "id": "9f877543-a190-4470-9305-42e9356e13a0",
      "instanceId": 14085,
      "authEntityId": 14085,
      "displayName": "14085",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "167",
      "protocol": "TCP",
      "upperPort": 0
    },
    {
      "id": "481a4fba-c89e-48b1-8377-a89e5438b5b3",
      "instanceId": 14086,
      "authEntityId": 14086,
      "displayName": "14086",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "167",
      "protocol": "UDP",
      "upperPort": 0
    }
  ],
  "networkApplications": [
    {
      "id": "2fad91e4-c338-4bb1-a0fd-dd6b0c77c564",
      "instanceId": 11765,
      "authEntityId": 11765,
      "displayName": "11765",
      "authEntityClass": -217092956,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "appProtocol": "tcp/udp",
      "applicationGroup": "other",
      "applicationType": "DEFAULT",
      "categoryId": "1fc17362-9334-4c7b-b752-5afdbb574017",
      "createTime": 1517966743548,
      "encrypted": "false",
      "engineId": "3",
      "helpString": "namp",
      "ignoreConflict": false,
      "lastUpdateTime": 1517966743548,
      "longDescription": "Neighbour Aware Multicast Routings Protocol (NAMP), is a tree based, hybrid multicast routing protocol for A Mobile Ad Hoc Network (MANET). MANET is a system of wireless mobile nodes that can freely and dynamically self-organize into arbitrary and temporary network  topologies without the presence of any fixed communication infrastructure.",
      "name": "namp",
      "nbarId": "1012",
      "p2pTechnology": "false",
      "popularity": 1,
      "rank": 65535,
      "references": "http://www.ccis2k.org/iajit/PDF/vol.5,no.1/14-116.pdf",
      "selectorId": "167",
      "subCategory": "7cc1b3a6-4102-44e9-b522-0e71afe39a03",
      "trafficClass": "NETWORK_CONTROL",
      "tunnel": "false"
    }
  ],
  "networkIdentity": [
    {
      "id": "507e3f43-d2b6-4982-91f9-5184f7edc72e",
      "instanceId": 14083,
      "authEntityId": 14083,
      "displayName": "14083",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "167",
      "protocol": "TCP",
      "upperPort": 0
    },
    {
      "id": "e1305501-4237-4bde-9631-fcfdcfb069e4",
      "instanceId": 14084,
      "authEntityId": 14084,
      "displayName": "14084",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "167",
      "protocol": "UDP",
      "upperPort": 0
    }
  ],
  "parentScalableGroup": {
    "idRef": "77e75842-e98b-4112-ad0c-300db4bb11be"
  },
  "user": [

  ],
  "userGroup": [

  ]
},
{
  "id": "a68e1bf3-4e43-4049-aeb7-dc15de00e373",
  "instanceId": 15362,
  "authEntityId": 15362,
  "displayName": "15362",
  "authEntityClass": -1909073334,
  "deployPending": "NONE",
  "instanceVersion": 0,
  "createTime": 1517966744806,
  "deployed": false,
  "isSeeded": true,
  "isStale": false,
  "lastUpdateTime": 1517966744806,
  "name": "vmware-vmotion",
  "namespace": "scalablegroup:application",
  "provisioningState": "DEFINED",
  "qualifier": "application",
  "resourceVersion": 0,
  "targetIdList": [

  ],
  "type": "scalablegroup",
  "cfsChangeInfo": [

  ],
  "customProvisions": [

  ],
  "scalableGroupExternalHandle": "vmware-vmotion",
  "scalableGroupType": "APPLICATION",
  "securityGroupTag": 0,
  "state": "ACTIVE",
  "vnAgnostic": true,
  "identitySource": {
    "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
    "instanceId": 10010,
    "authEntityId": 10010,
    "displayName": "10010",
    "authEntityClass": 1738953278,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "state": "INACTIVE",
    "type": "NBAR"
  },
  "indicativeNetworkIdentity": [
    {
      "id": "a79ed9ba-0d0d-4957-b43c-e37d407cb16d",
      "instanceId": 17701,
      "authEntityId": 17701,
      "displayName": "17701",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "8000",
      "protocol": "TCP",
      "upperPort": 0
    }
  ],
  "networkApplications": [
    {
      "id": "686395ed-3202-4105-a5d5-1839c9e3690b",
      "instanceId": 16305,
      "authEntityId": 16305,
      "displayName": "16305",
      "authEntityClass": -217092956,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "appProtocol": "tcp",
      "applicationGroup": "vmware-group",
      "applicationType": "DEFAULT",
      "categoryId": "7e19fdea-6222-4c57-9e66-66c2340d6bc3",
      "createTime": 1517966744806,
      "encrypted": "false",
      "engineId": "13",
      "helpString": "VMware live Migration of Virtual Machines",
      "ignoreConflict": false,
      "lastUpdateTime": 1517966744806,
      "longDescription": "VMware vMotion is a feature of VMware cluster that allows the migration of operational guest virtual machines between similar but separate hardware hosts sharing the same storage. Each of these transitions is completely transparent to any users on the virtual machine at the time it is being migrated.",
      "name": "vmware-vmotion",
      "nbarId": "1429",
      "p2pTechnology": "false",
      "popularity": 9,
      "rank": 65535,
      "references": "http://www.vmware.com/products/vmotion/overview.html",
      "selectorId": "493",
      "subCategory": "ff664205-64f3-447e-acff-1ec1ba9a6c31",
      "trafficClass": "MULTIMEDIA_STREAMING",
      "tunnel": "false"
    }
  ],
  "networkIdentity": [
    {
      "id": "2d17d7e8-d571-4bbf-ba3e-c1e90a9a1001",
      "instanceId": 17700,
      "authEntityId": 17700,
      "displayName": "17700",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "8000",
      "protocol": "TCP",
      "upperPort": 0
    }
  ],
  "parentScalableGroup": {
    "idRef": "4a6b37c6-7e11-40fe-a6b1-719b24813145"
  },
  "user": [

  ],
  "userGroup": [

  ]
},
{
  "id": "a6f7e621-0c8b-4a69-8be0-110834d10274",
  "instanceId": 4173,
  "authEntityId": 4173,
  "displayName": "4173",
  "authEntityClass": -1909073334,
  "deployPending": "NONE",
  "instanceVersion": 0,
  "createTime": 1517966741530,
  "deployed": false,
  "isSeeded": true,
  "isStale": false,
  "lastUpdateTime": 1517966741530,
  "name": "bgp",
  "namespace": "scalablegroup:application",
  "provisioningState": "DEFINED",
  "qualifier": "application",
  "resourceVersion": 0,
  "targetIdList": [

  ],
  "type": "scalablegroup",
  "cfsChangeInfo": [

  ],
  "customProvisions": [

  ],
  "scalableGroupExternalHandle": "bgp",
  "scalableGroupType": "APPLICATION",
  "securityGroupTag": 0,
  "state": "ACTIVE",
  "vnAgnostic": true,
  "identitySource": {
    "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
    "instanceId": 10010,
    "authEntityId": 10010,
    "displayName": "10010",
    "authEntityClass": 1738953278,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "state": "INACTIVE",
    "type": "NBAR"
  },
  "indicativeNetworkIdentity": [
    {
      "id": "ae803ebf-fec7-436c-b3b0-db76fdde14f5",
      "instanceId": 12368,
      "authEntityId": 12368,
      "displayName": "12368",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "179",
      "protocol": "UDP",
      "upperPort": 0
    },
    {
      "id": "3d93d03f-de74-4208-84ad-ba439d75fcb9",
      "instanceId": 12369,
      "authEntityId": 12369,
      "displayName": "12369",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "179",
      "protocol": "TCP",
      "upperPort": 0
    }
  ],
  "networkApplications": [
    {
      "id": "30379694-8bea-4465-972a-ddf36a0254d5",
      "instanceId": 11132,
      "authEntityId": 11132,
      "displayName": "11132",
      "authEntityClass": -217092956,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "appProtocol": "tcp/udp",
      "applicationGroup": "other",
      "applicationType": "DEFAULT",
      "categoryId": "1fc17362-9334-4c7b-b752-5afdbb574017",
      "createTime": 1517966741530,
      "encrypted": "false",
      "engineId": "3",
      "helpString": "Border Gateway Protocol",
      "ignoreConflict": false,
      "lastUpdateTime": 1517966741530,
      "longDescription": "Border Gateway Protocol (BGP) is a protocol designed to share network information (for example network reachability) between autonomous systems (AS). According to the information, the BGP routers build/modify their routing tables. The protocol was designed to replace the Exterior Gateway Protocol (EGP).",
      "name": "bgp",
      "nbarId": "11",
      "p2pTechnology": "false",
      "popularity": 8,
      "rank": 65535,
      "references": "http://tools.ietf.org/html/rfc4274",
      "selectorId": "179",
      "subCategory": "7cc1b3a6-4102-44e9-b522-0e71afe39a03",
      "trafficClass": "NETWORK_CONTROL",
      "tunnel": "false"
    }
  ],
  "networkIdentity": [
    {
      "id": "bfc3bd9a-80a3-4373-96bb-8d8989ef7ac3",
      "instanceId": 12366,
      "authEntityId": 12366,
      "displayName": "12366",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "179",
      "protocol": "UDP",
      "upperPort": 0
    },
    {
      "id": "87e887eb-d8ca-42c0-9509-ab7590ffbfcb",
      "instanceId": 12367,
      "authEntityId": 12367,
      "displayName": "12367",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "179",
      "protocol": "TCP",
      "upperPort": 0
    }
  ],
  "parentScalableGroup": {
    "idRef": "77e75842-e98b-4112-ad0c-300db4bb11be"
  },
  "user": [

  ],
  "userGroup": [

  ]
},
{
  "id": "a746e686-937a-41e6-b308-4058ccb4e6ef",
  "instanceId": 4419,
  "authEntityId": 4419,
  "displayName": "4419",
  "authEntityClass": -1909073334,
  "deployPending": "NONE",
  "instanceVersion": 0,
  "createTime": 1517966742591,
  "deployed": false,
  "isSeeded": true,
  "isStale": false,
  "lastUpdateTime": 1517966742591,
  "name": "epmap",
  "namespace": "scalablegroup:application",
  "provisioningState": "DEFINED",
  "qualifier": "application",
  "resourceVersion": 0,
  "targetIdList": [

  ],
  "type": "scalablegroup",
  "cfsChangeInfo": [

  ],
  "customProvisions": [

  ],
  "scalableGroupExternalHandle": "epmap",
  "scalableGroupType": "APPLICATION",
  "securityGroupTag": 0,
  "state": "ACTIVE",
  "vnAgnostic": true,
  "identitySource": {
    "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
    "instanceId": 10010,
    "authEntityId": 10010,
    "displayName": "10010",
    "authEntityClass": 1738953278,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "state": "INACTIVE",
    "type": "NBAR"
  },
  "indicativeNetworkIdentity": [
    {
      "id": "008767a1-dbb1-42e2-9dc3-c90595c49195",
      "instanceId": 13054,
      "authEntityId": 13054,
      "displayName": "13054",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "135",
      "protocol": "TCP",
      "upperPort": 0
    },
    {
      "id": "dd8f0f97-740a-43e4-b0e2-9bf01ab42c9a",
      "instanceId": 13055,
      "authEntityId": 13055,
      "displayName": "13055",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "135",
      "protocol": "UDP",
      "upperPort": 0
    }
  ],
  "networkApplications": [
    {
      "id": "f0c9ccbd-51ea-4858-8a7a-bf14b1756e8c",
      "instanceId": 11370,
      "authEntityId": 11370,
      "displayName": "11370",
      "authEntityClass": -217092956,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "appProtocol": "tcp/udp",
      "applicationGroup": "other",
      "applicationType": "DEFAULT",
      "categoryId": "58aa61dc-dd95-4a6d-9c7c-0ebf16da6dde",
      "createTime": 1517966742591,
      "encrypted": "false",
      "engineId": "3",
      "helpString": "DCE endpoint resolution",
      "ignoreConflict": false,
      "lastUpdateTime": 1517966742591,
      "longDescription": "Registered with IANA on port 135 TCP/UDP",
      "name": "epmap",
      "nbarId": "1311",
      "p2pTechnology": "false",
      "popularity": 1,
      "rank": 65535,
      "references": "http://www.iana.org/assignments/service-names-port-numbers/service-names-port-numbers.xml",
      "selectorId": "135",
      "subCategory": "b90786f4-0190-45b8-9924-21b3e9682590",
      "trafficClass": "TRANSACTIONAL_DATA",
      "tunnel": "false"
    }
  ],
  "networkIdentity": [
    {
      "id": "61521ca0-be26-4a02-b702-2d646e2d0533",
      "instanceId": 13052,
      "authEntityId": 13052,
      "displayName": "13052",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "135",
      "protocol": "TCP",
      "upperPort": 0
    },
    {
      "id": "eeaff0b7-8dd5-41b8-996c-74df834f7b13",
      "instanceId": 13053,
      "authEntityId": 13053,
      "displayName": "13053",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "135",
      "protocol": "UDP",
      "upperPort": 0
    }
  ],
  "parentScalableGroup": {
    "idRef": "cff11752-fafb-4e22-9e6f-11f44f59a9a3"
  },
  "user": [

  ],
  "userGroup": [

  ]
},
{
  "id": "a77d79e7-f554-44fe-a66f-68e3b7220cbe",
  "instanceId": 15159,
  "authEntityId": 15159,
  "displayName": "15159",
  "authEntityClass": -1909073334,
  "deployPending": "NONE",
  "instanceVersion": 0,
  "createTime": 1517966744369,
  "deployed": false,
  "isSeeded": true,
  "isStale": false,
  "lastUpdateTime": 1517966744369,
  "name": "sling",
  "namespace": "scalablegroup:application",
  "provisioningState": "DEFINED",
  "qualifier": "application",
  "resourceVersion": 0,
  "targetIdList": [

  ],
  "type": "scalablegroup",
  "cfsChangeInfo": [

  ],
  "customProvisions": [

  ],
  "scalableGroupExternalHandle": "sling",
  "scalableGroupType": "APPLICATION",
  "securityGroupTag": 0,
  "state": "ACTIVE",
  "vnAgnostic": true,
  "identitySource": {
    "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
    "instanceId": 10010,
    "authEntityId": 10010,
    "displayName": "10010",
    "authEntityClass": 1738953278,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "state": "INACTIVE",
    "type": "NBAR"
  },
  "indicativeNetworkIdentity": [

  ],
  "networkApplications": [
    {
      "id": "c2f66763-37a8-4c6e-b65d-5053e046cc5f",
      "instanceId": 16102,
      "authEntityId": 16102,
      "displayName": "16102",
      "authEntityClass": -217092956,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "appProtocol": "tcp/udp",
      "applicationGroup": "other",
      "applicationType": "DEFAULT",
      "categoryId": "a09ef1b3-ba26-4372-aee8-309d50697e76",
      "createTime": 1517966744369,
      "encrypted": "false",
      "engineId": "13",
      "helpString": "SlingBox Internet TV",
      "ignoreConflict": false,
      "lastUpdateTime": 1517966744369,
      "longDescription": "Sling is a protocol used for the Slingbox TV streaming device. It works with a broadband internet connection over HTTP.",
      "name": "sling",
      "nbarId": "892",
      "p2pTechnology": "false",
      "popularity": 7,
      "rank": 65535,
      "references": "http://www.slingbox.com/",
      "selectorId": "440",
      "subCategory": "85a797c0-aef8-4284-91a7-677f33e29abb",
      "trafficClass": "MULTIMEDIA_STREAMING",
      "tunnel": "false"
    }
  ],
  "networkIdentity": [
    {
      "id": "f3c8c978-ea63-4f28-b79f-e0d30ae22b1d",
      "instanceId": 17121,
      "authEntityId": 17121,
      "displayName": "17121",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "53,80,5353",
      "protocol": "TCP",
      "upperPort": 0
    },
    {
      "id": "00cd4334-607c-4e11-aaf0-c132c51ade0b",
      "instanceId": 17122,
      "authEntityId": 17122,
      "displayName": "17122",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "53,5353",
      "protocol": "UDP",
      "upperPort": 0
    }
  ],
  "parentScalableGroup": {
    "idRef": "fd6264c9-c16a-4311-a335-a3db039256f3"
  },
  "user": [

  ],
  "userGroup": [

  ]
},
{
  "id": "a7e0515f-8106-4777-b7d0-73b65337862e",
  "instanceId": 4863,
  "authEntityId": 4863,
  "displayName": "4863",
  "authEntityClass": -1909073334,
  "deployPending": "NONE",
  "instanceVersion": 0,
  "createTime": 1517966743638,
  "deployed": false,
  "isSeeded": true,
  "isStale": false,
  "lastUpdateTime": 1517966743638,
  "name": "ni-ftp",
  "namespace": "scalablegroup:application",
  "provisioningState": "DEFINED",
  "qualifier": "application",
  "resourceVersion": 0,
  "targetIdList": [

  ],
  "type": "scalablegroup",
  "cfsChangeInfo": [

  ],
  "customProvisions": [

  ],
  "scalableGroupExternalHandle": "ni-ftp",
  "scalableGroupType": "APPLICATION",
  "securityGroupTag": 0,
  "state": "ACTIVE",
  "vnAgnostic": true,
  "identitySource": {
    "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
    "instanceId": 10010,
    "authEntityId": 10010,
    "displayName": "10010",
    "authEntityClass": 1738953278,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "state": "INACTIVE",
    "type": "NBAR"
  },
  "indicativeNetworkIdentity": [
    {
      "id": "cc0ba9e0-5c30-4cc0-943e-bc5314af7dfb",
      "instanceId": 14230,
      "authEntityId": 14230,
      "displayName": "14230",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "47",
      "protocol": "TCP",
      "upperPort": 0
    },
    {
      "id": "5343d1d9-1b0c-45d0-9b19-5570e5867128",
      "instanceId": 14231,
      "authEntityId": 14231,
      "displayName": "14231",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "47",
      "protocol": "UDP",
      "upperPort": 0
    }
  ],
  "networkApplications": [
    {
      "id": "c755a257-cd35-45d6-bc9e-9b1272af85c8",
      "instanceId": 11812,
      "authEntityId": 11812,
      "displayName": "11812",
      "authEntityClass": -217092956,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "appProtocol": "tcp/udp",
      "applicationGroup": "ftp-group",
      "applicationType": "DEFAULT",
      "categoryId": "e6619ce8-97de-4c63-a53a-cceddbddc1a8",
      "createTime": 1517966743638,
      "encrypted": "false",
      "engineId": "3",
      "helpString": "NI FTP",
      "ignoreConflict": false,
      "lastUpdateTime": 1517966743638,
      "longDescription": "The NI FTP is a two-party file transfer protocol. The transfer occurs in two phases. In the first, the transfer is defined and the attributes of the data to be transferred are negotiated. In the second, the data is actually transferred.",
      "name": "ni-ftp",
      "nbarId": "927",
      "p2pTechnology": "false",
      "popularity": 1,
      "rank": 65535,
      "references": "http://www.rfc-editor.org/ien/ien99.txt",
      "selectorId": "47",
      "subCategory": "50b55e2e-d0d0-4404-bea4-819351565d90",
      "trafficClass": "BULK_DATA",
      "tunnel": "false"
    }
  ],
  "networkIdentity": [
    {
      "id": "dcf4c6f9-2552-4c72-beaf-1b7da8d5bdf9",
      "instanceId": 14228,
      "authEntityId": 14228,
      "displayName": "14228",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "47",
      "protocol": "TCP",
      "upperPort": 0
    },
    {
      "id": "34474a47-c315-4629-a27e-840e5439d592",
      "instanceId": 14229,
      "authEntityId": 14229,
      "displayName": "14229",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "47",
      "protocol": "UDP",
      "upperPort": 0
    }
  ],
  "parentScalableGroup": {
    "idRef": "383363b1-4dd5-49d1-bfe4-34daac437cfb"
  },
  "user": [

  ],
  "userGroup": [

  ]
},
{
  "id": "a843333e-9a46-43d6-9b16-27f74c6367b3",
  "instanceId": 4831,
  "authEntityId": 4831,
  "displayName": "4831",
  "authEntityClass": -1909073334,
  "deployPending": "NONE",
  "instanceVersion": 0,
  "createTime": 1517966743576,
  "deployed": false,
  "isSeeded": true,
  "isStale": false,
  "lastUpdateTime": 1517966743576,
  "name": "net-assistant",
  "namespace": "scalablegroup:application",
  "provisioningState": "DEFINED",
  "qualifier": "application",
  "resourceVersion": 0,
  "targetIdList": [

  ],
  "type": "scalablegroup",
  "cfsChangeInfo": [

  ],
  "customProvisions": [

  ],
  "scalableGroupExternalHandle": "net-assistant",
  "scalableGroupType": "APPLICATION",
  "securityGroupTag": 0,
  "state": "ACTIVE",
  "vnAgnostic": true,
  "identitySource": {
    "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
    "instanceId": 10010,
    "authEntityId": 10010,
    "displayName": "10010",
    "authEntityClass": 1738953278,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "state": "INACTIVE",
    "type": "NBAR"
  },
  "indicativeNetworkIdentity": [

  ],
  "networkApplications": [
    {
      "id": "8c0e0ae1-b4b2-4197-9ebe-59b4a6ec7ddb",
      "instanceId": 11780,
      "authEntityId": 11780,
      "displayName": "11780",
      "authEntityClass": -217092956,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "applicationGroup": "other",
      "applicationType": "DEFAULT",
      "categoryId": "6daa3dfd-3526-407a-88f0-97824b3f3a24",
      "createTime": 1517966743576,
      "encrypted": "unassigned",
      "engineId": "3",
      "helpString": "DEPRECATED, Please refer to apple-remote-desktop",
      "ignoreConflict": false,
      "lastUpdateTime": 1517966743576,
      "longDescription": "Apple Net Assistant is part of an administrators package developed for Apple OS based networks, giving the administrator remote desktop access.",
      "name": "net-assistant",
      "nbarId": "712",
      "p2pTechnology": "unassigned",
      "popularity": 0,
      "rank": 65535,
      "references": "http://www.apple.com/remotedesktop/",
      "selectorId": "3300",
      "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
      "trafficClass": "BULK_DATA",
      "tunnel": "unassigned"
    }
  ],
  "networkIdentity": [

  ],
  "parentScalableGroup": {
    "idRef": "000c15bf-28fe-4fbb-9b69-35e6502721f1"
  },
  "user": [

  ],
  "userGroup": [

  ]
},
{
  "id": "a857909a-064b-4548-bfeb-aeb3c18ebfd9",
  "instanceId": 4586,
  "authEntityId": 4586,
  "displayName": "4586",
  "authEntityClass": -1909073334,
  "deployPending": "NONE",
  "instanceVersion": 0,
  "createTime": 1517966743056,
  "deployed": false,
  "isSeeded": true,
  "isStale": false,
  "lastUpdateTime": 1517966743056,
  "name": "idpr",
  "namespace": "scalablegroup:application",
  "provisioningState": "DEFINED",
  "qualifier": "application",
  "resourceVersion": 0,
  "targetIdList": [

  ],
  "type": "scalablegroup",
  "cfsChangeInfo": [

  ],
  "customProvisions": [

  ],
  "scalableGroupExternalHandle": "idpr",
  "scalableGroupType": "APPLICATION",
  "securityGroupTag": 0,
  "state": "ACTIVE",
  "vnAgnostic": true,
  "identitySource": {
    "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
    "instanceId": 10010,
    "authEntityId": 10010,
    "displayName": "10010",
    "authEntityClass": 1738953278,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "state": "INACTIVE",
    "type": "NBAR"
  },
  "indicativeNetworkIdentity": [
    {
      "id": "f92f0560-af60-4988-a757-e5076523a8af",
      "instanceId": 13474,
      "authEntityId": 13474,
      "displayName": "13474",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipProtocolNumbers": "35",
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "protocol": "IDPR",
      "upperPort": 0
    }
  ],
  "networkApplications": [
    {
      "id": "c7f0f39e-611d-4a10-91d7-515c10bc5f3f",
      "instanceId": 11536,
      "authEntityId": 11536,
      "displayName": "11536",
      "authEntityClass": -217092956,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "appProtocol": "ip",
      "applicationGroup": "other",
      "applicationType": "DEFAULT",
      "categoryId": "1fc17362-9334-4c7b-b752-5afdbb574017",
      "createTime": 1517966743056,
      "encrypted": "unassigned",
      "engineId": "1",
      "helpString": "Inter-Domain Policy Routing Protocol",
      "ignoreConflict": false,
      "lastUpdateTime": 1517966743056,
      "longDescription": "Inter-Domain Policy Routing Protocol (IDPR) constructs and maintains routes between source and destination administrative domains, that provide user traffic with the services requested within the constraints stipulated for the domains transited.  IDPR supports link state routing information distribution and route generation in conjunction with source specified message forwarding.",
      "name": "idpr",
      "nbarId": "789",
      "p2pTechnology": "unassigned",
      "popularity": 2,
      "rank": 65535,
      "references": "http://tools.ietf.org/html/rfc1479",
      "selectorId": "35",
      "subCategory": "7cc1b3a6-4102-44e9-b522-0e71afe39a03",
      "trafficClass": "NETWORK_CONTROL",
      "tunnel": "unassigned"
    }
  ],
  "networkIdentity": [
    {
      "id": "66c6bb58-7e7a-48b5-aaa3-4c70f7850536",
      "instanceId": 13473,
      "authEntityId": 13473,
      "displayName": "13473",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipProtocolNumbers": "35",
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "protocol": "IDPR",
      "upperPort": 0
    }
  ],
  "parentScalableGroup": {
    "idRef": "77e75842-e98b-4112-ad0c-300db4bb11be"
  },
  "user": [

  ],
  "userGroup": [

  ]
},
{
  "id": "a889fa76-6b63-41fb-b1dc-72196fa3dbb7",
  "instanceId": 15078,
  "authEntityId": 15078,
  "displayName": "15078",
  "authEntityClass": -1909073334,
  "deployPending": "NONE",
  "instanceVersion": 0,
  "createTime": 1517966744183,
  "deployed": false,
  "isSeeded": true,
  "isStale": false,
  "lastUpdateTime": 1517966744183,
  "name": "rtp-audio",
  "namespace": "scalablegroup:application",
  "provisioningState": "DEFINED",
  "qualifier": "application",
  "resourceVersion": 0,
  "targetIdList": [

  ],
  "type": "scalablegroup",
  "cfsChangeInfo": [

  ],
  "customProvisions": [

  ],
  "scalableGroupExternalHandle": "rtp-audio",
  "scalableGroupType": "APPLICATION",
  "securityGroupTag": 0,
  "state": "ACTIVE",
  "vnAgnostic": true,
  "identitySource": {
    "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
    "instanceId": 10010,
    "authEntityId": 10010,
    "displayName": "10010",
    "authEntityClass": 1738953278,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "state": "INACTIVE",
    "type": "NBAR"
  },
  "indicativeNetworkIdentity": [

  ],
  "networkApplications": [
    {
      "id": "5e41fbc2-2d99-4575-9880-bdbb04b434f3",
      "instanceId": 16021,
      "authEntityId": 16021,
      "displayName": "16021",
      "authEntityClass": -217092956,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "appProtocol": "tcp/udp",
      "applicationGroup": "other",
      "applicationType": "DEFAULT",
      "categoryId": "38a8e354-e2fe-4f3c-b1c5-2bbb90fb0d99",
      "createTime": 1517966744183,
      "encrypted": "false",
      "engineId": "13",
      "helpString": "Real Time Protocol Audio",
      "ignoreConflict": false,
      "lastUpdateTime": 1517966744183,
      "longDescription": "RTP Audio is the audio portion of Real-time Transport Protocol (RTP) which is used for streaming audio in real time for various applications. RTP works in conjunction with some streaming control protocols like RTCP, SIP, H.225 or H.245",
      "name": "rtp-audio",
      "nbarId": "1503",
      "p2pTechnology": "false",
      "popularity": 10,
      "rank": 65535,
      "references": "http://tools.ietf.org/html/rfc3550",
      "selectorId": "566",
      "subCategory": "05178016-4555-4291-ab4e-43c2ab2a931a",
      "trafficClass": "VOIP_TELEPHONY",
      "tunnel": "false"
    }
  ],
  "networkIdentity": [
    {
      "id": "73c30c01-c071-4c3b-8675-5128c117c6a2",
      "instanceId": 14870,
      "authEntityId": 14870,
      "displayName": "14870",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "80,3478,5060",
      "protocol": "TCP",
      "upperPort": 0
    },
    {
      "id": "ea4021e2-7c7e-49c6-8a2f-2e182733b3ea",
      "instanceId": 14871,
      "authEntityId": 14871,
      "displayName": "14871",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "3478,5060",
      "protocol": "UDP",
      "upperPort": 0
    }
  ],
  "parentScalableGroup": {
    "idRef": "60f1364d-2a02-4063-8ba1-07817abe0612"
  },
  "user": [

  ],
  "userGroup": [

  ]
},
{
  "id": "a8921b44-f6e7-4a68-aeaa-161d4328965f",
  "instanceId": 4793,
  "authEntityId": 4793,
  "displayName": "4793",
  "authEntityClass": -1909073334,
  "deployPending": "NONE",
  "instanceVersion": 0,
  "createTime": 1517966743505,
  "deployed": false,
  "isSeeded": true,
  "isStale": false,
  "lastUpdateTime": 1517966743505,
  "name": "ms-wbt",
  "namespace": "scalablegroup:application",
  "provisioningState": "DEFINED",
  "qualifier": "application",
  "resourceVersion": 0,
  "targetIdList": [

  ],
  "type": "scalablegroup",
  "cfsChangeInfo": [

  ],
  "customProvisions": [

  ],
  "scalableGroupExternalHandle": "ms-wbt",
  "scalableGroupType": "APPLICATION",
  "securityGroupTag": 0,
  "state": "ACTIVE",
  "vnAgnostic": true,
  "identitySource": {
    "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
    "instanceId": 10010,
    "authEntityId": 10010,
    "displayName": "10010",
    "authEntityClass": 1738953278,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "state": "INACTIVE",
    "type": "NBAR"
  },
  "indicativeNetworkIdentity": [
    {
      "id": "71ac0e85-909b-4426-8072-06c5a63c7ab1",
      "instanceId": 14023,
      "authEntityId": 14023,
      "displayName": "14023",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "3389",
      "protocol": "TCP",
      "upperPort": 0
    }
  ],
  "networkApplications": [
    {
      "id": "702d0d16-2b50-4515-b69a-03a72737e07d",
      "instanceId": 11742,
      "authEntityId": 11742,
      "displayName": "11742",
      "authEntityClass": -217092956,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "appProtocol": "tcp",
      "applicationGroup": "other",
      "applicationType": "DEFAULT",
      "categoryId": "7e19fdea-6222-4c57-9e66-66c2340d6bc3",
      "createTime": 1517966743505,
      "encrypted": "false",
      "engineId": "3",
      "helpString": "Microsoft Windows based Terminal Services",
      "ignoreConflict": false,
      "lastUpdateTime": 1517966743505,
      "longDescription": "Remote Desktop Protocol (RDP) is a protocol developed by Microsoft. It was formerly known as Microsoft Windows Based Terminal (MS WBT). By default, RDP communication travels over TCP port 3389. The most common use of this protocol is the Remote Desktop Connection or Terminal Services client. The protocol is an extension of the ITU-T T.128 application sharing protocol. Clients exist for most versions of Microsoft Windows (including Windows Mobile), Linux, Unix, Mac OS X and other modern operating systems.",
      "name": "ms-wbt",
      "nbarId": "689",
      "p2pTechnology": "false",
      "popularity": 10,
      "rank": 65535,
      "references": "http://technet.microsoft.com/en-us/library/cc960973.aspx",
      "selectorId": "3389",
      "subCategory": "ff664205-64f3-447e-acff-1ec1ba9a6c31",
      "trafficClass": "MULTIMEDIA_STREAMING",
      "tunnel": "false"
    }
  ],
  "networkIdentity": [
    {
      "id": "1b779c5e-70cd-41a3-9243-63490683aa88",
      "instanceId": 14022,
      "authEntityId": 14022,
      "displayName": "14022",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "3389",
      "protocol": "TCP",
      "upperPort": 0
    }
  ],
  "parentScalableGroup": {
    "idRef": "4a6b37c6-7e11-40fe-a6b1-719b24813145"
  },
  "user": [

  ],
  "userGroup": [

  ]
},
{
  "id": "a8d68934-2603-4f1c-a8ca-701f4ebc64f7",
  "instanceId": 15382,
  "authEntityId": 15382,
  "displayName": "15382",
  "authEntityClass": -1909073334,
  "deployPending": "NONE",
  "instanceVersion": 0,
  "createTime": 1517966744844,
  "deployed": false,
  "isSeeded": true,
  "isStale": false,
  "lastUpdateTime": 1517966744844,
  "name": "wap-wsp",
  "namespace": "scalablegroup:application",
  "provisioningState": "DEFINED",
  "qualifier": "application",
  "resourceVersion": 0,
  "targetIdList": [

  ],
  "type": "scalablegroup",
  "cfsChangeInfo": [

  ],
  "customProvisions": [

  ],
  "scalableGroupExternalHandle": "wap-wsp",
  "scalableGroupType": "APPLICATION",
  "securityGroupTag": 0,
  "state": "ACTIVE",
  "vnAgnostic": true,
  "identitySource": {
    "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
    "instanceId": 10010,
    "authEntityId": 10010,
    "displayName": "10010",
    "authEntityClass": 1738953278,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "state": "INACTIVE",
    "type": "NBAR"
  },
  "indicativeNetworkIdentity": [
    {
      "id": "6ef7a8b2-8da2-426e-b55e-09fdf2e54bc6",
      "instanceId": 17771,
      "authEntityId": 17771,
      "displayName": "17771",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "9200",
      "protocol": "UDP",
      "upperPort": 0
    },
    {
      "id": "023fcaa4-87f5-42d5-8430-57f538fd83a1",
      "instanceId": 17772,
      "authEntityId": 17772,
      "displayName": "17772",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "9200",
      "protocol": "TCP",
      "upperPort": 0
    }
  ],
  "networkApplications": [
    {
      "id": "a1fe9e9c-bd84-4615-9f36-1fc46f35d7d5",
      "instanceId": 16325,
      "authEntityId": 16325,
      "displayName": "16325",
      "authEntityClass": -217092956,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "appProtocol": "tcp/udp",
      "applicationGroup": "wap-group",
      "applicationType": "DEFAULT",
      "categoryId": "6daa3dfd-3526-407a-88f0-97824b3f3a24",
      "createTime": 1517966744844,
      "encrypted": "false",
      "engineId": "3",
      "helpString": "WAP connectionless session service",
      "ignoreConflict": false,
      "lastUpdateTime": 1517966744844,
      "longDescription": "Wireless Session Protocol (WSP) is an open standard for maintaining high level session. Wireless session is a normal Web browsing session that starts when the user connects to one URL and ends when the user leaves that URL. By establishing the session, the session wide properties need only to be defined once at the beginning of the session. This has the benefit of saving bandwidth due to the nature of the wireless communication. The session establishing process does not have lengthy hand shaking mechanisms.",
      "name": "wap-wsp",
      "nbarId": "729",
      "p2pTechnology": "false",
      "popularity": 2,
      "rank": 65535,
      "references": "http://vallejo.cc/proyectos/envio%20sms_files/OMA-WAP-ProvCont-v1_1-20050428-C.pdf",
      "selectorId": "9200",
      "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
      "trafficClass": "BULK_DATA",
      "tunnel": "false"
    }
  ],
  "networkIdentity": [
    {
      "id": "ab70411d-c1ea-4209-afa5-fd1f43cccb9c",
      "instanceId": 17769,
      "authEntityId": 17769,
      "displayName": "17769",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "9200",
      "protocol": "UDP",
      "upperPort": 0
    },
    {
      "id": "eb4cb7d3-3967-4929-96d7-b21fccf1735f",
      "instanceId": 17770,
      "authEntityId": 17770,
      "displayName": "17770",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "9200",
      "protocol": "TCP",
      "upperPort": 0
    }
  ],
  "parentScalableGroup": {
    "idRef": "000c15bf-28fe-4fbb-9b69-35e6502721f1"
  },
  "user": [

  ],
  "userGroup": [

  ]
},
{
  "id": "a8f36355-a7d5-4f49-bb4e-163889d3d452",
  "instanceId": 4759,
  "authEntityId": 4759,
  "displayName": "4759",
  "authEntityClass": -1909073334,
  "deployPending": "NONE",
  "instanceVersion": 0,
  "createTime": 1517966743437,
  "deployed": false,
  "isSeeded": true,
  "isStale": false,
  "lastUpdateTime": 1517966743437,
  "name": "mongo",
  "namespace": "scalablegroup:application",
  "provisioningState": "DEFINED",
  "qualifier": "application",
  "resourceVersion": 0,
  "targetIdList": [

  ],
  "type": "scalablegroup",
  "cfsChangeInfo": [

  ],
  "customProvisions": [

  ],
  "scalableGroupExternalHandle": "mongo",
  "scalableGroupType": "APPLICATION",
  "securityGroupTag": 0,
  "state": "ACTIVE",
  "vnAgnostic": true,
  "identitySource": {
    "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
    "instanceId": 10010,
    "authEntityId": 10010,
    "displayName": "10010",
    "authEntityClass": 1738953278,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "state": "INACTIVE",
    "type": "NBAR"
  },
  "indicativeNetworkIdentity": [

  ],
  "networkApplications": [
    {
      "id": "e3ed3cab-bec6-44e8-a0a5-6e57b46e31c6",
      "instanceId": 11709,
      "authEntityId": 11709,
      "displayName": "11709",
      "authEntityClass": -217092956,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "applicationGroup": "other",
      "applicationType": "DEFAULT",
      "categoryId": "cb5915d1-afec-4c30-ac9e-069ff382272f",
      "createTime": 1517966743437,
      "encrypted": "false",
      "engineId": "3",
      "helpString": "No SQL Database management system",
      "ignoreConflict": false,
      "lastUpdateTime": 1517966743437,
      "longDescription": "mongo is a leading no SQL db  designed with both scalability and developer agility in mind. Instead of storing your data in tables and rows as you would with a relational database, in MongoDB you store JSON-like documents with dynamic schemas",
      "name": "mongo",
      "nbarId": "1751",
      "p2pTechnology": "false",
      "popularity": 9,
      "rank": 65535,
      "references": "https://www.mongodb.org/",
      "selectorId": "27017",
      "subCategory": "b90786f4-0190-45b8-9924-21b3e9682590",
      "trafficClass": "TRANSACTIONAL_DATA",
      "tunnel": "false"
    }
  ],
  "networkIdentity": [

  ],
  "parentScalableGroup": {
    "idRef": "8718395e-683e-4a2d-a232-100202751271"
  },
  "user": [

  ],
  "userGroup": [

  ]
},
{
  "id": "a96194e6-5752-4d31-81f5-32d85c045b90",
  "instanceId": 4222,
  "authEntityId": 4222,
  "displayName": "4222",
  "authEntityClass": -1909073334,
  "deployPending": "NONE",
  "instanceVersion": 0,
  "createTime": 1517966741820,
  "deployed": false,
  "isSeeded": true,
  "isStale": false,
  "lastUpdateTime": 1517966741820,
  "name": "chaos",
  "namespace": "scalablegroup:application",
  "provisioningState": "DEFINED",
  "qualifier": "application",
  "resourceVersion": 0,
  "targetIdList": [

  ],
  "type": "scalablegroup",
  "cfsChangeInfo": [

  ],
  "customProvisions": [

  ],
  "scalableGroupExternalHandle": "chaos",
  "scalableGroupType": "APPLICATION",
  "securityGroupTag": 0,
  "state": "ACTIVE",
  "vnAgnostic": true,
  "identitySource": {
    "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
    "instanceId": 10010,
    "authEntityId": 10010,
    "displayName": "10010",
    "authEntityClass": 1738953278,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "state": "INACTIVE",
    "type": "NBAR"
  },
  "indicativeNetworkIdentity": [
    {
      "id": "4080735d-3e23-4083-8208-6fe7706fc968",
      "instanceId": 12486,
      "authEntityId": 12486,
      "displayName": "12486",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipProtocolNumbers": "16",
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "protocol": "CHAOS",
      "upperPort": 0
    }
  ],
  "networkApplications": [
    {
      "id": "1c8a5540-eda5-4b17-90b3-6893b2c13c99",
      "instanceId": 11177,
      "authEntityId": 11177,
      "displayName": "11177",
      "authEntityClass": -217092956,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "appProtocol": "ip",
      "applicationGroup": "other",
      "applicationType": "DEFAULT",
      "categoryId": "6daa3dfd-3526-407a-88f0-97824b3f3a24",
      "createTime": 1517966741820,
      "encrypted": "unassigned",
      "engineId": "1",
      "helpString": "Chaos",
      "ignoreConflict": false,
      "lastUpdateTime": 1517966741820,
      "longDescription": "CHAOSNet is one of the earliest local area network hardware implementations. The Chaosnet protocol  implementation was over CATV coaxial cable modeled on the early Xerox PARC 3 megabit/second Ethernet, over ARPANET, and over Transmission Control Protocol (TCP).",
      "name": "chaos",
      "nbarId": "771",
      "p2pTechnology": "unassigned",
      "popularity": 2,
      "rank": 65535,
      "references": "http://en.wikipedia.org/wiki/CHAOSnet",
      "selectorId": "16",
      "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
      "trafficClass": "BULK_DATA",
      "tunnel": "unassigned"
    }
  ],
  "networkIdentity": [
    {
      "id": "e2db98d1-24c2-44e9-85dc-e55dbdc96bcb",
      "instanceId": 12485,
      "authEntityId": 12485,
      "displayName": "12485",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipProtocolNumbers": "16",
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "protocol": "CHAOS",
      "upperPort": 0
    }
  ],
  "parentScalableGroup": {
    "idRef": "000c15bf-28fe-4fbb-9b69-35e6502721f1"
  },
  "user": [

  ],
  "userGroup": [

  ]
},
{
  "id": "a98f4dad-1e80-4ce5-a8e3-0ab0d674c519",
  "instanceId": 15319,
  "authEntityId": 15319,
  "displayName": "15319",
  "authEntityClass": -1909073334,
  "deployPending": "NONE",
  "instanceVersion": 0,
  "createTime": 1517966744731,
  "deployed": false,
  "isSeeded": true,
  "isStale": false,
  "lastUpdateTime": 1517966744731,
  "name": "ulp",
  "namespace": "scalablegroup:application",
  "provisioningState": "DEFINED",
  "qualifier": "application",
  "resourceVersion": 0,
  "targetIdList": [

  ],
  "type": "scalablegroup",
  "cfsChangeInfo": [

  ],
  "customProvisions": [

  ],
  "scalableGroupExternalHandle": "ulp",
  "scalableGroupType": "APPLICATION",
  "securityGroupTag": 0,
  "state": "ACTIVE",
  "vnAgnostic": true,
  "identitySource": {
    "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
    "instanceId": 10010,
    "authEntityId": 10010,
    "displayName": "10010",
    "authEntityClass": 1738953278,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "state": "INACTIVE",
    "type": "NBAR"
  },
  "indicativeNetworkIdentity": [
    {
      "id": "1f809760-f2f0-4748-a360-a46a97401ec9",
      "instanceId": 17577,
      "authEntityId": 17577,
      "displayName": "17577",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "522",
      "protocol": "UDP",
      "upperPort": 0
    },
    {
      "id": "684c8d81-762e-46bc-89e5-cbcaeabfcdb5",
      "instanceId": 17578,
      "authEntityId": 17578,
      "displayName": "17578",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "522",
      "protocol": "TCP",
      "upperPort": 0
    }
  ],
  "networkApplications": [
    {
      "id": "369a2eee-b2fe-40e4-b2c3-698108cca322",
      "instanceId": 16262,
      "authEntityId": 16262,
      "displayName": "16262",
      "authEntityClass": -217092956,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "appProtocol": "tcp/udp",
      "applicationGroup": "other",
      "applicationType": "DEFAULT",
      "categoryId": "1fc17362-9334-4c7b-b752-5afdbb574017",
      "createTime": 1517966744731,
      "encrypted": "false",
      "engineId": "3",
      "helpString": "ulp",
      "ignoreConflict": false,
      "lastUpdateTime": 1517966744731,
      "longDescription": "The User Location Protocol (ULP) is the interface between a user location client and a user location server. User Location Servers are server programs that maintain dynamic information about users and the applications they are running. The server is similar to a DNS server in that the set of resource information associated with a particular user is composed of separate resource records. User Location Clients are programs that connect to user location servers. Clients can create, delete, modify, and query resource records on the server.",
      "name": "ulp",
      "nbarId": "440",
      "p2pTechnology": "false",
      "popularity": 1,
      "rank": 65535,
      "references": "http://www1.cs.columbia.edu/sip/drafts/draft-williams-uls-01.txt",
      "selectorId": "522",
      "subCategory": "f3c0f761-d894-4112-ab89-b90fc84fd660",
      "trafficClass": "OPS_ADMIN_MGMT",
      "tunnel": "false"
    }
  ],
  "networkIdentity": [
    {
      "id": "07fb76f1-ae19-4399-a03d-1674fa7032b7",
      "instanceId": 17575,
      "authEntityId": 17575,
      "displayName": "17575",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "522",
      "protocol": "TCP",
      "upperPort": 0
    },
    {
      "id": "209cea62-00b9-486c-b9eb-065d46208993",
      "instanceId": 17576,
      "authEntityId": 17576,
      "displayName": "17576",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "522",
      "protocol": "UDP",
      "upperPort": 0
    }
  ],
  "parentScalableGroup": {
    "idRef": "5fcd74d4-8726-47f7-a088-c457863da7ef"
  },
  "user": [

  ],
  "userGroup": [

  ]
},
{
  "id": "a9990e67-d071-4599-aebd-1c973cd901e3",
  "instanceId": 15346,
  "authEntityId": 15346,
  "displayName": "15346",
  "authEntityClass": -1909073334,
  "deployPending": "NONE",
  "instanceVersion": 0,
  "createTime": 1517966744779,
  "deployed": false,
  "isSeeded": true,
  "isStale": false,
  "lastUpdateTime": 1517966744779,
  "name": "ventrilo",
  "namespace": "scalablegroup:application",
  "provisioningState": "DEFINED",
  "qualifier": "application",
  "resourceVersion": 0,
  "targetIdList": [

  ],
  "type": "scalablegroup",
  "cfsChangeInfo": [

  ],
  "customProvisions": [

  ],
  "scalableGroupExternalHandle": "ventrilo",
  "scalableGroupType": "APPLICATION",
  "securityGroupTag": 0,
  "state": "ACTIVE",
  "vnAgnostic": true,
  "identitySource": {
    "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
    "instanceId": 10010,
    "authEntityId": 10010,
    "displayName": "10010",
    "authEntityClass": 1738953278,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "state": "INACTIVE",
    "type": "NBAR"
  },
  "indicativeNetworkIdentity": [

  ],
  "networkApplications": [
    {
      "id": "425c1b37-7c0f-47a2-8ec6-c0b96373e1ef",
      "instanceId": 16289,
      "authEntityId": 16289,
      "displayName": "16289",
      "authEntityClass": -217092956,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "applicationGroup": "other",
      "applicationType": "DEFAULT",
      "categoryId": "82a7b7fa-da5a-48f5-8b6a-a2546b947843",
      "createTime": 1517966744779,
      "encrypted": "false",
      "engineId": "13",
      "helpString": "Ventrilo VOIP and chat protocol",
      "ignoreConflict": false,
      "lastUpdateTime": 1517966744779,
      "longDescription": "Ventrilo is the next generation of VoIP communication software that also includes chat messages.  It is based on client-server architecture. Both server and client applications are available on various platforms, including Microsoft Windows and MAC OS X.",
      "name": "ventrilo",
      "nbarId": "1069",
      "p2pTechnology": "false",
      "popularity": 4,
      "rank": 65535,
      "references": "http://www.ventrilo.com/",
      "selectorId": "446",
      "subCategory": "118c10a2-e272-47c1-92b9-4814ebe97138",
      "trafficClass": "MULTIMEDIA_CONFERENCING",
      "tunnel": "false"
    }
  ],
  "networkIdentity": [

  ],
  "parentScalableGroup": {
    "idRef": "fd6264c9-c16a-4311-a335-a3db039256f3"
  },
  "user": [

  ],
  "userGroup": [

  ]
},
{
  "id": "aa64e0f2-0958-488f-bff8-ad0a65140891",
  "instanceId": 4157,
  "authEntityId": 4157,
  "displayName": "4157",
  "authEntityClass": -1909073334,
  "deployPending": "NONE",
  "instanceVersion": 0,
  "createTime": 1517966741430,
  "deployed": false,
  "isSeeded": true,
  "isStale": false,
  "lastUpdateTime": 1517966741430,
  "name": "backup-service",
  "namespace": "scalablegroup:application",
  "provisioningState": "DEFINED",
  "qualifier": "application",
  "resourceVersion": 0,
  "targetIdList": [

  ],
  "type": "scalablegroup",
  "cfsChangeInfo": [

  ],
  "customProvisions": [

  ],
  "scalableGroupExternalHandle": "backup-service",
  "scalableGroupType": "APPLICATION",
  "securityGroupTag": 0,
  "state": "ACTIVE",
  "vnAgnostic": true,
  "identitySource": {
    "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
    "instanceId": 10010,
    "authEntityId": 10010,
    "displayName": "10010",
    "authEntityClass": 1738953278,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "state": "INACTIVE",
    "type": "NBAR"
  },
  "indicativeNetworkIdentity": [

  ],
  "networkApplications": [
    {
      "id": "dcdf5a04-ceb6-4a81-affd-0bbf1172244a",
      "instanceId": 11117,
      "authEntityId": 11117,
      "displayName": "11117",
      "authEntityClass": -217092956,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "applicationGroup": "other",
      "applicationType": "DEFAULT",
      "categoryId": "80909d4c-efe6-4878-9e1e-68281fd26edd",
      "createTime": 1517966741430,
      "encrypted": "true",
      "engineId": "13",
      "helpString": "Backup services",
      "ignoreConflict": false,
      "lastUpdateTime": 1517966741430,
      "longDescription": "Backup Software Services",
      "name": "backup-service",
      "nbarId": "1757",
      "p2pTechnology": "false",
      "popularity": 10,
      "rank": 65535,
      "references": "https://en.wikipedia.org/wiki/Backup_software",
      "selectorId": "1757",
      "subCategory": "697101ea-4e72-4b89-872a-e2d494155c78",
      "trafficClass": "BULK_DATA",
      "tunnel": "false"
    }
  ],
  "networkIdentity": [

  ],
  "parentScalableGroup": {
    "idRef": "f09a36de-df38-4ff8-b0be-56738afc75f7"
  },
  "user": [

  ],
  "userGroup": [

  ]
},
{
  "id": "aa9b58c2-dd19-44b6-afaa-a5634dfbbabc",
  "instanceId": 4611,
  "authEntityId": 4611,
  "displayName": "4611",
  "authEntityClass": -1909073334,
  "deployPending": "NONE",
  "instanceVersion": 0,
  "createTime": 1517966743121,
  "deployed": false,
  "isSeeded": true,
  "isStale": false,
  "lastUpdateTime": 1517966743121,
  "name": "internet-video-streaming",
  "namespace": "scalablegroup:application",
  "provisioningState": "DEFINED",
  "qualifier": "application",
  "resourceVersion": 0,
  "targetIdList": [

  ],
  "type": "scalablegroup",
  "cfsChangeInfo": [

  ],
  "customProvisions": [

  ],
  "scalableGroupExternalHandle": "internet-video-streaming",
  "scalableGroupType": "APPLICATION",
  "securityGroupTag": 0,
  "state": "ACTIVE",
  "vnAgnostic": true,
  "identitySource": {
    "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
    "instanceId": 10010,
    "authEntityId": 10010,
    "displayName": "10010",
    "authEntityClass": 1738953278,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "state": "INACTIVE",
    "type": "NBAR"
  },
  "indicativeNetworkIdentity": [

  ],
  "networkApplications": [
    {
      "id": "a71daccd-a739-481b-95da-854edb06f244",
      "instanceId": 11561,
      "authEntityId": 11561,
      "displayName": "11561",
      "authEntityClass": -217092956,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "appProtocol": "tcp/udp",
      "applicationGroup": "other",
      "applicationType": "DEFAULT",
      "categoryId": "a09ef1b3-ba26-4372-aee8-309d50697e76",
      "createTime": 1517966743121,
      "encrypted": "true",
      "engineId": "13",
      "helpString": "Top internet video streaming applications",
      "ignoreConflict": false,
      "lastUpdateTime": 1517966743121,
      "longDescription": "The internet video streaming protocol gathers the top websites and web applications for video streaming on the internet such as Ustream, DailyMotion and more.",
      "name": "internet-video-streaming",
      "nbarId": "1513",
      "p2pTechnology": "false",
      "popularity": 10,
      "rank": 65535,
      "references": "http://www.ustream.tv/,http://www.dailymotion.com/,http://xfinitytv.comcast.net/,http://www.blip.tv/,http://www.vimeo.com/,http://www.gorillavid.in/,http://www.veoh.com/,http://www.metacafe.com/,http://www.primewire.ag/,http://www.dishanywhere.com/,http://www.blockbusternow.com/,http://www.justin.tv/,http://www.new.livestream.com/,http://www.crackle.com/,http://www.afreeca.com/,http://www.filmon.com/,http://www.movieclips.com/,http://www.redbox.com/,http://www.crunchyroll.com/,http://www.adnstream.com/,http://www.sunshion.com/,http://www.marca.com/,http://www.telly.com/,http://www.freecast.com/,http://www.rutube.ru/,http://www.putlocker.com/,http://www.sockshare.com/,http://www.nowvideo.sx/,http://www.novamov.com/,http://www.videosweed.com/,http://www.divxstage.eu/,http://www.vidbux.com/,http://www.vidxden.com/,http://www.free-tv-video-online.me/,http://www.tubeplus.com/,http://www.sidereel.com/,http://www.tentkotta.com/,http://video.nationalgeographic.com/,http://www.videogram.com/,http://www.yupptv.com/",
      "selectorId": "574",
      "subCategory": "85a797c0-aef8-4284-91a7-677f33e29abb",
      "trafficClass": "MULTIMEDIA_STREAMING",
      "tunnel": "false"
    }
  ],
  "networkIdentity": [
    {
      "id": "46f54ccf-8c42-4c2e-8866-f7ed67280860",
      "instanceId": 13543,
      "authEntityId": 13543,
      "displayName": "13543",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "53,5353",
      "protocol": "UDP",
      "upperPort": 0
    },
    {
      "id": "268a5266-7bbb-4197-8763-bd76a68a90e3",
      "instanceId": 13544,
      "authEntityId": 13544,
      "displayName": "13544",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "53,80,443,5353",
      "protocol": "TCP",
      "upperPort": 0
    }
  ],
  "parentScalableGroup": {
    "idRef": "fd6264c9-c16a-4311-a335-a3db039256f3"
  },
  "user": [

  ],
  "userGroup": [

  ]
},
{
  "id": "aac14b83-f6df-4c0d-a792-9011a0e5af4d",
  "instanceId": 15060,
  "authEntityId": 15060,
  "displayName": "15060",
  "authEntityClass": -1909073334,
  "deployPending": "NONE",
  "instanceVersion": 0,
  "createTime": 1517966744140,
  "deployed": false,
  "isSeeded": true,
  "isStale": false,
  "lastUpdateTime": 1517966744140,
  "name": "rpc2portmap",
  "namespace": "scalablegroup:application",
  "provisioningState": "DEFINED",
  "qualifier": "application",
  "resourceVersion": 0,
  "targetIdList": [

  ],
  "type": "scalablegroup",
  "cfsChangeInfo": [

  ],
  "customProvisions": [

  ],
  "scalableGroupExternalHandle": "rpc2portmap",
  "scalableGroupType": "APPLICATION",
  "securityGroupTag": 0,
  "state": "ACTIVE",
  "vnAgnostic": true,
  "identitySource": {
    "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
    "instanceId": 10010,
    "authEntityId": 10010,
    "displayName": "10010",
    "authEntityClass": 1738953278,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "state": "INACTIVE",
    "type": "NBAR"
  },
  "indicativeNetworkIdentity": [
    {
      "id": "26f019ba-3f59-4cd9-94b0-ab24ff136a42",
      "instanceId": 14819,
      "authEntityId": 14819,
      "displayName": "14819",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "369",
      "protocol": "UDP",
      "upperPort": 0
    },
    {
      "id": "fb3658c3-fc94-4e78-ac3f-57ba1fc65df6",
      "instanceId": 14820,
      "authEntityId": 14820,
      "displayName": "14820",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "369",
      "protocol": "TCP",
      "upperPort": 0
    }
  ],
  "networkApplications": [
    {
      "id": "5fa7bbf8-0f45-4a88-9a1b-c807e9facfdf",
      "instanceId": 11999,
      "authEntityId": 11999,
      "displayName": "11999",
      "authEntityClass": -217092956,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "appProtocol": "tcp/udp",
      "applicationGroup": "other",
      "applicationType": "DEFAULT",
      "categoryId": "1fc17362-9334-4c7b-b752-5afdbb574017",
      "createTime": 1517966744140,
      "encrypted": "false",
      "engineId": "3",
      "helpString": "rpc2portmap",
      "ignoreConflict": false,
      "lastUpdateTime": 1517966744140,
      "longDescription": "Registered with IANA on port 369 TCP/UDP",
      "name": "rpc2portmap",
      "nbarId": "285",
      "p2pTechnology": "false",
      "popularity": 1,
      "rank": 65535,
      "references": "http://www.iana.org/assignments/service-names-port-numbers/service-names-port-numbers.xml",
      "selectorId": "369",
      "subCategory": "68c4f989-5735-4e16-8d1c-6a9d89d5df9c",
      "trafficClass": "SIGNALING",
      "tunnel": "false"
    }
  ],
  "networkIdentity": [
    {
      "id": "5e100861-ada0-442a-9e78-93d1602059aa",
      "instanceId": 14817,
      "authEntityId": 14817,
      "displayName": "14817",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "369",
      "protocol": "TCP",
      "upperPort": 0
    },
    {
      "id": "dea229da-c4f7-40b1-9ab7-1b824620a583",
      "instanceId": 14818,
      "authEntityId": 14818,
      "displayName": "14818",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "369",
      "protocol": "UDP",
      "upperPort": 0
    }
  ],
  "parentScalableGroup": {
    "idRef": "a3243a74-3864-4041-a927-0201030da17c"
  },
  "user": [

  ],
  "userGroup": [

  ]
},
{
  "id": "aae4cf74-a9fe-475c-9c8c-c1fde0ebb398",
  "instanceId": 4537,
  "authEntityId": 4537,
  "displayName": "4537",
  "authEntityClass": -1909073334,
  "deployPending": "NONE",
  "instanceVersion": 0,
  "createTime": 1517966742929,
  "deployed": false,
  "isSeeded": true,
  "isStale": false,
  "lastUpdateTime": 1517966742929,
  "name": "hems",
  "namespace": "scalablegroup:application",
  "provisioningState": "DEFINED",
  "qualifier": "application",
  "resourceVersion": 0,
  "targetIdList": [

  ],
  "type": "scalablegroup",
  "cfsChangeInfo": [

  ],
  "customProvisions": [

  ],
  "scalableGroupExternalHandle": "hems",
  "scalableGroupType": "APPLICATION",
  "securityGroupTag": 0,
  "state": "ACTIVE",
  "vnAgnostic": true,
  "identitySource": {
    "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
    "instanceId": 10010,
    "authEntityId": 10010,
    "displayName": "10010",
    "authEntityClass": 1738953278,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "state": "INACTIVE",
    "type": "NBAR"
  },
  "indicativeNetworkIdentity": [
    {
      "id": "b5db52ab-8a0f-421a-8ff5-a42cb015375f",
      "instanceId": 13335,
      "authEntityId": 13335,
      "displayName": "13335",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "151",
      "protocol": "TCP",
      "upperPort": 0
    },
    {
      "id": "e19b5bc3-81f1-476a-9085-6fb646d449dc",
      "instanceId": 13336,
      "authEntityId": 13336,
      "displayName": "13336",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "151",
      "protocol": "UDP",
      "upperPort": 0
    }
  ],
  "networkApplications": [
    {
      "id": "ac697bb6-9952-4b84-98b0-e54235011f74",
      "instanceId": 11488,
      "authEntityId": 11488,
      "displayName": "11488",
      "authEntityClass": -217092956,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "appProtocol": "tcp/udp",
      "applicationGroup": "other",
      "applicationType": "DEFAULT",
      "categoryId": "6daa3dfd-3526-407a-88f0-97824b3f3a24",
      "createTime": 1517966742929,
      "encrypted": "false",
      "engineId": "3",
      "helpString": "hems",
      "ignoreConflict": false,
      "lastUpdateTime": 1517966742929,
      "longDescription": "High-Level Entity Management System (HEMS) is made up of three parts: a query processor which can reside on any addressable entity, an event generator which also resides on entities, and applications which know how to send requests to the query processor and interpret the replies.",
      "name": "hems",
      "nbarId": "981",
      "p2pTechnology": "false",
      "popularity": 1,
      "rank": 65535,
      "references": "http://tools.ietf.org/html/rfc1021",
      "selectorId": "151",
      "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
      "trafficClass": "BULK_DATA",
      "tunnel": "false"
    }
  ],
  "networkIdentity": [
    {
      "id": "2ad52ac1-4d18-403d-b09c-5ca6b323298e",
      "instanceId": 13333,
      "authEntityId": 13333,
      "displayName": "13333",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "151",
      "protocol": "TCP",
      "upperPort": 0
    },
    {
      "id": "001104b0-bf84-43bf-bac2-db9eb516489f",
      "instanceId": 13334,
      "authEntityId": 13334,
      "displayName": "13334",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "151",
      "protocol": "UDP",
      "upperPort": 0
    }
  ],
  "parentScalableGroup": {
    "idRef": "000c15bf-28fe-4fbb-9b69-35e6502721f1"
  },
  "user": [

  ],
  "userGroup": [

  ]
},
{
  "id": "ab2d6f6a-046f-44dc-9559-302b686e0f20",
  "instanceId": 4931,
  "authEntityId": 4931,
  "displayName": "4931",
  "authEntityClass": -1909073334,
  "deployPending": "NONE",
  "instanceVersion": 0,
  "createTime": 1517966743833,
  "deployed": false,
  "isSeeded": true,
  "isStale": false,
  "lastUpdateTime": 1517966743833,
  "name": "outlook-web-service",
  "namespace": "scalablegroup:application",
  "provisioningState": "DEFINED",
  "qualifier": "application",
  "resourceVersion": 0,
  "targetIdList": [

  ],
  "type": "scalablegroup",
  "cfsChangeInfo": [

  ],
  "customProvisions": [

  ],
  "scalableGroupExternalHandle": "outlook-web-service",
  "scalableGroupType": "APPLICATION",
  "securityGroupTag": 0,
  "state": "ACTIVE",
  "vnAgnostic": true,
  "identitySource": {
    "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
    "instanceId": 10010,
    "authEntityId": 10010,
    "displayName": "10010",
    "authEntityClass": 1738953278,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "state": "INACTIVE",
    "type": "NBAR"
  },
  "indicativeNetworkIdentity": [

  ],
  "networkApplications": [
    {
      "id": "69249e3d-ab56-4636-a5eb-777eda9ec897",
      "instanceId": 11880,
      "authEntityId": 11880,
      "displayName": "11880",
      "authEntityClass": -217092956,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "appProtocol": "tcp/udp",
      "applicationGroup": "ms-cloud-group",
      "applicationType": "DEFAULT",
      "categoryId": "27953c60-b2ff-4e1a-bc52-0187d8295250",
      "createTime": 1517966743833,
      "encrypted": "true",
      "engineId": "13",
      "helpString": "Microsoft Web-Based email services under Outlook brand, part of Office 365",
      "ignoreConflict": false,
      "lastUpdateTime": 1517966743833,
      "longDescription": "Outlook Web Service is a protocol that covers a group of Microsoft's web email services, it includes Outlook email service part of Microsoft Office 365 Business Plan.",
      "name": "outlook-web-service",
      "nbarId": "1484",
      "p2pTechnology": "false",
      "popularity": 10,
      "rank": 65535,
      "references": "http://www.outlook.com",
      "selectorId": "550",
      "subCategory": "21d2cdac-b44c-4cab-a279-af3ff88ed550",
      "trafficClass": "BULK_DATA",
      "tunnel": "false"
    }
  ],
  "networkIdentity": [
    {
      "id": "39c6c313-a838-439a-a258-a89480e168b0",
      "instanceId": 14457,
      "authEntityId": 14457,
      "displayName": "14457",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "53,5353",
      "protocol": "UDP",
      "upperPort": 0
    },
    {
      "id": "8fa2356d-5534-45a6-873b-4811ac114509",
      "instanceId": 14458,
      "authEntityId": 14458,
      "displayName": "14458",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "53,80,443,5353",
      "protocol": "TCP",
      "upperPort": 0
    }
  ],
  "parentScalableGroup": {
    "idRef": "8a7ca43a-32bd-40d2-871a-02220a165cb7"
  },
  "user": [

  ],
  "userGroup": [

  ]
},
{
  "id": "ac1125f8-44ed-4835-bb7a-3d2069cbcccd",
  "instanceId": 4507,
  "authEntityId": 4507,
  "displayName": "4507",
  "authEntityClass": -1909073334,
  "deployPending": "NONE",
  "instanceVersion": 0,
  "createTime": 1517966742840,
  "deployed": false,
  "isSeeded": true,
  "isStale": false,
  "lastUpdateTime": 1517966742840,
  "name": "graphics",
  "namespace": "scalablegroup:application",
  "provisioningState": "DEFINED",
  "qualifier": "application",
  "resourceVersion": 0,
  "targetIdList": [

  ],
  "type": "scalablegroup",
  "cfsChangeInfo": [

  ],
  "customProvisions": [

  ],
  "scalableGroupExternalHandle": "graphics",
  "scalableGroupType": "APPLICATION",
  "securityGroupTag": 0,
  "state": "ACTIVE",
  "vnAgnostic": true,
  "identitySource": {
    "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
    "instanceId": 10010,
    "authEntityId": 10010,
    "displayName": "10010",
    "authEntityClass": 1738953278,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "state": "INACTIVE",
    "type": "NBAR"
  },
  "indicativeNetworkIdentity": [
    {
      "id": "de7ee766-e09d-48bb-9975-706098dff7a6",
      "instanceId": 13257,
      "authEntityId": 13257,
      "displayName": "13257",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "41",
      "protocol": "UDP",
      "upperPort": 0
    },
    {
      "id": "8838e7c2-56dc-4f58-8a58-5783eda600b5",
      "instanceId": 13258,
      "authEntityId": 13258,
      "displayName": "13258",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "41",
      "protocol": "TCP",
      "upperPort": 0
    }
  ],
  "networkApplications": [
    {
      "id": "334c9fd5-fc8c-45e9-a1e7-02ee6ade3192",
      "instanceId": 11458,
      "authEntityId": 11458,
      "displayName": "11458",
      "authEntityClass": -217092956,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "appProtocol": "tcp/udp",
      "applicationGroup": "other",
      "applicationType": "DEFAULT",
      "categoryId": "6daa3dfd-3526-407a-88f0-97824b3f3a24",
      "createTime": 1517966742840,
      "encrypted": "false",
      "engineId": "3",
      "helpString": "Graphics",
      "ignoreConflict": false,
      "lastUpdateTime": 1517966742840,
      "longDescription": "Registered with IANA on port 41 TCP/UDP",
      "name": "graphics",
      "nbarId": "921",
      "p2pTechnology": "false",
      "popularity": 1,
      "rank": 65535,
      "references": "http://www.iana.org/assignments/service-names-port-numbers/service-names-port-numbers.xml",
      "selectorId": "41",
      "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
      "trafficClass": "BULK_DATA",
      "tunnel": "false"
    }
  ],
  "networkIdentity": [
    {
      "id": "0f9762ce-5ed4-4c3d-9325-4854f6468006",
      "instanceId": 13255,
      "authEntityId": 13255,
      "displayName": "13255",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "41",
      "protocol": "TCP",
      "upperPort": 0
    },
    {
      "id": "4e6ebc0a-a4bf-4de0-bf16-4c19c99f182c",
      "instanceId": 13256,
      "authEntityId": 13256,
      "displayName": "13256",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "41",
      "protocol": "UDP",
      "upperPort": 0
    }
  ],
  "parentScalableGroup": {
    "idRef": "000c15bf-28fe-4fbb-9b69-35e6502721f1"
  },
  "user": [

  ],
  "userGroup": [

  ]
},
{
  "id": "ac207d0e-8106-47d1-bed6-b4622d195fa1",
  "instanceId": 4129,
  "authEntityId": 4129,
  "displayName": "4129",
  "authEntityClass": -1909073334,
  "deployPending": "NONE",
  "instanceVersion": 0,
  "createTime": 1517966741268,
  "deployed": false,
  "isSeeded": true,
  "isStale": false,
  "lastUpdateTime": 1517966741268,
  "name": "at-3",
  "namespace": "scalablegroup:application",
  "provisioningState": "DEFINED",
  "qualifier": "application",
  "resourceVersion": 0,
  "targetIdList": [

  ],
  "type": "scalablegroup",
  "cfsChangeInfo": [

  ],
  "customProvisions": [

  ],
  "scalableGroupExternalHandle": "at-3",
  "scalableGroupType": "APPLICATION",
  "securityGroupTag": 0,
  "state": "ACTIVE",
  "vnAgnostic": true,
  "identitySource": {
    "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
    "instanceId": 10010,
    "authEntityId": 10010,
    "displayName": "10010",
    "authEntityClass": 1738953278,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "state": "INACTIVE",
    "type": "NBAR"
  },
  "indicativeNetworkIdentity": [
    {
      "id": "9f018126-d994-402c-abd7-a96d397776be",
      "instanceId": 12245,
      "authEntityId": 12245,
      "displayName": "12245",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "203",
      "protocol": "TCP",
      "upperPort": 0
    },
    {
      "id": "a48c3e17-c22a-4384-9447-30595e41bc64",
      "instanceId": 12246,
      "authEntityId": 12246,
      "displayName": "12246",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "203",
      "protocol": "UDP",
      "upperPort": 0
    }
  ],
  "networkApplications": [
    {
      "id": "4c6f2e20-4d7d-4082-9b90-13fc2f49ac9b",
      "instanceId": 11091,
      "authEntityId": 11091,
      "displayName": "11091",
      "authEntityClass": -217092956,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "appProtocol": "tcp/udp",
      "applicationGroup": "apple-talk-group",
      "applicationType": "DEFAULT",
      "categoryId": "70fce3cd-f2bd-48de-9020-7b7840b9e7a1",
      "createTime": 1517966741268,
      "encrypted": "false",
      "engineId": "3",
      "helpString": "AppleTalk Unused",
      "ignoreConflict": false,
      "lastUpdateTime": 1517966741268,
      "longDescription": "Registered with IANA on port 203 TCP/UDP",
      "name": "at-3",
      "nbarId": "1101",
      "p2pTechnology": "false",
      "popularity": 1,
      "rank": 65535,
      "references": "http://www.iana.org/assignments/service-names-port-numbers/service-names-port-numbers.xml",
      "selectorId": "203",
      "subCategory": "f3c0f761-d894-4112-ab89-b90fc84fd660",
      "trafficClass": "OPS_ADMIN_MGMT",
      "tunnel": "false"
    }
  ],
  "networkIdentity": [
    {
      "id": "2c791d5c-af88-4dfc-a6f8-3d36c4e642f2",
      "instanceId": 12243,
      "authEntityId": 12243,
      "displayName": "12243",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "203",
      "protocol": "TCP",
      "upperPort": 0
    },
    {
      "id": "13ef6e55-b9a3-4b43-af9f-2a672aa2d045",
      "instanceId": 12244,
      "authEntityId": 12244,
      "displayName": "12244",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "203",
      "protocol": "UDP",
      "upperPort": 0
    }
  ],
  "parentScalableGroup": {
    "idRef": "000c15bf-28fe-4fbb-9b69-35e6502721f1"
  },
  "user": [

  ],
  "userGroup": [

  ]
},
{
  "id": "ac36c526-9b35-47e6-b2a1-6590950a41c1",
  "instanceId": 4468,
  "authEntityId": 4468,
  "displayName": "4468",
  "authEntityClass": -1909073334,
  "deployPending": "NONE",
  "instanceVersion": 0,
  "createTime": 1517966742746,
  "deployed": false,
  "isSeeded": true,
  "isStale": false,
  "lastUpdateTime": 1517966742746,
  "name": "ftp-agent",
  "namespace": "scalablegroup:application",
  "provisioningState": "DEFINED",
  "qualifier": "application",
  "resourceVersion": 0,
  "targetIdList": [

  ],
  "type": "scalablegroup",
  "cfsChangeInfo": [

  ],
  "customProvisions": [

  ],
  "scalableGroupExternalHandle": "ftp-agent",
  "scalableGroupType": "APPLICATION",
  "securityGroupTag": 0,
  "state": "ACTIVE",
  "vnAgnostic": true,
  "identitySource": {
    "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
    "instanceId": 10010,
    "authEntityId": 10010,
    "displayName": "10010",
    "authEntityClass": 1738953278,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "state": "INACTIVE",
    "type": "NBAR"
  },
  "indicativeNetworkIdentity": [
    {
      "id": "9d05a323-7100-4c0c-8440-00deeec9f55c",
      "instanceId": 13159,
      "authEntityId": 13159,
      "displayName": "13159",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "574",
      "protocol": "TCP",
      "upperPort": 0
    },
    {
      "id": "0a0f2e4b-22ae-4060-8ab8-aaacfcc3607d",
      "instanceId": 13160,
      "authEntityId": 13160,
      "displayName": "13160",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "574",
      "protocol": "UDP",
      "upperPort": 0
    }
  ],
  "networkApplications": [
    {
      "id": "fa722a61-7c32-4f84-9f43-cb203a41c054",
      "instanceId": 11419,
      "authEntityId": 11419,
      "displayName": "11419",
      "authEntityClass": -217092956,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "appProtocol": "tcp/udp",
      "applicationGroup": "ftp-group",
      "applicationType": "DEFAULT",
      "categoryId": "e6619ce8-97de-4c63-a53a-cceddbddc1a8",
      "createTime": 1517966742746,
      "encrypted": "false",
      "engineId": "3",
      "helpString": "FTP Software Agent System",
      "ignoreConflict": false,
      "lastUpdateTime": 1517966742746,
      "longDescription": "Registered with IANA on port 574 TCP/UDP",
      "name": "ftp-agent",
      "nbarId": "488",
      "p2pTechnology": "false",
      "popularity": 1,
      "rank": 65535,
      "references": "http://www.iana.org/assignments/service-names-port-numbers/service-names-port-numbers.xml",
      "selectorId": "574",
      "subCategory": "50b55e2e-d0d0-4404-bea4-819351565d90",
      "trafficClass": "BULK_DATA",
      "tunnel": "false"
    }
  ],
  "networkIdentity": [
    {
      "id": "ac53ddda-107c-4d63-8ac8-c6ea28ad39dd",
      "instanceId": 13157,
      "authEntityId": 13157,
      "displayName": "13157",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "574",
      "protocol": "UDP",
      "upperPort": 0
    },
    {
      "id": "6ec2ce70-fc24-4d35-a0ce-bb9b6942f38d",
      "instanceId": 13158,
      "authEntityId": 13158,
      "displayName": "13158",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "574",
      "protocol": "TCP",
      "upperPort": 0
    }
  ],
  "parentScalableGroup": {
    "idRef": "383363b1-4dd5-49d1-bfe4-34daac437cfb"
  },
  "user": [

  ],
  "userGroup": [

  ]
},
{
  "id": "ac56145f-59d3-4172-8865-d67e3832d4fd",
  "instanceId": 4622,
  "authEntityId": 4622,
  "displayName": "4622",
  "authEntityClass": -1909073334,
  "deployPending": "NONE",
  "instanceVersion": 0,
  "createTime": 1517966743146,
  "deployed": false,
  "isSeeded": true,
  "isStale": false,
  "lastUpdateTime": 1517966743146,
  "name": "ipfix",
  "namespace": "scalablegroup:application",
  "provisioningState": "DEFINED",
  "qualifier": "application",
  "resourceVersion": 0,
  "targetIdList": [

  ],
  "type": "scalablegroup",
  "cfsChangeInfo": [

  ],
  "customProvisions": [

  ],
  "scalableGroupExternalHandle": "ipfix",
  "scalableGroupType": "APPLICATION",
  "securityGroupTag": 0,
  "state": "ACTIVE",
  "vnAgnostic": true,
  "identitySource": {
    "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
    "instanceId": 10010,
    "authEntityId": 10010,
    "displayName": "10010",
    "authEntityClass": 1738953278,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "state": "INACTIVE",
    "type": "NBAR"
  },
  "indicativeNetworkIdentity": [

  ],
  "networkApplications": [
    {
      "id": "c39faead-bad7-4488-9e72-a7f11ef34683",
      "instanceId": 11572,
      "authEntityId": 11572,
      "displayName": "11572",
      "authEntityClass": -217092956,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "applicationGroup": "other",
      "applicationType": "DEFAULT",
      "categoryId": "1fc17362-9334-4c7b-b752-5afdbb574017",
      "createTime": 1517966743146,
      "encrypted": "false",
      "engineId": "13",
      "helpString": "A network traffic monitoring and IP information collection protocol",
      "ignoreConflict": false,
      "lastUpdateTime": 1517966743146,
      "longDescription": "IPFIX is the 10th version of the protocol Netflow, which is a network protocol developed by Cisco Systems for collecting IP traffic information and monitoring network traffic. The 9th version of this protocol is classified as Netflow.",
      "name": "ipfix",
      "nbarId": "1545",
      "p2pTechnology": "false",
      "popularity": 8,
      "rank": 65535,
      "references": "http://www.oiseyer.com/index-e.php",
      "selectorId": "606",
      "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
      "trafficClass": "OPS_ADMIN_MGMT",
      "tunnel": "false"
    }
  ],
  "networkIdentity": [

  ],
  "parentScalableGroup": {
    "idRef": "5fcd74d4-8726-47f7-a088-c457863da7ef"
  },
  "user": [

  ],
  "userGroup": [

  ]
},
{
  "id": "ac56235d-42f8-4245-8478-2b8d69bfa119",
  "instanceId": 4280,
  "authEntityId": 4280,
  "displayName": "4280",
  "authEntityClass": -1909073334,
  "deployPending": "NONE",
  "instanceVersion": 0,
  "createTime": 1517966742125,
  "deployed": false,
  "isSeeded": true,
  "isStale": false,
  "lastUpdateTime": 1517966742125,
  "name": "conference",
  "namespace": "scalablegroup:application",
  "provisioningState": "DEFINED",
  "qualifier": "application",
  "resourceVersion": 0,
  "targetIdList": [

  ],
  "type": "scalablegroup",
  "cfsChangeInfo": [

  ],
  "customProvisions": [

  ],
  "scalableGroupExternalHandle": "conference",
  "scalableGroupType": "APPLICATION",
  "securityGroupTag": 0,
  "state": "ACTIVE",
  "vnAgnostic": true,
  "identitySource": {
    "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
    "instanceId": 10010,
    "authEntityId": 10010,
    "displayName": "10010",
    "authEntityClass": 1738953278,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "state": "INACTIVE",
    "type": "NBAR"
  },
  "indicativeNetworkIdentity": [
    {
      "id": "9c78818a-b524-4a45-b65b-791c77de7c5e",
      "instanceId": 12618,
      "authEntityId": 12618,
      "displayName": "12618",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "531",
      "protocol": "TCP",
      "upperPort": 0
    },
    {
      "id": "c23dde09-0c72-4d27-a048-5dbe9d00dcf3",
      "instanceId": 12619,
      "authEntityId": 12619,
      "displayName": "12619",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "531",
      "protocol": "UDP",
      "upperPort": 0
    }
  ],
  "networkApplications": [
    {
      "id": "da025de9-35d7-49ef-afb9-a42633fa699d",
      "instanceId": 11233,
      "authEntityId": 11233,
      "displayName": "11233",
      "authEntityClass": -217092956,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "appProtocol": "tcp/udp",
      "applicationGroup": "other",
      "applicationType": "DEFAULT",
      "categoryId": "0f6cab47-be95-4124-9db3-360ccd55f983",
      "createTime": 1517966742125,
      "encrypted": "false",
      "engineId": "3",
      "helpString": "chat",
      "ignoreConflict": false,
      "lastUpdateTime": 1517966742125,
      "longDescription": "Registered with IANA on port 531 TCP/UDP",
      "name": "conference",
      "nbarId": "449",
      "p2pTechnology": "false",
      "popularity": 1,
      "rank": 65535,
      "references": "http://www.iana.org/assignments/service-names-port-numbers/service-names-port-numbers.xml",
      "selectorId": "531",
      "subCategory": "118c10a2-e272-47c1-92b9-4814ebe97138",
      "trafficClass": "TRANSACTIONAL_DATA",
      "tunnel": "false"
    }
  ],
  "networkIdentity": [
    {
      "id": "cf31e79a-9ae4-4039-b912-2d9cbb590668",
      "instanceId": 12616,
      "authEntityId": 12616,
      "displayName": "12616",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "531",
      "protocol": "TCP",
      "upperPort": 0
    },
    {
      "id": "ff55986c-561d-4a6c-a75c-25c5757f1421",
      "instanceId": 12617,
      "authEntityId": 12617,
      "displayName": "12617",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "531",
      "protocol": "UDP",
      "upperPort": 0
    }
  ],
  "parentScalableGroup": {
    "idRef": "fd6264c9-c16a-4311-a335-a3db039256f3"
  },
  "user": [

  ],
  "userGroup": [

  ]
},
{
  "id": "ac903006-f10e-44a3-8589-ba51496d146a",
  "instanceId": 15315,
  "authEntityId": 15315,
  "displayName": "15315",
  "authEntityClass": -1909073334,
  "deployPending": "NONE",
  "instanceVersion": 0,
  "createTime": 1517966744724,
  "deployed": false,
  "isSeeded": true,
  "isStale": false,
  "lastUpdateTime": 1517966744724,
  "name": "uarps",
  "namespace": "scalablegroup:application",
  "provisioningState": "DEFINED",
  "qualifier": "application",
  "resourceVersion": 0,
  "targetIdList": [

  ],
  "type": "scalablegroup",
  "cfsChangeInfo": [

  ],
  "customProvisions": [

  ],
  "scalableGroupExternalHandle": "uarps",
  "scalableGroupType": "APPLICATION",
  "securityGroupTag": 0,
  "state": "ACTIVE",
  "vnAgnostic": true,
  "identitySource": {
    "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
    "instanceId": 10010,
    "authEntityId": 10010,
    "displayName": "10010",
    "authEntityClass": 1738953278,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "state": "INACTIVE",
    "type": "NBAR"
  },
  "indicativeNetworkIdentity": [
    {
      "id": "ce650e12-9aae-49f1-8d9a-09a90fdc17c2",
      "instanceId": 17563,
      "authEntityId": 17563,
      "displayName": "17563",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "219",
      "protocol": "UDP",
      "upperPort": 0
    },
    {
      "id": "c1c797ee-a713-402e-9ab3-04daefcef807",
      "instanceId": 17564,
      "authEntityId": 17564,
      "displayName": "17564",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "219",
      "protocol": "TCP",
      "upperPort": 0
    }
  ],
  "networkApplications": [
    {
      "id": "450998de-3cad-4b10-a23f-c31fa13f146a",
      "instanceId": 16258,
      "authEntityId": 16258,
      "displayName": "16258",
      "authEntityClass": -217092956,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "appProtocol": "tcp/udp",
      "applicationGroup": "other",
      "applicationType": "DEFAULT",
      "categoryId": "6daa3dfd-3526-407a-88f0-97824b3f3a24",
      "createTime": 1517966744724,
      "encrypted": "false",
      "engineId": "3",
      "helpString": "Unisys ARPs",
      "ignoreConflict": false,
      "lastUpdateTime": 1517966744724,
      "longDescription": "Registered with IANA on port 219 TCP/UDP",
      "name": "uarps",
      "nbarId": "1116",
      "p2pTechnology": "false",
      "popularity": 1,
      "rank": 65535,
      "references": "http://www.iana.org/assignments/service-names-port-numbers/service-names-port-numbers.xml",
      "selectorId": "219",
      "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
      "trafficClass": "BULK_DATA",
      "tunnel": "false"
    }
  ],
  "networkIdentity": [
    {
      "id": "8eabdda5-bf0d-428d-a98d-5592dd83c923",
      "instanceId": 17561,
      "authEntityId": 17561,
      "displayName": "17561",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "219",
      "protocol": "TCP",
      "upperPort": 0
    },
    {
      "id": "4dbb8cf3-51d3-41b5-abd9-9e2670389431",
      "instanceId": 17562,
      "authEntityId": 17562,
      "displayName": "17562",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "219",
      "protocol": "UDP",
      "upperPort": 0
    }
  ],
  "parentScalableGroup": {
    "idRef": "000c15bf-28fe-4fbb-9b69-35e6502721f1"
  },
  "user": [

  ],
  "userGroup": [

  ]
},
{
  "id": "acd0b223-083a-4b6b-89e0-a8ae05a72628",
  "instanceId": 4936,
  "authEntityId": 4936,
  "displayName": "4936",
  "authEntityClass": -1909073334,
  "deployPending": "NONE",
  "instanceVersion": 0,
  "createTime": 1517966743847,
  "deployed": false,
  "isSeeded": true,
  "isStale": false,
  "lastUpdateTime": 1517966743847,
  "name": "passgo",
  "namespace": "scalablegroup:application",
  "provisioningState": "DEFINED",
  "qualifier": "application",
  "resourceVersion": 0,
  "targetIdList": [

  ],
  "type": "scalablegroup",
  "cfsChangeInfo": [

  ],
  "customProvisions": [

  ],
  "scalableGroupExternalHandle": "passgo",
  "scalableGroupType": "APPLICATION",
  "securityGroupTag": 0,
  "state": "ACTIVE",
  "vnAgnostic": true,
  "identitySource": {
    "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
    "instanceId": 10010,
    "authEntityId": 10010,
    "displayName": "10010",
    "authEntityClass": 1738953278,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "state": "INACTIVE",
    "type": "NBAR"
  },
  "indicativeNetworkIdentity": [
    {
      "id": "c55ca119-6314-47ac-a510-3808105cd2aa",
      "instanceId": 14473,
      "authEntityId": 14473,
      "displayName": "14473",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "511",
      "protocol": "UDP",
      "upperPort": 0
    },
    {
      "id": "00b50a8b-c52e-41ca-a345-a0d9b078ce57",
      "instanceId": 14474,
      "authEntityId": 14474,
      "displayName": "14474",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "511",
      "protocol": "TCP",
      "upperPort": 0
    }
  ],
  "networkApplications": [
    {
      "id": "15ad3eed-614c-4d0f-883e-9dab7399b701",
      "instanceId": 11885,
      "authEntityId": 11885,
      "displayName": "11885",
      "authEntityClass": -217092956,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "appProtocol": "tcp/udp",
      "applicationGroup": "other",
      "applicationType": "DEFAULT",
      "categoryId": "1fc17362-9334-4c7b-b752-5afdbb574017",
      "createTime": 1517966743847,
      "encrypted": "false",
      "engineId": "3",
      "helpString": "passgo",
      "ignoreConflict": false,
      "lastUpdateTime": 1517966743847,
      "longDescription": "PassGo Technologies Service is a service used by PassGo. PassGo is a product manufactured by PassGo Technologies, a company that developed software for web access management, privilege management and one-time password token products. In 2008 they were aquired by Quest Software Inc.",
      "name": "passgo",
      "nbarId": "425",
      "p2pTechnology": "false",
      "popularity": 1,
      "rank": 65535,
      "references": "http://www.quest.com/",
      "selectorId": "511",
      "subCategory": "e390124a-0fd6-4253-b68e-4b0c1af8cb4b",
      "trafficClass": "OPS_ADMIN_MGMT",
      "tunnel": "false"
    }
  ],
  "networkIdentity": [
    {
      "id": "f8c5ae9b-a8e7-4409-b587-3c1a2fefa1ce",
      "instanceId": 14471,
      "authEntityId": 14471,
      "displayName": "14471",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "511",
      "protocol": "UDP",
      "upperPort": 0
    },
    {
      "id": "c7382dac-0d03-4585-8105-1b90e8ad5818",
      "instanceId": 14472,
      "authEntityId": 14472,
      "displayName": "14472",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "511",
      "protocol": "TCP",
      "upperPort": 0
    }
  ],
  "parentScalableGroup": {
    "idRef": "7788bf2d-e289-4597-b500-17aacb7e820e"
  },
  "user": [

  ],
  "userGroup": [

  ]
},
{
  "id": "acea28e1-46aa-4a1a-bd48-1272d5ca3d07",
  "instanceId": 4862,
  "authEntityId": 4862,
  "displayName": "4862",
  "authEntityClass": -1909073334,
  "deployPending": "NONE",
  "instanceVersion": 0,
  "createTime": 1517966743636,
  "deployed": false,
  "isSeeded": true,
  "isStale": false,
  "lastUpdateTime": 1517966743636,
  "name": "nhl-com",
  "namespace": "scalablegroup:application",
  "provisioningState": "DEFINED",
  "qualifier": "application",
  "resourceVersion": 0,
  "targetIdList": [

  ],
  "type": "scalablegroup",
  "cfsChangeInfo": [

  ],
  "customProvisions": [

  ],
  "scalableGroupExternalHandle": "nhl-com",
  "scalableGroupType": "APPLICATION",
  "securityGroupTag": 0,
  "state": "ACTIVE",
  "vnAgnostic": true,
  "identitySource": {
    "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
    "instanceId": 10010,
    "authEntityId": 10010,
    "displayName": "10010",
    "authEntityClass": 1738953278,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "state": "INACTIVE",
    "type": "NBAR"
  },
  "indicativeNetworkIdentity": [

  ],
  "networkApplications": [
    {
      "id": "5f9f269d-1411-4c30-9ee3-2042eabcb210",
      "instanceId": 11811,
      "authEntityId": 11811,
      "displayName": "11811",
      "authEntityClass": -217092956,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "appProtocol": "tcp/udp",
      "applicationGroup": "other",
      "applicationType": "DEFAULT",
      "categoryId": "878644b5-fe91-45b4-9204-31c84a73ba48",
      "createTime": 1517966743636,
      "encrypted": "false",
      "engineId": "13",
      "helpString": "NHL.com - The National Hockey League official website.",
      "ignoreConflict": false,
      "lastUpdateTime": 1517966743636,
      "longDescription": "NHL.com - The National Hockey League official website.",
      "name": "nhl-com",
      "nbarId": "1691",
      "p2pTechnology": "false",
      "popularity": 3,
      "rank": 65535,
      "references": "http://nhl.com",
      "selectorId": "761",
      "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
      "trafficClass": "TRANSACTIONAL_DATA",
      "tunnel": "false"
    }
  ],
  "networkIdentity": [
    {
      "id": "a588b3d6-894b-4c69-a12d-88cf15c0aab7",
      "instanceId": 14226,
      "authEntityId": 14226,
      "displayName": "14226",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "53,80,443,5353",
      "protocol": "TCP",
      "upperPort": 0
    },
    {
      "id": "a654c6cf-0fc5-4b6a-b0ad-f61feaf82122",
      "instanceId": 14227,
      "authEntityId": 14227,
      "displayName": "14227",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "53,5353",
      "protocol": "UDP",
      "upperPort": 0
    }
  ],
  "parentScalableGroup": {
    "idRef": "28e08de4-d7b2-4521-84ce-bc1e5ee0b9cc"
  },
  "user": [

  ],
  "userGroup": [

  ]
},
{
  "id": "ad99e3ff-0dd4-4478-8015-5c41ba5e6d82",
  "instanceId": 15420,
  "authEntityId": 15420,
  "displayName": "15420",
  "authEntityClass": -1909073334,
  "deployPending": "NONE",
  "instanceVersion": 0,
  "createTime": 1517966744934,
  "deployed": false,
  "isSeeded": true,
  "isStale": false,
  "lastUpdateTime": 1517966744934,
  "name": "winmx",
  "namespace": "scalablegroup:application",
  "provisioningState": "DEFINED",
  "qualifier": "application",
  "resourceVersion": 0,
  "targetIdList": [

  ],
  "type": "scalablegroup",
  "cfsChangeInfo": [

  ],
  "customProvisions": [

  ],
  "scalableGroupExternalHandle": "winmx",
  "scalableGroupType": "APPLICATION",
  "securityGroupTag": 0,
  "state": "ACTIVE",
  "vnAgnostic": true,
  "identitySource": {
    "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
    "instanceId": 10010,
    "authEntityId": 10010,
    "displayName": "10010",
    "authEntityClass": 1738953278,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "state": "INACTIVE",
    "type": "NBAR"
  },
  "indicativeNetworkIdentity": [

  ],
  "networkApplications": [
    {
      "id": "23359708-8867-4a61-9433-9af60c8d1335",
      "instanceId": 16363,
      "authEntityId": 16363,
      "displayName": "16363",
      "authEntityClass": -217092956,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "applicationGroup": "other",
      "applicationType": "DEFAULT",
      "categoryId": "b33b2761-d981-4734-810a-a6a1c18ab352",
      "createTime": 1517966744934,
      "encrypted": "false",
      "engineId": "13",
      "helpString": "WinMx file-sharing application",
      "ignoreConflict": false,
      "lastUpdateTime": 1517966744934,
      "longDescription": "WinMX is a freeware peer-to-peer file sharing client developed by Frontcode Technologies in 2001. It runs on Windows OS.",
      "name": "winmx",
      "nbarId": "68",
      "p2pTechnology": "true",
      "popularity": 6,
      "rank": 65535,
      "references": "http://en.wikipedia.org/wiki/WinMX",
      "selectorId": "68",
      "subCategory": "79fe7198-9ce3-4c01-9b58-45a8df3d9513",
      "trafficClass": "BULK_DATA",
      "tunnel": "false"
    }
  ],
  "networkIdentity": [

  ],
  "parentScalableGroup": {
    "idRef": "7a1a3bbb-dc84-4a35-82a4-7e650c4c4761"
  },
  "user": [

  ],
  "userGroup": [

  ]
},
{
  "id": "adc6dda1-3afa-4cae-8155-70b62c886c9b",
  "instanceId": 4228,
  "authEntityId": 4228,
  "displayName": "4228",
  "authEntityClass": -1909073334,
  "deployPending": "NONE",
  "instanceVersion": 0,
  "createTime": 1517966741846,
  "deployed": false,
  "isSeeded": true,
  "isStale": false,
  "lastUpdateTime": 1517966741846,
  "name": "cimplex",
  "namespace": "scalablegroup:application",
  "provisioningState": "DEFINED",
  "qualifier": "application",
  "resourceVersion": 0,
  "targetIdList": [

  ],
  "type": "scalablegroup",
  "cfsChangeInfo": [

  ],
  "customProvisions": [

  ],
  "scalableGroupExternalHandle": "cimplex",
  "scalableGroupType": "APPLICATION",
  "securityGroupTag": 0,
  "state": "ACTIVE",
  "vnAgnostic": true,
  "identitySource": {
    "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
    "instanceId": 10010,
    "authEntityId": 10010,
    "displayName": "10010",
    "authEntityClass": 1738953278,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "state": "INACTIVE",
    "type": "NBAR"
  },
  "indicativeNetworkIdentity": [
    {
      "id": "b43ee4e4-f35b-4a57-aecb-0b1f8a65f1c3",
      "instanceId": 12503,
      "authEntityId": 12503,
      "displayName": "12503",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "673",
      "protocol": "UDP",
      "upperPort": 0
    },
    {
      "id": "8e052bf5-2013-4775-af3c-e194ddd7814d",
      "instanceId": 12504,
      "authEntityId": 12504,
      "displayName": "12504",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "673",
      "protocol": "TCP",
      "upperPort": 0
    }
  ],
  "networkApplications": [
    {
      "id": "bc25553a-feca-498a-be5a-dd7f8dd18df9",
      "instanceId": 11183,
      "authEntityId": 11183,
      "displayName": "11183",
      "authEntityClass": -217092956,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "appProtocol": "tcp/udp",
      "applicationGroup": "other",
      "applicationType": "DEFAULT",
      "categoryId": "6daa3dfd-3526-407a-88f0-97824b3f3a24",
      "createTime": 1517966741846,
      "encrypted": "false",
      "engineId": "3",
      "helpString": "cimplex",
      "ignoreConflict": false,
      "lastUpdateTime": 1517966741846,
      "longDescription": "Registered with IANA on port 673 TCP/UDP",
      "name": "cimplex",
      "nbarId": "581",
      "p2pTechnology": "false",
      "popularity": 1,
      "rank": 65535,
      "references": "http://www.iana.org/assignments/service-names-port-numbers/service-names-port-numbers.xml",
      "selectorId": "673",
      "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
      "trafficClass": "BULK_DATA",
      "tunnel": "false"
    }
  ],
  "networkIdentity": [
    {
      "id": "a73c9f45-c6a1-4df3-a8c0-bc380be35016",
      "instanceId": 12501,
      "authEntityId": 12501,
      "displayName": "12501",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "673",
      "protocol": "UDP",
      "upperPort": 0
    },
    {
      "id": "e48c66c9-ba75-46df-a021-26a49b4c07a7",
      "instanceId": 12502,
      "authEntityId": 12502,
      "displayName": "12502",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "673",
      "protocol": "TCP",
      "upperPort": 0
    }
  ],
  "parentScalableGroup": {
    "idRef": "000c15bf-28fe-4fbb-9b69-35e6502721f1"
  },
  "user": [

  ],
  "userGroup": [

  ]
},
{
  "id": "addd13bf-317a-4a1b-af43-75816b50d200",
  "instanceId": 4914,
  "authEntityId": 4914,
  "displayName": "4914",
  "authEntityClass": -1909073334,
  "deployPending": "NONE",
  "instanceVersion": 0,
  "createTime": 1517966743751,
  "deployed": false,
  "isSeeded": true,
  "isStale": false,
  "lastUpdateTime": 1517966743751,
  "name": "openvms-sysipc",
  "namespace": "scalablegroup:application",
  "provisioningState": "DEFINED",
  "qualifier": "application",
  "resourceVersion": 0,
  "targetIdList": [

  ],
  "type": "scalablegroup",
  "cfsChangeInfo": [

  ],
  "customProvisions": [

  ],
  "scalableGroupExternalHandle": "openvms-sysipc",
  "scalableGroupType": "APPLICATION",
  "securityGroupTag": 0,
  "state": "ACTIVE",
  "vnAgnostic": true,
  "identitySource": {
    "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
    "instanceId": 10010,
    "authEntityId": 10010,
    "displayName": "10010",
    "authEntityClass": 1738953278,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "state": "INACTIVE",
    "type": "NBAR"
  },
  "indicativeNetworkIdentity": [
    {
      "id": "ad8949d4-1c83-42a1-89f1-b5f58f001eb6",
      "instanceId": 14408,
      "authEntityId": 14408,
      "displayName": "14408",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "557",
      "protocol": "TCP",
      "upperPort": 0
    },
    {
      "id": "5c41b31e-943e-485d-be64-d60128923f80",
      "instanceId": 14409,
      "authEntityId": 14409,
      "displayName": "14409",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "557",
      "protocol": "UDP",
      "upperPort": 0
    }
  ],
  "networkApplications": [
    {
      "id": "b6000736-ad4e-46bc-9a01-868e23850726",
      "instanceId": 11863,
      "authEntityId": 11863,
      "displayName": "11863",
      "authEntityClass": -217092956,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "appProtocol": "tcp/udp",
      "applicationGroup": "other",
      "applicationType": "DEFAULT",
      "categoryId": "6daa3dfd-3526-407a-88f0-97824b3f3a24",
      "createTime": 1517966743751,
      "encrypted": "false",
      "engineId": "3",
      "helpString": "openvms-sysipc",
      "ignoreConflict": false,
      "lastUpdateTime": 1517966743751,
      "longDescription": "Registered with IANA on port 557 TCP/UDP",
      "name": "openvms-sysipc",
      "nbarId": "472",
      "p2pTechnology": "false",
      "popularity": 1,
      "rank": 65535,
      "references": "http://www.iana.org/assignments/service-names-port-numbers/service-names-port-numbers.xml",
      "selectorId": "557",
      "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
      "trafficClass": "BULK_DATA",
      "tunnel": "false"
    }
  ],
  "networkIdentity": [
    {
      "id": "e0ad39c2-ceb8-4cfa-baab-f8e1617227ec",
      "instanceId": 14406,
      "authEntityId": 14406,
      "displayName": "14406",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "557",
      "protocol": "UDP",
      "upperPort": 0
    },
    {
      "id": "a1bfda0f-601c-437e-ae42-12e9350f000b",
      "instanceId": 14407,
      "authEntityId": 14407,
      "displayName": "14407",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "557",
      "protocol": "TCP",
      "upperPort": 0
    }
  ],
  "parentScalableGroup": {
    "idRef": "000c15bf-28fe-4fbb-9b69-35e6502721f1"
  },
  "user": [

  ],
  "userGroup": [

  ]
},
{
  "id": "ade5a755-03ca-49a3-8879-37fc2a59e82c",
  "instanceId": 4484,
  "authEntityId": 4484,
  "displayName": "4484",
  "authEntityClass": -1909073334,
  "deployPending": "NONE",
  "instanceVersion": 0,
  "createTime": 1517966742786,
  "deployed": false,
  "isSeeded": true,
  "isStale": false,
  "lastUpdateTime": 1517966742786,
  "name": "gizmodo",
  "namespace": "scalablegroup:application",
  "provisioningState": "DEFINED",
  "qualifier": "application",
  "resourceVersion": 0,
  "targetIdList": [

  ],
  "type": "scalablegroup",
  "cfsChangeInfo": [

  ],
  "customProvisions": [

  ],
  "scalableGroupExternalHandle": "gizmodo",
  "scalableGroupType": "APPLICATION",
  "securityGroupTag": 0,
  "state": "ACTIVE",
  "vnAgnostic": true,
  "identitySource": {
    "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
    "instanceId": 10010,
    "authEntityId": 10010,
    "displayName": "10010",
    "authEntityClass": 1738953278,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "state": "INACTIVE",
    "type": "NBAR"
  },
  "indicativeNetworkIdentity": [

  ],
  "networkApplications": [
    {
      "id": "43ce32ef-bb36-46d3-8aae-254b4f9d8f6d",
      "instanceId": 11435,
      "authEntityId": 11435,
      "displayName": "11435",
      "authEntityClass": -217092956,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "appProtocol": "tcp/udp",
      "applicationGroup": "other",
      "applicationType": "DEFAULT",
      "categoryId": "878644b5-fe91-45b4-9204-31c84a73ba48",
      "createTime": 1517966742786,
      "encrypted": "false",
      "engineId": "13",
      "helpString": "Gizmodo - Blogs about design and technology.",
      "ignoreConflict": false,
      "lastUpdateTime": 1517966742786,
      "longDescription": "Gizmodo - Blogs about design and technology.",
      "name": "gizmodo",
      "nbarId": "1618",
      "p2pTechnology": "false",
      "popularity": 3,
      "rank": 65535,
      "references": "http://gizmodo.com",
      "selectorId": "688",
      "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
      "trafficClass": "TRANSACTIONAL_DATA",
      "tunnel": "false"
    }
  ],
  "networkIdentity": [
    {
      "id": "47d6fb01-36c0-49e4-ad65-3f35cb5e3d9c",
      "instanceId": 13209,
      "authEntityId": 13209,
      "displayName": "13209",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "53,5353",
      "protocol": "UDP",
      "upperPort": 0
    },
    {
      "id": "84f70f34-8c4f-44bd-b964-9c1b94dc8f24",
      "instanceId": 13210,
      "authEntityId": 13210,
      "displayName": "13210",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "53,80,443,5353",
      "protocol": "TCP",
      "upperPort": 0
    }
  ],
  "parentScalableGroup": {
    "idRef": "28e08de4-d7b2-4521-84ce-bc1e5ee0b9cc"
  },
  "user": [

  ],
  "userGroup": [

  ]
},
{
  "id": "adf9a131-f856-44fb-b671-b1d5e9420370",
  "instanceId": 15204,
  "authEntityId": 15204,
  "displayName": "15204",
  "authEntityClass": -1909073334,
  "deployPending": "NONE",
  "instanceVersion": 0,
  "createTime": 1517966744477,
  "deployed": false,
  "isSeeded": true,
  "isStale": false,
  "lastUpdateTime": 1517966744477,
  "name": "ss7ns",
  "namespace": "scalablegroup:application",
  "provisioningState": "DEFINED",
  "qualifier": "application",
  "resourceVersion": 0,
  "targetIdList": [

  ],
  "type": "scalablegroup",
  "cfsChangeInfo": [

  ],
  "customProvisions": [

  ],
  "scalableGroupExternalHandle": "ss7ns",
  "scalableGroupType": "APPLICATION",
  "securityGroupTag": 0,
  "state": "ACTIVE",
  "vnAgnostic": true,
  "identitySource": {
    "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
    "instanceId": 10010,
    "authEntityId": 10010,
    "displayName": "10010",
    "authEntityClass": 1738953278,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "state": "INACTIVE",
    "type": "NBAR"
  },
  "indicativeNetworkIdentity": [
    {
      "id": "a996017b-7ea0-4bc3-be75-d8fc75bf15f0",
      "instanceId": 17254,
      "authEntityId": 17254,
      "displayName": "17254",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "477",
      "protocol": "TCP",
      "upperPort": 0
    },
    {
      "id": "2a18e52d-f315-419d-81c9-11c60bdf1724",
      "instanceId": 17255,
      "authEntityId": 17255,
      "displayName": "17255",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "477",
      "protocol": "UDP",
      "upperPort": 0
    }
  ],
  "networkApplications": [
    {
      "id": "6c3bdff6-7fee-45f3-a22d-e571e5c99874",
      "instanceId": 16147,
      "authEntityId": 16147,
      "displayName": "16147",
      "authEntityClass": -217092956,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "appProtocol": "tcp/udp",
      "applicationGroup": "other",
      "applicationType": "DEFAULT",
      "categoryId": "38a8e354-e2fe-4f3c-b1c5-2bbb90fb0d99",
      "createTime": 1517966744477,
      "encrypted": "false",
      "engineId": "3",
      "helpString": "ss7ns",
      "ignoreConflict": false,
      "lastUpdateTime": 1517966744477,
      "longDescription": "Registered with IANA on port 477 TCP/UDP",
      "name": "ss7ns",
      "nbarId": "391",
      "p2pTechnology": "false",
      "popularity": 1,
      "rank": 65535,
      "references": "http://www.iana.org/assignments/service-names-port-numbers/service-names-port-numbers.xml",
      "selectorId": "477",
      "subCategory": "68c4f989-5735-4e16-8d1c-6a9d89d5df9c",
      "trafficClass": "SIGNALING",
      "tunnel": "false"
    }
  ],
  "networkIdentity": [
    {
      "id": "b5e97c46-4add-4760-83f5-2b43412fd205",
      "instanceId": 17252,
      "authEntityId": 17252,
      "displayName": "17252",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "477",
      "protocol": "UDP",
      "upperPort": 0
    },
    {
      "id": "a5b084a0-479f-44ce-9dde-98d1f855df7d",
      "instanceId": 17253,
      "authEntityId": 17253,
      "displayName": "17253",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "477",
      "protocol": "TCP",
      "upperPort": 0
    }
  ],
  "parentScalableGroup": {
    "idRef": "a3243a74-3864-4041-a927-0201030da17c"
  },
  "user": [

  ],
  "userGroup": [

  ]
},
{
  "id": "ae17a4b3-997c-403b-a748-f9414f475a64",
  "instanceId": 15392,
  "authEntityId": 15392,
  "displayName": "15392",
  "authEntityClass": -1909073334,
  "deployPending": "NONE",
  "instanceVersion": 0,
  "createTime": 1517966744862,
  "deployed": false,
  "isSeeded": true,
  "isStale": false,
  "lastUpdateTime": 1517966744862,
  "name": "wccp",
  "namespace": "scalablegroup:application",
  "provisioningState": "DEFINED",
  "qualifier": "application",
  "resourceVersion": 0,
  "targetIdList": [

  ],
  "type": "scalablegroup",
  "cfsChangeInfo": [

  ],
  "customProvisions": [

  ],
  "scalableGroupExternalHandle": "wccp",
  "scalableGroupType": "APPLICATION",
  "securityGroupTag": 0,
  "state": "ACTIVE",
  "vnAgnostic": true,
  "identitySource": {
    "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
    "instanceId": 10010,
    "authEntityId": 10010,
    "displayName": "10010",
    "authEntityClass": 1738953278,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "state": "INACTIVE",
    "type": "NBAR"
  },
  "indicativeNetworkIdentity": [
    {
      "id": "2e4a0b90-785c-45df-beaa-a78433e8ea2e",
      "instanceId": 17797,
      "authEntityId": 17797,
      "displayName": "17797",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "2048",
      "protocol": "UDP",
      "upperPort": 0
    },
    {
      "id": "a4c5d059-37c9-4c29-8398-a75b812954bd",
      "instanceId": 17798,
      "authEntityId": 17798,
      "displayName": "17798",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "2048",
      "protocol": "TCP",
      "upperPort": 0
    }
  ],
  "networkApplications": [
    {
      "id": "6e446ee4-1287-4bb3-a2e7-fff29ea4cd7c",
      "instanceId": 16335,
      "authEntityId": 16335,
      "displayName": "16335",
      "authEntityClass": -217092956,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "appProtocol": "tcp/udp",
      "applicationGroup": "other",
      "applicationType": "DEFAULT",
      "categoryId": "6daa3dfd-3526-407a-88f0-97824b3f3a24",
      "createTime": 1517966744862,
      "encrypted": "false",
      "engineId": "3",
      "helpString": "Web Cache Communication Protocol ",
      "ignoreConflict": false,
      "lastUpdateTime": 1517966744862,
      "longDescription": "Web Cache Communication Protocol (WCCP) is a routing-cache protocol developed by Cisco. The protocol localizes network traffic and \"intelligently\" distributes load across multiple network caches for maximized download performance and content availability. WCCP usually uses TCP/UDP port 2048.",
      "name": "wccp",
      "nbarId": "1396",
      "p2pTechnology": "false",
      "popularity": 7,
      "rank": 65535,
      "references": "http://www.cisco.com/en/US/docs/ios/12_0t/12_0t3/feature/guide/wccp.html",
      "selectorId": "2048",
      "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
      "trafficClass": "BULK_DATA",
      "tunnel": "false"
    }
  ],
  "networkIdentity": [
    {
      "id": "54294780-690d-458f-826d-6d1667a1999b",
      "instanceId": 17795,
      "authEntityId": 17795,
      "displayName": "17795",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "2048",
      "protocol": "UDP",
      "upperPort": 0
    },
    {
      "id": "c28e352a-af7b-41de-9ff7-dce0f7366538",
      "instanceId": 17796,
      "authEntityId": 17796,
      "displayName": "17796",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "2048",
      "protocol": "TCP",
      "upperPort": 0
    }
  ],
  "parentScalableGroup": {
    "idRef": "000c15bf-28fe-4fbb-9b69-35e6502721f1"
  },
  "user": [

  ],
  "userGroup": [

  ]
},
{
  "id": "aec31ef5-4a1a-4c4a-962c-3e9fa6308467",
  "instanceId": 15301,
  "authEntityId": 15301,
  "displayName": "15301",
  "authEntityClass": -1909073334,
  "deployPending": "NONE",
  "instanceVersion": 0,
  "createTime": 1517966744697,
  "deployed": false,
  "isSeeded": true,
  "isStale": false,
  "lastUpdateTime": 1517966744697,
  "name": "tradestation",
  "namespace": "scalablegroup:application",
  "provisioningState": "DEFINED",
  "qualifier": "application",
  "resourceVersion": 0,
  "targetIdList": [

  ],
  "type": "scalablegroup",
  "cfsChangeInfo": [

  ],
  "customProvisions": [

  ],
  "scalableGroupExternalHandle": "tradestation",
  "scalableGroupType": "APPLICATION",
  "securityGroupTag": 0,
  "state": "ACTIVE",
  "vnAgnostic": true,
  "identitySource": {
    "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
    "instanceId": 10010,
    "authEntityId": 10010,
    "displayName": "10010",
    "authEntityClass": 1738953278,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "state": "INACTIVE",
    "type": "NBAR"
  },
  "indicativeNetworkIdentity": [
    {
      "id": "48eb1931-2982-440d-9524-b27474a812a3",
      "instanceId": 17529,
      "authEntityId": 17529,
      "displayName": "17529",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "11010,11020",
      "protocol": "TCP",
      "upperPort": 0
    }
  ],
  "networkApplications": [
    {
      "id": "96931ee8-9a9c-4b58-b37c-af5f59b9ac17",
      "instanceId": 16244,
      "authEntityId": 16244,
      "displayName": "16244",
      "authEntityClass": -217092956,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "appProtocol": "tcp",
      "applicationGroup": "other",
      "applicationType": "DEFAULT",
      "categoryId": "6daa3dfd-3526-407a-88f0-97824b3f3a24",
      "createTime": 1517966744697,
      "encrypted": "false",
      "engineId": "3",
      "helpString": "Technical analysis software",
      "ignoreConflict": false,
      "lastUpdateTime": 1517966744697,
      "longDescription": "The TradeStation analysis and trading platform is a professional electronic trading platform for financial market traders.",
      "name": "tradestation",
      "nbarId": "1393",
      "p2pTechnology": "false",
      "popularity": 1,
      "rank": 65535,
      "references": "http://www.tradestation.com/",
      "selectorId": "11010",
      "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
      "trafficClass": "BULK_DATA",
      "tunnel": "false"
    }
  ],
  "networkIdentity": [
    {
      "id": "ffbb4a2e-9db9-4e70-9584-ec400b71eb87",
      "instanceId": 17528,
      "authEntityId": 17528,
      "displayName": "17528",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "11010,11020",
      "protocol": "TCP",
      "upperPort": 0
    }
  ],
  "parentScalableGroup": {
    "idRef": "000c15bf-28fe-4fbb-9b69-35e6502721f1"
  },
  "user": [

  ],
  "userGroup": [

  ]
},
{
  "id": "aecef8c4-25d1-4757-b9b3-1616349bea0b",
  "instanceId": 4762,
  "authEntityId": 4762,
  "displayName": "4762",
  "authEntityClass": -1909073334,
  "deployPending": "NONE",
  "instanceVersion": 0,
  "createTime": 1517966743442,
  "deployed": false,
  "isSeeded": true,
  "isStale": false,
  "lastUpdateTime": 1517966743442,
  "name": "mortgageware",
  "namespace": "scalablegroup:application",
  "provisioningState": "DEFINED",
  "qualifier": "application",
  "resourceVersion": 0,
  "targetIdList": [

  ],
  "type": "scalablegroup",
  "cfsChangeInfo": [

  ],
  "customProvisions": [

  ],
  "scalableGroupExternalHandle": "mortgageware",
  "scalableGroupType": "APPLICATION",
  "securityGroupTag": 0,
  "state": "ACTIVE",
  "vnAgnostic": true,
  "identitySource": {
    "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
    "instanceId": 10010,
    "authEntityId": 10010,
    "displayName": "10010",
    "authEntityClass": 1738953278,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "state": "INACTIVE",
    "type": "NBAR"
  },
  "indicativeNetworkIdentity": [
    {
      "id": "ff92234c-9b91-4c63-b363-75ec8dd4c8d9",
      "instanceId": 13946,
      "authEntityId": 13946,
      "displayName": "13946",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "367",
      "protocol": "TCP",
      "upperPort": 0
    },
    {
      "id": "2cc93ba3-f4e6-40db-bcb3-4b5392231f0e",
      "instanceId": 13947,
      "authEntityId": 13947,
      "displayName": "13947",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "367",
      "protocol": "UDP",
      "upperPort": 0
    }
  ],
  "networkApplications": [
    {
      "id": "f965b7ab-50f2-4678-ba98-33e630ab8713",
      "instanceId": 11712,
      "authEntityId": 11712,
      "displayName": "11712",
      "authEntityClass": -217092956,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "appProtocol": "tcp/udp",
      "applicationGroup": "other",
      "applicationType": "DEFAULT",
      "categoryId": "6daa3dfd-3526-407a-88f0-97824b3f3a24",
      "createTime": 1517966743442,
      "encrypted": "false",
      "engineId": "3",
      "helpString": "mortgageware",
      "ignoreConflict": false,
      "lastUpdateTime": 1517966743442,
      "longDescription": "Registered with IANA on port 367 TCP/UDP",
      "name": "mortgageware",
      "nbarId": "283",
      "p2pTechnology": "false",
      "popularity": 1,
      "rank": 65535,
      "references": "http://www.iana.org/assignments/service-names-port-numbers/service-names-port-numbers.xml",
      "selectorId": "367",
      "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
      "trafficClass": "BULK_DATA",
      "tunnel": "false"
    }
  ],
  "networkIdentity": [
    {
      "id": "df244e9a-5b1f-4ff2-8f12-6efe654bcc2f",
      "instanceId": 13944,
      "authEntityId": 13944,
      "displayName": "13944",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "367",
      "protocol": "TCP",
      "upperPort": 0
    },
    {
      "id": "7d36dd8c-9710-4e7c-8abe-32136da649d2",
      "instanceId": 13945,
      "authEntityId": 13945,
      "displayName": "13945",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "367",
      "protocol": "UDP",
      "upperPort": 0
    }
  ],
  "parentScalableGroup": {
    "idRef": "000c15bf-28fe-4fbb-9b69-35e6502721f1"
  },
  "user": [

  ],
  "userGroup": [

  ]
},
{
  "id": "aee891a2-fb42-41e3-b524-4517fd2f458b",
  "instanceId": 15052,
  "authEntityId": 15052,
  "displayName": "15052",
  "authEntityClass": -1909073334,
  "deployPending": "NONE",
  "instanceVersion": 0,
  "createTime": 1517966744122,
  "deployed": false,
  "isSeeded": true,
  "isStale": false,
  "lastUpdateTime": 1517966744122,
  "name": "rlp",
  "namespace": "scalablegroup:application",
  "provisioningState": "DEFINED",
  "qualifier": "application",
  "resourceVersion": 0,
  "targetIdList": [

  ],
  "type": "scalablegroup",
  "cfsChangeInfo": [

  ],
  "customProvisions": [

  ],
  "scalableGroupExternalHandle": "rlp",
  "scalableGroupType": "APPLICATION",
  "securityGroupTag": 0,
  "state": "ACTIVE",
  "vnAgnostic": true,
  "identitySource": {
    "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
    "instanceId": 10010,
    "authEntityId": 10010,
    "displayName": "10010",
    "authEntityClass": 1738953278,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "state": "INACTIVE",
    "type": "NBAR"
  },
  "indicativeNetworkIdentity": [
    {
      "id": "aa3affa2-beed-46ae-a6b2-42910c8e4049",
      "instanceId": 14791,
      "authEntityId": 14791,
      "displayName": "14791",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "39",
      "protocol": "TCP",
      "upperPort": 0
    },
    {
      "id": "0b881f9b-71d8-46cb-b1f6-ffd25a376ae0",
      "instanceId": 14792,
      "authEntityId": 14792,
      "displayName": "14792",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "39",
      "protocol": "UDP",
      "upperPort": 0
    }
  ],
  "networkApplications": [
    {
      "id": "c16b5ccf-b47c-4018-8b96-68ece21775b7",
      "instanceId": 11991,
      "authEntityId": 11991,
      "displayName": "11991",
      "authEntityClass": -217092956,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "appProtocol": "tcp/udp",
      "applicationGroup": "other",
      "applicationType": "DEFAULT",
      "categoryId": "1fc17362-9334-4c7b-b752-5afdbb574017",
      "createTime": 1517966744122,
      "encrypted": "false",
      "engineId": "3",
      "helpString": "Resource Location Protocol",
      "ignoreConflict": false,
      "lastUpdateTime": 1517966744122,
      "longDescription": "Resource Location Protocol (RLP) is a simple request/reply procedure used for determining the location of network services or resources.",
      "name": "rlp",
      "nbarId": "920",
      "p2pTechnology": "false",
      "popularity": 1,
      "rank": 65535,
      "references": "http://www.ietf.org/rfc/rfc887.txt",
      "selectorId": "39",
      "subCategory": "f3c0f761-d894-4112-ab89-b90fc84fd660",
      "trafficClass": "OPS_ADMIN_MGMT",
      "tunnel": "false"
    }
  ],
  "networkIdentity": [
    {
      "id": "b62190ed-8221-4742-bcf3-a3e6361fb055",
      "instanceId": 14789,
      "authEntityId": 14789,
      "displayName": "14789",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "39",
      "protocol": "TCP",
      "upperPort": 0
    },
    {
      "id": "22827010-b644-4c5d-88bb-582b095d162c",
      "instanceId": 14790,
      "authEntityId": 14790,
      "displayName": "14790",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "39",
      "protocol": "UDP",
      "upperPort": 0
    }
  ],
  "parentScalableGroup": {
    "idRef": "5fcd74d4-8726-47f7-a088-c457863da7ef"
  },
  "user": [

  ],
  "userGroup": [

  ]
},
{
  "id": "aef7caf8-ade0-4470-8d9c-6e993cc54bed",
  "instanceId": 4565,
  "authEntityId": 4565,
  "displayName": "4565",
  "authEntityClass": -1909073334,
  "deployPending": "NONE",
  "instanceVersion": 0,
  "createTime": 1517966743001,
  "deployed": false,
  "isSeeded": true,
  "isStale": false,
  "lastUpdateTime": 1517966743001,
  "name": "hulu",
  "namespace": "scalablegroup:application",
  "provisioningState": "DEFINED",
  "qualifier": "application",
  "resourceVersion": 0,
  "targetIdList": [

  ],
  "type": "scalablegroup",
  "cfsChangeInfo": [

  ],
  "customProvisions": [

  ],
  "scalableGroupExternalHandle": "hulu",
  "scalableGroupType": "APPLICATION",
  "securityGroupTag": 0,
  "state": "ACTIVE",
  "vnAgnostic": true,
  "identitySource": {
    "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
    "instanceId": 10010,
    "authEntityId": 10010,
    "displayName": "10010",
    "authEntityClass": 1738953278,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "state": "INACTIVE",
    "type": "NBAR"
  },
  "indicativeNetworkIdentity": [

  ],
  "networkApplications": [
    {
      "id": "5b2cfc3f-3f41-477b-a9cb-b9cf20b54e14",
      "instanceId": 11515,
      "authEntityId": 11515,
      "displayName": "11515",
      "authEntityClass": -217092956,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "appProtocol": "tcp/udp",
      "applicationGroup": "other",
      "applicationType": "DEFAULT",
      "categoryId": "a09ef1b3-ba26-4372-aee8-309d50697e76",
      "createTime": 1517966743001,
      "encrypted": "true",
      "engineId": "13",
      "helpString": "Hulu browsing",
      "ignoreConflict": false,
      "lastUpdateTime": 1517966743001,
      "longDescription": "Hulu is a Flash-based, ad-supported streaming video website that carries movies and TV series. ",
      "name": "hulu",
      "nbarId": "1317",
      "p2pTechnology": "false",
      "popularity": 10,
      "rank": 65535,
      "references": "http://www.hulu.com",
      "selectorId": "458",
      "subCategory": "85a797c0-aef8-4284-91a7-677f33e29abb",
      "trafficClass": "MULTIMEDIA_STREAMING",
      "tunnel": "false"
    }
  ],
  "networkIdentity": [
    {
      "id": "e0328d12-d940-4476-8c2e-d032a12d0e96",
      "instanceId": 13405,
      "authEntityId": 13405,
      "displayName": "13405",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "53,80,443,5353",
      "protocol": "TCP",
      "upperPort": 0
    },
    {
      "id": "190bf4ea-395f-4eec-b173-67dfd27aa9b7",
      "instanceId": 13406,
      "authEntityId": 13406,
      "displayName": "13406",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "53,5353",
      "protocol": "UDP",
      "upperPort": 0
    }
  ],
  "parentScalableGroup": {
    "idRef": "fd6264c9-c16a-4311-a335-a3db039256f3"
  },
  "user": [

  ],
  "userGroup": [

  ]
},
{
  "id": "af19a7cd-8d30-4495-9770-43ee8f9aeae5",
  "instanceId": 4886,
  "authEntityId": 4886,
  "displayName": "4886",
  "authEntityClass": -1909073334,
  "deployPending": "NONE",
  "instanceVersion": 0,
  "createTime": 1517966743683,
  "deployed": false,
  "isSeeded": true,
  "isStale": false,
  "lastUpdateTime": 1517966743683,
  "name": "nss-routing",
  "namespace": "scalablegroup:application",
  "provisioningState": "DEFINED",
  "qualifier": "application",
  "resourceVersion": 0,
  "targetIdList": [

  ],
  "type": "scalablegroup",
  "cfsChangeInfo": [

  ],
  "customProvisions": [

  ],
  "scalableGroupExternalHandle": "nss-routing",
  "scalableGroupType": "APPLICATION",
  "securityGroupTag": 0,
  "state": "ACTIVE",
  "vnAgnostic": true,
  "identitySource": {
    "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
    "instanceId": 10010,
    "authEntityId": 10010,
    "displayName": "10010",
    "authEntityClass": 1738953278,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "state": "INACTIVE",
    "type": "NBAR"
  },
  "indicativeNetworkIdentity": [
    {
      "id": "4cb4dd9c-26f3-44d8-950d-08e4f0b9d492",
      "instanceId": 14308,
      "authEntityId": 14308,
      "displayName": "14308",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "159",
      "protocol": "UDP",
      "upperPort": 0
    },
    {
      "id": "dd8b45eb-07ce-4338-8370-bde0f4cabbe1",
      "instanceId": 14309,
      "authEntityId": 14309,
      "displayName": "14309",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "159",
      "protocol": "TCP",
      "upperPort": 0
    }
  ],
  "networkApplications": [
    {
      "id": "220e6d9a-3d07-4c39-8487-4b64f54fe6d6",
      "instanceId": 11835,
      "authEntityId": 11835,
      "displayName": "11835",
      "authEntityClass": -217092956,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "appProtocol": "tcp/udp",
      "applicationGroup": "other",
      "applicationType": "DEFAULT",
      "categoryId": "1fc17362-9334-4c7b-b752-5afdbb574017",
      "createTime": 1517966743683,
      "encrypted": "false",
      "engineId": "3",
      "helpString": "NSS-Routing",
      "ignoreConflict": false,
      "lastUpdateTime": 1517966743683,
      "longDescription": "Registered with IANA on port 159 TCP/UDP",
      "name": "nss-routing",
      "nbarId": "1005",
      "p2pTechnology": "false",
      "popularity": 1,
      "rank": 65535,
      "references": "http://www.iana.org/assignments/service-names-port-numbers/service-names-port-numbers.xml",
      "selectorId": "159",
      "subCategory": "7cc1b3a6-4102-44e9-b522-0e71afe39a03",
      "trafficClass": "NETWORK_CONTROL",
      "tunnel": "false"
    }
  ],
  "networkIdentity": [
    {
      "id": "940f51e7-bacc-4f1e-b3a0-33dcc66167f8",
      "instanceId": 14306,
      "authEntityId": 14306,
      "displayName": "14306",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "159",
      "protocol": "TCP",
      "upperPort": 0
    },
    {
      "id": "ee759a90-58ba-45e0-b556-50443d417478",
      "instanceId": 14307,
      "authEntityId": 14307,
      "displayName": "14307",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "159",
      "protocol": "UDP",
      "upperPort": 0
    }
  ],
  "parentScalableGroup": {
    "idRef": "77e75842-e98b-4112-ad0c-300db4bb11be"
  },
  "user": [

  ],
  "userGroup": [

  ]
},
{
  "id": "af5b78b1-bca5-4583-a01c-ac4b6d569ab9",
  "instanceId": 4310,
  "authEntityId": 4310,
  "displayName": "4310",
  "authEntityClass": -1909073334,
  "deployPending": "NONE",
  "instanceVersion": 0,
  "createTime": 1517966742235,
  "deployed": false,
  "isSeeded": true,
  "isStale": false,
  "lastUpdateTime": 1517966742235,
  "name": "csnet-ns",
  "namespace": "scalablegroup:application",
  "provisioningState": "DEFINED",
  "qualifier": "application",
  "resourceVersion": 0,
  "targetIdList": [

  ],
  "type": "scalablegroup",
  "cfsChangeInfo": [

  ],
  "customProvisions": [

  ],
  "scalableGroupExternalHandle": "csnet-ns",
  "scalableGroupType": "APPLICATION",
  "securityGroupTag": 0,
  "state": "ACTIVE",
  "vnAgnostic": true,
  "identitySource": {
    "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
    "instanceId": 10010,
    "authEntityId": 10010,
    "displayName": "10010",
    "authEntityClass": 1738953278,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "state": "INACTIVE",
    "type": "NBAR"
  },
  "indicativeNetworkIdentity": [
    {
      "id": "e910bed3-f71a-4c45-a9be-7db1a2eb17a4",
      "instanceId": 12700,
      "authEntityId": 12700,
      "displayName": "12700",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "105",
      "protocol": "UDP",
      "upperPort": 0
    },
    {
      "id": "1eb28aa9-6082-4735-a908-68529f99f99c",
      "instanceId": 12701,
      "authEntityId": 12701,
      "displayName": "12701",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "105",
      "protocol": "TCP",
      "upperPort": 0
    }
  ],
  "networkApplications": [
    {
      "id": "4797bfa9-b033-416d-a3bf-f1fa6c2a94b2",
      "instanceId": 11262,
      "authEntityId": 11262,
      "displayName": "11262",
      "authEntityClass": -217092956,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "appProtocol": "tcp/udp",
      "applicationGroup": "other",
      "applicationType": "DEFAULT",
      "categoryId": "1fc17362-9334-4c7b-b752-5afdbb574017",
      "createTime": 1517966742235,
      "encrypted": "false",
      "engineId": "3",
      "helpString": "Mailbox Name Nameserver",
      "ignoreConflict": false,
      "lastUpdateTime": 1517966742235,
      "longDescription": "The Computer Science Network (CSNET) was a computer network that began operation in 1981 in the United States. Its purpose was to extend networking benefits for computer science departments at academic and research institutions that could not be directly connected to ARPANET, due to funding or authorization limitations. The CSNET name service allowed manual and automated email address lookup based on various user attributes, such as name, title, or institution.",
      "name": "csnet-ns",
      "nbarId": "976",
      "p2pTechnology": "false",
      "popularity": 1,
      "rank": 65535,
      "references": "http://www.isoc.org/internet/history/documents/Comm83.pdf",
      "selectorId": "105",
      "subCategory": "7f5fa07d-fcb4-4217-9908-b438066c0b60",
      "trafficClass": "OPS_ADMIN_MGMT",
      "tunnel": "false"
    }
  ],
  "networkIdentity": [
    {
      "id": "ff90581e-c0b2-42b1-bddf-6e497c2d5f49",
      "instanceId": 12698,
      "authEntityId": 12698,
      "displayName": "12698",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "105",
      "protocol": "UDP",
      "upperPort": 0
    },
    {
      "id": "d85aaa5f-afd6-4824-8439-3d19da27e300",
      "instanceId": 12699,
      "authEntityId": 12699,
      "displayName": "12699",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "105",
      "protocol": "TCP",
      "upperPort": 0
    }
  ],
  "parentScalableGroup": {
    "idRef": "38b9eb6b-f3b6-4e13-9b79-a11e0c6423d4"
  },
  "user": [

  ],
  "userGroup": [

  ]
},
{
  "id": "af7feb38-a6cc-4903-82fa-9675238c2d91",
  "instanceId": 15093,
  "authEntityId": 15093,
  "displayName": "15093",
  "authEntityClass": -1909073334,
  "deployPending": "NONE",
  "instanceVersion": 0,
  "createTime": 1517966744218,
  "deployed": false,
  "isSeeded": true,
  "isStale": false,
  "lastUpdateTime": 1517966744218,
  "name": "scc-security",
  "namespace": "scalablegroup:application",
  "provisioningState": "DEFINED",
  "qualifier": "application",
  "resourceVersion": 0,
  "targetIdList": [

  ],
  "type": "scalablegroup",
  "cfsChangeInfo": [

  ],
  "customProvisions": [

  ],
  "scalableGroupExternalHandle": "scc-security",
  "scalableGroupType": "APPLICATION",
  "securityGroupTag": 0,
  "state": "ACTIVE",
  "vnAgnostic": true,
  "identitySource": {
    "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
    "instanceId": 10010,
    "authEntityId": 10010,
    "displayName": "10010",
    "authEntityClass": 1738953278,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "state": "INACTIVE",
    "type": "NBAR"
  },
  "indicativeNetworkIdentity": [
    {
      "id": "bf4e9f17-bf0a-4b1b-86be-dcbf98e15456",
      "instanceId": 14914,
      "authEntityId": 14914,
      "displayName": "14914",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "582",
      "protocol": "TCP",
      "upperPort": 0
    },
    {
      "id": "f394fd59-7d91-44b6-8e83-ac3626404f83",
      "instanceId": 14915,
      "authEntityId": 14915,
      "displayName": "14915",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "582",
      "protocol": "UDP",
      "upperPort": 0
    }
  ],
  "networkApplications": [
    {
      "id": "d58e88a2-8ac3-47c9-b28d-5176ead3585d",
      "instanceId": 16036,
      "authEntityId": 16036,
      "displayName": "16036",
      "authEntityClass": -217092956,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "appProtocol": "tcp/udp",
      "applicationGroup": "other",
      "applicationType": "DEFAULT",
      "categoryId": "6daa3dfd-3526-407a-88f0-97824b3f3a24",
      "createTime": 1517966744218,
      "encrypted": "false",
      "engineId": "3",
      "helpString": "scc-security",
      "ignoreConflict": false,
      "lastUpdateTime": 1517966744218,
      "longDescription": "Registered with IANA on port 582 TCP/UDP",
      "name": "scc-security",
      "nbarId": "496",
      "p2pTechnology": "false",
      "popularity": 1,
      "rank": 65535,
      "references": "http://www.iana.org/assignments/service-names-port-numbers/service-names-port-numbers.xml",
      "selectorId": "582",
      "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
      "trafficClass": "BULK_DATA",
      "tunnel": "false"
    }
  ],
  "networkIdentity": [
    {
      "id": "6abce6bf-2539-4e32-88d1-64406cf02fa9",
      "instanceId": 14912,
      "authEntityId": 14912,
      "displayName": "14912",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "582",
      "protocol": "TCP",
      "upperPort": 0
    },
    {
      "id": "f17e0312-70ed-49aa-9c63-5a7f42006cf6",
      "instanceId": 14913,
      "authEntityId": 14913,
      "displayName": "14913",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "582",
      "protocol": "UDP",
      "upperPort": 0
    }
  ],
  "parentScalableGroup": {
    "idRef": "000c15bf-28fe-4fbb-9b69-35e6502721f1"
  },
  "user": [

  ],
  "userGroup": [

  ]
},
{
  "id": "afa814fe-625a-43ad-87e9-c5434207bae6",
  "instanceId": 4059,
  "authEntityId": 4059,
  "displayName": "4059",
  "authEntityClass": -1909073334,
  "deployPending": "NONE",
  "instanceVersion": 0,
  "createTime": 1517966740662,
  "deployed": false,
  "isSeeded": true,
  "isStale": false,
  "lastUpdateTime": 1517966740662,
  "name": "adcash",
  "namespace": "scalablegroup:application",
  "provisioningState": "DEFINED",
  "qualifier": "application",
  "resourceVersion": 0,
  "targetIdList": [

  ],
  "type": "scalablegroup",
  "cfsChangeInfo": [

  ],
  "customProvisions": [

  ],
  "scalableGroupExternalHandle": "adcash",
  "scalableGroupType": "APPLICATION",
  "securityGroupTag": 0,
  "state": "ACTIVE",
  "vnAgnostic": true,
  "identitySource": {
    "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
    "instanceId": 10010,
    "authEntityId": 10010,
    "displayName": "10010",
    "authEntityClass": 1738953278,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "state": "INACTIVE",
    "type": "NBAR"
  },
  "indicativeNetworkIdentity": [

  ],
  "networkApplications": [
    {
      "id": "a6227750-fd72-4076-a7c0-88a07bc237c9",
      "instanceId": 11030,
      "authEntityId": 11030,
      "displayName": "11030",
      "authEntityClass": -217092956,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "appProtocol": "tcp/udp",
      "applicationGroup": "other",
      "applicationType": "DEFAULT",
      "categoryId": "878644b5-fe91-45b4-9204-31c84a73ba48",
      "createTime": 1517966740662,
      "encrypted": "false",
      "engineId": "13",
      "helpString": "Adcash - Advertising network.",
      "ignoreConflict": false,
      "lastUpdateTime": 1517966740662,
      "longDescription": "Adcash - Advertising network.",
      "name": "adcash",
      "nbarId": "1064",
      "p2pTechnology": "false",
      "popularity": 3,
      "rank": 65535,
      "references": "http://adcash.com",
      "selectorId": "616",
      "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
      "trafficClass": "TRANSACTIONAL_DATA",
      "tunnel": "false"
    }
  ],
  "networkIdentity": [
    {
      "id": "f714655e-4065-47ac-bd28-1591583febce",
      "instanceId": 12073,
      "authEntityId": 12073,
      "displayName": "12073",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "53,80,443,5353",
      "protocol": "TCP",
      "upperPort": 0
    },
    {
      "id": "447f35fb-fad6-4f67-ae86-ca12a7d2b0b5",
      "instanceId": 12074,
      "authEntityId": 12074,
      "displayName": "12074",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "53,5353",
      "protocol": "UDP",
      "upperPort": 0
    }
  ],
  "parentScalableGroup": {
    "idRef": "28e08de4-d7b2-4521-84ce-bc1e5ee0b9cc"
  },
  "user": [

  ],
  "userGroup": [

  ]
},
{
  "id": "afd267b0-3962-4935-ae22-ff639a7139ab",
  "instanceId": 4430,
  "authEntityId": 4430,
  "displayName": "4430",
  "authEntityClass": -1909073334,
  "deployPending": "NONE",
  "instanceVersion": 0,
  "createTime": 1517966742634,
  "deployed": false,
  "isSeeded": true,
  "isStale": false,
  "lastUpdateTime": 1517966742634,
  "name": "european-union-portal",
  "namespace": "scalablegroup:application",
  "provisioningState": "DEFINED",
  "qualifier": "application",
  "resourceVersion": 0,
  "targetIdList": [

  ],
  "type": "scalablegroup",
  "cfsChangeInfo": [

  ],
  "customProvisions": [

  ],
  "scalableGroupExternalHandle": "european-union-portal",
  "scalableGroupType": "APPLICATION",
  "securityGroupTag": 0,
  "state": "ACTIVE",
  "vnAgnostic": true,
  "identitySource": {
    "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
    "instanceId": 10010,
    "authEntityId": 10010,
    "displayName": "10010",
    "authEntityClass": 1738953278,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "state": "INACTIVE",
    "type": "NBAR"
  },
  "indicativeNetworkIdentity": [

  ],
  "networkApplications": [
    {
      "id": "590ceb45-010a-45e8-a44b-1744802f0915",
      "instanceId": 11381,
      "authEntityId": 11381,
      "displayName": "11381",
      "authEntityClass": -217092956,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "appProtocol": "tcp/udp",
      "applicationGroup": "other",
      "applicationType": "DEFAULT",
      "categoryId": "878644b5-fe91-45b4-9204-31c84a73ba48",
      "createTime": 1517966742634,
      "encrypted": "false",
      "engineId": "13",
      "helpString": "European Union - Official website for European Union.",
      "ignoreConflict": false,
      "lastUpdateTime": 1517966742634,
      "longDescription": "European Union - Official website for European Union.",
      "name": "european-union-portal",
      "nbarId": "1712",
      "p2pTechnology": "false",
      "popularity": 3,
      "rank": 65535,
      "references": "http://europa.eu",
      "selectorId": "784",
      "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
      "trafficClass": "TRANSACTIONAL_DATA",
      "tunnel": "false"
    }
  ],
  "networkIdentity": [
    {
      "id": "f50408a3-ddcb-4fda-b574-612b3fce6477",
      "instanceId": 13085,
      "authEntityId": 13085,
      "displayName": "13085",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "53,80,443,5353",
      "protocol": "TCP",
      "upperPort": 0
    },
    {
      "id": "2adb9652-4f39-49f4-9d31-427d2ce7f8d6",
      "instanceId": 13086,
      "authEntityId": 13086,
      "displayName": "13086",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "53,5353",
      "protocol": "UDP",
      "upperPort": 0
    }
  ],
  "parentScalableGroup": {
    "idRef": "28e08de4-d7b2-4521-84ce-bc1e5ee0b9cc"
  },
  "user": [

  ],
  "userGroup": [

  ]
},
{
  "id": "afd5a572-bbdc-4df2-93ee-957b3e04ff29",
  "instanceId": 15119,
  "authEntityId": 15119,
  "displayName": "15119",
  "authEntityClass": -1909073334,
  "deployPending": "NONE",
  "instanceVersion": 0,
  "createTime": 1517966744277,
  "deployed": false,
  "isSeeded": true,
  "isStale": false,
  "lastUpdateTime": 1517966744277,
  "name": "secure-vmtp",
  "namespace": "scalablegroup:application",
  "provisioningState": "DEFINED",
  "qualifier": "application",
  "resourceVersion": 0,
  "targetIdList": [

  ],
  "type": "scalablegroup",
  "cfsChangeInfo": [

  ],
  "customProvisions": [

  ],
  "scalableGroupExternalHandle": "secure-vmtp",
  "scalableGroupType": "APPLICATION",
  "securityGroupTag": 0,
  "state": "ACTIVE",
  "vnAgnostic": true,
  "identitySource": {
    "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
    "instanceId": 10010,
    "authEntityId": 10010,
    "displayName": "10010",
    "authEntityClass": 1738953278,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "state": "INACTIVE",
    "type": "NBAR"
  },
  "indicativeNetworkIdentity": [
    {
      "id": "792efeb5-d344-4d07-9172-7c1bf74dd2ed",
      "instanceId": 14997,
      "authEntityId": 14997,
      "displayName": "14997",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipProtocolNumbers": "82",
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "protocol": "SECURE_VMTP",
      "upperPort": 0
    }
  ],
  "networkApplications": [
    {
      "id": "45067103-1c39-4282-9e6a-d561ef4af1c9",
      "instanceId": 16062,
      "authEntityId": 16062,
      "displayName": "16062",
      "authEntityClass": -217092956,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "appProtocol": "ip",
      "applicationGroup": "other",
      "applicationType": "DEFAULT",
      "categoryId": "6daa3dfd-3526-407a-88f0-97824b3f3a24",
      "createTime": 1517966744277,
      "encrypted": "unassigned",
      "engineId": "1",
      "helpString": "SECURE-VMTP",
      "ignoreConflict": false,
      "lastUpdateTime": 1517966744277,
      "longDescription": "Versatile Message Transaction Protocol (VMTP) is a transport protocol specifically designed to support the transaction model of communication, as exemplified by remote procedure call (RPC).  The full function of VMTP, including support for security, real-time, asynchronous message exchanges, streaming, multicast and idempotency, provides a rich selection to the VMTP user level.",
      "name": "secure-vmtp",
      "nbarId": "836",
      "p2pTechnology": "unassigned",
      "popularity": 2,
      "rank": 65535,
      "references": "http://tools.ietf.org/html/rfc1045",
      "selectorId": "82",
      "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
      "trafficClass": "BULK_DATA",
      "tunnel": "unassigned"
    }
  ],
  "networkIdentity": [
    {
      "id": "dc3ef0ec-b9d2-4a1d-b375-6bfef04f0f4e",
      "instanceId": 14996,
      "authEntityId": 14996,
      "displayName": "14996",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipProtocolNumbers": "82",
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "protocol": "SECURE_VMTP",
      "upperPort": 0
    }
  ],
  "parentScalableGroup": {
    "idRef": "000c15bf-28fe-4fbb-9b69-35e6502721f1"
  },
  "user": [

  ],
  "userGroup": [

  ]
},
{
  "id": "afd6cc60-0360-4d8c-b257-cf9f96a984e1",
  "instanceId": 4442,
  "authEntityId": 4442,
  "displayName": "4442",
  "authEntityClass": -1909073334,
  "deployPending": "NONE",
  "instanceVersion": 0,
  "createTime": 1517966742670,
  "deployed": false,
  "isSeeded": true,
  "isStale": false,
  "lastUpdateTime": 1517966742670,
  "name": "fatserv",
  "namespace": "scalablegroup:application",
  "provisioningState": "DEFINED",
  "qualifier": "application",
  "resourceVersion": 0,
  "targetIdList": [

  ],
  "type": "scalablegroup",
  "cfsChangeInfo": [

  ],
  "customProvisions": [

  ],
  "scalableGroupExternalHandle": "fatserv",
  "scalableGroupType": "APPLICATION",
  "securityGroupTag": 0,
  "state": "ACTIVE",
  "vnAgnostic": true,
  "identitySource": {
    "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
    "instanceId": 10010,
    "authEntityId": 10010,
    "displayName": "10010",
    "authEntityClass": 1738953278,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "state": "INACTIVE",
    "type": "NBAR"
  },
  "indicativeNetworkIdentity": [
    {
      "id": "250a4e6a-5134-4a00-bc56-8ac7fbfa00dc",
      "instanceId": 13104,
      "authEntityId": 13104,
      "displayName": "13104",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "347",
      "protocol": "UDP",
      "upperPort": 0
    },
    {
      "id": "76ca8ab5-be77-4378-b0df-f760564ed969",
      "instanceId": 13105,
      "authEntityId": 13105,
      "displayName": "13105",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "347",
      "protocol": "TCP",
      "upperPort": 0
    }
  ],
  "networkApplications": [
    {
      "id": "675c6ef2-32ac-454a-b9a6-550d1291fda7",
      "instanceId": 11393,
      "authEntityId": 11393,
      "displayName": "11393",
      "authEntityClass": -217092956,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "appProtocol": "tcp/udp",
      "applicationGroup": "other",
      "applicationType": "DEFAULT",
      "categoryId": "e6619ce8-97de-4c63-a53a-cceddbddc1a8",
      "createTime": 1517966742669,
      "encrypted": "false",
      "engineId": "3",
      "helpString": "Fatmen Server",
      "ignoreConflict": false,
      "lastUpdateTime": 1517966742669,
      "longDescription": "Registered with IANA on port 347 TCP/UDP",
      "name": "fatserv",
      "nbarId": "305",
      "p2pTechnology": "false",
      "popularity": 1,
      "rank": 65535,
      "references": "http://www.iana.org/assignments/service-names-port-numbers/service-names-port-numbers.xml",
      "selectorId": "347",
      "subCategory": "50b55e2e-d0d0-4404-bea4-819351565d90",
      "trafficClass": "BULK_DATA",
      "tunnel": "false"
    }
  ],
  "networkIdentity": [
    {
      "id": "61633b1e-921c-4fb1-a521-33487a01e1d1",
      "instanceId": 13102,
      "authEntityId": 13102,
      "displayName": "13102",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "347",
      "protocol": "UDP",
      "upperPort": 0
    },
    {
      "id": "53bf0518-2d25-4eb4-bd7d-720d74f982a3",
      "instanceId": 13103,
      "authEntityId": 13103,
      "displayName": "13103",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "347",
      "protocol": "TCP",
      "upperPort": 0
    }
  ],
  "parentScalableGroup": {
    "idRef": "383363b1-4dd5-49d1-bfe4-34daac437cfb"
  },
  "user": [

  ],
  "userGroup": [

  ]
},
{
  "id": "b008c425-07cb-4e04-b3f6-6eb07025fca5",
  "instanceId": 15248,
  "authEntityId": 15248,
  "displayName": "15248",
  "authEntityClass": -1909073334,
  "deployPending": "NONE",
  "instanceVersion": 0,
  "createTime": 1517966744576,
  "deployed": false,
  "isSeeded": true,
  "isStale": false,
  "lastUpdateTime": 1517966744576,
  "name": "t-mobile-web-services",
  "namespace": "scalablegroup:application",
  "provisioningState": "DEFINED",
  "qualifier": "application",
  "resourceVersion": 0,
  "targetIdList": [

  ],
  "type": "scalablegroup",
  "cfsChangeInfo": [

  ],
  "customProvisions": [

  ],
  "scalableGroupExternalHandle": "t-mobile-web-services",
  "scalableGroupType": "APPLICATION",
  "securityGroupTag": 0,
  "state": "ACTIVE",
  "vnAgnostic": true,
  "identitySource": {
    "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
    "instanceId": 10010,
    "authEntityId": 10010,
    "displayName": "10010",
    "authEntityClass": 1738953278,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "state": "INACTIVE",
    "type": "NBAR"
  },
  "indicativeNetworkIdentity": [

  ],
  "networkApplications": [
    {
      "id": "1341bf2c-41c4-48fc-b834-868e32c18ca0",
      "instanceId": 16191,
      "authEntityId": 16191,
      "displayName": "16191",
      "authEntityClass": -217092956,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "appProtocol": "tcp/udp",
      "applicationGroup": "other",
      "applicationType": "DEFAULT",
      "categoryId": "878644b5-fe91-45b4-9204-31c84a73ba48",
      "createTime": 1517966744576,
      "encrypted": "false",
      "engineId": "13",
      "helpString": "T Mobile - Telecommunication and phone service provider.",
      "ignoreConflict": false,
      "lastUpdateTime": 1517966744576,
      "longDescription": "T Mobile - Telecommunication and phone service provider.",
      "name": "t-mobile-web-services",
      "nbarId": "1720",
      "p2pTechnology": "false",
      "popularity": 3,
      "rank": 65535,
      "references": "http://t-mobile.com",
      "selectorId": "792",
      "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
      "trafficClass": "TRANSACTIONAL_DATA",
      "tunnel": "false"
    }
  ],
  "networkIdentity": [
    {
      "id": "194a9d34-e111-48c5-ba7b-08232b48ada3",
      "instanceId": 17382,
      "authEntityId": 17382,
      "displayName": "17382",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "53,80,443,5353",
      "protocol": "TCP",
      "upperPort": 0
    },
    {
      "id": "1927528d-28da-452a-81a8-753cc53cfb74",
      "instanceId": 17383,
      "authEntityId": 17383,
      "displayName": "17383",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "53,5353",
      "protocol": "UDP",
      "upperPort": 0
    }
  ],
  "parentScalableGroup": {
    "idRef": "28e08de4-d7b2-4521-84ce-bc1e5ee0b9cc"
  },
  "user": [

  ],
  "userGroup": [

  ]
},
{
  "id": "b00d579a-b575-4e10-b22a-aead15ffe636",
  "instanceId": 4619,
  "authEntityId": 4619,
  "displayName": "4619",
  "authEntityClass": -1909073334,
  "deployPending": "NONE",
  "instanceVersion": 0,
  "createTime": 1517966743139,
  "deployed": false,
  "isSeeded": true,
  "isStale": false,
  "lastUpdateTime": 1517966743139,
  "name": "ipcserver",
  "namespace": "scalablegroup:application",
  "provisioningState": "DEFINED",
  "qualifier": "application",
  "resourceVersion": 0,
  "targetIdList": [

  ],
  "type": "scalablegroup",
  "cfsChangeInfo": [

  ],
  "customProvisions": [

  ],
  "scalableGroupExternalHandle": "ipcserver",
  "scalableGroupType": "APPLICATION",
  "securityGroupTag": 0,
  "state": "ACTIVE",
  "vnAgnostic": true,
  "identitySource": {
    "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
    "instanceId": 10010,
    "authEntityId": 10010,
    "displayName": "10010",
    "authEntityClass": 1738953278,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "state": "INACTIVE",
    "type": "NBAR"
  },
  "indicativeNetworkIdentity": [
    {
      "id": "1d025d6e-3b4e-4092-a30b-8eb93f525cda",
      "instanceId": 13563,
      "authEntityId": 13563,
      "displayName": "13563",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "600",
      "protocol": "UDP",
      "upperPort": 0
    },
    {
      "id": "565709ab-502d-49b1-8605-0362eade2e2a",
      "instanceId": 13564,
      "authEntityId": 13564,
      "displayName": "13564",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "600",
      "protocol": "TCP",
      "upperPort": 0
    }
  ],
  "networkApplications": [
    {
      "id": "a628960f-702b-4a26-b023-0df43310e2fc",
      "instanceId": 11569,
      "authEntityId": 11569,
      "displayName": "11569",
      "authEntityClass": -217092956,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "appProtocol": "tcp/udp",
      "applicationGroup": "other",
      "applicationType": "DEFAULT",
      "categoryId": "1fc17362-9334-4c7b-b752-5afdbb574017",
      "createTime": 1517966743139,
      "encrypted": "false",
      "engineId": "3",
      "helpString": "Sun IPC server",
      "ignoreConflict": false,
      "lastUpdateTime": 1517966743139,
      "longDescription": "ipcserver is a client-server communication program that listens for connections from local-domain clients.",
      "name": "ipcserver",
      "nbarId": "514",
      "p2pTechnology": "false",
      "popularity": 1,
      "rank": 65535,
      "references": "http://www.superscript.com/ucspi-ipc/ipcserver.html",
      "selectorId": "600",
      "subCategory": "787a7d77-977b-4974-89d3-379ba49e88b7",
      "trafficClass": "OPS_ADMIN_MGMT",
      "tunnel": "false"
    }
  ],
  "networkIdentity": [
    {
      "id": "2e425f2f-4bf4-4a18-bf74-b522c1ad23c4",
      "instanceId": 13561,
      "authEntityId": 13561,
      "displayName": "13561",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "600",
      "protocol": "TCP",
      "upperPort": 0
    },
    {
      "id": "4b8abeb2-4f01-4e45-9008-d8d230857a3a",
      "instanceId": 13562,
      "authEntityId": 13562,
      "displayName": "13562",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "600",
      "protocol": "UDP",
      "upperPort": 0
    }
  ],
  "parentScalableGroup": {
    "idRef": "e06dd89f-0a70-4422-ab0b-c2314650a9a7"
  },
  "user": [

  ],
  "userGroup": [

  ]
},
{
  "id": "b00df4c8-db1b-47a9-90d3-398e901e3c55",
  "instanceId": 4910,
  "authEntityId": 4910,
  "displayName": "4910",
  "authEntityClass": -1909073334,
  "deployPending": "NONE",
  "instanceVersion": 0,
  "createTime": 1517966743740,
  "deployed": false,
  "isSeeded": true,
  "isStale": false,
  "lastUpdateTime": 1517966743740,
  "name": "opalis-robot",
  "namespace": "scalablegroup:application",
  "provisioningState": "DEFINED",
  "qualifier": "application",
  "resourceVersion": 0,
  "targetIdList": [

  ],
  "type": "scalablegroup",
  "cfsChangeInfo": [

  ],
  "customProvisions": [

  ],
  "scalableGroupExternalHandle": "opalis-robot",
  "scalableGroupType": "APPLICATION",
  "securityGroupTag": 0,
  "state": "ACTIVE",
  "vnAgnostic": true,
  "identitySource": {
    "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
    "instanceId": 10010,
    "authEntityId": 10010,
    "displayName": "10010",
    "authEntityClass": 1738953278,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "state": "INACTIVE",
    "type": "NBAR"
  },
  "indicativeNetworkIdentity": [
    {
      "id": "063483ff-0732-47d1-8afe-67a689b6deda",
      "instanceId": 14392,
      "authEntityId": 14392,
      "displayName": "14392",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "314",
      "protocol": "UDP",
      "upperPort": 0
    },
    {
      "id": "c520e9ef-fa84-45f0-8c71-7ea49cd09a94",
      "instanceId": 14393,
      "authEntityId": 14393,
      "displayName": "14393",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "314",
      "protocol": "TCP",
      "upperPort": 0
    }
  ],
  "networkApplications": [
    {
      "id": "ca6cd9a8-8658-4a7c-ac78-ee9298f0a814",
      "instanceId": 11859,
      "authEntityId": 11859,
      "displayName": "11859",
      "authEntityClass": -217092956,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "appProtocol": "tcp/udp",
      "applicationGroup": "other",
      "applicationType": "DEFAULT",
      "categoryId": "1fc17362-9334-4c7b-b752-5afdbb574017",
      "createTime": 1517966743740,
      "encrypted": "false",
      "engineId": "3",
      "helpString": "opalis-robot",
      "ignoreConflict": false,
      "lastUpdateTime": 1517966743740,
      "longDescription": "OpalisRobot is a comprehensive system management and automation solution.  It delivers real-time monitoring, notification, corrective action and event driven job scheduling to provide administrators with proactive management.",
      "name": "opalis-robot",
      "nbarId": "1154",
      "p2pTechnology": "false",
      "popularity": 1,
      "rank": 65535,
      "references": "http://amtsoft.com/opalisrobot/",
      "selectorId": "314",
      "subCategory": "f3c0f761-d894-4112-ab89-b90fc84fd660",
      "trafficClass": "OPS_ADMIN_MGMT",
      "tunnel": "false"
    }
  ],
  "networkIdentity": [
    {
      "id": "9fd93208-d57a-4316-aea9-9fa61aedb382",
      "instanceId": 14390,
      "authEntityId": 14390,
      "displayName": "14390",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "314",
      "protocol": "UDP",
      "upperPort": 0
    },
    {
      "id": "800b8c83-eb63-4ac9-8867-0ffcaad04a7c",
      "instanceId": 14391,
      "authEntityId": 14391,
      "displayName": "14391",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "314",
      "protocol": "TCP",
      "upperPort": 0
    }
  ],
  "parentScalableGroup": {
    "idRef": "5fcd74d4-8726-47f7-a088-c457863da7ef"
  },
  "user": [

  ],
  "userGroup": [

  ]
},
{
  "id": "b017983f-314e-4997-8801-2abd394cc321",
  "instanceId": 4799,
  "authEntityId": 4799,
  "displayName": "4799",
  "authEntityClass": -1909073334,
  "deployPending": "NONE",
  "instanceVersion": 0,
  "createTime": 1517966743516,
  "deployed": false,
  "isSeeded": true,
  "isStale": false,
  "lastUpdateTime": 1517966743516,
  "name": "msg-auth",
  "namespace": "scalablegroup:application",
  "provisioningState": "DEFINED",
  "qualifier": "application",
  "resourceVersion": 0,
  "targetIdList": [

  ],
  "type": "scalablegroup",
  "cfsChangeInfo": [

  ],
  "customProvisions": [

  ],
  "scalableGroupExternalHandle": "msg-auth",
  "scalableGroupType": "APPLICATION",
  "securityGroupTag": 0,
  "state": "ACTIVE",
  "vnAgnostic": true,
  "identitySource": {
    "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
    "instanceId": 10010,
    "authEntityId": 10010,
    "displayName": "10010",
    "authEntityClass": 1738953278,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "state": "INACTIVE",
    "type": "NBAR"
  },
  "indicativeNetworkIdentity": [
    {
      "id": "56a906e1-285d-4f57-b9b6-9e3ecb3cbc88",
      "instanceId": 14043,
      "authEntityId": 14043,
      "displayName": "14043",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "31",
      "protocol": "TCP",
      "upperPort": 0
    },
    {
      "id": "689ac5a1-c452-4b69-ac02-5db3dc426125",
      "instanceId": 14044,
      "authEntityId": 14044,
      "displayName": "14044",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "31",
      "protocol": "UDP",
      "upperPort": 0
    }
  ],
  "networkApplications": [
    {
      "id": "4b6ad8a8-f82c-4cc7-a9f2-70ade6304100",
      "instanceId": 11748,
      "authEntityId": 11748,
      "displayName": "11748",
      "authEntityClass": -217092956,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "appProtocol": "tcp/udp",
      "applicationGroup": "other",
      "applicationType": "DEFAULT",
      "categoryId": "6daa3dfd-3526-407a-88f0-97824b3f3a24",
      "createTime": 1517966743516,
      "encrypted": "false",
      "engineId": "3",
      "helpString": "msg-auth",
      "ignoreConflict": false,
      "lastUpdateTime": 1517966743516,
      "longDescription": "Registered with IANA on port 31 TCP/UDP",
      "name": "msg-auth",
      "nbarId": "916",
      "p2pTechnology": "false",
      "popularity": 1,
      "rank": 65535,
      "references": "http://www.iana.org/assignments/service-names-port-numbers/service-names-port-numbers.xml",
      "selectorId": "31",
      "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
      "trafficClass": "BULK_DATA",
      "tunnel": "false"
    }
  ],
  "networkIdentity": [
    {
      "id": "3c9580b1-b717-4d15-ab91-7361f582f6e1",
      "instanceId": 14041,
      "authEntityId": 14041,
      "displayName": "14041",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "31",
      "protocol": "TCP",
      "upperPort": 0
    },
    {
      "id": "941533da-8a04-4e1c-b408-6faa1042b67c",
      "instanceId": 14042,
      "authEntityId": 14042,
      "displayName": "14042",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "31",
      "protocol": "UDP",
      "upperPort": 0
    }
  ],
  "parentScalableGroup": {
    "idRef": "000c15bf-28fe-4fbb-9b69-35e6502721f1"
  },
  "user": [

  ],
  "userGroup": [

  ]
},
{
  "id": "b0491327-4469-4ab4-b474-5e729ab4bfcc",
  "instanceId": 4744,
  "authEntityId": 4744,
  "displayName": "4744",
  "authEntityClass": -1909073334,
  "deployPending": "NONE",
  "instanceVersion": 0,
  "createTime": 1517966743407,
  "deployed": false,
  "isSeeded": true,
  "isStale": false,
  "lastUpdateTime": 1517966743407,
  "name": "micom-pfs",
  "namespace": "scalablegroup:application",
  "provisioningState": "DEFINED",
  "qualifier": "application",
  "resourceVersion": 0,
  "targetIdList": [

  ],
  "type": "scalablegroup",
  "cfsChangeInfo": [

  ],
  "customProvisions": [

  ],
  "scalableGroupExternalHandle": "micom-pfs",
  "scalableGroupType": "APPLICATION",
  "securityGroupTag": 0,
  "state": "ACTIVE",
  "vnAgnostic": true,
  "identitySource": {
    "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
    "instanceId": 10010,
    "authEntityId": 10010,
    "displayName": "10010",
    "authEntityClass": 1738953278,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "state": "INACTIVE",
    "type": "NBAR"
  },
  "indicativeNetworkIdentity": [
    {
      "id": "07324a11-6bd9-4ebf-8b70-7ff6c6a2285e",
      "instanceId": 13896,
      "authEntityId": 13896,
      "displayName": "13896",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "490",
      "protocol": "UDP",
      "upperPort": 0
    },
    {
      "id": "1dc7d226-6de1-4242-aae0-cd70b2d4ff48",
      "instanceId": 13897,
      "authEntityId": 13897,
      "displayName": "13897",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "490",
      "protocol": "TCP",
      "upperPort": 0
    }
  ],
  "networkApplications": [
    {
      "id": "369606aa-21b9-41bf-9e58-28f0e1b5af2e",
      "instanceId": 11694,
      "authEntityId": 11694,
      "displayName": "11694",
      "authEntityClass": -217092956,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "appProtocol": "tcp/udp",
      "applicationGroup": "other",
      "applicationType": "DEFAULT",
      "categoryId": "6daa3dfd-3526-407a-88f0-97824b3f3a24",
      "createTime": 1517966743407,
      "encrypted": "false",
      "engineId": "3",
      "helpString": "micom-pfs",
      "ignoreConflict": false,
      "lastUpdateTime": 1517966743407,
      "longDescription": "Registered with IANA on port 490 TCP/UDP",
      "name": "micom-pfs",
      "nbarId": "404",
      "p2pTechnology": "false",
      "popularity": 1,
      "rank": 65535,
      "references": "http://www.iana.org/assignments/service-names-port-numbers/service-names-port-numbers.xml",
      "selectorId": "490",
      "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
      "trafficClass": "BULK_DATA",
      "tunnel": "false"
    }
  ],
  "networkIdentity": [
    {
      "id": "d9bc0660-516f-4c21-a7f4-3827ac60a8bd",
      "instanceId": 13894,
      "authEntityId": 13894,
      "displayName": "13894",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "490",
      "protocol": "TCP",
      "upperPort": 0
    },
    {
      "id": "ea54d909-ec3c-4999-932f-006f4ad2e1b6",
      "instanceId": 13895,
      "authEntityId": 13895,
      "displayName": "13895",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "490",
      "protocol": "UDP",
      "upperPort": 0
    }
  ],
  "parentScalableGroup": {
    "idRef": "000c15bf-28fe-4fbb-9b69-35e6502721f1"
  },
  "user": [

  ],
  "userGroup": [

  ]
},
{
  "id": "b066eed8-7aa4-4acb-a714-81345bb43347",
  "instanceId": 4333,
  "authEntityId": 4333,
  "displayName": "4333",
  "authEntityClass": -1909073334,
  "deployPending": "NONE",
  "instanceVersion": 0,
  "createTime": 1517966742314,
  "deployed": false,
  "isSeeded": true,
  "isStale": false,
  "lastUpdateTime": 1517966742314,
  "name": "dccp",
  "namespace": "scalablegroup:application",
  "provisioningState": "DEFINED",
  "qualifier": "application",
  "resourceVersion": 0,
  "targetIdList": [

  ],
  "type": "scalablegroup",
  "cfsChangeInfo": [

  ],
  "customProvisions": [

  ],
  "scalableGroupExternalHandle": "dccp",
  "scalableGroupType": "APPLICATION",
  "securityGroupTag": 0,
  "state": "ACTIVE",
  "vnAgnostic": true,
  "identitySource": {
    "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
    "instanceId": 10010,
    "authEntityId": 10010,
    "displayName": "10010",
    "authEntityClass": 1738953278,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "state": "INACTIVE",
    "type": "NBAR"
  },
  "indicativeNetworkIdentity": [
    {
      "id": "f3c172e0-16f3-48f2-add7-37db02d67303",
      "instanceId": 12773,
      "authEntityId": 12773,
      "displayName": "12773",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipProtocolNumbers": "33",
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "protocol": "DCCP",
      "upperPort": 0
    }
  ],
  "networkApplications": [
    {
      "id": "f226770e-060b-4252-bcc7-4ff7103d2cb2",
      "instanceId": 11284,
      "authEntityId": 11284,
      "displayName": "11284",
      "authEntityClass": -217092956,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "appProtocol": "ip",
      "applicationGroup": "other",
      "applicationType": "DEFAULT",
      "categoryId": "6daa3dfd-3526-407a-88f0-97824b3f3a24",
      "createTime": 1517966742314,
      "encrypted": "false",
      "engineId": "1",
      "helpString": "Datagram Congestion Control Protocol",
      "ignoreConflict": false,
      "lastUpdateTime": 1517966742314,
      "longDescription": "The Datagram Congestion Control Protocol (DCCP) is a message-oriented transport layer protocol. DCCP implements reliable connection setup, teardown, Explicit Congestion Notification (ECN), congestion control, and feature negotiation.",
      "name": "dccp",
      "nbarId": "1238",
      "p2pTechnology": "false",
      "popularity": 2,
      "rank": 65535,
      "references": "http://www.ietf.org/rfc/rfc4340.txt",
      "selectorId": "33",
      "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
      "trafficClass": "BULK_DATA",
      "tunnel": "false"
    }
  ],
  "networkIdentity": [
    {
      "id": "955bbbd9-3bd9-40c5-9c72-6f8b217e0403",
      "instanceId": 12772,
      "authEntityId": 12772,
      "displayName": "12772",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipProtocolNumbers": "33",
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "protocol": "DCCP",
      "upperPort": 0
    }
  ],
  "parentScalableGroup": {
    "idRef": "000c15bf-28fe-4fbb-9b69-35e6502721f1"
  },
  "user": [

  ],
  "userGroup": [

  ]
},
{
  "id": "b0eb036c-89eb-4440-93b2-4e017c71bda3",
  "instanceId": 15401,
  "authEntityId": 15401,
  "displayName": "15401",
  "authEntityClass": -1909073334,
  "deployPending": "NONE",
  "instanceVersion": 0,
  "createTime": 1517966744878,
  "deployed": false,
  "isSeeded": true,
  "isStale": false,
  "lastUpdateTime": 1517966744878,
  "name": "webex-media",
  "namespace": "scalablegroup:application",
  "provisioningState": "DEFINED",
  "qualifier": "application",
  "resourceVersion": 0,
  "targetIdList": [

  ],
  "type": "scalablegroup",
  "cfsChangeInfo": [

  ],
  "customProvisions": [

  ],
  "scalableGroupExternalHandle": "webex-media",
  "scalableGroupType": "APPLICATION",
  "securityGroupTag": 0,
  "state": "ACTIVE",
  "vnAgnostic": true,
  "identitySource": {
    "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
    "instanceId": 10010,
    "authEntityId": 10010,
    "displayName": "10010",
    "authEntityClass": 1738953278,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "state": "INACTIVE",
    "type": "NBAR"
  },
  "indicativeNetworkIdentity": [

  ],
  "networkApplications": [
    {
      "id": "7368794d-b825-475f-93f0-f1b3042e4311",
      "instanceId": 16344,
      "authEntityId": 16344,
      "displayName": "16344",
      "authEntityClass": -217092956,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "appProtocol": "tcp/udp",
      "applicationGroup": "webex-group",
      "applicationType": "DEFAULT",
      "categoryId": "38a8e354-e2fe-4f3c-b1c5-2bbb90fb0d99",
      "createTime": 1517966744878,
      "encrypted": "true",
      "engineId": "13",
      "helpString": "Webex audio, video and file sharing with HTTP-Proxy configured",
      "ignoreConflict": false,
      "lastUpdateTime": 1517966744878,
      "longDescription": "WebEx-Media is granular classification of WebEx protocol video, audio and file sharing traffic, configured with HTTP-proxy.",
      "name": "webex-media",
      "nbarId": "1479",
      "p2pTechnology": "false",
      "popularity": 10,
      "rank": 65535,
      "references": "http://www.webex.com/",
      "selectorId": "545",
      "subCategory": "05178016-4555-4291-ab4e-43c2ab2a931a",
      "trafficClass": "MULTIMEDIA_CONFERENCING",
      "tunnel": "false"
    }
  ],
  "networkIdentity": [
    {
      "id": "cc6008d0-76d9-4184-bb29-454007b3516f",
      "instanceId": 17809,
      "authEntityId": 17809,
      "displayName": "17809",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "53,5353,9000",
      "protocol": "UDP",
      "upperPort": 0
    },
    {
      "id": "881722ad-1d30-483e-848b-5d3cd2696fe9",
      "instanceId": 17810,
      "authEntityId": 17810,
      "displayName": "17810",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "53,80,443,5353",
      "protocol": "TCP",
      "upperPort": 0
    }
  ],
  "parentScalableGroup": {
    "idRef": "60f1364d-2a02-4063-8ba1-07817abe0612"
  },
  "user": [

  ],
  "userGroup": [

  ]
},
{
  "id": "b0f59285-db3d-447d-ac16-e7cce686d988",
  "instanceId": 15357,
  "authEntityId": 15357,
  "displayName": "15357",
  "authEntityClass": -1909073334,
  "deployPending": "NONE",
  "instanceVersion": 0,
  "createTime": 1517966744798,
  "deployed": false,
  "isSeeded": true,
  "isStale": false,
  "lastUpdateTime": 1517966744798,
  "name": "vmnet",
  "namespace": "scalablegroup:application",
  "provisioningState": "DEFINED",
  "qualifier": "application",
  "resourceVersion": 0,
  "targetIdList": [

  ],
  "type": "scalablegroup",
  "cfsChangeInfo": [

  ],
  "customProvisions": [

  ],
  "scalableGroupExternalHandle": "vmnet",
  "scalableGroupType": "APPLICATION",
  "securityGroupTag": 0,
  "state": "ACTIVE",
  "vnAgnostic": true,
  "identitySource": {
    "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
    "instanceId": 10010,
    "authEntityId": 10010,
    "displayName": "10010",
    "authEntityClass": 1738953278,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "state": "INACTIVE",
    "type": "NBAR"
  },
  "indicativeNetworkIdentity": [
    {
      "id": "499672fc-641b-4ef7-bd6f-72ab896fbe4f",
      "instanceId": 17687,
      "authEntityId": 17687,
      "displayName": "17687",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "175",
      "protocol": "UDP",
      "upperPort": 0
    },
    {
      "id": "6506647c-df3c-45bb-9138-151080373d42",
      "instanceId": 17688,
      "authEntityId": 17688,
      "displayName": "17688",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "175",
      "protocol": "TCP",
      "upperPort": 0
    }
  ],
  "networkApplications": [
    {
      "id": "0cc2450f-24f7-447f-b3e8-1239a2782cbe",
      "instanceId": 16300,
      "authEntityId": 16300,
      "displayName": "16300",
      "authEntityClass": -217092956,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "appProtocol": "tcp/udp",
      "applicationGroup": "other",
      "applicationType": "DEFAULT",
      "categoryId": "6daa3dfd-3526-407a-88f0-97824b3f3a24",
      "createTime": 1517966744798,
      "encrypted": "false",
      "engineId": "3",
      "helpString": "vmnet",
      "ignoreConflict": false,
      "lastUpdateTime": 1517966744798,
      "longDescription": "VMNet is a virtual networking program that has been written to implement virtual networking support for the Hercules S/390 simulator, but it is intentionally generic in its functionality, so any other virtual machine simulator/emulator program could potentially use it.",
      "name": "vmnet",
      "nbarId": "1020",
      "p2pTechnology": "false",
      "popularity": 1,
      "rank": 65535,
      "references": "http://www.tldp.org/LDP/Linux-Dictionary/html/v.html",
      "selectorId": "175",
      "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
      "trafficClass": "BULK_DATA",
      "tunnel": "false"
    }
  ],
  "networkIdentity": [
    {
      "id": "efa2d582-58e1-4c5e-9c98-4b7a142d7bf2",
      "instanceId": 17685,
      "authEntityId": 17685,
      "displayName": "17685",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "175",
      "protocol": "TCP",
      "upperPort": 0
    },
    {
      "id": "4a08270e-02e7-4427-9491-08cbe90166fb",
      "instanceId": 17686,
      "authEntityId": 17686,
      "displayName": "17686",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "175",
      "protocol": "UDP",
      "upperPort": 0
    }
  ],
  "parentScalableGroup": {
    "idRef": "000c15bf-28fe-4fbb-9b69-35e6502721f1"
  },
  "user": [

  ],
  "userGroup": [

  ]
},
{
  "id": "b1231341-538a-43a5-8ce9-ecd5123e7317",
  "instanceId": 4994,
  "authEntityId": 4994,
  "displayName": "4994",
  "authEntityClass": -1909073334,
  "deployPending": "NONE",
  "instanceVersion": 0,
  "createTime": 1517966744002,
  "deployed": false,
  "isSeeded": true,
  "isStale": false,
  "lastUpdateTime": 1517966744002,
  "name": "ptp-event",
  "namespace": "scalablegroup:application",
  "provisioningState": "DEFINED",
  "qualifier": "application",
  "resourceVersion": 0,
  "targetIdList": [

  ],
  "type": "scalablegroup",
  "cfsChangeInfo": [

  ],
  "customProvisions": [

  ],
  "scalableGroupExternalHandle": "ptp-event",
  "scalableGroupType": "APPLICATION",
  "securityGroupTag": 0,
  "state": "ACTIVE",
  "vnAgnostic": true,
  "identitySource": {
    "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
    "instanceId": 10010,
    "authEntityId": 10010,
    "displayName": "10010",
    "authEntityClass": 1738953278,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "state": "INACTIVE",
    "type": "NBAR"
  },
  "indicativeNetworkIdentity": [
    {
      "id": "9a8a198c-f692-4e87-a1ee-89dbc1aac36a",
      "instanceId": 14638,
      "authEntityId": 14638,
      "displayName": "14638",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "319",
      "protocol": "TCP",
      "upperPort": 0
    },
    {
      "id": "aa9a3492-89b8-41db-9508-36bf4660678f",
      "instanceId": 14639,
      "authEntityId": 14639,
      "displayName": "14639",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "319",
      "protocol": "UDP",
      "upperPort": 0
    }
  ],
  "networkApplications": [
    {
      "id": "f3d14d0e-7c8a-4faa-b18e-1ffb83d5c8b3",
      "instanceId": 11943,
      "authEntityId": 11943,
      "displayName": "11943",
      "authEntityClass": -217092956,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "appProtocol": "tcp/udp",
      "applicationGroup": "other",
      "applicationType": "DEFAULT",
      "categoryId": "1fc17362-9334-4c7b-b752-5afdbb574017",
      "createTime": 1517966744002,
      "encrypted": "false",
      "engineId": "3",
      "helpString": "PTP Event",
      "ignoreConflict": false,
      "lastUpdateTime": 1517966744002,
      "longDescription": "The Precision Time Protocol (PTP) is a protocol used to synchronize clocks throughout a computer network. On a local area network it achieves clock accuracy in the sub-microsecond range, making it suitable for measurement and control systems.",
      "name": "ptp-event",
      "nbarId": "1159",
      "p2pTechnology": "false",
      "popularity": 1,
      "rank": 65535,
      "references": "http://www.nist.gov/el/isd/ieee/ieee1588.cfm",
      "selectorId": "319",
      "subCategory": "f3c0f761-d894-4112-ab89-b90fc84fd660",
      "trafficClass": "OPS_ADMIN_MGMT",
      "tunnel": "false"
    }
  ],
  "networkIdentity": [
    {
      "id": "880260fa-d93e-4f8c-a581-a1a0ed56f63b",
      "instanceId": 14636,
      "authEntityId": 14636,
      "displayName": "14636",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "319",
      "protocol": "TCP",
      "upperPort": 0
    },
    {
      "id": "a42cde12-4974-48e6-ac2d-f94a698f2cd4",
      "instanceId": 14637,
      "authEntityId": 14637,
      "displayName": "14637",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "319",
      "protocol": "UDP",
      "upperPort": 0
    }
  ],
  "parentScalableGroup": {
    "idRef": "5fcd74d4-8726-47f7-a088-c457863da7ef"
  },
  "user": [

  ],
  "userGroup": [

  ]
},
{
  "id": "b174d27c-551e-4c24-b36f-df8cee493dc2",
  "instanceId": 15321,
  "authEntityId": 15321,
  "displayName": "15321",
  "authEntityClass": -1909073334,
  "deployPending": "NONE",
  "instanceVersion": 0,
  "createTime": 1517966744735,
  "deployed": false,
  "isSeeded": true,
  "isStale": false,
  "lastUpdateTime": 1517966744735,
  "name": "ultrasurf",
  "namespace": "scalablegroup:application",
  "provisioningState": "DEFINED",
  "qualifier": "application",
  "resourceVersion": 0,
  "targetIdList": [

  ],
  "type": "scalablegroup",
  "cfsChangeInfo": [

  ],
  "customProvisions": [

  ],
  "scalableGroupExternalHandle": "ultrasurf",
  "scalableGroupType": "APPLICATION",
  "securityGroupTag": 0,
  "state": "ACTIVE",
  "vnAgnostic": true,
  "identitySource": {
    "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
    "instanceId": 10010,
    "authEntityId": 10010,
    "displayName": "10010",
    "authEntityClass": 1738953278,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "state": "INACTIVE",
    "type": "NBAR"
  },
  "indicativeNetworkIdentity": [

  ],
  "networkApplications": [
    {
      "id": "4aefc08c-fdbc-41ff-8a68-91374671b4ce",
      "instanceId": 16264,
      "authEntityId": 16264,
      "displayName": "16264",
      "authEntityClass": -217092956,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "appProtocol": "tcp/udp",
      "applicationGroup": "other",
      "applicationType": "DEFAULT",
      "categoryId": "cf6b10c2-40a5-4a1c-857c-4b6e0caae271",
      "createTime": 1517966744735,
      "encrypted": "true",
      "engineId": "13",
      "helpString": "Ultrasurf - Internet censorship circumvention",
      "ignoreConflict": false,
      "lastUpdateTime": 1517966744735,
      "longDescription": "Ultrasurf is an internet censorship circumvention proxy as well as anonymizer. It is developed and maintained by Ultrareach Internet Corporation.",
      "name": "ultrasurf",
      "nbarId": "1523",
      "p2pTechnology": "false",
      "popularity": 7,
      "rank": 65535,
      "references": "http://www.ultrasurf.us/",
      "selectorId": "584",
      "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
      "trafficClass": "BULK_DATA",
      "tunnel": "true"
    }
  ],
  "networkIdentity": [
    {
      "id": "06f15cae-4403-4cdc-a2f4-fd14d8e44e7c",
      "instanceId": 17584,
      "authEntityId": 17584,
      "displayName": "17584",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "53,80,443,5353",
      "protocol": "TCP",
      "upperPort": 0
    },
    {
      "id": "4d809f75-014f-42a2-b491-a67015a82d55",
      "instanceId": 17583,
      "authEntityId": 17583,
      "displayName": "17583",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "53,5353",
      "protocol": "UDP",
      "upperPort": 0
    }
  ],
  "parentScalableGroup": {
    "idRef": "5e66d25d-d517-482b-82a8-6f99f07fa786"
  },
  "user": [

  ],
  "userGroup": [

  ]
},
{
  "id": "b1d44564-3d39-42d2-8669-bb25a20cb54d",
  "instanceId": 4067,
  "authEntityId": 4067,
  "displayName": "4067",
  "authEntityClass": -1909073334,
  "deployPending": "NONE",
  "instanceVersion": 0,
  "createTime": 1517966740750,
  "deployed": false,
  "isSeeded": true,
  "isStale": false,
  "lastUpdateTime": 1517966740750,
  "name": "agentx",
  "namespace": "scalablegroup:application",
  "provisioningState": "DEFINED",
  "qualifier": "application",
  "resourceVersion": 0,
  "targetIdList": [

  ],
  "type": "scalablegroup",
  "cfsChangeInfo": [

  ],
  "customProvisions": [

  ],
  "scalableGroupExternalHandle": "agentx",
  "scalableGroupType": "APPLICATION",
  "securityGroupTag": 0,
  "state": "ACTIVE",
  "vnAgnostic": true,
  "identitySource": {
    "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
    "instanceId": 10010,
    "authEntityId": 10010,
    "displayName": "10010",
    "authEntityClass": 1738953278,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "state": "INACTIVE",
    "type": "NBAR"
  },
  "indicativeNetworkIdentity": [
    {
      "id": "d12bdc9e-48e9-41af-ba85-a89228e54f5e",
      "instanceId": 12091,
      "authEntityId": 12091,
      "displayName": "12091",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "705",
      "protocol": "TCP",
      "upperPort": 0
    },
    {
      "id": "79812763-0a44-47db-912f-abcc716366b6",
      "instanceId": 12092,
      "authEntityId": 12092,
      "displayName": "12092",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "705",
      "protocol": "UDP",
      "upperPort": 0
    }
  ],
  "networkApplications": [
    {
      "id": "400aeacb-5965-4937-8e06-3b4f99598dd7",
      "instanceId": 11036,
      "authEntityId": 11036,
      "displayName": "11036",
      "authEntityClass": -217092956,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "appProtocol": "tcp/udp",
      "applicationGroup": "snmp-group",
      "applicationType": "DEFAULT",
      "categoryId": "1fc17362-9334-4c7b-b752-5afdbb574017",
      "createTime": 1517966740750,
      "encrypted": "false",
      "engineId": "3",
      "helpString": "AgentX",
      "ignoreConflict": false,
      "lastUpdateTime": 1517966740750,
      "longDescription": "AgentX is a protocol used to communicate between processing entities called master, agents and subagents, and the elements of procedure by which the extensible agent processes SNMP protocol messages.",
      "name": "agentx",
      "nbarId": "609",
      "p2pTechnology": "false",
      "popularity": 1,
      "rank": 65535,
      "references": "http://www.ietf.org/rfc/rfc2741.txt",
      "selectorId": "705",
      "subCategory": "f3c0f761-d894-4112-ab89-b90fc84fd660",
      "trafficClass": "OPS_ADMIN_MGMT",
      "tunnel": "false"
    }
  ],
  "networkIdentity": [
    {
      "id": "f71aa461-0748-47d6-8d9b-29703bbf78f0",
      "instanceId": 12089,
      "authEntityId": 12089,
      "displayName": "12089",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "705",
      "protocol": "UDP",
      "upperPort": 0
    },
    {
      "id": "a82d6af4-a2ea-44ba-a12d-77061b871331",
      "instanceId": 12090,
      "authEntityId": 12090,
      "displayName": "12090",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "705",
      "protocol": "TCP",
      "upperPort": 0
    }
  ],
  "parentScalableGroup": {
    "idRef": "5fcd74d4-8726-47f7-a088-c457863da7ef"
  },
  "user": [

  ],
  "userGroup": [

  ]
},
{
  "id": "b1d8265a-0145-4a63-a164-41369fef78e4",
  "instanceId": 4245,
  "authEntityId": 4245,
  "displayName": "4245",
  "authEntityClass": -1909073334,
  "deployPending": "NONE",
  "instanceVersion": 0,
  "createTime": 1517966741925,
  "deployed": false,
  "isSeeded": true,
  "isStale": false,
  "lastUpdateTime": 1517966741925,
  "name": "cisco-phone",
  "namespace": "scalablegroup:application",
  "provisioningState": "DEFINED",
  "qualifier": "application",
  "resourceVersion": 0,
  "targetIdList": [

  ],
  "type": "scalablegroup",
  "cfsChangeInfo": [

  ],
  "customProvisions": [

  ],
  "scalableGroupExternalHandle": "cisco-phone",
  "scalableGroupType": "APPLICATION",
  "securityGroupTag": 0,
  "state": "ACTIVE",
  "vnAgnostic": true,
  "identitySource": {
    "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
    "instanceId": 10010,
    "authEntityId": 10010,
    "displayName": "10010",
    "authEntityClass": 1738953278,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "state": "INACTIVE",
    "type": "NBAR"
  },
  "indicativeNetworkIdentity": [

  ],
  "networkApplications": [
    {
      "id": "705c4e3c-597c-4283-a293-29e9b6adba75",
      "instanceId": 11199,
      "authEntityId": 11199,
      "displayName": "11199",
      "authEntityClass": -217092956,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "appProtocol": "tcp/udp",
      "applicationGroup": "cisco-phone-group",
      "applicationType": "DEFAULT",
      "categoryId": "38a8e354-e2fe-4f3c-b1c5-2bbb90fb0d99",
      "createTime": 1517966741925,
      "encrypted": "false",
      "engineId": "13",
      "helpString": "Cisco IP Phones and PC-based Unified Communicators",
      "ignoreConflict": false,
      "lastUpdateTime": 1517966741925,
      "longDescription": "Cisco-ip-phone is a VoIP telephone used mainly in corporations and can be used on or off site.",
      "name": "cisco-phone",
      "nbarId": "81",
      "p2pTechnology": "false",
      "popularity": 10,
      "rank": 65535,
      "references": "http://www.cisco.com/en/US/prod/collateral/voicesw/ps6788/phones/ps379/ps1854/product_data_sheet09186a008008884a.html",
      "selectorId": "81",
      "subCategory": "53ce0342-1e2d-455a-acc7-1b50db9d6845",
      "trafficClass": "MULTIMEDIA_CONFERENCING",
      "tunnel": "false"
    }
  ],
  "networkIdentity": [
    {
      "id": "8954b129-3a50-4c7b-a267-6fbdfa990a5c",
      "instanceId": 12526,
      "authEntityId": 12526,
      "displayName": "12526",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "80,443,2000,2001,2002,5060",
      "protocol": "TCP",
      "upperPort": 0
    },
    {
      "id": "b87cb95f-7842-47ac-9275-dea5ec71d2d6",
      "instanceId": 12527,
      "authEntityId": 12527,
      "displayName": "12527",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "5060",
      "protocol": "UDP",
      "upperPort": 0
    }
  ],
  "parentScalableGroup": {
    "idRef": "60f1364d-2a02-4063-8ba1-07817abe0612"
  },
  "user": [

  ],
  "userGroup": [

  ]
},
{
  "id": "b2063bf1-4612-47bd-b667-5523680978a4",
  "instanceId": 4954,
  "authEntityId": 4954,
  "displayName": "4954",
  "authEntityClass": -1909073334,
  "deployPending": "NONE",
  "instanceVersion": 0,
  "createTime": 1517966743894,
  "deployed": false,
  "isSeeded": true,
  "isStale": false,
  "lastUpdateTime": 1517966743894,
  "name": "pgm",
  "namespace": "scalablegroup:application",
  "provisioningState": "DEFINED",
  "qualifier": "application",
  "resourceVersion": 0,
  "targetIdList": [

  ],
  "type": "scalablegroup",
  "cfsChangeInfo": [

  ],
  "customProvisions": [

  ],
  "scalableGroupExternalHandle": "pgm",
  "scalableGroupType": "APPLICATION",
  "securityGroupTag": 0,
  "state": "ACTIVE",
  "vnAgnostic": true,
  "identitySource": {
    "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
    "instanceId": 10010,
    "authEntityId": 10010,
    "displayName": "10010",
    "authEntityClass": 1738953278,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "state": "INACTIVE",
    "type": "NBAR"
  },
  "indicativeNetworkIdentity": [
    {
      "id": "3a97717f-2062-45a8-b6b8-04cafa2a5b3f",
      "instanceId": 14521,
      "authEntityId": 14521,
      "displayName": "14521",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipProtocolNumbers": "113",
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "protocol": "PGM",
      "upperPort": 0
    }
  ],
  "networkApplications": [
    {
      "id": "356552b6-e3ec-492c-9d1f-93cd697b12e6",
      "instanceId": 11903,
      "authEntityId": 11903,
      "displayName": "11903",
      "authEntityClass": -217092956,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "appProtocol": "ip",
      "applicationGroup": "other",
      "applicationType": "DEFAULT",
      "categoryId": "6daa3dfd-3526-407a-88f0-97824b3f3a24",
      "createTime": 1517966743894,
      "encrypted": "unassigned",
      "engineId": "1",
      "helpString": "PGM Reliable Transport Protocol",
      "ignoreConflict": false,
      "lastUpdateTime": 1517966743894,
      "longDescription": "Pragmatic General Multicast (PGM) is a reliable multicast transport protocol. PGM provides a reliable sequence of packets to multiple recipients simultaneously, making it suitable for applications like multi-receiver file-transfer.",
      "name": "pgm",
      "nbarId": "867",
      "p2pTechnology": "unassigned",
      "popularity": 2,
      "rank": 65535,
      "references": "http://www.ietf.org/rfc/rfc3208.txt",
      "selectorId": "113",
      "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
      "trafficClass": "BULK_DATA",
      "tunnel": "unassigned"
    }
  ],
  "networkIdentity": [
    {
      "id": "c156ff45-d4af-4a72-85b2-c665d910e60d",
      "instanceId": 14520,
      "authEntityId": 14520,
      "displayName": "14520",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipProtocolNumbers": "113",
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "protocol": "PGM",
      "upperPort": 0
    }
  ],
  "parentScalableGroup": {
    "idRef": "000c15bf-28fe-4fbb-9b69-35e6502721f1"
  },
  "user": [

  ],
  "userGroup": [

  ]
},
{
  "id": "b212367e-8449-4533-9aa0-2870d4044d63",
  "instanceId": 4767,
  "authEntityId": 4767,
  "displayName": "4767",
  "authEntityClass": -1909073334,
  "deployPending": "NONE",
  "instanceVersion": 0,
  "createTime": 1517966743452,
  "deployed": false,
  "isSeeded": true,
  "isStale": false,
  "lastUpdateTime": 1517966743452,
  "name": "mpm-snd",
  "namespace": "scalablegroup:application",
  "provisioningState": "DEFINED",
  "qualifier": "application",
  "resourceVersion": 0,
  "targetIdList": [

  ],
  "type": "scalablegroup",
  "cfsChangeInfo": [

  ],
  "customProvisions": [

  ],
  "scalableGroupExternalHandle": "mpm-snd",
  "scalableGroupType": "APPLICATION",
  "securityGroupTag": 0,
  "state": "ACTIVE",
  "vnAgnostic": true,
  "identitySource": {
    "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
    "instanceId": 10010,
    "authEntityId": 10010,
    "displayName": "10010",
    "authEntityClass": 1738953278,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "state": "INACTIVE",
    "type": "NBAR"
  },
  "indicativeNetworkIdentity": [
    {
      "id": "679153d0-8d16-4a69-9223-3aa1b651196e",
      "instanceId": 13960,
      "authEntityId": 13960,
      "displayName": "13960",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "46",
      "protocol": "UDP",
      "upperPort": 0
    },
    {
      "id": "716850fd-6b08-4e0f-8259-250872c61fd5",
      "instanceId": 13961,
      "authEntityId": 13961,
      "displayName": "13961",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "46",
      "protocol": "TCP",
      "upperPort": 0
    }
  ],
  "networkApplications": [
    {
      "id": "0cb2ebfb-579c-49da-92d4-b4db607d0c7a",
      "instanceId": 11717,
      "authEntityId": 11717,
      "displayName": "11717",
      "authEntityClass": -217092956,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "appProtocol": "tcp/udp",
      "applicationGroup": "other",
      "applicationType": "DEFAULT",
      "categoryId": "6daa3dfd-3526-407a-88f0-97824b3f3a24",
      "createTime": 1517966743452,
      "encrypted": "false",
      "engineId": "3",
      "helpString": "MPM [default send]",
      "ignoreConflict": false,
      "lastUpdateTime": 1517966743452,
      "longDescription": "Registered with IANA on port 46 TCP/UDP",
      "name": "mpm-snd",
      "nbarId": "926",
      "p2pTechnology": "false",
      "popularity": 1,
      "rank": 65535,
      "references": "http://www.iana.org/assignments/service-names-port-numbers/service-names-port-numbers.xml",
      "selectorId": "46",
      "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
      "trafficClass": "BULK_DATA",
      "tunnel": "false"
    }
  ],
  "networkIdentity": [
    {
      "id": "e7f77f4f-29f3-4461-8b52-0cbec4f921b2",
      "instanceId": 13958,
      "authEntityId": 13958,
      "displayName": "13958",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "46",
      "protocol": "UDP",
      "upperPort": 0
    },
    {
      "id": "7335738c-d298-410a-9196-19285a15833e",
      "instanceId": 13959,
      "authEntityId": 13959,
      "displayName": "13959",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "46",
      "protocol": "TCP",
      "upperPort": 0
    }
  ],
  "parentScalableGroup": {
    "idRef": "000c15bf-28fe-4fbb-9b69-35e6502721f1"
  },
  "user": [

  ],
  "userGroup": [

  ]
},
{
  "id": "b25e35ba-0c3a-4102-b7f5-e133f9c5cf07",
  "instanceId": 4233,
  "authEntityId": 4233,
  "displayName": "4233",
  "authEntityClass": -1909073334,
  "deployPending": "NONE",
  "instanceVersion": 0,
  "createTime": 1517966741871,
  "deployed": false,
  "isSeeded": true,
  "isStale": false,
  "lastUpdateTime": 1517966741871,
  "name": "cisco-controller",
  "namespace": "scalablegroup:application",
  "provisioningState": "DEFINED",
  "qualifier": "application",
  "resourceVersion": 0,
  "targetIdList": [

  ],
  "type": "scalablegroup",
  "cfsChangeInfo": [

  ],
  "customProvisions": [

  ],
  "scalableGroupExternalHandle": "cisco-controller",
  "scalableGroupType": "APPLICATION",
  "securityGroupTag": 0,
  "state": "ACTIVE",
  "vnAgnostic": true,
  "identitySource": {
    "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
    "instanceId": 10010,
    "authEntityId": 10010,
    "displayName": "10010",
    "authEntityClass": 1738953278,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "state": "INACTIVE",
    "type": "NBAR"
  },
  "indicativeNetworkIdentity": [

  ],
  "networkApplications": [
    {
      "id": "49718ed3-12d0-4cd5-bf07-b80c9f1ff64f",
      "instanceId": 11188,
      "authEntityId": 11188,
      "displayName": "11188",
      "authEntityClass": -217092956,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "applicationGroup": "other",
      "applicationType": "DEFAULT",
      "categoryId": "1fc17362-9334-4c7b-b752-5afdbb574017",
      "createTime": 1517966741871,
      "encrypted": "false",
      "engineId": "13",
      "helpString": "Cisco Network Controller",
      "ignoreConflict": false,
      "lastUpdateTime": 1517966741871,
      "longDescription": "Cisco network controller device and SDN solutions",
      "name": "cisco-controller",
      "nbarId": "1122",
      "p2pTechnology": "false",
      "popularity": 7,
      "rank": 65535,
      "references": "http://www.cisco.com/c/en/us/solutions/software-defined-networking/overview.html",
      "selectorId": "1122",
      "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
      "trafficClass": "OPS_ADMIN_MGMT",
      "tunnel": "false"
    }
  ],
  "networkIdentity": [

  ],
  "parentScalableGroup": {
    "idRef": "77e75842-e98b-4112-ad0c-300db4bb11be"
  },
  "user": [

  ],
  "userGroup": [

  ]
},
{
  "id": "b263ffa8-bd59-4700-9d93-6e21b80fdb6e",
  "instanceId": 4601,
  "authEntityId": 4601,
  "displayName": "4601",
  "authEntityClass": -1909073334,
  "deployPending": "NONE",
  "instanceVersion": 0,
  "createTime": 1517966743093,
  "deployed": false,
  "isSeeded": true,
  "isStale": false,
  "lastUpdateTime": 1517966743093,
  "name": "indiatimes",
  "namespace": "scalablegroup:application",
  "provisioningState": "DEFINED",
  "qualifier": "application",
  "resourceVersion": 0,
  "targetIdList": [

  ],
  "type": "scalablegroup",
  "cfsChangeInfo": [

  ],
  "customProvisions": [

  ],
  "scalableGroupExternalHandle": "indiatimes",
  "scalableGroupType": "APPLICATION",
  "securityGroupTag": 0,
  "state": "ACTIVE",
  "vnAgnostic": true,
  "identitySource": {
    "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
    "instanceId": 10010,
    "authEntityId": 10010,
    "displayName": "10010",
    "authEntityClass": 1738953278,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "state": "INACTIVE",
    "type": "NBAR"
  },
  "indicativeNetworkIdentity": [

  ],
  "networkApplications": [
    {
      "id": "1fd03e1f-4986-472d-b28e-35dfa7bb64b4",
      "instanceId": 11551,
      "authEntityId": 11551,
      "displayName": "11551",
      "authEntityClass": -217092956,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "appProtocol": "tcp/udp",
      "applicationGroup": "other",
      "applicationType": "DEFAULT",
      "categoryId": "878644b5-fe91-45b4-9204-31c84a73ba48",
      "createTime": 1517966743092,
      "encrypted": "false",
      "engineId": "13",
      "helpString": "Indiatimes - Online news portal.",
      "ignoreConflict": false,
      "lastUpdateTime": 1517966743092,
      "longDescription": "Indiatimes - Online news portal.",
      "name": "indiatimes",
      "nbarId": "1561",
      "p2pTechnology": "false",
      "popularity": 3,
      "rank": 65535,
      "references": "http://indiatimes.com",
      "selectorId": "632",
      "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
      "trafficClass": "TRANSACTIONAL_DATA",
      "tunnel": "false"
    }
  ],
  "networkIdentity": [
    {
      "id": "4b088de0-d4c5-436d-8a93-f0587b2b9073",
      "instanceId": 13515,
      "authEntityId": 13515,
      "displayName": "13515",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "53,5353",
      "protocol": "UDP",
      "upperPort": 0
    },
    {
      "id": "28f90a0d-1ffd-402c-a931-7caaebf00886",
      "instanceId": 13516,
      "authEntityId": 13516,
      "displayName": "13516",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "53,80,443,5353",
      "protocol": "TCP",
      "upperPort": 0
    }
  ],
  "parentScalableGroup": {
    "idRef": "28e08de4-d7b2-4521-84ce-bc1e5ee0b9cc"
  },
  "user": [

  ],
  "userGroup": [

  ]
},
{
  "id": "b2650ba4-789c-402a-a354-d0cbff87340e",
  "instanceId": 4557,
  "authEntityId": 4557,
  "displayName": "4557",
  "authEntityClass": -1909073334,
  "deployPending": "NONE",
  "instanceVersion": 0,
  "createTime": 1517966742984,
  "deployed": false,
  "isSeeded": true,
  "isStale": false,
  "lastUpdateTime": 1517966742984,
  "name": "http",
  "namespace": "scalablegroup:application",
  "provisioningState": "DEFINED",
  "qualifier": "application",
  "resourceVersion": 0,
  "targetIdList": [

  ],
  "type": "scalablegroup",
  "cfsChangeInfo": [

  ],
  "customProvisions": [

  ],
  "scalableGroupExternalHandle": "http",
  "scalableGroupType": "APPLICATION",
  "securityGroupTag": 0,
  "state": "ACTIVE",
  "vnAgnostic": true,
  "identitySource": {
    "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
    "instanceId": 10010,
    "authEntityId": 10010,
    "displayName": "10010",
    "authEntityClass": 1738953278,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "state": "INACTIVE",
    "type": "NBAR"
  },
  "indicativeNetworkIdentity": [
    {
      "id": "529633de-f56c-433b-83fd-a372a7e34f6b",
      "instanceId": 13384,
      "authEntityId": 13384,
      "displayName": "13384",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "80",
      "protocol": "TCP",
      "upperPort": 0
    }
  ],
  "networkApplications": [
    {
      "id": "155d128b-dce0-47c8-8104-a6be20422715",
      "instanceId": 11508,
      "authEntityId": 11508,
      "displayName": "11508",
      "authEntityClass": -217092956,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "appProtocol": "tcp",
      "applicationGroup": "other",
      "applicationType": "DEFAULT",
      "categoryId": "878644b5-fe91-45b4-9204-31c84a73ba48",
      "createTime": 1517966742984,
      "encrypted": "false",
      "engineId": "3",
      "helpString": "World Wide Web traffic",
      "ignoreConflict": false,
      "lastUpdateTime": 1517966742984,
      "longDescription": "Hypertext Transfer Protocol (HTTP) is a standard for communication between web browsers and servers over the World Wide Web. The protocol works in a request-response manner over a client server computing model. The server usually listens on port 80.",
      "name": "http",
      "nbarId": "3",
      "p2pTechnology": "false",
      "popularity": 10,
      "rank": 65535,
      "references": "http://www.w3.org/Protocols/rfc2616/rfc2616.html",
      "selectorId": "80",
      "subCategory": "b90786f4-0190-45b8-9924-21b3e9682590",
      "trafficClass": "TRANSACTIONAL_DATA",
      "tunnel": "false"
    }
  ],
  "networkIdentity": [
    {
      "id": "df1f4987-a139-4b5f-914d-143beb017e41",
      "instanceId": 13383,
      "authEntityId": 13383,
      "displayName": "13383",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "80",
      "protocol": "TCP",
      "upperPort": 0
    }
  ],
  "parentScalableGroup": {
    "idRef": "53f03553-069a-4046-ad7c-84610eb579c5"
  },
  "user": [

  ],
  "userGroup": [

  ]
},
{
  "id": "b278a22a-02e5-4a95-abff-cba12e8eb275",
  "instanceId": 4668,
  "authEntityId": 4668,
  "displayName": "4668",
  "authEntityClass": -1909073334,
  "deployPending": "NONE",
  "instanceVersion": 0,
  "createTime": 1517966743244,
  "deployed": false,
  "isSeeded": true,
  "isStale": false,
  "lastUpdateTime": 1517966743244,
  "name": "kakao-talk",
  "namespace": "scalablegroup:application",
  "provisioningState": "DEFINED",
  "qualifier": "application",
  "resourceVersion": 0,
  "targetIdList": [

  ],
  "type": "scalablegroup",
  "cfsChangeInfo": [

  ],
  "customProvisions": [

  ],
  "scalableGroupExternalHandle": "kakao-talk",
  "scalableGroupType": "APPLICATION",
  "securityGroupTag": 0,
  "state": "ACTIVE",
  "vnAgnostic": true,
  "identitySource": {
    "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
    "instanceId": 10010,
    "authEntityId": 10010,
    "displayName": "10010",
    "authEntityClass": 1738953278,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "state": "INACTIVE",
    "type": "NBAR"
  },
  "indicativeNetworkIdentity": [

  ],
  "networkApplications": [
    {
      "id": "f30d41e3-3ac5-49cc-b763-3cd9543ac055",
      "instanceId": 11618,
      "authEntityId": 11618,
      "displayName": "11618",
      "authEntityClass": -217092956,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "appProtocol": "tcp/udp",
      "applicationGroup": "kakao-group",
      "applicationType": "DEFAULT",
      "categoryId": "0f6cab47-be95-4124-9db3-360ccd55f983",
      "createTime": 1517966743244,
      "encrypted": "true",
      "engineId": "13",
      "helpString": "Kakao-Talk is a free messenger application",
      "ignoreConflict": false,
      "lastUpdateTime": 1517966743244,
      "longDescription": "Kakao-Talk is a free mobile messenger application for smartphones with free multimedia messaging and free call features. Kakao-Talk was released by Kakao corporation which is based in Seoul, South Korea. It is available on most common mobile operating systems including IOS, Android and also available on PC by syncing between your PC and mobile device.",
      "name": "kakao-talk",
      "nbarId": "1518",
      "p2pTechnology": "false",
      "popularity": 8,
      "rank": 65535,
      "references": "http://www.kakao.com/talk/",
      "selectorId": "579",
      "subCategory": "118c10a2-e272-47c1-92b9-4814ebe97138",
      "trafficClass": "MULTIMEDIA_CONFERENCING",
      "tunnel": "false"
    }
  ],
  "networkIdentity": [
    {
      "id": "6bc66f5c-db5b-4d96-8846-27214b7fd481",
      "instanceId": 13676,
      "authEntityId": 13676,
      "displayName": "13676",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "53,80,443,5353",
      "protocol": "TCP",
      "upperPort": 0
    },
    {
      "id": "26764ae7-7115-4b63-809f-381887269e4a",
      "instanceId": 13677,
      "authEntityId": 13677,
      "displayName": "13677",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "53,5353",
      "protocol": "UDP",
      "upperPort": 0
    }
  ],
  "parentScalableGroup": {
    "idRef": "fd6264c9-c16a-4311-a335-a3db039256f3"
  },
  "user": [

  ],
  "userGroup": [

  ]
},
{
  "id": "b2a91979-a4b3-483d-9af9-e5bdc4a27bbd",
  "instanceId": 15170,
  "authEntityId": 15170,
  "displayName": "15170",
  "authEntityClass": -1909073334,
  "deployPending": "NONE",
  "instanceVersion": 0,
  "createTime": 1517966744395,
  "deployed": false,
  "isSeeded": true,
  "isStale": false,
  "lastUpdateTime": 1517966744395,
  "name": "snagas",
  "namespace": "scalablegroup:application",
  "provisioningState": "DEFINED",
  "qualifier": "application",
  "resourceVersion": 0,
  "targetIdList": [

  ],
  "type": "scalablegroup",
  "cfsChangeInfo": [

  ],
  "customProvisions": [

  ],
  "scalableGroupExternalHandle": "snagas",
  "scalableGroupType": "APPLICATION",
  "securityGroupTag": 0,
  "state": "ACTIVE",
  "vnAgnostic": true,
  "identitySource": {
    "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
    "instanceId": 10010,
    "authEntityId": 10010,
    "displayName": "10010",
    "authEntityClass": 1738953278,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "state": "INACTIVE",
    "type": "NBAR"
  },
  "indicativeNetworkIdentity": [
    {
      "id": "571f919a-157c-4052-a8e7-de7d836ecaad",
      "instanceId": 17159,
      "authEntityId": 17159,
      "displayName": "17159",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "108",
      "protocol": "UDP",
      "upperPort": 0
    },
    {
      "id": "a9687e37-ae1e-4f6f-88a8-8328ad28268b",
      "instanceId": 17160,
      "authEntityId": 17160,
      "displayName": "17160",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "108",
      "protocol": "TCP",
      "upperPort": 0
    }
  ],
  "networkApplications": [
    {
      "id": "62d1f3d3-a89f-400d-87e6-4713f31e486c",
      "instanceId": 16113,
      "authEntityId": 16113,
      "displayName": "16113",
      "authEntityClass": -217092956,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "appProtocol": "tcp/udp",
      "applicationGroup": "other",
      "applicationType": "DEFAULT",
      "categoryId": "6daa3dfd-3526-407a-88f0-97824b3f3a24",
      "createTime": 1517966744395,
      "encrypted": "false",
      "engineId": "3",
      "helpString": "SNA Gateway Access Server",
      "ignoreConflict": false,
      "lastUpdateTime": 1517966744395,
      "longDescription": "Gateway Access Server (GAS) is part of Systems Network Architecture (SNA), IBM's proprietary networking architecture. It is a complete protocol stack for interconnecting computers and their resources.",
      "name": "snagas",
      "nbarId": "979",
      "p2pTechnology": "false",
      "popularity": 1,
      "rank": 65535,
      "references": "http://publib.boulder.ibm.com/infocenter/zos/basics/index.jsp?topic=/com.ibm.zos.znetwork/znetwork_151.htm",
      "selectorId": "108",
      "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
      "trafficClass": "BULK_DATA",
      "tunnel": "false"
    }
  ],
  "networkIdentity": [
    {
      "id": "8c08365d-6147-4fa6-92e4-6518425fd182",
      "instanceId": 17157,
      "authEntityId": 17157,
      "displayName": "17157",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "108",
      "protocol": "UDP",
      "upperPort": 0
    },
    {
      "id": "28b3f93d-8e93-434d-b603-505b82520191",
      "instanceId": 17158,
      "authEntityId": 17158,
      "displayName": "17158",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "108",
      "protocol": "TCP",
      "upperPort": 0
    }
  ],
  "parentScalableGroup": {
    "idRef": "000c15bf-28fe-4fbb-9b69-35e6502721f1"
  },
  "user": [

  ],
  "userGroup": [

  ]
},
{
  "id": "b2bd5454-bb5c-47e6-be9f-d452bd12adf1",
  "instanceId": 4800,
  "authEntityId": 4800,
  "displayName": "4800",
  "authEntityClass": -1909073334,
  "deployPending": "NONE",
  "instanceVersion": 0,
  "createTime": 1517966743518,
  "deployed": false,
  "isSeeded": true,
  "isStale": false,
  "lastUpdateTime": 1517966743518,
  "name": "msg-icp",
  "namespace": "scalablegroup:application",
  "provisioningState": "DEFINED",
  "qualifier": "application",
  "resourceVersion": 0,
  "targetIdList": [

  ],
  "type": "scalablegroup",
  "cfsChangeInfo": [

  ],
  "customProvisions": [

  ],
  "scalableGroupExternalHandle": "msg-icp",
  "scalableGroupType": "APPLICATION",
  "securityGroupTag": 0,
  "state": "ACTIVE",
  "vnAgnostic": true,
  "identitySource": {
    "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
    "instanceId": 10010,
    "authEntityId": 10010,
    "displayName": "10010",
    "authEntityClass": 1738953278,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "state": "INACTIVE",
    "type": "NBAR"
  },
  "indicativeNetworkIdentity": [
    {
      "id": "09f09c51-0626-4ff4-a130-c051cad6ac8e",
      "instanceId": 14048,
      "authEntityId": 14048,
      "displayName": "14048",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "29",
      "protocol": "UDP",
      "upperPort": 0
    },
    {
      "id": "da914914-f67f-444d-ada3-b7b40050a058",
      "instanceId": 14047,
      "authEntityId": 14047,
      "displayName": "14047",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "29",
      "protocol": "TCP",
      "upperPort": 0
    }
  ],
  "networkApplications": [
    {
      "id": "2856c628-6185-4290-af2e-000ae5615257",
      "instanceId": 11749,
      "authEntityId": 11749,
      "displayName": "11749",
      "authEntityClass": -217092956,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "appProtocol": "tcp/udp",
      "applicationGroup": "other",
      "applicationType": "DEFAULT",
      "categoryId": "6daa3dfd-3526-407a-88f0-97824b3f3a24",
      "createTime": 1517966743518,
      "encrypted": "false",
      "engineId": "3",
      "helpString": "msg-icp",
      "ignoreConflict": false,
      "lastUpdateTime": 1517966743518,
      "longDescription": "Registered with IANA on port 29 TCP/UDP",
      "name": "msg-icp",
      "nbarId": "913",
      "p2pTechnology": "false",
      "popularity": 1,
      "rank": 65535,
      "references": "http://www.iana.org/assignments/service-names-port-numbers/service-names-port-numbers.xml",
      "selectorId": "29",
      "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
      "trafficClass": "BULK_DATA",
      "tunnel": "false"
    }
  ],
  "networkIdentity": [
    {
      "id": "0054bdf2-0850-442a-88c0-0c2b29dc713f",
      "instanceId": 14045,
      "authEntityId": 14045,
      "displayName": "14045",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "29",
      "protocol": "TCP",
      "upperPort": 0
    },
    {
      "id": "8301ffe9-b863-4597-a239-484dd415e833",
      "instanceId": 14046,
      "authEntityId": 14046,
      "displayName": "14046",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "29",
      "protocol": "UDP",
      "upperPort": 0
    }
  ],
  "parentScalableGroup": {
    "idRef": "000c15bf-28fe-4fbb-9b69-35e6502721f1"
  },
  "user": [

  ],
  "userGroup": [

  ]
},
{
  "id": "b326b549-1130-4bb6-91d5-1bef7f7db478",
  "instanceId": 4381,
  "authEntityId": 4381,
  "displayName": "4381",
  "authEntityClass": -1909073334,
  "deployPending": "NONE",
  "instanceVersion": 0,
  "createTime": 1517966742473,
  "deployed": false,
  "isSeeded": true,
  "isStale": false,
  "lastUpdateTime": 1517966742473,
  "name": "dns",
  "namespace": "scalablegroup:application",
  "provisioningState": "DEFINED",
  "qualifier": "application",
  "resourceVersion": 0,
  "targetIdList": [

  ],
  "type": "scalablegroup",
  "cfsChangeInfo": [

  ],
  "customProvisions": [

  ],
  "scalableGroupExternalHandle": "dns",
  "scalableGroupType": "APPLICATION",
  "securityGroupTag": 0,
  "state": "ACTIVE",
  "vnAgnostic": true,
  "identitySource": {
    "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
    "instanceId": 10010,
    "authEntityId": 10010,
    "displayName": "10010",
    "authEntityClass": 1738953278,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "state": "INACTIVE",
    "type": "NBAR"
  },
  "indicativeNetworkIdentity": [
    {
      "id": "c9556639-e088-440d-869b-a876aa37c8b4",
      "instanceId": 12936,
      "authEntityId": 12936,
      "displayName": "12936",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "53,5353",
      "protocol": "UDP",
      "upperPort": 0
    },
    {
      "id": "1ae9ec96-a44a-4e65-848b-d217fddc9cd2",
      "instanceId": 12937,
      "authEntityId": 12937,
      "displayName": "12937",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "53,5353",
      "protocol": "TCP",
      "upperPort": 0
    }
  ],
  "networkApplications": [
    {
      "id": "83509f8f-a518-484c-9ac8-957ce86356b2",
      "instanceId": 11332,
      "authEntityId": 11332,
      "displayName": "11332",
      "authEntityClass": -217092956,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "appProtocol": "tcp/udp",
      "applicationGroup": "other",
      "applicationType": "DEFAULT",
      "categoryId": "1fc17362-9334-4c7b-b752-5afdbb574017",
      "createTime": 1517966742473,
      "encrypted": "false",
      "engineId": "3",
      "helpString": "Domain Name Server lookup",
      "ignoreConflict": false,
      "lastUpdateTime": 1517966742473,
      "longDescription": "Domain Name Server (DNS) is a server that translates URLs into IP addresses based on client queries. It is based on client-server architecture.",
      "name": "dns",
      "nbarId": "72",
      "p2pTechnology": "false",
      "popularity": 10,
      "rank": 65535,
      "references": "https://www1.ietf.org/rfc/rfc1035.txt",
      "selectorId": "53",
      "subCategory": "7f5fa07d-fcb4-4217-9908-b438066c0b60",
      "trafficClass": "OPS_ADMIN_MGMT",
      "tunnel": "false"
    }
  ],
  "networkIdentity": [
    {
      "id": "318480f3-3285-44c1-b87c-87f340cbea9e",
      "instanceId": 12934,
      "authEntityId": 12934,
      "displayName": "12934",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "53,80,5353",
      "protocol": "TCP",
      "upperPort": 0
    },
    {
      "id": "4a11f551-d6b5-4b7e-8916-82bbb4ce20fe",
      "instanceId": 12935,
      "authEntityId": 12935,
      "displayName": "12935",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "53,5353",
      "protocol": "UDP",
      "upperPort": 0
    }
  ],
  "parentScalableGroup": {
    "idRef": "38b9eb6b-f3b6-4e13-9b79-a11e0c6423d4"
  },
  "user": [

  ],
  "userGroup": [

  ]
},
{
  "id": "b3621425-8f91-439a-b2f5-3b3c76ff6f33",
  "instanceId": 15173,
  "authEntityId": 15173,
  "displayName": "15173",
  "authEntityClass": -1909073334,
  "deployPending": "NONE",
  "instanceVersion": 0,
  "createTime": 1517966744402,
  "deployed": false,
  "isSeeded": true,
  "isStale": false,
  "lastUpdateTime": 1517966744402,
  "name": "snp",
  "namespace": "scalablegroup:application",
  "provisioningState": "DEFINED",
  "qualifier": "application",
  "resourceVersion": 0,
  "targetIdList": [

  ],
  "type": "scalablegroup",
  "cfsChangeInfo": [

  ],
  "customProvisions": [

  ],
  "scalableGroupExternalHandle": "snp",
  "scalableGroupType": "APPLICATION",
  "securityGroupTag": 0,
  "state": "ACTIVE",
  "vnAgnostic": true,
  "identitySource": {
    "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
    "instanceId": 10010,
    "authEntityId": 10010,
    "displayName": "10010",
    "authEntityClass": 1738953278,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "state": "INACTIVE",
    "type": "NBAR"
  },
  "indicativeNetworkIdentity": [
    {
      "id": "29250f77-96e0-43de-896e-d5206917ee60",
      "instanceId": 17170,
      "authEntityId": 17170,
      "displayName": "17170",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipProtocolNumbers": "109",
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "protocol": "SNP",
      "upperPort": 0
    }
  ],
  "networkApplications": [
    {
      "id": "3941f6a0-8008-40f7-b3db-4aeb8f41cdbd",
      "instanceId": 16116,
      "authEntityId": 16116,
      "displayName": "16116",
      "authEntityClass": -217092956,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "appProtocol": "ip",
      "applicationGroup": "other",
      "applicationType": "DEFAULT",
      "categoryId": "6daa3dfd-3526-407a-88f0-97824b3f3a24",
      "createTime": 1517966744402,
      "encrypted": "unassigned",
      "engineId": "1",
      "helpString": "Sitara Networks Protocol",
      "ignoreConflict": false,
      "lastUpdateTime": 1517966744402,
      "longDescription": "The Sitara Network Protocol (SNP) is a network control protocol that adds end-to-end intelligence to IP networks. It allows features such as quality of service control and load balance.",
      "name": "snp",
      "nbarId": "863",
      "p2pTechnology": "unassigned",
      "popularity": 2,
      "rank": 65535,
      "references": "http://www.dejean.com/mc/sitara/tech_snp-paper.html",
      "selectorId": "109",
      "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
      "trafficClass": "BULK_DATA",
      "tunnel": "unassigned"
    }
  ],
  "networkIdentity": [
    {
      "id": "b7379ca5-b39c-4be5-8a70-e3717ef51bee",
      "instanceId": 17169,
      "authEntityId": 17169,
      "displayName": "17169",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipProtocolNumbers": "109",
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "protocol": "SNP",
      "upperPort": 0
    }
  ],
  "parentScalableGroup": {
    "idRef": "000c15bf-28fe-4fbb-9b69-35e6502721f1"
  },
  "user": [

  ],
  "userGroup": [

  ]
},
{
  "id": "b3a9c639-7690-44bf-ba51-057de0a2ca2c",
  "instanceId": 4526,
  "authEntityId": 4526,
  "displayName": "4526",
  "authEntityClass": -1909073334,
  "deployPending": "NONE",
  "instanceVersion": 0,
  "createTime": 1517966742892,
  "deployed": false,
  "isSeeded": true,
  "isStale": false,
  "lastUpdateTime": 1517966742892,
  "name": "hangouts",
  "namespace": "scalablegroup:application",
  "provisioningState": "DEFINED",
  "qualifier": "application",
  "resourceVersion": 0,
  "targetIdList": [

  ],
  "type": "scalablegroup",
  "cfsChangeInfo": [

  ],
  "customProvisions": [

  ],
  "scalableGroupExternalHandle": "hangouts",
  "scalableGroupType": "APPLICATION",
  "securityGroupTag": 0,
  "state": "ACTIVE",
  "vnAgnostic": true,
  "identitySource": {
    "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
    "instanceId": 10010,
    "authEntityId": 10010,
    "displayName": "10010",
    "authEntityClass": 1738953278,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "state": "INACTIVE",
    "type": "NBAR"
  },
  "indicativeNetworkIdentity": [

  ],
  "networkApplications": [
    {
      "id": "3e4934c5-4b97-4926-8528-aaac12786087",
      "instanceId": 11477,
      "authEntityId": 11477,
      "displayName": "11477",
      "authEntityClass": -217092956,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "appProtocol": "tcp/udp",
      "applicationGroup": "hangouts-group",
      "applicationType": "DEFAULT",
      "categoryId": "0f6cab47-be95-4124-9db3-360ccd55f983",
      "createTime": 1517966742892,
      "encrypted": "true",
      "engineId": "13",
      "helpString": "Hangouts is a communication platform which includes IM,video chat,SMS and VOIP.",
      "ignoreConflict": false,
      "lastUpdateTime": 1517966742892,
      "longDescription": "Google Hangouts is a communication platform developed by Google which includes instant messaging, video chat, SMS and VOIP features",
      "name": "hangouts",
      "nbarId": "1087",
      "p2pTechnology": "false",
      "popularity": 10,
      "rank": 65535,
      "references": "https://hangouts.google.com/",
      "selectorId": "1087",
      "subCategory": "118c10a2-e272-47c1-92b9-4814ebe97138",
      "trafficClass": "MULTIMEDIA_CONFERENCING",
      "tunnel": "false"
    }
  ],
  "networkIdentity": [
    {
      "id": "5accb4f0-642f-41ad-8104-5f395876029a",
      "instanceId": 13309,
      "authEntityId": 13309,
      "displayName": "13309",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "53,80,443,5353",
      "protocol": "TCP",
      "upperPort": 0
    },
    {
      "id": "5406eedc-b2f0-4350-a6fc-b9a4f384ad22",
      "instanceId": 13310,
      "authEntityId": 13310,
      "displayName": "13310",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "53,5353",
      "protocol": "UDP",
      "upperPort": 0
    }
  ],
  "parentScalableGroup": {
    "idRef": "82348eb1-d8b8-4daf-8532-15e1a5f9cdd0"
  },
  "user": [

  ],
  "userGroup": [

  ]
},
{
  "id": "b3b0ccee-f9ab-49cd-a438-1010c1fac8ae",
  "instanceId": 15296,
  "authEntityId": 15296,
  "displayName": "15296",
  "authEntityClass": -1909073334,
  "deployPending": "NONE",
  "instanceVersion": 0,
  "createTime": 1517966744680,
  "deployed": false,
  "isSeeded": true,
  "isStale": false,
  "lastUpdateTime": 1517966744680,
  "name": "tns-cml",
  "namespace": "scalablegroup:application",
  "provisioningState": "DEFINED",
  "qualifier": "application",
  "resourceVersion": 0,
  "targetIdList": [

  ],
  "type": "scalablegroup",
  "cfsChangeInfo": [

  ],
  "customProvisions": [

  ],
  "scalableGroupExternalHandle": "tns-cml",
  "scalableGroupType": "APPLICATION",
  "securityGroupTag": 0,
  "state": "ACTIVE",
  "vnAgnostic": true,
  "identitySource": {
    "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
    "instanceId": 10010,
    "authEntityId": 10010,
    "displayName": "10010",
    "authEntityClass": 1738953278,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "state": "INACTIVE",
    "type": "NBAR"
  },
  "indicativeNetworkIdentity": [
    {
      "id": "d5d9ee37-e6d8-4bef-adb2-313fbc5feb7e",
      "instanceId": 17520,
      "authEntityId": 17520,
      "displayName": "17520",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "590",
      "protocol": "UDP",
      "upperPort": 0
    },
    {
      "id": "c2d0a812-5aac-4d13-8178-84ba6aa1bf55",
      "instanceId": 17519,
      "authEntityId": 17519,
      "displayName": "17519",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "590",
      "protocol": "TCP",
      "upperPort": 0
    }
  ],
  "networkApplications": [
    {
      "id": "cb6d0927-881c-4754-984c-d97426e0d51c",
      "instanceId": 16239,
      "authEntityId": 16239,
      "displayName": "16239",
      "authEntityClass": -217092956,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "appProtocol": "tcp/udp",
      "applicationGroup": "other",
      "applicationType": "DEFAULT",
      "categoryId": "6daa3dfd-3526-407a-88f0-97824b3f3a24",
      "createTime": 1517966744680,
      "encrypted": "false",
      "engineId": "3",
      "helpString": "tns-cml",
      "ignoreConflict": false,
      "lastUpdateTime": 1517966744680,
      "longDescription": "Registered with IANA on port 590 TCP/UDP",
      "name": "tns-cml",
      "nbarId": "504",
      "p2pTechnology": "false",
      "popularity": 1,
      "rank": 65535,
      "references": "http://www.iana.org/assignments/service-names-port-numbers/service-names-port-numbers.xml",
      "selectorId": "590",
      "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
      "trafficClass": "BULK_DATA",
      "tunnel": "false"
    }
  ],
  "networkIdentity": [
    {
      "id": "caee965d-5d84-401b-ad3b-61aba9f056d9",
      "instanceId": 17517,
      "authEntityId": 17517,
      "displayName": "17517",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "590",
      "protocol": "TCP",
      "upperPort": 0
    },
    {
      "id": "036d943e-3f8c-4134-aa78-0de64468cd4d",
      "instanceId": 17518,
      "authEntityId": 17518,
      "displayName": "17518",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "590",
      "protocol": "UDP",
      "upperPort": 0
    }
  ],
  "parentScalableGroup": {
    "idRef": "000c15bf-28fe-4fbb-9b69-35e6502721f1"
  },
  "user": [

  ],
  "userGroup": [

  ]
},
{
  "id": "b3f3f088-ff65-4b65-bbd2-d391a7ad11aa",
  "instanceId": 15105,
  "authEntityId": 15105,
  "displayName": "15105",
  "authEntityClass": -1909073334,
  "deployPending": "NONE",
  "instanceVersion": 0,
  "createTime": 1517966744245,
  "deployed": false,
  "isSeeded": true,
  "isStale": false,
  "lastUpdateTime": 1517966744245,
  "name": "sctp",
  "namespace": "scalablegroup:application",
  "provisioningState": "DEFINED",
  "qualifier": "application",
  "resourceVersion": 0,
  "targetIdList": [

  ],
  "type": "scalablegroup",
  "cfsChangeInfo": [

  ],
  "customProvisions": [

  ],
  "scalableGroupExternalHandle": "sctp",
  "scalableGroupType": "APPLICATION",
  "securityGroupTag": 0,
  "state": "ACTIVE",
  "vnAgnostic": true,
  "identitySource": {
    "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
    "instanceId": 10010,
    "authEntityId": 10010,
    "displayName": "10010",
    "authEntityClass": 1738953278,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "state": "INACTIVE",
    "type": "NBAR"
  },
  "indicativeNetworkIdentity": [
    {
      "id": "ebc8d393-d52d-4723-9020-3506e80cfbd0",
      "instanceId": 14953,
      "authEntityId": 14953,
      "displayName": "14953",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipProtocolNumbers": "132",
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "protocol": "SCTP",
      "upperPort": 0
    }
  ],
  "networkApplications": [
    {
      "id": "b8f5aa68-a132-42fb-8c7a-9e5cc1b37fe0",
      "instanceId": 16048,
      "authEntityId": 16048,
      "displayName": "16048",
      "authEntityClass": -217092956,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "appProtocol": "ip",
      "applicationGroup": "other",
      "applicationType": "DEFAULT",
      "categoryId": "6daa3dfd-3526-407a-88f0-97824b3f3a24",
      "createTime": 1517966744245,
      "encrypted": "false",
      "engineId": "1",
      "helpString": "Stream Control Transmission Protocol",
      "ignoreConflict": false,
      "lastUpdateTime": 1517966744245,
      "longDescription": "The Stream Control Transmission Protocol (SCTP) is a transport layer protocol, serving in a similar role to the popular protocols Transmission Control Protocol (TCP) and User Datagram Protocol (UDP). It provides some of the same service features of both: it is message-oriented like UDP and ensures reliable, in-sequence transport of messages with congestion control like TCP.",
      "name": "sctp",
      "nbarId": "1230",
      "p2pTechnology": "false",
      "popularity": 2,
      "rank": 65535,
      "references": "http://www.ietf.org/rfc/rfc3286.txt",
      "selectorId": "132",
      "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
      "trafficClass": "BULK_DATA",
      "tunnel": "false"
    }
  ],
  "networkIdentity": [
    {
      "id": "299efd45-e518-4c31-a1ca-9cf02e1bef7a",
      "instanceId": 14952,
      "authEntityId": 14952,
      "displayName": "14952",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipProtocolNumbers": "132",
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "protocol": "SCTP",
      "upperPort": 0
    }
  ],
  "parentScalableGroup": {
    "idRef": "000c15bf-28fe-4fbb-9b69-35e6502721f1"
  },
  "user": [

  ],
  "userGroup": [

  ]
},
{
  "id": "b3fbc587-63ff-4371-a782-fa00fb6d5fef",
  "instanceId": 4092,
  "authEntityId": 4092,
  "displayName": "4092",
  "authEntityClass": -1909073334,
  "deployPending": "NONE",
  "instanceVersion": 0,
  "createTime": 1517966741021,
  "deployed": false,
  "isSeeded": true,
  "isStale": false,
  "lastUpdateTime": 1517966741021,
  "name": "aodv",
  "namespace": "scalablegroup:application",
  "provisioningState": "DEFINED",
  "qualifier": "application",
  "resourceVersion": 0,
  "targetIdList": [

  ],
  "type": "scalablegroup",
  "cfsChangeInfo": [

  ],
  "customProvisions": [

  ],
  "scalableGroupExternalHandle": "aodv",
  "scalableGroupType": "APPLICATION",
  "securityGroupTag": 0,
  "state": "ACTIVE",
  "vnAgnostic": true,
  "identitySource": {
    "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
    "instanceId": 10010,
    "authEntityId": 10010,
    "displayName": "10010",
    "authEntityClass": 1738953278,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "state": "INACTIVE",
    "type": "NBAR"
  },
  "indicativeNetworkIdentity": [
    {
      "id": "c65d9cda-9fbd-4f54-98e4-b15535f05080",
      "instanceId": 12144,
      "authEntityId": 12144,
      "displayName": "12144",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "654",
      "protocol": "TCP",
      "upperPort": 0
    },
    {
      "id": "cf647bce-4b0b-4f5a-9532-dff67678ff10",
      "instanceId": 12145,
      "authEntityId": 12145,
      "displayName": "12145",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "654",
      "protocol": "UDP",
      "upperPort": 0
    }
  ],
  "networkApplications": [
    {
      "id": "b22e5e5e-79de-4f91-9f43-c8b67ea8d527",
      "instanceId": 11056,
      "authEntityId": 11056,
      "displayName": "11056",
      "authEntityClass": -217092956,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "appProtocol": "tcp/udp",
      "applicationGroup": "other",
      "applicationType": "DEFAULT",
      "categoryId": "1fc17362-9334-4c7b-b752-5afdbb574017",
      "createTime": 1517966741021,
      "encrypted": "false",
      "engineId": "3",
      "helpString": "AODV",
      "ignoreConflict": false,
      "lastUpdateTime": 1517966741021,
      "longDescription": "Ad hoc On-Demand Distance Vector (AODV) is a routing protocol for mobile ad hoc networks (MANETs) and other wireless ad-hoc networks.",
      "name": "aodv",
      "nbarId": "563",
      "p2pTechnology": "false",
      "popularity": 1,
      "rank": 65535,
      "references": "http://www.ietf.org/rfc/rfc3561.txt",
      "selectorId": "654",
      "subCategory": "7cc1b3a6-4102-44e9-b522-0e71afe39a03",
      "trafficClass": "NETWORK_CONTROL",
      "tunnel": "false"
    }
  ],
  "networkIdentity": [
    {
      "id": "3e0b4db2-2b80-45de-a623-d5d05dd11d89",
      "instanceId": 12142,
      "authEntityId": 12142,
      "displayName": "12142",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "654",
      "protocol": "TCP",
      "upperPort": 0
    },
    {
      "id": "b2b379f1-a4b0-4099-9701-368659c149e7",
      "instanceId": 12143,
      "authEntityId": 12143,
      "displayName": "12143",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "654",
      "protocol": "UDP",
      "upperPort": 0
    }
  ],
  "parentScalableGroup": {
    "idRef": "77e75842-e98b-4112-ad0c-300db4bb11be"
  },
  "user": [

  ],
  "userGroup": [

  ]
},
{
  "id": "b403805e-39f2-4d7c-b9d1-4aafd249bf25",
  "instanceId": 4299,
  "authEntityId": 4299,
  "displayName": "4299",
  "authEntityClass": -1909073334,
  "deployPending": "NONE",
  "instanceVersion": 0,
  "createTime": 1517966742199,
  "deployed": false,
  "isSeeded": true,
  "isStale": false,
  "lastUpdateTime": 1517966742199,
  "name": "craigslist",
  "namespace": "scalablegroup:application",
  "provisioningState": "DEFINED",
  "qualifier": "application",
  "resourceVersion": 0,
  "targetIdList": [

  ],
  "type": "scalablegroup",
  "cfsChangeInfo": [

  ],
  "customProvisions": [

  ],
  "scalableGroupExternalHandle": "craigslist",
  "scalableGroupType": "APPLICATION",
  "securityGroupTag": 0,
  "state": "ACTIVE",
  "vnAgnostic": true,
  "identitySource": {
    "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
    "instanceId": 10010,
    "authEntityId": 10010,
    "displayName": "10010",
    "authEntityClass": 1738953278,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "state": "INACTIVE",
    "type": "NBAR"
  },
  "indicativeNetworkIdentity": [

  ],
  "networkApplications": [
    {
      "id": "f683ad9f-8085-4340-957c-7df40d3b4471",
      "instanceId": 11252,
      "authEntityId": 11252,
      "displayName": "11252",
      "authEntityClass": -217092956,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "appProtocol": "tcp/udp",
      "applicationGroup": "other",
      "applicationType": "DEFAULT",
      "categoryId": "878644b5-fe91-45b4-9204-31c84a73ba48",
      "createTime": 1517966742199,
      "encrypted": "false",
      "engineId": "13",
      "helpString": "Craigslist - Popular online classifieds.",
      "ignoreConflict": false,
      "lastUpdateTime": 1517966742199,
      "longDescription": "Craigslist - Popular online classifieds.",
      "name": "craigslist",
      "nbarId": "1068",
      "p2pTechnology": "false",
      "popularity": 3,
      "rank": 65535,
      "references": "http://craigslist.org",
      "selectorId": "617",
      "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
      "trafficClass": "TRANSACTIONAL_DATA",
      "tunnel": "false"
    }
  ],
  "networkIdentity": [
    {
      "id": "c3451875-c24f-418f-8fd6-460abdef0da5",
      "instanceId": 12669,
      "authEntityId": 12669,
      "displayName": "12669",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "53,5353",
      "protocol": "UDP",
      "upperPort": 0
    },
    {
      "id": "7e7116a5-9001-4d7c-9822-cf2aff236bb3",
      "instanceId": 12670,
      "authEntityId": 12670,
      "displayName": "12670",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "53,80,443,5353",
      "protocol": "TCP",
      "upperPort": 0
    }
  ],
  "parentScalableGroup": {
    "idRef": "28e08de4-d7b2-4521-84ce-bc1e5ee0b9cc"
  },
  "user": [

  ],
  "userGroup": [

  ]
},
{
  "id": "b408598e-d021-471f-93f9-181b7b84eeaf",
  "instanceId": 15238,
  "authEntityId": 15238,
  "displayName": "15238",
  "authEntityClass": -1909073334,
  "deployPending": "NONE",
  "instanceVersion": 0,
  "createTime": 1517966744557,
  "deployed": false,
  "isSeeded": true,
  "isStale": false,
  "lastUpdateTime": 1517966744557,
  "name": "swagbucks",
  "namespace": "scalablegroup:application",
  "provisioningState": "DEFINED",
  "qualifier": "application",
  "resourceVersion": 0,
  "targetIdList": [

  ],
  "type": "scalablegroup",
  "cfsChangeInfo": [

  ],
  "customProvisions": [

  ],
  "scalableGroupExternalHandle": "swagbucks",
  "scalableGroupType": "APPLICATION",
  "securityGroupTag": 0,
  "state": "ACTIVE",
  "vnAgnostic": true,
  "identitySource": {
    "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
    "instanceId": 10010,
    "authEntityId": 10010,
    "displayName": "10010",
    "authEntityClass": 1738953278,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "state": "INACTIVE",
    "type": "NBAR"
  },
  "indicativeNetworkIdentity": [

  ],
  "networkApplications": [
    {
      "id": "5fc5596b-9379-4d36-8f5d-edfcc5bee8f6",
      "instanceId": 16181,
      "authEntityId": 16181,
      "displayName": "16181",
      "authEntityClass": -217092956,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "appProtocol": "tcp/udp",
      "applicationGroup": "other",
      "applicationType": "DEFAULT",
      "categoryId": "878644b5-fe91-45b4-9204-31c84a73ba48",
      "createTime": 1517966744556,
      "encrypted": "false",
      "engineId": "13",
      "helpString": "Swagbucks - Online rewards program.",
      "ignoreConflict": false,
      "lastUpdateTime": 1517966744556,
      "longDescription": "Swagbucks - Online rewards program.",
      "name": "swagbucks",
      "nbarId": "1697",
      "p2pTechnology": "false",
      "popularity": 3,
      "rank": 65535,
      "references": "http://swagbucks.com",
      "selectorId": "767",
      "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
      "trafficClass": "TRANSACTIONAL_DATA",
      "tunnel": "false"
    }
  ],
  "networkIdentity": [
    {
      "id": "635cf4be-8831-4ebc-8025-f48a5e67144b",
      "instanceId": 17352,
      "authEntityId": 17352,
      "displayName": "17352",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "53,5353",
      "protocol": "UDP",
      "upperPort": 0
    },
    {
      "id": "725283d6-7439-4dca-a413-864659105a1a",
      "instanceId": 17353,
      "authEntityId": 17353,
      "displayName": "17353",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "53,80,443,5353",
      "protocol": "TCP",
      "upperPort": 0
    }
  ],
  "parentScalableGroup": {
    "idRef": "28e08de4-d7b2-4521-84ce-bc1e5ee0b9cc"
  },
  "user": [

  ],
  "userGroup": [

  ]
},
{
  "id": "b41e999d-a060-4cd5-b71e-1c5b55002961",
  "instanceId": 4904,
  "authEntityId": 4904,
  "displayName": "4904",
  "authEntityClass": -1909073334,
  "deployPending": "NONE",
  "instanceVersion": 0,
  "createTime": 1517966743721,
  "deployed": false,
  "isSeeded": true,
  "isStale": false,
  "lastUpdateTime": 1517966743721,
  "name": "olsr",
  "namespace": "scalablegroup:application",
  "provisioningState": "DEFINED",
  "qualifier": "application",
  "resourceVersion": 0,
  "targetIdList": [

  ],
  "type": "scalablegroup",
  "cfsChangeInfo": [

  ],
  "customProvisions": [

  ],
  "scalableGroupExternalHandle": "olsr",
  "scalableGroupType": "APPLICATION",
  "securityGroupTag": 0,
  "state": "ACTIVE",
  "vnAgnostic": true,
  "identitySource": {
    "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
    "instanceId": 10010,
    "authEntityId": 10010,
    "displayName": "10010",
    "authEntityClass": 1738953278,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "state": "INACTIVE",
    "type": "NBAR"
  },
  "indicativeNetworkIdentity": [
    {
      "id": "a4a1e91d-cc9c-4d5b-8058-22fc8e1f00af",
      "instanceId": 14368,
      "authEntityId": 14368,
      "displayName": "14368",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "698",
      "protocol": "TCP",
      "upperPort": 0
    },
    {
      "id": "d37554c7-f8c8-4828-8b4b-1894c5099f7c",
      "instanceId": 14369,
      "authEntityId": 14369,
      "displayName": "14369",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "698",
      "protocol": "UDP",
      "upperPort": 0
    }
  ],
  "networkApplications": [
    {
      "id": "13c92ecf-6991-447f-a9d4-86fd0fa8cb96",
      "instanceId": 11853,
      "authEntityId": 11853,
      "displayName": "11853",
      "authEntityClass": -217092956,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "appProtocol": "tcp/udp",
      "applicationGroup": "other",
      "applicationType": "DEFAULT",
      "categoryId": "1fc17362-9334-4c7b-b752-5afdbb574017",
      "createTime": 1517966743721,
      "encrypted": "false",
      "engineId": "3",
      "helpString": "olsr",
      "ignoreConflict": false,
      "lastUpdateTime": 1517966743721,
      "longDescription": "The Optimized Link State Routing Protocol (OLSR) is an IP routing protocol optimized for mobile ad-hoc networks, which can also be used on other wireless ad-hoc networks. OLSR is a proactive link-state routing protocol, which uses hello and topology control (TC) messages to discover and then disseminate link state information throughout the mobile ad-hoc network. Individual nodes use this topology information to compute next hop destinations for all nodes in the network using shortest hop forwarding paths.",
      "name": "olsr",
      "nbarId": "606",
      "p2pTechnology": "false",
      "popularity": 1,
      "rank": 65535,
      "references": "http://tools.ietf.org/html/rfc3626",
      "selectorId": "698",
      "subCategory": "7cc1b3a6-4102-44e9-b522-0e71afe39a03",
      "trafficClass": "NETWORK_CONTROL",
      "tunnel": "false"
    }
  ],
  "networkIdentity": [
    {
      "id": "649687d1-2c11-4012-98f1-b8d326dd44bb",
      "instanceId": 14366,
      "authEntityId": 14366,
      "displayName": "14366",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "698",
      "protocol": "TCP",
      "upperPort": 0
    },
    {
      "id": "b6775df9-31e2-4120-9936-20511bc5aa3b",
      "instanceId": 14367,
      "authEntityId": 14367,
      "displayName": "14367",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "698",
      "protocol": "UDP",
      "upperPort": 0
    }
  ],
  "parentScalableGroup": {
    "idRef": "77e75842-e98b-4112-ad0c-300db4bb11be"
  },
  "user": [

  ],
  "userGroup": [

  ]
},
{
  "id": "b42bdcea-bd81-45df-9478-86df3bcc944e",
  "instanceId": 4898,
  "authEntityId": 4898,
  "displayName": "4898",
  "authEntityClass": -1909073334,
  "deployPending": "NONE",
  "instanceVersion": 0,
  "createTime": 1517966743709,
  "deployed": false,
  "isSeeded": true,
  "isStale": false,
  "lastUpdateTime": 1517966743709,
  "name": "ocs_cmu",
  "namespace": "scalablegroup:application",
  "provisioningState": "DEFINED",
  "qualifier": "application",
  "resourceVersion": 0,
  "targetIdList": [

  ],
  "type": "scalablegroup",
  "cfsChangeInfo": [

  ],
  "customProvisions": [

  ],
  "scalableGroupExternalHandle": "ocs_cmu",
  "scalableGroupType": "APPLICATION",
  "securityGroupTag": 0,
  "state": "ACTIVE",
  "vnAgnostic": true,
  "identitySource": {
    "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
    "instanceId": 10010,
    "authEntityId": 10010,
    "displayName": "10010",
    "authEntityClass": 1738953278,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "state": "INACTIVE",
    "type": "NBAR"
  },
  "indicativeNetworkIdentity": [
    {
      "id": "7b92472a-4736-415e-9c8b-d4ffd43bafa0",
      "instanceId": 14349,
      "authEntityId": 14349,
      "displayName": "14349",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "428",
      "protocol": "TCP",
      "upperPort": 0
    },
    {
      "id": "3322f2e0-f315-4b05-ac97-51d97998028e",
      "instanceId": 14350,
      "authEntityId": 14350,
      "displayName": "14350",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "428",
      "protocol": "UDP",
      "upperPort": 0
    }
  ],
  "networkApplications": [
    {
      "id": "8ed192ce-caaa-4f3e-bd16-51cb0eecc638",
      "instanceId": 11847,
      "authEntityId": 11847,
      "displayName": "11847",
      "authEntityClass": -217092956,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "appProtocol": "tcp/udp",
      "applicationGroup": "other",
      "applicationType": "DEFAULT",
      "categoryId": "6daa3dfd-3526-407a-88f0-97824b3f3a24",
      "createTime": 1517966743709,
      "encrypted": "false",
      "engineId": "3",
      "helpString": "ocs_cmu",
      "ignoreConflict": false,
      "lastUpdateTime": 1517966743709,
      "longDescription": "Registered with IANA on port 428 TCP/UDP",
      "name": "ocs_cmu",
      "nbarId": "343",
      "p2pTechnology": "false",
      "popularity": 1,
      "rank": 65535,
      "references": "http://www.iana.org/assignments/service-names-port-numbers/service-names-port-numbers.xml",
      "selectorId": "428",
      "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
      "trafficClass": "BULK_DATA",
      "tunnel": "false"
    }
  ],
  "networkIdentity": [
    {
      "id": "e9ff1eb4-fde5-40e6-bd75-01a840e845e8",
      "instanceId": 14347,
      "authEntityId": 14347,
      "displayName": "14347",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "428",
      "protocol": "TCP",
      "upperPort": 0
    },
    {
      "id": "0aec3270-a580-414c-be43-d13522bd4058",
      "instanceId": 14348,
      "authEntityId": 14348,
      "displayName": "14348",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "428",
      "protocol": "UDP",
      "upperPort": 0
    }
  ],
  "parentScalableGroup": {
    "idRef": "000c15bf-28fe-4fbb-9b69-35e6502721f1"
  },
  "user": [

  ],
  "userGroup": [

  ]
},
{
  "id": "b445628f-0740-4b8d-ad7f-47d47b315f74",
  "instanceId": 4739,
  "authEntityId": 4739,
  "displayName": "4739",
  "authEntityClass": -1909073334,
  "deployPending": "NONE",
  "instanceVersion": 0,
  "createTime": 1517966743397,
  "deployed": false,
  "isSeeded": true,
  "isStale": false,
  "lastUpdateTime": 1517966743397,
  "name": "meter",
  "namespace": "scalablegroup:application",
  "provisioningState": "DEFINED",
  "qualifier": "application",
  "resourceVersion": 0,
  "targetIdList": [

  ],
  "type": "scalablegroup",
  "cfsChangeInfo": [

  ],
  "customProvisions": [

  ],
  "scalableGroupExternalHandle": "meter",
  "scalableGroupType": "APPLICATION",
  "securityGroupTag": 0,
  "state": "ACTIVE",
  "vnAgnostic": true,
  "identitySource": {
    "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
    "instanceId": 10010,
    "authEntityId": 10010,
    "displayName": "10010",
    "authEntityClass": 1738953278,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "state": "INACTIVE",
    "type": "NBAR"
  },
  "indicativeNetworkIdentity": [
    {
      "id": "a0391fe6-f521-4ce2-9d50-0cdad0281dbb",
      "instanceId": 13878,
      "authEntityId": 13878,
      "displayName": "13878",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "570",
      "protocol": "TCP",
      "upperPort": 0
    },
    {
      "id": "d0396779-340b-4334-98f4-97adbb185658",
      "instanceId": 13879,
      "authEntityId": 13879,
      "displayName": "13879",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "570",
      "protocol": "UDP",
      "upperPort": 0
    }
  ],
  "networkApplications": [
    {
      "id": "6ee95859-f32c-46f4-80ac-0271b793b6be",
      "instanceId": 11689,
      "authEntityId": 11689,
      "displayName": "11689",
      "authEntityClass": -217092956,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "appProtocol": "tcp/udp",
      "applicationGroup": "other",
      "applicationType": "DEFAULT",
      "categoryId": "6daa3dfd-3526-407a-88f0-97824b3f3a24",
      "createTime": 1517966743397,
      "encrypted": "false",
      "engineId": "3",
      "helpString": "meter",
      "ignoreConflict": false,
      "lastUpdateTime": 1517966743397,
      "longDescription": "Registered with IANA on port 570 TCP/UDP",
      "name": "meter",
      "nbarId": "485",
      "p2pTechnology": "false",
      "popularity": 1,
      "rank": 65535,
      "references": "http://www.iana.org/assignments/service-names-port-numbers/service-names-port-numbers.xml",
      "selectorId": "570",
      "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
      "trafficClass": "BULK_DATA",
      "tunnel": "false"
    }
  ],
  "networkIdentity": [
    {
      "id": "75300407-b3a7-4e85-a7c5-62b9c4532230",
      "instanceId": 13876,
      "authEntityId": 13876,
      "displayName": "13876",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "570",
      "protocol": "UDP",
      "upperPort": 0
    },
    {
      "id": "c7696e1b-7dba-436b-a60c-8e4a17da08aa",
      "instanceId": 13877,
      "authEntityId": 13877,
      "displayName": "13877",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "570",
      "protocol": "TCP",
      "upperPort": 0
    }
  ],
  "parentScalableGroup": {
    "idRef": "000c15bf-28fe-4fbb-9b69-35e6502721f1"
  },
  "user": [

  ],
  "userGroup": [

  ]
},
{
  "id": "b52ccbe9-71e3-4d81-8d89-8955383650cd",
  "instanceId": 15235,
  "authEntityId": 15235,
  "displayName": "15235",
  "authEntityClass": -1909073334,
  "deployPending": "NONE",
  "instanceVersion": 0,
  "createTime": 1517966744551,
  "deployed": false,
  "isSeeded": true,
  "isStale": false,
  "lastUpdateTime": 1517966744551,
  "name": "surf",
  "namespace": "scalablegroup:application",
  "provisioningState": "DEFINED",
  "qualifier": "application",
  "resourceVersion": 0,
  "targetIdList": [

  ],
  "type": "scalablegroup",
  "cfsChangeInfo": [

  ],
  "customProvisions": [

  ],
  "scalableGroupExternalHandle": "surf",
  "scalableGroupType": "APPLICATION",
  "securityGroupTag": 0,
  "state": "ACTIVE",
  "vnAgnostic": true,
  "identitySource": {
    "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
    "instanceId": 10010,
    "authEntityId": 10010,
    "displayName": "10010",
    "authEntityClass": 1738953278,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "state": "INACTIVE",
    "type": "NBAR"
  },
  "indicativeNetworkIdentity": [
    {
      "id": "11942e8e-96df-4ccc-aa88-48c213cf70c0",
      "instanceId": 17342,
      "authEntityId": 17342,
      "displayName": "17342",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "1010",
      "protocol": "TCP",
      "upperPort": 0
    },
    {
      "id": "a2d9e774-8ea4-4b72-8354-7f4cdbd66994",
      "instanceId": 17343,
      "authEntityId": 17343,
      "displayName": "17343",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "1010",
      "protocol": "UDP",
      "upperPort": 0
    }
  ],
  "networkApplications": [
    {
      "id": "c265b770-7cf5-4e97-9ada-9dc7bd68ccb6",
      "instanceId": 16178,
      "authEntityId": 16178,
      "displayName": "16178",
      "authEntityClass": -217092956,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "appProtocol": "tcp/udp",
      "applicationGroup": "other",
      "applicationType": "DEFAULT",
      "categoryId": "6daa3dfd-3526-407a-88f0-97824b3f3a24",
      "createTime": 1517966744551,
      "encrypted": "false",
      "engineId": "3",
      "helpString": "surf",
      "ignoreConflict": false,
      "lastUpdateTime": 1517966744551,
      "longDescription": "Speeded Up Robust Feature (SURF) is a robust local feature detector that can be used in computer vision tasks like object recognition or 3D reconstruction. It is partly inspired by the SIFT descriptor.",
      "name": "surf",
      "nbarId": "681",
      "p2pTechnology": "false",
      "popularity": 1,
      "rank": 65535,
      "references": "http://en.wikipedia.org/wiki/SURF",
      "selectorId": "1010",
      "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
      "trafficClass": "BULK_DATA",
      "tunnel": "false"
    }
  ],
  "networkIdentity": [
    {
      "id": "6d56c6c9-92e3-4393-8eda-6c2ad4d6debe",
      "instanceId": 17340,
      "authEntityId": 17340,
      "displayName": "17340",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "1010",
      "protocol": "UDP",
      "upperPort": 0
    },
    {
      "id": "22cb52ff-dedb-476b-88a5-3e047f48c700",
      "instanceId": 17341,
      "authEntityId": 17341,
      "displayName": "17341",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "1010",
      "protocol": "TCP",
      "upperPort": 0
    }
  ],
  "parentScalableGroup": {
    "idRef": "000c15bf-28fe-4fbb-9b69-35e6502721f1"
  },
  "user": [

  ],
  "userGroup": [

  ]
},
{
  "id": "b536c063-5eaf-4e9d-af2c-7330aeb8ff01",
  "instanceId": 4874,
  "authEntityId": 4874,
  "displayName": "4874",
  "authEntityClass": -1909073334,
  "deployPending": "NONE",
  "instanceVersion": 0,
  "createTime": 1517966743658,
  "deployed": false,
  "isSeeded": true,
  "isStale": false,
  "lastUpdateTime": 1517966743658,
  "name": "notes",
  "namespace": "scalablegroup:application",
  "provisioningState": "DEFINED",
  "qualifier": "application",
  "resourceVersion": 0,
  "targetIdList": [

  ],
  "type": "scalablegroup",
  "cfsChangeInfo": [

  ],
  "customProvisions": [

  ],
  "scalableGroupExternalHandle": "notes",
  "scalableGroupType": "APPLICATION",
  "securityGroupTag": 0,
  "state": "ACTIVE",
  "vnAgnostic": true,
  "identitySource": {
    "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
    "instanceId": 10010,
    "authEntityId": 10010,
    "displayName": "10010",
    "authEntityClass": 1738953278,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "state": "INACTIVE",
    "type": "NBAR"
  },
  "indicativeNetworkIdentity": [

  ],
  "networkApplications": [
    {
      "id": "a9be8735-d001-4d84-9730-60e3cea37543",
      "instanceId": 11823,
      "authEntityId": 11823,
      "displayName": "11823",
      "authEntityClass": -217092956,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "applicationGroup": "other",
      "applicationType": "DEFAULT",
      "categoryId": "6daa3dfd-3526-407a-88f0-97824b3f3a24",
      "createTime": 1517966743658,
      "encrypted": "unassigned",
      "engineId": "13",
      "helpString": "DEPRECATED, Please refer to lotus-notes",
      "ignoreConflict": false,
      "lastUpdateTime": 1517966743658,
      "longDescription": "IBM Lotus Notes provides integrated collaboration functionality, including email, calendaring, contacts management, to do tracking, instant messaging, an office productivity suite (IBM Lotus Symphony), and access to other Lotus Domino applications and databases.",
      "name": "notes",
      "nbarId": "30",
      "p2pTechnology": "unassigned",
      "popularity": 0,
      "rank": 65535,
      "references": "http://www-01.ibm.com/software/lotus/notesanddomino/",
      "selectorId": "2000",
      "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
      "trafficClass": "BULK_DATA",
      "tunnel": "unassigned"
    }
  ],
  "networkIdentity": [

  ],
  "parentScalableGroup": {
    "idRef": "000c15bf-28fe-4fbb-9b69-35e6502721f1"
  },
  "user": [

  ],
  "userGroup": [

  ]
},
{
  "id": "b5e063ea-27b3-4948-8654-e16a15f09881",
  "instanceId": 4585,
  "authEntityId": 4585,
  "displayName": "4585",
  "authEntityClass": -1909073334,
  "deployPending": "NONE",
  "instanceVersion": 0,
  "createTime": 1517966743054,
  "deployed": false,
  "isSeeded": true,
  "isStale": false,
  "lastUpdateTime": 1517966743054,
  "name": "idfp",
  "namespace": "scalablegroup:application",
  "provisioningState": "DEFINED",
  "qualifier": "application",
  "resourceVersion": 0,
  "targetIdList": [

  ],
  "type": "scalablegroup",
  "cfsChangeInfo": [

  ],
  "customProvisions": [

  ],
  "scalableGroupExternalHandle": "idfp",
  "scalableGroupType": "APPLICATION",
  "securityGroupTag": 0,
  "state": "ACTIVE",
  "vnAgnostic": true,
  "identitySource": {
    "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
    "instanceId": 10010,
    "authEntityId": 10010,
    "displayName": "10010",
    "authEntityClass": 1738953278,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "state": "INACTIVE",
    "type": "NBAR"
  },
  "indicativeNetworkIdentity": [
    {
      "id": "635ebdcb-476d-4e33-8576-628b89147a05",
      "instanceId": 13472,
      "authEntityId": 13472,
      "displayName": "13472",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "549",
      "protocol": "UDP",
      "upperPort": 0
    },
    {
      "id": "e5e35392-1d52-47fe-b282-80b4186950b1",
      "instanceId": 13471,
      "authEntityId": 13471,
      "displayName": "13471",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "549",
      "protocol": "TCP",
      "upperPort": 0
    }
  ],
  "networkApplications": [
    {
      "id": "c1cd5c24-d77c-43f8-84d4-93dc5bbfaad4",
      "instanceId": 11535,
      "authEntityId": 11535,
      "displayName": "11535",
      "authEntityClass": -217092956,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "appProtocol": "tcp/udp",
      "applicationGroup": "other",
      "applicationType": "DEFAULT",
      "categoryId": "6daa3dfd-3526-407a-88f0-97824b3f3a24",
      "createTime": 1517966743054,
      "encrypted": "false",
      "engineId": "3",
      "helpString": "idfp",
      "ignoreConflict": false,
      "lastUpdateTime": 1517966743054,
      "longDescription": "Registered with IANA on port 549 TCP/UDP",
      "name": "idfp",
      "nbarId": "466",
      "p2pTechnology": "false",
      "popularity": 1,
      "rank": 65535,
      "references": "http://www.iana.org/assignments/service-names-port-numbers/service-names-port-numbers.xml",
      "selectorId": "549",
      "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
      "trafficClass": "BULK_DATA",
      "tunnel": "false"
    }
  ],
  "networkIdentity": [
    {
      "id": "754937e2-325e-4bb3-9c6a-bdf7a750f06c",
      "instanceId": 13469,
      "authEntityId": 13469,
      "displayName": "13469",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "549",
      "protocol": "TCP",
      "upperPort": 0
    },
    {
      "id": "d2f3e3ea-4ed4-4e87-a2dc-b8865e09d90c",
      "instanceId": 13470,
      "authEntityId": 13470,
      "displayName": "13470",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "549",
      "protocol": "UDP",
      "upperPort": 0
    }
  ],
  "parentScalableGroup": {
    "idRef": "000c15bf-28fe-4fbb-9b69-35e6502721f1"
  },
  "user": [

  ],
  "userGroup": [

  ]
},
{
  "id": "b5ed62df-04f1-4460-a2a1-cb5dfa24ed54",
  "instanceId": 15308,
  "authEntityId": 15308,
  "displayName": "15308",
  "authEntityClass": -1909073334,
  "deployPending": "NONE",
  "instanceVersion": 0,
  "createTime": 1517966744709,
  "deployed": false,
  "isSeeded": true,
  "isStale": false,
  "lastUpdateTime": 1517966744709,
  "name": "tudou",
  "namespace": "scalablegroup:application",
  "provisioningState": "DEFINED",
  "qualifier": "application",
  "resourceVersion": 0,
  "targetIdList": [

  ],
  "type": "scalablegroup",
  "cfsChangeInfo": [

  ],
  "customProvisions": [

  ],
  "scalableGroupExternalHandle": "tudou",
  "scalableGroupType": "APPLICATION",
  "securityGroupTag": 0,
  "state": "ACTIVE",
  "vnAgnostic": true,
  "identitySource": {
    "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
    "instanceId": 10010,
    "authEntityId": 10010,
    "displayName": "10010",
    "authEntityClass": 1738953278,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "state": "INACTIVE",
    "type": "NBAR"
  },
  "indicativeNetworkIdentity": [

  ],
  "networkApplications": [
    {
      "id": "3920685b-bde9-44e7-9077-de7c5dce353e",
      "instanceId": 16251,
      "authEntityId": 16251,
      "displayName": "16251",
      "authEntityClass": -217092956,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "appProtocol": "tcp/udp",
      "applicationGroup": "other",
      "applicationType": "DEFAULT",
      "categoryId": "878644b5-fe91-45b4-9204-31c84a73ba48",
      "createTime": 1517966744709,
      "encrypted": "false",
      "engineId": "13",
      "helpString": "Tudou - Popular Chinese video sharing website.",
      "ignoreConflict": false,
      "lastUpdateTime": 1517966744709,
      "longDescription": "Tudou - Popular Chinese video sharing website.",
      "name": "tudou",
      "nbarId": "1559",
      "p2pTechnology": "false",
      "popularity": 3,
      "rank": 65535,
      "references": "http://tudou.com",
      "selectorId": "630",
      "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
      "trafficClass": "TRANSACTIONAL_DATA",
      "tunnel": "false"
    }
  ],
  "networkIdentity": [
    {
      "id": "9df09f7c-f0de-4a8a-a47b-4e93aedc2d54",
      "instanceId": 17546,
      "authEntityId": 17546,
      "displayName": "17546",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "53,80,443,5353",
      "protocol": "TCP",
      "upperPort": 0
    },
    {
      "id": "e2c9bf39-0fbd-4c0f-92fb-cb44e3ce0d28",
      "instanceId": 17547,
      "authEntityId": 17547,
      "displayName": "17547",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "53,5353",
      "protocol": "UDP",
      "upperPort": 0
    }
  ],
  "parentScalableGroup": {
    "idRef": "28e08de4-d7b2-4521-84ce-bc1e5ee0b9cc"
  },
  "user": [

  ],
  "userGroup": [

  ]
},
{
  "id": "b6087e94-e28f-4741-991a-0789e63431f0",
  "instanceId": 4890,
  "authEntityId": 4890,
  "displayName": "4890",
  "authEntityClass": -1909073334,
  "deployPending": "NONE",
  "instanceVersion": 0,
  "createTime": 1517966743691,
  "deployed": false,
  "isSeeded": true,
  "isStale": false,
  "lastUpdateTime": 1517966743691,
  "name": "nvp-ii",
  "namespace": "scalablegroup:application",
  "provisioningState": "DEFINED",
  "qualifier": "application",
  "resourceVersion": 0,
  "targetIdList": [

  ],
  "type": "scalablegroup",
  "cfsChangeInfo": [

  ],
  "customProvisions": [

  ],
  "scalableGroupExternalHandle": "nvp-ii",
  "scalableGroupType": "APPLICATION",
  "securityGroupTag": 0,
  "state": "ACTIVE",
  "vnAgnostic": true,
  "identitySource": {
    "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
    "instanceId": 10010,
    "authEntityId": 10010,
    "displayName": "10010",
    "authEntityClass": 1738953278,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "state": "INACTIVE",
    "type": "NBAR"
  },
  "indicativeNetworkIdentity": [
    {
      "id": "176de16b-3267-4e17-a2ad-50e434ce67fd",
      "instanceId": 14322,
      "authEntityId": 14322,
      "displayName": "14322",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipProtocolNumbers": "11",
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "protocol": "NVP_II",
      "upperPort": 0
    }
  ],
  "networkApplications": [
    {
      "id": "6e569526-4d8d-46a1-96fa-607c75828128",
      "instanceId": 11839,
      "authEntityId": 11839,
      "displayName": "11839",
      "authEntityClass": -217092956,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "appProtocol": "ip",
      "applicationGroup": "other",
      "applicationType": "DEFAULT",
      "categoryId": "6daa3dfd-3526-407a-88f0-97824b3f3a24",
      "createTime": 1517966743691,
      "encrypted": "unassigned",
      "engineId": "1",
      "helpString": "Network Voice Protocol",
      "ignoreConflict": false,
      "lastUpdateTime": 1517966743691,
      "longDescription": "The Network Voice Protocol (NVP) was a computer network protocol for transporting human speech over packetized communications networks. It was an early example of VoIP technology.",
      "name": "nvp-ii",
      "nbarId": "766",
      "p2pTechnology": "unassigned",
      "popularity": 2,
      "rank": 65535,
      "references": "http://www.ietf.org/rfc/rfc741.txt",
      "selectorId": "11",
      "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
      "trafficClass": "BULK_DATA",
      "tunnel": "unassigned"
    }
  ],
  "networkIdentity": [
    {
      "id": "441ab768-30de-484d-95e0-9d4c2f737544",
      "instanceId": 14321,
      "authEntityId": 14321,
      "displayName": "14321",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipProtocolNumbers": "11",
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "protocol": "NVP_II",
      "upperPort": 0
    }
  ],
  "parentScalableGroup": {
    "idRef": "000c15bf-28fe-4fbb-9b69-35e6502721f1"
  },
  "user": [

  ],
  "userGroup": [

  ]
},
{
  "id": "b651c342-1e01-4d49-889b-b045a7179320",
  "instanceId": 15150,
  "authEntityId": 15150,
  "displayName": "15150",
  "authEntityClass": -1909073334,
  "deployPending": "NONE",
  "instanceVersion": 0,
  "createTime": 1517966744348,
  "deployed": false,
  "isSeeded": true,
  "isStale": false,
  "lastUpdateTime": 1517966744348,
  "name": "sixtofour-ipv6-tunneled",
  "namespace": "scalablegroup:application",
  "provisioningState": "DEFINED",
  "qualifier": "application",
  "resourceVersion": 0,
  "targetIdList": [

  ],
  "type": "scalablegroup",
  "cfsChangeInfo": [

  ],
  "customProvisions": [

  ],
  "scalableGroupExternalHandle": "sixtofour-ipv6-tunneled",
  "scalableGroupType": "APPLICATION",
  "securityGroupTag": 0,
  "state": "ACTIVE",
  "vnAgnostic": true,
  "identitySource": {
    "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
    "instanceId": 10010,
    "authEntityId": 10010,
    "displayName": "10010",
    "authEntityClass": 1738953278,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "state": "INACTIVE",
    "type": "NBAR"
  },
  "indicativeNetworkIdentity": [

  ],
  "networkApplications": [
    {
      "id": "58a84ab0-ddd5-4b18-9401-d7a5e5b96f0c",
      "instanceId": 16093,
      "authEntityId": 16093,
      "displayName": "16093",
      "authEntityClass": -217092956,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "applicationGroup": "other",
      "applicationType": "DEFAULT",
      "categoryId": "70fce3cd-f2bd-48de-9020-7b7840b9e7a1",
      "createTime": 1517966744348,
      "encrypted": "false",
      "engineId": "13",
      "helpString": "Sixtofour IPv6 over IPv4 tunneling protocol",
      "ignoreConflict": false,
      "lastUpdateTime": 1517966744348,
      "longDescription": "6to4 is an Internet transition mechanism for migrating from IPv4 to IPv6, a system that allows IPv6 packets to be transmitted over an IPv4 network (generally the IPv4 Internet) without the need to configure explicit tunnels. Special relay servers are also in place that allow 6to4 networks to communicate with native IPv6 networks.",
      "name": "sixtofour-ipv6-tunneled",
      "nbarId": "1223",
      "p2pTechnology": "false",
      "popularity": 7,
      "rank": 65535,
      "references": "http://tools.ietf.org/html/rfc3056",
      "selectorId": "330",
      "subCategory": "ab63e736-9aa7-4946-b55b-3b26ddca609c",
      "trafficClass": "BULK_DATA",
      "tunnel": "true"
    }
  ],
  "networkIdentity": [

  ],
  "parentScalableGroup": {
    "idRef": "79f9d505-477f-4ccd-bd2a-c5fc8097a2e1"
  },
  "user": [

  ],
  "userGroup": [

  ]
},
{
  "id": "b6667a57-c9af-46b2-bd09-a9bd7e094948",
  "instanceId": 4550,
  "authEntityId": 4550,
  "displayName": "4550",
  "authEntityClass": -1909073334,
  "deployPending": "NONE",
  "instanceVersion": 0,
  "createTime": 1517966742963,
  "deployed": false,
  "isSeeded": true,
  "isStale": false,
  "lastUpdateTime": 1517966742963,
  "name": "hotels-com",
  "namespace": "scalablegroup:application",
  "provisioningState": "DEFINED",
  "qualifier": "application",
  "resourceVersion": 0,
  "targetIdList": [

  ],
  "type": "scalablegroup",
  "cfsChangeInfo": [

  ],
  "customProvisions": [

  ],
  "scalableGroupExternalHandle": "hotels-com",
  "scalableGroupType": "APPLICATION",
  "securityGroupTag": 0,
  "state": "ACTIVE",
  "vnAgnostic": true,
  "identitySource": {
    "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
    "instanceId": 10010,
    "authEntityId": 10010,
    "displayName": "10010",
    "authEntityClass": 1738953278,
    "deployPending": "NONE",
    "instanceVersion": 0,
    "state": "INACTIVE",
    "type": "NBAR"
  },
  "indicativeNetworkIdentity": [

  ],
  "networkApplications": [
    {
      "id": "00481ca5-8ddb-46bf-9f7b-e578eacf0c96",
      "instanceId": 11501,
      "authEntityId": 11501,
      "displayName": "11501",
      "authEntityClass": -217092956,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "appProtocol": "tcp/udp",
      "applicationGroup": "other",
      "applicationType": "DEFAULT",
      "categoryId": "878644b5-fe91-45b4-9204-31c84a73ba48",
      "createTime": 1517966742963,
      "encrypted": "false",
      "engineId": "13",
      "helpString": "Hotels.com - Webportal for finding hotel deals and booking it online.",
      "ignoreConflict": false,
      "lastUpdateTime": 1517966742963,
      "longDescription": "Hotels.com - Webportal for finding hotel deals and booking it online.",
      "name": "hotels-com",
      "nbarId": "1645",
      "p2pTechnology": "false",
      "popularity": 3,
      "rank": 65535,
      "references": "http://hotels.com",
      "selectorId": "715",
      "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
      "trafficClass": "TRANSACTIONAL_DATA",
      "tunnel": "false"
    }
  ],
  "networkIdentity": [
    {
      "id": "97e494bd-aa8f-4216-9603-842f13a29811",
      "instanceId": 13363,
      "authEntityId": 13363,
      "displayName": "13363",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "53,5353",
      "protocol": "UDP",
      "upperPort": 0
    },
    {
      "id": "e3b6d1e0-43cd-435c-925d-7390e0f3604f",
      "instanceId": 13364,
      "authEntityId": 13364,
      "displayName": "13364",
      "authEntityClass": 124090504,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "ipv4Subnet": [

      ],
      "ipv6Subnet": [

      ],
      "lowerPort": 0,
      "ports": "53,80,443,5353",
      "protocol": "TCP",
      "upperPort": 0
    }
  ],
  "parentScalableGroup": {
    "idRef": "28e08de4-d7b2-4521-84ce-bc1e5ee0b9cc"
  },
  "user": [

  ],
  "userGroup": [

  ]
},
    {
      "id": "b67e8a86-402a-4367-8e05-28beb6bf89d2",
      "instanceId": 4644,
      "authEntityId": 4644,
      "displayName": "4644",
      "authEntityClass": -1909073334,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "createTime": 1517966743193,
      "deployed": false,
      "isSeeded": true,
      "isStale": false,
      "lastUpdateTime": 1517966743193,
      "name": "iscsi-target",
      "namespace": "scalablegroup:application",
      "provisioningState": "DEFINED",
      "qualifier": "application",
      "resourceVersion": 0,
      "targetIdList": [

      ],
      "type": "scalablegroup",
      "cfsChangeInfo": [

      ],
      "customProvisions": [

      ],
      "scalableGroupExternalHandle": "iscsi-target",
      "scalableGroupType": "APPLICATION",
      "securityGroupTag": 0,
      "state": "ACTIVE",
      "vnAgnostic": true,
      "identitySource": {
        "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
        "instanceId": 10010,
        "authEntityId": 10010,
        "displayName": "10010",
        "authEntityClass": 1738953278,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "state": "INACTIVE",
        "type": "NBAR"
      },
      "indicativeNetworkIdentity": [
        {
          "id": "5514418f-8553-4ce6-98e8-027f5b3ad7c8",
          "instanceId": 13616,
          "authEntityId": 13616,
          "displayName": "13616",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "3260",
          "protocol": "TCP",
          "upperPort": 0
        }
      ],
      "networkApplications": [
        {
          "id": "178a7dc5-f4af-46e0-83b8-26ad91a71e1f",
          "instanceId": 11594,
          "authEntityId": 11594,
          "displayName": "11594",
          "authEntityClass": -217092956,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "appProtocol": "tcp",
          "applicationGroup": "other",
          "applicationType": "DEFAULT",
          "categoryId": "6daa3dfd-3526-407a-88f0-97824b3f3a24",
          "createTime": 1517966743193,
          "encrypted": "false",
          "engineId": "3",
          "helpString": "Internet Small Computer System Interface",
          "ignoreConflict": false,
          "lastUpdateTime": 1517966743193,
          "longDescription": "Internet Small Computer System Interface (iSCSI) is an IP-based storage networking standard for linking data storage facilities.",
          "name": "iscsi-target",
          "nbarId": "1350",
          "p2pTechnology": "false",
          "popularity": 1,
          "rank": 65535,
          "references": "http://www.ietf.org/rfc/rfc3720.txt",
          "selectorId": "3260",
          "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
          "trafficClass": "BULK_DATA",
          "tunnel": "false"
        }
      ],
      "networkIdentity": [
        {
          "id": "61f29964-7263-4d7e-b08f-b0556184bb0d",
          "instanceId": 13615,
          "authEntityId": 13615,
          "displayName": "13615",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "3260",
          "protocol": "TCP",
          "upperPort": 0
        }
      ],
      "parentScalableGroup": {
        "idRef": "000c15bf-28fe-4fbb-9b69-35e6502721f1"
      },
      "user": [

      ],
      "userGroup": [

      ]
    },
    {
      "id": "b693555a-4a2f-478b-a779-ff5117095e37",
      "instanceId": 15361,
      "authEntityId": 15361,
      "displayName": "15361",
      "authEntityClass": -1909073334,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "createTime": 1517966744805,
      "deployed": false,
      "isSeeded": true,
      "isStale": false,
      "lastUpdateTime": 1517966744805,
      "name": "vmware-view",
      "namespace": "scalablegroup:application",
      "provisioningState": "DEFINED",
      "qualifier": "application",
      "resourceVersion": 0,
      "targetIdList": [

      ],
      "type": "scalablegroup",
      "cfsChangeInfo": [

      ],
      "customProvisions": [

      ],
      "scalableGroupExternalHandle": "vmware-view",
      "scalableGroupType": "APPLICATION",
      "securityGroupTag": 0,
      "state": "ACTIVE",
      "vnAgnostic": true,
      "identitySource": {
        "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
        "instanceId": 10010,
        "authEntityId": 10010,
        "displayName": "10010",
        "authEntityClass": 1738953278,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "state": "INACTIVE",
        "type": "NBAR"
      },
      "indicativeNetworkIdentity": [

      ],
      "networkApplications": [
        {
          "id": "65b314fa-3352-4037-b7ba-a413ad8f6466",
          "instanceId": 16304,
          "authEntityId": 16304,
          "displayName": "16304",
          "authEntityClass": -217092956,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "appProtocol": "tcp",
          "applicationGroup": "vmware-group",
          "applicationType": "DEFAULT",
          "categoryId": "7e19fdea-6222-4c57-9e66-66c2340d6bc3",
          "createTime": 1517966744805,
          "encrypted": "true",
          "engineId": "13",
          "helpString": "Match UI and login of VMware. Actual desktop data is sent over PCoIP or MS-WBT",
          "ignoreConflict": false,
          "lastUpdateTime": 1517966744805,
          "longDescription": "VMware View is a desktop-virtualization product developed by VMware, provides virtual desktop infrastructure (VDI) solution using VMware's virtualization technology. A client desktop operating system runs within a virtual environment on a server. This protocol match the user interface traffic and authentication, the remote desktops traffic sent over PCoIP or Microsoft Remote Desktop protocols.",
          "name": "vmware-view",
          "nbarId": "1426",
          "p2pTechnology": "false",
          "popularity": 9,
          "rank": 65535,
          "references": "http://www.vmware.com/products/view/overview.html",
          "selectorId": "476",
          "subCategory": "ff664205-64f3-447e-acff-1ec1ba9a6c31",
          "trafficClass": "MULTIMEDIA_STREAMING",
          "tunnel": "false"
        }
      ],
      "networkIdentity": [
        {
          "id": "b811d3c4-2c60-415b-a166-8d6686a705b8",
          "instanceId": 17699,
          "authEntityId": 17699,
          "displayName": "17699",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "80,443",
          "protocol": "TCP",
          "upperPort": 0
        }
      ],
      "parentScalableGroup": {
        "idRef": "4a6b37c6-7e11-40fe-a6b1-719b24813145"
      },
      "user": [

      ],
      "userGroup": [

      ]
    },
    {
      "id": "b697d002-8d25-422b-8a3d-614325dfdcd5",
      "instanceId": 4210,
      "authEntityId": 4210,
      "displayName": "4210",
      "authEntityClass": -1909073334,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "createTime": 1517966741736,
      "deployed": false,
      "isSeeded": true,
      "isStale": false,
      "lastUpdateTime": 1517966741736,
      "name": "capital-one",
      "namespace": "scalablegroup:application",
      "provisioningState": "DEFINED",
      "qualifier": "application",
      "resourceVersion": 0,
      "targetIdList": [

      ],
      "type": "scalablegroup",
      "cfsChangeInfo": [

      ],
      "customProvisions": [

      ],
      "scalableGroupExternalHandle": "capital-one",
      "scalableGroupType": "APPLICATION",
      "securityGroupTag": 0,
      "state": "ACTIVE",
      "vnAgnostic": true,
      "identitySource": {
        "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
        "instanceId": 10010,
        "authEntityId": 10010,
        "displayName": "10010",
        "authEntityClass": 1738953278,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "state": "INACTIVE",
        "type": "NBAR"
      },
      "indicativeNetworkIdentity": [

      ],
      "networkApplications": [
        {
          "id": "426c0a33-d835-4d53-ae89-b1b20b23be69",
          "instanceId": 11166,
          "authEntityId": 11166,
          "displayName": "11166",
          "authEntityClass": -217092956,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "appProtocol": "tcp/udp",
          "applicationGroup": "other",
          "applicationType": "DEFAULT",
          "categoryId": "878644b5-fe91-45b4-9204-31c84a73ba48",
          "createTime": 1517966741736,
          "encrypted": "false",
          "engineId": "13",
          "helpString": "Capital One - U.S. based bank holding company.",
          "ignoreConflict": false,
          "lastUpdateTime": 1517966741736,
          "longDescription": "Capital One - U.S. based bank holding company.",
          "name": "capital-one",
          "nbarId": "1620",
          "p2pTechnology": "false",
          "popularity": 3,
          "rank": 65535,
          "references": "http://capitalone.com",
          "selectorId": "690",
          "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
          "trafficClass": "TRANSACTIONAL_DATA",
          "tunnel": "false"
        }
      ],
      "networkIdentity": [
        {
          "id": "1fe05f04-e1bd-4b79-9233-29f5de9a0da4",
          "instanceId": 12459,
          "authEntityId": 12459,
          "displayName": "12459",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "53,5353",
          "protocol": "UDP",
          "upperPort": 0
        },
        {
          "id": "4a2942f2-2933-483f-be5f-5f52eb6796bd",
          "instanceId": 12460,
          "authEntityId": 12460,
          "displayName": "12460",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "53,80,443,5353",
          "protocol": "TCP",
          "upperPort": 0
        }
      ],
      "parentScalableGroup": {
        "idRef": "28e08de4-d7b2-4521-84ce-bc1e5ee0b9cc"
      },
      "user": [

      ],
      "userGroup": [

      ]
    },
    {
      "id": "b6a7d724-00e2-4811-8bb6-1a2996700311",
      "instanceId": 15286,
      "authEntityId": 15286,
      "displayName": "15286",
      "authEntityClass": -1909073334,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "createTime": 1517966744659,
      "deployed": false,
      "isSeeded": true,
      "isStale": false,
      "lastUpdateTime": 1517966744659,
      "name": "time",
      "namespace": "scalablegroup:application",
      "provisioningState": "DEFINED",
      "qualifier": "application",
      "resourceVersion": 0,
      "targetIdList": [

      ],
      "type": "scalablegroup",
      "cfsChangeInfo": [

      ],
      "customProvisions": [

      ],
      "scalableGroupExternalHandle": "time",
      "scalableGroupType": "APPLICATION",
      "securityGroupTag": 0,
      "state": "ACTIVE",
      "vnAgnostic": true,
      "identitySource": {
        "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
        "instanceId": 10010,
        "authEntityId": 10010,
        "displayName": "10010",
        "authEntityClass": 1738953278,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "state": "INACTIVE",
        "type": "NBAR"
      },
      "indicativeNetworkIdentity": [
        {
          "id": "ff21a532-b222-40bd-85b8-a9ae4486f14b",
          "instanceId": 17488,
          "authEntityId": 17488,
          "displayName": "17488",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "37",
          "protocol": "UDP",
          "upperPort": 0
        },
        {
          "id": "63be58b0-8bcf-4274-b392-7f5ac929516b",
          "instanceId": 17487,
          "authEntityId": 17487,
          "displayName": "17487",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "37",
          "protocol": "TCP",
          "upperPort": 0
        }
      ],
      "networkApplications": [
        {
          "id": "17d48f5e-1a32-4e4e-8fb5-85a6f9c78ee3",
          "instanceId": 16229,
          "authEntityId": 16229,
          "displayName": "16229",
          "authEntityClass": -217092956,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "appProtocol": "tcp/udp",
          "applicationGroup": "other",
          "applicationType": "DEFAULT",
          "categoryId": "1fc17362-9334-4c7b-b752-5afdbb574017",
          "createTime": 1517966744659,
          "encrypted": "false",
          "engineId": "3",
          "helpString": "Time",
          "ignoreConflict": false,
          "lastUpdateTime": 1517966744659,
          "longDescription": "The Network Time Protocol (NTP) is a protocol used to get the accurate time and date from designated time servers. The protocol works on TCP and UDP, typically on port 37.",
          "name": "time",
          "nbarId": "105",
          "p2pTechnology": "false",
          "popularity": 1,
          "rank": 65535,
          "references": "http://www.ietf.org/rfc/rfc868.txt",
          "selectorId": "37",
          "subCategory": "f3c0f761-d894-4112-ab89-b90fc84fd660",
          "trafficClass": "OPS_ADMIN_MGMT",
          "tunnel": "false"
        }
      ],
      "networkIdentity": [
        {
          "id": "e6bcb28d-a7d0-4355-bb12-dacec1ecb2ea",
          "instanceId": 17485,
          "authEntityId": 17485,
          "displayName": "17485",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "37",
          "protocol": "UDP",
          "upperPort": 0
        },
        {
          "id": "73ee3cc1-0a24-4504-8bc2-237394c90f13",
          "instanceId": 17486,
          "authEntityId": 17486,
          "displayName": "17486",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "37",
          "protocol": "TCP",
          "upperPort": 0
        }
      ],
      "parentScalableGroup": {
        "idRef": "5fcd74d4-8726-47f7-a088-c457863da7ef"
      },
      "user": [

      ],
      "userGroup": [

      ]
    },
    {
      "id": "b6d1eb6d-fee9-49f1-92f5-48a8aa0fe72c",
      "instanceId": 4634,
      "authEntityId": 4634,
      "displayName": "4634",
      "authEntityClass": -1909073334,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "createTime": 1517966743172,
      "deployed": false,
      "isSeeded": true,
      "isStale": false,
      "lastUpdateTime": 1517966743172,
      "name": "ipv6inip",
      "namespace": "scalablegroup:application",
      "provisioningState": "DEFINED",
      "qualifier": "application",
      "resourceVersion": 0,
      "targetIdList": [

      ],
      "type": "scalablegroup",
      "cfsChangeInfo": [

      ],
      "customProvisions": [

      ],
      "scalableGroupExternalHandle": "ipv6inip",
      "scalableGroupType": "APPLICATION",
      "securityGroupTag": 0,
      "state": "ACTIVE",
      "vnAgnostic": true,
      "identitySource": {
        "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
        "instanceId": 10010,
        "authEntityId": 10010,
        "displayName": "10010",
        "authEntityClass": 1738953278,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "state": "INACTIVE",
        "type": "NBAR"
      },
      "indicativeNetworkIdentity": [
        {
          "id": "f573c66e-6d5e-461e-8148-da169a6007f5",
          "instanceId": 13586,
          "authEntityId": 13586,
          "displayName": "13586",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipProtocolNumbers": "41",
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "protocol": "IP",
          "upperPort": 0
        }
      ],
      "networkApplications": [
        {
          "id": "326d7f83-94a0-411b-930b-72e9c7d7c101",
          "instanceId": 11584,
          "authEntityId": 11584,
          "displayName": "11584",
          "authEntityClass": -217092956,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "appProtocol": "ip",
          "applicationGroup": "other",
          "applicationType": "DEFAULT",
          "categoryId": "70fce3cd-f2bd-48de-9020-7b7840b9e7a1",
          "createTime": 1517966743172,
          "encrypted": "false",
          "engineId": "1",
          "helpString": "Ipv6 encapsulated",
          "ignoreConflict": false,
          "lastUpdateTime": 1517966743172,
          "longDescription": "A method and generic mechanism by which a packet is encapsulated and carried as payload within an IPv6 packet. The resulting packet is called an IPv6 tunnel packet. The forwarding path between the source and destination of the tunnel packet is called an IPv6 tunnel. The technique is called IPv6 tunneling.",
          "name": "ipv6inip",
          "nbarId": "795",
          "p2pTechnology": "false",
          "popularity": 7,
          "rank": 65535,
          "references": "http://tools.ietf.org/html/rfc2473",
          "selectorId": "41",
          "subCategory": "ab63e736-9aa7-4946-b55b-3b26ddca609c",
          "trafficClass": "BULK_DATA",
          "tunnel": "true"
        }
      ],
      "networkIdentity": [
        {
          "id": "a4e55e34-eddb-4c53-8c98-7573c24db92f",
          "instanceId": 13585,
          "authEntityId": 13585,
          "displayName": "13585",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipProtocolNumbers": "41",
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "protocol": "IP",
          "upperPort": 0
        }
      ],
      "parentScalableGroup": {
        "idRef": "79f9d505-477f-4ccd-bd2a-c5fc8097a2e1"
      },
      "user": [

      ],
      "userGroup": [

      ]
    },
    {
      "id": "b6ecf6f5-6eeb-47e1-a790-c56fb7a19b77",
      "instanceId": 15187,
      "authEntityId": 15187,
      "displayName": "15187",
      "authEntityClass": -1909073334,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "createTime": 1517966744434,
      "deployed": false,
      "isSeeded": true,
      "isStale": false,
      "lastUpdateTime": 1517966744434,
      "name": "speedtest",
      "namespace": "scalablegroup:application",
      "provisioningState": "DEFINED",
      "qualifier": "application",
      "resourceVersion": 0,
      "targetIdList": [

      ],
      "type": "scalablegroup",
      "cfsChangeInfo": [

      ],
      "customProvisions": [

      ],
      "scalableGroupExternalHandle": "speedtest",
      "scalableGroupType": "APPLICATION",
      "securityGroupTag": 0,
      "state": "ACTIVE",
      "vnAgnostic": true,
      "identitySource": {
        "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
        "instanceId": 10010,
        "authEntityId": 10010,
        "displayName": "10010",
        "authEntityClass": 1738953278,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "state": "INACTIVE",
        "type": "NBAR"
      },
      "indicativeNetworkIdentity": [

      ],
      "networkApplications": [
        {
          "id": "71f265c9-922c-46d8-8ac5-2861a6ca469a",
          "instanceId": 16130,
          "authEntityId": 16130,
          "displayName": "16130",
          "authEntityClass": -217092956,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "appProtocol": "tcp/udp",
          "applicationGroup": "other",
          "applicationType": "DEFAULT",
          "categoryId": "878644b5-fe91-45b4-9204-31c84a73ba48",
          "createTime": 1517966744434,
          "encrypted": "false",
          "engineId": "13",
          "helpString": "Speedtest - Test the download and upload speed of the internet.",
          "ignoreConflict": false,
          "lastUpdateTime": 1517966744434,
          "longDescription": "Speedtest - Test the download and upload speed of the internet.",
          "name": "speedtest",
          "nbarId": "1622",
          "p2pTechnology": "false",
          "popularity": 3,
          "rank": 65535,
          "references": "http://speedtest.net",
          "selectorId": "692",
          "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
          "trafficClass": "TRANSACTIONAL_DATA",
          "tunnel": "false"
        }
      ],
      "networkIdentity": [
        {
          "id": "fdca684f-8290-4d3d-9029-5285b61f21c0",
          "instanceId": 17200,
          "authEntityId": 17200,
          "displayName": "17200",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "53,80,443,5353",
          "protocol": "TCP",
          "upperPort": 0
        },
        {
          "id": "725fdd45-4ad7-47d3-b38b-34a6538b6955",
          "instanceId": 17201,
          "authEntityId": 17201,
          "displayName": "17201",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "53,5353",
          "protocol": "UDP",
          "upperPort": 0
        }
      ],
      "parentScalableGroup": {
        "idRef": "28e08de4-d7b2-4521-84ce-bc1e5ee0b9cc"
      },
      "user": [

      ],
      "userGroup": [

      ]
    },
    {
      "id": "b70d1990-7193-4918-9556-06a1152ed691",
      "instanceId": 4351,
      "authEntityId": 4351,
      "displayName": "4351",
      "authEntityClass": -1909073334,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "createTime": 1517966742369,
      "deployed": false,
      "isSeeded": true,
      "isStale": false,
      "lastUpdateTime": 1517966742369,
      "name": "desknets",
      "namespace": "scalablegroup:application",
      "provisioningState": "DEFINED",
      "qualifier": "application",
      "resourceVersion": 0,
      "targetIdList": [

      ],
      "type": "scalablegroup",
      "cfsChangeInfo": [

      ],
      "customProvisions": [

      ],
      "scalableGroupExternalHandle": "desknets",
      "scalableGroupType": "APPLICATION",
      "securityGroupTag": 0,
      "state": "ACTIVE",
      "vnAgnostic": true,
      "identitySource": {
        "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
        "instanceId": 10010,
        "authEntityId": 10010,
        "displayName": "10010",
        "authEntityClass": 1738953278,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "state": "INACTIVE",
        "type": "NBAR"
      },
      "indicativeNetworkIdentity": [
        {
          "id": "c9de3df5-305b-4925-a5b2-3effd0063e6f",
          "instanceId": 12835,
          "authEntityId": 12835,
          "displayName": "12835",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "52300",
          "protocol": "TCP",
          "upperPort": 0
        }
      ],
      "networkApplications": [
        {
          "id": "1cca0564-5ec2-4cce-b7b0-eaa63fbac1e7",
          "instanceId": 11302,
          "authEntityId": 11302,
          "displayName": "11302",
          "authEntityClass": -217092956,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "appProtocol": "tcp",
          "applicationGroup": "other",
          "applicationType": "DEFAULT",
          "categoryId": "6daa3dfd-3526-407a-88f0-97824b3f3a24",
          "createTime": 1517966742369,
          "encrypted": "false",
          "engineId": "3",
          "helpString": "Groupware application for resource sharing",
          "ignoreConflict": false,
          "lastUpdateTime": 1517966742369,
          "longDescription": "Desknet's (by NEO) is a Japanese groupware application for resource sharing.",
          "name": "desknets",
          "nbarId": "1339",
          "p2pTechnology": "false",
          "popularity": 1,
          "rank": 65535,
          "references": "http://www.desknets.com/",
          "selectorId": "52300",
          "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
          "trafficClass": "BULK_DATA",
          "tunnel": "false"
        }
      ],
      "networkIdentity": [
        {
          "id": "cb67de55-1cbe-4114-a473-1c5df21d1d8b",
          "instanceId": 12834,
          "authEntityId": 12834,
          "displayName": "12834",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "52300",
          "protocol": "TCP",
          "upperPort": 0
        }
      ],
      "parentScalableGroup": {
        "idRef": "000c15bf-28fe-4fbb-9b69-35e6502721f1"
      },
      "user": [

      ],
      "userGroup": [

      ]
    },
    {
      "id": "b74b3698-2936-4c3b-89a8-7a08ac367036",
      "instanceId": 4678,
      "authEntityId": 4678,
      "displayName": "4678",
      "authEntityClass": -1909073334,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "createTime": 1517966743265,
      "deployed": false,
      "isSeeded": true,
      "isStale": false,
      "lastUpdateTime": 1517966743265,
      "name": "klogin",
      "namespace": "scalablegroup:application",
      "provisioningState": "DEFINED",
      "qualifier": "application",
      "resourceVersion": 0,
      "targetIdList": [

      ],
      "type": "scalablegroup",
      "cfsChangeInfo": [

      ],
      "customProvisions": [

      ],
      "scalableGroupExternalHandle": "klogin",
      "scalableGroupType": "APPLICATION",
      "securityGroupTag": 0,
      "state": "ACTIVE",
      "vnAgnostic": true,
      "identitySource": {
        "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
        "instanceId": 10010,
        "authEntityId": 10010,
        "displayName": "10010",
        "authEntityClass": 1738953278,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "state": "INACTIVE",
        "type": "NBAR"
      },
      "indicativeNetworkIdentity": [
        {
          "id": "f8a540cc-02be-4e3b-8ea8-685c171850dc",
          "instanceId": 13706,
          "authEntityId": 13706,
          "displayName": "13706",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "543",
          "protocol": "TCP",
          "upperPort": 0
        },
        {
          "id": "0f1aa6e4-d602-4d40-9685-3843902ff35b",
          "instanceId": 13707,
          "authEntityId": 13707,
          "displayName": "13707",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "543",
          "protocol": "UDP",
          "upperPort": 0
        }
      ],
      "networkApplications": [
        {
          "id": "aa78732e-bc2c-4bdb-ac3b-f2f3f7377e39",
          "instanceId": 11628,
          "authEntityId": 11628,
          "displayName": "11628",
          "authEntityClass": -217092956,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "appProtocol": "tcp/udp",
          "applicationGroup": "other",
          "applicationType": "DEFAULT",
          "categoryId": "1fc17362-9334-4c7b-b752-5afdbb574017",
          "createTime": 1517966743265,
          "encrypted": "false",
          "engineId": "3",
          "helpString": "klogin",
          "ignoreConflict": false,
          "lastUpdateTime": 1517966743265,
          "longDescription": "Registered with IANA on port 543 TCP/UDP",
          "name": "klogin",
          "nbarId": "87",
          "p2pTechnology": "false",
          "popularity": 1,
          "rank": 65535,
          "references": "http://www.iana.org/assignments/service-names-port-numbers/service-names-port-numbers.xml",
          "selectorId": "543",
          "subCategory": "787a7d77-977b-4974-89d3-379ba49e88b7",
          "trafficClass": "OPS_ADMIN_MGMT",
          "tunnel": "false"
        }
      ],
      "networkIdentity": [
        {
          "id": "27328fe0-dcd6-4aa1-a143-348f50f4e4d6",
          "instanceId": 13704,
          "authEntityId": 13704,
          "displayName": "13704",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "543",
          "protocol": "TCP",
          "upperPort": 0
        },
        {
          "id": "c74f44eb-af34-44a2-aaa6-74410b720446",
          "instanceId": 13705,
          "authEntityId": 13705,
          "displayName": "13705",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "543",
          "protocol": "UDP",
          "upperPort": 0
        }
      ],
      "parentScalableGroup": {
        "idRef": "e06dd89f-0a70-4422-ab0b-c2314650a9a7"
      },
      "user": [

      ],
      "userGroup": [

      ]
    },
    {
      "id": "b7be5f38-a36c-48d5-989b-6b6842559f57",
      "instanceId": 15180,
      "authEntityId": 15180,
      "displayName": "15180",
      "authEntityClass": -1909073334,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "createTime": 1517966744418,
      "deployed": false,
      "isSeeded": true,
      "isStale": false,
      "lastUpdateTime": 1517966744418,
      "name": "sonar",
      "namespace": "scalablegroup:application",
      "provisioningState": "DEFINED",
      "qualifier": "application",
      "resourceVersion": 0,
      "targetIdList": [

      ],
      "type": "scalablegroup",
      "cfsChangeInfo": [

      ],
      "customProvisions": [

      ],
      "scalableGroupExternalHandle": "sonar",
      "scalableGroupType": "APPLICATION",
      "securityGroupTag": 0,
      "state": "ACTIVE",
      "vnAgnostic": true,
      "identitySource": {
        "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
        "instanceId": 10010,
        "authEntityId": 10010,
        "displayName": "10010",
        "authEntityClass": 1738953278,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "state": "INACTIVE",
        "type": "NBAR"
      },
      "indicativeNetworkIdentity": [
        {
          "id": "8d38749b-e349-4484-9fbe-04071af2d454",
          "instanceId": 17191,
          "authEntityId": 17191,
          "displayName": "17191",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "572",
          "protocol": "TCP",
          "upperPort": 0
        },
        {
          "id": "04f482cd-c803-4c84-9463-23302b4b27c8",
          "instanceId": 17192,
          "authEntityId": 17192,
          "displayName": "17192",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "572",
          "protocol": "UDP",
          "upperPort": 0
        }
      ],
      "networkApplications": [
        {
          "id": "5c4773a8-9bb3-49b8-9a19-8a10b714747d",
          "instanceId": 16123,
          "authEntityId": 16123,
          "displayName": "16123",
          "authEntityClass": -217092956,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "appProtocol": "tcp/udp",
          "applicationGroup": "other",
          "applicationType": "DEFAULT",
          "categoryId": "6daa3dfd-3526-407a-88f0-97824b3f3a24",
          "createTime": 1517966744418,
          "encrypted": "false",
          "engineId": "3",
          "helpString": "sonar",
          "ignoreConflict": false,
          "lastUpdateTime": 1517966744418,
          "longDescription": "Sonar is a service designed to allow the availability of network-accessible resources to a world wide audience by providing several copies (or mirrors) of those resources at multiple locations.",
          "name": "sonar",
          "nbarId": "486",
          "p2pTechnology": "false",
          "popularity": 1,
          "rank": 65535,
          "references": "http://tools.ietf.org/html/draft-moore-sonar-01",
          "selectorId": "572",
          "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
          "trafficClass": "BULK_DATA",
          "tunnel": "false"
        }
      ],
      "networkIdentity": [
        {
          "id": "db436d26-7052-4d56-b9e9-3d5ee946cf90",
          "instanceId": 17189,
          "authEntityId": 17189,
          "displayName": "17189",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "572",
          "protocol": "TCP",
          "upperPort": 0
        },
        {
          "id": "28b99e38-cce6-4dec-b231-234dbeae0e41",
          "instanceId": 17190,
          "authEntityId": 17190,
          "displayName": "17190",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "572",
          "protocol": "UDP",
          "upperPort": 0
        }
      ],
      "parentScalableGroup": {
        "idRef": "000c15bf-28fe-4fbb-9b69-35e6502721f1"
      },
      "user": [

      ],
      "userGroup": [

      ]
    },
    {
      "id": "b87e1623-d899-4f3b-8bb8-3b2ca0fec881",
      "instanceId": 4932,
      "authEntityId": 4932,
      "displayName": "4932",
      "authEntityClass": -1909073334,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "createTime": 1517966743837,
      "deployed": false,
      "isSeeded": true,
      "isStale": false,
      "lastUpdateTime": 1517966743837,
      "name": "p10",
      "namespace": "scalablegroup:application",
      "provisioningState": "DEFINED",
      "qualifier": "application",
      "resourceVersion": 0,
      "targetIdList": [

      ],
      "type": "scalablegroup",
      "cfsChangeInfo": [

      ],
      "customProvisions": [

      ],
      "scalableGroupExternalHandle": "p10",
      "scalableGroupType": "APPLICATION",
      "securityGroupTag": 0,
      "state": "ACTIVE",
      "vnAgnostic": true,
      "identitySource": {
        "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
        "instanceId": 10010,
        "authEntityId": 10010,
        "displayName": "10010",
        "authEntityClass": 1738953278,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "state": "INACTIVE",
        "type": "NBAR"
      },
      "indicativeNetworkIdentity": [
        {
          "id": "59d69b17-2470-4bf4-96c1-59e6b7a86ccd",
          "instanceId": 14461,
          "authEntityId": 14461,
          "displayName": "14461",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "6665,6666,6667,6668,6669",
          "protocol": "UDP",
          "upperPort": 0
        },
        {
          "id": "0572eecd-9314-43a9-9191-e6f3cb76b719",
          "instanceId": 14462,
          "authEntityId": 14462,
          "displayName": "14462",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "6665,6666,6667,6668,6669",
          "protocol": "TCP",
          "upperPort": 0
        }
      ],
      "networkApplications": [
        {
          "id": "bd103769-7087-47b4-93bc-0880af62cccb",
          "instanceId": 11881,
          "authEntityId": 11881,
          "displayName": "11881",
          "authEntityClass": -217092956,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "appProtocol": "tcp/udp",
          "applicationGroup": "other",
          "applicationType": "DEFAULT",
          "categoryId": "6daa3dfd-3526-407a-88f0-97824b3f3a24",
          "createTime": 1517966743837,
          "encrypted": "false",
          "engineId": "3",
          "helpString": "Internet relay chat",
          "ignoreConflict": false,
          "lastUpdateTime": 1517966743837,
          "longDescription": "TheP10protocol is an extension to theInternet Relay Chatprotocol (IRC) for server to server communications. It is similar in purpose toIRCXand EFnet TS5/TS6 protocols and implements nick and channel timestamping for handling nick collisions and netsplit channel riding, respectively.",
          "name": "p10",
          "nbarId": "1400",
          "p2pTechnology": "false",
          "popularity": 1,
          "rank": 65535,
          "references": "http://en.wikipedia.org/wiki/IRCd#P10",
          "selectorId": "6665",
          "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
          "trafficClass": "BULK_DATA",
          "tunnel": "false"
        }
      ],
      "networkIdentity": [
        {
          "id": "3b369aa0-9928-4bb7-b83d-7de21ad2e769",
          "instanceId": 14459,
          "authEntityId": 14459,
          "displayName": "14459",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "6665,6666,6667,6668,6669",
          "protocol": "UDP",
          "upperPort": 0
        },
        {
          "id": "c67e95b9-3d3c-4b9a-97cf-194268d1ab72",
          "instanceId": 14460,
          "authEntityId": 14460,
          "displayName": "14460",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "6665,6666,6667,6668,6669",
          "protocol": "TCP",
          "upperPort": 0
        }
      ],
      "parentScalableGroup": {
        "idRef": "000c15bf-28fe-4fbb-9b69-35e6502721f1"
      },
      "user": [

      ],
      "userGroup": [

      ]
    },
    {
      "id": "b8a1dc14-26c8-496f-bdca-882ce6d4214f",
      "instanceId": 4439,
      "authEntityId": 4439,
      "displayName": "4439",
      "authEntityClass": -1909073334,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "createTime": 1517966742661,
      "deployed": false,
      "isSeeded": true,
      "isStale": false,
      "lastUpdateTime": 1517966742661,
      "name": "facetime",
      "namespace": "scalablegroup:application",
      "provisioningState": "DEFINED",
      "qualifier": "application",
      "resourceVersion": 0,
      "targetIdList": [

      ],
      "type": "scalablegroup",
      "cfsChangeInfo": [

      ],
      "customProvisions": [

      ],
      "scalableGroupExternalHandle": "facetime",
      "scalableGroupType": "APPLICATION",
      "securityGroupTag": 0,
      "state": "ACTIVE",
      "vnAgnostic": true,
      "identitySource": {
        "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
        "instanceId": 10010,
        "authEntityId": 10010,
        "displayName": "10010",
        "authEntityClass": 1738953278,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "state": "INACTIVE",
        "type": "NBAR"
      },
      "indicativeNetworkIdentity": [

      ],
      "networkApplications": [
        {
          "id": "26668347-fe69-46e6-a36f-59be984cc300",
          "instanceId": 11390,
          "authEntityId": 11390,
          "displayName": "11390",
          "authEntityClass": -217092956,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "appProtocol": "tcp/udp",
          "applicationGroup": "other",
          "applicationType": "DEFAULT",
          "categoryId": "82a7b7fa-da5a-48f5-8b6a-a2546b947843",
          "createTime": 1517966742661,
          "encrypted": "true",
          "engineId": "13",
          "helpString": "Facetime video calling software",
          "ignoreConflict": false,
          "lastUpdateTime": 1517966742661,
          "longDescription": "FaceTime is a video calling (video telephone) software application and related protocol  developed by Apple Inc. for supported mobile devices running iOS, in addition to Macintosh computers running Mac OS X 10.6.6 and higher. FaceTime is supported on any iOS device with a forward-facing camera (that is, all iOS devices released since the iPhone 4) and on any Macintosh computer equipped with a FaceTime Camera (formerly known as an iSight Camera).",
          "name": "facetime",
          "nbarId": "1469",
          "p2pTechnology": "false",
          "popularity": 10,
          "rank": 65535,
          "references": "http://www.apple.com/iphone/built-in-apps/facetime.html",
          "selectorId": "535",
          "subCategory": "118c10a2-e272-47c1-92b9-4814ebe97138",
          "trafficClass": "MULTIMEDIA_CONFERENCING",
          "tunnel": "false"
        }
      ],
      "networkIdentity": [
        {
          "id": "475334ea-b295-4d3a-a0a4-6836a4f7b121",
          "instanceId": 13096,
          "authEntityId": 13096,
          "displayName": "13096",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "53,80,443,3478,5060,5353",
          "protocol": "TCP",
          "upperPort": 0
        },
        {
          "id": "a90e9702-d931-488f-a1c8-31a3d6e284ef",
          "instanceId": 13097,
          "authEntityId": 13097,
          "displayName": "13097",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "53,3478,5060,5353",
          "protocol": "UDP",
          "upperPort": 0
        }
      ],
      "parentScalableGroup": {
        "idRef": "fd6264c9-c16a-4311-a335-a3db039256f3"
      },
      "user": [

      ],
      "userGroup": [

      ]
    },
    {
      "id": "b8cd7f8b-b49b-4259-9ea4-0525184802a0",
      "instanceId": 15352,
      "authEntityId": 15352,
      "displayName": "15352",
      "authEntityClass": -1909073334,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "createTime": 1517966744789,
      "deployed": false,
      "isSeeded": true,
      "isStale": false,
      "lastUpdateTime": 1517966744789,
      "name": "videotex",
      "namespace": "scalablegroup:application",
      "provisioningState": "DEFINED",
      "qualifier": "application",
      "resourceVersion": 0,
      "targetIdList": [

      ],
      "type": "scalablegroup",
      "cfsChangeInfo": [

      ],
      "customProvisions": [

      ],
      "scalableGroupExternalHandle": "videotex",
      "scalableGroupType": "APPLICATION",
      "securityGroupTag": 0,
      "state": "ACTIVE",
      "vnAgnostic": true,
      "identitySource": {
        "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
        "instanceId": 10010,
        "authEntityId": 10010,
        "displayName": "10010",
        "authEntityClass": 1738953278,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "state": "INACTIVE",
        "type": "NBAR"
      },
      "indicativeNetworkIdentity": [
        {
          "id": "64f9d023-e50a-4a64-8c85-f42c484d55b5",
          "instanceId": 17673,
          "authEntityId": 17673,
          "displayName": "17673",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "516",
          "protocol": "UDP",
          "upperPort": 0
        },
        {
          "id": "9a5259af-a0ae-462d-ac54-e98ebc886e70",
          "instanceId": 17674,
          "authEntityId": 17674,
          "displayName": "17674",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "516",
          "protocol": "TCP",
          "upperPort": 0
        }
      ],
      "networkApplications": [
        {
          "id": "3ec53d6f-6587-4d7a-8ff5-2df94fde5274",
          "instanceId": 16295,
          "authEntityId": 16295,
          "displayName": "16295",
          "authEntityClass": -217092956,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "appProtocol": "tcp/udp",
          "applicationGroup": "other",
          "applicationType": "DEFAULT",
          "categoryId": "6daa3dfd-3526-407a-88f0-97824b3f3a24",
          "createTime": 1517966744789,
          "encrypted": "false",
          "engineId": "3",
          "helpString": "videotex",
          "ignoreConflict": false,
          "lastUpdateTime": 1517966744789,
          "longDescription": "Videotex was one of the earliest implementations of an end-user information system. From the late 1970s to mid-1980s, it was used to deliver information (usually pages of text) to a user in computer-like format, typically to be displayed on a television.",
          "name": "videotex",
          "nbarId": "433",
          "p2pTechnology": "false",
          "popularity": 1,
          "rank": 65535,
          "references": "http://en.wikipedia.org/wiki/Videotex",
          "selectorId": "516",
          "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
          "trafficClass": "BULK_DATA",
          "tunnel": "false"
        }
      ],
      "networkIdentity": [
        {
          "id": "6a3e7235-b710-47ca-93ad-c695c26bbf91",
          "instanceId": 17671,
          "authEntityId": 17671,
          "displayName": "17671",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "516",
          "protocol": "UDP",
          "upperPort": 0
        },
        {
          "id": "ec55c16a-97f3-4686-8716-48b32dd45270",
          "instanceId": 17672,
          "authEntityId": 17672,
          "displayName": "17672",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "516",
          "protocol": "TCP",
          "upperPort": 0
        }
      ],
      "parentScalableGroup": {
        "idRef": "000c15bf-28fe-4fbb-9b69-35e6502721f1"
      },
      "user": [

      ],
      "userGroup": [

      ]
    },
    {
      "id": "b8cfc724-ef0f-4149-a906-df67583bc353",
      "instanceId": 4729,
      "authEntityId": 4729,
      "displayName": "4729",
      "authEntityClass": -1909073334,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "createTime": 1517966743372,
      "deployed": false,
      "isSeeded": true,
      "isStale": false,
      "lastUpdateTime": 1517966743372,
      "name": "mcafee-update",
      "namespace": "scalablegroup:application",
      "provisioningState": "DEFINED",
      "qualifier": "application",
      "resourceVersion": 0,
      "targetIdList": [

      ],
      "type": "scalablegroup",
      "cfsChangeInfo": [

      ],
      "customProvisions": [

      ],
      "scalableGroupExternalHandle": "mcafee-update",
      "scalableGroupType": "APPLICATION",
      "securityGroupTag": 0,
      "state": "ACTIVE",
      "vnAgnostic": true,
      "identitySource": {
        "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
        "instanceId": 10010,
        "authEntityId": 10010,
        "displayName": "10010",
        "authEntityClass": 1738953278,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "state": "INACTIVE",
        "type": "NBAR"
      },
      "indicativeNetworkIdentity": [

      ],
      "networkApplications": [
        {
          "id": "3989525a-7e6c-41ec-be58-20ecc8f0fc19",
          "instanceId": 11679,
          "authEntityId": 11679,
          "displayName": "11679",
          "authEntityClass": -217092956,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "applicationGroup": "other",
          "applicationType": "DEFAULT",
          "categoryId": "6daa3dfd-3526-407a-88f0-97824b3f3a24",
          "createTime": 1517966743372,
          "encrypted": "false",
          "engineId": "3",
          "helpString": "DEPRECATED, traffic will not match",
          "ignoreConflict": false,
          "lastUpdateTime": 1517966743372,
          "longDescription": "McAfee AutoUpdate is the virus signature updating service for McAfee VirusScan. This service provides a mechanism for customers to monitor and measure the average bandwidth used in updates.",
          "name": "mcafee-update",
          "nbarId": "1354",
          "p2pTechnology": "false",
          "popularity": 8,
          "rank": 65535,
          "references": "http://www.mcafee.com/",
          "selectorId": "8801",
          "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
          "trafficClass": "BULK_DATA",
          "tunnel": "false"
        }
      ],
      "networkIdentity": [

      ],
      "parentScalableGroup": {
        "idRef": "4a68bc73-53c6-4deb-9983-784a0338aefe"
      },
      "user": [

      ],
      "userGroup": [

      ]
    },
    {
      "id": "b8f7fa3d-02ff-45d1-9f88-ed21ae64f270",
      "instanceId": 15178,
      "authEntityId": 15178,
      "displayName": "15178",
      "authEntityClass": -1909073334,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "createTime": 1517966744413,
      "deployed": false,
      "isSeeded": true,
      "isStale": false,
      "lastUpdateTime": 1517966744413,
      "name": "softros-messenger-ft",
      "namespace": "scalablegroup:application",
      "provisioningState": "DEFINED",
      "qualifier": "application",
      "resourceVersion": 0,
      "targetIdList": [

      ],
      "type": "scalablegroup",
      "cfsChangeInfo": [

      ],
      "customProvisions": [

      ],
      "scalableGroupExternalHandle": "softros-messenger-ft",
      "scalableGroupType": "APPLICATION",
      "securityGroupTag": 0,
      "state": "ACTIVE",
      "vnAgnostic": true,
      "identitySource": {
        "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
        "instanceId": 10010,
        "authEntityId": 10010,
        "displayName": "10010",
        "authEntityClass": 1738953278,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "state": "INACTIVE",
        "type": "NBAR"
      },
      "indicativeNetworkIdentity": [
        {
          "id": "88d3546a-0f2a-416f-8c4a-660ca4ca4313",
          "instanceId": 17186,
          "authEntityId": 17186,
          "displayName": "17186",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "19880",
          "protocol": "TCP",
          "upperPort": 0
        }
      ],
      "networkApplications": [
        {
          "id": "b33cf0b7-3b7a-462b-a30a-1ae5e0853e05",
          "instanceId": 16121,
          "authEntityId": 16121,
          "displayName": "16121",
          "authEntityClass": -217092956,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "appProtocol": "tcp",
          "applicationGroup": "other",
          "applicationType": "DEFAULT",
          "categoryId": "e6619ce8-97de-4c63-a53a-cceddbddc1a8",
          "createTime": 1517966744413,
          "encrypted": "false",
          "engineId": "3",
          "helpString": "Softros LAN Messenger and File Transfer",
          "ignoreConflict": false,
          "lastUpdateTime": 1517966744413,
          "longDescription": "Softros LAN Messenger is a secure serverless instant messaging program for user-to-user or user-to-group message and file exchange through a network (LAN, WAN, VPN). Softros Messenger FT is the file transfer protocol of the Softros LAM Messenger.",
          "name": "softros-messenger-ft",
          "nbarId": "1365",
          "p2pTechnology": "true",
          "popularity": 1,
          "rank": 65535,
          "references": "http://messenger.softros.com",
          "selectorId": "19880",
          "subCategory": "50b55e2e-d0d0-4404-bea4-819351565d90",
          "trafficClass": "BULK_DATA",
          "tunnel": "false"
        }
      ],
      "networkIdentity": [
        {
          "id": "628b75bf-b452-48b3-8284-0fe24004943a",
          "instanceId": 17185,
          "authEntityId": 17185,
          "displayName": "17185",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "19880",
          "protocol": "TCP",
          "upperPort": 0
        }
      ],
      "parentScalableGroup": {
        "idRef": "383363b1-4dd5-49d1-bfe4-34daac437cfb"
      },
      "user": [

      ],
      "userGroup": [

      ]
    },
    {
      "id": "b8fbe293-2cb5-4a07-92a8-6adde0cbad72",
      "instanceId": 4749,
      "authEntityId": 4749,
      "displayName": "4749",
      "authEntityClass": -1909073334,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "createTime": 1517966743417,
      "deployed": false,
      "isSeeded": true,
      "isStale": false,
      "lastUpdateTime": 1517966743417,
      "name": "mint-com",
      "namespace": "scalablegroup:application",
      "provisioningState": "DEFINED",
      "qualifier": "application",
      "resourceVersion": 0,
      "targetIdList": [

      ],
      "type": "scalablegroup",
      "cfsChangeInfo": [

      ],
      "customProvisions": [

      ],
      "scalableGroupExternalHandle": "mint-com",
      "scalableGroupType": "APPLICATION",
      "securityGroupTag": 0,
      "state": "ACTIVE",
      "vnAgnostic": true,
      "identitySource": {
        "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
        "instanceId": 10010,
        "authEntityId": 10010,
        "displayName": "10010",
        "authEntityClass": 1738953278,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "state": "INACTIVE",
        "type": "NBAR"
      },
      "indicativeNetworkIdentity": [

      ],
      "networkApplications": [
        {
          "id": "f5ea9a93-9909-49d5-98b5-5dc69e8c17e7",
          "instanceId": 11699,
          "authEntityId": 11699,
          "displayName": "11699",
          "authEntityClass": -217092956,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "appProtocol": "tcp/udp",
          "applicationGroup": "other",
          "applicationType": "DEFAULT",
          "categoryId": "878644b5-fe91-45b4-9204-31c84a73ba48",
          "createTime": 1517966743417,
          "encrypted": "false",
          "engineId": "13",
          "helpString": "Mint.com - Web-based personal finance tool.",
          "ignoreConflict": false,
          "lastUpdateTime": 1517966743417,
          "longDescription": "Mint.com - Web-based personal finance tool.",
          "name": "mint-com",
          "nbarId": "1689",
          "p2pTechnology": "false",
          "popularity": 3,
          "rank": 65535,
          "references": "http://mint.com",
          "selectorId": "759",
          "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
          "trafficClass": "TRANSACTIONAL_DATA",
          "tunnel": "false"
        }
      ],
      "networkIdentity": [
        {
          "id": "95a89bcd-d234-4ea5-b21e-8c43602aef88",
          "instanceId": 13910,
          "authEntityId": 13910,
          "displayName": "13910",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "53,5353",
          "protocol": "UDP",
          "upperPort": 0
        },
        {
          "id": "92bdb7a6-5c18-4d1d-a84b-295e27a771e9",
          "instanceId": 13911,
          "authEntityId": 13911,
          "displayName": "13911",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "53,80,443,5353",
          "protocol": "TCP",
          "upperPort": 0
        }
      ],
      "parentScalableGroup": {
        "idRef": "28e08de4-d7b2-4521-84ce-bc1e5ee0b9cc"
      },
      "user": [

      ],
      "userGroup": [

      ]
    },
    {
      "id": "b8fdeb04-e0d7-4d8a-9f2a-373d61f4f09d",
      "instanceId": 4429,
      "authEntityId": 4429,
      "displayName": "4429",
      "authEntityClass": -1909073334,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "createTime": 1517966742631,
      "deployed": false,
      "isSeeded": true,
      "isStale": false,
      "lastUpdateTime": 1517966742631,
      "name": "eudora-set",
      "namespace": "scalablegroup:application",
      "provisioningState": "DEFINED",
      "qualifier": "application",
      "resourceVersion": 0,
      "targetIdList": [

      ],
      "type": "scalablegroup",
      "cfsChangeInfo": [

      ],
      "customProvisions": [

      ],
      "scalableGroupExternalHandle": "eudora-set",
      "scalableGroupType": "APPLICATION",
      "securityGroupTag": 0,
      "state": "ACTIVE",
      "vnAgnostic": true,
      "identitySource": {
        "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
        "instanceId": 10010,
        "authEntityId": 10010,
        "displayName": "10010",
        "authEntityClass": 1738953278,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "state": "INACTIVE",
        "type": "NBAR"
      },
      "indicativeNetworkIdentity": [
        {
          "id": "144e6d49-6df6-4642-ac6b-6470abe066d8",
          "instanceId": 13083,
          "authEntityId": 13083,
          "displayName": "13083",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "592",
          "protocol": "UDP",
          "upperPort": 0
        },
        {
          "id": "2f194aa6-413e-4027-bb05-61e71216697d",
          "instanceId": 13084,
          "authEntityId": 13084,
          "displayName": "13084",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "592",
          "protocol": "TCP",
          "upperPort": 0
        }
      ],
      "networkApplications": [
        {
          "id": "b28b083b-9e14-479b-9e13-c1c5792f7750",
          "instanceId": 11380,
          "authEntityId": 11380,
          "displayName": "11380",
          "authEntityClass": -217092956,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "appProtocol": "tcp/udp",
          "applicationGroup": "other",
          "applicationType": "DEFAULT",
          "categoryId": "27953c60-b2ff-4e1a-bc52-0187d8295250",
          "createTime": 1517966742631,
          "encrypted": "false",
          "engineId": "3",
          "helpString": "Eudora Set",
          "ignoreConflict": false,
          "lastUpdateTime": 1517966742631,
          "longDescription": "Registered with IANA on port 592 TCP/UDP",
          "name": "eudora-set",
          "nbarId": "506",
          "p2pTechnology": "false",
          "popularity": 1,
          "rank": 65535,
          "references": "http://www.iana.org/assignments/service-names-port-numbers/service-names-port-numbers.xml",
          "selectorId": "592",
          "subCategory": "b90786f4-0190-45b8-9924-21b3e9682590",
          "trafficClass": "BULK_DATA",
          "tunnel": "false"
        }
      ],
      "networkIdentity": [
        {
          "id": "790a30a6-a050-4209-a683-e8db3e20a65a",
          "instanceId": 13081,
          "authEntityId": 13081,
          "displayName": "13081",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "592",
          "protocol": "UDP",
          "upperPort": 0
        },
        {
          "id": "32f05a15-5e25-461f-825c-0081fdd72ee1",
          "instanceId": 13082,
          "authEntityId": 13082,
          "displayName": "13082",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "592",
          "protocol": "TCP",
          "upperPort": 0
        }
      ],
      "parentScalableGroup": {
        "idRef": "8a7ca43a-32bd-40d2-871a-02220a165cb7"
      },
      "user": [

      ],
      "userGroup": [

      ]
    },
    {
      "id": "b98f593d-21f1-483a-8a58-74b838e82635",
      "instanceId": 15251,
      "authEntityId": 15251,
      "displayName": "15251",
      "authEntityClass": -1909073334,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "createTime": 1517966744582,
      "deployed": false,
      "isSeeded": true,
      "isStale": false,
      "lastUpdateTime": 1517966744582,
      "name": "tagged-com",
      "namespace": "scalablegroup:application",
      "provisioningState": "DEFINED",
      "qualifier": "application",
      "resourceVersion": 0,
      "targetIdList": [

      ],
      "type": "scalablegroup",
      "cfsChangeInfo": [

      ],
      "customProvisions": [

      ],
      "scalableGroupExternalHandle": "tagged-com",
      "scalableGroupType": "APPLICATION",
      "securityGroupTag": 0,
      "state": "ACTIVE",
      "vnAgnostic": true,
      "identitySource": {
        "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
        "instanceId": 10010,
        "authEntityId": 10010,
        "displayName": "10010",
        "authEntityClass": 1738953278,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "state": "INACTIVE",
        "type": "NBAR"
      },
      "indicativeNetworkIdentity": [

      ],
      "networkApplications": [
        {
          "id": "b46adb18-4e24-4eb8-a9fb-ac167e2322f3",
          "instanceId": 16194,
          "authEntityId": 16194,
          "displayName": "16194",
          "authEntityClass": -217092956,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "appProtocol": "tcp/udp",
          "applicationGroup": "other",
          "applicationType": "DEFAULT",
          "categoryId": "878644b5-fe91-45b4-9204-31c84a73ba48",
          "createTime": 1517966744582,
          "encrypted": "false",
          "engineId": "13",
          "helpString": "Tagged - Social networking site based in California.",
          "ignoreConflict": false,
          "lastUpdateTime": 1517966744582,
          "longDescription": "Tagged - Social networking site based in California.",
          "name": "tagged-com",
          "nbarId": "1726",
          "p2pTechnology": "false",
          "popularity": 3,
          "rank": 65535,
          "references": "http://tagged.com",
          "selectorId": "798",
          "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
          "trafficClass": "TRANSACTIONAL_DATA",
          "tunnel": "false"
        }
      ],
      "networkIdentity": [
        {
          "id": "eb4d65c9-011f-4c3b-971e-b9a42abdabe8",
          "instanceId": 17392,
          "authEntityId": 17392,
          "displayName": "17392",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "53,5353",
          "protocol": "UDP",
          "upperPort": 0
        },
        {
          "id": "db2bb6c1-a02c-44a4-bb5a-9bab62ca9047",
          "instanceId": 17393,
          "authEntityId": 17393,
          "displayName": "17393",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "53,80,443,5353",
          "protocol": "TCP",
          "upperPort": 0
        }
      ],
      "parentScalableGroup": {
        "idRef": "28e08de4-d7b2-4521-84ce-bc1e5ee0b9cc"
      },
      "user": [

      ],
      "userGroup": [

      ]
    },
    {
      "id": "b9ec651f-c792-40f0-882f-bf87ce70a387",
      "instanceId": 4075,
      "authEntityId": 4075,
      "displayName": "4075",
      "authEntityClass": -1909073334,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "createTime": 1517966740847,
      "deployed": false,
      "isSeeded": true,
      "isStale": false,
      "lastUpdateTime": 1517966740847,
      "name": "amanda",
      "namespace": "scalablegroup:application",
      "provisioningState": "DEFINED",
      "qualifier": "application",
      "resourceVersion": 0,
      "targetIdList": [

      ],
      "type": "scalablegroup",
      "cfsChangeInfo": [

      ],
      "customProvisions": [

      ],
      "scalableGroupExternalHandle": "amanda",
      "scalableGroupType": "APPLICATION",
      "securityGroupTag": 0,
      "state": "ACTIVE",
      "vnAgnostic": true,
      "identitySource": {
        "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
        "instanceId": 10010,
        "authEntityId": 10010,
        "displayName": "10010",
        "authEntityClass": 1738953278,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "state": "INACTIVE",
        "type": "NBAR"
      },
      "indicativeNetworkIdentity": [
        {
          "id": "3afe5f00-9a74-4e63-bc79-c22370b8ed68",
          "instanceId": 12106,
          "authEntityId": 12106,
          "displayName": "12106",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "10080",
          "protocol": "TCP",
          "upperPort": 0
        },
        {
          "id": "0e272900-54f7-4a93-9766-5a348a228b01",
          "instanceId": 12107,
          "authEntityId": 12107,
          "displayName": "12107",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "10080",
          "protocol": "UDP",
          "upperPort": 0
        }
      ],
      "networkApplications": [
        {
          "id": "1bc8fc02-ff1f-4bc3-b39f-66a18f66847f",
          "instanceId": 11042,
          "authEntityId": 11042,
          "displayName": "11042",
          "authEntityClass": -217092956,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "appProtocol": "tcp/udp",
          "applicationGroup": "other",
          "applicationType": "DEFAULT",
          "categoryId": "6daa3dfd-3526-407a-88f0-97824b3f3a24",
          "createTime": 1517966740847,
          "encrypted": "false",
          "engineId": "3",
          "helpString": "Advanced Maryland Automatic Network Disk Archiver",
          "ignoreConflict": false,
          "lastUpdateTime": 1517966740847,
          "longDescription": "Advanced Maryland Automatic Network Disk Archiver",
          "name": "amanda",
          "nbarId": "1492",
          "p2pTechnology": "false",
          "popularity": 1,
          "rank": 65535,
          "references": "http://amanda.org/",
          "selectorId": "10080",
          "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
          "trafficClass": "BULK_DATA",
          "tunnel": "false"
        }
      ],
      "networkIdentity": [
        {
          "id": "eea83043-8350-4783-b28b-5371132cbeb6",
          "instanceId": 12104,
          "authEntityId": 12104,
          "displayName": "12104",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "10080",
          "protocol": "UDP",
          "upperPort": 0
        },
        {
          "id": "f5f4bf9d-0ed3-4837-ab0a-ad10fa8ba599",
          "instanceId": 12105,
          "authEntityId": 12105,
          "displayName": "12105",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "10080",
          "protocol": "TCP",
          "upperPort": 0
        }
      ],
      "parentScalableGroup": {
        "idRef": "000c15bf-28fe-4fbb-9b69-35e6502721f1"
      },
      "user": [

      ],
      "userGroup": [

      ]
    },
    {
      "id": "ba02bd4c-6ca3-4a2b-a723-81e6d1c8c52f",
      "instanceId": 15196,
      "authEntityId": 15196,
      "displayName": "15196",
      "authEntityClass": -1909073334,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "createTime": 1517966744454,
      "deployed": false,
      "isSeeded": true,
      "isStale": false,
      "lastUpdateTime": 1517966744454,
      "name": "sqlnet",
      "namespace": "scalablegroup:application",
      "provisioningState": "DEFINED",
      "qualifier": "application",
      "resourceVersion": 0,
      "targetIdList": [

      ],
      "type": "scalablegroup",
      "cfsChangeInfo": [

      ],
      "customProvisions": [

      ],
      "scalableGroupExternalHandle": "sqlnet",
      "scalableGroupType": "APPLICATION",
      "securityGroupTag": 0,
      "state": "ACTIVE",
      "vnAgnostic": true,
      "identitySource": {
        "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
        "instanceId": 10010,
        "authEntityId": 10010,
        "displayName": "10010",
        "authEntityClass": 1738953278,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "state": "INACTIVE",
        "type": "NBAR"
      },
      "indicativeNetworkIdentity": [

      ],
      "networkApplications": [
        {
          "id": "219fe204-44e7-4584-a9d3-2693c09494df",
          "instanceId": 16139,
          "authEntityId": 16139,
          "displayName": "16139",
          "authEntityClass": -217092956,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "applicationGroup": "sqlsvr-group",
          "applicationType": "DEFAULT",
          "categoryId": "cb5915d1-afec-4c30-ac9e-069ff382272f",
          "createTime": 1517966744454,
          "encrypted": "false",
          "engineId": "3",
          "helpString": "DEPRECATED, Please refer to oracle-sqlnet",
          "ignoreConflict": false,
          "lastUpdateTime": 1517966744454,
          "longDescription": "SQL*NET is a client-server middleware used to transfer information between databases and between database to clients.",
          "name": "sqlnet",
          "nbarId": "51",
          "p2pTechnology": "false",
          "popularity": 0,
          "rank": 65535,
          "references": "http://www.orafaq.com/wiki/SQL*Net",
          "selectorId": "1700",
          "subCategory": "b90786f4-0190-45b8-9924-21b3e9682590",
          "trafficClass": "TRANSACTIONAL_DATA",
          "tunnel": "false"
        }
      ],
      "networkIdentity": [

      ],
      "parentScalableGroup": {
        "idRef": "8718395e-683e-4a2d-a232-100202751271"
      },
      "user": [

      ],
      "userGroup": [

      ]
    },
    {
      "id": "ba20d38d-9c5f-4d6b-81a0-1d8e78fc1d8c",
      "instanceId": 4095,
      "authEntityId": 4095,
      "displayName": "4095",
      "authEntityClass": -1909073334,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "createTime": 1517966741054,
      "deployed": false,
      "isSeeded": true,
      "isStale": false,
      "lastUpdateTime": 1517966741054,
      "name": "aol-messenger-ft",
      "namespace": "scalablegroup:application",
      "provisioningState": "DEFINED",
      "qualifier": "application",
      "resourceVersion": 0,
      "targetIdList": [

      ],
      "type": "scalablegroup",
      "cfsChangeInfo": [

      ],
      "customProvisions": [

      ],
      "scalableGroupExternalHandle": "aol-messenger-ft",
      "scalableGroupType": "APPLICATION",
      "securityGroupTag": 0,
      "state": "ACTIVE",
      "vnAgnostic": true,
      "identitySource": {
        "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
        "instanceId": 10010,
        "authEntityId": 10010,
        "displayName": "10010",
        "authEntityClass": 1738953278,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "state": "INACTIVE",
        "type": "NBAR"
      },
      "indicativeNetworkIdentity": [

      ],
      "networkApplications": [
        {
          "id": "8a1b7ac1-934a-4d1a-84e3-d32e7a61e0f9",
          "instanceId": 11059,
          "authEntityId": 11059,
          "displayName": "11059",
          "authEntityClass": -217092956,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "applicationGroup": "aol-group",
          "applicationType": "DEFAULT",
          "categoryId": "b33b2761-d981-4734-810a-a6a1c18ab352",
          "createTime": 1517966741054,
          "encrypted": "false",
          "engineId": "13",
          "helpString": "DEPRECATED, Please refer to oscar-filetransfer",
          "ignoreConflict": false,
          "lastUpdateTime": 1517966741054,
          "longDescription": "AOL Instant Messenger File transfer flows classification. AIM File transfer flows Flows are TCP flows.",
          "name": "aol-messenger-ft",
          "nbarId": "1438",
          "p2pTechnology": "false",
          "popularity": 0,
          "rank": 65535,
          "references": "http://www.aim.com",
          "selectorId": "502",
          "subCategory": "118c10a2-e272-47c1-92b9-4814ebe97138",
          "trafficClass": "MULTIMEDIA_CONFERENCING",
          "tunnel": "false"
        }
      ],
      "networkIdentity": [

      ],
      "parentScalableGroup": {
        "idRef": "7a1a3bbb-dc84-4a35-82a4-7e650c4c4761"
      },
      "user": [

      ],
      "userGroup": [

      ]
    },
    {
      "id": "ba2f3bd9-aa79-454a-bf53-144e5ce5a49c",
      "instanceId": 4576,
      "authEntityId": 4576,
      "displayName": "4576",
      "authEntityClass": -1909073334,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "createTime": 1517966743034,
      "deployed": false,
      "isSeeded": true,
      "isStale": false,
      "lastUpdateTime": 1517966743034,
      "name": "ibm-db2",
      "namespace": "scalablegroup:application",
      "provisioningState": "DEFINED",
      "qualifier": "application",
      "resourceVersion": 0,
      "targetIdList": [

      ],
      "type": "scalablegroup",
      "cfsChangeInfo": [

      ],
      "customProvisions": [

      ],
      "scalableGroupExternalHandle": "ibm-db2",
      "scalableGroupType": "APPLICATION",
      "securityGroupTag": 0,
      "state": "ACTIVE",
      "vnAgnostic": true,
      "identitySource": {
        "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
        "instanceId": 10010,
        "authEntityId": 10010,
        "displayName": "10010",
        "authEntityClass": 1738953278,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "state": "INACTIVE",
        "type": "NBAR"
      },
      "indicativeNetworkIdentity": [
        {
          "id": "d9fc1b28-1167-4e27-beda-be0c6348c03a",
          "instanceId": 13445,
          "authEntityId": 13445,
          "displayName": "13445",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "523",
          "protocol": "UDP",
          "upperPort": 0
        },
        {
          "id": "cc97caa5-3ae4-40bf-bd56-8b68c34206a5",
          "instanceId": 13446,
          "authEntityId": 13446,
          "displayName": "13446",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "523",
          "protocol": "TCP",
          "upperPort": 0
        }
      ],
      "networkApplications": [
        {
          "id": "8c9649be-0c00-4a31-8b52-175460962931",
          "instanceId": 11526,
          "authEntityId": 11526,
          "displayName": "11526",
          "authEntityClass": -217092956,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "appProtocol": "tcp/udp",
          "applicationGroup": "other",
          "applicationType": "DEFAULT",
          "categoryId": "cb5915d1-afec-4c30-ac9e-069ff382272f",
          "createTime": 1517966743034,
          "encrypted": "false",
          "engineId": "3",
          "helpString": "IBM-DB2",
          "ignoreConflict": false,
          "lastUpdateTime": 1517966743034,
          "longDescription": "IBM DB2 is a database software solution that works on different operating systems (Linux, Unix, Windows) which provide performance for mixed workloads on distributed systems, and offers efficiencies for staffing and storage.",
          "name": "ibm-db2",
          "nbarId": "95",
          "p2pTechnology": "false",
          "popularity": 5,
          "rank": 65535,
          "references": "http://www-01.ibm.com/software/data/db2/",
          "selectorId": "523",
          "subCategory": "b90786f4-0190-45b8-9924-21b3e9682590",
          "trafficClass": "TRANSACTIONAL_DATA",
          "tunnel": "false"
        }
      ],
      "networkIdentity": [
        {
          "id": "a1a31328-034a-491b-a3e4-afceb6224792",
          "instanceId": 13443,
          "authEntityId": 13443,
          "displayName": "13443",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "523",
          "protocol": "UDP",
          "upperPort": 0
        },
        {
          "id": "5b9e5561-73f3-4a5a-a91a-00d5c58fe555",
          "instanceId": 13444,
          "authEntityId": 13444,
          "displayName": "13444",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "523",
          "protocol": "TCP",
          "upperPort": 0
        }
      ],
      "parentScalableGroup": {
        "idRef": "8718395e-683e-4a2d-a232-100202751271"
      },
      "user": [

      ],
      "userGroup": [

      ]
    },
    {
      "id": "ba421d5d-1e73-4c16-9408-a19b84d79c18",
      "instanceId": 4164,
      "authEntityId": 4164,
      "displayName": "4164",
      "authEntityClass": -1909073334,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "createTime": 1517966741460,
      "deployed": false,
      "isSeeded": true,
      "isStale": false,
      "lastUpdateTime": 1517966741460,
      "name": "banyan-rpc",
      "namespace": "scalablegroup:application",
      "provisioningState": "DEFINED",
      "qualifier": "application",
      "resourceVersion": 0,
      "targetIdList": [

      ],
      "type": "scalablegroup",
      "cfsChangeInfo": [

      ],
      "customProvisions": [

      ],
      "scalableGroupExternalHandle": "banyan-rpc",
      "scalableGroupType": "APPLICATION",
      "securityGroupTag": 0,
      "state": "ACTIVE",
      "vnAgnostic": true,
      "identitySource": {
        "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
        "instanceId": 10010,
        "authEntityId": 10010,
        "displayName": "10010",
        "authEntityClass": 1738953278,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "state": "INACTIVE",
        "type": "NBAR"
      },
      "indicativeNetworkIdentity": [
        {
          "id": "97127d17-5445-4a96-a8be-1cda19eed3a7",
          "instanceId": 12338,
          "authEntityId": 12338,
          "displayName": "12338",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "567",
          "protocol": "UDP",
          "upperPort": 0
        },
        {
          "id": "f65faa33-2bcf-4c4d-aa8b-8fea15ebfb6d",
          "instanceId": 12339,
          "authEntityId": 12339,
          "displayName": "12339",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "567",
          "protocol": "TCP",
          "upperPort": 0
        }
      ],
      "networkApplications": [
        {
          "id": "27c2305c-3c5b-4f33-8a17-77f48f549295",
          "instanceId": 11123,
          "authEntityId": 11123,
          "displayName": "11123",
          "authEntityClass": -217092956,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "appProtocol": "tcp/udp",
          "applicationGroup": "banyan-group",
          "applicationType": "DEFAULT",
          "categoryId": "58aa61dc-dd95-4a6d-9c7c-0ebf16da6dde",
          "createTime": 1517966741460,
          "encrypted": "false",
          "engineId": "3",
          "helpString": "banyan-rpc",
          "ignoreConflict": false,
          "lastUpdateTime": 1517966741460,
          "longDescription": "Registered with IANA on port 567 TCP/UDP",
          "name": "banyan-rpc",
          "nbarId": "482",
          "p2pTechnology": "false",
          "popularity": 1,
          "rank": 65535,
          "references": "http://www.iana.org/assignments/service-names-port-numbers/service-names-port-numbers.xml",
          "selectorId": "567",
          "subCategory": "b90786f4-0190-45b8-9924-21b3e9682590",
          "trafficClass": "TRANSACTIONAL_DATA",
          "tunnel": "false"
        }
      ],
      "networkIdentity": [
        {
          "id": "0410f207-03bf-4080-91a4-9443c1e769f5",
          "instanceId": 12336,
          "authEntityId": 12336,
          "displayName": "12336",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "567",
          "protocol": "TCP",
          "upperPort": 0
        },
        {
          "id": "a16af29b-175a-4554-8334-99c0df59ee77",
          "instanceId": 12337,
          "authEntityId": 12337,
          "displayName": "12337",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "567",
          "protocol": "UDP",
          "upperPort": 0
        }
      ],
      "parentScalableGroup": {
        "idRef": "cff11752-fafb-4e22-9e6f-11f44f59a9a3"
      },
      "user": [

      ],
      "userGroup": [

      ]
    },
    {
      "id": "ba49273b-c413-4177-9a12-f244ee2da527",
      "instanceId": 4387,
      "authEntityId": 4387,
      "displayName": "4387",
      "authEntityClass": -1909073334,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "createTime": 1517966742493,
      "deployed": false,
      "isSeeded": true,
      "isStale": false,
      "lastUpdateTime": 1517966742493,
      "name": "drudge-report-com",
      "namespace": "scalablegroup:application",
      "provisioningState": "DEFINED",
      "qualifier": "application",
      "resourceVersion": 0,
      "targetIdList": [

      ],
      "type": "scalablegroup",
      "cfsChangeInfo": [

      ],
      "customProvisions": [

      ],
      "scalableGroupExternalHandle": "drudge-report-com",
      "scalableGroupType": "APPLICATION",
      "securityGroupTag": 0,
      "state": "ACTIVE",
      "vnAgnostic": true,
      "identitySource": {
        "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
        "instanceId": 10010,
        "authEntityId": 10010,
        "displayName": "10010",
        "authEntityClass": 1738953278,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "state": "INACTIVE",
        "type": "NBAR"
      },
      "indicativeNetworkIdentity": [

      ],
      "networkApplications": [
        {
          "id": "9edb0006-0b2c-4954-9c12-a45ef334ab38",
          "instanceId": 11338,
          "authEntityId": 11338,
          "displayName": "11338",
          "authEntityClass": -217092956,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "appProtocol": "tcp/udp",
          "applicationGroup": "other",
          "applicationType": "DEFAULT",
          "categoryId": "878644b5-fe91-45b4-9204-31c84a73ba48",
          "createTime": 1517966742493,
          "encrypted": "false",
          "engineId": "13",
          "helpString": "Drudge Report - News aggregator.",
          "ignoreConflict": false,
          "lastUpdateTime": 1517966742493,
          "longDescription": "Drudge Report - News aggregator.",
          "name": "drudge-report-com",
          "nbarId": "1675",
          "p2pTechnology": "false",
          "popularity": 3,
          "rank": 65535,
          "references": "http://drudgereport.com",
          "selectorId": "745",
          "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
          "trafficClass": "TRANSACTIONAL_DATA",
          "tunnel": "false"
        }
      ],
      "networkIdentity": [
        {
          "id": "df0ff4f2-5eaf-470d-9129-a5b6b7d12376",
          "instanceId": 12954,
          "authEntityId": 12954,
          "displayName": "12954",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "53,5353",
          "protocol": "UDP",
          "upperPort": 0
        },
        {
          "id": "6a1c361a-7a68-4fd1-a5f8-9230e6dae9e0",
          "instanceId": 12955,
          "authEntityId": 12955,
          "displayName": "12955",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "53,80,443,5353",
          "protocol": "TCP",
          "upperPort": 0
        }
      ],
      "parentScalableGroup": {
        "idRef": "28e08de4-d7b2-4521-84ce-bc1e5ee0b9cc"
      },
      "user": [

      ],
      "userGroup": [

      ]
    },
    {
      "id": "ba53c637-c93c-4c0f-8997-31837926b7b8",
      "instanceId": 4753,
      "authEntityId": 4753,
      "displayName": "4753",
      "authEntityClass": -1909073334,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "createTime": 1517966743424,
      "deployed": false,
      "isSeeded": true,
      "isStale": false,
      "lastUpdateTime": 1517966743424,
      "name": "mlb-com",
      "namespace": "scalablegroup:application",
      "provisioningState": "DEFINED",
      "qualifier": "application",
      "resourceVersion": 0,
      "targetIdList": [

      ],
      "type": "scalablegroup",
      "cfsChangeInfo": [

      ],
      "customProvisions": [

      ],
      "scalableGroupExternalHandle": "mlb-com",
      "scalableGroupType": "APPLICATION",
      "securityGroupTag": 0,
      "state": "ACTIVE",
      "vnAgnostic": true,
      "identitySource": {
        "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
        "instanceId": 10010,
        "authEntityId": 10010,
        "displayName": "10010",
        "authEntityClass": 1738953278,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "state": "INACTIVE",
        "type": "NBAR"
      },
      "indicativeNetworkIdentity": [

      ],
      "networkApplications": [
        {
          "id": "f526f91e-ba63-4c1c-9819-43e22992390d",
          "instanceId": 11703,
          "authEntityId": 11703,
          "displayName": "11703",
          "authEntityClass": -217092956,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "appProtocol": "tcp/udp",
          "applicationGroup": "other",
          "applicationType": "DEFAULT",
          "categoryId": "878644b5-fe91-45b4-9204-31c84a73ba48",
          "createTime": 1517966743424,
          "encrypted": "false",
          "engineId": "13",
          "helpString": "Official Major League Baseball - Web Portal for Sports news update.",
          "ignoreConflict": false,
          "lastUpdateTime": 1517966743424,
          "longDescription": "Official Major League Baseball - Web Portal for Sports news update.",
          "name": "mlb-com",
          "nbarId": "1684",
          "p2pTechnology": "false",
          "popularity": 3,
          "rank": 65535,
          "references": "http://mlb.com",
          "selectorId": "754",
          "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
          "trafficClass": "TRANSACTIONAL_DATA",
          "tunnel": "false"
        }
      ],
      "networkIdentity": [
        {
          "id": "85702917-17ea-4c81-aa40-481256427e3e",
          "instanceId": 13922,
          "authEntityId": 13922,
          "displayName": "13922",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "53,80,443,5353",
          "protocol": "TCP",
          "upperPort": 0
        },
        {
          "id": "62d64d8f-d8ec-4415-b882-660bc25e258f",
          "instanceId": 13923,
          "authEntityId": 13923,
          "displayName": "13923",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "53,5353",
          "protocol": "UDP",
          "upperPort": 0
        }
      ],
      "parentScalableGroup": {
        "idRef": "28e08de4-d7b2-4521-84ce-bc1e5ee0b9cc"
      },
      "user": [

      ],
      "userGroup": [

      ]
    },
    {
      "id": "ba84bf1d-e3b1-46bf-a188-bb46d118ad09",
      "instanceId": 15330,
      "authEntityId": 15330,
      "displayName": "15330",
      "authEntityClass": -1909073334,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "createTime": 1517966744750,
      "deployed": false,
      "isSeeded": true,
      "isStale": false,
      "lastUpdateTime": 1517966744750,
      "name": "usaa",
      "namespace": "scalablegroup:application",
      "provisioningState": "DEFINED",
      "qualifier": "application",
      "resourceVersion": 0,
      "targetIdList": [

      ],
      "type": "scalablegroup",
      "cfsChangeInfo": [

      ],
      "customProvisions": [

      ],
      "scalableGroupExternalHandle": "usaa",
      "scalableGroupType": "APPLICATION",
      "securityGroupTag": 0,
      "state": "ACTIVE",
      "vnAgnostic": true,
      "identitySource": {
        "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
        "instanceId": 10010,
        "authEntityId": 10010,
        "displayName": "10010",
        "authEntityClass": 1738953278,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "state": "INACTIVE",
        "type": "NBAR"
      },
      "indicativeNetworkIdentity": [

      ],
      "networkApplications": [
        {
          "id": "d17a555b-dbfb-4942-ad13-6f89963f0c65",
          "instanceId": 16273,
          "authEntityId": 16273,
          "displayName": "16273",
          "authEntityClass": -217092956,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "appProtocol": "tcp/udp",
          "applicationGroup": "other",
          "applicationType": "DEFAULT",
          "categoryId": "878644b5-fe91-45b4-9204-31c84a73ba48",
          "createTime": 1517966744750,
          "encrypted": "false",
          "engineId": "13",
          "helpString": "USAA - Insurance company.",
          "ignoreConflict": false,
          "lastUpdateTime": 1517966744750,
          "longDescription": "USAA - Insurance company.",
          "name": "usaa",
          "nbarId": "1710",
          "p2pTechnology": "false",
          "popularity": 3,
          "rank": 65535,
          "references": "http://usaa.com",
          "selectorId": "782",
          "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
          "trafficClass": "TRANSACTIONAL_DATA",
          "tunnel": "false"
        }
      ],
      "networkIdentity": [
        {
          "id": "df38972e-4e04-4ef2-bd24-9e29a205e902",
          "instanceId": 17607,
          "authEntityId": 17607,
          "displayName": "17607",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "53,5353",
          "protocol": "UDP",
          "upperPort": 0
        },
        {
          "id": "6d66754e-5dd1-4f88-ba93-1133c4b25204",
          "instanceId": 17608,
          "authEntityId": 17608,
          "displayName": "17608",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "53,80,443,5353",
          "protocol": "TCP",
          "upperPort": 0
        }
      ],
      "parentScalableGroup": {
        "idRef": "28e08de4-d7b2-4521-84ce-bc1e5ee0b9cc"
      },
      "user": [

      ],
      "userGroup": [

      ]
    },
    {
      "id": "ba97e604-a8e6-4248-b9e4-3c93af5e5fac",
      "instanceId": 4482,
      "authEntityId": 4482,
      "displayName": "4482",
      "authEntityClass": -1909073334,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "createTime": 1517966742782,
      "deployed": false,
      "isSeeded": true,
      "isStale": false,
      "lastUpdateTime": 1517966742782,
      "name": "ginad",
      "namespace": "scalablegroup:application",
      "provisioningState": "DEFINED",
      "qualifier": "application",
      "resourceVersion": 0,
      "targetIdList": [

      ],
      "type": "scalablegroup",
      "cfsChangeInfo": [

      ],
      "customProvisions": [

      ],
      "scalableGroupExternalHandle": "ginad",
      "scalableGroupType": "APPLICATION",
      "securityGroupTag": 0,
      "state": "ACTIVE",
      "vnAgnostic": true,
      "identitySource": {
        "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
        "instanceId": 10010,
        "authEntityId": 10010,
        "displayName": "10010",
        "authEntityClass": 1738953278,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "state": "INACTIVE",
        "type": "NBAR"
      },
      "indicativeNetworkIdentity": [
        {
          "id": "a6bbda25-66da-48c8-b7b2-cd4d9ab5bf6f",
          "instanceId": 13205,
          "authEntityId": 13205,
          "displayName": "13205",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "634",
          "protocol": "TCP",
          "upperPort": 0
        },
        {
          "id": "893998a6-c8f4-4a57-86d6-748f367b86fb",
          "instanceId": 13206,
          "authEntityId": 13206,
          "displayName": "13206",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "634",
          "protocol": "UDP",
          "upperPort": 0
        }
      ],
      "networkApplications": [
        {
          "id": "cd6a5f7d-a17d-4fe3-bf49-be1fe1f904f8",
          "instanceId": 11433,
          "authEntityId": 11433,
          "displayName": "11433",
          "authEntityClass": -217092956,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "appProtocol": "tcp/udp",
          "applicationGroup": "other",
          "applicationType": "DEFAULT",
          "categoryId": "6daa3dfd-3526-407a-88f0-97824b3f3a24",
          "createTime": 1517966742782,
          "encrypted": "false",
          "engineId": "3",
          "helpString": "ginad",
          "ignoreConflict": false,
          "lastUpdateTime": 1517966742782,
          "longDescription": "Registered with IANA on port 634 TCP/UDP",
          "name": "ginad",
          "nbarId": "543",
          "p2pTechnology": "false",
          "popularity": 1,
          "rank": 65535,
          "references": "http://www.iana.org/assignments/service-names-port-numbers/service-names-port-numbers.xml",
          "selectorId": "634",
          "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
          "trafficClass": "BULK_DATA",
          "tunnel": "false"
        }
      ],
      "networkIdentity": [
        {
          "id": "8ef243a0-ca1b-464e-8806-780ae6850f26",
          "instanceId": 13203,
          "authEntityId": 13203,
          "displayName": "13203",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "634",
          "protocol": "UDP",
          "upperPort": 0
        },
        {
          "id": "1e1ea637-e26b-4982-9065-b28699a586c0",
          "instanceId": 13204,
          "authEntityId": 13204,
          "displayName": "13204",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "634",
          "protocol": "TCP",
          "upperPort": 0
        }
      ],
      "parentScalableGroup": {
        "idRef": "000c15bf-28fe-4fbb-9b69-35e6502721f1"
      },
      "user": [

      ],
      "userGroup": [

      ]
    },
    {
      "id": "bacc7f7e-bc29-4981-afcf-d97316c2c987",
      "instanceId": 4705,
      "authEntityId": 4705,
      "displayName": "4705",
      "authEntityClass": -1909073334,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "createTime": 1517966743322,
      "deployed": false,
      "isSeeded": true,
      "isStale": false,
      "lastUpdateTime": 1517966743322,
      "name": "login",
      "namespace": "scalablegroup:application",
      "provisioningState": "DEFINED",
      "qualifier": "application",
      "resourceVersion": 0,
      "targetIdList": [

      ],
      "type": "scalablegroup",
      "cfsChangeInfo": [

      ],
      "customProvisions": [

      ],
      "scalableGroupExternalHandle": "login",
      "scalableGroupType": "APPLICATION",
      "securityGroupTag": 0,
      "state": "ACTIVE",
      "vnAgnostic": true,
      "identitySource": {
        "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
        "instanceId": 10010,
        "authEntityId": 10010,
        "displayName": "10010",
        "authEntityClass": 1738953278,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "state": "INACTIVE",
        "type": "NBAR"
      },
      "indicativeNetworkIdentity": [
        {
          "id": "197f35c3-a627-4ee5-9588-b031b2bee70b",
          "instanceId": 13785,
          "authEntityId": 13785,
          "displayName": "13785",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "513",
          "protocol": "TCP",
          "upperPort": 0
        }
      ],
      "networkApplications": [
        {
          "id": "392aab5c-24eb-4dfc-8c2f-97dbab667d2c",
          "instanceId": 11655,
          "authEntityId": 11655,
          "displayName": "11655",
          "authEntityClass": -217092956,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "appProtocol": "tcp",
          "applicationGroup": "other",
          "applicationType": "DEFAULT",
          "categoryId": "1fc17362-9334-4c7b-b752-5afdbb574017",
          "createTime": 1517966743322,
          "encrypted": "false",
          "engineId": "3",
          "helpString": "Obtain a remote login Terminal",
          "ignoreConflict": false,
          "lastUpdateTime": 1517966743322,
          "longDescription": "The rlogin facility provides a remote-echoed, locally flow-controlled virtual terminal with proper flushing of output.",
          "name": "login",
          "nbarId": "428",
          "p2pTechnology": "false",
          "popularity": 7,
          "rank": 65535,
          "references": "http://tools.ietf.org/html/rfc1282",
          "selectorId": "513",
          "subCategory": "787a7d77-977b-4974-89d3-379ba49e88b7",
          "trafficClass": "OPS_ADMIN_MGMT",
          "tunnel": "false"
        }
      ],
      "networkIdentity": [
        {
          "id": "cff0559f-c904-449e-836e-3a45d7adbe37",
          "instanceId": 13784,
          "authEntityId": 13784,
          "displayName": "13784",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "513",
          "protocol": "TCP",
          "upperPort": 0
        }
      ],
      "parentScalableGroup": {
        "idRef": "e06dd89f-0a70-4422-ab0b-c2314650a9a7"
      },
      "user": [

      ],
      "userGroup": [

      ]
    },
    {
      "id": "bae302f5-8807-4587-906f-548b5aa394f1",
      "instanceId": 4742,
      "authEntityId": 4742,
      "displayName": "4742",
      "authEntityClass": -1909073334,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "createTime": 1517966743403,
      "deployed": false,
      "isSeeded": true,
      "isStale": false,
      "lastUpdateTime": 1517966743403,
      "name": "mftp",
      "namespace": "scalablegroup:application",
      "provisioningState": "DEFINED",
      "qualifier": "application",
      "resourceVersion": 0,
      "targetIdList": [

      ],
      "type": "scalablegroup",
      "cfsChangeInfo": [

      ],
      "customProvisions": [

      ],
      "scalableGroupExternalHandle": "mftp",
      "scalableGroupType": "APPLICATION",
      "securityGroupTag": 0,
      "state": "ACTIVE",
      "vnAgnostic": true,
      "identitySource": {
        "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
        "instanceId": 10010,
        "authEntityId": 10010,
        "displayName": "10010",
        "authEntityClass": 1738953278,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "state": "INACTIVE",
        "type": "NBAR"
      },
      "indicativeNetworkIdentity": [
        {
          "id": "e6ca0100-3af2-4bbe-9a8a-67cd57b7df68",
          "instanceId": 13888,
          "authEntityId": 13888,
          "displayName": "13888",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "349",
          "protocol": "TCP",
          "upperPort": 0
        },
        {
          "id": "72e17269-ada0-4ea6-9081-9d25caabd945",
          "instanceId": 13889,
          "authEntityId": 13889,
          "displayName": "13889",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "349",
          "protocol": "UDP",
          "upperPort": 0
        }
      ],
      "networkApplications": [
        {
          "id": "e45074c6-97e8-4972-aed7-70ab41d9c1de",
          "instanceId": 11692,
          "authEntityId": 11692,
          "displayName": "11692",
          "authEntityClass": -217092956,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "appProtocol": "tcp/udp",
          "applicationGroup": "other",
          "applicationType": "DEFAULT",
          "categoryId": "e6619ce8-97de-4c63-a53a-cceddbddc1a8",
          "createTime": 1517966743403,
          "encrypted": "false",
          "engineId": "3",
          "helpString": "mftp",
          "ignoreConflict": false,
          "lastUpdateTime": 1517966743403,
          "longDescription": "Multisource File Transfer Protocol (MFTP) is designed for the purpose of file sharing. It is still under development, and therefore may acquire more features. This is the communication protocol used by such clients as eMule and eDonkey and, in its extended implementation, by the Overnet network.",
          "name": "mftp",
          "nbarId": "265",
          "p2pTechnology": "true",
          "popularity": 1,
          "rank": 65535,
          "references": "http://tools.ietf.org/html/draft-miller-mftp-spec-02",
          "selectorId": "349",
          "subCategory": "50b55e2e-d0d0-4404-bea4-819351565d90",
          "trafficClass": "BULK_DATA",
          "tunnel": "false"
        }
      ],
      "networkIdentity": [
        {
          "id": "38fc45d5-bccb-4e31-ad7e-4e662c563926",
          "instanceId": 13886,
          "authEntityId": 13886,
          "displayName": "13886",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "349",
          "protocol": "TCP",
          "upperPort": 0
        },
        {
          "id": "4ada27da-6e63-498f-9189-0e1b197c220d",
          "instanceId": 13887,
          "authEntityId": 13887,
          "displayName": "13887",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "349",
          "protocol": "UDP",
          "upperPort": 0
        }
      ],
      "parentScalableGroup": {
        "idRef": "383363b1-4dd5-49d1-bfe4-34daac437cfb"
      },
      "user": [

      ],
      "userGroup": [

      ]
    },
    {
      "id": "bafe26ca-80d0-4367-8b53-d298175fabc7",
      "instanceId": 15467,
      "authEntityId": 15467,
      "displayName": "15467",
      "authEntityClass": -1909073334,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "createTime": 1517966745024,
      "deployed": false,
      "isSeeded": true,
      "isStale": false,
      "lastUpdateTime": 1517966745024,
      "name": "zillow",
      "namespace": "scalablegroup:application",
      "provisioningState": "DEFINED",
      "qualifier": "application",
      "resourceVersion": 0,
      "targetIdList": [

      ],
      "type": "scalablegroup",
      "cfsChangeInfo": [

      ],
      "customProvisions": [

      ],
      "scalableGroupExternalHandle": "zillow",
      "scalableGroupType": "APPLICATION",
      "securityGroupTag": 0,
      "state": "ACTIVE",
      "vnAgnostic": true,
      "identitySource": {
        "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
        "instanceId": 10010,
        "authEntityId": 10010,
        "displayName": "10010",
        "authEntityClass": 1738953278,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "state": "INACTIVE",
        "type": "NBAR"
      },
      "indicativeNetworkIdentity": [

      ],
      "networkApplications": [
        {
          "id": "61814189-94bc-4d8d-91e1-5a263dc4ed1c",
          "instanceId": 16410,
          "authEntityId": 16410,
          "displayName": "16410",
          "authEntityClass": -217092956,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "appProtocol": "tcp/udp",
          "applicationGroup": "other",
          "applicationType": "DEFAULT",
          "categoryId": "878644b5-fe91-45b4-9204-31c84a73ba48",
          "createTime": 1517966745024,
          "encrypted": "false",
          "engineId": "13",
          "helpString": "Zillow - Online portal for Real Estate.",
          "ignoreConflict": false,
          "lastUpdateTime": 1517966745024,
          "longDescription": "Zillow - Online portal for Real Estate.",
          "name": "zillow",
          "nbarId": "1583",
          "p2pTechnology": "false",
          "popularity": 3,
          "rank": 65535,
          "references": "http://zillow.com",
          "selectorId": "654",
          "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
          "trafficClass": "TRANSACTIONAL_DATA",
          "tunnel": "false"
        }
      ],
      "networkIdentity": [
        {
          "id": "3742c686-cfa9-404c-b560-9e2036b94a4c",
          "instanceId": 17970,
          "authEntityId": 17970,
          "displayName": "17970",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "53,80,443,5353",
          "protocol": "TCP",
          "upperPort": 0
        },
        {
          "id": "0e87417e-50c9-49b9-9adc-ad1c3a12c1af",
          "instanceId": 17971,
          "authEntityId": 17971,
          "displayName": "17971",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "53,5353",
          "protocol": "UDP",
          "upperPort": 0
        }
      ],
      "parentScalableGroup": {
        "idRef": "28e08de4-d7b2-4521-84ce-bc1e5ee0b9cc"
      },
      "user": [

      ],
      "userGroup": [

      ]
    },
    {
      "id": "bb1a3cd4-a2dc-4146-a7ac-a7077a700f2a",
      "instanceId": 4431,
      "authEntityId": 4431,
      "displayName": "4431",
      "authEntityClass": -1909073334,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "createTime": 1517966742637,
      "deployed": false,
      "isSeeded": true,
      "isStale": false,
      "lastUpdateTime": 1517966742637,
      "name": "eventbrite",
      "namespace": "scalablegroup:application",
      "provisioningState": "DEFINED",
      "qualifier": "application",
      "resourceVersion": 0,
      "targetIdList": [

      ],
      "type": "scalablegroup",
      "cfsChangeInfo": [

      ],
      "customProvisions": [

      ],
      "scalableGroupExternalHandle": "eventbrite",
      "scalableGroupType": "APPLICATION",
      "securityGroupTag": 0,
      "state": "ACTIVE",
      "vnAgnostic": true,
      "identitySource": {
        "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
        "instanceId": 10010,
        "authEntityId": 10010,
        "displayName": "10010",
        "authEntityClass": 1738953278,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "state": "INACTIVE",
        "type": "NBAR"
      },
      "indicativeNetworkIdentity": [

      ],
      "networkApplications": [
        {
          "id": "1b32e58f-ccfb-4986-80e9-924276fd440c",
          "instanceId": 11382,
          "authEntityId": 11382,
          "displayName": "11382",
          "authEntityClass": -217092956,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "appProtocol": "tcp/udp",
          "applicationGroup": "other",
          "applicationType": "DEFAULT",
          "categoryId": "878644b5-fe91-45b4-9204-31c84a73ba48",
          "createTime": 1517966742637,
          "encrypted": "false",
          "engineId": "13",
          "helpString": "Eventbrite - Event organization and invite site.",
          "ignoreConflict": false,
          "lastUpdateTime": 1517966742637,
          "longDescription": "Eventbrite - Event organization and invite site.",
          "name": "eventbrite",
          "nbarId": "1647",
          "p2pTechnology": "false",
          "popularity": 3,
          "rank": 65535,
          "references": "http://eventbrite.com",
          "selectorId": "717",
          "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
          "trafficClass": "TRANSACTIONAL_DATA",
          "tunnel": "false"
        }
      ],
      "networkIdentity": [
        {
          "id": "b9b71314-566a-46af-862e-7f63e54f2dfe",
          "instanceId": 13088,
          "authEntityId": 13088,
          "displayName": "13088",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "53,80,443,5353",
          "protocol": "TCP",
          "upperPort": 0
        },
        {
          "id": "43f91e50-560c-435e-b963-ce0c977022a8",
          "instanceId": 13087,
          "authEntityId": 13087,
          "displayName": "13087",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "53,5353",
          "protocol": "UDP",
          "upperPort": 0
        }
      ],
      "parentScalableGroup": {
        "idRef": "28e08de4-d7b2-4521-84ce-bc1e5ee0b9cc"
      },
      "user": [

      ],
      "userGroup": [

      ]
    },
    {
      "id": "bb2690bf-4822-4817-a082-fad50f32ad91",
      "instanceId": 4117,
      "authEntityId": 4117,
      "displayName": "4117",
      "authEntityClass": -1909073334,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "createTime": 1517966741227,
      "deployed": false,
      "isSeeded": true,
      "isStale": false,
      "lastUpdateTime": 1517966741227,
      "name": "ariel2",
      "namespace": "scalablegroup:application",
      "provisioningState": "DEFINED",
      "qualifier": "application",
      "resourceVersion": 0,
      "targetIdList": [

      ],
      "type": "scalablegroup",
      "cfsChangeInfo": [

      ],
      "customProvisions": [

      ],
      "scalableGroupExternalHandle": "ariel2",
      "scalableGroupType": "APPLICATION",
      "securityGroupTag": 0,
      "state": "ACTIVE",
      "vnAgnostic": true,
      "identitySource": {
        "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
        "instanceId": 10010,
        "authEntityId": 10010,
        "displayName": "10010",
        "authEntityClass": 1738953278,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "state": "INACTIVE",
        "type": "NBAR"
      },
      "indicativeNetworkIdentity": [
        {
          "id": "199f4cbb-e06c-48e2-9afb-8c402f74171a",
          "instanceId": 12205,
          "authEntityId": 12205,
          "displayName": "12205",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "421",
          "protocol": "TCP",
          "upperPort": 0
        },
        {
          "id": "f33cdbcc-45d6-4910-8eaa-78d50a45b637",
          "instanceId": 12206,
          "authEntityId": 12206,
          "displayName": "12206",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "421",
          "protocol": "UDP",
          "upperPort": 0
        }
      ],
      "networkApplications": [
        {
          "id": "30f40efe-0b6a-4944-a622-7e58099eab48",
          "instanceId": 11079,
          "authEntityId": 11079,
          "displayName": "11079",
          "authEntityClass": -217092956,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "appProtocol": "tcp/udp",
          "applicationGroup": "other",
          "applicationType": "DEFAULT",
          "categoryId": "6daa3dfd-3526-407a-88f0-97824b3f3a24",
          "createTime": 1517966741227,
          "encrypted": "false",
          "engineId": "3",
          "helpString": "Ariel2",
          "ignoreConflict": false,
          "lastUpdateTime": 1517966741227,
          "longDescription": "Ariel allows users to send high-detail electronic images to other Ariel workstations anywhere in the world, using either FTP or email, converting the images to PDF files for easy delivery.",
          "name": "ariel2",
          "nbarId": "336",
          "p2pTechnology": "false",
          "popularity": 1,
          "rank": 65535,
          "references": "http://www.infotrieve.com/ariel-interlibrary-loan-software",
          "selectorId": "421",
          "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
          "trafficClass": "BULK_DATA",
          "tunnel": "false"
        }
      ],
      "networkIdentity": [
        {
          "id": "2c687006-33b9-4a1e-9be2-876e6e9dfb91",
          "instanceId": 12203,
          "authEntityId": 12203,
          "displayName": "12203",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "421",
          "protocol": "UDP",
          "upperPort": 0
        },
        {
          "id": "d2023376-d6b3-40e8-95ad-efa3ce0855f2",
          "instanceId": 12204,
          "authEntityId": 12204,
          "displayName": "12204",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "421",
          "protocol": "TCP",
          "upperPort": 0
        }
      ],
      "parentScalableGroup": {
        "idRef": "000c15bf-28fe-4fbb-9b69-35e6502721f1"
      },
      "user": [

      ],
      "userGroup": [

      ]
    },
    {
      "id": "bb9e2d57-ee7f-4afc-a9c3-14ffc0a7d16e",
      "instanceId": 15129,
      "authEntityId": 15129,
      "displayName": "15129",
      "authEntityClass": -1909073334,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "createTime": 1517966744300,
      "deployed": false,
      "isSeeded": true,
      "isStale": false,
      "lastUpdateTime": 1517966744300,
      "name": "sftp",
      "namespace": "scalablegroup:application",
      "provisioningState": "DEFINED",
      "qualifier": "application",
      "resourceVersion": 0,
      "targetIdList": [

      ],
      "type": "scalablegroup",
      "cfsChangeInfo": [

      ],
      "customProvisions": [

      ],
      "scalableGroupExternalHandle": "sftp",
      "scalableGroupType": "APPLICATION",
      "securityGroupTag": 0,
      "state": "ACTIVE",
      "vnAgnostic": true,
      "identitySource": {
        "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
        "instanceId": 10010,
        "authEntityId": 10010,
        "displayName": "10010",
        "authEntityClass": 1738953278,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "state": "INACTIVE",
        "type": "NBAR"
      },
      "indicativeNetworkIdentity": [
        {
          "id": "53519533-6c0a-4093-bb04-69cbd676cebd",
          "instanceId": 17036,
          "authEntityId": 17036,
          "displayName": "17036",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "115",
          "protocol": "TCP",
          "upperPort": 0
        },
        {
          "id": "63e05d95-123e-4b93-ba06-a33477d38aa8",
          "instanceId": 17037,
          "authEntityId": 17037,
          "displayName": "17037",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "115",
          "protocol": "UDP",
          "upperPort": 0
        }
      ],
      "networkApplications": [
        {
          "id": "fad2cbad-3c2f-4472-ade9-c5915f9317c7",
          "instanceId": 16072,
          "authEntityId": 16072,
          "displayName": "16072",
          "authEntityClass": -217092956,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "appProtocol": "tcp/udp",
          "applicationGroup": "ftp-group",
          "applicationType": "DEFAULT",
          "categoryId": "e6619ce8-97de-4c63-a53a-cceddbddc1a8",
          "createTime": 1517966744300,
          "encrypted": "false",
          "engineId": "3",
          "helpString": "Simple File Transfer Protocol",
          "ignoreConflict": false,
          "lastUpdateTime": 1517966744300,
          "longDescription": "Simple File Transfer Protocol (SFTP), was proposed as an unsecured file transfer protocol with a level of complexity intermediate between TFTP and FTP. SFTP supports user access control, file transfers, directory listing, directory changing, file renaming and deleting.",
          "name": "sftp",
          "nbarId": "985",
          "p2pTechnology": "false",
          "popularity": 1,
          "rank": 65535,
          "references": "http://www.ietf.org/rfc/rfc913.txt",
          "selectorId": "115",
          "subCategory": "50b55e2e-d0d0-4404-bea4-819351565d90",
          "trafficClass": "BULK_DATA",
          "tunnel": "false"
        }
      ],
      "networkIdentity": [
        {
          "id": "7d1c7585-d5dd-4c67-894d-37acd34e6df9",
          "instanceId": 17034,
          "authEntityId": 17034,
          "displayName": "17034",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "115",
          "protocol": "TCP",
          "upperPort": 0
        },
        {
          "id": "de6e6ca7-bcdd-43b4-b804-05999519e975",
          "instanceId": 17035,
          "authEntityId": 17035,
          "displayName": "17035",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "115",
          "protocol": "UDP",
          "upperPort": 0
        }
      ],
      "parentScalableGroup": {
        "idRef": "383363b1-4dd5-49d1-bfe4-34daac437cfb"
      },
      "user": [

      ],
      "userGroup": [

      ]
    },
    {
      "id": "bbaa06f8-dd8d-4eb6-9bd4-645c8d15f4fc",
      "instanceId": 15287,
      "authEntityId": 15287,
      "displayName": "15287",
      "authEntityClass": -1909073334,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "createTime": 1517966744661,
      "deployed": false,
      "isSeeded": true,
      "isStale": false,
      "lastUpdateTime": 1517966744661,
      "name": "time-news",
      "namespace": "scalablegroup:application",
      "provisioningState": "DEFINED",
      "qualifier": "application",
      "resourceVersion": 0,
      "targetIdList": [

      ],
      "type": "scalablegroup",
      "cfsChangeInfo": [

      ],
      "customProvisions": [

      ],
      "scalableGroupExternalHandle": "time-news",
      "scalableGroupType": "APPLICATION",
      "securityGroupTag": 0,
      "state": "ACTIVE",
      "vnAgnostic": true,
      "identitySource": {
        "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
        "instanceId": 10010,
        "authEntityId": 10010,
        "displayName": "10010",
        "authEntityClass": 1738953278,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "state": "INACTIVE",
        "type": "NBAR"
      },
      "indicativeNetworkIdentity": [

      ],
      "networkApplications": [
        {
          "id": "3ed36a87-3ad0-4f60-bea8-d32a142653d4",
          "instanceId": 16230,
          "authEntityId": 16230,
          "displayName": "16230",
          "authEntityClass": -217092956,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "appProtocol": "tcp/udp",
          "applicationGroup": "other",
          "applicationType": "DEFAULT",
          "categoryId": "878644b5-fe91-45b4-9204-31c84a73ba48",
          "createTime": 1517966744661,
          "encrypted": "false",
          "engineId": "13",
          "helpString": "TIME.com - Webportal for TIME Magazine.",
          "ignoreConflict": false,
          "lastUpdateTime": 1517966744661,
          "longDescription": "TIME.com - Webportal for TIME Magazine.",
          "name": "time-news",
          "nbarId": "1626",
          "p2pTechnology": "false",
          "popularity": 3,
          "rank": 65535,
          "references": "http://time.com",
          "selectorId": "696",
          "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
          "trafficClass": "TRANSACTIONAL_DATA",
          "tunnel": "false"
        }
      ],
      "networkIdentity": [
        {
          "id": "c05843e7-c4c9-4a00-bce9-aad1b415b4dc",
          "instanceId": 17489,
          "authEntityId": 17489,
          "displayName": "17489",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "53,5353",
          "protocol": "UDP",
          "upperPort": 0
        },
        {
          "id": "0c6924e6-498f-446d-97a8-c070a1060b2c",
          "instanceId": 17490,
          "authEntityId": 17490,
          "displayName": "17490",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "53,80,443,5353",
          "protocol": "TCP",
          "upperPort": 0
        }
      ],
      "parentScalableGroup": {
        "idRef": "28e08de4-d7b2-4521-84ce-bc1e5ee0b9cc"
      },
      "user": [

      ],
      "userGroup": [

      ]
    },
    {
      "id": "bbab21ae-aad4-4d9b-94c3-43e01bb940a5",
      "instanceId": 15062,
      "authEntityId": 15062,
      "displayName": "15062",
      "authEntityClass": -1909073334,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "createTime": 1517966744145,
      "deployed": false,
      "isSeeded": true,
      "isStale": false,
      "lastUpdateTime": 1517966744145,
      "name": "rrp",
      "namespace": "scalablegroup:application",
      "provisioningState": "DEFINED",
      "qualifier": "application",
      "resourceVersion": 0,
      "targetIdList": [

      ],
      "type": "scalablegroup",
      "cfsChangeInfo": [

      ],
      "customProvisions": [

      ],
      "scalableGroupExternalHandle": "rrp",
      "scalableGroupType": "APPLICATION",
      "securityGroupTag": 0,
      "state": "ACTIVE",
      "vnAgnostic": true,
      "identitySource": {
        "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
        "instanceId": 10010,
        "authEntityId": 10010,
        "displayName": "10010",
        "authEntityClass": 1738953278,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "state": "INACTIVE",
        "type": "NBAR"
      },
      "indicativeNetworkIdentity": [
        {
          "id": "55683ae6-47c5-447d-a249-65f9def5e8a6",
          "instanceId": 14827,
          "authEntityId": 14827,
          "displayName": "14827",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "648",
          "protocol": "TCP",
          "upperPort": 0
        },
        {
          "id": "a9a82b4b-751c-4a3b-bd8c-f1076740c339",
          "instanceId": 14828,
          "authEntityId": 14828,
          "displayName": "14828",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "648",
          "protocol": "UDP",
          "upperPort": 0
        }
      ],
      "networkApplications": [
        {
          "id": "977cb172-2767-4084-a4a8-7fc0104cfea2",
          "instanceId": 12001,
          "authEntityId": 12001,
          "displayName": "12001",
          "authEntityClass": -217092956,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "appProtocol": "tcp/udp",
          "applicationGroup": "other",
          "applicationType": "DEFAULT",
          "categoryId": "1fc17362-9334-4c7b-b752-5afdbb574017",
          "createTime": 1517966744145,
          "encrypted": "false",
          "engineId": "3",
          "helpString": "Registry Registrar Protocol",
          "ignoreConflict": false,
          "lastUpdateTime": 1517966744145,
          "longDescription": "Registry Registrar Protocol (RRP) is a text protocol that permits multiple registrars to provide second level Internet domain name registration services in the top level domains (TLDs) administered by a TLD registry. The registry stores information about registered domain names and associated name servers.",
          "name": "rrp",
          "nbarId": "557",
          "p2pTechnology": "false",
          "popularity": 1,
          "rank": 65535,
          "references": "http://www.ietf.org/rfc/rfc2832.txt",
          "selectorId": "648",
          "subCategory": "f3c0f761-d894-4112-ab89-b90fc84fd660",
          "trafficClass": "OPS_ADMIN_MGMT",
          "tunnel": "false"
        }
      ],
      "networkIdentity": [
        {
          "id": "12c6e220-ff87-42aa-9673-260ef9ffc619",
          "instanceId": 14825,
          "authEntityId": 14825,
          "displayName": "14825",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "648",
          "protocol": "TCP",
          "upperPort": 0
        },
        {
          "id": "226af2b8-545a-46ae-9234-1f7bd6ade4de",
          "instanceId": 14826,
          "authEntityId": 14826,
          "displayName": "14826",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "648",
          "protocol": "UDP",
          "upperPort": 0
        }
      ],
      "parentScalableGroup": {
        "idRef": "5fcd74d4-8726-47f7-a088-c457863da7ef"
      },
      "user": [

      ],
      "userGroup": [

      ]
    },
    {
      "id": "bc089d48-9ae1-4906-a017-cbed59fa7751",
      "instanceId": 4489,
      "authEntityId": 4489,
      "displayName": "4489",
      "authEntityClass": -1909073334,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "createTime": 1517966742797,
      "deployed": false,
      "isSeeded": true,
      "isStale": false,
      "lastUpdateTime": 1517966742797,
      "name": "gnutella",
      "namespace": "scalablegroup:application",
      "provisioningState": "DEFINED",
      "qualifier": "application",
      "resourceVersion": 0,
      "targetIdList": [

      ],
      "type": "scalablegroup",
      "cfsChangeInfo": [

      ],
      "customProvisions": [

      ],
      "scalableGroupExternalHandle": "gnutella",
      "scalableGroupType": "APPLICATION",
      "securityGroupTag": 0,
      "state": "ACTIVE",
      "vnAgnostic": true,
      "identitySource": {
        "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
        "instanceId": 10010,
        "authEntityId": 10010,
        "displayName": "10010",
        "authEntityClass": 1738953278,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "state": "INACTIVE",
        "type": "NBAR"
      },
      "indicativeNetworkIdentity": [

      ],
      "networkApplications": [
        {
          "id": "47f0f8fe-3a00-4e2a-bc65-2d2360d29625",
          "instanceId": 11440,
          "authEntityId": 11440,
          "displayName": "11440",
          "authEntityClass": -217092956,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "appProtocol": "tcp",
          "applicationGroup": "gnutella-group",
          "applicationType": "DEFAULT",
          "categoryId": "b33b2761-d981-4734-810a-a6a1c18ab352",
          "createTime": 1517966742797,
          "encrypted": "false",
          "engineId": "13",
          "helpString": "Gnutella Version2 Traffic, peer-to-peer file-sharing protocol.",
          "ignoreConflict": false,
          "lastUpdateTime": 1517966742797,
          "longDescription": "Gnutella is decentralized and open-source peer-to-peer file sharing protocol used by various clients such as BearShare, Shareeza, Morpheus, etc. Using a Gnutella client, files can be shared, located and downloaded by another Gnutella client.",
          "name": "gnutella",
          "nbarId": "58",
          "p2pTechnology": "true",
          "popularity": 7,
          "rank": 65535,
          "references": "http://rfc-gnutella.sourceforge.net/",
          "selectorId": "58",
          "subCategory": "79fe7198-9ce3-4c01-9b58-45a8df3d9513",
          "trafficClass": "BULK_DATA",
          "tunnel": "false"
        }
      ],
      "networkIdentity": [
        {
          "id": "e65b081d-ecb6-4187-a4a3-ae70ac7c9002",
          "instanceId": 13219,
          "authEntityId": 13219,
          "displayName": "13219",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "80",
          "protocol": "TCP",
          "upperPort": 0
        }
      ],
      "parentScalableGroup": {
        "idRef": "7a1a3bbb-dc84-4a35-82a4-7e650c4c4761"
      },
      "user": [

      ],
      "userGroup": [

      ]
    },
    {
      "id": "bc0ecd4b-a44a-444f-a4da-e0186d9f5ea7",
      "instanceId": 4139,
      "authEntityId": 4139,
      "displayName": "4139",
      "authEntityClass": -1909073334,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "createTime": 1517966741353,
      "deployed": false,
      "isSeeded": true,
      "isStale": false,
      "lastUpdateTime": 1517966741353,
      "name": "audible-com",
      "namespace": "scalablegroup:application",
      "provisioningState": "DEFINED",
      "qualifier": "application",
      "resourceVersion": 0,
      "targetIdList": [

      ],
      "type": "scalablegroup",
      "cfsChangeInfo": [

      ],
      "customProvisions": [

      ],
      "scalableGroupExternalHandle": "audible-com",
      "scalableGroupType": "APPLICATION",
      "securityGroupTag": 0,
      "state": "ACTIVE",
      "vnAgnostic": true,
      "identitySource": {
        "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
        "instanceId": 10010,
        "authEntityId": 10010,
        "displayName": "10010",
        "authEntityClass": 1738953278,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "state": "INACTIVE",
        "type": "NBAR"
      },
      "indicativeNetworkIdentity": [

      ],
      "networkApplications": [
        {
          "id": "0bd33a0c-1522-44e5-b835-12a5d19bbc5c",
          "instanceId": 11101,
          "authEntityId": 11101,
          "displayName": "11101",
          "authEntityClass": -217092956,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "appProtocol": "tcp/udp",
          "applicationGroup": "other",
          "applicationType": "DEFAULT",
          "categoryId": "878644b5-fe91-45b4-9204-31c84a73ba48",
          "createTime": 1517966741353,
          "encrypted": "false",
          "engineId": "13",
          "helpString": "Audible.com - Digital audio version for books, magazines, information and more.",
          "ignoreConflict": false,
          "lastUpdateTime": 1517966741353,
          "longDescription": "Audible.com - Digital audio version for books, magazines, information and more.",
          "name": "audible-com",
          "nbarId": "1660",
          "p2pTechnology": "false",
          "popularity": 3,
          "rank": 65535,
          "references": "http://audible.com",
          "selectorId": "730",
          "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
          "trafficClass": "TRANSACTIONAL_DATA",
          "tunnel": "false"
        }
      ],
      "networkIdentity": [
        {
          "id": "63461457-316e-4c4d-a54e-9bcf52bdb978",
          "instanceId": 12279,
          "authEntityId": 12279,
          "displayName": "12279",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "53,80,443,5353",
          "protocol": "TCP",
          "upperPort": 0
        },
        {
          "id": "802ecbd1-3e56-4b1e-97e7-b9e193d63f79",
          "instanceId": 12280,
          "authEntityId": 12280,
          "displayName": "12280",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "53,5353",
          "protocol": "UDP",
          "upperPort": 0
        }
      ],
      "parentScalableGroup": {
        "idRef": "28e08de4-d7b2-4521-84ce-bc1e5ee0b9cc"
      },
      "user": [

      ],
      "userGroup": [

      ]
    },
    {
      "id": "bc15406b-7102-43f0-9357-0dc602a592f9",
      "instanceId": 4956,
      "authEntityId": 4956,
      "displayName": "4956",
      "authEntityClass": -1909073334,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "createTime": 1517966743899,
      "deployed": false,
      "isSeeded": true,
      "isStale": false,
      "lastUpdateTime": 1517966743899,
      "name": "phonebook",
      "namespace": "scalablegroup:application",
      "provisioningState": "DEFINED",
      "qualifier": "application",
      "resourceVersion": 0,
      "targetIdList": [

      ],
      "type": "scalablegroup",
      "cfsChangeInfo": [

      ],
      "customProvisions": [

      ],
      "scalableGroupExternalHandle": "phonebook",
      "scalableGroupType": "APPLICATION",
      "securityGroupTag": 0,
      "state": "ACTIVE",
      "vnAgnostic": true,
      "identitySource": {
        "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
        "instanceId": 10010,
        "authEntityId": 10010,
        "displayName": "10010",
        "authEntityClass": 1738953278,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "state": "INACTIVE",
        "type": "NBAR"
      },
      "indicativeNetworkIdentity": [
        {
          "id": "cca2e325-f1fb-4c2c-b371-31972893ce18",
          "instanceId": 14528,
          "authEntityId": 14528,
          "displayName": "14528",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "767",
          "protocol": "TCP",
          "upperPort": 0
        },
        {
          "id": "d7b741c9-8579-43d7-a6c5-8756ee01fe3e",
          "instanceId": 14529,
          "authEntityId": 14529,
          "displayName": "14529",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "767",
          "protocol": "UDP",
          "upperPort": 0
        }
      ],
      "networkApplications": [
        {
          "id": "436ccc1a-1484-42c7-9947-82a770f88abf",
          "instanceId": 11905,
          "authEntityId": 11905,
          "displayName": "11905",
          "authEntityClass": -217092956,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "appProtocol": "tcp/udp",
          "applicationGroup": "other",
          "applicationType": "DEFAULT",
          "categoryId": "6daa3dfd-3526-407a-88f0-97824b3f3a24",
          "createTime": 1517966743899,
          "encrypted": "false",
          "engineId": "3",
          "helpString": "Phone",
          "ignoreConflict": false,
          "lastUpdateTime": 1517966743899,
          "longDescription": "Registered with IANA on port 767 TCP/UDP",
          "name": "phonebook",
          "nbarId": "638",
          "p2pTechnology": "false",
          "popularity": 1,
          "rank": 65535,
          "references": "http://www.iana.org/assignments/service-names-port-numbers/service-names-port-numbers.xml",
          "selectorId": "767",
          "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
          "trafficClass": "BULK_DATA",
          "tunnel": "false"
        }
      ],
      "networkIdentity": [
        {
          "id": "66a4b6d1-73ce-43e1-a910-92ba97d09e9a",
          "instanceId": 14526,
          "authEntityId": 14526,
          "displayName": "14526",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "767",
          "protocol": "TCP",
          "upperPort": 0
        },
        {
          "id": "8b01ac2e-f60f-47cc-b549-61a5a4a9c3a4",
          "instanceId": 14527,
          "authEntityId": 14527,
          "displayName": "14527",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "767",
          "protocol": "UDP",
          "upperPort": 0
        }
      ],
      "parentScalableGroup": {
        "idRef": "000c15bf-28fe-4fbb-9b69-35e6502721f1"
      },
      "user": [

      ],
      "userGroup": [

      ]
    },
    {
      "id": "bc223a82-184f-4203-9754-81d8d65a0411",
      "instanceId": 4123,
      "authEntityId": 4123,
      "displayName": "4123",
      "authEntityClass": -1909073334,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "createTime": 1517966741243,
      "deployed": false,
      "isSeeded": true,
      "isStale": false,
      "lastUpdateTime": 1517966741243,
      "name": "asa",
      "namespace": "scalablegroup:application",
      "provisioningState": "DEFINED",
      "qualifier": "application",
      "resourceVersion": 0,
      "targetIdList": [

      ],
      "type": "scalablegroup",
      "cfsChangeInfo": [

      ],
      "customProvisions": [

      ],
      "scalableGroupExternalHandle": "asa",
      "scalableGroupType": "APPLICATION",
      "securityGroupTag": 0,
      "state": "ACTIVE",
      "vnAgnostic": true,
      "identitySource": {
        "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
        "instanceId": 10010,
        "authEntityId": 10010,
        "displayName": "10010",
        "authEntityClass": 1738953278,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "state": "INACTIVE",
        "type": "NBAR"
      },
      "indicativeNetworkIdentity": [
        {
          "id": "83de9a5e-b4f2-42bd-89c8-e7542ab93172",
          "instanceId": 12225,
          "authEntityId": 12225,
          "displayName": "12225",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "386",
          "protocol": "UDP",
          "upperPort": 0
        },
        {
          "id": "b09f5792-7939-4a04-b29a-b44d7464cfd8",
          "instanceId": 12226,
          "authEntityId": 12226,
          "displayName": "12226",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "386",
          "protocol": "TCP",
          "upperPort": 0
        }
      ],
      "networkApplications": [
        {
          "id": "c5007e12-ca7d-4691-b59c-b98b3abdb72b",
          "instanceId": 11085,
          "authEntityId": 11085,
          "displayName": "11085",
          "authEntityClass": -217092956,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "appProtocol": "tcp/udp",
          "applicationGroup": "other",
          "applicationType": "DEFAULT",
          "categoryId": "6daa3dfd-3526-407a-88f0-97824b3f3a24",
          "createTime": 1517966741243,
          "encrypted": "false",
          "engineId": "3",
          "helpString": "ASA Message router object def",
          "ignoreConflict": false,
          "lastUpdateTime": 1517966741243,
          "longDescription": "Registered with IANA on port 386 TCP/UDP",
          "name": "asa",
          "nbarId": "302",
          "p2pTechnology": "false",
          "popularity": 1,
          "rank": 65535,
          "references": "http://www.iana.org/assignments/service-names-port-numbers/service-names-port-numbers.xml",
          "selectorId": "386",
          "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
          "trafficClass": "BULK_DATA",
          "tunnel": "false"
        }
      ],
      "networkIdentity": [
        {
          "id": "69312592-37ea-4dbe-b7c7-db7d06d3a880",
          "instanceId": 12224,
          "authEntityId": 12224,
          "displayName": "12224",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "386",
          "protocol": "UDP",
          "upperPort": 0
        },
        {
          "id": "d3711d2c-50cf-454d-ae6b-461bdca99852",
          "instanceId": 12223,
          "authEntityId": 12223,
          "displayName": "12223",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "386",
          "protocol": "TCP",
          "upperPort": 0
        }
      ],
      "parentScalableGroup": {
        "idRef": "000c15bf-28fe-4fbb-9b69-35e6502721f1"
      },
      "user": [

      ],
      "userGroup": [

      ]
    },
    {
      "id": "bceec0fb-dfc8-4f77-be11-c37b4d35f3d2",
      "instanceId": 4408,
      "authEntityId": 4408,
      "displayName": "4408",
      "authEntityClass": -1909073334,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "createTime": 1517966742561,
      "deployed": false,
      "isSeeded": true,
      "isStale": false,
      "lastUpdateTime": 1517966742561,
      "name": "encap",
      "namespace": "scalablegroup:application",
      "provisioningState": "DEFINED",
      "qualifier": "application",
      "resourceVersion": 0,
      "targetIdList": [

      ],
      "type": "scalablegroup",
      "cfsChangeInfo": [

      ],
      "customProvisions": [

      ],
      "scalableGroupExternalHandle": "encap",
      "scalableGroupType": "APPLICATION",
      "securityGroupTag": 0,
      "state": "ACTIVE",
      "vnAgnostic": true,
      "identitySource": {
        "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
        "instanceId": 10010,
        "authEntityId": 10010,
        "displayName": "10010",
        "authEntityClass": 1738953278,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "state": "INACTIVE",
        "type": "NBAR"
      },
      "indicativeNetworkIdentity": [
        {
          "id": "29f1605d-c9bb-4600-a9eb-b4152f86a67f",
          "instanceId": 13020,
          "authEntityId": 13020,
          "displayName": "13020",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipProtocolNumbers": "98",
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "protocol": "ENCAP",
          "upperPort": 0
        }
      ],
      "networkApplications": [
        {
          "id": "1e51d12e-5734-49db-8e1b-d3c1a6c0f419",
          "instanceId": 11359,
          "authEntityId": 11359,
          "displayName": "11359",
          "authEntityClass": -217092956,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "appProtocol": "ip",
          "applicationGroup": "other",
          "applicationType": "DEFAULT",
          "categoryId": "6daa3dfd-3526-407a-88f0-97824b3f3a24",
          "createTime": 1517966742561,
          "encrypted": "unassigned",
          "engineId": "1",
          "helpString": "Encapsulation Header",
          "ignoreConflict": false,
          "lastUpdateTime": 1517966742561,
          "longDescription": "Encapsulation Protocol is an IP tunneling protocol implemented by encapsulating the IP datagram within an additional IP header, thus enabling a destination to be reached transparently without the source having to know topology specifics.",
          "name": "encap",
          "nbarId": "852",
          "p2pTechnology": "unassigned",
          "popularity": 2,
          "rank": 65535,
          "references": "http://tools.ietf.org/html/rfc1241",
          "selectorId": "98",
          "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
          "trafficClass": "BULK_DATA",
          "tunnel": "unassigned"
        }
      ],
      "networkIdentity": [
        {
          "id": "64ba7041-d382-4884-908b-53a0f1395000",
          "instanceId": 13019,
          "authEntityId": 13019,
          "displayName": "13019",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipProtocolNumbers": "98",
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "protocol": "ENCAP",
          "upperPort": 0
        }
      ],
      "parentScalableGroup": {
        "idRef": "000c15bf-28fe-4fbb-9b69-35e6502721f1"
      },
      "user": [

      ],
      "userGroup": [

      ]
    },
    {
      "id": "bd0157b2-7e2a-466f-9d6e-9fb1a40432b7",
      "instanceId": 4179,
      "authEntityId": 4179,
      "displayName": "4179",
      "authEntityClass": -1909073334,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "createTime": 1517966741553,
      "deployed": false,
      "isSeeded": true,
      "isStale": false,
      "lastUpdateTime": 1517966741553,
      "name": "binary-over-http",
      "namespace": "scalablegroup:application",
      "provisioningState": "DEFINED",
      "qualifier": "application",
      "resourceVersion": 0,
      "targetIdList": [

      ],
      "type": "scalablegroup",
      "cfsChangeInfo": [

      ],
      "customProvisions": [

      ],
      "scalableGroupExternalHandle": "binary-over-http",
      "scalableGroupType": "APPLICATION",
      "securityGroupTag": 0,
      "state": "ACTIVE",
      "vnAgnostic": true,
      "identitySource": {
        "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
        "instanceId": 10010,
        "authEntityId": 10010,
        "displayName": "10010",
        "authEntityClass": 1738953278,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "state": "INACTIVE",
        "type": "NBAR"
      },
      "indicativeNetworkIdentity": [

      ],
      "networkApplications": [
        {
          "id": "e8c2365f-f984-453d-aeec-c5e8430d7f36",
          "instanceId": 11138,
          "authEntityId": 11138,
          "displayName": "11138",
          "authEntityClass": -217092956,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "appProtocol": "tcp",
          "applicationGroup": "other",
          "applicationType": "DEFAULT",
          "categoryId": "e6619ce8-97de-4c63-a53a-cceddbddc1a8",
          "createTime": 1517966741553,
          "encrypted": "false",
          "engineId": "13",
          "helpString": "Binary traffic over HTTP",
          "ignoreConflict": false,
          "lastUpdateTime": 1517966741553,
          "longDescription": "Binary over HTTP represents the transfer of binary data (for example, executive and compressed files such as .exe, .zip , and .rar) over HTTP protocol.",
          "name": "binary-over-http",
          "nbarId": "121",
          "p2pTechnology": "false",
          "popularity": 10,
          "rank": 65535,
          "references": "N/A",
          "selectorId": "431",
          "subCategory": "50b55e2e-d0d0-4404-bea4-819351565d90",
          "trafficClass": "BULK_DATA",
          "tunnel": "false"
        }
      ],
      "networkIdentity": [
        {
          "id": "1775dd4b-9ac3-4827-ab67-926056f11f28",
          "instanceId": 12388,
          "authEntityId": 12388,
          "displayName": "12388",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "80",
          "protocol": "TCP",
          "upperPort": 0
        }
      ],
      "parentScalableGroup": {
        "idRef": "383363b1-4dd5-49d1-bfe4-34daac437cfb"
      },
      "user": [

      ],
      "userGroup": [

      ]
    },
    {
      "id": "bd1a9f6c-d0a1-425d-9ea1-88f14dc08fe6",
      "instanceId": 4652,
      "authEntityId": 4652,
      "displayName": "4652",
      "authEntityClass": -1909073334,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "createTime": 1517966743211,
      "deployed": false,
      "isSeeded": true,
      "isStale": false,
      "lastUpdateTime": 1517966743211,
      "name": "iso-tsap-c2",
      "namespace": "scalablegroup:application",
      "provisioningState": "DEFINED",
      "qualifier": "application",
      "resourceVersion": 0,
      "targetIdList": [

      ],
      "type": "scalablegroup",
      "cfsChangeInfo": [

      ],
      "customProvisions": [

      ],
      "scalableGroupExternalHandle": "iso-tsap-c2",
      "scalableGroupType": "APPLICATION",
      "securityGroupTag": 0,
      "state": "ACTIVE",
      "vnAgnostic": true,
      "identitySource": {
        "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
        "instanceId": 10010,
        "authEntityId": 10010,
        "displayName": "10010",
        "authEntityClass": 1738953278,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "state": "INACTIVE",
        "type": "NBAR"
      },
      "indicativeNetworkIdentity": [
        {
          "id": "cd4ddebb-3c7b-4f70-b7be-238db1410a49",
          "instanceId": 13643,
          "authEntityId": 13643,
          "displayName": "13643",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "399",
          "protocol": "TCP",
          "upperPort": 0
        },
        {
          "id": "394f422c-5eaf-436c-9995-cfd824f7663c",
          "instanceId": 13644,
          "authEntityId": 13644,
          "displayName": "13644",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "399",
          "protocol": "UDP",
          "upperPort": 0
        }
      ],
      "networkApplications": [
        {
          "id": "4a1a1a49-5901-4ec7-868e-2810c180fd5f",
          "instanceId": 11602,
          "authEntityId": 11602,
          "displayName": "11602",
          "authEntityClass": -217092956,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "appProtocol": "tcp/udp",
          "applicationGroup": "other",
          "applicationType": "DEFAULT",
          "categoryId": "6daa3dfd-3526-407a-88f0-97824b3f3a24",
          "createTime": 1517966743211,
          "encrypted": "false",
          "engineId": "3",
          "helpString": "ISO Transport Class 2 Non-Control",
          "ignoreConflict": false,
          "lastUpdateTime": 1517966743211,
          "longDescription": "Implementation of ISO Transport Class 2 Non-use of Explicit Flow Control on top of TCP.",
          "name": "iso-tsap-c2",
          "nbarId": "314",
          "p2pTechnology": "false",
          "popularity": 3,
          "rank": 65535,
          "references": "http://tools.ietf.org/html/rfc1859",
          "selectorId": "399",
          "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
          "trafficClass": "BULK_DATA",
          "tunnel": "false"
        }
      ],
      "networkIdentity": [
        {
          "id": "561aab3d-cbbb-4216-9f23-a87555218b1e",
          "instanceId": 13641,
          "authEntityId": 13641,
          "displayName": "13641",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "399",
          "protocol": "TCP",
          "upperPort": 0
        },
        {
          "id": "c252cd83-3cb1-4a34-8560-01d9d32dc0fc",
          "instanceId": 13642,
          "authEntityId": 13642,
          "displayName": "13642",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "399",
          "protocol": "UDP",
          "upperPort": 0
        }
      ],
      "parentScalableGroup": {
        "idRef": "000c15bf-28fe-4fbb-9b69-35e6502721f1"
      },
      "user": [

      ],
      "userGroup": [

      ]
    },
    {
      "id": "bd9c4106-df64-480d-bfa5-05067aee4818",
      "instanceId": 4621,
      "authEntityId": 4621,
      "displayName": "4621",
      "authEntityClass": -1909073334,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "createTime": 1517966743144,
      "deployed": false,
      "isSeeded": true,
      "isStale": false,
      "lastUpdateTime": 1517966743144,
      "name": "ipdd",
      "namespace": "scalablegroup:application",
      "provisioningState": "DEFINED",
      "qualifier": "application",
      "resourceVersion": 0,
      "targetIdList": [

      ],
      "type": "scalablegroup",
      "cfsChangeInfo": [

      ],
      "customProvisions": [

      ],
      "scalableGroupExternalHandle": "ipdd",
      "scalableGroupType": "APPLICATION",
      "securityGroupTag": 0,
      "state": "ACTIVE",
      "vnAgnostic": true,
      "identitySource": {
        "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
        "instanceId": 10010,
        "authEntityId": 10010,
        "displayName": "10010",
        "authEntityClass": 1738953278,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "state": "INACTIVE",
        "type": "NBAR"
      },
      "indicativeNetworkIdentity": [
        {
          "id": "6063700f-5eba-418c-a230-3365cc08443b",
          "instanceId": 13569,
          "authEntityId": 13569,
          "displayName": "13569",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "578",
          "protocol": "UDP",
          "upperPort": 0
        },
        {
          "id": "e0a7e827-f2ca-493e-a7c1-29b7f58d3f46",
          "instanceId": 13570,
          "authEntityId": 13570,
          "displayName": "13570",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "578",
          "protocol": "TCP",
          "upperPort": 0
        }
      ],
      "networkApplications": [
        {
          "id": "0cc7ac08-4ac3-4e88-89a8-3b0d4e668526",
          "instanceId": 11571,
          "authEntityId": 11571,
          "displayName": "11571",
          "authEntityClass": -217092956,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "appProtocol": "tcp/udp",
          "applicationGroup": "other",
          "applicationType": "DEFAULT",
          "categoryId": "6daa3dfd-3526-407a-88f0-97824b3f3a24",
          "createTime": 1517966743143,
          "encrypted": "false",
          "engineId": "3",
          "helpString": "ipdd",
          "ignoreConflict": false,
          "lastUpdateTime": 1517966743143,
          "longDescription": "Registered with IANA on port 578 TCP/UDP",
          "name": "ipdd",
          "nbarId": "492",
          "p2pTechnology": "false",
          "popularity": 1,
          "rank": 65535,
          "references": "http://www.iana.org/assignments/service-names-port-numbers/service-names-port-numbers.xml",
          "selectorId": "578",
          "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
          "trafficClass": "BULK_DATA",
          "tunnel": "false"
        }
      ],
      "networkIdentity": [
        {
          "id": "057d98a5-4289-4efa-acb5-234a47f6d6fd",
          "instanceId": 13568,
          "authEntityId": 13568,
          "displayName": "13568",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "578",
          "protocol": "UDP",
          "upperPort": 0
        },
        {
          "id": "95a628ec-4d4e-4f7c-adc8-b0ff16726390",
          "instanceId": 13567,
          "authEntityId": 13567,
          "displayName": "13567",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "578",
          "protocol": "TCP",
          "upperPort": 0
        }
      ],
      "parentScalableGroup": {
        "idRef": "000c15bf-28fe-4fbb-9b69-35e6502721f1"
      },
      "user": [

      ],
      "userGroup": [

      ]
    },
    {
      "id": "bdf8989a-41f6-4c9a-a440-220fbc3f94aa",
      "instanceId": 4273,
      "authEntityId": 4273,
      "displayName": "4273",
      "authEntityClass": -1909073334,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "createTime": 1517966742034,
      "deployed": false,
      "isSeeded": true,
      "isStale": false,
      "lastUpdateTime": 1517966742034,
      "name": "collaborator",
      "namespace": "scalablegroup:application",
      "provisioningState": "DEFINED",
      "qualifier": "application",
      "resourceVersion": 0,
      "targetIdList": [

      ],
      "type": "scalablegroup",
      "cfsChangeInfo": [

      ],
      "customProvisions": [

      ],
      "scalableGroupExternalHandle": "collaborator",
      "scalableGroupType": "APPLICATION",
      "securityGroupTag": 0,
      "state": "ACTIVE",
      "vnAgnostic": true,
      "identitySource": {
        "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
        "instanceId": 10010,
        "authEntityId": 10010,
        "displayName": "10010",
        "authEntityClass": 1738953278,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "state": "INACTIVE",
        "type": "NBAR"
      },
      "indicativeNetworkIdentity": [
        {
          "id": "871deaa5-f637-495f-a2f1-784d75208611",
          "instanceId": 12594,
          "authEntityId": 12594,
          "displayName": "12594",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "622",
          "protocol": "TCP",
          "upperPort": 0
        },
        {
          "id": "54a3faff-cf75-4dde-98e6-7dd7d7714045",
          "instanceId": 12595,
          "authEntityId": 12595,
          "displayName": "12595",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "622",
          "protocol": "UDP",
          "upperPort": 0
        }
      ],
      "networkApplications": [
        {
          "id": "0cc076f6-e56f-44e9-ad32-a46942365260",
          "instanceId": 11226,
          "authEntityId": 11226,
          "displayName": "11226",
          "authEntityClass": -217092956,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "appProtocol": "tcp/udp",
          "applicationGroup": "other",
          "applicationType": "DEFAULT",
          "categoryId": "6daa3dfd-3526-407a-88f0-97824b3f3a24",
          "createTime": 1517966742034,
          "encrypted": "false",
          "engineId": "3",
          "helpString": "collaborator",
          "ignoreConflict": false,
          "lastUpdateTime": 1517966742034,
          "longDescription": "Registered with IANA on port 622 TCP/UDP",
          "name": "collaborator",
          "nbarId": "531",
          "p2pTechnology": "false",
          "popularity": 1,
          "rank": 65535,
          "references": "http://www.iana.org/assignments/service-names-port-numbers/service-names-port-numbers.xml",
          "selectorId": "622",
          "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
          "trafficClass": "BULK_DATA",
          "tunnel": "false"
        }
      ],
      "networkIdentity": [
        {
          "id": "4bab6f6d-a713-4988-8da9-1e638d423c8c",
          "instanceId": 12592,
          "authEntityId": 12592,
          "displayName": "12592",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "622",
          "protocol": "UDP",
          "upperPort": 0
        },
        {
          "id": "1e140b99-c5f0-437b-a080-027b117e2103",
          "instanceId": 12593,
          "authEntityId": 12593,
          "displayName": "12593",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "622",
          "protocol": "TCP",
          "upperPort": 0
        }
      ],
      "parentScalableGroup": {
        "idRef": "000c15bf-28fe-4fbb-9b69-35e6502721f1"
      },
      "user": [

      ],
      "userGroup": [

      ]
    },
    {
      "id": "be029553-73bc-4e27-afe7-03618afbeb6d",
      "instanceId": 4547,
      "authEntityId": 4547,
      "displayName": "4547",
      "authEntityClass": -1909073334,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "createTime": 1517966742956,
      "deployed": false,
      "isSeeded": true,
      "isStale": false,
      "lastUpdateTime": 1517966742956,
      "name": "hopopt",
      "namespace": "scalablegroup:application",
      "provisioningState": "DEFINED",
      "qualifier": "application",
      "resourceVersion": 0,
      "targetIdList": [

      ],
      "type": "scalablegroup",
      "cfsChangeInfo": [

      ],
      "customProvisions": [

      ],
      "scalableGroupExternalHandle": "hopopt",
      "scalableGroupType": "APPLICATION",
      "securityGroupTag": 0,
      "state": "ACTIVE",
      "vnAgnostic": true,
      "identitySource": {
        "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
        "instanceId": 10010,
        "authEntityId": 10010,
        "displayName": "10010",
        "authEntityClass": 1738953278,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "state": "INACTIVE",
        "type": "NBAR"
      },
      "indicativeNetworkIdentity": [

      ],
      "networkApplications": [
        {
          "id": "11303483-7d61-4360-b731-137bf5da91d7",
          "instanceId": 11498,
          "authEntityId": 11498,
          "displayName": "11498",
          "authEntityClass": -217092956,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "applicationGroup": "other",
          "applicationType": "DEFAULT",
          "categoryId": "6daa3dfd-3526-407a-88f0-97824b3f3a24",
          "createTime": 1517966742956,
          "encrypted": "unassigned",
          "engineId": "1",
          "helpString": "DEPRECATED, traffic will not match",
          "ignoreConflict": false,
          "lastUpdateTime": 1517966742956,
          "longDescription": "Deprecated: IPv6 Hop-by-Hop Option",
          "name": "hopopt",
          "nbarId": "756",
          "p2pTechnology": "unassigned",
          "popularity": 0,
          "rank": 65535,
          "references": "http://www.iana.org/assignments/protocol-numbers/protocol-numbers.xml",
          "selectorId": "0",
          "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
          "trafficClass": "BULK_DATA",
          "tunnel": "unassigned"
        }
      ],
      "networkIdentity": [

      ],
      "parentScalableGroup": {
        "idRef": "000c15bf-28fe-4fbb-9b69-35e6502721f1"
      },
      "user": [

      ],
      "userGroup": [

      ]
    },
    {
      "id": "be38a46a-a243-4987-ab20-a0dab3e3f598",
      "instanceId": 4101,
      "authEntityId": 4101,
      "displayName": "4101",
      "authEntityClass": -1909073334,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "createTime": 1517966741111,
      "deployed": false,
      "isSeeded": true,
      "isStale": false,
      "lastUpdateTime": 1517966741111,
      "name": "apple-ios-updates",
      "namespace": "scalablegroup:application",
      "provisioningState": "DEFINED",
      "qualifier": "application",
      "resourceVersion": 0,
      "targetIdList": [

      ],
      "type": "scalablegroup",
      "cfsChangeInfo": [

      ],
      "customProvisions": [

      ],
      "scalableGroupExternalHandle": "apple-ios-updates",
      "scalableGroupType": "APPLICATION",
      "securityGroupTag": 0,
      "state": "ACTIVE",
      "vnAgnostic": true,
      "identitySource": {
        "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
        "instanceId": 10010,
        "authEntityId": 10010,
        "displayName": "10010",
        "authEntityClass": 1738953278,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "state": "INACTIVE",
        "type": "NBAR"
      },
      "indicativeNetworkIdentity": [

      ],
      "networkApplications": [
        {
          "id": "368a7fb1-6b23-463a-b987-0ee53ace2548",
          "instanceId": 11065,
          "authEntityId": 11065,
          "displayName": "11065",
          "authEntityClass": -217092956,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "appProtocol": "tcp",
          "applicationGroup": "apple-group",
          "applicationType": "DEFAULT",
          "categoryId": "9d9132a2-d99b-49db-a414-9fc93dea6604",
          "createTime": 1517966741111,
          "encrypted": "true",
          "engineId": "13",
          "helpString": "iOS operating system updates",
          "ignoreConflict": false,
          "lastUpdateTime": 1517966741111,
          "longDescription": "Apple iOS updates is a service that provides updates for the iOS operating system and its installed components.",
          "name": "apple-ios-updates",
          "nbarId": "1525",
          "p2pTechnology": "false",
          "popularity": 8,
          "rank": 65535,
          "references": "http://www.apple.com/softwareupdate/",
          "selectorId": "586",
          "subCategory": "98e625d1-8720-4d2b-a186-c821a5d86693",
          "trafficClass": "BULK_DATA",
          "tunnel": "false"
        }
      ],
      "networkIdentity": [
        {
          "id": "1fb5c0d8-e770-4174-82f9-bf3457abba3b",
          "instanceId": 12163,
          "authEntityId": 12163,
          "displayName": "12163",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "80",
          "protocol": "TCP",
          "upperPort": 0
        }
      ],
      "parentScalableGroup": {
        "idRef": "4a68bc73-53c6-4deb-9983-784a0338aefe"
      },
      "user": [

      ],
      "userGroup": [

      ]
    },
    {
      "id": "be6dc2b8-3422-42f2-8ad6-9e5615f84777",
      "instanceId": 4539,
      "authEntityId": 4539,
      "displayName": "4539",
      "authEntityClass": -1909073334,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "createTime": 1517966742934,
      "deployed": false,
      "isSeeded": true,
      "isStale": false,
      "lastUpdateTime": 1517966742934,
      "name": "hip",
      "namespace": "scalablegroup:application",
      "provisioningState": "DEFINED",
      "qualifier": "application",
      "resourceVersion": 0,
      "targetIdList": [

      ],
      "type": "scalablegroup",
      "cfsChangeInfo": [

      ],
      "customProvisions": [

      ],
      "scalableGroupExternalHandle": "hip",
      "scalableGroupType": "APPLICATION",
      "securityGroupTag": 0,
      "state": "ACTIVE",
      "vnAgnostic": true,
      "identitySource": {
        "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
        "instanceId": 10010,
        "authEntityId": 10010,
        "displayName": "10010",
        "authEntityClass": 1738953278,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "state": "INACTIVE",
        "type": "NBAR"
      },
      "indicativeNetworkIdentity": [
        {
          "id": "8defc13d-f90c-4d4b-8259-19f5f71971ea",
          "instanceId": 13340,
          "authEntityId": 13340,
          "displayName": "13340",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipProtocolNumbers": "139",
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "protocol": "HIP",
          "upperPort": 0
        }
      ],
      "networkApplications": [
        {
          "id": "17a27abe-8d21-4b36-a353-f4b4ddf17583",
          "instanceId": 11490,
          "authEntityId": 11490,
          "displayName": "11490",
          "authEntityClass": -217092956,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "appProtocol": "ip",
          "applicationGroup": "other",
          "applicationType": "DEFAULT",
          "categoryId": "6daa3dfd-3526-407a-88f0-97824b3f3a24",
          "createTime": 1517966742934,
          "encrypted": "false",
          "engineId": "1",
          "helpString": "Host Identity Protocol",
          "ignoreConflict": false,
          "lastUpdateTime": 1517966742934,
          "longDescription": "The Host Identity Protocol (HIP) is a host identification technology for use on Internet Protocol (IP) networks. The Internet has two main name spaces, IP addresses and the Domain Name System. HIP separates the end-point identifier and locator roles of IP addresses. It introduces a Host Identity (HI) name space, based on a public key security infrastructure.",
          "name": "hip",
          "nbarId": "1237",
          "p2pTechnology": "false",
          "popularity": 2,
          "rank": 65535,
          "references": "http://tools.ietf.org/html/rfc5201",
          "selectorId": "139",
          "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
          "trafficClass": "BULK_DATA",
          "tunnel": "false"
        }
      ],
      "networkIdentity": [
        {
          "id": "11dbcf4c-52b0-439c-a2e1-2a1a62cc6a63",
          "instanceId": 13339,
          "authEntityId": 13339,
          "displayName": "13339",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipProtocolNumbers": "139",
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "protocol": "HIP",
          "upperPort": 0
        }
      ],
      "parentScalableGroup": {
        "idRef": "000c15bf-28fe-4fbb-9b69-35e6502721f1"
      },
      "user": [

      ],
      "userGroup": [

      ]
    },
    {
      "id": "bec5f4bd-63f7-457a-aa5d-27332ab086b0",
      "instanceId": 15441,
      "authEntityId": 15441,
      "displayName": "15441",
      "authEntityClass": -1909073334,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "createTime": 1517966744971,
      "deployed": false,
      "isSeeded": true,
      "isStale": false,
      "lastUpdateTime": 1517966744971,
      "name": "xns-auth",
      "namespace": "scalablegroup:application",
      "provisioningState": "DEFINED",
      "qualifier": "application",
      "resourceVersion": 0,
      "targetIdList": [

      ],
      "type": "scalablegroup",
      "cfsChangeInfo": [

      ],
      "customProvisions": [

      ],
      "scalableGroupExternalHandle": "xns-auth",
      "scalableGroupType": "APPLICATION",
      "securityGroupTag": 0,
      "state": "ACTIVE",
      "vnAgnostic": true,
      "identitySource": {
        "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
        "instanceId": 10010,
        "authEntityId": 10010,
        "displayName": "10010",
        "authEntityClass": 1738953278,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "state": "INACTIVE",
        "type": "NBAR"
      },
      "indicativeNetworkIdentity": [
        {
          "id": "8a6006ff-a5fc-4cc6-88c7-4dcc59597e75",
          "instanceId": 17907,
          "authEntityId": 17907,
          "displayName": "17907",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "56",
          "protocol": "UDP",
          "upperPort": 0
        },
        {
          "id": "a0204c80-bc80-417a-8b34-59d5659903db",
          "instanceId": 17908,
          "authEntityId": 17908,
          "displayName": "17908",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "56",
          "protocol": "TCP",
          "upperPort": 0
        }
      ],
      "networkApplications": [
        {
          "id": "cb73cffd-64fb-4932-a382-e448a13c9ff3",
          "instanceId": 16384,
          "authEntityId": 16384,
          "displayName": "16384",
          "authEntityClass": -217092956,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "appProtocol": "tcp/udp",
          "applicationGroup": "xns-xerox-group",
          "applicationType": "DEFAULT",
          "categoryId": "1fc17362-9334-4c7b-b752-5afdbb574017",
          "createTime": 1517966744971,
          "encrypted": "false",
          "engineId": "3",
          "helpString": "XNS Authentication",
          "ignoreConflict": false,
          "lastUpdateTime": 1517966744971,
          "longDescription": "Xerox Network Services(XNS) is aprotocol suitedeveloped byXeroxwithin theXerox Network Systems Architecture. It provided general purpose network communications, internetworkroutingand packet delivery, including higher level functions such as areliable stream, andremote procedure calls. XNS predated and influenced the development of theOpen Systems Interconnection(OSI) networking model.",
          "name": "xns-auth",
          "nbarId": "936",
          "p2pTechnology": "false",
          "popularity": 1,
          "rank": 65535,
          "references": "http://en.wikipedia.org/wiki/Xerox_Network_Systems",
          "selectorId": "56",
          "subCategory": "e390124a-0fd6-4253-b68e-4b0c1af8cb4b",
          "trafficClass": "OPS_ADMIN_MGMT",
          "tunnel": "false"
        }
      ],
      "networkIdentity": [
        {
          "id": "bdbdf552-9982-4ab5-a240-59b153be012c",
          "instanceId": 17905,
          "authEntityId": 17905,
          "displayName": "17905",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "56",
          "protocol": "UDP",
          "upperPort": 0
        },
        {
          "id": "b0cdf4d3-251a-456a-abad-2aa5b0b53ba1",
          "instanceId": 17906,
          "authEntityId": 17906,
          "displayName": "17906",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "56",
          "protocol": "TCP",
          "upperPort": 0
        }
      ],
      "parentScalableGroup": {
        "idRef": "7788bf2d-e289-4597-b500-17aacb7e820e"
      },
      "user": [

      ],
      "userGroup": [

      ]
    },
    {
      "id": "bfd72087-4302-40c3-a6ed-aad2b6ac845f",
      "instanceId": 15252,
      "authEntityId": 15252,
      "displayName": "15252",
      "authEntityClass": -1909073334,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "createTime": 1517966744584,
      "deployed": false,
      "isSeeded": true,
      "isStale": false,
      "lastUpdateTime": 1517966744584,
      "name": "talk",
      "namespace": "scalablegroup:application",
      "provisioningState": "DEFINED",
      "qualifier": "application",
      "resourceVersion": 0,
      "targetIdList": [

      ],
      "type": "scalablegroup",
      "cfsChangeInfo": [

      ],
      "customProvisions": [

      ],
      "scalableGroupExternalHandle": "talk",
      "scalableGroupType": "APPLICATION",
      "securityGroupTag": 0,
      "state": "ACTIVE",
      "vnAgnostic": true,
      "identitySource": {
        "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
        "instanceId": 10010,
        "authEntityId": 10010,
        "displayName": "10010",
        "authEntityClass": 1738953278,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "state": "INACTIVE",
        "type": "NBAR"
      },
      "indicativeNetworkIdentity": [
        {
          "id": "d115eab6-83fa-406f-bf31-b4d95283fcb8",
          "instanceId": 17396,
          "authEntityId": 17396,
          "displayName": "17396",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "517",
          "protocol": "UDP",
          "upperPort": 0
        },
        {
          "id": "2c427075-bc0f-4b6e-a542-48ac89c517ac",
          "instanceId": 17397,
          "authEntityId": 17397,
          "displayName": "17397",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "517",
          "protocol": "TCP",
          "upperPort": 0
        }
      ],
      "networkApplications": [
        {
          "id": "ce0a1af0-cc3b-4141-beb9-e6e8bbf0b531",
          "instanceId": 16195,
          "authEntityId": 16195,
          "displayName": "16195",
          "authEntityClass": -217092956,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "appProtocol": "tcp/udp",
          "applicationGroup": "other",
          "applicationType": "DEFAULT",
          "categoryId": "0f6cab47-be95-4124-9db3-360ccd55f983",
          "createTime": 1517966744584,
          "encrypted": "false",
          "engineId": "3",
          "helpString": "talk",
          "ignoreConflict": false,
          "lastUpdateTime": 1517966744584,
          "longDescription": "talk is a Unix text chat program. It originally allowed messaging only between the users logged on to one multi-user computer, but later was extended to allow chat to users on other systems.",
          "name": "talk",
          "nbarId": "434",
          "p2pTechnology": "false",
          "popularity": 1,
          "rank": 65535,
          "references": "http://manpages.ubuntu.com/manpages/precise/en/man1/talk.1posix.html",
          "selectorId": "517",
          "subCategory": "118c10a2-e272-47c1-92b9-4814ebe97138",
          "trafficClass": "MULTIMEDIA_CONFERENCING",
          "tunnel": "false"
        }
      ],
      "networkIdentity": [
        {
          "id": "7c951634-7803-4d0d-8774-1e1da20a4c72",
          "instanceId": 17394,
          "authEntityId": 17394,
          "displayName": "17394",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "517",
          "protocol": "UDP",
          "upperPort": 0
        },
        {
          "id": "04a2beb4-d503-4a3b-acdf-e60f1a685d3a",
          "instanceId": 17395,
          "authEntityId": 17395,
          "displayName": "17395",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "517",
          "protocol": "TCP",
          "upperPort": 0
        }
      ],
      "parentScalableGroup": {
        "idRef": "fd6264c9-c16a-4311-a335-a3db039256f3"
      },
      "user": [

      ],
      "userGroup": [

      ]
    },
    {
      "id": "c000f18d-4618-48b0-a9a0-3f9c4af658b4",
      "instanceId": 15095,
      "authEntityId": 15095,
      "displayName": "15095",
      "authEntityClass": -1909073334,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "createTime": 1517966744222,
      "deployed": false,
      "isSeeded": true,
      "isStale": false,
      "lastUpdateTime": 1517966744222,
      "name": "schedule-transfer",
      "namespace": "scalablegroup:application",
      "provisioningState": "DEFINED",
      "qualifier": "application",
      "resourceVersion": 0,
      "targetIdList": [

      ],
      "type": "scalablegroup",
      "cfsChangeInfo": [

      ],
      "customProvisions": [

      ],
      "scalableGroupExternalHandle": "schedule-transfer",
      "scalableGroupType": "APPLICATION",
      "securityGroupTag": 0,
      "state": "ACTIVE",
      "vnAgnostic": true,
      "identitySource": {
        "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
        "instanceId": 10010,
        "authEntityId": 10010,
        "displayName": "10010",
        "authEntityClass": 1738953278,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "state": "INACTIVE",
        "type": "NBAR"
      },
      "indicativeNetworkIdentity": [
        {
          "id": "460bdaba-1262-43f4-a0fe-c0c194f0c3f1",
          "instanceId": 14919,
          "authEntityId": 14919,
          "displayName": "14919",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipProtocolNumbers": "118",
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "protocol": "STP",
          "upperPort": 0
        }
      ],
      "networkApplications": [
        {
          "id": "771e6974-1e61-4159-94a5-df1b42a6ab9e",
          "instanceId": 16038,
          "authEntityId": 16038,
          "displayName": "16038",
          "authEntityClass": -217092956,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "appProtocol": "ip",
          "applicationGroup": "other",
          "applicationType": "DEFAULT",
          "categoryId": "6daa3dfd-3526-407a-88f0-97824b3f3a24",
          "createTime": 1517966744222,
          "encrypted": "unassigned",
          "engineId": "1",
          "helpString": "Schedule Transfer Protocol",
          "ignoreConflict": false,
          "lastUpdateTime": 1517966744222,
          "longDescription": "Scheduled Transfer Protocol (STP) is a new ANSI specifed connection-oriented data transfer protocol. In STP small control messages are used to allocate bufers on the remote host before any data transfer. This reduces the workload of the receiver considerably and makes hardware acceleration relatively simple to implement.",
          "name": "schedule-transfer",
          "nbarId": "872",
          "p2pTechnology": "unassigned",
          "popularity": 2,
          "rank": 65535,
          "references": "http://www.kernel.org/doc/ols/2001/stlinux.pdf",
          "selectorId": "118",
          "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
          "trafficClass": "BULK_DATA",
          "tunnel": "unassigned"
        }
      ],
      "networkIdentity": [
        {
          "id": "f0dc25fd-89ab-426b-8ff8-b8283f0b3b30",
          "instanceId": 14918,
          "authEntityId": 14918,
          "displayName": "14918",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipProtocolNumbers": "118",
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "protocol": "STP",
          "upperPort": 0
        }
      ],
      "parentScalableGroup": {
        "idRef": "000c15bf-28fe-4fbb-9b69-35e6502721f1"
      },
      "user": [

      ],
      "userGroup": [

      ]
    },
    {
      "id": "c0145044-9a08-4a70-bdc5-76e96f1d9070",
      "instanceId": 15108,
      "authEntityId": 15108,
      "displayName": "15108",
      "authEntityClass": -1909073334,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "createTime": 1517966744252,
      "deployed": false,
      "isSeeded": true,
      "isStale": false,
      "lastUpdateTime": 1517966744252,
      "name": "sdrp",
      "namespace": "scalablegroup:application",
      "provisioningState": "DEFINED",
      "qualifier": "application",
      "resourceVersion": 0,
      "targetIdList": [

      ],
      "type": "scalablegroup",
      "cfsChangeInfo": [

      ],
      "customProvisions": [

      ],
      "scalableGroupExternalHandle": "sdrp",
      "scalableGroupType": "APPLICATION",
      "securityGroupTag": 0,
      "state": "ACTIVE",
      "vnAgnostic": true,
      "identitySource": {
        "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
        "instanceId": 10010,
        "authEntityId": 10010,
        "displayName": "10010",
        "authEntityClass": 1738953278,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "state": "INACTIVE",
        "type": "NBAR"
      },
      "indicativeNetworkIdentity": [
        {
          "id": "5cb1adac-9655-4ad0-ac75-9173355f230c",
          "instanceId": 14963,
          "authEntityId": 14963,
          "displayName": "14963",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipProtocolNumbers": "42",
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "protocol": "SDRP",
          "upperPort": 0
        }
      ],
      "networkApplications": [
        {
          "id": "9be65bf5-cccd-4abe-8ec4-2b1ae244dade",
          "instanceId": 16051,
          "authEntityId": 16051,
          "displayName": "16051",
          "authEntityClass": -217092956,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "appProtocol": "ip",
          "applicationGroup": "other",
          "applicationType": "DEFAULT",
          "categoryId": "1fc17362-9334-4c7b-b752-5afdbb574017",
          "createTime": 1517966744252,
          "encrypted": "unassigned",
          "engineId": "1",
          "helpString": "Source Demand Routing Protocol",
          "ignoreConflict": false,
          "lastUpdateTime": 1517966744252,
          "longDescription": "Source Demand Routing Protocol (SDRP) supports source-initiated selection of routes to complement the route selection provided by existing routing protocols for both inter-domain and intra-domain routes.",
          "name": "sdrp",
          "nbarId": "796",
          "p2pTechnology": "unassigned",
          "popularity": 2,
          "rank": 65535,
          "references": "http://tools.ietf.org/html/rfc1940",
          "selectorId": "42",
          "subCategory": "7cc1b3a6-4102-44e9-b522-0e71afe39a03",
          "trafficClass": "NETWORK_CONTROL",
          "tunnel": "unassigned"
        }
      ],
      "networkIdentity": [
        {
          "id": "14c70826-04c5-4a5c-95bc-5bf4744e08de",
          "instanceId": 14962,
          "authEntityId": 14962,
          "displayName": "14962",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipProtocolNumbers": "42",
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "protocol": "SDRP",
          "upperPort": 0
        }
      ],
      "parentScalableGroup": {
        "idRef": "77e75842-e98b-4112-ad0c-300db4bb11be"
      },
      "user": [

      ],
      "userGroup": [

      ]
    },
    {
      "id": "c034ad54-5eb2-480a-bca5-32cd435c57e1",
      "instanceId": 15015,
      "authEntityId": 15015,
      "displayName": "15015",
      "authEntityClass": -1909073334,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "createTime": 1517966744031,
      "deployed": false,
      "isSeeded": true,
      "isStale": false,
      "lastUpdateTime": 1517966744031,
      "name": "qnx",
      "namespace": "scalablegroup:application",
      "provisioningState": "DEFINED",
      "qualifier": "application",
      "resourceVersion": 0,
      "targetIdList": [

      ],
      "type": "scalablegroup",
      "cfsChangeInfo": [

      ],
      "customProvisions": [

      ],
      "scalableGroupExternalHandle": "qnx",
      "scalableGroupType": "APPLICATION",
      "securityGroupTag": 0,
      "state": "ACTIVE",
      "vnAgnostic": true,
      "identitySource": {
        "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
        "instanceId": 10010,
        "authEntityId": 10010,
        "displayName": "10010",
        "authEntityClass": 1738953278,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "state": "INACTIVE",
        "type": "NBAR"
      },
      "indicativeNetworkIdentity": [
        {
          "id": "df3721e5-109a-4418-8430-6eab01270c8f",
          "instanceId": 14677,
          "authEntityId": 14677,
          "displayName": "14677",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipProtocolNumbers": "106",
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "protocol": "QNX",
          "upperPort": 0
        }
      ],
      "networkApplications": [
        {
          "id": "4b831678-b65e-48f6-a0bf-78ed0b327e7a",
          "instanceId": 11954,
          "authEntityId": 11954,
          "displayName": "11954",
          "authEntityClass": -217092956,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "appProtocol": "ip",
          "applicationGroup": "other",
          "applicationType": "DEFAULT",
          "categoryId": "6daa3dfd-3526-407a-88f0-97824b3f3a24",
          "createTime": 1517966744031,
          "encrypted": "unassigned",
          "engineId": "1",
          "helpString": "QNX",
          "ignoreConflict": false,
          "lastUpdateTime": 1517966744031,
          "longDescription": "QNX IP protocol.   QNX is a commercial Unix-like real-time operating system, aimed primarily at the embedded systems market.",
          "name": "qnx",
          "nbarId": "860",
          "p2pTechnology": "unassigned",
          "popularity": 2,
          "rank": 65535,
          "references": "http://www.qnx.com/",
          "selectorId": "106",
          "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
          "trafficClass": "BULK_DATA",
          "tunnel": "unassigned"
        }
      ],
      "networkIdentity": [
        {
          "id": "109b823c-1d2a-4f33-994e-dde21b366bc3",
          "instanceId": 14676,
          "authEntityId": 14676,
          "displayName": "14676",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipProtocolNumbers": "106",
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "protocol": "QNX",
          "upperPort": 0
        }
      ],
      "parentScalableGroup": {
        "idRef": "000c15bf-28fe-4fbb-9b69-35e6502721f1"
      },
      "user": [

      ],
      "userGroup": [

      ]
    },
    {
      "id": "c09e47f0-464a-4225-bacb-0402e94e88fe",
      "instanceId": 4689,
      "authEntityId": 4689,
      "displayName": "4689",
      "authEntityClass": -1909073334,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "createTime": 1517966743289,
      "deployed": false,
      "isSeeded": true,
      "isStale": false,
      "lastUpdateTime": 1517966743289,
      "name": "ldp",
      "namespace": "scalablegroup:application",
      "provisioningState": "DEFINED",
      "qualifier": "application",
      "resourceVersion": 0,
      "targetIdList": [

      ],
      "type": "scalablegroup",
      "cfsChangeInfo": [

      ],
      "customProvisions": [

      ],
      "scalableGroupExternalHandle": "ldp",
      "scalableGroupType": "APPLICATION",
      "securityGroupTag": 0,
      "state": "ACTIVE",
      "vnAgnostic": true,
      "identitySource": {
        "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
        "instanceId": 10010,
        "authEntityId": 10010,
        "displayName": "10010",
        "authEntityClass": 1738953278,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "state": "INACTIVE",
        "type": "NBAR"
      },
      "indicativeNetworkIdentity": [
        {
          "id": "9bd78c40-5465-4494-ac66-900e2bbad5c3",
          "instanceId": 13742,
          "authEntityId": 13742,
          "displayName": "13742",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "646",
          "protocol": "TCP",
          "upperPort": 0
        },
        {
          "id": "b6da5cf5-a331-4246-a1bd-7ca6abb30315",
          "instanceId": 13743,
          "authEntityId": 13743,
          "displayName": "13743",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "646",
          "protocol": "UDP",
          "upperPort": 0
        }
      ],
      "networkApplications": [
        {
          "id": "26cf2df1-4324-4774-be03-001a7bf7b2d5",
          "instanceId": 11639,
          "authEntityId": 11639,
          "displayName": "11639",
          "authEntityClass": -217092956,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "appProtocol": "tcp/udp",
          "applicationGroup": "other",
          "applicationType": "DEFAULT",
          "categoryId": "1fc17362-9334-4c7b-b752-5afdbb574017",
          "createTime": 1517966743289,
          "encrypted": "false",
          "engineId": "3",
          "helpString": "LDP",
          "ignoreConflict": false,
          "lastUpdateTime": 1517966743289,
          "longDescription": "Label Distribution Protocol (LDP) is a protocol in which routers capable of Multiprotocol Label Switching (MPLS) exchange label mapping information. LDP is used to build and maintain LSP databases that are used to forward traffic through MPLS networks.",
          "name": "ldp",
          "nbarId": "555",
          "p2pTechnology": "false",
          "popularity": 1,
          "rank": 65535,
          "references": "http://tools.ietf.org/html/rfc5036",
          "selectorId": "646",
          "subCategory": "7cc1b3a6-4102-44e9-b522-0e71afe39a03",
          "trafficClass": "NETWORK_CONTROL",
          "tunnel": "false"
        }
      ],
      "networkIdentity": [
        {
          "id": "a0fbce54-dd24-46e7-acd8-2e4479bbd563",
          "instanceId": 13740,
          "authEntityId": 13740,
          "displayName": "13740",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "646",
          "protocol": "TCP",
          "upperPort": 0
        },
        {
          "id": "faccb734-f984-4c13-858e-3ba21fcc2a31",
          "instanceId": 13741,
          "authEntityId": 13741,
          "displayName": "13741",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "646",
          "protocol": "UDP",
          "upperPort": 0
        }
      ],
      "parentScalableGroup": {
        "idRef": "77e75842-e98b-4112-ad0c-300db4bb11be"
      },
      "user": [

      ],
      "userGroup": [

      ]
    },
    {
      "id": "c0b4a2cc-cd6c-4ab8-9a2e-8e53c7188506",
      "instanceId": 15405,
      "authEntityId": 15405,
      "displayName": "15405",
      "authEntityClass": -1909073334,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "createTime": 1517966744885,
      "deployed": false,
      "isSeeded": true,
      "isStale": false,
      "lastUpdateTime": 1517966744885,
      "name": "webster",
      "namespace": "scalablegroup:application",
      "provisioningState": "DEFINED",
      "qualifier": "application",
      "resourceVersion": 0,
      "targetIdList": [

      ],
      "type": "scalablegroup",
      "cfsChangeInfo": [

      ],
      "customProvisions": [

      ],
      "scalableGroupExternalHandle": "webster",
      "scalableGroupType": "APPLICATION",
      "securityGroupTag": 0,
      "state": "ACTIVE",
      "vnAgnostic": true,
      "identitySource": {
        "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
        "instanceId": 10010,
        "authEntityId": 10010,
        "displayName": "10010",
        "authEntityClass": 1738953278,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "state": "INACTIVE",
        "type": "NBAR"
      },
      "indicativeNetworkIdentity": [
        {
          "id": "9581cfb7-d1e2-4cb1-a789-7ac545b81d17",
          "instanceId": 17820,
          "authEntityId": 17820,
          "displayName": "17820",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "765",
          "protocol": "UDP",
          "upperPort": 0
        },
        {
          "id": "9bd3b4c8-04fc-445f-a295-99e713090313",
          "instanceId": 17821,
          "authEntityId": 17821,
          "displayName": "17821",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "765",
          "protocol": "TCP",
          "upperPort": 0
        }
      ],
      "networkApplications": [
        {
          "id": "17f95156-7b42-420a-a830-896b8d9cecba",
          "instanceId": 16348,
          "authEntityId": 16348,
          "displayName": "16348",
          "authEntityClass": -217092956,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "appProtocol": "tcp/udp",
          "applicationGroup": "other",
          "applicationType": "DEFAULT",
          "categoryId": "6daa3dfd-3526-407a-88f0-97824b3f3a24",
          "createTime": 1517966744885,
          "encrypted": "false",
          "engineId": "3",
          "helpString": "webster",
          "ignoreConflict": false,
          "lastUpdateTime": 1517966744885,
          "longDescription": "The webster protocol supports access to a single dictionary and (optionally) to a single thesaurus.",
          "name": "webster",
          "nbarId": "637",
          "p2pTechnology": "false",
          "popularity": 1,
          "rank": 65535,
          "references": "http://tools.ietf.org/html/rfc2229",
          "selectorId": "765",
          "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
          "trafficClass": "BULK_DATA",
          "tunnel": "false"
        }
      ],
      "networkIdentity": [
        {
          "id": "cf605d30-d219-443a-b11c-e619fc29962a",
          "instanceId": 17818,
          "authEntityId": 17818,
          "displayName": "17818",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "765",
          "protocol": "TCP",
          "upperPort": 0
        },
        {
          "id": "7f8f712e-039f-4e70-b882-a5b596aff510",
          "instanceId": 17819,
          "authEntityId": 17819,
          "displayName": "17819",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "765",
          "protocol": "UDP",
          "upperPort": 0
        }
      ],
      "parentScalableGroup": {
        "idRef": "000c15bf-28fe-4fbb-9b69-35e6502721f1"
      },
      "user": [

      ],
      "userGroup": [

      ]
    },
    {
      "id": "c0c0ff48-afa8-49e1-ab17-7ee4d9f632c0",
      "instanceId": 15071,
      "authEntityId": 15071,
      "displayName": "15071",
      "authEntityClass": -1909073334,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "createTime": 1517966744167,
      "deployed": false,
      "isSeeded": true,
      "isStale": false,
      "lastUpdateTime": 1517966744167,
      "name": "rtcp",
      "namespace": "scalablegroup:application",
      "provisioningState": "DEFINED",
      "qualifier": "application",
      "resourceVersion": 0,
      "targetIdList": [

      ],
      "type": "scalablegroup",
      "cfsChangeInfo": [

      ],
      "customProvisions": [

      ],
      "scalableGroupExternalHandle": "rtcp",
      "scalableGroupType": "APPLICATION",
      "securityGroupTag": 0,
      "state": "ACTIVE",
      "vnAgnostic": true,
      "identitySource": {
        "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
        "instanceId": 10010,
        "authEntityId": 10010,
        "displayName": "10010",
        "authEntityClass": 1738953278,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "state": "INACTIVE",
        "type": "NBAR"
      },
      "indicativeNetworkIdentity": [

      ],
      "networkApplications": [
        {
          "id": "24d3b67d-b81f-4d77-aa83-648983a9aaab",
          "instanceId": 12010,
          "authEntityId": 12010,
          "displayName": "12010",
          "authEntityClass": -217092956,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "appProtocol": "tcp/udp",
          "applicationGroup": "other",
          "applicationType": "DEFAULT",
          "categoryId": "38a8e354-e2fe-4f3c-b1c5-2bbb90fb0d99",
          "createTime": 1517966744167,
          "encrypted": "false",
          "engineId": "13",
          "helpString": "Real Time Control Protocol",
          "ignoreConflict": false,
          "lastUpdateTime": 1517966744167,
          "longDescription": "Real Time Transport Control Protocol (RTCP) is augmentation of Real-time Transport Protocol (RTP). RTCP allow monitoring of the data delivery to large multicast networks, provides control and identification functionality. Typically, RTCP uses UDP as its transport protocol.",
          "name": "rtcp",
          "nbarId": "66",
          "p2pTechnology": "false",
          "popularity": 9,
          "rank": 65535,
          "references": "http://www.ietf.org/rfc/rfc3550.txt",
          "selectorId": "66",
          "subCategory": "68c4f989-5735-4e16-8d1c-6a9d89d5df9c",
          "trafficClass": "SIGNALING",
          "tunnel": "false"
        }
      ],
      "networkIdentity": [
        {
          "id": "2f0e473e-5982-4d35-bf0a-9c156118d4e2",
          "instanceId": 14857,
          "authEntityId": 14857,
          "displayName": "14857",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "3478",
          "protocol": "TCP",
          "upperPort": 0
        },
        {
          "id": "de9335fe-0d13-453b-8595-280d363fd648",
          "instanceId": 14858,
          "authEntityId": 14858,
          "displayName": "14858",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "3478",
          "protocol": "UDP",
          "upperPort": 0
        }
      ],
      "parentScalableGroup": {
        "idRef": "a3243a74-3864-4041-a927-0201030da17c"
      },
      "user": [

      ],
      "userGroup": [

      ]
    },
    {
      "id": "c0ebf94a-a6c7-4d54-b6b9-d32b77a45984",
      "instanceId": 4098,
      "authEntityId": 4098,
      "displayName": "4098",
      "authEntityClass": -1909073334,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "createTime": 1517966741064,
      "deployed": false,
      "isSeeded": true,
      "isStale": false,
      "lastUpdateTime": 1517966741064,
      "name": "apc-powerchute",
      "namespace": "scalablegroup:application",
      "provisioningState": "DEFINED",
      "qualifier": "application",
      "resourceVersion": 0,
      "targetIdList": [

      ],
      "type": "scalablegroup",
      "cfsChangeInfo": [

      ],
      "customProvisions": [

      ],
      "scalableGroupExternalHandle": "apc-powerchute",
      "scalableGroupType": "APPLICATION",
      "securityGroupTag": 0,
      "state": "ACTIVE",
      "vnAgnostic": true,
      "identitySource": {
        "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
        "instanceId": 10010,
        "authEntityId": 10010,
        "displayName": "10010",
        "authEntityClass": 1738953278,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "state": "INACTIVE",
        "type": "NBAR"
      },
      "indicativeNetworkIdentity": [
        {
          "id": "c9c3a66e-9e61-480d-8016-a7b2206f7cd4",
          "instanceId": 12156,
          "authEntityId": 12156,
          "displayName": "12156",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "2160,2161,2260,3052,3506,5454,5455,5456,6547,6548,6549,7845,7846,9950,9951,9952",
          "protocol": "TCP",
          "upperPort": 0
        },
        {
          "id": "5fd0a6a4-68ba-4b49-a116-c7f7e93029b5",
          "instanceId": 12157,
          "authEntityId": 12157,
          "displayName": "12157",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "2160,2161,2260,3052,3506,5454,5455,5456,6547,6548,6549,7845,7846,9950,9951,9952",
          "protocol": "UDP",
          "upperPort": 0
        }
      ],
      "networkApplications": [
        {
          "id": "fc9981bf-c5d6-49b9-8f87-3a81c80e7f7f",
          "instanceId": 11062,
          "authEntityId": 11062,
          "displayName": "11062",
          "authEntityClass": -217092956,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "appProtocol": "tcp/udp",
          "applicationGroup": "other",
          "applicationType": "DEFAULT",
          "categoryId": "6daa3dfd-3526-407a-88f0-97824b3f3a24",
          "createTime": 1517966741064,
          "encrypted": "false",
          "engineId": "3",
          "helpString": "American Power Conversion",
          "ignoreConflict": false,
          "lastUpdateTime": 1517966741064,
          "longDescription": "PowerChute is a computer program by American Power Conversion (APC) used to control the uninterruptible power supplies (UPS) the company produces. It provides unattended shutdown of servers and workstations in the event of an extended power outage. It also monitors and logs the UPS status.",
          "name": "apc-powerchute",
          "nbarId": "1374",
          "p2pTechnology": "false",
          "popularity": 1,
          "rank": 65535,
          "references": "http://www.apc.com/",
          "selectorId": "2160",
          "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
          "trafficClass": "BULK_DATA",
          "tunnel": "false"
        }
      ],
      "networkIdentity": [
        {
          "id": "2b647572-6996-47ef-8076-8319bd5a9117",
          "instanceId": 12154,
          "authEntityId": 12154,
          "displayName": "12154",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "2160,2161,2260,3052,3506,5454,5455,5456,6547,6548,6549,7845,7846,9950,9951,9952",
          "protocol": "UDP",
          "upperPort": 0
        },
        {
          "id": "b20681f8-2781-43a2-a07c-5fa108c6ff4f",
          "instanceId": 12155,
          "authEntityId": 12155,
          "displayName": "12155",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "2160,2161,2260,3052,3506,5454,5455,5456,6547,6548,6549,7845,7846,9950,9951,9952",
          "protocol": "TCP",
          "upperPort": 0
        }
      ],
      "parentScalableGroup": {
        "idRef": "000c15bf-28fe-4fbb-9b69-35e6502721f1"
      },
      "user": [

      ],
      "userGroup": [

      ]
    },
    {
      "id": "c14100f1-2a0b-4d3c-987f-dccea039e959",
      "instanceId": 4757,
      "authEntityId": 4757,
      "displayName": "4757",
      "authEntityClass": -1909073334,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "createTime": 1517966743433,
      "deployed": false,
      "isSeeded": true,
      "isStale": false,
      "lastUpdateTime": 1517966743433,
      "name": "modbus",
      "namespace": "scalablegroup:application",
      "provisioningState": "DEFINED",
      "qualifier": "application",
      "resourceVersion": 0,
      "targetIdList": [

      ],
      "type": "scalablegroup",
      "cfsChangeInfo": [

      ],
      "customProvisions": [

      ],
      "scalableGroupExternalHandle": "modbus",
      "scalableGroupType": "APPLICATION",
      "securityGroupTag": 0,
      "state": "ACTIVE",
      "vnAgnostic": true,
      "identitySource": {
        "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
        "instanceId": 10010,
        "authEntityId": 10010,
        "displayName": "10010",
        "authEntityClass": 1738953278,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "state": "INACTIVE",
        "type": "NBAR"
      },
      "indicativeNetworkIdentity": [

      ],
      "networkApplications": [
        {
          "id": "be131b67-7fcd-4a4b-be49-ac87d90f8234",
          "instanceId": 11707,
          "authEntityId": 11707,
          "displayName": "11707",
          "authEntityClass": -217092956,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "applicationGroup": "other",
          "applicationType": "DEFAULT",
          "categoryId": "6daa3dfd-3526-407a-88f0-97824b3f3a24",
          "createTime": 1517966743433,
          "encrypted": "false",
          "engineId": "13",
          "helpString": "Industrial Standard Communication Protocol",
          "ignoreConflict": false,
          "lastUpdateTime": 1517966743433,
          "longDescription": "Modbus is a standard communication protocol for connecting industrial electronic devices, Modbus TCP/IP used the Modbus instruction set and wraps TCP/IP around it",
          "name": "modbus",
          "nbarId": "1491",
          "p2pTechnology": "false",
          "popularity": 6,
          "rank": 65535,
          "references": "http://www.modbus.org/docs/Modbus_Messaging_Implementation_Guide_V1_0b.pdf",
          "selectorId": "555",
          "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
          "trafficClass": "BULK_DATA",
          "tunnel": "true"
        }
      ],
      "networkIdentity": [

      ],
      "parentScalableGroup": {
        "idRef": "000c15bf-28fe-4fbb-9b69-35e6502721f1"
      },
      "user": [

      ],
      "userGroup": [

      ]
    },
    {
      "id": "c14921b8-d184-49f6-aa9b-a736cc01eb52",
      "instanceId": 15165,
      "authEntityId": 15165,
      "displayName": "15165",
      "authEntityClass": -1909073334,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "createTime": 1517966744383,
      "deployed": false,
      "isSeeded": true,
      "isStale": false,
      "lastUpdateTime": 1517966744383,
      "name": "smpnameres",
      "namespace": "scalablegroup:application",
      "provisioningState": "DEFINED",
      "qualifier": "application",
      "resourceVersion": 0,
      "targetIdList": [

      ],
      "type": "scalablegroup",
      "cfsChangeInfo": [

      ],
      "customProvisions": [

      ],
      "scalableGroupExternalHandle": "smpnameres",
      "scalableGroupType": "APPLICATION",
      "securityGroupTag": 0,
      "state": "ACTIVE",
      "vnAgnostic": true,
      "identitySource": {
        "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
        "instanceId": 10010,
        "authEntityId": 10010,
        "displayName": "10010",
        "authEntityClass": 1738953278,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "state": "INACTIVE",
        "type": "NBAR"
      },
      "indicativeNetworkIdentity": [
        {
          "id": "c0ac4f92-a881-4bbe-89e1-dd877ccdc741",
          "instanceId": 17141,
          "authEntityId": 17141,
          "displayName": "17141",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "901",
          "protocol": "UDP",
          "upperPort": 0
        },
        {
          "id": "ad8144f4-8897-4a50-82d5-7a7f7166e4d6",
          "instanceId": 17142,
          "authEntityId": 17142,
          "displayName": "17142",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "901",
          "protocol": "TCP",
          "upperPort": 0
        }
      ],
      "networkApplications": [
        {
          "id": "997aca7a-5135-410e-a814-abeafad4de98",
          "instanceId": 16108,
          "authEntityId": 16108,
          "displayName": "16108",
          "authEntityClass": -217092956,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "appProtocol": "tcp/udp",
          "applicationGroup": "other",
          "applicationType": "DEFAULT",
          "categoryId": "6daa3dfd-3526-407a-88f0-97824b3f3a24",
          "createTime": 1517966744383,
          "encrypted": "false",
          "engineId": "3",
          "helpString": "smpnameres",
          "ignoreConflict": false,
          "lastUpdateTime": 1517966744383,
          "longDescription": "Registered with IANA on port 901 TCP/UDP",
          "name": "smpnameres",
          "nbarId": "664",
          "p2pTechnology": "false",
          "popularity": 1,
          "rank": 65535,
          "references": "http://www.iana.org/assignments/service-names-port-numbers/service-names-port-numbers.xml",
          "selectorId": "901",
          "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
          "trafficClass": "BULK_DATA",
          "tunnel": "false"
        }
      ],
      "networkIdentity": [
        {
          "id": "ade58542-05d1-4f60-9810-6dc53715c919",
          "instanceId": 17139,
          "authEntityId": 17139,
          "displayName": "17139",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "901",
          "protocol": "UDP",
          "upperPort": 0
        },
        {
          "id": "eb51eb0a-4cd0-4473-916f-b7d343ad6cb5",
          "instanceId": 17140,
          "authEntityId": 17140,
          "displayName": "17140",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "901",
          "protocol": "TCP",
          "upperPort": 0
        }
      ],
      "parentScalableGroup": {
        "idRef": "000c15bf-28fe-4fbb-9b69-35e6502721f1"
      },
      "user": [

      ],
      "userGroup": [

      ]
    },
    {
      "id": "c15495a2-048a-474a-9010-7f6a7b878d42",
      "instanceId": 4366,
      "authEntityId": 4366,
      "displayName": "4366",
      "authEntityClass": -1909073334,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "createTime": 1517966742419,
      "deployed": false,
      "isSeeded": true,
      "isStale": false,
      "lastUpdateTime": 1517966742419,
      "name": "directv-soft",
      "namespace": "scalablegroup:application",
      "provisioningState": "DEFINED",
      "qualifier": "application",
      "resourceVersion": 0,
      "targetIdList": [

      ],
      "type": "scalablegroup",
      "cfsChangeInfo": [

      ],
      "customProvisions": [

      ],
      "scalableGroupExternalHandle": "directv-soft",
      "scalableGroupType": "APPLICATION",
      "securityGroupTag": 0,
      "state": "ACTIVE",
      "vnAgnostic": true,
      "identitySource": {
        "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
        "instanceId": 10010,
        "authEntityId": 10010,
        "displayName": "10010",
        "authEntityClass": 1738953278,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "state": "INACTIVE",
        "type": "NBAR"
      },
      "indicativeNetworkIdentity": [
        {
          "id": "17cbc11b-02dc-4568-b0d3-4f7c63d981e0",
          "instanceId": 12881,
          "authEntityId": 12881,
          "displayName": "12881",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "3335",
          "protocol": "UDP",
          "upperPort": 0
        },
        {
          "id": "9ae6ff8c-3ccb-4a15-9335-8572ea00885c",
          "instanceId": 12882,
          "authEntityId": 12882,
          "displayName": "12882",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "3335",
          "protocol": "TCP",
          "upperPort": 0
        }
      ],
      "networkApplications": [
        {
          "id": "f59a3214-74ff-4393-9616-0e7f72106878",
          "instanceId": 11317,
          "authEntityId": 11317,
          "displayName": "11317",
          "authEntityClass": -217092956,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "appProtocol": "tcp/udp",
          "applicationGroup": "other",
          "applicationType": "DEFAULT",
          "categoryId": "a09ef1b3-ba26-4372-aee8-309d50697e76",
          "createTime": 1517966742419,
          "encrypted": "false",
          "engineId": "3",
          "helpString": "Direct TV Software Updates",
          "ignoreConflict": false,
          "lastUpdateTime": 1517966742419,
          "longDescription": "Registered with IANA on port 3335 TCP/UDP",
          "name": "directv-soft",
          "nbarId": "721",
          "p2pTechnology": "false",
          "popularity": 1,
          "rank": 65535,
          "references": "http://www.iana.org/assignments/service-names-port-numbers/service-names-port-numbers.xml",
          "selectorId": "3335",
          "subCategory": "85a797c0-aef8-4284-91a7-677f33e29abb",
          "trafficClass": "MULTIMEDIA_STREAMING",
          "tunnel": "false"
        }
      ],
      "networkIdentity": [
        {
          "id": "102cf291-ea4d-4030-9a81-3e8eeb0b8186",
          "instanceId": 12880,
          "authEntityId": 12880,
          "displayName": "12880",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "3335",
          "protocol": "UDP",
          "upperPort": 0
        },
        {
          "id": "f16604ee-7e3e-4879-a37e-a70fd036ad90",
          "instanceId": 12879,
          "authEntityId": 12879,
          "displayName": "12879",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "3335",
          "protocol": "TCP",
          "upperPort": 0
        }
      ],
      "parentScalableGroup": {
        "idRef": "fd6264c9-c16a-4311-a335-a3db039256f3"
      },
      "user": [

      ],
      "userGroup": [

      ]
    },
    {
      "id": "c15766e0-26ee-4413-b4bb-105a28eed7e9",
      "instanceId": 15080,
      "authEntityId": 15080,
      "displayName": "15080",
      "authEntityClass": -1909073334,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "createTime": 1517966744188,
      "deployed": false,
      "isSeeded": true,
      "isStale": false,
      "lastUpdateTime": 1517966744188,
      "name": "rtsp",
      "namespace": "scalablegroup:application",
      "provisioningState": "DEFINED",
      "qualifier": "application",
      "resourceVersion": 0,
      "targetIdList": [

      ],
      "type": "scalablegroup",
      "cfsChangeInfo": [

      ],
      "customProvisions": [

      ],
      "scalableGroupExternalHandle": "rtsp",
      "scalableGroupType": "APPLICATION",
      "securityGroupTag": 0,
      "state": "ACTIVE",
      "vnAgnostic": true,
      "identitySource": {
        "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
        "instanceId": 10010,
        "authEntityId": 10010,
        "displayName": "10010",
        "authEntityClass": 1738953278,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "state": "INACTIVE",
        "type": "NBAR"
      },
      "indicativeNetworkIdentity": [
        {
          "id": "ac76901b-81e2-471e-a7a3-6f0c8e67ebcd",
          "instanceId": 14875,
          "authEntityId": 14875,
          "displayName": "14875",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "554,8554",
          "protocol": "TCP",
          "upperPort": 0
        }
      ],
      "networkApplications": [
        {
          "id": "3c060b21-9213-44a9-806d-cb78b273f7a4",
          "instanceId": 16023,
          "authEntityId": 16023,
          "displayName": "16023",
          "authEntityClass": -217092956,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "appProtocol": "tcp",
          "applicationGroup": "other",
          "applicationType": "DEFAULT",
          "categoryId": "38a8e354-e2fe-4f3c-b1c5-2bbb90fb0d99",
          "createTime": 1517966744188,
          "encrypted": "false",
          "engineId": "3",
          "helpString": "Real Time Streaming Protocol",
          "ignoreConflict": false,
          "lastUpdateTime": 1517966744188,
          "longDescription": "Real Time Streaming Protocol (RTSP) is a control protocol that is used to control media streaming in real time for various applications. RTSP is based on client server architecture and the common port associated is 554.",
          "name": "rtsp",
          "nbarId": "60",
          "p2pTechnology": "false",
          "popularity": 10,
          "rank": 65535,
          "references": "http://www.ietf.org/rfc/rfc2326.txt",
          "selectorId": "554",
          "subCategory": "68c4f989-5735-4e16-8d1c-6a9d89d5df9c",
          "trafficClass": "SIGNALING",
          "tunnel": "false"
        }
      ],
      "networkIdentity": [
        {
          "id": "2ee49a90-cbfb-4d21-9dca-e3c98f4ca33d",
          "instanceId": 14874,
          "authEntityId": 14874,
          "displayName": "14874",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "80,554,8554",
          "protocol": "TCP",
          "upperPort": 0
        }
      ],
      "parentScalableGroup": {
        "idRef": "a3243a74-3864-4041-a927-0201030da17c"
      },
      "user": [

      ],
      "userGroup": [

      ]
    },
    {
      "id": "c168df55-d1d9-49f3-9c46-2f7ae8ac66d3",
      "instanceId": 4269,
      "authEntityId": 4269,
      "displayName": "4269",
      "authEntityClass": -1909073334,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "createTime": 1517966742011,
      "deployed": false,
      "isSeeded": true,
      "isStale": false,
      "lastUpdateTime": 1517966742011,
      "name": "cnbc",
      "namespace": "scalablegroup:application",
      "provisioningState": "DEFINED",
      "qualifier": "application",
      "resourceVersion": 0,
      "targetIdList": [

      ],
      "type": "scalablegroup",
      "cfsChangeInfo": [

      ],
      "customProvisions": [

      ],
      "scalableGroupExternalHandle": "cnbc",
      "scalableGroupType": "APPLICATION",
      "securityGroupTag": 0,
      "state": "ACTIVE",
      "vnAgnostic": true,
      "identitySource": {
        "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
        "instanceId": 10010,
        "authEntityId": 10010,
        "displayName": "10010",
        "authEntityClass": 1738953278,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "state": "INACTIVE",
        "type": "NBAR"
      },
      "indicativeNetworkIdentity": [

      ],
      "networkApplications": [
        {
          "id": "f39efcf2-8629-4488-9f45-e97b8436e77b",
          "instanceId": 11222,
          "authEntityId": 11222,
          "displayName": "11222",
          "authEntityClass": -217092956,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "appProtocol": "tcp/udp",
          "applicationGroup": "other",
          "applicationType": "DEFAULT",
          "categoryId": "878644b5-fe91-45b4-9204-31c84a73ba48",
          "createTime": 1517966742011,
          "encrypted": "false",
          "engineId": "13",
          "helpString": "CNBC - Official website of CNBC channel for Business and Financial market news.",
          "ignoreConflict": false,
          "lastUpdateTime": 1517966742011,
          "longDescription": "CNBC - Official website of CNBC channel for Business and Financial market news.",
          "name": "cnbc",
          "nbarId": "1686",
          "p2pTechnology": "false",
          "popularity": 3,
          "rank": 65535,
          "references": "http://cnbc.com",
          "selectorId": "756",
          "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
          "trafficClass": "TRANSACTIONAL_DATA",
          "tunnel": "false"
        }
      ],
      "networkIdentity": [
        {
          "id": "d412e220-243a-414f-a6a2-1b0527cbf26d",
          "instanceId": 12580,
          "authEntityId": 12580,
          "displayName": "12580",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "53,5353",
          "protocol": "UDP",
          "upperPort": 0
        },
        {
          "id": "fc240baa-386e-49db-8905-eb7fb2952247",
          "instanceId": 12581,
          "authEntityId": 12581,
          "displayName": "12581",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "53,80,443,5353",
          "protocol": "TCP",
          "upperPort": 0
        }
      ],
      "parentScalableGroup": {
        "idRef": "28e08de4-d7b2-4521-84ce-bc1e5ee0b9cc"
      },
      "user": [

      ],
      "userGroup": [

      ]
    },
    {
      "id": "c17343d2-acf3-4000-b89c-bb07b1a02a04",
      "instanceId": 4323,
      "authEntityId": 4323,
      "displayName": "4323",
      "authEntityClass": -1909073334,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "createTime": 1517966742282,
      "deployed": false,
      "isSeeded": true,
      "isStale": false,
      "lastUpdateTime": 1517966742282,
      "name": "dantz",
      "namespace": "scalablegroup:application",
      "provisioningState": "DEFINED",
      "qualifier": "application",
      "resourceVersion": 0,
      "targetIdList": [

      ],
      "type": "scalablegroup",
      "cfsChangeInfo": [

      ],
      "customProvisions": [

      ],
      "scalableGroupExternalHandle": "dantz",
      "scalableGroupType": "APPLICATION",
      "securityGroupTag": 0,
      "state": "ACTIVE",
      "vnAgnostic": true,
      "identitySource": {
        "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
        "instanceId": 10010,
        "authEntityId": 10010,
        "displayName": "10010",
        "authEntityClass": 1738953278,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "state": "INACTIVE",
        "type": "NBAR"
      },
      "indicativeNetworkIdentity": [
        {
          "id": "139020fd-3653-4d86-beb1-e6866d07edc9",
          "instanceId": 12744,
          "authEntityId": 12744,
          "displayName": "12744",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "497",
          "protocol": "TCP",
          "upperPort": 0
        },
        {
          "id": "f8328032-dc9e-4f91-8ad8-7d4354e31e66",
          "instanceId": 12745,
          "authEntityId": 12745,
          "displayName": "12745",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "497",
          "protocol": "UDP",
          "upperPort": 0
        }
      ],
      "networkApplications": [
        {
          "id": "92ad2d94-341e-4895-a376-0f1ce7a40bad",
          "instanceId": 11275,
          "authEntityId": 11275,
          "displayName": "11275",
          "authEntityClass": -217092956,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "appProtocol": "tcp/udp",
          "applicationGroup": "other",
          "applicationType": "DEFAULT",
          "categoryId": "6daa3dfd-3526-407a-88f0-97824b3f3a24",
          "createTime": 1517966742282,
          "encrypted": "false",
          "engineId": "3",
          "helpString": "dantz",
          "ignoreConflict": false,
          "lastUpdateTime": 1517966742282,
          "longDescription": "Retrospect is a family of backup software applications for the Mac OS, Mac OS X, and Microsoft Windows operating systems.",
          "name": "dantz",
          "nbarId": "411",
          "p2pTechnology": "false",
          "popularity": 1,
          "rank": 65535,
          "references": "http://www.retrospect.com/",
          "selectorId": "497",
          "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
          "trafficClass": "BULK_DATA",
          "tunnel": "false"
        }
      ],
      "networkIdentity": [
        {
          "id": "2436689b-6e37-42ef-a126-023b9c7f58b9",
          "instanceId": 12742,
          "authEntityId": 12742,
          "displayName": "12742",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "497",
          "protocol": "TCP",
          "upperPort": 0
        },
        {
          "id": "a80683cb-bc11-4687-8e4f-ca24bc336625",
          "instanceId": 12743,
          "authEntityId": 12743,
          "displayName": "12743",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "497",
          "protocol": "UDP",
          "upperPort": 0
        }
      ],
      "parentScalableGroup": {
        "idRef": "000c15bf-28fe-4fbb-9b69-35e6502721f1"
      },
      "user": [

      ],
      "userGroup": [

      ]
    },
    {
      "id": "c1757576-cb9a-413f-b214-c1025dc64ca8",
      "instanceId": 4970,
      "authEntityId": 4970,
      "displayName": "4970",
      "authEntityClass": -1909073334,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "createTime": 1517966743939,
      "deployed": false,
      "isSeeded": true,
      "isStale": false,
      "lastUpdateTime": 1517966743939,
      "name": "pnni",
      "namespace": "scalablegroup:application",
      "provisioningState": "DEFINED",
      "qualifier": "application",
      "resourceVersion": 0,
      "targetIdList": [

      ],
      "type": "scalablegroup",
      "cfsChangeInfo": [

      ],
      "customProvisions": [

      ],
      "scalableGroupExternalHandle": "pnni",
      "scalableGroupType": "APPLICATION",
      "securityGroupTag": 0,
      "state": "ACTIVE",
      "vnAgnostic": true,
      "identitySource": {
        "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
        "instanceId": 10010,
        "authEntityId": 10010,
        "displayName": "10010",
        "authEntityClass": 1738953278,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "state": "INACTIVE",
        "type": "NBAR"
      },
      "indicativeNetworkIdentity": [
        {
          "id": "299ef000-3fe1-433d-8b85-92a1310d87e5",
          "instanceId": 14567,
          "authEntityId": 14567,
          "displayName": "14567",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipProtocolNumbers": "102",
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "protocol": "PNNI",
          "upperPort": 0
        }
      ],
      "networkApplications": [
        {
          "id": "15d5b94e-e3bf-4d84-8c44-71336f2f4da3",
          "instanceId": 11919,
          "authEntityId": 11919,
          "displayName": "11919",
          "authEntityClass": -217092956,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "appProtocol": "ip",
          "applicationGroup": "other",
          "applicationType": "DEFAULT",
          "categoryId": "1fc17362-9334-4c7b-b752-5afdbb574017",
          "createTime": 1517966743939,
          "encrypted": "unassigned",
          "engineId": "1",
          "helpString": "PNNI over IP",
          "ignoreConflict": false,
          "lastUpdateTime": 1517966743939,
          "longDescription": "Private Network-to-Network Interface (PNNI) is a suite of network protocols that can be used to discover an ATM network topology, create a database of topology information, and route calls over the discovered topology.",
          "name": "pnni",
          "nbarId": "856",
          "p2pTechnology": "unassigned",
          "popularity": 2,
          "rank": 65535,
          "references": "http://www.cisco.com/en/US/docs/switches/wan/mgx/mgx_8850/software/mgx_r5.2/data/pnni/network/planning/guide/pintro.html",
          "selectorId": "102",
          "subCategory": "7cc1b3a6-4102-44e9-b522-0e71afe39a03",
          "trafficClass": "NETWORK_CONTROL",
          "tunnel": "unassigned"
        }
      ],
      "networkIdentity": [
        {
          "id": "ede33a20-8a5c-4dfe-a11d-23d3f59abeb6",
          "instanceId": 14566,
          "authEntityId": 14566,
          "displayName": "14566",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipProtocolNumbers": "102",
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "protocol": "PNNI",
          "upperPort": 0
        }
      ],
      "parentScalableGroup": {
        "idRef": "77e75842-e98b-4112-ad0c-300db4bb11be"
      },
      "user": [

      ],
      "userGroup": [

      ]
    },
    {
      "id": "c1908961-9a2d-42a0-90f3-a6b4d535a7ed",
      "instanceId": 4957,
      "authEntityId": 4957,
      "displayName": "4957",
      "authEntityClass": -1909073334,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "createTime": 1517966743902,
      "deployed": false,
      "isSeeded": true,
      "isStale": false,
      "lastUpdateTime": 1517966743902,
      "name": "photuris",
      "namespace": "scalablegroup:application",
      "provisioningState": "DEFINED",
      "qualifier": "application",
      "resourceVersion": 0,
      "targetIdList": [

      ],
      "type": "scalablegroup",
      "cfsChangeInfo": [

      ],
      "customProvisions": [

      ],
      "scalableGroupExternalHandle": "photuris",
      "scalableGroupType": "APPLICATION",
      "securityGroupTag": 0,
      "state": "ACTIVE",
      "vnAgnostic": true,
      "identitySource": {
        "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
        "instanceId": 10010,
        "authEntityId": 10010,
        "displayName": "10010",
        "authEntityClass": 1738953278,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "state": "INACTIVE",
        "type": "NBAR"
      },
      "indicativeNetworkIdentity": [
        {
          "id": "a7cb1b2c-b547-41e1-9371-336e8aa6ef20",
          "instanceId": 14532,
          "authEntityId": 14532,
          "displayName": "14532",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "468",
          "protocol": "UDP",
          "upperPort": 0
        },
        {
          "id": "add7960f-97a7-4112-bb54-cbf56c70ce60",
          "instanceId": 14533,
          "authEntityId": 14533,
          "displayName": "14533",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "468",
          "protocol": "TCP",
          "upperPort": 0
        }
      ],
      "networkApplications": [
        {
          "id": "2091e955-d1cb-4e88-90b7-313ee2fdda4a",
          "instanceId": 11906,
          "authEntityId": 11906,
          "displayName": "11906",
          "authEntityClass": -217092956,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "appProtocol": "tcp/udp",
          "applicationGroup": "other",
          "applicationType": "DEFAULT",
          "categoryId": "1fc17362-9334-4c7b-b752-5afdbb574017",
          "createTime": 1517966743902,
          "encrypted": "false",
          "engineId": "3",
          "helpString": "photuris",
          "ignoreConflict": false,
          "lastUpdateTime": 1517966743902,
          "longDescription": "Photuris is a session-key management protocol intended for use with  the IP Security Protocols (AH and ESP). Photuris establishes short-lived session-keys between two parties, without passing the session-keys across the Internet.",
          "name": "photuris",
          "nbarId": "382",
          "p2pTechnology": "false",
          "popularity": 1,
          "rank": 65535,
          "references": "http://tools.ietf.org/html/rfc2522",
          "selectorId": "468",
          "subCategory": "e390124a-0fd6-4253-b68e-4b0c1af8cb4b",
          "trafficClass": "OPS_ADMIN_MGMT",
          "tunnel": "false"
        }
      ],
      "networkIdentity": [
        {
          "id": "496efb85-cd31-4086-977d-8233bdf6e6f2",
          "instanceId": 14530,
          "authEntityId": 14530,
          "displayName": "14530",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "468",
          "protocol": "TCP",
          "upperPort": 0
        },
        {
          "id": "b2b43662-6a27-45c3-b99e-dcc3bb1967ff",
          "instanceId": 14531,
          "authEntityId": 14531,
          "displayName": "14531",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "468",
          "protocol": "UDP",
          "upperPort": 0
        }
      ],
      "parentScalableGroup": {
        "idRef": "7788bf2d-e289-4597-b500-17aacb7e820e"
      },
      "user": [

      ],
      "userGroup": [

      ]
    },
    {
      "id": "c21f7c8a-1133-4123-94b8-941e4124fca8",
      "instanceId": 4232,
      "authEntityId": 4232,
      "displayName": "4232",
      "authEntityClass": -1909073334,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "createTime": 1517966741866,
      "deployed": false,
      "isSeeded": true,
      "isStale": false,
      "lastUpdateTime": 1517966741866,
      "name": "cisco-collaboration",
      "namespace": "scalablegroup:application",
      "provisioningState": "DEFINED",
      "qualifier": "application",
      "resourceVersion": 0,
      "targetIdList": [

      ],
      "type": "scalablegroup",
      "cfsChangeInfo": [

      ],
      "customProvisions": [

      ],
      "scalableGroupExternalHandle": "cisco-collaboration",
      "scalableGroupType": "APPLICATION",
      "securityGroupTag": 0,
      "state": "ACTIVE",
      "vnAgnostic": true,
      "identitySource": {
        "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
        "instanceId": 10010,
        "authEntityId": 10010,
        "displayName": "10010",
        "authEntityClass": 1738953278,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "state": "INACTIVE",
        "type": "NBAR"
      },
      "indicativeNetworkIdentity": [

      ],
      "networkApplications": [
        {
          "id": "42035917-aa03-4f45-bcd9-b5e628c497d3",
          "instanceId": 11187,
          "authEntityId": 11187,
          "displayName": "11187",
          "authEntityClass": -217092956,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "appProtocol": "tcp/udp",
          "applicationGroup": "webex-group",
          "applicationType": "DEFAULT",
          "categoryId": "38a8e354-e2fe-4f3c-b1c5-2bbb90fb0d99",
          "createTime": 1517966741866,
          "encrypted": "true",
          "engineId": "13",
          "helpString": "Cisco Collaboration Media",
          "ignoreConflict": false,
          "lastUpdateTime": 1517966741866,
          "longDescription": "Cisco Collaboration Media - Voice, video and destop sharing by various Cisco unified communication clients.",
          "name": "cisco-collaboration",
          "nbarId": "1740",
          "p2pTechnology": "false",
          "popularity": 9,
          "rank": 65535,
          "references": "http://ciscospark.com",
          "selectorId": "811",
          "subCategory": "05178016-4555-4291-ab4e-43c2ab2a931a",
          "trafficClass": "MULTIMEDIA_CONFERENCING",
          "tunnel": "false"
        }
      ],
      "networkIdentity": [
        {
          "id": "4971b09d-1062-4b64-8c18-e276358e322d",
          "instanceId": 12509,
          "authEntityId": 12509,
          "displayName": "12509",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "53,69,3478,5060,5353",
          "protocol": "UDP",
          "upperPort": 0
        },
        {
          "id": "9d39576a-bdca-4ce9-9cb2-7716ef659e3b",
          "instanceId": 12510,
          "authEntityId": 12510,
          "displayName": "12510",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "53,80,443,2000,2001,2002,3478,5060,5353",
          "protocol": "TCP",
          "upperPort": 0
        }
      ],
      "parentScalableGroup": {
        "idRef": "60f1364d-2a02-4063-8ba1-07817abe0612"
      },
      "user": [

      ],
      "userGroup": [

      ]
    },
    {
      "id": "c27d98c4-c717-4c25-b574-e3d761f02ad1",
      "instanceId": 4089,
      "authEntityId": 4089,
      "displayName": "4089",
      "authEntityClass": -1909073334,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "createTime": 1517966740972,
      "deployed": false,
      "isSeeded": true,
      "isStale": false,
      "lastUpdateTime": 1517966740972,
      "name": "ansatrader",
      "namespace": "scalablegroup:application",
      "provisioningState": "DEFINED",
      "qualifier": "application",
      "resourceVersion": 0,
      "targetIdList": [

      ],
      "type": "scalablegroup",
      "cfsChangeInfo": [

      ],
      "customProvisions": [

      ],
      "scalableGroupExternalHandle": "ansatrader",
      "scalableGroupType": "APPLICATION",
      "securityGroupTag": 0,
      "state": "ACTIVE",
      "vnAgnostic": true,
      "identitySource": {
        "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
        "instanceId": 10010,
        "authEntityId": 10010,
        "displayName": "10010",
        "authEntityClass": 1738953278,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "state": "INACTIVE",
        "type": "NBAR"
      },
      "indicativeNetworkIdentity": [
        {
          "id": "0e38c2b8-2874-4629-9f22-ffbdd41d9cfb",
          "instanceId": 12138,
          "authEntityId": 12138,
          "displayName": "12138",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "124",
          "protocol": "UDP",
          "upperPort": 0
        },
        {
          "id": "4bf923ea-9dd7-4203-8763-d79594f35036",
          "instanceId": 12139,
          "authEntityId": 12139,
          "displayName": "12139",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "124",
          "protocol": "TCP",
          "upperPort": 0
        }
      ],
      "networkApplications": [
        {
          "id": "44b25804-cf15-4799-a2f3-1d99feb42242",
          "instanceId": 11054,
          "authEntityId": 11054,
          "displayName": "11054",
          "authEntityClass": -217092956,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "appProtocol": "tcp/udp",
          "applicationGroup": "other",
          "applicationType": "DEFAULT",
          "categoryId": "6daa3dfd-3526-407a-88f0-97824b3f3a24",
          "createTime": 1517966740972,
          "encrypted": "false",
          "engineId": "3",
          "helpString": "ansatrader",
          "ignoreConflict": false,
          "lastUpdateTime": 1517966740972,
          "longDescription": "ANSAware is an infrastructure for developing and running distributed application. It is available for a number of operating system like SunOS, HP/UX, VMS and MS DOS. A factory server is able to create server processes called capsules, and interfaces for a certain service type dynamically on the local computer node. References to these interfaces are mediated by a server called trader.",
          "name": "ansatrader",
          "nbarId": "993",
          "p2pTechnology": "false",
          "popularity": 1,
          "rank": 65535,
          "references": "http://citeseerx.ist.psu.edu/viewdoc/download?doi=10.1.1.42.275&rep=rep1&type=pdf",
          "selectorId": "124",
          "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
          "trafficClass": "BULK_DATA",
          "tunnel": "false"
        }
      ],
      "networkIdentity": [
        {
          "id": "11373b03-4f5c-4d87-8088-c45a8d300897",
          "instanceId": 12136,
          "authEntityId": 12136,
          "displayName": "12136",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "124",
          "protocol": "TCP",
          "upperPort": 0
        },
        {
          "id": "550e8fbf-0958-4a1a-9766-0e76c8f36fc5",
          "instanceId": 12137,
          "authEntityId": 12137,
          "displayName": "12137",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "124",
          "protocol": "UDP",
          "upperPort": 0
        }
      ],
      "parentScalableGroup": {
        "idRef": "000c15bf-28fe-4fbb-9b69-35e6502721f1"
      },
      "user": [

      ],
      "userGroup": [

      ]
    },
    {
      "id": "c2fd2c52-d735-4a74-a741-b630daeb7904",
      "instanceId": 15322,
      "authEntityId": 15322,
      "displayName": "15322",
      "authEntityClass": -1909073334,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "createTime": 1517966744736,
      "deployed": false,
      "isSeeded": true,
      "isStale": false,
      "lastUpdateTime": 1517966744736,
      "name": "unidata-ldm",
      "namespace": "scalablegroup:application",
      "provisioningState": "DEFINED",
      "qualifier": "application",
      "resourceVersion": 0,
      "targetIdList": [

      ],
      "type": "scalablegroup",
      "cfsChangeInfo": [

      ],
      "customProvisions": [

      ],
      "scalableGroupExternalHandle": "unidata-ldm",
      "scalableGroupType": "APPLICATION",
      "securityGroupTag": 0,
      "state": "ACTIVE",
      "vnAgnostic": true,
      "identitySource": {
        "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
        "instanceId": 10010,
        "authEntityId": 10010,
        "displayName": "10010",
        "authEntityClass": 1738953278,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "state": "INACTIVE",
        "type": "NBAR"
      },
      "indicativeNetworkIdentity": [
        {
          "id": "441de2b6-712f-448e-a166-a00422d7e8e1",
          "instanceId": 17587,
          "authEntityId": 17587,
          "displayName": "17587",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "388",
          "protocol": "UDP",
          "upperPort": 0
        },
        {
          "id": "425150c1-174c-4d4b-bff8-ad8a91428a18",
          "instanceId": 17588,
          "authEntityId": 17588,
          "displayName": "17588",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "388",
          "protocol": "TCP",
          "upperPort": 0
        }
      ],
      "networkApplications": [
        {
          "id": "6b76e4b6-881b-4850-9b9a-a227aea05601",
          "instanceId": 16265,
          "authEntityId": 16265,
          "displayName": "16265",
          "authEntityClass": -217092956,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "appProtocol": "tcp/udp",
          "applicationGroup": "other",
          "applicationType": "DEFAULT",
          "categoryId": "6daa3dfd-3526-407a-88f0-97824b3f3a24",
          "createTime": 1517966744736,
          "encrypted": "false",
          "engineId": "3",
          "helpString": "Unidata LDM",
          "ignoreConflict": false,
          "lastUpdateTime": 1517966744736,
          "longDescription": "The Unidata Local Data Manager (LDM) is a collection of cooperating programs that select, capture, manage, and distribute arbitrary data products. The system is designed for event-driven data distribution, and is currently used in theUnidata Internet Data Distribution(IDD) project. The LDM system includes network client and server programs and their shared protocols.",
          "name": "unidata-ldm",
          "nbarId": "304",
          "p2pTechnology": "false",
          "popularity": 1,
          "rank": 65535,
          "references": "http://www.unidata.ucar.edu/software/ldm/",
          "selectorId": "388",
          "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
          "trafficClass": "BULK_DATA",
          "tunnel": "false"
        }
      ],
      "networkIdentity": [
        {
          "id": "f30ecef7-c9cf-40a7-98d1-07d3424b0dfa",
          "instanceId": 17585,
          "authEntityId": 17585,
          "displayName": "17585",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "388",
          "protocol": "TCP",
          "upperPort": 0
        },
        {
          "id": "fe7e61a0-de09-4bb9-8035-728d3bc25ae4",
          "instanceId": 17586,
          "authEntityId": 17586,
          "displayName": "17586",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "388",
          "protocol": "UDP",
          "upperPort": 0
        }
      ],
      "parentScalableGroup": {
        "idRef": "000c15bf-28fe-4fbb-9b69-35e6502721f1"
      },
      "user": [

      ],
      "userGroup": [

      ]
    },
    {
      "id": "c329cf85-0ba5-46c1-8639-4a284634d0b3",
      "instanceId": 15016,
      "authEntityId": 15016,
      "displayName": "15016",
      "authEntityClass": -1909073334,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "createTime": 1517966744033,
      "deployed": false,
      "isSeeded": true,
      "isStale": false,
      "lastUpdateTime": 1517966744033,
      "name": "qotd",
      "namespace": "scalablegroup:application",
      "provisioningState": "DEFINED",
      "qualifier": "application",
      "resourceVersion": 0,
      "targetIdList": [

      ],
      "type": "scalablegroup",
      "cfsChangeInfo": [

      ],
      "customProvisions": [

      ],
      "scalableGroupExternalHandle": "qotd",
      "scalableGroupType": "APPLICATION",
      "securityGroupTag": 0,
      "state": "ACTIVE",
      "vnAgnostic": true,
      "identitySource": {
        "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
        "instanceId": 10010,
        "authEntityId": 10010,
        "displayName": "10010",
        "authEntityClass": 1738953278,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "state": "INACTIVE",
        "type": "NBAR"
      },
      "indicativeNetworkIdentity": [
        {
          "id": "5d252014-6e44-44eb-accf-ae37c0cdb878",
          "instanceId": 14680,
          "authEntityId": 14680,
          "displayName": "14680",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "17",
          "protocol": "TCP",
          "upperPort": 0
        },
        {
          "id": "db7479ab-4530-49f4-88dd-d37eeb6118af",
          "instanceId": 14681,
          "authEntityId": 14681,
          "displayName": "14681",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "17",
          "protocol": "UDP",
          "upperPort": 0
        }
      ],
      "networkApplications": [
        {
          "id": "8d1526de-a885-42e4-ba21-75fc4a6b694a",
          "instanceId": 11955,
          "authEntityId": 11955,
          "displayName": "11955",
          "authEntityClass": -217092956,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "appProtocol": "tcp/udp",
          "applicationGroup": "other",
          "applicationType": "DEFAULT",
          "categoryId": "6daa3dfd-3526-407a-88f0-97824b3f3a24",
          "createTime": 1517966744033,
          "encrypted": "false",
          "engineId": "3",
          "helpString": "Quote of the Day",
          "ignoreConflict": false,
          "lastUpdateTime": 1517966744033,
          "longDescription": "The Quote Of The Day (QOTD) service is a useful debugging and measurement tool. A quote of the day service simply sends a short message without regard to the input.",
          "name": "qotd",
          "nbarId": "906",
          "p2pTechnology": "false",
          "popularity": 1,
          "rank": 65535,
          "references": "http://www.ietf.org/rfc/rfc865.txt",
          "selectorId": "17",
          "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
          "trafficClass": "BULK_DATA",
          "tunnel": "false"
        }
      ],
      "networkIdentity": [
        {
          "id": "b163432c-2e9b-4fa6-b16b-89bdcce9d9cf",
          "instanceId": 14678,
          "authEntityId": 14678,
          "displayName": "14678",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "17",
          "protocol": "TCP",
          "upperPort": 0
        },
        {
          "id": "768b8eba-6e89-4b96-8782-cdde80ed51a5",
          "instanceId": 14679,
          "authEntityId": 14679,
          "displayName": "14679",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "17",
          "protocol": "UDP",
          "upperPort": 0
        }
      ],
      "parentScalableGroup": {
        "idRef": "000c15bf-28fe-4fbb-9b69-35e6502721f1"
      },
      "user": [

      ],
      "userGroup": [

      ]
    },
    {
      "id": "c357d45c-0c5d-4072-9d62-8a52ab796293",
      "instanceId": 4361,
      "authEntityId": 4361,
      "displayName": "4361",
      "authEntityClass": -1909073334,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "createTime": 1517966742400,
      "deployed": false,
      "isSeeded": true,
      "isStale": false,
      "lastUpdateTime": 1517966742400,
      "name": "digital-vrc",
      "namespace": "scalablegroup:application",
      "provisioningState": "DEFINED",
      "qualifier": "application",
      "resourceVersion": 0,
      "targetIdList": [

      ],
      "type": "scalablegroup",
      "cfsChangeInfo": [

      ],
      "customProvisions": [

      ],
      "scalableGroupExternalHandle": "digital-vrc",
      "scalableGroupType": "APPLICATION",
      "securityGroupTag": 0,
      "state": "ACTIVE",
      "vnAgnostic": true,
      "identitySource": {
        "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
        "instanceId": 10010,
        "authEntityId": 10010,
        "displayName": "10010",
        "authEntityClass": 1738953278,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "state": "INACTIVE",
        "type": "NBAR"
      },
      "indicativeNetworkIdentity": [
        {
          "id": "75b83f59-e509-4e19-87ad-cf5491805e4f",
          "instanceId": 12864,
          "authEntityId": 12864,
          "displayName": "12864",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "466",
          "protocol": "UDP",
          "upperPort": 0
        },
        {
          "id": "61103322-e123-4596-b313-2c060054af89",
          "instanceId": 12863,
          "authEntityId": 12863,
          "displayName": "12863",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "466",
          "protocol": "TCP",
          "upperPort": 0
        }
      ],
      "networkApplications": [
        {
          "id": "f771c8bd-b85a-4215-be15-bfc0a0c81973",
          "instanceId": 11312,
          "authEntityId": 11312,
          "displayName": "11312",
          "authEntityClass": -217092956,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "appProtocol": "tcp/udp",
          "applicationGroup": "other",
          "applicationType": "DEFAULT",
          "categoryId": "6daa3dfd-3526-407a-88f0-97824b3f3a24",
          "createTime": 1517966742400,
          "encrypted": "false",
          "engineId": "3",
          "helpString": "digital-vrc",
          "ignoreConflict": false,
          "lastUpdateTime": 1517966742400,
          "longDescription": "Registered with IANA on port 466 TCP/UDP",
          "name": "digital-vrc",
          "nbarId": "380",
          "p2pTechnology": "false",
          "popularity": 1,
          "rank": 65535,
          "references": "http://www.iana.org/assignments/service-names-port-numbers/service-names-port-numbers.xml",
          "selectorId": "466",
          "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
          "trafficClass": "BULK_DATA",
          "tunnel": "false"
        }
      ],
      "networkIdentity": [
        {
          "id": "9dd818b6-82dc-4a8e-9755-eb3fc501574f",
          "instanceId": 12861,
          "authEntityId": 12861,
          "displayName": "12861",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "466",
          "protocol": "TCP",
          "upperPort": 0
        },
        {
          "id": "5ea9b630-764f-4648-b350-f0e2aa548738",
          "instanceId": 12862,
          "authEntityId": 12862,
          "displayName": "12862",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "466",
          "protocol": "UDP",
          "upperPort": 0
        }
      ],
      "parentScalableGroup": {
        "idRef": "000c15bf-28fe-4fbb-9b69-35e6502721f1"
      },
      "user": [

      ],
      "userGroup": [

      ]
    },
    {
      "id": "c360343e-0aef-43a1-a858-3d81185a37f2",
      "instanceId": 4234,
      "authEntityId": 4234,
      "displayName": "4234",
      "authEntityClass": -1909073334,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "createTime": 1517966741873,
      "deployed": false,
      "isSeeded": true,
      "isStale": false,
      "lastUpdateTime": 1517966741873,
      "name": "cisco-fna",
      "namespace": "scalablegroup:application",
      "provisioningState": "DEFINED",
      "qualifier": "application",
      "resourceVersion": 0,
      "targetIdList": [

      ],
      "type": "scalablegroup",
      "cfsChangeInfo": [

      ],
      "customProvisions": [

      ],
      "scalableGroupExternalHandle": "cisco-fna",
      "scalableGroupType": "APPLICATION",
      "securityGroupTag": 0,
      "state": "ACTIVE",
      "vnAgnostic": true,
      "identitySource": {
        "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
        "instanceId": 10010,
        "authEntityId": 10010,
        "displayName": "10010",
        "authEntityClass": 1738953278,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "state": "INACTIVE",
        "type": "NBAR"
      },
      "indicativeNetworkIdentity": [
        {
          "id": "c69b2b86-9398-4e9b-8211-9411190245b8",
          "instanceId": 12513,
          "authEntityId": 12513,
          "displayName": "12513",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "130",
          "protocol": "TCP",
          "upperPort": 0
        },
        {
          "id": "1a58ba82-f0a9-4961-9b3a-ac2b11fad1cf",
          "instanceId": 12514,
          "authEntityId": 12514,
          "displayName": "12514",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "130",
          "protocol": "UDP",
          "upperPort": 0
        }
      ],
      "networkApplications": [
        {
          "id": "3e7d36c7-e696-4b51-b1ef-e5ac36489f37",
          "instanceId": 11189,
          "authEntityId": 11189,
          "displayName": "11189",
          "authEntityClass": -217092956,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "appProtocol": "tcp/udp",
          "applicationGroup": "other",
          "applicationType": "DEFAULT",
          "categoryId": "1fc17362-9334-4c7b-b752-5afdbb574017",
          "createTime": 1517966741873,
          "encrypted": "false",
          "engineId": "3",
          "helpString": "cisco FNATIVE",
          "ignoreConflict": false,
          "lastUpdateTime": 1517966741873,
          "longDescription": "Cisco FNATIVE, Registered with IANA on port 130 TCP/UDP",
          "name": "cisco-fna",
          "nbarId": "999",
          "p2pTechnology": "false",
          "popularity": 3,
          "rank": 65535,
          "references": "http://www.iana.org/assignments/service-names-port-numbers/service-names-port-numbers.xml",
          "selectorId": "130",
          "subCategory": "f3c0f761-d894-4112-ab89-b90fc84fd660",
          "trafficClass": "OPS_ADMIN_MGMT",
          "tunnel": "false"
        }
      ],
      "networkIdentity": [
        {
          "id": "a2b6ec45-df08-4132-b0d5-aeda46c60e88",
          "instanceId": 12512,
          "authEntityId": 12512,
          "displayName": "12512",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "130",
          "protocol": "UDP",
          "upperPort": 0
        },
        {
          "id": "9d97c2d4-733f-4fd6-9c2f-f3a671655b73",
          "instanceId": 12511,
          "authEntityId": 12511,
          "displayName": "12511",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "130",
          "protocol": "TCP",
          "upperPort": 0
        }
      ],
      "parentScalableGroup": {
        "idRef": "5fcd74d4-8726-47f7-a088-c457863da7ef"
      },
      "user": [

      ],
      "userGroup": [

      ]
    },
    {
      "id": "c3a0aecc-2fef-47e9-b33e-82c3e0418afe",
      "instanceId": 15056,
      "authEntityId": 15056,
      "displayName": "15056",
      "authEntityClass": -1909073334,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "createTime": 1517966744131,
      "deployed": false,
      "isSeeded": true,
      "isStale": false,
      "lastUpdateTime": 1517966744131,
      "name": "rmiregistry",
      "namespace": "scalablegroup:application",
      "provisioningState": "DEFINED",
      "qualifier": "application",
      "resourceVersion": 0,
      "targetIdList": [

      ],
      "type": "scalablegroup",
      "cfsChangeInfo": [

      ],
      "customProvisions": [

      ],
      "scalableGroupExternalHandle": "rmiregistry",
      "scalableGroupType": "APPLICATION",
      "securityGroupTag": 0,
      "state": "ACTIVE",
      "vnAgnostic": true,
      "identitySource": {
        "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
        "instanceId": 10010,
        "authEntityId": 10010,
        "displayName": "10010",
        "authEntityClass": 1738953278,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "state": "INACTIVE",
        "type": "NBAR"
      },
      "indicativeNetworkIdentity": [
        {
          "id": "39afd4fe-9f01-4a87-a2c1-6e6210629257",
          "instanceId": 14807,
          "authEntityId": 14807,
          "displayName": "14807",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "1099",
          "protocol": "TCP",
          "upperPort": 0
        },
        {
          "id": "231fe489-2023-4620-b112-557be4f491f9",
          "instanceId": 14808,
          "authEntityId": 14808,
          "displayName": "14808",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "1099",
          "protocol": "UDP",
          "upperPort": 0
        }
      ],
      "networkApplications": [
        {
          "id": "5ac8b75e-4602-414d-8178-dcbaf8f577d7",
          "instanceId": 11995,
          "authEntityId": 11995,
          "displayName": "11995",
          "authEntityClass": -217092956,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "appProtocol": "tcp/udp",
          "applicationGroup": "other",
          "applicationType": "DEFAULT",
          "categoryId": "6daa3dfd-3526-407a-88f0-97824b3f3a24",
          "createTime": 1517966744131,
          "encrypted": "false",
          "engineId": "3",
          "helpString": "rmiregistry",
          "ignoreConflict": false,
          "lastUpdateTime": 1517966744131,
          "longDescription": "rmiregistry is a command that creates and starts a remote object registry on the current host.",
          "name": "rmiregistry",
          "nbarId": "683",
          "p2pTechnology": "false",
          "popularity": 1,
          "rank": 65535,
          "references": "http://docs.oracle.com/javase/1.4.2/docs/tooldocs/windows/rmiregistry.html",
          "selectorId": "1099",
          "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
          "trafficClass": "BULK_DATA",
          "tunnel": "false"
        }
      ],
      "networkIdentity": [
        {
          "id": "95f745c9-3502-4606-a799-3696ac09c585",
          "instanceId": 14805,
          "authEntityId": 14805,
          "displayName": "14805",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "1099",
          "protocol": "TCP",
          "upperPort": 0
        },
        {
          "id": "d79c3ce6-3576-45df-bea4-97678eeedaf7",
          "instanceId": 14806,
          "authEntityId": 14806,
          "displayName": "14806",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "1099",
          "protocol": "UDP",
          "upperPort": 0
        }
      ],
      "parentScalableGroup": {
        "idRef": "000c15bf-28fe-4fbb-9b69-35e6502721f1"
      },
      "user": [

      ],
      "userGroup": [

      ]
    },
    {
      "id": "c3cc5bad-80c4-4704-9c29-f1c4d5e1489a",
      "instanceId": 4528,
      "authEntityId": 4528,
      "displayName": "4528",
      "authEntityClass": -1909073334,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "createTime": 1517966742898,
      "deployed": false,
      "isSeeded": true,
      "isStale": false,
      "lastUpdateTime": 1517966742898,
      "name": "hangouts-chat",
      "namespace": "scalablegroup:application",
      "provisioningState": "DEFINED",
      "qualifier": "application",
      "resourceVersion": 0,
      "targetIdList": [

      ],
      "type": "scalablegroup",
      "cfsChangeInfo": [

      ],
      "customProvisions": [

      ],
      "scalableGroupExternalHandle": "hangouts-chat",
      "scalableGroupType": "APPLICATION",
      "securityGroupTag": 0,
      "state": "ACTIVE",
      "vnAgnostic": true,
      "identitySource": {
        "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
        "instanceId": 10010,
        "authEntityId": 10010,
        "displayName": "10010",
        "authEntityClass": 1738953278,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "state": "INACTIVE",
        "type": "NBAR"
      },
      "indicativeNetworkIdentity": [

      ],
      "networkApplications": [
        {
          "id": "8ac17672-b277-4ae8-b91b-b11d86a214ef",
          "instanceId": 11479,
          "authEntityId": 11479,
          "displayName": "11479",
          "authEntityClass": -217092956,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "applicationGroup": "hangouts-group",
          "applicationType": "DEFAULT",
          "categoryId": "0f6cab47-be95-4124-9db3-360ccd55f983",
          "createTime": 1517966742898,
          "encrypted": "true",
          "engineId": "13",
          "helpString": "Google Hangouts chat",
          "ignoreConflict": false,
          "lastUpdateTime": 1517966742898,
          "longDescription": "Google Hangouts chat",
          "name": "hangouts-chat",
          "nbarId": "1091",
          "p2pTechnology": "false",
          "popularity": 10,
          "rank": 65535,
          "references": "https://hangouts.google.com/",
          "selectorId": "1091",
          "subCategory": "118c10a2-e272-47c1-92b9-4814ebe97138",
          "trafficClass": "TRANSACTIONAL_DATA",
          "tunnel": "false"
        }
      ],
      "networkIdentity": [

      ],
      "parentScalableGroup": {
        "idRef": "82348eb1-d8b8-4daf-8532-15e1a5f9cdd0"
      },
      "user": [

      ],
      "userGroup": [

      ]
    },
    {
      "id": "c3d55c39-d6d9-493b-9270-27283f82937c",
      "instanceId": 4633,
      "authEntityId": 4633,
      "displayName": "4633",
      "authEntityClass": -1909073334,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "createTime": 1517966743170,
      "deployed": false,
      "isSeeded": true,
      "isStale": false,
      "lastUpdateTime": 1517966743170,
      "name": "ipv6-route",
      "namespace": "scalablegroup:application",
      "provisioningState": "DEFINED",
      "qualifier": "application",
      "resourceVersion": 0,
      "targetIdList": [

      ],
      "type": "scalablegroup",
      "cfsChangeInfo": [

      ],
      "customProvisions": [

      ],
      "scalableGroupExternalHandle": "ipv6-route",
      "scalableGroupType": "APPLICATION",
      "securityGroupTag": 0,
      "state": "ACTIVE",
      "vnAgnostic": true,
      "identitySource": {
        "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
        "instanceId": 10010,
        "authEntityId": 10010,
        "displayName": "10010",
        "authEntityClass": 1738953278,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "state": "INACTIVE",
        "type": "NBAR"
      },
      "indicativeNetworkIdentity": [

      ],
      "networkApplications": [
        {
          "id": "583fd240-8942-4917-922b-63222bd90f75",
          "instanceId": 11583,
          "authEntityId": 11583,
          "displayName": "11583",
          "authEntityClass": -217092956,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "applicationGroup": "other",
          "applicationType": "DEFAULT",
          "categoryId": "6daa3dfd-3526-407a-88f0-97824b3f3a24",
          "createTime": 1517966743170,
          "encrypted": "unassigned",
          "engineId": "1",
          "helpString": "DEPRECATED, traffic will not match",
          "ignoreConflict": false,
          "lastUpdateTime": 1517966743170,
          "longDescription": "Deprecated: Routing Header for IPv6",
          "name": "ipv6-route",
          "nbarId": "797",
          "p2pTechnology": "unassigned",
          "popularity": 0,
          "rank": 65535,
          "references": "http://www.iana.org/assignments/protocol-numbers/protocol-numbers.xml",
          "selectorId": "43",
          "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
          "trafficClass": "BULK_DATA",
          "tunnel": "unassigned"
        }
      ],
      "networkIdentity": [

      ],
      "parentScalableGroup": {
        "idRef": "000c15bf-28fe-4fbb-9b69-35e6502721f1"
      },
      "user": [

      ],
      "userGroup": [

      ]
    },
    {
      "id": "c3df3602-134d-44d8-a208-2644e553ca47",
      "instanceId": 15136,
      "authEntityId": 15136,
      "displayName": "15136",
      "authEntityClass": -1909073334,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "createTime": 1517966744316,
      "deployed": false,
      "isSeeded": true,
      "isStale": false,
      "lastUpdateTime": 1517966744316,
      "name": "shockwave",
      "namespace": "scalablegroup:application",
      "provisioningState": "DEFINED",
      "qualifier": "application",
      "resourceVersion": 0,
      "targetIdList": [

      ],
      "type": "scalablegroup",
      "cfsChangeInfo": [

      ],
      "customProvisions": [

      ],
      "scalableGroupExternalHandle": "shockwave",
      "scalableGroupType": "APPLICATION",
      "securityGroupTag": 0,
      "state": "ACTIVE",
      "vnAgnostic": true,
      "identitySource": {
        "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
        "instanceId": 10010,
        "authEntityId": 10010,
        "displayName": "10010",
        "authEntityClass": 1738953278,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "state": "INACTIVE",
        "type": "NBAR"
      },
      "indicativeNetworkIdentity": [
        {
          "id": "b7af682a-5f22-48c1-a76c-544bb84294f4",
          "instanceId": 17056,
          "authEntityId": 17056,
          "displayName": "17056",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "1626",
          "protocol": "TCP",
          "upperPort": 0
        },
        {
          "id": "cf609da1-1f2a-46da-927a-5bc203b2b073",
          "instanceId": 17057,
          "authEntityId": 17057,
          "displayName": "17057",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "1626",
          "protocol": "UDP",
          "upperPort": 0
        }
      ],
      "networkApplications": [
        {
          "id": "bb7989ce-83f6-49fb-815c-be541f6122bd",
          "instanceId": 16079,
          "authEntityId": 16079,
          "displayName": "16079",
          "authEntityClass": -217092956,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "appProtocol": "tcp/udp",
          "applicationGroup": "other",
          "applicationType": "DEFAULT",
          "categoryId": "878644b5-fe91-45b4-9204-31c84a73ba48",
          "createTime": 1517966744316,
          "encrypted": "false",
          "engineId": "3",
          "helpString": "Shockwave",
          "ignoreConflict": false,
          "lastUpdateTime": 1517966744316,
          "longDescription": "Adobe Shockwave (formerly Macromedia Shockwave) is a multimedia platform used to add animation and interactivity to web pages.  It allows Adobe Director applications to be published on the Internet and viewed in a web browser on any computer which has the Shockwave plug-in installed.",
          "name": "shockwave",
          "nbarId": "707",
          "p2pTechnology": "false",
          "popularity": 1,
          "rank": 65535,
          "references": "http://www.adobe.com/products/shockwaveplayer/",
          "selectorId": "1626",
          "subCategory": "fe150151-77ab-4ac6-805f-77d0d642b798",
          "trafficClass": "MULTIMEDIA_STREAMING",
          "tunnel": "false"
        }
      ],
      "networkIdentity": [
        {
          "id": "d4d4b730-c39e-4bc3-9a0b-abdc65ea35e2",
          "instanceId": 17054,
          "authEntityId": 17054,
          "displayName": "17054",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "1626",
          "protocol": "TCP",
          "upperPort": 0
        },
        {
          "id": "14763b78-51a9-493c-aea2-c7fb98c28dfd",
          "instanceId": 17055,
          "authEntityId": 17055,
          "displayName": "17055",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "1626",
          "protocol": "UDP",
          "upperPort": 0
        }
      ],
      "parentScalableGroup": {
        "idRef": "aad510ce-fe95-4dfc-ad06-e494641cc016"
      },
      "user": [

      ],
      "userGroup": [

      ]
    },
    {
      "id": "c3e3b13e-c816-42fa-b0b2-c0241209596e",
      "instanceId": 4542,
      "authEntityId": 4542,
      "displayName": "4542",
      "authEntityClass": -1909073334,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "createTime": 1517966742941,
      "deployed": false,
      "isSeeded": true,
      "isStale": false,
      "lastUpdateTime": 1517966742941,
      "name": "hmmp-ind",
      "namespace": "scalablegroup:application",
      "provisioningState": "DEFINED",
      "qualifier": "application",
      "resourceVersion": 0,
      "targetIdList": [

      ],
      "type": "scalablegroup",
      "cfsChangeInfo": [

      ],
      "customProvisions": [

      ],
      "scalableGroupExternalHandle": "hmmp-ind",
      "scalableGroupType": "APPLICATION",
      "securityGroupTag": 0,
      "state": "ACTIVE",
      "vnAgnostic": true,
      "identitySource": {
        "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
        "instanceId": 10010,
        "authEntityId": 10010,
        "displayName": "10010",
        "authEntityClass": 1738953278,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "state": "INACTIVE",
        "type": "NBAR"
      },
      "indicativeNetworkIdentity": [
        {
          "id": "290ea61b-2bce-4836-99f5-e5de5ba4b3a2",
          "instanceId": 13345,
          "authEntityId": 13345,
          "displayName": "13345",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "612",
          "protocol": "TCP",
          "upperPort": 0
        },
        {
          "id": "38cd658e-b618-48f8-b80c-c53fa6e34696",
          "instanceId": 13346,
          "authEntityId": 13346,
          "displayName": "13346",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "612",
          "protocol": "UDP",
          "upperPort": 0
        }
      ],
      "networkApplications": [
        {
          "id": "01c1ecdc-f8b4-461f-9360-a2d97358a8cb",
          "instanceId": 11493,
          "authEntityId": 11493,
          "displayName": "11493",
          "authEntityClass": -217092956,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "appProtocol": "tcp/udp",
          "applicationGroup": "other",
          "applicationType": "DEFAULT",
          "categoryId": "1fc17362-9334-4c7b-b752-5afdbb574017",
          "createTime": 1517966742941,
          "encrypted": "false",
          "engineId": "3",
          "helpString": "HMMP Indication",
          "ignoreConflict": false,
          "lastUpdateTime": 1517966742941,
          "longDescription": "Registered with IANA on port 612 TCP/UDP",
          "name": "hmmp-ind",
          "nbarId": "521",
          "p2pTechnology": "false",
          "popularity": 1,
          "rank": 65535,
          "references": "http://www.iana.org/assignments/service-names-port-numbers/service-names-port-numbers.xml",
          "selectorId": "612",
          "subCategory": "f3c0f761-d894-4112-ab89-b90fc84fd660",
          "trafficClass": "OPS_ADMIN_MGMT",
          "tunnel": "false"
        }
      ],
      "networkIdentity": [
        {
          "id": "03421ac7-f622-48f4-87b1-b6ef39465a2c",
          "instanceId": 13344,
          "authEntityId": 13344,
          "displayName": "13344",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "612",
          "protocol": "UDP",
          "upperPort": 0
        },
        {
          "id": "5a570c0b-36f6-4192-8bb8-3f7d9a72ce19",
          "instanceId": 13343,
          "authEntityId": 13343,
          "displayName": "13343",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "612",
          "protocol": "TCP",
          "upperPort": 0
        }
      ],
      "parentScalableGroup": {
        "idRef": "5fcd74d4-8726-47f7-a088-c457863da7ef"
      },
      "user": [

      ],
      "userGroup": [

      ]
    },
    {
      "id": "c41fc0a9-5544-4354-9063-8395c4e9a2bc",
      "instanceId": 4818,
      "authEntityId": 4818,
      "displayName": "4818",
      "authEntityClass": -1909073334,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "createTime": 1517966743552,
      "deployed": false,
      "isSeeded": true,
      "isStale": false,
      "lastUpdateTime": 1517966743552,
      "name": "narp",
      "namespace": "scalablegroup:application",
      "provisioningState": "DEFINED",
      "qualifier": "application",
      "resourceVersion": 0,
      "targetIdList": [

      ],
      "type": "scalablegroup",
      "cfsChangeInfo": [

      ],
      "customProvisions": [

      ],
      "scalableGroupExternalHandle": "narp",
      "scalableGroupType": "APPLICATION",
      "securityGroupTag": 0,
      "state": "ACTIVE",
      "vnAgnostic": true,
      "identitySource": {
        "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
        "instanceId": 10010,
        "authEntityId": 10010,
        "displayName": "10010",
        "authEntityClass": 1738953278,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "state": "INACTIVE",
        "type": "NBAR"
      },
      "indicativeNetworkIdentity": [
        {
          "id": "af844924-9c83-4843-a882-960d1c59872d",
          "instanceId": 14089,
          "authEntityId": 14089,
          "displayName": "14089",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipProtocolNumbers": "54",
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "protocol": "NARP",
          "upperPort": 0
        }
      ],
      "networkApplications": [
        {
          "id": "05ec4d80-a71f-4ef0-a90f-0582e79a02c6",
          "instanceId": 11767,
          "authEntityId": 11767,
          "displayName": "11767",
          "authEntityClass": -217092956,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "appProtocol": "ip",
          "applicationGroup": "other",
          "applicationType": "DEFAULT",
          "categoryId": "6daa3dfd-3526-407a-88f0-97824b3f3a24",
          "createTime": 1517966743552,
          "encrypted": "unassigned",
          "engineId": "1",
          "helpString": "NBMA Address Resolution Protocol",
          "ignoreConflict": false,
          "lastUpdateTime": 1517966743552,
          "longDescription": "NBMA Address Resolution Protocol (NARP) can be used by a source terminal (host or router) connected to a Non-Broadcast, Multi-Access link layer (NBMA) network to find out the NBMA addresses of the a destination terminal provided that the destination terminal is connected to the same NBMA network.",
          "name": "narp",
          "nbarId": "808",
          "p2pTechnology": "unassigned",
          "popularity": 2,
          "rank": 65535,
          "references": "http://tools.ietf.org/html/rfc1735",
          "selectorId": "54",
          "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
          "trafficClass": "BULK_DATA",
          "tunnel": "unassigned"
        }
      ],
      "networkIdentity": [
        {
          "id": "a8595c2d-4e29-45bc-a246-9247f9a4d05d",
          "instanceId": 14088,
          "authEntityId": 14088,
          "displayName": "14088",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipProtocolNumbers": "54",
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "protocol": "NARP",
          "upperPort": 0
        }
      ],
      "parentScalableGroup": {
        "idRef": "000c15bf-28fe-4fbb-9b69-35e6502721f1"
      },
      "user": [

      ],
      "userGroup": [

      ]
    },
    {
      "id": "c43d1632-0e40-4f31-be65-2250a5a13578",
      "instanceId": 4808,
      "authEntityId": 4808,
      "displayName": "4808",
      "authEntityClass": -1909073334,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "createTime": 1517966743533,
      "deployed": false,
      "isSeeded": true,
      "isStale": false,
      "lastUpdateTime": 1517966743533,
      "name": "multiling-http",
      "namespace": "scalablegroup:application",
      "provisioningState": "DEFINED",
      "qualifier": "application",
      "resourceVersion": 0,
      "targetIdList": [

      ],
      "type": "scalablegroup",
      "cfsChangeInfo": [

      ],
      "customProvisions": [

      ],
      "scalableGroupExternalHandle": "multiling-http",
      "scalableGroupType": "APPLICATION",
      "securityGroupTag": 0,
      "state": "ACTIVE",
      "vnAgnostic": true,
      "identitySource": {
        "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
        "instanceId": 10010,
        "authEntityId": 10010,
        "displayName": "10010",
        "authEntityClass": 1738953278,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "state": "INACTIVE",
        "type": "NBAR"
      },
      "indicativeNetworkIdentity": [
        {
          "id": "9334c0e1-d13e-46b7-b291-87043c53d669",
          "instanceId": 14064,
          "authEntityId": 14064,
          "displayName": "14064",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "777",
          "protocol": "UDP",
          "upperPort": 0
        },
        {
          "id": "b3660d0b-8857-4fae-af4d-ffd1e81ff470",
          "instanceId": 14063,
          "authEntityId": 14063,
          "displayName": "14063",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "777",
          "protocol": "TCP",
          "upperPort": 0
        }
      ],
      "networkApplications": [
        {
          "id": "b1c25e44-a110-4e43-9690-569d05c4b299",
          "instanceId": 11757,
          "authEntityId": 11757,
          "displayName": "11757",
          "authEntityClass": -217092956,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "appProtocol": "tcp/udp",
          "applicationGroup": "other",
          "applicationType": "DEFAULT",
          "categoryId": "6daa3dfd-3526-407a-88f0-97824b3f3a24",
          "createTime": 1517966743533,
          "encrypted": "false",
          "engineId": "3",
          "helpString": "Multiling HTTP",
          "ignoreConflict": false,
          "lastUpdateTime": 1517966743533,
          "longDescription": "Registered with IANA on port 777 TCP/UDP",
          "name": "multiling-http",
          "nbarId": "650",
          "p2pTechnology": "false",
          "popularity": 1,
          "rank": 65535,
          "references": "http://www.iana.org/assignments/service-names-port-numbers/service-names-port-numbers.xml",
          "selectorId": "777",
          "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
          "trafficClass": "BULK_DATA",
          "tunnel": "false"
        }
      ],
      "networkIdentity": [
        {
          "id": "c5fd2473-0ffa-4555-89a3-c5e82e28d26c",
          "instanceId": 14061,
          "authEntityId": 14061,
          "displayName": "14061",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "777",
          "protocol": "UDP",
          "upperPort": 0
        },
        {
          "id": "183a5039-6897-4c8a-acf5-a3d1ef1d43b2",
          "instanceId": 14062,
          "authEntityId": 14062,
          "displayName": "14062",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "777",
          "protocol": "TCP",
          "upperPort": 0
        }
      ],
      "parentScalableGroup": {
        "idRef": "000c15bf-28fe-4fbb-9b69-35e6502721f1"
      },
      "user": [

      ],
      "userGroup": [

      ]
    },
    {
      "id": "c49fdc82-841e-473b-87e3-daf75dcbc7be",
      "instanceId": 4324,
      "authEntityId": 4324,
      "displayName": "4324",
      "authEntityClass": -1909073334,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "createTime": 1517966742284,
      "deployed": false,
      "isSeeded": true,
      "isStale": false,
      "lastUpdateTime": 1517966742284,
      "name": "dasp",
      "namespace": "scalablegroup:application",
      "provisioningState": "DEFINED",
      "qualifier": "application",
      "resourceVersion": 0,
      "targetIdList": [

      ],
      "type": "scalablegroup",
      "cfsChangeInfo": [

      ],
      "customProvisions": [

      ],
      "scalableGroupExternalHandle": "dasp",
      "scalableGroupType": "APPLICATION",
      "securityGroupTag": 0,
      "state": "ACTIVE",
      "vnAgnostic": true,
      "identitySource": {
        "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
        "instanceId": 10010,
        "authEntityId": 10010,
        "displayName": "10010",
        "authEntityClass": 1738953278,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "state": "INACTIVE",
        "type": "NBAR"
      },
      "indicativeNetworkIdentity": [
        {
          "id": "6abab1ed-cca4-49d5-83d4-4a52c4252057",
          "instanceId": 12748,
          "authEntityId": 12748,
          "displayName": "12748",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "439",
          "protocol": "UDP",
          "upperPort": 0
        },
        {
          "id": "d3619ddf-7a89-4dab-b1e0-f25b7f75b02d",
          "instanceId": 12749,
          "authEntityId": 12749,
          "displayName": "12749",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "439",
          "protocol": "TCP",
          "upperPort": 0
        }
      ],
      "networkApplications": [
        {
          "id": "8bcf43e8-9392-4c2c-8398-20226d750872",
          "instanceId": 11276,
          "authEntityId": 11276,
          "displayName": "11276",
          "authEntityClass": -217092956,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "appProtocol": "tcp/udp",
          "applicationGroup": "other",
          "applicationType": "DEFAULT",
          "categoryId": "6daa3dfd-3526-407a-88f0-97824b3f3a24",
          "createTime": 1517966742284,
          "encrypted": "false",
          "engineId": "3",
          "helpString": "dasp",
          "ignoreConflict": false,
          "lastUpdateTime": 1517966742284,
          "longDescription": "Datagram Authenticated Session Protocol (DASP) is designed to provide an unordered, reliable, secure session for full-duplex datagram exchange that can be implemented for low power wireless networks and low cost devices.",
          "name": "dasp",
          "nbarId": "354",
          "p2pTechnology": "false",
          "popularity": 1,
          "rank": 65535,
          "references": "http://sedonadev.org/doc/dasp.html",
          "selectorId": "439",
          "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
          "trafficClass": "BULK_DATA",
          "tunnel": "false"
        }
      ],
      "networkIdentity": [
        {
          "id": "50e7b6c9-1bc4-4315-b240-172dd95118c1",
          "instanceId": 12746,
          "authEntityId": 12746,
          "displayName": "12746",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "439",
          "protocol": "UDP",
          "upperPort": 0
        },
        {
          "id": "573fcd8b-67cc-4dc1-ab01-ce8a8589789d",
          "instanceId": 12747,
          "authEntityId": 12747,
          "displayName": "12747",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "439",
          "protocol": "TCP",
          "upperPort": 0
        }
      ],
      "parentScalableGroup": {
        "idRef": "000c15bf-28fe-4fbb-9b69-35e6502721f1"
      },
      "user": [

      ],
      "userGroup": [

      ]
    },
    {
      "id": "c4c81bd6-c68e-4032-bc47-428c0c950d46",
      "instanceId": 4147,
      "authEntityId": 4147,
      "displayName": "4147",
      "authEntityClass": -1909073334,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "createTime": 1517966741381,
      "deployed": false,
      "isSeeded": true,
      "isStale": false,
      "lastUpdateTime": 1517966741381,
      "name": "auth-service",
      "namespace": "scalablegroup:application",
      "provisioningState": "DEFINED",
      "qualifier": "application",
      "resourceVersion": 0,
      "targetIdList": [

      ],
      "type": "scalablegroup",
      "cfsChangeInfo": [

      ],
      "customProvisions": [

      ],
      "scalableGroupExternalHandle": "auth-service",
      "scalableGroupType": "APPLICATION",
      "securityGroupTag": 0,
      "state": "ACTIVE",
      "vnAgnostic": true,
      "identitySource": {
        "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
        "instanceId": 10010,
        "authEntityId": 10010,
        "displayName": "10010",
        "authEntityClass": 1738953278,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "state": "INACTIVE",
        "type": "NBAR"
      },
      "indicativeNetworkIdentity": [

      ],
      "networkApplications": [
        {
          "id": "8397926d-7062-4143-ac5c-fb8bb99274aa",
          "instanceId": 11108,
          "authEntityId": 11108,
          "displayName": "11108",
          "authEntityClass": -217092956,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "applicationGroup": "ldap-group",
          "applicationType": "DEFAULT",
          "categoryId": "1fc17362-9334-4c7b-b752-5afdbb574017",
          "createTime": 1517966741380,
          "encrypted": "false",
          "engineId": "13",
          "helpString": "Authentication service",
          "ignoreConflict": false,
          "lastUpdateTime": 1517966741380,
          "longDescription": "authentication service",
          "name": "auth-service",
          "nbarId": "1755",
          "p2pTechnology": "false",
          "popularity": 10,
          "rank": 65535,
          "references": "https://en.wikipedia.org/wiki/Authentication_protocol",
          "selectorId": "823",
          "subCategory": "e390124a-0fd6-4253-b68e-4b0c1af8cb4b",
          "trafficClass": "OPS_ADMIN_MGMT",
          "tunnel": "false"
        }
      ],
      "networkIdentity": [

      ],
      "parentScalableGroup": {
        "idRef": "7788bf2d-e289-4597-b500-17aacb7e820e"
      },
      "user": [

      ],
      "userGroup": [

      ]
    },
    {
      "id": "c4fa2a9d-6a08-496d-ba5d-d2841d3f2046",
      "instanceId": 4708,
      "authEntityId": 4708,
      "displayName": "4708",
      "authEntityClass": -1909073334,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "createTime": 1517966743328,
      "deployed": false,
      "isSeeded": true,
      "isStale": false,
      "lastUpdateTime": 1517966743328,
      "name": "lotus-notes",
      "namespace": "scalablegroup:application",
      "provisioningState": "DEFINED",
      "qualifier": "application",
      "resourceVersion": 0,
      "targetIdList": [

      ],
      "type": "scalablegroup",
      "cfsChangeInfo": [

      ],
      "customProvisions": [

      ],
      "scalableGroupExternalHandle": "lotus-notes",
      "scalableGroupType": "APPLICATION",
      "securityGroupTag": 0,
      "state": "ACTIVE",
      "vnAgnostic": true,
      "identitySource": {
        "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
        "instanceId": 10010,
        "authEntityId": 10010,
        "displayName": "10010",
        "authEntityClass": 1738953278,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "state": "INACTIVE",
        "type": "NBAR"
      },
      "indicativeNetworkIdentity": [
        {
          "id": "76f0f4df-c8d9-453d-9df7-47f85c965a97",
          "instanceId": 13792,
          "authEntityId": 13792,
          "displayName": "13792",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "1352",
          "protocol": "TCP",
          "upperPort": 0
        },
        {
          "id": "0607b499-36ff-4f78-827c-ad4371c4d132",
          "instanceId": 13793,
          "authEntityId": 13793,
          "displayName": "13793",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "1352",
          "protocol": "UDP",
          "upperPort": 0
        }
      ],
      "networkApplications": [
        {
          "id": "c5746cbc-8344-474e-88e6-4477fc1b8b03",
          "instanceId": 11658,
          "authEntityId": 11658,
          "displayName": "11658",
          "authEntityClass": -217092956,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "appProtocol": "tcp/udp",
          "applicationGroup": "other",
          "applicationType": "DEFAULT",
          "categoryId": "27953c60-b2ff-4e1a-bc52-0187d8295250",
          "createTime": 1517966743328,
          "encrypted": "true",
          "engineId": "3",
          "helpString": "IBM Lotus Notes collaborative client-server platform",
          "ignoreConflict": false,
          "lastUpdateTime": 1517966743328,
          "longDescription": "IBM Lotus Notes provides integrated collaboration functionality, including email, calendaring, contacts management, to do tracking, instant messaging, an office productivity suite (IBM Lotus Symphony), and access to other Lotus Domino applications and databases.",
          "name": "lotus-notes",
          "nbarId": "1470",
          "p2pTechnology": "false",
          "popularity": 8,
          "rank": 65535,
          "references": "http://www-01.ibm.com/software/lotus/notesanddomino/",
          "selectorId": "1352",
          "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
          "trafficClass": "BULK_DATA",
          "tunnel": "false"
        }
      ],
      "networkIdentity": [
        {
          "id": "ccc016ad-3eb2-4305-baca-861d249e8a87",
          "instanceId": 13790,
          "authEntityId": 13790,
          "displayName": "13790",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "1352",
          "protocol": "TCP",
          "upperPort": 0
        },
        {
          "id": "dc6fd68b-89f3-486a-9330-08baf6d2b6ca",
          "instanceId": 13791,
          "authEntityId": 13791,
          "displayName": "13791",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "1352",
          "protocol": "UDP",
          "upperPort": 0
        }
      ],
      "parentScalableGroup": {
        "idRef": "8a7ca43a-32bd-40d2-871a-02220a165cb7"
      },
      "user": [

      ],
      "userGroup": [

      ]
    },
    {
      "id": "c4fa9c9a-7fd2-4c27-871b-fc9c3885cdaa",
      "instanceId": 4933,
      "authEntityId": 4933,
      "displayName": "4933",
      "authEntityClass": -1909073334,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "createTime": 1517966743840,
      "deployed": false,
      "isSeeded": true,
      "isStale": false,
      "lastUpdateTime": 1517966743840,
      "name": "pando",
      "namespace": "scalablegroup:application",
      "provisioningState": "DEFINED",
      "qualifier": "application",
      "resourceVersion": 0,
      "targetIdList": [

      ],
      "type": "scalablegroup",
      "cfsChangeInfo": [

      ],
      "customProvisions": [

      ],
      "scalableGroupExternalHandle": "pando",
      "scalableGroupType": "APPLICATION",
      "securityGroupTag": 0,
      "state": "ACTIVE",
      "vnAgnostic": true,
      "identitySource": {
        "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
        "instanceId": 10010,
        "authEntityId": 10010,
        "displayName": "10010",
        "authEntityClass": 1738953278,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "state": "INACTIVE",
        "type": "NBAR"
      },
      "indicativeNetworkIdentity": [

      ],
      "networkApplications": [
        {
          "id": "6d4ee177-08c1-47b4-ab2d-c0151f929095",
          "instanceId": 11882,
          "authEntityId": 11882,
          "displayName": "11882",
          "authEntityClass": -217092956,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "appProtocol": "tcp/udp",
          "applicationGroup": "other",
          "applicationType": "DEFAULT",
          "categoryId": "b33b2761-d981-4734-810a-a6a1c18ab352",
          "createTime": 1517966743840,
          "encrypted": "true",
          "engineId": "13",
          "helpString": "Peer-to-Peer File-Sharing",
          "ignoreConflict": false,
          "lastUpdateTime": 1517966743840,
          "longDescription": "Pando is a file sharing software that allows the clients to stream, download and share media. Pando is based on peer to peer and client server architectures. It uses BitTorrent protocol to transfer files therefore the underlying protocols for Pando are SSL and HTTP.",
          "name": "pando",
          "nbarId": "1049",
          "p2pTechnology": "true",
          "popularity": 6,
          "rank": 65535,
          "references": "http://www.pando.com/",
          "selectorId": "443",
          "subCategory": "79fe7198-9ce3-4c01-9b58-45a8df3d9513",
          "trafficClass": "BULK_DATA",
          "tunnel": "false"
        }
      ],
      "networkIdentity": [
        {
          "id": "2d55668c-178c-4fd9-85f0-d56c3ecd1182",
          "instanceId": 14464,
          "authEntityId": 14464,
          "displayName": "14464",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "53,80,443,5353",
          "protocol": "TCP",
          "upperPort": 0
        },
        {
          "id": "e109f470-2f43-4e1a-9032-6cadbdb3d955",
          "instanceId": 14463,
          "authEntityId": 14463,
          "displayName": "14463",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "53,5353",
          "protocol": "UDP",
          "upperPort": 0
        }
      ],
      "parentScalableGroup": {
        "idRef": "7a1a3bbb-dc84-4a35-82a4-7e650c4c4761"
      },
      "user": [

      ],
      "userGroup": [

      ]
    },
    {
      "id": "c514e4aa-9a67-4e1b-a2a3-1a63305d16da",
      "instanceId": 4628,
      "authEntityId": 4628,
      "displayName": "4628",
      "authEntityClass": -1909073334,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "createTime": 1517966743159,
      "deployed": false,
      "isSeeded": true,
      "isStale": false,
      "lastUpdateTime": 1517966743159,
      "name": "ipsec",
      "namespace": "scalablegroup:application",
      "provisioningState": "DEFINED",
      "qualifier": "application",
      "resourceVersion": 0,
      "targetIdList": [

      ],
      "type": "scalablegroup",
      "cfsChangeInfo": [

      ],
      "customProvisions": [

      ],
      "scalableGroupExternalHandle": "ipsec",
      "scalableGroupType": "APPLICATION",
      "securityGroupTag": 0,
      "state": "ACTIVE",
      "vnAgnostic": true,
      "identitySource": {
        "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
        "instanceId": 10010,
        "authEntityId": 10010,
        "displayName": "10010",
        "authEntityClass": 1738953278,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "state": "INACTIVE",
        "type": "NBAR"
      },
      "indicativeNetworkIdentity": [

      ],
      "networkApplications": [
        {
          "id": "df5bf2cd-4693-4697-8cf1-0378c3f91a79",
          "instanceId": 11578,
          "authEntityId": 11578,
          "displayName": "11578",
          "authEntityClass": -217092956,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "applicationGroup": "ipsec-group",
          "applicationType": "DEFAULT",
          "categoryId": "70fce3cd-f2bd-48de-9020-7b7840b9e7a1",
          "createTime": 1517966743159,
          "encrypted": "true",
          "engineId": "13",
          "helpString": "IPSec traffic",
          "ignoreConflict": false,
          "lastUpdateTime": 1517966743159,
          "longDescription": "Internet Protocol Security (IPSec) is a framework used to help ensure a private and secure IP communication using cryptographic services.",
          "name": "ipsec",
          "nbarId": "9",
          "p2pTechnology": "false",
          "popularity": 9,
          "rank": 65535,
          "references": "http://www.ietf.org/rfc/rfc2401.txt",
          "selectorId": "9",
          "subCategory": "ab63e736-9aa7-4946-b55b-3b26ddca609c",
          "trafficClass": "BULK_DATA",
          "tunnel": "true"
        }
      ],
      "networkIdentity": [

      ],
      "parentScalableGroup": {
        "idRef": "79f9d505-477f-4ccd-bd2a-c5fc8097a2e1"
      },
      "user": [

      ],
      "userGroup": [

      ]
    },
    {
      "id": "c5c414c7-df90-4f63-a943-6c4afa845d6c",
      "instanceId": 15213,
      "authEntityId": 15213,
      "displayName": "15213",
      "authEntityClass": -1909073334,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "createTime": 1517966744497,
      "deployed": false,
      "isSeeded": true,
      "isStale": false,
      "lastUpdateTime": 1517966744497,
      "name": "statcounter",
      "namespace": "scalablegroup:application",
      "provisioningState": "DEFINED",
      "qualifier": "application",
      "resourceVersion": 0,
      "targetIdList": [

      ],
      "type": "scalablegroup",
      "cfsChangeInfo": [

      ],
      "customProvisions": [

      ],
      "scalableGroupExternalHandle": "statcounter",
      "scalableGroupType": "APPLICATION",
      "securityGroupTag": 0,
      "state": "ACTIVE",
      "vnAgnostic": true,
      "identitySource": {
        "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
        "instanceId": 10010,
        "authEntityId": 10010,
        "displayName": "10010",
        "authEntityClass": 1738953278,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "state": "INACTIVE",
        "type": "NBAR"
      },
      "indicativeNetworkIdentity": [

      ],
      "networkApplications": [
        {
          "id": "ed8918f6-bb7f-499f-8af2-9ac504f810fa",
          "instanceId": 16156,
          "authEntityId": 16156,
          "displayName": "16156",
          "authEntityClass": -217092956,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "appProtocol": "tcp/udp",
          "applicationGroup": "other",
          "applicationType": "DEFAULT",
          "categoryId": "878644b5-fe91-45b4-9204-31c84a73ba48",
          "createTime": 1517966744497,
          "encrypted": "false",
          "engineId": "13",
          "helpString": "StatCounter - Web traffic analyser.",
          "ignoreConflict": false,
          "lastUpdateTime": 1517966744497,
          "longDescription": "StatCounter - Web traffic analyser.",
          "name": "statcounter",
          "nbarId": "1655",
          "p2pTechnology": "false",
          "popularity": 3,
          "rank": 65535,
          "references": "http://statcounter.com",
          "selectorId": "725",
          "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
          "trafficClass": "TRANSACTIONAL_DATA",
          "tunnel": "false"
        }
      ],
      "networkIdentity": [
        {
          "id": "0c346158-22b8-497c-90b9-c0a374a4d905",
          "instanceId": 17278,
          "authEntityId": 17278,
          "displayName": "17278",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "53,80,443,5353",
          "protocol": "TCP",
          "upperPort": 0
        },
        {
          "id": "0d5af89e-7557-4009-a144-75e906d293b8",
          "instanceId": 17279,
          "authEntityId": 17279,
          "displayName": "17279",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "53,5353",
          "protocol": "UDP",
          "upperPort": 0
        }
      ],
      "parentScalableGroup": {
        "idRef": "28e08de4-d7b2-4521-84ce-bc1e5ee0b9cc"
      },
      "user": [

      ],
      "userGroup": [

      ]
    },
    {
      "id": "c61b2775-d4bc-4fed-b40a-a0f75d90b53d",
      "instanceId": 4456,
      "authEntityId": 4456,
      "displayName": "4456",
      "authEntityClass": -1909073334,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "createTime": 1517966742717,
      "deployed": false,
      "isSeeded": true,
      "isStale": false,
      "lastUpdateTime": 1517966742717,
      "name": "flashyahoo",
      "namespace": "scalablegroup:application",
      "provisioningState": "DEFINED",
      "qualifier": "application",
      "resourceVersion": 0,
      "targetIdList": [

      ],
      "type": "scalablegroup",
      "cfsChangeInfo": [

      ],
      "customProvisions": [

      ],
      "scalableGroupExternalHandle": "flashyahoo",
      "scalableGroupType": "APPLICATION",
      "securityGroupTag": 0,
      "state": "ACTIVE",
      "vnAgnostic": true,
      "identitySource": {
        "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
        "instanceId": 10010,
        "authEntityId": 10010,
        "displayName": "10010",
        "authEntityClass": 1738953278,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "state": "INACTIVE",
        "type": "NBAR"
      },
      "indicativeNetworkIdentity": [

      ],
      "networkApplications": [
        {
          "id": "0c2e87f1-9f7d-49f2-904a-44988de814ca",
          "instanceId": 11407,
          "authEntityId": 11407,
          "displayName": "11407",
          "authEntityClass": -217092956,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "appProtocol": "tcp",
          "applicationGroup": "flash-group",
          "applicationType": "DEFAULT",
          "categoryId": "a09ef1b3-ba26-4372-aee8-309d50697e76",
          "createTime": 1517966742717,
          "encrypted": "false",
          "engineId": "13",
          "helpString": "Flash streams at yahoo",
          "ignoreConflict": false,
          "lastUpdateTime": 1517966742717,
          "longDescription": "Flash video is a format used to stream videos on the web; the user only needs a flash player in order to view a flash video. The format uses HTTP as its underlying protocol. Flash-Yahoo is the same as Flash video protocol, but specifically from the Yahoo website.",
          "name": "flashyahoo",
          "nbarId": "118",
          "p2pTechnology": "false",
          "popularity": 6,
          "rank": 65535,
          "references": "http://developer.yahoo.com/flash/",
          "selectorId": "301",
          "subCategory": "85a797c0-aef8-4284-91a7-677f33e29abb",
          "trafficClass": "MULTIMEDIA_STREAMING",
          "tunnel": "false"
        }
      ],
      "networkIdentity": [
        {
          "id": "05574002-4272-4b40-a894-9508c0cd5cb6",
          "instanceId": 13134,
          "authEntityId": 13134,
          "displayName": "13134",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "80",
          "protocol": "TCP",
          "upperPort": 0
        }
      ],
      "parentScalableGroup": {
        "idRef": "fd6264c9-c16a-4311-a335-a3db039256f3"
      },
      "user": [

      ],
      "userGroup": [

      ]
    },
    {
      "id": "c621aeb0-f89a-463e-9c64-1133ecc3a49c",
      "instanceId": 4418,
      "authEntityId": 4418,
      "displayName": "4418",
      "authEntityClass": -1909073334,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "createTime": 1517966742587,
      "deployed": false,
      "isSeeded": true,
      "isStale": false,
      "lastUpdateTime": 1517966742587,
      "name": "entrust-sps",
      "namespace": "scalablegroup:application",
      "provisioningState": "DEFINED",
      "qualifier": "application",
      "resourceVersion": 0,
      "targetIdList": [

      ],
      "type": "scalablegroup",
      "cfsChangeInfo": [

      ],
      "customProvisions": [

      ],
      "scalableGroupExternalHandle": "entrust-sps",
      "scalableGroupType": "APPLICATION",
      "securityGroupTag": 0,
      "state": "ACTIVE",
      "vnAgnostic": true,
      "identitySource": {
        "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
        "instanceId": 10010,
        "authEntityId": 10010,
        "displayName": "10010",
        "authEntityClass": 1738953278,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "state": "INACTIVE",
        "type": "NBAR"
      },
      "indicativeNetworkIdentity": [
        {
          "id": "1b670dd5-47c9-42e0-8434-59abe6a0752c",
          "instanceId": 13050,
          "authEntityId": 13050,
          "displayName": "13050",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "640",
          "protocol": "TCP",
          "upperPort": 0
        },
        {
          "id": "0ac207b5-0a17-49ba-828a-6cacceac645f",
          "instanceId": 13051,
          "authEntityId": 13051,
          "displayName": "13051",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "640",
          "protocol": "UDP",
          "upperPort": 0
        }
      ],
      "networkApplications": [
        {
          "id": "85014a82-0e48-4d21-bec8-31d50e5e9dc6",
          "instanceId": 11369,
          "authEntityId": 11369,
          "displayName": "11369",
          "authEntityClass": -217092956,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "appProtocol": "tcp/udp",
          "applicationGroup": "other",
          "applicationType": "DEFAULT",
          "categoryId": "7e19fdea-6222-4c57-9e66-66c2340d6bc3",
          "createTime": 1517966742587,
          "encrypted": "false",
          "engineId": "3",
          "helpString": "entrust-sps",
          "ignoreConflict": false,
          "lastUpdateTime": 1517966742587,
          "longDescription": "Registered with IANA on port 640 TCP/UDP",
          "name": "entrust-sps",
          "nbarId": "549",
          "p2pTechnology": "false",
          "popularity": 1,
          "rank": 65535,
          "references": "http://www.iana.org/assignments/service-names-port-numbers/service-names-port-numbers.xml",
          "selectorId": "640",
          "subCategory": "e390124a-0fd6-4253-b68e-4b0c1af8cb4b",
          "trafficClass": "OPS_ADMIN_MGMT",
          "tunnel": "false"
        }
      ],
      "networkIdentity": [
        {
          "id": "07722c1b-e68d-4617-8afe-00d482a177ea",
          "instanceId": 13048,
          "authEntityId": 13048,
          "displayName": "13048",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "640",
          "protocol": "TCP",
          "upperPort": 0
        },
        {
          "id": "52662669-da17-4202-b21f-ee9e554ee288",
          "instanceId": 13049,
          "authEntityId": 13049,
          "displayName": "13049",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "640",
          "protocol": "UDP",
          "upperPort": 0
        }
      ],
      "parentScalableGroup": {
        "idRef": "7788bf2d-e289-4597-b500-17aacb7e820e"
      },
      "user": [

      ],
      "userGroup": [

      ]
    },
    {
      "id": "c6422315-c372-437a-bfb4-6f09c59436dd",
      "instanceId": 15356,
      "authEntityId": 15356,
      "displayName": "15356",
      "authEntityClass": -1909073334,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "createTime": 1517966744796,
      "deployed": false,
      "isSeeded": true,
      "isStale": false,
      "lastUpdateTime": 1517966744796,
      "name": "vkontakte",
      "namespace": "scalablegroup:application",
      "provisioningState": "DEFINED",
      "qualifier": "application",
      "resourceVersion": 0,
      "targetIdList": [

      ],
      "type": "scalablegroup",
      "cfsChangeInfo": [

      ],
      "customProvisions": [

      ],
      "scalableGroupExternalHandle": "vkontakte",
      "scalableGroupType": "APPLICATION",
      "securityGroupTag": 0,
      "state": "ACTIVE",
      "vnAgnostic": true,
      "identitySource": {
        "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
        "instanceId": 10010,
        "authEntityId": 10010,
        "displayName": "10010",
        "authEntityClass": 1738953278,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "state": "INACTIVE",
        "type": "NBAR"
      },
      "indicativeNetworkIdentity": [

      ],
      "networkApplications": [
        {
          "id": "7ae81197-ed05-44eb-a4b6-0ba5c6703db5",
          "instanceId": 16299,
          "authEntityId": 16299,
          "displayName": "16299",
          "authEntityClass": -217092956,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "appProtocol": "tcp/udp",
          "applicationGroup": "other",
          "applicationType": "DEFAULT",
          "categoryId": "878644b5-fe91-45b4-9204-31c84a73ba48",
          "createTime": 1517966744796,
          "encrypted": "false",
          "engineId": "13",
          "helpString": "VKontakte - Russian social networking service.",
          "ignoreConflict": false,
          "lastUpdateTime": 1517966744796,
          "longDescription": "VKontakte - Russian social networking service.",
          "name": "vkontakte",
          "nbarId": "1047",
          "p2pTechnology": "false",
          "popularity": 3,
          "rank": 65535,
          "references": "http://vk.com",
          "selectorId": "615",
          "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
          "trafficClass": "TRANSACTIONAL_DATA",
          "tunnel": "false"
        }
      ],
      "networkIdentity": [
        {
          "id": "be7ca598-23ae-4a3d-8b72-e0ebbf52563d",
          "instanceId": 17683,
          "authEntityId": 17683,
          "displayName": "17683",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "53,5353",
          "protocol": "UDP",
          "upperPort": 0
        },
        {
          "id": "87c1fa45-b4e3-4ce2-9ed6-70dfe64dc23f",
          "instanceId": 17684,
          "authEntityId": 17684,
          "displayName": "17684",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "53,80,443,5353",
          "protocol": "TCP",
          "upperPort": 0
        }
      ],
      "parentScalableGroup": {
        "idRef": "28e08de4-d7b2-4521-84ce-bc1e5ee0b9cc"
      },
      "user": [

      ],
      "userGroup": [

      ]
    },
    {
      "id": "c6872970-2753-447a-ad29-ad990ca94023",
      "instanceId": 4238,
      "authEntityId": 4238,
      "displayName": "4238",
      "authEntityClass": -1909073334,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "createTime": 1517966741884,
      "deployed": false,
      "isSeeded": true,
      "isStale": false,
      "lastUpdateTime": 1517966741884,
      "name": "cisco-jabber-audio",
      "namespace": "scalablegroup:application",
      "provisioningState": "DEFINED",
      "qualifier": "application",
      "resourceVersion": 0,
      "targetIdList": [

      ],
      "type": "scalablegroup",
      "cfsChangeInfo": [

      ],
      "customProvisions": [

      ],
      "scalableGroupExternalHandle": "cisco-jabber-audio",
      "scalableGroupType": "APPLICATION",
      "securityGroupTag": 0,
      "state": "ACTIVE",
      "vnAgnostic": true,
      "identitySource": {
        "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
        "instanceId": 10010,
        "authEntityId": 10010,
        "displayName": "10010",
        "authEntityClass": 1738953278,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "state": "INACTIVE",
        "type": "NBAR"
      },
      "indicativeNetworkIdentity": [

      ],
      "networkApplications": [
        {
          "id": "4273274d-7fb4-4ec3-a71b-4f320b54a42f",
          "instanceId": 11192,
          "authEntityId": 11192,
          "displayName": "11192",
          "authEntityClass": -217092956,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "appProtocol": "tcp/udp",
          "applicationGroup": "cisco-jabber-group",
          "applicationType": "DEFAULT",
          "categoryId": "38a8e354-e2fe-4f3c-b1c5-2bbb90fb0d99",
          "createTime": 1517966741884,
          "encrypted": "false",
          "engineId": "13",
          "helpString": "Cisco Jabber Client; Audio Calls and Voice Mail",
          "ignoreConflict": false,
          "lastUpdateTime": 1517966741884,
          "longDescription": "Cisco Jabber is a unified communications client application that provides presence, instant messaging (IM), voice, and video calling capabilities on many platforms. This protocol classifies the audio calls part of Cisco Jabber",
          "name": "cisco-jabber-audio",
          "nbarId": "1494",
          "p2pTechnology": "false",
          "popularity": 10,
          "rank": 65535,
          "references": "http://www.cisco.com/web/products/voice/jabber.html",
          "selectorId": "558",
          "subCategory": "53ce0342-1e2d-455a-acc7-1b50db9d6845",
          "trafficClass": "VOIP_TELEPHONY",
          "tunnel": "false"
        }
      ],
      "networkIdentity": [
        {
          "id": "675377e7-3c8f-445e-89ab-b938f71d163a",
          "instanceId": 12516,
          "authEntityId": 12516,
          "displayName": "12516",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "143,220,5060",
          "protocol": "UDP",
          "upperPort": 0
        },
        {
          "id": "618e6054-e3db-4c60-bee4-1b92c7eef171",
          "instanceId": 12517,
          "authEntityId": 12517,
          "displayName": "12517",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "143,220,5060",
          "protocol": "TCP",
          "upperPort": 0
        }
      ],
      "parentScalableGroup": {
        "idRef": "60f1364d-2a02-4063-8ba1-07817abe0612"
      },
      "user": [

      ],
      "userGroup": [

      ]
    },
    {
      "id": "c68d906a-3c0f-441a-8d59-2c4b6ce15116",
      "instanceId": 4915,
      "authEntityId": 4915,
      "displayName": "4915",
      "authEntityClass": -1909073334,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "createTime": 1517966743754,
      "deployed": false,
      "isSeeded": true,
      "isStale": false,
      "lastUpdateTime": 1517966743754,
      "name": "openvpn",
      "namespace": "scalablegroup:application",
      "provisioningState": "DEFINED",
      "qualifier": "application",
      "resourceVersion": 0,
      "targetIdList": [

      ],
      "type": "scalablegroup",
      "cfsChangeInfo": [

      ],
      "customProvisions": [

      ],
      "scalableGroupExternalHandle": "openvpn",
      "scalableGroupType": "APPLICATION",
      "securityGroupTag": 0,
      "state": "ACTIVE",
      "vnAgnostic": true,
      "identitySource": {
        "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
        "instanceId": 10010,
        "authEntityId": 10010,
        "displayName": "10010",
        "authEntityClass": 1738953278,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "state": "INACTIVE",
        "type": "NBAR"
      },
      "indicativeNetworkIdentity": [

      ],
      "networkApplications": [
        {
          "id": "721b56e8-3a9d-45ef-b566-7b22d92f5295",
          "instanceId": 11864,
          "authEntityId": 11864,
          "displayName": "11864",
          "authEntityClass": -217092956,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "appProtocol": "tcp/udp",
          "applicationGroup": "other",
          "applicationType": "DEFAULT",
          "categoryId": "70fce3cd-f2bd-48de-9020-7b7840b9e7a1",
          "createTime": 1517966743754,
          "encrypted": "true",
          "engineId": "13",
          "helpString": "OpenVPN protocol",
          "ignoreConflict": false,
          "lastUpdateTime": 1517966743754,
          "longDescription": "OpenVPN is a free and open source virtual private network (VPN) program for creating point-to-point or server-to-multiclient encrypted tunnels between host computers. OpenVPN allows peers to authenticate each other using certificates.",
          "name": "openvpn",
          "nbarId": "1314",
          "p2pTechnology": "true",
          "popularity": 8,
          "rank": 65535,
          "references": "http://openvpn.net/",
          "selectorId": "455",
          "subCategory": "ab63e736-9aa7-4946-b55b-3b26ddca609c",
          "trafficClass": "BULK_DATA",
          "tunnel": "true"
        }
      ],
      "networkIdentity": [
        {
          "id": "c54f30c0-a752-4d2e-9acb-92fdbd2451ff",
          "instanceId": 14410,
          "authEntityId": 14410,
          "displayName": "14410",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "53,5353",
          "protocol": "UDP",
          "upperPort": 0
        },
        {
          "id": "4cbb632e-cadf-4e39-84cf-2304cc1b7d84",
          "instanceId": 14411,
          "authEntityId": 14411,
          "displayName": "14411",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "53,80,443,5353",
          "protocol": "TCP",
          "upperPort": 0
        }
      ],
      "parentScalableGroup": {
        "idRef": "79f9d505-477f-4ccd-bd2a-c5fc8097a2e1"
      },
      "user": [

      ],
      "userGroup": [

      ]
    },
    {
      "id": "c6bedb6f-0474-4ca8-924d-43acbe983db9",
      "instanceId": 4639,
      "authEntityId": 4639,
      "displayName": "4639",
      "authEntityClass": -1909073334,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "createTime": 1517966743183,
      "deployed": false,
      "isSeeded": true,
      "isStale": false,
      "lastUpdateTime": 1517966743183,
      "name": "is99c",
      "namespace": "scalablegroup:application",
      "provisioningState": "DEFINED",
      "qualifier": "application",
      "resourceVersion": 0,
      "targetIdList": [

      ],
      "type": "scalablegroup",
      "cfsChangeInfo": [

      ],
      "customProvisions": [

      ],
      "scalableGroupExternalHandle": "is99c",
      "scalableGroupType": "APPLICATION",
      "securityGroupTag": 0,
      "state": "ACTIVE",
      "vnAgnostic": true,
      "identitySource": {
        "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
        "instanceId": 10010,
        "authEntityId": 10010,
        "displayName": "10010",
        "authEntityClass": 1738953278,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "state": "INACTIVE",
        "type": "NBAR"
      },
      "indicativeNetworkIdentity": [
        {
          "id": "c0d68f98-c976-4227-93ba-c2678cfcd989",
          "instanceId": 13601,
          "authEntityId": 13601,
          "displayName": "13601",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "379",
          "protocol": "UDP",
          "upperPort": 0
        },
        {
          "id": "39cc2376-f93a-448e-ac87-20fd5b2f7a56",
          "instanceId": 13602,
          "authEntityId": 13602,
          "displayName": "13602",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "379",
          "protocol": "TCP",
          "upperPort": 0
        }
      ],
      "networkApplications": [
        {
          "id": "d2cf6b31-343a-4b8d-8252-472867818b71",
          "instanceId": 11589,
          "authEntityId": 11589,
          "displayName": "11589",
          "authEntityClass": -217092956,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "appProtocol": "tcp/udp",
          "applicationGroup": "other",
          "applicationType": "DEFAULT",
          "categoryId": "6daa3dfd-3526-407a-88f0-97824b3f3a24",
          "createTime": 1517966743183,
          "encrypted": "false",
          "engineId": "3",
          "helpString": "TIA/EIA/IS-99 modem client",
          "ignoreConflict": false,
          "lastUpdateTime": 1517966743183,
          "longDescription": "TIA/EIA/IS-99 modem client  is a data services option standard for wideband spread spectrum digital cellular systems.",
          "name": "is99c",
          "nbarId": "295",
          "p2pTechnology": "false",
          "popularity": 1,
          "rank": 65535,
          "references": "http://www.tiaonline.org/standards/technology/cdma2000/documents/TIA-EIA-IS-707-A.pdf",
          "selectorId": "379",
          "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
          "trafficClass": "BULK_DATA",
          "tunnel": "false"
        }
      ],
      "networkIdentity": [
        {
          "id": "93573587-80a5-4979-b3ca-37376e40ff7b",
          "instanceId": 13600,
          "authEntityId": 13600,
          "displayName": "13600",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "379",
          "protocol": "UDP",
          "upperPort": 0
        },
        {
          "id": "1803330a-8a36-476b-bb11-f502afb46204",
          "instanceId": 13599,
          "authEntityId": 13599,
          "displayName": "13599",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "379",
          "protocol": "TCP",
          "upperPort": 0
        }
      ],
      "parentScalableGroup": {
        "idRef": "000c15bf-28fe-4fbb-9b69-35e6502721f1"
      },
      "user": [

      ],
      "userGroup": [

      ]
    },
    {
      "id": "c6e65ac9-facf-46f9-af1d-ea6b56bf1b54",
      "instanceId": 4341,
      "authEntityId": 4341,
      "displayName": "4341",
      "authEntityClass": -1909073334,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "createTime": 1517966742340,
      "deployed": false,
      "isSeeded": true,
      "isStale": false,
      "lastUpdateTime": 1517966742340,
      "name": "ddp",
      "namespace": "scalablegroup:application",
      "provisioningState": "DEFINED",
      "qualifier": "application",
      "resourceVersion": 0,
      "targetIdList": [

      ],
      "type": "scalablegroup",
      "cfsChangeInfo": [

      ],
      "customProvisions": [

      ],
      "scalableGroupExternalHandle": "ddp",
      "scalableGroupType": "APPLICATION",
      "securityGroupTag": 0,
      "state": "ACTIVE",
      "vnAgnostic": true,
      "identitySource": {
        "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
        "instanceId": 10010,
        "authEntityId": 10010,
        "displayName": "10010",
        "authEntityClass": 1738953278,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "state": "INACTIVE",
        "type": "NBAR"
      },
      "indicativeNetworkIdentity": [
        {
          "id": "286a2821-aeb9-40f3-93ff-1e35ee700726",
          "instanceId": 12799,
          "authEntityId": 12799,
          "displayName": "12799",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipProtocolNumbers": "37",
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "protocol": "DDP",
          "upperPort": 0
        }
      ],
      "networkApplications": [
        {
          "id": "8e594532-56ec-45c0-8f00-261cf97772b9",
          "instanceId": 11292,
          "authEntityId": 11292,
          "displayName": "11292",
          "authEntityClass": -217092956,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "appProtocol": "ip",
          "applicationGroup": "other",
          "applicationType": "DEFAULT",
          "categoryId": "6daa3dfd-3526-407a-88f0-97824b3f3a24",
          "createTime": 1517966742340,
          "encrypted": "unassigned",
          "engineId": "1",
          "helpString": "Datagram Delivery Protocol",
          "ignoreConflict": false,
          "lastUpdateTime": 1517966742340,
          "longDescription": "Datagram Delivery Protocol (DDP) is a member of the AppleTalk networking protocol suite. It is the network-layer protocol that is responsible for the socket-to-socket delivery of datagrams over the AppleTalk Internet.",
          "name": "ddp",
          "nbarId": "791",
          "p2pTechnology": "unassigned",
          "popularity": 2,
          "rank": 65535,
          "references": "http://www.ietf.org/rfc/rfc1742.txt",
          "selectorId": "37",
          "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
          "trafficClass": "BULK_DATA",
          "tunnel": "unassigned"
        }
      ],
      "networkIdentity": [
        {
          "id": "e45d5888-3ed3-4f95-b137-2132dedfdf23",
          "instanceId": 12798,
          "authEntityId": 12798,
          "displayName": "12798",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipProtocolNumbers": "37",
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "protocol": "DDP",
          "upperPort": 0
        }
      ],
      "parentScalableGroup": {
        "idRef": "000c15bf-28fe-4fbb-9b69-35e6502721f1"
      },
      "user": [

      ],
      "userGroup": [

      ]
    },
    {
      "id": "c71579e5-7937-4b36-a253-665bb6eab726",
      "instanceId": 4905,
      "authEntityId": 4905,
      "displayName": "4905",
      "authEntityClass": -1909073334,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "createTime": 1517966743723,
      "deployed": false,
      "isSeeded": true,
      "isStale": false,
      "lastUpdateTime": 1517966743723,
      "name": "omginitialrefs",
      "namespace": "scalablegroup:application",
      "provisioningState": "DEFINED",
      "qualifier": "application",
      "resourceVersion": 0,
      "targetIdList": [

      ],
      "type": "scalablegroup",
      "cfsChangeInfo": [

      ],
      "customProvisions": [

      ],
      "scalableGroupExternalHandle": "omginitialrefs",
      "scalableGroupType": "APPLICATION",
      "securityGroupTag": 0,
      "state": "ACTIVE",
      "vnAgnostic": true,
      "identitySource": {
        "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
        "instanceId": 10010,
        "authEntityId": 10010,
        "displayName": "10010",
        "authEntityClass": 1738953278,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "state": "INACTIVE",
        "type": "NBAR"
      },
      "indicativeNetworkIdentity": [
        {
          "id": "e02f7b66-5ba2-486c-8bec-a206427c70b0",
          "instanceId": 14372,
          "authEntityId": 14372,
          "displayName": "14372",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "900",
          "protocol": "UDP",
          "upperPort": 0
        },
        {
          "id": "4625ec2a-e2f9-423d-881f-2f9bd4c48758",
          "instanceId": 14373,
          "authEntityId": 14373,
          "displayName": "14373",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "900",
          "protocol": "TCP",
          "upperPort": 0
        }
      ],
      "networkApplications": [
        {
          "id": "30449e22-9e18-4f48-96d6-c2e37597aec1",
          "instanceId": 11854,
          "authEntityId": 11854,
          "displayName": "11854",
          "authEntityClass": -217092956,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "appProtocol": "tcp/udp",
          "applicationGroup": "other",
          "applicationType": "DEFAULT",
          "categoryId": "6daa3dfd-3526-407a-88f0-97824b3f3a24",
          "createTime": 1517966743723,
          "encrypted": "false",
          "engineId": "3",
          "helpString": "omginitialrefs",
          "ignoreConflict": false,
          "lastUpdateTime": 1517966743723,
          "longDescription": "Registered with IANA on port 900 TCP/UDP",
          "name": "omginitialrefs",
          "nbarId": "663",
          "p2pTechnology": "false",
          "popularity": 1,
          "rank": 65535,
          "references": "http://www.iana.org/assignments/service-names-port-numbers/service-names-port-numbers.xml",
          "selectorId": "900",
          "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
          "trafficClass": "BULK_DATA",
          "tunnel": "false"
        }
      ],
      "networkIdentity": [
        {
          "id": "fdc04c88-5f7d-466e-8e2d-bfcbad26163c",
          "instanceId": 14370,
          "authEntityId": 14370,
          "displayName": "14370",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "900",
          "protocol": "UDP",
          "upperPort": 0
        },
        {
          "id": "07c59966-d0a2-4da8-a2b1-563762ed0aa9",
          "instanceId": 14371,
          "authEntityId": 14371,
          "displayName": "14371",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "900",
          "protocol": "TCP",
          "upperPort": 0
        }
      ],
      "parentScalableGroup": {
        "idRef": "000c15bf-28fe-4fbb-9b69-35e6502721f1"
      },
      "user": [

      ],
      "userGroup": [

      ]
    },
    {
      "id": "c728bddb-8389-4d5e-bedb-ebfe38adc838",
      "instanceId": 15317,
      "authEntityId": 15317,
      "displayName": "15317",
      "authEntityClass": -1909073334,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "createTime": 1517966744728,
      "deployed": false,
      "isSeeded": true,
      "isStale": false,
      "lastUpdateTime": 1517966744728,
      "name": "uis",
      "namespace": "scalablegroup:application",
      "provisioningState": "DEFINED",
      "qualifier": "application",
      "resourceVersion": 0,
      "targetIdList": [

      ],
      "type": "scalablegroup",
      "cfsChangeInfo": [

      ],
      "customProvisions": [

      ],
      "scalableGroupExternalHandle": "uis",
      "scalableGroupType": "APPLICATION",
      "securityGroupTag": 0,
      "state": "ACTIVE",
      "vnAgnostic": true,
      "identitySource": {
        "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
        "instanceId": 10010,
        "authEntityId": 10010,
        "displayName": "10010",
        "authEntityClass": 1738953278,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "state": "INACTIVE",
        "type": "NBAR"
      },
      "indicativeNetworkIdentity": [
        {
          "id": "05889cd4-bf2c-47e0-9576-577794793691",
          "instanceId": 17569,
          "authEntityId": 17569,
          "displayName": "17569",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "390",
          "protocol": "UDP",
          "upperPort": 0
        },
        {
          "id": "5802c059-a0ef-43d1-9daf-dfa4f127cae8",
          "instanceId": 17570,
          "authEntityId": 17570,
          "displayName": "17570",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "390",
          "protocol": "TCP",
          "upperPort": 0
        }
      ],
      "networkApplications": [
        {
          "id": "b8820101-032e-4795-8743-5b22af6f156e",
          "instanceId": 16260,
          "authEntityId": 16260,
          "displayName": "16260",
          "authEntityClass": -217092956,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "appProtocol": "tcp/udp",
          "applicationGroup": "other",
          "applicationType": "DEFAULT",
          "categoryId": "6daa3dfd-3526-407a-88f0-97824b3f3a24",
          "createTime": 1517966744727,
          "encrypted": "false",
          "engineId": "3",
          "helpString": "uis",
          "ignoreConflict": false,
          "lastUpdateTime": 1517966744727,
          "longDescription": "Registered with IANA on port 390 TCP/UDP",
          "name": "uis",
          "nbarId": "306",
          "p2pTechnology": "false",
          "popularity": 1,
          "rank": 65535,
          "references": "http://www.iana.org/assignments/service-names-port-numbers/service-names-port-numbers.xml",
          "selectorId": "390",
          "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
          "trafficClass": "BULK_DATA",
          "tunnel": "false"
        }
      ],
      "networkIdentity": [
        {
          "id": "34ff7358-7a3c-47cb-b3c1-9b87a82bd5ba",
          "instanceId": 17568,
          "authEntityId": 17568,
          "displayName": "17568",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "390",
          "protocol": "UDP",
          "upperPort": 0
        },
        {
          "id": "8816238e-0cca-4561-a98a-ad3274202ac5",
          "instanceId": 17567,
          "authEntityId": 17567,
          "displayName": "17567",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "390",
          "protocol": "TCP",
          "upperPort": 0
        }
      ],
      "parentScalableGroup": {
        "idRef": "000c15bf-28fe-4fbb-9b69-35e6502721f1"
      },
      "user": [

      ],
      "userGroup": [

      ]
    },
    {
      "id": "c7297416-5063-4146-902f-67754424bb29",
      "instanceId": 15192,
      "authEntityId": 15192,
      "displayName": "15192",
      "authEntityClass": -1909073334,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "createTime": 1517966744445,
      "deployed": false,
      "isSeeded": true,
      "isStale": false,
      "lastUpdateTime": 1517966744445,
      "name": "sps",
      "namespace": "scalablegroup:application",
      "provisioningState": "DEFINED",
      "qualifier": "application",
      "resourceVersion": 0,
      "targetIdList": [

      ],
      "type": "scalablegroup",
      "cfsChangeInfo": [

      ],
      "customProvisions": [

      ],
      "scalableGroupExternalHandle": "sps",
      "scalableGroupType": "APPLICATION",
      "securityGroupTag": 0,
      "state": "ACTIVE",
      "vnAgnostic": true,
      "identitySource": {
        "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
        "instanceId": 10010,
        "authEntityId": 10010,
        "displayName": "10010",
        "authEntityClass": 1738953278,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "state": "INACTIVE",
        "type": "NBAR"
      },
      "indicativeNetworkIdentity": [
        {
          "id": "fc4259d3-3bf5-4d28-9d1e-603d78c36773",
          "instanceId": 17213,
          "authEntityId": 17213,
          "displayName": "17213",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipProtocolNumbers": "130",
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "protocol": "SPS",
          "upperPort": 0
        }
      ],
      "networkApplications": [
        {
          "id": "dd7e93c0-bc70-4900-b99e-df1bc3715299",
          "instanceId": 16135,
          "authEntityId": 16135,
          "displayName": "16135",
          "authEntityClass": -217092956,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "appProtocol": "ip",
          "applicationGroup": "other",
          "applicationType": "DEFAULT",
          "categoryId": "6daa3dfd-3526-407a-88f0-97824b3f3a24",
          "createTime": 1517966744445,
          "encrypted": "false",
          "engineId": "1",
          "helpString": "Secure Packet Shield",
          "ignoreConflict": false,
          "lastUpdateTime": 1517966744445,
          "longDescription": "Secure Packet Shield (SPS) is an internet security protocol designed in 1998 for use in Fortress Technologies products as an alternative to IPSEC.",
          "name": "sps",
          "nbarId": "1228",
          "p2pTechnology": "false",
          "popularity": 2,
          "rank": 65535,
          "references": "http://www.thefreelibrary.com/Secure+Packet+Shield+Technology+Provides+More+Robust+Alternative+to...-a050265191",
          "selectorId": "130",
          "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
          "trafficClass": "BULK_DATA",
          "tunnel": "false"
        }
      ],
      "networkIdentity": [
        {
          "id": "d54ebe6e-079f-4a4f-b6cc-7822000e2a88",
          "instanceId": 17212,
          "authEntityId": 17212,
          "displayName": "17212",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipProtocolNumbers": "130",
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "protocol": "SPS",
          "upperPort": 0
        }
      ],
      "parentScalableGroup": {
        "idRef": "000c15bf-28fe-4fbb-9b69-35e6502721f1"
      },
      "user": [

      ],
      "userGroup": [

      ]
    },
    {
      "id": "c7bd82ea-d5ba-4b8b-82e3-5c89c01412c1",
      "instanceId": 4415,
      "authEntityId": 4415,
      "displayName": "4415",
      "authEntityClass": -1909073334,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "createTime": 1517966742579,
      "deployed": false,
      "isSeeded": true,
      "isStale": false,
      "lastUpdateTime": 1517966742579,
      "name": "entrust-aams",
      "namespace": "scalablegroup:application",
      "provisioningState": "DEFINED",
      "qualifier": "application",
      "resourceVersion": 0,
      "targetIdList": [

      ],
      "type": "scalablegroup",
      "cfsChangeInfo": [

      ],
      "customProvisions": [

      ],
      "scalableGroupExternalHandle": "entrust-aams",
      "scalableGroupType": "APPLICATION",
      "securityGroupTag": 0,
      "state": "ACTIVE",
      "vnAgnostic": true,
      "identitySource": {
        "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
        "instanceId": 10010,
        "authEntityId": 10010,
        "displayName": "10010",
        "authEntityClass": 1738953278,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "state": "INACTIVE",
        "type": "NBAR"
      },
      "indicativeNetworkIdentity": [
        {
          "id": "4c571485-33d9-49f0-82f2-c7a5a8f40859",
          "instanceId": 13038,
          "authEntityId": 13038,
          "displayName": "13038",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "681",
          "protocol": "TCP",
          "upperPort": 0
        },
        {
          "id": "a0933b9f-9a3c-4513-be15-07f83105ba7f",
          "instanceId": 13039,
          "authEntityId": 13039,
          "displayName": "13039",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "681",
          "protocol": "UDP",
          "upperPort": 0
        }
      ],
      "networkApplications": [
        {
          "id": "55eefa18-6c15-459b-b1be-ab103b452b0c",
          "instanceId": 11366,
          "authEntityId": 11366,
          "displayName": "11366",
          "authEntityClass": -217092956,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "appProtocol": "tcp/udp",
          "applicationGroup": "other",
          "applicationType": "DEFAULT",
          "categoryId": "1fc17362-9334-4c7b-b752-5afdbb574017",
          "createTime": 1517966742579,
          "encrypted": "false",
          "engineId": "3",
          "helpString": "entrust-aams",
          "ignoreConflict": false,
          "lastUpdateTime": 1517966742579,
          "longDescription": "Registered with IANA on port 681 TCP/UDP",
          "name": "entrust-aams",
          "nbarId": "589",
          "p2pTechnology": "false",
          "popularity": 1,
          "rank": 65535,
          "references": "http://www.iana.org/assignments/service-names-port-numbers/service-names-port-numbers.xml",
          "selectorId": "681",
          "subCategory": "e390124a-0fd6-4253-b68e-4b0c1af8cb4b",
          "trafficClass": "OPS_ADMIN_MGMT",
          "tunnel": "false"
        }
      ],
      "networkIdentity": [
        {
          "id": "79227741-2184-498c-902d-8bb09aacd00e",
          "instanceId": 13036,
          "authEntityId": 13036,
          "displayName": "13036",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "681",
          "protocol": "UDP",
          "upperPort": 0
        },
        {
          "id": "70b9f0e5-b227-45ff-bac9-3975fd8e0cde",
          "instanceId": 13037,
          "authEntityId": 13037,
          "displayName": "13037",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "681",
          "protocol": "TCP",
          "upperPort": 0
        }
      ],
      "parentScalableGroup": {
        "idRef": "7788bf2d-e289-4597-b500-17aacb7e820e"
      },
      "user": [

      ],
      "userGroup": [

      ]
    },
    {
      "id": "c7eeb341-16f7-4b4b-81fd-ad7b2bb7a8c0",
      "instanceId": 4848,
      "authEntityId": 4848,
      "displayName": "4848",
      "authEntityClass": -1909073334,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "createTime": 1517966743609,
      "deployed": false,
      "isSeeded": true,
      "isStale": false,
      "lastUpdateTime": 1517966743609,
      "name": "netrjs-4",
      "namespace": "scalablegroup:application",
      "provisioningState": "DEFINED",
      "qualifier": "application",
      "resourceVersion": 0,
      "targetIdList": [

      ],
      "type": "scalablegroup",
      "cfsChangeInfo": [

      ],
      "customProvisions": [

      ],
      "scalableGroupExternalHandle": "netrjs-4",
      "scalableGroupType": "APPLICATION",
      "securityGroupTag": 0,
      "state": "ACTIVE",
      "vnAgnostic": true,
      "identitySource": {
        "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
        "instanceId": 10010,
        "authEntityId": 10010,
        "displayName": "10010",
        "authEntityClass": 1738953278,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "state": "INACTIVE",
        "type": "NBAR"
      },
      "indicativeNetworkIdentity": [
        {
          "id": "e07550be-fe80-4578-a373-8b7a9080312e",
          "instanceId": 14178,
          "authEntityId": 14178,
          "displayName": "14178",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "74",
          "protocol": "UDP",
          "upperPort": 0
        },
        {
          "id": "7a80df40-d1e7-4688-a543-3d8bbc573af2",
          "instanceId": 14179,
          "authEntityId": 14179,
          "displayName": "14179",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "74",
          "protocol": "TCP",
          "upperPort": 0
        }
      ],
      "networkApplications": [
        {
          "id": "bf2351da-501e-4e2b-848e-b9e099bbc22b",
          "instanceId": 11797,
          "authEntityId": 11797,
          "displayName": "11797",
          "authEntityClass": -217092956,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "appProtocol": "tcp/udp",
          "applicationGroup": "other",
          "applicationType": "DEFAULT",
          "categoryId": "1fc17362-9334-4c7b-b752-5afdbb574017",
          "createTime": 1517966743609,
          "encrypted": "false",
          "engineId": "3",
          "helpString": "Remote Job Service",
          "ignoreConflict": false,
          "lastUpdateTime": 1517966743609,
          "longDescription": "NETRJS is a private protocol for remote job entry service, was defined and implemented by the UCLA Campus Computing Network (CCN). CCN's NETRJS server allows a remote user, or a daemon process working in behalf of a user, to access CCN's Remote Job Service (RJS) subsystem.",
          "name": "netrjs-4",
          "nbarId": "951",
          "p2pTechnology": "false",
          "popularity": 1,
          "rank": 65535,
          "references": "http://tools.ietf.org/html/rfc740",
          "selectorId": "74",
          "subCategory": "f3c0f761-d894-4112-ab89-b90fc84fd660",
          "trafficClass": "OPS_ADMIN_MGMT",
          "tunnel": "false"
        }
      ],
      "networkIdentity": [
        {
          "id": "47e42fcc-63d1-4052-b950-0b81f97d4b19",
          "instanceId": 14176,
          "authEntityId": 14176,
          "displayName": "14176",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "74",
          "protocol": "UDP",
          "upperPort": 0
        },
        {
          "id": "7a4cd858-1c85-49e3-9215-d8e312a706d1",
          "instanceId": 14177,
          "authEntityId": 14177,
          "displayName": "14177",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "74",
          "protocol": "TCP",
          "upperPort": 0
        }
      ],
      "parentScalableGroup": {
        "idRef": "5fcd74d4-8726-47f7-a088-c457863da7ef"
      },
      "user": [

      ],
      "userGroup": [

      ]
    },
    {
      "id": "c7f5cab4-c2f9-43f0-8510-e33ac2a44e52",
      "instanceId": 4567,
      "authEntityId": 4567,
      "displayName": "4567",
      "authEntityClass": -1909073334,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "createTime": 1517966743005,
      "deployed": false,
      "isSeeded": true,
      "isStale": false,
      "lastUpdateTime": 1517966743005,
      "name": "hyper-g",
      "namespace": "scalablegroup:application",
      "provisioningState": "DEFINED",
      "qualifier": "application",
      "resourceVersion": 0,
      "targetIdList": [

      ],
      "type": "scalablegroup",
      "cfsChangeInfo": [

      ],
      "customProvisions": [

      ],
      "scalableGroupExternalHandle": "hyper-g",
      "scalableGroupType": "APPLICATION",
      "securityGroupTag": 0,
      "state": "ACTIVE",
      "vnAgnostic": true,
      "identitySource": {
        "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
        "instanceId": 10010,
        "authEntityId": 10010,
        "displayName": "10010",
        "authEntityClass": 1738953278,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "state": "INACTIVE",
        "type": "NBAR"
      },
      "indicativeNetworkIdentity": [
        {
          "id": "703f17ba-ca44-43b1-a327-06f3948273fa",
          "instanceId": 13413,
          "authEntityId": 13413,
          "displayName": "13413",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "418",
          "protocol": "TCP",
          "upperPort": 0
        },
        {
          "id": "983e0258-c750-4764-a6da-b3e6acebee42",
          "instanceId": 13414,
          "authEntityId": 13414,
          "displayName": "13414",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "418",
          "protocol": "UDP",
          "upperPort": 0
        }
      ],
      "networkApplications": [
        {
          "id": "27857a18-f9d6-4074-9fbd-c3d22b8a89c4",
          "instanceId": 11517,
          "authEntityId": 11517,
          "displayName": "11517",
          "authEntityClass": -217092956,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "appProtocol": "tcp/udp",
          "applicationGroup": "other",
          "applicationType": "DEFAULT",
          "categoryId": "6daa3dfd-3526-407a-88f0-97824b3f3a24",
          "createTime": 1517966743005,
          "encrypted": "false",
          "engineId": "3",
          "helpString": "hyper-g",
          "ignoreConflict": false,
          "lastUpdateTime": 1517966743005,
          "longDescription": "Hyper-G is a multi-user, multi-protocol, structured hypermedia information system. It runs as a client-server application on the Internet.",
          "name": "hyper-g",
          "nbarId": "333",
          "p2pTechnology": "false",
          "popularity": 1,
          "rank": 65535,
          "references": "http://www.jucs.org/jucs_1_4/the_hyper_g_network/Andrews_K.pdf",
          "selectorId": "418",
          "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
          "trafficClass": "BULK_DATA",
          "tunnel": "false"
        }
      ],
      "networkIdentity": [
        {
          "id": "54f41494-befe-4410-97d2-4e60a0f93df0",
          "instanceId": 13411,
          "authEntityId": 13411,
          "displayName": "13411",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "418",
          "protocol": "UDP",
          "upperPort": 0
        },
        {
          "id": "96f896f0-49a1-4d7f-b818-4e53e1eebc82",
          "instanceId": 13412,
          "authEntityId": 13412,
          "displayName": "13412",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "418",
          "protocol": "TCP",
          "upperPort": 0
        }
      ],
      "parentScalableGroup": {
        "idRef": "000c15bf-28fe-4fbb-9b69-35e6502721f1"
      },
      "user": [

      ],
      "userGroup": [

      ]
    },
    {
      "id": "c8a06ffa-a338-414c-802d-81fa73bdab70",
      "instanceId": 4832,
      "authEntityId": 4832,
      "displayName": "4832",
      "authEntityClass": -1909073334,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "createTime": 1517966743578,
      "deployed": false,
      "isSeeded": true,
      "isStale": false,
      "lastUpdateTime": 1517966743578,
      "name": "net8-cman",
      "namespace": "scalablegroup:application",
      "provisioningState": "DEFINED",
      "qualifier": "application",
      "resourceVersion": 0,
      "targetIdList": [

      ],
      "type": "scalablegroup",
      "cfsChangeInfo": [

      ],
      "customProvisions": [

      ],
      "scalableGroupExternalHandle": "net8-cman",
      "scalableGroupType": "APPLICATION",
      "securityGroupTag": 0,
      "state": "ACTIVE",
      "vnAgnostic": true,
      "identitySource": {
        "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
        "instanceId": 10010,
        "authEntityId": 10010,
        "displayName": "10010",
        "authEntityClass": 1738953278,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "state": "INACTIVE",
        "type": "NBAR"
      },
      "indicativeNetworkIdentity": [
        {
          "id": "89f733f3-9ccd-43bc-9407-645fae64d764",
          "instanceId": 14130,
          "authEntityId": 14130,
          "displayName": "14130",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "1830",
          "protocol": "UDP",
          "upperPort": 0
        },
        {
          "id": "2ec3bac2-110c-4644-b051-7b3686dd43e7",
          "instanceId": 14131,
          "authEntityId": 14131,
          "displayName": "14131",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "1830",
          "protocol": "TCP",
          "upperPort": 0
        }
      ],
      "networkApplications": [
        {
          "id": "de5627a5-5cb9-43d1-84a0-1aa03398d76e",
          "instanceId": 11781,
          "authEntityId": 11781,
          "displayName": "11781",
          "authEntityClass": -217092956,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "appProtocol": "tcp/udp",
          "applicationGroup": "sqlsvr-group",
          "applicationType": "DEFAULT",
          "categoryId": "cb5915d1-afec-4c30-ac9e-069ff382272f",
          "createTime": 1517966743578,
          "encrypted": "false",
          "engineId": "3",
          "helpString": "Oracle Net8 CMan Admin",
          "ignoreConflict": false,
          "lastUpdateTime": 1517966743578,
          "longDescription": "Oracle Net8 CMan Admin refers to general administrative commands to Oracle Connection Manager.",
          "name": "net8-cman",
          "nbarId": "697",
          "p2pTechnology": "false",
          "popularity": 1,
          "rank": 65535,
          "references": "http://docs.oracle.com/cd/A64702_01/doc/network.805/a58230/toc.htm",
          "selectorId": "1830",
          "subCategory": "b90786f4-0190-45b8-9924-21b3e9682590",
          "trafficClass": "TRANSACTIONAL_DATA",
          "tunnel": "false"
        }
      ],
      "networkIdentity": [
        {
          "id": "9ee5f61c-db77-4041-89d5-baf95f08affe",
          "instanceId": 14128,
          "authEntityId": 14128,
          "displayName": "14128",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "1830",
          "protocol": "UDP",
          "upperPort": 0
        },
        {
          "id": "aeb1cef6-aeb7-4fc8-96c8-3a64e7a6dc0b",
          "instanceId": 14129,
          "authEntityId": 14129,
          "displayName": "14129",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "1830",
          "protocol": "TCP",
          "upperPort": 0
        }
      ],
      "parentScalableGroup": {
        "idRef": "8718395e-683e-4a2d-a232-100202751271"
      },
      "user": [

      ],
      "userGroup": [

      ]
    },
    {
      "id": "c8f97bad-3b0c-4124-9fca-148a1b14d61d",
      "instanceId": 15397,
      "authEntityId": 15397,
      "displayName": "15397",
      "authEntityClass": -1909073334,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "createTime": 1517966744871,
      "deployed": false,
      "isSeeded": true,
      "isStale": false,
      "lastUpdateTime": 1517966744871,
      "name": "web-rtc-audio",
      "namespace": "scalablegroup:application",
      "provisioningState": "DEFINED",
      "qualifier": "application",
      "resourceVersion": 0,
      "targetIdList": [

      ],
      "type": "scalablegroup",
      "cfsChangeInfo": [

      ],
      "customProvisions": [

      ],
      "scalableGroupExternalHandle": "web-rtc-audio",
      "scalableGroupType": "APPLICATION",
      "securityGroupTag": 0,
      "state": "ACTIVE",
      "vnAgnostic": true,
      "identitySource": {
        "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
        "instanceId": 10010,
        "authEntityId": 10010,
        "displayName": "10010",
        "authEntityClass": 1738953278,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "state": "INACTIVE",
        "type": "NBAR"
      },
      "indicativeNetworkIdentity": [

      ],
      "networkApplications": [
        {
          "id": "d8dee5b3-88cb-4b93-9149-ffab425b6ced",
          "instanceId": 16340,
          "authEntityId": 16340,
          "displayName": "16340",
          "authEntityClass": -217092956,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "applicationGroup": "other",
          "applicationType": "DEFAULT",
          "categoryId": "38a8e354-e2fe-4f3c-b1c5-2bbb90fb0d99",
          "createTime": 1517966744871,
          "encrypted": "false",
          "engineId": "13",
          "helpString": "web rtc conferencing audio",
          "ignoreConflict": false,
          "lastUpdateTime": 1517966744871,
          "longDescription": "WebRTC provides browsers and mobile applications with Real-Time Communications (RTC) capabilities.",
          "name": "web-rtc-audio",
          "nbarId": "1056",
          "p2pTechnology": "false",
          "popularity": 10,
          "rank": 65535,
          "references": "www.webrtc.org",
          "selectorId": "1767",
          "subCategory": "53ce0342-1e2d-455a-acc7-1b50db9d6845",
          "trafficClass": "MULTIMEDIA_CONFERENCING",
          "tunnel": "false"
        }
      ],
      "networkIdentity": [

      ],
      "parentScalableGroup": {
        "idRef": "aad510ce-fe95-4dfc-ad06-e494641cc016"
      },
      "user": [

      ],
      "userGroup": [

      ]
    },
    {
      "id": "c902bfe1-a679-4e03-87f2-93dd35f5b217",
      "instanceId": 15325,
      "authEntityId": 15325,
      "displayName": "15325",
      "authEntityClass": -1909073334,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "createTime": 1517966744742,
      "deployed": false,
      "isSeeded": true,
      "isStale": false,
      "lastUpdateTime": 1517966744742,
      "name": "unknown",
      "namespace": "scalablegroup:application",
      "provisioningState": "DEFINED",
      "qualifier": "application",
      "resourceVersion": 0,
      "targetIdList": [

      ],
      "type": "scalablegroup",
      "cfsChangeInfo": [

      ],
      "customProvisions": [

      ],
      "scalableGroupExternalHandle": "unknown",
      "scalableGroupType": "APPLICATION",
      "securityGroupTag": 0,
      "state": "ACTIVE",
      "vnAgnostic": true,
      "identitySource": {
        "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
        "instanceId": 10010,
        "authEntityId": 10010,
        "displayName": "10010",
        "authEntityClass": 1738953278,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "state": "INACTIVE",
        "type": "NBAR"
      },
      "indicativeNetworkIdentity": [

      ],
      "networkApplications": [
        {
          "id": "7d38c5e9-137a-4cf3-92cf-e371a9883f9a",
          "instanceId": 16268,
          "authEntityId": 16268,
          "displayName": "16268",
          "authEntityClass": -217092956,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "applicationGroup": "other",
          "applicationType": "DEFAULT",
          "categoryId": "6daa3dfd-3526-407a-88f0-97824b3f3a24",
          "createTime": 1517966744742,
          "encrypted": "false",
          "engineId": "13",
          "helpString": "Traffic for which the classification is unknown",
          "ignoreConflict": false,
          "lastUpdateTime": 1517966744742,
          "longDescription": "unknown refers to traffic that did not get classified by the NBAR protocol library",
          "name": "unknown",
          "nbarId": "1",
          "p2pTechnology": "false",
          "popularity": 8,
          "rank": 65535,
          "references": "N/A",
          "selectorId": "1",
          "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
          "trafficClass": "BULK_DATA",
          "tunnel": "false"
        }
      ],
      "networkIdentity": [

      ],
      "parentScalableGroup": {
        "idRef": "000c15bf-28fe-4fbb-9b69-35e6502721f1"
      },
      "user": [

      ],
      "userGroup": [

      ]
    },
    {
      "id": "c90d3f7c-f7dc-4b93-9f1d-a79ddb3b70b6",
      "instanceId": 4441,
      "authEntityId": 4441,
      "displayName": "4441",
      "authEntityClass": -1909073334,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "createTime": 1517966742667,
      "deployed": false,
      "isSeeded": true,
      "isStale": false,
      "lastUpdateTime": 1517966742667,
      "name": "fasttrack-static",
      "namespace": "scalablegroup:application",
      "provisioningState": "DEFINED",
      "qualifier": "application",
      "resourceVersion": 0,
      "targetIdList": [

      ],
      "type": "scalablegroup",
      "cfsChangeInfo": [

      ],
      "customProvisions": [

      ],
      "scalableGroupExternalHandle": "fasttrack-static",
      "scalableGroupType": "APPLICATION",
      "securityGroupTag": 0,
      "state": "ACTIVE",
      "vnAgnostic": true,
      "identitySource": {
        "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
        "instanceId": 10010,
        "authEntityId": 10010,
        "displayName": "10010",
        "authEntityClass": 1738953278,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "state": "INACTIVE",
        "type": "NBAR"
      },
      "indicativeNetworkIdentity": [
        {
          "id": "66b40d5f-c96c-4272-b578-fe7d3e32291d",
          "instanceId": 13100,
          "authEntityId": 13100,
          "displayName": "13100",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "1214",
          "protocol": "TCP",
          "upperPort": 0
        },
        {
          "id": "8e9ea005-77fd-4fc8-b22d-ebad5e8f787b",
          "instanceId": 13101,
          "authEntityId": 13101,
          "displayName": "13101",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "1214",
          "protocol": "UDP",
          "upperPort": 0
        }
      ],
      "networkApplications": [
        {
          "id": "32af1928-7ccd-4670-89e7-aef205ad1025",
          "instanceId": 11392,
          "authEntityId": 11392,
          "displayName": "11392",
          "authEntityClass": -217092956,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "appProtocol": "tcp/udp",
          "applicationGroup": "fasttrack-group",
          "applicationType": "DEFAULT",
          "categoryId": "b33b2761-d981-4734-810a-a6a1c18ab352",
          "createTime": 1517966742667,
          "encrypted": "false",
          "engineId": "13",
          "helpString": "FastTrack Traffic - KaZaA, Morpheus, Grokster...",
          "ignoreConflict": false,
          "lastUpdateTime": 1517966742667,
          "longDescription": "FastTrack is a peer-to-peer (P2P) protocol that was used by the Kazaa, Grokster, iMesh, and Morpheus file sharing programs. It is a file sharing network used mainly for the exchange of music mp3 files. FastTrack uses supernodes to improve scalability, and UUHash hashing algorithm to allow downloading from multiple sources.",
          "name": "fasttrack-static",
          "nbarId": "1322",
          "p2pTechnology": "true",
          "popularity": 6,
          "rank": 65535,
          "references": "http://en.wikipedia.org/wiki/FastTrack",
          "selectorId": "467",
          "subCategory": "61b315f5-5e87-4de0-bc1a-5d084ea0b28d",
          "trafficClass": "BULK_DATA",
          "tunnel": "false"
        }
      ],
      "networkIdentity": [
        {
          "id": "9dbd5168-a575-46fe-98ee-3cddb4c35e92",
          "instanceId": 13098,
          "authEntityId": 13098,
          "displayName": "13098",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "1214",
          "protocol": "TCP",
          "upperPort": 0
        },
        {
          "id": "c983c149-e7af-404c-94f7-154c971df934",
          "instanceId": 13099,
          "authEntityId": 13099,
          "displayName": "13099",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "1214",
          "protocol": "UDP",
          "upperPort": 0
        }
      ],
      "parentScalableGroup": {
        "idRef": "7a1a3bbb-dc84-4a35-82a4-7e650c4c4761"
      },
      "user": [

      ],
      "userGroup": [

      ]
    },
    {
      "id": "c94ee072-a0fc-41df-83e0-b9dd6ed6dabf",
      "instanceId": 4696,
      "authEntityId": 4696,
      "displayName": "4696",
      "authEntityClass": -1909073334,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "createTime": 1517966743303,
      "deployed": false,
      "isSeeded": true,
      "isStale": false,
      "lastUpdateTime": 1517966743303,
      "name": "livedoor",
      "namespace": "scalablegroup:application",
      "provisioningState": "DEFINED",
      "qualifier": "application",
      "resourceVersion": 0,
      "targetIdList": [

      ],
      "type": "scalablegroup",
      "cfsChangeInfo": [

      ],
      "customProvisions": [

      ],
      "scalableGroupExternalHandle": "livedoor",
      "scalableGroupType": "APPLICATION",
      "securityGroupTag": 0,
      "state": "ACTIVE",
      "vnAgnostic": true,
      "identitySource": {
        "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
        "instanceId": 10010,
        "authEntityId": 10010,
        "displayName": "10010",
        "authEntityClass": 1738953278,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "state": "INACTIVE",
        "type": "NBAR"
      },
      "indicativeNetworkIdentity": [

      ],
      "networkApplications": [
        {
          "id": "8d24c54e-e1db-44bc-a337-329177f06d8f",
          "instanceId": 11646,
          "authEntityId": 11646,
          "displayName": "11646",
          "authEntityClass": -217092956,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "appProtocol": "tcp/udp",
          "applicationGroup": "other",
          "applicationType": "DEFAULT",
          "categoryId": "878644b5-fe91-45b4-9204-31c84a73ba48",
          "createTime": 1517966743303,
          "encrypted": "false",
          "engineId": "13",
          "helpString": "Livedoor - Japanese Internet service provider.",
          "ignoreConflict": false,
          "lastUpdateTime": 1517966743303,
          "longDescription": "Livedoor - Japanese Internet service provider.",
          "name": "livedoor",
          "nbarId": "1560",
          "p2pTechnology": "false",
          "popularity": 3,
          "rank": 65535,
          "references": "http://livedoor.com",
          "selectorId": "631",
          "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
          "trafficClass": "TRANSACTIONAL_DATA",
          "tunnel": "false"
        }
      ],
      "networkIdentity": [
        {
          "id": "89094f23-d9be-4289-abf2-38cd8c6ef149",
          "instanceId": 13760,
          "authEntityId": 13760,
          "displayName": "13760",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "53,80,443,5353",
          "protocol": "TCP",
          "upperPort": 0
        },
        {
          "id": "0f1111b7-6e2a-4769-8d6a-458718ea4745",
          "instanceId": 13761,
          "authEntityId": 13761,
          "displayName": "13761",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "53,5353",
          "protocol": "UDP",
          "upperPort": 0
        }
      ],
      "parentScalableGroup": {
        "idRef": "28e08de4-d7b2-4521-84ce-bc1e5ee0b9cc"
      },
      "user": [

      ],
      "userGroup": [

      ]
    },
    {
      "id": "c97e1087-d1da-4a47-99d7-1a807e51f9f8",
      "instanceId": 15471,
      "authEntityId": 15471,
      "displayName": "15471",
      "authEntityClass": -1909073334,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "createTime": 1517966745031,
      "deployed": false,
      "isSeeded": true,
      "isStale": false,
      "lastUpdateTime": 1517966745031,
      "name": "zulily",
      "namespace": "scalablegroup:application",
      "provisioningState": "DEFINED",
      "qualifier": "application",
      "resourceVersion": 0,
      "targetIdList": [

      ],
      "type": "scalablegroup",
      "cfsChangeInfo": [

      ],
      "customProvisions": [

      ],
      "scalableGroupExternalHandle": "zulily",
      "scalableGroupType": "APPLICATION",
      "securityGroupTag": 0,
      "state": "ACTIVE",
      "vnAgnostic": true,
      "identitySource": {
        "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
        "instanceId": 10010,
        "authEntityId": 10010,
        "displayName": "10010",
        "authEntityClass": 1738953278,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "state": "INACTIVE",
        "type": "NBAR"
      },
      "indicativeNetworkIdentity": [

      ],
      "networkApplications": [
        {
          "id": "ec8c6cb2-6fb1-4277-b381-17a3b1baa5ca",
          "instanceId": 16414,
          "authEntityId": 16414,
          "displayName": "16414",
          "authEntityClass": -217092956,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "appProtocol": "tcp/udp",
          "applicationGroup": "other",
          "applicationType": "DEFAULT",
          "categoryId": "878644b5-fe91-45b4-9204-31c84a73ba48",
          "createTime": 1517966745031,
          "encrypted": "false",
          "engineId": "13",
          "helpString": "Zulily - Online shopping aimed for Moms apparel and home decor items.",
          "ignoreConflict": false,
          "lastUpdateTime": 1517966745031,
          "longDescription": "Zulily - Online shopping aimed for Moms apparel and home decor items.",
          "name": "zulily",
          "nbarId": "1681",
          "p2pTechnology": "false",
          "popularity": 3,
          "rank": 65535,
          "references": "http://zulily.com",
          "selectorId": "751",
          "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
          "trafficClass": "TRANSACTIONAL_DATA",
          "tunnel": "false"
        }
      ],
      "networkIdentity": [
        {
          "id": "18a32caf-5f69-4b2f-8a53-6760dfd5d5c6",
          "instanceId": 17982,
          "authEntityId": 17982,
          "displayName": "17982",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "53,80,443,5353",
          "protocol": "TCP",
          "upperPort": 0
        },
        {
          "id": "8bda83be-dda2-4f19-88e1-70ebea624679",
          "instanceId": 17983,
          "authEntityId": 17983,
          "displayName": "17983",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "53,5353",
          "protocol": "UDP",
          "upperPort": 0
        }
      ],
      "parentScalableGroup": {
        "idRef": "28e08de4-d7b2-4521-84ce-bc1e5ee0b9cc"
      },
      "user": [

      ],
      "userGroup": [

      ]
    },
    {
      "id": "c98c57cf-b530-492a-b79d-d62aede4a473",
      "instanceId": 4382,
      "authEntityId": 4382,
      "displayName": "4382",
      "authEntityClass": -1909073334,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "createTime": 1517966742477,
      "deployed": false,
      "isSeeded": true,
      "isStale": false,
      "lastUpdateTime": 1517966742477,
      "name": "dnsix",
      "namespace": "scalablegroup:application",
      "provisioningState": "DEFINED",
      "qualifier": "application",
      "resourceVersion": 0,
      "targetIdList": [

      ],
      "type": "scalablegroup",
      "cfsChangeInfo": [

      ],
      "customProvisions": [

      ],
      "scalableGroupExternalHandle": "dnsix",
      "scalableGroupType": "APPLICATION",
      "securityGroupTag": 0,
      "state": "ACTIVE",
      "vnAgnostic": true,
      "identitySource": {
        "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
        "instanceId": 10010,
        "authEntityId": 10010,
        "displayName": "10010",
        "authEntityClass": 1738953278,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "state": "INACTIVE",
        "type": "NBAR"
      },
      "indicativeNetworkIdentity": [
        {
          "id": "804b93d1-081e-4091-be94-2f2ddef07ebb",
          "instanceId": 12940,
          "authEntityId": 12940,
          "displayName": "12940",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "90",
          "protocol": "TCP",
          "upperPort": 0
        },
        {
          "id": "dd8801e4-0663-4bd7-80c2-6f3543622d8e",
          "instanceId": 12941,
          "authEntityId": 12941,
          "displayName": "12941",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "90",
          "protocol": "UDP",
          "upperPort": 0
        }
      ],
      "networkApplications": [
        {
          "id": "5512512b-df7d-4c5d-b5bb-25368a6995c9",
          "instanceId": 11333,
          "authEntityId": 11333,
          "displayName": "11333",
          "authEntityClass": -217092956,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "appProtocol": "tcp/udp",
          "applicationGroup": "other",
          "applicationType": "DEFAULT",
          "categoryId": "1fc17362-9334-4c7b-b752-5afdbb574017",
          "createTime": 1517966742477,
          "encrypted": "false",
          "engineId": "3",
          "helpString": "DNSIX Security Attribute Token Map",
          "ignoreConflict": false,
          "lastUpdateTime": 1517966742477,
          "longDescription": "Registered with IANA on port 90 TCP/UDP",
          "name": "dnsix",
          "nbarId": "961",
          "p2pTechnology": "false",
          "popularity": 1,
          "rank": 65535,
          "references": "http://www.iana.org/assignments/service-names-port-numbers/service-names-port-numbers.xml",
          "selectorId": "90",
          "subCategory": "7f5fa07d-fcb4-4217-9908-b438066c0b60",
          "trafficClass": "OPS_ADMIN_MGMT",
          "tunnel": "false"
        }
      ],
      "networkIdentity": [
        {
          "id": "791c2450-bad2-4467-91c9-065238aec64e",
          "instanceId": 12938,
          "authEntityId": 12938,
          "displayName": "12938",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "90",
          "protocol": "TCP",
          "upperPort": 0
        },
        {
          "id": "d871661a-35c8-472f-b738-bc0d3a3163f7",
          "instanceId": 12939,
          "authEntityId": 12939,
          "displayName": "12939",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "90",
          "protocol": "UDP",
          "upperPort": 0
        }
      ],
      "parentScalableGroup": {
        "idRef": "38b9eb6b-f3b6-4e13-9b79-a11e0c6423d4"
      },
      "user": [

      ],
      "userGroup": [

      ]
    },
    {
      "id": "c9a3c24d-eeae-437b-b2d1-4dfd7e95ea8e",
      "instanceId": 15199,
      "authEntityId": 15199,
      "displayName": "15199",
      "authEntityClass": -1909073334,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "createTime": 1517966744464,
      "deployed": false,
      "isSeeded": true,
      "isStale": false,
      "lastUpdateTime": 1517966744464,
      "name": "sqlsrv",
      "namespace": "scalablegroup:application",
      "provisioningState": "DEFINED",
      "qualifier": "application",
      "resourceVersion": 0,
      "targetIdList": [

      ],
      "type": "scalablegroup",
      "cfsChangeInfo": [

      ],
      "customProvisions": [

      ],
      "scalableGroupExternalHandle": "sqlsrv",
      "scalableGroupType": "APPLICATION",
      "securityGroupTag": 0,
      "state": "ACTIVE",
      "vnAgnostic": true,
      "identitySource": {
        "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
        "instanceId": 10010,
        "authEntityId": 10010,
        "displayName": "10010",
        "authEntityClass": 1738953278,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "state": "INACTIVE",
        "type": "NBAR"
      },
      "indicativeNetworkIdentity": [
        {
          "id": "5200d74a-0627-4f61-a888-63135386441c",
          "instanceId": 17236,
          "authEntityId": 17236,
          "displayName": "17236",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "156",
          "protocol": "UDP",
          "upperPort": 0
        },
        {
          "id": "20337c97-b6df-4f63-9f84-42768f470796",
          "instanceId": 17237,
          "authEntityId": 17237,
          "displayName": "17237",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "156",
          "protocol": "TCP",
          "upperPort": 0
        }
      ],
      "networkApplications": [
        {
          "id": "cbe94324-f3df-48a9-8772-077bface01f1",
          "instanceId": 16142,
          "authEntityId": 16142,
          "displayName": "16142",
          "authEntityClass": -217092956,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "appProtocol": "tcp/udp",
          "applicationGroup": "sqlsvr-group",
          "applicationType": "DEFAULT",
          "categoryId": "cb5915d1-afec-4c30-ac9e-069ff382272f",
          "createTime": 1517966744464,
          "encrypted": "false",
          "engineId": "3",
          "helpString": "SQL Service",
          "ignoreConflict": false,
          "lastUpdateTime": 1517966744464,
          "longDescription": "Registered with IANA on port 156 TCP/UDP",
          "name": "sqlsrv",
          "nbarId": "684",
          "p2pTechnology": "false",
          "popularity": 1,
          "rank": 65535,
          "references": "http://www.iana.org/assignments/service-names-port-numbers/service-names-port-numbers.xml",
          "selectorId": "156",
          "subCategory": "b90786f4-0190-45b8-9924-21b3e9682590",
          "trafficClass": "TRANSACTIONAL_DATA",
          "tunnel": "false"
        }
      ],
      "networkIdentity": [
        {
          "id": "898e1e56-9773-47ac-9983-de4cbce1784d",
          "instanceId": 17234,
          "authEntityId": 17234,
          "displayName": "17234",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "156",
          "protocol": "TCP",
          "upperPort": 0
        },
        {
          "id": "5d6a2344-1110-424b-bd8a-84c713491149",
          "instanceId": 17235,
          "authEntityId": 17235,
          "displayName": "17235",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "156",
          "protocol": "UDP",
          "upperPort": 0
        }
      ],
      "parentScalableGroup": {
        "idRef": "8718395e-683e-4a2d-a232-100202751271"
      },
      "user": [

      ],
      "userGroup": [

      ]
    },
    {
      "id": "c9b9890d-e4a7-4785-8f15-257453b7ffdb",
      "instanceId": 15227,
      "authEntityId": 15227,
      "displayName": "15227",
      "authEntityClass": -1909073334,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "createTime": 1517966744534,
      "deployed": false,
      "isSeeded": true,
      "isStale": false,
      "lastUpdateTime": 1517966744534,
      "name": "submission",
      "namespace": "scalablegroup:application",
      "provisioningState": "DEFINED",
      "qualifier": "application",
      "resourceVersion": 0,
      "targetIdList": [

      ],
      "type": "scalablegroup",
      "cfsChangeInfo": [

      ],
      "customProvisions": [

      ],
      "scalableGroupExternalHandle": "submission",
      "scalableGroupType": "APPLICATION",
      "securityGroupTag": 0,
      "state": "ACTIVE",
      "vnAgnostic": true,
      "identitySource": {
        "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
        "instanceId": 10010,
        "authEntityId": 10010,
        "displayName": "10010",
        "authEntityClass": 1738953278,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "state": "INACTIVE",
        "type": "NBAR"
      },
      "indicativeNetworkIdentity": [
        {
          "id": "ec5b2483-cf83-4afc-a323-766c260596d1",
          "instanceId": 17314,
          "authEntityId": 17314,
          "displayName": "17314",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "587",
          "protocol": "UDP",
          "upperPort": 0
        },
        {
          "id": "8bf8c893-835f-401e-821c-5746e7ae80f4",
          "instanceId": 17315,
          "authEntityId": 17315,
          "displayName": "17315",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "587",
          "protocol": "TCP",
          "upperPort": 0
        }
      ],
      "networkApplications": [
        {
          "id": "5d569cf4-05d2-48fd-8668-d6860fc8a841",
          "instanceId": 16170,
          "authEntityId": 16170,
          "displayName": "16170",
          "authEntityClass": -217092956,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "appProtocol": "tcp/udp",
          "applicationGroup": "smtp-group",
          "applicationType": "DEFAULT",
          "categoryId": "27953c60-b2ff-4e1a-bc52-0187d8295250",
          "createTime": 1517966744534,
          "encrypted": "false",
          "engineId": "3",
          "helpString": "submission",
          "ignoreConflict": false,
          "lastUpdateTime": 1517966744534,
          "longDescription": "Amail submission agent(MSA) is acomputer programorsoftware agentthat receiveselectronic mailmessages from amail user agent(MUA) and cooperates with amail transfer agent(MTA) for delivery of the mail. It uses a variant of theSimple Mail Transfer Protocol(SMTP).",
          "name": "submission",
          "nbarId": "501",
          "p2pTechnology": "false",
          "popularity": 1,
          "rank": 65535,
          "references": "http://en.wikipedia.org/wiki/Mail_submission_agent",
          "selectorId": "587",
          "subCategory": "b90786f4-0190-45b8-9924-21b3e9682590",
          "trafficClass": "BULK_DATA",
          "tunnel": "false"
        }
      ],
      "networkIdentity": [
        {
          "id": "092b1364-0fa7-4eda-8540-a8543e15fde8",
          "instanceId": 17312,
          "authEntityId": 17312,
          "displayName": "17312",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "587",
          "protocol": "TCP",
          "upperPort": 0
        },
        {
          "id": "026961bc-1711-4322-9821-4b11afe15a05",
          "instanceId": 17313,
          "authEntityId": 17313,
          "displayName": "17313",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "587",
          "protocol": "UDP",
          "upperPort": 0
        }
      ],
      "parentScalableGroup": {
        "idRef": "8a7ca43a-32bd-40d2-871a-02220a165cb7"
      },
      "user": [

      ],
      "userGroup": [

      ]
    },
    {
      "id": "c9cda272-1f43-4d33-b72d-2ab8d41fc523",
      "instanceId": 4575,
      "authEntityId": 4575,
      "displayName": "4575",
      "authEntityClass": -1909073334,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "createTime": 1517966743032,
      "deployed": false,
      "isSeeded": true,
      "isStale": false,
      "lastUpdateTime": 1517966743032,
      "name": "ibm-app",
      "namespace": "scalablegroup:application",
      "provisioningState": "DEFINED",
      "qualifier": "application",
      "resourceVersion": 0,
      "targetIdList": [

      ],
      "type": "scalablegroup",
      "cfsChangeInfo": [

      ],
      "customProvisions": [

      ],
      "scalableGroupExternalHandle": "ibm-app",
      "scalableGroupType": "APPLICATION",
      "securityGroupTag": 0,
      "state": "ACTIVE",
      "vnAgnostic": true,
      "identitySource": {
        "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
        "instanceId": 10010,
        "authEntityId": 10010,
        "displayName": "10010",
        "authEntityClass": 1738953278,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "state": "INACTIVE",
        "type": "NBAR"
      },
      "indicativeNetworkIdentity": [
        {
          "id": "9dae051d-a23b-4ed2-b827-41a194e929f3",
          "instanceId": 13441,
          "authEntityId": 13441,
          "displayName": "13441",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "385",
          "protocol": "TCP",
          "upperPort": 0
        },
        {
          "id": "7abeb34e-3c7f-449b-9c40-c26a364d9db2",
          "instanceId": 13442,
          "authEntityId": 13442,
          "displayName": "13442",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "385",
          "protocol": "UDP",
          "upperPort": 0
        }
      ],
      "networkApplications": [
        {
          "id": "7e43a51f-5419-4128-bfb0-a76590e5b4de",
          "instanceId": 11525,
          "authEntityId": 11525,
          "displayName": "11525",
          "authEntityClass": -217092956,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "appProtocol": "tcp/udp",
          "applicationGroup": "other",
          "applicationType": "DEFAULT",
          "categoryId": "6daa3dfd-3526-407a-88f0-97824b3f3a24",
          "createTime": 1517966743032,
          "encrypted": "false",
          "engineId": "3",
          "helpString": "IBM Application",
          "ignoreConflict": false,
          "lastUpdateTime": 1517966743032,
          "longDescription": "Registered with IANA on port 385 TCP/UDP",
          "name": "ibm-app",
          "nbarId": "301",
          "p2pTechnology": "false",
          "popularity": 2,
          "rank": 65535,
          "references": "http://www.iana.org/assignments/service-names-port-numbers/service-names-port-numbers.xml",
          "selectorId": "385",
          "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
          "trafficClass": "BULK_DATA",
          "tunnel": "false"
        }
      ],
      "networkIdentity": [
        {
          "id": "6c2f009a-f296-4b4e-84e8-1ff37db8f949",
          "instanceId": 13440,
          "authEntityId": 13440,
          "displayName": "13440",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "385",
          "protocol": "TCP",
          "upperPort": 0
        },
        {
          "id": "632f3295-c547-4aa4-ba59-f2f8b05ef01e",
          "instanceId": 13439,
          "authEntityId": 13439,
          "displayName": "13439",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "385",
          "protocol": "UDP",
          "upperPort": 0
        }
      ],
      "parentScalableGroup": {
        "idRef": "000c15bf-28fe-4fbb-9b69-35e6502721f1"
      },
      "user": [

      ],
      "userGroup": [

      ]
    },
    {
      "id": "c9e09d5e-2e43-4540-8447-338de2633675",
      "instanceId": 4719,
      "authEntityId": 4719,
      "displayName": "4719",
      "authEntityClass": -1909073334,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "createTime": 1517966743351,
      "deployed": false,
      "isSeeded": true,
      "isStale": false,
      "lastUpdateTime": 1517966743351,
      "name": "manet",
      "namespace": "scalablegroup:application",
      "provisioningState": "DEFINED",
      "qualifier": "application",
      "resourceVersion": 0,
      "targetIdList": [

      ],
      "type": "scalablegroup",
      "cfsChangeInfo": [

      ],
      "customProvisions": [

      ],
      "scalableGroupExternalHandle": "manet",
      "scalableGroupType": "APPLICATION",
      "securityGroupTag": 0,
      "state": "ACTIVE",
      "vnAgnostic": true,
      "identitySource": {
        "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
        "instanceId": 10010,
        "authEntityId": 10010,
        "displayName": "10010",
        "authEntityClass": 1738953278,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "state": "INACTIVE",
        "type": "NBAR"
      },
      "indicativeNetworkIdentity": [
        {
          "id": "88c51938-2e4e-4224-a7a3-96e65cc6da97",
          "instanceId": 13825,
          "authEntityId": 13825,
          "displayName": "13825",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipProtocolNumbers": "138",
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "protocol": "MANET",
          "upperPort": 0
        }
      ],
      "networkApplications": [
        {
          "id": "26aad718-1c71-4b13-bbdc-0d3e7c26ced9",
          "instanceId": 11669,
          "authEntityId": 11669,
          "displayName": "11669",
          "authEntityClass": -217092956,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "appProtocol": "ip",
          "applicationGroup": "other",
          "applicationType": "DEFAULT",
          "categoryId": "6daa3dfd-3526-407a-88f0-97824b3f3a24",
          "createTime": 1517966743351,
          "encrypted": "false",
          "engineId": "1",
          "helpString": "MANET Protocols",
          "ignoreConflict": false,
          "lastUpdateTime": 1517966743351,
          "longDescription": "A set of proocols used for Mobile Ad hoc Networking. With recent performance advancements in computer and wireless communications technologies, advanced mobile wireless computing is expected to see increasingly widespread use and application, much of which will involve the use of the Internet Protocol (IP) suite. The vision of mobile ad hoc networking is to support robust and efficient operation in mobile wireless networks by incorporating routing functionality into mobile nodes.  Such networks are envisioned to have dynamic, sometimes rapidly-changing, random, multihop topologies which are likely composed of relatively bandwidth-constrained wireless links.",
          "name": "manet",
          "nbarId": "1236",
          "p2pTechnology": "false",
          "popularity": 2,
          "rank": 65535,
          "references": "http://www.ietf.org/rfc/rfc2501",
          "selectorId": "138",
          "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
          "trafficClass": "BULK_DATA",
          "tunnel": "false"
        }
      ],
      "networkIdentity": [
        {
          "id": "8e36e350-27e9-43ec-a60e-2b01c4706448",
          "instanceId": 13824,
          "authEntityId": 13824,
          "displayName": "13824",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipProtocolNumbers": "138",
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "protocol": "MANET",
          "upperPort": 0
        }
      ],
      "parentScalableGroup": {
        "idRef": "000c15bf-28fe-4fbb-9b69-35e6502721f1"
      },
      "user": [

      ],
      "userGroup": [

      ]
    },
    {
      "id": "c9e65fc3-115e-4ef6-9836-d94a61c6d268",
      "instanceId": 4165,
      "authEntityId": 4165,
      "displayName": "4165",
      "authEntityClass": -1909073334,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "createTime": 1517966741463,
      "deployed": false,
      "isSeeded": true,
      "isStale": false,
      "lastUpdateTime": 1517966741463,
      "name": "banyan-vip",
      "namespace": "scalablegroup:application",
      "provisioningState": "DEFINED",
      "qualifier": "application",
      "resourceVersion": 0,
      "targetIdList": [

      ],
      "type": "scalablegroup",
      "cfsChangeInfo": [

      ],
      "customProvisions": [

      ],
      "scalableGroupExternalHandle": "banyan-vip",
      "scalableGroupType": "APPLICATION",
      "securityGroupTag": 0,
      "state": "ACTIVE",
      "vnAgnostic": true,
      "identitySource": {
        "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
        "instanceId": 10010,
        "authEntityId": 10010,
        "displayName": "10010",
        "authEntityClass": 1738953278,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "state": "INACTIVE",
        "type": "NBAR"
      },
      "indicativeNetworkIdentity": [
        {
          "id": "2d655a14-ccdd-4aa1-a656-8fb89957132b",
          "instanceId": 12342,
          "authEntityId": 12342,
          "displayName": "12342",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "573",
          "protocol": "UDP",
          "upperPort": 0
        },
        {
          "id": "ccf9803d-8b34-4786-9cf6-4aada0a6a1f7",
          "instanceId": 12343,
          "authEntityId": 12343,
          "displayName": "12343",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "573",
          "protocol": "TCP",
          "upperPort": 0
        }
      ],
      "networkApplications": [
        {
          "id": "fe59e987-50fa-4713-8fa9-06b62530b9c6",
          "instanceId": 11124,
          "authEntityId": 11124,
          "displayName": "11124",
          "authEntityClass": -217092956,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "appProtocol": "tcp/udp",
          "applicationGroup": "banyan-group",
          "applicationType": "DEFAULT",
          "categoryId": "6daa3dfd-3526-407a-88f0-97824b3f3a24",
          "createTime": 1517966741463,
          "encrypted": "false",
          "engineId": "3",
          "helpString": "banyan-vip",
          "ignoreConflict": false,
          "lastUpdateTime": 1517966741463,
          "longDescription": "Banyan VINES Internet Protocol.   Banyan Virtual Integrated Network Service(VINES) was acomputer networkoperating systemand the set of computernetwork protocolsit used to talk to client machines on the network.Banyan Systemsran as a collection of services on top of AT&T System 5Unix, and based its core network protocols on the archetypicalXerox XNSstack.",
          "name": "banyan-vip",
          "nbarId": "487",
          "p2pTechnology": "false",
          "popularity": 1,
          "rank": 65535,
          "references": "http://en.wikipedia.org/wiki/Banyan_VINES#Protocol_Stack",
          "selectorId": "573",
          "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
          "trafficClass": "BULK_DATA",
          "tunnel": "false"
        }
      ],
      "networkIdentity": [
        {
          "id": "36ec59e5-40b6-4094-b75e-9a3bf7492306",
          "instanceId": 12340,
          "authEntityId": 12340,
          "displayName": "12340",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "573",
          "protocol": "UDP",
          "upperPort": 0
        },
        {
          "id": "9be722f7-7b8c-4559-8378-6809047154a0",
          "instanceId": 12341,
          "authEntityId": 12341,
          "displayName": "12341",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "573",
          "protocol": "TCP",
          "upperPort": 0
        }
      ],
      "parentScalableGroup": {
        "idRef": "000c15bf-28fe-4fbb-9b69-35e6502721f1"
      },
      "user": [

      ],
      "userGroup": [

      ]
    },
    {
      "id": "ca18295d-a25f-47e4-8f95-c36030e788bd",
      "instanceId": 4511,
      "authEntityId": 4511,
      "displayName": "4511",
      "authEntityClass": -1909073334,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "createTime": 1517966742850,
      "deployed": false,
      "isSeeded": true,
      "isStale": false,
      "lastUpdateTime": 1517966742850,
      "name": "groove",
      "namespace": "scalablegroup:application",
      "provisioningState": "DEFINED",
      "qualifier": "application",
      "resourceVersion": 0,
      "targetIdList": [

      ],
      "type": "scalablegroup",
      "cfsChangeInfo": [

      ],
      "customProvisions": [

      ],
      "scalableGroupExternalHandle": "groove",
      "scalableGroupType": "APPLICATION",
      "securityGroupTag": 0,
      "state": "ACTIVE",
      "vnAgnostic": true,
      "identitySource": {
        "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
        "instanceId": 10010,
        "authEntityId": 10010,
        "displayName": "10010",
        "authEntityClass": 1738953278,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "state": "INACTIVE",
        "type": "NBAR"
      },
      "indicativeNetworkIdentity": [
        {
          "id": "8d41aa19-b656-4a2b-a8ce-4a8b4a734179",
          "instanceId": 13267,
          "authEntityId": 13267,
          "displayName": "13267",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "2492",
          "protocol": "TCP",
          "upperPort": 0
        },
        {
          "id": "7fe32c06-ae17-4193-b371-fe9f18bc5e4c",
          "instanceId": 13268,
          "authEntityId": 13268,
          "displayName": "13268",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "2492",
          "protocol": "UDP",
          "upperPort": 0
        }
      ],
      "networkApplications": [
        {
          "id": "48093a3b-c359-4cc6-8f17-36e83839d087",
          "instanceId": 11462,
          "authEntityId": 11462,
          "displayName": "11462",
          "authEntityClass": -217092956,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "appProtocol": "tcp/udp",
          "applicationGroup": "other",
          "applicationType": "DEFAULT",
          "categoryId": "6daa3dfd-3526-407a-88f0-97824b3f3a24",
          "createTime": 1517966742850,
          "encrypted": "false",
          "engineId": "3",
          "helpString": "groove",
          "ignoreConflict": false,
          "lastUpdateTime": 1517966742850,
          "longDescription": "Microsoft SharePoint Workspace, previously known as Microsoft Office Groove, is a desktop application designed for document collaboration in teams with members who are regularly off-line or who do not share the same network security clearance.",
          "name": "groove",
          "nbarId": "715",
          "p2pTechnology": "false",
          "popularity": 1,
          "rank": 65535,
          "references": "http://office.com/sharepoint-workspace/",
          "selectorId": "2492",
          "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
          "trafficClass": "BULK_DATA",
          "tunnel": "false"
        }
      ],
      "networkIdentity": [
        {
          "id": "e6ffa38d-9ec7-483f-b317-6e93ccf241a2",
          "instanceId": 13265,
          "authEntityId": 13265,
          "displayName": "13265",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "2492",
          "protocol": "UDP",
          "upperPort": 0
        },
        {
          "id": "617bb39e-cda4-4ee8-a5aa-afa4791b2933",
          "instanceId": 13266,
          "authEntityId": 13266,
          "displayName": "13266",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "2492",
          "protocol": "TCP",
          "upperPort": 0
        }
      ],
      "parentScalableGroup": {
        "idRef": "000c15bf-28fe-4fbb-9b69-35e6502721f1"
      },
      "user": [

      ],
      "userGroup": [

      ]
    },
    {
      "id": "ca227fc8-eb9b-4960-b5cf-b0142e9a3d37",
      "instanceId": 4295,
      "authEntityId": 4295,
      "displayName": "4295",
      "authEntityClass": -1909073334,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "createTime": 1517966742184,
      "deployed": false,
      "isSeeded": true,
      "isStale": false,
      "lastUpdateTime": 1517966742184,
      "name": "covia",
      "namespace": "scalablegroup:application",
      "provisioningState": "DEFINED",
      "qualifier": "application",
      "resourceVersion": 0,
      "targetIdList": [

      ],
      "type": "scalablegroup",
      "cfsChangeInfo": [

      ],
      "customProvisions": [

      ],
      "scalableGroupExternalHandle": "covia",
      "scalableGroupType": "APPLICATION",
      "securityGroupTag": 0,
      "state": "ACTIVE",
      "vnAgnostic": true,
      "identitySource": {
        "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
        "instanceId": 10010,
        "authEntityId": 10010,
        "displayName": "10010",
        "authEntityClass": 1738953278,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "state": "INACTIVE",
        "type": "NBAR"
      },
      "indicativeNetworkIdentity": [
        {
          "id": "7f66344d-3882-4384-8c70-f94588f5e9bb",
          "instanceId": 12659,
          "authEntityId": 12659,
          "displayName": "12659",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "64",
          "protocol": "TCP",
          "upperPort": 0
        },
        {
          "id": "392ce7fa-9a03-4fd2-8d2a-38b0e4248d07",
          "instanceId": 12660,
          "authEntityId": 12660,
          "displayName": "12660",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "64",
          "protocol": "UDP",
          "upperPort": 0
        }
      ],
      "networkApplications": [
        {
          "id": "432abdd3-adb7-4cc8-a63f-b01b36f20002",
          "instanceId": 11248,
          "authEntityId": 11248,
          "displayName": "11248",
          "authEntityClass": -217092956,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "appProtocol": "tcp/udp",
          "applicationGroup": "other",
          "applicationType": "DEFAULT",
          "categoryId": "6daa3dfd-3526-407a-88f0-97824b3f3a24",
          "createTime": 1517966742184,
          "encrypted": "false",
          "engineId": "3",
          "helpString": "Communications Integrator",
          "ignoreConflict": false,
          "lastUpdateTime": 1517966742184,
          "longDescription": "Covia is used mainly by emergency response teams and the military to enable audio, video, data and other types of communication between multiple systems and devices running different operating systems.",
          "name": "covia",
          "nbarId": "941",
          "p2pTechnology": "false",
          "popularity": 1,
          "rank": 65535,
          "references": "http://www.covialabs.com",
          "selectorId": "64",
          "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
          "trafficClass": "BULK_DATA",
          "tunnel": "false"
        }
      ],
      "networkIdentity": [
        {
          "id": "06a3604c-6eed-42a5-aa2d-ffe63eb65de7",
          "instanceId": 12657,
          "authEntityId": 12657,
          "displayName": "12657",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "64",
          "protocol": "UDP",
          "upperPort": 0
        },
        {
          "id": "7497f77b-7956-4e1f-9030-5444f0bdf8c0",
          "instanceId": 12658,
          "authEntityId": 12658,
          "displayName": "12658",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "64",
          "protocol": "TCP",
          "upperPort": 0
        }
      ],
      "parentScalableGroup": {
        "idRef": "000c15bf-28fe-4fbb-9b69-35e6502721f1"
      },
      "user": [

      ],
      "userGroup": [

      ]
    },
    {
      "id": "ca79f6b6-aa7f-4ee9-bc15-904b249b3472",
      "instanceId": 4331,
      "authEntityId": 4331,
      "displayName": "4331",
      "authEntityClass": -1909073334,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "createTime": 1517966742305,
      "deployed": false,
      "isSeeded": true,
      "isStale": false,
      "lastUpdateTime": 1517966742305,
      "name": "db-service",
      "namespace": "scalablegroup:application",
      "provisioningState": "DEFINED",
      "qualifier": "application",
      "resourceVersion": 0,
      "targetIdList": [

      ],
      "type": "scalablegroup",
      "cfsChangeInfo": [

      ],
      "customProvisions": [

      ],
      "scalableGroupExternalHandle": "db-service",
      "scalableGroupType": "APPLICATION",
      "securityGroupTag": 0,
      "state": "ACTIVE",
      "vnAgnostic": true,
      "identitySource": {
        "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
        "instanceId": 10010,
        "authEntityId": 10010,
        "displayName": "10010",
        "authEntityClass": 1738953278,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "state": "INACTIVE",
        "type": "NBAR"
      },
      "indicativeNetworkIdentity": [

      ],
      "networkApplications": [
        {
          "id": "7ada3081-7fc4-4c2f-9a6a-fa855fbfed16",
          "instanceId": 11282,
          "authEntityId": 11282,
          "displayName": "11282",
          "authEntityClass": -217092956,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "applicationGroup": "sqlsvr-group",
          "applicationType": "DEFAULT",
          "categoryId": "cb5915d1-afec-4c30-ac9e-069ff382272f",
          "createTime": 1517966742305,
          "encrypted": "false",
          "engineId": "13",
          "helpString": "db service",
          "ignoreConflict": false,
          "lastUpdateTime": 1517966742305,
          "longDescription": "Data base service ",
          "name": "db-service",
          "nbarId": "1756",
          "p2pTechnology": "false",
          "popularity": 10,
          "rank": 65535,
          "references": "https://en.wikipedia.org/wiki/Database",
          "selectorId": "824",
          "subCategory": "b90786f4-0190-45b8-9924-21b3e9682590",
          "trafficClass": "TRANSACTIONAL_DATA",
          "tunnel": "false"
        }
      ],
      "networkIdentity": [

      ],
      "parentScalableGroup": {
        "idRef": "8718395e-683e-4a2d-a232-100202751271"
      },
      "user": [

      ],
      "userGroup": [

      ]
    },
    {
      "id": "cab2a4f3-a229-4832-9ac6-1939cfb6e2f2",
      "instanceId": 15221,
      "authEntityId": 15221,
      "displayName": "15221",
      "authEntityClass": -1909073334,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "createTime": 1517966744523,
      "deployed": false,
      "isSeeded": true,
      "isStale": false,
      "lastUpdateTime": 1517966744523,
      "name": "streamwork",
      "namespace": "scalablegroup:application",
      "provisioningState": "DEFINED",
      "qualifier": "application",
      "resourceVersion": 0,
      "targetIdList": [

      ],
      "type": "scalablegroup",
      "cfsChangeInfo": [

      ],
      "customProvisions": [

      ],
      "scalableGroupExternalHandle": "streamwork",
      "scalableGroupType": "APPLICATION",
      "securityGroupTag": 0,
      "state": "ACTIVE",
      "vnAgnostic": true,
      "identitySource": {
        "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
        "instanceId": 10010,
        "authEntityId": 10010,
        "displayName": "10010",
        "authEntityClass": 1738953278,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "state": "INACTIVE",
        "type": "NBAR"
      },
      "indicativeNetworkIdentity": [

      ],
      "networkApplications": [
        {
          "id": "bf70d956-b89a-482d-ae7e-ad995eed8d86",
          "instanceId": 16164,
          "authEntityId": 16164,
          "displayName": "16164",
          "authEntityClass": -217092956,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "applicationGroup": "other",
          "applicationType": "DEFAULT",
          "categoryId": "a09ef1b3-ba26-4372-aee8-309d50697e76",
          "createTime": 1517966744523,
          "encrypted": "false",
          "engineId": "13",
          "helpString": "DEPRECATED, traffic will not match",
          "ignoreConflict": false,
          "lastUpdateTime": 1517966744523,
          "longDescription": "Deprecated: Stream Work developed by Xing Technology is a network delivery of live and on-demand of video and audio data. NBC is using it for broadcasting financial news, popular in the U.S and Europe. The protocol is based on a client server architecture and uses connectionless  protocol UDP.",
          "name": "streamwork",
          "nbarId": "55",
          "p2pTechnology": "false",
          "popularity": 4,
          "rank": 65535,
          "references": "http://www.sapstreamwork.com/how-it-works",
          "selectorId": "427",
          "subCategory": "85a797c0-aef8-4284-91a7-677f33e29abb",
          "trafficClass": "MULTIMEDIA_STREAMING",
          "tunnel": "false"
        }
      ],
      "networkIdentity": [

      ],
      "parentScalableGroup": {
        "idRef": "fd6264c9-c16a-4311-a335-a3db039256f3"
      },
      "user": [

      ],
      "userGroup": [

      ]
    },
    {
      "id": "cac00891-d6f4-4d86-901f-23e60d6daa34",
      "instanceId": 4837,
      "authEntityId": 4837,
      "displayName": "4837",
      "authEntityClass": -1909073334,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "createTime": 1517966743588,
      "deployed": false,
      "isSeeded": true,
      "isStale": false,
      "lastUpdateTime": 1517966743588,
      "name": "netbios-ssn",
      "namespace": "scalablegroup:application",
      "provisioningState": "DEFINED",
      "qualifier": "application",
      "resourceVersion": 0,
      "targetIdList": [

      ],
      "type": "scalablegroup",
      "cfsChangeInfo": [

      ],
      "customProvisions": [

      ],
      "scalableGroupExternalHandle": "netbios-ssn",
      "scalableGroupType": "APPLICATION",
      "securityGroupTag": 0,
      "state": "ACTIVE",
      "vnAgnostic": true,
      "identitySource": {
        "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
        "instanceId": 10010,
        "authEntityId": 10010,
        "displayName": "10010",
        "authEntityClass": 1738953278,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "state": "INACTIVE",
        "type": "NBAR"
      },
      "indicativeNetworkIdentity": [
        {
          "id": "f17f4058-2a31-4298-b497-a4e94ba4af11",
          "instanceId": 14142,
          "authEntityId": 14142,
          "displayName": "14142",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "139",
          "protocol": "UDP",
          "upperPort": 0
        },
        {
          "id": "306e377e-d1e5-4d02-872e-afd963af7ac4",
          "instanceId": 14143,
          "authEntityId": 14143,
          "displayName": "14143",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "139",
          "protocol": "TCP",
          "upperPort": 0
        }
      ],
      "networkApplications": [
        {
          "id": "0ab8df36-f8a9-4947-82aa-60a68fdc3e0d",
          "instanceId": 11786,
          "authEntityId": 11786,
          "displayName": "11786",
          "authEntityClass": -217092956,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "appProtocol": "tcp/udp",
          "applicationGroup": "netbios-group",
          "applicationType": "DEFAULT",
          "categoryId": "1fc17362-9334-4c7b-b752-5afdbb574017",
          "createTime": 1517966743588,
          "encrypted": "false",
          "engineId": "3",
          "helpString": "NETBIOS Session Service",
          "ignoreConflict": false,
          "lastUpdateTime": 1517966743588,
          "longDescription": "NetBIOS's session service is the part of the NetBIOS-over-TCP/UDP protocol suite for connection oriented communication.  NetBIOS provides services related to the session layer of the OSI model allowing applications on separate computers to communicate over a local area network. NetBIOS's session service allows two machines to form a connection, mechanisms for error detection and recovery and multiple packets messages.",
          "name": "netbios-ssn",
          "nbarId": "1506",
          "p2pTechnology": "false",
          "popularity": 7,
          "rank": 65535,
          "references": "http://tools.ietf.org/html/rfc1002",
          "selectorId": "139",
          "subCategory": "7f5fa07d-fcb4-4217-9908-b438066c0b60",
          "trafficClass": "OPS_ADMIN_MGMT",
          "tunnel": "false"
        }
      ],
      "networkIdentity": [
        {
          "id": "da33ee21-c31c-422f-a250-393a88e7857c",
          "instanceId": 14140,
          "authEntityId": 14140,
          "displayName": "14140",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "139",
          "protocol": "UDP",
          "upperPort": 0
        },
        {
          "id": "e60f63d8-c61f-4cce-b59b-f4b11c37285b",
          "instanceId": 14141,
          "authEntityId": 14141,
          "displayName": "14141",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "139",
          "protocol": "TCP",
          "upperPort": 0
        }
      ],
      "parentScalableGroup": {
        "idRef": "38b9eb6b-f3b6-4e13-9b79-a11e0c6423d4"
      },
      "user": [

      ],
      "userGroup": [

      ]
    },
    {
      "id": "cb71f1d8-4752-434a-b074-2eed15c9bbc3",
      "instanceId": 15058,
      "authEntityId": 15058,
      "displayName": "15058",
      "authEntityClass": -1909073334,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "createTime": 1517966744136,
      "deployed": false,
      "isSeeded": true,
      "isStale": false,
      "lastUpdateTime": 1517966744136,
      "name": "rmt",
      "namespace": "scalablegroup:application",
      "provisioningState": "DEFINED",
      "qualifier": "application",
      "resourceVersion": 0,
      "targetIdList": [

      ],
      "type": "scalablegroup",
      "cfsChangeInfo": [

      ],
      "customProvisions": [

      ],
      "scalableGroupExternalHandle": "rmt",
      "scalableGroupType": "APPLICATION",
      "securityGroupTag": 0,
      "state": "ACTIVE",
      "vnAgnostic": true,
      "identitySource": {
        "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
        "instanceId": 10010,
        "authEntityId": 10010,
        "displayName": "10010",
        "authEntityClass": 1738953278,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "state": "INACTIVE",
        "type": "NBAR"
      },
      "indicativeNetworkIdentity": [
        {
          "id": "b7a36219-7790-4027-9873-bd6aea2c0193",
          "instanceId": 14814,
          "authEntityId": 14814,
          "displayName": "14814",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "411",
          "protocol": "UDP",
          "upperPort": 0
        }
      ],
      "networkApplications": [
        {
          "id": "bd17881e-c7f8-4c48-8f91-aac4fb8fdecd",
          "instanceId": 11997,
          "authEntityId": 11997,
          "displayName": "11997",
          "authEntityClass": -217092956,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "appProtocol": "udp",
          "applicationGroup": "other",
          "applicationType": "DEFAULT",
          "categoryId": "1fc17362-9334-4c7b-b752-5afdbb574017",
          "createTime": 1517966744136,
          "encrypted": "false",
          "engineId": "3",
          "helpString": "Remote MT Protocol",
          "ignoreConflict": false,
          "lastUpdateTime": 1517966744136,
          "longDescription": "Rmtis a program used by the remotedump,restoreortarprograms in manipulating a magnetic tape drive through an interprocess communication connection.",
          "name": "rmt",
          "nbarId": "326",
          "p2pTechnology": "false",
          "popularity": 1,
          "rank": 65535,
          "references": "http://linux.die.net/man/8/rmt",
          "selectorId": "411",
          "subCategory": "787a7d77-977b-4974-89d3-379ba49e88b7",
          "trafficClass": "OPS_ADMIN_MGMT",
          "tunnel": "false"
        }
      ],
      "networkIdentity": [
        {
          "id": "7592f361-025f-428d-b4a0-9d3a626e72c5",
          "instanceId": 14813,
          "authEntityId": 14813,
          "displayName": "14813",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "411",
          "protocol": "UDP",
          "upperPort": 0
        }
      ],
      "parentScalableGroup": {
        "idRef": "e06dd89f-0a70-4422-ab0b-c2314650a9a7"
      },
      "user": [

      ],
      "userGroup": [

      ]
    },
    {
      "id": "cbc2057b-e19d-444c-8f08-33688688a6b2",
      "instanceId": 15049,
      "authEntityId": 15049,
      "displayName": "15049",
      "authEntityClass": -1909073334,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "createTime": 1517966744115,
      "deployed": false,
      "isSeeded": true,
      "isStale": false,
      "lastUpdateTime": 1517966744115,
      "name": "ris",
      "namespace": "scalablegroup:application",
      "provisioningState": "DEFINED",
      "qualifier": "application",
      "resourceVersion": 0,
      "targetIdList": [

      ],
      "type": "scalablegroup",
      "cfsChangeInfo": [

      ],
      "customProvisions": [

      ],
      "scalableGroupExternalHandle": "ris",
      "scalableGroupType": "APPLICATION",
      "securityGroupTag": 0,
      "state": "ACTIVE",
      "vnAgnostic": true,
      "identitySource": {
        "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
        "instanceId": 10010,
        "authEntityId": 10010,
        "displayName": "10010",
        "authEntityClass": 1738953278,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "state": "INACTIVE",
        "type": "NBAR"
      },
      "indicativeNetworkIdentity": [
        {
          "id": "ef6c7036-b34e-42d0-9a77-98b0646a0195",
          "instanceId": 14779,
          "authEntityId": 14779,
          "displayName": "14779",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "180",
          "protocol": "UDP",
          "upperPort": 0
        },
        {
          "id": "37be8b06-5d60-4775-87fb-2bb3c57c9153",
          "instanceId": 14780,
          "authEntityId": 14780,
          "displayName": "14780",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "180",
          "protocol": "TCP",
          "upperPort": 0
        }
      ],
      "networkApplications": [
        {
          "id": "1c3b5fd7-60b1-4bed-9491-f373e3d3abd1",
          "instanceId": 11988,
          "authEntityId": 11988,
          "displayName": "11988",
          "authEntityClass": -217092956,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "appProtocol": "tcp/udp",
          "applicationGroup": "other",
          "applicationType": "DEFAULT",
          "categoryId": "6daa3dfd-3526-407a-88f0-97824b3f3a24",
          "createTime": 1517966744115,
          "encrypted": "false",
          "engineId": "3",
          "helpString": "Intergraph",
          "ignoreConflict": false,
          "lastUpdateTime": 1517966744115,
          "longDescription": "Relational Interface System (RIS) is Intergraph Corporation's middleware for connecting client software and DBMS. It is used in geographic information systems Modular GIS Environment (MGE) and GeoMedia, the digital photogrammetric station ImageStation, security systems, CAD software (such as MicroStation) and mechanical-design software I/EMS (predecessor of Solid Edge). It was developed for UNIX (including CLIX) many years before Open Database Connectivity (ODBC) and in the last years for Apple Macintosh and Windows computers.",
          "name": "ris",
          "nbarId": "1023",
          "p2pTechnology": "false",
          "popularity": 1,
          "rank": 65535,
          "references": "http://en.wikipedia.org/wiki/Relational_Interface_System",
          "selectorId": "180",
          "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
          "trafficClass": "BULK_DATA",
          "tunnel": "false"
        }
      ],
      "networkIdentity": [
        {
          "id": "1edc6f4b-b977-4e7a-b26b-adeb300b057f",
          "instanceId": 14777,
          "authEntityId": 14777,
          "displayName": "14777",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "180",
          "protocol": "TCP",
          "upperPort": 0
        },
        {
          "id": "1742877c-bd17-4672-94a8-4c40b5057308",
          "instanceId": 14778,
          "authEntityId": 14778,
          "displayName": "14778",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "180",
          "protocol": "UDP",
          "upperPort": 0
        }
      ],
      "parentScalableGroup": {
        "idRef": "000c15bf-28fe-4fbb-9b69-35e6502721f1"
      },
      "user": [

      ],
      "userGroup": [

      ]
    },
    {
      "id": "cbe6213d-f94c-4423-ade0-bf99daf7aa71",
      "instanceId": 4795,
      "authEntityId": 4795,
      "displayName": "4795",
      "authEntityClass": -1909073334,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "createTime": 1517966743509,
      "deployed": false,
      "isSeeded": true,
      "isStale": false,
      "lastUpdateTime": 1517966743509,
      "name": "msdp",
      "namespace": "scalablegroup:application",
      "provisioningState": "DEFINED",
      "qualifier": "application",
      "resourceVersion": 0,
      "targetIdList": [

      ],
      "type": "scalablegroup",
      "cfsChangeInfo": [

      ],
      "customProvisions": [

      ],
      "scalableGroupExternalHandle": "msdp",
      "scalableGroupType": "APPLICATION",
      "securityGroupTag": 0,
      "state": "ACTIVE",
      "vnAgnostic": true,
      "identitySource": {
        "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
        "instanceId": 10010,
        "authEntityId": 10010,
        "displayName": "10010",
        "authEntityClass": 1738953278,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "state": "INACTIVE",
        "type": "NBAR"
      },
      "indicativeNetworkIdentity": [
        {
          "id": "cdd70534-38a0-4043-818e-ef91f72790ad",
          "instanceId": 14027,
          "authEntityId": 14027,
          "displayName": "14027",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "639",
          "protocol": "UDP",
          "upperPort": 0
        },
        {
          "id": "ff2e4a1f-b49b-48fa-a499-b0505d3512a4",
          "instanceId": 14028,
          "authEntityId": 14028,
          "displayName": "14028",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "639",
          "protocol": "TCP",
          "upperPort": 0
        }
      ],
      "networkApplications": [
        {
          "id": "ef20d2dc-b484-444b-9e84-809cce819476",
          "instanceId": 11744,
          "authEntityId": 11744,
          "displayName": "11744",
          "authEntityClass": -217092956,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "appProtocol": "tcp/udp",
          "applicationGroup": "other",
          "applicationType": "DEFAULT",
          "categoryId": "1fc17362-9334-4c7b-b752-5afdbb574017",
          "createTime": 1517966743509,
          "encrypted": "false",
          "engineId": "3",
          "helpString": "msdp",
          "ignoreConflict": false,
          "lastUpdateTime": 1517966743509,
          "longDescription": "Multicast Source Discovery Protocol (MSDP) is a multicast routing protocol belonging to the Protocol Independent Multicast (PIM) family. MSDP interconnects multiple IPv4 PIM Sparse-Mode (PIM-SM) domains, which enables PIM-SM to have Rendezvous Point (RP) redundancy and inter-domain multicasting.",
          "name": "msdp",
          "nbarId": "548",
          "p2pTechnology": "false",
          "popularity": 1,
          "rank": 65535,
          "references": "http://tools.ietf.org/html/rfc3618",
          "selectorId": "639",
          "subCategory": "7cc1b3a6-4102-44e9-b522-0e71afe39a03",
          "trafficClass": "NETWORK_CONTROL",
          "tunnel": "false"
        }
      ],
      "networkIdentity": [
        {
          "id": "1a1cd5d4-9ba8-4b49-bdee-28ca10eb223c",
          "instanceId": 14025,
          "authEntityId": 14025,
          "displayName": "14025",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "639",
          "protocol": "UDP",
          "upperPort": 0
        },
        {
          "id": "fbe88fb8-4eb1-4466-900b-3580cbaf33d7",
          "instanceId": 14026,
          "authEntityId": 14026,
          "displayName": "14026",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "639",
          "protocol": "TCP",
          "upperPort": 0
        }
      ],
      "parentScalableGroup": {
        "idRef": "77e75842-e98b-4112-ad0c-300db4bb11be"
      },
      "user": [

      ],
      "userGroup": [

      ]
    },
    {
      "id": "cbfdaee2-f4d8-483f-81d8-d872ab6b1331",
      "instanceId": 4999,
      "authEntityId": 4999,
      "displayName": "4999",
      "authEntityClass": -1909073334,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "createTime": 1517966744016,
      "deployed": false,
      "isSeeded": true,
      "isStale": false,
      "lastUpdateTime": 1517966744016,
      "name": "pvp",
      "namespace": "scalablegroup:application",
      "provisioningState": "DEFINED",
      "qualifier": "application",
      "resourceVersion": 0,
      "targetIdList": [

      ],
      "type": "scalablegroup",
      "cfsChangeInfo": [

      ],
      "customProvisions": [

      ],
      "scalableGroupExternalHandle": "pvp",
      "scalableGroupType": "APPLICATION",
      "securityGroupTag": 0,
      "state": "ACTIVE",
      "vnAgnostic": true,
      "identitySource": {
        "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
        "instanceId": 10010,
        "authEntityId": 10010,
        "displayName": "10010",
        "authEntityClass": 1738953278,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "state": "INACTIVE",
        "type": "NBAR"
      },
      "indicativeNetworkIdentity": [
        {
          "id": "2e0d6796-25d6-4b5e-8358-c3cc7fc5450c",
          "instanceId": 14655,
          "authEntityId": 14655,
          "displayName": "14655",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipProtocolNumbers": "75",
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "protocol": "PVP",
          "upperPort": 0
        }
      ],
      "networkApplications": [
        {
          "id": "3d74aa9b-8916-426b-a8f2-66f1517d6854",
          "instanceId": 11948,
          "authEntityId": 11948,
          "displayName": "11948",
          "authEntityClass": -217092956,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "appProtocol": "ip",
          "applicationGroup": "other",
          "applicationType": "DEFAULT",
          "categoryId": "6daa3dfd-3526-407a-88f0-97824b3f3a24",
          "createTime": 1517966744015,
          "encrypted": "unassigned",
          "engineId": "1",
          "helpString": "Packet Video Protocol",
          "ignoreConflict": false,
          "lastUpdateTime": 1517966744015,
          "longDescription": "The Packet Video Protocol (PVP) is a set of extensions to the Network Voice Protocol (NVP-II) and consists mostly of a data protocol for transmission of video data. No specific changes to the NVP-II protocol are necessary for the PVP.",
          "name": "pvp",
          "nbarId": "829",
          "p2pTechnology": "unassigned",
          "popularity": 2,
          "rank": 65535,
          "references": "http://tools.ietf.org/html/rfc1453",
          "selectorId": "75",
          "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
          "trafficClass": "BULK_DATA",
          "tunnel": "unassigned"
        }
      ],
      "networkIdentity": [
        {
          "id": "c6e2971f-c1d7-47e8-a5a2-65f416688177",
          "instanceId": 14654,
          "authEntityId": 14654,
          "displayName": "14654",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipProtocolNumbers": "75",
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "protocol": "PVP",
          "upperPort": 0
        }
      ],
      "parentScalableGroup": {
        "idRef": "000c15bf-28fe-4fbb-9b69-35e6502721f1"
      },
      "user": [

      ],
      "userGroup": [

      ]
    },
    {
      "id": "cc134f84-b8cb-4dc8-8636-9dd1dbf87e4c",
      "instanceId": 4345,
      "authEntityId": 4345,
      "displayName": "4345",
      "authEntityClass": -1909073334,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "createTime": 1517966742351,
      "deployed": false,
      "isSeeded": true,
      "isStale": false,
      "lastUpdateTime": 1517966742351,
      "name": "decauth",
      "namespace": "scalablegroup:application",
      "provisioningState": "DEFINED",
      "qualifier": "application",
      "resourceVersion": 0,
      "targetIdList": [

      ],
      "type": "scalablegroup",
      "cfsChangeInfo": [

      ],
      "customProvisions": [

      ],
      "scalableGroupExternalHandle": "decauth",
      "scalableGroupType": "APPLICATION",
      "securityGroupTag": 0,
      "state": "ACTIVE",
      "vnAgnostic": true,
      "identitySource": {
        "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
        "instanceId": 10010,
        "authEntityId": 10010,
        "displayName": "10010",
        "authEntityClass": 1738953278,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "state": "INACTIVE",
        "type": "NBAR"
      },
      "indicativeNetworkIdentity": [
        {
          "id": "1ffb702c-76a8-46d2-bc11-e145162c8635",
          "instanceId": 12812,
          "authEntityId": 12812,
          "displayName": "12812",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "316",
          "protocol": "TCP",
          "upperPort": 0
        },
        {
          "id": "0364479e-a769-453d-a748-afc6ad59c864",
          "instanceId": 12813,
          "authEntityId": 12813,
          "displayName": "12813",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "316",
          "protocol": "UDP",
          "upperPort": 0
        }
      ],
      "networkApplications": [
        {
          "id": "7b6100cd-4e04-4a51-8c3b-01ab4ddf93b4",
          "instanceId": 11296,
          "authEntityId": 11296,
          "displayName": "11296",
          "authEntityClass": -217092956,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "appProtocol": "tcp/udp",
          "applicationGroup": "other",
          "applicationType": "DEFAULT",
          "categoryId": "1fc17362-9334-4c7b-b752-5afdbb574017",
          "createTime": 1517966742351,
          "encrypted": "false",
          "engineId": "3",
          "helpString": "decauth",
          "ignoreConflict": false,
          "lastUpdateTime": 1517966742351,
          "longDescription": "Registered with IANA on port 316 TCP/UDP",
          "name": "decauth",
          "nbarId": "1156",
          "p2pTechnology": "false",
          "popularity": 1,
          "rank": 65535,
          "references": "http://www.iana.org/assignments/service-names-port-numbers/service-names-port-numbers.xml",
          "selectorId": "316",
          "subCategory": "e390124a-0fd6-4253-b68e-4b0c1af8cb4b",
          "trafficClass": "OPS_ADMIN_MGMT",
          "tunnel": "false"
        }
      ],
      "networkIdentity": [
        {
          "id": "b3557607-bf1d-40c0-88cb-6699643369ac",
          "instanceId": 12810,
          "authEntityId": 12810,
          "displayName": "12810",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "316",
          "protocol": "TCP",
          "upperPort": 0
        },
        {
          "id": "a5c08169-f945-4f11-b647-1ce82a3631ed",
          "instanceId": 12811,
          "authEntityId": 12811,
          "displayName": "12811",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "316",
          "protocol": "UDP",
          "upperPort": 0
        }
      ],
      "parentScalableGroup": {
        "idRef": "7788bf2d-e289-4597-b500-17aacb7e820e"
      },
      "user": [

      ],
      "userGroup": [

      ]
    },
    {
      "id": "ccbf2ef9-4b58-4533-b119-3777defa6e19",
      "instanceId": 4859,
      "authEntityId": 4859,
      "displayName": "4859",
      "authEntityClass": -1909073334,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "createTime": 1517966743630,
      "deployed": false,
      "isSeeded": true,
      "isStale": false,
      "lastUpdateTime": 1517966743630,
      "name": "new-rwho",
      "namespace": "scalablegroup:application",
      "provisioningState": "DEFINED",
      "qualifier": "application",
      "resourceVersion": 0,
      "targetIdList": [

      ],
      "type": "scalablegroup",
      "cfsChangeInfo": [

      ],
      "customProvisions": [

      ],
      "scalableGroupExternalHandle": "new-rwho",
      "scalableGroupType": "APPLICATION",
      "securityGroupTag": 0,
      "state": "ACTIVE",
      "vnAgnostic": true,
      "identitySource": {
        "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
        "instanceId": 10010,
        "authEntityId": 10010,
        "displayName": "10010",
        "authEntityClass": 1738953278,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "state": "INACTIVE",
        "type": "NBAR"
      },
      "indicativeNetworkIdentity": [
        {
          "id": "fad1f62c-5f34-4ffa-84a6-2accd101e721",
          "instanceId": 14216,
          "authEntityId": 14216,
          "displayName": "14216",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "550",
          "protocol": "UDP",
          "upperPort": 0
        },
        {
          "id": "0b7a399e-38dc-4218-949d-c5eadb2dff6c",
          "instanceId": 14217,
          "authEntityId": 14217,
          "displayName": "14217",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "550",
          "protocol": "TCP",
          "upperPort": 0
        }
      ],
      "networkApplications": [
        {
          "id": "deaafb5b-203b-428c-ac0a-7cdb7cab40b4",
          "instanceId": 11808,
          "authEntityId": 11808,
          "displayName": "11808",
          "authEntityClass": -217092956,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "appProtocol": "tcp/udp",
          "applicationGroup": "other",
          "applicationType": "DEFAULT",
          "categoryId": "1fc17362-9334-4c7b-b752-5afdbb574017",
          "createTime": 1517966743630,
          "encrypted": "false",
          "engineId": "3",
          "helpString": "new who",
          "ignoreConflict": false,
          "lastUpdateTime": 1517966743630,
          "longDescription": "Registered with IANA on port 550 TCP/UDP",
          "name": "new-rwho",
          "nbarId": "467",
          "p2pTechnology": "false",
          "popularity": 1,
          "rank": 65535,
          "references": "http://www.iana.org/assignments/service-names-port-numbers/service-names-port-numbers.xml",
          "selectorId": "550",
          "subCategory": "f3c0f761-d894-4112-ab89-b90fc84fd660",
          "trafficClass": "OPS_ADMIN_MGMT",
          "tunnel": "false"
        }
      ],
      "networkIdentity": [
        {
          "id": "534b6f78-340a-44ad-856f-047e8e71e9c7",
          "instanceId": 14214,
          "authEntityId": 14214,
          "displayName": "14214",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "550",
          "protocol": "TCP",
          "upperPort": 0
        },
        {
          "id": "3c47608e-a6db-4c55-bced-96743be7240d",
          "instanceId": 14215,
          "authEntityId": 14215,
          "displayName": "14215",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "550",
          "protocol": "UDP",
          "upperPort": 0
        }
      ],
      "parentScalableGroup": {
        "idRef": "5fcd74d4-8726-47f7-a088-c457863da7ef"
      },
      "user": [

      ],
      "userGroup": [

      ]
    },
    {
      "id": "cce9b96f-e396-4403-81ee-7bfdedeafef0",
      "instanceId": 4534,
      "authEntityId": 4534,
      "displayName": "4534",
      "authEntityClass": -1909073334,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "createTime": 1517966742923,
      "deployed": false,
      "isSeeded": true,
      "isStale": false,
      "lastUpdateTime": 1517966742923,
      "name": "hcp-wismar",
      "namespace": "scalablegroup:application",
      "provisioningState": "DEFINED",
      "qualifier": "application",
      "resourceVersion": 0,
      "targetIdList": [

      ],
      "type": "scalablegroup",
      "cfsChangeInfo": [

      ],
      "customProvisions": [

      ],
      "scalableGroupExternalHandle": "hcp-wismar",
      "scalableGroupType": "APPLICATION",
      "securityGroupTag": 0,
      "state": "ACTIVE",
      "vnAgnostic": true,
      "identitySource": {
        "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
        "instanceId": 10010,
        "authEntityId": 10010,
        "displayName": "10010",
        "authEntityClass": 1738953278,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "state": "INACTIVE",
        "type": "NBAR"
      },
      "indicativeNetworkIdentity": [
        {
          "id": "5a4d896c-4060-4813-8e5c-5c47228d6c24",
          "instanceId": 13323,
          "authEntityId": 13323,
          "displayName": "13323",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "686",
          "protocol": "TCP",
          "upperPort": 0
        },
        {
          "id": "03602f31-b349-4da9-91e9-9bd650d722a1",
          "instanceId": 13324,
          "authEntityId": 13324,
          "displayName": "13324",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "686",
          "protocol": "UDP",
          "upperPort": 0
        }
      ],
      "networkApplications": [
        {
          "id": "f5fadc8f-0a22-4bc4-bb71-031785a61133",
          "instanceId": 11485,
          "authEntityId": 11485,
          "displayName": "11485",
          "authEntityClass": -217092956,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "appProtocol": "tcp/udp",
          "applicationGroup": "other",
          "applicationType": "DEFAULT",
          "categoryId": "6daa3dfd-3526-407a-88f0-97824b3f3a24",
          "createTime": 1517966742923,
          "encrypted": "false",
          "engineId": "3",
          "helpString": "Hardware Control Protocol Wismar",
          "ignoreConflict": false,
          "lastUpdateTime": 1517966742923,
          "longDescription": "Registered with IANA on port 686 TCP/UDP",
          "name": "hcp-wismar",
          "nbarId": "594",
          "p2pTechnology": "false",
          "popularity": 1,
          "rank": 65535,
          "references": "http://www.iana.org/assignments/service-names-port-numbers/service-names-port-numbers.xml",
          "selectorId": "686",
          "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
          "trafficClass": "BULK_DATA",
          "tunnel": "false"
        }
      ],
      "networkIdentity": [
        {
          "id": "e8e6dac1-72bb-4778-9e91-3a41e850568b",
          "instanceId": 13321,
          "authEntityId": 13321,
          "displayName": "13321",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "686",
          "protocol": "UDP",
          "upperPort": 0
        },
        {
          "id": "0133096d-703e-4c1f-9406-f6b0fd9d0b25",
          "instanceId": 13322,
          "authEntityId": 13322,
          "displayName": "13322",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "686",
          "protocol": "TCP",
          "upperPort": 0
        }
      ],
      "parentScalableGroup": {
        "idRef": "000c15bf-28fe-4fbb-9b69-35e6502721f1"
      },
      "user": [

      ],
      "userGroup": [

      ]
    },
    {
      "id": "cd1addfe-10d8-4544-8292-c07c8e384213",
      "instanceId": 15457,
      "authEntityId": 15457,
      "displayName": "15457",
      "authEntityClass": -1909073334,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "createTime": 1517966744999,
      "deployed": false,
      "isSeeded": true,
      "isStale": false,
      "lastUpdateTime": 1517966744999,
      "name": "yahoo-messenger-video",
      "namespace": "scalablegroup:application",
      "provisioningState": "DEFINED",
      "qualifier": "application",
      "resourceVersion": 0,
      "targetIdList": [

      ],
      "type": "scalablegroup",
      "cfsChangeInfo": [

      ],
      "customProvisions": [

      ],
      "scalableGroupExternalHandle": "yahoo-messenger-video",
      "scalableGroupType": "APPLICATION",
      "securityGroupTag": 0,
      "state": "ACTIVE",
      "vnAgnostic": true,
      "identitySource": {
        "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
        "instanceId": 10010,
        "authEntityId": 10010,
        "displayName": "10010",
        "authEntityClass": 1738953278,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "state": "INACTIVE",
        "type": "NBAR"
      },
      "indicativeNetworkIdentity": [

      ],
      "networkApplications": [
        {
          "id": "617714eb-900c-4b11-b9e7-1f03ff8cfbe6",
          "instanceId": 16400,
          "authEntityId": 16400,
          "displayName": "16400",
          "authEntityClass": -217092956,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "applicationGroup": "yahoo-messenger-group",
          "applicationType": "DEFAULT",
          "categoryId": "82a7b7fa-da5a-48f5-8b6a-a2546b947843",
          "createTime": 1517966744999,
          "encrypted": "false",
          "engineId": "13",
          "helpString": "Yahoo Messenger Video traffic classification",
          "ignoreConflict": false,
          "lastUpdateTime": 1517966744999,
          "longDescription": "Yahoo Messenger Video is a feature included in Yahoo Messenger. It uses the webcam to allow users to make private video calls and wide broadcasts.",
          "name": "yahoo-messenger-video",
          "nbarId": "1533",
          "p2pTechnology": "false",
          "popularity": 9,
          "rank": 65535,
          "references": "https://messenger.yahoo.com/features/webcam/",
          "selectorId": "594",
          "subCategory": "118c10a2-e272-47c1-92b9-4814ebe97138",
          "trafficClass": "MULTIMEDIA_CONFERENCING",
          "tunnel": "false"
        }
      ],
      "networkIdentity": [

      ],
      "parentScalableGroup": {
        "idRef": "fd6264c9-c16a-4311-a335-a3db039256f3"
      },
      "user": [

      ],
      "userGroup": [

      ]
    },
    {
      "id": "cd9d651f-a33a-4e4f-b7ae-f8db9563afcc",
      "instanceId": 4591,
      "authEntityId": 4591,
      "displayName": "4591",
      "authEntityClass": -1909073334,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "createTime": 1517966743069,
      "deployed": false,
      "isSeeded": true,
      "isStale": false,
      "lastUpdateTime": 1517966743069,
      "name": "ifmp",
      "namespace": "scalablegroup:application",
      "provisioningState": "DEFINED",
      "qualifier": "application",
      "resourceVersion": 0,
      "targetIdList": [

      ],
      "type": "scalablegroup",
      "cfsChangeInfo": [

      ],
      "customProvisions": [

      ],
      "scalableGroupExternalHandle": "ifmp",
      "scalableGroupType": "APPLICATION",
      "securityGroupTag": 0,
      "state": "ACTIVE",
      "vnAgnostic": true,
      "identitySource": {
        "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
        "instanceId": 10010,
        "authEntityId": 10010,
        "displayName": "10010",
        "authEntityClass": 1738953278,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "state": "INACTIVE",
        "type": "NBAR"
      },
      "indicativeNetworkIdentity": [
        {
          "id": "443af7ff-43ff-48b2-b510-8d65eb23ca27",
          "instanceId": 13488,
          "authEntityId": 13488,
          "displayName": "13488",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipProtocolNumbers": "101",
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "protocol": "IFMP",
          "upperPort": 0
        }
      ],
      "networkApplications": [
        {
          "id": "0070d090-1801-40e6-adf7-33868813e863",
          "instanceId": 11541,
          "authEntityId": 11541,
          "displayName": "11541",
          "authEntityClass": -217092956,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "appProtocol": "ip",
          "applicationGroup": "other",
          "applicationType": "DEFAULT",
          "categoryId": "6daa3dfd-3526-407a-88f0-97824b3f3a24",
          "createTime": 1517966743069,
          "encrypted": "unassigned",
          "engineId": "1",
          "helpString": "Ipsilon Flow Management Protocol",
          "ignoreConflict": false,
          "lastUpdateTime": 1517966743069,
          "longDescription": "The Ipsilon Flow Management Protocol (IFMP), is a protocol for allowing a node to instruct an adjacent node to attach a layer 2 label to a specified IP flow. The label allows more efficient access to cached routing information for that flow. The label can also enable a node to switch further packets belonging to the specified flow at layer 2 rather than forwarding them at layer 3.",
          "name": "ifmp",
          "nbarId": "855",
          "p2pTechnology": "unassigned",
          "popularity": 2,
          "rank": 65535,
          "references": "http://www.rfc-editor.org/rfc/rfc1953.txt",
          "selectorId": "101",
          "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
          "trafficClass": "BULK_DATA",
          "tunnel": "unassigned"
        }
      ],
      "networkIdentity": [
        {
          "id": "3c2b3277-8b10-48de-8155-4f1214a013ec",
          "instanceId": 13487,
          "authEntityId": 13487,
          "displayName": "13487",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipProtocolNumbers": "101",
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "protocol": "IFMP",
          "upperPort": 0
        }
      ],
      "parentScalableGroup": {
        "idRef": "000c15bf-28fe-4fbb-9b69-35e6502721f1"
      },
      "user": [

      ],
      "userGroup": [

      ]
    },
    {
      "id": "cdba760e-6788-4b7a-aebd-3c2744f17526",
      "instanceId": 15183,
      "authEntityId": 15183,
      "displayName": "15183",
      "authEntityClass": -1909073334,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "createTime": 1517966744425,
      "deployed": false,
      "isSeeded": true,
      "isStale": false,
      "lastUpdateTime": 1517966744425,
      "name": "soribada",
      "namespace": "scalablegroup:application",
      "provisioningState": "DEFINED",
      "qualifier": "application",
      "resourceVersion": 0,
      "targetIdList": [

      ],
      "type": "scalablegroup",
      "cfsChangeInfo": [

      ],
      "customProvisions": [

      ],
      "scalableGroupExternalHandle": "soribada",
      "scalableGroupType": "APPLICATION",
      "securityGroupTag": 0,
      "state": "ACTIVE",
      "vnAgnostic": true,
      "identitySource": {
        "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
        "instanceId": 10010,
        "authEntityId": 10010,
        "displayName": "10010",
        "authEntityClass": 1738953278,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "state": "INACTIVE",
        "type": "NBAR"
      },
      "indicativeNetworkIdentity": [

      ],
      "networkApplications": [
        {
          "id": "a00fda3c-cfdc-48c6-a0a7-c06f015ad06e",
          "instanceId": 16126,
          "authEntityId": 16126,
          "displayName": "16126",
          "authEntityClass": -217092956,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "appProtocol": "tcp",
          "applicationGroup": "other",
          "applicationType": "DEFAULT",
          "categoryId": "a09ef1b3-ba26-4372-aee8-309d50697e76",
          "createTime": 1517966744424,
          "encrypted": "false",
          "engineId": "13",
          "helpString": "a Korean P2P music sharing protocol",
          "ignoreConflict": false,
          "lastUpdateTime": 1517966744424,
          "longDescription": "The Soribada protocol is used by the Soribada file-sharing application popular in Korea. It also has paid services for MP3 file downloading. ",
          "name": "soribada",
          "nbarId": "842",
          "p2pTechnology": "true",
          "popularity": 7,
          "rank": 65535,
          "references": "http://kpop.soribada.com/En/",
          "selectorId": "438",
          "subCategory": "bf1b9d2c-aa52-4f37-8860-40d4603319f5",
          "trafficClass": "MULTIMEDIA_STREAMING",
          "tunnel": "false"
        }
      ],
      "networkIdentity": [
        {
          "id": "6b5464a8-8f03-43e8-9c77-43dcefbb4695",
          "instanceId": 17196,
          "authEntityId": 17196,
          "displayName": "17196",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "80",
          "protocol": "TCP",
          "upperPort": 0
        }
      ],
      "parentScalableGroup": {
        "idRef": "fd6264c9-c16a-4311-a335-a3db039256f3"
      },
      "user": [

      ],
      "userGroup": [

      ]
    },
    {
      "id": "ce0c3b23-a03f-4a40-966f-d430a23a4020",
      "instanceId": 4760,
      "authEntityId": 4760,
      "displayName": "4760",
      "authEntityClass": -1909073334,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "createTime": 1517966743438,
      "deployed": false,
      "isSeeded": true,
      "isStale": false,
      "lastUpdateTime": 1517966743438,
      "name": "monitor",
      "namespace": "scalablegroup:application",
      "provisioningState": "DEFINED",
      "qualifier": "application",
      "resourceVersion": 0,
      "targetIdList": [

      ],
      "type": "scalablegroup",
      "cfsChangeInfo": [

      ],
      "customProvisions": [

      ],
      "scalableGroupExternalHandle": "monitor",
      "scalableGroupType": "APPLICATION",
      "securityGroupTag": 0,
      "state": "ACTIVE",
      "vnAgnostic": true,
      "identitySource": {
        "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
        "instanceId": 10010,
        "authEntityId": 10010,
        "displayName": "10010",
        "authEntityClass": 1738953278,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "state": "INACTIVE",
        "type": "NBAR"
      },
      "indicativeNetworkIdentity": [
        {
          "id": "55b044c5-7844-4a48-8349-faba9ab67f8d",
          "instanceId": 13940,
          "authEntityId": 13940,
          "displayName": "13940",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "561",
          "protocol": "UDP",
          "upperPort": 0
        },
        {
          "id": "0f000e79-fc2a-4b99-ac1f-10593292f7eb",
          "instanceId": 13941,
          "authEntityId": 13941,
          "displayName": "13941",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "561",
          "protocol": "TCP",
          "upperPort": 0
        }
      ],
      "networkApplications": [
        {
          "id": "cc579315-f183-47a1-91f9-5043df26bede",
          "instanceId": 11710,
          "authEntityId": 11710,
          "displayName": "11710",
          "authEntityClass": -217092956,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "appProtocol": "tcp/udp",
          "applicationGroup": "other",
          "applicationType": "DEFAULT",
          "categoryId": "1fc17362-9334-4c7b-b752-5afdbb574017",
          "createTime": 1517966743438,
          "encrypted": "false",
          "engineId": "3",
          "helpString": "monitor",
          "ignoreConflict": false,
          "lastUpdateTime": 1517966743438,
          "longDescription": "Registered with IANA on port 561 TCP/UDP",
          "name": "monitor",
          "nbarId": "476",
          "p2pTechnology": "false",
          "popularity": 1,
          "rank": 65535,
          "references": "http://www.iana.org/assignments/service-names-port-numbers/service-names-port-numbers.xml",
          "selectorId": "561",
          "subCategory": "f3c0f761-d894-4112-ab89-b90fc84fd660",
          "trafficClass": "OPS_ADMIN_MGMT",
          "tunnel": "false"
        }
      ],
      "networkIdentity": [
        {
          "id": "4b0f2cdc-1b8f-42d2-bfcf-2ee841ab2ad9",
          "instanceId": 13938,
          "authEntityId": 13938,
          "displayName": "13938",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "561",
          "protocol": "TCP",
          "upperPort": 0
        },
        {
          "id": "dbb23ea5-f15b-442a-b6a1-054d919a1d84",
          "instanceId": 13939,
          "authEntityId": 13939,
          "displayName": "13939",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "561",
          "protocol": "UDP",
          "upperPort": 0
        }
      ],
      "parentScalableGroup": {
        "idRef": "5fcd74d4-8726-47f7-a088-c457863da7ef"
      },
      "user": [

      ],
      "userGroup": [

      ]
    },
    {
      "id": "ce2e1d8d-9a06-4f19-897c-6fda4652a7a0",
      "instanceId": 4827,
      "authEntityId": 4827,
      "displayName": "4827",
      "authEntityClass": -1909073334,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "createTime": 1517966743569,
      "deployed": false,
      "isSeeded": true,
      "isStale": false,
      "lastUpdateTime": 1517966743569,
      "name": "ncube-lm",
      "namespace": "scalablegroup:application",
      "provisioningState": "DEFINED",
      "qualifier": "application",
      "resourceVersion": 0,
      "targetIdList": [

      ],
      "type": "scalablegroup",
      "cfsChangeInfo": [

      ],
      "customProvisions": [

      ],
      "scalableGroupExternalHandle": "ncube-lm",
      "scalableGroupType": "APPLICATION",
      "securityGroupTag": 0,
      "state": "ACTIVE",
      "vnAgnostic": true,
      "identitySource": {
        "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
        "instanceId": 10010,
        "authEntityId": 10010,
        "displayName": "10010",
        "authEntityClass": 1738953278,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "state": "INACTIVE",
        "type": "NBAR"
      },
      "indicativeNetworkIdentity": [
        {
          "id": "e62c2a09-0754-4fad-9610-c47c940eb568",
          "instanceId": 14116,
          "authEntityId": 14116,
          "displayName": "14116",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "1521",
          "protocol": "UDP",
          "upperPort": 0
        },
        {
          "id": "68306aa6-02b8-403c-bda4-2b81a3eb15d4",
          "instanceId": 14117,
          "authEntityId": 14117,
          "displayName": "14117",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "1521",
          "protocol": "TCP",
          "upperPort": 0
        }
      ],
      "networkApplications": [
        {
          "id": "6aa4402e-f187-47ec-8245-4f6d4623a6e8",
          "instanceId": 11776,
          "authEntityId": 11776,
          "displayName": "11776",
          "authEntityClass": -217092956,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "appProtocol": "tcp/udp",
          "applicationGroup": "other",
          "applicationType": "DEFAULT",
          "categoryId": "6daa3dfd-3526-407a-88f0-97824b3f3a24",
          "createTime": 1517966743569,
          "encrypted": "false",
          "engineId": "3",
          "helpString": "Ncube License Manager",
          "ignoreConflict": false,
          "lastUpdateTime": 1517966743569,
          "longDescription": "nCube License Manager is a service used by nCube, a series of parallel computing computers. nCube used server class chips manufactured by a third party in massively parallel hardware deployments, primarily for the purposes of on-demand video. nCube was aquired and is now a part of the telecommunications company AARIS.",
          "name": "ncube-lm",
          "nbarId": "1389",
          "p2pTechnology": "false",
          "popularity": 1,
          "rank": 65535,
          "references": "http://www.ncube.com/",
          "selectorId": "1521",
          "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
          "trafficClass": "BULK_DATA",
          "tunnel": "false"
        }
      ],
      "networkIdentity": [
        {
          "id": "e5702027-c92e-483d-848e-37fc9d232184",
          "instanceId": 14114,
          "authEntityId": 14114,
          "displayName": "14114",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "1521",
          "protocol": "TCP",
          "upperPort": 0
        },
        {
          "id": "d38b2627-1304-4a73-828d-fd0e9a378151",
          "instanceId": 14115,
          "authEntityId": 14115,
          "displayName": "14115",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "1521",
          "protocol": "UDP",
          "upperPort": 0
        }
      ],
      "parentScalableGroup": {
        "idRef": "000c15bf-28fe-4fbb-9b69-35e6502721f1"
      },
      "user": [

      ],
      "userGroup": [

      ]
    },
    {
      "id": "ce31cc0d-1c5d-4433-95e8-7b8d64b2e803",
      "instanceId": 4191,
      "authEntityId": 4191,
      "displayName": "4191",
      "authEntityClass": -1909073334,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "createTime": 1517966741628,
      "deployed": false,
      "isSeeded": true,
      "isStale": false,
      "lastUpdateTime": 1517966741628,
      "name": "blizwow",
      "namespace": "scalablegroup:application",
      "provisioningState": "DEFINED",
      "qualifier": "application",
      "resourceVersion": 0,
      "targetIdList": [

      ],
      "type": "scalablegroup",
      "cfsChangeInfo": [

      ],
      "customProvisions": [

      ],
      "scalableGroupExternalHandle": "blizwow",
      "scalableGroupType": "APPLICATION",
      "securityGroupTag": 0,
      "state": "ACTIVE",
      "vnAgnostic": true,
      "identitySource": {
        "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
        "instanceId": 10010,
        "authEntityId": 10010,
        "displayName": "10010",
        "authEntityClass": 1738953278,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "state": "INACTIVE",
        "type": "NBAR"
      },
      "indicativeNetworkIdentity": [
        {
          "id": "f0f291e4-94cd-4711-b607-dc7b402c1ad6",
          "instanceId": 12411,
          "authEntityId": 12411,
          "displayName": "12411",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "3724",
          "protocol": "TCP",
          "upperPort": 0
        },
        {
          "id": "f9142462-c2aa-4588-9820-d1031994e41d",
          "instanceId": 12412,
          "authEntityId": 12412,
          "displayName": "12412",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "3724",
          "protocol": "UDP",
          "upperPort": 0
        }
      ],
      "networkApplications": [
        {
          "id": "bf2c4601-b1b5-4756-9284-116039328638",
          "instanceId": 11149,
          "authEntityId": 11149,
          "displayName": "11149",
          "authEntityClass": -217092956,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "appProtocol": "tcp/udp",
          "applicationGroup": "other",
          "applicationType": "DEFAULT",
          "categoryId": "3cd50dd8-1b2e-46c1-b672-41c14bfa364b",
          "createTime": 1517966741628,
          "encrypted": "false",
          "engineId": "3",
          "helpString": "World of Warcraft Gaming Protocol",
          "ignoreConflict": false,
          "lastUpdateTime": 1517966741628,
          "longDescription": "World of warcraft (WOW) is a massive multiplayer online role playing gaming protocol that was develped by Blizzard Entertainment.",
          "name": "blizwow",
          "nbarId": "85",
          "p2pTechnology": "false",
          "popularity": 7,
          "rank": 65535,
          "references": "http://us.battle.net/wow/en/",
          "selectorId": "3724",
          "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
          "trafficClass": "MULTIMEDIA_CONFERENCING",
          "tunnel": "false"
        }
      ],
      "networkIdentity": [
        {
          "id": "64f34d15-cbc7-4ab2-b112-aabbf04ac247",
          "instanceId": 12409,
          "authEntityId": 12409,
          "displayName": "12409",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "3724",
          "protocol": "UDP",
          "upperPort": 0
        },
        {
          "id": "20166f19-2c26-4f14-ba02-151b036cc4b5",
          "instanceId": 12410,
          "authEntityId": 12410,
          "displayName": "12410",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "3724",
          "protocol": "TCP",
          "upperPort": 0
        }
      ],
      "parentScalableGroup": {
        "idRef": "0bd0e6c9-5083-492b-97cb-a88928bee99c"
      },
      "user": [

      ],
      "userGroup": [

      ]
    },
    {
      "id": "ce48a062-14ae-4e0e-8709-6644b5cf6003",
      "instanceId": 15077,
      "authEntityId": 15077,
      "displayName": "15077",
      "authEntityClass": -1909073334,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "createTime": 1517966744181,
      "deployed": false,
      "isSeeded": true,
      "isStale": false,
      "lastUpdateTime": 1517966744181,
      "name": "rtp",
      "namespace": "scalablegroup:application",
      "provisioningState": "DEFINED",
      "qualifier": "application",
      "resourceVersion": 0,
      "targetIdList": [

      ],
      "type": "scalablegroup",
      "cfsChangeInfo": [

      ],
      "customProvisions": [

      ],
      "scalableGroupExternalHandle": "rtp",
      "scalableGroupType": "APPLICATION",
      "securityGroupTag": 0,
      "state": "ACTIVE",
      "vnAgnostic": true,
      "identitySource": {
        "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
        "instanceId": 10010,
        "authEntityId": 10010,
        "displayName": "10010",
        "authEntityClass": 1738953278,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "state": "INACTIVE",
        "type": "NBAR"
      },
      "indicativeNetworkIdentity": [

      ],
      "networkApplications": [
        {
          "id": "a5293b29-f49b-4393-8783-43d8668ae3c6",
          "instanceId": 16020,
          "authEntityId": 16020,
          "displayName": "16020",
          "authEntityClass": -217092956,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "appProtocol": "tcp/udp",
          "applicationGroup": "other",
          "applicationType": "DEFAULT",
          "categoryId": "38a8e354-e2fe-4f3c-b1c5-2bbb90fb0d99",
          "createTime": 1517966744181,
          "encrypted": "false",
          "engineId": "13",
          "helpString": "Real Time Protocol",
          "ignoreConflict": false,
          "lastUpdateTime": 1517966744181,
          "longDescription": "Real-time Transport Protocol (RTP) is used for streaming video and audio in real time for various applications. RTP works in conjunction with some streaming control protocols like RTCP, SIP, H.225 or H.245.",
          "name": "rtp",
          "nbarId": "61",
          "p2pTechnology": "false",
          "popularity": 10,
          "rank": 65535,
          "references": "http://tools.ietf.org/html/rfc3550",
          "selectorId": "61",
          "subCategory": "53ce0342-1e2d-455a-acc7-1b50db9d6845",
          "trafficClass": "MULTIMEDIA_CONFERENCING",
          "tunnel": "false"
        }
      ],
      "networkIdentity": [
        {
          "id": "3d9f708b-741e-402b-8b6e-bbf9a56a1b3c",
          "instanceId": 14868,
          "authEntityId": 14868,
          "displayName": "14868",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "3478,5060",
          "protocol": "UDP",
          "upperPort": 0
        },
        {
          "id": "4c0f801d-2671-4617-acfb-9a07c7ebf87b",
          "instanceId": 14869,
          "authEntityId": 14869,
          "displayName": "14869",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "3478,5060",
          "protocol": "TCP",
          "upperPort": 0
        }
      ],
      "parentScalableGroup": {
        "idRef": "60f1364d-2a02-4063-8ba1-07817abe0612"
      },
      "user": [

      ],
      "userGroup": [

      ]
    },
    {
      "id": "ce82ed22-93c8-4b91-9de7-aff0ea6660e2",
      "instanceId": 15277,
      "authEntityId": 15277,
      "displayName": "15277",
      "authEntityClass": -1909073334,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "createTime": 1517966744640,
      "deployed": false,
      "isSeeded": true,
      "isStale": false,
      "lastUpdateTime": 1517966744640,
      "name": "the-atlantic",
      "namespace": "scalablegroup:application",
      "provisioningState": "DEFINED",
      "qualifier": "application",
      "resourceVersion": 0,
      "targetIdList": [

      ],
      "type": "scalablegroup",
      "cfsChangeInfo": [

      ],
      "customProvisions": [

      ],
      "scalableGroupExternalHandle": "the-atlantic",
      "scalableGroupType": "APPLICATION",
      "securityGroupTag": 0,
      "state": "ACTIVE",
      "vnAgnostic": true,
      "identitySource": {
        "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
        "instanceId": 10010,
        "authEntityId": 10010,
        "displayName": "10010",
        "authEntityClass": 1738953278,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "state": "INACTIVE",
        "type": "NBAR"
      },
      "indicativeNetworkIdentity": [

      ],
      "networkApplications": [
        {
          "id": "2405f1c4-a71a-4411-829f-627f0513ed84",
          "instanceId": 16220,
          "authEntityId": 16220,
          "displayName": "16220",
          "authEntityClass": -217092956,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "appProtocol": "tcp/udp",
          "applicationGroup": "other",
          "applicationType": "DEFAULT",
          "categoryId": "878644b5-fe91-45b4-9204-31c84a73ba48",
          "createTime": 1517966744640,
          "encrypted": "false",
          "engineId": "13",
          "helpString": "The Atlantic - News portal.",
          "ignoreConflict": false,
          "lastUpdateTime": 1517966744640,
          "longDescription": "The Atlantic - News portal.",
          "name": "the-atlantic",
          "nbarId": "1706",
          "p2pTechnology": "false",
          "popularity": 3,
          "rank": 65535,
          "references": "http://theatlantic.com",
          "selectorId": "776",
          "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
          "trafficClass": "TRANSACTIONAL_DATA",
          "tunnel": "false"
        }
      ],
      "networkIdentity": [
        {
          "id": "77178c8f-8a11-4779-90aa-a6352478bf6b",
          "instanceId": 17461,
          "authEntityId": 17461,
          "displayName": "17461",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "53,80,443,5353",
          "protocol": "TCP",
          "upperPort": 0
        },
        {
          "id": "250e3cb7-3183-45c6-b1be-3308d04102d8",
          "instanceId": 17462,
          "authEntityId": 17462,
          "displayName": "17462",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "53,5353",
          "protocol": "UDP",
          "upperPort": 0
        }
      ],
      "parentScalableGroup": {
        "idRef": "28e08de4-d7b2-4521-84ce-bc1e5ee0b9cc"
      },
      "user": [

      ],
      "userGroup": [

      ]
    },
    {
      "id": "ceb0b42b-c9f0-4223-b6f8-d779385ba322",
      "instanceId": 4943,
      "authEntityId": 4943,
      "displayName": "4943",
      "authEntityClass": -1909073334,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "createTime": 1517966743866,
      "deployed": false,
      "isSeeded": true,
      "isStale": false,
      "lastUpdateTime": 1517966743866,
      "name": "pcanywhere",
      "namespace": "scalablegroup:application",
      "provisioningState": "DEFINED",
      "qualifier": "application",
      "resourceVersion": 0,
      "targetIdList": [

      ],
      "type": "scalablegroup",
      "cfsChangeInfo": [

      ],
      "customProvisions": [

      ],
      "scalableGroupExternalHandle": "pcanywhere",
      "scalableGroupType": "APPLICATION",
      "securityGroupTag": 0,
      "state": "ACTIVE",
      "vnAgnostic": true,
      "identitySource": {
        "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
        "instanceId": 10010,
        "authEntityId": 10010,
        "displayName": "10010",
        "authEntityClass": 1738953278,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "state": "INACTIVE",
        "type": "NBAR"
      },
      "indicativeNetworkIdentity": [

      ],
      "networkApplications": [
        {
          "id": "fc3b1b95-729f-4232-aa80-d3ea3ab36ead",
          "instanceId": 11892,
          "authEntityId": 11892,
          "displayName": "11892",
          "authEntityClass": -217092956,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "applicationGroup": "other",
          "applicationType": "DEFAULT",
          "categoryId": "a09ef1b3-ba26-4372-aee8-309d50697e76",
          "createTime": 1517966743866,
          "encrypted": "true",
          "engineId": "13",
          "helpString": "DEPRECATED, traffic will not match",
          "ignoreConflict": false,
          "lastUpdateTime": 1517966743866,
          "longDescription": "Deprecated: pcAnywhere lets users connect to another computer for remote desktop access. The protocol uses UDP for control, typically on port 5361 and a TCP connection for transferring the data, typically on port 5362.",
          "name": "pcanywhere",
          "nbarId": "32",
          "p2pTechnology": "false",
          "popularity": 6,
          "rank": 65535,
          "references": "http://www.symantec.com/pcanywhere",
          "selectorId": "32",
          "subCategory": "ff664205-64f3-447e-acff-1ec1ba9a6c31",
          "trafficClass": "MULTIMEDIA_STREAMING",
          "tunnel": "false"
        }
      ],
      "networkIdentity": [

      ],
      "parentScalableGroup": {
        "idRef": "fd6264c9-c16a-4311-a335-a3db039256f3"
      },
      "user": [

      ],
      "userGroup": [

      ]
    },
    {
      "id": "cf04f152-7f90-405a-914f-65dc4a54b0be",
      "instanceId": 4392,
      "authEntityId": 4392,
      "displayName": "4392",
      "authEntityClass": -1909073334,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "createTime": 1517966742519,
      "deployed": false,
      "isSeeded": true,
      "isStale": false,
      "lastUpdateTime": 1517966742519,
      "name": "dtag-ste-sb",
      "namespace": "scalablegroup:application",
      "provisioningState": "DEFINED",
      "qualifier": "application",
      "resourceVersion": 0,
      "targetIdList": [

      ],
      "type": "scalablegroup",
      "cfsChangeInfo": [

      ],
      "customProvisions": [

      ],
      "scalableGroupExternalHandle": "dtag-ste-sb",
      "scalableGroupType": "APPLICATION",
      "securityGroupTag": 0,
      "state": "ACTIVE",
      "vnAgnostic": true,
      "identitySource": {
        "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
        "instanceId": 10010,
        "authEntityId": 10010,
        "displayName": "10010",
        "authEntityClass": 1738953278,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "state": "INACTIVE",
        "type": "NBAR"
      },
      "indicativeNetworkIdentity": [
        {
          "id": "eb04d2ae-6382-4f05-8843-17c0f34bfe7e",
          "instanceId": 12972,
          "authEntityId": 12972,
          "displayName": "12972",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "352",
          "protocol": "UDP",
          "upperPort": 0
        },
        {
          "id": "47d8a2b5-1c0f-4197-b63a-01a59e40f5db",
          "instanceId": 12973,
          "authEntityId": 12973,
          "displayName": "12973",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "352",
          "protocol": "TCP",
          "upperPort": 0
        }
      ],
      "networkApplications": [
        {
          "id": "216dcea4-4134-4d98-8865-a16f9759e62d",
          "instanceId": 11343,
          "authEntityId": 11343,
          "displayName": "11343",
          "authEntityClass": -217092956,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "appProtocol": "tcp/udp",
          "applicationGroup": "other",
          "applicationType": "DEFAULT",
          "categoryId": "6daa3dfd-3526-407a-88f0-97824b3f3a24",
          "createTime": 1517966742519,
          "encrypted": "false",
          "engineId": "3",
          "helpString": "DTAG",
          "ignoreConflict": false,
          "lastUpdateTime": 1517966742519,
          "longDescription": "Registered with IANA on port 352 TCP/UDP",
          "name": "dtag-ste-sb",
          "nbarId": "268",
          "p2pTechnology": "false",
          "popularity": 1,
          "rank": 65535,
          "references": "http://www.iana.org/assignments/service-names-port-numbers/service-names-port-numbers.xml",
          "selectorId": "352",
          "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
          "trafficClass": "BULK_DATA",
          "tunnel": "false"
        }
      ],
      "networkIdentity": [
        {
          "id": "32cffd98-c678-4905-89ab-d204c95971cc",
          "instanceId": 12970,
          "authEntityId": 12970,
          "displayName": "12970",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "352",
          "protocol": "TCP",
          "upperPort": 0
        },
        {
          "id": "596d43b0-ce54-4215-847d-020cdca0e460",
          "instanceId": 12971,
          "authEntityId": 12971,
          "displayName": "12971",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "352",
          "protocol": "UDP",
          "upperPort": 0
        }
      ],
      "parentScalableGroup": {
        "idRef": "000c15bf-28fe-4fbb-9b69-35e6502721f1"
      },
      "user": [

      ],
      "userGroup": [

      ]
    },
    {
      "id": "cf2bfa5b-e0d7-4122-bc66-15033df88dc7",
      "instanceId": 15396,
      "authEntityId": 15396,
      "displayName": "15396",
      "authEntityClass": -1909073334,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "createTime": 1517966744869,
      "deployed": false,
      "isSeeded": true,
      "isStale": false,
      "lastUpdateTime": 1517966744869,
      "name": "web-rtc",
      "namespace": "scalablegroup:application",
      "provisioningState": "DEFINED",
      "qualifier": "application",
      "resourceVersion": 0,
      "targetIdList": [

      ],
      "type": "scalablegroup",
      "cfsChangeInfo": [

      ],
      "customProvisions": [

      ],
      "scalableGroupExternalHandle": "web-rtc",
      "scalableGroupType": "APPLICATION",
      "securityGroupTag": 0,
      "state": "ACTIVE",
      "vnAgnostic": true,
      "identitySource": {
        "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
        "instanceId": 10010,
        "authEntityId": 10010,
        "displayName": "10010",
        "authEntityClass": 1738953278,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "state": "INACTIVE",
        "type": "NBAR"
      },
      "indicativeNetworkIdentity": [

      ],
      "networkApplications": [
        {
          "id": "7e4b0ae3-1eaf-42d8-a44a-9cd26a8ee151",
          "instanceId": 16339,
          "authEntityId": 16339,
          "displayName": "16339",
          "authEntityClass": -217092956,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "applicationGroup": "other",
          "applicationType": "DEFAULT",
          "categoryId": "38a8e354-e2fe-4f3c-b1c5-2bbb90fb0d99",
          "createTime": 1517966744869,
          "encrypted": "false",
          "engineId": "13",
          "helpString": "web rtc conferencing",
          "ignoreConflict": false,
          "lastUpdateTime": 1517966744869,
          "longDescription": "WebRTC provides browsers and mobile applications with Real-Time Communications (RTC) capabilities.",
          "name": "web-rtc",
          "nbarId": "1054",
          "p2pTechnology": "false",
          "popularity": 10,
          "rank": 65535,
          "references": "www.webrtc.org",
          "selectorId": "1768",
          "subCategory": "53ce0342-1e2d-455a-acc7-1b50db9d6845",
          "trafficClass": "MULTIMEDIA_CONFERENCING",
          "tunnel": "false"
        }
      ],
      "networkIdentity": [

      ],
      "parentScalableGroup": {
        "idRef": "aad510ce-fe95-4dfc-ad06-e494641cc016"
      },
      "user": [

      ],
      "userGroup": [

      ]
    },
    {
      "id": "cf9b0c64-b327-4dfa-9094-7aef4953c757",
      "instanceId": 4873,
      "authEntityId": 4873,
      "displayName": "4873",
      "authEntityClass": -1909073334,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "createTime": 1517966743657,
      "deployed": false,
      "isSeeded": true,
      "isStale": false,
      "lastUpdateTime": 1517966743657,
      "name": "noaa",
      "namespace": "scalablegroup:application",
      "provisioningState": "DEFINED",
      "qualifier": "application",
      "resourceVersion": 0,
      "targetIdList": [

      ],
      "type": "scalablegroup",
      "cfsChangeInfo": [

      ],
      "customProvisions": [

      ],
      "scalableGroupExternalHandle": "noaa",
      "scalableGroupType": "APPLICATION",
      "securityGroupTag": 0,
      "state": "ACTIVE",
      "vnAgnostic": true,
      "identitySource": {
        "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
        "instanceId": 10010,
        "authEntityId": 10010,
        "displayName": "10010",
        "authEntityClass": 1738953278,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "state": "INACTIVE",
        "type": "NBAR"
      },
      "indicativeNetworkIdentity": [

      ],
      "networkApplications": [
        {
          "id": "837261e1-aaa2-4ed5-bc27-1f23d406b833",
          "instanceId": 11822,
          "authEntityId": 11822,
          "displayName": "11822",
          "authEntityClass": -217092956,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "appProtocol": "tcp/udp",
          "applicationGroup": "other",
          "applicationType": "DEFAULT",
          "categoryId": "878644b5-fe91-45b4-9204-31c84a73ba48",
          "createTime": 1517966743657,
          "encrypted": "false",
          "engineId": "13",
          "helpString": "NOAA - Ocean and Atmospheric research agency.",
          "ignoreConflict": false,
          "lastUpdateTime": 1517966743657,
          "longDescription": "NOAA - Ocean and Atmospheric research agency.",
          "name": "noaa",
          "nbarId": "1721",
          "p2pTechnology": "false",
          "popularity": 3,
          "rank": 65535,
          "references": "http://noaa.gov",
          "selectorId": "793",
          "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
          "trafficClass": "TRANSACTIONAL_DATA",
          "tunnel": "false"
        }
      ],
      "networkIdentity": [
        {
          "id": "0900e348-cf62-457c-84c7-3190b08dd8b8",
          "instanceId": 14262,
          "authEntityId": 14262,
          "displayName": "14262",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "53,80,443,5353",
          "protocol": "TCP",
          "upperPort": 0
        },
        {
          "id": "1b9f4950-a0b5-4bd6-9997-925c326a5e57",
          "instanceId": 14263,
          "authEntityId": 14263,
          "displayName": "14263",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "53,5353",
          "protocol": "UDP",
          "upperPort": 0
        }
      ],
      "parentScalableGroup": {
        "idRef": "28e08de4-d7b2-4521-84ce-bc1e5ee0b9cc"
      },
      "user": [

      ],
      "userGroup": [

      ]
    },
    {
      "id": "cf9db65e-1fbb-455b-a179-e026fad91865",
      "instanceId": 15092,
      "authEntityId": 15092,
      "displayName": "15092",
      "authEntityClass": -1909073334,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "createTime": 1517966744215,
      "deployed": false,
      "isSeeded": true,
      "isStale": false,
      "lastUpdateTime": 1517966744215,
      "name": "sat-mon",
      "namespace": "scalablegroup:application",
      "provisioningState": "DEFINED",
      "qualifier": "application",
      "resourceVersion": 0,
      "targetIdList": [

      ],
      "type": "scalablegroup",
      "cfsChangeInfo": [

      ],
      "customProvisions": [

      ],
      "scalableGroupExternalHandle": "sat-mon",
      "scalableGroupType": "APPLICATION",
      "securityGroupTag": 0,
      "state": "ACTIVE",
      "vnAgnostic": true,
      "identitySource": {
        "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
        "instanceId": 10010,
        "authEntityId": 10010,
        "displayName": "10010",
        "authEntityClass": 1738953278,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "state": "INACTIVE",
        "type": "NBAR"
      },
      "indicativeNetworkIdentity": [
        {
          "id": "182f91cf-7f41-4d59-b96a-d97e9eebfa60",
          "instanceId": 14911,
          "authEntityId": 14911,
          "displayName": "14911",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipProtocolNumbers": "69",
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "protocol": "SAT_MON",
          "upperPort": 0
        }
      ],
      "networkApplications": [
        {
          "id": "05e5249b-0e28-4fef-8a90-3c32d3545dda",
          "instanceId": 16035,
          "authEntityId": 16035,
          "displayName": "16035",
          "authEntityClass": -217092956,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "appProtocol": "ip",
          "applicationGroup": "other",
          "applicationType": "DEFAULT",
          "categoryId": "6daa3dfd-3526-407a-88f0-97824b3f3a24",
          "createTime": 1517966744215,
          "encrypted": "unassigned",
          "engineId": "1",
          "helpString": "SATNET Monitoring",
          "ignoreConflict": false,
          "lastUpdateTime": 1517966744215,
          "longDescription": "SATNET Monitoring is a protocol used for the monitoring and control of multiple-access satellite networks. The protocol covers the monitoring of network performance, the modification of network parameters whenever necessary, the coordination of network usage, the collection of status reports and performance statistics from individual SATNET nodes, and more.",
          "name": "sat-mon",
          "nbarId": "823",
          "p2pTechnology": "unassigned",
          "popularity": 2,
          "rank": 65535,
          "references": "http://adsabs.harvard.edu/abs/1979ntc.....3...45M",
          "selectorId": "69",
          "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
          "trafficClass": "BULK_DATA",
          "tunnel": "unassigned"
        }
      ],
      "networkIdentity": [
        {
          "id": "86b1f0f2-3e08-43bf-81ac-0c8e09dc65d8",
          "instanceId": 14910,
          "authEntityId": 14910,
          "displayName": "14910",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipProtocolNumbers": "69",
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "protocol": "SAT_MON",
          "upperPort": 0
        }
      ],
      "parentScalableGroup": {
        "idRef": "000c15bf-28fe-4fbb-9b69-35e6502721f1"
      },
      "user": [

      ],
      "userGroup": [

      ]
    },
    {
      "id": "cfcb406e-a987-4401-a637-c869826c6c2d",
      "instanceId": 4951,
      "authEntityId": 4951,
      "displayName": "4951",
      "authEntityClass": -1909073334,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "createTime": 1517966743887,
      "deployed": false,
      "isSeeded": true,
      "isStale": false,
      "lastUpdateTime": 1517966743887,
      "name": "perforce",
      "namespace": "scalablegroup:application",
      "provisioningState": "DEFINED",
      "qualifier": "application",
      "resourceVersion": 0,
      "targetIdList": [

      ],
      "type": "scalablegroup",
      "cfsChangeInfo": [

      ],
      "customProvisions": [

      ],
      "scalableGroupExternalHandle": "perforce",
      "scalableGroupType": "APPLICATION",
      "securityGroupTag": 0,
      "state": "ACTIVE",
      "vnAgnostic": true,
      "identitySource": {
        "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
        "instanceId": 10010,
        "authEntityId": 10010,
        "displayName": "10010",
        "authEntityClass": 1738953278,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "state": "INACTIVE",
        "type": "NBAR"
      },
      "indicativeNetworkIdentity": [

      ],
      "networkApplications": [
        {
          "id": "2782ea74-04c6-49c0-9a22-448235b3f2e4",
          "instanceId": 11900,
          "authEntityId": 11900,
          "displayName": "11900",
          "authEntityClass": -217092956,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "appProtocol": "tcp",
          "applicationGroup": "other",
          "applicationType": "DEFAULT",
          "categoryId": "7e19fdea-6222-4c57-9e66-66c2340d6bc3",
          "createTime": 1517966743887,
          "encrypted": "false",
          "engineId": "13",
          "helpString": "Software Version Management",
          "ignoreConflict": false,
          "lastUpdateTime": 1517966743887,
          "longDescription": "Perforce is a commercial proprietary Revision Control (RC) system. The Perforce system is based on a client/server model with the server managing the collection of source versions in one or more depots.",
          "name": "perforce",
          "nbarId": "1415",
          "p2pTechnology": "false",
          "popularity": 7,
          "rank": 65535,
          "references": "http://www.perforce.com/documentation/perforce_technical_documentation",
          "selectorId": "486",
          "subCategory": "b90786f4-0190-45b8-9924-21b3e9682590",
          "trafficClass": "TRANSACTIONAL_DATA",
          "tunnel": "false"
        }
      ],
      "networkIdentity": [
        {
          "id": "9eee0b61-b158-40ef-a089-49cd31c75118",
          "instanceId": 14511,
          "authEntityId": 14511,
          "displayName": "14511",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "80",
          "protocol": "TCP",
          "upperPort": 0
        }
      ],
      "parentScalableGroup": {
        "idRef": "fdbaf614-da09-4ba2-89bb-d1ca9e379597"
      },
      "user": [

      ],
      "userGroup": [

      ]
    },
    {
      "id": "cfd02c41-fa67-4ab6-9c2d-600a17d463b8",
      "instanceId": 4993,
      "authEntityId": 4993,
      "displayName": "4993",
      "authEntityClass": -1909073334,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "createTime": 1517966743999,
      "deployed": false,
      "isSeeded": true,
      "isStale": false,
      "lastUpdateTime": 1517966743999,
      "name": "ptp",
      "namespace": "scalablegroup:application",
      "provisioningState": "DEFINED",
      "qualifier": "application",
      "resourceVersion": 0,
      "targetIdList": [

      ],
      "type": "scalablegroup",
      "cfsChangeInfo": [

      ],
      "customProvisions": [

      ],
      "scalableGroupExternalHandle": "ptp",
      "scalableGroupType": "APPLICATION",
      "securityGroupTag": 0,
      "state": "ACTIVE",
      "vnAgnostic": true,
      "identitySource": {
        "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
        "instanceId": 10010,
        "authEntityId": 10010,
        "displayName": "10010",
        "authEntityClass": 1738953278,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "state": "INACTIVE",
        "type": "NBAR"
      },
      "indicativeNetworkIdentity": [
        {
          "id": "539c28d3-0d3b-4f1e-ba32-56183b122615",
          "instanceId": 14635,
          "authEntityId": 14635,
          "displayName": "14635",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipProtocolNumbers": "123",
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "protocol": "PTP",
          "upperPort": 0
        }
      ],
      "networkApplications": [
        {
          "id": "33691a32-dec4-4c0d-a771-47e9068e355f",
          "instanceId": 11942,
          "authEntityId": 11942,
          "displayName": "11942",
          "authEntityClass": -217092956,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "appProtocol": "ip",
          "applicationGroup": "other",
          "applicationType": "DEFAULT",
          "categoryId": "6daa3dfd-3526-407a-88f0-97824b3f3a24",
          "createTime": 1517966743999,
          "encrypted": "unassigned",
          "engineId": "1",
          "helpString": "Performance Transparency Protocol",
          "ignoreConflict": false,
          "lastUpdateTime": 1517966743999,
          "longDescription": "Performance Transparency Protocol (PTP) is a means to efficiently retrieve performance related information from a path of routers between the sender and the receiver. For example, a single PTP packet could be used to determine the bottleneck bandwidth along such a path.",
          "name": "ptp",
          "nbarId": "877",
          "p2pTechnology": "unassigned",
          "popularity": 2,
          "rank": 65535,
          "references": "http://heim.ifi.uio.no/michawe/research/projects/ptp/",
          "selectorId": "123",
          "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
          "trafficClass": "BULK_DATA",
          "tunnel": "unassigned"
        }
      ],
      "networkIdentity": [
        {
          "id": "ebcd4b9f-957b-40af-a720-571c3810cb3f",
          "instanceId": 14634,
          "authEntityId": 14634,
          "displayName": "14634",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipProtocolNumbers": "123",
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "protocol": "PTP",
          "upperPort": 0
        }
      ],
      "parentScalableGroup": {
        "idRef": "000c15bf-28fe-4fbb-9b69-35e6502721f1"
      },
      "user": [

      ],
      "userGroup": [

      ]
    },
    {
      "id": "d005ecb2-1e00-4fc9-98a7-afa03bc636ee",
      "instanceId": 4319,
      "authEntityId": 4319,
      "displayName": "4319",
      "authEntityClass": -1909073334,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "createTime": 1517966742273,
      "deployed": false,
      "isSeeded": true,
      "isStale": false,
      "lastUpdateTime": 1517966742273,
      "name": "cycleserv2",
      "namespace": "scalablegroup:application",
      "provisioningState": "DEFINED",
      "qualifier": "application",
      "resourceVersion": 0,
      "targetIdList": [

      ],
      "type": "scalablegroup",
      "cfsChangeInfo": [

      ],
      "customProvisions": [

      ],
      "scalableGroupExternalHandle": "cycleserv2",
      "scalableGroupType": "APPLICATION",
      "securityGroupTag": 0,
      "state": "ACTIVE",
      "vnAgnostic": true,
      "identitySource": {
        "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
        "instanceId": 10010,
        "authEntityId": 10010,
        "displayName": "10010",
        "authEntityClass": 1738953278,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "state": "INACTIVE",
        "type": "NBAR"
      },
      "indicativeNetworkIdentity": [
        {
          "id": "a4fc5463-0436-4d00-a657-6110752aea83",
          "instanceId": 12736,
          "authEntityId": 12736,
          "displayName": "12736",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "772",
          "protocol": "UDP",
          "upperPort": 0
        },
        {
          "id": "15dfa55d-257f-4dda-b34f-12a3b782459d",
          "instanceId": 12737,
          "authEntityId": 12737,
          "displayName": "12737",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "772",
          "protocol": "TCP",
          "upperPort": 0
        }
      ],
      "networkApplications": [
        {
          "id": "41a77dc0-34b9-4be0-9835-030cef0d35ad",
          "instanceId": 11271,
          "authEntityId": 11271,
          "displayName": "11271",
          "authEntityClass": -217092956,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "appProtocol": "tcp/udp",
          "applicationGroup": "other",
          "applicationType": "DEFAULT",
          "categoryId": "1fc17362-9334-4c7b-b752-5afdbb574017",
          "createTime": 1517966742273,
          "encrypted": "false",
          "engineId": "3",
          "helpString": "cycleserv2",
          "ignoreConflict": false,
          "lastUpdateTime": 1517966742273,
          "longDescription": "Registered with IANA on port 772 TCP/UDP",
          "name": "cycleserv2",
          "nbarId": "642",
          "p2pTechnology": "false",
          "popularity": 1,
          "rank": 65535,
          "references": "http://www.iana.org/assignments/service-names-port-numbers/service-names-port-numbers.xml",
          "selectorId": "772",
          "subCategory": "f3c0f761-d894-4112-ab89-b90fc84fd660",
          "trafficClass": "OPS_ADMIN_MGMT",
          "tunnel": "false"
        }
      ],
      "networkIdentity": [
        {
          "id": "5c828901-18f5-482a-aba7-06bf99e27669",
          "instanceId": 12734,
          "authEntityId": 12734,
          "displayName": "12734",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "772",
          "protocol": "TCP",
          "upperPort": 0
        },
        {
          "id": "70b62868-ed62-4e29-b2e0-c7eb4475513b",
          "instanceId": 12735,
          "authEntityId": 12735,
          "displayName": "12735",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "772",
          "protocol": "UDP",
          "upperPort": 0
        }
      ],
      "parentScalableGroup": {
        "idRef": "5fcd74d4-8726-47f7-a088-c457863da7ef"
      },
      "user": [

      ],
      "userGroup": [

      ]
    },
    {
      "id": "d011a377-249f-4f52-a185-37f9a161c566",
      "instanceId": 15377,
      "authEntityId": 15377,
      "displayName": "15377",
      "authEntityClass": -1909073334,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "createTime": 1517966744836,
      "deployed": false,
      "isSeeded": true,
      "isStale": false,
      "lastUpdateTime": 1517966744836,
      "name": "wap-pushsecure",
      "namespace": "scalablegroup:application",
      "provisioningState": "DEFINED",
      "qualifier": "application",
      "resourceVersion": 0,
      "targetIdList": [

      ],
      "type": "scalablegroup",
      "cfsChangeInfo": [

      ],
      "customProvisions": [

      ],
      "scalableGroupExternalHandle": "wap-pushsecure",
      "scalableGroupType": "APPLICATION",
      "securityGroupTag": 0,
      "state": "ACTIVE",
      "vnAgnostic": true,
      "identitySource": {
        "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
        "instanceId": 10010,
        "authEntityId": 10010,
        "displayName": "10010",
        "authEntityClass": 1738953278,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "state": "INACTIVE",
        "type": "NBAR"
      },
      "indicativeNetworkIdentity": [
        {
          "id": "93c94355-b18b-49ec-b91c-b2f91190fca6",
          "instanceId": 17751,
          "authEntityId": 17751,
          "displayName": "17751",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "2949",
          "protocol": "UDP",
          "upperPort": 0
        },
        {
          "id": "454d80e0-5cfb-47ed-9824-f7619f23bcce",
          "instanceId": 17752,
          "authEntityId": 17752,
          "displayName": "17752",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "2949",
          "protocol": "TCP",
          "upperPort": 0
        }
      ],
      "networkApplications": [
        {
          "id": "40f9e69c-4a17-49fd-9614-a278e48fc5b1",
          "instanceId": 16320,
          "authEntityId": 16320,
          "displayName": "16320",
          "authEntityClass": -217092956,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "appProtocol": "tcp/udp",
          "applicationGroup": "wap-group",
          "applicationType": "DEFAULT",
          "categoryId": "6daa3dfd-3526-407a-88f0-97824b3f3a24",
          "createTime": 1517966744836,
          "encrypted": "false",
          "engineId": "3",
          "helpString": "WAP PUSH SECURE",
          "ignoreConflict": false,
          "lastUpdateTime": 1517966744836,
          "longDescription": "WAP Push Secure is the secured version of WAP Push. WAP Push is a protocol enabling the delivery of content to a mobile device with no prior user interaction.",
          "name": "wap-pushsecure",
          "nbarId": "726",
          "p2pTechnology": "false",
          "popularity": 2,
          "rank": 65535,
          "references": "http://www.lasalle.edu/~beatty/430/wireless/toolkit/wap_push_library/pdf/WAP_Push_Tech_Overview.pdf",
          "selectorId": "2949",
          "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
          "trafficClass": "BULK_DATA",
          "tunnel": "false"
        }
      ],
      "networkIdentity": [
        {
          "id": "294dd48e-3231-409c-b142-e15cba87ef36",
          "instanceId": 17749,
          "authEntityId": 17749,
          "displayName": "17749",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "2949",
          "protocol": "UDP",
          "upperPort": 0
        },
        {
          "id": "d3a00c7c-383f-47b0-803f-18c61b490610",
          "instanceId": 17750,
          "authEntityId": 17750,
          "displayName": "17750",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "2949",
          "protocol": "TCP",
          "upperPort": 0
        }
      ],
      "parentScalableGroup": {
        "idRef": "000c15bf-28fe-4fbb-9b69-35e6502721f1"
      },
      "user": [

      ],
      "userGroup": [

      ]
    },
    {
      "id": "d03c33bc-88a8-46ad-b809-264fae23e45c",
      "instanceId": 4875,
      "authEntityId": 4875,
      "displayName": "4875",
      "authEntityClass": -1909073334,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "createTime": 1517966743660,
      "deployed": false,
      "isSeeded": true,
      "isStale": false,
      "lastUpdateTime": 1517966743660,
      "name": "novadigm",
      "namespace": "scalablegroup:application",
      "provisioningState": "DEFINED",
      "qualifier": "application",
      "resourceVersion": 0,
      "targetIdList": [

      ],
      "type": "scalablegroup",
      "cfsChangeInfo": [

      ],
      "customProvisions": [

      ],
      "scalableGroupExternalHandle": "novadigm",
      "scalableGroupType": "APPLICATION",
      "securityGroupTag": 0,
      "state": "ACTIVE",
      "vnAgnostic": true,
      "identitySource": {
        "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
        "instanceId": 10010,
        "authEntityId": 10010,
        "displayName": "10010",
        "authEntityClass": 1738953278,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "state": "INACTIVE",
        "type": "NBAR"
      },
      "indicativeNetworkIdentity": [
        {
          "id": "0a78fcb4-2e16-4650-ad08-22fbfcb19453",
          "instanceId": 14266,
          "authEntityId": 14266,
          "displayName": "14266",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "3460,3461,3462,3463,3464,3465",
          "protocol": "TCP",
          "upperPort": 0
        },
        {
          "id": "6aff3a56-487a-4cbf-86a2-ae41d36c1239",
          "instanceId": 14267,
          "authEntityId": 14267,
          "displayName": "14267",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "3460,3461,3462,3463,3464,3465",
          "protocol": "UDP",
          "upperPort": 0
        }
      ],
      "networkApplications": [
        {
          "id": "ad45f7f9-ea92-482b-9e1f-917e64c7faee",
          "instanceId": 11824,
          "authEntityId": 11824,
          "displayName": "11824",
          "authEntityClass": -217092956,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "appProtocol": "tcp/udp",
          "applicationGroup": "other",
          "applicationType": "DEFAULT",
          "categoryId": "1fc17362-9334-4c7b-b752-5afdbb574017",
          "createTime": 1517966743660,
          "encrypted": "false",
          "engineId": "13",
          "helpString": "Novadigm EDM",
          "ignoreConflict": false,
          "lastUpdateTime": 1517966743660,
          "longDescription": "Novadigm Enterprise Desktop Manager (EDM) was a management platform for deploying enterprise applications, distributing software changes and standardizing systems administration. It is now part of the HP Client Automation software.",
          "name": "novadigm",
          "nbarId": "47",
          "p2pTechnology": "false",
          "popularity": 4,
          "rank": 65535,
          "references": "http://en.wikipedia.org/wiki/HP_Client_Automation_Software",
          "selectorId": "47",
          "subCategory": "787a7d77-977b-4974-89d3-379ba49e88b7",
          "trafficClass": "OPS_ADMIN_MGMT",
          "tunnel": "false"
        }
      ],
      "networkIdentity": [
        {
          "id": "a5d30fe5-7d1b-4b66-9744-84ac81799011",
          "instanceId": 14264,
          "authEntityId": 14264,
          "displayName": "14264",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "3460,3461,3462,3463,3464,3465",
          "protocol": "TCP",
          "upperPort": 0
        },
        {
          "id": "c9fab468-819d-4196-a7ea-4b362363e5f7",
          "instanceId": 14265,
          "authEntityId": 14265,
          "displayName": "14265",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "3460,3461,3462,3463,3464,3465",
          "protocol": "UDP",
          "upperPort": 0
        }
      ],
      "parentScalableGroup": {
        "idRef": "e06dd89f-0a70-4422-ab0b-c2314650a9a7"
      },
      "user": [

      ],
      "userGroup": [

      ]
    },
    {
      "id": "d0427cd5-f879-4ff0-95ba-0c794599b29b",
      "instanceId": 4554,
      "authEntityId": 4554,
      "displayName": "4554",
      "authEntityClass": -1909073334,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "createTime": 1517966742977,
      "deployed": false,
      "isSeeded": true,
      "isStale": false,
      "lastUpdateTime": 1517966742977,
      "name": "hp-managed-node",
      "namespace": "scalablegroup:application",
      "provisioningState": "DEFINED",
      "qualifier": "application",
      "resourceVersion": 0,
      "targetIdList": [

      ],
      "type": "scalablegroup",
      "cfsChangeInfo": [

      ],
      "customProvisions": [

      ],
      "scalableGroupExternalHandle": "hp-managed-node",
      "scalableGroupType": "APPLICATION",
      "securityGroupTag": 0,
      "state": "ACTIVE",
      "vnAgnostic": true,
      "identitySource": {
        "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
        "instanceId": 10010,
        "authEntityId": 10010,
        "displayName": "10010",
        "authEntityClass": 1738953278,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "state": "INACTIVE",
        "type": "NBAR"
      },
      "indicativeNetworkIdentity": [
        {
          "id": "0886803f-a8e1-4eb3-93b9-a8be22295898",
          "instanceId": 13376,
          "authEntityId": 13376,
          "displayName": "13376",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "382",
          "protocol": "TCP",
          "upperPort": 0
        },
        {
          "id": "d45602b4-ed8e-48d3-b4a4-d4856825d8e1",
          "instanceId": 13375,
          "authEntityId": 13375,
          "displayName": "13375",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "382",
          "protocol": "UDP",
          "upperPort": 0
        }
      ],
      "networkApplications": [
        {
          "id": "552c5d06-ae8d-4dc4-ac80-e680f8ffeb4f",
          "instanceId": 11505,
          "authEntityId": 11505,
          "displayName": "11505",
          "authEntityClass": -217092956,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "appProtocol": "tcp/udp",
          "applicationGroup": "other",
          "applicationType": "DEFAULT",
          "categoryId": "1fc17362-9334-4c7b-b752-5afdbb574017",
          "createTime": 1517966742977,
          "encrypted": "false",
          "engineId": "3",
          "helpString": "hp performance data managed node",
          "ignoreConflict": false,
          "lastUpdateTime": 1517966742977,
          "longDescription": "The HP OpenView Performance manager, agents, and monitor combine to provide flexible distributed management solution. This solution is a single interface for centrally monitoring, analyzing, and forecasting resource utilization for distributed multivendor environments.",
          "name": "hp-managed-node",
          "nbarId": "298",
          "p2pTechnology": "false",
          "popularity": 1,
          "rank": 65535,
          "references": "https://h20392.www2.hp.com/portal/swdepot/displayProductInfo.do?productNumber=PERFMINFO",
          "selectorId": "382",
          "subCategory": "f3c0f761-d894-4112-ab89-b90fc84fd660",
          "trafficClass": "OPS_ADMIN_MGMT",
          "tunnel": "false"
        }
      ],
      "networkIdentity": [
        {
          "id": "ff2710a1-1ed2-4eac-b376-37745ea6acb2",
          "instanceId": 13373,
          "authEntityId": 13373,
          "displayName": "13373",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "382",
          "protocol": "TCP",
          "upperPort": 0
        },
        {
          "id": "a8f26c8e-0c85-4751-8c52-f92930eb572d",
          "instanceId": 13374,
          "authEntityId": 13374,
          "displayName": "13374",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "382",
          "protocol": "UDP",
          "upperPort": 0
        }
      ],
      "parentScalableGroup": {
        "idRef": "5fcd74d4-8726-47f7-a088-c457863da7ef"
      },
      "user": [

      ],
      "userGroup": [

      ]
    },
    {
      "id": "d07f59e1-178f-4823-b093-2618e505a019",
      "instanceId": 15103,
      "authEntityId": 15103,
      "displayName": "15103",
      "authEntityClass": -1909073334,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "createTime": 1517966744241,
      "deployed": false,
      "isSeeded": true,
      "isStale": false,
      "lastUpdateTime": 1517966744241,
      "name": "scps",
      "namespace": "scalablegroup:application",
      "provisioningState": "DEFINED",
      "qualifier": "application",
      "resourceVersion": 0,
      "targetIdList": [

      ],
      "type": "scalablegroup",
      "cfsChangeInfo": [

      ],
      "customProvisions": [

      ],
      "scalableGroupExternalHandle": "scps",
      "scalableGroupType": "APPLICATION",
      "securityGroupTag": 0,
      "state": "ACTIVE",
      "vnAgnostic": true,
      "identitySource": {
        "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
        "instanceId": 10010,
        "authEntityId": 10010,
        "displayName": "10010",
        "authEntityClass": 1738953278,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "state": "INACTIVE",
        "type": "NBAR"
      },
      "indicativeNetworkIdentity": [
        {
          "id": "d87868b1-9e2d-4856-b776-2c1c46209b1d",
          "instanceId": 14949,
          "authEntityId": 14949,
          "displayName": "14949",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipProtocolNumbers": "105",
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "protocol": "SCPS",
          "upperPort": 0
        }
      ],
      "networkApplications": [
        {
          "id": "1d66a29f-5e39-4b40-a676-0b13e87e8dec",
          "instanceId": 16046,
          "authEntityId": 16046,
          "displayName": "16046",
          "authEntityClass": -217092956,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "appProtocol": "ip",
          "applicationGroup": "other",
          "applicationType": "DEFAULT",
          "categoryId": "6daa3dfd-3526-407a-88f0-97824b3f3a24",
          "createTime": 1517966744241,
          "encrypted": "unassigned",
          "engineId": "1",
          "helpString": "SCPS",
          "ignoreConflict": false,
          "lastUpdateTime": 1517966744241,
          "longDescription": "Space Communications Protocol Specifications (SCPS) are a set of extensions to existing protocols and new protocols developed by the Consultative Committee for Space Data Systems (CCSDS) to improve performance of Internet protocols in space environments.",
          "name": "scps",
          "nbarId": "859",
          "p2pTechnology": "unassigned",
          "popularity": 2,
          "rank": 65535,
          "references": "http://en.wikipedia.org/wiki/Space_Communications_Protocol_Specifications",
          "selectorId": "105",
          "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
          "trafficClass": "BULK_DATA",
          "tunnel": "unassigned"
        }
      ],
      "networkIdentity": [
        {
          "id": "7e51d492-18c3-4f5e-8c64-37ddbefc82de",
          "instanceId": 14948,
          "authEntityId": 14948,
          "displayName": "14948",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipProtocolNumbers": "105",
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "protocol": "SCPS",
          "upperPort": 0
        }
      ],
      "parentScalableGroup": {
        "idRef": "000c15bf-28fe-4fbb-9b69-35e6502721f1"
      },
      "user": [

      ],
      "userGroup": [

      ]
    },
    {
      "id": "d0a8da0e-fdbd-43a0-93e0-9aeaeae31b45",
      "instanceId": 4375,
      "authEntityId": 4375,
      "displayName": "4375",
      "authEntityClass": -1909073334,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "createTime": 1517966742450,
      "deployed": false,
      "isSeeded": true,
      "isStale": false,
      "lastUpdateTime": 1517966742450,
      "name": "dls",
      "namespace": "scalablegroup:application",
      "provisioningState": "DEFINED",
      "qualifier": "application",
      "resourceVersion": 0,
      "targetIdList": [

      ],
      "type": "scalablegroup",
      "cfsChangeInfo": [

      ],
      "customProvisions": [

      ],
      "scalableGroupExternalHandle": "dls",
      "scalableGroupType": "APPLICATION",
      "securityGroupTag": 0,
      "state": "ACTIVE",
      "vnAgnostic": true,
      "identitySource": {
        "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
        "instanceId": 10010,
        "authEntityId": 10010,
        "displayName": "10010",
        "authEntityClass": 1738953278,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "state": "INACTIVE",
        "type": "NBAR"
      },
      "indicativeNetworkIdentity": [
        {
          "id": "425f9167-91a4-4f4d-b40b-fdb596f2224c",
          "instanceId": 12913,
          "authEntityId": 12913,
          "displayName": "12913",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "197",
          "protocol": "TCP",
          "upperPort": 0
        },
        {
          "id": "b9dfab9a-cc6e-4b4a-882b-a1fe00848438",
          "instanceId": 12914,
          "authEntityId": 12914,
          "displayName": "12914",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "197",
          "protocol": "UDP",
          "upperPort": 0
        }
      ],
      "networkApplications": [
        {
          "id": "56931a2d-f820-4e80-93e1-373e79d2043f",
          "instanceId": 11326,
          "authEntityId": 11326,
          "displayName": "11326",
          "authEntityClass": -217092956,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "appProtocol": "tcp/udp",
          "applicationGroup": "other",
          "applicationType": "DEFAULT",
          "categoryId": "6daa3dfd-3526-407a-88f0-97824b3f3a24",
          "createTime": 1517966742450,
          "encrypted": "false",
          "engineId": "3",
          "helpString": "Directory Location Service",
          "ignoreConflict": false,
          "lastUpdateTime": 1517966742450,
          "longDescription": "Registered with IANA on port 197 TCP/UDP",
          "name": "dls",
          "nbarId": "1078",
          "p2pTechnology": "false",
          "popularity": 1,
          "rank": 65535,
          "references": "http://www.iana.org/assignments/service-names-port-numbers/service-names-port-numbers.xml",
          "selectorId": "197",
          "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
          "trafficClass": "BULK_DATA",
          "tunnel": "false"
        }
      ],
      "networkIdentity": [
        {
          "id": "8f36c675-8c95-43c1-bb94-ac47bf31cb34",
          "instanceId": 12912,
          "authEntityId": 12912,
          "displayName": "12912",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "197",
          "protocol": "TCP",
          "upperPort": 0
        },
        {
          "id": "18806d9f-49bc-4010-a3b0-7bf30d497579",
          "instanceId": 12911,
          "authEntityId": 12911,
          "displayName": "12911",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "197",
          "protocol": "UDP",
          "upperPort": 0
        }
      ],
      "parentScalableGroup": {
        "idRef": "000c15bf-28fe-4fbb-9b69-35e6502721f1"
      },
      "user": [

      ],
      "userGroup": [

      ]
    },
    {
      "id": "d1c0f282-0e99-4df8-b133-5034c732d942",
      "instanceId": 4072,
      "authEntityId": 4072,
      "displayName": "4072",
      "authEntityClass": -1909073334,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "createTime": 1517966740838,
      "deployed": false,
      "isSeeded": true,
      "isStale": false,
      "lastUpdateTime": 1517966740838,
      "name": "allrecipes",
      "namespace": "scalablegroup:application",
      "provisioningState": "DEFINED",
      "qualifier": "application",
      "resourceVersion": 0,
      "targetIdList": [

      ],
      "type": "scalablegroup",
      "cfsChangeInfo": [

      ],
      "customProvisions": [

      ],
      "scalableGroupExternalHandle": "allrecipes",
      "scalableGroupType": "APPLICATION",
      "securityGroupTag": 0,
      "state": "ACTIVE",
      "vnAgnostic": true,
      "identitySource": {
        "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
        "instanceId": 10010,
        "authEntityId": 10010,
        "displayName": "10010",
        "authEntityClass": 1738953278,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "state": "INACTIVE",
        "type": "NBAR"
      },
      "indicativeNetworkIdentity": [

      ],
      "networkApplications": [
        {
          "id": "43ad92c2-b222-4ea0-ac12-5c9353f7982a",
          "instanceId": 11040,
          "authEntityId": 11040,
          "displayName": "11040",
          "authEntityClass": -217092956,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "appProtocol": "tcp/udp",
          "applicationGroup": "other",
          "applicationType": "DEFAULT",
          "categoryId": "878644b5-fe91-45b4-9204-31c84a73ba48",
          "createTime": 1517966740838,
          "encrypted": "false",
          "engineId": "13",
          "helpString": "AllRecipes - Recipes and cooking guide.",
          "ignoreConflict": false,
          "lastUpdateTime": 1517966740838,
          "longDescription": "AllRecipes - Recipes and cooking guide.",
          "name": "allrecipes",
          "nbarId": "1671",
          "p2pTechnology": "false",
          "popularity": 3,
          "rank": 65535,
          "references": "http://allrecipes.com",
          "selectorId": "741",
          "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
          "trafficClass": "TRANSACTIONAL_DATA",
          "tunnel": "false"
        }
      ],
      "networkIdentity": [
        {
          "id": "f3b18031-3dbd-4801-81c9-a3f4d367f6d4",
          "instanceId": 12098,
          "authEntityId": 12098,
          "displayName": "12098",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "53,5353",
          "protocol": "UDP",
          "upperPort": 0
        },
        {
          "id": "51251109-ada2-435e-bab4-7461e67b2921",
          "instanceId": 12099,
          "authEntityId": 12099,
          "displayName": "12099",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "53,80,443,5353",
          "protocol": "TCP",
          "upperPort": 0
        }
      ],
      "parentScalableGroup": {
        "idRef": "28e08de4-d7b2-4521-84ce-bc1e5ee0b9cc"
      },
      "user": [

      ],
      "userGroup": [

      ]
    },
    {
      "id": "d1f100c6-ad84-4cc6-81ae-7a6d8e99292e",
      "instanceId": 15409,
      "authEntityId": 15409,
      "displayName": "15409",
      "authEntityClass": -1909073334,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "createTime": 1517966744914,
      "deployed": false,
      "isSeeded": true,
      "isStale": false,
      "lastUpdateTime": 1517966744914,
      "name": "wells-fargo",
      "namespace": "scalablegroup:application",
      "provisioningState": "DEFINED",
      "qualifier": "application",
      "resourceVersion": 0,
      "targetIdList": [

      ],
      "type": "scalablegroup",
      "cfsChangeInfo": [

      ],
      "customProvisions": [

      ],
      "scalableGroupExternalHandle": "wells-fargo",
      "scalableGroupType": "APPLICATION",
      "securityGroupTag": 0,
      "state": "ACTIVE",
      "vnAgnostic": true,
      "identitySource": {
        "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
        "instanceId": 10010,
        "authEntityId": 10010,
        "displayName": "10010",
        "authEntityClass": 1738953278,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "state": "INACTIVE",
        "type": "NBAR"
      },
      "indicativeNetworkIdentity": [

      ],
      "networkApplications": [
        {
          "id": "a075f17e-bbdf-47ce-9140-dd8f01b45a85",
          "instanceId": 16352,
          "authEntityId": 16352,
          "displayName": "16352",
          "authEntityClass": -217092956,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "appProtocol": "tcp/udp",
          "applicationGroup": "other",
          "applicationType": "DEFAULT",
          "categoryId": "878644b5-fe91-45b4-9204-31c84a73ba48",
          "createTime": 1517966744914,
          "encrypted": "false",
          "engineId": "13",
          "helpString": "Wells Fargo - Global financial services company.",
          "ignoreConflict": false,
          "lastUpdateTime": 1517966744914,
          "longDescription": "Wells Fargo - Global financial services company.",
          "name": "wells-fargo",
          "nbarId": "1579",
          "p2pTechnology": "false",
          "popularity": 3,
          "rank": 65535,
          "references": "http://wellsfargo.com",
          "selectorId": "650",
          "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
          "trafficClass": "TRANSACTIONAL_DATA",
          "tunnel": "false"
        }
      ],
      "networkIdentity": [
        {
          "id": "ee505d50-2dda-4d2f-9c66-8e1208f3cd0f",
          "instanceId": 17827,
          "authEntityId": 17827,
          "displayName": "17827",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "53,80,443,5353",
          "protocol": "TCP",
          "upperPort": 0
        },
        {
          "id": "13211ace-838e-4f2a-9c87-05378132ee70",
          "instanceId": 17828,
          "authEntityId": 17828,
          "displayName": "17828",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "53,5353",
          "protocol": "UDP",
          "upperPort": 0
        }
      ],
      "parentScalableGroup": {
        "idRef": "28e08de4-d7b2-4521-84ce-bc1e5ee0b9cc"
      },
      "user": [

      ],
      "userGroup": [

      ]
    },
    {
      "id": "d2413deb-03c0-44db-84d8-a314ba8a9441",
      "instanceId": 15207,
      "authEntityId": 15207,
      "displayName": "15207",
      "authEntityClass": -1909073334,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "createTime": 1517966744484,
      "deployed": false,
      "isSeeded": true,
      "isStale": false,
      "lastUpdateTime": 1517966744484,
      "name": "sshell",
      "namespace": "scalablegroup:application",
      "provisioningState": "DEFINED",
      "qualifier": "application",
      "resourceVersion": 0,
      "targetIdList": [

      ],
      "type": "scalablegroup",
      "cfsChangeInfo": [

      ],
      "customProvisions": [

      ],
      "scalableGroupExternalHandle": "sshell",
      "scalableGroupType": "APPLICATION",
      "securityGroupTag": 0,
      "state": "ACTIVE",
      "vnAgnostic": true,
      "identitySource": {
        "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
        "instanceId": 10010,
        "authEntityId": 10010,
        "displayName": "10010",
        "authEntityClass": 1738953278,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "state": "INACTIVE",
        "type": "NBAR"
      },
      "indicativeNetworkIdentity": [
        {
          "id": "b89d5256-13cd-4820-ae6d-5126f98175d3",
          "instanceId": 17262,
          "authEntityId": 17262,
          "displayName": "17262",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "614",
          "protocol": "TCP",
          "upperPort": 0
        },
        {
          "id": "f186a7c3-cd4a-46c6-99ed-e566c5be326e",
          "instanceId": 17263,
          "authEntityId": 17263,
          "displayName": "17263",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "614",
          "protocol": "UDP",
          "upperPort": 0
        }
      ],
      "networkApplications": [
        {
          "id": "cae89ce1-d47f-49ce-b7ce-873d423c779c",
          "instanceId": 16150,
          "authEntityId": 16150,
          "displayName": "16150",
          "authEntityClass": -217092956,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "appProtocol": "tcp/udp",
          "applicationGroup": "other",
          "applicationType": "DEFAULT",
          "categoryId": "1fc17362-9334-4c7b-b752-5afdbb574017",
          "createTime": 1517966744484,
          "encrypted": "true",
          "engineId": "3",
          "helpString": "SSL Shell establishes a Secure Shell connection to a server",
          "ignoreConflict": false,
          "lastUpdateTime": 1517966744484,
          "longDescription": "SSL Shell establishes a Secure Shell connection to a server and starts up the user's default shell.",
          "name": "sshell",
          "nbarId": "523",
          "p2pTechnology": "false",
          "popularity": 2,
          "rank": 65535,
          "references": "http://www.nsoftware.com/products/component/sshell.aspx",
          "selectorId": "614",
          "subCategory": "787a7d77-977b-4974-89d3-379ba49e88b7",
          "trafficClass": "OPS_ADMIN_MGMT",
          "tunnel": "false"
        }
      ],
      "networkIdentity": [
        {
          "id": "ead28474-b00b-4773-b729-9ef28682ac0a",
          "instanceId": 17260,
          "authEntityId": 17260,
          "displayName": "17260",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "614",
          "protocol": "UDP",
          "upperPort": 0
        },
        {
          "id": "564bb110-b14d-47c5-8248-7939552dbc12",
          "instanceId": 17261,
          "authEntityId": 17261,
          "displayName": "17261",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "614",
          "protocol": "TCP",
          "upperPort": 0
        }
      ],
      "parentScalableGroup": {
        "idRef": "e06dd89f-0a70-4422-ab0b-c2314650a9a7"
      },
      "user": [

      ],
      "userGroup": [

      ]
    },
    {
      "id": "d252ba9e-2389-4a2b-92e7-b318d68fafbd",
      "instanceId": 4974,
      "authEntityId": 4974,
      "displayName": "4974",
      "authEntityClass": -1909073334,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "createTime": 1517966743948,
      "deployed": false,
      "isSeeded": true,
      "isStale": false,
      "lastUpdateTime": 1517966743948,
      "name": "pop3",
      "namespace": "scalablegroup:application",
      "provisioningState": "DEFINED",
      "qualifier": "application",
      "resourceVersion": 0,
      "targetIdList": [

      ],
      "type": "scalablegroup",
      "cfsChangeInfo": [

      ],
      "customProvisions": [

      ],
      "scalableGroupExternalHandle": "pop3",
      "scalableGroupType": "APPLICATION",
      "securityGroupTag": 0,
      "state": "ACTIVE",
      "vnAgnostic": true,
      "identitySource": {
        "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
        "instanceId": 10010,
        "authEntityId": 10010,
        "displayName": "10010",
        "authEntityClass": 1738953278,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "state": "INACTIVE",
        "type": "NBAR"
      },
      "indicativeNetworkIdentity": [
        {
          "id": "fdb2a6bd-6100-4ecb-906b-d46a67135658",
          "instanceId": 14575,
          "authEntityId": 14575,
          "displayName": "14575",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "110",
          "protocol": "TCP",
          "upperPort": 0
        }
      ],
      "networkApplications": [
        {
          "id": "1859d877-e008-4edd-ad67-40fd4efebd13",
          "instanceId": 11923,
          "authEntityId": 11923,
          "displayName": "11923",
          "authEntityClass": -217092956,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "appProtocol": "tcp",
          "applicationGroup": "pop3-group",
          "applicationType": "DEFAULT",
          "categoryId": "27953c60-b2ff-4e1a-bc52-0187d8295250",
          "createTime": 1517966743948,
          "encrypted": "false",
          "engineId": "3",
          "helpString": "Post Office Protocol 3",
          "ignoreConflict": false,
          "lastUpdateTime": 1517966743948,
          "longDescription": "Post Office Protocol 3 is an application-layer Internet standard protocol used by local e-mail clients to retrieve e-mail from a remote server over a TCP/IP connection. POP3 usually uses TCP port 995.",
          "name": "pop3",
          "nbarId": "33",
          "p2pTechnology": "false",
          "popularity": 9,
          "rank": 65535,
          "references": "http://www.ietf.org/rfc/rfc1939.txt",
          "selectorId": "110",
          "subCategory": "b90786f4-0190-45b8-9924-21b3e9682590",
          "trafficClass": "BULK_DATA",
          "tunnel": "false"
        }
      ],
      "networkIdentity": [
        {
          "id": "27d47291-c7a8-4c5e-896b-bd919b13ef67",
          "instanceId": 14574,
          "authEntityId": 14574,
          "displayName": "14574",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "110",
          "protocol": "TCP",
          "upperPort": 0
        }
      ],
      "parentScalableGroup": {
        "idRef": "8a7ca43a-32bd-40d2-871a-02220a165cb7"
      },
      "user": [

      ],
      "userGroup": [

      ]
    },
    {
      "id": "d268585c-5e12-4730-8d24-c480aff28886",
      "instanceId": 4777,
      "authEntityId": 4777,
      "displayName": "4777",
      "authEntityClass": -1909073334,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "createTime": 1517966743472,
      "deployed": false,
      "isSeeded": true,
      "isStale": false,
      "lastUpdateTime": 1517966743472,
      "name": "ms-lync-control",
      "namespace": "scalablegroup:application",
      "provisioningState": "DEFINED",
      "qualifier": "application",
      "resourceVersion": 0,
      "targetIdList": [

      ],
      "type": "scalablegroup",
      "cfsChangeInfo": [

      ],
      "customProvisions": [

      ],
      "scalableGroupExternalHandle": "ms-lync-control",
      "scalableGroupType": "APPLICATION",
      "securityGroupTag": 0,
      "state": "ACTIVE",
      "vnAgnostic": true,
      "identitySource": {
        "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
        "instanceId": 10010,
        "authEntityId": 10010,
        "displayName": "10010",
        "authEntityClass": 1738953278,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "state": "INACTIVE",
        "type": "NBAR"
      },
      "indicativeNetworkIdentity": [

      ],
      "networkApplications": [
        {
          "id": "0965a62d-6201-4f18-ba4e-542540807732",
          "instanceId": 11726,
          "authEntityId": 11726,
          "displayName": "11726",
          "authEntityClass": -217092956,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "appProtocol": "tcp/udp",
          "applicationGroup": "ms-lync-group",
          "applicationType": "DEFAULT",
          "categoryId": "38a8e354-e2fe-4f3c-b1c5-2bbb90fb0d99",
          "createTime": 1517966743472,
          "encrypted": "true",
          "engineId": "13",
          "helpString": "Skype provides cost effective and collaborative tools for businesses",
          "ignoreConflict": false,
          "lastUpdateTime": 1517966743472,
          "longDescription": "Skype for Business (formerly Microsoft Lync) is a communications and                      collaboration platform that brings together an experience inspired by                      Skype with enterprise-grade security, compliance and control. It offers                      features including presence, IM, voice and video calls, and online meetings.                      Since it is built right in to Microsoft Office, initiating chats,                      calls and meetings is an integrated experience within Office.",
          "name": "ms-lync-control",
          "nbarId": "1059",
          "p2pTechnology": "false",
          "popularity": 10,
          "rank": 65535,
          "references": "http://www.skype.com/en/business/",
          "selectorId": "1059",
          "subCategory": "68c4f989-5735-4e16-8d1c-6a9d89d5df9c",
          "trafficClass": "SIGNALING",
          "tunnel": "false"
        }
      ],
      "networkIdentity": [
        {
          "id": "81d8c5a7-2154-4575-ac0f-0be2d044af65",
          "instanceId": 13984,
          "authEntityId": 13984,
          "displayName": "13984",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "443,5060,5061",
          "protocol": "TCP",
          "upperPort": 0
        },
        {
          "id": "72c734b8-ba49-47d6-9434-d692f20943c0",
          "instanceId": 13983,
          "authEntityId": 13983,
          "displayName": "13983",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "5060,5061",
          "protocol": "UDP",
          "upperPort": 0
        }
      ],
      "parentScalableGroup": {
        "idRef": "60f1364d-2a02-4063-8ba1-07817abe0612"
      },
      "user": [

      ],
      "userGroup": [

      ]
    },
    {
      "id": "d2783247-078f-4f4c-b647-943a648e6c7b",
      "instanceId": 4155,
      "authEntityId": 4155,
      "displayName": "4155",
      "authEntityClass": -1909073334,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "createTime": 1517966741419,
      "deployed": false,
      "isSeeded": true,
      "isStale": false,
      "lastUpdateTime": 1517966741419,
      "name": "babelgum",
      "namespace": "scalablegroup:application",
      "provisioningState": "DEFINED",
      "qualifier": "application",
      "resourceVersion": 0,
      "targetIdList": [

      ],
      "type": "scalablegroup",
      "cfsChangeInfo": [

      ],
      "customProvisions": [

      ],
      "scalableGroupExternalHandle": "babelgum",
      "scalableGroupType": "APPLICATION",
      "securityGroupTag": 0,
      "state": "ACTIVE",
      "vnAgnostic": true,
      "identitySource": {
        "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
        "instanceId": 10010,
        "authEntityId": 10010,
        "displayName": "10010",
        "authEntityClass": 1738953278,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "state": "INACTIVE",
        "type": "NBAR"
      },
      "indicativeNetworkIdentity": [

      ],
      "networkApplications": [
        {
          "id": "929b4365-e6c5-4f48-bb86-77bf3fdd3812",
          "instanceId": 11115,
          "authEntityId": 11115,
          "displayName": "11115",
          "authEntityClass": -217092956,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "appProtocol": "tcp/udp",
          "applicationGroup": "other",
          "applicationType": "DEFAULT",
          "categoryId": "a09ef1b3-ba26-4372-aee8-309d50697e76",
          "createTime": 1517966741419,
          "encrypted": "true",
          "engineId": "13",
          "helpString": "Internet TV web platform",
          "ignoreConflict": false,
          "lastUpdateTime": 1517966741419,
          "longDescription": "Babelgum is an internet TV website based on streaming TV shows and music videos. Also supporting Apple mobile devices, including the iPhone, iPod Touch and iPad.",
          "name": "babelgum",
          "nbarId": "1066",
          "p2pTechnology": "false",
          "popularity": 7,
          "rank": 65535,
          "references": "http://www.babelgum.com/",
          "selectorId": "454",
          "subCategory": "85a797c0-aef8-4284-91a7-677f33e29abb",
          "trafficClass": "MULTIMEDIA_STREAMING",
          "tunnel": "false"
        }
      ],
      "networkIdentity": [
        {
          "id": "fff02e84-e34d-4ccf-89c0-ed51534c511d",
          "instanceId": 12320,
          "authEntityId": 12320,
          "displayName": "12320",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "53,80,443,5353",
          "protocol": "TCP",
          "upperPort": 0
        },
        {
          "id": "1ba36867-ca32-4c96-9725-825c00425fe1",
          "instanceId": 12321,
          "authEntityId": 12321,
          "displayName": "12321",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "53,5353",
          "protocol": "UDP",
          "upperPort": 0
        }
      ],
      "parentScalableGroup": {
        "idRef": "fd6264c9-c16a-4311-a335-a3db039256f3"
      },
      "user": [

      ],
      "userGroup": [

      ]
    },
    {
      "id": "d2885f27-f9db-4bed-a22f-ad2fa4f31894",
      "instanceId": 4355,
      "authEntityId": 4355,
      "displayName": "4355",
      "authEntityClass": -1909073334,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "createTime": 1517966742384,
      "deployed": false,
      "isSeeded": true,
      "isStale": false,
      "lastUpdateTime": 1517966742384,
      "name": "dhcp-failover",
      "namespace": "scalablegroup:application",
      "provisioningState": "DEFINED",
      "qualifier": "application",
      "resourceVersion": 0,
      "targetIdList": [

      ],
      "type": "scalablegroup",
      "cfsChangeInfo": [

      ],
      "customProvisions": [

      ],
      "scalableGroupExternalHandle": "dhcp-failover",
      "scalableGroupType": "APPLICATION",
      "securityGroupTag": 0,
      "state": "ACTIVE",
      "vnAgnostic": true,
      "identitySource": {
        "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
        "instanceId": 10010,
        "authEntityId": 10010,
        "displayName": "10010",
        "authEntityClass": 1738953278,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "state": "INACTIVE",
        "type": "NBAR"
      },
      "indicativeNetworkIdentity": [
        {
          "id": "1e5c9939-67fe-4652-b272-60b39b3dc9f8",
          "instanceId": 12846,
          "authEntityId": 12846,
          "displayName": "12846",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "647",
          "protocol": "UDP",
          "upperPort": 0
        },
        {
          "id": "7c1019b3-f2e1-47a0-8652-0007a925556e",
          "instanceId": 12847,
          "authEntityId": 12847,
          "displayName": "12847",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "647",
          "protocol": "TCP",
          "upperPort": 0
        }
      ],
      "networkApplications": [
        {
          "id": "3ecf4198-b3ea-4aef-a036-95d0ddc6d6ac",
          "instanceId": 11306,
          "authEntityId": 11306,
          "displayName": "11306",
          "authEntityClass": -217092956,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "appProtocol": "tcp/udp",
          "applicationGroup": "other",
          "applicationType": "DEFAULT",
          "categoryId": "1fc17362-9334-4c7b-b752-5afdbb574017",
          "createTime": 1517966742384,
          "encrypted": "false",
          "engineId": "3",
          "helpString": "DHCP Failover",
          "ignoreConflict": false,
          "lastUpdateTime": 1517966742384,
          "longDescription": "DHCP Failover Protocol supports automatic failover from a primary server to its secondary server. The failover mechanism allows the secondary server to perform DHCP actions while the primary is down, or when a network failure prevents the primary and secondary from communicating.",
          "name": "dhcp-failover",
          "nbarId": "556",
          "p2pTechnology": "false",
          "popularity": 6,
          "rank": 65535,
          "references": "http://tools.ietf.org/html/draft-ietf-dhc-failover-03",
          "selectorId": "647",
          "subCategory": "f3c0f761-d894-4112-ab89-b90fc84fd660",
          "trafficClass": "OPS_ADMIN_MGMT",
          "tunnel": "false"
        }
      ],
      "networkIdentity": [
        {
          "id": "e09505fa-c59d-45f7-b333-44a65efe922f",
          "instanceId": 12844,
          "authEntityId": 12844,
          "displayName": "12844",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "647",
          "protocol": "TCP",
          "upperPort": 0
        },
        {
          "id": "e624a4cd-8eae-4419-9815-93311089db16",
          "instanceId": 12845,
          "authEntityId": 12845,
          "displayName": "12845",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "647",
          "protocol": "UDP",
          "upperPort": 0
        }
      ],
      "parentScalableGroup": {
        "idRef": "5fcd74d4-8726-47f7-a088-c457863da7ef"
      },
      "user": [

      ],
      "userGroup": [

      ]
    },
    {
      "id": "d307f4fa-5b8a-499b-bee0-996c369e7b33",
      "instanceId": 4522,
      "authEntityId": 4522,
      "displayName": "4522",
      "authEntityClass": -1909073334,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "createTime": 1517966742881,
      "deployed": false,
      "isSeeded": true,
      "isStale": false,
      "lastUpdateTime": 1517966742881,
      "name": "guruguru",
      "namespace": "scalablegroup:application",
      "provisioningState": "DEFINED",
      "qualifier": "application",
      "resourceVersion": 0,
      "targetIdList": [

      ],
      "type": "scalablegroup",
      "cfsChangeInfo": [

      ],
      "customProvisions": [

      ],
      "scalableGroupExternalHandle": "guruguru",
      "scalableGroupType": "APPLICATION",
      "securityGroupTag": 0,
      "state": "ACTIVE",
      "vnAgnostic": true,
      "identitySource": {
        "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
        "instanceId": 10010,
        "authEntityId": 10010,
        "displayName": "10010",
        "authEntityClass": 1738953278,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "state": "INACTIVE",
        "type": "NBAR"
      },
      "indicativeNetworkIdentity": [

      ],
      "networkApplications": [
        {
          "id": "95f5e038-8ebe-46c5-87a8-730b4202357d",
          "instanceId": 11473,
          "authEntityId": 11473,
          "displayName": "11473",
          "authEntityClass": -217092956,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "applicationGroup": "other",
          "applicationType": "DEFAULT",
          "categoryId": "6daa3dfd-3526-407a-88f0-97824b3f3a24",
          "createTime": 1517966742881,
          "encrypted": "unassigned",
          "engineId": "13",
          "helpString": "DEPRECATED, traffic will not match",
          "ignoreConflict": false,
          "lastUpdateTime": 1517966742881,
          "longDescription": "The Guruguru protocol is used by the Guruguru file-sharing application mostly in Japan.",
          "name": "guruguru",
          "nbarId": "757",
          "p2pTechnology": "unassigned",
          "popularity": 0,
          "rank": 65535,
          "references": "http://www.guruguru.co.jp",
          "selectorId": "436",
          "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
          "trafficClass": "BULK_DATA",
          "tunnel": "unassigned"
        }
      ],
      "networkIdentity": [

      ],
      "parentScalableGroup": {
        "idRef": "000c15bf-28fe-4fbb-9b69-35e6502721f1"
      },
      "user": [

      ],
      "userGroup": [

      ]
    },
    {
      "id": "d3c2f9c3-91db-4f34-a473-409829f95248",
      "instanceId": 4648,
      "authEntityId": 4648,
      "displayName": "4648",
      "authEntityClass": -1909073334,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "createTime": 1517966743202,
      "deployed": false,
      "isSeeded": true,
      "isStale": false,
      "lastUpdateTime": 1517966743202,
      "name": "iso-ip",
      "namespace": "scalablegroup:application",
      "provisioningState": "DEFINED",
      "qualifier": "application",
      "resourceVersion": 0,
      "targetIdList": [

      ],
      "type": "scalablegroup",
      "cfsChangeInfo": [

      ],
      "customProvisions": [

      ],
      "scalableGroupExternalHandle": "iso-ip",
      "scalableGroupType": "APPLICATION",
      "securityGroupTag": 0,
      "state": "ACTIVE",
      "vnAgnostic": true,
      "identitySource": {
        "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
        "instanceId": 10010,
        "authEntityId": 10010,
        "displayName": "10010",
        "authEntityClass": 1738953278,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "state": "INACTIVE",
        "type": "NBAR"
      },
      "indicativeNetworkIdentity": [
        {
          "id": "4bf26cef-89f3-4648-a799-05c783469027",
          "instanceId": 13629,
          "authEntityId": 13629,
          "displayName": "13629",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "147",
          "protocol": "TCP",
          "upperPort": 0
        },
        {
          "id": "dbf2c9b7-67f7-4d84-ad84-d359f98464ec",
          "instanceId": 13630,
          "authEntityId": 13630,
          "displayName": "13630",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "147",
          "protocol": "UDP",
          "upperPort": 0
        }
      ],
      "networkApplications": [
        {
          "id": "990e0d2a-17fe-48dc-bad1-e03a2c44cbab",
          "instanceId": 11598,
          "authEntityId": 11598,
          "displayName": "11598",
          "authEntityClass": -217092956,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "appProtocol": "tcp/udp",
          "applicationGroup": "other",
          "applicationType": "DEFAULT",
          "categoryId": "6daa3dfd-3526-407a-88f0-97824b3f3a24",
          "createTime": 1517966743202,
          "encrypted": "false",
          "engineId": "3",
          "helpString": "iso-ip",
          "ignoreConflict": false,
          "lastUpdateTime": 1517966743202,
          "longDescription": "ISO-IP is an encapsulation of the OSI connectionless network layer protocol (CLNP) packets in IP datagrams. The intent is for implementations to use OSI network layer protocols directly over links locally, and to use the IP subnet as a link only when necessary to reach a site that is separated from the source by an IP gateway.",
          "name": "iso-ip",
          "nbarId": "953",
          "p2pTechnology": "false",
          "popularity": 1,
          "rank": 65535,
          "references": "http://tools.ietf.org/rfc/rfc1070.txt",
          "selectorId": "147",
          "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
          "trafficClass": "BULK_DATA",
          "tunnel": "false"
        }
      ],
      "networkIdentity": [
        {
          "id": "34ff077f-d4e6-41cc-9801-622023675c7f",
          "instanceId": 13627,
          "authEntityId": 13627,
          "displayName": "13627",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "147",
          "protocol": "TCP",
          "upperPort": 0
        },
        {
          "id": "1912896d-23be-4484-9a12-8e9a2a4983bf",
          "instanceId": 13628,
          "authEntityId": 13628,
          "displayName": "13628",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "147",
          "protocol": "UDP",
          "upperPort": 0
        }
      ],
      "parentScalableGroup": {
        "idRef": "000c15bf-28fe-4fbb-9b69-35e6502721f1"
      },
      "user": [

      ],
      "userGroup": [

      ]
    },
    {
      "id": "d414caa8-a086-492a-9691-6cf68857f1f9",
      "instanceId": 15158,
      "authEntityId": 15158,
      "displayName": "15158",
      "authEntityClass": -1909073334,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "createTime": 1517966744367,
      "deployed": false,
      "isSeeded": true,
      "isStale": false,
      "lastUpdateTime": 1517966744367,
      "name": "slickdeals",
      "namespace": "scalablegroup:application",
      "provisioningState": "DEFINED",
      "qualifier": "application",
      "resourceVersion": 0,
      "targetIdList": [

      ],
      "type": "scalablegroup",
      "cfsChangeInfo": [

      ],
      "customProvisions": [

      ],
      "scalableGroupExternalHandle": "slickdeals",
      "scalableGroupType": "APPLICATION",
      "securityGroupTag": 0,
      "state": "ACTIVE",
      "vnAgnostic": true,
      "identitySource": {
        "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
        "instanceId": 10010,
        "authEntityId": 10010,
        "displayName": "10010",
        "authEntityClass": 1738953278,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "state": "INACTIVE",
        "type": "NBAR"
      },
      "indicativeNetworkIdentity": [

      ],
      "networkApplications": [
        {
          "id": "c23c7eda-b793-4f27-850b-540e9a6965f9",
          "instanceId": 16101,
          "authEntityId": 16101,
          "displayName": "16101",
          "authEntityClass": -217092956,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "appProtocol": "tcp/udp",
          "applicationGroup": "other",
          "applicationType": "DEFAULT",
          "categoryId": "878644b5-fe91-45b4-9204-31c84a73ba48",
          "createTime": 1517966744367,
          "encrypted": "false",
          "engineId": "13",
          "helpString": "Slickdeals - An online coupons and deals website.",
          "ignoreConflict": false,
          "lastUpdateTime": 1517966744367,
          "longDescription": "Slickdeals - An online coupons and deals website.",
          "name": "slickdeals",
          "nbarId": "1603",
          "p2pTechnology": "false",
          "popularity": 3,
          "rank": 65535,
          "references": "http://slickdeals.net",
          "selectorId": "673",
          "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
          "trafficClass": "TRANSACTIONAL_DATA",
          "tunnel": "false"
        }
      ],
      "networkIdentity": [
        {
          "id": "a280d838-d1ce-4906-8dfa-a7fdbdeea3f9",
          "instanceId": 17120,
          "authEntityId": 17120,
          "displayName": "17120",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "53,5353",
          "protocol": "UDP",
          "upperPort": 0
        },
        {
          "id": "90d5e8ce-dac9-479d-a9b7-d56193e1d598",
          "instanceId": 17119,
          "authEntityId": 17119,
          "displayName": "17119",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "53,80,443,5353",
          "protocol": "TCP",
          "upperPort": 0
        }
      ],
      "parentScalableGroup": {
        "idRef": "28e08de4-d7b2-4521-84ce-bc1e5ee0b9cc"
      },
      "user": [

      ],
      "userGroup": [

      ]
    },
    {
      "id": "d425a0f6-0eb8-4462-9e6f-c5b2283dbea9",
      "instanceId": 15282,
      "authEntityId": 15282,
      "displayName": "15282",
      "authEntityClass": -1909073334,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "createTime": 1517966744650,
      "deployed": false,
      "isSeeded": true,
      "isStale": false,
      "lastUpdateTime": 1517966744650,
      "name": "tianya",
      "namespace": "scalablegroup:application",
      "provisioningState": "DEFINED",
      "qualifier": "application",
      "resourceVersion": 0,
      "targetIdList": [

      ],
      "type": "scalablegroup",
      "cfsChangeInfo": [

      ],
      "customProvisions": [

      ],
      "scalableGroupExternalHandle": "tianya",
      "scalableGroupType": "APPLICATION",
      "securityGroupTag": 0,
      "state": "ACTIVE",
      "vnAgnostic": true,
      "identitySource": {
        "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
        "instanceId": 10010,
        "authEntityId": 10010,
        "displayName": "10010",
        "authEntityClass": 1738953278,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "state": "INACTIVE",
        "type": "NBAR"
      },
      "indicativeNetworkIdentity": [

      ],
      "networkApplications": [
        {
          "id": "bb839580-1bfc-4820-a191-3810bce13a58",
          "instanceId": 16225,
          "authEntityId": 16225,
          "displayName": "16225",
          "authEntityClass": -217092956,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "appProtocol": "tcp/udp",
          "applicationGroup": "other",
          "applicationType": "DEFAULT",
          "categoryId": "878644b5-fe91-45b4-9204-31c84a73ba48",
          "createTime": 1517966744650,
          "encrypted": "false",
          "engineId": "13",
          "helpString": "Tianya - Chinese forum for blogging, microblogging and photo album services.",
          "ignoreConflict": false,
          "lastUpdateTime": 1517966744650,
          "longDescription": "Tianya - Chinese forum for blogging, microblogging and photo album services.",
          "name": "tianya",
          "nbarId": "1602",
          "p2pTechnology": "false",
          "popularity": 3,
          "rank": 65535,
          "references": "http://tianya.cn",
          "selectorId": "672",
          "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
          "trafficClass": "TRANSACTIONAL_DATA",
          "tunnel": "false"
        }
      ],
      "networkIdentity": [
        {
          "id": "60d272bb-d9cc-4794-b0d6-69d7696572cb",
          "instanceId": 17472,
          "authEntityId": 17472,
          "displayName": "17472",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "53,5353",
          "protocol": "UDP",
          "upperPort": 0
        },
        {
          "id": "fa9626f5-9f3c-49a8-ac7f-803d1cc71fd7",
          "instanceId": 17471,
          "authEntityId": 17471,
          "displayName": "17471",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "53,80,443,5353",
          "protocol": "TCP",
          "upperPort": 0
        }
      ],
      "parentScalableGroup": {
        "idRef": "28e08de4-d7b2-4521-84ce-bc1e5ee0b9cc"
      },
      "user": [

      ],
      "userGroup": [

      ]
    },
    {
      "id": "d44edaf4-b810-457c-a13d-8f18938b7a7b",
      "instanceId": 15019,
      "authEntityId": 15019,
      "displayName": "15019",
      "authEntityClass": -1909073334,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "createTime": 1517966744040,
      "deployed": false,
      "isSeeded": true,
      "isStale": false,
      "lastUpdateTime": 1517966744040,
      "name": "qq-im",
      "namespace": "scalablegroup:application",
      "provisioningState": "DEFINED",
      "qualifier": "application",
      "resourceVersion": 0,
      "targetIdList": [

      ],
      "type": "scalablegroup",
      "cfsChangeInfo": [

      ],
      "customProvisions": [

      ],
      "scalableGroupExternalHandle": "qq-im",
      "scalableGroupType": "APPLICATION",
      "securityGroupTag": 0,
      "state": "ACTIVE",
      "vnAgnostic": true,
      "identitySource": {
        "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
        "instanceId": 10010,
        "authEntityId": 10010,
        "displayName": "10010",
        "authEntityClass": 1738953278,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "state": "INACTIVE",
        "type": "NBAR"
      },
      "indicativeNetworkIdentity": [

      ],
      "networkApplications": [
        {
          "id": "ef4e242e-7725-4c8d-b583-769ab4e16dc4",
          "instanceId": 11958,
          "authEntityId": 11958,
          "displayName": "11958",
          "authEntityClass": -217092956,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "appProtocol": "tcp/udp",
          "applicationGroup": "qq-group",
          "applicationType": "DEFAULT",
          "categoryId": "0f6cab47-be95-4124-9db3-360ccd55f983",
          "createTime": 1517966744040,
          "encrypted": "false",
          "engineId": "13",
          "helpString": "QQ Instant Messaging",
          "ignoreConflict": false,
          "lastUpdateTime": 1517966744040,
          "longDescription": "QQ instant messenger is the most popular IM software service in China. QQ IM was developed by Tencent Holding LTD. and has clients for Windows, Mac, Android and iPhone. A Chinese version is available as well as an English version (QQ International).",
          "name": "qq-im",
          "nbarId": "1508",
          "p2pTechnology": "true",
          "popularity": 8,
          "rank": 65535,
          "references": "http://www.imqq.com/",
          "selectorId": "569",
          "subCategory": "118c10a2-e272-47c1-92b9-4814ebe97138",
          "trafficClass": "MULTIMEDIA_CONFERENCING",
          "tunnel": "false"
        }
      ],
      "networkIdentity": [
        {
          "id": "c61fc6c9-a0be-43db-aa47-ce43ce2ff15f",
          "instanceId": 14688,
          "authEntityId": 14688,
          "displayName": "14688",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "53,80,5353",
          "protocol": "TCP",
          "upperPort": 0
        },
        {
          "id": "e0b3ca54-5363-4b8b-aeac-5d4707b4dbb6",
          "instanceId": 14687,
          "authEntityId": 14687,
          "displayName": "14687",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "53,5353",
          "protocol": "UDP",
          "upperPort": 0
        }
      ],
      "parentScalableGroup": {
        "idRef": "fd6264c9-c16a-4311-a335-a3db039256f3"
      },
      "user": [

      ],
      "userGroup": [

      ]
    },
    {
      "id": "d4affc4c-48d1-46a0-88c1-c369bf08fb5f",
      "instanceId": 4409,
      "authEntityId": 4409,
      "displayName": "4409",
      "authEntityClass": -1909073334,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "createTime": 1517966742564,
      "deployed": false,
      "isSeeded": true,
      "isStale": false,
      "lastUpdateTime": 1517966742564,
      "name": "encrypted-bittorrent",
      "namespace": "scalablegroup:application",
      "provisioningState": "DEFINED",
      "qualifier": "application",
      "resourceVersion": 0,
      "targetIdList": [

      ],
      "type": "scalablegroup",
      "cfsChangeInfo": [

      ],
      "customProvisions": [

      ],
      "scalableGroupExternalHandle": "encrypted-bittorrent",
      "scalableGroupType": "APPLICATION",
      "securityGroupTag": 0,
      "state": "ACTIVE",
      "vnAgnostic": true,
      "identitySource": {
        "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
        "instanceId": 10010,
        "authEntityId": 10010,
        "displayName": "10010",
        "authEntityClass": 1738953278,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "state": "INACTIVE",
        "type": "NBAR"
      },
      "indicativeNetworkIdentity": [

      ],
      "networkApplications": [
        {
          "id": "1a24305c-021d-4530-a844-2f1c710ef4e1",
          "instanceId": 11360,
          "authEntityId": 11360,
          "displayName": "11360",
          "authEntityClass": -217092956,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "appProtocol": "tcp",
          "applicationGroup": "bittorrent-group",
          "applicationType": "DEFAULT",
          "categoryId": "b33b2761-d981-4734-810a-a6a1c18ab352",
          "createTime": 1517966742564,
          "encrypted": "true",
          "engineId": "13",
          "helpString": "Encrypted Bittorrent Traffic",
          "ignoreConflict": false,
          "lastUpdateTime": 1517966742564,
          "longDescription": "Encrypted BitTorrent is an attempt to provide anonymous and private BitTorrent traffic. BitTorrent is a peer-to-peer file sharing protocol used for distributing files over the internet. It identifies content by URL and is designed to integrate seamlessly with the web. The BitTorrent protocol is based on a BitTorrent tracker (server) that initializes the connections between the clients (peers).",
          "name": "encrypted-bittorrent",
          "nbarId": "1206",
          "p2pTechnology": "true",
          "popularity": 10,
          "rank": 65535,
          "references": "http://www.bittorrent.com/",
          "selectorId": "313",
          "subCategory": "79fe7198-9ce3-4c01-9b58-45a8df3d9513",
          "trafficClass": "BULK_DATA",
          "tunnel": "false"
        }
      ],
      "networkIdentity": [
        {
          "id": "10a072aa-d9a4-43c1-92e0-d5b74014a5b0",
          "instanceId": 13021,
          "authEntityId": 13021,
          "displayName": "13021",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "6881,6882,6883,6884,6885,6886,6887,6888,6889",
          "protocol": "TCP",
          "upperPort": 0
        }
      ],
      "parentScalableGroup": {
        "idRef": "7a1a3bbb-dc84-4a35-82a4-7e650c4c4761"
      },
      "user": [

      ],
      "userGroup": [

      ]
    },
    {
      "id": "d512ffec-5bfe-4e12-87c6-bdf70baf0ddd",
      "instanceId": 4096,
      "authEntityId": 4096,
      "displayName": "4096",
      "authEntityClass": -1909073334,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "createTime": 1517966741058,
      "deployed": false,
      "isSeeded": true,
      "isStale": false,
      "lastUpdateTime": 1517966741058,
      "name": "aol-messenger-video",
      "namespace": "scalablegroup:application",
      "provisioningState": "DEFINED",
      "qualifier": "application",
      "resourceVersion": 0,
      "targetIdList": [

      ],
      "type": "scalablegroup",
      "cfsChangeInfo": [

      ],
      "customProvisions": [

      ],
      "scalableGroupExternalHandle": "aol-messenger-video",
      "scalableGroupType": "APPLICATION",
      "securityGroupTag": 0,
      "state": "ACTIVE",
      "vnAgnostic": true,
      "identitySource": {
        "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
        "instanceId": 10010,
        "authEntityId": 10010,
        "displayName": "10010",
        "authEntityClass": 1738953278,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "state": "INACTIVE",
        "type": "NBAR"
      },
      "indicativeNetworkIdentity": [

      ],
      "networkApplications": [
        {
          "id": "5463eef6-cf35-4c4a-a450-a5b31d08284a",
          "instanceId": 11060,
          "authEntityId": 11060,
          "displayName": "11060",
          "authEntityClass": -217092956,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "appProtocol": "tcp/udp",
          "applicationGroup": "aol-group",
          "applicationType": "DEFAULT",
          "categoryId": "82a7b7fa-da5a-48f5-8b6a-a2546b947843",
          "createTime": 1517966741058,
          "encrypted": "false",
          "engineId": "13",
          "helpString": "AOL Instant Messenger Video Calls traffic",
          "ignoreConflict": false,
          "lastUpdateTime": 1517966741058,
          "longDescription": "AOL Instant Messenger (AIM) video calls classification. Flows can run over TCP or over UDP if the two clients are on the same network. It uses RTP over STUN to send video data over UDP.",
          "name": "aol-messenger-video",
          "nbarId": "1437",
          "p2pTechnology": "false",
          "popularity": 7,
          "rank": 65535,
          "references": "http://www.aim.com/",
          "selectorId": "501",
          "subCategory": "118c10a2-e272-47c1-92b9-4814ebe97138",
          "trafficClass": "MULTIMEDIA_CONFERENCING",
          "tunnel": "false"
        }
      ],
      "networkIdentity": [
        {
          "id": "231dac69-1590-4a60-bccf-22c21177a840",
          "instanceId": 12150,
          "authEntityId": 12150,
          "displayName": "12150",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "80,3478",
          "protocol": "TCP",
          "upperPort": 0
        },
        {
          "id": "dff12b16-472d-4f1f-85cf-47adccadf221",
          "instanceId": 12151,
          "authEntityId": 12151,
          "displayName": "12151",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "3478",
          "protocol": "UDP",
          "upperPort": 0
        }
      ],
      "parentScalableGroup": {
        "idRef": "fd6264c9-c16a-4311-a335-a3db039256f3"
      },
      "user": [

      ],
      "userGroup": [

      ]
    },
    {
      "id": "d5148a63-3d03-4d7d-9055-551ba58cd765",
      "instanceId": 15051,
      "authEntityId": 15051,
      "displayName": "15051",
      "authEntityClass": -1909073334,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "createTime": 1517966744120,
      "deployed": false,
      "isSeeded": true,
      "isStale": false,
      "lastUpdateTime": 1517966744120,
      "name": "rje",
      "namespace": "scalablegroup:application",
      "provisioningState": "DEFINED",
      "qualifier": "application",
      "resourceVersion": 0,
      "targetIdList": [

      ],
      "type": "scalablegroup",
      "cfsChangeInfo": [

      ],
      "customProvisions": [

      ],
      "scalableGroupExternalHandle": "rje",
      "scalableGroupType": "APPLICATION",
      "securityGroupTag": 0,
      "state": "ACTIVE",
      "vnAgnostic": true,
      "identitySource": {
        "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
        "instanceId": 10010,
        "authEntityId": 10010,
        "displayName": "10010",
        "authEntityClass": 1738953278,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "state": "INACTIVE",
        "type": "NBAR"
      },
      "indicativeNetworkIdentity": [
        {
          "id": "f49aef55-cba0-4296-8d34-6e7436c74a49",
          "instanceId": 14787,
          "authEntityId": 14787,
          "displayName": "14787",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "5",
          "protocol": "TCP",
          "upperPort": 0
        },
        {
          "id": "4899a927-2f0f-401f-8579-b3b0448ab201",
          "instanceId": 14788,
          "authEntityId": 14788,
          "displayName": "14788",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "5",
          "protocol": "UDP",
          "upperPort": 0
        }
      ],
      "networkApplications": [
        {
          "id": "647c8006-c785-4d8d-9610-37dc100e3dfe",
          "instanceId": 11990,
          "authEntityId": 11990,
          "displayName": "11990",
          "authEntityClass": -217092956,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "appProtocol": "tcp/udp",
          "applicationGroup": "other",
          "applicationType": "DEFAULT",
          "categoryId": "1fc17362-9334-4c7b-b752-5afdbb574017",
          "createTime": 1517966744120,
          "encrypted": "false",
          "engineId": "3",
          "helpString": "Remote Job Entry",
          "ignoreConflict": false,
          "lastUpdateTime": 1517966744120,
          "longDescription": "Theremote job entry (RJE)is a Job Entry Subsystem (JES2) function that provides the ability to submit jobs and receive system output (SYSOUT) at remote facilities as if the jobs had been submitted at a local facility.",
          "name": "rje",
          "nbarId": "901",
          "p2pTechnology": "false",
          "popularity": 1,
          "rank": 65535,
          "references": "http://publib.boulder.ibm.com/infocenter/zos/v1r11/index.jsp?topic=/com.ibm.zos.r11.hasa300/rjechp.htm",
          "selectorId": "5",
          "subCategory": "f3c0f761-d894-4112-ab89-b90fc84fd660",
          "trafficClass": "OPS_ADMIN_MGMT",
          "tunnel": "false"
        }
      ],
      "networkIdentity": [
        {
          "id": "88e40393-a7aa-4d56-923e-f90dd49d0998",
          "instanceId": 14785,
          "authEntityId": 14785,
          "displayName": "14785",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "5",
          "protocol": "TCP",
          "upperPort": 0
        },
        {
          "id": "21330c42-ddee-4b6c-9874-72f41e01c0b5",
          "instanceId": 14786,
          "authEntityId": 14786,
          "displayName": "14786",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "5",
          "protocol": "UDP",
          "upperPort": 0
        }
      ],
      "parentScalableGroup": {
        "idRef": "5fcd74d4-8726-47f7-a088-c457863da7ef"
      },
      "user": [

      ],
      "userGroup": [

      ]
    },
    {
      "id": "d52ee5a9-43e5-4280-a7a6-e1409ac81545",
      "instanceId": 4947,
      "authEntityId": 4947,
      "displayName": "4947",
      "authEntityClass": -1909073334,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "createTime": 1517966743876,
      "deployed": false,
      "isSeeded": true,
      "isStale": false,
      "lastUpdateTime": 1517966743876,
      "name": "pdap",
      "namespace": "scalablegroup:application",
      "provisioningState": "DEFINED",
      "qualifier": "application",
      "resourceVersion": 0,
      "targetIdList": [

      ],
      "type": "scalablegroup",
      "cfsChangeInfo": [

      ],
      "customProvisions": [

      ],
      "scalableGroupExternalHandle": "pdap",
      "scalableGroupType": "APPLICATION",
      "securityGroupTag": 0,
      "state": "ACTIVE",
      "vnAgnostic": true,
      "identitySource": {
        "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
        "instanceId": 10010,
        "authEntityId": 10010,
        "displayName": "10010",
        "authEntityClass": 1738953278,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "state": "INACTIVE",
        "type": "NBAR"
      },
      "indicativeNetworkIdentity": [
        {
          "id": "8c3752bd-9751-40e6-b8ff-339e10d26f43",
          "instanceId": 14503,
          "authEntityId": 14503,
          "displayName": "14503",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "344",
          "protocol": "UDP",
          "upperPort": 0
        },
        {
          "id": "05d340a4-5f48-4fc9-af9e-41c8cb780011",
          "instanceId": 14504,
          "authEntityId": 14504,
          "displayName": "14504",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "344",
          "protocol": "TCP",
          "upperPort": 0
        }
      ],
      "networkApplications": [
        {
          "id": "bb9f5b79-903f-4e83-81a8-6a85c7d82e71",
          "instanceId": 11896,
          "authEntityId": 11896,
          "displayName": "11896",
          "authEntityClass": -217092956,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "appProtocol": "tcp/udp",
          "applicationGroup": "other",
          "applicationType": "DEFAULT",
          "categoryId": "6daa3dfd-3526-407a-88f0-97824b3f3a24",
          "createTime": 1517966743876,
          "encrypted": "false",
          "engineId": "3",
          "helpString": "Prospero Data Access Protocol",
          "ignoreConflict": false,
          "lastUpdateTime": 1517966743876,
          "longDescription": "Prospero Data Access Protocol (PDAP) is used to implement the Prospero File System, which is based on the Virtual System Model.",
          "name": "pdap",
          "nbarId": "441",
          "p2pTechnology": "false",
          "popularity": 1,
          "rank": 65535,
          "references": "http://ntrs.nasa.gov/archive/nasa/casi.ntrs.nasa.gov/19970004479_1997000649.pdf",
          "selectorId": "344",
          "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
          "trafficClass": "BULK_DATA",
          "tunnel": "false"
        }
      ],
      "networkIdentity": [
        {
          "id": "6a3a1c03-80da-492d-9357-b2010034c5ae",
          "instanceId": 14501,
          "authEntityId": 14501,
          "displayName": "14501",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "344",
          "protocol": "TCP",
          "upperPort": 0
        },
        {
          "id": "2f4d40ac-9d3e-4dc1-9ce8-1e66b897a297",
          "instanceId": 14502,
          "authEntityId": 14502,
          "displayName": "14502",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "344",
          "protocol": "UDP",
          "upperPort": 0
        }
      ],
      "parentScalableGroup": {
        "idRef": "000c15bf-28fe-4fbb-9b69-35e6502721f1"
      },
      "user": [

      ],
      "userGroup": [

      ]
    },
    {
      "id": "d5314981-8e17-4725-9b03-0763285267b4",
      "instanceId": 4445,
      "authEntityId": 4445,
      "displayName": "4445",
      "authEntityClass": -1909073334,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "createTime": 1517966742681,
      "deployed": false,
      "isSeeded": true,
      "isStale": false,
      "lastUpdateTime": 1517966742681,
      "name": "fcp",
      "namespace": "scalablegroup:application",
      "provisioningState": "DEFINED",
      "qualifier": "application",
      "resourceVersion": 0,
      "targetIdList": [

      ],
      "type": "scalablegroup",
      "cfsChangeInfo": [

      ],
      "customProvisions": [

      ],
      "scalableGroupExternalHandle": "fcp",
      "scalableGroupType": "APPLICATION",
      "securityGroupTag": 0,
      "state": "ACTIVE",
      "vnAgnostic": true,
      "identitySource": {
        "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
        "instanceId": 10010,
        "authEntityId": 10010,
        "displayName": "10010",
        "authEntityClass": 1738953278,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "state": "INACTIVE",
        "type": "NBAR"
      },
      "indicativeNetworkIdentity": [
        {
          "id": "05e9ec4f-93ae-417b-9aaf-4272049b3a66",
          "instanceId": 13112,
          "authEntityId": 13112,
          "displayName": "13112",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "510",
          "protocol": "TCP",
          "upperPort": 0
        },
        {
          "id": "0ca6262d-7192-4188-8734-190e654fd1c3",
          "instanceId": 13113,
          "authEntityId": 13113,
          "displayName": "13113",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "510",
          "protocol": "UDP",
          "upperPort": 0
        }
      ],
      "networkApplications": [
        {
          "id": "5e5247c7-7ce1-4006-a2f3-cc38bdd26f75",
          "instanceId": 11396,
          "authEntityId": 11396,
          "displayName": "11396",
          "authEntityClass": -217092956,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "appProtocol": "tcp/udp",
          "applicationGroup": "other",
          "applicationType": "DEFAULT",
          "categoryId": "1fc17362-9334-4c7b-b752-5afdbb574017",
          "createTime": 1517966742681,
          "encrypted": "false",
          "engineId": "3",
          "helpString": "FirstClass Protocol",
          "ignoreConflict": false,
          "lastUpdateTime": 1517966742681,
          "longDescription": "FirstClass Protocol (FCP) is a transport layer networking protocol that all FirstClass communications used. The protocol guarantees error-free communications for all activities. FCP could run on several different physical layers, starting with modems and AppleTalk, and later adding Novell's IPX and TCP/IP. Both the client and server could communicate over any of these links, allowing a user to move from office to home and have access to the same server.",
          "name": "fcp",
          "nbarId": "424",
          "p2pTechnology": "false",
          "popularity": 1,
          "rank": 65535,
          "references": "http://en.wikipedia.org/wiki/FirstClass",
          "selectorId": "510",
          "subCategory": "f3c0f761-d894-4112-ab89-b90fc84fd660",
          "trafficClass": "OPS_ADMIN_MGMT",
          "tunnel": "false"
        }
      ],
      "networkIdentity": [
        {
          "id": "8decba97-842b-444f-a147-649227f9a40e",
          "instanceId": 13110,
          "authEntityId": 13110,
          "displayName": "13110",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "510",
          "protocol": "TCP",
          "upperPort": 0
        },
        {
          "id": "ca3aad78-44e4-4c75-ae3d-1471cedfa908",
          "instanceId": 13111,
          "authEntityId": 13111,
          "displayName": "13111",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "510",
          "protocol": "UDP",
          "upperPort": 0
        }
      ],
      "parentScalableGroup": {
        "idRef": "5fcd74d4-8726-47f7-a088-c457863da7ef"
      },
      "user": [

      ],
      "userGroup": [

      ]
    },
    {
      "id": "d582ffef-8deb-4b89-add0-a4b7549f6b32",
      "instanceId": 4304,
      "authEntityId": 4304,
      "displayName": "4304",
      "authEntityClass": -1909073334,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "createTime": 1517966742217,
      "deployed": false,
      "isSeeded": true,
      "isStale": false,
      "lastUpdateTime": 1517966742217,
      "name": "crtp",
      "namespace": "scalablegroup:application",
      "provisioningState": "DEFINED",
      "qualifier": "application",
      "resourceVersion": 0,
      "targetIdList": [

      ],
      "type": "scalablegroup",
      "cfsChangeInfo": [

      ],
      "customProvisions": [

      ],
      "scalableGroupExternalHandle": "crtp",
      "scalableGroupType": "APPLICATION",
      "securityGroupTag": 0,
      "state": "ACTIVE",
      "vnAgnostic": true,
      "identitySource": {
        "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
        "instanceId": 10010,
        "authEntityId": 10010,
        "displayName": "10010",
        "authEntityClass": 1738953278,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "state": "INACTIVE",
        "type": "NBAR"
      },
      "indicativeNetworkIdentity": [
        {
          "id": "3de30327-e4cb-44a5-bbd3-7b722f6ceba6",
          "instanceId": 12685,
          "authEntityId": 12685,
          "displayName": "12685",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipProtocolNumbers": "126",
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "protocol": "CRTP",
          "upperPort": 0
        }
      ],
      "networkApplications": [
        {
          "id": "13af5c35-9171-4c73-84d5-1b8090a72580",
          "instanceId": 11257,
          "authEntityId": 11257,
          "displayName": "11257",
          "authEntityClass": -217092956,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "appProtocol": "ip",
          "applicationGroup": "other",
          "applicationType": "DEFAULT",
          "categoryId": "6daa3dfd-3526-407a-88f0-97824b3f3a24",
          "createTime": 1517966742217,
          "encrypted": "unassigned",
          "engineId": "1",
          "helpString": "Combat Radio Transport Protocol",
          "ignoreConflict": false,
          "lastUpdateTime": 1517966742217,
          "longDescription": "Combat Radio Transport Protocol transports the combat radio's data through in an internet network.",
          "name": "crtp",
          "nbarId": "880",
          "p2pTechnology": "unassigned",
          "popularity": 2,
          "rank": 65535,
          "references": "http://www.springerlink.com/content/a761662632006m51/fulltext.pdf",
          "selectorId": "126",
          "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
          "trafficClass": "BULK_DATA",
          "tunnel": "unassigned"
        }
      ],
      "networkIdentity": [
        {
          "id": "fe812eda-686c-4e93-a7ad-b1cbc98462ab",
          "instanceId": 12684,
          "authEntityId": 12684,
          "displayName": "12684",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipProtocolNumbers": "126",
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "protocol": "CRTP",
          "upperPort": 0
        }
      ],
      "parentScalableGroup": {
        "idRef": "000c15bf-28fe-4fbb-9b69-35e6502721f1"
      },
      "user": [

      ],
      "userGroup": [

      ]
    },
    {
      "id": "d5b92e59-dbfe-49c6-be34-0350e089be91",
      "instanceId": 15169,
      "authEntityId": 15169,
      "displayName": "15169",
      "authEntityClass": -1909073334,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "createTime": 1517966744393,
      "deployed": false,
      "isSeeded": true,
      "isStale": false,
      "lastUpdateTime": 1517966744393,
      "name": "smux",
      "namespace": "scalablegroup:application",
      "provisioningState": "DEFINED",
      "qualifier": "application",
      "resourceVersion": 0,
      "targetIdList": [

      ],
      "type": "scalablegroup",
      "cfsChangeInfo": [

      ],
      "customProvisions": [

      ],
      "scalableGroupExternalHandle": "smux",
      "scalableGroupType": "APPLICATION",
      "securityGroupTag": 0,
      "state": "ACTIVE",
      "vnAgnostic": true,
      "identitySource": {
        "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
        "instanceId": 10010,
        "authEntityId": 10010,
        "displayName": "10010",
        "authEntityClass": 1738953278,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "state": "INACTIVE",
        "type": "NBAR"
      },
      "indicativeNetworkIdentity": [
        {
          "id": "f2b2b2b6-85ec-4630-af1e-bca2787e9228",
          "instanceId": 17155,
          "authEntityId": 17155,
          "displayName": "17155",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "199",
          "protocol": "UDP",
          "upperPort": 0
        },
        {
          "id": "80676dc3-8a13-45fa-90ab-7f604fde54e2",
          "instanceId": 17156,
          "authEntityId": 17156,
          "displayName": "17156",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "199",
          "protocol": "TCP",
          "upperPort": 0
        }
      ],
      "networkApplications": [
        {
          "id": "408989e2-81e1-431e-b817-a71beb1a1970",
          "instanceId": 16112,
          "authEntityId": 16112,
          "displayName": "16112",
          "authEntityClass": -217092956,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "appProtocol": "tcp/udp",
          "applicationGroup": "other",
          "applicationType": "DEFAULT",
          "categoryId": "6daa3dfd-3526-407a-88f0-97824b3f3a24",
          "createTime": 1517966744393,
          "encrypted": "false",
          "engineId": "3",
          "helpString": "SMUX",
          "ignoreConflict": false,
          "lastUpdateTime": 1517966744393,
          "longDescription": "SNMP multiplexing (SMUX) is a computer networking protocol used in implementing the Simple Network Management Protocol (SNMP). It defines communications between the SNMP Agent and other processes.",
          "name": "smux",
          "nbarId": "1097",
          "p2pTechnology": "false",
          "popularity": 1,
          "rank": 65535,
          "references": "http://www.ietf.org/rfc/rfc1227.txt",
          "selectorId": "199",
          "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
          "trafficClass": "BULK_DATA",
          "tunnel": "false"
        }
      ],
      "networkIdentity": [
        {
          "id": "ab84851f-1634-45db-8831-b5c8dc968b2e",
          "instanceId": 17153,
          "authEntityId": 17153,
          "displayName": "17153",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "199",
          "protocol": "UDP",
          "upperPort": 0
        },
        {
          "id": "44c653ff-41c0-4e5a-ac5c-ea512218537d",
          "instanceId": 17154,
          "authEntityId": 17154,
          "displayName": "17154",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "199",
          "protocol": "TCP",
          "upperPort": 0
        }
      ],
      "parentScalableGroup": {
        "idRef": "000c15bf-28fe-4fbb-9b69-35e6502721f1"
      },
      "user": [

      ],
      "userGroup": [

      ]
    },
    {
      "id": "d5e07928-c077-4917-9ac4-bf2ae02c24c0",
      "instanceId": 15226,
      "authEntityId": 15226,
      "displayName": "15226",
      "authEntityClass": -1909073334,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "createTime": 1517966744533,
      "deployed": false,
      "isSeeded": true,
      "isStale": false,
      "lastUpdateTime": 1517966744533,
      "name": "su-mit-tg",
      "namespace": "scalablegroup:application",
      "provisioningState": "DEFINED",
      "qualifier": "application",
      "resourceVersion": 0,
      "targetIdList": [

      ],
      "type": "scalablegroup",
      "cfsChangeInfo": [

      ],
      "customProvisions": [

      ],
      "scalableGroupExternalHandle": "su-mit-tg",
      "scalableGroupType": "APPLICATION",
      "securityGroupTag": 0,
      "state": "ACTIVE",
      "vnAgnostic": true,
      "identitySource": {
        "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
        "instanceId": 10010,
        "authEntityId": 10010,
        "displayName": "10010",
        "authEntityClass": 1738953278,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "state": "INACTIVE",
        "type": "NBAR"
      },
      "indicativeNetworkIdentity": [
        {
          "id": "23dcd5aa-0f8a-4909-a57e-e2f9d4f76a33",
          "instanceId": 17310,
          "authEntityId": 17310,
          "displayName": "17310",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "89",
          "protocol": "TCP",
          "upperPort": 0
        },
        {
          "id": "0e83a385-0f2b-4369-aff1-fc1506b78174",
          "instanceId": 17311,
          "authEntityId": 17311,
          "displayName": "17311",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "89",
          "protocol": "UDP",
          "upperPort": 0
        }
      ],
      "networkApplications": [
        {
          "id": "d0a3aee4-f97c-42ec-8c30-7ea49c3e9780",
          "instanceId": 16169,
          "authEntityId": 16169,
          "displayName": "16169",
          "authEntityClass": -217092956,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "appProtocol": "tcp/udp",
          "applicationGroup": "other",
          "applicationType": "DEFAULT",
          "categoryId": "6daa3dfd-3526-407a-88f0-97824b3f3a24",
          "createTime": 1517966744533,
          "encrypted": "false",
          "engineId": "3",
          "helpString": "SU/MIT Telnet Gateway",
          "ignoreConflict": false,
          "lastUpdateTime": 1517966744533,
          "longDescription": "SU/MIT Telnet Gateway is used to enable users to telnet between networks running different protocols.",
          "name": "su-mit-tg",
          "nbarId": "960",
          "p2pTechnology": "false",
          "popularity": 1,
          "rank": 65535,
          "references": "http://donsnotes.com/tech/networks/internet/proto/su-mit-tg.html",
          "selectorId": "89",
          "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
          "trafficClass": "BULK_DATA",
          "tunnel": "false"
        }
      ],
      "networkIdentity": [
        {
          "id": "c1be6e2a-fa2e-4481-b91f-80f343d2faaf",
          "instanceId": 17308,
          "authEntityId": 17308,
          "displayName": "17308",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "89",
          "protocol": "UDP",
          "upperPort": 0
        },
        {
          "id": "f48ac977-7e8c-4d79-aced-e00dc201a9f9",
          "instanceId": 17309,
          "authEntityId": 17309,
          "displayName": "17309",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "89",
          "protocol": "TCP",
          "upperPort": 0
        }
      ],
      "parentScalableGroup": {
        "idRef": "000c15bf-28fe-4fbb-9b69-35e6502721f1"
      },
      "user": [

      ],
      "userGroup": [

      ]
    },
    {
      "id": "d6284111-7f86-48c4-bd47-4f1c02faf82a",
      "instanceId": 4377,
      "authEntityId": 4377,
      "displayName": "4377",
      "authEntityClass": -1909073334,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "createTime": 1517966742455,
      "deployed": false,
      "isSeeded": true,
      "isStale": false,
      "lastUpdateTime": 1517966742455,
      "name": "dmp",
      "namespace": "scalablegroup:application",
      "provisioningState": "DEFINED",
      "qualifier": "application",
      "resourceVersion": 0,
      "targetIdList": [

      ],
      "type": "scalablegroup",
      "cfsChangeInfo": [

      ],
      "customProvisions": [

      ],
      "scalableGroupExternalHandle": "dmp",
      "scalableGroupType": "APPLICATION",
      "securityGroupTag": 0,
      "state": "ACTIVE",
      "vnAgnostic": true,
      "identitySource": {
        "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
        "instanceId": 10010,
        "authEntityId": 10010,
        "displayName": "10010",
        "authEntityClass": 1738953278,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "state": "INACTIVE",
        "type": "NBAR"
      },
      "indicativeNetworkIdentity": [
        {
          "id": "2829660f-3f44-43f4-af21-43e0f3e55615",
          "instanceId": 12921,
          "authEntityId": 12921,
          "displayName": "12921",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "7777",
          "protocol": "TCP",
          "upperPort": 0
        }
      ],
      "networkApplications": [
        {
          "id": "1c1e8b75-2276-4386-987f-76e097d51396",
          "instanceId": 11328,
          "authEntityId": 11328,
          "displayName": "11328",
          "authEntityClass": -217092956,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "appProtocol": "tcp/udp",
          "applicationGroup": "other",
          "applicationType": "DEFAULT",
          "categoryId": "38a8e354-e2fe-4f3c-b1c5-2bbb90fb0d99",
          "createTime": 1517966742455,
          "encrypted": "true",
          "engineId": "13",
          "helpString": "Cisco Digital Media Player",
          "ignoreConflict": false,
          "lastUpdateTime": 1517966742455,
          "longDescription": "Digital Media Player is an IP-based integrated component of the Cisco Digital Media Suite. It controls the display and playback of rich digital media. DMP is fully manageable as a standalone device, however, when it is a part of the integrated Cisco Digital Signs and Cisco Cast systems, it functions as a digital media publishing endpoint. Managing the DMP can be achieved with using the Cisco Digital Media Manager (DMM), the centralized management system component of the Cisco Digital Media Suite. The underlying protocols are FTP (for transferring files), HTTP and HTTPS for browsing, RTP and RTSP for unicast streaming. The classification is for the traffic between DMM and DMP.",
          "name": "dmp",
          "nbarId": "1422",
          "p2pTechnology": "false",
          "popularity": 10,
          "rank": 65535,
          "references": "http://www.cisco.com/en/US/products/ps7220/index.html",
          "selectorId": "492",
          "subCategory": "d0124db5-106b-46ed-a7f2-e2256010b2d8",
          "trafficClass": "BROADCAST_VIDEO",
          "tunnel": "false"
        }
      ],
      "networkIdentity": [
        {
          "id": "eb1bd2df-d89b-461a-8582-e32872865140",
          "instanceId": 12919,
          "authEntityId": 12919,
          "displayName": "12919",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "53,5353",
          "protocol": "UDP",
          "upperPort": 0
        },
        {
          "id": "d828cd10-bd08-4016-b129-6289e82350dc",
          "instanceId": 12920,
          "authEntityId": 12920,
          "displayName": "12920",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "53,80,443,5353,7777",
          "protocol": "TCP",
          "upperPort": 0
        }
      ],
      "parentScalableGroup": {
        "idRef": "961b652e-1019-451e-86da-f9ff537016d2"
      },
      "user": [

      ],
      "userGroup": [

      ]
    },
    {
      "id": "d63e2004-ee49-4bf9-b656-c327ce204731",
      "instanceId": 4883,
      "authEntityId": 4883,
      "displayName": "4883",
      "authEntityClass": -1909073334,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "createTime": 1517966743677,
      "deployed": false,
      "isSeeded": true,
      "isStale": false,
      "lastUpdateTime": 1517966743677,
      "name": "nsfnet-igp",
      "namespace": "scalablegroup:application",
      "provisioningState": "DEFINED",
      "qualifier": "application",
      "resourceVersion": 0,
      "targetIdList": [

      ],
      "type": "scalablegroup",
      "cfsChangeInfo": [

      ],
      "customProvisions": [

      ],
      "scalableGroupExternalHandle": "nsfnet-igp",
      "scalableGroupType": "APPLICATION",
      "securityGroupTag": 0,
      "state": "ACTIVE",
      "vnAgnostic": true,
      "identitySource": {
        "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
        "instanceId": 10010,
        "authEntityId": 10010,
        "displayName": "10010",
        "authEntityClass": 1738953278,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "state": "INACTIVE",
        "type": "NBAR"
      },
      "indicativeNetworkIdentity": [
        {
          "id": "2b9339aa-d148-42d7-826f-a53190287fe7",
          "instanceId": 14297,
          "authEntityId": 14297,
          "displayName": "14297",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipProtocolNumbers": "85",
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "protocol": "NSFNET_IGP",
          "upperPort": 0
        }
      ],
      "networkApplications": [
        {
          "id": "d680a59d-f798-4417-8296-288cb4e4149f",
          "instanceId": 11832,
          "authEntityId": 11832,
          "displayName": "11832",
          "authEntityClass": -217092956,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "appProtocol": "ip",
          "applicationGroup": "other",
          "applicationType": "DEFAULT",
          "categoryId": "6daa3dfd-3526-407a-88f0-97824b3f3a24",
          "createTime": 1517966743677,
          "encrypted": "unassigned",
          "engineId": "1",
          "helpString": "NSFNET-IGP",
          "ignoreConflict": false,
          "lastUpdateTime": 1517966743677,
          "longDescription": "NSFNET-IGP",
          "name": "nsfnet-igp",
          "nbarId": "839",
          "p2pTechnology": "unassigned",
          "popularity": 2,
          "rank": 65535,
          "references": "http://en.wikipedia.org/wiki/List_of_IP_protocol_numbers",
          "selectorId": "85",
          "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
          "trafficClass": "BULK_DATA",
          "tunnel": "unassigned"
        }
      ],
      "networkIdentity": [
        {
          "id": "187729f2-ca2a-435f-9c8b-46d1ad8a88a9",
          "instanceId": 14296,
          "authEntityId": 14296,
          "displayName": "14296",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipProtocolNumbers": "85",
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "protocol": "NSFNET_IGP",
          "upperPort": 0
        }
      ],
      "parentScalableGroup": {
        "idRef": "000c15bf-28fe-4fbb-9b69-35e6502721f1"
      },
      "user": [

      ],
      "userGroup": [

      ]
    },
    {
      "id": "d644b7a2-ff53-4642-b97a-8ca7721e5f5f",
      "instanceId": 4427,
      "authEntityId": 4427,
      "displayName": "4427",
      "authEntityClass": -1909073334,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "createTime": 1517966742621,
      "deployed": false,
      "isSeeded": true,
      "isStale": false,
      "lastUpdateTime": 1517966742621,
      "name": "etherip",
      "namespace": "scalablegroup:application",
      "provisioningState": "DEFINED",
      "qualifier": "application",
      "resourceVersion": 0,
      "targetIdList": [

      ],
      "type": "scalablegroup",
      "cfsChangeInfo": [

      ],
      "customProvisions": [

      ],
      "scalableGroupExternalHandle": "etherip",
      "scalableGroupType": "APPLICATION",
      "securityGroupTag": 0,
      "state": "ACTIVE",
      "vnAgnostic": true,
      "identitySource": {
        "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
        "instanceId": 10010,
        "authEntityId": 10010,
        "displayName": "10010",
        "authEntityClass": 1738953278,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "state": "INACTIVE",
        "type": "NBAR"
      },
      "indicativeNetworkIdentity": [
        {
          "id": "8ab9163e-08ea-4177-a720-80bcd3fc1565",
          "instanceId": 13078,
          "authEntityId": 13078,
          "displayName": "13078",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipProtocolNumbers": "97",
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "protocol": "ETHERIP",
          "upperPort": 0
        }
      ],
      "networkApplications": [
        {
          "id": "b46a1a8d-0c53-4acf-ba3d-47a8f4d6b12c",
          "instanceId": 11378,
          "authEntityId": 11378,
          "displayName": "11378",
          "authEntityClass": -217092956,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "appProtocol": "ip",
          "applicationGroup": "other",
          "applicationType": "DEFAULT",
          "categoryId": "6daa3dfd-3526-407a-88f0-97824b3f3a24",
          "createTime": 1517966742621,
          "encrypted": "unassigned",
          "engineId": "1",
          "helpString": "Ethernet-within-IP Encapsulation",
          "ignoreConflict": false,
          "lastUpdateTime": 1517966742621,
          "longDescription": "EtherIP is a protocol used for tunneling Ethernet packets and IEEE 802.3 MAC frames across an IP internet. It is usually used when the Layer 3 protocol is not IP, or when the Layer 3 data is obscured by encryption. EtherIP is IP protocol number 97.",
          "name": "etherip",
          "nbarId": "851",
          "p2pTechnology": "unassigned",
          "popularity": 2,
          "rank": 65535,
          "references": "http://tools.ietf.org/html/rfc3378",
          "selectorId": "97",
          "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
          "trafficClass": "BULK_DATA",
          "tunnel": "unassigned"
        }
      ],
      "networkIdentity": [
        {
          "id": "69483f92-f36a-4f20-ae9e-f3b4003288dd",
          "instanceId": 13077,
          "authEntityId": 13077,
          "displayName": "13077",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipProtocolNumbers": "97",
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "protocol": "ETHERIP",
          "upperPort": 0
        }
      ],
      "parentScalableGroup": {
        "idRef": "000c15bf-28fe-4fbb-9b69-35e6502721f1"
      },
      "user": [

      ],
      "userGroup": [

      ]
    },
    {
      "id": "d653a9c0-214d-4f7a-b730-e74c32de5c47",
      "instanceId": 4168,
      "authEntityId": 4168,
      "displayName": "4168",
      "authEntityClass": -1909073334,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "createTime": 1517966741471,
      "deployed": false,
      "isSeeded": true,
      "isStale": false,
      "lastUpdateTime": 1517966741471,
      "name": "bbc",
      "namespace": "scalablegroup:application",
      "provisioningState": "DEFINED",
      "qualifier": "application",
      "resourceVersion": 0,
      "targetIdList": [

      ],
      "type": "scalablegroup",
      "cfsChangeInfo": [

      ],
      "customProvisions": [

      ],
      "scalableGroupExternalHandle": "bbc",
      "scalableGroupType": "APPLICATION",
      "securityGroupTag": 0,
      "state": "ACTIVE",
      "vnAgnostic": true,
      "identitySource": {
        "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
        "instanceId": 10010,
        "authEntityId": 10010,
        "displayName": "10010",
        "authEntityClass": 1738953278,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "state": "INACTIVE",
        "type": "NBAR"
      },
      "indicativeNetworkIdentity": [

      ],
      "networkApplications": [
        {
          "id": "83503afa-06ce-4e09-8336-2d8d550330ba",
          "instanceId": 11127,
          "authEntityId": 11127,
          "displayName": "11127",
          "authEntityClass": -217092956,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "appProtocol": "tcp/udp",
          "applicationGroup": "other",
          "applicationType": "DEFAULT",
          "categoryId": "878644b5-fe91-45b4-9204-31c84a73ba48",
          "createTime": 1517966741471,
          "encrypted": "false",
          "engineId": "13",
          "helpString": "BBC - Web Portal for news update.",
          "ignoreConflict": false,
          "lastUpdateTime": 1517966741471,
          "longDescription": "BBC - Web Portal for news update.",
          "name": "bbc",
          "nbarId": "1464",
          "p2pTechnology": "false",
          "popularity": 3,
          "rank": 65535,
          "references": "http://bbc.com",
          "selectorId": "621",
          "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
          "trafficClass": "TRANSACTIONAL_DATA",
          "tunnel": "false"
        }
      ],
      "networkIdentity": [
        {
          "id": "6ccf6ef2-ae06-4df4-8aaa-6f9135bc3718",
          "instanceId": 12350,
          "authEntityId": 12350,
          "displayName": "12350",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "53,5353",
          "protocol": "UDP",
          "upperPort": 0
        },
        {
          "id": "cc9e06aa-3a9a-4b3e-8667-51d57e09ac48",
          "instanceId": 12351,
          "authEntityId": 12351,
          "displayName": "12351",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "53,80,443,5353",
          "protocol": "TCP",
          "upperPort": 0
        }
      ],
      "parentScalableGroup": {
        "idRef": "28e08de4-d7b2-4521-84ce-bc1e5ee0b9cc"
      },
      "user": [

      ],
      "userGroup": [

      ]
    },
    {
      "id": "d67ffa18-3462-4ef2-b75e-b89dcbe2dac8",
      "instanceId": 15278,
      "authEntityId": 15278,
      "displayName": "15278",
      "authEntityClass": -1909073334,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "createTime": 1517966744642,
      "deployed": false,
      "isSeeded": true,
      "isStale": false,
      "lastUpdateTime": 1517966744642,
      "name": "the-daily-beast",
      "namespace": "scalablegroup:application",
      "provisioningState": "DEFINED",
      "qualifier": "application",
      "resourceVersion": 0,
      "targetIdList": [

      ],
      "type": "scalablegroup",
      "cfsChangeInfo": [

      ],
      "customProvisions": [

      ],
      "scalableGroupExternalHandle": "the-daily-beast",
      "scalableGroupType": "APPLICATION",
      "securityGroupTag": 0,
      "state": "ACTIVE",
      "vnAgnostic": true,
      "identitySource": {
        "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
        "instanceId": 10010,
        "authEntityId": 10010,
        "displayName": "10010",
        "authEntityClass": 1738953278,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "state": "INACTIVE",
        "type": "NBAR"
      },
      "indicativeNetworkIdentity": [

      ],
      "networkApplications": [
        {
          "id": "1add1a99-9044-46da-a59f-8a199375ccef",
          "instanceId": 16221,
          "authEntityId": 16221,
          "displayName": "16221",
          "authEntityClass": -217092956,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "appProtocol": "tcp/udp",
          "applicationGroup": "other",
          "applicationType": "DEFAULT",
          "categoryId": "878644b5-fe91-45b4-9204-31c84a73ba48",
          "createTime": 1517966744642,
          "encrypted": "false",
          "engineId": "13",
          "helpString": "The Daily Beast - American news reporting and opinion website.",
          "ignoreConflict": false,
          "lastUpdateTime": 1517966744642,
          "longDescription": "The Daily Beast - American news reporting and opinion website.",
          "name": "the-daily-beast",
          "nbarId": "1717",
          "p2pTechnology": "false",
          "popularity": 3,
          "rank": 65535,
          "references": "http://thedailybeast.com",
          "selectorId": "789",
          "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
          "trafficClass": "TRANSACTIONAL_DATA",
          "tunnel": "false"
        }
      ],
      "networkIdentity": [
        {
          "id": "01f95574-f1b0-4978-9156-b928777c2f6d",
          "instanceId": 17463,
          "authEntityId": 17463,
          "displayName": "17463",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "53,5353",
          "protocol": "UDP",
          "upperPort": 0
        },
        {
          "id": "be90acc0-3c06-4fbb-b235-7ab369b2923b",
          "instanceId": 17464,
          "authEntityId": 17464,
          "displayName": "17464",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "53,80,443,5353",
          "protocol": "TCP",
          "upperPort": 0
        }
      ],
      "parentScalableGroup": {
        "idRef": "28e08de4-d7b2-4521-84ce-bc1e5ee0b9cc"
      },
      "user": [

      ],
      "userGroup": [

      ]
    },
    {
      "id": "d686c565-f577-460a-ab80-b70dbcd31261",
      "instanceId": 15418,
      "authEntityId": 15418,
      "displayName": "15418",
      "authEntityClass": -1909073334,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "createTime": 1517966744930,
      "deployed": false,
      "isSeeded": true,
      "isStale": false,
      "lastUpdateTime": 1517966744930,
      "name": "windows-store",
      "namespace": "scalablegroup:application",
      "provisioningState": "DEFINED",
      "qualifier": "application",
      "resourceVersion": 0,
      "targetIdList": [

      ],
      "type": "scalablegroup",
      "cfsChangeInfo": [

      ],
      "customProvisions": [

      ],
      "scalableGroupExternalHandle": "windows-store",
      "scalableGroupType": "APPLICATION",
      "securityGroupTag": 0,
      "state": "ACTIVE",
      "vnAgnostic": true,
      "identitySource": {
        "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
        "instanceId": 10010,
        "authEntityId": 10010,
        "displayName": "10010",
        "authEntityClass": 1738953278,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "state": "INACTIVE",
        "type": "NBAR"
      },
      "indicativeNetworkIdentity": [

      ],
      "networkApplications": [
        {
          "id": "a9a16a5b-cc93-4893-a473-66c9a01f5ba3",
          "instanceId": 16361,
          "authEntityId": 16361,
          "displayName": "16361",
          "authEntityClass": -217092956,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "appProtocol": "tcp/udp",
          "applicationGroup": "other",
          "applicationType": "DEFAULT",
          "categoryId": "9d9132a2-d99b-49db-a414-9fc93dea6604",
          "createTime": 1517966744930,
          "encrypted": "true",
          "engineId": "13",
          "helpString": "Microsoft Windows Store",
          "ignoreConflict": false,
          "lastUpdateTime": 1517966744930,
          "longDescription": "Windows store is an application store operated by Microsoft. It has free and paid applications, as well as desktop and mobile applications written for Windows and Windows mobile.",
          "name": "windows-store",
          "nbarId": "1529",
          "p2pTechnology": "false",
          "popularity": 8,
          "rank": 65535,
          "references": "http://www.windowsstore.com/",
          "selectorId": "590",
          "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
          "trafficClass": "BULK_DATA",
          "tunnel": "false"
        }
      ],
      "networkIdentity": [
        {
          "id": "710c46a3-f2d1-4aca-aaa2-0c44af6179d5",
          "instanceId": 17847,
          "authEntityId": 17847,
          "displayName": "17847",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "53,80,443,5353",
          "protocol": "TCP",
          "upperPort": 0
        },
        {
          "id": "f7ce8c71-6935-4b18-86cc-050ccb6d7e63",
          "instanceId": 17848,
          "authEntityId": 17848,
          "displayName": "17848",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "53,5353",
          "protocol": "UDP",
          "upperPort": 0
        }
      ],
      "parentScalableGroup": {
        "idRef": "4a68bc73-53c6-4deb-9983-784a0338aefe"
      },
      "user": [

      ],
      "userGroup": [

      ]
    },
    {
      "id": "d6a4dfd5-e209-4ba8-bdf1-d49ce39e35a4",
      "instanceId": 4573,
      "authEntityId": 4573,
      "displayName": "4573",
      "authEntityClass": -1909073334,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "createTime": 1517966743024,
      "deployed": false,
      "isSeeded": true,
      "isStale": false,
      "lastUpdateTime": 1517966743024,
      "name": "iatp",
      "namespace": "scalablegroup:application",
      "provisioningState": "DEFINED",
      "qualifier": "application",
      "resourceVersion": 0,
      "targetIdList": [

      ],
      "type": "scalablegroup",
      "cfsChangeInfo": [

      ],
      "customProvisions": [

      ],
      "scalableGroupExternalHandle": "iatp",
      "scalableGroupType": "APPLICATION",
      "securityGroupTag": 0,
      "state": "ACTIVE",
      "vnAgnostic": true,
      "identitySource": {
        "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
        "instanceId": 10010,
        "authEntityId": 10010,
        "displayName": "10010",
        "authEntityClass": 1738953278,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "state": "INACTIVE",
        "type": "NBAR"
      },
      "indicativeNetworkIdentity": [
        {
          "id": "228bc887-e236-49f8-867d-44fa7a8bce49",
          "instanceId": 13434,
          "authEntityId": 13434,
          "displayName": "13434",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipProtocolNumbers": "117",
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "protocol": "IATP",
          "upperPort": 0
        }
      ],
      "networkApplications": [
        {
          "id": "c5b5bfba-2510-4c44-b6ad-a14d90d16a9b",
          "instanceId": 11523,
          "authEntityId": 11523,
          "displayName": "11523",
          "authEntityClass": -217092956,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "appProtocol": "ip",
          "applicationGroup": "other",
          "applicationType": "DEFAULT",
          "categoryId": "6daa3dfd-3526-407a-88f0-97824b3f3a24",
          "createTime": 1517966743024,
          "encrypted": "unassigned",
          "engineId": "1",
          "helpString": "Interactive Agent Transfer Protocol",
          "ignoreConflict": false,
          "lastUpdateTime": 1517966743024,
          "longDescription": "Registered with IANA as IP Protocol 117",
          "name": "iatp",
          "nbarId": "871",
          "p2pTechnology": "unassigned",
          "popularity": 1,
          "rank": 65535,
          "references": "http://www.iana.org/assignments/protocol-numbers/protocol-numbers.xml",
          "selectorId": "117",
          "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
          "trafficClass": "BULK_DATA",
          "tunnel": "unassigned"
        }
      ],
      "networkIdentity": [
        {
          "id": "6bdd0d1c-6dde-4e2c-940a-39f3315b8e77",
          "instanceId": 13433,
          "authEntityId": 13433,
          "displayName": "13433",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipProtocolNumbers": "117",
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "protocol": "IATP",
          "upperPort": 0
        }
      ],
      "parentScalableGroup": {
        "idRef": "000c15bf-28fe-4fbb-9b69-35e6502721f1"
      },
      "user": [

      ],
      "userGroup": [

      ]
    },
    {
      "id": "d6b3c009-b27d-479a-9936-ec26ffb7a05c",
      "instanceId": 4105,
      "authEntityId": 4105,
      "displayName": "4105",
      "authEntityClass": -1909073334,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "createTime": 1517966741138,
      "deployed": false,
      "isSeeded": true,
      "isStale": false,
      "lastUpdateTime": 1517966741138,
      "name": "apple-remote-desktop",
      "namespace": "scalablegroup:application",
      "provisioningState": "DEFINED",
      "qualifier": "application",
      "resourceVersion": 0,
      "targetIdList": [

      ],
      "type": "scalablegroup",
      "cfsChangeInfo": [

      ],
      "customProvisions": [

      ],
      "scalableGroupExternalHandle": "apple-remote-desktop",
      "scalableGroupType": "APPLICATION",
      "securityGroupTag": 0,
      "state": "ACTIVE",
      "vnAgnostic": true,
      "identitySource": {
        "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
        "instanceId": 10010,
        "authEntityId": 10010,
        "displayName": "10010",
        "authEntityClass": 1738953278,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "state": "INACTIVE",
        "type": "NBAR"
      },
      "indicativeNetworkIdentity": [
        {
          "id": "53e5f89b-37aa-42e3-92d9-85354bea195e",
          "instanceId": 12168,
          "authEntityId": 12168,
          "displayName": "12168",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "3283",
          "protocol": "UDP",
          "upperPort": 0
        },
        {
          "id": "a726fd31-21fc-4eed-9ccb-bcbe2ffb91a1",
          "instanceId": 12169,
          "authEntityId": 12169,
          "displayName": "12169",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "3283",
          "protocol": "TCP",
          "upperPort": 0
        }
      ],
      "networkApplications": [
        {
          "id": "fbaace28-af5e-4e8f-879c-b243f59883cd",
          "instanceId": 11067,
          "authEntityId": 11067,
          "displayName": "11067",
          "authEntityClass": -217092956,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "appProtocol": "tcp/udp",
          "applicationGroup": "other",
          "applicationType": "DEFAULT",
          "categoryId": "7e19fdea-6222-4c57-9e66-66c2340d6bc3",
          "createTime": 1517966741138,
          "encrypted": "true",
          "engineId": "3",
          "helpString": "Apple Remote Desktop",
          "ignoreConflict": false,
          "lastUpdateTime": 1517966741138,
          "longDescription": "Apple Remote Desktop (ARD), is a desktop management system for Mac OS X produced by Apple Inc. which allows users to remotely control or monitor other computers over a network. Apple Remote Desktop replaced a similar product called Apple Network Assistant.",
          "name": "apple-remote-desktop",
          "nbarId": "1475",
          "p2pTechnology": "false",
          "popularity": 9,
          "rank": 65535,
          "references": "http://www.apple.com/remotedesktop/",
          "selectorId": "3283",
          "subCategory": "ff664205-64f3-447e-acff-1ec1ba9a6c31",
          "trafficClass": "MULTIMEDIA_STREAMING",
          "tunnel": "false"
        }
      ],
      "networkIdentity": [
        {
          "id": "9dd4fa5e-e440-4ad8-8a0a-0f054fbb5b7b",
          "instanceId": 12166,
          "authEntityId": 12166,
          "displayName": "12166",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "3283,5800,5900,5901",
          "protocol": "UDP",
          "upperPort": 0
        },
        {
          "id": "b6b74104-047f-4682-a24e-908cfe68dc65",
          "instanceId": 12167,
          "authEntityId": 12167,
          "displayName": "12167",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "3283,5800,5900,5901",
          "protocol": "TCP",
          "upperPort": 0
        }
      ],
      "parentScalableGroup": {
        "idRef": "4a6b37c6-7e11-40fe-a6b1-719b24813145"
      },
      "user": [

      ],
      "userGroup": [

      ]
    },
    {
      "id": "d74ddc0f-8355-47d1-96ec-40948701da51",
      "instanceId": 4312,
      "authEntityId": 4312,
      "displayName": "4312",
      "authEntityClass": -1909073334,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "createTime": 1517966742244,
      "deployed": false,
      "isSeeded": true,
      "isStale": false,
      "lastUpdateTime": 1517966742244,
      "name": "cuseeme",
      "namespace": "scalablegroup:application",
      "provisioningState": "DEFINED",
      "qualifier": "application",
      "resourceVersion": 0,
      "targetIdList": [

      ],
      "type": "scalablegroup",
      "cfsChangeInfo": [

      ],
      "customProvisions": [

      ],
      "scalableGroupExternalHandle": "cuseeme",
      "scalableGroupType": "APPLICATION",
      "securityGroupTag": 0,
      "state": "ACTIVE",
      "vnAgnostic": true,
      "identitySource": {
        "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
        "instanceId": 10010,
        "authEntityId": 10010,
        "displayName": "10010",
        "authEntityClass": 1738953278,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "state": "INACTIVE",
        "type": "NBAR"
      },
      "indicativeNetworkIdentity": [
        {
          "id": "173718c3-35a6-4053-92b4-f7dc30e81d5a",
          "instanceId": 12708,
          "authEntityId": 12708,
          "displayName": "12708",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "7648,7649",
          "protocol": "TCP",
          "upperPort": 0
        },
        {
          "id": "9c67fe0b-d4c3-41bb-a944-570f35c82265",
          "instanceId": 12709,
          "authEntityId": 12709,
          "displayName": "12709",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "7648,7649,24032",
          "protocol": "UDP",
          "upperPort": 0
        }
      ],
      "networkApplications": [
        {
          "id": "52803190-52e4-43d0-a598-a01e4ae391b6",
          "instanceId": 11264,
          "authEntityId": 11264,
          "displayName": "11264",
          "authEntityClass": -217092956,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "appProtocol": "tcp/udp",
          "applicationGroup": "other",
          "applicationType": "DEFAULT",
          "categoryId": "0f6cab47-be95-4124-9db3-360ccd55f983",
          "createTime": 1517966742244,
          "encrypted": "false",
          "engineId": "13",
          "helpString": "CU-SeeMe desktop video conference",
          "ignoreConflict": false,
          "lastUpdateTime": 1517966742244,
          "longDescription": "CU-SeeMe is an Internet video conferencing client. CU-SeeMe can make point-to-point video calls without a server or make multi-point calls through server software. Later commercial versions of CU-SeeMe could also make point-to-point or multi-point calls to other vendor's standard-based H.323 endpoints and servers.",
          "name": "cuseeme",
          "nbarId": "12",
          "p2pTechnology": "false",
          "popularity": 6,
          "rank": 65535,
          "references": "http://en.wikipedia.org/wiki/CU-SeeMe",
          "selectorId": "12",
          "subCategory": "118c10a2-e272-47c1-92b9-4814ebe97138",
          "trafficClass": "MULTIMEDIA_CONFERENCING",
          "tunnel": "false"
        }
      ],
      "networkIdentity": [
        {
          "id": "d26255b4-a164-44d5-9e64-824314fcd76f",
          "instanceId": 12706,
          "authEntityId": 12706,
          "displayName": "12706",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "7648,7649",
          "protocol": "TCP",
          "upperPort": 0
        },
        {
          "id": "9f538a13-1318-4ed5-a948-f87eac2f8aa1",
          "instanceId": 12707,
          "authEntityId": 12707,
          "displayName": "12707",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "7648,7649,24032",
          "protocol": "UDP",
          "upperPort": 0
        }
      ],
      "parentScalableGroup": {
        "idRef": "fd6264c9-c16a-4311-a335-a3db039256f3"
      },
      "user": [

      ],
      "userGroup": [

      ]
    },
    {
      "id": "d76cf47c-25d4-4b8b-bcb3-2a6672fa0662",
      "instanceId": 4813,
      "authEntityId": 4813,
      "displayName": "4813",
      "authEntityClass": -1909073334,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "createTime": 1517966743543,
      "deployed": false,
      "isSeeded": true,
      "isStale": false,
      "lastUpdateTime": 1517966743543,
      "name": "mylex-mapd",
      "namespace": "scalablegroup:application",
      "provisioningState": "DEFINED",
      "qualifier": "application",
      "resourceVersion": 0,
      "targetIdList": [

      ],
      "type": "scalablegroup",
      "cfsChangeInfo": [

      ],
      "customProvisions": [

      ],
      "scalableGroupExternalHandle": "mylex-mapd",
      "scalableGroupType": "APPLICATION",
      "securityGroupTag": 0,
      "state": "ACTIVE",
      "vnAgnostic": true,
      "identitySource": {
        "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
        "instanceId": 10010,
        "authEntityId": 10010,
        "displayName": "10010",
        "authEntityClass": 1738953278,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "state": "INACTIVE",
        "type": "NBAR"
      },
      "indicativeNetworkIdentity": [
        {
          "id": "dac2e7d7-ee5d-4f6a-bdb9-6db921ad54c1",
          "instanceId": 14077,
          "authEntityId": 14077,
          "displayName": "14077",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "467",
          "protocol": "UDP",
          "upperPort": 0
        },
        {
          "id": "28650800-3e34-4868-bbca-921480ecb036",
          "instanceId": 14078,
          "authEntityId": 14078,
          "displayName": "14078",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "467",
          "protocol": "TCP",
          "upperPort": 0
        }
      ],
      "networkApplications": [
        {
          "id": "64c04691-e8c1-449c-91cf-f69893d284e0",
          "instanceId": 11762,
          "authEntityId": 11762,
          "displayName": "11762",
          "authEntityClass": -217092956,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "appProtocol": "tcp/udp",
          "applicationGroup": "other",
          "applicationType": "DEFAULT",
          "categoryId": "6daa3dfd-3526-407a-88f0-97824b3f3a24",
          "createTime": 1517966743543,
          "encrypted": "false",
          "engineId": "3",
          "helpString": "mylex-mapd",
          "ignoreConflict": false,
          "lastUpdateTime": 1517966743543,
          "longDescription": "Registered with IANA on port 467 TCP/UDP",
          "name": "mylex-mapd",
          "nbarId": "381",
          "p2pTechnology": "false",
          "popularity": 1,
          "rank": 65535,
          "references": "http://www.iana.org/assignments/service-names-port-numbers/service-names-port-numbers.xml",
          "selectorId": "467",
          "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
          "trafficClass": "BULK_DATA",
          "tunnel": "false"
        }
      ],
      "networkIdentity": [
        {
          "id": "a0194520-594c-400a-b8eb-7f886024685b",
          "instanceId": 14075,
          "authEntityId": 14075,
          "displayName": "14075",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "467",
          "protocol": "TCP",
          "upperPort": 0
        },
        {
          "id": "7468ad1c-524b-43fb-be35-45943064bf1a",
          "instanceId": 14076,
          "authEntityId": 14076,
          "displayName": "14076",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "467",
          "protocol": "UDP",
          "upperPort": 0
        }
      ],
      "parentScalableGroup": {
        "idRef": "000c15bf-28fe-4fbb-9b69-35e6502721f1"
      },
      "user": [

      ],
      "userGroup": [

      ]
    },
    {
      "id": "d7a9467b-8e25-4ce4-87dc-8bc14bf0e669",
      "instanceId": 15145,
      "authEntityId": 15145,
      "displayName": "15145",
      "authEntityClass": -1909073334,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "createTime": 1517966744337,
      "deployed": false,
      "isSeeded": true,
      "isStale": false,
      "lastUpdateTime": 1517966744337,
      "name": "sip-tls",
      "namespace": "scalablegroup:application",
      "provisioningState": "DEFINED",
      "qualifier": "application",
      "resourceVersion": 0,
      "targetIdList": [

      ],
      "type": "scalablegroup",
      "cfsChangeInfo": [

      ],
      "customProvisions": [

      ],
      "scalableGroupExternalHandle": "sip-tls",
      "scalableGroupType": "APPLICATION",
      "securityGroupTag": 0,
      "state": "ACTIVE",
      "vnAgnostic": true,
      "identitySource": {
        "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
        "instanceId": 10010,
        "authEntityId": 10010,
        "displayName": "10010",
        "authEntityClass": 1738953278,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "state": "INACTIVE",
        "type": "NBAR"
      },
      "indicativeNetworkIdentity": [
        {
          "id": "a77b2835-08f4-4c3f-8da6-188627fd3bc5",
          "instanceId": 17086,
          "authEntityId": 17086,
          "displayName": "17086",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "5061",
          "protocol": "UDP",
          "upperPort": 0
        },
        {
          "id": "41c7c108-ddd4-43ac-9dbc-4f26c7d29c56",
          "instanceId": 17087,
          "authEntityId": 17087,
          "displayName": "17087",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "5061",
          "protocol": "TCP",
          "upperPort": 0
        }
      ],
      "networkApplications": [
        {
          "id": "5f5a1c9a-d0cc-471f-baf0-fe27384e7f22",
          "instanceId": 16088,
          "authEntityId": 16088,
          "displayName": "16088",
          "authEntityClass": -217092956,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "appProtocol": "tcp/udp",
          "applicationGroup": "other",
          "applicationType": "DEFAULT",
          "categoryId": "38a8e354-e2fe-4f3c-b1c5-2bbb90fb0d99",
          "createTime": 1517966744337,
          "encrypted": "true",
          "engineId": "3",
          "helpString": "Secure Session Initiation Protocol tunneled in SSL or TLS",
          "ignoreConflict": false,
          "lastUpdateTime": 1517966744337,
          "longDescription": "Session Initiation Protocol- Transport Later Security (SIP-TLS) is an encrypted SIP traffic tunneled in SSL. This scheme of SIP is sometimes known as SIPS. SIPS uses port number of 5061 for communication.",
          "name": "sip-tls",
          "nbarId": "1428",
          "p2pTechnology": "false",
          "popularity": 10,
          "rank": 65535,
          "references": "http://www.privatewave.com/pages/viewpage.action?pageId=5406766",
          "selectorId": "5061",
          "subCategory": "68c4f989-5735-4e16-8d1c-6a9d89d5df9c",
          "trafficClass": "SIGNALING",
          "tunnel": "false"
        }
      ],
      "networkIdentity": [
        {
          "id": "cb4b4bdc-c4d7-4be3-89e5-05f89f331369",
          "instanceId": 17084,
          "authEntityId": 17084,
          "displayName": "17084",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "5061",
          "protocol": "UDP",
          "upperPort": 0
        },
        {
          "id": "ecf4581d-194f-484b-bbb7-7a2407b7e5e1",
          "instanceId": 17085,
          "authEntityId": 17085,
          "displayName": "17085",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "80,443,5061",
          "protocol": "TCP",
          "upperPort": 0
        }
      ],
      "parentScalableGroup": {
        "idRef": "a3243a74-3864-4041-a927-0201030da17c"
      },
      "user": [

      ],
      "userGroup": [

      ]
    },
    {
      "id": "d7b8bc62-2574-462d-8e8a-7437f49a2352",
      "instanceId": 4992,
      "authEntityId": 4992,
      "displayName": "4992",
      "authEntityClass": -1909073334,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "createTime": 1517966743996,
      "deployed": false,
      "isSeeded": true,
      "isStale": false,
      "lastUpdateTime": 1517966743996,
      "name": "ptcnameservice",
      "namespace": "scalablegroup:application",
      "provisioningState": "DEFINED",
      "qualifier": "application",
      "resourceVersion": 0,
      "targetIdList": [

      ],
      "type": "scalablegroup",
      "cfsChangeInfo": [

      ],
      "customProvisions": [

      ],
      "scalableGroupExternalHandle": "ptcnameservice",
      "scalableGroupType": "APPLICATION",
      "securityGroupTag": 0,
      "state": "ACTIVE",
      "vnAgnostic": true,
      "identitySource": {
        "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
        "instanceId": 10010,
        "authEntityId": 10010,
        "displayName": "10010",
        "authEntityClass": 1738953278,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "state": "INACTIVE",
        "type": "NBAR"
      },
      "indicativeNetworkIdentity": [
        {
          "id": "cb733383-b0f9-4f57-aa70-255775e017f0",
          "instanceId": 14632,
          "authEntityId": 14632,
          "displayName": "14632",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "597",
          "protocol": "UDP",
          "upperPort": 0
        },
        {
          "id": "427a3cf9-1db8-486c-a189-3d0ef4de6f88",
          "instanceId": 14633,
          "authEntityId": 14633,
          "displayName": "14633",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "597",
          "protocol": "TCP",
          "upperPort": 0
        }
      ],
      "networkApplications": [
        {
          "id": "5b0a2264-c72e-43a1-aa76-0e77111f59c8",
          "instanceId": 11941,
          "authEntityId": 11941,
          "displayName": "11941",
          "authEntityClass": -217092956,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "appProtocol": "tcp/udp",
          "applicationGroup": "other",
          "applicationType": "DEFAULT",
          "categoryId": "6daa3dfd-3526-407a-88f0-97824b3f3a24",
          "createTime": 1517966743996,
          "encrypted": "false",
          "engineId": "3",
          "helpString": "PTC Name Service",
          "ignoreConflict": false,
          "lastUpdateTime": 1517966743996,
          "longDescription": "PTC Name Service is a protocol used by Parametric Technology Corporation (PTC) in their products.",
          "name": "ptcnameservice",
          "nbarId": "511",
          "p2pTechnology": "false",
          "popularity": 1,
          "rank": 65535,
          "references": "http://www.ptc.com",
          "selectorId": "597",
          "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
          "trafficClass": "BULK_DATA",
          "tunnel": "false"
        }
      ],
      "networkIdentity": [
        {
          "id": "4a50ff42-cf99-40d7-a86e-153fb2b45326",
          "instanceId": 14630,
          "authEntityId": 14630,
          "displayName": "14630",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "597",
          "protocol": "UDP",
          "upperPort": 0
        },
        {
          "id": "39e50ca6-eabe-456e-8053-910f06f2d2eb",
          "instanceId": 14631,
          "authEntityId": 14631,
          "displayName": "14631",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "597",
          "protocol": "TCP",
          "upperPort": 0
        }
      ],
      "parentScalableGroup": {
        "idRef": "000c15bf-28fe-4fbb-9b69-35e6502721f1"
      },
      "user": [

      ],
      "userGroup": [

      ]
    },
    {
      "id": "d7d7f8b3-30a6-4f62-881b-ced29ad1b869",
      "instanceId": 4455,
      "authEntityId": 4455,
      "displayName": "4455",
      "authEntityClass": -1909073334,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "createTime": 1517966742714,
      "deployed": false,
      "isSeeded": true,
      "isStale": false,
      "lastUpdateTime": 1517966742714,
      "name": "flashmyspace",
      "namespace": "scalablegroup:application",
      "provisioningState": "DEFINED",
      "qualifier": "application",
      "resourceVersion": 0,
      "targetIdList": [

      ],
      "type": "scalablegroup",
      "cfsChangeInfo": [

      ],
      "customProvisions": [

      ],
      "scalableGroupExternalHandle": "flashmyspace",
      "scalableGroupType": "APPLICATION",
      "securityGroupTag": 0,
      "state": "ACTIVE",
      "vnAgnostic": true,
      "identitySource": {
        "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
        "instanceId": 10010,
        "authEntityId": 10010,
        "displayName": "10010",
        "authEntityClass": 1738953278,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "state": "INACTIVE",
        "type": "NBAR"
      },
      "indicativeNetworkIdentity": [

      ],
      "networkApplications": [
        {
          "id": "d04c8f04-c5da-4e7d-aa45-5d8673dbd380",
          "instanceId": 11406,
          "authEntityId": 11406,
          "displayName": "11406",
          "authEntityClass": -217092956,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "appProtocol": "tcp",
          "applicationGroup": "flash-group",
          "applicationType": "DEFAULT",
          "categoryId": "878644b5-fe91-45b4-9204-31c84a73ba48",
          "createTime": 1517966742714,
          "encrypted": "false",
          "engineId": "13",
          "helpString": "Flash streams at myspace.com",
          "ignoreConflict": false,
          "lastUpdateTime": 1517966742714,
          "longDescription": "Flash-myspace is a flash based video originated by MySpace website. Flash video is a format used to stream videos on the web,  the user required only for a flash player in order to view a flash video.",
          "name": "flashmyspace",
          "nbarId": "119",
          "p2pTechnology": "false",
          "popularity": 6,
          "rank": 65535,
          "references": "http://download.macromedia.com/f4v/video_file_format_spec_v10_1.pdf",
          "selectorId": "300",
          "subCategory": "fe150151-77ab-4ac6-805f-77d0d642b798",
          "trafficClass": "MULTIMEDIA_STREAMING",
          "tunnel": "false"
        }
      ],
      "networkIdentity": [
        {
          "id": "0b4d9daf-a8cc-4413-b8cf-5d81c91f250e",
          "instanceId": 13133,
          "authEntityId": 13133,
          "displayName": "13133",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "80",
          "protocol": "TCP",
          "upperPort": 0
        }
      ],
      "parentScalableGroup": {
        "idRef": "28e08de4-d7b2-4521-84ce-bc1e5ee0b9cc"
      },
      "user": [

      ],
      "userGroup": [

      ]
    },
    {
      "id": "d7e200cb-1549-4441-a8d4-2002221d2ab5",
      "instanceId": 4750,
      "authEntityId": 4750,
      "displayName": "4750",
      "authEntityClass": -1909073334,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "createTime": 1517966743419,
      "deployed": false,
      "isSeeded": true,
      "isStale": false,
      "lastUpdateTime": 1517966743419,
      "name": "mit-dov",
      "namespace": "scalablegroup:application",
      "provisioningState": "DEFINED",
      "qualifier": "application",
      "resourceVersion": 0,
      "targetIdList": [

      ],
      "type": "scalablegroup",
      "cfsChangeInfo": [

      ],
      "customProvisions": [

      ],
      "scalableGroupExternalHandle": "mit-dov",
      "scalableGroupType": "APPLICATION",
      "securityGroupTag": 0,
      "state": "ACTIVE",
      "vnAgnostic": true,
      "identitySource": {
        "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
        "instanceId": 10010,
        "authEntityId": 10010,
        "displayName": "10010",
        "authEntityClass": 1738953278,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "state": "INACTIVE",
        "type": "NBAR"
      },
      "indicativeNetworkIdentity": [
        {
          "id": "ea89a25a-983b-49ae-b254-588cc412e083",
          "instanceId": 13914,
          "authEntityId": 13914,
          "displayName": "13914",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "91",
          "protocol": "TCP",
          "upperPort": 0
        },
        {
          "id": "f375fae4-8fac-4e61-8c6b-689021791df8",
          "instanceId": 13915,
          "authEntityId": 13915,
          "displayName": "13915",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "91",
          "protocol": "UDP",
          "upperPort": 0
        }
      ],
      "networkApplications": [
        {
          "id": "dc0cf38b-4733-4b81-a57c-21ef35f919f4",
          "instanceId": 11700,
          "authEntityId": 11700,
          "displayName": "11700",
          "authEntityClass": -217092956,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "appProtocol": "tcp/udp",
          "applicationGroup": "other",
          "applicationType": "DEFAULT",
          "categoryId": "6daa3dfd-3526-407a-88f0-97824b3f3a24",
          "createTime": 1517966743419,
          "encrypted": "false",
          "engineId": "3",
          "helpString": "MIT Dover Spooler",
          "ignoreConflict": false,
          "lastUpdateTime": 1517966743419,
          "longDescription": "Registered with IANA on port 91 TCP/UDP",
          "name": "mit-dov",
          "nbarId": "962",
          "p2pTechnology": "false",
          "popularity": 1,
          "rank": 65535,
          "references": "http://www.iana.org/assignments/service-names-port-numbers/service-names-port-numbers.xml",
          "selectorId": "91",
          "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
          "trafficClass": "BULK_DATA",
          "tunnel": "false"
        }
      ],
      "networkIdentity": [
        {
          "id": "73747ce9-9d6a-4de1-9e1e-47165170f72d",
          "instanceId": 13912,
          "authEntityId": 13912,
          "displayName": "13912",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "91",
          "protocol": "TCP",
          "upperPort": 0
        },
        {
          "id": "1c7b7564-56fa-45a6-a1ab-cc28ec74a5bc",
          "instanceId": 13913,
          "authEntityId": 13913,
          "displayName": "13913",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "91",
          "protocol": "UDP",
          "upperPort": 0
        }
      ],
      "parentScalableGroup": {
        "idRef": "000c15bf-28fe-4fbb-9b69-35e6502721f1"
      },
      "user": [

      ],
      "userGroup": [

      ]
    },
    {
      "id": "d7e45c2e-12ee-4739-9090-b75d94a1bcb3",
      "instanceId": 4218,
      "authEntityId": 4218,
      "displayName": "4218",
      "authEntityClass": -1909073334,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "createTime": 1517966741775,
      "deployed": false,
      "isSeeded": true,
      "isStale": false,
      "lastUpdateTime": 1517966741775,
      "name": "cdc",
      "namespace": "scalablegroup:application",
      "provisioningState": "DEFINED",
      "qualifier": "application",
      "resourceVersion": 0,
      "targetIdList": [

      ],
      "type": "scalablegroup",
      "cfsChangeInfo": [

      ],
      "customProvisions": [

      ],
      "scalableGroupExternalHandle": "cdc",
      "scalableGroupType": "APPLICATION",
      "securityGroupTag": 0,
      "state": "ACTIVE",
      "vnAgnostic": true,
      "identitySource": {
        "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
        "instanceId": 10010,
        "authEntityId": 10010,
        "displayName": "10010",
        "authEntityClass": 1738953278,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "state": "INACTIVE",
        "type": "NBAR"
      },
      "indicativeNetworkIdentity": [
        {
          "id": "2c2eb577-a180-4ed6-8a1e-092d4986125f",
          "instanceId": 12473,
          "authEntityId": 12473,
          "displayName": "12473",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "223",
          "protocol": "TCP",
          "upperPort": 0
        },
        {
          "id": "59d349ec-c3f1-4ca6-9848-0cd0cfed5d90",
          "instanceId": 12474,
          "authEntityId": 12474,
          "displayName": "12474",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "223",
          "protocol": "UDP",
          "upperPort": 0
        }
      ],
      "networkApplications": [
        {
          "id": "54b5286a-86a2-4f43-92d3-7b85873541f7",
          "instanceId": 11173,
          "authEntityId": 11173,
          "displayName": "11173",
          "authEntityClass": -217092956,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "appProtocol": "tcp/udp",
          "applicationGroup": "other",
          "applicationType": "DEFAULT",
          "categoryId": "1fc17362-9334-4c7b-b752-5afdbb574017",
          "createTime": 1517966741775,
          "encrypted": "false",
          "engineId": "3",
          "helpString": "Certificate Distribution Center",
          "ignoreConflict": false,
          "lastUpdateTime": 1517966741775,
          "longDescription": "Registered with IANA on port 223 TCP/UDP",
          "name": "cdc",
          "nbarId": "1120",
          "p2pTechnology": "false",
          "popularity": 1,
          "rank": 65535,
          "references": "http://www.iana.org/assignments/service-names-port-numbers/service-names-port-numbers.xml",
          "selectorId": "223",
          "subCategory": "e390124a-0fd6-4253-b68e-4b0c1af8cb4b",
          "trafficClass": "OPS_ADMIN_MGMT",
          "tunnel": "false"
        }
      ],
      "networkIdentity": [
        {
          "id": "50ab26fc-0933-4546-b545-b4cf047f4710",
          "instanceId": 12471,
          "authEntityId": 12471,
          "displayName": "12471",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "223",
          "protocol": "TCP",
          "upperPort": 0
        },
        {
          "id": "7995f362-dbe6-403f-9208-816acb6583fb",
          "instanceId": 12472,
          "authEntityId": 12472,
          "displayName": "12472",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "223",
          "protocol": "UDP",
          "upperPort": 0
        }
      ],
      "parentScalableGroup": {
        "idRef": "7788bf2d-e289-4597-b500-17aacb7e820e"
      },
      "user": [

      ],
      "userGroup": [

      ]
    },
    {
      "id": "d7f4745f-1c46-4c80-baf5-6d01096c2213",
      "instanceId": 4653,
      "authEntityId": 4653,
      "displayName": "4653",
      "authEntityClass": -1909073334,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "createTime": 1517966743213,
      "deployed": false,
      "isSeeded": true,
      "isStale": false,
      "lastUpdateTime": 1517966743213,
      "name": "itm-mcell-s",
      "namespace": "scalablegroup:application",
      "provisioningState": "DEFINED",
      "qualifier": "application",
      "resourceVersion": 0,
      "targetIdList": [

      ],
      "type": "scalablegroup",
      "cfsChangeInfo": [

      ],
      "customProvisions": [

      ],
      "scalableGroupExternalHandle": "itm-mcell-s",
      "scalableGroupType": "APPLICATION",
      "securityGroupTag": 0,
      "state": "ACTIVE",
      "vnAgnostic": true,
      "identitySource": {
        "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
        "instanceId": 10010,
        "authEntityId": 10010,
        "displayName": "10010",
        "authEntityClass": 1738953278,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "state": "INACTIVE",
        "type": "NBAR"
      },
      "indicativeNetworkIdentity": [
        {
          "id": "5ca234a6-db0b-4d5b-b0c0-01bf9843f947",
          "instanceId": 13648,
          "authEntityId": 13648,
          "displayName": "13648",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "828",
          "protocol": "UDP",
          "upperPort": 0
        },
        {
          "id": "d574aa1d-88c1-43b5-9e06-2085a0280936",
          "instanceId": 13647,
          "authEntityId": 13647,
          "displayName": "13647",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "828",
          "protocol": "TCP",
          "upperPort": 0
        }
      ],
      "networkApplications": [
        {
          "id": "e5678b62-bf76-4e8a-b223-c76e54fa8cbe",
          "instanceId": 11603,
          "authEntityId": 11603,
          "displayName": "11603",
          "authEntityClass": -217092956,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "appProtocol": "tcp/udp",
          "applicationGroup": "other",
          "applicationType": "DEFAULT",
          "categoryId": "6daa3dfd-3526-407a-88f0-97824b3f3a24",
          "createTime": 1517966743213,
          "encrypted": "false",
          "engineId": "3",
          "helpString": "itm-mcell-s",
          "ignoreConflict": false,
          "lastUpdateTime": 1517966743213,
          "longDescription": "Registered with IANA on port 828 TCP/UDP",
          "name": "itm-mcell-s",
          "nbarId": "656",
          "p2pTechnology": "false",
          "popularity": 1,
          "rank": 65535,
          "references": "http://www.iana.org/assignments/service-names-port-numbers/service-names-port-numbers.xml",
          "selectorId": "828",
          "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
          "trafficClass": "BULK_DATA",
          "tunnel": "false"
        }
      ],
      "networkIdentity": [
        {
          "id": "dc18b958-1b17-4cf1-8cc1-ffc39d224731",
          "instanceId": 13645,
          "authEntityId": 13645,
          "displayName": "13645",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "828",
          "protocol": "UDP",
          "upperPort": 0
        },
        {
          "id": "185311ca-ba9d-4557-937a-e2798f1fb17e",
          "instanceId": 13646,
          "authEntityId": 13646,
          "displayName": "13646",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "828",
          "protocol": "TCP",
          "upperPort": 0
        }
      ],
      "parentScalableGroup": {
        "idRef": "000c15bf-28fe-4fbb-9b69-35e6502721f1"
      },
      "user": [

      ],
      "userGroup": [

      ]
    },
    {
      "id": "d8025fcc-f723-4633-acb5-1a51dc09a291",
      "instanceId": 4330,
      "authEntityId": 4330,
      "displayName": "4330",
      "authEntityClass": -1909073334,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "createTime": 1517966742302,
      "deployed": false,
      "isSeeded": true,
      "isStale": false,
      "lastUpdateTime": 1517966742302,
      "name": "daytime",
      "namespace": "scalablegroup:application",
      "provisioningState": "DEFINED",
      "qualifier": "application",
      "resourceVersion": 0,
      "targetIdList": [

      ],
      "type": "scalablegroup",
      "cfsChangeInfo": [

      ],
      "customProvisions": [

      ],
      "scalableGroupExternalHandle": "daytime",
      "scalableGroupType": "APPLICATION",
      "securityGroupTag": 0,
      "state": "ACTIVE",
      "vnAgnostic": true,
      "identitySource": {
        "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
        "instanceId": 10010,
        "authEntityId": 10010,
        "displayName": "10010",
        "authEntityClass": 1738953278,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "state": "INACTIVE",
        "type": "NBAR"
      },
      "indicativeNetworkIdentity": [
        {
          "id": "f268348f-7640-499e-a7db-5ccdad128c83",
          "instanceId": 12766,
          "authEntityId": 12766,
          "displayName": "12766",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "13",
          "protocol": "TCP",
          "upperPort": 0
        },
        {
          "id": "2a3ed598-8b66-445d-bf6a-555b9328e780",
          "instanceId": 12767,
          "authEntityId": 12767,
          "displayName": "12767",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "13",
          "protocol": "UDP",
          "upperPort": 0
        }
      ],
      "networkApplications": [
        {
          "id": "244ccdc5-1fbb-449d-b72e-8818011d1900",
          "instanceId": 11281,
          "authEntityId": 11281,
          "displayName": "11281",
          "authEntityClass": -217092956,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "appProtocol": "tcp/udp",
          "applicationGroup": "other",
          "applicationType": "DEFAULT",
          "categoryId": "1fc17362-9334-4c7b-b752-5afdbb574017",
          "createTime": 1517966742302,
          "encrypted": "false",
          "engineId": "3",
          "helpString": "Daytime (RFC 867)",
          "ignoreConflict": false,
          "lastUpdateTime": 1517966742302,
          "longDescription": "The Daytime Protocol is a debugging and measurement service. A host may connect to a server, that in turn returns an ASCII character string of the current date and time in an unspecified format.",
          "name": "daytime",
          "nbarId": "103",
          "p2pTechnology": "false",
          "popularity": 1,
          "rank": 65535,
          "references": "http://www.ietf.org/rfc/rfc867).txt",
          "selectorId": "13",
          "subCategory": "f3c0f761-d894-4112-ab89-b90fc84fd660",
          "trafficClass": "OPS_ADMIN_MGMT",
          "tunnel": "false"
        }
      ],
      "networkIdentity": [
        {
          "id": "15619499-3f3d-419f-b8a7-b6c3da72c035",
          "instanceId": 12764,
          "authEntityId": 12764,
          "displayName": "12764",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "13",
          "protocol": "TCP",
          "upperPort": 0
        },
        {
          "id": "c79cd69b-554c-44b7-ad99-998ea482bf43",
          "instanceId": 12765,
          "authEntityId": 12765,
          "displayName": "12765",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "13",
          "protocol": "UDP",
          "upperPort": 0
        }
      ],
      "parentScalableGroup": {
        "idRef": "5fcd74d4-8726-47f7-a088-c457863da7ef"
      },
      "user": [

      ],
      "userGroup": [

      ]
    },
    {
      "id": "d83121ec-7a10-4bec-a6a2-7be33c2db79e",
      "instanceId": 4274,
      "authEntityId": 4274,
      "displayName": "4274",
      "authEntityClass": -1909073334,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "createTime": 1517966742037,
      "deployed": false,
      "isSeeded": true,
      "isStale": false,
      "lastUpdateTime": 1517966742037,
      "name": "commerce",
      "namespace": "scalablegroup:application",
      "provisioningState": "DEFINED",
      "qualifier": "application",
      "resourceVersion": 0,
      "targetIdList": [

      ],
      "type": "scalablegroup",
      "cfsChangeInfo": [

      ],
      "customProvisions": [

      ],
      "scalableGroupExternalHandle": "commerce",
      "scalableGroupType": "APPLICATION",
      "securityGroupTag": 0,
      "state": "ACTIVE",
      "vnAgnostic": true,
      "identitySource": {
        "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
        "instanceId": 10010,
        "authEntityId": 10010,
        "displayName": "10010",
        "authEntityClass": 1738953278,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "state": "INACTIVE",
        "type": "NBAR"
      },
      "indicativeNetworkIdentity": [
        {
          "id": "e850ff81-6f8b-496c-a137-43291085a09f",
          "instanceId": 12598,
          "authEntityId": 12598,
          "displayName": "12598",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "542",
          "protocol": "TCP",
          "upperPort": 0
        },
        {
          "id": "f9081f75-7a7b-4207-87bc-b6f6b69678e9",
          "instanceId": 12599,
          "authEntityId": 12599,
          "displayName": "12599",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "542",
          "protocol": "UDP",
          "upperPort": 0
        }
      ],
      "networkApplications": [
        {
          "id": "ba8abf88-be69-4bb5-8744-57eda9dc502c",
          "instanceId": 11227,
          "authEntityId": 11227,
          "displayName": "11227",
          "authEntityClass": -217092956,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "appProtocol": "tcp/udp",
          "applicationGroup": "other",
          "applicationType": "DEFAULT",
          "categoryId": "6daa3dfd-3526-407a-88f0-97824b3f3a24",
          "createTime": 1517966742037,
          "encrypted": "false",
          "engineId": "3",
          "helpString": "commerce",
          "ignoreConflict": false,
          "lastUpdateTime": 1517966742037,
          "longDescription": "Registered with IANA on port 542 TCP/UDP",
          "name": "commerce",
          "nbarId": "460",
          "p2pTechnology": "false",
          "popularity": 1,
          "rank": 65535,
          "references": "http://www.iana.org/assignments/service-names-port-numbers/service-names-port-numbers.xml",
          "selectorId": "542",
          "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
          "trafficClass": "BULK_DATA",
          "tunnel": "false"
        }
      ],
      "networkIdentity": [
        {
          "id": "09920738-2903-4839-9c29-0d4598296068",
          "instanceId": 12596,
          "authEntityId": 12596,
          "displayName": "12596",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "542",
          "protocol": "UDP",
          "upperPort": 0
        },
        {
          "id": "73325901-54d7-4e10-8361-9fea85c8a9a0",
          "instanceId": 12597,
          "authEntityId": 12597,
          "displayName": "12597",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "542",
          "protocol": "TCP",
          "upperPort": 0
        }
      ],
      "parentScalableGroup": {
        "idRef": "000c15bf-28fe-4fbb-9b69-35e6502721f1"
      },
      "user": [

      ],
      "userGroup": [

      ]
    },
    {
      "id": "d841b19a-cc76-4364-9e27-61659f308a6f",
      "instanceId": 15064,
      "authEntityId": 15064,
      "displayName": "15064",
      "authEntityClass": -1909073334,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "createTime": 1517966744149,
      "deployed": false,
      "isSeeded": true,
      "isStale": false,
      "lastUpdateTime": 1517966744149,
      "name": "rsvd",
      "namespace": "scalablegroup:application",
      "provisioningState": "DEFINED",
      "qualifier": "application",
      "resourceVersion": 0,
      "targetIdList": [

      ],
      "type": "scalablegroup",
      "cfsChangeInfo": [

      ],
      "customProvisions": [

      ],
      "scalableGroupExternalHandle": "rsvd",
      "scalableGroupType": "APPLICATION",
      "securityGroupTag": 0,
      "state": "ACTIVE",
      "vnAgnostic": true,
      "identitySource": {
        "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
        "instanceId": 10010,
        "authEntityId": 10010,
        "displayName": "10010",
        "authEntityClass": 1738953278,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "state": "INACTIVE",
        "type": "NBAR"
      },
      "indicativeNetworkIdentity": [
        {
          "id": "fb7f6150-5b5d-487d-81db-f1d1286f8751",
          "instanceId": 14835,
          "authEntityId": 14835,
          "displayName": "14835",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "168",
          "protocol": "UDP",
          "upperPort": 0
        },
        {
          "id": "50a5d060-e179-4b81-9d43-2992a3361912",
          "instanceId": 14836,
          "authEntityId": 14836,
          "displayName": "14836",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "168",
          "protocol": "TCP",
          "upperPort": 0
        }
      ],
      "networkApplications": [
        {
          "id": "40a6ff1c-4b33-4687-9540-dff1e491491e",
          "instanceId": 12003,
          "authEntityId": 12003,
          "displayName": "12003",
          "authEntityClass": -217092956,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "appProtocol": "tcp/udp",
          "applicationGroup": "other",
          "applicationType": "DEFAULT",
          "categoryId": "6daa3dfd-3526-407a-88f0-97824b3f3a24",
          "createTime": 1517966744149,
          "encrypted": "false",
          "engineId": "3",
          "helpString": "rsvd",
          "ignoreConflict": false,
          "lastUpdateTime": 1517966744149,
          "longDescription": "Registered with IANA on port 168 TCP/UDP",
          "name": "rsvd",
          "nbarId": "1013",
          "p2pTechnology": "false",
          "popularity": 1,
          "rank": 65535,
          "references": "http://www.iana.org/assignments/service-names-port-numbers/service-names-port-numbers.xml",
          "selectorId": "168",
          "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
          "trafficClass": "BULK_DATA",
          "tunnel": "false"
        }
      ],
      "networkIdentity": [
        {
          "id": "e82d117a-9e45-428c-8ac5-2de5ddf59d23",
          "instanceId": 14833,
          "authEntityId": 14833,
          "displayName": "14833",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "168",
          "protocol": "UDP",
          "upperPort": 0
        },
        {
          "id": "23b64b40-9dd0-4508-b377-2ca14c7f5219",
          "instanceId": 14834,
          "authEntityId": 14834,
          "displayName": "14834",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "168",
          "protocol": "TCP",
          "upperPort": 0
        }
      ],
      "parentScalableGroup": {
        "idRef": "000c15bf-28fe-4fbb-9b69-35e6502721f1"
      },
      "user": [

      ],
      "userGroup": [

      ]
    },
    {
      "id": "d841e45f-3673-4181-8a97-99bc0fa62706",
      "instanceId": 4283,
      "authEntityId": 4283,
      "displayName": "4283",
      "authEntityClass": -1909073334,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "createTime": 1517966742134,
      "deployed": false,
      "isSeeded": true,
      "isStale": false,
      "lastUpdateTime": 1517966742134,
      "name": "connected-backup",
      "namespace": "scalablegroup:application",
      "provisioningState": "DEFINED",
      "qualifier": "application",
      "resourceVersion": 0,
      "targetIdList": [

      ],
      "type": "scalablegroup",
      "cfsChangeInfo": [

      ],
      "customProvisions": [

      ],
      "scalableGroupExternalHandle": "connected-backup",
      "scalableGroupType": "APPLICATION",
      "securityGroupTag": 0,
      "state": "ACTIVE",
      "vnAgnostic": true,
      "identitySource": {
        "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
        "instanceId": 10010,
        "authEntityId": 10010,
        "displayName": "10010",
        "authEntityClass": 1738953278,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "state": "INACTIVE",
        "type": "NBAR"
      },
      "indicativeNetworkIdentity": [

      ],
      "networkApplications": [
        {
          "id": "fae9ee76-264a-4eef-8b90-3f293bf55c0c",
          "instanceId": 11236,
          "authEntityId": 11236,
          "displayName": "11236",
          "authEntityClass": -217092956,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "applicationGroup": "other",
          "applicationType": "DEFAULT",
          "categoryId": "80909d4c-efe6-4878-9e1e-68281fd26edd",
          "createTime": 1517966742134,
          "encrypted": "true",
          "engineId": "13",
          "helpString": "Connected Backup ensures secure, and efficient data protection",
          "ignoreConflict": false,
          "lastUpdateTime": 1517966742134,
          "longDescription": "Connected-Backup ensures enterprise-proven, secure, and efficient data protection without disrupting users at work.",
          "name": "connected-backup",
          "nbarId": "1544",
          "p2pTechnology": "false",
          "popularity": 6,
          "rank": 65535,
          "references": "http://www.autonomy.com/products/connected-backup",
          "selectorId": "605",
          "subCategory": "697101ea-4e72-4b89-872a-e2d494155c78",
          "trafficClass": "BULK_DATA",
          "tunnel": "false"
        }
      ],
      "networkIdentity": [

      ],
      "parentScalableGroup": {
        "idRef": "f09a36de-df38-4ff8-b0be-56738afc75f7"
      },
      "user": [

      ],
      "userGroup": [

      ]
    },
    {
      "id": "d876b3af-5e94-4b15-adbd-1d5774b7a517",
      "instanceId": 4054,
      "authEntityId": 4054,
      "displayName": "4054",
      "authEntityClass": -1909073334,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "createTime": 1517966740627,
      "deployed": false,
      "isSeeded": true,
      "isStale": false,
      "lastUpdateTime": 1517966740627,
      "name": "acr-nema",
      "namespace": "scalablegroup:application",
      "provisioningState": "DEFINED",
      "qualifier": "application",
      "resourceVersion": 0,
      "targetIdList": [

      ],
      "type": "scalablegroup",
      "cfsChangeInfo": [

      ],
      "customProvisions": [

      ],
      "scalableGroupExternalHandle": "acr-nema",
      "scalableGroupType": "APPLICATION",
      "securityGroupTag": 0,
      "state": "ACTIVE",
      "vnAgnostic": true,
      "identitySource": {
        "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
        "instanceId": 10010,
        "authEntityId": 10010,
        "displayName": "10010",
        "authEntityClass": 1738953278,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "state": "INACTIVE",
        "type": "NBAR"
      },
      "indicativeNetworkIdentity": [
        {
          "id": "e4be3eb6-2a3b-4ff8-8747-8cc244f4b164",
          "instanceId": 12068,
          "authEntityId": 12068,
          "displayName": "12068",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "104",
          "protocol": "UDP",
          "upperPort": 0
        },
        {
          "id": "cab1ecb5-be25-4001-9daa-6cf068354d68",
          "instanceId": 12069,
          "authEntityId": 12069,
          "displayName": "12069",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "104",
          "protocol": "TCP",
          "upperPort": 0
        }
      ],
      "networkApplications": [
        {
          "id": "418e2e9d-c313-43d4-9239-f7041dbde013",
          "instanceId": 11027,
          "authEntityId": 11027,
          "displayName": "11027",
          "authEntityClass": -217092956,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "appProtocol": "tcp/udp",
          "applicationGroup": "other",
          "applicationType": "DEFAULT",
          "categoryId": "6daa3dfd-3526-407a-88f0-97824b3f3a24",
          "createTime": 1517966740627,
          "encrypted": "false",
          "engineId": "3",
          "helpString": "ACR-NEMA Digital Img",
          "ignoreConflict": false,
          "lastUpdateTime": 1517966740627,
          "longDescription": "ACR-NEMA Digital Img is a standard for handling, storing, printing, and transmitting information in medical imaging.",
          "name": "acr-nema",
          "nbarId": "975",
          "p2pTechnology": "false",
          "popularity": 1,
          "rank": 65535,
          "references": "http://medical.nema.org/Dicom/2011/11_01pu.pdf",
          "selectorId": "104",
          "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
          "trafficClass": "BULK_DATA",
          "tunnel": "false"
        }
      ],
      "networkIdentity": [
        {
          "id": "a376ca40-e8cd-42bf-b5d9-225c68f8b95f",
          "instanceId": 12066,
          "authEntityId": 12066,
          "displayName": "12066",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "104",
          "protocol": "TCP",
          "upperPort": 0
        },
        {
          "id": "eafadb76-64f0-4e73-a47c-2e9278cd5418",
          "instanceId": 12067,
          "authEntityId": 12067,
          "displayName": "12067",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "104",
          "protocol": "UDP",
          "upperPort": 0
        }
      ],
      "parentScalableGroup": {
        "idRef": "000c15bf-28fe-4fbb-9b69-35e6502721f1"
      },
      "user": [

      ],
      "userGroup": [

      ]
    },
    {
      "id": "d89c364d-6f9c-4209-b81a-29151330b5b5",
      "instanceId": 15188,
      "authEntityId": 15188,
      "displayName": "15188",
      "authEntityClass": -1909073334,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "createTime": 1517966744436,
      "deployed": false,
      "isSeeded": true,
      "isStale": false,
      "lastUpdateTime": 1517966744436,
      "name": "spiegel-online",
      "namespace": "scalablegroup:application",
      "provisioningState": "DEFINED",
      "qualifier": "application",
      "resourceVersion": 0,
      "targetIdList": [

      ],
      "type": "scalablegroup",
      "cfsChangeInfo": [

      ],
      "customProvisions": [

      ],
      "scalableGroupExternalHandle": "spiegel-online",
      "scalableGroupType": "APPLICATION",
      "securityGroupTag": 0,
      "state": "ACTIVE",
      "vnAgnostic": true,
      "identitySource": {
        "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
        "instanceId": 10010,
        "authEntityId": 10010,
        "displayName": "10010",
        "authEntityClass": 1738953278,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "state": "INACTIVE",
        "type": "NBAR"
      },
      "indicativeNetworkIdentity": [

      ],
      "networkApplications": [
        {
          "id": "c48d0e06-7e38-496d-b25f-83de297ac2e5",
          "instanceId": 16131,
          "authEntityId": 16131,
          "displayName": "16131",
          "authEntityClass": -217092956,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "appProtocol": "tcp/udp",
          "applicationGroup": "other",
          "applicationType": "DEFAULT",
          "categoryId": "878644b5-fe91-45b4-9204-31c84a73ba48",
          "createTime": 1517966744436,
          "encrypted": "false",
          "engineId": "13",
          "helpString": "Spiegel Online - Web portal for the Germans magazine Der Speigel.",
          "ignoreConflict": false,
          "lastUpdateTime": 1517966744436,
          "longDescription": "Spiegel Online - Web portal for the Germans magazine Der Speigel.",
          "name": "spiegel-online",
          "nbarId": "1608",
          "p2pTechnology": "false",
          "popularity": 3,
          "rank": 65535,
          "references": "http://spiegel.de",
          "selectorId": "678",
          "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
          "trafficClass": "TRANSACTIONAL_DATA",
          "tunnel": "false"
        }
      ],
      "networkIdentity": [
        {
          "id": "5102576e-1b66-43f0-afcf-2cdbd4f66390",
          "instanceId": 17202,
          "authEntityId": 17202,
          "displayName": "17202",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "53,80,443,5353",
          "protocol": "TCP",
          "upperPort": 0
        },
        {
          "id": "0acdc52a-6531-4b47-9849-d8eaff29845c",
          "instanceId": 17203,
          "authEntityId": 17203,
          "displayName": "17203",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "53,5353",
          "protocol": "UDP",
          "upperPort": 0
        }
      ],
      "parentScalableGroup": {
        "idRef": "28e08de4-d7b2-4521-84ce-bc1e5ee0b9cc"
      },
      "user": [

      ],
      "userGroup": [

      ]
    },
    {
      "id": "d8bb02db-4677-4162-acb1-ef751769357e",
      "instanceId": 4665,
      "authEntityId": 4665,
      "displayName": "4665",
      "authEntityClass": -1909073334,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "createTime": 1517966743238,
      "deployed": false,
      "isSeeded": true,
      "isStale": false,
      "lastUpdateTime": 1517966743238,
      "name": "joomla",
      "namespace": "scalablegroup:application",
      "provisioningState": "DEFINED",
      "qualifier": "application",
      "resourceVersion": 0,
      "targetIdList": [

      ],
      "type": "scalablegroup",
      "cfsChangeInfo": [

      ],
      "customProvisions": [

      ],
      "scalableGroupExternalHandle": "joomla",
      "scalableGroupType": "APPLICATION",
      "securityGroupTag": 0,
      "state": "ACTIVE",
      "vnAgnostic": true,
      "identitySource": {
        "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
        "instanceId": 10010,
        "authEntityId": 10010,
        "displayName": "10010",
        "authEntityClass": 1738953278,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "state": "INACTIVE",
        "type": "NBAR"
      },
      "indicativeNetworkIdentity": [

      ],
      "networkApplications": [
        {
          "id": "f43c9808-17c7-4992-a604-b438ff028909",
          "instanceId": 11615,
          "authEntityId": 11615,
          "displayName": "11615",
          "authEntityClass": -217092956,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "appProtocol": "tcp/udp",
          "applicationGroup": "other",
          "applicationType": "DEFAULT",
          "categoryId": "878644b5-fe91-45b4-9204-31c84a73ba48",
          "createTime": 1517966743238,
          "encrypted": "false",
          "engineId": "13",
          "helpString": "Joomla - Content Management System for building web sites.",
          "ignoreConflict": false,
          "lastUpdateTime": 1517966743238,
          "longDescription": "Joomla - Content Management System for building web sites.",
          "name": "joomla",
          "nbarId": "255",
          "p2pTechnology": "false",
          "popularity": 3,
          "rank": 65535,
          "references": "http://joomla.org",
          "selectorId": "419",
          "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
          "trafficClass": "TRANSACTIONAL_DATA",
          "tunnel": "false"
        }
      ],
      "networkIdentity": [
        {
          "id": "c4105b2e-32ba-407e-a5c0-49a2923fd4eb",
          "instanceId": 13668,
          "authEntityId": 13668,
          "displayName": "13668",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "53,80,443,5353",
          "protocol": "TCP",
          "upperPort": 0
        },
        {
          "id": "75612159-0b84-4057-8384-6dd4435019e4",
          "instanceId": 13669,
          "authEntityId": 13669,
          "displayName": "13669",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "53,5353",
          "protocol": "UDP",
          "upperPort": 0
        }
      ],
      "parentScalableGroup": {
        "idRef": "28e08de4-d7b2-4521-84ce-bc1e5ee0b9cc"
      },
      "user": [

      ],
      "userGroup": [

      ]
    },
    {
      "id": "d8c994b7-1d97-4f90-8587-4e2436edeaa0",
      "instanceId": 4281,
      "authEntityId": 4281,
      "displayName": "4281",
      "authEntityClass": -1909073334,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "createTime": 1517966742128,
      "deployed": false,
      "isSeeded": true,
      "isStale": false,
      "lastUpdateTime": 1517966742128,
      "name": "conference-server",
      "namespace": "scalablegroup:application",
      "provisioningState": "DEFINED",
      "qualifier": "application",
      "resourceVersion": 0,
      "targetIdList": [

      ],
      "type": "scalablegroup",
      "cfsChangeInfo": [

      ],
      "customProvisions": [

      ],
      "scalableGroupExternalHandle": "conference-server",
      "scalableGroupType": "APPLICATION",
      "securityGroupTag": 0,
      "state": "ACTIVE",
      "vnAgnostic": true,
      "identitySource": {
        "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
        "instanceId": 10010,
        "authEntityId": 10010,
        "displayName": "10010",
        "authEntityClass": 1738953278,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "state": "INACTIVE",
        "type": "NBAR"
      },
      "indicativeNetworkIdentity": [

      ],
      "networkApplications": [
        {
          "id": "3fe9250a-dbad-4d98-abf9-f1a472d2d025",
          "instanceId": 11234,
          "authEntityId": 11234,
          "displayName": "11234",
          "authEntityClass": -217092956,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "appProtocol": "tcp/udp",
          "applicationGroup": "cisco-phone-group",
          "applicationType": "DEFAULT",
          "categoryId": "38a8e354-e2fe-4f3c-b1c5-2bbb90fb0d99",
          "createTime": 1517966742128,
          "encrypted": "false",
          "engineId": "13",
          "helpString": "Media SIP server",
          "ignoreConflict": false,
          "lastUpdateTime": 1517966742128,
          "longDescription": "Conferencing Server establish communication between SIP users.",
          "name": "conference-server",
          "nbarId": "1748",
          "p2pTechnology": "false",
          "popularity": 10,
          "rank": 65535,
          "selectorId": "1748",
          "subCategory": "05178016-4555-4291-ab4e-43c2ab2a931a",
          "trafficClass": "MULTIMEDIA_CONFERENCING",
          "tunnel": "false"
        }
      ],
      "networkIdentity": [
        {
          "id": "b3cc8c6f-995d-4378-b4d4-e0d009a2c790",
          "instanceId": 12620,
          "authEntityId": 12620,
          "displayName": "12620",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "53,67,68,5060,5353",
          "protocol": "UDP",
          "upperPort": 0
        },
        {
          "id": "cd14b195-fdca-465a-a083-0805432845f6",
          "instanceId": 12621,
          "authEntityId": 12621,
          "displayName": "12621",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "53,80,443,5060,5353",
          "protocol": "TCP",
          "upperPort": 0
        }
      ],
      "parentScalableGroup": {
        "idRef": "60f1364d-2a02-4063-8ba1-07817abe0612"
      },
      "user": [

      ],
      "userGroup": [

      ]
    },
    {
      "id": "d8eb0946-7373-4259-b3ef-c11fe113ddff",
      "instanceId": 15254,
      "authEntityId": 15254,
      "displayName": "15254",
      "authEntityClass": -1909073334,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "createTime": 1517966744588,
      "deployed": false,
      "isSeeded": true,
      "isStale": false,
      "lastUpdateTime": 1517966744588,
      "name": "taringa",
      "namespace": "scalablegroup:application",
      "provisioningState": "DEFINED",
      "qualifier": "application",
      "resourceVersion": 0,
      "targetIdList": [

      ],
      "type": "scalablegroup",
      "cfsChangeInfo": [

      ],
      "customProvisions": [

      ],
      "scalableGroupExternalHandle": "taringa",
      "scalableGroupType": "APPLICATION",
      "securityGroupTag": 0,
      "state": "ACTIVE",
      "vnAgnostic": true,
      "identitySource": {
        "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
        "instanceId": 10010,
        "authEntityId": 10010,
        "displayName": "10010",
        "authEntityClass": 1738953278,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "state": "INACTIVE",
        "type": "NBAR"
      },
      "indicativeNetworkIdentity": [

      ],
      "networkApplications": [
        {
          "id": "f344ca1a-ea8e-434e-8204-3c49910952b2",
          "instanceId": 16197,
          "authEntityId": 16197,
          "displayName": "16197",
          "authEntityClass": -217092956,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "appProtocol": "tcp/udp",
          "applicationGroup": "other",
          "applicationType": "DEFAULT",
          "categoryId": "878644b5-fe91-45b4-9204-31c84a73ba48",
          "createTime": 1517966744588,
          "encrypted": "false",
          "engineId": "13",
          "helpString": "Taringa - Argentinian Social network.",
          "ignoreConflict": false,
          "lastUpdateTime": 1517966744588,
          "longDescription": "Taringa - Argentinian Social network.",
          "name": "taringa",
          "nbarId": "1715",
          "p2pTechnology": "false",
          "popularity": 3,
          "rank": 65535,
          "references": "http://taringa.net",
          "selectorId": "787",
          "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
          "trafficClass": "TRANSACTIONAL_DATA",
          "tunnel": "false"
        }
      ],
      "networkIdentity": [
        {
          "id": "1408452a-aab7-406d-8fd4-710ae7bf159a",
          "instanceId": 17402,
          "authEntityId": 17402,
          "displayName": "17402",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "53,80,443,5353",
          "protocol": "TCP",
          "upperPort": 0
        },
        {
          "id": "3d8f7311-3c42-425c-a3fb-24ae512337a0",
          "instanceId": 17403,
          "authEntityId": 17403,
          "displayName": "17403",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "53,5353",
          "protocol": "UDP",
          "upperPort": 0
        }
      ],
      "parentScalableGroup": {
        "idRef": "28e08de4-d7b2-4521-84ce-bc1e5ee0b9cc"
      },
      "user": [

      ],
      "userGroup": [

      ]
    },
    {
      "id": "d8f0dfce-1a92-4709-ad74-f03c837bbe86",
      "instanceId": 15389,
      "authEntityId": 15389,
      "displayName": "15389",
      "authEntityClass": -1909073334,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "createTime": 1517966744857,
      "deployed": false,
      "isSeeded": true,
      "isStale": false,
      "lastUpdateTime": 1517966744857,
      "name": "waste",
      "namespace": "scalablegroup:application",
      "provisioningState": "DEFINED",
      "qualifier": "application",
      "resourceVersion": 0,
      "targetIdList": [

      ],
      "type": "scalablegroup",
      "cfsChangeInfo": [

      ],
      "customProvisions": [

      ],
      "scalableGroupExternalHandle": "waste",
      "scalableGroupType": "APPLICATION",
      "securityGroupTag": 0,
      "state": "ACTIVE",
      "vnAgnostic": true,
      "identitySource": {
        "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
        "instanceId": 10010,
        "authEntityId": 10010,
        "displayName": "10010",
        "authEntityClass": 1738953278,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "state": "INACTIVE",
        "type": "NBAR"
      },
      "indicativeNetworkIdentity": [

      ],
      "networkApplications": [
        {
          "id": "1fe913c9-506a-42a8-9391-9db673ab2b13",
          "instanceId": 16332,
          "authEntityId": 16332,
          "displayName": "16332",
          "authEntityClass": -217092956,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "applicationGroup": "other",
          "applicationType": "DEFAULT",
          "categoryId": "b33b2761-d981-4734-810a-a6a1c18ab352",
          "createTime": 1517966744857,
          "encrypted": "true",
          "engineId": "13",
          "helpString": "Encrypted file sharing client",
          "ignoreConflict": false,
          "lastUpdateTime": 1517966744857,
          "longDescription": "Waste is an encrypted, decentralized peer to peer file sharing client.  Users can chat and share files with another, all with strong encryption.",
          "name": "waste",
          "nbarId": "574",
          "p2pTechnology": "true",
          "popularity": 5,
          "rank": 65535,
          "references": "http://waste.sourceforge.net/",
          "selectorId": "421",
          "subCategory": "79fe7198-9ce3-4c01-9b58-45a8df3d9513",
          "trafficClass": "BULK_DATA",
          "tunnel": "false"
        }
      ],
      "networkIdentity": [

      ],
      "parentScalableGroup": {
        "idRef": "7a1a3bbb-dc84-4a35-82a4-7e650c4c4761"
      },
      "user": [

      ],
      "userGroup": [

      ]
    },
    {
      "id": "d9779e90-64e2-478c-b510-37275d02aadb",
      "instanceId": 4980,
      "authEntityId": 4980,
      "displayName": "4980",
      "authEntityClass": -1909073334,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "createTime": 1517966743963,
      "deployed": false,
      "isSeeded": true,
      "isStale": false,
      "lastUpdateTime": 1517966743963,
      "name": "pptv",
      "namespace": "scalablegroup:application",
      "provisioningState": "DEFINED",
      "qualifier": "application",
      "resourceVersion": 0,
      "targetIdList": [

      ],
      "type": "scalablegroup",
      "cfsChangeInfo": [

      ],
      "customProvisions": [

      ],
      "scalableGroupExternalHandle": "pptv",
      "scalableGroupType": "APPLICATION",
      "securityGroupTag": 0,
      "state": "ACTIVE",
      "vnAgnostic": true,
      "identitySource": {
        "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
        "instanceId": 10010,
        "authEntityId": 10010,
        "displayName": "10010",
        "authEntityClass": 1738953278,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "state": "INACTIVE",
        "type": "NBAR"
      },
      "indicativeNetworkIdentity": [

      ],
      "networkApplications": [
        {
          "id": "9376c92c-846b-4418-ae0c-78cf5e388f84",
          "instanceId": 11929,
          "authEntityId": 11929,
          "displayName": "11929",
          "authEntityClass": -217092956,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "appProtocol": "tcp/udp",
          "applicationGroup": "other",
          "applicationType": "DEFAULT",
          "categoryId": "a09ef1b3-ba26-4372-aee8-309d50697e76",
          "createTime": 1517966743963,
          "encrypted": "false",
          "engineId": "13",
          "helpString": "P2P video streaming app",
          "ignoreConflict": false,
          "lastUpdateTime": 1517966743963,
          "longDescription": "PPTV (also known as PPLive) developed by PPLive, is a leading online TV service offering both live streaming and video-on-demand of TV programs/shows, movies, drama, sports, news and entertainment video contents. The service is accessible either from its website or from client software installation. It is now available for a variety of systems, including windows PC, Mac, Android mobile phones, and iPads etc.",
          "name": "pptv",
          "nbarId": "673",
          "p2pTechnology": "true",
          "popularity": 8,
          "rank": 65535,
          "references": "http://www.pptv.com",
          "selectorId": "542",
          "subCategory": "85a797c0-aef8-4284-91a7-677f33e29abb",
          "trafficClass": "MULTIMEDIA_STREAMING",
          "tunnel": "false"
        }
      ],
      "networkIdentity": [
        {
          "id": "aab766d6-de60-4bf6-93bd-e57a1059ccd8",
          "instanceId": 14592,
          "authEntityId": 14592,
          "displayName": "14592",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "53,80,5353",
          "protocol": "TCP",
          "upperPort": 0
        },
        {
          "id": "1df5e216-495f-450d-b27f-6f623e1e24c3",
          "instanceId": 14593,
          "authEntityId": 14593,
          "displayName": "14593",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "53,5353",
          "protocol": "UDP",
          "upperPort": 0
        }
      ],
      "parentScalableGroup": {
        "idRef": "fd6264c9-c16a-4311-a335-a3db039256f3"
      },
      "user": [

      ],
      "userGroup": [

      ]
    },
    {
      "id": "d97f12be-3f98-4576-9289-8ba6ce8fb619",
      "instanceId": 15091,
      "authEntityId": 15091,
      "displayName": "15091",
      "authEntityClass": -1909073334,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "createTime": 1517966744213,
      "deployed": false,
      "isSeeded": true,
      "isStale": false,
      "lastUpdateTime": 1517966744213,
      "name": "sat-expak",
      "namespace": "scalablegroup:application",
      "provisioningState": "DEFINED",
      "qualifier": "application",
      "resourceVersion": 0,
      "targetIdList": [

      ],
      "type": "scalablegroup",
      "cfsChangeInfo": [

      ],
      "customProvisions": [

      ],
      "scalableGroupExternalHandle": "sat-expak",
      "scalableGroupType": "APPLICATION",
      "securityGroupTag": 0,
      "state": "ACTIVE",
      "vnAgnostic": true,
      "identitySource": {
        "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
        "instanceId": 10010,
        "authEntityId": 10010,
        "displayName": "10010",
        "authEntityClass": 1738953278,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "state": "INACTIVE",
        "type": "NBAR"
      },
      "indicativeNetworkIdentity": [
        {
          "id": "4d079d64-8e89-4210-bdfb-3f6e92975715",
          "instanceId": 14909,
          "authEntityId": 14909,
          "displayName": "14909",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipProtocolNumbers": "64",
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "protocol": "SAT_EXPAK",
          "upperPort": 0
        }
      ],
      "networkApplications": [
        {
          "id": "db35cc22-7d7e-47d0-8207-ef69dfd07771",
          "instanceId": 16034,
          "authEntityId": 16034,
          "displayName": "16034",
          "authEntityClass": -217092956,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "appProtocol": "ip",
          "applicationGroup": "other",
          "applicationType": "DEFAULT",
          "categoryId": "6daa3dfd-3526-407a-88f0-97824b3f3a24",
          "createTime": 1517966744213,
          "encrypted": "unassigned",
          "engineId": "1",
          "helpString": "SATNET and Backroom EXPAK",
          "ignoreConflict": false,
          "lastUpdateTime": 1517966744213,
          "longDescription": "Registered with IANA as IP Protocol 64",
          "name": "sat-expak",
          "nbarId": "818",
          "p2pTechnology": "unassigned",
          "popularity": 1,
          "rank": 65535,
          "references": "http://www.iana.org/assignments/protocol-numbers/protocol-numbers.xml",
          "selectorId": "64",
          "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
          "trafficClass": "BULK_DATA",
          "tunnel": "unassigned"
        }
      ],
      "networkIdentity": [
        {
          "id": "155d00f4-2b57-4828-b1a5-192865b95bb5",
          "instanceId": 14908,
          "authEntityId": 14908,
          "displayName": "14908",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipProtocolNumbers": "64",
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "protocol": "SAT_EXPAK",
          "upperPort": 0
        }
      ],
      "parentScalableGroup": {
        "idRef": "000c15bf-28fe-4fbb-9b69-35e6502721f1"
      },
      "user": [

      ],
      "userGroup": [

      ]
    },
    {
      "id": "d9a83746-c810-4aea-b37a-0a7fcdaf58f5",
      "instanceId": 4945,
      "authEntityId": 4945,
      "displayName": "4945",
      "authEntityClass": -1909073334,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "createTime": 1517966743871,
      "deployed": false,
      "isSeeded": true,
      "isStale": false,
      "lastUpdateTime": 1517966743871,
      "name": "pcmail-srv",
      "namespace": "scalablegroup:application",
      "provisioningState": "DEFINED",
      "qualifier": "application",
      "resourceVersion": 0,
      "targetIdList": [

      ],
      "type": "scalablegroup",
      "cfsChangeInfo": [

      ],
      "customProvisions": [

      ],
      "scalableGroupExternalHandle": "pcmail-srv",
      "scalableGroupType": "APPLICATION",
      "securityGroupTag": 0,
      "state": "ACTIVE",
      "vnAgnostic": true,
      "identitySource": {
        "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
        "instanceId": 10010,
        "authEntityId": 10010,
        "displayName": "10010",
        "authEntityClass": 1738953278,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "state": "INACTIVE",
        "type": "NBAR"
      },
      "indicativeNetworkIdentity": [
        {
          "id": "125a041b-b948-443e-a136-fc60c7a7769d",
          "instanceId": 14497,
          "authEntityId": 14497,
          "displayName": "14497",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "158",
          "protocol": "TCP",
          "upperPort": 0
        },
        {
          "id": "64bc9406-7e03-4107-8f5c-43dbc53bcc39",
          "instanceId": 14498,
          "authEntityId": 14498,
          "displayName": "14498",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "158",
          "protocol": "UDP",
          "upperPort": 0
        }
      ],
      "networkApplications": [
        {
          "id": "c3222556-2422-463b-84bf-dbfcd0b03262",
          "instanceId": 11894,
          "authEntityId": 11894,
          "displayName": "11894",
          "authEntityClass": -217092956,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "appProtocol": "tcp/udp",
          "applicationGroup": "other",
          "applicationType": "DEFAULT",
          "categoryId": "6daa3dfd-3526-407a-88f0-97824b3f3a24",
          "createTime": 1517966743871,
          "encrypted": "false",
          "engineId": "3",
          "helpString": "PCMail Server",
          "ignoreConflict": false,
          "lastUpdateTime": 1517966743871,
          "longDescription": "PCMail is a distributed mail system providing mail service to an arbitrary number of users, each of whom owns one or more workstations.",
          "name": "pcmail-srv",
          "nbarId": "1004",
          "p2pTechnology": "false",
          "popularity": 1,
          "rank": 65535,
          "references": "http://tools.ietf.org/html/rfc1056",
          "selectorId": "158",
          "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
          "trafficClass": "BULK_DATA",
          "tunnel": "false"
        }
      ],
      "networkIdentity": [
        {
          "id": "7cccca2e-bd7b-4dc1-b6d2-41d40ba14dc8",
          "instanceId": 14496,
          "authEntityId": 14496,
          "displayName": "14496",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "158",
          "protocol": "UDP",
          "upperPort": 0
        },
        {
          "id": "00222763-0460-4b01-8767-8aef5f480962",
          "instanceId": 14495,
          "authEntityId": 14495,
          "displayName": "14495",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "158",
          "protocol": "TCP",
          "upperPort": 0
        }
      ],
      "parentScalableGroup": {
        "idRef": "000c15bf-28fe-4fbb-9b69-35e6502721f1"
      },
      "user": [

      ],
      "userGroup": [

      ]
    },
    {
      "id": "d9b38d0a-f36c-415d-bd69-d4b7896b5cfa",
      "instanceId": 4525,
      "authEntityId": 4525,
      "displayName": "4525",
      "authEntityClass": -1909073334,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "createTime": 1517966742890,
      "deployed": false,
      "isSeeded": true,
      "isStale": false,
      "lastUpdateTime": 1517966742890,
      "name": "hamachi",
      "namespace": "scalablegroup:application",
      "provisioningState": "DEFINED",
      "qualifier": "application",
      "resourceVersion": 0,
      "targetIdList": [

      ],
      "type": "scalablegroup",
      "cfsChangeInfo": [

      ],
      "customProvisions": [

      ],
      "scalableGroupExternalHandle": "hamachi",
      "scalableGroupType": "APPLICATION",
      "securityGroupTag": 0,
      "state": "ACTIVE",
      "vnAgnostic": true,
      "identitySource": {
        "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
        "instanceId": 10010,
        "authEntityId": 10010,
        "displayName": "10010",
        "authEntityClass": 1738953278,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "state": "INACTIVE",
        "type": "NBAR"
      },
      "indicativeNetworkIdentity": [
        {
          "id": "f0f91ac2-4280-4571-965e-871043f9eabc",
          "instanceId": 13308,
          "authEntityId": 13308,
          "displayName": "13308",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "12975",
          "protocol": "TCP",
          "upperPort": 0
        }
      ],
      "networkApplications": [
        {
          "id": "1022457b-187e-49a2-8ca8-f79cda9486a6",
          "instanceId": 11476,
          "authEntityId": 11476,
          "displayName": "11476",
          "authEntityClass": -217092956,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "appProtocol": "tcp",
          "applicationGroup": "other",
          "applicationType": "DEFAULT",
          "categoryId": "6daa3dfd-3526-407a-88f0-97824b3f3a24",
          "createTime": 1517966742890,
          "encrypted": "false",
          "engineId": "3",
          "helpString": "Shareware VPN application",
          "ignoreConflict": false,
          "lastUpdateTime": 1517966742890,
          "longDescription": "Hamachi is a zero-configuration virtual private network (VPN) shareware application that is capable of establishing direct links between computers that are behind NAT firewalls without requiring reconfiguration. It is available for Microsoft Windows, Linux and MAC OS.",
          "name": "hamachi",
          "nbarId": "1382",
          "p2pTechnology": "false",
          "popularity": 6,
          "rank": 65535,
          "references": "http://www.hamachi.cc/",
          "selectorId": "12975",
          "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
          "trafficClass": "BULK_DATA",
          "tunnel": "false"
        }
      ],
      "networkIdentity": [
        {
          "id": "40f68a54-0793-4403-ad7e-aa57debadda4",
          "instanceId": 13307,
          "authEntityId": 13307,
          "displayName": "13307",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "12975",
          "protocol": "TCP",
          "upperPort": 0
        }
      ],
      "parentScalableGroup": {
        "idRef": "000c15bf-28fe-4fbb-9b69-35e6502721f1"
      },
      "user": [

      ],
      "userGroup": [

      ]
    },
    {
      "id": "d9b4deb1-676b-4065-bc39-2e9a140aa4c7",
      "instanceId": 4607,
      "authEntityId": 4607,
      "displayName": "4607",
      "authEntityClass": -1909073334,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "createTime": 1517966743107,
      "deployed": false,
      "isSeeded": true,
      "isStale": false,
      "lastUpdateTime": 1517966743107,
      "name": "institute-of-health",
      "namespace": "scalablegroup:application",
      "provisioningState": "DEFINED",
      "qualifier": "application",
      "resourceVersion": 0,
      "targetIdList": [

      ],
      "type": "scalablegroup",
      "cfsChangeInfo": [

      ],
      "customProvisions": [

      ],
      "scalableGroupExternalHandle": "institute-of-health",
      "scalableGroupType": "APPLICATION",
      "securityGroupTag": 0,
      "state": "ACTIVE",
      "vnAgnostic": true,
      "identitySource": {
        "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
        "instanceId": 10010,
        "authEntityId": 10010,
        "displayName": "10010",
        "authEntityClass": 1738953278,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "state": "INACTIVE",
        "type": "NBAR"
      },
      "indicativeNetworkIdentity": [

      ],
      "networkApplications": [
        {
          "id": "2504fa01-59cc-455b-8d24-05da58977bda",
          "instanceId": 11557,
          "authEntityId": 11557,
          "displayName": "11557",
          "authEntityClass": -217092956,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "appProtocol": "tcp/udp",
          "applicationGroup": "other",
          "applicationType": "DEFAULT",
          "categoryId": "878644b5-fe91-45b4-9204-31c84a73ba48",
          "createTime": 1517966743107,
          "encrypted": "false",
          "engineId": "13",
          "helpString": "NIH - National Institute of Health and Human services.",
          "ignoreConflict": false,
          "lastUpdateTime": 1517966743107,
          "longDescription": "NIH - National Institute of Health and Human services.",
          "name": "institute-of-health",
          "nbarId": "1590",
          "p2pTechnology": "false",
          "popularity": 3,
          "rank": 65535,
          "references": "http://nih.gov",
          "selectorId": "661",
          "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
          "trafficClass": "TRANSACTIONAL_DATA",
          "tunnel": "false"
        }
      ],
      "networkIdentity": [
        {
          "id": "2d919a5b-48fb-4971-8710-92c1c69e1fa9",
          "instanceId": 13531,
          "authEntityId": 13531,
          "displayName": "13531",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "53,80,443,5353",
          "protocol": "TCP",
          "upperPort": 0
        },
        {
          "id": "1e44730a-0c3b-4b49-976a-7d7c3dc1ee99",
          "instanceId": 13532,
          "authEntityId": 13532,
          "displayName": "13532",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "53,5353",
          "protocol": "UDP",
          "upperPort": 0
        }
      ],
      "parentScalableGroup": {
        "idRef": "28e08de4-d7b2-4521-84ce-bc1e5ee0b9cc"
      },
      "user": [

      ],
      "userGroup": [

      ]
    },
    {
      "id": "d9ddff87-2ab0-4daa-8b5a-8a817997a9e3",
      "instanceId": 15148,
      "authEntityId": 15148,
      "displayName": "15148",
      "authEntityClass": -1909073334,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "createTime": 1517966744344,
      "deployed": false,
      "isSeeded": true,
      "isStale": false,
      "lastUpdateTime": 1517966744344,
      "name": "sitaramgmt",
      "namespace": "scalablegroup:application",
      "provisioningState": "DEFINED",
      "qualifier": "application",
      "resourceVersion": 0,
      "targetIdList": [

      ],
      "type": "scalablegroup",
      "cfsChangeInfo": [

      ],
      "customProvisions": [

      ],
      "scalableGroupExternalHandle": "sitaramgmt",
      "scalableGroupType": "APPLICATION",
      "securityGroupTag": 0,
      "state": "ACTIVE",
      "vnAgnostic": true,
      "identitySource": {
        "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
        "instanceId": 10010,
        "authEntityId": 10010,
        "displayName": "10010",
        "authEntityClass": 1738953278,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "state": "INACTIVE",
        "type": "NBAR"
      },
      "indicativeNetworkIdentity": [
        {
          "id": "15d5418a-d703-46cc-8ae1-dc75c6f40bdf",
          "instanceId": 17096,
          "authEntityId": 17096,
          "displayName": "17096",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "2630",
          "protocol": "TCP",
          "upperPort": 0
        },
        {
          "id": "3aa49379-9b7f-4b3a-887e-141e40316254",
          "instanceId": 17097,
          "authEntityId": 17097,
          "displayName": "17097",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "2630",
          "protocol": "UDP",
          "upperPort": 0
        }
      ],
      "networkApplications": [
        {
          "id": "0161a579-bb3e-4401-a329-77be60f84b08",
          "instanceId": 16091,
          "authEntityId": 16091,
          "displayName": "16091",
          "authEntityClass": -217092956,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "appProtocol": "tcp/udp",
          "applicationGroup": "other",
          "applicationType": "DEFAULT",
          "categoryId": "6daa3dfd-3526-407a-88f0-97824b3f3a24",
          "createTime": 1517966744343,
          "encrypted": "false",
          "engineId": "3",
          "helpString": "sitaramgmt",
          "ignoreConflict": false,
          "lastUpdateTime": 1517966744343,
          "longDescription": "The Sitara Network Protocol (SNP) addresses performance problems over the Internet, intranets, and extranet, providing a higher quality of service to traffic between the end points of a Sitara-enhanced connection.",
          "name": "sitaramgmt",
          "nbarId": "709",
          "p2pTechnology": "false",
          "popularity": 1,
          "rank": 65535,
          "references": "http://www.dejean.com/mc/sitara/tech_snp-paper.html",
          "selectorId": "2630",
          "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
          "trafficClass": "BULK_DATA",
          "tunnel": "false"
        }
      ],
      "networkIdentity": [
        {
          "id": "8a10f2ee-d112-42cd-8f46-e8be0148cda2",
          "instanceId": 17094,
          "authEntityId": 17094,
          "displayName": "17094",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "2630",
          "protocol": "TCP",
          "upperPort": 0
        },
        {
          "id": "91ad0a03-42a3-4ed8-bb19-7cefcd03aeb8",
          "instanceId": 17095,
          "authEntityId": 17095,
          "displayName": "17095",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "2630",
          "protocol": "UDP",
          "upperPort": 0
        }
      ],
      "parentScalableGroup": {
        "idRef": "000c15bf-28fe-4fbb-9b69-35e6502721f1"
      },
      "user": [

      ],
      "userGroup": [

      ]
    },
    {
      "id": "d9e43745-7c63-427e-9a54-53d43b04db9e",
      "instanceId": 15096,
      "authEntityId": 15096,
      "displayName": "15096",
      "authEntityClass": -1909073334,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "createTime": 1517966744225,
      "deployed": false,
      "isSeeded": true,
      "isStale": false,
      "lastUpdateTime": 1517966744225,
      "name": "sco-dtmgr",
      "namespace": "scalablegroup:application",
      "provisioningState": "DEFINED",
      "qualifier": "application",
      "resourceVersion": 0,
      "targetIdList": [

      ],
      "type": "scalablegroup",
      "cfsChangeInfo": [

      ],
      "customProvisions": [

      ],
      "scalableGroupExternalHandle": "sco-dtmgr",
      "scalableGroupType": "APPLICATION",
      "securityGroupTag": 0,
      "state": "ACTIVE",
      "vnAgnostic": true,
      "identitySource": {
        "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
        "instanceId": 10010,
        "authEntityId": 10010,
        "displayName": "10010",
        "authEntityClass": 1738953278,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "state": "INACTIVE",
        "type": "NBAR"
      },
      "indicativeNetworkIdentity": [
        {
          "id": "42398058-5656-40c4-8ae0-ebc01b413bb5",
          "instanceId": 14922,
          "authEntityId": 14922,
          "displayName": "14922",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "617",
          "protocol": "TCP",
          "upperPort": 0
        },
        {
          "id": "0f9015ce-4441-45d6-a93c-d2c592fc0ad3",
          "instanceId": 14923,
          "authEntityId": 14923,
          "displayName": "14923",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "617",
          "protocol": "UDP",
          "upperPort": 0
        }
      ],
      "networkApplications": [
        {
          "id": "140e2732-d475-4eb7-b703-ca8f69d9b199",
          "instanceId": 16039,
          "authEntityId": 16039,
          "displayName": "16039",
          "authEntityClass": -217092956,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "appProtocol": "tcp/udp",
          "applicationGroup": "other",
          "applicationType": "DEFAULT",
          "categoryId": "1fc17362-9334-4c7b-b752-5afdbb574017",
          "createTime": 1517966744225,
          "encrypted": "false",
          "engineId": "3",
          "helpString": "SCO Desktop Administration Server",
          "ignoreConflict": false,
          "lastUpdateTime": 1517966744225,
          "longDescription": "Registered with IANA on port 617 TCP/UDP",
          "name": "sco-dtmgr",
          "nbarId": "526",
          "p2pTechnology": "false",
          "popularity": 1,
          "rank": 65535,
          "references": "http://www.iana.org/assignments/service-names-port-numbers/service-names-port-numbers.xml",
          "selectorId": "617",
          "subCategory": "f3c0f761-d894-4112-ab89-b90fc84fd660",
          "trafficClass": "OPS_ADMIN_MGMT",
          "tunnel": "false"
        }
      ],
      "networkIdentity": [
        {
          "id": "56c835db-4965-48a1-97bb-577e5875c315",
          "instanceId": 14920,
          "authEntityId": 14920,
          "displayName": "14920",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "617",
          "protocol": "UDP",
          "upperPort": 0
        },
        {
          "id": "7b14dc37-6525-4da4-88c2-ac064c5da011",
          "instanceId": 14921,
          "authEntityId": 14921,
          "displayName": "14921",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "617",
          "protocol": "TCP",
          "upperPort": 0
        }
      ],
      "parentScalableGroup": {
        "idRef": "5fcd74d4-8726-47f7-a088-c457863da7ef"
      },
      "user": [

      ],
      "userGroup": [

      ]
    },
    {
      "id": "d9f2fcfa-12be-4acf-982a-bc3eaac8ef39",
      "instanceId": 4676,
      "authEntityId": 4676,
      "displayName": "4676",
      "authEntityClass": -1909073334,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "createTime": 1517966743261,
      "deployed": false,
      "isSeeded": true,
      "isStale": false,
      "lastUpdateTime": 1517966743261,
      "name": "kickstarter",
      "namespace": "scalablegroup:application",
      "provisioningState": "DEFINED",
      "qualifier": "application",
      "resourceVersion": 0,
      "targetIdList": [

      ],
      "type": "scalablegroup",
      "cfsChangeInfo": [

      ],
      "customProvisions": [

      ],
      "scalableGroupExternalHandle": "kickstarter",
      "scalableGroupType": "APPLICATION",
      "securityGroupTag": 0,
      "state": "ACTIVE",
      "vnAgnostic": true,
      "identitySource": {
        "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
        "instanceId": 10010,
        "authEntityId": 10010,
        "displayName": "10010",
        "authEntityClass": 1738953278,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "state": "INACTIVE",
        "type": "NBAR"
      },
      "indicativeNetworkIdentity": [

      ],
      "networkApplications": [
        {
          "id": "1cd1ea6f-1fb6-46f8-9038-f59d2b371bcb",
          "instanceId": 11626,
          "authEntityId": 11626,
          "displayName": "11626",
          "authEntityClass": -217092956,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "appProtocol": "tcp/udp",
          "applicationGroup": "other",
          "applicationType": "DEFAULT",
          "categoryId": "878644b5-fe91-45b4-9204-31c84a73ba48",
          "createTime": 1517966743261,
          "encrypted": "false",
          "engineId": "13",
          "helpString": "Kickstarter - Platform for creative projects with funding goal and deadline.",
          "ignoreConflict": false,
          "lastUpdateTime": 1517966743261,
          "longDescription": "Kickstarter - Platform for creative projects with funding goal and deadline.",
          "name": "kickstarter",
          "nbarId": "1616",
          "p2pTechnology": "false",
          "popularity": 3,
          "rank": 65535,
          "references": "http://kickstarter.com",
          "selectorId": "686",
          "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
          "trafficClass": "TRANSACTIONAL_DATA",
          "tunnel": "false"
        }
      ],
      "networkIdentity": [
        {
          "id": "78262e73-5933-42ea-828c-8e2ee49a6add",
          "instanceId": 13698,
          "authEntityId": 13698,
          "displayName": "13698",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "53,5353",
          "protocol": "UDP",
          "upperPort": 0
        },
        {
          "id": "26ba5851-c07b-4143-989f-1bdc15afcfbe",
          "instanceId": 13699,
          "authEntityId": 13699,
          "displayName": "13699",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "53,80,443,5353",
          "protocol": "TCP",
          "upperPort": 0
        }
      ],
      "parentScalableGroup": {
        "idRef": "28e08de4-d7b2-4521-84ce-bc1e5ee0b9cc"
      },
      "user": [

      ],
      "userGroup": [

      ]
    },
    {
      "id": "d9f7b982-c92c-49e4-b297-4be0574e815e",
      "instanceId": 4313,
      "authEntityId": 4313,
      "displayName": "4313",
      "authEntityClass": -1909073334,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "createTime": 1517966742249,
      "deployed": false,
      "isSeeded": true,
      "isStale": false,
      "lastUpdateTime": 1517966742249,
      "name": "custix",
      "namespace": "scalablegroup:application",
      "provisioningState": "DEFINED",
      "qualifier": "application",
      "resourceVersion": 0,
      "targetIdList": [

      ],
      "type": "scalablegroup",
      "cfsChangeInfo": [

      ],
      "customProvisions": [

      ],
      "scalableGroupExternalHandle": "custix",
      "scalableGroupType": "APPLICATION",
      "securityGroupTag": 0,
      "state": "ACTIVE",
      "vnAgnostic": true,
      "identitySource": {
        "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
        "instanceId": 10010,
        "authEntityId": 10010,
        "displayName": "10010",
        "authEntityClass": 1738953278,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "state": "INACTIVE",
        "type": "NBAR"
      },
      "indicativeNetworkIdentity": [
        {
          "id": "0c813910-9d00-4c69-8082-11b84a3b9c86",
          "instanceId": 12712,
          "authEntityId": 12712,
          "displayName": "12712",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "528",
          "protocol": "TCP",
          "upperPort": 0
        },
        {
          "id": "51397ecc-4149-4b7d-9aed-3840b8c772a9",
          "instanceId": 12713,
          "authEntityId": 12713,
          "displayName": "12713",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "528",
          "protocol": "UDP",
          "upperPort": 0
        }
      ],
      "networkApplications": [
        {
          "id": "ebc1cc3d-9019-48b7-b7ad-052028b448aa",
          "instanceId": 11265,
          "authEntityId": 11265,
          "displayName": "11265",
          "authEntityClass": -217092956,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "appProtocol": "tcp/udp",
          "applicationGroup": "other",
          "applicationType": "DEFAULT",
          "categoryId": "6daa3dfd-3526-407a-88f0-97824b3f3a24",
          "createTime": 1517966742249,
          "encrypted": "false",
          "engineId": "3",
          "helpString": "Customer Ixchange",
          "ignoreConflict": false,
          "lastUpdateTime": 1517966742249,
          "longDescription": "Registered with IANA on port 528 TCP/UDP",
          "name": "custix",
          "nbarId": "446",
          "p2pTechnology": "false",
          "popularity": 1,
          "rank": 65535,
          "references": "http://www.iana.org/assignments/service-names-port-numbers/service-names-port-numbers.xml",
          "selectorId": "528",
          "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
          "trafficClass": "BULK_DATA",
          "tunnel": "false"
        }
      ],
      "networkIdentity": [
        {
          "id": "0cd23830-08d5-4ef1-91f4-ab8db57f76f7",
          "instanceId": 12710,
          "authEntityId": 12710,
          "displayName": "12710",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "528",
          "protocol": "TCP",
          "upperPort": 0
        },
        {
          "id": "b990fa71-dcdf-479e-b7aa-6647dc2a7313",
          "instanceId": 12711,
          "authEntityId": 12711,
          "displayName": "12711",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "528",
          "protocol": "UDP",
          "upperPort": 0
        }
      ],
      "parentScalableGroup": {
        "idRef": "000c15bf-28fe-4fbb-9b69-35e6502721f1"
      },
      "user": [

      ],
      "userGroup": [

      ]
    },
    {
      "id": "da063ee6-2cf7-449a-8893-27d90acbde94",
      "instanceId": 4581,
      "authEntityId": 4581,
      "displayName": "4581",
      "authEntityClass": -1909073334,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "createTime": 1517966743045,
      "deployed": false,
      "isSeeded": true,
      "isStale": false,
      "lastUpdateTime": 1517966743045,
      "name": "icloud",
      "namespace": "scalablegroup:application",
      "provisioningState": "DEFINED",
      "qualifier": "application",
      "resourceVersion": 0,
      "targetIdList": [

      ],
      "type": "scalablegroup",
      "cfsChangeInfo": [

      ],
      "customProvisions": [

      ],
      "scalableGroupExternalHandle": "icloud",
      "scalableGroupType": "APPLICATION",
      "securityGroupTag": 0,
      "state": "ACTIVE",
      "vnAgnostic": true,
      "identitySource": {
        "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
        "instanceId": 10010,
        "authEntityId": 10010,
        "displayName": "10010",
        "authEntityClass": 1738953278,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "state": "INACTIVE",
        "type": "NBAR"
      },
      "indicativeNetworkIdentity": [

      ],
      "networkApplications": [
        {
          "id": "8696c15e-5cc6-45e2-a0ad-6ed6f3a97081",
          "instanceId": 11531,
          "authEntityId": 11531,
          "displayName": "11531",
          "authEntityClass": -217092956,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "appProtocol": "tcp/udp",
          "applicationGroup": "apple-group",
          "applicationType": "DEFAULT",
          "categoryId": "f6ec0221-b82d-43e8-a64d-163cbc13263b",
          "createTime": 1517966743045,
          "encrypted": "true",
          "engineId": "13",
          "helpString": "iCloud - Apple cloud services",
          "ignoreConflict": false,
          "lastUpdateTime": 1517966743045,
          "longDescription": "iCloud is Apple's cloud computing and storage service. It provides data storage (such as music, files and iOS applications) over remote servers and enables downloading stored data to multiple devices.",
          "name": "icloud",
          "nbarId": "1501",
          "p2pTechnology": "false",
          "popularity": 10,
          "rank": 65535,
          "references": "http://www.apple.com/icloud/",
          "selectorId": "564",
          "subCategory": "9fecfe24-2ee3-4b19-bbf7-3c992d1d25b4",
          "trafficClass": "BULK_DATA",
          "tunnel": "false"
        }
      ],
      "networkIdentity": [
        {
          "id": "18558af8-50df-4957-a646-5ffe52f74170",
          "instanceId": 13463,
          "authEntityId": 13463,
          "displayName": "13463",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "53,80,443,5353",
          "protocol": "TCP",
          "upperPort": 0
        },
        {
          "id": "3a618995-925d-4597-bb1e-397f5c966f80",
          "instanceId": 13464,
          "authEntityId": 13464,
          "displayName": "13464",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "53,5353",
          "protocol": "UDP",
          "upperPort": 0
        }
      ],
      "parentScalableGroup": {
        "idRef": "28e08de4-d7b2-4521-84ce-bc1e5ee0b9cc"
      },
      "user": [

      ],
      "userGroup": [

      ]
    },
    {
      "id": "da1aad34-e432-4387-9b74-9d98ae3a5cb0",
      "instanceId": 4780,
      "authEntityId": 4780,
      "displayName": "4780",
      "authEntityClass": -1909073334,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "createTime": 1517966743477,
      "deployed": false,
      "isSeeded": true,
      "isStale": false,
      "lastUpdateTime": 1517966743477,
      "name": "ms-netlogon",
      "namespace": "scalablegroup:application",
      "provisioningState": "DEFINED",
      "qualifier": "application",
      "resourceVersion": 0,
      "targetIdList": [

      ],
      "type": "scalablegroup",
      "cfsChangeInfo": [

      ],
      "customProvisions": [

      ],
      "scalableGroupExternalHandle": "ms-netlogon",
      "scalableGroupType": "APPLICATION",
      "securityGroupTag": 0,
      "state": "ACTIVE",
      "vnAgnostic": true,
      "identitySource": {
        "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
        "instanceId": 10010,
        "authEntityId": 10010,
        "displayName": "10010",
        "authEntityClass": 1738953278,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "state": "INACTIVE",
        "type": "NBAR"
      },
      "indicativeNetworkIdentity": [

      ],
      "networkApplications": [
        {
          "id": "b0fd9d74-42ee-4510-a995-285ea64a9ea3",
          "instanceId": 11729,
          "authEntityId": 11729,
          "displayName": "11729",
          "authEntityClass": -217092956,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "appProtocol": "tcp",
          "applicationGroup": "other",
          "applicationType": "DEFAULT",
          "categoryId": "1fc17362-9334-4c7b-b752-5afdbb574017",
          "createTime": 1517966743477,
          "encrypted": "false",
          "engineId": "13",
          "helpString": "MS-RPC Net Logon, log on management service",
          "ignoreConflict": false,
          "lastUpdateTime": 1517966743477,
          "longDescription": "The Net Logon service verifies logon requests, and it registers, authenticates, and locates domain controllers. To maintain backward compatibility Net Logon manages replication of the user account database to back-up domain controllers running Windows NT 4.0 and earlier. It is based on Microsoft Remote Procedure Call (MS RPC).",
          "name": "ms-netlogon",
          "nbarId": "1412",
          "p2pTechnology": "false",
          "popularity": 9,
          "rank": 65535,
          "references": "http://technet.microsoft.com/en-us/library/cc962284.aspx",
          "selectorId": "483",
          "subCategory": "e390124a-0fd6-4253-b68e-4b0c1af8cb4b",
          "trafficClass": "OPS_ADMIN_MGMT",
          "tunnel": "false"
        }
      ],
      "networkIdentity": [
        {
          "id": "eb499e64-3ae6-454f-a800-bfed117db243",
          "instanceId": 13987,
          "authEntityId": 13987,
          "displayName": "13987",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "135",
          "protocol": "TCP",
          "upperPort": 0
        }
      ],
      "parentScalableGroup": {
        "idRef": "7788bf2d-e289-4597-b500-17aacb7e820e"
      },
      "user": [

      ],
      "userGroup": [

      ]
    },
    {
      "id": "da271423-d3c9-4be9-883d-893732f45abc",
      "instanceId": 4432,
      "authEntityId": 4432,
      "displayName": "4432",
      "authEntityClass": -1909073334,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "createTime": 1517966742640,
      "deployed": false,
      "isSeeded": true,
      "isStale": false,
      "lastUpdateTime": 1517966742640,
      "name": "evernote",
      "namespace": "scalablegroup:application",
      "provisioningState": "DEFINED",
      "qualifier": "application",
      "resourceVersion": 0,
      "targetIdList": [

      ],
      "type": "scalablegroup",
      "cfsChangeInfo": [

      ],
      "customProvisions": [

      ],
      "scalableGroupExternalHandle": "evernote",
      "scalableGroupType": "APPLICATION",
      "securityGroupTag": 0,
      "state": "ACTIVE",
      "vnAgnostic": true,
      "identitySource": {
        "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
        "instanceId": 10010,
        "authEntityId": 10010,
        "displayName": "10010",
        "authEntityClass": 1738953278,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "state": "INACTIVE",
        "type": "NBAR"
      },
      "indicativeNetworkIdentity": [

      ],
      "networkApplications": [
        {
          "id": "032c4e26-5f6c-4a9f-9fa1-3d4a114de92a",
          "instanceId": 11383,
          "authEntityId": 11383,
          "displayName": "11383",
          "authEntityClass": -217092956,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "appProtocol": "tcp/udp",
          "applicationGroup": "other",
          "applicationType": "DEFAULT",
          "categoryId": "878644b5-fe91-45b4-9204-31c84a73ba48",
          "createTime": 1517966742639,
          "encrypted": "false",
          "engineId": "13",
          "helpString": "Evernote - Synched note taking and web bookmarking app.",
          "ignoreConflict": false,
          "lastUpdateTime": 1517966742639,
          "longDescription": "Evernote - Synched note taking and web bookmarking app.",
          "name": "evernote",
          "nbarId": "1617",
          "p2pTechnology": "false",
          "popularity": 3,
          "rank": 65535,
          "references": "http://evernote.com",
          "selectorId": "687",
          "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
          "trafficClass": "TRANSACTIONAL_DATA",
          "tunnel": "false"
        }
      ],
      "networkIdentity": [
        {
          "id": "d4c340c1-c5f1-4e48-a091-c072a28395cd",
          "instanceId": 13089,
          "authEntityId": 13089,
          "displayName": "13089",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "53,5353",
          "protocol": "UDP",
          "upperPort": 0
        },
        {
          "id": "b6c3b272-73f0-42a1-9e17-0c923d83dccc",
          "instanceId": 13090,
          "authEntityId": 13090,
          "displayName": "13090",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "53,80,443,5353",
          "protocol": "TCP",
          "upperPort": 0
        }
      ],
      "parentScalableGroup": {
        "idRef": "28e08de4-d7b2-4521-84ce-bc1e5ee0b9cc"
      },
      "user": [

      ],
      "userGroup": [

      ]
    },
    {
      "id": "da470a3a-5a9f-45d3-9a3b-2b103aabab81",
      "instanceId": 4223,
      "authEntityId": 4223,
      "displayName": "4223",
      "authEntityClass": -1909073334,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "createTime": 1517966741823,
      "deployed": false,
      "isSeeded": true,
      "isStale": false,
      "lastUpdateTime": 1517966741823,
      "name": "chargen",
      "namespace": "scalablegroup:application",
      "provisioningState": "DEFINED",
      "qualifier": "application",
      "resourceVersion": 0,
      "targetIdList": [

      ],
      "type": "scalablegroup",
      "cfsChangeInfo": [

      ],
      "customProvisions": [

      ],
      "scalableGroupExternalHandle": "chargen",
      "scalableGroupType": "APPLICATION",
      "securityGroupTag": 0,
      "state": "ACTIVE",
      "vnAgnostic": true,
      "identitySource": {
        "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
        "instanceId": 10010,
        "authEntityId": 10010,
        "displayName": "10010",
        "authEntityClass": 1738953278,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "state": "INACTIVE",
        "type": "NBAR"
      },
      "indicativeNetworkIdentity": [
        {
          "id": "318246b5-a6bf-495d-9045-f58140bdfddf",
          "instanceId": 12489,
          "authEntityId": 12489,
          "displayName": "12489",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "19",
          "protocol": "UDP",
          "upperPort": 0
        },
        {
          "id": "6d649251-bba4-4250-9fb1-a45d99dbee4e",
          "instanceId": 12490,
          "authEntityId": 12490,
          "displayName": "12490",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "19",
          "protocol": "TCP",
          "upperPort": 0
        }
      ],
      "networkApplications": [
        {
          "id": "a2f668b1-b095-4b9a-8281-bdd15de996fe",
          "instanceId": 11178,
          "authEntityId": 11178,
          "displayName": "11178",
          "authEntityClass": -217092956,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "appProtocol": "tcp/udp",
          "applicationGroup": "other",
          "applicationType": "DEFAULT",
          "categoryId": "1fc17362-9334-4c7b-b752-5afdbb574017",
          "createTime": 1517966741823,
          "encrypted": "false",
          "engineId": "3",
          "helpString": "Character Generator",
          "ignoreConflict": false,
          "lastUpdateTime": 1517966741823,
          "longDescription": "The Character Generator Protocol (CHARGEN) is a service of the Internet Protocol Suite. It is intended for testing, debugging, and measurement purposes.",
          "name": "chargen",
          "nbarId": "104",
          "p2pTechnology": "false",
          "popularity": 1,
          "rank": 65535,
          "references": "http://www.ietf.org/rfc/rfc864.txt",
          "selectorId": "19",
          "subCategory": "f3c0f761-d894-4112-ab89-b90fc84fd660",
          "trafficClass": "OPS_ADMIN_MGMT",
          "tunnel": "false"
        }
      ],
      "networkIdentity": [
        {
          "id": "16ff9e64-0756-4ab5-bb5d-7c96ac3f3257",
          "instanceId": 12487,
          "authEntityId": 12487,
          "displayName": "12487",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "19",
          "protocol": "UDP",
          "upperPort": 0
        },
        {
          "id": "26a4d728-6d11-46f2-b71e-ccef0d65e54e",
          "instanceId": 12488,
          "authEntityId": 12488,
          "displayName": "12488",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "19",
          "protocol": "TCP",
          "upperPort": 0
        }
      ],
      "parentScalableGroup": {
        "idRef": "5fcd74d4-8726-47f7-a088-c457863da7ef"
      },
      "user": [

      ],
      "userGroup": [

      ]
    },
    {
      "id": "da4f0afe-e24a-4b8f-a3c8-850127fa2c47",
      "instanceId": 15364,
      "authEntityId": 15364,
      "displayName": "15364",
      "authEntityClass": -1909073334,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "createTime": 1517966744810,
      "deployed": false,
      "isSeeded": true,
      "isStale": false,
      "lastUpdateTime": 1517966744810,
      "name": "vnas",
      "namespace": "scalablegroup:application",
      "provisioningState": "DEFINED",
      "qualifier": "application",
      "resourceVersion": 0,
      "targetIdList": [

      ],
      "type": "scalablegroup",
      "cfsChangeInfo": [

      ],
      "customProvisions": [

      ],
      "scalableGroupExternalHandle": "vnas",
      "scalableGroupType": "APPLICATION",
      "securityGroupTag": 0,
      "state": "ACTIVE",
      "vnAgnostic": true,
      "identitySource": {
        "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
        "instanceId": 10010,
        "authEntityId": 10010,
        "displayName": "10010",
        "authEntityClass": 1738953278,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "state": "INACTIVE",
        "type": "NBAR"
      },
      "indicativeNetworkIdentity": [
        {
          "id": "67837f35-ec02-465c-a20b-ff65dc955a07",
          "instanceId": 17706,
          "authEntityId": 17706,
          "displayName": "17706",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "577",
          "protocol": "TCP",
          "upperPort": 0
        },
        {
          "id": "165d2797-fe5b-44da-a686-6baa308db3d2",
          "instanceId": 17707,
          "authEntityId": 17707,
          "displayName": "17707",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "577",
          "protocol": "UDP",
          "upperPort": 0
        }
      ],
      "networkApplications": [
        {
          "id": "ee41a115-5309-41d8-96dc-17f4ce35ddde",
          "instanceId": 16307,
          "authEntityId": 16307,
          "displayName": "16307",
          "authEntityClass": -217092956,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "appProtocol": "tcp/udp",
          "applicationGroup": "other",
          "applicationType": "DEFAULT",
          "categoryId": "6daa3dfd-3526-407a-88f0-97824b3f3a24",
          "createTime": 1517966744810,
          "encrypted": "false",
          "engineId": "3",
          "helpString": "vnas",
          "ignoreConflict": false,
          "lastUpdateTime": 1517966744810,
          "longDescription": "Registered with IANA on port 577 TCP/UDP",
          "name": "vnas",
          "nbarId": "491",
          "p2pTechnology": "false",
          "popularity": 1,
          "rank": 65535,
          "references": "http://www.iana.org/assignments/service-names-port-numbers/service-names-port-numbers.xml",
          "selectorId": "577",
          "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
          "trafficClass": "BULK_DATA",
          "tunnel": "false"
        }
      ],
      "networkIdentity": [
        {
          "id": "54fe5655-fb23-4187-bce4-c1c955f85cdb",
          "instanceId": 17704,
          "authEntityId": 17704,
          "displayName": "17704",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "577",
          "protocol": "UDP",
          "upperPort": 0
        },
        {
          "id": "d8a7264d-8c8e-44c3-857b-c395c4732119",
          "instanceId": 17705,
          "authEntityId": 17705,
          "displayName": "17705",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "577",
          "protocol": "TCP",
          "upperPort": 0
        }
      ],
      "parentScalableGroup": {
        "idRef": "000c15bf-28fe-4fbb-9b69-35e6502721f1"
      },
      "user": [

      ],
      "userGroup": [

      ]
    },
    {
      "id": "daa9f46d-645d-4eff-8567-243cbe901a2c",
      "instanceId": 4356,
      "authEntityId": 4356,
      "displayName": "4356",
      "authEntityClass": -1909073334,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "createTime": 1517966742387,
      "deployed": false,
      "isSeeded": true,
      "isStale": false,
      "lastUpdateTime": 1517966742387,
      "name": "dhcp-failover2",
      "namespace": "scalablegroup:application",
      "provisioningState": "DEFINED",
      "qualifier": "application",
      "resourceVersion": 0,
      "targetIdList": [

      ],
      "type": "scalablegroup",
      "cfsChangeInfo": [

      ],
      "customProvisions": [

      ],
      "scalableGroupExternalHandle": "dhcp-failover2",
      "scalableGroupType": "APPLICATION",
      "securityGroupTag": 0,
      "state": "ACTIVE",
      "vnAgnostic": true,
      "identitySource": {
        "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
        "instanceId": 10010,
        "authEntityId": 10010,
        "displayName": "10010",
        "authEntityClass": 1738953278,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "state": "INACTIVE",
        "type": "NBAR"
      },
      "indicativeNetworkIdentity": [
        {
          "id": "f237ba8d-a245-4b04-8b78-d373b0fd3e7b",
          "instanceId": 12850,
          "authEntityId": 12850,
          "displayName": "12850",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "847",
          "protocol": "TCP",
          "upperPort": 0
        },
        {
          "id": "a220dba7-3936-4b56-89f1-dfeadea0bb42",
          "instanceId": 12851,
          "authEntityId": 12851,
          "displayName": "12851",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "847",
          "protocol": "UDP",
          "upperPort": 0
        }
      ],
      "networkApplications": [
        {
          "id": "5893e231-1eec-491a-ab4a-581e89cfd4f5",
          "instanceId": 11307,
          "authEntityId": 11307,
          "displayName": "11307",
          "authEntityClass": -217092956,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "appProtocol": "tcp/udp",
          "applicationGroup": "other",
          "applicationType": "DEFAULT",
          "categoryId": "1fc17362-9334-4c7b-b752-5afdbb574017",
          "createTime": 1517966742387,
          "encrypted": "false",
          "engineId": "3",
          "helpString": "dhcp-failover2",
          "ignoreConflict": false,
          "lastUpdateTime": 1517966742387,
          "longDescription": "DHCP Failover Protocol provides synchronization between two DHCP servers, for redundancy in case of a server failure.",
          "name": "dhcp-failover2",
          "nbarId": "658",
          "p2pTechnology": "false",
          "popularity": 6,
          "rank": 65535,
          "references": "http://tools.ietf.org/html/draft-ietf-dhc-failover-12",
          "selectorId": "847",
          "subCategory": "f3c0f761-d894-4112-ab89-b90fc84fd660",
          "trafficClass": "OPS_ADMIN_MGMT",
          "tunnel": "false"
        }
      ],
      "networkIdentity": [
        {
          "id": "52b7cf50-e7ef-4aa7-86dd-2fdad5c09f2b",
          "instanceId": 12848,
          "authEntityId": 12848,
          "displayName": "12848",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "847",
          "protocol": "TCP",
          "upperPort": 0
        },
        {
          "id": "5dd03fd3-0ab8-44c9-bf2d-7dd76723bb94",
          "instanceId": 12849,
          "authEntityId": 12849,
          "displayName": "12849",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "847",
          "protocol": "UDP",
          "upperPort": 0
        }
      ],
      "parentScalableGroup": {
        "idRef": "5fcd74d4-8726-47f7-a088-c457863da7ef"
      },
      "user": [

      ],
      "userGroup": [

      ]
    },
    {
      "id": "dabd81c2-ab19-49f2-a974-1c1c9cecb20a",
      "instanceId": 15376,
      "authEntityId": 15376,
      "displayName": "15376",
      "authEntityClass": -1909073334,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "createTime": 1517966744834,
      "deployed": false,
      "isSeeded": true,
      "isStale": false,
      "lastUpdateTime": 1517966744834,
      "name": "wap-push-https",
      "namespace": "scalablegroup:application",
      "provisioningState": "DEFINED",
      "qualifier": "application",
      "resourceVersion": 0,
      "targetIdList": [

      ],
      "type": "scalablegroup",
      "cfsChangeInfo": [

      ],
      "customProvisions": [

      ],
      "scalableGroupExternalHandle": "wap-push-https",
      "scalableGroupType": "APPLICATION",
      "securityGroupTag": 0,
      "state": "ACTIVE",
      "vnAgnostic": true,
      "identitySource": {
        "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
        "instanceId": 10010,
        "authEntityId": 10010,
        "displayName": "10010",
        "authEntityClass": 1738953278,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "state": "INACTIVE",
        "type": "NBAR"
      },
      "indicativeNetworkIdentity": [
        {
          "id": "e57f617e-7d3e-4605-8dfd-132f0e102948",
          "instanceId": 17747,
          "authEntityId": 17747,
          "displayName": "17747",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "4036",
          "protocol": "TCP",
          "upperPort": 0
        },
        {
          "id": "14f325fd-5790-4301-be03-d1e496795021",
          "instanceId": 17748,
          "authEntityId": 17748,
          "displayName": "17748",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "4036",
          "protocol": "UDP",
          "upperPort": 0
        }
      ],
      "networkApplications": [
        {
          "id": "82ffc40b-6adc-436f-b66a-ad9ed6b1ead1",
          "instanceId": 16319,
          "authEntityId": 16319,
          "displayName": "16319",
          "authEntityClass": -217092956,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "appProtocol": "tcp/udp",
          "applicationGroup": "wap-group",
          "applicationType": "DEFAULT",
          "categoryId": "6daa3dfd-3526-407a-88f0-97824b3f3a24",
          "createTime": 1517966744834,
          "encrypted": "false",
          "engineId": "3",
          "helpString": "WAP Push OTA-HTTP secure",
          "ignoreConflict": false,
          "lastUpdateTime": 1517966744834,
          "longDescription": "The Wireless Application Protocol(WAP) is atechnical standardfor accessing information over a mobilewireless network. WAP Push allows WAP content to be pushed to the mobile handset with minimum user intervention.",
          "name": "wap-push-https",
          "nbarId": "728",
          "p2pTechnology": "false",
          "popularity": 2,
          "rank": 65535,
          "references": "http://en.wikipedia.org/wiki/Wireless_Application_Protocol#WAP_Push",
          "selectorId": "4036",
          "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
          "trafficClass": "BULK_DATA",
          "tunnel": "false"
        }
      ],
      "networkIdentity": [
        {
          "id": "0034a4f3-9c60-4f35-98d0-75ef6569c323",
          "instanceId": 17745,
          "authEntityId": 17745,
          "displayName": "17745",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "4036",
          "protocol": "UDP",
          "upperPort": 0
        },
        {
          "id": "4bfd46a2-28a6-4977-9cab-44a4a27b2819",
          "instanceId": 17746,
          "authEntityId": 17746,
          "displayName": "17746",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "4036",
          "protocol": "TCP",
          "upperPort": 0
        }
      ],
      "parentScalableGroup": {
        "idRef": "000c15bf-28fe-4fbb-9b69-35e6502721f1"
      },
      "user": [

      ],
      "userGroup": [

      ]
    },
    {
      "id": "daee7b00-abd2-4293-81d0-1f78746b920c",
      "instanceId": 4242,
      "authEntityId": 4242,
      "displayName": "4242",
      "authEntityClass": -1909073334,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "createTime": 1517966741917,
      "deployed": false,
      "isSeeded": true,
      "isStale": false,
      "lastUpdateTime": 1517966741917,
      "name": "cisco-media-audio",
      "namespace": "scalablegroup:application",
      "provisioningState": "DEFINED",
      "qualifier": "application",
      "resourceVersion": 0,
      "targetIdList": [

      ],
      "type": "scalablegroup",
      "cfsChangeInfo": [

      ],
      "customProvisions": [

      ],
      "scalableGroupExternalHandle": "cisco-media-audio",
      "scalableGroupType": "APPLICATION",
      "securityGroupTag": 0,
      "state": "ACTIVE",
      "vnAgnostic": true,
      "identitySource": {
        "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
        "instanceId": 10010,
        "authEntityId": 10010,
        "displayName": "10010",
        "authEntityClass": 1738953278,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "state": "INACTIVE",
        "type": "NBAR"
      },
      "indicativeNetworkIdentity": [

      ],
      "networkApplications": [
        {
          "id": "f991f7d5-4892-4452-b460-3e20c5c2fe35",
          "instanceId": 11196,
          "authEntityId": 11196,
          "displayName": "11196",
          "authEntityClass": -217092956,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "applicationGroup": "cisco-phone-group",
          "applicationType": "DEFAULT",
          "categoryId": "38a8e354-e2fe-4f3c-b1c5-2bbb90fb0d99",
          "createTime": 1517966741917,
          "encrypted": "false",
          "engineId": "13",
          "helpString": "Cisco IP Phones and PC-based Unified Communicators",
          "ignoreConflict": false,
          "lastUpdateTime": 1517966741917,
          "longDescription": "Cisco-media is used mainly in corporations and can be used on or off site.",
          "name": "cisco-media-audio",
          "nbarId": "1746",
          "p2pTechnology": "false",
          "popularity": 10,
          "rank": 65535,
          "selectorId": "1746",
          "subCategory": "53ce0342-1e2d-455a-acc7-1b50db9d6845",
          "trafficClass": "VOIP_TELEPHONY",
          "tunnel": "false"
        }
      ],
      "networkIdentity": [

      ],
      "parentScalableGroup": {
        "idRef": "60f1364d-2a02-4063-8ba1-07817abe0612"
      },
      "user": [

      ],
      "userGroup": [

      ]
    },
    {
      "id": "db23aec1-da40-4adf-afaa-0b1a255b7401",
      "instanceId": 4159,
      "authEntityId": 4159,
      "displayName": "4159",
      "authEntityClass": -1909073334,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "createTime": 1517966741439,
      "deployed": false,
      "isSeeded": true,
      "isStale": false,
      "lastUpdateTime": 1517966741439,
      "name": "badoo",
      "namespace": "scalablegroup:application",
      "provisioningState": "DEFINED",
      "qualifier": "application",
      "resourceVersion": 0,
      "targetIdList": [

      ],
      "type": "scalablegroup",
      "cfsChangeInfo": [

      ],
      "customProvisions": [

      ],
      "scalableGroupExternalHandle": "badoo",
      "scalableGroupType": "APPLICATION",
      "securityGroupTag": 0,
      "state": "ACTIVE",
      "vnAgnostic": true,
      "identitySource": {
        "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
        "instanceId": 10010,
        "authEntityId": 10010,
        "displayName": "10010",
        "authEntityClass": 1738953278,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "state": "INACTIVE",
        "type": "NBAR"
      },
      "indicativeNetworkIdentity": [

      ],
      "networkApplications": [
        {
          "id": "fc33af35-2949-47e7-bb3f-d9fca31eef8e",
          "instanceId": 11119,
          "authEntityId": 11119,
          "displayName": "11119",
          "authEntityClass": -217092956,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "appProtocol": "tcp/udp",
          "applicationGroup": "other",
          "applicationType": "DEFAULT",
          "categoryId": "878644b5-fe91-45b4-9204-31c84a73ba48",
          "createTime": 1517966741439,
          "encrypted": "false",
          "engineId": "13",
          "helpString": "Badoo - Social networking service.",
          "ignoreConflict": false,
          "lastUpdateTime": 1517966741439,
          "longDescription": "Badoo - Social networking service.",
          "name": "badoo",
          "nbarId": "1614",
          "p2pTechnology": "false",
          "popularity": 3,
          "rank": 65535,
          "references": "http://badoo.com",
          "selectorId": "684",
          "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
          "trafficClass": "TRANSACTIONAL_DATA",
          "tunnel": "false"
        }
      ],
      "networkIdentity": [
        {
          "id": "d03df854-8d9c-4ddc-9b4d-16be5ecd668e",
          "instanceId": 12328,
          "authEntityId": 12328,
          "displayName": "12328",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "53,5353",
          "protocol": "UDP",
          "upperPort": 0
        },
        {
          "id": "4d813b1c-beed-45c1-9eea-bbad74c1dda6",
          "instanceId": 12329,
          "authEntityId": 12329,
          "displayName": "12329",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "53,80,443,5353",
          "protocol": "TCP",
          "upperPort": 0
        }
      ],
      "parentScalableGroup": {
        "idRef": "28e08de4-d7b2-4521-84ce-bc1e5ee0b9cc"
      },
      "user": [

      ],
      "userGroup": [

      ]
    },
    {
      "id": "db4f33e2-60cf-44f3-a0ea-04307e580fc3",
      "instanceId": 15463,
      "authEntityId": 15463,
      "displayName": "15463",
      "authEntityClass": -1909073334,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "createTime": 1517966745017,
      "deployed": false,
      "isSeeded": true,
      "isStale": false,
      "lastUpdateTime": 1517966745017,
      "name": "youtube",
      "namespace": "scalablegroup:application",
      "provisioningState": "DEFINED",
      "qualifier": "application",
      "resourceVersion": 0,
      "targetIdList": [

      ],
      "type": "scalablegroup",
      "cfsChangeInfo": [

      ],
      "customProvisions": [

      ],
      "scalableGroupExternalHandle": "youtube",
      "scalableGroupType": "APPLICATION",
      "securityGroupTag": 0,
      "state": "ACTIVE",
      "vnAgnostic": true,
      "identitySource": {
        "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
        "instanceId": 10010,
        "authEntityId": 10010,
        "displayName": "10010",
        "authEntityClass": 1738953278,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "state": "INACTIVE",
        "type": "NBAR"
      },
      "indicativeNetworkIdentity": [

      ],
      "networkApplications": [
        {
          "id": "b825a23b-5b61-4449-8f52-803ef7e9d8b0",
          "instanceId": 16406,
          "authEntityId": 16406,
          "displayName": "16406",
          "authEntityClass": -217092956,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "appProtocol": "tcp/udp",
          "applicationGroup": "flash-group",
          "applicationType": "DEFAULT",
          "categoryId": "a09ef1b3-ba26-4372-aee8-309d50697e76",
          "createTime": 1517966745017,
          "encrypted": "true",
          "engineId": "13",
          "helpString": "Youtube, video streaming",
          "ignoreConflict": false,
          "lastUpdateTime": 1517966745017,
          "longDescription": "YouTube is a video-sharing website. The user can watch videos or upload videos online to share with other users. Youtube works cross platform on: PC, iPhone, Android and BlackBerry. Youtube is using HTTP and RTSP as underlying protocols.",
          "name": "youtube",
          "nbarId": "82",
          "p2pTechnology": "false",
          "popularity": 10,
          "rank": 65535,
          "references": "http://www.youtube.com/t/about_youtube",
          "selectorId": "82",
          "subCategory": "85a797c0-aef8-4284-91a7-677f33e29abb",
          "trafficClass": "MULTIMEDIA_STREAMING",
          "tunnel": "false"
        }
      ],
      "networkIdentity": [
        {
          "id": "8295e688-1551-4d64-a1e5-7a09730c7a53",
          "instanceId": 17962,
          "authEntityId": 17962,
          "displayName": "17962",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "53,5353",
          "protocol": "UDP",
          "upperPort": 0
        },
        {
          "id": "8d1955f5-fcce-465c-af77-72070d920c47",
          "instanceId": 17963,
          "authEntityId": 17963,
          "displayName": "17963",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "53,80,443,554,5353,8554",
          "protocol": "TCP",
          "upperPort": 0
        }
      ],
      "parentScalableGroup": {
        "idRef": "fd6264c9-c16a-4311-a335-a3db039256f3"
      },
      "user": [

      ],
      "userGroup": [

      ]
    },
    {
      "id": "db51e170-3c79-4af3-8c5f-721b411b0cc1",
      "instanceId": 4913,
      "authEntityId": 4913,
      "displayName": "4913",
      "authEntityClass": -1909073334,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "createTime": 1517966743748,
      "deployed": false,
      "isSeeded": true,
      "isStale": false,
      "lastUpdateTime": 1517966743748,
      "name": "openport",
      "namespace": "scalablegroup:application",
      "provisioningState": "DEFINED",
      "qualifier": "application",
      "resourceVersion": 0,
      "targetIdList": [

      ],
      "type": "scalablegroup",
      "cfsChangeInfo": [

      ],
      "customProvisions": [

      ],
      "scalableGroupExternalHandle": "openport",
      "scalableGroupType": "APPLICATION",
      "securityGroupTag": 0,
      "state": "ACTIVE",
      "vnAgnostic": true,
      "identitySource": {
        "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
        "instanceId": 10010,
        "authEntityId": 10010,
        "displayName": "10010",
        "authEntityClass": 1738953278,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "state": "INACTIVE",
        "type": "NBAR"
      },
      "indicativeNetworkIdentity": [
        {
          "id": "c3396b07-36e9-48ea-a809-a40144f4862f",
          "instanceId": 14404,
          "authEntityId": 14404,
          "displayName": "14404",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "260",
          "protocol": "UDP",
          "upperPort": 0
        },
        {
          "id": "291180a8-c218-4dc8-9512-c3e4b10ded0b",
          "instanceId": 14405,
          "authEntityId": 14405,
          "displayName": "14405",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "260",
          "protocol": "TCP",
          "upperPort": 0
        }
      ],
      "networkApplications": [
        {
          "id": "9d9ce36f-d144-40d2-8171-0f3d519d45ad",
          "instanceId": 11862,
          "authEntityId": 11862,
          "displayName": "11862",
          "authEntityClass": -217092956,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "appProtocol": "tcp/udp",
          "applicationGroup": "other",
          "applicationType": "DEFAULT",
          "categoryId": "6daa3dfd-3526-407a-88f0-97824b3f3a24",
          "createTime": 1517966743748,
          "encrypted": "false",
          "engineId": "3",
          "helpString": "openport",
          "ignoreConflict": false,
          "lastUpdateTime": 1517966743748,
          "longDescription": "Registered with IANA on port 260 TCP/UDP",
          "name": "openport",
          "nbarId": "1132",
          "p2pTechnology": "false",
          "popularity": 1,
          "rank": 65535,
          "references": "http://www.iana.org/assignments/service-names-port-numbers/service-names-port-numbers.xml",
          "selectorId": "260",
          "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
          "trafficClass": "BULK_DATA",
          "tunnel": "false"
        }
      ],
      "networkIdentity": [
        {
          "id": "68756f1f-5207-42d4-addc-a650ab541d6f",
          "instanceId": 14402,
          "authEntityId": 14402,
          "displayName": "14402",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "260",
          "protocol": "UDP",
          "upperPort": 0
        },
        {
          "id": "877c38b5-9865-4ed2-9837-138319585499",
          "instanceId": 14403,
          "authEntityId": 14403,
          "displayName": "14403",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "260",
          "protocol": "TCP",
          "upperPort": 0
        }
      ],
      "parentScalableGroup": {
        "idRef": "000c15bf-28fe-4fbb-9b69-35e6502721f1"
      },
      "user": [

      ],
      "userGroup": [

      ]
    },
    {
      "id": "db721f9d-9e90-46b5-9139-532229a8e84d",
      "instanceId": 15386,
      "authEntityId": 15386,
      "displayName": "15386",
      "authEntityClass": -1909073334,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "createTime": 1517966744851,
      "deployed": false,
      "isSeeded": true,
      "isStale": false,
      "lastUpdateTime": 1517966744851,
      "name": "war-rock",
      "namespace": "scalablegroup:application",
      "provisioningState": "DEFINED",
      "qualifier": "application",
      "resourceVersion": 0,
      "targetIdList": [

      ],
      "type": "scalablegroup",
      "cfsChangeInfo": [

      ],
      "customProvisions": [

      ],
      "scalableGroupExternalHandle": "war-rock",
      "scalableGroupType": "APPLICATION",
      "securityGroupTag": 0,
      "state": "ACTIVE",
      "vnAgnostic": true,
      "identitySource": {
        "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
        "instanceId": 10010,
        "authEntityId": 10010,
        "displayName": "10010",
        "authEntityClass": 1738953278,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "state": "INACTIVE",
        "type": "NBAR"
      },
      "indicativeNetworkIdentity": [
        {
          "id": "69154bc7-b895-45c6-80a2-babdba891e93",
          "instanceId": 17786,
          "authEntityId": 17786,
          "displayName": "17786",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "5330,5340",
          "protocol": "TCP",
          "upperPort": 0
        }
      ],
      "networkApplications": [
        {
          "id": "d2de6224-892b-4133-8392-721679cf4df3",
          "instanceId": 16329,
          "authEntityId": 16329,
          "displayName": "16329",
          "authEntityClass": -217092956,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "appProtocol": "tcp",
          "applicationGroup": "other",
          "applicationType": "DEFAULT",
          "categoryId": "6daa3dfd-3526-407a-88f0-97824b3f3a24",
          "createTime": 1517966744851,
          "encrypted": "false",
          "engineId": "3",
          "helpString": "War-rock online gaming",
          "ignoreConflict": false,
          "lastUpdateTime": 1517966744851,
          "longDescription": "War Rock is a multiplayer first-person shooter made by the South Korean company, Dream Execution. The game is distributed as a free-to-play, a common form of freeware EULA and available through online downloading. Outside of the Far East, the game is hosted by NexonEU.",
          "name": "war-rock",
          "nbarId": "1395",
          "p2pTechnology": "false",
          "popularity": 6,
          "rank": 65535,
          "references": "http://en.warrock.nexoneu.com/",
          "selectorId": "5330",
          "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
          "trafficClass": "BULK_DATA",
          "tunnel": "false"
        }
      ],
      "networkIdentity": [
        {
          "id": "cc3eaecc-c491-432b-853f-8610774b663a",
          "instanceId": 17785,
          "authEntityId": 17785,
          "displayName": "17785",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "5330,5340",
          "protocol": "TCP",
          "upperPort": 0
        }
      ],
      "parentScalableGroup": {
        "idRef": "000c15bf-28fe-4fbb-9b69-35e6502721f1"
      },
      "user": [

      ],
      "userGroup": [

      ]
    },
    {
      "id": "dc0c945d-8181-4b3f-8e14-89cbdce5fa21",
      "instanceId": 4314,
      "authEntityId": 4314,
      "displayName": "4314",
      "authEntityClass": -1909073334,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "createTime": 1517966742253,
      "deployed": false,
      "isSeeded": true,
      "isStale": false,
      "lastUpdateTime": 1517966742253,
      "name": "cvc_hostd",
      "namespace": "scalablegroup:application",
      "provisioningState": "DEFINED",
      "qualifier": "application",
      "resourceVersion": 0,
      "targetIdList": [

      ],
      "type": "scalablegroup",
      "cfsChangeInfo": [

      ],
      "customProvisions": [

      ],
      "scalableGroupExternalHandle": "cvc_hostd",
      "scalableGroupType": "APPLICATION",
      "securityGroupTag": 0,
      "state": "ACTIVE",
      "vnAgnostic": true,
      "identitySource": {
        "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
        "instanceId": 10010,
        "authEntityId": 10010,
        "displayName": "10010",
        "authEntityClass": 1738953278,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "state": "INACTIVE",
        "type": "NBAR"
      },
      "indicativeNetworkIdentity": [
        {
          "id": "1b1b8a99-ea11-41e9-8655-62563301d7f8",
          "instanceId": 12716,
          "authEntityId": 12716,
          "displayName": "12716",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "442",
          "protocol": "TCP",
          "upperPort": 0
        },
        {
          "id": "2e4fbd5a-bed1-4e4a-89d2-0dda2cf086d6",
          "instanceId": 12717,
          "authEntityId": 12717,
          "displayName": "12717",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "442",
          "protocol": "UDP",
          "upperPort": 0
        }
      ],
      "networkApplications": [
        {
          "id": "c4df1525-0314-4bc3-8ffa-3d80fbf4dae1",
          "instanceId": 11266,
          "authEntityId": 11266,
          "displayName": "11266",
          "authEntityClass": -217092956,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "appProtocol": "tcp/udp",
          "applicationGroup": "other",
          "applicationType": "DEFAULT",
          "categoryId": "1fc17362-9334-4c7b-b752-5afdbb574017",
          "createTime": 1517966742253,
          "encrypted": "false",
          "engineId": "3",
          "helpString": "cvc_hostd",
          "ignoreConflict": false,
          "lastUpdateTime": 1517966742253,
          "longDescription": "Registered with IANA on port 442 TCP/UDP",
          "name": "cvc_hostd",
          "nbarId": "357",
          "p2pTechnology": "false",
          "popularity": 1,
          "rank": 65535,
          "references": "http://www.iana.org/assignments/service-names-port-numbers/service-names-port-numbers.xml",
          "selectorId": "442",
          "subCategory": "e390124a-0fd6-4253-b68e-4b0c1af8cb4b",
          "trafficClass": "OPS_ADMIN_MGMT",
          "tunnel": "false"
        }
      ],
      "networkIdentity": [
        {
          "id": "96efb7e3-a1d7-42aa-a092-a5bb62c2fbf8",
          "instanceId": 12714,
          "authEntityId": 12714,
          "displayName": "12714",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "442",
          "protocol": "TCP",
          "upperPort": 0
        },
        {
          "id": "01a6648e-eec8-4c28-ac64-2d61bc84c924",
          "instanceId": 12715,
          "authEntityId": 12715,
          "displayName": "12715",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "442",
          "protocol": "UDP",
          "upperPort": 0
        }
      ],
      "parentScalableGroup": {
        "idRef": "7788bf2d-e289-4597-b500-17aacb7e820e"
      },
      "user": [

      ],
      "userGroup": [

      ]
    },
    {
      "id": "dc426596-d31b-4a61-b567-f5d2a9a27665",
      "instanceId": 4967,
      "authEntityId": 4967,
      "displayName": "4967",
      "authEntityClass": -1909073334,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "createTime": 1517966743931,
      "deployed": false,
      "isSeeded": true,
      "isStale": false,
      "lastUpdateTime": 1517966743931,
      "name": "pkix-timestamp",
      "namespace": "scalablegroup:application",
      "provisioningState": "DEFINED",
      "qualifier": "application",
      "resourceVersion": 0,
      "targetIdList": [

      ],
      "type": "scalablegroup",
      "cfsChangeInfo": [

      ],
      "customProvisions": [

      ],
      "scalableGroupExternalHandle": "pkix-timestamp",
      "scalableGroupType": "APPLICATION",
      "securityGroupTag": 0,
      "state": "ACTIVE",
      "vnAgnostic": true,
      "identitySource": {
        "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
        "instanceId": 10010,
        "authEntityId": 10010,
        "displayName": "10010",
        "authEntityClass": 1738953278,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "state": "INACTIVE",
        "type": "NBAR"
      },
      "indicativeNetworkIdentity": [
        {
          "id": "3c36427b-0ff0-469b-a6cb-b952ce72c779",
          "instanceId": 14560,
          "authEntityId": 14560,
          "displayName": "14560",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "318",
          "protocol": "UDP",
          "upperPort": 0
        },
        {
          "id": "2359c2aa-6e40-492d-8aa4-5af7421554f0",
          "instanceId": 14561,
          "authEntityId": 14561,
          "displayName": "14561",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "318",
          "protocol": "TCP",
          "upperPort": 0
        }
      ],
      "networkApplications": [
        {
          "id": "3b29ed8e-e0b4-4c39-9f07-f2c24eb49a12",
          "instanceId": 11916,
          "authEntityId": 11916,
          "displayName": "11916",
          "authEntityClass": -217092956,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "appProtocol": "tcp/udp",
          "applicationGroup": "other",
          "applicationType": "DEFAULT",
          "categoryId": "1fc17362-9334-4c7b-b752-5afdbb574017",
          "createTime": 1517966743931,
          "encrypted": "false",
          "engineId": "3",
          "helpString": "pkix-timestamp",
          "ignoreConflict": false,
          "lastUpdateTime": 1517966743931,
          "longDescription": "The Time-Stamp Protocol (TSP, PKIX-TimeStamp) is a cryptographic protocol for certifying timestamps using X.509 certificates and public key infrastructure. The timestamp is the signer's assertion that a piece of electronic data existed at or before a particular time.",
          "name": "pkix-timestamp",
          "nbarId": "1158",
          "p2pTechnology": "false",
          "popularity": 1,
          "rank": 65535,
          "references": "http://tools.ietf.org/html/rfc3161",
          "selectorId": "318",
          "subCategory": "e390124a-0fd6-4253-b68e-4b0c1af8cb4b",
          "trafficClass": "OPS_ADMIN_MGMT",
          "tunnel": "false"
        }
      ],
      "networkIdentity": [
        {
          "id": "91f138fe-ff0d-495f-b6f8-270fbdec4866",
          "instanceId": 14558,
          "authEntityId": 14558,
          "displayName": "14558",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "318",
          "protocol": "UDP",
          "upperPort": 0
        },
        {
          "id": "941fa16b-2ceb-4401-8c6a-62f5144a01e8",
          "instanceId": 14559,
          "authEntityId": 14559,
          "displayName": "14559",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "318",
          "protocol": "TCP",
          "upperPort": 0
        }
      ],
      "parentScalableGroup": {
        "idRef": "7788bf2d-e289-4597-b500-17aacb7e820e"
      },
      "user": [

      ],
      "userGroup": [

      ]
    },
    {
      "id": "dc45c704-24e6-4519-9e75-9eaa47130b97",
      "instanceId": 4955,
      "authEntityId": 4955,
      "displayName": "4955",
      "authEntityClass": -1909073334,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "createTime": 1517966743897,
      "deployed": false,
      "isSeeded": true,
      "isStale": false,
      "lastUpdateTime": 1517966743897,
      "name": "philips-vc",
      "namespace": "scalablegroup:application",
      "provisioningState": "DEFINED",
      "qualifier": "application",
      "resourceVersion": 0,
      "targetIdList": [

      ],
      "type": "scalablegroup",
      "cfsChangeInfo": [

      ],
      "customProvisions": [

      ],
      "scalableGroupExternalHandle": "philips-vc",
      "scalableGroupType": "APPLICATION",
      "securityGroupTag": 0,
      "state": "ACTIVE",
      "vnAgnostic": true,
      "identitySource": {
        "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
        "instanceId": 10010,
        "authEntityId": 10010,
        "displayName": "10010",
        "authEntityClass": 1738953278,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "state": "INACTIVE",
        "type": "NBAR"
      },
      "indicativeNetworkIdentity": [
        {
          "id": "e8cdb639-89d6-4e5b-851d-6ea216f81cb0",
          "instanceId": 14524,
          "authEntityId": 14524,
          "displayName": "14524",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "583",
          "protocol": "TCP",
          "upperPort": 0
        },
        {
          "id": "a53344f5-bfb4-486e-ab70-42fa250fdb53",
          "instanceId": 14525,
          "authEntityId": 14525,
          "displayName": "14525",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "583",
          "protocol": "UDP",
          "upperPort": 0
        }
      ],
      "networkApplications": [
        {
          "id": "c8cfeebb-baf3-48d3-8a12-13d28d75216e",
          "instanceId": 11904,
          "authEntityId": 11904,
          "displayName": "11904",
          "authEntityClass": -217092956,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "appProtocol": "tcp/udp",
          "applicationGroup": "other",
          "applicationType": "DEFAULT",
          "categoryId": "82a7b7fa-da5a-48f5-8b6a-a2546b947843",
          "createTime": 1517966743897,
          "encrypted": "false",
          "engineId": "3",
          "helpString": "Philips Video-Conferencing",
          "ignoreConflict": false,
          "lastUpdateTime": 1517966743897,
          "longDescription": "Used by Philips Electronics in their video conferencing products.",
          "name": "philips-vc",
          "nbarId": "497",
          "p2pTechnology": "false",
          "popularity": 1,
          "rank": 65535,
          "references": "http://www.philips.com",
          "selectorId": "583",
          "subCategory": "118c10a2-e272-47c1-92b9-4814ebe97138",
          "trafficClass": "MULTIMEDIA_CONFERENCING",
          "tunnel": "false"
        }
      ],
      "networkIdentity": [
        {
          "id": "5de83510-82ff-4f25-b76a-a29eb84eed5d",
          "instanceId": 14522,
          "authEntityId": 14522,
          "displayName": "14522",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "583",
          "protocol": "UDP",
          "upperPort": 0
        },
        {
          "id": "f13d931e-620f-4dc2-bf12-3113e3ecb848",
          "instanceId": 14523,
          "authEntityId": 14523,
          "displayName": "14523",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "583",
          "protocol": "TCP",
          "upperPort": 0
        }
      ],
      "parentScalableGroup": {
        "idRef": "fd6264c9-c16a-4311-a335-a3db039256f3"
      },
      "user": [

      ],
      "userGroup": [

      ]
    },
    {
      "id": "dc714a03-d8b4-4050-b6e2-4234c2f26fbf",
      "instanceId": 4838,
      "authEntityId": 4838,
      "displayName": "4838",
      "authEntityClass": -1909073334,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "createTime": 1517966743590,
      "deployed": false,
      "isSeeded": true,
      "isStale": false,
      "lastUpdateTime": 1517966743590,
      "name": "netblt",
      "namespace": "scalablegroup:application",
      "provisioningState": "DEFINED",
      "qualifier": "application",
      "resourceVersion": 0,
      "targetIdList": [

      ],
      "type": "scalablegroup",
      "cfsChangeInfo": [

      ],
      "customProvisions": [

      ],
      "scalableGroupExternalHandle": "netblt",
      "scalableGroupType": "APPLICATION",
      "securityGroupTag": 0,
      "state": "ACTIVE",
      "vnAgnostic": true,
      "identitySource": {
        "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
        "instanceId": 10010,
        "authEntityId": 10010,
        "displayName": "10010",
        "authEntityClass": 1738953278,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "state": "INACTIVE",
        "type": "NBAR"
      },
      "indicativeNetworkIdentity": [
        {
          "id": "e7d7e647-5d7f-4cc9-aaf1-43af208c5c7d",
          "instanceId": 14145,
          "authEntityId": 14145,
          "displayName": "14145",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipProtocolNumbers": "30",
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "protocol": "NETBLT",
          "upperPort": 0
        }
      ],
      "networkApplications": [
        {
          "id": "c9d4d0aa-0797-4cf6-90f2-d2b690cf6734",
          "instanceId": 11787,
          "authEntityId": 11787,
          "displayName": "11787",
          "authEntityClass": -217092956,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "appProtocol": "ip",
          "applicationGroup": "other",
          "applicationType": "DEFAULT",
          "categoryId": "6daa3dfd-3526-407a-88f0-97824b3f3a24",
          "createTime": 1517966743590,
          "encrypted": "unassigned",
          "engineId": "1",
          "helpString": "Bulk Data Transfer Protocol",
          "ignoreConflict": false,
          "lastUpdateTime": 1517966743590,
          "longDescription": "NETBLT (NETwork BLock Transfer) is a transport level protocol intended for the rapid transfer of a large quantity of data between computers.  It provides a transfer that is reliable and flow controlled, and is designed to provide maximum throughput over a wide variety of networks.  Although NETBLT currently runs on top of the Internet Protocol (IP), it should be able to operate on top of any datagram protocol similar in function to IP.",
          "name": "netblt",
          "nbarId": "784",
          "p2pTechnology": "unassigned",
          "popularity": 2,
          "rank": 65535,
          "references": "http://tools.ietf.org/html/rfc998",
          "selectorId": "30",
          "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
          "trafficClass": "BULK_DATA",
          "tunnel": "unassigned"
        }
      ],
      "networkIdentity": [
        {
          "id": "58ce724b-dbf4-4ffa-973e-cabb30638a6d",
          "instanceId": 14144,
          "authEntityId": 14144,
          "displayName": "14144",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipProtocolNumbers": "30",
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "protocol": "NETBLT",
          "upperPort": 0
        }
      ],
      "parentScalableGroup": {
        "idRef": "000c15bf-28fe-4fbb-9b69-35e6502721f1"
      },
      "user": [

      ],
      "userGroup": [

      ]
    },
    {
      "id": "dc89fd1a-1261-4f19-83fd-2b04f1729fea",
      "instanceId": 4781,
      "authEntityId": 4781,
      "displayName": "4781",
      "authEntityClass": -1909073334,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "createTime": 1517966743479,
      "deployed": false,
      "isSeeded": true,
      "isStale": false,
      "lastUpdateTime": 1517966743479,
      "name": "ms-ocs-file-transfer",
      "namespace": "scalablegroup:application",
      "provisioningState": "DEFINED",
      "qualifier": "application",
      "resourceVersion": 0,
      "targetIdList": [

      ],
      "type": "scalablegroup",
      "cfsChangeInfo": [

      ],
      "customProvisions": [

      ],
      "scalableGroupExternalHandle": "ms-ocs-file-transfer",
      "scalableGroupType": "APPLICATION",
      "securityGroupTag": 0,
      "state": "ACTIVE",
      "vnAgnostic": true,
      "identitySource": {
        "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
        "instanceId": 10010,
        "authEntityId": 10010,
        "displayName": "10010",
        "authEntityClass": 1738953278,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "state": "INACTIVE",
        "type": "NBAR"
      },
      "indicativeNetworkIdentity": [
        {
          "id": "de351642-144c-4eb2-b116-e3d86f2a9c2d",
          "instanceId": 13989,
          "authEntityId": 13989,
          "displayName": "13989",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "6891",
          "protocol": "TCP",
          "upperPort": 0
        }
      ],
      "networkApplications": [
        {
          "id": "cafbc0a1-124c-4941-8779-52a3de3bdf07",
          "instanceId": 11730,
          "authEntityId": 11730,
          "displayName": "11730",
          "authEntityClass": -217092956,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "appProtocol": "tcp",
          "applicationGroup": "other",
          "applicationType": "DEFAULT",
          "categoryId": "6daa3dfd-3526-407a-88f0-97824b3f3a24",
          "createTime": 1517966743479,
          "encrypted": "false",
          "engineId": "3",
          "helpString": "Microsoft Office Communications Server and File Transfer",
          "ignoreConflict": false,
          "lastUpdateTime": 1517966743479,
          "longDescription": "Microsoft Office Communications Server and File Transfer is used by Microsoft Office Communicator (an instant messaging software) for file transfer.",
          "name": "ms-ocs-file-transfer",
          "nbarId": "1356",
          "p2pTechnology": "false",
          "popularity": 1,
          "rank": 65535,
          "references": "http://technet.microsoft.com/en-us/office/ocs/bb267356.aspx",
          "selectorId": "6891",
          "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
          "trafficClass": "BULK_DATA",
          "tunnel": "false"
        }
      ],
      "networkIdentity": [
        {
          "id": "dcfed7ed-5a3c-4124-84c2-f7d31779d545",
          "instanceId": 13988,
          "authEntityId": 13988,
          "displayName": "13988",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "6891",
          "protocol": "TCP",
          "upperPort": 0
        }
      ],
      "parentScalableGroup": {
        "idRef": "000c15bf-28fe-4fbb-9b69-35e6502721f1"
      },
      "user": [

      ],
      "userGroup": [

      ]
    },
    {
      "id": "dca893f5-af11-4cdd-9ffd-ac848ab00af7",
      "instanceId": 4180,
      "authEntityId": 4180,
      "displayName": "4180",
      "authEntityClass": -1909073334,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "createTime": 1517966741555,
      "deployed": false,
      "isSeeded": true,
      "isStale": false,
      "lastUpdateTime": 1517966741555,
      "name": "bing",
      "namespace": "scalablegroup:application",
      "provisioningState": "DEFINED",
      "qualifier": "application",
      "resourceVersion": 0,
      "targetIdList": [

      ],
      "type": "scalablegroup",
      "cfsChangeInfo": [

      ],
      "customProvisions": [

      ],
      "scalableGroupExternalHandle": "bing",
      "scalableGroupType": "APPLICATION",
      "securityGroupTag": 0,
      "state": "ACTIVE",
      "vnAgnostic": true,
      "identitySource": {
        "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
        "instanceId": 10010,
        "authEntityId": 10010,
        "displayName": "10010",
        "authEntityClass": 1738953278,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "state": "INACTIVE",
        "type": "NBAR"
      },
      "indicativeNetworkIdentity": [

      ],
      "networkApplications": [
        {
          "id": "0a3353a6-5bf5-40b8-96f1-75e2d75dfdc2",
          "instanceId": 11139,
          "authEntityId": 11139,
          "displayName": "11139",
          "authEntityClass": -217092956,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "appProtocol": "tcp/udp",
          "applicationGroup": "other",
          "applicationType": "DEFAULT",
          "categoryId": "878644b5-fe91-45b4-9204-31c84a73ba48",
          "createTime": 1517966741555,
          "encrypted": "false",
          "engineId": "13",
          "helpString": "Bing - Microsofts internet search engine.",
          "ignoreConflict": false,
          "lastUpdateTime": 1517966741555,
          "longDescription": "Bing - Microsofts internet search engine.",
          "name": "bing",
          "nbarId": "258",
          "p2pTechnology": "false",
          "popularity": 3,
          "rank": 65535,
          "references": "http://bing.com",
          "selectorId": "465",
          "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
          "trafficClass": "TRANSACTIONAL_DATA",
          "tunnel": "false"
        }
      ],
      "networkIdentity": [
        {
          "id": "250dfec9-f5c3-4596-b362-bacc467eac20",
          "instanceId": 12389,
          "authEntityId": 12389,
          "displayName": "12389",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "53,80,443,5353",
          "protocol": "TCP",
          "upperPort": 0
        },
        {
          "id": "d68c9608-bbb5-4fce-9d3d-cd0964f90143",
          "instanceId": 12390,
          "authEntityId": 12390,
          "displayName": "12390",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "53,5353",
          "protocol": "UDP",
          "upperPort": 0
        }
      ],
      "parentScalableGroup": {
        "idRef": "28e08de4-d7b2-4521-84ce-bc1e5ee0b9cc"
      },
      "user": [

      ],
      "userGroup": [

      ]
    },
    {
      "id": "dcead8a7-1128-4cdd-820d-d415d78fc483",
      "instanceId": 4371,
      "authEntityId": 4371,
      "displayName": "4371",
      "authEntityClass": -1909073334,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "createTime": 1517966742439,
      "deployed": false,
      "isSeeded": true,
      "isStale": false,
      "lastUpdateTime": 1517966742439,
      "name": "discover-com",
      "namespace": "scalablegroup:application",
      "provisioningState": "DEFINED",
      "qualifier": "application",
      "resourceVersion": 0,
      "targetIdList": [

      ],
      "type": "scalablegroup",
      "cfsChangeInfo": [

      ],
      "customProvisions": [

      ],
      "scalableGroupExternalHandle": "discover-com",
      "scalableGroupType": "APPLICATION",
      "securityGroupTag": 0,
      "state": "ACTIVE",
      "vnAgnostic": true,
      "identitySource": {
        "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
        "instanceId": 10010,
        "authEntityId": 10010,
        "displayName": "10010",
        "authEntityClass": 1738953278,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "state": "INACTIVE",
        "type": "NBAR"
      },
      "indicativeNetworkIdentity": [

      ],
      "networkApplications": [
        {
          "id": "4825759e-8770-4dc4-aa3b-4c1d5fa00e13",
          "instanceId": 11322,
          "authEntityId": 11322,
          "displayName": "11322",
          "authEntityClass": -217092956,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "appProtocol": "tcp/udp",
          "applicationGroup": "other",
          "applicationType": "DEFAULT",
          "categoryId": "878644b5-fe91-45b4-9204-31c84a73ba48",
          "createTime": 1517966742439,
          "encrypted": "false",
          "engineId": "13",
          "helpString": "Discover - Financial services company.",
          "ignoreConflict": false,
          "lastUpdateTime": 1517966742439,
          "longDescription": "Discover - Financial services company.",
          "name": "discover-com",
          "nbarId": "1670",
          "p2pTechnology": "false",
          "popularity": 3,
          "rank": 65535,
          "references": "http://discover.com",
          "selectorId": "740",
          "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
          "trafficClass": "TRANSACTIONAL_DATA",
          "tunnel": "false"
        }
      ],
      "networkIdentity": [
        {
          "id": "dc624b2e-f653-4955-884f-afc351eb2647",
          "instanceId": 12899,
          "authEntityId": 12899,
          "displayName": "12899",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "53,5353",
          "protocol": "UDP",
          "upperPort": 0
        },
        {
          "id": "0d7d3ceb-25f9-4467-a5d7-5e8a67168fa8",
          "instanceId": 12900,
          "authEntityId": 12900,
          "displayName": "12900",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "53,80,443,5353",
          "protocol": "TCP",
          "upperPort": 0
        }
      ],
      "parentScalableGroup": {
        "idRef": "28e08de4-d7b2-4521-84ce-bc1e5ee0b9cc"
      },
      "user": [

      ],
      "userGroup": [

      ]
    },
    {
      "id": "dcef98f0-73a9-4dec-b12b-bfa9f72d4b6a",
      "instanceId": 4764,
      "authEntityId": 4764,
      "displayName": "4764",
      "authEntityClass": -1909073334,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "createTime": 1517966743446,
      "deployed": false,
      "isSeeded": true,
      "isStale": false,
      "lastUpdateTime": 1517966743446,
      "name": "mpls-in-ip",
      "namespace": "scalablegroup:application",
      "provisioningState": "DEFINED",
      "qualifier": "application",
      "resourceVersion": 0,
      "targetIdList": [

      ],
      "type": "scalablegroup",
      "cfsChangeInfo": [

      ],
      "customProvisions": [

      ],
      "scalableGroupExternalHandle": "mpls-in-ip",
      "scalableGroupType": "APPLICATION",
      "securityGroupTag": 0,
      "state": "ACTIVE",
      "vnAgnostic": true,
      "identitySource": {
        "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
        "instanceId": 10010,
        "authEntityId": 10010,
        "displayName": "10010",
        "authEntityClass": 1738953278,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "state": "INACTIVE",
        "type": "NBAR"
      },
      "indicativeNetworkIdentity": [
        {
          "id": "6df64ebc-2063-4131-939e-38114535a6be",
          "instanceId": 13949,
          "authEntityId": 13949,
          "displayName": "13949",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipProtocolNumbers": "137",
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "protocol": "MPLS_IN_IP",
          "upperPort": 0
        }
      ],
      "networkApplications": [
        {
          "id": "36fe896e-36c7-420c-8660-dc4d93f2dc75",
          "instanceId": 11714,
          "authEntityId": 11714,
          "displayName": "11714",
          "authEntityClass": -217092956,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "appProtocol": "ip",
          "applicationGroup": "other",
          "applicationType": "DEFAULT",
          "categoryId": "6daa3dfd-3526-407a-88f0-97824b3f3a24",
          "createTime": 1517966743446,
          "encrypted": "false",
          "engineId": "1",
          "helpString": "MPLS-in-IP",
          "ignoreConflict": false,
          "lastUpdateTime": 1517966743446,
          "longDescription": "Multiprotocol Label Switching (MPLS) is a mechanism in high-performance telecommunications networks that directs data from one network node to the next based on short path labels rather than long network addresses, avoiding complex lookups in a routing table.",
          "name": "mpls-in-ip",
          "nbarId": "1235",
          "p2pTechnology": "false",
          "popularity": 5,
          "rank": 65535,
          "references": "http://www.ietf.org/rfc/rfc4023",
          "selectorId": "137",
          "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
          "trafficClass": "BULK_DATA",
          "tunnel": "false"
        }
      ],
      "networkIdentity": [
        {
          "id": "017a1458-e213-4294-aded-e2091b7fcb56",
          "instanceId": 13948,
          "authEntityId": 13948,
          "displayName": "13948",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipProtocolNumbers": "137",
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "protocol": "MPLS_IN_IP",
          "upperPort": 0
        }
      ],
      "parentScalableGroup": {
        "idRef": "000c15bf-28fe-4fbb-9b69-35e6502721f1"
      },
      "user": [

      ],
      "userGroup": [

      ]
    },
    {
      "id": "dcfb4a49-3d2f-4e29-b3c1-8504b497af66",
      "instanceId": 4183,
      "authEntityId": 4183,
      "displayName": "4183",
      "authEntityClass": -1909073334,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "createTime": 1517966741570,
      "deployed": false,
      "isSeeded": true,
      "isStale": false,
      "lastUpdateTime": 1517966741570,
      "name": "bitly",
      "namespace": "scalablegroup:application",
      "provisioningState": "DEFINED",
      "qualifier": "application",
      "resourceVersion": 0,
      "targetIdList": [

      ],
      "type": "scalablegroup",
      "cfsChangeInfo": [

      ],
      "customProvisions": [

      ],
      "scalableGroupExternalHandle": "bitly",
      "scalableGroupType": "APPLICATION",
      "securityGroupTag": 0,
      "state": "ACTIVE",
      "vnAgnostic": true,
      "identitySource": {
        "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
        "instanceId": 10010,
        "authEntityId": 10010,
        "displayName": "10010",
        "authEntityClass": 1738953278,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "state": "INACTIVE",
        "type": "NBAR"
      },
      "indicativeNetworkIdentity": [

      ],
      "networkApplications": [
        {
          "id": "aa555642-e8de-4803-a983-8bebb0394544",
          "instanceId": 11142,
          "authEntityId": 11142,
          "displayName": "11142",
          "authEntityClass": -217092956,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "appProtocol": "tcp/udp",
          "applicationGroup": "other",
          "applicationType": "DEFAULT",
          "categoryId": "878644b5-fe91-45b4-9204-31c84a73ba48",
          "createTime": 1517966741570,
          "encrypted": "false",
          "engineId": "13",
          "helpString": "bitly - Web portal for bookmarking and sharing links.",
          "ignoreConflict": false,
          "lastUpdateTime": 1517966741570,
          "longDescription": "bitly - Web portal for bookmarking and sharing links.",
          "name": "bitly",
          "nbarId": "1623",
          "p2pTechnology": "false",
          "popularity": 3,
          "rank": 65535,
          "references": "http://bitly.com",
          "selectorId": "693",
          "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
          "trafficClass": "TRANSACTIONAL_DATA",
          "tunnel": "false"
        }
      ],
      "networkIdentity": [
        {
          "id": "2d9f45bb-07ce-4ae1-b9d9-0eddf78ac4ea",
          "instanceId": 12393,
          "authEntityId": 12393,
          "displayName": "12393",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "53,80,443,5353",
          "protocol": "TCP",
          "upperPort": 0
        },
        {
          "id": "b48c3377-3ff9-4e1e-abc4-57a101e04f15",
          "instanceId": 12394,
          "authEntityId": 12394,
          "displayName": "12394",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "53,5353",
          "protocol": "UDP",
          "upperPort": 0
        }
      ],
      "parentScalableGroup": {
        "idRef": "28e08de4-d7b2-4521-84ce-bc1e5ee0b9cc"
      },
      "user": [

      ],
      "userGroup": [

      ]
    },
    {
      "id": "dcfcbe3a-e224-47a7-9a63-d01261106de5",
      "instanceId": 4256,
      "authEntityId": 4256,
      "displayName": "4256",
      "authEntityClass": -1909073334,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "createTime": 1517966741959,
      "deployed": false,
      "isSeeded": true,
      "isStale": false,
      "lastUpdateTime": 1517966741959,
      "name": "cisco-sys",
      "namespace": "scalablegroup:application",
      "provisioningState": "DEFINED",
      "qualifier": "application",
      "resourceVersion": 0,
      "targetIdList": [

      ],
      "type": "scalablegroup",
      "cfsChangeInfo": [

      ],
      "customProvisions": [

      ],
      "scalableGroupExternalHandle": "cisco-sys",
      "scalableGroupType": "APPLICATION",
      "securityGroupTag": 0,
      "state": "ACTIVE",
      "vnAgnostic": true,
      "identitySource": {
        "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
        "instanceId": 10010,
        "authEntityId": 10010,
        "displayName": "10010",
        "authEntityClass": 1738953278,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "state": "INACTIVE",
        "type": "NBAR"
      },
      "indicativeNetworkIdentity": [
        {
          "id": "44ea9334-4a8a-45ae-a308-5209623a675f",
          "instanceId": 12542,
          "authEntityId": 12542,
          "displayName": "12542",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "132",
          "protocol": "TCP",
          "upperPort": 0
        },
        {
          "id": "0842a844-1d4f-4a80-9c26-88894aeaec56",
          "instanceId": 12543,
          "authEntityId": 12543,
          "displayName": "12543",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "132",
          "protocol": "UDP",
          "upperPort": 0
        }
      ],
      "networkApplications": [
        {
          "id": "1f7d05dc-8e05-4756-ab74-f8f639d7c5bc",
          "instanceId": 11210,
          "authEntityId": 11210,
          "displayName": "11210",
          "authEntityClass": -217092956,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "appProtocol": "tcp/udp",
          "applicationGroup": "other",
          "applicationType": "DEFAULT",
          "categoryId": "1fc17362-9334-4c7b-b752-5afdbb574017",
          "createTime": 1517966741959,
          "encrypted": "false",
          "engineId": "3",
          "helpString": "cisco SYSMAINT",
          "ignoreConflict": false,
          "lastUpdateTime": 1517966741959,
          "longDescription": "Cisco SYSMAINT, Registered with IANA on port 132 TCP/UDP",
          "name": "cisco-sys",
          "nbarId": "1161",
          "p2pTechnology": "false",
          "popularity": 3,
          "rank": 65535,
          "references": "http://www.iana.org/assignments/service-names-port-numbers/service-names-port-numbers.xml",
          "selectorId": "132",
          "subCategory": "f3c0f761-d894-4112-ab89-b90fc84fd660",
          "trafficClass": "OPS_ADMIN_MGMT",
          "tunnel": "false"
        }
      ],
      "networkIdentity": [
        {
          "id": "f60e9cad-da3c-4aad-bdb2-b73c0077953c",
          "instanceId": 12540,
          "authEntityId": 12540,
          "displayName": "12540",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "132",
          "protocol": "UDP",
          "upperPort": 0
        },
        {
          "id": "80b3cc02-8bfd-411a-83ef-b391f49ae105",
          "instanceId": 12541,
          "authEntityId": 12541,
          "displayName": "12541",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "132",
          "protocol": "TCP",
          "upperPort": 0
        }
      ],
      "parentScalableGroup": {
        "idRef": "5fcd74d4-8726-47f7-a088-c457863da7ef"
      },
      "user": [

      ],
      "userGroup": [

      ]
    },
    {
      "id": "dd0434a6-81d9-4b82-8ace-fa0230cc36b9",
      "instanceId": 15225,
      "authEntityId": 15225,
      "displayName": "15225",
      "authEntityClass": -1909073334,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "createTime": 1517966744531,
      "deployed": false,
      "isSeeded": true,
      "isStale": false,
      "lastUpdateTime": 1517966744531,
      "name": "stx",
      "namespace": "scalablegroup:application",
      "provisioningState": "DEFINED",
      "qualifier": "application",
      "resourceVersion": 0,
      "targetIdList": [

      ],
      "type": "scalablegroup",
      "cfsChangeInfo": [

      ],
      "customProvisions": [

      ],
      "scalableGroupExternalHandle": "stx",
      "scalableGroupType": "APPLICATION",
      "securityGroupTag": 0,
      "state": "ACTIVE",
      "vnAgnostic": true,
      "identitySource": {
        "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
        "instanceId": 10010,
        "authEntityId": 10010,
        "displayName": "10010",
        "authEntityClass": 1738953278,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "state": "INACTIVE",
        "type": "NBAR"
      },
      "indicativeNetworkIdentity": [
        {
          "id": "57a6db36-9fca-4241-b59d-0a64dc82fa33",
          "instanceId": 17306,
          "authEntityId": 17306,
          "displayName": "17306",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "527",
          "protocol": "TCP",
          "upperPort": 0
        },
        {
          "id": "b3f9ea12-cd24-4e8f-a30b-10a5a441b40f",
          "instanceId": 17307,
          "authEntityId": 17307,
          "displayName": "17307",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "527",
          "protocol": "UDP",
          "upperPort": 0
        }
      ],
      "networkApplications": [
        {
          "id": "cc8ae69c-b368-4d34-9e93-809526faf07e",
          "instanceId": 16168,
          "authEntityId": 16168,
          "displayName": "16168",
          "authEntityClass": -217092956,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "appProtocol": "tcp/udp",
          "applicationGroup": "other",
          "applicationType": "DEFAULT",
          "categoryId": "6daa3dfd-3526-407a-88f0-97824b3f3a24",
          "createTime": 1517966744531,
          "encrypted": "false",
          "engineId": "3",
          "helpString": "Stock IXChange",
          "ignoreConflict": false,
          "lastUpdateTime": 1517966744531,
          "longDescription": "Registered with IANA on port 527 TCP/UDP",
          "name": "stx",
          "nbarId": "445",
          "p2pTechnology": "false",
          "popularity": 1,
          "rank": 65535,
          "references": "http://www.iana.org/assignments/service-names-port-numbers/service-names-port-numbers.xml",
          "selectorId": "527",
          "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
          "trafficClass": "BULK_DATA",
          "tunnel": "false"
        }
      ],
      "networkIdentity": [
        {
          "id": "33213641-fdf5-4d7b-a3b7-80b16bd498bf",
          "instanceId": 17304,
          "authEntityId": 17304,
          "displayName": "17304",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "527",
          "protocol": "UDP",
          "upperPort": 0
        },
        {
          "id": "45c10bdc-77d7-4fa6-ad23-7e736ab697c8",
          "instanceId": 17305,
          "authEntityId": 17305,
          "displayName": "17305",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "527",
          "protocol": "TCP",
          "upperPort": 0
        }
      ],
      "parentScalableGroup": {
        "idRef": "000c15bf-28fe-4fbb-9b69-35e6502721f1"
      },
      "user": [

      ],
      "userGroup": [

      ]
    },
    {
      "id": "dd2066d6-6c98-432e-a7d1-0164c1d3db67",
      "instanceId": 15468,
      "authEntityId": 15468,
      "displayName": "15468",
      "authEntityClass": -1909073334,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "createTime": 1517966745026,
      "deployed": false,
      "isSeeded": true,
      "isStale": false,
      "lastUpdateTime": 1517966745026,
      "name": "zippyshare",
      "namespace": "scalablegroup:application",
      "provisioningState": "DEFINED",
      "qualifier": "application",
      "resourceVersion": 0,
      "targetIdList": [

      ],
      "type": "scalablegroup",
      "cfsChangeInfo": [

      ],
      "customProvisions": [

      ],
      "scalableGroupExternalHandle": "zippyshare",
      "scalableGroupType": "APPLICATION",
      "securityGroupTag": 0,
      "state": "ACTIVE",
      "vnAgnostic": true,
      "identitySource": {
        "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
        "instanceId": 10010,
        "authEntityId": 10010,
        "displayName": "10010",
        "authEntityClass": 1738953278,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "state": "INACTIVE",
        "type": "NBAR"
      },
      "indicativeNetworkIdentity": [

      ],
      "networkApplications": [
        {
          "id": "7a0b4330-893f-41aa-b9aa-7278d2ac8374",
          "instanceId": 16411,
          "authEntityId": 16411,
          "displayName": "16411",
          "authEntityClass": -217092956,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "appProtocol": "tcp/udp",
          "applicationGroup": "other",
          "applicationType": "DEFAULT",
          "categoryId": "878644b5-fe91-45b4-9204-31c84a73ba48",
          "createTime": 1517966745026,
          "encrypted": "false",
          "engineId": "13",
          "helpString": "Zippyshare - File hosting site.",
          "ignoreConflict": false,
          "lastUpdateTime": 1517966745026,
          "longDescription": "Zippyshare - File hosting site.",
          "name": "zippyshare",
          "nbarId": "1652",
          "p2pTechnology": "false",
          "popularity": 3,
          "rank": 65535,
          "references": "http://zippyshare.com",
          "selectorId": "722",
          "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
          "trafficClass": "TRANSACTIONAL_DATA",
          "tunnel": "false"
        }
      ],
      "networkIdentity": [
        {
          "id": "709aa8f3-d4be-4c0a-a74d-245744e6d822",
          "instanceId": 17972,
          "authEntityId": 17972,
          "displayName": "17972",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "53,5353",
          "protocol": "UDP",
          "upperPort": 0
        },
        {
          "id": "64b3284d-f4d2-4627-874a-6093bdce0bbf",
          "instanceId": 17973,
          "authEntityId": 17973,
          "displayName": "17973",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "53,80,443,5353",
          "protocol": "TCP",
          "upperPort": 0
        }
      ],
      "parentScalableGroup": {
        "idRef": "28e08de4-d7b2-4521-84ce-bc1e5ee0b9cc"
      },
      "user": [

      ],
      "userGroup": [

      ]
    },
    {
      "id": "dd6dce5f-67cd-413a-b150-5c093a095fbd",
      "instanceId": 4911,
      "authEntityId": 4911,
      "displayName": "4911",
      "authEntityClass": -1909073334,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "createTime": 1517966743743,
      "deployed": false,
      "isSeeded": true,
      "isStale": false,
      "lastUpdateTime": 1517966743743,
      "name": "opc-job-start",
      "namespace": "scalablegroup:application",
      "provisioningState": "DEFINED",
      "qualifier": "application",
      "resourceVersion": 0,
      "targetIdList": [

      ],
      "type": "scalablegroup",
      "cfsChangeInfo": [

      ],
      "customProvisions": [

      ],
      "scalableGroupExternalHandle": "opc-job-start",
      "scalableGroupType": "APPLICATION",
      "securityGroupTag": 0,
      "state": "ACTIVE",
      "vnAgnostic": true,
      "identitySource": {
        "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
        "instanceId": 10010,
        "authEntityId": 10010,
        "displayName": "10010",
        "authEntityClass": 1738953278,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "state": "INACTIVE",
        "type": "NBAR"
      },
      "indicativeNetworkIdentity": [
        {
          "id": "4dd9af98-4028-4deb-966c-8e31f28a0bc5",
          "instanceId": 14396,
          "authEntityId": 14396,
          "displayName": "14396",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "423",
          "protocol": "TCP",
          "upperPort": 0
        },
        {
          "id": "d20449c0-3a63-4ac2-8ef4-d54554a1dd92",
          "instanceId": 14397,
          "authEntityId": 14397,
          "displayName": "14397",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "423",
          "protocol": "UDP",
          "upperPort": 0
        }
      ],
      "networkApplications": [
        {
          "id": "9c551dfd-4f0b-49ee-8ff3-1caa69cb5792",
          "instanceId": 11860,
          "authEntityId": 11860,
          "displayName": "11860",
          "authEntityClass": -217092956,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "appProtocol": "tcp/udp",
          "applicationGroup": "other",
          "applicationType": "DEFAULT",
          "categoryId": "6daa3dfd-3526-407a-88f0-97824b3f3a24",
          "createTime": 1517966743743,
          "encrypted": "false",
          "engineId": "3",
          "helpString": "IBM Operations Planning and Control Start",
          "ignoreConflict": false,
          "lastUpdateTime": 1517966743743,
          "longDescription": "Operations Planning and Control (OPC) is part of IBM Tivoli OPC Operations and Administration discipline. Tivoli OPC supports operations management, providing the foundation for enterprise-wide production workload management. Tivoli OPC helps users plan, manage, and automate the production workload, and control a single-image OS/390 system or complex, multivendor networks and systems from a single point of control.",
          "name": "opc-job-start",
          "nbarId": "338",
          "p2pTechnology": "false",
          "popularity": 1,
          "rank": 65535,
          "references": "http://publib.boulder.ibm.com/tividd/td/OperationsPlanningandControl2.3.html",
          "selectorId": "423",
          "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
          "trafficClass": "BULK_DATA",
          "tunnel": "false"
        }
      ],
      "networkIdentity": [
        {
          "id": "4e25eb64-0d0d-4f85-98e0-5753f23b8f0b",
          "instanceId": 14394,
          "authEntityId": 14394,
          "displayName": "14394",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "423",
          "protocol": "TCP",
          "upperPort": 0
        },
        {
          "id": "bc8998bc-a19e-4107-9184-0289cb5d1c01",
          "instanceId": 14395,
          "authEntityId": 14395,
          "displayName": "14395",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "423",
          "protocol": "UDP",
          "upperPort": 0
        }
      ],
      "parentScalableGroup": {
        "idRef": "000c15bf-28fe-4fbb-9b69-35e6502721f1"
      },
      "user": [

      ],
      "userGroup": [

      ]
    },
    {
      "id": "dd8f2d4b-fb42-4acd-ae35-c722dc96237e",
      "instanceId": 4520,
      "authEntityId": 4520,
      "displayName": "4520",
      "authEntityClass": -1909073334,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "createTime": 1517966742875,
      "deployed": false,
      "isSeeded": true,
      "isStale": false,
      "lastUpdateTime": 1517966742875,
      "name": "gtalk-voip",
      "namespace": "scalablegroup:application",
      "provisioningState": "DEFINED",
      "qualifier": "application",
      "resourceVersion": 0,
      "targetIdList": [

      ],
      "type": "scalablegroup",
      "cfsChangeInfo": [

      ],
      "customProvisions": [

      ],
      "scalableGroupExternalHandle": "gtalk-voip",
      "scalableGroupType": "APPLICATION",
      "securityGroupTag": 0,
      "state": "ACTIVE",
      "vnAgnostic": true,
      "identitySource": {
        "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
        "instanceId": 10010,
        "authEntityId": 10010,
        "displayName": "10010",
        "authEntityClass": 1738953278,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "state": "INACTIVE",
        "type": "NBAR"
      },
      "indicativeNetworkIdentity": [

      ],
      "networkApplications": [
        {
          "id": "a47c484e-fc0f-498a-aa28-fe81572338fa",
          "instanceId": 11471,
          "authEntityId": 11471,
          "displayName": "11471",
          "authEntityClass": -217092956,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "appProtocol": "tcp/udp",
          "applicationGroup": "gtalk-group",
          "applicationType": "DEFAULT",
          "categoryId": "82a7b7fa-da5a-48f5-8b6a-a2546b947843",
          "createTime": 1517966742875,
          "encrypted": "true",
          "engineId": "13",
          "helpString": "Protocol integrated into hangouts-audio",
          "ignoreConflict": false,
          "lastUpdateTime": 1517966742875,
          "longDescription": "Google Talk Voice is a feature of Google Talk (GTALK) that allows users to make VoIP calls.  The protocol is based on Google Talk (GTALK), STUN and HTTP. Google Talk is now being replaced by Google Hangouts",
          "name": "gtalk-voip",
          "nbarId": "1198",
          "p2pTechnology": "false",
          "popularity": 10,
          "rank": 65535,
          "references": "http://en.wikipedia.org/wiki/Google_talk",
          "selectorId": "305",
          "subCategory": "118c10a2-e272-47c1-92b9-4814ebe97138",
          "trafficClass": "VOIP_TELEPHONY",
          "tunnel": "false"
        }
      ],
      "networkIdentity": [
        {
          "id": "eab5c596-ebdd-4dc5-9717-9cb3d711b093",
          "instanceId": 13293,
          "authEntityId": 13293,
          "displayName": "13293",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "3478",
          "protocol": "UDP",
          "upperPort": 0
        },
        {
          "id": "a2a9ef1d-9c77-4c2a-88fc-8b9708bdc5d5",
          "instanceId": 13294,
          "authEntityId": 13294,
          "displayName": "13294",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "80,3478",
          "protocol": "TCP",
          "upperPort": 0
        }
      ],
      "parentScalableGroup": {
        "idRef": "fd6264c9-c16a-4311-a335-a3db039256f3"
      },
      "user": [

      ],
      "userGroup": [

      ]
    },
    {
      "id": "ddcd1725-723f-4dd8-9ff2-6f360d5dcc88",
      "instanceId": 4094,
      "authEntityId": 4094,
      "displayName": "4094",
      "authEntityClass": -1909073334,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "createTime": 1517966741043,
      "deployed": false,
      "isSeeded": true,
      "isStale": false,
      "lastUpdateTime": 1517966741043,
      "name": "aol-messenger-audio",
      "namespace": "scalablegroup:application",
      "provisioningState": "DEFINED",
      "qualifier": "application",
      "resourceVersion": 0,
      "targetIdList": [

      ],
      "type": "scalablegroup",
      "cfsChangeInfo": [

      ],
      "customProvisions": [

      ],
      "scalableGroupExternalHandle": "aol-messenger-audio",
      "scalableGroupType": "APPLICATION",
      "securityGroupTag": 0,
      "state": "ACTIVE",
      "vnAgnostic": true,
      "identitySource": {
        "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
        "instanceId": 10010,
        "authEntityId": 10010,
        "displayName": "10010",
        "authEntityClass": 1738953278,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "state": "INACTIVE",
        "type": "NBAR"
      },
      "indicativeNetworkIdentity": [

      ],
      "networkApplications": [
        {
          "id": "881fd572-6859-45ca-8113-1ff47ad8783a",
          "instanceId": 11058,
          "authEntityId": 11058,
          "displayName": "11058",
          "authEntityClass": -217092956,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "appProtocol": "tcp/udp",
          "applicationGroup": "aol-group",
          "applicationType": "DEFAULT",
          "categoryId": "82a7b7fa-da5a-48f5-8b6a-a2546b947843",
          "createTime": 1517966741043,
          "encrypted": "false",
          "engineId": "13",
          "helpString": "AOL Instant Messenger Audio Calls traffic",
          "ignoreConflict": false,
          "lastUpdateTime": 1517966741043,
          "longDescription": "AOL Instant Messenger (AIM) Audio Calls classification. Flows can be over TCP or over UDP if the two clients are on the same network. AIM uses RTP over STUN to send audio data over UDP.",
          "name": "aol-messenger-audio",
          "nbarId": "1436",
          "p2pTechnology": "false",
          "popularity": 7,
          "rank": 65535,
          "references": "http://www.aim.com/",
          "selectorId": "500",
          "subCategory": "118c10a2-e272-47c1-92b9-4814ebe97138",
          "trafficClass": "VOIP_TELEPHONY",
          "tunnel": "false"
        }
      ],
      "networkIdentity": [
        {
          "id": "9ae72b90-081c-4cb4-80ef-8eb3c51938be",
          "instanceId": 12148,
          "authEntityId": 12148,
          "displayName": "12148",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "3478",
          "protocol": "UDP",
          "upperPort": 0
        },
        {
          "id": "ba94ca52-8af8-413a-80e0-ea6cc263ca97",
          "instanceId": 12149,
          "authEntityId": 12149,
          "displayName": "12149",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "3478",
          "protocol": "TCP",
          "upperPort": 0
        }
      ],
      "parentScalableGroup": {
        "idRef": "fd6264c9-c16a-4311-a335-a3db039256f3"
      },
      "user": [

      ],
      "userGroup": [

      ]
    },
    {
      "id": "de8933ff-545c-40ff-a99c-ef5589ea4faf",
      "instanceId": 4208,
      "authEntityId": 4208,
      "displayName": "4208",
      "authEntityClass": -1909073334,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "createTime": 1517966741727,
      "deployed": false,
      "isSeeded": true,
      "isStale": false,
      "lastUpdateTime": 1517966741727,
      "name": "california-gov",
      "namespace": "scalablegroup:application",
      "provisioningState": "DEFINED",
      "qualifier": "application",
      "resourceVersion": 0,
      "targetIdList": [

      ],
      "type": "scalablegroup",
      "cfsChangeInfo": [

      ],
      "customProvisions": [

      ],
      "scalableGroupExternalHandle": "california-gov",
      "scalableGroupType": "APPLICATION",
      "securityGroupTag": 0,
      "state": "ACTIVE",
      "vnAgnostic": true,
      "identitySource": {
        "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
        "instanceId": 10010,
        "authEntityId": 10010,
        "displayName": "10010",
        "authEntityClass": 1738953278,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "state": "INACTIVE",
        "type": "NBAR"
      },
      "indicativeNetworkIdentity": [

      ],
      "networkApplications": [
        {
          "id": "b8634ba1-63fe-4ba3-8946-e21b79191391",
          "instanceId": 11164,
          "authEntityId": 11164,
          "displayName": "11164",
          "authEntityClass": -217092956,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "appProtocol": "tcp/udp",
          "applicationGroup": "other",
          "applicationType": "DEFAULT",
          "categoryId": "878644b5-fe91-45b4-9204-31c84a73ba48",
          "createTime": 1517966741727,
          "encrypted": "false",
          "engineId": "13",
          "helpString": "California.gov - California government official website.",
          "ignoreConflict": false,
          "lastUpdateTime": 1517966741727,
          "longDescription": "California.gov - California government official website.",
          "name": "california-gov",
          "nbarId": "1663",
          "p2pTechnology": "false",
          "popularity": 3,
          "rank": 65535,
          "references": "http://ca.gov",
          "selectorId": "733",
          "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
          "trafficClass": "TRANSACTIONAL_DATA",
          "tunnel": "false"
        }
      ],
      "networkIdentity": [
        {
          "id": "d520b11d-d126-4121-8e44-b5c06b98ac06",
          "instanceId": 12453,
          "authEntityId": 12453,
          "displayName": "12453",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "53,80,443,5353",
          "protocol": "TCP",
          "upperPort": 0
        },
        {
          "id": "ff7080ea-58d6-4a7b-8608-8fd07a3204f4",
          "instanceId": 12454,
          "authEntityId": 12454,
          "displayName": "12454",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "53,5353",
          "protocol": "UDP",
          "upperPort": 0
        }
      ],
      "parentScalableGroup": {
        "idRef": "28e08de4-d7b2-4521-84ce-bc1e5ee0b9cc"
      },
      "user": [

      ],
      "userGroup": [

      ]
    },
    {
      "id": "de8fb133-7cd2-4af8-acce-14217fe14627",
      "instanceId": 4343,
      "authEntityId": 4343,
      "displayName": "4343",
      "authEntityClass": -1909073334,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "createTime": 1517966742345,
      "deployed": false,
      "isSeeded": true,
      "isStale": false,
      "lastUpdateTime": 1517966742345,
      "name": "dec_dlm",
      "namespace": "scalablegroup:application",
      "provisioningState": "DEFINED",
      "qualifier": "application",
      "resourceVersion": 0,
      "targetIdList": [

      ],
      "type": "scalablegroup",
      "cfsChangeInfo": [

      ],
      "customProvisions": [

      ],
      "scalableGroupExternalHandle": "dec_dlm",
      "scalableGroupType": "APPLICATION",
      "securityGroupTag": 0,
      "state": "ACTIVE",
      "vnAgnostic": true,
      "identitySource": {
        "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
        "instanceId": 10010,
        "authEntityId": 10010,
        "displayName": "10010",
        "authEntityClass": 1738953278,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "state": "INACTIVE",
        "type": "NBAR"
      },
      "indicativeNetworkIdentity": [
        {
          "id": "79a92731-9908-4156-a44f-be01bc9d9ca8",
          "instanceId": 12804,
          "authEntityId": 12804,
          "displayName": "12804",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "625",
          "protocol": "TCP",
          "upperPort": 0
        },
        {
          "id": "f6bf0e7e-1864-41ae-bd6f-273edd386409",
          "instanceId": 12805,
          "authEntityId": 12805,
          "displayName": "12805",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "625",
          "protocol": "UDP",
          "upperPort": 0
        }
      ],
      "networkApplications": [
        {
          "id": "781d5e0d-6fb2-4273-b890-8d254d02a206",
          "instanceId": 11294,
          "authEntityId": 11294,
          "displayName": "11294",
          "authEntityClass": -217092956,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "appProtocol": "tcp/udp",
          "applicationGroup": "other",
          "applicationType": "DEFAULT",
          "categoryId": "6daa3dfd-3526-407a-88f0-97824b3f3a24",
          "createTime": 1517966742345,
          "encrypted": "false",
          "engineId": "3",
          "helpString": "dec_dlm",
          "ignoreConflict": false,
          "lastUpdateTime": 1517966742345,
          "longDescription": "Registered with IANA on port 625 TCP/UDP",
          "name": "dec_dlm",
          "nbarId": "534",
          "p2pTechnology": "false",
          "popularity": 1,
          "rank": 65535,
          "references": "http://www.iana.org/assignments/service-names-port-numbers/service-names-port-numbers.xml",
          "selectorId": "625",
          "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
          "trafficClass": "BULK_DATA",
          "tunnel": "false"
        }
      ],
      "networkIdentity": [
        {
          "id": "055e7210-ccae-41d6-ad6d-12b8c4fe806a",
          "instanceId": 12802,
          "authEntityId": 12802,
          "displayName": "12802",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "625",
          "protocol": "TCP",
          "upperPort": 0
        },
        {
          "id": "c2666bc0-0249-4fcf-83e8-db08b3d4e62c",
          "instanceId": 12803,
          "authEntityId": 12803,
          "displayName": "12803",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "625",
          "protocol": "UDP",
          "upperPort": 0
        }
      ],
      "parentScalableGroup": {
        "idRef": "000c15bf-28fe-4fbb-9b69-35e6502721f1"
      },
      "user": [

      ],
      "userGroup": [

      ]
    },
    {
      "id": "ded96d9f-3381-4dd3-afc3-7cf41912dc08",
      "instanceId": 4372,
      "authEntityId": 4372,
      "displayName": "4372",
      "authEntityClass": -1909073334,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "createTime": 1517966742441,
      "deployed": false,
      "isSeeded": true,
      "isStale": false,
      "lastUpdateTime": 1517966742441,
      "name": "webex",
      "namespace": "scalablegroup:application",
      "provisioningState": "DEFINED",
      "qualifier": "application",
      "resourceVersion": 0,
      "targetIdList": [

      ],
      "type": "scalablegroup",
      "cfsChangeInfo": [

      ],
      "customProvisions": [

      ],
      "scalableGroupExternalHandle": "webex",
      "scalableGroupType": "APPLICATION",
      "securityGroupTag": 0,
      "state": "ACTIVE",
      "vnAgnostic": true,
      "identitySource": {
        "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
        "instanceId": 10010,
        "authEntityId": 10010,
        "displayName": "10010",
        "authEntityClass": 1738953278,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "state": "INACTIVE",
        "type": "NBAR"
      },
      "indicativeNetworkIdentity": [

      ],
      "networkApplications": [
        {
          "id": "5a265d29-083c-4ea9-abca-7386400d736a",
          "instanceId": 11323,
          "authEntityId": 11323,
          "displayName": "11323",
          "authEntityClass": -217092956,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "appProtocol": "tcp/udp",
          "applicationGroup": "other",
          "applicationType": "DEFAULT",
          "categoryId": "878644b5-fe91-45b4-9204-31c84a73ba48",
          "createTime": 1517966742441,
          "encrypted": "false",
          "engineId": "13",
          "helpString": "webex - Official webex website.",
          "ignoreConflict": false,
          "lastUpdateTime": 1517966742441,
          "longDescription": "webex - Official webex website.",
          "name": "webex",
          "nbarId": "1687",
          "p2pTechnology": "false",
          "popularity": 3,
          "rank": 65535,
          "references": "http://webex.com",
          "selectorId": "757",
          "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
          "trafficClass": "TRANSACTIONAL_DATA",
          "tunnel": "false"
        }
      ],
      "networkIdentity": [
        {
          "id": "bb365483-f96a-48bf-b469-9fa233c44170",
          "instanceId": 12901,
          "authEntityId": 12901,
          "displayName": "12901",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "53,80,443,5353",
          "protocol": "TCP",
          "upperPort": 0
        },
        {
          "id": "20300b76-6ace-4445-87f1-af5b502e1d14",
          "instanceId": 12902,
          "authEntityId": 12902,
          "displayName": "12902",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "53,5353",
          "protocol": "UDP",
          "upperPort": 0
        }
      ],
      "parentScalableGroup": {
        "idRef": "28e08de4-d7b2-4521-84ce-bc1e5ee0b9cc"
      },
      "user": [

      ],
      "userGroup": [

      ]
    },


    {
        "id": "ded96d9f-3381-4dd3-afc3-7cf41912dc07",
        "instanceId": 4372,
        "authEntityId": 4372,
        "displayName": "4372",
        "authEntityClass": -1909073334,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "createTime": 1517966742441,
        "deployed": false,
        "isSeeded": true,
        "isStale": false,
        "lastUpdateTime": 1517966742441,
        "name": "disney-web-portal",
        "namespace": "scalablegroup:application",
        "provisioningState": "DEFINED",
        "qualifier": "application",
        "resourceVersion": 0,
        "targetIdList": [

        ],
        "type": "scalablegroup",
        "cfsChangeInfo": [

        ],
        "customProvisions": [

        ],
        "scalableGroupExternalHandle": "disney-web-portal",
        "scalableGroupType": "APPLICATION",
        "securityGroupTag": 0,
        "state": "ACTIVE",
        "vnAgnostic": true,
        "identitySource": {
          "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
          "instanceId": 10010,
          "authEntityId": 10010,
          "displayName": "10010",
          "authEntityClass": 1738953278,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "state": "INACTIVE",
          "type": "NBAR"
        },
        "indicativeNetworkIdentity": [

        ],
        "networkApplications": [
          {
            "id": "5a265d29-083c-4ea9-abca-7386400d736a",
            "instanceId": 11323,
            "authEntityId": 11323,
            "displayName": "11323",
            "authEntityClass": -217092956,
            "deployPending": "NONE",
            "instanceVersion": 0,
            "appProtocol": "tcp/udp",
            "applicationGroup": "other",
            "applicationType": "DEFAULT",
            "categoryId": "878644b5-fe91-45b4-9204-31c84a73ba48",
            "createTime": 1517966742441,
            "encrypted": "false",
            "engineId": "13",
            "helpString": "Disney - Official Disney website.",
            "ignoreConflict": false,
            "lastUpdateTime": 1517966742441,
            "longDescription": "Disney - Official Disney website.",
            "name": "disney-web-portal",
            "nbarId": "1687",
            "p2pTechnology": "false",
            "popularity": 3,
            "rank": 65535,
            "references": "http://disney.com",
            "selectorId": "757",
            "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
            "trafficClass": "TRANSACTIONAL_DATA",
            "tunnel": "false"
          }
        ],
        "networkIdentity": [
          {
            "id": "bb365483-f96a-48bf-b469-9fa233c44170",
            "instanceId": 12901,
            "authEntityId": 12901,
            "displayName": "12901",
            "authEntityClass": 124090504,
            "deployPending": "NONE",
            "instanceVersion": 0,
            "ipv4Subnet": [

            ],
            "ipv6Subnet": [

            ],
            "lowerPort": 0,
            "ports": "53,80,443,5353",
            "protocol": "TCP",
            "upperPort": 0
          },
          {
            "id": "20300b76-6ace-4445-87f1-af5b502e1d14",
            "instanceId": 12902,
            "authEntityId": 12902,
            "displayName": "12902",
            "authEntityClass": 124090504,
            "deployPending": "NONE",
            "instanceVersion": 0,
            "ipv4Subnet": [

            ],
            "ipv6Subnet": [

            ],
            "lowerPort": 0,
            "ports": "53,5353",
            "protocol": "UDP",
            "upperPort": 0
          }
        ],
        "parentScalableGroup": {
          "idRef": "28e08de4-d7b2-4521-84ce-bc1e5ee0b9cc"
        },
        "user": [

        ],
        "userGroup": [

        ]
      },
    {
      "id": "df1de9c0-aff7-44bc-b3d5-0b4b592ef341",
      "instanceId": 15139,
      "authEntityId": 15139,
      "displayName": "15139",
      "authEntityClass": -1909073334,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "createTime": 1517966744323,
      "deployed": false,
      "isSeeded": true,
      "isStale": false,
      "lastUpdateTime": 1517966744323,
      "name": "shrinkwrap",
      "namespace": "scalablegroup:application",
      "provisioningState": "DEFINED",
      "qualifier": "application",
      "resourceVersion": 0,
      "targetIdList": [

      ],
      "type": "scalablegroup",
      "cfsChangeInfo": [

      ],
      "customProvisions": [

      ],
      "scalableGroupExternalHandle": "shrinkwrap",
      "scalableGroupType": "APPLICATION",
      "securityGroupTag": 0,
      "state": "ACTIVE",
      "vnAgnostic": true,
      "identitySource": {
        "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
        "instanceId": 10010,
        "authEntityId": 10010,
        "displayName": "10010",
        "authEntityClass": 1738953278,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "state": "INACTIVE",
        "type": "NBAR"
      },
      "indicativeNetworkIdentity": [
        {
          "id": "159165a6-72cf-45e9-a64c-fdf2393c91e8",
          "instanceId": 17064,
          "authEntityId": 17064,
          "displayName": "17064",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "358",
          "protocol": "TCP",
          "upperPort": 0
        },
        {
          "id": "077cbed4-d36e-45b3-b965-b1cdf3e73489",
          "instanceId": 17065,
          "authEntityId": 17065,
          "displayName": "17065",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "358",
          "protocol": "UDP",
          "upperPort": 0
        }
      ],
      "networkApplications": [
        {
          "id": "7fca708a-351c-49cc-89b9-fa3ae15c1ef8",
          "instanceId": 16082,
          "authEntityId": 16082,
          "displayName": "16082",
          "authEntityClass": -217092956,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "appProtocol": "tcp/udp",
          "applicationGroup": "other",
          "applicationType": "DEFAULT",
          "categoryId": "1fc17362-9334-4c7b-b752-5afdbb574017",
          "createTime": 1517966744323,
          "encrypted": "false",
          "engineId": "3",
          "helpString": "shrinkwrap",
          "ignoreConflict": false,
          "lastUpdateTime": 1517966744323,
          "longDescription": "Registered with IANA on port 358 TCP/UDP",
          "name": "shrinkwrap",
          "nbarId": "274",
          "p2pTechnology": "false",
          "popularity": 1,
          "rank": 65535,
          "references": "http://www.iana.org/assignments/service-names-port-numbers/service-names-port-numbers.xml",
          "selectorId": "358",
          "subCategory": "e390124a-0fd6-4253-b68e-4b0c1af8cb4b",
          "trafficClass": "OPS_ADMIN_MGMT",
          "tunnel": "false"
        }
      ],
      "networkIdentity": [
        {
          "id": "11f12632-3fbd-457b-a53f-975476d1cb5f",
          "instanceId": 17062,
          "authEntityId": 17062,
          "displayName": "17062",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "358",
          "protocol": "TCP",
          "upperPort": 0
        },
        {
          "id": "6bfcf550-6b29-4cdd-996f-43a297cc668f",
          "instanceId": 17063,
          "authEntityId": 17063,
          "displayName": "17063",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "358",
          "protocol": "UDP",
          "upperPort": 0
        }
      ],
      "parentScalableGroup": {
        "idRef": "7788bf2d-e289-4597-b500-17aacb7e820e"
      },
      "user": [

      ],
      "userGroup": [

      ]
    },
    {
      "id": "df3e24f8-e958-43d1-b526-916bac3aea2c",
      "instanceId": 15421,
      "authEntityId": 15421,
      "displayName": "15421",
      "authEntityClass": -1909073334,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "createTime": 1517966744936,
      "deployed": false,
      "isSeeded": true,
      "isStale": false,
      "lastUpdateTime": 1517966744936,
      "name": "winny",
      "namespace": "scalablegroup:application",
      "provisioningState": "DEFINED",
      "qualifier": "application",
      "resourceVersion": 0,
      "targetIdList": [

      ],
      "type": "scalablegroup",
      "cfsChangeInfo": [

      ],
      "customProvisions": [

      ],
      "scalableGroupExternalHandle": "winny",
      "scalableGroupType": "APPLICATION",
      "securityGroupTag": 0,
      "state": "ACTIVE",
      "vnAgnostic": true,
      "identitySource": {
        "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
        "instanceId": 10010,
        "authEntityId": 10010,
        "displayName": "10010",
        "authEntityClass": 1738953278,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "state": "INACTIVE",
        "type": "NBAR"
      },
      "indicativeNetworkIdentity": [

      ],
      "networkApplications": [
        {
          "id": "821feb54-ab07-4681-a772-0d96bd6b8c29",
          "instanceId": 16364,
          "authEntityId": 16364,
          "displayName": "16364",
          "authEntityClass": -217092956,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "applicationGroup": "other",
          "applicationType": "DEFAULT",
          "categoryId": "b33b2761-d981-4734-810a-a6a1c18ab352",
          "createTime": 1517966744936,
          "encrypted": "false",
          "engineId": "13",
          "helpString": "winny2 and winnyP traffic, peer-to-peer file sharing protocol",
          "ignoreConflict": false,
          "lastUpdateTime": 1517966744936,
          "longDescription": "Winny is a Japanese Peer to Peer file sharing protocol which works on windows OS. The protocol is based on WinMX.",
          "name": "winny",
          "nbarId": "372",
          "p2pTechnology": "true",
          "popularity": 6,
          "rank": 65535,
          "references": "http://arufa.wordpress.com/2008/08/10/winnys-protocol-handshake/",
          "selectorId": "469",
          "subCategory": "79fe7198-9ce3-4c01-9b58-45a8df3d9513",
          "trafficClass": "BULK_DATA",
          "tunnel": "false"
        }
      ],
      "networkIdentity": [

      ],
      "parentScalableGroup": {
        "idRef": "7a1a3bbb-dc84-4a35-82a4-7e650c4c4761"
      },
      "user": [

      ],
      "userGroup": [

      ]
    },
    {
      "id": "df9223b7-f462-455d-984f-81f53c4e2f48",
      "instanceId": 15300,
      "authEntityId": 15300,
      "displayName": "15300",
      "authEntityClass": -1909073334,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "createTime": 1517966744695,
      "deployed": false,
      "isSeeded": true,
      "isStale": false,
      "lastUpdateTime": 1517966744695,
      "name": "tpip",
      "namespace": "scalablegroup:application",
      "provisioningState": "DEFINED",
      "qualifier": "application",
      "resourceVersion": 0,
      "targetIdList": [

      ],
      "type": "scalablegroup",
      "cfsChangeInfo": [

      ],
      "customProvisions": [

      ],
      "scalableGroupExternalHandle": "tpip",
      "scalableGroupType": "APPLICATION",
      "securityGroupTag": 0,
      "state": "ACTIVE",
      "vnAgnostic": true,
      "identitySource": {
        "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
        "instanceId": 10010,
        "authEntityId": 10010,
        "displayName": "10010",
        "authEntityClass": 1738953278,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "state": "INACTIVE",
        "type": "NBAR"
      },
      "indicativeNetworkIdentity": [
        {
          "id": "ca7b431f-d1d1-4d38-a6d6-c7f1bb26990d",
          "instanceId": 17526,
          "authEntityId": 17526,
          "displayName": "17526",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "594",
          "protocol": "TCP",
          "upperPort": 0
        },
        {
          "id": "d4088d43-7fb9-4f24-b3c0-8ff88987419e",
          "instanceId": 17527,
          "authEntityId": 17527,
          "displayName": "17527",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "594",
          "protocol": "UDP",
          "upperPort": 0
        }
      ],
      "networkApplications": [
        {
          "id": "f8db2d29-d4ed-432d-ab4b-df03467c7ab8",
          "instanceId": 16243,
          "authEntityId": 16243,
          "displayName": "16243",
          "authEntityClass": -217092956,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "appProtocol": "tcp/udp",
          "applicationGroup": "other",
          "applicationType": "DEFAULT",
          "categoryId": "38a8e354-e2fe-4f3c-b1c5-2bbb90fb0d99",
          "createTime": 1517966744695,
          "encrypted": "false",
          "engineId": "3",
          "helpString": "tpip",
          "ignoreConflict": false,
          "lastUpdateTime": 1517966744695,
          "longDescription": "Registered with IANA on port 594 TCP/UDP",
          "name": "tpip",
          "nbarId": "508",
          "p2pTechnology": "false",
          "popularity": 1,
          "rank": 65535,
          "references": "http://www.iana.org/assignments/service-names-port-numbers/service-names-port-numbers.xml",
          "selectorId": "594",
          "subCategory": "68c4f989-5735-4e16-8d1c-6a9d89d5df9c",
          "trafficClass": "SIGNALING",
          "tunnel": "false"
        }
      ],
      "networkIdentity": [
        {
          "id": "498cfee3-fbff-4888-9264-7425cc01e113",
          "instanceId": 17524,
          "authEntityId": 17524,
          "displayName": "17524",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "594",
          "protocol": "TCP",
          "upperPort": 0
        },
        {
          "id": "7af59658-2555-4a42-9248-60def6645655",
          "instanceId": 17525,
          "authEntityId": 17525,
          "displayName": "17525",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "594",
          "protocol": "UDP",
          "upperPort": 0
        }
      ],
      "parentScalableGroup": {
        "idRef": "a3243a74-3864-4041-a927-0201030da17c"
      },
      "user": [

      ],
      "userGroup": [

      ]
    },
    {
      "id": "df94d36a-d348-4361-b550-c8738d53d3f2",
      "instanceId": 15037,
      "authEntityId": 15037,
      "displayName": "15037",
      "authEntityClass": -1909073334,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "createTime": 1517966744083,
      "deployed": false,
      "isSeeded": true,
      "isStale": false,
      "lastUpdateTime": 1517966744083,
      "name": "realtor-com",
      "namespace": "scalablegroup:application",
      "provisioningState": "DEFINED",
      "qualifier": "application",
      "resourceVersion": 0,
      "targetIdList": [

      ],
      "type": "scalablegroup",
      "cfsChangeInfo": [

      ],
      "customProvisions": [

      ],
      "scalableGroupExternalHandle": "realtor-com",
      "scalableGroupType": "APPLICATION",
      "securityGroupTag": 0,
      "state": "ACTIVE",
      "vnAgnostic": true,
      "identitySource": {
        "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
        "instanceId": 10010,
        "authEntityId": 10010,
        "displayName": "10010",
        "authEntityClass": 1738953278,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "state": "INACTIVE",
        "type": "NBAR"
      },
      "indicativeNetworkIdentity": [

      ],
      "networkApplications": [
        {
          "id": "9275dc2f-ab38-4789-978f-aa151000e81d",
          "instanceId": 11976,
          "authEntityId": 11976,
          "displayName": "11976",
          "authEntityClass": -217092956,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "appProtocol": "tcp/udp",
          "applicationGroup": "other",
          "applicationType": "DEFAULT",
          "categoryId": "878644b5-fe91-45b4-9204-31c84a73ba48",
          "createTime": 1517966744083,
          "encrypted": "false",
          "engineId": "13",
          "helpString": "Realtor.com - Web portal Real Estate.",
          "ignoreConflict": false,
          "lastUpdateTime": 1517966744083,
          "longDescription": "Realtor.com - Web portal Real Estate.",
          "name": "realtor-com",
          "nbarId": "1662",
          "p2pTechnology": "false",
          "popularity": 3,
          "rank": 65535,
          "references": "http://realtor.com",
          "selectorId": "732",
          "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
          "trafficClass": "TRANSACTIONAL_DATA",
          "tunnel": "false"
        }
      ],
      "networkIdentity": [
        {
          "id": "ef7ff234-5815-4e33-9dd7-01f8c3f4c5ce",
          "instanceId": 14739,
          "authEntityId": 14739,
          "displayName": "14739",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "53,5353",
          "protocol": "UDP",
          "upperPort": 0
        },
        {
          "id": "eaf64c66-6853-43c2-93bb-d0853b5b94f1",
          "instanceId": 14740,
          "authEntityId": 14740,
          "displayName": "14740",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "53,80,443,5353",
          "protocol": "TCP",
          "upperPort": 0
        }
      ],
      "parentScalableGroup": {
        "idRef": "28e08de4-d7b2-4521-84ce-bc1e5ee0b9cc"
      },
      "user": [

      ],
      "userGroup": [

      ]
    },
    {
      "id": "dfe8a370-e9e7-43d0-9363-30c16caa1d26",
      "instanceId": 15162,
      "authEntityId": 15162,
      "displayName": "15162",
      "authEntityClass": -1909073334,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "createTime": 1517966744376,
      "deployed": false,
      "isSeeded": true,
      "isStale": false,
      "lastUpdateTime": 1517966744376,
      "name": "smartpackets",
      "namespace": "scalablegroup:application",
      "provisioningState": "DEFINED",
      "qualifier": "application",
      "resourceVersion": 0,
      "targetIdList": [

      ],
      "type": "scalablegroup",
      "cfsChangeInfo": [

      ],
      "customProvisions": [

      ],
      "scalableGroupExternalHandle": "smartpackets",
      "scalableGroupType": "APPLICATION",
      "securityGroupTag": 0,
      "state": "ACTIVE",
      "vnAgnostic": true,
      "identitySource": {
        "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
        "instanceId": 10010,
        "authEntityId": 10010,
        "displayName": "10010",
        "authEntityClass": 1738953278,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "state": "INACTIVE",
        "type": "NBAR"
      },
      "indicativeNetworkIdentity": [
        {
          "id": "ddee0b64-603f-4e05-8482-7fae26d89742",
          "instanceId": 17131,
          "authEntityId": 17131,
          "displayName": "17131",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "3218",
          "protocol": "TCP",
          "upperPort": 0
        },
        {
          "id": "d23a8393-1c40-44b9-913a-2aaf994b2e13",
          "instanceId": 17132,
          "authEntityId": 17132,
          "displayName": "17132",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "3218",
          "protocol": "UDP",
          "upperPort": 0
        }
      ],
      "networkApplications": [
        {
          "id": "4aef570f-f8e4-4dff-963e-e310030b8f23",
          "instanceId": 16105,
          "authEntityId": 16105,
          "displayName": "16105",
          "authEntityClass": -217092956,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "appProtocol": "tcp/udp",
          "applicationGroup": "other",
          "applicationType": "DEFAULT",
          "categoryId": "80909d4c-efe6-4878-9e1e-68281fd26edd",
          "createTime": 1517966744376,
          "encrypted": "false",
          "engineId": "3",
          "helpString": "EMC SmartPackets",
          "ignoreConflict": false,
          "lastUpdateTime": 1517966744376,
          "longDescription": "Registered with IANA on port 3218 TCP/UDP",
          "name": "smartpackets",
          "nbarId": "1342",
          "p2pTechnology": "false",
          "popularity": 1,
          "rank": 65535,
          "references": "http://www.iana.org/assignments/service-names-port-numbers/service-names-port-numbers.xml",
          "selectorId": "3218",
          "subCategory": "4a197877-986d-4422-b48f-500fb4b3340f",
          "trafficClass": "BULK_DATA",
          "tunnel": "false"
        }
      ],
      "networkIdentity": [
        {
          "id": "6753a228-1e4a-42fe-bc68-1d55e12f984b",
          "instanceId": 17129,
          "authEntityId": 17129,
          "displayName": "17129",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "3218",
          "protocol": "UDP",
          "upperPort": 0
        },
        {
          "id": "2d3685a3-1218-44d8-9bd8-21a981398f4b",
          "instanceId": 17130,
          "authEntityId": 17130,
          "displayName": "17130",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "3218",
          "protocol": "TCP",
          "upperPort": 0
        }
      ],
      "parentScalableGroup": {
        "idRef": "f09a36de-df38-4ff8-b0be-56738afc75f7"
      },
      "user": [

      ],
      "userGroup": [

      ]
    },
    {
      "id": "e0891f40-dc06-4103-a336-929cf1d2ed81",
      "instanceId": 15135,
      "authEntityId": 15135,
      "displayName": "15135",
      "authEntityClass": -1909073334,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "createTime": 1517966744314,
      "deployed": false,
      "isSeeded": true,
      "isStale": false,
      "lastUpdateTime": 1517966744314,
      "name": "shell",
      "namespace": "scalablegroup:application",
      "provisioningState": "DEFINED",
      "qualifier": "application",
      "resourceVersion": 0,
      "targetIdList": [

      ],
      "type": "scalablegroup",
      "cfsChangeInfo": [

      ],
      "customProvisions": [

      ],
      "scalableGroupExternalHandle": "shell",
      "scalableGroupType": "APPLICATION",
      "securityGroupTag": 0,
      "state": "ACTIVE",
      "vnAgnostic": true,
      "identitySource": {
        "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
        "instanceId": 10010,
        "authEntityId": 10010,
        "displayName": "10010",
        "authEntityClass": 1738953278,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "state": "INACTIVE",
        "type": "NBAR"
      },
      "indicativeNetworkIdentity": [
        {
          "id": "eefab3d2-8094-46f2-ae17-85471398abb2",
          "instanceId": 17053,
          "authEntityId": 17053,
          "displayName": "17053",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "514",
          "protocol": "TCP",
          "upperPort": 0
        }
      ],
      "networkApplications": [
        {
          "id": "fabf647d-cb66-49f6-a24e-4c9f655d34b1",
          "instanceId": 16078,
          "authEntityId": 16078,
          "displayName": "16078",
          "authEntityClass": -217092956,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "appProtocol": "tcp",
          "applicationGroup": "other",
          "applicationType": "DEFAULT",
          "categoryId": "1fc17362-9334-4c7b-b752-5afdbb574017",
          "createTime": 1517966744314,
          "encrypted": "false",
          "engineId": "3",
          "helpString": "Execute a program on remote shell",
          "ignoreConflict": false,
          "lastUpdateTime": 1517966744314,
          "longDescription": "SHELL is a UNIX protocol which allows user to connect to a shell and to execute commands remotely on another machine. It doesn't require a password if the hostname - username combination is listed in the .rhosts file of the remote user.",
          "name": "shell",
          "nbarId": "430",
          "p2pTechnology": "false",
          "popularity": 5,
          "rank": 65535,
          "references": "http://www.bell-labs.com/history/unix/tutorial.html",
          "selectorId": "514",
          "subCategory": "787a7d77-977b-4974-89d3-379ba49e88b7",
          "trafficClass": "OPS_ADMIN_MGMT",
          "tunnel": "false"
        }
      ],
      "networkIdentity": [
        {
          "id": "cf130874-b876-47d8-a581-fd7678856e40",
          "instanceId": 17052,
          "authEntityId": 17052,
          "displayName": "17052",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "514",
          "protocol": "TCP",
          "upperPort": 0
        }
      ],
      "parentScalableGroup": {
        "idRef": "e06dd89f-0a70-4422-ab0b-c2314650a9a7"
      },
      "user": [

      ],
      "userGroup": [

      ]
    },
    {
      "id": "e0ce6fc7-a749-40b9-b4cb-d035e521b646",
      "instanceId": 4111,
      "authEntityId": 4111,
      "displayName": "4111",
      "authEntityClass": -1909073334,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "createTime": 1517966741174,
      "deployed": false,
      "isSeeded": true,
      "isStale": false,
      "lastUpdateTime": 1517966741174,
      "name": "appleqtcsrvr",
      "namespace": "scalablegroup:application",
      "provisioningState": "DEFINED",
      "qualifier": "application",
      "resourceVersion": 0,
      "targetIdList": [

      ],
      "type": "scalablegroup",
      "cfsChangeInfo": [

      ],
      "customProvisions": [

      ],
      "scalableGroupExternalHandle": "appleqtcsrvr",
      "scalableGroupType": "APPLICATION",
      "securityGroupTag": 0,
      "state": "ACTIVE",
      "vnAgnostic": true,
      "identitySource": {
        "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
        "instanceId": 10010,
        "authEntityId": 10010,
        "displayName": "10010",
        "authEntityClass": 1738953278,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "state": "INACTIVE",
        "type": "NBAR"
      },
      "indicativeNetworkIdentity": [
        {
          "id": "7578326b-6319-4849-90a9-aaa4435a5b51",
          "instanceId": 12185,
          "authEntityId": 12185,
          "displayName": "12185",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "545",
          "protocol": "UDP",
          "upperPort": 0
        },
        {
          "id": "b3778582-9923-4f97-9018-37eea41efc05",
          "instanceId": 12186,
          "authEntityId": 12186,
          "displayName": "12186",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "545",
          "protocol": "TCP",
          "upperPort": 0
        }
      ],
      "networkApplications": [
        {
          "id": "bac7d68c-a7f3-46d2-a628-d22cd1b78685",
          "instanceId": 11073,
          "authEntityId": 11073,
          "displayName": "11073",
          "authEntityClass": -217092956,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "appProtocol": "tcp/udp",
          "applicationGroup": "other",
          "applicationType": "DEFAULT",
          "categoryId": "a09ef1b3-ba26-4372-aee8-309d50697e76",
          "createTime": 1517966741174,
          "encrypted": "false",
          "engineId": "3",
          "helpString": "appleqtcsrvr",
          "ignoreConflict": false,
          "lastUpdateTime": 1517966741174,
          "longDescription": "Registered with IANA on port 545 TCP/UDP",
          "name": "appleqtcsrvr",
          "nbarId": "463",
          "p2pTechnology": "false",
          "popularity": 1,
          "rank": 65535,
          "references": "http://www.iana.org/assignments/service-names-port-numbers/service-names-port-numbers.xml",
          "selectorId": "545",
          "subCategory": "85a797c0-aef8-4284-91a7-677f33e29abb",
          "trafficClass": "MULTIMEDIA_STREAMING",
          "tunnel": "false"
        }
      ],
      "networkIdentity": [
        {
          "id": "5dcee062-8a10-401f-bf55-a57cbccfbba9",
          "instanceId": 12183,
          "authEntityId": 12183,
          "displayName": "12183",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "545",
          "protocol": "TCP",
          "upperPort": 0
        },
        {
          "id": "beead0e9-0418-4fe7-80fd-84d2616731b6",
          "instanceId": 12184,
          "authEntityId": 12184,
          "displayName": "12184",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "545",
          "protocol": "UDP",
          "upperPort": 0
        }
      ],
      "parentScalableGroup": {
        "idRef": "fd6264c9-c16a-4311-a335-a3db039256f3"
      },
      "user": [

      ],
      "userGroup": [

      ]
    },
    {
      "id": "e0e5f949-6ffd-4c53-b409-f380c63e4a8b",
      "instanceId": 15304,
      "authEntityId": 15304,
      "displayName": "15304",
      "authEntityClass": -1909073334,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "createTime": 1517966744702,
      "deployed": false,
      "isSeeded": true,
      "isStale": false,
      "lastUpdateTime": 1517966744702,
      "name": "trunk-1",
      "namespace": "scalablegroup:application",
      "provisioningState": "DEFINED",
      "qualifier": "application",
      "resourceVersion": 0,
      "targetIdList": [

      ],
      "type": "scalablegroup",
      "cfsChangeInfo": [

      ],
      "customProvisions": [

      ],
      "scalableGroupExternalHandle": "trunk-1",
      "scalableGroupType": "APPLICATION",
      "securityGroupTag": 0,
      "state": "ACTIVE",
      "vnAgnostic": true,
      "identitySource": {
        "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
        "instanceId": 10010,
        "authEntityId": 10010,
        "displayName": "10010",
        "authEntityClass": 1738953278,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "state": "INACTIVE",
        "type": "NBAR"
      },
      "indicativeNetworkIdentity": [
        {
          "id": "ca4f6d0c-b358-473b-8906-e47a6b59b41c",
          "instanceId": 17537,
          "authEntityId": 17537,
          "displayName": "17537",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipProtocolNumbers": "23",
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "protocol": "TRUNK_1",
          "upperPort": 0
        }
      ],
      "networkApplications": [
        {
          "id": "90f46694-ce87-4a82-b98b-57d6440d0cc5",
          "instanceId": 16247,
          "authEntityId": 16247,
          "displayName": "16247",
          "authEntityClass": -217092956,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "appProtocol": "ip",
          "applicationGroup": "other",
          "applicationType": "DEFAULT",
          "categoryId": "6daa3dfd-3526-407a-88f0-97824b3f3a24",
          "createTime": 1517966744702,
          "encrypted": "unassigned",
          "engineId": "1",
          "helpString": "Trunk-1",
          "ignoreConflict": false,
          "lastUpdateTime": 1517966744702,
          "longDescription": "Registered with IANA as IP Protocol 23",
          "name": "trunk-1",
          "nbarId": "777",
          "p2pTechnology": "unassigned",
          "popularity": 1,
          "rank": 65535,
          "references": "http://www.iana.org/assignments/protocol-numbers/protocol-numbers.xml",
          "selectorId": "23",
          "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
          "trafficClass": "BULK_DATA",
          "tunnel": "unassigned"
        }
      ],
      "networkIdentity": [
        {
          "id": "5b8aaa63-ae5d-48df-b987-282b85815634",
          "instanceId": 17536,
          "authEntityId": 17536,
          "displayName": "17536",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipProtocolNumbers": "23",
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "protocol": "TRUNK_1",
          "upperPort": 0
        }
      ],
      "parentScalableGroup": {
        "idRef": "000c15bf-28fe-4fbb-9b69-35e6502721f1"
      },
      "user": [

      ],
      "userGroup": [

      ]
    },
    {
      "id": "e13990f1-e13d-4e2e-b7ca-31b477ce1c8d",
      "instanceId": 4486,
      "authEntityId": 4486,
      "displayName": "4486",
      "authEntityClass": -1909073334,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "createTime": 1517966742791,
      "deployed": false,
      "isSeeded": true,
      "isStale": false,
      "lastUpdateTime": 1517966742791,
      "name": "gmail",
      "namespace": "scalablegroup:application",
      "provisioningState": "DEFINED",
      "qualifier": "application",
      "resourceVersion": 0,
      "targetIdList": [

      ],
      "type": "scalablegroup",
      "cfsChangeInfo": [

      ],
      "customProvisions": [

      ],
      "scalableGroupExternalHandle": "gmail",
      "scalableGroupType": "APPLICATION",
      "securityGroupTag": 0,
      "state": "ACTIVE",
      "vnAgnostic": true,
      "identitySource": {
        "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
        "instanceId": 10010,
        "authEntityId": 10010,
        "displayName": "10010",
        "authEntityClass": 1738953278,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "state": "INACTIVE",
        "type": "NBAR"
      },
      "indicativeNetworkIdentity": [

      ],
      "networkApplications": [
        {
          "id": "1042f52a-a96e-4dbb-9349-a1e996e6a5b6",
          "instanceId": 11437,
          "authEntityId": 11437,
          "displayName": "11437",
          "authEntityClass": -217092956,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "appProtocol": "tcp/udp",
          "applicationGroup": "google-group",
          "applicationType": "DEFAULT",
          "categoryId": "f6ec0221-b82d-43e8-a64d-163cbc13263b",
          "createTime": 1517966742791,
          "encrypted": "true",
          "engineId": "13",
          "helpString": "Gmail and Gmail-chat traffic",
          "ignoreConflict": false,
          "lastUpdateTime": 1517966742791,
          "longDescription": "GMAIL is a free web based email service provided by Google Inc. The underlying protocols for GMAIL are SSL and HTTP.",
          "name": "gmail",
          "nbarId": "1073",
          "p2pTechnology": "false",
          "popularity": 10,
          "rank": 65535,
          "references": "http://mail.google.com/mail/",
          "selectorId": "462",
          "subCategory": "aab99e59-8589-4a61-933e-f01bff556d78",
          "trafficClass": "BULK_DATA",
          "tunnel": "false"
        }
      ],
      "networkIdentity": [
        {
          "id": "e1dac985-f65f-420e-9e92-dda463b8018f",
          "instanceId": 13213,
          "authEntityId": 13213,
          "displayName": "13213",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "53,80,443,5353",
          "protocol": "TCP",
          "upperPort": 0
        },
        {
          "id": "f9802e95-f620-47bc-a633-b5a3de9b3687",
          "instanceId": 13214,
          "authEntityId": 13214,
          "displayName": "13214",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "53,5353",
          "protocol": "UDP",
          "upperPort": 0
        }
      ],
      "parentScalableGroup": {
        "idRef": "28e08de4-d7b2-4521-84ce-bc1e5ee0b9cc"
      },
      "user": [

      ],
      "userGroup": [

      ]
    },
    {
      "id": "e1467b46-1c10-4734-9053-88195a1fede1",
      "instanceId": 15270,
      "authEntityId": 15270,
      "displayName": "15270",
      "authEntityClass": -1909073334,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "createTime": 1517966744625,
      "deployed": false,
      "isSeeded": true,
      "isStale": false,
      "lastUpdateTime": 1517966744625,
      "name": "tempo",
      "namespace": "scalablegroup:application",
      "provisioningState": "DEFINED",
      "qualifier": "application",
      "resourceVersion": 0,
      "targetIdList": [

      ],
      "type": "scalablegroup",
      "cfsChangeInfo": [

      ],
      "customProvisions": [

      ],
      "scalableGroupExternalHandle": "tempo",
      "scalableGroupType": "APPLICATION",
      "securityGroupTag": 0,
      "state": "ACTIVE",
      "vnAgnostic": true,
      "identitySource": {
        "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
        "instanceId": 10010,
        "authEntityId": 10010,
        "displayName": "10010",
        "authEntityClass": 1738953278,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "state": "INACTIVE",
        "type": "NBAR"
      },
      "indicativeNetworkIdentity": [
        {
          "id": "3e35441c-13c0-4719-8137-e356718df7c8",
          "instanceId": 17441,
          "authEntityId": 17441,
          "displayName": "17441",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "526",
          "protocol": "UDP",
          "upperPort": 0
        },
        {
          "id": "16e3b3b8-4548-41b5-a545-facfe37da253",
          "instanceId": 17442,
          "authEntityId": 17442,
          "displayName": "17442",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "526",
          "protocol": "TCP",
          "upperPort": 0
        }
      ],
      "networkApplications": [
        {
          "id": "abc9e0a8-d363-487d-b311-8a6fc7de84bb",
          "instanceId": 16213,
          "authEntityId": 16213,
          "displayName": "16213",
          "authEntityClass": -217092956,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "appProtocol": "tcp/udp",
          "applicationGroup": "other",
          "applicationType": "DEFAULT",
          "categoryId": "6daa3dfd-3526-407a-88f0-97824b3f3a24",
          "createTime": 1517966744625,
          "encrypted": "false",
          "engineId": "3",
          "helpString": "newdate",
          "ignoreConflict": false,
          "lastUpdateTime": 1517966744625,
          "longDescription": "Registered with IANA on port 526 TCP/UDP",
          "name": "tempo",
          "nbarId": "444",
          "p2pTechnology": "false",
          "popularity": 1,
          "rank": 65535,
          "references": "http://www.iana.org/assignments/service-names-port-numbers/service-names-port-numbers.xml",
          "selectorId": "526",
          "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
          "trafficClass": "BULK_DATA",
          "tunnel": "false"
        }
      ],
      "networkIdentity": [
        {
          "id": "7893e4df-836e-4811-a85c-22f56d640693",
          "instanceId": 17440,
          "authEntityId": 17440,
          "displayName": "17440",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "526",
          "protocol": "UDP",
          "upperPort": 0
        },
        {
          "id": "dd240393-9d8c-4dca-9503-65e0191a0db9",
          "instanceId": 17439,
          "authEntityId": 17439,
          "displayName": "17439",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "526",
          "protocol": "TCP",
          "upperPort": 0
        }
      ],
      "parentScalableGroup": {
        "idRef": "000c15bf-28fe-4fbb-9b69-35e6502721f1"
      },
      "user": [

      ],
      "userGroup": [

      ]
    },
    {
      "id": "e15046ec-761c-42b2-9a84-6b392fdbca9e",
      "instanceId": 15344,
      "authEntityId": 15344,
      "displayName": "15344",
      "authEntityClass": -1909073334,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "createTime": 1517966744775,
      "deployed": false,
      "isSeeded": true,
      "isStale": false,
      "lastUpdateTime": 1517966744775,
      "name": "vdolive",
      "namespace": "scalablegroup:application",
      "provisioningState": "DEFINED",
      "qualifier": "application",
      "resourceVersion": 0,
      "targetIdList": [

      ],
      "type": "scalablegroup",
      "cfsChangeInfo": [

      ],
      "customProvisions": [

      ],
      "scalableGroupExternalHandle": "vdolive",
      "scalableGroupType": "APPLICATION",
      "securityGroupTag": 0,
      "state": "ACTIVE",
      "vnAgnostic": true,
      "identitySource": {
        "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
        "instanceId": 10010,
        "authEntityId": 10010,
        "displayName": "10010",
        "authEntityClass": 1738953278,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "state": "INACTIVE",
        "type": "NBAR"
      },
      "indicativeNetworkIdentity": [
        {
          "id": "cae688b6-d811-49c0-b560-175f8509edb0",
          "instanceId": 17656,
          "authEntityId": 17656,
          "displayName": "17656",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "7000",
          "protocol": "TCP",
          "upperPort": 0
        }
      ],
      "networkApplications": [
        {
          "id": "9d69fca2-d3c7-4878-afba-e4aa5518343b",
          "instanceId": 16287,
          "authEntityId": 16287,
          "displayName": "16287",
          "authEntityClass": -217092956,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "appProtocol": "tcp",
          "applicationGroup": "other",
          "applicationType": "DEFAULT",
          "categoryId": "a09ef1b3-ba26-4372-aee8-309d50697e76",
          "createTime": 1517966744775,
          "encrypted": "false",
          "engineId": "13",
          "helpString": "VDOLive streaming video",
          "ignoreConflict": false,
          "lastUpdateTime": 1517966744775,
          "longDescription": "VDOLive is a real time video-audio streaming and broadcasting player that is used in many different applications. The player is available both as a Netscape plug-in and as an Internet Explorer ActiveX control.",
          "name": "vdolive",
          "nbarId": "50",
          "p2pTechnology": "false",
          "popularity": 5,
          "rank": 65535,
          "references": "http://www.5star-shareware.com/Windows/Music/MultimediaPlayers/vdolive-player.html",
          "selectorId": "425",
          "subCategory": "85a797c0-aef8-4284-91a7-677f33e29abb",
          "trafficClass": "MULTIMEDIA_STREAMING",
          "tunnel": "false"
        }
      ],
      "networkIdentity": [
        {
          "id": "34871a90-69cc-42f9-836d-0029455db5cc",
          "instanceId": 17655,
          "authEntityId": 17655,
          "displayName": "17655",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "7000",
          "protocol": "TCP",
          "upperPort": 0
        }
      ],
      "parentScalableGroup": {
        "idRef": "fd6264c9-c16a-4311-a335-a3db039256f3"
      },
      "user": [

      ],
      "userGroup": [

      ]
    },
    {
      "id": "e15e6cad-58c7-4e12-aa3b-554b9c61f08e",
      "instanceId": 15152,
      "authEntityId": 15152,
      "displayName": "15152",
      "authEntityClass": -1909073334,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "createTime": 1517966744353,
      "deployed": false,
      "isSeeded": true,
      "isStale": false,
      "lastUpdateTime": 1517966744353,
      "name": "skip",
      "namespace": "scalablegroup:application",
      "provisioningState": "DEFINED",
      "qualifier": "application",
      "resourceVersion": 0,
      "targetIdList": [

      ],
      "type": "scalablegroup",
      "cfsChangeInfo": [

      ],
      "customProvisions": [

      ],
      "scalableGroupExternalHandle": "skip",
      "scalableGroupType": "APPLICATION",
      "securityGroupTag": 0,
      "state": "ACTIVE",
      "vnAgnostic": true,
      "identitySource": {
        "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
        "instanceId": 10010,
        "authEntityId": 10010,
        "displayName": "10010",
        "authEntityClass": 1738953278,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "state": "INACTIVE",
        "type": "NBAR"
      },
      "indicativeNetworkIdentity": [
        {
          "id": "65c769c7-b49e-4772-8490-400c52909d2a",
          "instanceId": 17105,
          "authEntityId": 17105,
          "displayName": "17105",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipProtocolNumbers": "57",
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "protocol": "SKIP",
          "upperPort": 0
        }
      ],
      "networkApplications": [
        {
          "id": "02c8a01d-0dcd-4540-93e6-f9ce32fe8f69",
          "instanceId": 16095,
          "authEntityId": 16095,
          "displayName": "16095",
          "authEntityClass": -217092956,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "appProtocol": "ip",
          "applicationGroup": "other",
          "applicationType": "DEFAULT",
          "categoryId": "6daa3dfd-3526-407a-88f0-97824b3f3a24",
          "createTime": 1517966744353,
          "encrypted": "unassigned",
          "engineId": "1",
          "helpString": "SKIP",
          "ignoreConflict": false,
          "lastUpdateTime": 1517966744353,
          "longDescription": "Simple Key-Management for Internet Protocol (SKIP) is a protocol developed for the sharing of encryption keys. SKIP was evaluated as a key exchange mechanism for IPsec before the adoption of IKE in 1998.",
          "name": "skip",
          "nbarId": "811",
          "p2pTechnology": "unassigned",
          "popularity": 2,
          "rank": 65535,
          "references": "http://en.wikipedia.org/wiki/Simple_Key-Management_for_Internet_Protocol",
          "selectorId": "57",
          "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
          "trafficClass": "BULK_DATA",
          "tunnel": "unassigned"
        }
      ],
      "networkIdentity": [
        {
          "id": "c4d1114c-42dd-4eab-86df-790121c404fe",
          "instanceId": 17104,
          "authEntityId": 17104,
          "displayName": "17104",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipProtocolNumbers": "57",
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "protocol": "SKIP",
          "upperPort": 0
        }
      ],
      "parentScalableGroup": {
        "idRef": "000c15bf-28fe-4fbb-9b69-35e6502721f1"
      },
      "user": [

      ],
      "userGroup": [

      ]
    },
    {
      "id": "e1d4f001-17a9-4585-934e-69fc4109caa2",
      "instanceId": 4846,
      "authEntityId": 4846,
      "displayName": "4846",
      "authEntityClass": -1909073334,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "createTime": 1517966743605,
      "deployed": false,
      "isSeeded": true,
      "isStale": false,
      "lastUpdateTime": 1517966743605,
      "name": "netrjs-2",
      "namespace": "scalablegroup:application",
      "provisioningState": "DEFINED",
      "qualifier": "application",
      "resourceVersion": 0,
      "targetIdList": [

      ],
      "type": "scalablegroup",
      "cfsChangeInfo": [

      ],
      "customProvisions": [

      ],
      "scalableGroupExternalHandle": "netrjs-2",
      "scalableGroupType": "APPLICATION",
      "securityGroupTag": 0,
      "state": "ACTIVE",
      "vnAgnostic": true,
      "identitySource": {
        "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
        "instanceId": 10010,
        "authEntityId": 10010,
        "displayName": "10010",
        "authEntityClass": 1738953278,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "state": "INACTIVE",
        "type": "NBAR"
      },
      "indicativeNetworkIdentity": [
        {
          "id": "7d909d48-e606-43aa-b2be-14d9be3b587e",
          "instanceId": 14170,
          "authEntityId": 14170,
          "displayName": "14170",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "72",
          "protocol": "UDP",
          "upperPort": 0
        },
        {
          "id": "1dd779a3-409c-4175-a51c-fcfeed089c48",
          "instanceId": 14171,
          "authEntityId": 14171,
          "displayName": "14171",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "72",
          "protocol": "TCP",
          "upperPort": 0
        }
      ],
      "networkApplications": [
        {
          "id": "1ef7ce7d-b315-4b7d-9d6e-66e2e6a7a856",
          "instanceId": 11795,
          "authEntityId": 11795,
          "displayName": "11795",
          "authEntityClass": -217092956,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "appProtocol": "tcp/udp",
          "applicationGroup": "other",
          "applicationType": "DEFAULT",
          "categoryId": "1fc17362-9334-4c7b-b752-5afdbb574017",
          "createTime": 1517966743605,
          "encrypted": "false",
          "engineId": "3",
          "helpString": "Remote Job Service",
          "ignoreConflict": false,
          "lastUpdateTime": 1517966743605,
          "longDescription": "NETRJS is a private protocol for remote job entry service, was defined and implemented by the UCLA Campus Computing Network (CCN). CCN's NETRJS server allows a remote user, or a daemon process working in behalf of a user, to access CCN's Remote Job Service (RJS) subsystem.",
          "name": "netrjs-2",
          "nbarId": "949",
          "p2pTechnology": "false",
          "popularity": 1,
          "rank": 65535,
          "references": "http://tools.ietf.org/html/rfc740",
          "selectorId": "72",
          "subCategory": "f3c0f761-d894-4112-ab89-b90fc84fd660",
          "trafficClass": "OPS_ADMIN_MGMT",
          "tunnel": "false"
        }
      ],
      "networkIdentity": [
        {
          "id": "b3190de5-3b4a-4d48-9d61-0645c4523fed",
          "instanceId": 14168,
          "authEntityId": 14168,
          "displayName": "14168",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "72",
          "protocol": "UDP",
          "upperPort": 0
        },
        {
          "id": "b2deab1f-fda3-4057-91b3-77434c14d7d9",
          "instanceId": 14169,
          "authEntityId": 14169,
          "displayName": "14169",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "72",
          "protocol": "TCP",
          "upperPort": 0
        }
      ],
      "parentScalableGroup": {
        "idRef": "5fcd74d4-8726-47f7-a088-c457863da7ef"
      },
      "user": [

      ],
      "userGroup": [

      ]
    },
    {
      "id": "e1e7b986-6e66-4039-951d-bb4977786c1b",
      "instanceId": 4555,
      "authEntityId": 4555,
      "displayName": "4555",
      "authEntityClass": -1909073334,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "createTime": 1517966742979,
      "deployed": false,
      "isSeeded": true,
      "isStale": false,
      "lastUpdateTime": 1517966742979,
      "name": "hp-pdl-datastr",
      "namespace": "scalablegroup:application",
      "provisioningState": "DEFINED",
      "qualifier": "application",
      "resourceVersion": 0,
      "targetIdList": [

      ],
      "type": "scalablegroup",
      "cfsChangeInfo": [

      ],
      "customProvisions": [

      ],
      "scalableGroupExternalHandle": "hp-pdl-datastr",
      "scalableGroupType": "APPLICATION",
      "securityGroupTag": 0,
      "state": "ACTIVE",
      "vnAgnostic": true,
      "identitySource": {
        "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
        "instanceId": 10010,
        "authEntityId": 10010,
        "displayName": "10010",
        "authEntityClass": 1738953278,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "state": "INACTIVE",
        "type": "NBAR"
      },
      "indicativeNetworkIdentity": [
        {
          "id": "8e7cd6b7-d693-4a88-9e50-39604b546de1",
          "instanceId": 13379,
          "authEntityId": 13379,
          "displayName": "13379",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "9100",
          "protocol": "UDP",
          "upperPort": 0
        },
        {
          "id": "786afdb7-64db-4a78-92de-f23b318185d3",
          "instanceId": 13380,
          "authEntityId": 13380,
          "displayName": "13380",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "9100",
          "protocol": "TCP",
          "upperPort": 0
        }
      ],
      "networkApplications": [
        {
          "id": "7bb1afc8-b695-4ae6-a677-f6092b54f0ed",
          "instanceId": 11506,
          "authEntityId": 11506,
          "displayName": "11506",
          "authEntityClass": -217092956,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "appProtocol": "tcp/udp",
          "applicationGroup": "other",
          "applicationType": "DEFAULT",
          "categoryId": "7e19fdea-6222-4c57-9e66-66c2340d6bc3",
          "createTime": 1517966742979,
          "encrypted": "false",
          "engineId": "3",
          "helpString": "Describes the appearance of a printed page",
          "ignoreConflict": false,
          "lastUpdateTime": 1517966742979,
          "longDescription": "Page description language (PDL) is a language that describes the appearance of a printed page in a higher level than an actual output bitmap.",
          "name": "hp-pdl-datastr",
          "nbarId": "1384",
          "p2pTechnology": "false",
          "popularity": 8,
          "rank": 65535,
          "references": "http://www.pclviewer.com/resources/reference/",
          "selectorId": "9100",
          "subCategory": "b90786f4-0190-45b8-9924-21b3e9682590",
          "trafficClass": "BULK_DATA",
          "tunnel": "false"
        }
      ],
      "networkIdentity": [
        {
          "id": "820309ab-d21e-490f-b9a8-e2d6c1aceb9a",
          "instanceId": 13377,
          "authEntityId": 13377,
          "displayName": "13377",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "9100",
          "protocol": "UDP",
          "upperPort": 0
        },
        {
          "id": "bc4adb85-3317-4e35-9277-b56f6db74230",
          "instanceId": 13378,
          "authEntityId": 13378,
          "displayName": "13378",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "9100",
          "protocol": "TCP",
          "upperPort": 0
        }
      ],
      "parentScalableGroup": {
        "idRef": "60f1364d-2a02-4063-8ba1-07817abe0612"
      },
      "user": [

      ],
      "userGroup": [

      ]
    },
    {
      "id": "e1f7d518-c4e0-4d9f-a864-a03c27b9378d",
      "instanceId": 15038,
      "authEntityId": 15038,
      "displayName": "15038",
      "authEntityClass": -1909073334,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "createTime": 1517966744085,
      "deployed": false,
      "isSeeded": true,
      "isStale": false,
      "lastUpdateTime": 1517966744085,
      "name": "rediff-com",
      "namespace": "scalablegroup:application",
      "provisioningState": "DEFINED",
      "qualifier": "application",
      "resourceVersion": 0,
      "targetIdList": [

      ],
      "type": "scalablegroup",
      "cfsChangeInfo": [

      ],
      "customProvisions": [

      ],
      "scalableGroupExternalHandle": "rediff-com",
      "scalableGroupType": "APPLICATION",
      "securityGroupTag": 0,
      "state": "ACTIVE",
      "vnAgnostic": true,
      "identitySource": {
        "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
        "instanceId": 10010,
        "authEntityId": 10010,
        "displayName": "10010",
        "authEntityClass": 1738953278,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "state": "INACTIVE",
        "type": "NBAR"
      },
      "indicativeNetworkIdentity": [

      ],
      "networkApplications": [
        {
          "id": "a1eb69da-667c-4e5f-b009-7f824e663aef",
          "instanceId": 11977,
          "authEntityId": 11977,
          "displayName": "11977",
          "authEntityClass": -217092956,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "appProtocol": "tcp/udp",
          "applicationGroup": "other",
          "applicationType": "DEFAULT",
          "categoryId": "878644b5-fe91-45b4-9204-31c84a73ba48",
          "createTime": 1517966744085,
          "encrypted": "false",
          "engineId": "13",
          "helpString": "Rediff.com - Online news, information and web portal.",
          "ignoreConflict": false,
          "lastUpdateTime": 1517966744085,
          "longDescription": "Rediff.com - Online news, information and web portal.",
          "name": "rediff-com",
          "nbarId": "1601",
          "p2pTechnology": "false",
          "popularity": 3,
          "rank": 65535,
          "references": "http://rediff.com",
          "selectorId": "671",
          "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
          "trafficClass": "TRANSACTIONAL_DATA",
          "tunnel": "false"
        }
      ],
      "networkIdentity": [
        {
          "id": "b9874130-0001-4704-825b-0ca3be35edce",
          "instanceId": 14741,
          "authEntityId": 14741,
          "displayName": "14741",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "53,80,443,5353",
          "protocol": "TCP",
          "upperPort": 0
        },
        {
          "id": "0e722489-fb56-44f5-960f-33f957b235e6",
          "instanceId": 14742,
          "authEntityId": 14742,
          "displayName": "14742",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "53,5353",
          "protocol": "UDP",
          "upperPort": 0
        }
      ],
      "parentScalableGroup": {
        "idRef": "28e08de4-d7b2-4521-84ce-bc1e5ee0b9cc"
      },
      "user": [

      ],
      "userGroup": [

      ]
    },
    {
      "id": "e20369a7-6514-4a10-a276-450398e76521",
      "instanceId": 4083,
      "authEntityId": 4083,
      "displayName": "4083",
      "authEntityClass": -1909073334,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "createTime": 1517966740945,
      "deployed": false,
      "isSeeded": true,
      "isStale": false,
      "lastUpdateTime": 1517966740945,
      "name": "an",
      "namespace": "scalablegroup:application",
      "provisioningState": "DEFINED",
      "qualifier": "application",
      "resourceVersion": 0,
      "targetIdList": [

      ],
      "type": "scalablegroup",
      "cfsChangeInfo": [

      ],
      "customProvisions": [

      ],
      "scalableGroupExternalHandle": "an",
      "scalableGroupType": "APPLICATION",
      "securityGroupTag": 0,
      "state": "ACTIVE",
      "vnAgnostic": true,
      "identitySource": {
        "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
        "instanceId": 10010,
        "authEntityId": 10010,
        "displayName": "10010",
        "authEntityClass": 1738953278,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "state": "INACTIVE",
        "type": "NBAR"
      },
      "indicativeNetworkIdentity": [
        {
          "id": "eec8156a-12bd-4eb7-80e8-30621e660d5f",
          "instanceId": 12123,
          "authEntityId": 12123,
          "displayName": "12123",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipProtocolNumbers": "107",
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "protocol": "AN",
          "upperPort": 0
        }
      ],
      "networkApplications": [
        {
          "id": "5c45b407-a7f2-42b1-a3d2-f8f45c8772c2",
          "instanceId": 11049,
          "authEntityId": 11049,
          "displayName": "11049",
          "authEntityClass": -217092956,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "appProtocol": "ip",
          "applicationGroup": "other",
          "applicationType": "DEFAULT",
          "categoryId": "6daa3dfd-3526-407a-88f0-97824b3f3a24",
          "createTime": 1517966740944,
          "encrypted": "unassigned",
          "engineId": "1",
          "helpString": "Active Networks",
          "ignoreConflict": false,
          "lastUpdateTime": 1517966740944,
          "longDescription": "Active Networks is a networking technology used to enable unique processing of each network packet. This is accomplished by sending the proper processing code along with the payload of each packet, or in a separate control flow. This code can then be executed by certain nodes, called Active Nodes, inside the network.",
          "name": "an",
          "nbarId": "861",
          "p2pTechnology": "unassigned",
          "popularity": 1,
          "rank": 65535,
          "references": "http://www.isi.edu/active-signal/ARP/DOCUMENTS/DANCE.ARP.FINAL.pdf",
          "selectorId": "107",
          "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
          "trafficClass": "BULK_DATA",
          "tunnel": "unassigned"
        }
      ],
      "networkIdentity": [
        {
          "id": "0d4e9045-6387-42d0-820c-a65d68930b83",
          "instanceId": 12122,
          "authEntityId": 12122,
          "displayName": "12122",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipProtocolNumbers": "107",
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "protocol": "AN",
          "upperPort": 0
        }
      ],
      "parentScalableGroup": {
        "idRef": "000c15bf-28fe-4fbb-9b69-35e6502721f1"
      },
      "user": [

      ],
      "userGroup": [

      ]
    },
    {
      "id": "e21d7cf9-8f47-4812-b05d-9418aa329e3a",
      "instanceId": 15100,
      "authEntityId": 15100,
      "displayName": "15100",
      "authEntityClass": -1909073334,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "createTime": 1517966744234,
      "deployed": false,
      "isSeeded": true,
      "isStale": false,
      "lastUpdateTime": 1517966744234,
      "name": "sco-websrvrmgr",
      "namespace": "scalablegroup:application",
      "provisioningState": "DEFINED",
      "qualifier": "application",
      "resourceVersion": 0,
      "targetIdList": [

      ],
      "type": "scalablegroup",
      "cfsChangeInfo": [

      ],
      "customProvisions": [

      ],
      "scalableGroupExternalHandle": "sco-websrvrmgr",
      "scalableGroupType": "APPLICATION",
      "securityGroupTag": 0,
      "state": "ACTIVE",
      "vnAgnostic": true,
      "identitySource": {
        "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
        "instanceId": 10010,
        "authEntityId": 10010,
        "displayName": "10010",
        "authEntityClass": 1738953278,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "state": "INACTIVE",
        "type": "NBAR"
      },
      "indicativeNetworkIdentity": [
        {
          "id": "ee5cdc2f-7649-4857-980f-eec0cefb3090",
          "instanceId": 14938,
          "authEntityId": 14938,
          "displayName": "14938",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "620",
          "protocol": "UDP",
          "upperPort": 0
        },
        {
          "id": "fa80365b-3dbc-4e5e-b4c4-4ae1db507bff",
          "instanceId": 14939,
          "authEntityId": 14939,
          "displayName": "14939",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "620",
          "protocol": "TCP",
          "upperPort": 0
        }
      ],
      "networkApplications": [
        {
          "id": "2c598f59-f359-4870-92e5-70e3ad827471",
          "instanceId": 16043,
          "authEntityId": 16043,
          "displayName": "16043",
          "authEntityClass": -217092956,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "appProtocol": "tcp/udp",
          "applicationGroup": "other",
          "applicationType": "DEFAULT",
          "categoryId": "1fc17362-9334-4c7b-b752-5afdbb574017",
          "createTime": 1517966744234,
          "encrypted": "false",
          "engineId": "3",
          "helpString": "SCO Web Server Manager",
          "ignoreConflict": false,
          "lastUpdateTime": 1517966744234,
          "longDescription": "Registered with IANA on port 620 TCP/UDP",
          "name": "sco-websrvrmgr",
          "nbarId": "529",
          "p2pTechnology": "false",
          "popularity": 1,
          "rank": 65535,
          "references": "http://www.iana.org/assignments/service-names-port-numbers/service-names-port-numbers.xml",
          "selectorId": "620",
          "subCategory": "f3c0f761-d894-4112-ab89-b90fc84fd660",
          "trafficClass": "OPS_ADMIN_MGMT",
          "tunnel": "false"
        }
      ],
      "networkIdentity": [
        {
          "id": "c98ff4b9-8848-491e-9930-b4b023e97d8f",
          "instanceId": 14936,
          "authEntityId": 14936,
          "displayName": "14936",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "620",
          "protocol": "UDP",
          "upperPort": 0
        },
        {
          "id": "59a1d80f-7415-4847-933c-9831095210ab",
          "instanceId": 14937,
          "authEntityId": 14937,
          "displayName": "14937",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "620",
          "protocol": "TCP",
          "upperPort": 0
        }
      ],
      "parentScalableGroup": {
        "idRef": "5fcd74d4-8726-47f7-a088-c457863da7ef"
      },
      "user": [

      ],
      "userGroup": [

      ]
    },
    {
      "id": "e23be2be-e971-4290-9bb8-cebf9f2060c9",
      "instanceId": 15333,
      "authEntityId": 15333,
      "displayName": "15333",
      "authEntityClass": -1909073334,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "createTime": 1517966744756,
      "deployed": false,
      "isSeeded": true,
      "isStale": false,
      "lastUpdateTime": 1517966744756,
      "name": "uti",
      "namespace": "scalablegroup:application",
      "provisioningState": "DEFINED",
      "qualifier": "application",
      "resourceVersion": 0,
      "targetIdList": [

      ],
      "type": "scalablegroup",
      "cfsChangeInfo": [

      ],
      "customProvisions": [

      ],
      "scalableGroupExternalHandle": "uti",
      "scalableGroupType": "APPLICATION",
      "securityGroupTag": 0,
      "state": "ACTIVE",
      "vnAgnostic": true,
      "identitySource": {
        "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
        "instanceId": 10010,
        "authEntityId": 10010,
        "displayName": "10010",
        "authEntityClass": 1738953278,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "state": "INACTIVE",
        "type": "NBAR"
      },
      "indicativeNetworkIdentity": [
        {
          "id": "a8c7b474-175f-44a2-ac4e-bd6bcd33331b",
          "instanceId": 17614,
          "authEntityId": 17614,
          "displayName": "17614",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipProtocolNumbers": "120",
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "protocol": "UTI",
          "upperPort": 0
        }
      ],
      "networkApplications": [
        {
          "id": "3615a981-aafb-40bf-b413-2275332e9578",
          "instanceId": 16276,
          "authEntityId": 16276,
          "displayName": "16276",
          "authEntityClass": -217092956,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "appProtocol": "ip",
          "applicationGroup": "other",
          "applicationType": "DEFAULT",
          "categoryId": "6daa3dfd-3526-407a-88f0-97824b3f3a24",
          "createTime": 1517966744756,
          "encrypted": "unassigned",
          "engineId": "1",
          "helpString": "UTI",
          "ignoreConflict": false,
          "lastUpdateTime": 1517966744756,
          "longDescription": "Registered with IANA as IP Protocol 120",
          "name": "uti",
          "nbarId": "874",
          "p2pTechnology": "unassigned",
          "popularity": 1,
          "rank": 65535,
          "references": "http://www.iana.org/assignments/protocol-numbers/protocol-numbers.xml",
          "selectorId": "120",
          "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
          "trafficClass": "BULK_DATA",
          "tunnel": "unassigned"
        }
      ],
      "networkIdentity": [
        {
          "id": "1eedf5ea-faec-49f6-80b5-d29707b60caa",
          "instanceId": 17613,
          "authEntityId": 17613,
          "displayName": "17613",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipProtocolNumbers": "120",
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "protocol": "UTI",
          "upperPort": 0
        }
      ],
      "parentScalableGroup": {
        "idRef": "000c15bf-28fe-4fbb-9b69-35e6502721f1"
      },
      "user": [

      ],
      "userGroup": [

      ]
    },
    {
      "id": "e25f155b-db87-4137-983d-391b8389f6eb",
      "instanceId": 4649,
      "authEntityId": 4649,
      "displayName": "4649",
      "authEntityClass": -1909073334,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "createTime": 1517966743204,
      "deployed": false,
      "isSeeded": true,
      "isStale": false,
      "lastUpdateTime": 1517966743204,
      "name": "iso-tp0",
      "namespace": "scalablegroup:application",
      "provisioningState": "DEFINED",
      "qualifier": "application",
      "resourceVersion": 0,
      "targetIdList": [

      ],
      "type": "scalablegroup",
      "cfsChangeInfo": [

      ],
      "customProvisions": [

      ],
      "scalableGroupExternalHandle": "iso-tp0",
      "scalableGroupType": "APPLICATION",
      "securityGroupTag": 0,
      "state": "ACTIVE",
      "vnAgnostic": true,
      "identitySource": {
        "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
        "instanceId": 10010,
        "authEntityId": 10010,
        "displayName": "10010",
        "authEntityClass": 1738953278,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "state": "INACTIVE",
        "type": "NBAR"
      },
      "indicativeNetworkIdentity": [
        {
          "id": "4170d376-cd55-4512-905e-600ecd65f26e",
          "instanceId": 13633,
          "authEntityId": 13633,
          "displayName": "13633",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "146",
          "protocol": "TCP",
          "upperPort": 0
        },
        {
          "id": "7e247d06-cd0b-45bf-b31f-657e7acebb09",
          "instanceId": 13634,
          "authEntityId": 13634,
          "displayName": "13634",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "146",
          "protocol": "UDP",
          "upperPort": 0
        }
      ],
      "networkApplications": [
        {
          "id": "7f5bb8e9-9674-4c50-a82c-d4edd1637ac0",
          "instanceId": 11599,
          "authEntityId": 11599,
          "displayName": "11599",
          "authEntityClass": -217092956,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "appProtocol": "tcp/udp",
          "applicationGroup": "other",
          "applicationType": "DEFAULT",
          "categoryId": "6daa3dfd-3526-407a-88f0-97824b3f3a24",
          "createTime": 1517966743204,
          "encrypted": "false",
          "engineId": "3",
          "helpString": "iso-tp0",
          "ignoreConflict": false,
          "lastUpdateTime": 1517966743204,
          "longDescription": "A protocol that is used to bridge ISO TP0 packets between X.25 and TCP networks.  This technique is useful when interconnecting a DDN IP internet to an X.25 subnetwork.",
          "name": "iso-tp0",
          "nbarId": "947",
          "p2pTechnology": "false",
          "popularity": 1,
          "rank": 65535,
          "references": "http://tools.ietf.org/html/rfc1086",
          "selectorId": "146",
          "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
          "trafficClass": "BULK_DATA",
          "tunnel": "false"
        }
      ],
      "networkIdentity": [
        {
          "id": "36e11e6b-4574-41ca-95d1-3047336fcdf8",
          "instanceId": 13632,
          "authEntityId": 13632,
          "displayName": "13632",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "146",
          "protocol": "TCP",
          "upperPort": 0
        },
        {
          "id": "43b0e0b9-266a-452a-997b-4fe2154ac1fe",
          "instanceId": 13631,
          "authEntityId": 13631,
          "displayName": "13631",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "146",
          "protocol": "UDP",
          "upperPort": 0
        }
      ],
      "parentScalableGroup": {
        "idRef": "000c15bf-28fe-4fbb-9b69-35e6502721f1"
      },
      "user": [

      ],
      "userGroup": [

      ]
    },
    {
      "id": "e295d834-732b-4c20-8377-9ed63233af72",
      "instanceId": 15065,
      "authEntityId": 15065,
      "displayName": "15065",
      "authEntityClass": -1909073334,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "createTime": 1517966744152,
      "deployed": false,
      "isSeeded": true,
      "isStale": false,
      "lastUpdateTime": 1517966744152,
      "name": "rsvp",
      "namespace": "scalablegroup:application",
      "provisioningState": "DEFINED",
      "qualifier": "application",
      "resourceVersion": 0,
      "targetIdList": [

      ],
      "type": "scalablegroup",
      "cfsChangeInfo": [

      ],
      "customProvisions": [

      ],
      "scalableGroupExternalHandle": "rsvp",
      "scalableGroupType": "APPLICATION",
      "securityGroupTag": 0,
      "state": "ACTIVE",
      "vnAgnostic": true,
      "identitySource": {
        "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
        "instanceId": 10010,
        "authEntityId": 10010,
        "displayName": "10010",
        "authEntityClass": 1738953278,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "state": "INACTIVE",
        "type": "NBAR"
      },
      "indicativeNetworkIdentity": [
        {
          "id": "23b2323f-499a-4f38-acb5-4fc260edfedc",
          "instanceId": 14838,
          "authEntityId": 14838,
          "displayName": "14838",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipProtocolNumbers": "46",
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "protocol": "RSVP",
          "upperPort": 0
        }
      ],
      "networkApplications": [
        {
          "id": "55df9763-e8eb-43e2-afbd-ec55c96c149a",
          "instanceId": 12004,
          "authEntityId": 12004,
          "displayName": "12004",
          "authEntityClass": -217092956,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "appProtocol": "ip",
          "applicationGroup": "other",
          "applicationType": "DEFAULT",
          "categoryId": "1fc17362-9334-4c7b-b752-5afdbb574017",
          "createTime": 1517966744152,
          "encrypted": "false",
          "engineId": "1",
          "helpString": "Resource Reservation Protocol",
          "ignoreConflict": false,
          "lastUpdateTime": 1517966744152,
          "longDescription": "Resource Reservation Protocol (RSVP) is a Transport Layer protocol designed to reserve resources across a network for an integrated services internet. RSVP operates over an IPv4 or IPv6 internet Layer and provides receiver-initiated setup of resource reservations for multicast or unicast data flows with scaling and robustness. It does not transport application data but is similar to a control protocol, like ICMP or IGMP.",
          "name": "rsvp",
          "nbarId": "37",
          "p2pTechnology": "false",
          "popularity": 5,
          "rank": 65535,
          "references": "http://www.ietf.org/rfc/rfc2205.txt",
          "selectorId": "46",
          "subCategory": "f3c0f761-d894-4112-ab89-b90fc84fd660",
          "trafficClass": "OPS_ADMIN_MGMT",
          "tunnel": "false"
        }
      ],
      "networkIdentity": [
        {
          "id": "10f4e3ec-bf93-41c1-9aad-7b900f37b5e8",
          "instanceId": 14837,
          "authEntityId": 14837,
          "displayName": "14837",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipProtocolNumbers": "46",
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "protocol": "RSVP",
          "upperPort": 0
        }
      ],
      "parentScalableGroup": {
        "idRef": "5fcd74d4-8726-47f7-a088-c457863da7ef"
      },
      "user": [

      ],
      "userGroup": [

      ]
    },
    {
      "id": "e2f00dbd-0cf0-46ce-895d-9c1b357d5e8d",
      "instanceId": 4552,
      "authEntityId": 4552,
      "displayName": "4552",
      "authEntityClass": -1909073334,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "createTime": 1517966742970,
      "deployed": false,
      "isSeeded": true,
      "isStale": false,
      "lastUpdateTime": 1517966742970,
      "name": "hp-alarm-mgr",
      "namespace": "scalablegroup:application",
      "provisioningState": "DEFINED",
      "qualifier": "application",
      "resourceVersion": 0,
      "targetIdList": [

      ],
      "type": "scalablegroup",
      "cfsChangeInfo": [

      ],
      "customProvisions": [

      ],
      "scalableGroupExternalHandle": "hp-alarm-mgr",
      "scalableGroupType": "APPLICATION",
      "securityGroupTag": 0,
      "state": "ACTIVE",
      "vnAgnostic": true,
      "identitySource": {
        "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
        "instanceId": 10010,
        "authEntityId": 10010,
        "displayName": "10010",
        "authEntityClass": 1738953278,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "state": "INACTIVE",
        "type": "NBAR"
      },
      "indicativeNetworkIdentity": [
        {
          "id": "19670e7f-51fa-4764-bb75-55a40a22e449",
          "instanceId": 13367,
          "authEntityId": 13367,
          "displayName": "13367",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "383",
          "protocol": "UDP",
          "upperPort": 0
        },
        {
          "id": "6cd208e6-689f-4808-9c22-c9336dbda731",
          "instanceId": 13368,
          "authEntityId": 13368,
          "displayName": "13368",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "383",
          "protocol": "TCP",
          "upperPort": 0
        }
      ],
      "networkApplications": [
        {
          "id": "f5cb29b6-4c39-449c-8fca-814c28da4e88",
          "instanceId": 11503,
          "authEntityId": 11503,
          "displayName": "11503",
          "authEntityClass": -217092956,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "appProtocol": "tcp/udp",
          "applicationGroup": "other",
          "applicationType": "DEFAULT",
          "categoryId": "1fc17362-9334-4c7b-b752-5afdbb574017",
          "createTime": 1517966742970,
          "encrypted": "false",
          "engineId": "3",
          "helpString": "hp performance data alarm manager",
          "ignoreConflict": false,
          "lastUpdateTime": 1517966742970,
          "longDescription": "Used by HP OpenView product family that consists of network and systems management products. In 2007, HP OpenView was rebranded as HP Network Management Center.",
          "name": "hp-alarm-mgr",
          "nbarId": "299",
          "p2pTechnology": "false",
          "popularity": 1,
          "rank": 65535,
          "references": "http://en.wikipedia.org/wiki/HP_OpenView",
          "selectorId": "383",
          "subCategory": "f3c0f761-d894-4112-ab89-b90fc84fd660",
          "trafficClass": "OPS_ADMIN_MGMT",
          "tunnel": "false"
        }
      ],
      "networkIdentity": [
        {
          "id": "2a9befe5-8019-42ac-a528-5ebcb09f7d7e",
          "instanceId": 13365,
          "authEntityId": 13365,
          "displayName": "13365",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "383",
          "protocol": "UDP",
          "upperPort": 0
        },
        {
          "id": "a48ae8b3-7a03-4abe-ab04-5d36ae080634",
          "instanceId": 13366,
          "authEntityId": 13366,
          "displayName": "13366",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "383",
          "protocol": "TCP",
          "upperPort": 0
        }
      ],
      "parentScalableGroup": {
        "idRef": "5fcd74d4-8726-47f7-a088-c457863da7ef"
      },
      "user": [

      ],
      "userGroup": [

      ]
    },
    {
      "id": "e30e4d71-a01d-4b01-9f79-f2012defc15d",
      "instanceId": 15160,
      "authEntityId": 15160,
      "displayName": "15160",
      "authEntityClass": -1909073334,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "createTime": 1517966744371,
      "deployed": false,
      "isSeeded": true,
      "isStale": false,
      "lastUpdateTime": 1517966744371,
      "name": "sm",
      "namespace": "scalablegroup:application",
      "provisioningState": "DEFINED",
      "qualifier": "application",
      "resourceVersion": 0,
      "targetIdList": [

      ],
      "type": "scalablegroup",
      "cfsChangeInfo": [

      ],
      "customProvisions": [

      ],
      "scalableGroupExternalHandle": "sm",
      "scalableGroupType": "APPLICATION",
      "securityGroupTag": 0,
      "state": "ACTIVE",
      "vnAgnostic": true,
      "identitySource": {
        "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
        "instanceId": 10010,
        "authEntityId": 10010,
        "displayName": "10010",
        "authEntityClass": 1738953278,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "state": "INACTIVE",
        "type": "NBAR"
      },
      "indicativeNetworkIdentity": [
        {
          "id": "93b12114-3dd0-47ff-8a3c-f619786b1528",
          "instanceId": 17124,
          "authEntityId": 17124,
          "displayName": "17124",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipProtocolNumbers": "122",
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "protocol": "SM",
          "upperPort": 0
        }
      ],
      "networkApplications": [
        {
          "id": "a8256288-b389-449a-a976-acf4b2bfba1c",
          "instanceId": 16103,
          "authEntityId": 16103,
          "displayName": "16103",
          "authEntityClass": -217092956,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "appProtocol": "ip",
          "applicationGroup": "other",
          "applicationType": "DEFAULT",
          "categoryId": "6daa3dfd-3526-407a-88f0-97824b3f3a24",
          "createTime": 1517966744371,
          "encrypted": "unassigned",
          "engineId": "1",
          "helpString": "SM",
          "ignoreConflict": false,
          "lastUpdateTime": 1517966744371,
          "longDescription": "Registered with IANA as IP Protocol 122",
          "name": "sm",
          "nbarId": "876",
          "p2pTechnology": "unassigned",
          "popularity": 1,
          "rank": 65535,
          "references": "http://www.iana.org/assignments/protocol-numbers/protocol-numbers.xml",
          "selectorId": "122",
          "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
          "trafficClass": "BULK_DATA",
          "tunnel": "unassigned"
        }
      ],
      "networkIdentity": [
        {
          "id": "4f6a8ba7-a4b4-4291-ac4b-eacf24de5565",
          "instanceId": 17123,
          "authEntityId": 17123,
          "displayName": "17123",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipProtocolNumbers": "122",
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "protocol": "SM",
          "upperPort": 0
        }
      ],
      "parentScalableGroup": {
        "idRef": "000c15bf-28fe-4fbb-9b69-35e6502721f1"
      },
      "user": [

      ],
      "userGroup": [

      ]
    },
    {
      "id": "e316f7ff-c50f-4d38-94be-23870170b121",
      "instanceId": 4114,
      "authEntityId": 4114,
      "displayName": "4114",
      "authEntityClass": -1909073334,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "createTime": 1517966741218,
      "deployed": false,
      "isSeeded": true,
      "isStale": false,
      "lastUpdateTime": 1517966741218,
      "name": "ares",
      "namespace": "scalablegroup:application",
      "provisioningState": "DEFINED",
      "qualifier": "application",
      "resourceVersion": 0,
      "targetIdList": [

      ],
      "type": "scalablegroup",
      "cfsChangeInfo": [

      ],
      "customProvisions": [

      ],
      "scalableGroupExternalHandle": "ares",
      "scalableGroupType": "APPLICATION",
      "securityGroupTag": 0,
      "state": "ACTIVE",
      "vnAgnostic": true,
      "identitySource": {
        "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
        "instanceId": 10010,
        "authEntityId": 10010,
        "displayName": "10010",
        "authEntityClass": 1738953278,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "state": "INACTIVE",
        "type": "NBAR"
      },
      "indicativeNetworkIdentity": [

      ],
      "networkApplications": [
        {
          "id": "f242cbf8-f9de-47a5-b87d-246f2ee1b7c2",
          "instanceId": 11076,
          "authEntityId": 11076,
          "displayName": "11076",
          "authEntityClass": -217092956,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "appProtocol": "tcp/udp",
          "applicationGroup": "other",
          "applicationType": "DEFAULT",
          "categoryId": "b33b2761-d981-4734-810a-a6a1c18ab352",
          "createTime": 1517966741218,
          "encrypted": "false",
          "engineId": "13",
          "helpString": "Ares Protocol, P2P File-Sharing and Networking Protocol",
          "ignoreConflict": false,
          "lastUpdateTime": 1517966741218,
          "longDescription": "Ares is a P2P network which was originally operating on the Gnutella network. After that, it switched to its own network with a leaves-and-super nodes architecture. Ares Galaxy, which is an open source P2P software, is the main client which makes use of Ares network.",
          "name": "ares",
          "nbarId": "1502",
          "p2pTechnology": "true",
          "popularity": 8,
          "rank": 65535,
          "references": "http://aresgalaxy.sourceforge.net/",
          "selectorId": "565",
          "subCategory": "79fe7198-9ce3-4c01-9b58-45a8df3d9513",
          "trafficClass": "BULK_DATA",
          "tunnel": "false"
        }
      ],
      "networkIdentity": [
        {
          "id": "a8379536-ef58-4550-a7ef-6d16f76f9954",
          "instanceId": 12195,
          "authEntityId": 12195,
          "displayName": "12195",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "53,5353",
          "protocol": "UDP",
          "upperPort": 0
        },
        {
          "id": "3bf18e37-74c3-4017-ba56-2064d32408ec",
          "instanceId": 12196,
          "authEntityId": 12196,
          "displayName": "12196",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "53,80,1080,5353",
          "protocol": "TCP",
          "upperPort": 0
        }
      ],
      "parentScalableGroup": {
        "idRef": "7a1a3bbb-dc84-4a35-82a4-7e650c4c4761"
      },
      "user": [

      ],
      "userGroup": [

      ]
    },
    {
      "id": "e31c72fb-64e1-47fb-a97a-dabdc8bbf1f3",
      "instanceId": 4948,
      "authEntityId": 4948,
      "displayName": "4948",
      "authEntityClass": -1909073334,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "createTime": 1517966743879,
      "deployed": false,
      "isSeeded": true,
      "isStale": false,
      "lastUpdateTime": 1517966743879,
      "name": "pearson",
      "namespace": "scalablegroup:application",
      "provisioningState": "DEFINED",
      "qualifier": "application",
      "resourceVersion": 0,
      "targetIdList": [

      ],
      "type": "scalablegroup",
      "cfsChangeInfo": [

      ],
      "customProvisions": [

      ],
      "scalableGroupExternalHandle": "pearson",
      "scalableGroupType": "APPLICATION",
      "securityGroupTag": 0,
      "state": "ACTIVE",
      "vnAgnostic": true,
      "identitySource": {
        "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
        "instanceId": 10010,
        "authEntityId": 10010,
        "displayName": "10010",
        "authEntityClass": 1738953278,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "state": "INACTIVE",
        "type": "NBAR"
      },
      "indicativeNetworkIdentity": [

      ],
      "networkApplications": [
        {
          "id": "b125b601-5aed-4a75-bec3-48c223880bc5",
          "instanceId": 11897,
          "authEntityId": 11897,
          "displayName": "11897",
          "authEntityClass": -217092956,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "appProtocol": "tcp/udp",
          "applicationGroup": "other",
          "applicationType": "DEFAULT",
          "categoryId": "7e19fdea-6222-4c57-9e66-66c2340d6bc3",
          "createTime": 1517966743879,
          "encrypted": "false",
          "engineId": "13",
          "helpString": "Pearson - Education content provider.",
          "ignoreConflict": false,
          "lastUpdateTime": 1517966743879,
          "longDescription": "Pearson - Education content provider.",
          "name": "pearson",
          "nbarId": "1739",
          "p2pTechnology": "false",
          "popularity": 3,
          "rank": 65535,
          "references": "http://www.pearsonschool.com",
          "selectorId": "821",
          "subCategory": "aab99e59-8589-4a61-933e-f01bff556d78",
          "trafficClass": "TRANSACTIONAL_DATA",
          "tunnel": "false"
        }
      ],
      "networkIdentity": [
        {
          "id": "7ad664c9-3571-4183-8749-0ea7c3c130cb",
          "instanceId": 14505,
          "authEntityId": 14505,
          "displayName": "14505",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "53,80,443,5353",
          "protocol": "TCP",
          "upperPort": 0
        },
        {
          "id": "5f7eadc3-3206-402e-9728-40d5b15ef5e2",
          "instanceId": 14506,
          "authEntityId": 14506,
          "displayName": "14506",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "53,5353",
          "protocol": "UDP",
          "upperPort": 0
        }
      ],
      "parentScalableGroup": {
        "idRef": "dc13f401-c34c-4d18-a5a1-6e9f8624d67e"
      },
      "user": [

      ],
      "userGroup": [

      ]
    },
    {
      "id": "e3a41f10-cd30-4306-8cd1-e3c039991dfb",
      "instanceId": 15444,
      "authEntityId": 15444,
      "displayName": "15444",
      "authEntityClass": -1909073334,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "createTime": 1517966744976,
      "deployed": false,
      "isSeeded": true,
      "isStale": false,
      "lastUpdateTime": 1517966744976,
      "name": "xns-idp",
      "namespace": "scalablegroup:application",
      "provisioningState": "DEFINED",
      "qualifier": "application",
      "resourceVersion": 0,
      "targetIdList": [

      ],
      "type": "scalablegroup",
      "cfsChangeInfo": [

      ],
      "customProvisions": [

      ],
      "scalableGroupExternalHandle": "xns-idp",
      "scalableGroupType": "APPLICATION",
      "securityGroupTag": 0,
      "state": "ACTIVE",
      "vnAgnostic": true,
      "identitySource": {
        "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
        "instanceId": 10010,
        "authEntityId": 10010,
        "displayName": "10010",
        "authEntityClass": 1738953278,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "state": "INACTIVE",
        "type": "NBAR"
      },
      "indicativeNetworkIdentity": [
        {
          "id": "64c47e84-9fcc-4f1d-ac22-caf38b3a175b",
          "instanceId": 17918,
          "authEntityId": 17918,
          "displayName": "17918",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipProtocolNumbers": "22",
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "protocol": "XNS_IDP",
          "upperPort": 0
        }
      ],
      "networkApplications": [
        {
          "id": "73acafc4-175a-4811-aafb-766fc195153a",
          "instanceId": 16387,
          "authEntityId": 16387,
          "displayName": "16387",
          "authEntityClass": -217092956,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "appProtocol": "ip",
          "applicationGroup": "xns-xerox-group",
          "applicationType": "DEFAULT",
          "categoryId": "6daa3dfd-3526-407a-88f0-97824b3f3a24",
          "createTime": 1517966744976,
          "encrypted": "unassigned",
          "engineId": "1",
          "helpString": "XEROX NS IDP",
          "ignoreConflict": false,
          "lastUpdateTime": 1517966744976,
          "longDescription": "Internet Datagram Protocol (IDP) is a simple, unreliable datagram protocol, which is used to support the SOCK_DGRAM abstraction for the Internet Protocol (IP) family. IDP sockets are connectionless and normally used with the sendto and recvfrom subroutines. The connect subroutine can also be used to fix the destination for future packets, in which case the recv or read subroutine and the send or write subroutine can be used. Xerox protocols (XNS protocol suite) are built vertically on top of IDP. Thus, IDP address formats are identical to those used by the Sequenced Packet Protocol (SPP). The IDP port space is the same as the SPP port space; that is, an IDP port may be connected to an SPP port, with certain options enabled. In addition, broadcast packets may be sent (assuming the underlying network supports this) by using a reserved broadcast address. This address is network interface-dependent.",
          "name": "xns-idp",
          "nbarId": "776",
          "p2pTechnology": "unassigned",
          "popularity": 2,
          "rank": 65535,
          "references": "http://www.javvin.com/protocolIDP.html",
          "selectorId": "22",
          "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
          "trafficClass": "BULK_DATA",
          "tunnel": "unassigned"
        }
      ],
      "networkIdentity": [
        {
          "id": "6f77ab14-8dc9-4e4b-bbab-3dc79f1e5d0a",
          "instanceId": 17917,
          "authEntityId": 17917,
          "displayName": "17917",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipProtocolNumbers": "22",
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "protocol": "XNS_IDP",
          "upperPort": 0
        }
      ],
      "parentScalableGroup": {
        "idRef": "000c15bf-28fe-4fbb-9b69-35e6502721f1"
      },
      "user": [

      ],
      "userGroup": [

      ]
    },
    {
      "id": "e3ca7878-47ad-46fb-b3f0-8203aa6957b6",
      "instanceId": 4963,
      "authEntityId": 4963,
      "displayName": "4963",
      "authEntityClass": -1909073334,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "createTime": 1517966743919,
      "deployed": false,
      "isSeeded": true,
      "isStale": false,
      "lastUpdateTime": 1517966743919,
      "name": "pip",
      "namespace": "scalablegroup:application",
      "provisioningState": "DEFINED",
      "qualifier": "application",
      "resourceVersion": 0,
      "targetIdList": [

      ],
      "type": "scalablegroup",
      "cfsChangeInfo": [

      ],
      "customProvisions": [

      ],
      "scalableGroupExternalHandle": "pip",
      "scalableGroupType": "APPLICATION",
      "securityGroupTag": 0,
      "state": "ACTIVE",
      "vnAgnostic": true,
      "identitySource": {
        "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
        "instanceId": 10010,
        "authEntityId": 10010,
        "displayName": "10010",
        "authEntityClass": 1738953278,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "state": "INACTIVE",
        "type": "NBAR"
      },
      "indicativeNetworkIdentity": [
        {
          "id": "8b06474b-ec23-49df-8c4e-2edf32f9cc81",
          "instanceId": 14546,
          "authEntityId": 14546,
          "displayName": "14546",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "1321",
          "protocol": "UDP",
          "upperPort": 0
        },
        {
          "id": "fc065bcf-2e68-4391-80a3-f6dfa0457c82",
          "instanceId": 14547,
          "authEntityId": 14547,
          "displayName": "14547",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "1321",
          "protocol": "TCP",
          "upperPort": 0
        }
      ],
      "networkApplications": [
        {
          "id": "12c80667-a4c8-43a2-91d2-c060e4de9283",
          "instanceId": 11912,
          "authEntityId": 11912,
          "displayName": "11912",
          "authEntityClass": -217092956,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "appProtocol": "tcp/udp",
          "applicationGroup": "other",
          "applicationType": "DEFAULT",
          "categoryId": "6daa3dfd-3526-407a-88f0-97824b3f3a24",
          "createTime": 1517966743919,
          "encrypted": "false",
          "engineId": "3",
          "helpString": "pip",
          "ignoreConflict": false,
          "lastUpdateTime": 1517966743919,
          "longDescription": "Registered with IANA on port 1321 TCP/UDP",
          "name": "pip",
          "nbarId": "883",
          "p2pTechnology": "false",
          "popularity": 1,
          "rank": 65535,
          "references": "http://www.iana.org/assignments/service-names-port-numbers/service-names-port-numbers.xml",
          "selectorId": "1321",
          "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
          "trafficClass": "BULK_DATA",
          "tunnel": "false"
        }
      ],
      "networkIdentity": [
        {
          "id": "2ca546e7-d510-4dd6-a2cd-5452080e1af7",
          "instanceId": 14544,
          "authEntityId": 14544,
          "displayName": "14544",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "1321",
          "protocol": "TCP",
          "upperPort": 0
        },
        {
          "id": "f96c5298-edd7-4b23-a0e3-7a8dab71e801",
          "instanceId": 14545,
          "authEntityId": 14545,
          "displayName": "14545",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "1321",
          "protocol": "UDP",
          "upperPort": 0
        }
      ],
      "parentScalableGroup": {
        "idRef": "000c15bf-28fe-4fbb-9b69-35e6502721f1"
      },
      "user": [

      ],
      "userGroup": [

      ]
    },
    {
      "id": "e3d6cf1f-054b-498d-8c02-6f020f806e2c",
      "instanceId": 4538,
      "authEntityId": 4538,
      "displayName": "4538",
      "authEntityClass": -1909073334,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "createTime": 1517966742931,
      "deployed": false,
      "isSeeded": true,
      "isStale": false,
      "lastUpdateTime": 1517966742931,
      "name": "heroix-longitude",
      "namespace": "scalablegroup:application",
      "provisioningState": "DEFINED",
      "qualifier": "application",
      "resourceVersion": 0,
      "targetIdList": [

      ],
      "type": "scalablegroup",
      "cfsChangeInfo": [

      ],
      "customProvisions": [

      ],
      "scalableGroupExternalHandle": "heroix-longitude",
      "scalableGroupType": "APPLICATION",
      "securityGroupTag": 0,
      "state": "ACTIVE",
      "vnAgnostic": true,
      "identitySource": {
        "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
        "instanceId": 10010,
        "authEntityId": 10010,
        "displayName": "10010",
        "authEntityClass": 1738953278,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "state": "INACTIVE",
        "type": "NBAR"
      },
      "indicativeNetworkIdentity": [
        {
          "id": "bc6c14f6-8b6d-4d96-8517-9ce28fb4ce1b",
          "instanceId": 13338,
          "authEntityId": 13338,
          "displayName": "13338",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "7220,7223",
          "protocol": "TCP",
          "upperPort": 0
        }
      ],
      "networkApplications": [
        {
          "id": "42f1264b-26eb-4ac7-9971-791bad541c3d",
          "instanceId": 11489,
          "authEntityId": 11489,
          "displayName": "11489",
          "authEntityClass": -217092956,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "appProtocol": "tcp",
          "applicationGroup": "other",
          "applicationType": "DEFAULT",
          "categoryId": "6daa3dfd-3526-407a-88f0-97824b3f3a24",
          "createTime": 1517966742931,
          "encrypted": "false",
          "engineId": "3",
          "helpString": "Network performance monitoring software",
          "ignoreConflict": false,
          "lastUpdateTime": 1517966742931,
          "longDescription": "Heroix Longitude is a self-service applications and networking performance monitoring solution. It delivers immediate, comprehensive performance information to solve multiple monitoring challenges.",
          "name": "heroix-longitude",
          "nbarId": "1383",
          "p2pTechnology": "false",
          "popularity": 1,
          "rank": 65535,
          "references": "http://www.heroix.com/longitude_overview.html",
          "selectorId": "7220",
          "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
          "trafficClass": "BULK_DATA",
          "tunnel": "false"
        }
      ],
      "networkIdentity": [
        {
          "id": "dfb2c294-f98f-402a-9e13-1c6620fd2396",
          "instanceId": 13337,
          "authEntityId": 13337,
          "displayName": "13337",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "7220,7223",
          "protocol": "TCP",
          "upperPort": 0
        }
      ],
      "parentScalableGroup": {
        "idRef": "000c15bf-28fe-4fbb-9b69-35e6502721f1"
      },
      "user": [

      ],
      "userGroup": [

      ]
    },
    {
      "id": "e3df851a-92dd-45a9-85eb-e09b46c60609",
      "instanceId": 15053,
      "authEntityId": 15053,
      "displayName": "15053",
      "authEntityClass": -1909073334,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "createTime": 1517966744124,
      "deployed": false,
      "isSeeded": true,
      "isStale": false,
      "lastUpdateTime": 1517966744124,
      "name": "rlzdbase",
      "namespace": "scalablegroup:application",
      "provisioningState": "DEFINED",
      "qualifier": "application",
      "resourceVersion": 0,
      "targetIdList": [

      ],
      "type": "scalablegroup",
      "cfsChangeInfo": [

      ],
      "customProvisions": [

      ],
      "scalableGroupExternalHandle": "rlzdbase",
      "scalableGroupType": "APPLICATION",
      "securityGroupTag": 0,
      "state": "ACTIVE",
      "vnAgnostic": true,
      "identitySource": {
        "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
        "instanceId": 10010,
        "authEntityId": 10010,
        "displayName": "10010",
        "authEntityClass": 1738953278,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "state": "INACTIVE",
        "type": "NBAR"
      },
      "indicativeNetworkIdentity": [
        {
          "id": "a1686855-4ce3-4613-83ce-804505792594",
          "instanceId": 14795,
          "authEntityId": 14795,
          "displayName": "14795",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "635",
          "protocol": "UDP",
          "upperPort": 0
        },
        {
          "id": "c1eaa926-248a-4ef3-80ff-e3d78904f9ac",
          "instanceId": 14796,
          "authEntityId": 14796,
          "displayName": "14796",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "635",
          "protocol": "TCP",
          "upperPort": 0
        }
      ],
      "networkApplications": [
        {
          "id": "057b261e-a049-43d3-9b7a-7e851ce65591",
          "instanceId": 11992,
          "authEntityId": 11992,
          "displayName": "11992",
          "authEntityClass": -217092956,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "appProtocol": "tcp/udp",
          "applicationGroup": "other",
          "applicationType": "DEFAULT",
          "categoryId": "6daa3dfd-3526-407a-88f0-97824b3f3a24",
          "createTime": 1517966744124,
          "encrypted": "false",
          "engineId": "3",
          "helpString": "rlzdbase",
          "ignoreConflict": false,
          "lastUpdateTime": 1517966744124,
          "longDescription": "Registered with IANA on port 635 TCP/UDP",
          "name": "rlzdbase",
          "nbarId": "544",
          "p2pTechnology": "false",
          "popularity": 1,
          "rank": 65535,
          "references": "http://www.iana.org/assignments/service-names-port-numbers/service-names-port-numbers.xml",
          "selectorId": "635",
          "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
          "trafficClass": "BULK_DATA",
          "tunnel": "false"
        }
      ],
      "networkIdentity": [
        {
          "id": "fd31890d-8646-4908-92be-28a7d70431d4",
          "instanceId": 14793,
          "authEntityId": 14793,
          "displayName": "14793",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "635",
          "protocol": "UDP",
          "upperPort": 0
        },
        {
          "id": "cdc9589c-9a24-4978-a3b5-9fe5da5868c0",
          "instanceId": 14794,
          "authEntityId": 14794,
          "displayName": "14794",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "635",
          "protocol": "TCP",
          "upperPort": 0
        }
      ],
      "parentScalableGroup": {
        "idRef": "000c15bf-28fe-4fbb-9b69-35e6502721f1"
      },
      "user": [

      ],
      "userGroup": [

      ]
    },
    {
      "id": "e3ffbdd1-6467-4034-a34a-41a43145c55d",
      "instanceId": 4887,
      "authEntityId": 4887,
      "displayName": "4887",
      "authEntityClass": -1909073334,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "createTime": 1517966743685,
      "deployed": false,
      "isSeeded": true,
      "isStale": false,
      "lastUpdateTime": 1517966743685,
      "name": "nsw-fe",
      "namespace": "scalablegroup:application",
      "provisioningState": "DEFINED",
      "qualifier": "application",
      "resourceVersion": 0,
      "targetIdList": [

      ],
      "type": "scalablegroup",
      "cfsChangeInfo": [

      ],
      "customProvisions": [

      ],
      "scalableGroupExternalHandle": "nsw-fe",
      "scalableGroupType": "APPLICATION",
      "securityGroupTag": 0,
      "state": "ACTIVE",
      "vnAgnostic": true,
      "identitySource": {
        "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
        "instanceId": 10010,
        "authEntityId": 10010,
        "displayName": "10010",
        "authEntityClass": 1738953278,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "state": "INACTIVE",
        "type": "NBAR"
      },
      "indicativeNetworkIdentity": [
        {
          "id": "99c23912-7301-44f9-9f3d-4ee517529766",
          "instanceId": 14312,
          "authEntityId": 14312,
          "displayName": "14312",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "27",
          "protocol": "UDP",
          "upperPort": 0
        },
        {
          "id": "675322ba-1772-424d-bac6-3689a3b07770",
          "instanceId": 14313,
          "authEntityId": 14313,
          "displayName": "14313",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "27",
          "protocol": "TCP",
          "upperPort": 0
        }
      ],
      "networkApplications": [
        {
          "id": "12309215-6bef-4d48-a731-7af2e824ecee",
          "instanceId": 11836,
          "authEntityId": 11836,
          "displayName": "11836",
          "authEntityClass": -217092956,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "appProtocol": "tcp/udp",
          "applicationGroup": "other",
          "applicationType": "DEFAULT",
          "categoryId": "6daa3dfd-3526-407a-88f0-97824b3f3a24",
          "createTime": 1517966743685,
          "encrypted": "false",
          "engineId": "3",
          "helpString": "NSW User System FE",
          "ignoreConflict": false,
          "lastUpdateTime": 1517966743685,
          "longDescription": "Registered with IANA on port 27 TCP/UDP",
          "name": "nsw-fe",
          "nbarId": "912",
          "p2pTechnology": "false",
          "popularity": 1,
          "rank": 65535,
          "references": "http://www.iana.org/assignments/service-names-port-numbers/service-names-port-numbers.xml",
          "selectorId": "27",
          "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
          "trafficClass": "BULK_DATA",
          "tunnel": "false"
        }
      ],
      "networkIdentity": [
        {
          "id": "dab7b03b-6bdf-40d9-a096-342e4738d968",
          "instanceId": 14310,
          "authEntityId": 14310,
          "displayName": "14310",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "27",
          "protocol": "UDP",
          "upperPort": 0
        },
        {
          "id": "7f0a62b9-b928-4e7e-b7aa-61d696d5ab41",
          "instanceId": 14311,
          "authEntityId": 14311,
          "displayName": "14311",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "27",
          "protocol": "TCP",
          "upperPort": 0
        }
      ],
      "parentScalableGroup": {
        "idRef": "000c15bf-28fe-4fbb-9b69-35e6502721f1"
      },
      "user": [

      ],
      "userGroup": [

      ]
    },
    {
      "id": "e45c0ad6-04ed-4c4d-aab0-2517f1034d4e",
      "instanceId": 4320,
      "authEntityId": 4320,
      "displayName": "4320",
      "authEntityClass": -1909073334,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "createTime": 1517966742275,
      "deployed": false,
      "isSeeded": true,
      "isStale": false,
      "lastUpdateTime": 1517966742275,
      "name": "daily-mail",
      "namespace": "scalablegroup:application",
      "provisioningState": "DEFINED",
      "qualifier": "application",
      "resourceVersion": 0,
      "targetIdList": [

      ],
      "type": "scalablegroup",
      "cfsChangeInfo": [

      ],
      "customProvisions": [

      ],
      "scalableGroupExternalHandle": "daily-mail",
      "scalableGroupType": "APPLICATION",
      "securityGroupTag": 0,
      "state": "ACTIVE",
      "vnAgnostic": true,
      "identitySource": {
        "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
        "instanceId": 10010,
        "authEntityId": 10010,
        "displayName": "10010",
        "authEntityClass": 1738953278,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "state": "INACTIVE",
        "type": "NBAR"
      },
      "indicativeNetworkIdentity": [

      ],
      "networkApplications": [
        {
          "id": "22a2f4bd-9eac-48c2-a89e-423a28e30e45",
          "instanceId": 11272,
          "authEntityId": 11272,
          "displayName": "11272",
          "authEntityClass": -217092956,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "appProtocol": "tcp/udp",
          "applicationGroup": "other",
          "applicationType": "DEFAULT",
          "categoryId": "878644b5-fe91-45b4-9204-31c84a73ba48",
          "createTime": 1517966742275,
          "encrypted": "false",
          "engineId": "13",
          "helpString": "Daily Mail - Web Portal for news update.",
          "ignoreConflict": false,
          "lastUpdateTime": 1517966742275,
          "longDescription": "Daily Mail - Web Portal for news update.",
          "name": "daily-mail",
          "nbarId": "1562",
          "p2pTechnology": "false",
          "popularity": 3,
          "rank": 65535,
          "references": "http://dailymail.co.uk",
          "selectorId": "633",
          "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
          "trafficClass": "TRANSACTIONAL_DATA",
          "tunnel": "false"
        }
      ],
      "networkIdentity": [
        {
          "id": "3433739b-24b3-463f-b5ae-957bd74308bb",
          "instanceId": 12738,
          "authEntityId": 12738,
          "displayName": "12738",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "53,5353",
          "protocol": "UDP",
          "upperPort": 0
        },
        {
          "id": "3ae48743-483b-4687-b55b-b1195a056c58",
          "instanceId": 12739,
          "authEntityId": 12739,
          "displayName": "12739",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "53,80,443,5353",
          "protocol": "TCP",
          "upperPort": 0
        }
      ],
      "parentScalableGroup": {
        "idRef": "28e08de4-d7b2-4521-84ce-bc1e5ee0b9cc"
      },
      "user": [

      ],
      "userGroup": [

      ]
    },
    {
      "id": "e46d729a-9d97-44a7-b840-2d3eeba19c7a",
      "instanceId": 4916,
      "authEntityId": 4916,
      "displayName": "4916",
      "authEntityClass": -1909073334,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "createTime": 1517966743756,
      "deployed": false,
      "isSeeded": true,
      "isStale": false,
      "lastUpdateTime": 1517966743756,
      "name": "opsmgr",
      "namespace": "scalablegroup:application",
      "provisioningState": "DEFINED",
      "qualifier": "application",
      "resourceVersion": 0,
      "targetIdList": [

      ],
      "type": "scalablegroup",
      "cfsChangeInfo": [

      ],
      "customProvisions": [

      ],
      "scalableGroupExternalHandle": "opsmgr",
      "scalableGroupType": "APPLICATION",
      "securityGroupTag": 0,
      "state": "ACTIVE",
      "vnAgnostic": true,
      "identitySource": {
        "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
        "instanceId": 10010,
        "authEntityId": 10010,
        "displayName": "10010",
        "authEntityClass": 1738953278,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "state": "INACTIVE",
        "type": "NBAR"
      },
      "indicativeNetworkIdentity": [
        {
          "id": "d8091dcf-fe6f-43bc-8108-2969f5376078",
          "instanceId": 14414,
          "authEntityId": 14414,
          "displayName": "14414",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "1270",
          "protocol": "TCP",
          "upperPort": 0
        },
        {
          "id": "75705db4-e2d2-4568-94a3-2525e8057637",
          "instanceId": 14415,
          "authEntityId": 14415,
          "displayName": "14415",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "1270",
          "protocol": "UDP",
          "upperPort": 0
        }
      ],
      "networkApplications": [
        {
          "id": "14bd3a20-c461-40ed-8565-93ce21ef79dd",
          "instanceId": 11865,
          "authEntityId": 11865,
          "displayName": "11865",
          "authEntityClass": -217092956,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "appProtocol": "tcp/udp",
          "applicationGroup": "other",
          "applicationType": "DEFAULT",
          "categoryId": "6daa3dfd-3526-407a-88f0-97824b3f3a24",
          "createTime": 1517966743756,
          "encrypted": "false",
          "engineId": "3",
          "helpString": "Microsoft Operations Manager",
          "ignoreConflict": false,
          "lastUpdateTime": 1517966743756,
          "longDescription": "Microsoft System Center Operations Manager is a cross-platform data center management system for operating systems and hypervisors. It uses a single interface that shows state, health and performance information of computer systems. It also provides alerts generated according to some availability, performance, configuration or security situation being identified. It works with Microsoft Windows Server and Unix-based hosts.",
          "name": "opsmgr",
          "nbarId": "1387",
          "p2pTechnology": "false",
          "popularity": 2,
          "rank": 65535,
          "references": "http://www.microsoft.com/en-us/server-cloud/system-center/datacenter-management.aspx",
          "selectorId": "1270",
          "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
          "trafficClass": "BULK_DATA",
          "tunnel": "false"
        }
      ],
      "networkIdentity": [
        {
          "id": "163fbb07-d9fa-4f8f-a184-49f800aca979",
          "instanceId": 14412,
          "authEntityId": 14412,
          "displayName": "14412",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "1270",
          "protocol": "UDP",
          "upperPort": 0
        },
        {
          "id": "77c6cc33-2b88-4246-b85e-4dd0d8bf6244",
          "instanceId": 14413,
          "authEntityId": 14413,
          "displayName": "14413",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "1270",
          "protocol": "TCP",
          "upperPort": 0
        }
      ],
      "parentScalableGroup": {
        "idRef": "000c15bf-28fe-4fbb-9b69-35e6502721f1"
      },
      "user": [

      ],
      "userGroup": [

      ]
    },
    {
      "id": "e47e01ff-05ed-40a5-b654-062e57dfe490",
      "instanceId": 4842,
      "authEntityId": 4842,
      "displayName": "4842",
      "authEntityClass": -1909073334,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "createTime": 1517966743597,
      "deployed": false,
      "isSeeded": true,
      "isStale": false,
      "lastUpdateTime": 1517966743597,
      "name": "netnews",
      "namespace": "scalablegroup:application",
      "provisioningState": "DEFINED",
      "qualifier": "application",
      "resourceVersion": 0,
      "targetIdList": [

      ],
      "type": "scalablegroup",
      "cfsChangeInfo": [

      ],
      "customProvisions": [

      ],
      "scalableGroupExternalHandle": "netnews",
      "scalableGroupType": "APPLICATION",
      "securityGroupTag": 0,
      "state": "ACTIVE",
      "vnAgnostic": true,
      "identitySource": {
        "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
        "instanceId": 10010,
        "authEntityId": 10010,
        "displayName": "10010",
        "authEntityClass": 1738953278,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "state": "INACTIVE",
        "type": "NBAR"
      },
      "indicativeNetworkIdentity": [
        {
          "id": "fdadae3f-92b9-48de-a558-49e1a88b6037",
          "instanceId": 14154,
          "authEntityId": 14154,
          "displayName": "14154",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "532",
          "protocol": "TCP",
          "upperPort": 0
        },
        {
          "id": "8e1e003c-f7f9-4ab8-a8bb-40b732a13e4f",
          "instanceId": 14155,
          "authEntityId": 14155,
          "displayName": "14155",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "532",
          "protocol": "UDP",
          "upperPort": 0
        }
      ],
      "networkApplications": [
        {
          "id": "b1458bd8-6de5-4b10-95a9-e07c57730452",
          "instanceId": 11791,
          "authEntityId": 11791,
          "displayName": "11791",
          "authEntityClass": -217092956,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "appProtocol": "tcp/udp",
          "applicationGroup": "nntp-group",
          "applicationType": "DEFAULT",
          "categoryId": "6daa3dfd-3526-407a-88f0-97824b3f3a24",
          "createTime": 1517966743597,
          "encrypted": "false",
          "engineId": "3",
          "helpString": "readnews",
          "ignoreConflict": false,
          "lastUpdateTime": 1517966743597,
          "longDescription": "Netnews (Usenet) is a worldwide distributed Internet discussion system. It was developed from the general purpose UUCP architecture of the same name.",
          "name": "netnews",
          "nbarId": "450",
          "p2pTechnology": "false",
          "popularity": 1,
          "rank": 65535,
          "references": "http://en.wikipedia.org/wiki/Usenet",
          "selectorId": "532",
          "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
          "trafficClass": "BULK_DATA",
          "tunnel": "false"
        }
      ],
      "networkIdentity": [
        {
          "id": "9851864c-bee7-4331-b76b-e43f42821ab0",
          "instanceId": 14152,
          "authEntityId": 14152,
          "displayName": "14152",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "532",
          "protocol": "UDP",
          "upperPort": 0
        },
        {
          "id": "580bc16e-b5f1-46f4-b9f2-a21c64fed289",
          "instanceId": 14153,
          "authEntityId": 14153,
          "displayName": "14153",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "532",
          "protocol": "TCP",
          "upperPort": 0
        }
      ],
      "parentScalableGroup": {
        "idRef": "000c15bf-28fe-4fbb-9b69-35e6502721f1"
      },
      "user": [

      ],
      "userGroup": [

      ]
    },
    {
      "id": "e48eb0ed-23e9-421b-bc4c-c28f76b8e79d",
      "instanceId": 15294,
      "authEntityId": 15294,
      "displayName": "15294",
      "authEntityClass": -1909073334,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "createTime": 1517966744676,
      "deployed": false,
      "isSeeded": true,
      "isStale": false,
      "lastUpdateTime": 1517966744676,
      "name": "tn-tl-fd1",
      "namespace": "scalablegroup:application",
      "provisioningState": "DEFINED",
      "qualifier": "application",
      "resourceVersion": 0,
      "targetIdList": [

      ],
      "type": "scalablegroup",
      "cfsChangeInfo": [

      ],
      "customProvisions": [

      ],
      "scalableGroupExternalHandle": "tn-tl-fd1",
      "scalableGroupType": "APPLICATION",
      "securityGroupTag": 0,
      "state": "ACTIVE",
      "vnAgnostic": true,
      "identitySource": {
        "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
        "instanceId": 10010,
        "authEntityId": 10010,
        "displayName": "10010",
        "authEntityClass": 1738953278,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "state": "INACTIVE",
        "type": "NBAR"
      },
      "indicativeNetworkIdentity": [
        {
          "id": "1b4f239d-0ee3-4c8e-9dd3-3280dd7e3def",
          "instanceId": 17511,
          "authEntityId": 17511,
          "displayName": "17511",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "476",
          "protocol": "UDP",
          "upperPort": 0
        },
        {
          "id": "d58d6b41-b2e1-4d2b-937a-3f7f61b96aef",
          "instanceId": 17512,
          "authEntityId": 17512,
          "displayName": "17512",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "476",
          "protocol": "TCP",
          "upperPort": 0
        }
      ],
      "networkApplications": [
        {
          "id": "07e58a1b-7b6a-4a52-a1cb-546ea0fb2291",
          "instanceId": 16237,
          "authEntityId": 16237,
          "displayName": "16237",
          "authEntityClass": -217092956,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "appProtocol": "tcp/udp",
          "applicationGroup": "other",
          "applicationType": "DEFAULT",
          "categoryId": "6daa3dfd-3526-407a-88f0-97824b3f3a24",
          "createTime": 1517966744676,
          "encrypted": "false",
          "engineId": "3",
          "helpString": "tn-tl-fd1",
          "ignoreConflict": false,
          "lastUpdateTime": 1517966744676,
          "longDescription": "Registered with IANA on port 476 TCP/UDP",
          "name": "tn-tl-fd1",
          "nbarId": "390",
          "p2pTechnology": "false",
          "popularity": 1,
          "rank": 65535,
          "references": "http://www.iana.org/assignments/service-names-port-numbers/service-names-port-numbers.xml",
          "selectorId": "476",
          "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
          "trafficClass": "BULK_DATA",
          "tunnel": "false"
        }
      ],
      "networkIdentity": [
        {
          "id": "66036d0e-f6b2-4f1b-a097-c54d2403aa00",
          "instanceId": 17509,
          "authEntityId": 17509,
          "displayName": "17509",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "476",
          "protocol": "UDP",
          "upperPort": 0
        },
        {
          "id": "514b725d-b834-4eaf-9a67-90c288bba6ea",
          "instanceId": 17510,
          "authEntityId": 17510,
          "displayName": "17510",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "476",
          "protocol": "TCP",
          "upperPort": 0
        }
      ],
      "parentScalableGroup": {
        "idRef": "000c15bf-28fe-4fbb-9b69-35e6502721f1"
      },
      "user": [

      ],
      "userGroup": [

      ]
    },
    {
      "id": "e49ddd3b-6447-44fc-b914-674ea6e08903",
      "instanceId": 15438,
      "authEntityId": 15438,
      "displayName": "15438",
      "authEntityClass": -1909073334,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "createTime": 1517966744965,
      "deployed": false,
      "isSeeded": true,
      "isStale": false,
      "lastUpdateTime": 1517966744965,
      "name": "xinhuanet",
      "namespace": "scalablegroup:application",
      "provisioningState": "DEFINED",
      "qualifier": "application",
      "resourceVersion": 0,
      "targetIdList": [

      ],
      "type": "scalablegroup",
      "cfsChangeInfo": [

      ],
      "customProvisions": [

      ],
      "scalableGroupExternalHandle": "xinhuanet",
      "scalableGroupType": "APPLICATION",
      "securityGroupTag": 0,
      "state": "ACTIVE",
      "vnAgnostic": true,
      "identitySource": {
        "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
        "instanceId": 10010,
        "authEntityId": 10010,
        "displayName": "10010",
        "authEntityClass": 1738953278,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "state": "INACTIVE",
        "type": "NBAR"
      },
      "indicativeNetworkIdentity": [

      ],
      "networkApplications": [
        {
          "id": "36a1196b-df3d-412e-96d9-af1d661b7f8d",
          "instanceId": 16381,
          "authEntityId": 16381,
          "displayName": "16381",
          "authEntityClass": -217092956,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "appProtocol": "tcp/udp",
          "applicationGroup": "other",
          "applicationType": "DEFAULT",
          "categoryId": "878644b5-fe91-45b4-9204-31c84a73ba48",
          "createTime": 1517966744965,
          "encrypted": "false",
          "engineId": "13",
          "helpString": "The Xinhuanet - Chinese official website for the news agency Xinhua.",
          "ignoreConflict": false,
          "lastUpdateTime": 1517966744965,
          "longDescription": "The Xinhuanet - Chinese official website for the news agency Xinhua.",
          "name": "xinhuanet",
          "nbarId": "1539",
          "p2pTechnology": "false",
          "popularity": 3,
          "rank": 65535,
          "references": "http://xinhuanet.com",
          "selectorId": "622",
          "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
          "trafficClass": "TRANSACTIONAL_DATA",
          "tunnel": "false"
        }
      ],
      "networkIdentity": [
        {
          "id": "9e087312-ab21-4a29-bf68-bdeeecfa6198",
          "instanceId": 17897,
          "authEntityId": 17897,
          "displayName": "17897",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "53,5353",
          "protocol": "UDP",
          "upperPort": 0
        },
        {
          "id": "65110019-2fa6-4d32-9e87-11af8e7080da",
          "instanceId": 17898,
          "authEntityId": 17898,
          "displayName": "17898",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "53,80,443,5353",
          "protocol": "TCP",
          "upperPort": 0
        }
      ],
      "parentScalableGroup": {
        "idRef": "28e08de4-d7b2-4521-84ce-bc1e5ee0b9cc"
      },
      "user": [

      ],
      "userGroup": [

      ]
    },
    {
      "id": "e4e3348e-b8d4-4c99-bc12-18c11f9e6485",
      "instanceId": 15454,
      "authEntityId": 15454,
      "displayName": "15454",
      "authEntityClass": -1909073334,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "createTime": 1517966744994,
      "deployed": false,
      "isSeeded": true,
      "isStale": false,
      "lastUpdateTime": 1517966744994,
      "name": "yahoo-accounts",
      "namespace": "scalablegroup:application",
      "provisioningState": "DEFINED",
      "qualifier": "application",
      "resourceVersion": 0,
      "targetIdList": [

      ],
      "type": "scalablegroup",
      "cfsChangeInfo": [

      ],
      "customProvisions": [

      ],
      "scalableGroupExternalHandle": "yahoo-accounts",
      "scalableGroupType": "APPLICATION",
      "securityGroupTag": 0,
      "state": "ACTIVE",
      "vnAgnostic": true,
      "identitySource": {
        "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
        "instanceId": 10010,
        "authEntityId": 10010,
        "displayName": "10010",
        "authEntityClass": 1738953278,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "state": "INACTIVE",
        "type": "NBAR"
      },
      "indicativeNetworkIdentity": [

      ],
      "networkApplications": [
        {
          "id": "c391d1f0-a5d6-4b80-a15d-4b140f21daf4",
          "instanceId": 16397,
          "authEntityId": 16397,
          "displayName": "16397",
          "authEntityClass": -217092956,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "appProtocol": "tcp/udp",
          "applicationGroup": "yahoo-group",
          "applicationType": "DEFAULT",
          "categoryId": "f6ec0221-b82d-43e8-a64d-163cbc13263b",
          "createTime": 1517966744994,
          "encrypted": "true",
          "engineId": "13",
          "helpString": "Yahoo Accounts",
          "ignoreConflict": false,
          "lastUpdateTime": 1517966744994,
          "longDescription": "Yahoo Accounts is a protocol that covers the traffic of user login into one of multiple web services offered by Yahoo!",
          "name": "yahoo-accounts",
          "nbarId": "1083",
          "p2pTechnology": "false",
          "popularity": 10,
          "rank": 65535,
          "references": "http://login.yahoo.com",
          "selectorId": "533",
          "subCategory": "aab99e59-8589-4a61-933e-f01bff556d78",
          "trafficClass": "BULK_DATA",
          "tunnel": "false"
        }
      ],
      "networkIdentity": [
        {
          "id": "6a75a87e-d331-4b6b-aa5c-8a6c3756498c",
          "instanceId": 17945,
          "authEntityId": 17945,
          "displayName": "17945",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "53,80,443,5353",
          "protocol": "TCP",
          "upperPort": 0
        },
        {
          "id": "c28b470e-b634-4691-bf5a-0f7c7bd41e5d",
          "instanceId": 17946,
          "authEntityId": 17946,
          "displayName": "17946",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "53,5353",
          "protocol": "UDP",
          "upperPort": 0
        }
      ],
      "parentScalableGroup": {
        "idRef": "28e08de4-d7b2-4521-84ce-bc1e5ee0b9cc"
      },
      "user": [

      ],
      "userGroup": [

      ]
    },
    {
      "id": "e501c635-8120-425a-a491-d283628d8d1e",
      "instanceId": 4895,
      "authEntityId": 4895,
      "displayName": "4895",
      "authEntityClass": -1909073334,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "createTime": 1517966743702,
      "deployed": false,
      "isSeeded": true,
      "isStale": false,
      "lastUpdateTime": 1517966743702,
      "name": "objcall",
      "namespace": "scalablegroup:application",
      "provisioningState": "DEFINED",
      "qualifier": "application",
      "resourceVersion": 0,
      "targetIdList": [

      ],
      "type": "scalablegroup",
      "cfsChangeInfo": [

      ],
      "customProvisions": [

      ],
      "scalableGroupExternalHandle": "objcall",
      "scalableGroupType": "APPLICATION",
      "securityGroupTag": 0,
      "state": "ACTIVE",
      "vnAgnostic": true,
      "identitySource": {
        "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
        "instanceId": 10010,
        "authEntityId": 10010,
        "displayName": "10010",
        "authEntityClass": 1738953278,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "state": "INACTIVE",
        "type": "NBAR"
      },
      "indicativeNetworkIdentity": [
        {
          "id": "c9e834ff-c42e-427b-80ca-e88adef1f2e4",
          "instanceId": 14337,
          "authEntityId": 14337,
          "displayName": "14337",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "94",
          "protocol": "TCP",
          "upperPort": 0
        },
        {
          "id": "0c8ebf9f-b377-4d9c-a995-01e15b162fb1",
          "instanceId": 14338,
          "authEntityId": 14338,
          "displayName": "14338",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "94",
          "protocol": "UDP",
          "upperPort": 0
        }
      ],
      "networkApplications": [
        {
          "id": "382664af-a27e-4da1-952a-b0c6237cc9ca",
          "instanceId": 11844,
          "authEntityId": 11844,
          "displayName": "11844",
          "authEntityClass": -217092956,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "appProtocol": "tcp/udp",
          "applicationGroup": "other",
          "applicationType": "DEFAULT",
          "categoryId": "6daa3dfd-3526-407a-88f0-97824b3f3a24",
          "createTime": 1517966743702,
          "encrypted": "false",
          "engineId": "3",
          "helpString": "Tivoli Object Dispatcher",
          "ignoreConflict": false,
          "lastUpdateTime": 1517966743702,
          "longDescription": "The Tivoli object dispatcher (oserv) is the central driving mechanism for operations in the Tivoli environment.  It maintains the Tivoli object database on each system that has Tivoli installed, it routes object calls to the proper systems and objects, and it arranges for the execution of methods that are invoked in the context of objects that reside on the local system.",
          "name": "objcall",
          "nbarId": "965",
          "p2pTechnology": "false",
          "popularity": 1,
          "rank": 65535,
          "references": "http://publib.boulder.ibm.com/tividd/td/framework/GC32-0807-00/en_US/HTML/troubl09.html",
          "selectorId": "94",
          "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
          "trafficClass": "BULK_DATA",
          "tunnel": "false"
        }
      ],
      "networkIdentity": [
        {
          "id": "a16329f0-3168-478a-8e1a-6c67a9eb00a0",
          "instanceId": 14336,
          "authEntityId": 14336,
          "displayName": "14336",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "94",
          "protocol": "UDP",
          "upperPort": 0
        },
        {
          "id": "0fcf4d78-747f-468d-8ea1-a9d4b391a60b",
          "instanceId": 14335,
          "authEntityId": 14335,
          "displayName": "14335",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "94",
          "protocol": "TCP",
          "upperPort": 0
        }
      ],
      "parentScalableGroup": {
        "idRef": "000c15bf-28fe-4fbb-9b69-35e6502721f1"
      },
      "user": [

      ],
      "userGroup": [

      ]
    },
    {
      "id": "e50a6a80-77da-420b-9d6d-4cb4110301f5",
      "instanceId": 4160,
      "authEntityId": 4160,
      "displayName": "4160",
      "authEntityClass": -1909073334,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "createTime": 1517966741443,
      "deployed": false,
      "isSeeded": true,
      "isStale": false,
      "lastUpdateTime": 1517966741443,
      "name": "baidu-movie",
      "namespace": "scalablegroup:application",
      "provisioningState": "DEFINED",
      "qualifier": "application",
      "resourceVersion": 0,
      "targetIdList": [

      ],
      "type": "scalablegroup",
      "cfsChangeInfo": [

      ],
      "customProvisions": [

      ],
      "scalableGroupExternalHandle": "baidu-movie",
      "scalableGroupType": "APPLICATION",
      "securityGroupTag": 0,
      "state": "ACTIVE",
      "vnAgnostic": true,
      "identitySource": {
        "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
        "instanceId": 10010,
        "authEntityId": 10010,
        "displayName": "10010",
        "authEntityClass": 1738953278,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "state": "INACTIVE",
        "type": "NBAR"
      },
      "indicativeNetworkIdentity": [

      ],
      "networkApplications": [
        {
          "id": "666f90a2-6204-4b8e-b445-9e477f687c63",
          "instanceId": 11120,
          "authEntityId": 11120,
          "displayName": "11120",
          "authEntityClass": -217092956,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "appProtocol": "tcp/udp",
          "applicationGroup": "other",
          "applicationType": "DEFAULT",
          "categoryId": "a09ef1b3-ba26-4372-aee8-309d50697e76",
          "createTime": 1517966741443,
          "encrypted": "false",
          "engineId": "13",
          "helpString": "Internet TV",
          "ignoreConflict": false,
          "lastUpdateTime": 1517966741443,
          "longDescription": "Baidu movie is an Internet TV web-based application popular in China.",
          "name": "baidu-movie",
          "nbarId": "1043",
          "p2pTechnology": "false",
          "popularity": 8,
          "rank": 65535,
          "references": "http://video.baidu.com/",
          "selectorId": "442",
          "subCategory": "85a797c0-aef8-4284-91a7-677f33e29abb",
          "trafficClass": "MULTIMEDIA_STREAMING",
          "tunnel": "false"
        }
      ],
      "networkIdentity": [
        {
          "id": "7b1a53f0-4054-4a7d-a376-76e5e9085321",
          "instanceId": 12330,
          "authEntityId": 12330,
          "displayName": "12330",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "53,5353",
          "protocol": "UDP",
          "upperPort": 0
        },
        {
          "id": "ed650005-d062-4751-a3ca-f8ebdf66e29e",
          "instanceId": 12331,
          "authEntityId": 12331,
          "displayName": "12331",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "53,80,5353",
          "protocol": "TCP",
          "upperPort": 0
        }
      ],
      "parentScalableGroup": {
        "idRef": "fd6264c9-c16a-4311-a335-a3db039256f3"
      },
      "user": [

      ],
      "userGroup": [

      ]
    },
    {
      "id": "e5232643-8c3c-40e0-9c92-64f1691a5c37",
      "instanceId": 4329,
      "authEntityId": 4329,
      "displayName": "4329",
      "authEntityClass": -1909073334,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "createTime": 1517966742299,
      "deployed": false,
      "isSeeded": true,
      "isStale": false,
      "lastUpdateTime": 1517966742299,
      "name": "daum",
      "namespace": "scalablegroup:application",
      "provisioningState": "DEFINED",
      "qualifier": "application",
      "resourceVersion": 0,
      "targetIdList": [

      ],
      "type": "scalablegroup",
      "cfsChangeInfo": [

      ],
      "customProvisions": [

      ],
      "scalableGroupExternalHandle": "daum",
      "scalableGroupType": "APPLICATION",
      "securityGroupTag": 0,
      "state": "ACTIVE",
      "vnAgnostic": true,
      "identitySource": {
        "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
        "instanceId": 10010,
        "authEntityId": 10010,
        "displayName": "10010",
        "authEntityClass": 1738953278,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "state": "INACTIVE",
        "type": "NBAR"
      },
      "indicativeNetworkIdentity": [

      ],
      "networkApplications": [
        {
          "id": "f30381fd-6b82-462d-b86e-0dede6e687cf",
          "instanceId": 11280,
          "authEntityId": 11280,
          "displayName": "11280",
          "authEntityClass": -217092956,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "appProtocol": "tcp/udp",
          "applicationGroup": "other",
          "applicationType": "DEFAULT",
          "categoryId": "878644b5-fe91-45b4-9204-31c84a73ba48",
          "createTime": 1517966742299,
          "encrypted": "false",
          "engineId": "13",
          "helpString": "Daum - Popular South Korean web portal.",
          "ignoreConflict": false,
          "lastUpdateTime": 1517966742299,
          "longDescription": "Daum - Popular South Korean web portal.",
          "name": "daum",
          "nbarId": "1576",
          "p2pTechnology": "false",
          "popularity": 3,
          "rank": 65535,
          "references": "http://daum.net",
          "selectorId": "647",
          "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
          "trafficClass": "TRANSACTIONAL_DATA",
          "tunnel": "false"
        }
      ],
      "networkIdentity": [
        {
          "id": "543ad8ef-e26f-431e-8bb6-c6ed0f9dd036",
          "instanceId": 12762,
          "authEntityId": 12762,
          "displayName": "12762",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "53,5353",
          "protocol": "UDP",
          "upperPort": 0
        },
        {
          "id": "89884831-d6c4-4ef6-a123-ddb28c6a2264",
          "instanceId": 12763,
          "authEntityId": 12763,
          "displayName": "12763",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "53,80,443,5353",
          "protocol": "TCP",
          "upperPort": 0
        }
      ],
      "parentScalableGroup": {
        "idRef": "28e08de4-d7b2-4521-84ce-bc1e5ee0b9cc"
      },
      "user": [

      ],
      "userGroup": [

      ]
    },
    {
      "id": "e557feb0-6f7a-404a-ab5b-a9d64bf8fb6f",
      "instanceId": 4480,
      "authEntityId": 4480,
      "displayName": "4480",
      "authEntityClass": -1909073334,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "createTime": 1517966742778,
      "deployed": false,
      "isSeeded": true,
      "isStale": false,
      "lastUpdateTime": 1517966742778,
      "name": "ggp",
      "namespace": "scalablegroup:application",
      "provisioningState": "DEFINED",
      "qualifier": "application",
      "resourceVersion": 0,
      "targetIdList": [

      ],
      "type": "scalablegroup",
      "cfsChangeInfo": [

      ],
      "customProvisions": [

      ],
      "scalableGroupExternalHandle": "ggp",
      "scalableGroupType": "APPLICATION",
      "securityGroupTag": 0,
      "state": "ACTIVE",
      "vnAgnostic": true,
      "identitySource": {
        "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
        "instanceId": 10010,
        "authEntityId": 10010,
        "displayName": "10010",
        "authEntityClass": 1738953278,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "state": "INACTIVE",
        "type": "NBAR"
      },
      "indicativeNetworkIdentity": [
        {
          "id": "3d0bcd31-f159-416a-bb54-14882335a976",
          "instanceId": 13202,
          "authEntityId": 13202,
          "displayName": "13202",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipProtocolNumbers": "3",
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "protocol": "GGP",
          "upperPort": 0
        }
      ],
      "networkApplications": [
        {
          "id": "5dd7cd78-58b2-4bfc-81f8-a5fd80bd8e22",
          "instanceId": 11431,
          "authEntityId": 11431,
          "displayName": "11431",
          "authEntityClass": -217092956,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "appProtocol": "ip",
          "applicationGroup": "other",
          "applicationType": "DEFAULT",
          "categoryId": "6daa3dfd-3526-407a-88f0-97824b3f3a24",
          "createTime": 1517966742778,
          "encrypted": "false",
          "engineId": "1",
          "helpString": "Gateway-to-Gateway",
          "ignoreConflict": false,
          "lastUpdateTime": 1517966742778,
          "longDescription": "The Gateway-to-Gateway Protocol (GGP) is an obsolete protocol defined for routing datagrams between Internet gateways. The Gateway-to-Gateway Protocol was designed as an Internet Protocol (IP) datagram service similar to the Transmission Control Protocol (TCP) and the User Datagram Protocol (UDP). However, it is classified as an Internet Layer protocol.GGP uses a minimum hop algorithm, in which it measures distance in router hops. A router is defined to be zero hops from directly connected networks, one hop from networks that are reachable through one other gateway. The protocol implements a distributed shortest-path methodologies, and therefore requires global convergence of the routing tables after any change of link connectivity in the network.",
          "name": "ggp",
          "nbarId": "759",
          "p2pTechnology": "false",
          "popularity": 2,
          "rank": 65535,
          "references": "http://en.wikipedia.org/wiki/Gateway-to-Gateway_Protocol",
          "selectorId": "3",
          "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
          "trafficClass": "BULK_DATA",
          "tunnel": "false"
        }
      ],
      "networkIdentity": [
        {
          "id": "351abafc-ed5e-4284-a0bb-74051b6807ff",
          "instanceId": 13201,
          "authEntityId": 13201,
          "displayName": "13201",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipProtocolNumbers": "3",
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "protocol": "GGP",
          "upperPort": 0
        }
      ],
      "parentScalableGroup": {
        "idRef": "000c15bf-28fe-4fbb-9b69-35e6502721f1"
      },
      "user": [

      ],
      "userGroup": [

      ]
    },
    {
      "id": "e55a3898-c64e-4f1b-aa4c-0ab46751c0ae",
      "instanceId": 15211,
      "authEntityId": 15211,
      "displayName": "15211",
      "authEntityClass": -1909073334,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "createTime": 1517966744493,
      "deployed": false,
      "isSeeded": true,
      "isStale": false,
      "lastUpdateTime": 1517966744493,
      "name": "st",
      "namespace": "scalablegroup:application",
      "provisioningState": "DEFINED",
      "qualifier": "application",
      "resourceVersion": 0,
      "targetIdList": [

      ],
      "type": "scalablegroup",
      "cfsChangeInfo": [

      ],
      "customProvisions": [

      ],
      "scalableGroupExternalHandle": "st",
      "scalableGroupType": "APPLICATION",
      "securityGroupTag": 0,
      "state": "ACTIVE",
      "vnAgnostic": true,
      "identitySource": {
        "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
        "instanceId": 10010,
        "authEntityId": 10010,
        "displayName": "10010",
        "authEntityClass": 1738953278,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "state": "INACTIVE",
        "type": "NBAR"
      },
      "indicativeNetworkIdentity": [
        {
          "id": "caaf95bf-727c-431f-b392-302f3dcf44d5",
          "instanceId": 17275,
          "authEntityId": 17275,
          "displayName": "17275",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipProtocolNumbers": "5",
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "protocol": "ST",
          "upperPort": 0
        }
      ],
      "networkApplications": [
        {
          "id": "359714a9-0ab0-4c6e-b864-e94a10016045",
          "instanceId": 16154,
          "authEntityId": 16154,
          "displayName": "16154",
          "authEntityClass": -217092956,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "appProtocol": "ip",
          "applicationGroup": "other",
          "applicationType": "DEFAULT",
          "categoryId": "6daa3dfd-3526-407a-88f0-97824b3f3a24",
          "createTime": 1517966744493,
          "encrypted": "unassigned",
          "engineId": "1",
          "helpString": "Stream",
          "ignoreConflict": false,
          "lastUpdateTime": 1517966744493,
          "longDescription": "Internet Stream Protocol (ST or ST2) is an experimental resource reservation protocol intended to provide end-to-end real-time guarantees over an internet. It allows applications to build multi-destination simplex data streams with a desired quality of service.",
          "name": "st",
          "nbarId": "761",
          "p2pTechnology": "unassigned",
          "popularity": 2,
          "rank": 65535,
          "references": "http://tools.ietf.org/html/rfc1190",
          "selectorId": "5",
          "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
          "trafficClass": "BULK_DATA",
          "tunnel": "unassigned"
        }
      ],
      "networkIdentity": [
        {
          "id": "70b15250-657e-4a8a-82d1-3d5f501072d0",
          "instanceId": 17274,
          "authEntityId": 17274,
          "displayName": "17274",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipProtocolNumbers": "5",
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "protocol": "ST",
          "upperPort": 0
        }
      ],
      "parentScalableGroup": {
        "idRef": "000c15bf-28fe-4fbb-9b69-35e6502721f1"
      },
      "user": [

      ],
      "userGroup": [

      ]
    },
    {
      "id": "e57314ea-b277-4ed9-a8d3-a9430d3c0d45",
      "instanceId": 15395,
      "authEntityId": 15395,
      "displayName": "15395",
      "authEntityClass": -1909073334,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "createTime": 1517966744867,
      "deployed": false,
      "isSeeded": true,
      "isStale": false,
      "lastUpdateTime": 1517966744867,
      "name": "web-analytics",
      "namespace": "scalablegroup:application",
      "provisioningState": "DEFINED",
      "qualifier": "application",
      "resourceVersion": 0,
      "targetIdList": [

      ],
      "type": "scalablegroup",
      "cfsChangeInfo": [

      ],
      "customProvisions": [

      ],
      "scalableGroupExternalHandle": "web-analytics",
      "scalableGroupType": "APPLICATION",
      "securityGroupTag": 0,
      "state": "ACTIVE",
      "vnAgnostic": true,
      "identitySource": {
        "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
        "instanceId": 10010,
        "authEntityId": 10010,
        "displayName": "10010",
        "authEntityClass": 1738953278,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "state": "INACTIVE",
        "type": "NBAR"
      },
      "indicativeNetworkIdentity": [

      ],
      "networkApplications": [
        {
          "id": "9d909245-c711-4f7c-a6c2-df49af16773f",
          "instanceId": 16338,
          "authEntityId": 16338,
          "displayName": "16338",
          "authEntityClass": -217092956,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "appProtocol": "tcp/udp",
          "applicationGroup": "other",
          "applicationType": "DEFAULT",
          "categoryId": "6daa3dfd-3526-407a-88f0-97824b3f3a24",
          "createTime": 1517966744867,
          "encrypted": "false",
          "engineId": "13",
          "helpString": "Web Analytics",
          "ignoreConflict": false,
          "lastUpdateTime": 1517966744867,
          "longDescription": "Web and mobile analytics and customer engagement platforms",
          "name": "web-analytics",
          "nbarId": "1079",
          "p2pTechnology": "false",
          "popularity": 10,
          "rank": 65535,
          "references": "https://en.wikipedia.org/wiki/Web_analytics",
          "selectorId": "1079",
          "subCategory": "21d2cdac-b44c-4cab-a279-af3ff88ed550",
          "trafficClass": "TRANSACTIONAL_DATA",
          "tunnel": "false"
        }
      ],
      "networkIdentity": [
        {
          "id": "b6203da0-5944-45ec-b4ba-e48cfb9e9642",
          "instanceId": 17803,
          "authEntityId": 17803,
          "displayName": "17803",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "53,80,443,5353",
          "protocol": "TCP",
          "upperPort": 0
        },
        {
          "id": "6d5facbe-fa4d-4b07-a841-d5f96cf0178a",
          "instanceId": 17804,
          "authEntityId": 17804,
          "displayName": "17804",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "53,5353",
          "protocol": "UDP",
          "upperPort": 0
        }
      ],
      "parentScalableGroup": {
        "idRef": "5e66d25d-d517-482b-82a8-6f99f07fa786"
      },
      "user": [

      ],
      "userGroup": [

      ]
    },
    {
      "id": "e5782260-12e0-453e-acf1-536ad1f75724",
      "instanceId": 15164,
      "authEntityId": 15164,
      "displayName": "15164",
      "authEntityClass": -1909073334,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "createTime": 1517966744381,
      "deployed": false,
      "isSeeded": true,
      "isStale": false,
      "lastUpdateTime": 1517966744381,
      "name": "smp",
      "namespace": "scalablegroup:application",
      "provisioningState": "DEFINED",
      "qualifier": "application",
      "resourceVersion": 0,
      "targetIdList": [

      ],
      "type": "scalablegroup",
      "cfsChangeInfo": [

      ],
      "customProvisions": [

      ],
      "scalableGroupExternalHandle": "smp",
      "scalableGroupType": "APPLICATION",
      "securityGroupTag": 0,
      "state": "ACTIVE",
      "vnAgnostic": true,
      "identitySource": {
        "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
        "instanceId": 10010,
        "authEntityId": 10010,
        "displayName": "10010",
        "authEntityClass": 1738953278,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "state": "INACTIVE",
        "type": "NBAR"
      },
      "indicativeNetworkIdentity": [
        {
          "id": "0a58dfca-230f-49d2-8d6c-a8d224d347f8",
          "instanceId": 17138,
          "authEntityId": 17138,
          "displayName": "17138",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipProtocolNumbers": "121",
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "protocol": "SMP",
          "upperPort": 0
        }
      ],
      "networkApplications": [
        {
          "id": "f1ffb459-5132-4461-a830-45120f269811",
          "instanceId": 16107,
          "authEntityId": 16107,
          "displayName": "16107",
          "authEntityClass": -217092956,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "appProtocol": "ip",
          "applicationGroup": "other",
          "applicationType": "DEFAULT",
          "categoryId": "6daa3dfd-3526-407a-88f0-97824b3f3a24",
          "createTime": 1517966744381,
          "encrypted": "unassigned",
          "engineId": "1",
          "helpString": "Simple Message Protocol",
          "ignoreConflict": false,
          "lastUpdateTime": 1517966744381,
          "longDescription": "The Simple Message Protocol (SMP) is intended to be used to implement thread-to-thread messaging locally or over the Internet, in a reliable and secure communication.",
          "name": "smp",
          "nbarId": "875",
          "p2pTechnology": "unassigned",
          "popularity": 2,
          "rank": 65535,
          "references": "http://rdos.net/smp/smp.txt",
          "selectorId": "121",
          "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
          "trafficClass": "BULK_DATA",
          "tunnel": "unassigned"
        }
      ],
      "networkIdentity": [
        {
          "id": "21d9b7da-1e75-4928-88cc-53ce0d80f152",
          "instanceId": 17137,
          "authEntityId": 17137,
          "displayName": "17137",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipProtocolNumbers": "121",
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "protocol": "SMP",
          "upperPort": 0
        }
      ],
      "parentScalableGroup": {
        "idRef": "000c15bf-28fe-4fbb-9b69-35e6502721f1"
      },
      "user": [

      ],
      "userGroup": [

      ]
    },
    {
      "id": "e5be7e01-8c89-41ac-a021-9efa14487922",
      "instanceId": 4360,
      "authEntityId": 4360,
      "displayName": "4360",
      "authEntityClass": -1909073334,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "createTime": 1517966742397,
      "deployed": false,
      "isSeeded": true,
      "isStale": false,
      "lastUpdateTime": 1517966742397,
      "name": "dicom",
      "namespace": "scalablegroup:application",
      "provisioningState": "DEFINED",
      "qualifier": "application",
      "resourceVersion": 0,
      "targetIdList": [

      ],
      "type": "scalablegroup",
      "cfsChangeInfo": [

      ],
      "customProvisions": [

      ],
      "scalableGroupExternalHandle": "dicom",
      "scalableGroupType": "APPLICATION",
      "securityGroupTag": 0,
      "state": "ACTIVE",
      "vnAgnostic": true,
      "identitySource": {
        "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
        "instanceId": 10010,
        "authEntityId": 10010,
        "displayName": "10010",
        "authEntityClass": 1738953278,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "state": "INACTIVE",
        "type": "NBAR"
      },
      "indicativeNetworkIdentity": [

      ],
      "networkApplications": [
        {
          "id": "914b5364-80ae-4758-a232-052ef8b73c24",
          "instanceId": 11311,
          "authEntityId": 11311,
          "displayName": "11311",
          "authEntityClass": -217092956,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "appProtocol": "tcp",
          "applicationGroup": "other",
          "applicationType": "DEFAULT",
          "categoryId": "58aa61dc-dd95-4a6d-9c7c-0ebf16da6dde",
          "createTime": 1517966742397,
          "encrypted": "false",
          "engineId": "13",
          "helpString": "Digital Imaging and Communication in Medicine",
          "ignoreConflict": false,
          "lastUpdateTime": 1517966742397,
          "longDescription": "Digital Imaging and Communication in Medicine (DICOM) is used to handle, store, print, and transmit medical imaging.",
          "name": "dicom",
          "nbarId": "76",
          "p2pTechnology": "false",
          "popularity": 6,
          "rank": 65535,
          "references": "http://dicom.nema.org/",
          "selectorId": "76",
          "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
          "trafficClass": "BULK_DATA",
          "tunnel": "false"
        }
      ],
      "networkIdentity": [
        {
          "id": "e2b4beaa-59d4-4f15-b96b-2b340be29467",
          "instanceId": 12860,
          "authEntityId": 12860,
          "displayName": "12860",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "80",
          "protocol": "TCP",
          "upperPort": 0
        }
      ],
      "parentScalableGroup": {
        "idRef": "cff11752-fafb-4e22-9e6f-11f44f59a9a3"
      },
      "user": [

      ],
      "userGroup": [

      ]
    },
    {
      "id": "e5e73a85-97c1-4308-972c-29e70b91c17e",
      "instanceId": 15289,
      "authEntityId": 15289,
      "displayName": "15289",
      "authEntityClass": -1909073334,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "createTime": 1517966744665,
      "deployed": false,
      "isSeeded": true,
      "isStale": false,
      "lastUpdateTime": 1517966744665,
      "name": "tinc",
      "namespace": "scalablegroup:application",
      "provisioningState": "DEFINED",
      "qualifier": "application",
      "resourceVersion": 0,
      "targetIdList": [

      ],
      "type": "scalablegroup",
      "cfsChangeInfo": [

      ],
      "customProvisions": [

      ],
      "scalableGroupExternalHandle": "tinc",
      "scalableGroupType": "APPLICATION",
      "securityGroupTag": 0,
      "state": "ACTIVE",
      "vnAgnostic": true,
      "identitySource": {
        "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
        "instanceId": 10010,
        "authEntityId": 10010,
        "displayName": "10010",
        "authEntityClass": 1738953278,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "state": "INACTIVE",
        "type": "NBAR"
      },
      "indicativeNetworkIdentity": [
        {
          "id": "0a3d9252-2e49-41ce-bcef-55d514ed4be7",
          "instanceId": 17497,
          "authEntityId": 17497,
          "displayName": "17497",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "655",
          "protocol": "TCP",
          "upperPort": 0
        },
        {
          "id": "0f4c936f-cd5c-45e2-87fd-9326603e26c6",
          "instanceId": 17498,
          "authEntityId": 17498,
          "displayName": "17498",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "655",
          "protocol": "UDP",
          "upperPort": 0
        }
      ],
      "networkApplications": [
        {
          "id": "341c2b23-04ea-4aa0-9211-d349639db624",
          "instanceId": 16232,
          "authEntityId": 16232,
          "displayName": "16232",
          "authEntityClass": -217092956,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "appProtocol": "tcp/udp",
          "applicationGroup": "other",
          "applicationType": "DEFAULT",
          "categoryId": "70fce3cd-f2bd-48de-9020-7b7840b9e7a1",
          "createTime": 1517966744665,
          "encrypted": "true",
          "engineId": "3",
          "helpString": "tinc",
          "ignoreConflict": false,
          "lastUpdateTime": 1517966744665,
          "longDescription": "tinc is a Virtual Private Network (VPN) daemon that uses tunnelling and encryption to create a secure private network between hosts on the Internet.",
          "name": "tinc",
          "nbarId": "564",
          "p2pTechnology": "false",
          "popularity": 1,
          "rank": 65535,
          "references": "http://www.tinc-vpn.org/",
          "selectorId": "655",
          "subCategory": "ab63e736-9aa7-4946-b55b-3b26ddca609c",
          "trafficClass": "BULK_DATA",
          "tunnel": "true"
        }
      ],
      "networkIdentity": [
        {
          "id": "d86c1efa-3636-4756-851a-ead05654d590",
          "instanceId": 17495,
          "authEntityId": 17495,
          "displayName": "17495",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "655",
          "protocol": "UDP",
          "upperPort": 0
        },
        {
          "id": "ab7cc409-dda4-4d82-833b-b2058713c56c",
          "instanceId": 17496,
          "authEntityId": 17496,
          "displayName": "17496",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "655",
          "protocol": "TCP",
          "upperPort": 0
        }
      ],
      "parentScalableGroup": {
        "idRef": "79f9d505-477f-4ccd-bd2a-c5fc8097a2e1"
      },
      "user": [

      ],
      "userGroup": [

      ]
    },
    {
      "id": "e5f08701-7eb1-4cfc-bf06-2dc9886bb3f6",
      "instanceId": 4987,
      "authEntityId": 4987,
      "displayName": "4987",
      "authEntityClass": -1909073334,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "createTime": 1517966743981,
      "deployed": false,
      "isSeeded": true,
      "isStale": false,
      "lastUpdateTime": 1517966743981,
      "name": "prm-sm",
      "namespace": "scalablegroup:application",
      "provisioningState": "DEFINED",
      "qualifier": "application",
      "resourceVersion": 0,
      "targetIdList": [

      ],
      "type": "scalablegroup",
      "cfsChangeInfo": [

      ],
      "customProvisions": [

      ],
      "scalableGroupExternalHandle": "prm-sm",
      "scalableGroupType": "APPLICATION",
      "securityGroupTag": 0,
      "state": "ACTIVE",
      "vnAgnostic": true,
      "identitySource": {
        "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
        "instanceId": 10010,
        "authEntityId": 10010,
        "displayName": "10010",
        "authEntityClass": 1738953278,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "state": "INACTIVE",
        "type": "NBAR"
      },
      "indicativeNetworkIdentity": [
        {
          "id": "4b32bc88-60d7-4c6a-b386-d0443746c0a8",
          "instanceId": 14614,
          "authEntityId": 14614,
          "displayName": "14614",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "408",
          "protocol": "UDP",
          "upperPort": 0
        },
        {
          "id": "2c6fce09-a1d4-447b-a7c7-60bd37e5f898",
          "instanceId": 14615,
          "authEntityId": 14615,
          "displayName": "14615",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "408",
          "protocol": "TCP",
          "upperPort": 0
        }
      ],
      "networkApplications": [
        {
          "id": "9c5e79fc-bf9b-4a50-b139-ac283cbb6766",
          "instanceId": 11936,
          "authEntityId": 11936,
          "displayName": "11936",
          "authEntityClass": -217092956,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "appProtocol": "tcp/udp",
          "applicationGroup": "prm-group",
          "applicationType": "DEFAULT",
          "categoryId": "d264d6f4-b014-43e6-acbc-516128d1c6c8",
          "createTime": 1517966743981,
          "encrypted": "false",
          "engineId": "3",
          "helpString": "Prospero Resource Manager Sys. Man",
          "ignoreConflict": false,
          "lastUpdateTime": 1517966743981,
          "longDescription": "The Prospero Resource Manager (PRM) presents a uniform and scalable model for scheduling tasks in parallel and distributed systems. PRM provides the mechanisms through which nodes on multiprocessors can be allocated to jobs running within an extremely large distributed system. The system manager is one (out of three) type of managers that manages  the full set of resources that exist in a system.",
          "name": "prm-sm",
          "nbarId": "323",
          "p2pTechnology": "false",
          "popularity": 1,
          "rank": 65535,
          "references": "http://gost.isi.edu/products/prm/papers/prm-hpdc93.ps",
          "selectorId": "408",
          "subCategory": "68c4f989-5735-4e16-8d1c-6a9d89d5df9c",
          "trafficClass": "SIGNALING",
          "tunnel": "false"
        }
      ],
      "networkIdentity": [
        {
          "id": "af04c2da-860a-4ad3-a4f2-1e258c0b8d81",
          "instanceId": 14612,
          "authEntityId": 14612,
          "displayName": "14612",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "408",
          "protocol": "TCP",
          "upperPort": 0
        },
        {
          "id": "469ec7fd-3b74-499a-a071-96226a5a81eb",
          "instanceId": 14613,
          "authEntityId": 14613,
          "displayName": "14613",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "408",
          "protocol": "UDP",
          "upperPort": 0
        }
      ],
      "parentScalableGroup": {
        "idRef": "a3243a74-3864-4041-a927-0201030da17c"
      },
      "user": [

      ],
      "userGroup": [

      ]
    },
    {
      "id": "e61d8316-f8ed-4b88-9a19-f9a97e6bd740",
      "instanceId": 4120,
      "authEntityId": 4120,
      "displayName": "4120",
      "authEntityClass": -1909073334,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "createTime": 1517966741235,
      "deployed": false,
      "isSeeded": true,
      "isStale": false,
      "lastUpdateTime": 1517966741235,
      "name": "arns",
      "namespace": "scalablegroup:application",
      "provisioningState": "DEFINED",
      "qualifier": "application",
      "resourceVersion": 0,
      "targetIdList": [

      ],
      "type": "scalablegroup",
      "cfsChangeInfo": [

      ],
      "customProvisions": [

      ],
      "scalableGroupExternalHandle": "arns",
      "scalableGroupType": "APPLICATION",
      "securityGroupTag": 0,
      "state": "ACTIVE",
      "vnAgnostic": true,
      "identitySource": {
        "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
        "instanceId": 10010,
        "authEntityId": 10010,
        "displayName": "10010",
        "authEntityClass": 1738953278,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "state": "INACTIVE",
        "type": "NBAR"
      },
      "indicativeNetworkIdentity": [
        {
          "id": "9f5ecfd2-40c2-4674-9e5c-a1764ea58339",
          "instanceId": 12215,
          "authEntityId": 12215,
          "displayName": "12215",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "384",
          "protocol": "UDP",
          "upperPort": 0
        },
        {
          "id": "500f2875-e17c-41b2-bf78-ca670db98400",
          "instanceId": 12216,
          "authEntityId": 12216,
          "displayName": "12216",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "384",
          "protocol": "TCP",
          "upperPort": 0
        }
      ],
      "networkApplications": [
        {
          "id": "7de0dea7-ca18-419a-a5d8-09b258134504",
          "instanceId": 11082,
          "authEntityId": 11082,
          "displayName": "11082",
          "authEntityClass": -217092956,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "appProtocol": "tcp/udp",
          "applicationGroup": "other",
          "applicationType": "DEFAULT",
          "categoryId": "6daa3dfd-3526-407a-88f0-97824b3f3a24",
          "createTime": 1517966741235,
          "encrypted": "false",
          "engineId": "3",
          "helpString": "A remote network server system",
          "ignoreConflict": false,
          "lastUpdateTime": 1517966741235,
          "longDescription": "Registered with IANA on port 384 TCP/UDP",
          "name": "arns",
          "nbarId": "300",
          "p2pTechnology": "false",
          "popularity": 1,
          "rank": 65535,
          "references": "http://www.iana.org/assignments/service-names-port-numbers/service-names-port-numbers.xml",
          "selectorId": "384",
          "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
          "trafficClass": "BULK_DATA",
          "tunnel": "false"
        }
      ],
      "networkIdentity": [
        {
          "id": "22883f96-b9f1-4797-8f0f-9f627e392157",
          "instanceId": 12213,
          "authEntityId": 12213,
          "displayName": "12213",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "384",
          "protocol": "TCP",
          "upperPort": 0
        },
        {
          "id": "e496ad2b-fbb3-463c-864e-9ccc1e949797",
          "instanceId": 12214,
          "authEntityId": 12214,
          "displayName": "12214",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "384",
          "protocol": "UDP",
          "upperPort": 0
        }
      ],
      "parentScalableGroup": {
        "idRef": "000c15bf-28fe-4fbb-9b69-35e6502721f1"
      },
      "user": [

      ],
      "userGroup": [

      ]
    },
    {
      "id": "e622f755-fbe9-47e0-ba1f-fd0ced94b0fa",
      "instanceId": 15255,
      "authEntityId": 15255,
      "displayName": "15255",
      "authEntityClass": -1909073334,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "createTime": 1517966744590,
      "deployed": false,
      "isSeeded": true,
      "isStale": false,
      "lastUpdateTime": 1517966744590,
      "name": "tcf",
      "namespace": "scalablegroup:application",
      "provisioningState": "DEFINED",
      "qualifier": "application",
      "resourceVersion": 0,
      "targetIdList": [

      ],
      "type": "scalablegroup",
      "cfsChangeInfo": [

      ],
      "customProvisions": [

      ],
      "scalableGroupExternalHandle": "tcf",
      "scalableGroupType": "APPLICATION",
      "securityGroupTag": 0,
      "state": "ACTIVE",
      "vnAgnostic": true,
      "identitySource": {
        "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
        "instanceId": 10010,
        "authEntityId": 10010,
        "displayName": "10010",
        "authEntityClass": 1738953278,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "state": "INACTIVE",
        "type": "NBAR"
      },
      "indicativeNetworkIdentity": [
        {
          "id": "cdcb20c1-6fd6-4d3b-b42e-7169d08a9fd5",
          "instanceId": 17405,
          "authEntityId": 17405,
          "displayName": "17405",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipProtocolNumbers": "87",
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "protocol": "TCF",
          "upperPort": 0
        }
      ],
      "networkApplications": [
        {
          "id": "977950da-36bb-4ee2-ac04-d3ef24f7a381",
          "instanceId": 16198,
          "authEntityId": 16198,
          "displayName": "16198",
          "authEntityClass": -217092956,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "appProtocol": "ip",
          "applicationGroup": "other",
          "applicationType": "DEFAULT",
          "categoryId": "6daa3dfd-3526-407a-88f0-97824b3f3a24",
          "createTime": 1517966744590,
          "encrypted": "unassigned",
          "engineId": "1",
          "helpString": "TCF",
          "ignoreConflict": false,
          "lastUpdateTime": 1517966744590,
          "longDescription": "Target Communication Framework (TCF) is a vendor-neutral, lightweight, extensible network protocol used mainly for communicating with embedded systems (targets). Its most distinguishing feature is that TCF is designed to transparently plug in value-adding servers between the tool and the target. Without value-add, the protocol can unify many currently independent communication links, thus saving resources and making setup and configuration much easier than in current embedded development scenarios.",
          "name": "tcf",
          "nbarId": "841",
          "p2pTechnology": "unassigned",
          "popularity": 2,
          "rank": 65535,
          "references": "http://wiki.eclipse.org/DSDP/TM/TCF_FAQ",
          "selectorId": "87",
          "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
          "trafficClass": "BULK_DATA",
          "tunnel": "unassigned"
        }
      ],
      "networkIdentity": [
        {
          "id": "6f862364-37cc-470d-ad67-0ecd4793f17e",
          "instanceId": 17404,
          "authEntityId": 17404,
          "displayName": "17404",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipProtocolNumbers": "87",
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "protocol": "TCF",
          "upperPort": 0
        }
      ],
      "parentScalableGroup": {
        "idRef": "000c15bf-28fe-4fbb-9b69-35e6502721f1"
      },
      "user": [

      ],
      "userGroup": [

      ]
    },
    {
      "id": "e65b0838-57fa-4221-996c-4ae39415a72a",
      "instanceId": 15229,
      "authEntityId": 15229,
      "displayName": "15229",
      "authEntityClass": -1909073334,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "createTime": 1517966744538,
      "deployed": false,
      "isSeeded": true,
      "isStale": false,
      "lastUpdateTime": 1517966744538,
      "name": "subntbcst_tftp",
      "namespace": "scalablegroup:application",
      "provisioningState": "DEFINED",
      "qualifier": "application",
      "resourceVersion": 0,
      "targetIdList": [

      ],
      "type": "scalablegroup",
      "cfsChangeInfo": [

      ],
      "customProvisions": [

      ],
      "scalableGroupExternalHandle": "subntbcst_tftp",
      "scalableGroupType": "APPLICATION",
      "securityGroupTag": 0,
      "state": "ACTIVE",
      "vnAgnostic": true,
      "identitySource": {
        "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
        "instanceId": 10010,
        "authEntityId": 10010,
        "displayName": "10010",
        "authEntityClass": 1738953278,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "state": "INACTIVE",
        "type": "NBAR"
      },
      "indicativeNetworkIdentity": [
        {
          "id": "c9ccf263-7148-42da-8440-9447833b3473",
          "instanceId": 17320,
          "authEntityId": 17320,
          "displayName": "17320",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "247",
          "protocol": "TCP",
          "upperPort": 0
        },
        {
          "id": "168b111f-81c2-416b-9bd5-5d3c122046a0",
          "instanceId": 17321,
          "authEntityId": 17321,
          "displayName": "17321",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "247",
          "protocol": "UDP",
          "upperPort": 0
        }
      ],
      "networkApplications": [
        {
          "id": "a926e60f-dca0-4f80-aa6e-478700adf554",
          "instanceId": 16172,
          "authEntityId": 16172,
          "displayName": "16172",
          "authEntityClass": -217092956,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "appProtocol": "tcp/udp",
          "applicationGroup": "tftp-group",
          "applicationType": "DEFAULT",
          "categoryId": "e6619ce8-97de-4c63-a53a-cceddbddc1a8",
          "createTime": 1517966744538,
          "encrypted": "false",
          "engineId": "3",
          "helpString": "subntbcst_tftp",
          "ignoreConflict": false,
          "lastUpdateTime": 1517966744538,
          "longDescription": "Registered with IANA on port 247 TCP/UDP",
          "name": "subntbcst_tftp",
          "nbarId": "1127",
          "p2pTechnology": "false",
          "popularity": 1,
          "rank": 65535,
          "references": "http://www.iana.org/assignments/service-names-port-numbers/service-names-port-numbers.xml",
          "selectorId": "247",
          "subCategory": "50b55e2e-d0d0-4404-bea4-819351565d90",
          "trafficClass": "BULK_DATA",
          "tunnel": "false"
        }
      ],
      "networkIdentity": [
        {
          "id": "ac2e4283-a61f-4391-b88d-047b828ea4d6",
          "instanceId": 17318,
          "authEntityId": 17318,
          "displayName": "17318",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "247",
          "protocol": "UDP",
          "upperPort": 0
        },
        {
          "id": "b485f43e-5236-44d4-83c5-f431f82c8f8f",
          "instanceId": 17319,
          "authEntityId": 17319,
          "displayName": "17319",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "247",
          "protocol": "TCP",
          "upperPort": 0
        }
      ],
      "parentScalableGroup": {
        "idRef": "383363b1-4dd5-49d1-bfe4-34daac437cfb"
      },
      "user": [

      ],
      "userGroup": [

      ]
    },
    {
      "id": "e68edc86-12a4-4868-9091-4a0b9d1ab217",
      "instanceId": 4785,
      "authEntityId": 4785,
      "displayName": "4785",
      "authEntityClass": -1909073334,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "createTime": 1517966743487,
      "deployed": false,
      "isSeeded": true,
      "isStale": false,
      "lastUpdateTime": 1517966743487,
      "name": "ms-rome",
      "namespace": "scalablegroup:application",
      "provisioningState": "DEFINED",
      "qualifier": "application",
      "resourceVersion": 0,
      "targetIdList": [

      ],
      "type": "scalablegroup",
      "cfsChangeInfo": [

      ],
      "customProvisions": [

      ],
      "scalableGroupExternalHandle": "ms-rome",
      "scalableGroupType": "APPLICATION",
      "securityGroupTag": 0,
      "state": "ACTIVE",
      "vnAgnostic": true,
      "identitySource": {
        "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
        "instanceId": 10010,
        "authEntityId": 10010,
        "displayName": "10010",
        "authEntityClass": 1738953278,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "state": "INACTIVE",
        "type": "NBAR"
      },
      "indicativeNetworkIdentity": [
        {
          "id": "61ed8a25-45e4-4a78-b629-f77e4dd52c8c",
          "instanceId": 14000,
          "authEntityId": 14000,
          "displayName": "14000",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "569",
          "protocol": "TCP",
          "upperPort": 0
        },
        {
          "id": "69c4ae6f-5b6f-4b6d-a685-64ef2512947c",
          "instanceId": 14001,
          "authEntityId": 14001,
          "displayName": "14001",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "569",
          "protocol": "UDP",
          "upperPort": 0
        }
      ],
      "networkApplications": [
        {
          "id": "f2c4a01b-42e3-4037-822f-7fcf429f8f95",
          "instanceId": 11734,
          "authEntityId": 11734,
          "displayName": "11734",
          "authEntityClass": -217092956,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "appProtocol": "tcp/udp",
          "applicationGroup": "other",
          "applicationType": "DEFAULT",
          "categoryId": "6daa3dfd-3526-407a-88f0-97824b3f3a24",
          "createTime": 1517966743487,
          "encrypted": "false",
          "engineId": "3",
          "helpString": "microsoft rome",
          "ignoreConflict": false,
          "lastUpdateTime": 1517966743487,
          "longDescription": "Registered with IANA on port 569 TCP/UDP",
          "name": "ms-rome",
          "nbarId": "484",
          "p2pTechnology": "false",
          "popularity": 2,
          "rank": 65535,
          "references": "http://www.iana.org/assignments/service-names-port-numbers/service-names-port-numbers.xml",
          "selectorId": "569",
          "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
          "trafficClass": "BULK_DATA",
          "tunnel": "false"
        }
      ],
      "networkIdentity": [
        {
          "id": "86e3a74b-4828-48d1-b23b-9b8d53e40a02",
          "instanceId": 13998,
          "authEntityId": 13998,
          "displayName": "13998",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "569",
          "protocol": "UDP",
          "upperPort": 0
        },
        {
          "id": "25b1bddc-5624-466b-85c6-d39ac4027644",
          "instanceId": 13999,
          "authEntityId": 13999,
          "displayName": "13999",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "569",
          "protocol": "TCP",
          "upperPort": 0
        }
      ],
      "parentScalableGroup": {
        "idRef": "000c15bf-28fe-4fbb-9b69-35e6502721f1"
      },
      "user": [

      ],
      "userGroup": [

      ]
    },
    {
      "id": "e6b9621d-6165-4036-83d2-93b04fb698ae",
      "instanceId": 15256,
      "authEntityId": 15256,
      "displayName": "15256",
      "authEntityClass": -1909073334,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "createTime": 1517966744592,
      "deployed": false,
      "isSeeded": true,
      "isStale": false,
      "lastUpdateTime": 1517966744592,
      "name": "tcpoverdns",
      "namespace": "scalablegroup:application",
      "provisioningState": "DEFINED",
      "qualifier": "application",
      "resourceVersion": 0,
      "targetIdList": [

      ],
      "type": "scalablegroup",
      "cfsChangeInfo": [

      ],
      "customProvisions": [

      ],
      "scalableGroupExternalHandle": "tcpoverdns",
      "scalableGroupType": "APPLICATION",
      "securityGroupTag": 0,
      "state": "ACTIVE",
      "vnAgnostic": true,
      "identitySource": {
        "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
        "instanceId": 10010,
        "authEntityId": 10010,
        "displayName": "10010",
        "authEntityClass": 1738953278,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "state": "INACTIVE",
        "type": "NBAR"
      },
      "indicativeNetworkIdentity": [

      ],
      "networkApplications": [
        {
          "id": "3f36757f-be7c-47f1-bf42-3b7ce4433358",
          "instanceId": 16199,
          "authEntityId": 16199,
          "displayName": "16199",
          "authEntityClass": -217092956,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "appProtocol": "tcp/udp",
          "applicationGroup": "other",
          "applicationType": "DEFAULT",
          "categoryId": "f6ec0221-b82d-43e8-a64d-163cbc13263b",
          "createTime": 1517966744592,
          "encrypted": "false",
          "engineId": "13",
          "helpString": "TCP over DNS traffic",
          "ignoreConflict": false,
          "lastUpdateTime": 1517966744592,
          "longDescription": "tcp-over-dns contains a special dns server and a special dns client. The client and server work in tandem to provide a TCP tunnel through the standard DNS protocol.",
          "name": "tcpoverdns",
          "nbarId": "1042",
          "p2pTechnology": "false",
          "popularity": 4,
          "rank": 65535,
          "references": "http://analogbit.com/software/tcp-over-dns",
          "selectorId": "331",
          "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
          "trafficClass": "BULK_DATA",
          "tunnel": "true"
        }
      ],
      "networkIdentity": [
        {
          "id": "a8ada878-0853-4b47-ac67-12229afaa06b",
          "instanceId": 17406,
          "authEntityId": 17406,
          "displayName": "17406",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "53,80,5353",
          "protocol": "TCP",
          "upperPort": 0
        },
        {
          "id": "53d986a9-25ac-4b63-9359-a56e5babee20",
          "instanceId": 17407,
          "authEntityId": 17407,
          "displayName": "17407",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "53,5353",
          "protocol": "UDP",
          "upperPort": 0
        }
      ],
      "parentScalableGroup": {
        "idRef": "28e08de4-d7b2-4521-84ce-bc1e5ee0b9cc"
      },
      "user": [

      ],
      "userGroup": [

      ]
    },
    {
      "id": "e6ecc6b2-84b5-4ced-8098-7686d4f0366c",
      "instanceId": 15079,
      "authEntityId": 15079,
      "displayName": "15079",
      "authEntityClass": -1909073334,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "createTime": 1517966744186,
      "deployed": false,
      "isSeeded": true,
      "isStale": false,
      "lastUpdateTime": 1517966744186,
      "name": "rtp-video",
      "namespace": "scalablegroup:application",
      "provisioningState": "DEFINED",
      "qualifier": "application",
      "resourceVersion": 0,
      "targetIdList": [

      ],
      "type": "scalablegroup",
      "cfsChangeInfo": [

      ],
      "customProvisions": [

      ],
      "scalableGroupExternalHandle": "rtp-video",
      "scalableGroupType": "APPLICATION",
      "securityGroupTag": 0,
      "state": "ACTIVE",
      "vnAgnostic": true,
      "identitySource": {
        "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
        "instanceId": 10010,
        "authEntityId": 10010,
        "displayName": "10010",
        "authEntityClass": 1738953278,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "state": "INACTIVE",
        "type": "NBAR"
      },
      "indicativeNetworkIdentity": [

      ],
      "networkApplications": [
        {
          "id": "49edfbe6-fee0-4c04-8f75-dc60d5398a51",
          "instanceId": 16022,
          "authEntityId": 16022,
          "displayName": "16022",
          "authEntityClass": -217092956,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "appProtocol": "tcp/udp",
          "applicationGroup": "other",
          "applicationType": "DEFAULT",
          "categoryId": "38a8e354-e2fe-4f3c-b1c5-2bbb90fb0d99",
          "createTime": 1517966744186,
          "encrypted": "false",
          "engineId": "13",
          "helpString": "Real Time Protocol Video",
          "ignoreConflict": false,
          "lastUpdateTime": 1517966744186,
          "longDescription": "RTP Video is the video portion of Real-time Transport Protocol (RTP) which is used for streaming video in real time for various applications. RTP works in conjunction with some streaming control protocols like RTCP, SIP, H.225 or H.245.",
          "name": "rtp-video",
          "nbarId": "1504",
          "p2pTechnology": "false",
          "popularity": 10,
          "rank": 65535,
          "references": "http://tools.ietf.org/html/rfc3550",
          "selectorId": "567",
          "subCategory": "53ce0342-1e2d-455a-acc7-1b50db9d6845",
          "trafficClass": "MULTIMEDIA_CONFERENCING",
          "tunnel": "false"
        }
      ],
      "networkIdentity": [
        {
          "id": "232c947e-97fd-4571-b9b2-1970fcb6e4ac",
          "instanceId": 14872,
          "authEntityId": 14872,
          "displayName": "14872",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "80,3478,5060",
          "protocol": "TCP",
          "upperPort": 0
        },
        {
          "id": "d2dc2baa-6c74-4785-83f0-9e64b6222e78",
          "instanceId": 14873,
          "authEntityId": 14873,
          "displayName": "14873",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "3478,5060",
          "protocol": "UDP",
          "upperPort": 0
        }
      ],
      "parentScalableGroup": {
        "idRef": "60f1364d-2a02-4063-8ba1-07817abe0612"
      },
      "user": [

      ],
      "userGroup": [

      ]
    },
    {
      "id": "e6fbd562-9c03-4bc0-8baa-6fa4199c828e",
      "instanceId": 4206,
      "authEntityId": 4206,
      "displayName": "4206",
      "authEntityClass": -1909073334,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "createTime": 1517966741721,
      "deployed": false,
      "isSeeded": true,
      "isStale": false,
      "lastUpdateTime": 1517966741721,
      "name": "cableport-ax",
      "namespace": "scalablegroup:application",
      "provisioningState": "DEFINED",
      "qualifier": "application",
      "resourceVersion": 0,
      "targetIdList": [

      ],
      "type": "scalablegroup",
      "cfsChangeInfo": [

      ],
      "customProvisions": [

      ],
      "scalableGroupExternalHandle": "cableport-ax",
      "scalableGroupType": "APPLICATION",
      "securityGroupTag": 0,
      "state": "ACTIVE",
      "vnAgnostic": true,
      "identitySource": {
        "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
        "instanceId": 10010,
        "authEntityId": 10010,
        "displayName": "10010",
        "authEntityClass": 1738953278,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "state": "INACTIVE",
        "type": "NBAR"
      },
      "indicativeNetworkIdentity": [
        {
          "id": "746b17e3-00c4-4560-8e8d-30a69fde4e50",
          "instanceId": 12448,
          "authEntityId": 12448,
          "displayName": "12448",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "282",
          "protocol": "UDP",
          "upperPort": 0
        },
        {
          "id": "922c9c71-1cd4-4c3a-86c9-2dfada16a5cf",
          "instanceId": 12447,
          "authEntityId": 12447,
          "displayName": "12447",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "282",
          "protocol": "TCP",
          "upperPort": 0
        }
      ],
      "networkApplications": [
        {
          "id": "d8cc3eea-71e5-48c8-a7d1-a9eefe136fad",
          "instanceId": 11162,
          "authEntityId": 11162,
          "displayName": "11162",
          "authEntityClass": -217092956,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "appProtocol": "tcp/udp",
          "applicationGroup": "other",
          "applicationType": "DEFAULT",
          "categoryId": "6daa3dfd-3526-407a-88f0-97824b3f3a24",
          "createTime": 1517966741721,
          "encrypted": "false",
          "engineId": "3",
          "helpString": "Cable Port A/X",
          "ignoreConflict": false,
          "lastUpdateTime": 1517966741721,
          "longDescription": "Registered with IANA on port 282 TCP/UDP",
          "name": "cableport-ax",
          "nbarId": "1143",
          "p2pTechnology": "false",
          "popularity": 1,
          "rank": 65535,
          "references": "http://www.iana.org/assignments/service-names-port-numbers/service-names-port-numbers.xml",
          "selectorId": "282",
          "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
          "trafficClass": "BULK_DATA",
          "tunnel": "false"
        }
      ],
      "networkIdentity": [
        {
          "id": "9c5af4d6-ddf9-4064-b7af-fad983ae3c99",
          "instanceId": 12445,
          "authEntityId": 12445,
          "displayName": "12445",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "282",
          "protocol": "UDP",
          "upperPort": 0
        },
        {
          "id": "3dba3bf8-c417-4e9f-a5ad-cc66dd1cf0b9",
          "instanceId": 12446,
          "authEntityId": 12446,
          "displayName": "12446",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "282",
          "protocol": "TCP",
          "upperPort": 0
        }
      ],
      "parentScalableGroup": {
        "idRef": "000c15bf-28fe-4fbb-9b69-35e6502721f1"
      },
      "user": [

      ],
      "userGroup": [

      ]
    },
    {
      "id": "e75a7f8d-30cb-458e-9251-b2c9e8ac591c",
      "instanceId": 4508,
      "authEntityId": 4508,
      "displayName": "4508",
      "authEntityClass": -1909073334,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "createTime": 1517966742843,
      "deployed": false,
      "isSeeded": true,
      "isStale": false,
      "lastUpdateTime": 1517966742843,
      "name": "gre",
      "namespace": "scalablegroup:application",
      "provisioningState": "DEFINED",
      "qualifier": "application",
      "resourceVersion": 0,
      "targetIdList": [

      ],
      "type": "scalablegroup",
      "cfsChangeInfo": [

      ],
      "customProvisions": [

      ],
      "scalableGroupExternalHandle": "gre",
      "scalableGroupType": "APPLICATION",
      "securityGroupTag": 0,
      "state": "ACTIVE",
      "vnAgnostic": true,
      "identitySource": {
        "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
        "instanceId": 10010,
        "authEntityId": 10010,
        "displayName": "10010",
        "authEntityClass": 1738953278,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "state": "INACTIVE",
        "type": "NBAR"
      },
      "indicativeNetworkIdentity": [
        {
          "id": "aaa71a13-a976-44db-b406-171be7c28c64",
          "instanceId": 13260,
          "authEntityId": 13260,
          "displayName": "13260",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipProtocolNumbers": "47",
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "protocol": "GRE",
          "upperPort": 0
        }
      ],
      "networkApplications": [
        {
          "id": "ad3c240c-976d-4973-94d7-bd4e5e25b00e",
          "instanceId": 11459,
          "authEntityId": 11459,
          "displayName": "11459",
          "authEntityClass": -217092956,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "appProtocol": "ip",
          "applicationGroup": "other",
          "applicationType": "DEFAULT",
          "categoryId": "70fce3cd-f2bd-48de-9020-7b7840b9e7a1",
          "createTime": 1517966742843,
          "encrypted": "unassigned",
          "engineId": "1",
          "helpString": "General Routing Encapsulation",
          "ignoreConflict": false,
          "lastUpdateTime": 1517966742843,
          "longDescription": "Generic Routing Encapsulation (GRE) is a protocol used for encapsulation of a network layer over another. The protocol encapsulates the packet and saves the protocol type of the payload packet so the receivers know what network layer was encapsulated, and digests the packet respectively. Usually the protocol uses IP port 47.",
          "name": "gre",
          "nbarId": "5",
          "p2pTechnology": "unassigned",
          "popularity": 9,
          "rank": 65535,
          "references": "http://tools.ietf.org/html/rfc2784",
          "selectorId": "47",
          "subCategory": "ab63e736-9aa7-4946-b55b-3b26ddca609c",
          "trafficClass": "BULK_DATA",
          "tunnel": "unassigned"
        }
      ],
      "networkIdentity": [
        {
          "id": "1fb17433-8e9e-4e63-b726-6ad50fad00ba",
          "instanceId": 13259,
          "authEntityId": 13259,
          "displayName": "13259",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipProtocolNumbers": "47",
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "protocol": "GRE",
          "upperPort": 0
        }
      ],
      "parentScalableGroup": {
        "idRef": "79f9d505-477f-4ccd-bd2a-c5fc8097a2e1"
      },
      "user": [

      ],
      "userGroup": [

      ]
    },
    {
      "id": "e7cbceba-02b3-489b-859f-d6a1d7ba356a",
      "instanceId": 15417,
      "authEntityId": 15417,
      "displayName": "15417",
      "authEntityClass": -1909073334,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "createTime": 1517966744929,
      "deployed": false,
      "isSeeded": true,
      "isStale": false,
      "lastUpdateTime": 1517966744929,
      "name": "windows-azure",
      "namespace": "scalablegroup:application",
      "provisioningState": "DEFINED",
      "qualifier": "application",
      "resourceVersion": 0,
      "targetIdList": [

      ],
      "type": "scalablegroup",
      "cfsChangeInfo": [

      ],
      "customProvisions": [

      ],
      "scalableGroupExternalHandle": "windows-azure",
      "scalableGroupType": "APPLICATION",
      "securityGroupTag": 0,
      "state": "ACTIVE",
      "vnAgnostic": true,
      "identitySource": {
        "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
        "instanceId": 10010,
        "authEntityId": 10010,
        "displayName": "10010",
        "authEntityClass": 1738953278,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "state": "INACTIVE",
        "type": "NBAR"
      },
      "indicativeNetworkIdentity": [

      ],
      "networkApplications": [
        {
          "id": "f02bd3ef-c401-4e1e-b3c2-c64136090862",
          "instanceId": 16360,
          "authEntityId": 16360,
          "displayName": "16360",
          "authEntityClass": -217092956,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "appProtocol": "tcp/udp",
          "applicationGroup": "other",
          "applicationType": "DEFAULT",
          "categoryId": "80909d4c-efe6-4878-9e1e-68281fd26edd",
          "createTime": 1517966744929,
          "encrypted": "true",
          "engineId": "13",
          "helpString": "Microsoft Windows Azure",
          "ignoreConflict": false,
          "lastUpdateTime": 1517966744929,
          "longDescription": "Windows Azure is an open and flexible cloud platform that enables users to build, deploy, and manage applications across a global network of Microsoft-managed data centers. Users can build applications using any language tool or framework, and can integrate thier public cloud applications with their existing IT environment.",
          "name": "windows-azure",
          "nbarId": "1445",
          "p2pTechnology": "false",
          "popularity": 7,
          "rank": 65535,
          "references": "https://www.windowsazure.com/en-us/",
          "selectorId": "510",
          "subCategory": "8320328d-5e88-43b4-a6f1-d148749ef1a3",
          "trafficClass": "BULK_DATA",
          "tunnel": "false"
        }
      ],
      "networkIdentity": [
        {
          "id": "bf590166-0300-4b29-b689-232a37110a54",
          "instanceId": 17845,
          "authEntityId": 17845,
          "displayName": "17845",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "53,80,443,5353",
          "protocol": "TCP",
          "upperPort": 0
        },
        {
          "id": "21480cde-9f62-4afc-b349-1745f7ad66ed",
          "instanceId": 17846,
          "authEntityId": 17846,
          "displayName": "17846",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "53,5353",
          "protocol": "UDP",
          "upperPort": 0
        }
      ],
      "parentScalableGroup": {
        "idRef": "53f03553-069a-4046-ad7c-84610eb579c5"
      },
      "user": [

      ],
      "userGroup": [

      ]
    },
    {
      "id": "e82dfb58-eb44-49c5-a3b6-c15227f3e3e5",
      "instanceId": 4944,
      "authEntityId": 4944,
      "displayName": "4944",
      "authEntityClass": -1909073334,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "createTime": 1517966743868,
      "deployed": false,
      "isSeeded": true,
      "isStale": false,
      "lastUpdateTime": 1517966743868,
      "name": "pch-com",
      "namespace": "scalablegroup:application",
      "provisioningState": "DEFINED",
      "qualifier": "application",
      "resourceVersion": 0,
      "targetIdList": [

      ],
      "type": "scalablegroup",
      "cfsChangeInfo": [

      ],
      "customProvisions": [

      ],
      "scalableGroupExternalHandle": "pch-com",
      "scalableGroupType": "APPLICATION",
      "securityGroupTag": 0,
      "state": "ACTIVE",
      "vnAgnostic": true,
      "identitySource": {
        "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
        "instanceId": 10010,
        "authEntityId": 10010,
        "displayName": "10010",
        "authEntityClass": 1738953278,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "state": "INACTIVE",
        "type": "NBAR"
      },
      "indicativeNetworkIdentity": [

      ],
      "networkApplications": [
        {
          "id": "0d158cd0-77de-4b20-9c44-0ddb95daaf8e",
          "instanceId": 11893,
          "authEntityId": 11893,
          "displayName": "11893",
          "authEntityClass": -217092956,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "appProtocol": "tcp/udp",
          "applicationGroup": "other",
          "applicationType": "DEFAULT",
          "categoryId": "878644b5-fe91-45b4-9204-31c84a73ba48",
          "createTime": 1517966743868,
          "encrypted": "false",
          "engineId": "13",
          "helpString": "Publishers Clearing House - Online marketing company.",
          "ignoreConflict": false,
          "lastUpdateTime": 1517966743868,
          "longDescription": "Publishers Clearing House - Online marketing company.",
          "name": "pch-com",
          "nbarId": "1709",
          "p2pTechnology": "false",
          "popularity": 3,
          "rank": 65535,
          "references": "http://pch.com",
          "selectorId": "781",
          "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
          "trafficClass": "TRANSACTIONAL_DATA",
          "tunnel": "false"
        }
      ],
      "networkIdentity": [
        {
          "id": "98eff8c5-ef3a-4f5d-ab8b-8a843b86849d",
          "instanceId": 14493,
          "authEntityId": 14493,
          "displayName": "14493",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "53,5353",
          "protocol": "UDP",
          "upperPort": 0
        },
        {
          "id": "6ddc0056-ab30-475c-8e09-32dc8c515607",
          "instanceId": 14494,
          "authEntityId": 14494,
          "displayName": "14494",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "53,80,443,5353",
          "protocol": "TCP",
          "upperPort": 0
        }
      ],
      "parentScalableGroup": {
        "idRef": "28e08de4-d7b2-4521-84ce-bc1e5ee0b9cc"
      },
      "user": [

      ],
      "userGroup": [

      ]
    },
    {
      "id": "e8625a68-1b5e-461e-8d88-b55898415502",
      "instanceId": 15048,
      "authEntityId": 15048,
      "displayName": "15048",
      "authEntityClass": -1909073334,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "createTime": 1517966744113,
      "deployed": false,
      "isSeeded": true,
      "isStale": false,
      "lastUpdateTime": 1517966744113,
      "name": "ripng",
      "namespace": "scalablegroup:application",
      "provisioningState": "DEFINED",
      "qualifier": "application",
      "resourceVersion": 0,
      "targetIdList": [

      ],
      "type": "scalablegroup",
      "cfsChangeInfo": [

      ],
      "customProvisions": [

      ],
      "scalableGroupExternalHandle": "ripng",
      "scalableGroupType": "APPLICATION",
      "securityGroupTag": 0,
      "state": "ACTIVE",
      "vnAgnostic": true,
      "identitySource": {
        "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
        "instanceId": 10010,
        "authEntityId": 10010,
        "displayName": "10010",
        "authEntityClass": 1738953278,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "state": "INACTIVE",
        "type": "NBAR"
      },
      "indicativeNetworkIdentity": [
        {
          "id": "81eef244-aada-4753-bb14-46fbdff8c290",
          "instanceId": 14775,
          "authEntityId": 14775,
          "displayName": "14775",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "521",
          "protocol": "UDP",
          "upperPort": 0
        },
        {
          "id": "f990e9d1-f133-4302-90e6-d19ee1c2a002",
          "instanceId": 14776,
          "authEntityId": 14776,
          "displayName": "14776",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "521",
          "protocol": "TCP",
          "upperPort": 0
        }
      ],
      "networkApplications": [
        {
          "id": "c49af964-8b72-4673-8560-50b28d96f790",
          "instanceId": 11987,
          "authEntityId": 11987,
          "displayName": "11987",
          "authEntityClass": -217092956,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "appProtocol": "tcp/udp",
          "applicationGroup": "other",
          "applicationType": "DEFAULT",
          "categoryId": "1fc17362-9334-4c7b-b752-5afdbb574017",
          "createTime": 1517966744113,
          "encrypted": "false",
          "engineId": "3",
          "helpString": "Routing information protocol for IPV6 networks",
          "ignoreConflict": false,
          "lastUpdateTime": 1517966744113,
          "longDescription": "RIPng is a Routing Information Protocol enhancement for IPV6 based networks. The routing protocol uses a distance-vector algorithm to determine best route to destination,  and its purpose is to allow routers to exchange information for computing routes. The protocol is based on UDP and typically uses port  521.",
          "name": "ripng",
          "nbarId": "439",
          "p2pTechnology": "false",
          "popularity": 1,
          "rank": 65535,
          "references": "http://tools.ietf.org/html/rfc2080",
          "selectorId": "521",
          "subCategory": "7cc1b3a6-4102-44e9-b522-0e71afe39a03",
          "trafficClass": "NETWORK_CONTROL",
          "tunnel": "false"
        }
      ],
      "networkIdentity": [
        {
          "id": "b6ac209a-3b4b-4789-8619-4439f6385586",
          "instanceId": 14773,
          "authEntityId": 14773,
          "displayName": "14773",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "521",
          "protocol": "UDP",
          "upperPort": 0
        },
        {
          "id": "521e0e3d-1f3a-45ac-98b9-678f1fa44f9d",
          "instanceId": 14774,
          "authEntityId": 14774,
          "displayName": "14774",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "521",
          "protocol": "TCP",
          "upperPort": 0
        }
      ],
      "parentScalableGroup": {
        "idRef": "77e75842-e98b-4112-ad0c-300db4bb11be"
      },
      "user": [

      ],
      "userGroup": [

      ]
    },
    {
      "id": "e89be0cd-9b76-4aab-af25-bb6e687e432e",
      "instanceId": 4298,
      "authEntityId": 4298,
      "displayName": "4298",
      "authEntityClass": -1909073334,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "createTime": 1517966742196,
      "deployed": false,
      "isSeeded": true,
      "isStale": false,
      "lastUpdateTime": 1517966742196,
      "name": "cpq-wbem",
      "namespace": "scalablegroup:application",
      "provisioningState": "DEFINED",
      "qualifier": "application",
      "resourceVersion": 0,
      "targetIdList": [

      ],
      "type": "scalablegroup",
      "cfsChangeInfo": [

      ],
      "customProvisions": [

      ],
      "scalableGroupExternalHandle": "cpq-wbem",
      "scalableGroupType": "APPLICATION",
      "securityGroupTag": 0,
      "state": "ACTIVE",
      "vnAgnostic": true,
      "identitySource": {
        "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
        "instanceId": 10010,
        "authEntityId": 10010,
        "displayName": "10010",
        "authEntityClass": 1738953278,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "state": "INACTIVE",
        "type": "NBAR"
      },
      "indicativeNetworkIdentity": [
        {
          "id": "1e943a75-c84a-4a7d-8b4b-7a2cfc6e53b9",
          "instanceId": 12667,
          "authEntityId": 12667,
          "displayName": "12667",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "2301",
          "protocol": "TCP",
          "upperPort": 0
        },
        {
          "id": "b1a3cbf6-8caa-460c-b848-530c0cfec018",
          "instanceId": 12668,
          "authEntityId": 12668,
          "displayName": "12668",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "2301",
          "protocol": "UDP",
          "upperPort": 0
        }
      ],
      "networkApplications": [
        {
          "id": "5620a53c-99e3-4ceb-886f-3fde085ab335",
          "instanceId": 11251,
          "authEntityId": 11251,
          "displayName": "11251",
          "authEntityClass": -217092956,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "appProtocol": "tcp/udp",
          "applicationGroup": "other",
          "applicationType": "DEFAULT",
          "categoryId": "7e19fdea-6222-4c57-9e66-66c2340d6bc3",
          "createTime": 1517966742196,
          "encrypted": "false",
          "engineId": "3",
          "helpString": "Compaq HTTP",
          "ignoreConflict": false,
          "lastUpdateTime": 1517966742196,
          "longDescription": "Compaq Insight Manager Service (cpq-wbem) is a service used by the Compaq Insight Manager software. In 2002 Compaq was aquired by HP, and the Insight Manager software is now known as HP Insight Control.",
          "name": "cpq-wbem",
          "nbarId": "1336",
          "p2pTechnology": "false",
          "popularity": 1,
          "rank": 65535,
          "references": "http://h18000.www1.hp.com/products/servers/management/index.html",
          "selectorId": "2301",
          "subCategory": "f3c0f761-d894-4112-ab89-b90fc84fd660",
          "trafficClass": "OPS_ADMIN_MGMT",
          "tunnel": "false"
        }
      ],
      "networkIdentity": [
        {
          "id": "0d3af41a-7223-4d57-8e8e-d4266361a359",
          "instanceId": 12665,
          "authEntityId": 12665,
          "displayName": "12665",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "2301",
          "protocol": "UDP",
          "upperPort": 0
        },
        {
          "id": "84f4c7b2-5988-4ce8-bc07-67121ae7d325",
          "instanceId": 12666,
          "authEntityId": 12666,
          "displayName": "12666",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "2301",
          "protocol": "TCP",
          "upperPort": 0
        }
      ],
      "parentScalableGroup": {
        "idRef": "5fcd74d4-8726-47f7-a088-c457863da7ef"
      },
      "user": [

      ],
      "userGroup": [

      ]
    },
    {
      "id": "e8aebd44-0e00-4b7d-acee-3fef587d5728",
      "instanceId": 15285,
      "authEntityId": 15285,
      "displayName": "15285",
      "authEntityClass": -1909073334,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "createTime": 1517966744657,
      "deployed": false,
      "isSeeded": true,
      "isStale": false,
      "lastUpdateTime": 1517966744657,
      "name": "timbuktu",
      "namespace": "scalablegroup:application",
      "provisioningState": "DEFINED",
      "qualifier": "application",
      "resourceVersion": 0,
      "targetIdList": [

      ],
      "type": "scalablegroup",
      "cfsChangeInfo": [

      ],
      "customProvisions": [

      ],
      "scalableGroupExternalHandle": "timbuktu",
      "scalableGroupType": "APPLICATION",
      "securityGroupTag": 0,
      "state": "ACTIVE",
      "vnAgnostic": true,
      "identitySource": {
        "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
        "instanceId": 10010,
        "authEntityId": 10010,
        "displayName": "10010",
        "authEntityClass": 1738953278,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "state": "INACTIVE",
        "type": "NBAR"
      },
      "indicativeNetworkIdentity": [
        {
          "id": "52bdebfe-faa0-49f7-8b0e-19a60a83f9f7",
          "instanceId": 17483,
          "authEntityId": 17483,
          "displayName": "17483",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "407",
          "protocol": "UDP",
          "upperPort": 0
        },
        {
          "id": "f520c5db-ea69-49f1-a01b-bc5039b6702b",
          "instanceId": 17484,
          "authEntityId": 17484,
          "displayName": "17484",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "407",
          "protocol": "TCP",
          "upperPort": 0
        }
      ],
      "networkApplications": [
        {
          "id": "4817f893-b409-42f6-84c5-bcc7b0959ebe",
          "instanceId": 16228,
          "authEntityId": 16228,
          "displayName": "16228",
          "authEntityClass": -217092956,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "appProtocol": "tcp/udp",
          "applicationGroup": "other",
          "applicationType": "DEFAULT",
          "categoryId": "7e19fdea-6222-4c57-9e66-66c2340d6bc3",
          "createTime": 1517966744656,
          "encrypted": "false",
          "engineId": "3",
          "helpString": "Timbuktu",
          "ignoreConflict": false,
          "lastUpdateTime": 1517966744656,
          "longDescription": "Timbuktu is a remote control software product developed by WOS Datasystems. Timbuktu is compatible with computers running both Mac OS X and Windows.",
          "name": "timbuktu",
          "nbarId": "322",
          "p2pTechnology": "false",
          "popularity": 1,
          "rank": 65535,
          "references": "http://www.motorola.com/Video-Solutions/US-EN/Products-and-Services/Software/Remote-Access/Timbuktu+Pro_US-EN",
          "selectorId": "407",
          "subCategory": "ff664205-64f3-447e-acff-1ec1ba9a6c31",
          "trafficClass": "MULTIMEDIA_STREAMING",
          "tunnel": "false"
        }
      ],
      "networkIdentity": [
        {
          "id": "d4a9289a-a2b7-442f-a117-fc135f2069b7",
          "instanceId": 17481,
          "authEntityId": 17481,
          "displayName": "17481",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "407",
          "protocol": "UDP",
          "upperPort": 0
        },
        {
          "id": "a13f2019-a8be-4d1f-87d5-6b107155e465",
          "instanceId": 17482,
          "authEntityId": 17482,
          "displayName": "17482",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "407",
          "protocol": "TCP",
          "upperPort": 0
        }
      ],
      "parentScalableGroup": {
        "idRef": "4a6b37c6-7e11-40fe-a6b1-719b24813145"
      },
      "user": [

      ],
      "userGroup": [

      ]
    },
    {
      "id": "e8b5afbb-5826-4ab3-9d6d-af74b86b2670",
      "instanceId": 4582,
      "authEntityId": 4582,
      "displayName": "4582",
      "authEntityClass": -1909073334,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "createTime": 1517966743048,
      "deployed": false,
      "isSeeded": true,
      "isStale": false,
      "lastUpdateTime": 1517966743048,
      "name": "icmp",
      "namespace": "scalablegroup:application",
      "provisioningState": "DEFINED",
      "qualifier": "application",
      "resourceVersion": 0,
      "targetIdList": [

      ],
      "type": "scalablegroup",
      "cfsChangeInfo": [

      ],
      "customProvisions": [

      ],
      "scalableGroupExternalHandle": "icmp",
      "scalableGroupType": "APPLICATION",
      "securityGroupTag": 0,
      "state": "ACTIVE",
      "vnAgnostic": true,
      "identitySource": {
        "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
        "instanceId": 10010,
        "authEntityId": 10010,
        "displayName": "10010",
        "authEntityClass": 1738953278,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "state": "INACTIVE",
        "type": "NBAR"
      },
      "indicativeNetworkIdentity": [
        {
          "id": "2ae0e869-a5a6-4aba-b31c-45453040e30d",
          "instanceId": 13466,
          "authEntityId": 13466,
          "displayName": "13466",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipProtocolNumbers": "1",
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "protocol": "ICMP",
          "upperPort": 0
        }
      ],
      "networkApplications": [
        {
          "id": "560e2d54-9f4f-45e9-95c8-a3f39eb05704",
          "instanceId": 11532,
          "authEntityId": 11532,
          "displayName": "11532",
          "authEntityClass": -217092956,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "appProtocol": "ip",
          "applicationGroup": "other",
          "applicationType": "DEFAULT",
          "categoryId": "1fc17362-9334-4c7b-b752-5afdbb574017",
          "createTime": 1517966743047,
          "encrypted": "false",
          "engineId": "1",
          "helpString": "Internet Control Message Protocol",
          "ignoreConflict": false,
          "lastUpdateTime": 1517966743047,
          "longDescription": "Internet Control Message Protocol (ICMP) messages are typically generated in response to errors in IP datagrams or for diagnostic or routing purposes. ICMP errors are always reported to the original source IP address of the originating datagram. ICMP is IP protocol number 1. Traffic is classified only if its identified as ICMP but was not recognized as any other more granular classification such as Ping.",
          "name": "icmp",
          "nbarId": "6",
          "p2pTechnology": "false",
          "popularity": 2,
          "rank": 65535,
          "references": "http://tools.ietf.org/html/rfc792",
          "selectorId": "1",
          "subCategory": "f3c0f761-d894-4112-ab89-b90fc84fd660",
          "trafficClass": "OPS_ADMIN_MGMT",
          "tunnel": "false"
        }
      ],
      "networkIdentity": [
        {
          "id": "259e2aa6-26aa-477f-b4ac-04433a0921d8",
          "instanceId": 13465,
          "authEntityId": 13465,
          "displayName": "13465",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipProtocolNumbers": "1",
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "protocol": "ICMP",
          "upperPort": 0
        }
      ],
      "parentScalableGroup": {
        "idRef": "5fcd74d4-8726-47f7-a088-c457863da7ef"
      },
      "user": [

      ],
      "userGroup": [

      ]
    },
    {
      "id": "e9006e86-9a37-4c2a-8e78-a097f5004fb6",
      "instanceId": 4686,
      "authEntityId": 4686,
      "displayName": "4686",
      "authEntityClass": -1909073334,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "createTime": 1517966743282,
      "deployed": false,
      "isSeeded": true,
      "isStale": false,
      "lastUpdateTime": 1517966743282,
      "name": "lanserver",
      "namespace": "scalablegroup:application",
      "provisioningState": "DEFINED",
      "qualifier": "application",
      "resourceVersion": 0,
      "targetIdList": [

      ],
      "type": "scalablegroup",
      "cfsChangeInfo": [

      ],
      "customProvisions": [

      ],
      "scalableGroupExternalHandle": "lanserver",
      "scalableGroupType": "APPLICATION",
      "securityGroupTag": 0,
      "state": "ACTIVE",
      "vnAgnostic": true,
      "identitySource": {
        "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
        "instanceId": 10010,
        "authEntityId": 10010,
        "displayName": "10010",
        "authEntityClass": 1738953278,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "state": "INACTIVE",
        "type": "NBAR"
      },
      "indicativeNetworkIdentity": [
        {
          "id": "6ea2a71f-8b0f-4eec-bf6b-141c92e0f8be",
          "instanceId": 13732,
          "authEntityId": 13732,
          "displayName": "13732",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "637",
          "protocol": "TCP",
          "upperPort": 0
        },
        {
          "id": "397283ce-add2-4d13-8025-197bd8571018",
          "instanceId": 13733,
          "authEntityId": 13733,
          "displayName": "13733",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "637",
          "protocol": "UDP",
          "upperPort": 0
        }
      ],
      "networkApplications": [
        {
          "id": "ca58c23a-4644-454b-886e-1c807d220aa9",
          "instanceId": 11636,
          "authEntityId": 11636,
          "displayName": "11636",
          "authEntityClass": -217092956,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "appProtocol": "tcp/udp",
          "applicationGroup": "ldap-group",
          "applicationType": "DEFAULT",
          "categoryId": "1fc17362-9334-4c7b-b752-5afdbb574017",
          "createTime": 1517966743282,
          "encrypted": "true",
          "engineId": "3",
          "helpString": "lanserver",
          "ignoreConflict": false,
          "lastUpdateTime": 1517966743282,
          "longDescription": "Registered with IANA on port 637 TCP/UDP",
          "name": "lanserver",
          "nbarId": "546",
          "p2pTechnology": "false",
          "popularity": 1,
          "rank": 65535,
          "references": "http://www.iana.org/assignments/service-names-port-numbers/service-names-port-numbers.xml",
          "selectorId": "637",
          "subCategory": "f3c0f761-d894-4112-ab89-b90fc84fd660",
          "trafficClass": "OPS_ADMIN_MGMT",
          "tunnel": "false"
        }
      ],
      "networkIdentity": [
        {
          "id": "98380aea-0649-46a9-96cd-97736e356fef",
          "instanceId": 13730,
          "authEntityId": 13730,
          "displayName": "13730",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "637",
          "protocol": "UDP",
          "upperPort": 0
        },
        {
          "id": "c909ea9f-c204-43ff-a6fb-4d9f36b5a805",
          "instanceId": 13731,
          "authEntityId": 13731,
          "displayName": "13731",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "637",
          "protocol": "TCP",
          "upperPort": 0
        }
      ],
      "parentScalableGroup": {
        "idRef": "5fcd74d4-8726-47f7-a088-c457863da7ef"
      },
      "user": [

      ],
      "userGroup": [

      ]
    },
    {
      "id": "e92700f0-55e9-44c6-af24-5e5a397d3068",
      "instanceId": 4938,
      "authEntityId": 4938,
      "displayName": "4938",
      "authEntityClass": -1909073334,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "createTime": 1517966743852,
      "deployed": false,
      "isSeeded": true,
      "isStale": false,
      "lastUpdateTime": 1517966743852,
      "name": "password-chg",
      "namespace": "scalablegroup:application",
      "provisioningState": "DEFINED",
      "qualifier": "application",
      "resourceVersion": 0,
      "targetIdList": [

      ],
      "type": "scalablegroup",
      "cfsChangeInfo": [

      ],
      "customProvisions": [

      ],
      "scalableGroupExternalHandle": "password-chg",
      "scalableGroupType": "APPLICATION",
      "securityGroupTag": 0,
      "state": "ACTIVE",
      "vnAgnostic": true,
      "identitySource": {
        "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
        "instanceId": 10010,
        "authEntityId": 10010,
        "displayName": "10010",
        "authEntityClass": 1738953278,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "state": "INACTIVE",
        "type": "NBAR"
      },
      "indicativeNetworkIdentity": [
        {
          "id": "9f8609c5-fb92-4ac2-8c94-7010d4524769",
          "instanceId": 14481,
          "authEntityId": 14481,
          "displayName": "14481",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "586",
          "protocol": "UDP",
          "upperPort": 0
        },
        {
          "id": "449e09ed-8e4e-40e4-ab87-dbd25696971e",
          "instanceId": 14482,
          "authEntityId": 14482,
          "displayName": "14482",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "586",
          "protocol": "TCP",
          "upperPort": 0
        }
      ],
      "networkApplications": [
        {
          "id": "21cfd61a-6c8d-4e29-8f9e-65f4ba8ebe58",
          "instanceId": 11887,
          "authEntityId": 11887,
          "displayName": "11887",
          "authEntityClass": -217092956,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "appProtocol": "tcp/udp",
          "applicationGroup": "other",
          "applicationType": "DEFAULT",
          "categoryId": "1fc17362-9334-4c7b-b752-5afdbb574017",
          "createTime": 1517966743852,
          "encrypted": "false",
          "engineId": "3",
          "helpString": "Password Change",
          "ignoreConflict": false,
          "lastUpdateTime": 1517966743852,
          "longDescription": "The Change Password service is a protocol provider that services Kerberos Change Password and Set Password Protocol requests. Change Password is a request-reply protocol that uses Kerberos infrastructure to allow users to securely set initial passwords or to change existing passwords. The Change Password protocol interoperates with the original Kerberos Change Password protocol, while adding the ability for an administrator to set a password for a new user.",
          "name": "password-chg",
          "nbarId": "500",
          "p2pTechnology": "false",
          "popularity": 1,
          "rank": 65535,
          "references": "http://directory.apache.org/apacheds/1.5/55-change-password-protocol-provider.html",
          "selectorId": "586",
          "subCategory": "f3c0f761-d894-4112-ab89-b90fc84fd660",
          "trafficClass": "OPS_ADMIN_MGMT",
          "tunnel": "false"
        }
      ],
      "networkIdentity": [
        {
          "id": "816e5c58-be3a-4683-a0d5-b5c037325736",
          "instanceId": 14480,
          "authEntityId": 14480,
          "displayName": "14480",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "586",
          "protocol": "UDP",
          "upperPort": 0
        },
        {
          "id": "52175fe2-acc2-450c-b509-645a05d2b765",
          "instanceId": 14479,
          "authEntityId": 14479,
          "displayName": "14479",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "586",
          "protocol": "TCP",
          "upperPort": 0
        }
      ],
      "parentScalableGroup": {
        "idRef": "5fcd74d4-8726-47f7-a088-c457863da7ef"
      },
      "user": [

      ],
      "userGroup": [

      ]
    },
    {
      "id": "e9d2aaeb-b6af-4e36-85c8-4fcb03eb0446",
      "instanceId": 15200,
      "authEntityId": 15200,
      "displayName": "15200",
      "authEntityClass": -1909073334,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "createTime": 1517966744468,
      "deployed": false,
      "isSeeded": true,
      "isStale": false,
      "lastUpdateTime": 1517966744468,
      "name": "src",
      "namespace": "scalablegroup:application",
      "provisioningState": "DEFINED",
      "qualifier": "application",
      "resourceVersion": 0,
      "targetIdList": [

      ],
      "type": "scalablegroup",
      "cfsChangeInfo": [

      ],
      "customProvisions": [

      ],
      "scalableGroupExternalHandle": "src",
      "scalableGroupType": "APPLICATION",
      "securityGroupTag": 0,
      "state": "ACTIVE",
      "vnAgnostic": true,
      "identitySource": {
        "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
        "instanceId": 10010,
        "authEntityId": 10010,
        "displayName": "10010",
        "authEntityClass": 1738953278,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "state": "INACTIVE",
        "type": "NBAR"
      },
      "indicativeNetworkIdentity": [
        {
          "id": "aa88e19e-ace7-4e22-9353-cef7674387f4",
          "instanceId": 17240,
          "authEntityId": 17240,
          "displayName": "17240",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "200",
          "protocol": "UDP",
          "upperPort": 0
        },
        {
          "id": "11232037-1af4-4822-b996-2b10a49ab613",
          "instanceId": 17241,
          "authEntityId": 17241,
          "displayName": "17241",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "200",
          "protocol": "TCP",
          "upperPort": 0
        }
      ],
      "networkApplications": [
        {
          "id": "739521b1-679c-4ca0-ac9f-4c9b3ad05b44",
          "instanceId": 16143,
          "authEntityId": 16143,
          "displayName": "16143",
          "authEntityClass": -217092956,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "appProtocol": "tcp/udp",
          "applicationGroup": "other",
          "applicationType": "DEFAULT",
          "categoryId": "1fc17362-9334-4c7b-b752-5afdbb574017",
          "createTime": 1517966744467,
          "encrypted": "false",
          "engineId": "3",
          "helpString": "IBM System Resource Controller",
          "ignoreConflict": false,
          "lastUpdateTime": 1517966744467,
          "longDescription": "The System Resource Controller (SRC) provides a set of commands and subroutines to make it easier for the system manager and programmer to create and control subsystems.",
          "name": "src",
          "nbarId": "1098",
          "p2pTechnology": "false",
          "popularity": 1,
          "rank": 65535,
          "references": "http://pic.dhe.ibm.com/infocenter/aix/v6r1/index.jsp?topic=%2Fcom.ibm.aix.baseadmn%2Fdoc%2Fbaseadmndita%2Fsysrescon.htm",
          "selectorId": "200",
          "subCategory": "f3c0f761-d894-4112-ab89-b90fc84fd660",
          "trafficClass": "OPS_ADMIN_MGMT",
          "tunnel": "false"
        }
      ],
      "networkIdentity": [
        {
          "id": "4624ed8a-b45d-41af-9b54-abd13a8718a5",
          "instanceId": 17238,
          "authEntityId": 17238,
          "displayName": "17238",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "200",
          "protocol": "TCP",
          "upperPort": 0
        },
        {
          "id": "9207d52a-ad36-448c-8198-0ab5899ee6bd",
          "instanceId": 17239,
          "authEntityId": 17239,
          "displayName": "17239",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "200",
          "protocol": "UDP",
          "upperPort": 0
        }
      ],
      "parentScalableGroup": {
        "idRef": "5fcd74d4-8726-47f7-a088-c457863da7ef"
      },
      "user": [

      ],
      "userGroup": [

      ]
    },
    {
      "id": "ea690694-ef98-411a-a7ab-601a9e754bbc",
      "instanceId": 4336,
      "authEntityId": 4336,
      "displayName": "4336",
      "authEntityClass": -1909073334,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "createTime": 1517966742326,
      "deployed": false,
      "isSeeded": true,
      "isStale": false,
      "lastUpdateTime": 1517966742326,
      "name": "dcp",
      "namespace": "scalablegroup:application",
      "provisioningState": "DEFINED",
      "qualifier": "application",
      "resourceVersion": 0,
      "targetIdList": [

      ],
      "type": "scalablegroup",
      "cfsChangeInfo": [

      ],
      "customProvisions": [

      ],
      "scalableGroupExternalHandle": "dcp",
      "scalableGroupType": "APPLICATION",
      "securityGroupTag": 0,
      "state": "ACTIVE",
      "vnAgnostic": true,
      "identitySource": {
        "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
        "instanceId": 10010,
        "authEntityId": 10010,
        "displayName": "10010",
        "authEntityClass": 1738953278,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "state": "INACTIVE",
        "type": "NBAR"
      },
      "indicativeNetworkIdentity": [
        {
          "id": "0ea43d1d-cc32-4f9f-8016-4ae086a9958a",
          "instanceId": 12780,
          "authEntityId": 12780,
          "displayName": "12780",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "93",
          "protocol": "TCP",
          "upperPort": 0
        },
        {
          "id": "fde181e7-2343-423d-8e42-22e8b202b38a",
          "instanceId": 12781,
          "authEntityId": 12781,
          "displayName": "12781",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "93",
          "protocol": "UDP",
          "upperPort": 0
        }
      ],
      "networkApplications": [
        {
          "id": "6491b6a4-657e-4fe7-97ca-431080764b61",
          "instanceId": 11287,
          "authEntityId": 11287,
          "displayName": "11287",
          "authEntityClass": -217092956,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "appProtocol": "tcp/udp",
          "applicationGroup": "other",
          "applicationType": "DEFAULT",
          "categoryId": "1fc17362-9334-4c7b-b752-5afdbb574017",
          "createTime": 1517966742326,
          "encrypted": "false",
          "engineId": "3",
          "helpString": "Device Control Protocol",
          "ignoreConflict": false,
          "lastUpdateTime": 1517966742326,
          "longDescription": "The Device Control Protocol (DCP) is an application level protocol optimized for the integration, monitoring and control of devices on a network. It provides a framework for integrating unconventional network devices attached to networks either directly or as computer periphery.",
          "name": "dcp",
          "nbarId": "964",
          "p2pTechnology": "false",
          "popularity": 1,
          "rank": 65535,
          "references": "http://dcp.chrisarmbruster.com/Introduction.html",
          "selectorId": "93",
          "subCategory": "f3c0f761-d894-4112-ab89-b90fc84fd660",
          "trafficClass": "OPS_ADMIN_MGMT",
          "tunnel": "false"
        }
      ],
      "networkIdentity": [
        {
          "id": "2f4baf80-723c-4c97-a0ba-16e91dcebd26",
          "instanceId": 12778,
          "authEntityId": 12778,
          "displayName": "12778",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "93",
          "protocol": "UDP",
          "upperPort": 0
        },
        {
          "id": "a78108d3-3575-4c0d-a007-3f4406e045b1",
          "instanceId": 12779,
          "authEntityId": 12779,
          "displayName": "12779",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "93",
          "protocol": "TCP",
          "upperPort": 0
        }
      ],
      "parentScalableGroup": {
        "idRef": "5fcd74d4-8726-47f7-a088-c457863da7ef"
      },
      "user": [

      ],
      "userGroup": [

      ]
    },
    {
      "id": "ea7ea772-e383-4ad2-83c1-3cb44eb8c61b",
      "instanceId": 4850,
      "authEntityId": 4850,
      "displayName": "4850",
      "authEntityClass": -1909073334,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "createTime": 1517966743613,
      "deployed": false,
      "isSeeded": true,
      "isStale": false,
      "lastUpdateTime": 1517966743613,
      "name": "netsc-prod",
      "namespace": "scalablegroup:application",
      "provisioningState": "DEFINED",
      "qualifier": "application",
      "resourceVersion": 0,
      "targetIdList": [

      ],
      "type": "scalablegroup",
      "cfsChangeInfo": [

      ],
      "customProvisions": [

      ],
      "scalableGroupExternalHandle": "netsc-prod",
      "scalableGroupType": "APPLICATION",
      "securityGroupTag": 0,
      "state": "ACTIVE",
      "vnAgnostic": true,
      "identitySource": {
        "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
        "instanceId": 10010,
        "authEntityId": 10010,
        "displayName": "10010",
        "authEntityClass": 1738953278,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "state": "INACTIVE",
        "type": "NBAR"
      },
      "indicativeNetworkIdentity": [
        {
          "id": "903afe93-07d0-45f5-9f38-1e78fe3966f9",
          "instanceId": 14186,
          "authEntityId": 14186,
          "displayName": "14186",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "154",
          "protocol": "UDP",
          "upperPort": 0
        },
        {
          "id": "4561f0a8-899f-438f-9fe1-cd4e95fa7e86",
          "instanceId": 14187,
          "authEntityId": 14187,
          "displayName": "14187",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "154",
          "protocol": "TCP",
          "upperPort": 0
        }
      ],
      "networkApplications": [
        {
          "id": "537d0254-d6a9-40db-895e-e00708f6cd5f",
          "instanceId": 11799,
          "authEntityId": 11799,
          "displayName": "11799",
          "authEntityClass": -217092956,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "appProtocol": "tcp/udp",
          "applicationGroup": "other",
          "applicationType": "DEFAULT",
          "categoryId": "6daa3dfd-3526-407a-88f0-97824b3f3a24",
          "createTime": 1517966743613,
          "encrypted": "false",
          "engineId": "3",
          "helpString": "NETSC",
          "ignoreConflict": false,
          "lastUpdateTime": 1517966743613,
          "longDescription": "Registered with IANA on port 154 TCP/UDP",
          "name": "netsc-prod",
          "nbarId": "1001",
          "p2pTechnology": "false",
          "popularity": 1,
          "rank": 65535,
          "references": "http://www.iana.org/assignments/service-names-port-numbers/service-names-port-numbers.xml",
          "selectorId": "154",
          "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
          "trafficClass": "BULK_DATA",
          "tunnel": "false"
        }
      ],
      "networkIdentity": [
        {
          "id": "a2fd8d2c-0694-4026-9c04-c8dcce3bd959",
          "instanceId": 14184,
          "authEntityId": 14184,
          "displayName": "14184",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "154",
          "protocol": "TCP",
          "upperPort": 0
        },
        {
          "id": "cf4645f3-32a5-44f8-9b4a-ed3fd872b3b3",
          "instanceId": 14185,
          "authEntityId": 14185,
          "displayName": "14185",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "154",
          "protocol": "UDP",
          "upperPort": 0
        }
      ],
      "parentScalableGroup": {
        "idRef": "000c15bf-28fe-4fbb-9b69-35e6502721f1"
      },
      "user": [

      ],
      "userGroup": [

      ]
    },
    {
      "id": "ea85f627-244d-421e-a4ad-1154d34db2e5",
      "instanceId": 5002,
      "authEntityId": 5002,
      "displayName": "5002",
      "authEntityClass": -1909073334,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "createTime": 1517966744023,
      "deployed": false,
      "isSeeded": true,
      "isStale": false,
      "lastUpdateTime": 1517966744023,
      "name": "qft",
      "namespace": "scalablegroup:application",
      "provisioningState": "DEFINED",
      "qualifier": "application",
      "resourceVersion": 0,
      "targetIdList": [

      ],
      "type": "scalablegroup",
      "cfsChangeInfo": [

      ],
      "customProvisions": [

      ],
      "scalableGroupExternalHandle": "qft",
      "scalableGroupType": "APPLICATION",
      "securityGroupTag": 0,
      "state": "ACTIVE",
      "vnAgnostic": true,
      "identitySource": {
        "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
        "instanceId": 10010,
        "authEntityId": 10010,
        "displayName": "10010",
        "authEntityClass": 1738953278,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "state": "INACTIVE",
        "type": "NBAR"
      },
      "indicativeNetworkIdentity": [
        {
          "id": "0d3454f6-bed3-42be-b837-504425e343a4",
          "instanceId": 14666,
          "authEntityId": 14666,
          "displayName": "14666",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "189",
          "protocol": "UDP",
          "upperPort": 0
        },
        {
          "id": "adac61ab-d75a-4cf4-b3ac-6c52b4205e54",
          "instanceId": 14667,
          "authEntityId": 14667,
          "displayName": "14667",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "189",
          "protocol": "TCP",
          "upperPort": 0
        }
      ],
      "networkApplications": [
        {
          "id": "c2a22ea3-8ea5-4929-a29e-a9299c6cd4a6",
          "instanceId": 11951,
          "authEntityId": 11951,
          "displayName": "11951",
          "authEntityClass": -217092956,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "appProtocol": "tcp/udp",
          "applicationGroup": "other",
          "applicationType": "DEFAULT",
          "categoryId": "e6619ce8-97de-4c63-a53a-cceddbddc1a8",
          "createTime": 1517966744023,
          "encrypted": "false",
          "engineId": "3",
          "helpString": "Queued File Transport",
          "ignoreConflict": false,
          "lastUpdateTime": 1517966744023,
          "longDescription": "Registered with IANA on port 189 TCP/UDP",
          "name": "qft",
          "nbarId": "1033",
          "p2pTechnology": "false",
          "popularity": 1,
          "rank": 65535,
          "references": "http://www.iana.org/assignments/service-names-port-numbers/service-names-port-numbers.xml",
          "selectorId": "189",
          "subCategory": "50b55e2e-d0d0-4404-bea4-819351565d90",
          "trafficClass": "BULK_DATA",
          "tunnel": "false"
        }
      ],
      "networkIdentity": [
        {
          "id": "3ffda97e-218e-40f6-91d4-7c1149b75989",
          "instanceId": 14664,
          "authEntityId": 14664,
          "displayName": "14664",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "189",
          "protocol": "TCP",
          "upperPort": 0
        },
        {
          "id": "991dfdc4-d4a1-4329-bc48-346090730e67",
          "instanceId": 14665,
          "authEntityId": 14665,
          "displayName": "14665",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "189",
          "protocol": "UDP",
          "upperPort": 0
        }
      ],
      "parentScalableGroup": {
        "idRef": "383363b1-4dd5-49d1-bfe4-34daac437cfb"
      },
      "user": [

      ],
      "userGroup": [

      ]
    },
    {
      "id": "ea92e9b7-9f5d-4989-830b-f4da666530b0",
      "instanceId": 4142,
      "authEntityId": 4142,
      "displayName": "4142",
      "authEntityClass": -1909073334,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "createTime": 1517966741366,
      "deployed": false,
      "isSeeded": true,
      "isStale": false,
      "lastUpdateTime": 1517966741366,
      "name": "audit",
      "namespace": "scalablegroup:application",
      "provisioningState": "DEFINED",
      "qualifier": "application",
      "resourceVersion": 0,
      "targetIdList": [

      ],
      "type": "scalablegroup",
      "cfsChangeInfo": [

      ],
      "customProvisions": [

      ],
      "scalableGroupExternalHandle": "audit",
      "scalableGroupType": "APPLICATION",
      "securityGroupTag": 0,
      "state": "ACTIVE",
      "vnAgnostic": true,
      "identitySource": {
        "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
        "instanceId": 10010,
        "authEntityId": 10010,
        "displayName": "10010",
        "authEntityClass": 1738953278,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "state": "INACTIVE",
        "type": "NBAR"
      },
      "indicativeNetworkIdentity": [
        {
          "id": "9aaa8fca-bfb9-4ced-8e11-411dd33cfc55",
          "instanceId": 12284,
          "authEntityId": 12284,
          "displayName": "12284",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "182",
          "protocol": "TCP",
          "upperPort": 0
        },
        {
          "id": "30b9e2ba-0b96-4672-b55c-37715c00164e",
          "instanceId": 12285,
          "authEntityId": 12285,
          "displayName": "12285",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "182",
          "protocol": "UDP",
          "upperPort": 0
        }
      ],
      "networkApplications": [
        {
          "id": "82520bfd-48ca-4a18-bb82-672c0f5160d3",
          "instanceId": 11103,
          "authEntityId": 11103,
          "displayName": "11103",
          "authEntityClass": -217092956,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "appProtocol": "tcp/udp",
          "applicationGroup": "other",
          "applicationType": "DEFAULT",
          "categoryId": "6daa3dfd-3526-407a-88f0-97824b3f3a24",
          "createTime": 1517966741366,
          "encrypted": "false",
          "engineId": "3",
          "helpString": "Unisys Audit SITP",
          "ignoreConflict": false,
          "lastUpdateTime": 1517966741366,
          "longDescription": "Registered with IANA on port 182 TCP/UDP",
          "name": "audit",
          "nbarId": "1025",
          "p2pTechnology": "false",
          "popularity": 1,
          "rank": 65535,
          "references": "http://www.iana.org/assignments/service-names-port-numbers/service-names-port-numbers.xml",
          "selectorId": "182",
          "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
          "trafficClass": "BULK_DATA",
          "tunnel": "false"
        }
      ],
      "networkIdentity": [
        {
          "id": "b8e1dc68-554e-436a-909b-fc8c914841b3",
          "instanceId": 12282,
          "authEntityId": 12282,
          "displayName": "12282",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "182",
          "protocol": "TCP",
          "upperPort": 0
        },
        {
          "id": "c9280cfc-b5ba-4f9e-b64a-db3f116943ea",
          "instanceId": 12283,
          "authEntityId": 12283,
          "displayName": "12283",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "182",
          "protocol": "UDP",
          "upperPort": 0
        }
      ],
      "parentScalableGroup": {
        "idRef": "000c15bf-28fe-4fbb-9b69-35e6502721f1"
      },
      "user": [

      ],
      "userGroup": [

      ]
    },
    {
      "id": "ea9ffab6-598e-4583-bdcb-ac176229b2b8",
      "instanceId": 4761,
      "authEntityId": 4761,
      "displayName": "4761",
      "authEntityClass": -1909073334,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "createTime": 1517966743440,
      "deployed": false,
      "isSeeded": true,
      "isStale": false,
      "lastUpdateTime": 1517966743440,
      "name": "monster-com",
      "namespace": "scalablegroup:application",
      "provisioningState": "DEFINED",
      "qualifier": "application",
      "resourceVersion": 0,
      "targetIdList": [

      ],
      "type": "scalablegroup",
      "cfsChangeInfo": [

      ],
      "customProvisions": [

      ],
      "scalableGroupExternalHandle": "monster-com",
      "scalableGroupType": "APPLICATION",
      "securityGroupTag": 0,
      "state": "ACTIVE",
      "vnAgnostic": true,
      "identitySource": {
        "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
        "instanceId": 10010,
        "authEntityId": 10010,
        "displayName": "10010",
        "authEntityClass": 1738953278,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "state": "INACTIVE",
        "type": "NBAR"
      },
      "indicativeNetworkIdentity": [

      ],
      "networkApplications": [
        {
          "id": "4e9a2657-174a-4384-adec-5be94a44731e",
          "instanceId": 11711,
          "authEntityId": 11711,
          "displayName": "11711",
          "authEntityClass": -217092956,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "appProtocol": "tcp/udp",
          "applicationGroup": "other",
          "applicationType": "DEFAULT",
          "categoryId": "878644b5-fe91-45b4-9204-31c84a73ba48",
          "createTime": 1517966743440,
          "encrypted": "false",
          "engineId": "13",
          "helpString": "Monster.com - Online job search portal.",
          "ignoreConflict": false,
          "lastUpdateTime": 1517966743440,
          "longDescription": "Monster.com - Online job search portal.",
          "name": "monster-com",
          "nbarId": "1713",
          "p2pTechnology": "false",
          "popularity": 3,
          "rank": 65535,
          "references": "http://monster.com",
          "selectorId": "785",
          "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
          "trafficClass": "TRANSACTIONAL_DATA",
          "tunnel": "false"
        }
      ],
      "networkIdentity": [
        {
          "id": "e2eec650-05d9-489e-8d80-0e5fcad43e91",
          "instanceId": 13942,
          "authEntityId": 13942,
          "displayName": "13942",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "53,5353",
          "protocol": "UDP",
          "upperPort": 0
        },
        {
          "id": "803481e0-d92d-412f-a932-cd686e9d96a8",
          "instanceId": 13943,
          "authEntityId": 13943,
          "displayName": "13943",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "53,80,443,5353",
          "protocol": "TCP",
          "upperPort": 0
        }
      ],
      "parentScalableGroup": {
        "idRef": "28e08de4-d7b2-4521-84ce-bc1e5ee0b9cc"
      },
      "user": [

      ],
      "userGroup": [

      ]
    },
    {
      "id": "eacd594b-0845-4916-9899-422161bcc6a2",
      "instanceId": 15066,
      "authEntityId": 15066,
      "displayName": "15066",
      "authEntityClass": -1909073334,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "createTime": 1517966744154,
      "deployed": false,
      "isSeeded": true,
      "isStale": false,
      "lastUpdateTime": 1517966744154,
      "name": "rsvp-e2e-ignore",
      "namespace": "scalablegroup:application",
      "provisioningState": "DEFINED",
      "qualifier": "application",
      "resourceVersion": 0,
      "targetIdList": [

      ],
      "type": "scalablegroup",
      "cfsChangeInfo": [

      ],
      "customProvisions": [

      ],
      "scalableGroupExternalHandle": "rsvp-e2e-ignore",
      "scalableGroupType": "APPLICATION",
      "securityGroupTag": 0,
      "state": "ACTIVE",
      "vnAgnostic": true,
      "identitySource": {
        "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
        "instanceId": 10010,
        "authEntityId": 10010,
        "displayName": "10010",
        "authEntityClass": 1738953278,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "state": "INACTIVE",
        "type": "NBAR"
      },
      "indicativeNetworkIdentity": [
        {
          "id": "8f8c7bee-257b-4efa-8318-a9aedb31c892",
          "instanceId": 14840,
          "authEntityId": 14840,
          "displayName": "14840",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipProtocolNumbers": "134",
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "protocol": "RSVP_E2E_IGNORE",
          "upperPort": 0
        }
      ],
      "networkApplications": [
        {
          "id": "5bd57be2-5bc9-4698-9b4d-577922ac5093",
          "instanceId": 12005,
          "authEntityId": 12005,
          "displayName": "12005",
          "authEntityClass": -217092956,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "appProtocol": "ip",
          "applicationGroup": "other",
          "applicationType": "DEFAULT",
          "categoryId": "6daa3dfd-3526-407a-88f0-97824b3f3a24",
          "createTime": 1517966744154,
          "encrypted": "false",
          "engineId": "1",
          "helpString": "RSVP-E2E-IGNORE",
          "ignoreConflict": false,
          "lastUpdateTime": 1517966744154,
          "longDescription": "A Protocol used in Aggregation of RSVP for IPv4 and IPv6 Reservations.",
          "name": "rsvp-e2e-ignore",
          "nbarId": "1232",
          "p2pTechnology": "false",
          "popularity": 2,
          "rank": 65535,
          "references": "http://tools.ietf.org/html/rfc3175",
          "selectorId": "134",
          "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
          "trafficClass": "BULK_DATA",
          "tunnel": "false"
        }
      ],
      "networkIdentity": [
        {
          "id": "3e53c938-59bb-4fee-ae53-34c6d0222137",
          "instanceId": 14839,
          "authEntityId": 14839,
          "displayName": "14839",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipProtocolNumbers": "134",
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "protocol": "RSVP_E2E_IGNORE",
          "upperPort": 0
        }
      ],
      "parentScalableGroup": {
        "idRef": "000c15bf-28fe-4fbb-9b69-35e6502721f1"
      },
      "user": [

      ],
      "userGroup": [

      ]
    },
    {
      "id": "eadf1b63-59a4-4869-a4e9-ea3a171b6812",
      "instanceId": 4822,
      "authEntityId": 4822,
      "displayName": "4822",
      "authEntityClass": -1909073334,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "createTime": 1517966743560,
      "deployed": false,
      "isSeeded": true,
      "isStale": false,
      "lastUpdateTime": 1517966743560,
      "name": "naver-line",
      "namespace": "scalablegroup:application",
      "provisioningState": "DEFINED",
      "qualifier": "application",
      "resourceVersion": 0,
      "targetIdList": [

      ],
      "type": "scalablegroup",
      "cfsChangeInfo": [

      ],
      "customProvisions": [

      ],
      "scalableGroupExternalHandle": "naver-line",
      "scalableGroupType": "APPLICATION",
      "securityGroupTag": 0,
      "state": "ACTIVE",
      "vnAgnostic": true,
      "identitySource": {
        "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
        "instanceId": 10010,
        "authEntityId": 10010,
        "displayName": "10010",
        "authEntityClass": 1738953278,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "state": "INACTIVE",
        "type": "NBAR"
      },
      "indicativeNetworkIdentity": [

      ],
      "networkApplications": [
        {
          "id": "7a3664b3-a0a4-45d2-a39f-78075a43ab18",
          "instanceId": 11771,
          "authEntityId": 11771,
          "displayName": "11771",
          "authEntityClass": -217092956,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "appProtocol": "tcp/udp",
          "applicationGroup": "other",
          "applicationType": "DEFAULT",
          "categoryId": "82a7b7fa-da5a-48f5-8b6a-a2546b947843",
          "createTime": 1517966743560,
          "encrypted": "true",
          "engineId": "13",
          "helpString": "Naver Line, an IM client with audio and video calling capabilities",
          "ignoreConflict": false,
          "lastUpdateTime": 1517966743560,
          "longDescription": "Naver-line is a Japanese proprietary application for instant messaging on smartphones and PCs. Naver-Line users exchange text messages, graphics, video and audio media, make free VoIP calls, and hold free audio or video conferences",
          "name": "naver-line",
          "nbarId": "1512",
          "p2pTechnology": "false",
          "popularity": 8,
          "rank": 65535,
          "references": "http://line.me/ko/",
          "selectorId": "573",
          "subCategory": "118c10a2-e272-47c1-92b9-4814ebe97138",
          "trafficClass": "MULTIMEDIA_CONFERENCING",
          "tunnel": "false"
        }
      ],
      "networkIdentity": [
        {
          "id": "344de729-1570-406b-a780-415e5202734e",
          "instanceId": 14098,
          "authEntityId": 14098,
          "displayName": "14098",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "53,80,443,3478,5353",
          "protocol": "TCP",
          "upperPort": 0
        },
        {
          "id": "a671cf9c-6349-4f63-812d-78b35ff1f9e3",
          "instanceId": 14099,
          "authEntityId": 14099,
          "displayName": "14099",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "53,3478,5353",
          "protocol": "UDP",
          "upperPort": 0
        }
      ],
      "parentScalableGroup": {
        "idRef": "fd6264c9-c16a-4311-a335-a3db039256f3"
      },
      "user": [

      ],
      "userGroup": [

      ]
    },
    {
      "id": "ebb70377-872b-494a-a3eb-4408e7224291",
      "instanceId": 4505,
      "authEntityId": 4505,
      "displayName": "4505",
      "authEntityClass": -1909073334,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "createTime": 1517966742836,
      "deployed": false,
      "isSeeded": true,
      "isStale": false,
      "lastUpdateTime": 1517966742836,
      "name": "gotodevice",
      "namespace": "scalablegroup:application",
      "provisioningState": "DEFINED",
      "qualifier": "application",
      "resourceVersion": 0,
      "targetIdList": [

      ],
      "type": "scalablegroup",
      "cfsChangeInfo": [

      ],
      "customProvisions": [

      ],
      "scalableGroupExternalHandle": "gotodevice",
      "scalableGroupType": "APPLICATION",
      "securityGroupTag": 0,
      "state": "ACTIVE",
      "vnAgnostic": true,
      "identitySource": {
        "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
        "instanceId": 10010,
        "authEntityId": 10010,
        "displayName": "10010",
        "authEntityClass": 1738953278,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "state": "INACTIVE",
        "type": "NBAR"
      },
      "indicativeNetworkIdentity": [
        {
          "id": "9defc36c-3db7-40dd-b3cd-c5905a639388",
          "instanceId": 13251,
          "authEntityId": 13251,
          "displayName": "13251",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "2217",
          "protocol": "TCP",
          "upperPort": 0
        },
        {
          "id": "0ac12255-3d3d-460c-86bb-697d1308ab94",
          "instanceId": 13252,
          "authEntityId": 13252,
          "displayName": "13252",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "2217",
          "protocol": "UDP",
          "upperPort": 0
        }
      ],
      "networkApplications": [
        {
          "id": "b7f1ed09-8df7-4c05-9632-beebd38869f2",
          "instanceId": 11456,
          "authEntityId": 11456,
          "displayName": "11456",
          "authEntityClass": -217092956,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "appProtocol": "tcp/udp",
          "applicationGroup": "other",
          "applicationType": "DEFAULT",
          "categoryId": "a09ef1b3-ba26-4372-aee8-309d50697e76",
          "createTime": 1517966742836,
          "encrypted": "false",
          "engineId": "3",
          "helpString": "Online device management application",
          "ignoreConflict": false,
          "lastUpdateTime": 1517966742836,
          "longDescription": "GoToDevice is a cross-platform control and administration software. It offers the remote manager different management options such as remote control of the desktop, file system browsing, services and processes control, registry editing and viewing. The software usually uses TCP/UDP port 2217.",
          "name": "gotodevice",
          "nbarId": "1346",
          "p2pTechnology": "false",
          "popularity": 1,
          "rank": 65535,
          "references": "http://www.gotodevice.com/",
          "selectorId": "2217",
          "subCategory": "ff664205-64f3-447e-acff-1ec1ba9a6c31",
          "trafficClass": "MULTIMEDIA_STREAMING",
          "tunnel": "false"
        }
      ],
      "networkIdentity": [
        {
          "id": "1c7e623d-bcde-4f7e-9596-69c86389b459",
          "instanceId": 13249,
          "authEntityId": 13249,
          "displayName": "13249",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "2217",
          "protocol": "UDP",
          "upperPort": 0
        },
        {
          "id": "3638b265-fd15-4e01-8ea7-d4f63f13ee8e",
          "instanceId": 13250,
          "authEntityId": 13250,
          "displayName": "13250",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "2217",
          "protocol": "TCP",
          "upperPort": 0
        }
      ],
      "parentScalableGroup": {
        "idRef": "fd6264c9-c16a-4311-a335-a3db039256f3"
      },
      "user": [

      ],
      "userGroup": [

      ]
    },
    {
      "id": "ebecbd76-f456-4a8d-9983-cd9122660b4d",
      "instanceId": 4541,
      "authEntityId": 4541,
      "displayName": "4541",
      "authEntityClass": -1909073334,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "createTime": 1517966742938,
      "deployed": false,
      "isSeeded": true,
      "isStale": false,
      "lastUpdateTime": 1517966742938,
      "name": "hl7",
      "namespace": "scalablegroup:application",
      "provisioningState": "DEFINED",
      "qualifier": "application",
      "resourceVersion": 0,
      "targetIdList": [

      ],
      "type": "scalablegroup",
      "cfsChangeInfo": [

      ],
      "customProvisions": [

      ],
      "scalableGroupExternalHandle": "hl7",
      "scalableGroupType": "APPLICATION",
      "securityGroupTag": 0,
      "state": "ACTIVE",
      "vnAgnostic": true,
      "identitySource": {
        "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
        "instanceId": 10010,
        "authEntityId": 10010,
        "displayName": "10010",
        "authEntityClass": 1738953278,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "state": "INACTIVE",
        "type": "NBAR"
      },
      "indicativeNetworkIdentity": [

      ],
      "networkApplications": [
        {
          "id": "b0601da7-4610-4cb2-b448-f0c4208e802b",
          "instanceId": 11492,
          "authEntityId": 11492,
          "displayName": "11492",
          "authEntityClass": -217092956,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "applicationGroup": "other",
          "applicationType": "DEFAULT",
          "categoryId": "58aa61dc-dd95-4a6d-9c7c-0ebf16da6dde",
          "createTime": 1517966742938,
          "encrypted": "false",
          "engineId": "13",
          "helpString": "Health Level Seven protocol, information exchanging between health applications",
          "ignoreConflict": false,
          "lastUpdateTime": 1517966742938,
          "longDescription": "Health Level Seven is a protocol designated to exchange information between health applications. The protocol is messaged based and can give the client various information regarding his health.",
          "name": "hl7",
          "nbarId": "73",
          "p2pTechnology": "false",
          "popularity": 6,
          "rank": 65535,
          "references": "http://www.hl7.org/about/index.cfm?ref=nav",
          "selectorId": "73",
          "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
          "trafficClass": "BULK_DATA",
          "tunnel": "false"
        }
      ],
      "networkIdentity": [

      ],
      "parentScalableGroup": {
        "idRef": "cff11752-fafb-4e22-9e6f-11f44f59a9a3"
      },
      "user": [

      ],
      "userGroup": [

      ]
    },
    {
      "id": "ebfc0c30-b4c4-450e-9f0f-398436a1c875",
      "instanceId": 15426,
      "authEntityId": 15426,
      "displayName": "15426",
      "authEntityClass": -1909073334,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "createTime": 1517966744944,
      "deployed": false,
      "isSeeded": true,
      "isStale": false,
      "lastUpdateTime": 1517966744944,
      "name": "worldstarhiphop",
      "namespace": "scalablegroup:application",
      "provisioningState": "DEFINED",
      "qualifier": "application",
      "resourceVersion": 0,
      "targetIdList": [

      ],
      "type": "scalablegroup",
      "cfsChangeInfo": [

      ],
      "customProvisions": [

      ],
      "scalableGroupExternalHandle": "worldstarhiphop",
      "scalableGroupType": "APPLICATION",
      "securityGroupTag": 0,
      "state": "ACTIVE",
      "vnAgnostic": true,
      "identitySource": {
        "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
        "instanceId": 10010,
        "authEntityId": 10010,
        "displayName": "10010",
        "authEntityClass": 1738953278,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "state": "INACTIVE",
        "type": "NBAR"
      },
      "indicativeNetworkIdentity": [

      ],
      "networkApplications": [
        {
          "id": "a81fcb1b-ca2e-42bc-ab5e-ccda726e12ea",
          "instanceId": 16369,
          "authEntityId": 16369,
          "displayName": "16369",
          "authEntityClass": -217092956,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "appProtocol": "tcp/udp",
          "applicationGroup": "other",
          "applicationType": "DEFAULT",
          "categoryId": "878644b5-fe91-45b4-9204-31c84a73ba48",
          "createTime": 1517966744944,
          "encrypted": "false",
          "engineId": "13",
          "helpString": "WorldstarHipHop - Entertainment, hip hop, music videos and blogs.",
          "ignoreConflict": false,
          "lastUpdateTime": 1517966744944,
          "longDescription": "WorldstarHipHop - Entertainment, hip hop, music videos and blogs.",
          "name": "worldstarhiphop",
          "nbarId": "1732",
          "p2pTechnology": "false",
          "popularity": 3,
          "rank": 65535,
          "references": "http://worldstarhiphop.com",
          "selectorId": "804",
          "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
          "trafficClass": "TRANSACTIONAL_DATA",
          "tunnel": "false"
        }
      ],
      "networkIdentity": [
        {
          "id": "f04075fd-c2d4-4a21-875e-ebe1b5d052d3",
          "instanceId": 17861,
          "authEntityId": 17861,
          "displayName": "17861",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "53,80,443,5353",
          "protocol": "TCP",
          "upperPort": 0
        },
        {
          "id": "94d331d8-6c9a-4ae1-a84a-416ad12921ff",
          "instanceId": 17862,
          "authEntityId": 17862,
          "displayName": "17862",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "53,5353",
          "protocol": "UDP",
          "upperPort": 0
        }
      ],
      "parentScalableGroup": {
        "idRef": "28e08de4-d7b2-4521-84ce-bc1e5ee0b9cc"
      },
      "user": [

      ],
      "userGroup": [

      ]
    },
    {
      "id": "ebfe27b1-0ffc-40e5-9aef-45138e69a517",
      "instanceId": 4506,
      "authEntityId": 4506,
      "displayName": "4506",
      "authEntityClass": -1909073334,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "createTime": 1517966742838,
      "deployed": false,
      "isSeeded": true,
      "isStale": false,
      "lastUpdateTime": 1517966742838,
      "name": "gotomypc",
      "namespace": "scalablegroup:application",
      "provisioningState": "DEFINED",
      "qualifier": "application",
      "resourceVersion": 0,
      "targetIdList": [

      ],
      "type": "scalablegroup",
      "cfsChangeInfo": [

      ],
      "customProvisions": [

      ],
      "scalableGroupExternalHandle": "gotomypc",
      "scalableGroupType": "APPLICATION",
      "securityGroupTag": 0,
      "state": "ACTIVE",
      "vnAgnostic": true,
      "identitySource": {
        "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
        "instanceId": 10010,
        "authEntityId": 10010,
        "displayName": "10010",
        "authEntityClass": 1738953278,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "state": "INACTIVE",
        "type": "NBAR"
      },
      "indicativeNetworkIdentity": [

      ],
      "networkApplications": [
        {
          "id": "c7a90f4b-f41b-4813-a6fc-bf0f42767415",
          "instanceId": 11457,
          "authEntityId": 11457,
          "displayName": "11457",
          "authEntityClass": -217092956,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "appProtocol": "tcp/udp",
          "applicationGroup": "other",
          "applicationType": "DEFAULT",
          "categoryId": "a09ef1b3-ba26-4372-aee8-309d50697e76",
          "createTime": 1517966742838,
          "encrypted": "true",
          "engineId": "13",
          "helpString": "GoToMyPc is a remote control service",
          "ignoreConflict": false,
          "lastUpdateTime": 1517966742838,
          "longDescription": "GoToMyPC is a remote control software service that enables users to operate their computer remotely from any device. It is produced by Critix Online.",
          "name": "gotomypc",
          "nbarId": "1435",
          "p2pTechnology": "false",
          "popularity": 7,
          "rank": 65535,
          "references": "http://www.gotomypc.com/",
          "selectorId": "499",
          "subCategory": "ff664205-64f3-447e-acff-1ec1ba9a6c31",
          "trafficClass": "MULTIMEDIA_STREAMING",
          "tunnel": "false"
        }
      ],
      "networkIdentity": [
        {
          "id": "dcbb0263-1aee-4dd2-82a1-be89caf239b9",
          "instanceId": 13253,
          "authEntityId": 13253,
          "displayName": "13253",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "53,80,443,5353",
          "protocol": "TCP",
          "upperPort": 0
        },
        {
          "id": "aaee77a5-70ba-4ea4-b931-d0f01e2a627b",
          "instanceId": 13254,
          "authEntityId": 13254,
          "displayName": "13254",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "53,5353",
          "protocol": "UDP",
          "upperPort": 0
        }
      ],
      "parentScalableGroup": {
        "idRef": "fd6264c9-c16a-4311-a335-a3db039256f3"
      },
      "user": [

      ],
      "userGroup": [

      ]
    },
    {
      "id": "ec43e5bc-239f-40f5-8875-5bb54d2277d1",
      "instanceId": 15387,
      "authEntityId": 15387,
      "displayName": "15387",
      "authEntityClass": -1909073334,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "createTime": 1517966744853,
      "deployed": false,
      "isSeeded": true,
      "isStale": false,
      "lastUpdateTime": 1517966744853,
      "name": "warriorforum",
      "namespace": "scalablegroup:application",
      "provisioningState": "DEFINED",
      "qualifier": "application",
      "resourceVersion": 0,
      "targetIdList": [

      ],
      "type": "scalablegroup",
      "cfsChangeInfo": [

      ],
      "customProvisions": [

      ],
      "scalableGroupExternalHandle": "warriorforum",
      "scalableGroupType": "APPLICATION",
      "securityGroupTag": 0,
      "state": "ACTIVE",
      "vnAgnostic": true,
      "identitySource": {
        "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
        "instanceId": 10010,
        "authEntityId": 10010,
        "displayName": "10010",
        "authEntityClass": 1738953278,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "state": "INACTIVE",
        "type": "NBAR"
      },
      "indicativeNetworkIdentity": [

      ],
      "networkApplications": [
        {
          "id": "726937d2-a277-4558-a1b5-b2163544586f",
          "instanceId": 16330,
          "authEntityId": 16330,
          "displayName": "16330",
          "authEntityClass": -217092956,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "appProtocol": "tcp/udp",
          "applicationGroup": "other",
          "applicationType": "DEFAULT",
          "categoryId": "878644b5-fe91-45b4-9204-31c84a73ba48",
          "createTime": 1517966744853,
          "encrypted": "false",
          "engineId": "13",
          "helpString": "WarriorForum - Internet Marketing Forums.",
          "ignoreConflict": false,
          "lastUpdateTime": 1517966744853,
          "longDescription": "WarriorForum - Internet Marketing Forums.",
          "name": "warriorforum",
          "nbarId": "1722",
          "p2pTechnology": "false",
          "popularity": 3,
          "rank": 65535,
          "references": "http://warriorforum.com",
          "selectorId": "794",
          "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
          "trafficClass": "TRANSACTIONAL_DATA",
          "tunnel": "false"
        }
      ],
      "networkIdentity": [
        {
          "id": "367fbd0b-937c-4199-8249-530ff3f7fd9b",
          "instanceId": 17787,
          "authEntityId": 17787,
          "displayName": "17787",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "53,5353",
          "protocol": "UDP",
          "upperPort": 0
        },
        {
          "id": "8afe6779-ce2b-4f31-9e17-50a85434134f",
          "instanceId": 17788,
          "authEntityId": 17788,
          "displayName": "17788",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "53,80,443,5353",
          "protocol": "TCP",
          "upperPort": 0
        }
      ],
      "parentScalableGroup": {
        "idRef": "28e08de4-d7b2-4521-84ce-bc1e5ee0b9cc"
      },
      "user": [

      ],
      "userGroup": [

      ]
    },
    {
      "id": "ec5018b9-ebd1-4261-a7eb-8da11b02c250",
      "instanceId": 4338,
      "authEntityId": 4338,
      "displayName": "4338",
      "authEntityClass": -1909073334,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "createTime": 1517966742332,
      "deployed": false,
      "isSeeded": true,
      "isStale": false,
      "lastUpdateTime": 1517966742332,
      "name": "ddm-dfm",
      "namespace": "scalablegroup:application",
      "provisioningState": "DEFINED",
      "qualifier": "application",
      "resourceVersion": 0,
      "targetIdList": [

      ],
      "type": "scalablegroup",
      "cfsChangeInfo": [

      ],
      "customProvisions": [

      ],
      "scalableGroupExternalHandle": "ddm-dfm",
      "scalableGroupType": "APPLICATION",
      "securityGroupTag": 0,
      "state": "ACTIVE",
      "vnAgnostic": true,
      "identitySource": {
        "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
        "instanceId": 10010,
        "authEntityId": 10010,
        "displayName": "10010",
        "authEntityClass": 1738953278,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "state": "INACTIVE",
        "type": "NBAR"
      },
      "indicativeNetworkIdentity": [
        {
          "id": "e0e9cb57-a975-4428-b428-d6fcc5996cc2",
          "instanceId": 12788,
          "authEntityId": 12788,
          "displayName": "12788",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "447",
          "protocol": "UDP",
          "upperPort": 0
        },
        {
          "id": "15ac9543-dc5a-43d5-8353-179534741cb1",
          "instanceId": 12789,
          "authEntityId": 12789,
          "displayName": "12789",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "447",
          "protocol": "TCP",
          "upperPort": 0
        }
      ],
      "networkApplications": [
        {
          "id": "a4b27e10-86ed-4832-b555-354dce398660",
          "instanceId": 11289,
          "authEntityId": 11289,
          "displayName": "11289",
          "authEntityClass": -217092956,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "appProtocol": "tcp/udp",
          "applicationGroup": "other",
          "applicationType": "DEFAULT",
          "categoryId": "cb5915d1-afec-4c30-ac9e-069ff382272f",
          "createTime": 1517966742332,
          "encrypted": "false",
          "engineId": "3",
          "helpString": "DDM Distributed File management",
          "ignoreConflict": false,
          "lastUpdateTime": 1517966742332,
          "longDescription": "Registered with IANA on port 447 TCP/UDP",
          "name": "ddm-dfm",
          "nbarId": "362",
          "p2pTechnology": "false",
          "popularity": 1,
          "rank": 65535,
          "references": "http://www.iana.org/assignments/service-names-port-numbers/service-names-port-numbers.xml",
          "selectorId": "447",
          "subCategory": "b90786f4-0190-45b8-9924-21b3e9682590",
          "trafficClass": "TRANSACTIONAL_DATA",
          "tunnel": "false"
        }
      ],
      "networkIdentity": [
        {
          "id": "82c62b8d-34b1-4da1-86c8-2a3b5ce8e239",
          "instanceId": 12786,
          "authEntityId": 12786,
          "displayName": "12786",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "447",
          "protocol": "TCP",
          "upperPort": 0
        },
        {
          "id": "16a0d558-dae4-41b5-8b18-37bc8af01dd0",
          "instanceId": 12787,
          "authEntityId": 12787,
          "displayName": "12787",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "447",
          "protocol": "UDP",
          "upperPort": 0
        }
      ],
      "parentScalableGroup": {
        "idRef": "8718395e-683e-4a2d-a232-100202751271"
      },
      "user": [

      ],
      "userGroup": [

      ]
    },
    {
      "id": "ec76bcab-48fe-46ba-9ee2-b9b370eca732",
      "instanceId": 4638,
      "authEntityId": 4638,
      "displayName": "4638",
      "authEntityClass": -1909073334,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "createTime": 1517966743181,
      "deployed": false,
      "isSeeded": true,
      "isStale": false,
      "lastUpdateTime": 1517966743181,
      "name": "irtp",
      "namespace": "scalablegroup:application",
      "provisioningState": "DEFINED",
      "qualifier": "application",
      "resourceVersion": 0,
      "targetIdList": [

      ],
      "type": "scalablegroup",
      "cfsChangeInfo": [

      ],
      "customProvisions": [

      ],
      "scalableGroupExternalHandle": "irtp",
      "scalableGroupType": "APPLICATION",
      "securityGroupTag": 0,
      "state": "ACTIVE",
      "vnAgnostic": true,
      "identitySource": {
        "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
        "instanceId": 10010,
        "authEntityId": 10010,
        "displayName": "10010",
        "authEntityClass": 1738953278,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "state": "INACTIVE",
        "type": "NBAR"
      },
      "indicativeNetworkIdentity": [
        {
          "id": "81096dd8-2440-43c8-b5b8-723931363566",
          "instanceId": 13598,
          "authEntityId": 13598,
          "displayName": "13598",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipProtocolNumbers": "28",
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "protocol": "IRTP",
          "upperPort": 0
        }
      ],
      "networkApplications": [
        {
          "id": "3431d5db-3c84-4b8d-bfd7-7d8c33d2d7f9",
          "instanceId": 11588,
          "authEntityId": 11588,
          "displayName": "11588",
          "authEntityClass": -217092956,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "appProtocol": "ip",
          "applicationGroup": "other",
          "applicationType": "DEFAULT",
          "categoryId": "6daa3dfd-3526-407a-88f0-97824b3f3a24",
          "createTime": 1517966743181,
          "encrypted": "unassigned",
          "engineId": "1",
          "helpString": "Internet Reliable Transaction",
          "ignoreConflict": false,
          "lastUpdateTime": 1517966743181,
          "longDescription": "The Internet Reliable Transaction Protocol (IRTP) is a transport level host-to-host protocol designed for an internet environment.  It provides reliable, sequenced delivery of packets of data between hosts and multiplexer/demultiplexer streams of packets from/to user processes representing ports.  It is simple to implement, with a minimum of connection management, at the possible expense of efficiency.",
          "name": "irtp",
          "nbarId": "782",
          "p2pTechnology": "unassigned",
          "popularity": 2,
          "rank": 65535,
          "references": "http://tools.ietf.org/html/rfc938",
          "selectorId": "28",
          "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
          "trafficClass": "BULK_DATA",
          "tunnel": "unassigned"
        }
      ],
      "networkIdentity": [
        {
          "id": "a0fae065-13cd-41ab-adf5-6607c9248b8a",
          "instanceId": 13597,
          "authEntityId": 13597,
          "displayName": "13597",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipProtocolNumbers": "28",
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "protocol": "IRTP",
          "upperPort": 0
        }
      ],
      "parentScalableGroup": {
        "idRef": "000c15bf-28fe-4fbb-9b69-35e6502721f1"
      },
      "user": [

      ],
      "userGroup": [

      ]
    },
    {
      "id": "ec9f3d9a-941c-4bf6-936d-cb7edb9ca69a",
      "instanceId": 4252,
      "authEntityId": 4252,
      "displayName": "4252",
      "authEntityClass": -1909073334,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "createTime": 1517966741944,
      "deployed": false,
      "isSeeded": true,
      "isStale": false,
      "lastUpdateTime": 1517966741944,
      "name": "cisco-spark",
      "namespace": "scalablegroup:application",
      "provisioningState": "DEFINED",
      "qualifier": "application",
      "resourceVersion": 0,
      "targetIdList": [

      ],
      "type": "scalablegroup",
      "cfsChangeInfo": [

      ],
      "customProvisions": [

      ],
      "scalableGroupExternalHandle": "cisco-spark",
      "scalableGroupType": "APPLICATION",
      "securityGroupTag": 0,
      "state": "ACTIVE",
      "vnAgnostic": true,
      "identitySource": {
        "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
        "instanceId": 10010,
        "authEntityId": 10010,
        "displayName": "10010",
        "authEntityClass": 1738953278,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "state": "INACTIVE",
        "type": "NBAR"
      },
      "indicativeNetworkIdentity": [

      ],
      "networkApplications": [
        {
          "id": "5b8cd72c-6805-41c2-9bfb-4dea4c3c4fe7",
          "instanceId": 11206,
          "authEntityId": 11206,
          "displayName": "11206",
          "authEntityClass": -217092956,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "appProtocol": "tcp/udp",
          "applicationGroup": "webex-group",
          "applicationType": "DEFAULT",
          "categoryId": "38a8e354-e2fe-4f3c-b1c5-2bbb90fb0d99",
          "createTime": 1517966741944,
          "encrypted": "true",
          "engineId": "13",
          "helpString": "Cisco Spark - unified communications client",
          "ignoreConflict": false,
          "lastUpdateTime": 1517966741944,
          "longDescription": "Cisco Spark - unified communications client and SaaS with mobile team communication: group chat, private chat, video calls with screen sharing and file sharing.",
          "name": "cisco-spark",
          "nbarId": "1741",
          "p2pTechnology": "false",
          "popularity": 9,
          "rank": 65535,
          "references": "http://ciscospark.com",
          "selectorId": "809",
          "subCategory": "05178016-4555-4291-ab4e-43c2ab2a931a",
          "trafficClass": "MULTIMEDIA_CONFERENCING",
          "tunnel": "false"
        }
      ],
      "networkIdentity": [
        {
          "id": "d7937119-12cf-4c15-978c-42a434ee4e11",
          "instanceId": 12538,
          "authEntityId": 12538,
          "displayName": "12538",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "53,3478,5353",
          "protocol": "UDP",
          "upperPort": 0
        },
        {
          "id": "3653e27e-4857-482f-b122-dd98c9b51ffc",
          "instanceId": 12539,
          "authEntityId": 12539,
          "displayName": "12539",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "53,80,443,3478,5353",
          "protocol": "TCP",
          "upperPort": 0
        }
      ],
      "parentScalableGroup": {
        "idRef": "60f1364d-2a02-4063-8ba1-07817abe0612"
      },
      "user": [

      ],
      "userGroup": [

      ]
    },
    {
      "id": "eca69c2e-8f5e-422a-a51b-1ab41389b3db",
      "instanceId": 15369,
      "authEntityId": 15369,
      "displayName": "15369",
      "authEntityClass": -1909073334,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "createTime": 1517966744822,
      "deployed": false,
      "isSeeded": true,
      "isStale": false,
      "lastUpdateTime": 1517966744822,
      "name": "vpps-via",
      "namespace": "scalablegroup:application",
      "provisioningState": "DEFINED",
      "qualifier": "application",
      "resourceVersion": 0,
      "targetIdList": [

      ],
      "type": "scalablegroup",
      "cfsChangeInfo": [

      ],
      "customProvisions": [

      ],
      "scalableGroupExternalHandle": "vpps-via",
      "scalableGroupType": "APPLICATION",
      "securityGroupTag": 0,
      "state": "ACTIVE",
      "vnAgnostic": true,
      "identitySource": {
        "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
        "instanceId": 10010,
        "authEntityId": 10010,
        "displayName": "10010",
        "authEntityClass": 1738953278,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "state": "INACTIVE",
        "type": "NBAR"
      },
      "indicativeNetworkIdentity": [
        {
          "id": "e9c67344-c051-4330-8611-670a6e99bc1a",
          "instanceId": 17723,
          "authEntityId": 17723,
          "displayName": "17723",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "676",
          "protocol": "UDP",
          "upperPort": 0
        },
        {
          "id": "55d8f8a5-054f-4519-9e4e-4a5472a7dae9",
          "instanceId": 17724,
          "authEntityId": 17724,
          "displayName": "17724",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "676",
          "protocol": "TCP",
          "upperPort": 0
        }
      ],
      "networkApplications": [
        {
          "id": "79a2f6e6-4000-49b9-9e7d-3b28c975d396",
          "instanceId": 16312,
          "authEntityId": 16312,
          "displayName": "16312",
          "authEntityClass": -217092956,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "appProtocol": "tcp/udp",
          "applicationGroup": "other",
          "applicationType": "DEFAULT",
          "categoryId": "6daa3dfd-3526-407a-88f0-97824b3f3a24",
          "createTime": 1517966744822,
          "encrypted": "false",
          "engineId": "3",
          "helpString": "vpps-via",
          "ignoreConflict": false,
          "lastUpdateTime": 1517966744822,
          "longDescription": "Registered with IANA on port 676 TCP/UDP",
          "name": "vpps-via",
          "nbarId": "584",
          "p2pTechnology": "false",
          "popularity": 1,
          "rank": 65535,
          "references": "http://www.iana.org/assignments/service-names-port-numbers/service-names-port-numbers.xml",
          "selectorId": "676",
          "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
          "trafficClass": "BULK_DATA",
          "tunnel": "false"
        }
      ],
      "networkIdentity": [
        {
          "id": "b40edf78-063f-4700-ada1-287b803e2274",
          "instanceId": 17721,
          "authEntityId": 17721,
          "displayName": "17721",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "676",
          "protocol": "TCP",
          "upperPort": 0
        },
        {
          "id": "2fd076e8-aaa2-4945-886b-77f8c703f45c",
          "instanceId": 17722,
          "authEntityId": 17722,
          "displayName": "17722",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "676",
          "protocol": "UDP",
          "upperPort": 0
        }
      ],
      "parentScalableGroup": {
        "idRef": "000c15bf-28fe-4fbb-9b69-35e6502721f1"
      },
      "user": [

      ],
      "userGroup": [

      ]
    },
    {
      "id": "ecb7cb0c-7dac-4830-88c4-117f25608b54",
      "instanceId": 4349,
      "authEntityId": 4349,
      "displayName": "4349",
      "authEntityClass": -1909073334,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "createTime": 1517966742363,
      "deployed": false,
      "isSeeded": true,
      "isStale": false,
      "lastUpdateTime": 1517966742363,
      "name": "dei-icda",
      "namespace": "scalablegroup:application",
      "provisioningState": "DEFINED",
      "qualifier": "application",
      "resourceVersion": 0,
      "targetIdList": [

      ],
      "type": "scalablegroup",
      "cfsChangeInfo": [

      ],
      "customProvisions": [

      ],
      "scalableGroupExternalHandle": "dei-icda",
      "scalableGroupType": "APPLICATION",
      "securityGroupTag": 0,
      "state": "ACTIVE",
      "vnAgnostic": true,
      "identitySource": {
        "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
        "instanceId": 10010,
        "authEntityId": 10010,
        "displayName": "10010",
        "authEntityClass": 1738953278,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "state": "INACTIVE",
        "type": "NBAR"
      },
      "indicativeNetworkIdentity": [
        {
          "id": "2fbcc0e0-1b6b-4de1-864a-0911544ea63e",
          "instanceId": 12828,
          "authEntityId": 12828,
          "displayName": "12828",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "618",
          "protocol": "TCP",
          "upperPort": 0
        },
        {
          "id": "b389f454-7de3-4843-aad9-53ed4c3060ab",
          "instanceId": 12829,
          "authEntityId": 12829,
          "displayName": "12829",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "618",
          "protocol": "UDP",
          "upperPort": 0
        }
      ],
      "networkApplications": [
        {
          "id": "2546bbbf-9038-49aa-9d75-1da926ecbcec",
          "instanceId": 11300,
          "authEntityId": 11300,
          "displayName": "11300",
          "authEntityClass": -217092956,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "appProtocol": "tcp/udp",
          "applicationGroup": "other",
          "applicationType": "DEFAULT",
          "categoryId": "6daa3dfd-3526-407a-88f0-97824b3f3a24",
          "createTime": 1517966742363,
          "encrypted": "false",
          "engineId": "3",
          "helpString": "dei-icda",
          "ignoreConflict": false,
          "lastUpdateTime": 1517966742363,
          "longDescription": "Registered with IANA on port 618 TCP/UDP",
          "name": "dei-icda",
          "nbarId": "527",
          "p2pTechnology": "false",
          "popularity": 1,
          "rank": 65535,
          "references": "http://www.iana.org/assignments/service-names-port-numbers/service-names-port-numbers.xml",
          "selectorId": "618",
          "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
          "trafficClass": "BULK_DATA",
          "tunnel": "false"
        }
      ],
      "networkIdentity": [
        {
          "id": "2e77275e-50bc-49b8-b79a-f2dc9bd0f0a9",
          "instanceId": 12826,
          "authEntityId": 12826,
          "displayName": "12826",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "618",
          "protocol": "TCP",
          "upperPort": 0
        },
        {
          "id": "6b25245e-9931-41e1-a406-f56e28cde0f6",
          "instanceId": 12827,
          "authEntityId": 12827,
          "displayName": "12827",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "618",
          "protocol": "UDP",
          "upperPort": 0
        }
      ],
      "parentScalableGroup": {
        "idRef": "000c15bf-28fe-4fbb-9b69-35e6502721f1"
      },
      "user": [

      ],
      "userGroup": [

      ]
    },
    {
      "id": "eccb082e-5fd3-4275-935e-d98217984425",
      "instanceId": 4258,
      "authEntityId": 4258,
      "displayName": "4258",
      "authEntityClass": -1909073334,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "createTime": 1517966741965,
      "deployed": false,
      "isSeeded": true,
      "isStale": false,
      "lastUpdateTime": 1517966741965,
      "name": "cisco-tna",
      "namespace": "scalablegroup:application",
      "provisioningState": "DEFINED",
      "qualifier": "application",
      "resourceVersion": 0,
      "targetIdList": [

      ],
      "type": "scalablegroup",
      "cfsChangeInfo": [

      ],
      "customProvisions": [

      ],
      "scalableGroupExternalHandle": "cisco-tna",
      "scalableGroupType": "APPLICATION",
      "securityGroupTag": 0,
      "state": "ACTIVE",
      "vnAgnostic": true,
      "identitySource": {
        "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
        "instanceId": 10010,
        "authEntityId": 10010,
        "displayName": "10010",
        "authEntityClass": 1738953278,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "state": "INACTIVE",
        "type": "NBAR"
      },
      "indicativeNetworkIdentity": [
        {
          "id": "4421dddf-8c52-4794-a700-42542ed89429",
          "instanceId": 12550,
          "authEntityId": 12550,
          "displayName": "12550",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "131",
          "protocol": "UDP",
          "upperPort": 0
        },
        {
          "id": "b9d59c04-8f65-451f-9c0f-c07be5d4097a",
          "instanceId": 12551,
          "authEntityId": 12551,
          "displayName": "12551",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "131",
          "protocol": "TCP",
          "upperPort": 0
        }
      ],
      "networkApplications": [
        {
          "id": "b1dd3807-8b61-4287-be38-df1cb435ecca",
          "instanceId": 11212,
          "authEntityId": 11212,
          "displayName": "11212",
          "authEntityClass": -217092956,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "appProtocol": "tcp/udp",
          "applicationGroup": "other",
          "applicationType": "DEFAULT",
          "categoryId": "1fc17362-9334-4c7b-b752-5afdbb574017",
          "createTime": 1517966741965,
          "encrypted": "false",
          "engineId": "3",
          "helpString": "cisco TNATIVE",
          "ignoreConflict": false,
          "lastUpdateTime": 1517966741965,
          "longDescription": "Cisco TNATIVE, Registered with IANA on port 131 TCP/UDP",
          "name": "cisco-tna",
          "nbarId": "1160",
          "p2pTechnology": "false",
          "popularity": 3,
          "rank": 65535,
          "references": "http://www.iana.org/assignments/service-names-port-numbers/service-names-port-numbers.xml",
          "selectorId": "131",
          "subCategory": "f3c0f761-d894-4112-ab89-b90fc84fd660",
          "trafficClass": "OPS_ADMIN_MGMT",
          "tunnel": "false"
        }
      ],
      "networkIdentity": [
        {
          "id": "a63aea3a-40ec-48c0-a3ce-741b2dce11b4",
          "instanceId": 12548,
          "authEntityId": 12548,
          "displayName": "12548",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "131",
          "protocol": "TCP",
          "upperPort": 0
        },
        {
          "id": "166ab7ab-673e-4e80-86b6-9a93e7e91351",
          "instanceId": 12549,
          "authEntityId": 12549,
          "displayName": "12549",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "131",
          "protocol": "UDP",
          "upperPort": 0
        }
      ],
      "parentScalableGroup": {
        "idRef": "5fcd74d4-8726-47f7-a088-c457863da7ef"
      },
      "user": [

      ],
      "userGroup": [

      ]
    },
    {
      "id": "ed25aa0f-1714-4b18-9f13-a88efb24c17d",
      "instanceId": 15151,
      "authEntityId": 15151,
      "displayName": "15151",
      "authEntityClass": -1909073334,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "createTime": 1517966744350,
      "deployed": false,
      "isSeeded": true,
      "isStale": false,
      "lastUpdateTime": 1517966744350,
      "name": "skinny",
      "namespace": "scalablegroup:application",
      "provisioningState": "DEFINED",
      "qualifier": "application",
      "resourceVersion": 0,
      "targetIdList": [

      ],
      "type": "scalablegroup",
      "cfsChangeInfo": [

      ],
      "customProvisions": [

      ],
      "scalableGroupExternalHandle": "skinny",
      "scalableGroupType": "APPLICATION",
      "securityGroupTag": 0,
      "state": "ACTIVE",
      "vnAgnostic": true,
      "identitySource": {
        "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
        "instanceId": 10010,
        "authEntityId": 10010,
        "displayName": "10010",
        "authEntityClass": 1738953278,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "state": "INACTIVE",
        "type": "NBAR"
      },
      "indicativeNetworkIdentity": [
        {
          "id": "9d858aab-2307-4d17-a426-84934393d724",
          "instanceId": 17103,
          "authEntityId": 17103,
          "displayName": "17103",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "2000,2001,2002",
          "protocol": "TCP",
          "upperPort": 0
        }
      ],
      "networkApplications": [
        {
          "id": "6dcff863-c2a4-4de4-919a-d4c057155156",
          "instanceId": 16094,
          "authEntityId": 16094,
          "displayName": "16094",
          "authEntityClass": -217092956,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "appProtocol": "tcp",
          "applicationGroup": "other",
          "applicationType": "DEFAULT",
          "categoryId": "38a8e354-e2fe-4f3c-b1c5-2bbb90fb0d99",
          "createTime": 1517966744350,
          "encrypted": "false",
          "engineId": "13",
          "helpString": "Skinny Call Control Protocol",
          "ignoreConflict": false,
          "lastUpdateTime": 1517966744350,
          "longDescription": "Skinny Client Control Protocol (SCCP) is a network control protocol over Cisco's Ethernet telephones. SCCP, also known as Skinny, uses TCP/IP connections for calls and RTP for audio transfer between Skinny clients or H.323 terminals.",
          "name": "skinny",
          "nbarId": "63",
          "p2pTechnology": "false",
          "popularity": 10,
          "rank": 65535,
          "references": "http://www.cisco.com/en/US/tech/tk652/tk701/tk589/tsd_technology_support_sub-protocol_home.html",
          "selectorId": "63",
          "subCategory": "68c4f989-5735-4e16-8d1c-6a9d89d5df9c",
          "trafficClass": "SIGNALING",
          "tunnel": "false"
        }
      ],
      "networkIdentity": [
        {
          "id": "34d858a7-2ddc-43d5-b5fb-3afb5c96e3d0",
          "instanceId": 17102,
          "authEntityId": 17102,
          "displayName": "17102",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "2000,2001,2002",
          "protocol": "TCP",
          "upperPort": 0
        }
      ],
      "parentScalableGroup": {
        "idRef": "a3243a74-3864-4041-a927-0201030da17c"
      },
      "user": [

      ],
      "userGroup": [

      ]
    },
    {
      "id": "ed2a213b-d198-4fb9-8361-eacb714f68c2",
      "instanceId": 4751,
      "authEntityId": 4751,
      "displayName": "4751",
      "authEntityClass": -1909073334,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "createTime": 1517966743421,
      "deployed": false,
      "isSeeded": true,
      "isStale": false,
      "lastUpdateTime": 1517966743421,
      "name": "mit-ml-dev",
      "namespace": "scalablegroup:application",
      "provisioningState": "DEFINED",
      "qualifier": "application",
      "resourceVersion": 0,
      "targetIdList": [

      ],
      "type": "scalablegroup",
      "cfsChangeInfo": [

      ],
      "customProvisions": [

      ],
      "scalableGroupExternalHandle": "mit-ml-dev",
      "scalableGroupType": "APPLICATION",
      "securityGroupTag": 0,
      "state": "ACTIVE",
      "vnAgnostic": true,
      "identitySource": {
        "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
        "instanceId": 10010,
        "authEntityId": 10010,
        "displayName": "10010",
        "authEntityClass": 1738953278,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "state": "INACTIVE",
        "type": "NBAR"
      },
      "indicativeNetworkIdentity": [
        {
          "id": "0db8f404-720d-48ce-a988-e702b72d2c61",
          "instanceId": 13918,
          "authEntityId": 13918,
          "displayName": "13918",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "83",
          "protocol": "TCP",
          "upperPort": 0
        },
        {
          "id": "585f22db-942b-4171-804c-862237a9d17c",
          "instanceId": 13919,
          "authEntityId": 13919,
          "displayName": "13919",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "83",
          "protocol": "UDP",
          "upperPort": 0
        }
      ],
      "networkApplications": [
        {
          "id": "9a02e22a-dbdb-4fc6-870c-472516d3cdab",
          "instanceId": 11701,
          "authEntityId": 11701,
          "displayName": "11701",
          "authEntityClass": -217092956,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "appProtocol": "tcp/udp",
          "applicationGroup": "other",
          "applicationType": "DEFAULT",
          "categoryId": "6daa3dfd-3526-407a-88f0-97824b3f3a24",
          "createTime": 1517966743421,
          "encrypted": "false",
          "engineId": "3",
          "helpString": "MIT ML Device",
          "ignoreConflict": false,
          "lastUpdateTime": 1517966743421,
          "longDescription": "Registered with IANA on port 83 TCP/UDP",
          "name": "mit-ml-dev",
          "nbarId": "956",
          "p2pTechnology": "false",
          "popularity": 1,
          "rank": 65535,
          "references": "http://www.iana.org/assignments/service-names-port-numbers/service-names-port-numbers.xml",
          "selectorId": "83",
          "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
          "trafficClass": "BULK_DATA",
          "tunnel": "false"
        }
      ],
      "networkIdentity": [
        {
          "id": "dabcc0bc-a5e4-45fb-b6e1-84db0ba6ff1e",
          "instanceId": 13916,
          "authEntityId": 13916,
          "displayName": "13916",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "83",
          "protocol": "TCP",
          "upperPort": 0
        },
        {
          "id": "43fb997d-3ae7-4e05-ab18-0ac8fa44fc4b",
          "instanceId": 13917,
          "authEntityId": 13917,
          "displayName": "13917",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "83",
          "protocol": "UDP",
          "upperPort": 0
        }
      ],
      "parentScalableGroup": {
        "idRef": "000c15bf-28fe-4fbb-9b69-35e6502721f1"
      },
      "user": [

      ],
      "userGroup": [

      ]
    },
    {
      "id": "ed477169-0ea2-44df-a374-e8549a2fa711",
      "instanceId": 15157,
      "authEntityId": 15157,
      "displayName": "15157",
      "authEntityClass": -1909073334,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "createTime": 1517966744365,
      "deployed": false,
      "isSeeded": true,
      "isStale": false,
      "lastUpdateTime": 1517966744365,
      "name": "slate-magazine",
      "namespace": "scalablegroup:application",
      "provisioningState": "DEFINED",
      "qualifier": "application",
      "resourceVersion": 0,
      "targetIdList": [

      ],
      "type": "scalablegroup",
      "cfsChangeInfo": [

      ],
      "customProvisions": [

      ],
      "scalableGroupExternalHandle": "slate-magazine",
      "scalableGroupType": "APPLICATION",
      "securityGroupTag": 0,
      "state": "ACTIVE",
      "vnAgnostic": true,
      "identitySource": {
        "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
        "instanceId": 10010,
        "authEntityId": 10010,
        "displayName": "10010",
        "authEntityClass": 1738953278,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "state": "INACTIVE",
        "type": "NBAR"
      },
      "indicativeNetworkIdentity": [

      ],
      "networkApplications": [
        {
          "id": "cf6b0c93-ff03-4fa6-afb4-cb79209468e8",
          "instanceId": 16100,
          "authEntityId": 16100,
          "displayName": "16100",
          "authEntityClass": -217092956,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "appProtocol": "tcp/udp",
          "applicationGroup": "other",
          "applicationType": "DEFAULT",
          "categoryId": "878644b5-fe91-45b4-9204-31c84a73ba48",
          "createTime": 1517966744365,
          "encrypted": "false",
          "engineId": "13",
          "helpString": "Slate Magazine - Online daily magazine.",
          "ignoreConflict": false,
          "lastUpdateTime": 1517966744365,
          "longDescription": "Slate Magazine - Online daily magazine.",
          "name": "slate-magazine",
          "nbarId": "1650",
          "p2pTechnology": "false",
          "popularity": 3,
          "rank": 65535,
          "references": "http://slate.com",
          "selectorId": "720",
          "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
          "trafficClass": "TRANSACTIONAL_DATA",
          "tunnel": "false"
        }
      ],
      "networkIdentity": [
        {
          "id": "b5d86d3b-1b7f-416b-8c55-ea8ca3c27a7c",
          "instanceId": 17117,
          "authEntityId": 17117,
          "displayName": "17117",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "53,80,443,5353",
          "protocol": "TCP",
          "upperPort": 0
        },
        {
          "id": "be910fe5-abf9-481b-8e26-8c51c3e5a192",
          "instanceId": 17118,
          "authEntityId": 17118,
          "displayName": "17118",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "53,5353",
          "protocol": "UDP",
          "upperPort": 0
        }
      ],
      "parentScalableGroup": {
        "idRef": "28e08de4-d7b2-4521-84ce-bc1e5ee0b9cc"
      },
      "user": [

      ],
      "userGroup": [

      ]
    },
    {
      "id": "ed521744-6886-4437-ba8d-71f79f94d58e",
      "instanceId": 4615,
      "authEntityId": 4615,
      "displayName": "4615",
      "authEntityClass": -1909073334,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "createTime": 1517966743131,
      "deployed": false,
      "isSeeded": true,
      "isStale": false,
      "lastUpdateTime": 1517966743131,
      "name": "ip-messenger",
      "namespace": "scalablegroup:application",
      "provisioningState": "DEFINED",
      "qualifier": "application",
      "resourceVersion": 0,
      "targetIdList": [

      ],
      "type": "scalablegroup",
      "cfsChangeInfo": [

      ],
      "customProvisions": [

      ],
      "scalableGroupExternalHandle": "ip-messenger",
      "scalableGroupType": "APPLICATION",
      "securityGroupTag": 0,
      "state": "ACTIVE",
      "vnAgnostic": true,
      "identitySource": {
        "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
        "instanceId": 10010,
        "authEntityId": 10010,
        "displayName": "10010",
        "authEntityClass": 1738953278,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "state": "INACTIVE",
        "type": "NBAR"
      },
      "indicativeNetworkIdentity": [

      ],
      "networkApplications": [
        {
          "id": "69801091-9fba-42c0-b380-476ed2099273",
          "instanceId": 11565,
          "authEntityId": 11565,
          "displayName": "11565",
          "authEntityClass": -217092956,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "applicationGroup": "other",
          "applicationType": "DEFAULT",
          "categoryId": "0f6cab47-be95-4124-9db3-360ccd55f983",
          "createTime": 1517966743131,
          "encrypted": "false",
          "engineId": "13",
          "helpString": "Instant messaging over IP application",
          "ignoreConflict": false,
          "lastUpdateTime": 1517966743131,
          "longDescription": "IP Messenger is a LAN Messenger for multi platforms (Windows, Mac OS, iPhone, Android). It is based on TCP/IP (UDP). It does not require server machine, its simple, lightweight and has compact size. This messenger provides instant messaging and file-transfer services.",
          "name": "ip-messenger",
          "nbarId": "1326",
          "p2pTechnology": "false",
          "popularity": 4,
          "rank": 65535,
          "references": "http://ipmsg.org/index.html.en",
          "selectorId": "475",
          "subCategory": "118c10a2-e272-47c1-92b9-4814ebe97138",
          "trafficClass": "TRANSACTIONAL_DATA",
          "tunnel": "false"
        }
      ],
      "networkIdentity": [

      ],
      "parentScalableGroup": {
        "idRef": "fd6264c9-c16a-4311-a335-a3db039256f3"
      },
      "user": [

      ],
      "userGroup": [

      ]
    },
    {
      "id": "ed6a96d0-3429-4454-a92a-10a528e6c675",
      "instanceId": 15245,
      "authEntityId": 15245,
      "displayName": "15245",
      "authEntityClass": -1909073334,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "createTime": 1517966744570,
      "deployed": false,
      "isSeeded": true,
      "isStale": false,
      "lastUpdateTime": 1517966744570,
      "name": "synotics-relay",
      "namespace": "scalablegroup:application",
      "provisioningState": "DEFINED",
      "qualifier": "application",
      "resourceVersion": 0,
      "targetIdList": [

      ],
      "type": "scalablegroup",
      "cfsChangeInfo": [

      ],
      "customProvisions": [

      ],
      "scalableGroupExternalHandle": "synotics-relay",
      "scalableGroupType": "APPLICATION",
      "securityGroupTag": 0,
      "state": "ACTIVE",
      "vnAgnostic": true,
      "identitySource": {
        "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
        "instanceId": 10010,
        "authEntityId": 10010,
        "displayName": "10010",
        "authEntityClass": 1738953278,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "state": "INACTIVE",
        "type": "NBAR"
      },
      "indicativeNetworkIdentity": [
        {
          "id": "f11047a2-97f5-4f2b-8658-4aca143a01cd",
          "instanceId": 17374,
          "authEntityId": 17374,
          "displayName": "17374",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "391",
          "protocol": "UDP",
          "upperPort": 0
        },
        {
          "id": "7e2cf7a1-a94e-4e1b-aaa1-b2779c5d7fb4",
          "instanceId": 17375,
          "authEntityId": 17375,
          "displayName": "17375",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "391",
          "protocol": "TCP",
          "upperPort": 0
        }
      ],
      "networkApplications": [
        {
          "id": "73224e19-394e-4d18-a6e5-eb24c5523493",
          "instanceId": 16188,
          "authEntityId": 16188,
          "displayName": "16188",
          "authEntityClass": -217092956,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "appProtocol": "tcp/udp",
          "applicationGroup": "snmp-group",
          "applicationType": "DEFAULT",
          "categoryId": "1fc17362-9334-4c7b-b752-5afdbb574017",
          "createTime": 1517966744570,
          "encrypted": "false",
          "engineId": "3",
          "helpString": "SynOptics SNMP Relay Port",
          "ignoreConflict": false,
          "lastUpdateTime": 1517966744570,
          "longDescription": "Registered with IANA on port 391 TCP/UDP",
          "name": "synotics-relay",
          "nbarId": "307",
          "p2pTechnology": "false",
          "popularity": 1,
          "rank": 65535,
          "references": "http://www.iana.org/assignments/service-names-port-numbers/service-names-port-numbers.xml",
          "selectorId": "391",
          "subCategory": "f3c0f761-d894-4112-ab89-b90fc84fd660",
          "trafficClass": "OPS_ADMIN_MGMT",
          "tunnel": "false"
        }
      ],
      "networkIdentity": [
        {
          "id": "ef360ba5-dc0d-4103-993c-572eaa6c01aa",
          "instanceId": 17372,
          "authEntityId": 17372,
          "displayName": "17372",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "391",
          "protocol": "UDP",
          "upperPort": 0
        },
        {
          "id": "ff5933dc-da62-4fe7-bb15-678d39770791",
          "instanceId": 17373,
          "authEntityId": 17373,
          "displayName": "17373",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "391",
          "protocol": "TCP",
          "upperPort": 0
        }
      ],
      "parentScalableGroup": {
        "idRef": "5fcd74d4-8726-47f7-a088-c457863da7ef"
      },
      "user": [

      ],
      "userGroup": [

      ]
    },
    {
      "id": "eda7cdb4-b275-483c-996f-9b846e4e69dc",
      "instanceId": 4857,
      "authEntityId": 4857,
      "displayName": "4857",
      "authEntityClass": -1909073334,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "createTime": 1517966743626,
      "deployed": false,
      "isSeeded": true,
      "isStale": false,
      "lastUpdateTime": 1517966743626,
      "name": "netware-ip",
      "namespace": "scalablegroup:application",
      "provisioningState": "DEFINED",
      "qualifier": "application",
      "resourceVersion": 0,
      "targetIdList": [

      ],
      "type": "scalablegroup",
      "cfsChangeInfo": [

      ],
      "customProvisions": [

      ],
      "scalableGroupExternalHandle": "netware-ip",
      "scalableGroupType": "APPLICATION",
      "securityGroupTag": 0,
      "state": "ACTIVE",
      "vnAgnostic": true,
      "identitySource": {
        "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
        "instanceId": 10010,
        "authEntityId": 10010,
        "displayName": "10010",
        "authEntityClass": 1738953278,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "state": "INACTIVE",
        "type": "NBAR"
      },
      "indicativeNetworkIdentity": [
        {
          "id": "2d5a3230-5259-4690-b5f9-0cd844f008cb",
          "instanceId": 14210,
          "authEntityId": 14210,
          "displayName": "14210",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "396",
          "protocol": "TCP",
          "upperPort": 0
        },
        {
          "id": "450edbe0-311f-407e-a95f-82586999d842",
          "instanceId": 14211,
          "authEntityId": 14211,
          "displayName": "14211",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "396",
          "protocol": "UDP",
          "upperPort": 0
        }
      ],
      "networkApplications": [
        {
          "id": "b44ab852-3714-4de4-ad60-8addb5339945",
          "instanceId": 11806,
          "authEntityId": 11806,
          "displayName": "11806",
          "authEntityClass": -217092956,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "appProtocol": "tcp/udp",
          "applicationGroup": "other",
          "applicationType": "DEFAULT",
          "categoryId": "1fc17362-9334-4c7b-b752-5afdbb574017",
          "createTime": 1517966743626,
          "encrypted": "false",
          "engineId": "3",
          "helpString": "Novell Netware over IP",
          "ignoreConflict": false,
          "lastUpdateTime": 1517966743626,
          "longDescription": "NetWare is a computer network operating system by Novell. NetWare Over TCP/IP feature makes both NetWare Core Protocol (NCP) and Novell Directory Services (NDS) application-layer protocols in the Internet protocol suite without the need for IPX encapsulation or special naming support.",
          "name": "netware-ip",
          "nbarId": "311",
          "p2pTechnology": "false",
          "popularity": 1,
          "rank": 65535,
          "references": "http://support.novell.com/techcenter/articles/ana19970303.html",
          "selectorId": "396",
          "subCategory": "f3c0f761-d894-4112-ab89-b90fc84fd660",
          "trafficClass": "OPS_ADMIN_MGMT",
          "tunnel": "false"
        }
      ],
      "networkIdentity": [
        {
          "id": "181c778a-8fc6-428c-ae3a-7d0d6c3f499f",
          "instanceId": 14208,
          "authEntityId": 14208,
          "displayName": "14208",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "396",
          "protocol": "TCP",
          "upperPort": 0
        },
        {
          "id": "54e291f4-6b0e-40e6-9ad3-e41fe335df11",
          "instanceId": 14209,
          "authEntityId": 14209,
          "displayName": "14209",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "396",
          "protocol": "UDP",
          "upperPort": 0
        }
      ],
      "parentScalableGroup": {
        "idRef": "5fcd74d4-8726-47f7-a088-c457863da7ef"
      },
      "user": [

      ],
      "userGroup": [

      ]
    },
    {
      "id": "ede417f4-a082-4883-9495-9f38e6e6bd9b",
      "instanceId": 4380,
      "authEntityId": 4380,
      "displayName": "4380",
      "authEntityClass": -1909073334,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "createTime": 1517966742469,
      "deployed": false,
      "isSeeded": true,
      "isStale": false,
      "lastUpdateTime": 1517966742469,
      "name": "dnp",
      "namespace": "scalablegroup:application",
      "provisioningState": "DEFINED",
      "qualifier": "application",
      "resourceVersion": 0,
      "targetIdList": [

      ],
      "type": "scalablegroup",
      "cfsChangeInfo": [

      ],
      "customProvisions": [

      ],
      "scalableGroupExternalHandle": "dnp",
      "scalableGroupType": "APPLICATION",
      "securityGroupTag": 0,
      "state": "ACTIVE",
      "vnAgnostic": true,
      "identitySource": {
        "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
        "instanceId": 10010,
        "authEntityId": 10010,
        "displayName": "10010",
        "authEntityClass": 1738953278,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "state": "INACTIVE",
        "type": "NBAR"
      },
      "indicativeNetworkIdentity": [
        {
          "id": "71bc9106-fafe-46a3-97ba-3720c8bd09db",
          "instanceId": 12932,
          "authEntityId": 12932,
          "displayName": "12932",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "19999,20000",
          "protocol": "TCP",
          "upperPort": 0
        },
        {
          "id": "7b9feaeb-912f-4c38-b636-811bdf0636c2",
          "instanceId": 12933,
          "authEntityId": 12933,
          "displayName": "12933",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "19999,20000",
          "protocol": "UDP",
          "upperPort": 0
        }
      ],
      "networkApplications": [
        {
          "id": "cc5478fd-8644-4e79-be79-56408452f4d4",
          "instanceId": 11331,
          "authEntityId": 11331,
          "displayName": "11331",
          "authEntityClass": -217092956,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "appProtocol": "tcp/udp",
          "applicationGroup": "other",
          "applicationType": "DEFAULT",
          "categoryId": "d264d6f4-b014-43e6-acbc-516128d1c6c8",
          "createTime": 1517966742469,
          "encrypted": "false",
          "engineId": "3",
          "helpString": "Distributed Network Protocol (DNP3) over TCP",
          "ignoreConflict": false,
          "lastUpdateTime": 1517966742469,
          "longDescription": "Distributed Network Protocol (DNP3) is a set of open source and public communications protocols managed by DNP3 Users Group. It is developed for communications between various types of data acquisition and control equipment mainly in utilities such as electric and water companies. It is very important in SCADA systems. It was originally developed for serial connections, hence its architecture has a data link layer, a pseudo transport layer and an application layer, but with the advent of TCP/IP networks, it was ported to IP networks.",
          "name": "dnp",
          "nbarId": "1341",
          "p2pTechnology": "false",
          "popularity": 6,
          "rank": 65535,
          "references": "http://dnp3.org",
          "selectorId": "20000",
          "subCategory": "68c4f989-5735-4e16-8d1c-6a9d89d5df9c",
          "trafficClass": "SIGNALING",
          "tunnel": "false"
        }
      ],
      "networkIdentity": [
        {
          "id": "0815b9c7-702e-49fa-a3a2-951e2cdd52c8",
          "instanceId": 12930,
          "authEntityId": 12930,
          "displayName": "12930",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "19999,20000",
          "protocol": "UDP",
          "upperPort": 0
        },
        {
          "id": "aa0d9f40-5ec3-4309-a353-3fcce31bed69",
          "instanceId": 12931,
          "authEntityId": 12931,
          "displayName": "12931",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "19999,20000",
          "protocol": "TCP",
          "upperPort": 0
        }
      ],
      "parentScalableGroup": {
        "idRef": "a3243a74-3864-4041-a927-0201030da17c"
      },
      "user": [

      ],
      "userGroup": [

      ]
    },
    {
      "id": "ede67a2e-8e74-4412-b0f2-5de429b71e91",
      "instanceId": 4172,
      "authEntityId": 4172,
      "displayName": "4172",
      "authEntityClass": -1909073334,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "createTime": 1517966741527,
      "deployed": false,
      "isSeeded": true,
      "isStale": false,
      "lastUpdateTime": 1517966741527,
      "name": "bgmp",
      "namespace": "scalablegroup:application",
      "provisioningState": "DEFINED",
      "qualifier": "application",
      "resourceVersion": 0,
      "targetIdList": [

      ],
      "type": "scalablegroup",
      "cfsChangeInfo": [

      ],
      "customProvisions": [

      ],
      "scalableGroupExternalHandle": "bgmp",
      "scalableGroupType": "APPLICATION",
      "securityGroupTag": 0,
      "state": "ACTIVE",
      "vnAgnostic": true,
      "identitySource": {
        "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
        "instanceId": 10010,
        "authEntityId": 10010,
        "displayName": "10010",
        "authEntityClass": 1738953278,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "state": "INACTIVE",
        "type": "NBAR"
      },
      "indicativeNetworkIdentity": [
        {
          "id": "b944cb14-7700-41c2-b0b7-5b89b7e908b6",
          "instanceId": 12364,
          "authEntityId": 12364,
          "displayName": "12364",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "264",
          "protocol": "UDP",
          "upperPort": 0
        },
        {
          "id": "0e2bcd1f-4397-45bb-8007-0caf174457a0",
          "instanceId": 12365,
          "authEntityId": 12365,
          "displayName": "12365",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "264",
          "protocol": "TCP",
          "upperPort": 0
        }
      ],
      "networkApplications": [
        {
          "id": "3c51ff60-b614-450a-b7ca-2f68b030e9d0",
          "instanceId": 11131,
          "authEntityId": 11131,
          "displayName": "11131",
          "authEntityClass": -217092956,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "appProtocol": "tcp/udp",
          "applicationGroup": "other",
          "applicationType": "DEFAULT",
          "categoryId": "1fc17362-9334-4c7b-b752-5afdbb574017",
          "createTime": 1517966741527,
          "encrypted": "false",
          "engineId": "3",
          "helpString": "BGMP",
          "ignoreConflict": false,
          "lastUpdateTime": 1517966741527,
          "longDescription": "The Border Gateway Multicast Protocol (BGMP) is an IETF on-going project in an attempt to design a true inter-domain multicast routing protocol. BGMP should be able to scale in order to operate in the global Internet.",
          "name": "bgmp",
          "nbarId": "1136",
          "p2pTechnology": "false",
          "popularity": 1,
          "rank": 65535,
          "references": "http://www.ietf.org/rfc/rfc3913.txt",
          "selectorId": "264",
          "subCategory": "7cc1b3a6-4102-44e9-b522-0e71afe39a03",
          "trafficClass": "NETWORK_CONTROL",
          "tunnel": "false"
        }
      ],
      "networkIdentity": [
        {
          "id": "a42d32a0-abaf-44a5-a882-bbbb466fcffc",
          "instanceId": 12362,
          "authEntityId": 12362,
          "displayName": "12362",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "264",
          "protocol": "TCP",
          "upperPort": 0
        },
        {
          "id": "3657feb2-dade-46dd-91dc-8ca06a505c0b",
          "instanceId": 12363,
          "authEntityId": 12363,
          "displayName": "12363",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "264",
          "protocol": "UDP",
          "upperPort": 0
        }
      ],
      "parentScalableGroup": {
        "idRef": "77e75842-e98b-4112-ad0c-300db4bb11be"
      },
      "user": [

      ],
      "userGroup": [

      ]
    },
    {
      "id": "ededa73b-acb2-4ffc-9ba1-e96ecf08c733",
      "instanceId": 4048,
      "authEntityId": 4048,
      "displayName": "4048",
      "authEntityClass": -1909073334,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "createTime": 1517966740591,
      "deployed": false,
      "isSeeded": true,
      "isStale": false,
      "lastUpdateTime": 1517966740591,
      "name": "acap",
      "namespace": "scalablegroup:application",
      "provisioningState": "DEFINED",
      "qualifier": "application",
      "resourceVersion": 0,
      "targetIdList": [

      ],
      "type": "scalablegroup",
      "cfsChangeInfo": [

      ],
      "customProvisions": [

      ],
      "scalableGroupExternalHandle": "acap",
      "scalableGroupType": "APPLICATION",
      "securityGroupTag": 0,
      "state": "ACTIVE",
      "vnAgnostic": true,
      "identitySource": {
        "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
        "instanceId": 10010,
        "authEntityId": 10010,
        "displayName": "10010",
        "authEntityClass": 1738953278,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "state": "INACTIVE",
        "type": "NBAR"
      },
      "indicativeNetworkIdentity": [
        {
          "id": "d9c532b2-a6d3-4f33-8782-b9d0257f151f",
          "instanceId": 12046,
          "authEntityId": 12046,
          "displayName": "12046",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "674",
          "protocol": "UDP",
          "upperPort": 0
        },
        {
          "id": "5680238e-8833-48fc-a5d6-0724517a0e5e",
          "instanceId": 12047,
          "authEntityId": 12047,
          "displayName": "12047",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "674",
          "protocol": "TCP",
          "upperPort": 0
        }
      ],
      "networkApplications": [
        {
          "id": "f7ddda71-6e4f-42fe-b152-5eeee2471a3a",
          "instanceId": 11021,
          "authEntityId": 11021,
          "displayName": "11021",
          "authEntityClass": -217092956,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "appProtocol": "tcp/udp",
          "applicationGroup": "other",
          "applicationType": "DEFAULT",
          "categoryId": "1fc17362-9334-4c7b-b752-5afdbb574017",
          "createTime": 1517966740591,
          "encrypted": "false",
          "engineId": "3",
          "helpString": "ACAP",
          "ignoreConflict": false,
          "lastUpdateTime": 1517966740591,
          "longDescription": "The Application Configuration Access Protocol (ACAP) is a protocol for storing and synchronizing general configuration and preference data. It was originally developed so that IMAP clients can easily access address books, user options, and other data on a central server and be kept in sync across all clients.",
          "name": "acap",
          "nbarId": "582",
          "p2pTechnology": "false",
          "popularity": 1,
          "rank": 65535,
          "references": "http://www.ietf.org/rfc/rfc2244.txt",
          "selectorId": "674",
          "subCategory": "f3c0f761-d894-4112-ab89-b90fc84fd660",
          "trafficClass": "OPS_ADMIN_MGMT",
          "tunnel": "false"
        }
      ],
      "networkIdentity": [
        {
          "id": "4f60ae2c-ef55-4b29-8cf5-4539e854892c",
          "instanceId": 12044,
          "authEntityId": 12044,
          "displayName": "12044",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "674",
          "protocol": "TCP",
          "upperPort": 0
        },
        {
          "id": "ba4e9ab8-a416-4afc-aced-a25c06a1b7bd",
          "instanceId": 12045,
          "authEntityId": 12045,
          "displayName": "12045",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "674",
          "protocol": "UDP",
          "upperPort": 0
        }
      ],
      "parentScalableGroup": {
        "idRef": "5fcd74d4-8726-47f7-a088-c457863da7ef"
      },
      "user": [

      ],
      "userGroup": [

      ]
    },
    {
      "id": "edf3e41b-1f16-4e1e-9095-e5be23eadb08",
      "instanceId": 4996,
      "authEntityId": 4996,
      "displayName": "4996",
      "authEntityClass": -1909073334,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "createTime": 1517966744006,
      "deployed": false,
      "isSeeded": true,
      "isStale": false,
      "lastUpdateTime": 1517966744006,
      "name": "pump",
      "namespace": "scalablegroup:application",
      "provisioningState": "DEFINED",
      "qualifier": "application",
      "resourceVersion": 0,
      "targetIdList": [

      ],
      "type": "scalablegroup",
      "cfsChangeInfo": [

      ],
      "customProvisions": [

      ],
      "scalableGroupExternalHandle": "pump",
      "scalableGroupType": "APPLICATION",
      "securityGroupTag": 0,
      "state": "ACTIVE",
      "vnAgnostic": true,
      "identitySource": {
        "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
        "instanceId": 10010,
        "authEntityId": 10010,
        "displayName": "10010",
        "authEntityClass": 1738953278,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "state": "INACTIVE",
        "type": "NBAR"
      },
      "indicativeNetworkIdentity": [
        {
          "id": "01a02725-c1e7-485a-a420-c1e5c2b25122",
          "instanceId": 14646,
          "authEntityId": 14646,
          "displayName": "14646",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "751",
          "protocol": "UDP",
          "upperPort": 0
        },
        {
          "id": "c6313707-8f03-41ab-950a-0f7d86e55e8a",
          "instanceId": 14647,
          "authEntityId": 14647,
          "displayName": "14647",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "751",
          "protocol": "TCP",
          "upperPort": 0
        }
      ],
      "networkApplications": [
        {
          "id": "332c4ad6-52fa-4b63-97ea-253833d5d793",
          "instanceId": 11945,
          "authEntityId": 11945,
          "displayName": "11945",
          "authEntityClass": -217092956,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "appProtocol": "tcp/udp",
          "applicationGroup": "other",
          "applicationType": "DEFAULT",
          "categoryId": "6daa3dfd-3526-407a-88f0-97824b3f3a24",
          "createTime": 1517966744006,
          "encrypted": "false",
          "engineId": "3",
          "helpString": "pump",
          "ignoreConflict": false,
          "lastUpdateTime": 1517966744006,
          "longDescription": "Registered with IANA on port 751 TCP/UDP",
          "name": "pump",
          "nbarId": "626",
          "p2pTechnology": "false",
          "popularity": 1,
          "rank": 65535,
          "references": "http://www.iana.org/assignments/service-names-port-numbers/service-names-port-numbers.xml",
          "selectorId": "751",
          "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
          "trafficClass": "BULK_DATA",
          "tunnel": "false"
        }
      ],
      "networkIdentity": [
        {
          "id": "fb4ea9e7-807c-4fbd-a0c2-1a2654637dd5",
          "instanceId": 14644,
          "authEntityId": 14644,
          "displayName": "14644",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "751",
          "protocol": "TCP",
          "upperPort": 0
        },
        {
          "id": "09f1c240-1477-4daf-abc2-2bed37e957d2",
          "instanceId": 14645,
          "authEntityId": 14645,
          "displayName": "14645",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "751",
          "protocol": "UDP",
          "upperPort": 0
        }
      ],
      "parentScalableGroup": {
        "idRef": "000c15bf-28fe-4fbb-9b69-35e6502721f1"
      },
      "user": [

      ],
      "userGroup": [

      ]
    },
    {
      "id": "ee289a88-a1da-4f4e-ac28-c33134c14c38",
      "instanceId": 4035,
      "authEntityId": 4035,
      "displayName": "4035",
      "authEntityClass": -1909073334,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "createTime": 1517966739933,
      "deployed": false,
      "isSeeded": true,
      "isStale": false,
      "lastUpdateTime": 1517966739933,
      "name": "3com-amp3",
      "namespace": "scalablegroup:application",
      "provisioningState": "DEFINED",
      "qualifier": "application",
      "resourceVersion": 0,
      "targetIdList": [

      ],
      "type": "scalablegroup",
      "cfsChangeInfo": [

      ],
      "customProvisions": [

      ],
      "scalableGroupExternalHandle": "3com-amp3",
      "scalableGroupType": "APPLICATION",
      "securityGroupTag": 0,
      "state": "ACTIVE",
      "vnAgnostic": true,
      "identitySource": {
        "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
        "instanceId": 10010,
        "authEntityId": 10010,
        "displayName": "10010",
        "authEntityClass": 1738953278,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "state": "INACTIVE",
        "type": "NBAR"
      },
      "indicativeNetworkIdentity": [
        {
          "id": "3068d647-5e5d-45da-b1e5-482d1172e92b",
          "instanceId": 12014,
          "authEntityId": 12014,
          "displayName": "12014",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "629",
          "protocol": "UDP",
          "upperPort": 0
        },
        {
          "id": "cccc673c-3bc6-4fa3-a972-6d075d63e2ba",
          "instanceId": 12015,
          "authEntityId": 12015,
          "displayName": "12015",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "629",
          "protocol": "TCP",
          "upperPort": 0
        }
      ],
      "networkApplications": [
        {
          "id": "de4b01eb-e275-4565-802d-3261f0bde9d1",
          "instanceId": 11011,
          "authEntityId": 11011,
          "displayName": "11011",
          "authEntityClass": -217092956,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "appProtocol": "tcp/udp",
          "applicationGroup": "other",
          "applicationType": "DEFAULT",
          "categoryId": "6daa3dfd-3526-407a-88f0-97824b3f3a24",
          "createTime": 1517966739930,
          "encrypted": "false",
          "engineId": "3",
          "helpString": "3Com AMP3",
          "ignoreConflict": false,
          "lastUpdateTime": 1517966739930,
          "longDescription": "Registered with IANA on port 629 TCP/UDP",
          "name": "3com-amp3",
          "nbarId": "538",
          "p2pTechnology": "false",
          "popularity": 1,
          "rank": 65535,
          "references": "http://www.iana.org/assignments/service-names-port-numbers/service-names-port-numbers.xml",
          "selectorId": "629",
          "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
          "trafficClass": "BULK_DATA",
          "tunnel": "false"
        }
      ],
      "networkIdentity": [
        {
          "id": "9938cc3c-0364-4102-8433-deb45b4b3895",
          "instanceId": 12012,
          "authEntityId": 12012,
          "displayName": "12012",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "629",
          "protocol": "UDP",
          "upperPort": 0
        },
        {
          "id": "7885d089-fe8b-414a-8a06-c1f5fc8df9b8",
          "instanceId": 12013,
          "authEntityId": 12013,
          "displayName": "12013",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "629",
          "protocol": "TCP",
          "upperPort": 0
        }
      ],
      "parentScalableGroup": {
        "idRef": "000c15bf-28fe-4fbb-9b69-35e6502721f1"
      },
      "user": [

      ],
      "userGroup": [

      ]
    },
    {
      "id": "ee39e947-5358-4ea5-9764-86b1eb72266b",
      "instanceId": 4502,
      "authEntityId": 4502,
      "displayName": "4502",
      "authEntityClass": -1909073334,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "createTime": 1517966742829,
      "deployed": false,
      "isSeeded": true,
      "isStale": false,
      "lastUpdateTime": 1517966742829,
      "name": "google-services-media",
      "namespace": "scalablegroup:application",
      "provisioningState": "DEFINED",
      "qualifier": "application",
      "resourceVersion": 0,
      "targetIdList": [

      ],
      "type": "scalablegroup",
      "cfsChangeInfo": [

      ],
      "customProvisions": [

      ],
      "scalableGroupExternalHandle": "google-services-media",
      "scalableGroupType": "APPLICATION",
      "securityGroupTag": 0,
      "state": "ACTIVE",
      "vnAgnostic": true,
      "identitySource": {
        "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
        "instanceId": 10010,
        "authEntityId": 10010,
        "displayName": "10010",
        "authEntityClass": 1738953278,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "state": "INACTIVE",
        "type": "NBAR"
      },
      "indicativeNetworkIdentity": [

      ],
      "networkApplications": [
        {
          "id": "12fd147b-8d2c-4851-90a5-cf20cbe38846",
          "instanceId": 11453,
          "authEntityId": 11453,
          "displayName": "11453",
          "authEntityClass": -217092956,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "applicationGroup": "other",
          "applicationType": "DEFAULT",
          "categoryId": "38a8e354-e2fe-4f3c-b1c5-2bbb90fb0d99",
          "createTime": 1517966742829,
          "encrypted": "false",
          "engineId": "13",
          "helpString": "Google Services Media",
          "ignoreConflict": false,
          "lastUpdateTime": 1517966742829,
          "longDescription": "Media streaming related to various Google services, APIs and collaboration software.",
          "name": "google-services-media",
          "nbarId": "1071",
          "p2pTechnology": "false",
          "popularity": 10,
          "rank": 65535,
          "references": "http://www.google.com",
          "selectorId": "1071",
          "subCategory": "53ce0342-1e2d-455a-acc7-1b50db9d6845",
          "trafficClass": "MULTIMEDIA_CONFERENCING",
          "tunnel": "false"
        }
      ],
      "networkIdentity": [

      ],
      "parentScalableGroup": {
        "idRef": "aad510ce-fe95-4dfc-ad06-e494641cc016"
      },
      "user": [

      ],
      "userGroup": [

      ]
    },
    {
      "id": "ee49ac11-e97d-464e-8a77-c0515c499acd",
      "instanceId": 4318,
      "authEntityId": 4318,
      "displayName": "4318",
      "authEntityClass": -1909073334,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "createTime": 1517966742271,
      "deployed": false,
      "isSeeded": true,
      "isStale": false,
      "lastUpdateTime": 1517966742271,
      "name": "cycleserv",
      "namespace": "scalablegroup:application",
      "provisioningState": "DEFINED",
      "qualifier": "application",
      "resourceVersion": 0,
      "targetIdList": [

      ],
      "type": "scalablegroup",
      "cfsChangeInfo": [

      ],
      "customProvisions": [

      ],
      "scalableGroupExternalHandle": "cycleserv",
      "scalableGroupType": "APPLICATION",
      "securityGroupTag": 0,
      "state": "ACTIVE",
      "vnAgnostic": true,
      "identitySource": {
        "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
        "instanceId": 10010,
        "authEntityId": 10010,
        "displayName": "10010",
        "authEntityClass": 1738953278,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "state": "INACTIVE",
        "type": "NBAR"
      },
      "indicativeNetworkIdentity": [
        {
          "id": "99b7a269-3fc8-470d-a98e-ea4f7ed2f777",
          "instanceId": 12732,
          "authEntityId": 12732,
          "displayName": "12732",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "763",
          "protocol": "UDP",
          "upperPort": 0
        },
        {
          "id": "6f920df6-159d-444b-837e-9fdd0b74e082",
          "instanceId": 12733,
          "authEntityId": 12733,
          "displayName": "12733",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "763",
          "protocol": "TCP",
          "upperPort": 0
        }
      ],
      "networkApplications": [
        {
          "id": "ffcc26a3-1222-4088-b403-ea38857f0973",
          "instanceId": 11270,
          "authEntityId": 11270,
          "displayName": "11270",
          "authEntityClass": -217092956,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "appProtocol": "tcp/udp",
          "applicationGroup": "other",
          "applicationType": "DEFAULT",
          "categoryId": "1fc17362-9334-4c7b-b752-5afdbb574017",
          "createTime": 1517966742271,
          "encrypted": "false",
          "engineId": "3",
          "helpString": "cycleserv",
          "ignoreConflict": false,
          "lastUpdateTime": 1517966742271,
          "longDescription": "Registered with IANA on port 763 TCP/UDP",
          "name": "cycleserv",
          "nbarId": "635",
          "p2pTechnology": "false",
          "popularity": 1,
          "rank": 65535,
          "references": "http://www.iana.org/assignments/service-names-port-numbers/service-names-port-numbers.xml",
          "selectorId": "763",
          "subCategory": "f3c0f761-d894-4112-ab89-b90fc84fd660",
          "trafficClass": "OPS_ADMIN_MGMT",
          "tunnel": "false"
        }
      ],
      "networkIdentity": [
        {
          "id": "2220bb88-392c-4c52-8ebb-d28ba48b7aa9",
          "instanceId": 12730,
          "authEntityId": 12730,
          "displayName": "12730",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "763",
          "protocol": "TCP",
          "upperPort": 0
        },
        {
          "id": "8884afe8-c581-47d5-8e66-32f0c0d7ba23",
          "instanceId": 12731,
          "authEntityId": 12731,
          "displayName": "12731",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "763",
          "protocol": "UDP",
          "upperPort": 0
        }
      ],
      "parentScalableGroup": {
        "idRef": "5fcd74d4-8726-47f7-a088-c457863da7ef"
      },
      "user": [

      ],
      "userGroup": [

      ]
    },
    {
      "id": "eeaa5344-b52c-44fb-b1c5-6745cce94b5b",
      "instanceId": 4483,
      "authEntityId": 4483,
      "displayName": "4483",
      "authEntityClass": -1909073334,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "createTime": 1517966742784,
      "deployed": false,
      "isSeeded": true,
      "isStale": false,
      "lastUpdateTime": 1517966742784,
      "name": "github",
      "namespace": "scalablegroup:application",
      "provisioningState": "DEFINED",
      "qualifier": "application",
      "resourceVersion": 0,
      "targetIdList": [

      ],
      "type": "scalablegroup",
      "cfsChangeInfo": [

      ],
      "customProvisions": [

      ],
      "scalableGroupExternalHandle": "github",
      "scalableGroupType": "APPLICATION",
      "securityGroupTag": 0,
      "state": "ACTIVE",
      "vnAgnostic": true,
      "identitySource": {
        "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
        "instanceId": 10010,
        "authEntityId": 10010,
        "displayName": "10010",
        "authEntityClass": 1738953278,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "state": "INACTIVE",
        "type": "NBAR"
      },
      "indicativeNetworkIdentity": [

      ],
      "networkApplications": [
        {
          "id": "87ceb1ab-61f9-49ac-aadc-7075a70142ba",
          "instanceId": 11434,
          "authEntityId": 11434,
          "displayName": "11434",
          "authEntityClass": -217092956,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "appProtocol": "tcp/udp",
          "applicationGroup": "other",
          "applicationType": "DEFAULT",
          "categoryId": "878644b5-fe91-45b4-9204-31c84a73ba48",
          "createTime": 1517966742784,
          "encrypted": "false",
          "engineId": "13",
          "helpString": "GitHub - Code management portal for open Source projects.",
          "ignoreConflict": false,
          "lastUpdateTime": 1517966742784,
          "longDescription": "GitHub - Code management portal for open Source projects.",
          "name": "github",
          "nbarId": "1554",
          "p2pTechnology": "false",
          "popularity": 3,
          "rank": 65535,
          "references": "http://github.com",
          "selectorId": "625",
          "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
          "trafficClass": "TRANSACTIONAL_DATA",
          "tunnel": "false"
        }
      ],
      "networkIdentity": [
        {
          "id": "614bb258-ddc6-4aa6-85d6-509aad2e49f4",
          "instanceId": 13207,
          "authEntityId": 13207,
          "displayName": "13207",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "53,80,443,5353",
          "protocol": "TCP",
          "upperPort": 0
        },
        {
          "id": "623335c3-2dfd-40a8-840e-e24beb2f4cf2",
          "instanceId": 13208,
          "authEntityId": 13208,
          "displayName": "13208",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "53,5353",
          "protocol": "UDP",
          "upperPort": 0
        }
      ],
      "parentScalableGroup": {
        "idRef": "28e08de4-d7b2-4521-84ce-bc1e5ee0b9cc"
      },
      "user": [

      ],
      "userGroup": [

      ]
    },
    {
      "id": "eec68fe6-83f7-4018-8948-e838c20290e2",
      "instanceId": 4498,
      "authEntityId": 4498,
      "displayName": "4498",
      "authEntityClass": -1909073334,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "createTime": 1517966742821,
      "deployed": false,
      "isSeeded": true,
      "isStale": false,
      "lastUpdateTime": 1517966742821,
      "name": "google-play",
      "namespace": "scalablegroup:application",
      "provisioningState": "DEFINED",
      "qualifier": "application",
      "resourceVersion": 0,
      "targetIdList": [

      ],
      "type": "scalablegroup",
      "cfsChangeInfo": [

      ],
      "customProvisions": [

      ],
      "scalableGroupExternalHandle": "google-play",
      "scalableGroupType": "APPLICATION",
      "securityGroupTag": 0,
      "state": "ACTIVE",
      "vnAgnostic": true,
      "identitySource": {
        "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
        "instanceId": 10010,
        "authEntityId": 10010,
        "displayName": "10010",
        "authEntityClass": 1738953278,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "state": "INACTIVE",
        "type": "NBAR"
      },
      "indicativeNetworkIdentity": [

      ],
      "networkApplications": [
        {
          "id": "b9a87ce4-8417-4e7e-b1c4-1900955c24f9",
          "instanceId": 11449,
          "authEntityId": 11449,
          "displayName": "11449",
          "authEntityClass": -217092956,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "appProtocol": "tcp/udp",
          "applicationGroup": "google-group",
          "applicationType": "DEFAULT",
          "categoryId": "9d9132a2-d99b-49db-a414-9fc93dea6604",
          "createTime": 1517966742821,
          "encrypted": "true",
          "engineId": "13",
          "helpString": "Google Store",
          "ignoreConflict": false,
          "lastUpdateTime": 1517966742821,
          "longDescription": "Google Play, formerly known as Android Market, is a Google operated digital store for applications developed with the Android operating system SDK, published through Google.",
          "name": "google-play",
          "nbarId": "1528",
          "p2pTechnology": "false",
          "popularity": 10,
          "rank": 65535,
          "references": "https://play.google.com",
          "selectorId": "589",
          "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
          "trafficClass": "BULK_DATA",
          "tunnel": "false"
        }
      ],
      "networkIdentity": [
        {
          "id": "68c24ac1-a2ce-41db-88f3-b5437eca8239",
          "instanceId": 13239,
          "authEntityId": 13239,
          "displayName": "13239",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "53,80,443,5353",
          "protocol": "TCP",
          "upperPort": 0
        },
        {
          "id": "c95a4b46-bf1e-4451-8fb6-5dd7ad1b4523",
          "instanceId": 13240,
          "authEntityId": 13240,
          "displayName": "13240",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "53,5353",
          "protocol": "UDP",
          "upperPort": 0
        }
      ],
      "parentScalableGroup": {
        "idRef": "4a68bc73-53c6-4deb-9983-784a0338aefe"
      },
      "user": [

      ],
      "userGroup": [

      ]
    },
    {
      "id": "eecf0a25-ea91-47b0-a2d3-3b35bc3257dd",
      "instanceId": 4194,
      "authEntityId": 4194,
      "displayName": "4194",
      "authEntityClass": -1909073334,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "createTime": 1517966741641,
      "deployed": false,
      "isSeeded": true,
      "isStale": false,
      "lastUpdateTime": 1517966741641,
      "name": "bluehost",
      "namespace": "scalablegroup:application",
      "provisioningState": "DEFINED",
      "qualifier": "application",
      "resourceVersion": 0,
      "targetIdList": [

      ],
      "type": "scalablegroup",
      "cfsChangeInfo": [

      ],
      "customProvisions": [

      ],
      "scalableGroupExternalHandle": "bluehost",
      "scalableGroupType": "APPLICATION",
      "securityGroupTag": 0,
      "state": "ACTIVE",
      "vnAgnostic": true,
      "identitySource": {
        "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
        "instanceId": 10010,
        "authEntityId": 10010,
        "displayName": "10010",
        "authEntityClass": 1738953278,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "state": "INACTIVE",
        "type": "NBAR"
      },
      "indicativeNetworkIdentity": [

      ],
      "networkApplications": [
        {
          "id": "50e87b0c-56d5-43ec-8bc6-c048d0efcf66",
          "instanceId": 11151,
          "authEntityId": 11151,
          "displayName": "11151",
          "authEntityClass": -217092956,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "appProtocol": "tcp/udp",
          "applicationGroup": "other",
          "applicationType": "DEFAULT",
          "categoryId": "878644b5-fe91-45b4-9204-31c84a73ba48",
          "createTime": 1517966741641,
          "encrypted": "false",
          "engineId": "13",
          "helpString": "Bluehost - Web hosting portal.",
          "ignoreConflict": false,
          "lastUpdateTime": 1517966741641,
          "longDescription": "Bluehost - Web hosting portal.",
          "name": "bluehost",
          "nbarId": "1680",
          "p2pTechnology": "false",
          "popularity": 3,
          "rank": 65535,
          "references": "http://bluehost.com",
          "selectorId": "750",
          "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
          "trafficClass": "TRANSACTIONAL_DATA",
          "tunnel": "false"
        }
      ],
      "networkIdentity": [
        {
          "id": "1ac340e5-492e-4163-8950-91738f67470f",
          "instanceId": 12416,
          "authEntityId": 12416,
          "displayName": "12416",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "53,5353",
          "protocol": "UDP",
          "upperPort": 0
        },
        {
          "id": "581d3d2f-a902-477a-b08a-f356dfe2aef1",
          "instanceId": 12415,
          "authEntityId": 12415,
          "displayName": "12415",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "53,80,443,5353",
          "protocol": "TCP",
          "upperPort": 0
        }
      ],
      "parentScalableGroup": {
        "idRef": "28e08de4-d7b2-4521-84ce-bc1e5ee0b9cc"
      },
      "user": [

      ],
      "userGroup": [

      ]
    },
    {
      "id": "ef0dbd75-8973-4a36-915a-47d28031a662",
      "instanceId": 4501,
      "authEntityId": 4501,
      "displayName": "4501",
      "authEntityClass": -1909073334,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "createTime": 1517966742827,
      "deployed": false,
      "isSeeded": true,
      "isStale": false,
      "lastUpdateTime": 1517966742827,
      "name": "google-services-audio",
      "namespace": "scalablegroup:application",
      "provisioningState": "DEFINED",
      "qualifier": "application",
      "resourceVersion": 0,
      "targetIdList": [

      ],
      "type": "scalablegroup",
      "cfsChangeInfo": [

      ],
      "customProvisions": [

      ],
      "scalableGroupExternalHandle": "google-services-audio",
      "scalableGroupType": "APPLICATION",
      "securityGroupTag": 0,
      "state": "ACTIVE",
      "vnAgnostic": true,
      "identitySource": {
        "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
        "instanceId": 10010,
        "authEntityId": 10010,
        "displayName": "10010",
        "authEntityClass": 1738953278,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "state": "INACTIVE",
        "type": "NBAR"
      },
      "indicativeNetworkIdentity": [

      ],
      "networkApplications": [
        {
          "id": "68616e72-c7e6-4de2-a39f-0ddd1d68f59f",
          "instanceId": 11452,
          "authEntityId": 11452,
          "displayName": "11452",
          "authEntityClass": -217092956,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "applicationGroup": "other",
          "applicationType": "DEFAULT",
          "categoryId": "38a8e354-e2fe-4f3c-b1c5-2bbb90fb0d99",
          "createTime": 1517966742827,
          "encrypted": "false",
          "engineId": "13",
          "helpString": "Google Services Audio",
          "ignoreConflict": false,
          "lastUpdateTime": 1517966742827,
          "longDescription": "Audio streaming related to various Google services, APIs and collaboration software.",
          "name": "google-services-audio",
          "nbarId": "1061",
          "p2pTechnology": "false",
          "popularity": 10,
          "rank": 65535,
          "references": "http://www.google.com",
          "selectorId": "1061",
          "subCategory": "05178016-4555-4291-ab4e-43c2ab2a931a",
          "trafficClass": "VOIP_TELEPHONY",
          "tunnel": "false"
        }
      ],
      "networkIdentity": [

      ],
      "parentScalableGroup": {
        "idRef": "aad510ce-fe95-4dfc-ad06-e494641cc016"
      },
      "user": [

      ],
      "userGroup": [

      ]
    },
    {
      "id": "ef12a690-e526-4194-a5d1-d3b8fa4ec710",
      "instanceId": 4352,
      "authEntityId": 4352,
      "displayName": "4352",
      "authEntityClass": -1909073334,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "createTime": 1517966742374,
      "deployed": false,
      "isSeeded": true,
      "isStale": false,
      "lastUpdateTime": 1517966742374,
      "name": "device",
      "namespace": "scalablegroup:application",
      "provisioningState": "DEFINED",
      "qualifier": "application",
      "resourceVersion": 0,
      "targetIdList": [

      ],
      "type": "scalablegroup",
      "cfsChangeInfo": [

      ],
      "customProvisions": [

      ],
      "scalableGroupExternalHandle": "device",
      "scalableGroupType": "APPLICATION",
      "securityGroupTag": 0,
      "state": "ACTIVE",
      "vnAgnostic": true,
      "identitySource": {
        "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
        "instanceId": 10010,
        "authEntityId": 10010,
        "displayName": "10010",
        "authEntityClass": 1738953278,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "state": "INACTIVE",
        "type": "NBAR"
      },
      "indicativeNetworkIdentity": [
        {
          "id": "cc408ed3-ac7b-4b9a-8e3c-30276ff41b27",
          "instanceId": 12838,
          "authEntityId": 12838,
          "displayName": "12838",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "801",
          "protocol": "UDP",
          "upperPort": 0
        },
        {
          "id": "9d85a40c-f629-4a2b-ac71-e54a9fd5e3bf",
          "instanceId": 12839,
          "authEntityId": 12839,
          "displayName": "12839",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "801",
          "protocol": "TCP",
          "upperPort": 0
        }
      ],
      "networkApplications": [
        {
          "id": "65ce98a0-aea0-4b8f-a153-10cab239d77d",
          "instanceId": 11303,
          "authEntityId": 11303,
          "displayName": "11303",
          "authEntityClass": -217092956,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "appProtocol": "tcp/udp",
          "applicationGroup": "other",
          "applicationType": "DEFAULT",
          "categoryId": "6daa3dfd-3526-407a-88f0-97824b3f3a24",
          "createTime": 1517966742374,
          "encrypted": "false",
          "engineId": "3",
          "helpString": "device",
          "ignoreConflict": false,
          "lastUpdateTime": 1517966742374,
          "longDescription": "Registered with IANA on port 801 TCP/UDP",
          "name": "device",
          "nbarId": "655",
          "p2pTechnology": "false",
          "popularity": 1,
          "rank": 65535,
          "references": "http://www.iana.org/assignments/service-names-port-numbers/service-names-port-numbers.xml",
          "selectorId": "801",
          "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
          "trafficClass": "BULK_DATA",
          "tunnel": "false"
        }
      ],
      "networkIdentity": [
        {
          "id": "9754b1af-e313-44d7-942c-ba2437fa0bb3",
          "instanceId": 12836,
          "authEntityId": 12836,
          "displayName": "12836",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "801",
          "protocol": "TCP",
          "upperPort": 0
        },
        {
          "id": "eb61e397-f522-4850-b9f9-39de1ad8d81a",
          "instanceId": 12837,
          "authEntityId": 12837,
          "displayName": "12837",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "801",
          "protocol": "UDP",
          "upperPort": 0
        }
      ],
      "parentScalableGroup": {
        "idRef": "000c15bf-28fe-4fbb-9b69-35e6502721f1"
      },
      "user": [

      ],
      "userGroup": [

      ]
    },
    {
      "id": "ef1fffeb-e150-412b-a8ee-77da603f8695",
      "instanceId": 4919,
      "authEntityId": 4919,
      "displayName": "4919",
      "authEntityClass": -1909073334,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "createTime": 1517966743764,
      "deployed": false,
      "isSeeded": true,
      "isStale": false,
      "lastUpdateTime": 1517966743764,
      "name": "oracle-ebsuite-unsecured",
      "namespace": "scalablegroup:application",
      "provisioningState": "DEFINED",
      "qualifier": "application",
      "resourceVersion": 0,
      "targetIdList": [

      ],
      "type": "scalablegroup",
      "cfsChangeInfo": [

      ],
      "customProvisions": [

      ],
      "scalableGroupExternalHandle": "oracle-ebsuite-unsecured",
      "scalableGroupType": "APPLICATION",
      "securityGroupTag": 0,
      "state": "ACTIVE",
      "vnAgnostic": true,
      "identitySource": {
        "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
        "instanceId": 10010,
        "authEntityId": 10010,
        "displayName": "10010",
        "authEntityClass": 1738953278,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "state": "INACTIVE",
        "type": "NBAR"
      },
      "indicativeNetworkIdentity": [

      ],
      "networkApplications": [
        {
          "id": "d4eed47d-a495-4dec-807c-a5d2307c95af",
          "instanceId": 11868,
          "authEntityId": 11868,
          "displayName": "11868",
          "authEntityClass": -217092956,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "appProtocol": "tcp",
          "applicationGroup": "other",
          "applicationType": "DEFAULT",
          "categoryId": "7e19fdea-6222-4c57-9e66-66c2340d6bc3",
          "createTime": 1517966743764,
          "encrypted": "false",
          "engineId": "13",
          "helpString": "Oracle E-Business Suite - Un-encrypted Traffic",
          "ignoreConflict": false,
          "lastUpdateTime": 1517966743764,
          "longDescription": "Oracle E-Business Suite (also known as Applications/Apps or EB-Suite/EBS) consists of a collection of enterprise resource planning (ERP), customer relationship management (CRM), and supply-chain management (SCM) computer applications. The software utilizes Oracle's core relational database management system technology.",
          "name": "oracle-ebsuite-unsecured",
          "nbarId": "1452",
          "p2pTechnology": "false",
          "popularity": 9,
          "rank": 65535,
          "references": "http://www.oracle.com/us/products/applications/ebusiness/index.html",
          "selectorId": "516",
          "subCategory": "b90786f4-0190-45b8-9924-21b3e9682590",
          "trafficClass": "TRANSACTIONAL_DATA",
          "tunnel": "false"
        }
      ],
      "networkIdentity": [
        {
          "id": "dcf7b360-0d96-4440-9ecc-0634644725d6",
          "instanceId": 14422,
          "authEntityId": 14422,
          "displayName": "14422",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "80",
          "protocol": "TCP",
          "upperPort": 0
        }
      ],
      "parentScalableGroup": {
        "idRef": "8718395e-683e-4a2d-a232-100202751271"
      },
      "user": [

      ],
      "userGroup": [

      ]
    },
    {
      "id": "effd1692-a38a-48af-93b6-152ff8b9b969",
      "instanceId": 4836,
      "authEntityId": 4836,
      "displayName": "4836",
      "authEntityClass": -1909073334,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "createTime": 1517966743586,
      "deployed": false,
      "isSeeded": true,
      "isStale": false,
      "lastUpdateTime": 1517966743586,
      "name": "netbios-ns",
      "namespace": "scalablegroup:application",
      "provisioningState": "DEFINED",
      "qualifier": "application",
      "resourceVersion": 0,
      "targetIdList": [

      ],
      "type": "scalablegroup",
      "cfsChangeInfo": [

      ],
      "customProvisions": [

      ],
      "scalableGroupExternalHandle": "netbios-ns",
      "scalableGroupType": "APPLICATION",
      "securityGroupTag": 0,
      "state": "ACTIVE",
      "vnAgnostic": true,
      "identitySource": {
        "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
        "instanceId": 10010,
        "authEntityId": 10010,
        "displayName": "10010",
        "authEntityClass": 1738953278,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "state": "INACTIVE",
        "type": "NBAR"
      },
      "indicativeNetworkIdentity": [
        {
          "id": "6e33e732-12f7-4bdb-88e1-99532f43b713",
          "instanceId": 14138,
          "authEntityId": 14138,
          "displayName": "14138",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "137",
          "protocol": "TCP",
          "upperPort": 0
        },
        {
          "id": "be3be4df-6e97-432b-806c-c68752245893",
          "instanceId": 14139,
          "authEntityId": 14139,
          "displayName": "14139",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "137",
          "protocol": "UDP",
          "upperPort": 0
        }
      ],
      "networkApplications": [
        {
          "id": "4e969f61-6c11-4c5d-973f-b92f92470439",
          "instanceId": 11785,
          "authEntityId": 11785,
          "displayName": "11785",
          "authEntityClass": -217092956,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "appProtocol": "tcp/udp",
          "applicationGroup": "netbios-group",
          "applicationType": "DEFAULT",
          "categoryId": "1fc17362-9334-4c7b-b752-5afdbb574017",
          "createTime": 1517966743586,
          "encrypted": "false",
          "engineId": "3",
          "helpString": "NetBIOS Naming Service",
          "ignoreConflict": false,
          "lastUpdateTime": 1517966743586,
          "longDescription": "NetBIOS Name Service is part of the NetBIOS-over-TCP/UDP protocol suite for name registration and resolution.  It provides services related to the session layer of the OSI model allowing applications on separate computers to communicate over a local area network. In NetBIOS, each participant must register on the network using a unique name. NetBIOS over TCP/IP can implement a central repository or Name Service that records all name registrations. It only supports IPV4 addresses and typically works on TCP/UDP port 137.",
          "name": "netbios-ns",
          "nbarId": "1421",
          "p2pTechnology": "false",
          "popularity": 10,
          "rank": 65535,
          "references": "http://publibz.boulder.ibm.com/cgi-bin/bookmgr_OS390/BOOKS/BK8P7001/1.12?DT=19960430153053",
          "selectorId": "137",
          "subCategory": "7f5fa07d-fcb4-4217-9908-b438066c0b60",
          "trafficClass": "OPS_ADMIN_MGMT",
          "tunnel": "false"
        }
      ],
      "networkIdentity": [
        {
          "id": "69cb2d78-bc3d-4db7-bbdf-65be649e9aaf",
          "instanceId": 14136,
          "authEntityId": 14136,
          "displayName": "14136",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "137",
          "protocol": "TCP",
          "upperPort": 0
        },
        {
          "id": "35afce32-773a-4326-8495-714856b10b08",
          "instanceId": 14137,
          "authEntityId": 14137,
          "displayName": "14137",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "137",
          "protocol": "UDP",
          "upperPort": 0
        }
      ],
      "parentScalableGroup": {
        "idRef": "38b9eb6b-f3b6-4e13-9b79-a11e0c6423d4"
      },
      "user": [

      ],
      "userGroup": [

      ]
    },
    {
      "id": "efffc1ab-3973-41bf-a9f0-eeecc0e56367",
      "instanceId": 15345,
      "authEntityId": 15345,
      "displayName": "15345",
      "authEntityClass": -1909073334,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "createTime": 1517966744777,
      "deployed": false,
      "isSeeded": true,
      "isStale": false,
      "lastUpdateTime": 1517966744777,
      "name": "vemmi",
      "namespace": "scalablegroup:application",
      "provisioningState": "DEFINED",
      "qualifier": "application",
      "resourceVersion": 0,
      "targetIdList": [

      ],
      "type": "scalablegroup",
      "cfsChangeInfo": [

      ],
      "customProvisions": [

      ],
      "scalableGroupExternalHandle": "vemmi",
      "scalableGroupType": "APPLICATION",
      "securityGroupTag": 0,
      "state": "ACTIVE",
      "vnAgnostic": true,
      "identitySource": {
        "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
        "instanceId": 10010,
        "authEntityId": 10010,
        "displayName": "10010",
        "authEntityClass": 1738953278,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "state": "INACTIVE",
        "type": "NBAR"
      },
      "indicativeNetworkIdentity": [
        {
          "id": "624aa18b-e1ef-445c-9888-69a5d4c94999",
          "instanceId": 17659,
          "authEntityId": 17659,
          "displayName": "17659",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "575",
          "protocol": "UDP",
          "upperPort": 0
        },
        {
          "id": "04b8b350-64d0-4085-ad44-0d6e0aecdad7",
          "instanceId": 17660,
          "authEntityId": 17660,
          "displayName": "17660",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "575",
          "protocol": "TCP",
          "upperPort": 0
        }
      ],
      "networkApplications": [
        {
          "id": "20d6c41d-1b65-4385-9217-49495a7c78dc",
          "instanceId": 16288,
          "authEntityId": 16288,
          "displayName": "16288",
          "authEntityClass": -217092956,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "appProtocol": "tcp/udp",
          "applicationGroup": "other",
          "applicationType": "DEFAULT",
          "categoryId": "6daa3dfd-3526-407a-88f0-97824b3f3a24",
          "createTime": 1517966744777,
          "encrypted": "false",
          "engineId": "3",
          "helpString": "vemmi",
          "ignoreConflict": false,
          "lastUpdateTime": 1517966744777,
          "longDescription": "VEMMI is an international standard defining the user interface and client/server protocol for on-line multimedia interactive services. VEMMI allows to create on-line multimedia and hypermedia services that could be accessed from any user owning a PC or Mac personal computer running a VEMMI client software, using any network (Internet, ISDN, videotex etc.) to access the multimedia server.",
          "name": "vemmi",
          "nbarId": "489",
          "p2pTechnology": "false",
          "popularity": 1,
          "rank": 65535,
          "references": "http://tools.ietf.org/html/rfc2122",
          "selectorId": "575",
          "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
          "trafficClass": "BULK_DATA",
          "tunnel": "false"
        }
      ],
      "networkIdentity": [
        {
          "id": "d85ec805-99fe-4f1f-a60b-9025993046ab",
          "instanceId": 17657,
          "authEntityId": 17657,
          "displayName": "17657",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "575",
          "protocol": "UDP",
          "upperPort": 0
        },
        {
          "id": "d0e969c5-ca03-48d1-b514-657fbf6dd00d",
          "instanceId": 17658,
          "authEntityId": 17658,
          "displayName": "17658",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "575",
          "protocol": "TCP",
          "upperPort": 0
        }
      ],
      "parentScalableGroup": {
        "idRef": "000c15bf-28fe-4fbb-9b69-35e6502721f1"
      },
      "user": [

      ],
      "userGroup": [

      ]
    },
    {
      "id": "f0096fd9-3317-40b8-b9ff-008e255bc64b",
      "instanceId": 15440,
      "authEntityId": 15440,
      "displayName": "15440",
      "authEntityClass": -1909073334,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "createTime": 1517966744969,
      "deployed": false,
      "isSeeded": true,
      "isStale": false,
      "lastUpdateTime": 1517966744969,
      "name": "xnet",
      "namespace": "scalablegroup:application",
      "provisioningState": "DEFINED",
      "qualifier": "application",
      "resourceVersion": 0,
      "targetIdList": [

      ],
      "type": "scalablegroup",
      "cfsChangeInfo": [

      ],
      "customProvisions": [

      ],
      "scalableGroupExternalHandle": "xnet",
      "scalableGroupType": "APPLICATION",
      "securityGroupTag": 0,
      "state": "ACTIVE",
      "vnAgnostic": true,
      "identitySource": {
        "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
        "instanceId": 10010,
        "authEntityId": 10010,
        "displayName": "10010",
        "authEntityClass": 1738953278,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "state": "INACTIVE",
        "type": "NBAR"
      },
      "indicativeNetworkIdentity": [
        {
          "id": "672ff210-6fde-48e5-82eb-7f7726b6cc57",
          "instanceId": 17904,
          "authEntityId": 17904,
          "displayName": "17904",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipProtocolNumbers": "15",
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "protocol": "XNET",
          "upperPort": 0
        }
      ],
      "networkApplications": [
        {
          "id": "877be82a-308e-40e6-b19a-c90cf9a3d801",
          "instanceId": 16383,
          "authEntityId": 16383,
          "displayName": "16383",
          "authEntityClass": -217092956,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "appProtocol": "ip",
          "applicationGroup": "other",
          "applicationType": "DEFAULT",
          "categoryId": "6daa3dfd-3526-407a-88f0-97824b3f3a24",
          "createTime": 1517966744969,
          "encrypted": "unassigned",
          "engineId": "1",
          "helpString": "Cross Net Debugger",
          "ignoreConflict": false,
          "lastUpdateTime": 1517966744969,
          "longDescription": "Cross Net Debugger allows a programmer at one computer on a network to debug a program which executes on another computer.",
          "name": "xnet",
          "nbarId": "770",
          "p2pTechnology": "unassigned",
          "popularity": 2,
          "rank": 65535,
          "references": "http://tools.ietf.org/html/rfc685",
          "selectorId": "15",
          "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
          "trafficClass": "BULK_DATA",
          "tunnel": "unassigned"
        }
      ],
      "networkIdentity": [
        {
          "id": "65b1aeb4-6290-4a4a-bf0f-f69c60372995",
          "instanceId": 17903,
          "authEntityId": 17903,
          "displayName": "17903",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipProtocolNumbers": "15",
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "protocol": "XNET",
          "upperPort": 0
        }
      ],
      "parentScalableGroup": {
        "idRef": "000c15bf-28fe-4fbb-9b69-35e6502721f1"
      },
      "user": [

      ],
      "userGroup": [

      ]
    },
    {
      "id": "f0227c6b-6361-4e48-8d8c-d3d518314ba2",
      "instanceId": 4103,
      "authEntityId": 4103,
      "displayName": "4103",
      "authEntityClass": -1909073334,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "createTime": 1517966741125,
      "deployed": false,
      "isSeeded": true,
      "isStale": false,
      "lastUpdateTime": 1517966741125,
      "name": "apple-pay",
      "namespace": "scalablegroup:application",
      "provisioningState": "DEFINED",
      "qualifier": "application",
      "resourceVersion": 0,
      "targetIdList": [

      ],
      "type": "scalablegroup",
      "cfsChangeInfo": [

      ],
      "customProvisions": [

      ],
      "scalableGroupExternalHandle": "apple-pay",
      "scalableGroupType": "APPLICATION",
      "securityGroupTag": 0,
      "state": "ACTIVE",
      "vnAgnostic": true,
      "identitySource": {
        "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
        "instanceId": 10010,
        "authEntityId": 10010,
        "displayName": "10010",
        "authEntityClass": 1738953278,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "state": "INACTIVE",
        "type": "NBAR"
      },
      "indicativeNetworkIdentity": [

      ],
      "networkApplications": [
        {
          "id": "838d36ba-e19b-4eae-a903-9d7653a2c637",
          "instanceId": 11066,
          "authEntityId": 11066,
          "displayName": "11066",
          "authEntityClass": -217092956,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "appProtocol": "tcp/udp",
          "applicationGroup": "apple-group",
          "applicationType": "DEFAULT",
          "categoryId": "f6ec0221-b82d-43e8-a64d-163cbc13263b",
          "createTime": 1517966741125,
          "encrypted": "true",
          "engineId": "13",
          "helpString": "Apple Pay",
          "ignoreConflict": false,
          "lastUpdateTime": 1517966741125,
          "longDescription": "Apple Pay is a mobile payment and digital wallet service by Apple Inc. that lets users make payments using an iPhone, Apple Watch, iPad or Mac.",
          "name": "apple-pay",
          "nbarId": "1117",
          "p2pTechnology": "false",
          "popularity": 7,
          "rank": 65535,
          "references": "https://www.apple.com/apple-pay/",
          "selectorId": "1117",
          "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
          "trafficClass": "TRANSACTIONAL_DATA",
          "tunnel": "false"
        }
      ],
      "networkIdentity": [
        {
          "id": "c8e56c01-5f3c-428a-8316-d2393a106558",
          "instanceId": 12164,
          "authEntityId": 12164,
          "displayName": "12164",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "53,5353",
          "protocol": "UDP",
          "upperPort": 0
        },
        {
          "id": "7baece9d-391c-4eb4-8d94-24511bd583a6",
          "instanceId": 12165,
          "authEntityId": 12165,
          "displayName": "12165",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "53,80,443,5353",
          "protocol": "TCP",
          "upperPort": 0
        }
      ],
      "parentScalableGroup": {
        "idRef": "5e66d25d-d517-482b-82a8-6f99f07fa786"
      },
      "user": [

      ],
      "userGroup": [

      ]
    },
    {
      "id": "f0650325-bd5b-4eac-a939-9b94e850c72d",
      "instanceId": 4731,
      "authEntityId": 4731,
      "displayName": "4731",
      "authEntityClass": -1909073334,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "createTime": 1517966743378,
      "deployed": false,
      "isSeeded": true,
      "isStale": false,
      "lastUpdateTime": 1517966743378,
      "name": "mcns-sec",
      "namespace": "scalablegroup:application",
      "provisioningState": "DEFINED",
      "qualifier": "application",
      "resourceVersion": 0,
      "targetIdList": [

      ],
      "type": "scalablegroup",
      "cfsChangeInfo": [

      ],
      "customProvisions": [

      ],
      "scalableGroupExternalHandle": "mcns-sec",
      "scalableGroupType": "APPLICATION",
      "securityGroupTag": 0,
      "state": "ACTIVE",
      "vnAgnostic": true,
      "identitySource": {
        "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
        "instanceId": 10010,
        "authEntityId": 10010,
        "displayName": "10010",
        "authEntityClass": 1738953278,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "state": "INACTIVE",
        "type": "NBAR"
      },
      "indicativeNetworkIdentity": [
        {
          "id": "f5322d2f-b5b0-4bf6-937b-b040cdbae771",
          "instanceId": 13852,
          "authEntityId": 13852,
          "displayName": "13852",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "638",
          "protocol": "TCP",
          "upperPort": 0
        },
        {
          "id": "e160fcb6-2fbe-4750-a7e0-1b74631da984",
          "instanceId": 13853,
          "authEntityId": 13853,
          "displayName": "13853",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "638",
          "protocol": "UDP",
          "upperPort": 0
        }
      ],
      "networkApplications": [
        {
          "id": "42326f9f-5e14-4017-a338-aaafe908962b",
          "instanceId": 11681,
          "authEntityId": 11681,
          "displayName": "11681",
          "authEntityClass": -217092956,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "appProtocol": "tcp/udp",
          "applicationGroup": "other",
          "applicationType": "DEFAULT",
          "categoryId": "6daa3dfd-3526-407a-88f0-97824b3f3a24",
          "createTime": 1517966743378,
          "encrypted": "false",
          "engineId": "3",
          "helpString": "mcns-sec",
          "ignoreConflict": false,
          "lastUpdateTime": 1517966743378,
          "longDescription": "Registered with IANA on port 638 TCP/UDP",
          "name": "mcns-sec",
          "nbarId": "547",
          "p2pTechnology": "false",
          "popularity": 1,
          "rank": 65535,
          "references": "http://www.iana.org/assignments/service-names-port-numbers/service-names-port-numbers.xml",
          "selectorId": "638",
          "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
          "trafficClass": "BULK_DATA",
          "tunnel": "false"
        }
      ],
      "networkIdentity": [
        {
          "id": "7edea110-b968-443c-a175-bd679660201f",
          "instanceId": 13850,
          "authEntityId": 13850,
          "displayName": "13850",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "638",
          "protocol": "TCP",
          "upperPort": 0
        },
        {
          "id": "d252a754-e2f7-412c-b0a2-1db8fd1cc38b",
          "instanceId": 13851,
          "authEntityId": 13851,
          "displayName": "13851",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "638",
          "protocol": "UDP",
          "upperPort": 0
        }
      ],
      "parentScalableGroup": {
        "idRef": "000c15bf-28fe-4fbb-9b69-35e6502721f1"
      },
      "user": [

      ],
      "userGroup": [

      ]
    },
    {
      "id": "f1299116-fb35-4172-a92c-f92fa806b4ab",
      "instanceId": 15305,
      "authEntityId": 15305,
      "displayName": "15305",
      "authEntityClass": -1909073334,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "createTime": 1517966744704,
      "deployed": false,
      "isSeeded": true,
      "isStale": false,
      "lastUpdateTime": 1517966744704,
      "name": "trunk-2",
      "namespace": "scalablegroup:application",
      "provisioningState": "DEFINED",
      "qualifier": "application",
      "resourceVersion": 0,
      "targetIdList": [

      ],
      "type": "scalablegroup",
      "cfsChangeInfo": [

      ],
      "customProvisions": [

      ],
      "scalableGroupExternalHandle": "trunk-2",
      "scalableGroupType": "APPLICATION",
      "securityGroupTag": 0,
      "state": "ACTIVE",
      "vnAgnostic": true,
      "identitySource": {
        "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
        "instanceId": 10010,
        "authEntityId": 10010,
        "displayName": "10010",
        "authEntityClass": 1738953278,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "state": "INACTIVE",
        "type": "NBAR"
      },
      "indicativeNetworkIdentity": [
        {
          "id": "720e1d9a-a65a-4070-9d29-913505895c52",
          "instanceId": 17539,
          "authEntityId": 17539,
          "displayName": "17539",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipProtocolNumbers": "24",
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "protocol": "TRUNK_2",
          "upperPort": 0
        }
      ],
      "networkApplications": [
        {
          "id": "df006457-0e44-4964-8577-16e70692816f",
          "instanceId": 16248,
          "authEntityId": 16248,
          "displayName": "16248",
          "authEntityClass": -217092956,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "appProtocol": "ip",
          "applicationGroup": "other",
          "applicationType": "DEFAULT",
          "categoryId": "6daa3dfd-3526-407a-88f0-97824b3f3a24",
          "createTime": 1517966744704,
          "encrypted": "unassigned",
          "engineId": "1",
          "helpString": "Trunk-2",
          "ignoreConflict": false,
          "lastUpdateTime": 1517966744704,
          "longDescription": "Registered with IANA as IP Protocol 24",
          "name": "trunk-2",
          "nbarId": "778",
          "p2pTechnology": "unassigned",
          "popularity": 1,
          "rank": 65535,
          "references": "http://www.iana.org/assignments/protocol-numbers/protocol-numbers.xml",
          "selectorId": "24",
          "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
          "trafficClass": "BULK_DATA",
          "tunnel": "unassigned"
        }
      ],
      "networkIdentity": [
        {
          "id": "6248072c-6da7-408e-92ff-3818c2f994f3",
          "instanceId": 17538,
          "authEntityId": 17538,
          "displayName": "17538",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipProtocolNumbers": "24",
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "protocol": "TRUNK_2",
          "upperPort": 0
        }
      ],
      "parentScalableGroup": {
        "idRef": "000c15bf-28fe-4fbb-9b69-35e6502721f1"
      },
      "user": [

      ],
      "userGroup": [

      ]
    },
    {
      "id": "f1a2346f-0880-4ce6-9391-567870f85043",
      "instanceId": 4783,
      "authEntityId": 4783,
      "displayName": "4783",
      "authEntityClass": -1909073334,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "createTime": 1517966743483,
      "deployed": false,
      "isSeeded": true,
      "isStale": false,
      "lastUpdateTime": 1517966743483,
      "name": "ms-office-web-apps",
      "namespace": "scalablegroup:application",
      "provisioningState": "DEFINED",
      "qualifier": "application",
      "resourceVersion": 0,
      "targetIdList": [

      ],
      "type": "scalablegroup",
      "cfsChangeInfo": [

      ],
      "customProvisions": [

      ],
      "scalableGroupExternalHandle": "ms-office-web-apps",
      "scalableGroupType": "APPLICATION",
      "securityGroupTag": 0,
      "state": "ACTIVE",
      "vnAgnostic": true,
      "identitySource": {
        "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
        "instanceId": 10010,
        "authEntityId": 10010,
        "displayName": "10010",
        "authEntityClass": 1738953278,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "state": "INACTIVE",
        "type": "NBAR"
      },
      "indicativeNetworkIdentity": [

      ],
      "networkApplications": [
        {
          "id": "e64df751-599c-4090-a51a-c8aa7a89b301",
          "instanceId": 11732,
          "authEntityId": 11732,
          "displayName": "11732",
          "authEntityClass": -217092956,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "appProtocol": "tcp/udp",
          "applicationGroup": "ms-cloud-group",
          "applicationType": "DEFAULT",
          "categoryId": "7e19fdea-6222-4c57-9e66-66c2340d6bc3",
          "createTime": 1517966743483,
          "encrypted": "true",
          "engineId": "13",
          "helpString": "Web-based versions of Microsoft Word, Excel, PowerPoint and OneNote",
          "ignoreConflict": false,
          "lastUpdateTime": 1517966743483,
          "longDescription": "Microsoft Office Web Apps is the web-based version of the Microsoft Office productivity suite. It includes the web-based versions of Microsoft Word, Microsoft Excel, Microsoft PowerPoint and Microsoft OneNote. The web applications allow users to access their documents within a web browser and collaborate with other users online.",
          "name": "ms-office-web-apps",
          "nbarId": "1500",
          "p2pTechnology": "false",
          "popularity": 10,
          "rank": 65535,
          "references": "http://office.microsoft.com/en-us/web-apps/",
          "selectorId": "563",
          "subCategory": "21d2cdac-b44c-4cab-a279-af3ff88ed550",
          "trafficClass": "TRANSACTIONAL_DATA",
          "tunnel": "false"
        }
      ],
      "networkIdentity": [
        {
          "id": "afba6442-b548-4b02-bd6e-13c3d4ac9ffc",
          "instanceId": 13992,
          "authEntityId": 13992,
          "displayName": "13992",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "53,80,443,5353",
          "protocol": "TCP",
          "upperPort": 0
        },
        {
          "id": "29782465-43cd-4631-892b-46ef9f0f97a9",
          "instanceId": 13993,
          "authEntityId": 13993,
          "displayName": "13993",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "53,5353",
          "protocol": "UDP",
          "upperPort": 0
        }
      ],
      "parentScalableGroup": {
        "idRef": "dc13f401-c34c-4d18-a5a1-6e9f8624d67e"
      },
      "user": [

      ],
      "userGroup": [

      ]
    },
    {
      "id": "f1a7d9da-7218-45f4-be51-845904f224ab",
      "instanceId": 4716,
      "authEntityId": 4716,
      "displayName": "4716",
      "authEntityClass": -1909073334,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "createTime": 1517966743344,
      "deployed": false,
      "isSeeded": true,
      "isStale": false,
      "lastUpdateTime": 1517966743344,
      "name": "mailchimp",
      "namespace": "scalablegroup:application",
      "provisioningState": "DEFINED",
      "qualifier": "application",
      "resourceVersion": 0,
      "targetIdList": [

      ],
      "type": "scalablegroup",
      "cfsChangeInfo": [

      ],
      "customProvisions": [

      ],
      "scalableGroupExternalHandle": "mailchimp",
      "scalableGroupType": "APPLICATION",
      "securityGroupTag": 0,
      "state": "ACTIVE",
      "vnAgnostic": true,
      "identitySource": {
        "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
        "instanceId": 10010,
        "authEntityId": 10010,
        "displayName": "10010",
        "authEntityClass": 1738953278,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "state": "INACTIVE",
        "type": "NBAR"
      },
      "indicativeNetworkIdentity": [

      ],
      "networkApplications": [
        {
          "id": "20edee79-e5b3-4bc6-bee8-e3eea8b3231b",
          "instanceId": 11666,
          "authEntityId": 11666,
          "displayName": "11666",
          "authEntityClass": -217092956,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "appProtocol": "tcp/udp",
          "applicationGroup": "other",
          "applicationType": "DEFAULT",
          "categoryId": "878644b5-fe91-45b4-9204-31c84a73ba48",
          "createTime": 1517966743344,
          "encrypted": "false",
          "engineId": "13",
          "helpString": "MailChimp - Email service provider.",
          "ignoreConflict": false,
          "lastUpdateTime": 1517966743344,
          "longDescription": "MailChimp - Email service provider.",
          "name": "mailchimp",
          "nbarId": "1580",
          "p2pTechnology": "false",
          "popularity": 3,
          "rank": 65535,
          "references": "http://mailchimp.com",
          "selectorId": "651",
          "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
          "trafficClass": "TRANSACTIONAL_DATA",
          "tunnel": "false"
        }
      ],
      "networkIdentity": [
        {
          "id": "d632970f-84e3-4498-8cda-9c5e6f44c5f9",
          "instanceId": 13814,
          "authEntityId": 13814,
          "displayName": "13814",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "53,5353",
          "protocol": "UDP",
          "upperPort": 0
        },
        {
          "id": "02c0e8f6-6726-4dea-b379-91a5191a33b2",
          "instanceId": 13815,
          "authEntityId": 13815,
          "displayName": "13815",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "53,80,443,5353",
          "protocol": "TCP",
          "upperPort": 0
        }
      ],
      "parentScalableGroup": {
        "idRef": "28e08de4-d7b2-4521-84ce-bc1e5ee0b9cc"
      },
      "user": [

      ],
      "userGroup": [

      ]
    },
    {
      "id": "f1fd66a7-6e25-4114-9184-9a47f04b9cba",
      "instanceId": 4472,
      "authEntityId": 4472,
      "displayName": "4472",
      "authEntityClass": -1909073334,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "createTime": 1517966742755,
      "deployed": false,
      "isSeeded": true,
      "isStale": false,
      "lastUpdateTime": 1517966742755,
      "name": "gacp",
      "namespace": "scalablegroup:application",
      "provisioningState": "DEFINED",
      "qualifier": "application",
      "resourceVersion": 0,
      "targetIdList": [

      ],
      "type": "scalablegroup",
      "cfsChangeInfo": [

      ],
      "customProvisions": [

      ],
      "scalableGroupExternalHandle": "gacp",
      "scalableGroupType": "APPLICATION",
      "securityGroupTag": 0,
      "state": "ACTIVE",
      "vnAgnostic": true,
      "identitySource": {
        "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
        "instanceId": 10010,
        "authEntityId": 10010,
        "displayName": "10010",
        "authEntityClass": 1738953278,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "state": "INACTIVE",
        "type": "NBAR"
      },
      "indicativeNetworkIdentity": [
        {
          "id": "8fd849bf-8f27-453b-b522-5bb2474331f4",
          "instanceId": 13175,
          "authEntityId": 13175,
          "displayName": "13175",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "190",
          "protocol": "TCP",
          "upperPort": 0
        },
        {
          "id": "345b23be-c7cf-43eb-b3ae-1c1c2f2da952",
          "instanceId": 13176,
          "authEntityId": 13176,
          "displayName": "13176",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "190",
          "protocol": "UDP",
          "upperPort": 0
        }
      ],
      "networkApplications": [
        {
          "id": "5716ac1f-1800-4171-8646-4b8228c6dcea",
          "instanceId": 11423,
          "authEntityId": 11423,
          "displayName": "11423",
          "authEntityClass": -217092956,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "appProtocol": "tcp/udp",
          "applicationGroup": "other",
          "applicationType": "DEFAULT",
          "categoryId": "1fc17362-9334-4c7b-b752-5afdbb574017",
          "createTime": 1517966742755,
          "encrypted": "false",
          "engineId": "3",
          "helpString": "Gateway Access Control Protocol",
          "ignoreConflict": false,
          "lastUpdateTime": 1517966742755,
          "longDescription": "Registered with IANA on port 190 TCP/UDP",
          "name": "gacp",
          "nbarId": "1034",
          "p2pTechnology": "false",
          "popularity": 1,
          "rank": 65535,
          "references": "http://www.iana.org/assignments/service-names-port-numbers/service-names-port-numbers.xml",
          "selectorId": "190",
          "subCategory": "7cc1b3a6-4102-44e9-b522-0e71afe39a03",
          "trafficClass": "NETWORK_CONTROL",
          "tunnel": "false"
        }
      ],
      "networkIdentity": [
        {
          "id": "ce7d3229-3bb0-4916-a215-0472021232bd",
          "instanceId": 13173,
          "authEntityId": 13173,
          "displayName": "13173",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "190",
          "protocol": "UDP",
          "upperPort": 0
        },
        {
          "id": "abb4b351-bd9c-4cec-856f-5c64133432f3",
          "instanceId": 13174,
          "authEntityId": 13174,
          "displayName": "13174",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "190",
          "protocol": "TCP",
          "upperPort": 0
        }
      ],
      "parentScalableGroup": {
        "idRef": "77e75842-e98b-4112-ad0c-300db4bb11be"
      },
      "user": [

      ],
      "userGroup": [

      ]
    },
    {
      "id": "f1fd8197-980e-43d1-ab67-5db2e299c9ba",
      "instanceId": 4564,
      "authEntityId": 4564,
      "displayName": "4564",
      "authEntityClass": -1909073334,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "createTime": 1517966742998,
      "deployed": false,
      "isSeeded": true,
      "isStale": false,
      "lastUpdateTime": 1517966742998,
      "name": "huffingtonpost",
      "namespace": "scalablegroup:application",
      "provisioningState": "DEFINED",
      "qualifier": "application",
      "resourceVersion": 0,
      "targetIdList": [

      ],
      "type": "scalablegroup",
      "cfsChangeInfo": [

      ],
      "customProvisions": [

      ],
      "scalableGroupExternalHandle": "huffingtonpost",
      "scalableGroupType": "APPLICATION",
      "securityGroupTag": 0,
      "state": "ACTIVE",
      "vnAgnostic": true,
      "identitySource": {
        "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
        "instanceId": 10010,
        "authEntityId": 10010,
        "displayName": "10010",
        "authEntityClass": 1738953278,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "state": "INACTIVE",
        "type": "NBAR"
      },
      "indicativeNetworkIdentity": [

      ],
      "networkApplications": [
        {
          "id": "da190927-e4c4-4c61-897e-25fee2f2694f",
          "instanceId": 11514,
          "authEntityId": 11514,
          "displayName": "11514",
          "authEntityClass": -217092956,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "appProtocol": "tcp/udp",
          "applicationGroup": "other",
          "applicationType": "DEFAULT",
          "categoryId": "878644b5-fe91-45b4-9204-31c84a73ba48",
          "createTime": 1517966742998,
          "encrypted": "false",
          "engineId": "13",
          "helpString": "The Huffington Post - Online news website.",
          "ignoreConflict": false,
          "lastUpdateTime": 1517966742998,
          "longDescription": "The Huffington Post - Online news website.",
          "name": "huffingtonpost",
          "nbarId": "1557",
          "p2pTechnology": "false",
          "popularity": 3,
          "rank": 65535,
          "references": "http://huffingtonpost.co.uk",
          "selectorId": "628",
          "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
          "trafficClass": "TRANSACTIONAL_DATA",
          "tunnel": "false"
        }
      ],
      "networkIdentity": [
        {
          "id": "e3dec107-97a2-45d4-970f-25086b42b2dd",
          "instanceId": 13403,
          "authEntityId": 13403,
          "displayName": "13403",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "53,5353",
          "protocol": "UDP",
          "upperPort": 0
        },
        {
          "id": "79c4c4fe-f1d4-4475-9f72-9d9947229f72",
          "instanceId": 13404,
          "authEntityId": 13404,
          "displayName": "13404",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "53,80,443,5353",
          "protocol": "TCP",
          "upperPort": 0
        }
      ],
      "parentScalableGroup": {
        "idRef": "28e08de4-d7b2-4521-84ce-bc1e5ee0b9cc"
      },
      "user": [

      ],
      "userGroup": [

      ]
    },
    {
      "id": "f2787140-a59b-41f6-8a75-7f6b2b57420e",
      "instanceId": 4741,
      "authEntityId": 4741,
      "displayName": "4741",
      "authEntityClass": -1909073334,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "createTime": 1517966743401,
      "deployed": false,
      "isSeeded": true,
      "isStale": false,
      "lastUpdateTime": 1517966743401,
      "name": "mfe-nsp",
      "namespace": "scalablegroup:application",
      "provisioningState": "DEFINED",
      "qualifier": "application",
      "resourceVersion": 0,
      "targetIdList": [

      ],
      "type": "scalablegroup",
      "cfsChangeInfo": [

      ],
      "customProvisions": [

      ],
      "scalableGroupExternalHandle": "mfe-nsp",
      "scalableGroupType": "APPLICATION",
      "securityGroupTag": 0,
      "state": "ACTIVE",
      "vnAgnostic": true,
      "identitySource": {
        "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
        "instanceId": 10010,
        "authEntityId": 10010,
        "displayName": "10010",
        "authEntityClass": 1738953278,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "state": "INACTIVE",
        "type": "NBAR"
      },
      "indicativeNetworkIdentity": [
        {
          "id": "106af525-c435-4fdf-a1f3-169f94eabb9d",
          "instanceId": 13885,
          "authEntityId": 13885,
          "displayName": "13885",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipProtocolNumbers": "31",
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "protocol": "MFE_NSP",
          "upperPort": 0
        }
      ],
      "networkApplications": [
        {
          "id": "8aa6a746-bfc6-44a3-a6ae-e66340f5106c",
          "instanceId": 11691,
          "authEntityId": 11691,
          "displayName": "11691",
          "authEntityClass": -217092956,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "appProtocol": "ip",
          "applicationGroup": "other",
          "applicationType": "DEFAULT",
          "categoryId": "6daa3dfd-3526-407a-88f0-97824b3f3a24",
          "createTime": 1517966743401,
          "encrypted": "unassigned",
          "engineId": "1",
          "helpString": "MFE Network Services Protocol",
          "ignoreConflict": false,
          "lastUpdateTime": 1517966743401,
          "longDescription": "Registered with IANA as IP Protocol 31",
          "name": "mfe-nsp",
          "nbarId": "785",
          "p2pTechnology": "unassigned",
          "popularity": 1,
          "rank": 65535,
          "references": "http://www.iana.org/assignments/protocol-numbers/protocol-numbers.xml",
          "selectorId": "31",
          "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
          "trafficClass": "BULK_DATA",
          "tunnel": "unassigned"
        }
      ],
      "networkIdentity": [
        {
          "id": "d70cde4d-d252-4302-bd35-c32f378bc74c",
          "instanceId": 13884,
          "authEntityId": 13884,
          "displayName": "13884",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipProtocolNumbers": "31",
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "protocol": "MFE_NSP",
          "upperPort": 0
        }
      ],
      "parentScalableGroup": {
        "idRef": "000c15bf-28fe-4fbb-9b69-35e6502721f1"
      },
      "user": [

      ],
      "userGroup": [

      ]
    },
    {
      "id": "f30395f9-0b28-409d-80fa-5edbd9796179",
      "instanceId": 4796,
      "authEntityId": 4796,
      "displayName": "4796",
      "authEntityClass": -1909073334,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "createTime": 1517966743511,
      "deployed": false,
      "isSeeded": true,
      "isStale": false,
      "lastUpdateTime": 1517966743511,
      "name": "msexch-routing",
      "namespace": "scalablegroup:application",
      "provisioningState": "DEFINED",
      "qualifier": "application",
      "resourceVersion": 0,
      "targetIdList": [

      ],
      "type": "scalablegroup",
      "cfsChangeInfo": [

      ],
      "customProvisions": [

      ],
      "scalableGroupExternalHandle": "msexch-routing",
      "scalableGroupType": "APPLICATION",
      "securityGroupTag": 0,
      "state": "ACTIVE",
      "vnAgnostic": true,
      "identitySource": {
        "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
        "instanceId": 10010,
        "authEntityId": 10010,
        "displayName": "10010",
        "authEntityClass": 1738953278,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "state": "INACTIVE",
        "type": "NBAR"
      },
      "indicativeNetworkIdentity": [
        {
          "id": "ced3fa4d-2c1b-49e2-918e-4bc99be30d24",
          "instanceId": 14032,
          "authEntityId": 14032,
          "displayName": "14032",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "691",
          "protocol": "TCP",
          "upperPort": 0
        },
        {
          "id": "e5c0afae-3c61-4cfe-8a50-d9eca9f3e2aa",
          "instanceId": 14031,
          "authEntityId": 14031,
          "displayName": "14031",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "691",
          "protocol": "UDP",
          "upperPort": 0
        }
      ],
      "networkApplications": [
        {
          "id": "1ddc4081-dea5-480f-8367-8d89bd3c3386",
          "instanceId": 11745,
          "authEntityId": 11745,
          "displayName": "11745",
          "authEntityClass": -217092956,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "appProtocol": "tcp/udp",
          "applicationGroup": "other",
          "applicationType": "DEFAULT",
          "categoryId": "27953c60-b2ff-4e1a-bc52-0187d8295250",
          "createTime": 1517966743511,
          "encrypted": "false",
          "engineId": "3",
          "helpString": "MS Exchange Routing",
          "ignoreConflict": false,
          "lastUpdateTime": 1517966743511,
          "longDescription": "MS Exchange Routing is Used by Microsoft Exchange servers to exchange routing information.",
          "name": "msexch-routing",
          "nbarId": "599",
          "p2pTechnology": "false",
          "popularity": 2,
          "rank": 65535,
          "references": "http://technet.microsoft.com/en-us/library/bb123812",
          "selectorId": "691",
          "subCategory": "b90786f4-0190-45b8-9924-21b3e9682590",
          "trafficClass": "BULK_DATA",
          "tunnel": "false"
        }
      ],
      "networkIdentity": [
        {
          "id": "d44e1408-323d-4ea2-9a3d-4259d40ad8f2",
          "instanceId": 14029,
          "authEntityId": 14029,
          "displayName": "14029",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "691",
          "protocol": "TCP",
          "upperPort": 0
        },
        {
          "id": "2cfcab42-fbae-47e9-8999-dcff2a7feb9b",
          "instanceId": 14030,
          "authEntityId": 14030,
          "displayName": "14030",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "691",
          "protocol": "UDP",
          "upperPort": 0
        }
      ],
      "parentScalableGroup": {
        "idRef": "8a7ca43a-32bd-40d2-871a-02220a165cb7"
      },
      "user": [

      ],
      "userGroup": [

      ]
    },
    {
      "id": "f309e889-75be-46c5-828f-bf40b11a86e0",
      "instanceId": 15191,
      "authEntityId": 15191,
      "displayName": "15191",
      "authEntityClass": -1909073334,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "createTime": 1517966744443,
      "deployed": false,
      "isSeeded": true,
      "isStale": false,
      "lastUpdateTime": 1517966744443,
      "name": "sprite-rpc",
      "namespace": "scalablegroup:application",
      "provisioningState": "DEFINED",
      "qualifier": "application",
      "resourceVersion": 0,
      "targetIdList": [

      ],
      "type": "scalablegroup",
      "cfsChangeInfo": [

      ],
      "customProvisions": [

      ],
      "scalableGroupExternalHandle": "sprite-rpc",
      "scalableGroupType": "APPLICATION",
      "securityGroupTag": 0,
      "state": "ACTIVE",
      "vnAgnostic": true,
      "identitySource": {
        "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
        "instanceId": 10010,
        "authEntityId": 10010,
        "displayName": "10010",
        "authEntityClass": 1738953278,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "state": "INACTIVE",
        "type": "NBAR"
      },
      "indicativeNetworkIdentity": [
        {
          "id": "510378e9-f484-4b04-810a-d9ff0f135138",
          "instanceId": 17211,
          "authEntityId": 17211,
          "displayName": "17211",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipProtocolNumbers": "90",
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "protocol": "SPRITE_RPC",
          "upperPort": 0
        }
      ],
      "networkApplications": [
        {
          "id": "02c93522-9315-44c6-8660-25bbd361e887",
          "instanceId": 16134,
          "authEntityId": 16134,
          "displayName": "16134",
          "authEntityClass": -217092956,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "appProtocol": "ip",
          "applicationGroup": "other",
          "applicationType": "DEFAULT",
          "categoryId": "6daa3dfd-3526-407a-88f0-97824b3f3a24",
          "createTime": 1517966744443,
          "encrypted": "unassigned",
          "engineId": "1",
          "helpString": "Sprite RPC Protocol",
          "ignoreConflict": false,
          "lastUpdateTime": 1517966744443,
          "longDescription": "The Sprite operating system is designed for a set of cooperating hosts that communicate over a network, If a service is not implemented locally, the local Sprite kernel uses remote procedure call (RPC) to call a service procedure on a remote server machine.",
          "name": "sprite-rpc",
          "nbarId": "844",
          "p2pTechnology": "unassigned",
          "popularity": 2,
          "rank": 65535,
          "references": "http://www.eecs.berkeley.edu/Pubs/TechRpts/1987/CSD-87-302.pdf",
          "selectorId": "90",
          "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
          "trafficClass": "BULK_DATA",
          "tunnel": "unassigned"
        }
      ],
      "networkIdentity": [
        {
          "id": "ecaa1a76-66bb-4645-b4c3-4d3454d101c2",
          "instanceId": 17210,
          "authEntityId": 17210,
          "displayName": "17210",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipProtocolNumbers": "90",
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "protocol": "SPRITE_RPC",
          "upperPort": 0
        }
      ],
      "parentScalableGroup": {
        "idRef": "000c15bf-28fe-4fbb-9b69-35e6502721f1"
      },
      "user": [

      ],
      "userGroup": [

      ]
    },
    {
      "id": "f32c4634-2850-4e79-8f31-4953d1b7d0df",
      "instanceId": 4217,
      "authEntityId": 4217,
      "displayName": "4217",
      "authEntityClass": -1909073334,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "createTime": 1517966741772,
      "deployed": false,
      "isSeeded": true,
      "isStale": false,
      "lastUpdateTime": 1517966741772,
      "name": "cbt",
      "namespace": "scalablegroup:application",
      "provisioningState": "DEFINED",
      "qualifier": "application",
      "resourceVersion": 0,
      "targetIdList": [

      ],
      "type": "scalablegroup",
      "cfsChangeInfo": [

      ],
      "customProvisions": [

      ],
      "scalableGroupExternalHandle": "cbt",
      "scalableGroupType": "APPLICATION",
      "securityGroupTag": 0,
      "state": "ACTIVE",
      "vnAgnostic": true,
      "identitySource": {
        "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
        "instanceId": 10010,
        "authEntityId": 10010,
        "displayName": "10010",
        "authEntityClass": 1738953278,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "state": "INACTIVE",
        "type": "NBAR"
      },
      "indicativeNetworkIdentity": [
        {
          "id": "dfcd891d-7174-4763-b1fc-bab9ce5fbd7b",
          "instanceId": 12470,
          "authEntityId": 12470,
          "displayName": "12470",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipProtocolNumbers": "7",
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "protocol": "CBT",
          "upperPort": 0
        }
      ],
      "networkApplications": [
        {
          "id": "50cab4a7-8f05-4ead-a594-03c3ca508eff",
          "instanceId": 11172,
          "authEntityId": 11172,
          "displayName": "11172",
          "authEntityClass": -217092956,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "appProtocol": "ip",
          "applicationGroup": "other",
          "applicationType": "DEFAULT",
          "categoryId": "6daa3dfd-3526-407a-88f0-97824b3f3a24",
          "createTime": 1517966741772,
          "encrypted": "unassigned",
          "engineId": "1",
          "helpString": "CBT",
          "ignoreConflict": false,
          "lastUpdateTime": 1517966741772,
          "longDescription": "The Core-Based Trees protocol (CBT) is designed to build and maintain a shared multicast distribution tree that spans only those networks and links leading to interested receivers. CBT builds a shared multicast distribution tree per group, and is suited for inter- and intra-domain multicast routing. CBT may use a separate multicast routing table, or it may use that of an underlying unicast routing table, to establish paths between senders and receivers.",
          "name": "cbt",
          "nbarId": "762",
          "p2pTechnology": "unassigned",
          "popularity": 2,
          "rank": 65535,
          "references": "http://www.ietf.org/rfc/rfc2189.txt",
          "selectorId": "7",
          "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
          "trafficClass": "BULK_DATA",
          "tunnel": "unassigned"
        }
      ],
      "networkIdentity": [
        {
          "id": "f72774a5-cb79-4094-894e-448b3e5d6a81",
          "instanceId": 12469,
          "authEntityId": 12469,
          "displayName": "12469",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipProtocolNumbers": "7",
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "protocol": "CBT",
          "upperPort": 0
        }
      ],
      "parentScalableGroup": {
        "idRef": "000c15bf-28fe-4fbb-9b69-35e6502721f1"
      },
      "user": [

      ],
      "userGroup": [

      ]
    },
    {
      "id": "f35843ae-36cf-48d7-aeea-52c36581e255",
      "instanceId": 4659,
      "authEntityId": 4659,
      "displayName": "4659",
      "authEntityClass": -1909073334,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "createTime": 1517966743225,
      "deployed": false,
      "isSeeded": true,
      "isStale": false,
      "lastUpdateTime": 1517966743225,
      "name": "jabber-audio",
      "namespace": "scalablegroup:application",
      "provisioningState": "DEFINED",
      "qualifier": "application",
      "resourceVersion": 0,
      "targetIdList": [

      ],
      "type": "scalablegroup",
      "cfsChangeInfo": [

      ],
      "customProvisions": [

      ],
      "scalableGroupExternalHandle": "jabber-audio",
      "scalableGroupType": "APPLICATION",
      "securityGroupTag": 0,
      "state": "ACTIVE",
      "vnAgnostic": true,
      "identitySource": {
        "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
        "instanceId": 10010,
        "authEntityId": 10010,
        "displayName": "10010",
        "authEntityClass": 1738953278,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "state": "INACTIVE",
        "type": "NBAR"
      },
      "indicativeNetworkIdentity": [

      ],
      "networkApplications": [
        {
          "id": "c1930be7-4599-40d2-af35-2d9b0406419f",
          "instanceId": 11609,
          "authEntityId": 11609,
          "displayName": "11609",
          "authEntityClass": -217092956,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "applicationGroup": "other",
          "applicationType": "DEFAULT",
          "categoryId": "38a8e354-e2fe-4f3c-b1c5-2bbb90fb0d99",
          "createTime": 1517966743225,
          "encrypted": "false",
          "engineId": "13",
          "helpString": "Communications protocol for message-oriented middleware based on XML",
          "ignoreConflict": false,
          "lastUpdateTime": 1517966743225,
          "longDescription": "Communications protocol for message-oriented middleware based on XML (Extensible Markup Language). It enables the near-real-time exchange of structured yet extensible data between any two or more network entities.",
          "name": "jabber-audio",
          "nbarId": "1045",
          "p2pTechnology": "false",
          "popularity": 10,
          "rank": 65535,
          "references": "http://www.jabber.org/",
          "selectorId": "1045",
          "subCategory": "53ce0342-1e2d-455a-acc7-1b50db9d6845",
          "trafficClass": "VOIP_TELEPHONY",
          "tunnel": "false"
        }
      ],
      "networkIdentity": [

      ],
      "parentScalableGroup": {
        "idRef": "60f1364d-2a02-4063-8ba1-07817abe0612"
      },
      "user": [

      ],
      "userGroup": [

      ]
    },
    {
      "id": "f3605621-a855-4d18-be1a-b9e84f212226",
      "instanceId": 15205,
      "authEntityId": 15205,
      "displayName": "15205",
      "authEntityClass": -1909073334,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "createTime": 1517966744479,
      "deployed": false,
      "isSeeded": true,
      "isStale": false,
      "lastUpdateTime": 1517966744479,
      "name": "sscopmce",
      "namespace": "scalablegroup:application",
      "provisioningState": "DEFINED",
      "qualifier": "application",
      "resourceVersion": 0,
      "targetIdList": [

      ],
      "type": "scalablegroup",
      "cfsChangeInfo": [

      ],
      "customProvisions": [

      ],
      "scalableGroupExternalHandle": "sscopmce",
      "scalableGroupType": "APPLICATION",
      "securityGroupTag": 0,
      "state": "ACTIVE",
      "vnAgnostic": true,
      "identitySource": {
        "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
        "instanceId": 10010,
        "authEntityId": 10010,
        "displayName": "10010",
        "authEntityClass": 1738953278,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "state": "INACTIVE",
        "type": "NBAR"
      },
      "indicativeNetworkIdentity": [
        {
          "id": "a901f676-d4c6-4de0-a2fc-8edcb7abee4a",
          "instanceId": 17257,
          "authEntityId": 17257,
          "displayName": "17257",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipProtocolNumbers": "128",
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "protocol": "SSCOPMCE",
          "upperPort": 0
        }
      ],
      "networkApplications": [
        {
          "id": "47c6deb1-c446-4658-9fd2-ee4e96ab2523",
          "instanceId": 16148,
          "authEntityId": 16148,
          "displayName": "16148",
          "authEntityClass": -217092956,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "appProtocol": "ip",
          "applicationGroup": "other",
          "applicationType": "DEFAULT",
          "categoryId": "6daa3dfd-3526-407a-88f0-97824b3f3a24",
          "createTime": 1517966744479,
          "encrypted": "false",
          "engineId": "1",
          "helpString": "SSCOPMCE",
          "ignoreConflict": false,
          "lastUpdateTime": 1517966744479,
          "longDescription": "Service Specific Connection Oriented Protocol in a Multilink and Connectionless Environment (SSCOPMCE) is a peer-to-peer protocol deployed on a single ATM connection, multiple ATM connections between the same endpoints, or on a connectionless network.",
          "name": "sscopmce",
          "nbarId": "1226",
          "p2pTechnology": "false",
          "popularity": 2,
          "rank": 65535,
          "references": "http://www.itu.int/rec/T-REC-Q.2111/en",
          "selectorId": "128",
          "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
          "trafficClass": "BULK_DATA",
          "tunnel": "false"
        }
      ],
      "networkIdentity": [
        {
          "id": "2dc18305-f265-4c83-af53-e4db19ff78a2",
          "instanceId": 17256,
          "authEntityId": 17256,
          "displayName": "17256",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipProtocolNumbers": "128",
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "protocol": "SSCOPMCE",
          "upperPort": 0
        }
      ],
      "parentScalableGroup": {
        "idRef": "000c15bf-28fe-4fbb-9b69-35e6502721f1"
      },
      "user": [

      ],
      "userGroup": [

      ]
    },
    {
      "id": "f394a048-0abf-4b30-9744-0f059042d84d",
      "instanceId": 15185,
      "authEntityId": 15185,
      "displayName": "15185",
      "authEntityClass": -1909073334,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "createTime": 1517966744429,
      "deployed": false,
      "isSeeded": true,
      "isStale": false,
      "lastUpdateTime": 1517966744429,
      "name": "southwest-airlines",
      "namespace": "scalablegroup:application",
      "provisioningState": "DEFINED",
      "qualifier": "application",
      "resourceVersion": 0,
      "targetIdList": [

      ],
      "type": "scalablegroup",
      "cfsChangeInfo": [

      ],
      "customProvisions": [

      ],
      "scalableGroupExternalHandle": "southwest-airlines",
      "scalableGroupType": "APPLICATION",
      "securityGroupTag": 0,
      "state": "ACTIVE",
      "vnAgnostic": true,
      "identitySource": {
        "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
        "instanceId": 10010,
        "authEntityId": 10010,
        "displayName": "10010",
        "authEntityClass": 1738953278,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "state": "INACTIVE",
        "type": "NBAR"
      },
      "indicativeNetworkIdentity": [

      ],
      "networkApplications": [
        {
          "id": "07dba742-5731-4e17-949f-592cfa91051c",
          "instanceId": 16128,
          "authEntityId": 16128,
          "displayName": "16128",
          "authEntityClass": -217092956,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "appProtocol": "tcp/udp",
          "applicationGroup": "other",
          "applicationType": "DEFAULT",
          "categoryId": "878644b5-fe91-45b4-9204-31c84a73ba48",
          "createTime": 1517966744429,
          "encrypted": "false",
          "engineId": "13",
          "helpString": "Southwest Airlines - Airlines service in United States.",
          "ignoreConflict": false,
          "lastUpdateTime": 1517966744429,
          "longDescription": "Southwest Airlines - Airlines service in United States.",
          "name": "southwest-airlines",
          "nbarId": "1659",
          "p2pTechnology": "false",
          "popularity": 3,
          "rank": 65535,
          "references": "http://southwest.com",
          "selectorId": "729",
          "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
          "trafficClass": "TRANSACTIONAL_DATA",
          "tunnel": "false"
        }
      ],
      "networkIdentity": [
        {
          "id": "146d3350-0aa3-4947-8fc8-d8cc25320617",
          "instanceId": 17197,
          "authEntityId": 17197,
          "displayName": "17197",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "53,80,443,5353",
          "protocol": "TCP",
          "upperPort": 0
        },
        {
          "id": "d192a4ea-a19c-4ef5-99b2-a5b27250436b",
          "instanceId": 17198,
          "authEntityId": 17198,
          "displayName": "17198",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "53,5353",
          "protocol": "UDP",
          "upperPort": 0
        }
      ],
      "parentScalableGroup": {
        "idRef": "28e08de4-d7b2-4521-84ce-bc1e5ee0b9cc"
      },
      "user": [

      ],
      "userGroup": [

      ]
    },
    {
      "id": "f39f8789-5b83-42be-88d9-9052dfd0f3e7",
      "instanceId": 4128,
      "authEntityId": 4128,
      "displayName": "4128",
      "authEntityClass": -1909073334,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "createTime": 1517966741258,
      "deployed": false,
      "isSeeded": true,
      "isStale": false,
      "lastUpdateTime": 1517966741258,
      "name": "asus",
      "namespace": "scalablegroup:application",
      "provisioningState": "DEFINED",
      "qualifier": "application",
      "resourceVersion": 0,
      "targetIdList": [

      ],
      "type": "scalablegroup",
      "cfsChangeInfo": [

      ],
      "customProvisions": [

      ],
      "scalableGroupExternalHandle": "asus",
      "scalableGroupType": "APPLICATION",
      "securityGroupTag": 0,
      "state": "ACTIVE",
      "vnAgnostic": true,
      "identitySource": {
        "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
        "instanceId": 10010,
        "authEntityId": 10010,
        "displayName": "10010",
        "authEntityClass": 1738953278,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "state": "INACTIVE",
        "type": "NBAR"
      },
      "indicativeNetworkIdentity": [

      ],
      "networkApplications": [
        {
          "id": "ce99b329-1622-4f2f-97a1-eacac2b2a271",
          "instanceId": 11090,
          "authEntityId": 11090,
          "displayName": "11090",
          "authEntityClass": -217092956,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "appProtocol": "tcp/udp",
          "applicationGroup": "other",
          "applicationType": "DEFAULT",
          "categoryId": "878644b5-fe91-45b4-9204-31c84a73ba48",
          "createTime": 1517966741258,
          "encrypted": "false",
          "engineId": "13",
          "helpString": "Asus - Manufacturer of PCs and PC components.",
          "ignoreConflict": false,
          "lastUpdateTime": 1517966741258,
          "longDescription": "Asus - Manufacturer of PCs and PC components.",
          "name": "asus",
          "nbarId": "1692",
          "p2pTechnology": "false",
          "popularity": 3,
          "rank": 65535,
          "references": "http://asus.com",
          "selectorId": "762",
          "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
          "trafficClass": "TRANSACTIONAL_DATA",
          "tunnel": "false"
        }
      ],
      "networkIdentity": [
        {
          "id": "eadf10ce-ff52-4048-b439-4033384b24ae",
          "instanceId": 12241,
          "authEntityId": 12241,
          "displayName": "12241",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "53,80,443,5353",
          "protocol": "TCP",
          "upperPort": 0
        },
        {
          "id": "df02f9f2-88e3-4ec2-bf05-402c4ebe1548",
          "instanceId": 12242,
          "authEntityId": 12242,
          "displayName": "12242",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "53,5353",
          "protocol": "UDP",
          "upperPort": 0
        }
      ],
      "parentScalableGroup": {
        "idRef": "28e08de4-d7b2-4521-84ce-bc1e5ee0b9cc"
      },
      "user": [

      ],
      "userGroup": [

      ]
    },
    {
      "id": "f3cc61c0-fdc5-482f-9df4-c4468b4535ee",
      "instanceId": 15198,
      "authEntityId": 15198,
      "displayName": "15198",
      "authEntityClass": -1909073334,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "createTime": 1517966744460,
      "deployed": false,
      "isSeeded": true,
      "isStale": false,
      "lastUpdateTime": 1517966744460,
      "name": "sqlserver",
      "namespace": "scalablegroup:application",
      "provisioningState": "DEFINED",
      "qualifier": "application",
      "resourceVersion": 0,
      "targetIdList": [

      ],
      "type": "scalablegroup",
      "cfsChangeInfo": [

      ],
      "customProvisions": [

      ],
      "scalableGroupExternalHandle": "sqlserver",
      "scalableGroupType": "APPLICATION",
      "securityGroupTag": 0,
      "state": "ACTIVE",
      "vnAgnostic": true,
      "identitySource": {
        "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
        "instanceId": 10010,
        "authEntityId": 10010,
        "displayName": "10010",
        "authEntityClass": 1738953278,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "state": "INACTIVE",
        "type": "NBAR"
      },
      "indicativeNetworkIdentity": [
        {
          "id": "07158066-f48c-469d-8106-db95e0cf3590",
          "instanceId": 17232,
          "authEntityId": 17232,
          "displayName": "17232",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "1433",
          "protocol": "UDP",
          "upperPort": 0
        },
        {
          "id": "41139e72-0369-4ed9-944f-c7f464c3c136",
          "instanceId": 17233,
          "authEntityId": 17233,
          "displayName": "17233",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "1433",
          "protocol": "TCP",
          "upperPort": 0
        }
      ],
      "networkApplications": [
        {
          "id": "18aeb192-16d7-4510-99c6-f59d183baaf3",
          "instanceId": 16141,
          "authEntityId": 16141,
          "displayName": "16141",
          "authEntityClass": -217092956,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "appProtocol": "tcp/udp",
          "applicationGroup": "sqlsvr-group",
          "applicationType": "DEFAULT",
          "categoryId": "cb5915d1-afec-4c30-ac9e-069ff382272f",
          "createTime": 1517966744460,
          "encrypted": "false",
          "engineId": "3",
          "helpString": "Microsoft SQL Server",
          "ignoreConflict": false,
          "lastUpdateTime": 1517966744460,
          "longDescription": "Microsoft SQL Server is a relational database server, developed by Microsoft. It is a software product whose primary function is to store and retrieve data as requested by other software applications, be it those on the same computer or those running on another computer across a network.",
          "name": "sqlserver",
          "nbarId": "25",
          "p2pTechnology": "false",
          "popularity": 9,
          "rank": 65535,
          "references": "http://technet.microsoft.com/en-us/sqlserver/default",
          "selectorId": "1433",
          "subCategory": "b90786f4-0190-45b8-9924-21b3e9682590",
          "trafficClass": "TRANSACTIONAL_DATA",
          "tunnel": "false"
        }
      ],
      "networkIdentity": [
        {
          "id": "ac84c74c-8cab-4d6f-976b-38fc95ae47e3",
          "instanceId": 17230,
          "authEntityId": 17230,
          "displayName": "17230",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "139,445,1433",
          "protocol": "TCP",
          "upperPort": 0
        },
        {
          "id": "a4d02e38-df7e-4f2c-9b4c-0ea6f170e5c5",
          "instanceId": 17231,
          "authEntityId": 17231,
          "displayName": "17231",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "1433",
          "protocol": "UDP",
          "upperPort": 0
        }
      ],
      "parentScalableGroup": {
        "idRef": "8718395e-683e-4a2d-a232-100202751271"
      },
      "user": [

      ],
      "userGroup": [

      ]
    },
    {
      "id": "f40239aa-3847-4b92-ab4b-4842717d8e3b",
      "instanceId": 4149,
      "authEntityId": 4149,
      "displayName": "4149",
      "authEntityClass": -1909073334,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "createTime": 1517966741386,
      "deployed": false,
      "isSeeded": true,
      "isStale": false,
      "lastUpdateTime": 1517966741386,
      "name": "avian",
      "namespace": "scalablegroup:application",
      "provisioningState": "DEFINED",
      "qualifier": "application",
      "resourceVersion": 0,
      "targetIdList": [

      ],
      "type": "scalablegroup",
      "cfsChangeInfo": [

      ],
      "customProvisions": [

      ],
      "scalableGroupExternalHandle": "avian",
      "scalableGroupType": "APPLICATION",
      "securityGroupTag": 0,
      "state": "ACTIVE",
      "vnAgnostic": true,
      "identitySource": {
        "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
        "instanceId": 10010,
        "authEntityId": 10010,
        "displayName": "10010",
        "authEntityClass": 1738953278,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "state": "INACTIVE",
        "type": "NBAR"
      },
      "indicativeNetworkIdentity": [
        {
          "id": "14115272-b019-45d6-837e-d1dfe67c66ea",
          "instanceId": 12306,
          "authEntityId": 12306,
          "displayName": "12306",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "486",
          "protocol": "UDP",
          "upperPort": 0
        },
        {
          "id": "83cb3da0-9958-4e7e-9ae1-7b1fd0be997d",
          "instanceId": 12307,
          "authEntityId": 12307,
          "displayName": "12307",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "486",
          "protocol": "TCP",
          "upperPort": 0
        }
      ],
      "networkApplications": [
        {
          "id": "0d05789b-9ec9-4835-b57b-296f6b2764eb",
          "instanceId": 11110,
          "authEntityId": 11110,
          "displayName": "11110",
          "authEntityClass": -217092956,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "appProtocol": "tcp/udp",
          "applicationGroup": "other",
          "applicationType": "DEFAULT",
          "categoryId": "6daa3dfd-3526-407a-88f0-97824b3f3a24",
          "createTime": 1517966741386,
          "encrypted": "false",
          "engineId": "3",
          "helpString": "avian",
          "ignoreConflict": false,
          "lastUpdateTime": 1517966741386,
          "longDescription": "Registered with IANA on port 486 TCP/UDP",
          "name": "avian",
          "nbarId": "400",
          "p2pTechnology": "false",
          "popularity": 1,
          "rank": 65535,
          "references": "http://www.iana.org/assignments/service-names-port-numbers/service-names-port-numbers.xml",
          "selectorId": "486",
          "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
          "trafficClass": "BULK_DATA",
          "tunnel": "false"
        }
      ],
      "networkIdentity": [
        {
          "id": "dad26045-c5bd-4c4f-b948-2455febbee41",
          "instanceId": 12304,
          "authEntityId": 12304,
          "displayName": "12304",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "486",
          "protocol": "TCP",
          "upperPort": 0
        },
        {
          "id": "e8f48463-ff40-4e65-a5a1-af219de893e2",
          "instanceId": 12305,
          "authEntityId": 12305,
          "displayName": "12305",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "486",
          "protocol": "UDP",
          "upperPort": 0
        }
      ],
      "parentScalableGroup": {
        "idRef": "000c15bf-28fe-4fbb-9b69-35e6502721f1"
      },
      "user": [

      ],
      "userGroup": [

      ]
    },
    {
      "id": "f41bf4b5-243a-4794-87ef-2f8f16c458c6",
      "instanceId": 15223,
      "authEntityId": 15223,
      "displayName": "15223",
      "authEntityClass": -1909073334,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "createTime": 1517966744527,
      "deployed": false,
      "isSeeded": true,
      "isStale": false,
      "lastUpdateTime": 1517966744527,
      "name": "stun-nat",
      "namespace": "scalablegroup:application",
      "provisioningState": "DEFINED",
      "qualifier": "application",
      "resourceVersion": 0,
      "targetIdList": [

      ],
      "type": "scalablegroup",
      "cfsChangeInfo": [

      ],
      "customProvisions": [

      ],
      "scalableGroupExternalHandle": "stun-nat",
      "scalableGroupType": "APPLICATION",
      "securityGroupTag": 0,
      "state": "ACTIVE",
      "vnAgnostic": true,
      "identitySource": {
        "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
        "instanceId": 10010,
        "authEntityId": 10010,
        "displayName": "10010",
        "authEntityClass": 1738953278,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "state": "INACTIVE",
        "type": "NBAR"
      },
      "indicativeNetworkIdentity": [
        {
          "id": "4f5658cf-fe7d-4045-bb05-74c82d4d2176",
          "instanceId": 17298,
          "authEntityId": 17298,
          "displayName": "17298",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "3478",
          "protocol": "TCP",
          "upperPort": 0
        },
        {
          "id": "0fdc362c-0ed6-416e-a4d2-59cd03761313",
          "instanceId": 17299,
          "authEntityId": 17299,
          "displayName": "17299",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "3478",
          "protocol": "UDP",
          "upperPort": 0
        }
      ],
      "networkApplications": [
        {
          "id": "8756122c-bea7-4e82-8105-a484a521e8a1",
          "instanceId": 16166,
          "authEntityId": 16166,
          "displayName": "16166",
          "authEntityClass": -217092956,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "appProtocol": "tcp/udp",
          "applicationGroup": "stun-group",
          "applicationType": "DEFAULT",
          "categoryId": "70fce3cd-f2bd-48de-9020-7b7840b9e7a1",
          "createTime": 1517966744527,
          "encrypted": "true",
          "engineId": "3",
          "helpString": "Session Traversal Utilities for NAT (STUN)",
          "ignoreConflict": false,
          "lastUpdateTime": 1517966744527,
          "longDescription": "Session Traversal Utilities for NAT (STUN) is an Internet standards-track suite of methods, including a network protocol, used in NAT traversal for applications of real-time voice, video, messaging, and other interactive IP communications.",
          "name": "stun-nat",
          "nbarId": "894",
          "p2pTechnology": "false",
          "popularity": 8,
          "rank": 65535,
          "references": "http://tools.ietf.org/html/rfc5389",
          "selectorId": "3478",
          "subCategory": "f3c0f761-d894-4112-ab89-b90fc84fd660",
          "trafficClass": "TRANSACTIONAL_DATA",
          "tunnel": "false"
        }
      ],
      "networkIdentity": [
        {
          "id": "041ff609-79a4-40b0-ad90-7c185cefcfce",
          "instanceId": 17296,
          "authEntityId": 17296,
          "displayName": "17296",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "443,3478",
          "protocol": "TCP",
          "upperPort": 0
        },
        {
          "id": "9ed07676-1afe-45ea-99f9-89711d98ace6",
          "instanceId": 17297,
          "authEntityId": 17297,
          "displayName": "17297",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "3478",
          "protocol": "UDP",
          "upperPort": 0
        }
      ],
      "parentScalableGroup": {
        "idRef": "79f9d505-477f-4ccd-bd2a-c5fc8097a2e1"
      },
      "user": [

      ],
      "userGroup": [

      ]
    },
    {
      "id": "f44c1952-9981-4f0c-ab03-17fb3c83f30c",
      "instanceId": 4200,
      "authEntityId": 4200,
      "displayName": "4200",
      "authEntityClass": -1909073334,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "createTime": 1517966741667,
      "deployed": false,
      "isSeeded": true,
      "isStale": false,
      "lastUpdateTime": 1517966741667,
      "name": "box",
      "namespace": "scalablegroup:application",
      "provisioningState": "DEFINED",
      "qualifier": "application",
      "resourceVersion": 0,
      "targetIdList": [

      ],
      "type": "scalablegroup",
      "cfsChangeInfo": [

      ],
      "customProvisions": [

      ],
      "scalableGroupExternalHandle": "box",
      "scalableGroupType": "APPLICATION",
      "securityGroupTag": 0,
      "state": "ACTIVE",
      "vnAgnostic": true,
      "identitySource": {
        "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
        "instanceId": 10010,
        "authEntityId": 10010,
        "displayName": "10010",
        "authEntityClass": 1738953278,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "state": "INACTIVE",
        "type": "NBAR"
      },
      "indicativeNetworkIdentity": [

      ],
      "networkApplications": [
        {
          "id": "a49afd92-24cd-464a-ae21-e2b5806901bd",
          "instanceId": 11156,
          "authEntityId": 11156,
          "displayName": "11156",
          "authEntityClass": -217092956,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "appProtocol": "tcp/udp",
          "applicationGroup": "other",
          "applicationType": "DEFAULT",
          "categoryId": "7e19fdea-6222-4c57-9e66-66c2340d6bc3",
          "createTime": 1517966741667,
          "encrypted": "true",
          "engineId": "13",
          "helpString": "Box",
          "ignoreConflict": false,
          "lastUpdateTime": 1517966741667,
          "longDescription": "Box is an online file sharing and content management service for businesses.",
          "name": "box",
          "nbarId": "1074",
          "p2pTechnology": "false",
          "popularity": 10,
          "rank": 65535,
          "references": "http://www.box.com/",
          "selectorId": "1074",
          "subCategory": "8320328d-5e88-43b4-a6f1-d148749ef1a3",
          "trafficClass": "BULK_DATA",
          "tunnel": "false"
        }
      ],
      "networkIdentity": [
        {
          "id": "a3e286d2-f5aa-4eab-afac-969e7379a8a5",
          "instanceId": 12432,
          "authEntityId": 12432,
          "displayName": "12432",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "53,5353",
          "protocol": "UDP",
          "upperPort": 0
        },
        {
          "id": "6bb8f679-102c-486c-bb09-c08e690e22a9",
          "instanceId": 12431,
          "authEntityId": 12431,
          "displayName": "12431",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "53,80,443,5353",
          "protocol": "TCP",
          "upperPort": 0
        }
      ],
      "parentScalableGroup": {
        "idRef": "f09a36de-df38-4ff8-b0be-56738afc75f7"
      },
      "user": [

      ],
      "userGroup": [

      ]
    },
    {
      "id": "f4729cb5-59bf-4f58-9b6f-9d4fc3595b07",
      "instanceId": 4594,
      "authEntityId": 4594,
      "displayName": "4594",
      "authEntityClass": -1909073334,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "createTime": 1517966743076,
      "deployed": false,
      "isSeeded": true,
      "isStale": false,
      "lastUpdateTime": 1517966743076,
      "name": "il",
      "namespace": "scalablegroup:application",
      "provisioningState": "DEFINED",
      "qualifier": "application",
      "resourceVersion": 0,
      "targetIdList": [

      ],
      "type": "scalablegroup",
      "cfsChangeInfo": [

      ],
      "customProvisions": [

      ],
      "scalableGroupExternalHandle": "il",
      "scalableGroupType": "APPLICATION",
      "securityGroupTag": 0,
      "state": "ACTIVE",
      "vnAgnostic": true,
      "identitySource": {
        "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
        "instanceId": 10010,
        "authEntityId": 10010,
        "displayName": "10010",
        "authEntityClass": 1738953278,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "state": "INACTIVE",
        "type": "NBAR"
      },
      "indicativeNetworkIdentity": [
        {
          "id": "9fa13eaf-ce7c-40dc-b41c-1937b3225fbe",
          "instanceId": 13496,
          "authEntityId": 13496,
          "displayName": "13496",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipProtocolNumbers": "40",
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "protocol": "IL",
          "upperPort": 0
        }
      ],
      "networkApplications": [
        {
          "id": "7cfa316c-9263-4f60-9e54-aea9fee26bb4",
          "instanceId": 11544,
          "authEntityId": 11544,
          "displayName": "11544",
          "authEntityClass": -217092956,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "appProtocol": "ip",
          "applicationGroup": "other",
          "applicationType": "DEFAULT",
          "categoryId": "6daa3dfd-3526-407a-88f0-97824b3f3a24",
          "createTime": 1517966743076,
          "encrypted": "unassigned",
          "engineId": "1",
          "helpString": "IL Transport Protocol",
          "ignoreConflict": false,
          "lastUpdateTime": 1517966743076,
          "longDescription": "The Internet Link Protocol or IL is a connection-based transport layer protocol designed at Bell Labs originally as part of the Plan 9 operating system and is used to carry 9P. It is similar to TCP but much simpler.",
          "name": "il",
          "nbarId": "794",
          "p2pTechnology": "unassigned",
          "popularity": 2,
          "rank": 65535,
          "references": "http://doc.cat-v.org/plan_9/4th_edition/papers/il/",
          "selectorId": "40",
          "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
          "trafficClass": "BULK_DATA",
          "tunnel": "unassigned"
        }
      ],
      "networkIdentity": [
        {
          "id": "f21121ef-28d4-45c8-a446-cd7c82925be9",
          "instanceId": 13495,
          "authEntityId": 13495,
          "displayName": "13495",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipProtocolNumbers": "40",
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "protocol": "IL",
          "upperPort": 0
        }
      ],
      "parentScalableGroup": {
        "idRef": "000c15bf-28fe-4fbb-9b69-35e6502721f1"
      },
      "user": [

      ],
      "userGroup": [

      ]
    },
    {
      "id": "f48f57ae-c1d4-4280-9495-1f778f2e8522",
      "instanceId": 4614,
      "authEntityId": 4614,
      "displayName": "4614",
      "authEntityClass": -1909073334,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "createTime": 1517966743128,
      "deployed": false,
      "isSeeded": true,
      "isStale": false,
      "lastUpdateTime": 1517966743128,
      "name": "investopedia",
      "namespace": "scalablegroup:application",
      "provisioningState": "DEFINED",
      "qualifier": "application",
      "resourceVersion": 0,
      "targetIdList": [

      ],
      "type": "scalablegroup",
      "cfsChangeInfo": [

      ],
      "customProvisions": [

      ],
      "scalableGroupExternalHandle": "investopedia",
      "scalableGroupType": "APPLICATION",
      "securityGroupTag": 0,
      "state": "ACTIVE",
      "vnAgnostic": true,
      "identitySource": {
        "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
        "instanceId": 10010,
        "authEntityId": 10010,
        "displayName": "10010",
        "authEntityClass": 1738953278,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "state": "INACTIVE",
        "type": "NBAR"
      },
      "indicativeNetworkIdentity": [

      ],
      "networkApplications": [
        {
          "id": "fdb0f578-0a1b-46fd-9c9b-b1dde2134b00",
          "instanceId": 11564,
          "authEntityId": 11564,
          "displayName": "11564",
          "authEntityClass": -217092956,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "appProtocol": "tcp/udp",
          "applicationGroup": "other",
          "applicationType": "DEFAULT",
          "categoryId": "878644b5-fe91-45b4-9204-31c84a73ba48",
          "createTime": 1517966743128,
          "encrypted": "false",
          "engineId": "13",
          "helpString": "Investopedia - A wiki focused on information related to investments.",
          "ignoreConflict": false,
          "lastUpdateTime": 1517966743128,
          "longDescription": "Investopedia - A wiki focused on information related to investments.",
          "name": "investopedia",
          "nbarId": "1711",
          "p2pTechnology": "false",
          "popularity": 3,
          "rank": 65535,
          "references": "http://investopedia.com",
          "selectorId": "783",
          "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
          "trafficClass": "TRANSACTIONAL_DATA",
          "tunnel": "false"
        }
      ],
      "networkIdentity": [
        {
          "id": "9e3ca30e-42f1-4c2a-b4f3-44b9d68366ab",
          "instanceId": 13552,
          "authEntityId": 13552,
          "displayName": "13552",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "53,80,443,5353",
          "protocol": "TCP",
          "upperPort": 0
        },
        {
          "id": "2af4733d-868b-44c4-809a-1493bd3755b5",
          "instanceId": 13551,
          "authEntityId": 13551,
          "displayName": "13551",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "53,5353",
          "protocol": "UDP",
          "upperPort": 0
        }
      ],
      "parentScalableGroup": {
        "idRef": "28e08de4-d7b2-4521-84ce-bc1e5ee0b9cc"
      },
      "user": [

      ],
      "userGroup": [

      ]
    },
    {
      "id": "f5014c22-4f0f-4ca8-93c8-1cde17fce74d",
      "instanceId": 4711,
      "authEntityId": 4711,
      "displayName": "4711",
      "authEntityClass": -1909073334,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "createTime": 1517966743334,
      "deployed": false,
      "isSeeded": true,
      "isStale": false,
      "lastUpdateTime": 1517966743334,
      "name": "mac-srvr-admin",
      "namespace": "scalablegroup:application",
      "provisioningState": "DEFINED",
      "qualifier": "application",
      "resourceVersion": 0,
      "targetIdList": [

      ],
      "type": "scalablegroup",
      "cfsChangeInfo": [

      ],
      "customProvisions": [

      ],
      "scalableGroupExternalHandle": "mac-srvr-admin",
      "scalableGroupType": "APPLICATION",
      "securityGroupTag": 0,
      "state": "ACTIVE",
      "vnAgnostic": true,
      "identitySource": {
        "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
        "instanceId": 10010,
        "authEntityId": 10010,
        "displayName": "10010",
        "authEntityClass": 1738953278,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "state": "INACTIVE",
        "type": "NBAR"
      },
      "indicativeNetworkIdentity": [
        {
          "id": "f5d15740-def3-429c-8610-b2f48177843e",
          "instanceId": 13800,
          "authEntityId": 13800,
          "displayName": "13800",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "660",
          "protocol": "TCP",
          "upperPort": 0
        },
        {
          "id": "93968912-736b-4f42-9ca3-3b9b45d85679",
          "instanceId": 13801,
          "authEntityId": 13801,
          "displayName": "13801",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "660",
          "protocol": "UDP",
          "upperPort": 0
        }
      ],
      "networkApplications": [
        {
          "id": "1e52826e-ce9f-417f-a310-54bb4d8e005e",
          "instanceId": 11661,
          "authEntityId": 11661,
          "displayName": "11661",
          "authEntityClass": -217092956,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "appProtocol": "tcp/udp",
          "applicationGroup": "other",
          "applicationType": "DEFAULT",
          "categoryId": "1fc17362-9334-4c7b-b752-5afdbb574017",
          "createTime": 1517966743334,
          "encrypted": "false",
          "engineId": "3",
          "helpString": "MacOS Server Admin",
          "ignoreConflict": false,
          "lastUpdateTime": 1517966743334,
          "longDescription": "Server Adminis one of theremote administration/configuration tools forMac OS X Server. It is used for remote administration of one or more servers. It can be used to install and setup Mac OS X Server on a remote computer, manage file share points, and configure service settings.",
          "name": "mac-srvr-admin",
          "nbarId": "568",
          "p2pTechnology": "false",
          "popularity": 1,
          "rank": 65535,
          "references": "http://support.apple.com/kb/HT5050",
          "selectorId": "660",
          "subCategory": "f3c0f761-d894-4112-ab89-b90fc84fd660",
          "trafficClass": "OPS_ADMIN_MGMT",
          "tunnel": "false"
        }
      ],
      "networkIdentity": [
        {
          "id": "6eb34ee2-5aeb-4f9e-b604-8330f1098745",
          "instanceId": 13798,
          "authEntityId": 13798,
          "displayName": "13798",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "660",
          "protocol": "TCP",
          "upperPort": 0
        },
        {
          "id": "7cfd7ae7-3c4b-4e5f-b9e1-5f7a9b0d583a",
          "instanceId": 13799,
          "authEntityId": 13799,
          "displayName": "13799",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "660",
          "protocol": "UDP",
          "upperPort": 0
        }
      ],
      "parentScalableGroup": {
        "idRef": "5fcd74d4-8726-47f7-a088-c457863da7ef"
      },
      "user": [

      ],
      "userGroup": [

      ]
    },
    {
      "id": "f515e556-27dd-4f45-aefa-964a832577e8",
      "instanceId": 4176,
      "authEntityId": 4176,
      "displayName": "4176",
      "authEntityClass": -1909073334,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "createTime": 1517966741537,
      "deployed": false,
      "isSeeded": true,
      "isStale": false,
      "lastUpdateTime": 1517966741537,
      "name": "bhfhs",
      "namespace": "scalablegroup:application",
      "provisioningState": "DEFINED",
      "qualifier": "application",
      "resourceVersion": 0,
      "targetIdList": [

      ],
      "type": "scalablegroup",
      "cfsChangeInfo": [

      ],
      "customProvisions": [

      ],
      "scalableGroupExternalHandle": "bhfhs",
      "scalableGroupType": "APPLICATION",
      "securityGroupTag": 0,
      "state": "ACTIVE",
      "vnAgnostic": true,
      "identitySource": {
        "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
        "instanceId": 10010,
        "authEntityId": 10010,
        "displayName": "10010",
        "authEntityClass": 1738953278,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "state": "INACTIVE",
        "type": "NBAR"
      },
      "indicativeNetworkIdentity": [
        {
          "id": "268153d9-ee0b-4eb0-bb4f-72aae4d6a1e7",
          "instanceId": 12380,
          "authEntityId": 12380,
          "displayName": "12380",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "248",
          "protocol": "UDP",
          "upperPort": 0
        },
        {
          "id": "bb4661a2-ea88-45a8-a3a8-5dc0bb00cfef",
          "instanceId": 12381,
          "authEntityId": 12381,
          "displayName": "12381",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "248",
          "protocol": "TCP",
          "upperPort": 0
        }
      ],
      "networkApplications": [
        {
          "id": "552dc3f9-ca92-4658-93e7-d9ac3952c1a6",
          "instanceId": 11135,
          "authEntityId": 11135,
          "displayName": "11135",
          "authEntityClass": -217092956,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "appProtocol": "tcp/udp",
          "applicationGroup": "other",
          "applicationType": "DEFAULT",
          "categoryId": "6daa3dfd-3526-407a-88f0-97824b3f3a24",
          "createTime": 1517966741537,
          "encrypted": "false",
          "engineId": "3",
          "helpString": "bhfhs",
          "ignoreConflict": false,
          "lastUpdateTime": 1517966741537,
          "longDescription": "Registered with IANA on port 248 TCP/UDP",
          "name": "bhfhs",
          "nbarId": "1128",
          "p2pTechnology": "false",
          "popularity": 1,
          "rank": 65535,
          "references": "http://www.iana.org/assignments/service-names-port-numbers/service-names-port-numbers.xml",
          "selectorId": "248",
          "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
          "trafficClass": "BULK_DATA",
          "tunnel": "false"
        }
      ],
      "networkIdentity": [
        {
          "id": "c5d6e13e-1870-4064-82aa-b3cf7d2462fd",
          "instanceId": 12378,
          "authEntityId": 12378,
          "displayName": "12378",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "248",
          "protocol": "TCP",
          "upperPort": 0
        },
        {
          "id": "9debcd1f-05ca-4a39-8f73-1b4d4994ee23",
          "instanceId": 12379,
          "authEntityId": 12379,
          "displayName": "12379",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "248",
          "protocol": "UDP",
          "upperPort": 0
        }
      ],
      "parentScalableGroup": {
        "idRef": "000c15bf-28fe-4fbb-9b69-35e6502721f1"
      },
      "user": [

      ],
      "userGroup": [

      ]
    },
    {
      "id": "f573ea73-0107-4822-934d-b7de7b26e652",
      "instanceId": 4869,
      "authEntityId": 4869,
      "displayName": "4869",
      "authEntityClass": -1909073334,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "createTime": 1517966743649,
      "deployed": false,
      "isSeeded": true,
      "isStale": false,
      "lastUpdateTime": 1517966743649,
      "name": "nmap",
      "namespace": "scalablegroup:application",
      "provisioningState": "DEFINED",
      "qualifier": "application",
      "resourceVersion": 0,
      "targetIdList": [

      ],
      "type": "scalablegroup",
      "cfsChangeInfo": [

      ],
      "customProvisions": [

      ],
      "scalableGroupExternalHandle": "nmap",
      "scalableGroupType": "APPLICATION",
      "securityGroupTag": 0,
      "state": "ACTIVE",
      "vnAgnostic": true,
      "identitySource": {
        "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
        "instanceId": 10010,
        "authEntityId": 10010,
        "displayName": "10010",
        "authEntityClass": 1738953278,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "state": "INACTIVE",
        "type": "NBAR"
      },
      "indicativeNetworkIdentity": [
        {
          "id": "8955a25b-fc73-4535-9c1b-75282b979a2d",
          "instanceId": 14250,
          "authEntityId": 14250,
          "displayName": "14250",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "689",
          "protocol": "UDP",
          "upperPort": 0
        },
        {
          "id": "b37d756a-9dba-4cbd-873e-008f3c65e5d4",
          "instanceId": 14251,
          "authEntityId": 14251,
          "displayName": "14251",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "689",
          "protocol": "TCP",
          "upperPort": 0
        }
      ],
      "networkApplications": [
        {
          "id": "f00ef79e-b08e-48e8-9b16-5c4407c8d4ad",
          "instanceId": 11818,
          "authEntityId": 11818,
          "displayName": "11818",
          "authEntityClass": -217092956,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "appProtocol": "tcp/udp",
          "applicationGroup": "other",
          "applicationType": "DEFAULT",
          "categoryId": "1fc17362-9334-4c7b-b752-5afdbb574017",
          "createTime": 1517966743649,
          "encrypted": "false",
          "engineId": "3",
          "helpString": "nmap",
          "ignoreConflict": false,
          "lastUpdateTime": 1517966743649,
          "longDescription": "Network Mapper (Nmap) is a security scanner used to discover hosts and services on a computer network, thus creating a \"map\" of the network. To accomplish its goal, Nmap sends specially crafted packets to the target host and then analyzes the responses. Nmap is able to determine the operating system of the target, names and versions of the listening services, estimated uptime, type of device, and presence of a firewall.",
          "name": "nmap",
          "nbarId": "597",
          "p2pTechnology": "false",
          "popularity": 1,
          "rank": 65535,
          "references": "http://nmap.org/",
          "selectorId": "689",
          "subCategory": "f3c0f761-d894-4112-ab89-b90fc84fd660",
          "trafficClass": "OPS_ADMIN_MGMT",
          "tunnel": "false"
        }
      ],
      "networkIdentity": [
        {
          "id": "2a52d7e4-ebf5-4b34-bc76-20389fd6f52c",
          "instanceId": 14248,
          "authEntityId": 14248,
          "displayName": "14248",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "689",
          "protocol": "TCP",
          "upperPort": 0
        },
        {
          "id": "d33257ad-dca4-478d-86e3-9f43d17563d1",
          "instanceId": 14249,
          "authEntityId": 14249,
          "displayName": "14249",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "689",
          "protocol": "UDP",
          "upperPort": 0
        }
      ],
      "parentScalableGroup": {
        "idRef": "5fcd74d4-8726-47f7-a088-c457863da7ef"
      },
      "user": [

      ],
      "userGroup": [

      ]
    },
    {
      "id": "f5c9468f-b693-4b7a-ab71-b8510f20a672",
      "instanceId": 4851,
      "authEntityId": 4851,
      "displayName": "4851",
      "authEntityClass": -1909073334,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "createTime": 1517966743614,
      "deployed": false,
      "isSeeded": true,
      "isStale": false,
      "lastUpdateTime": 1517966743614,
      "name": "netshow",
      "namespace": "scalablegroup:application",
      "provisioningState": "DEFINED",
      "qualifier": "application",
      "resourceVersion": 0,
      "targetIdList": [

      ],
      "type": "scalablegroup",
      "cfsChangeInfo": [

      ],
      "customProvisions": [

      ],
      "scalableGroupExternalHandle": "netshow",
      "scalableGroupType": "APPLICATION",
      "securityGroupTag": 0,
      "state": "ACTIVE",
      "vnAgnostic": true,
      "identitySource": {
        "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
        "instanceId": 10010,
        "authEntityId": 10010,
        "displayName": "10010",
        "authEntityClass": 1738953278,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "state": "INACTIVE",
        "type": "NBAR"
      },
      "indicativeNetworkIdentity": [
        {
          "id": "27ab687b-7d61-44fc-b679-12bbbc241bb2",
          "instanceId": 14189,
          "authEntityId": 14189,
          "displayName": "14189",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "1755",
          "protocol": "TCP",
          "upperPort": 0
        }
      ],
      "networkApplications": [
        {
          "id": "28010b2f-b3bb-44e4-b2a7-3ab87bac1917",
          "instanceId": 11800,
          "authEntityId": 11800,
          "displayName": "11800",
          "authEntityClass": -217092956,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "appProtocol": "tcp",
          "applicationGroup": "other",
          "applicationType": "DEFAULT",
          "categoryId": "a09ef1b3-ba26-4372-aee8-309d50697e76",
          "createTime": 1517966743614,
          "encrypted": "false",
          "engineId": "13",
          "helpString": "Microsoft Netshow, media streaming protocol",
          "ignoreConflict": false,
          "lastUpdateTime": 1517966743614,
          "longDescription": "Netshow is a Microsoft software designed to stream media over intranets and the internet. NetShow can transfer live multicast and on-demand streamed audio, illustrated audio and video. Typically, Netshow uses TCP port 1755 and UDP ports between 1024-5000.",
          "name": "netshow",
          "nbarId": "53",
          "p2pTechnology": "false",
          "popularity": 8,
          "rank": 65535,
          "references": "http://www.microsoft.com/presspass/press/1997/mar97/nsbta2pr.mspx",
          "selectorId": "426",
          "subCategory": "85a797c0-aef8-4284-91a7-677f33e29abb",
          "trafficClass": "MULTIMEDIA_STREAMING",
          "tunnel": "false"
        }
      ],
      "networkIdentity": [
        {
          "id": "b43c4d40-4a39-45f5-8c2c-921a3fb4fc19",
          "instanceId": 14188,
          "authEntityId": 14188,
          "displayName": "14188",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "1755",
          "protocol": "TCP",
          "upperPort": 0
        }
      ],
      "parentScalableGroup": {
        "idRef": "fd6264c9-c16a-4311-a335-a3db039256f3"
      },
      "user": [

      ],
      "userGroup": [

      ]
    },
    {
      "id": "f5da0e6d-f683-48dc-8ee4-7a32cade52a5",
      "instanceId": 15384,
      "authEntityId": 15384,
      "displayName": "15384",
      "authEntityClass": -1909073334,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "createTime": 1517966744848,
      "deployed": false,
      "isSeeded": true,
      "isStale": false,
      "lastUpdateTime": 1517966744848,
      "name": "wap-wsp-wtp",
      "namespace": "scalablegroup:application",
      "provisioningState": "DEFINED",
      "qualifier": "application",
      "resourceVersion": 0,
      "targetIdList": [

      ],
      "type": "scalablegroup",
      "cfsChangeInfo": [

      ],
      "customProvisions": [

      ],
      "scalableGroupExternalHandle": "wap-wsp-wtp",
      "scalableGroupType": "APPLICATION",
      "securityGroupTag": 0,
      "state": "ACTIVE",
      "vnAgnostic": true,
      "identitySource": {
        "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
        "instanceId": 10010,
        "authEntityId": 10010,
        "displayName": "10010",
        "authEntityClass": 1738953278,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "state": "INACTIVE",
        "type": "NBAR"
      },
      "indicativeNetworkIdentity": [
        {
          "id": "d1dc6003-19b7-4b47-b3ce-c3ed719c98ea",
          "instanceId": 17779,
          "authEntityId": 17779,
          "displayName": "17779",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "9201",
          "protocol": "UDP",
          "upperPort": 0
        },
        {
          "id": "38210dc3-4311-4301-bd43-f20bdbcbb6aa",
          "instanceId": 17780,
          "authEntityId": 17780,
          "displayName": "17780",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "9201",
          "protocol": "TCP",
          "upperPort": 0
        }
      ],
      "networkApplications": [
        {
          "id": "05ec0658-d316-4449-8f0c-0d61e5ee21a7",
          "instanceId": 16327,
          "authEntityId": 16327,
          "displayName": "16327",
          "authEntityClass": -217092956,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "appProtocol": "tcp/udp",
          "applicationGroup": "wap-group",
          "applicationType": "DEFAULT",
          "categoryId": "6daa3dfd-3526-407a-88f0-97824b3f3a24",
          "createTime": 1517966744848,
          "encrypted": "false",
          "engineId": "3",
          "helpString": "WAP session service",
          "ignoreConflict": false,
          "lastUpdateTime": 1517966744848,
          "longDescription": "Wireless Transaction Protocol (WTP) is a standard used in mobile telephony. It is a layer of the Wireless Application Protocol (WAP) that is intended to bring Internet access to mobile phones.",
          "name": "wap-wsp-wtp",
          "nbarId": "730",
          "p2pTechnology": "false",
          "popularity": 2,
          "rank": 65535,
          "references": "http://www.openmobilealliance.org/",
          "selectorId": "9201",
          "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
          "trafficClass": "BULK_DATA",
          "tunnel": "false"
        }
      ],
      "networkIdentity": [
        {
          "id": "54384191-3839-49de-8faa-c7da6d3ca5a1",
          "instanceId": 17777,
          "authEntityId": 17777,
          "displayName": "17777",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "9201",
          "protocol": "UDP",
          "upperPort": 0
        },
        {
          "id": "16f8c1e6-6e54-4bda-93c5-25980ee42a2d",
          "instanceId": 17778,
          "authEntityId": 17778,
          "displayName": "17778",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "9201",
          "protocol": "TCP",
          "upperPort": 0
        }
      ],
      "parentScalableGroup": {
        "idRef": "000c15bf-28fe-4fbb-9b69-35e6502721f1"
      },
      "user": [

      ],
      "userGroup": [

      ]
    },
    {
      "id": "f60b306e-0062-4693-b65c-1413894e0521",
      "instanceId": 15349,
      "authEntityId": 15349,
      "displayName": "15349",
      "authEntityClass": -1909073334,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "createTime": 1517966744784,
      "deployed": false,
      "isSeeded": true,
      "isStale": false,
      "lastUpdateTime": 1517966744784,
      "name": "viber",
      "namespace": "scalablegroup:application",
      "provisioningState": "DEFINED",
      "qualifier": "application",
      "resourceVersion": 0,
      "targetIdList": [

      ],
      "type": "scalablegroup",
      "cfsChangeInfo": [

      ],
      "customProvisions": [

      ],
      "scalableGroupExternalHandle": "viber",
      "scalableGroupType": "APPLICATION",
      "securityGroupTag": 0,
      "state": "ACTIVE",
      "vnAgnostic": true,
      "identitySource": {
        "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
        "instanceId": 10010,
        "authEntityId": 10010,
        "displayName": "10010",
        "authEntityClass": 1738953278,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "state": "INACTIVE",
        "type": "NBAR"
      },
      "indicativeNetworkIdentity": [

      ],
      "networkApplications": [
        {
          "id": "13d6c3fd-f420-4d47-b337-151b8a01a126",
          "instanceId": 16292,
          "authEntityId": 16292,
          "displayName": "16292",
          "authEntityClass": -217092956,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "appProtocol": "tcp",
          "applicationGroup": "other",
          "applicationType": "DEFAULT",
          "categoryId": "82a7b7fa-da5a-48f5-8b6a-a2546b947843",
          "createTime": 1517966744784,
          "encrypted": "false",
          "engineId": "13",
          "helpString": "Viber VoIP is a mobile voice communication application.",
          "ignoreConflict": false,
          "lastUpdateTime": 1517966744784,
          "longDescription": "Viber is a VoIP application for iPhone and Android phones that lets users make free phone calls and send text messages to anyone who also has the application installed. The application uses 3G or Wi-Fi connection to access the internet.",
          "name": "viber",
          "nbarId": "1320",
          "p2pTechnology": "false",
          "popularity": 7,
          "rank": 65535,
          "references": "http://www.viber.com/",
          "selectorId": "1320",
          "subCategory": "118c10a2-e272-47c1-92b9-4814ebe97138",
          "trafficClass": "MULTIMEDIA_CONFERENCING",
          "tunnel": "false"
        }
      ],
      "networkIdentity": [
        {
          "id": "764b2453-a4a7-4f15-b198-54f07d0fe3c7",
          "instanceId": 17665,
          "authEntityId": 17665,
          "displayName": "17665",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "80",
          "protocol": "TCP",
          "upperPort": 0
        }
      ],
      "parentScalableGroup": {
        "idRef": "fd6264c9-c16a-4311-a335-a3db039256f3"
      },
      "user": [

      ],
      "userGroup": [

      ]
    },
    {
      "id": "f63ce433-d330-4849-b716-36b14c2d3736",
      "instanceId": 4670,
      "authEntityId": 4670,
      "displayName": "4670",
      "authEntityClass": -1909073334,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "createTime": 1517966743248,
      "deployed": false,
      "isSeeded": true,
      "isStale": false,
      "lastUpdateTime": 1517966743248,
      "name": "kayak-com",
      "namespace": "scalablegroup:application",
      "provisioningState": "DEFINED",
      "qualifier": "application",
      "resourceVersion": 0,
      "targetIdList": [

      ],
      "type": "scalablegroup",
      "cfsChangeInfo": [

      ],
      "customProvisions": [

      ],
      "scalableGroupExternalHandle": "kayak-com",
      "scalableGroupType": "APPLICATION",
      "securityGroupTag": 0,
      "state": "ACTIVE",
      "vnAgnostic": true,
      "identitySource": {
        "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
        "instanceId": 10010,
        "authEntityId": 10010,
        "displayName": "10010",
        "authEntityClass": 1738953278,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "state": "INACTIVE",
        "type": "NBAR"
      },
      "indicativeNetworkIdentity": [

      ],
      "networkApplications": [
        {
          "id": "d520e96c-0978-48ac-ab93-73ab46b5116f",
          "instanceId": 11620,
          "authEntityId": 11620,
          "displayName": "11620",
          "authEntityClass": -217092956,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "appProtocol": "tcp/udp",
          "applicationGroup": "other",
          "applicationType": "DEFAULT",
          "categoryId": "878644b5-fe91-45b4-9204-31c84a73ba48",
          "createTime": 1517966743248,
          "encrypted": "false",
          "engineId": "13",
          "helpString": "Kayak - Online Flight and Hotel reservation/deals website.",
          "ignoreConflict": false,
          "lastUpdateTime": 1517966743248,
          "longDescription": "Kayak - Online Flight and Hotel reservation/deals website.",
          "name": "kayak-com",
          "nbarId": "1638",
          "p2pTechnology": "false",
          "popularity": 3,
          "rank": 65535,
          "references": "http://kayak.com",
          "selectorId": "708",
          "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
          "trafficClass": "TRANSACTIONAL_DATA",
          "tunnel": "false"
        }
      ],
      "networkIdentity": [
        {
          "id": "53dba465-46c8-4eb1-bfcc-e2fd042352b2",
          "instanceId": 13682,
          "authEntityId": 13682,
          "displayName": "13682",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "53,5353",
          "protocol": "UDP",
          "upperPort": 0
        },
        {
          "id": "65abeb0c-7e21-4591-9902-c507e50021bf",
          "instanceId": 13683,
          "authEntityId": 13683,
          "displayName": "13683",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "53,80,443,5353",
          "protocol": "TCP",
          "upperPort": 0
        }
      ],
      "parentScalableGroup": {
        "idRef": "28e08de4-d7b2-4521-84ce-bc1e5ee0b9cc"
      },
      "user": [

      ],
      "userGroup": [

      ]
    },
    {
      "id": "f6703490-5ef7-4410-8927-7dc388e6aa97",
      "instanceId": 4119,
      "authEntityId": 4119,
      "displayName": "4119",
      "authEntityClass": -1909073334,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "createTime": 1517966741232,
      "deployed": false,
      "isSeeded": true,
      "isStale": false,
      "lastUpdateTime": 1517966741232,
      "name": "aris",
      "namespace": "scalablegroup:application",
      "provisioningState": "DEFINED",
      "qualifier": "application",
      "resourceVersion": 0,
      "targetIdList": [

      ],
      "type": "scalablegroup",
      "cfsChangeInfo": [

      ],
      "customProvisions": [

      ],
      "scalableGroupExternalHandle": "aris",
      "scalableGroupType": "APPLICATION",
      "securityGroupTag": 0,
      "state": "ACTIVE",
      "vnAgnostic": true,
      "identitySource": {
        "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
        "instanceId": 10010,
        "authEntityId": 10010,
        "displayName": "10010",
        "authEntityClass": 1738953278,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "state": "INACTIVE",
        "type": "NBAR"
      },
      "indicativeNetworkIdentity": [
        {
          "id": "c13fad62-89a3-4719-b6a7-cd48f42083e6",
          "instanceId": 12212,
          "authEntityId": 12212,
          "displayName": "12212",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipProtocolNumbers": "104",
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "protocol": "ARIS",
          "upperPort": 0
        }
      ],
      "networkApplications": [
        {
          "id": "87d1d707-bd7a-47da-adb7-fecb1b9cd511",
          "instanceId": 11081,
          "authEntityId": 11081,
          "displayName": "11081",
          "authEntityClass": -217092956,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "appProtocol": "ip",
          "applicationGroup": "other",
          "applicationType": "DEFAULT",
          "categoryId": "6daa3dfd-3526-407a-88f0-97824b3f3a24",
          "createTime": 1517966741232,
          "encrypted": "unassigned",
          "engineId": "1",
          "helpString": "ARIS",
          "ignoreConflict": false,
          "lastUpdateTime": 1517966741232,
          "longDescription": "Aggregate Route-Based IP Switching (ARIS) establishes switched paths through a network, leveraging the advantages of switching technologies in an internet network.",
          "name": "aris",
          "nbarId": "858",
          "p2pTechnology": "unassigned",
          "popularity": 2,
          "rank": 65535,
          "references": "http://tools.ietf.org/html/draft-viswanathan-aris-overview-00",
          "selectorId": "104",
          "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
          "trafficClass": "BULK_DATA",
          "tunnel": "unassigned"
        }
      ],
      "networkIdentity": [
        {
          "id": "ee3ab964-024a-4859-a874-25c7b75833d0",
          "instanceId": 12211,
          "authEntityId": 12211,
          "displayName": "12211",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipProtocolNumbers": "104",
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "protocol": "ARIS",
          "upperPort": 0
        }
      ],
      "parentScalableGroup": {
        "idRef": "000c15bf-28fe-4fbb-9b69-35e6502721f1"
      },
      "user": [

      ],
      "userGroup": [

      ]
    },
    {
      "id": "f68745e3-a924-4ef5-acd1-94f8a7ee7329",
      "instanceId": 4703,
      "authEntityId": 4703,
      "displayName": "4703",
      "authEntityClass": -1909073334,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "createTime": 1517966743317,
      "deployed": false,
      "isSeeded": true,
      "isStale": false,
      "lastUpdateTime": 1517966743317,
      "name": "locus-con",
      "namespace": "scalablegroup:application",
      "provisioningState": "DEFINED",
      "qualifier": "application",
      "resourceVersion": 0,
      "targetIdList": [

      ],
      "type": "scalablegroup",
      "cfsChangeInfo": [

      ],
      "customProvisions": [

      ],
      "scalableGroupExternalHandle": "locus-con",
      "scalableGroupType": "APPLICATION",
      "securityGroupTag": 0,
      "state": "ACTIVE",
      "vnAgnostic": true,
      "identitySource": {
        "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
        "instanceId": 10010,
        "authEntityId": 10010,
        "displayName": "10010",
        "authEntityClass": 1738953278,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "state": "INACTIVE",
        "type": "NBAR"
      },
      "indicativeNetworkIdentity": [
        {
          "id": "70943868-ad02-4c82-89e8-f4b7ef399e48",
          "instanceId": 13778,
          "authEntityId": 13778,
          "displayName": "13778",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "127",
          "protocol": "TCP",
          "upperPort": 0
        },
        {
          "id": "97e8bc2c-07fa-468b-bd1f-775ab12d7e3c",
          "instanceId": 13779,
          "authEntityId": 13779,
          "displayName": "13779",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "127",
          "protocol": "UDP",
          "upperPort": 0
        }
      ],
      "networkApplications": [
        {
          "id": "05ad9967-164c-477d-8efa-488a716d1a24",
          "instanceId": 11653,
          "authEntityId": 11653,
          "displayName": "11653",
          "authEntityClass": -217092956,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "appProtocol": "tcp/udp",
          "applicationGroup": "other",
          "applicationType": "DEFAULT",
          "categoryId": "1fc17362-9334-4c7b-b752-5afdbb574017",
          "createTime": 1517966743317,
          "encrypted": "false",
          "engineId": "3",
          "helpString": "Locus PC-Interface Conn Server",
          "ignoreConflict": false,
          "lastUpdateTime": 1517966743317,
          "longDescription": "Registered with IANA on port 127 TCP/UDP",
          "name": "locus-con",
          "nbarId": "996",
          "p2pTechnology": "false",
          "popularity": 1,
          "rank": 65535,
          "references": "http://www.iana.org/assignments/service-names-port-numbers/service-names-port-numbers.xml",
          "selectorId": "127",
          "subCategory": "f3c0f761-d894-4112-ab89-b90fc84fd660",
          "trafficClass": "OPS_ADMIN_MGMT",
          "tunnel": "false"
        }
      ],
      "networkIdentity": [
        {
          "id": "250aac07-bfd0-48ca-ae90-71dd1c3bb39d",
          "instanceId": 13776,
          "authEntityId": 13776,
          "displayName": "13776",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "127",
          "protocol": "TCP",
          "upperPort": 0
        },
        {
          "id": "e7cb597f-99e0-4f52-b0bc-d35c0d872688",
          "instanceId": 13777,
          "authEntityId": 13777,
          "displayName": "13777",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "127",
          "protocol": "UDP",
          "upperPort": 0
        }
      ],
      "parentScalableGroup": {
        "idRef": "5fcd74d4-8726-47f7-a088-c457863da7ef"
      },
      "user": [

      ],
      "userGroup": [

      ]
    },
    {
      "id": "f6abd930-25f4-430c-9542-cdbbec0356b0",
      "instanceId": 4171,
      "authEntityId": 4171,
      "displayName": "4171",
      "authEntityClass": -1909073334,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "createTime": 1517966741524,
      "deployed": false,
      "isSeeded": true,
      "isStale": false,
      "lastUpdateTime": 1517966741524,
      "name": "bftp",
      "namespace": "scalablegroup:application",
      "provisioningState": "DEFINED",
      "qualifier": "application",
      "resourceVersion": 0,
      "targetIdList": [

      ],
      "type": "scalablegroup",
      "cfsChangeInfo": [

      ],
      "customProvisions": [

      ],
      "scalableGroupExternalHandle": "bftp",
      "scalableGroupType": "APPLICATION",
      "securityGroupTag": 0,
      "state": "ACTIVE",
      "vnAgnostic": true,
      "identitySource": {
        "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
        "instanceId": 10010,
        "authEntityId": 10010,
        "displayName": "10010",
        "authEntityClass": 1738953278,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "state": "INACTIVE",
        "type": "NBAR"
      },
      "indicativeNetworkIdentity": [
        {
          "id": "4476ac6f-eeb1-4863-b8df-ab94ca7548e5",
          "instanceId": 12360,
          "authEntityId": 12360,
          "displayName": "12360",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "152",
          "protocol": "UDP",
          "upperPort": 0
        },
        {
          "id": "0c146d4d-4106-49d5-ae23-37129c5572c9",
          "instanceId": 12361,
          "authEntityId": 12361,
          "displayName": "12361",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "152",
          "protocol": "TCP",
          "upperPort": 0
        }
      ],
      "networkApplications": [
        {
          "id": "8115a004-50d2-4dd5-ae38-fad427897139",
          "instanceId": 11130,
          "authEntityId": 11130,
          "displayName": "11130",
          "authEntityClass": -217092956,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "appProtocol": "tcp/udp",
          "applicationGroup": "ftp-group",
          "applicationType": "DEFAULT",
          "categoryId": "e6619ce8-97de-4c63-a53a-cceddbddc1a8",
          "createTime": 1517966741524,
          "encrypted": "false",
          "engineId": "3",
          "helpString": "Background File Transfer Program",
          "ignoreConflict": false,
          "lastUpdateTime": 1517966741524,
          "longDescription": "Background File Transfer Program (BFTP) is a file transfer service that is built upon the third-party transfer model of FTP. It performs file transfer asynchronously and eliminates requirement for a human user to be directly involved at the time that a file transfer takes place.",
          "name": "bftp",
          "nbarId": "992",
          "p2pTechnology": "false",
          "popularity": 1,
          "rank": 65535,
          "references": "http://tools.ietf.org/html/rfc1068",
          "selectorId": "152",
          "subCategory": "50b55e2e-d0d0-4404-bea4-819351565d90",
          "trafficClass": "BULK_DATA",
          "tunnel": "false"
        }
      ],
      "networkIdentity": [
        {
          "id": "c4538c93-912a-4706-b73e-beac6d85cdd6",
          "instanceId": 12358,
          "authEntityId": 12358,
          "displayName": "12358",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "152",
          "protocol": "TCP",
          "upperPort": 0
        },
        {
          "id": "41a3c76b-895f-45b6-a69e-a4d188901f22",
          "instanceId": 12359,
          "authEntityId": 12359,
          "displayName": "12359",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "152",
          "protocol": "UDP",
          "upperPort": 0
        }
      ],
      "parentScalableGroup": {
        "idRef": "383363b1-4dd5-49d1-bfe4-34daac437cfb"
      },
      "user": [

      ],
      "userGroup": [

      ]
    },
    {
      "id": "f7384a4a-6771-437f-8291-4d69e4d19b13",
      "instanceId": 4635,
      "authEntityId": 4635,
      "displayName": "4635",
      "authEntityClass": -1909073334,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "createTime": 1517966743174,
      "deployed": false,
      "isSeeded": true,
      "isStale": false,
      "lastUpdateTime": 1517966743174,
      "name": "ipx-in-ip",
      "namespace": "scalablegroup:application",
      "provisioningState": "DEFINED",
      "qualifier": "application",
      "resourceVersion": 0,
      "targetIdList": [

      ],
      "type": "scalablegroup",
      "cfsChangeInfo": [

      ],
      "customProvisions": [

      ],
      "scalableGroupExternalHandle": "ipx-in-ip",
      "scalableGroupType": "APPLICATION",
      "securityGroupTag": 0,
      "state": "ACTIVE",
      "vnAgnostic": true,
      "identitySource": {
        "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
        "instanceId": 10010,
        "authEntityId": 10010,
        "displayName": "10010",
        "authEntityClass": 1738953278,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "state": "INACTIVE",
        "type": "NBAR"
      },
      "indicativeNetworkIdentity": [
        {
          "id": "9a4ed0f0-8ea6-4603-91ca-542a045f13c2",
          "instanceId": 13588,
          "authEntityId": 13588,
          "displayName": "13588",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipProtocolNumbers": "111",
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "protocol": "IPX_IN_IP",
          "upperPort": 0
        }
      ],
      "networkApplications": [
        {
          "id": "cb8af0f3-b842-46cc-a341-a98c384632a9",
          "instanceId": 11585,
          "authEntityId": 11585,
          "displayName": "11585",
          "authEntityClass": -217092956,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "appProtocol": "ip",
          "applicationGroup": "other",
          "applicationType": "DEFAULT",
          "categoryId": "70fce3cd-f2bd-48de-9020-7b7840b9e7a1",
          "createTime": 1517966743174,
          "encrypted": "false",
          "engineId": "1",
          "helpString": "IPX in IP",
          "ignoreConflict": false,
          "lastUpdateTime": 1517966743174,
          "longDescription": "Internetwork Packet Exchange (IPX) is the OSI-model Network layer protocol in the IPX/SPX protocol stack. The IPX/SPXM protocol stack is supported by Novell's NetWare network operating system. IPX could be transported over IP, mainly for backward compatibility.",
          "name": "ipx-in-ip",
          "nbarId": "865",
          "p2pTechnology": "false",
          "popularity": 2,
          "rank": 65535,
          "references": "http://tools.ietf.org/html/rfc1234",
          "selectorId": "111",
          "subCategory": "ab63e736-9aa7-4946-b55b-3b26ddca609c",
          "trafficClass": "BULK_DATA",
          "tunnel": "true"
        }
      ],
      "networkIdentity": [
        {
          "id": "377ae54f-833a-4129-a9ae-7a46ccde6a96",
          "instanceId": 13587,
          "authEntityId": 13587,
          "displayName": "13587",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipProtocolNumbers": "111",
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "protocol": "IPX_IN_IP",
          "upperPort": 0
        }
      ],
      "parentScalableGroup": {
        "idRef": "79f9d505-477f-4ccd-bd2a-c5fc8097a2e1"
      },
      "user": [

      ],
      "userGroup": [

      ]
    },
    {
      "id": "f74f5b58-acdf-4baa-9fbf-becdf45800d1",
      "instanceId": 4261,
      "authEntityId": 4261,
      "displayName": "4261",
      "authEntityClass": -1909073334,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "createTime": 1517966741976,
      "deployed": false,
      "isSeeded": true,
      "isStale": false,
      "lastUpdateTime": 1517966741976,
      "name": "citrix-audio",
      "namespace": "scalablegroup:application",
      "provisioningState": "DEFINED",
      "qualifier": "application",
      "resourceVersion": 0,
      "targetIdList": [

      ],
      "type": "scalablegroup",
      "cfsChangeInfo": [

      ],
      "customProvisions": [

      ],
      "scalableGroupExternalHandle": "citrix-audio",
      "scalableGroupType": "APPLICATION",
      "securityGroupTag": 0,
      "state": "ACTIVE",
      "vnAgnostic": true,
      "identitySource": {
        "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
        "instanceId": 10010,
        "authEntityId": 10010,
        "displayName": "10010",
        "authEntityClass": 1738953278,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "state": "INACTIVE",
        "type": "NBAR"
      },
      "indicativeNetworkIdentity": [

      ],
      "networkApplications": [
        {
          "id": "7d65d3a4-9ead-4ec2-8440-e4f7d5f85e93",
          "instanceId": 11215,
          "authEntityId": 11215,
          "displayName": "11215",
          "authEntityClass": -217092956,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "appProtocol": "tcp",
          "applicationGroup": "other",
          "applicationType": "DEFAULT",
          "categoryId": "7e19fdea-6222-4c57-9e66-66c2340d6bc3",
          "createTime": 1517966741976,
          "encrypted": "true",
          "engineId": "13",
          "helpString": "Citrix Audio Traffic",
          "ignoreConflict": false,
          "lastUpdateTime": 1517966741976,
          "longDescription": "Citrix-Audio refers to all audio traffic in Citrix multistream configuration.",
          "name": "citrix-audio",
          "nbarId": "1548",
          "p2pTechnology": "false",
          "popularity": 8,
          "rank": 65535,
          "references": "http://www.citrix.com/site/resources/dynamic/additional/ICA_Acceleration_0709a.pdf",
          "selectorId": "609",
          "subCategory": "ff664205-64f3-447e-acff-1ec1ba9a6c31",
          "trafficClass": "VOIP_TELEPHONY",
          "tunnel": "false"
        }
      ],
      "networkIdentity": [
        {
          "id": "1b929152-1168-4eab-94b7-40486376936f",
          "instanceId": 12557,
          "authEntityId": 12557,
          "displayName": "12557",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "1494,2598",
          "protocol": "TCP",
          "upperPort": 0
        }
      ],
      "parentScalableGroup": {
        "idRef": "4a6b37c6-7e11-40fe-a6b1-719b24813145"
      },
      "user": [

      ],
      "userGroup": [

      ]
    },
    {
      "id": "f751b12d-291f-4ad7-b0ca-d4a0a5ce5a03",
      "instanceId": 4620,
      "authEntityId": 4620,
      "displayName": "4620",
      "authEntityClass": -1909073334,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "createTime": 1517966743141,
      "deployed": false,
      "isSeeded": true,
      "isStale": false,
      "lastUpdateTime": 1517966743141,
      "name": "ipcv",
      "namespace": "scalablegroup:application",
      "provisioningState": "DEFINED",
      "qualifier": "application",
      "resourceVersion": 0,
      "targetIdList": [

      ],
      "type": "scalablegroup",
      "cfsChangeInfo": [

      ],
      "customProvisions": [

      ],
      "scalableGroupExternalHandle": "ipcv",
      "scalableGroupType": "APPLICATION",
      "securityGroupTag": 0,
      "state": "ACTIVE",
      "vnAgnostic": true,
      "identitySource": {
        "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
        "instanceId": 10010,
        "authEntityId": 10010,
        "displayName": "10010",
        "authEntityClass": 1738953278,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "state": "INACTIVE",
        "type": "NBAR"
      },
      "indicativeNetworkIdentity": [
        {
          "id": "d20b74f3-6715-4f13-9571-7c13f304ffe0",
          "instanceId": 13566,
          "authEntityId": 13566,
          "displayName": "13566",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipProtocolNumbers": "71",
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "protocol": "IPCV",
          "upperPort": 0
        }
      ],
      "networkApplications": [
        {
          "id": "8d57ba9b-477d-4ed8-9d17-eec19bb746da",
          "instanceId": 11570,
          "authEntityId": 11570,
          "displayName": "11570",
          "authEntityClass": -217092956,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "appProtocol": "ip",
          "applicationGroup": "other",
          "applicationType": "DEFAULT",
          "categoryId": "6daa3dfd-3526-407a-88f0-97824b3f3a24",
          "createTime": 1517966743141,
          "encrypted": "unassigned",
          "engineId": "1",
          "helpString": "Internet Packet Core Utility",
          "ignoreConflict": false,
          "lastUpdateTime": 1517966743141,
          "longDescription": "Registered with IANA as IP Protocol 71",
          "name": "ipcv",
          "nbarId": "825",
          "p2pTechnology": "unassigned",
          "popularity": 1,
          "rank": 65535,
          "references": "http://www.iana.org/assignments/protocol-numbers/protocol-numbers.xml",
          "selectorId": "71",
          "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
          "trafficClass": "BULK_DATA",
          "tunnel": "unassigned"
        }
      ],
      "networkIdentity": [
        {
          "id": "0ad32a9a-e79b-4467-8c67-0f9f55754b9e",
          "instanceId": 13565,
          "authEntityId": 13565,
          "displayName": "13565",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipProtocolNumbers": "71",
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "protocol": "IPCV",
          "upperPort": 0
        }
      ],
      "parentScalableGroup": {
        "idRef": "000c15bf-28fe-4fbb-9b69-35e6502721f1"
      },
      "user": [

      ],
      "userGroup": [

      ]
    },
    {
      "id": "f765a94d-05a6-41dc-8a48-2be9afe20a15",
      "instanceId": 4663,
      "authEntityId": 4663,
      "displayName": "4663",
      "authEntityClass": -1909073334,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "createTime": 1517966743233,
      "deployed": false,
      "isSeeded": true,
      "isStale": false,
      "lastUpdateTime": 1517966743233,
      "name": "jimdo",
      "namespace": "scalablegroup:application",
      "provisioningState": "DEFINED",
      "qualifier": "application",
      "resourceVersion": 0,
      "targetIdList": [

      ],
      "type": "scalablegroup",
      "cfsChangeInfo": [

      ],
      "customProvisions": [

      ],
      "scalableGroupExternalHandle": "jimdo",
      "scalableGroupType": "APPLICATION",
      "securityGroupTag": 0,
      "state": "ACTIVE",
      "vnAgnostic": true,
      "identitySource": {
        "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
        "instanceId": 10010,
        "authEntityId": 10010,
        "displayName": "10010",
        "authEntityClass": 1738953278,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "state": "INACTIVE",
        "type": "NBAR"
      },
      "indicativeNetworkIdentity": [

      ],
      "networkApplications": [
        {
          "id": "0f527540-6f0a-4e05-b48c-1ac0566ea44e",
          "instanceId": 11613,
          "authEntityId": 11613,
          "displayName": "11613",
          "authEntityClass": -217092956,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "appProtocol": "tcp/udp",
          "applicationGroup": "other",
          "applicationType": "DEFAULT",
          "categoryId": "878644b5-fe91-45b4-9204-31c84a73ba48",
          "createTime": 1517966743233,
          "encrypted": "false",
          "engineId": "13",
          "helpString": "Jimdo - Portal for to creating web site and blog.",
          "ignoreConflict": false,
          "lastUpdateTime": 1517966743233,
          "longDescription": "Jimdo - Portal for to creating web site and blog.",
          "name": "jimdo",
          "nbarId": "1678",
          "p2pTechnology": "false",
          "popularity": 3,
          "rank": 65535,
          "references": "http://jimdo.com",
          "selectorId": "748",
          "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
          "trafficClass": "TRANSACTIONAL_DATA",
          "tunnel": "false"
        }
      ],
      "networkIdentity": [
        {
          "id": "2cee0068-047e-45c3-a672-79fc3756ed36",
          "instanceId": 13664,
          "authEntityId": 13664,
          "displayName": "13664",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "53,5353",
          "protocol": "UDP",
          "upperPort": 0
        },
        {
          "id": "b0b68848-a337-4166-95a1-0203d49661e0",
          "instanceId": 13665,
          "authEntityId": 13665,
          "displayName": "13665",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "53,80,443,5353",
          "protocol": "TCP",
          "upperPort": 0
        }
      ],
      "parentScalableGroup": {
        "idRef": "28e08de4-d7b2-4521-84ce-bc1e5ee0b9cc"
      },
      "user": [

      ],
      "userGroup": [

      ]
    },
    {
      "id": "f7825db6-01c8-4163-9f30-07af931132e9",
      "instanceId": 4422,
      "authEntityId": 4422,
      "displayName": "4422",
      "authEntityClass": -1909073334,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "createTime": 1517966742601,
      "deployed": false,
      "isSeeded": true,
      "isStale": false,
      "lastUpdateTime": 1517966742601,
      "name": "esignal",
      "namespace": "scalablegroup:application",
      "provisioningState": "DEFINED",
      "qualifier": "application",
      "resourceVersion": 0,
      "targetIdList": [

      ],
      "type": "scalablegroup",
      "cfsChangeInfo": [

      ],
      "customProvisions": [

      ],
      "scalableGroupExternalHandle": "esignal",
      "scalableGroupType": "APPLICATION",
      "securityGroupTag": 0,
      "state": "ACTIVE",
      "vnAgnostic": true,
      "identitySource": {
        "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
        "instanceId": 10010,
        "authEntityId": 10010,
        "displayName": "10010",
        "authEntityClass": 1738953278,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "state": "INACTIVE",
        "type": "NBAR"
      },
      "indicativeNetworkIdentity": [
        {
          "id": "4534d52f-4509-48c0-bd8f-b23a73c65d69",
          "instanceId": 13065,
          "authEntityId": 13065,
          "displayName": "13065",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "2189,2194,2196",
          "protocol": "TCP",
          "upperPort": 0
        }
      ],
      "networkApplications": [
        {
          "id": "a2869483-37c0-419e-b2c3-74382ffb66e1",
          "instanceId": 11373,
          "authEntityId": 11373,
          "displayName": "11373",
          "authEntityClass": -217092956,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "appProtocol": "tcp",
          "applicationGroup": "other",
          "applicationType": "DEFAULT",
          "categoryId": "6daa3dfd-3526-407a-88f0-97824b3f3a24",
          "createTime": 1517966742601,
          "encrypted": "false",
          "engineId": "3",
          "helpString": "Real-time market data and decision support tools",
          "ignoreConflict": false,
          "lastUpdateTime": 1517966742601,
          "longDescription": "Used by eSignal in their online trading line of products.",
          "name": "esignal",
          "nbarId": "1380",
          "p2pTechnology": "false",
          "popularity": 1,
          "rank": 65535,
          "references": "http://www.esignal.com/",
          "selectorId": "2189",
          "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
          "trafficClass": "BULK_DATA",
          "tunnel": "false"
        }
      ],
      "networkIdentity": [
        {
          "id": "044467b5-1be3-4949-957b-2ddaecf6cad7",
          "instanceId": 13064,
          "authEntityId": 13064,
          "displayName": "13064",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "2189,2194,2196",
          "protocol": "TCP",
          "upperPort": 0
        }
      ],
      "parentScalableGroup": {
        "idRef": "000c15bf-28fe-4fbb-9b69-35e6502721f1"
      },
      "user": [

      ],
      "userGroup": [

      ]
    },
    {
      "id": "f782b6e0-65c9-4de5-a602-b44f616800e8",
      "instanceId": 4365,
      "authEntityId": 4365,
      "displayName": "4365",
      "authEntityClass": -1909073334,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "createTime": 1517966742415,
      "deployed": false,
      "isSeeded": true,
      "isStale": false,
      "lastUpdateTime": 1517966742415,
      "name": "directv-catlg",
      "namespace": "scalablegroup:application",
      "provisioningState": "DEFINED",
      "qualifier": "application",
      "resourceVersion": 0,
      "targetIdList": [

      ],
      "type": "scalablegroup",
      "cfsChangeInfo": [

      ],
      "customProvisions": [

      ],
      "scalableGroupExternalHandle": "directv-catlg",
      "scalableGroupType": "APPLICATION",
      "securityGroupTag": 0,
      "state": "ACTIVE",
      "vnAgnostic": true,
      "identitySource": {
        "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
        "instanceId": 10010,
        "authEntityId": 10010,
        "displayName": "10010",
        "authEntityClass": 1738953278,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "state": "INACTIVE",
        "type": "NBAR"
      },
      "indicativeNetworkIdentity": [
        {
          "id": "39f351fc-5242-4189-91ad-aa01dec3bd30",
          "instanceId": 12877,
          "authEntityId": 12877,
          "displayName": "12877",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "3337",
          "protocol": "TCP",
          "upperPort": 0
        },
        {
          "id": "457c2779-16f9-467e-ad39-630748b59886",
          "instanceId": 12878,
          "authEntityId": 12878,
          "displayName": "12878",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "3337",
          "protocol": "UDP",
          "upperPort": 0
        }
      ],
      "networkApplications": [
        {
          "id": "22271446-5e92-4b4a-89e8-f42aba7451e9",
          "instanceId": 11316,
          "authEntityId": 11316,
          "displayName": "11316",
          "authEntityClass": -217092956,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "appProtocol": "tcp/udp",
          "applicationGroup": "other",
          "applicationType": "DEFAULT",
          "categoryId": "a09ef1b3-ba26-4372-aee8-309d50697e76",
          "createTime": 1517966742415,
          "encrypted": "false",
          "engineId": "3",
          "helpString": "Direct TV Data Catalog",
          "ignoreConflict": false,
          "lastUpdateTime": 1517966742415,
          "longDescription": "A DirecTV service - Data Catalog. DirecTV (DIRECTV) is an America+E41ndirect broadcast satelliteservice provider and broadcaster.",
          "name": "directv-catlg",
          "nbarId": "723",
          "p2pTechnology": "false",
          "popularity": 1,
          "rank": 65535,
          "references": "http://www.directv.com",
          "selectorId": "3337",
          "subCategory": "85a797c0-aef8-4284-91a7-677f33e29abb",
          "trafficClass": "MULTIMEDIA_STREAMING",
          "tunnel": "false"
        }
      ],
      "networkIdentity": [
        {
          "id": "745bb2fb-16c8-4b02-bb2f-aa2ce58e727c",
          "instanceId": 12875,
          "authEntityId": 12875,
          "displayName": "12875",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "3337",
          "protocol": "UDP",
          "upperPort": 0
        },
        {
          "id": "3ef3e9dc-b7c2-4cb5-8d30-6a6565f18fae",
          "instanceId": 12876,
          "authEntityId": 12876,
          "displayName": "12876",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "3337",
          "protocol": "TCP",
          "upperPort": 0
        }
      ],
      "parentScalableGroup": {
        "idRef": "fd6264c9-c16a-4311-a335-a3db039256f3"
      },
      "user": [

      ],
      "userGroup": [

      ]
    },
    {
      "id": "f78f1771-0805-4714-bd36-fe05fe5fbcf1",
      "instanceId": 4064,
      "authEntityId": 4064,
      "displayName": "4064",
      "authEntityClass": -1909073334,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "createTime": 1517966740728,
      "deployed": false,
      "isSeeded": true,
      "isStale": false,
      "lastUpdateTime": 1517966740728,
      "name": "aed-512",
      "namespace": "scalablegroup:application",
      "provisioningState": "DEFINED",
      "qualifier": "application",
      "resourceVersion": 0,
      "targetIdList": [

      ],
      "type": "scalablegroup",
      "cfsChangeInfo": [

      ],
      "customProvisions": [

      ],
      "scalableGroupExternalHandle": "aed-512",
      "scalableGroupType": "APPLICATION",
      "securityGroupTag": 0,
      "state": "ACTIVE",
      "vnAgnostic": true,
      "identitySource": {
        "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
        "instanceId": 10010,
        "authEntityId": 10010,
        "displayName": "10010",
        "authEntityClass": 1738953278,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "state": "INACTIVE",
        "type": "NBAR"
      },
      "indicativeNetworkIdentity": [
        {
          "id": "48d19122-7180-439a-98bc-fe8e246264da",
          "instanceId": 12083,
          "authEntityId": 12083,
          "displayName": "12083",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "149",
          "protocol": "UDP",
          "upperPort": 0
        },
        {
          "id": "36854fbc-d0f5-4f6c-9299-6c3baf73f159",
          "instanceId": 12084,
          "authEntityId": 12084,
          "displayName": "12084",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "149",
          "protocol": "TCP",
          "upperPort": 0
        }
      ],
      "networkApplications": [
        {
          "id": "25ca8c51-aea1-49d4-94d5-35ce53368dd6",
          "instanceId": 11034,
          "authEntityId": 11034,
          "displayName": "11034",
          "authEntityClass": -217092956,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "appProtocol": "tcp/udp",
          "applicationGroup": "other",
          "applicationType": "DEFAULT",
          "categoryId": "6daa3dfd-3526-407a-88f0-97824b3f3a24",
          "createTime": 1517966740728,
          "encrypted": "false",
          "engineId": "3",
          "helpString": "AED 512 Emulation service",
          "ignoreConflict": false,
          "lastUpdateTime": 1517966740728,
          "longDescription": "Registered with IANA on port 149 TCP/UDP",
          "name": "aed-512",
          "nbarId": "963",
          "p2pTechnology": "false",
          "popularity": 1,
          "rank": 65535,
          "references": "http://www.iana.org/assignments/service-names-port-numbers/service-names-port-numbers.xml",
          "selectorId": "149",
          "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
          "trafficClass": "BULK_DATA",
          "tunnel": "false"
        }
      ],
      "networkIdentity": [
        {
          "id": "238c6145-4cad-4f80-bb2b-77c1c175766c",
          "instanceId": 12081,
          "authEntityId": 12081,
          "displayName": "12081",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "149",
          "protocol": "TCP",
          "upperPort": 0
        },
        {
          "id": "785370ae-e298-44ae-8234-1c667551689a",
          "instanceId": 12082,
          "authEntityId": 12082,
          "displayName": "12082",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "149",
          "protocol": "UDP",
          "upperPort": 0
        }
      ],
      "parentScalableGroup": {
        "idRef": "000c15bf-28fe-4fbb-9b69-35e6502721f1"
      },
      "user": [

      ],
      "userGroup": [

      ]
    },
    {
      "id": "f80bfa82-d5f9-4306-a719-8fdc55136ea9",
      "instanceId": 4657,
      "authEntityId": 4657,
      "displayName": "4657",
      "authEntityClass": -1909073334,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "createTime": 1517966743221,
      "deployed": false,
      "isSeeded": true,
      "isStale": false,
      "lastUpdateTime": 1517966743221,
      "name": "itunes-video",
      "namespace": "scalablegroup:application",
      "provisioningState": "DEFINED",
      "qualifier": "application",
      "resourceVersion": 0,
      "targetIdList": [

      ],
      "type": "scalablegroup",
      "cfsChangeInfo": [

      ],
      "customProvisions": [

      ],
      "scalableGroupExternalHandle": "itunes-video",
      "scalableGroupType": "APPLICATION",
      "securityGroupTag": 0,
      "state": "ACTIVE",
      "vnAgnostic": true,
      "identitySource": {
        "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
        "instanceId": 10010,
        "authEntityId": 10010,
        "displayName": "10010",
        "authEntityClass": 1738953278,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "state": "INACTIVE",
        "type": "NBAR"
      },
      "indicativeNetworkIdentity": [

      ],
      "networkApplications": [
        {
          "id": "cc31f38d-5af7-4a0e-a12b-ee0de8779f49",
          "instanceId": 11607,
          "authEntityId": 11607,
          "displayName": "11607",
          "authEntityClass": -217092956,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "appProtocol": "tcp",
          "applicationGroup": "apple-group",
          "applicationType": "DEFAULT",
          "categoryId": "a09ef1b3-ba26-4372-aee8-309d50697e76",
          "createTime": 1517966743221,
          "encrypted": "true",
          "engineId": "13",
          "helpString": "iTunes video streaming",
          "ignoreConflict": false,
          "lastUpdateTime": 1517966743221,
          "longDescription": "iTunes is a media player and media library application developed by Apple Inc. It is used to play, download, and organize digital audio and video on personal computers running the OS X and Microsoft Windows operating systems. Through the iTunes Store, users can purchase and download music, music videos, television shows, audiobooks, podcasts, movies, etc. on their MAC/ Win PC/ iDevices running iTunes. iTunes-video refers to all video streaming media services generated by iTunes such as: play movies, TV shows, videocasts, video, etc.",
          "name": "itunes-video",
          "nbarId": "1511",
          "p2pTechnology": "false",
          "popularity": 10,
          "rank": 65535,
          "references": "http://www.apple.com/itunes/",
          "selectorId": "572",
          "subCategory": "85a797c0-aef8-4284-91a7-677f33e29abb",
          "trafficClass": "MULTIMEDIA_STREAMING",
          "tunnel": "false"
        }
      ],
      "networkIdentity": [
        {
          "id": "b8e630ee-604b-4c01-b14f-170ee55564d1",
          "instanceId": 13653,
          "authEntityId": 13653,
          "displayName": "13653",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "80",
          "protocol": "TCP",
          "upperPort": 0
        }
      ],
      "parentScalableGroup": {
        "idRef": "fd6264c9-c16a-4311-a335-a3db039256f3"
      },
      "user": [

      ],
      "userGroup": [

      ]
    },
    {
      "id": "f86ada74-9a16-462b-91b3-d22e5efe7cb7",
      "instanceId": 15138,
      "authEntityId": 15138,
      "displayName": "15138",
      "authEntityClass": -1909073334,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "createTime": 1517966744321,
      "deployed": false,
      "isSeeded": true,
      "isStale": false,
      "lastUpdateTime": 1517966744321,
      "name": "showmypc",
      "namespace": "scalablegroup:application",
      "provisioningState": "DEFINED",
      "qualifier": "application",
      "resourceVersion": 0,
      "targetIdList": [

      ],
      "type": "scalablegroup",
      "cfsChangeInfo": [

      ],
      "customProvisions": [

      ],
      "scalableGroupExternalHandle": "showmypc",
      "scalableGroupType": "APPLICATION",
      "securityGroupTag": 0,
      "state": "ACTIVE",
      "vnAgnostic": true,
      "identitySource": {
        "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
        "instanceId": 10010,
        "authEntityId": 10010,
        "displayName": "10010",
        "authEntityClass": 1738953278,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "state": "INACTIVE",
        "type": "NBAR"
      },
      "indicativeNetworkIdentity": [

      ],
      "networkApplications": [
        {
          "id": "b41baaf8-3e75-45f4-aacc-7df7db06ca03",
          "instanceId": 16081,
          "authEntityId": 16081,
          "displayName": "16081",
          "authEntityClass": -217092956,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "appProtocol": "tcp/udp",
          "applicationGroup": "other",
          "applicationType": "DEFAULT",
          "categoryId": "a09ef1b3-ba26-4372-aee8-309d50697e76",
          "createTime": 1517966744321,
          "encrypted": "true",
          "engineId": "13",
          "helpString": "Showmypc is a remote control software",
          "ignoreConflict": false,
          "lastUpdateTime": 1517966744321,
          "longDescription": "Showmypc is a PC remote control software that enables users to access their target PCs remotely. It provides remote PC access solutions that can manage anywhere from one to 50 PCs.",
          "name": "showmypc",
          "nbarId": "1468",
          "p2pTechnology": "true",
          "popularity": 7,
          "rank": 65535,
          "references": "http://www.showmypc.com",
          "selectorId": "534",
          "subCategory": "ff664205-64f3-447e-acff-1ec1ba9a6c31",
          "trafficClass": "MULTIMEDIA_STREAMING",
          "tunnel": "false"
        }
      ],
      "networkIdentity": [
        {
          "id": "658c4447-bb86-4dff-9de7-7138b45ab888",
          "instanceId": 17060,
          "authEntityId": 17060,
          "displayName": "17060",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "53,5353",
          "protocol": "UDP",
          "upperPort": 0
        },
        {
          "id": "67289546-30c9-40a3-b50e-ddd69a7cb503",
          "instanceId": 17061,
          "authEntityId": 17061,
          "displayName": "17061",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "53,80,443,5353",
          "protocol": "TCP",
          "upperPort": 0
        }
      ],
      "parentScalableGroup": {
        "idRef": "fd6264c9-c16a-4311-a335-a3db039256f3"
      },
      "user": [

      ],
      "userGroup": [

      ]
    },
    {
      "id": "f895265e-5efb-423a-af4c-c76f81269269",
      "instanceId": 4782,
      "authEntityId": 4782,
      "displayName": "4782",
      "authEntityClass": -1909073334,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "createTime": 1517966743481,
      "deployed": false,
      "isSeeded": true,
      "isStale": false,
      "lastUpdateTime": 1517966743481,
      "name": "ms-office-365",
      "namespace": "scalablegroup:application",
      "provisioningState": "DEFINED",
      "qualifier": "application",
      "resourceVersion": 0,
      "targetIdList": [

      ],
      "type": "scalablegroup",
      "cfsChangeInfo": [

      ],
      "customProvisions": [

      ],
      "scalableGroupExternalHandle": "ms-office-365",
      "scalableGroupType": "APPLICATION",
      "securityGroupTag": 0,
      "state": "ACTIVE",
      "vnAgnostic": true,
      "identitySource": {
        "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
        "instanceId": 10010,
        "authEntityId": 10010,
        "displayName": "10010",
        "authEntityClass": 1738953278,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "state": "INACTIVE",
        "type": "NBAR"
      },
      "indicativeNetworkIdentity": [

      ],
      "networkApplications": [
        {
          "id": "c5aea5a6-6796-49a6-b277-b0b2ee017f00",
          "instanceId": 11731,
          "authEntityId": 11731,
          "displayName": "11731",
          "authEntityClass": -217092956,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "appProtocol": "tcp/udp",
          "applicationGroup": "ms-cloud-group",
          "applicationType": "DEFAULT",
          "categoryId": "7e19fdea-6222-4c57-9e66-66c2340d6bc3",
          "createTime": 1517966743481,
          "encrypted": "true",
          "engineId": "13",
          "helpString": "Microsoft Office 365",
          "ignoreConflict": false,
          "lastUpdateTime": 1517966743481,
          "longDescription": "Microsoft Office 365 is a commercial software plus services that offers the Microsoft Office suite of desktop applications as well as hosted versions of Microsoft's Server products delivered and accessed over the Internet",
          "name": "ms-office-365",
          "nbarId": "1431",
          "p2pTechnology": "false",
          "popularity": 10,
          "rank": 65535,
          "references": "http://www.microsoft.com/en-us/office365/online-software.aspx",
          "selectorId": "495",
          "subCategory": "21d2cdac-b44c-4cab-a279-af3ff88ed550",
          "trafficClass": "TRANSACTIONAL_DATA",
          "tunnel": "false"
        }
      ],
      "networkIdentity": [
        {
          "id": "9510d047-3d5c-461e-8c73-69da4729d1cd",
          "instanceId": 13990,
          "authEntityId": 13990,
          "displayName": "13990",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "53,80,443,5353",
          "protocol": "TCP",
          "upperPort": 0
        },
        {
          "id": "620380cf-5285-442f-8bef-07b2ed781d51",
          "instanceId": 13991,
          "authEntityId": 13991,
          "displayName": "13991",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "53,5353",
          "protocol": "UDP",
          "upperPort": 0
        }
      ],
      "parentScalableGroup": {
        "idRef": "dc13f401-c34c-4d18-a5a1-6e9f8624d67e"
      },
      "user": [

      ],
      "userGroup": [

      ]
    },
    {
      "id": "f8e202d2-dfd5-4f0d-bccd-1f0feef3a594",
      "instanceId": 4465,
      "authEntityId": 4465,
      "displayName": "4465",
      "authEntityClass": -1909073334,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "createTime": 1517966742738,
      "deployed": false,
      "isSeeded": true,
      "isStale": false,
      "lastUpdateTime": 1517966742738,
      "name": "fring-video",
      "namespace": "scalablegroup:application",
      "provisioningState": "DEFINED",
      "qualifier": "application",
      "resourceVersion": 0,
      "targetIdList": [

      ],
      "type": "scalablegroup",
      "cfsChangeInfo": [

      ],
      "customProvisions": [

      ],
      "scalableGroupExternalHandle": "fring-video",
      "scalableGroupType": "APPLICATION",
      "securityGroupTag": 0,
      "state": "ACTIVE",
      "vnAgnostic": true,
      "identitySource": {
        "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
        "instanceId": 10010,
        "authEntityId": 10010,
        "displayName": "10010",
        "authEntityClass": 1738953278,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "state": "INACTIVE",
        "type": "NBAR"
      },
      "indicativeNetworkIdentity": [

      ],
      "networkApplications": [
        {
          "id": "879fb148-68fa-4b3b-b887-fdec56f33c18",
          "instanceId": 11416,
          "authEntityId": 11416,
          "displayName": "11416",
          "authEntityClass": -217092956,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "applicationGroup": "fring-group",
          "applicationType": "DEFAULT",
          "categoryId": "82a7b7fa-da5a-48f5-8b6a-a2546b947843",
          "createTime": 1517966742738,
          "encrypted": "false",
          "engineId": "13",
          "helpString": "DEPRECATED, traffic will not match",
          "ignoreConflict": false,
          "lastUpdateTime": 1517966742738,
          "longDescription": "Deprecated: Fring Video refers to Fring video calls. Fring is a peer-to-peer mobile service enabling live chat, audio and video calls from mobile phones. Fring technology is a Mobile VoIP based internet telephony service. Fring runs on various mobile devices including iPhone, Android and Nokia.",
          "name": "fring-video",
          "nbarId": "1040",
          "p2pTechnology": "true",
          "popularity": 7,
          "rank": 65535,
          "references": "http://www.fring.com/what-is-fring",
          "selectorId": "60",
          "subCategory": "118c10a2-e272-47c1-92b9-4814ebe97138",
          "trafficClass": "MULTIMEDIA_CONFERENCING",
          "tunnel": "false"
        }
      ],
      "networkIdentity": [

      ],
      "parentScalableGroup": {
        "idRef": "fd6264c9-c16a-4311-a335-a3db039256f3"
      },
      "user": [

      ],
      "userGroup": [

      ]
    },
    {
      "id": "f99611a0-60c2-40eb-8f16-0494fb5d7f24",
      "instanceId": 4958,
      "authEntityId": 4958,
      "displayName": "4958",
      "authEntityClass": -1909073334,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "createTime": 1517966743904,
      "deployed": false,
      "isSeeded": true,
      "isStale": false,
      "lastUpdateTime": 1517966743904,
      "name": "picasa",
      "namespace": "scalablegroup:application",
      "provisioningState": "DEFINED",
      "qualifier": "application",
      "resourceVersion": 0,
      "targetIdList": [

      ],
      "type": "scalablegroup",
      "cfsChangeInfo": [

      ],
      "customProvisions": [

      ],
      "scalableGroupExternalHandle": "picasa",
      "scalableGroupType": "APPLICATION",
      "securityGroupTag": 0,
      "state": "ACTIVE",
      "vnAgnostic": true,
      "identitySource": {
        "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
        "instanceId": 10010,
        "authEntityId": 10010,
        "displayName": "10010",
        "authEntityClass": 1738953278,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "state": "INACTIVE",
        "type": "NBAR"
      },
      "indicativeNetworkIdentity": [

      ],
      "networkApplications": [
        {
          "id": "ce90fe86-f0f8-408d-b7a3-7908fcb2ede0",
          "instanceId": 11907,
          "authEntityId": 11907,
          "displayName": "11907",
          "authEntityClass": -217092956,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "appProtocol": "tcp/udp",
          "applicationGroup": "google-group",
          "applicationType": "DEFAULT",
          "categoryId": "9eb6d680-796f-478a-9106-1151f00f7eff",
          "createTime": 1517966743904,
          "encrypted": "true",
          "engineId": "13",
          "helpString": "Picasa, social photo sharing web application",
          "ignoreConflict": false,
          "lastUpdateTime": 1517966743904,
          "longDescription": "Picasa is a photo sharing and editing website by Google.",
          "name": "picasa",
          "nbarId": "1459",
          "p2pTechnology": "true",
          "popularity": 10,
          "rank": 65535,
          "references": "https://picasaweb.google.com/home",
          "selectorId": "523",
          "subCategory": "aab99e59-8589-4a61-933e-f01bff556d78",
          "trafficClass": "BULK_DATA",
          "tunnel": "false"
        }
      ],
      "networkIdentity": [
        {
          "id": "ecaecef3-7632-4992-8fa5-d2cd3101fe57",
          "instanceId": 14534,
          "authEntityId": 14534,
          "displayName": "14534",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "53,5353",
          "protocol": "UDP",
          "upperPort": 0
        },
        {
          "id": "45f3231d-893c-465a-9003-6bd14aefc9aa",
          "instanceId": 14535,
          "authEntityId": 14535,
          "displayName": "14535",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "53,80,443,5353",
          "protocol": "TCP",
          "upperPort": 0
        }
      ],
      "parentScalableGroup": {
        "idRef": "82348eb1-d8b8-4daf-8532-15e1a5f9cdd0"
      },
      "user": [

      ],
      "userGroup": [

      ]
    },
    {
      "id": "f9be7256-16cc-4921-89af-5ba745a3e055",
      "instanceId": 4798,
      "authEntityId": 4798,
      "displayName": "4798",
      "authEntityClass": -1909073334,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "createTime": 1517966743514,
      "deployed": false,
      "isSeeded": true,
      "isStale": false,
      "lastUpdateTime": 1517966743514,
      "name": "msft-gc-ssl",
      "namespace": "scalablegroup:application",
      "provisioningState": "DEFINED",
      "qualifier": "application",
      "resourceVersion": 0,
      "targetIdList": [

      ],
      "type": "scalablegroup",
      "cfsChangeInfo": [

      ],
      "customProvisions": [

      ],
      "scalableGroupExternalHandle": "msft-gc-ssl",
      "scalableGroupType": "APPLICATION",
      "securityGroupTag": 0,
      "state": "ACTIVE",
      "vnAgnostic": true,
      "identitySource": {
        "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
        "instanceId": 10010,
        "authEntityId": 10010,
        "displayName": "10010",
        "authEntityClass": 1738953278,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "state": "INACTIVE",
        "type": "NBAR"
      },
      "indicativeNetworkIdentity": [
        {
          "id": "36591a39-ef69-44f3-982f-9a2b3cf75005",
          "instanceId": 14039,
          "authEntityId": 14039,
          "displayName": "14039",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "3269",
          "protocol": "UDP",
          "upperPort": 0
        },
        {
          "id": "8046ebd8-86bf-4df6-b2a0-aee15a18a974",
          "instanceId": 14040,
          "authEntityId": 14040,
          "displayName": "14040",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "3269",
          "protocol": "TCP",
          "upperPort": 0
        }
      ],
      "networkApplications": [
        {
          "id": "eaec7890-e66e-44f6-b0e9-4a2c6e14a189",
          "instanceId": 11747,
          "authEntityId": 11747,
          "displayName": "11747",
          "authEntityClass": -217092956,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "appProtocol": "tcp/udp",
          "applicationGroup": "sqlsvr-group",
          "applicationType": "DEFAULT",
          "categoryId": "1fc17362-9334-4c7b-b752-5afdbb574017",
          "createTime": 1517966743514,
          "encrypted": "true",
          "engineId": "3",
          "helpString": "Microsoft Global Catalog with LDAP/SSL",
          "ignoreConflict": false,
          "lastUpdateTime": 1517966743514,
          "longDescription": "The Microsoft Global Catalog enables searching for Active Directory objects in any domain in the forest without the need for subordinate referrals, and users can find objects of interest quickly without having to know what domain holds the object.",
          "name": "msft-gc-ssl",
          "nbarId": "688",
          "p2pTechnology": "false",
          "popularity": 7,
          "rank": 65535,
          "references": "http://technet.microsoft.com/en-us/library/how-global-catalog-servers-work(v=ws.10).aspx",
          "selectorId": "3269",
          "subCategory": "f3c0f761-d894-4112-ab89-b90fc84fd660",
          "trafficClass": "OPS_ADMIN_MGMT",
          "tunnel": "false"
        }
      ],
      "networkIdentity": [
        {
          "id": "9d87f5dc-1c06-4609-bd53-6ef769b3eca0",
          "instanceId": 14037,
          "authEntityId": 14037,
          "displayName": "14037",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "3269",
          "protocol": "TCP",
          "upperPort": 0
        },
        {
          "id": "aba6d67b-aabd-4a8a-aef8-1a2f213f4d85",
          "instanceId": 14038,
          "authEntityId": 14038,
          "displayName": "14038",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "3269",
          "protocol": "UDP",
          "upperPort": 0
        }
      ],
      "parentScalableGroup": {
        "idRef": "5fcd74d4-8726-47f7-a088-c457863da7ef"
      },
      "user": [

      ],
      "userGroup": [

      ]
    },
    {
      "id": "f9e7dc36-9a8b-4608-8e61-0a333cf1ec70",
      "instanceId": 4053,
      "authEntityId": 4053,
      "displayName": "4053",
      "authEntityClass": -1909073334,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "createTime": 1517966740622,
      "deployed": false,
      "isSeeded": true,
      "isStale": false,
      "lastUpdateTime": 1517966740622,
      "name": "acp",
      "namespace": "scalablegroup:application",
      "provisioningState": "DEFINED",
      "qualifier": "application",
      "resourceVersion": 0,
      "targetIdList": [

      ],
      "type": "scalablegroup",
      "cfsChangeInfo": [

      ],
      "customProvisions": [

      ],
      "scalableGroupExternalHandle": "acp",
      "scalableGroupType": "APPLICATION",
      "securityGroupTag": 0,
      "state": "ACTIVE",
      "vnAgnostic": true,
      "identitySource": {
        "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
        "instanceId": 10010,
        "authEntityId": 10010,
        "displayName": "10010",
        "authEntityClass": 1738953278,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "state": "INACTIVE",
        "type": "NBAR"
      },
      "indicativeNetworkIdentity": [
        {
          "id": "99719f4f-0b9b-4f3e-836c-7757c8ee8da9",
          "instanceId": 12064,
          "authEntityId": 12064,
          "displayName": "12064",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "599",
          "protocol": "TCP",
          "upperPort": 0
        },
        {
          "id": "cb66b044-0542-41d4-9b0d-7b71d9931daa",
          "instanceId": 12065,
          "authEntityId": 12065,
          "displayName": "12065",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "599",
          "protocol": "UDP",
          "upperPort": 0
        }
      ],
      "networkApplications": [
        {
          "id": "e6344510-986a-453d-9e88-826608bcc341",
          "instanceId": 11026,
          "authEntityId": 11026,
          "displayName": "11026",
          "authEntityClass": -217092956,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "appProtocol": "tcp/udp",
          "applicationGroup": "other",
          "applicationType": "DEFAULT",
          "categoryId": "6daa3dfd-3526-407a-88f0-97824b3f3a24",
          "createTime": 1517966740622,
          "encrypted": "false",
          "engineId": "3",
          "helpString": "Aeolon Core Protocol",
          "ignoreConflict": false,
          "lastUpdateTime": 1517966740622,
          "longDescription": "Registered with IANA on port 599 TCP/UDP",
          "name": "acp",
          "nbarId": "513",
          "p2pTechnology": "false",
          "popularity": 1,
          "rank": 65535,
          "references": "http://www.iana.org/assignments/service-names-port-numbers/service-names-port-numbers.xml",
          "selectorId": "599",
          "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
          "trafficClass": "BULK_DATA",
          "tunnel": "false"
        }
      ],
      "networkIdentity": [
        {
          "id": "10913c70-bf6d-46fa-9301-b867fbe84862",
          "instanceId": 12062,
          "authEntityId": 12062,
          "displayName": "12062",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "599",
          "protocol": "TCP",
          "upperPort": 0
        },
        {
          "id": "a79dc36c-695f-4a4b-a172-096cf212522b",
          "instanceId": 12063,
          "authEntityId": 12063,
          "displayName": "12063",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "599",
          "protocol": "UDP",
          "upperPort": 0
        }
      ],
      "parentScalableGroup": {
        "idRef": "000c15bf-28fe-4fbb-9b69-35e6502721f1"
      },
      "user": [

      ],
      "userGroup": [

      ]
    },
    {
      "id": "f9f2571f-a181-483a-8c5d-715142600278",
      "instanceId": 4403,
      "authEntityId": 4403,
      "displayName": "4403",
      "authEntityClass": -1909073334,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "createTime": 1517966742547,
      "deployed": false,
      "isSeeded": true,
      "isStale": false,
      "lastUpdateTime": 1517966742547,
      "name": "elcsd",
      "namespace": "scalablegroup:application",
      "provisioningState": "DEFINED",
      "qualifier": "application",
      "resourceVersion": 0,
      "targetIdList": [

      ],
      "type": "scalablegroup",
      "cfsChangeInfo": [

      ],
      "customProvisions": [

      ],
      "scalableGroupExternalHandle": "elcsd",
      "scalableGroupType": "APPLICATION",
      "securityGroupTag": 0,
      "state": "ACTIVE",
      "vnAgnostic": true,
      "identitySource": {
        "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
        "instanceId": 10010,
        "authEntityId": 10010,
        "displayName": "10010",
        "authEntityClass": 1738953278,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "state": "INACTIVE",
        "type": "NBAR"
      },
      "indicativeNetworkIdentity": [
        {
          "id": "68852204-8307-4ddf-9b46-a45b23e482b4",
          "instanceId": 13003,
          "authEntityId": 13003,
          "displayName": "13003",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "704",
          "protocol": "UDP",
          "upperPort": 0
        },
        {
          "id": "cf017260-9eed-492c-8d7b-ad2f524de3ca",
          "instanceId": 13004,
          "authEntityId": 13004,
          "displayName": "13004",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "704",
          "protocol": "TCP",
          "upperPort": 0
        }
      ],
      "networkApplications": [
        {
          "id": "23b28197-a1d7-4d2b-9ca0-765e83e9e8a1",
          "instanceId": 11354,
          "authEntityId": 11354,
          "displayName": "11354",
          "authEntityClass": -217092956,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "appProtocol": "tcp/udp",
          "applicationGroup": "other",
          "applicationType": "DEFAULT",
          "categoryId": "1fc17362-9334-4c7b-b752-5afdbb574017",
          "createTime": 1517966742547,
          "encrypted": "unassigned",
          "engineId": "3",
          "helpString": "errlog copy/server daemon",
          "ignoreConflict": false,
          "lastUpdateTime": 1517966742547,
          "longDescription": "Registered with IANA on port 704 TCP/UDP",
          "name": "elcsd",
          "nbarId": "608",
          "p2pTechnology": "unassigned",
          "popularity": 1,
          "rank": 65535,
          "references": "http://www.iana.org/assignments/service-names-port-numbers/service-names-port-numbers.xml",
          "selectorId": "704",
          "subCategory": "f3c0f761-d894-4112-ab89-b90fc84fd660",
          "trafficClass": "OPS_ADMIN_MGMT",
          "tunnel": "unassigned"
        }
      ],
      "networkIdentity": [
        {
          "id": "6f0ae3d8-abbe-4e35-b233-c4c232e61c58",
          "instanceId": 13001,
          "authEntityId": 13001,
          "displayName": "13001",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "704",
          "protocol": "UDP",
          "upperPort": 0
        },
        {
          "id": "57e46275-9ea4-4d52-9a1f-0043768da3f0",
          "instanceId": 13002,
          "authEntityId": 13002,
          "displayName": "13002",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "704",
          "protocol": "TCP",
          "upperPort": 0
        }
      ],
      "parentScalableGroup": {
        "idRef": "5fcd74d4-8726-47f7-a088-c457863da7ef"
      },
      "user": [

      ],
      "userGroup": [

      ]
    },
    {
      "id": "f9f7d59e-5b8b-4b46-bf04-e153ba88dcf3",
      "instanceId": 4841,
      "authEntityId": 4841,
      "displayName": "4841",
      "authEntityClass": -1909073334,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "createTime": 1517966743595,
      "deployed": false,
      "isSeeded": true,
      "isStale": false,
      "lastUpdateTime": 1517966743595,
      "name": "netgw",
      "namespace": "scalablegroup:application",
      "provisioningState": "DEFINED",
      "qualifier": "application",
      "resourceVersion": 0,
      "targetIdList": [

      ],
      "type": "scalablegroup",
      "cfsChangeInfo": [

      ],
      "customProvisions": [

      ],
      "scalableGroupExternalHandle": "netgw",
      "scalableGroupType": "APPLICATION",
      "securityGroupTag": 0,
      "state": "ACTIVE",
      "vnAgnostic": true,
      "identitySource": {
        "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
        "instanceId": 10010,
        "authEntityId": 10010,
        "displayName": "10010",
        "authEntityClass": 1738953278,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "state": "INACTIVE",
        "type": "NBAR"
      },
      "indicativeNetworkIdentity": [
        {
          "id": "c07a27ce-2040-49ac-b51a-68ca5cd57222",
          "instanceId": 14150,
          "authEntityId": 14150,
          "displayName": "14150",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "741",
          "protocol": "UDP",
          "upperPort": 0
        },
        {
          "id": "e5abc065-a87d-4004-95ba-0be44fc71a23",
          "instanceId": 14151,
          "authEntityId": 14151,
          "displayName": "14151",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "741",
          "protocol": "TCP",
          "upperPort": 0
        }
      ],
      "networkApplications": [
        {
          "id": "9eb7e419-68ee-4101-8924-c1f638ff748f",
          "instanceId": 11790,
          "authEntityId": 11790,
          "displayName": "11790",
          "authEntityClass": -217092956,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "appProtocol": "tcp/udp",
          "applicationGroup": "other",
          "applicationType": "DEFAULT",
          "categoryId": "6daa3dfd-3526-407a-88f0-97824b3f3a24",
          "createTime": 1517966743595,
          "encrypted": "false",
          "engineId": "3",
          "helpString": "netgw",
          "ignoreConflict": false,
          "lastUpdateTime": 1517966743595,
          "longDescription": "Registered with IANA on port 741 TCP/UDP",
          "name": "netgw",
          "nbarId": "618",
          "p2pTechnology": "false",
          "popularity": 1,
          "rank": 65535,
          "references": "http://www.iana.org/assignments/service-names-port-numbers/service-names-port-numbers.xml",
          "selectorId": "741",
          "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
          "trafficClass": "BULK_DATA",
          "tunnel": "false"
        }
      ],
      "networkIdentity": [
        {
          "id": "d8c7f74a-780b-461c-afea-86210a683a88",
          "instanceId": 14148,
          "authEntityId": 14148,
          "displayName": "14148",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "741",
          "protocol": "UDP",
          "upperPort": 0
        },
        {
          "id": "fe8d8cbb-b3af-419b-802b-a8c3cc3c49d5",
          "instanceId": 14149,
          "authEntityId": 14149,
          "displayName": "14149",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "741",
          "protocol": "TCP",
          "upperPort": 0
        }
      ],
      "parentScalableGroup": {
        "idRef": "000c15bf-28fe-4fbb-9b69-35e6502721f1"
      },
      "user": [

      ],
      "userGroup": [

      ]
    },
    {
      "id": "f9f88409-c33f-4bbe-b8bc-05a264e9ced0",
      "instanceId": 4290,
      "authEntityId": 4290,
      "displayName": "4290",
      "authEntityClass": -1909073334,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "createTime": 1517966742170,
      "deployed": false,
      "isSeeded": true,
      "isStale": false,
      "lastUpdateTime": 1517966742170,
      "name": "corba-iiop-ssl",
      "namespace": "scalablegroup:application",
      "provisioningState": "DEFINED",
      "qualifier": "application",
      "resourceVersion": 0,
      "targetIdList": [

      ],
      "type": "scalablegroup",
      "cfsChangeInfo": [

      ],
      "customProvisions": [

      ],
      "scalableGroupExternalHandle": "corba-iiop-ssl",
      "scalableGroupType": "APPLICATION",
      "securityGroupTag": 0,
      "state": "ACTIVE",
      "vnAgnostic": true,
      "identitySource": {
        "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
        "instanceId": 10010,
        "authEntityId": 10010,
        "displayName": "10010",
        "authEntityClass": 1738953278,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "state": "INACTIVE",
        "type": "NBAR"
      },
      "indicativeNetworkIdentity": [
        {
          "id": "f6268d9f-26b9-44b0-be1d-49e2e71036d1",
          "instanceId": 12643,
          "authEntityId": 12643,
          "displayName": "12643",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "684",
          "protocol": "TCP",
          "upperPort": 0
        },
        {
          "id": "3cffc947-f68c-4756-96fc-5fdbc0f15ef0",
          "instanceId": 12644,
          "authEntityId": 12644,
          "displayName": "12644",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "684",
          "protocol": "UDP",
          "upperPort": 0
        }
      ],
      "networkApplications": [
        {
          "id": "217c3377-629a-4dfb-99a1-31af63e88dc6",
          "instanceId": 11243,
          "authEntityId": 11243,
          "displayName": "11243",
          "authEntityClass": -217092956,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "appProtocol": "tcp/udp",
          "applicationGroup": "corba-group",
          "applicationType": "DEFAULT",
          "categoryId": "58aa61dc-dd95-4a6d-9c7c-0ebf16da6dde",
          "createTime": 1517966742170,
          "encrypted": "true",
          "engineId": "3",
          "helpString": "secure CORBA Internet Inter-ORB Protocol",
          "ignoreConflict": false,
          "lastUpdateTime": 1517966742170,
          "longDescription": "The Common Object Request Broker Architecture (CORBA) and the Internet Inter-ORB Protocol (IIOP) are two technologies that enable distributed computing across heterogeneous systems. CORBA defines the total architecture required for communication between distributed objects and IIOP is the most important specification of CORBA. secure cobra-iiop is working over SSL protocol , register in IANA on port 684.",
          "name": "corba-iiop-ssl",
          "nbarId": "592",
          "p2pTechnology": "false",
          "popularity": 6,
          "rank": 65535,
          "references": "http://www.opengroup.org/infosrv/Brand/SPS_pdf/X01OB.pdf",
          "selectorId": "684",
          "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
          "trafficClass": "BULK_DATA",
          "tunnel": "false"
        }
      ],
      "networkIdentity": [
        {
          "id": "99cd9670-fba0-439d-a0f0-36aa68066b3f",
          "instanceId": 12641,
          "authEntityId": 12641,
          "displayName": "12641",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "684",
          "protocol": "UDP",
          "upperPort": 0
        },
        {
          "id": "70e9844b-8b49-43f0-b550-87a64f7a9f87",
          "instanceId": 12642,
          "authEntityId": 12642,
          "displayName": "12642",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "684",
          "protocol": "TCP",
          "upperPort": 0
        }
      ],
      "parentScalableGroup": {
        "idRef": "cff11752-fafb-4e22-9e6f-11f44f59a9a3"
      },
      "user": [

      ],
      "userGroup": [

      ]
    },
    {
      "id": "fa1dffd3-e756-4e74-9769-a3c0b8c3a87b",
      "instanceId": 4301,
      "authEntityId": 4301,
      "displayName": "4301",
      "authEntityClass": -1909073334,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "createTime": 1517966742205,
      "deployed": false,
      "isSeeded": true,
      "isStale": false,
      "lastUpdateTime": 1517966742205,
      "name": "creativepartnr",
      "namespace": "scalablegroup:application",
      "provisioningState": "DEFINED",
      "qualifier": "application",
      "resourceVersion": 0,
      "targetIdList": [

      ],
      "type": "scalablegroup",
      "cfsChangeInfo": [

      ],
      "customProvisions": [

      ],
      "scalableGroupExternalHandle": "creativepartnr",
      "scalableGroupType": "APPLICATION",
      "securityGroupTag": 0,
      "state": "ACTIVE",
      "vnAgnostic": true,
      "identitySource": {
        "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
        "instanceId": 10010,
        "authEntityId": 10010,
        "displayName": "10010",
        "authEntityClass": 1738953278,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "state": "INACTIVE",
        "type": "NBAR"
      },
      "indicativeNetworkIdentity": [
        {
          "id": "de880055-2434-4f56-ad40-6224661488d5",
          "instanceId": 12674,
          "authEntityId": 12674,
          "displayName": "12674",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "455",
          "protocol": "TCP",
          "upperPort": 0
        },
        {
          "id": "f62b750e-6443-4545-a51c-fda3411d52ac",
          "instanceId": 12675,
          "authEntityId": 12675,
          "displayName": "12675",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "455",
          "protocol": "UDP",
          "upperPort": 0
        }
      ],
      "networkApplications": [
        {
          "id": "9452f6d4-cc1e-4bb5-a65c-bd41e7840eda",
          "instanceId": 11254,
          "authEntityId": 11254,
          "displayName": "11254",
          "authEntityClass": -217092956,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "appProtocol": "tcp/udp",
          "applicationGroup": "other",
          "applicationType": "DEFAULT",
          "categoryId": "6daa3dfd-3526-407a-88f0-97824b3f3a24",
          "createTime": 1517966742205,
          "encrypted": "false",
          "engineId": "3",
          "helpString": "creativepartnr",
          "ignoreConflict": false,
          "lastUpdateTime": 1517966742205,
          "longDescription": "Registered with IANA on port 455 TCP/UDP",
          "name": "creativepartnr",
          "nbarId": "370",
          "p2pTechnology": "false",
          "popularity": 1,
          "rank": 65535,
          "references": "http://www.iana.org/assignments/service-names-port-numbers/service-names-port-numbers.xml",
          "selectorId": "455",
          "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
          "trafficClass": "BULK_DATA",
          "tunnel": "false"
        }
      ],
      "networkIdentity": [
        {
          "id": "17f52b63-55f8-46ca-81f8-5f5e5709acb4",
          "instanceId": 12672,
          "authEntityId": 12672,
          "displayName": "12672",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "455",
          "protocol": "TCP",
          "upperPort": 0
        },
        {
          "id": "ed0c60ad-0e30-40de-8346-14406ea2356d",
          "instanceId": 12673,
          "authEntityId": 12673,
          "displayName": "12673",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "455",
          "protocol": "UDP",
          "upperPort": 0
        }
      ],
      "parentScalableGroup": {
        "idRef": "000c15bf-28fe-4fbb-9b69-35e6502721f1"
      },
      "user": [

      ],
      "userGroup": [

      ]
    },
    {
      "id": "fad20451-2a47-4f86-850c-d8aee5eb5147",
      "instanceId": 15261,
      "authEntityId": 15261,
      "displayName": "15261",
      "authEntityClass": -1909073334,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "createTime": 1517966744602,
      "deployed": false,
      "isSeeded": true,
      "isStale": false,
      "lastUpdateTime": 1517966744602,
      "name": "teamviewer",
      "namespace": "scalablegroup:application",
      "provisioningState": "DEFINED",
      "qualifier": "application",
      "resourceVersion": 0,
      "targetIdList": [

      ],
      "type": "scalablegroup",
      "cfsChangeInfo": [

      ],
      "customProvisions": [

      ],
      "scalableGroupExternalHandle": "teamviewer",
      "scalableGroupType": "APPLICATION",
      "securityGroupTag": 0,
      "state": "ACTIVE",
      "vnAgnostic": true,
      "identitySource": {
        "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
        "instanceId": 10010,
        "authEntityId": 10010,
        "displayName": "10010",
        "authEntityClass": 1738953278,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "state": "INACTIVE",
        "type": "NBAR"
      },
      "indicativeNetworkIdentity": [

      ],
      "networkApplications": [
        {
          "id": "c26d8dfc-ac73-4fb0-9189-63fbdba7a093",
          "instanceId": 16204,
          "authEntityId": 16204,
          "displayName": "16204",
          "authEntityClass": -217092956,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "appProtocol": "tcp",
          "applicationGroup": "other",
          "applicationType": "DEFAULT",
          "categoryId": "7e19fdea-6222-4c57-9e66-66c2340d6bc3",
          "createTime": 1517966744602,
          "encrypted": "true",
          "engineId": "13",
          "helpString": "Free remote access and desktop sharing",
          "ignoreConflict": false,
          "lastUpdateTime": 1517966744602,
          "longDescription": "TeamViewer is a computer software package for remote control, desktop sharing, and file transfer between computers. The software operates with the Microsoft Windows, Mac OS X Linux, iOS and Android operating systems.",
          "name": "teamviewer",
          "nbarId": "1430",
          "p2pTechnology": "false",
          "popularity": 6,
          "rank": 65535,
          "references": "http://www.teamviewer.com/en/index.aspx",
          "selectorId": "494",
          "subCategory": "ff664205-64f3-447e-acff-1ec1ba9a6c31",
          "trafficClass": "MULTIMEDIA_STREAMING",
          "tunnel": "false"
        }
      ],
      "networkIdentity": [
        {
          "id": "64c1c59c-5819-47a2-8e44-ce6d34ae7048",
          "instanceId": 17420,
          "authEntityId": 17420,
          "displayName": "17420",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "80",
          "protocol": "TCP",
          "upperPort": 0
        }
      ],
      "parentScalableGroup": {
        "idRef": "4a6b37c6-7e11-40fe-a6b1-719b24813145"
      },
      "user": [

      ],
      "userGroup": [

      ]
    },
    {
      "id": "faee76b2-b6db-438b-a5a3-450fa52327e2",
      "instanceId": 15081,
      "authEntityId": 15081,
      "displayName": "15081",
      "authEntityClass": -1909073334,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "createTime": 1517966744190,
      "deployed": false,
      "isSeeded": true,
      "isStale": false,
      "lastUpdateTime": 1517966744190,
      "name": "rtsps",
      "namespace": "scalablegroup:application",
      "provisioningState": "DEFINED",
      "qualifier": "application",
      "resourceVersion": 0,
      "targetIdList": [

      ],
      "type": "scalablegroup",
      "cfsChangeInfo": [

      ],
      "customProvisions": [

      ],
      "scalableGroupExternalHandle": "rtsps",
      "scalableGroupType": "APPLICATION",
      "securityGroupTag": 0,
      "state": "ACTIVE",
      "vnAgnostic": true,
      "identitySource": {
        "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
        "instanceId": 10010,
        "authEntityId": 10010,
        "displayName": "10010",
        "authEntityClass": 1738953278,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "state": "INACTIVE",
        "type": "NBAR"
      },
      "indicativeNetworkIdentity": [
        {
          "id": "ec9325ce-e6c6-4313-9374-8bce2665686d",
          "instanceId": 14878,
          "authEntityId": 14878,
          "displayName": "14878",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "322",
          "protocol": "TCP",
          "upperPort": 0
        },
        {
          "id": "9539bb56-a8c4-445a-97fa-fe62d998d9be",
          "instanceId": 14879,
          "authEntityId": 14879,
          "displayName": "14879",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "322",
          "protocol": "UDP",
          "upperPort": 0
        }
      ],
      "networkApplications": [
        {
          "id": "ef4db1e0-0bb6-47f8-9284-2b42dc5d1d6c",
          "instanceId": 16024,
          "authEntityId": 16024,
          "displayName": "16024",
          "authEntityClass": -217092956,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "appProtocol": "tcp/udp",
          "applicationGroup": "other",
          "applicationType": "DEFAULT",
          "categoryId": "38a8e354-e2fe-4f3c-b1c5-2bbb90fb0d99",
          "createTime": 1517966744190,
          "encrypted": "true",
          "engineId": "3",
          "helpString": "RTSPS",
          "ignoreConflict": false,
          "lastUpdateTime": 1517966744190,
          "longDescription": "Secure Real Time Streaming Protocol (RTSPS) is RTSP over TLS/SSL.  It first establishes an encrypted connection and then works the same as RTSP. RTSP is a network control protocol designed for use in entertainment and communications systems to control streaming media servers. The protocol is used for establishing and controlling media sessions between end points.",
          "name": "rtsps",
          "nbarId": "881",
          "p2pTechnology": "false",
          "popularity": 1,
          "rank": 65535,
          "references": "http://www.ietf.org/rfc/rfc2326.txt",
          "selectorId": "322",
          "subCategory": "68c4f989-5735-4e16-8d1c-6a9d89d5df9c",
          "trafficClass": "SIGNALING",
          "tunnel": "false"
        }
      ],
      "networkIdentity": [
        {
          "id": "ba8fcf62-65f7-42d0-b082-95fb1ef0a275",
          "instanceId": 14876,
          "authEntityId": 14876,
          "displayName": "14876",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "322",
          "protocol": "UDP",
          "upperPort": 0
        },
        {
          "id": "04c33c19-bacd-491d-8936-dbaa6997a879",
          "instanceId": 14877,
          "authEntityId": 14877,
          "displayName": "14877",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "322",
          "protocol": "TCP",
          "upperPort": 0
        }
      ],
      "parentScalableGroup": {
        "idRef": "a3243a74-3864-4041-a927-0201030da17c"
      },
      "user": [

      ],
      "userGroup": [

      ]
    },
    {
      "id": "fb6ac8f8-2391-4744-a70c-92141f3ffaba",
      "instanceId": 15323,
      "authEntityId": 15323,
      "displayName": "15323",
      "authEntityClass": -1909073334,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "createTime": 1517966744738,
      "deployed": false,
      "isSeeded": true,
      "isStale": false,
      "lastUpdateTime": 1517966744738,
      "name": "unify",
      "namespace": "scalablegroup:application",
      "provisioningState": "DEFINED",
      "qualifier": "application",
      "resourceVersion": 0,
      "targetIdList": [

      ],
      "type": "scalablegroup",
      "cfsChangeInfo": [

      ],
      "customProvisions": [

      ],
      "scalableGroupExternalHandle": "unify",
      "scalableGroupType": "APPLICATION",
      "securityGroupTag": 0,
      "state": "ACTIVE",
      "vnAgnostic": true,
      "identitySource": {
        "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
        "instanceId": 10010,
        "authEntityId": 10010,
        "displayName": "10010",
        "authEntityClass": 1738953278,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "state": "INACTIVE",
        "type": "NBAR"
      },
      "indicativeNetworkIdentity": [
        {
          "id": "416e77de-6ace-4118-b6b8-a225f88053cf",
          "instanceId": 17591,
          "authEntityId": 17591,
          "displayName": "17591",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "181",
          "protocol": "UDP",
          "upperPort": 0
        },
        {
          "id": "9bca11f4-9ddb-400a-9758-5129c6b81f05",
          "instanceId": 17592,
          "authEntityId": 17592,
          "displayName": "17592",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "181",
          "protocol": "TCP",
          "upperPort": 0
        }
      ],
      "networkApplications": [
        {
          "id": "3ebf54eb-379b-4e7a-a9d2-1f5a8563ed14",
          "instanceId": 16266,
          "authEntityId": 16266,
          "displayName": "16266",
          "authEntityClass": -217092956,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "appProtocol": "tcp/udp",
          "applicationGroup": "other",
          "applicationType": "DEFAULT",
          "categoryId": "6daa3dfd-3526-407a-88f0-97824b3f3a24",
          "createTime": 1517966744738,
          "encrypted": "false",
          "engineId": "3",
          "helpString": "Unify",
          "ignoreConflict": false,
          "lastUpdateTime": 1517966744738,
          "longDescription": "Registered with IANA on port 181 TCP/UDP",
          "name": "unify",
          "nbarId": "1024",
          "p2pTechnology": "false",
          "popularity": 1,
          "rank": 65535,
          "references": "http://www.iana.org/assignments/service-names-port-numbers/service-names-port-numbers.xml",
          "selectorId": "181",
          "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
          "trafficClass": "BULK_DATA",
          "tunnel": "false"
        }
      ],
      "networkIdentity": [
        {
          "id": "e249d296-0b0f-49cc-ad46-f603710932a5",
          "instanceId": 17589,
          "authEntityId": 17589,
          "displayName": "17589",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "181",
          "protocol": "UDP",
          "upperPort": 0
        },
        {
          "id": "cc311358-34ab-4ca9-99df-78dd0f673d71",
          "instanceId": 17590,
          "authEntityId": 17590,
          "displayName": "17590",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "181",
          "protocol": "TCP",
          "upperPort": 0
        }
      ],
      "parentScalableGroup": {
        "idRef": "000c15bf-28fe-4fbb-9b69-35e6502721f1"
      },
      "user": [

      ],
      "userGroup": [

      ]
    },
    {
      "id": "fb909b8a-f151-4375-be22-87387854ab97",
      "instanceId": 4685,
      "authEntityId": 4685,
      "displayName": "4685",
      "authEntityClass": -1909073334,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "createTime": 1517966743280,
      "deployed": false,
      "isSeeded": true,
      "isStale": false,
      "lastUpdateTime": 1517966743280,
      "name": "la-maint",
      "namespace": "scalablegroup:application",
      "provisioningState": "DEFINED",
      "qualifier": "application",
      "resourceVersion": 0,
      "targetIdList": [

      ],
      "type": "scalablegroup",
      "cfsChangeInfo": [

      ],
      "customProvisions": [

      ],
      "scalableGroupExternalHandle": "la-maint",
      "scalableGroupType": "APPLICATION",
      "securityGroupTag": 0,
      "state": "ACTIVE",
      "vnAgnostic": true,
      "identitySource": {
        "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
        "instanceId": 10010,
        "authEntityId": 10010,
        "displayName": "10010",
        "authEntityClass": 1738953278,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "state": "INACTIVE",
        "type": "NBAR"
      },
      "indicativeNetworkIdentity": [
        {
          "id": "10de0014-f018-4ad0-bf36-c9fc5afb2270",
          "instanceId": 13728,
          "authEntityId": 13728,
          "displayName": "13728",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "51",
          "protocol": "UDP",
          "upperPort": 0
        },
        {
          "id": "c0b47090-ddc3-416a-b518-1406ce9d2335",
          "instanceId": 13729,
          "authEntityId": 13729,
          "displayName": "13729",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "51",
          "protocol": "TCP",
          "upperPort": 0
        }
      ],
      "networkApplications": [
        {
          "id": "3439c779-f645-4ae3-b0b3-be252964f48b",
          "instanceId": 11635,
          "authEntityId": 11635,
          "displayName": "11635",
          "authEntityClass": -217092956,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "appProtocol": "tcp/udp",
          "applicationGroup": "other",
          "applicationType": "DEFAULT",
          "categoryId": "6daa3dfd-3526-407a-88f0-97824b3f3a24",
          "createTime": 1517966743280,
          "encrypted": "false",
          "engineId": "3",
          "helpString": "IMP Logical Address Maintenance",
          "ignoreConflict": false,
          "lastUpdateTime": 1517966743280,
          "longDescription": "Registered with IANA on port 51 TCP/UDP",
          "name": "la-maint",
          "nbarId": "931",
          "p2pTechnology": "false",
          "popularity": 1,
          "rank": 65535,
          "references": "http://www.iana.org/assignments/service-names-port-numbers/service-names-port-numbers.xml",
          "selectorId": "51",
          "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
          "trafficClass": "BULK_DATA",
          "tunnel": "false"
        }
      ],
      "networkIdentity": [
        {
          "id": "a49ec4f0-4ccb-4c70-8198-5521b53de793",
          "instanceId": 13726,
          "authEntityId": 13726,
          "displayName": "13726",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "51",
          "protocol": "UDP",
          "upperPort": 0
        },
        {
          "id": "26b0dca9-6b86-42a5-b6ce-11515b34bf8c",
          "instanceId": 13727,
          "authEntityId": 13727,
          "displayName": "13727",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "51",
          "protocol": "TCP",
          "upperPort": 0
        }
      ],
      "parentScalableGroup": {
        "idRef": "000c15bf-28fe-4fbb-9b69-35e6502721f1"
      },
      "user": [

      ],
      "userGroup": [

      ]
    },
    {
      "id": "fbfe700e-67ff-47cb-b153-da0c6e0f9fbd",
      "instanceId": 4706,
      "authEntityId": 4706,
      "displayName": "4706",
      "authEntityClass": -1909073334,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "createTime": 1517966743324,
      "deployed": false,
      "isSeeded": true,
      "isStale": false,
      "lastUpdateTime": 1517966743324,
      "name": "loglogic",
      "namespace": "scalablegroup:application",
      "provisioningState": "DEFINED",
      "qualifier": "application",
      "resourceVersion": 0,
      "targetIdList": [

      ],
      "type": "scalablegroup",
      "cfsChangeInfo": [

      ],
      "customProvisions": [

      ],
      "scalableGroupExternalHandle": "loglogic",
      "scalableGroupType": "APPLICATION",
      "securityGroupTag": 0,
      "state": "ACTIVE",
      "vnAgnostic": true,
      "identitySource": {
        "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
        "instanceId": 10010,
        "authEntityId": 10010,
        "displayName": "10010",
        "authEntityClass": 1738953278,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "state": "INACTIVE",
        "type": "NBAR"
      },
      "indicativeNetworkIdentity": [
        {
          "id": "96e9ec1f-864e-4c94-b4c6-417ee10678a7",
          "instanceId": 13787,
          "authEntityId": 13787,
          "displayName": "13787",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "4514,11965",
          "protocol": "TCP",
          "upperPort": 0
        }
      ],
      "networkApplications": [
        {
          "id": "16fbf93f-7343-4596-9ef1-20cfb7a70450",
          "instanceId": 11656,
          "authEntityId": 11656,
          "displayName": "11656",
          "authEntityClass": -217092956,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "appProtocol": "tcp",
          "applicationGroup": "other",
          "applicationType": "DEFAULT",
          "categoryId": "6daa3dfd-3526-407a-88f0-97824b3f3a24",
          "createTime": 1517966743324,
          "encrypted": "false",
          "engineId": "3",
          "helpString": "Log and security management",
          "ignoreConflict": false,
          "lastUpdateTime": 1517966743324,
          "longDescription": "LogLogic provides enterprise-class log management infrastructure and analysis that enables customers to instantly collect, centralize and analyze their data. The software collects data from both physical and virutal (cloud) sources. It stores, alerts, and reports data to help defend against security risks and threats and to monitor performance. The software typically uses TCP port 4514 for RealTime Viewer and TCP port 11965 for Loglogic Tunnel.",
          "name": "loglogic",
          "nbarId": "1351",
          "p2pTechnology": "false",
          "popularity": 1,
          "rank": 65535,
          "references": "http://www.loglogic.com/about",
          "selectorId": "4514",
          "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
          "trafficClass": "BULK_DATA",
          "tunnel": "false"
        }
      ],
      "networkIdentity": [
        {
          "id": "b34031ca-0311-4dcc-ad46-c457f6fb7fa8",
          "instanceId": 13786,
          "authEntityId": 13786,
          "displayName": "13786",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "4514,11965",
          "protocol": "TCP",
          "upperPort": 0
        }
      ],
      "parentScalableGroup": {
        "idRef": "000c15bf-28fe-4fbb-9b69-35e6502721f1"
      },
      "user": [

      ],
      "userGroup": [

      ]
    },
    {
      "id": "fc064fc5-9ae7-424c-844b-edef547b3f56",
      "instanceId": 4876,
      "authEntityId": 4876,
      "displayName": "4876",
      "authEntityClass": -1909073334,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "createTime": 1517966743662,
      "deployed": false,
      "isSeeded": true,
      "isStale": false,
      "lastUpdateTime": 1517966743662,
      "name": "novastorbakcup",
      "namespace": "scalablegroup:application",
      "provisioningState": "DEFINED",
      "qualifier": "application",
      "resourceVersion": 0,
      "targetIdList": [

      ],
      "type": "scalablegroup",
      "cfsChangeInfo": [

      ],
      "customProvisions": [

      ],
      "scalableGroupExternalHandle": "novastorbakcup",
      "scalableGroupType": "APPLICATION",
      "securityGroupTag": 0,
      "state": "ACTIVE",
      "vnAgnostic": true,
      "identitySource": {
        "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
        "instanceId": 10010,
        "authEntityId": 10010,
        "displayName": "10010",
        "authEntityClass": 1738953278,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "state": "INACTIVE",
        "type": "NBAR"
      },
      "indicativeNetworkIdentity": [
        {
          "id": "94fd5dab-90c9-4c4e-acd1-1ef7b5347450",
          "instanceId": 14270,
          "authEntityId": 14270,
          "displayName": "14270",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "308",
          "protocol": "UDP",
          "upperPort": 0
        },
        {
          "id": "54925c4e-25fe-44e1-94d9-20fc34da9daa",
          "instanceId": 14271,
          "authEntityId": 14271,
          "displayName": "14271",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "308",
          "protocol": "TCP",
          "upperPort": 0
        }
      ],
      "networkApplications": [
        {
          "id": "2f8757ab-2263-4bec-9bf8-7763b252ca1a",
          "instanceId": 11825,
          "authEntityId": 11825,
          "displayName": "11825",
          "authEntityClass": -217092956,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "appProtocol": "tcp/udp",
          "applicationGroup": "other",
          "applicationType": "DEFAULT",
          "categoryId": "80909d4c-efe6-4878-9e1e-68281fd26edd",
          "createTime": 1517966743662,
          "encrypted": "false",
          "engineId": "3",
          "helpString": "Novastor Backup",
          "ignoreConflict": false,
          "lastUpdateTime": 1517966743662,
          "longDescription": "Used by NovaStor in their backup products.",
          "name": "novastorbakcup",
          "nbarId": "1148",
          "p2pTechnology": "false",
          "popularity": 1,
          "rank": 65535,
          "references": "http://www.novastor.com",
          "selectorId": "308",
          "subCategory": "4a197877-986d-4422-b48f-500fb4b3340f",
          "trafficClass": "BULK_DATA",
          "tunnel": "false"
        }
      ],
      "networkIdentity": [
        {
          "id": "5be30187-b35f-4ce4-aea3-b3cf7ea98dff",
          "instanceId": 14268,
          "authEntityId": 14268,
          "displayName": "14268",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "308",
          "protocol": "UDP",
          "upperPort": 0
        },
        {
          "id": "77fa3bbc-a1f4-46e1-a645-4364e8b8b9b5",
          "instanceId": 14269,
          "authEntityId": 14269,
          "displayName": "14269",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "308",
          "protocol": "TCP",
          "upperPort": 0
        }
      ],
      "parentScalableGroup": {
        "idRef": "f09a36de-df38-4ff8-b0be-56738afc75f7"
      },
      "user": [

      ],
      "userGroup": [

      ]
    },
    {
      "id": "fc26298c-b3a5-479b-8d76-7870271d359f",
      "instanceId": 4740,
      "authEntityId": 4740,
      "displayName": "4740",
      "authEntityClass": -1909073334,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "createTime": 1517966743399,
      "deployed": false,
      "isSeeded": true,
      "isStale": false,
      "lastUpdateTime": 1517966743399,
      "name": "mfcobol",
      "namespace": "scalablegroup:application",
      "provisioningState": "DEFINED",
      "qualifier": "application",
      "resourceVersion": 0,
      "targetIdList": [

      ],
      "type": "scalablegroup",
      "cfsChangeInfo": [

      ],
      "customProvisions": [

      ],
      "scalableGroupExternalHandle": "mfcobol",
      "scalableGroupType": "APPLICATION",
      "securityGroupTag": 0,
      "state": "ACTIVE",
      "vnAgnostic": true,
      "identitySource": {
        "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
        "instanceId": 10010,
        "authEntityId": 10010,
        "displayName": "10010",
        "authEntityClass": 1738953278,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "state": "INACTIVE",
        "type": "NBAR"
      },
      "indicativeNetworkIdentity": [
        {
          "id": "c4597d18-104e-4b7f-a6e1-125b75f80624",
          "instanceId": 13882,
          "authEntityId": 13882,
          "displayName": "13882",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "86",
          "protocol": "TCP",
          "upperPort": 0
        },
        {
          "id": "552ce2c1-7dbb-493e-96f1-4941a1a0f038",
          "instanceId": 13883,
          "authEntityId": 13883,
          "displayName": "13883",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "86",
          "protocol": "UDP",
          "upperPort": 0
        }
      ],
      "networkApplications": [
        {
          "id": "290a20b0-3fbb-4052-b78a-f7ccb92f5ee7",
          "instanceId": 11690,
          "authEntityId": 11690,
          "displayName": "11690",
          "authEntityClass": -217092956,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "appProtocol": "tcp/udp",
          "applicationGroup": "other",
          "applicationType": "DEFAULT",
          "categoryId": "58aa61dc-dd95-4a6d-9c7c-0ebf16da6dde",
          "createTime": 1517966743399,
          "encrypted": "false",
          "engineId": "3",
          "helpString": "Micro Focus Cobol",
          "ignoreConflict": false,
          "lastUpdateTime": 1517966743399,
          "longDescription": "Used by Micro Focus applications such as Enterprise Server, COBOL Server Express and more.",
          "name": "mfcobol",
          "nbarId": "958",
          "p2pTechnology": "false",
          "popularity": 1,
          "rank": 65535,
          "references": "http://www.microfocus.com/",
          "selectorId": "86",
          "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
          "trafficClass": "TRANSACTIONAL_DATA",
          "tunnel": "false"
        }
      ],
      "networkIdentity": [
        {
          "id": "fa88c33e-6b55-4e09-8222-74a151c7163c",
          "instanceId": 13880,
          "authEntityId": 13880,
          "displayName": "13880",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "86",
          "protocol": "UDP",
          "upperPort": 0
        },
        {
          "id": "1489fa89-a67e-4671-b3b8-de0381fc7e07",
          "instanceId": 13881,
          "authEntityId": 13881,
          "displayName": "13881",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "86",
          "protocol": "TCP",
          "upperPort": 0
        }
      ],
      "parentScalableGroup": {
        "idRef": "cff11752-fafb-4e22-9e6f-11f44f59a9a3"
      },
      "user": [

      ],
      "userGroup": [

      ]
    },
    {
      "id": "fc6f9e38-4e79-473a-bd05-34ca71edd642",
      "instanceId": 15267,
      "authEntityId": 15267,
      "displayName": "15267",
      "authEntityClass": -1909073334,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "createTime": 1517966744619,
      "deployed": false,
      "isSeeded": true,
      "isStale": false,
      "lastUpdateTime": 1517966744619,
      "name": "telepresence-media",
      "namespace": "scalablegroup:application",
      "provisioningState": "DEFINED",
      "qualifier": "application",
      "resourceVersion": 0,
      "targetIdList": [

      ],
      "type": "scalablegroup",
      "cfsChangeInfo": [

      ],
      "customProvisions": [

      ],
      "scalableGroupExternalHandle": "telepresence-media",
      "scalableGroupType": "APPLICATION",
      "securityGroupTag": 0,
      "state": "ACTIVE",
      "vnAgnostic": true,
      "identitySource": {
        "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
        "instanceId": 10010,
        "authEntityId": 10010,
        "displayName": "10010",
        "authEntityClass": 1738953278,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "state": "INACTIVE",
        "type": "NBAR"
      },
      "indicativeNetworkIdentity": [

      ],
      "networkApplications": [
        {
          "id": "49a7b3d8-23d4-4b20-af07-6e65b5c828c7",
          "instanceId": 16210,
          "authEntityId": 16210,
          "displayName": "16210",
          "authEntityClass": -217092956,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "appProtocol": "tcp/udp",
          "applicationGroup": "telepresence-group",
          "applicationType": "DEFAULT",
          "categoryId": "38a8e354-e2fe-4f3c-b1c5-2bbb90fb0d99",
          "createTime": 1517966744619,
          "encrypted": "false",
          "engineId": "13",
          "helpString": "Cisco Telepresence-media",
          "ignoreConflict": false,
          "lastUpdateTime": 1517966744619,
          "longDescription": "Cisco TelePresence media protocol is used for the audio and HD video interactive conferencing of the telepresence device. The communication is done over the internet. The protocol consists only data packets, and works simultaneously with cisco telepresence control protocol.",
          "name": "telepresence-media",
          "nbarId": "113",
          "p2pTechnology": "false",
          "popularity": 10,
          "rank": 65535,
          "references": "http://www.cisco.com/en/US/solutions/ns669/public_telepresence.html",
          "selectorId": "113",
          "subCategory": "d29e5109-f6b1-436b-b23d-69672b608d13",
          "trafficClass": "REAL_TIME_INTERACTIVE",
          "tunnel": "false"
        }
      ],
      "networkIdentity": [
        {
          "id": "c1044bce-239b-4e46-a545-6c7995fafffc",
          "instanceId": 17431,
          "authEntityId": 17431,
          "displayName": "17431",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "53,80,443,5060,5353",
          "protocol": "TCP",
          "upperPort": 0
        },
        {
          "id": "7e67231c-9202-4cf4-92d6-798d1f5e62f4",
          "instanceId": 17432,
          "authEntityId": 17432,
          "displayName": "17432",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "53,5060,5353",
          "protocol": "UDP",
          "upperPort": 0
        }
      ],
      "parentScalableGroup": {
        "idRef": "60f1364d-2a02-4063-8ba1-07817abe0612"
      },
      "user": [

      ],
      "userGroup": [

      ]
    },
    {
      "id": "fc913fbf-2868-4577-ab95-85ebddee272b",
      "instanceId": 15097,
      "authEntityId": 15097,
      "displayName": "15097",
      "authEntityClass": -1909073334,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "createTime": 1517966744227,
      "deployed": false,
      "isSeeded": true,
      "isStale": false,
      "lastUpdateTime": 1517966744227,
      "name": "sco-inetmgr",
      "namespace": "scalablegroup:application",
      "provisioningState": "DEFINED",
      "qualifier": "application",
      "resourceVersion": 0,
      "targetIdList": [

      ],
      "type": "scalablegroup",
      "cfsChangeInfo": [

      ],
      "customProvisions": [

      ],
      "scalableGroupExternalHandle": "sco-inetmgr",
      "scalableGroupType": "APPLICATION",
      "securityGroupTag": 0,
      "state": "ACTIVE",
      "vnAgnostic": true,
      "identitySource": {
        "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
        "instanceId": 10010,
        "authEntityId": 10010,
        "displayName": "10010",
        "authEntityClass": 1738953278,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "state": "INACTIVE",
        "type": "NBAR"
      },
      "indicativeNetworkIdentity": [
        {
          "id": "0dc8a8c9-5f5e-4e98-ac17-ba607c7acd8c",
          "instanceId": 14926,
          "authEntityId": 14926,
          "displayName": "14926",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "615",
          "protocol": "TCP",
          "upperPort": 0
        },
        {
          "id": "46dd3b7d-77c4-4e55-bc4a-783fd89d4dd1",
          "instanceId": 14927,
          "authEntityId": 14927,
          "displayName": "14927",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "615",
          "protocol": "UDP",
          "upperPort": 0
        }
      ],
      "networkApplications": [
        {
          "id": "0e7a3fd0-7762-478c-946f-a94d2ef74da2",
          "instanceId": 16040,
          "authEntityId": 16040,
          "displayName": "16040",
          "authEntityClass": -217092956,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "appProtocol": "tcp/udp",
          "applicationGroup": "other",
          "applicationType": "DEFAULT",
          "categoryId": "1fc17362-9334-4c7b-b752-5afdbb574017",
          "createTime": 1517966744227,
          "encrypted": "false",
          "engineId": "3",
          "helpString": "Internet Configuration Manager",
          "ignoreConflict": false,
          "lastUpdateTime": 1517966744227,
          "longDescription": "Registered with IANA on port 615 TCP/UDP",
          "name": "sco-inetmgr",
          "nbarId": "524",
          "p2pTechnology": "false",
          "popularity": 1,
          "rank": 65535,
          "references": "http://www.iana.org/assignments/service-names-port-numbers/service-names-port-numbers.xml",
          "selectorId": "615",
          "subCategory": "f3c0f761-d894-4112-ab89-b90fc84fd660",
          "trafficClass": "OPS_ADMIN_MGMT",
          "tunnel": "false"
        }
      ],
      "networkIdentity": [
        {
          "id": "edfbfa0b-f7d3-4c55-8506-758b0dee059e",
          "instanceId": 14924,
          "authEntityId": 14924,
          "displayName": "14924",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "615",
          "protocol": "TCP",
          "upperPort": 0
        },
        {
          "id": "0ad5db51-2f6f-495a-970d-818a2665abd8",
          "instanceId": 14925,
          "authEntityId": 14925,
          "displayName": "14925",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "615",
          "protocol": "UDP",
          "upperPort": 0
        }
      ],
      "parentScalableGroup": {
        "idRef": "5fcd74d4-8726-47f7-a088-c457863da7ef"
      },
      "user": [

      ],
      "userGroup": [

      ]
    },
    {
      "id": "fcb1b437-e3b6-48de-b0a1-c9e8140a7fa9",
      "instanceId": 15380,
      "authEntityId": 15380,
      "displayName": "15380",
      "authEntityClass": -1909073334,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "createTime": 1517966744841,
      "deployed": false,
      "isSeeded": true,
      "isStale": false,
      "lastUpdateTime": 1517966744841,
      "name": "wap-vcard",
      "namespace": "scalablegroup:application",
      "provisioningState": "DEFINED",
      "qualifier": "application",
      "resourceVersion": 0,
      "targetIdList": [

      ],
      "type": "scalablegroup",
      "cfsChangeInfo": [

      ],
      "customProvisions": [

      ],
      "scalableGroupExternalHandle": "wap-vcard",
      "scalableGroupType": "APPLICATION",
      "securityGroupTag": 0,
      "state": "ACTIVE",
      "vnAgnostic": true,
      "identitySource": {
        "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
        "instanceId": 10010,
        "authEntityId": 10010,
        "displayName": "10010",
        "authEntityClass": 1738953278,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "state": "INACTIVE",
        "type": "NBAR"
      },
      "indicativeNetworkIdentity": [
        {
          "id": "57783910-aff7-424e-a142-1cf927a45015",
          "instanceId": 17763,
          "authEntityId": 17763,
          "displayName": "17763",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "9204",
          "protocol": "UDP",
          "upperPort": 0
        },
        {
          "id": "3306b8e3-3ce4-43a0-91ca-a7d7007bc548",
          "instanceId": 17764,
          "authEntityId": 17764,
          "displayName": "17764",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "9204",
          "protocol": "TCP",
          "upperPort": 0
        }
      ],
      "networkApplications": [
        {
          "id": "a4adde0e-ac0c-4edc-953f-1bc00fd80984",
          "instanceId": 16323,
          "authEntityId": 16323,
          "displayName": "16323",
          "authEntityClass": -217092956,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "appProtocol": "tcp/udp",
          "applicationGroup": "wap-group",
          "applicationType": "DEFAULT",
          "categoryId": "6daa3dfd-3526-407a-88f0-97824b3f3a24",
          "createTime": 1517966744841,
          "encrypted": "false",
          "engineId": "3",
          "helpString": "WAP vCard",
          "ignoreConflict": false,
          "lastUpdateTime": 1517966744841,
          "longDescription": "Internet Mail Consortium (IMC) electronic business card.",
          "name": "wap-vcard",
          "nbarId": "733",
          "p2pTechnology": "false",
          "popularity": 2,
          "rank": 65535,
          "references": "http://www.wapforum.org/what/technical/SPEC-WAESpec-19990524.pdf",
          "selectorId": "9204",
          "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
          "trafficClass": "BULK_DATA",
          "tunnel": "false"
        }
      ],
      "networkIdentity": [
        {
          "id": "77261383-0081-4d42-a00b-f0027b5c81ad",
          "instanceId": 17761,
          "authEntityId": 17761,
          "displayName": "17761",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "9204",
          "protocol": "TCP",
          "upperPort": 0
        },
        {
          "id": "d62a86be-75f0-4844-8785-d96e365cafc1",
          "instanceId": 17762,
          "authEntityId": 17762,
          "displayName": "17762",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "9204",
          "protocol": "UDP",
          "upperPort": 0
        }
      ],
      "parentScalableGroup": {
        "idRef": "000c15bf-28fe-4fbb-9b69-35e6502721f1"
      },
      "user": [

      ],
      "userGroup": [

      ]
    },
    {
      "id": "fce4ab03-b9d8-43c0-917d-802dae6e072a",
      "instanceId": 4346,
      "authEntityId": 4346,
      "displayName": "4346",
      "authEntityClass": -1909073334,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "createTime": 1517966742353,
      "deployed": false,
      "isSeeded": true,
      "isStale": false,
      "lastUpdateTime": 1517966742353,
      "name": "decbsrv",
      "namespace": "scalablegroup:application",
      "provisioningState": "DEFINED",
      "qualifier": "application",
      "resourceVersion": 0,
      "targetIdList": [

      ],
      "type": "scalablegroup",
      "cfsChangeInfo": [

      ],
      "customProvisions": [

      ],
      "scalableGroupExternalHandle": "decbsrv",
      "scalableGroupType": "APPLICATION",
      "securityGroupTag": 0,
      "state": "ACTIVE",
      "vnAgnostic": true,
      "identitySource": {
        "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
        "instanceId": 10010,
        "authEntityId": 10010,
        "displayName": "10010",
        "authEntityClass": 1738953278,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "state": "INACTIVE",
        "type": "NBAR"
      },
      "indicativeNetworkIdentity": [
        {
          "id": "4fa14ec9-5d69-42fc-bcb3-09d199e29914",
          "instanceId": 12816,
          "authEntityId": 12816,
          "displayName": "12816",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "579",
          "protocol": "TCP",
          "upperPort": 0
        },
        {
          "id": "85281ad8-c4a5-497d-800a-281569a2dbf3",
          "instanceId": 12817,
          "authEntityId": 12817,
          "displayName": "12817",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "579",
          "protocol": "UDP",
          "upperPort": 0
        }
      ],
      "networkApplications": [
        {
          "id": "bdd016a9-526a-4868-aad1-0127a92d4b14",
          "instanceId": 11297,
          "authEntityId": 11297,
          "displayName": "11297",
          "authEntityClass": -217092956,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "appProtocol": "tcp/udp",
          "applicationGroup": "other",
          "applicationType": "DEFAULT",
          "categoryId": "6daa3dfd-3526-407a-88f0-97824b3f3a24",
          "createTime": 1517966742353,
          "encrypted": "false",
          "engineId": "3",
          "helpString": "decbsrv",
          "ignoreConflict": false,
          "lastUpdateTime": 1517966742353,
          "longDescription": "Registered with IANA on port 579 TCP/UDP",
          "name": "decbsrv",
          "nbarId": "493",
          "p2pTechnology": "false",
          "popularity": 1,
          "rank": 65535,
          "references": "http://www.iana.org/assignments/service-names-port-numbers/service-names-port-numbers.xml",
          "selectorId": "579",
          "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
          "trafficClass": "BULK_DATA",
          "tunnel": "false"
        }
      ],
      "networkIdentity": [
        {
          "id": "539a9eea-a59c-4c2e-a0c9-a356e6d93d61",
          "instanceId": 12814,
          "authEntityId": 12814,
          "displayName": "12814",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "579",
          "protocol": "TCP",
          "upperPort": 0
        },
        {
          "id": "c0c765c4-6656-4133-b460-c744cdf6630f",
          "instanceId": 12815,
          "authEntityId": 12815,
          "displayName": "12815",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "579",
          "protocol": "UDP",
          "upperPort": 0
        }
      ],
      "parentScalableGroup": {
        "idRef": "000c15bf-28fe-4fbb-9b69-35e6502721f1"
      },
      "user": [

      ],
      "userGroup": [

      ]
    },
    {
      "id": "fd2f2610-4a50-4297-89ba-c144eee49832",
      "instanceId": 4694,
      "authEntityId": 4694,
      "displayName": "4694",
      "authEntityClass": -1909073334,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "createTime": 1517966743299,
      "deployed": false,
      "isSeeded": true,
      "isStale": false,
      "lastUpdateTime": 1517966743299,
      "name": "letv-com",
      "namespace": "scalablegroup:application",
      "provisioningState": "DEFINED",
      "qualifier": "application",
      "resourceVersion": 0,
      "targetIdList": [

      ],
      "type": "scalablegroup",
      "cfsChangeInfo": [

      ],
      "customProvisions": [

      ],
      "scalableGroupExternalHandle": "letv-com",
      "scalableGroupType": "APPLICATION",
      "securityGroupTag": 0,
      "state": "ACTIVE",
      "vnAgnostic": true,
      "identitySource": {
        "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
        "instanceId": 10010,
        "authEntityId": 10010,
        "displayName": "10010",
        "authEntityClass": 1738953278,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "state": "INACTIVE",
        "type": "NBAR"
      },
      "indicativeNetworkIdentity": [

      ],
      "networkApplications": [
        {
          "id": "3730cdfc-bf5d-404b-97c6-35302ad9d645",
          "instanceId": 11644,
          "authEntityId": 11644,
          "displayName": "11644",
          "authEntityClass": -217092956,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "appProtocol": "tcp/udp",
          "applicationGroup": "other",
          "applicationType": "DEFAULT",
          "categoryId": "878644b5-fe91-45b4-9204-31c84a73ba48",
          "createTime": 1517966743299,
          "encrypted": "false",
          "engineId": "13",
          "helpString": "LeTV - Chinese online video portal.",
          "ignoreConflict": false,
          "lastUpdateTime": 1517966743299,
          "longDescription": "LeTV - Chinese online video portal.",
          "name": "letv-com",
          "nbarId": "1701",
          "p2pTechnology": "false",
          "popularity": 3,
          "rank": 65535,
          "references": "http://letv.com",
          "selectorId": "771",
          "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
          "trafficClass": "TRANSACTIONAL_DATA",
          "tunnel": "false"
        }
      ],
      "networkIdentity": [
        {
          "id": "0c3274d9-b6ec-4144-8047-567363145af3",
          "instanceId": 13756,
          "authEntityId": 13756,
          "displayName": "13756",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "53,5353",
          "protocol": "UDP",
          "upperPort": 0
        },
        {
          "id": "99c990e6-9fc1-42f5-8bde-ce63cb2ef400",
          "instanceId": 13757,
          "authEntityId": 13757,
          "displayName": "13757",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "53,80,443,5353",
          "protocol": "TCP",
          "upperPort": 0
        }
      ],
      "parentScalableGroup": {
        "idRef": "28e08de4-d7b2-4521-84ce-bc1e5ee0b9cc"
      },
      "user": [

      ],
      "userGroup": [

      ]
    },
    {
      "id": "fd317f60-a18e-494d-be46-fc469fda0cf6",
      "instanceId": 4775,
      "authEntityId": 4775,
      "displayName": "4775",
      "authEntityClass": -1909073334,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "createTime": 1517966743468,
      "deployed": false,
      "isSeeded": true,
      "isStale": false,
      "lastUpdateTime": 1517966743468,
      "name": "ms-lync",
      "namespace": "scalablegroup:application",
      "provisioningState": "DEFINED",
      "qualifier": "application",
      "resourceVersion": 0,
      "targetIdList": [

      ],
      "type": "scalablegroup",
      "cfsChangeInfo": [

      ],
      "customProvisions": [

      ],
      "scalableGroupExternalHandle": "ms-lync",
      "scalableGroupType": "APPLICATION",
      "securityGroupTag": 0,
      "state": "ACTIVE",
      "vnAgnostic": true,
      "identitySource": {
        "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
        "instanceId": 10010,
        "authEntityId": 10010,
        "displayName": "10010",
        "authEntityClass": 1738953278,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "state": "INACTIVE",
        "type": "NBAR"
      },
      "indicativeNetworkIdentity": [

      ],
      "networkApplications": [
        {
          "id": "fcc26156-d528-4aba-9e96-e9757ecf6103",
          "instanceId": 11724,
          "authEntityId": 11724,
          "displayName": "11724",
          "authEntityClass": -217092956,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "appProtocol": "tcp/udp",
          "applicationGroup": "ms-lync-group",
          "applicationType": "DEFAULT",
          "categoryId": "38a8e354-e2fe-4f3c-b1c5-2bbb90fb0d99",
          "createTime": 1517966743468,
          "encrypted": "true",
          "engineId": "13",
          "helpString": "Skype provides cost effective and collaborative tools for businesses",
          "ignoreConflict": false,
          "lastUpdateTime": 1517966743468,
          "longDescription": "Skype for Business (formerly Microsoft Lync) is a communications and                      collaboration platform that brings together an experience inspired by                      Skype with enterprise-grade security, compliance and control. It offers                      features including presence, IM, voice and video calls, and online meetings.                      Since it is built right in to Microsoft Office, initiating chats,                      calls and meetings is an integrated experience within Office.",
          "name": "ms-lync",
          "nbarId": "1466",
          "p2pTechnology": "false",
          "popularity": 10,
          "rank": 65535,
          "references": "http://www.skype.com/en/business/",
          "selectorId": "531",
          "subCategory": "05178016-4555-4291-ab4e-43c2ab2a931a",
          "trafficClass": "MULTIMEDIA_CONFERENCING",
          "tunnel": "false"
        }
      ],
      "networkIdentity": [
        {
          "id": "82665c22-4315-44b5-92ea-1e19626b8036",
          "instanceId": 13979,
          "authEntityId": 13979,
          "displayName": "13979",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "53,80,443,3478,5353",
          "protocol": "TCP",
          "upperPort": 0
        },
        {
          "id": "d66f0b6d-46cb-4c92-9d70-885ac706a060",
          "instanceId": 13980,
          "authEntityId": 13980,
          "displayName": "13980",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "53,3478,5353",
          "protocol": "UDP",
          "upperPort": 0
        }
      ],
      "parentScalableGroup": {
        "idRef": "60f1364d-2a02-4063-8ba1-07817abe0612"
      },
      "user": [

      ],
      "userGroup": [

      ]
    },
    {
      "id": "fda665c1-a2cb-456a-833d-c0365ae6b089",
      "instanceId": 4453,
      "authEntityId": 4453,
      "displayName": "4453",
      "authEntityClass": -1909073334,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "createTime": 1517966742710,
      "deployed": false,
      "isSeeded": true,
      "isStale": false,
      "lastUpdateTime": 1517966742710,
      "name": "fix",
      "namespace": "scalablegroup:application",
      "provisioningState": "DEFINED",
      "qualifier": "application",
      "resourceVersion": 0,
      "targetIdList": [

      ],
      "type": "scalablegroup",
      "cfsChangeInfo": [

      ],
      "customProvisions": [

      ],
      "scalableGroupExternalHandle": "fix",
      "scalableGroupType": "APPLICATION",
      "securityGroupTag": 0,
      "state": "ACTIVE",
      "vnAgnostic": true,
      "identitySource": {
        "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
        "instanceId": 10010,
        "authEntityId": 10010,
        "displayName": "10010",
        "authEntityClass": 1738953278,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "state": "INACTIVE",
        "type": "NBAR"
      },
      "indicativeNetworkIdentity": [

      ],
      "networkApplications": [
        {
          "id": "affaa181-1689-482c-b101-1ce953f7882d",
          "instanceId": 11404,
          "authEntityId": 11404,
          "displayName": "11404",
          "authEntityClass": -217092956,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "appProtocol": "tcp/udp",
          "applicationGroup": "other",
          "applicationType": "DEFAULT",
          "categoryId": "7e19fdea-6222-4c57-9e66-66c2340d6bc3",
          "createTime": 1517966742710,
          "encrypted": "true",
          "engineId": "13",
          "helpString": "Financial Information eXchange Protocol",
          "ignoreConflict": false,
          "lastUpdateTime": 1517966742710,
          "longDescription": "Financial Information eXchange (FIX) is a standard way to communicate trading information electronically between brokers, institutions and markets. The underlying protocol is SSL.",
          "name": "fix",
          "nbarId": "74",
          "p2pTechnology": "false",
          "popularity": 6,
          "rank": 65535,
          "references": "http://www.fixprotocol.org/what-is-fix.shtml",
          "selectorId": "74",
          "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
          "trafficClass": "BULK_DATA",
          "tunnel": "false"
        }
      ],
      "networkIdentity": [
        {
          "id": "d89b5c31-4d7e-4173-9c08-9fde206e49dc",
          "instanceId": 13130,
          "authEntityId": 13130,
          "displayName": "13130",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "53,5353",
          "protocol": "UDP",
          "upperPort": 0
        },
        {
          "id": "7118207c-d61a-4cc7-949f-9298d63e83ba",
          "instanceId": 13131,
          "authEntityId": 13131,
          "displayName": "13131",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "53,80,443,5353",
          "protocol": "TCP",
          "upperPort": 0
        }
      ],
      "parentScalableGroup": {
        "idRef": "383363b1-4dd5-49d1-bfe4-34daac437cfb"
      },
      "user": [

      ],
      "userGroup": [

      ]
    },
    {
      "id": "fdbd5c93-493b-4799-8c05-2f6e45bb2c44",
      "instanceId": 4809,
      "authEntityId": 4809,
      "displayName": "4809",
      "authEntityClass": -1909073334,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "createTime": 1517966743535,
      "deployed": false,
      "isSeeded": true,
      "isStale": false,
      "lastUpdateTime": 1517966743535,
      "name": "multiplex",
      "namespace": "scalablegroup:application",
      "provisioningState": "DEFINED",
      "qualifier": "application",
      "resourceVersion": 0,
      "targetIdList": [

      ],
      "type": "scalablegroup",
      "cfsChangeInfo": [

      ],
      "customProvisions": [

      ],
      "scalableGroupExternalHandle": "multiplex",
      "scalableGroupType": "APPLICATION",
      "securityGroupTag": 0,
      "state": "ACTIVE",
      "vnAgnostic": true,
      "identitySource": {
        "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
        "instanceId": 10010,
        "authEntityId": 10010,
        "displayName": "10010",
        "authEntityClass": 1738953278,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "state": "INACTIVE",
        "type": "NBAR"
      },
      "indicativeNetworkIdentity": [
        {
          "id": "c3255a6a-82c5-408e-ac2e-5a9a08353000",
          "instanceId": 14067,
          "authEntityId": 14067,
          "displayName": "14067",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "171",
          "protocol": "UDP",
          "upperPort": 0
        },
        {
          "id": "324ece5b-3a14-4697-aa19-19d8a76ae301",
          "instanceId": 14068,
          "authEntityId": 14068,
          "displayName": "14068",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "171",
          "protocol": "TCP",
          "upperPort": 0
        }
      ],
      "networkApplications": [
        {
          "id": "395a4c30-b9aa-492f-a0a6-1dd453884bfe",
          "instanceId": 11758,
          "authEntityId": 11758,
          "displayName": "11758",
          "authEntityClass": -217092956,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "appProtocol": "tcp/udp",
          "applicationGroup": "other",
          "applicationType": "DEFAULT",
          "categoryId": "6daa3dfd-3526-407a-88f0-97824b3f3a24",
          "createTime": 1517966743535,
          "encrypted": "false",
          "engineId": "3",
          "helpString": "Network Innovations Multiplex",
          "ignoreConflict": false,
          "lastUpdateTime": 1517966743535,
          "longDescription": "Registered with IANA on port 171 TCP/UDP",
          "name": "multiplex",
          "nbarId": "1016",
          "p2pTechnology": "false",
          "popularity": 1,
          "rank": 65535,
          "references": "http://www.iana.org/assignments/service-names-port-numbers/service-names-port-numbers.xml",
          "selectorId": "171",
          "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
          "trafficClass": "BULK_DATA",
          "tunnel": "false"
        }
      ],
      "networkIdentity": [
        {
          "id": "cec508d5-c952-4c16-9f66-19f12eb69916",
          "instanceId": 14065,
          "authEntityId": 14065,
          "displayName": "14065",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "171",
          "protocol": "UDP",
          "upperPort": 0
        },
        {
          "id": "3607a8ce-1b80-4567-a6b3-753a7a755015",
          "instanceId": 14066,
          "authEntityId": 14066,
          "displayName": "14066",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "171",
          "protocol": "TCP",
          "upperPort": 0
        }
      ],
      "parentScalableGroup": {
        "idRef": "000c15bf-28fe-4fbb-9b69-35e6502721f1"
      },
      "user": [

      ],
      "userGroup": [

      ]
    },
    {
      "id": "fdf65e88-82f0-48f2-9da9-f126f61eeacf",
      "instanceId": 4928,
      "authEntityId": 4928,
      "displayName": "4928",
      "authEntityClass": -1909073334,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "createTime": 1517966743821,
      "deployed": false,
      "isSeeded": true,
      "isStale": false,
      "lastUpdateTime": 1517966743821,
      "name": "ospf",
      "namespace": "scalablegroup:application",
      "provisioningState": "DEFINED",
      "qualifier": "application",
      "resourceVersion": 0,
      "targetIdList": [

      ],
      "type": "scalablegroup",
      "cfsChangeInfo": [

      ],
      "customProvisions": [

      ],
      "scalableGroupExternalHandle": "ospf",
      "scalableGroupType": "APPLICATION",
      "securityGroupTag": 0,
      "state": "ACTIVE",
      "vnAgnostic": true,
      "identitySource": {
        "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
        "instanceId": 10010,
        "authEntityId": 10010,
        "displayName": "10010",
        "authEntityClass": 1738953278,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "state": "INACTIVE",
        "type": "NBAR"
      },
      "indicativeNetworkIdentity": [
        {
          "id": "be4253dd-0fe5-4a9c-8635-fd19cae6fe4c",
          "instanceId": 14450,
          "authEntityId": 14450,
          "displayName": "14450",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipProtocolNumbers": "89",
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "protocol": "OSPF",
          "upperPort": 0
        }
      ],
      "networkApplications": [
        {
          "id": "6f96e101-9202-4f6a-8f75-8599e039c2f7",
          "instanceId": 11877,
          "authEntityId": 11877,
          "displayName": "11877",
          "authEntityClass": -217092956,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "appProtocol": "ip",
          "applicationGroup": "other",
          "applicationType": "DEFAULT",
          "categoryId": "1fc17362-9334-4c7b-b752-5afdbb574017",
          "createTime": 1517966743821,
          "encrypted": "false",
          "engineId": "1",
          "helpString": "Open Shortest Path First",
          "ignoreConflict": false,
          "lastUpdateTime": 1517966743821,
          "longDescription": "Open Shortest Path First (OSPF) is a link state routing protocol that shares the network topology of an Autonomous System between OSPF routers. Each OSPF router maintains a database by calculating Shortest Path Tree algorithm with the link state provided from the OSPF protocol.",
          "name": "ospf",
          "nbarId": "10",
          "p2pTechnology": "false",
          "popularity": 9,
          "rank": 65535,
          "references": "http://www.ietf.org/rfc/rfc2328.txt",
          "selectorId": "89",
          "subCategory": "7cc1b3a6-4102-44e9-b522-0e71afe39a03",
          "trafficClass": "NETWORK_CONTROL",
          "tunnel": "false"
        }
      ],
      "networkIdentity": [
        {
          "id": "8995eb65-9d8b-4c26-95ce-ad92ede6cd10",
          "instanceId": 14449,
          "authEntityId": 14449,
          "displayName": "14449",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipProtocolNumbers": "89",
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "protocol": "OSPF",
          "upperPort": 0
        }
      ],
      "parentScalableGroup": {
        "idRef": "77e75842-e98b-4112-ad0c-300db4bb11be"
      },
      "user": [

      ],
      "userGroup": [

      ]
    },
    {
      "id": "fe3b864a-1e7f-4d75-9887-d546eb16214a",
      "instanceId": 4504,
      "authEntityId": 4504,
      "displayName": "4504",
      "authEntityClass": -1909073334,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "createTime": 1517966742834,
      "deployed": false,
      "isSeeded": true,
      "isStale": false,
      "lastUpdateTime": 1517966742834,
      "name": "gopher",
      "namespace": "scalablegroup:application",
      "provisioningState": "DEFINED",
      "qualifier": "application",
      "resourceVersion": 0,
      "targetIdList": [

      ],
      "type": "scalablegroup",
      "cfsChangeInfo": [

      ],
      "customProvisions": [

      ],
      "scalableGroupExternalHandle": "gopher",
      "scalableGroupType": "APPLICATION",
      "securityGroupTag": 0,
      "state": "ACTIVE",
      "vnAgnostic": true,
      "identitySource": {
        "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
        "instanceId": 10010,
        "authEntityId": 10010,
        "displayName": "10010",
        "authEntityClass": 1738953278,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "state": "INACTIVE",
        "type": "NBAR"
      },
      "indicativeNetworkIdentity": [
        {
          "id": "41b799a7-1f45-43e5-b23e-3b5fd827d9bd",
          "instanceId": 13248,
          "authEntityId": 13248,
          "displayName": "13248",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "70",
          "protocol": "TCP",
          "upperPort": 0
        },
        {
          "id": "00e12b91-c4c5-48f6-a7e0-2c278e9b5803",
          "instanceId": 13247,
          "authEntityId": 13247,
          "displayName": "13247",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "70",
          "protocol": "UDP",
          "upperPort": 0
        }
      ],
      "networkApplications": [
        {
          "id": "4357b183-cdee-4b7e-8a1c-2c2115f8cb85",
          "instanceId": 11455,
          "authEntityId": 11455,
          "displayName": "11455",
          "authEntityClass": -217092956,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "appProtocol": "tcp/udp",
          "applicationGroup": "other",
          "applicationType": "DEFAULT",
          "categoryId": "6daa3dfd-3526-407a-88f0-97824b3f3a24",
          "createTime": 1517966742834,
          "encrypted": "false",
          "engineId": "3",
          "helpString": "Internet Gopher protocol, online document management.",
          "ignoreConflict": false,
          "lastUpdateTime": 1517966742834,
          "longDescription": "Gopher is a TCP/IP application layer protocol designed for distributing, searching, and retrieving documents over the Internet. The protocol is based on a client-server architecture and usually uses TCP port 70 as default.",
          "name": "gopher",
          "nbarId": "15",
          "p2pTechnology": "false",
          "popularity": 1,
          "rank": 65535,
          "references": "http://tools.ietf.org/html/rfc1436",
          "selectorId": "70",
          "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
          "trafficClass": "BULK_DATA",
          "tunnel": "false"
        }
      ],
      "networkIdentity": [
        {
          "id": "06376fac-c80d-4afe-8f3e-72b11f158f0d",
          "instanceId": 13245,
          "authEntityId": 13245,
          "displayName": "13245",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "70",
          "protocol": "UDP",
          "upperPort": 0
        },
        {
          "id": "698b0690-c379-487e-962a-3155976d5c0a",
          "instanceId": 13246,
          "authEntityId": 13246,
          "displayName": "13246",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "70",
          "protocol": "TCP",
          "upperPort": 0
        }
      ],
      "parentScalableGroup": {
        "idRef": "000c15bf-28fe-4fbb-9b69-35e6502721f1"
      },
      "user": [

      ],
      "userGroup": [

      ]
    },
    {
      "id": "fe4d572e-24fc-481e-a553-6b7237753d2d",
      "instanceId": 15243,
      "authEntityId": 15243,
      "displayName": "15243",
      "authEntityClass": -1909073334,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "createTime": 1517966744566,
      "deployed": false,
      "isSeeded": true,
      "isStale": false,
      "lastUpdateTime": 1517966744566,
      "name": "synoptics-trap",
      "namespace": "scalablegroup:application",
      "provisioningState": "DEFINED",
      "qualifier": "application",
      "resourceVersion": 0,
      "targetIdList": [

      ],
      "type": "scalablegroup",
      "cfsChangeInfo": [

      ],
      "customProvisions": [

      ],
      "scalableGroupExternalHandle": "synoptics-trap",
      "scalableGroupType": "APPLICATION",
      "securityGroupTag": 0,
      "state": "ACTIVE",
      "vnAgnostic": true,
      "identitySource": {
        "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
        "instanceId": 10010,
        "authEntityId": 10010,
        "displayName": "10010",
        "authEntityClass": 1738953278,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "state": "INACTIVE",
        "type": "NBAR"
      },
      "indicativeNetworkIdentity": [
        {
          "id": "00885ebf-5aea-43f8-ac44-39798240fe0f",
          "instanceId": 17367,
          "authEntityId": 17367,
          "displayName": "17367",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "412",
          "protocol": "UDP",
          "upperPort": 0
        }
      ],
      "networkApplications": [
        {
          "id": "55f85918-b2bc-4c6a-9f43-2d357384edab",
          "instanceId": 16186,
          "authEntityId": 16186,
          "displayName": "16186",
          "authEntityClass": -217092956,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "appProtocol": "udp",
          "applicationGroup": "snmp-group",
          "applicationType": "DEFAULT",
          "categoryId": "1fc17362-9334-4c7b-b752-5afdbb574017",
          "createTime": 1517966744566,
          "encrypted": "false",
          "engineId": "3",
          "helpString": "Trap Convention Port",
          "ignoreConflict": false,
          "lastUpdateTime": 1517966744566,
          "longDescription": "Registered with IANA on port 412 UDP",
          "name": "synoptics-trap",
          "nbarId": "327",
          "p2pTechnology": "false",
          "popularity": 1,
          "rank": 65535,
          "references": "http://www.iana.org/assignments/service-names-port-numbers/service-names-port-numbers.xml",
          "selectorId": "412",
          "subCategory": "f3c0f761-d894-4112-ab89-b90fc84fd660",
          "trafficClass": "OPS_ADMIN_MGMT",
          "tunnel": "false"
        }
      ],
      "networkIdentity": [
        {
          "id": "95c8287c-dd75-4fb2-9f5d-72a9817e5a19",
          "instanceId": 17366,
          "authEntityId": 17366,
          "displayName": "17366",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "412",
          "protocol": "UDP",
          "upperPort": 0
        }
      ],
      "parentScalableGroup": {
        "idRef": "5fcd74d4-8726-47f7-a088-c457863da7ef"
      },
      "user": [

      ],
      "userGroup": [

      ]
    },
    {
      "id": "fee29259-a3bc-4170-a765-2eb7a7032d21",
      "instanceId": 4830,
      "authEntityId": 4830,
      "displayName": "4830",
      "authEntityClass": -1909073334,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "createTime": 1517966743575,
      "deployed": false,
      "isSeeded": true,
      "isStale": false,
      "lastUpdateTime": 1517966743575,
      "name": "nest-protocol",
      "namespace": "scalablegroup:application",
      "provisioningState": "DEFINED",
      "qualifier": "application",
      "resourceVersion": 0,
      "targetIdList": [

      ],
      "type": "scalablegroup",
      "cfsChangeInfo": [

      ],
      "customProvisions": [

      ],
      "scalableGroupExternalHandle": "nest-protocol",
      "scalableGroupType": "APPLICATION",
      "securityGroupTag": 0,
      "state": "ACTIVE",
      "vnAgnostic": true,
      "identitySource": {
        "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
        "instanceId": 10010,
        "authEntityId": 10010,
        "displayName": "10010",
        "authEntityClass": 1738953278,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "state": "INACTIVE",
        "type": "NBAR"
      },
      "indicativeNetworkIdentity": [
        {
          "id": "b837ed21-c5cb-4464-9137-b9d62eb22eeb",
          "instanceId": 14126,
          "authEntityId": 14126,
          "displayName": "14126",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "489",
          "protocol": "UDP",
          "upperPort": 0
        },
        {
          "id": "eec8049e-a9e3-49ce-b8c9-a00af6af85e7",
          "instanceId": 14127,
          "authEntityId": 14127,
          "displayName": "14127",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "489",
          "protocol": "TCP",
          "upperPort": 0
        }
      ],
      "networkApplications": [
        {
          "id": "13aa7974-3511-47f0-ba20-e4335bbb3f95",
          "instanceId": 11779,
          "authEntityId": 11779,
          "displayName": "11779",
          "authEntityClass": -217092956,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "appProtocol": "tcp/udp",
          "applicationGroup": "other",
          "applicationType": "DEFAULT",
          "categoryId": "1fc17362-9334-4c7b-b752-5afdbb574017",
          "createTime": 1517966743575,
          "encrypted": "false",
          "engineId": "3",
          "helpString": "nest-protocol",
          "ignoreConflict": false,
          "lastUpdateTime": 1517966743575,
          "longDescription": "NEST , by Novell,  defines a systems architecture, and supplies a set of implementation tools and strategies, to add value to embedded systems by making NetWare services available for their use. These services range from file and print, to communications with other network nodes.",
          "name": "nest-protocol",
          "nbarId": "403",
          "p2pTechnology": "false",
          "popularity": 1,
          "rank": 65535,
          "references": "http://support.novell.com/techcenter/articles/dnd19941103.html",
          "selectorId": "489",
          "subCategory": "787a7d77-977b-4974-89d3-379ba49e88b7",
          "trafficClass": "OPS_ADMIN_MGMT",
          "tunnel": "false"
        }
      ],
      "networkIdentity": [
        {
          "id": "394f6975-5bcf-4bae-b730-c97af912855c",
          "instanceId": 14124,
          "authEntityId": 14124,
          "displayName": "14124",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "489",
          "protocol": "TCP",
          "upperPort": 0
        },
        {
          "id": "ec4373d6-df91-42a3-b59f-92800020930f",
          "instanceId": 14125,
          "authEntityId": 14125,
          "displayName": "14125",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "489",
          "protocol": "UDP",
          "upperPort": 0
        }
      ],
      "parentScalableGroup": {
        "idRef": "e06dd89f-0a70-4422-ab0b-c2314650a9a7"
      },
      "user": [

      ],
      "userGroup": [

      ]
    },
    {
      "id": "ff1ba77b-d8c7-4b30-b010-0cfa8b1d43d6",
      "instanceId": 4979,
      "authEntityId": 4979,
      "displayName": "4979",
      "authEntityClass": -1909073334,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "createTime": 1517966743961,
      "deployed": false,
      "isSeeded": true,
      "isStale": false,
      "lastUpdateTime": 1517966743961,
      "name": "pptp",
      "namespace": "scalablegroup:application",
      "provisioningState": "DEFINED",
      "qualifier": "application",
      "resourceVersion": 0,
      "targetIdList": [

      ],
      "type": "scalablegroup",
      "cfsChangeInfo": [

      ],
      "customProvisions": [

      ],
      "scalableGroupExternalHandle": "pptp",
      "scalableGroupType": "APPLICATION",
      "securityGroupTag": 0,
      "state": "ACTIVE",
      "vnAgnostic": true,
      "identitySource": {
        "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
        "instanceId": 10010,
        "authEntityId": 10010,
        "displayName": "10010",
        "authEntityClass": 1738953278,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "state": "INACTIVE",
        "type": "NBAR"
      },
      "indicativeNetworkIdentity": [
        {
          "id": "b6348461-0693-4b4b-a1e2-5bd937448202",
          "instanceId": 14591,
          "authEntityId": 14591,
          "displayName": "14591",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "1723",
          "protocol": "TCP",
          "upperPort": 0
        }
      ],
      "networkApplications": [
        {
          "id": "1964af98-3f8f-40a8-a46e-09eea0f5f87d",
          "instanceId": 11928,
          "authEntityId": 11928,
          "displayName": "11928",
          "authEntityClass": -217092956,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "appProtocol": "tcp",
          "applicationGroup": "other",
          "applicationType": "DEFAULT",
          "categoryId": "70fce3cd-f2bd-48de-9020-7b7840b9e7a1",
          "createTime": 1517966743961,
          "encrypted": "true",
          "engineId": "3",
          "helpString": "Point-to-Point Tunneling Protocol",
          "ignoreConflict": false,
          "lastUpdateTime": 1517966743961,
          "longDescription": "Point-to-Point Tunneling Protocol (PPTP) uses a control channel over TCP and a GRE (Generic Routing Encapsulation) tunnel operating to encapsulate PPP packets.",
          "name": "pptp",
          "nbarId": "35",
          "p2pTechnology": "false",
          "popularity": 4,
          "rank": 65535,
          "references": "http://www.ietf.org/rfc/rfc2637.txt",
          "selectorId": "1723",
          "subCategory": "ab63e736-9aa7-4946-b55b-3b26ddca609c",
          "trafficClass": "BULK_DATA",
          "tunnel": "true"
        }
      ],
      "networkIdentity": [
        {
          "id": "2e60c926-2912-4f50-83e6-9be88d1efb39",
          "instanceId": 14590,
          "authEntityId": 14590,
          "displayName": "14590",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "1723",
          "protocol": "TCP",
          "upperPort": 0
        }
      ],
      "parentScalableGroup": {
        "idRef": "79f9d505-477f-4ccd-bd2a-c5fc8097a2e1"
      },
      "user": [

      ],
      "userGroup": [

      ]
    },
    {
      "id": "ff2ea93b-0dc7-43a8-acb9-00bcad42cab7",
      "instanceId": 15094,
      "authEntityId": 15094,
      "displayName": "15094",
      "authEntityClass": -1909073334,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "createTime": 1517966744220,
      "deployed": false,
      "isSeeded": true,
      "isStale": false,
      "lastUpdateTime": 1517966744220,
      "name": "scc-sp",
      "namespace": "scalablegroup:application",
      "provisioningState": "DEFINED",
      "qualifier": "application",
      "resourceVersion": 0,
      "targetIdList": [

      ],
      "type": "scalablegroup",
      "cfsChangeInfo": [

      ],
      "customProvisions": [

      ],
      "scalableGroupExternalHandle": "scc-sp",
      "scalableGroupType": "APPLICATION",
      "securityGroupTag": 0,
      "state": "ACTIVE",
      "vnAgnostic": true,
      "identitySource": {
        "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
        "instanceId": 10010,
        "authEntityId": 10010,
        "displayName": "10010",
        "authEntityClass": 1738953278,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "state": "INACTIVE",
        "type": "NBAR"
      },
      "indicativeNetworkIdentity": [
        {
          "id": "e612a790-86ff-432d-8eaa-e7ed2e46e6a7",
          "instanceId": 14917,
          "authEntityId": 14917,
          "displayName": "14917",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipProtocolNumbers": "96",
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "protocol": "SCC_SP",
          "upperPort": 0
        }
      ],
      "networkApplications": [
        {
          "id": "71d4fae7-9877-4044-b7ba-1a49f550c4e7",
          "instanceId": 16037,
          "authEntityId": 16037,
          "displayName": "16037",
          "authEntityClass": -217092956,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "appProtocol": "ip",
          "applicationGroup": "other",
          "applicationType": "DEFAULT",
          "categoryId": "6daa3dfd-3526-407a-88f0-97824b3f3a24",
          "createTime": 1517966744220,
          "encrypted": "unassigned",
          "engineId": "1",
          "helpString": "Semaphore Communications Sec. Pro.",
          "ignoreConflict": false,
          "lastUpdateTime": 1517966744220,
          "longDescription": "Registered with IANA as IP Protocol 96",
          "name": "scc-sp",
          "nbarId": "850",
          "p2pTechnology": "unassigned",
          "popularity": 1,
          "rank": 65535,
          "references": "http://www.iana.org/assignments/protocol-numbers/protocol-numbers.xml",
          "selectorId": "96",
          "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
          "trafficClass": "BULK_DATA",
          "tunnel": "unassigned"
        }
      ],
      "networkIdentity": [
        {
          "id": "a451c9de-33a6-4f6d-98dc-194250921469",
          "instanceId": 14916,
          "authEntityId": 14916,
          "displayName": "14916",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipProtocolNumbers": "96",
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "protocol": "SCC_SP",
          "upperPort": 0
        }
      ],
      "parentScalableGroup": {
        "idRef": "000c15bf-28fe-4fbb-9b69-35e6502721f1"
      },
      "user": [

      ],
      "userGroup": [

      ]
    },
    {
      "id": "ff79286a-9072-4d1e-950c-198c349d2c12",
      "instanceId": 4692,
      "authEntityId": 4692,
      "displayName": "4692",
      "authEntityClass": -1909073334,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "createTime": 1517966743295,
      "deployed": false,
      "isSeeded": true,
      "isStale": false,
      "lastUpdateTime": 1517966743295,
      "name": "legent-1",
      "namespace": "scalablegroup:application",
      "provisioningState": "DEFINED",
      "qualifier": "application",
      "resourceVersion": 0,
      "targetIdList": [

      ],
      "type": "scalablegroup",
      "cfsChangeInfo": [

      ],
      "customProvisions": [

      ],
      "scalableGroupExternalHandle": "legent-1",
      "scalableGroupType": "APPLICATION",
      "securityGroupTag": 0,
      "state": "ACTIVE",
      "vnAgnostic": true,
      "identitySource": {
        "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
        "instanceId": 10010,
        "authEntityId": 10010,
        "displayName": "10010",
        "authEntityClass": 1738953278,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "state": "INACTIVE",
        "type": "NBAR"
      },
      "indicativeNetworkIdentity": [
        {
          "id": "54500917-7787-4846-8ad6-d8f8123c9310",
          "instanceId": 13750,
          "authEntityId": 13750,
          "displayName": "13750",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "373",
          "protocol": "TCP",
          "upperPort": 0
        },
        {
          "id": "05d22e9d-1bfb-4ea8-be27-62adccf311d0",
          "instanceId": 13751,
          "authEntityId": 13751,
          "displayName": "13751",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "373",
          "protocol": "UDP",
          "upperPort": 0
        }
      ],
      "networkApplications": [
        {
          "id": "fbf74436-2f00-4538-b108-46d1152be39a",
          "instanceId": 11642,
          "authEntityId": 11642,
          "displayName": "11642",
          "authEntityClass": -217092956,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "appProtocol": "tcp/udp",
          "applicationGroup": "other",
          "applicationType": "DEFAULT",
          "categoryId": "6daa3dfd-3526-407a-88f0-97824b3f3a24",
          "createTime": 1517966743295,
          "encrypted": "false",
          "engineId": "3",
          "helpString": "Legent Corporation",
          "ignoreConflict": false,
          "lastUpdateTime": 1517966743295,
          "longDescription": "Registered with IANA on port 373 TCP/UDP",
          "name": "legent-1",
          "nbarId": "289",
          "p2pTechnology": "false",
          "popularity": 1,
          "rank": 65535,
          "references": "http://www.iana.org/assignments/service-names-port-numbers/service-names-port-numbers.xml",
          "selectorId": "373",
          "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
          "trafficClass": "BULK_DATA",
          "tunnel": "false"
        }
      ],
      "networkIdentity": [
        {
          "id": "0d009807-fd4d-4098-abc1-6b3f37b88aff",
          "instanceId": 13748,
          "authEntityId": 13748,
          "displayName": "13748",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "373",
          "protocol": "TCP",
          "upperPort": 0
        },
        {
          "id": "9d3356b3-62fb-4ec1-8ff1-8d4ca951eb5c",
          "instanceId": 13749,
          "authEntityId": 13749,
          "displayName": "13749",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "373",
          "protocol": "UDP",
          "upperPort": 0
        }
      ],
      "parentScalableGroup": {
        "idRef": "000c15bf-28fe-4fbb-9b69-35e6502721f1"
      },
      "user": [

      ],
      "userGroup": [

      ]
    },
    {
      "id": "ffc9f692-aa35-4a8d-9941-4c9d70f66a9c",
      "instanceId": 15360,
      "authEntityId": 15360,
      "displayName": "15360",
      "authEntityClass": -1909073334,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "createTime": 1517966744803,
      "deployed": false,
      "isSeeded": true,
      "isStale": false,
      "lastUpdateTime": 1517966744803,
      "name": "vmware-fdm",
      "namespace": "scalablegroup:application",
      "provisioningState": "DEFINED",
      "qualifier": "application",
      "resourceVersion": 0,
      "targetIdList": [

      ],
      "type": "scalablegroup",
      "cfsChangeInfo": [

      ],
      "customProvisions": [

      ],
      "scalableGroupExternalHandle": "vmware-fdm",
      "scalableGroupType": "APPLICATION",
      "securityGroupTag": 0,
      "state": "ACTIVE",
      "vnAgnostic": true,
      "identitySource": {
        "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
        "instanceId": 10010,
        "authEntityId": 10010,
        "displayName": "10010",
        "authEntityClass": 1738953278,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "state": "INACTIVE",
        "type": "NBAR"
      },
      "indicativeNetworkIdentity": [
        {
          "id": "c4a5c021-e75c-4200-b2b2-8a387bcc5c52",
          "instanceId": 17697,
          "authEntityId": 17697,
          "displayName": "17697",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "8182",
          "protocol": "TCP",
          "upperPort": 0
        },
        {
          "id": "3a20c9d3-cd5f-4f26-a782-b9ddab1d43fa",
          "instanceId": 17698,
          "authEntityId": 17698,
          "displayName": "17698",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "8182",
          "protocol": "UDP",
          "upperPort": 0
        }
      ],
      "networkApplications": [
        {
          "id": "c0911c49-9be3-492b-959b-84425513720e",
          "instanceId": 16303,
          "authEntityId": 16303,
          "displayName": "16303",
          "authEntityClass": -217092956,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "appProtocol": "tcp/udp",
          "applicationGroup": "vmware-group",
          "applicationType": "DEFAULT",
          "categoryId": "6daa3dfd-3526-407a-88f0-97824b3f3a24",
          "createTime": 1517966744803,
          "encrypted": "unassigned",
          "engineId": "3",
          "helpString": "VMware Fault Domain Manager",
          "ignoreConflict": false,
          "lastUpdateTime": 1517966744803,
          "longDescription": "VMware Fault Domain Manager is a service of vSphere High Availability (HA) and vSphere Fault Tolerance. This service monitors the availability and power state of virtual machines, and restarts protected virtual machines when they fail. vSphere is a software of the VMWare suite, which is a virtualization software suite. Fault Domain manager typically uses TCP/UDP port 8182.",
          "name": "vmware-fdm",
          "nbarId": "1394",
          "p2pTechnology": "unassigned",
          "popularity": 1,
          "rank": 65535,
          "references": "http://pubs.vmware.com/vsphere-50/topic/com.vmware.vsphere.avail.doc_50/GUID-7442EF04-56C9-4910-ACBA-DF42E97ED311.html",
          "selectorId": "8182",
          "subCategory": "d9f80d02-3df5-4c74-ba7a-a2ea614b6f4f",
          "trafficClass": "BULK_DATA",
          "tunnel": "unassigned"
        }
      ],
      "networkIdentity": [
        {
          "id": "fd5a4a87-76f8-4dad-8dbc-da8084255594",
          "instanceId": 17696,
          "authEntityId": 17696,
          "displayName": "17696",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "8182",
          "protocol": "UDP",
          "upperPort": 0
        },
        {
          "id": "dd9e00f5-5bad-4272-8e4d-f086f38f7af9",
          "instanceId": 17695,
          "authEntityId": 17695,
          "displayName": "17695",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "8182",
          "protocol": "TCP",
          "upperPort": 0
        }
      ],
      "parentScalableGroup": {
        "idRef": "000c15bf-28fe-4fbb-9b69-35e6502721f1"
      },
      "user": [

      ],
      "userGroup": [

      ]
    },
    {
      "id": "ffd9ac6e-fb86-4bc0-a7ba-ac020fe2075a",
      "instanceId": 15430,
      "authEntityId": 15430,
      "displayName": "15430",
      "authEntityClass": -1909073334,
      "deployPending": "NONE",
      "instanceVersion": 0,
      "createTime": 1517966744951,
      "deployed": false,
      "isSeeded": true,
      "isStale": false,
      "lastUpdateTime": 1517966744951,
      "name": "x-bone-ctl",
      "namespace": "scalablegroup:application",
      "provisioningState": "DEFINED",
      "qualifier": "application",
      "resourceVersion": 0,
      "targetIdList": [

      ],
      "type": "scalablegroup",
      "cfsChangeInfo": [

      ],
      "customProvisions": [

      ],
      "scalableGroupExternalHandle": "x-bone-ctl",
      "scalableGroupType": "APPLICATION",
      "securityGroupTag": 0,
      "state": "ACTIVE",
      "vnAgnostic": true,
      "identitySource": {
        "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
        "instanceId": 10010,
        "authEntityId": 10010,
        "displayName": "10010",
        "authEntityClass": 1738953278,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "state": "INACTIVE",
        "type": "NBAR"
      },
      "indicativeNetworkIdentity": [
        {
          "id": "f45e11c6-643a-483a-86b0-3e49d0e94a4e",
          "instanceId": 17873,
          "authEntityId": 17873,
          "displayName": "17873",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "265",
          "protocol": "TCP",
          "upperPort": 0
        },
        {
          "id": "8fd970fa-47e3-4ee3-af63-6e4d1f5cb995",
          "instanceId": 17874,
          "authEntityId": 17874,
          "displayName": "17874",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "265",
          "protocol": "UDP",
          "upperPort": 0
        }
      ],
      "networkApplications": [
        {
          "id": "347e3dc4-6ae5-4daf-91e6-9da19d8d8e66",
          "instanceId": 16373,
          "authEntityId": 16373,
          "displayName": "16373",
          "authEntityClass": -217092956,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "appProtocol": "tcp/udp",
          "applicationGroup": "other",
          "applicationType": "DEFAULT",
          "categoryId": "1fc17362-9334-4c7b-b752-5afdbb574017",
          "createTime": 1517966744951,
          "encrypted": "false",
          "engineId": "3",
          "helpString": "Xbone CTL",
          "ignoreConflict": false,
          "lastUpdateTime": 1517966744951,
          "longDescription": "The X-Bone is a system for the automated deployment, management, coodination, and monitoring of IP overlay networks. Its core is composed of Overlay Managers (OM) which deploy and coordinate the overlay itself, and Resource Daemons (RD), which coordinate the resources of individual network components.  The X-Bone Control protocol is used for exchanges between the Overlay Manager and the Resource Daemons.",
          "name": "x-bone-ctl",
          "nbarId": "1137",
          "p2pTechnology": "false",
          "popularity": 1,
          "rank": 65535,
          "references": "http://www.isi.edu/xbone/information/architecture.html#ctl",
          "selectorId": "265",
          "subCategory": "f3c0f761-d894-4112-ab89-b90fc84fd660",
          "trafficClass": "OPS_ADMIN_MGMT",
          "tunnel": "false"
        }
      ],
      "networkIdentity": [
        {
          "id": "46211f2e-8184-4f36-88d9-12771138a5e3",
          "instanceId": 17872,
          "authEntityId": 17872,
          "displayName": "17872",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "265",
          "protocol": "UDP",
          "upperPort": 0
        },
        {
          "id": "2dae2a1e-8131-4463-a31c-f3ff79ff6521",
          "instanceId": 17871,
          "authEntityId": 17871,
          "displayName": "17871",
          "authEntityClass": 124090504,
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [

          ],
          "ipv6Subnet": [

          ],
          "lowerPort": 0,
          "ports": "265",
          "protocol": "TCP",
          "upperPort": 0
        }
      ],
      "parentScalableGroup": {
        "idRef": "5fcd74d4-8726-47f7-a088-c457863da7ef"
      },
      "user": [

      ],
      "userGroup": [

      ]
    }


]


 });