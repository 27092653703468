define({
"Template":
{
  "version": "1.0",
  "response": {
    "id": "AWjB2BDpsIPgh8_7Py0J",
    "name": "ap_ilm_event",
    "enabled": true,
    "flattened": true,
    "entityType": "Unknown",
    "entity": "Unknown",
    "groupBy": "Unknown",
    "category": "AP Anomaly",
    "severity": "MEDIUM",
    "summary": "AP DeviceNamePlaceHolder missed during beacon scan. No immediate action required. ",
    "scope": "LOCALIZED",
    "groupId": "ap_anomaly_grouping",
    "groupName": "AP Anomaly",
    "priority": "P3",
    "rootCause": "Unknown",
    "timestamp": 1549440384965,
    "description": "AP DeviceNamePlaceHolder missed during beacon scan. No immediate action required. ",
    "suggestions": [
      {
        "message": "If this issue happens multiple times, please reach out  Cisco TAC.",
        "steps": [

        ]
      },
      {
        "message": "Check if there is newer WLC image available from DNA Center Image management or cisco.com",
        "steps": [

        ]
      }
    ],
    "additionalParams": [
      {
        "key": "__key__",
        "value": "NetworkDevice:00:3A:7D:87:C3:80"
      },
      {
        "key": "eventId",
        "value": "00:3A:7D:87:C3:80-44397"
      },
      {
        "key": "ilmEventSubType",
        "value": "101"
      },
      {
        "key": "ilmEventType",
        "value": "1"
      },
      {
        "key": "clientMac",
        "value": ""
      },
      {
        "key": "bssid",
        "value": "00:3A:7D:87:C3:8F"
      },
      {
        "key": "__entity_type__",
        "value": "NetworkDevice"
      },
      {
        "key": "ilmEventTypeStr",
        "value": "AP_EVENT_TYPE_VAP"
      },
      {
        "key": "apMac",
        "value": "00:3A:7D:87:C3:80"
      },
      {
        "key": "type",
        "value": "issue"
      },
      {
        "key": "priority",
        "value": "P3"
      },
      {
        "key": "ilmEventSubTypeStr",
        "value": "AP_EVENT_VAP_SUBTYPE_BEACON_MISS"
      },
      {
        "key": "macAddress",
        "value": "00:3A:7D:87:C3:80"
      },
      {
        "key": "entityName",
        "value": "apIlmEvent"
      },
      {
        "key": "category",
        "value": "AP Anomaly"
      },
      {
        "key": "timestring",
        "value": "2019-02-06T08:06:24.965+0000"
      },
      {
        "key": "__suppression__",
        "value": "0"
      },
      {
        "key": "status",
        "value": "active"
      }
    ],
    "status": {
      "status": "ACTIVE",
      "updatedBy": "Unknown",
      "notes": "Unknown",
      "source": "Unknown",
      "updatedAt": null,
      "ignoreSource": "Unknown",
      "ignoreValue": "Unknown",
      "ignoreStartTime": null,
      "ignoreEndTime": null
    },
    "siteQulaifiedName": null
  }
}

})