define([
  'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/database/SimLokiDatabaseActions',
  'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/utilities/UtilityFunctions',
  'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/utilities/SimulationUtility',
  'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/assurance/issue1/DeviceIssueMapping',
  'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/assurance/issue1/HostIssueTimings_Devices'
], function(SimLokiDatabaseActions, UtilityFunctions,SimulationUtility,DeviceIssueMapping, HostIssueTimings_Devices){


var self = this;
var deviceRoles = ['CORE', 'ACCESS', 'DISTRIBUTION', 'ROUTER', 'WIRELESS', 'CP', 'BORDER'];
var WirelessFamilyMapping = {'Wireless Controller':'wlc', 'Unified AP':'ap'};
var explicitTopHealth = ["LAB-AP00F2.8B27.B788"]

var nwDeviceSummary = {
  startDate: '', endDate: '', isSiteIdGlobal: true, siteIds: [],
  monitoredDevices: 0, unMonitoredDevices: 0, latestHealthScore: 0,
  overallBadCount: 0, overallFairCount: 0, overallGoodCount: 0,
  BORDER: {Score: 0, notInFabricCnt: 0, TotalCount: 0, GoodCount: 0, FairCount: 0, BadCount: 0, UnmonCount: 0, badPercentage: 0,
         fairPercentage: 0, goodPercentage: 0},
  CP: {Score: 0, notInFabricCnt: 0, TotalCount: 0, GoodCount: 0, FairCount: 0, BadCount: 0, UnmonCount: 0, badPercentage: 0,
         fairPercentage: 0, goodPercentage: 0},
  CORE: {Score: 0, notInFabricCnt: 0, TotalCount: 0, GoodCount: 0, FairCount: 0, BadCount: 0, UnmonCount: 0, badPercentage: 0,
         fairPercentage: 0, goodPercentage: 0},
  ACCESS: {Score: 0, notInFabricCnt: 0, TotalCount: 0, GoodCount: 0, FairCount: 0, BadCount: 0, UnmonCount: 0, badPercentage: 0,
           fairPercentage: 0, goodPercentage: 0},
  DISTRIBUTION: {Score: 0, notInFabricCnt: 0, TotalCount: 0, GoodCount: 0, FairCount: 0, BadCount: 0, UnmonCount: 0, badPercentage: 0,
                 fairPercentage: 0, FairCount: 0, goodPercentage: 0},
  ROUTER: {Score: 0, notInFabricCnt: 0, TotalCount: 0, GoodCount: 0, FairCount: 0, BadCount: 0, UnmonCount: 0, badPercentage: 0,
           fairPercentage: 0, goodPercentage: 0},
  WIRELESS: {Score: 0, notInFabricCnt: 0, TotalCount: 0, GoodCount: 0, FairCount: 0, BadCount: 0, UnmonCount: 0, badPercentage: 0,
             fairPercentage: 0, goodPercentage: 0, wlcTotalCount: 0, apTotalCount: 0},
  OTHERS: {Score: -1, notInFabricCnt: -1, TotalCount: -1, GoodCount: -1, FairCount: -1, BadCount: -1, UnmonCount: -1, badPercentage: -1,
           fairPercentage: -1, goodPercentage: -1},
  network_devices: [], hostsList: [], ap_clientedConnected: [], mappedIssues:{},
  WLC: {Score: 0, notInFabricCnt: 0, TotalCount: 0, GoodCount: 0, FairCount: 0, BadCount: 0, UnmonCount: 0, badPercentage: 0,
    fairPercentage: 0, goodPercentage: 0},
  AP: {Score: 0, notInFabricCnt: 0, TotalCount: 0, GoodCount: 0, FairCount: 0, BadCount: 0, UnmonCount: 0, badPercentage: 0,
    fairPercentage: 0, goodPercentage: 0}
};

// removed from CAT 9K "GigabitEthernet0/0", "FortyGigabitEthernet1/1/1","FortyGigabitEthernet1/1/2",
var interfaces = {
    "BORDER ROUTER": ["TenGigabitEthernet1/0/2","Vlan100","Loopback0","TenGigabitEthernet1/0/24",
                      "TenGigabitEthernet1/0/4","TenGigabitEthernet1/0/3","TenGigabitEthernet1/0/1"],
    "DEFAULT": ["Ethernet5/2","Ethernet3/4","Ethernet3/15","Ethernet3/6","Ethernet4/2","Ethernet3/8","Ethernet3/2",
                "Loopback0","Tunnel0","GigabitEthernet1/1/1","GigabitEthernet1/0/15"],
    "CAT 9K": ["Vlan1021","Vlan1023","Vlan1022","Vlan1024","GigabitEthernet1/0/40","GigabitEthernet1/0/41",
                "GigabitEthernet1/0/42","GigabitEthernet1/0/43","GigabitEthernet1/0/44",
                "GigabitEthernet1/0/45","Loopback0","GigabitEthernet1/0/46","GigabitEthernet1/0/47","GigabitEthernet1/0/48",
                "GigabitEthernet1/1/4","GigabitEthernet1/1/2","GigabitEthernet1/1/3","GigabitEthernet1/1/1",
                 "GigabitEthernet1/0/30","GigabitEthernet1/0/31",
                "GigabitEthernet1/0/32","GigabitEthernet1/0/33","GigabitEthernet1/0/34","GigabitEthernet1/0/35",
                "GigabitEthernet1/0/36","GigabitEthernet1/0/37","GigabitEthernet1/0/38","GigabitEthernet1/0/39",
                "GigabitEthernet1/0/20","GigabitEthernet1/0/21","GigabitEthernet1/0/22","GigabitEthernet1/0/23",
                "GigabitEthernet1/0/24","GigabitEthernet1/0/25","GigabitEthernet1/0/26","GigabitEthernet1/0/27",
                "GigabitEthernet1/0/9","GigabitEthernet1/0/28","GigabitEthernet1/0/7","GigabitEthernet1/0/29",
                "GigabitEthernet1/0/8","GigabitEthernet1/0/5","TenGigabitEthernet1/1/8","TenGigabitEthernet1/1/7",
                "GigabitEthernet1/0/6","TenGigabitEthernet1/1/6","GigabitEthernet1/0/3","TenGigabitEthernet1/1/5",
                "GigabitEthernet1/0/4","GigabitEthernet1/0/1","TenGigabitEthernet1/1/4","TenGigabitEthernet1/1/3",
                "GigabitEthernet1/0/2","TenGigabitEthernet1/1/2","TenGigabitEthernet1/1/1","GigabitEthernet1/0/10",
                "GigabitEthernet1/0/11","GigabitEthernet1/0/12","GigabitEthernet1/0/13","GigabitEthernet1/0/14",
                "GigabitEthernet1/0/15","GigabitEthernet1/0/16","GigabitEthernet1/0/17","GigabitEthernet1/0/18",
                "GigabitEthernet1/0/19", "GigabiEthernet1/0/10"]
    };

var interfaceMetric = {
    "Ethernet1/1": {
        "avg": {"tx":55,"rx":40,"txErr":12,"rxErr":6},
        "max": {"tx":65,"rx":50,"txErr":17,"rxErr":12}
    },
    "Ethernet1/2": {
        "avg": {"tx":18,"rx":15,"txErr":25,"rxErr":15},
        "max": {"tx":28,"rx":25,"txErr":35,"rxErr":20}
    },
    "Ethernet2/5": {
        "avg": {"tx":25,"rx":23,"txErr":7,"rxErr":4},
        "max": {"tx":32,"rx":29,"txErr":10,"rxErr":8}
    },
    "Ethernet3/2": {
        "avg": {"tx":45,"rx":30,"txErr":10,"rxErr":5},
        "max": {"tx":55,"rx":40,"txErr":15,"rxErr":10}
    },
    "Ethernet3/4": {
        "avg": {"tx":25,"rx":23,"txErr":7,"rxErr":4},
        "max": {"tx":32,"rx":29,"txErr":10,"rxErr":8}
    },
    "Ethernet3/15": {
        "avg": {"tx":65,"rx":56,"txErr":10,"rxErr":5},
        "max": {"tx":69,"rx":40,"txErr":15,"rxErr":10}
    },
    "Ethernet5/2": {
        "avg": {"tx":25,"rx":23,"txErr":7,"rxErr":4},
        "max": {"tx":32,"rx":29,"txErr":10,"rxErr":8}
    },
    "FortyGigabitEthernet1/1/1": {
        "avg": {"tx":25,"rx":23,"txErr":7,"rxErr":4},
        "max": {"tx":32,"rx":29,"txErr":10,"rxErr":8}
    },
    "GigabitEthernet0": {
        "avg": {"tx":25,"rx":23,"txErr":7,"rxErr":4},
        "max": {"tx":32,"rx":29,"txErr":10,"rxErr":8}
    },
    "GigabitEthernet0/0": {
        "avg": {"tx":60,"rx":50,"txErr":15,"rxErr":10},
        "max": {"tx":65,"rx":44,"txErr":20,"rxErr":15}
    },
    "GigabitEthernet0/1/0": {
        "avg": {"tx":65,"rx":56,"txErr":10,"rxErr":5},
        "max": {"tx":69,"rx":40,"txErr":15,"rxErr":10}
    },
    "GigabitEthernet1/0/1": {
        "avg": {"tx":45,"rx":30,"txErr":10,"rxErr":5},
        "max": {"tx":55,"rx":40,"txErr":15,"rxErr":10}
    },
    "GigabitEthernet1/1/1": {
        "avg": {"tx":65,"rx":0,"txErr":80,"rxErr":75},
        "max": {"tx":69,"rx":0,"txErr":85,"rxErr":80}
    },
    "GigabitEthernet1/1/2": {
        "avg": {"tx":25,"rx":23,"txErr":7,"rxErr":4},
        "max": {"tx":32,"rx":29,"txErr":10,"rxErr":8}
    },
    "Loopback0": {
        "avg": {"tx":45,"rx":30,"txErr":10,"rxErr":5},
        "max": {"tx":55,"rx":40,"txErr":15,"rxErr":10}
    },
    "mgmt0": {
        "avg": {"tx":65,"rx":0,"txErr":80,"rxErr":75},
        "max": {"tx":69,"rx":0,"txErr":85,"rxErr":80}
    },
    "Port-channel10": {
        "avg": {"tx":65,"rx":0,"txErr":80,"rxErr":75},
        "max": {"tx":69,"rx":0,"txErr":85,"rxErr":80}
    },
    "TenGigabitEthernet1/0/1": {
        "avg": {"tx":20,"rx":17,"txErr":10,"rxErr":4},
        "max": {"tx":25,"rx":22,"txErr":12,"rxErr":8}
    },
    "TenGigabitEthernet1/0/3": {
        "avg": {"tx":25,"rx":23,"txErr":7,"rxErr":4},
        "max": {"tx":32,"rx":29,"txErr":10,"rxErr":8}
    },
    "TenGigabitEthernet1/0/5": {
        "avg": {"tx":20,"rx":15,"txErr":8,"rxErr":4},
        "max": {"tx":25,"rx":20,"txErr":12,"rxErr":8}
    },
    "TenGigabitEthernet1/0/24": {
        "avg": {"tx":45,"rx":30,"txErr":10,"rxErr":5},
        "max": {"tx":55,"rx":40,"txErr":15,"rxErr":10}
    },
    "Tunnel0": {
        "avg": {"tx":55,"rx":34,"txErr":12,"rxErr":6},
        "max": {"tx":62,"rx":38,"txErr":15,"rxErr":11}
    },
    "Vlan1": {
        "avg": {"tx":35,"rx":32,"txErr":12,"rxErr":6},
        "max": {"tx":40,"rx":36,"txErr":15,"rxErr":11}
    },
    "Vlan100": {
        "avg": {"tx":55,"rx":34,"txErr":12,"rxErr":6},
        "max": {"tx":62,"rx":38,"txErr":15,"rxErr":11}
    },
    "Vlan130": {
        "avg": {"tx":65,"rx":0,"txErr":80,"rxErr":75},
        "max": {"tx":69,"rx":0,"txErr":85,"rxErr":80}
    },//Latest Data
    "GigabitEthernet1/1": {
        "avg": {"tx":45,"rx":30,"txErr":10,"rxErr":5},
        "max": {"tx":55,"rx":40,"txErr":15,"rxErr":10}
    },
    "GigabitEthernet1/10": {
        "avg": {"tx":65,"rx":0,"txErr":80,"rxErr":75},
        "max": {"tx":69,"rx":0,"txErr":85,"rxErr":80}
    },
    "GigabitEthernet1/11": {
        "avg": {"tx":69,"rx":0,"txErr":50,"rxErr":35},
        "max": {"tx":49,"rx":0,"txErr":35,"rxErr":30}
    },
    "GigabitEthernet1/12": {
        "avg": {"tx":75,"rx":0,"txErr":55,"rxErr":40},
        "max": {"tx":55,"rx":0,"txErr":40,"rxErr":35}
    },
    "GigabitEthernet1/13": {
        "avg": {"tx":79,"rx":0,"txErr":59,"rxErr":45},
        "max": {"tx":60,"rx":0,"txErr":45,"rxErr":40}
    },
    "GigabitEthernet1/14": {
        "avg": {"tx":85,"rx":0,"txErr":65,"rxErr":50},
        "max": {"tx":65,"rx":0,"txErr":50,"rxErr":45}
    },
    "GigabitEthernet1/15": {
        "avg": {"tx":55,"rx":0,"txErr":50,"rxErr":35},
        "max": {"tx":35,"rx":0,"txErr":45,"rxErr":30}
    },
    "GigabitEthernet1/16": {
        "avg": {"tx":60,"rx":0,"txErr":60,"rxErr":75},
        "max": {"tx":40,"rx":0,"txErr":45,"rxErr":35}
    },
    "GigabitEthernet1/2": {
        "avg": {"tx":45,"rx":0,"txErr":80,"rxErr":75},
        "max": {"tx":49,"rx":0,"txErr":85,"rxErr":80}
    },
    "GigabitEthernet1/20": {
        "avg": {"tx":35,"rx":0,"txErr":80,"rxErr":75},
        "max": {"tx":29,"rx":0,"txErr":85,"rxErr":80}
    },
    "GigabitEthernet0/0/0" : {
        "avg": {"tx":45,"rx":0,"txErr":40,"rxErr":35},
        "max": {"tx":29,"rx":0,"txErr":45,"rxErr":40}
    },
    "GigabitEthernet0/0/1" : {
        "avg": {"tx":55,"rx":0,"txErr":50,"rxErr":45},
        "max": {"tx":39,"rx":0,"txErr":55,"rxErr":50}
    },
    "GigabitEthernet0/0/2" : {
        "avg": {"tx":65,"rx":0,"txErr":60,"rxErr":55},
        "max": {"tx":49,"rx":0,"txErr":65,"rxErr":60}
    },
    "GigabitEthernet0/0/3" : {
        "avg": {"tx":75,"rx":0,"txErr":70,"rxErr":65},
        "max": {"tx":59,"rx":0,"txErr":75,"rxErr":70}
    },
    "GigabitEthernet0/0/4" : {
        "avg": {"tx":85,"rx":0,"txErr":80,"rxErr":75},
        "max": {"tx":69,"rx":0,"txErr":85,"rxErr":80}
    },
    "GigabitEthernet0/0/5" : {
        "avg": {"tx":89,"rx":0,"txErr":85,"rxErr":80},
        "max": {"tx":70,"rx":0,"txErr":85,"rxErr":80}
    },
    "Loopback0": {
        "avg": {"tx":45,"rx":0,"txErr":80,"rxErr":75},
        "max": {"tx":49,"rx":0,"txErr":85,"rxErr":80}
    }, 
    "VoIP-Null0": {
        "avg": {"tx":55,"rx":0,"txErr":0,"rxErr":75},
        "max": {"tx":49,"rx":0,"txErr":0,"rxErr":80}
    },
    "management":{
        "avg": {"tx":55,"rx":0,"txErr":80,"rxErr":75},
        "max": {"tx":59,"rx":0,"txErr":85,"rxErr":80}
    }, 
    "TenGigabitEthernet0/0/1": {
        "avg": {"tx":45,"rx":0,"txErr":30,"rxErr":40},
        "max": {"tx":49,"rx":0,"txErr":35,"rxErr":50}
    },
    "TenGigabitEthernet0/0/2" : {
        "avg": {"tx":65,"rx":0,"txErr":35,"rxErr":45},
        "max": {"tx":69,"rx":0,"txErr":40,"rxErr":55}
    },
    "TenGigabitEthernet0/0/3": {
        "avg": {"tx":70,"rx":0,"txErr":40,"rxErr":50},
        "max": {"tx":75,"rx":0,"txErr":45,"rxErr":60}
    },
    "TenGigabitEthernet0/0/4":{
        "avg": {"tx":75,"rx":0,"txErr":45,"rxErr":55},
        "max": {"tx":80,"rx":0,"txErr":50,"rxErr":65}
    },
    "TenGigabitEthernet0/0/5":{
        "avg": {"tx":80,"rx":0,"txErr":50,"rxErr":60},
        "max": {"tx":85,"rx":0,"txErr":55,"rxErr":70}
    },
    "Virtual Interface": {
        "avg": {"tx":85,"rx":0,"txErr":50,"rxErr":55},
        "max": {"tx":90,"rx":0,"txErr":55,"rxErr":50}
    }
};

var apConfig =  {
    "Default": {
        "cpu": {"min":20,"max":40},
        "memory": {"min":20,"max":40},
        "airQuality_1": {"min":87,"max":93},
        "airQuality_2": {"min":88,"max":95},
        "itfValue_1": {"min":65,"max":75},
        "itfValue_2": {"min":10,"max":14},
        "noise_0": {"min":20,"max":40},
        "noise_1": {"min":20,"max":40}
    },
    "HighInterference_24": {
         "cpu": {"min":30,"max":40},
         "memory": {"min":43,"max":45},
         "airQuality_1": {"min":90,"max":95},
         "airQuality_2": {"min":88,"max":95},
         "itfValue_1": {"min":85,"max":97},
         "itfValue_2": {"min":10,"max":14},
         "noise_0": {"min":-70,"max":-90},
         "noise_1": {"min":-90,"max":-95}
    },
    "HighInterference_5": {
         "cpu": {"min":30,"max":40},
         "memory": {"min":43,"max":45},
         "airQuality_1": {"min":90,"max":95},
         "airQuality_2": {"min":88,"max":95},
         "itfValue_1": {"min":65,"max":75},
         "itfValue_2": {"min":70,"max":85},
         "noise_0": {"min":-70,"max":-90},
         "noise_1": {"min":-90,"max":-95}
    },
    "HighInterference_Both": {
         "cpu": {"min":30,"max":40},
         "memory": {"min":43,"max":45},
         "airQuality_1": {"min":90,"max":95},
         "airQuality_2": {"min":88,"max":95},
         "itfValue_1": {"min":85,"max":97},
         "itfValue_2": {"min":70,"max":85},
         "noise_0": {"min":-70,"max":-90},
         "noise_1": {"min":-90,"max":-95}
    }};


  function checkCacheNeedRefetching(self, startDate, endDate, filter) {

      if(self.cachedData == undefined || self.cachedData.startDate == '' || self.cachedData.endDate == '') {
          return true;
      }

      if(filter != undefined && filter.reloadNeeded) {
          return true;
      }

      var curStDate = new Date(startDate), curEndDate = new Date(endDate);
      var cachedStDate = new Date(self.cachedData.startDate), cachedEndDate = new Date(self.cachedData.endDate);

      //if(cachedStDate < curStDate && Math.round((curStDate-cachedStDate)/(1000*60*60*24)) == 1) {
      if(cachedStDate < curStDate) {
           return false;
      }

      if(curStDate.getHours() != cachedStDate.getHours() || curStDate.getDate() != cachedStDate.getDate() ||
          curStDate.getFullYear() != cachedStDate.getFullYear() || curStDate.getMonth() != cachedStDate.getMonth()) {
           return true;
      }

      if(curEndDate.getHours() != cachedEndDate.getHours() || curEndDate.getDate() != cachedEndDate.getDate() ||
          curEndDate.getFullYear() != cachedEndDate.getFullYear() || curEndDate.getMonth() != cachedEndDate.getMonth()){
           return true;
      }

       return false;
  }

  function adjustCachedDate(self, startDate, endDate) {
      //var startDate, endDate;
      if(self.cachedData == undefined || startDate == undefined || self.cachedData.startDate == '' ||
          self.cachedData.endDate == '') {
          var tDate = new Date(startDate);
          var tDate1 = UtilityFunctions.getDateInTimestamp_SubtractedByDays_FromCurrentDate(1);
          startDate = tDate > tDate1 ? tDate1 : tDate;

          endDate = new Date();
      } //else {
      /*var curStDate = new Date(startDate), curEndDate = new Date(endDate);
      var cachedStDate = new Date(self.cachedData.startDate), cachedEndDate = new Date(self.cachedData.endDate);*/

      self.cachedData.startDate = startDate;
      self.cachedData.endDate = endDate;
      //}
  }

  function getDevicesAssociatedWithFabric(filter) {
      var t = filter.fabricName;
      var tDeviceInfo = SimLokiDatabaseActions.getAll('DeviceInfo');
      var fabricObject = SimLokiDatabaseActions.getFilteredRecordHandler('ConnectivityDomain',{'name': t});
      if(fabricObject.length == 0) {
          var fabricObjects = SimLokiDatabaseActions.getFilteredRecordHandler('ConnectivityDomain', {'domainType':'FABRIC_LAN'});
          fabricObject = fabricObjects.filter(function(itm){ return (itm.name.split('_').join(' ')) == t }); //logic picked from SimAssuranceModelExtended file
          if(fabricObject.length == 0 ) {
              return [];
          }
      }

      var allDevices =  SimLokiDatabaseActions.getAll('network-device');
      var tDevices = [], result = [];
      if(fabricObject[0].domainType == 'FABRIC_LAN') {
          var siteSpecificDomains = fabricObject[0].siteSpecificDomain;
          if(siteSpecificDomains.length == 0) { return []; }
          var siteSpecificDomainIds = [];
          siteSpecificDomains.forEach(function(itm) { siteSpecificDomainIds.push(itm.idRef); });
          var fabricSiteDomains = SimLokiDatabaseActions.getFilteredRecordHandler('ConnectivityDomain',{'id': {'$in':siteSpecificDomainIds} });
          for(var i=0; i<fabricSiteDomains.length; i++) {
            tDevices = tDevices.concat(fabricSiteDomains[i].deviceInfo);
          }
      } else {
          tDevices = fabricObject[0].deviceInfo;
      }
      for(var i =0; i < tDevices.length; i++) {
          var networkDeviceId = '';
          if(tDevices[i].networkDeviceId != undefined) {
             networkDeviceId =  tDevices[i].networkDeviceId;
          } else {
              var obj = tDeviceInfo.filter(function(itm){return itm.id == tDevices[i].idRef || itm.id == tDevices[i] });
              if(obj.length) {
                 networkDeviceId = obj[0].networkDeviceId;
              }
          }

          var obj = allDevices.filter(function(itm){ return itm.id == networkDeviceId });
          if(obj.length) {
              result.push(obj[0]);
          }
      }
      return result;
  }

  function getDevicesAssociatedWithSites(filter) {
      var siteIdList =[];
      for(var k=0;k<filter.sites.length;k++){
        var childrenSites=[];
        SimulationUtility.getChildrenSites(filter.sites[k],childrenSites);
        siteIdList.push(filter.sites[k]);
        for(var i=0;i<childrenSites.length;i++) {
            siteIdList.push(childrenSites[i].id);
        }
      }
      var deviceList = SimLokiDatabaseActions.getFilteredRecordHandler('network-device',  {
                                                                      'siteId': { '$containsAny' :  siteIdList}
                                                                    });
      return deviceList;
  }

  function getDevicesBasedOnFilter(filter) {
     if(filter.measureBy == 'fabric') {
         return getDevicesAssociatedWithFabric(filter);
     } else if(filter.measureBy == 'sites') {
          return getDevicesAssociatedWithSites(filter);
     } else {
         var allDevices =  SimLokiDatabaseActions.getAll('network-device');
         return allDevices;
     }
  }

  function roleMappingBasedOnSite(role, family) {
      var tRole = WirelessFamilyMapping[family] != undefined ? WirelessFamilyMapping[family] : role;
      if(tRole == 'BORDER ROUTER')
          tRole = 'ROUTER'
      return [{role: tRole, isWireless: WirelessFamilyMapping[family] != undefined ? true : false}];
  }

  function roleMappingBasedOnFabric(role, family, deviceId) {
      var tRole = [], isWireless = WirelessFamilyMapping[family] != undefined ? true : false;
      var obj = SimLokiDatabaseActions.getFilteredRecordHandler('DeviceInfo',{'networkDeviceId': deviceId});

      if(obj.length) {
          if(obj[0].roles.indexOf('EDGENODE') >= 0) {
              tRole.push({role:"ACCESS",isWireless:false});
              // else conditions commented by Karan for getting SFO15 data
          } /* else if(obj[0].roles.indexOf('ENDPOINT_PROXY') >= 0) {
              tRole.push({role:"WLC",isWireless:true});
          } */
          else {
            if(obj[0].roles.indexOf('ENDPOINT_PROXY') >= 0) {
                    tRole.push({role:"WLC",isWireless:true});
                }
            if(obj[0].roles.indexOf('BORDERNODE') >= 0) {
                tRole.push({role:"BORDER",isWireless:isWireless});
            }

            if(obj[0].roles.indexOf('MAPSERVER') >= 0) {
                tRole.push({role:"CP",isWireless:isWireless});
            }
          }
      }
      return tRole;
  }

  function roleMapping(role, family, deviceId, prepareCacheForFabric) {
      if(prepareCacheForFabric) {
          return roleMappingBasedOnFabric(role, family, deviceId);
      } else {
          return roleMappingBasedOnSite(role, family);
      }
  }

  function getScoreBasedOnDeviceRole(self, deviceRole, deviceObj, devicesWithIssues, prepareCacheForFabric,prepareCacheForSite, isInitHealth) {
      var min, max, scoreType, score;
      if(self.isInit == undefined){
          if(self.cachedData.highInterfaceApList.indexOf(deviceObj.id)>=0 || deviceObj.hostname==="SJC01_9136_12") {
              min = 4; max =7;
              scoreType = "FairCount";

          } else if(devicesWithIssues.indexOf(deviceObj.managementIpAddress) >= 0 || devicesWithIssues.indexOf(deviceObj.macAddress.toUpperCase()) >= 0 || deviceObj.reachabilityStatus== "UnReachable") {
                  min = 1; max = 3;
                  scoreType = "BadCount";
          } else {
              if(self.cachedData[ deviceRole ].TotalCount == 0 && ! prepareCacheForFabric) {
                  min = 4; max =7;
                  scoreType = "FairCount";
              } else {
                  min = 8; max = 10;
                  if(explicitTopHealth.indexOf(deviceObj.hostname)>-1){min = 10; max = 10}
                  scoreType = "GoodCount";
              }
          }
          //Below line is to make SJC01_9136_1 as good device, this needs need to fix later
          deviceObj.hostname ==="SJC01_9136_1" ? score = UtilityFunctions.getRandomIntForRangeValues(8, 10) : score = UtilityFunctions.getRandomIntForRangeValues(min, max);
          if(HostIssueTimings_Devices.Modified_TimeTravel[deviceObj.hostname]!=undefined && 
            HostIssueTimings_Devices.Modified_TimeTravel[deviceObj.hostname].defHealth!=undefined) {
            score = HostIssueTimings_Devices.Modified_TimeTravel[deviceObj.hostname].defHealth;
            scoreType = score>7 ? "GoodCount" : (score>3 ? "FairCount" : "BadCount");
          }
      }else {
             if(deviceObj.assuranceHealthScore>=8)  {
                  score= deviceObj.assuranceHealthScore;
                  scoreType = "GoodCount";
             } else if(deviceObj.assuranceHealthScore>=4) {
                 score= deviceObj.assuranceHealthScore;
                  scoreType = "FairCount";
             } else if(deviceObj.assuranceHealthScore<=3) {
                  score= deviceObj.assuranceHealthScore;
                  scoreType = "BadCount";
             }
      }
      return {score:score,scoreType:scoreType};
  }

  function prepareDeviceCaching(self, devices, devicesWithIssues, prepareCacheForFabric,prepareCacheForSite) {
      var i, iLen = devices.length, score, isInitHealth = false;
      if(!getCookie("initHealthScores")) {
        console.log('initializing health scores..');
        isInitHealth = true;
      }
      for(var i = 0; i < iLen; i++) {
          var tRoles = roleMapping(devices[i].role, devices[i].family, devices[i].id, prepareCacheForFabric);
          if(tRoles.length == 0) {
              continue;
          }
          var tScore = getScoreBasedOnDeviceRole(self, tRoles[0].role.toUpperCase(), devices[i], devicesWithIssues, prepareCacheForFabric,prepareCacheForSite,isInitHealth);
          if(prepareCacheForFabric) {
              devices[i].role = [];
          }

            devices[i].assuranceHealthScore = tScore.score;

          self.cachedData.network_devices.push(devices[i]);

          for(var j = 0; j < tRoles.length; j++) {
              var curRole = tRoles[j].role.toUpperCase();
              self.cachedData[ curRole ][tScore.scoreType]++;
              if(tRoles[j].isWireless) {
                  self.cachedData[ 'WIRELESS' ][tScore.scoreType]++;
                  self.cachedData[ 'WIRELESS' ]['TotalCount']++;
              }
              self.cachedData[ curRole ].TotalCount++;
              if(devices[i].siteId == "") {
                  self.cachedData[ curRole ].notInFabricCnt++;
                  if(tRoles[j].isWireless) {
                      self.cachedData[ 'WIRELESS' ].notInFabricCnt++;
                  }
              }
              if(prepareCacheForFabric) {
                  devices[i].role.push(curRole);
              }
          }
      }
      if(isInitHealth) {
        document.cookie = "initHealthScores=true";
        isInitHealth = false;
      }
      //self.isInit = false;
      var grandTotalCount = 0, grandTotalGoodCount = 0, grandTotalFairCount = 0, grandTotalBadCount = 0;
      for(i = 0; i < deviceRoles.length; i++) {
          var tRole = deviceRoles[i];
          var badRolePercentage = -1, goodRolePercentage = -1, fairRolePercentage = -1, tScore = -1;
          var GoodRoleCount = -1; totalRoleCount = 0; BadRoleCount = -1; FairRoleCount = -1, notInFabricCnt = -1;
          if(self.cachedData[ tRole ].TotalCount) {
              //some devices exists for this role..
              var totalRoleCount = self.cachedData[ tRole ].TotalCount;

              badRolePercentage = Math.round((self.cachedData[ tRole ].BadCount / totalRoleCount) * 100);
              goodRolePercentage = Math.round((self.cachedData[ tRole ].GoodCount / totalRoleCount) * 100);
              fairRolePercentage =  Math.round((self.cachedData[ tRole ].FairCount / totalRoleCount) * 100);
              tScore = Math.round( (self.cachedData[ tRole ].GoodCount / totalRoleCount) * 100);

              grandTotalCount += self.cachedData[ tRole ].TotalCount;
              grandTotalGoodCount += self.cachedData[ tRole ].GoodCount;
              grandTotalFairCount += self.cachedData[ tRole ].FairCount;
              grandTotalBadCount += self.cachedData[ tRole ].BadCount;
          }
          self.cachedData[ tRole ].Score = tScore;
          self.cachedData[ tRole ].badPercentage = badRolePercentage;
          self.cachedData[ tRole ].fairPercentage =  fairRolePercentage;
          self.cachedData[ tRole ].goodPercentage = goodRolePercentage;
          //self.cachedData[ tRole ].notInFabricCnt = notInFabricCnt;
      }

      var tScore = Math.round( (grandTotalGoodCount / grandTotalCount) * 100);
      self.cachedData.latestHealthScore = tScore;

      self.cachedData.monitoredDevices = grandTotalCount;
      self.cachedData.overallGoodCount = grandTotalGoodCount;
      self.cachedData.overallFairCount = grandTotalFairCount;
      self.cachedData.overallBadCount = grandTotalBadCount;
  }

  getDeviceStatHandler = {
      wlcStats: function(devices) {
          var tWlc = devices.filter(function(itm){ return itm.family == 'Wireless Controller';});
          return getStats(tWlc);
      },
      apStats: function(devices) {
          var tAP = devices.filter(function(itm){ return itm.family == 'Unified AP'; });
          return getStats(tAP);
      },
      cpStats: function(devices) {
          var tCP;
          return getStats(tCP);
      },
      borderStats: function(devices) {
          var tBorder;
          return getStats(tBorder);
      }
  };

    function getCookie(cname) {
        var name = cname + "=";
        var ca = document.cookie.split(';');
        for (var i = 0; i < ca.length; i++) {
            var c = ca[i];
            while (c.charAt(0) == ' ') c = c.substring(1);
            if (c.indexOf(name) != -1) return c.substring(name.length, c.length);
        }
        return false;
    };

  return {
    init: function(){
        let endDate = UtilityFunctions.getTimeStamp()
        let startDate = UtilityFunctions.getTimeStamp() - (3*60*60*1000)
        this.buildAssuranceCache(startDate, endDate)
    },

    getCacheData: function(urlAction) {
            if(this.cachedData == undefined){
                return false;
            }else{
                return  JSON.parse(JSON.stringify(this.cachedData));
            }
    },

    buildAssuranceCache: function(startDate, endDate, filter) {

        var devices, prepareCacheForFabric = false,prepareCacheForSite=false, mappedIssues;
        if(checkCacheNeedRefetching(this, startDate, endDate, filter) == false) {
            return;
        }

        this.cachedData = JSON.parse(JSON.stringify(nwDeviceSummary));
        adjustCachedDate(this, startDate, endDate);

        if(filter != undefined && filter.measureBy == 'sites' && 
             (filter.sites == undefined || filter.sites.length == 0)) {
            filter.measureBy = 'global';
        }

        if(filter == undefined || filter.measureBy == 'global') {
            devices =  SimLokiDatabaseActions.getAll('network-device');
        } else {
            devices = getDevicesBasedOnFilter(filter);
            prepareCacheForFabric = filter.measureBy == 'fabric' ? true : false;
            prepareCacheForSite = filter.measureBy == 'sites' ? true : false;
        }
        devices = JSON.parse(JSON.stringify(devices));
        mappedIssues=DeviceIssueMapping.issues;

        this.cachedData.mappedIssues=mappedIssues;
        var tAP = devices.filter(function(itm){ return itm.family == 'Unified AP'; });

        this.cachedData.ap_clientedConnected = getClientConnectedToAP(tAP);

        this.cachedData.highInterfaceApList24=getHighInterfaceAPList(this,"2.4");

        this.cachedData.highInterfaceApList5=getHighInterfaceAPList(this,"5.0");

        this.cachedData.highInterfaceApList=getHighInterfaceAP(this.cachedData.highInterfaceApList24,this.cachedData.highInterfaceApList5);


        var devicesWithIssues = getBadDevicesInTimeRange(this.cachedData.startDate, this.cachedData.endDate);
        prepareDeviceCaching(this, devices, devicesWithIssues, prepareCacheForFabric,prepareCacheForSite);

        var tWlc = devices.filter(function(itm){ return itm.family == 'Wireless Controller';});
        this.cachedData[ 'WIRELESS' ].wlcTotalCount = tWlc.length;

        this.cachedData[ 'WIRELESS' ].apTotalCount = tAP.length;




        this.cachedData['WLC'] = getDeviceStatHandler['wlcStats'](devices);
        this.cachedData['AP'] = getDeviceStatHandler['apStats'](devices);

        var tHosts =  SimLokiDatabaseActions.getAll('host');
        this.cachedData.hostsList = JSON.parse(JSON.stringify(tHosts));

        this.cachedData.interfaces = interfaces;
        this.cachedData.interfaceMetricData = interfaceMetric;
        this.cachedData.apConfig = apConfig;
        this.cachedData.fabricName = (filter)?filter.fabricName: '';

        if(filter == undefined || filter.measureBy == 'global')  {

            SimLokiDatabaseActions.update('network-device', devices);
        }

        /*console.log('---------------------------------------------');
        console.log(this.cachedData);
        console.log('---------------------------------------------');*/
    }
  };

  function getHighInterfaceAP(aplist24,aplist5) {
   var aplist=[];

       for(var i=0;i<aplist24.length;i++) {
        aplist.push(aplist24[i].id);
       }
       for(var i=0;i<aplist5.length;i++) {
        aplist.push(aplist5[i].id);
       }

   return aplist;

  }

  function getBadDevicesInTimeRange(startDate, endDate) {
      //above step devices received, query issues for the devices which falls under this range, make a hash or cache of
      //these devices, to be used as any device fall under these as bad device and score of that device as well
      var issues =  SimLokiDatabaseActions.getAll('issue');
      
      var customIssues = SimLokiDatabaseActions.getAll('customIssue');
      issues = issues.concat(getCustomIssueAfter5Min(customIssues));

      var tStartDate = new Date(startDate);

      var tIssueIds = issues.filter(function(itm) {
                          //return itm.entityType == "Device"}).map(function(itm) { return itm.entity;});
                          var tTimeStamp = new Date(itm.timestamp);
                          return itm.entityType == "Device" &&  tTimeStamp > tStartDate }).map(function(itm) { return itm.entity;});

      return tIssueIds;
  }

  function getDeviceScore(devicesList, indx) {
      var score;
      //UI graph to look attractive making if more than one device for any role, mark first device health as fair..
      if(indx == 0 && devicesList.length > 1) {
          score = UtilityFunctions.getRandomIntForRangeValues(4, 7);
      } else {
          score = UtilityFunctions.getRandomIntForRangeValues(8, 10);
      }
      return score;
  }

  function getClientConnectedToAP(apList) {
      var result = [];
      for(var i = 0; i < apList.length; i++) {
          var hosts = SimLokiDatabaseActions.getFilteredRecordHandler('host',{'connectedNetworkDeviceId': apList[i].id });
          var obj = {"id":apList[i].id,"name":apList[i].hostname,"count":hosts.length};
          result.push(obj);
      }
      return result;
  }

  function sortByProperty (property) {

    return function (x, y) {

        return ((x[property] === y[property]) ? 0 : ((x[property] < y[property]) ? 1 : -1));

    };

   };

  function getHighInterfaceAPList(self,frequency){
    recordObj=self.cachedData.ap_clientedConnected;
    recordObj.sort(sortByProperty("count"));
    var apList = []
    for(var i = 0; i < recordObj.length; i++){

     var client_List = SimLokiDatabaseActions.getFilteredRecordHandler('host',{'$and': [ {
                                                                                              'connectedNetworkDeviceId': recordObj[i].id},
                                                                                               { 'frequency':frequency }]});
      if(client_List.length>0 && apList.length<2) {

           if(frequency == "2.4"){
            var value = 80 -(i*4);
           }
           else{
            var value = 80 -((i+1)*20);
           }

           apList.push({"id":recordObj[i].id, "name":recordObj[i].name,"type": frequency, "count":value});

           var hostIssueTime =  SimLokiDatabaseActions.getFilteredRecordHandler('sim-issue-timing-device', { 'hostName': recordObj[i].name });
           if(hostIssueTime.length==0) {
            var start =UtilityFunctions.getRandomIntForRangeValues(3,15);
             SimLokiDatabaseActions.insert('sim-issue-timing-device', {"hostName":recordObj[i].name,"start" : start,"end" : start+5,graphStartDateTime:0});

           }
      }
    }
       return apList;
  }

  function getStats(someDeviceTypeList) {
      var GoodCount = 0, TotalCount = someDeviceTypeList.length, FairCount = 0, BadCount = 0, notInFabricCnt = 0;
      for(var i = 0; i < TotalCount; i++) {
          var score = someDeviceTypeList[i].assuranceHealthScore;
          if(score >= 1 && score <= 3) {
              BadCount++;
          } else if(score >= 4 && score <= 7) {
              FairCount++;
          } else if(score >= 8 && score <= 10) {
              GoodCount++;
          }

          notInFabricCnt = someDeviceTypeList[i].siteId != '' ? ++notInFabricCnt : notInFabricCnt;
      }
      //Add for 1.2.4
      var tScore = Math.round( (GoodCount / TotalCount) * 100);
      var badRolePercentage = Math.round((BadCount / TotalCount) * 100);
      var goodRolePercentage = Math.round((GoodCount / TotalCount) * 100);
      var fairRolePercentage =  Math.round((FairCount / TotalCount) * 100);
      return {Score: tScore,badPercentage:badRolePercentage,goodPercentage:goodRolePercentage,
              fairPercentage:fairRolePercentage, notInFabricCnt: notInFabricCnt, TotalCount: TotalCount,
              GoodCount: GoodCount, FairCount: FairCount, BadCount: BadCount};
  }

  function getCustomIssueAfter5Min(customIssues) {
    var matchingIssues=[];
    var currDate = UtilityFunctions.getTimeStamp();
    for(var i=0;i<customIssues.length; i++) {
        var delayedTime = UtilityFunctions.incrementMinutes(customIssues[i].timestamp, 5);
        if(new Date(currDate) >= new Date(delayedTime)) {
            matchingIssues.push(customIssues[i]);
        }
    }
    return matchingIssues;
  }

});
