define({
"Template":
{
  "version": "1.0",
  "response": {
    "id": "AWjwXsGyiN3yBbjMOeXM",
    "name": "device_time_drift",
    "enabled": true,
    "flattened": true,
    "entityType": "network_device",
    "entity": "10.13.6.2",
    "groupBy": "Unknown",
    "category": "Device",
    "severity": "HIGH",
    "summary": "Excessive time lag between Cisco DNA Center and Device DeviceIPPlaceHolder ",
    "scope": "LOCALIZED",
    "priority": "P3",
    "groupId": "device_time_grouping",
    "groupName": "Device time has drifted from DNAC",
    "rootCause": "Unknown",
    "timestamp": 1550220932000,
    "description": "The time on Cisco DNA Center and Device DeviceNamePlaceHolder has drifted too far apart. Cisco DNA Center cannot process the device data accurately if the time difference is more than 3 minutes.",
    "suggestions": [
      {
        "message": "Check time on the Device",
        "steps": [
          {
            "entityId": "DeviceIdPlaceHolder",
            "description": "Check system time",
            "command": "show clock",
            "stepType": "command-Runner"
          }
        ]
      },
      {
        "message": "If NTP is enabled, check whether the NTP servers are reachable from Cisco DNA Center and the Device",
        "steps": [
          {
            "entityId": "DeviceIdPlaceHolder",
            "description": "Check NTP Status",
            "command": "show ntp status",
            "stepType": "command-Runner"
          }
        ]
      },
      {
        "message": "If NTP servers are not configured, configure the NTP servers on Cisco DNA Center and Device ",
        "steps": [

        ]
      },
      {
        "message": "If NTP servers are not deployed, manually reset the time on Cisco DNA Center or Device so that the time is synchronized",
        "steps": [

        ]
      },
      {
        "message": "Contact Cisco TAC for support – https://www.cisco.com/c/en/us/support/web/tsd-cisco-worldwide-contacts.html",
        "steps": [

        ]
      }
    ],
    "additionalParams": [
      {
        "key": "__key__",
        "value": "NetworkDevice:10.13.6.2"
      },
      {
        "key": "managementIpAddr",
        "value": "10.13.6.2"
      },
      {
        "key": "entityType",
        "value": "network_device"
      },
      {
        "key": "__entity_type__",
        "value": "NetworkDevice"
      },
      {
        "key": "entityId",
        "value": "10.13.6.2"
      },
      {
        "key": "deviceFamily",
        "value": "Switches and Hubs"
      },
      {
        "key": "platformId",
        "value": "C9300-48UXM"
      },
      {
        "key": "type",
        "value": "Issue"
      },
      {
        "key": "deviceTime",
        "value": "1550221188000"
      },
      {
        "key": "priority",
        "value": "P1"
      },
      {
        "key": "capturedTime",
        "value": "1550220932000"
      },
      {
        "key": "category",
        "value": "Device"
      },
      {
        "key": "timestring",
        "value": "2019-02-15T08:55:32.000+0000"
      },
      {
        "key": "__suppression__",
        "value": "3600"
      },
      {
        "key": "status",
        "value": "active"
      }
    ],
    "status": {
      "status": "ACTIVE",
      "updatedBy": "Unknown",
      "notes": "Unknown",
      "source": "Unknown",
      "updatedAt": null,
      "ignoreSource": "Unknown",
      "ignoreValue": "Unknown",
      "ignoreStartTime": null,
      "ignoreEndTime": null
    },
    "siteQulaifiedName": null
  }
}
})