define([
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/utilities/UtilityFunctions',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/database/SimLokiDatabaseActions',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/data-generation/configuration/DefaultConfig',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/data-generation/configuration/SimDefaultDataGenerator',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/topology/TopologyNodeTemplate',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/topology/TopologyLinkTemplate',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/topology/TopologyHostLinkTemplate',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/topology/TopologyHostTemplate',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/topology/assurance/TopologyControllerAppTemplate',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/topology/assurance/TopologyControllerPageTemplate',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/topology/assurance/TopologyVlanNamesTemplate',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/topology/assurance/TopologyVrfNamesTemplate',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/topology/TopologySiteTemplate',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/data-generation/configuration/SimTaskDataGenerator',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/topology/assurance/SimCustomTopologyView',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/utilities/SimulationUtility'
], function(UtilityFunctions, SimLokiDatabaseActions, DefaultConfig, SimDefaultDataGenerator,
            TopologyNodeTemplate, TopologyLinkTemplate, TopologyHostLinkTemplate, TopologyHostTemplate,
            TopologyControllerAppTemplate, TopologyControllerPageTemplate, TopologyVlanNamesTemplate,
            TopologyVrfNamesTemplate, TopologySiteTemplate, SimTaskDataGenerator, SimCustomTopologyView, SimulationUtility){
  return {
      init: function(){
          var devices = SimDefaultDataGenerator.getNetworkDevices(DefaultConfig.Ignore_Device_In_Topology);
          var len = devices.length;
          var i, j;
          var root_id = UtilityFunctions.generate_uuid();
          var topolgoyData = { nodes : [], links : [] }
          var nodes = [], links = [];
          var cloudnodeUuid = DefaultConfig.DEFAULT_UUID.TopologyCloudNode;

          var node_mapping = new Object();
          var host_connected_device_mapping = new Object();

          if(DefaultConfig.NetworkDevice.CloudNodeEnabled){
              var tJson = JSON.parse(JSON.stringify(DefaultConfig.TopologyCloudNode));
              tJson.id = cloudnodeUuid;
              SimLokiDatabaseActions.insert( 'physical-topology', tJson);
          }

          //topology host related changes...
          var recordObj = SimLokiDatabaseActions.getAll('host');
          var ii, len1;
          for(ii = 0, len1 = recordObj.length; ii < len1; ii++){
              host_connected_device_mapping[recordObj[ii].id] = recordObj[ii].connectedNetworkDeviceId;
              createHostInTopology(recordObj[ii], (ii + 1).toString() );
          }

          for(i=0;  i<len; i++) {
              var iStr = (i + 1).toString();

              var t_Json = JSON.parse(JSON.stringify(TopologyNodeTemplate.Topology_Node_Template));
              t_Json.deviceType = devices[i].type;

              t_Json.label = devices[i].hostname;
              t_Json.ip = devices[i].managementIpAddress;
              t_Json.softwareVersion = devices[i].swversion;
              t_Json.nodeType = "device";
              t_Json.family = devices[i].family;
              t_Json.platformId = devices[i].platformid;
              t_Json.tags = [];
              t_Json.role = devices[i].role;
             // t_Json.role = devices[i].deviceDefinedAs.toUpperCase();
              t_Json.roleSource = "AUTO";
              t_Json.customParam = {};
              t_Json.additionalInfo = {};
              t_Json.id = devices[i].id;
              t_Json.topology_type = "node";

              t_Json.deviceSeries =  devices[i].series;
              t_Json.managementIpAddress = devices[i].managementIpAddress;

              t_Json.healthScore = 5;
              t_Json.vrf = devices[i].vrf==undefined? "" : devices[i].vrf;
              t_Json.vlan = devices[i].vlan==undefined? "" : devices[i].vlan;
              t_Json.routing = devices[i].routing==undefined? "" : devices[i].routing;

              SimLokiDatabaseActions.insert( 'physical-topology', t_Json);

              if(devices[i].deviceDefinedAs == "edge") {
                  if(node_mapping["EDGENODE"] == undefined) {
                      node_mapping["EDGENODE"] = { ids: [] };
                  }
                  node_mapping["EDGENODE"].ids.push(devices[i].id);
              }
              if(devices[i].deviceDefinedAs == "core") {
                  if(node_mapping["CORENODE"] == undefined) {
                      node_mapping["CORENODE"] = { ids: [] };
                  }
                  node_mapping["CORENODE"].ids.push(devices[i].id);
              }
              if(devices[i].deviceDefinedAs == "distribution") {
                  if(node_mapping["DISTNODE"] == undefined) {
                      node_mapping["DISTNODE"] = { ids: [] };
                  }
                  node_mapping["DISTNODE"].ids.push(devices[i].id);
              }
              if(devices[i].deviceDefinedAs == "access") {
                  if(node_mapping["ACCESSNODE"] == undefined) {
                      node_mapping["ACCESSNODE"] = { ids: [] };
                  }
                  node_mapping["ACCESSNODE"].ids.push(devices[i].id);
              }
              if(devices[i].deviceDefinedAs == "wlc") {
                  if(node_mapping["WLCNODE"] == undefined) {
                      node_mapping["WLCNODE"] = { ids: [] };
                  }
                  node_mapping["WLCNODE"].ids.push(devices[i].id);
              }

              if(devices[i].deviceDefinedAs == 'ap' || devices[i].deviceDefinedAs == 'sensor' ) {
                  if(node_mapping["APNODE"] == undefined) {
                      node_mapping["APNODE"] = { ids: [] };
                  }
                  node_mapping["APNODE"].ids.push(devices[i].id);
              }
          }

          if(DefaultConfig.NetworkDevice.CloudNodeEnabled){
              for(i =0; i < node_mapping["EDGENODE"].ids.length; i++) {
                  var targetDeviceId = node_mapping["EDGENODE"].ids[i];
                  if(isNeededToConnectToCloudNode(devices, targetDeviceId)) {
                      var t_linkJson = JSON.parse(JSON.stringify(TopologyLinkTemplate.Topology_Link_Template));
                      t_linkJson.source = cloudnodeUuid;
                      t_linkJson.target = targetDeviceId;
                      t_linkJson.topology_type = "link";
                      SimLokiDatabaseActions.insert( 'physical-topology', t_linkJson);
                  }
              }

              addExceptionConnectOtherNodeToCloudNode(cloudnodeUuid, devices);
          }
          if(node_mapping["EDGENODE"] != undefined){
              for(i =0; i < node_mapping["EDGENODE"].ids.length; i++) {
                  for(j = Math.floor(i/2)*2; j < (Math.floor(i/2)*2 + 2) && j < node_mapping["CORENODE"].ids.length; j++) {
                      var t_linkJson = JSON.parse(JSON.stringify(TopologyLinkTemplate.Topology_Link_Template));
                      t_linkJson.source = node_mapping["EDGENODE"].ids[i];
                      t_linkJson.target = node_mapping["CORENODE"].ids[j];
                      t_linkJson.topology_type = "link";
                      // checking whether both devices(source,target) belong to same location.
                      var sourceNetworkObj = SimLokiDatabaseActions.getFilteredRecordHandler('network-device', {'id': t_linkJson.source});
                      var targetNetworkObj = SimLokiDatabaseActions.getFilteredRecordHandler('network-device', {'id': t_linkJson.target});
                      //if it is part of child, we shall proceed to link
                      let srcChildren=[], tgtChildren=[];
                      srcChildren = SimulationUtility.getChildrenSites(sourceNetworkObj[0].siteId, srcChildren)
                      tgtChildren = SimulationUtility.getChildrenSites(targetNetworkObj[0].siteId, tgtChildren)
                      srcChildren = srcChildren.map(e=>e.id), tgtChildren = tgtChildren.map(e=>e.id);
                      if(sourceNetworkObj.length>0 && targetNetworkObj.length>0 && (sourceNetworkObj[0].siteId ==targetNetworkObj[0].siteId || 
                      srcChildren.indexOf(targetNetworkObj[0].siteId) > -1 || tgtChildren.indexOf(sourceNetworkObj[0].siteId) > -1) ) {
                      //if(sourceNetworkObj.length>0 && targetNetworkObj.length>0 && sourceNetworkObj[0].siteId ==targetNetworkObj[0].siteId) {
                          SimLokiDatabaseActions.insert( 'physical-topology', t_linkJson);
                      }
                  }
              }

              //As a temp woraround added on 12 oct 2018 -- In SCJ06 edge to core link below code adds..
            /*  var t_linkJson = JSON.parse(JSON.stringify(TopologyLinkTemplate.Topology_Link_Template));
              t_linkJson.source = node_mapping["EDGENODE"].ids[6];
              t_linkJson.target = node_mapping["CORENODE"].ids[3];
              t_linkJson.topology_type = "link";
              SimLokiDatabaseActions.insert( 'physical-topology', t_linkJson); */

          }

          if(node_mapping["CORENODE"] != undefined){
              for(i =0; i < node_mapping["CORENODE"].ids.length; i++) {
                  for(j =Math.floor(i/2)*4; j < (Math.floor(i/2)*4 + 4) && j < node_mapping["DISTNODE"].ids.length; j++) {
                      var t_linkJson = JSON.parse(JSON.stringify(TopologyLinkTemplate.Topology_Link_Template));
                      t_linkJson.source = node_mapping["CORENODE"].ids[i];
                      t_linkJson.target = node_mapping["DISTNODE"].ids[j];
                      t_linkJson.topology_type = "link";
                      // checking whether both devices(source,target) belong to same location.
                      var sourceNetworkObj = SimLokiDatabaseActions.getFilteredRecordHandler('network-device', {'id': t_linkJson.source});
                      var targetNetworkObj = SimLokiDatabaseActions.getFilteredRecordHandler('network-device', {'id': t_linkJson.target});
                      //if it is part of child, we shall proceed to link
                      let srcChildren=[], tgtChildren=[];
                      srcChildren = SimulationUtility.getChildrenSites(sourceNetworkObj[0].siteId, srcChildren)
                      tgtChildren = SimulationUtility.getChildrenSites(targetNetworkObj[0].siteId, tgtChildren)
                      srcChildren = srcChildren.map(e=>e.id), tgtChildren = tgtChildren.map(e=>e.id);
                      if(sourceNetworkObj.length>0 && targetNetworkObj.length>0 && (sourceNetworkObj[0].siteId ==targetNetworkObj[0].siteId || 
                      srcChildren.indexOf(targetNetworkObj[0].siteId) > -1 || tgtChildren.indexOf(sourceNetworkObj[0].siteId) > -1) ) {
                      //if(sourceNetworkObj.length>0 && targetNetworkObj.length>0 && sourceNetworkObj[0].siteId ==targetNetworkObj[0].siteId) {
                          SimLokiDatabaseActions.insert( 'physical-topology', t_linkJson);
                      }
                  }
              }
          }

          if(node_mapping["DISTNODE"] != undefined){
              for(i =0; i < node_mapping["DISTNODE"].ids.length; i++) {
                  for(j =Math.floor(i/2)*2; j < (Math.floor(i/2)*4 + 4) && j < node_mapping["ACCESSNODE"].ids.length; j++) {
                      var t_linkJson = JSON.parse(JSON.stringify(TopologyLinkTemplate.Topology_Link_Template));
                      t_linkJson.source = node_mapping["DISTNODE"].ids[i];
                      t_linkJson.target = node_mapping["ACCESSNODE"].ids[j];
                      t_linkJson.topology_type = "link";
                      // checking whether both devices(source,target) belong to same location.
                      var sourceNetworkObj = SimLokiDatabaseActions.getFilteredRecordHandler('network-device', {'id': t_linkJson.source});
                      var targetNetworkObj = SimLokiDatabaseActions.getFilteredRecordHandler('network-device', {'id': t_linkJson.target});
                      //if it is part of child, we shall proceed to link
                      let srcChildren=[], tgtChildren=[];
                      srcChildren = SimulationUtility.getChildrenSites(sourceNetworkObj[0].siteId, srcChildren)
                      tgtChildren = SimulationUtility.getChildrenSites(targetNetworkObj[0].siteId, tgtChildren)
                      srcChildren = srcChildren.map(e=>e.id), tgtChildren = tgtChildren.map(e=>e.id);
                      if(sourceNetworkObj.length>0 && targetNetworkObj.length>0 && (sourceNetworkObj[0].siteId ==targetNetworkObj[0].siteId || 
                      srcChildren.indexOf(targetNetworkObj[0].siteId) > -1 || tgtChildren.indexOf(sourceNetworkObj[0].siteId) > -1) ) {
                      //if(sourceNetworkObj.length>0 && targetNetworkObj.length>0 && sourceNetworkObj[0].siteId ==targetNetworkObj[0].siteId) {
                          SimLokiDatabaseActions.insert( 'physical-topology', t_linkJson);
                      }
                  }
              }
          }

          if(node_mapping["WLCNODE"] != undefined){
              for(i =0; i < node_mapping["WLCNODE"].ids.length; i++) {
                  var t_linkJson = JSON.parse(JSON.stringify(TopologyLinkTemplate.Topology_Link_Template));
                  // to split campus node across
                  var coreMax=  node_mapping["CORENODE"].ids.length-1
                  var corePos = (i<coreMax )?i :coreMax
                  t_linkJson.source = node_mapping["CORENODE"].ids[corePos];
                  t_linkJson.target = node_mapping["WLCNODE"].ids[i];
                  t_linkJson.topology_type = "link";
                  // Fix for topology issue for WLC due to site mismatch.
                 // checking whether both devices(source,target) belong to same location.
                  var sourceNetworkObj = SimLokiDatabaseActions.getFilteredRecordHandler('network-device', {'id': t_linkJson.source});
                  var targetNetworkObj = SimLokiDatabaseActions.getFilteredRecordHandler('network-device', {'id': t_linkJson.target});
                  //if it is part of child, we shall proceed to link
                  let srcChildren=[], tgtChildren=[];
                  srcChildren = SimulationUtility.getChildrenSites(sourceNetworkObj[0].siteId, srcChildren)
                  tgtChildren = SimulationUtility.getChildrenSites(targetNetworkObj[0].siteId, tgtChildren)
                  srcChildren = srcChildren.map(e=>e.id), tgtChildren = tgtChildren.map(e=>e.id);
                  if(sourceNetworkObj.length>0 && targetNetworkObj.length>0 && (sourceNetworkObj[0].siteId ==targetNetworkObj[0].siteId || 
                  srcChildren.indexOf(targetNetworkObj[0].siteId) > -1 || tgtChildren.indexOf(sourceNetworkObj[0].siteId) > -1) ) {
                  //if(sourceNetworkObj.length>0 && targetNetworkObj.length>0 && sourceNetworkObj[0].siteId ==targetNetworkObj[0].siteId) {
                       SimLokiDatabaseActions.insert( 'physical-topology', t_linkJson);
                  }
              }
          }

          if(node_mapping["APNODE"] != undefined){
              for(i =0; i < node_mapping["APNODE"].ids.length; i++) {
                  var t_linkJson = JSON.parse(JSON.stringify(TopologyLinkTemplate.Topology_Link_Template));
                  var indx = UtilityFunctions.getRandomIntForRangeValues(1, 4) - 1;

                  //explicit link of ap4800 to p1.edge device, as requested.
                  var swDevId = (SimLokiDatabaseActions.getFilteredRecordHandler('network-device', {'hostname': 'p1.edge1-sda1.local'}))[0].id;
                  var apDevId = (SimLokiDatabaseActions.getFilteredRecordHandler('network-device', {'hostname': 'AP4800'}))[0].id;
                  if(node_mapping["APNODE"].ids[i] == apDevId) {
                    indx = node_mapping["ACCESSNODE"].ids.indexOf(swDevId);
                  }

                  t_linkJson.source = node_mapping["ACCESSNODE"].ids[indx];
                  t_linkJson.target = node_mapping["APNODE"].ids[i];
                  t_linkJson.topology_type = "link";
                  SimLokiDatabaseActions.insert( 'physical-topology', t_linkJson);
                  /* // checking whether both devices(source,target) belong to same location.
                  var sourceNetworkObj = SimLokiDatabaseActions.getFilteredRecordHandler('network-device', {'id': t_linkJson.source});
                  var targetNetworkObj = SimLokiDatabaseActions.getFilteredRecordHandler('network-device', {'id': t_linkJson.target});
                  if(sourceNetworkObj.length>0 && targetNetworkObj.length>0 && sourceNetworkObj[0].siteId ==targetNetworkObj[0].siteId) {
                       SimLokiDatabaseActions.insert( 'physical-topology', t_linkJson);
                  } */
              }
          }

          Object.keys(host_connected_device_mapping).forEach(function (key) {
              var accessNodeId = host_connected_device_mapping[key];

              var t_linkJson = JSON.parse(JSON.stringify(TopologyHostLinkTemplate.Topology_Host_Link_Template));
              t_linkJson.source =  accessNodeId;
              t_linkJson.target = key;
              SimLokiDatabaseActions.insert( 'physical-topology', t_linkJson);
          });

          this.updateTopology( DefaultConfig.Topology_Config);
         //  for updating site info
         var recordObj = SimLokiDatabaseActions.getAll('network-device');
         for(var k=0; k<recordObj.length;k++) {
            if(recordObj[k].siteId.length>0) {
             this.updateSiteDetails(recordObj[k].id,recordObj[k].siteId);
            }
         }
      },

      initCustomFabricTopology() {
        //custom view initialisations. not adding in init as dependant tables are still not populated.
        //initialise for pages
        let tJson_pages = JSON.stringify(TopologyControllerPageTemplate.TopologyControllerPage_Template);
        let siteSJC06 = SimLokiDatabaseActions.getFilteredRecordHandler('site', {'name':'SJC06'});
        tJson_pages = tJson_pages.replace('siteIdOfSJC06',siteSJC06[0].id);

        tJson_pages = JSON.parse(tJson_pages);
        var recordFdObj = SimLokiDatabaseActions.getFilteredRecordHandler('ConnectivityDomain', {'name': "SanJose_Fabric"});
        let siteDomRec = recordFdObj[0].siteSpecificDomain;
        siteDomRec = siteDomRec.map(e=>e.idRef);
        siteDomRec = SimLokiDatabaseActions.getFilteredRecordHandler('ConnectivityDomain', {'id': {'$in': siteDomRec} } );              
        siteDomRec.forEach(e => {
            if(e.siteId == siteSJC06[0].id) return;
            var tJson_pages2 = JSON.parse(JSON.stringify(tJson_pages[0]));
            tJson_pages2.id = UtilityFunctions.generate_uuid();
            tJson_pages2.name = e.siteId;
            tJson_pages.push(tJson_pages2);
        });
        //initialise for views
        let tJson_views = JSON.parse(JSON.stringify(SimCustomTopologyView.SCJ06_Page_View_Template));
        let customTJson = {"pages":tJson_pages, "views":tJson_views}
        SimLokiDatabaseActions.insert('custom-topology', customTJson);
        
        //initialise for view details
        var customViewData = JSON.stringify(SimCustomTopologyView.CustomViewCoordinates), devToIdMapping = {}, reqdDevices = [];
        var tempData = customViewData.split("<");
        tempData.forEach(e => {
            e.split(">").length > 1 ? reqdDevices.push(e.split(">")[0]) : undefined;
        });
        let nwDevices = SimLokiDatabaseActions.getFilteredRecordHandler("network-device", {"hostname": {"$in": reqdDevices}});
        nwDevices.forEach(e => {
            customViewData = customViewData.replace("<"+e.hostname+">", e.id);
        });
        customViewData = JSON.parse(customViewData);
        SimLokiDatabaseActions.insert("custom-topology-view", customViewData);
        console.log('initialised data for custom fabric views')
      },

      updateTopology :function(list) {

         for(var i=0;i<list.length;i++) {
           var source = list[i].source;
           var target = list[i].target;
            for(var j=0;j<source.length;j++) {

                for(var k=0;k<target.length;k++) {

                   var sourceDevice = SimLokiDatabaseActions.getFilteredRecordHandler('network-device',{'hostname':source[j] });

                   var targetDevice = SimLokiDatabaseActions.getFilteredRecordHandler('network-device',{'hostname':target[k] });
                    if(sourceDevice.length>0 && targetDevice.length>0)
                     {
                        var t_linkJson = JSON.parse(JSON.stringify(TopologyLinkTemplate.Topology_Link_Template));
                          t_linkJson.source = sourceDevice[0].id;
                          t_linkJson.target = targetDevice[0].id;
                          t_linkJson.topology_type = "link";
                          var hasMultipleLinks = false;
                          if(list[i].linkInfo) {
                            if(list[i].linkInfo.length>1) hasMultipleLinks = true;
                            list[i].linkInfo.forEach(linkInfoDtl => {
                                t_linkJson = JSON.parse(JSON.stringify(t_linkJson));
                                delete t_linkJson.$loki, t_linkJson.meta;
                                t_linkJson.startPortName = linkInfoDtl.start ? linkInfoDtl.start : t_linkJson.startPortName;
                                t_linkJson.endPortName = linkInfoDtl.end ? linkInfoDtl.end : t_linkJson.endPortName;
                                t_linkJson.linkStatus = linkInfoDtl.status ? linkInfoDtl.status : t_linkJson.linkStatus;
                                if(hasMultipleLinks) {
                                    SimLokiDatabaseActions.insert( 'physical-topology', t_linkJson);
                                }
                            });
                          }
                          if(!hasMultipleLinks) {
                            SimLokiDatabaseActions.insert( 'physical-topology', t_linkJson);
                          }

                      }

                }
            }

         }




      },

      addDevice :function (sourceDeviceId, devices) {
          var t_Json = JSON.parse(JSON.stringify(TopologyNodeTemplate.Topology_Node_Template));
              t_Json.deviceType = devices[0].type;
              t_Json.label = devices[0].hostname;
              t_Json.ip = devices[0].managementIpAddress;
              t_Json.softwareVersion = devices[0].swversion==undefined? devices[0].softwareVersion:devices[0].swversion
              t_Json.nodeType = "device";
              t_Json.family = devices[0].family;
              t_Json.platformId = devices[0].platformid==undefined? devices[0].platformId:devices[0].platformid;
              t_Json.tags = [];
              t_Json.role = devices[0].role;
              t_Json.roleSource = "AUTO";
              t_Json.customParam = {};
              t_Json.additionalInfo = {};
              t_Json.id = devices[0].id;
              t_Json.topology_type = "node";
              t_Json.healthScore = 5;
              t_Json.vrf = devices[0].vrf==undefined? "" : devices[0].vrf;
              t_Json.vlan = devices[0].vlan==undefined? "" : devices[0].vlan;
              t_Json.routing = devices[0].routing==undefined? "" : devices[0].routing;
              t_Json.deviceSeries = devices[0].series;
          SimLokiDatabaseActions.insert( 'physical-topology', t_Json);
         if(sourceDeviceId != undefined && sourceDeviceId != '' )
         {
            var t_linkJson = JSON.parse(JSON.stringify(TopologyLinkTemplate.Topology_Link_Template));
              t_linkJson.source = sourceDeviceId;
              t_linkJson.target = devices[0].id;
              t_linkJson.topology_type = "link";
              SimLokiDatabaseActions.insert( 'physical-topology', t_linkJson);

          }
      },


      deleteDevice :function(deviceId) {
          var deviceInTopology = SimLokiDatabaseActions.getFilteredRecordHandler('physical-topology', {'id' : deviceId});
          if(deviceInTopology != undefined && deviceInTopology.length > 0) {
            SimLokiDatabaseActions.delete('physical-topology', deviceInTopology[0] );
          }
          var totalLinksInTopology = [];
          var sourceLinksInTopology = SimLokiDatabaseActions.getFilteredRecordHandler('physical-topology', { 'source' : deviceId} );

          var targetLinksInTopology = SimLokiDatabaseActions.getFilteredRecordHandler('physical-topology',{'target':deviceId});

          if(sourceLinksInTopology != undefined && sourceLinksInTopology.length > 0) {
              totalLinksInTopology.push(...sourceLinksInTopology);
          }

          if(targetLinksInTopology != undefined && targetLinksInTopology.length > 0) {
              totalLinksInTopology.push(...targetLinksInTopology);
          }

          for(var linkIndex in totalLinksInTopology) {
              SimLokiDatabaseActions.delete('physical-topology', totalLinksInTopology[linkIndex] );
          }
      },

        getControllerAppDetails: function(urlAction) {
            if(urlAction.method == 'POST') {
                let id = UtilityFunctions.generate_uuid();
                var taskObj = SimTaskDataGenerator.createTask('NCTO', {'progress':id});
                if(urlAction.service.indexOf('view') >= 0) {
                    //custom fabric view creation
                    let restPayload = urlAction.restPayload[0];
                    restPayload.id = id;
                    SimLokiDatabaseActions.insert('custom-topology-view', restPayload);
                    let topo = SimLokiDatabaseActions.getAll('custom-topology')
                    topo[0].views.push( {"id":id,"name":restPayload.name,
                        "applicationUuid":restPayload.applicationUuid,"pageUuid":restPayload.pageUuid });
                    SimLokiDatabaseActions.update('custom-topology', topo);
                }
                if(urlAction.service.indexOf('page') >= 0){
                    //when fabric site is added and first time we are viewing it
                    let restPayload = urlAction.restPayload[0];
                    restPayload.id = id;
                    if(! restPayload.defaultViewId) restPayload.defaultViewId = null;
                    let topo = SimLokiDatabaseActions.getAll('custom-topology', restPayload);
                    topo[0].pages.push(restPayload);
                    SimLokiDatabaseActions.update('custom-topology', topo);
                }
                SimTaskDataGenerator.updateTaskCompletion(taskObj.taskId, {isError: false });
                return taskObj
            } else if(urlAction.method == 'PUT') {
                let restPayload = urlAction.restPayload[0];
                var taskObj = SimTaskDataGenerator.createTask('NCTO', {'progress':restPayload.id});
                let topo = SimLokiDatabaseActions.getAll('custom-topology');
                if(urlAction.service.indexOf('view') >= 0) {
                    //making changes to existing fabric view
                    topo[0].views.forEach(e => {
                        if(e.id==restPayload.id) e.topology = restPayload.topology;
                    });
                } else if(urlAction.service.indexOf('page') >= 0) {
                    //making a fabric view default
                    topo[0].pages.forEach(e => {
                        if(e.id==restPayload.id) e.defaultViewId=restPayload.defaultViewId;
                    });
                }
                SimLokiDatabaseActions.update('custom-topology', topo);
                SimTaskDataGenerator.updateTaskCompletion(taskObj.taskId, {isError: false });
                return taskObj
            }
            if(urlAction.service.indexOf('view') >= 0) {
                if(urlAction.filter && urlAction.filter.nodeType && urlAction.filter.nodeType=="device") {
                    let topology = this.getPhyTopology(urlAction, true);
                    //to add custom attributes
                    return topology;
                }
                let topo = SimLokiDatabaseActions.getAll('custom-topology');
                topo = topo[0].views;
                return topo.filter(e => e.pageUuid==urlAction.action.id);//view with the requested page id
            } else if(urlAction.service.indexOf('page') >= 0) {
                let topo = SimLokiDatabaseActions.getAll('custom-topology')
                return topo[0].pages;
            } else {
                return TopologyControllerAppTemplate.TopologyControllerApp_Template;
            }
        },

      getVlanDetails: function(urlAction) {
          if(urlAction.service.indexOf('l2') > -1) {
              var idx = urlAction.service.indexOf('l2');
              if(idx > -1 && urlAction.service[idx+1]) { 
                  return this.getPhyTopology(urlAction, false, true, {"vlan":urlAction.service[idx+1]});
              }
          };
          return TopologyVlanNamesTemplate.TopologyVlanNames_Template;
      },

      getVrfDetails: function(urlAction) {
          var idx = urlAction.service.indexOf('l3');
          if(idx > -1 && urlAction.service[idx+2]) { 
              return this.getPhyTopology(urlAction, false, true, {"vrf":urlAction.service[idx+2]});
          }
          return TopologyVrfNamesTemplate.TopologyVrfNames_Template;
      },

      getRoutingDetails: function(urlAction) {
          var idx = urlAction.service.indexOf('l3');
          if(idx > -1 && urlAction.service[idx+1]) { 
              return this.getPhyTopology(urlAction, false, true, {"routing":urlAction.service[idx+1]});
          }
      },

      updateSiteDetails :function(deviceId,siteId) {
          var deviceInTopology = SimLokiDatabaseActions.getFilteredRecordHandler('physical-topology', {'id' : deviceId});
          var siteObject = SimLokiDatabaseActions.getFilteredRecordHandler('site', {'id' : siteId});
          var device = SimLokiDatabaseActions.getFilteredRecordHandler('network-device', {'id' : deviceId});
              if(deviceInTopology != undefined && deviceInTopology.length > 0) {

                  var additionalInfoObj =siteObject[0].additionalInfo;
                  for(var i=0;i<additionalInfoObj.length;i++) {
                    if(additionalInfoObj[i].nameSpace=="Location"){
                      var t_Json = JSON.parse(JSON.stringify(TopologyNodeTemplate.Topology_Additional_Info_Template));
                        t_Json.siteid=siteId;
                        t_Json.macAddress=device[0].macAddress;
                        t_Json.latitude=additionalInfoObj[i].attributes.latitude;
                        t_Json.longitude=additionalInfoObj[i].attributes.longitude;
                        deviceInTopology[0].additionalInfo=t_Json;
                         SimLokiDatabaseActions.update('physical-topology', deviceInTopology);
                        break;
                    }
                  }
              }
      },

      updateRoleDetails(jsonObj) {
            var recordObj = SimLokiDatabaseActions.getFilteredRecordHandler('physical-topology', {'id' : jsonObj.id});
            if (recordObj && recordObj.length) {
                recordObj[0].role = jsonObj.role;
                recordObj[0].roleSource = jsonObj.roleSource;
                SimLokiDatabaseActions.update('physical-topology', recordObj);

            }
      },

      getSiteDetails(jsonObj){
           var recordObj = SimLokiDatabaseActions.getFilteredRecordHandler('site', {'name': { '$ne' : 'Global' }});
           var siteList =[];

           for(var i=0;i<recordObj.length;i++) {
             var t_Json = JSON.parse(JSON.stringify(TopologySiteTemplate.Topology_Site_Template));
             var additionalInfoObj =recordObj[i].additionalInfo;
             t_Json.id= recordObj[i].id;
             t_Json.parentId= recordObj[i].parentId;
             t_Json.name= recordObj[i].name;
             t_Json.groupNameHierarchy= recordObj[i].groupNameHierarchy;

             for(var j=0;j<additionalInfoObj.length;j++) {
                    if(additionalInfoObj[j].nameSpace=="Location"){
                     t_Json.locationType=additionalInfoObj[j].attributes.type;
                     if(additionalInfoObj[j].attributes.type=="building") {
                         t_Json.latitude=additionalInfoObj[j].attributes.latitude
                         t_Json.longitude=additionalInfoObj[j].attributes.longitude
                         t_Json.locationAddress=additionalInfoObj[j].attributes.address;
                         t_Json.locationCountry=additionalInfoObj[j].attributes.country;
                     }
                    }
              }
                siteList.push(t_Json);
           }
           var response ={"sites":siteList};
      return response;
      },

        getPhyTopology: function(urlAction, isFromView, isFiltered, filterObj) {
            var data = SimLokiDatabaseActions.getAll("physical-topology");
            var nodes = [], links = [], key = "", customViewData = [];
            if(isFiltered) key = Object.keys(filterObj)[0];
            if(isFromView) {
                let urlArr = urlAction.url.split("?")[0].split("/");//as all ids wont be present in action or service.
                let idx = urlArr.indexOf("view");
                let viewId = urlArr[idx+1], pageId = urlArr[idx-1], ctrlId = urlArr[idx-3];
                customViewData = SimLokiDatabaseActions.getFilteredRecordHandler("custom-topology-view", {"$and": 
                    [{"id":viewId},{"pageUuid":pageId}, {"applicationUuid":ctrlId}] });
            }
            for(var i =0; i < data.length; i++) {
                var rec = JSON.parse(JSON.stringify(data[i]));
                if(rec.nodeType!=undefined) {
                    if(rec.nodeType=="device" || rec.nodeType=="cloud node") {
                        rec['customParam']= {
                            "x": 0, "y": 0, "cx": 0, "cy": 0, "parentNodeId": null, "label": null, "id": null, "attributeInfo": {}
                        };
                    }
                    if(isFiltered) {
                        rec.greyOut = true;
                        delete rec['healthScore'];
                        delete rec['meta'];
                        delete rec['$loki'];
                        if(rec[key] == filterObj[key]) {
                            rec.additionalInfo.hasHosts = true;
                            delete rec.greyOut;
                        }
                    }
                    if(isFromView && customViewData.length>0 && customViewData[0].topology && customViewData[0].topology.nodes) {
                        customViewData[0].topology.nodes.every(e => {
                            if(rec.id == e.id) {
                                rec.customParam.x = e.customParam.x;
                                rec.customParam.y = e.customParam.y;
                                return false;//to break out of the loop
                            }
                            return true;
                        });
                    }                    
                    nodes.push(rec);
                } else {
                    links.push(rec);
                }
            }
            if(isFromView) {
                customViewData[0].topology = { nodes : nodes, links : links };
                return customViewData[0];
            }
            return { nodes : nodes, links : links };
        },

        getUpdatedTopoData(topoData, key) {
            var updatedTopoData = {'nodeList':[], 'linkList':[]};
            if(key == 'requiredFieldView') {
                topoData.nodes.forEach(rec => {
                    var nodeObj = {};
                    nodeObj.id = rec.id;
                    nodeObj.role = rec.role;
                    nodeObj.fam = rec.family;
                    nodeObj.type = rec.nodeType;
                    nodeObj.ser = rec.deviceSeries;
                    updatedTopoData.nodeList.push(nodeObj);
                });
                topoData.links.forEach(rec => {
                    var linkObj = {};
                    linkObj.id = rec.id;
                    linkObj.s = rec.source;
                    linkObj.t = rec.target;
                    updatedTopoData.linkList.push(linkObj);
                });
            } else if(key == 'extraFieldView') {
                topoData.nodes.forEach(rec => {
                    var nodeObj = {};
                    nodeObj.cust = rec.customParam;
                    nodeObj.dtype = rec.deviceType;
                    nodeObj.id = rec.id;
                    nodeObj.info = rec.additionalInfo;
                    nodeObj.ip = rec.ip;
                    nodeObj.lbl = rec.label;
                    nodeObj.pid = rec.platformId;
                    nodeObj.rs = rec.roleSource;
                    nodeObj.sv = rec.softwareVersion;
                    updatedTopoData.nodeList.push(nodeObj);
                });
                topoData.links.forEach(rec => {
                    var linkObj = {};
                    linkObj.id = rec.id;
                    linkObj.spid = rec.startPortID;
                    linkObj.spipv4A = rec.startPortIpv4Address;
                    linkObj.spipv4M = rec.startPortIpv4Mask;
                    linkObj.spname = rec.startPortName;
                    linkObj.spspeed = rec.startPortSpeed;
                    linkObj.st = rec.linkStatus;
                    linkObj.tpid = rec.endPortID;
                    linkObj.tpname = rec.endPortName;
                    linkObj.tpspeed = rec.endPortSpeed;
                    updatedTopoData.linkList.push(linkObj);
                });
            }
            return updatedTopoData;
        }

  };

  function createHostInTopology(recordObj, iStr) {
      var t_Json = JSON.parse(JSON.stringify(TopologyHostTemplate.Topology_Host_Template));
      var ipAddr = DefaultConfig.NetworkDevice.IPADDRESS_PREFIX + iStr;
      t_Json.id = recordObj.id;
      t_Json.deviceType = recordObj.hostType.toLowerCase();
      t_Json.label = recordObj.hostName;
      t_Json.ip = recordObj.hostIpV4;
      t_Json.family = recordObj.hostType;
      t_Json.vrf = "";
      t_Json.vlan = "";
      t_Json.routing = "";
      SimLokiDatabaseActions.insert( 'physical-topology', t_Json);
  }

  function isNeededToConnectToCloudNode(listOfDevices, deviceId) {
      var nodesNotConnectedToCloudNodes = [ "FUSION1-SJC23.cisco.local", "WAN_EDGE3" ];
      var tDevice = listOfDevices.filter(function(obj) { return obj.id == deviceId });
      if(tDevice.length) {
          if(nodesNotConnectedToCloudNodes.indexOf(tDevice[0].hostname) >= 0 ) {
              return false;
          }
      }
      return true;
  }

  //This function is added as a request for Cisco live 'clound node' should be connected to core node
  function addExceptionConnectOtherNodeToCloudNode(cloudnodeUuid, listOfDevices) {
      var nodesNeedsToConnectedToCloudNodes = [ "CAMPUS-CORE3" ]
      for(var i = 0, len = nodesNeedsToConnectedToCloudNodes.length; i < len; i++) {
           var tDevice = listOfDevices.filter(function(obj) { return obj.hostname == nodesNeedsToConnectedToCloudNodes[i] });
           if(tDevice.length) {
               var t_linkJson = JSON.parse(JSON.stringify(TopologyLinkTemplate.Topology_Link_Template));
               t_linkJson.source = cloudnodeUuid;
               t_linkJson.target = tDevice[0].id;
               t_linkJson.topology_type = "link";
               SimLokiDatabaseActions.insert( 'physical-topology', t_linkJson);
           }
      }
  }


});
