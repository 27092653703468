define({
    "mappings": {
  "version": "1.0",
  "response": [
    {
      "issueName": "onboarding_dhcp_timeout_global",
      "columns": [
        {"data": "hostName", "title": "Hostname",  "orderable": true},
        {"data": "OS", "title": "OS",  "orderable": false},
        {"data": "failedAttempts", "title": "# Failed Attempts",  "orderable": false},
        {"data": "recentFailure", "title": "Most Recent Failure",  "orderable": false},
        {"data": "ssid_vlanId", "title": "SSID / VLAN",  "orderable": false}
      ]
    },
    {
      "issueName": "client_auth_timeouts_global",
      "columns": [
        {"data": "hostName", "title": "Hostname",  "orderable": true},
        {"data": "OS", "title": "OS",  "orderable": false},
        {"data": "apName", "title": "AP Name",  "orderable": false},
        {"data": "failedAttempts", "title": "# Failed Attempts",  "orderable": false},
        {"data": "recentFailure", "title": "Most Recent Failure",  "orderable": false}
      ]
    },
    {
      "issueName": "client_auth_failures_global",
      "columns": [
        {"data": "hostName", "title": "Hostname",  "orderable": true},
        {"data": "OS", "title": "OS",  "orderable": false},
        {"data": "failedAttempts", "title": "# Failed Attempts",  "orderable": false},
        {"data": "recentFailure", "title": "Most Recent Failure",  "orderable": false}
      ]
    },
    {
      "issueName": "client_auth_wlc_failures_global",
      "columns": [
        {"data": "hostName", "title": "Hostname",  "orderable": true},
        {"data": "OS", "title": "OS",  "orderable": false},
        {"data": "failedAttempts", "title": "# Failed Attempts",  "orderable": false},
        {"data": "recentFailure", "title": "Most Recent Failure",  "orderable": false},
        {"data": "ssid_vlanId", "title": "SSID / VLAN",  "orderable": false}
      ]
    },
    {
      "issueName": "client_auth_param_mismatch_global",
      "columns": [
        {"data": "hostName", "title": "Hostname",  "orderable": true},
        {"data": "OS", "title": "OS",  "orderable": false},
        {"data": "macAddress", "title": "MAC Address",  "orderable": false},
        {"data": "apName", "title": "AP",  "orderable": false},
        {"data": "wlcName", "title": "WLC",  "orderable": false},
        {"data": "recentFailure", "title": "Most Recent Failure",  "orderable": false}
      ]
    },
    {
      "issueName": "client_auth_rejected_global",
      "columns": [
        {"data": "hostName", "title": "Hostname",  "orderable": true},
        {"data": "macAddress", "title": "MAC Address",  "orderable": false},
        {"data": "apName", "title": "AP",  "orderable": false},
        {"data": "wlcName", "title": "WLC",  "orderable": false},
        {"data": "ssid_vlanId", "title": "SSID / VLAN",  "orderable": false},
        {"data": "recentFailure", "title": "Most Recent Failure",  "orderable": false}
      ]
    },
    {
      "issueName": "onboarding_dhcp_timeout",
      "columns": [
        {"data": "hostName", "title": "Hostname",  "orderable": true},
        {"data": "OS", "title": "OS",  "orderable": false},
        {"data": "failedAttempts", "title": "# Failed Attempts",  "orderable": false},
        {"data": "recentFailure", "title": "Most Recent Failure",  "orderable": false},
        {"data": "ssid_vlanId", "title": "SSID / VLAN",  "orderable": false}
      ]
    },
    {
      "issueName": "onboarding_auth_fail_psk",
      "columns": [
        {"data": "hostName", "title": "Hostname",  "orderable": true},
        {"data": "OS", "title": "OS",  "orderable": false},
        {"data": "failedAttempts", "title": "# Failed Attempts",  "orderable": false},
        {"data": "recentFailure", "title": "Most Recent Failure",  "orderable": false},
        {"data": "ssid_vlanId", "title": "SSID / VLAN",  "orderable": false}
      ]
    },
    {
      "issueName": "client_auth_timeouts",
      "columns": [
        {"data": "hostName", "title": "Hostname",  "orderable": true},
        {"data": "OS", "title": "OS",  "orderable": false},
        {"data": "apName", "title": "AP Name",  "orderable": false},
        {"data": "failedAttempts", "title": "# Failed Attempts",  "orderable": false},
        {"data": "recentFailure", "title": "Most Recent Failure",  "orderable": false}
      ]
    },
    {
      "issueName": "wireless_client_onboarding_auth_kex_eap_id_request_timeout",
      "columns": [
        {"data": "hostName", "title": "Hostname",  "orderable": true},
        {"data": "OS", "title": "OS",  "orderable": false},
        {"data": "apName", "title": "AP Name",  "orderable": false},
        {"data": "failedAttempts", "title": "# Failed Attempts",  "orderable": false},
        {"data": "recentFailure", "title": "Most Recent Failure",  "orderable": false}
      ]
    },
    {
      "issueName": "client_auth_failures",
      "columns": [
        {"data": "hostName", "title": "Hostname",  "orderable": true},
        {"data": "OS", "title": "OS",  "orderable": false},
        {"data": "failedAttempts", "title": "# Failed Attempts",  "orderable": false},
        {"data": "recentFailure", "title": "Most Recent Failure",  "orderable": false}
      ]
    },
    {
      "issueName": "wireless_client_onboarding_failed_mac_auth",
      "columns": [
        {"data": "hostName", "title": "Hostname",  "orderable": true},
        {"data": "OS", "title": "OS",  "orderable": false},
        {"data": "failedAttempts", "title": "# Failed Attempts",  "orderable": false},
        {"data": "recentFailure", "title": "Most Recent Failure",  "orderable": false}
      ]
    },
    {
      "issueName": "onboarding_auth_fail_invalid_user_passwd",
      "columns": [
        {"data": "hostName", "title": "Hostname",  "orderable": true},
        {"data": "OS", "title": "OS",  "orderable": false},
        {"data": "failedAttempts", "title": "# Failed Attempts",  "orderable": false},
        {"data": "recentFailure", "title": "Most Recent Failure",  "orderable": false}
      ]
    },
    {
      "issueName": "client_auth_wlc_failures",
      "columns": [
        {"data": "hostName", "title": "Hostname",  "orderable": true},
        {"data": "OS", "title": "OS",  "orderable": false},
        {"data": "failedAttempts", "title": "# Failed Attempts",  "orderable": false},
        {"data": "recentFailure", "title": "Most Recent Failure",  "orderable": false},
        {"data": "ssid_vlanId", "title": "SSID / VLAN",  "orderable": false}
      ]
    },
    {
      "issueName": "wireless_client_onboarding_auth_kex_no_server_response_after_challenge",
      "columns": [
        {"data": "hostName", "title": "Hostname",  "orderable": true},
        {"data": "OS", "title": "OS",  "orderable": false},
        {"data": "failedAttempts", "title": "# Failed Attempts",  "orderable": false},
        {"data": "recentFailure", "title": "Most Recent Failure",  "orderable": false},
        {"data": "ssid_vlanId", "title": "SSID / VLAN",  "orderable": false}
      ]
    },
    {
      "issueName": "wireless_client_onboarding",
      "columns": [
        {"data": "hostName", "title": "Hostname",  "orderable": true},
        {"data": "OS", "title": "OS",  "orderable": false},
        {"data": "failedAttempts", "title": "# Failed Attempts",  "orderable": false},
        {"data": "recentFailure", "title": "Most Recent Failure",  "orderable": false}
      ]
    },
    {
      "issueName": "wireless_client_onboarding_auth_kex_eapol_4way_handshake",
      "columns": [
        {"data": "hostName", "title": "Hostname",  "orderable": true},
        {"data": "OS", "title": "OS",  "orderable": false},
        {"data": "failedAttempts", "title": "# Failed Attempts",  "orderable": false},
        {"data": "recentFailure", "title": "Most Recent Failure",  "orderable": false}
      ]
    },
    {
      "issueName": "wireless_client_ping_pong",
      "columns": [
        {"data": "hostName", "title": "Hostname",  "orderable": true},
        {"data": "clientLocation", "title": "Client Location",  "orderable": false},
        {"data": "apName", "title": "AP Name",  "orderable": false},
        {"data": "radio", "title": "Radio",  "orderable": false},
        {"data": "ssid", "title": "SSID",  "orderable": false},
        {"data": "rssi", "title": "RSSI",  "orderable": false},
        {"data": "recentFailure", "title": "Most Recent Failure",  "orderable": false}
      ]
    },
    {
      "issueName": "AUTH_AAA_REJECTED",
      "columns": [
        {"data": "hostName", "title": "Hostname",  "orderable": true},
        {"data": "macAddress", "title": "MAC Address",  "orderable": false},
        {"data": "apName", "title": "AP",  "orderable": false},
        {"data": "wlcName", "title": "WLC",  "orderable": false},
        {"data": "ssid_vlanId", "title": "SSID / VLAN",  "orderable": false},
        {"data": "location", "title": "Location",  "orderable": false, "visible": false},
        {"data": "OS", "title": "OS",  "orderable": false, "visible": false},
        {"data": "radio", "title": "Radio",  "orderable": false, "visible": false},
        {"data": "hostType", "title": "Device Type",  "orderable": false, "visible": false},
        {"data": "recentFailure", "title": "Most Recent Failure",  "orderable": false}
      ]
    },
    {
      "issueName": "AUTH_SEC_PARAM_MISMATCH",
      "columns": [
        {"data": "hostName", "title": "Hostname",  "orderable": true},
        {"data": "macAddress", "title": "MAC Address",  "orderable": false},
        {"data": "hostType", "title": "Device Type",  "orderable": false, "visible": false},
        {"data": "ssid_vlanId", "title": "SSID / VLAN",  "orderable": false, "visible": false},
        {"data": "OS", "title": "OS",  "orderable": false},
        {"data": "apName", "title": "AP",  "orderable": false},
        {"data": "wlcName", "title": "WLC",  "orderable": false},
        {"data": "location", "title": "Location",  "orderable": false, "visible": false},
        {"data": "radio", "title": "Radio",  "orderable": false, "visible": false},
        {"data": "recentFailure", "title": "Most Recent Failure",  "orderable": false}
      ]
    },
    {
      "issueName": "AUTH_WLC_MSG_ERROR",
      "columns": [
        {"data": "hostName", "title": "Hostname",  "orderable": true},
        {"data": "macAddress", "title": "MAC Address",  "orderable": false},
        {"data": "apName", "title": "AP",  "orderable": false},
        {"data": "wlcName", "title": "WLC",  "orderable": false},
        {"data": "ssid_vlanId", "title": "SSID / VLAN",  "orderable": false},
        {"data": "hostType", "title": "Device Type",  "orderable": false, "visible": false},
        {"data": "location", "title": "Location",  "orderable": false, "visible": false},
        {"data": "OS", "title": "OS",  "orderable": false, "visible": false},
        {"data": "radio", "title": "Radio",  "orderable": false, "visible": false},
        {"data": "recentFailure", "title": "Most Recent Failure",  "orderable": false}
      ]
    },
    {
      "issueName": "AUTH_WLC_CONFIG_ERROR",
      "columns": [
        {"data": "hostName", "title": "Hostname",  "orderable": true},
        {"data": "macAddress", "title": "MAC Address",  "orderable": false},
        {"data": "apName", "title": "AP",  "orderable": false},
        {"data": "ssid_vlanId", "title": "SSID / VLAN",  "orderable": false},
        {"data": "hostType", "title": "Device Type",  "orderable": false, "visible": false},
        {"data": "location", "title": "Location",  "orderable": false, "visible": false},
        {"data": "OS", "title": "OS",  "orderable": false, "visible": false},
        {"data": "radio", "title": "Radio",  "orderable": false, "visible": false},
        {"data": "wlcName", "title": "WLC",  "orderable": false, "visible": false},
        {"data": "recentFailure", "title": "Most Recent Failure",  "orderable": false}
      ]
    },
    {
      "issueName": "AUTH_WLC_INTERNAL_ERROR",
      "columns": [
        {"data": "hostName", "title": "Hostname",  "orderable": true},
        {"data": "macAddress", "title": "MAC Address",  "orderable": false},
        {"data": "apName", "title": "AP",  "orderable": false},
        {"data": "ssid_vlanId", "title": "SSID / VLAN",  "orderable": false},
        {"data": "hostType", "title": "Device Type",  "orderable": false, "visible": false},
        {"data": "location", "title": "Location",  "orderable": false, "visible": false},
        {"data": "OS", "title": "OS",  "orderable": false, "visible": false},
        {"data": "radio", "title": "Radio",  "orderable": false, "visible": false},
        {"data": "wlcName", "title": "WLC",  "orderable": false, "visible": false},
        {"data": "recentFailure", "title": "Most Recent Failure",  "orderable": false}
      ]
    },
    {
      "issueName": "AUTH_CL_TIMEOUT_OR_RF",
      "columns": [
        {"data": "hostName", "title": "Hostname",  "orderable": true},
        {"data": "macAddress", "title": "MAC Address",  "orderable": false},
        {"data": "OS", "title": "OS",  "orderable": false},
        {"data": "apName", "title": "AP",  "orderable": false},
        {"data": "wlcName", "title": "WLC",  "orderable": false},
        {"data": "hostType", "title": "Device Type",  "orderable": false, "visible": false},
        {"data": "location", "title": "Location",  "orderable": false, "visible": false},
        {"data": "radio", "title": "Radio",  "orderable": false, "visible": false},
        {"data": "ssid_vlanId", "title": "SSID / VLAN",  "orderable": false, "visible": false},
        {"data": "recentFailure", "title": "Most Recent Failure",  "orderable": false}
      ]
    },
    {
      "issueName": "AUTH_AAA_NO_RESPONSE",
      "columns": [
        {"data": "hostName", "title": "Hostname",  "orderable": true},
        {"data": "macAddress", "title": "MAC Address",  "orderable": false},
        {"data": "apName", "title": "AP",  "orderable": false},
        {"data": "wlcName", "title": "WLC",  "orderable": false},
        {"data": "ssid_vlanId", "title": "SSID / VLAN",  "orderable": false},
        {"data": "hostType", "title": "Device Type",  "orderable": false, "visible": false},
        {"data": "location", "title": "Location",  "orderable": false, "visible": false},
        {"data": "OS", "title": "OS",  "orderable": false, "visible": false},
        {"data": "radio", "title": "Radio",  "orderable": false, "visible": false},
        {"data": "recentFailure", "title": "Most Recent Failure",  "orderable": false}
      ]
    },
    {
      "issueName": "EXCESSIVE_DHCP",
      "columns": [
        {"data": "hostName", "title": "Hostname",  "orderable": true},
        {"data": "macAddress", "title": "MAC Address",  "orderable": false},
        {"data": "apName", "title": "AP",  "orderable": false},
        {"data": "location", "title": "Location",  "orderable": false},
        {"data": "ssid_vlanId", "title": "SSID / VLAN",  "orderable": false},
        {"data": "radio", "title": "Radio",  "orderable": false, "visible": false},
        {"data": "ssid", "title": "SSID",  "orderable": false, "visible": false},
        {"data": "OS", "title": "OS",  "orderable": false, "visible": false},
        {"data": "wlcName", "title": "WLC",  "orderable": false, "visible": false},
        {"data": "hostType", "title": "Device Type",  "orderable": false, "visible": false},
        {"data": "recentFailure", "title": "Most Recent Failure",  "orderable": false}
      ]
    },
    {
      "issueName": "EXCESSIVE_ASSOC",
      "columns": [
        {"data": "hostName", "title": "Hostname",  "orderable": true},
        {"data": "macAddress", "title": "MAC Address",  "orderable": false},
        {"data": "apName", "title": "AP",  "orderable": false},
        {"data": "location", "title": "Location",  "orderable": false},
        {"data": "ssid_vlanId", "title": "SSID / VLAN",  "orderable": false},
        {"data": "radio", "title": "Radio",  "orderable": false, "visible": false},
        {"data": "ssid", "title": "SSID",  "orderable": false, "visible": false},
        {"data": "OS", "title": "OS",  "orderable": false, "visible": false},
        {"data": "wlcName", "title": "WLC",  "orderable": false, "visible": false},
        {"data": "hostType", "title": "Device Type",  "orderable": false, "visible": false},
        {"data": "recentFailure", "title": "Most Recent Failure",  "orderable": false}
      ]
    },
    {
      "issueName": "EXCESSIVE_AUTH_SERVER",
      "columns": [
        {"data": "hostName", "title": "Hostname",  "orderable": true},
        {"data": "macAddress", "title": "MAC Address",  "orderable": false},
        {"data": "aaaServerIp", "title": "AAA Server",  "orderable": false},
        {"data": "apName", "title": "AP",  "orderable": false},
        {"data": "location", "title": "Location",  "orderable": false},
        {"data": "radio", "title": "Radio",  "orderable": false, "visible": false},
        {"data": "ssid", "title": "SSID",  "orderable": false, "visible": false},
        {"data": "ssid_vlanId", "title": "SSID / VLAN",  "orderable": false, "visible": false},
        {"data": "hostType", "title": "Device Type",  "orderable": false, "visible": false},
        {"data": "wlcName", "title": "WLC",  "orderable": false, "visible": false},
        {"data": "OS", "title": "OS",  "orderable": false, "visible": false},
        {"data": "recentFailure", "title": "Most Recent Failure",  "orderable": false}
      ]
    },
    {
      "issueName": "EXCESSIVE_AUTH_POOR_RF",
      "columns": [
        {"data": "hostName", "title": "Hostname",  "orderable": true},
        {"data": "macAddress", "title": "MAC Address",  "orderable": false},
        {"data": "apName", "title": "AP",  "orderable": false},
        {"data": "ssid_vlanId", "title": "SSID / VLAN",  "orderable": false},
        {"data": "radio", "title": "Radio",  "orderable": false, "visible": false},
        {"data": "ssid", "title": "SSID",  "orderable": false, "visible": false},
        {"data": "hostType", "title": "Device Type",  "orderable": false, "visible": false},
        {"data": "wlcName", "title": "WLC",  "orderable": false, "visible": false},
        {"data": "location", "title": "Location",  "orderable": false},
        {"data": "OS", "title": "OS",  "orderable": false, "visible": false},
        {"data": "recentFailure", "title": "Most Recent Failure",  "orderable": false}
      ]
    },
    {
      "issueName": "EXCESSIVE_TOTAL",
      "columns": [
        {"data": "hostName", "title": "Hostname",  "orderable": true},
        {"data": "macAddress", "title": "MAC Address",  "orderable": false},
        {"data": "aaaServerIp", "title": "AAA Server",  "orderable": false},
        {"data": "wlcName", "title": "WLC",  "orderable": false},
        {"data": "apName", "title": "AP",  "orderable": false},
        {"data": "radio", "title": "Radio",  "orderable": false, "visible": false},
        {"data": "ssid", "title": "SSID",  "orderable": false, "visible": false},
        {"data": "hostType", "title": "Device Type",  "orderable": false, "visible": false},
        {"data": "ssid_vlanId", "title": "SSID / VLAN",  "orderable": false, "visible": false},
        {"data": "location", "title": "Location",  "orderable": false, "visible": false},
        {"data": "OS", "title": "OS",  "orderable": false, "visible": false},
        {"data": "recentFailure", "title": "Most Recent Failure",  "orderable": false}
      ]
    },
    {
      "issueName": "EXCESSIVE_DHCP_FAIL",
      "columns": [
        {"data": "hostName", "title": "Hostname",  "orderable": true},
        {"data": "macAddress", "title": "MAC Address",  "orderable": false},
        {"data": "apName", "title": "AP",  "orderable": false},
        {"data": "location", "title": "Location",  "orderable": false},
        {"data": "ssid_vlanId", "title": "SSID / VLAN",  "orderable": false},
        {"data": "radio", "title": "Radio",  "orderable": false, "visible": false},
        {"data": "ssid", "title": "SSID",  "orderable": false, "visible": false},
        {"data": "hostType", "title": "Device Type",  "orderable": false, "visible": false},
        {"data": "OS", "title": "OS",  "orderable": false, "visible": false},
        {"data": "wlcName", "title": "WLC",  "orderable": false, "visible": false},
        {"data": "recentFailure", "title": "Most Recent Failure",  "orderable": false}
      ]
    },
    {
      "issueName": "EXCESSIVE_ASSOC_FAIL",
      "columns": [
        {"data": "hostName", "title": "Hostname",  "orderable": true},
        {"data": "macAddress", "title": "MAC Address",  "orderable": false},
        {"data": "apName", "title": "AP",  "orderable": false},
        {"data": "location", "title": "Location",  "orderable": false},
        {"data": "radio", "title": "Radio",  "orderable": false},
        {"data": "ssid", "title": "SSID",  "orderable": false, "visible": false},
        {"data": "ssid_vlanId", "title": "SSID / VLAN",  "orderable": false, "visible": false},
        {"data": "wlcName", "title": "WLC",  "orderable": false, "visible": false},
        {"data": "hostType", "title": "Device Type",  "orderable": false, "visible": false},
        {"data": "OS", "title": "OS",  "orderable": false, "visible": false},
        {"data": "recentFailure", "title": "Most Recent Failure",  "orderable": false}
      ]
    },
    {
      "issueName": "EXCESSIVE_AUTH_FAIL_SERVER",
      "columns": [
        {"data": "hostName", "title": "Hostname",  "orderable": true},
        {"data": "macAddress", "title": "MAC Address",  "orderable": false},
        {"data": "aaaServerIp", "title": "AAA Server",  "orderable": false},
        {"data": "apName", "title": "AP",  "orderable": false},
        {"data": "location", "title": "Location",  "orderable": false},
        {"data": "radio", "title": "Radio",  "orderable": false, "visible": false},
        {"data": "ssid", "title": "SSID",  "orderable": false, "visible": false},
        {"data": "hostType", "title": "Device Type",  "orderable": false, "visible": false},
        {"data": "OS", "title": "OS",  "orderable": false, "visible": false},
        {"data": "radio", "title": "Radio",  "orderable": false, "visible": false},
        {"data": "ssid_vlanId", "title": "SSID / VLAN",  "orderable": false, "visible": false},
        {"data": "wlcName", "title": "WLC",  "orderable": false, "visible": false},
        {"data": "recentFailure", "title": "Most Recent Failure",  "orderable": false}
      ]
    },
    {
      "issueName": "EXCESSIVE_AUTH_FAIL_WLC",
      "columns": [
        {"data": "hostName", "title": "Hostname",  "orderable": true},
        {"data": "macAddress", "title": "MAC Address",  "orderable": false},
        {"data": "wlcName", "title": "WLC",  "orderable": false},
        {"data": "apName", "title": "AP",  "orderable": false},
        {"data": "location", "title": "Location",  "orderable": false},
        {"data": "radio", "title": "Radio",  "orderable": false, "visible": false},
        {"data": "ssid", "title": "SSID",  "orderable": false, "visible": false},
        {"data": "ssid_vlanId", "title": "SSID / VLAN",  "orderable": false, "visible": false},
        {"data": "hostType", "title": "Device Type",  "orderable": false, "visible": false},
        {"data": "OS", "title": "OS",  "orderable": false, "visible": false},
        {"data": "recentFailure", "title": "Most Recent Failure",  "orderable": false}
      ]
    },
    {
      "issueName": "EXCESSIVE_AUTH_FAIL_NETWORK",
      "columns": [
        {"data": "hostName", "title": "Hostname",  "orderable": true},
        {"data": "macAddress", "title": "MAC Address",  "orderable": false},
        {"data": "aaaServerIp", "title": "AAA Server",  "orderable": false},
        {"data": "apName", "title": "AP",  "orderable": false},
        {"data": "location", "title": "Location",  "orderable": false},
        {"data": "radio", "title": "Radio",  "orderable": false, "visible": false},
        {"data": "ssid", "title": "SSID",  "orderable": false, "visible": false},
        {"data": "ssid_vlanId", "title": "SSID / VLAN",  "orderable": false, "visible": false},
        {"data": "wlcName", "title": "WLC",  "orderable": false, "visible": false},
        {"data": "hostType", "title": "Device Type",  "orderable": false, "visible": false},
        {"data": "OS", "title": "OS",  "orderable": false, "visible": false},
        {"data": "recentFailure", "title": "Most Recent Failure",  "orderable": false}
      ]
    },
    {
      "issueName": "EXCESSIVE_AUTH_FAIL_CLIENT",
      "columns": [
        {"data": "hostName", "title": "Hostname",  "orderable": true},
        {"data": "macAddress", "title": "MAC Address",  "orderable": false},
        {"data": "apName", "title": "AP",  "orderable": false},
        {"data": "location", "title": "Location",  "orderable": false},
        {"data": "radio", "title": "Radio",  "orderable": false},
        {"data": "ssid", "title": "SSID",  "orderable": false, "visible": false},
        {"data": "ssid_vlanId", "title": "SSID / VLAN",  "orderable": false, "visible": false},
        {"data": "wlcName", "title": "WLC",  "orderable": false, "visible": false},
        {"data": "hostType", "title": "Device Type",  "orderable": false, "visible": false},
        {"data": "OS", "title": "OS",  "orderable": false, "visible": false},
        {"data": "recentFailure", "title": "Most Recent Failure",  "orderable": false}
      ]
    },
    {
      "issueName": "IOS_DISASSOC_FAIL_INTERNAL",
      "columns": [
        {"data": "hostName", "title": "Hostname",  "orderable": true},
        {"data": "macAddress", "title": "MAC Address",  "orderable": false},
        {"data": "hostType", "title": "Model",  "orderable": false},
        {"data": "OS", "title": "SW version",  "orderable": false},
        {"data": "ssid", "title": "SSID",  "orderable": false},
        {"data": "apName", "title": "AP",  "orderable": false, "visible": false},
        {"data": "radio", "title": "Band",  "orderable": false, "visible": false},
        {"data": "location", "title": "Location",  "orderable": false, "visible": false},
        {"data": "recentFailure", "title": "Most Recent Failure",  "orderable": false}
      ]
    },
    {
      "issueName": "IOS_DISASSOC_FAIL_DECRYPT",
      "columns": [
        {"data": "hostName", "title": "Hostname",  "orderable": true},
        {"data": "macAddress", "title": "MAC Address",  "orderable": false},
        {"data": "hostType", "title": "Model",  "orderable": false},
        {"data": "OS", "title": "SW version",  "orderable": false},
        {"data": "ssid", "title": "SSID",  "orderable": false},
        {"data": "apName", "title": "AP",  "orderable": false, "visible": false},
        {"data": "radio", "title": "Band",  "orderable": false, "visible": false},
        {"data": "location", "title": "Location",  "orderable": false, "visible": false},
        {"data": "recentFailure", "title": "Most Recent Failure",  "orderable": false}
      ]
    },
    {
      "issueName": "IOS_DISASSOC_FAIL_CAPTIVE_PORTAL",
      "columns": [
        {"data": "hostName", "title": "Hostname",  "orderable": true},
        {"data": "macAddress", "title": "MAC Address",  "orderable": false},
        {"data": "hostType", "title": "Model",  "orderable": false},
        {"data": "OS", "title": "SW version",  "orderable": false},
        {"data": "ssid", "title": "SSID",  "orderable": false},
        {"data": "apName", "title": "AP",  "orderable": false, "visible": false},
        {"data": "radio", "title": "Band",  "orderable": false, "visible": false},
        {"data": "location", "title": "Location",  "orderable": false, "visible": false},
        {"data": "recentFailure", "title": "Most Recent Failure",  "orderable": false}
      ]
    },
    {
      "issueName": "BLACKLIST_WEB_AUTH_FAILURE",
      "columns": [
        {"data": "hostName", "title": "Hostname",  "orderable": true},
        {"data": "macAddress", "title": "MAC Address",  "orderable": false},
        {"data": "apName", "title": "AP",  "orderable": false},
        {"data": "wlcName", "title": "WLC",  "orderable": false},
        {"data": "location", "title": "Location",  "orderable": false, "visible": false},
        {"data": "hostType", "title": "Device Type",  "orderable": false, "visible": false},
        {"data": "OS", "title": "OS",  "orderable": false, "visible": false},
        {"data": "radio", "title": "Radio",  "orderable": false, "visible": false},
        {"data": "ssid_vlanId", "title": "SSID / VLAN",  "orderable": false, "visible": false},
        {"data": "failedAttempts", "title": "# Failed Attempts",  "orderable": false, "visible": false},
        {"data": "recentFailure", "title": "Most Recent Failure",  "orderable": false, "visible": false}
      ]
    },
    {
      "issueName": "BLACKLIST_AUTH_FAILURE",
      "columns": [
        {"data": "hostName", "title": "Hostname",  "orderable": true},
        {"data": "macAddress", "title": "MAC Address",  "orderable": false},
        {"data": "apName", "title": "AP",  "orderable": false},
        {"data": "wlcName", "title": "WLC",  "orderable": false},
        {"data": "aaaServerIp", "title": "AAA Server",  "orderable": false},
        {"data": "ssid_vlanId", "title": "SSID / VLAN",  "orderable": false, "visible": false},
        {"data": "location", "title": "Location",  "orderable": false, "visible": false},
        {"data": "hostType", "title": "Device Type",  "orderable": false, "visible": false},
        {"data": "OS", "title": "OS",  "orderable": false, "visible": false},
        {"data": "radio", "title": "Radio",  "orderable": false, "visible": false},
        {"data": "failedAttempts", "title": "# Failed Attempts",  "orderable": false, "visible": false},
        {"data": "recentFailure", "title": "Most Recent Failure",  "orderable": false, "visible": false}
      ]
    },
    {
      "issueName": "BLACKLIST_IP_THEFT",
      "columns": [
        {"data": "hostName", "title": "Hostname",  "orderable": true},
        {"data": "macAddress", "title": "MAC Address",  "orderable": false},
        {"data": "apName", "title": "AP",  "orderable": false},
        {"data": "wlcName", "title": "WLC",  "orderable": false},
        {"data": "ssid_vlanId", "title": "SSID / VLAN",  "orderable": false, "visible": false},
        {"data": "location", "title": "Location",  "orderable": false, "visible": false},
        {"data": "hostType", "title": "Device Type",  "orderable": false, "visible": false},
        {"data": "OS", "title": "OS",  "orderable": false, "visible": false},
        {"data": "radio", "title": "Radio",  "orderable": false, "visible": false},
        {"data": "failedAttempts", "title": "# Failed Attempts",  "orderable": false, "visible": false},
        {"data": "recentFailure", "title": "Most Recent Failure",  "orderable": false, "visible": false}
      ]
    },
    {
      "issueName": "BLACKLIST_IDS_SHUN_LIST",
      "columns": [
        {"data": "hostName", "title": "Hostname",  "orderable": true},
        {"data": "macAddress", "title": "MAC Address",  "orderable": false},
        {"data": "apName", "title": "AP",  "orderable": false},
        {"data": "wlcName", "title": "WLC",  "orderable": false},
        {"data": "ssid_vlanId", "title": "SSID / VLAN",  "orderable": false, "visible": false},
        {"data": "location", "title": "Location",  "orderable": false, "visible": false},
        {"data": "hostType", "title": "Device Type",  "orderable": false, "visible": false},
        {"data": "OS", "title": "OS",  "orderable": false, "visible": false},
        {"data": "radio", "title": "Radio",  "orderable": false, "visible": false},
        {"data": "failedAttempts", "title": "# Failed Attempts",  "orderable": false, "visible": false},
        {"data": "recentFailure", "title": "Most Recent Failure",  "orderable": false, "visible": false}
      ]
    },
    {
      "issueName": "BLACKLIST_ASSOC_FAIL",
      "columns": [
        {"data": "hostName", "title": "Hostname",  "orderable": true},
        {"data": "macAddress", "title": "MAC Address",  "orderable": false},
        {"data": "apName", "title": "AP",  "orderable": false},
        {"data": "wlcName", "title": "WLC",  "orderable": false},
        {"data": "ssid_vlanId", "title": "SSID / VLAN",  "orderable": false, "visible": false},
        {"data": "location", "title": "Location",  "orderable": false, "visible": false},
        {"data": "hostType", "title": "Device Type",  "orderable": false, "visible": false},
        {"data": "OS", "title": "OS",  "orderable": false, "visible": false},
        {"data": "radio", "title": "Radio",  "orderable": false, "visible": false},
        {"data": "failedAttempts", "title": "# Failed Attempts",  "orderable": false, "visible": false},
        {"data": "recentFailure", "title": "Most Recent Failure",  "orderable": false, "visible": false}
      ]
    },
    {
      "issueName": "BLACKLIST_ROAMING_FAILURE",
      "columns": [
        {"data": "hostName", "title": "Hostname",  "orderable": true},
        {"data": "macAddress", "title": "MAC Address",  "orderable": false},
        {"data": "apName", "title": "AP",  "orderable": false},
        {"data": "wlcName", "title": "WLC",  "orderable": false},
        {"data": "aaaServerIp", "title": "AAA Server",  "orderable": false},
        {"data": "ssid_vlanId", "title": "SSID / VLAN",  "orderable": false, "visible": false},
        {"data": "location", "title": "Location",  "orderable": false, "visible": false},
        {"data": "hostType", "title": "Device Type",  "orderable": false, "visible": false},
        {"data": "OS", "title": "OS",  "orderable": false, "visible": false},
        {"data": "radio", "title": "Radio",  "orderable": false, "visible": false},
        {"data": "failedAttempts", "title": "# Failed Attempts",  "orderable": false, "visible": false},
        {"data": "recentFailure", "title": "Most Recent Failure",  "orderable": false, "visible": false}
      ]
    },
    {
      "issueName": "connected_poor_rssi",
      "columns": [
        {"data": "hostName", "title": "Hostname",  "orderable": true},
        {"data": "macAddress", "title": "MAC Address",  "orderable": false},
        {"data": "radio", "title": "Radio",  "orderable": false},
        {"data": "ssid_vlanId", "title": "SSID / VLAN",  "orderable": false},
        {"data": "recentFailure", "title": "Most Recent Failure",  "orderable": false}
      ]
    },
    {
      "issueName": "connected_sticky_client",
      "columns": [
        {"data": "hostName", "title": "Hostname",  "orderable": true},
        {"data": "macAddress", "title": "MAC Address",  "orderable": false},
        {"data": "ssid", "title": "SSID",  "orderable": false},
        {"data": "apName", "title": "AP",  "orderable": false},
        {"data": "radio", "title": "Radio",  "orderable": false},
        {"data": "location", "title": "Location",  "orderable": false, "visible": false},
        {"data": "recentFailure", "title": "Most Recent Failure",  "orderable": false}
      ]
    },
    {
      "issueName": "wireless_client_roaming_slow",
      "columns": [
        {"data": "hostName", "title": "Hostname",  "orderable": true},
        {"data": "macAddress", "title": "MAC Address",  "orderable": false},
        {"data": "ssid", "title": "SSID",  "orderable": false},
        {"data": "apName", "title": "AP",  "orderable": false},
        {"data": "radio", "title": "Radio",  "orderable": false},
        {"data": "location", "title": "Location",  "orderable": false, "visible": false},
        {"data": "recentFailure", "title": "Most Recent Failure",  "orderable": false}
      ]
    },
    {
      "issueName": "client_poor_band_selection",
      "columns": [
        {"data": "hostName", "title": "Hostname",  "orderable": true},
        {"data": "macAddress", "title": "MAC Address",  "orderable": false},
        {"data": "ssid", "title": "SSID",  "orderable": false},
        {"data": "apName", "title": "AP",  "orderable": false},
        {"data": "radio", "title": "Radio",  "orderable": false},
        {"data": "rssi", "title": "RSSI",  "orderable": false},
        {"data": "location", "title": "Location",  "orderable": false, "visible": false},
        {"data": "recentFailure", "title": "Most Recent Failure",  "orderable": false}
      ]
    },
    {
      "issueName": "poor_5G_coverage",
      "columns": [
        {"data": "hostName", "title": "Hostname",  "orderable": true},
        {"data": "macAddress", "title": "MAC Address",  "orderable": false},
        {"data": "ssid", "title": "SSID",  "orderable": false},
        {"data": "apName", "title": "AP",  "orderable": false},
        {"data": "radio", "title": "Radio",  "orderable": false},
        {"data": "rssi", "title": "RSSI",  "orderable": false},
        {"data": "location", "title": "Location",  "orderable": false, "visible": false},
        {"data": "recentFailure", "title": "Most Recent Failure",  "orderable": false}
      ]
    },
    {
      "issueName": "MOBILITY_FAIL_GENERAL_ERRORS",
      "columns": [
        {"data": "hostName", "title": "Hostname",  "orderable": true},
        {"data": "macAddress", "title": "MAC Address",  "orderable": false},
        {"data": "apName", "title": "AP",  "orderable": false},
        {"data": "wlcName", "title": "WLC",  "orderable": false},
        {"data": "ssid_vlanId", "title": "SSID / VLAN",  "orderable": false},
        {"data": "aaaServerIp", "title": "AAA Server",  "visible": false, "orderable": false},
        {"data": "location", "title": "Location",  "orderable": false, "visible": false},
        {"data": "hostType", "title": "Device Type",  "orderable": false, "visible": false},
        {"data": "OS", "title": "OS",  "orderable": false, "visible": false},
        {"data": "radio", "title": "Radio",  "orderable": false, "visible": false},
        {"data": "failedAttempts", "title": "# Failed Attempts",  "visible": false, "orderable": false},
        {"data": "recentFailure", "title": "Most Recent Failure",  "visible": false, "orderable": false}
      ]
    },
    {
      "issueName": "MOBILITY_FAIL_WLC_CONFIG_ISSUES",
      "columns": [
        {"data": "hostName", "title": "Hostname",  "orderable": true},
        {"data": "macAddress", "title": "MAC Address",  "orderable": false},
        {"data": "apName", "title": "AP",  "orderable": false},
        {"data": "wlcName", "title": "WLC",  "orderable": false},
        {"data": "ssid_vlanId", "title": "SSID / VLAN",  "orderable": false},
        {"data": "aaaServerIp", "title": "AAA Server",  "visible": false, "orderable": false},
        {"data": "location", "title": "Location",  "orderable": false, "visible": false},
        {"data": "hostType", "title": "Device Type",  "orderable": false, "visible": false},
        {"data": "OS", "title": "OS",  "orderable": false, "visible": false},
        {"data": "radio", "title": "Radio",  "orderable": false, "visible": false},
        {"data": "failedAttempts", "title": "# Failed Attempts",  "visible": false, "orderable": false},
        {"data": "recentFailure", "title": "Most Recent Failure",  "visible": false, "orderable": false}
      ]
    },
    {
      "issueName": "wired_client_onboarding_failures_trigger",
      "columns": [
        {"data": "hostName", "title": "Client Hostname", "orderable": true},
        {"data": "failedAttempts", "title": "# Failed Attempts", "orderable": true},
        {"data": "recentFailure", "title": "Most Recent Failure", "orderable": true},
        {"data": "ssid_vlanId", "title": "SSID / VLAN", "orderable": true}
      ]
    },
    {
      "issueName": "dhcp_reachability_issue",
      "columns": [
        {"data": "hostName", "title": "Client Hostname", "orderable": true},
        {"data": "failedAttempts", "title": "# Failed Attempts", "orderable": true},
        {"data": "recentFailure", "title": "Most Recent Failure", "orderable": true},
        {"data": "ssid_vlanId", "title": "SSID / VLAN", "orderable": true}
      ]
    },
    {
      "issueName": "dns_reachability_issue",
      "columns": [
        {"data": "hostName", "title": "Client Hostname", "orderable": true},
        {"data": "failedAttempts", "title": "# Failed Attempts", "orderable": true},
        {"data": "recentFailure", "title": "Most Recent Failure", "orderable": true},
        {"data": "ssid_vlanId", "title": "SSID / VLAN", "orderable": true}
      ]
    },
    {
      "issueName": "DHCP_SERVER_TIMEOUT",
      "columns": [
        {"data": "hostName", "title": "Hostname", "orderable": true},
        {"data": "macAddress", "title": "Mac Address",  "orderable": false},
        {"data": "hostType", "title": "Device Type",  "orderable": false},
        {"data": "apName", "title": "AP",  "orderable": false},
        {"data": "ssid_vlanId", "title": "SSID / VLAN",  "orderable": false},
        {"data": "wlcName", "title": "WLC",  "orderable": false},
        {"data": "location", "title": "Location",  "orderable": false, "visible": false},
        {"data": "OS", "title": "OS",  "orderable": false, "visible": false},
        {"data": "radio", "title": "Radio",  "orderable": false, "visible": false},
        {"data": "failedAttempts", "title": "# Failed Attempts",  "visible": false, "orderable": false},
        {"data": "recentFailure", "title": "Most Recent Failure",  "visible": false, "orderable": false}
      ]
    },
    {
      "issueName": "DHCP_CLIENT_TIMEOUT",
      "columns": [
        {"data": "hostName", "title": "Hostname", "orderable": true},
        {"data": "macAddress", "title": "Mac Address",  "orderable": false},
        {"data": "hostType", "title": "Device Type",  "orderable": false},
        {"data": "apName", "title": "AP",  "orderable": false},
        {"data": "ssid_vlanId", "title": "SSID / VLAN",  "orderable": false},
        {"data": "wlcName", "title": "WLC",  "orderable": false},
        {"data": "location", "title": "Location",  "orderable": false, "visible": false},
        {"data": "OS", "title": "OS",  "orderable": false, "visible": false},
        {"data": "radio", "title": "Radio",  "orderable": false, "visible": false},
        {"data": "failedAttempts", "title": "# Failed Attempts",  "visible": false, "orderable": false},
        {"data": "recentFailure", "title": "Most Recent Failure",  "visible": false, "orderable": false}
      ]
    },
    {
      "issueName": "DHCP_SERVER_OR_CLIENT_TIMEOUT",
      "columns": [
        {"data": "hostName", "title": "Hostname", "orderable": true},
        {"data": "macAddress", "title": "Mac Address",  "orderable": false},
        {"data": "hostType", "title": "Device Type",  "orderable": false},
        {"data": "apName", "title": "AP",  "orderable": false},
        {"data": "ssid_vlanId", "title": "SSID / VLAN",  "orderable": false},
        {"data": "wlcName", "title": "WLC",  "orderable": false},
        {"data": "failedAttempts", "title": "# Failed Attempts",  "visible": false, "orderable": false},
        {"data": "recentFailure", "title": "Most Recent Failure",  "visible": false, "orderable": false},
        {"data": "location", "title": "Location", "visible": false, "orderable": false},
        {"data": "radio", "title": "Radio", "orderable": false, "visible": false},
        {"data": "OS", "title": "OS", "orderable": false, "visible": false}
      ]
    },
    {
      "issueName": "sensor_test_result_global_issue",
      "columns": [
        {"data": "sensormac", "title": "Mac Address", "orderable": true},
        {"data": "testsuite", "title": "Test Suite",  "orderable": false},
        {"data": "band", "title": "Band",  "orderable": false},
        {"data": "ipurl", "title": "IP/URL",  "orderable": false},
        {"data": "apList", "title": "AP List",  "orderable": false}
      ]
    }
  ]
}
})
