define({
"Redundancy_Error_Template":
  {
        "errorCode": "Not found",
        "message": "Request data not found",
        "detail": "",
        "statusCode": 404
  },
"ROGUE_Telemetry_Subscription":
  [
    {
      "instanceUuid": "250a3322-2145-4cc9-bc23-7f34abb1fdd5",
      "instanceId": 558558,
      "authEntityId": 558558,
      "displayName": "558558",
      "authEntityClass": 593091854,
      "instanceTenantId": "5e746c8b00b88600c019b63f",
      "_orderedListOEIndex": 0,
      "_orderedListOEAssocName": null,
      "_creationOrderIndex": 0,
      "_isBeingChanged": true,
      "deployPending": "NONE",
      "instanceCreatedOn": 1584710543087,
      "instanceUpdatedOn": 1584710543087,
      "changeLogList": null,
      "instanceOrigin": null,
      "lazyLoadedEntities": null,
      "instanceVersion": 58,
      "active": true,
      "appName": "ROGUE",
      "subscriptionJson": "{\"wlcSubscriptions\":{\"8.8\":[{\"name\":\"rogues\",\"syncInterval\":\"300\"},{\"name\":\"rogue-events\",\"syncInterval\":\"30\"},{\"name\":\"rogue-clients\",\"syncInterval\":\"300\"},{\"name\":\"rogue-client-events\",\"syncInterval\":\"30\"}]},\"ewlcSubscriptions\":{\"16.10\":[{\"subscriptionId\":9991,\"receiverPort\":25103,\"stream\":\"native\",\"encoding\":\"encode-tdl\",\"filterValue\":\"/services;serviceName=rogue_oper/rogue_client_data\",\"filterChoice\":\"tdl-uri\",\"triggerChoice\":\"no-synch-on-start\",\"triggerValue\":\"false\",\"receiverProtocol\":\"tls-native\",\"receiverSecurityProfile\":\"sdn-network-infra-iwan\"},{\"subscriptionId\":9992,\"receiverPort\":25103,\"stream\":\"native\",\"encoding\":\"encode-tdl\",\"filterValue\":\"/services;serviceName=rogue_oper/rogue_data\",\"filterChoice\":\"tdl-uri\",\"triggerChoice\":\"no-synch-on-start\",\"triggerValue\":\"false\",\"receiverProtocol\":\"tls-native\",\"receiverSecurityProfile\":\"sdn-network-infra-iwan\"}]}}",
      "internalKey": {
        "type": "AppTelemetrySubscription",
        "id": 558558,
        "longType": "com.cisco.enc.subscription.model.AppTelemetrySubscription",
        "url": "../../AppTelemetrySubscription/558558"
      }
    }
  ],
  "AWIPS_Telemetry_Subscription":
  [
    {
      "instanceUuid": "c3151f4f-bf3e-4900-888d-fe77e9ae9dc9",
      "instanceId": 558559,
      "authEntityId": 558559,
      "displayName": "558559",
      "authEntityClass": 593091854,
      "instanceTenantId": "5e746c8b00b88600c019b63f",
      "_orderedListOEIndex": 0,
      "_orderedListOEAssocName": null,
      "_creationOrderIndex": 0,
      "_isBeingChanged": true,
      "deployPending": "NONE",
      "instanceCreatedOn": 1584710550914,
      "instanceUpdatedOn": 1584710550914,
      "changeLogList": null,
      "instanceOrigin": null,
      "lazyLoadedEntities": null,
      "instanceVersion": 32,
      "active": true,
      "appName": "AWIPS",
      "subscriptionJson": "{\"wlcSubscriptions\":null,\"ewlcSubscriptions\":{\"17.1\":[{\"subscriptionId\":9993,\"receiverPort\":25103,\"stream\":\"native\",\"encoding\":\"encode-tdl\",\"filterValue\":\"/services;serviceName=ewlc_oper/awips_alarm\",\"filterChoice\":\"tdl-uri\",\"triggerChoice\":\"no-synch-on-start\",\"triggerValue\":\"false\",\"receiverProtocol\":\"tls-native\",\"receiverSecurityProfile\":\"sdn-network-infra-iwan\"}]}}",
      "internalKey": {
        "type": "AppTelemetrySubscription",
        "id": 558559,
        "longType": "com.cisco.enc.subscription.model.AppTelemetrySubscription",
        "url": "../../AppTelemetrySubscription/558559"
      }
    }
  ]
});