define({
    "ScalableGroup_Application_Group_Template":  [
    {
      "id": "000c15bf-28fe-4fbb-9b69-35e6502721f1",
      "instanceId": 4034,
      "authEntityId": 4034,
      "displayName": "4034",
      "authEntityClass": -1909073334,
      "deployPending": "NONE",
      "instanceVersion": 1,
      "createTime": 1517966739948,
      "deployed": false,
      "isSeeded": true,
      "isStale": false,
      "lastUpdateTime": 1517966739948,
      "name": "general-misc",
      "namespace": "scalablegroup:application",
      "provisioningState": "DEFINED",
      "qualifier": "application",
      "resourceVersion": 1,
      "targetIdList": [

      ],
      "type": "scalablegroup",
      "cfsChangeInfo": [

      ],
      "customProvisions": [

      ],
      "scalableGroupExternalHandle": "general-misc",
      "scalableGroupType": "APPLICATION_GROUP",
      "securityGroupTag": 0,
      "state": "ACTIVE",
      "vnAgnostic": true,
      "identitySource": {
        "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
        "instanceId": 10010,
        "authEntityId": 10010,
        "displayName": "10010",
        "authEntityClass": 1738953278,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "state": "INACTIVE",
        "type": "NBAR"
      },
      "indicativeNetworkIdentity": [

      ],
      "networkApplications": [

      ],
      "networkIdentity": [

      ],
      "user": [

      ],
      "userGroup": [

      ]
    },
    {
      "id": "0bd0e6c9-5083-492b-97cb-a88928bee99c",
      "instanceId": 4190,
      "authEntityId": 4190,
      "displayName": "4190",
      "authEntityClass": -1909073334,
      "deployPending": "NONE",
      "instanceVersion": 1,
      "createTime": 1517966741628,
      "deployed": false,
      "isSeeded": true,
      "isStale": false,
      "lastUpdateTime": 1517966741628,
      "name": "consumer-gaming",
      "namespace": "scalablegroup:application",
      "provisioningState": "DEFINED",
      "qualifier": "application",
      "resourceVersion": 1,
      "targetIdList": [

      ],
      "type": "scalablegroup",
      "cfsChangeInfo": [

      ],
      "customProvisions": [

      ],
      "scalableGroupExternalHandle": "consumer-gaming",
      "scalableGroupType": "APPLICATION_GROUP",
      "securityGroupTag": 0,
      "state": "ACTIVE",
      "vnAgnostic": true,
      "identitySource": {
        "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
        "instanceId": 10010,
        "authEntityId": 10010,
        "displayName": "10010",
        "authEntityClass": 1738953278,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "state": "INACTIVE",
        "type": "NBAR"
      },
      "indicativeNetworkIdentity": [

      ],
      "networkApplications": [

      ],
      "networkIdentity": [

      ],
      "user": [

      ],
      "userGroup": [

      ]
    },
    {
      "id": "28e08de4-d7b2-4521-84ce-bc1e5ee0b9cc",
      "instanceId": 4038,
      "authEntityId": 4038,
      "displayName": "4038",
      "authEntityClass": -1909073334,
      "deployPending": "NONE",
      "instanceVersion": 1,
      "createTime": 1517966740525,
      "deployed": false,
      "isSeeded": true,
      "isStale": false,
      "lastUpdateTime": 1517966740525,
      "name": "consumer-browsing",
      "namespace": "scalablegroup:application",
      "provisioningState": "DEFINED",
      "qualifier": "application",
      "resourceVersion": 1,
      "targetIdList": [

      ],
      "type": "scalablegroup",
      "cfsChangeInfo": [

      ],
      "customProvisions": [

      ],
      "scalableGroupExternalHandle": "consumer-browsing",
      "scalableGroupType": "APPLICATION_GROUP",
      "securityGroupTag": 0,
      "state": "ACTIVE",
      "vnAgnostic": true,
      "identitySource": {
        "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
        "instanceId": 10010,
        "authEntityId": 10010,
        "displayName": "10010",
        "authEntityClass": 1738953278,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "state": "INACTIVE",
        "type": "NBAR"
      },
      "indicativeNetworkIdentity": [

      ],
      "networkApplications": [

      ],
      "networkIdentity": [

      ],
      "user": [

      ],
      "userGroup": [

      ]
    },
    {
      "id": "383363b1-4dd5-49d1-bfe4-34daac437cfb",
      "instanceId": 4057,
      "authEntityId": 4057,
      "displayName": "4057",
      "authEntityClass": -1909073334,
      "deployPending": "NONE",
      "instanceVersion": 1,
      "createTime": 1517966740658,
      "deployed": false,
      "isSeeded": true,
      "isStale": false,
      "lastUpdateTime": 1517966740658,
      "name": "file-sharing",
      "namespace": "scalablegroup:application",
      "provisioningState": "DEFINED",
      "qualifier": "application",
      "resourceVersion": 1,
      "targetIdList": [

      ],
      "type": "scalablegroup",
      "cfsChangeInfo": [

      ],
      "customProvisions": [

      ],
      "scalableGroupExternalHandle": "file-sharing",
      "scalableGroupType": "APPLICATION_GROUP",
      "securityGroupTag": 0,
      "state": "ACTIVE",
      "vnAgnostic": true,
      "identitySource": {
        "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
        "instanceId": 10010,
        "authEntityId": 10010,
        "displayName": "10010",
        "authEntityClass": 1738953278,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "state": "INACTIVE",
        "type": "NBAR"
      },
      "indicativeNetworkIdentity": [

      ],
      "networkApplications": [

      ],
      "networkIdentity": [

      ],
      "user": [

      ],
      "userGroup": [

      ]
    },
    {
      "id": "38b9eb6b-f3b6-4e13-9b79-a11e0c6423d4",
      "instanceId": 4309,
      "authEntityId": 4309,
      "displayName": "4309",
      "authEntityClass": -1909073334,
      "deployPending": "NONE",
      "instanceVersion": 1,
      "createTime": 1517966742235,
      "deployed": false,
      "isSeeded": true,
      "isStale": false,
      "lastUpdateTime": 1517966742235,
      "name": "naming-services",
      "namespace": "scalablegroup:application",
      "provisioningState": "DEFINED",
      "qualifier": "application",
      "resourceVersion": 1,
      "targetIdList": [

      ],
      "type": "scalablegroup",
      "cfsChangeInfo": [

      ],
      "customProvisions": [

      ],
      "scalableGroupExternalHandle": "naming-services",
      "scalableGroupType": "APPLICATION_GROUP",
      "securityGroupTag": 0,
      "state": "ACTIVE",
      "vnAgnostic": true,
      "identitySource": {
        "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
        "instanceId": 10010,
        "authEntityId": 10010,
        "displayName": "10010",
        "authEntityClass": 1738953278,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "state": "INACTIVE",
        "type": "NBAR"
      },
      "indicativeNetworkIdentity": [

      ],
      "networkApplications": [

      ],
      "networkIdentity": [

      ],
      "user": [

      ],
      "userGroup": [

      ]
    },
    {
      "id": "4a68bc73-53c6-4deb-9983-784a0338aefe",
      "instanceId": 4085,
      "authEntityId": 4085,
      "displayName": "4085",
      "authEntityClass": -1909073334,
      "deployPending": "NONE",
      "instanceVersion": 1,
      "createTime": 1517966740961,
      "deployed": false,
      "isSeeded": true,
      "isStale": false,
      "lastUpdateTime": 1517966740961,
      "name": "software-updates",
      "namespace": "scalablegroup:application",
      "provisioningState": "DEFINED",
      "qualifier": "application",
      "resourceVersion": 1,
      "targetIdList": [

      ],
      "type": "scalablegroup",
      "cfsChangeInfo": [

      ],
      "customProvisions": [

      ],
      "scalableGroupExternalHandle": "software-updates",
      "scalableGroupType": "APPLICATION_GROUP",
      "securityGroupTag": 0,
      "state": "ACTIVE",
      "vnAgnostic": true,
      "identitySource": {
        "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
        "instanceId": 10010,
        "authEntityId": 10010,
        "displayName": "10010",
        "authEntityClass": 1738953278,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "state": "INACTIVE",
        "type": "NBAR"
      },
      "indicativeNetworkIdentity": [

      ],
      "networkApplications": [

      ],
      "networkIdentity": [

      ],
      "user": [

      ],
      "userGroup": [

      ]
    },
    {
      "id": "4a6b37c6-7e11-40fe-a6b1-719b24813145",
      "instanceId": 4104,
      "authEntityId": 4104,
      "displayName": "4104",
      "authEntityClass": -1909073334,
      "deployPending": "NONE",
      "instanceVersion": 1,
      "createTime": 1517966741138,
      "deployed": false,
      "isSeeded": true,
      "isStale": false,
      "lastUpdateTime": 1517966741138,
      "name": "desktop-virtualization-apps",
      "namespace": "scalablegroup:application",
      "provisioningState": "DEFINED",
      "qualifier": "application",
      "resourceVersion": 1,
      "targetIdList": [

      ],
      "type": "scalablegroup",
      "cfsChangeInfo": [

      ],
      "customProvisions": [

      ],
      "scalableGroupExternalHandle": "desktop-virtualization-apps",
      "scalableGroupType": "APPLICATION_GROUP",
      "securityGroupTag": 0,
      "state": "ACTIVE",
      "vnAgnostic": true,
      "identitySource": {
        "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
        "instanceId": 10010,
        "authEntityId": 10010,
        "displayName": "10010",
        "authEntityClass": 1738953278,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "state": "INACTIVE",
        "type": "NBAR"
      },
      "indicativeNetworkIdentity": [

      ],
      "networkApplications": [

      ],
      "networkIdentity": [

      ],
      "user": [

      ],
      "userGroup": [

      ]
    },
    {
      "id": "53f03553-069a-4046-ad7c-84610eb579c5",
      "instanceId": 4078,
      "authEntityId": 4078,
      "displayName": "4078",
      "authEntityClass": -1909073334,
      "deployPending": "NONE",
      "instanceVersion": 1,
      "createTime": 1517966740927,
      "deployed": false,
      "isSeeded": true,
      "isStale": false,
      "lastUpdateTime": 1517966740927,
      "name": "general-browsing",
      "namespace": "scalablegroup:application",
      "provisioningState": "DEFINED",
      "qualifier": "application",
      "resourceVersion": 1,
      "targetIdList": [

      ],
      "type": "scalablegroup",
      "cfsChangeInfo": [

      ],
      "customProvisions": [

      ],
      "scalableGroupExternalHandle": "general-browsing",
      "scalableGroupType": "APPLICATION_GROUP",
      "securityGroupTag": 0,
      "state": "ACTIVE",
      "vnAgnostic": true,
      "identitySource": {
        "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
        "instanceId": 10010,
        "authEntityId": 10010,
        "displayName": "10010",
        "authEntityClass": 1738953278,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "state": "INACTIVE",
        "type": "NBAR"
      },
      "indicativeNetworkIdentity": [

      ],
      "networkApplications": [

      ],
      "networkIdentity": [

      ],
      "user": [

      ],
      "userGroup": [

      ]
    },
    {
      "id": "5e66d25d-d517-482b-82a8-6f99f07fa786",
      "instanceId": 4102,
      "authEntityId": 4102,
      "displayName": "4102",
      "authEntityClass": -1909073334,
      "deployPending": "NONE",
      "instanceVersion": 1,
      "createTime": 1517966741125,
      "deployed": false,
      "isSeeded": true,
      "isStale": false,
      "lastUpdateTime": 1517966741125,
      "name": "consumer-misc",
      "namespace": "scalablegroup:application",
      "provisioningState": "DEFINED",
      "qualifier": "application",
      "resourceVersion": 1,
      "targetIdList": [

      ],
      "type": "scalablegroup",
      "cfsChangeInfo": [

      ],
      "customProvisions": [

      ],
      "scalableGroupExternalHandle": "consumer-misc",
      "scalableGroupType": "APPLICATION_GROUP",
      "securityGroupTag": 0,
      "state": "ACTIVE",
      "vnAgnostic": true,
      "identitySource": {
        "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
        "instanceId": 10010,
        "authEntityId": 10010,
        "displayName": "10010",
        "authEntityClass": 1738953278,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "state": "INACTIVE",
        "type": "NBAR"
      },
      "indicativeNetworkIdentity": [

      ],
      "networkApplications": [

      ],
      "networkIdentity": [

      ],
      "user": [

      ],
      "userGroup": [

      ]
    },
    {
      "id": "5fcd74d4-8726-47f7-a088-c457863da7ef",
      "instanceId": 4047,
      "authEntityId": 4047,
      "displayName": "4047",
      "authEntityClass": -1909073334,
      "deployPending": "NONE",
      "instanceVersion": 1,
      "createTime": 1517966740591,
      "deployed": false,
      "isSeeded": true,
      "isStale": false,
      "lastUpdateTime": 1517966740591,
      "name": "network-management",
      "namespace": "scalablegroup:application",
      "provisioningState": "DEFINED",
      "qualifier": "application",
      "resourceVersion": 1,
      "targetIdList": [

      ],
      "type": "scalablegroup",
      "cfsChangeInfo": [

      ],
      "customProvisions": [

      ],
      "scalableGroupExternalHandle": "network-management",
      "scalableGroupType": "APPLICATION_GROUP",
      "securityGroupTag": 0,
      "state": "ACTIVE",
      "vnAgnostic": true,
      "identitySource": {
        "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
        "instanceId": 10010,
        "authEntityId": 10010,
        "displayName": "10010",
        "authEntityClass": 1738953278,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "state": "INACTIVE",
        "type": "NBAR"
      },
      "indicativeNetworkIdentity": [

      ],
      "networkApplications": [

      ],
      "networkIdentity": [

      ],
      "user": [

      ],
      "userGroup": [

      ]
    },
    {
      "id": "60f1364d-2a02-4063-8ba1-07817abe0612",
      "instanceId": 4061,
      "authEntityId": 4061,
      "displayName": "4061",
      "authEntityClass": -1909073334,
      "deployPending": "NONE",
      "instanceVersion": 1,
      "createTime": 1517966740722,
      "deployed": false,
      "isSeeded": true,
      "isStale": false,
      "lastUpdateTime": 1517966740722,
      "name": "collaboration-apps",
      "namespace": "scalablegroup:application",
      "provisioningState": "DEFINED",
      "qualifier": "application",
      "resourceVersion": 1,
      "targetIdList": [

      ],
      "type": "scalablegroup",
      "cfsChangeInfo": [

      ],
      "customProvisions": [

      ],
      "scalableGroupExternalHandle": "collaboration-apps",
      "scalableGroupType": "APPLICATION_GROUP",
      "securityGroupTag": 0,
      "state": "ACTIVE",
      "vnAgnostic": true,
      "identitySource": {
        "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
        "instanceId": 10010,
        "authEntityId": 10010,
        "displayName": "10010",
        "authEntityClass": 1738953278,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "state": "INACTIVE",
        "type": "NBAR"
      },
      "indicativeNetworkIdentity": [

      ],
      "networkApplications": [

      ],
      "networkIdentity": [

      ],
      "user": [

      ],
      "userGroup": [

      ]
    },
    {
      "id": "7788bf2d-e289-4597-b500-17aacb7e820e",
      "instanceId": 4055,
      "authEntityId": 4055,
      "displayName": "4055",
      "authEntityClass": -1909073334,
      "deployPending": "NONE",
      "instanceVersion": 1,
      "createTime": 1517966740643,
      "deployed": false,
      "isSeeded": true,
      "isStale": false,
      "lastUpdateTime": 1517966740643,
      "name": "authentication-services",
      "namespace": "scalablegroup:application",
      "provisioningState": "DEFINED",
      "qualifier": "application",
      "resourceVersion": 1,
      "targetIdList": [

      ],
      "type": "scalablegroup",
      "cfsChangeInfo": [

      ],
      "customProvisions": [

      ],
      "scalableGroupExternalHandle": "authentication-services",
      "scalableGroupType": "APPLICATION_GROUP",
      "securityGroupTag": 0,
      "state": "ACTIVE",
      "vnAgnostic": true,
      "identitySource": {
        "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
        "instanceId": 10010,
        "authEntityId": 10010,
        "displayName": "10010",
        "authEntityClass": 1738953278,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "state": "INACTIVE",
        "type": "NBAR"
      },
      "indicativeNetworkIdentity": [

      ],
      "networkApplications": [

      ],
      "networkIdentity": [

      ],
      "user": [

      ],
      "userGroup": [

      ]
    },
    {
      "id": "77e75842-e98b-4112-ad0c-300db4bb11be",
      "instanceId": 4091,
      "authEntityId": 4091,
      "displayName": "4091",
      "authEntityClass": -1909073334,
      "deployPending": "NONE",
      "instanceVersion": 1,
      "createTime": 1517966741021,
      "deployed": false,
      "isSeeded": true,
      "isStale": false,
      "lastUpdateTime": 1517966741021,
      "name": "network-control",
      "namespace": "scalablegroup:application",
      "provisioningState": "DEFINED",
      "qualifier": "application",
      "resourceVersion": 1,
      "targetIdList": [

      ],
      "type": "scalablegroup",
      "cfsChangeInfo": [

      ],
      "customProvisions": [

      ],
      "scalableGroupExternalHandle": "network-control",
      "scalableGroupType": "APPLICATION_GROUP",
      "securityGroupTag": 0,
      "state": "ACTIVE",
      "vnAgnostic": true,
      "identitySource": {
        "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
        "instanceId": 10010,
        "authEntityId": 10010,
        "displayName": "10010",
        "authEntityClass": 1738953278,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "state": "INACTIVE",
        "type": "NBAR"
      },
      "indicativeNetworkIdentity": [

      ],
      "networkApplications": [

      ],
      "networkIdentity": [

      ],
      "user": [

      ],
      "userGroup": [

      ]
    },
    {
      "id": "79f9d505-477f-4ccd-bd2a-c5fc8097a2e1",
      "instanceId": 4153,
      "authEntityId": 4153,
      "displayName": "4153",
      "authEntityClass": -1909073334,
      "deployPending": "NONE",
      "instanceVersion": 1,
      "createTime": 1517966741414,
      "deployed": false,
      "isSeeded": true,
      "isStale": false,
      "lastUpdateTime": 1517966741414,
      "name": "tunneling",
      "namespace": "scalablegroup:application",
      "provisioningState": "DEFINED",
      "qualifier": "application",
      "resourceVersion": 1,
      "targetIdList": [

      ],
      "type": "scalablegroup",
      "cfsChangeInfo": [

      ],
      "customProvisions": [

      ],
      "scalableGroupExternalHandle": "tunneling",
      "scalableGroupType": "APPLICATION_GROUP",
      "securityGroupTag": 0,
      "state": "ACTIVE",
      "vnAgnostic": true,
      "identitySource": {
        "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
        "instanceId": 10010,
        "authEntityId": 10010,
        "displayName": "10010",
        "authEntityClass": 1738953278,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "state": "INACTIVE",
        "type": "NBAR"
      },
      "indicativeNetworkIdentity": [

      ],
      "networkApplications": [

      ],
      "networkIdentity": [

      ],
      "user": [

      ],
      "userGroup": [

      ]
    },
    {
      "id": "7a1a3bbb-dc84-4a35-82a4-7e650c4c4761",
      "instanceId": 4043,
      "authEntityId": 4043,
      "displayName": "4043",
      "authEntityClass": -1909073334,
      "deployPending": "NONE",
      "instanceVersion": 1,
      "createTime": 1517966740563,
      "deployed": false,
      "isSeeded": true,
      "isStale": false,
      "lastUpdateTime": 1517966740563,
      "name": "consumer-file-sharing",
      "namespace": "scalablegroup:application",
      "provisioningState": "DEFINED",
      "qualifier": "application",
      "resourceVersion": 1,
      "targetIdList": [

      ],
      "type": "scalablegroup",
      "cfsChangeInfo": [

      ],
      "customProvisions": [

      ],
      "scalableGroupExternalHandle": "consumer-file-sharing",
      "scalableGroupType": "APPLICATION_GROUP",
      "securityGroupTag": 0,
      "state": "ACTIVE",
      "vnAgnostic": true,
      "identitySource": {
        "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
        "instanceId": 10010,
        "authEntityId": 10010,
        "displayName": "10010",
        "authEntityClass": 1738953278,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "state": "INACTIVE",
        "type": "NBAR"
      },
      "indicativeNetworkIdentity": [

      ],
      "networkApplications": [

      ],
      "networkIdentity": [

      ],
      "user": [

      ],
      "userGroup": [

      ]
    },
    {
      "id": "82348eb1-d8b8-4daf-8532-15e1a5f9cdd0",
      "instanceId": 4192,
      "authEntityId": 4192,
      "displayName": "4192",
      "authEntityClass": -1909073334,
      "deployPending": "NONE",
      "instanceVersion": 1,
      "createTime": 1517966741638,
      "deployed": false,
      "isSeeded": true,
      "isStale": false,
      "lastUpdateTime": 1517966741638,
      "name": "consumer-social-networking",
      "namespace": "scalablegroup:application",
      "provisioningState": "DEFINED",
      "qualifier": "application",
      "resourceVersion": 1,
      "targetIdList": [

      ],
      "type": "scalablegroup",
      "cfsChangeInfo": [

      ],
      "customProvisions": [

      ],
      "scalableGroupExternalHandle": "consumer-social-networking",
      "scalableGroupType": "APPLICATION_GROUP",
      "securityGroupTag": 0,
      "state": "ACTIVE",
      "vnAgnostic": true,
      "identitySource": {
        "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
        "instanceId": 10010,
        "authEntityId": 10010,
        "displayName": "10010",
        "authEntityClass": 1738953278,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "state": "INACTIVE",
        "type": "NBAR"
      },
      "indicativeNetworkIdentity": [

      ],
      "networkApplications": [

      ],
      "networkIdentity": [

      ],
      "user": [

      ],
      "userGroup": [

      ]
    },
    {
      "id": "8718395e-683e-4a2d-a232-100202751271",
      "instanceId": 4213,
      "authEntityId": 4213,
      "displayName": "4213",
      "authEntityClass": -1909073334,
      "deployPending": "NONE",
      "instanceVersion": 1,
      "createTime": 1517966741760,
      "deployed": false,
      "isSeeded": true,
      "isStale": false,
      "lastUpdateTime": 1517966741760,
      "name": "database-apps",
      "namespace": "scalablegroup:application",
      "provisioningState": "DEFINED",
      "qualifier": "application",
      "resourceVersion": 1,
      "targetIdList": [

      ],
      "type": "scalablegroup",
      "cfsChangeInfo": [

      ],
      "customProvisions": [

      ],
      "scalableGroupExternalHandle": "database-apps",
      "scalableGroupType": "APPLICATION_GROUP",
      "securityGroupTag": 0,
      "state": "ACTIVE",
      "vnAgnostic": true,
      "identitySource": {
        "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
        "instanceId": 10010,
        "authEntityId": 10010,
        "displayName": "10010",
        "authEntityClass": 1738953278,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "state": "INACTIVE",
        "type": "NBAR"
      },
      "indicativeNetworkIdentity": [

      ],
      "networkApplications": [

      ],
      "networkIdentity": [

      ],
      "user": [

      ],
      "userGroup": [

      ]
    },
    {
      "id": "8a7ca43a-32bd-40d2-871a-02220a165cb7",
      "instanceId": 4195,
      "authEntityId": 4195,
      "displayName": "4195",
      "authEntityClass": -1909073334,
      "deployPending": "NONE",
      "instanceVersion": 1,
      "createTime": 1517966741651,
      "deployed": false,
      "isSeeded": true,
      "isStale": false,
      "lastUpdateTime": 1517966741651,
      "name": "email",
      "namespace": "scalablegroup:application",
      "provisioningState": "DEFINED",
      "qualifier": "application",
      "resourceVersion": 1,
      "targetIdList": [

      ],
      "type": "scalablegroup",
      "cfsChangeInfo": [

      ],
      "customProvisions": [

      ],
      "scalableGroupExternalHandle": "email",
      "scalableGroupType": "APPLICATION_GROUP",
      "securityGroupTag": 0,
      "state": "ACTIVE",
      "vnAgnostic": true,
      "identitySource": {
        "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
        "instanceId": 10010,
        "authEntityId": 10010,
        "displayName": "10010",
        "authEntityClass": 1738953278,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "state": "INACTIVE",
        "type": "NBAR"
      },
      "indicativeNetworkIdentity": [

      ],
      "networkApplications": [

      ],
      "networkIdentity": [

      ],
      "user": [

      ],
      "userGroup": [

      ]
    },
    {
      "id": "961b652e-1019-451e-86da-f9ff537016d2",
      "instanceId": 4235,
      "authEntityId": 4235,
      "displayName": "4235",
      "authEntityClass": -1909073334,
      "deployPending": "NONE",
      "instanceVersion": 1,
      "createTime": 1517966741879,
      "deployed": false,
      "isSeeded": true,
      "isStale": false,
      "lastUpdateTime": 1517966741879,
      "name": "streaming-media",
      "namespace": "scalablegroup:application",
      "provisioningState": "DEFINED",
      "qualifier": "application",
      "resourceVersion": 1,
      "targetIdList": [

      ],
      "type": "scalablegroup",
      "cfsChangeInfo": [

      ],
      "customProvisions": [

      ],
      "scalableGroupExternalHandle": "streaming-media",
      "scalableGroupType": "APPLICATION_GROUP",
      "securityGroupTag": 0,
      "state": "ACTIVE",
      "vnAgnostic": true,
      "identitySource": {
        "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
        "instanceId": 10010,
        "authEntityId": 10010,
        "displayName": "10010",
        "authEntityClass": 1738953278,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "state": "INACTIVE",
        "type": "NBAR"
      },
      "indicativeNetworkIdentity": [

      ],
      "networkApplications": [

      ],
      "networkIdentity": [

      ],
      "user": [

      ],
      "userGroup": [

      ]
    },
    {
      "id": "a3243a74-3864-4041-a927-0201030da17c",
      "instanceId": 4327,
      "authEntityId": 4327,
      "displayName": "4327",
      "authEntityClass": -1909073334,
      "deployPending": "NONE",
      "instanceVersion": 1,
      "createTime": 1517966742297,
      "deployed": false,
      "isSeeded": true,
      "isStale": false,
      "lastUpdateTime": 1517966742297,
      "name": "signaling",
      "namespace": "scalablegroup:application",
      "provisioningState": "DEFINED",
      "qualifier": "application",
      "resourceVersion": 1,
      "targetIdList": [

      ],
      "type": "scalablegroup",
      "cfsChangeInfo": [

      ],
      "customProvisions": [

      ],
      "scalableGroupExternalHandle": "signaling",
      "scalableGroupType": "APPLICATION_GROUP",
      "securityGroupTag": 0,
      "state": "ACTIVE",
      "vnAgnostic": true,
      "identitySource": {
        "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
        "instanceId": 10010,
        "authEntityId": 10010,
        "displayName": "10010",
        "authEntityClass": 1738953278,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "state": "INACTIVE",
        "type": "NBAR"
      },
      "indicativeNetworkIdentity": [

      ],
      "networkApplications": [

      ],
      "networkIdentity": [

      ],
      "user": [

      ],
      "userGroup": [

      ]
    },
    {
      "id": "aad510ce-fe95-4dfc-ad06-e494641cc016",
      "instanceId": 4140,
      "authEntityId": 4140,
      "displayName": "4140",
      "authEntityClass": -1909073334,
      "deployPending": "NONE",
      "instanceVersion": 1,
      "createTime": 1517966741363,
      "deployed": false,
      "isSeeded": true,
      "isStale": false,
      "lastUpdateTime": 1517966741363,
      "name": "general-media",
      "namespace": "scalablegroup:application",
      "provisioningState": "DEFINED",
      "qualifier": "application",
      "resourceVersion": 1,
      "targetIdList": [

      ],
      "type": "scalablegroup",
      "cfsChangeInfo": [

      ],
      "customProvisions": [

      ],
      "scalableGroupExternalHandle": "general-media",
      "scalableGroupType": "APPLICATION_GROUP",
      "securityGroupTag": 0,
      "state": "ACTIVE",
      "vnAgnostic": true,
      "identitySource": {
        "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
        "instanceId": 10010,
        "authEntityId": 10010,
        "displayName": "10010",
        "authEntityClass": 1738953278,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "state": "INACTIVE",
        "type": "NBAR"
      },
      "indicativeNetworkIdentity": [

      ],
      "networkApplications": [

      ],
      "networkIdentity": [

      ],
      "user": [

      ],
      "userGroup": [

      ]
    },
    {
      "id": "cff11752-fafb-4e22-9e6f-11f44f59a9a3",
      "instanceId": 4163,
      "authEntityId": 4163,
      "displayName": "4163",
      "authEntityClass": -1909073334,
      "deployPending": "NONE",
      "instanceVersion": 1,
      "createTime": 1517966741460,
      "deployed": false,
      "isSeeded": true,
      "isStale": false,
      "lastUpdateTime": 1517966741460,
      "name": "enterprise-ipc",
      "namespace": "scalablegroup:application",
      "provisioningState": "DEFINED",
      "qualifier": "application",
      "resourceVersion": 1,
      "targetIdList": [

      ],
      "type": "scalablegroup",
      "cfsChangeInfo": [

      ],
      "customProvisions": [

      ],
      "scalableGroupExternalHandle": "enterprise-ipc",
      "scalableGroupType": "APPLICATION_GROUP",
      "securityGroupTag": 0,
      "state": "ACTIVE",
      "vnAgnostic": true,
      "identitySource": {
        "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
        "instanceId": 10010,
        "authEntityId": 10010,
        "displayName": "10010",
        "authEntityClass": 1738953278,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "state": "INACTIVE",
        "type": "NBAR"
      },
      "indicativeNetworkIdentity": [

      ],
      "networkApplications": [

      ],
      "networkIdentity": [

      ],
      "user": [

      ],
      "userGroup": [

      ]
    },
    {
      "id": "dc13f401-c34c-4d18-a5a1-6e9f8624d67e",
      "instanceId": 4771,
      "authEntityId": 4771,
      "displayName": "4771",
      "authEntityClass": -1909073334,
      "deployPending": "NONE",
      "instanceVersion": 1,
      "createTime": 1517966743462,
      "deployed": false,
      "isSeeded": true,
      "isStale": false,
      "lastUpdateTime": 1517966743462,
      "name": "saas-apps",
      "namespace": "scalablegroup:application",
      "provisioningState": "DEFINED",
      "qualifier": "application",
      "resourceVersion": 1,
      "targetIdList": [

      ],
      "type": "scalablegroup",
      "cfsChangeInfo": [

      ],
      "customProvisions": [

      ],
      "scalableGroupExternalHandle": "saas-apps",
      "scalableGroupType": "APPLICATION_GROUP",
      "securityGroupTag": 0,
      "state": "ACTIVE",
      "vnAgnostic": true,
      "identitySource": {
        "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
        "instanceId": 10010,
        "authEntityId": 10010,
        "displayName": "10010",
        "authEntityClass": 1738953278,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "state": "INACTIVE",
        "type": "NBAR"
      },
      "indicativeNetworkIdentity": [

      ],
      "networkApplications": [

      ],
      "networkIdentity": [

      ],
      "user": [

      ],
      "userGroup": [

      ]
    },
    {
      "id": "e06dd89f-0a70-4422-ab0b-c2314650a9a7",
      "instanceId": 4073,
      "authEntityId": 4073,
      "displayName": "4073",
      "authEntityClass": -1909073334,
      "deployPending": "NONE",
      "instanceVersion": 1,
      "createTime": 1517966740844,
      "deployed": false,
      "isSeeded": true,
      "isStale": false,
      "lastUpdateTime": 1517966740844,
      "name": "remote-access",
      "namespace": "scalablegroup:application",
      "provisioningState": "DEFINED",
      "qualifier": "application",
      "resourceVersion": 1,
      "targetIdList": [

      ],
      "type": "scalablegroup",
      "cfsChangeInfo": [

      ],
      "customProvisions": [

      ],
      "scalableGroupExternalHandle": "remote-access",
      "scalableGroupType": "APPLICATION_GROUP",
      "securityGroupTag": 0,
      "state": "ACTIVE",
      "vnAgnostic": true,
      "identitySource": {
        "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
        "instanceId": 10010,
        "authEntityId": 10010,
        "displayName": "10010",
        "authEntityClass": 1738953278,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "state": "INACTIVE",
        "type": "NBAR"
      },
      "indicativeNetworkIdentity": [

      ],
      "networkApplications": [

      ],
      "networkIdentity": [

      ],
      "user": [

      ],
      "userGroup": [

      ]
    },
    {
      "id": "e4e23452-2fdf-4d92-88d8-a7d24426ab72",
      "instanceId": 4559,
      "authEntityId": 4559,
      "displayName": "4559",
      "authEntityClass": -1909073334,
      "deployPending": "NONE",
      "instanceVersion": 1,
      "createTime": 1517966742989,
      "deployed": false,
      "isSeeded": true,
      "isStale": false,
      "lastUpdateTime": 1517966742989,
      "name": "local-services",
      "namespace": "scalablegroup:application",
      "provisioningState": "DEFINED",
      "qualifier": "application",
      "resourceVersion": 1,
      "targetIdList": [

      ],
      "type": "scalablegroup",
      "cfsChangeInfo": [

      ],
      "customProvisions": [

      ],
      "scalableGroupExternalHandle": "local-services",
      "scalableGroupType": "APPLICATION_GROUP",
      "securityGroupTag": 0,
      "state": "ACTIVE",
      "vnAgnostic": true,
      "identitySource": {
        "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
        "instanceId": 10010,
        "authEntityId": 10010,
        "displayName": "10010",
        "authEntityClass": 1738953278,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "state": "INACTIVE",
        "type": "NBAR"
      },
      "indicativeNetworkIdentity": [

      ],
      "networkApplications": [

      ],
      "networkIdentity": [

      ],
      "user": [

      ],
      "userGroup": [

      ]
    },
    {
      "id": "f09a36de-df38-4ff8-b0be-56738afc75f7",
      "instanceId": 4065,
      "authEntityId": 4065,
      "displayName": "4065",
      "authEntityClass": -1909073334,
      "deployPending": "NONE",
      "instanceVersion": 1,
      "createTime": 1517966740746,
      "deployed": false,
      "isSeeded": true,
      "isStale": false,
      "lastUpdateTime": 1517966740746,
      "name": "backup-and-storage",
      "namespace": "scalablegroup:application",
      "provisioningState": "DEFINED",
      "qualifier": "application",
      "resourceVersion": 1,
      "targetIdList": [

      ],
      "type": "scalablegroup",
      "cfsChangeInfo": [

      ],
      "customProvisions": [

      ],
      "scalableGroupExternalHandle": "backup-and-storage",
      "scalableGroupType": "APPLICATION_GROUP",
      "securityGroupTag": 0,
      "state": "ACTIVE",
      "vnAgnostic": true,
      "identitySource": {
        "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
        "instanceId": 10010,
        "authEntityId": 10010,
        "displayName": "10010",
        "authEntityClass": 1738953278,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "state": "INACTIVE",
        "type": "NBAR"
      },
      "indicativeNetworkIdentity": [

      ],
      "networkApplications": [

      ],
      "networkIdentity": [

      ],
      "user": [

      ],
      "userGroup": [

      ]
    },
    {
      "id": "fd6264c9-c16a-4311-a335-a3db039256f3",
      "instanceId": 4069,
      "authEntityId": 4069,
      "displayName": "4069",
      "authEntityClass": -1909073334,
      "deployPending": "NONE",
      "instanceVersion": 1,
      "createTime": 1517966740821,
      "deployed": false,
      "isSeeded": true,
      "isStale": false,
      "lastUpdateTime": 1517966740821,
      "name": "consumer-media",
      "namespace": "scalablegroup:application",
      "provisioningState": "DEFINED",
      "qualifier": "application",
      "resourceVersion": 1,
      "targetIdList": [

      ],
      "type": "scalablegroup",
      "cfsChangeInfo": [

      ],
      "customProvisions": [

      ],
      "scalableGroupExternalHandle": "consumer-media",
      "scalableGroupType": "APPLICATION_GROUP",
      "securityGroupTag": 0,
      "state": "ACTIVE",
      "vnAgnostic": true,
      "identitySource": {
        "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
        "instanceId": 10010,
        "authEntityId": 10010,
        "displayName": "10010",
        "authEntityClass": 1738953278,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "state": "INACTIVE",
        "type": "NBAR"
      },
      "indicativeNetworkIdentity": [

      ],
      "networkApplications": [

      ],
      "networkIdentity": [

      ],
      "user": [

      ],
      "userGroup": [

      ]
    },
    {
      "id": "fdbaf614-da09-4ba2-89bb-d1ca9e379597",
      "instanceId": 4263,
      "authEntityId": 4263,
      "displayName": "4263",
      "authEntityClass": -1909073334,
      "deployPending": "NONE",
      "instanceVersion": 1,
      "createTime": 1517966741982,
      "deployed": false,
      "isSeeded": true,
      "isStale": false,
      "lastUpdateTime": 1517966741982,
      "name": "software-development-tools",
      "namespace": "scalablegroup:application",
      "provisioningState": "DEFINED",
      "qualifier": "application",
      "resourceVersion": 1,
      "targetIdList": [

      ],
      "type": "scalablegroup",
      "cfsChangeInfo": [

      ],
      "customProvisions": [

      ],
      "scalableGroupExternalHandle": "software-development-tools",
      "scalableGroupType": "APPLICATION_GROUP",
      "securityGroupTag": 0,
      "state": "ACTIVE",
      "vnAgnostic": true,
      "identitySource": {
        "id": "cb4f92a0-1f5c-473f-bdb3-5a5294f09cd2",
        "instanceId": 10010,
        "authEntityId": 10010,
        "displayName": "10010",
        "authEntityClass": 1738953278,
        "deployPending": "NONE",
        "instanceVersion": 0,
        "state": "INACTIVE",
        "type": "NBAR"
      },
      "indicativeNetworkIdentity": [

      ],
      "networkApplications": [

      ],
      "networkIdentity": [

      ],
      "user": [

      ],
      "userGroup": [

      ]
    }
  ]
  });