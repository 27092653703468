define({
  "Template": {
    "records": [

    ],
    "request": {
      "entity": {
        "macAddress": "00:3A:7D:87:D2:90"
      },
      "name": "wlc_ap_connectivity",
      "dimensions": [

      ],
      "window": "5 min",
      "timeRange": {
        "start": "2018-06-24T09:04:34.958Z",
        "end": "2018-06-25T09:04:34.958Z"
      }
    },
    "pagination": {
      "links": [
        {
          "rel": "self",
          "url": "http://queryengine.ndp.svc.cluster.local:8006/api/ndp/v1/data/entities/metrics?page=1&pageSize=100&order=DESC",
          "method": "POST",
          "body": {
            "entity": {
              "macAddress": "00:3A:7D:87:D2:90"
            },
            "name": "wlc_ap_connectivity",
            "dimensions": [

            ],
            "window": "5 min",
            "timeRange": {
              "start": "2018-06-24T09:04:34.958Z",
              "end": "2018-06-25T09:04:34.958Z"
            },
            "values": [

            ],
            "fields": [
              "ap_status",
              "wlc",
              "event_time",
              "failure_reason"
            ]
          }
        }
      ],
      "page": 1,
      "pageSize": 100,
      "order": "DESC",
      "totalResults": 0
    }
  }
})