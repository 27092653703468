define([
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/database/SimLokiDatabaseActions',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/data-generation/services/assurance/SimAssuranceData',

], function(SimLokiDatabaseActions,SimAssuranceData){

    return {
        init: function () {
        },

        doProcessRestRequest: function (urlAction) {
            var data={};
            switch (urlAction.method) {
                case 'GET':
                    data = processGetRequest(urlAction);
                    break;
                default:
                    console.log('other requests are not handled yet..')
                    break;
            }
            return data;
        }
    };
    function processGetRequest(urlAction) {
        if(urlAction.url.indexOf('events')>-1 && urlAction.url.indexOf('tags')>-1){
            return SimAssuranceData.getEventId(urlAction);
        }
        if(urlAction.service.indexOf('artifact') > -1 || 
            urlAction.service.indexOf('subscription') > -1 || (urlAction.service.indexOf('webhook') > -1) ||
            (urlAction.service.indexOf('subscription-details') > -1) || (urlAction.service.indexOf('email-config') > -1) ||
             urlAction.service.indexOf('syslogConfig') > -1 || urlAction.service.indexOf('web-ui-events') > -1) {
            //Settings page - Event Catalog
            if(urlAction.action.count) {
                return {"response":0};
            } else if(urlAction.service.indexOf('webhook') > -1 || urlAction.service.indexOf('syslogConfig') > -1 || 
                urlAction.service.indexOf('web-ui-events') > -1) {
                return {"errorMessage":{"errors":[]},"apiStatus":"SUCCESS","statusMessage":[]};
            } else {
                return [];
            }
        }
        return {};
    }

});