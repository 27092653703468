define([
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/database/SimLokiDatabaseActions',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/utilities/UtilityFunctions',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/nfv/NfvisTemplate',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/data-generation/services/SimSiteProfileData'
], function (SimLokiDatabaseActions, UtilityFunctions, NfvisTemplate, SimSiteProfileData) {

    return {
        init: function () {
            //Creation of NFV Profile and assign site to it
            createfvProfileDefault();
        },

        getProvisionLog: function(urlAction) {
            var records = SimLokiDatabaseActions.getFilteredRecordHandler('nfv-provision-details', {'deviceId':urlAction.filter['deviceId']});
            if(urlAction.action.count) {
                return this.formatResponse(records.length);
            } else {
                var response = [], provisionDuration = 64000;//1min and 4sec
                for(var i=0; i<records.length; i++) {
                    var tJson = JSON.parse(JSON.stringify(NfvisTemplate.NFV_PROV_LOG_TEMPLATE));
                    tJson.startTime = UtilityFunctions.getCustomFormattedTime(records[i].startTime);
                    tJson.endTime = UtilityFunctions.getCustomFormattedTime(records[i].startTime + provisionDuration);
                    tJson.workflowId = records[i].workflowId;
                    response.push(tJson);
                }
                return this.formatResponse(response);
            }
        },

        getWorkFlowLevel1: function(urlAction) {
            var tJson = JSON.parse(JSON.stringify(NfvisTemplate.NFV_WORKFLOW_OVERVIEW_TEMPLATE));
            var provisionDetail = SimLokiDatabaseActions.getFilteredRecordHandler('nfv-provision-details',
                {'$and' :[{'deviceId':urlAction.filter['deviceId']}, {'workflowId':urlAction.filter['workflowId']}]});
            var deviceDetail = SimLokiDatabaseActions.getFilteredRecordHandler('network-device', {'id': urlAction.filter['deviceId']});
            if(provisionDetail && provisionDetail.length) {
                tJson.siteId = deviceDetail[0].siteId;
                tJson.siteProfileUuid = provisionDetail[0].siteProfileUuid;
                tJson.siteName = "";//to update the site name
                tJson.siteProfileName = provisionDetail[0].siteProfileName;
                tJson.deviceId = deviceDetail[0].id;
                tJson.workflowId = provisionDetail[0].workflowId;
            }
            return tJson;
        },

        getWorkFlowLevel2: function(urlAction) {
            var tJson = JSON.parse(JSON.stringify(NfvisTemplate.NFV_WORKFLOW_DETAIL_TEMPLATE));
            var provisionDetail = SimLokiDatabaseActions.getFilteredRecordHandler('nfv-provision-details', {'workflowId':urlAction.filter['workflowid']});
            if(provisionDetail && provisionDetail.length) {
                var device = SimLokiDatabaseActions.getFilteredRecordHandler('network-device', {'id':provisionDetail[0].deviceId});
                var deviceIp = device[0].managementIpAddress;
                var delayedStart = 8000;//8sec
                var taskNodeIntervals = [16381, 11, 955, 16, 1134, 11, 1090, 15, 599, 712, 18, 27, 17159, 13, 45, 1468, 430, 13, 441, 44, 14, 21, 39, 87, 24, 26];
                var startTime = provisionDetail[0].startTime + delayedStart, endTime;
                for(var i=0; i< tJson.taskNodes.length; i++) {
                    if(i<18) {
                        tJson.taskNodes[i].target = deviceIp;
                        tJson.taskNodes[i].startTime = UtilityFunctions.getCustomFormattedTime(startTime);
                        endTime = startTime + taskNodeIntervals[i];
                        tJson.taskNodes[i].endTime = UtilityFunctions.getCustomFormattedTime(endTime);
                        startTime = endTime;//updating for next iteration
                    } else if(i==18) {
                        tJson.taskNodes[i].startTime = UtilityFunctions.getCustomFormattedTime(provisionDetail[0].startTime);
                        tJson.taskNodes[i].endTime = UtilityFunctions.getCustomFormattedTime(provisionDetail[0].startTime + taskNodeIntervals[i]);
                        startTime = startTime + 3000;//next time is 3sec after the prev
                    } else {
                        tJson.taskNodes[i].startTime = UtilityFunctions.getCustomFormattedTime(startTime);
                        endTime = startTime + taskNodeIntervals[i];
                        tJson.taskNodes[i].endTime = UtilityFunctions.getCustomFormattedTime(endTime);
                        startTime = endTime;
                    }
                }
                tJson.startTime = UtilityFunctions.getTimeCommaFormatted(provisionDetail[0].startTime);
                tJson.endTime = UtilityFunctions.getTimeCommaFormatted(startTime);
                tJson.duration = UtilityFunctions.getDiffTimeFormatted(provisionDetail[0].startTime, startTime);
            }
            return tJson;
        },

        //get profile details for NFV device when provisioning. get already provisioned data if already provisioned
        getProfileDetails: function(restPayload) {
            /* var profile = SimLokiDatabaseActions.getFilteredRecordHandler('siteprofile', {'id':restPayload.siteProfileUuid});
            var json = [{"deviceId":restPayload.deviceIds[0],"siteProfile":profile[0]}];
            return json; */
            var profile = [];
            var nwProfile = SimLokiDatabaseActions.getFilteredRecordHandler('siteprofile', {'id':restPayload.siteProfileUuid});
            restPayload.deviceIds.forEach(deviceId => {
                var nfvProvObj = SimLokiDatabaseActions.getFilteredRecordHandler('nfv-provision-data', {'deviceId':deviceId});
                if(nfvProvObj.length>0 && nwProfile.length>0 && nwProfile[0].lastUpdatedDatetime < nfvProvObj[0].data.lastUpdatedDatetime) {
                    //consider prev provisioned data as there is no updation in network profile after
                    profile.push({"deviceId":deviceId,"siteProfile":nfvProvObj[0].data});
                } else {                    
                    if(nwProfile.length>0) {
                        //consider network profile data as there is modification or the device is not already provisioned
                        profile.push({"deviceId":deviceId,"siteProfile":nwProfile[0]});
                    } else {
                        //no network profile for this device/site. ideally, it will not enter this condition as it is already handled before
                        profile.push({"deviceId":deviceId,"siteProfile":[]});
                    }
                }
            });
            return profile;
        },

        formatResponse: function(data) {
            return {"response":data, "version":"1.0"}
        },

        getNetworkFunctions: function() {
            var tJson = JSON.parse(JSON.stringify(NfvisTemplate.NFV_SERVICE_NTW_FUNCS));
            for(var i=0; i<tJson.networkFunctions.length; i++) {
                var type = tJson.networkFunctions[i].type.toUpperCase();
                switch (type) {
                    case "VWAAS": 
                        type = "WAAS"; break;
                    case "ISRV": 
                        type = "ISR"; break;
                    case "NGFWV": 
                        type = "NGFW"; break;
                    case "ASAV": 
                        type = "ASA"; break;               
                    default:
                        break;
                }
                var records = SimLokiDatabaseActions.getFilteredRecordHandler('images', {'deviceFamily':type});
                if(records.length > 0) {
                    var imageTemplate;
                    if(tJson.networkFunctions[i].images.length>0) {
                        imageTemplate = JSON.parse(JSON.stringify(tJson.networkFunctions[i].images[0]));
                    } else {
                        imageTemplate = JSON.parse(JSON.stringify(NfvisTemplate.NFV_SERVICE_CONFIG_IMAGE_TEMPLATE));
                        imageTemplate.vnfType = records[0].runningImageList[0].applicationType;
                        imageTemplate.family = records[0].deviceFamily;
                    }
                    tJson.networkFunctions[i].images = [];
                    records[0].runningImageList.forEach(image => {
                        imageTemplate = JSON.parse(JSON.stringify(imageTemplate));
                        imageTemplate.imageUUID = image.imageUuid;
                        imageTemplate.name = image.name;
                        imageTemplate.profiles = image.profileInfo.map(profInfo => {
                            return { 'name':profInfo.profileName, 'description':profInfo.description, 
                            'vcpu':profInfo.vCpu, 'memoryInMb':profInfo.memory, 'shares':profInfo.shares };
                        });
                        imageTemplate.taggedGolden = image.isTaggedGolden == "true" ? true : false;
                        tJson.networkFunctions[i].images.push(imageTemplate);
                    });
                } else {
                    tJson.networkFunctions[i].images = [];
                }
            }
            return tJson;
        }

    };

    function createfvProfileDefault() {
        var tJson = JSON.parse(JSON.stringify(NfvisTemplate.NFV_SITEPROFILE));
        tJson.lastUpdatedDatetime = UtilityFunctions.getTimeStamp();
        /* note: otp, system_ip are added manually in template;
        vbond, orgname are picked from cred template and placed manually;
        uuid is picked from vedge details from ApplicationTypeTemplte.js */
        SimLokiDatabaseActions.insert('siteprofile', tJson );
        var siteRecord = SimLokiDatabaseActions.getFilteredRecordHandler('site',{name:'Flr-SJC4-1'});
        if(siteRecord && siteRecord.length > 0)
        {
            var siteId = siteRecord[0].id;
            var profileId = tJson.id;
            //Simulate the siterofile/site POST call, to add sites profile Attributes ..
            var urlAction = {url:'/api/v1/siteprofile/'+profileId+'/site/'+siteId,service:['siteprofile','site', '-1'],method:'POST'};
            SimSiteProfileData.addSiteAttributes(urlAction);
        }
        //Router profile for Mobility Express
        var tJson = JSON.parse(JSON.stringify(NfvisTemplate.ROUTER_TEMPLATE));
        tJson.lastUpdatedDatetime = UtilityFunctions.getTimeStamp();
        SimLokiDatabaseActions.insert('siteprofile', tJson );
        var siteRecord = SimLokiDatabaseActions.getFilteredRecordHandler('site',{name:'SFO10'});
        if(siteRecord && siteRecord.length > 0)
        {
            var siteId = siteRecord[0].id;
            var profileId = tJson.id;
            //Simulate the siterofile/site POST call, to add sites profile Attributes ..
            var urlAction = {url:'/api/v1/siteprofile/'+profileId+'/site/'+siteId,service:['siteprofile','site', '-1'],method:'POST'};
            SimSiteProfileData.addSiteAttributes(urlAction);
        }
    }

});
