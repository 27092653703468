define({

    "INV_INSIGHT_TEMPLATE": {
        "endPortAllowedVlanIds": null,
        "endPortNativeVlanId": null,
        "startPortAllowedVlanIds": null,
        "startPortNativeVlanId": null,
        "endDeviceHostName": "DIST1-6840XLE.ibng.local",
        "endDeviceId": "1a0ee970-c4ec-42f0-b58f-5964a9d9ddf6",
        "endDeviceIpAddress": "10.16.50.5",
        "endPortAddress": "10.16.50.81",
        "endPortDuplex": "FullDuplex",
        "endPortId": "12faa022-90d2-423b-98c6-6c2c08452673",
        "endPortMask": "255.255.255.252",
        "endPortName": "TenGigabitEthernet1/0/1",
        "endPortPepId": "288497",
        "endPortSpeed": "1000000",
        "startDeviceHostName": "C9500-SVL-ISSU.ibng.local",
        "startDeviceId": "9b4a675b-38c6-4beb-95f0-97643b7155ad",
        "startDeviceIpAddress": "10.16.50.14",
        "startPortAddress": "10.16.50.82",
        "startPortDuplex": "AutoNegotiate",
        "startPortId": "54efb55e-6a80-4ac0-aa26-8d8f27a89dc0",
        "startPortMask": "255.255.255.252",
        "startPortName": "TenGigabitEthernet3/1",
        "startPortPepId": "30201588",
        "startPortSpeed": "1000000",
        "linkStatus": "up",
        "avgUpdateFrequency": 0,
        "lastUpdated": "",
        "numUpdates": 0,
        "type": "physical",
        "id": "5f9f91e2-77f5-41f8-80bb-a4129b69dc0a",
        "instanceUuid": "5f9f91e2-77f5-41f8-80bb-a4129b69dc0a",
        "instanceTenantId": "5f35ea5f15de5300c6a0162a",
        "simSiteId": ""//field added for easy ref for simulation
    }

});