define({
"Template":
{
  "version": "1.0",
  "response": {
    "id": "AWl6tHPAKBEs-UKu18NO",
    "name": "nwDevice_ntp_not_synched",
    "enabled": true,
    "flattened": true,
    "entityType": "network_device",
    "entity": "10.0.255.52",
    "groupBy": "Unknown",
    "category": "Device",
    "severity": "HIGH",
    "summary": "Network device DeviceIPPlaceHolder is not synchronized with NTP Server 192.168.136.126",
    "scope": "LOCALIZED",
    "priority": "P3",
    "groupId": "ap_down_grouping",
    "groupName": "AP went down",
    "rootCause": "Unknown",
    "timestamp": 1552541840114,
    "description": "Network Device 'DeviceNamePlaceHolder' is not synchronized with the NTP server 192.168.136.126.",
    "suggestions": [
      {
        "message": "Verify that the network device is configured correctly with NTP.",
        "steps": [
          {
            "entityId": "DeviceIdPlaceHolder",
            "description": "Verify the NTP configuration",
            "command": "show running-config | s ntp",
            "stepType": "command-Runner"
          }
        ]
      },
      {
        "message": "Verify the NTP operational status on the network device.",
        "steps": [
          {
            "entityId": "DeviceIdPlaceHolder",
            "description": "Verify NTP Status",
            "command": "show ntp status",
            "stepType": "command-Runner"
          },
          {
            "entityId": "DeviceIdPlaceHolder",
            "description": "Verify NTP associations",
            "command": "show ntp associations",
            "stepType": "command-Runner"
          }
        ]
      }
    ],
    "additionalParams": [
      {
        "key": "managementIpAddr",
        "value": "10.0.255.52"
      },
      {
        "key": "entityType",
        "value": "network_device"
      },
      {
        "key": "__entity_type__",
        "value": "NetworkDevice"
      },
      {
        "key": "entityId",
        "value": "10.0.255.52"
      },
      {
        "key": "deviceFamily",
        "value": "Routers"
      },
      {
        "key": "platformId",
        "value": "ASR1004"
      },
      {
        "key": "priority",
        "value": "P3"
      },
      {
        "key": "type",
        "value": "Issue"
      },
      {
        "key": "ntpServer",
        "value": "192.168.136.126"
      },
      {
        "key": "category",
        "value": "Device"
      },
      {
        "key": "timestring",
        "value": "2019-03-14T05:37:20.114+0000"
      },
      {
        "key": "status",
        "value": "active"
      }
    ],
    "status": {
      "status": "ACTIVE",
      "updatedBy": "Unknown",
      "notes": "Unknown",
      "source": "Unknown",
      "updatedAt": null,
      "ignoreSource": "Unknown",
      "ignoreValue": "Unknown",
      "ignoreStartTime": null,
      "ignoreEndTime": null
    },
    "siteQulaifiedName": null
  }
}
})