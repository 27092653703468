define({
    "getSmartDashboardPeers36SConnection1":
        {
            "data": {
                "appThroughputCloud": {
                    "__typename": "SmartDashboardPeers36SConnection",
                    "nodes": [{
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.00679344529943468,
                        "binCatValue": null,
                        "binLowerBound": 0,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "All"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.0123039478797569,
                        "binCatValue": null,
                        "binLowerBound": 1.13,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "All"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.0182331291793957,
                        "binCatValue": null,
                        "binLowerBound": 2.26,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "All"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.0281843071270307,
                        "binCatValue": null,
                        "binLowerBound": 3.39,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "All"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.0351222659151642,
                        "binCatValue": null,
                        "binLowerBound": 4.52,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "All"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.0528553410264568,
                        "binCatValue": null,
                        "binLowerBound": 5.65,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "All"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.075249322193855,
                        "binCatValue": null,
                        "binLowerBound": 6.78,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "All"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.106213941595812,
                        "binCatValue": null,
                        "binLowerBound": 7.91,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "All"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.125123085982466,
                        "binCatValue": null,
                        "binLowerBound": 9.04,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "All"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.12636173486804,
                        "binCatValue": null,
                        "binLowerBound": 10.17,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "All"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.110576471373034,
                        "binCatValue": null,
                        "binLowerBound": 11.3,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "All"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.0849894001990844,
                        "binCatValue": null,
                        "binLowerBound": 12.43,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "All"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.0652974400131212,
                        "binCatValue": null,
                        "binLowerBound": 13.56,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "All"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.049971312214775,
                        "binCatValue": null,
                        "binLowerBound": 14.69,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "All"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.0375412580424938,
                        "binCatValue": null,
                        "binLowerBound": 15.82,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "All"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.0262396767016402,
                        "binCatValue": null,
                        "binLowerBound": 16.95,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "All"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.0176875185149559,
                        "binCatValue": null,
                        "binLowerBound": 18.08,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "All"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.0111456058513708,
                        "binCatValue": null,
                        "binLowerBound": 19.21,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "All"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.00604999370197055,
                        "binCatValue": null,
                        "binLowerBound": 20.34,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "All"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.00270177814243561,
                        "binCatValue": null,
                        "binLowerBound": 21.47,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "All"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.000970894604547253,
                        "binCatValue": null,
                        "binLowerBound": 22.6,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "All"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.000296409282987012,
                        "binCatValue": null,
                        "binLowerBound": 23.73,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "All"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.0000783641451908156,
                        "binCatValue": null,
                        "binLowerBound": 24.86,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "All"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.000011899110983421,
                        "binCatValue": null,
                        "binLowerBound": 25.99,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "All"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.00000128704669820676,
                        "binCatValue": null,
                        "binLowerBound": 27.12,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "All"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 1.69987299763157e-7,
                        "binCatValue": null,
                        "binLowerBound": 28.25,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "All"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 29.38,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "All"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 30.51,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "All"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 31.64,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "All"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 32.77,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "All"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 33.9,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "All"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.00425103260142015,
                        "binCatValue": null,
                        "binLowerBound": 0,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "All"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.00647514813142781,
                        "binCatValue": null,
                        "binLowerBound": 1.13,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "All"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.00913238536595712,
                        "binCatValue": null,
                        "binLowerBound": 2.26,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "All"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.0132012996731462,
                        "binCatValue": null,
                        "binLowerBound": 3.39,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "All"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.0171792963746925,
                        "binCatValue": null,
                        "binLowerBound": 4.52,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "All"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.0268880626076222,
                        "binCatValue": null,
                        "binLowerBound": 5.65,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "All"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.0472999167222752,
                        "binCatValue": null,
                        "binLowerBound": 6.78,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "All"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.0826593180381186,
                        "binCatValue": null,
                        "binLowerBound": 7.91,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "All"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.121419888973046,
                        "binCatValue": null,
                        "binLowerBound": 9.04,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "All"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.132635184257417,
                        "binCatValue": null,
                        "binLowerBound": 10.17,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "All"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.137716623886196,
                        "binCatValue": null,
                        "binLowerBound": 11.3,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "All"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.117195768443505,
                        "binCatValue": null,
                        "binLowerBound": 12.43,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "All"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.0920327989112762,
                        "binCatValue": null,
                        "binLowerBound": 13.56,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "All"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.0688159862447406,
                        "binCatValue": null,
                        "binLowerBound": 14.69,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "All"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.0483371902105176,
                        "binCatValue": null,
                        "binLowerBound": 15.82,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "All"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.0319870149948892,
                        "binCatValue": null,
                        "binLowerBound": 16.95,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "All"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.0199541089918952,
                        "binCatValue": null,
                        "binLowerBound": 18.08,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "All"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.0116752810806135,
                        "binCatValue": null,
                        "binLowerBound": 19.21,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "All"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.00639424878324355,
                        "binCatValue": null,
                        "binLowerBound": 20.34,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "All"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.00299133268632728,
                        "binCatValue": null,
                        "binLowerBound": 21.47,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "All"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.0011670070435211,
                        "binCatValue": null,
                        "binLowerBound": 22.6,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "All"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.00041501879006232,
                        "binCatValue": null,
                        "binLowerBound": 23.73,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "All"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.000136565930097158,
                        "binCatValue": null,
                        "binLowerBound": 24.86,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "All"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.0000340851058360052,
                        "binCatValue": null,
                        "binLowerBound": 25.99,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "All"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.00000519625135422201,
                        "binCatValue": null,
                        "binLowerBound": 27.12,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "All"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 2.3990080121062e-7,
                        "binCatValue": null,
                        "binLowerBound": 28.25,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "All"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 29.38,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "All"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 30.51,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "All"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 31.64,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "All"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 32.77,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "All"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 33.9,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "All"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 0,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "All"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 1.13,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "All"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 2.26,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "All"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 3.39,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "All"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 4.52,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "All"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 5.65,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "All"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 6.78,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "All"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 7.91,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "All"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 9.04,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "All"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 10.17,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "All"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 11.3,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "All"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 12.43,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "All"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 13.56,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "All"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 14.69,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "All"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 15.82,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "All"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 16.95,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "All"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 18.08,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "All"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 19.21,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "All"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 20.34,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "All"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 21.47,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "All"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 22.6,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "All"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 23.73,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "All"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 24.86,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "All"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 25.99,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "All"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 27.12,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "All"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 28.25,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "All"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 29.38,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "All"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 30.51,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "All"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 31.64,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "All"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 32.77,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "All"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 33.9,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "All"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.00673553315150602,
                        "binCatValue": null,
                        "binLowerBound": 0,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "Friday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.0132384813971737,
                        "binCatValue": null,
                        "binLowerBound": 1.13,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "Friday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.0188809852260691,
                        "binCatValue": null,
                        "binLowerBound": 2.26,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "Friday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.0281994979817946,
                        "binCatValue": null,
                        "binLowerBound": 3.39,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "Friday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.0351059526020698,
                        "binCatValue": null,
                        "binLowerBound": 4.52,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "Friday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.0521732545019174,
                        "binCatValue": null,
                        "binLowerBound": 5.65,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "Friday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.0746461349372611,
                        "binCatValue": null,
                        "binLowerBound": 6.78,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "Friday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.105245567903044,
                        "binCatValue": null,
                        "binLowerBound": 7.91,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "Friday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.124603995760788,
                        "binCatValue": null,
                        "binLowerBound": 9.04,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "Friday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.126415733396035,
                        "binCatValue": null,
                        "binLowerBound": 10.17,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "Friday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.111147896311434,
                        "binCatValue": null,
                        "binLowerBound": 11.3,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "Friday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.0857810250528666,
                        "binCatValue": null,
                        "binLowerBound": 12.43,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "Friday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.0659412631814951,
                        "binCatValue": null,
                        "binLowerBound": 13.56,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "Friday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.0501653014152208,
                        "binCatValue": null,
                        "binLowerBound": 14.69,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "Friday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.0376311601511712,
                        "binCatValue": null,
                        "binLowerBound": 15.82,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "Friday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.0262606917589959,
                        "binCatValue": null,
                        "binLowerBound": 16.95,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "Friday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.0175398054698162,
                        "binCatValue": null,
                        "binLowerBound": 18.08,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "Friday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.0108357027109911,
                        "binCatValue": null,
                        "binLowerBound": 19.21,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "Friday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.00575116318644913,
                        "binCatValue": null,
                        "binLowerBound": 20.34,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "Friday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.00247132687593421,
                        "binCatValue": null,
                        "binLowerBound": 21.47,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "Friday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.000878105304088301,
                        "binCatValue": null,
                        "binLowerBound": 22.6,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "Friday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.00026715833778722,
                        "binCatValue": null,
                        "binLowerBound": 23.73,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "Friday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.0000676501785321138,
                        "binCatValue": null,
                        "binLowerBound": 24.86,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "Friday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.0000149668536575473,
                        "binCatValue": null,
                        "binLowerBound": 25.99,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "Friday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.00000149668536575473,
                        "binCatValue": null,
                        "binLowerBound": 27.12,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "Friday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 1.49668536575473e-7,
                        "binCatValue": null,
                        "binLowerBound": 28.25,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "Friday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 29.38,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "Friday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 30.51,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "Friday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 31.64,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "Friday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 32.77,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "Friday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 33.9,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "Friday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.00432697711796019,
                        "binCatValue": null,
                        "binLowerBound": 0,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "Friday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.00684815857735561,
                        "binCatValue": null,
                        "binLowerBound": 1.13,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "Friday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.00946065441008876,
                        "binCatValue": null,
                        "binLowerBound": 2.26,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "Friday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.0134862162031963,
                        "binCatValue": null,
                        "binLowerBound": 3.39,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "Friday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.0175698381195087,
                        "binCatValue": null,
                        "binLowerBound": 4.52,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "Friday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.0272080882005772,
                        "binCatValue": null,
                        "binLowerBound": 5.65,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "Friday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.0471199242929547,
                        "binCatValue": null,
                        "binLowerBound": 6.78,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "Friday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.0818917366244216,
                        "binCatValue": null,
                        "binLowerBound": 7.91,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "Friday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.121123063733838,
                        "binCatValue": null,
                        "binLowerBound": 9.04,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "Friday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.132162036756312,
                        "binCatValue": null,
                        "binLowerBound": 10.17,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "Friday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.137239631204973,
                        "binCatValue": null,
                        "binLowerBound": 11.3,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "Friday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.117055170179074,
                        "binCatValue": null,
                        "binLowerBound": 12.43,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "Friday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.0918514444567742,
                        "binCatValue": null,
                        "binLowerBound": 13.56,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "Friday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.0689893871607196,
                        "binCatValue": null,
                        "binLowerBound": 14.69,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "Friday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.0490725479514303,
                        "binCatValue": null,
                        "binLowerBound": 15.82,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "Friday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.0326687356602281,
                        "binCatValue": null,
                        "binLowerBound": 16.95,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "Friday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.0199799024494062,
                        "binCatValue": null,
                        "binLowerBound": 18.08,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "Friday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.0114163937820903,
                        "binCatValue": null,
                        "binLowerBound": 19.21,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "Friday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.00613136471368149,
                        "binCatValue": null,
                        "binLowerBound": 20.34,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "Friday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.00280066695443077,
                        "binCatValue": null,
                        "binLowerBound": 21.47,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "Friday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.00106877961575583,
                        "binCatValue": null,
                        "binLowerBound": 22.6,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "Friday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.000372627354191198,
                        "binCatValue": null,
                        "binLowerBound": 23.73,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "Friday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.000124089282928119,
                        "binCatValue": null,
                        "binLowerBound": 24.86,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "Friday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.0000291498967380636,
                        "binCatValue": null,
                        "binLowerBound": 25.99,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "Friday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.00000326550744547681,
                        "binCatValue": null,
                        "binLowerBound": 27.12,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "Friday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 1.49793919517285e-7,
                        "binCatValue": null,
                        "binLowerBound": 28.25,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "Friday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 29.38,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "Friday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 30.51,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "Friday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 31.64,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "Friday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 32.77,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "Friday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 33.9,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "Friday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 0,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "Friday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 1.13,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "Friday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 2.26,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "Friday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 3.39,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "Friday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 4.52,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "Friday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 5.65,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "Friday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 6.78,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "Friday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 7.91,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "Friday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 9.04,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "Friday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 10.17,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "Friday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 11.3,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "Friday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 12.43,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "Friday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 13.56,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "Friday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 14.69,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "Friday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 15.82,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "Friday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 16.95,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "Friday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 18.08,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "Friday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 19.21,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "Friday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 20.34,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "Friday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 21.47,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "Friday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 22.6,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "Friday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 23.73,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "Friday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 24.86,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "Friday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 25.99,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "Friday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 27.12,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "Friday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 28.25,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "Friday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 29.38,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "Friday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 30.51,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "Friday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 31.64,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "Friday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 32.77,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "Friday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 33.9,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "Friday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.00669485718008958,
                        "binCatValue": null,
                        "binLowerBound": 0,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "Monday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.0116561251951679,
                        "binCatValue": null,
                        "binLowerBound": 1.13,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "Monday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.0176228071887261,
                        "binCatValue": null,
                        "binLowerBound": 2.26,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "Monday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.0275603421762133,
                        "binCatValue": null,
                        "binLowerBound": 3.39,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "Monday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.0346409789900089,
                        "binCatValue": null,
                        "binLowerBound": 4.52,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "Monday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.0524166787760398,
                        "binCatValue": null,
                        "binLowerBound": 5.65,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "Monday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.0741451682955586,
                        "binCatValue": null,
                        "binLowerBound": 6.78,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "Monday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.10438742080994,
                        "binCatValue": null,
                        "binLowerBound": 7.91,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "Monday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.123125473051793,
                        "binCatValue": null,
                        "binLowerBound": 9.04,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "Monday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.125602236360666,
                        "binCatValue": null,
                        "binLowerBound": 10.17,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "Monday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.110609891473673,
                        "binCatValue": null,
                        "binLowerBound": 11.3,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "Monday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.0863725441021759,
                        "binCatValue": null,
                        "binLowerBound": 12.43,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "Monday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.0671654922052997,
                        "binCatValue": null,
                        "binLowerBound": 13.56,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "Monday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.0516338299708519,
                        "binCatValue": null,
                        "binLowerBound": 14.69,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "Monday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.0390721225669634,
                        "binCatValue": null,
                        "binLowerBound": 15.82,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "Monday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.0270561836749736,
                        "binCatValue": null,
                        "binLowerBound": 16.95,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "Monday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.0181966382659528,
                        "binCatValue": null,
                        "binLowerBound": 18.08,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "Monday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.0114613000289819,
                        "binCatValue": null,
                        "binLowerBound": 19.21,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "Monday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.00630778731349483,
                        "binCatValue": null,
                        "binLowerBound": 20.34,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "Monday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.002812223859922,
                        "binCatValue": null,
                        "binLowerBound": 21.47,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "Monday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.00105024957136044,
                        "binCatValue": null,
                        "binLowerBound": 22.6,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "Monday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.000313687871052835,
                        "binCatValue": null,
                        "binLowerBound": 23.73,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "Monday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.0000828974630957106,
                        "binCatValue": null,
                        "binLowerBound": 24.86,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "Monday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.0000117733751089239,
                        "binCatValue": null,
                        "binLowerBound": 25.99,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "Monday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 9.67674666486894e-7,
                        "binCatValue": null,
                        "binLowerBound": 27.12,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "Monday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 3.22558222162298e-7,
                        "binCatValue": null,
                        "binLowerBound": 28.25,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "Monday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 29.38,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "Monday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 30.51,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "Monday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 31.64,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "Monday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 32.77,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "Monday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 33.9,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "Monday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.00414759611955585,
                        "binCatValue": null,
                        "binLowerBound": 0,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "Monday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.00604484906396285,
                        "binCatValue": null,
                        "binLowerBound": 1.13,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "Monday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.00885590833174124,
                        "binCatValue": null,
                        "binLowerBound": 2.26,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "Monday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.0129389471507195,
                        "binCatValue": null,
                        "binLowerBound": 3.39,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "Monday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.0170164497117245,
                        "binCatValue": null,
                        "binLowerBound": 4.52,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "Monday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.0265858230549143,
                        "binCatValue": null,
                        "binLowerBound": 5.65,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "Monday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.0464392974080697,
                        "binCatValue": null,
                        "binLowerBound": 6.78,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "Monday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.0811363859094269,
                        "binCatValue": null,
                        "binLowerBound": 7.91,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "Monday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.118654958678957,
                        "binCatValue": null,
                        "binLowerBound": 9.04,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "Monday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.130557621939562,
                        "binCatValue": null,
                        "binLowerBound": 10.17,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "Monday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.136139238377314,
                        "binCatValue": null,
                        "binLowerBound": 11.3,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "Monday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.116752169476577,
                        "binCatValue": null,
                        "binLowerBound": 12.43,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "Monday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.0936662974856744,
                        "binCatValue": null,
                        "binLowerBound": 13.56,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "Monday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.0713677072791983,
                        "binCatValue": null,
                        "binLowerBound": 14.69,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "Monday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.0504929390209675,
                        "binCatValue": null,
                        "binLowerBound": 15.82,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "Monday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.0335422445135926,
                        "binCatValue": null,
                        "binLowerBound": 16.95,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "Monday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.0211021109686901,
                        "binCatValue": null,
                        "binLowerBound": 18.08,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "Monday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.0123948398320099,
                        "binCatValue": null,
                        "binLowerBound": 19.21,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "Monday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.00686586640867057,
                        "binCatValue": null,
                        "binLowerBound": 20.34,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "Monday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.00325512543363712,
                        "binCatValue": null,
                        "binLowerBound": 21.47,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "Monday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.00133834989532426,
                        "binCatValue": null,
                        "binLowerBound": 22.6,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "Monday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.00049719481693586,
                        "binCatValue": null,
                        "binLowerBound": 23.73,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "Monday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.000162655906771229,
                        "binCatValue": null,
                        "binLowerBound": 24.86,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "Monday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.0000390128120341024,
                        "binCatValue": null,
                        "binLowerBound": 25.99,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "Monday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.00000621614930335906,
                        "binCatValue": null,
                        "binLowerBound": 27.12,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "Monday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 1.94254665729971e-7,
                        "binCatValue": null,
                        "binLowerBound": 28.25,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "Monday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 29.38,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "Monday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 30.51,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "Monday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 31.64,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "Monday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 32.77,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "Monday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 33.9,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "Monday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 0,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "Monday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 1.13,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "Monday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 2.26,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "Monday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 3.39,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "Monday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 4.52,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "Monday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 5.65,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "Monday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 6.78,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "Monday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 7.91,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "Monday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 9.04,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "Monday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 10.17,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "Monday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 11.3,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "Monday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 12.43,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "Monday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 13.56,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "Monday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 14.69,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "Monday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 15.82,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "Monday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 16.95,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "Monday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 18.08,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "Monday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 19.21,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "Monday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 20.34,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "Monday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 21.47,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "Monday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 22.6,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "Monday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 23.73,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "Monday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 24.86,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "Monday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 25.99,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "Monday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 27.12,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "Monday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 28.25,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "Monday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 29.38,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "Monday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 30.51,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "Monday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 31.64,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "Monday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 32.77,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "Monday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 33.9,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "Monday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.00777331621436579,
                        "binCatValue": null,
                        "binLowerBound": 0,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "Saturday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.0144020485687908,
                        "binCatValue": null,
                        "binLowerBound": 1.13,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "Saturday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.0213863732988694,
                        "binCatValue": null,
                        "binLowerBound": 2.26,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "Saturday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.0333347230046865,
                        "binCatValue": null,
                        "binLowerBound": 3.39,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "Saturday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.0395019655675109,
                        "binCatValue": null,
                        "binLowerBound": 4.52,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "Saturday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.0585900305274382,
                        "binCatValue": null,
                        "binLowerBound": 5.65,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "Saturday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.0835280916862056,
                        "binCatValue": null,
                        "binLowerBound": 6.78,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "Saturday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.11658614375786,
                        "binCatValue": null,
                        "binLowerBound": 7.91,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "Saturday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.133761589914821,
                        "binCatValue": null,
                        "binLowerBound": 9.04,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "Saturday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.13082878884791,
                        "binCatValue": null,
                        "binLowerBound": 10.17,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "Saturday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.108300284050311,
                        "binCatValue": null,
                        "binLowerBound": 11.3,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "Saturday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.0760564159008426,
                        "binCatValue": null,
                        "binLowerBound": 12.43,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "Saturday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.0544562412927243,
                        "binCatValue": null,
                        "binLowerBound": 13.56,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "Saturday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.0399995719514976,
                        "binCatValue": null,
                        "binLowerBound": 14.69,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "Saturday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.0289935977766447,
                        "binCatValue": null,
                        "binLowerBound": 15.82,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "Saturday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.020504192088549,
                        "binCatValue": null,
                        "binLowerBound": 16.95,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "Saturday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.0143625878474801,
                        "binCatValue": null,
                        "binLowerBound": 18.08,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "Saturday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.00920883929075038,
                        "binCatValue": null,
                        "binLowerBound": 19.21,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "Saturday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.00505476234056029,
                        "binCatValue": null,
                        "binLowerBound": 20.34,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "Saturday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.00227913533310757,
                        "binCatValue": null,
                        "binLowerBound": 21.47,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "Saturday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.000797909161419544,
                        "binCatValue": null,
                        "binLowerBound": 22.6,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "Saturday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.00022963018615874,
                        "binCatValue": null,
                        "binLowerBound": 23.73,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "Saturday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.0000559584240056735,
                        "binCatValue": null,
                        "binLowerBound": 24.86,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "Saturday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.00000735708363421204,
                        "binCatValue": null,
                        "binLowerBound": 25.99,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "Saturday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 2.22941928309456e-7,
                        "binCatValue": null,
                        "binLowerBound": 27.12,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "Saturday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 2.22941928309456e-7,
                        "binCatValue": null,
                        "binLowerBound": 28.25,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "Saturday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 29.38,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "Saturday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 30.51,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "Saturday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 31.64,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "Saturday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 32.77,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "Saturday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 33.9,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "Saturday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.00490527912419304,
                        "binCatValue": null,
                        "binLowerBound": 0,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "Saturday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.00736155519674445,
                        "binCatValue": null,
                        "binLowerBound": 1.13,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "Saturday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.00977265991240217,
                        "binCatValue": null,
                        "binLowerBound": 2.26,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "Saturday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.0140056760227725,
                        "binCatValue": null,
                        "binLowerBound": 3.39,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "Saturday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.0178682005166361,
                        "binCatValue": null,
                        "binLowerBound": 4.52,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "Saturday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.0277797161579752,
                        "binCatValue": null,
                        "binLowerBound": 5.65,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "Saturday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.0511308702271734,
                        "binCatValue": null,
                        "binLowerBound": 6.78,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "Saturday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.0917938976838494,
                        "binCatValue": null,
                        "binLowerBound": 7.91,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "Saturday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.131805773544154,
                        "binCatValue": null,
                        "binLowerBound": 9.04,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "Saturday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.1433455809695,
                        "binCatValue": null,
                        "binLowerBound": 10.17,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "Saturday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.150490164261374,
                        "binCatValue": null,
                        "binLowerBound": 11.3,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "Saturday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.121844762047002,
                        "binCatValue": null,
                        "binLowerBound": 12.43,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "Saturday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.0862478850743184,
                        "binCatValue": null,
                        "binLowerBound": 13.56,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "Saturday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.0587818916561271,
                        "binCatValue": null,
                        "binLowerBound": 14.69,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "Saturday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.0353942545415003,
                        "binCatValue": null,
                        "binLowerBound": 15.82,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "Saturday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.020850060462392,
                        "binCatValue": null,
                        "binLowerBound": 16.95,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "Saturday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.0124106435668184,
                        "binCatValue": null,
                        "binLowerBound": 18.08,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "Saturday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.00729377850459064,
                        "binCatValue": null,
                        "binLowerBound": 19.21,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "Saturday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.00404140149461759,
                        "binCatValue": null,
                        "binLowerBound": 20.34,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "Saturday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.00188925563697451,
                        "binCatValue": null,
                        "binLowerBound": 21.47,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "Saturday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.000685236510580692,
                        "binCatValue": null,
                        "binLowerBound": 22.6,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "Saturday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.000216971904870616,
                        "binCatValue": null,
                        "binLowerBound": 23.73,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "Saturday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.0000638060158733486,
                        "binCatValue": null,
                        "binLowerBound": 24.86,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "Saturday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.0000176125046896242,
                        "binCatValue": null,
                        "binLowerBound": 25.99,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "Saturday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.00000279126748429312,
                        "binCatValue": null,
                        "binLowerBound": 27.12,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "Saturday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 2.75195385775379e-7,
                        "binCatValue": null,
                        "binLowerBound": 28.25,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "Saturday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 29.38,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "Saturday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 30.51,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "Saturday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 31.64,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "Saturday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 32.77,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "Saturday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 33.9,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "Saturday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 0,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "Saturday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 1.13,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "Saturday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 2.26,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "Saturday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 3.39,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "Saturday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 4.52,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "Saturday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 5.65,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "Saturday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 6.78,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "Saturday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 7.91,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "Saturday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 9.04,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "Saturday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 10.17,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "Saturday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 11.3,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "Saturday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 12.43,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "Saturday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 13.56,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "Saturday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 14.69,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "Saturday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 15.82,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "Saturday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 16.95,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "Saturday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 18.08,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "Saturday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 19.21,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "Saturday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 20.34,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "Saturday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 21.47,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "Saturday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 22.6,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "Saturday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 23.73,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "Saturday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 24.86,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "Saturday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 25.99,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "Saturday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 27.12,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "Saturday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 28.25,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "Saturday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 29.38,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "Saturday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 30.51,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "Saturday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 31.64,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "Saturday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 32.77,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "Saturday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 33.9,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "Saturday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.00742366863371965,
                        "binCatValue": null,
                        "binLowerBound": 0,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "Sunday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.0141659773172282,
                        "binCatValue": null,
                        "binLowerBound": 1.13,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "Sunday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.0211606718872851,
                        "binCatValue": null,
                        "binLowerBound": 2.26,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "Sunday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.0337058073746664,
                        "binCatValue": null,
                        "binLowerBound": 3.39,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "Sunday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.0405099833777672,
                        "binCatValue": null,
                        "binLowerBound": 4.52,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "Sunday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.0612666739596354,
                        "binCatValue": null,
                        "binLowerBound": 5.65,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "Sunday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.0857333551302963,
                        "binCatValue": null,
                        "binLowerBound": 6.78,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "Sunday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.118036290304408,
                        "binCatValue": null,
                        "binLowerBound": 7.91,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "Sunday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.132078532982451,
                        "binCatValue": null,
                        "binLowerBound": 9.04,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "Sunday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.1276986545292,
                        "binCatValue": null,
                        "binLowerBound": 10.17,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "Sunday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.107154607251096,
                        "binCatValue": null,
                        "binLowerBound": 11.3,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "Sunday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.0752187788210548,
                        "binCatValue": null,
                        "binLowerBound": 12.43,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "Sunday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.0541130090584409,
                        "binCatValue": null,
                        "binLowerBound": 13.56,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "Sunday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.0395512447827617,
                        "binCatValue": null,
                        "binLowerBound": 14.69,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "Sunday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.0289223573333464,
                        "binCatValue": null,
                        "binLowerBound": 15.82,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "Sunday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.0200413240917488,
                        "binCatValue": null,
                        "binLowerBound": 16.95,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "Sunday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.0142245668980341,
                        "binCatValue": null,
                        "binLowerBound": 18.08,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "Sunday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.009624260511401,
                        "binCatValue": null,
                        "binLowerBound": 19.21,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "Sunday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.00557420452352535,
                        "binCatValue": null,
                        "binLowerBound": 20.34,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "Sunday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.00257179579523474,
                        "binCatValue": null,
                        "binLowerBound": 21.47,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "Sunday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.000909982230558605,
                        "binCatValue": null,
                        "binLowerBound": 22.6,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "Sunday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.000252385886548447,
                        "binCatValue": null,
                        "binLowerBound": 23.73,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "Sunday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.0000557215593678389,
                        "binCatValue": null,
                        "binLowerBound": 24.86,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "Sunday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.00000450689083122227,
                        "binCatValue": null,
                        "binLowerBound": 25.99,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "Sunday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.00000163886939317173,
                        "binCatValue": null,
                        "binLowerBound": 27.12,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "Sunday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 28.25,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "Sunday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 29.38,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "Sunday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 30.51,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "Sunday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 31.64,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "Sunday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 32.77,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "Sunday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 33.9,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "Sunday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.00465450868365691,
                        "binCatValue": null,
                        "binLowerBound": 0,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "Sunday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.00737745134697091,
                        "binCatValue": null,
                        "binLowerBound": 1.13,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "Sunday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.0097434829254306,
                        "binCatValue": null,
                        "binLowerBound": 2.26,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "Sunday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.0135778694699119,
                        "binCatValue": null,
                        "binLowerBound": 3.39,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "Sunday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.0173298133476137,
                        "binCatValue": null,
                        "binLowerBound": 4.52,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "Sunday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.0275842948908528,
                        "binCatValue": null,
                        "binLowerBound": 5.65,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "Sunday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.0525191159373713,
                        "binCatValue": null,
                        "binLowerBound": 6.78,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "Sunday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.0947154615384896,
                        "binCatValue": null,
                        "binLowerBound": 7.91,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "Sunday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.130095987340308,
                        "binCatValue": null,
                        "binLowerBound": 9.04,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "Sunday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.141223412695952,
                        "binCatValue": null,
                        "binLowerBound": 10.17,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "Sunday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.149084065619985,
                        "binCatValue": null,
                        "binLowerBound": 11.3,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "Sunday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.120879991566042,
                        "binCatValue": null,
                        "binLowerBound": 12.43,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "Sunday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.0878994228648535,
                        "binCatValue": null,
                        "binLowerBound": 13.56,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "Sunday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.0597838470010021,
                        "binCatValue": null,
                        "binLowerBound": 14.69,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "Sunday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.0355235966598317,
                        "binCatValue": null,
                        "binLowerBound": 15.82,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "Sunday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.0201059424747173,
                        "binCatValue": null,
                        "binLowerBound": 16.95,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "Sunday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.0122925664073857,
                        "binCatValue": null,
                        "binLowerBound": 18.08,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "Sunday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.00767045112554304,
                        "binCatValue": null,
                        "binLowerBound": 19.21,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "Sunday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.00458425002161384,
                        "binCatValue": null,
                        "binLowerBound": 20.34,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "Sunday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.0021551826342183,
                        "binCatValue": null,
                        "binLowerBound": 21.47,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "Sunday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.00082245679876584,
                        "binCatValue": null,
                        "binLowerBound": 22.6,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "Sunday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.000263086952572485,
                        "binCatValue": null,
                        "binLowerBound": 23.73,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "Sunday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.0000855798656038615,
                        "binCatValue": null,
                        "binLowerBound": 24.86,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "Sunday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.0000223981404436364,
                        "binCatValue": null,
                        "binLowerBound": 25.99,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "Sunday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.00000561777463895766,
                        "binCatValue": null,
                        "binLowerBound": 27.12,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "Sunday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 1.45916224388511e-7,
                        "binCatValue": null,
                        "binLowerBound": 28.25,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "Sunday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 29.38,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "Sunday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 30.51,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "Sunday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 31.64,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "Sunday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 32.77,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "Sunday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 33.9,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "Sunday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 0,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "Sunday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 1.13,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "Sunday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 2.26,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "Sunday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 3.39,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "Sunday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 4.52,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "Sunday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 5.65,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "Sunday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 6.78,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "Sunday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 7.91,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "Sunday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 9.04,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "Sunday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 10.17,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "Sunday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 11.3,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "Sunday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 12.43,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "Sunday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 13.56,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "Sunday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 14.69,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "Sunday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 15.82,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "Sunday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 16.95,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "Sunday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 18.08,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "Sunday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 19.21,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "Sunday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 20.34,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "Sunday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 21.47,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "Sunday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 22.6,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "Sunday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 23.73,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "Sunday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 24.86,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "Sunday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 25.99,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "Sunday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 27.12,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "Sunday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 28.25,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "Sunday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 29.38,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "Sunday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 30.51,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "Sunday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 31.64,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "Sunday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 32.77,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "Sunday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 33.9,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "Sunday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.0066673953437534,
                        "binCatValue": null,
                        "binLowerBound": 0,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "Thursday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.0119464690801219,
                        "binCatValue": null,
                        "binLowerBound": 1.13,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "Thursday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.0176157685721572,
                        "binCatValue": null,
                        "binLowerBound": 2.26,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "Thursday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.0271752723156673,
                        "binCatValue": null,
                        "binLowerBound": 3.39,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "Thursday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.0340129205952908,
                        "binCatValue": null,
                        "binLowerBound": 4.52,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "Thursday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.0512062756081371,
                        "binCatValue": null,
                        "binLowerBound": 5.65,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "Thursday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.0730871336171887,
                        "binCatValue": null,
                        "binLowerBound": 6.78,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "Thursday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.10341667372059,
                        "binCatValue": null,
                        "binLowerBound": 7.91,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "Thursday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.122855367734014,
                        "binCatValue": null,
                        "binLowerBound": 9.04,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "Thursday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.125307667463554,
                        "binCatValue": null,
                        "binLowerBound": 10.17,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "Thursday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.111068604947718,
                        "binCatValue": null,
                        "binLowerBound": 11.3,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "Thursday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.0869217255621292,
                        "binCatValue": null,
                        "binLowerBound": 12.43,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "Thursday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.0676704056567257,
                        "binCatValue": null,
                        "binLowerBound": 13.56,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "Thursday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.0524435199924611,
                        "binCatValue": null,
                        "binLowerBound": 14.69,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "Thursday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.0399206837730461,
                        "binCatValue": null,
                        "binLowerBound": 15.82,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "Thursday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.0280392751470982,
                        "binCatValue": null,
                        "binLowerBound": 16.95,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "Thursday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.0186663236953461,
                        "binCatValue": null,
                        "binLowerBound": 18.08,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "Thursday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.0115626808851838,
                        "binCatValue": null,
                        "binLowerBound": 19.21,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "Thursday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.00625128237579411,
                        "binCatValue": null,
                        "binLowerBound": 20.34,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "Thursday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.00279061409124516,
                        "binCatValue": null,
                        "binLowerBound": 21.47,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "Thursday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.000979878924549323,
                        "binCatValue": null,
                        "binLowerBound": 22.6,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "Thursday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.000300099905463736,
                        "binCatValue": null,
                        "binLowerBound": 23.73,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "Thursday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.0000813598100618252,
                        "binCatValue": null,
                        "binLowerBound": 24.86,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "Thursday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.0000113684583083022,
                        "binCatValue": null,
                        "binLowerBound": 25.99,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "Thursday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.00000123272439487614,
                        "binCatValue": null,
                        "binLowerBound": 27.12,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "Thursday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 28.25,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "Thursday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 29.38,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "Thursday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 30.51,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "Thursday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 31.64,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "Thursday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 32.77,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "Thursday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 33.9,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "Thursday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.00412561711899806,
                        "binCatValue": null,
                        "binLowerBound": 0,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "Thursday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.00635784951676913,
                        "binCatValue": null,
                        "binLowerBound": 1.13,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "Thursday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.00901332026139263,
                        "binCatValue": null,
                        "binLowerBound": 2.26,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "Thursday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.0131615644717345,
                        "binCatValue": null,
                        "binLowerBound": 3.39,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "Thursday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.0171876503235004,
                        "binCatValue": null,
                        "binLowerBound": 4.52,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "Thursday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.0268804816922248,
                        "binCatValue": null,
                        "binLowerBound": 5.65,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "Thursday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.0464378589117609,
                        "binCatValue": null,
                        "binLowerBound": 6.78,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "Thursday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.0796255166100169,
                        "binCatValue": null,
                        "binLowerBound": 7.91,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "Thursday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.11819526542919,
                        "binCatValue": null,
                        "binLowerBound": 9.04,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "Thursday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.12904540268345,
                        "binCatValue": null,
                        "binLowerBound": 10.17,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "Thursday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.133079030898997,
                        "binCatValue": null,
                        "binLowerBound": 11.3,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "Thursday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.115018002784305,
                        "binCatValue": null,
                        "binLowerBound": 12.43,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "Thursday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.0924307462951071,
                        "binCatValue": null,
                        "binLowerBound": 13.56,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "Thursday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.0710161597658135,
                        "binCatValue": null,
                        "binLowerBound": 14.69,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "Thursday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.0533729506699239,
                        "binCatValue": null,
                        "binLowerBound": 15.82,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "Thursday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.0368297534295128,
                        "binCatValue": null,
                        "binLowerBound": 16.95,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "Thursday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.0229142319264571,
                        "binCatValue": null,
                        "binLowerBound": 18.08,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "Thursday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.0131247186279289,
                        "binCatValue": null,
                        "binLowerBound": 19.21,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "Thursday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.00707730721746109,
                        "binCatValue": null,
                        "binLowerBound": 20.34,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "Thursday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.00325696028884013,
                        "binCatValue": null,
                        "binLowerBound": 21.47,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "Thursday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.00123231050313824,
                        "binCatValue": null,
                        "binLowerBound": 22.6,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "Thursday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.000434775369969703,
                        "binCatValue": null,
                        "binLowerBound": 23.73,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "Thursday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.000142131655256095,
                        "binCatValue": null,
                        "binLowerBound": 24.86,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "Thursday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.0000348624814779101,
                        "binCatValue": null,
                        "binLowerBound": 25.99,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "Thursday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.00000533552400823782,
                        "binCatValue": null,
                        "binLowerBound": 27.12,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "Thursday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 1.95542764699816e-7,
                        "binCatValue": null,
                        "binLowerBound": 28.25,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "Thursday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 29.38,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "Thursday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 30.51,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "Thursday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 31.64,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "Thursday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 32.77,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "Thursday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 33.9,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "Thursday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 0,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "Thursday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 1.13,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "Thursday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 2.26,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "Thursday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 3.39,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "Thursday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 4.52,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "Thursday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 5.65,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "Thursday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 6.78,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "Thursday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 7.91,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "Thursday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 9.04,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "Thursday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 10.17,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "Thursday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 11.3,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "Thursday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 12.43,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "Thursday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 13.56,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "Thursday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 14.69,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "Thursday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 15.82,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "Thursday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 16.95,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "Thursday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 18.08,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "Thursday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 19.21,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "Thursday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 20.34,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "Thursday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 21.47,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "Thursday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 22.6,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "Thursday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 23.73,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "Thursday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 24.86,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "Thursday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 25.99,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "Thursday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 27.12,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "Thursday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 28.25,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "Thursday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 29.38,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "Thursday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 30.51,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "Thursday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 31.64,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "Thursday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 32.77,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "Thursday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 33.9,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "Thursday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.00658906320470243,
                        "binCatValue": null,
                        "binLowerBound": 0,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "Tuesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.0114209037336882,
                        "binCatValue": null,
                        "binLowerBound": 1.13,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "Tuesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.0171379157394879,
                        "binCatValue": null,
                        "binLowerBound": 2.26,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "Tuesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.026330012262987,
                        "binCatValue": null,
                        "binLowerBound": 3.39,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "Tuesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.0336207121478119,
                        "binCatValue": null,
                        "binLowerBound": 4.52,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "Tuesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.0508899797604804,
                        "binCatValue": null,
                        "binLowerBound": 5.65,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "Tuesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.0728184369723468,
                        "binCatValue": null,
                        "binLowerBound": 6.78,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "Tuesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.103392710045586,
                        "binCatValue": null,
                        "binLowerBound": 7.91,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "Tuesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.123086247190509,
                        "binCatValue": null,
                        "binLowerBound": 9.04,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "Tuesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.125114373786281,
                        "binCatValue": null,
                        "binLowerBound": 10.17,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "Tuesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.111387133927473,
                        "binCatValue": null,
                        "binLowerBound": 11.3,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "Tuesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.0875620510870822,
                        "binCatValue": null,
                        "binLowerBound": 12.43,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "Tuesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.0682156049305408,
                        "binCatValue": null,
                        "binLowerBound": 13.56,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "Tuesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.0529295865994253,
                        "binCatValue": null,
                        "binLowerBound": 14.69,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "Tuesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.0400189349465312,
                        "binCatValue": null,
                        "binLowerBound": 15.82,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "Tuesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.0279120494097739,
                        "binCatValue": null,
                        "binLowerBound": 16.95,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "Tuesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.018786001558033,
                        "binCatValue": null,
                        "binLowerBound": 18.08,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "Tuesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.0119317000078274,
                        "binCatValue": null,
                        "binLowerBound": 19.21,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "Tuesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.00646531512894774,
                        "binCatValue": null,
                        "binLowerBound": 20.34,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "Tuesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.00292612016236344,
                        "binCatValue": null,
                        "binLowerBound": 21.47,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "Tuesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.00104291299988445,
                        "binCatValue": null,
                        "binLowerBound": 22.6,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "Tuesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.000321894091029383,
                        "binCatValue": null,
                        "binLowerBound": 23.73,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "Tuesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.0000869218411626355,
                        "binCatValue": null,
                        "binLowerBound": 24.86,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "Tuesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.0000117784313067722,
                        "binCatValue": null,
                        "binLowerBound": 25.99,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "Tuesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.00000134184660456899,
                        "binCatValue": null,
                        "binLowerBound": 27.12,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "Tuesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 2.98188134348664e-7,
                        "binCatValue": null,
                        "binLowerBound": 28.25,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "Tuesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 29.38,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "Tuesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 30.51,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "Tuesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 31.64,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "Tuesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 32.77,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "Tuesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 33.9,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "Tuesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.00401323818043953,
                        "binCatValue": null,
                        "binLowerBound": 0,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "Tuesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.00602047842584127,
                        "binCatValue": null,
                        "binLowerBound": 1.13,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "Tuesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.00878513408998115,
                        "binCatValue": null,
                        "binLowerBound": 2.26,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "Tuesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.0127697991320492,
                        "binCatValue": null,
                        "binLowerBound": 3.39,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "Tuesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.0166983784062218,
                        "binCatValue": null,
                        "binLowerBound": 4.52,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "Tuesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.0263400703420368,
                        "binCatValue": null,
                        "binLowerBound": 5.65,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "Tuesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.045646543006285,
                        "binCatValue": null,
                        "binLowerBound": 6.78,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "Tuesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.0794196289582545,
                        "binCatValue": null,
                        "binLowerBound": 7.91,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "Tuesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.118738600476775,
                        "binCatValue": null,
                        "binLowerBound": 9.04,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "Tuesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.129722805712507,
                        "binCatValue": null,
                        "binLowerBound": 10.17,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "Tuesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.134118826986314,
                        "binCatValue": null,
                        "binLowerBound": 11.3,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "Tuesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.116209862580779,
                        "binCatValue": null,
                        "binLowerBound": 12.43,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "Tuesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.0940438641609369,
                        "binCatValue": null,
                        "binLowerBound": 13.56,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "Tuesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.0720436687243063,
                        "binCatValue": null,
                        "binLowerBound": 14.69,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "Tuesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.0517325305786363,
                        "binCatValue": null,
                        "binLowerBound": 15.82,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "Tuesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.0349466147421335,
                        "binCatValue": null,
                        "binLowerBound": 16.95,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "Tuesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.0223685556096752,
                        "binCatValue": null,
                        "binLowerBound": 18.08,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "Tuesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.0133714712514989,
                        "binCatValue": null,
                        "binLowerBound": 19.21,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "Tuesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.00736274932373624,
                        "binCatValue": null,
                        "binLowerBound": 20.34,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "Tuesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.00350583014738801,
                        "binCatValue": null,
                        "binLowerBound": 21.47,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "Tuesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.00140129579031013,
                        "binCatValue": null,
                        "binLowerBound": 22.6,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "Tuesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.000514801294729802,
                        "binCatValue": null,
                        "binLowerBound": 23.73,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "Tuesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.000173984051461949,
                        "binCatValue": null,
                        "binLowerBound": 24.86,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "Tuesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.0000442989695641535,
                        "binCatValue": null,
                        "binLowerBound": 25.99,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "Tuesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.00000651455434766965,
                        "binCatValue": null,
                        "binLowerBound": 27.12,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "Tuesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 4.54503791697882e-7,
                        "binCatValue": null,
                        "binLowerBound": 28.25,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "Tuesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 29.38,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "Tuesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 30.51,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "Tuesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 31.64,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "Tuesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 32.77,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "Tuesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 33.9,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "Tuesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 0,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "Tuesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 1.13,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "Tuesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 2.26,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "Tuesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 3.39,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "Tuesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 4.52,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "Tuesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 5.65,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "Tuesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 6.78,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "Tuesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 7.91,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "Tuesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 9.04,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "Tuesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 10.17,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "Tuesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 11.3,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "Tuesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 12.43,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "Tuesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 13.56,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "Tuesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 14.69,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "Tuesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 15.82,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "Tuesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 16.95,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "Tuesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 18.08,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "Tuesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 19.21,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "Tuesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 20.34,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "Tuesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 21.47,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "Tuesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 22.6,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "Tuesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 23.73,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "Tuesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 24.86,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "Tuesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 25.99,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "Tuesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 27.12,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "Tuesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 28.25,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "Tuesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 29.38,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "Tuesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 30.51,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "Tuesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 31.64,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "Tuesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 32.77,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "Tuesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 33.9,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "Tuesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.00643436725394747,
                        "binCatValue": null,
                        "binLowerBound": 0,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "Wednesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.0112649977700603,
                        "binCatValue": null,
                        "binLowerBound": 1.13,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "Wednesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.0168777368367016,
                        "binCatValue": null,
                        "binLowerBound": 2.26,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "Wednesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.0264178586386667,
                        "binCatValue": null,
                        "binLowerBound": 3.39,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "Wednesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.0335562458137704,
                        "binCatValue": null,
                        "binLowerBound": 4.52,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "Wednesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.0509875291779861,
                        "binCatValue": null,
                        "binLowerBound": 5.65,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "Wednesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.0725663373091989,
                        "binCatValue": null,
                        "binLowerBound": 6.78,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "Wednesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.10373700944723,
                        "binCatValue": null,
                        "binLowerBound": 7.91,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "Wednesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.123812305898829,
                        "binCatValue": null,
                        "binLowerBound": 9.04,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "Wednesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.125969317551554,
                        "binCatValue": null,
                        "binLowerBound": 10.17,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "Wednesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.111324236483333,
                        "binCatValue": null,
                        "binLowerBound": 11.3,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "Wednesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.0875273038590178,
                        "binCatValue": null,
                        "binLowerBound": 12.43,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "Wednesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.0684405415521141,
                        "binCatValue": null,
                        "binLowerBound": 13.56,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "Wednesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.05277772798213,
                        "binCatValue": null,
                        "binLowerBound": 14.69,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "Wednesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.0396182321603471,
                        "binCatValue": null,
                        "binLowerBound": 15.82,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "Wednesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.0277737488571899,
                        "binCatValue": null,
                        "binLowerBound": 16.95,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "Wednesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.0185950076513276,
                        "binCatValue": null,
                        "binLowerBound": 18.08,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "Wednesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.0117154673068033,
                        "binCatValue": null,
                        "binLowerBound": 19.21,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "Wednesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.00629014095065504,
                        "binCatValue": null,
                        "binLowerBound": 20.34,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "Wednesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.00282599354539761,
                        "binCatValue": null,
                        "binLowerBound": 21.47,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "Wednesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.0010393257053644,
                        "binCatValue": null,
                        "binLowerBound": 22.6,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "Wednesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.000336799653639583,
                        "binCatValue": null,
                        "binLowerBound": 23.73,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "Wednesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.0000946569994301569,
                        "binCatValue": null,
                        "binLowerBound": 24.86,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "Wednesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.0000150745006266103,
                        "binCatValue": null,
                        "binLowerBound": 25.99,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "Wednesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.00000190128836732022,
                        "binCatValue": null,
                        "binLowerBound": 27.12,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "Wednesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 1.35806311951444e-7,
                        "binCatValue": null,
                        "binLowerBound": 28.25,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "Wednesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 29.38,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "Wednesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 30.51,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "Wednesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 31.64,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "Wednesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 32.77,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "Wednesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 33.9,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "Wednesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.00399765706011419,
                        "binCatValue": null,
                        "binLowerBound": 0,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "Wednesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.00606447934281029,
                        "binCatValue": null,
                        "binLowerBound": 1.13,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "Wednesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.00881872343579779,
                        "binCatValue": null,
                        "binLowerBound": 2.26,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "Wednesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.0128874121497656,
                        "binCatValue": null,
                        "binLowerBound": 3.39,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "Wednesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.0168473440002514,
                        "binCatValue": null,
                        "binLowerBound": 4.52,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "Wednesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.0264679076529952,
                        "binCatValue": null,
                        "binLowerBound": 5.65,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "Wednesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.0458923584881404,
                        "binCatValue": null,
                        "binLowerBound": 6.78,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "Wednesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.0796375585006565,
                        "binCatValue": null,
                        "binLowerBound": 7.91,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "Wednesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.119103761697749,
                        "binCatValue": null,
                        "binLowerBound": 9.04,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "Wednesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.130272191253888,
                        "binCatValue": null,
                        "binLowerBound": 10.17,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "Wednesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.134089783623829,
                        "binCatValue": null,
                        "binLowerBound": 11.3,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "Wednesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.11609494148298,
                        "binCatValue": null,
                        "binLowerBound": 12.43,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "Wednesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.0942086031208249,
                        "binCatValue": null,
                        "binLowerBound": 13.56,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "Wednesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.0718302076137592,
                        "binCatValue": null,
                        "binLowerBound": 14.69,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "Wednesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.0516898063141893,
                        "binCatValue": null,
                        "binLowerBound": 15.82,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "Wednesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.0348676492767351,
                        "binCatValue": null,
                        "binLowerBound": 16.95,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "Wednesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.0220232648550815,
                        "binCatValue": null,
                        "binLowerBound": 18.08,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "Wednesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.012915084668102,
                        "binCatValue": null,
                        "binLowerBound": 19.21,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "Wednesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.00701423773162237,
                        "binCatValue": null,
                        "binLowerBound": 20.34,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "Wednesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.00330123201531412,
                        "binCatValue": null,
                        "binLowerBound": 21.47,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "Wednesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.00130215492970311,
                        "binCatValue": null,
                        "binLowerBound": 22.6,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "Wednesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.000470156638881562,
                        "binCatValue": null,
                        "binLowerBound": 23.73,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "Wednesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.000156617616253368,
                        "binCatValue": null,
                        "binLowerBound": 24.86,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "Wednesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.0000403488515863465,
                        "binCatValue": null,
                        "binLowerBound": 25.99,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "Wednesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.00000629674069930663,
                        "binCatValue": null,
                        "binLowerBound": 27.12,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "Wednesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 2.2093827015111e-7,
                        "binCatValue": null,
                        "binLowerBound": 28.25,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "Wednesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 29.38,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "Wednesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 30.51,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "Wednesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 31.64,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "Wednesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 32.77,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "Wednesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 33.9,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "Wednesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 0,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "Wednesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 1.13,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "Wednesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 2.26,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "Wednesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 3.39,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "Wednesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 4.52,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "Wednesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 5.65,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "Wednesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 6.78,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "Wednesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 7.91,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "Wednesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 9.04,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "Wednesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 10.17,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "Wednesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 11.3,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "Wednesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 12.43,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "Wednesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 13.56,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "Wednesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 14.69,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "Wednesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 15.82,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "Wednesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 16.95,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "Wednesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 18.08,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "Wednesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 19.21,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "Wednesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 20.34,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "Wednesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 21.47,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "Wednesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 22.6,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "Wednesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 23.73,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "Wednesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 24.86,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "Wednesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 25.99,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "Wednesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 27.12,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "Wednesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 28.25,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "Wednesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 29.38,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "Wednesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 30.51,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "Wednesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 31.64,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "Wednesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 32.77,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "Wednesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 33.9,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "appThroughput-cloud",
                        "weekday": "Wednesday"
                    }],
                    "totalCount": 744
                },
                "interference": {
                    "__typename": "SmartDashboardPeers36SConnection",
                    "nodes": [{
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.247024524202863,
                        "binCatValue": null,
                        "binLowerBound": 0,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "interference",
                        "weekday": "All"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.223735850714618,
                        "binCatValue": null,
                        "binLowerBound": 10,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "interference",
                        "weekday": "All"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.167068115959416,
                        "binCatValue": null,
                        "binLowerBound": 20,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "interference",
                        "weekday": "All"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.122909891648441,
                        "binCatValue": null,
                        "binLowerBound": 30,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "interference",
                        "weekday": "All"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.0929710799570521,
                        "binCatValue": null,
                        "binLowerBound": 40,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "interference",
                        "weekday": "All"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.0708703386293094,
                        "binCatValue": null,
                        "binLowerBound": 50,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "interference",
                        "weekday": "All"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.0479307515953783,
                        "binCatValue": null,
                        "binLowerBound": 60,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "interference",
                        "weekday": "All"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.0220139405691714,
                        "binCatValue": null,
                        "binLowerBound": 70,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "interference",
                        "weekday": "All"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.00506130608073323,
                        "binCatValue": null,
                        "binLowerBound": 80,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "interference",
                        "weekday": "All"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.000411733518433575,
                        "binCatValue": null,
                        "binLowerBound": 90,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "interference",
                        "weekday": "All"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.00000246712458237058,
                        "binCatValue": null,
                        "binLowerBound": 100,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "interference",
                        "weekday": "All"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz and 5 GHz",
                        "binAvgFrequency": 0.0562708730158434,
                        "binCatValue": null,
                        "binLowerBound": 0,
                        "lastTimestamp": "2019-01-09T00:00:00",
                        "variable": "interference",
                        "weekday": "All"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz and 5 GHz",
                        "binAvgFrequency": 0.0571287221863286,
                        "binCatValue": null,
                        "binLowerBound": 10,
                        "lastTimestamp": "2019-01-09T00:00:00",
                        "variable": "interference",
                        "weekday": "All"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz and 5 GHz",
                        "binAvgFrequency": 0.206201497685113,
                        "binCatValue": null,
                        "binLowerBound": 20,
                        "lastTimestamp": "2019-01-09T00:00:00",
                        "variable": "interference",
                        "weekday": "All"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz and 5 GHz",
                        "binAvgFrequency": 0.264373263274851,
                        "binCatValue": null,
                        "binLowerBound": 30,
                        "lastTimestamp": "2019-01-09T00:00:00",
                        "variable": "interference",
                        "weekday": "All"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz and 5 GHz",
                        "binAvgFrequency": 0.221037848218221,
                        "binCatValue": null,
                        "binLowerBound": 40,
                        "lastTimestamp": "2019-01-09T00:00:00",
                        "variable": "interference",
                        "weekday": "All"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz and 5 GHz",
                        "binAvgFrequency": 0.133627599630558,
                        "binCatValue": null,
                        "binLowerBound": 50,
                        "lastTimestamp": "2019-01-09T00:00:00",
                        "variable": "interference",
                        "weekday": "All"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz and 5 GHz",
                        "binAvgFrequency": 0.0476932318330489,
                        "binCatValue": null,
                        "binLowerBound": 60,
                        "lastTimestamp": "2019-01-09T00:00:00",
                        "variable": "interference",
                        "weekday": "All"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz and 5 GHz",
                        "binAvgFrequency": 0.0106949280589463,
                        "binCatValue": null,
                        "binLowerBound": 70,
                        "lastTimestamp": "2019-01-09T00:00:00",
                        "variable": "interference",
                        "weekday": "All"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz and 5 GHz",
                        "binAvgFrequency": 0.00172745985869253,
                        "binCatValue": null,
                        "binLowerBound": 80,
                        "lastTimestamp": "2019-01-09T00:00:00",
                        "variable": "interference",
                        "weekday": "All"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz and 5 GHz",
                        "binAvgFrequency": 0.00124457623839701,
                        "binCatValue": null,
                        "binLowerBound": 90,
                        "lastTimestamp": "2019-01-09T00:00:00",
                        "variable": "interference",
                        "weekday": "All"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.925720939758228,
                        "binCatValue": null,
                        "binLowerBound": 0,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "interference",
                        "weekday": "All"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.0572544587221013,
                        "binCatValue": null,
                        "binLowerBound": 10,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "interference",
                        "weekday": "All"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.0114803282164672,
                        "binCatValue": null,
                        "binLowerBound": 20,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "interference",
                        "weekday": "All"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.00308611859465956,
                        "binCatValue": null,
                        "binLowerBound": 30,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "interference",
                        "weekday": "All"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.00130827054499251,
                        "binCatValue": null,
                        "binLowerBound": 40,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "interference",
                        "weekday": "All"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.000625448451323001,
                        "binCatValue": null,
                        "binLowerBound": 50,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "interference",
                        "weekday": "All"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.000271737878982848,
                        "binCatValue": null,
                        "binLowerBound": 60,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "interference",
                        "weekday": "All"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.000116811540721888,
                        "binCatValue": null,
                        "binLowerBound": 70,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "interference",
                        "weekday": "All"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.0000541897386762317,
                        "binCatValue": null,
                        "binLowerBound": 80,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "interference",
                        "weekday": "All"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.0000735059239289175,
                        "binCatValue": null,
                        "binLowerBound": 90,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "interference",
                        "weekday": "All"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.00000819062991575489,
                        "binCatValue": null,
                        "binLowerBound": 100,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "interference",
                        "weekday": "All"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0.991998553410786,
                        "binCatValue": null,
                        "binLowerBound": 0,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "interference",
                        "weekday": "All"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0.00641923963654446,
                        "binCatValue": null,
                        "binLowerBound": 10,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "interference",
                        "weekday": "All"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0.00131097147506894,
                        "binCatValue": null,
                        "binLowerBound": 20,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "interference",
                        "weekday": "All"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0.000180823651733647,
                        "binCatValue": null,
                        "binLowerBound": 30,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "interference",
                        "weekday": "All"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0.0000904118258668234,
                        "binCatValue": null,
                        "binLowerBound": 40,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "interference",
                        "weekday": "All"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 50,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "interference",
                        "weekday": "All"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 60,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "interference",
                        "weekday": "All"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 70,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "interference",
                        "weekday": "All"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 80,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "interference",
                        "weekday": "All"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 90,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "interference",
                        "weekday": "All"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 100,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "interference",
                        "weekday": "All"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.245023533552328,
                        "binCatValue": null,
                        "binLowerBound": 0,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "interference",
                        "weekday": "Friday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.223800351213267,
                        "binCatValue": null,
                        "binLowerBound": 10,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "interference",
                        "weekday": "Friday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.167040322268215,
                        "binCatValue": null,
                        "binLowerBound": 20,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "interference",
                        "weekday": "Friday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.122885269331714,
                        "binCatValue": null,
                        "binLowerBound": 30,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "interference",
                        "weekday": "Friday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.0935700528060814,
                        "binCatValue": null,
                        "binLowerBound": 40,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "interference",
                        "weekday": "Friday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.0716614270748515,
                        "binCatValue": null,
                        "binLowerBound": 50,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "interference",
                        "weekday": "Friday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.048512359386817,
                        "binCatValue": null,
                        "binLowerBound": 60,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "interference",
                        "weekday": "Friday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.0220737292729287,
                        "binCatValue": null,
                        "binLowerBound": 70,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "interference",
                        "weekday": "Friday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.00503385249266142,
                        "binCatValue": null,
                        "binLowerBound": 80,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "interference",
                        "weekday": "Friday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.000397017866449265,
                        "binCatValue": null,
                        "binLowerBound": 90,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "interference",
                        "weekday": "Friday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.00000208473468661238,
                        "binCatValue": null,
                        "binLowerBound": 100,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "interference",
                        "weekday": "Friday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz and 5 GHz",
                        "binAvgFrequency": 0.0575771896660365,
                        "binCatValue": null,
                        "binLowerBound": 0,
                        "lastTimestamp": "2019-01-04T00:00:00",
                        "variable": "interference",
                        "weekday": "Friday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz and 5 GHz",
                        "binAvgFrequency": 0.0522211720226843,
                        "binCatValue": null,
                        "binLowerBound": 10,
                        "lastTimestamp": "2019-01-04T00:00:00",
                        "variable": "interference",
                        "weekday": "Friday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz and 5 GHz",
                        "binAvgFrequency": 0.204158790170132,
                        "binCatValue": null,
                        "binLowerBound": 20,
                        "lastTimestamp": "2019-01-04T00:00:00",
                        "variable": "interference",
                        "weekday": "Friday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz and 5 GHz",
                        "binAvgFrequency": 0.262208569628229,
                        "binCatValue": null,
                        "binLowerBound": 30,
                        "lastTimestamp": "2019-01-04T00:00:00",
                        "variable": "interference",
                        "weekday": "Friday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz and 5 GHz",
                        "binAvgFrequency": 0.22440138626339,
                        "binCatValue": null,
                        "binLowerBound": 40,
                        "lastTimestamp": "2019-01-04T00:00:00",
                        "variable": "interference",
                        "weekday": "Friday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz and 5 GHz",
                        "binAvgFrequency": 0.1330734089477,
                        "binCatValue": null,
                        "binLowerBound": 50,
                        "lastTimestamp": "2019-01-04T00:00:00",
                        "variable": "interference",
                        "weekday": "Friday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz and 5 GHz",
                        "binAvgFrequency": 0.0496613106490233,
                        "binCatValue": null,
                        "binLowerBound": 60,
                        "lastTimestamp": "2019-01-04T00:00:00",
                        "variable": "interference",
                        "weekday": "Friday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz and 5 GHz",
                        "binAvgFrequency": 0.0129174543163201,
                        "binCatValue": null,
                        "binLowerBound": 70,
                        "lastTimestamp": "2019-01-04T00:00:00",
                        "variable": "interference",
                        "weekday": "Friday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz and 5 GHz",
                        "binAvgFrequency": 0.0020478890989288,
                        "binCatValue": null,
                        "binLowerBound": 80,
                        "lastTimestamp": "2019-01-04T00:00:00",
                        "variable": "interference",
                        "weekday": "Friday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz and 5 GHz",
                        "binAvgFrequency": 0.00173282923755514,
                        "binCatValue": null,
                        "binLowerBound": 90,
                        "lastTimestamp": "2019-01-04T00:00:00",
                        "variable": "interference",
                        "weekday": "Friday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.925130085722114,
                        "binCatValue": null,
                        "binLowerBound": 0,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "interference",
                        "weekday": "Friday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.057786768402815,
                        "binCatValue": null,
                        "binLowerBound": 10,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "interference",
                        "weekday": "Friday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.0115559094935499,
                        "binCatValue": null,
                        "binLowerBound": 20,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "interference",
                        "weekday": "Friday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.00302469681904157,
                        "binCatValue": null,
                        "binLowerBound": 30,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "interference",
                        "weekday": "Friday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.00131568129123319,
                        "binCatValue": null,
                        "binLowerBound": 40,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "interference",
                        "weekday": "Friday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.000645615054490032,
                        "binCatValue": null,
                        "binLowerBound": 50,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "interference",
                        "weekday": "Friday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.00028377024846103,
                        "binCatValue": null,
                        "binLowerBound": 60,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "interference",
                        "weekday": "Friday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.000123044348915928,
                        "binCatValue": null,
                        "binLowerBound": 70,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "interference",
                        "weekday": "Friday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.0000547771865104356,
                        "binCatValue": null,
                        "binLowerBound": 80,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "interference",
                        "weekday": "Friday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.0000718343620185122,
                        "binCatValue": null,
                        "binLowerBound": 90,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "interference",
                        "weekday": "Friday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.00000781707085009376,
                        "binCatValue": null,
                        "binLowerBound": 100,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "interference",
                        "weekday": "Friday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0.992469879518072,
                        "binCatValue": null,
                        "binLowerBound": 0,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "interference",
                        "weekday": "Friday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0.00602409638554217,
                        "binCatValue": null,
                        "binLowerBound": 10,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "interference",
                        "weekday": "Friday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0.00120481927710843,
                        "binCatValue": null,
                        "binLowerBound": 20,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "interference",
                        "weekday": "Friday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0.000301204819277108,
                        "binCatValue": null,
                        "binLowerBound": 30,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "interference",
                        "weekday": "Friday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 40,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "interference",
                        "weekday": "Friday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 50,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "interference",
                        "weekday": "Friday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 60,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "interference",
                        "weekday": "Friday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 70,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "interference",
                        "weekday": "Friday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 80,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "interference",
                        "weekday": "Friday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 90,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "interference",
                        "weekday": "Friday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 100,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "interference",
                        "weekday": "Friday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.239607024072793,
                        "binCatValue": null,
                        "binLowerBound": 0,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "interference",
                        "weekday": "Monday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.222745809744402,
                        "binCatValue": null,
                        "binLowerBound": 10,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "interference",
                        "weekday": "Monday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.169116315787911,
                        "binCatValue": null,
                        "binLowerBound": 20,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "interference",
                        "weekday": "Monday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.12476309373035,
                        "binCatValue": null,
                        "binLowerBound": 30,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "interference",
                        "weekday": "Monday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.0947002895063597,
                        "binCatValue": null,
                        "binLowerBound": 40,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "interference",
                        "weekday": "Monday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.0721774344871379,
                        "binCatValue": null,
                        "binLowerBound": 50,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "interference",
                        "weekday": "Monday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.0489401820949915,
                        "binCatValue": null,
                        "binLowerBound": 60,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "interference",
                        "weekday": "Monday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.0223671943909796,
                        "binCatValue": null,
                        "binLowerBound": 70,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "interference",
                        "weekday": "Monday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.0051517039783298,
                        "binCatValue": null,
                        "binLowerBound": 80,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "interference",
                        "weekday": "Monday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.000428238843961661,
                        "binCatValue": null,
                        "binLowerBound": 90,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "interference",
                        "weekday": "Monday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.00000271336278524395,
                        "binCatValue": null,
                        "binLowerBound": 100,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "interference",
                        "weekday": "Monday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz and 5 GHz",
                        "binAvgFrequency": 0.0559747185463164,
                        "binCatValue": null,
                        "binLowerBound": 0,
                        "lastTimestamp": "2019-01-07T00:00:00",
                        "variable": "interference",
                        "weekday": "Monday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz and 5 GHz",
                        "binAvgFrequency": 0.0621765751530713,
                        "binCatValue": null,
                        "binLowerBound": 10,
                        "lastTimestamp": "2019-01-07T00:00:00",
                        "variable": "interference",
                        "weekday": "Monday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz and 5 GHz",
                        "binAvgFrequency": 0.221805253802094,
                        "binCatValue": null,
                        "binLowerBound": 20,
                        "lastTimestamp": "2019-01-07T00:00:00",
                        "variable": "interference",
                        "weekday": "Monday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz and 5 GHz",
                        "binAvgFrequency": 0.276120876950425,
                        "binCatValue": null,
                        "binLowerBound": 30,
                        "lastTimestamp": "2019-01-07T00:00:00",
                        "variable": "interference",
                        "weekday": "Monday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz and 5 GHz",
                        "binAvgFrequency": 0.209757061031009,
                        "binCatValue": null,
                        "binLowerBound": 40,
                        "lastTimestamp": "2019-01-07T00:00:00",
                        "variable": "interference",
                        "weekday": "Monday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz and 5 GHz",
                        "binAvgFrequency": 0.129922970570808,
                        "binCatValue": null,
                        "binLowerBound": 50,
                        "lastTimestamp": "2019-01-07T00:00:00",
                        "variable": "interference",
                        "weekday": "Monday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz and 5 GHz",
                        "binAvgFrequency": 0.0348805056290737,
                        "binCatValue": null,
                        "binLowerBound": 60,
                        "lastTimestamp": "2019-01-07T00:00:00",
                        "variable": "interference",
                        "weekday": "Monday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz and 5 GHz",
                        "binAvgFrequency": 0.00596484297847126,
                        "binCatValue": null,
                        "binLowerBound": 70,
                        "lastTimestamp": "2019-01-07T00:00:00",
                        "variable": "interference",
                        "weekday": "Monday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz and 5 GHz",
                        "binAvgFrequency": 0.000592534070709066,
                        "binCatValue": null,
                        "binLowerBound": 80,
                        "lastTimestamp": "2019-01-07T00:00:00",
                        "variable": "interference",
                        "weekday": "Monday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz and 5 GHz",
                        "binAvgFrequency": 0.00280466126802291,
                        "binCatValue": null,
                        "binLowerBound": 90,
                        "lastTimestamp": "2019-01-07T00:00:00",
                        "variable": "interference",
                        "weekday": "Monday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.922135109154231,
                        "binCatValue": null,
                        "binLowerBound": 0,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "interference",
                        "weekday": "Monday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.0597200958508557,
                        "binCatValue": null,
                        "binLowerBound": 10,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "interference",
                        "weekday": "Monday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.0122947616680227,
                        "binCatValue": null,
                        "binLowerBound": 20,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "interference",
                        "weekday": "Monday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.00333006053076768,
                        "binCatValue": null,
                        "binLowerBound": 30,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "interference",
                        "weekday": "Monday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.00137748708527632,
                        "binCatValue": null,
                        "binLowerBound": 40,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "interference",
                        "weekday": "Monday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.000624264447314704,
                        "binCatValue": null,
                        "binLowerBound": 50,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "interference",
                        "weekday": "Monday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.000265092314257676,
                        "binCatValue": null,
                        "binLowerBound": 60,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "interference",
                        "weekday": "Monday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.000117745795390892,
                        "binCatValue": null,
                        "binLowerBound": 70,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "interference",
                        "weekday": "Monday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.0000537673465532862,
                        "binCatValue": null,
                        "binLowerBound": 80,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "interference",
                        "weekday": "Monday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.0000736158936902218,
                        "binCatValue": null,
                        "binLowerBound": 90,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "interference",
                        "weekday": "Monday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.00000799991363848119,
                        "binCatValue": null,
                        "binLowerBound": 100,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "interference",
                        "weekday": "Monday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0.992611537368571,
                        "binCatValue": null,
                        "binLowerBound": 0,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "interference",
                        "weekday": "Monday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0.00568343279340722,
                        "binCatValue": null,
                        "binLowerBound": 10,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "interference",
                        "weekday": "Monday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0.0014208581983518,
                        "binCatValue": null,
                        "binLowerBound": 20,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "interference",
                        "weekday": "Monday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 30,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "interference",
                        "weekday": "Monday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0.000284171639670361,
                        "binCatValue": null,
                        "binLowerBound": 40,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "interference",
                        "weekday": "Monday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 50,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "interference",
                        "weekday": "Monday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 60,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "interference",
                        "weekday": "Monday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 70,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "interference",
                        "weekday": "Monday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 80,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "interference",
                        "weekday": "Monday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 90,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "interference",
                        "weekday": "Monday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 100,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "interference",
                        "weekday": "Monday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.268685216119293,
                        "binCatValue": null,
                        "binLowerBound": 0,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "interference",
                        "weekday": "Saturday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.226492695961865,
                        "binCatValue": null,
                        "binLowerBound": 10,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "interference",
                        "weekday": "Saturday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.161334771684514,
                        "binCatValue": null,
                        "binLowerBound": 20,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "interference",
                        "weekday": "Saturday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.116429853601212,
                        "binCatValue": null,
                        "binLowerBound": 30,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "interference",
                        "weekday": "Saturday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.0878142487055593,
                        "binCatValue": null,
                        "binLowerBound": 40,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "interference",
                        "weekday": "Saturday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.0668898424596965,
                        "binCatValue": null,
                        "binLowerBound": 50,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "interference",
                        "weekday": "Saturday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.0454131244633409,
                        "binCatValue": null,
                        "binLowerBound": 60,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "interference",
                        "weekday": "Saturday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.021318377055659,
                        "binCatValue": null,
                        "binLowerBound": 70,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "interference",
                        "weekday": "Saturday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.00520329338513584,
                        "binCatValue": null,
                        "binLowerBound": 80,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "interference",
                        "weekday": "Saturday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.000416473291246006,
                        "binCatValue": null,
                        "binLowerBound": 90,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "interference",
                        "weekday": "Saturday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.00000210327247784719,
                        "binCatValue": null,
                        "binLowerBound": 100,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "interference",
                        "weekday": "Saturday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz and 5 GHz",
                        "binAvgFrequency": 0.0553323881537492,
                        "binCatValue": null,
                        "binLowerBound": 0,
                        "lastTimestamp": "2019-01-05T00:00:00",
                        "variable": "interference",
                        "weekday": "Saturday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz and 5 GHz",
                        "binAvgFrequency": 0.0523393194706994,
                        "binCatValue": null,
                        "binLowerBound": 10,
                        "lastTimestamp": "2019-01-05T00:00:00",
                        "variable": "interference",
                        "weekday": "Saturday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz and 5 GHz",
                        "binAvgFrequency": 0.19025677378702,
                        "binCatValue": null,
                        "binLowerBound": 20,
                        "lastTimestamp": "2019-01-05T00:00:00",
                        "variable": "interference",
                        "weekday": "Saturday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz and 5 GHz",
                        "binAvgFrequency": 0.2479127284184,
                        "binCatValue": null,
                        "binLowerBound": 30,
                        "lastTimestamp": "2019-01-05T00:00:00",
                        "variable": "interference",
                        "weekday": "Saturday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz and 5 GHz",
                        "binAvgFrequency": 0.216446124763705,
                        "binCatValue": null,
                        "binLowerBound": 40,
                        "lastTimestamp": "2019-01-05T00:00:00",
                        "variable": "interference",
                        "weekday": "Saturday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz and 5 GHz",
                        "binAvgFrequency": 0.145321361058601,
                        "binCatValue": null,
                        "binLowerBound": 50,
                        "lastTimestamp": "2019-01-05T00:00:00",
                        "variable": "interference",
                        "weekday": "Saturday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz and 5 GHz",
                        "binAvgFrequency": 0.0667926906112161,
                        "binCatValue": null,
                        "binLowerBound": 60,
                        "lastTimestamp": "2019-01-05T00:00:00",
                        "variable": "interference",
                        "weekday": "Saturday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz and 5 GHz",
                        "binAvgFrequency": 0.0207151858853182,
                        "binCatValue": null,
                        "binLowerBound": 70,
                        "lastTimestamp": "2019-01-05T00:00:00",
                        "variable": "interference",
                        "weekday": "Saturday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz and 5 GHz",
                        "binAvgFrequency": 0.00405639571518589,
                        "binCatValue": null,
                        "binLowerBound": 80,
                        "lastTimestamp": "2019-01-05T00:00:00",
                        "variable": "interference",
                        "weekday": "Saturday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz and 5 GHz",
                        "binAvgFrequency": 0.00082703213610586,
                        "binCatValue": null,
                        "binLowerBound": 90,
                        "lastTimestamp": "2019-01-05T00:00:00",
                        "variable": "interference",
                        "weekday": "Saturday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.935151376356188,
                        "binCatValue": null,
                        "binLowerBound": 0,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "interference",
                        "weekday": "Saturday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.0503773539966509,
                        "binCatValue": null,
                        "binLowerBound": 10,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "interference",
                        "weekday": "Saturday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.00958034088019036,
                        "binCatValue": null,
                        "binLowerBound": 20,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "interference",
                        "weekday": "Saturday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.00259500386207474,
                        "binCatValue": null,
                        "binLowerBound": 30,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "interference",
                        "weekday": "Saturday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.00117438619091298,
                        "binCatValue": null,
                        "binLowerBound": 40,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "interference",
                        "weekday": "Saturday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.000608198344939749,
                        "binCatValue": null,
                        "binLowerBound": 50,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "interference",
                        "weekday": "Saturday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.00026834796249965,
                        "binCatValue": null,
                        "binLowerBound": 60,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "interference",
                        "weekday": "Saturday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.000109402341058656,
                        "binCatValue": null,
                        "binLowerBound": 70,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "interference",
                        "weekday": "Saturday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.000053309671090245,
                        "binCatValue": null,
                        "binLowerBound": 80,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "interference",
                        "weekday": "Saturday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.0000739313977599081,
                        "binCatValue": null,
                        "binLowerBound": 90,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "interference",
                        "weekday": "Saturday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.00000834899663449881,
                        "binCatValue": null,
                        "binLowerBound": 100,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "interference",
                        "weekday": "Saturday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0.989080459770115,
                        "binCatValue": null,
                        "binLowerBound": 0,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "interference",
                        "weekday": "Saturday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0.00890804597701149,
                        "binCatValue": null,
                        "binLowerBound": 10,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "interference",
                        "weekday": "Saturday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0.00201149425287356,
                        "binCatValue": null,
                        "binLowerBound": 20,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "interference",
                        "weekday": "Saturday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 30,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "interference",
                        "weekday": "Saturday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 40,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "interference",
                        "weekday": "Saturday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 50,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "interference",
                        "weekday": "Saturday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 60,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "interference",
                        "weekday": "Saturday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 70,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "interference",
                        "weekday": "Saturday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 80,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "interference",
                        "weekday": "Saturday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 90,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "interference",
                        "weekday": "Saturday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 100,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "interference",
                        "weekday": "Saturday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.277824074632675,
                        "binCatValue": null,
                        "binLowerBound": 0,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "interference",
                        "weekday": "Sunday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.223301506479996,
                        "binCatValue": null,
                        "binLowerBound": 10,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "interference",
                        "weekday": "Sunday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.160258551846892,
                        "binCatValue": null,
                        "binLowerBound": 20,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "interference",
                        "weekday": "Sunday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.11609810017549,
                        "binCatValue": null,
                        "binLowerBound": 30,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "interference",
                        "weekday": "Sunday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.0867216508850698,
                        "binCatValue": null,
                        "binLowerBound": 40,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "interference",
                        "weekday": "Sunday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.0651072536357108,
                        "binCatValue": null,
                        "binLowerBound": 50,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "interference",
                        "weekday": "Sunday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.0442317738532703,
                        "binCatValue": null,
                        "binLowerBound": 60,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "interference",
                        "weekday": "Sunday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.0210410267891656,
                        "binCatValue": null,
                        "binLowerBound": 70,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "interference",
                        "weekday": "Sunday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.00502586769735263,
                        "binCatValue": null,
                        "binLowerBound": 80,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "interference",
                        "weekday": "Sunday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.000388763985694451,
                        "binCatValue": null,
                        "binLowerBound": 90,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "interference",
                        "weekday": "Sunday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.00000143001868408787,
                        "binCatValue": null,
                        "binLowerBound": 100,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "interference",
                        "weekday": "Sunday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz and 5 GHz",
                        "binAvgFrequency": 0.054820415879017,
                        "binCatValue": null,
                        "binLowerBound": 0,
                        "lastTimestamp": "2019-01-06T00:00:00",
                        "variable": "interference",
                        "weekday": "Sunday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz and 5 GHz",
                        "binAvgFrequency": 0.0636027095148078,
                        "binCatValue": null,
                        "binLowerBound": 10,
                        "lastTimestamp": "2019-01-06T00:00:00",
                        "variable": "interference",
                        "weekday": "Sunday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz and 5 GHz",
                        "binAvgFrequency": 0.205891619407687,
                        "binCatValue": null,
                        "binLowerBound": 20,
                        "lastTimestamp": "2019-01-06T00:00:00",
                        "variable": "interference",
                        "weekday": "Sunday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz and 5 GHz",
                        "binAvgFrequency": 0.255631695022054,
                        "binCatValue": null,
                        "binLowerBound": 30,
                        "lastTimestamp": "2019-01-06T00:00:00",
                        "variable": "interference",
                        "weekday": "Sunday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz and 5 GHz",
                        "binAvgFrequency": 0.226803717706364,
                        "binCatValue": null,
                        "binLowerBound": 40,
                        "lastTimestamp": "2019-01-06T00:00:00",
                        "variable": "interference",
                        "weekday": "Sunday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz and 5 GHz",
                        "binAvgFrequency": 0.129804662885948,
                        "binCatValue": null,
                        "binLowerBound": 50,
                        "lastTimestamp": "2019-01-06T00:00:00",
                        "variable": "interference",
                        "weekday": "Sunday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz and 5 GHz",
                        "binAvgFrequency": 0.0528512917454316,
                        "binCatValue": null,
                        "binLowerBound": 60,
                        "lastTimestamp": "2019-01-06T00:00:00",
                        "variable": "interference",
                        "weekday": "Sunday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz and 5 GHz",
                        "binAvgFrequency": 0.00945179584120983,
                        "binCatValue": null,
                        "binLowerBound": 70,
                        "lastTimestamp": "2019-01-06T00:00:00",
                        "variable": "interference",
                        "weekday": "Sunday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz and 5 GHz",
                        "binAvgFrequency": 0.0010239445494644,
                        "binCatValue": null,
                        "binLowerBound": 80,
                        "lastTimestamp": "2019-01-06T00:00:00",
                        "variable": "interference",
                        "weekday": "Sunday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz and 5 GHz",
                        "binAvgFrequency": 0.000118147448015123,
                        "binCatValue": null,
                        "binLowerBound": 90,
                        "lastTimestamp": "2019-01-06T00:00:00",
                        "variable": "interference",
                        "weekday": "Sunday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.94238796225749,
                        "binCatValue": null,
                        "binLowerBound": 0,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "interference",
                        "weekday": "Sunday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.0453047089034145,
                        "binCatValue": null,
                        "binLowerBound": 10,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "interference",
                        "weekday": "Sunday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.00818804846441504,
                        "binCatValue": null,
                        "binLowerBound": 20,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "interference",
                        "weekday": "Sunday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.00227897775798712,
                        "binCatValue": null,
                        "binLowerBound": 30,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "interference",
                        "weekday": "Sunday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.000926819071450847,
                        "binCatValue": null,
                        "binLowerBound": 40,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "interference",
                        "weekday": "Sunday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.000468122954471328,
                        "binCatValue": null,
                        "binLowerBound": 50,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "interference",
                        "weekday": "Sunday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.00021993006418509,
                        "binCatValue": null,
                        "binLowerBound": 60,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "interference",
                        "weekday": "Sunday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.0000818348537985053,
                        "binCatValue": null,
                        "binLowerBound": 70,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "interference",
                        "weekday": "Sunday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.0000520333131349043,
                        "binCatValue": null,
                        "binLowerBound": 80,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "interference",
                        "weekday": "Sunday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.000082277013155532,
                        "binCatValue": null,
                        "binLowerBound": 90,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "interference",
                        "weekday": "Sunday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.00000928534649756111,
                        "binCatValue": null,
                        "binLowerBound": 100,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "interference",
                        "weekday": "Sunday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0.991825613079019,
                        "binCatValue": null,
                        "binLowerBound": 0,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "interference",
                        "weekday": "Sunday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0.00681198910081744,
                        "binCatValue": null,
                        "binLowerBound": 10,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "interference",
                        "weekday": "Sunday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0.00136239782016349,
                        "binCatValue": null,
                        "binLowerBound": 20,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "interference",
                        "weekday": "Sunday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 30,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "interference",
                        "weekday": "Sunday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 40,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "interference",
                        "weekday": "Sunday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 50,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "interference",
                        "weekday": "Sunday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 60,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "interference",
                        "weekday": "Sunday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 70,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "interference",
                        "weekday": "Sunday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 80,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "interference",
                        "weekday": "Sunday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 90,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "interference",
                        "weekday": "Sunday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 100,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "interference",
                        "weekday": "Sunday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.239177975527172,
                        "binCatValue": null,
                        "binLowerBound": 0,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "interference",
                        "weekday": "Thursday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.223269822347179,
                        "binCatValue": null,
                        "binLowerBound": 10,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "interference",
                        "weekday": "Thursday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.169250922989193,
                        "binCatValue": null,
                        "binLowerBound": 20,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "interference",
                        "weekday": "Thursday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.125219073594884,
                        "binCatValue": null,
                        "binLowerBound": 30,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "interference",
                        "weekday": "Thursday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.094595942828216,
                        "binCatValue": null,
                        "binLowerBound": 40,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "interference",
                        "weekday": "Thursday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.0722924833635976,
                        "binCatValue": null,
                        "binLowerBound": 50,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "interference",
                        "weekday": "Thursday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.0486766873100209,
                        "binCatValue": null,
                        "binLowerBound": 60,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "interference",
                        "weekday": "Thursday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.0221510502582148,
                        "binCatValue": null,
                        "binLowerBound": 70,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "interference",
                        "weekday": "Thursday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.00496342653668527,
                        "binCatValue": null,
                        "binLowerBound": 80,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "interference",
                        "weekday": "Thursday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.000400198986288312,
                        "binCatValue": null,
                        "binLowerBound": 90,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "interference",
                        "weekday": "Thursday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.00000241625855011888,
                        "binCatValue": null,
                        "binLowerBound": 100,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "interference",
                        "weekday": "Thursday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz and 5 GHz",
                        "binAvgFrequency": 0.056710775047259,
                        "binCatValue": null,
                        "binLowerBound": 0,
                        "lastTimestamp": "2019-01-03T00:00:00",
                        "variable": "interference",
                        "weekday": "Thursday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz and 5 GHz",
                        "binAvgFrequency": 0.056710775047259,
                        "binCatValue": null,
                        "binLowerBound": 10,
                        "lastTimestamp": "2019-01-03T00:00:00",
                        "variable": "interference",
                        "weekday": "Thursday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz and 5 GHz",
                        "binAvgFrequency": 0.200259924385633,
                        "binCatValue": null,
                        "binLowerBound": 20,
                        "lastTimestamp": "2019-01-03T00:00:00",
                        "variable": "interference",
                        "weekday": "Thursday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz and 5 GHz",
                        "binAvgFrequency": 0.252008506616257,
                        "binCatValue": null,
                        "binLowerBound": 30,
                        "lastTimestamp": "2019-01-03T00:00:00",
                        "variable": "interference",
                        "weekday": "Thursday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz and 5 GHz",
                        "binAvgFrequency": 0.23062381852552,
                        "binCatValue": null,
                        "binLowerBound": 40,
                        "lastTimestamp": "2019-01-03T00:00:00",
                        "variable": "interference",
                        "weekday": "Thursday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz and 5 GHz",
                        "binAvgFrequency": 0.141540642722117,
                        "binCatValue": null,
                        "binLowerBound": 50,
                        "lastTimestamp": "2019-01-03T00:00:00",
                        "variable": "interference",
                        "weekday": "Thursday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz and 5 GHz",
                        "binAvgFrequency": 0.0474952741020794,
                        "binCatValue": null,
                        "binLowerBound": 60,
                        "lastTimestamp": "2019-01-03T00:00:00",
                        "variable": "interference",
                        "weekday": "Thursday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz and 5 GHz",
                        "binAvgFrequency": 0.0107514177693762,
                        "binCatValue": null,
                        "binLowerBound": 70,
                        "lastTimestamp": "2019-01-03T00:00:00",
                        "variable": "interference",
                        "weekday": "Thursday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz and 5 GHz",
                        "binAvgFrequency": 0.00200850661625709,
                        "binCatValue": null,
                        "binLowerBound": 80,
                        "lastTimestamp": "2019-01-03T00:00:00",
                        "variable": "interference",
                        "weekday": "Thursday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz and 5 GHz",
                        "binAvgFrequency": 0.00189035916824197,
                        "binCatValue": null,
                        "binLowerBound": 90,
                        "lastTimestamp": "2019-01-03T00:00:00",
                        "variable": "interference",
                        "weekday": "Thursday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.92153655655016,
                        "binCatValue": null,
                        "binLowerBound": 0,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "interference",
                        "weekday": "Thursday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.0603593285301307,
                        "binCatValue": null,
                        "binLowerBound": 10,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "interference",
                        "weekday": "Thursday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.0122890555214536,
                        "binCatValue": null,
                        "binLowerBound": 20,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "interference",
                        "weekday": "Thursday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.00326275684387346,
                        "binCatValue": null,
                        "binLowerBound": 30,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "interference",
                        "weekday": "Thursday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.00137184105405166,
                        "binCatValue": null,
                        "binLowerBound": 40,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "interference",
                        "weekday": "Thursday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.000652463663076347,
                        "binCatValue": null,
                        "binLowerBound": 50,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "interference",
                        "weekday": "Thursday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.00027798893083541,
                        "binCatValue": null,
                        "binLowerBound": 60,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "interference",
                        "weekday": "Thursday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.000116162384164248,
                        "binCatValue": null,
                        "binLowerBound": 70,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "interference",
                        "weekday": "Thursday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.0000525852860935571,
                        "binCatValue": null,
                        "binLowerBound": 80,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "interference",
                        "weekday": "Thursday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.0000733295903973242,
                        "binCatValue": null,
                        "binLowerBound": 90,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "interference",
                        "weekday": "Thursday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.00000793164576320512,
                        "binCatValue": null,
                        "binLowerBound": 100,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "interference",
                        "weekday": "Thursday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0.992226540810661,
                        "binCatValue": null,
                        "binLowerBound": 0,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "interference",
                        "weekday": "Thursday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0.00610771793448084,
                        "binCatValue": null,
                        "binLowerBound": 10,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "interference",
                        "weekday": "Thursday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0.00138811771238201,
                        "binCatValue": null,
                        "binLowerBound": 20,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "interference",
                        "weekday": "Thursday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0.000277623542476402,
                        "binCatValue": null,
                        "binLowerBound": 30,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "interference",
                        "weekday": "Thursday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 40,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "interference",
                        "weekday": "Thursday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 50,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "interference",
                        "weekday": "Thursday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 60,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "interference",
                        "weekday": "Thursday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 70,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "interference",
                        "weekday": "Thursday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 80,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "interference",
                        "weekday": "Thursday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 90,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "interference",
                        "weekday": "Thursday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 100,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "interference",
                        "weekday": "Thursday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.235138178122334,
                        "binCatValue": null,
                        "binLowerBound": 0,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "interference",
                        "weekday": "Tuesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.22322481573282,
                        "binCatValue": null,
                        "binLowerBound": 10,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "interference",
                        "weekday": "Tuesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.170047681087463,
                        "binCatValue": null,
                        "binLowerBound": 20,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "interference",
                        "weekday": "Tuesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.12625157899858,
                        "binCatValue": null,
                        "binLowerBound": 30,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "interference",
                        "weekday": "Tuesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.0955686447370478,
                        "binCatValue": null,
                        "binLowerBound": 40,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "interference",
                        "weekday": "Tuesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.0728216036761346,
                        "binCatValue": null,
                        "binLowerBound": 50,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "interference",
                        "weekday": "Tuesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.0491068015526747,
                        "binCatValue": null,
                        "binLowerBound": 60,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "interference",
                        "weekday": "Tuesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.0223713908914153,
                        "binCatValue": null,
                        "binLowerBound": 70,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "interference",
                        "weekday": "Tuesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.00504454209475555,
                        "binCatValue": null,
                        "binLowerBound": 80,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "interference",
                        "weekday": "Tuesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.00042177669267779,
                        "binCatValue": null,
                        "binLowerBound": 90,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "interference",
                        "weekday": "Tuesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.0000029864140963958,
                        "binCatValue": null,
                        "binLowerBound": 100,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "interference",
                        "weekday": "Tuesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz and 5 GHz",
                        "binAvgFrequency": 0.0573015122873346,
                        "binCatValue": null,
                        "binLowerBound": 0,
                        "lastTimestamp": "2019-01-08T00:00:00",
                        "variable": "interference",
                        "weekday": "Tuesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz and 5 GHz",
                        "binAvgFrequency": 0.0603930371770636,
                        "binCatValue": null,
                        "binLowerBound": 10,
                        "lastTimestamp": "2019-01-08T00:00:00",
                        "variable": "interference",
                        "weekday": "Tuesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz and 5 GHz",
                        "binAvgFrequency": 0.210164618777568,
                        "binCatValue": null,
                        "binLowerBound": 20,
                        "lastTimestamp": "2019-01-08T00:00:00",
                        "variable": "interference",
                        "weekday": "Tuesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz and 5 GHz",
                        "binAvgFrequency": 0.282017958412098,
                        "binCatValue": null,
                        "binLowerBound": 30,
                        "lastTimestamp": "2019-01-08T00:00:00",
                        "variable": "interference",
                        "weekday": "Tuesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz and 5 GHz",
                        "binAvgFrequency": 0.20801827347196,
                        "binCatValue": null,
                        "binLowerBound": 40,
                        "lastTimestamp": "2019-01-08T00:00:00",
                        "variable": "interference",
                        "weekday": "Tuesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz and 5 GHz",
                        "binAvgFrequency": 0.12775677378702,
                        "binCatValue": null,
                        "binLowerBound": 50,
                        "lastTimestamp": "2019-01-08T00:00:00",
                        "variable": "interference",
                        "weekday": "Tuesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz and 5 GHz",
                        "binAvgFrequency": 0.0439311594202899,
                        "binCatValue": null,
                        "binLowerBound": 60,
                        "lastTimestamp": "2019-01-08T00:00:00",
                        "variable": "interference",
                        "weekday": "Tuesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz and 5 GHz",
                        "binAvgFrequency": 0.00866414618777568,
                        "binCatValue": null,
                        "binLowerBound": 70,
                        "lastTimestamp": "2019-01-08T00:00:00",
                        "variable": "interference",
                        "weekday": "Tuesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz and 5 GHz",
                        "binAvgFrequency": 0.00124054820415879,
                        "binCatValue": null,
                        "binLowerBound": 80,
                        "lastTimestamp": "2019-01-08T00:00:00",
                        "variable": "interference",
                        "weekday": "Tuesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz and 5 GHz",
                        "binAvgFrequency": 0.000511972274732199,
                        "binCatValue": null,
                        "binLowerBound": 90,
                        "lastTimestamp": "2019-01-08T00:00:00",
                        "variable": "interference",
                        "weekday": "Tuesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.920278615390699,
                        "binCatValue": null,
                        "binLowerBound": 0,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "interference",
                        "weekday": "Tuesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.0609811207161983,
                        "binCatValue": null,
                        "binLowerBound": 10,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "interference",
                        "weekday": "Tuesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.0125768693916046,
                        "binCatValue": null,
                        "binLowerBound": 20,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "interference",
                        "weekday": "Tuesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.00348696717240637,
                        "binCatValue": null,
                        "binLowerBound": 30,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "interference",
                        "weekday": "Tuesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.00146862622839124,
                        "binCatValue": null,
                        "binLowerBound": 40,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "interference",
                        "weekday": "Tuesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.000660039974000463,
                        "binCatValue": null,
                        "binLowerBound": 50,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "interference",
                        "weekday": "Tuesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.000284488761270751,
                        "binCatValue": null,
                        "binLowerBound": 60,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "interference",
                        "weekday": "Tuesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.00012667868824066,
                        "binCatValue": null,
                        "binLowerBound": 70,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "interference",
                        "weekday": "Tuesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.0000567065946282501,
                        "binCatValue": null,
                        "binLowerBound": 80,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "interference",
                        "weekday": "Tuesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.0000712534798959157,
                        "binCatValue": null,
                        "binLowerBound": 90,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "interference",
                        "weekday": "Tuesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.00000863360266427517,
                        "binCatValue": null,
                        "binLowerBound": 100,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "interference",
                        "weekday": "Tuesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0.991808650065531,
                        "binCatValue": null,
                        "binLowerBound": 0,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "interference",
                        "weekday": "Tuesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0.00622542595019659,
                        "binCatValue": null,
                        "binLowerBound": 10,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "interference",
                        "weekday": "Tuesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0.00163826998689384,
                        "binCatValue": null,
                        "binLowerBound": 20,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "interference",
                        "weekday": "Tuesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0.000327653997378768,
                        "binCatValue": null,
                        "binLowerBound": 30,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "interference",
                        "weekday": "Tuesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 40,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "interference",
                        "weekday": "Tuesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 50,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "interference",
                        "weekday": "Tuesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 60,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "interference",
                        "weekday": "Tuesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 70,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "interference",
                        "weekday": "Tuesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 80,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "interference",
                        "weekday": "Tuesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 90,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "interference",
                        "weekday": "Tuesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 100,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "interference",
                        "weekday": "Tuesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.23632152099633,
                        "binCatValue": null,
                        "binLowerBound": 0,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "interference",
                        "weekday": "Wednesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.223060959984386,
                        "binCatValue": null,
                        "binLowerBound": 10,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "interference",
                        "weekday": "Wednesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.169681162507042,
                        "binCatValue": null,
                        "binLowerBound": 20,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "interference",
                        "weekday": "Wednesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.12597194603777,
                        "binCatValue": null,
                        "binLowerBound": 30,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "interference",
                        "weekday": "Wednesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.095286613480898,
                        "binCatValue": null,
                        "binLowerBound": 40,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "interference",
                        "weekday": "Wednesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.0727557725904587,
                        "binCatValue": null,
                        "binLowerBound": 50,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "interference",
                        "weekday": "Wednesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.0491117167374838,
                        "binCatValue": null,
                        "binLowerBound": 60,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "interference",
                        "weekday": "Wednesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.022385068779001,
                        "binCatValue": null,
                        "binLowerBound": 70,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "interference",
                        "weekday": "Wednesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.00500031868705683,
                        "binCatValue": null,
                        "binLowerBound": 80,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "interference",
                        "weekday": "Wednesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.000421790237408001,
                        "binCatValue": null,
                        "binLowerBound": 90,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "interference",
                        "weekday": "Wednesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.00000312996216531426,
                        "binCatValue": null,
                        "binLowerBound": 100,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "interference",
                        "weekday": "Wednesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz and 5 GHz",
                        "binAvgFrequency": 0.0561791115311909,
                        "binCatValue": null,
                        "binLowerBound": 0,
                        "lastTimestamp": "2019-01-09T00:00:00",
                        "variable": "interference",
                        "weekday": "Wednesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz and 5 GHz",
                        "binAvgFrequency": 0.0524574669187146,
                        "binCatValue": null,
                        "binLowerBound": 10,
                        "lastTimestamp": "2019-01-09T00:00:00",
                        "variable": "interference",
                        "weekday": "Wednesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz and 5 GHz",
                        "binAvgFrequency": 0.210873503465658,
                        "binCatValue": null,
                        "binLowerBound": 20,
                        "lastTimestamp": "2019-01-09T00:00:00",
                        "variable": "interference",
                        "weekday": "Wednesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz and 5 GHz",
                        "binAvgFrequency": 0.274712507876497,
                        "binCatValue": null,
                        "binLowerBound": 30,
                        "lastTimestamp": "2019-01-09T00:00:00",
                        "variable": "interference",
                        "weekday": "Wednesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz and 5 GHz",
                        "binAvgFrequency": 0.231214555765595,
                        "binCatValue": null,
                        "binLowerBound": 40,
                        "lastTimestamp": "2019-01-09T00:00:00",
                        "variable": "interference",
                        "weekday": "Wednesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz and 5 GHz",
                        "binAvgFrequency": 0.127973377441714,
                        "binCatValue": null,
                        "binLowerBound": 50,
                        "lastTimestamp": "2019-01-09T00:00:00",
                        "variable": "interference",
                        "weekday": "Wednesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz and 5 GHz",
                        "binAvgFrequency": 0.0382403906742281,
                        "binCatValue": null,
                        "binLowerBound": 60,
                        "lastTimestamp": "2019-01-09T00:00:00",
                        "variable": "interference",
                        "weekday": "Wednesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz and 5 GHz",
                        "binAvgFrequency": 0.00639965343415249,
                        "binCatValue": null,
                        "binLowerBound": 70,
                        "lastTimestamp": "2019-01-09T00:00:00",
                        "variable": "interference",
                        "weekday": "Wednesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz and 5 GHz",
                        "binAvgFrequency": 0.00112240075614367,
                        "binCatValue": null,
                        "binLowerBound": 80,
                        "lastTimestamp": "2019-01-09T00:00:00",
                        "variable": "interference",
                        "weekday": "Wednesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz and 5 GHz",
                        "binAvgFrequency": 0.00082703213610586,
                        "binCatValue": null,
                        "binLowerBound": 90,
                        "lastTimestamp": "2019-01-09T00:00:00",
                        "variable": "interference",
                        "weekday": "Wednesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.920562723494097,
                        "binCatValue": null,
                        "binLowerBound": 0,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "interference",
                        "weekday": "Wednesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.061108383630994,
                        "binCatValue": null,
                        "binLowerBound": 10,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "interference",
                        "weekday": "Wednesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.0124772892660505,
                        "binCatValue": null,
                        "binLowerBound": 20,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "interference",
                        "weekday": "Wednesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.00329554519977593,
                        "binCatValue": null,
                        "binLowerBound": 30,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "interference",
                        "weekday": "Wednesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.00136415369171449,
                        "binCatValue": null,
                        "binLowerBound": 40,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "interference",
                        "weekday": "Wednesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.000649740089130974,
                        "binCatValue": null,
                        "binLowerBound": 50,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "interference",
                        "weekday": "Wednesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.000279298089688153,
                        "binCatValue": null,
                        "binLowerBound": 60,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "interference",
                        "weekday": "Wednesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.00012754788210943,
                        "binCatValue": null,
                        "binLowerBound": 70,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "interference",
                        "weekday": "Wednesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.0000553332723857083,
                        "binCatValue": null,
                        "binLowerBound": 80,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "interference",
                        "weekday": "Wednesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.0000721380503707142,
                        "binCatValue": null,
                        "binLowerBound": 90,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "interference",
                        "weekday": "Wednesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.0000078473336832032,
                        "binCatValue": null,
                        "binLowerBound": 100,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "interference",
                        "weekday": "Wednesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0.99375,
                        "binCatValue": null,
                        "binLowerBound": 0,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "interference",
                        "weekday": "Wednesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0.00543478260869565,
                        "binCatValue": null,
                        "binLowerBound": 10,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "interference",
                        "weekday": "Wednesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0.000271739130434783,
                        "binCatValue": null,
                        "binLowerBound": 20,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "interference",
                        "weekday": "Wednesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0.000271739130434783,
                        "binCatValue": null,
                        "binLowerBound": 30,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "interference",
                        "weekday": "Wednesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0.000271739130434783,
                        "binCatValue": null,
                        "binLowerBound": 40,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "interference",
                        "weekday": "Wednesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 50,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "interference",
                        "weekday": "Wednesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 60,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "interference",
                        "weekday": "Wednesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 70,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "interference",
                        "weekday": "Wednesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 80,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "interference",
                        "weekday": "Wednesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 90,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "interference",
                        "weekday": "Wednesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 100,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "interference",
                        "weekday": "Wednesday"
                    }],
                    "totalCount": 344
                },
                "logTotalBitsPerSec": {
                    "__typename": "SmartDashboardPeers36SConnection",
                    "nodes": [{
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.903429831262404,
                        "binCatValue": null,
                        "binLowerBound": 0,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "All"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.00118115784204351,
                        "binCatValue": null,
                        "binLowerBound": 1.13,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "All"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.00210523074984467,
                        "binCatValue": null,
                        "binLowerBound": 2.26,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "All"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.00260289428179432,
                        "binCatValue": null,
                        "binLowerBound": 3.39,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "All"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.00316814220173417,
                        "binCatValue": null,
                        "binLowerBound": 4.52,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "All"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.00745999696351077,
                        "binCatValue": null,
                        "binLowerBound": 5.65,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "All"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.00723044748199817,
                        "binCatValue": null,
                        "binLowerBound": 6.78,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "All"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.00788055116948248,
                        "binCatValue": null,
                        "binLowerBound": 7.91,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "All"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.00799807366434949,
                        "binCatValue": null,
                        "binLowerBound": 9.04,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "All"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.00947145965183466,
                        "binCatValue": null,
                        "binLowerBound": 10.17,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "All"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.00842520236351784,
                        "binCatValue": null,
                        "binLowerBound": 11.3,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "All"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.00704249988163553,
                        "binCatValue": null,
                        "binLowerBound": 12.43,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "All"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.00562826452104879,
                        "binCatValue": null,
                        "binLowerBound": 13.56,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "All"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.00486511886952685,
                        "binCatValue": null,
                        "binLowerBound": 14.69,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "All"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.0044070927955166,
                        "binCatValue": null,
                        "binLowerBound": 15.82,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "All"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.00414795961860937,
                        "binCatValue": null,
                        "binLowerBound": 16.95,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "All"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.00407654544355306,
                        "binCatValue": null,
                        "binLowerBound": 18.08,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "All"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.00393322179666511,
                        "binCatValue": null,
                        "binLowerBound": 19.21,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "All"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.00282928300788508,
                        "binCatValue": null,
                        "binLowerBound": 20.34,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "All"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.00146619427830695,
                        "binCatValue": null,
                        "binLowerBound": 21.47,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "All"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.000523179697826017,
                        "binCatValue": null,
                        "binLowerBound": 22.6,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "All"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.000097062928069476,
                        "binCatValue": null,
                        "binLowerBound": 23.73,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "All"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.0000256076052810706,
                        "binCatValue": null,
                        "binLowerBound": 24.86,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "All"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.00000451863058134716,
                        "binCatValue": null,
                        "binLowerBound": 25.99,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "All"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 4.00809390520844e-7,
                        "binCatValue": null,
                        "binLowerBound": 27.12,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "All"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 5.79116229649888e-8,
                        "binCatValue": null,
                        "binLowerBound": 28.25,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "All"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 4.57197023407806e-9,
                        "binCatValue": null,
                        "binLowerBound": 29.38,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "All"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 30.51,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "All"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 31.64,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "All"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 32.77,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "All"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 33.9,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "All"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz and 5 GHz",
                        "binAvgFrequency": 1,
                        "binCatValue": null,
                        "binLowerBound": 0,
                        "lastTimestamp": "2019-01-09T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "All"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.731358206677966,
                        "binCatValue": null,
                        "binLowerBound": 0,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "All"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.000654464873717604,
                        "binCatValue": null,
                        "binLowerBound": 1.13,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "All"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.00132231064896491,
                        "binCatValue": null,
                        "binLowerBound": 2.26,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "All"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.00157827011604836,
                        "binCatValue": null,
                        "binLowerBound": 3.39,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "All"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.00255640046311415,
                        "binCatValue": null,
                        "binLowerBound": 4.52,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "All"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.00656056820877134,
                        "binCatValue": null,
                        "binLowerBound": 5.65,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "All"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.00675143538015091,
                        "binCatValue": null,
                        "binLowerBound": 6.78,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "All"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.00872272564667894,
                        "binCatValue": null,
                        "binLowerBound": 7.91,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "All"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.0098440015839145,
                        "binCatValue": null,
                        "binLowerBound": 9.04,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "All"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.0127327219374199,
                        "binCatValue": null,
                        "binLowerBound": 10.17,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "All"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.0172847743943021,
                        "binCatValue": null,
                        "binLowerBound": 11.3,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "All"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.0263332237745624,
                        "binCatValue": null,
                        "binLowerBound": 12.43,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "All"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.0296525743064432,
                        "binCatValue": null,
                        "binLowerBound": 13.56,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "All"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.0306211352904353,
                        "binCatValue": null,
                        "binLowerBound": 14.69,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "All"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.0271919853415097,
                        "binCatValue": null,
                        "binLowerBound": 15.82,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "All"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.0229627320322656,
                        "binCatValue": null,
                        "binLowerBound": 16.95,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "All"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.0191322139711705,
                        "binCatValue": null,
                        "binLowerBound": 18.08,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "All"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.0174710638888426,
                        "binCatValue": null,
                        "binLowerBound": 19.21,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "All"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.0136924487565175,
                        "binCatValue": null,
                        "binLowerBound": 20.34,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "All"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.00834014560501225,
                        "binCatValue": null,
                        "binLowerBound": 21.47,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "All"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.00386747981805121,
                        "binCatValue": null,
                        "binLowerBound": 22.6,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "All"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.00106139338253688,
                        "binCatValue": null,
                        "binLowerBound": 23.73,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "All"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.00023449875229797,
                        "binCatValue": null,
                        "binLowerBound": 24.86,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "All"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.0000619363949026577,
                        "binCatValue": null,
                        "binLowerBound": 25.99,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "All"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.0000104165353242733,
                        "binCatValue": null,
                        "binLowerBound": 27.12,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "All"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 8.32438820115181e-7,
                        "binCatValue": null,
                        "binLowerBound": 28.25,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "All"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 3.8306919155743e-8,
                        "binCatValue": null,
                        "binLowerBound": 29.38,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "All"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 1.47334304445165e-9,
                        "binCatValue": null,
                        "binLowerBound": 30.51,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "All"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 31.64,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "All"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 32.77,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "All"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 33.9,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "All"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 1,
                        "binCatValue": null,
                        "binLowerBound": 0,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "All"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 1.13,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "All"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 2.26,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "All"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 3.39,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "All"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 4.52,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "All"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 5.65,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "All"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 6.78,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "All"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 7.91,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "All"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 9.04,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "All"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 10.17,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "All"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 11.3,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "All"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 12.43,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "All"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 13.56,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "All"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 14.69,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "All"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 15.82,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "All"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 16.95,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "All"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 18.08,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "All"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 19.21,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "All"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 20.34,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "All"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 21.47,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "All"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 22.6,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "All"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 23.73,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "All"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 24.86,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "All"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 25.99,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "All"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 27.12,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "All"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 28.25,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "All"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 29.38,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "All"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 30.51,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "All"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 31.64,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "All"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 32.77,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "All"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 33.9,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "All"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.900959353105738,
                        "binCatValue": null,
                        "binLowerBound": 0,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "Friday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.00119804524955865,
                        "binCatValue": null,
                        "binLowerBound": 1.13,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "Friday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.00212914734102338,
                        "binCatValue": null,
                        "binLowerBound": 2.26,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "Friday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.00262059150360591,
                        "binCatValue": null,
                        "binLowerBound": 3.39,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "Friday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.00320959914139475,
                        "binCatValue": null,
                        "binLowerBound": 4.52,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "Friday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.00752358881905462,
                        "binCatValue": null,
                        "binLowerBound": 5.65,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "Friday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.00729287463331658,
                        "binCatValue": null,
                        "binLowerBound": 6.78,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "Friday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.00793517561449846,
                        "binCatValue": null,
                        "binLowerBound": 7.91,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "Friday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.00809385968021175,
                        "binCatValue": null,
                        "binLowerBound": 9.04,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "Friday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.00983301564919503,
                        "binCatValue": null,
                        "binLowerBound": 10.17,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "Friday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.00872407461398667,
                        "binCatValue": null,
                        "binLowerBound": 11.3,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "Friday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.00731771763502597,
                        "binCatValue": null,
                        "binLowerBound": 12.43,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "Friday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.00587581231389323,
                        "binCatValue": null,
                        "binLowerBound": 13.56,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "Friday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.00503797600418841,
                        "binCatValue": null,
                        "binLowerBound": 14.69,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "Friday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.00459927095756354,
                        "binCatValue": null,
                        "binLowerBound": 15.82,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "Friday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.00433137491721228,
                        "binCatValue": null,
                        "binLowerBound": 16.95,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "Friday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.00423411286394451,
                        "binCatValue": null,
                        "binLowerBound": 18.08,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "Friday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.00407995985059779,
                        "binCatValue": null,
                        "binLowerBound": 19.21,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "Friday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.00289566333075229,
                        "binCatValue": null,
                        "binLowerBound": 20.34,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "Friday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.0014719016656625,
                        "binCatValue": null,
                        "binLowerBound": 21.47,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "Friday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.000513817449053495,
                        "binCatValue": null,
                        "binLowerBound": 22.6,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "Friday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.0000955896476732444,
                        "binCatValue": null,
                        "binLowerBound": 23.73,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "Friday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.0000227622180045838,
                        "binCatValue": null,
                        "binLowerBound": 24.86,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "Friday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.00000429769344640156,
                        "binCatValue": null,
                        "binLowerBound": 25.99,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "Friday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 3.88931533611001e-7,
                        "binCatValue": null,
                        "binLowerBound": 27.12,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "Friday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 2.91698650208251e-8,
                        "binCatValue": null,
                        "binLowerBound": 28.25,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "Friday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 29.38,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "Friday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 30.51,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "Friday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 31.64,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "Friday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 32.77,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "Friday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 33.9,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "Friday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz and 5 GHz",
                        "binAvgFrequency": 1,
                        "binCatValue": null,
                        "binLowerBound": 0,
                        "lastTimestamp": "2019-01-04T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "Friday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.72652288187763,
                        "binCatValue": null,
                        "binLowerBound": 0,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "Friday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.000667355640182056,
                        "binCatValue": null,
                        "binLowerBound": 1.13,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "Friday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.00133139628907479,
                        "binCatValue": null,
                        "binLowerBound": 2.26,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "Friday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.00161407207604316,
                        "binCatValue": null,
                        "binLowerBound": 3.39,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "Friday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.00259499959764645,
                        "binCatValue": null,
                        "binLowerBound": 4.52,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "Friday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.00659045623774351,
                        "binCatValue": null,
                        "binLowerBound": 5.65,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "Friday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.00685228420413882,
                        "binCatValue": null,
                        "binLowerBound": 6.78,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "Friday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.00881479661105716,
                        "binCatValue": null,
                        "binLowerBound": 7.91,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "Friday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.00993708550342008,
                        "binCatValue": null,
                        "binLowerBound": 9.04,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "Friday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.012948271457894,
                        "binCatValue": null,
                        "binLowerBound": 10.17,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "Friday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.0174647451850244,
                        "binCatValue": null,
                        "binLowerBound": 11.3,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "Friday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.0265154696322334,
                        "binCatValue": null,
                        "binLowerBound": 12.43,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "Friday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.0297262969459699,
                        "binCatValue": null,
                        "binLowerBound": 13.56,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "Friday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.0307837697763529,
                        "binCatValue": null,
                        "binLowerBound": 14.69,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "Friday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.0278384234930672,
                        "binCatValue": null,
                        "binLowerBound": 15.82,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "Friday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.024276441875417,
                        "binCatValue": null,
                        "binLowerBound": 16.95,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "Friday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.0202792385260287,
                        "binCatValue": null,
                        "binLowerBound": 18.08,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "Friday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.018196672723522,
                        "binCatValue": null,
                        "binLowerBound": 19.21,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "Friday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.0139207189876155,
                        "binCatValue": null,
                        "binLowerBound": 20.34,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "Friday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.00817584847413345,
                        "binCatValue": null,
                        "binLowerBound": 21.47,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "Friday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.00367689818537658,
                        "binCatValue": null,
                        "binLowerBound": 22.6,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "Friday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.000993332913935285,
                        "binCatValue": null,
                        "binLowerBound": 23.73,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "Friday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.000212694724396085,
                        "binCatValue": null,
                        "binLowerBound": 24.86,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "Friday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.0000557068791168517,
                        "binCatValue": null,
                        "binLowerBound": 25.99,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "Friday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.00000944725562905477,
                        "binCatValue": null,
                        "binLowerBound": 27.12,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "Friday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 6.47972801595208e-7,
                        "binCatValue": null,
                        "binLowerBound": 28.25,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "Friday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 4.69545508402324e-8,
                        "binCatValue": null,
                        "binLowerBound": 29.38,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "Friday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 30.51,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "Friday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 31.64,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "Friday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 32.77,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "Friday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 33.9,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "Friday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 1,
                        "binCatValue": null,
                        "binLowerBound": 0,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "Friday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 1.13,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "Friday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 2.26,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "Friday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 3.39,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "Friday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 4.52,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "Friday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 5.65,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "Friday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 6.78,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "Friday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 7.91,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "Friday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 9.04,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "Friday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 10.17,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "Friday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 11.3,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "Friday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 12.43,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "Friday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 13.56,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "Friday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 14.69,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "Friday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 15.82,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "Friday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 16.95,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "Friday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 18.08,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "Friday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 19.21,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "Friday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 20.34,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "Friday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 21.47,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "Friday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 22.6,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "Friday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 23.73,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "Friday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 24.86,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "Friday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 25.99,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "Friday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 27.12,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "Friday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 28.25,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "Friday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 29.38,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "Friday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 30.51,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "Friday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 31.64,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "Friday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 32.77,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "Friday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 33.9,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "Friday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.902724055186216,
                        "binCatValue": null,
                        "binLowerBound": 0,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "Monday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.00116333265313342,
                        "binCatValue": null,
                        "binLowerBound": 1.13,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "Monday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.00206457059785339,
                        "binCatValue": null,
                        "binLowerBound": 2.26,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "Monday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.00257215036076367,
                        "binCatValue": null,
                        "binLowerBound": 3.39,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "Monday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.00313846344477403,
                        "binCatValue": null,
                        "binLowerBound": 4.52,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "Monday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.00734704528906266,
                        "binCatValue": null,
                        "binLowerBound": 5.65,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "Monday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.00705244028503583,
                        "binCatValue": null,
                        "binLowerBound": 6.78,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "Monday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.00777213982150554,
                        "binCatValue": null,
                        "binLowerBound": 7.91,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "Monday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.00794415869803835,
                        "binCatValue": null,
                        "binLowerBound": 9.04,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "Monday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.00942208424381015,
                        "binCatValue": null,
                        "binLowerBound": 10.17,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "Monday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.00845282283257909,
                        "binCatValue": null,
                        "binLowerBound": 11.3,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "Monday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.00714151515848837,
                        "binCatValue": null,
                        "binLowerBound": 12.43,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "Monday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.00572917324305912,
                        "binCatValue": null,
                        "binLowerBound": 13.56,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "Monday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.00495966559814007,
                        "binCatValue": null,
                        "binLowerBound": 14.69,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "Monday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.00458828916800638,
                        "binCatValue": null,
                        "binLowerBound": 15.82,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "Monday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.00438736761686156,
                        "binCatValue": null,
                        "binLowerBound": 16.95,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "Monday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.00429986716760326,
                        "binCatValue": null,
                        "binLowerBound": 18.08,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "Monday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.00413010044753373,
                        "binCatValue": null,
                        "binLowerBound": 19.21,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "Monday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.00293256838143948,
                        "binCatValue": null,
                        "binLowerBound": 20.34,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "Monday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.00151631647215038,
                        "binCatValue": null,
                        "binLowerBound": 21.47,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "Monday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.000529986634389789,
                        "binCatValue": null,
                        "binLowerBound": 22.6,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "Monday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.0000989906178811066,
                        "binCatValue": null,
                        "binLowerBound": 23.73,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "Monday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.0000273908103195353,
                        "binCatValue": null,
                        "binLowerBound": 24.86,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "Monday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.00000512991194412308,
                        "binCatValue": null,
                        "binLowerBound": 25.99,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "Monday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 3.0237285849506e-7,
                        "binCatValue": null,
                        "binLowerBound": 27.12,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "Monday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 7.29865520505316e-8,
                        "binCatValue": null,
                        "binLowerBound": 28.25,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "Monday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 29.38,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "Monday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 30.51,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "Monday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 31.64,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "Monday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 32.77,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "Monday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 33.9,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "Monday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz and 5 GHz",
                        "binAvgFrequency": 1,
                        "binCatValue": null,
                        "binLowerBound": 0,
                        "lastTimestamp": "2019-01-07T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "Monday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.727559304186143,
                        "binCatValue": null,
                        "binLowerBound": 0,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "Monday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.000642168499657224,
                        "binCatValue": null,
                        "binLowerBound": 1.13,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "Monday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.00128669802516064,
                        "binCatValue": null,
                        "binLowerBound": 2.26,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "Monday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.00153832028398419,
                        "binCatValue": null,
                        "binLowerBound": 3.39,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "Monday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.00248977299265685,
                        "binCatValue": null,
                        "binLowerBound": 4.52,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "Monday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.00643092556324612,
                        "binCatValue": null,
                        "binLowerBound": 5.65,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "Monday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.00668719863591326,
                        "binCatValue": null,
                        "binLowerBound": 6.78,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "Monday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.00858446608636722,
                        "binCatValue": null,
                        "binLowerBound": 7.91,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "Monday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.00967649142440811,
                        "binCatValue": null,
                        "binLowerBound": 9.04,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "Monday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.0126861887681685,
                        "binCatValue": null,
                        "binLowerBound": 10.17,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "Monday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.0173362088185699,
                        "binCatValue": null,
                        "binLowerBound": 11.3,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "Monday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.0263394313338986,
                        "binCatValue": null,
                        "binLowerBound": 12.43,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "Monday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.0294926627661706,
                        "binCatValue": null,
                        "binLowerBound": 13.56,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "Monday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.0303814261678926,
                        "binCatValue": null,
                        "binLowerBound": 14.69,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "Monday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.0274244855395919,
                        "binCatValue": null,
                        "binLowerBound": 15.82,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "Monday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.02367225574404,
                        "binCatValue": null,
                        "binLowerBound": 16.95,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "Monday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.0202008659021584,
                        "binCatValue": null,
                        "binLowerBound": 18.08,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "Monday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.0185298803900235,
                        "binCatValue": null,
                        "binLowerBound": 19.21,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "Monday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.0145557955796141,
                        "binCatValue": null,
                        "binLowerBound": 20.34,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "Monday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.00890266520331913,
                        "binCatValue": null,
                        "binLowerBound": 21.47,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "Monday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.00408951047914862,
                        "binCatValue": null,
                        "binLowerBound": 22.6,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "Monday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.00115097974634685,
                        "binCatValue": null,
                        "binLowerBound": 23.73,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "Monday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.00026222652146032,
                        "binCatValue": null,
                        "binLowerBound": 24.86,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "Monday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.0000674825964917679,
                        "binCatValue": null,
                        "binLowerBound": 25.99,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "Monday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.0000117847755598654,
                        "binCatValue": null,
                        "binLowerBound": 27.12,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "Monday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 7.42908994706539e-7,
                        "binCatValue": null,
                        "binLowerBound": 28.25,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "Monday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 6.10610132635512e-8,
                        "binCatValue": null,
                        "binLowerBound": 29.38,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "Monday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 30.51,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "Monday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 31.64,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "Monday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 32.77,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "Monday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 33.9,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "Monday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 1,
                        "binCatValue": null,
                        "binLowerBound": 0,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "Monday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 1.13,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "Monday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 2.26,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "Monday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 3.39,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "Monday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 4.52,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "Monday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 5.65,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "Monday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 6.78,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "Monday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 7.91,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "Monday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 9.04,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "Monday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 10.17,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "Monday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 11.3,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "Monday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 12.43,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "Monday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 13.56,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "Monday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 14.69,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "Monday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 15.82,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "Monday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 16.95,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "Monday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 18.08,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "Monday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 19.21,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "Monday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 20.34,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "Monday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 21.47,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "Monday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 22.6,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "Monday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 23.73,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "Monday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 24.86,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "Monday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 25.99,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "Monday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 27.12,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "Monday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 28.25,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "Monday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 29.38,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "Monday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 30.51,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "Monday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 31.64,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "Monday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 32.77,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "Monday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 33.9,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "Monday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.919168432375482,
                        "binCatValue": null,
                        "binLowerBound": 0,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "Saturday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.0011144544111845,
                        "binCatValue": null,
                        "binLowerBound": 1.13,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "Saturday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.00202853819454895,
                        "binCatValue": null,
                        "binLowerBound": 2.26,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "Saturday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.00249587685475716,
                        "binCatValue": null,
                        "binLowerBound": 3.39,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "Saturday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.00300379425698209,
                        "binCatValue": null,
                        "binLowerBound": 4.52,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "Saturday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.00721536705092711,
                        "binCatValue": null,
                        "binLowerBound": 5.65,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "Saturday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.00707337635816869,
                        "binCatValue": null,
                        "binLowerBound": 6.78,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "Saturday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.00768324423462239,
                        "binCatValue": null,
                        "binLowerBound": 7.91,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "Saturday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.00752603526702557,
                        "binCatValue": null,
                        "binLowerBound": 9.04,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "Saturday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.0085489307127504,
                        "binCatValue": null,
                        "binLowerBound": 10.17,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "Saturday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.0072304058179365,
                        "binCatValue": null,
                        "binLowerBound": 11.3,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "Saturday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.0056421803826704,
                        "binCatValue": null,
                        "binLowerBound": 12.43,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "Saturday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.00416758340317684,
                        "binCatValue": null,
                        "binLowerBound": 13.56,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "Saturday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.00336491414569362,
                        "binCatValue": null,
                        "binLowerBound": 14.69,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "Saturday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.00280692403182801,
                        "binCatValue": null,
                        "binLowerBound": 15.82,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "Saturday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.00252904591249803,
                        "binCatValue": null,
                        "binLowerBound": 16.95,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "Saturday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.00250650770729829,
                        "binCatValue": null,
                        "binLowerBound": 18.08,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "Saturday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.00256014065754794,
                        "binCatValue": null,
                        "binLowerBound": 19.21,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "Saturday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.00188438343518508,
                        "binCatValue": null,
                        "binLowerBound": 20.34,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "Saturday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.00099287774764905,
                        "binCatValue": null,
                        "binLowerBound": 21.47,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "Saturday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.000376876687037015,
                        "binCatValue": null,
                        "binLowerBound": 22.6,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "Saturday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.0000630072479876126,
                        "binCatValue": null,
                        "binLowerBound": 23.73,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "Saturday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.0000146099427511637,
                        "binCatValue": null,
                        "binLowerBound": 24.86,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "Saturday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.00000223387520563868,
                        "binCatValue": null,
                        "binLowerBound": 25.99,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "Saturday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 2.09425800528627e-7,
                        "binCatValue": null,
                        "binLowerBound": 27.12,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "Saturday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 3.98906286721194e-8,
                        "binCatValue": null,
                        "binLowerBound": 28.25,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "Saturday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 9.97265716802984e-9,
                        "binCatValue": null,
                        "binLowerBound": 29.38,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "Saturday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 30.51,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "Saturday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 31.64,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "Saturday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 32.77,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "Saturday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 33.9,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "Saturday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz and 5 GHz",
                        "binAvgFrequency": 1,
                        "binCatValue": null,
                        "binLowerBound": 0,
                        "lastTimestamp": "2019-01-05T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "Saturday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.768334662267751,
                        "binCatValue": null,
                        "binLowerBound": 0,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "Saturday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.000673002883099263,
                        "binCatValue": null,
                        "binLowerBound": 1.13,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "Saturday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.00140303828147244,
                        "binCatValue": null,
                        "binLowerBound": 2.26,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "Saturday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.0016411538334578,
                        "binCatValue": null,
                        "binLowerBound": 3.39,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "Saturday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.00264386034315485,
                        "binCatValue": null,
                        "binLowerBound": 4.52,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "Saturday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.00669583812612893,
                        "binCatValue": null,
                        "binLowerBound": 5.65,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "Saturday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.00676380667421377,
                        "binCatValue": null,
                        "binLowerBound": 6.78,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "Saturday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.00885381792459751,
                        "binCatValue": null,
                        "binLowerBound": 7.91,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "Saturday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.00993904875563789,
                        "binCatValue": null,
                        "binLowerBound": 9.04,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "Saturday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.0124587433601526,
                        "binCatValue": null,
                        "binLowerBound": 10.17,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "Saturday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.016949103396052,
                        "binCatValue": null,
                        "binLowerBound": 11.3,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "Saturday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.0262473140709757,
                        "binCatValue": null,
                        "binLowerBound": 12.43,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "Saturday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.0292174599303853,
                        "binCatValue": null,
                        "binLowerBound": 13.56,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "Saturday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.0293639009948564,
                        "binCatValue": null,
                        "binLowerBound": 14.69,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "Saturday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.0236364442534912,
                        "binCatValue": null,
                        "binLowerBound": 15.82,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "Saturday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.016916064863214,
                        "binCatValue": null,
                        "binLowerBound": 16.95,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "Saturday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.0119131322973655,
                        "binCatValue": null,
                        "binLowerBound": 18.08,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "Saturday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.0105246881948857,
                        "binCatValue": null,
                        "binLowerBound": 19.21,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "Saturday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.00803185840143774,
                        "binCatValue": null,
                        "binLowerBound": 20.34,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "Saturday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.00472229226467995,
                        "binCatValue": null,
                        "binLowerBound": 21.47,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "Saturday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.00232311485405715,
                        "binCatValue": null,
                        "binLowerBound": 22.6,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "Saturday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.000612840300488768,
                        "binCatValue": null,
                        "binLowerBound": 23.73,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "Saturday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.000103637873714918,
                        "binCatValue": null,
                        "binLowerBound": 24.86,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "Saturday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.0000266535795286837,
                        "binCatValue": null,
                        "binLowerBound": 25.99,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "Saturday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.00000424383362812615,
                        "binCatValue": null,
                        "binLowerBound": 27.12,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "Saturday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 2.68840139338308e-7,
                        "binCatValue": null,
                        "binLowerBound": 28.25,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "Saturday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 9.60143354779671e-9,
                        "binCatValue": null,
                        "binLowerBound": 29.38,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "Saturday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 30.51,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "Saturday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 31.64,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "Saturday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 32.77,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "Saturday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 33.9,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "Saturday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 1,
                        "binCatValue": null,
                        "binLowerBound": 0,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "Saturday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 1.13,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "Saturday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 2.26,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "Saturday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 3.39,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "Saturday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 4.52,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "Saturday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 5.65,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "Saturday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 6.78,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "Saturday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 7.91,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "Saturday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 9.04,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "Saturday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 10.17,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "Saturday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 11.3,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "Saturday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 12.43,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "Saturday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 13.56,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "Saturday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 14.69,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "Saturday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 15.82,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "Saturday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 16.95,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "Saturday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 18.08,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "Saturday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 19.21,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "Saturday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 20.34,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "Saturday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 21.47,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "Saturday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 22.6,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "Saturday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 23.73,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "Saturday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 24.86,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "Saturday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 25.99,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "Saturday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 27.12,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "Saturday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 28.25,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "Saturday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 29.38,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "Saturday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 30.51,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "Saturday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 31.64,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "Saturday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 32.77,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "Saturday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 33.9,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "Saturday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.916939729783787,
                        "binCatValue": null,
                        "binLowerBound": 0,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "Sunday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.00125563576577214,
                        "binCatValue": null,
                        "binLowerBound": 1.13,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "Sunday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.00227528804524151,
                        "binCatValue": null,
                        "binLowerBound": 2.26,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "Sunday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.00278743400990323,
                        "binCatValue": null,
                        "binLowerBound": 3.39,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "Sunday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.00313339786015592,
                        "binCatValue": null,
                        "binLowerBound": 4.52,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "Sunday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.00718448840703653,
                        "binCatValue": null,
                        "binLowerBound": 5.65,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "Sunday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.00724655076783764,
                        "binCatValue": null,
                        "binLowerBound": 6.78,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "Sunday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.00778860087244953,
                        "binCatValue": null,
                        "binLowerBound": 7.91,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "Sunday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.00755748244409091,
                        "binCatValue": null,
                        "binLowerBound": 9.04,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "Sunday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.00857144316928584,
                        "binCatValue": null,
                        "binLowerBound": 10.17,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "Sunday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.00753743990808159,
                        "binCatValue": null,
                        "binLowerBound": 11.3,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "Sunday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.00588752782475032,
                        "binCatValue": null,
                        "binLowerBound": 12.43,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "Sunday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.00425376998787455,
                        "binCatValue": null,
                        "binLowerBound": 13.56,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "Sunday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.00348531624078814,
                        "binCatValue": null,
                        "binLowerBound": 14.69,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "Sunday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.00282090711127962,
                        "binCatValue": null,
                        "binLowerBound": 15.82,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "Sunday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.00254476341692818,
                        "binCatValue": null,
                        "binLowerBound": 16.95,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "Sunday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.00253054392324584,
                        "binCatValue": null,
                        "binLowerBound": 18.08,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "Sunday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.00261593602136989,
                        "binCatValue": null,
                        "binLowerBound": 19.21,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "Sunday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.00198344092061473,
                        "binCatValue": null,
                        "binLowerBound": 20.34,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "Sunday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.00108460737742646,
                        "binCatValue": null,
                        "binLowerBound": 21.47,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "Sunday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.0004253638499822,
                        "binCatValue": null,
                        "binLowerBound": 22.6,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "Sunday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.0000695759638036683,
                        "binCatValue": null,
                        "binLowerBound": 23.73,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "Sunday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.0000173752069434107,
                        "binCatValue": null,
                        "binLowerBound": 24.86,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "Sunday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.00000300544120102239,
                        "binCatValue": null,
                        "binLowerBound": 25.99,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "Sunday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 2.81760112595849e-7,
                        "binCatValue": null,
                        "binLowerBound": 27.12,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "Sunday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 9.39200375319497e-8,
                        "binCatValue": null,
                        "binLowerBound": 28.25,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "Sunday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 29.38,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "Sunday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 30.51,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "Sunday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 31.64,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "Sunday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 32.77,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "Sunday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 33.9,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "Sunday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz and 5 GHz",
                        "binAvgFrequency": 1,
                        "binCatValue": null,
                        "binLowerBound": 0,
                        "lastTimestamp": "2019-01-06T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "Sunday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.77196602831366,
                        "binCatValue": null,
                        "binLowerBound": 0,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "Sunday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.000648985532408656,
                        "binCatValue": null,
                        "binLowerBound": 1.13,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "Sunday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.00142089369328077,
                        "binCatValue": null,
                        "binLowerBound": 2.26,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "Sunday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.00157787544448286,
                        "binCatValue": null,
                        "binLowerBound": 3.39,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "Sunday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.002556035349645,
                        "binCatValue": null,
                        "binLowerBound": 4.52,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "Sunday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.00678227299411956,
                        "binCatValue": null,
                        "binLowerBound": 5.65,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "Sunday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.00658785579426605,
                        "binCatValue": null,
                        "binLowerBound": 6.78,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "Sunday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.00875072321249126,
                        "binCatValue": null,
                        "binLowerBound": 7.91,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "Sunday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.00968330271783098,
                        "binCatValue": null,
                        "binLowerBound": 9.04,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "Sunday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.0123360506607609,
                        "binCatValue": null,
                        "binLowerBound": 10.17,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "Sunday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.0167287899032122,
                        "binCatValue": null,
                        "binLowerBound": 11.3,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "Sunday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.0258986822374,
                        "binCatValue": null,
                        "binLowerBound": 12.43,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "Sunday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.0290890317651007,
                        "binCatValue": null,
                        "binLowerBound": 13.56,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "Sunday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.0290578964709876,
                        "binCatValue": null,
                        "binLowerBound": 14.69,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "Sunday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.0231057471541228,
                        "binCatValue": null,
                        "binLowerBound": 15.82,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "Sunday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.0166675809432369,
                        "binCatValue": null,
                        "binLowerBound": 16.95,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "Sunday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.011190049069502,
                        "binCatValue": null,
                        "binLowerBound": 18.08,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "Sunday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.00998377832032589,
                        "binCatValue": null,
                        "binLowerBound": 19.21,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "Sunday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.00791570908378558,
                        "binCatValue": null,
                        "binLowerBound": 20.34,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "Sunday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.00482073206124975,
                        "binCatValue": null,
                        "binLowerBound": 21.47,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "Sunday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.00245425826749092,
                        "binCatValue": null,
                        "binLowerBound": 22.6,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "Sunday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.000629893627633856,
                        "binCatValue": null,
                        "binLowerBound": 23.73,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "Sunday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.000111958271117857,
                        "binCatValue": null,
                        "binLowerBound": 24.86,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "Sunday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.0000307176043095006,
                        "binCatValue": null,
                        "binLowerBound": 25.99,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "Sunday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.00000483824022551908,
                        "binCatValue": null,
                        "binLowerBound": 27.12,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "Sunday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 3.13267352731451e-7,
                        "binCatValue": null,
                        "binLowerBound": 28.25,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "Sunday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 29.38,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "Sunday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 30.51,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "Sunday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 31.64,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "Sunday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 32.77,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "Sunday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 33.9,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "Sunday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 1,
                        "binCatValue": null,
                        "binLowerBound": 0,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "Sunday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 1.13,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "Sunday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 2.26,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "Sunday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 3.39,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "Sunday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 4.52,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "Sunday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 5.65,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "Sunday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 6.78,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "Sunday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 7.91,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "Sunday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 9.04,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "Sunday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 10.17,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "Sunday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 11.3,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "Sunday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 12.43,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "Sunday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 13.56,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "Sunday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 14.69,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "Sunday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 15.82,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "Sunday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 16.95,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "Sunday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 18.08,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "Sunday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 19.21,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "Sunday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 20.34,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "Sunday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 21.47,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "Sunday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 22.6,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "Sunday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 23.73,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "Sunday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 24.86,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "Sunday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 25.99,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "Sunday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 27.12,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "Sunday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 28.25,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "Sunday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 29.38,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "Sunday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 30.51,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "Sunday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 31.64,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "Sunday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 32.77,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "Sunday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 33.9,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "Sunday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.896886170406482,
                        "binCatValue": null,
                        "binLowerBound": 0,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "Thursday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.00120556784339175,
                        "binCatValue": null,
                        "binLowerBound": 1.13,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "Thursday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.00210591962064721,
                        "binCatValue": null,
                        "binLowerBound": 2.26,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "Thursday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.00261992365304952,
                        "binCatValue": null,
                        "binLowerBound": 3.39,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "Thursday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.00323228107432821,
                        "binCatValue": null,
                        "binLowerBound": 4.52,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "Thursday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.00759131828658366,
                        "binCatValue": null,
                        "binLowerBound": 5.65,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "Thursday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.00733789249471133,
                        "binCatValue": null,
                        "binLowerBound": 6.78,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "Thursday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.00795370580975596,
                        "binCatValue": null,
                        "binLowerBound": 7.91,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "Thursday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.00819403963638685,
                        "binCatValue": null,
                        "binLowerBound": 9.04,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "Thursday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.00982419146429279,
                        "binCatValue": null,
                        "binLowerBound": 10.17,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "Thursday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.00890583823483035,
                        "binCatValue": null,
                        "binLowerBound": 11.3,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "Thursday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.00762760035790353,
                        "binCatValue": null,
                        "binLowerBound": 12.43,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "Thursday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.00626931859054829,
                        "binCatValue": null,
                        "binLowerBound": 13.56,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "Thursday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.00553219281522959,
                        "binCatValue": null,
                        "binLowerBound": 14.69,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "Thursday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.00508836556709947,
                        "binCatValue": null,
                        "binLowerBound": 15.82,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "Thursday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.00480787983824646,
                        "binCatValue": null,
                        "binLowerBound": 16.95,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "Thursday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.00469493275870388,
                        "binCatValue": null,
                        "binLowerBound": 18.08,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "Thursday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.00449058258359691,
                        "binCatValue": null,
                        "binLowerBound": 19.21,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "Thursday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.00323424486911442,
                        "binCatValue": null,
                        "binLowerBound": 20.34,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "Thursday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.00166719438838324,
                        "binCatValue": null,
                        "binLowerBound": 21.47,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "Thursday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.000585432254528443,
                        "binCatValue": null,
                        "binLowerBound": 22.6,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "Thursday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.00011004951958841,
                        "binCatValue": null,
                        "binLowerBound": 23.73,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "Thursday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.0000298901118196303,
                        "binCatValue": null,
                        "binLowerBound": 24.86,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "Thursday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.00000490948696554314,
                        "binCatValue": null,
                        "binLowerBound": 25.99,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "Thursday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 4.81322251523837e-7,
                        "binCatValue": null,
                        "binLowerBound": 27.12,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "Thursday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 6.73851152133372e-8,
                        "binCatValue": null,
                        "binLowerBound": 28.25,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "Thursday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 9.62644503047675e-9,
                        "binCatValue": null,
                        "binLowerBound": 29.38,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "Thursday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 30.51,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "Thursday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 31.64,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "Thursday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 32.77,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "Thursday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 33.9,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "Thursday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz and 5 GHz",
                        "binAvgFrequency": 1,
                        "binCatValue": null,
                        "binLowerBound": 0,
                        "lastTimestamp": "2019-01-03T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "Thursday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.7164547725486,
                        "binCatValue": null,
                        "binLowerBound": 0,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "Thursday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.000647916676357986,
                        "binCatValue": null,
                        "binLowerBound": 1.13,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "Thursday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.00128546906763301,
                        "binCatValue": null,
                        "binLowerBound": 2.26,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "Thursday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.0015601691406668,
                        "binCatValue": null,
                        "binLowerBound": 3.39,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "Thursday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.00253367694876936,
                        "binCatValue": null,
                        "binLowerBound": 4.52,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "Thursday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.00651885620717406,
                        "binCatValue": null,
                        "binLowerBound": 5.65,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "Thursday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.00676828752052622,
                        "binCatValue": null,
                        "binLowerBound": 6.78,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "Thursday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.00870858588912819,
                        "binCatValue": null,
                        "binLowerBound": 7.91,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "Thursday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.00986946955142369,
                        "binCatValue": null,
                        "binLowerBound": 9.04,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "Thursday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.0127989617107583,
                        "binCatValue": null,
                        "binLowerBound": 10.17,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "Thursday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.0172949960226824,
                        "binCatValue": null,
                        "binLowerBound": 11.3,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "Thursday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.0260563057929283,
                        "binCatValue": null,
                        "binLowerBound": 12.43,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "Thursday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.0295993468825738,
                        "binCatValue": null,
                        "binLowerBound": 13.56,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "Thursday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.0310832910791789,
                        "binCatValue": null,
                        "binLowerBound": 14.69,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "Thursday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.0286760137508199,
                        "binCatValue": null,
                        "binLowerBound": 15.82,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "Thursday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.0255283599030558,
                        "binCatValue": null,
                        "binLowerBound": 16.95,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "Thursday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.0222366666821728,
                        "binCatValue": null,
                        "binLowerBound": 18.08,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "Thursday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.0203924751940977,
                        "binCatValue": null,
                        "binLowerBound": 19.21,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "Thursday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.0160231382199294,
                        "binCatValue": null,
                        "binLowerBound": 20.34,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "Thursday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.00986826007470845,
                        "binCatValue": null,
                        "binLowerBound": 21.47,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "Thursday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.00451401830031307,
                        "binCatValue": null,
                        "binLowerBound": 22.6,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "Thursday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.00121867804195024,
                        "binCatValue": null,
                        "binLowerBound": 23.73,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "Thursday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.000274653554698584,
                        "binCatValue": null,
                        "binLowerBound": 24.86,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "Thursday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.000073443147616633,
                        "binCatValue": null,
                        "binLowerBound": 25.99,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "Thursday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.0000128855788509041,
                        "binCatValue": null,
                        "binLowerBound": 27.12,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "Thursday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.00000124669138340877,
                        "binCatValue": null,
                        "binLowerBound": 28.25,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "Thursday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 5.58220022421837e-8,
                        "binCatValue": null,
                        "binLowerBound": 29.38,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "Thursday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 30.51,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "Thursday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 31.64,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "Thursday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 32.77,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "Thursday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 33.9,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "Thursday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 1,
                        "binCatValue": null,
                        "binLowerBound": 0,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "Thursday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 1.13,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "Thursday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 2.26,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "Thursday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 3.39,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "Thursday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 4.52,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "Thursday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 5.65,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "Thursday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 6.78,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "Thursday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 7.91,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "Thursday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 9.04,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "Thursday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 10.17,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "Thursday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 11.3,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "Thursday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 12.43,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "Thursday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 13.56,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "Thursday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 14.69,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "Thursday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 15.82,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "Thursday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 16.95,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "Thursday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 18.08,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "Thursday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 19.21,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "Thursday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 20.34,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "Thursday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 21.47,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "Thursday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 22.6,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "Thursday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 23.73,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "Thursday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 24.86,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "Thursday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 25.99,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "Thursday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 27.12,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "Thursday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 28.25,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "Thursday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 29.38,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "Thursday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 30.51,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "Thursday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 31.64,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "Thursday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 32.77,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "Thursday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 33.9,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "Thursday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.897016444566091,
                        "binCatValue": null,
                        "binLowerBound": 0,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "Tuesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.00118960486132454,
                        "binCatValue": null,
                        "binLowerBound": 1.13,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "Tuesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.00211957266185232,
                        "binCatValue": null,
                        "binLowerBound": 2.26,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "Tuesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.00259624509353628,
                        "binCatValue": null,
                        "binLowerBound": 3.39,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "Tuesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.00322930850669038,
                        "binCatValue": null,
                        "binLowerBound": 4.52,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "Tuesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.0076404608990331,
                        "binCatValue": null,
                        "binLowerBound": 5.65,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "Tuesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.00731349580534954,
                        "binCatValue": null,
                        "binLowerBound": 6.78,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "Tuesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.00801320738155391,
                        "binCatValue": null,
                        "binLowerBound": 7.91,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "Tuesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.00822601709991877,
                        "binCatValue": null,
                        "binLowerBound": 9.04,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "Tuesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.00982342880825636,
                        "binCatValue": null,
                        "binLowerBound": 10.17,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "Tuesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.00886353627538603,
                        "binCatValue": null,
                        "binLowerBound": 11.3,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "Tuesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.00754775803194042,
                        "binCatValue": null,
                        "binLowerBound": 12.43,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "Tuesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.00618720251510838,
                        "binCatValue": null,
                        "binLowerBound": 13.56,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "Tuesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.00544499384435391,
                        "binCatValue": null,
                        "binLowerBound": 14.69,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "Tuesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.00505094128711034,
                        "binCatValue": null,
                        "binLowerBound": 15.82,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "Tuesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.00480950381886025,
                        "binCatValue": null,
                        "binLowerBound": 16.95,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "Tuesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.00476674523591149,
                        "binCatValue": null,
                        "binLowerBound": 18.08,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "Tuesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.00451733760127027,
                        "binCatValue": null,
                        "binLowerBound": 19.21,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "Tuesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.00323554239011442,
                        "binCatValue": null,
                        "binLowerBound": 20.34,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "Tuesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.00166795081875992,
                        "binCatValue": null,
                        "binLowerBound": 21.47,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "Tuesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.000587930515545506,
                        "binCatValue": null,
                        "binLowerBound": 22.6,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "Tuesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.00011551511499172,
                        "binCatValue": null,
                        "binLowerBound": 23.73,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "Tuesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.0000310543622247751,
                        "binCatValue": null,
                        "binLowerBound": 24.86,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "Tuesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.00000562723033914752,
                        "binCatValue": null,
                        "binLowerBound": 25.99,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "Tuesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 5.12517261372172e-7,
                        "binCatValue": null,
                        "binLowerBound": 27.12,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "Tuesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 5.22976797318543e-8,
                        "binCatValue": null,
                        "binLowerBound": 28.25,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "Tuesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 1.04595359463709e-8,
                        "binCatValue": null,
                        "binLowerBound": 29.38,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "Tuesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 30.51,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "Tuesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 31.64,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "Tuesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 32.77,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "Tuesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 33.9,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "Tuesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz and 5 GHz",
                        "binAvgFrequency": 1,
                        "binCatValue": null,
                        "binLowerBound": 0,
                        "lastTimestamp": "2019-01-08T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "Tuesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.713224617208255,
                        "binCatValue": null,
                        "binLowerBound": 0,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "Tuesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.000654762320960132,
                        "binCatValue": null,
                        "binLowerBound": 1.13,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "Tuesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.00128772926086826,
                        "binCatValue": null,
                        "binLowerBound": 2.26,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "Tuesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.00155777085851494,
                        "binCatValue": null,
                        "binLowerBound": 3.39,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "Tuesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.00254913821892548,
                        "binCatValue": null,
                        "binLowerBound": 4.52,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "Tuesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.0064839057593112,
                        "binCatValue": null,
                        "binLowerBound": 5.65,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "Tuesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.00675183845677154,
                        "binCatValue": null,
                        "binLowerBound": 6.78,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "Tuesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.0086459686576278,
                        "binCatValue": null,
                        "binLowerBound": 7.91,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "Tuesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.00984308687214036,
                        "binCatValue": null,
                        "binLowerBound": 9.04,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "Tuesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.0128812749493257,
                        "binCatValue": null,
                        "binLowerBound": 10.17,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "Tuesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.0175751134708589,
                        "binCatValue": null,
                        "binLowerBound": 11.3,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "Tuesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.0267133772360625,
                        "binCatValue": null,
                        "binLowerBound": 12.43,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "Tuesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.0301809107739994,
                        "binCatValue": null,
                        "binLowerBound": 13.56,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "Tuesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.0314250799849023,
                        "binCatValue": null,
                        "binLowerBound": 14.69,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "Tuesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.0287792497161712,
                        "binCatValue": null,
                        "binLowerBound": 15.82,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "Tuesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.0252776154669688,
                        "binCatValue": null,
                        "binLowerBound": 16.95,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "Tuesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.0222472896972743,
                        "binCatValue": null,
                        "binLowerBound": 18.08,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "Tuesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.0206957636402819,
                        "binCatValue": null,
                        "binLowerBound": 19.21,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "Tuesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.0164727626787975,
                        "binCatValue": null,
                        "binLowerBound": 20.34,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "Tuesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.0102348099630628,
                        "binCatValue": null,
                        "binLowerBound": 21.47,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "Tuesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.00474281414527806,
                        "binCatValue": null,
                        "binLowerBound": 22.6,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "Tuesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.00135250116125156,
                        "binCatValue": null,
                        "binLowerBound": 23.73,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "Tuesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.000321105646818779,
                        "binCatValue": null,
                        "binLowerBound": 24.86,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "Tuesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.0000869153523398405,
                        "binCatValue": null,
                        "binLowerBound": 25.99,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "Tuesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.0000135747652771058,
                        "binCatValue": null,
                        "binLowerBound": 27.12,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "Tuesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 9.72551056806226e-7,
                        "binCatValue": null,
                        "binLowerBound": 28.25,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "Tuesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 4.09495181813148e-8,
                        "binCatValue": null,
                        "binLowerBound": 29.38,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "Tuesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 1.02373795453287e-8,
                        "binCatValue": null,
                        "binLowerBound": 30.51,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "Tuesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 31.64,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "Tuesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 32.77,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "Tuesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 33.9,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "Tuesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 1,
                        "binCatValue": null,
                        "binLowerBound": 0,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "Tuesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 1.13,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "Tuesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 2.26,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "Tuesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 3.39,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "Tuesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 4.52,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "Tuesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 5.65,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "Tuesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 6.78,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "Tuesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 7.91,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "Tuesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 9.04,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "Tuesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 10.17,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "Tuesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 11.3,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "Tuesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 12.43,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "Tuesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 13.56,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "Tuesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 14.69,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "Tuesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 15.82,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "Tuesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 16.95,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "Tuesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 18.08,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "Tuesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 19.21,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "Tuesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 20.34,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "Tuesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 21.47,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "Tuesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 22.6,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "Tuesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 23.73,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "Tuesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 24.86,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "Tuesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 25.99,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "Tuesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 27.12,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "Tuesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 28.25,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "Tuesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 29.38,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "Tuesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 30.51,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "Tuesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 31.64,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "Tuesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 32.77,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "Tuesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 33.9,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "Tuesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.896891733963361,
                        "binCatValue": null,
                        "binLowerBound": 0,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "Wednesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.00117496300456265,
                        "binCatValue": null,
                        "binLowerBound": 1.13,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "Wednesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.00209215048386804,
                        "binCatValue": null,
                        "binLowerBound": 2.26,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "Wednesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.00261153220682386,
                        "binCatValue": null,
                        "binLowerBound": 3.39,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "Wednesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.00321029503944487,
                        "binCatValue": null,
                        "binLowerBound": 4.52,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "Wednesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.00758061346093124,
                        "binCatValue": null,
                        "binLowerBound": 5.65,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "Wednesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.00729215424941904,
                        "binCatValue": null,
                        "binLowerBound": 6.78,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "Wednesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.00796844031557118,
                        "binCatValue": null,
                        "binLowerBound": 7.91,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "Wednesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.00822751826945847,
                        "binCatValue": null,
                        "binLowerBound": 9.04,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "Wednesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.00983229229861789,
                        "binCatValue": null,
                        "binLowerBound": 10.17,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "Wednesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.00882594591824085,
                        "binCatValue": null,
                        "binLowerBound": 11.3,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "Wednesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.00756936086063488,
                        "binCatValue": null,
                        "binLowerBound": 12.43,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "Wednesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.00624571030526256,
                        "binCatValue": null,
                        "binLowerBound": 13.56,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "Wednesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.00555757788458565,
                        "binCatValue": null,
                        "binLowerBound": 14.69,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "Wednesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.00512945235586307,
                        "binCatValue": null,
                        "binLowerBound": 15.82,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "Wednesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.00485719688877763,
                        "binCatValue": null,
                        "binLowerBound": 16.95,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "Wednesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.00476500217979627,
                        "binCatValue": null,
                        "binLowerBound": 18.08,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "Wednesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.00450873019347222,
                        "binCatValue": null,
                        "binLowerBound": 19.21,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "Wednesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.0032328193933998,
                        "binCatValue": null,
                        "binLowerBound": 20.34,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "Wednesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.00167891669426108,
                        "binCatValue": null,
                        "binLowerBound": 21.47,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "Wednesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.000595296074061609,
                        "binCatValue": null,
                        "binLowerBound": 22.6,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "Wednesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.000113646474652742,
                        "binCatValue": null,
                        "binLowerBound": 23.73,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "Wednesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.0000322829919991905,
                        "binCatValue": null,
                        "binLowerBound": 24.86,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "Wednesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.0000057364319808707,
                        "binCatValue": null,
                        "binLowerBound": 25.99,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "Wednesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 5.65024185094108e-7,
                        "binCatValue": null,
                        "binLowerBound": 27.12,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "Wednesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 6.70367677230298e-8,
                        "binCatValue": null,
                        "binLowerBound": 28.25,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "Wednesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 29.38,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "Wednesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 30.51,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "Wednesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 31.64,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "Wednesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 32.77,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "Wednesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 33.9,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "Wednesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz and 5 GHz",
                        "binAvgFrequency": 1,
                        "binCatValue": null,
                        "binLowerBound": 0,
                        "lastTimestamp": "2019-01-09T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "Wednesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.713420175984087,
                        "binCatValue": null,
                        "binLowerBound": 0,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "Wednesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.000644152268805667,
                        "binCatValue": null,
                        "binLowerBound": 1.13,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "Wednesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.00128310885854326,
                        "binCatValue": null,
                        "binLowerBound": 2.26,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "Wednesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.00155527102760812,
                        "binCatValue": null,
                        "binLowerBound": 3.39,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "Wednesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.0025237567994602,
                        "binCatValue": null,
                        "binLowerBound": 4.52,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "Wednesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.00651113732519316,
                        "binCatValue": null,
                        "binLowerBound": 5.65,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "Wednesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.00676853331837873,
                        "binCatValue": null,
                        "binLowerBound": 6.78,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "Wednesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.00869974950483872,
                        "binCatValue": null,
                        "binLowerBound": 7.91,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "Wednesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.00987416886158456,
                        "binCatValue": null,
                        "binLowerBound": 9.04,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "Wednesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.0128382804499954,
                        "binCatValue": null,
                        "binLowerBound": 10.17,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "Wednesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.0174081740214069,
                        "binCatValue": null,
                        "binLowerBound": 11.3,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "Wednesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.0263941336175305,
                        "binCatValue": null,
                        "binLowerBound": 12.43,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "Wednesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.030022648496752,
                        "binCatValue": null,
                        "binLowerBound": 13.56,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "Wednesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.0315426971111446,
                        "binCatValue": null,
                        "binLowerBound": 14.69,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "Wednesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.0290469988442459,
                        "binCatValue": null,
                        "binLowerBound": 15.82,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "Wednesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.0255744009769146,
                        "binCatValue": null,
                        "binLowerBound": 16.95,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "Wednesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.0223327169970699,
                        "binCatValue": null,
                        "binLowerBound": 18.08,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "Wednesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.0206740640823676,
                        "binCatValue": null,
                        "binLowerBound": 19.21,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "Wednesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.0163998205074777,
                        "binCatValue": null,
                        "binLowerBound": 20.34,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "Wednesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.0101304081865395,
                        "binCatValue": null,
                        "binLowerBound": 21.47,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "Wednesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.00466530004449628,
                        "binCatValue": null,
                        "binLowerBound": 22.6,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "Wednesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.00129099099285659,
                        "binCatValue": null,
                        "binLowerBound": 23.73,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "Wednesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.000304380976068665,
                        "binCatValue": null,
                        "binLowerBound": 24.86,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "Wednesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.00007975414009338,
                        "binCatValue": null,
                        "binLowerBound": 25.99,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "Wednesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.0000137587551623082,
                        "binCatValue": null,
                        "binLowerBound": 27.12,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "Wednesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.00000138053950103161,
                        "binCatValue": null,
                        "binLowerBound": 28.25,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "Wednesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 3.73118784062597e-8,
                        "binCatValue": null,
                        "binLowerBound": 29.38,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "Wednesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 30.51,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "Wednesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 31.64,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "Wednesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 32.77,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "Wednesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 33.9,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "Wednesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 1,
                        "binCatValue": null,
                        "binLowerBound": 0,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "Wednesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 1.13,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "Wednesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 2.26,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "Wednesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 3.39,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "Wednesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 4.52,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "Wednesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 5.65,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "Wednesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 6.78,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "Wednesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 7.91,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "Wednesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 9.04,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "Wednesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 10.17,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "Wednesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 11.3,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "Wednesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 12.43,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "Wednesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 13.56,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "Wednesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 14.69,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "Wednesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 15.82,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "Wednesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 16.95,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "Wednesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 18.08,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "Wednesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 19.21,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "Wednesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 20.34,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "Wednesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 21.47,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "Wednesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 22.6,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "Wednesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 23.73,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "Wednesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 24.86,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "Wednesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 25.99,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "Wednesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 27.12,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "Wednesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 28.25,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "Wednesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 29.38,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "Wednesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 30.51,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "Wednesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 31.64,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "Wednesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 32.77,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "Wednesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 33.9,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "logTotalBitsPerSec",
                        "weekday": "Wednesday"
                    }],
                    "totalCount": 752
                },
                "packetFailureRate": {
                    "__typename": "SmartDashboardPeers36SConnection",
                    "nodes": [{
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.993600602595433,
                        "binCatValue": null,
                        "binLowerBound": 0,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "packetFailureRate",
                        "weekday": "All"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.00384220453723515,
                        "binCatValue": null,
                        "binLowerBound": 0.05,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "packetFailureRate",
                        "weekday": "All"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.00136256447699126,
                        "binCatValue": null,
                        "binLowerBound": 0.1,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "packetFailureRate",
                        "weekday": "All"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.000481472661360679,
                        "binCatValue": null,
                        "binLowerBound": 0.15,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "packetFailureRate",
                        "weekday": "All"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.00016333058863228,
                        "binCatValue": null,
                        "binLowerBound": 0.2,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "packetFailureRate",
                        "weekday": "All"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.0000892600988699827,
                        "binCatValue": null,
                        "binLowerBound": 0.25,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "packetFailureRate",
                        "weekday": "All"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.0000622062270048648,
                        "binCatValue": null,
                        "binLowerBound": 0.3,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "packetFailureRate",
                        "weekday": "All"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.0000431395871386814,
                        "binCatValue": null,
                        "binLowerBound": 0.35,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "packetFailureRate",
                        "weekday": "All"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.0000299662169042247,
                        "binCatValue": null,
                        "binLowerBound": 0.4,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "packetFailureRate",
                        "weekday": "All"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.0000220978561313767,
                        "binCatValue": null,
                        "binLowerBound": 0.45,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "packetFailureRate",
                        "weekday": "All"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.0000369415194913498,
                        "binCatValue": null,
                        "binLowerBound": 0.5,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "packetFailureRate",
                        "weekday": "All"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.0000192220868541419,
                        "binCatValue": null,
                        "binLowerBound": 0.55,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "packetFailureRate",
                        "weekday": "All"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.0000178611637144647,
                        "binCatValue": null,
                        "binLowerBound": 0.6,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "packetFailureRate",
                        "weekday": "All"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.0000139658450750305,
                        "binCatValue": null,
                        "binLowerBound": 0.65,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "packetFailureRate",
                        "weekday": "All"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.0000171494603480267,
                        "binCatValue": null,
                        "binLowerBound": 0.7,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "packetFailureRate",
                        "weekday": "All"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.0000163341256562828,
                        "binCatValue": null,
                        "binLowerBound": 0.75,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "packetFailureRate",
                        "weekday": "All"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.0000148482353302076,
                        "binCatValue": null,
                        "binLowerBound": 0.8,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "packetFailureRate",
                        "weekday": "All"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.0000204854746288254,
                        "binCatValue": null,
                        "binLowerBound": 0.85,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "packetFailureRate",
                        "weekday": "All"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.0000296172231763573,
                        "binCatValue": null,
                        "binLowerBound": 0.9,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "packetFailureRate",
                        "weekday": "All"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.000116730020026402,
                        "binCatValue": null,
                        "binLowerBound": 0.95,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "packetFailureRate",
                        "weekday": "All"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz and 5 GHz",
                        "binAvgFrequency": 1,
                        "binCatValue": null,
                        "binLowerBound": 0,
                        "lastTimestamp": "2019-01-09T00:00:00",
                        "variable": "packetFailureRate",
                        "weekday": "All"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.998040674752338,
                        "binCatValue": null,
                        "binLowerBound": 0,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "packetFailureRate",
                        "weekday": "All"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.000735872970295736,
                        "binCatValue": null,
                        "binLowerBound": 0.05,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "packetFailureRate",
                        "weekday": "All"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.000241389577716871,
                        "binCatValue": null,
                        "binLowerBound": 0.1,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "packetFailureRate",
                        "weekday": "All"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.000157622658924571,
                        "binCatValue": null,
                        "binLowerBound": 0.15,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "packetFailureRate",
                        "weekday": "All"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.000111926924400902,
                        "binCatValue": null,
                        "binLowerBound": 0.2,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "packetFailureRate",
                        "weekday": "All"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.0000603127708676722,
                        "binCatValue": null,
                        "binLowerBound": 0.25,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "packetFailureRate",
                        "weekday": "All"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.0000491300971602841,
                        "binCatValue": null,
                        "binLowerBound": 0.3,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "packetFailureRate",
                        "weekday": "All"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.0000355119874004176,
                        "binCatValue": null,
                        "binLowerBound": 0.35,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "packetFailureRate",
                        "weekday": "All"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.0000330249843413833,
                        "binCatValue": null,
                        "binLowerBound": 0.4,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "packetFailureRate",
                        "weekday": "All"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.0000315236477790872,
                        "binCatValue": null,
                        "binLowerBound": 0.45,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "packetFailureRate",
                        "weekday": "All"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.0000230533986165348,
                        "binCatValue": null,
                        "binLowerBound": 0.5,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "packetFailureRate",
                        "weekday": "All"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.00000952221609629111,
                        "binCatValue": null,
                        "binLowerBound": 0.55,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "packetFailureRate",
                        "weekday": "All"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.0000111355267299657,
                        "binCatValue": null,
                        "binLowerBound": 0.6,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "packetFailureRate",
                        "weekday": "All"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.00000970638397684755,
                        "binCatValue": null,
                        "binLowerBound": 0.65,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "packetFailureRate",
                        "weekday": "All"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.00000826250779328491,
                        "binCatValue": null,
                        "binLowerBound": 0.7,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "packetFailureRate",
                        "weekday": "All"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.00000868093721790917,
                        "binCatValue": null,
                        "binLowerBound": 0.75,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "packetFailureRate",
                        "weekday": "All"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.00000875165768404285,
                        "binCatValue": null,
                        "binLowerBound": 0.8,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "packetFailureRate",
                        "weekday": "All"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.0000111900404226103,
                        "binCatValue": null,
                        "binLowerBound": 0.85,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "packetFailureRate",
                        "weekday": "All"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.0000125425693374169,
                        "binCatValue": null,
                        "binLowerBound": 0.9,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "packetFailureRate",
                        "weekday": "All"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.000400164390902205,
                        "binCatValue": null,
                        "binLowerBound": 0.95,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "packetFailureRate",
                        "weekday": "All"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 1,
                        "binCatValue": null,
                        "binLowerBound": 0,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "packetFailureRate",
                        "weekday": "All"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 0.05,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "packetFailureRate",
                        "weekday": "All"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 0.1,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "packetFailureRate",
                        "weekday": "All"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 0.15,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "packetFailureRate",
                        "weekday": "All"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 0.2,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "packetFailureRate",
                        "weekday": "All"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 0.25,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "packetFailureRate",
                        "weekday": "All"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 0.3,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "packetFailureRate",
                        "weekday": "All"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 0.35,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "packetFailureRate",
                        "weekday": "All"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 0.4,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "packetFailureRate",
                        "weekday": "All"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 0.45,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "packetFailureRate",
                        "weekday": "All"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 0.5,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "packetFailureRate",
                        "weekday": "All"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 0.55,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "packetFailureRate",
                        "weekday": "All"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 0.6,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "packetFailureRate",
                        "weekday": "All"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 0.65,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "packetFailureRate",
                        "weekday": "All"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 0.7,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "packetFailureRate",
                        "weekday": "All"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 0.75,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "packetFailureRate",
                        "weekday": "All"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 0.8,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "packetFailureRate",
                        "weekday": "All"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 0.85,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "packetFailureRate",
                        "weekday": "All"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 0.9,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "packetFailureRate",
                        "weekday": "All"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 0.95,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "packetFailureRate",
                        "weekday": "All"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.99349111410556,
                        "binCatValue": null,
                        "binLowerBound": 0,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "packetFailureRate",
                        "weekday": "Friday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.00395519061461538,
                        "binCatValue": null,
                        "binLowerBound": 0.05,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "packetFailureRate",
                        "weekday": "Friday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.00135758496464588,
                        "binCatValue": null,
                        "binLowerBound": 0.1,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "packetFailureRate",
                        "weekday": "Friday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.000476363342366755,
                        "binCatValue": null,
                        "binLowerBound": 0.15,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "packetFailureRate",
                        "weekday": "Friday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.000165227838766295,
                        "binCatValue": null,
                        "binLowerBound": 0.2,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "packetFailureRate",
                        "weekday": "Friday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.0000887250061050103,
                        "binCatValue": null,
                        "binLowerBound": 0.25,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "packetFailureRate",
                        "weekday": "Friday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.00006220959880108,
                        "binCatValue": null,
                        "binLowerBound": 0.3,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "packetFailureRate",
                        "weekday": "Friday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.0000447854660953069,
                        "binCatValue": null,
                        "binLowerBound": 0.35,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "packetFailureRate",
                        "weekday": "Friday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.0000309103336337343,
                        "binCatValue": null,
                        "binLowerBound": 0.4,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "packetFailureRate",
                        "weekday": "Friday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.0000252222099546734,
                        "binCatValue": null,
                        "binLowerBound": 0.45,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "packetFailureRate",
                        "weekday": "Friday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.0000364623312760315,
                        "binCatValue": null,
                        "binLowerBound": 0.5,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "packetFailureRate",
                        "weekday": "Friday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.0000189312423985155,
                        "binCatValue": null,
                        "binLowerBound": 0.55,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "packetFailureRate",
                        "weekday": "Friday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.0000180269765828699,
                        "binCatValue": null,
                        "binLowerBound": 0.6,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "packetFailureRate",
                        "weekday": "Friday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.0000140793215167182,
                        "binCatValue": null,
                        "binLowerBound": 0.65,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "packetFailureRate",
                        "weekday": "Friday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.0000167143326569328,
                        "binCatValue": null,
                        "binLowerBound": 0.7,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "packetFailureRate",
                        "weekday": "Friday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.0000158781298596691,
                        "binCatValue": null,
                        "binLowerBound": 0.75,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "packetFailureRate",
                        "weekday": "Friday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.0000143807434552668,
                        "binCatValue": null,
                        "binLowerBound": 0.8,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "packetFailureRate",
                        "weekday": "Friday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.0000201077602876888,
                        "binCatValue": null,
                        "binLowerBound": 0.85,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "packetFailureRate",
                        "weekday": "Friday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.0000309103336337343,
                        "binCatValue": null,
                        "binLowerBound": 0.9,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "packetFailureRate",
                        "weekday": "Friday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.000117175347788655,
                        "binCatValue": null,
                        "binLowerBound": 0.95,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "packetFailureRate",
                        "weekday": "Friday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz and 5 GHz",
                        "binAvgFrequency": 1,
                        "binCatValue": null,
                        "binLowerBound": 0,
                        "lastTimestamp": "2019-01-04T00:00:00",
                        "variable": "packetFailureRate",
                        "weekday": "Friday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.998063134168751,
                        "binCatValue": null,
                        "binLowerBound": 0,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "packetFailureRate",
                        "weekday": "Friday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.000737336702754338,
                        "binCatValue": null,
                        "binLowerBound": 0.05,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "packetFailureRate",
                        "weekday": "Friday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.000244529909865763,
                        "binCatValue": null,
                        "binLowerBound": 0.1,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "packetFailureRate",
                        "weekday": "Friday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.000162960464146111,
                        "binCatValue": null,
                        "binLowerBound": 0.15,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "packetFailureRate",
                        "weekday": "Friday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.000114052603990925,
                        "binCatValue": null,
                        "binLowerBound": 0.2,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "packetFailureRate",
                        "weekday": "Friday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.0000613320343075115,
                        "binCatValue": null,
                        "binLowerBound": 0.25,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "packetFailureRate",
                        "weekday": "Friday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.0000470766326724169,
                        "binCatValue": null,
                        "binLowerBound": 0.3,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "packetFailureRate",
                        "weekday": "Friday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.000034154740281185,
                        "binCatValue": null,
                        "binLowerBound": 0.35,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "packetFailureRate",
                        "weekday": "Friday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.000030520458046151,
                        "binCatValue": null,
                        "binLowerBound": 0.4,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "packetFailureRate",
                        "weekday": "Friday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.0000302669034716138,
                        "binCatValue": null,
                        "binLowerBound": 0.45,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "packetFailureRate",
                        "weekday": "Friday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.0000238153481861659,
                        "binCatValue": null,
                        "binLowerBound": 0.5,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "packetFailureRate",
                        "weekday": "Friday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.00000903405558166072,
                        "binCatValue": null,
                        "binLowerBound": 0.55,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "packetFailureRate",
                        "weekday": "Friday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.0000122081832184604,
                        "binCatValue": null,
                        "binLowerBound": 0.6,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "packetFailureRate",
                        "weekday": "Friday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.00000968202838325592,
                        "binCatValue": null,
                        "binLowerBound": 0.65,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "packetFailureRate",
                        "weekday": "Friday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.00000855511916309035,
                        "binCatValue": null,
                        "binLowerBound": 0.7,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "packetFailureRate",
                        "weekday": "Friday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.00000910918286300509,
                        "binCatValue": null,
                        "binLowerBound": 0.75,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "packetFailureRate",
                        "weekday": "Friday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.00000930639197653407,
                        "binCatValue": null,
                        "binLowerBound": 0.8,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "packetFailureRate",
                        "weekday": "Friday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.0000114475194948487,
                        "binCatValue": null,
                        "binLowerBound": 0.85,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "packetFailureRate",
                        "weekday": "Friday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.0000137013379351798,
                        "binCatValue": null,
                        "binLowerBound": 0.9,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "packetFailureRate",
                        "weekday": "Friday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.000367776214911204,
                        "binCatValue": null,
                        "binLowerBound": 0.95,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "packetFailureRate",
                        "weekday": "Friday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 1,
                        "binCatValue": null,
                        "binLowerBound": 0,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "packetFailureRate",
                        "weekday": "Friday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 0.05,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "packetFailureRate",
                        "weekday": "Friday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 0.1,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "packetFailureRate",
                        "weekday": "Friday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 0.15,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "packetFailureRate",
                        "weekday": "Friday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 0.2,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "packetFailureRate",
                        "weekday": "Friday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 0.25,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "packetFailureRate",
                        "weekday": "Friday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 0.3,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "packetFailureRate",
                        "weekday": "Friday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 0.35,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "packetFailureRate",
                        "weekday": "Friday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 0.4,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "packetFailureRate",
                        "weekday": "Friday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 0.45,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "packetFailureRate",
                        "weekday": "Friday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 0.5,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "packetFailureRate",
                        "weekday": "Friday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 0.55,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "packetFailureRate",
                        "weekday": "Friday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 0.6,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "packetFailureRate",
                        "weekday": "Friday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 0.65,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "packetFailureRate",
                        "weekday": "Friday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 0.7,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "packetFailureRate",
                        "weekday": "Friday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 0.75,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "packetFailureRate",
                        "weekday": "Friday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 0.8,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "packetFailureRate",
                        "weekday": "Friday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 0.85,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "packetFailureRate",
                        "weekday": "Friday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 0.9,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "packetFailureRate",
                        "weekday": "Friday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 0.95,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "packetFailureRate",
                        "weekday": "Friday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.993628461685694,
                        "binCatValue": null,
                        "binLowerBound": 0,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "packetFailureRate",
                        "weekday": "Monday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.00387138397381518,
                        "binCatValue": null,
                        "binLowerBound": 0.05,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "packetFailureRate",
                        "weekday": "Monday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.00133191073506957,
                        "binCatValue": null,
                        "binLowerBound": 0.1,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "packetFailureRate",
                        "weekday": "Monday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.000464569830451927,
                        "binCatValue": null,
                        "binLowerBound": 0.15,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "packetFailureRate",
                        "weekday": "Monday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.000161310706681968,
                        "binCatValue": null,
                        "binLowerBound": 0.2,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "packetFailureRate",
                        "weekday": "Monday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.0000899611387274267,
                        "binCatValue": null,
                        "binLowerBound": 0.25,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "packetFailureRate",
                        "weekday": "Monday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.0000623826487026185,
                        "binCatValue": null,
                        "binLowerBound": 0.3,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "packetFailureRate",
                        "weekday": "Monday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.0000398506574195903,
                        "binCatValue": null,
                        "binLowerBound": 0.35,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "packetFailureRate",
                        "weekday": "Monday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.0000307377650635668,
                        "binCatValue": null,
                        "binLowerBound": 0.4,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "packetFailureRate",
                        "weekday": "Monday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.0000196959424033507,
                        "binCatValue": null,
                        "binLowerBound": 0.45,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "packetFailureRate",
                        "weekday": "Monday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.0000370041818896196,
                        "binCatValue": null,
                        "binLowerBound": 0.5,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "packetFailureRate",
                        "weekday": "Monday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.0000186115707728856,
                        "binCatValue": null,
                        "binLowerBound": 0.55,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "packetFailureRate",
                        "weekday": "Monday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.0000181215182091177,
                        "binCatValue": null,
                        "binLowerBound": 0.6,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "packetFailureRate",
                        "weekday": "Monday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.0000148579766674297,
                        "binCatValue": null,
                        "binLowerBound": 0.65,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "packetFailureRate",
                        "weekday": "Monday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.0000176835988968146,
                        "binCatValue": null,
                        "binLowerBound": 0.7,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "packetFailureRate",
                        "weekday": "Monday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.0000161613079540463,
                        "binCatValue": null,
                        "binLowerBound": 0.75,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "packetFailureRate",
                        "weekday": "Monday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.0000147224302136216,
                        "binCatValue": null,
                        "binLowerBound": 0.8,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "packetFailureRate",
                        "weekday": "Monday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.0000209888470396744,
                        "binCatValue": null,
                        "binLowerBound": 0.85,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "packetFailureRate",
                        "weekday": "Monday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.0000284751819500003,
                        "binCatValue": null,
                        "binLowerBound": 0.9,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "packetFailureRate",
                        "weekday": "Monday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.000113108302377738,
                        "binCatValue": null,
                        "binLowerBound": 0.95,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "packetFailureRate",
                        "weekday": "Monday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz and 5 GHz",
                        "binAvgFrequency": 1,
                        "binCatValue": null,
                        "binLowerBound": 0,
                        "lastTimestamp": "2019-01-07T00:00:00",
                        "variable": "packetFailureRate",
                        "weekday": "Monday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.998092555714002,
                        "binCatValue": null,
                        "binLowerBound": 0,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "packetFailureRate",
                        "weekday": "Monday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.000704440556350503,
                        "binCatValue": null,
                        "binLowerBound": 0.05,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "packetFailureRate",
                        "weekday": "Monday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.000242259570123139,
                        "binCatValue": null,
                        "binLowerBound": 0.1,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "packetFailureRate",
                        "weekday": "Monday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.000154158704819379,
                        "binCatValue": null,
                        "binLowerBound": 0.15,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "packetFailureRate",
                        "weekday": "Monday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.000109116030701966,
                        "binCatValue": null,
                        "binLowerBound": 0.2,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "packetFailureRate",
                        "weekday": "Monday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.0000638392893670426,
                        "binCatValue": null,
                        "binLowerBound": 0.25,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "packetFailureRate",
                        "weekday": "Monday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.000050466927462325,
                        "binCatValue": null,
                        "binLowerBound": 0.3,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "packetFailureRate",
                        "weekday": "Monday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.0000375932304992596,
                        "binCatValue": null,
                        "binLowerBound": 0.35,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "packetFailureRate",
                        "weekday": "Monday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.0000367790836557456,
                        "binCatValue": null,
                        "binLowerBound": 0.4,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "packetFailureRate",
                        "weekday": "Monday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.0000356596317459139,
                        "binCatValue": null,
                        "binLowerBound": 0.45,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "packetFailureRate",
                        "weekday": "Monday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.0000232438923823251,
                        "binCatValue": null,
                        "binLowerBound": 0.5,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "packetFailureRate",
                        "weekday": "Monday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.000010339664912628,
                        "binCatValue": null,
                        "binLowerBound": 0.55,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "packetFailureRate",
                        "weekday": "Monday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.0000114489399869158,
                        "binCatValue": null,
                        "binLowerBound": 0.6,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "packetFailureRate",
                        "weekday": "Monday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.00000949498756248221,
                        "binCatValue": null,
                        "binLowerBound": 0.65,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "packetFailureRate",
                        "weekday": "Monday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.00000818217577731585,
                        "binCatValue": null,
                        "binLowerBound": 0.7,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "packetFailureRate",
                        "weekday": "Monday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.00000887420059430277,
                        "binCatValue": null,
                        "binLowerBound": 0.75,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "packetFailureRate",
                        "weekday": "Monday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.00000917950566062052,
                        "binCatValue": null,
                        "binLowerBound": 0.8,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "packetFailureRate",
                        "weekday": "Monday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.0000108993908675439,
                        "binCatValue": null,
                        "binLowerBound": 0.85,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "packetFailureRate",
                        "weekday": "Monday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.0000128228127853457,
                        "binCatValue": null,
                        "binLowerBound": 0.9,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "packetFailureRate",
                        "weekday": "Monday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.000368645690743146,
                        "binCatValue": null,
                        "binLowerBound": 0.95,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "packetFailureRate",
                        "weekday": "Monday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 1,
                        "binCatValue": null,
                        "binLowerBound": 0,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "packetFailureRate",
                        "weekday": "Monday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 0.05,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "packetFailureRate",
                        "weekday": "Monday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 0.1,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "packetFailureRate",
                        "weekday": "Monday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 0.15,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "packetFailureRate",
                        "weekday": "Monday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 0.2,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "packetFailureRate",
                        "weekday": "Monday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 0.25,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "packetFailureRate",
                        "weekday": "Monday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 0.3,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "packetFailureRate",
                        "weekday": "Monday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 0.35,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "packetFailureRate",
                        "weekday": "Monday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 0.4,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "packetFailureRate",
                        "weekday": "Monday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 0.45,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "packetFailureRate",
                        "weekday": "Monday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 0.5,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "packetFailureRate",
                        "weekday": "Monday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 0.55,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "packetFailureRate",
                        "weekday": "Monday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 0.6,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "packetFailureRate",
                        "weekday": "Monday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 0.65,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "packetFailureRate",
                        "weekday": "Monday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 0.7,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "packetFailureRate",
                        "weekday": "Monday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 0.75,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "packetFailureRate",
                        "weekday": "Monday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 0.8,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "packetFailureRate",
                        "weekday": "Monday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 0.85,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "packetFailureRate",
                        "weekday": "Monday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 0.9,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "packetFailureRate",
                        "weekday": "Monday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 0.95,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "packetFailureRate",
                        "weekday": "Monday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.994484881242306,
                        "binCatValue": null,
                        "binLowerBound": 0,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "packetFailureRate",
                        "weekday": "Saturday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.00319391299323341,
                        "binCatValue": null,
                        "binLowerBound": 0.05,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "packetFailureRate",
                        "weekday": "Saturday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.00120165532546175,
                        "binCatValue": null,
                        "binLowerBound": 0.1,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "packetFailureRate",
                        "weekday": "Saturday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.000438976423222337,
                        "binCatValue": null,
                        "binLowerBound": 0.15,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "packetFailureRate",
                        "weekday": "Saturday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.000148034123002235,
                        "binCatValue": null,
                        "binLowerBound": 0.2,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "packetFailureRate",
                        "weekday": "Saturday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.0000807585777467058,
                        "binCatValue": null,
                        "binLowerBound": 0.25,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "packetFailureRate",
                        "weekday": "Saturday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.0000536628682211687,
                        "binCatValue": null,
                        "binLowerBound": 0.3,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "packetFailureRate",
                        "weekday": "Saturday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.0000344555305155431,
                        "binCatValue": null,
                        "binLowerBound": 0.35,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "packetFailureRate",
                        "weekday": "Saturday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.0000276741236412828,
                        "binCatValue": null,
                        "binLowerBound": 0.4,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "packetFailureRate",
                        "weekday": "Saturday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.0000180106188454619,
                        "binCatValue": null,
                        "binLowerBound": 0.45,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "packetFailureRate",
                        "weekday": "Saturday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.0000346150930302316,
                        "binCatValue": null,
                        "binLowerBound": 0.5,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "packetFailureRate",
                        "weekday": "Saturday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.0000184494157608552,
                        "binCatValue": null,
                        "binLowerBound": 0.55,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "packetFailureRate",
                        "weekday": "Saturday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.0000181701813601504,
                        "binCatValue": null,
                        "binLowerBound": 0.6,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "packetFailureRate",
                        "weekday": "Saturday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.000013692458291705,
                        "binCatValue": null,
                        "binLowerBound": 0.65,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "packetFailureRate",
                        "weekday": "Saturday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.0000184992790466953,
                        "binCatValue": null,
                        "binLowerBound": 0.7,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "packetFailureRate",
                        "weekday": "Saturday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.0000174521500440522,
                        "binCatValue": null,
                        "binLowerBound": 0.75,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "packetFailureRate",
                        "weekday": "Saturday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.0000159263334973437,
                        "binCatValue": null,
                        "binLowerBound": 0.8,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "packetFailureRate",
                        "weekday": "Saturday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.0000225382051997474,
                        "binCatValue": null,
                        "binLowerBound": 0.85,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "packetFailureRate",
                        "weekday": "Saturday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.0000318227490231832,
                        "binCatValue": null,
                        "binLowerBound": 0.9,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "packetFailureRate",
                        "weekday": "Saturday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.000126812308548668,
                        "binCatValue": null,
                        "binLowerBound": 0.95,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "packetFailureRate",
                        "weekday": "Saturday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz and 5 GHz",
                        "binAvgFrequency": 1,
                        "binCatValue": null,
                        "binLowerBound": 0,
                        "lastTimestamp": "2019-01-05T00:00:00",
                        "variable": "packetFailureRate",
                        "weekday": "Saturday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.9980585517309,
                        "binCatValue": null,
                        "binLowerBound": 0,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "packetFailureRate",
                        "weekday": "Saturday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.000749046236797812,
                        "binCatValue": null,
                        "binLowerBound": 0.05,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "packetFailureRate",
                        "weekday": "Saturday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.000229022994415595,
                        "binCatValue": null,
                        "binLowerBound": 0.1,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "packetFailureRate",
                        "weekday": "Saturday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.000138423867458585,
                        "binCatValue": null,
                        "binLowerBound": 0.15,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "packetFailureRate",
                        "weekday": "Saturday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.0000987219397384457,
                        "binCatValue": null,
                        "binLowerBound": 0.2,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "packetFailureRate",
                        "weekday": "Saturday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.0000512908580123299,
                        "binCatValue": null,
                        "binLowerBound": 0.25,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "packetFailureRate",
                        "weekday": "Saturday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.0000412093527871435,
                        "binCatValue": null,
                        "binLowerBound": 0.3,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "packetFailureRate",
                        "weekday": "Saturday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.000029428393823997,
                        "binCatValue": null,
                        "binLowerBound": 0.35,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "packetFailureRate",
                        "weekday": "Saturday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.0000268360067660918,
                        "binCatValue": null,
                        "binLowerBound": 0.4,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "packetFailureRate",
                        "weekday": "Saturday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.0000283338303995481,
                        "binCatValue": null,
                        "binLowerBound": 0.45,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "packetFailureRate",
                        "weekday": "Saturday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.0000189628312568985,
                        "binCatValue": null,
                        "binLowerBound": 0.5,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "packetFailureRate",
                        "weekday": "Saturday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.0000061449174705899,
                        "binCatValue": null,
                        "binLowerBound": 0.55,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "packetFailureRate",
                        "weekday": "Saturday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.00000961103498134452,
                        "binCatValue": null,
                        "binLowerBound": 0.6,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "packetFailureRate",
                        "weekday": "Saturday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.0000073258937969689,
                        "binCatValue": null,
                        "binLowerBound": 0.65,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "packetFailureRate",
                        "weekday": "Saturday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.00000580886729641702,
                        "binCatValue": null,
                        "binLowerBound": 0.7,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "packetFailureRate",
                        "weekday": "Saturday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.00000664419201507533,
                        "binCatValue": null,
                        "binLowerBound": 0.75,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "packetFailureRate",
                        "weekday": "Saturday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.00000665379344862313,
                        "binCatValue": null,
                        "binLowerBound": 0.8,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "packetFailureRate",
                        "weekday": "Saturday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.0000081804213827228,
                        "binCatValue": null,
                        "binLowerBound": 0.85,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "packetFailureRate",
                        "weekday": "Saturday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.00000786357407564551,
                        "binCatValue": null,
                        "binLowerBound": 0.9,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "packetFailureRate",
                        "weekday": "Saturday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.000471939263174852,
                        "binCatValue": null,
                        "binLowerBound": 0.95,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "packetFailureRate",
                        "weekday": "Saturday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 1,
                        "binCatValue": null,
                        "binLowerBound": 0,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "packetFailureRate",
                        "weekday": "Saturday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 0.05,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "packetFailureRate",
                        "weekday": "Saturday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 0.1,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "packetFailureRate",
                        "weekday": "Saturday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 0.15,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "packetFailureRate",
                        "weekday": "Saturday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 0.2,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "packetFailureRate",
                        "weekday": "Saturday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 0.25,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "packetFailureRate",
                        "weekday": "Saturday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 0.3,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "packetFailureRate",
                        "weekday": "Saturday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 0.35,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "packetFailureRate",
                        "weekday": "Saturday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 0.4,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "packetFailureRate",
                        "weekday": "Saturday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 0.45,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "packetFailureRate",
                        "weekday": "Saturday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 0.5,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "packetFailureRate",
                        "weekday": "Saturday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 0.55,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "packetFailureRate",
                        "weekday": "Saturday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 0.6,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "packetFailureRate",
                        "weekday": "Saturday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 0.65,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "packetFailureRate",
                        "weekday": "Saturday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 0.7,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "packetFailureRate",
                        "weekday": "Saturday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 0.75,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "packetFailureRate",
                        "weekday": "Saturday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 0.8,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "packetFailureRate",
                        "weekday": "Saturday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 0.85,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "packetFailureRate",
                        "weekday": "Saturday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 0.9,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "packetFailureRate",
                        "weekday": "Saturday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 0.95,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "packetFailureRate",
                        "weekday": "Saturday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.993410476246721,
                        "binCatValue": null,
                        "binLowerBound": 0,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "packetFailureRate",
                        "weekday": "Sunday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.00366164171925062,
                        "binCatValue": null,
                        "binLowerBound": 0.05,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "packetFailureRate",
                        "weekday": "Sunday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.0016150301813919,
                        "binCatValue": null,
                        "binLowerBound": 0.1,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "packetFailureRate",
                        "weekday": "Sunday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.000591808940496322,
                        "binCatValue": null,
                        "binLowerBound": 0.15,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "packetFailureRate",
                        "weekday": "Sunday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.000169337827670105,
                        "binCatValue": null,
                        "binLowerBound": 0.2,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "packetFailureRate",
                        "weekday": "Sunday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.0000822927368854942,
                        "binCatValue": null,
                        "binLowerBound": 0.25,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "packetFailureRate",
                        "weekday": "Sunday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.0000548493019186586,
                        "binCatValue": null,
                        "binLowerBound": 0.3,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "packetFailureRate",
                        "weekday": "Sunday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.0000321582208509395,
                        "binCatValue": null,
                        "binLowerBound": 0.35,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "packetFailureRate",
                        "weekday": "Sunday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.0000272368108842654,
                        "binCatValue": null,
                        "binLowerBound": 0.4,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "packetFailureRate",
                        "weekday": "Sunday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.0000160227584029506,
                        "binCatValue": null,
                        "binLowerBound": 0.45,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "packetFailureRate",
                        "weekday": "Sunday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.0000407425122813597,
                        "binCatValue": null,
                        "binLowerBound": 0.5,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "packetFailureRate",
                        "weekday": "Sunday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.0000227662170977446,
                        "binCatValue": null,
                        "binLowerBound": 0.55,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "packetFailureRate",
                        "weekday": "Sunday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.0000166238466431551,
                        "binCatValue": null,
                        "binLowerBound": 0.6,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "packetFailureRate",
                        "weekday": "Sunday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.0000134681333820816,
                        "binCatValue": null,
                        "binLowerBound": 0.65,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "packetFailureRate",
                        "weekday": "Sunday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.0000222402648875657,
                        "binCatValue": null,
                        "binLowerBound": 0.7,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "packetFailureRate",
                        "weekday": "Sunday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.0000206999762720417,
                        "binCatValue": null,
                        "binLowerBound": 0.75,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "packetFailureRate",
                        "weekday": "Sunday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.0000173376389283979,
                        "binCatValue": null,
                        "binLowerBound": 0.8,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "packetFailureRate",
                        "weekday": "Sunday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.000026109770433882,
                        "binCatValue": null,
                        "binLowerBound": 0.85,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "packetFailureRate",
                        "weekday": "Sunday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.0000285516914097127,
                        "binCatValue": null,
                        "binLowerBound": 0.9,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "packetFailureRate",
                        "weekday": "Sunday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.000130605204191929,
                        "binCatValue": null,
                        "binLowerBound": 0.95,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "packetFailureRate",
                        "weekday": "Sunday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz and 5 GHz",
                        "binAvgFrequency": 1,
                        "binCatValue": null,
                        "binLowerBound": 0,
                        "lastTimestamp": "2019-01-06T00:00:00",
                        "variable": "packetFailureRate",
                        "weekday": "Sunday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.997755700473806,
                        "binCatValue": null,
                        "binLowerBound": 0,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "packetFailureRate",
                        "weekday": "Sunday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.00080728996798895,
                        "binCatValue": null,
                        "binLowerBound": 0.05,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "packetFailureRate",
                        "weekday": "Sunday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.000235089744483136,
                        "binCatValue": null,
                        "binLowerBound": 0.1,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "packetFailureRate",
                        "weekday": "Sunday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.000129762298998094,
                        "binCatValue": null,
                        "binLowerBound": 0.15,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "packetFailureRate",
                        "weekday": "Sunday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.0000835379607283871,
                        "binCatValue": null,
                        "binLowerBound": 0.2,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "packetFailureRate",
                        "weekday": "Sunday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.0000485042284479197,
                        "binCatValue": null,
                        "binLowerBound": 0.25,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "packetFailureRate",
                        "weekday": "Sunday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.0000438226218987663,
                        "binCatValue": null,
                        "binLowerBound": 0.3,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "packetFailureRate",
                        "weekday": "Sunday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.0000242782198366874,
                        "binCatValue": null,
                        "binLowerBound": 0.35,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "packetFailureRate",
                        "weekday": "Sunday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.000027915601876736,
                        "binCatValue": null,
                        "binLowerBound": 0.4,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "packetFailureRate",
                        "weekday": "Sunday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.0000198924768984471,
                        "binCatValue": null,
                        "binLowerBound": 0.45,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "packetFailureRate",
                        "weekday": "Sunday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.0000152978890583859,
                        "binCatValue": null,
                        "binLowerBound": 0.5,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "packetFailureRate",
                        "weekday": "Sunday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.0000082319698801098,
                        "binCatValue": null,
                        "binLowerBound": 0.55,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "packetFailureRate",
                        "weekday": "Sunday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.0000113472396656059,
                        "binCatValue": null,
                        "binLowerBound": 0.6,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "packetFailureRate",
                        "weekday": "Sunday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.00000903254200375684,
                        "binCatValue": null,
                        "binLowerBound": 0.65,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "packetFailureRate",
                        "weekday": "Sunday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.00000779687633464945,
                        "binCatValue": null,
                        "binLowerBound": 0.7,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "packetFailureRate",
                        "weekday": "Sunday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.00000750101272373641,
                        "binCatValue": null,
                        "binLowerBound": 0.75,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "packetFailureRate",
                        "weekday": "Sunday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.00000783168381828628,
                        "binCatValue": null,
                        "binLowerBound": 0.8,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "packetFailureRate",
                        "weekday": "Sunday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.00000978090290194864,
                        "binCatValue": null,
                        "binLowerBound": 0.85,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "packetFailureRate",
                        "weekday": "Sunday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.00000929359813103305,
                        "binCatValue": null,
                        "binLowerBound": 0.9,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "packetFailureRate",
                        "weekday": "Sunday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.000738092690518936,
                        "binCatValue": null,
                        "binLowerBound": 0.95,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "packetFailureRate",
                        "weekday": "Sunday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 1,
                        "binCatValue": null,
                        "binLowerBound": 0,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "packetFailureRate",
                        "weekday": "Sunday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 0.05,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "packetFailureRate",
                        "weekday": "Sunday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 0.1,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "packetFailureRate",
                        "weekday": "Sunday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 0.15,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "packetFailureRate",
                        "weekday": "Sunday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 0.2,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "packetFailureRate",
                        "weekday": "Sunday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 0.25,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "packetFailureRate",
                        "weekday": "Sunday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 0.3,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "packetFailureRate",
                        "weekday": "Sunday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 0.35,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "packetFailureRate",
                        "weekday": "Sunday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 0.4,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "packetFailureRate",
                        "weekday": "Sunday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 0.45,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "packetFailureRate",
                        "weekday": "Sunday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 0.5,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "packetFailureRate",
                        "weekday": "Sunday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 0.55,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "packetFailureRate",
                        "weekday": "Sunday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 0.6,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "packetFailureRate",
                        "weekday": "Sunday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 0.65,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "packetFailureRate",
                        "weekday": "Sunday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 0.7,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "packetFailureRate",
                        "weekday": "Sunday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 0.75,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "packetFailureRate",
                        "weekday": "Sunday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 0.8,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "packetFailureRate",
                        "weekday": "Sunday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 0.85,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "packetFailureRate",
                        "weekday": "Sunday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 0.9,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "packetFailureRate",
                        "weekday": "Sunday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 0.95,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "packetFailureRate",
                        "weekday": "Sunday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.993380230678116,
                        "binCatValue": null,
                        "binLowerBound": 0,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "packetFailureRate",
                        "weekday": "Thursday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.00403028448801964,
                        "binCatValue": null,
                        "binLowerBound": 0.05,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "packetFailureRate",
                        "weekday": "Thursday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.00137803523255778,
                        "binCatValue": null,
                        "binLowerBound": 0.1,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "packetFailureRate",
                        "weekday": "Thursday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.000481716935770088,
                        "binCatValue": null,
                        "binLowerBound": 0.15,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "packetFailureRate",
                        "weekday": "Thursday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.000167808189771271,
                        "binCatValue": null,
                        "binLowerBound": 0.2,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "packetFailureRate",
                        "weekday": "Thursday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.0000921347053866928,
                        "binCatValue": null,
                        "binLowerBound": 0.25,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "packetFailureRate",
                        "weekday": "Thursday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.0000665572409407162,
                        "binCatValue": null,
                        "binLowerBound": 0.3,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "packetFailureRate",
                        "weekday": "Thursday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.0000491333754355534,
                        "binCatValue": null,
                        "binLowerBound": 0.35,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "packetFailureRate",
                        "weekday": "Thursday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.0000307468654273427,
                        "binCatValue": null,
                        "binLowerBound": 0.4,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "packetFailureRate",
                        "weekday": "Thursday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.0000255678380009462,
                        "binCatValue": null,
                        "binLowerBound": 0.45,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "packetFailureRate",
                        "weekday": "Thursday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.000037273595158006,
                        "binCatValue": null,
                        "binLowerBound": 0.5,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "packetFailureRate",
                        "weekday": "Thursday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.0000193106487311363,
                        "binCatValue": null,
                        "binLowerBound": 0.55,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "packetFailureRate",
                        "weekday": "Thursday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.000017385359725041,
                        "binCatValue": null,
                        "binLowerBound": 0.6,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "packetFailureRate",
                        "weekday": "Thursday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.0000139872246292827,
                        "binCatValue": null,
                        "binLowerBound": 0.65,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "packetFailureRate",
                        "weekday": "Thursday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.0000164419681120543,
                        "binCatValue": null,
                        "binLowerBound": 0.7,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "packetFailureRate",
                        "weekday": "Thursday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.0000156525996195552,
                        "binCatValue": null,
                        "binLowerBound": 0.75,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "packetFailureRate",
                        "weekday": "Thursday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.0000139005866240084,
                        "binCatValue": null,
                        "binLowerBound": 0.8,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "packetFailureRate",
                        "weekday": "Thursday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.0000194839247416849,
                        "binCatValue": null,
                        "binLowerBound": 0.85,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "packetFailureRate",
                        "weekday": "Thursday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.0000278878112532911,
                        "binCatValue": null,
                        "binLowerBound": 0.9,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "packetFailureRate",
                        "weekday": "Thursday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.000116460731978708,
                        "binCatValue": null,
                        "binLowerBound": 0.95,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "packetFailureRate",
                        "weekday": "Thursday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz and 5 GHz",
                        "binAvgFrequency": 1,
                        "binCatValue": null,
                        "binLowerBound": 0,
                        "lastTimestamp": "2019-01-03T00:00:00",
                        "variable": "packetFailureRate",
                        "weekday": "Thursday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.998046397387531,
                        "binCatValue": null,
                        "binLowerBound": 0,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "packetFailureRate",
                        "weekday": "Thursday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.000746628583656249,
                        "binCatValue": null,
                        "binLowerBound": 0.05,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "packetFailureRate",
                        "weekday": "Thursday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.00024669603524229,
                        "binCatValue": null,
                        "binLowerBound": 0.1,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "packetFailureRate",
                        "weekday": "Thursday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.000171922463238885,
                        "binCatValue": null,
                        "binLowerBound": 0.15,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "packetFailureRate",
                        "weekday": "Thursday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.000122687457261279,
                        "binCatValue": null,
                        "binLowerBound": 0.2,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "packetFailureRate",
                        "weekday": "Thursday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.0000645488419260452,
                        "binCatValue": null,
                        "binLowerBound": 0.25,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "packetFailureRate",
                        "weekday": "Thursday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.00005163535207402,
                        "binCatValue": null,
                        "binLowerBound": 0.3,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "packetFailureRate",
                        "weekday": "Thursday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.0000407221506356731,
                        "binCatValue": null,
                        "binLowerBound": 0.35,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "packetFailureRate",
                        "weekday": "Thursday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.0000347398927287191,
                        "binCatValue": null,
                        "binLowerBound": 0.4,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "packetFailureRate",
                        "weekday": "Thursday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.0000345259083867907,
                        "binCatValue": null,
                        "binLowerBound": 0.45,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "packetFailureRate",
                        "weekday": "Thursday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.0000268969014136922,
                        "binCatValue": null,
                        "binLowerBound": 0.5,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "packetFailureRate",
                        "weekday": "Thursday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.0000101596044080774,
                        "binCatValue": null,
                        "binLowerBound": 0.55,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "packetFailureRate",
                        "weekday": "Thursday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.000010941112439468,
                        "binCatValue": null,
                        "binLowerBound": 0.6,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "packetFailureRate",
                        "weekday": "Thursday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.00000948974038117123,
                        "binCatValue": null,
                        "binLowerBound": 0.65,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "packetFailureRate",
                        "weekday": "Thursday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.00000793602798543045,
                        "binCatValue": null,
                        "binLowerBound": 0.7,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "packetFailureRate",
                        "weekday": "Thursday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.00000870823234978066,
                        "binCatValue": null,
                        "binLowerBound": 0.75,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "packetFailureRate",
                        "weekday": "Thursday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.00000841981867152938,
                        "binCatValue": null,
                        "binLowerBound": 0.8,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "packetFailureRate",
                        "weekday": "Thursday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.0000117505314719797,
                        "binCatValue": null,
                        "binLowerBound": 0.85,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "packetFailureRate",
                        "weekday": "Thursday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.000013574050211891,
                        "binCatValue": null,
                        "binLowerBound": 0.9,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "packetFailureRate",
                        "weekday": "Thursday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.000331619907986733,
                        "binCatValue": null,
                        "binLowerBound": 0.95,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "packetFailureRate",
                        "weekday": "Thursday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 1,
                        "binCatValue": null,
                        "binLowerBound": 0,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "packetFailureRate",
                        "weekday": "Thursday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 0.05,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "packetFailureRate",
                        "weekday": "Thursday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 0.1,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "packetFailureRate",
                        "weekday": "Thursday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 0.15,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "packetFailureRate",
                        "weekday": "Thursday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 0.2,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "packetFailureRate",
                        "weekday": "Thursday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 0.25,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "packetFailureRate",
                        "weekday": "Thursday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 0.3,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "packetFailureRate",
                        "weekday": "Thursday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 0.35,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "packetFailureRate",
                        "weekday": "Thursday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 0.4,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "packetFailureRate",
                        "weekday": "Thursday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 0.45,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "packetFailureRate",
                        "weekday": "Thursday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 0.5,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "packetFailureRate",
                        "weekday": "Thursday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 0.55,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "packetFailureRate",
                        "weekday": "Thursday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 0.6,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "packetFailureRate",
                        "weekday": "Thursday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 0.65,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "packetFailureRate",
                        "weekday": "Thursday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 0.7,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "packetFailureRate",
                        "weekday": "Thursday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 0.75,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "packetFailureRate",
                        "weekday": "Thursday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 0.8,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "packetFailureRate",
                        "weekday": "Thursday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 0.85,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "packetFailureRate",
                        "weekday": "Thursday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 0.9,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "packetFailureRate",
                        "weekday": "Thursday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 0.95,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "packetFailureRate",
                        "weekday": "Thursday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.993380996462417,
                        "binCatValue": null,
                        "binLowerBound": 0,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "packetFailureRate",
                        "weekday": "Tuesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.00402971403545046,
                        "binCatValue": null,
                        "binLowerBound": 0.05,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "packetFailureRate",
                        "weekday": "Tuesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.00138896361646237,
                        "binCatValue": null,
                        "binLowerBound": 0.1,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "packetFailureRate",
                        "weekday": "Tuesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.000486964615055188,
                        "binCatValue": null,
                        "binLowerBound": 0.15,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "packetFailureRate",
                        "weekday": "Tuesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.000167582684932754,
                        "binCatValue": null,
                        "binLowerBound": 0.2,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "packetFailureRate",
                        "weekday": "Tuesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.0000918347256091364,
                        "binCatValue": null,
                        "binLowerBound": 0.25,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "packetFailureRate",
                        "weekday": "Tuesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.0000650269349785877,
                        "binCatValue": null,
                        "binLowerBound": 0.3,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "packetFailureRate",
                        "weekday": "Tuesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.0000467855042881169,
                        "binCatValue": null,
                        "binLowerBound": 0.35,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "packetFailureRate",
                        "weekday": "Tuesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.0000305313854274566,
                        "binCatValue": null,
                        "binLowerBound": 0.4,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "packetFailureRate",
                        "weekday": "Tuesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.0000223834069252337,
                        "binCatValue": null,
                        "binLowerBound": 0.45,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "packetFailureRate",
                        "weekday": "Tuesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.0000366397544201372,
                        "binCatValue": null,
                        "binLowerBound": 0.5,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "packetFailureRate",
                        "weekday": "Tuesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.0000188062456315748,
                        "binCatValue": null,
                        "binLowerBound": 0.55,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "packetFailureRate",
                        "weekday": "Tuesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.0000182309711545244,
                        "binCatValue": null,
                        "binLowerBound": 0.6,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "packetFailureRate",
                        "weekday": "Tuesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.0000140366972400297,
                        "binCatValue": null,
                        "binLowerBound": 0.65,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "packetFailureRate",
                        "weekday": "Tuesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.0000158671160306446,
                        "binCatValue": null,
                        "binLowerBound": 0.7,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "packetFailureRate",
                        "weekday": "Tuesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.0000152918415535942,
                        "binCatValue": null,
                        "binLowerBound": 0.75,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "packetFailureRate",
                        "weekday": "Tuesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.0000149780554752031,
                        "binCatValue": null,
                        "binLowerBound": 0.8,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "packetFailureRate",
                        "weekday": "Tuesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.0000183669451218272,
                        "binCatValue": null,
                        "binLowerBound": 0.85,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "packetFailureRate",
                        "weekday": "Tuesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.000029527269976605,
                        "binCatValue": null,
                        "binLowerBound": 0.9,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "packetFailureRate",
                        "weekday": "Tuesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.000107471731848961,
                        "binCatValue": null,
                        "binLowerBound": 0.95,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "packetFailureRate",
                        "weekday": "Tuesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz and 5 GHz",
                        "binAvgFrequency": 1,
                        "binCatValue": null,
                        "binLowerBound": 0,
                        "lastTimestamp": "2019-01-08T00:00:00",
                        "variable": "packetFailureRate",
                        "weekday": "Tuesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.998086480202285,
                        "binCatValue": null,
                        "binLowerBound": 0,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "packetFailureRate",
                        "weekday": "Tuesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.000712716126566238,
                        "binCatValue": null,
                        "binLowerBound": 0.05,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "packetFailureRate",
                        "weekday": "Tuesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.000240578419315224,
                        "binCatValue": null,
                        "binLowerBound": 0.1,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "packetFailureRate",
                        "weekday": "Tuesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.000161996293925281,
                        "binCatValue": null,
                        "binLowerBound": 0.15,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "packetFailureRate",
                        "weekday": "Tuesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.000120667992700789,
                        "binCatValue": null,
                        "binLowerBound": 0.2,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "packetFailureRate",
                        "weekday": "Tuesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.0000629598842037715,
                        "binCatValue": null,
                        "binLowerBound": 0.25,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "packetFailureRate",
                        "weekday": "Tuesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.0000541762125538794,
                        "binCatValue": null,
                        "binLowerBound": 0.3,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "packetFailureRate",
                        "weekday": "Tuesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.0000386665825427065,
                        "binCatValue": null,
                        "binLowerBound": 0.35,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "packetFailureRate",
                        "weekday": "Tuesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.0000363426973859169,
                        "binCatValue": null,
                        "binLowerBound": 0.4,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "packetFailureRate",
                        "weekday": "Tuesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.0000331895844859556,
                        "binCatValue": null,
                        "binLowerBound": 0.45,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "packetFailureRate",
                        "weekday": "Tuesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.0000240168924133411,
                        "binCatValue": null,
                        "binLowerBound": 0.5,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "packetFailureRate",
                        "weekday": "Tuesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.0000111894558430443,
                        "binCatValue": null,
                        "binLowerBound": 0.55,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "packetFailureRate",
                        "weekday": "Tuesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.0000105035514135072,
                        "binCatValue": null,
                        "binLowerBound": 0.6,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "packetFailureRate",
                        "weekday": "Tuesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.0000115682388862214,
                        "binCatValue": null,
                        "binLowerBound": 0.65,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "packetFailureRate",
                        "weekday": "Tuesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.00000951052559761036,
                        "binCatValue": null,
                        "binLowerBound": 0.7,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "packetFailureRate",
                        "weekday": "Tuesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.0000103192785816913,
                        "binCatValue": null,
                        "binLowerBound": 0.75,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "packetFailureRate",
                        "weekday": "Tuesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.0000102373795453287,
                        "binCatValue": null,
                        "binLowerBound": 0.8,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "packetFailureRate",
                        "weekday": "Tuesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.0000131038458180207,
                        "binCatValue": null,
                        "binLowerBound": 0.85,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "packetFailureRate",
                        "weekday": "Tuesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.0000146292153702747,
                        "binCatValue": null,
                        "binLowerBound": 0.9,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "packetFailureRate",
                        "weekday": "Tuesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.000337147620566309,
                        "binCatValue": null,
                        "binLowerBound": 0.95,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "packetFailureRate",
                        "weekday": "Tuesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 1,
                        "binCatValue": null,
                        "binLowerBound": 0,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "packetFailureRate",
                        "weekday": "Tuesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 0.05,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "packetFailureRate",
                        "weekday": "Tuesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 0.1,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "packetFailureRate",
                        "weekday": "Tuesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 0.15,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "packetFailureRate",
                        "weekday": "Tuesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 0.2,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "packetFailureRate",
                        "weekday": "Tuesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 0.25,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "packetFailureRate",
                        "weekday": "Tuesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 0.3,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "packetFailureRate",
                        "weekday": "Tuesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 0.35,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "packetFailureRate",
                        "weekday": "Tuesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 0.4,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "packetFailureRate",
                        "weekday": "Tuesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 0.45,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "packetFailureRate",
                        "weekday": "Tuesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 0.5,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "packetFailureRate",
                        "weekday": "Tuesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 0.55,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "packetFailureRate",
                        "weekday": "Tuesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 0.6,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "packetFailureRate",
                        "weekday": "Tuesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 0.65,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "packetFailureRate",
                        "weekday": "Tuesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 0.7,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "packetFailureRate",
                        "weekday": "Tuesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 0.75,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "packetFailureRate",
                        "weekday": "Tuesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 0.8,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "packetFailureRate",
                        "weekday": "Tuesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 0.85,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "packetFailureRate",
                        "weekday": "Tuesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 0.9,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "packetFailureRate",
                        "weekday": "Tuesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 0.95,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "packetFailureRate",
                        "weekday": "Tuesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.993350919886666,
                        "binCatValue": null,
                        "binLowerBound": 0,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "packetFailureRate",
                        "weekday": "Wednesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.00405993818692875,
                        "binCatValue": null,
                        "binLowerBound": 0.05,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "packetFailureRate",
                        "weekday": "Wednesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.00138186719979923,
                        "binCatValue": null,
                        "binLowerBound": 0.1,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "packetFailureRate",
                        "weekday": "Wednesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.000481314415570251,
                        "binCatValue": null,
                        "binLowerBound": 0.15,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "packetFailureRate",
                        "weekday": "Wednesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.000166595944472833,
                        "binCatValue": null,
                        "binLowerBound": 0.2,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "packetFailureRate",
                        "weekday": "Wednesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.0000956423141785569,
                        "binCatValue": null,
                        "binLowerBound": 0.25,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "packetFailureRate",
                        "weekday": "Wednesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.0000670846511285462,
                        "binCatValue": null,
                        "binLowerBound": 0.3,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "packetFailureRate",
                        "weekday": "Wednesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.000049176257465394,
                        "binCatValue": null,
                        "binLowerBound": 0.35,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "packetFailureRate",
                        "weekday": "Wednesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.0000306262261683213,
                        "binCatValue": null,
                        "binLowerBound": 0.4,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "packetFailureRate",
                        "weekday": "Wednesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.0000245354569866289,
                        "binCatValue": null,
                        "binLowerBound": 0.45,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "packetFailureRate",
                        "weekday": "Wednesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.0000375980500115164,
                        "binCatValue": null,
                        "binLowerBound": 0.5,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "packetFailureRate",
                        "weekday": "Wednesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.0000192970124231293,
                        "binCatValue": null,
                        "binLowerBound": 0.55,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "packetFailureRate",
                        "weekday": "Wednesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.0000179275470253588,
                        "binCatValue": null,
                        "binLowerBound": 0.6,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "packetFailureRate",
                        "weekday": "Wednesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.0000134648136312257,
                        "binCatValue": null,
                        "binLowerBound": 0.65,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "packetFailureRate",
                        "weekday": "Wednesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.0000150736960565784,
                        "binCatValue": null,
                        "binLowerBound": 0.7,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "packetFailureRate",
                        "weekday": "Wednesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.0000152748063597475,
                        "binCatValue": null,
                        "binLowerBound": 0.75,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "packetFailureRate",
                        "weekday": "Wednesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.0000139436476863902,
                        "binCatValue": null,
                        "binLowerBound": 0.8,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "packetFailureRate",
                        "weekday": "Wednesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.0000184925712104529,
                        "binCatValue": null,
                        "binLowerBound": 0.85,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "packetFailureRate",
                        "weekday": "Wednesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.0000296206746524759,
                        "binCatValue": null,
                        "binLowerBound": 0.9,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "packetFailureRate",
                        "weekday": "Wednesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.000111606641577741,
                        "binCatValue": null,
                        "binLowerBound": 0.95,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "packetFailureRate",
                        "weekday": "Wednesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz and 5 GHz",
                        "binAvgFrequency": 1,
                        "binCatValue": null,
                        "binLowerBound": 0,
                        "lastTimestamp": "2019-01-09T00:00:00",
                        "variable": "packetFailureRate",
                        "weekday": "Wednesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.99805870960637,
                        "binCatValue": null,
                        "binLowerBound": 0,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "packetFailureRate",
                        "weekday": "Wednesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.000722469901580407,
                        "binCatValue": null,
                        "binLowerBound": 0.05,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "packetFailureRate",
                        "weekday": "Wednesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.000248282566884853,
                        "binCatValue": null,
                        "binLowerBound": 0.1,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "packetFailureRate",
                        "weekday": "Wednesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.000170757811526247,
                        "binCatValue": null,
                        "binLowerBound": 0.15,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "packetFailureRate",
                        "weekday": "Wednesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.000121683363452414,
                        "binCatValue": null,
                        "binLowerBound": 0.2,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "packetFailureRate",
                        "weekday": "Wednesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.0000645029097948215,
                        "binCatValue": null,
                        "binLowerBound": 0.25,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "packetFailureRate",
                        "weekday": "Wednesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.0000533746420601545,
                        "binCatValue": null,
                        "binLowerBound": 0.3,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "packetFailureRate",
                        "weekday": "Wednesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.000038785697603307,
                        "binCatValue": null,
                        "binLowerBound": 0.35,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "packetFailureRate",
                        "weekday": "Wednesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.0000360805864188531,
                        "binCatValue": null,
                        "binLowerBound": 0.4,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "packetFailureRate",
                        "weekday": "Wednesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.0000337859058968682,
                        "binCatValue": null,
                        "binLowerBound": 0.45,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "packetFailureRate",
                        "weekday": "Wednesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.0000255213248298816,
                        "binCatValue": null,
                        "binLowerBound": 0.5,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "packetFailureRate",
                        "weekday": "Wednesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.0000110722999170576,
                        "binCatValue": null,
                        "binLowerBound": 0.55,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "packetFailureRate",
                        "weekday": "Wednesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.0000119211451508,
                        "binCatValue": null,
                        "binLowerBound": 0.6,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "packetFailureRate",
                        "weekday": "Wednesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.0000111189397650654,
                        "binCatValue": null,
                        "binLowerBound": 0.65,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "packetFailureRate",
                        "weekday": "Wednesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.00000986899183845568,
                        "binCatValue": null,
                        "binLowerBound": 0.7,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "packetFailureRate",
                        "weekday": "Wednesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.00000916939411833832,
                        "binCatValue": null,
                        "binLowerBound": 0.75,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "packetFailureRate",
                        "weekday": "Wednesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.00000931864163196335,
                        "binCatValue": null,
                        "binLowerBound": 0.8,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "packetFailureRate",
                        "weekday": "Wednesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.0000125741030229095,
                        "binCatValue": null,
                        "binLowerBound": 0.85,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "packetFailureRate",
                        "weekday": "Wednesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.0000144863367912303,
                        "binCatValue": null,
                        "binLowerBound": 0.9,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "packetFailureRate",
                        "weekday": "Wednesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.000336515831346056,
                        "binCatValue": null,
                        "binLowerBound": 0.95,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "packetFailureRate",
                        "weekday": "Wednesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 1,
                        "binCatValue": null,
                        "binLowerBound": 0,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "packetFailureRate",
                        "weekday": "Wednesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 0.05,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "packetFailureRate",
                        "weekday": "Wednesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 0.1,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "packetFailureRate",
                        "weekday": "Wednesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 0.15,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "packetFailureRate",
                        "weekday": "Wednesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 0.2,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "packetFailureRate",
                        "weekday": "Wednesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 0.25,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "packetFailureRate",
                        "weekday": "Wednesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 0.3,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "packetFailureRate",
                        "weekday": "Wednesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 0.35,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "packetFailureRate",
                        "weekday": "Wednesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 0.4,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "packetFailureRate",
                        "weekday": "Wednesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 0.45,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "packetFailureRate",
                        "weekday": "Wednesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 0.5,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "packetFailureRate",
                        "weekday": "Wednesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 0.55,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "packetFailureRate",
                        "weekday": "Wednesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 0.6,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "packetFailureRate",
                        "weekday": "Wednesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 0.65,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "packetFailureRate",
                        "weekday": "Wednesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 0.7,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "packetFailureRate",
                        "weekday": "Wednesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 0.75,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "packetFailureRate",
                        "weekday": "Wednesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 0.8,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "packetFailureRate",
                        "weekday": "Wednesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 0.85,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "packetFailureRate",
                        "weekday": "Wednesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 0.9,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "packetFailureRate",
                        "weekday": "Wednesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 0.95,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "packetFailureRate",
                        "weekday": "Wednesday"
                    }],
                    "totalCount": 488
                },
                "radioResetCount": {
                    "__typename": "SmartDashboardPeers36SConnection",
                    "nodes": [{
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.999999964948229,
                        "binCatValue": null,
                        "binLowerBound": 0,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "radioResetCount",
                        "weekday": "All"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 1.21919206242082e-8,
                        "binCatValue": null,
                        "binLowerBound": 5,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "radioResetCount",
                        "weekday": "All"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 2.28598511703903e-8,
                        "binCatValue": null,
                        "binLowerBound": 10,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "radioResetCount",
                        "weekday": "All"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 15,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "radioResetCount",
                        "weekday": "All"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 20,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "radioResetCount",
                        "weekday": "All"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 25,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "radioResetCount",
                        "weekday": "All"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 30,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "radioResetCount",
                        "weekday": "All"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz and 5 GHz",
                        "binAvgFrequency": 1,
                        "binCatValue": null,
                        "binLowerBound": 0,
                        "lastTimestamp": "2019-01-09T00:00:00",
                        "variable": "radioResetCount",
                        "weekday": "All"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.999999921912819,
                        "binCatValue": null,
                        "binLowerBound": 0,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "radioResetCount",
                        "weekday": "All"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 4.86203204669045e-8,
                        "binCatValue": null,
                        "binLowerBound": 5,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "radioResetCount",
                        "weekday": "All"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 2.06268026223231e-8,
                        "binCatValue": null,
                        "binLowerBound": 10,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "radioResetCount",
                        "weekday": "All"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 7.36671522225826e-9,
                        "binCatValue": null,
                        "binLowerBound": 15,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "radioResetCount",
                        "weekday": "All"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 1.47334304445165e-9,
                        "binCatValue": null,
                        "binLowerBound": 20,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "radioResetCount",
                        "weekday": "All"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 25,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "radioResetCount",
                        "weekday": "All"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 30,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "radioResetCount",
                        "weekday": "All"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 1,
                        "binCatValue": null,
                        "binLowerBound": 0,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "radioResetCount",
                        "weekday": "All"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 5,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "radioResetCount",
                        "weekday": "All"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 10,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "radioResetCount",
                        "weekday": "All"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 15,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "radioResetCount",
                        "weekday": "All"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 20,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "radioResetCount",
                        "weekday": "All"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 25,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "radioResetCount",
                        "weekday": "All"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 30,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "radioResetCount",
                        "weekday": "All"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 1,
                        "binCatValue": null,
                        "binLowerBound": 0,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "radioResetCount",
                        "weekday": "Friday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 5,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "radioResetCount",
                        "weekday": "Friday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 10,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "radioResetCount",
                        "weekday": "Friday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 15,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "radioResetCount",
                        "weekday": "Friday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 20,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "radioResetCount",
                        "weekday": "Friday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 25,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "radioResetCount",
                        "weekday": "Friday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 30,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "radioResetCount",
                        "weekday": "Friday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz and 5 GHz",
                        "binAvgFrequency": 1,
                        "binCatValue": null,
                        "binLowerBound": 0,
                        "lastTimestamp": "2019-01-04T00:00:00",
                        "variable": "radioResetCount",
                        "weekday": "Friday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.999999924872719,
                        "binCatValue": null,
                        "binLowerBound": 0,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "radioResetCount",
                        "weekday": "Friday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 3.75636406721859e-8,
                        "binCatValue": null,
                        "binLowerBound": 5,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "radioResetCount",
                        "weekday": "Friday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 2.81727305041395e-8,
                        "binCatValue": null,
                        "binLowerBound": 10,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "radioResetCount",
                        "weekday": "Friday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 15,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "radioResetCount",
                        "weekday": "Friday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 9.39091016804649e-9,
                        "binCatValue": null,
                        "binLowerBound": 20,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "radioResetCount",
                        "weekday": "Friday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 25,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "radioResetCount",
                        "weekday": "Friday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 30,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "radioResetCount",
                        "weekday": "Friday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 1,
                        "binCatValue": null,
                        "binLowerBound": 0,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "radioResetCount",
                        "weekday": "Friday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 5,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "radioResetCount",
                        "weekday": "Friday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 10,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "radioResetCount",
                        "weekday": "Friday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 15,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "radioResetCount",
                        "weekday": "Friday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 20,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "radioResetCount",
                        "weekday": "Friday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 25,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "radioResetCount",
                        "weekday": "Friday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 30,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "radioResetCount",
                        "weekday": "Friday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.999999812320294,
                        "binCatValue": null,
                        "binLowerBound": 0,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "radioResetCount",
                        "weekday": "Monday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 5.21332514646655e-8,
                        "binCatValue": null,
                        "binLowerBound": 5,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "radioResetCount",
                        "weekday": "Monday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 1.3554645380813e-7,
                        "binCatValue": null,
                        "binLowerBound": 10,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "radioResetCount",
                        "weekday": "Monday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 15,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "radioResetCount",
                        "weekday": "Monday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 20,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "radioResetCount",
                        "weekday": "Monday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 25,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "radioResetCount",
                        "weekday": "Monday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 30,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "radioResetCount",
                        "weekday": "Monday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz and 5 GHz",
                        "binAvgFrequency": 1,
                        "binCatValue": null,
                        "binLowerBound": 0,
                        "lastTimestamp": "2019-01-07T00:00:00",
                        "variable": "radioResetCount",
                        "weekday": "Monday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.999999877877973,
                        "binCatValue": null,
                        "binLowerBound": 0,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "radioResetCount",
                        "weekday": "Monday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 7.12378488074764e-8,
                        "binCatValue": null,
                        "binLowerBound": 5,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "radioResetCount",
                        "weekday": "Monday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 3.05305066317756e-8,
                        "binCatValue": null,
                        "binLowerBound": 10,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "radioResetCount",
                        "weekday": "Monday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 2.03536710878504e-8,
                        "binCatValue": null,
                        "binLowerBound": 15,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "radioResetCount",
                        "weekday": "Monday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 20,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "radioResetCount",
                        "weekday": "Monday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 25,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "radioResetCount",
                        "weekday": "Monday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 30,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "radioResetCount",
                        "weekday": "Monday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 1,
                        "binCatValue": null,
                        "binLowerBound": 0,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "radioResetCount",
                        "weekday": "Monday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 5,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "radioResetCount",
                        "weekday": "Monday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 10,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "radioResetCount",
                        "weekday": "Monday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 15,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "radioResetCount",
                        "weekday": "Monday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 20,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "radioResetCount",
                        "weekday": "Monday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 25,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "radioResetCount",
                        "weekday": "Monday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 30,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "radioResetCount",
                        "weekday": "Monday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.999999980054686,
                        "binCatValue": null,
                        "binLowerBound": 0,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "radioResetCount",
                        "weekday": "Saturday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 1.99453143360597e-8,
                        "binCatValue": null,
                        "binLowerBound": 5,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "radioResetCount",
                        "weekday": "Saturday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 10,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "radioResetCount",
                        "weekday": "Saturday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 15,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "radioResetCount",
                        "weekday": "Saturday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 20,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "radioResetCount",
                        "weekday": "Saturday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 25,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "radioResetCount",
                        "weekday": "Saturday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 30,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "radioResetCount",
                        "weekday": "Saturday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz and 5 GHz",
                        "binAvgFrequency": 1,
                        "binCatValue": null,
                        "binLowerBound": 0,
                        "lastTimestamp": "2019-01-05T00:00:00",
                        "variable": "radioResetCount",
                        "weekday": "Saturday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.999999990398567,
                        "binCatValue": null,
                        "binLowerBound": 0,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "radioResetCount",
                        "weekday": "Saturday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 5,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "radioResetCount",
                        "weekday": "Saturday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 10,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "radioResetCount",
                        "weekday": "Saturday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 9.60143354779671e-9,
                        "binCatValue": null,
                        "binLowerBound": 15,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "radioResetCount",
                        "weekday": "Saturday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 20,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "radioResetCount",
                        "weekday": "Saturday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 25,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "radioResetCount",
                        "weekday": "Saturday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 30,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "radioResetCount",
                        "weekday": "Saturday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 1,
                        "binCatValue": null,
                        "binLowerBound": 0,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "radioResetCount",
                        "weekday": "Saturday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 5,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "radioResetCount",
                        "weekday": "Saturday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 10,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "radioResetCount",
                        "weekday": "Saturday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 15,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "radioResetCount",
                        "weekday": "Saturday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 20,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "radioResetCount",
                        "weekday": "Saturday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 25,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "radioResetCount",
                        "weekday": "Saturday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 30,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "radioResetCount",
                        "weekday": "Saturday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 1,
                        "binCatValue": null,
                        "binLowerBound": 0,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "radioResetCount",
                        "weekday": "Sunday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 5,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "radioResetCount",
                        "weekday": "Sunday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 10,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "radioResetCount",
                        "weekday": "Sunday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 15,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "radioResetCount",
                        "weekday": "Sunday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 20,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "radioResetCount",
                        "weekday": "Sunday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 25,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "radioResetCount",
                        "weekday": "Sunday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 30,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "radioResetCount",
                        "weekday": "Sunday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz and 5 GHz",
                        "binAvgFrequency": 1,
                        "binCatValue": null,
                        "binLowerBound": 0,
                        "lastTimestamp": "2019-01-06T00:00:00",
                        "variable": "radioResetCount",
                        "weekday": "Sunday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.999999982596258,
                        "binCatValue": null,
                        "binLowerBound": 0,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "radioResetCount",
                        "weekday": "Sunday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 5,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "radioResetCount",
                        "weekday": "Sunday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 1.7403741818414e-8,
                        "binCatValue": null,
                        "binLowerBound": 10,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "radioResetCount",
                        "weekday": "Sunday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 15,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "radioResetCount",
                        "weekday": "Sunday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 20,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "radioResetCount",
                        "weekday": "Sunday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 25,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "radioResetCount",
                        "weekday": "Sunday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 30,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "radioResetCount",
                        "weekday": "Sunday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 1,
                        "binCatValue": null,
                        "binLowerBound": 0,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "radioResetCount",
                        "weekday": "Sunday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 5,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "radioResetCount",
                        "weekday": "Sunday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 10,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "radioResetCount",
                        "weekday": "Sunday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 15,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "radioResetCount",
                        "weekday": "Sunday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 20,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "radioResetCount",
                        "weekday": "Sunday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 25,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "radioResetCount",
                        "weekday": "Sunday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 30,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "radioResetCount",
                        "weekday": "Sunday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.999999971120664,
                        "binCatValue": null,
                        "binLowerBound": 0,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "radioResetCount",
                        "weekday": "Thursday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 9.62644503047675e-9,
                        "binCatValue": null,
                        "binLowerBound": 5,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "radioResetCount",
                        "weekday": "Thursday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 1.92528900609535e-8,
                        "binCatValue": null,
                        "binLowerBound": 10,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "radioResetCount",
                        "weekday": "Thursday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 15,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "radioResetCount",
                        "weekday": "Thursday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 20,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "radioResetCount",
                        "weekday": "Thursday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 25,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "radioResetCount",
                        "weekday": "Thursday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 30,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "radioResetCount",
                        "weekday": "Thursday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz and 5 GHz",
                        "binAvgFrequency": 1,
                        "binCatValue": null,
                        "binLowerBound": 0,
                        "lastTimestamp": "2019-01-03T00:00:00",
                        "variable": "radioResetCount",
                        "weekday": "Thursday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.999999888355996,
                        "binCatValue": null,
                        "binLowerBound": 0,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "radioResetCount",
                        "weekday": "Thursday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 7.44293363229117e-8,
                        "binCatValue": null,
                        "binLowerBound": 5,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "radioResetCount",
                        "weekday": "Thursday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 2.79110011210919e-8,
                        "binCatValue": null,
                        "binLowerBound": 10,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "radioResetCount",
                        "weekday": "Thursday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 9.30366704036396e-9,
                        "binCatValue": null,
                        "binLowerBound": 15,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "radioResetCount",
                        "weekday": "Thursday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 20,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "radioResetCount",
                        "weekday": "Thursday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 25,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "radioResetCount",
                        "weekday": "Thursday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 30,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "radioResetCount",
                        "weekday": "Thursday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 1,
                        "binCatValue": null,
                        "binLowerBound": 0,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "radioResetCount",
                        "weekday": "Thursday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 5,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "radioResetCount",
                        "weekday": "Thursday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 10,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "radioResetCount",
                        "weekday": "Thursday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 15,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "radioResetCount",
                        "weekday": "Thursday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 20,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "radioResetCount",
                        "weekday": "Thursday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 25,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "radioResetCount",
                        "weekday": "Thursday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 30,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "radioResetCount",
                        "weekday": "Thursday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 1,
                        "binCatValue": null,
                        "binLowerBound": 0,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "radioResetCount",
                        "weekday": "Tuesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 5,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "radioResetCount",
                        "weekday": "Tuesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 10,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "radioResetCount",
                        "weekday": "Tuesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 15,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "radioResetCount",
                        "weekday": "Tuesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 20,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "radioResetCount",
                        "weekday": "Tuesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 25,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "radioResetCount",
                        "weekday": "Tuesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 30,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "radioResetCount",
                        "weekday": "Tuesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz and 5 GHz",
                        "binAvgFrequency": 1,
                        "binCatValue": null,
                        "binLowerBound": 0,
                        "lastTimestamp": "2019-01-08T00:00:00",
                        "variable": "radioResetCount",
                        "weekday": "Tuesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.999999948813102,
                        "binCatValue": null,
                        "binLowerBound": 0,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "radioResetCount",
                        "weekday": "Tuesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 2.04747590906574e-8,
                        "binCatValue": null,
                        "binLowerBound": 5,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "radioResetCount",
                        "weekday": "Tuesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 3.07121386359861e-8,
                        "binCatValue": null,
                        "binLowerBound": 10,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "radioResetCount",
                        "weekday": "Tuesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 15,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "radioResetCount",
                        "weekday": "Tuesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 20,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "radioResetCount",
                        "weekday": "Tuesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 25,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "radioResetCount",
                        "weekday": "Tuesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 30,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "radioResetCount",
                        "weekday": "Tuesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 1,
                        "binCatValue": null,
                        "binLowerBound": 0,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "radioResetCount",
                        "weekday": "Tuesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 5,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "radioResetCount",
                        "weekday": "Tuesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 10,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "radioResetCount",
                        "weekday": "Tuesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 15,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "radioResetCount",
                        "weekday": "Tuesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 20,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "radioResetCount",
                        "weekday": "Tuesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 25,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "radioResetCount",
                        "weekday": "Tuesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 30,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "radioResetCount",
                        "weekday": "Tuesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 1,
                        "binCatValue": null,
                        "binLowerBound": 0,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "radioResetCount",
                        "weekday": "Wednesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 5,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "radioResetCount",
                        "weekday": "Wednesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 10,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "radioResetCount",
                        "weekday": "Wednesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 15,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "radioResetCount",
                        "weekday": "Wednesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 20,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "radioResetCount",
                        "weekday": "Wednesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 25,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "radioResetCount",
                        "weekday": "Wednesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 30,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "radioResetCount",
                        "weekday": "Wednesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz and 5 GHz",
                        "binAvgFrequency": 1,
                        "binCatValue": null,
                        "binLowerBound": 0,
                        "lastTimestamp": "2019-01-09T00:00:00",
                        "variable": "radioResetCount",
                        "weekday": "Wednesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.999999869408425,
                        "binCatValue": null,
                        "binLowerBound": 0,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "radioResetCount",
                        "weekday": "Wednesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 1.11935635218779e-7,
                        "binCatValue": null,
                        "binLowerBound": 5,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "radioResetCount",
                        "weekday": "Wednesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 9.32796960156492e-9,
                        "binCatValue": null,
                        "binLowerBound": 10,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "radioResetCount",
                        "weekday": "Wednesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 9.32796960156492e-9,
                        "binCatValue": null,
                        "binLowerBound": 15,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "radioResetCount",
                        "weekday": "Wednesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 20,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "radioResetCount",
                        "weekday": "Wednesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 25,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "radioResetCount",
                        "weekday": "Wednesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 30,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "radioResetCount",
                        "weekday": "Wednesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 1,
                        "binCatValue": null,
                        "binLowerBound": 0,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "radioResetCount",
                        "weekday": "Wednesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 5,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "radioResetCount",
                        "weekday": "Wednesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 10,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "radioResetCount",
                        "weekday": "Wednesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 15,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "radioResetCount",
                        "weekday": "Wednesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 20,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "radioResetCount",
                        "weekday": "Wednesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 25,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "radioResetCount",
                        "weekday": "Wednesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": 30,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "radioResetCount",
                        "weekday": "Wednesday"
                    }],
                    "totalCount": 176
                },
                "rssi": {
                    "__typename": "SmartDashboardPeers36SConnection",
                    "nodes": [{
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.00306054462511777,
                        "binCatValue": null,
                        "binLowerBound": -95,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "rssi",
                        "weekday": "All"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.0192408854478235,
                        "binCatValue": null,
                        "binLowerBound": -90,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "rssi",
                        "weekday": "All"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.0442189879174344,
                        "binCatValue": null,
                        "binLowerBound": -85,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "rssi",
                        "weekday": "All"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.0558696261751515,
                        "binCatValue": null,
                        "binLowerBound": -80,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "rssi",
                        "weekday": "All"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.0573610927183753,
                        "binCatValue": null,
                        "binLowerBound": -75,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "rssi",
                        "weekday": "All"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.0761247355203299,
                        "binCatValue": null,
                        "binLowerBound": -70,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "rssi",
                        "weekday": "All"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.109443245491686,
                        "binCatValue": null,
                        "binLowerBound": -65,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "rssi",
                        "weekday": "All"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.141904185092669,
                        "binCatValue": null,
                        "binLowerBound": -60,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "rssi",
                        "weekday": "All"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.142796766377988,
                        "binCatValue": null,
                        "binLowerBound": -55,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "rssi",
                        "weekday": "All"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.131206653893826,
                        "binCatValue": null,
                        "binLowerBound": -50,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "rssi",
                        "weekday": "All"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.102697112843233,
                        "binCatValue": null,
                        "binLowerBound": -45,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "rssi",
                        "weekday": "All"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.0640606857123878,
                        "binCatValue": null,
                        "binLowerBound": -40,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "rssi",
                        "weekday": "All"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.0304354197170896,
                        "binCatValue": null,
                        "binLowerBound": -35,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "rssi",
                        "weekday": "All"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.0112561297615506,
                        "binCatValue": null,
                        "binLowerBound": -30,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "rssi",
                        "weekday": "All"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.00287538059194502,
                        "binCatValue": null,
                        "binLowerBound": -25,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "rssi",
                        "weekday": "All"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.000765590224683802,
                        "binCatValue": null,
                        "binLowerBound": -20,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "rssi",
                        "weekday": "All"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.000325943949537693,
                        "binCatValue": null,
                        "binLowerBound": -15,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "rssi",
                        "weekday": "All"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.00635701393916914,
                        "binCatValue": null,
                        "binLowerBound": -10,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "rssi",
                        "weekday": "All"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz and 5 GHz",
                        "binAvgFrequency": 0.00330058914117389,
                        "binCatValue": null,
                        "binLowerBound": -95,
                        "lastTimestamp": "2019-01-09T00:00:00",
                        "variable": "rssi",
                        "weekday": "All"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz and 5 GHz",
                        "binAvgFrequency": 0.0272286521349924,
                        "binCatValue": null,
                        "binLowerBound": -90,
                        "lastTimestamp": "2019-01-09T00:00:00",
                        "variable": "rssi",
                        "weekday": "All"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz and 5 GHz",
                        "binAvgFrequency": 0.030649118128484,
                        "binCatValue": null,
                        "binLowerBound": -85,
                        "lastTimestamp": "2019-01-09T00:00:00",
                        "variable": "rssi",
                        "weekday": "All"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz and 5 GHz",
                        "binAvgFrequency": 0.031559415378843,
                        "binCatValue": null,
                        "binLowerBound": -80,
                        "lastTimestamp": "2019-01-09T00:00:00",
                        "variable": "rssi",
                        "weekday": "All"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz and 5 GHz",
                        "binAvgFrequency": 0.0402214208015068,
                        "binCatValue": null,
                        "binLowerBound": -75,
                        "lastTimestamp": "2019-01-09T00:00:00",
                        "variable": "rssi",
                        "weekday": "All"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz and 5 GHz",
                        "binAvgFrequency": 0.0628316183604494,
                        "binCatValue": null,
                        "binLowerBound": -70,
                        "lastTimestamp": "2019-01-09T00:00:00",
                        "variable": "rssi",
                        "weekday": "All"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz and 5 GHz",
                        "binAvgFrequency": 0.101042877495406,
                        "binCatValue": null,
                        "binLowerBound": -65,
                        "lastTimestamp": "2019-01-09T00:00:00",
                        "variable": "rssi",
                        "weekday": "All"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz and 5 GHz",
                        "binAvgFrequency": 0.168482035881148,
                        "binCatValue": null,
                        "binLowerBound": -60,
                        "lastTimestamp": "2019-01-09T00:00:00",
                        "variable": "rssi",
                        "weekday": "All"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz and 5 GHz",
                        "binAvgFrequency": 0.175033371235677,
                        "binCatValue": null,
                        "binLowerBound": -55,
                        "lastTimestamp": "2019-01-09T00:00:00",
                        "variable": "rssi",
                        "weekday": "All"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz and 5 GHz",
                        "binAvgFrequency": 0.155352201781809,
                        "binCatValue": null,
                        "binLowerBound": -50,
                        "lastTimestamp": "2019-01-09T00:00:00",
                        "variable": "rssi",
                        "weekday": "All"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz and 5 GHz",
                        "binAvgFrequency": 0.117827653582111,
                        "binCatValue": null,
                        "binLowerBound": -45,
                        "lastTimestamp": "2019-01-09T00:00:00",
                        "variable": "rssi",
                        "weekday": "All"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz and 5 GHz",
                        "binAvgFrequency": 0.0701991694297376,
                        "binCatValue": null,
                        "binLowerBound": -40,
                        "lastTimestamp": "2019-01-09T00:00:00",
                        "variable": "rssi",
                        "weekday": "All"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz and 5 GHz",
                        "binAvgFrequency": 0.0143339956409852,
                        "binCatValue": null,
                        "binLowerBound": -35,
                        "lastTimestamp": "2019-01-09T00:00:00",
                        "variable": "rssi",
                        "weekday": "All"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz and 5 GHz",
                        "binAvgFrequency": 0.00179800460292261,
                        "binCatValue": null,
                        "binLowerBound": -30,
                        "lastTimestamp": "2019-01-09T00:00:00",
                        "variable": "rssi",
                        "weekday": "All"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz and 5 GHz",
                        "binAvgFrequency": 0.000130181865894212,
                        "binCatValue": null,
                        "binLowerBound": -25,
                        "lastTimestamp": "2019-01-09T00:00:00",
                        "variable": "rssi",
                        "weekday": "All"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz and 5 GHz",
                        "binAvgFrequency": 0.00000814371975346491,
                        "binCatValue": null,
                        "binLowerBound": -20,
                        "lastTimestamp": "2019-01-09T00:00:00",
                        "variable": "rssi",
                        "weekday": "All"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz and 5 GHz",
                        "binAvgFrequency": 0.00000281605445273113,
                        "binCatValue": null,
                        "binLowerBound": -15,
                        "lastTimestamp": "2019-01-02T00:00:00",
                        "variable": "rssi",
                        "weekday": "All"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz and 5 GHz",
                        "binAvgFrequency": 0.00000717035478130133,
                        "binCatValue": null,
                        "binLowerBound": -10,
                        "lastTimestamp": "2019-01-08T00:00:00",
                        "variable": "rssi",
                        "weekday": "All"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.00108371458775881,
                        "binCatValue": null,
                        "binLowerBound": -95,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "rssi",
                        "weekday": "All"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.00952870475762158,
                        "binCatValue": null,
                        "binLowerBound": -90,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "rssi",
                        "weekday": "All"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.0114425925017331,
                        "binCatValue": null,
                        "binLowerBound": -85,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "rssi",
                        "weekday": "All"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.0183289267380118,
                        "binCatValue": null,
                        "binLowerBound": -80,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "rssi",
                        "weekday": "All"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.0370633489422422,
                        "binCatValue": null,
                        "binLowerBound": -75,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "rssi",
                        "weekday": "All"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.0762613046239527,
                        "binCatValue": null,
                        "binLowerBound": -70,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "rssi",
                        "weekday": "All"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.134420479343588,
                        "binCatValue": null,
                        "binLowerBound": -65,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "rssi",
                        "weekday": "All"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.18727906217559,
                        "binCatValue": null,
                        "binLowerBound": -60,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "rssi",
                        "weekday": "All"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.200156409757266,
                        "binCatValue": null,
                        "binLowerBound": -55,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "rssi",
                        "weekday": "All"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.151206018083643,
                        "binCatValue": null,
                        "binLowerBound": -50,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "rssi",
                        "weekday": "All"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.106741788736295,
                        "binCatValue": null,
                        "binLowerBound": -45,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "rssi",
                        "weekday": "All"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.0484683217149984,
                        "binCatValue": null,
                        "binLowerBound": -40,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "rssi",
                        "weekday": "All"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.0140814655671489,
                        "binCatValue": null,
                        "binLowerBound": -35,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "rssi",
                        "weekday": "All"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.00317671391727697,
                        "binCatValue": null,
                        "binLowerBound": -30,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "rssi",
                        "weekday": "All"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.000491794930235199,
                        "binCatValue": null,
                        "binLowerBound": -25,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "rssi",
                        "weekday": "All"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.000110061624243771,
                        "binCatValue": null,
                        "binLowerBound": -20,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "rssi",
                        "weekday": "All"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.0000359342365174221,
                        "binCatValue": null,
                        "binLowerBound": -15,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "rssi",
                        "weekday": "All"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.000123357761877509,
                        "binCatValue": null,
                        "binLowerBound": -10,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "rssi",
                        "weekday": "All"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": -95,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "rssi",
                        "weekday": "All"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": -90,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "rssi",
                        "weekday": "All"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": -85,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "rssi",
                        "weekday": "All"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": -80,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "rssi",
                        "weekday": "All"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": -75,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "rssi",
                        "weekday": "All"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": -70,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "rssi",
                        "weekday": "All"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": -65,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "rssi",
                        "weekday": "All"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": -60,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "rssi",
                        "weekday": "All"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": -55,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "rssi",
                        "weekday": "All"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": -50,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "rssi",
                        "weekday": "All"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": -45,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "rssi",
                        "weekday": "All"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": -40,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "rssi",
                        "weekday": "All"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": -35,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "rssi",
                        "weekday": "All"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": -30,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "rssi",
                        "weekday": "All"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": -25,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "rssi",
                        "weekday": "All"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": -20,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "rssi",
                        "weekday": "All"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": -15,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "rssi",
                        "weekday": "All"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": -10,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "rssi",
                        "weekday": "All"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.00331131375711101,
                        "binCatValue": null,
                        "binLowerBound": -95,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "rssi",
                        "weekday": "Friday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.0207060203829743,
                        "binCatValue": null,
                        "binLowerBound": -90,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "rssi",
                        "weekday": "Friday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.0474060129696936,
                        "binCatValue": null,
                        "binLowerBound": -85,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "rssi",
                        "weekday": "Friday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.0589701004430098,
                        "binCatValue": null,
                        "binLowerBound": -80,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "rssi",
                        "weekday": "Friday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.0583619360365212,
                        "binCatValue": null,
                        "binLowerBound": -75,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "rssi",
                        "weekday": "Friday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.0759978167018902,
                        "binCatValue": null,
                        "binLowerBound": -70,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "rssi",
                        "weekday": "Friday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.108764407641922,
                        "binCatValue": null,
                        "binLowerBound": -65,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "rssi",
                        "weekday": "Friday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.14048247385452,
                        "binCatValue": null,
                        "binLowerBound": -60,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "rssi",
                        "weekday": "Friday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.140992446103079,
                        "binCatValue": null,
                        "binLowerBound": -55,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "rssi",
                        "weekday": "Friday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.129411451593045,
                        "binCatValue": null,
                        "binLowerBound": -50,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "rssi",
                        "weekday": "Friday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.101109455822809,
                        "binCatValue": null,
                        "binLowerBound": -45,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "rssi",
                        "weekday": "Friday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.0628405945518427,
                        "binCatValue": null,
                        "binLowerBound": -40,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "rssi",
                        "weekday": "Friday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.0300026346730854,
                        "binCatValue": null,
                        "binLowerBound": -35,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "rssi",
                        "weekday": "Friday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.0111944946980312,
                        "binCatValue": null,
                        "binLowerBound": -30,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "rssi",
                        "weekday": "Friday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.00290157640733227,
                        "binCatValue": null,
                        "binLowerBound": -25,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "rssi",
                        "weekday": "Friday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.000762459371487772,
                        "binCatValue": null,
                        "binLowerBound": -20,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "rssi",
                        "weekday": "Friday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.000332279192882721,
                        "binCatValue": null,
                        "binLowerBound": -15,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "rssi",
                        "weekday": "Friday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.00645252579876245,
                        "binCatValue": null,
                        "binLowerBound": -10,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "rssi",
                        "weekday": "Friday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz and 5 GHz",
                        "binAvgFrequency": 0.00268467379164062,
                        "binCatValue": null,
                        "binLowerBound": -95,
                        "lastTimestamp": "2019-01-04T00:00:00",
                        "variable": "rssi",
                        "weekday": "Friday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz and 5 GHz",
                        "binAvgFrequency": 0.0271490198891291,
                        "binCatValue": null,
                        "binLowerBound": -90,
                        "lastTimestamp": "2019-01-04T00:00:00",
                        "variable": "rssi",
                        "weekday": "Friday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz and 5 GHz",
                        "binAvgFrequency": 0.0298388171040362,
                        "binCatValue": null,
                        "binLowerBound": -85,
                        "lastTimestamp": "2019-01-04T00:00:00",
                        "variable": "rssi",
                        "weekday": "Friday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz and 5 GHz",
                        "binAvgFrequency": 0.0312938693117193,
                        "binCatValue": null,
                        "binLowerBound": -80,
                        "lastTimestamp": "2019-01-04T00:00:00",
                        "variable": "rssi",
                        "weekday": "Friday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz and 5 GHz",
                        "binAvgFrequency": 0.0398756032830896,
                        "binCatValue": null,
                        "binLowerBound": -75,
                        "lastTimestamp": "2019-01-04T00:00:00",
                        "variable": "rssi",
                        "weekday": "Friday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz and 5 GHz",
                        "binAvgFrequency": 0.0630488467174227,
                        "binCatValue": null,
                        "binLowerBound": -70,
                        "lastTimestamp": "2019-01-04T00:00:00",
                        "variable": "rssi",
                        "weekday": "Friday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz and 5 GHz",
                        "binAvgFrequency": 0.103329200438565,
                        "binCatValue": null,
                        "binLowerBound": -65,
                        "lastTimestamp": "2019-01-04T00:00:00",
                        "variable": "rssi",
                        "weekday": "Friday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz and 5 GHz",
                        "binAvgFrequency": 0.170405057843449,
                        "binCatValue": null,
                        "binLowerBound": -60,
                        "lastTimestamp": "2019-01-04T00:00:00",
                        "variable": "rssi",
                        "weekday": "Friday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz and 5 GHz",
                        "binAvgFrequency": 0.173274174872683,
                        "binCatValue": null,
                        "binLowerBound": -55,
                        "lastTimestamp": "2019-01-04T00:00:00",
                        "variable": "rssi",
                        "weekday": "Friday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz and 5 GHz",
                        "binAvgFrequency": 0.156889467266449,
                        "binCatValue": null,
                        "binLowerBound": -50,
                        "lastTimestamp": "2019-01-04T00:00:00",
                        "variable": "rssi",
                        "weekday": "Friday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz and 5 GHz",
                        "binAvgFrequency": 0.115738131590003,
                        "binCatValue": null,
                        "binLowerBound": -45,
                        "lastTimestamp": "2019-01-04T00:00:00",
                        "variable": "rssi",
                        "weekday": "Friday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz and 5 GHz",
                        "binAvgFrequency": 0.0684745519566353,
                        "binCatValue": null,
                        "binLowerBound": -40,
                        "lastTimestamp": "2019-01-04T00:00:00",
                        "variable": "rssi",
                        "weekday": "Friday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz and 5 GHz",
                        "binAvgFrequency": 0.0159287229355166,
                        "binCatValue": null,
                        "binLowerBound": -35,
                        "lastTimestamp": "2019-01-04T00:00:00",
                        "variable": "rssi",
                        "weekday": "Friday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz and 5 GHz",
                        "binAvgFrequency": 0.00182393868287035,
                        "binCatValue": null,
                        "binLowerBound": -30,
                        "lastTimestamp": "2019-01-04T00:00:00",
                        "variable": "rssi",
                        "weekday": "Friday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz and 5 GHz",
                        "binAvgFrequency": 0.000225430623725548,
                        "binCatValue": null,
                        "binLowerBound": -25,
                        "lastTimestamp": "2019-01-04T00:00:00",
                        "variable": "rssi",
                        "weekday": "Friday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz and 5 GHz",
                        "binAvgFrequency": 0.0000102468465329795,
                        "binCatValue": null,
                        "binLowerBound": -20,
                        "lastTimestamp": "2019-01-04T00:00:00",
                        "variable": "rssi",
                        "weekday": "Friday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz and 5 GHz",
                        "binAvgFrequency": 0.0000102468465329795,
                        "binCatValue": null,
                        "binLowerBound": -10,
                        "lastTimestamp": "2019-01-04T00:00:00",
                        "variable": "rssi",
                        "weekday": "Friday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.00119950912525082,
                        "binCatValue": null,
                        "binLowerBound": -95,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "rssi",
                        "weekday": "Friday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.0103035375236699,
                        "binCatValue": null,
                        "binLowerBound": -90,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "rssi",
                        "weekday": "Friday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.0120490195582435,
                        "binCatValue": null,
                        "binLowerBound": -85,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "rssi",
                        "weekday": "Friday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.0188145150808891,
                        "binCatValue": null,
                        "binLowerBound": -80,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "rssi",
                        "weekday": "Friday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.0373763089132894,
                        "binCatValue": null,
                        "binLowerBound": -75,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "rssi",
                        "weekday": "Friday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.0761756282147237,
                        "binCatValue": null,
                        "binLowerBound": -70,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "rssi",
                        "weekday": "Friday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.133930788249397,
                        "binCatValue": null,
                        "binLowerBound": -65,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "rssi",
                        "weekday": "Friday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.186864511284308,
                        "binCatValue": null,
                        "binLowerBound": -60,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "rssi",
                        "weekday": "Friday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.199640204331821,
                        "binCatValue": null,
                        "binLowerBound": -55,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "rssi",
                        "weekday": "Friday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.150959361822218,
                        "binCatValue": null,
                        "binLowerBound": -50,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "rssi",
                        "weekday": "Friday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.106628644270117,
                        "binCatValue": null,
                        "binLowerBound": -45,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "rssi",
                        "weekday": "Friday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.0482494215205559,
                        "binCatValue": null,
                        "binLowerBound": -40,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "rssi",
                        "weekday": "Friday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.013889291813605,
                        "binCatValue": null,
                        "binLowerBound": -35,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "rssi",
                        "weekday": "Friday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.00315386310363173,
                        "binCatValue": null,
                        "binLowerBound": -30,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "rssi",
                        "weekday": "Friday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.000487474731896724,
                        "binCatValue": null,
                        "binLowerBound": -25,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "rssi",
                        "weekday": "Friday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.000108169469993054,
                        "binCatValue": null,
                        "binLowerBound": -20,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "rssi",
                        "weekday": "Friday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.0000383658679151439,
                        "binCatValue": null,
                        "binLowerBound": -15,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "rssi",
                        "weekday": "Friday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.000131385118475881,
                        "binCatValue": null,
                        "binLowerBound": -10,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "rssi",
                        "weekday": "Friday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": -95,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "rssi",
                        "weekday": "Friday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": -90,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "rssi",
                        "weekday": "Friday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": -85,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "rssi",
                        "weekday": "Friday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": -80,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "rssi",
                        "weekday": "Friday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": -75,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "rssi",
                        "weekday": "Friday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": -70,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "rssi",
                        "weekday": "Friday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": -65,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "rssi",
                        "weekday": "Friday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": -60,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "rssi",
                        "weekday": "Friday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": -55,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "rssi",
                        "weekday": "Friday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": -50,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "rssi",
                        "weekday": "Friday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": -45,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "rssi",
                        "weekday": "Friday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": -40,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "rssi",
                        "weekday": "Friday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": -35,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "rssi",
                        "weekday": "Friday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": -30,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "rssi",
                        "weekday": "Friday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": -25,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "rssi",
                        "weekday": "Friday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": -20,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "rssi",
                        "weekday": "Friday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": -15,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "rssi",
                        "weekday": "Friday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": -10,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "rssi",
                        "weekday": "Friday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.00296125989869202,
                        "binCatValue": null,
                        "binLowerBound": -95,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "rssi",
                        "weekday": "Monday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.0177953074606608,
                        "binCatValue": null,
                        "binLowerBound": -90,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "rssi",
                        "weekday": "Monday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.04058490201578,
                        "binCatValue": null,
                        "binLowerBound": -85,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "rssi",
                        "weekday": "Monday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.0527234937237376,
                        "binCatValue": null,
                        "binLowerBound": -80,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "rssi",
                        "weekday": "Monday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.0567766911718277,
                        "binCatValue": null,
                        "binLowerBound": -75,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "rssi",
                        "weekday": "Monday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.0771410804555297,
                        "binCatValue": null,
                        "binLowerBound": -70,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "rssi",
                        "weekday": "Monday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.111303289856065,
                        "binCatValue": null,
                        "binLowerBound": -65,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "rssi",
                        "weekday": "Monday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.14413847937814,
                        "binCatValue": null,
                        "binLowerBound": -60,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "rssi",
                        "weekday": "Monday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.144440029885512,
                        "binCatValue": null,
                        "binLowerBound": -55,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "rssi",
                        "weekday": "Monday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.132252161023397,
                        "binCatValue": null,
                        "binLowerBound": -50,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "rssi",
                        "weekday": "Monday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.103198783842821,
                        "binCatValue": null,
                        "binLowerBound": -45,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "rssi",
                        "weekday": "Monday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.0641267506690528,
                        "binCatValue": null,
                        "binLowerBound": -40,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "rssi",
                        "weekday": "Monday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.0304895641427106,
                        "binCatValue": null,
                        "binLowerBound": -35,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "rssi",
                        "weekday": "Monday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.0113786327985856,
                        "binCatValue": null,
                        "binLowerBound": -30,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "rssi",
                        "weekday": "Monday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.00290482529138537,
                        "binCatValue": null,
                        "binLowerBound": -25,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "rssi",
                        "weekday": "Monday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.000779796160988939,
                        "binCatValue": null,
                        "binLowerBound": -20,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "rssi",
                        "weekday": "Monday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.000347311632333462,
                        "binCatValue": null,
                        "binLowerBound": -15,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "rssi",
                        "weekday": "Monday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.00665764059278139,
                        "binCatValue": null,
                        "binLowerBound": -10,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "rssi",
                        "weekday": "Monday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz and 5 GHz",
                        "binAvgFrequency": 0.00373252461966574,
                        "binCatValue": null,
                        "binLowerBound": -95,
                        "lastTimestamp": "2019-01-07T00:00:00",
                        "variable": "rssi",
                        "weekday": "Monday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz and 5 GHz",
                        "binAvgFrequency": 0.0272774232249679,
                        "binCatValue": null,
                        "binLowerBound": -90,
                        "lastTimestamp": "2019-01-07T00:00:00",
                        "variable": "rssi",
                        "weekday": "Monday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz and 5 GHz",
                        "binAvgFrequency": 0.03179311149251,
                        "binCatValue": null,
                        "binLowerBound": -85,
                        "lastTimestamp": "2019-01-07T00:00:00",
                        "variable": "rssi",
                        "weekday": "Monday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz and 5 GHz",
                        "binAvgFrequency": 0.0338871022389593,
                        "binCatValue": null,
                        "binLowerBound": -80,
                        "lastTimestamp": "2019-01-07T00:00:00",
                        "variable": "rssi",
                        "weekday": "Monday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz and 5 GHz",
                        "binAvgFrequency": 0.0435127944500914,
                        "binCatValue": null,
                        "binLowerBound": -75,
                        "lastTimestamp": "2019-01-07T00:00:00",
                        "variable": "rssi",
                        "weekday": "Monday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz and 5 GHz",
                        "binAvgFrequency": 0.0651081154638717,
                        "binCatValue": null,
                        "binLowerBound": -70,
                        "lastTimestamp": "2019-01-07T00:00:00",
                        "variable": "rssi",
                        "weekday": "Monday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz and 5 GHz",
                        "binAvgFrequency": 0.100878143069002,
                        "binCatValue": null,
                        "binLowerBound": -65,
                        "lastTimestamp": "2019-01-07T00:00:00",
                        "variable": "rssi",
                        "weekday": "Monday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz and 5 GHz",
                        "binAvgFrequency": 0.167658118518765,
                        "binCatValue": null,
                        "binLowerBound": -60,
                        "lastTimestamp": "2019-01-07T00:00:00",
                        "variable": "rssi",
                        "weekday": "Monday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz and 5 GHz",
                        "binAvgFrequency": 0.16993540288493,
                        "binCatValue": null,
                        "binLowerBound": -55,
                        "lastTimestamp": "2019-01-07T00:00:00",
                        "variable": "rssi",
                        "weekday": "Monday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz and 5 GHz",
                        "binAvgFrequency": 0.152428085026022,
                        "binCatValue": null,
                        "binLowerBound": -50,
                        "lastTimestamp": "2019-01-07T00:00:00",
                        "variable": "rssi",
                        "weekday": "Monday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz and 5 GHz",
                        "binAvgFrequency": 0.114714034181483,
                        "binCatValue": null,
                        "binLowerBound": -45,
                        "lastTimestamp": "2019-01-07T00:00:00",
                        "variable": "rssi",
                        "weekday": "Monday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz and 5 GHz",
                        "binAvgFrequency": 0.0725953821116536,
                        "binCatValue": null,
                        "binLowerBound": -40,
                        "lastTimestamp": "2019-01-07T00:00:00",
                        "variable": "rssi",
                        "weekday": "Monday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz and 5 GHz",
                        "binAvgFrequency": 0.0141247174223363,
                        "binCatValue": null,
                        "binLowerBound": -35,
                        "lastTimestamp": "2019-01-07T00:00:00",
                        "variable": "rssi",
                        "weekday": "Monday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz and 5 GHz",
                        "binAvgFrequency": 0.00228283871827771,
                        "binCatValue": null,
                        "binLowerBound": -30,
                        "lastTimestamp": "2019-01-07T00:00:00",
                        "variable": "rssi",
                        "weekday": "Monday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz and 5 GHz",
                        "binAvgFrequency": 0.0000666522253511739,
                        "binCatValue": null,
                        "binLowerBound": -25,
                        "lastTimestamp": "2019-01-07T00:00:00",
                        "variable": "rssi",
                        "weekday": "Monday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz and 5 GHz",
                        "binAvgFrequency": 0.00000555435211259783,
                        "binCatValue": null,
                        "binLowerBound": -20,
                        "lastTimestamp": "2019-01-07T00:00:00",
                        "variable": "rssi",
                        "weekday": "Monday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.00101472961659345,
                        "binCatValue": null,
                        "binLowerBound": -95,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "rssi",
                        "weekday": "Monday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.00891477157668908,
                        "binCatValue": null,
                        "binLowerBound": -90,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "rssi",
                        "weekday": "Monday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.0108404120358494,
                        "binCatValue": null,
                        "binLowerBound": -85,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "rssi",
                        "weekday": "Monday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.0175840090874282,
                        "binCatValue": null,
                        "binLowerBound": -80,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "rssi",
                        "weekday": "Monday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.0362859289792301,
                        "binCatValue": null,
                        "binLowerBound": -75,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "rssi",
                        "weekday": "Monday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.0759808787085146,
                        "binCatValue": null,
                        "binLowerBound": -70,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "rssi",
                        "weekday": "Monday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.134539440154881,
                        "binCatValue": null,
                        "binLowerBound": -65,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "rssi",
                        "weekday": "Monday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.187950540577066,
                        "binCatValue": null,
                        "binLowerBound": -60,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "rssi",
                        "weekday": "Monday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.201477142046057,
                        "binCatValue": null,
                        "binLowerBound": -55,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "rssi",
                        "weekday": "Monday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.152436702355846,
                        "binCatValue": null,
                        "binLowerBound": -50,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "rssi",
                        "weekday": "Monday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.107383270895917,
                        "binCatValue": null,
                        "binLowerBound": -45,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "rssi",
                        "weekday": "Monday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.0480676642773614,
                        "binCatValue": null,
                        "binLowerBound": -40,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "rssi",
                        "weekday": "Monday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.0137148797288986,
                        "binCatValue": null,
                        "binLowerBound": -35,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "rssi",
                        "weekday": "Monday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.00305933543936184,
                        "binCatValue": null,
                        "binLowerBound": -30,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "rssi",
                        "weekday": "Monday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.000482088850717011,
                        "binCatValue": null,
                        "binLowerBound": -25,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "rssi",
                        "weekday": "Monday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.000110394773572318,
                        "binCatValue": null,
                        "binLowerBound": -20,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "rssi",
                        "weekday": "Monday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.0000359426094773847,
                        "binCatValue": null,
                        "binLowerBound": -15,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "rssi",
                        "weekday": "Monday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.000121868286539186,
                        "binCatValue": null,
                        "binLowerBound": -10,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "rssi",
                        "weekday": "Monday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": -95,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "rssi",
                        "weekday": "Monday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": -90,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "rssi",
                        "weekday": "Monday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": -85,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "rssi",
                        "weekday": "Monday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": -80,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "rssi",
                        "weekday": "Monday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": -75,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "rssi",
                        "weekday": "Monday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": -70,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "rssi",
                        "weekday": "Monday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": -65,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "rssi",
                        "weekday": "Monday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": -60,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "rssi",
                        "weekday": "Monday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": -55,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "rssi",
                        "weekday": "Monday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": -50,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "rssi",
                        "weekday": "Monday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": -45,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "rssi",
                        "weekday": "Monday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": -40,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "rssi",
                        "weekday": "Monday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": -35,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "rssi",
                        "weekday": "Monday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": -30,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "rssi",
                        "weekday": "Monday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": -25,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "rssi",
                        "weekday": "Monday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": -20,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "rssi",
                        "weekday": "Monday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": -15,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "rssi",
                        "weekday": "Monday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": -10,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "rssi",
                        "weekday": "Monday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.00251175174911637,
                        "binCatValue": null,
                        "binLowerBound": -95,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "rssi",
                        "weekday": "Saturday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.0164374701895112,
                        "binCatValue": null,
                        "binLowerBound": -90,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "rssi",
                        "weekday": "Saturday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.0384552416369245,
                        "binCatValue": null,
                        "binLowerBound": -85,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "rssi",
                        "weekday": "Saturday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.0496383216681298,
                        "binCatValue": null,
                        "binLowerBound": -80,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "rssi",
                        "weekday": "Saturday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.0523191732425756,
                        "binCatValue": null,
                        "binLowerBound": -75,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "rssi",
                        "weekday": "Saturday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.0710643174581963,
                        "binCatValue": null,
                        "binLowerBound": -70,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "rssi",
                        "weekday": "Saturday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.105947293685904,
                        "binCatValue": null,
                        "binLowerBound": -65,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "rssi",
                        "weekday": "Saturday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.142681544812573,
                        "binCatValue": null,
                        "binLowerBound": -60,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "rssi",
                        "weekday": "Saturday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.146482647619681,
                        "binCatValue": null,
                        "binLowerBound": -55,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "rssi",
                        "weekday": "Saturday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.137098501452277,
                        "binCatValue": null,
                        "binLowerBound": -50,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "rssi",
                        "weekday": "Saturday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.109524537713808,
                        "binCatValue": null,
                        "binLowerBound": -45,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "rssi",
                        "weekday": "Saturday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.0699821829702546,
                        "binCatValue": null,
                        "binLowerBound": -40,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "rssi",
                        "weekday": "Saturday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.0340305486989374,
                        "binCatValue": null,
                        "binLowerBound": -35,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "rssi",
                        "weekday": "Saturday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.0126502885552502,
                        "binCatValue": null,
                        "binLowerBound": -30,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "rssi",
                        "weekday": "Saturday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.00321166548547748,
                        "binCatValue": null,
                        "binLowerBound": -25,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "rssi",
                        "weekday": "Saturday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.000833387296652291,
                        "binCatValue": null,
                        "binLowerBound": -20,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "rssi",
                        "weekday": "Saturday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.000325856093700946,
                        "binCatValue": null,
                        "binLowerBound": -15,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "rssi",
                        "weekday": "Saturday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.00680526967103006,
                        "binCatValue": null,
                        "binLowerBound": -10,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "rssi",
                        "weekday": "Saturday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz and 5 GHz",
                        "binAvgFrequency": 0.00292602318715497,
                        "binCatValue": null,
                        "binLowerBound": -95,
                        "lastTimestamp": "2019-01-05T00:00:00",
                        "variable": "rssi",
                        "weekday": "Saturday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz and 5 GHz",
                        "binAvgFrequency": 0.028676079760445,
                        "binCatValue": null,
                        "binLowerBound": -90,
                        "lastTimestamp": "2019-01-05T00:00:00",
                        "variable": "rssi",
                        "weekday": "Saturday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz and 5 GHz",
                        "binAvgFrequency": 0.0330756398044406,
                        "binCatValue": null,
                        "binLowerBound": -85,
                        "lastTimestamp": "2019-01-05T00:00:00",
                        "variable": "rssi",
                        "weekday": "Saturday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz and 5 GHz",
                        "binAvgFrequency": 0.0326493666422831,
                        "binCatValue": null,
                        "binLowerBound": -80,
                        "lastTimestamp": "2019-01-05T00:00:00",
                        "variable": "rssi",
                        "weekday": "Saturday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz and 5 GHz",
                        "binAvgFrequency": 0.0415905777843268,
                        "binCatValue": null,
                        "binLowerBound": -75,
                        "lastTimestamp": "2019-01-05T00:00:00",
                        "variable": "rssi",
                        "weekday": "Saturday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz and 5 GHz",
                        "binAvgFrequency": 0.0651619048621454,
                        "binCatValue": null,
                        "binLowerBound": -70,
                        "lastTimestamp": "2019-01-05T00:00:00",
                        "variable": "rssi",
                        "weekday": "Saturday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz and 5 GHz",
                        "binAvgFrequency": 0.10366858051037,
                        "binCatValue": null,
                        "binLowerBound": -65,
                        "lastTimestamp": "2019-01-05T00:00:00",
                        "variable": "rssi",
                        "weekday": "Saturday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz and 5 GHz",
                        "binAvgFrequency": 0.165672906393571,
                        "binCatValue": null,
                        "binLowerBound": -60,
                        "lastTimestamp": "2019-01-05T00:00:00",
                        "variable": "rssi",
                        "weekday": "Saturday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz and 5 GHz",
                        "binAvgFrequency": 0.173251095943037,
                        "binCatValue": null,
                        "binLowerBound": -55,
                        "lastTimestamp": "2019-01-05T00:00:00",
                        "variable": "rssi",
                        "weekday": "Saturday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz and 5 GHz",
                        "binAvgFrequency": 0.153516227324636,
                        "binCatValue": null,
                        "binLowerBound": -50,
                        "lastTimestamp": "2019-01-05T00:00:00",
                        "variable": "rssi",
                        "weekday": "Saturday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz and 5 GHz",
                        "binAvgFrequency": 0.119340697509196,
                        "binCatValue": null,
                        "binLowerBound": -45,
                        "lastTimestamp": "2019-01-05T00:00:00",
                        "variable": "rssi",
                        "weekday": "Saturday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz and 5 GHz",
                        "binAvgFrequency": 0.0656881680253027,
                        "binCatValue": null,
                        "binLowerBound": -40,
                        "lastTimestamp": "2019-01-05T00:00:00",
                        "variable": "rssi",
                        "weekday": "Saturday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz and 5 GHz",
                        "binAvgFrequency": 0.0128039827596188,
                        "binCatValue": null,
                        "binLowerBound": -35,
                        "lastTimestamp": "2019-01-05T00:00:00",
                        "variable": "rssi",
                        "weekday": "Saturday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz and 5 GHz",
                        "binAvgFrequency": 0.00181560791289292,
                        "binCatValue": null,
                        "binLowerBound": -30,
                        "lastTimestamp": "2019-01-05T00:00:00",
                        "variable": "rssi",
                        "weekday": "Saturday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz and 5 GHz",
                        "binAvgFrequency": 0.000163141580578784,
                        "binCatValue": null,
                        "binLowerBound": -25,
                        "lastTimestamp": "2019-01-05T00:00:00",
                        "variable": "rssi",
                        "weekday": "Saturday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.000977377164358669,
                        "binCatValue": null,
                        "binLowerBound": -95,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "rssi",
                        "weekday": "Saturday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.00847553597744883,
                        "binCatValue": null,
                        "binLowerBound": -90,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "rssi",
                        "weekday": "Saturday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.0108568168265209,
                        "binCatValue": null,
                        "binLowerBound": -85,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "rssi",
                        "weekday": "Saturday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.0186154210595498,
                        "binCatValue": null,
                        "binLowerBound": -80,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "rssi",
                        "weekday": "Saturday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.0385885587149041,
                        "binCatValue": null,
                        "binLowerBound": -75,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "rssi",
                        "weekday": "Saturday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.0789286453815709,
                        "binCatValue": null,
                        "binLowerBound": -70,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "rssi",
                        "weekday": "Saturday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.136256855299875,
                        "binCatValue": null,
                        "binLowerBound": -65,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "rssi",
                        "weekday": "Saturday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.187109208780449,
                        "binCatValue": null,
                        "binLowerBound": -60,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "rssi",
                        "weekday": "Saturday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.197111199374531,
                        "binCatValue": null,
                        "binLowerBound": -55,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "rssi",
                        "weekday": "Saturday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.147296596318208,
                        "binCatValue": null,
                        "binLowerBound": -50,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "rssi",
                        "weekday": "Saturday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.104082030782826,
                        "binCatValue": null,
                        "binLowerBound": -45,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "rssi",
                        "weekday": "Saturday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.0504847363652784,
                        "binCatValue": null,
                        "binLowerBound": -40,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "rssi",
                        "weekday": "Saturday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.0161827313490879,
                        "binCatValue": null,
                        "binLowerBound": -35,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "rssi",
                        "weekday": "Saturday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.00405572306553828,
                        "binCatValue": null,
                        "binLowerBound": -30,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "rssi",
                        "weekday": "Saturday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.000658942704237006,
                        "binCatValue": null,
                        "binLowerBound": -25,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "rssi",
                        "weekday": "Saturday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.000138673405333842,
                        "binCatValue": null,
                        "binLowerBound": -20,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "rssi",
                        "weekday": "Saturday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.0000398646377673952,
                        "binCatValue": null,
                        "binLowerBound": -15,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "rssi",
                        "weekday": "Saturday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.000141082792514263,
                        "binCatValue": null,
                        "binLowerBound": -10,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "rssi",
                        "weekday": "Saturday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": -95,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "rssi",
                        "weekday": "Saturday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": -90,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "rssi",
                        "weekday": "Saturday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": -85,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "rssi",
                        "weekday": "Saturday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": -80,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "rssi",
                        "weekday": "Saturday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": -75,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "rssi",
                        "weekday": "Saturday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": -70,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "rssi",
                        "weekday": "Saturday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": -65,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "rssi",
                        "weekday": "Saturday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": -60,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "rssi",
                        "weekday": "Saturday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": -55,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "rssi",
                        "weekday": "Saturday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": -50,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "rssi",
                        "weekday": "Saturday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": -45,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "rssi",
                        "weekday": "Saturday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": -40,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "rssi",
                        "weekday": "Saturday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": -35,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "rssi",
                        "weekday": "Saturday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": -30,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "rssi",
                        "weekday": "Saturday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": -25,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "rssi",
                        "weekday": "Saturday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": -20,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "rssi",
                        "weekday": "Saturday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": -15,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "rssi",
                        "weekday": "Saturday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": -10,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "rssi",
                        "weekday": "Saturday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.00204077687505138,
                        "binCatValue": null,
                        "binLowerBound": -95,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "rssi",
                        "weekday": "Sunday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.0133824148491156,
                        "binCatValue": null,
                        "binLowerBound": -90,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "rssi",
                        "weekday": "Sunday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.0321730217928766,
                        "binCatValue": null,
                        "binLowerBound": -85,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "rssi",
                        "weekday": "Sunday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.0431577964576482,
                        "binCatValue": null,
                        "binLowerBound": -80,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "rssi",
                        "weekday": "Sunday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.0513297765847438,
                        "binCatValue": null,
                        "binLowerBound": -75,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "rssi",
                        "weekday": "Sunday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.0728932270150624,
                        "binCatValue": null,
                        "binLowerBound": -70,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "rssi",
                        "weekday": "Sunday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.108066936859449,
                        "binCatValue": null,
                        "binLowerBound": -65,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "rssi",
                        "weekday": "Sunday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.145616781561384,
                        "binCatValue": null,
                        "binLowerBound": -60,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "rssi",
                        "weekday": "Sunday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.150436798672866,
                        "binCatValue": null,
                        "binLowerBound": -55,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "rssi",
                        "weekday": "Sunday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.140965375199139,
                        "binCatValue": null,
                        "binLowerBound": -50,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "rssi",
                        "weekday": "Sunday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.112754001137309,
                        "binCatValue": null,
                        "binLowerBound": -45,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "rssi",
                        "weekday": "Sunday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.0720143104986706,
                        "binCatValue": null,
                        "binLowerBound": -40,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "rssi",
                        "weekday": "Sunday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.0338875357888133,
                        "binCatValue": null,
                        "binLowerBound": -35,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "rssi",
                        "weekday": "Sunday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.0126511456286059,
                        "binCatValue": null,
                        "binLowerBound": -30,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "rssi",
                        "weekday": "Sunday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.003037442930429,
                        "binCatValue": null,
                        "binLowerBound": -25,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "rssi",
                        "weekday": "Sunday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.000743487805752804,
                        "binCatValue": null,
                        "binLowerBound": -20,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "rssi",
                        "weekday": "Sunday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.000279329149932341,
                        "binCatValue": null,
                        "binLowerBound": -15,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "rssi",
                        "weekday": "Sunday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.00456984119315103,
                        "binCatValue": null,
                        "binLowerBound": -10,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "rssi",
                        "weekday": "Sunday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz and 5 GHz",
                        "binAvgFrequency": 0.00391419019940531,
                        "binCatValue": null,
                        "binLowerBound": -95,
                        "lastTimestamp": "2019-01-06T00:00:00",
                        "variable": "rssi",
                        "weekday": "Sunday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz and 5 GHz",
                        "binAvgFrequency": 0.0267721650119554,
                        "binCatValue": null,
                        "binLowerBound": -90,
                        "lastTimestamp": "2019-01-06T00:00:00",
                        "variable": "rssi",
                        "weekday": "Sunday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz and 5 GHz",
                        "binAvgFrequency": 0.0325622546631501,
                        "binCatValue": null,
                        "binLowerBound": -85,
                        "lastTimestamp": "2019-01-06T00:00:00",
                        "variable": "rssi",
                        "weekday": "Sunday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz and 5 GHz",
                        "binAvgFrequency": 0.0340349757253011,
                        "binCatValue": null,
                        "binLowerBound": -80,
                        "lastTimestamp": "2019-01-06T00:00:00",
                        "variable": "rssi",
                        "weekday": "Sunday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz and 5 GHz",
                        "binAvgFrequency": 0.0405922242567798,
                        "binCatValue": null,
                        "binLowerBound": -75,
                        "lastTimestamp": "2019-01-06T00:00:00",
                        "variable": "rssi",
                        "weekday": "Sunday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz and 5 GHz",
                        "binAvgFrequency": 0.0597879953634485,
                        "binCatValue": null,
                        "binLowerBound": -70,
                        "lastTimestamp": "2019-01-06T00:00:00",
                        "variable": "rssi",
                        "weekday": "Sunday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz and 5 GHz",
                        "binAvgFrequency": 0.0966116216170813,
                        "binCatValue": null,
                        "binLowerBound": -65,
                        "lastTimestamp": "2019-01-06T00:00:00",
                        "variable": "rssi",
                        "weekday": "Sunday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz and 5 GHz",
                        "binAvgFrequency": 0.169698904138738,
                        "binCatValue": null,
                        "binLowerBound": -60,
                        "lastTimestamp": "2019-01-06T00:00:00",
                        "variable": "rssi",
                        "weekday": "Sunday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz and 5 GHz",
                        "binAvgFrequency": 0.174481047815837,
                        "binCatValue": null,
                        "binLowerBound": -55,
                        "lastTimestamp": "2019-01-06T00:00:00",
                        "variable": "rssi",
                        "weekday": "Sunday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz and 5 GHz",
                        "binAvgFrequency": 0.156976386065707,
                        "binCatValue": null,
                        "binLowerBound": -50,
                        "lastTimestamp": "2019-01-06T00:00:00",
                        "variable": "rssi",
                        "weekday": "Sunday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz and 5 GHz",
                        "binAvgFrequency": 0.115376215834831,
                        "binCatValue": null,
                        "binLowerBound": -45,
                        "lastTimestamp": "2019-01-06T00:00:00",
                        "variable": "rssi",
                        "weekday": "Sunday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz and 5 GHz",
                        "binAvgFrequency": 0.0734008657135977,
                        "binCatValue": null,
                        "binLowerBound": -40,
                        "lastTimestamp": "2019-01-06T00:00:00",
                        "variable": "rssi",
                        "weekday": "Sunday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz and 5 GHz",
                        "binAvgFrequency": 0.0143912286301454,
                        "binCatValue": null,
                        "binLowerBound": -35,
                        "lastTimestamp": "2019-01-06T00:00:00",
                        "variable": "rssi",
                        "weekday": "Sunday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz and 5 GHz",
                        "binAvgFrequency": 0.0012319339683393,
                        "binCatValue": null,
                        "binLowerBound": -30,
                        "lastTimestamp": "2019-01-06T00:00:00",
                        "variable": "rssi",
                        "weekday": "Sunday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz and 5 GHz",
                        "binAvgFrequency": 0.000156791595970456,
                        "binCatValue": null,
                        "binLowerBound": -25,
                        "lastTimestamp": "2019-01-06T00:00:00",
                        "variable": "rssi",
                        "weekday": "Sunday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz and 5 GHz",
                        "binAvgFrequency": 0.0000111993997121754,
                        "binCatValue": null,
                        "binLowerBound": -20,
                        "lastTimestamp": "2019-01-06T00:00:00",
                        "variable": "rssi",
                        "weekday": "Sunday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.000730973606224314,
                        "binCatValue": null,
                        "binLowerBound": -95,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "rssi",
                        "weekday": "Sunday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.00697241098917369,
                        "binCatValue": null,
                        "binLowerBound": -90,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "rssi",
                        "weekday": "Sunday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.0102280514385784,
                        "binCatValue": null,
                        "binLowerBound": -85,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "rssi",
                        "weekday": "Sunday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.0184843175330203,
                        "binCatValue": null,
                        "binLowerBound": -80,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "rssi",
                        "weekday": "Sunday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.0392954192660266,
                        "binCatValue": null,
                        "binLowerBound": -75,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "rssi",
                        "weekday": "Sunday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.0808053084112346,
                        "binCatValue": null,
                        "binLowerBound": -70,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "rssi",
                        "weekday": "Sunday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.138204355237719,
                        "binCatValue": null,
                        "binLowerBound": -65,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "rssi",
                        "weekday": "Sunday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.187245662291345,
                        "binCatValue": null,
                        "binLowerBound": -60,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "rssi",
                        "weekday": "Sunday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.19639840716451,
                        "binCatValue": null,
                        "binLowerBound": -55,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "rssi",
                        "weekday": "Sunday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.145915253899358,
                        "binCatValue": null,
                        "binLowerBound": -50,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "rssi",
                        "weekday": "Sunday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.103557069197084,
                        "binCatValue": null,
                        "binLowerBound": -45,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "rssi",
                        "weekday": "Sunday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.0509720993802642,
                        "binCatValue": null,
                        "binLowerBound": -40,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "rssi",
                        "weekday": "Sunday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.0164540471241612,
                        "binCatValue": null,
                        "binLowerBound": -35,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "rssi",
                        "weekday": "Sunday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.00397351977585533,
                        "binCatValue": null,
                        "binLowerBound": -30,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "rssi",
                        "weekday": "Sunday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.00059400615832249,
                        "binCatValue": null,
                        "binLowerBound": -25,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "rssi",
                        "weekday": "Sunday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.000113060485056939,
                        "binCatValue": null,
                        "binLowerBound": -20,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "rssi",
                        "weekday": "Sunday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.0000260085714491365,
                        "binCatValue": null,
                        "binLowerBound": -15,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "rssi",
                        "weekday": "Sunday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.0000300294706163083,
                        "binCatValue": null,
                        "binLowerBound": -10,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "rssi",
                        "weekday": "Sunday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": -95,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "rssi",
                        "weekday": "Sunday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": -90,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "rssi",
                        "weekday": "Sunday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": -85,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "rssi",
                        "weekday": "Sunday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": -80,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "rssi",
                        "weekday": "Sunday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": -75,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "rssi",
                        "weekday": "Sunday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": -70,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "rssi",
                        "weekday": "Sunday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": -65,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "rssi",
                        "weekday": "Sunday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": -60,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "rssi",
                        "weekday": "Sunday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": -55,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "rssi",
                        "weekday": "Sunday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": -50,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "rssi",
                        "weekday": "Sunday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": -45,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "rssi",
                        "weekday": "Sunday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": -40,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "rssi",
                        "weekday": "Sunday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": -35,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "rssi",
                        "weekday": "Sunday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": -30,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "rssi",
                        "weekday": "Sunday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": -25,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "rssi",
                        "weekday": "Sunday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": -20,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "rssi",
                        "weekday": "Sunday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": -15,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "rssi",
                        "weekday": "Sunday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": -10,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "rssi",
                        "weekday": "Sunday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.00331838794313829,
                        "binCatValue": null,
                        "binLowerBound": -95,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "rssi",
                        "weekday": "Thursday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.0208699646439565,
                        "binCatValue": null,
                        "binLowerBound": -90,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "rssi",
                        "weekday": "Thursday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.0478427410264904,
                        "binCatValue": null,
                        "binLowerBound": -85,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "rssi",
                        "weekday": "Thursday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.0593564083657665,
                        "binCatValue": null,
                        "binLowerBound": -80,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "rssi",
                        "weekday": "Thursday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.0596242301491947,
                        "binCatValue": null,
                        "binLowerBound": -75,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "rssi",
                        "weekday": "Thursday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.0777943149835258,
                        "binCatValue": null,
                        "binLowerBound": -70,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "rssi",
                        "weekday": "Thursday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.109826910386045,
                        "binCatValue": null,
                        "binLowerBound": -65,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "rssi",
                        "weekday": "Thursday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.140348192269168,
                        "binCatValue": null,
                        "binLowerBound": -60,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "rssi",
                        "weekday": "Thursday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.140655542853558,
                        "binCatValue": null,
                        "binLowerBound": -55,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "rssi",
                        "weekday": "Thursday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.128613543750229,
                        "binCatValue": null,
                        "binLowerBound": -50,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "rssi",
                        "weekday": "Thursday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.100019289015149,
                        "binCatValue": null,
                        "binLowerBound": -45,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "rssi",
                        "weekday": "Thursday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.0617766210013638,
                        "binCatValue": null,
                        "binLowerBound": -40,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "rssi",
                        "weekday": "Thursday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.0292094065620669,
                        "binCatValue": null,
                        "binLowerBound": -35,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "rssi",
                        "weekday": "Thursday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.0107545926662172,
                        "binCatValue": null,
                        "binLowerBound": -30,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "rssi",
                        "weekday": "Thursday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.00276301584279302,
                        "binCatValue": null,
                        "binLowerBound": -25,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "rssi",
                        "weekday": "Thursday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.000765971262684071,
                        "binCatValue": null,
                        "binLowerBound": -20,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "rssi",
                        "weekday": "Thursday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.000325690854303883,
                        "binCatValue": null,
                        "binLowerBound": -15,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "rssi",
                        "weekday": "Thursday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.00613517642434965,
                        "binCatValue": null,
                        "binLowerBound": -10,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "rssi",
                        "weekday": "Thursday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz and 5 GHz",
                        "binAvgFrequency": 0.00317696040732982,
                        "binCatValue": null,
                        "binLowerBound": -95,
                        "lastTimestamp": "2019-01-03T00:00:00",
                        "variable": "rssi",
                        "weekday": "Thursday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz and 5 GHz",
                        "binAvgFrequency": 0.0270757644063751,
                        "binCatValue": null,
                        "binLowerBound": -90,
                        "lastTimestamp": "2019-01-03T00:00:00",
                        "variable": "rssi",
                        "weekday": "Thursday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz and 5 GHz",
                        "binAvgFrequency": 0.0297700814129253,
                        "binCatValue": null,
                        "binLowerBound": -85,
                        "lastTimestamp": "2019-01-03T00:00:00",
                        "variable": "rssi",
                        "weekday": "Thursday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz and 5 GHz",
                        "binAvgFrequency": 0.0311225436898353,
                        "binCatValue": null,
                        "binLowerBound": -80,
                        "lastTimestamp": "2019-01-03T00:00:00",
                        "variable": "rssi",
                        "weekday": "Thursday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz and 5 GHz",
                        "binAvgFrequency": 0.039099419236787,
                        "binCatValue": null,
                        "binLowerBound": -75,
                        "lastTimestamp": "2019-01-03T00:00:00",
                        "variable": "rssi",
                        "weekday": "Thursday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz and 5 GHz",
                        "binAvgFrequency": 0.0629133628576732,
                        "binCatValue": null,
                        "binLowerBound": -70,
                        "lastTimestamp": "2019-01-03T00:00:00",
                        "variable": "rssi",
                        "weekday": "Thursday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz and 5 GHz",
                        "binAvgFrequency": 0.100533029250312,
                        "binCatValue": null,
                        "binLowerBound": -65,
                        "lastTimestamp": "2019-01-03T00:00:00",
                        "variable": "rssi",
                        "weekday": "Thursday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz and 5 GHz",
                        "binAvgFrequency": 0.168654697817497,
                        "binCatValue": null,
                        "binLowerBound": -60,
                        "lastTimestamp": "2019-01-03T00:00:00",
                        "variable": "rssi",
                        "weekday": "Thursday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz and 5 GHz",
                        "binAvgFrequency": 0.179935824339017,
                        "binCatValue": null,
                        "binLowerBound": -55,
                        "lastTimestamp": "2019-01-03T00:00:00",
                        "variable": "rssi",
                        "weekday": "Thursday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz and 5 GHz",
                        "binAvgFrequency": 0.156233259964465,
                        "binCatValue": null,
                        "binLowerBound": -50,
                        "lastTimestamp": "2019-01-03T00:00:00",
                        "variable": "rssi",
                        "weekday": "Thursday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz and 5 GHz",
                        "binAvgFrequency": 0.118661327534541,
                        "binCatValue": null,
                        "binLowerBound": -45,
                        "lastTimestamp": "2019-01-03T00:00:00",
                        "variable": "rssi",
                        "weekday": "Thursday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz and 5 GHz",
                        "binAvgFrequency": 0.0682807817762338,
                        "binCatValue": null,
                        "binLowerBound": -40,
                        "lastTimestamp": "2019-01-03T00:00:00",
                        "variable": "rssi",
                        "weekday": "Thursday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz and 5 GHz",
                        "binAvgFrequency": 0.0129518152165266,
                        "binCatValue": null,
                        "binLowerBound": -35,
                        "lastTimestamp": "2019-01-03T00:00:00",
                        "variable": "rssi",
                        "weekday": "Thursday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz and 5 GHz",
                        "binAvgFrequency": 0.00154870190140285,
                        "binCatValue": null,
                        "binLowerBound": -30,
                        "lastTimestamp": "2019-01-03T00:00:00",
                        "variable": "rssi",
                        "weekday": "Thursday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz and 5 GHz",
                        "binAvgFrequency": 0.0000371264154445888,
                        "binCatValue": null,
                        "binLowerBound": -25,
                        "lastTimestamp": "2019-01-03T00:00:00",
                        "variable": "rssi",
                        "weekday": "Thursday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz and 5 GHz",
                        "binAvgFrequency": 0.00000530377363494126,
                        "binCatValue": null,
                        "binLowerBound": -20,
                        "lastTimestamp": "2019-01-03T00:00:00",
                        "variable": "rssi",
                        "weekday": "Thursday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.00114806059213414,
                        "binCatValue": null,
                        "binLowerBound": -95,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "rssi",
                        "weekday": "Thursday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.0101070304939876,
                        "binCatValue": null,
                        "binLowerBound": -90,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "rssi",
                        "weekday": "Thursday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.0118246692919704,
                        "binCatValue": null,
                        "binLowerBound": -85,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "rssi",
                        "weekday": "Thursday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.0185473943276058,
                        "binCatValue": null,
                        "binLowerBound": -80,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "rssi",
                        "weekday": "Thursday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.036965594915162,
                        "binCatValue": null,
                        "binLowerBound": -75,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "rssi",
                        "weekday": "Thursday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.075688857668161,
                        "binCatValue": null,
                        "binLowerBound": -70,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "rssi",
                        "weekday": "Thursday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.13403133737255,
                        "binCatValue": null,
                        "binLowerBound": -65,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "rssi",
                        "weekday": "Thursday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.187212816006922,
                        "binCatValue": null,
                        "binLowerBound": -60,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "rssi",
                        "weekday": "Thursday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.200863020467525,
                        "binCatValue": null,
                        "binLowerBound": -55,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "rssi",
                        "weekday": "Thursday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.151758231040772,
                        "binCatValue": null,
                        "binLowerBound": -50,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "rssi",
                        "weekday": "Thursday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.107001032994503,
                        "binCatValue": null,
                        "binLowerBound": -45,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "rssi",
                        "weekday": "Thursday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.0477580341242729,
                        "binCatValue": null,
                        "binLowerBound": -40,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "rssi",
                        "weekday": "Thursday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.0134337874960055,
                        "binCatValue": null,
                        "binLowerBound": -35,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "rssi",
                        "weekday": "Thursday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.00294574533933105,
                        "binCatValue": null,
                        "binLowerBound": -30,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "rssi",
                        "weekday": "Thursday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.00044795687706528,
                        "binCatValue": null,
                        "binLowerBound": -25,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "rssi",
                        "weekday": "Thursday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.000102446398140781,
                        "binCatValue": null,
                        "binLowerBound": -20,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "rssi",
                        "weekday": "Thursday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.0000360367152505493,
                        "binCatValue": null,
                        "binLowerBound": -15,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "rssi",
                        "weekday": "Thursday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.000127947878640847,
                        "binCatValue": null,
                        "binLowerBound": -10,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "rssi",
                        "weekday": "Thursday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": -95,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "rssi",
                        "weekday": "Thursday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": -90,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "rssi",
                        "weekday": "Thursday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": -85,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "rssi",
                        "weekday": "Thursday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": -80,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "rssi",
                        "weekday": "Thursday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": -75,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "rssi",
                        "weekday": "Thursday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": -70,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "rssi",
                        "weekday": "Thursday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": -65,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "rssi",
                        "weekday": "Thursday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": -60,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "rssi",
                        "weekday": "Thursday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": -55,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "rssi",
                        "weekday": "Thursday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": -50,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "rssi",
                        "weekday": "Thursday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": -45,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "rssi",
                        "weekday": "Thursday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": -40,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "rssi",
                        "weekday": "Thursday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": -35,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "rssi",
                        "weekday": "Thursday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": -30,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "rssi",
                        "weekday": "Thursday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": -25,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "rssi",
                        "weekday": "Thursday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": -20,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "rssi",
                        "weekday": "Thursday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": -15,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "rssi",
                        "weekday": "Thursday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": -10,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "rssi",
                        "weekday": "Thursday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.00313437498361805,
                        "binCatValue": null,
                        "binLowerBound": -95,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "rssi",
                        "weekday": "Tuesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.0201088339208818,
                        "binCatValue": null,
                        "binLowerBound": -90,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "rssi",
                        "weekday": "Tuesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.0461453574245779,
                        "binCatValue": null,
                        "binLowerBound": -85,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "rssi",
                        "weekday": "Tuesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.0582155097318249,
                        "binCatValue": null,
                        "binLowerBound": -80,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "rssi",
                        "weekday": "Tuesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.0587167487229677,
                        "binCatValue": null,
                        "binLowerBound": -75,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "rssi",
                        "weekday": "Tuesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.0773145725805514,
                        "binCatValue": null,
                        "binLowerBound": -70,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "rssi",
                        "weekday": "Tuesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.110818506964212,
                        "binCatValue": null,
                        "binLowerBound": -65,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "rssi",
                        "weekday": "Tuesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.142073728898055,
                        "binCatValue": null,
                        "binLowerBound": -60,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "rssi",
                        "weekday": "Tuesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.141607466057454,
                        "binCatValue": null,
                        "binLowerBound": -55,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "rssi",
                        "weekday": "Tuesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.129145261089499,
                        "binCatValue": null,
                        "binLowerBound": -50,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "rssi",
                        "weekday": "Tuesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.100474851008643,
                        "binCatValue": null,
                        "binLowerBound": -45,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "rssi",
                        "weekday": "Tuesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.0621067160539701,
                        "binCatValue": null,
                        "binLowerBound": -40,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "rssi",
                        "weekday": "Tuesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.0293446579285954,
                        "binCatValue": null,
                        "binLowerBound": -35,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "rssi",
                        "weekday": "Tuesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.0107129481858143,
                        "binCatValue": null,
                        "binLowerBound": -30,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "rssi",
                        "weekday": "Tuesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.00276949038387199,
                        "binCatValue": null,
                        "binLowerBound": -25,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "rssi",
                        "weekday": "Tuesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.000733833496851676,
                        "binCatValue": null,
                        "binLowerBound": -20,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "rssi",
                        "weekday": "Tuesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.000329406233174993,
                        "binCatValue": null,
                        "binLowerBound": -15,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "rssi",
                        "weekday": "Tuesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.00624773633543757,
                        "binCatValue": null,
                        "binLowerBound": -10,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "rssi",
                        "weekday": "Tuesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz and 5 GHz",
                        "binAvgFrequency": 0.00351441674253434,
                        "binCatValue": null,
                        "binLowerBound": -95,
                        "lastTimestamp": "2019-01-08T00:00:00",
                        "variable": "rssi",
                        "weekday": "Tuesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz and 5 GHz",
                        "binAvgFrequency": 0.0271144461471475,
                        "binCatValue": null,
                        "binLowerBound": -90,
                        "lastTimestamp": "2019-01-08T00:00:00",
                        "variable": "rssi",
                        "weekday": "Tuesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz and 5 GHz",
                        "binAvgFrequency": 0.0288787229350328,
                        "binCatValue": null,
                        "binLowerBound": -85,
                        "lastTimestamp": "2019-01-08T00:00:00",
                        "variable": "rssi",
                        "weekday": "Tuesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz and 5 GHz",
                        "binAvgFrequency": 0.0293056552987678,
                        "binCatValue": null,
                        "binLowerBound": -80,
                        "lastTimestamp": "2019-01-08T00:00:00",
                        "variable": "rssi",
                        "weekday": "Tuesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz and 5 GHz",
                        "binAvgFrequency": 0.0383730201365053,
                        "binCatValue": null,
                        "binLowerBound": -75,
                        "lastTimestamp": "2019-01-08T00:00:00",
                        "variable": "rssi",
                        "weekday": "Tuesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz and 5 GHz",
                        "binAvgFrequency": 0.0613114457456614,
                        "binCatValue": null,
                        "binLowerBound": -70,
                        "lastTimestamp": "2019-01-08T00:00:00",
                        "variable": "rssi",
                        "weekday": "Tuesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz and 5 GHz",
                        "binAvgFrequency": 0.102192340098279,
                        "binCatValue": null,
                        "binLowerBound": -65,
                        "lastTimestamp": "2019-01-08T00:00:00",
                        "variable": "rssi",
                        "weekday": "Tuesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz and 5 GHz",
                        "binAvgFrequency": 0.170382768896705,
                        "binCatValue": null,
                        "binLowerBound": -60,
                        "lastTimestamp": "2019-01-08T00:00:00",
                        "variable": "rssi",
                        "weekday": "Tuesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz and 5 GHz",
                        "binAvgFrequency": 0.178322014442189,
                        "binCatValue": null,
                        "binLowerBound": -55,
                        "lastTimestamp": "2019-01-08T00:00:00",
                        "variable": "rssi",
                        "weekday": "Tuesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz and 5 GHz",
                        "binAvgFrequency": 0.155655016031169,
                        "binCatValue": null,
                        "binLowerBound": -50,
                        "lastTimestamp": "2019-01-08T00:00:00",
                        "variable": "rssi",
                        "weekday": "Tuesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz and 5 GHz",
                        "binAvgFrequency": 0.117689136691868,
                        "binCatValue": null,
                        "binLowerBound": -45,
                        "lastTimestamp": "2019-01-08T00:00:00",
                        "variable": "rssi",
                        "weekday": "Tuesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz and 5 GHz",
                        "binAvgFrequency": 0.0714447278094129,
                        "binCatValue": null,
                        "binLowerBound": -40,
                        "lastTimestamp": "2019-01-08T00:00:00",
                        "variable": "rssi",
                        "weekday": "Tuesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz and 5 GHz",
                        "binAvgFrequency": 0.0137381745389979,
                        "binCatValue": null,
                        "binLowerBound": -35,
                        "lastTimestamp": "2019-01-08T00:00:00",
                        "variable": "rssi",
                        "weekday": "Tuesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz and 5 GHz",
                        "binAvgFrequency": 0.00190847248689516,
                        "binCatValue": null,
                        "binLowerBound": -30,
                        "lastTimestamp": "2019-01-08T00:00:00",
                        "variable": "rssi",
                        "weekday": "Tuesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz and 5 GHz",
                        "binAvgFrequency": 0.000161159898893369,
                        "binCatValue": null,
                        "binLowerBound": -25,
                        "lastTimestamp": "2019-01-08T00:00:00",
                        "variable": "rssi",
                        "weekday": "Tuesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz and 5 GHz",
                        "binAvgFrequency": 0.00000282736664725208,
                        "binCatValue": null,
                        "binLowerBound": -15,
                        "lastTimestamp": "2019-01-01T00:00:00",
                        "variable": "rssi",
                        "weekday": "Tuesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz and 5 GHz",
                        "binAvgFrequency": 0.00000565473329450416,
                        "binCatValue": null,
                        "binLowerBound": -10,
                        "lastTimestamp": "2019-01-08T00:00:00",
                        "variable": "rssi",
                        "weekday": "Tuesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.00110297938484393,
                        "binCatValue": null,
                        "binLowerBound": -95,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "rssi",
                        "weekday": "Tuesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.00973213471418435,
                        "binCatValue": null,
                        "binLowerBound": -90,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "rssi",
                        "weekday": "Tuesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.01148745996713,
                        "binCatValue": null,
                        "binLowerBound": -85,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "rssi",
                        "weekday": "Tuesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.0180321437360661,
                        "binCatValue": null,
                        "binLowerBound": -80,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "rssi",
                        "weekday": "Tuesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.0362704384381785,
                        "binCatValue": null,
                        "binLowerBound": -75,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "rssi",
                        "weekday": "Tuesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.0749781443951955,
                        "binCatValue": null,
                        "binLowerBound": -70,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "rssi",
                        "weekday": "Tuesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.133352559030235,
                        "binCatValue": null,
                        "binLowerBound": -65,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "rssi",
                        "weekday": "Tuesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.187219756721876,
                        "binCatValue": null,
                        "binLowerBound": -60,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "rssi",
                        "weekday": "Tuesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.201257108092518,
                        "binCatValue": null,
                        "binLowerBound": -55,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "rssi",
                        "weekday": "Tuesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.152925628533093,
                        "binCatValue": null,
                        "binLowerBound": -50,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "rssi",
                        "weekday": "Tuesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.108184596568813,
                        "binCatValue": null,
                        "binLowerBound": -45,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "rssi",
                        "weekday": "Tuesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.0481920325287218,
                        "binCatValue": null,
                        "binLowerBound": -40,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "rssi",
                        "weekday": "Tuesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.0136034786567984,
                        "binCatValue": null,
                        "binLowerBound": -35,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "rssi",
                        "weekday": "Tuesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.00294015407025632,
                        "binCatValue": null,
                        "binLowerBound": -30,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "rssi",
                        "weekday": "Tuesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.00045736770105673,
                        "binCatValue": null,
                        "binLowerBound": -25,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "rssi",
                        "weekday": "Tuesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.00010520922258624,
                        "binCatValue": null,
                        "binLowerBound": -20,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "rssi",
                        "weekday": "Tuesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.0000357964252368079,
                        "binCatValue": null,
                        "binLowerBound": -15,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "rssi",
                        "weekday": "Tuesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.000123011813210089,
                        "binCatValue": null,
                        "binLowerBound": -10,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "rssi",
                        "weekday": "Tuesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": -95,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "rssi",
                        "weekday": "Tuesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": -90,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "rssi",
                        "weekday": "Tuesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": -85,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "rssi",
                        "weekday": "Tuesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": -80,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "rssi",
                        "weekday": "Tuesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": -75,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "rssi",
                        "weekday": "Tuesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": -70,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "rssi",
                        "weekday": "Tuesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": -65,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "rssi",
                        "weekday": "Tuesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": -60,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "rssi",
                        "weekday": "Tuesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": -55,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "rssi",
                        "weekday": "Tuesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": -50,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "rssi",
                        "weekday": "Tuesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": -45,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "rssi",
                        "weekday": "Tuesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": -40,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "rssi",
                        "weekday": "Tuesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": -35,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "rssi",
                        "weekday": "Tuesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": -30,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "rssi",
                        "weekday": "Tuesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": -25,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "rssi",
                        "weekday": "Tuesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": -20,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "rssi",
                        "weekday": "Tuesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": -15,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "rssi",
                        "weekday": "Tuesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": -10,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "rssi",
                        "weekday": "Tuesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.00336824580643792,
                        "binCatValue": null,
                        "binLowerBound": -95,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "rssi",
                        "weekday": "Wednesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.0209191330439764,
                        "binCatValue": null,
                        "binLowerBound": -90,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "rssi",
                        "weekday": "Wednesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.047663177088033,
                        "binCatValue": null,
                        "binLowerBound": -85,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "rssi",
                        "weekday": "Wednesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.0593219723976663,
                        "binCatValue": null,
                        "binLowerBound": -80,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "rssi",
                        "weekday": "Wednesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.0593332443094262,
                        "binCatValue": null,
                        "binLowerBound": -75,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "rssi",
                        "weekday": "Wednesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.077483221559104,
                        "binCatValue": null,
                        "binLowerBound": -70,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "rssi",
                        "weekday": "Wednesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.109885922996656,
                        "binCatValue": null,
                        "binLowerBound": -65,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "rssi",
                        "weekday": "Wednesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.140761710074999,
                        "binCatValue": null,
                        "binLowerBound": -60,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "rssi",
                        "weekday": "Wednesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.140760800646145,
                        "binCatValue": null,
                        "binLowerBound": -55,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "rssi",
                        "weekday": "Wednesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.128531896902973,
                        "binCatValue": null,
                        "binLowerBound": -50,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "rssi",
                        "weekday": "Wednesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.0997382138124805,
                        "binCatValue": null,
                        "binLowerBound": -45,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "rssi",
                        "weekday": "Wednesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.061936964148771,
                        "binCatValue": null,
                        "binLowerBound": -40,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "rssi",
                        "weekday": "Wednesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.0291262895899306,
                        "binCatValue": null,
                        "binLowerBound": -35,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "rssi",
                        "weekday": "Wednesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.0106799787026781,
                        "binCatValue": null,
                        "binLowerBound": -30,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "rssi",
                        "weekday": "Wednesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.00273331845707969,
                        "binCatValue": null,
                        "binLowerBound": -25,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "rssi",
                        "weekday": "Wednesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.000745239400679709,
                        "binCatValue": null,
                        "binLowerBound": -20,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "rssi",
                        "weekday": "Wednesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.000317008543082878,
                        "binCatValue": null,
                        "binLowerBound": -15,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "rssi",
                        "weekday": "Wednesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.00669366251987967,
                        "binCatValue": null,
                        "binLowerBound": -10,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "rssi",
                        "weekday": "Wednesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz and 5 GHz",
                        "binAvgFrequency": 0.00315533504048645,
                        "binCatValue": null,
                        "binLowerBound": -95,
                        "lastTimestamp": "2019-01-09T00:00:00",
                        "variable": "rssi",
                        "weekday": "Wednesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz and 5 GHz",
                        "binAvgFrequency": 0.0265356665049265,
                        "binCatValue": null,
                        "binLowerBound": -90,
                        "lastTimestamp": "2019-01-09T00:00:00",
                        "variable": "rssi",
                        "weekday": "Wednesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz and 5 GHz",
                        "binAvgFrequency": 0.0286251994872931,
                        "binCatValue": null,
                        "binLowerBound": -85,
                        "lastTimestamp": "2019-01-09T00:00:00",
                        "variable": "rssi",
                        "weekday": "Wednesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz and 5 GHz",
                        "binAvgFrequency": 0.0286223947450349,
                        "binCatValue": null,
                        "binLowerBound": -80,
                        "lastTimestamp": "2019-01-09T00:00:00",
                        "variable": "rssi",
                        "weekday": "Wednesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz and 5 GHz",
                        "binAvgFrequency": 0.0385063064629676,
                        "binCatValue": null,
                        "binLowerBound": -75,
                        "lastTimestamp": "2019-01-09T00:00:00",
                        "variable": "rssi",
                        "weekday": "Wednesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz and 5 GHz",
                        "binAvgFrequency": 0.0624896575129228,
                        "binCatValue": null,
                        "binLowerBound": -70,
                        "lastTimestamp": "2019-01-09T00:00:00",
                        "variable": "rssi",
                        "weekday": "Wednesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz and 5 GHz",
                        "binAvgFrequency": 0.10008722748423,
                        "binCatValue": null,
                        "binLowerBound": -65,
                        "lastTimestamp": "2019-01-09T00:00:00",
                        "variable": "rssi",
                        "weekday": "Wednesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz and 5 GHz",
                        "binAvgFrequency": 0.166901797559313,
                        "binCatValue": null,
                        "binLowerBound": -60,
                        "lastTimestamp": "2019-01-09T00:00:00",
                        "variable": "rssi",
                        "weekday": "Wednesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz and 5 GHz",
                        "binAvgFrequency": 0.176034038352046,
                        "binCatValue": null,
                        "binLowerBound": -55,
                        "lastTimestamp": "2019-01-09T00:00:00",
                        "variable": "rssi",
                        "weekday": "Wednesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz and 5 GHz",
                        "binAvgFrequency": 0.155766970794219,
                        "binCatValue": null,
                        "binLowerBound": -50,
                        "lastTimestamp": "2019-01-09T00:00:00",
                        "variable": "rssi",
                        "weekday": "Wednesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz and 5 GHz",
                        "binAvgFrequency": 0.123274031732854,
                        "binCatValue": null,
                        "binLowerBound": -45,
                        "lastTimestamp": "2019-01-09T00:00:00",
                        "variable": "rssi",
                        "weekday": "Wednesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz and 5 GHz",
                        "binAvgFrequency": 0.0715097086153268,
                        "binCatValue": null,
                        "binLowerBound": -40,
                        "lastTimestamp": "2019-01-09T00:00:00",
                        "variable": "rssi",
                        "weekday": "Wednesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz and 5 GHz",
                        "binAvgFrequency": 0.0163993279837549,
                        "binCatValue": null,
                        "binLowerBound": -35,
                        "lastTimestamp": "2019-01-09T00:00:00",
                        "variable": "rssi",
                        "weekday": "Wednesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz and 5 GHz",
                        "binAvgFrequency": 0.00197453854977997,
                        "binCatValue": null,
                        "binLowerBound": -30,
                        "lastTimestamp": "2019-01-09T00:00:00",
                        "variable": "rssi",
                        "weekday": "Wednesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz and 5 GHz",
                        "binAvgFrequency": 0.000100970721295567,
                        "binCatValue": null,
                        "binLowerBound": -25,
                        "lastTimestamp": "2019-01-09T00:00:00",
                        "variable": "rssi",
                        "weekday": "Wednesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz and 5 GHz",
                        "binAvgFrequency": 0.00000841422677463055,
                        "binCatValue": null,
                        "binLowerBound": -20,
                        "lastTimestamp": "2019-01-09T00:00:00",
                        "variable": "rssi",
                        "weekday": "Wednesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz and 5 GHz",
                        "binAvgFrequency": 0.00000280474225821018,
                        "binCatValue": null,
                        "binLowerBound": -15,
                        "lastTimestamp": "2019-01-02T00:00:00",
                        "variable": "rssi",
                        "weekday": "Wednesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz and 5 GHz",
                        "binAvgFrequency": 0.00000560948451642036,
                        "binCatValue": null,
                        "binLowerBound": -10,
                        "lastTimestamp": "2019-01-02T00:00:00",
                        "variable": "rssi",
                        "weekday": "Wednesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.00114455594657232,
                        "binCatValue": null,
                        "binLowerBound": -95,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "rssi",
                        "weekday": "Wednesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.0101250473898581,
                        "binCatValue": null,
                        "binLowerBound": -90,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "rssi",
                        "weekday": "Wednesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.0117748496499774,
                        "binCatValue": null,
                        "binLowerBound": -85,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "rssi",
                        "weekday": "Wednesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.0183627279268935,
                        "binCatValue": null,
                        "binLowerBound": -80,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "rssi",
                        "weekday": "Wednesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.0365803885257966,
                        "binCatValue": null,
                        "binLowerBound": -75,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "rssi",
                        "weekday": "Wednesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.0751440137968599,
                        "binCatValue": null,
                        "binLowerBound": -70,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "rssi",
                        "weekday": "Wednesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.133704379154083,
                        "binCatValue": null,
                        "binLowerBound": -65,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "rssi",
                        "weekday": "Wednesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.187308781927009,
                        "binCatValue": null,
                        "binLowerBound": -60,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "rssi",
                        "weekday": "Wednesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.200924625970375,
                        "binCatValue": null,
                        "binLowerBound": -55,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "rssi",
                        "weekday": "Wednesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.152448250537694,
                        "binCatValue": null,
                        "binLowerBound": -50,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "rssi",
                        "weekday": "Wednesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.107429684146841,
                        "binCatValue": null,
                        "binLowerBound": -45,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "rssi",
                        "weekday": "Wednesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.0478651694682593,
                        "binCatValue": null,
                        "binLowerBound": -40,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "rssi",
                        "weekday": "Wednesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.0135415241640061,
                        "binCatValue": null,
                        "binLowerBound": -35,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "rssi",
                        "weekday": "Wednesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.00293115099527689,
                        "binCatValue": null,
                        "binLowerBound": -30,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "rssi",
                        "weekday": "Wednesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.000441355838183395,
                        "binCatValue": null,
                        "binLowerBound": -25,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "rssi",
                        "weekday": "Wednesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.000104771574983332,
                        "binCatValue": null,
                        "binLowerBound": -20,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "rssi",
                        "weekday": "Wednesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.0000347821277554256,
                        "binCatValue": null,
                        "binLowerBound": -15,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "rssi",
                        "weekday": "Wednesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.000133940859576266,
                        "binCatValue": null,
                        "binLowerBound": -10,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "rssi",
                        "weekday": "Wednesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": -95,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "rssi",
                        "weekday": "Wednesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": -90,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "rssi",
                        "weekday": "Wednesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": -85,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "rssi",
                        "weekday": "Wednesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": -80,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "rssi",
                        "weekday": "Wednesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": -75,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "rssi",
                        "weekday": "Wednesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": -70,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "rssi",
                        "weekday": "Wednesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": -65,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "rssi",
                        "weekday": "Wednesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": -60,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "rssi",
                        "weekday": "Wednesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": -55,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "rssi",
                        "weekday": "Wednesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": -50,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "rssi",
                        "weekday": "Wednesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": -45,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "rssi",
                        "weekday": "Wednesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": -40,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "rssi",
                        "weekday": "Wednesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": -35,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "rssi",
                        "weekday": "Wednesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": -30,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "rssi",
                        "weekday": "Wednesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": -25,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "rssi",
                        "weekday": "Wednesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": -20,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "rssi",
                        "weekday": "Wednesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": -15,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "rssi",
                        "weekday": "Wednesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": null,
                        "binLowerBound": -10,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "rssi",
                        "weekday": "Wednesday"
                    }],
                    "totalCount": 565
                },
                "tagOnboardingSourceModuleType": {
                    "__typename": "SmartDashboardPeers36SConnection",
                    "nodes": [{
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.00119129584238399,
                        "binCatValue": "Client-Association-Blacklisted",
                        "binLowerBound": 0,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "All"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.00259238325914347,
                        "binCatValue": "Client-Association-Fail",
                        "binLowerBound": 1,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "All"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.00409277950639174,
                        "binCatValue": "Client-Association-Invalid",
                        "binLowerBound": 2,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "All"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.000132597739697017,
                        "binCatValue": "Client-Association-Unknown",
                        "binLowerBound": 3,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "All"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.326128335241745,
                        "binCatValue": "Client-Auth-Blacklisted",
                        "binLowerBound": 4,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "All"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.280716239802453,
                        "binCatValue": "Client-Auth-Fail",
                        "binLowerBound": 5,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "All"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.110059818469328,
                        "binCatValue": "Client-Auth-Invalid",
                        "binLowerBound": 6,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "All"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.0645205277767093,
                        "binCatValue": "Client-Auth-Timeout",
                        "binLowerBound": 7,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "All"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.00000313725172935823,
                        "binCatValue": "Client-Auth-Unknown",
                        "binLowerBound": 8,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "All"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.00380751713635314,
                        "binCatValue": "Client-DHCP-Invalid",
                        "binLowerBound": 9,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "All"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.00853964003124308,
                        "binCatValue": "Client-DHCP-Timeout",
                        "binLowerBound": 10,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "All"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.00127174078718124,
                        "binCatValue": "Client-Operation-Invalid",
                        "binLowerBound": 11,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "All"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.000170622673401442,
                        "binCatValue": "Client-Operation-Timeout",
                        "binLowerBound": 12,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "All"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.0155319019318426,
                        "binCatValue": "Client-Operation-Unknown",
                        "binLowerBound": 13,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "All"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.0000197437086755344,
                        "binCatValue": "Client-Unknown-Blacklisted",
                        "binLowerBound": 14,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "All"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.0168929603636872,
                        "binCatValue": "Network-Association-Fail",
                        "binLowerBound": 15,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "All"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.00522560543202874,
                        "binCatValue": "Network-Association-MaxStations",
                        "binLowerBound": 16,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "All"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.000340682335460383,
                        "binCatValue": "Network-Association-Timeout",
                        "binLowerBound": 17,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "All"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": "Network-Auth-Blacklisted",
                        "binLowerBound": 18,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "All"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.00192155107244329,
                        "binCatValue": "Network-Auth-Fail",
                        "binLowerBound": 19,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "All"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.00150326962386076,
                        "binCatValue": "Network-Auth-Invalid",
                        "binLowerBound": 20,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "All"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.00289620248303074,
                        "binCatValue": "Network-Auth-Timeout",
                        "binLowerBound": 21,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "All"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.000395164337444014,
                        "binCatValue": "Network-Auth-Unknown",
                        "binLowerBound": 22,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "All"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.0000184309725875564,
                        "binCatValue": "Network-DHCP-Invalid",
                        "binLowerBound": 23,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "All"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.00661889467064932,
                        "binCatValue": "Network-DHCP-Timeout",
                        "binLowerBound": 24,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "All"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.0462362960429421,
                        "binCatValue": "Network-Operation-Fail",
                        "binLowerBound": 25,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "All"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.00000170670944334074,
                        "binCatValue": "Network-Operation-Invalid",
                        "binLowerBound": 26,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "All"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.0940336954012441,
                        "binCatValue": "Network-Operation-Timeout",
                        "binLowerBound": 27,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "All"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.00513725006571579,
                        "binCatValue": "Network-Operation-Unknown",
                        "binLowerBound": 28,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "All"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": "Unknown-Auth-Timeout",
                        "binLowerBound": 29,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "All"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 9.33118400312461e-9,
                        "binCatValue": "Unknown-Operation-Invalid",
                        "binLowerBound": 30,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "All"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.000264468164159095,
                        "binCatValue": "Client-Association-Blacklisted",
                        "binLowerBound": 0,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "All"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.00775307443579052,
                        "binCatValue": "Client-Association-Fail",
                        "binLowerBound": 1,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "All"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.00014536284892374,
                        "binCatValue": "Client-Association-Invalid",
                        "binLowerBound": 2,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "All"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.000221547331834029,
                        "binCatValue": "Client-Association-Unknown",
                        "binLowerBound": 3,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "All"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.27092328372833,
                        "binCatValue": "Client-Auth-Blacklisted",
                        "binLowerBound": 4,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "All"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.31247804437113,
                        "binCatValue": "Client-Auth-Fail",
                        "binLowerBound": 5,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "All"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.106073116667256,
                        "binCatValue": "Client-Auth-Invalid",
                        "binLowerBound": 6,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "All"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.0788891809875896,
                        "binCatValue": "Client-Auth-Timeout",
                        "binLowerBound": 7,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "All"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.0000117531913629379,
                        "binCatValue": "Client-Auth-Unknown",
                        "binLowerBound": 8,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "All"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.00218614454402547,
                        "binCatValue": "Client-DHCP-Invalid",
                        "binLowerBound": 9,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "All"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.00777725738474829,
                        "binCatValue": "Client-DHCP-Timeout",
                        "binLowerBound": 10,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "All"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.0043830414624853,
                        "binCatValue": "Client-Operation-Invalid",
                        "binLowerBound": 11,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "All"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.000213931269496931,
                        "binCatValue": "Client-Operation-Timeout",
                        "binLowerBound": 12,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "All"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.0340252782880852,
                        "binCatValue": "Client-Operation-Unknown",
                        "binLowerBound": 13,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "All"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.0000207836064659733,
                        "binCatValue": "Client-Unknown-Blacklisted",
                        "binLowerBound": 14,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "All"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.023023063800489,
                        "binCatValue": "Network-Association-Fail",
                        "binLowerBound": 15,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "All"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.0118550182106721,
                        "binCatValue": "Network-Association-MaxStations",
                        "binLowerBound": 16,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "All"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.000098131762047254,
                        "binCatValue": "Network-Association-Timeout",
                        "binLowerBound": 17,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "All"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": "Network-Auth-Blacklisted",
                        "binLowerBound": 18,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "All"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.000881895097187726,
                        "binCatValue": "Network-Auth-Fail",
                        "binLowerBound": 19,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "All"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.000781582112876947,
                        "binCatValue": "Network-Auth-Invalid",
                        "binLowerBound": 20,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "All"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.00286012832383203,
                        "binCatValue": "Network-Auth-Timeout",
                        "binLowerBound": 21,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "All"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.000776209865361067,
                        "binCatValue": "Network-Auth-Unknown",
                        "binLowerBound": 22,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "All"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.0000124633999879175,
                        "binCatValue": "Network-DHCP-Invalid",
                        "binLowerBound": 23,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "All"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.00731351563190415,
                        "binCatValue": "Network-DHCP-Timeout",
                        "binLowerBound": 24,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "All"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.0700736689322825,
                        "binCatValue": "Network-Operation-Fail",
                        "binLowerBound": 25,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "All"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.00000994137142898017,
                        "binCatValue": "Network-Operation-Invalid",
                        "binLowerBound": 26,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "All"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.0559591303475698,
                        "binCatValue": "Network-Operation-Timeout",
                        "binLowerBound": 27,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "All"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.000988911815254135,
                        "binCatValue": "Network-Operation-Unknown",
                        "binLowerBound": 28,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "All"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": "Unknown-Auth-Timeout",
                        "binLowerBound": 29,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "All"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 7.1047422281997e-8,
                        "binCatValue": "Unknown-Operation-Invalid",
                        "binLowerBound": 30,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "All"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": "Client-Association-Blacklisted",
                        "binLowerBound": 0,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "All"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": "Client-Association-Fail",
                        "binLowerBound": 1,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "All"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": "Client-Association-Invalid",
                        "binLowerBound": 2,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "All"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": "Client-Association-Unknown",
                        "binLowerBound": 3,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "All"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": "Client-Auth-Blacklisted",
                        "binLowerBound": 4,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "All"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": "Client-Auth-Fail",
                        "binLowerBound": 5,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "All"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": "Client-Auth-Invalid",
                        "binLowerBound": 6,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "All"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": "Client-Auth-Timeout",
                        "binLowerBound": 7,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "All"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": "Client-Auth-Unknown",
                        "binLowerBound": 8,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "All"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": "Client-DHCP-Invalid",
                        "binLowerBound": 9,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "All"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": "Client-DHCP-Timeout",
                        "binLowerBound": 10,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "All"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": "Client-Operation-Invalid",
                        "binLowerBound": 11,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "All"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": "Client-Operation-Timeout",
                        "binLowerBound": 12,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "All"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": "Client-Operation-Unknown",
                        "binLowerBound": 13,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "All"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": "Client-Unknown-Blacklisted",
                        "binLowerBound": 14,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "All"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": "Network-Association-Fail",
                        "binLowerBound": 15,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "All"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": "Network-Association-MaxStations",
                        "binLowerBound": 16,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "All"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": "Network-Association-Timeout",
                        "binLowerBound": 17,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "All"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": "Network-Auth-Blacklisted",
                        "binLowerBound": 18,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "All"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": "Network-Auth-Fail",
                        "binLowerBound": 19,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "All"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": "Network-Auth-Invalid",
                        "binLowerBound": 20,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "All"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": "Network-Auth-Timeout",
                        "binLowerBound": 21,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "All"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": "Network-Auth-Unknown",
                        "binLowerBound": 22,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "All"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": "Network-DHCP-Invalid",
                        "binLowerBound": 23,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "All"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": "Network-DHCP-Timeout",
                        "binLowerBound": 24,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "All"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": "Network-Operation-Fail",
                        "binLowerBound": 25,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "All"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": "Network-Operation-Invalid",
                        "binLowerBound": 26,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "All"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": "Network-Operation-Timeout",
                        "binLowerBound": 27,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "All"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": "Network-Operation-Unknown",
                        "binLowerBound": 28,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "All"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": "Unknown-Auth-Timeout",
                        "binLowerBound": 29,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "All"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": "Unknown-Operation-Invalid",
                        "binLowerBound": 30,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "All"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.00103732892775499,
                        "binCatValue": "Client-Association-Blacklisted",
                        "binLowerBound": 0,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "Friday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.00233779443383307,
                        "binCatValue": "Client-Association-Fail",
                        "binLowerBound": 1,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "Friday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.00408319722637547,
                        "binCatValue": "Client-Association-Invalid",
                        "binLowerBound": 2,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "Friday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.000128414095243715,
                        "binCatValue": "Client-Association-Unknown",
                        "binLowerBound": 3,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "Friday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.333849785332484,
                        "binCatValue": "Client-Auth-Blacklisted",
                        "binLowerBound": 4,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "Friday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.282052229279748,
                        "binCatValue": "Client-Auth-Fail",
                        "binLowerBound": 5,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "Friday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.106862684079505,
                        "binCatValue": "Client-Auth-Invalid",
                        "binLowerBound": 6,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "Friday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.0631701723410741,
                        "binCatValue": "Client-Auth-Timeout",
                        "binLowerBound": 7,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "Friday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.00000223005664677942,
                        "binCatValue": "Client-Auth-Unknown",
                        "binLowerBound": 8,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "Friday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.00402155064535423,
                        "binCatValue": "Client-DHCP-Invalid",
                        "binLowerBound": 9,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "Friday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.00872111037619615,
                        "binCatValue": "Client-DHCP-Timeout",
                        "binLowerBound": 10,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "Friday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.00127465591291281,
                        "binCatValue": "Client-Operation-Invalid",
                        "binLowerBound": 11,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "Friday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.000167963914067754,
                        "binCatValue": "Client-Operation-Timeout",
                        "binLowerBound": 12,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "Friday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.0146086508147462,
                        "binCatValue": "Client-Operation-Unknown",
                        "binLowerBound": 13,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "Friday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.0000166282949918596,
                        "binCatValue": "Client-Unknown-Blacklisted",
                        "binLowerBound": 14,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "Friday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.0143076959117327,
                        "binCatValue": "Network-Association-Fail",
                        "binLowerBound": 15,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "Friday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.00531350353338636,
                        "binCatValue": "Network-Association-MaxStations",
                        "binLowerBound": 16,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "Friday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.000340789374083258,
                        "binCatValue": "Network-Association-Timeout",
                        "binLowerBound": 17,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "Friday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": "Network-Auth-Blacklisted",
                        "binLowerBound": 18,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "Friday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.00174690914752309,
                        "binCatValue": "Network-Auth-Fail",
                        "binLowerBound": 19,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "Friday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.00151873763410219,
                        "binCatValue": "Network-Auth-Invalid",
                        "binLowerBound": 20,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "Friday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.00249445535182505,
                        "binCatValue": "Network-Auth-Timeout",
                        "binLowerBound": 21,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "Friday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.0004259481183104,
                        "binCatValue": "Network-Auth-Unknown",
                        "binLowerBound": 22,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "Friday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.0000187309037889867,
                        "binCatValue": "Network-DHCP-Invalid",
                        "binLowerBound": 23,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "Friday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.00635290643628064,
                        "binCatValue": "Network-DHCP-Timeout",
                        "binLowerBound": 24,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "Friday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.0485419192610293,
                        "binCatValue": "Network-Operation-Fail",
                        "binLowerBound": 25,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "Friday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.00000169331592313362,
                        "binCatValue": "Network-Operation-Invalid",
                        "binLowerBound": 26,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "Friday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.0916889294994008,
                        "binCatValue": "Network-Operation-Timeout",
                        "binLowerBound": 27,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "Friday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.00491338578167928,
                        "binCatValue": "Network-Operation-Unknown",
                        "binLowerBound": 28,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "Friday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": "Unknown-Auth-Timeout",
                        "binLowerBound": 29,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "Friday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": "Unknown-Operation-Invalid",
                        "binLowerBound": 30,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "Friday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.000248275483240141,
                        "binCatValue": "Client-Association-Blacklisted",
                        "binLowerBound": 0,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "Friday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.00682818930392369,
                        "binCatValue": "Client-Association-Fail",
                        "binLowerBound": 1,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "Friday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.000149857290128906,
                        "binCatValue": "Client-Association-Invalid",
                        "binLowerBound": 2,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "Friday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.000259760858390332,
                        "binCatValue": "Client-Association-Unknown",
                        "binLowerBound": 3,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "Friday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.274649174470168,
                        "binCatValue": "Client-Auth-Blacklisted",
                        "binLowerBound": 4,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "Friday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.318849989019258,
                        "binCatValue": "Client-Auth-Fail",
                        "binLowerBound": 5,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "Friday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.105435490918348,
                        "binCatValue": "Client-Auth-Invalid",
                        "binLowerBound": 6,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "Friday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.0779603188172113,
                        "binCatValue": "Client-Auth-Timeout",
                        "binLowerBound": 7,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "Friday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.0000102177115999665,
                        "binCatValue": "Client-Auth-Unknown",
                        "binLowerBound": 8,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "Friday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.00220859125576957,
                        "binCatValue": "Client-DHCP-Invalid",
                        "binLowerBound": 9,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "Friday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.00798360167600688,
                        "binCatValue": "Client-DHCP-Timeout",
                        "binLowerBound": 10,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "Friday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.00451724208571677,
                        "binCatValue": "Client-Operation-Invalid",
                        "binLowerBound": 11,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "Friday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.000227516419526595,
                        "binCatValue": "Client-Operation-Timeout",
                        "binLowerBound": 12,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "Friday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.0334345734847692,
                        "binCatValue": "Client-Operation-Unknown",
                        "binLowerBound": 13,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "Friday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.0000237745577298573,
                        "binCatValue": "Client-Unknown-Blacklisted",
                        "binLowerBound": 14,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "Friday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.0196364288857875,
                        "binCatValue": "Network-Association-Fail",
                        "binLowerBound": 15,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "Friday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.011378808713379,
                        "binCatValue": "Network-Association-MaxStations",
                        "binLowerBound": 16,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "Friday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.000103059615861717,
                        "binCatValue": "Network-Association-Timeout",
                        "binLowerBound": 17,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "Friday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": "Network-Auth-Blacklisted",
                        "binLowerBound": 18,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "Friday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.000786801279410758,
                        "binCatValue": "Network-Auth-Fail",
                        "binLowerBound": 19,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "Friday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.000758826835015745,
                        "binCatValue": "Network-Auth-Invalid",
                        "binLowerBound": 20,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "Friday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.00205653229445435,
                        "binCatValue": "Network-Auth-Timeout",
                        "binLowerBound": 21,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "Friday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.000746765145104798,
                        "binCatValue": "Network-Auth-Unknown",
                        "binLowerBound": 22,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "Friday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.000013325061146667,
                        "binCatValue": "Network-DHCP-Invalid",
                        "binLowerBound": 23,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "Friday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.00703251148664138,
                        "binCatValue": "Network-DHCP-Timeout",
                        "binLowerBound": 24,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "Friday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.0678152769602628,
                        "binCatValue": "Network-Operation-Fail",
                        "binLowerBound": 25,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "Friday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.00000964225530211082,
                        "binCatValue": "Network-Operation-Invalid",
                        "binLowerBound": 26,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "Friday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.0558677666212861,
                        "binCatValue": "Network-Operation-Timeout",
                        "binLowerBound": 27,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "Friday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.00100757733439434,
                        "binCatValue": "Network-Operation-Unknown",
                        "binLowerBound": 28,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "Friday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": "Unknown-Auth-Timeout",
                        "binLowerBound": 29,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "Friday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 1.0416016530058e-7,
                        "binCatValue": "Unknown-Operation-Invalid",
                        "binLowerBound": 30,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "Friday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": "Client-Association-Blacklisted",
                        "binLowerBound": 0,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "Friday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": "Client-Association-Fail",
                        "binLowerBound": 1,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "Friday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": "Client-Association-Invalid",
                        "binLowerBound": 2,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "Friday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": "Client-Association-Unknown",
                        "binLowerBound": 3,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "Friday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": "Client-Auth-Blacklisted",
                        "binLowerBound": 4,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "Friday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": "Client-Auth-Fail",
                        "binLowerBound": 5,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "Friday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": "Client-Auth-Invalid",
                        "binLowerBound": 6,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "Friday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": "Client-Auth-Timeout",
                        "binLowerBound": 7,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "Friday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": "Client-Auth-Unknown",
                        "binLowerBound": 8,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "Friday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": "Client-DHCP-Invalid",
                        "binLowerBound": 9,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "Friday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": "Client-DHCP-Timeout",
                        "binLowerBound": 10,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "Friday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": "Client-Operation-Invalid",
                        "binLowerBound": 11,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "Friday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": "Client-Operation-Timeout",
                        "binLowerBound": 12,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "Friday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": "Client-Operation-Unknown",
                        "binLowerBound": 13,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "Friday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": "Client-Unknown-Blacklisted",
                        "binLowerBound": 14,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "Friday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": "Network-Association-Fail",
                        "binLowerBound": 15,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "Friday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": "Network-Association-MaxStations",
                        "binLowerBound": 16,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "Friday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": "Network-Association-Timeout",
                        "binLowerBound": 17,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "Friday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": "Network-Auth-Blacklisted",
                        "binLowerBound": 18,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "Friday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": "Network-Auth-Fail",
                        "binLowerBound": 19,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "Friday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": "Network-Auth-Invalid",
                        "binLowerBound": 20,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "Friday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": "Network-Auth-Timeout",
                        "binLowerBound": 21,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "Friday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": "Network-Auth-Unknown",
                        "binLowerBound": 22,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "Friday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": "Network-DHCP-Invalid",
                        "binLowerBound": 23,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "Friday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": "Network-DHCP-Timeout",
                        "binLowerBound": 24,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "Friday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": "Network-Operation-Fail",
                        "binLowerBound": 25,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "Friday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": "Network-Operation-Invalid",
                        "binLowerBound": 26,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "Friday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": "Network-Operation-Timeout",
                        "binLowerBound": 27,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "Friday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": "Network-Operation-Unknown",
                        "binLowerBound": 28,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "Friday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": "Unknown-Auth-Timeout",
                        "binLowerBound": 29,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "Friday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": "Unknown-Operation-Invalid",
                        "binLowerBound": 30,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "Friday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.0011148524732697,
                        "binCatValue": "Client-Association-Blacklisted",
                        "binLowerBound": 0,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "Monday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.00267230358516956,
                        "binCatValue": "Client-Association-Fail",
                        "binLowerBound": 1,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "Monday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.00418036457870085,
                        "binCatValue": "Client-Association-Invalid",
                        "binLowerBound": 2,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "Monday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.000130583761599216,
                        "binCatValue": "Client-Association-Unknown",
                        "binLowerBound": 3,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "Monday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.31631827917375,
                        "binCatValue": "Client-Auth-Blacklisted",
                        "binLowerBound": 4,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "Monday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.284282984658945,
                        "binCatValue": "Client-Auth-Fail",
                        "binLowerBound": 5,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "Monday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.11074768664634,
                        "binCatValue": "Client-Auth-Invalid",
                        "binLowerBound": 6,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "Monday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.0669592331557331,
                        "binCatValue": "Client-Auth-Timeout",
                        "binLowerBound": 7,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "Monday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.00000398198787877277,
                        "binCatValue": "Client-Auth-Unknown",
                        "binLowerBound": 8,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "Monday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.00381085219113095,
                        "binCatValue": "Client-DHCP-Invalid",
                        "binLowerBound": 9,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "Monday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.00847309841102615,
                        "binCatValue": "Client-DHCP-Timeout",
                        "binLowerBound": 10,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "Monday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.00116492600202274,
                        "binCatValue": "Client-Operation-Invalid",
                        "binLowerBound": 11,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "Monday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.000180049953021553,
                        "binCatValue": "Client-Operation-Timeout",
                        "binLowerBound": 12,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "Monday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.017172317115261,
                        "binCatValue": "Client-Operation-Unknown",
                        "binLowerBound": 13,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "Monday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.0000168140155263245,
                        "binCatValue": "Client-Unknown-Blacklisted",
                        "binLowerBound": 14,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "Monday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.0188992858035366,
                        "binCatValue": "Network-Association-Fail",
                        "binLowerBound": 15,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "Monday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.00556036344529964,
                        "binCatValue": "Network-Association-MaxStations",
                        "binLowerBound": 16,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "Monday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.000350759132722716,
                        "binCatValue": "Network-Association-Timeout",
                        "binLowerBound": 17,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "Monday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": "Network-Auth-Blacklisted",
                        "binLowerBound": 18,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "Monday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.0018330575501188,
                        "binCatValue": "Network-Auth-Fail",
                        "binLowerBound": 19,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "Monday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.00176091098833714,
                        "binCatValue": "Network-Auth-Invalid",
                        "binLowerBound": 20,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "Monday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.00354977882392526,
                        "binCatValue": "Network-Auth-Timeout",
                        "binLowerBound": 21,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "Monday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.000252888654882356,
                        "binCatValue": "Network-Auth-Unknown",
                        "binLowerBound": 22,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "Monday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.0000175123911016808,
                        "binCatValue": "Network-DHCP-Invalid",
                        "binLowerBound": 23,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "Monday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.00719116207555093,
                        "binCatValue": "Network-DHCP-Timeout",
                        "binLowerBound": 24,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "Monday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.0434505157851876,
                        "binCatValue": "Network-Operation-Fail",
                        "binLowerBound": 25,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "Monday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.00000233332268123516,
                        "binCatValue": "Network-Operation-Invalid",
                        "binLowerBound": 26,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "Monday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.0948349260471117,
                        "binCatValue": "Network-Operation-Timeout",
                        "binLowerBound": 27,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "Monday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.00506814584559002,
                        "binCatValue": "Network-Operation-Unknown",
                        "binLowerBound": 28,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "Monday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": "Unknown-Auth-Timeout",
                        "binLowerBound": 29,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "Monday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 3.24245802844009e-8,
                        "binCatValue": "Unknown-Operation-Invalid",
                        "binLowerBound": 30,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "Monday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.000243958435807528,
                        "binCatValue": "Client-Association-Blacklisted",
                        "binLowerBound": 0,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "Monday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.00725944460082913,
                        "binCatValue": "Client-Association-Fail",
                        "binLowerBound": 1,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "Monday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.000129255704934018,
                        "binCatValue": "Client-Association-Invalid",
                        "binLowerBound": 2,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "Monday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.000193323422631444,
                        "binCatValue": "Client-Association-Unknown",
                        "binLowerBound": 3,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "Monday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.262719717894345,
                        "binCatValue": "Client-Auth-Blacklisted",
                        "binLowerBound": 4,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "Monday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.304128531906682,
                        "binCatValue": "Client-Auth-Fail",
                        "binLowerBound": 5,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "Monday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.11048359747497,
                        "binCatValue": "Client-Auth-Invalid",
                        "binLowerBound": 6,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "Monday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.0803004876696518,
                        "binCatValue": "Client-Auth-Timeout",
                        "binLowerBound": 7,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "Monday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.0000115700736008663,
                        "binCatValue": "Client-Auth-Unknown",
                        "binLowerBound": 8,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "Monday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.00223373526900107,
                        "binCatValue": "Client-DHCP-Invalid",
                        "binLowerBound": 9,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "Monday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.00764910184141764,
                        "binCatValue": "Client-DHCP-Timeout",
                        "binLowerBound": 10,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "Monday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.00399530617710037,
                        "binCatValue": "Client-Operation-Invalid",
                        "binLowerBound": 11,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "Monday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.000225276259375105,
                        "binCatValue": "Client-Operation-Timeout",
                        "binLowerBound": 12,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "Monday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.0351100327161878,
                        "binCatValue": "Client-Operation-Unknown",
                        "binLowerBound": 13,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "Monday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.0000117423552610673,
                        "binCatValue": "Client-Unknown-Blacklisted",
                        "binLowerBound": 14,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "Monday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.0286111829802858,
                        "binCatValue": "Network-Association-Fail",
                        "binLowerBound": 15,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "Monday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.0109351390483993,
                        "binCatValue": "Network-Association-MaxStations",
                        "binLowerBound": 16,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "Monday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.000102600017369033,
                        "binCatValue": "Network-Association-Timeout",
                        "binLowerBound": 17,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "Monday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": "Network-Auth-Blacklisted",
                        "binLowerBound": 18,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "Monday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.000944906406487578,
                        "binCatValue": "Network-Auth-Fail",
                        "binLowerBound": 19,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "Monday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.000916421432839577,
                        "binCatValue": "Network-Auth-Invalid",
                        "binLowerBound": 20,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "Monday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.00575837701788813,
                        "binCatValue": "Network-Auth-Timeout",
                        "binLowerBound": 21,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "Monday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.000693554484220118,
                        "binCatValue": "Network-Auth-Unknown",
                        "binLowerBound": 22,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "Monday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.0000112363327277096,
                        "binCatValue": "Network-DHCP-Invalid",
                        "binLowerBound": 23,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "Monday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.00763006398671899,
                        "binCatValue": "Network-DHCP-Timeout",
                        "binLowerBound": 24,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "Monday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.0730806605382046,
                        "binCatValue": "Network-Operation-Fail",
                        "binLowerBound": 25,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "Monday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.000013123241029843,
                        "binCatValue": "Network-Operation-Invalid",
                        "binLowerBound": 26,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "Monday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.0555805621036593,
                        "binCatValue": "Network-Operation-Timeout",
                        "binLowerBound": 27,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "Monday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.00102701572873994,
                        "binCatValue": "Network-Operation-Unknown",
                        "binLowerBound": 28,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "Monday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": "Unknown-Auth-Timeout",
                        "binLowerBound": 29,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "Monday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 7.48796349939776e-8,
                        "binCatValue": "Unknown-Operation-Invalid",
                        "binLowerBound": 30,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "Monday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": "Client-Association-Blacklisted",
                        "binLowerBound": 0,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "Monday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": "Client-Association-Fail",
                        "binLowerBound": 1,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "Monday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": "Client-Association-Invalid",
                        "binLowerBound": 2,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "Monday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": "Client-Association-Unknown",
                        "binLowerBound": 3,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "Monday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": "Client-Auth-Blacklisted",
                        "binLowerBound": 4,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "Monday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": "Client-Auth-Fail",
                        "binLowerBound": 5,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "Monday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": "Client-Auth-Invalid",
                        "binLowerBound": 6,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "Monday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": "Client-Auth-Timeout",
                        "binLowerBound": 7,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "Monday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": "Client-Auth-Unknown",
                        "binLowerBound": 8,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "Monday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": "Client-DHCP-Invalid",
                        "binLowerBound": 9,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "Monday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": "Client-DHCP-Timeout",
                        "binLowerBound": 10,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "Monday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": "Client-Operation-Invalid",
                        "binLowerBound": 11,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "Monday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": "Client-Operation-Timeout",
                        "binLowerBound": 12,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "Monday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": "Client-Operation-Unknown",
                        "binLowerBound": 13,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "Monday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": "Client-Unknown-Blacklisted",
                        "binLowerBound": 14,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "Monday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": "Network-Association-Fail",
                        "binLowerBound": 15,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "Monday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": "Network-Association-MaxStations",
                        "binLowerBound": 16,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "Monday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": "Network-Association-Timeout",
                        "binLowerBound": 17,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "Monday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": "Network-Auth-Blacklisted",
                        "binLowerBound": 18,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "Monday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": "Network-Auth-Fail",
                        "binLowerBound": 19,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "Monday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": "Network-Auth-Invalid",
                        "binLowerBound": 20,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "Monday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": "Network-Auth-Timeout",
                        "binLowerBound": 21,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "Monday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": "Network-Auth-Unknown",
                        "binLowerBound": 22,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "Monday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": "Network-DHCP-Invalid",
                        "binLowerBound": 23,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "Monday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": "Network-DHCP-Timeout",
                        "binLowerBound": 24,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "Monday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": "Network-Operation-Fail",
                        "binLowerBound": 25,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "Monday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": "Network-Operation-Invalid",
                        "binLowerBound": 26,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "Monday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": "Network-Operation-Timeout",
                        "binLowerBound": 27,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "Monday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": "Network-Operation-Unknown",
                        "binLowerBound": 28,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "Monday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": "Unknown-Auth-Timeout",
                        "binLowerBound": 29,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "Monday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": "Unknown-Operation-Invalid",
                        "binLowerBound": 30,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "Monday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.00127582921628173,
                        "binCatValue": "Client-Association-Blacklisted",
                        "binLowerBound": 0,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "Saturday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.000897157628671137,
                        "binCatValue": "Client-Association-Fail",
                        "binLowerBound": 1,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "Saturday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.0035634458438651,
                        "binCatValue": "Client-Association-Invalid",
                        "binLowerBound": 2,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "Saturday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.000106658570565185,
                        "binCatValue": "Client-Association-Unknown",
                        "binLowerBound": 3,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "Saturday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.348731470043436,
                        "binCatValue": "Client-Auth-Blacklisted",
                        "binLowerBound": 4,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "Saturday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.280003014112547,
                        "binCatValue": "Client-Auth-Fail",
                        "binLowerBound": 5,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "Saturday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.107567308086669,
                        "binCatValue": "Client-Auth-Invalid",
                        "binLowerBound": 6,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "Saturday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.0589208825765206,
                        "binCatValue": "Client-Auth-Timeout",
                        "binLowerBound": 7,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "Saturday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.00000147481287868255,
                        "binCatValue": "Client-Auth-Unknown",
                        "binLowerBound": 8,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "Saturday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.00249563875841778,
                        "binCatValue": "Client-DHCP-Invalid",
                        "binLowerBound": 9,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "Saturday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.00872113281105912,
                        "binCatValue": "Client-DHCP-Timeout",
                        "binLowerBound": 10,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "Saturday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.00129201217613767,
                        "binCatValue": "Client-Operation-Invalid",
                        "binLowerBound": 11,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "Saturday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.000127449111539705,
                        "binCatValue": "Client-Operation-Timeout",
                        "binLowerBound": 12,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "Saturday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.00992753039068936,
                        "binCatValue": "Client-Operation-Unknown",
                        "binLowerBound": 13,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "Saturday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.0000184509602469896,
                        "binCatValue": "Client-Unknown-Blacklisted",
                        "binLowerBound": 14,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "Saturday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.0120469880413118,
                        "binCatValue": "Network-Association-Fail",
                        "binLowerBound": 15,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "Saturday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.00433950502003863,
                        "binCatValue": "Network-Association-MaxStations",
                        "binLowerBound": 16,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "Saturday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.000336609434210964,
                        "binCatValue": "Network-Association-Timeout",
                        "binLowerBound": 17,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "Saturday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": "Network-Auth-Blacklisted",
                        "binLowerBound": 18,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "Saturday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.00261460269817887,
                        "binCatValue": "Network-Auth-Fail",
                        "binLowerBound": 19,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "Saturday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.00163169505074208,
                        "binCatValue": "Network-Auth-Invalid",
                        "binLowerBound": 20,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "Saturday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.00231916743427442,
                        "binCatValue": "Network-Auth-Timeout",
                        "binLowerBound": 21,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "Saturday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.000344029284225009,
                        "binCatValue": "Network-Auth-Unknown",
                        "binLowerBound": 22,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "Saturday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.0000109988668381327,
                        "binCatValue": "Network-DHCP-Invalid",
                        "binLowerBound": 23,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "Saturday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.00654935186907221,
                        "binCatValue": "Network-DHCP-Timeout",
                        "binLowerBound": 24,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "Saturday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.0475446487185314,
                        "binCatValue": "Network-Operation-Fail",
                        "binLowerBound": 25,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "Saturday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 2.68265044832506e-7,
                        "binCatValue": "Network-Operation-Invalid",
                        "binLowerBound": 26,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "Saturday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.0933508554688537,
                        "binCatValue": "Network-Operation-Timeout",
                        "binLowerBound": 27,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "Saturday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.00526182474915283,
                        "binCatValue": "Network-Operation-Unknown",
                        "binLowerBound": 28,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "Saturday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": "Unknown-Auth-Timeout",
                        "binLowerBound": 29,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "Saturday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": "Unknown-Operation-Invalid",
                        "binLowerBound": 30,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "Saturday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.000311597180868003,
                        "binCatValue": "Client-Association-Blacklisted",
                        "binLowerBound": 0,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "Saturday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.00450877528207332,
                        "binCatValue": "Client-Association-Fail",
                        "binLowerBound": 1,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "Saturday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.00012485827414484,
                        "binCatValue": "Client-Association-Invalid",
                        "binLowerBound": 2,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "Saturday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.000286737576145941,
                        "binCatValue": "Client-Association-Unknown",
                        "binLowerBound": 3,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "Saturday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.311415742273039,
                        "binCatValue": "Client-Auth-Blacklisted",
                        "binLowerBound": 4,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "Saturday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.355137095295507,
                        "binCatValue": "Client-Auth-Fail",
                        "binLowerBound": 5,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "Saturday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.0632282515257604,
                        "binCatValue": "Client-Auth-Invalid",
                        "binLowerBound": 6,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "Saturday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.0745101927301633,
                        "binCatValue": "Client-Auth-Timeout",
                        "binLowerBound": 7,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "Saturday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.00000980723187919852,
                        "binCatValue": "Client-Auth-Unknown",
                        "binLowerBound": 8,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "Saturday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.00127822974749791,
                        "binCatValue": "Client-DHCP-Invalid",
                        "binLowerBound": 9,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "Saturday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.00850952434913996,
                        "binCatValue": "Client-DHCP-Timeout",
                        "binLowerBound": 10,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "Saturday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.00535682729925818,
                        "binCatValue": "Client-Operation-Invalid",
                        "binLowerBound": 11,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "Saturday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.000170882464021659,
                        "binCatValue": "Client-Operation-Timeout",
                        "binLowerBound": 12,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "Saturday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.02710181670234,
                        "binCatValue": "Client-Operation-Unknown",
                        "binLowerBound": 13,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "Saturday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.0000223459520696869,
                        "binCatValue": "Client-Unknown-Blacklisted",
                        "binLowerBound": 14,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "Saturday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.0120853856753752,
                        "binCatValue": "Network-Association-Fail",
                        "binLowerBound": 15,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "Saturday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.00675148913862399,
                        "binCatValue": "Network-Association-MaxStations",
                        "binLowerBound": 16,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "Saturday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.0000925993758320693,
                        "binCatValue": "Network-Association-Timeout",
                        "binLowerBound": 17,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "Saturday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": "Network-Auth-Blacklisted",
                        "binLowerBound": 18,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "Saturday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.000710646480274674,
                        "binCatValue": "Network-Auth-Fail",
                        "binLowerBound": 19,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "Saturday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.000727579999289699,
                        "binCatValue": "Network-Auth-Invalid",
                        "binLowerBound": 20,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "Saturday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.0016934686500256,
                        "binCatValue": "Network-Auth-Timeout",
                        "binLowerBound": 21,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "Saturday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.000379797539139179,
                        "binCatValue": "Network-Auth-Unknown",
                        "binLowerBound": 22,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "Saturday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.0000137695628514788,
                        "binCatValue": "Network-DHCP-Invalid",
                        "binLowerBound": 23,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "Saturday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.00724765363249778,
                        "binCatValue": "Network-DHCP-Timeout",
                        "binLowerBound": 24,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "Saturday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.0620406428361333,
                        "binCatValue": "Network-Operation-Fail",
                        "binLowerBound": 25,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "Saturday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.00000153196842478389,
                        "binCatValue": "Network-Operation-Invalid",
                        "binLowerBound": 26,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "Saturday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.0556366553205112,
                        "binCatValue": "Network-Operation-Timeout",
                        "binLowerBound": 27,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "Saturday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.000646022969285248,
                        "binCatValue": "Network-Operation-Unknown",
                        "binLowerBound": 28,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "Saturday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": "Unknown-Auth-Timeout",
                        "binLowerBound": 29,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "Saturday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 7.29678269239537e-8,
                        "binCatValue": "Unknown-Operation-Invalid",
                        "binLowerBound": 30,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "Saturday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": "Client-Association-Blacklisted",
                        "binLowerBound": 0,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "Saturday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": "Client-Association-Fail",
                        "binLowerBound": 1,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "Saturday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": "Client-Association-Invalid",
                        "binLowerBound": 2,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "Saturday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": "Client-Association-Unknown",
                        "binLowerBound": 3,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "Saturday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": "Client-Auth-Blacklisted",
                        "binLowerBound": 4,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "Saturday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": "Client-Auth-Fail",
                        "binLowerBound": 5,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "Saturday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": "Client-Auth-Invalid",
                        "binLowerBound": 6,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "Saturday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": "Client-Auth-Timeout",
                        "binLowerBound": 7,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "Saturday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": "Client-Auth-Unknown",
                        "binLowerBound": 8,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "Saturday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": "Client-DHCP-Invalid",
                        "binLowerBound": 9,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "Saturday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": "Client-DHCP-Timeout",
                        "binLowerBound": 10,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "Saturday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": "Client-Operation-Invalid",
                        "binLowerBound": 11,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "Saturday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": "Client-Operation-Timeout",
                        "binLowerBound": 12,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "Saturday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": "Client-Operation-Unknown",
                        "binLowerBound": 13,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "Saturday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": "Client-Unknown-Blacklisted",
                        "binLowerBound": 14,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "Saturday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": "Network-Association-Fail",
                        "binLowerBound": 15,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "Saturday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": "Network-Association-MaxStations",
                        "binLowerBound": 16,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "Saturday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": "Network-Association-Timeout",
                        "binLowerBound": 17,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "Saturday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": "Network-Auth-Blacklisted",
                        "binLowerBound": 18,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "Saturday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": "Network-Auth-Fail",
                        "binLowerBound": 19,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "Saturday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": "Network-Auth-Invalid",
                        "binLowerBound": 20,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "Saturday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": "Network-Auth-Timeout",
                        "binLowerBound": 21,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "Saturday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": "Network-Auth-Unknown",
                        "binLowerBound": 22,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "Saturday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": "Network-DHCP-Invalid",
                        "binLowerBound": 23,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "Saturday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": "Network-DHCP-Timeout",
                        "binLowerBound": 24,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "Saturday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": "Network-Operation-Fail",
                        "binLowerBound": 25,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "Saturday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": "Network-Operation-Invalid",
                        "binLowerBound": 26,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "Saturday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": "Network-Operation-Timeout",
                        "binLowerBound": 27,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "Saturday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": "Network-Operation-Unknown",
                        "binLowerBound": 28,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "Saturday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": "Unknown-Auth-Timeout",
                        "binLowerBound": 29,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "Saturday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": "Unknown-Operation-Invalid",
                        "binLowerBound": 30,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "Saturday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.00133537759076657,
                        "binCatValue": "Client-Association-Blacklisted",
                        "binLowerBound": 0,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "Sunday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.00108605400368915,
                        "binCatValue": "Client-Association-Fail",
                        "binLowerBound": 1,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "Sunday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.00425190625380272,
                        "binCatValue": "Client-Association-Invalid",
                        "binLowerBound": 2,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "Sunday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.0000839291263518179,
                        "binCatValue": "Client-Association-Unknown",
                        "binLowerBound": 3,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "Sunday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.322925134745713,
                        "binCatValue": "Client-Auth-Blacklisted",
                        "binLowerBound": 4,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "Sunday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.296014182695156,
                        "binCatValue": "Client-Auth-Fail",
                        "binLowerBound": 5,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "Sunday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.111716777909447,
                        "binCatValue": "Client-Auth-Invalid",
                        "binLowerBound": 6,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "Sunday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.0602435574719265,
                        "binCatValue": "Client-Auth-Timeout",
                        "binLowerBound": 7,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "Sunday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.00000251524278345887,
                        "binCatValue": "Client-Auth-Unknown",
                        "binLowerBound": 8,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "Sunday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.00286450254405816,
                        "binCatValue": "Client-DHCP-Invalid",
                        "binLowerBound": 9,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "Sunday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.00816533169074298,
                        "binCatValue": "Client-DHCP-Timeout",
                        "binLowerBound": 10,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "Sunday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.00114356548012749,
                        "binCatValue": "Client-Operation-Invalid",
                        "binLowerBound": 11,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "Sunday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.000139125316545964,
                        "binCatValue": "Client-Operation-Timeout",
                        "binLowerBound": 12,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "Sunday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.00906189120871021,
                        "binCatValue": "Client-Operation-Unknown",
                        "binLowerBound": 13,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "Sunday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.0000247618694479429,
                        "binCatValue": "Client-Unknown-Blacklisted",
                        "binLowerBound": 14,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "Sunday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.0130964517203445,
                        "binCatValue": "Network-Association-Fail",
                        "binLowerBound": 15,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "Sunday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.00440366975909979,
                        "binCatValue": "Network-Association-MaxStations",
                        "binLowerBound": 16,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "Sunday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.000324689662335204,
                        "binCatValue": "Network-Association-Timeout",
                        "binLowerBound": 17,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "Sunday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": "Network-Auth-Blacklisted",
                        "binLowerBound": 18,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "Sunday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.00263659769341245,
                        "binCatValue": "Network-Auth-Fail",
                        "binLowerBound": 19,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "Sunday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.000330646262399798,
                        "binCatValue": "Network-Auth-Invalid",
                        "binLowerBound": 20,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "Sunday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.0028603011180605,
                        "binCatValue": "Network-Auth-Timeout",
                        "binLowerBound": 21,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "Sunday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.000123168550844858,
                        "binCatValue": "Network-Auth-Unknown",
                        "binLowerBound": 22,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "Sunday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.0000107625730260137,
                        "binCatValue": "Network-DHCP-Invalid",
                        "binLowerBound": 23,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "Sunday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.00663996849292997,
                        "binCatValue": "Network-DHCP-Timeout",
                        "binLowerBound": 24,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "Sunday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.0404560770116123,
                        "binCatValue": "Network-Operation-Fail",
                        "binLowerBound": 25,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "Sunday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 2.94672794714847e-7,
                        "binCatValue": "Network-Operation-Invalid",
                        "binLowerBound": 26,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "Sunday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.102793351342168,
                        "binCatValue": "Network-Operation-Timeout",
                        "binLowerBound": 27,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "Sunday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.00726540799170276,
                        "binCatValue": "Network-Operation-Unknown",
                        "binLowerBound": 28,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "Sunday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": "Unknown-Auth-Timeout",
                        "binLowerBound": 29,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "Sunday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": "Unknown-Operation-Invalid",
                        "binLowerBound": 30,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "Sunday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.000293398714776191,
                        "binCatValue": "Client-Association-Blacklisted",
                        "binLowerBound": 0,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "Sunday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.00898374417682789,
                        "binCatValue": "Client-Association-Fail",
                        "binLowerBound": 1,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "Sunday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.000188184531673977,
                        "binCatValue": "Client-Association-Invalid",
                        "binLowerBound": 2,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "Sunday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.000277660990402602,
                        "binCatValue": "Client-Association-Unknown",
                        "binLowerBound": 3,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "Sunday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.266091049465742,
                        "binCatValue": "Client-Auth-Blacklisted",
                        "binLowerBound": 4,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "Sunday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.369079400728346,
                        "binCatValue": "Client-Auth-Fail",
                        "binLowerBound": 5,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "Sunday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.0719065309900549,
                        "binCatValue": "Client-Auth-Invalid",
                        "binLowerBound": 6,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "Sunday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.079884938423424,
                        "binCatValue": "Client-Auth-Timeout",
                        "binLowerBound": 7,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "Sunday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.0000069691709707576,
                        "binCatValue": "Client-Auth-Unknown",
                        "binLowerBound": 8,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "Sunday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.00184356562279117,
                        "binCatValue": "Client-DHCP-Invalid",
                        "binLowerBound": 9,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "Sunday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.00846633679788363,
                        "binCatValue": "Client-DHCP-Timeout",
                        "binLowerBound": 10,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "Sunday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.00504960346555446,
                        "binCatValue": "Client-Operation-Invalid",
                        "binLowerBound": 11,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "Sunday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.000188851987672086,
                        "binCatValue": "Client-Operation-Timeout",
                        "binLowerBound": 12,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "Sunday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.0274668193826665,
                        "binCatValue": "Client-Operation-Unknown",
                        "binLowerBound": 13,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "Sunday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.0000202478098349438,
                        "binCatValue": "Client-Unknown-Blacklisted",
                        "binLowerBound": 14,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "Sunday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.015849618050349,
                        "binCatValue": "Network-Association-Fail",
                        "binLowerBound": 15,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "Sunday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.00661604445909884,
                        "binCatValue": "Network-Association-MaxStations",
                        "binLowerBound": 16,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "Sunday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.0000866959794123189,
                        "binCatValue": "Network-Association-Timeout",
                        "binLowerBound": 17,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "Sunday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": "Network-Auth-Blacklisted",
                        "binLowerBound": 18,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "Sunday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.000929707706055291,
                        "binCatValue": "Network-Auth-Fail",
                        "binLowerBound": 19,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "Sunday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.0000316981690168743,
                        "binCatValue": "Network-Auth-Invalid",
                        "binLowerBound": 20,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "Sunday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.0018049807456882,
                        "binCatValue": "Network-Auth-Timeout",
                        "binLowerBound": 21,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "Sunday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.000295555436375151,
                        "binCatValue": "Network-Auth-Unknown",
                        "binLowerBound": 22,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "Sunday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.0000181784847975101,
                        "binCatValue": "Network-DHCP-Invalid",
                        "binLowerBound": 23,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "Sunday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.00719440318678706,
                        "binCatValue": "Network-DHCP-Timeout",
                        "binLowerBound": 24,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "Sunday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.0664418608142927,
                        "binCatValue": "Network-Operation-Fail",
                        "binLowerBound": 25,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "Sunday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.00000109950512888166,
                        "binCatValue": "Network-Operation-Invalid",
                        "binLowerBound": 26,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "Sunday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.0602066757692958,
                        "binCatValue": "Network-Operation-Timeout",
                        "binLowerBound": 27,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "Sunday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.000776106134739543,
                        "binCatValue": "Network-Operation-Unknown",
                        "binLowerBound": 28,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "Sunday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": "Unknown-Auth-Timeout",
                        "binLowerBound": 29,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "Sunday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 7.33003419254441e-8,
                        "binCatValue": "Unknown-Operation-Invalid",
                        "binLowerBound": 30,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "Sunday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": "Client-Association-Blacklisted",
                        "binLowerBound": 0,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "Sunday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": "Client-Association-Fail",
                        "binLowerBound": 1,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "Sunday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": "Client-Association-Invalid",
                        "binLowerBound": 2,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "Sunday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": "Client-Association-Unknown",
                        "binLowerBound": 3,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "Sunday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": "Client-Auth-Blacklisted",
                        "binLowerBound": 4,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "Sunday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": "Client-Auth-Fail",
                        "binLowerBound": 5,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "Sunday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": "Client-Auth-Invalid",
                        "binLowerBound": 6,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "Sunday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": "Client-Auth-Timeout",
                        "binLowerBound": 7,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "Sunday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": "Client-Auth-Unknown",
                        "binLowerBound": 8,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "Sunday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": "Client-DHCP-Invalid",
                        "binLowerBound": 9,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "Sunday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": "Client-DHCP-Timeout",
                        "binLowerBound": 10,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "Sunday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": "Client-Operation-Invalid",
                        "binLowerBound": 11,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "Sunday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": "Client-Operation-Timeout",
                        "binLowerBound": 12,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "Sunday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": "Client-Operation-Unknown",
                        "binLowerBound": 13,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "Sunday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": "Client-Unknown-Blacklisted",
                        "binLowerBound": 14,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "Sunday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": "Network-Association-Fail",
                        "binLowerBound": 15,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "Sunday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": "Network-Association-MaxStations",
                        "binLowerBound": 16,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "Sunday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": "Network-Association-Timeout",
                        "binLowerBound": 17,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "Sunday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": "Network-Auth-Blacklisted",
                        "binLowerBound": 18,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "Sunday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": "Network-Auth-Fail",
                        "binLowerBound": 19,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "Sunday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": "Network-Auth-Invalid",
                        "binLowerBound": 20,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "Sunday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": "Network-Auth-Timeout",
                        "binLowerBound": 21,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "Sunday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": "Network-Auth-Unknown",
                        "binLowerBound": 22,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "Sunday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": "Network-DHCP-Invalid",
                        "binLowerBound": 23,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "Sunday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": "Network-DHCP-Timeout",
                        "binLowerBound": 24,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "Sunday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": "Network-Operation-Fail",
                        "binLowerBound": 25,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "Sunday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": "Network-Operation-Invalid",
                        "binLowerBound": 26,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "Sunday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": "Network-Operation-Timeout",
                        "binLowerBound": 27,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "Sunday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": "Network-Operation-Unknown",
                        "binLowerBound": 28,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "Sunday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": "Unknown-Auth-Timeout",
                        "binLowerBound": 29,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "Sunday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": "Unknown-Operation-Invalid",
                        "binLowerBound": 30,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "Sunday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.00116826386791123,
                        "binCatValue": "Client-Association-Blacklisted",
                        "binLowerBound": 0,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "Thursday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.00324718632222774,
                        "binCatValue": "Client-Association-Fail",
                        "binLowerBound": 1,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "Thursday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.00412876963180979,
                        "binCatValue": "Client-Association-Invalid",
                        "binLowerBound": 2,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "Thursday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.000119958764718425,
                        "binCatValue": "Client-Association-Unknown",
                        "binLowerBound": 3,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "Thursday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.325516234424074,
                        "binCatValue": "Client-Auth-Blacklisted",
                        "binLowerBound": 4,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "Thursday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.275633485201552,
                        "binCatValue": "Client-Auth-Fail",
                        "binLowerBound": 5,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "Thursday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.108862328040256,
                        "binCatValue": "Client-Auth-Invalid",
                        "binLowerBound": 6,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "Thursday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.0661897100071888,
                        "binCatValue": "Client-Auth-Timeout",
                        "binLowerBound": 7,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "Thursday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.00000296817417611346,
                        "binCatValue": "Client-Auth-Unknown",
                        "binLowerBound": 8,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "Thursday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.00432047724178245,
                        "binCatValue": "Client-DHCP-Invalid",
                        "binLowerBound": 9,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "Thursday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.00867208688945752,
                        "binCatValue": "Client-DHCP-Timeout",
                        "binLowerBound": 10,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "Thursday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.00122995024691568,
                        "binCatValue": "Client-Operation-Invalid",
                        "binLowerBound": 11,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "Thursday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.000184783667161545,
                        "binCatValue": "Client-Operation-Timeout",
                        "binLowerBound": 12,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "Thursday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.017481503316695,
                        "binCatValue": "Client-Operation-Unknown",
                        "binLowerBound": 13,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "Thursday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.0000254003688394686,
                        "binCatValue": "Client-Unknown-Blacklisted",
                        "binLowerBound": 14,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "Thursday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.0180389723565804,
                        "binCatValue": "Network-Association-Fail",
                        "binLowerBound": 15,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "Thursday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.00546291325045798,
                        "binCatValue": "Network-Association-MaxStations",
                        "binLowerBound": 16,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "Thursday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.000333223642604017,
                        "binCatValue": "Network-Association-Timeout",
                        "binLowerBound": 17,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "Thursday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": "Network-Auth-Blacklisted",
                        "binLowerBound": 18,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "Thursday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.00161793848203281,
                        "binCatValue": "Network-Auth-Fail",
                        "binLowerBound": 19,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "Thursday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.00139909790027806,
                        "binCatValue": "Network-Auth-Invalid",
                        "binLowerBound": 20,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "Thursday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.00298156707826227,
                        "binCatValue": "Network-Auth-Timeout",
                        "binLowerBound": 21,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "Thursday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.000493785908765389,
                        "binCatValue": "Network-Auth-Unknown",
                        "binLowerBound": 22,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "Thursday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.0000230170964034719,
                        "binCatValue": "Network-DHCP-Invalid",
                        "binLowerBound": 23,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "Thursday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.00639820886850089,
                        "binCatValue": "Network-DHCP-Timeout",
                        "binLowerBound": 24,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "Thursday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.0491800827730625,
                        "binCatValue": "Network-Operation-Fail",
                        "binLowerBound": 25,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "Thursday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.00000204634747049159,
                        "binCatValue": "Network-Operation-Invalid",
                        "binLowerBound": 26,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "Thursday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.0925032786559225,
                        "binCatValue": "Network-Operation-Timeout",
                        "binLowerBound": 27,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "Thursday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.00478276147489304,
                        "binCatValue": "Network-Operation-Unknown",
                        "binLowerBound": 28,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "Thursday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": "Unknown-Auth-Timeout",
                        "binLowerBound": 29,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "Thursday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": "Unknown-Operation-Invalid",
                        "binLowerBound": 30,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "Thursday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.000264374100643264,
                        "binCatValue": "Client-Association-Blacklisted",
                        "binLowerBound": 0,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "Thursday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.00877936212115575,
                        "binCatValue": "Client-Association-Fail",
                        "binLowerBound": 1,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "Thursday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.000160431048784945,
                        "binCatValue": "Client-Association-Invalid",
                        "binLowerBound": 2,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "Thursday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.000197364061267597,
                        "binCatValue": "Client-Association-Unknown",
                        "binLowerBound": 3,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "Thursday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.260907503327837,
                        "binCatValue": "Client-Auth-Blacklisted",
                        "binLowerBound": 4,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "Thursday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.298796772582991,
                        "binCatValue": "Client-Auth-Fail",
                        "binLowerBound": 5,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "Thursday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.118903977955813,
                        "binCatValue": "Client-Auth-Invalid",
                        "binLowerBound": 6,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "Thursday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.0798900787508142,
                        "binCatValue": "Client-Auth-Timeout",
                        "binLowerBound": 7,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "Thursday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.0000119130631032459,
                        "binCatValue": "Client-Auth-Unknown",
                        "binLowerBound": 8,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "Thursday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.00241622934233135,
                        "binCatValue": "Client-DHCP-Invalid",
                        "binLowerBound": 9,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "Thursday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.00776727329282378,
                        "binCatValue": "Client-DHCP-Timeout",
                        "binLowerBound": 10,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "Thursday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.00415644548011178,
                        "binCatValue": "Client-Operation-Invalid",
                        "binLowerBound": 11,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "Thursday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.000221311100666564,
                        "binCatValue": "Client-Operation-Timeout",
                        "binLowerBound": 12,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "Thursday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.0365698621636311,
                        "binCatValue": "Client-Operation-Unknown",
                        "binLowerBound": 13,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "Thursday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.0000279174550025187,
                        "binCatValue": "Client-Unknown-Blacklisted",
                        "binLowerBound": 14,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "Thursday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.024831045609027,
                        "binCatValue": "Network-Association-Fail",
                        "binLowerBound": 15,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "Thursday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.0117084395799631,
                        "binCatValue": "Network-Association-MaxStations",
                        "binLowerBound": 16,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "Thursday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.0000995650083033111,
                        "binCatValue": "Network-Association-Timeout",
                        "binLowerBound": 17,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "Thursday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": "Network-Auth-Blacklisted",
                        "binLowerBound": 18,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "Thursday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.000925290540649587,
                        "binCatValue": "Network-Auth-Fail",
                        "binLowerBound": 19,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "Thursday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.000705236314455878,
                        "binCatValue": "Network-Auth-Invalid",
                        "binLowerBound": 20,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "Thursday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.00332524849607082,
                        "binCatValue": "Network-Auth-Timeout",
                        "binLowerBound": 21,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "Thursday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.000922879023029707,
                        "binCatValue": "Network-Auth-Unknown",
                        "binLowerBound": 22,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "Thursday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.0000104745593713095,
                        "binCatValue": "Network-DHCP-Invalid",
                        "binLowerBound": 23,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "Thursday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.00746283541021201,
                        "binCatValue": "Network-DHCP-Timeout",
                        "binLowerBound": 24,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "Thursday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.0738311057023647,
                        "binCatValue": "Network-Operation-Fail",
                        "binLowerBound": 25,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "Thursday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.0000110098467448638,
                        "binCatValue": "Network-Operation-Invalid",
                        "binLowerBound": 26,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "Thursday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.0560113285801475,
                        "binCatValue": "Network-Operation-Timeout",
                        "binLowerBound": 27,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "Thursday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.00108469849776516,
                        "binCatValue": "Network-Operation-Unknown",
                        "binLowerBound": 28,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "Thursday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": "Unknown-Auth-Timeout",
                        "binLowerBound": 29,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "Thursday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 2.69849184923132e-8,
                        "binCatValue": "Unknown-Operation-Invalid",
                        "binLowerBound": 30,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "Thursday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": "Client-Association-Blacklisted",
                        "binLowerBound": 0,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "Thursday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": "Client-Association-Fail",
                        "binLowerBound": 1,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "Thursday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": "Client-Association-Invalid",
                        "binLowerBound": 2,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "Thursday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": "Client-Association-Unknown",
                        "binLowerBound": 3,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "Thursday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": "Client-Auth-Blacklisted",
                        "binLowerBound": 4,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "Thursday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": "Client-Auth-Fail",
                        "binLowerBound": 5,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "Thursday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": "Client-Auth-Invalid",
                        "binLowerBound": 6,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "Thursday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": "Client-Auth-Timeout",
                        "binLowerBound": 7,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "Thursday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": "Client-Auth-Unknown",
                        "binLowerBound": 8,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "Thursday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": "Client-DHCP-Invalid",
                        "binLowerBound": 9,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "Thursday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": "Client-DHCP-Timeout",
                        "binLowerBound": 10,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "Thursday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": "Client-Operation-Invalid",
                        "binLowerBound": 11,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "Thursday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": "Client-Operation-Timeout",
                        "binLowerBound": 12,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "Thursday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": "Client-Operation-Unknown",
                        "binLowerBound": 13,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "Thursday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": "Client-Unknown-Blacklisted",
                        "binLowerBound": 14,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "Thursday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": "Network-Association-Fail",
                        "binLowerBound": 15,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "Thursday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": "Network-Association-MaxStations",
                        "binLowerBound": 16,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "Thursday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": "Network-Association-Timeout",
                        "binLowerBound": 17,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "Thursday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": "Network-Auth-Blacklisted",
                        "binLowerBound": 18,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "Thursday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": "Network-Auth-Fail",
                        "binLowerBound": 19,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "Thursday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": "Network-Auth-Invalid",
                        "binLowerBound": 20,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "Thursday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": "Network-Auth-Timeout",
                        "binLowerBound": 21,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "Thursday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": "Network-Auth-Unknown",
                        "binLowerBound": 22,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "Thursday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": "Network-DHCP-Invalid",
                        "binLowerBound": 23,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "Thursday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": "Network-DHCP-Timeout",
                        "binLowerBound": 24,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "Thursday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": "Network-Operation-Fail",
                        "binLowerBound": 25,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "Thursday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": "Network-Operation-Invalid",
                        "binLowerBound": 26,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "Thursday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": "Network-Operation-Timeout",
                        "binLowerBound": 27,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "Thursday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": "Network-Operation-Unknown",
                        "binLowerBound": 28,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "Thursday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": "Unknown-Auth-Timeout",
                        "binLowerBound": 29,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "Thursday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": "Unknown-Operation-Invalid",
                        "binLowerBound": 30,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "Thursday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.00124295344275657,
                        "binCatValue": "Client-Association-Blacklisted",
                        "binLowerBound": 0,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "Tuesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.00338927422104782,
                        "binCatValue": "Client-Association-Fail",
                        "binLowerBound": 1,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "Tuesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.00397946279990528,
                        "binCatValue": "Client-Association-Invalid",
                        "binLowerBound": 2,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "Tuesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.000136181409530341,
                        "binCatValue": "Client-Association-Unknown",
                        "binLowerBound": 3,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "Tuesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.313765867635602,
                        "binCatValue": "Client-Auth-Blacklisted",
                        "binLowerBound": 4,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "Tuesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.280605710600346,
                        "binCatValue": "Client-Auth-Fail",
                        "binLowerBound": 5,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "Tuesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.113182999645249,
                        "binCatValue": "Client-Auth-Invalid",
                        "binLowerBound": 6,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "Tuesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.0665318642117514,
                        "binCatValue": "Client-Auth-Timeout",
                        "binLowerBound": 7,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "Tuesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.00000444918177931537,
                        "binCatValue": "Client-Auth-Unknown",
                        "binLowerBound": 8,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "Tuesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.00394634870646828,
                        "binCatValue": "Client-DHCP-Invalid",
                        "binLowerBound": 9,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "Tuesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.00862381151673821,
                        "binCatValue": "Client-DHCP-Timeout",
                        "binLowerBound": 10,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "Tuesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.00134831129018631,
                        "binCatValue": "Client-Operation-Invalid",
                        "binLowerBound": 11,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "Tuesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.000190060149646629,
                        "binCatValue": "Client-Operation-Timeout",
                        "binLowerBound": 12,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "Tuesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.0183534359329318,
                        "binCatValue": "Client-Operation-Unknown",
                        "binLowerBound": 13,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "Tuesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.0000209504788473852,
                        "binCatValue": "Client-Unknown-Blacklisted",
                        "binLowerBound": 14,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "Tuesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.019839001064096,
                        "binCatValue": "Network-Association-Fail",
                        "binLowerBound": 15,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "Tuesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.0055335730254585,
                        "binCatValue": "Network-Association-MaxStations",
                        "binLowerBound": 16,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "Tuesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.000339608287190331,
                        "binCatValue": "Network-Association-Timeout",
                        "binLowerBound": 17,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "Tuesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": "Network-Auth-Blacklisted",
                        "binLowerBound": 18,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "Tuesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.00184327347152675,
                        "binCatValue": "Network-Auth-Fail",
                        "binLowerBound": 19,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "Tuesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.00162195874904127,
                        "binCatValue": "Network-Auth-Invalid",
                        "binLowerBound": 20,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "Tuesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.00287525704897456,
                        "binCatValue": "Network-Auth-Timeout",
                        "binLowerBound": 21,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "Tuesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.000444604061621599,
                        "binCatValue": "Network-Auth-Unknown",
                        "binLowerBound": 22,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "Tuesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.0000226949033930343,
                        "binCatValue": "Network-DHCP-Invalid",
                        "binLowerBound": 23,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "Tuesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.00667503093258862,
                        "binCatValue": "Network-DHCP-Timeout",
                        "binLowerBound": 24,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "Tuesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.0461855617103536,
                        "binCatValue": "Network-Operation-Fail",
                        "binLowerBound": 25,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "Tuesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.00000246257595653834,
                        "binCatValue": "Network-Operation-Invalid",
                        "binLowerBound": 26,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "Tuesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.0943270831211314,
                        "binCatValue": "Network-Operation-Timeout",
                        "binLowerBound": 27,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "Tuesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.00496820982588248,
                        "binCatValue": "Network-Operation-Unknown",
                        "binLowerBound": 28,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "Tuesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": "Unknown-Auth-Timeout",
                        "binLowerBound": 29,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "Tuesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": "Unknown-Operation-Invalid",
                        "binLowerBound": 30,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "Tuesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.000248162746487693,
                        "binCatValue": "Client-Association-Blacklisted",
                        "binLowerBound": 0,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "Tuesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.00888217185389752,
                        "binCatValue": "Client-Association-Fail",
                        "binLowerBound": 1,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "Tuesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.000145047361512816,
                        "binCatValue": "Client-Association-Invalid",
                        "binLowerBound": 2,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "Tuesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.00019439733371487,
                        "binCatValue": "Client-Association-Unknown",
                        "binLowerBound": 3,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "Tuesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.25887604650552,
                        "binCatValue": "Client-Auth-Blacklisted",
                        "binLowerBound": 4,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "Tuesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.29423182071693,
                        "binCatValue": "Client-Auth-Fail",
                        "binLowerBound": 5,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "Tuesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.121565592026999,
                        "binCatValue": "Client-Auth-Invalid",
                        "binLowerBound": 6,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "Tuesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.0804291915692902,
                        "binCatValue": "Client-Auth-Timeout",
                        "binLowerBound": 7,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "Tuesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.0000132474318167868,
                        "binCatValue": "Client-Auth-Unknown",
                        "binLowerBound": 8,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "Tuesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.00227764271381713,
                        "binCatValue": "Client-DHCP-Invalid",
                        "binLowerBound": 9,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "Tuesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.00742887996101977,
                        "binCatValue": "Client-DHCP-Timeout",
                        "binLowerBound": 10,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "Tuesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.00416391892564957,
                        "binCatValue": "Client-Operation-Invalid",
                        "binLowerBound": 11,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "Tuesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.000221520794010467,
                        "binCatValue": "Client-Operation-Timeout",
                        "binLowerBound": 12,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "Tuesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.0366508628945782,
                        "binCatValue": "Client-Operation-Unknown",
                        "binLowerBound": 13,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "Tuesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.0000161706144397854,
                        "binCatValue": "Client-Unknown-Blacklisted",
                        "binLowerBound": 14,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "Tuesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.0277486059203169,
                        "binCatValue": "Network-Association-Fail",
                        "binLowerBound": 15,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "Tuesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.013579071644939,
                        "binCatValue": "Network-Association-MaxStations",
                        "binLowerBound": 16,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "Tuesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.000095963536859936,
                        "binCatValue": "Network-Association-Timeout",
                        "binLowerBound": 17,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "Tuesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": "Network-Auth-Blacklisted",
                        "binLowerBound": 18,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "Tuesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.00098687788760048,
                        "binCatValue": "Network-Auth-Fail",
                        "binLowerBound": 19,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "Tuesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.00105124757342227,
                        "binCatValue": "Network-Auth-Invalid",
                        "binLowerBound": 20,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "Tuesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.00227236555464346,
                        "binCatValue": "Network-Auth-Timeout",
                        "binLowerBound": 21,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "Tuesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.000928588521543929,
                        "binCatValue": "Network-Auth-Unknown",
                        "binLowerBound": 22,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "Tuesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.0000122760903964417,
                        "binCatValue": "Network-DHCP-Invalid",
                        "binLowerBound": 23,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "Tuesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.00739293561052295,
                        "binCatValue": "Network-DHCP-Timeout",
                        "binLowerBound": 24,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "Tuesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.073492776435389,
                        "binCatValue": "Network-Operation-Fail",
                        "binLowerBound": 25,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "Tuesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.0000133184785035321,
                        "binCatValue": "Network-Operation-Invalid",
                        "binLowerBound": 26,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "Tuesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.0559917747652204,
                        "binCatValue": "Network-Operation-Timeout",
                        "binLowerBound": 27,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "Tuesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.00108940908009255,
                        "binCatValue": "Network-Operation-Unknown",
                        "binLowerBound": 28,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "Tuesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": "Unknown-Auth-Timeout",
                        "binLowerBound": 29,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "Tuesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 1.1545086596102e-7,
                        "binCatValue": "Unknown-Operation-Invalid",
                        "binLowerBound": 30,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "Tuesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": "Client-Association-Blacklisted",
                        "binLowerBound": 0,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "Tuesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": "Client-Association-Fail",
                        "binLowerBound": 1,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "Tuesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": "Client-Association-Invalid",
                        "binLowerBound": 2,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "Tuesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": "Client-Association-Unknown",
                        "binLowerBound": 3,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "Tuesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": "Client-Auth-Blacklisted",
                        "binLowerBound": 4,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "Tuesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": "Client-Auth-Fail",
                        "binLowerBound": 5,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "Tuesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": "Client-Auth-Invalid",
                        "binLowerBound": 6,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "Tuesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": "Client-Auth-Timeout",
                        "binLowerBound": 7,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "Tuesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": "Client-Auth-Unknown",
                        "binLowerBound": 8,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "Tuesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": "Client-DHCP-Invalid",
                        "binLowerBound": 9,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "Tuesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": "Client-DHCP-Timeout",
                        "binLowerBound": 10,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "Tuesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": "Client-Operation-Invalid",
                        "binLowerBound": 11,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "Tuesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": "Client-Operation-Timeout",
                        "binLowerBound": 12,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "Tuesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": "Client-Operation-Unknown",
                        "binLowerBound": 13,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "Tuesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": "Client-Unknown-Blacklisted",
                        "binLowerBound": 14,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "Tuesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": "Network-Association-Fail",
                        "binLowerBound": 15,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "Tuesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": "Network-Association-MaxStations",
                        "binLowerBound": 16,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "Tuesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": "Network-Association-Timeout",
                        "binLowerBound": 17,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "Tuesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": "Network-Auth-Blacklisted",
                        "binLowerBound": 18,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "Tuesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": "Network-Auth-Fail",
                        "binLowerBound": 19,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "Tuesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": "Network-Auth-Invalid",
                        "binLowerBound": 20,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "Tuesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": "Network-Auth-Timeout",
                        "binLowerBound": 21,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "Tuesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": "Network-Auth-Unknown",
                        "binLowerBound": 22,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "Tuesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": "Network-DHCP-Invalid",
                        "binLowerBound": 23,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "Tuesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": "Network-DHCP-Timeout",
                        "binLowerBound": 24,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "Tuesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": "Network-Operation-Fail",
                        "binLowerBound": 25,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "Tuesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": "Network-Operation-Invalid",
                        "binLowerBound": 26,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "Tuesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": "Network-Operation-Timeout",
                        "binLowerBound": 27,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "Tuesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": "Network-Operation-Unknown",
                        "binLowerBound": 28,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "Tuesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": "Unknown-Auth-Timeout",
                        "binLowerBound": 29,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "Tuesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": "Unknown-Operation-Invalid",
                        "binLowerBound": 30,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "Tuesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.00124539086581248,
                        "binCatValue": "Client-Association-Blacklisted",
                        "binLowerBound": 0,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "Wednesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.00351962622919449,
                        "binCatValue": "Client-Association-Fail",
                        "binLowerBound": 1,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "Wednesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.00446604184604294,
                        "binCatValue": "Client-Association-Invalid",
                        "binLowerBound": 2,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "Wednesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.000192654010265648,
                        "binCatValue": "Client-Association-Unknown",
                        "binLowerBound": 3,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "Wednesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.32144720055389,
                        "binCatValue": "Client-Auth-Blacklisted",
                        "binLowerBound": 4,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "Wednesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.275003318225319,
                        "binCatValue": "Client-Auth-Fail",
                        "binLowerBound": 5,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "Wednesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.11227156659808,
                        "binCatValue": "Client-Auth-Invalid",
                        "binLowerBound": 6,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "Wednesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.0669139328884778,
                        "binCatValue": "Client-Auth-Timeout",
                        "binLowerBound": 7,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "Wednesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.00000395412272838884,
                        "binCatValue": "Client-Auth-Unknown",
                        "binLowerBound": 8,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "Wednesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.00450152466484297,
                        "binCatValue": "Client-DHCP-Invalid",
                        "binLowerBound": 9,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "Wednesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.00823172512828385,
                        "binCatValue": "Client-DHCP-Timeout",
                        "binLowerBound": 10,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "Wednesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.00137770655755917,
                        "binCatValue": "Client-Operation-Invalid",
                        "binLowerBound": 11,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "Wednesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.00018416809816338,
                        "binCatValue": "Client-Operation-Timeout",
                        "binLowerBound": 12,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "Wednesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.01821499613155,
                        "binCatValue": "Client-Operation-Unknown",
                        "binLowerBound": 13,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "Wednesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.0000172617495247188,
                        "binCatValue": "Client-Unknown-Blacklisted",
                        "binLowerBound": 14,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "Wednesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.019681980031772,
                        "binCatValue": "Network-Association-Fail",
                        "binLowerBound": 15,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "Wednesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.00546017775099705,
                        "binCatValue": "Network-Association-MaxStations",
                        "binLowerBound": 16,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "Wednesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.000351203302499901,
                        "binCatValue": "Network-Association-Timeout",
                        "binLowerBound": 17,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "Wednesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": "Network-Auth-Blacklisted",
                        "binLowerBound": 18,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "Wednesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.00162701011899318,
                        "binCatValue": "Network-Auth-Fail",
                        "binLowerBound": 19,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "Wednesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.00169883079058836,
                        "binCatValue": "Network-Auth-Invalid",
                        "binLowerBound": 20,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "Wednesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.0031525529279065,
                        "binCatValue": "Network-Auth-Timeout",
                        "binLowerBound": 21,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "Wednesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.00051518855798793,
                        "binCatValue": "Network-Auth-Unknown",
                        "binLowerBound": 22,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "Wednesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.0000202882204282619,
                        "binCatValue": "Network-DHCP-Invalid",
                        "binLowerBound": 23,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "Wednesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.00659789985327081,
                        "binCatValue": "Network-DHCP-Timeout",
                        "binLowerBound": 24,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "Wednesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.0450892551368949,
                        "binCatValue": "Network-Operation-Fail",
                        "binLowerBound": 25,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "Wednesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.00000201582727329627,
                        "binCatValue": "Network-Operation-Invalid",
                        "binLowerBound": 26,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "Wednesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.0933832093554243,
                        "binCatValue": "Network-Operation-Timeout",
                        "binLowerBound": 27,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "Wednesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.00482929206429528,
                        "binCatValue": "Network-Operation-Unknown",
                        "binLowerBound": 28,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "Wednesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": "Unknown-Auth-Timeout",
                        "binLowerBound": 29,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "Wednesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 2.83919334267081e-8,
                        "binCatValue": "Unknown-Operation-Invalid",
                        "binLowerBound": 30,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "Wednesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.000267566538639876,
                        "binCatValue": "Client-Association-Blacklisted",
                        "binLowerBound": 0,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "Wednesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.00882148344792678,
                        "binCatValue": "Client-Association-Fail",
                        "binLowerBound": 1,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "Wednesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.000140170746257529,
                        "binCatValue": "Client-Association-Invalid",
                        "binLowerBound": 2,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "Wednesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.000194294191314253,
                        "binCatValue": "Client-Association-Unknown",
                        "binLowerBound": 3,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "Wednesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.268450674327764,
                        "binCatValue": "Client-Auth-Blacklisted",
                        "binLowerBound": 4,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "Wednesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.293587109442212,
                        "binCatValue": "Client-Auth-Fail",
                        "binLowerBound": 5,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "Wednesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.118780677351143,
                        "binCatValue": "Client-Auth-Invalid",
                        "binLowerBound": 6,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "Wednesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.0788651966784719,
                        "binCatValue": "Client-Auth-Timeout",
                        "binLowerBound": 7,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "Wednesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.000014821271400528,
                        "binCatValue": "Client-Auth-Unknown",
                        "binLowerBound": 8,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "Wednesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.00258178405618013,
                        "binCatValue": "Client-DHCP-Invalid",
                        "binLowerBound": 9,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "Wednesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.00727015454908311,
                        "binCatValue": "Client-DHCP-Timeout",
                        "binLowerBound": 10,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "Wednesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.00409191077253898,
                        "binCatValue": "Client-Operation-Invalid",
                        "binLowerBound": 11,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "Wednesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.000217558025678378,
                        "binCatValue": "Client-Operation-Timeout",
                        "binLowerBound": 12,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "Wednesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.0359905842837803,
                        "binCatValue": "Client-Operation-Unknown",
                        "binLowerBound": 13,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "Wednesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.00002227600013268,
                        "binCatValue": "Client-Unknown-Blacklisted",
                        "binLowerBound": 14,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "Wednesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.0254385710911452,
                        "binCatValue": "Network-Association-Fail",
                        "binLowerBound": 15,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "Wednesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.0170993519226215,
                        "binCatValue": "Network-Association-MaxStations",
                        "binLowerBound": 16,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "Wednesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.0000985226228820185,
                        "binCatValue": "Network-Association-Timeout",
                        "binLowerBound": 17,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "Wednesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": "Network-Auth-Blacklisted",
                        "binLowerBound": 18,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "Wednesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.00087804784314425,
                        "binCatValue": "Network-Auth-Fail",
                        "binLowerBound": 19,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "Wednesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.000820854420742324,
                        "binCatValue": "Network-Auth-Invalid",
                        "binLowerBound": 20,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "Wednesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.00235899724829418,
                        "binCatValue": "Network-Auth-Timeout",
                        "binLowerBound": 21,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "Wednesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.00104347963768756,
                        "binCatValue": "Network-Auth-Unknown",
                        "binLowerBound": 22,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "Wednesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.0000118986048664076,
                        "binCatValue": "Network-DHCP-Invalid",
                        "binLowerBound": 23,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "Wednesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.00716123574129055,
                        "binCatValue": "Network-DHCP-Timeout",
                        "binLowerBound": 24,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "Wednesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.0697059064921077,
                        "binCatValue": "Network-Operation-Fail",
                        "binLowerBound": 25,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "Wednesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.000012459538380028,
                        "binCatValue": "Network-Operation-Invalid",
                        "binLowerBound": 26,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "Wednesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.055000662147064,
                        "binCatValue": "Network-Operation-Timeout",
                        "binLowerBound": 27,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "Wednesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.00107371192479012,
                        "binCatValue": "Network-Operation-Unknown",
                        "binLowerBound": 28,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "Wednesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": "Unknown-Auth-Timeout",
                        "binLowerBound": 29,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "Wednesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 3.90824600825281e-8,
                        "binCatValue": "Unknown-Operation-Invalid",
                        "binLowerBound": 30,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "Wednesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": "Client-Association-Blacklisted",
                        "binLowerBound": 0,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "Wednesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": "Client-Association-Fail",
                        "binLowerBound": 1,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "Wednesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": "Client-Association-Invalid",
                        "binLowerBound": 2,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "Wednesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": "Client-Association-Unknown",
                        "binLowerBound": 3,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "Wednesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": "Client-Auth-Blacklisted",
                        "binLowerBound": 4,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "Wednesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": "Client-Auth-Fail",
                        "binLowerBound": 5,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "Wednesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": "Client-Auth-Invalid",
                        "binLowerBound": 6,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "Wednesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": "Client-Auth-Timeout",
                        "binLowerBound": 7,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "Wednesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": "Client-Auth-Unknown",
                        "binLowerBound": 8,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "Wednesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": "Client-DHCP-Invalid",
                        "binLowerBound": 9,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "Wednesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": "Client-DHCP-Timeout",
                        "binLowerBound": 10,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "Wednesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": "Client-Operation-Invalid",
                        "binLowerBound": 11,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "Wednesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": "Client-Operation-Timeout",
                        "binLowerBound": 12,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "Wednesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": "Client-Operation-Unknown",
                        "binLowerBound": 13,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "Wednesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": "Client-Unknown-Blacklisted",
                        "binLowerBound": 14,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "Wednesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": "Network-Association-Fail",
                        "binLowerBound": 15,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "Wednesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": "Network-Association-MaxStations",
                        "binLowerBound": 16,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "Wednesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": "Network-Association-Timeout",
                        "binLowerBound": 17,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "Wednesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": "Network-Auth-Blacklisted",
                        "binLowerBound": 18,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "Wednesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": "Network-Auth-Fail",
                        "binLowerBound": 19,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "Wednesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": "Network-Auth-Invalid",
                        "binLowerBound": 20,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "Wednesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": "Network-Auth-Timeout",
                        "binLowerBound": 21,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "Wednesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": "Network-Auth-Unknown",
                        "binLowerBound": 22,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "Wednesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": "Network-DHCP-Invalid",
                        "binLowerBound": 23,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "Wednesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": "Network-DHCP-Timeout",
                        "binLowerBound": 24,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "Wednesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": "Network-Operation-Fail",
                        "binLowerBound": 25,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "Wednesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": "Network-Operation-Invalid",
                        "binLowerBound": 26,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "Wednesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": "Network-Operation-Timeout",
                        "binLowerBound": 27,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "Wednesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": "Network-Operation-Unknown",
                        "binLowerBound": 28,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "Wednesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": "Unknown-Auth-Timeout",
                        "binLowerBound": 29,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "Wednesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": "Unknown-Operation-Invalid",
                        "binLowerBound": 30,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagOnboardingSourceModuleType",
                        "weekday": "Wednesday"
                    }],
                    "totalCount": 744
                },
                "tagRoamingSourceModuleType": {
                    "__typename": "SmartDashboardPeers36SConnection",
                    "nodes": [{
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.000116747568629256,
                        "binCatValue": "Client-Association-Blacklisted",
                        "binLowerBound": 0,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "All"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.0628844169826392,
                        "binCatValue": "Client-Association-Fail",
                        "binLowerBound": 1,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "All"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.0000195815588645991,
                        "binCatValue": "Client-Association-Invalid",
                        "binLowerBound": 2,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "All"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.0019998162160792,
                        "binCatValue": "Client-Association-Unknown",
                        "binLowerBound": 3,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "All"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.0221799602660576,
                        "binCatValue": "Client-Auth-Blacklisted",
                        "binLowerBound": 4,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "All"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.0638626327469583,
                        "binCatValue": "Client-Auth-Fail",
                        "binLowerBound": 5,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "All"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.0531651440282877,
                        "binCatValue": "Client-Auth-Invalid",
                        "binLowerBound": 6,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "All"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.585309226350125,
                        "binCatValue": "Client-Auth-Timeout",
                        "binLowerBound": 7,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "All"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.0000187659842193064,
                        "binCatValue": "Client-Auth-Unknown",
                        "binLowerBound": 8,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "All"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.000376244002317458,
                        "binCatValue": "Client-DHCP-Invalid",
                        "binLowerBound": 9,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "All"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.000347896957860356,
                        "binCatValue": "Client-DHCP-Timeout",
                        "binLowerBound": 10,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "All"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.000527175799650841,
                        "binCatValue": "Client-Operation-Invalid",
                        "binLowerBound": 11,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "All"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.0000565037881636359,
                        "binCatValue": "Client-Operation-Timeout",
                        "binLowerBound": 12,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "All"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.0272231088058016,
                        "binCatValue": "Client-Operation-Unknown",
                        "binLowerBound": 13,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "All"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.0000962145060118161,
                        "binCatValue": "Client-Unknown-Blacklisted",
                        "binLowerBound": 14,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "All"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.000551472156702989,
                        "binCatValue": "Network-Association-Fail",
                        "binLowerBound": 15,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "All"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.0650990089560232,
                        "binCatValue": "Network-Association-MaxStations",
                        "binLowerBound": 16,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "All"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.00145233260776058,
                        "binCatValue": "Network-Association-Timeout",
                        "binLowerBound": 17,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "All"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": "Network-Auth-Blacklisted",
                        "binLowerBound": 18,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "All"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.000365462882244446,
                        "binCatValue": "Network-Auth-Fail",
                        "binLowerBound": 19,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "All"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.00117759269558298,
                        "binCatValue": "Network-Auth-Invalid",
                        "binLowerBound": 20,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "All"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.0200176815806361,
                        "binCatValue": "Network-Auth-Timeout",
                        "binLowerBound": 21,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "All"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.000466198002004456,
                        "binCatValue": "Network-Auth-Unknown",
                        "binLowerBound": 22,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "All"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.00000642362125387681,
                        "binCatValue": "Network-DHCP-Invalid",
                        "binLowerBound": 23,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "All"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.0183874876774923,
                        "binCatValue": "Network-DHCP-Timeout",
                        "binLowerBound": 24,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "All"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.07405602642844,
                        "binCatValue": "Network-Operation-Fail",
                        "binLowerBound": 25,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "All"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": "Network-Operation-Invalid",
                        "binLowerBound": 26,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "All"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.00000824118760624341,
                        "binCatValue": "Network-Operation-Timeout",
                        "binLowerBound": 27,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "All"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.000228636642585841,
                        "binCatValue": "Network-Operation-Unknown",
                        "binLowerBound": 28,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "All"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": "Unknown-Auth-Timeout",
                        "binLowerBound": 29,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "All"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": "Unknown-Operation-Invalid",
                        "binLowerBound": 30,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "All"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.0000343176177132408,
                        "binCatValue": "Client-Association-Blacklisted",
                        "binLowerBound": 0,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "All"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.275286341533254,
                        "binCatValue": "Client-Association-Fail",
                        "binLowerBound": 1,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "All"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.0000240530258310039,
                        "binCatValue": "Client-Association-Invalid",
                        "binLowerBound": 2,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "All"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.00206142399858789,
                        "binCatValue": "Client-Association-Unknown",
                        "binLowerBound": 3,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "All"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.010743931795905,
                        "binCatValue": "Client-Auth-Blacklisted",
                        "binLowerBound": 4,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "All"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.0346980145951713,
                        "binCatValue": "Client-Auth-Fail",
                        "binLowerBound": 5,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "All"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.0524381068769016,
                        "binCatValue": "Client-Auth-Invalid",
                        "binLowerBound": 6,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "All"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.304092147875846,
                        "binCatValue": "Client-Auth-Timeout",
                        "binLowerBound": 7,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "All"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.0000192030701112963,
                        "binCatValue": "Client-Auth-Unknown",
                        "binLowerBound": 8,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "All"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.000314258439124133,
                        "binCatValue": "Client-DHCP-Invalid",
                        "binLowerBound": 9,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "All"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.000127526290040853,
                        "binCatValue": "Client-DHCP-Timeout",
                        "binLowerBound": 10,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "All"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.000706026647254358,
                        "binCatValue": "Client-Operation-Invalid",
                        "binLowerBound": 11,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "All"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.0000273230568274152,
                        "binCatValue": "Client-Operation-Timeout",
                        "binLowerBound": 12,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "All"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.0254162391860276,
                        "binCatValue": "Client-Operation-Unknown",
                        "binLowerBound": 13,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "All"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.0000900163586743768,
                        "binCatValue": "Client-Unknown-Blacklisted",
                        "binLowerBound": 14,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "All"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.00117372371590354,
                        "binCatValue": "Network-Association-Fail",
                        "binLowerBound": 15,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "All"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.196975450348541,
                        "binCatValue": "Network-Association-MaxStations",
                        "binLowerBound": 16,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "All"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.000364650757944883,
                        "binCatValue": "Network-Association-Timeout",
                        "binLowerBound": 17,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "All"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": "Network-Auth-Blacklisted",
                        "binLowerBound": 18,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "All"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.000512185820680082,
                        "binCatValue": "Network-Auth-Fail",
                        "binLowerBound": 19,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "All"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.000381906959421432,
                        "binCatValue": "Network-Auth-Invalid",
                        "binLowerBound": 20,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "All"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.014638822036616,
                        "binCatValue": "Network-Auth-Timeout",
                        "binLowerBound": 21,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "All"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.000310900853146168,
                        "binCatValue": "Network-Auth-Unknown",
                        "binLowerBound": 22,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "All"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.00000795373062755282,
                        "binCatValue": "Network-DHCP-Invalid",
                        "binLowerBound": 23,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "All"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.0373363964092753,
                        "binCatValue": "Network-DHCP-Timeout",
                        "binLowerBound": 24,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "All"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.0419579113444739,
                        "binCatValue": "Network-Operation-Fail",
                        "binLowerBound": 25,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "All"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": "Network-Operation-Invalid",
                        "binLowerBound": 26,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "All"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.00000250171143919194,
                        "binCatValue": "Network-Operation-Timeout",
                        "binLowerBound": 27,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "All"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.000258665944657465,
                        "binCatValue": "Network-Operation-Unknown",
                        "binLowerBound": 28,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "All"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": "Unknown-Auth-Timeout",
                        "binLowerBound": 29,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "All"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": "Unknown-Operation-Invalid",
                        "binLowerBound": 30,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "All"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": "Client-Association-Blacklisted",
                        "binLowerBound": 0,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "All"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": "Client-Association-Fail",
                        "binLowerBound": 1,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "All"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": "Client-Association-Invalid",
                        "binLowerBound": 2,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "All"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": "Client-Association-Unknown",
                        "binLowerBound": 3,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "All"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": "Client-Auth-Blacklisted",
                        "binLowerBound": 4,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "All"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": "Client-Auth-Fail",
                        "binLowerBound": 5,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "All"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": "Client-Auth-Invalid",
                        "binLowerBound": 6,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "All"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": "Client-Auth-Timeout",
                        "binLowerBound": 7,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "All"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": "Client-Auth-Unknown",
                        "binLowerBound": 8,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "All"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": "Client-DHCP-Invalid",
                        "binLowerBound": 9,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "All"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": "Client-DHCP-Timeout",
                        "binLowerBound": 10,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "All"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": "Client-Operation-Invalid",
                        "binLowerBound": 11,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "All"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": "Client-Operation-Timeout",
                        "binLowerBound": 12,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "All"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": "Client-Operation-Unknown",
                        "binLowerBound": 13,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "All"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": "Client-Unknown-Blacklisted",
                        "binLowerBound": 14,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "All"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": "Network-Association-Fail",
                        "binLowerBound": 15,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "All"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": "Network-Association-MaxStations",
                        "binLowerBound": 16,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "All"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": "Network-Association-Timeout",
                        "binLowerBound": 17,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "All"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": "Network-Auth-Blacklisted",
                        "binLowerBound": 18,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "All"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": "Network-Auth-Fail",
                        "binLowerBound": 19,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "All"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": "Network-Auth-Invalid",
                        "binLowerBound": 20,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "All"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": "Network-Auth-Timeout",
                        "binLowerBound": 21,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "All"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": "Network-Auth-Unknown",
                        "binLowerBound": 22,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "All"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": "Network-DHCP-Invalid",
                        "binLowerBound": 23,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "All"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": "Network-DHCP-Timeout",
                        "binLowerBound": 24,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "All"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": "Network-Operation-Fail",
                        "binLowerBound": 25,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "All"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": "Network-Operation-Invalid",
                        "binLowerBound": 26,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "All"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": "Network-Operation-Timeout",
                        "binLowerBound": 27,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "All"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": "Network-Operation-Unknown",
                        "binLowerBound": 28,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "All"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": "Unknown-Auth-Timeout",
                        "binLowerBound": 29,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "All"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": "Unknown-Operation-Invalid",
                        "binLowerBound": 30,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "All"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.000126782959510194,
                        "binCatValue": "Client-Association-Blacklisted",
                        "binLowerBound": 0,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "Friday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.0605288786991306,
                        "binCatValue": "Client-Association-Fail",
                        "binLowerBound": 1,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "Friday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.0000167066823837994,
                        "binCatValue": "Client-Association-Invalid",
                        "binLowerBound": 2,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "Friday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.00203240745443271,
                        "binCatValue": "Client-Association-Unknown",
                        "binLowerBound": 3,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "Friday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.0213294658846456,
                        "binCatValue": "Client-Auth-Blacklisted",
                        "binLowerBound": 4,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "Friday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.0643565625170602,
                        "binCatValue": "Client-Auth-Fail",
                        "binLowerBound": 5,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "Friday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.0553788461421069,
                        "binCatValue": "Client-Auth-Invalid",
                        "binLowerBound": 6,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "Friday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.587976314372905,
                        "binCatValue": "Client-Auth-Timeout",
                        "binLowerBound": 7,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "Friday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.0000115661647272457,
                        "binCatValue": "Client-Auth-Unknown",
                        "binLowerBound": 8,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "Friday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.00034876435177541,
                        "binCatValue": "Client-DHCP-Invalid",
                        "binLowerBound": 9,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "Friday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.000318885092896692,
                        "binCatValue": "Client-DHCP-Timeout",
                        "binLowerBound": 10,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "Friday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.000643404983993837,
                        "binCatValue": "Client-Operation-Invalid",
                        "binLowerBound": 11,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "Friday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.0000500953331242032,
                        "binCatValue": "Client-Operation-Timeout",
                        "binLowerBound": 12,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "Friday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.0261742307777571,
                        "binCatValue": "Client-Operation-Unknown",
                        "binLowerBound": 13,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "Friday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.0000860789567200789,
                        "binCatValue": "Client-Unknown-Blacklisted",
                        "binLowerBound": 14,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "Friday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.000336407338177925,
                        "binCatValue": "Network-Association-Fail",
                        "binLowerBound": 15,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "Friday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.0701188850978394,
                        "binCatValue": "Network-Association-MaxStations",
                        "binLowerBound": 16,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "Friday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.00149675562900894,
                        "binCatValue": "Network-Association-Timeout",
                        "binLowerBound": 17,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "Friday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": "Network-Auth-Blacklisted",
                        "binLowerBound": 18,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "Friday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.000335270492926957,
                        "binCatValue": "Network-Auth-Fail",
                        "binLowerBound": 19,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "Friday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.00123004184752083,
                        "binCatValue": "Network-Auth-Invalid",
                        "binLowerBound": 20,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "Friday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.016134948078671,
                        "binCatValue": "Network-Auth-Timeout",
                        "binLowerBound": 21,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "Friday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.000468825095888572,
                        "binCatValue": "Network-Auth-Unknown",
                        "binLowerBound": 22,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "Friday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.00000257025882827683,
                        "binCatValue": "Network-DHCP-Invalid",
                        "binLowerBound": 23,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "Friday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.0169644991154973,
                        "binCatValue": "Network-DHCP-Timeout",
                        "binLowerBound": 24,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "Friday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.0732668095977716,
                        "binCatValue": "Network-Operation-Fail",
                        "binLowerBound": 25,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "Friday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": "Network-Operation-Invalid",
                        "binLowerBound": 26,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "Friday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.00000897119187177394,
                        "binCatValue": "Network-Operation-Timeout",
                        "binLowerBound": 27,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "Friday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.000257025882827683,
                        "binCatValue": "Network-Operation-Unknown",
                        "binLowerBound": 28,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "Friday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": "Unknown-Auth-Timeout",
                        "binLowerBound": 29,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "Friday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": "Unknown-Operation-Invalid",
                        "binLowerBound": 30,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "Friday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.0000394946734280304,
                        "binCatValue": "Client-Association-Blacklisted",
                        "binLowerBound": 0,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "Friday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.253888534369773,
                        "binCatValue": "Client-Association-Fail",
                        "binLowerBound": 1,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "Friday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.0000244314982770837,
                        "binCatValue": "Client-Association-Invalid",
                        "binLowerBound": 2,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "Friday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.00243107337244757,
                        "binCatValue": "Client-Association-Unknown",
                        "binLowerBound": 3,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "Friday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.0107770830965337,
                        "binCatValue": "Client-Auth-Blacklisted",
                        "binLowerBound": 4,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "Friday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.0350878621725045,
                        "binCatValue": "Client-Auth-Fail",
                        "binLowerBound": 5,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "Friday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.0536893674482309,
                        "binCatValue": "Client-Auth-Invalid",
                        "binLowerBound": 6,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "Friday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.303535502783001,
                        "binCatValue": "Client-Auth-Timeout",
                        "binLowerBound": 7,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "Friday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.0000205312697003655,
                        "binCatValue": "Client-Auth-Unknown",
                        "binLowerBound": 8,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "Friday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.000301049703113564,
                        "binCatValue": "Client-DHCP-Invalid",
                        "binLowerBound": 9,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "Friday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.000106498268013445,
                        "binCatValue": "Client-DHCP-Timeout",
                        "binLowerBound": 10,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "Friday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.000815555935989811,
                        "binCatValue": "Client-Operation-Invalid",
                        "binLowerBound": 11,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "Friday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.0000218853357676979,
                        "binCatValue": "Client-Operation-Timeout",
                        "binLowerBound": 12,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "Friday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.0253482463561629,
                        "binCatValue": "Client-Operation-Unknown",
                        "binLowerBound": 13,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "Friday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.0000869647312047985,
                        "binCatValue": "Client-Unknown-Blacklisted",
                        "binLowerBound": 14,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "Friday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.000768280241763378,
                        "binCatValue": "Network-Association-Fail",
                        "binLowerBound": 15,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "Friday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.219439337866099,
                        "binCatValue": "Network-Association-MaxStations",
                        "binLowerBound": 16,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "Friday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.000380868334451053,
                        "binCatValue": "Network-Association-Timeout",
                        "binLowerBound": 17,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "Friday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": "Network-Auth-Blacklisted",
                        "binLowerBound": 18,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "Friday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.000513670469609808,
                        "binCatValue": "Network-Auth-Fail",
                        "binLowerBound": 19,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "Friday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.000382319582293553,
                        "binCatValue": "Network-Auth-Invalid",
                        "binLowerBound": 20,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "Friday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.0107857970623738,
                        "binCatValue": "Network-Auth-Timeout",
                        "binLowerBound": 21,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "Friday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.000299222685740417,
                        "binCatValue": "Network-Auth-Unknown",
                        "binLowerBound": 22,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "Friday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.00000976352901181787,
                        "binCatValue": "Network-DHCP-Invalid",
                        "binLowerBound": 23,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "Friday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.0377774001941873,
                        "binCatValue": "Network-DHCP-Timeout",
                        "binLowerBound": 24,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "Friday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.0431741438842783,
                        "binCatValue": "Network-Operation-Fail",
                        "binLowerBound": 25,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "Friday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": "Network-Operation-Invalid",
                        "binLowerBound": 26,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "Friday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.00000268869577963133,
                        "binCatValue": "Network-Operation-Timeout",
                        "binLowerBound": 27,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "Friday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.00029242644026371,
                        "binCatValue": "Network-Operation-Unknown",
                        "binLowerBound": 28,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "Friday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": "Unknown-Auth-Timeout",
                        "binLowerBound": 29,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "Friday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": "Unknown-Operation-Invalid",
                        "binLowerBound": 30,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "Friday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": "Client-Association-Blacklisted",
                        "binLowerBound": 0,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "Friday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": "Client-Association-Fail",
                        "binLowerBound": 1,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "Friday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": "Client-Association-Invalid",
                        "binLowerBound": 2,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "Friday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": "Client-Association-Unknown",
                        "binLowerBound": 3,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "Friday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": "Client-Auth-Blacklisted",
                        "binLowerBound": 4,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "Friday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": "Client-Auth-Fail",
                        "binLowerBound": 5,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "Friday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": "Client-Auth-Invalid",
                        "binLowerBound": 6,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "Friday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": "Client-Auth-Timeout",
                        "binLowerBound": 7,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "Friday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": "Client-Auth-Unknown",
                        "binLowerBound": 8,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "Friday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": "Client-DHCP-Invalid",
                        "binLowerBound": 9,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "Friday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": "Client-DHCP-Timeout",
                        "binLowerBound": 10,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "Friday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": "Client-Operation-Invalid",
                        "binLowerBound": 11,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "Friday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": "Client-Operation-Timeout",
                        "binLowerBound": 12,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "Friday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": "Client-Operation-Unknown",
                        "binLowerBound": 13,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "Friday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": "Client-Unknown-Blacklisted",
                        "binLowerBound": 14,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "Friday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": "Network-Association-Fail",
                        "binLowerBound": 15,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "Friday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": "Network-Association-MaxStations",
                        "binLowerBound": 16,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "Friday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": "Network-Association-Timeout",
                        "binLowerBound": 17,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "Friday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": "Network-Auth-Blacklisted",
                        "binLowerBound": 18,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "Friday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": "Network-Auth-Fail",
                        "binLowerBound": 19,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "Friday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": "Network-Auth-Invalid",
                        "binLowerBound": 20,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "Friday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": "Network-Auth-Timeout",
                        "binLowerBound": 21,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "Friday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": "Network-Auth-Unknown",
                        "binLowerBound": 22,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "Friday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": "Network-DHCP-Invalid",
                        "binLowerBound": 23,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "Friday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": "Network-DHCP-Timeout",
                        "binLowerBound": 24,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "Friday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": "Network-Operation-Fail",
                        "binLowerBound": 25,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "Friday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": "Network-Operation-Invalid",
                        "binLowerBound": 26,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "Friday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": "Network-Operation-Timeout",
                        "binLowerBound": 27,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "Friday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": "Network-Operation-Unknown",
                        "binLowerBound": 28,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "Friday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": "Unknown-Auth-Timeout",
                        "binLowerBound": 29,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "Friday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": "Unknown-Operation-Invalid",
                        "binLowerBound": 30,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "Friday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.000112642822495677,
                        "binCatValue": "Client-Association-Blacklisted",
                        "binLowerBound": 0,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "Monday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.0628414533211524,
                        "binCatValue": "Client-Association-Fail",
                        "binLowerBound": 1,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "Monday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.0000158594587629292,
                        "binCatValue": "Client-Association-Invalid",
                        "binLowerBound": 2,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "Monday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.00198779507901835,
                        "binCatValue": "Client-Association-Unknown",
                        "binLowerBound": 3,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "Monday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.0206366378150749,
                        "binCatValue": "Client-Auth-Blacklisted",
                        "binLowerBound": 4,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "Monday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.0615220175163146,
                        "binCatValue": "Client-Auth-Fail",
                        "binLowerBound": 5,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "Monday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.0541073138918565,
                        "binCatValue": "Client-Auth-Invalid",
                        "binLowerBound": 6,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "Monday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.579061524152443,
                        "binCatValue": "Client-Auth-Timeout",
                        "binLowerBound": 7,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "Monday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.0000158594587629292,
                        "binCatValue": "Client-Auth-Unknown",
                        "binLowerBound": 8,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "Monday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.000379712041535516,
                        "binCatValue": "Client-DHCP-Invalid",
                        "binLowerBound": 9,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "Monday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.000347637302819463,
                        "binCatValue": "Client-DHCP-Timeout",
                        "binLowerBound": 10,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "Monday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.000524302523750747,
                        "binCatValue": "Client-Operation-Invalid",
                        "binLowerBound": 11,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "Monday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.0000581259329981074,
                        "binCatValue": "Client-Operation-Timeout",
                        "binLowerBound": 12,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "Monday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.0273078784784222,
                        "binCatValue": "Client-Operation-Unknown",
                        "binLowerBound": 13,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "Monday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.0000917510354714332,
                        "binCatValue": "Client-Unknown-Blacklisted",
                        "binLowerBound": 14,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "Monday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.000461830489072413,
                        "binCatValue": "Network-Association-Fail",
                        "binLowerBound": 15,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "Monday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.0665090548232769,
                        "binCatValue": "Network-Association-MaxStations",
                        "binLowerBound": 16,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "Monday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.00150268371778754,
                        "binCatValue": "Network-Association-Timeout",
                        "binLowerBound": 17,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "Monday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": "Network-Auth-Blacklisted",
                        "binLowerBound": 18,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "Monday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.000533604706294388,
                        "binCatValue": "Network-Auth-Fail",
                        "binLowerBound": 19,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "Monday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.0012122319633921,
                        "binCatValue": "Network-Auth-Invalid",
                        "binLowerBound": 20,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "Monday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.026675787549842,
                        "binCatValue": "Network-Auth-Timeout",
                        "binLowerBound": 21,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "Monday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.00050069124620145,
                        "binCatValue": "Network-Auth-Unknown",
                        "binLowerBound": 22,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "Monday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.00000792972938146459,
                        "binCatValue": "Network-DHCP-Invalid",
                        "binLowerBound": 23,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "Monday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.0194150782533399,
                        "binCatValue": "Network-DHCP-Timeout",
                        "binLowerBound": 24,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "Monday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.073921877678587,
                        "binCatValue": "Network-Operation-Fail",
                        "binLowerBound": 25,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "Monday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": "Network-Operation-Invalid",
                        "binLowerBound": 26,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "Monday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.00000792972938146459,
                        "binCatValue": "Network-Operation-Timeout",
                        "binLowerBound": 27,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "Monday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.000240789282564088,
                        "binCatValue": "Network-Operation-Unknown",
                        "binLowerBound": 28,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "Monday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": "Unknown-Auth-Timeout",
                        "binLowerBound": 29,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "Monday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": "Unknown-Operation-Invalid",
                        "binLowerBound": 30,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "Monday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.0000325356920895362,
                        "binCatValue": "Client-Association-Blacklisted",
                        "binLowerBound": 0,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "Monday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.255114528570494,
                        "binCatValue": "Client-Association-Fail",
                        "binLowerBound": 1,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "Monday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.0000238100644587845,
                        "binCatValue": "Client-Association-Invalid",
                        "binLowerBound": 2,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "Monday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.00220392070374038,
                        "binCatValue": "Client-Association-Unknown",
                        "binLowerBound": 3,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "Monday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.010049162817303,
                        "binCatValue": "Client-Auth-Blacklisted",
                        "binLowerBound": 4,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "Monday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.0344890202486734,
                        "binCatValue": "Client-Auth-Fail",
                        "binLowerBound": 5,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "Monday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.0528095750303983,
                        "binCatValue": "Client-Auth-Invalid",
                        "binLowerBound": 6,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "Monday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.316045863627185,
                        "binCatValue": "Client-Auth-Timeout",
                        "binLowerBound": 7,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "Monday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.0000251256801547737,
                        "binCatValue": "Client-Auth-Unknown",
                        "binLowerBound": 8,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "Monday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.000309782352729608,
                        "binCatValue": "Client-DHCP-Invalid",
                        "binLowerBound": 9,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "Monday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.000139571347512734,
                        "binCatValue": "Client-DHCP-Timeout",
                        "binLowerBound": 10,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "Monday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.000705189360339845,
                        "binCatValue": "Client-Operation-Invalid",
                        "binLowerBound": 11,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "Monday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.0000288081142842336,
                        "binCatValue": "Client-Operation-Timeout",
                        "binLowerBound": 12,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "Monday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.0269649173468631,
                        "binCatValue": "Client-Operation-Unknown",
                        "binLowerBound": 13,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "Monday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.0000802525574553397,
                        "binCatValue": "Client-Unknown-Blacklisted",
                        "binLowerBound": 14,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "Monday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.000874916683315546,
                        "binCatValue": "Network-Association-Fail",
                        "binLowerBound": 15,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "Monday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.199677095025613,
                        "binCatValue": "Network-Association-MaxStations",
                        "binLowerBound": 16,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "Monday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.000405196736171568,
                        "binCatValue": "Network-Association-Timeout",
                        "binLowerBound": 17,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "Monday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": "Network-Auth-Blacklisted",
                        "binLowerBound": 18,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "Monday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.000527826307050167,
                        "binCatValue": "Network-Auth-Fail",
                        "binLowerBound": 19,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "Monday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.000308099138530328,
                        "binCatValue": "Network-Auth-Invalid",
                        "binLowerBound": 20,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "Monday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.018292456068061,
                        "binCatValue": "Network-Auth-Timeout",
                        "binLowerBound": 21,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "Monday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.000333740746409019,
                        "binCatValue": "Network-Auth-Unknown",
                        "binLowerBound": 22,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "Monday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.00000666836583163141,
                        "binCatValue": "Network-DHCP-Invalid",
                        "binLowerBound": 23,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "Monday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.0338643220623613,
                        "binCatValue": "Network-DHCP-Timeout",
                        "binLowerBound": 24,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "Monday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.0464161212864562,
                        "binCatValue": "Network-Operation-Fail",
                        "binLowerBound": 25,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "Monday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": "Network-Operation-Invalid",
                        "binLowerBound": 26,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "Monday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.00000268282416437008,
                        "binCatValue": "Network-Operation-Timeout",
                        "binLowerBound": 27,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "Monday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.000268811242354024,
                        "binCatValue": "Network-Operation-Unknown",
                        "binLowerBound": 28,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "Monday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": "Unknown-Auth-Timeout",
                        "binLowerBound": 29,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "Monday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": "Unknown-Operation-Invalid",
                        "binLowerBound": 30,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "Monday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": "Client-Association-Blacklisted",
                        "binLowerBound": 0,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "Monday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": "Client-Association-Fail",
                        "binLowerBound": 1,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "Monday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": "Client-Association-Invalid",
                        "binLowerBound": 2,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "Monday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": "Client-Association-Unknown",
                        "binLowerBound": 3,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "Monday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": "Client-Auth-Blacklisted",
                        "binLowerBound": 4,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "Monday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": "Client-Auth-Fail",
                        "binLowerBound": 5,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "Monday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": "Client-Auth-Invalid",
                        "binLowerBound": 6,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "Monday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": "Client-Auth-Timeout",
                        "binLowerBound": 7,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "Monday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": "Client-Auth-Unknown",
                        "binLowerBound": 8,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "Monday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": "Client-DHCP-Invalid",
                        "binLowerBound": 9,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "Monday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": "Client-DHCP-Timeout",
                        "binLowerBound": 10,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "Monday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": "Client-Operation-Invalid",
                        "binLowerBound": 11,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "Monday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": "Client-Operation-Timeout",
                        "binLowerBound": 12,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "Monday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": "Client-Operation-Unknown",
                        "binLowerBound": 13,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "Monday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": "Client-Unknown-Blacklisted",
                        "binLowerBound": 14,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "Monday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": "Network-Association-Fail",
                        "binLowerBound": 15,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "Monday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": "Network-Association-MaxStations",
                        "binLowerBound": 16,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "Monday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": "Network-Association-Timeout",
                        "binLowerBound": 17,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "Monday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": "Network-Auth-Blacklisted",
                        "binLowerBound": 18,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "Monday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": "Network-Auth-Fail",
                        "binLowerBound": 19,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "Monday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": "Network-Auth-Invalid",
                        "binLowerBound": 20,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "Monday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": "Network-Auth-Timeout",
                        "binLowerBound": 21,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "Monday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": "Network-Auth-Unknown",
                        "binLowerBound": 22,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "Monday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": "Network-DHCP-Invalid",
                        "binLowerBound": 23,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "Monday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": "Network-DHCP-Timeout",
                        "binLowerBound": 24,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "Monday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": "Network-Operation-Fail",
                        "binLowerBound": 25,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "Monday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": "Network-Operation-Invalid",
                        "binLowerBound": 26,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "Monday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": "Network-Operation-Timeout",
                        "binLowerBound": 27,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "Monday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": "Network-Operation-Unknown",
                        "binLowerBound": 28,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "Monday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": "Unknown-Auth-Timeout",
                        "binLowerBound": 29,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "Monday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": "Unknown-Operation-Invalid",
                        "binLowerBound": 30,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "Monday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.000157902372013625,
                        "binCatValue": "Client-Association-Blacklisted",
                        "binLowerBound": 0,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "Saturday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.0177322952018855,
                        "binCatValue": "Client-Association-Fail",
                        "binLowerBound": 1,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "Saturday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.0000244703757225124,
                        "binCatValue": "Client-Association-Invalid",
                        "binLowerBound": 2,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "Saturday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.00163904458925982,
                        "binCatValue": "Client-Association-Unknown",
                        "binLowerBound": 3,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "Saturday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.0195955945280989,
                        "binCatValue": "Client-Auth-Blacklisted",
                        "binLowerBound": 4,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "Saturday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.0846888046277074,
                        "binCatValue": "Client-Auth-Fail",
                        "binLowerBound": 5,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "Saturday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.0506274533744157,
                        "binCatValue": "Client-Auth-Invalid",
                        "binLowerBound": 6,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "Saturday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.628511632604859,
                        "binCatValue": "Client-Auth-Timeout",
                        "binLowerBound": 7,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "Saturday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.0000177966368891,
                        "binCatValue": "Client-Auth-Unknown",
                        "binLowerBound": 8,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "Saturday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.000315847524404387,
                        "binCatValue": "Client-DHCP-Invalid",
                        "binLowerBound": 9,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "Saturday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.000256382800183596,
                        "binCatValue": "Client-DHCP-Timeout",
                        "binLowerBound": 10,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "Saturday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.000589470816574299,
                        "binCatValue": "Client-Operation-Invalid",
                        "binLowerBound": 11,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "Saturday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.0000509514291704761,
                        "binCatValue": "Client-Operation-Timeout",
                        "binLowerBound": 12,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "Saturday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.0234223420434039,
                        "binCatValue": "Client-Operation-Unknown",
                        "binLowerBound": 13,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "Saturday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.0000543310789643196,
                        "binCatValue": "Client-Unknown-Blacklisted",
                        "binLowerBound": 14,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "Saturday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.000217153194348729,
                        "binCatValue": "Network-Association-Fail",
                        "binLowerBound": 15,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "Saturday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.0599722706151472,
                        "binCatValue": "Network-Association-MaxStations",
                        "binLowerBound": 16,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "Saturday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.00147468238029847,
                        "binCatValue": "Network-Association-Timeout",
                        "binLowerBound": 17,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "Saturday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": "Network-Auth-Blacklisted",
                        "binLowerBound": 18,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "Saturday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.0000705448418993409,
                        "binCatValue": "Network-Auth-Fail",
                        "binLowerBound": 19,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "Saturday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.00103442951917894,
                        "binCatValue": "Network-Auth-Invalid",
                        "binLowerBound": 20,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "Saturday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.0128255570657504,
                        "binCatValue": "Network-Auth-Timeout",
                        "binLowerBound": 21,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "Saturday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.000471610877561149,
                        "binCatValue": "Network-Auth-Unknown",
                        "binLowerBound": 22,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "Saturday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.00000859885580458916,
                        "binCatValue": "Network-DHCP-Invalid",
                        "binLowerBound": 23,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "Saturday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.0263095041356433,
                        "binCatValue": "Network-DHCP-Timeout",
                        "binLowerBound": 24,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "Saturday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.0697110951293129,
                        "binCatValue": "Network-Operation-Fail",
                        "binLowerBound": 25,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "Saturday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": "Network-Operation-Invalid",
                        "binLowerBound": 26,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "Saturday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.00000444915922227499,
                        "binCatValue": "Network-Operation-Timeout",
                        "binLowerBound": 27,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "Saturday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.000215784222280337,
                        "binCatValue": "Network-Operation-Unknown",
                        "binLowerBound": 28,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "Saturday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": "Unknown-Auth-Timeout",
                        "binLowerBound": 29,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "Saturday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": "Unknown-Operation-Invalid",
                        "binLowerBound": 30,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "Saturday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.000049335907985667,
                        "binCatValue": "Client-Association-Blacklisted",
                        "binLowerBound": 0,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "Saturday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.2499005722306,
                        "binCatValue": "Client-Association-Fail",
                        "binLowerBound": 1,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "Saturday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.0000206892517359249,
                        "binCatValue": "Client-Association-Invalid",
                        "binLowerBound": 2,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "Saturday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.000951398651392725,
                        "binCatValue": "Client-Association-Unknown",
                        "binLowerBound": 3,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "Saturday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.00997965382607583,
                        "binCatValue": "Client-Auth-Blacklisted",
                        "binLowerBound": 4,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "Saturday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.0368134314440385,
                        "binCatValue": "Client-Auth-Fail",
                        "binLowerBound": 5,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "Saturday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.0505984638889913,
                        "binCatValue": "Client-Auth-Invalid",
                        "binLowerBound": 6,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "Saturday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.325178039536978,
                        "binCatValue": "Client-Auth-Timeout",
                        "binLowerBound": 7,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "Saturday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.0000105492448411749,
                        "binCatValue": "Client-Auth-Unknown",
                        "binLowerBound": 8,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "Saturday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.000291036386232499,
                        "binCatValue": "Client-DHCP-Invalid",
                        "binLowerBound": 9,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "Saturday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.0000984785647188554,
                        "binCatValue": "Client-DHCP-Timeout",
                        "binLowerBound": 10,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "Saturday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.00090500698307717,
                        "binCatValue": "Client-Operation-Invalid",
                        "binLowerBound": 11,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "Saturday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.0000195070087795863,
                        "binCatValue": "Client-Operation-Timeout",
                        "binLowerBound": 12,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "Saturday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.0203567345367339,
                        "binCatValue": "Client-Operation-Unknown",
                        "binLowerBound": 13,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "Saturday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.0000837346309268255,
                        "binCatValue": "Client-Unknown-Blacklisted",
                        "binLowerBound": 14,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "Saturday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.000283783780404191,
                        "binCatValue": "Network-Association-Fail",
                        "binLowerBound": 15,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "Saturday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.208002131947817,
                        "binCatValue": "Network-Association-MaxStations",
                        "binLowerBound": 16,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "Saturday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.00026618654870792,
                        "binCatValue": "Network-Association-Timeout",
                        "binLowerBound": 17,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "Saturday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": "Network-Auth-Blacklisted",
                        "binLowerBound": 18,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "Saturday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.000375896421511993,
                        "binCatValue": "Network-Auth-Fail",
                        "binLowerBound": 19,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "Saturday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.000206596956620164,
                        "binCatValue": "Network-Auth-Invalid",
                        "binLowerBound": 20,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "Saturday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.00887282432908678,
                        "binCatValue": "Network-Auth-Timeout",
                        "binLowerBound": 21,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "Saturday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.000189136137572702,
                        "binCatValue": "Network-Auth-Unknown",
                        "binLowerBound": 22,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "Saturday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.00000404690858131277,
                        "binCatValue": "Network-DHCP-Invalid",
                        "binLowerBound": 23,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "Saturday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.0604098413450414,
                        "binCatValue": "Network-DHCP-Timeout",
                        "binLowerBound": 24,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "Saturday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.0258799235388915,
                        "binCatValue": "Network-Operation-Fail",
                        "binLowerBound": 25,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "Saturday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": "Network-Operation-Invalid",
                        "binLowerBound": 26,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "Saturday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.00000177336443450784,
                        "binCatValue": "Network-Operation-Timeout",
                        "binLowerBound": 27,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "Saturday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.000251226628221945,
                        "binCatValue": "Network-Operation-Unknown",
                        "binLowerBound": 28,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "Saturday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": "Unknown-Auth-Timeout",
                        "binLowerBound": 29,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "Saturday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": "Unknown-Operation-Invalid",
                        "binLowerBound": 30,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "Saturday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": "Client-Association-Blacklisted",
                        "binLowerBound": 0,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "Saturday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": "Client-Association-Fail",
                        "binLowerBound": 1,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "Saturday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": "Client-Association-Invalid",
                        "binLowerBound": 2,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "Saturday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": "Client-Association-Unknown",
                        "binLowerBound": 3,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "Saturday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": "Client-Auth-Blacklisted",
                        "binLowerBound": 4,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "Saturday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": "Client-Auth-Fail",
                        "binLowerBound": 5,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "Saturday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": "Client-Auth-Invalid",
                        "binLowerBound": 6,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "Saturday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": "Client-Auth-Timeout",
                        "binLowerBound": 7,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "Saturday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": "Client-Auth-Unknown",
                        "binLowerBound": 8,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "Saturday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": "Client-DHCP-Invalid",
                        "binLowerBound": 9,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "Saturday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": "Client-DHCP-Timeout",
                        "binLowerBound": 10,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "Saturday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": "Client-Operation-Invalid",
                        "binLowerBound": 11,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "Saturday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": "Client-Operation-Timeout",
                        "binLowerBound": 12,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "Saturday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": "Client-Operation-Unknown",
                        "binLowerBound": 13,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "Saturday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": "Client-Unknown-Blacklisted",
                        "binLowerBound": 14,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "Saturday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": "Network-Association-Fail",
                        "binLowerBound": 15,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "Saturday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": "Network-Association-MaxStations",
                        "binLowerBound": 16,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "Saturday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": "Network-Association-Timeout",
                        "binLowerBound": 17,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "Saturday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": "Network-Auth-Blacklisted",
                        "binLowerBound": 18,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "Saturday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": "Network-Auth-Fail",
                        "binLowerBound": 19,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "Saturday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": "Network-Auth-Invalid",
                        "binLowerBound": 20,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "Saturday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": "Network-Auth-Timeout",
                        "binLowerBound": 21,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "Saturday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": "Network-Auth-Unknown",
                        "binLowerBound": 22,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "Saturday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": "Network-DHCP-Invalid",
                        "binLowerBound": 23,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "Saturday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": "Network-DHCP-Timeout",
                        "binLowerBound": 24,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "Saturday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": "Network-Operation-Fail",
                        "binLowerBound": 25,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "Saturday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": "Network-Operation-Invalid",
                        "binLowerBound": 26,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "Saturday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": "Network-Operation-Timeout",
                        "binLowerBound": 27,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "Saturday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": "Network-Operation-Unknown",
                        "binLowerBound": 28,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "Saturday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": "Unknown-Auth-Timeout",
                        "binLowerBound": 29,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "Saturday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": "Unknown-Operation-Invalid",
                        "binLowerBound": 30,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "Saturday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.000142705364388739,
                        "binCatValue": "Client-Association-Blacklisted",
                        "binLowerBound": 0,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "Sunday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.0111087501127137,
                        "binCatValue": "Client-Association-Fail",
                        "binLowerBound": 1,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "Sunday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.0000489275535047105,
                        "binCatValue": "Client-Association-Invalid",
                        "binLowerBound": 2,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "Sunday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.00157556131430073,
                        "binCatValue": "Client-Association-Unknown",
                        "binLowerBound": 3,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "Sunday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.0233455782899663,
                        "binCatValue": "Client-Auth-Blacklisted",
                        "binLowerBound": 4,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "Sunday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.0540147645165464,
                        "binCatValue": "Client-Auth-Fail",
                        "binLowerBound": 5,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "Sunday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.0447599295882323,
                        "binCatValue": "Client-Auth-Invalid",
                        "binLowerBound": 6,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "Sunday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.654771651814593,
                        "binCatValue": "Client-Auth-Timeout",
                        "binLowerBound": 7,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "Sunday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.0000326183690031403,
                        "binCatValue": "Client-Auth-Unknown",
                        "binLowerBound": 8,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "Sunday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.000383265835786898,
                        "binCatValue": "Client-DHCP-Invalid",
                        "binLowerBound": 9,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "Sunday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.000205903454332323,
                        "binCatValue": "Client-DHCP-Timeout",
                        "binLowerBound": 10,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "Sunday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.000549494062437517,
                        "binCatValue": "Client-Operation-Invalid",
                        "binLowerBound": 11,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "Sunday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.0000366956651285329,
                        "binCatValue": "Client-Operation-Timeout",
                        "binLowerBound": 12,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "Sunday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.0242181980703412,
                        "binCatValue": "Client-Operation-Unknown",
                        "binLowerBound": 13,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "Sunday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.0000881323239411772,
                        "binCatValue": "Client-Unknown-Blacklisted",
                        "binLowerBound": 14,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "Sunday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.000173285085329183,
                        "binCatValue": "Network-Association-Fail",
                        "binLowerBound": 15,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "Sunday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.0614188990516366,
                        "binCatValue": "Network-Association-MaxStations",
                        "binLowerBound": 16,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "Sunday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.00129705062512007,
                        "binCatValue": "Network-Association-Timeout",
                        "binLowerBound": 17,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "Sunday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": "Network-Auth-Blacklisted",
                        "binLowerBound": 18,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "Sunday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.000424038797040824,
                        "binCatValue": "Network-Auth-Fail",
                        "binLowerBound": 19,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "Sunday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.00102747862359892,
                        "binCatValue": "Network-Auth-Invalid",
                        "binLowerBound": 20,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "Sunday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.0216767096220268,
                        "binCatValue": "Network-Auth-Timeout",
                        "binLowerBound": 21,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "Sunday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.000300857408329446,
                        "binCatValue": "Network-Auth-Unknown",
                        "binLowerBound": 22,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "Sunday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": "Network-DHCP-Invalid",
                        "binLowerBound": 23,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "Sunday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.0225870443915616,
                        "binCatValue": "Network-DHCP-Timeout",
                        "binLowerBound": 24,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "Sunday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.0756779092880022,
                        "binCatValue": "Network-Operation-Fail",
                        "binLowerBound": 25,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "Sunday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": "Network-Operation-Invalid",
                        "binLowerBound": 26,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "Sunday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.00000407729612539254,
                        "binCatValue": "Network-Operation-Timeout",
                        "binLowerBound": 27,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "Sunday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.000130473476012561,
                        "binCatValue": "Network-Operation-Unknown",
                        "binLowerBound": 28,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "Sunday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": "Unknown-Auth-Timeout",
                        "binLowerBound": 29,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "Sunday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": "Unknown-Operation-Invalid",
                        "binLowerBound": 30,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "Sunday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.0000409338630525074,
                        "binCatValue": "Client-Association-Blacklisted",
                        "binLowerBound": 0,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "Sunday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.406155270469276,
                        "binCatValue": "Client-Association-Fail",
                        "binLowerBound": 1,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "Sunday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.0000100060554128352,
                        "binCatValue": "Client-Association-Invalid",
                        "binLowerBound": 2,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "Sunday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.000568928217118247,
                        "binCatValue": "Client-Association-Unknown",
                        "binLowerBound": 3,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "Sunday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.0101729396237415,
                        "binCatValue": "Client-Auth-Blacklisted",
                        "binLowerBound": 4,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "Sunday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.0231750739223583,
                        "binCatValue": "Client-Auth-Fail",
                        "binLowerBound": 5,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "Sunday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.0379284778354677,
                        "binCatValue": "Client-Auth-Invalid",
                        "binLowerBound": 6,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "Sunday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.270943915989439,
                        "binCatValue": "Client-Auth-Timeout",
                        "binLowerBound": 7,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "Sunday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.00000727713120933466,
                        "binCatValue": "Client-Auth-Unknown",
                        "binLowerBound": 8,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "Sunday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.000263411158053272,
                        "binCatValue": "Client-DHCP-Invalid",
                        "binLowerBound": 9,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "Sunday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.0000739958293641481,
                        "binCatValue": "Client-DHCP-Timeout",
                        "binLowerBound": 10,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "Sunday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.000639477905020284,
                        "binCatValue": "Client-Operation-Invalid",
                        "binLowerBound": 11,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "Sunday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.0000213765729274206,
                        "binCatValue": "Client-Operation-Timeout",
                        "binLowerBound": 12,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "Sunday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.0201771582606704,
                        "binCatValue": "Client-Operation-Unknown",
                        "binLowerBound": 13,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "Sunday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.000071161946537436,
                        "binCatValue": "Client-Unknown-Blacklisted",
                        "binLowerBound": 14,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "Sunday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.000197847004753786,
                        "binCatValue": "Network-Association-Fail",
                        "binLowerBound": 15,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "Sunday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.142389142352302,
                        "binCatValue": "Network-Association-MaxStations",
                        "binLowerBound": 16,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "Sunday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.000183135304400299,
                        "binCatValue": "Network-Association-Timeout",
                        "binLowerBound": 17,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "Sunday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": "Network-Auth-Blacklisted",
                        "binLowerBound": 18,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "Sunday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.000630329011697009,
                        "binCatValue": "Network-Auth-Fail",
                        "binLowerBound": 19,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "Sunday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.000168283659215864,
                        "binCatValue": "Network-Auth-Invalid",
                        "binLowerBound": 20,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "Sunday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.0141634839713488,
                        "binCatValue": "Network-Auth-Timeout",
                        "binLowerBound": 21,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "Sunday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.000178062304278407,
                        "binCatValue": "Network-Auth-Unknown",
                        "binLowerBound": 22,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "Sunday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.00000363856560466733,
                        "binCatValue": "Network-DHCP-Invalid",
                        "binLowerBound": 23,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "Sunday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.0505718635599474,
                        "binCatValue": "Network-DHCP-Timeout",
                        "binLowerBound": 24,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "Sunday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.0211201765040186,
                        "binCatValue": "Network-Operation-Fail",
                        "binLowerBound": 25,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "Sunday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": "Network-Operation-Invalid",
                        "binLowerBound": 26,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "Sunday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.00000363856560466733,
                        "binCatValue": "Network-Operation-Timeout",
                        "binLowerBound": 27,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "Sunday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.000140994417180859,
                        "binCatValue": "Network-Operation-Unknown",
                        "binLowerBound": 28,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "Sunday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": "Unknown-Auth-Timeout",
                        "binLowerBound": 29,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "Sunday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": "Unknown-Operation-Invalid",
                        "binLowerBound": 30,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "Sunday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": "Client-Association-Blacklisted",
                        "binLowerBound": 0,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "Sunday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": "Client-Association-Fail",
                        "binLowerBound": 1,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "Sunday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": "Client-Association-Invalid",
                        "binLowerBound": 2,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "Sunday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": "Client-Association-Unknown",
                        "binLowerBound": 3,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "Sunday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": "Client-Auth-Blacklisted",
                        "binLowerBound": 4,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "Sunday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": "Client-Auth-Fail",
                        "binLowerBound": 5,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "Sunday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": "Client-Auth-Invalid",
                        "binLowerBound": 6,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "Sunday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": "Client-Auth-Timeout",
                        "binLowerBound": 7,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "Sunday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": "Client-Auth-Unknown",
                        "binLowerBound": 8,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "Sunday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": "Client-DHCP-Invalid",
                        "binLowerBound": 9,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "Sunday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": "Client-DHCP-Timeout",
                        "binLowerBound": 10,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "Sunday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": "Client-Operation-Invalid",
                        "binLowerBound": 11,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "Sunday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": "Client-Operation-Timeout",
                        "binLowerBound": 12,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "Sunday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": "Client-Operation-Unknown",
                        "binLowerBound": 13,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "Sunday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": "Client-Unknown-Blacklisted",
                        "binLowerBound": 14,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "Sunday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": "Network-Association-Fail",
                        "binLowerBound": 15,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "Sunday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": "Network-Association-MaxStations",
                        "binLowerBound": 16,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "Sunday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": "Network-Association-Timeout",
                        "binLowerBound": 17,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "Sunday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": "Network-Auth-Blacklisted",
                        "binLowerBound": 18,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "Sunday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": "Network-Auth-Fail",
                        "binLowerBound": 19,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "Sunday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": "Network-Auth-Invalid",
                        "binLowerBound": 20,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "Sunday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": "Network-Auth-Timeout",
                        "binLowerBound": 21,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "Sunday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": "Network-Auth-Unknown",
                        "binLowerBound": 22,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "Sunday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": "Network-DHCP-Invalid",
                        "binLowerBound": 23,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "Sunday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": "Network-DHCP-Timeout",
                        "binLowerBound": 24,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "Sunday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": "Network-Operation-Fail",
                        "binLowerBound": 25,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "Sunday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": "Network-Operation-Invalid",
                        "binLowerBound": 26,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "Sunday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": "Network-Operation-Timeout",
                        "binLowerBound": 27,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "Sunday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": "Network-Operation-Unknown",
                        "binLowerBound": 28,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "Sunday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": "Unknown-Auth-Timeout",
                        "binLowerBound": 29,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "Sunday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": "Unknown-Operation-Invalid",
                        "binLowerBound": 30,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "Sunday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.000081297405092448,
                        "binCatValue": "Client-Association-Blacklisted",
                        "binLowerBound": 0,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "Thursday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.0704757736208259,
                        "binCatValue": "Client-Association-Fail",
                        "binLowerBound": 1,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "Thursday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.000018235703146847,
                        "binCatValue": "Client-Association-Invalid",
                        "binLowerBound": 2,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "Thursday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.00193085978534845,
                        "binCatValue": "Client-Association-Unknown",
                        "binLowerBound": 3,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "Thursday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.0220724620831461,
                        "binCatValue": "Client-Auth-Blacklisted",
                        "binLowerBound": 4,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "Thursday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.0637875819482382,
                        "binCatValue": "Client-Auth-Fail",
                        "binLowerBound": 5,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "Thursday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.0526232058976739,
                        "binCatValue": "Client-Auth-Invalid",
                        "binLowerBound": 6,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "Thursday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.57788621465832,
                        "binCatValue": "Client-Auth-Timeout",
                        "binLowerBound": 7,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "Thursday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.0000235991452488608,
                        "binCatValue": "Client-Auth-Unknown",
                        "binLowerBound": 8,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "Thursday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.000388271950938862,
                        "binCatValue": "Client-DHCP-Invalid",
                        "binLowerBound": 9,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "Thursday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.000389035210007226,
                        "binCatValue": "Client-DHCP-Timeout",
                        "binLowerBound": 10,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "Thursday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.000395306311541888,
                        "binCatValue": "Client-Operation-Invalid",
                        "binLowerBound": 11,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "Thursday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.0000536137923966689,
                        "binCatValue": "Client-Operation-Timeout",
                        "binLowerBound": 12,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "Thursday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.028023840582658,
                        "binCatValue": "Client-Operation-Unknown",
                        "binLowerBound": 13,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "Thursday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.000134931826112586,
                        "binCatValue": "Client-Unknown-Blacklisted",
                        "binLowerBound": 14,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "Thursday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.000573888304915479,
                        "binCatValue": "Network-Association-Fail",
                        "binLowerBound": 15,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "Thursday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.0652059029548688,
                        "binCatValue": "Network-Association-MaxStations",
                        "binLowerBound": 16,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "Thursday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.0014880457539568,
                        "binCatValue": "Network-Association-Timeout",
                        "binLowerBound": 17,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "Thursday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": "Network-Auth-Blacklisted",
                        "binLowerBound": 18,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "Thursday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.000345632586227852,
                        "binCatValue": "Network-Auth-Fail",
                        "binLowerBound": 19,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "Thursday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.00116784826046657,
                        "binCatValue": "Network-Auth-Invalid",
                        "binLowerBound": 20,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "Thursday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.0204595718999532,
                        "binCatValue": "Network-Auth-Timeout",
                        "binLowerBound": 21,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "Thursday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.000459729502636462,
                        "binCatValue": "Network-Auth-Unknown",
                        "binLowerBound": 22,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "Thursday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.0000074469330724115,
                        "binCatValue": "Network-DHCP-Invalid",
                        "binLowerBound": 23,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "Thursday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.0170526928481305,
                        "binCatValue": "Network-DHCP-Timeout",
                        "binLowerBound": 24,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "Thursday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.0746911915695272,
                        "binCatValue": "Network-Operation-Fail",
                        "binLowerBound": 25,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "Thursday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": "Network-Operation-Invalid",
                        "binLowerBound": 26,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "Thursday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.0000117376867540226,
                        "binCatValue": "Network-Operation-Timeout",
                        "binLowerBound": 27,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "Thursday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.00025208177879465,
                        "binCatValue": "Network-Operation-Unknown",
                        "binLowerBound": 28,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "Thursday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": "Unknown-Auth-Timeout",
                        "binLowerBound": 29,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "Thursday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": "Unknown-Operation-Invalid",
                        "binLowerBound": 30,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "Thursday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.0000290618713945709,
                        "binCatValue": "Client-Association-Blacklisted",
                        "binLowerBound": 0,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "Thursday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.271908050096166,
                        "binCatValue": "Client-Association-Fail",
                        "binLowerBound": 1,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "Thursday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.0000282598734911623,
                        "binCatValue": "Client-Association-Invalid",
                        "binLowerBound": 2,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "Thursday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.0021404849174797,
                        "binCatValue": "Client-Association-Unknown",
                        "binLowerBound": 3,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "Thursday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.0107903963705796,
                        "binCatValue": "Client-Auth-Blacklisted",
                        "binLowerBound": 4,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "Thursday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.035928123681584,
                        "binCatValue": "Client-Auth-Fail",
                        "binLowerBound": 5,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "Thursday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.0540531760488813,
                        "binCatValue": "Client-Auth-Invalid",
                        "binLowerBound": 6,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "Thursday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.30454913260761,
                        "binCatValue": "Client-Auth-Timeout",
                        "binLowerBound": 7,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "Thursday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.0000156389591164685,
                        "binCatValue": "Client-Auth-Unknown",
                        "binLowerBound": 8,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "Thursday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.000315121860415642,
                        "binCatValue": "Client-DHCP-Invalid",
                        "binLowerBound": 9,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "Thursday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.000143346572630302,
                        "binCatValue": "Client-DHCP-Timeout",
                        "binLowerBound": 10,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "Thursday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.000663927632774446,
                        "binCatValue": "Client-Operation-Invalid",
                        "binLowerBound": 11,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "Thursday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.0000272890339238782,
                        "binCatValue": "Client-Operation-Timeout",
                        "binLowerBound": 12,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "Thursday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.0258398553702307,
                        "binCatValue": "Client-Operation-Unknown",
                        "binLowerBound": 13,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "Thursday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.000129084728334818,
                        "binCatValue": "Client-Unknown-Blacklisted",
                        "binLowerBound": 14,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "Thursday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.00122957886456936,
                        "binCatValue": "Network-Association-Fail",
                        "binLowerBound": 15,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "Thursday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.192531916878405,
                        "binCatValue": "Network-Association-MaxStations",
                        "binLowerBound": 16,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "Thursday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.000393258616676027,
                        "binCatValue": "Network-Association-Timeout",
                        "binLowerBound": 17,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "Thursday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": "Network-Auth-Blacklisted",
                        "binLowerBound": 18,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "Thursday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.00052747718685306,
                        "binCatValue": "Network-Auth-Fail",
                        "binLowerBound": 19,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "Thursday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.000435965005032537,
                        "binCatValue": "Network-Auth-Invalid",
                        "binLowerBound": 20,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "Thursday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.016564496354814,
                        "binCatValue": "Network-Auth-Timeout",
                        "binLowerBound": 21,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "Thursday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.000351301463202965,
                        "binCatValue": "Network-Auth-Unknown",
                        "binLowerBound": 22,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "Thursday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.00000987723733671692,
                        "binCatValue": "Network-DHCP-Invalid",
                        "binLowerBound": 23,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "Thursday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.0342418070110234,
                        "binCatValue": "Network-DHCP-Timeout",
                        "binLowerBound": 24,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "Thursday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.0468836524757359,
                        "binCatValue": "Network-Operation-Fail",
                        "binLowerBound": 25,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "Thursday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": "Network-Operation-Invalid",
                        "binLowerBound": 26,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "Thursday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.00000246930933417923,
                        "binCatValue": "Network-Operation-Timeout",
                        "binLowerBound": 27,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "Thursday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.000267249972404941,
                        "binCatValue": "Network-Operation-Unknown",
                        "binLowerBound": 28,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "Thursday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": "Unknown-Auth-Timeout",
                        "binLowerBound": 29,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "Thursday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": "Unknown-Operation-Invalid",
                        "binLowerBound": 30,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "Thursday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": "Client-Association-Blacklisted",
                        "binLowerBound": 0,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "Thursday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": "Client-Association-Fail",
                        "binLowerBound": 1,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "Thursday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": "Client-Association-Invalid",
                        "binLowerBound": 2,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "Thursday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": "Client-Association-Unknown",
                        "binLowerBound": 3,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "Thursday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": "Client-Auth-Blacklisted",
                        "binLowerBound": 4,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "Thursday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": "Client-Auth-Fail",
                        "binLowerBound": 5,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "Thursday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": "Client-Auth-Invalid",
                        "binLowerBound": 6,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "Thursday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": "Client-Auth-Timeout",
                        "binLowerBound": 7,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "Thursday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": "Client-Auth-Unknown",
                        "binLowerBound": 8,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "Thursday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": "Client-DHCP-Invalid",
                        "binLowerBound": 9,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "Thursday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": "Client-DHCP-Timeout",
                        "binLowerBound": 10,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "Thursday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": "Client-Operation-Invalid",
                        "binLowerBound": 11,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "Thursday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": "Client-Operation-Timeout",
                        "binLowerBound": 12,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "Thursday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": "Client-Operation-Unknown",
                        "binLowerBound": 13,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "Thursday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": "Client-Unknown-Blacklisted",
                        "binLowerBound": 14,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "Thursday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": "Network-Association-Fail",
                        "binLowerBound": 15,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "Thursday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": "Network-Association-MaxStations",
                        "binLowerBound": 16,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "Thursday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": "Network-Association-Timeout",
                        "binLowerBound": 17,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "Thursday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": "Network-Auth-Blacklisted",
                        "binLowerBound": 18,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "Thursday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": "Network-Auth-Fail",
                        "binLowerBound": 19,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "Thursday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": "Network-Auth-Invalid",
                        "binLowerBound": 20,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "Thursday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": "Network-Auth-Timeout",
                        "binLowerBound": 21,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "Thursday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": "Network-Auth-Unknown",
                        "binLowerBound": 22,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "Thursday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": "Network-DHCP-Invalid",
                        "binLowerBound": 23,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "Thursday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": "Network-DHCP-Timeout",
                        "binLowerBound": 24,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "Thursday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": "Network-Operation-Fail",
                        "binLowerBound": 25,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "Thursday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": "Network-Operation-Invalid",
                        "binLowerBound": 26,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "Thursday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": "Network-Operation-Timeout",
                        "binLowerBound": 27,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "Thursday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": "Network-Operation-Unknown",
                        "binLowerBound": 28,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "Thursday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": "Unknown-Auth-Timeout",
                        "binLowerBound": 29,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "Thursday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": "Unknown-Operation-Invalid",
                        "binLowerBound": 30,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "Thursday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.000119660949454294,
                        "binCatValue": "Client-Association-Blacklisted",
                        "binLowerBound": 0,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "Tuesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.0786484518025495,
                        "binCatValue": "Client-Association-Fail",
                        "binLowerBound": 1,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "Tuesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.0000129730976284792,
                        "binCatValue": "Client-Association-Invalid",
                        "binLowerBound": 2,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "Tuesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.00189609079517219,
                        "binCatValue": "Client-Association-Unknown",
                        "binLowerBound": 3,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "Tuesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.0232886834240532,
                        "binCatValue": "Client-Auth-Blacklisted",
                        "binLowerBound": 4,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "Tuesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.0595359264423548,
                        "binCatValue": "Client-Auth-Fail",
                        "binLowerBound": 5,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "Tuesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.0538184419069336,
                        "binCatValue": "Client-Auth-Invalid",
                        "binLowerBound": 6,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "Tuesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.570057913767593,
                        "binCatValue": "Client-Auth-Timeout",
                        "binLowerBound": 7,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "Tuesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.0000129730976284792,
                        "binCatValue": "Client-Auth-Unknown",
                        "binLowerBound": 8,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "Tuesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.000388512521636099,
                        "binCatValue": "Client-DHCP-Invalid",
                        "binLowerBound": 9,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "Tuesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.000402302107926511,
                        "binCatValue": "Client-DHCP-Timeout",
                        "binLowerBound": 10,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "Tuesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.000563785421064224,
                        "binCatValue": "Client-Operation-Invalid",
                        "binLowerBound": 11,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "Tuesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.0000682448439931712,
                        "binCatValue": "Client-Operation-Timeout",
                        "binLowerBound": 12,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "Tuesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.0287474771350854,
                        "binCatValue": "Client-Operation-Unknown",
                        "binLowerBound": 13,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "Tuesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.000110634213691821,
                        "binCatValue": "Client-Unknown-Blacklisted",
                        "binLowerBound": 14,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "Tuesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.000711320386227261,
                        "binCatValue": "Network-Association-Fail",
                        "binLowerBound": 15,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "Tuesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.0625995960240903,
                        "binCatValue": "Network-Association-MaxStations",
                        "binLowerBound": 16,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "Tuesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.00144121588951348,
                        "binCatValue": "Network-Association-Timeout",
                        "binLowerBound": 17,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "Tuesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": "Network-Auth-Blacklisted",
                        "binLowerBound": 18,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "Tuesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.000396994931623951,
                        "binCatValue": "Network-Auth-Fail",
                        "binLowerBound": 19,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "Tuesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.00122296393413087,
                        "binCatValue": "Network-Auth-Invalid",
                        "binLowerBound": 20,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "Tuesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.0190217590372764,
                        "binCatValue": "Network-Auth-Timeout",
                        "binLowerBound": 21,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "Tuesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.000464151124067879,
                        "binCatValue": "Network-Auth-Unknown",
                        "binLowerBound": 22,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "Tuesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.00000825560758175949,
                        "binCatValue": "Network-DHCP-Invalid",
                        "binLowerBound": 23,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "Tuesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.0171742946779704,
                        "binCatValue": "Network-DHCP-Timeout",
                        "binLowerBound": 24,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "Tuesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.0790762691811614,
                        "binCatValue": "Network-Operation-Fail",
                        "binLowerBound": 25,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "Tuesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": "Network-Operation-Invalid",
                        "binLowerBound": 26,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "Tuesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.00000471749004671971,
                        "binCatValue": "Network-Operation-Timeout",
                        "binLowerBound": 27,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "Tuesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.000206390189543987,
                        "binCatValue": "Network-Operation-Unknown",
                        "binLowerBound": 28,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "Tuesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": "Unknown-Auth-Timeout",
                        "binLowerBound": 29,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "Tuesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": "Unknown-Operation-Invalid",
                        "binLowerBound": 30,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "Tuesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.0000312512804619688,
                        "binCatValue": "Client-Association-Blacklisted",
                        "binLowerBound": 0,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "Tuesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.281005441685907,
                        "binCatValue": "Client-Association-Fail",
                        "binLowerBound": 1,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "Tuesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.0000241755188479381,
                        "binCatValue": "Client-Association-Invalid",
                        "binLowerBound": 2,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "Tuesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.00237220577791158,
                        "binCatValue": "Client-Association-Unknown",
                        "binLowerBound": 3,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "Tuesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.0106559042215836,
                        "binCatValue": "Client-Auth-Blacklisted",
                        "binLowerBound": 4,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "Tuesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.0339535693852388,
                        "binCatValue": "Client-Auth-Fail",
                        "binLowerBound": 5,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "Tuesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.0547625451793474,
                        "binCatValue": "Client-Auth-Invalid",
                        "binLowerBound": 6,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "Tuesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.300640419359527,
                        "binCatValue": "Client-Auth-Timeout",
                        "binLowerBound": 7,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "Tuesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.0000200933486859973,
                        "binCatValue": "Client-Auth-Unknown",
                        "binLowerBound": 8,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "Tuesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.000320580760370301,
                        "binCatValue": "Client-DHCP-Invalid",
                        "binLowerBound": 9,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "Tuesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.000151346458753954,
                        "binCatValue": "Client-DHCP-Timeout",
                        "binLowerBound": 10,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "Tuesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.000651383955187795,
                        "binCatValue": "Client-Operation-Invalid",
                        "binLowerBound": 11,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "Tuesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.0000312229320580664,
                        "binCatValue": "Client-Operation-Timeout",
                        "binLowerBound": 12,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "Tuesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.0269694354823013,
                        "binCatValue": "Client-Operation-Unknown",
                        "binLowerBound": 13,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "Tuesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.0000934476786237607,
                        "binCatValue": "Client-Unknown-Blacklisted",
                        "binLowerBound": 14,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "Tuesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.00164401465719072,
                        "binCatValue": "Network-Association-Fail",
                        "binLowerBound": 15,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "Tuesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.19342198759924,
                        "binCatValue": "Network-Association-MaxStations",
                        "binLowerBound": 16,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "Tuesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.000380809779301267,
                        "binCatValue": "Network-Association-Timeout",
                        "binLowerBound": 17,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "Tuesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": "Network-Auth-Blacklisted",
                        "binLowerBound": 18,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "Tuesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.000546063965009724,
                        "binCatValue": "Network-Auth-Fail",
                        "binLowerBound": 19,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "Tuesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.000457962795361949,
                        "binCatValue": "Network-Auth-Invalid",
                        "binLowerBound": 20,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "Tuesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.0145486673576912,
                        "binCatValue": "Network-Auth-Timeout",
                        "binLowerBound": 21,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "Tuesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.000334426120836217,
                        "binCatValue": "Network-Auth-Unknown",
                        "binLowerBound": 22,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "Tuesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.00000701339512544546,
                        "binCatValue": "Network-DHCP-Invalid",
                        "binLowerBound": 23,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "Tuesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.0316248500589133,
                        "binCatValue": "Network-DHCP-Timeout",
                        "binLowerBound": 24,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "Tuesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.0450924170157031,
                        "binCatValue": "Network-Operation-Fail",
                        "binLowerBound": 25,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "Tuesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": "Network-Operation-Invalid",
                        "binLowerBound": 26,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "Tuesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.00000263073188213961,
                        "binCatValue": "Network-Operation-Timeout",
                        "binLowerBound": 27,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "Tuesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.000256133498938661,
                        "binCatValue": "Network-Operation-Unknown",
                        "binLowerBound": 28,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "Tuesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": "Unknown-Auth-Timeout",
                        "binLowerBound": 29,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "Tuesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": "Unknown-Operation-Invalid",
                        "binLowerBound": 30,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "Tuesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": "Client-Association-Blacklisted",
                        "binLowerBound": 0,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "Tuesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": "Client-Association-Fail",
                        "binLowerBound": 1,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "Tuesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": "Client-Association-Invalid",
                        "binLowerBound": 2,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "Tuesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": "Client-Association-Unknown",
                        "binLowerBound": 3,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "Tuesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": "Client-Auth-Blacklisted",
                        "binLowerBound": 4,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "Tuesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": "Client-Auth-Fail",
                        "binLowerBound": 5,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "Tuesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": "Client-Auth-Invalid",
                        "binLowerBound": 6,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "Tuesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": "Client-Auth-Timeout",
                        "binLowerBound": 7,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "Tuesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": "Client-Auth-Unknown",
                        "binLowerBound": 8,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "Tuesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": "Client-DHCP-Invalid",
                        "binLowerBound": 9,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "Tuesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": "Client-DHCP-Timeout",
                        "binLowerBound": 10,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "Tuesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": "Client-Operation-Invalid",
                        "binLowerBound": 11,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "Tuesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": "Client-Operation-Timeout",
                        "binLowerBound": 12,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "Tuesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": "Client-Operation-Unknown",
                        "binLowerBound": 13,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "Tuesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": "Client-Unknown-Blacklisted",
                        "binLowerBound": 14,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "Tuesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": "Network-Association-Fail",
                        "binLowerBound": 15,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "Tuesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": "Network-Association-MaxStations",
                        "binLowerBound": 16,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "Tuesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": "Network-Association-Timeout",
                        "binLowerBound": 17,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "Tuesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": "Network-Auth-Blacklisted",
                        "binLowerBound": 18,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "Tuesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": "Network-Auth-Fail",
                        "binLowerBound": 19,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "Tuesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": "Network-Auth-Invalid",
                        "binLowerBound": 20,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "Tuesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": "Network-Auth-Timeout",
                        "binLowerBound": 21,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "Tuesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": "Network-Auth-Unknown",
                        "binLowerBound": 22,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "Tuesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": "Network-DHCP-Invalid",
                        "binLowerBound": 23,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "Tuesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": "Network-DHCP-Timeout",
                        "binLowerBound": 24,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "Tuesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": "Network-Operation-Fail",
                        "binLowerBound": 25,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "Tuesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": "Network-Operation-Invalid",
                        "binLowerBound": 26,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "Tuesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": "Network-Operation-Timeout",
                        "binLowerBound": 27,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "Tuesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": "Network-Operation-Unknown",
                        "binLowerBound": 28,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "Tuesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": "Unknown-Auth-Timeout",
                        "binLowerBound": 29,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "Tuesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": "Unknown-Operation-Invalid",
                        "binLowerBound": 30,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "Tuesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.000117817883766645,
                        "binCatValue": "Client-Association-Blacklisted",
                        "binLowerBound": 0,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "Wednesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.0781901009009832,
                        "binCatValue": "Client-Association-Fail",
                        "binLowerBound": 1,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "Wednesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.0000222529012832513,
                        "binCatValue": "Client-Association-Invalid",
                        "binLowerBound": 2,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "Wednesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.00242679117022026,
                        "binCatValue": "Client-Association-Unknown",
                        "binLowerBound": 3,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "Wednesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.024160403648847,
                        "binCatValue": "Client-Auth-Blacklisted",
                        "binLowerBound": 4,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "Wednesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.061929170975104,
                        "binCatValue": "Client-Auth-Fail",
                        "binLowerBound": 5,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "Wednesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.0539274359837621,
                        "binCatValue": "Client-Auth-Invalid",
                        "binLowerBound": 6,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "Wednesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.570496425683873,
                        "binCatValue": "Client-Auth-Timeout",
                        "binLowerBound": 7,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "Wednesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.0000243352828712253,
                        "binCatValue": "Client-Auth-Unknown",
                        "binLowerBound": 8,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "Wednesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.000400205159500528,
                        "binCatValue": "Client-DHCP-Invalid",
                        "binLowerBound": 9,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "Wednesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.000363028523503463,
                        "binCatValue": "Client-DHCP-Timeout",
                        "binLowerBound": 10,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "Wednesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.00049548432490322,
                        "binCatValue": "Client-Operation-Invalid",
                        "binLowerBound": 11,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "Wednesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.0000605932211088899,
                        "binCatValue": "Client-Operation-Timeout",
                        "binLowerBound": 12,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "Wednesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.0284530291373977,
                        "binCatValue": "Client-Operation-Unknown",
                        "binLowerBound": 13,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "Wednesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.0000789671762968955,
                        "binCatValue": "Client-Unknown-Blacklisted",
                        "binLowerBound": 14,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "Wednesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.000893076299665898,
                        "binCatValue": "Network-Association-Fail",
                        "binLowerBound": 15,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "Wednesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.0653684079071949,
                        "binCatValue": "Network-Association-MaxStations",
                        "binLowerBound": 16,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "Wednesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.00137961863304429,
                        "binCatValue": "Network-Association-Timeout",
                        "binLowerBound": 17,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "Wednesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": "Network-Auth-Blacklisted",
                        "binLowerBound": 18,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "Wednesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.000372072592557115,
                        "binCatValue": "Network-Auth-Fail",
                        "binLowerBound": 19,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "Wednesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.00118264983342887,
                        "binCatValue": "Network-Auth-Invalid",
                        "binLowerBound": 20,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "Wednesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.021336285905439,
                        "binCatValue": "Network-Auth-Timeout",
                        "binLowerBound": 21,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "Wednesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.000485031585951821,
                        "binCatValue": "Network-Auth-Unknown",
                        "binLowerBound": 22,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "Wednesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.00000636963779850864,
                        "binCatValue": "Network-DHCP-Invalid",
                        "binLowerBound": 23,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "Wednesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.0162766498653179,
                        "binCatValue": "Network-DHCP-Timeout",
                        "binLowerBound": 24,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "Wednesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.0713193645134861,
                        "binCatValue": "Network-Operation-Fail",
                        "binLowerBound": 25,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "Wednesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": "Network-Operation-Invalid",
                        "binLowerBound": 26,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "Wednesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.0000104935699629277,
                        "binCatValue": "Network-Operation-Timeout",
                        "binLowerBound": 27,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "Wednesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0.00022393768273026,
                        "binCatValue": "Network-Operation-Unknown",
                        "binLowerBound": 28,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "Wednesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": "Unknown-Auth-Timeout",
                        "binLowerBound": 29,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "Wednesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "2.4 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": "Unknown-Operation-Invalid",
                        "binLowerBound": 30,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "Wednesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.0000308227475243106,
                        "binCatValue": "Client-Association-Blacklisted",
                        "binLowerBound": 0,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "Wednesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.279438886239425,
                        "binCatValue": "Client-Association-Fail",
                        "binLowerBound": 1,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "Wednesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.0000253753382315812,
                        "binCatValue": "Client-Association-Invalid",
                        "binLowerBound": 2,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "Wednesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.0022349271766047,
                        "binCatValue": "Client-Association-Unknown",
                        "binLowerBound": 3,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "Wednesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.0118110085613076,
                        "binCatValue": "Client-Auth-Blacklisted",
                        "binLowerBound": 4,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "Wednesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.0364476209664052,
                        "binCatValue": "Client-Auth-Fail",
                        "binLowerBound": 5,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "Wednesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.0525629244631013,
                        "binCatValue": "Client-Auth-Invalid",
                        "binLowerBound": 6,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "Wednesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.297943934529077,
                        "binCatValue": "Client-Auth-Timeout",
                        "binLowerBound": 7,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "Wednesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.0000234779260060238,
                        "binCatValue": "Client-Auth-Unknown",
                        "binLowerBound": 8,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "Wednesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.000346920403047081,
                        "binCatValue": "Client-DHCP-Invalid",
                        "binLowerBound": 9,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "Wednesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.0001264737460885,
                        "binCatValue": "Client-DHCP-Timeout",
                        "binLowerBound": 10,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "Wednesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.000640437832971618,
                        "binCatValue": "Client-Operation-Invalid",
                        "binLowerBound": 11,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "Wednesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.0000321948009884906,
                        "binCatValue": "Client-Operation-Timeout",
                        "binLowerBound": 12,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "Wednesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.026235860581006,
                        "binCatValue": "Client-Operation-Unknown",
                        "binLowerBound": 13,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "Wednesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.0000676029613912316,
                        "binCatValue": "Client-Unknown-Blacklisted",
                        "binLowerBound": 14,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "Wednesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.00193601334309242,
                        "binCatValue": "Network-Association-Fail",
                        "binLowerBound": 15,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "Wednesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.195614345864585,
                        "binCatValue": "Network-Association-MaxStations",
                        "binLowerBound": 16,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "Wednesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.000374728713406596,
                        "binCatValue": "Network-Association-Timeout",
                        "binLowerBound": 17,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "Wednesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": "Network-Auth-Blacklisted",
                        "binLowerBound": 18,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "Wednesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.000480091198200519,
                        "binCatValue": "Network-Auth-Fail",
                        "binLowerBound": 19,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "Wednesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.000460224476107653,
                        "binCatValue": "Network-Auth-Invalid",
                        "binLowerBound": 20,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "Wednesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.015727859061036,
                        "binCatValue": "Network-Auth-Timeout",
                        "binLowerBound": 21,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "Wednesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.00033517888975882,
                        "binCatValue": "Network-Auth-Unknown",
                        "binLowerBound": 22,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "Wednesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.00000954316740327406,
                        "binCatValue": "Network-DHCP-Invalid",
                        "binLowerBound": 23,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "Wednesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.0336530693958119,
                        "binCatValue": "Network-DHCP-Timeout",
                        "binLowerBound": 24,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "Wednesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.0431826641809887,
                        "binCatValue": "Network-Operation-Fail",
                        "binLowerBound": 25,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "Wednesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": "Network-Operation-Invalid",
                        "binLowerBound": 26,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "Wednesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.00000212183732750508,
                        "binCatValue": "Network-Operation-Timeout",
                        "binLowerBound": 27,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "Wednesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0.00025569159910536,
                        "binCatValue": "Network-Operation-Unknown",
                        "binLowerBound": 28,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "Wednesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": "Unknown-Auth-Timeout",
                        "binLowerBound": 29,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "Wednesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "5 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": "Unknown-Operation-Invalid",
                        "binLowerBound": 30,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "Wednesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": "Client-Association-Blacklisted",
                        "binLowerBound": 0,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "Wednesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": "Client-Association-Fail",
                        "binLowerBound": 1,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "Wednesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": "Client-Association-Invalid",
                        "binLowerBound": 2,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "Wednesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": "Client-Association-Unknown",
                        "binLowerBound": 3,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "Wednesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": "Client-Auth-Blacklisted",
                        "binLowerBound": 4,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "Wednesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": "Client-Auth-Fail",
                        "binLowerBound": 5,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "Wednesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": "Client-Auth-Invalid",
                        "binLowerBound": 6,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "Wednesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": "Client-Auth-Timeout",
                        "binLowerBound": 7,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "Wednesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": "Client-Auth-Unknown",
                        "binLowerBound": 8,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "Wednesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": "Client-DHCP-Invalid",
                        "binLowerBound": 9,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "Wednesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": "Client-DHCP-Timeout",
                        "binLowerBound": 10,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "Wednesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": "Client-Operation-Invalid",
                        "binLowerBound": 11,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "Wednesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": "Client-Operation-Timeout",
                        "binLowerBound": 12,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "Wednesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": "Client-Operation-Unknown",
                        "binLowerBound": 13,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "Wednesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": "Client-Unknown-Blacklisted",
                        "binLowerBound": 14,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "Wednesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": "Network-Association-Fail",
                        "binLowerBound": 15,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "Wednesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": "Network-Association-MaxStations",
                        "binLowerBound": 16,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "Wednesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": "Network-Association-Timeout",
                        "binLowerBound": 17,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "Wednesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": "Network-Auth-Blacklisted",
                        "binLowerBound": 18,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "Wednesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": "Network-Auth-Fail",
                        "binLowerBound": 19,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "Wednesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": "Network-Auth-Invalid",
                        "binLowerBound": 20,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "Wednesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": "Network-Auth-Timeout",
                        "binLowerBound": 21,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "Wednesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": "Network-Auth-Unknown",
                        "binLowerBound": 22,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "Wednesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": "Network-DHCP-Invalid",
                        "binLowerBound": 23,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "Wednesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": "Network-DHCP-Timeout",
                        "binLowerBound": 24,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "Wednesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": "Network-Operation-Fail",
                        "binLowerBound": 25,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "Wednesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": "Network-Operation-Invalid",
                        "binLowerBound": 26,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "Wednesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": "Network-Operation-Timeout",
                        "binLowerBound": 27,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "Wednesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": "Network-Operation-Unknown",
                        "binLowerBound": 28,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "Wednesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": "Unknown-Auth-Timeout",
                        "binLowerBound": 29,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "Wednesday"
                    }, {
                        "__typename": "SmartDashboardPeers36",
                        "band": "6 GHz",
                        "binAvgFrequency": 0,
                        "binCatValue": "Unknown-Operation-Invalid",
                        "binLowerBound": 30,
                        "lastTimestamp": "2022-05-12T00:00:00",
                        "variable": "tagRoamingSourceModuleType",
                        "weekday": "Wednesday"
                    }],
                    "totalCount": 744
                }
            }
        }
})