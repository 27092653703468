define([
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/database/SimLokiDatabaseActions',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/wireless/WirelessTemplate',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/site/SiteTemplate',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/utilities/UtilityFunctions',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/design/AuthenticationTemplate',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/wireless/APWirelessConfigTemplate',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/data-generation/services/SimNetworkDeviceData',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/utilities/SimulationUtility',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/wireless/WlanTemplate',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/data-generation/services/SimSiteProfileData',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/data-generation/configuration/DefaultConfig',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/data-generation/services/SimTopologyData',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/nfv/DeviceCategoriesTemplate',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/design/SwitchingNwrkProfileTemplate'
    ],
function(SimLokiDatabaseActions, WirelessTemplate, SiteTemplate, UtilityFunctions,AuthenticationTemplate,
    APWirelessConfigTemplate,SimNetworkDeviceData,SimulationUtility,WlanTemplate,SimSiteProfileData, DefaultConfig,SimTopologyData,
    DeviceCategoriesTemplate, SwitchingNwrkProfileTemplate) {

    function createAuthProfile() {
      var tJsonArr = JSON.parse(JSON.stringify( AuthenticationTemplate.AuthProfile_Template ));
      for(var i = 0, len = tJsonArr.length; i < len; i++){
          SimLokiDatabaseActions.insert( 'siteprofile', tJsonArr[i] );
      }
   }

   function createEnterpriseWlan() {
     var tJsonArr = JSON.parse(JSON.stringify( WlanTemplate.Enterprise_Wlan_Template));
     var k;
     for(k = 0; k < tJsonArr.length; k++) {
         SimLokiDatabaseActions.insert( 'wlan', tJsonArr[k] );
     }

    var tSensorJsonArr = JSON.parse(JSON.stringify( WlanTemplate.Sensor_Template));
    for(k = 0; k < tSensorJsonArr.length; k++) {
        SimLokiDatabaseActions.insert( 'wlan', tSensorJsonArr[k] );
    }

    /* var tJson = JSON.parse(JSON.stringify(WlanTemplate.GUEST_WLAN_TEMPLATE));
    SimLokiDatabaseActions.insert('wlan',tJson); */

     //Automatic creation of wireless Profile
     var tJsonArr = JSON.parse(JSON.stringify( WlanTemplate.WlanProfile_Template));
     for(k = 0; k < tJsonArr.length; k++) {
         tJsonArr[k].lastUpdatedDatetime = UtilityFunctions.getTimeStamp();
         SimLokiDatabaseActions.insert( 'siteprofile', tJsonArr[k] );  
     }

     //Automatic updation of sites in wireless Profile

           for(var i=0; i<tJsonArr.length;i++) {
               var profileId = tJsonArr[i].id;
               var tempSites = tJsonArr[i].siteName.split(',');
               tempSites.forEach(siteNameIter => {
                    var siteRecord = SimLokiDatabaseActions.getFilteredRecordHandler('site',{name:siteNameIter});
                    var siteId = siteRecord[0].id;
                    //Simulate the siterofile/site POST call, to add sites profile Attributes ..
                    var urlAction = {url:'/api/v1/siteprofile/'+profileId+'/site/'+siteId,service:['siteprofile','site','-1'],method:'POST'};
                    SimSiteProfileData.addSiteAttributes(urlAction); 
               });
           }
         //Automatic WLC Provisioning
         var deviceList =DefaultConfig.Wlc_Provision_list;
         var profileList= ["4bead126-9c3a-4553-a34f-cc2d10cc1118","4bead126-9c3a-4553-a34f-cc2d10cc1119","4bead126-9c3a-4553-a34f-cc2d10cc1726",
            "4bead126-9c3a-4553-a34f-cc2d10cc1727", "f3988658-d73a-49c3-8d7b-47501a4a54b7"];
         for(var k=0;k<deviceList.length;k++)  {
              var profileId = profileList[k];
              var hostName= deviceList[k];
              var profileData = SimLokiDatabaseActions.getFilteredRecordHandler('siteprofile', { 'id': profileId });
              var siteRecord = SimLokiDatabaseActions.getFilteredRecordHandler('site',{name:profileData[0].siteName});
              var deviceRecord = SimLokiDatabaseActions.getFilteredRecordHandler('network-device',{hostname:hostName});
              console.log(deviceRecord);
              if(deviceRecord && deviceRecord.length >0 )
              {
                var childrenSites=[];
                SimulationUtility.getChildrenSites(siteRecord[0].id,childrenSites);
                childrenSites.push(siteRecord[0]);

                for(var index in childrenSites)
                {
                  var individualSite = childrenSites[index];
                  //Set the device id in the siteRecord
                  var additionalInfoObj = {nameSpace:"com.wireless.managingwlc",attributes:{primaryWlc:deviceRecord[0].id,
                                                      primaryWlcInheritedFrom:individualSite.id}};
                  individualSite.additionalInfo.push(additionalInfoObj);
                  console.log(individualSite);
                }
                /*FIXME -- deviceRecord what is passed , what needed in function is having mismatch and big error prone code..
                  It is better somebody look and clean/refactor the below code and function which is called as well..   
                */
                //Setting the profileid and version in the device obj and creating an entry in host-onboarding-wlan table
                deviceRecord[0].siteId = siteRecord[0].id;
                deviceRecord[0].profileId = profileId;
                deviceRecord[0].profileVersion = '1';
                deviceRecord[0].networkDeviceId = deviceRecord[0].id;
                deviceRecord[0].roles = ["ENDPOINT_PROXY"];
                SimNetworkDeviceData.CreateOrUpdateDeviceInfo('DeviceInfo','post',deviceRecord[0]);

                // Multisite - updating site id the device object
                 //SimTopologyData.updateSiteDetails( deviceRecord[0].id,siteRecord[0].id);
              }
         }


   }

   function createSwitchingProfile() {
        //Automatic creation of switching Profile and updation of sites in switching Profile
        var tJsonArr = JSON.parse(JSON.stringify( SwitchingNwrkProfileTemplate.Switching_Template));
        for(var i=0; i<tJsonArr.length;i++) {
            tJsonArr[i].lastUpdatedDatetime = UtilityFunctions.getTimeStamp();
            SimLokiDatabaseActions.insert('siteprofile', tJsonArr[i]);
            var profileId = tJsonArr[i].id;
            var site;
            switch (tJsonArr[i].name) {
                case 'switch-stack-3members':
                    site = 'SJC04';
                    break;
                case 'mobExpr-switching-profile':
                    site = 'SFO10';
                    break;
                case 'IE_alarm_profile':
                    site = 'SJC06';
                    break;
                case 'Wide Area Bonjour Profile': //to assign site
                default:
                    site = 'SJC01';
                    break;
            }
            var siteRecord = SimLokiDatabaseActions.getFilteredRecordHandler('site',{name:site});
            var siteId = siteRecord[0].id;
            //Simulate the siterofile/site POST call, to add sites profile Attributes ..
            var urlAction = {url:'/api/v1/siteprofile/'+profileId+'/site/'+siteId,service:['siteprofile','site','-1'],method:'POST'};
            SimSiteProfileData.addSiteAttributes(urlAction);
        }
   }

   function createRFProfile() {
       var tJsonArr = JSON.parse(JSON.stringify( WlanTemplate.RFProfile_Template));
       tJsonArr[0].value = DefaultConfig.RF_PROFILE;
       for(var k =0; k < tJsonArr.length; k++) {
           SimLokiDatabaseActions.insert( 'wlan', tJsonArr[k] );
       }
   }

    function createAAAvlanaclInfo() {
        var tJsonArr = JSON.parse(JSON.stringify(WlanTemplate.AAAvlanaclTemplate));
        for (var k = 0; k < tJsonArr.length; k++) {
            SimLokiDatabaseActions.insert('wlan', tJsonArr[k]);
        }
    }
    function createWlanNativeVlan() {
        var tJsonArr = JSON.parse(JSON.stringify(WlanTemplate.WlanNativeVlanTemplate));
        for (var k = 0; k < tJsonArr.length; k++) {
            SimLokiDatabaseActions.insert('wlan', tJsonArr[k]);
        }
    }
    function createApimpersonation() {
        var tJsonArr = JSON.parse(JSON.stringify(WlanTemplate.ApimpersonationTemplate));
        for (var k = 0; k < tJsonArr.length; k++) {
            SimLokiDatabaseActions.insert('wlan', tJsonArr[k]);
        }
    }
    function createDtlscipher() {
        var tJsonArr = JSON.parse(JSON.stringify(WlanTemplate.DtlscipherTemplate));
        for (var k = 0; k < tJsonArr.length; k++) {
            SimLokiDatabaseActions.insert('wlan', tJsonArr[k]);
        }
    }
    function createAwips() {
        var tJsonArr = JSON.parse(JSON.stringify(WlanTemplate.AwipsTemplate));
        for (var k = 0; k < tJsonArr.length; k++) {
            SimLokiDatabaseActions.insert('wlan', tJsonArr[k]);
        }
    }
    function createAPGroup() {
        var tJson = JSON.parse(JSON.stringify(WlanTemplate.WLAN_APGROUP_TEMPLATE));
        var siteRecord = SimLokiDatabaseActions.getFilteredRecordHandler('site', {'name':'Flr-SJC1-1'});
        tJson.value[0].siteHierarchy = siteRecord[0].groupNameHierarchy;
        tJson.groupUuid = siteRecord[0].id;
        SimLokiDatabaseActions.insert('wlan', tJson);
    }

   function boolToString(profileAttr) {
    for(var i=0; i<profileAttr.length; i++) {
        if (profileAttr[i].hasOwnProperty("value") && !isString(profileAttr[i]["value"])) {
            profileAttr[i]["value"] = profileAttr[i]["value"].toString();
        } else if (profileAttr[i]["attribs"] != undefined) {
            for(var j=0; j<profileAttr[i]["attribs"].length; j++) {
                var attrLevel1 = profileAttr[i]["attribs"][j];
                if (attrLevel1.hasOwnProperty("value") && !isString(attrLevel1["value"])) {
                    attrLevel1["value"] = attrLevel1["value"].toString();
                } else if (attrLevel1["attribs"] != undefined) {
                    for(var k=0; k<attrLevel1["attribs"].length; k++) {
                        if (attrLevel1["attribs"][k].hasOwnProperty("value") && !isString(attrLevel1["attribs"][k]["value"])) {
                            attrLevel1["attribs"][k]["value"] = attrLevel1["attribs"][k]["value"].toString();
                        }
                    }
                }
                profileAttr[i]["attribs"][j] = attrLevel1;
            }
        }
    }
    return profileAttr;
}

function isString(property) {
    if (typeof property != "string") {
        return false;
    }
    return true;
}

function updateWLCAtrribute() {

    var serviceName = 'site';
    var tObjs = SimLokiDatabaseActions.getAll(serviceName);
    if(tObjs && tObjs.length > 0) {
        for(var index = 0; index < tObjs.length; index++) {
            if(tObjs[index].additionalInfo && tObjs[index].additionalInfo.length > 0) {
                var flag =true;
                for(var secondIndex = 0; secondIndex < tObjs[index].additionalInfo.length; secondIndex++) {
                    if(tObjs[index].additionalInfo[secondIndex].nameSpace =="com.wireless.managingwlc") {
                      flag =false;
                    }
                }
                if(flag) {

                 var additionalInfoObj = {nameSpace:"com.wireless.managingwlc",attributes:{
                                                        "anchorWlcInheritedFrom": "33755632-e8d2-48d1-8a3d-63d392e99b14",
                                           "primaryWlcInheritedFrom": "33755632-e8d2-48d1-8a3d-63d392e99b14"}};
                  tObjs[index].additionalInfo.push(additionalInfoObj);
                  console.log(tObjs[index]);
                }

            }
        }
    }

}

    return {
        init: function() {
            createEnterpriseWlan();
            createAuthProfile();
            createRFProfile();
            createAAAvlanaclInfo();
            createWlanNativeVlan();
            createApimpersonation();
            createDtlscipher();
            createAwips();
            updateWLCAtrribute();
            createSwitchingProfile();
            createAPGroup();
        },

        wirelessSiteCUD: function(taskId, operation, jsonObj) {
            if (operation == "post") {
                var siteTemplate = JSON.parse(JSON.stringify(SiteTemplate.WLAN_SITE_TEMPLATE[0]));
                jsonObj.id = jsonObj.id;
                if(jsonObj.id==undefined || jsonObj.id.trim()=="") jsonObj.id = taskId;
                jsonObj.siteProfileUuid = jsonObj.id;
                jsonObj.lastUpdatedDatetime = UtilityFunctions.getTimeStamp();
                jsonObj.status = "draft";
                jsonObj.lastUpdatedBy = "admin";
                jsonObj.version = 1;
                SimLokiDatabaseActions.insert('siteprofile', jsonObj);
            } else if (operation == "put") {
            ///api/v1/siteprofile/ PUT call. It brings the  wireless_ssid details only. So take care already existing site_uuid is not removed. Update only the site.settings
                var recordObj = SimLokiDatabaseActions.getFilteredRecordHandler('siteprofile', {
                    'id': jsonObj.id
                });

                //switching profile
                if (recordObj && recordObj.length && recordObj[0].namespace == 'switching') {
                    recordObj[0].lastUpdatedDatetime = UtilityFunctions.getTimeStamp();
                    recordObj[0].profileAttributes = jsonObj.profileAttributes;
                    SimLokiDatabaseActions.update('siteprofile', recordObj);
                    return;
                }
                //wireless profile
                if (recordObj && recordObj.length && recordObj[0].namespace == 'wlan') {
                    jsonObj.lastUpdatedDatetime = UtilityFunctions.getTimeStamp();

                    var wireless_ssid="wireless.ssid";
                    try {
                        var profileObj,recordProfileObj;
                          for(secondIndex=0;secondIndex<recordObj[0].profileAttributes.length;secondIndex++)
                          {
                              recordProfileObj = recordObj[0].profileAttributes[secondIndex];
                               if( wireless_ssid== recordProfileObj.key)
                               {
                                 recordObj[0].profileAttributes.splice(secondIndex,1);
                               }
                          }
                          if(jsonObj.profileAttributes && jsonObj.profileAttributes.length>0)
                            {
                             for(index=0;index<jsonObj.profileAttributes.length;index++)
                             {
                               if(jsonObj.profileAttributes[index].key == wireless_ssid)
                               {
                                 var ssidAttribObj;
                                 for(var ssidIndex=0; ssidIndex <jsonObj.profileAttributes[index].attribs.length; ssidIndex++)
                                 {
                                    ssidAttribObj = jsonObj.profileAttributes[index].attribs[ssidIndex];

                                    ssidAttribObj.value = ssidAttribObj.value.toString();
                                 }
                               }
                               recordObj[0].profileAttributes.push(jsonObj.profileAttributes[index]);
                             }
                            }
                        SimLokiDatabaseActions.update('siteprofile', recordObj);
                    } catch (e) {
                        console.log(e);
                        console.log("delete siteprofile")
                        SimLokiDatabaseActions.delete('siteprofile', recordObj);
                        jsonObj.id = taskId;
                        jsonObj.siteProfileUuid = jsonObj.id;
                        jsonObj.lastUpdatedDatetime = UtilityFunctions.getTimeStamp();
                        jsonObj.status = "draft";
                        jsonObj.lastUpdatedBy = "admin";
                        jsonObj.version = 1;
                        console.log("insert siteprofile")
                        SimLokiDatabaseActions.insert('siteprofile', jsonObj);
                    }
                } else {
                    console.log("Record not found to update ID  = " + JSON.stringify(jsonObj.id));
                }
            } else if(operation  == "delete"){
                var recordObj = SimLokiDatabaseActions.getFilteredRecordHandler('siteprofile', {
                    'siteProfileUuid': jsonObj.id
                });

                if (recordObj && recordObj.length) {
                   if(jsonObj.siteId != undefined)
                   {
                    var childrenSites = [],childSiteIds=[];
                    SimulationUtility.getChildrenSites(jsonObj.siteId,childrenSites);
                    //Fetch only the SIteIds
                    for(var childSiteIndex in childrenSites)
                    {
                      var childSite = childrenSites[childSiteIndex];
                      childSiteIds.push(childSite.id);
                    }
                    childSiteIds.push(jsonObj.siteId);
                    //Remove the children sites entries from the siteprofile record.
                    for(var childSiteIndex in childSiteIds)
                    {
                        var childId = childSiteIds[childSiteIndex];
                        //If the delete request is to delete the site from the siteprofile, remove the site entry in sites obj and profileAttributes obj
                        for(var index=0;index<recordObj[0].sites.length;index++)
                        {
                            if(recordObj[0].sites[index].uuid == childId)
                            {
                                recordObj[0].sites.splice(index,1);
                                recordObj[0].siteCount=recordObj[0]['sites'].length;
                                if(recordObj[0].sites.length ==0)
                                {
                                    recordObj[0].sites = [];
                                    recordObj[0].sites = undefined;
                                }
                                break;
                            }
                         }
                    }
                   for(var index=0;index<recordObj[0].profileAttributes.length;index++)
                    {
                      if(recordObj[0].profileAttributes[index].key == 'site.settings')
                      {
                        for(var secondIndex=0;secondIndex<recordObj[0].profileAttributes[index].attribs.length;secondIndex++)
                        {
                         var attribObj = recordObj[0].profileAttributes[index].attribs[secondIndex];
                         if(attribObj.key == 'site.uuid' && attribObj.value == jsonObj.siteId){
                           recordObj[0].profileAttributes[index].attribs.splice(secondIndex,1);
                           SimLokiDatabaseActions.update('siteprofile', recordObj);
                           break;
                         }
                        }
                      }
                    }
                   }else
                    {
                      SimLokiDatabaseActions.delete('siteprofile', recordObj[0]);
                    }
                }
            }
        },

        wlanCUD: function(operation, serviceType, jsonObj) {
            if (operation == "post") {
                if(jsonObj.commonSettingsDtoMap && jsonObj.commonSettingsDtoMap.length>0){
                    let temp=[]
                    let v=jsonObj.commonSettingsDtoMap[0]
                    let x=Object.entries(v).forEach(f=> {
                        f.map(k=>{if(Array.isArray(k)){temp.push(k[0])}})
                    })
                    jsonObj=temp
                }
                for(var i=0;i<jsonObj.length;i++) {
                    var recordObj = SimLokiDatabaseActions.getFilteredRecordHandler(serviceType, {
                        'key': jsonObj[i].key
                    });
                    // Now separate record will be created
                    if (recordObj && recordObj.length) {
                        SimLokiDatabaseActions.delete('wlan', recordObj);
                    }
                    delete jsonObj[i]['$loki'];delete jsonObj[i]['meta'];//sometimes second req is also coming with DB attributes
                }
                SimLokiDatabaseActions.insert(serviceType, jsonObj);
            /**
             * Added to handle delete wireless in Design-> network settings -> Wireless
             */
            } else if(operation == "delete"){
                var recordObj = SimLokiDatabaseActions.getFilteredRecordHandler(serviceType, {
                    'key': jsonObj[3]
                });
                SimLokiDatabaseActions.delete('wlan', recordObj);
            }
        },

        NFVProfileCUD: function(taskId, operation, jsonObj) {
            if (operation == "post") {
                jsonObj.id = taskId;
                jsonObj.siteProfileUuid = jsonObj.id;
                jsonObj.lastUpdatedDatetime = UtilityFunctions.getTimeStamp();
                jsonObj.status = "final";
                jsonObj.lastUpdatedBy = "admin";
                jsonObj.version = 1;

                jsonObj.profileAttributes = boolToString(jsonObj.profileAttributes);

                SimLokiDatabaseActions.insert('siteprofile', jsonObj);
            } else if (operation == "put") {
                if(jsonObj.namespace == "nfv") {
                    var recordObj = SimLokiDatabaseActions.getFilteredRecordHandler('siteprofile', {
                        'id': jsonObj.siteProfileUuid
                    });
                    if (recordObj && recordObj.length) {
                        recordObj[0].lastUpdatedDatetime = UtilityFunctions.getTimeStamp();
                        jsonObj.profileAttributes = boolToString(jsonObj.profileAttributes);
                        recordObj[0].profileAttributes = jsonObj.profileAttributes;
                    }
                    SimLokiDatabaseActions.update('siteprofile', recordObj);
                    return;
                }
            }
          }
         ,
         //AP Wireless Configuration
        apWirelessCUD: function(apServiceType,operation,jsonObj){

            if (operation == "post") {

                  var record = SimLokiDatabaseActions.getFilteredRecordHandler(apServiceType,{'siteId': jsonObj.siteId});
                  if(record && record.length > 0)
                  {
                    record.apMacAddrList = jsonObj.apMacAddrList;
                    record.rfProfile = jsonObj.rfProfile;
                    SimLokiDatabaseActions.update(apServiceType, record);
                    return;
                  }
                 var tJson = JSON.parse(JSON.stringify(APWirelessConfigTemplate.AP_Wlan_Template));
                 var wlanId =  UtilityFunctions.generate_uuid();
                 tJson.apMacAddrList = jsonObj.apMacAddrList;
                 tJson.createTime =tJson.lastUpdateTime = UtilityFunctions.getTimeStamp();
                 tJson.id = tJson.namespace = wlanId;
                 tJson.rfProfile = jsonObj.rfProfile;
                 tJson.siteId = jsonObj.siteId;
                 var record = SimLokiDatabaseActions.getFilteredRecordHandler('site',{'id': tJson.siteId});
                 if(record && record.length >0)
                 {
                   tJson.name = record[0].name+"_"+ tJson.rfProfile+"_f28e7";
                 }
                 SimLokiDatabaseActions.insert(apServiceType, tJson);

                jsonObj.apMacAddrList.map(function(macId){
                    var deviceRecord = SimLokiDatabaseActions.getFilteredRecordHandler('network-device',{macAddress:macId});
                    SimNetworkDeviceData.createAccessPointForFloor(deviceRecord[0].id, tJson.siteId)
                })               
                SimNetworkDeviceData.updateDeviceConfigStatus(jsonObj);
             }
             else if (operation == "put"){
                  var record = SimLokiDatabaseActions.getFilteredRecordHandler(apServiceType,{'id': jsonObj.id});
                  if(record && record.length > 0)
                  {
                    record=jsonObj;
                    SimLokiDatabaseActions.update(apServiceType, record);
                  }
             }
        }

    };
});
