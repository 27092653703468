define({
  "Template":
    {
  "type": "apInterference",
  "records": [

  ],
  "values": [

  ]
}
});
