define({
  "Template": [
    {
      "testSuiteId": "5adf649d64825e0081e87d46",
      "testResultDetails": {
        "startTime": 1548913800000,
        "endTime": 1549000200000,
        "mostRecent": {
          "lastRun": 1527333900000,
          "testResults": [
            {
              "id": 1,
              "testType": "dhcp",
              "status": "fail"
            },
            {
              "id": 2,
              "testType": "onboarding",
              "status": "fail"
            },
            {
              "id": 3,
              "testType": "dns",
              "status": "fail"
            },
            {
              "id": 4,
              "testType": "webserver",
              "status": "pass"
            }
          ]
        },
        "detailResults": {
          "dhcp": {
            "detailList": {
              "interval1": {
                "intervalIndex": "interval1",
                "testStatus": "fail",
                "resultCountDetails": {
                  "passCount": 6,
                  "failureCount": 3,
                  "slowCount": 0
                }
              },
              "interval2": {
                "intervalIndex": "interval2",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 5,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval3": {
                "intervalIndex": "interval3",
                "testStatus": "fail",
                "resultCountDetails": {
                  "passCount": 2,
                  "failureCount": 3,
                  "slowCount": 0
                }
              },
              "interval4": {
                "intervalIndex": "interval4",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 6,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval5": {
                "intervalIndex": "interval5",
                "testStatus": "fail",
                "resultCountDetails": {
                  "passCount": 6,
                  "failureCount": 2,
                  "slowCount": 0
                }
              },
              "interval6": {
                "intervalIndex": "interval6",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 4,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval7": {
                "intervalIndex": "interval7",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 1,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval8": {
                "intervalIndex": "interval8",
                "testStatus": "fail",
                "resultCountDetails": {
                  "passCount": 2,
                  "failureCount": 5,
                  "slowCount": 0
                }
              },
              "interval9": {
                "intervalIndex": "interval9",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 7,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval10": {
                "intervalIndex": "interval10",
                "testStatus": "fail",
                "resultCountDetails": {
                  "passCount": 6,
                  "failureCount": 4,
                  "slowCount": 0
                }
              },
              "interval11": {
                "intervalIndex": "interval11",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 8,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval12": {
                "intervalIndex": "interval12",
                "testStatus": "fail",
                "resultCountDetails": {
                  "passCount": 5,
                  "failureCount": 1,
                  "slowCount": 0
                }
              },
              "interval13": {
                "intervalIndex": "interval13",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 4,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval14": {
                "intervalIndex": "interval14",
                "testStatus": "fail",
                "resultCountDetails": {
                  "passCount": 7,
                  "failureCount": 2,
                  "slowCount": 0
                }
              },
              "interval15": {
                "intervalIndex": "interval15",
                "testStatus": "fail",
                "resultCountDetails": {
                  "passCount": 8,
                  "failureCount": 5,
                  "slowCount": 0
                }
              },
              "interval16": {
                "intervalIndex": "interval16",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 7,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval17": {
                "intervalIndex": "interval17",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 2,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval18": {
                "intervalIndex": "interval18",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 8,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval19": {
                "intervalIndex": "interval19",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 5,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval20": {
                "intervalIndex": "interval20",
                "testStatus": "fail",
                "resultCountDetails": {
                  "passCount": 2,
                  "failureCount": 2,
                  "slowCount": 0
                }
              },
              "interval21": {
                "intervalIndex": "interval21",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 5,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval22": {
                "intervalIndex": "interval22",
                "testStatus": "fail",
                "resultCountDetails": {
                  "passCount": 6,
                  "failureCount": 4,
                  "slowCount": 0
                }
              },
              "interval23": {
                "intervalIndex": "interval23",
                "testStatus": "fail",
                "resultCountDetails": {
                  "passCount": 3,
                  "failureCount": 3,
                  "slowCount": 0
                }
              },
              "interval24": {
                "intervalIndex": "interval24",
                "testStatus": "fail",
                "resultCountDetails": {
                  "passCount": 3,
                  "failureCount": 3,
                  "slowCount": 0
                }
              }
            }
          },
          "onboarding": {
            "detailList": {
              "interval1": {
                "intervalIndex": "interval1",
                "testStatus": "fail",
                "resultCountDetails": {
                  "passCount": 2,
                  "failureCount": 5,
                  "slowCount": 0
                }
              },
              "interval2": {
                "intervalIndex": "interval2",
                "testStatus": "slow",
                "resultCountDetails": {
                  "passCount": 7,
                  "failureCount": 0,
                  "slowCount": 2
                }
              },
              "interval3": {
                "intervalIndex": "interval3",
                "testStatus": "fail",
                "resultCountDetails": {
                  "passCount": 5,
                  "failureCount": 4,
                  "slowCount": 0
                }
              },
              "interval4": {
                "intervalIndex": "interval4",
                "testStatus": "slow",
                "resultCountDetails": {
                  "passCount": 2,
                  "failureCount": 0,
                  "slowCount": 2
                }
              },
              "interval5": {
                "intervalIndex": "interval5",
                "testStatus": "fail",
                "resultCountDetails": {
                  "passCount": 7,
                  "failureCount": 4,
                  "slowCount": 0
                }
              },
              "interval6": {
                "intervalIndex": "interval6",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 4,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval7": {
                "intervalIndex": "interval7",
                "testStatus": "slow",
                "resultCountDetails": {
                  "passCount": 3,
                  "failureCount": 0,
                  "slowCount": 2
                }
              },
              "interval8": {
                "intervalIndex": "interval8",
                "testStatus": "fail",
                "resultCountDetails": {
                  "passCount": 5,
                  "failureCount": 3,
                  "slowCount": 0
                }
              },
              "interval9": {
                "intervalIndex": "interval9",
                "testStatus": "slow",
                "resultCountDetails": {
                  "passCount": 5,
                  "failureCount": 0,
                  "slowCount": 4
                }
              },
              "interval10": {
                "intervalIndex": "interval10",
                "testStatus": "fail",
                "resultCountDetails": {
                  "passCount": 8,
                  "failureCount": 2,
                  "slowCount": 0
                }
              },
              "interval11": {
                "intervalIndex": "interval11",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 4,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval12": {
                "intervalIndex": "interval12",
                "testStatus": "fail",
                "resultCountDetails": {
                  "passCount": 7,
                  "failureCount": 5,
                  "slowCount": 0
                }
              },
              "interval13": {
                "intervalIndex": "interval13",
                "testStatus": "slow",
                "resultCountDetails": {
                  "passCount": 8,
                  "failureCount": 0,
                  "slowCount": 4
                }
              },
              "interval14": {
                "intervalIndex": "interval14",
                "testStatus": "fail",
                "resultCountDetails": {
                  "passCount": 6,
                  "failureCount": 4,
                  "slowCount": 0
                }
              },
              "interval15": {
                "intervalIndex": "interval15",
                "testStatus": "fail",
                "resultCountDetails": {
                  "passCount": 7,
                  "failureCount": 4,
                  "slowCount": 0
                }
              },
              "interval16": {
                "intervalIndex": "interval16",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 4,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval17": {
                "intervalIndex": "interval17",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 3,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval18": {
                "intervalIndex": "interval18",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 8,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval19": {
                "intervalIndex": "interval19",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 7,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval20": {
                "intervalIndex": "interval20",
                "testStatus": "fail",
                "resultCountDetails": {
                  "passCount": 5,
                  "failureCount": 1,
                  "slowCount": 0
                }
              },
              "interval21": {
                "intervalIndex": "interval21",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 3,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval22": {
                "intervalIndex": "interval22",
                "testStatus": "fail",
                "resultCountDetails": {
                  "passCount": 8,
                  "failureCount": 5,
                  "slowCount": 0
                }
              },
              "interval23": {
                "intervalIndex": "interval23",
                "testStatus": "fail",
                "resultCountDetails": {
                  "passCount": 5,
                  "failureCount": 5,
                  "slowCount": 0
                }
              },
              "interval24": {
                "intervalIndex": "interval24",
                "testStatus": "fail",
                "resultCountDetails": {
                  "passCount": 6,
                  "failureCount": 3,
                  "slowCount": 0
                }
              }
            }
          },
          "dns": {
            "detailList": {
              "interval1": {
                "intervalIndex": "interval1",
                "testStatus": "fail",
                "resultCountDetails": {
                  "passCount": 4,
                  "failureCount": 5,
                  "slowCount": 0
                }
              },
              "interval2": {
                "intervalIndex": "interval2",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 7,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval3": {
                "intervalIndex": "interval3",
                "testStatus": "fail",
                "resultCountDetails": {
                  "passCount": 7,
                  "failureCount": 1,
                  "slowCount": 0
                }
              },
              "interval4": {
                "intervalIndex": "interval4",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 5,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval5": {
                "intervalIndex": "interval5",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 3,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval6": {
                "intervalIndex": "interval6",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 4,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval7": {
                "intervalIndex": "interval7",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 4,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval8": {
                "intervalIndex": "interval8",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 5,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval9": {
                "intervalIndex": "interval9",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 2,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval10": {
                "intervalIndex": "interval10",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 1,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval11": {
                "intervalIndex": "interval11",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 7,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval12": {
                "intervalIndex": "interval12",
                "testStatus": "fail",
                "resultCountDetails": {
                  "passCount": 8,
                  "failureCount": 1,
                  "slowCount": 0
                }
              },
              "interval13": {
                "intervalIndex": "interval13",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 8,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval14": {
                "intervalIndex": "interval14",
                "testStatus": "fail",
                "resultCountDetails": {
                  "passCount": 7,
                  "failureCount": 1,
                  "slowCount": 0
                }
              },
              "interval15": {
                "intervalIndex": "interval15",
                "testStatus": "fail",
                "resultCountDetails": {
                  "passCount": 5,
                  "failureCount": 5,
                  "slowCount": 0
                }
              },
              "interval16": {
                "intervalIndex": "interval16",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 5,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval17": {
                "intervalIndex": "interval17",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 8,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval18": {
                "intervalIndex": "interval18",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 4,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval19": {
                "intervalIndex": "interval19",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 3,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval20": {
                "intervalIndex": "interval20",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 7,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval21": {
                "intervalIndex": "interval21",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 6,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval22": {
                "intervalIndex": "interval22",
                "testStatus": "fail",
                "resultCountDetails": {
                  "passCount": 6,
                  "failureCount": 1,
                  "slowCount": 0
                }
              },
              "interval23": {
                "intervalIndex": "interval23",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 7,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval24": {
                "intervalIndex": "interval24",
                "testStatus": "fail",
                "resultCountDetails": {
                  "passCount": 8,
                  "failureCount": 4,
                  "slowCount": 0
                }
              }
            }
          },
          "webserver": {
            "detailList": {
              "interval1": {
                "intervalIndex": "interval1",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 6,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval2": {
                "intervalIndex": "interval2",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 1,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval3": {
                "intervalIndex": "interval3",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 6,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval4": {
                "intervalIndex": "interval4",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 6,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval5": {
                "intervalIndex": "interval5",
                "testStatus": "fail",
                "resultCountDetails": {
                  "passCount": 5,
                  "failureCount": 5,
                  "slowCount": 0
                }
              },
              "interval6": {
                "intervalIndex": "interval6",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 5,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval7": {
                "intervalIndex": "interval7",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 3,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval8": {
                "intervalIndex": "interval8",
                "testStatus": "fail",
                "resultCountDetails": {
                  "passCount": 8,
                  "failureCount": 5,
                  "slowCount": 0
                }
              },
              "interval9": {
                "intervalIndex": "interval9",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 5,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval10": {
                "intervalIndex": "interval10",
                "testStatus": "fail",
                "resultCountDetails": {
                  "passCount": 8,
                  "failureCount": 5,
                  "slowCount": 0
                }
              },
              "interval11": {
                "intervalIndex": "interval11",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 3,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval12": {
                "intervalIndex": "interval12",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 4,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval13": {
                "intervalIndex": "interval13",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 6,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval14": {
                "intervalIndex": "interval14",
                "testStatus": "fail",
                "resultCountDetails": {
                  "passCount": 2,
                  "failureCount": 2,
                  "slowCount": 0
                }
              },
              "interval15": {
                "intervalIndex": "interval15",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 1,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval16": {
                "intervalIndex": "interval16",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 2,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval17": {
                "intervalIndex": "interval17",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 7,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval18": {
                "intervalIndex": "interval18",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 2,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval19": {
                "intervalIndex": "interval19",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 2,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval20": {
                "intervalIndex": "interval20",
                "testStatus": "fail",
                "resultCountDetails": {
                  "passCount": 7,
                  "failureCount": 3,
                  "slowCount": 0
                }
              },
              "interval21": {
                "intervalIndex": "interval21",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 8,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval22": {
                "intervalIndex": "interval22",
                "testStatus": "fail",
                "resultCountDetails": {
                  "passCount": 7,
                  "failureCount": 4,
                  "slowCount": 0
                }
              },
              "interval23": {
                "intervalIndex": "interval23",
                "testStatus": "fail",
                "resultCountDetails": {
                  "passCount": 4,
                  "failureCount": 2,
                  "slowCount": 0
                }
              }
            }
          }
        }
      }
    },
    {
      "testSuiteId": "5ad7498764825e0020f152a1",
      "testResultDetails": {
        "startTime": 1548913800000,
        "endTime": 1549000200000,
        "mostRecent": {
          "lastRun": 1527333900000,
          "testResults": [
            {
              "id": 1,
              "testType": "mailserver",
              "status": "fail"
            },
            {
              "id": 2,
              "testType": "radius",
              "status": "pass"
            },
            {
              "id": 3,
              "testType": "ping",
              "status": "pass"
            },
            {
              "id": 4,
              "testType": "dhcp",
              "status": "pass"
            },
            {
              "id": 5,
              "testType": "onboarding",
              "status": "pass"
            },
            {
              "id": 6,
              "testType": "dns",
              "status": "pass"
            },
            {
              "id": 7,
              "testType": "webserver",
              "status": "pass"
            },
            {
              "id": 8,
              "testType": "filetransfer",
              "status": "pass"
            }
          ]
        },
        "detailResults": {
          "mailserver": {
            "detailList": {
              "interval1": {
                "intervalIndex": "interval1",
                "testStatus": "fail",
                "resultCountDetails": {
                  "passCount": 0,
                  "failureCount": 2,
                  "slowCount": 0
                }
              },
              "interval2": {
                "intervalIndex": "interval2",
                "testStatus": "fail",
                "resultCountDetails": {
                  "passCount": 0,
                  "failureCount": 4,
                  "slowCount": 0
                }
              },
              "interval3": {
                "intervalIndex": "interval3",
                "testStatus": "fail",
                "resultCountDetails": {
                  "passCount": 0,
                  "failureCount": 5,
                  "slowCount": 0
                }
              },
              "interval4": {
                "intervalIndex": "interval4",
                "testStatus": "fail",
                "resultCountDetails": {
                  "passCount": 0,
                  "failureCount": 3,
                  "slowCount": 0
                }
              },
              "interval5": {
                "intervalIndex": "interval5",
                "testStatus": "fail",
                "resultCountDetails": {
                  "passCount": 0,
                  "failureCount": 3,
                  "slowCount": 0
                }
              },
              "interval6": {
                "intervalIndex": "interval6",
                "testStatus": "fail",
                "resultCountDetails": {
                  "passCount": 0,
                  "failureCount": 3,
                  "slowCount": 0
                }
              },
              "interval7": {
                "intervalIndex": "interval7",
                "testStatus": "fail",
                "resultCountDetails": {
                  "passCount": 0,
                  "failureCount": 3,
                  "slowCount": 0
                }
              },
              "interval8": {
                "intervalIndex": "interval8",
                "testStatus": "fail",
                "resultCountDetails": {
                  "passCount": 0,
                  "failureCount": 1,
                  "slowCount": 0
                }
              },
              "interval9": {
                "intervalIndex": "interval9",
                "testStatus": "fail",
                "resultCountDetails": {
                  "passCount": 0,
                  "failureCount": 2,
                  "slowCount": 0
                }
              },
              "interval10": {
                "intervalIndex": "interval10",
                "testStatus": "fail",
                "resultCountDetails": {
                  "passCount": 0,
                  "failureCount": 5,
                  "slowCount": 0
                }
              },
              "interval11": {
                "intervalIndex": "interval11",
                "testStatus": "fail",
                "resultCountDetails": {
                  "passCount": 0,
                  "failureCount": 3,
                  "slowCount": 0
                }
              },
              "interval12": {
                "intervalIndex": "interval12",
                "testStatus": "fail",
                "resultCountDetails": {
                  "passCount": 0,
                  "failureCount": 3,
                  "slowCount": 0
                }
              },
              "interval13": {
                "intervalIndex": "interval13",
                "testStatus": "fail",
                "resultCountDetails": {
                  "passCount": 0,
                  "failureCount": 1,
                  "slowCount": 0
                }
              },
              "interval14": {
                "intervalIndex": "interval14",
                "testStatus": "fail",
                "resultCountDetails": {
                  "passCount": 0,
                  "failureCount": 1,
                  "slowCount": 0
                }
              },
              "interval15": {
                "intervalIndex": "interval15",
                "testStatus": "fail",
                "resultCountDetails": {
                  "passCount": 0,
                  "failureCount": 4,
                  "slowCount": 0
                }
              },
              "interval16": {
                "intervalIndex": "interval16",
                "testStatus": "fail",
                "resultCountDetails": {
                  "passCount": 0,
                  "failureCount": 3,
                  "slowCount": 0
                }
              },
              "interval17": {
                "intervalIndex": "interval17",
                "testStatus": "fail",
                "resultCountDetails": {
                  "passCount": 0,
                  "failureCount": 5,
                  "slowCount": 0
                }
              },
              "interval18": {
                "intervalIndex": "interval18",
                "testStatus": "fail",
                "resultCountDetails": {
                  "passCount": 0,
                  "failureCount": 3,
                  "slowCount": 0
                }
              },
              "interval19": {
                "intervalIndex": "interval19",
                "testStatus": "fail",
                "resultCountDetails": {
                  "passCount": 0,
                  "failureCount": 5,
                  "slowCount": 0
                }
              },
              "interval20": {
                "intervalIndex": "interval20",
                "testStatus": "fail",
                "resultCountDetails": {
                  "passCount": 0,
                  "failureCount": 1,
                  "slowCount": 0
                }
              },
              "interval21": {
                "intervalIndex": "interval21",
                "testStatus": "fail",
                "resultCountDetails": {
                  "passCount": 0,
                  "failureCount": 1,
                  "slowCount": 0
                }
              },
              "interval22": {
                "intervalIndex": "interval22",
                "testStatus": "fail",
                "resultCountDetails": {
                  "passCount": 0,
                  "failureCount": 1,
                  "slowCount": 0
                }
              },
              "interval23": {
                "intervalIndex": "interval23",
                "testStatus": "fail",
                "resultCountDetails": {
                  "passCount": 0,
                  "failureCount": 3,
                  "slowCount": 0
                }
              },
              "interval24": {
                "intervalIndex": "interval24",
                "testStatus": "fail",
                "resultCountDetails": {
                  "passCount": 0,
                  "failureCount": 3,
                  "slowCount": 0
                }
              },
              "interval25": {
                "intervalIndex": "interval25",
                "testStatus": "fail",
                "resultCountDetails": {
                  "passCount": 0,
                  "failureCount": 4,
                  "slowCount": 0
                }
              },
              "interval26": {
                "intervalIndex": "interval26",
                "testStatus": "fail",
                "resultCountDetails": {
                  "passCount": 0,
                  "failureCount": 2,
                  "slowCount": 0
                }
              },
              "interval27": {
                "intervalIndex": "interval27",
                "testStatus": "fail",
                "resultCountDetails": {
                  "passCount": 0,
                  "failureCount": 4,
                  "slowCount": 0
                }
              },
              "interval28": {
                "intervalIndex": "interval28",
                "testStatus": "fail",
                "resultCountDetails": {
                  "passCount": 0,
                  "failureCount": 3,
                  "slowCount": 0
                }
              },
              "interval29": {
                "intervalIndex": "interval29",
                "testStatus": "fail",
                "resultCountDetails": {
                  "passCount": 0,
                  "failureCount": 1,
                  "slowCount": 0
                }
              },
              "interval30": {
                "intervalIndex": "interval30",
                "testStatus": "fail",
                "resultCountDetails": {
                  "passCount": 0,
                  "failureCount": 1,
                  "slowCount": 0
                }
              },
              "interval31": {
                "intervalIndex": "interval31",
                "testStatus": "fail",
                "resultCountDetails": {
                  "passCount": 0,
                  "failureCount": 5,
                  "slowCount": 0
                }
              },
              "interval32": {
                "intervalIndex": "interval32",
                "testStatus": "fail",
                "resultCountDetails": {
                  "passCount": 0,
                  "failureCount": 2,
                  "slowCount": 0
                }
              },
              "interval33": {
                "intervalIndex": "interval33",
                "testStatus": "fail",
                "resultCountDetails": {
                  "passCount": 0,
                  "failureCount": 5,
                  "slowCount": 0
                }
              },
              "interval34": {
                "intervalIndex": "interval34",
                "testStatus": "fail",
                "resultCountDetails": {
                  "passCount": 0,
                  "failureCount": 3,
                  "slowCount": 0
                }
              },
              "interval35": {
                "intervalIndex": "interval35",
                "testStatus": "fail",
                "resultCountDetails": {
                  "passCount": 0,
                  "failureCount": 5,
                  "slowCount": 0
                }
              },
              "interval36": {
                "intervalIndex": "interval36",
                "testStatus": "fail",
                "resultCountDetails": {
                  "passCount": 0,
                  "failureCount": 2,
                  "slowCount": 0
                }
              },
              "interval37": {
                "intervalIndex": "interval37",
                "testStatus": "fail",
                "resultCountDetails": {
                  "passCount": 0,
                  "failureCount": 5,
                  "slowCount": 0
                }
              },
              "interval38": {
                "intervalIndex": "interval38",
                "testStatus": "fail",
                "resultCountDetails": {
                  "passCount": 0,
                  "failureCount": 5,
                  "slowCount": 0
                }
              },
              "interval39": {
                "intervalIndex": "interval39",
                "testStatus": "fail",
                "resultCountDetails": {
                  "passCount": 0,
                  "failureCount": 3,
                  "slowCount": 0
                }
              },
              "interval40": {
                "intervalIndex": "interval40",
                "testStatus": "fail",
                "resultCountDetails": {
                  "passCount": 0,
                  "failureCount": 1,
                  "slowCount": 0
                }
              },
              "interval41": {
                "intervalIndex": "interval41",
                "testStatus": "fail",
                "resultCountDetails": {
                  "passCount": 0,
                  "failureCount": 5,
                  "slowCount": 0
                }
              },
              "interval42": {
                "intervalIndex": "interval42",
                "testStatus": "fail",
                "resultCountDetails": {
                  "passCount": 0,
                  "failureCount": 3,
                  "slowCount": 0
                }
              },
              "interval43": {
                "intervalIndex": "interval43",
                "testStatus": "fail",
                "resultCountDetails": {
                  "passCount": 0,
                  "failureCount": 2,
                  "slowCount": 0
                }
              },
              "interval44": {
                "intervalIndex": "interval44",
                "testStatus": "fail",
                "resultCountDetails": {
                  "passCount": 0,
                  "failureCount": 5,
                  "slowCount": 0
                }
              },
              "interval45": {
                "intervalIndex": "interval45",
                "testStatus": "fail",
                "resultCountDetails": {
                  "passCount": 0,
                  "failureCount": 1,
                  "slowCount": 0
                }
              },
              "interval46": {
                "intervalIndex": "interval46",
                "testStatus": "fail",
                "resultCountDetails": {
                  "passCount": 0,
                  "failureCount": 4,
                  "slowCount": 0
                }
              },
              "interval47": {
                "intervalIndex": "interval47",
                "testStatus": "fail",
                "resultCountDetails": {
                  "passCount": 0,
                  "failureCount": 3,
                  "slowCount": 0
                }
              },
              "interval48": {
                "intervalIndex": "interval48",
                "testStatus": "fail",
                "resultCountDetails": {
                  "passCount": 0,
                  "failureCount": 5,
                  "slowCount": 0
                }
              }
            }
          },
          "radius": {
            "detailList": {
              "interval1": {
                "intervalIndex": "interval1",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 4,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval2": {
                "intervalIndex": "interval2",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 1,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval3": {
                "intervalIndex": "interval3",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 7,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval4": {
                "intervalIndex": "interval4",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 2,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval5": {
                "intervalIndex": "interval5",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 3,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval6": {
                "intervalIndex": "interval6",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 4,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval7": {
                "intervalIndex": "interval7",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 6,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval8": {
                "intervalIndex": "interval8",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 5,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval9": {
                "intervalIndex": "interval9",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 6,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval10": {
                "intervalIndex": "interval10",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 7,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval11": {
                "intervalIndex": "interval11",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 4,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval12": {
                "intervalIndex": "interval12",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 7,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval13": {
                "intervalIndex": "interval13",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 5,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval14": {
                "intervalIndex": "interval14",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 2,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval15": {
                "intervalIndex": "interval15",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 5,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval16": {
                "intervalIndex": "interval16",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 3,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval17": {
                "intervalIndex": "interval17",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 1,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval18": {
                "intervalIndex": "interval18",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 5,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval19": {
                "intervalIndex": "interval19",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 2,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval20": {
                "intervalIndex": "interval20",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 7,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval21": {
                "intervalIndex": "interval21",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 1,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval22": {
                "intervalIndex": "interval22",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 4,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval23": {
                "intervalIndex": "interval23",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 5,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval24": {
                "intervalIndex": "interval24",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 4,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval25": {
                "intervalIndex": "interval25",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 4,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval26": {
                "intervalIndex": "interval26",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 1,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval27": {
                "intervalIndex": "interval27",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 7,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval28": {
                "intervalIndex": "interval28",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 5,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval29": {
                "intervalIndex": "interval29",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 7,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval30": {
                "intervalIndex": "interval30",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 7,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval31": {
                "intervalIndex": "interval31",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 3,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval32": {
                "intervalIndex": "interval32",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 1,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval33": {
                "intervalIndex": "interval33",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 2,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval34": {
                "intervalIndex": "interval34",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 4,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval35": {
                "intervalIndex": "interval35",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 4,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval36": {
                "intervalIndex": "interval36",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 5,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval37": {
                "intervalIndex": "interval37",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 4,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval38": {
                "intervalIndex": "interval38",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 1,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval39": {
                "intervalIndex": "interval39",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 2,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval40": {
                "intervalIndex": "interval40",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 4,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval41": {
                "intervalIndex": "interval41",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 2,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval42": {
                "intervalIndex": "interval42",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 7,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval43": {
                "intervalIndex": "interval43",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 1,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval44": {
                "intervalIndex": "interval44",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 1,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval45": {
                "intervalIndex": "interval45",
                "testStatus": "fail",
                "resultCountDetails": {
                  "passCount": 7,
                  "failureCount": 2,
                  "slowCount": 0
                }
              },
              "interval46": {
                "intervalIndex": "interval46",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 5,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval47": {
                "intervalIndex": "interval47",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 3,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval48": {
                "intervalIndex": "interval48",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 3,
                  "failureCount": 0,
                  "slowCount": 0
                }
              }
            }
          },
          "ping": {
            "detailList": {
              "interval1": {
                "intervalIndex": "interval1",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 3,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval2": {
                "intervalIndex": "interval2",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 2,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval3": {
                "intervalIndex": "interval3",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 5,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval4": {
                "intervalIndex": "interval4",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 6,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval5": {
                "intervalIndex": "interval5",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 6,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval6": {
                "intervalIndex": "interval6",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 3,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval7": {
                "intervalIndex": "interval7",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 8,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval8": {
                "intervalIndex": "interval8",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 7,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval9": {
                "intervalIndex": "interval9",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 4,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval10": {
                "intervalIndex": "interval10",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 5,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval11": {
                "intervalIndex": "interval11",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 2,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval12": {
                "intervalIndex": "interval12",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 3,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval13": {
                "intervalIndex": "interval13",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 1,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval14": {
                "intervalIndex": "interval14",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 5,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval15": {
                "intervalIndex": "interval15",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 6,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval16": {
                "intervalIndex": "interval16",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 6,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval17": {
                "intervalIndex": "interval17",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 6,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval18": {
                "intervalIndex": "interval18",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 6,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval19": {
                "intervalIndex": "interval19",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 1,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval20": {
                "intervalIndex": "interval20",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 5,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval21": {
                "intervalIndex": "interval21",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 2,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval22": {
                "intervalIndex": "interval22",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 1,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval23": {
                "intervalIndex": "interval23",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 5,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval24": {
                "intervalIndex": "interval24",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 1,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval25": {
                "intervalIndex": "interval25",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 2,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval26": {
                "intervalIndex": "interval26",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 5,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval27": {
                "intervalIndex": "interval27",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 2,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval28": {
                "intervalIndex": "interval28",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 4,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval29": {
                "intervalIndex": "interval29",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 4,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval30": {
                "intervalIndex": "interval30",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 3,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval31": {
                "intervalIndex": "interval31",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 5,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval32": {
                "intervalIndex": "interval32",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 3,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval33": {
                "intervalIndex": "interval33",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 6,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval34": {
                "intervalIndex": "interval34",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 5,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval35": {
                "intervalIndex": "interval35",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 2,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval36": {
                "intervalIndex": "interval36",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 2,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval37": {
                "intervalIndex": "interval37",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 3,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval38": {
                "intervalIndex": "interval38",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 7,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval39": {
                "intervalIndex": "interval39",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 1,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval40": {
                "intervalIndex": "interval40",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 1,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval41": {
                "intervalIndex": "interval41",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 3,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval42": {
                "intervalIndex": "interval42",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 4,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval43": {
                "intervalIndex": "interval43",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 7,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval44": {
                "intervalIndex": "interval44",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 1,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval45": {
                "intervalIndex": "interval45",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 4,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval46": {
                "intervalIndex": "interval46",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 8,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval47": {
                "intervalIndex": "interval47",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 3,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval48": {
                "intervalIndex": "interval48",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 5,
                  "failureCount": 0,
                  "slowCount": 0
                }
              }
            }
          },
          "dhcp": {
            "detailList": {
              "interval1": {
                "intervalIndex": "interval1",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 2,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval2": {
                "intervalIndex": "interval2",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 6,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval3": {
                "intervalIndex": "interval3",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 5,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval4": {
                "intervalIndex": "interval4",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 3,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval5": {
                "intervalIndex": "interval5",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 6,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval6": {
                "intervalIndex": "interval6",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 7,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval7": {
                "intervalIndex": "interval7",
                "testStatus": "fail",
                "resultCountDetails": {
                  "passCount": 4,
                  "failureCount": 4,
                  "slowCount": 0
                }
              },
              "interval8": {
                "intervalIndex": "interval8",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 7,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval9": {
                "intervalIndex": "interval9",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 1,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval10": {
                "intervalIndex": "interval10",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 6,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval11": {
                "intervalIndex": "interval11",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 4,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval12": {
                "intervalIndex": "interval12",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 8,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval13": {
                "intervalIndex": "interval13",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 3,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval14": {
                "intervalIndex": "interval14",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 4,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval15": {
                "intervalIndex": "interval15",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 3,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval16": {
                "intervalIndex": "interval16",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 7,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval17": {
                "intervalIndex": "interval17",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 4,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval18": {
                "intervalIndex": "interval18",
                "testStatus": "fail",
                "resultCountDetails": {
                  "passCount": 6,
                  "failureCount": 2,
                  "slowCount": 0
                }
              },
              "interval19": {
                "intervalIndex": "interval19",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 2,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval20": {
                "intervalIndex": "interval20",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 7,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval21": {
                "intervalIndex": "interval21",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 3,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval22": {
                "intervalIndex": "interval22",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 7,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval23": {
                "intervalIndex": "interval23",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 5,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval24": {
                "intervalIndex": "interval24",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 8,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval25": {
                "intervalIndex": "interval25",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 3,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval26": {
                "intervalIndex": "interval26",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 8,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval27": {
                "intervalIndex": "interval27",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 1,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval28": {
                "intervalIndex": "interval28",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 5,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval29": {
                "intervalIndex": "interval29",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 5,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval30": {
                "intervalIndex": "interval30",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 8,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval31": {
                "intervalIndex": "interval31",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 7,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval32": {
                "intervalIndex": "interval32",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 8,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval33": {
                "intervalIndex": "interval33",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 5,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval34": {
                "intervalIndex": "interval34",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 2,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval35": {
                "intervalIndex": "interval35",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 4,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval36": {
                "intervalIndex": "interval36",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 4,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval37": {
                "intervalIndex": "interval37",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 7,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval38": {
                "intervalIndex": "interval38",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 3,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval39": {
                "intervalIndex": "interval39",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 7,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval40": {
                "intervalIndex": "interval40",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 5,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval41": {
                "intervalIndex": "interval41",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 5,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval42": {
                "intervalIndex": "interval42",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 4,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval43": {
                "intervalIndex": "interval43",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 6,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval44": {
                "intervalIndex": "interval44",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 3,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval45": {
                "intervalIndex": "interval45",
                "testStatus": "fail",
                "resultCountDetails": {
                  "passCount": 5,
                  "failureCount": 2,
                  "slowCount": 0
                }
              },
              "interval46": {
                "intervalIndex": "interval46",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 5,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval47": {
                "intervalIndex": "interval47",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 4,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval48": {
                "intervalIndex": "interval48",
                "testStatus": "fail",
                "resultCountDetails": {
                  "passCount": 4,
                  "failureCount": 4,
                  "slowCount": 0
                }
              }
            }
          },
          "onboarding": {
            "detailList": {
              "interval1": {
                "intervalIndex": "interval1",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 7,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval2": {
                "intervalIndex": "interval2",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 8,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval3": {
                "intervalIndex": "interval3",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 5,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval4": {
                "intervalIndex": "interval4",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 7,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval5": {
                "intervalIndex": "interval5",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 6,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval6": {
                "intervalIndex": "interval6",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 8,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval7": {
                "intervalIndex": "interval7",
                "testStatus": "fail",
                "resultCountDetails": {
                  "passCount": 3,
                  "failureCount": 3,
                  "slowCount": 0
                }
              },
              "interval8": {
                "intervalIndex": "interval8",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 8,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval9": {
                "intervalIndex": "interval9",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 6,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval10": {
                "intervalIndex": "interval10",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 2,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval11": {
                "intervalIndex": "interval11",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 8,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval12": {
                "intervalIndex": "interval12",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 1,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval13": {
                "intervalIndex": "interval13",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 5,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval14": {
                "intervalIndex": "interval14",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 7,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval15": {
                "intervalIndex": "interval15",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 1,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval16": {
                "intervalIndex": "interval16",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 7,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval17": {
                "intervalIndex": "interval17",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 4,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval18": {
                "intervalIndex": "interval18",
                "testStatus": "fail",
                "resultCountDetails": {
                  "passCount": 5,
                  "failureCount": 3,
                  "slowCount": 0
                }
              },
              "interval19": {
                "intervalIndex": "interval19",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 5,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval20": {
                "intervalIndex": "interval20",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 3,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval21": {
                "intervalIndex": "interval21",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 4,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval22": {
                "intervalIndex": "interval22",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 5,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval23": {
                "intervalIndex": "interval23",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 2,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval24": {
                "intervalIndex": "interval24",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 7,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval25": {
                "intervalIndex": "interval25",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 3,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval26": {
                "intervalIndex": "interval26",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 6,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval27": {
                "intervalIndex": "interval27",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 7,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval28": {
                "intervalIndex": "interval28",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 3,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval29": {
                "intervalIndex": "interval29",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 5,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval30": {
                "intervalIndex": "interval30",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 8,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval31": {
                "intervalIndex": "interval31",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 3,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval32": {
                "intervalIndex": "interval32",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 3,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval33": {
                "intervalIndex": "interval33",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 8,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval34": {
                "intervalIndex": "interval34",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 3,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval35": {
                "intervalIndex": "interval35",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 5,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval36": {
                "intervalIndex": "interval36",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 5,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval37": {
                "intervalIndex": "interval37",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 4,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval38": {
                "intervalIndex": "interval38",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 4,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval39": {
                "intervalIndex": "interval39",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 3,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval40": {
                "intervalIndex": "interval40",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 6,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval41": {
                "intervalIndex": "interval41",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 8,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval42": {
                "intervalIndex": "interval42",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 4,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval43": {
                "intervalIndex": "interval43",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 3,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval44": {
                "intervalIndex": "interval44",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 4,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval45": {
                "intervalIndex": "interval45",
                "testStatus": "fail",
                "resultCountDetails": {
                  "passCount": 8,
                  "failureCount": 3,
                  "slowCount": 0
                }
              },
              "interval46": {
                "intervalIndex": "interval46",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 2,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval47": {
                "intervalIndex": "interval47",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 5,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval48": {
                "intervalIndex": "interval48",
                "testStatus": "fail",
                "resultCountDetails": {
                  "passCount": 6,
                  "failureCount": 1,
                  "slowCount": 0
                }
              }
            }
          },
          "dns": {
            "detailList": {
              "interval1": {
                "intervalIndex": "interval1",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 7,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval2": {
                "intervalIndex": "interval2",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 4,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval3": {
                "intervalIndex": "interval3",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 1,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval4": {
                "intervalIndex": "interval4",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 6,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval5": {
                "intervalIndex": "interval5",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 5,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval6": {
                "intervalIndex": "interval6",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 2,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval7": {
                "intervalIndex": "interval7",
                "testStatus": "fail",
                "resultCountDetails": {
                  "passCount": 6,
                  "failureCount": 4,
                  "slowCount": 0
                }
              },
              "interval8": {
                "intervalIndex": "interval8",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 6,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval9": {
                "intervalIndex": "interval9",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 5,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval10": {
                "intervalIndex": "interval10",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 2,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval11": {
                "intervalIndex": "interval11",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 6,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval12": {
                "intervalIndex": "interval12",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 1,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval13": {
                "intervalIndex": "interval13",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 7,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval14": {
                "intervalIndex": "interval14",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 2,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval15": {
                "intervalIndex": "interval15",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 5,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval16": {
                "intervalIndex": "interval16",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 1,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval17": {
                "intervalIndex": "interval17",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 3,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval18": {
                "intervalIndex": "interval18",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 2,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval19": {
                "intervalIndex": "interval19",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 8,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval20": {
                "intervalIndex": "interval20",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 2,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval21": {
                "intervalIndex": "interval21",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 5,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval22": {
                "intervalIndex": "interval22",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 2,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval23": {
                "intervalIndex": "interval23",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 5,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval24": {
                "intervalIndex": "interval24",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 8,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval25": {
                "intervalIndex": "interval25",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 7,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval26": {
                "intervalIndex": "interval26",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 3,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval27": {
                "intervalIndex": "interval27",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 5,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval28": {
                "intervalIndex": "interval28",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 2,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval29": {
                "intervalIndex": "interval29",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 8,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval30": {
                "intervalIndex": "interval30",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 7,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval31": {
                "intervalIndex": "interval31",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 8,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval32": {
                "intervalIndex": "interval32",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 4,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval33": {
                "intervalIndex": "interval33",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 5,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval34": {
                "intervalIndex": "interval34",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 5,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval35": {
                "intervalIndex": "interval35",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 5,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval36": {
                "intervalIndex": "interval36",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 5,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval37": {
                "intervalIndex": "interval37",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 1,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval38": {
                "intervalIndex": "interval38",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 4,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval39": {
                "intervalIndex": "interval39",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 2,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval40": {
                "intervalIndex": "interval40",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 2,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval41": {
                "intervalIndex": "interval41",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 2,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval42": {
                "intervalIndex": "interval42",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 3,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval43": {
                "intervalIndex": "interval43",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 4,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval44": {
                "intervalIndex": "interval44",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 2,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval45": {
                "intervalIndex": "interval45",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 1,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval46": {
                "intervalIndex": "interval46",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 2,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval47": {
                "intervalIndex": "interval47",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 2,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval48": {
                "intervalIndex": "interval48",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 8,
                  "failureCount": 0,
                  "slowCount": 0
                }
              }
            }
          },
          "webserver": {
            "detailList": {
              "interval1": {
                "intervalIndex": "interval1",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 6,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval2": {
                "intervalIndex": "interval2",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 5,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval3": {
                "intervalIndex": "interval3",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 5,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval4": {
                "intervalIndex": "interval4",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 2,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval5": {
                "intervalIndex": "interval5",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 6,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval6": {
                "intervalIndex": "interval6",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 8,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval7": {
                "intervalIndex": "interval7",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 6,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval8": {
                "intervalIndex": "interval8",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 5,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval9": {
                "intervalIndex": "interval9",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 3,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval10": {
                "intervalIndex": "interval10",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 5,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval11": {
                "intervalIndex": "interval11",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 3,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval12": {
                "intervalIndex": "interval12",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 6,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval13": {
                "intervalIndex": "interval13",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 7,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval14": {
                "intervalIndex": "interval14",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 4,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval15": {
                "intervalIndex": "interval15",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 8,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval16": {
                "intervalIndex": "interval16",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 5,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval17": {
                "intervalIndex": "interval17",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 7,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval18": {
                "intervalIndex": "interval18",
                "testStatus": "fail",
                "resultCountDetails": {
                  "passCount": 4,
                  "failureCount": 2,
                  "slowCount": 0
                }
              },
              "interval19": {
                "intervalIndex": "interval19",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 2,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval20": {
                "intervalIndex": "interval20",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 5,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval21": {
                "intervalIndex": "interval21",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 2,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval22": {
                "intervalIndex": "interval22",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 1,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval23": {
                "intervalIndex": "interval23",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 1,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval24": {
                "intervalIndex": "interval24",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 8,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval25": {
                "intervalIndex": "interval25",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 6,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval26": {
                "intervalIndex": "interval26",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 7,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval27": {
                "intervalIndex": "interval27",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 5,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval28": {
                "intervalIndex": "interval28",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 2,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval29": {
                "intervalIndex": "interval29",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 1,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval30": {
                "intervalIndex": "interval30",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 1,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval31": {
                "intervalIndex": "interval31",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 2,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval32": {
                "intervalIndex": "interval32",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 4,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval33": {
                "intervalIndex": "interval33",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 4,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval34": {
                "intervalIndex": "interval34",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 5,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval35": {
                "intervalIndex": "interval35",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 2,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval36": {
                "intervalIndex": "interval36",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 4,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval37": {
                "intervalIndex": "interval37",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 1,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval38": {
                "intervalIndex": "interval38",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 8,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval39": {
                "intervalIndex": "interval39",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 5,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval40": {
                "intervalIndex": "interval40",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 5,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval41": {
                "intervalIndex": "interval41",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 3,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval42": {
                "intervalIndex": "interval42",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 6,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval43": {
                "intervalIndex": "interval43",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 8,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval44": {
                "intervalIndex": "interval44",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 5,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval45": {
                "intervalIndex": "interval45",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 5,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval46": {
                "intervalIndex": "interval46",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 2,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval47": {
                "intervalIndex": "interval47",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 3,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval48": {
                "intervalIndex": "interval48",
                "testStatus": "fail",
                "resultCountDetails": {
                  "passCount": 4,
                  "failureCount": 2,
                  "slowCount": 0
                }
              }
            }
          },
          "filetransfer": {
            "detailList": {
              "interval1": {
                "intervalIndex": "interval1",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 1,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval2": {
                "intervalIndex": "interval2",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 5,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval3": {
                "intervalIndex": "interval3",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 6,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval4": {
                "intervalIndex": "interval4",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 2,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval5": {
                "intervalIndex": "interval5",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 2,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval6": {
                "intervalIndex": "interval6",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 3,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval7": {
                "intervalIndex": "interval7",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 5,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval8": {
                "intervalIndex": "interval8",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 1,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval9": {
                "intervalIndex": "interval9",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 8,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval10": {
                "intervalIndex": "interval10",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 3,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval11": {
                "intervalIndex": "interval11",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 3,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval12": {
                "intervalIndex": "interval12",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 4,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval13": {
                "intervalIndex": "interval13",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 5,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval14": {
                "intervalIndex": "interval14",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 2,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval15": {
                "intervalIndex": "interval15",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 5,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval16": {
                "intervalIndex": "interval16",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 1,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval17": {
                "intervalIndex": "interval17",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 3,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval18": {
                "intervalIndex": "interval18",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 5,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval19": {
                "intervalIndex": "interval19",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 7,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval20": {
                "intervalIndex": "interval20",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 4,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval21": {
                "intervalIndex": "interval21",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 4,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval22": {
                "intervalIndex": "interval22",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 2,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval23": {
                "intervalIndex": "interval23",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 1,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval24": {
                "intervalIndex": "interval24",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 1,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval25": {
                "intervalIndex": "interval25",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 3,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval26": {
                "intervalIndex": "interval26",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 1,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval27": {
                "intervalIndex": "interval27",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 7,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval28": {
                "intervalIndex": "interval28",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 7,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval29": {
                "intervalIndex": "interval29",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 4,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval30": {
                "intervalIndex": "interval30",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 2,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval31": {
                "intervalIndex": "interval31",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 7,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval32": {
                "intervalIndex": "interval32",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 6,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval33": {
                "intervalIndex": "interval33",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 7,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval34": {
                "intervalIndex": "interval34",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 6,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval35": {
                "intervalIndex": "interval35",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 3,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval36": {
                "intervalIndex": "interval36",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 5,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval37": {
                "intervalIndex": "interval37",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 1,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval38": {
                "intervalIndex": "interval38",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 3,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval39": {
                "intervalIndex": "interval39",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 6,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval40": {
                "intervalIndex": "interval40",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 3,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval41": {
                "intervalIndex": "interval41",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 5,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval42": {
                "intervalIndex": "interval42",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 2,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval43": {
                "intervalIndex": "interval43",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 8,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval44": {
                "intervalIndex": "interval44",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 6,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval45": {
                "intervalIndex": "interval45",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 4,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval46": {
                "intervalIndex": "interval46",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 3,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval47": {
                "intervalIndex": "interval47",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 7,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval48": {
                "intervalIndex": "interval48",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 2,
                  "failureCount": 0,
                  "slowCount": 0
                }
              }
            }
          }
        }
      }
    },
    {
      "testSuiteId": "5b60b6f236fe7400cf82c7b7",
      "testResultDetails": {
        "startTime": 1548913800000,
        "endTime": 1549000200000,
        "mostRecent": {
          "lastRun": 1537769700000,
          "testResults": [
            {
              "id": 1,
              "testType": "dhcp",
              "status": "pass"
            },
            {
              "id": 2,
              "testType": "dns",
              "status": "pass"
            },
            {
              "id": 3,
              "testType": "webserver",
              "status": "pass"
            },
            {
              "id": 4,
              "testType": "onboarding",
              "status": "pass"
            },
            {
              "id": 5,
              "testType": "ping",
              "status": "pass"
            }
          ]
        },
        "detailResults": {
          "dhcp": {
            "detailList": {
              "interval1": {
                "intervalIndex": "interval1",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 3,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval2": {
                "intervalIndex": "interval2",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 3,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval3": {
                "intervalIndex": "interval3",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 2,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval4": {
                "intervalIndex": "interval4",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 4,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval5": {
                "intervalIndex": "interval5",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 7,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval6": {
                "intervalIndex": "interval6",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 8,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval7": {
                "intervalIndex": "interval7",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 1,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval8": {
                "intervalIndex": "interval8",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 5,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval9": {
                "intervalIndex": "interval9",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 3,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval10": {
                "intervalIndex": "interval10",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 8,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval11": {
                "intervalIndex": "interval11",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 2,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval12": {
                "intervalIndex": "interval12",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 7,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval13": {
                "intervalIndex": "interval13",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 1,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval14": {
                "intervalIndex": "interval14",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 1,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval15": {
                "intervalIndex": "interval15",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 4,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval16": {
                "intervalIndex": "interval16",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 3,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval17": {
                "intervalIndex": "interval17",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 7,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval18": {
                "intervalIndex": "interval18",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 2,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval19": {
                "intervalIndex": "interval19",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 6,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval20": {
                "intervalIndex": "interval20",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 7,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval21": {
                "intervalIndex": "interval21",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 4,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval22": {
                "intervalIndex": "interval22",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 1,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval23": {
                "intervalIndex": "interval23",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 1,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval24": {
                "intervalIndex": "interval24",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 1,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval25": {
                "intervalIndex": "interval25",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 6,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval26": {
                "intervalIndex": "interval26",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 4,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval27": {
                "intervalIndex": "interval27",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 3,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval28": {
                "intervalIndex": "interval28",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 3,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval29": {
                "intervalIndex": "interval29",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 3,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval30": {
                "intervalIndex": "interval30",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 3,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval31": {
                "intervalIndex": "interval31",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 6,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval32": {
                "intervalIndex": "interval32",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 2,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval33": {
                "intervalIndex": "interval33",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 7,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval34": {
                "intervalIndex": "interval34",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 2,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval35": {
                "intervalIndex": "interval35",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 6,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval36": {
                "intervalIndex": "interval36",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 3,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval37": {
                "intervalIndex": "interval37",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 3,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval38": {
                "intervalIndex": "interval38",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 3,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval39": {
                "intervalIndex": "interval39",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 6,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval40": {
                "intervalIndex": "interval40",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 6,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval41": {
                "intervalIndex": "interval41",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 7,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval42": {
                "intervalIndex": "interval42",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 7,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval43": {
                "intervalIndex": "interval43",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 2,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval44": {
                "intervalIndex": "interval44",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 3,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval45": {
                "intervalIndex": "interval45",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 1,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval46": {
                "intervalIndex": "interval46",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 5,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval47": {
                "intervalIndex": "interval47",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 2,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval48": {
                "intervalIndex": "interval48",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 8,
                  "failureCount": 0,
                  "slowCount": 0
                }
              }
            }
          },
          "dns": {
            "detailList": {
              "interval1": {
                "intervalIndex": "interval1",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 4,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval2": {
                "intervalIndex": "interval2",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 5,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval3": {
                "intervalIndex": "interval3",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 7,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval4": {
                "intervalIndex": "interval4",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 4,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval5": {
                "intervalIndex": "interval5",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 6,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval6": {
                "intervalIndex": "interval6",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 8,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval7": {
                "intervalIndex": "interval7",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 6,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval8": {
                "intervalIndex": "interval8",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 1,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval9": {
                "intervalIndex": "interval9",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 7,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval10": {
                "intervalIndex": "interval10",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 5,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval11": {
                "intervalIndex": "interval11",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 4,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval12": {
                "intervalIndex": "interval12",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 4,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval13": {
                "intervalIndex": "interval13",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 1,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval14": {
                "intervalIndex": "interval14",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 6,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval15": {
                "intervalIndex": "interval15",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 6,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval16": {
                "intervalIndex": "interval16",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 7,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval17": {
                "intervalIndex": "interval17",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 6,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval18": {
                "intervalIndex": "interval18",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 1,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval19": {
                "intervalIndex": "interval19",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 4,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval20": {
                "intervalIndex": "interval20",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 2,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval21": {
                "intervalIndex": "interval21",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 7,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval22": {
                "intervalIndex": "interval22",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 3,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval23": {
                "intervalIndex": "interval23",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 1,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval24": {
                "intervalIndex": "interval24",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 1,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval25": {
                "intervalIndex": "interval25",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 8,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval26": {
                "intervalIndex": "interval26",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 6,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval27": {
                "intervalIndex": "interval27",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 6,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval28": {
                "intervalIndex": "interval28",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 1,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval29": {
                "intervalIndex": "interval29",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 4,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval30": {
                "intervalIndex": "interval30",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 1,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval31": {
                "intervalIndex": "interval31",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 4,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval32": {
                "intervalIndex": "interval32",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 4,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval33": {
                "intervalIndex": "interval33",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 4,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval34": {
                "intervalIndex": "interval34",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 4,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval35": {
                "intervalIndex": "interval35",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 1,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval36": {
                "intervalIndex": "interval36",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 5,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval37": {
                "intervalIndex": "interval37",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 5,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval38": {
                "intervalIndex": "interval38",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 2,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval39": {
                "intervalIndex": "interval39",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 1,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval40": {
                "intervalIndex": "interval40",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 1,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval41": {
                "intervalIndex": "interval41",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 8,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval42": {
                "intervalIndex": "interval42",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 5,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval43": {
                "intervalIndex": "interval43",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 3,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval44": {
                "intervalIndex": "interval44",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 7,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval45": {
                "intervalIndex": "interval45",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 3,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval46": {
                "intervalIndex": "interval46",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 3,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval47": {
                "intervalIndex": "interval47",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 2,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval48": {
                "intervalIndex": "interval48",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 2,
                  "failureCount": 0,
                  "slowCount": 0
                }
              }
            }
          },
          "webserver": {
            "detailList": {
              "interval1": {
                "intervalIndex": "interval1",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 5,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval2": {
                "intervalIndex": "interval2",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 3,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval3": {
                "intervalIndex": "interval3",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 7,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval4": {
                "intervalIndex": "interval4",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 8,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval5": {
                "intervalIndex": "interval5",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 2,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval6": {
                "intervalIndex": "interval6",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 5,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval7": {
                "intervalIndex": "interval7",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 1,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval8": {
                "intervalIndex": "interval8",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 7,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval9": {
                "intervalIndex": "interval9",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 7,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval10": {
                "intervalIndex": "interval10",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 2,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval11": {
                "intervalIndex": "interval11",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 3,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval12": {
                "intervalIndex": "interval12",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 3,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval13": {
                "intervalIndex": "interval13",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 6,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval14": {
                "intervalIndex": "interval14",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 5,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval15": {
                "intervalIndex": "interval15",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 3,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval16": {
                "intervalIndex": "interval16",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 2,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval17": {
                "intervalIndex": "interval17",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 3,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval18": {
                "intervalIndex": "interval18",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 8,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval19": {
                "intervalIndex": "interval19",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 1,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval20": {
                "intervalIndex": "interval20",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 7,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval21": {
                "intervalIndex": "interval21",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 7,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval22": {
                "intervalIndex": "interval22",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 2,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval23": {
                "intervalIndex": "interval23",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 4,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval24": {
                "intervalIndex": "interval24",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 1,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval25": {
                "intervalIndex": "interval25",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 4,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval26": {
                "intervalIndex": "interval26",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 8,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval27": {
                "intervalIndex": "interval27",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 7,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval28": {
                "intervalIndex": "interval28",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 7,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval29": {
                "intervalIndex": "interval29",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 5,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval30": {
                "intervalIndex": "interval30",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 5,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval31": {
                "intervalIndex": "interval31",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 7,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval32": {
                "intervalIndex": "interval32",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 7,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval33": {
                "intervalIndex": "interval33",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 1,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval34": {
                "intervalIndex": "interval34",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 6,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval35": {
                "intervalIndex": "interval35",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 5,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval36": {
                "intervalIndex": "interval36",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 2,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval37": {
                "intervalIndex": "interval37",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 4,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval38": {
                "intervalIndex": "interval38",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 8,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval39": {
                "intervalIndex": "interval39",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 7,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval40": {
                "intervalIndex": "interval40",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 4,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval41": {
                "intervalIndex": "interval41",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 6,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval42": {
                "intervalIndex": "interval42",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 4,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval43": {
                "intervalIndex": "interval43",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 3,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval44": {
                "intervalIndex": "interval44",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 8,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval45": {
                "intervalIndex": "interval45",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 8,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval46": {
                "intervalIndex": "interval46",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 6,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval47": {
                "intervalIndex": "interval47",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 8,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval48": {
                "intervalIndex": "interval48",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 4,
                  "failureCount": 0,
                  "slowCount": 0
                }
              }
            }
          },
          "onboarding": {
            "detailList": {
              "interval1": {
                "intervalIndex": "interval1",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 3,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval2": {
                "intervalIndex": "interval2",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 6,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval3": {
                "intervalIndex": "interval3",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 3,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval4": {
                "intervalIndex": "interval4",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 4,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval5": {
                "intervalIndex": "interval5",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 3,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval6": {
                "intervalIndex": "interval6",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 7,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval7": {
                "intervalIndex": "interval7",
                "testStatus": "slow",
                "resultCountDetails": {
                  "passCount": 7,
                  "failureCount": 0,
                  "slowCount": 2
                }
              },
              "interval8": {
                "intervalIndex": "interval8",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 2,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval9": {
                "intervalIndex": "interval9",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 1,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval10": {
                "intervalIndex": "interval10",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 8,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval11": {
                "intervalIndex": "interval11",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 5,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval12": {
                "intervalIndex": "interval12",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 6,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval13": {
                "intervalIndex": "interval13",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 1,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval14": {
                "intervalIndex": "interval14",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 7,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval15": {
                "intervalIndex": "interval15",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 1,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval16": {
                "intervalIndex": "interval16",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 1,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval17": {
                "intervalIndex": "interval17",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 7,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval18": {
                "intervalIndex": "interval18",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 6,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval19": {
                "intervalIndex": "interval19",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 8,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval20": {
                "intervalIndex": "interval20",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 5,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval21": {
                "intervalIndex": "interval21",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 2,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval22": {
                "intervalIndex": "interval22",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 8,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval23": {
                "intervalIndex": "interval23",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 1,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval24": {
                "intervalIndex": "interval24",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 7,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval25": {
                "intervalIndex": "interval25",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 1,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval26": {
                "intervalIndex": "interval26",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 7,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval27": {
                "intervalIndex": "interval27",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 2,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval28": {
                "intervalIndex": "interval28",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 6,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval29": {
                "intervalIndex": "interval29",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 4,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval30": {
                "intervalIndex": "interval30",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 4,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval31": {
                "intervalIndex": "interval31",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 7,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval32": {
                "intervalIndex": "interval32",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 4,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval33": {
                "intervalIndex": "interval33",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 2,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval34": {
                "intervalIndex": "interval34",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 2,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval35": {
                "intervalIndex": "interval35",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 4,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval36": {
                "intervalIndex": "interval36",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 5,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval37": {
                "intervalIndex": "interval37",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 8,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval38": {
                "intervalIndex": "interval38",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 1,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval39": {
                "intervalIndex": "interval39",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 1,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval40": {
                "intervalIndex": "interval40",
                "testStatus": "slow",
                "resultCountDetails": {
                  "passCount": 5,
                  "failureCount": 0,
                  "slowCount": 4
                }
              },
              "interval41": {
                "intervalIndex": "interval41",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 3,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval42": {
                "intervalIndex": "interval42",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 8,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval43": {
                "intervalIndex": "interval43",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 4,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval44": {
                "intervalIndex": "interval44",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 8,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval45": {
                "intervalIndex": "interval45",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 2,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval46": {
                "intervalIndex": "interval46",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 5,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval47": {
                "intervalIndex": "interval47",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 6,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval48": {
                "intervalIndex": "interval48",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 8,
                  "failureCount": 0,
                  "slowCount": 0
                }
              }
            }
          },
          "ping": {
            "detailList": {
              "interval1": {
                "intervalIndex": "interval1",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 6,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval2": {
                "intervalIndex": "interval2",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 1,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval3": {
                "intervalIndex": "interval3",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 2,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval4": {
                "intervalIndex": "interval4",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 1,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval5": {
                "intervalIndex": "interval5",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 6,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval6": {
                "intervalIndex": "interval6",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 6,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval7": {
                "intervalIndex": "interval7",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 4,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval8": {
                "intervalIndex": "interval8",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 2,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval9": {
                "intervalIndex": "interval9",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 3,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval10": {
                "intervalIndex": "interval10",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 7,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval11": {
                "intervalIndex": "interval11",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 3,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval12": {
                "intervalIndex": "interval12",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 7,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval13": {
                "intervalIndex": "interval13",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 7,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval14": {
                "intervalIndex": "interval14",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 4,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval15": {
                "intervalIndex": "interval15",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 3,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval16": {
                "intervalIndex": "interval16",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 1,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval17": {
                "intervalIndex": "interval17",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 5,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval18": {
                "intervalIndex": "interval18",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 8,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval19": {
                "intervalIndex": "interval19",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 3,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval20": {
                "intervalIndex": "interval20",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 3,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval21": {
                "intervalIndex": "interval21",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 6,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval22": {
                "intervalIndex": "interval22",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 4,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval23": {
                "intervalIndex": "interval23",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 8,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval24": {
                "intervalIndex": "interval24",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 3,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval25": {
                "intervalIndex": "interval25",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 6,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval26": {
                "intervalIndex": "interval26",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 1,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval27": {
                "intervalIndex": "interval27",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 7,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval28": {
                "intervalIndex": "interval28",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 5,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval29": {
                "intervalIndex": "interval29",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 2,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval30": {
                "intervalIndex": "interval30",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 8,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval31": {
                "intervalIndex": "interval31",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 7,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval32": {
                "intervalIndex": "interval32",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 3,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval33": {
                "intervalIndex": "interval33",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 6,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval34": {
                "intervalIndex": "interval34",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 3,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval35": {
                "intervalIndex": "interval35",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 1,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval36": {
                "intervalIndex": "interval36",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 2,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval37": {
                "intervalIndex": "interval37",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 8,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval38": {
                "intervalIndex": "interval38",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 6,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval39": {
                "intervalIndex": "interval39",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 8,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval40": {
                "intervalIndex": "interval40",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 1,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval41": {
                "intervalIndex": "interval41",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 3,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval42": {
                "intervalIndex": "interval42",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 6,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval43": {
                "intervalIndex": "interval43",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 3,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval44": {
                "intervalIndex": "interval44",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 3,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval45": {
                "intervalIndex": "interval45",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 4,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval46": {
                "intervalIndex": "interval46",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 8,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval47": {
                "intervalIndex": "interval47",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 4,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval48": {
                "intervalIndex": "interval48",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 6,
                  "failureCount": 0,
                  "slowCount": 0
                }
              }
            }
          }
        }
      }
    },
    {
      "testSuiteId": "5b994c2c3ecd34001e3aa2c3",
      "testResultDetails": {
        "startTime": 1548913800000,
        "endTime": 1549000200000,
        "mostRecent": {
          "lastRun": 1537962600000,
          "testResults": [
            {
              "id": 1,
              "testType": "dns",
              "status": "pass"
            },
            {
              "id": 2,
              "testType": "dhcp",
              "status": "pass"
            },
            {
              "id": 3,
              "testType": "ping",
              "status": "pass"
            },
            {
              "id": 4,
              "testType": "onboarding",
              "status": "pass"
            },
            {
              "id": 5,
              "testType": "webserver",
              "status": "pass"
            },
            {
              "id": 6,
              "testType": "speed",
              "status": "pass"
            },
            {
              "id": 7,
              "testType": "ipslasender",
              "status": "fail"
            }
          ]
        },
        "detailResults": {
          "dns": {
            "detailList": {
              "interval1": {
                "intervalIndex": "interval1",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 1,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval2": {
                "intervalIndex": "interval2",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 4,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval3": {
                "intervalIndex": "interval3",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 6,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval4": {
                "intervalIndex": "interval4",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 5,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval5": {
                "intervalIndex": "interval5",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 6,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval6": {
                "intervalIndex": "interval6",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 7,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval7": {
                "intervalIndex": "interval7",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 8,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval8": {
                "intervalIndex": "interval8",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 6,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval9": {
                "intervalIndex": "interval9",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 4,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval10": {
                "intervalIndex": "interval10",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 3,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval11": {
                "intervalIndex": "interval11",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 3,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval12": {
                "intervalIndex": "interval12",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 6,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval13": {
                "intervalIndex": "interval13",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 5,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval14": {
                "intervalIndex": "interval14",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 5,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval15": {
                "intervalIndex": "interval15",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 5,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval16": {
                "intervalIndex": "interval16",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 1,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval17": {
                "intervalIndex": "interval17",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 6,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval18": {
                "intervalIndex": "interval18",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 7,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval19": {
                "intervalIndex": "interval19",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 5,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval20": {
                "intervalIndex": "interval20",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 6,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval21": {
                "intervalIndex": "interval21",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 1,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval22": {
                "intervalIndex": "interval22",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 1,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval23": {
                "intervalIndex": "interval23",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 8,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval24": {
                "intervalIndex": "interval24",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 2,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval25": {
                "intervalIndex": "interval25",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 5,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval26": {
                "intervalIndex": "interval26",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 5,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval27": {
                "intervalIndex": "interval27",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 2,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval28": {
                "intervalIndex": "interval28",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 4,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval29": {
                "intervalIndex": "interval29",
                "testStatus": "fail",
                "resultCountDetails": {
                  "passCount": 4,
                  "failureCount": 3,
                  "slowCount": 0
                }
              },
              "interval30": {
                "intervalIndex": "interval30",
                "testStatus": "fail",
                "resultCountDetails": {
                  "passCount": 4,
                  "failureCount": 3,
                  "slowCount": 0
                }
              },
              "interval31": {
                "intervalIndex": "interval31",
                "testStatus": "fail",
                "resultCountDetails": {
                  "passCount": 2,
                  "failureCount": 5,
                  "slowCount": 0
                }
              },
              "interval32": {
                "intervalIndex": "interval32",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 1,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval33": {
                "intervalIndex": "interval33",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 2,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval34": {
                "intervalIndex": "interval34",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 5,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval35": {
                "intervalIndex": "interval35",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 7,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval36": {
                "intervalIndex": "interval36",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 1,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval37": {
                "intervalIndex": "interval37",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 5,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval38": {
                "intervalIndex": "interval38",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 3,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval39": {
                "intervalIndex": "interval39",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 5,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval40": {
                "intervalIndex": "interval40",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 8,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval41": {
                "intervalIndex": "interval41",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 1,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval42": {
                "intervalIndex": "interval42",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 6,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval43": {
                "intervalIndex": "interval43",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 2,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval44": {
                "intervalIndex": "interval44",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 8,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval45": {
                "intervalIndex": "interval45",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 8,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval46": {
                "intervalIndex": "interval46",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 3,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval47": {
                "intervalIndex": "interval47",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 8,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval48": {
                "intervalIndex": "interval48",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 1,
                  "failureCount": 0,
                  "slowCount": 0
                }
              }
            }
          },
          "dhcp": {
            "detailList": {
              "interval1": {
                "intervalIndex": "interval1",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 3,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval2": {
                "intervalIndex": "interval2",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 4,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval3": {
                "intervalIndex": "interval3",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 5,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval4": {
                "intervalIndex": "interval4",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 5,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval5": {
                "intervalIndex": "interval5",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 2,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval6": {
                "intervalIndex": "interval6",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 8,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval7": {
                "intervalIndex": "interval7",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 4,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval8": {
                "intervalIndex": "interval8",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 8,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval9": {
                "intervalIndex": "interval9",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 4,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval10": {
                "intervalIndex": "interval10",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 1,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval11": {
                "intervalIndex": "interval11",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 5,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval12": {
                "intervalIndex": "interval12",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 1,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval13": {
                "intervalIndex": "interval13",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 3,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval14": {
                "intervalIndex": "interval14",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 2,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval15": {
                "intervalIndex": "interval15",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 3,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval16": {
                "intervalIndex": "interval16",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 3,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval17": {
                "intervalIndex": "interval17",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 8,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval18": {
                "intervalIndex": "interval18",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 8,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval19": {
                "intervalIndex": "interval19",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 1,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval20": {
                "intervalIndex": "interval20",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 7,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval21": {
                "intervalIndex": "interval21",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 3,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval22": {
                "intervalIndex": "interval22",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 6,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval23": {
                "intervalIndex": "interval23",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 1,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval24": {
                "intervalIndex": "interval24",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 6,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval25": {
                "intervalIndex": "interval25",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 2,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval26": {
                "intervalIndex": "interval26",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 3,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval27": {
                "intervalIndex": "interval27",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 2,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval28": {
                "intervalIndex": "interval28",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 8,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval29": {
                "intervalIndex": "interval29",
                "testStatus": "fail",
                "resultCountDetails": {
                  "passCount": 2,
                  "failureCount": 1,
                  "slowCount": 0
                }
              },
              "interval30": {
                "intervalIndex": "interval30",
                "testStatus": "fail",
                "resultCountDetails": {
                  "passCount": 3,
                  "failureCount": 2,
                  "slowCount": 0
                }
              },
              "interval31": {
                "intervalIndex": "interval31",
                "testStatus": "fail",
                "resultCountDetails": {
                  "passCount": 4,
                  "failureCount": 1,
                  "slowCount": 0
                }
              },
              "interval32": {
                "intervalIndex": "interval32",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 3,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval33": {
                "intervalIndex": "interval33",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 2,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval34": {
                "intervalIndex": "interval34",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 7,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval35": {
                "intervalIndex": "interval35",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 3,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval36": {
                "intervalIndex": "interval36",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 1,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval37": {
                "intervalIndex": "interval37",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 3,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval38": {
                "intervalIndex": "interval38",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 4,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval39": {
                "intervalIndex": "interval39",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 8,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval40": {
                "intervalIndex": "interval40",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 7,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval41": {
                "intervalIndex": "interval41",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 2,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval42": {
                "intervalIndex": "interval42",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 4,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval43": {
                "intervalIndex": "interval43",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 7,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval44": {
                "intervalIndex": "interval44",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 3,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval45": {
                "intervalIndex": "interval45",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 3,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval46": {
                "intervalIndex": "interval46",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 5,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval47": {
                "intervalIndex": "interval47",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 2,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval48": {
                "intervalIndex": "interval48",
                "testStatus": "slow",
                "resultCountDetails": {
                  "passCount": 3,
                  "failureCount": 0,
                  "slowCount": 1
                }
              }
            }
          },
          "ping": {
            "detailList": {
              "interval1": {
                "intervalIndex": "interval1",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 4,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval2": {
                "intervalIndex": "interval2",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 2,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval3": {
                "intervalIndex": "interval3",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 3,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval4": {
                "intervalIndex": "interval4",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 8,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval5": {
                "intervalIndex": "interval5",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 3,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval6": {
                "intervalIndex": "interval6",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 1,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval7": {
                "intervalIndex": "interval7",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 7,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval8": {
                "intervalIndex": "interval8",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 2,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval9": {
                "intervalIndex": "interval9",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 3,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval10": {
                "intervalIndex": "interval10",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 6,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval11": {
                "intervalIndex": "interval11",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 8,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval12": {
                "intervalIndex": "interval12",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 2,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval13": {
                "intervalIndex": "interval13",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 5,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval14": {
                "intervalIndex": "interval14",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 6,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval15": {
                "intervalIndex": "interval15",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 3,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval16": {
                "intervalIndex": "interval16",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 2,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval17": {
                "intervalIndex": "interval17",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 4,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval18": {
                "intervalIndex": "interval18",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 8,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval19": {
                "intervalIndex": "interval19",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 2,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval20": {
                "intervalIndex": "interval20",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 1,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval21": {
                "intervalIndex": "interval21",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 3,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval22": {
                "intervalIndex": "interval22",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 1,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval23": {
                "intervalIndex": "interval23",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 3,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval24": {
                "intervalIndex": "interval24",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 4,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval25": {
                "intervalIndex": "interval25",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 1,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval26": {
                "intervalIndex": "interval26",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 1,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval27": {
                "intervalIndex": "interval27",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 7,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval28": {
                "intervalIndex": "interval28",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 1,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval29": {
                "intervalIndex": "interval29",
                "testStatus": "fail",
                "resultCountDetails": {
                  "passCount": 3,
                  "failureCount": 4,
                  "slowCount": 0
                }
              },
              "interval30": {
                "intervalIndex": "interval30",
                "testStatus": "fail",
                "resultCountDetails": {
                  "passCount": 3,
                  "failureCount": 3,
                  "slowCount": 0
                }
              },
              "interval31": {
                "intervalIndex": "interval31",
                "testStatus": "fail",
                "resultCountDetails": {
                  "passCount": 6,
                  "failureCount": 4,
                  "slowCount": 0
                }
              },
              "interval32": {
                "intervalIndex": "interval32",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 7,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval33": {
                "intervalIndex": "interval33",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 4,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval34": {
                "intervalIndex": "interval34",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 6,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval35": {
                "intervalIndex": "interval35",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 1,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval36": {
                "intervalIndex": "interval36",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 5,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval37": {
                "intervalIndex": "interval37",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 2,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval38": {
                "intervalIndex": "interval38",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 8,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval39": {
                "intervalIndex": "interval39",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 8,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval40": {
                "intervalIndex": "interval40",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 7,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval41": {
                "intervalIndex": "interval41",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 1,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval42": {
                "intervalIndex": "interval42",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 8,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval43": {
                "intervalIndex": "interval43",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 8,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval44": {
                "intervalIndex": "interval44",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 4,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval45": {
                "intervalIndex": "interval45",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 3,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval46": {
                "intervalIndex": "interval46",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 6,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval47": {
                "intervalIndex": "interval47",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 7,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval48": {
                "intervalIndex": "interval48",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 8,
                  "failureCount": 0,
                  "slowCount": 0
                }
              }
            }
          },
          "onboarding": {
            "detailList": {
              "interval1": {
                "intervalIndex": "interval1",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 3,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval2": {
                "intervalIndex": "interval2",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 7,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval3": {
                "intervalIndex": "interval3",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 5,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval4": {
                "intervalIndex": "interval4",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 3,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval5": {
                "intervalIndex": "interval5",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 1,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval6": {
                "intervalIndex": "interval6",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 3,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval7": {
                "intervalIndex": "interval7",
                "testStatus": "slow",
                "resultCountDetails": {
                  "passCount": 7,
                  "failureCount": 0,
                  "slowCount": 1
                }
              },
              "interval8": {
                "intervalIndex": "interval8",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 2,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval9": {
                "intervalIndex": "interval9",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 8,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval10": {
                "intervalIndex": "interval10",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 5,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval11": {
                "intervalIndex": "interval11",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 2,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval12": {
                "intervalIndex": "interval12",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 4,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval13": {
                "intervalIndex": "interval13",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 8,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval14": {
                "intervalIndex": "interval14",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 1,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval15": {
                "intervalIndex": "interval15",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 6,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval16": {
                "intervalIndex": "interval16",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 7,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval17": {
                "intervalIndex": "interval17",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 1,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval18": {
                "intervalIndex": "interval18",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 7,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval19": {
                "intervalIndex": "interval19",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 1,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval20": {
                "intervalIndex": "interval20",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 3,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval21": {
                "intervalIndex": "interval21",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 4,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval22": {
                "intervalIndex": "interval22",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 8,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval23": {
                "intervalIndex": "interval23",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 7,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval24": {
                "intervalIndex": "interval24",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 5,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval25": {
                "intervalIndex": "interval25",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 5,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval26": {
                "intervalIndex": "interval26",
                "testStatus": "slow",
                "resultCountDetails": {
                  "passCount": 8,
                  "failureCount": 0,
                  "slowCount": 1
                }
              },
              "interval27": {
                "intervalIndex": "interval27",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 2,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval28": {
                "intervalIndex": "interval28",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 5,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval29": {
                "intervalIndex": "interval29",
                "testStatus": "fail",
                "resultCountDetails": {
                  "passCount": 8,
                  "failureCount": 2,
                  "slowCount": 0
                }
              },
              "interval30": {
                "intervalIndex": "interval30",
                "testStatus": "fail",
                "resultCountDetails": {
                  "passCount": 4,
                  "failureCount": 4,
                  "slowCount": 0
                }
              },
              "interval31": {
                "intervalIndex": "interval31",
                "testStatus": "fail",
                "resultCountDetails": {
                  "passCount": 3,
                  "failureCount": 4,
                  "slowCount": 0
                }
              },
              "interval32": {
                "intervalIndex": "interval32",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 7,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval33": {
                "intervalIndex": "interval33",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 1,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval34": {
                "intervalIndex": "interval34",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 5,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval35": {
                "intervalIndex": "interval35",
                "testStatus": "slow",
                "resultCountDetails": {
                  "passCount": 6,
                  "failureCount": 0,
                  "slowCount": 1
                }
              },
              "interval36": {
                "intervalIndex": "interval36",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 7,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval37": {
                "intervalIndex": "interval37",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 8,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval38": {
                "intervalIndex": "interval38",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 8,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval39": {
                "intervalIndex": "interval39",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 8,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval40": {
                "intervalIndex": "interval40",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 8,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval41": {
                "intervalIndex": "interval41",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 6,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval42": {
                "intervalIndex": "interval42",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 2,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval43": {
                "intervalIndex": "interval43",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 3,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval44": {
                "intervalIndex": "interval44",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 6,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval45": {
                "intervalIndex": "interval45",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 2,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval46": {
                "intervalIndex": "interval46",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 3,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval47": {
                "intervalIndex": "interval47",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 4,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval48": {
                "intervalIndex": "interval48",
                "testStatus": "slow",
                "resultCountDetails": {
                  "passCount": 3,
                  "failureCount": 0,
                  "slowCount": 5
                }
              }
            }
          },
          "webserver": {
            "detailList": {
              "interval1": {
                "intervalIndex": "interval1",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 2,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval2": {
                "intervalIndex": "interval2",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 7,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval3": {
                "intervalIndex": "interval3",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 3,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval4": {
                "intervalIndex": "interval4",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 1,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval5": {
                "intervalIndex": "interval5",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 3,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval6": {
                "intervalIndex": "interval6",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 8,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval7": {
                "intervalIndex": "interval7",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 8,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval8": {
                "intervalIndex": "interval8",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 5,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval9": {
                "intervalIndex": "interval9",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 2,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval10": {
                "intervalIndex": "interval10",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 1,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval11": {
                "intervalIndex": "interval11",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 1,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval12": {
                "intervalIndex": "interval12",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 6,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval13": {
                "intervalIndex": "interval13",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 2,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval14": {
                "intervalIndex": "interval14",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 6,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval15": {
                "intervalIndex": "interval15",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 1,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval16": {
                "intervalIndex": "interval16",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 8,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval17": {
                "intervalIndex": "interval17",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 5,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval18": {
                "intervalIndex": "interval18",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 3,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval19": {
                "intervalIndex": "interval19",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 3,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval20": {
                "intervalIndex": "interval20",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 2,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval21": {
                "intervalIndex": "interval21",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 6,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval22": {
                "intervalIndex": "interval22",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 4,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval23": {
                "intervalIndex": "interval23",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 8,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval24": {
                "intervalIndex": "interval24",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 6,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval25": {
                "intervalIndex": "interval25",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 8,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval26": {
                "intervalIndex": "interval26",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 4,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval27": {
                "intervalIndex": "interval27",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 1,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval28": {
                "intervalIndex": "interval28",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 8,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval29": {
                "intervalIndex": "interval29",
                "testStatus": "fail",
                "resultCountDetails": {
                  "passCount": 4,
                  "failureCount": 3,
                  "slowCount": 0
                }
              },
              "interval30": {
                "intervalIndex": "interval30",
                "testStatus": "fail",
                "resultCountDetails": {
                  "passCount": 7,
                  "failureCount": 2,
                  "slowCount": 0
                }
              },
              "interval31": {
                "intervalIndex": "interval31",
                "testStatus": "fail",
                "resultCountDetails": {
                  "passCount": 6,
                  "failureCount": 2,
                  "slowCount": 0
                }
              },
              "interval32": {
                "intervalIndex": "interval32",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 8,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval33": {
                "intervalIndex": "interval33",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 3,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval34": {
                "intervalIndex": "interval34",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 8,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval35": {
                "intervalIndex": "interval35",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 3,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval36": {
                "intervalIndex": "interval36",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 7,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval37": {
                "intervalIndex": "interval37",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 1,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval38": {
                "intervalIndex": "interval38",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 1,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval39": {
                "intervalIndex": "interval39",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 2,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval40": {
                "intervalIndex": "interval40",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 5,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval41": {
                "intervalIndex": "interval41",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 6,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval42": {
                "intervalIndex": "interval42",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 2,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval43": {
                "intervalIndex": "interval43",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 8,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval44": {
                "intervalIndex": "interval44",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 2,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval45": {
                "intervalIndex": "interval45",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 2,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval46": {
                "intervalIndex": "interval46",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 1,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval47": {
                "intervalIndex": "interval47",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 1,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval48": {
                "intervalIndex": "interval48",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 2,
                  "failureCount": 0,
                  "slowCount": 0
                }
              }
            }
          },
          "ipslasender": {
            "detailList": {
              "interval1": {
                "intervalIndex": "interval1",
                "testStatus": "fail",
                "resultCountDetails": {
                  "passCount": 0,
                  "failureCount": 4,
                  "slowCount": 0
                }
              },
              "interval2": {
                "intervalIndex": "interval2",
                "testStatus": "fail",
                "resultCountDetails": {
                  "passCount": 0,
                  "failureCount": 1,
                  "slowCount": 0
                }
              },
              "interval3": {
                "intervalIndex": "interval3",
                "testStatus": "fail",
                "resultCountDetails": {
                  "passCount": 0,
                  "failureCount": 1,
                  "slowCount": 0
                }
              },
              "interval4": {
                "intervalIndex": "interval4",
                "testStatus": "fail",
                "resultCountDetails": {
                  "passCount": 0,
                  "failureCount": 5,
                  "slowCount": 0
                }
              },
              "interval5": {
                "intervalIndex": "interval5",
                "testStatus": "fail",
                "resultCountDetails": {
                  "passCount": 0,
                  "failureCount": 1,
                  "slowCount": 0
                }
              },
              "interval6": {
                "intervalIndex": "interval6",
                "testStatus": "fail",
                "resultCountDetails": {
                  "passCount": 0,
                  "failureCount": 3,
                  "slowCount": 0
                }
              },
              "interval7": {
                "intervalIndex": "interval7",
                "testStatus": "fail",
                "resultCountDetails": {
                  "passCount": 0,
                  "failureCount": 3,
                  "slowCount": 0
                }
              },
              "interval8": {
                "intervalIndex": "interval8",
                "testStatus": "fail",
                "resultCountDetails": {
                  "passCount": 0,
                  "failureCount": 5,
                  "slowCount": 0
                }
              },
              "interval9": {
                "intervalIndex": "interval9",
                "testStatus": "fail",
                "resultCountDetails": {
                  "passCount": 0,
                  "failureCount": 4,
                  "slowCount": 0
                }
              },
              "interval10": {
                "intervalIndex": "interval10",
                "testStatus": "fail",
                "resultCountDetails": {
                  "passCount": 0,
                  "failureCount": 1,
                  "slowCount": 0
                }
              },
              "interval11": {
                "intervalIndex": "interval11",
                "testStatus": "fail",
                "resultCountDetails": {
                  "passCount": 0,
                  "failureCount": 3,
                  "slowCount": 0
                }
              },
              "interval12": {
                "intervalIndex": "interval12",
                "testStatus": "fail",
                "resultCountDetails": {
                  "passCount": 0,
                  "failureCount": 2,
                  "slowCount": 0
                }
              },
              "interval13": {
                "intervalIndex": "interval13",
                "testStatus": "fail",
                "resultCountDetails": {
                  "passCount": 0,
                  "failureCount": 1,
                  "slowCount": 0
                }
              },
              "interval14": {
                "intervalIndex": "interval14",
                "testStatus": "fail",
                "resultCountDetails": {
                  "passCount": 0,
                  "failureCount": 1,
                  "slowCount": 0
                }
              },
              "interval15": {
                "intervalIndex": "interval15",
                "testStatus": "fail",
                "resultCountDetails": {
                  "passCount": 0,
                  "failureCount": 4,
                  "slowCount": 0
                }
              },
              "interval16": {
                "intervalIndex": "interval16",
                "testStatus": "fail",
                "resultCountDetails": {
                  "passCount": 0,
                  "failureCount": 5,
                  "slowCount": 0
                }
              },
              "interval17": {
                "intervalIndex": "interval17",
                "testStatus": "fail",
                "resultCountDetails": {
                  "passCount": 0,
                  "failureCount": 1,
                  "slowCount": 0
                }
              },
              "interval18": {
                "intervalIndex": "interval18",
                "testStatus": "fail",
                "resultCountDetails": {
                  "passCount": 0,
                  "failureCount": 2,
                  "slowCount": 0
                }
              },
              "interval19": {
                "intervalIndex": "interval19",
                "testStatus": "fail",
                "resultCountDetails": {
                  "passCount": 0,
                  "failureCount": 2,
                  "slowCount": 0
                }
              },
              "interval20": {
                "intervalIndex": "interval20",
                "testStatus": "fail",
                "resultCountDetails": {
                  "passCount": 0,
                  "failureCount": 2,
                  "slowCount": 0
                }
              },
              "interval21": {
                "intervalIndex": "interval21",
                "testStatus": "fail",
                "resultCountDetails": {
                  "passCount": 0,
                  "failureCount": 2,
                  "slowCount": 0
                }
              },
              "interval22": {
                "intervalIndex": "interval22",
                "testStatus": "fail",
                "resultCountDetails": {
                  "passCount": 0,
                  "failureCount": 4,
                  "slowCount": 0
                }
              },
              "interval23": {
                "intervalIndex": "interval23",
                "testStatus": "fail",
                "resultCountDetails": {
                  "passCount": 0,
                  "failureCount": 3,
                  "slowCount": 0
                }
              },
              "interval24": {
                "intervalIndex": "interval24",
                "testStatus": "fail",
                "resultCountDetails": {
                  "passCount": 0,
                  "failureCount": 2,
                  "slowCount": 0
                }
              },
              "interval25": {
                "intervalIndex": "interval25",
                "testStatus": "fail",
                "resultCountDetails": {
                  "passCount": 0,
                  "failureCount": 5,
                  "slowCount": 0
                }
              },
              "interval26": {
                "intervalIndex": "interval26",
                "testStatus": "fail",
                "resultCountDetails": {
                  "passCount": 0,
                  "failureCount": 1,
                  "slowCount": 0
                }
              },
              "interval27": {
                "intervalIndex": "interval27",
                "testStatus": "fail",
                "resultCountDetails": {
                  "passCount": 0,
                  "failureCount": 3,
                  "slowCount": 0
                }
              },
              "interval28": {
                "intervalIndex": "interval28",
                "testStatus": "fail",
                "resultCountDetails": {
                  "passCount": 0,
                  "failureCount": 2,
                  "slowCount": 0
                }
              },
              "interval29": {
                "intervalIndex": "interval29",
                "testStatus": "fail",
                "resultCountDetails": {
                  "passCount": 0,
                  "failureCount": 1,
                  "slowCount": 0
                }
              },
              "interval30": {
                "intervalIndex": "interval30",
                "testStatus": "fail",
                "resultCountDetails": {
                  "passCount": 0,
                  "failureCount": 2,
                  "slowCount": 0
                }
              },
              "interval31": {
                "intervalIndex": "interval31",
                "testStatus": "fail",
                "resultCountDetails": {
                  "passCount": 0,
                  "failureCount": 5,
                  "slowCount": 0
                }
              },
              "interval32": {
                "intervalIndex": "interval32",
                "testStatus": "fail",
                "resultCountDetails": {
                  "passCount": 0,
                  "failureCount": 2,
                  "slowCount": 0
                }
              },
              "interval33": {
                "intervalIndex": "interval33",
                "testStatus": "fail",
                "resultCountDetails": {
                  "passCount": 0,
                  "failureCount": 5,
                  "slowCount": 0
                }
              },
              "interval34": {
                "intervalIndex": "interval34",
                "testStatus": "fail",
                "resultCountDetails": {
                  "passCount": 0,
                  "failureCount": 3,
                  "slowCount": 0
                }
              },
              "interval35": {
                "intervalIndex": "interval35",
                "testStatus": "fail",
                "resultCountDetails": {
                  "passCount": 0,
                  "failureCount": 4,
                  "slowCount": 0
                }
              },
              "interval36": {
                "intervalIndex": "interval36",
                "testStatus": "fail",
                "resultCountDetails": {
                  "passCount": 0,
                  "failureCount": 3,
                  "slowCount": 0
                }
              },
              "interval37": {
                "intervalIndex": "interval37",
                "testStatus": "fail",
                "resultCountDetails": {
                  "passCount": 0,
                  "failureCount": 1,
                  "slowCount": 0
                }
              },
              "interval38": {
                "intervalIndex": "interval38",
                "testStatus": "fail",
                "resultCountDetails": {
                  "passCount": 0,
                  "failureCount": 2,
                  "slowCount": 0
                }
              },
              "interval39": {
                "intervalIndex": "interval39",
                "testStatus": "fail",
                "resultCountDetails": {
                  "passCount": 0,
                  "failureCount": 4,
                  "slowCount": 0
                }
              },
              "interval40": {
                "intervalIndex": "interval40",
                "testStatus": "fail",
                "resultCountDetails": {
                  "passCount": 0,
                  "failureCount": 5,
                  "slowCount": 0
                }
              },
              "interval41": {
                "intervalIndex": "interval41",
                "testStatus": "fail",
                "resultCountDetails": {
                  "passCount": 0,
                  "failureCount": 5,
                  "slowCount": 0
                }
              },
              "interval42": {
                "intervalIndex": "interval42",
                "testStatus": "fail",
                "resultCountDetails": {
                  "passCount": 0,
                  "failureCount": 5,
                  "slowCount": 0
                }
              },
              "interval43": {
                "intervalIndex": "interval43",
                "testStatus": "fail",
                "resultCountDetails": {
                  "passCount": 0,
                  "failureCount": 1,
                  "slowCount": 0
                }
              },
              "interval44": {
                "intervalIndex": "interval44",
                "testStatus": "fail",
                "resultCountDetails": {
                  "passCount": 0,
                  "failureCount": 1,
                  "slowCount": 0
                }
              },
              "interval45": {
                "intervalIndex": "interval45",
                "testStatus": "fail",
                "resultCountDetails": {
                  "passCount": 0,
                  "failureCount": 4,
                  "slowCount": 0
                }
              },
              "interval46": {
                "intervalIndex": "interval46",
                "testStatus": "fail",
                "resultCountDetails": {
                  "passCount": 0,
                  "failureCount": 3,
                  "slowCount": 0
                }
              },
              "interval47": {
                "intervalIndex": "interval47",
                "testStatus": "fail",
                "resultCountDetails": {
                  "passCount": 0,
                  "failureCount": 2,
                  "slowCount": 0
                }
              },
              "interval48": {
                "intervalIndex": "interval48",
                "testStatus": "fail",
                "resultCountDetails": {
                  "passCount": 0,
                  "failureCount": 2,
                  "slowCount": 0
                }
              }
            }
          },
          "speed": {
            "detailList": {
              "interval1": {
                "intervalIndex": "interval1",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 5,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval2": {
                "intervalIndex": "interval2",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 8,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval3": {
                "intervalIndex": "interval3",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 5,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval4": {
                "intervalIndex": "interval4",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 3,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval5": {
                "intervalIndex": "interval5",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 2,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval6": {
                "intervalIndex": "interval6",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 7,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval7": {
                "intervalIndex": "interval7",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 7,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval8": {
                "intervalIndex": "interval8",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 1,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval9": {
                "intervalIndex": "interval9",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 6,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval10": {
                "intervalIndex": "interval10",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 5,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval11": {
                "intervalIndex": "interval11",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 4,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval12": {
                "intervalIndex": "interval12",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 3,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval13": {
                "intervalIndex": "interval13",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 7,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval14": {
                "intervalIndex": "interval14",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 2,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval15": {
                "intervalIndex": "interval15",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 1,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval16": {
                "intervalIndex": "interval16",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 1,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval17": {
                "intervalIndex": "interval17",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 3,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval18": {
                "intervalIndex": "interval18",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 1,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval19": {
                "intervalIndex": "interval19",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 1,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval20": {
                "intervalIndex": "interval20",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 4,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval21": {
                "intervalIndex": "interval21",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 6,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval22": {
                "intervalIndex": "interval22",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 3,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval23": {
                "intervalIndex": "interval23",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 4,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval24": {
                "intervalIndex": "interval24",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 3,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval25": {
                "intervalIndex": "interval25",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 6,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval26": {
                "intervalIndex": "interval26",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 5,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval27": {
                "intervalIndex": "interval27",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 4,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval28": {
                "intervalIndex": "interval28",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 3,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval29": {
                "intervalIndex": "interval29",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 4,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval30": {
                "intervalIndex": "interval30",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 4,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval31": {
                "intervalIndex": "interval31",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 4,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval32": {
                "intervalIndex": "interval32",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 7,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval33": {
                "intervalIndex": "interval33",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 7,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval34": {
                "intervalIndex": "interval34",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 8,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval35": {
                "intervalIndex": "interval35",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 8,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval36": {
                "intervalIndex": "interval36",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 5,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval37": {
                "intervalIndex": "interval37",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 3,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval38": {
                "intervalIndex": "interval38",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 6,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval39": {
                "intervalIndex": "interval39",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 6,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval40": {
                "intervalIndex": "interval40",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 4,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval41": {
                "intervalIndex": "interval41",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 2,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval42": {
                "intervalIndex": "interval42",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 7,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval43": {
                "intervalIndex": "interval43",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 5,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval44": {
                "intervalIndex": "interval44",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 7,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval45": {
                "intervalIndex": "interval45",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 5,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval46": {
                "intervalIndex": "interval46",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 4,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval47": {
                "intervalIndex": "interval47",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 6,
                  "failureCount": 0,
                  "slowCount": 0
                }
              },
              "interval48": {
                "intervalIndex": "interval48",
                "testStatus": "pass",
                "resultCountDetails": {
                  "passCount": 8,
                  "failureCount": 0,
                  "slowCount": 0
                }
              }
            }
          }
        }
      }
    }
  ],
  "Result_Template": {
    "testSuiteId": "5adf649d64825e0081e87d47",
    "testResultDetails": {
      "startTime": 1548913800000,
      "endTime": 1549000200000,
      "mostRecent": {
        "lastRun": 1527334632054,
        "testResults": [
          {
            "id": 1,
            "testType": "dhcp",
            "status": "none"
          },
          {
            "id": 2,
            "testType": "onboarding",
            "status": "none"
          }
        ]
      },
      "detailResults": {
        "dhcp": {
          "detailList": {

          }
        },
        "onboarding": {
          "detailList": {

          }
        }
      }
    }
  },
  "Create_Test_Suite_Template":{
    "_id": "5e5dfe0222b97b0020f812bb",
    "name": "test12344",
    "version": 0,
    "modelVersion": 2,
    "startTime": 0,
    "lastModifiedTime": 0,
    "numAssociatedSensor": 0,
    "location": null,
    "siteHierarchy": null,
    "status": "NOTRUNNING",
    "connection": "WIRELESS",
    "frequency": {},
    "rssiThreshold": -70,
    "numNeighborAPThreshold": 3,
    "scheduleInDays": 0,
    "wlans": [],
    "ssids": [
      {
        "bands": "BOTH",
        "ssid": "Guest_PSK",
        "profileName": "Guest_PSK",
        "authType": "WPA2_PSK",
        "authTypeRcvd": null,
        "psk": "PlRh/vfxvXDb1cQm5o4Rqw==",
        "username": null,
        "password": null,
        "eapMethod": null,
        "scep": false,
        "authProtocol": null,
        "certfilename": null,
        "certxferprotocol": "HTTP",
        "certstatus": "ACTIVE",
        "certpassphrase": null,
        "certdownloadurl": null,
        "numAps": 0,
        "numSensors": 0,
        "layer3webAuthsecurity": null,
        "layer3webAuthuserName": null,
        "layer3webAuthpassword": null,
        "layer3webAuthEmailAddress": null,
        "qosPolicy": null,
        "extWebAuth": false,
        "whiteList": false,
        "extWebAuthPortal": null,
        "extWebAuthAccessUrl": null,
        "extWebAuthHtmlTag": [],
        "id": 0,
        "wlanId": 0,
        "wlc": "",
        "validFrom": 0,
        "validTo": 0,
        "status": "ENABLED",
        "tests": [
          {
            "name": "Onboarding",
            "config": [
              {}
            ]
          }
        ]
      }
    ],
    "testScheduleMode": "DEDICATED",
    "showWlcUpgradeBanner": false,
    "radioAsSensorRemoved": true,
    "encryptionMode": "CBC",
    "runNow": "NO",
    "locationInfoList": [],
    "schedule": {},
    "tests": [],
    "sensors": [],
    "apCoverage": [
      {
        "bands": "2.4GHz",
        "numberOfApsToTest": 1,
        "rssiThreshold": -70
      }
    ],
    "testDurationEstimate": 134,
    "testTemplate": true,
    "legacyTestSuite": false,
    "tenantId": null
  }
})