define({
  "HealthSummary_Template": {
  "version": "1.0",
  "response": [
    {
      "time": "2018-08-02T09:46:00.000+0000",
      "healthScore": 71,
      "totalCount": 7,
      "goodCount": 5,
      "fairCount": 0,
      "badCount": 1,
      "entity": null
    },
    {
      "time": "2018-08-02T09:51:00.000+0000",
      "healthScore": 71,
      "totalCount": 7,
      "goodCount": 5,
      "fairCount": 0,
      "badCount": 1,
      "entity": null
    },
    {
      "time": "2018-08-02T09:56:00.000+0000",
      "healthScore": 71,
      "totalCount": 7,
      "goodCount": 5,
      "fairCount": 0,
      "badCount": 1,
      "entity": null
    },
    {
      "time": "2018-08-02T10:01:00.000+0000",
      "healthScore": 71,
      "totalCount": 7,
      "goodCount": 5,
      "fairCount": 0,
      "badCount": 1,
      "entity": null
    },
    {
      "time": "2018-08-02T10:06:00.000+0000",
      "healthScore": 71,
      "totalCount": 7,
      "goodCount": 5,
      "fairCount": 0,
      "badCount": 1,
      "entity": null
    },
    {
      "time": "2018-08-02T10:11:00.000+0000",
      "healthScore": 71,
      "totalCount": 7,
      "goodCount": 5,
      "fairCount": 0,
      "badCount": 1,
      "entity": null
    },
    {
      "time": "2018-08-02T10:16:00.000+0000",
      "healthScore": 71,
      "totalCount": 7,
      "goodCount": 5,
      "fairCount": 0,
      "badCount": 1,
      "entity": null
    },
    {
      "time": "2018-08-02T10:21:00.000+0000",
      "healthScore": 57,
      "totalCount": 7,
      "goodCount": 4,
      "fairCount": 1,
      "badCount": 1,
      "entity": null
    },
    {
      "time": "2018-08-02T10:26:00.000+0000",
      "healthScore": 71,
      "totalCount": 7,
      "goodCount": 5,
      "fairCount": 0,
      "badCount": 1,
      "entity": null
    },
    {
      "time": "2018-08-02T10:31:00.000+0000",
      "healthScore": 71,
      "totalCount": 7,
      "goodCount": 5,
      "fairCount": 0,
      "badCount": 1,
      "entity": null
    },
    {
      "time": "2018-08-02T10:36:00.000+0000",
      "healthScore": 71,
      "totalCount": 7,
      "goodCount": 5,
      "fairCount": 0,
      "badCount": 1,
      "entity": null
    },
    {
      "time": "2018-08-02T10:41:00.000+0000",
      "healthScore": 71,
      "totalCount": 7,
      "goodCount": 5,
      "fairCount": 0,
      "badCount": 1,
      "entity": null
    },
    {
      "time": "2018-08-02T10:46:00.000+0000",
      "healthScore": 71,
      "totalCount": 7,
      "goodCount": 5,
      "fairCount": 0,
      "badCount": 1,
      "entity": null
    },
    {
      "time": "2018-08-02T10:51:00.000+0000",
      "healthScore": 71,
      "totalCount": 7,
      "goodCount": 5,
      "fairCount": 0,
      "badCount": 1,
      "entity": null
    },
    {
      "time": "2018-08-02T10:56:00.000+0000",
      "healthScore": 71,
      "totalCount": 7,
      "goodCount": 5,
      "fairCount": 0,
      "badCount": 1,
      "entity": null
    },
    {
      "time": "2018-08-02T11:01:00.000+0000",
      "healthScore": 71,
      "totalCount": 7,
      "goodCount": 5,
      "fairCount": 0,
      "badCount": 1,
      "entity": null
    },
    {
      "time": "2018-08-02T11:06:00.000+0000",
      "healthScore": 71,
      "totalCount": 7,
      "goodCount": 5,
      "fairCount": 0,
      "badCount": 1,
      "entity": null
    },
    {
      "time": "2018-08-02T11:11:00.000+0000",
      "healthScore": 71,
      "totalCount": 7,
      "goodCount": 5,
      "fairCount": 0,
      "badCount": 1,
      "entity": null
    },
    {
      "time": "2018-08-02T11:16:00.000+0000",
      "healthScore": 57,
      "totalCount": 7,
      "goodCount": 4,
      "fairCount": 1,
      "badCount": 1,
      "entity": null
    },
    {
      "time": "2018-08-02T11:21:00.000+0000",
      "healthScore": 57,
      "totalCount": 7,
      "goodCount": 4,
      "fairCount": 1,
      "badCount": 1,
      "entity": null
    },
    {
      "time": "2018-08-02T11:26:00.000+0000",
      "healthScore": 57,
      "totalCount": 7,
      "goodCount": 4,
      "fairCount": 1,
      "badCount": 1,
      "entity": null
    },
    {
      "time": "2018-08-02T11:31:00.000+0000",
      "healthScore": 57,
      "totalCount": 7,
      "goodCount": 4,
      "fairCount": 1,
      "badCount": 1,
      "entity": null
    },
    {
      "time": "2018-08-02T11:36:00.000+0000",
      "healthScore": 57,
      "totalCount": 7,
      "goodCount": 4,
      "fairCount": 1,
      "badCount": 1,
      "entity": null
    },
    {
      "time": "2018-08-02T11:41:00.000+0000",
      "healthScore": 57,
      "totalCount": 7,
      "goodCount": 4,
      "fairCount": 1,
      "badCount": 1,
      "entity": null
    },
    {
      "time": "2018-08-02T11:46:00.000+0000",
      "healthScore": 57,
      "totalCount": 7,
      "goodCount": 4,
      "fairCount": 1,
      "badCount": 1,
      "entity": null
    },
    {
      "time": "2018-08-02T11:51:00.000+0000",
      "healthScore": 57,
      "totalCount": 7,
      "goodCount": 4,
      "fairCount": 1,
      "badCount": 1,
      "entity": null
    },
    {
      "time": "2018-08-02T11:56:00.000+0000",
      "healthScore": 57,
      "totalCount": 7,
      "goodCount": 4,
      "fairCount": 1,
      "badCount": 1,
      "entity": null
    },
    {
      "time": "2018-08-02T12:01:00.000+0000",
      "healthScore": 57,
      "totalCount": 7,
      "goodCount": 4,
      "fairCount": 1,
      "badCount": 1,
      "entity": null
    },
    {
      "time": "2018-08-02T12:06:00.000+0000",
      "healthScore": 57,
      "totalCount": 7,
      "goodCount": 4,
      "fairCount": 1,
      "badCount": 1,
      "entity": null
    },
    {
      "time": "2018-08-02T12:11:00.000+0000",
      "healthScore": 57,
      "totalCount": 7,
      "goodCount": 4,
      "fairCount": 1,
      "badCount": 1,
      "entity": null
    },
    {
      "time": "2018-08-02T12:16:00.000+0000",
      "healthScore": 57,
      "totalCount": 7,
      "goodCount": 4,
      "fairCount": 1,
      "badCount": 1,
      "entity": null
    },
    {
      "time": "2018-08-02T12:21:00.000+0000",
      "healthScore": 57,
      "totalCount": 7,
      "goodCount": 4,
      "fairCount": 1,
      "badCount": 1,
      "entity": null
    },
    {
      "time": "2018-08-02T12:26:00.000+0000",
      "healthScore": 57,
      "totalCount": 7,
      "goodCount": 4,
      "fairCount": 1,
      "badCount": 1,
      "entity": null
    },
    {
      "time": "2018-08-02T12:31:00.000+0000",
      "healthScore": 57,
      "totalCount": 7,
      "goodCount": 4,
      "fairCount": 1,
      "badCount": 1,
      "entity": null
    },
    {
      "time": "2018-08-02T12:36:00.000+0000",
      "healthScore": 57,
      "totalCount": 7,
      "goodCount": 4,
      "fairCount": 1,
      "badCount": 1,
      "entity": null
    },
    {
      "time": "2018-08-02T12:41:00.000+0000",
      "healthScore": 57,
      "totalCount": 7,
      "goodCount": 4,
      "fairCount": 1,
      "badCount": 1,
      "entity": null
    },
    {
      "time": "2018-08-02T12:46:00.000+0000",
      "healthScore": 57,
      "totalCount": 7,
      "goodCount": 4,
      "fairCount": 1,
      "badCount": 1,
      "entity": null
    },
    {
      "time": "2018-08-02T12:51:00.000+0000",
      "healthScore": 57,
      "totalCount": 7,
      "goodCount": 4,
      "fairCount": 1,
      "badCount": 1,
      "entity": null
    },
    {
      "time": "2018-08-02T12:56:00.000+0000",
      "healthScore": 57,
      "totalCount": 7,
      "goodCount": 4,
      "fairCount": 1,
      "badCount": 1,
      "entity": null
    },
    {
      "time": "2018-08-02T13:01:00.000+0000",
      "healthScore": 57,
      "totalCount": 7,
      "goodCount": 4,
      "fairCount": 1,
      "badCount": 1,
      "entity": null
    },
    {
      "time": "2018-08-02T13:06:00.000+0000",
      "healthScore": 71,
      "totalCount": 7,
      "goodCount": 5,
      "fairCount": 0,
      "badCount": 1,
      "entity": null
    },
    {
      "time": "2018-08-02T13:11:00.000+0000",
      "healthScore": 71,
      "totalCount": 7,
      "goodCount": 5,
      "fairCount": 0,
      "badCount": 1,
      "entity": null
    },
    {
      "time": "2018-08-02T13:16:00.000+0000",
      "healthScore": 57,
      "totalCount": 7,
      "goodCount": 4,
      "fairCount": 1,
      "badCount": 1,
      "entity": null
    },
    {
      "time": "2018-08-02T13:21:00.000+0000",
      "healthScore": 57,
      "totalCount": 7,
      "goodCount": 4,
      "fairCount": 1,
      "badCount": 1,
      "entity": null
    },
    {
      "time": "2018-08-02T13:26:00.000+0000",
      "healthScore": 57,
      "totalCount": 7,
      "goodCount": 4,
      "fairCount": 1,
      "badCount": 1,
      "entity": null
    },
    {
      "time": "2018-08-02T13:31:00.000+0000",
      "healthScore": 71,
      "totalCount": 7,
      "goodCount": 5,
      "fairCount": 0,
      "badCount": 1,
      "entity": null
    },
    {
      "time": "2018-08-02T13:36:00.000+0000",
      "healthScore": 71,
      "totalCount": 7,
      "goodCount": 5,
      "fairCount": 0,
      "badCount": 1,
      "entity": null
    },
    {
      "time": "2018-08-02T13:41:00.000+0000",
      "healthScore": 71,
      "totalCount": 7,
      "goodCount": 5,
      "fairCount": 0,
      "badCount": 1,
      "entity": null
    },
    {
      "time": "2018-08-02T13:46:00.000+0000",
      "healthScore": 71,
      "totalCount": 7,
      "goodCount": 5,
      "fairCount": 0,
      "badCount": 1,
      "entity": null
    },
    {
      "time": "2018-08-02T13:51:00.000+0000",
      "healthScore": 71,
      "totalCount": 7,
      "goodCount": 5,
      "fairCount": 0,
      "badCount": 1,
      "entity": null
    },
    {
      "time": "2018-08-02T13:56:00.000+0000",
      "healthScore": 71,
      "totalCount": 7,
      "goodCount": 5,
      "fairCount": 0,
      "badCount": 1,
      "entity": null
    },
    {
      "time": "2018-08-02T14:01:00.000+0000",
      "healthScore": 71,
      "totalCount": 7,
      "goodCount": 5,
      "fairCount": 0,
      "badCount": 1,
      "entity": null
    },
    {
      "time": "2018-08-02T14:06:00.000+0000",
      "healthScore": 71,
      "totalCount": 7,
      "goodCount": 5,
      "fairCount": 0,
      "badCount": 1,
      "entity": null
    },
    {
      "time": "2018-08-02T14:11:00.000+0000",
      "healthScore": 71,
      "totalCount": 7,
      "goodCount": 5,
      "fairCount": 0,
      "badCount": 1,
      "entity": null
    },
    {
      "time": "2018-08-02T14:16:00.000+0000",
      "healthScore": 71,
      "totalCount": 7,
      "goodCount": 5,
      "fairCount": 0,
      "badCount": 1,
      "entity": null
    },
    {
      "time": "2018-08-02T14:21:00.000+0000",
      "healthScore": 71,
      "totalCount": 7,
      "goodCount": 5,
      "fairCount": 0,
      "badCount": 1,
      "entity": null
    },
    {
      "time": "2018-08-02T14:26:00.000+0000",
      "healthScore": 71,
      "totalCount": 7,
      "goodCount": 5,
      "fairCount": 0,
      "badCount": 1,
      "entity": null
    },
    {
      "time": "2018-08-02T14:31:00.000+0000",
      "healthScore": 71,
      "totalCount": 7,
      "goodCount": 5,
      "fairCount": 0,
      "badCount": 1,
      "entity": null
    },
    {
      "time": "2018-08-02T14:36:00.000+0000",
      "healthScore": 71,
      "totalCount": 7,
      "goodCount": 5,
      "fairCount": 0,
      "badCount": 1,
      "entity": null
    },
    {
      "time": "2018-08-02T14:41:00.000+0000",
      "healthScore": 71,
      "totalCount": 7,
      "goodCount": 5,
      "fairCount": 0,
      "badCount": 1,
      "entity": null
    },
    {
      "time": "2018-08-02T14:46:00.000+0000",
      "healthScore": 71,
      "totalCount": 7,
      "goodCount": 5,
      "fairCount": 0,
      "badCount": 1,
      "entity": null
    },
    {
      "time": "2018-08-02T14:51:00.000+0000",
      "healthScore": 71,
      "totalCount": 7,
      "goodCount": 5,
      "fairCount": 0,
      "badCount": 1,
      "entity": null
    },
    {
      "time": "2018-08-02T14:56:00.000+0000",
      "healthScore": 57,
      "totalCount": 7,
      "goodCount": 4,
      "fairCount": 1,
      "badCount": 1,
      "entity": null
    },
    {
      "time": "2018-08-02T15:01:00.000+0000",
      "healthScore": 57,
      "totalCount": 7,
      "goodCount": 4,
      "fairCount": 1,
      "badCount": 1,
      "entity": null
    },
    {
      "time": "2018-08-02T15:06:00.000+0000",
      "healthScore": 57,
      "totalCount": 7,
      "goodCount": 4,
      "fairCount": 1,
      "badCount": 1,
      "entity": null
    },
    {
      "time": "2018-08-02T15:11:00.000+0000",
      "healthScore": 57,
      "totalCount": 7,
      "goodCount": 4,
      "fairCount": 1,
      "badCount": 1,
      "entity": null
    },
    {
      "time": "2018-08-02T15:16:00.000+0000",
      "healthScore": 57,
      "totalCount": 7,
      "goodCount": 4,
      "fairCount": 1,
      "badCount": 1,
      "entity": null
    },
    {
      "time": "2018-08-02T15:21:00.000+0000",
      "healthScore": 57,
      "totalCount": 7,
      "goodCount": 4,
      "fairCount": 1,
      "badCount": 1,
      "entity": null
    },
    {
      "time": "2018-08-02T15:26:00.000+0000",
      "healthScore": 57,
      "totalCount": 7,
      "goodCount": 4,
      "fairCount": 1,
      "badCount": 1,
      "entity": null
    },
    {
      "time": "2018-08-02T15:31:00.000+0000",
      "healthScore": 57,
      "totalCount": 7,
      "goodCount": 4,
      "fairCount": 1,
      "badCount": 1,
      "entity": null
    },
    {
      "time": "2018-08-02T15:36:00.000+0000",
      "healthScore": 57,
      "totalCount": 7,
      "goodCount": 4,
      "fairCount": 1,
      "badCount": 1,
      "entity": null
    },
    {
      "time": "2018-08-02T15:41:00.000+0000",
      "healthScore": 57,
      "totalCount": 7,
      "goodCount": 4,
      "fairCount": 1,
      "badCount": 1,
      "entity": null
    },
    {
      "time": "2018-08-02T15:46:00.000+0000",
      "healthScore": 57,
      "totalCount": 7,
      "goodCount": 4,
      "fairCount": 1,
      "badCount": 1,
      "entity": null
    },
    {
      "time": "2018-08-02T15:51:00.000+0000",
      "healthScore": 57,
      "totalCount": 7,
      "goodCount": 4,
      "fairCount": 1,
      "badCount": 1,
      "entity": null
    },
    {
      "time": "2018-08-02T15:56:00.000+0000",
      "healthScore": 57,
      "totalCount": 7,
      "goodCount": 4,
      "fairCount": 1,
      "badCount": 1,
      "entity": null
    },
    {
      "time": "2018-08-02T16:01:00.000+0000",
      "healthScore": 71,
      "totalCount": 7,
      "goodCount": 5,
      "fairCount": 0,
      "badCount": 1,
      "entity": null
    },
    {
      "time": "2018-08-02T16:06:00.000+0000",
      "healthScore": 57,
      "totalCount": 7,
      "goodCount": 4,
      "fairCount": 1,
      "badCount": 1,
      "entity": null
    },
    {
      "time": "2018-08-02T16:11:00.000+0000",
      "healthScore": 57,
      "totalCount": 7,
      "goodCount": 4,
      "fairCount": 1,
      "badCount": 1,
      "entity": null
    },
    {
      "time": "2018-08-02T16:16:00.000+0000",
      "healthScore": 71,
      "totalCount": 7,
      "goodCount": 5,
      "fairCount": 0,
      "badCount": 1,
      "entity": null
    },
    {
      "time": "2018-08-02T16:21:00.000+0000",
      "healthScore": 57,
      "totalCount": 7,
      "goodCount": 4,
      "fairCount": 1,
      "badCount": 1,
      "entity": null
    },
    {
      "time": "2018-08-02T16:26:00.000+0000",
      "healthScore": 71,
      "totalCount": 7,
      "goodCount": 5,
      "fairCount": 0,
      "badCount": 1,
      "entity": null
    },
    {
      "time": "2018-08-02T16:31:00.000+0000",
      "healthScore": 71,
      "totalCount": 7,
      "goodCount": 5,
      "fairCount": 0,
      "badCount": 1,
      "entity": null
    },
    {
      "time": "2018-08-02T16:36:00.000+0000",
      "healthScore": 71,
      "totalCount": 7,
      "goodCount": 5,
      "fairCount": 0,
      "badCount": 1,
      "entity": null
    },
    {
      "time": "2018-08-02T16:41:00.000+0000",
      "healthScore": 71,
      "totalCount": 7,
      "goodCount": 5,
      "fairCount": 0,
      "badCount": 1,
      "entity": null
    },
    {
      "time": "2018-08-02T16:46:00.000+0000",
      "healthScore": 71,
      "totalCount": 7,
      "goodCount": 5,
      "fairCount": 0,
      "badCount": 1,
      "entity": null
    },
    {
      "time": "2018-08-02T16:51:00.000+0000",
      "healthScore": 71,
      "totalCount": 7,
      "goodCount": 5,
      "fairCount": 0,
      "badCount": 1,
      "entity": null
    },
    {
      "time": "2018-08-02T16:56:00.000+0000",
      "healthScore": 57,
      "totalCount": 7,
      "goodCount": 4,
      "fairCount": 1,
      "badCount": 1,
      "entity": null
    },
    {
      "time": "2018-08-02T17:01:00.000+0000",
      "healthScore": 71,
      "totalCount": 7,
      "goodCount": 5,
      "fairCount": 0,
      "badCount": 1,
      "entity": null
    },
    {
      "time": "2018-08-02T17:06:00.000+0000",
      "healthScore": 71,
      "totalCount": 7,
      "goodCount": 5,
      "fairCount": 0,
      "badCount": 1,
      "entity": null
    },
    {
      "time": "2018-08-02T17:11:00.000+0000",
      "healthScore": 71,
      "totalCount": 7,
      "goodCount": 5,
      "fairCount": 0,
      "badCount": 1,
      "entity": null
    },
    {
      "time": "2018-08-02T17:16:00.000+0000",
      "healthScore": 71,
      "totalCount": 7,
      "goodCount": 5,
      "fairCount": 0,
      "badCount": 1,
      "entity": null
    },
    {
      "time": "2018-08-02T17:21:00.000+0000",
      "healthScore": 57,
      "totalCount": 7,
      "goodCount": 4,
      "fairCount": 1,
      "badCount": 1,
      "entity": null
    },
    {
      "time": "2018-08-02T17:26:00.000+0000",
      "healthScore": 57,
      "totalCount": 7,
      "goodCount": 4,
      "fairCount": 1,
      "badCount": 1,
      "entity": null
    },
    {
      "time": "2018-08-02T17:31:00.000+0000",
      "healthScore": 71,
      "totalCount": 7,
      "goodCount": 5,
      "fairCount": 0,
      "badCount": 1,
      "entity": null
    },
    {
      "time": "2018-08-02T17:36:00.000+0000",
      "healthScore": 71,
      "totalCount": 7,
      "goodCount": 5,
      "fairCount": 0,
      "badCount": 1,
      "entity": null
    },
    {
      "time": "2018-08-02T17:41:00.000+0000",
      "healthScore": 71,
      "totalCount": 7,
      "goodCount": 5,
      "fairCount": 0,
      "badCount": 1,
      "entity": null
    },
    {
      "time": "2018-08-02T17:46:00.000+0000",
      "healthScore": 71,
      "totalCount": 7,
      "goodCount": 5,
      "fairCount": 0,
      "badCount": 1,
      "entity": null
    },
    {
      "time": "2018-08-02T17:51:00.000+0000",
      "healthScore": 71,
      "totalCount": 7,
      "goodCount": 5,
      "fairCount": 0,
      "badCount": 1,
      "entity": null
    },
    {
      "time": "2018-08-02T17:56:00.000+0000",
      "healthScore": 71,
      "totalCount": 7,
      "goodCount": 5,
      "fairCount": 0,
      "badCount": 1,
      "entity": null
    },
    {
      "time": "2018-08-02T18:01:00.000+0000",
      "healthScore": 71,
      "totalCount": 7,
      "goodCount": 5,
      "fairCount": 0,
      "badCount": 1,
      "entity": null
    },
    {
      "time": "2018-08-02T18:06:00.000+0000",
      "healthScore": 71,
      "totalCount": 7,
      "goodCount": 5,
      "fairCount": 0,
      "badCount": 1,
      "entity": null
    },
    {
      "time": "2018-08-02T18:11:00.000+0000",
      "healthScore": 71,
      "totalCount": 7,
      "goodCount": 5,
      "fairCount": 0,
      "badCount": 1,
      "entity": null
    },
    {
      "time": "2018-08-02T18:16:00.000+0000",
      "healthScore": 71,
      "totalCount": 7,
      "goodCount": 5,
      "fairCount": 0,
      "badCount": 1,
      "entity": null
    },
    {
      "time": "2018-08-02T18:21:00.000+0000",
      "healthScore": 71,
      "totalCount": 7,
      "goodCount": 5,
      "fairCount": 0,
      "badCount": 1,
      "entity": null
    },
    {
      "time": "2018-08-02T18:26:00.000+0000",
      "healthScore": 71,
      "totalCount": 7,
      "goodCount": 5,
      "fairCount": 0,
      "badCount": 1,
      "entity": null
    },
    {
      "time": "2018-08-02T18:31:00.000+0000",
      "healthScore": 71,
      "totalCount": 7,
      "goodCount": 5,
      "fairCount": 0,
      "badCount": 1,
      "entity": null
    },
    {
      "time": "2018-08-02T18:36:00.000+0000",
      "healthScore": 71,
      "totalCount": 7,
      "goodCount": 5,
      "fairCount": 0,
      "badCount": 1,
      "entity": null
    },
    {
      "time": "2018-08-02T18:41:00.000+0000",
      "healthScore": 71,
      "totalCount": 7,
      "goodCount": 5,
      "fairCount": 0,
      "badCount": 1,
      "entity": null
    },
    {
      "time": "2018-08-02T18:46:00.000+0000",
      "healthScore": 71,
      "totalCount": 7,
      "goodCount": 5,
      "fairCount": 0,
      "badCount": 1,
      "entity": null
    },
    {
      "time": "2018-08-02T18:51:00.000+0000",
      "healthScore": 71,
      "totalCount": 7,
      "goodCount": 5,
      "fairCount": 0,
      "badCount": 1,
      "entity": null
    },
    {
      "time": "2018-08-02T18:56:00.000+0000",
      "healthScore": 71,
      "totalCount": 7,
      "goodCount": 5,
      "fairCount": 0,
      "badCount": 1,
      "entity": null
    },
    {
      "time": "2018-08-02T19:01:00.000+0000",
      "healthScore": 71,
      "totalCount": 7,
      "goodCount": 5,
      "fairCount": 0,
      "badCount": 1,
      "entity": null
    },
    {
      "time": "2018-08-02T19:06:00.000+0000",
      "healthScore": 71,
      "totalCount": 7,
      "goodCount": 5,
      "fairCount": 0,
      "badCount": 1,
      "entity": null
    },
    {
      "time": "2018-08-02T19:11:00.000+0000",
      "healthScore": 71,
      "totalCount": 7,
      "goodCount": 5,
      "fairCount": 0,
      "badCount": 1,
      "entity": null
    },
    {
      "time": "2018-08-02T19:16:00.000+0000",
      "healthScore": 71,
      "totalCount": 7,
      "goodCount": 5,
      "fairCount": 0,
      "badCount": 1,
      "entity": null
    },
    {
      "time": "2018-08-02T19:21:00.000+0000",
      "healthScore": 71,
      "totalCount": 7,
      "goodCount": 5,
      "fairCount": 0,
      "badCount": 1,
      "entity": null
    },
    {
      "time": "2018-08-02T19:26:00.000+0000",
      "healthScore": 71,
      "totalCount": 7,
      "goodCount": 5,
      "fairCount": 0,
      "badCount": 1,
      "entity": null
    },
    {
      "time": "2018-08-02T19:31:00.000+0000",
      "healthScore": 71,
      "totalCount": 7,
      "goodCount": 5,
      "fairCount": 0,
      "badCount": 1,
      "entity": null
    },
    {
      "time": "2018-08-02T19:36:00.000+0000",
      "healthScore": 71,
      "totalCount": 7,
      "goodCount": 5,
      "fairCount": 0,
      "badCount": 1,
      "entity": null
    },
    {
      "time": "2018-08-02T19:41:00.000+0000",
      "healthScore": 71,
      "totalCount": 7,
      "goodCount": 5,
      "fairCount": 0,
      "badCount": 1,
      "entity": null
    },
    {
      "time": "2018-08-02T19:46:00.000+0000",
      "healthScore": 71,
      "totalCount": 7,
      "goodCount": 5,
      "fairCount": 0,
      "badCount": 1,
      "entity": null
    },
    {
      "time": "2018-08-02T19:51:00.000+0000",
      "healthScore": 71,
      "totalCount": 7,
      "goodCount": 5,
      "fairCount": 0,
      "badCount": 1,
      "entity": null
    },
    {
      "time": "2018-08-02T19:56:00.000+0000",
      "healthScore": 71,
      "totalCount": 7,
      "goodCount": 5,
      "fairCount": 0,
      "badCount": 1,
      "entity": null
    },
    {
      "time": "2018-08-02T20:01:00.000+0000",
      "healthScore": 71,
      "totalCount": 7,
      "goodCount": 5,
      "fairCount": 0,
      "badCount": 1,
      "entity": null
    },
    {
      "time": "2018-08-02T20:06:00.000+0000",
      "healthScore": 71,
      "totalCount": 7,
      "goodCount": 5,
      "fairCount": 0,
      "badCount": 1,
      "entity": null
    },
    {
      "time": "2018-08-02T20:11:00.000+0000",
      "healthScore": 71,
      "totalCount": 7,
      "goodCount": 5,
      "fairCount": 0,
      "badCount": 1,
      "entity": null
    },
    {
      "time": "2018-08-02T20:16:00.000+0000",
      "healthScore": 71,
      "totalCount": 7,
      "goodCount": 5,
      "fairCount": 0,
      "badCount": 1,
      "entity": null
    },
    {
      "time": "2018-08-02T20:21:00.000+0000",
      "healthScore": 71,
      "totalCount": 7,
      "goodCount": 5,
      "fairCount": 0,
      "badCount": 1,
      "entity": null
    },
    {
      "time": "2018-08-02T20:26:00.000+0000",
      "healthScore": 57,
      "totalCount": 7,
      "goodCount": 4,
      "fairCount": 1,
      "badCount": 1,
      "entity": null
    },
    {
      "time": "2018-08-02T20:31:00.000+0000",
      "healthScore": 57,
      "totalCount": 7,
      "goodCount": 4,
      "fairCount": 1,
      "badCount": 1,
      "entity": null
    },
    {
      "time": "2018-08-02T20:36:00.000+0000",
      "healthScore": 71,
      "totalCount": 7,
      "goodCount": 5,
      "fairCount": 0,
      "badCount": 1,
      "entity": null
    },
    {
      "time": "2018-08-02T20:41:00.000+0000",
      "healthScore": 71,
      "totalCount": 7,
      "goodCount": 5,
      "fairCount": 0,
      "badCount": 1,
      "entity": null
    },
    {
      "time": "2018-08-02T20:46:00.000+0000",
      "healthScore": 71,
      "totalCount": 7,
      "goodCount": 5,
      "fairCount": 0,
      "badCount": 1,
      "entity": null
    },
    {
      "time": "2018-08-02T20:51:00.000+0000",
      "healthScore": 71,
      "totalCount": 7,
      "goodCount": 5,
      "fairCount": 0,
      "badCount": 1,
      "entity": null
    },
    {
      "time": "2018-08-02T20:56:00.000+0000",
      "healthScore": 71,
      "totalCount": 7,
      "goodCount": 5,
      "fairCount": 0,
      "badCount": 1,
      "entity": null
    },
    {
      "time": "2018-08-02T21:01:00.000+0000",
      "healthScore": 57,
      "totalCount": 7,
      "goodCount": 4,
      "fairCount": 1,
      "badCount": 1,
      "entity": null
    },
    {
      "time": "2018-08-02T21:06:00.000+0000",
      "healthScore": 71,
      "totalCount": 7,
      "goodCount": 5,
      "fairCount": 0,
      "badCount": 1,
      "entity": null
    },
    {
      "time": "2018-08-02T21:11:00.000+0000",
      "healthScore": 71,
      "totalCount": 7,
      "goodCount": 5,
      "fairCount": 0,
      "badCount": 1,
      "entity": null
    },
    {
      "time": "2018-08-02T21:16:00.000+0000",
      "healthScore": 71,
      "totalCount": 7,
      "goodCount": 5,
      "fairCount": 0,
      "badCount": 1,
      "entity": null
    },
    {
      "time": "2018-08-02T21:21:00.000+0000",
      "healthScore": 71,
      "totalCount": 7,
      "goodCount": 5,
      "fairCount": 0,
      "badCount": 1,
      "entity": null
    },
    {
      "time": "2018-08-02T21:26:00.000+0000",
      "healthScore": 71,
      "totalCount": 7,
      "goodCount": 5,
      "fairCount": 0,
      "badCount": 1,
      "entity": null
    },
    {
      "time": "2018-08-02T21:31:00.000+0000",
      "healthScore": 71,
      "totalCount": 7,
      "goodCount": 5,
      "fairCount": 0,
      "badCount": 1,
      "entity": null
    },
    {
      "time": "2018-08-02T21:36:00.000+0000",
      "healthScore": 57,
      "totalCount": 7,
      "goodCount": 4,
      "fairCount": 1,
      "badCount": 1,
      "entity": null
    },
    {
      "time": "2018-08-02T21:41:00.000+0000",
      "healthScore": 71,
      "totalCount": 7,
      "goodCount": 5,
      "fairCount": 0,
      "badCount": 1,
      "entity": null
    },
    {
      "time": "2018-08-02T21:46:00.000+0000",
      "healthScore": 71,
      "totalCount": 7,
      "goodCount": 5,
      "fairCount": 0,
      "badCount": 1,
      "entity": null
    },
    {
      "time": "2018-08-02T21:51:00.000+0000",
      "healthScore": 71,
      "totalCount": 7,
      "goodCount": 5,
      "fairCount": 0,
      "badCount": 1,
      "entity": null
    },
    {
      "time": "2018-08-02T21:56:00.000+0000",
      "healthScore": 71,
      "totalCount": 7,
      "goodCount": 5,
      "fairCount": 0,
      "badCount": 1,
      "entity": null
    },
    {
      "time": "2018-08-02T22:01:00.000+0000",
      "healthScore": 71,
      "totalCount": 7,
      "goodCount": 5,
      "fairCount": 0,
      "badCount": 1,
      "entity": null
    },
    {
      "time": "2018-08-02T22:06:00.000+0000",
      "healthScore": 71,
      "totalCount": 7,
      "goodCount": 5,
      "fairCount": 0,
      "badCount": 1,
      "entity": null
    },
    {
      "time": "2018-08-02T22:11:00.000+0000",
      "healthScore": 71,
      "totalCount": 7,
      "goodCount": 5,
      "fairCount": 0,
      "badCount": 1,
      "entity": null
    },
    {
      "time": "2018-08-02T22:16:00.000+0000",
      "healthScore": 71,
      "totalCount": 7,
      "goodCount": 5,
      "fairCount": 0,
      "badCount": 1,
      "entity": null
    },
    {
      "time": "2018-08-02T22:21:00.000+0000",
      "healthScore": 71,
      "totalCount": 7,
      "goodCount": 5,
      "fairCount": 0,
      "badCount": 1,
      "entity": null
    },
    {
      "time": "2018-08-02T22:26:00.000+0000",
      "healthScore": 71,
      "totalCount": 7,
      "goodCount": 5,
      "fairCount": 0,
      "badCount": 1,
      "entity": null
    },
    {
      "time": "2018-08-02T22:31:00.000+0000",
      "healthScore": 71,
      "totalCount": 7,
      "goodCount": 5,
      "fairCount": 0,
      "badCount": 1,
      "entity": null
    },
    {
      "time": "2018-08-02T22:36:00.000+0000",
      "healthScore": 71,
      "totalCount": 7,
      "goodCount": 5,
      "fairCount": 0,
      "badCount": 1,
      "entity": null
    },
    {
      "time": "2018-08-02T22:41:00.000+0000",
      "healthScore": 71,
      "totalCount": 7,
      "goodCount": 5,
      "fairCount": 0,
      "badCount": 1,
      "entity": null
    },
    {
      "time": "2018-08-02T22:46:00.000+0000",
      "healthScore": 71,
      "totalCount": 7,
      "goodCount": 5,
      "fairCount": 0,
      "badCount": 1,
      "entity": null
    },
    {
      "time": "2018-08-02T22:51:00.000+0000",
      "healthScore": 71,
      "totalCount": 7,
      "goodCount": 5,
      "fairCount": 0,
      "badCount": 1,
      "entity": null
    },
    {
      "time": "2018-08-02T22:56:00.000+0000",
      "healthScore": 71,
      "totalCount": 7,
      "goodCount": 5,
      "fairCount": 0,
      "badCount": 1,
      "entity": null
    },
    {
      "time": "2018-08-02T23:01:00.000+0000",
      "healthScore": 57,
      "totalCount": 7,
      "goodCount": 4,
      "fairCount": 1,
      "badCount": 1,
      "entity": null
    },
    {
      "time": "2018-08-02T23:06:00.000+0000",
      "healthScore": 57,
      "totalCount": 7,
      "goodCount": 4,
      "fairCount": 1,
      "badCount": 1,
      "entity": null
    },
    {
      "time": "2018-08-02T23:11:00.000+0000",
      "healthScore": 71,
      "totalCount": 7,
      "goodCount": 5,
      "fairCount": 0,
      "badCount": 1,
      "entity": null
    },
    {
      "time": "2018-08-02T23:16:00.000+0000",
      "healthScore": 75,
      "totalCount": 8,
      "goodCount": 6,
      "fairCount": 1,
      "badCount": 1,
      "entity": null
    },
    {
      "time": "2018-08-02T23:21:00.000+0000",
      "healthScore": 63,
      "totalCount": 8,
      "goodCount": 5,
      "fairCount": 2,
      "badCount": 1,
      "entity": null
    },
    {
      "time": "2018-08-02T23:26:00.000+0000",
      "healthScore": 50,
      "totalCount": 10,
      "goodCount": 5,
      "fairCount": 3,
      "badCount": 1,
      "entity": null
    },
    {
      "time": "2018-08-02T23:31:00.000+0000",
      "healthScore": 50,
      "totalCount": 10,
      "goodCount": 5,
      "fairCount": 4,
      "badCount": 1,
      "entity": null
    },
    {
      "time": "2018-08-02T23:36:00.000+0000",
      "healthScore": 40,
      "totalCount": 10,
      "goodCount": 4,
      "fairCount": 5,
      "badCount": 1,
      "entity": null
    },
    {
      "time": "2018-08-02T23:41:00.000+0000",
      "healthScore": 40,
      "totalCount": 10,
      "goodCount": 4,
      "fairCount": 5,
      "badCount": 1,
      "entity": null
    },
    {
      "time": "2018-08-02T23:46:00.000+0000",
      "healthScore": 40,
      "totalCount": 10,
      "goodCount": 4,
      "fairCount": 5,
      "badCount": 1,
      "entity": null
    },
    {
      "time": "2018-08-02T23:51:00.000+0000",
      "healthScore": 45,
      "totalCount": 11,
      "goodCount": 5,
      "fairCount": 5,
      "badCount": 0,
      "entity": null
    },
    {
      "time": "2018-08-02T23:56:00.000+0000",
      "healthScore": 36,
      "totalCount": 11,
      "goodCount": 4,
      "fairCount": 6,
      "badCount": 0,
      "entity": null
    },
    {
      "time": "2018-08-03T00:01:00.000+0000",
      "healthScore": 36,
      "totalCount": 11,
      "goodCount": 4,
      "fairCount": 5,
      "badCount": 1,
      "entity": null
    },
    {
      "time": "2018-08-03T00:06:00.000+0000",
      "healthScore": 36,
      "totalCount": 11,
      "goodCount": 4,
      "fairCount": 5,
      "badCount": 1,
      "entity": null
    },
    {
      "time": "2018-08-03T00:11:00.000+0000",
      "healthScore": 36,
      "totalCount": 11,
      "goodCount": 4,
      "fairCount": 4,
      "badCount": 2,
      "entity": null
    },
    {
      "time": "2018-08-03T00:16:00.000+0000",
      "healthScore": 36,
      "totalCount": 11,
      "goodCount": 4,
      "fairCount": 5,
      "badCount": 1,
      "entity": null
    },
    {
      "time": "2018-08-03T00:21:00.000+0000",
      "healthScore": 36,
      "totalCount": 11,
      "goodCount": 4,
      "fairCount": 5,
      "badCount": 1,
      "entity": null
    },
    {
      "time": "2018-08-03T00:26:00.000+0000",
      "healthScore": 36,
      "totalCount": 11,
      "goodCount": 4,
      "fairCount": 5,
      "badCount": 1,
      "entity": null
    },
    {
      "time": "2018-08-03T00:31:00.000+0000",
      "healthScore": 64,
      "totalCount": 11,
      "goodCount": 7,
      "fairCount": 2,
      "badCount": 1,
      "entity": null
    },
    {
      "time": "2018-08-03T00:36:00.000+0000",
      "healthScore": 73,
      "totalCount": 11,
      "goodCount": 8,
      "fairCount": 1,
      "badCount": 1,
      "entity": null
    },
    {
      "time": "2018-08-03T00:41:00.000+0000",
      "healthScore": 64,
      "totalCount": 11,
      "goodCount": 7,
      "fairCount": 2,
      "badCount": 1,
      "entity": null
    },
    {
      "time": "2018-08-03T00:46:00.000+0000",
      "healthScore": 64,
      "totalCount": 11,
      "goodCount": 7,
      "fairCount": 1,
      "badCount": 2,
      "entity": null
    },
    {
      "time": "2018-08-03T00:51:00.000+0000",
      "healthScore": 55,
      "totalCount": 11,
      "goodCount": 6,
      "fairCount": 2,
      "badCount": 2,
      "entity": null
    },
    {
      "time": "2018-08-03T00:56:00.000+0000",
      "healthScore": 55,
      "totalCount": 11,
      "goodCount": 6,
      "fairCount": 2,
      "badCount": 2,
      "entity": null
    },
    {
      "time": "2018-08-03T01:01:00.000+0000",
      "healthScore": 64,
      "totalCount": 11,
      "goodCount": 7,
      "fairCount": 1,
      "badCount": 2,
      "entity": null
    },
    {
      "time": "2018-08-03T01:06:00.000+0000",
      "healthScore": 55,
      "totalCount": 11,
      "goodCount": 6,
      "fairCount": 2,
      "badCount": 2,
      "entity": null
    },
    {
      "time": "2018-08-03T01:11:00.000+0000",
      "healthScore": 45,
      "totalCount": 11,
      "goodCount": 5,
      "fairCount": 3,
      "badCount": 2,
      "entity": null
    },
    {
      "time": "2018-08-03T01:16:00.000+0000",
      "healthScore": 64,
      "totalCount": 11,
      "goodCount": 7,
      "fairCount": 1,
      "badCount": 2,
      "entity": null
    },
    {
      "time": "2018-08-03T01:21:00.000+0000",
      "healthScore": 55,
      "totalCount": 11,
      "goodCount": 6,
      "fairCount": 2,
      "badCount": 2,
      "entity": null
    },
    {
      "time": "2018-08-03T01:26:00.000+0000",
      "healthScore": 55,
      "totalCount": 11,
      "goodCount": 6,
      "fairCount": 2,
      "badCount": 2,
      "entity": null
    },
    {
      "time": "2018-08-03T01:31:00.000+0000",
      "healthScore": 55,
      "totalCount": 11,
      "goodCount": 6,
      "fairCount": 2,
      "badCount": 2,
      "entity": null
    },
    {
      "time": "2018-08-03T01:36:00.000+0000",
      "healthScore": 55,
      "totalCount": 11,
      "goodCount": 6,
      "fairCount": 2,
      "badCount": 2,
      "entity": null
    },
    {
      "time": "2018-08-03T01:41:00.000+0000",
      "healthScore": 55,
      "totalCount": 11,
      "goodCount": 6,
      "fairCount": 2,
      "badCount": 2,
      "entity": null
    },
    {
      "time": "2018-08-03T01:46:00.000+0000",
      "healthScore": 45,
      "totalCount": 11,
      "goodCount": 5,
      "fairCount": 3,
      "badCount": 2,
      "entity": null
    },
    {
      "time": "2018-08-03T01:51:00.000+0000",
      "healthScore": 45,
      "totalCount": 11,
      "goodCount": 5,
      "fairCount": 3,
      "badCount": 2,
      "entity": null
    },
    {
      "time": "2018-08-03T01:56:00.000+0000",
      "healthScore": 45,
      "totalCount": 11,
      "goodCount": 5,
      "fairCount": 3,
      "badCount": 2,
      "entity": null
    },
    {
      "time": "2018-08-03T02:01:00.000+0000",
      "healthScore": 45,
      "totalCount": 11,
      "goodCount": 5,
      "fairCount": 3,
      "badCount": 2,
      "entity": null
    },
    {
      "time": "2018-08-03T02:06:00.000+0000",
      "healthScore": 45,
      "totalCount": 11,
      "goodCount": 5,
      "fairCount": 3,
      "badCount": 2,
      "entity": null
    },
    {
      "time": "2018-08-03T02:11:00.000+0000",
      "healthScore": 45,
      "totalCount": 11,
      "goodCount": 5,
      "fairCount": 3,
      "badCount": 2,
      "entity": null
    },
    {
      "time": "2018-08-03T02:16:00.000+0000",
      "healthScore": 64,
      "totalCount": 11,
      "goodCount": 7,
      "fairCount": 1,
      "badCount": 2,
      "entity": null
    },
    {
      "time": "2018-08-03T02:21:00.000+0000",
      "healthScore": 55,
      "totalCount": 11,
      "goodCount": 6,
      "fairCount": 2,
      "badCount": 2,
      "entity": null
    },
    {
      "time": "2018-08-03T02:26:00.000+0000",
      "healthScore": 55,
      "totalCount": 11,
      "goodCount": 6,
      "fairCount": 2,
      "badCount": 2,
      "entity": null
    },
    {
      "time": "2018-08-03T02:31:00.000+0000",
      "healthScore": 55,
      "totalCount": 11,
      "goodCount": 6,
      "fairCount": 2,
      "badCount": 2,
      "entity": null
    },
    {
      "time": "2018-08-03T02:36:00.000+0000",
      "healthScore": 55,
      "totalCount": 11,
      "goodCount": 6,
      "fairCount": 2,
      "badCount": 2,
      "entity": null
    },
    {
      "time": "2018-08-03T02:41:00.000+0000",
      "healthScore": 45,
      "totalCount": 11,
      "goodCount": 5,
      "fairCount": 3,
      "badCount": 2,
      "entity": null
    },
    {
      "time": "2018-08-03T02:46:00.000+0000",
      "healthScore": 45,
      "totalCount": 11,
      "goodCount": 5,
      "fairCount": 3,
      "badCount": 2,
      "entity": null
    },
    {
      "time": "2018-08-03T02:51:00.000+0000",
      "healthScore": 45,
      "totalCount": 11,
      "goodCount": 5,
      "fairCount": 3,
      "badCount": 2,
      "entity": null
    },
    {
      "time": "2018-08-03T02:56:00.000+0000",
      "healthScore": 45,
      "totalCount": 11,
      "goodCount": 5,
      "fairCount": 3,
      "badCount": 2,
      "entity": null
    },
    {
      "time": "2018-08-03T03:01:00.000+0000",
      "healthScore": 55,
      "totalCount": 11,
      "goodCount": 6,
      "fairCount": 2,
      "badCount": 2,
      "entity": null
    },
    {
      "time": "2018-08-03T03:06:00.000+0000",
      "healthScore": 64,
      "totalCount": 11,
      "goodCount": 7,
      "fairCount": 1,
      "badCount": 2,
      "entity": null
    },
    {
      "time": "2018-08-03T03:11:00.000+0000",
      "healthScore": 64,
      "totalCount": 11,
      "goodCount": 7,
      "fairCount": 1,
      "badCount": 2,
      "entity": null
    },
    {
      "time": "2018-08-03T03:16:00.000+0000",
      "healthScore": 64,
      "totalCount": 11,
      "goodCount": 7,
      "fairCount": 1,
      "badCount": 2,
      "entity": null
    },
    {
      "time": "2018-08-03T03:21:00.000+0000",
      "healthScore": 55,
      "totalCount": 11,
      "goodCount": 6,
      "fairCount": 2,
      "badCount": 2,
      "entity": null
    },
    {
      "time": "2018-08-03T03:26:00.000+0000",
      "healthScore": 55,
      "totalCount": 11,
      "goodCount": 6,
      "fairCount": 3,
      "badCount": 1,
      "entity": null
    },
    {
      "time": "2018-08-03T03:31:00.000+0000",
      "healthScore": 55,
      "totalCount": 11,
      "goodCount": 6,
      "fairCount": 3,
      "badCount": 1,
      "entity": null
    },
    {
      "time": "2018-08-03T03:36:00.000+0000",
      "healthScore": 64,
      "totalCount": 11,
      "goodCount": 7,
      "fairCount": 2,
      "badCount": 1,
      "entity": null
    },
    {
      "time": "2018-08-03T03:41:00.000+0000",
      "healthScore": 64,
      "totalCount": 11,
      "goodCount": 7,
      "fairCount": 2,
      "badCount": 1,
      "entity": null
    },
    {
      "time": "2018-08-03T03:46:00.000+0000",
      "healthScore": 64,
      "totalCount": 11,
      "goodCount": 7,
      "fairCount": 2,
      "badCount": 1,
      "entity": null
    },
    {
      "time": "2018-08-03T03:51:00.000+0000",
      "healthScore": 64,
      "totalCount": 11,
      "goodCount": 7,
      "fairCount": 2,
      "badCount": 1,
      "entity": null
    },
    {
      "time": "2018-08-03T03:56:00.000+0000",
      "healthScore": 64,
      "totalCount": 11,
      "goodCount": 7,
      "fairCount": 2,
      "badCount": 1,
      "entity": null
    },
    {
      "time": "2018-08-03T04:01:00.000+0000",
      "healthScore": 55,
      "totalCount": 11,
      "goodCount": 6,
      "fairCount": 3,
      "badCount": 1,
      "entity": null
    },
    {
      "time": "2018-08-03T04:06:00.000+0000",
      "healthScore": 55,
      "totalCount": 11,
      "goodCount": 6,
      "fairCount": 3,
      "badCount": 1,
      "entity": null
    },
    {
      "time": "2018-08-03T04:11:00.000+0000",
      "healthScore": 64,
      "totalCount": 11,
      "goodCount": 7,
      "fairCount": 2,
      "badCount": 1,
      "entity": null
    },
    {
      "time": "2018-08-03T04:16:00.000+0000",
      "healthScore": 64,
      "totalCount": 11,
      "goodCount": 7,
      "fairCount": 2,
      "badCount": 1,
      "entity": null
    },
    {
      "time": "2018-08-03T04:21:00.000+0000",
      "healthScore": 64,
      "totalCount": 11,
      "goodCount": 7,
      "fairCount": 2,
      "badCount": 1,
      "entity": null
    },
    {
      "time": "2018-08-03T04:26:00.000+0000",
      "healthScore": 64,
      "totalCount": 11,
      "goodCount": 7,
      "fairCount": 2,
      "badCount": 1,
      "entity": null
    },
    {
      "time": "2018-08-03T04:31:00.000+0000",
      "healthScore": 64,
      "totalCount": 11,
      "goodCount": 7,
      "fairCount": 2,
      "badCount": 1,
      "entity": null
    },
    {
      "time": "2018-08-03T04:36:00.000+0000",
      "healthScore": 64,
      "totalCount": 11,
      "goodCount": 7,
      "fairCount": 2,
      "badCount": 1,
      "entity": null
    },
    {
      "time": "2018-08-03T04:41:00.000+0000",
      "healthScore": 64,
      "totalCount": 11,
      "goodCount": 7,
      "fairCount": 2,
      "badCount": 1,
      "entity": null
    },
    {
      "time": "2018-08-03T04:46:00.000+0000",
      "healthScore": 64,
      "totalCount": 11,
      "goodCount": 7,
      "fairCount": 2,
      "badCount": 1,
      "entity": null
    },
    {
      "time": "2018-08-03T04:51:00.000+0000",
      "healthScore": 64,
      "totalCount": 11,
      "goodCount": 7,
      "fairCount": 2,
      "badCount": 1,
      "entity": null
    },
    {
      "time": "2018-08-03T04:56:00.000+0000",
      "healthScore": 64,
      "totalCount": 11,
      "goodCount": 7,
      "fairCount": 2,
      "badCount": 1,
      "entity": null
    },
    {
      "time": "2018-08-03T05:01:00.000+0000",
      "healthScore": 64,
      "totalCount": 11,
      "goodCount": 7,
      "fairCount": 2,
      "badCount": 1,
      "entity": null
    },
    {
      "time": "2018-08-03T05:06:00.000+0000",
      "healthScore": 55,
      "totalCount": 11,
      "goodCount": 6,
      "fairCount": 3,
      "badCount": 1,
      "entity": null
    },
    {
      "time": "2018-08-03T05:11:00.000+0000",
      "healthScore": 55,
      "totalCount": 11,
      "goodCount": 6,
      "fairCount": 3,
      "badCount": 1,
      "entity": null
    },
    {
      "time": "2018-08-03T05:16:00.000+0000",
      "healthScore": 64,
      "totalCount": 11,
      "goodCount": 7,
      "fairCount": 2,
      "badCount": 1,
      "entity": null
    },
    {
      "time": "2018-08-03T05:21:00.000+0000",
      "healthScore": 64,
      "totalCount": 11,
      "goodCount": 7,
      "fairCount": 2,
      "badCount": 1,
      "entity": null
    },
    {
      "time": "2018-08-03T05:26:00.000+0000",
      "healthScore": 64,
      "totalCount": 11,
      "goodCount": 7,
      "fairCount": 2,
      "badCount": 1,
      "entity": null
    },
    {
      "time": "2018-08-03T05:31:00.000+0000",
      "healthScore": 64,
      "totalCount": 11,
      "goodCount": 7,
      "fairCount": 2,
      "badCount": 1,
      "entity": null
    },
    {
      "time": "2018-08-03T05:36:00.000+0000",
      "healthScore": 64,
      "totalCount": 11,
      "goodCount": 7,
      "fairCount": 2,
      "badCount": 1,
      "entity": null
    },
    {
      "time": "2018-08-03T05:41:00.000+0000",
      "healthScore": 55,
      "totalCount": 11,
      "goodCount": 6,
      "fairCount": 2,
      "badCount": 2,
      "entity": null
    },
    {
      "time": "2018-08-03T05:46:00.000+0000",
      "healthScore": 64,
      "totalCount": 11,
      "goodCount": 7,
      "fairCount": 2,
      "badCount": 1,
      "entity": null
    },
    {
      "time": "2018-08-03T05:51:00.000+0000",
      "healthScore": 64,
      "totalCount": 11,
      "goodCount": 7,
      "fairCount": 2,
      "badCount": 1,
      "entity": null
    },
    {
      "time": "2018-08-03T05:56:00.000+0000",
      "healthScore": 64,
      "totalCount": 11,
      "goodCount": 7,
      "fairCount": 2,
      "badCount": 1,
      "entity": null
    },
    {
      "time": "2018-08-03T06:01:00.000+0000",
      "healthScore": 64,
      "totalCount": 11,
      "goodCount": 7,
      "fairCount": 2,
      "badCount": 1,
      "entity": null
    },
    {
      "time": "2018-08-03T06:06:00.000+0000",
      "healthScore": 64,
      "totalCount": 11,
      "goodCount": 7,
      "fairCount": 2,
      "badCount": 1,
      "entity": null
    },
    {
      "time": "2018-08-03T06:11:00.000+0000",
      "healthScore": 64,
      "totalCount": 11,
      "goodCount": 7,
      "fairCount": 2,
      "badCount": 1,
      "entity": null
    },
    {
      "time": "2018-08-03T06:16:00.000+0000",
      "healthScore": 55,
      "totalCount": 11,
      "goodCount": 6,
      "fairCount": 3,
      "badCount": 1,
      "entity": null
    },
    {
      "time": "2018-08-03T06:21:00.000+0000",
      "healthScore": 55,
      "totalCount": 11,
      "goodCount": 6,
      "fairCount": 3,
      "badCount": 1,
      "entity": null
    },
    {
      "time": "2018-08-03T06:26:00.000+0000",
      "healthScore": 55,
      "totalCount": 11,
      "goodCount": 6,
      "fairCount": 3,
      "badCount": 1,
      "entity": null
    },
    {
      "time": "2018-08-03T06:31:00.000+0000",
      "healthScore": 55,
      "totalCount": 11,
      "goodCount": 6,
      "fairCount": 3,
      "badCount": 1,
      "entity": null
    },
    {
      "time": "2018-08-03T06:36:00.000+0000",
      "healthScore": 55,
      "totalCount": 11,
      "goodCount": 6,
      "fairCount": 3,
      "badCount": 1,
      "entity": null
    },
    {
      "time": "2018-08-03T06:41:00.000+0000",
      "healthScore": 55,
      "totalCount": 11,
      "goodCount": 6,
      "fairCount": 3,
      "badCount": 1,
      "entity": null
    },
    {
      "time": "2018-08-03T06:46:00.000+0000",
      "healthScore": 55,
      "totalCount": 11,
      "goodCount": 6,
      "fairCount": 3,
      "badCount": 1,
      "entity": null
    },
    {
      "time": "2018-08-03T06:51:00.000+0000",
      "healthScore": 55,
      "totalCount": 11,
      "goodCount": 6,
      "fairCount": 3,
      "badCount": 1,
      "entity": null
    },
    {
      "time": "2018-08-03T06:56:00.000+0000",
      "healthScore": 55,
      "totalCount": 11,
      "goodCount": 6,
      "fairCount": 3,
      "badCount": 1,
      "entity": null
    },
    {
      "time": "2018-08-03T07:01:00.000+0000",
      "healthScore": 55,
      "totalCount": 11,
      "goodCount": 6,
      "fairCount": 3,
      "badCount": 1,
      "entity": null
    },
    {
      "time": "2018-08-03T07:06:00.000+0000",
      "healthScore": 55,
      "totalCount": 11,
      "goodCount": 6,
      "fairCount": 3,
      "badCount": 1,
      "entity": null
    },
    {
      "time": "2018-08-03T07:11:00.000+0000",
      "healthScore": 55,
      "totalCount": 11,
      "goodCount": 6,
      "fairCount": 3,
      "badCount": 1,
      "entity": null
    },
    {
      "time": "2018-08-03T07:16:00.000+0000",
      "healthScore": 55,
      "totalCount": 11,
      "goodCount": 6,
      "fairCount": 3,
      "badCount": 1,
      "entity": null
    },
    {
      "time": "2018-08-03T07:21:00.000+0000",
      "healthScore": 45,
      "totalCount": 11,
      "goodCount": 5,
      "fairCount": 4,
      "badCount": 1,
      "entity": null
    },
    {
      "time": "2018-08-03T07:26:00.000+0000",
      "healthScore": 55,
      "totalCount": 11,
      "goodCount": 6,
      "fairCount": 3,
      "badCount": 1,
      "entity": null
    },
    {
      "time": "2018-08-03T07:31:00.000+0000",
      "healthScore": 55,
      "totalCount": 11,
      "goodCount": 6,
      "fairCount": 3,
      "badCount": 1,
      "entity": null
    },
    {
      "time": "2018-08-03T07:36:00.000+0000",
      "healthScore": 55,
      "totalCount": 11,
      "goodCount": 6,
      "fairCount": 3,
      "badCount": 1,
      "entity": null
    },
    {
      "time": "2018-08-03T07:41:00.000+0000",
      "healthScore": 45,
      "totalCount": 11,
      "goodCount": 5,
      "fairCount": 3,
      "badCount": 2,
      "entity": null
    },
    {
      "time": "2018-08-03T07:46:00.000+0000",
      "healthScore": 55,
      "totalCount": 11,
      "goodCount": 6,
      "fairCount": 3,
      "badCount": 1,
      "entity": null
    },
    {
      "time": "2018-08-03T07:51:00.000+0000",
      "healthScore": 55,
      "totalCount": 11,
      "goodCount": 6,
      "fairCount": 3,
      "badCount": 1,
      "entity": null
    },
    {
      "time": "2018-08-03T07:56:00.000+0000",
      "healthScore": 55,
      "totalCount": 11,
      "goodCount": 6,
      "fairCount": 3,
      "badCount": 1,
      "entity": null
    },
    {
      "time": "2018-08-03T08:01:00.000+0000",
      "healthScore": 55,
      "totalCount": 11,
      "goodCount": 6,
      "fairCount": 3,
      "badCount": 1,
      "entity": null
    },
    {
      "time": "2018-08-03T08:06:00.000+0000",
      "healthScore": 55,
      "totalCount": 11,
      "goodCount": 6,
      "fairCount": 3,
      "badCount": 1,
      "entity": null
    },
    {
      "time": "2018-08-03T08:11:00.000+0000",
      "healthScore": 55,
      "totalCount": 11,
      "goodCount": 6,
      "fairCount": 3,
      "badCount": 1,
      "entity": null
    },
    {
      "time": "2018-08-03T08:16:00.000+0000",
      "healthScore": 55,
      "totalCount": 11,
      "goodCount": 6,
      "fairCount": 3,
      "badCount": 1,
      "entity": null
    },
    {
      "time": "2018-08-03T08:21:00.000+0000",
      "healthScore": 55,
      "totalCount": 11,
      "goodCount": 6,
      "fairCount": 3,
      "badCount": 1,
      "entity": null
    },
    {
      "time": "2018-08-03T08:26:00.000+0000",
      "healthScore": 55,
      "totalCount": 11,
      "goodCount": 6,
      "fairCount": 3,
      "badCount": 1,
      "entity": null
    },
    {
      "time": "2018-08-03T08:31:00.000+0000",
      "healthScore": 55,
      "totalCount": 11,
      "goodCount": 6,
      "fairCount": 3,
      "badCount": 1,
      "entity": null
    },
    {
      "time": "2018-08-03T08:36:00.000+0000",
      "healthScore": 55,
      "totalCount": 11,
      "goodCount": 6,
      "fairCount": 3,
      "badCount": 1,
      "entity": null
    },
    {
      "time": "2018-08-03T08:41:00.000+0000",
      "healthScore": 55,
      "totalCount": 11,
      "goodCount": 6,
      "fairCount": 3,
      "badCount": 1,
      "entity": null
    },
    {
      "time": "2018-08-03T08:46:00.000+0000",
      "healthScore": 55,
      "totalCount": 11,
      "goodCount": 6,
      "fairCount": 3,
      "badCount": 1,
      "entity": null
    },
    {
      "time": "2018-08-03T08:51:00.000+0000",
      "healthScore": 55,
      "totalCount": 11,
      "goodCount": 6,
      "fairCount": 3,
      "badCount": 1,
      "entity": null
    },
    {
      "time": "2018-08-03T08:56:00.000+0000",
      "healthScore": 64,
      "totalCount": 11,
      "goodCount": 7,
      "fairCount": 2,
      "badCount": 1,
      "entity": null
    },
    {
      "time": "2018-08-03T09:01:00.000+0000",
      "healthScore": 64,
      "totalCount": 11,
      "goodCount": 7,
      "fairCount": 2,
      "badCount": 1,
      "entity": null
    },
    {
      "time": "2018-08-03T09:06:00.000+0000",
      "healthScore": 64,
      "totalCount": 11,
      "goodCount": 7,
      "fairCount": 2,
      "badCount": 1,
      "entity": null
    },
    {
      "time": "2018-08-03T09:11:00.000+0000",
      "healthScore": 55,
      "totalCount": 11,
      "goodCount": 6,
      "fairCount": 3,
      "badCount": 1,
      "entity": null
    },
    {
      "time": "2018-08-03T09:16:00.000+0000",
      "healthScore": 55,
      "totalCount": 11,
      "goodCount": 6,
      "fairCount": 3,
      "badCount": 1,
      "entity": null
    },
    {
      "time": "2018-08-03T09:21:00.000+0000",
      "healthScore": 64,
      "totalCount": 11,
      "goodCount": 7,
      "fairCount": 2,
      "badCount": 1,
      "entity": null
    },
    {
      "time": "2018-08-03T09:26:00.000+0000",
      "healthScore": 64,
      "totalCount": 11,
      "goodCount": 7,
      "fairCount": 2,
      "badCount": 1,
      "entity": null
    },
    {
      "time": "2018-08-03T09:31:00.000+0000",
      "healthScore": 55,
      "totalCount": 11,
      "goodCount": 6,
      "fairCount": 3,
      "badCount": 1,
      "entity": null
    },
    {
      "time": "2018-08-03T09:36:00.000+0000",
      "healthScore": 64,
      "totalCount": 11,
      "goodCount": 7,
      "fairCount": 2,
      "badCount": 1,
      "entity": null
    },
    {
      "time": "2018-08-03T09:41:00.000+0000",
      "healthScore": 64,
      "totalCount": 11,
      "goodCount": 7,
      "fairCount": 2,
      "badCount": 1,
      "entity": null
    },

    {
      "time": "2018-08-03T09:16:00.000+0000",
      "healthScore": 55,
      "totalCount": 11,
      "goodCount": 6,
      "fairCount": 3,
      "badCount": 1,
      "entity": null
    },
    {
      "time": "2018-08-03T09:21:00.000+0000",
      "healthScore": 64,
      "totalCount": 11,
      "goodCount": 7,
      "fairCount": 2,
      "badCount": 1,
      "entity": null
    },
    {
      "time": "2018-08-03T09:26:00.000+0000",
      "healthScore": 64,
      "totalCount": 11,
      "goodCount": 7,
      "fairCount": 2,
      "badCount": 1,
      "entity": null
    },
    {
      "time": "2018-08-03T09:31:00.000+0000",
      "healthScore": 55,
      "totalCount": 11,
      "goodCount": 6,
      "fairCount": 3,
      "badCount": 1,
      "entity": null
    },
    {
      "time": "2018-08-03T09:36:00.000+0000",
      "healthScore": 64,
      "totalCount": 11,
      "goodCount": 7,
      "fairCount": 2,
      "badCount": 1,
      "entity": null
    },
    {
      "time": "2018-08-03T09:41:00.000+0000",
      "healthScore": 64,
      "totalCount": 11,
      "goodCount": 7,
      "fairCount": 2,
      "badCount": 1,
      "entity": null
    }

  ],
  "measuredBy": "global",
  "latestMeasuredByEntity": null,
  "latestHealthScore": 0,
  "monitoredDevices":0,
  "monitoredHealthyDevices": 0,
  "monitoredUnHealthyDevices": 0,
  "unMonitoredDevices": 0,
  "healthDistirubution": []
}
}
)