define({
"Template":
{
  "version": "1.0",
  "response": {
    "id": "78a0db69-0fde-4552-b786-435bf3f2cc51",
    "name": "EIGRP_Peering",
    "enabled": true,
    "flattened": true,
    "entityType": "network_device",
    "entityName": "Unknown",
    "entity": "10.30.255.102",
    "groupBy": "Unknown",
    "category": "Connectivity",
    "severity": "HIGH",
    "summary": "EIGRP Adjacency Failed on Device DeviceIPPlaceHolder Interface InterfaceDownPlaceHolder with Neighbor 10.30.253.3",
    "groupId": "interface_connectivity_failure_grouping",
    "groupName": "Network Connectivity Failures on Network Device",
    "scope": "GLOBAL",
    "priority": "P2",
    "rootCause": "EIGRP_PEERING",
    "timestamp": 1583854318415,
    "description": "EIGRP adjacency failed on device name:'DeviceNamePlaceHolder', interface:'InterfaceDownPlaceHolder' at site:'SJCFloor1' with neighbor '10.30.253.3'",
    "suggestions": [
      {
        "message": "Ping the neighbor IP to verify connectivity.",
        "steps": [
          {
            "entityId": "DeviceIdPlaceHolder",
            "description": "ping neighbor IP",
            "command": "ping 10.30.253.3",
            "stepType": "command-Runner"
          }
        ]
      },
      {
        "message": "Check EIGRP neighbors.",
        "steps": [
          {
            "entityId": "DeviceIdPlaceHolder",
            "description": "Check EIGRP neighbors",
            "command": "show ip eigrp neighbors",
            "stepType": "command-Runner"
          }
        ]
      },
      {
        "message": "Verify if there are any K-Values Mismatch",
        "steps": [
          {
            "entityId": "DeviceIdPlaceHolder",
            "description": "Check for K-Values",
            "command": "show run | sec eigrp",
            "stepType": "command-Runner"
          }
        ]
      },
      {
        "message": "Verify the MTU on interface connecting to neighbor",
        "steps": [
          {
            "entityId": "DeviceIdPlaceHolder",
            "description": "Check the interface MTU",
            "command": "show ip interface InterfaceDownPlaceHolder | in MTU",
            "stepType": "command-Runner"
          }
        ]
      },
      {
        "message": "Contact Cisco TAC World Wide – https://www.cisco.com/c/en/us/support/web/tsd-cisco-worldwide-contacts.html"
      }
    ],
    "additionalParams": [
      {
        "key": "managementIpAddr",
        "value": "10.30.255.102"
      },
      {
        "key": "remoteIP",
        "value": "10.30.253.3"
      },
      {
        "key": "_name",
        "value": "EIGRP_Peering"
      },
      {
        "key": "_entity_type",
        "value": "NetworkDevice"
      },
      {
        "key": "eventSource",
        "value": "Network Device"
      },
      {
        "key": "type",
        "value": "issue"
      },
      {
        "key": "_key",
        "value": "NetworkDevice:10.30.255.102:10.30.253.3:Vlan90"
      },
      {
        "key": "floorId",
        "value": "d2569c9e-047b-47d6-805e-fe5013423b01"
      },
      {
        "key": "_count",
        "value": "1"
      },
      {
        "key": "areaName",
        "value": "Global/USA"
      },
      {
        "key": "scope",
        "value": "global"
      },
      {
        "key": "rootCause",
        "value": "EIGRP_PEERING"
      },
      {
        "key": "_primary_key",
        "value": "NetworkDevice:10.30.255.102"
      },
      {
        "key": "syslogMsg",
        "value": "EIGRP-IPv4 10: Neighbor 10.30.253.3 (Vlan90) is down: holding time expired"
      },
      {
        "key": "floorName",
        "value": "Global/USA/SM/Level1"
      },
      {
        "key": "deviceType",
        "value": "Cisco Catalyst38xx stack-able ethernet switch"
      },
      {
        "key": "severity",
        "value": "HIGH"
      },
      {
        "key": "deviceUuid",
        "value": "8cc2fa77-a26f-4753-bdc9-04425b9d3e46"
      },
      {
        "key": "deviceRole",
        "value": "DISTRIBUTION"
      },
      {
        "key": "_suppression",
        "value": "7200"
      },
      {
        "key": "deviceOS",
        "value": "16.6.2"
      },
      {
        "key": "entityType",
        "value": "network_device"
      },
      {
        "key": "nwDeviceName",
        "value": "LA2-3850-CSW-3.corp.local"
      },
      {
        "key": "_eventTime",
        "value": "1583854318415"
      },
      {
        "key": "_mostRecent",
        "value": "1583854318415"
      },
      {
        "key": "entityId",
        "value": "10.30.255.102"
      },
      {
        "key": "eventUniqueId",
        "value": "10.30.255.102"
      },
      {
        "key": "deviceFamily",
        "value": "Switches and Hubs"
      },
      {
        "key": "priority",
        "value": "P2"
      },
      {
        "key": "buildingId",
        "value": "c090b198-f319-4b93-993f-2d8cc8b64b6b"
      },
      {
        "key": "buildingName",
        "value": "Global/USA/SM"
      },
      {
        "key": "areaId",
        "value": "8edbff1a-e8f0-46d7-8b3a-c77ded39c4ab"
      },
      {
        "key": "siteHierarchy",
        "value": "Global/USA/SM/Level1"
      },
      {
        "key": "failureReason",
        "value": "EIGRP Down"
      },
      {
        "key": "eventStatus",
        "value": "Down"
      },
      {
        "key": "siteId",
        "value": "d2569c9e-047b-47d6-805e-fe5013423b01"
      },
      {
        "key": "_source",
        "value": "internal"
      },
      {
        "key": "deviceModel",
        "value": "FCW2129C0XS"
      },
      {
        "key": "category",
        "value": "connectivity"
      },
      {
        "key": "timestring",
        "value": "2020-03-10T15:31:58.415+0000"
      },
      {
        "key": "status",
        "value": "active"
      },
      {
        "key": "intfName",
        "value": "Vlan90"
      }
    ],
    "status": {
      "status": "ACTIVE",
      "updatedBy": "Unknown",
      "notes": "Unknown",
      "source": "Unknown",
      "updatedAt": null,
      "ignoreSource": "Unknown",
      "ignoreValue": "Unknown",
      "ignoreStartTime": null,
      "ignoreEndTime": null
    },
    "siteQulaifiedName": null
  }
}
})