define({
"Learn_Device_Config_Template": [
 {
    "CONFIG": [
      {
        "instanceUuid": "4663e3c3-02e2-4706-b913-8d1563d8806d",
        "namespace": "wlan",
        "type": "wlan.setting",
        "key": "wlan.info.ClaimSSID",
        "version": 1,
        "value": [
          {
            "profileName": "ClaimSSIDProfile",
            "ssid": "ClaimSSID",
            "wlanType": "Enterprise",
            "isFastLaneEnabled": false,
            "authType": "wpa2_enterprise",
            "authServer": "",
            "passphrase": "",
            "peerIp": "",
            "scalableGroupTag": "",
            "trafficType": "voicedata",
            "isMacFilteringEnabled": false,
            "isEnabled": true,
            "isEmailReqd": false,
            "isFabric": false,
            "fabricId": null,
            "redirectUrl": "",
            "authSecServer": "",
            "portalType": "",
            "redirectUrlType": "",
            "externalAuthIpAddress": "",
            "portalName": "",
            "fastTransition": "ADAPTIVE",
            "radioPolicy": 0,
            "wlanBandSelectEnable": false,
            "isBroadcastSSID": true
          }
        ],
        "groupUuid": "-1"
      },
      {
        "instanceUuid": "4663e3c3-02e2-4706-b913-8d1563d8806a",
        "namespace": "wlan",
        "type": "wlan.setting",
        "key": "wlan.info.OPEN",
        "version": 1,
        "value": [
          {
            "profileName": "OPENProfile",
            "ssid": "OPEN",
            "wlanType": "Enterprise",
            "isFastLaneEnabled": false,
            "authType": "open",
            "authServer": "",
            "passphrase": "",
            "peerIp": "",
            "scalableGroupTag": "",
            "trafficType": "voicedata",
            "isMacFilteringEnabled": false,
            "isEnabled": true,
            "isEmailReqd": false,
            "isFabric": false,
            "fabricId": null,
            "redirectUrl": "",
            "authSecServer": "",
            "portalType": "",
            "redirectUrlType": "",
            "externalAuthIpAddress": "",
            "portalName": "",
            "fastTransition": "ADAPTIVE",
            "radioPolicy": 0,
            "wlanBandSelectEnable": false,
            "isBroadcastSSID": true
          }
        ],
        "groupUuid": "-1"
      },
      {
        "instanceUuid": "ebda5d41-bc34-420f-9662-ba7419e5fa62",
        "namespace": "global",
        "type": "interface.setting",
        "key": "interface.info",
        "version": 1,
        "value": [

          {
            "interfaceName": "vlan-154",
            "vlanId": 154
          }
        ],
        "groupUuid": "-1"
      },
      {
        "instanceUuid": "4265e241-37af-4f06-b16b-ea3fb2e2dbfa",
        "namespace": "global",
        "type": "ip.address",
        "key": "dhcp.server",
        "version": 1,
        "value": [
          "101.110.110.110",
          "11.11.11.11",
          "10.10.2.0",
          "9.1.0.100",
          "4.5.2.4",
          "50.1.1.1",
          "10.10.10.10",
          "101.101.101.10",
          "9.1.0.105"
        ],
        "groupUuid": "c69500b5-e66b-42c5-924a-354d0c829df0"
      }
    ],
    "BUSINESSCONFLICT": [
      {
        "objectName": "AAAAuthAndAccounting",
        "attrList": [
          "ipAddress",
          "protocol"
        ],
        "diffList": [
          {
            "oldObject": null,
            "newObject": [

            ],
            "validationError": "validation.error.conflict.duplicate"
          }
        ]
      }
    ],
    "PROFILE": [{
          "attributesList":[],
          "groupTypeList":[],
          "interfaceList":[],
          "siteProfileUuid":"4663e3c3-02e2-4706-b913-8d1563d8806e",
          "id": "4663e3c3-02e2-4706-b913-8d1563d8806e",
          "version":1,
          "name":"ClaimSSIDProfile",
          "namingPrefix":"",
          "namespace":"wlan",
          "primaryDeviceType":"",
          "secondaryDeviceType":"",
          "status":"",
          "lastUpdatedBy":"admin",
          "lastUpdatedDatetime":0,
          "siteAssociationId":"",
          "siteCount":0,
          "siteProfileType":"",
          "profileAttributes": [
                              {
                                "key": "wireless.ssid",
                                "value": "ClaimSSID",
                                "attribs":[{"key": "wireless.fabric","value": 'true'},{"key": "wireless.flexconnect","value": false},{"key": "wireless.authMode","value": "central"},{"key": "wireless.trafficSwitchingMode","value": "fabric"}
                                ]
                              }

                            ]

    },{
          "attributesList":[],
          "groupTypeList":[],
          "interfaceList":[],
          "siteProfileUuid":"4663e3c3-02e2-4706-b913-8d1563d8804e",
          "id": "4663e3c3-02e2-4706-b913-8d1563d8804e",
          "version":1,
          "name":"OPENProfile",
          "namingPrefix":"",
          "namespace":"wlan",
          "primaryDeviceType":"",
          "secondaryDeviceType":"",
          "status":"",
          "lastUpdatedBy":"admin",
          "lastUpdatedDatetime":0,
          "siteAssociationId":"",
          "siteCount":0,
          "siteProfileType":"",
          "profileAttributes": [
                              {
                                "key": "wireless.ssid",
                                "value": "OPEN",
                                "attribs":[{"key": "wireless.fabric","value": 'true'},{"key": "wireless.flexconnect","value": false},{"key": "wireless.authMode","value": "central"},{"key": "wireless.trafficSwitchingMode","value": "fabric"}
                                ]
                              }

                            ]

    }


    ],
    "VALUECONFLICT": [
      {
        "objectName": "wlan",
        "attrList": [
          "ssid"
        ],
        "diffList": [
          {
            "oldObject": {
              "profileName": "PSK",
              "ssid": "PSK",
              "wlanType": "Enterprise",
              "isFastLaneEnabled": false,
              "authType": "wpa2_personal",
              "authServer": "",
              "passphrase": "hcUKu8KiNG5FE/cMO7UJmBmtGBHpqgsxORYGUSBQHD/y6e3GQoSUsZAm8szMSNdrfZnQZwZ7hgOz/lGE5soaH7WuOsSu85id",
              "peerIp": "",
              "scalableGroupTag": "",
              "trafficType": "data",
              "isMacFilteringEnabled": false,
              "isEnabled": true,
              "isEmailReqd": false,
              "isFabric": false,
              "fabricId": null,
              "redirectUrl": "",
              "authSecServer": null,
              "portalType": "",
              "redirectUrlType": "",
              "externalAuthIpAddress": "",
              "portalName": "",
              "fastTransition": "ADAPTIVE",
              "radioPolicy": 0,
              "wlanBandSelectEnable": false,
              "isBroadcastSSID": true
            },
            "newObject": {
              "profileName": "PSK",
              "ssid": "PSK",
              "wlanType": "Enterprise",
              "isFastLaneEnabled": false,
              "authType": "wpa2_personal",
              "authServer": "",
              "passphrase": "",
              "peerIp": "",
              "scalableGroupTag": "",
              "trafficType": "data",
              "isMacFilteringEnabled": false,
              "isEnabled": true,
              "isEmailReqd": false,
              "isFabric": true,
              "fabricId": null,
              "redirectUrl": "",
              "authSecServer": "",
              "portalType": "",
              "redirectUrlType": "",
              "externalAuthIpAddress": "",
              "portalName": "",
              "fastTransition": "ADAPTIVE",
              "radioPolicy": 0,
              "wlanBandSelectEnable": false,
              "isBroadcastSSID": true
            },
            "validationError": "Duplicate design entity."
          },
          {
            "oldObject": {
              "profileName": "dot1x",
              "ssid": "dot1x",
              "wlanType": "Enterprise",
              "isFastLaneEnabled": false,
              "authType": "wpa2_enterprise",
              "authServer": "",
              "passphrase": "",
              "peerIp": "",
              "scalableGroupTag": "",
              "trafficType": "data",
              "isMacFilteringEnabled": false,
              "isEnabled": true,
              "isEmailReqd": false,
              "isFabric": false,
              "fabricId": null,
              "redirectUrl": "",
              "authSecServer": null,
              "portalType": "",
              "redirectUrlType": "",
              "externalAuthIpAddress": "",
              "portalName": "",
              "fastTransition": "ADAPTIVE",
              "radioPolicy": 0,
              "wlanBandSelectEnable": false,
              "isBroadcastSSID": true
            },
            "newObject": {
              "profileName": "dot1x",
              "ssid": "dot1x",
              "wlanType": "Enterprise",
              "isFastLaneEnabled": false,
              "authType": "wpa2_enterprise",
              "authServer": "",
              "passphrase": "",
              "peerIp": "",
              "scalableGroupTag": "",
              "trafficType": "data",
              "isMacFilteringEnabled": false,
              "isEnabled": true,
              "isEmailReqd": false,
              "isFabric": true,
              "fabricId": null,
              "redirectUrl": "",
              "authSecServer": "",
              "portalType": "",
              "redirectUrlType": "",
              "externalAuthIpAddress": "",
              "portalName": "",
              "fastTransition": "ADAPTIVE",
              "radioPolicy": 0,
              "wlanBandSelectEnable": false,
              "isBroadcastSSID": true
            },
            "validationError": "Duplicate design entity."
          }
        ]
      }
    ]
  },
  {
    "CONFIG": [
      {
        "instanceUuid": "ebda5d41-bc34-420f-9662-ba7419e5fa62",
        "namespace": "global",
        "type": "interface.setting",
        "key": "interface.info",
        "version": 1,
        "value": [

          {
            "interfaceName": "vlan157",
            "vlanId": 157
          }
        ],
        "groupUuid": "-1"
      },
      {
        "instanceUuid": "4265e241-37af-4f06-b16b-ea3fb2e2dbfa",
        "namespace": "global",
        "type": "ip.address",
        "key": "dhcp.server",
        "version": 1,
        "value": [
          "101.110.110.112",

        ],
        "groupUuid": "c69500b5-e66b-42c5-924a-354d0c829df0"
      }
    ],
    "BUSINESSCONFLICT": [
      {
        "objectName": "AAAAuthAndAccounting",
        "attrList": [
          "ipAddress",
          "protocol"
        ],
        "diffList": [
          {
            "oldObject": null,
            "newObject": [

            ],
            "validationError": "validation.error.conflict.duplicate"
          }
        ]
      }
    ],
    "PROFILE": [

    ],
    "VALUECONFLICT": [
      {
        "objectName": "wlan",
        "attrList": [
          "ssid"
        ],
        "diffList": [
          {
            "oldObject": {
              "profileName": "PSK",
              "ssid": "PSK",
              "wlanType": "Enterprise",
              "isFastLaneEnabled": false,
              "authType": "wpa2_personal",
              "authServer": "",
              "passphrase": "hcUKu8KiNG5FE/cMO7UJmBmtGBHpqgsxORYGUSBQHD/y6e3GQoSUsZAm8szMSNdrfZnQZwZ7hgOz/lGE5soaH7WuOsSu85id",
              "peerIp": "",
              "scalableGroupTag": "",
              "trafficType": "data",
              "isMacFilteringEnabled": false,
              "isEnabled": true,
              "isEmailReqd": false,
              "isFabric": false,
              "fabricId": null,
              "redirectUrl": "",
              "authSecServer": null,
              "portalType": "",
              "redirectUrlType": "",
              "externalAuthIpAddress": "",
              "portalName": "",
              "fastTransition": "ADAPTIVE",
              "radioPolicy": 0,
              "wlanBandSelectEnable": false,
              "isBroadcastSSID": true
            },
            "newObject": {
              "profileName": "PSK",
              "ssid": "PSK",
              "wlanType": "Enterprise",
              "isFastLaneEnabled": false,
              "authType": "wpa2_personal",
              "authServer": "",
              "passphrase": "",
              "peerIp": "",
              "scalableGroupTag": "",
              "trafficType": "data",
              "isMacFilteringEnabled": false,
              "isEnabled": true,
              "isEmailReqd": false,
              "isFabric": true,
              "fabricId": null,
              "redirectUrl": "",
              "authSecServer": "",
              "portalType": "",
              "redirectUrlType": "",
              "externalAuthIpAddress": "",
              "portalName": "",
              "fastTransition": "ADAPTIVE",
              "radioPolicy": 0,
              "wlanBandSelectEnable": false,
              "isBroadcastSSID": true
            },
            "validationError": "Duplicate design entity."
          },
          {
            "oldObject": {
              "profileName": "dot1x",
              "ssid": "dot1x",
              "wlanType": "Enterprise",
              "isFastLaneEnabled": false,
              "authType": "wpa2_enterprise",
              "authServer": "",
              "passphrase": "",
              "peerIp": "",
              "scalableGroupTag": "",
              "trafficType": "data",
              "isMacFilteringEnabled": false,
              "isEnabled": true,
              "isEmailReqd": false,
              "isFabric": false,
              "fabricId": null,
              "redirectUrl": "",
              "authSecServer": null,
              "portalType": "",
              "redirectUrlType": "",
              "externalAuthIpAddress": "",
              "portalName": "",
              "fastTransition": "ADAPTIVE",
              "radioPolicy": 0,
              "wlanBandSelectEnable": false,
              "isBroadcastSSID": true
            },
            "newObject": {
              "profileName": "dot1x",
              "ssid": "dot1x",
              "wlanType": "Enterprise",
              "isFastLaneEnabled": false,
              "authType": "wpa2_enterprise",
              "authServer": "",
              "passphrase": "",
              "peerIp": "",
              "scalableGroupTag": "",
              "trafficType": "data",
              "isMacFilteringEnabled": false,
              "isEnabled": true,
              "isEmailReqd": false,
              "isFabric": true,
              "fabricId": null,
              "redirectUrl": "",
              "authSecServer": "",
              "portalType": "",
              "redirectUrlType": "",
              "externalAuthIpAddress": "",
              "portalName": "",
              "fastTransition": "ADAPTIVE",
              "radioPolicy": 0,
              "wlanBandSelectEnable": false,
              "isBroadcastSSID": true
            },
            "validationError": "Duplicate design entity."
          }
        ]
      }
    ]
  }
  ],

  Default_Device_Config_Template : /* {
     "CONFIG": [],
     "BUSINESSCONFLICT":[],
     "PROFILE":[],
     "VALUECONFLICT": []
  } */
  {"BUSINESSCONFLICT":[],"CONFIG":[],"CWASSIDSTOCONVERT":[],"PROFILE":[],"VALUECONFLICT":[],"DUPLICATEVALUE":[],"EXISTINGCONFIG":[],
  "SITEHIERARCHY":{"0c2f89fc-8412-dd50-e30c-4fbf5f0e772f":["Global/North America/USA/California/San Jose/SJC04"]},
  "BROWNFIELD_BGP_LEARNER":[{"fabricBgpAsNumber":"65004","fabricAsNotation":"ASDOT","deviceBrownfieldBgpAsNumber":"-1",
  "deviceAsNotation":"ASPLAIN","asNumberToPrePopulate":"65004","asNotationToPrePopulate":"ASDOT",
  "description":"BGP has been configured in the fabric site with AS Number 65004"}]}
});