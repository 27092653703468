define([
    'core/../../../public/app/devices/dnac_sinon_sim/lib/services/sinonmock/rest/SimSinonMockRestHelper',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/services/sinonmock/controller/SimSinonMockController'
], function (SimSinonMockRestHelper, SimSinonMockController) {

    return {
        init: function (sinonHandler, restEndPoints) {
            setMockhandles(sinonHandler, restEndPoints);
            SimSinonMockController.init();
        }
    };

    function getResponseType(tXhr, data) {
        var stringConstructor = "test".constructor;
        if (data && data.constructor === stringConstructor) {
            return 'text';
        } else {
            return 'json';
        }
    }

    function getContentType(responseType) {
        var contentType = "application/json";
        if (responseType == 'text') {
            contentType = 'text/plain;charset=UTF-8';
        }
        return ({ "Content-Type": contentType });
    }

    function setXHRDefaultValue(tXhr, statusCode, data) {
        tXhr.responseType = 'text';//Sinon works only if responseType is text, hence being set as text here..
        var responseType = getResponseType(tXhr, data);

        tXhr.setStatus(statusCode);
        //console.log(tXhr.requestHeaders);

        tXhr.setResponseHeaders(getContentType(responseType));

        if (responseType === 'text') {
            tXhr.setResponseBody(data || 'not implemented');
        } else {
            tXhr.setResponseBody(JSON.stringify(data || { error: "not implemented" }));
        }
    }

    function handleRestRequest(type, url, payload, requestHeaders) {
        var action = SimSinonMockRestHelper.decodeUrl(type, url, payload, requestHeaders);
        //if(url.indexOf(`/api/ndp/v1/nls/applications/dna/autocomplete`)>-1)  debugger //replace url and use this for analyzing flow
        var urlValue = url, editedUrl;
        if(urlValue.indexOf("/dna/intent") != -1) {
            editedUrl = urlValue.replace("/dna/intent", "")
            var action = SimSinonMockRestHelper.decodeUrl(type, editedUrl, payload, requestHeaders);
        }
        else {
            var action = SimSinonMockRestHelper.decodeUrl(type, url, payload, requestHeaders);
        }
        return SimSinonMockController.doProcessRequest(action);
    }

    function setMockhandles(sinonHandler, restEndPoints) {
        for (var i = 0, len = restEndPoints.length; i < len; i++) {
            sinonHandler.respondWith(restEndPoints[i].endPoint, function (xhr, id) {
                //console.log('Req:')
                console.log(xhr.url, xhr.method, xhr.requestBody);

                var data = handleRestRequest(xhr.method, xhr.url, xhr.requestBody, xhr.requestHeaders);
                var statusCode = 200;

                if (data && data.response != undefined && data.response.statusCode != undefined) {
                    statusCode = data.response.statusCode;
                }
                if (data && data.response && data.response.isError) {
                    if (data.response.errorCode) {
                        statusCode = data.response.errorCode;
                    } else {
                        statusCode = 400;
                    }
                }
                //console.log('Res:')
                console.log(data);
                try {
                    setXHRDefaultValue(xhr, statusCode, data);
                    // console.trace()
                }
                catch (e) {
                    console.warn("Encountered an error from DNAC UI code before sending sim data:", e, "\nRetrying simulation...")
                    // setTimeout(function(){setXHRDefaultValue(xhr, statusCode, data)}, 2000)
                }

                /*if(xhr.url.indexOf("login")>=0 ) {
                    xhr.setResponseBody(data);
                } else {
                    xhr.setResponseBody(JSON.stringify(data));
                }*/
            });
        }
    }
});
