define({
"Template":
    {
      "intfName": "TwoGigabitEthernet4/0/12",
      "intfOperStatus": null,
      "intfDescr": null,
      "poeOperStatus": "ON",
      "poeAdminStatus": "AUTO",
      "chassidId": 1,
      "moduleId": 4,
      "pdClassSignal": "IEEE2",
      "pdClassSpare": "NONE",
      "pdDeviceType": "IP_PHONE",
      "pdDeviceModel": " IP Phone 6961",
      "pdPowerAdminMax": "60.0W",
      "pdPowerBudget": "6.0W",
      "pdPowerConsumed": "2.2W",
      "pdPowerRemaining": "3.8W",
      "pdConnectedDeviceList": [
        "64:00:F1:7E:31:93"
      ],
      "poeOperPriority": "LOW",
      "fastPoEEnabled": false,
      "perpetualPoEEnabled": false,
      "policingPoEEnabled": false,
      "upoePlusEnabled": false,
      "fourPairEnabled": false,
      "timestamp": 1588675782790,
      "pdLocation": "Global/RAJ-SJC20-9K",
      "pdDeviceName": "SEP6400F17E3193",
      "pdConnectedSwitch": "stack-poe1",
      "connectedSwitchUUID": "bea1e2f0-6505-41d1-803c-74b616c04831",
      "ieeeCompliant": "Yes",
      "connectedSwitchType": "Cisco Catalyst 9300 Switch",
      "SwitchPowerDetails": { },
      "switchSiteId": "bea1e2f0-6505-41d1-803c-74b616c04831"
    },
    "poe_details_module":
    {
      "version": "1.0",
      "response": {
        "MODULE": [
          {
            "time": "2020-05-19T07:26:17+0000",
            "entries": [
              {
                "id": "ALL",
                "powerBudget": "8702.0W",
                "usedPower": "2070.4W",
                "remainingPower": "6631.6W",
                "powerUsagePercent": "23.79%",
                "timestamp": "2020-05-19T07:20:00+0000",
                "nestedCounts": [
                  {
                    "id": "1/1",
                    "powerBudget": "1440.0W",
                    "usedPower": "259.6W",
                    "remainingPower": "1180.4W",
                    "powerUsagePercent": "18.03%",
                    "timestamp": "2020-05-19T07:20:00+0000"
                  }
                ]
              }
            ]
          }
        ]
      }
    },
    "PoE_Port_Analytics_AP4800": [
      {
        "intfName": "GigabitEthernet1/0/3",
        "intfOperStatus": null,
        "intfDescr": null,
        "poeOperStatus": "ON",
        "poeAdminStatus": "STATIC",
        "chassidId": 1,
        "moduleId": 1,
        "pdClassSignal": "IEEE4",
        "pdClassSpare": "NONE",
        "pdDeviceType": "Unified AP",
        "pdDeviceModel": "AIR-AP3802I-B-K9",
        "pdPowerAdminMax": "20.0W",
        "pdPowerBudget": "20.0W",
        "pdPowerConsumed": "10.5W",
        "pdPowerRemaining": "9.5W",
        "pdMaxPowerDrawn": "12.3W",
        "pdPowerNegotiationUsed": "1",
        "pdConnectedDeviceList": [''],
        "poeOperPriority": "HIGH",
        "fastPoEEnabled": false,
        "perpetualPoEEnabled": false,
        "policingPoEEnabled": false,
        "upoePlusEnabled": false,
        "fourPairEnabled": false,
        "timestamp": 1663924200000,
        "pdLocation": "Global/North America/USA/California/San Jose/SJC01/Flr-SJC1-1",
        "pdDeviceName": "AP4800",
        "pdConnectedSwitch": "p1.edge1-sda1.local",
        "connectedSwitchUUID": "e5df3334-c405-25ae-8ea1-cb153f36f660",
        "ieeeCompliant": "Yes",
        "connectedSwitchType": "Cisco Catalyst 9300 Switch",
        "SwitchPowerDetails":{"deviceName": "p1.edge1-sda1.local","powerBudget": "2500W",
        "powerConsumed": "2150W","powerLoad": "86.0","powerRemaining": "350W"}
      }
    ]

})