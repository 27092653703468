define({
  "Template": {
    request: {
      key: "ms-teams-app-sharing",
      type: "Application",
      label: "ms-teams-app-sharing",
      collection: false,
      componentContent: [
        {
          component: "fusion",
          additional: "e8326e24-4048-4e9e-adc9-c1a8143b5069",
          origType: "Application",
        },
      ],
    },
    data: {
      response: {
        id: "e8326e24-4048-4e9e-adc9-c1a8143b5069",
        instanceId: 5845,
        instanceCreatedOn: 1657037696506,
        instanceUpdatedOn: 1657037696506,
        instanceVersion: 0,
        createTime: 1657037684619,
        deployed: false,
        isSeeded: true,
        isStale: false,
        lastUpdateTime: 1657037684619,
        name: "ms-teams-app-sharing",
        namespace: "scalablegroup:application",
        provisioningState: "DEFINED",
        qualifier: "application",
        resourceVersion: 0,
        targetIdList: [],
        type: "scalablegroup",
        cfsChangeInfo: [],
        customProvisions: [],
        isDeleted: false,
        iseReserved: false,
        mdnacDeletionStatus: "ACTIVE",
        propagateToAci: false,
        pushed: false,
        scalableGroupExternalHandle: "ms-teams-app-sharing",
        scalableGroupType: "APPLICATION",
        securityGroupTag: 0,
        state: "ACTIVE",
        vnAgnostic: true,
        defaultContracts: [],
        identitySource: {
          id: "551bc1e2-bcde-46b3-9dff-9053ef4d272b",
          instanceId: 14014,
          instanceCreatedOn: 1657037668944,
          instanceUpdatedOn: 1657037668944,
          instanceVersion: 0,
          state: "INACTIVE",
          type: "NBAR",
          displayName: "14014",
        },
        indicativeNetworkIdentity: [],
        networkApplications: [
          {
            id: "d6ce23af-fa05-4329-9c00-1d897f944d65",
            instanceId: 16795,
            instanceCreatedOn: 1657037696506,
            instanceUpdatedOn: 1657037696506,
            instanceVersion: 0,
            appProtocol: "tcp/udp",
            applicationGroup: "ms-cloud-group",
            applicationSubType: "NONE",
            applicationType: "DEFAULT",
            categoryId: "0adcf602-f1ac-4f02-b84c-25cc1aa528ed",
            createTime: 1657037684619,
            encrypted: "true",
            engineId: "13",
            helpString: "Microsoft Teams Application/Desktop Sharing",
            ignoreConflict: false,
            lastUpdateTime: 1657037684619,
            longDescription:
              "Microsoft Teams is a unified communications platform that combines persistent workplace chat, video meetings, file storage, and application integration. ",
            name: "ms-teams-app-sharing",
            nbarId: "1260",
            p2pTechnology: "true",
            popularity: 7,
            rank: 65535,
            references: "https://products.office.com/en-us/microsoft-teams",
            selectorId: "1260",
            serverNames: [],
            subCategory: "5aee80d3-0ef6-40c3-9c4b-9d2f46b904ea",
            trafficClass: "MULTIMEDIA_CONFERENCING",
            tunnel: "false",
            displayName: "16795",
          },
        ],
        networkIdentity: [
          {
            id: "2c34c222-7f95-48c7-afb5-b51314ddc5df",
            instanceId: 19085,
            instanceCreatedOn: 1657037696506,
            instanceUpdatedOn: 1657037696506,
            instanceVersion: 0,
            ipv4Subnet: [],
            ipv6Subnet: [],
            lowerPort: 0,
            ports: "3478",
            protocol: "TCP",
            upperPort: 0,
            displayName: "19085",
          },
          {
            id: "97429a30-cad1-4043-970d-62ecb887a9f0",
            instanceId: 19086,
            instanceCreatedOn: 1657037696506,
            instanceUpdatedOn: 1657037696506,
            instanceVersion: 0,
            ipv4Subnet: [],
            ipv6Subnet: [],
            lowerPort: 0,
            ports: "3478",
            protocol: "UDP",
            upperPort: 0,
            displayName: "19086",
          },
        ],
        parentScalableGroup: {
          idRef: "579eb909-1c37-4515-abce-59b77638b6eb",
        },
        user: [],
        userGroup: [],
        displayName: "5845",
      },
    },
    result: [
      {
        bookID: {
          namespace: "fusion",
          name: "core",
          version: "1.0.1",
        },
        response: {
          id: "e8326e24-4048-4e9e-adc9-c1a8143b5069",
          instanceId: 5845,
          instanceCreatedOn: 1657037696506,
          instanceUpdatedOn: 1657037696506,
          instanceVersion: 0,
          createTime: 1657037684619,
          deployed: false,
          isSeeded: true,
          isStale: false,
          lastUpdateTime: 1657037684619,
          name: "ms-teams-app-sharing",
          namespace: "scalablegroup:application",
          provisioningState: "DEFINED",
          qualifier: "application",
          resourceVersion: 0,
          targetIdList: [],
          type: "scalablegroup",
          cfsChangeInfo: [],
          customProvisions: [],
          isDeleted: false,
          iseReserved: false,
          mdnacDeletionStatus: "ACTIVE",
          propagateToAci: false,
          pushed: false,
          scalableGroupExternalHandle: "ms-teams-app-sharing",
          scalableGroupType: "APPLICATION",
          securityGroupTag: 0,
          state: "ACTIVE",
          vnAgnostic: true,
          defaultContracts: [],
          identitySource: {
            id: "551bc1e2-bcde-46b3-9dff-9053ef4d272b",
            instanceId: 14014,
            instanceCreatedOn: 1657037668944,
            instanceUpdatedOn: 1657037668944,
            instanceVersion: 0,
            state: "INACTIVE",
            type: "NBAR",
            displayName: "14014",
          },
          indicativeNetworkIdentity: [],
          networkApplications: [
            {
              id: "d6ce23af-fa05-4329-9c00-1d897f944d65",
              instanceId: 16795,
              instanceCreatedOn: 1657037696506,
              instanceUpdatedOn: 1657037696506,
              instanceVersion: 0,
              appProtocol: "tcp/udp",
              applicationGroup: "ms-cloud-group",
              applicationSubType: "NONE",
              applicationType: "DEFAULT",
              categoryId: "0adcf602-f1ac-4f02-b84c-25cc1aa528ed",
              createTime: 1657037684619,
              encrypted: "true",
              engineId: "13",
              helpString: "Microsoft Teams Application/Desktop Sharing",
              ignoreConflict: false,
              lastUpdateTime: 1657037684619,
              longDescription:
                "Microsoft Teams is a unified communications platform that combines persistent workplace chat, video meetings, file storage, and application integration. ",
              name: "ms-teams-app-sharing",
              nbarId: "1260",
              p2pTechnology: "true",
              popularity: 7,
              rank: 65535,
              references: "https://products.office.com/en-us/microsoft-teams",
              selectorId: "1260",
              serverNames: [],
              subCategory: "5aee80d3-0ef6-40c3-9c4b-9d2f46b904ea",
              trafficClass: "MULTIMEDIA_CONFERENCING",
              tunnel: "false",
              displayName: "16795",
            },
          ],
          networkIdentity: [
            {
              id: "2c34c222-7f95-48c7-afb5-b51314ddc5df",
              instanceId: 19085,
              instanceCreatedOn: 1657037696506,
              instanceUpdatedOn: 1657037696506,
              instanceVersion: 0,
              ipv4Subnet: [],
              ipv6Subnet: [],
              lowerPort: 0,
              ports: "3478",
              protocol: "TCP",
              upperPort: 0,
              displayName: "19085",
            },
            {
              id: "97429a30-cad1-4043-970d-62ecb887a9f0",
              instanceId: 19086,
              instanceCreatedOn: 1657037696506,
              instanceUpdatedOn: 1657037696506,
              instanceVersion: 0,
              ipv4Subnet: [],
              ipv6Subnet: [],
              lowerPort: 0,
              ports: "3478",
              protocol: "UDP",
              upperPort: 0,
              displayName: "19086",
            },
          ],
          parentScalableGroup: {
            idRef: "579eb909-1c37-4515-abce-59b77638b6eb",
          },
          user: [],
          userGroup: [],
          displayName: "5845",
        },
      },
    ],
  },
});
