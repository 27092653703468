define({
  "Topology_Node_Template": {
    deviceType: "",
    label: "",
    ip: "",
    softwareVersion: "15.1(4)M2",
    nodeType: "device",
    family: "",
    platformId: "",
    tags: [],
    role: "",
    roleSource: "AUTO",
    customParam: {},
    additionalInfo: {},
    topology_type: "node"
  },
  "Topology_Additional_Info_Template": {
    "macAddress": "",
    "latitude": "",
    "siteid": "",
    "fabricRoles": [
      "EDGE",
      "BORDER",
      "MAPSERVER",
      "INTERMEDIATE"
    ],
    "longitude": ""
  }
});
