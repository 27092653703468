define({
    "runningImageList": [
      {
        "name": "SW1800-SENSOR-K9-2-2-1-0.tar.gz",
        "imageUuid": "ffda9275-04c4-2222-8888-f4809403a3b3",
        "version": "2.2.1.0",
        "softwareType": null,
        "imageType": "TGZ_SW",
        "isTaggedGolden": "true",
        "md5Checksum": "941ada1402dbe425f96c39e7888d8b63",
        "shaCheckSum": "d7d0e00a50891d37bf1d63c7fb56c954818495ef41ae1b70bc77a8585a7f5d5df90e60039bda77cb9fe38257d78ca0a0e6e1703f0b37335055bea92f99cd36e0",
        "createdTime": "2021-03-05 15:49:00.0",
        "applicationType": "UNKNOWN",
        "feature": null,
        "fileServiceId": "0d149141-5555-2222-848a-caa8111dc7d6",
        "imageSeries": null,
        "imageSource": "file:/opt/maglev/services/swim-service/7.1.26.60138//localdisk/Staging/SW1800-SENSOR-K9-2-2-1-0.tar.gz",
        "imageSourceType": "Imported from File System",
        "imageFamily": "AP1800S",
        "vendor": "CISCO",
        "filesize": 21975040,
        "deviceCount": 0,
        "deviceUuidList": [
        ],
        "smuCount": 0,
        "imageAttributes": [
          {
            "propertyName": "DEFAULT_BOOTROM",
            "propertyValue": "0"
          },
          {
            "propertyName": "VERSION",
            "propertyValue": "2.2.1.0"
          },
          {
            "propertyName": "MD5_CHECKSUM",
            "propertyValue": "UNKNOWN"
          },
          {
            "propertyName": "DEFAULT_RAM",
            "propertyValue": "0"
          },
          {
            "propertyName": "NAME",
            "propertyValue": "SW1800-SENSOR-K9-2-2-1-0.tar.gz"
          },
          {
            "propertyName": "APPLICATION_NAME",
            "propertyValue": "AP1800S"
          },
          {
            "propertyName": "DEFAULT_MINFLASHSIZE",
            "propertyValue": "0"
          },
          {
            "propertyName": "IMAGETYPE_VALUE",
            "propertyValue": "TGZ"
          },
          {
            "propertyName": "IMAGENAME",
            "propertyValue": "SW1800-SENSOR-K9-2-2-1-0"
          },
          {
            "propertyName": "MINDISKSIZE",
            "propertyValue": "0"
          }
        ],
        "isSMUApplicable": false,
        "goldenTagInheritedFromSiteUuid": null,
        "goldenTagInheritedFromSiteName": null,
        "importSupported": false,
        "reasonForNotSupportingImport": "Import from Cisco Aironet 1800S Active Sensor is not supported. Please import from url or local file.",
        "tagList": [
          {
            "taggedGolden": true,
            "role": "ALL",
            "inheritedSiteName": "",
            "inheritedSiteUuid": ""
          }
        ],
        "imageCategory": "PHYSICAL",
        "imageIntegrityStatus": "VERIFIED",
        "imageImported": true,
        "applicableDevicesForImage": [
          {
            "mdfId": "286318948",
            "productName": "Cisco Aironet 1800s Network Sensor",
            "productId": [
              "AIR-AP1800S-D-K9",
              "AIR-AP1800S-B-K9"
            ]
          }
        ],
        "profileInfo": null,
        "physicallyAvailable": true,
        "assignCount": 0,
        "showUnassign": false,
        "disableUnassign": false,
        "ccoreverseSync": false,
        "ccorecommended": false,
        "ccolatest": true
      },
      {
        "name": "SW1800-SENSOR-K9-2-1-2-0.tar.gz",
        "imageUuid": "ffda9275-04c4-1178-8888-f4809403a3b3",
        "version": "2.1.2.0",
        "softwareType": null,
        "imageType": "TGZ_SW",
        "isTaggedGolden": "false",
        "md5Checksum": "b05d78c2c5a96edf986cad209f6e3309",
        "shaCheckSum": "92d5fdb27416961c02b2104099cddb522d925aba4e6f4da576dd50a9f70340f56aedca9be3fc86d4ad76029b25148d54266942fcdd3872741e519b5748806a5c",
        "createdTime": "2020-10-16 11:11:23.0",
        "applicationType": "UNKNOWN",
        "feature": null,
        "fileServiceId": "0d149141-5555-6712-848a-caa8111dc7d6",
        "imageSeries": null,
        "imageSource": "file:/opt/maglev/services/swim-service/7.1.26.60138//localdisk/Staging/SW1800-SENSOR-K9-2-1-2-0.tar.gz",
        "imageSourceType": "Imported from File System",
        "imageFamily": "AP1800S",
        "vendor": "CISCO",
        "filesize": 21872640,
        "deviceCount": 0,
        "deviceUuidList": [
        ],
        "smuCount": 0,
        "imageAttributes": [
          {
            "propertyName": "DEFAULT_BOOTROM",
            "propertyValue": "0"
          },
          {
            "propertyName": "VERSION",
            "propertyValue": "2.1.2.0"
          },
          {
            "propertyName": "MD5_CHECKSUM",
            "propertyValue": "UNKNOWN"
          },
          {
            "propertyName": "DEFAULT_RAM",
            "propertyValue": "0"
          },
          {
            "propertyName": "NAME",
            "propertyValue": "SW1800-SENSOR-K9-2-1-2-0.tar.gz"
          },
          {
            "propertyName": "APPLICATION_NAME",
            "propertyValue": "AP1800S"
          },
          {
            "propertyName": "DEFAULT_MINFLASHSIZE",
            "propertyValue": "0"
          },
          {
            "propertyName": "IMAGETYPE_VALUE",
            "propertyValue": "TGZ"
          },
          {
            "propertyName": "IMAGENAME",
            "propertyValue": "SW1800-SENSOR-K9-2-1-2-0"
          },
          {
            "propertyName": "MINDISKSIZE",
            "propertyValue": "0"
          }
        ],
        "isSMUApplicable": false,
        "goldenTagInheritedFromSiteUuid": null,
        "goldenTagInheritedFromSiteName": null,
        "importSupported": false,
        "reasonForNotSupportingImport": "Import from Cisco Aironet 1800S Active Sensor is not supported. Please import from url or local file.",
        "tagList": [
          {
            "taggedGolden": false,
            "role": "ALL",
            "inheritedSiteName": "",
            "inheritedSiteUuid": ""
          }
        ],
        "imageCategory": "PHYSICAL",
        "imageIntegrityStatus": "VERIFIED",
        "imageImported": true,
        "applicableDevicesForImage": [
          {
            "mdfId": "286318948",
            "productName": "Cisco Aironet 1800s Network Sensor",
            "productId": [
              "AIR-AP1800S-D-K9",
              "AIR-AP1800S-B-K9"
            ]
          }
        ],
        "profileInfo": null,
        "physicallyAvailable": true,
        "assignCount": 0,
        "showUnassign": false,
        "disableUnassign": false,
        "ccoreverseSync": false,
        "ccorecommended": false,
        "ccolatest": true
      },
      {
        "name": "SW1800-SENSOR-K9-1-3-3-0.tar.gz",
        "imageUuid": "aada9275-04c4-3478-8888-f4809403a3b3",
        "version": "1.3.3.0",
        "softwareType": null,
        "imageType": "TGZ_SW",
        "isTaggedGolden": "false",
        "md5Checksum": "5905ef8eaf900bdf0c286b84001db574",
        "shaCheckSum": "d20af50b615c345021ae0988e2c7f8c30653cc0e603777ed69e8fa03c4cc17ac83056849d11038b6419a6f879d5048a2db79b8253948b855498dd8a14da7ff54",
        "createdTime": "2020-01-16 16:11:23.0",
        "applicationType": "UNKNOWN",
        "feature": null,
        "fileServiceId": "0d149141-065a-6712-848a-caa8111dc7d6",
        "imageSeries": null,
        "imageSource": "file:/opt/maglev/services/swim-service/7.1.26.60138//localdisk/Staging/SW1800-SENSOR-K9-1-3-3-0.tar.gz",
        "imageSourceType": "Imported from File System",
        "imageFamily": "AP1800S",
        "vendor": "CISCO",
        "filesize": 25384960,
        "deviceCount": 0,
        "deviceUuidList": [
        ],
        "smuCount": 0,
        "imageAttributes": [
          {
            "propertyName": "DEFAULT_BOOTROM",
            "propertyValue": "0"
          },
          {
            "propertyName": "VERSION",
            "propertyValue": "1.3.3.0"
          },
          {
            "propertyName": "MD5_CHECKSUM",
            "propertyValue": "UNKNOWN"
          },
          {
            "propertyName": "DEFAULT_RAM",
            "propertyValue": "0"
          },
          {
            "propertyName": "NAME",
            "propertyValue": "SW1800-SENSOR-K9-1-3-3-0.tar.gz"
          },
          {
            "propertyName": "APPLICATION_NAME",
            "propertyValue": "AP1800S"
          },
          {
            "propertyName": "DEFAULT_MINFLASHSIZE",
            "propertyValue": "0"
          },
          {
            "propertyName": "IMAGETYPE_VALUE",
            "propertyValue": "TGZ"
          },
          {
            "propertyName": "IMAGENAME",
            "propertyValue": "SW1800-SENSOR-K9-1-3-3-0"
          },
          {
            "propertyName": "MINDISKSIZE",
            "propertyValue": "0"
          }
        ],
        "isSMUApplicable": false,
        "goldenTagInheritedFromSiteUuid": null,
        "goldenTagInheritedFromSiteName": null,
        "importSupported": false,
        "reasonForNotSupportingImport": "Import from Cisco Aironet 1800S Active Sensor is not supported. Please import from url or local file.",
        "tagList": [
          {
            "taggedGolden": false,
            "role": "ALL",
            "inheritedSiteName": "",
            "inheritedSiteUuid": ""
          }
        ],
        "imageCategory": "PHYSICAL",
        "imageIntegrityStatus": "VERIFIED",
        "imageImported": true,
        "applicableDevicesForImage": [
          {
            "mdfId": "286318948",
            "productName": "Cisco Aironet 1800s Network Sensor",
            "productId": [
              "AIR-AP1800S-D-K9",
              "AIR-AP1800S-B-K9"
            ]
          }
        ],
        "profileInfo": null,
        "physicallyAvailable": true,
        "assignCount": 0,
        "showUnassign": false,
        "disableUnassign": false,
        "ccoreverseSync": false,
        "ccorecommended": false,
        "ccolatest": true
      },
      {
        "name": "SW1800-SENSOR-K9-8-8-263-0.tar.gz",
        "imageUuid": "aada9275-04c4-3478-8942-f4809403a3b3",
        "version": "8.8.263.0",
        "softwareType": null,
        "imageType": "TGZ_SW",
        "isTaggedGolden": "false",
        "md5Checksum": "0db835ac12a595e10f6f5b29161062f7",
        "shaCheckSum": "a05ab5f06d88a509a1de44eaed6a6d729eeca124323b299422143fa3020ae4f4d4dccf24ff392fea39a339186e5494fadb4715479a9c14933a96478b7de9ccbf",
        "createdTime": "2019-09-24 16:11:23.0",
        "applicationType": "UNKNOWN",
        "feature": null,
        "fileServiceId": "0d149141-065a-6712-848a-caa8111dc7d6",
        "imageSeries": null,
        "imageSource": "file:/opt/maglev/services/swim-service/7.1.26.60138//localdisk/Staging/SW1800-SENSOR-K9-8-8-263-0.tar.gz",
        "imageSourceType": "Imported from File System",
        "imageFamily": "AP1800S",
        "vendor": "CISCO",
        "filesize": 25384960,
        "deviceCount": 0,
        "deviceUuidList": [
        ],
        "smuCount": 0,
        "imageAttributes": [
          {
            "propertyName": "DEFAULT_BOOTROM",
            "propertyValue": "0"
          },
          {
            "propertyName": "VERSION",
            "propertyValue": "8.8.263.0"
          },
          {
            "propertyName": "MD5_CHECKSUM",
            "propertyValue": "UNKNOWN"
          },
          {
            "propertyName": "DEFAULT_RAM",
            "propertyValue": "0"
          },
          {
            "propertyName": "NAME",
            "propertyValue": "SW1800-SENSOR-K9-8-8-263-0.tar.gz"
          },
          {
            "propertyName": "APPLICATION_NAME",
            "propertyValue": "AP1800S"
          },
          {
            "propertyName": "DEFAULT_MINFLASHSIZE",
            "propertyValue": "0"
          },
          {
            "propertyName": "IMAGETYPE_VALUE",
            "propertyValue": "TGZ"
          },
          {
            "propertyName": "IMAGENAME",
            "propertyValue": "SW1800-SENSOR-K9-8-8-263-0"
          },
          {
            "propertyName": "MINDISKSIZE",
            "propertyValue": "0"
          }
        ],
        "isSMUApplicable": false,
        "goldenTagInheritedFromSiteUuid": null,
        "goldenTagInheritedFromSiteName": null,
        "importSupported": false,
        "reasonForNotSupportingImport": "Import from Cisco Aironet 1800S Active Sensor is not supported. Please import from url or local file.",
        "tagList": [
          {
            "taggedGolden": false,
            "role": "ALL",
            "inheritedSiteName": "",
            "inheritedSiteUuid": ""
          }
        ],
        "imageCategory": "PHYSICAL",
        "imageIntegrityStatus": "VERIFIED",
        "imageImported": true,
        "applicableDevicesForImage": [
          {
            "mdfId": "286318948",
            "productName": "Cisco Aironet 1800s Network Sensor",
            "productId": [
              "AIR-AP1800S-D-K9",
              "AIR-AP1800S-B-K9"
            ]
          }
        ],
        "profileInfo": null,
        "physicallyAvailable": true,
        "assignCount": 0,
        "showUnassign": false,
        "disableUnassign": false,
        "ccoreverseSync": false,
        "ccorecommended": false,
        "ccolatest": true
      },
      {
        "name": "SW1800-SENSOR-K9-8-8-261-0.tar.gz",
        "imageUuid": "aada9275-04c4-4149-8942-f4809403a3b3",
        "version": "8.8.261.0",
        "softwareType": null,
        "imageType": "TGZ_SW",
        "isTaggedGolden": "false",
        "md5Checksum": "9b1ab6a8cf6a5bc6d746f11639520f6f",
        "shaCheckSum": "25c60969df349a7f29b2226bf60df9c3157743979aca5532bf0ad1e96dc27ec8ffaece3868d8b42458dbb4e494a5167d56ae7bfdeaf70e14714d405125e78c3e",
        "createdTime": "2019-04-28 01:56:23.0",
        "applicationType": "UNKNOWN",
        "feature": null,
        "fileServiceId": "0d149141-065a-4078-848a-caa8111dc7d6",
        "imageSeries": null,
        "imageSource": "file:/opt/maglev/services/swim-service/7.1.26.60138//localdisk/Staging/SW1800-SENSOR-K9-8-8-261-0.tar.gz",
        "imageSourceType": "Imported from File System",
        "imageFamily": "AP1800S",
        "vendor": "CISCO",
        "filesize": 25384960,
        "deviceCount": 0,
        "deviceUuidList": [
        ],
        "smuCount": 0,
        "imageAttributes": [
          {
            "propertyName": "DEFAULT_BOOTROM",
            "propertyValue": "0"
          },
          {
            "propertyName": "VERSION",
            "propertyValue": "8.8.261.0"
          },
          {
            "propertyName": "MD5_CHECKSUM",
            "propertyValue": "UNKNOWN"
          },
          {
            "propertyName": "DEFAULT_RAM",
            "propertyValue": "0"
          },
          {
            "propertyName": "NAME",
            "propertyValue": "SW1800-SENSOR-K9-8-8-261-0.tar.gz"
          },
          {
            "propertyName": "APPLICATION_NAME",
            "propertyValue": "AP1800S"
          },
          {
            "propertyName": "DEFAULT_MINFLASHSIZE",
            "propertyValue": "0"
          },
          {
            "propertyName": "IMAGETYPE_VALUE",
            "propertyValue": "TGZ"
          },
          {
            "propertyName": "IMAGENAME",
            "propertyValue": "SW1800-SENSOR-K9-8-8-261-0"
          },
          {
            "propertyName": "MINDISKSIZE",
            "propertyValue": "0"
          }
        ],
        "isSMUApplicable": false,
        "goldenTagInheritedFromSiteUuid": null,
        "goldenTagInheritedFromSiteName": null,
        "importSupported": false,
        "reasonForNotSupportingImport": "Import from Cisco Aironet 1800S Active Sensor is not supported. Please import from url or local file.",
        "tagList": [
          {
            "taggedGolden": false,
            "role": "ALL",
            "inheritedSiteName": "",
            "inheritedSiteUuid": ""
          }
        ],
        "imageCategory": "PHYSICAL",
        "imageIntegrityStatus": "VERIFIED",
        "imageImported": true,
        "applicableDevicesForImage": [
          {
            "mdfId": "286318948",
            "productName": "Cisco Aironet 1800s Network Sensor",
            "productId": [
              "AIR-AP1800S-D-K9",
              "AIR-AP1800S-B-K9"
            ]
          }
        ],
        "profileInfo": null,
        "physicallyAvailable": true,
        "assignCount": 0,
        "showUnassign": false,
        "disableUnassign": false,
        "ccoreverseSync": false,
        "ccorecommended": false,
        "ccolatest": false
      }
    ]
  });