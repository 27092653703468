define({
    "magellanService_Template": {
        "version": "1.5.1",
        "response": [
          {
            "name": "magellan",
            "appstackVersion": "1.0.0.215",
            "modified": 1587323789.50929,
            "restartCount": 0,
            "error": "",
            "spec": {
              "apps": {
                "magellan": {
                  "runtimeVersion": "8",
                  "artifactPath": "5b3c215f6074da8c9d6c697ff96d6836ee915d25ef90d444336c1e17cf0c9dad",
                  "version": "1.0.0.215",
                  "environment": {
                    "DEBUG": 1
                  },
                  "artifactSize": 42642979,
                  "health": {
                    "readiness": {
                      "delay": 30,
                      "periodSeconds": 30,
                      "uri": "/api/services/magellan/status",
                      "port": 8033,
                      "successThreshold": 1,
                      "failureThreshold": 5,
                      "name": "check_magellan_readiness",
                      "timeout": 30,
                      "type": "get"
                    },
                    "liveness": {
                      "delay": 30,
                      "periodSeconds": 30,
                      "uri": "/api/services/magellan/status",
                      "port": 8033,
                      "successThreshold": 1,
                      "failureThreshold": 5,
                      "name": "check_magellan_livenes",
                      "timeout": 30,
                      "type": "get"
                    }
                  },
                  "runtime": "java",
                  "_buildStatus": {
                    "completed": true,
                    "error": false,
                    "modified": 1587333163.211919,
                    "message": "Successfully built image 'magellan/magellan:1.0.0.215'"
                  },
                  "framework": "maglev_appcontainer",
                  "artifactSha256": "5b3c215f6074da8c9d6c697ff96d6836ee915d25ef90d444336c1e17cf0c9dad",
                  "frameworkVersion": "1.6.5",
                  "resources": {
                    "requests": {
                      "cpu": 1000,
                      "memory": 1024
                    },
                    "limits": {
                      "cpu": 1000,
                      "memory": 1024
                    }
                  },
                  "image": "maglev-registry.maglev-system.svc.cluster.local:5000/magellan/magellan:1.0.0.215"
                }
              },
              "nodeType": "web",
              "autoTune": {
                "vdnac_medium": {
                  "apps#magellan#resources#limits#memory": 1000,
                  "apps#magellan#resources#limits#cpu": 300,
                  "apps#magellan#resources#requests#cpu": 300,
                  "apps#magellan#resources#requests#memory": 800
                },
                "low": {
                  "apps#magellan#resources#limits#memory": 1000,
                  "apps#magellan#resources#limits#cpu": 300,
                  "apps#magellan#resources#requests#cpu": 300,
                  "apps#magellan#resources#requests#memory": 1000
                },
                "vdnac": {
                  "apps#magellan#resources#limits#memory": 1000,
                  "apps#magellan#resources#limits#cpu": 300,
                  "apps#magellan#resources#requests#cpu": 300,
                  "apps#magellan#resources#requests#memory": 800
                },
                "medium": {
                  "apps#magellan#resources#limits#memory": 1024,
                  "apps#magellan#resources#limits#cpu": 1000,
                  "apps#magellan#resources#requests#cpu": 1000,
                  "apps#magellan#resources#requests#memory": 1024
                },
                "t2_2xlarge": {
                  "apps#magellan#resources#limits#memory": 2048,
                  "apps#magellan#resources#limits#cpu": 1000,
                  "apps#magellan#resources#requests#cpu": 1000,
                  "apps#magellan#resources#requests#memory": 2048
                },
                "high": {
                  "apps#magellan#resources#limits#memory": 2048,
                  "apps#magellan#resources#limits#cpu": 1000,
                  "apps#magellan#resources#requests#cpu": 1000,
                  "apps#magellan#resources#requests#memory": 1024
                },
                "t2_large": {
                  "apps#magellan#resources#limits#memory": 2048,
                  "apps#magellan#resources#limits#cpu": 1000,
                  "apps#magellan#resources#requests#cpu": 1000,
                  "apps#magellan#resources#requests#memory": 1024
                },
                "vdnac_low": {
                  "apps#magellan#resources#limits#memory": 1000,
                  "apps#magellan#resources#limits#cpu": 300,
                  "apps#magellan#resources#requests#cpu": 300,
                  "apps#magellan#resources#requests#memory": 800
                },
                "vdnac_high": {
                  "apps#magellan#resources#limits#memory": 1000,
                  "apps#magellan#resources#limits#cpu": 300,
                  "apps#magellan#resources#requests#cpu": 300,
                  "apps#magellan#resources#requests#memory": 800
                }
              },
              "scaling": {
                "limits": {
                  "min": 1,
                  "max": 2
                },
                "nodeScale": true,
                "nodeAntiAffinity": true
              },
              "provides": {
                "resources": [
                  {
                    "name": "magellan-all-crud-resources",
                    "label": "Magellan API access policies",
                    "type": "Security.Group-Based Policy",
                    "operations": [
                      {
                        "name": "gRead",
                        "label": "Read  access for group-based-policy-analystics"
                      },
                      {
                        "name": "gUpdate",
                        "label": "Update access for group-based-policy-analystics"
                      },
                      {
                        "name": "gCreate",
                        "label": "Write access for group-based-policy-analystics"
                      },
                      {
                        "name": "gRemove",
                        "label": "Delete access for group-based-policy-analystics"
                      }
                    ]
                  }
                ],
                "defaultPermissionMappings": [
                  {
                    "permissionGroup": "SUPER-ADMIN-PERMISSION-GROUP, NETWORK-ADMIN-PERMISSION-GROUP",
                    "rbacResource": "magellan-all-crud-resources",
                    "permissionName": "FullAccess",
                    "operations": "gCreate,gRead,gUpdate,gRemove"
                  },
                  {
                    "permissionGroup": "OBSERVER-PERMISSION-GROUP",
                    "rbacResource": "magellan-all-crud-resources",
                    "permissionName": "ViewOnly",
                    "operations": "gRead"
                  }
                ],
                "ports": [
                  {
                    "port": 8033,
                    "protocol": "TCP",
                    "name": "magellan-api"
                  }
                ],
                "services": [
                  {
                    "rbacMappings": [
                      {
                        "resourceURL": "/",
                        "rbacResource": "magellan-all-crud-resources"
                      }
                    ],
                    "name": "default",
                    "apiUrlPath": "/magellan",
                    "stripPath": true,
                    "port": "$magellan-api"
                  }
                ]
              }
            },
            "errorDetails": "",
            "tenantId": "SYS0",
            "pods": [
              {
                "name": "magellan-65bc455f8b-5jjbg",
                "containers": [
                  {
                    "name": "magellan",
                    "ready": true,
                    "restartCount": 0,
                    "image": "maglev-registry.maglev-system.svc.cluster.local:5000/magellan/magellan:1.0.0.215"
                  }
                ],
                "startTime": "2020-04-20T07:47:12Z",
                "status": "Running",
                "startTimestamp": 1587368832,
                "podIP": "10.60.3.67",
                "hostIP": "10.1.150.20"
              }
            ],
            "kind": "ServiceBundle",
            "version": "1.0.0.215",
            "manifestVersion": "v15",
            "package": "group-based-policy-analytics",
            "_id": "5e9d50bb3620287b1cace560",
            "appstack": "magellan",
            "managed": false,
            "nodes": [
              "10.1.150.20"
            ],
            "repository": null,
            "uiSchema": {
              "properties": {
                "general": {
                  "required": [
                    "scaling"
                  ],
                  "title": "General",
                  "properties": {
                    "scaling": {
                      "required": [
                        "limits"
                      ],
                      "title": "Scaling",
                      "properties": {
                        "limits": {
                          "required": [
                            "min",
                            "max"
                          ],
                          "title": "Scale Limits",
                          "properties": {
                            "min": {
                              "minimum": 1,
                              "maximum": 3,
                              "type": "integer",
                              "title": "Min"
                            },
                            "max": {
                              "minimum": 1,
                              "maximum": 3,
                              "type": "integer",
                              "title": "Max"
                            }
                          },
                          "type": "object"
                        },
                        "autoscale": {
                          "title": "Auto Scale",
                          "type": "boolean"
                        }
                      },
                      "type": "object"
                    }
                  },
                  "type": "object"
                },
                "magellan": {
                  "title": "magellan",
                  "properties": {
                    "environment": {
                      "title": "Environment",
                      "properties": {
                        "DEBUG": {
                          "type": "string"
                        }
                      },
                      "type": "object"
                    },
                    "resources": {
                      "title": "Resources",
                      "properties": {
                        "requests": {
                          "title": "requests",
                          "properties": {
                            "cpu": {
                              "title": "CPU",
                              "type": "integer"
                            },
                            "memory": {
                              "title": "MEMORY",
                              "type": "integer"
                            }
                          },
                          "type": "object"
                        },
                        "limits": {
                          "title": "limits",
                          "properties": {
                            "cpu": {
                              "title": "CPU",
                              "type": "integer"
                            },
                            "memory": {
                              "title": "MEMORY",
                              "type": "integer"
                            }
                          },
                          "type": "object"
                        }
                      },
                      "type": "object"
                    }
                  },
                  "type": "object"
                }
              },
              "type": "object"
            },
            "fqn": "magellan/magellan:1.0.0.215",
            "status": "RUNNING",
            "_pullStatus": {
              "magellan": {
                "bytesDownloaded": 42598400
              }
            },
            "creationTimestamp": "2020-04-20T07:47:12Z"
          }
        ]
    },
    "endpointclassification_Template":{
        "groups": {
          "data": [
            "Cisco-Device",
            "Picture_Archiving_and_Communication_Systems",
            "Cisco-Access-Point",
            "CT-Scanner",
            "PACS",
            "1WaterPumpProfile",
            "1-Cell1",
            "Workstation",
            "1WaterFilterProfile",
            "Cisco-Switch",
            "Xerox-Device",
            "Windows7-Workstation",
            "1EA-Joff",
            "1EnergyProfile",
            "VMWare-Device",
            "1-Plant",
            "1HVACProfile",
            "Microsoft-Workstation",
            "Cisco-AP-Aironet-2700",
            "1LightingProfile",
            "CT-Scanner-ISEProfile"
          ],
          "total": 21
        },
        "pageNo": 1,
        "pageSize": 10,
        "startTime": 1591520924000,
        "endTime": 1591607320000,
        "eventTime": 1591607324000
    },
    "hostgroups_Template":{
        "groups": {
          "data": [
            "Link-Local",
            "Catch All",
            "Other Fabric Sites",
            "Fabric Site 1",
            "Unknown",
            "Localhost"
          ],
          "total": 6
        },
        "pageNo": 1,
        "pageSize": 10,
        "startTime": 1591520924000,
        "endTime": 1591607320000,
        "eventTime": 1591607324000
    },
    "ScalableGroups_Template":{
        "groups": {
          "data": [
            "Storage"
          ],
          "total": 1
        },
        "pageNo": 1,
        "pageSize": 10,
        "startTime": 1591520924000,
        "endTime": 1591607320000,
        "eventTime": 1591607324000
    },
    "SGSummary_Template":{
        "groupViewList": {
          "totalCount": 9,
          "groupList": [
            {
              "sourcegroup": "Developers",
              "children": [
                {
                  "parent": "Developers",
                  "destination": "Unknown",
                  "uniqueflowcount": 12
                },
                {
                  "parent": "Developers",
                  "destination": "HVAC",
                  "uniqueflowcount": 5
                },
                {
                  "parent": "Developers",
                  "destination": "Lighting",
                  "uniqueflowcount": 5
                },
                {
                  "parent": "Developers",
                  "destination": "Guests",
                  "uniqueflowcount": 5
                },
                {
                  "parent": "Developers",
                  "destination": "Doctors",
                  "uniqueflowcount": 4
                }
              ]
            },
            {
              "sourcegroup": "HVAC",
              "children": [
                {
                  "parent": "HVAC",
                  "destination": "Unknown",
                  "uniqueflowcount": 13
                },
                {
                  "parent": "HVAC",
                  "destination": "Developers",
                  "uniqueflowcount": 6
                },
                {
                  "parent": "HVAC",
                  "destination": "Guests",
                  "uniqueflowcount": 6
                },
                {
                  "parent": "HVAC",
                  "destination": "Doctors",
                  "uniqueflowcount": 5
                },
                {
                  "parent": "HVAC",
                  "destination": "Lighting",
                  "uniqueflowcount": 5
                },
                {
                  "parent": "HVAC",
                  "destination": "Employees",
                  "uniqueflowcount": 4
                },
                {
                  "parent": "HVAC",
                  "destination": "HVAC",
                  "uniqueflowcount": 4
                },
                {
                  "parent": "HVAC",
                  "destination": "Printer_EPG",
                  "uniqueflowcount": 1
                }
              ]
            },
            {
              "sourcegroup": "Contractors",
              "children": [
                {
                  "parent": "Contractors",
                  "destination": "Unknown",
                  "uniqueflowcount": 13
                }
              ]
            },
            {
              "sourcegroup": "Unknown",
              "children": [
                {
                  "parent": "Unknown",
                  "destination": "HVAC",
                  "uniqueflowcount": 24
                },
                {
                  "parent": "Unknown",
                  "destination": "Unknown",
                  "uniqueflowcount": 5
                },
                {
                  "parent": "Unknown",
                  "destination": "Printer_EPG",
                  "uniqueflowcount": 3
                },
                {
                  "parent": "Unknown",
                  "destination": "Guests",
                  "uniqueflowcount": 3
                },
                {
                  "parent": "Unknown",
                  "destination": "Doctors",
                  "uniqueflowcount": 2
                },
                {
                  "parent": "Unknown",
                  "destination": "Employees",
                  "uniqueflowcount": 2
                },
                {
                  "parent": "Unknown",
                  "destination": "Developers",
                  "uniqueflowcount": 2
                },
                {
                  "parent": "Unknown",
                  "destination": "Lighting",
                  "uniqueflowcount": 2
                },
                {
                  "parent": "Unknown",
                  "destination": "Contractors",
                  "uniqueflowcount": 2
                }
              ]
            },
            {
              "sourcegroup": "Lighting",
              "children": [
                {
                  "parent": "Lighting",
                  "destination": "Unknown",
                  "uniqueflowcount": 10
                },
                {
                  "parent": "Lighting",
                  "destination": "Developers",
                  "uniqueflowcount": 10
                },
                {
                  "parent": "Lighting",
                  "destination": "HVAC",
                  "uniqueflowcount": 10
                },
                {
                  "parent": "Lighting",
                  "destination": "Guests",
                  "uniqueflowcount": 10
                },
                {
                  "parent": "Lighting",
                  "destination": "Employees",
                  "uniqueflowcount": 10
                }
              ]
            },
            {
              "sourcegroup": "Printer_EPG",
              "children": [
                {
                  "parent": "Printer_EPG",
                  "destination": "Unknown",
                  "uniqueflowcount": 15
                },
                {
                  "parent": "Printer_EPG",
                  "destination": "Printer_EPG",
                  "uniqueflowcount": 3
                },
                {
                  "parent": "Printer_EPG",
                  "destination": "Contractors",
                  "uniqueflowcount": 3
                },
                {
                  "parent": "Printer_EPG",
                  "destination": "HVAC",
                  "uniqueflowcount": 1
                }
              ]
            },
            {
              "sourcegroup": "Employees",
              "children": [
                {
                  "parent": "Employees",
                  "destination": "Unknown",
                  "uniqueflowcount": 12
                },
                {
                  "parent": "Employees",
                  "destination": "HVAC",
                  "uniqueflowcount": 2
                },
                {
                  "parent": "Employees",
                  "destination": "Lighting",
                  "uniqueflowcount": 2
                },
                {
                  "parent": "Employees",
                  "destination": "Guests",
                  "uniqueflowcount": 2
                },
                {
                  "parent": "Employees",
                  "destination": "Doctors",
                  "uniqueflowcount": 1
                }
              ]
            },
            {
              "sourcegroup": "Doctors",
              "children": [
                {
                  "parent": "Doctors",
                  "destination": "Unknown",
                  "uniqueflowcount": 14
                },
                {
                  "parent": "Doctors",
                  "destination": "Developers",
                  "uniqueflowcount": 2
                },
                {
                  "parent": "Doctors",
                  "destination": "HVAC",
                  "uniqueflowcount": 2
                },
                {
                  "parent": "Doctors",
                  "destination": "Employees",
                  "uniqueflowcount": 1
                },
                {
                  "parent": "Doctors",
                  "destination": "Guests",
                  "uniqueflowcount": 1
                }
              ]
            },
            {
              "sourcegroup": "Guests",
              "children": [
                {
                  "parent": "Guests",
                  "destination": "Unknown",
                  "uniqueflowcount": 22
                },
                {
                  "parent": "Guests",
                  "destination": "Developers",
                  "uniqueflowcount": 5
                },
                {
                  "parent": "Guests",
                  "destination": "HVAC",
                  "uniqueflowcount": 5
                },
                {
                  "parent": "Guests",
                  "destination": "Lighting",
                  "uniqueflowcount": 5
                },
                {
                  "parent": "Guests",
                  "destination": "Guests",
                  "uniqueflowcount": 5
                },
                {
                  "parent": "Guests",
                  "destination": "Employees",
                  "uniqueflowcount": 4
                }
              ]
            }
          ]
        },
        "pageNo": 1,
        "pageSize": 25,
        "startTime": 1590993000000,
        "endTime": 1591079339000,
        "eventTime": 1591696442000
    },
    "SGActivityDetail_Template":{
        "activitiesDetails": {
          "activitiesList": [
            {
              "port": "67",
              "protocol": "UDP",
              "direction": "bidirectional",
              "servicename": "bootps"
            },
            {
              "port": "53",
              "protocol": "UDP",
              "direction": "bidirectional",
              "servicename": "domain"
            },
            {
              "port": "80",
              "protocol": "TCP",
              "direction": "outbound",
              "servicename": "http"
            },
            {
              "port": "443",
              "protocol": "TCP",
              "direction": "outbound",
              "servicename": "https"
            },
            {
              "port": "21",
              "protocol": "TCP",
              "direction": "outbound",
              "servicename": "ftp"
            },
            {
              "port": "5355",
              "protocol": "UDP",
              "direction": "outbound",
              "servicename": "llmnr"
            },
            {
              "port": "138",
              "protocol": "UDP",
              "direction": "outbound",
              "servicename": "netbios-dgm"
            },
            {
              "port": "137",
              "protocol": "UDP",
              "direction": "outbound",
              "servicename": "netbios-ns"
            },
            {
              "port": "771",
              "protocol": "ICMP",
              "direction": "outbound",
              "servicename": "rtip"
            },
            {
              "port": "1900",
              "protocol": "UDP",
              "direction": "outbound",
              "servicename": "ssdp"
            }
          ],
          "totalCount": 10
        },
        "pageNo": 1,
        "pageSize": 10,
        "startTime": 1591608600000,
        "endTime": 1591694996000,
        "eventTime": 1591702089000
    },
    "endpoint_summary_Template":{
        "groupViewList": {
          "totalCount": 8,
          "groupList": [
            {
              "sourcegroup": "1WaterFilterProfile",
              "children": [
                {
                  "parent": "1WaterFilterProfile",
                  "destination": "Unknown",
                  "uniqueflowcount": 12
                },
                {
                  "parent": "1WaterFilterProfile",
                  "destination": "Developers",
                  "uniqueflowcount": 5
                },
                {
                  "parent": "1WaterFilterProfile",
                  "destination": "HVAC",
                  "uniqueflowcount": 5
                },
                {
                  "parent": "1WaterFilterProfile",
                  "destination": "Guests",
                  "uniqueflowcount": 5
                },
                {
                  "parent": "1WaterFilterProfile",
                  "destination": "Employees",
                  "uniqueflowcount": 4
                }
              ]
            },
            {
              "sourcegroup": "CT-Scanner",
              "children": [
                {
                  "parent": "CT-Scanner",
                  "destination": "Unknown",
                  "uniqueflowcount": 12
                },
                {
                  "parent": "CT-Scanner",
                  "destination": "Contractors",
                  "uniqueflowcount": 3
                },
                {
                  "parent": "CT-Scanner",
                  "destination": "HVAC",
                  "uniqueflowcount": 1
                }
              ]
            },
            {
              "sourcegroup": "PACS",
              "children": [
                {
                  "parent": "PACS",
                  "destination": "Unknown",
                  "uniqueflowcount": 13
                }
              ]
            },
            {
              "sourcegroup": "1EnergyProfile",
              "children": [
                {
                  "parent": "1EnergyProfile",
                  "destination": "Unknown",
                  "uniqueflowcount": 13
                },
                {
                  "parent": "1EnergyProfile",
                  "destination": "HVAC",
                  "uniqueflowcount": 5
                },
                {
                  "parent": "1EnergyProfile",
                  "destination": "Lighting",
                  "uniqueflowcount": 5
                },
                {
                  "parent": "1EnergyProfile",
                  "destination": "Guests",
                  "uniqueflowcount": 5
                },
                {
                  "parent": "1EnergyProfile",
                  "destination": "Doctors",
                  "uniqueflowcount": 4
                }
              ]
            },
            {
              "sourcegroup": "1WaterPumpProfile",
              "children": [
                {
                  "parent": "1WaterPumpProfile",
                  "destination": "Unknown",
                  "uniqueflowcount": 22
                },
                {
                  "parent": "1WaterPumpProfile",
                  "destination": "Developers",
                  "uniqueflowcount": 5
                },
                {
                  "parent": "1WaterPumpProfile",
                  "destination": "HVAC",
                  "uniqueflowcount": 5
                },
                {
                  "parent": "1WaterPumpProfile",
                  "destination": "Lighting",
                  "uniqueflowcount": 5
                },
                {
                  "parent": "1WaterPumpProfile",
                  "destination": "Guests",
                  "uniqueflowcount": 5
                }
              ]
            },
            {
              "sourcegroup": "1HVACProfile",
              "children": [
                {
                  "parent": "1HVACProfile",
                  "destination": "Unknown",
                  "uniqueflowcount": 14
                },
                {
                  "parent": "1HVACProfile",
                  "destination": "Developers",
                  "uniqueflowcount": 6
                },
                {
                  "parent": "1HVACProfile",
                  "destination": "Guests",
                  "uniqueflowcount": 6
                },
                {
                  "parent": "1HVACProfile",
                  "destination": "Lighting",
                  "uniqueflowcount": 5
                },
                {
                  "parent": "1HVACProfile",
                  "destination": "Doctors",
                  "uniqueflowcount": 4
                },
                {
                  "parent": "1HVACProfile",
                  "destination": "Employees",
                  "uniqueflowcount": 4
                },
                {
                  "parent": "1HVACProfile",
                  "destination": "HVAC",
                  "uniqueflowcount": 4
                },
                {
                  "parent": "1HVACProfile",
                  "destination": "Printer_EPG",
                  "uniqueflowcount": 1
                }
              ]
            },
            {
              "sourcegroup": "1LightingProfile",
              "children": [
                {
                  "parent": "1LightingProfile",
                  "destination": "Unknown",
                  "uniqueflowcount": 12
                },
                {
                  "parent": "1LightingProfile",
                  "destination": "Developers",
                  "uniqueflowcount": 5
                },
                {
                  "parent": "1LightingProfile",
                  "destination": "HVAC",
                  "uniqueflowcount": 5
                },
                {
                  "parent": "1LightingProfile",
                  "destination": "Guests",
                  "uniqueflowcount": 5
                },
                {
                  "parent": "1LightingProfile",
                  "destination": "Employees",
                  "uniqueflowcount": 4
                }
              ]
            },
            {
              "sourcegroup": "Windows7-Workstation",
              "children": [
                {
                  "parent": "Windows7-Workstation",
                  "destination": "Unknown",
                  "uniqueflowcount": 14
                },
                {
                  "parent": "Windows7-Workstation",
                  "destination": "Developers",
                  "uniqueflowcount": 2
                },
                {
                  "parent": "Windows7-Workstation",
                  "destination": "HVAC",
                  "uniqueflowcount": 2
                },
                {
                  "parent": "Windows7-Workstation",
                  "destination": "Lighting",
                  "uniqueflowcount": 2
                },
                {
                  "parent": "Windows7-Workstation",
                  "destination": "Guests",
                  "uniqueflowcount": 2
                },
                {
                  "parent": "Windows7-Workstation",
                  "destination": "Doctors",
                  "uniqueflowcount": 1
                },
                {
                  "parent": "Windows7-Workstation",
                  "destination": "Employees",
                  "uniqueflowcount": 1
                }
              ]
            }
          ]
        },
        "pageNo": 1,
        "pageSize": 25,
        "startTime": 1591021800000,
        "endTime": 1591108139000,
        "eventTime": 1591713870000
    },
    "hostgroups_summary_Template":{
        "groupViewList": {
          "totalCount": 5,
          "groupList": [
            {
              "sourcegroup": "Link-Local",
              "children": [
                {
                  "parent": "Link-Local",
                  "destination": "Unknown",
                  "uniqueflowcount": 22
                },
                {
                  "parent": "Link-Local",
                  "destination": "Developers",
                  "uniqueflowcount": 6
                },
                {
                  "parent": "Link-Local",
                  "destination": "HVAC",
                  "uniqueflowcount": 6
                },
                {
                  "parent": "Link-Local",
                  "destination": "Lighting",
                  "uniqueflowcount": 5
                },
                {
                  "parent": "Link-Local",
                  "destination": "Guests",
                  "uniqueflowcount": 5
                },
                {
                  "parent": "Link-Local",
                  "destination": "Doctors",
                  "uniqueflowcount": 4
                },
                {
                  "parent": "Link-Local",
                  "destination": "Employees",
                  "uniqueflowcount": 4
                },
                {
                  "parent": "Link-Local",
                  "destination": "Contractors",
                  "uniqueflowcount": 3
                },
                {
                  "parent": "Link-Local",
                  "destination": "Printer_EPG",
                  "uniqueflowcount": 1
                }
              ]
            },
            {
              "sourcegroup": "Fabric Site 1",
              "children": [
                {
                  "parent": "Fabric Site 1",
                  "destination": "Unknown",
                  "uniqueflowcount": 22
                },
                {
                  "parent": "Fabric Site 1",
                  "destination": "Developers",
                  "uniqueflowcount": 6
                },
                {
                  "parent": "Fabric Site 1",
                  "destination": "HVAC",
                  "uniqueflowcount": 6
                },
                {
                  "parent": "Fabric Site 1",
                  "destination": "Lighting",
                  "uniqueflowcount": 5
                },
                {
                  "parent": "Fabric Site 1",
                  "destination": "Guests",
                  "uniqueflowcount": 5
                },
                {
                  "parent": "Fabric Site 1",
                  "destination": "Doctors",
                  "uniqueflowcount": 4
                },
                {
                  "parent": "Fabric Site 1",
                  "destination": "Employees",
                  "uniqueflowcount": 4
                },
                {
                  "parent": "Fabric Site 1",
                  "destination": "Contractors",
                  "uniqueflowcount": 3
                },
                {
                  "parent": "Fabric Site 1",
                  "destination": "Printer_EPG",
                  "uniqueflowcount": 1
                }
              ]
            },
            {
              "sourcegroup": "Unknown",
              "children": [
                {
                  "parent": "Unknown",
                  "destination": "HVAC",
                  "uniqueflowcount": 25
                },
                {
                  "parent": "Unknown",
                  "destination": "Guests",
                  "uniqueflowcount": 3
                },
                {
                  "parent": "Unknown",
                  "destination": "Doctors",
                  "uniqueflowcount": 2
                },
                {
                  "parent": "Unknown",
                  "destination": "Employees",
                  "uniqueflowcount": 2
                },
                {
                  "parent": "Unknown",
                  "destination": "Developers",
                  "uniqueflowcount": 2
                },
                {
                  "parent": "Unknown",
                  "destination": "Lighting",
                  "uniqueflowcount": 2
                },
                {
                  "parent": "Unknown",
                  "destination": "Printer_EPG",
                  "uniqueflowcount": 2
                },
                {
                  "parent": "Unknown",
                  "destination": "Contractors",
                  "uniqueflowcount": 2
                }
              ]
            },
            {
              "sourcegroup": "Other Fabric Sites",
              "children": [
                {
                  "parent": "Other Fabric Sites",
                  "destination": "Unknown",
                  "uniqueflowcount": 13
                },
                {
                  "parent": "Other Fabric Sites",
                  "destination": "Developers",
                  "uniqueflowcount": 5
                },
                {
                  "parent": "Other Fabric Sites",
                  "destination": "HVAC",
                  "uniqueflowcount": 5
                },
                {
                  "parent": "Other Fabric Sites",
                  "destination": "Guests",
                  "uniqueflowcount": 5
                },
                {
                  "parent": "Other Fabric Sites",
                  "destination": "Employees",
                  "uniqueflowcount": 4
                },
                {
                  "parent": "Other Fabric Sites",
                  "destination": "Lighting",
                  "uniqueflowcount": 4
                }
              ]
            },
            {
              "sourcegroup": "Catch All",
              "children": [
                {
                  "parent": "Catch All",
                  "destination": "Unknown",
                  "uniqueflowcount": 22
                },
                {
                  "parent": "Catch All",
                  "destination": "Developers",
                  "uniqueflowcount": 6
                },
                {
                  "parent": "Catch All",
                  "destination": "HVAC",
                  "uniqueflowcount": 6
                },
                {
                  "parent": "Catch All",
                  "destination": "Lighting",
                  "uniqueflowcount": 5
                },
                {
                  "parent": "Catch All",
                  "destination": "Guests",
                  "uniqueflowcount": 5
                },
                {
                  "parent": "Catch All",
                  "destination": "Doctors",
                  "uniqueflowcount": 4
                },
                {
                  "parent": "Catch All",
                  "destination": "Employees",
                  "uniqueflowcount": 4
                },
                {
                  "parent": "Catch All",
                  "destination": "Contractors",
                  "uniqueflowcount": 3
                },
                {
                  "parent": "Catch All",
                  "destination": "Printer_EPG",
                  "uniqueflowcount": 1
                }
              ]
            }
          ]
        },
        "pageNo": 1,
        "pageSize": 25,
        "startTime": 1591626600000,
        "endTime": 1591712939000,
        "eventTime": 1591716620000
    },
    "SGActivitiesvisuals_Template":{
        "activitiesVisuals": {
          "source": "Storage",
          "destGroups": [
            {
              "id": "2ae0e8dd13c74d928f33b34229130efe",
              "name": "Unknown",
              "direction": "outbound",
              "numOfFlows": 13
            }
          ],
          "totalNumOfGroups": 1
        },
        "pageNo": 1,
        "pageSize": 10,
        "startTime": 1591777800000,
        "endTime": 1591864196000,
        "eventTime": 1591866673000
    },
    "Search_Template":{
        "endpointsList": {
          "data": [
            {
              "guid": "9ad0399631ca40689dcf2b6cf9f6deec",
              "destipaddress": "10.6.5.100",
              "macaddress": "MACUNRESOLVED_10.1.100.10",
              "classification": " ",
              "scalablegroup": "Unknown",
              "location": "UNRESOLVED",
              "nad": "UNRESOLVED",
              "hostgroup": "Unknown",
              "sourceipaddress": "10.1.100.10",
              "destclassification": " ",
              "desthostgroup": "Unknown",
              "destlocation": "UNRESOLVED",
              "destmacaddress": "58:0A:20:FA:4F:64",
              "destnad": "UNRESOLVED",
              "destscalablegroup": "Unknown"
            },
            {
              "guid": "db524acc96394609bf51f5803eef7402",
              "destipaddress": "10.4.1.113",
              "macaddress": "MACUNRESOLVED_10.1.100.10",
              "classification": " ",
              "scalablegroup": "Unknown",
              "location": "UNRESOLVED",
              "nad": "UNRESOLVED",
              "hostgroup": "Unknown",
              "sourceipaddress": "10.1.100.10",
              "sourceipaddress": "10.1.100.10",
              "destclassification": " ",
              "desthostgroup": "Unknown",
              "destlocation": "UNRESOLVED",
              "destmacaddress": "00:f6:65:b8:23:30",
              "destnad": "UNRESOLVED",
              "destscalablegroup": "Unknown"
            },
            {
              "guid": "db614a6ab76d4e34bdffafccb11eb22c",
              "destipaddress": "10.4.1.121",
              "macaddress": "MACUNRESOLVED_10.1.100.10",
              "classification": " ",
              "scalablegroup": "Unknown",
              "location": "UNRESOLVED",
              "nad": "UNRESOLVED",
              "hostgroup": "Unknown",
              "sourceipaddress": "10.1.100.10",
              "sourceipaddress": "10.1.100.10",
              "destclassification": " ",
              "desthostgroup": "Unknown",
              "destlocation": "UNRESOLVED",
              "destmacaddress": "00:f7:66:b8:23:30",
              "destnad": "UNRESOLVED",
              "destscalablegroup": "Unknown"
            },
            {
              "guid": "b4c4f42f8cf94abca0321ae79d90c0a2",
              "destipaddress": "10.4.1.122",
              "macaddress": "MACUNRESOLVED_10.1.100.10",
              "classification": " ",
              "scalablegroup": "Unknown",
              "location": "UNRESOLVED",
              "nad": "UNRESOLVED",
              "hostgroup": "Unknown",
              "sourceipaddress": "10.1.100.10",
              "sourceipaddress": "10.1.100.10",
              "destclassification": " ",
              "desthostgroup": "Unknown",
              "destlocation": "UNRESOLVED",
              "destmacaddress": "00:f7:69:b8:23:30",
              "destnad": "UNRESOLVED",
              "destscalablegroup": "Unknown"
            },
            {
              "guid": "934fd39d5d3047ea8b0c6f57c93a2e4d",
              "destipaddress": "10.4.1.123",
              "macaddress": "MACUNRESOLVED_10.1.100.10",
              "classification": " ",
              "scalablegroup": "Unknown",
              "location": "UNRESOLVED",
              "nad": "UNRESOLVED",
              "hostgroup": "Unknown",
              "sourceipaddress": "10.1.100.10",
              "sourceipaddress": "10.1.100.10",
              "destclassification": " ",
              "desthostgroup": "Unknown",
              "destlocation": "UNRESOLVED",
              "destmacaddress": "00:f7:66:b9:24:31",
              "destnad": "UNRESOLVED",
              "destscalablegroup": "Unknown"
            },
            {
              "guid": "4ab9d8570d83429196299e1b5510f811",
              "destipaddress": "10.4.1.124",
              "macaddress": "MACUNRESOLVED_10.1.100.10",
              "classification": " ",
              "scalablegroup": "Unknown",
              "location": "UNRESOLVED",
              "nad": "UNRESOLVED",
              "hostgroup": "Unknown",
              "sourceipaddress": "10.1.100.10",
              "sourceipaddress": "10.1.100.10",
              "destclassification": " ",
              "desthostgroup": "Unknown",
              "destlocation": "UNRESOLVED",
              "destmacaddress": "00:f9:66:b7:23:39",
              "destnad": "UNRESOLVED",
              "destscalablegroup": "Unknown"
            },
            {
              "guid": "a5888fa935434ebab8797d1ef56150d5",
              "destipaddress": "10.5.1.115",
              "macaddress": "MACUNRESOLVED_10.1.100.10",
              "classification": " ",
              "scalablegroup": "Unknown",
              "location": "UNRESOLVED",
              "nad": "UNRESOLVED",
              "hostgroup": "Unknown",
              "sourceipaddress": "10.1.100.10",
              "sourceipaddress": "10.1.100.10",
              "destclassification": " ",
              "desthostgroup": "Unknown",
              "destlocation": "UNRESOLVED",
              "destmacaddress": "00:f7:77:c8:23:30",
              "destnad": "UNRESOLVED",
              "destscalablegroup": "Unknown"
            },
            {
              "guid": "4d2ba7a42fe646da9c47381ce562ce90",
              "destipaddress": "10.5.1.116",
              "macaddress": "MACUNRESOLVED_10.1.100.10",
              "classification": " ",
              "scalablegroup": "Unknown",
              "location": "UNRESOLVED",
              "nad": "UNRESOLVED",
              "hostgroup": "Unknown",
              "sourceipaddress": "10.1.100.10",
              "sourceipaddress": "10.1.100.10",
              "destclassification": " ",
              "desthostgroup": "Unknown",
              "destlocation": "UNRESOLVED",
              "destmacaddress": "00:f7:88:b8:23:30",
              "destnad": "UNRESOLVED",
              "destscalablegroup": "Unknown"
            },
            {
              "guid": "b211cd90ea724c81a607665f79933042",
              "destipaddress": "10.5.1.117",
              "macaddress": "MACUNRESOLVED_10.1.100.10",
              "classification": " ",
              "scalablegroup": "Unknown",
              "location": "UNRESOLVED",
              "nad": "UNRESOLVED",
              "hostgroup": "Unknown",
              "sourceipaddress": "10.1.100.10",
              "sourceipaddress": "10.1.100.10",
              "destclassification": " ",
              "desthostgroup": "Unknown",
              "destlocation": "UNRESOLVED",
              "destmacaddress": "00:f1:99:b8:23:30",
              "destnad": "UNRESOLVED",
              "destscalablegroup": "Unknown"
            },
            {
              "guid": "21b4d49d6c0f45beb1e829df1b4ef9bc",
              "destipaddress": "10.5.1.118",
              "macaddress": "MACUNRESOLVED_10.1.100.10",
              "classification": " ",
              "scalablegroup": "Unknown",
              "location": "UNRESOLVED",
              "nad": "UNRESOLVED",
              "hostgroup": "Unknown",
              "sourceipaddress": "10.1.100.10",
              "sourceipaddress": "10.1.100.10",
              "destclassification": " ",
              "desthostgroup": "Unknown",
              "destlocation": "UNRESOLVED",
              "destmacaddress": "00:f2:56:b8:23:30",
              "destnad": "UNRESOLVED",
              "destscalablegroup": "Unknown"
            }
          ],
          "total": 10
        },
        "pageNo": 1,
        "pageSize": 10,
        "startTime": 1592148600000,
        "endTime": 1592234994000,
        "eventTime": 1592236890000
    },

    "ScalableGroup_Filter_ISE_Classification":{
      "groupViewList": {
        "totalCount": 9,
        "groupList": [
          {
            "sourcegroup": "Developers",
            "children": [
              {
                "parent": "Developers",
                "destination": "1WaterFilterProfile",
                "uniqueflowcount": 12
              },
              {
                "parent": "Developers",
                "destination": "CT-Scanner",
                "uniqueflowcount": 5
              },
              {
                "parent": "Developers",
                "destination": "PACS",
                "uniqueflowcount": 5
              },
              {
                "parent": "Developers",
                "destination": "1EnergyProfile",
                "uniqueflowcount": 5
              },
              {
                "parent": "Developers",
                "destination": "1WaterPumpProfile",
                "uniqueflowcount": 4
              }
            ]
          },
          {
            "sourcegroup": "HVAC",
            "children": [
              {
                "parent": "HVAC",
                "destination": "1WaterFilterProfile",
                "uniqueflowcount": 13
              },
              {
                "parent": "HVAC",
                "destination": "CT-Scanner",
                "uniqueflowcount": 6
              },
              {
                "parent": "HVAC",
                "destination": "PACS",
                "uniqueflowcount": 6
              },
              {
                "parent": "HVAC",
                "destination": "1EnergyProfile",
                "uniqueflowcount": 5
              },
              {
                "parent": "HVAC",
                "destination": "1WaterPumpProfile",
                "uniqueflowcount": 5
              },
              {
                "parent": "HVAC",
                "destination": "1HVACProfile",
                "uniqueflowcount": 4
              },
              {
                "parent": "HVAC",
                "destination": "1LightingProfile",
                "uniqueflowcount": 4
              },
              {
                "parent": "HVAC",
                "destination": "Windows7-Workstation",
                "uniqueflowcount": 1
              }
            ]
          },
          {
            "sourcegroup": "Contractors",
            "children": [
              {
                "parent": "Contractors",
                "destination": "Windows7-Workstation",
                "uniqueflowcount": 13
              }
            ]
          },
          {
            "sourcegroup": "Unknown",
            "children": [
              {
                "parent": "Unknown",
                "destination": "1WaterFilterProfile",
                "uniqueflowcount": 24
              },
              {
                "parent": "Unknown",
                "destination": "CT-Scanner",
                "uniqueflowcount": 5
              },
              {
                "parent": "Unknown",
                "destination": "PACS",
                "uniqueflowcount": 3
              },
              {
                "parent": "Unknown",
                "destination": "1EnergyProfile",
                "uniqueflowcount": 3
              },
              {
                "parent": "Unknown",
                "destination": "1WaterPumpProfile",
                "uniqueflowcount": 2
              },
              {
                "parent": "Unknown",
                "destination": "1HVACProfile",
                "uniqueflowcount": 2
              },
              {
                "parent": "Unknown",
                "destination": "1LightingProfile",
                "uniqueflowcount": 2
              },
              {
                "parent": "Unknown",
                "destination": "Windows7-Workstation",
                "uniqueflowcount": 2
              }
            ]
          },
          {
            "sourcegroup": "Lighting",
            "children": [
              {
                "parent": "Lighting",
                "destination": "1WaterFilterProfile",
                "uniqueflowcount": 13
              },
              {
                "parent": "Lighting",
                "destination": "CT-Scanner",
                "uniqueflowcount": 5
              },
              {
                "parent": "Lighting",
                "destination": "PACS",
                "uniqueflowcount": 5
              },
              {
                "parent": "Lighting",
                "destination": "1EnergyProfile",
                "uniqueflowcount": 5
              },
              {
                "parent": "Lighting",
                "destination": "1WaterPumpProfile",
                "uniqueflowcount": 4
              }
            ]
          },
          {
            "sourcegroup": "Printer_EPG",
            "children": [
              {
                "parent": "Printer_EPG",
                "destination": "1WaterFilterProfile",
                "uniqueflowcount": 15
              },
              {
                "parent": "Printer_EPG",
                "destination": "CT-Scanner",
                "uniqueflowcount": 3
              },
              {
                "parent": "Printer_EPG",
                "destination": "PACS",
                "uniqueflowcount": 3
              },
              {
                "parent": "Printer_EPG",
                "destination": "1EnergyProfile",
                "uniqueflowcount": 1
              }
            ]
          },
          {
            "sourcegroup": "Employees",
            "children": [
              {
                "parent": "Employees",
                "destination": "Windows7-Workstation",
                "uniqueflowcount": 12
              },
              {
                "parent": "Employees",
                "destination": "1LightingProfile",
                "uniqueflowcount": 2
              },
              {
                "parent": "Employees",
                "destination": "PACS",
                "uniqueflowcount": 2
              },
              {
                "parent": "Employees",
                "destination": "CT-Scanner",
                "uniqueflowcount": 2
              },
              {
                "parent": "Employees",
                "destination": "1WaterFilterProfile",
                "uniqueflowcount": 1
              }
            ]
          },
          {
            "sourcegroup": "Doctors",
            "children": [
              {
                "parent": "Doctors",
                "destination": "1WaterFilterProfile",
                "uniqueflowcount": 14
              },
              {
                "parent": "Doctors",
                "destination": "CT-Scanner",
                "uniqueflowcount": 2
              },
              {
                "parent": "Doctors",
                "destination": "PACS",
                "uniqueflowcount": 2
              },
              {
                "parent": "Doctors",
                "destination": "1EnergyProfile",
                "uniqueflowcount": 1
              },
              {
                "parent": "Doctors",
                "destination": "1WaterPumpProfile",
                "uniqueflowcount": 1
              }
            ]
          },
          {
            "sourcegroup": "Guests",
            "children": [
              {
                "parent": "Guests",
                "destination": "Windows7-Workstation",
                "uniqueflowcount": 22
              },
              {
                "parent": "Guests",
                "destination": "1LightingProfile",
                "uniqueflowcount": 5
              },
              {
                "parent": "Guests",
                "destination": "1HVACProfile",
                "uniqueflowcount": 5
              },
              {
                "parent": "Guests",
                "destination": "1WaterPumpProfile",
                "uniqueflowcount": 5
              },
              {
                "parent": "Guests",
                "destination": "1EnergyProfile",
                "uniqueflowcount": 5
              },
              {
                "parent": "Guests",
                "destination": "PACS",
                "uniqueflowcount": 4
              }
            ]
          }
        ]
      },
      "pageNo": 1,
      "pageSize": 25,
      "startTime": 1590993000000,
      "endTime": 1591079339000,
      "eventTime": 1591696442000
    },
    "ScalableGroup_Filter_SW_HostGroup":{
      "groupViewList": {
        "totalCount": 9,
        "groupList": [
          {
            "sourcegroup": "Developers",
            "children": [
              {
                "parent": "Developers",
                "destination": "Link-Local",
                "uniqueflowcount": 12
              },
              {
                "parent": "Developers",
                "destination": "Fabric Site 1",
                "uniqueflowcount": 5
              },
              {
                "parent": "Developers",
                "destination": "Unknown",
                "uniqueflowcount": 5
              },
              {
                "parent": "Developers",
                "destination": "Other Fabric Sites",
                "uniqueflowcount": 5
              },
              {
                "parent": "Developers",
                "destination": "Catch All",
                "uniqueflowcount": 4
              }
            ]
          },
          {
            "sourcegroup": "HVAC",
            "children": [
              {
                "parent": "HVAC",
                "destination": "Link-Local",
                "uniqueflowcount": 13
              },
              {
                "parent": "HVAC",
                "destination": "Fabric Site 1",
                "uniqueflowcount": 6
              },
              {
                "parent": "HVAC",
                "destination": "Unknown",
                "uniqueflowcount": 6
              },
              {
                "parent": "HVAC",
                "destination": "Other Fabric Sites",
                "uniqueflowcount": 5
              },
              {
                "parent": "HVAC",
                "destination": "Catch All",
                "uniqueflowcount": 5
              }
            ]
          },
          {
            "sourcegroup": "Contractors",
            "children": [
              {
                "parent": "Contractors",
                "destination": "Fabric Site 1",
                "uniqueflowcount": 13
              }
            ]
          },
          {
            "sourcegroup": "Unknown",
            "children": [
              {
                "parent": "Unknown",
                "destination": "Link-Local",
                "uniqueflowcount": 24
              },
              {
                "parent": "Unknown",
                "destination": "Fabric Site 1",
                "uniqueflowcount": 5
              },
              {
                "parent": "Unknown",
                "destination": "Unknown",
                "uniqueflowcount": 3
              },
              {
                "parent": "Unknown",
                "destination": "Other Fabric Sites",
                "uniqueflowcount": 3
              },
              {
                "parent": "Unknown",
                "destination": "Catch All",
                "uniqueflowcount": 2
              }
            ]
          },
          {
            "sourcegroup": "Lighting",
            "children": [
              {
                "parent": "Lighting",
                "destination": "Catch All",
                "uniqueflowcount": 13
              },
              {
                "parent": "Lighting",
                "destination": "Other Fabric Sitess",
                "uniqueflowcount": 5
              },
              {
                "parent": "Lighting",
                "destination": "Unknown",
                "uniqueflowcount": 5
              },
              {
                "parent": "Lighting",
                "destination": "Fabric Site 1",
                "uniqueflowcount": 5
              },
              {
                "parent": "Lighting",
                "destination": "Link-Local",
                "uniqueflowcount": 4
              }
            ]
          },
          {
            "sourcegroup": "Printer_EPG",
            "children": [
              {
                "parent": "Printer_EPG",
                "destination": "Link-Local",
                "uniqueflowcount": 15
              },
              {
                "parent": "Printer_EPG",
                "destination": "Fabric Site 1",
                "uniqueflowcount": 3
              },
              {
                "parent": "Printer_EPG",
                "destination": "Unknown",
                "uniqueflowcount": 3
              },
              {
                "parent": "Printer_EPG",
                "destination": "Other Fabric Sites",
                "uniqueflowcount": 1
              }
            ]
          },
          {
            "sourcegroup": "Employees",
            "children": [
              {
                "parent": "Employees",
                "destination": "Link-Local",
                "uniqueflowcount": 12
              },
              {
                "parent": "Employees",
                "destination": "Fabric Site 1",
                "uniqueflowcount": 2
              },
              {
                "parent": "Employees",
                "destination": "Unknown",
                "uniqueflowcount": 2
              },
              {
                "parent": "Employees",
                "destination": "Other Fabric Sites",
                "uniqueflowcount": 2
              },
              {
                "parent": "Employees",
                "destination": "Catch All",
                "uniqueflowcount": 1
              }
            ]
          },
          {
            "sourcegroup": "Doctors",
            "children": [
              {
                "parent": "Doctors",
                "destination": "Link-Local",
                "uniqueflowcount": 14
              },
              {
                "parent": "Doctors",
                "destination": "Fabric Site 1",
                "uniqueflowcount": 2
              },
              {
                "parent": "Doctors",
                "destination": "Unknown",
                "uniqueflowcount": 2
              },
              {
                "parent": "Doctors",
                "destination": "Other Fabric Sites",
                "uniqueflowcount": 1
              },
              {
                "parent": "Doctors",
                "destination": "Catch All",
                "uniqueflowcount": 1
              }
            ]
          },
          {
            "sourcegroup": "Guests",
            "children": [
              {
                "parent": "Guests",
                "destination": "Link-Local",
                "uniqueflowcount": 22
              },
              {
                "parent": "Guests",
                "destination": "Fabric Site 1",
                "uniqueflowcount": 5
              },
              {
                "parent": "Guests",
                "destination": "Unknown",
                "uniqueflowcount": 5
              },
              {
                "parent": "Guests",
                "destination": "Other Fabric Sites",
                "uniqueflowcount": 5
              },
              {
                "parent": "Guests",
                "destination": "Catch All",
                "uniqueflowcount": 5
              }
            ]
          }
        ]
      },
      "pageNo": 1,
      "pageSize": 25,
      "startTime": 1590993000000,
      "endTime": 1591079339000,
      "eventTime": 1591696442000
    },
    "Overview_MOST_LEAST_Active_Policies":{
      "policyAssuranceTopCountList": {
        "mostActiveGroupList": [
          {
            "sourceGroup": "(Unassigned)",
            "destinationGroup": "(Unassigned)",
            "sourceSecurityTag": "65535",
            "destinationSecurityTag": "65535",
            "sourceGroupName": "(Unassigned)",
            "destinationGroupName": "(Unassigned)",
            "totalCount": 527875
          }
        ],
        "leastActiveGroupList": [
          {
            "sourceGroup": "(Unassigned)",
            "destinationGroup": "(Unassigned)",
            "sourceSecurityTag": "65535",
            "destinationSecurityTag": "65535",
            "sourceGroupName": "(Unassigned)",
            "destinationGroupName": "(Unassigned)",
            "totalCount": 527875
          }
        ],
        "totalActiveCount": 1,
        "totalLeastCount": 1
      },
      "pageNo": 1,
      "pageSize": 10,
      "startTime": 1629693000000,
      "endTime": 1629779392000,
      "eventTime": 1629781952000
    }
    
})