define([
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/database/SimLokiDatabaseActions',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/data-generation/configuration/SimTaskDataGenerator',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/data-generation/SimDataGenerationInit',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/scalablegroup/ScalableGroupIseTemplate',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/utilities/UtilityFunctions',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/data-generation/configuration/DefaultConfig',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/policy/AccessPolicyTemplate',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/policy/TCP_UDP_Template_1',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/policy/TCP_UDP_Template_2',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/provision/DeviceFDCfsConfigStatusTemplate',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/scalablegroup/NetworkApplicationTemplate',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/scalablegroup/AppListTemplate',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/policy/ApplicationPolicyPreviewTemplate',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/utilities/SimulationUtility',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/assurance/sensor/ApModeChangeTemplate',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/data-generation/services/SimConnectivityDomainData',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/policy/ApplicationPolicyTemplate',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/data-generation/services/assurance/SimScheduledCaptureData',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/assurance/icap/ScheduledGlobalSettings',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/provision/DeviceInterfaceInfoContainer',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/scalablegroup/ApplicationSetTemplate',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/scalablegroup/ApplicationSgAutoFillPortTemplate',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/scalablegroup/ApplicationTemplate',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/scalablegroup/NetworkApplicationTemplate2',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/scalablegroup/ApplicationSetDefaultPolicyTemplate',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/scalablegroup/ScalableGroupSummaryTemplate',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/policy/CopyPoliciesTemplate',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/policy/SiteProfileTemplate',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/policy/SummaryPolicyAccessDeployTemplate',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/policy/ACAControllerServiceTemplate',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/data-generation/services/SimPolicyData',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/inventory/ScheduledJobTemplate',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/workflow/UDNWorkflowTemplate',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/provision/ExtranetPolicy_Template',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/data-generation/services/SimVirtualNetworkData',
], function (SimLokiDatabaseActions, SimTaskDataGenerator, SimDataGenerationInit, ScalableGroupIseTemplate,
             UtilityFunctions, DefaultConfig, AccessPolicyTemplate, TCP_UDP_Template_1, TCP_UDP_Template_2,
             DeviceConfigTemplate,NetworkApplicationTemplate,AppListTemplate,ApplicationPolicyPreviewTemplate,SimulationUtility,
             ApModeChangeTemplate,SimConnectivityDomainData,ApplicationPolicyTemplate,SimScheduledCaptureData,
             ScheduledGlobalSettings, DeviceInterfaceInfoContainer, ApplicationSetTemplate, ApplicationSgAutoFillPortTemplate, ApplicationTemplate,
             NetworkApplicationTemplate2, ApplicationSetDefaultPolicyTemplate, ScalableGroupSummaryTemplate, CopyPoliciesTemplate,
             SiteProfileTemplate, SummaryPolicyAccessDeployTemplate, ACAControllerServiceTemplate, SimPolicyData, ScheduledJobTemplate,UDNWorkflowTemplate,
             ExtranetPolicy_Template, SimVirtualNetworkData) {

    var apWirelessServiceType = 'ApWirelessConfiguration';
    var is126OrAbove = true;//change this field to false, in case it is being deployed for DNAC 1.2.5 or below

    function getVNAssociatedWithFD(fdName){
         //It is the virtualnetwork name is passed as mentioned below, hence name is extralcted like that...
         // api/v2/data/customer-facing-service/VirtualNetwork?name=.*test.*
         console.log(fdName);

         var name = fdName.substring(2, fdName.length - 2);
         //workaround fix -ui issue
        // Exp- "Global/North America/USA/California/San Jose/SJC01_Default_LAN_Fabric"
        // Act- "Global/North_America/USA/California/San_Jose/SJC01_Default_LAN_Fabric"
       /*  var array =name.split("/");

            for(var i=0; i<array.length-1;i++){
               var part =array[i];
               part =part.replace(/_/g, " ");
               array[i]=part;
            }
            name =array.join('/'); */
         // end of workaround

         var type = 'ConnectivityDomain';

         var doc = document.getElementsByClassName("leftHierarchy--tree__list--treeNodeLabelContainer level-1 selected");
         if(doc.length>0) {
             domainName = doc[0].textContent;
             domainName= domainName.split(' ').join('_');
             var cd = SimLokiDatabaseActions.getFilteredRecordHandler('ConnectivityDomain', {
                                                            '$and': [
                                                                    {
                                                                        'domainType': 'FABRIC_SITE'
                                                                    }, {
                                                                         'name':  name
                                                                    }]
                                                             });
             if(cd.length==0) {
                 domainName =domainName+"_"+name;
                 name =domainName;
             }
         } else {

           var docObj = document.getElementsByClassName("leftHierarchy--tree__list--treeNodeLabelContainer selected");
           if(docObj.length>0) {
             var siteId= docObj[0].attributes["data-id"].textContent;

             var cd = SimLokiDatabaseActions.getFilteredRecordHandler('ConnectivityDomain', {
                                                            '$and': [
                                                                    {
                                                                        'domainType': 'FABRIC_SITE'
                                                                    }, {
                                                                         'name':  name
                                                                    }]
                                                             });

             if(cd.length==0) {
                var siteFabric = SimLokiDatabaseActions.getFilteredRecordHandler('ConnectivityDomain', {
                                                            '$and': [
                                                                    {
                                                                        'domainType': 'FABRIC_SITE'
                                                                    }, {
                                                                         'siteId':  siteId
                                                                    }]
                                                             });
                if(siteFabric.length>0)  {

                 name =siteFabric[0].name;

                }
             }
           }
         }

         var fdObj = SimLokiDatabaseActions.getFilteredRecordHandler('ConnectivityDomain', {'name' : name});

         if(fdObj && fdObj.length){
              type = 'VirtualNetwork'; 
              var fdId = fdObj[0].id;
              var vnObj = SimLokiDatabaseActions.getFilteredRecordHandler('VirtualNetwork', {'namespace' : fdId});
              if(vnObj && vnObj.length){
                  return vnObj;
              }
         }
         return [];
    }

     function getVNAssociatedWithFDNew(domainId){


         var fdObj = SimLokiDatabaseActions.getFilteredRecordHandler('ConnectivityDomain', {'id' : domainId});

         if(fdObj && fdObj.length){
              type = 'VirtualNetwork';
              var fdId = fdObj[0].id;
              var vnObj = SimLokiDatabaseActions.getFilteredRecordHandler('VirtualNetwork', {'namespace' : fdId});
              if(vnObj && vnObj.length){
                  return vnObj;
              }
         }
         return [];
    }

    function getDeviceDetail(data, name, filterField) {
        var tData = [];
        for (var i = 0; i < data.length; i++) {
            if (filterField == 'name' && name == data[i].name) {
                return [data[i]];
            }
        }
        return tData;
    }

    function getCount(type, key, value) {
        var count = 0;
        var recordObj = SimLokiDatabaseActions.getFilteredRecordHandler(type, {key: value});
        if (recordObj && recordObj.length) {
            count = recordObj.length;
        }
        return count;
    }

    function getQualifierMatch(filter) {
        var qualifier = ['access', 'copy', 'scalableGroupType', 'name', 'networkDeviceId','cdVnList','status'];
        if (filter != undefined && filter.length) {
            for (var i = 0; i < qualifier.length; i++) {
                if (filter[qualifier[i]] != undefined) {
                    return {key: qualifier[i], value: filter[qualifier[i]]};
                }
            }
        }
        return {key: '', value: ''};
    }

    function getLokiDbName(subtype,type){
        var dbname = "";
        dbname = subtype+"-"+type;
        return dbname;
    }

    function getDeviceSpecificConfigStatus(cdId) {
        var deviceObjs = SimLokiDatabaseActions.getAll('DeviceInfo');
        if(deviceObjs == undefined || deviceObjs.length == 0){
            return [];
        }
        var f = 'connectivityDomain';
        var tIds = deviceObjs.filter(function(v) { var t = v[f]; return t != undefined && t.indexOf(cdId) >= 0; });
        var result = [];
        for(var i = 0; i < tIds.length; i++) {
            var id = tIds[i].networkDeviceId;
            var obj = SimLokiDatabaseActions.getFilteredRecordHandler('network-device', {'id' : id});
            if(obj && obj.length) {
                var configObj = SimLokiDatabaseActions.getFilteredRecordHandler('DeviceConfigStatus',{'deviceId':id});
                var deviceConfigId = configObj && configObj.length ? configObj[0].id: '';
                var configObj = JSON.parse(JSON.stringify(DeviceConfigTemplate.Template ));
                configObj.id = deviceConfigId;
                configObj.deviceId = obj[0].id;
                configObj.namespace = cdId;
                configObj.hostname = obj[0].hostname;
                configObj.type = obj[0].type;
                configObj.family = obj[0].family;
                configObj.managementIpAddress = obj[0].managementIpAddress;
                configObj.role = obj[0].role;
                configObj.groupId = obj[0].siteId;
                if(DefaultConfig.BAD_DEVICE.indexOf(obj[0].hostname) >= 0) {
                    configObj.status = 'FAILED';
                }
                result.push(configObj);
            }
        }
        return result;
    }

    function formQuery(filter) {
        var query;
        var qualifier = ['name', 'identitySource.type', 'scalableGroupType','state'];
        var list= [];
         for(var i=0;i<filter.length;i++) {
           var key = filter[i];
            if (qualifier.indexOf(key)>=0) {
                 list.push({[key]:filter[key]});
            }
         }
         query={ '$and':list};
      return query;
    }

    function getNetworkApplication(jsonObj) {
       var response =[];
        for(var i=0;i<jsonObj.length;i++) {
             var tjson= JSON.parse(JSON.stringify(NetworkApplicationTemplate.ScalableGroup_Network_Application_Template));
             tjson.id =jsonObj[i].id;
             tjson.instanceId =jsonObj[i].instanceId;
             tjson.displayName =jsonObj[i].displayName;
             tjson.identitySourceType =jsonObj[i].identitySource.type;
             tjson.name =jsonObj[i].name;
             tjson.applicationType =jsonObj[i].networkApplications[0].applicationType;
             tjson.parentScalableGroup =jsonObj[i].parentScalableGroup.idRef;

              recordObj = SimLokiDatabaseActions.getFilteredRecordHandler('scalablegroup', {'id' : jsonObj[i].parentScalableGroup.idRef});

             tjson.parentScalableGroupName =recordObj[0].name;
             tjson.rank =jsonObj[i].networkApplications[0].rank;
             tjson.trafficClass =jsonObj[i].networkApplications[0].trafficClass;
             response.push(tjson);
        }
       return  response;
    }

     function getDeviceList(siteId) {

     var childrenSites=[];
     var siteList =SimulationUtility.getChildrenSites(siteId,childrenSites);
     var siteIdList =[];
     var deviceIdList=[];
         siteIdList.push(siteId);
         for(var i=0;i<siteList.length;i++) {

             siteIdList.push(siteList[i].id);
         }
         var deviceList =   SimLokiDatabaseActions.getFilteredRecordHandler('network-device',{'siteId': { '$containsAny' :  siteIdList}});

     return  deviceList;
    }


    return {
        init: function () {
        },

        getCfsCount: function (url, filter ) {
            var t = url.split('/');
            var type = t[6];
            type = type.split('?')[0];
            var count = 0;

            var t6 = t.length == 6 ? t[6] : '';
            var t7 = t.length == 7 ? t[7] : '';

            var result = getQualifierMatch(filter);
            if(result.key != ''){

                if(result.key == 'scalableGroupType') {
                    if(result.value == "USER_DEVICE"){
                        var recordObj = SimLokiDatabaseActions.getFilteredRecordHandler('scalablegroup',{'scalableGroupType':'USER-DEVICE'})
                        if(recordObj && recordObj.length){
                            count = recordObj.length;
                        }
                    }if(filter['scalableGroupType'] == 'IP_RENDERED'){
                        var recordObj= SimLokiDatabaseActions.getAll('ip-network-group');
                        if(recordObj && recordObj.length){
                            count = recordObj.length;
                        }
                    }
                    else{
                        var recordObj = SimLokiDatabaseActions.getFilteredRecordHandler('scalablegroup',formQuery(filter));
                        if(recordObj && recordObj.length){
                            count = recordObj.length;
                        }
                    }
                    if(result.value=="APPLICATION") {
                        return 1400

                    var json= JSON.parse(JSON.stringify(AppListTemplate.Application_Template));

                      recordObj= recordObj.concat(json);
                      count = count+recordObj.length;
                    }

                    if(result.value == "APPLICATION_GROUP") {
                        let records = SimLokiDatabaseActions.getAll('applicationSet');
                        return records!=undefined?records.length:28;
                        // return 28;
                    }

                    return (count == undefined ? 0 : count);
                    
                }
            }

            if(t[6] =='networkapplicationcategory'){
                var recordObj = SimLokiDatabaseActions.getAll('networkapplicationcategory');
                if(recordObj && recordObj.length){
                        count = recordObj.length;
                    }
                    return (count == undefined ? 0 : count);
                    
            }

            if(type == 'copydestination'){
                var recordObj = SimLokiDatabaseActions.getAll('copy-destination');
                if(recordObj && recordObj.length){
                        count = recordObj.length;
                    }
                    return (count == undefined ? 0 : count);
                    

            }
            if(type == 'policy' || type == 'contract'){
                var subtype = t[7];
                var count = 0;
                if(subtype =='ipaccess' || subtype == 'copy'){
                    if(subtype == 'ipaccess'){
                        var recordObj = SimLokiDatabaseActions.getAll('ip-access-contract');   
                    }else{
                        var recordObj = SimLokiDatabaseActions.getAll(getLokiDbName(subtype,type));
                    }                  
                    if(recordObj && recordObj.length){
                        count = recordObj.length;
                    }
                    return (count == undefined ? 0 : count);
                    
                }else if(t[7] == 'access'){
                    count = SimLokiDatabaseActions.getCount('access-policy');
                    return (count == undefined ? 0 : count);
                }
                else {
                    count = SimLokiDatabaseActions.getCount(type);
                    return (count == undefined ? 0 : count);
                    
                }
                if(t.length == 8 && t[7] != undefined){
                    //qualifier e.g. 1)access 2)copy etc.. for policy
                    //qualifier e.g. 1)access for contract
                    count = getCount(type, 'qualifier', t[7].split('?')[0]);
                    return (count);
                    
                }
            }

            if(type == 'scalablegroup' ) {
                if(filter['identitySource.type'] == 'ISE'){
                    var recordObj = SimLokiDatabaseActions.getFilteredRecordHandler('scalablegroup', {'identitySource.type' : 'ISE'});
                    if(recordObj && recordObj.length){
                        count = recordObj.length;
                    }
                    return (count == undefined ? 0 : count);
                    
                }
                if(t[7] != undefined){
                    if(t[7].indexOf("?indicativeNetworkIdentity.protocol=TCP,UDP") != -1){
                    var t_Json1 = JSON.parse(JSON.stringify(TCP_UDP_Template_1.TCP_UDP_TEMPLATE_1));
                    var t_Json2 = JSON.parse(JSON.stringify(TCP_UDP_Template_2.TCP_UDP_TEMPLATE_2));
                    var t_Json3 = SimLokiDatabaseActions.getFilteredRecordHandler(type, {'networkApplications': {'$size': 1}});

                    if(t_Json1 && t_Json1.length){
                        count = t_Json1.length;
                    }
                    if(t_Json2 && t_Json2.length){
                        count = count + t_Json2.length;
                    }
                    if(t_Json3 && t_Json3.length){
                        count = count + t_Json3.length;
                    }
                    return (count == undefined ? 0 : count);                    
                    }
                }
            }
            if(type=="Segment" && filter) {
                var segData = SimLokiDatabaseActions.getAll("Segment");
                // if(filter.namespace) segData = segData.filter(e => e.connectivityDomain.idRef==filter.namespace);
                if(filter.isCriticalPool != undefined) segData = segData.filter(e => e.isCriticalPool==filter.isCriticalPool)
                return segData.length;
            }
            if(type=="ConnectivityDomain" && filter.length>0) {
                var condn = [];
                filter.forEach(e => {
                    var tempObj={}; tempObj[e]=filter[e];
                    condn.push(tempObj);
                });
                var connDomRecords = SimLokiDatabaseActions.getFilteredRecordHandler(type, {'$and':condn});
                return connDomRecords.length;
            }

            count = SimLokiDatabaseActions.getCount(type);
            return (count == undefined ? 0 : count);
        },

        getCfsDetails: function (url, filter ) {
            var t = url.split('/');
            var type = t[5], count = 0;
            type = type.split('?')[0];

            var t6 = t.length == 6 ? t[5] : '';
            var t7 = t.length == 7 ? t[6] : '';

            //url sometimes comes up with timestamp e.g. /ap1/v1/../id?_123, we are trying to remove timestamp
            var str = t[ t.length - 1];
            var indx = str.indexOf('?');
            var len = indx > 0 ? indx : str.length;
            var tId = str.substring(0, len);
            t[ t.length - 1] = tId;

            if(type =='APModeChange') {
                var recordObj = JSON.parse(JSON.stringify(ApModeChangeTemplate.ApModeChangeTemplate));
                recordObj[0].id = UtilityFunctions.generate_uuid();
                return (recordObj);
            }

            if(type =='count'){
                var recordObj = SimLokiDatabaseActions.getFilteredRecordHandler('scalablegroup', {'scalableGroupType' : filter['scalableGroupType']});
                if(recordObj && recordObj.length){
                    count = recordObj.length;
                }
                return (count == undefined ? 0 : count);
                
            }

            if(type == "DeviceInterfaceInfoContainer") {
                //FIXME -- Proper handling has to be done 1.3.0
                var recordObj = JSON.parse(JSON.stringify(DeviceInterfaceInfoContainer.Template));
                var id = t[ t.length - 1];
                return getDeviceInterfacePortChannel( id ); 
            }

            if(type =='scratchpad') {
                var recordObj = SimLokiDatabaseActions.getFilteredRecordHandler('application-policy-preview', {'namespace' : filter['namespace']});
                return (recordObj && recordObj.length ? recordObj : []);
            }

            if (type == 'summary' && filter["cdFSiteList"] == undefined && filter["fLanDeviceList"] == undefined && filter["siteDeviceList"] == undefined && filter["vncList"] == undefined) {
                console.log(t[5] + " " + t[6]);


                if (t[6] && t[6].split('?')[0] == "application" && filter["scratchPadId"] != undefined) {
                    var recordObj = JSON.parse(JSON.stringify(ApplicationPolicyPreviewTemplate.Application_Policy_Preview_Device_Summary_Template));
                    var deviceList = getDeviceList(filter["sites"]);
                    var summary = []
                    recordObj[0].count = deviceList.length;
                    for (var i = 0; i < deviceList.length; i++) {
                        var tJson = recordObj[0].applicationPolicyScopeResolutionDeviceSummary[0];
                        tJson.deviceId = deviceList[0].id;
                        tJson.deviceName = deviceList[0].name;
                        tJson.deviceType = deviceList[0].type;
                        tJson.groupNameHierarchy = "";
                        tJson.managementIpAddress = deviceList[0].managementIpAddress;
                        summary.push(tJson);
                    }
                    recordObj[0].applicationPolicyScopeResolutionDeviceSummary = summary;
                    return (recordObj && recordObj.length ? recordObj : []);
                }

                if (t[7] && t[7].split('?')[0] == "application" && filter["resolveScope"] == "true") {
                    var recordObj = JSON.parse(JSON.stringify(ApplicationPolicyPreviewTemplate.Application_Policy_Preview_Device_Summary_Template));
                    if (filter["sites"] != undefined) {
                        var deviceList = getDeviceList(filter["sites"]);
                    } else {
                        var deviceList = getDeviceList(filter["scratchPadId"]);
                    }
                    var summary = []
                    recordObj[0].count = deviceList.length;
                    for (var i = 0; i < deviceList.length; i++) {
                        var tJson = recordObj[0].applicationPolicyScopeResolutionDeviceSummary[0];
                        tJson.deviceId = deviceList[0].id;
                        tJson.deviceName = deviceList[0].name;
                        tJson.deviceType = deviceList[0].type;
                        tJson.groupNameHierarchy = "";
                        tJson.managementIpAddress = deviceList[0].managementIpAddress;
                        summary.push(tJson);
                    }
                    recordObj[0].applicationPolicyScopeResolutionDeviceSummary = summary;
                    return (recordObj && recordObj.length ? recordObj : []);
                }

                if (t[7] && t[7].split('?')[0] == "application" && filter["miniDashboardFilter"] != undefined) {

                    var policyList = SimLokiDatabaseActions.getAll('policy-summary');

                    var recordObj = JSON.parse(JSON.stringify(ApplicationPolicyTemplate.Application_Dashbaord_Template));
                    if (policyList != undefined && policyList.length > 0) {
                        recordObj[0].successfullPolicies = policyList[0].applicationPolicySummary.length;
                        recordObj[0].totalPolicies = policyList[0].applicationPolicySummary.length;

                    }
                    return (recordObj && recordObj.length ? recordObj : []);

                }

                if (t[7] && t[7].split('?')[0] == "application" && filter["applicationPolicyReadiness"] == "true") {
                    var policyReadiness = JSON.parse(JSON.stringify(CopyPoliciesTemplate.ApplicationPolicy_Readiness));
                    var recordObj = policyReadiness[0];
                    console.log('recordObj : ', recordObj);
                    //var recordObj = SimLokiDatabaseActions.getAll('policy-summary');
                    return recordObj;
                }

                if (t[7] && t[7].split('?')[0] == "application") {
                    var recordObj = SimLokiDatabaseActions.getAll('policy-summary');
                    return (recordObj && recordObj.length ? recordObj : []);
                }

                if (t[5] == 'policy' && t[6] && t[6] == 'copy') {
                    var recordObj = SimLokiDatabaseActions.getAll('copy-policy-summary');
                    return (recordObj && recordObj.length ? recordObj : []);

                }
                if (t[5] == 'policy') {
                    var recordObj = SimLokiDatabaseActions.getAll('policy-summary');
                    return (recordObj && recordObj.length ? recordObj : []);
                }

                if (filter['applicationSg'] == 'true') {
                    var recordObj = ApplicationSgAutoFillPortTemplate.accessPolicySgSummary;
                    let resultObj = {};
                    var tempObj = {};
                    var tempList = [];
                    tempObj.accessPolicySgSummary = recordObj;
                    tempObj.acaApplicationSgSummary = recordObj; // added for soniya fix in 1.4.0
                    tempObj.totalSgCount = recordObj.length;
                    tempObj.instanceId = 0;
                    tempObj.displayName = "0";
                    tempObj.instanceVersion = 0;
                    tempList.push(tempObj)
                    resultObj["response"] = tempList
                    resultObj["id"] = UtilityFunctions.generate_uuid();
                    resultObj["version"] = "1.0";
                    return resultObj;
                }
                // Scalable group related code for 1.3.1 by Karan
                if((t[7] == 'access' || (t[7] && t[7].split('?')[0] == "access")) && filter["scalableGroupSummary"] == "true"){
                    let resultObj = {};
                    var tempObj = {};
                    var tempList = [];
                    var recordObj = SimLokiDatabaseActions.getAll('scalablegroup');
                    //code for Filters for Policy-->GBAC-->ScalableGroup Filters Handling multiple filters 1.3.1
                    var filterName = filter["name"], filterDesc = filter["description"], filterVn = filter["virtualNetworkNames"],
                    filterCnt = filter["policyRefCount"], filterPushed = filter["pushed"], filterTag = filter["securityGroupTag"]
                    filterVnw = filter["vnName"];
                    if(filterName != undefined){
                        if(filterName == 'Lighting'){
                            recordObj = recordObj.filter(obj => obj.name == 'Lights');
                        }else{
                            recordObj = recordObj.filter(obj => obj.name == filterName);
                        }
                    }
                    if(filterDesc != undefined){
                        recordObj = recordObj.filter(obj => obj.description.indexOf(filterDesc) > -1);
                    }
                    if(filterVn != undefined){
                        recordObj = recordObj.filter(obj => obj.virtualNetworkNames == filterVn);
                    }
                    if(filterVnw != undefined){
                        recordObj = SimLokiDatabaseActions.getFilteredRecordHandler('scalablegroup',{'acaScalableGroupVNInfo.name':filterVnw})
                    }
                    if(filterCnt != undefined){
                        filterCnt = Number(filterCnt);
                        recordObj = recordObj.filter(obj => obj.policyRefCount == filterCnt);
                    }
                    if(filterPushed != undefined){
                        filterPushed = JSON.parse(filter["pushed"]);
                        recordObj = recordObj.filter(obj => obj.pushed == filterPushed);
                    }
                    if(filterTag != undefined){
                        recordObj = recordObj.filter(obj => obj.securityGroupTag == filterTag);
                    }
                    for (var i = 0; i < recordObj.length; i++) {
                        var access_record = SimLokiDatabaseActions.getFilteredRecordHandler('access-policy', {'producerId' : recordObj[i].id});
                        recordObj[i].policyRefCount = access_record.length; 
                    }
                    tempObj.acaScalableGroupSummary = recordObj;
                    tempObj.instanceId = 0;
                    tempObj.displayName = 0;
                    tempObj.instanceVersion = 0;
                    tempObj.totalSGCount = recordObj.length;
                    tempList.push(tempObj)
                    resultObj["response"] = tempList
                    resultObj["id"] = UtilityFunctions.generate_uuid();
                    resultObj["version"] = "1.0";
                    return resultObj;
                }
                // Below Call handeled in Gaurdian Relese
                if (t[7] == 'access' && filter["scalableGroupNames"] == "true"){
                    var resultObj = {};
                    var tempObj = {};
                    var tempList = [];
                    var recordObj = SimLokiDatabaseActions.getAll('scalablegroup');
                    var tList = []
                    for(var k=0;k<recordObj.length;k++){
                        var tObj = {};
                        tObj['displayName'] = recordObj[k].displayName;
                        tObj['id'] = recordObj[k].id;
                        tObj['instanceId'] = recordObj[k].instanceId;
                        tObj['instanceVersion'] = recordObj[k].instanceVersion;
                        tObj['name'] = recordObj[k].name;
                        tObj['securityGroupTag'] = recordObj[k].securityGroupTag;
                        tObj['tenantintsegment'] = recordObj[k].tenantintsegment;
                        tObj['tenantlongsegment'] = recordObj[k].tenantlongsegment;
                        tList.push(tObj)
                    }
                    tempObj.acaScalableGroupNames = tList;
                    tempObj.instanceId = 0;
                    tempObj.displayName = "0";
                    tempObj.instanceVersion = 0;
                    tempList.push(tempObj)
                    resultObj["response"] = tempList
                    resultObj["id"] = UtilityFunctions.generate_uuid();
                    resultObj["version"] = "1.0";
                    return resultObj;
                }
                if (t[7] == 'access' && filter["gbpSummary"] == "true" && filter["producerId"] != undefined || filter["consumerId"] != undefined ) {
                    let resultObj = {};
                    var tempObj = {};
                    var tempList = [];
                    if(filter["producerId"] != undefined){
                        var recordObj = SimLokiDatabaseActions.getFilteredRecordHandler('access-policy',{'producerId':filter["producerId"]})
                    }
                    else if(filter["consumerId"] != undefined){
                        var recordObj = SimLokiDatabaseActions.getFilteredRecordHandler('access-policy',{'consumerId' : filter['consumerId']})
                    }else{
                        var recordObj = SimLokiDatabaseActions.getFilteredRecordHandler('access-policy', {'$and':[{'producerId' : filter['producerId']}, {'consumerId' : filter['consumerId']}]});
                    }
                    tempObj.acaGBPSummary = recordObj;
                    tempObj.instanceId = 0;
                    tempObj.displayName = "0";
                    tempObj.instanceVersion = 0;
                    tempObj.totalPolicyCount = recordObj.length;
                    tempList.push(tempObj)
                    resultObj["response"] = tempList
                    resultObj["id"] = UtilityFunctions.generate_uuid();
                    resultObj["version"] = "1.0";
                    return resultObj;
                }
                if (t[7] == 'access' && filter["gbpSummary"] == "true" && filter["contractName"] != undefined) {
                    let resultObj = {};
                    var tempObj = {};
                    var tempList = [];
                    var contract_Name = filter["contractName"]
                    if (contract_Name.includes("+")){
                        contract_Name =contract_Name.replace('+',' ')
                    }
                    var recordObj = SimLokiDatabaseActions.getFilteredRecordHandler('access-policy', {'contractName': contract_Name});
                    tempObj.acaGBPSummary = recordObj;
                    tempObj.instanceId = 0;
                    tempObj.displayName = "0";
                    tempObj.instanceVersion = 0;
                    tempObj.totalPolicyCount = recordObj.length;
                    tempList.push(tempObj)
                    resultObj["response"] = tempList
                    resultObj["id"] = UtilityFunctions.generate_uuid();
                    resultObj["version"] = "1.0";
                    return resultObj;
                }
                if (t[7] == 'access' && filter["gbpSummary"] == "true") {
                    let resultObj = {};
                    var tempObj = {};
                    var tempList = [];
                    if( filter[1] == "producerOrConsumerId"){
                        var recordObj = SimLokiDatabaseActions.getFilteredRecordHandler('access-policy', {'producerId' : filter["producerOrConsumerId"]});
                    }else{
                        var recordObj = SimLokiDatabaseActions.getAll('access-policy');
                    }
                    tempObj.acaGBPSummary = recordObj;
                    tempObj.instanceId = 0;
                    tempObj.displayName = "0";
                    tempObj.instanceVersion = 0;
                    tempObj.totalPolicyCount = recordObj.length;
                    tempList.push(tempObj)
                    resultObj["response"] = tempList
                    resultObj["id"] = UtilityFunctions.generate_uuid();
                    resultObj["version"] = "1.0";
                    return resultObj;
                }
                if (t[7] == 'access' && filter["defaultPolicy"] == "true") {
                    let resultObj = {};
                    resultObj["response"] = ScalableGroupSummaryTemplate.defaultPolicy_Template;
                    resultObj["id"] = UtilityFunctions.generate_uuid();
                    resultObj["version"] = "1.0";
                    return resultObj;
                }
                if (t[7] == 'access' && filter["getNextSgt"] == "true") {
                    let resultObj = {};
                    var recordObj = SimLokiDatabaseActions.getAll('scalablegroup').length;
                    var tJson = ScalableGroupSummaryTemplate.GetNextSgt_Template;
                    tJson[0].securityGroupTag = recordObj;
                    resultObj["response"] = tJson;
                    resultObj["id"] = UtilityFunctions.generate_uuid();
                    resultObj["version"] = "1.0";
                    return resultObj;
                }
                if (t[7] == 'access' && filter["contractSummary"] == "true") {
                    let resultObj = {};
                    var tempObj = {};
                    var tempList = [];
                    //Filters for Policy-->GBAC-->Access Contract, Handling multiple filters 1.3.1 Karan
                    var recordObj = SimLokiDatabaseActions.getAll('access-contract');
                    var filterName = filter["name"], filterDesc = filter["description"], filterRlc = filter["ruleCount"],
                    filterCnt = filter["policyRefCount"], filterPushed = filter["pushed"]
                    if(filterName != undefined){

                    recordObj = recordObj.filter(obj => obj.name == filterName);
                    }
                    if(filterDesc != undefined){
                        recordObj = recordObj.filter(obj => obj.description.indexOf(filterDesc) > -1);
                    }
                    if(filterRlc != undefined){
                        filterRlc = Number(filterRlc)
                        recordObj = recordObj.filter(obj => obj.ruleCount == filterRlc);
                    }
                    if(filterCnt != undefined){
                        filterCnt = Number(filterCnt);
                        recordObj = recordObj.filter(obj => obj.policyRefCount == filterCnt);
                    }
                    if(filterPushed != undefined){
                        filterPushed = JSON.parse(filter["pushed"]);
                        recordObj = recordObj.filter(obj => obj.pushed == filterPushed);
                    }
                    for (var i = 0; i < recordObj.length; i++) {
                        var access_record = SimLokiDatabaseActions.getFilteredRecordHandler('access-policy', {'contractName' : recordObj[i].name});
                        recordObj[i].policyRefCount = access_record.length; 
                    }

                    tempObj.acaContractSummary = recordObj;
                    tempObj.instanceId = 0;
                    tempObj.displayName = "0";
                    tempObj.instanceVersion = 0;
                    tempObj.totalContractCount = recordObj.length;
                    tempList.push(tempObj)
                    resultObj["response"] = tempList
                    resultObj["id"] = UtilityFunctions.generate_uuid();
                    resultObj["version"] = "1.0";
                    return resultObj;
                }
                if(t[7] == 'access' && filter["gbpSourceListView"] == "true"){
                    let resultObj = {};
                    var recordObj = SimLokiDatabaseActions.getAll('scalablegroup')
                    var SourceList = [];
                    var tempList = [];
                    tempObj = {};
                    for (var i = 0; i < recordObj.length; i++) {
                        if(recordObj[i].acaGBPListSourceViewSummary != undefined){
                            if(recordObj[i].acaGBPListSourceViewSummary.length>0){
                                var access_record = SimLokiDatabaseActions.getFilteredRecordHandler('access-policy', {'producerId' : recordObj[i].id});
                                recordObj[i].acaGBPListSourceViewSummary[0].sgRefCount = access_record.length;
                                recordObj[i].acaGBPListSourceViewSummary[0].contractRefCount = access_record.length; 
                                SourceList.push(recordObj[i].acaGBPListSourceViewSummary[0])
                            }
                        }
                    }
                    tempObj.acaGBPListSourceViewSummary = SourceList;
                    tempObj.instanceId = 0;
                    tempObj.displayName = "0";
                    tempObj.instanceVersion = 0;
                    tempObj.totalPolicyCount = SourceList.length;
                    tempObj.acaGBPListDestViewSummary = [];
                    tempList.push(tempObj)
                    resultObj["response"] = tempList
                    resultObj["id"] = UtilityFunctions.generate_uuid();
                    resultObj["version"] = "1.0";
                    return resultObj;
                }
                if(t[7] == 'access' && filter["gbpDestListView"] == "true"){
                    let resultObj = {};
                    var recordObj = SimLokiDatabaseActions.getAll('scalablegroup')
                    var DestList = [];
                    var tempList = [];
                    tempObj = {};
                    for (var i = 0; i < recordObj.length; i++) {
                        if(recordObj[i].acaGBPListDestViewSummary.length>0){
                            var access_record = SimLokiDatabaseActions.getFilteredRecordHandler('access-policy', {'consumerId' : recordObj[i].id});
                            recordObj[i].acaGBPListDestViewSummary[0].sgRefCount = access_record.length;
                            recordObj[i].acaGBPListDestViewSummary[0].contractRefCount = access_record.length;
                            DestList.push(recordObj[i].acaGBPListDestViewSummary[0])
                        }
                    }
                    tempObj.acaGBPListDestViewSummary = DestList;
                    tempObj.instanceId = 0;
                    tempObj.displayName = "0";
                    tempObj.instanceVersion = 0;
                    tempObj.totalPolicyCount = DestList.length;
                    tempObj.acaGBPListSourceViewSummary = [];
                    tempList.push(tempObj)
                    resultObj["response"] = tempList
                    resultObj["id"] = UtilityFunctions.generate_uuid();
                    resultObj["version"] = "1.0";
                    return resultObj;
                }
            }

            if(type == 'haInfo') {
                //api/v2/data/customer-facing-service/haInfo?networkDeviceId=6ec38edc-772a-45cf-b853-d073639e6121
                return [];
            }

            if(type == 'copydestination'){
                var recordObj = SimLokiDatabaseActions.getAll('copy-destination');
                return (recordObj && recordObj.length ? recordObj : []);
                
            }

            if(filter['attributes']==='applicationPolicy' && filter['name']==='Application-set-default-policy') {
                return ApplicationSetDefaultPolicyTemplate.data
            }

            if(type == 'spslaprofile') {
                return SiteProfileTemplate.Template;
            }

            if(type =='policy' || type == 'contract'){
                var subtype = t[6];
                subtype = subtype.split('?')[0];
                subtype = (subtype=="ipaccess")? 'access' : subtype;
                var recordObj;
                if(subtype=="application" && filter["name"]!=undefined) {
                 recordObj = SimLokiDatabaseActions.getFilteredRecordHandler(getLokiDbName(subtype,type),{"name":filter["name"]});

                } else if(subtype=="application" && filter["policyScope"]!=undefined) {
                 recordObj = SimLokiDatabaseActions.getFilteredRecordHandler(getLokiDbName(subtype,type),{"policyScope":filter["policyScope"]});

                } else if (subtype=="access" && filter[4]!=undefined) {
                   var key = filter[4];
                 recordObj = SimLokiDatabaseActions.filterByRegx(getLokiDbName(subtype,type), key ,filter[key]);
                } else if(subtype=="access" && filter["name"]!=undefined) {
                 recordObj = SimLokiDatabaseActions.getFilteredRecordHandler(getLokiDbName(subtype,type),{"name":filter["name"]});

                } else if (filter['id'] != undefined) {
                 recordObj = SimLokiDatabaseActions.getFilteredRecordHandler(getLokiDbName(subtype,type),{"id":filter["id"]});
                }//By Karan for 1.3.1 to fix the IP Based Access Control policy page load
                else if (type == 'contract' && subtype=="access" && t[6] == "ipaccess") {
                    recordObj = SimLokiDatabaseActions.getAll('ip-access-contract');
                }else if (type =='policy' && subtype=="access" && t[6] == "ipaccess") {
                    recordObj = SimLokiDatabaseActions.getAll('ip-access-control-policy');
                }else if(type =='policy' && subtype=="access" && tId !=undefined && t[7] !=undefined){
                    recordObj = SimLokiDatabaseActions.getFilteredRecordHandler('access-policy',{'id':tId})
                }
                else {
                recordObj = SimLokiDatabaseActions.getAll(getLokiDbName(subtype,type));
                }
             /*   if(recordObj != undefined){
                        for (var i = 0; i < recordObj.length; i++) {
                            delete recordObj[i]['$loki'];//check with Vel/Srini
                        }
                    } */
                //console.log(recordObj);
                return (recordObj && recordObj.length ? recordObj : []);
                
            }

            if(type =='Wlan'){
                return getWlanSsid( filter );
            }

            if(type =='DeviceInfo'){
               var deviceInfoObjs = [];
               if(filter['networkDeviceId'] != undefined) {
                   var deviceIds = filter['networkDeviceId'].split(',')
                   for (var deviceIndex in deviceIds) {
                       var deviceId = deviceIds[deviceIndex];
                       recordObj = SimLokiDatabaseActions.getFilteredRecordHandler('DeviceInfo', { 'networkDeviceId': deviceId });
                       if (recordObj && recordObj.length > 0) {
                           if (t.indexOf('customer-facing-service') != -1) {
                               let missingDetailsObj = UDNWorkflowTemplate.MissingDeviceDetailsforUDNFlow;
                               let combinedObj = {
                                   ...missingDetailsObj,
                                   ...recordObj[0]
                               }
                               deviceInfoObjs.push(combinedObj);
                           } else {
                               deviceInfoObjs.push(recordObj[0]);
                           }
                       }
                   }
               } else if(filter['siteDeviceList'] != undefined && filter['roles'] != undefined) {
                     var recordObj = SimConnectivityDomainData.getDeviceInfoSummary(filter) ;
                     return (recordObj && recordObj.length ? recordObj : []);
                   
               } else if(filter['siteDeviceList'] != undefined) {
                   var recordObj = SimLokiDatabaseActions.getFilteredRecordHandler('DeviceInfo', {'siteId' : filter['siteDeviceList']});
                   return (recordObj && recordObj.length ? recordObj : []);
               }  else if(filter['siteId'] != undefined) {
                   var recordObj = SimLokiDatabaseActions.getFilteredRecordHandler('DeviceInfo', {'siteId' : filter['siteId']});
                     return (recordObj && recordObj.length ? recordObj : []);
                   
               } else if(filter['id'] != undefined) {
                   var recordObj = SimLokiDatabaseActions.getFilteredRecordHandler('DeviceInfo', {'id' : filter['id']});
                    //special handling for SJC04 - SDWAN-DNAC integration use case - to show BORDER configure pre-populated
                    recordObj = JSON.parse(JSON.stringify(recordObj));
                    var sdwanDevices = SimLokiDatabaseActions.getFilteredRecordHandler('network-device',{'hostname':{'$in':['SJC04-ISR4K-01','SJC04-ISR4K-02','SJC04-C9300Stack-1']}});
                    var sdaTransit = SimLokiDatabaseActions.getFilteredRecordHandler('ConnectivityDomain',{'transitType':'SDWAN'});
                    sdwanDevices = sdwanDevices.map(e=>e.id);
                    if (recordObj.length>0 && sdwanDevices.indexOf(recordObj[0].networkDeviceId)>-1) {
                        recordObj[0].roles = ["BORDERNODE","EXTERNAL"];
                        recordObj[0].deviceSettings = {"externalDomainRoutingProtocol":"BGP","externalConnectivityIpPool":"",
                            "extConnectivitySettings":[],"internalDomainProtocolNumber":"65004","nodeType":["EXTERNAL"],
                            "connectedTo":[],"dhcpEnabled":true,"internetVn":[],"internalDomainProtocolNotation":"ASDOT"};
                        recordObj[0].transitNetworks =[{"idRef":sdaTransit[0].id}];
                        return recordObj;
                    }
                    //sdwan special handling ends

                    return (recordObj && recordObj.length ? recordObj : []);
               //Transit PubSub/List changes - start
               } else if(filter['cdId']) {
                   filter['cdId'] = filter['cdId'].split(',');
                   var deviceInfoObjs = SimLokiDatabaseActions.getAll('DeviceInfo');
                   var resultArr = [];
                   deviceInfoObjs.forEach(e => {
                       e.transitNetworks.forEach(e2 => {
                           if(filter['cdId'].indexOf(e2.idRef)>-1) {
                               resultArr.push(e);
                           }
                        });
                        if((e.connectivityDomain.filter(e3 => filter['cdId'].includes(e3)) ).length>0) {
                            resultArr.push(e);
                        }
                   })
                   return resultArr;
               //Transit PubSub/List changes - end
               } else {

                   var deviceInfoObjs = SimLokiDatabaseActions.getAll('DeviceInfo');
                   console.log(deviceInfoObjs);
               }
               return (deviceInfoObjs && deviceInfoObjs.length ? deviceInfoObjs : []);
               
            } else if (type == 'summary' && t[6] && t[6] == 'DeviceInfo' && (filter['siteId'] || filter['roles'])) {
                var data = SimConnectivityDomainData.getDeviceInfoSummary_1_3_3(filter['siteId'], filter['roles']);
                return data;
            }

            if(type == apWirelessServiceType)
           {
             if(filter['siteId'] != undefined)
             {
               var recordObj = SimLokiDatabaseActions.getFilteredRecordHandler(apWirelessServiceType, {'siteId' : filter['siteId']});
             } else if (filter['id'] != undefined)
             {
               var recordObj = SimLokiDatabaseActions.getFilteredRecordHandler(apWirelessServiceType, {'id' : filter['id']});
             }
             console.log('Output==>'+apWirelessServiceType);
             console.log('Get==>'+recordObj);
             return (recordObj && recordObj.length ? recordObj : []);
             
           }

            if(type == "airsense") {
                var subType = t[6];
                if(t[6].split('?')[0] == "anomaly") {
                    var tObj = [];
                    //anomaly call flow is from this file in 1.2.6
                    if(is126OrAbove) {
                        tObj = SimLokiDatabaseActions.getFilteredRecordHandler('scheduled-capture-settings_126', {'$and': [
                            {"name": "anomaly" },{"siteId": filter['siteId']}, {"isGlobal": filter['isGlobal']},
                            {"isEnable": filter['isEnable']}, {"isDisable": filter['isDisable']},
                            {"isClientAnomalyDetectionPacketTrace": filter['isClientAnomalyDetectionPacketTrace']} ]});
                    } else {
                        tObj = SimLokiDatabaseActions.getFilteredRecordHandler('scheduled-capture-settings',   {'$and': [ {"name": "anomaly" },{"siteId": filter['siteId'] },{"isClientAnomalyDetectionPacketTrace": filter['isClientAnomalyDetectionPacketTrace'] }]});
                    }
                     console.log(tObj);
                     return (tObj);
                     

                } else if(t[6].split('?')[0] == "ap-statistic") {
                    //ap-statistic call goes to SimCfsModelsv1 in 1.2.6
                    var tObj = [];
                    if(is126OrAbove) {
                        tObj = SimLokiDatabaseActions.getFilteredRecordHandler('scheduled-capture-settings_126', {'$and': [{"name": "ap-statistic" },{"siteId": filter['siteId']} ]});
                    } else {
                        tObj = SimLokiDatabaseActions.getFilteredRecordHandler('scheduled-capture-settings',   {'$and': [ {"name": "ap-statistic" },{"siteId": filter['siteId'] },{"apStatisticsType": filter['apStatisticsType'] }]});
                    }
                    return (tObj);
                    
                }
            }

            var result = getQualifierMatch(filter);
            if(result.key != ''){
                var Value = result.value;

                if(result.key == 'scalableGroupType') {
                    if( filter.length <= 5 ){
                        if(  filter['scalableGroupType'] == 'USER_DEVICE'){
                            filter['scalableGroupType'] = "USER-DEVICE";
                        }
                        var recordObj= SimLokiDatabaseActions.getFilteredRecordHandler(type, formQuery(filter));
                        if(filter['scalableGroupType'] == 'IP_RENDERED'){
                            var recordObj= SimLokiDatabaseActions.getAll('ip-network-group');
                        }
                    }else{
                        var key = filter[5];
                        var tFilterObj = SimLokiDatabaseActions.getFilteredRecordHandler(type, {'scalableGroupType' : filter["scalableGroupType"]});
                        if( key === "name" ){
                            //var recordObj = tFilterObj.filter(({key}) => name.match(filter[key]));
                            var getFilteredCodes = (array, key, value) => array.filter(x => x[key].match(value));
                            var recordObj = getFilteredCodes(tFilterObj, key, filter[key]);
                            //var recordObj = tFilterObj[1].find({ ['name'] : {'$regex': ".*test.*"} });
                        }else{
                            var recordObj = tFilterObj.filter(({key}) => name.match(filter[key]));
                        }
                    }

                   /* if(recordObj != undefined){
                        for (var i = 0; i < recordObj.length; i++) {
                            delete recordObj[i]['$loki'];//check with Vel/Srini
                        }
                    } */
                    if(Value=="APPLICATION") {

                    if(recordObj != undefined){
                        for (var i = 0; i < recordObj.length; i++) {
                            delete recordObj[i]['$loki'];//check with Vel/Srini
                        }
                    }

                    var json= JSON.parse(JSON.stringify(AppListTemplate.Application_Template));

                      recordObj= recordObj.concat(json);
                    }
                    return (recordObj && recordObj.length ? recordObj : []);
                    
                }
                if(result.key == 'networkDeviceId') {
                    var recordObj = SimLokiDatabaseActions.getFilteredRecordHandler(type, {'networkDeviceId' : Value});
                    return (recordObj && recordObj.length ? recordObj : []);
                    
                }
                else if(result.key == 'name'){
                    if(type == 'VirtualNetwork') {
                        return ( getVNAssociatedWithFD(result.value));
                    }
                    else {
                        var value = result.value;
                        var recordObj = SimLokiDatabaseActions.getFilteredRecordHandler(type, {'name' : value});
                        return (recordObj && recordObj.length ? recordObj : []);
                        
                    }
                }else if(result.key == 'cdVnList'){
                    if(type == 'VirtualNetwork') {
                        return ( getVNAssociatedWithFDNew(result.value));
                        
                    }

                }
                else {
                    return ([]);
                }
                
            }


            if(t.length == 7 && t[6] != undefined){

                if (t[6].indexOf("?indicativeNetworkIdentity.protocol=TCP,UDP") != -1) {

                    var recordObj1 = JSON.parse(JSON.stringify(TCP_UDP_Template_1.TCP_UDP_TEMPLATE_1));
                    var recordObj2 = JSON.parse(JSON.stringify(TCP_UDP_Template_2.TCP_UDP_TEMPLATE_2));
                    var recordObj3 = SimLokiDatabaseActions.getFilteredRecordHandler(type, {'networkApplications': {'$size': 1}});

                    Array.prototype.push.apply(recordObj1, recordObj2);

                    if(recordObj3 && recordObj3.length){
                        Array.prototype.push.apply(recordObj1, recordObj3);
                    }
                    //var recordObj = recordObj1.push(recordObj2);
                    //var recordObj = SimLokiDatabaseActions.getFilteredRecordHandler(type, {'networkApplications': {'$size': 1}});
                    return (recordObj1 && recordObj1.length ? recordObj1 : []);
                    
                }

                if(type =='Segment' ) {
                    var recordObj = SimLokiDatabaseActions.getFilteredRecordHandler(type, {'id' : t[6]});
                    return (recordObj && recordObj.length ? recordObj : []);
                }
                if(type == 'summary' && t[6] == 'Segment' && filter['vncId']){
                    var recordObj = SimLokiDatabaseActions.getFilteredRecordHandler('Segment', { 'virtualNetworkContextId' : filter['vncId'] });
                    var data = {"id":"ce1c7dfe-fcd8-416b-9d39-4fb7293dfb23","response":[{"instanceId":0,"displayName":"0","instanceVersion":0,"summaries":recordObj}],"version":"1.0"}
                    return data;

                }

                if(type=="summary"  && (filter['siteDeviceList'] != undefined || filter['siteId'] != undefined) 
                  && filter['roles'] != undefined) {
                    filter['siteDeviceList'] == undefined ? filter['siteId'] : filter['siteDeviceList'];
                    var data = SimConnectivityDomainData.getDeviceInfoSummary(filter) ;
                    return (data == undefined ? [] : data);
                }

                if(type=="summary"  && filter['cdFSiteList'] != undefined) {


                    var data = SimConnectivityDomainData.getFabricSummary(filter['cdFSiteList']) ;

                    return (data == undefined ? [] : data);
                    

                }

                if(type=="summary"  && filter.indexOf("fabricDomainSummary") > -1) {
                    //before 1.3.3
                    var data = SimConnectivityDomainData.getFabricDomainSummary() ;
                    return (data == undefined ? [] : data);
                } else if(type == "summary" && t[6] == "ConnectivityDomain") {
                    //from DNAC 1.3.3
                    var data = SimConnectivityDomainData.getConnectivityDomainSummary(filter);
                    return data;
                }

                 if(type=="summary"  && filter['fLanDeviceList'] != undefined) {


                    var data = SimConnectivityDomainData.getLanFabricSummary(filter) ;

                    return (data == undefined ? [] : data);
                    

                }

                if(type=="summary"  && filter['vncList'] != undefined) {
                    //commenting below line in shockwave
                    // var data = SimConnectivityDomainData.getVirtualNetworkContextSummary(filter) ; 
                    var data = SimConnectivityDomainData.getVirtualNetworkContextSummary_2_2_3(filter)
                    return (data == undefined ? [] : data);
                } else if(type=="summary" && t[6] == 'virtualnetworkcontext' && filter['vncUuid']) {
                    //on clicking a VN from Host Onboarding
                    var data = SimConnectivityDomainData.getVirtualNetworkContextSummaryForId(filter['vncUuid']) ;
                    return data;
                } else if(type=="summary" && filter['vncId']) {
                    var data = SimConnectivityDomainData.getVirtualNetworkContextSummaryForId(filter['vncId']) ;
                    return data;
                }else if(type=="summary" && filter['virtualNetworkContextId'] && tId=='VirtualNetwork') {
                    var recordObj = SimLokiDatabaseActions.getFilteredRecordHandler('VirtualNetwork', {'virtualNetworkContextId':filter['virtualNetworkContextId']})
                    var data = {"id":"7d65e8ca-f28f-4347-8b6a-1a6d32b0c7a4","response":[{"instanceId":0,"displayName":"0","instanceVersion":0,"summaries":recordObj}],"version":"1.0"}
                    return data;
                }else if(type=="summary" && tId=='VirtualNetwork') {
                    var recordObj = SimLokiDatabaseActions.getAll('VirtualNetwork')
                    var data = {"id":"330894ba-9ab2-4753-b1be-3ef6f8b200e6","response":[{"instanceId":0,"displayName":"0","instanceVersion":0,"summaries":recordObj}],"version":"1.0"}
                    return data;
                } else if(type=="summary" && tId=="Segment") {
                    if(filter['count'] == 'true'){
                        var recordObj = SimLokiDatabaseActions.getAll('Segment');
                        var data = {"id":"0ff6c87f-01cf-4e99-9a19-8dea5d2db530","response":[{"instanceId":0,"displayName":"0","instanceVersion":0,"count":recordObj.length}],"version":"1.0"}
                        return data;
                    }else {
                        var recordObj = SimLokiDatabaseActions.getAll('Segment');
                        var data = {"id":"e33d0a96-cfa2-4bf7-a3f3-7ac3e149a238","response":[{"instanceId":0,"displayName":"0","instanceVersion":0,"summaries":recordObj}],"version":"1.0"}
                        return data;
                    }
                }
                else if(type=="summary" && tId=="L2Segment") {
                    // need to review the code Karan Gaurdian
                    var recordObj = SimLokiDatabaseActions.getAll('Segment');
                    if(filter['count'] == 'true'){
                        var data = {"id":"0ff6c87f-01cf-4e99-9a19-8dea5d2db520","response":[{"instanceId":0,"displayName":"0","instanceVersion":0,"count":recordObj.length}],"version":"1.0"}
                        return data;
                    }else {
                        // var recordObj = SimLokiDatabaseActions.getFilteredRecordHandler('Segment',{'connectivityDomainId':filter.connectivityDomainId});
                        var data = {"id":"e33d0a96-cfa2-4bf7-a3f3-7ac3e149a240","response":[{"instanceId":0,"displayName":"0","instanceVersion":0,"summaries":recordObj}],"version":"1.0"}
                        return data;
                    }
                }

                if(type == 'scalablegroup'){
                    var recordObj;
                    if(filter['identitySource.type'] == 'ISE'){
                        //recordObj = SimLokiDatabaseActions.getFilteredRecordHandler('scalablegroup', {'identitySource.type' : 'ISE'});
                        recordObj = SimLokiDatabaseActions.getFilteredRecordHandler('scalablegroup', {'scalableGroupType' : 'USER_DEVICE'});
                    } else if(filter['attributes'] == 'networkApplications'){
                        return NetworkApplicationTemplate2.data.slice(parseInt(filter['offset'])-1, parseInt(filter['offset'])-1+parseInt(filter['limit']))
                       // recordObj = SimLokiDatabaseActions.getFilteredRecordHandler('scalablegroup', {'scalableGroupType' : 'APPLICATION'});
                       // var json= JSON.parse(JSON.stringify(AppListTemplate.Application_Template));
                       // recordObj= recordObj.concat(json);
                       // recordObj = getNetworkApplication(recordObj)                       
                    } else if(filter['scalableGroupType'] == 'APPLICATION'){
                       recordObj = SimLokiDatabaseActions.getFilteredRecordHandler('scalablegroup', {'scalableGroupType' : 'APPLICATION'});
                       var json= JSON.parse(JSON.stringify(AppListTemplate.Application_Template));
                       recordObj= recordObj.concat(json);
                    } else if(filter['attributes']==='applicationSet') {
                        // return ApplicationSetTemplate.data
                        let records = SimLokiDatabaseActions.getAll('applicationSet');
                        if(records.length==0){
                            SimLokiDatabaseActions.insert('applicationSet', ApplicationSetTemplate.data);
                            records = SimLokiDatabaseActions.getAll('applicationSet');
                        }
                        return records;
                    } else if(filter['attributes']==='application') {
                        return ApplicationTemplate.data.slice(parseInt(filter['offset'])-1, parseInt(filter['offset'])-1+parseInt(filter['limit']))
                    } else if(filter['attributes']==='applicationPolicy' && filter['name']==='Application-set-default-policy') {
                        return ApplicationSetDefaultPolicyTemplate.data
                    } else if(filter['securityGroupTag'] != undefined){
                        var Obj = []
                        return Obj;
                    }

                     else {
                        recordObj = SimLokiDatabaseActions.getAll('scalablegroup');
                    }

                    return (recordObj && recordObj.length ? recordObj : []);
                    
                }
                if(type=="summary" && tId == "virtualnetworkcontext"){
                    if(filter['count'] == 'true'){
                        var recordObj = SimLokiDatabaseActions.getAll('virtualnetworkcontext');
                        var data = {"id":"2a595fe5-25e4-4c29-b487-bc7de63148d0","response":[{"instanceId":0,"displayName":"0","instanceVersion":0,"count":recordObj.length}],"version":"1.0"}
                        return data;
                    }else {
                        var recordObj = SimConnectivityDomainData.getVirtualNetworkContextSummary_2_2_3(filter)
                        return recordObj
                    }
                    
                }
                if(type=="virtualnetworkcontext" && tId != undefined){
                    var recordObj = SimLokiDatabaseActions.getFilteredRecordHandler('virtualnetworkcontext',{'id':tId});
                    return recordObj;
                }
                else {
                    var recordObj = SimLokiDatabaseActions.getFilteredRecordHandler(type, {'qualifier' : t[6].split('?')[0]});
                    return (recordObj && recordObj.length ? recordObj : []);
                    
                }
            }

            if(type == 'scalablegroup' && filter['id'] != undefined && filter['id'].length ){
                var result = [], j;
                var scalableGroupHash = new Object();
                var tGroup = SimLokiDatabaseActions.getAll(type);
                var recordObj2 = JSON.parse(JSON.stringify(TCP_UDP_Template_1.TCP_UDP_TEMPLATE_1));

                Array.prototype.push.apply(tGroup, recordObj2);


                for(j =0; j < tGroup.length; j++){
                   scalableGroupHash[tGroup[j].id] = j;
                }

                var tArr = filter['id'].split(',');
                var t_Arr1 = UtilityFunctions.remove_duplicates(tArr);

                var res = getScalablegroupForPolicy(t_Arr1);
                if(res.bPolicy){
                    return (res.policy);
                    
                }
                res = getCopyPolicy(t_Arr1);
                if(res.bCopyPolicy){
                    return (res.policy);
                    
                }

                for(j=0; j < t_Arr1.length; j++){
                    if(scalableGroupHash[t_Arr1[j]] != undefined) {
                        result.push( tGroup[ scalableGroupHash[t_Arr1[j]] ] );
                    } else {
                        var res = getContract(t_Arr1[j]);
                        if(res.bContract){
                            result.push(res.contract[0]);
                        }
                    }
                }
                /*for(var i=0; i < ScalableGroupIseTemplate.ScalableGroupIse_Template.length; i++) {
                    if( ScalableGroupIseTemplate.ScalableGroupIse_Template[i].scalableGroupType == 'USER_DEVICE') {
                        var t_Json = JSON.parse(JSON.stringify( ScalableGroupIseTemplate.ScalableGroupIse_Template[i]));
                        //tVnContextJson.scalableGroup.push({ idRef: t_Json.id });
                        result.push(t_Json);
                    }
                }*/
                if(result.length == 0){
                    result = SimLokiDatabaseActions.getFilteredRecordHandler('ip-network-group',{'id' : tArr[0]})
                }
                return (result);
                
            }

            if(type == 'virtualnetworkcontext' && filter['id'] != undefined && filter['id'] != ''){
                var data = SimLokiDatabaseActions.getFilteredRecordHandler('virtualnetworkcontext', {'id' : filter['id']});
                return (data == undefined ? [] : data);
                
            }

            if(type=="ConnectivityDomain"  && filter['domainType'] != undefined) {

                var filterValue = filter['domainType'].split(",");
                console.log(filterValue);
                var data = SimLokiDatabaseActions.getFilteredRecordHandler('ConnectivityDomain', {'domainType' : { '$containsAny' :  filterValue}});

                return (data == undefined ? [] : data);
                

            }
            if(type=="ConnectivityDomain"  && filter['id'] != undefined) {
                var filterValue = [filter['id']];
                if(filter['id'].indexOf("%2C")>=0 ) {
                    filterValue = filter['id'].split("%2C");
                } else if(filter['id'].indexOf(",")>=0 ) {
                    filterValue = filter['id'].split(",");
                }
                //console.log(filterValue);
                var data = SimLokiDatabaseActions.getFilteredRecordHandler('ConnectivityDomain', {'id' : { '$containsAny' :  filterValue}});
                if(data && data.length>0) {
               //Transit PubSub/List changes - start
                    data = JSON.parse(JSON.stringify(data));
                    data.forEach(e1=>{
                        e1.deviceInfo = {'__isArray':true,
                        '__link':'/api/v2/data/lazy-load/com.cisco.dnac.model.ConnectivityDomain/'+filter['id']+'/deviceInfo'};
                        e1.authenticationProfiles = {'__isArray':true,
                        '__link':'/api/v2/data/lazy-load/com.cisco.dnac.model.ConnectivityDomain/'+filter['id']+'/authenticationProfiles'};
                        if(e1.fabricAuthenticationProfile == ""){
                            e1.fabricAuthenticationProfile = (e1.name == "Global/North_America/USA/California/San_Jose/SJC01_SanJose_Fabric") || ("Global/North_America/USA/California/San_Jose/SJC06_SanJose_Fabric") ? {'__isArray':true,
                            '__link':'/api/v2/data/lazy-load/com.cisco.dnac.model.ConnectivityDomain/'+filter['id']+'/fabricAuthenticationProfile'}:{};
                        }
                    });
                    /* data.forEach(e1=>{
                        if(e1.deviceInfo && e1.deviceInfo.length>0) {
                            e1.deviceInfo.forEach(e2=>{
                                e2.id=e2.idRef;
                            })
                        }
                    }) */
               //Transit PubSub/List changes - end
                }
                return (data == undefined ? [] : data);
            }

            if(type == "summary") {
                if(t[6]=="policy" && t[7]=="access") {
                    let obj = {};
                    obj["response"] = SummaryPolicyAccessDeployTemplate.DeployTemplate;
                    obj["id"] = UtilityFunctions.generate_uuid();
                    obj["version"] = "1.0";
                    return obj;
                }
            }
            if(type=="Segment" && filter) {
                var segData = SimLokiDatabaseActions.getAll("Segment");
                // if(filter.namespace) segData = segData.filter(e => e.connectivityDomain.idRef==filter.namespace);
                if(filter.isCriticalPool != undefined) segData = segData.filter(e => e.isCriticalPool==filter.isCriticalPool)
                return segData;
            }

            var data = SimLokiDatabaseActions.getAll(type);
            if(type == 'virtualnetworkcontext'){
                let data = JSON.parse( JSON.stringify( SimLokiDatabaseActions.getAll(type) ) );
                 //data.scalableGroup = [] not sure of this line
                let recordObj = SimLokiDatabaseActions.getAll('scalablegroup');
                data.forEach(function(dataobj) {
                    recordObj.forEach(function(scalablegroupObj) {
                        if(scalablegroupObj.virtualNetworkNames.includes(dataobj.name)){
                            dataobj.scalableGroup.push({"idRef" : scalablegroupObj.id});
                        }
                    });
                });
                console.log(data);
                return data;
            }
            if(type == 'ExtranetPolicy'){
                var data = SimLokiDatabaseActions.getAll('extranet-policies');
                return data
            }
            return (data == undefined ? [] : data);
        },

        getAudit: function (url ) {
            var recordObj = SimLokiDatabaseActions.getAll('audit');
            return (recordObj && recordObj.length ? recordObj : []);
        },

        CfsPost: function (url, jsonObj ) {
            var resultObj = SimTaskDataGenerator.createTask('SPFService');
            var type = '';

            var t = url.split('/');
            var type = t[5];
            type = type.split('?')[0];
            console.log("type = " + type);

            if (type == 'APModeChange') {
                SimTaskDataGenerator.updateTaskCompletion(resultObj.taskId, {isError: false });
                resultObj.statusCode= 202;
                return (resultObj);                
            }

            if (type == 'copydestination') {
                type = "CopyDestination";
                SimDataGenerationInit.cfsCUDoperation(type, 'post', jsonObj);
                SimTaskDataGenerator.updateTaskCompletion(resultObj.taskId, {isError: false });
                resultObj.statusCode= 202;
                return (resultObj);                
            }


            if (type == 'policy' || type == 'contract') {
                var subtype = t[6];
                subtype = subtype.split('?')[0];
                // subtype = (subtype == 'ipaccess') ? 'access' : subtype
                if(t[4] == 'cfs-intent' && t[5] == 'policy' && t[6] == 'ipaccess'){
                    type = 'IPAccessControl';
                    SimPolicyData.CUDIPAccessControl(resultObj.taskId, 'post', jsonObj);
                }
                else if (subtype == 'access' || subtype == 'ipaccess') {
                    if (type == 'policy') {
                        type = 'AccessPolicy';
                    }else if(subtype == 'ipaccess'){
                        type = 'IPAccessContract';
                    }else {
                        type = 'ContractAccess';
                    }
                } else if (subtype == 'copy') {
                    if (type == 'policy') {
                        type = 'CopyPolicy';
                    } else {
                        type = 'copyContract';
                    }

                } else if (subtype == 'application') {
                    type = 'ApplicationContract';
                }
                SimDataGenerationInit.cfsCUDoperation(type, 'post', jsonObj);
                SimTaskDataGenerator.updateTaskCompletion(resultObj.taskId, {isError: false });
                resultObj.statusCode= 202;
                return (resultObj);                
            }


            if (type == 'scratchpad') {
               type = 'ApplicationPolicyPreview';
                SimDataGenerationInit.cfsCUDoperation(type, 'post', jsonObj);
                SimTaskDataGenerator.updateTaskCompletion(resultObj.taskId, {isError: false });
                resultObj.statusCode= 202;
                return (resultObj);                
            }

            if (type == 'scalablegroup') {
                let subType = t[6];
                var resultObj1 = SimDataGenerationInit.cfsCUDoperation(type, 'post', jsonObj);
                if(resultObj1) {
                    resultObj1.statusCode= 202;
                    return resultObj1;
                }
                SimTaskDataGenerator.updateTaskCompletion(resultObj.taskId, {isError: false });
                resultObj.statusCode= 202;
                return (resultObj);                
            }

            if(type == "airsense") {
                var subType =  t[6];
                SimScheduledCaptureData.updateSettings(subType,jsonObj)
                SimTaskDataGenerator.updateTaskCompletion(resultObj.taskId, {isError: false });
                resultObj.statusCode= 202;
                return (resultObj);
            }

            if(type == apWirelessServiceType) {
                SimDataGenerationInit.cfsCUDoperation(type,'post', jsonObj);
                SimTaskDataGenerator.updateTaskCompletion(resultObj.taskId, {isError: false });
                resultObj.statusCode= 202;
                return (resultObj);                
            }

            type = jsonObj.type;
            if (jsonObj.virtualNetworkContextType != undefined && jsonObj.virtualNetworkContextType != '') {
                type = 'virtualnetworkcontext';
            }

            SimDataGenerationInit.cfsCUDoperation(type, 'post', jsonObj);
            SimTaskDataGenerator.updateTaskCompletion(resultObj.taskId, {isError: false });
            resultObj.statusCode= 202;
            return (resultObj);
        },

        CfsPut: function ( url,  jsonObj, paramNamesAndValues) {

            var resultObj = {};
            //var type = jsonObj.type;
            var type;

            if($.isEmptyObject(jsonObj) == false){
              type = jsonObj.type;
            }

            var t = url.split('/');
            var type = t[5];
            type = type.split('?')[0];

            if(type=='ConnectivityDomain' && jsonObj.domainType=='FABRIC_SITE') {
                resultObj = SimTaskDataGenerator.createTask('SPFService', {'currentDelayCount':0,'maxDelayNoOfCalls':2});
                var childTaskObj = SimTaskDataGenerator.createChildTask('SPFService',  {'currentDelayCount':0,'maxDelayNoOfCalls':2});
                resultObj["childTaskId"]=childTaskObj.taskId;
                SimTaskDataGenerator.updateTaskCompletion(childTaskObj.taskId, {isError: false });
            } else {
                resultObj = SimTaskDataGenerator.createTask('SPFService');
            }  

           if (type == 'APModeChange') {
                SimTaskDataGenerator.updateTaskCompletion(resultObj.taskId, {isError: false });
                resultObj.statusCode= 202;
                return (resultObj);                
            }

            if (type == 'scalablegroup') {
                SimDataGenerationInit.cfsCUDoperation(type, 'PUT', jsonObj);
                SimTaskDataGenerator.updateTaskCompletion(resultObj.taskId, {isError: false });
                resultObj.statusCode= 202;
                return (resultObj);                
            }

            if(type == "DeviceInterfaceInfoContainer") {
                SimDataGenerationInit.cfsCUDoperation(type, 'PUT', jsonObj);
                SimTaskDataGenerator.updateTaskCompletion(resultObj.taskId, {isError: false });
                resultObj.statusCode= 202;
                return (resultObj);
            } 

            if (type == 'copydestination') {
                type = "CopyDestination";
                SimDataGenerationInit.cfsCUDoperation(type, 'put', jsonObj);
                SimTaskDataGenerator.updateTaskCompletion(resultObj.taskId, {isError: false });
                resultObj.statusCode= 202;
                return (resultObj);
                
            } else if (type == 'policy' || type == 'contract') {
                var subtype = t[6];
                subtype = subtype.split('?')[0];
                // subtype = (subtype == 'ipaccess') ? 'access' : subtype
                if (subtype == 'access' || subtype == 'ipaccess') {
                    if (type == 'policy') {
                        type = 'AccessPolicy';
                    }else if(subtype == 'ipaccess'){
                        type = 'IPAccessContract';
                    }else {
                        type = 'ContractAccess';
                    }
                } else if (subtype == 'copy') {

                    if (type == 'policy') {
                        type = 'CopyPolicy';
                    } else {
                        type = 'copyContract';
                    }
                }  else if (subtype == 'application') {
                    type = 'ApplicationContract';
                }
                SimDataGenerationInit.cfsCUDoperation(type, 'put', jsonObj);
                SimTaskDataGenerator.updateTaskCompletion(resultObj.taskId, {isError: false });
                resultObj.statusCode= 202;
                return (resultObj);
                
            }else if(type == apWirelessServiceType) {
                SimDataGenerationInit.cfsCUDoperation(type,'put', jsonObj);
                SimTaskDataGenerator.updateTaskCompletion(resultObj.taskId, {isError: false });
                resultObj.statusCode= 202;
                return (resultObj);                
            }

            if (jsonObj != undefined && jsonObj.namespace == 'VirtualNetworkContext') {
                type = 'virtualnetworkcontext';
            }
            SimDataGenerationInit.cfsCUDoperation(type, 'put', jsonObj, paramNamesAndValues);
            SimTaskDataGenerator.updateTaskCompletion(resultObj.taskId, {isError: false });
            resultObj.statusCode= 202;
            return (resultObj);
        },

        CfsDelete: function (urlAction, paramNamesAndValues) {
            var url = urlAction.url;
            var resultObj = SimTaskDataGenerator.createTask('SPFService');
            var t = url.split('/');
            var type = t[5];
            var jsonObj = {id: ''};
            if (type == 'scalablegroup') {
                jsonObj.id = t[6];
                SimDataGenerationInit.cfsCUDoperation(type, 'DELETE', jsonObj);
                SimTaskDataGenerator.updateTaskCompletion(resultObj.taskId, {isError: false });
                return (resultObj);
                
            }

            if (type == 'copydestination') {
                jsonObj.id = t[7];
                type = "CopyDestination";
                SimDataGenerationInit.cfsCUDoperation(type, 'delete', jsonObj);
                SimTaskDataGenerator.updateTaskCompletion(resultObj.taskId, {isError: false });
                return (resultObj);
                
            } else if (type == 'policy' || type == 'contract') {
                var subtype = t[6];
                subtype = subtype.split('?')[0];
                // subtype = (subtype == 'ipaccess') ? 'access' : subtype
                if (subtype == 'access' || subtype == 'ipaccess') {
                    if (type == 'policy') {
                        type = 'AccessPolicy';
                    }else if(subtype == 'ipaccess'){
                        type = 'IPAccessContract';
                    }else {
                        type = 'ContractAccess';
                    }
                }  else if (subtype == 'copy') {
                    jsonObj.id = t[7];

                    if (type == 'policy') {
                        type = 'CopyPolicy';
                    } else {
                        type = 'copyContract';
                    }
                } else if (subtype == 'application') {
                    jsonObj.id = t[7];
                    type = 'ApplicationContract';
                }
            } else if(type == 'airsense') {

                resultObj = SimScheduledCaptureData.deleteSettings(t[7],t[6])
                 return (resultObj);
                 return
            }
            else {
                jsonObj.id = t[6].split('?')[0];
            }
            if(jsonObj.id == undefined || jsonObj.id == "") {
                if(urlAction.action.id) {
                    jsonObj.id = urlAction.action.id;
                } else {
                    jsonObj.id = urlAction.restPayload.id;
                }
            }
            var isError = SimDataGenerationInit.cfsCUDoperation(type, 'delete', jsonObj, paramNamesAndValues);
            if(isError) {
                SimTaskDataGenerator.updateTaskCompletion(resultObj.taskId, 
                    {'isError':true, 'errorCode':'NCSO20022', 'failureReason':'Provisioning failed due to invalid parameter. Cannot delete fabric LAN with fabric site present under it, delete site first.'});
            } else {
                SimTaskDataGenerator.updateTaskCompletion(resultObj.taskId, {isError: false });
            }
            return (resultObj);
        },

        getAuditCount: function (url ) {
            var count = 0;
            var recordObj = SimLokiDatabaseActions.getAll('audit');

            if (recordObj && recordObj.length) {
                count = recordObj.length;
            }
            return (count);
        },

        getDeviceConfigStatusCount: function(filter ) {
           var count = 0;
           if(filter['cfsNamespace'] == 'undefined') return count;
           if (filter['cfsNamespace'] != undefined && filter['cfsNamespace'].length > 0) {
               if(filter['cfsNamespace'].search(/^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$/) == 0){
                   var data = getDeviceSpecificConfigStatus(filter['cfsNamespace']);
                    return (data && data.length ? data : []);
               }


               var configName = filter['cfsNamespace'].split(':');
               if (configName[0] == 'policy' && configName[1] == 'access') {
                   var recordObj = SimLokiDatabaseActions.getAll('access-policy');
                   var t_Json = JSON.parse(JSON.stringify(AccessPolicyTemplate.ACCESS_POLICY_STATUS_TEMPLATE));
                   var data = [];
                   if (recordObj && recordObj.length) {
                       count = recordObj.length;
                       t_Json.id = UtilityFunctions.generate_uuid();
                       t_Json.createTime = UtilityFunctions.getTimeStamp();
                       t_Json.lastUpdateTime = UtilityFunctions.getTimeStamp();
                       t_Json.namespace = filter['cfsNamespace'];
                       t_Json.provisioningData["Access-Policy-Count-Details"] = {
                            "Success_Count": count,
                            "Failure_Count": "0"
                       };
                       data.push(t_Json);
                   }
                   return (data && data.length ? data : []);
                   
               }
              if (configName[0] == 'policy' && configName[1] == 'application') {
                 var data;
                 if(filter['status']!=undefined) {
                    var statusFilterVal = filter['status'];
                    var statusFilter = statusFilterVal.split("%2");

                    data = SimLokiDatabaseActions.getFilteredRecordHandler('DeviceConfigStatus',  {'$and': [ {
                                                                  'namespace': filter['cfsNamespace']
                                                                },
                                                                 {
                                                                   'status': { '$containsAny' :  statusFilter}}]});

                  } else {

                    data = SimLokiDatabaseActions.getFilteredRecordHandler('DeviceConfigStatus',{'namespace': filter['cfsNamespace']});
                  }
                return (data && data.length );
              }
           }

            if (filter['status'] != undefined ) {

             data = SimLokiDatabaseActions.getFilteredRecordHandler('DeviceConfigStatus',  {
                                                               'aggregatedStatus':"Not Provisioned"});
              return (data && data.length );
              

            }

            if (filter['aggregatedStatus'] != undefined) {
                data = SimLokiDatabaseActions.getFilteredRecordHandler('DeviceConfigStatus', {'aggregatedStatus':decodeURI(filter['aggregatedStatus'])});
                return (data && data.length);
                   
            }

        },

         getDeviceConfigStatus: function(filter ) {

           var data = [];
            if( filter['deviceId'] != undefined) {
                var commaSeperatedId = filter['deviceId'];
                if(commaSeperatedId) {
                    var deviceId = commaSeperatedId.split(',');
                    var aggregateStatus = {};

                    for (commaIndex=0; commaIndex<deviceId.length;commaIndex++) {
                        var record = SimLokiDatabaseActions.getFilteredRecordHandler('DeviceConfigStatus', {'deviceId': deviceId[commaIndex]});
                        if(record && record.length>0) {
                            data.push(record[0]);
                        }
                    }
                }
                return (data == undefined ? [] : data);
            }

            if(filter['workflowId'] != undefined && filter['deviceId'] != undefined) {
                   var deviceId = filter['deviceId']
                   var workflowId = filter['workflowId']
                   if(deviceId && workflowId) {
                    var record = SimLokiDatabaseActions.getFilteredRecordHandler('DeviceConfigStatus',{'$and':[{'deviceId': deviceId},{'workflowId':workflowId}]});
                    return (record && record.length)
                   }
            }


             var data;

             if(filter['status']!=undefined) {

                var statusFilterVal = filter['status'];
                var statusFilter = statusFilterVal.split("%2");

                 data = SimLokiDatabaseActions.getFilteredRecordHandler('DeviceConfigStatus',  {'$and': [ {
                                                                  'namespace': filter['cfsNamespace']
                                                                },
                                                                 {
                                                                   'status': { '$containsAny' :  statusFilter}}]});
              } else if(filter["cfsNamespace"]) {
                var x = SimLokiDatabaseActions.getFilteredRecordHandler('ConnectivityDomain', {'id':filter['cfsNamespace']});
                if(x.length>0) {
                    x = x[0].deviceInfo.map(obj => obj.idRef);
                    data = SimLokiDatabaseActions.getFilteredRecordHandler('DeviceInfo', {'id':{'$in':x}});
                } else {
                    if(filter.cfsNamespace.split(':')[1] == 'ipaccess'){
                        data = SimLokiDatabaseActions.getFilteredRecordHandler('ip-access-control', {'namespace':filter.cfsNamespace})
                    }else if(filter.cfsNamespace == 'IPAccessContract'){
                        data = SimLokiDatabaseActions.getAll('ip-access-control')
                    }
                    else{
                        data = SimLokiDatabaseActions.getFilteredRecordHandler('DeviceConfigStatus',{'namespace': filter['cfsNamespace']});
                    }
                }
            }

            return (data == undefined ? [] : data);
         }
          ,
        getGroupNames : function(url,filter ){

            var data = SimLokiDatabaseActions.getAll('group-name');
            return (data);

        },

        CfsSsid : function(url ){
            var data = SimLokiDatabaseActions.getAll('segment-ssid');
            return (data);
        },

        CfsIntent: function(url, jsonObj ){
            var resultObj = SimTaskDataGenerator.createTask('SPFService');

            var t = url.split('/');
            var type = t[5];
            type = 'AccessPolicy';

            for (i = 0; i < jsonObj.deleteList.length; i++) {
                SimDataGenerationInit.cfsCUDoperation(type, 'delete', {"id":jsonObj.deleteList[i]});
            }
            SimTaskDataGenerator.updateTaskCompletion(resultObj.taskId, {isError: false });
            return (resultObj);
            
        },

        CfsIntentScalableGroup: function(url, jsonObj ){
            var resultObj = SimTaskDataGenerator.createTask('SPFService');

            for (i = 0; i < jsonObj.deleteList.length; i++) {
                SimDataGenerationInit.cfsCUDoperation('scalablegroup', 'DELETE', {"id":jsonObj.deleteList[i]});
            }
            SimTaskDataGenerator.updateTaskCompletion(resultObj.taskId, {isError: false });
            return (resultObj);
            
        },

        CfsIntentCopyPolicy: function(url, jsonObj ){
            var resultObj = SimTaskDataGenerator.createTask('SPFService');

            for (i = 0; i < jsonObj.deleteList.length; i++) {
                SimDataGenerationInit.cfsCUDoperation('CopyPolicy', 'delete', {"id":jsonObj.deleteList[i]});
            }
            SimTaskDataGenerator.updateTaskCompletion(resultObj.taskId, {isError: false });
            return (resultObj);
            
        },

        CfsIntentContractAccess: function(url, jsonObj ){
            var resultObj = SimTaskDataGenerator.createTask('SPFService');
            if(url.indexOf('ipaccess')>=0){
                for (i = 0; i < jsonObj.deleteList.length; i++) {
                    SimDataGenerationInit.cfsCUDoperation('IPAccessContract', 'delete', {"id":jsonObj.deleteList[i]});
                }
            }else{
                for (i = 0; i < jsonObj.deleteList.length; i++) {
                    SimDataGenerationInit.cfsCUDoperation('ContractAccess', 'delete', {"id":jsonObj.deleteList[i]});
                }
            }
            SimTaskDataGenerator.updateTaskCompletion(resultObj.taskId, {isError: false });
            return (resultObj);
            
        },
        CfsIntentApplicationPolicy: function(url, jsonObj ){
            var resultObj = SimTaskDataGenerator.createTask('SPFService');

           setTimeout(function() {
           SimDataGenerationInit.cfsCUDoperation('ApplicationPolicy', 'post', jsonObj);
           SimTaskDataGenerator.updateTaskCompletion(resultObj.taskId, {isError: false });


           }, 5000)

            return (resultObj);
            
        },
        // for GroupBasedPolicy 1.3.1 by Karan 
        getACAControllerService: function(){
            var resultObj = JSON.parse(JSON.stringify(ACAControllerServiceTemplate.ACAControllerService_Template));
            return resultObj
        },
        getACAControllerServiceStatus: function(){
            var resultObj = JSON.parse(JSON.stringify(ACAControllerServiceTemplate.ACAControllerServiceStatus_Template));
            resultObj.lastUpdatedTime = UtilityFunctions.getTimeStamp();
            return resultObj
        },
        getGBACConfigurationStatus: function(){
            var resultObj = JSON.parse(JSON.stringify(ACAControllerServiceTemplate.GBACConfigurationStatus_Template));
            resultObj.lastUpdatedTime = UtilityFunctions.getTimeStamp();
            return resultObj
        },
        getGBACConfigurationScalableGroup: function(){
            var resultObj = JSON.parse(JSON.stringify(ACAControllerServiceTemplate.GBACConfigurationScalableGroup_Template));
            return resultObj
        },
        getGBACConfigurationContracts: function(){
            var resultObj = JSON.parse(JSON.stringify(ACAControllerServiceTemplate.GBACConfigurationContracts_Template));
            return resultObj
        },
        getGBACConfigurationPolicies: function(){
            var resultObj = JSON.parse(JSON.stringify(ACAControllerServiceTemplate.GBACConfigurationPolicies_Template));
            return resultObj
        },
        //Transit PubSub/List changes - start
        lazyLoad: function(urlAction) {
            data = {};
            if(urlAction.service.indexOf('com.cisco.dnac.model.ConnectivityDomain')>-1 && urlAction.service.indexOf('deviceInfo')>-1) {
                var filterObj = ['cdId']; 
                filterObj['cdId'] = urlAction.action.id != "" ? urlAction.action.id : urlAction.service[2];
                data = this.getCfsDetails("/api/v2/data/ConnectivityDomain/DeviceInfo?cdId="+urlAction.service[2], filterObj);
            }
            if(urlAction.service.indexOf('com.cisco.dnac.model.ConnectivityDomain')>-1 && urlAction.service.indexOf('authenticationProfiles')>-1) {
                data = SimConnectivityDomainData.updateAuthenticationDetails(UtilityFunctions.generate_uuid())
            }
            if(urlAction.service.indexOf('com.cisco.dnac.model.ConnectivityDomain')>-1 && urlAction.service.indexOf('fabricAuthenticationProfile')>-1) {
                data = SimConnectivityDomainData.updateAuthenticationDetails(UtilityFunctions.generate_uuid())
                return [data[0]]
            }
            return data;
        },
        //Transit PubSub/List changes - end

        /** handles add, remove, updation of border configuration of fabric role */
        processFabricRequest: function(urlAction, paramNamesAndValues) {
            var resp, restPayload = urlAction.restPayload[0], isAnyUpdated = false;
            if(restPayload && ["FABRIC_ZONE","FABRIC_SITE"].indexOf(restPayload.domainType)>-1 && paramNamesAndValues.subModule=="FABRIC" && 
                paramNamesAndValues.deviceDetail.type == "device") {
                var changeConfig = paramNamesAndValues.deviceDetail.changeConfig;
                var fdObj = SimLokiDatabaseActions.getFilteredRecordHandler('ConnectivityDomain', {'id': restPayload.id});
                changeConfig.forEach(change => {
                    switch (change.typeOfChange) {
                        case "add":
                        case "update":
                            var devObj = SimLokiDatabaseActions.getFilteredRecordHandler('DeviceInfo', {'id': change.id});
                            if (devObj.length>0) {
                                var jsonRec = JSON.parse(JSON.stringify( restPayload.deviceInfo.filter(e=>e.id==change.id) ));
                                if(change.rolesList.length>0) 
                                    devObj[0].roles = JSON.parse(JSON.stringify(change.rolesList));
                                if(jsonRec[0].deviceSettings!=undefined) {
                                    devObj[0].deviceSettings = jsonRec[0].deviceSettings;
                                }
                                devObj[0].connectivityDomain = [restPayload.id];//jsonRec[0].connectivityDomain;
                                devObj[0].deviceInterfaceInfo.length = 0;
                                if(jsonRec[0].deviceInterfaceInfo!=undefined && jsonRec[0].deviceInterfaceInfo.length) {
                                    devObj[0].deviceInterfaceInfo = jsonRec[0].deviceInterfaceInfo;
                                }
                                //if(change.logs.isSDATransitPresent) {} else if(change.logs.isNewIPTransitAdded) {}
                                if(jsonRec[0].transitNetworks!=undefined) {
                                    devObj[0].transitNetworks =jsonRec[0].transitNetworks;
                                    jsonRec[0].transitNetworks.forEach(e=>{
                                        var tempTransit = SimLokiDatabaseActions.getFilteredRecordHandler('ConnectivityDomain', {'id':e.idRef});
                                        tempTransit[0].deviceInfo.push({'idRef':change.id});
                                        SimLokiDatabaseActions.update('ConnectivityDomain', tempTransit);
                                    });
                                }
                            }
                            SimLokiDatabaseActions.update('DeviceInfo', devObj);
                            if(change.typeOfChange == "add")
                                fdObj[0].deviceInfo.push({idRef: change.id});
                            SimLokiDatabaseActions.update('ConnectivityDomain', fdObj);
                            isAnyUpdated = true;
                            break;
                        case "remove":
                            var devObj = SimLokiDatabaseActions.getFilteredRecordHandler('DeviceInfo', {'name': change.data.name});
                            devObj[0].roles = [];
                            devObj[0].deviceInterfaceInfo.length = 0, idx = -1;
                            devObj[0].connectivityDomain = [DefaultConfig.DEFAULT_UUID.ConnectivityDomain];
                            SimLokiDatabaseActions.update('DeviceInfo', devObj);
                            fdObj[0].deviceInfo.forEach((e,i) => {
                                if(e.idRef == change.id) {
                                    idx = i;
                                }
                            })
                            fdObj[0].deviceInfo.splice(idx,1);
                            SimLokiDatabaseActions.update('ConnectivityDomain', fdObj);
                            isAnyUpdated = true;
                            break;
                        default:
                            break;
                    }
                });
            }
            if(isAnyUpdated) {
                resp = SimTaskDataGenerator.createTask('SPFService');
                var childTaskObj = SimTaskDataGenerator.createChildTask('SPFService');//, {'currentDelayCount':0,'maxDelayNoOfCalls':2}
                resp.childTaskId = childTaskObj.taskId;
                SimTaskDataGenerator.updateTaskCompletion(childTaskObj.taskId, {isError: false });
                SimTaskDataGenerator.updateTaskCompletion(resp.taskId, {isError: false });
                resp.statusCode= 202;
            }
            if(paramNamesAndValues.subModule == "FABRIC" && isAnyUpdated == false && urlAction.service.indexOf('ExtranetPolicy') >-1){
                if(urlAction.method == 'DELETE'){
                    var recObj = SimLokiDatabaseActions.getFilteredRecordHandler('extranet-policies',{'id':urlAction.service[6]});
                    SimLokiDatabaseActions.delete('extranet-policies',recObj)
                }
                if(urlAction.method == "POST"){
                    var tJson = JSON.parse(JSON.stringify(ExtranetPolicy_Template.Template));
                    tJson[0].id = UtilityFunctions.generate_uuid();
                    tJson[0].createTime = UtilityFunctions.getTimeStamp();
                    tJson[0].displayName = "2469f543["+urlAction.restPayload[0].name+"]";
                    tJson[0].fabricUuids = urlAction.restPayload[0].fabricUuids;
                    tJson[0].globalPolicyName = urlAction.restPayload[0].name;
                    tJson[0].instanceCreatedOn = UtilityFunctions.getTimeStamp();
                    tJson[0].instanceUpdatedOn = UtilityFunctions.getTimeStamp();
                    tJson[0].lastUpdateTime = UtilityFunctions.getTimeStamp();
                    tJson[0].name = urlAction.restPayload[0].name;
                    tJson[0].namespace = tJson[0].id;
                    tJson[0].policyType = urlAction.restPayload[0].policyType;
                    
                    var providers_VN = SimLokiDatabaseActions.getFilteredRecordHandler('virtualnetworkcontext',{'name':urlAction.restPayload[0].providers[0].name});
                    tJson[0].providers[0].displayName = providers_VN[0].name;
                    tJson[0].providers[0].id = providers_VN[0].id;
                    tJson[0].providers[0].instanceCreatedOn = providers_VN[0].instanceCreatedOn;
                    tJson[0].providers[0].instanceId = providers_VN[0].instanceId;
                    tJson[0].providers[0].instanceUpdatedOn = providers_VN[0].instanceUpdatedOn;
                    tJson[0].providers[0].instanceVersion = providers_VN[0].instanceVersion;
                    tJson[0].providers[0].name = providers_VN[0].name;

                    var subscribers_VN = SimLokiDatabaseActions.getFilteredRecordHandler('virtualnetworkcontext',{'name':urlAction.restPayload[0].subscribers[0].name});
                    tJson[0].subscribers[0].displayName = subscribers_VN[0].name;
                    tJson[0].subscribers[0].id = subscribers_VN[0].id;
                    tJson[0].subscribers[0].instanceCreatedOn = subscribers_VN[0].instanceCreatedOn;
                    tJson[0].subscribers[0].instanceId = subscribers_VN[0].instanceId;
                    tJson[0].subscribers[0].instanceUpdatedOn = subscribers_VN[0].instanceUpdatedOn;
                    tJson[0].subscribers[0].instanceVersion = subscribers_VN[0].instanceVersion;
                    tJson[0].subscribers[0].name = subscribers_VN[0].name;
                    SimLokiDatabaseActions.insert('extranet-policies', tJson);
                }
                else if(urlAction.method == "PUT"){
                    var recObj = SimLokiDatabaseActions.getFilteredRecordHandler('extranet-policies',{'id':urlAction.restPayload[0].id});
                    recObj[0].lastUpdateTime = UtilityFunctions.getTimeStamp();
                    var subscribers_VN = SimLokiDatabaseActions.getFilteredRecordHandler('virtualnetworkcontext',{'name':urlAction.restPayload[0].subscribers[0].name});
                    recObj[0].subscribers[0].displayName = subscribers_VN[0].name;
                    recObj[0].subscribers[0].id = subscribers_VN[0].id;
                    recObj[0].subscribers[0].instanceCreatedOn = subscribers_VN[0].instanceCreatedOn;
                    recObj[0].subscribers[0].instanceId = subscribers_VN[0].instanceId;
                    recObj[0].subscribers[0].instanceUpdatedOn = subscribers_VN[0].instanceUpdatedOn;
                    recObj[0].subscribers[0].instanceVersion = subscribers_VN[0].instanceVersion;
                    recObj[0].subscribers[0].name = subscribers_VN[0].name;
                    SimLokiDatabaseActions.update('extranet-policies', recObj);
                }
            }
            if(urlAction.method == "PUT" && urlAction.url.indexOf('VirtualNetwork') >-1){
                var recObj = SimLokiDatabaseActions.getFilteredRecordHandler('VirtualNetwork',{'id':urlAction.restPayload[0].id});
                SimVirtualNetworkData.AssociateVnToSegment(restPayload);
                var segInfo = SimLokiDatabaseActions.getFilteredRecordHandler('Segment',{'associatedL3VNName':restPayload.name})
                if(segInfo.length > 0){
                    segInfo[0].segment = urlAction.restPayload[0].segment;
                    SimLokiDatabaseActions.update('Segment', segInfo);
                    recObj[0].segment[0].idRef = segInfo[0].id;
                    SimLokiDatabaseActions.update('VirtualNetwork',recObj);
                }
            }
            return resp;
        }


    };

    function getCopyPolicy(tArr) {
        var result = { 'bCopyPolicy': false, 'policy': []};
        if(tArr.length == 1) {
            var recordObj = SimLokiDatabaseActions.getAll('copy-policy');
            if (recordObj && recordObj.length) {
                var j;
                for(j=0; j < recordObj.length; j++){
                    if( (recordObj[j].id === tArr[0]) == true) {
                        result.bCopyPolicy = true;
                        result.policy.push(recordObj[j]);
                        return result;
                    }
                }
            }
        }
        return result;
    }

    function getScalablegroupForPolicy(tArr) {
        var result = { 'bPolicy': false, 'policy': []};
        if(tArr.length == 1) {
            var subtype = 'access', type = 'policy';
            var recordObj = SimLokiDatabaseActions.getAll(getLokiDbName(subtype,type));
            if (recordObj && recordObj.length) {
                var j;
                for(j=0; j < recordObj.length; j++){
                    if( (recordObj[j].id === tArr[0]) == true) {
                        result.bPolicy = true;
                        result.policy.push(recordObj[j]);
                        return result;
                    }
                }
            }
        }
        return result;
    }

    function getContract(tId) {
        var result = { 'bContract': false, 'contract': []};
        var subtype = 'access', type = 'contract';
        var recordObj = SimLokiDatabaseActions.getAll(getLokiDbName(subtype,type));
        if (recordObj && recordObj.length) {
            for(j=0; j < recordObj.length; j++){
                if( (recordObj[j].id === tId) == true) {
                    result.bContract = true;
                    result.contract.push(recordObj[j]);
                    return result;
                }
            }
        }

        var recordObj = SimLokiDatabaseActions.getAll('copy-contract');
        if (recordObj && recordObj.length) {
            for(j=0; j < recordObj.length; j++){
                if( (recordObj[j].id === tId) == true) {
                    result.bContract = true;
                    result.contract.push(recordObj[j]);
                    return result;
                }
            }
        }

        var recordObj = SimLokiDatabaseActions.getAll('copy-destination');
        if (recordObj && recordObj.length) {
            for(j=0; j < recordObj.length; j++){
                if( (recordObj[j].id === tId) == true) {
                    result.bContract = true;
                    result.contract.push(recordObj[j]);
                    return result;
                }
            }
        }

        return result;
    }

    function getDeviceInterfacePortChannel(tId) {

        var ifaceObj = JSON.parse(JSON.stringify(DeviceInterfaceInfoContainer.Template));
        var recordObj = SimLokiDatabaseActions.getFilteredRecordHandler('interface-port-channel',{'id': tId });
        if (recordObj && recordObj.length){
            ifaceObj.networkDeviceId = recordObj[0].networkDeviceId;
            ifaceObj.id = tId;
            ifaceObj.name = recordObj[0].name;
            ifaceObj.status = recordObj[0].status;
            ifaceObj.interfaceInfo = recordObj[0].interfaceInfo;
        } else {
           /*When simulation initialize, DeviceInfo create entry for deviceInterfaceInfoContainer, but entry in 
            interface-port-channel is done only for edge device only.
           However after initialization some device is marked as 'EDGE' device, as this entry is not present,
           is created here for that edge device for interface-port-channel.*/
           var deviceObjs = SimLokiDatabaseActions.getAll('DeviceInfo');
           var tObj = deviceObjs.filter(function (itm) { return (itm.deviceInterfaceInfoContainer.idRef == tId) })
           if(tObj.length == 0) {
               return [ifaceObj];
           }
           var portChannelObj = {};
           portChannelObj.id = tId;
           portChannelObj.name = "DeviceIntfContainer_" + tObj[0].networkDeviceId;
           portChannelObj.networkDeviceId = tObj[0].networkDeviceId;
           portChannelObj.interfaceInfo = [];
           SimLokiDatabaseActions.insert('interface-port-channel', portChannelObj);

           ifaceObj.id = tId;
           ifaceObj.interfaceInfo = [];
           ifaceObj.networkDeviceId = tObj[0].networkDeviceId; 
        }

        return [ifaceObj];
    }

    function getWlanSsid(filter) {
        if(filter.id != undefined && filter.id != "") {
            var tIds = filter.id.split(',');
            var tArr = SimLokiDatabaseActions.getFilteredRecordHandler('wlan-ssid',{ 'id' : {'$in':tIds}});
            return (tArr && tArr.length ? tArr : []);
        } else {
            var tSsids = SimLokiDatabaseActions.getAll('wlan-ssid');
            return tSsids && tSsids.length ? tSsids : [];
        }
    }

    function scheduleJob(type, urlAction){
        var childJobs=[];
        var manipulatedStartTime = UtilityFunctions.getTimeStamp() - UtilityFunctions.getMinuteToMilliSeconds(2);
        var  resultObj = SimTaskDataGenerator.createTask('SPFService', {'currentDelayCount':0,'maxDelayNoOfCalls':2});
        if(typeof(urlAction.restPayload)=="string") {
            urlAction.restPayload = JSON.parse(urlAction.restPayload);
        }
        for(var i=0; i<urlAction.restPayload.length; i++) {
            var childTaskObj = SimTaskDataGenerator.createChildTask('SPFService',  {'currentDelayCount':0,'maxDelayNoOfCalls':2});
            var jsonObj=urlAction.restPayload[i].externalSchedule.notificationBody[0];
            SimDataGenerationInit.cfsCUDoperation(type, 'put', jsonObj);
            SimTaskDataGenerator.updateTaskCompletion(childTaskObj.taskId, {isError: false });
            childTaskObj.statusCode= 202;
            var id=UtilityFunctions.generate_uuid();
            childTaskObj["id"]= id;
            childTaskObj["triggeredJobTaskId"]= childTaskObj.taskId;
            childTaskObj["triggeredTime"]=manipulatedStartTime + UtilityFunctions.getMinuteToMilliSeconds(8);
            childTaskObj["status"]= "COMPLETED";
            childTaskObj["triggeredJobId"]= id;
            childJobs.push(childTaskObj);
         }
         SimTaskDataGenerator.updateTaskCompletion(resultObj.taskId, {isError: false });
        var tJson = JSON.parse(JSON.stringify(ScheduledJobTemplate.Scheduled_Job_Template));

        tJson[0].createTime = manipulatedStartTime

        tJson[0].externalSchedule= urlAction.restPayload[0].externalSchedule;
        tJson[0].timeZone = urlAction.restPayload[0].timeZone
        tJson[0].startTime = urlAction.restPayload[0].startTime
        tJson[0].description = urlAction.restPayload[0].description
        tJson[0].taskId = resultObj.taskId
        tJson[0].id = UtilityFunctions.generate_uuid();
        tJson[0].scheduledId=tJson[0].id;
        tJson[0].lastUpdateTime = manipulatedStartTime + UtilityFunctions.getMinuteToMilliSeconds(8);
        tJson[0].scheduledDevices=[];
        tJson[0].triggeredJobs=childJobs;
        tJson[0]["paramNamesAndValues"]={
            "deviceDetail": "{\"type\":\"authenticationTemplate\",\"changeConfig\":[]}",
            "subModule": "FABRIC"
          };
          resultObj["id"]=resultObj.taskId;
        SimLokiDatabaseActions.insert('scheduled-job', tJson);
         return resultObj;
    }


});
