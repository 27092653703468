
//  CAT3K_CAA Cisco Catalyst 3650-24PD-E Switch
define({
    "runningImageList":[  
    {  
       "name":"Install Mode (03.06.05E)",
       "imageUuid":"07076e3d-4ed7-47d4-9b63-6c6dbf4785fe",
       "version":"03.06.05E",
       "softwareType":null,
       "imageType":"RELEASE",
       "isTaggedGolden":"false",
       "md5Checksum":null,
       "shaCheckSum":null,
       "createdTime":null,
       "applicationType":null,
       "feature":null,
       "fileServiceId":null,
       "imageSeries":null,
       "imageSource":null,
       "imageSourceType":null,
       "imageFamily":"CAT3K_CAA",
       "vendor":null,
       "filesize":0,
       "deviceCount":0,
       "deviceUuidList":[  
       ],
       "smuCount":0,
       "imageAttributes":[  

       ],
       "isSMUApplicable":false,
       "goldenTagInheritedFromSiteUuid":null,
       "goldenTagInheritedFromSiteName":null,
       "importSupported":false,
       "reasonForNotSupportingImport":"Import from devices in install mode is not supported.",
       "tagList":[  
          {  
             "taggedGolden":false,
             "role":"ALL",
             "inheritedSiteName":null,
             "inheritedSiteUuid":null
          },
          {  
             "taggedGolden":false,
             "role":"CORE",
             "inheritedSiteName":null,
             "inheritedSiteUuid":null
          },
          {  
             "taggedGolden":false,
             "role":"DISTRIBUTION",
             "inheritedSiteName":null,
             "inheritedSiteUuid":null
          },
          {  
             "taggedGolden":false,
             "role":"BORDER ROUTER",
             "inheritedSiteName":null,
             "inheritedSiteUuid":null
          },
          {  
             "taggedGolden":false,
             "role":"UNKNOWN",
             "inheritedSiteName":null,
             "inheritedSiteUuid":null
          },
          {  
             "taggedGolden":false,
             "role":"ACCESS",
             "inheritedSiteName":null,
             "inheritedSiteUuid":null
          }
       ],
       "profileInfo": null,
        "physicallyAvailable": true,
        "assignCount": 0,
        "showUnassign": false,
        "disableUnassign": false,
       "imageCategory":"PHYSICAL",
       "imageIntegrityStatus":null,
       "ccorecommended":false,
       "ccolatest":false
    },
    {  
       "name":"Install Mode (03.06.06E)",
       "imageUuid":"2388ee1b-f145-4d4e-93ab-f092eeea0aa0",
       "version":"03.06.06E",
       "softwareType":null,
       "imageType":"RELEASE",
       "isTaggedGolden":"false",
       "md5Checksum":null,
       "shaCheckSum":null,
       "createdTime":null,
       "applicationType":null,
       "feature":null,
       "fileServiceId":null,
       "imageSeries":null,
       "imageSource":null,
       "imageSourceType":null,
       "imageFamily":"CAT3K_CAA",
       "vendor":null,
       "filesize":0,
       "deviceCount":0,
       "deviceUuidList":[  

       ],
       "smuCount":0,
       "imageAttributes":[  

       ],
       "isSMUApplicable":false,
       "goldenTagInheritedFromSiteUuid":null,
       "goldenTagInheritedFromSiteName":null,
       "importSupported":false,
       "reasonForNotSupportingImport":"Import from devices in install mode is not supported.",
       "tagList":[  
          {  
             "taggedGolden":false,
             "role":"ALL",
             "inheritedSiteName":null,
             "inheritedSiteUuid":null
          },
          {  
             "taggedGolden":false,
             "role":"CORE",
             "inheritedSiteName":null,
             "inheritedSiteUuid":null
          },
          {  
             "taggedGolden":false,
             "role":"DISTRIBUTION",
             "inheritedSiteName":null,
             "inheritedSiteUuid":null
          },
          {  
             "taggedGolden":false,
             "role":"BORDER ROUTER",
             "inheritedSiteName":null,
             "inheritedSiteUuid":null
          },
          {  
             "taggedGolden":false,
             "role":"UNKNOWN",
             "inheritedSiteName":null,
             "inheritedSiteUuid":null
          },
          {  
             "taggedGolden":false,
             "role":"ACCESS",
             "inheritedSiteName":null,
             "inheritedSiteUuid":null
          }
       ],
       "profileInfo": null,
        "physicallyAvailable": true,
        "assignCount": 0,
        "showUnassign": false,
        "disableUnassign": false,
       "imageCategory":"PHYSICAL",
       "imageIntegrityStatus":null,
       "ccorecommended":false,
       "ccolatest":false
    },
    {  
       "name":"Install Mode (16.6.2)",
       "imageUuid":"65fec3d4-650c-4403-87c5-25eb4404349e",
       "version":"16.6.2",
       "softwareType":null,
       "imageType":"RELEASE",
       "isTaggedGolden":"false",
       "md5Checksum":null,
       "shaCheckSum":null,
       "createdTime":null,
       "applicationType":null,
       "feature":null,
       "fileServiceId":null,
       "imageSeries":null,
       "imageSource":null,
       "imageSourceType":null,
       "imageFamily":"CAT3K_CAA",
       "vendor":null,
       "filesize":0,
       "deviceCount":0,
       "deviceUuidList":[  

       ],
       "smuCount":0,
       "imageAttributes":[  

       ],
       "isSMUApplicable":false,
       "goldenTagInheritedFromSiteUuid":null,
       "goldenTagInheritedFromSiteName":null,
       "importSupported":false,
       "reasonForNotSupportingImport":"Import from devices in install mode is not supported.",
       "tagList":[  
          {  
             "taggedGolden":false,
             "role":"ALL",
             "inheritedSiteName":null,
             "inheritedSiteUuid":null
          },
          {  
             "taggedGolden":false,
             "role":"CORE",
             "inheritedSiteName":null,
             "inheritedSiteUuid":null
          },
          {  
             "taggedGolden":false,
             "role":"DISTRIBUTION",
             "inheritedSiteName":null,
             "inheritedSiteUuid":null
          },
          {  
             "taggedGolden":false,
             "role":"BORDER ROUTER",
             "inheritedSiteName":null,
             "inheritedSiteUuid":null
          },
          {  
             "taggedGolden":false,
             "role":"UNKNOWN",
             "inheritedSiteName":null,
             "inheritedSiteUuid":null
          },
          {  
             "taggedGolden":false,
             "role":"ACCESS",
             "inheritedSiteName":null,
             "inheritedSiteUuid":null
          }
       ],
       "profileInfo": null,
        "physicallyAvailable": true,
        "assignCount": 0,
        "showUnassign": false,
        "disableUnassign": false,
       "imageCategory":"PHYSICAL",
       "imageIntegrityStatus":null,
       "ccorecommended":false,
       "ccolatest":false
    },
    {  
       "name":"Install Mode (16.6.1)",
       "imageUuid":"bda90ee9-99e6-431d-9add-425debb6ae29",
       "version":"16.6.1",
       "softwareType":null,
       "imageType":"RELEASE",
       "isTaggedGolden":"false",
       "md5Checksum":null,
       "shaCheckSum":null,
       "createdTime":null,
       "applicationType":null,
       "feature":null,
       "fileServiceId":null,
       "imageSeries":null,
       "imageSource":null,
       "imageSourceType":null,
       "imageFamily":"CAT3K_CAA",
       "vendor":null,
       "filesize":0,
       "deviceCount":0,
       "deviceUuidList":[  

       ],
       "smuCount":0,
       "imageAttributes":[  

       ],
       "isSMUApplicable":false,
       "goldenTagInheritedFromSiteUuid":null,
       "goldenTagInheritedFromSiteName":null,
       "importSupported":false,
       "reasonForNotSupportingImport":"Import from devices in install mode is not supported.",
       "tagList":[  
          {  
             "taggedGolden":false,
             "role":"ALL",
             "inheritedSiteName":null,
             "inheritedSiteUuid":null
          },
          {  
             "taggedGolden":false,
             "role":"CORE",
             "inheritedSiteName":null,
             "inheritedSiteUuid":null
          },
          {  
             "taggedGolden":false,
             "role":"DISTRIBUTION",
             "inheritedSiteName":null,
             "inheritedSiteUuid":null
          },
          {  
             "taggedGolden":false,
             "role":"BORDER ROUTER",
             "inheritedSiteName":null,
             "inheritedSiteUuid":null
          },
          {  
             "taggedGolden":false,
             "role":"UNKNOWN",
             "inheritedSiteName":null,
             "inheritedSiteUuid":null
          },
          {  
             "taggedGolden":false,
             "role":"ACCESS",
             "inheritedSiteName":null,
             "inheritedSiteUuid":null
          }
       ],
       "profileInfo": null,
        "physicallyAvailable": true,
        "assignCount": 0,
        "showUnassign": false,
        "disableUnassign": false,
       "imageCategory":"PHYSICAL",
       "imageIntegrityStatus":null,
       "ccorecommended":false,
       "ccolatest":false
    },
    {  
       "name":"Install Mode (16.3.3)",
       "imageUuid":"ebe29899-0942-4ceb-9750-e68cda2edf1a",
       "version":"16.3.3",
       "softwareType":null,
       "imageType":"SYSTEM_SW",
       "isTaggedGolden":"false",
       "md5Checksum":null,
       "shaCheckSum":null,
       "createdTime":null,
       "applicationType":null,
       "feature":null,
       "fileServiceId":null,
       "imageSeries":null,
       "imageSource":null,
       "imageSourceType":null,
       "imageFamily":"CAT3K_CAA",
       "vendor":null,
       "filesize":0,
       "deviceCount":0,
       "deviceUuidList":[  

       ],
       "smuCount":0,
       "imageAttributes":[  

       ],
       "isSMUApplicable":false,
       "goldenTagInheritedFromSiteUuid":null,
       "goldenTagInheritedFromSiteName":null,
       "importSupported":false,
       "reasonForNotSupportingImport":"Import from devices in install mode is not supported.",
       "tagList":[  
          {  
             "taggedGolden":false,
             "role":"ALL",
             "inheritedSiteName":null,
             "inheritedSiteUuid":null
          },
          {  
             "taggedGolden":false,
             "role":"CORE",
             "inheritedSiteName":null,
             "inheritedSiteUuid":null
          },
          {  
             "taggedGolden":false,
             "role":"DISTRIBUTION",
             "inheritedSiteName":null,
             "inheritedSiteUuid":null
          },
          {  
             "taggedGolden":false,
             "role":"BORDER ROUTER",
             "inheritedSiteName":null,
             "inheritedSiteUuid":null
          },
          {  
             "taggedGolden":false,
             "role":"UNKNOWN",
             "inheritedSiteName":null,
             "inheritedSiteUuid":null
          },
          {  
             "taggedGolden":false,
             "role":"ACCESS",
             "inheritedSiteName":null,
             "inheritedSiteUuid":null
          }
       ],
       "profileInfo": null,
        "physicallyAvailable": true,
        "assignCount": 0,
        "showUnassign": false,
        "disableUnassign": false,
       "imageCategory":"PHYSICAL",
       "imageIntegrityStatus":null,
       "ccorecommended":false,
       "ccolatest":false
    }
 ]
});