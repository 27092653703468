//similar to ApplicationTypeTemplate.js. But some format has changed
define({
  "VIRTUAL_IMAGES": [
      {
          "deviceFamily": "FORTINET",
          "deviceTypeOrdinal": -100,
          "softwareType": null,
          "runningImageList": [
              {
                  "name": "fortios5_4.tar.gz",
                  "imageUuid": "977cdfef-a009-4d19-b8ef-7b57db2cbd59",
                  "version": "5.4b9317",
                  "softwareType": null,
                  "imageType": "QCOW_SW",
                  "isTaggedGolden": "true",
                  "md5Checksum": null,
                  "shaCheckSum": "92ee65f1c8d011a06b2a8ec79c05db31128823d882f5744c6b13a6463137ed6b3a33580a627a9027691d2f00d582457c9a77d7962afc9b4123625140d25f6eab",
                  "createdTime": "2018-05-29 18:58:56.0",
                  "applicationType": "FIREWALL",
                  "feature": null,
                  "fileServiceId": "f1ca845b-fced-4eff-8d89-80e79673a223",
                  "imageSeries": null,
                  "imageSource": "http://192.168.114.107/thirdparty/fortios5_4.tar.gz",
                  "imageSourceType": "Imported from Remote server URL",
                  "imageFamily": "FORTINET",
                  "vendor": "FORTINET",
                  "filesize": 36518293,
                  "deviceCount": 0,
                  "deviceUuidList": [],
                  "smuCount": 0,
                  "imageAttributes": [
                      {
                          "propertyName": "DEFAULT_BOOTROM",
                          "propertyValue": "0"
                      },
                      {
                          "propertyName": "LOW_LATENCY",
                          "propertyValue": "false"
                      },
                      {
                          "propertyName": "MD5_CHECKSUM",
                          "propertyValue": "UNKNOWN"
                      },
                      {
                          "propertyName": "DEFAULT_RAM",
                          "propertyValue": "0"
                      },
                      {
                          "propertyName": "IMAGENAME",
                          "propertyValue": "fortios5_4"
                      },
                      {
                          "propertyName": "NAME",
                          "propertyValue": "fortios5_4.tar.gz"
                      },
                      {
                          "propertyName": "VERSION",
                          "propertyValue": "5.4b9317"
                      },
                      {
                          "propertyName": "DEFAULT_MINFLASHSIZE",
                          "propertyValue": "0"
                      },
                      {
                          "propertyName": "MINDISKSIZE",
                          "propertyValue": "0"
                      },
                      {
                          "propertyName": "IMAGETYPE_VALUE",
                          "propertyValue": "TGZ"
                      }
                  ],
                  "isSMUApplicable": false,
                  "goldenTagInheritedFromSiteUuid": null,
                  "goldenTagInheritedFromSiteName": null,
                  "importSupported": true,
                  "reasonForNotSupportingImport": null,
                  "tagList": [],
                  "imageCategory": "VIRTUAL",
                  "imageIntegrityStatus": "VERIFIED",
                  "imageImported": true,
                  "applicableDevicesForImage": null,
                  "profileInfo": [
                      {
                          "profileName": "fortinet-small",
                          "memory": 1024,
                          "vCpu": 1,
                          "shares": 30720,
                          "description": "small",
                          "productType": null,
                          "extendedAttributes": null
                      }
                  ],
                  "physicallyAvailable": true,
                  "assignCount": 0,
                  "showUnassign": false,
                  "disableUnassign": false,
                  "ccorecommended": false,
                  "ccolatest": false,
                  "ccoreverseSync": false
              }
          ],
          "deviceAvailable": false
      },
      {
          "deviceFamily": "PALOALTO",
          "deviceTypeOrdinal": -100,
          "softwareType": null,
          "runningImageList": [
              {
                  "name": "pafwUnMon.tar.gz",
                  "imageUuid": "a12328b5-f130-4df9-83ea-601b234bf60e",
                  "version": "7.1.4",
                  "softwareType": null,
                  "imageType": "QCOW_SW",
                  "isTaggedGolden": "true",
                  "md5Checksum": null,
                  "shaCheckSum": "7822d8831dca4e047042bebff1196c0040939f21cc387c643134c4ab462e27dacb3c4f37ebdeed4a058aebcfa5ab810911c73868a8ec923c4c565b05097fb8af",
                  "createdTime": "2018-05-29 19:10:59.0",
                  "applicationType": "FIREWALL",
                  "feature": null,
                  "fileServiceId": "218b57a9-7e71-4ac7-8329-de1825d572ba",
                  "imageSeries": null,
                  "imageSource": "http://192.168.114.107/thirdparty/pafwUnMon.tar.gz",
                  "imageSourceType": "Imported from Remote server URL",
                  "imageFamily": "PALOALTO",
                  "vendor": "PALOALTO",
                  "filesize": 2118201297,
                  "deviceCount": 0,
                  "deviceUuidList": [],
                  "smuCount": 0,
                  "imageAttributes": [
                      {
                          "propertyName": "DEFAULT_BOOTROM",
                          "propertyValue": "0"
                      },
                      {
                          "propertyName": "MD5_CHECKSUM",
                          "propertyValue": "UNKNOWN"
                      },
                      {
                          "propertyName": "DEFAULT_RAM",
                          "propertyValue": "0"
                      },
                      {
                          "propertyName": "IMAGENAME",
                          "propertyValue": "pafwUnMon"
                      },
                      {
                          "propertyName": "NAME",
                          "propertyValue": "pafwUnMon.tar.gz"
                      },
                      {
                          "propertyName": "DEFAULT_MINFLASHSIZE",
                          "propertyValue": "0"
                      },
                      {
                          "propertyName": "MINDISKSIZE",
                          "propertyValue": "0"
                      },
                      {
                          "propertyName": "LOW_LATENCY",
                          "propertyValue": "true"
                      },
                      {
                          "propertyName": "VERSION",
                          "propertyValue": "7.1.4"
                      },
                      {
                          "propertyName": "IMAGETYPE_VALUE",
                          "propertyValue": "TGZ"
                      }
                  ],
                  "isSMUApplicable": false,
                  "goldenTagInheritedFromSiteUuid": null,
                  "goldenTagInheritedFromSiteName": null,
                  "importSupported": true,
                  "reasonForNotSupportingImport": null,
                  "tagList": [],
                  "imageCategory": "VIRTUAL",
                  "imageIntegrityStatus": "VERIFIED",
                  "imageImported": true,
                  "applicableDevicesForImage": null,
                  "profileInfo": [
                      {
                          "profileName": "pa-small",
                          "memory": 4096,
                          "vCpu": 2,
                          "shares": 8192,
                          "description": "pa small  profile",
                          "productType": null,
                          "extendedAttributes": null
                      }
                  ],
                  "physicallyAvailable": true,
                  "assignCount": 0,
                  "showUnassign": false,
                  "disableUnassign": false,
                  "ccorecommended": false,
                  "ccolatest": false,
                  "ccoreverseSync": false
              }
          ],
          "deviceAvailable": false
      },
      {
          "deviceFamily": "WAAS",
          "deviceTypeOrdinal": -100,
          "softwareType": null,
          "runningImageList": [
              {
                  "name": "Cisco-KVM-vWAAS-Unified-6.4.3-b-171.tar.gz",
                  "imageUuid": "29cc4634-f469-4304-9c2e-a0effcf0614b",
                  "version": "6.4.3-b-171",
                  "softwareType": null,
                  "imageType": "QCOW_SW",
                  "isTaggedGolden": "true",
                  "md5Checksum": null,
                  "shaCheckSum": "51692f00db2b07ff8ea69dd6f4192ea44c3f5fe6ac705c59944cb986836b9b15d263b8733a7f88174f07bcfbc1bc423b9890cd08e14fb21aa70636e0a4507868",
                  "createdTime": "2018-12-18 23:00:35.0",
                  "applicationType": "WAAS",
                  "feature": null,
                  "fileServiceId": "63b930b2-254d-4a49-911d-8361aee45b35",
                  "imageSeries": null,
                  "imageSource": "http://192.168.114.107/Kent/Cisco-KVM-vWAAS-Unified-6.4.3-b-171.tar.gz",
                  "imageSourceType": "Imported from Remote server URL",
                  "imageFamily": "WAAS",
                  "vendor": "CISCO",
                  "filesize": 634322116,
                  "deviceCount": 0,
                  "deviceUuidList": [],
                  "smuCount": 0,
                  "imageAttributes": [
                      {
                          "propertyName": "DEFAULT_BOOTROM",
                          "propertyValue": "0"
                      },
                      {
                          "propertyName": "VERSION",
                          "propertyValue": "6.4.3-b-171"
                      },
                      {
                          "propertyName": "MD5_CHECKSUM",
                          "propertyValue": "UNKNOWN"
                      },
                      {
                          "propertyName": "DEFAULT_RAM",
                          "propertyValue": "0"
                      },
                      {
                          "propertyName": "IMAGENAME",
                          "propertyValue": "Cisco-KVM-vWAAS-Unified-6.4.3-b-171"
                      },
                      {
                          "propertyName": "NAME",
                          "propertyValue": "Cisco-KVM-vWAAS-Unified-6.4.3-b-171.tar.gz"
                      },
                      {
                          "propertyName": "DEFAULT_MINFLASHSIZE",
                          "propertyValue": "0"
                      },
                      {
                          "propertyName": "MINDISKSIZE",
                          "propertyValue": "0"
                      },
                      {
                          "propertyName": "LOW_LATENCY",
                          "propertyValue": "true"
                      },
                      {
                          "propertyName": "IMAGETYPE_VALUE",
                          "propertyValue": "TGZ"
                      }
                  ],
                  "isSMUApplicable": false,
                  "goldenTagInheritedFromSiteUuid": null,
                  "goldenTagInheritedFromSiteName": null,
                  "importSupported": true,
                  "reasonForNotSupportingImport": null,
                  "tagList": [],
                  "imageCategory": "VIRTUAL",
                  "imageIntegrityStatus": "VERIFIED",
                  "imageImported": true,
                  "applicableDevicesForImage": null,
                  "profileInfo": [
                      {
                          "profileName": "vCM-500",
                          "memory": 4096,
                          "vCpu": 2,
                          "shares": 307200,
                          "description": "vCM-500 profile",
                          "productType": null,
                          "extendedAttributes": null
                      },
                      {
                          "profileName": "vWAAS-150-Resized",
                          "memory": 4096,
                          "vCpu": 2,
                          "shares": 163840,
                          "description": "vWAAS-150 resized profile",
                          "productType": null,
                          "extendedAttributes": null
                      },
                      {
                          "profileName": "vWAAS-2500-Resized",
                          "memory": 16384,
                          "vCpu": 6,
                          "shares": 768000,
                          "description": "vWAAS-2500 resized profile",
                          "productType": null,
                          "extendedAttributes": null
                      },
                      {
                          "profileName": "vWAAS-6000R-Original",
                          "memory": 11264,
                          "vCpu": 4,
                          "shares": 870400,
                          "description": "vWAAS-6000R original profile",
                          "productType": null,
                          "extendedAttributes": null
                      },
                      {
                          "profileName": "vWAAS-200-Original",
                          "memory": 4096,
                          "vCpu": 1,
                          "shares": 266240,
                          "description": "vWAAS-200 original profile",
                          "productType": null,
                          "extendedAttributes": null
                      },
                      {
                          "profileName": "vWAAS-200-Resized",
                          "memory": 4096,
                          "vCpu": 2,
                          "shares": 266240,
                          "description": "vWAAS-200 resized profile",
                          "productType": null,
                          "extendedAttributes": null
                      },
                      {
                          "profileName": "vWAAS-750-Resized",
                          "memory": 8192,
                          "vCpu": 4,
                          "shares": 512000,
                          "description": "vWAAS-750 resized profile",
                          "productType": null,
                          "extendedAttributes": null
                      },
                      {
                          "profileName": "vWAAS-6000R-Resized",
                          "memory": 24576,
                          "vCpu": 8,
                          "shares": 870400,
                          "description": "vWAAS-6000R resized profile",
                          "productType": null,
                          "extendedAttributes": null
                      },
                      {
                          "profileName": "vWAAS-6000-Original",
                          "memory": 11264,
                          "vCpu": 4,
                          "shares": 921600,
                          "description": "vWAAS-6000 original profile",
                          "productType": null,
                          "extendedAttributes": null
                      },
                      {
                          "profileName": "vCM-2000",
                          "memory": 8192,
                          "vCpu": 4,
                          "shares": 614400,
                          "description": "vCM-2000 profile",
                          "productType": null,
                          "extendedAttributes": null
                      },
                      {
                          "profileName": "vWAAS-12000-Original",
                          "memory": 12288,
                          "vCpu": 4,
                          "shares": 768000,
                          "description": "vWAAS-12000 original profile",
                          "productType": null,
                          "extendedAttributes": null
                      },
                      {
                          "profileName": "vWAAS-1300-Original",
                          "memory": 6144,
                          "vCpu": 2,
                          "shares": 614400,
                          "description": "vWAAS-1300 original profile",
                          "productType": null,
                          "extendedAttributes": null
                      },
                      {
                          "profileName": "vWAAS-50000-Resized",
                          "memory": 73728,
                          "vCpu": 16,
                          "shares": 1536000,
                          "description": "vWAAS-50000 resized profile",
                          "productType": null,
                          "extendedAttributes": null
                      },
                      {
                          "profileName": "vCM-1000",
                          "memory": 4096,
                          "vCpu": 2,
                          "shares": 409600,
                          "description": "vCM-1000 profile",
                          "productType": null,
                          "extendedAttributes": null
                      },
                      {
                          "profileName": "vWAAS-12000-Resized",
                          "memory": 49152,
                          "vCpu": 12,
                          "shares": 768000,
                          "description": "vWAAS-12000 resized profile",
                          "productType": null,
                          "extendedAttributes": null
                      },
                      {
                          "profileName": "vWAAS-6000-Resized",
                          "memory": 24576,
                          "vCpu": 8,
                          "shares": 921600,
                          "description": "vWAAS-6000 resized profile",
                          "productType": null,
                          "extendedAttributes": null
                      },
                      {
                          "profileName": "vWAAS-150-Original",
                          "memory": 4096,
                          "vCpu": 1,
                          "shares": 163840,
                          "description": "vWAAS-150 original profile",
                          "productType": null,
                          "extendedAttributes": null
                      },
                      {
                          "profileName": "vWAAS-2500-Original",
                          "memory": 8192,
                          "vCpu": 4,
                          "shares": 768000,
                          "description": "vWAAS-2500 original profile",
                          "productType": null,
                          "extendedAttributes": null
                      },
                      {
                          "profileName": "vWAAS-1300-Resized",
                          "memory": 12288,
                          "vCpu": 4,
                          "shares": 614400,
                          "description": "vWAAS-1300 resized profile",
                          "productType": null,
                          "extendedAttributes": null
                      },
                      {
                          "profileName": "vWAAS-750-Original",
                          "memory": 4096,
                          "vCpu": 2,
                          "shares": 512000,
                          "description": "vWAAS-750 original profile",
                          "productType": null,
                          "extendedAttributes": null
                      },
                      {
                          "profileName": "vCM-100",
                          "memory": 4096,
                          "vCpu": 2,
                          "shares": 256000,
                          "description": "vCM-100 profile",
                          "productType": null,
                          "extendedAttributes": null
                      },
                      {
                          "profileName": "vWAAS-50000-Original",
                          "memory": 49152,
                          "vCpu": 8,
                          "shares": 1536000,
                          "description": "vWAAS-50000 Original profile",
                          "productType": null,
                          "extendedAttributes": null
                      }
                  ],
                  "physicallyAvailable": true,
                  "assignCount": 0,
                  "showUnassign": false,
                  "disableUnassign": false,
                  "ccorecommended": false,
                  "ccolatest": false,
                  "ccoreverseSync": false
              },
              {
                  "name": "Cisco-KVM-vWAAS-Unified-6.4.1a-b-6.tar.gz",
                  "imageUuid": "b00c97b2-2755-4575-9ba3-6c31e9a1453d",
                  "version": "6.4.1a-b-6",
                  "softwareType": null,
                  "imageType": "QCOW_SW",
                  "isTaggedGolden": "false",
                  "md5Checksum": null,
                  "shaCheckSum": "b722373b2afa3b677dcc3968c84344a6abf6b095e080940dd3d43406ad3ef1b8041e1c63554596f924b6dbe0196d33c0246bb6e882bf7aab3b1436e5e86d7900",
                  "createdTime": "2018-05-29 18:55:26.0",
                  "applicationType": "WAAS",
                  "feature": null,
                  "fileServiceId": "5e96b630-3839-4602-a2b6-06e3fda2bbd0",
                  "imageSeries": null,
                  "imageSource": "http://192.168.114.107/vnfImages/NEW/Cisco-KVM-vWAAS-Unified-6.4.1a-b-6.tar.gz",
                  "imageSourceType": "Imported from Remote server URL",
                  "imageFamily": "WAAS",
                  "vendor": "CISCO",
                  "filesize": 586020440,
                  "deviceCount": 0,
                  "deviceUuidList": [],
                  "smuCount": 0,
                  "imageAttributes": [
                      {
                          "propertyName": "DEFAULT_BOOTROM",
                          "propertyValue": "0"
                      },
                      {
                          "propertyName": "MD5_CHECKSUM",
                          "propertyValue": "UNKNOWN"
                      },
                      {
                          "propertyName": "NAME",
                          "propertyValue": "Cisco-KVM-vWAAS-Unified-6.4.1a-b-6.tar.gz"
                      },
                      {
                          "propertyName": "DEFAULT_RAM",
                          "propertyValue": "0"
                      },
                      {
                          "propertyName": "IMAGENAME",
                          "propertyValue": "Cisco-KVM-vWAAS-Unified-6.4.1a-b-6"
                      },
                      {
                          "propertyName": "VERSION",
                          "propertyValue": "6.4.1a-b-6"
                      },
                      {
                          "propertyName": "DEFAULT_MINFLASHSIZE",
                          "propertyValue": "0"
                      },
                      {
                          "propertyName": "MINDISKSIZE",
                          "propertyValue": "0"
                      },
                      {
                          "propertyName": "LOW_LATENCY",
                          "propertyValue": "true"
                      },
                      {
                          "propertyName": "IMAGETYPE_VALUE",
                          "propertyValue": "TGZ"
                      }
                  ],
                  "isSMUApplicable": false,
                  "goldenTagInheritedFromSiteUuid": null,
                  "goldenTagInheritedFromSiteName": null,
                  "importSupported": true,
                  "reasonForNotSupportingImport": null,
                  "tagList": [],
                  "imageCategory": "VIRTUAL",
                  "imageIntegrityStatus": "VERIFIED",
                  "imageImported": true,
                  "applicableDevicesForImage": null,
                  "profileInfo": [
                      {
                          "profileName": "vWAAS-6000R",
                          "memory": 24576,
                          "vCpu": 8,
                          "shares": 870400,
                          "description": "vWAAS-6000R profile",
                          "productType": null,
                          "extendedAttributes": null
                      },
                      {
                          "profileName": "vWAAS-150",
                          "memory": 4096,
                          "vCpu": 2,
                          "shares": 163840,
                          "description": "vWAAS-150 profile",
                          "productType": null,
                          "extendedAttributes": null
                      },
                      {
                          "profileName": "vWAAS-750",
                          "memory": 8192,
                          "vCpu": 4,
                          "shares": 512000,
                          "description": "vWAAS-750 profile",
                          "productType": null,
                          "extendedAttributes": {
                              "sessions": "750"
                          }
                      },
                      {
                          "profileName": "vWAAS-2500",
                          "memory": 16384,
                          "vCpu": 6,
                          "shares": 768000,
                          "description": "vWAAS-2500 profile",
                          "productType": null,
                          "extendedAttributes": {
                              "sessions": "2500"
                          }
                      },
                      {
                          "profileName": "vWAAS-6000",
                          "memory": 24576,
                          "vCpu": 8,
                          "shares": 921600,
                          "description": "vWAAS-6000 profile",
                          "productType": null,
                          "extendedAttributes": {
                              "sessions": "6000"
                          }
                      },
                      {
                          "profileName": "vWAAS-200",
                          "memory": 4096,
                          "vCpu": 2,
                          "shares": 266240,
                          "description": "vWAAS-200 profile",
                          "productType": null,
                          "extendedAttributes": {
                              "sessions": "200"
                          }
                      },
                      {
                          "profileName": "vWAAS-1300",
                          "memory": 12288,
                          "vCpu": 4,
                          "shares": 614400,
                          "description": "vWAAS-1300 profile",
                          "productType": null,
                          "extendedAttributes": {
                              "sessions": "1300"
                          }
                      }
                  ],
                  "physicallyAvailable": true,
                  "assignCount": 0,
                  "showUnassign": false,
                  "disableUnassign": false,
                  "ccorecommended": false,
                  "ccolatest": false,
                  "ccoreverseSync": false
              }
          ],
          "deviceAvailable": false
      },
      {
          "deviceFamily": "LINUX",
          "deviceTypeOrdinal": -100,
          "softwareType": null,
          "runningImageList": [
              {
                  "name": "redhat7.tar.gz",
                  "imageUuid": "60115c3f-97fe-4941-a3ca-759e55f8c228",
                  "version": "rhel7-server",
                  "softwareType": null,
                  "imageType": "QCOW_SW",
                  "isTaggedGolden": "true",
                  "md5Checksum": null,
                  "shaCheckSum": "59a543b17a53bd961fad5b220705d27ac531455f4361206c73c2926c374ed232d58e69856a2d9e818b68ef7c31ad8632a6e736522bf8429b42f61b51a55513f6",
                  "createdTime": "2018-05-29 18:47:21.0",
                  "applicationType": "LINUX",
                  "feature": null,
                  "fileServiceId": "880a38d3-b435-4f2d-96ce-b122b25990bb",
                  "imageSeries": null,
                  "imageSource": "http://192.168.114.107/vnfImages/NEW/redhat7.tar.gz",
                  "imageSourceType": "Imported from Remote server URL",
                  "imageFamily": "LINUX",
                  "vendor": "LINUX",
                  "filesize": 1456156247,
                  "deviceCount": 0,
                  "deviceUuidList": [],
                  "smuCount": 0,
                  "imageAttributes": [
                      {
                          "propertyName": "DEFAULT_BOOTROM",
                          "propertyValue": "0"
                      },
                      {
                          "propertyName": "IMAGENAME",
                          "propertyValue": "redhat7"
                      },
                      {
                          "propertyName": "MD5_CHECKSUM",
                          "propertyValue": "UNKNOWN"
                      },
                      {
                          "propertyName": "DEFAULT_RAM",
                          "propertyValue": "0"
                      },
                      {
                          "propertyName": "NAME",
                          "propertyValue": "redhat7.tar.gz"
                      },
                      {
                          "propertyName": "VERSION",
                          "propertyValue": "rhel7-server"
                      },
                      {
                          "propertyName": "DEFAULT_MINFLASHSIZE",
                          "propertyValue": "0"
                      },
                      {
                          "propertyName": "MINDISKSIZE",
                          "propertyValue": "0"
                      },
                      {
                          "propertyName": "IMAGETYPE_VALUE",
                          "propertyValue": "TGZ"
                      }
                  ],
                  "isSMUApplicable": false,
                  "goldenTagInheritedFromSiteUuid": null,
                  "goldenTagInheritedFromSiteName": null,
                  "importSupported": true,
                  "reasonForNotSupportingImport": null,
                  "tagList": [],
                  "imageCategory": "VIRTUAL",
                  "imageIntegrityStatus": "VERIFIED",
                  "imageImported": true,
                  "applicableDevicesForImage": null,
                  "profileInfo": [
                      {
                          "profileName": "rhel7-medium",
                          "memory": 2048,
                          "vCpu": 1,
                          "shares": 4096,
                          "description": "rhel7 medium profile",
                          "productType": null,
                          "extendedAttributes": null
                      }
                  ],
                  "physicallyAvailable": true,
                  "assignCount": 0,
                  "showUnassign": false,
                  "disableUnassign": false,
                  "ccorecommended": false,
                  "ccolatest": false,
                  "ccoreverseSync": false
              }
          ],
          "deviceAvailable": false
      },
      {
          "deviceFamily": "WINDOWS",
          "deviceTypeOrdinal": -100,
          "softwareType": null,
          "runningImageList": [
              {
                  "name": "win2k.tar.gz",
                  "imageUuid": "39392f29-fb5d-4f6c-bb1f-484812e503f6",
                  "version": "2012-R2",
                  "softwareType": null,
                  "imageType": "QCOW_SW",
                  "isTaggedGolden": "true",
                  "md5Checksum": null,
                  "shaCheckSum": "c9d8a1cf69c0220d973c687813c50ebf7ba3ff56c5077a6fbaef2a8a5f232b54a7320cb5953c87112815790fb89c345f16f6fcaebb89309df856e667adecdbee",
                  "createdTime": "2018-05-29 19:19:10.0",
                  "applicationType": "WINDOWS",
                  "feature": null,
                  "fileServiceId": "bd4ced8d-f58e-4b45-937f-3cb64e1d2cd5",
                  "imageSeries": null,
                  "imageSource": "http://192.168.114.107/vnfImages/NEW/win2k.tar.gz",
                  "imageSourceType": "Imported from Remote server URL",
                  "imageFamily": "WINDOWS",
                  "vendor": "WINDOWS",
                  "filesize": 5369621287,
                  "deviceCount": 0,
                  "deviceUuidList": [],
                  "smuCount": 0,
                  "imageAttributes": [
                      {
                          "propertyName": "DEFAULT_BOOTROM",
                          "propertyValue": "0"
                      },
                      {
                          "propertyName": "MD5_CHECKSUM",
                          "propertyValue": "UNKNOWN"
                      },
                      {
                          "propertyName": "VERSION",
                          "propertyValue": "2012-R2"
                      },
                      {
                          "propertyName": "IMAGENAME",
                          "propertyValue": "win2k"
                      },
                      {
                          "propertyName": "DEFAULT_RAM",
                          "propertyValue": "0"
                      },
                      {
                          "propertyName": "NAME",
                          "propertyValue": "win2k.tar.gz"
                      },
                      {
                          "propertyName": "DEFAULT_MINFLASHSIZE",
                          "propertyValue": "0"
                      },
                      {
                          "propertyName": "MINDISKSIZE",
                          "propertyValue": "0"
                      },
                      {
                          "propertyName": "IMAGETYPE_VALUE",
                          "propertyValue": "TGZ"
                      }
                  ],
                  "isSMUApplicable": false,
                  "goldenTagInheritedFromSiteUuid": null,
                  "goldenTagInheritedFromSiteName": null,
                  "importSupported": true,
                  "reasonForNotSupportingImport": null,
                  "tagList": [],
                  "imageCategory": "VIRTUAL",
                  "imageIntegrityStatus": "VERIFIED",
                  "imageImported": true,
                  "applicableDevicesForImage": null,
                  "profileInfo": [
                      {
                          "profileName": "windows-tiny",
                          "memory": 1024,
                          "vCpu": 1,
                          "shares": 32768,
                          "description": "windows tiny profile",
                          "productType": null,
                          "extendedAttributes": null
                      }
                  ],
                  "physicallyAvailable": true,
                  "assignCount": 0,
                  "showUnassign": false,
                  "disableUnassign": false,
                  "ccorecommended": false,
                  "ccolatest": false,
                  "ccoreverseSync": false
              }
          ],
          "deviceAvailable": false
      },
      {
          "deviceFamily": "ISR",
          "deviceTypeOrdinal": -100,
          "softwareType": null,
          "runningImageList": [
              {
                  "name": "isrv-universalk9.16.11.01b.tar.gz",
                  "imageUuid": "42f581fe-f533-45dc-ba53-ee6b0abb15c9",
                  "version": "16.11.01b",
                  "softwareType": null,
                  "imageType": "QCOW_SW",
                  "isTaggedGolden": "true",
                  "md5Checksum": null,
                  "shaCheckSum": "cd362e345d0a1dd79c232a4d6e3dbaef12d375c9395e9c4ef1b1001a4a864c1f7c8c080dfba188f5efb0d81e36744a9068308575b6af636d613b030a077bbbd5",
                  "createdTime": "2019-07-31 19:37:41.0",
                  "applicationType": "ROUTER",
                  "feature": null,
                  "fileServiceId": "4f6b0a62-3aeb-4dde-82ea-153f4660de15",
                  "imageSeries": [
                      "Routers/Network Functions Virtualization"
                  ],
                  "imageSource": "http://192.168.114.107/Kent/isrv-universalk9.16.11.01b.tar.gz",
                  "imageSourceType": "Imported from Remote server URL",
                  "imageFamily": "ISR",
                  "vendor": "CISCO",
                  "filesize": 1040331664,
                  "deviceCount": 0,
                  "deviceUuidList": [],
                  "smuCount": 0,
                  "imageAttributes": [
                      {
                          "propertyName": "IMAGENAME",
                          "propertyValue": "isrv-universalk9.16.11.01b"
                      },
                      {
                          "propertyName": "DEFAULT_BOOTROM",
                          "propertyValue": "0"
                      },
                      {
                          "propertyName": "MD5_CHECKSUM",
                          "propertyValue": "UNKNOWN"
                      },
                      {
                          "propertyName": "NAME",
                          "propertyValue": "isrv-universalk9.16.11.01b.tar.gz"
                      },
                      {
                          "propertyName": "DEFAULT_RAM",
                          "propertyValue": "0"
                      },
                      {
                          "propertyName": "VERSION",
                          "propertyValue": "16.11.01b"
                      },
                      {
                          "propertyName": "DEFAULT_MINFLASHSIZE",
                          "propertyValue": "0"
                      },
                      {
                          "propertyName": "MINDISKSIZE",
                          "propertyValue": "0"
                      },
                      {
                          "propertyName": "LOW_LATENCY",
                          "propertyValue": "true"
                      },
                      {
                          "propertyName": "IMAGETYPE_VALUE",
                          "propertyValue": "TGZ"
                      }
                  ],
                  "isSMUApplicable": false,
                  "goldenTagInheritedFromSiteUuid": null,
                  "goldenTagInheritedFromSiteName": null,
                  "importSupported": true,
                  "reasonForNotSupportingImport": null,
                  "tagList": [],
                  "imageCategory": "VIRTUAL",
                  "imageIntegrityStatus": "VERIFIED",
                  "imageImported": true,
                  "applicableDevicesForImage": null,
                  "profileInfo": [
                      {
                          "profileName": "ISRv-medium",
                          "memory": 4096,
                          "vCpu": 4,
                          "shares": 8192,
                          "description": "ISRv-medium",
                          "productType": null,
                          "extendedAttributes": {
                              "throughput": "250 Mbps"
                          }
                      },
                      {
                          "profileName": "ISRv-small",
                          "memory": 4096,
                          "vCpu": 2,
                          "shares": 8192,
                          "description": "ISRv-small",
                          "productType": null,
                          "extendedAttributes": {
                              "throughput": "50 Mbps"
                          }
                      },
                      {
                          "profileName": "ISRv-mini",
                          "memory": 4096,
                          "vCpu": 1,
                          "shares": 8192,
                          "description": "ISRv-mini",
                          "productType": null,
                          "extendedAttributes": null
                      }
                  ],
                  "physicallyAvailable": true,
                  "assignCount": 0,
                  "showUnassign": false,
                  "disableUnassign": false,
                  "ccorecommended": false,
                  "ccolatest": false,
                  "ccoreverseSync": false
              },
              {
                  "name": "isrv-universalk9.16.10.01a.tar.gz",
                  "imageUuid": "d62fe288-d39c-4390-a382-7ac7eaea69a3",
                  "version": "16.10.01a",
                  "softwareType": null,
                  "imageType": "QCOW_SW",
                  "isTaggedGolden": "false",
                  "md5Checksum": null,
                  "shaCheckSum": "e91b6bb7846bde56db2f74fb0ddc18d95ff9a92f493ab10c5276977381193994123284b8c8309d0ae24e1dd66bdb29460dee147ceafbef51ba972b0d69439b49",
                  "createdTime": "2019-03-12 01:48:36.0",
                  "applicationType": "ROUTER",
                  "feature": null,
                  "fileServiceId": "fbd56d25-a4ad-4929-90ba-b3b4d57754b9",
                  "imageSeries": [
                      "Routers/Network Functions Virtualization"
                  ],
                  "imageSource": "http://192.168.114.107/Kent/isrv-universalk9.16.10.01a.tar.gz",
                  "imageSourceType": "Imported from Remote server URL",
                  "imageFamily": "ISR",
                  "vendor": "CISCO",
                  "filesize": 1127928773,
                  "deviceCount": 0,
                  "deviceUuidList": [],
                  "smuCount": 0,
                  "imageAttributes": [
                      {
                          "propertyName": "DEFAULT_BOOTROM",
                          "propertyValue": "0"
                      },
                      {
                          "propertyName": "MD5_CHECKSUM",
                          "propertyValue": "UNKNOWN"
                      },
                      {
                          "propertyName": "IMAGENAME",
                          "propertyValue": "isrv-universalk9.16.10.01a"
                      },
                      {
                          "propertyName": "DEFAULT_RAM",
                          "propertyValue": "0"
                      },
                      {
                          "propertyName": "NAME",
                          "propertyValue": "isrv-universalk9.16.10.01a.tar.gz"
                      },
                      {
                          "propertyName": "VERSION",
                          "propertyValue": "16.10.01a"
                      },
                      {
                          "propertyName": "DEFAULT_MINFLASHSIZE",
                          "propertyValue": "0"
                      },
                      {
                          "propertyName": "MINDISKSIZE",
                          "propertyValue": "0"
                      },
                      {
                          "propertyName": "LOW_LATENCY",
                          "propertyValue": "true"
                      },
                      {
                          "propertyName": "IMAGETYPE_VALUE",
                          "propertyValue": "TGZ"
                      }
                  ],
                  "isSMUApplicable": false,
                  "goldenTagInheritedFromSiteUuid": null,
                  "goldenTagInheritedFromSiteName": null,
                  "importSupported": true,
                  "reasonForNotSupportingImport": null,
                  "tagList": [],
                  "imageCategory": "VIRTUAL",
                  "imageIntegrityStatus": "VERIFIED",
                  "imageImported": true,
                  "applicableDevicesForImage": null,
                  "profileInfo": [
                      {
                          "profileName": "ISRv-medium",
                          "memory": 4096,
                          "vCpu": 4,
                          "shares": 8192,
                          "description": "ISRv-medium",
                          "productType": null,
                          "extendedAttributes": {
                              "throughput": "250 Mbps"
                          }
                      },
                      {
                          "profileName": "ISRv-mini",
                          "memory": 4096,
                          "vCpu": 1,
                          "shares": 8192,
                          "description": "ISRv-mini",
                          "productType": null,
                          "extendedAttributes": null
                      },
                      {
                          "profileName": "ISRv-small",
                          "memory": 4096,
                          "vCpu": 2,
                          "shares": 8192,
                          "description": "ISRv-small",
                          "productType": null,
                          "extendedAttributes": {
                              "throughput": "50 Mbps"
                          }
                      }
                  ],
                  "physicallyAvailable": true,
                  "assignCount": 0,
                  "showUnassign": false,
                  "disableUnassign": false,
                  "ccorecommended": false,
                  "ccolatest": false,
                  "ccoreverseSync": false
              }
          ],
          "deviceAvailable": false
      },
      {
          "deviceFamily": "CHECKPOINT",
          "deviceTypeOrdinal": -100,
          "softwareType": null,
          "runningImageList": [
              {
                  "name": "CheckPoint.tar.gz",
                  "imageUuid": "09cd7de3-08ac-427a-b5ea-48965b58b5ca",
                  "version": "1",
                  "softwareType": null,
                  "imageType": "QCOW_SW",
                  "isTaggedGolden": "true",
                  "md5Checksum": null,
                  "shaCheckSum": "a3fb861c3c9cf04b485340ddd2a569fd7b46ccbc1733da108fa194d34cc6803ad45328e3a07388e3fc4543ad8397d5185990ad17f3face69d01ee113333c522d",
                  "createdTime": "2018-05-29 19:01:50.0",
                  "applicationType": "FIREWALL",
                  "feature": null,
                  "fileServiceId": "cf5f478a-1ac5-4942-b24f-04b158f07b51",
                  "imageSeries": [
                      "Routers/Network Functions Virtualization"
                  ],
                  "imageSource": "http://192.168.114.107/thirdparty/CheckPoint.tar.gz",
                  "imageSourceType": "Imported from Remote server URL",
                  "imageFamily": "CHECKPOINT",
                  "vendor": "CHECKPOINT",
                  "filesize": 3381573417,
                  "deviceCount": 0,
                  "deviceUuidList": [],
                  "smuCount": 0,
                  "imageAttributes": [
                      {
                          "propertyName": "DEFAULT_BOOTROM",
                          "propertyValue": "0"
                      },
                      {
                          "propertyName": "MD5_CHECKSUM",
                          "propertyValue": "UNKNOWN"
                      },
                      {
                          "propertyName": "DEFAULT_RAM",
                          "propertyValue": "0"
                      },
                      {
                          "propertyName": "IMAGENAME",
                          "propertyValue": "CheckPoint"
                      },
                      {
                          "propertyName": "VERSION",
                          "propertyValue": "1"
                      },
                      {
                          "propertyName": "DEFAULT_MINFLASHSIZE",
                          "propertyValue": "0"
                      },
                      {
                          "propertyName": "NAME",
                          "propertyValue": "CheckPoint.tar.gz"
                      },
                      {
                          "propertyName": "MINDISKSIZE",
                          "propertyValue": "0"
                      },
                      {
                          "propertyName": "LOW_LATENCY",
                          "propertyValue": "true"
                      },
                      {
                          "propertyName": "IMAGETYPE_VALUE",
                          "propertyValue": "TGZ"
                      }
                  ],
                  "isSMUApplicable": false,
                  "goldenTagInheritedFromSiteUuid": null,
                  "goldenTagInheritedFromSiteName": null,
                  "importSupported": true,
                  "reasonForNotSupportingImport": null,
                  "tagList": [],
                  "imageCategory": "VIRTUAL",
                  "imageIntegrityStatus": "VERIFIED",
                  "imageImported": true,
                  "applicableDevicesForImage": null,
                  "profileInfo": [
                      {
                          "profileName": "Checkpoint-medium",
                          "memory": 4096,
                          "vCpu": 4,
                          "shares": 8192,
                          "description": "ISRv-medium",
                          "productType": null,
                          "extendedAttributes": null
                      },
                      {
                          "profileName": "Checkpoint-small",
                          "memory": 4096,
                          "vCpu": 2,
                          "shares": 8192,
                          "description": "ISRv-small",
                          "productType": null,
                          "extendedAttributes": null
                      }
                  ],
                  "physicallyAvailable": true,
                  "assignCount": 0,
                  "showUnassign": false,
                  "disableUnassign": false,
                  "ccorecommended": false,
                  "ccolatest": false,
                  "ccoreverseSync": false
              }
          ],
          "deviceAvailable": false
      },
      {
          "deviceFamily": "ThirdParty",
          "deviceTypeOrdinal": -100,
          "softwareType": null,
          "runningImageList": [
              {
                  "name": "Silverpeak_Cust.tar.gz",
                  "imageUuid": "5a3b111a-aa07-4adc-86de-289cdeb752a7",
                  "version": "7.3.9.0",
                  "softwareType": null,
                  "imageType": "QCOW_SW",
                  "isTaggedGolden": "true",
                  "md5Checksum": null,
                  "shaCheckSum": "ebb82c0d67ca3a6f03fb781313e52a1ca55c4ab7899df2c7e0ca4a77ac57e0b12087d12c8bd3d24a1c9d2ef5b198c11d61e171a9ea365d31c9ab12bdf4d48866",
                  "createdTime": "2018-05-29 18:59:10.0",
                  "applicationType": "UNKNOWN",
                  "feature": null,
                  "fileServiceId": "16bd06bd-f35e-423e-94fc-9cfccdae00bb",
                  "imageSeries": null,
                  "imageSource": "http://192.168.114.107/thirdparty/Silverpeak_Cust.tar.gz",
                  "imageSourceType": "Imported from Remote server URL",
                  "imageFamily": "ThirdParty",
                  "vendor": null,
                  "filesize": 359017410,
                  "deviceCount": 0,
                  "deviceUuidList": [],
                  "smuCount": 0,
                  "imageAttributes": [
                      {
                          "propertyName": "DEFAULT_BOOTROM",
                          "propertyValue": "0"
                      },
                      {
                          "propertyName": "MD5_CHECKSUM",
                          "propertyValue": "UNKNOWN"
                      },
                      {
                          "propertyName": "DEFAULT_RAM",
                          "propertyValue": "0"
                      },
                      {
                          "propertyName": "VERSION",
                          "propertyValue": "7.3.9.0"
                      },
                      {
                          "propertyName": "DEFAULT_MINFLASHSIZE",
                          "propertyValue": "0"
                      },
                      {
                          "propertyName": "NAME",
                          "propertyValue": "Silverpeak_Cust.tar.gz"
                      },
                      {
                          "propertyName": "IMAGENAME",
                          "propertyValue": "Silverpeak_Cust"
                      },
                      {
                          "propertyName": "MINDISKSIZE",
                          "propertyValue": "0"
                      },
                      {
                          "propertyName": "LOW_LATENCY",
                          "propertyValue": "true"
                      },
                      {
                          "propertyName": "IMAGETYPE_VALUE",
                          "propertyValue": "TGZ"
                      }
                  ],
                  "isSMUApplicable": false,
                  "goldenTagInheritedFromSiteUuid": null,
                  "goldenTagInheritedFromSiteName": null,
                  "importSupported": true,
                  "reasonForNotSupportingImport": null,
                  "tagList": [],
                  "imageCategory": "VIRTUAL",
                  "imageIntegrityStatus": "VERIFIED",
                  "imageImported": true,
                  "applicableDevicesForImage": null,
                  "profileInfo": [
                      {
                          "profileName": "sp-small",
                          "memory": 4096,
                          "vCpu": 2,
                          "shares": 75776,
                          "description": "\"sp-small\"",
                          "productType": null,
                          "extendedAttributes": null
                      }
                  ],
                  "physicallyAvailable": true,
                  "assignCount": 0,
                  "showUnassign": false,
                  "disableUnassign": false,
                  "ccorecommended": false,
                  "ccolatest": false,
                  "ccoreverseSync": false
              },
              {
                  "name": "steelhead111.tar.gz",
                  "imageUuid": "afd7372c-4088-4e4e-8a6a-14e7aa99bc77",
                  "version": "9",
                  "softwareType": null,
                  "imageType": "QCOW_SW",
                  "isTaggedGolden": "false",
                  "md5Checksum": null,
                  "shaCheckSum": "4e56cb0d95b477b64b4fc35cfe35c386ea4b12998e8c4611031991a3fb826b195150090cffaa4625ff61e4a3d2c4872212cb80d17b0502af12e6c6bcb1304b46",
                  "createdTime": "2018-05-29 18:57:13.0",
                  "applicationType": "UNKNOWN",
                  "feature": null,
                  "fileServiceId": "a63ed656-929d-4141-a04f-a2993f0fd27b",
                  "imageSeries": null,
                  "imageSource": "http://192.168.114.107/thirdparty/steelhead111.tar.gz",
                  "imageSourceType": "Imported from Remote server URL",
                  "imageFamily": "ThirdParty",
                  "vendor": null,
                  "filesize": 759476034,
                  "deviceCount": 0,
                  "deviceUuidList": [],
                  "smuCount": 0,
                  "imageAttributes": [
                      {
                          "propertyName": "DEFAULT_BOOTROM",
                          "propertyValue": "0"
                      },
                      {
                          "propertyName": "MD5_CHECKSUM",
                          "propertyValue": "UNKNOWN"
                      },
                      {
                          "propertyName": "VERSION",
                          "propertyValue": "9"
                      },
                      {
                          "propertyName": "DEFAULT_RAM",
                          "propertyValue": "0"
                      },
                      {
                          "propertyName": "IMAGENAME",
                          "propertyValue": "steelhead111"
                      },
                      {
                          "propertyName": "DEFAULT_MINFLASHSIZE",
                          "propertyValue": "0"
                      },
                      {
                          "propertyName": "MINDISKSIZE",
                          "propertyValue": "0"
                      },
                      {
                          "propertyName": "NAME",
                          "propertyValue": "steelhead111.tar.gz"
                      },
                      {
                          "propertyName": "LOW_LATENCY",
                          "propertyValue": "true"
                      },
                      {
                          "propertyName": "IMAGETYPE_VALUE",
                          "propertyValue": "TGZ"
                      }
                  ],
                  "isSMUApplicable": false,
                  "goldenTagInheritedFromSiteUuid": null,
                  "goldenTagInheritedFromSiteName": null,
                  "importSupported": true,
                  "reasonForNotSupportingImport": null,
                  "tagList": [],
                  "imageCategory": "VIRTUAL",
                  "imageIntegrityStatus": "VERIFIED",
                  "imageImported": true,
                  "applicableDevicesForImage": null,
                  "profileInfo": [
                      {
                          "profileName": "riverbed-small",
                          "memory": 2048,
                          "vCpu": 1,
                          "shares": 8192,
                          "description": "1vCPU",
                          "productType": null,
                          "extendedAttributes": null
                      }
                  ],
                  "physicallyAvailable": true,
                  "assignCount": 0,
                  "showUnassign": false,
                  "disableUnassign": false,
                  "ccorecommended": false,
                  "ccolatest": false,
                  "ccoreverseSync": false
              }
          ],
          "deviceAvailable": false
      },
      {
          "deviceFamily": "NGFW",
          "deviceTypeOrdinal": -100,
          "softwareType": null,
          "runningImageList": [
              {
                  "name": "Cisco_FTDv_6.2.2_81.tar.gz",
                  "imageUuid": "8f1b3ca0-82a0-49be-8bc7-f4ac3ae9c7be",
                  "version": "1.0",
                  "softwareType": null,
                  "imageType": "QCOW_SW",
                  "isTaggedGolden": "true",
                  "md5Checksum": null,
                  "shaCheckSum": "30b002292892e243a0cb2c61d272332640482a72bfeb2f952893985f47926f21df26c75c0efca2d454db4b209952f7bf028a9e9b332d692f495b0f80dd439b96",
                  "createdTime": "2018-05-29 19:32:40.0",
                  "applicationType": "FIREWALL",
                  "feature": null,
                  "fileServiceId": "ed05fc50-1871-44a6-9d78-eeb405bcfb41",
                  "imageSeries": null,
                  "imageSource": "http://192.168.114.107/vnfImages/Cisco_FTDv_6.2.2_81.tar.gz",
                  "imageSourceType": "Imported from Remote server URL",
                  "imageFamily": "NGFW",
                  "vendor": "CISCO",
                  "filesize": 1017822511,
                  "deviceCount": 0,
                  "deviceUuidList": [],
                  "smuCount": 0,
                  "imageAttributes": [
                      {
                          "propertyName": "DEFAULT_BOOTROM",
                          "propertyValue": "0"
                      },
                      {
                          "propertyName": "MD5_CHECKSUM",
                          "propertyValue": "UNKNOWN"
                      },
                      {
                          "propertyName": "NAME",
                          "propertyValue": "Cisco_FTDv_6.2.2_81.tar.gz"
                      },
                      {
                          "propertyName": "VERSION",
                          "propertyValue": "1.0"
                      },
                      {
                          "propertyName": "DEFAULT_RAM",
                          "propertyValue": "0"
                      },
                      {
                          "propertyName": "IMAGENAME",
                          "propertyValue": "Cisco_FTDv_6.2.2_81"
                      },
                      {
                          "propertyName": "DEFAULT_MINFLASHSIZE",
                          "propertyValue": "0"
                      },
                      {
                          "propertyName": "MINDISKSIZE",
                          "propertyValue": "0"
                      },
                      {
                          "propertyName": "LOW_LATENCY",
                          "propertyValue": "true"
                      },
                      {
                          "propertyName": "IMAGETYPE_VALUE",
                          "propertyValue": "TGZ"
                      }
                  ],
                  "isSMUApplicable": false,
                  "goldenTagInheritedFromSiteUuid": null,
                  "goldenTagInheritedFromSiteName": null,
                  "importSupported": true,
                  "reasonForNotSupportingImport": null,
                  "tagList": [],
                  "imageCategory": "VIRTUAL",
                  "imageIntegrityStatus": "VERIFIED",
                  "imageImported": true,
                  "applicableDevicesForImage": null,
                  "profileInfo": [
                      {
                          "profileName": "FTDv622",
                          "memory": 8192,
                          "vCpu": 4,
                          "shares": 51200,
                          "description": "FTDv622 profile",
                          "productType": null,
                          "extendedAttributes": null
                      }
                  ],
                  "physicallyAvailable": true,
                  "assignCount": 0,
                  "showUnassign": false,
                  "disableUnassign": false,
                  "ccorecommended": false,
                  "ccolatest": false,
                  "ccoreverseSync": false
              }
          ],
          "deviceAvailable": false
      },
      {
          "deviceFamily": "ASA",
          "deviceTypeOrdinal": -100,
          "softwareType": null,
          "runningImageList": [
              {
                  "name": "asav992.tar.gz",
                  "imageUuid": "475b6350-d3d6-43a7-9c84-1c2fc58662b4",
                  "version": "9.9.2",
                  "softwareType": null,
                  "imageType": "QCOW_SW",
                  "isTaggedGolden": "true",
                  "md5Checksum": null,
                  "shaCheckSum": "6c3f4fc20ba9eac3bb2b719448333922ed96e5c3f5bf1831ea0c192340a8353645dbd10f2091bd86504dce826b2f874be257f2ec27426d5308d3fcfc8caaa0c5",
                  "createdTime": "2018-12-18 23:02:10.0",
                  "applicationType": "FIREWALL",
                  "feature": null,
                  "fileServiceId": "ab5095e4-f370-4283-80f8-95f7ca1f7f50",
                  "imageSeries": [
                      "Security and VPN/Adaptive Security Appliances (ASA)"
                  ],
                  "imageSource": "http://192.168.114.107/Kent/asav992.tar.gz",
                  "imageSourceType": "Imported from Remote server URL",
                  "imageFamily": "ASA",
                  "vendor": "CISCO",
                  "filesize": 202180624,
                  "deviceCount": 0,
                  "deviceUuidList": [],
                  "smuCount": 0,
                  "imageAttributes": [
                      {
                          "propertyName": "DEFAULT_BOOTROM",
                          "propertyValue": "0"
                      },
                      {
                          "propertyName": "MD5_CHECKSUM",
                          "propertyValue": "UNKNOWN"
                      },
                      {
                          "propertyName": "NAME",
                          "propertyValue": "asav992.tar.gz"
                      },
                      {
                          "propertyName": "DEFAULT_RAM",
                          "propertyValue": "0"
                      },
                      {
                          "propertyName": "VERSION",
                          "propertyValue": "9.9.2"
                      },
                      {
                          "propertyName": "IMAGENAME",
                          "propertyValue": "asav992"
                      },
                      {
                          "propertyName": "DEFAULT_MINFLASHSIZE",
                          "propertyValue": "0"
                      },
                      {
                          "propertyName": "MINDISKSIZE",
                          "propertyValue": "0"
                      },
                      {
                          "propertyName": "LOW_LATENCY",
                          "propertyValue": "true"
                      },
                      {
                          "propertyName": "IMAGETYPE_VALUE",
                          "propertyValue": "TGZ"
                      }
                  ],
                  "isSMUApplicable": false,
                  "goldenTagInheritedFromSiteUuid": null,
                  "goldenTagInheritedFromSiteName": null,
                  "importSupported": true,
                  "reasonForNotSupportingImport": null,
                  "tagList": [],
                  "imageCategory": "VIRTUAL",
                  "imageIntegrityStatus": "VERIFIED",
                  "imageImported": true,
                  "applicableDevicesForImage": null,
                  "profileInfo": [
                      {
                          "profileName": "ASAv5",
                          "memory": 1024,
                          "vCpu": 1,
                          "shares": 8192,
                          "description": "ASAv5 profile",
                          "productType": null,
                          "extendedAttributes": {
                              "throughput": "100 Mbps"
                          }
                      },
                      {
                          "profileName": "ASAv30",
                          "memory": 8192,
                          "vCpu": 4,
                          "shares": 16384,
                          "description": "ASAv30 profile",
                          "productType": null,
                          "extendedAttributes": {
                              "throughput": "2 Gbps"
                          }
                      },
                      {
                          "profileName": "ASAv10",
                          "memory": 2048,
                          "vCpu": 1,
                          "shares": 8192,
                          "description": "ASAv10 profile",
                          "productType": null,
                          "extendedAttributes": {
                              "throughput": "1 Gbps"
                          }
                      }
                  ],
                  "physicallyAvailable": true,
                  "assignCount": 0,
                  "showUnassign": false,
                  "disableUnassign": false,
                  "ccorecommended": false,
                  "ccolatest": false,
                  "ccoreverseSync": false
              },
              {
                  "name": "asav961-Cust.tar.gz",
                  "imageUuid": "9d24bea3-a026-48c6-8ea9-6a61bbb579fc",
                  "version": "961",
                  "softwareType": null,
                  "imageType": "QCOW_SW",
                  "isTaggedGolden": "false",
                  "md5Checksum": null,
                  "shaCheckSum": "1b4c245c13eb05476f73e04213b5575342c543e3f45881682fb7f1d502e65d31ab27ae5e772a372fc381ee91677cdac7af9ff9e5109349f824169a0321f168d3",
                  "createdTime": "2018-05-29 18:45:23.0",
                  "applicationType": "FIREWALL",
                  "feature": null,
                  "fileServiceId": "1e24bfc1-f1dc-47e0-8502-6a4f4015c33b",
                  "imageSeries": [
                      "Security and VPN/Adaptive Security Appliances (ASA)"
                  ],
                  "imageSource": "http://192.168.114.107/vnfImages/asav961-Cust.tar.gz",
                  "imageSourceType": "Imported from Remote server URL",
                  "imageFamily": "ASA",
                  "vendor": "CISCO",
                  "filesize": 171496919,
                  "deviceCount": 0,
                  "deviceUuidList": [],
                  "smuCount": 0,
                  "imageAttributes": [
                      {
                          "propertyName": "DEFAULT_BOOTROM",
                          "propertyValue": "0"
                      },
                      {
                          "propertyName": "IMAGENAME",
                          "propertyValue": "asav961-Cust"
                      },
                      {
                          "propertyName": "MD5_CHECKSUM",
                          "propertyValue": "UNKNOWN"
                      },
                      {
                          "propertyName": "DEFAULT_RAM",
                          "propertyValue": "0"
                      },
                      {
                          "propertyName": "VERSION",
                          "propertyValue": "961"
                      },
                      {
                          "propertyName": "DEFAULT_MINFLASHSIZE",
                          "propertyValue": "0"
                      },
                      {
                          "propertyName": "NAME",
                          "propertyValue": "asav961-Cust.tar.gz"
                      },
                      {
                          "propertyName": "MINDISKSIZE",
                          "propertyValue": "0"
                      },
                      {
                          "propertyName": "LOW_LATENCY",
                          "propertyValue": "true"
                      },
                      {
                          "propertyName": "IMAGETYPE_VALUE",
                          "propertyValue": "TGZ"
                      }
                  ],
                  "isSMUApplicable": false,
                  "goldenTagInheritedFromSiteUuid": null,
                  "goldenTagInheritedFromSiteName": null,
                  "importSupported": true,
                  "reasonForNotSupportingImport": null,
                  "tagList": [],
                  "imageCategory": "VIRTUAL",
                  "imageIntegrityStatus": "VERIFIED",
                  "imageImported": true,
                  "applicableDevicesForImage": null,
                  "profileInfo": [
                      {
                          "profileName": "ASAv10",
                          "memory": 2048,
                          "vCpu": 1,
                          "shares": 8192,
                          "description": "ASAv10 profile",
                          "productType": null,
                          "extendedAttributes": {
                              "throughput": "1 Gbps"
                          }
                      },
                      {
                          "profileName": "ASAv30",
                          "memory": 8192,
                          "vCpu": 4,
                          "shares": 16384,
                          "description": "ASAv30 profile",
                          "productType": null,
                          "extendedAttributes": {
                              "throughput": "2 Gbps"
                          }
                      },
                      {
                          "profileName": "ASAv5",
                          "memory": 1024,
                          "vCpu": 1,
                          "shares": 8192,
                          "description": "ASAv5 profile",
                          "productType": null,
                          "extendedAttributes": {
                              "throughput": "100 Mbps"
                          }
                      }
                  ],
                  "physicallyAvailable": true,
                  "assignCount": 0,
                  "showUnassign": false,
                  "disableUnassign": false,
                  "ccorecommended": false,
                  "ccolatest": false,
                  "ccoreverseSync": false
              }
          ],
          "deviceAvailable": false
      },
      {
          "deviceFamily": "VEDGE",
          "deviceTypeOrdinal": -100,
          "softwareType": null,
          "runningImageList": [
              {
                  "name": "vedge_18_2_updated.tar.gz",
                  "imageUuid": "9e6683ce-cd26-4f7f-8df9-f06a98ed948e",
                  "version": "18.2",
                  "softwareType": null,
                  "imageType": "QCOW_SW",
                  "isTaggedGolden": "false",
                  "md5Checksum": null,
                  "shaCheckSum": "522d2daa932346047729e15503d6a9e6c7ac7c415e16e90af7e91681cbc524b8c99b313cf6b448d7a001fa0fcea4338dcef333f13873a256d4774b35ff938bf4",
                  "createdTime": "2018-07-09 22:08:32.0",
                  "applicationType": "ROUTER",
                  "feature": null,
                  "fileServiceId": "b3e61a83-b2d3-4539-af12-81a0834618e0",
                  "imageSeries": null,
                  "imageSource": "http://192.168.114.107/vEDGE/vedge_18_2_updated.tar.gz",
                  "imageSourceType": "Imported from Remote server URL",
                  "imageFamily": "VEDGE",
                  "vendor": "CISCO",
                  "filesize": 218802915,
                  "deviceCount": 0,
                  "deviceUuidList": [],
                  "smuCount": 0,
                  "imageAttributes": [
                      {
                          "propertyName": "DEFAULT_BOOTROM",
                          "propertyValue": "0"
                      },
                      {
                          "propertyName": "MD5_CHECKSUM",
                          "propertyValue": "UNKNOWN"
                      },
                      {
                          "propertyName": "NAME",
                          "propertyValue": "vedge_18_2_updated.tar.gz"
                      },
                      {
                          "propertyName": "DEFAULT_RAM",
                          "propertyValue": "0"
                      },
                      {
                          "propertyName": "VERSION",
                          "propertyValue": "18.2"
                      },
                      {
                          "propertyName": "IMAGENAME",
                          "propertyValue": "vedge_18_2_updated"
                      },
                      {
                          "propertyName": "DEFAULT_MINFLASHSIZE",
                          "propertyValue": "0"
                      },
                      {
                          "propertyName": "MINDISKSIZE",
                          "propertyValue": "0"
                      },
                      {
                          "propertyName": "LOW_LATENCY",
                          "propertyValue": "true"
                      },
                      {
                          "propertyName": "IMAGETYPE_VALUE",
                          "propertyValue": "TGZ"
                      }
                  ],
                  "isSMUApplicable": false,
                  "goldenTagInheritedFromSiteUuid": null,
                  "goldenTagInheritedFromSiteName": null,
                  "importSupported": true,
                  "reasonForNotSupportingImport": null,
                  "tagList": [],
                  "imageCategory": "VIRTUAL",
                  "imageIntegrityStatus": "VERIFIED",
                  "imageImported": true,
                  "applicableDevicesForImage": null,
                  "profileInfo": [
                      {
                          "profileName": "Vedge-Standard",
                          "memory": 2048,
                          "vCpu": 2,
                          "shares": 8192,
                          "description": "Vedge Standard Profile",
                          "productType": null,
                          "extendedAttributes": null
                      }
                  ],
                  "physicallyAvailable": true,
                  "assignCount": 0,
                  "showUnassign": false,
                  "disableUnassign": false,
                  "ccorecommended": false,
                  "ccolatest": false,
                  "ccoreverseSync": false
              },
              {
                  "name": "vedge_18_2.tar.gz",
                  "imageUuid": "a70a6e45-a6f8-48d5-be90-02f02fba87f0",
                  "version": "17.2.5",
                  "softwareType": null,
                  "imageType": "QCOW_SW",
                  "isTaggedGolden": "false",
                  "md5Checksum": null,
                  "shaCheckSum": "fac0858cc91acf4b160ff3b8e06a611c7bf800f8461c319984ca9c48ee744c75bfbfcaa57e4e02b916d2ce4014e0e5e7212b4e290a23b524ec6fd31225c71e8b",
                  "createdTime": "2018-06-26 19:33:45.0",
                  "applicationType": "ROUTER",
                  "feature": null,
                  "fileServiceId": "222d2602-7117-4661-8f91-ffab632e4f76",
                  "imageSeries": null,
                  "imageSource": "http://192.168.114.107/vEDGE/vedge_18_2.tar.gz",
                  "imageSourceType": "Imported from Remote server URL",
                  "imageFamily": "VEDGE",
                  "vendor": "CISCO",
                  "filesize": 218802910,
                  "deviceCount": 0,
                  "deviceUuidList": [],
                  "smuCount": 0,
                  "imageAttributes": [
                      {
                          "propertyName": "VERSION",
                          "propertyValue": "17.2.5"
                      },
                      {
                          "propertyName": "DEFAULT_BOOTROM",
                          "propertyValue": "0"
                      },
                      {
                          "propertyName": "IMAGENAME",
                          "propertyValue": "vedge_18_2"
                      },
                      {
                          "propertyName": "MD5_CHECKSUM",
                          "propertyValue": "UNKNOWN"
                      },
                      {
                          "propertyName": "NAME",
                          "propertyValue": "vedge_18_2.tar.gz"
                      },
                      {
                          "propertyName": "DEFAULT_RAM",
                          "propertyValue": "0"
                      },
                      {
                          "propertyName": "DEFAULT_MINFLASHSIZE",
                          "propertyValue": "0"
                      },
                      {
                          "propertyName": "MINDISKSIZE",
                          "propertyValue": "0"
                      },
                      {
                          "propertyName": "LOW_LATENCY",
                          "propertyValue": "true"
                      },
                      {
                          "propertyName": "IMAGETYPE_VALUE",
                          "propertyValue": "TGZ"
                      }
                  ],
                  "isSMUApplicable": false,
                  "goldenTagInheritedFromSiteUuid": null,
                  "goldenTagInheritedFromSiteName": null,
                  "importSupported": true,
                  "reasonForNotSupportingImport": null,
                  "tagList": [],
                  "imageCategory": "VIRTUAL",
                  "imageIntegrityStatus": "VERIFIED",
                  "imageImported": true,
                  "applicableDevicesForImage": null,
                  "profileInfo": [
                      {
                          "profileName": "Vedge-Standard",
                          "memory": 2048,
                          "vCpu": 2,
                          "shares": 8192,
                          "description": "Vedge Standard Profile",
                          "productType": null,
                          "extendedAttributes": null
                      }
                  ],
                  "physicallyAvailable": true,
                  "assignCount": 0,
                  "showUnassign": false,
                  "disableUnassign": false,
                  "ccorecommended": false,
                  "ccolatest": false,
                  "ccoreverseSync": false
              },
              {
                  "name": "vedge_19.1.0_CCO.tar.gz",
                  "imageUuid": "769c2239-43f9-4b9c-a1c5-d8a5d2043c41",
                  "version": "19.1.0",
                  "softwareType": null,
                  "imageType": "QCOW_SW",
                  "isTaggedGolden": "false",
                  "md5Checksum": null,
                  "shaCheckSum": "1376a096b7b59933ac2aa1ca0ab71863e531f6e4e0f2a27c6ca2a3fe2326e084df1e8a74cfd7cb7ced54b8e3333aa577a2fc36f934aa72d3467c8935972fc8a3",
                  "createdTime": "2019-07-31 19:40:13.0",
                  "applicationType": "ROUTER",
                  "feature": null,
                  "fileServiceId": "db0f6a24-f491-4e60-b22f-d1541a5940da",
                  "imageSeries": null,
                  "imageSource": "http://192.168.114.107/Kent/vedge_19.1.0_CCO.tar.gz",
                  "imageSourceType": "Imported from Remote server URL",
                  "imageFamily": "VEDGE",
                  "vendor": "CISCO",
                  "filesize": 301085707,
                  "deviceCount": 0,
                  "deviceUuidList": [],
                  "smuCount": 0,
                  "imageAttributes": [
                      {
                          "propertyName": "IMAGENAME",
                          "propertyValue": "vedge_19.1.0_CCO"
                      },
                      {
                          "propertyName": "VERSION",
                          "propertyValue": "19.1.0"
                      },
                      {
                          "propertyName": "DEFAULT_BOOTROM",
                          "propertyValue": "0"
                      },
                      {
                          "propertyName": "MD5_CHECKSUM",
                          "propertyValue": "UNKNOWN"
                      },
                      {
                          "propertyName": "DEFAULT_RAM",
                          "propertyValue": "0"
                      },
                      {
                          "propertyName": "DEFAULT_MINFLASHSIZE",
                          "propertyValue": "0"
                      },
                      {
                          "propertyName": "NAME",
                          "propertyValue": "vedge_19.1.0_CCO.tar.gz"
                      },
                      {
                          "propertyName": "MINDISKSIZE",
                          "propertyValue": "0"
                      },
                      {
                          "propertyName": "LOW_LATENCY",
                          "propertyValue": "true"
                      },
                      {
                          "propertyName": "IMAGETYPE_VALUE",
                          "propertyValue": "TGZ"
                      }
                  ],
                  "isSMUApplicable": false,
                  "goldenTagInheritedFromSiteUuid": null,
                  "goldenTagInheritedFromSiteName": null,
                  "importSupported": true,
                  "reasonForNotSupportingImport": null,
                  "tagList": [],
                  "imageCategory": "VIRTUAL",
                  "imageIntegrityStatus": "VERIFIED",
                  "imageImported": true,
                  "applicableDevicesForImage": null,
                  "profileInfo": [
                      {
                          "profileName": "Vedge-Standard",
                          "memory": 2048,
                          "vCpu": 2,
                          "shares": 8192,
                          "description": "Vedge Standard Profile",
                          "productType": null,
                          "extendedAttributes": null
                      }
                  ],
                  "physicallyAvailable": true,
                  "assignCount": 0,
                  "showUnassign": false,
                  "disableUnassign": false,
                  "ccorecommended": false,
                  "ccolatest": false,
                  "ccoreverseSync": false
              },
              {
                  "name": "vedge_19.1.0_CCO-monitored.tar.gz",
                  "imageUuid": "a69142f0-a368-4878-b762-5ad6288bc878",
                  "version": "19.1.0",
                  "softwareType": null,
                  "imageType": "QCOW_SW",
                  "isTaggedGolden": "true",
                  "md5Checksum": null,
                  "shaCheckSum": "7fbfb8be124a18af2418191a69311944694cf0cc6f99e496afcd230c61bc05333bc709ca98bbd01c7577e640060bad3fba5b7a9ab11827ad0a2bd3131268ab60",
                  "createdTime": "2019-11-15 07:55:04.0",
                  "applicationType": "ROUTER",
                  "feature": null,
                  "fileServiceId": "531fef59-73a1-4bfc-9aca-7d90c5200f79",
                  "imageSeries": null,
                  "imageSource": "http://192.168.114.107/Kent/vedge_19.1.0_CCO-monitored.tar.gz",
                  "imageSourceType": "Imported from Remote server URL",
                  "imageFamily": "VEDGE",
                  "vendor": "CISCO",
                  "filesize": 301138096,
                  "deviceCount": 0,
                  "deviceUuidList": [],
                  "smuCount": 0,
                  "imageAttributes": [
                      {
                          "propertyName": "VERSION",
                          "propertyValue": "19.1.0"
                      },
                      {
                          "propertyName": "DEFAULT_BOOTROM",
                          "propertyValue": "0"
                      },
                      {
                          "propertyName": "MD5_CHECKSUM",
                          "propertyValue": "UNKNOWN"
                      },
                      {
                          "propertyName": "DEFAULT_RAM",
                          "propertyValue": "0"
                      },
                      {
                          "propertyName": "DEFAULT_MINFLASHSIZE",
                          "propertyValue": "0"
                      },
                      {
                          "propertyName": "IMAGENAME",
                          "propertyValue": "vedge_19.1.0_CCO-monitored"
                      },
                      {
                          "propertyName": "MINDISKSIZE",
                          "propertyValue": "0"
                      },
                      {
                          "propertyName": "NAME",
                          "propertyValue": "vedge_19.1.0_CCO-monitored.tar.gz"
                      },
                      {
                          "propertyName": "LOW_LATENCY",
                          "propertyValue": "true"
                      },
                      {
                          "propertyName": "IMAGETYPE_VALUE",
                          "propertyValue": "TGZ"
                      }
                  ],
                  "isSMUApplicable": false,
                  "goldenTagInheritedFromSiteUuid": null,
                  "goldenTagInheritedFromSiteName": null,
                  "importSupported": true,
                  "reasonForNotSupportingImport": null,
                  "tagList": [],
                  "imageCategory": "VIRTUAL",
                  "imageIntegrityStatus": "VERIFIED",
                  "imageImported": true,
                  "applicableDevicesForImage": null,
                  "profileInfo": [
                      {
                          "profileName": "Vedge-Standard",
                          "memory": 2048,
                          "vCpu": 2,
                          "shares": 8192,
                          "description": "Vedge Standard Profile",
                          "productType": null,
                          "extendedAttributes": null
                      }
                  ],
                  "physicallyAvailable": true,
                  "assignCount": 0,
                  "showUnassign": false,
                  "disableUnassign": false,
                  "ccorecommended": false,
                  "ccolatest": false,
                  "ccoreverseSync": false
              }
          ],
          "deviceAvailable": false
      }
  ]
});

