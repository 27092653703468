define({
    "InterfaceTemplate": {
        "addresses": null,
        "adminStatus": "DOWN",
        "className": "EthernetProtocolEndpoint",
        "description": "",
        "deviceId": "42b00d34-7db5-4adf-bc22-bb5f5b3042c1",
        "duplex": "AutoNegotiate",
        "id": "d7c7b4ab-d4b5-4eed-a872-1f66983a77be",
        "ifIndex": "2",
        "instanceTenantId": "5f35ea5f15de5300c6a0162a",
        "instanceUuid": "d7c7b4ab-d4b5-4eed-a872-1f66983a77be",
        "interfaceType": "Physical",
        "ipv4Address": null,
        "ipv4Mask": null,
        "isisSupport": "false",
        "lastUpdated": "2020-10-24 06:39:31.78",
        "macAddress": "d4:ad:71:ff:bc:00",
        "mappedPhysicalInterfaceId": null,
        "mappedPhysicalInterfaceName": null,
        "mediaType": null,
        "mtu": "1500",
        "nativeVlanId": null,
        "ospfSupport": "false",
        "owningEntityId": "277279_277279",
        "pid": "C9500-16X",
        "portMode": "routed",
        "portName": "Bluetooth0/4",
        "portType": "Ethernet Port",
        "poweroverethernet": 0,
        "serialNo": "FCW2245F0FN",
        "series": "Cisco Catalyst 9500 Series Switches",
        "speed": "1000000",
        "status": "down",
        "vlanId": "0"
    },

    // "Interfaces": ["GigabitEthernet1/0/2","GigabitEthernet1/0/3","GigabitEthernet1/0/4","GigabitEthernet1/0/5",
    //     "GigabitEthernet1/0/6","GigabitEthernet1/0/7","GigabitEthernet1/0/8","GigabitEthernet1/0/9","GigabiEthernet1/0/10",
    //     "GigabitEthernet1/0/11","GigabitEthernet1/0/12","GigabitEthernet1/0/13","GigabitEthernet1/0/14",
    //     "GigabitEthernet1/1/1","GigabitEthernet1/1/2","GigabitEthernet1/1/3","GigabitEthrnet1/1/4",
    //     "TenGigabitEthernet1/1/1","TenGigabitEthernet1/1/2","TenGigabitEthernet1/1/3","TenGigabitEthernet1/1/4"],
    
    "Interfaces": {
        "SwitchesAndHubs": ["GigabitEthernet0/0","GigabitEthernet1/0/1", "GigabitEthernet1/0/2", "GigabitEthernet1/0/3",
        "GigabitEthernet1/0/4", "GigabitEthernet1/0/5", "GigabitEthernet1/0/6", "GigabitEthernet1/0/7",
        "GigabitEthernet1/0/8", "GigabitEthernet1/0/9", "GigabitEthernet1/0/10", "GigabitEthernet1/0/11",
        "GigabitEthernet1/0/12", "GigabitEthernet1/0/13","GigabitEthernet1/0/14", "GigabitEthernet1/0/15",
        "GigabitEthernet1/0/16", "GigabitEthernet1/0/17", "GigabitEthernet1/0/18", "GigabitEthernet1/0/19",
        "GigabitEthernet1/0/20", "GigabitEthernet1/0/21","GigabitEthernet1/0/22", "GigabitEthernet1/0/23",
        "GigabitEthernet1/0/24"
    ],
       "SwitchesAndHubsExtraPorts": [ "TenGigabitEthernet1/1/1","TenGigabitEthernet1/1/2","TenGigabitEthernet1/1/3",
        "TenGigabitEthernet1/1/4","TenGigabitEthernet1/1/5", "TenGigabitEthernet1/1/6", "TenGigabitEthernet1/1/7",
        "TenGigabitEthernet1/1/8", "TenGigabitEthernet1/1/9", "TenGigabitEthernet1/1/10", "TenGigabitEthernet1/1/11",
        "TenGigabitEthernet1/1/12", "TenGigabitEthernet1/1/13", "TenGigabitEthernet1/1/14", "TenGigabitEthernet1/1/15",
        "TenGigabitEthernet1/1/16", "FortyGigabitEthernet1/1/1", "FortyGigabitEthernet1/1/2", "FortyGigabitEthernet1/1/3",
        "FortyGigabitEthernet1/1/4", "FortyGigabitEthernet1/1/5", "FortyGigabitEthernet1/1/6","FortyGigabitEthernet1/1/7",
        "FortyGigabitEthernet1/1/8"
    ],
        "Routers": ["GigabitEthernet0", "GigabitEthernet0/0/0", "GigabitEthernet0/0/1", "GigabitEthernet0/0/2",
        "GigabitEthernet0/0/3", "GigabitEthernet0/0/4","GigabitEthernet0/0/5", "Loopback0", "VoIP-Null0"
    ],
        /* "WirelessController": ["management", "TenGigabitEthernet0/0/1", "TenGigabitEthernet0/0/2", 
        "TenGigabitEthernet0/0/3","TenGigabitEthernet0/0/4", "TenGigabitEthernet0/0/5","Virtual Interface" */
        "WirelessController": ["GigabitEthernet0","TenGigabitEthernet0/0/0", "TenGigabitEthernet0/0/1", "TenGigabitEthernet0/0/2", 
            "TenGigabitEthernet0/0/3", "Vlan1", "Vlan174"
        ],
        "WirelessSensor": [],
        "IotDevices": ["GigabitEthernet0/0","GigabitEthernet1/1", "GigabitEthernet1/2", "GigabitEthernet1/3",
            "GigabitEthernet1/4", "GigabitEthernet1/5", "GigabitEthernet1/6", "GigabitEthernet1/7",
            "GigabitEthernet1/8", "GigabitEthernet1/9", "GigabitEthernet1/10", "GigabitEthernet1/11",
            "GigabitEthernet1/12", "GigabitEthernet1/13","GigabitEthernet1/14", "GigabitEthernet1/15",
            "GigabitEthernet1/16", "GigabitEthernet1/17", "GigabitEthernet1/18", "GigabitEthernet1/19",
            "GigabitEthernet1/20", "GigabitEthernet1/21","GigabitEthernet1/22", "GigabitEthernet1/23",
            "GigabitEthernet1/24"
        ]
    },

    "IntefaceTemplateFor360": [{
        "portMode":["access"],
        "vlanId":["ALL"],
        "mediaType":["ETHERNETCSMACD"],
        "speed":["10000000"],
        "interfaceType":["Physical"],
        "portType":["Ethernet Port"],
        "isL3Interface":["FALSE"],
        "duplexConfig":["AUTO_NEGOTIATE"],
        "adminStatus":["UP"],
        "portChannelId":["0"],
        "name":["TenGigabitEthernet1/0/1"],
        "operStatus":["DOWN"],
        "interfaceId":["3"],
        "duplexOper":["AutoNegotiate"]
    }],
    "StackPort_Template":
    [
        {
          "role": "",
          "portMode": "StackWise",
          "vlanId": null,
          "mediaType": "PROPVIRTUAL",
          "speed": null,
          "interfaceType": null,
          "portType": null,
          "isL3Interface": "false",
          "duplexConfig": null,
          "adminStatus": "UP",
          "portChannelId": null,
          "name": "StackPort 1/1",
          "operStatus": "UP",
          "interfaceId": "75",
          "duplexOper": null,
          "macAddr": null,
          "stackPortType": "BACKSTACK",
          "clientCount": 0
        },
        {
          "role": "",
          "portMode": "StackWise",
          "vlanId": null,
          "mediaType": "PROPVIRTUAL",
          "speed": null,
          "interfaceType": null,
          "portType": null,
          "isL3Interface": "false",
          "duplexConfig": null,
          "adminStatus": "DOWN",
          "portChannelId": null,
          "name": "StackPort 1/2",
          "operStatus": "DOWN",
          "interfaceId": "76",
          "duplexOper": null,
          "macAddr": null,
          "stackPortType": "BACKSTACK",
          "clientCount": 0
        },
        {
          "role": "",
          "portMode": "StackWise",
          "vlanId": null,
          "mediaType": "PROPVIRTUAL",
          "speed": null,
          "interfaceType": null,
          "portType": null,
          "isL3Interface": "false",
          "duplexConfig": null,
          "adminStatus": "DOWN",
          "portChannelId": null,
          "name": "StackPort 2/1",
          "operStatus": "DOWN",
          "interfaceId": "143",
          "duplexOper": null,
          "macAddr": null,
          "stackPortType": "BACKSTACK",
          "clientCount": 0
        },
        {
          "role": "",
          "portMode": "StackWise",
          "vlanId": null,
          "mediaType": "PROPVIRTUAL",
          "speed": null,
          "interfaceType": null,
          "portType": null,
          "isL3Interface": "false",
          "duplexConfig": null,
          "adminStatus": "UP",
          "portChannelId": null,
          "name": "StackPort 2/2",
          "operStatus": "UP",
          "interfaceId": "144",
          "duplexOper": null,
          "macAddr": null,
          "stackPortType": "BACKSTACK",
          "clientCount": 0
        }
      ],
      "C9500_Trunk_SVL_DAD":
      [
        {
          "role": "EtherChannel link to AD4-9300 L2 ",
          "portMode": "trunk",
          "vlanId": "196,197,211",
          "mediaType": "PROPVIRTUAL",
          "speed": "100000",
          "interfaceType": "Virtual",
          "portType": "Ethernet SVI",
          "isL3Interface": "false",
          "duplexConfig": "AUTO_NEGOTIATE",
          "adminStatus": "UP",
          "portChannelId": "1",
          "name": "Port-channel1",
          "operStatus": "DOWN",
          "interfaceId": "69",
          "duplexOper": "FullDuplex",
          "macAddr": null,
          "stackPortType": "NA",
          "clientCount": 0
        },
        {
            "role": "",
            "portMode": "SVL",
            "vlanId": "1",
            "mediaType": "ETHERNETCSMACD",
            "speed": "10000000",
            "interfaceType": "Physical",
            "portType": "Ethernet Port",
            "isL3Interface": "false",
            "duplexConfig": "AUTO_NEGOTIATE",
            "adminStatus": "UP",
            "portChannelId": null,
            "name": "TenGigabitEthernet1/0/15",
            "operStatus": "UP",
            "interfaceId": "23",
            "duplexOper": "FullDuplex",
            "macAddr": null,
            "stackPortType": "SVL",
            "clientCount": 0
        },
        {
            "role": "",
            "portMode": "SVL",
            "vlanId": "1",
            "mediaType": "ETHERNETCSMACD",
            "speed": "10000000",
            "interfaceType": "Physical",
            "portType": "Ethernet Port",
            "isL3Interface": "false",
            "duplexConfig": "AUTO_NEGOTIATE",
            "adminStatus": "UP",
            "portChannelId": null,
            "name": "TenGigabitEthernet1/0/16",
            "operStatus": "UP",
            "interfaceId": "24",
            "duplexOper": "FullDuplex",
            "macAddr": null,
            "stackPortType": "SVL",
            "clientCount": 0
        },
        {
            "role": "",
            "portMode": "SVL",
            "vlanId": "1",
            "mediaType": "ETHERNETCSMACD",
            "speed": "10000000",
            "interfaceType": "Physical",
            "portType": "Ethernet Port",
            "isL3Interface": "false",
            "duplexConfig": "AUTO_NEGOTIATE",
            "adminStatus": "UP",
            "portChannelId": null,
            "name": "TenGigabitEthernet2/0/15",
            "operStatus": "UP",
            "interfaceId": "52",
            "duplexOper": "FullDuplex",
            "macAddr": null,
            "stackPortType": "SVL",
            "clientCount": 0
        },
        {
            "role": "",
            "portMode": "SVL",
            "vlanId": "1",
            "mediaType": "ETHERNETCSMACD",
            "speed": "10000000",
            "interfaceType": "Physical",
            "portType": "Ethernet Port",
            "isL3Interface": "false",
            "duplexConfig": "AUTO_NEGOTIATE",
            "adminStatus": "UP",
            "portChannelId": null,
            "name": "TenGigabitEthernet2/0/16",
            "operStatus": "UP",
            "interfaceId": "53",
            "duplexOper": "FullDuplex",
            "macAddr": null,
            "stackPortType": "SVL",
            "clientCount": 0
        },
        {
            "role": "",
            "portMode": "DAD",
            "vlanId": "1",
            "mediaType": "ETHERNETCSMACD",
            "speed": "1000000",
            "interfaceType": "Physical",
            "portType": "Ethernet Port",
            "isL3Interface": "false",
            "duplexConfig": "AUTO_NEGOTIATE",
            "adminStatus": "UP",
            "portChannelId": null,
            "name": "TenGigabitEthernet1/0/13",
            "operStatus": "UP",
            "interfaceId": "21",
            "duplexOper": "AutoNegotiate",
            "macAddr": null,
            "stackPortType": "DAD",
            "clientCount": 0
        },
        {
            "role": "",
            "portMode": "DAD",
            "vlanId": "1",
            "mediaType": "ETHERNETCSMACD",
            "speed": "1000000",
            "interfaceType": "Physical",
            "portType": "Ethernet Port",
            "isL3Interface": "false",
            "duplexConfig": "AUTO_NEGOTIATE",
            "adminStatus": "UP",
            "portChannelId": null,
            "name": "TenGigabitEthernet2/0/13",
            "operStatus": "UP",
            "interfaceId": "50",
            "duplexOper": "AutoNegotiate",
            "macAddr": null,
            "stackPortType": "DAD",
            "clientCount": 0
        }
      ]
});