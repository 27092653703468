define({
"Template":
{
  "version": "1.0",
  "response": {
    "id": "AWXj7K67ARj6IWfUaZbl",
    "name": "fabric_wlc_reachability",
    "entityType": "network_device",
    "entity": "10.4.154.237",
    "enabled": true,
    "flattened": true,
    "groupBy": "Unknown",
    "category": "Connected",
    "severity": "HIGH",
    "summary": "Fabric WLC DeviceIPPlaceHolder Lost Connectivity to the Fabric Control Plane Node 10.4.30.30",
    "scope": "GLOBAL",
    "priority": "P1",
    "groupId": "fabric_reachability_grouping",
    "groupName": "Fabric WLC to MapServer Connectivity",
    "rootCause": "10.4.154.237_10.4.30.30_MapServer",
    "timestamp": 1537127263593,
    "description": "The Fabric WLC DeviceNamePlaceHolder has connectivity failure to the Fabric Control Plane Node p2-dist2.sda-pod2.local . This can prevent Fabric services and new wireless clients from functioning correctly.",
    "suggestions": [
      {
        "message": "Check the network connectivity to Control-Plane IP 10.4.30.30",
        "steps": [
          {
            "entityId": "DeviceIdPlaceHolder",
            "description": "Ping the Control-Plane node 10.4.30.30",
            "command": "ping 10.4.30.30",
            "stepType": "command-Runner"
          }
        ]
      },
      {
        "message": "Check if the Control-Plane IP 10.4.30.30 is configured correctly on WLC",
        "steps": [
          {
            "entityId": "DeviceIdPlaceHolder",
            "description": "Check the Control-Plane 10.4.30.30 configuration on WLC",
            "command": "show fabric map-server summary",
            "stepType": "command-Runner"
          },
          {
            "entityId": "DeviceIdPlaceHolder",
            "description": "Check the Control-Plane statistics",
            "command": "show fabric map-server detailed",
            "stepType": "command-Runner"
          }
        ]
      },
      {
        "message": "Verify the LISP configuration on the Control-Plane 10.4.30.30 to allow incoming TCP Connections from WLC",
        "steps": [
          {
            "entityId": "DeviceIdPlaceHolder",
            "description": "Check the LISP sessions on Control-Plane",
            "command": "show lisp session",
            "stepType": "command-Runner"
          },
          {
            "entityId": "DeviceIdPlaceHolder",
            "description": "Check the LISP configuration on Control-Plane",
            "command": "show run | sec lisp",
            "stepType": "command-Runner"
          }
        ]
      },
      {
        "message": "Initiate the Path Trace tool to check network path to Control-Plane node 10.4.30.30",
        "steps": [

        ]
      },
      {
        "message": "Contact Cisco TAC World Wide – https://www.cisco.com/c/en/us/support/web/tsd-cisco-worldwide-contacts.html",
        "steps": [

        ]
      }
    ],
    "additionalParams": [
      {
        "key": "destIp",
        "value": "10.4.30.30"
      },
      {
        "key": "deviceType",
        "value": "ENTERPRISE"
      },
      {
        "key": "managementIpAddr",
        "value": "10.4.154.237"
      },
      {
        "key": "entityType",
        "value": "network_device"
      },
      {
        "key": "__entity_type__",
        "value": "NetworkDevice"
      },
      {
        "key": "entityId",
        "value": "10.4.154.237"
      },
      {
        "key": "platformId",
        "value": "AIR-CT5520-K9"
      },
      {
        "key": "type",
        "value": "issue"
      },
      {
        "key": "uuid",
        "value": ""
      },
      {
        "key": "sourceRole",
        "value": "WLC"
      },
      {
        "key": "sessionIdentifier",
        "value": "10.4.154.237_10.4.30.30_MapServer"
      },
      {
        "key": "sourceIp",
        "value": "10.4.154.237"
      },
      {
        "key": "scope",
        "value": "global"
      },
      {
        "key": "rootCause",
        "value": "10.4.154.237_10.4.30.30_MapServer"
      },
      {
        "key": "sessionType",
        "value": "MapServer"
      },
      {
        "key": "redundancy",
        "value": ""
      },
      {
        "key": "category",
        "value": "connected"
      },
      {
        "key": "timestring",
        "value": "2018-09-16T19:47:43.593+0000"
      },
      {
        "key": "status",
        "value": "active"
      }
    ],
    "status": {
      "status": "ACTIVE",
      "updatedBy": "Unknown",
      "notes": "Unknown",
      "source": "Unknown",
      "updatedAt": null,
      "ignoreSource": "Unknown",
      "ignoreValue": "Unknown",
      "ignoreStartTime": null,
      "ignoreEndTime": null
    },
    "siteQulaifiedName": null
  }
}
})