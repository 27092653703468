define([
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/task/TaskTemplate',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/utilities/UtilityFunctions',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/database/SimLokiDatabaseActions',

], function (TaskTemplate, UtilityFunctions, SimLokiDatabaseActions) {

  var taskId_mapping = {};

  function updateTaskDetails(taskObj,finalResult)
  {
    if(finalResult.errorCode){
       taskObj.errorCode = finalResult.errorCode;
    }
    if(finalResult.message){
        taskObj.message = finalResult.message;
     }
    if(finalResult.detail){
        taskObj.detail = finalResult.detail;
    }
    if(finalResult.progress){
        taskObj.progress = finalResult.progress;
    }
    if(finalResult.rootId){
        taskObj.rootId = finalResult.rootId;
    }
    if(finalResult.data){
        taskObj.data = finalResult.data;
    }
  }



  return {

      init: function() {
          taskId_mapping = new Object();
      },

      createTask: function(serviceType, options){
          var taskId    = UtilityFunctions.generate_uuid();
          var startTime = UtilityFunctions.getTimeStamp();
          taskId_mapping[taskId] = {startTime : startTime, serviceType: serviceType, completed: false};

          if(options != undefined){
              taskId_mapping[taskId].options = options;
          }

          var tJson = JSON.parse(JSON.stringify(TaskTemplate.TASK_START_MSG));
          tJson.taskId = taskId;
          tJson.url = tJson.url + taskId;
          return tJson;
      },

     createChildTask: function(serviceType, parentId){
          var taskId    = UtilityFunctions.generate_uuid();
          var startTime = UtilityFunctions.getTimeStamp();
          taskId_mapping[taskId] = {startTime : startTime, serviceType: serviceType, completed: false,rootId: parentId,parentId: parentId};
          var tJson = JSON.parse(JSON.stringify(TaskTemplate.TASK_START_MSG));
          tJson.taskId = taskId;
          tJson.url = tJson.url + taskId;
          return tJson;
      },

      getTaskStatus: function(urlAction){
          var url = urlAction.url;
          var arr = url.split('/');
          var taskId = "";
          var isTree = false;

          if(urlAction.filter["parentId"]) {
            //api/v1/task?parentId=eabfbf7b-44b4-4150-8f5c-f57b286a156d&sortBy=startTime&order=asc
            var parentId = urlAction.filter["parentId"];
            var tJson = this.getSwimTaskStatus(parentId);
            return tJson;
          }else if(urlAction.filter['data'] == "TELEMETRY_SUBSCRIPTION-AppName:ROGUE" || urlAction.filter['data'] == "TELEMETRY_SUBSCRIPTION-AppName:AWIPS"){
            var tJson = JSON.parse(JSON.stringify(TaskTemplate.ROGUE_AWIPS_TASK[0]));
            if(urlAction.filter['data'] == "TELEMETRY_SUBSCRIPTION-AppName:AWIPS"){
                tJson.rootId = UtilityFunctions.generate_uuid();
                tJson.id = tJson.rootId; 
            }
            tJson.data = urlAction.filter['data']
            return [tJson]
          } 
          else {
            taskId = arr[4];
          }

          if(taskId.indexOf("?")>=0) {
             taskId = taskId.substr(0, taskId.indexOf("?"));
          }

          for(var i = 4; i < arr.length; i++){
              if(arr[i] == 'tree'){
                  isTree = true;
              }
          }
          // For Rogue Management 
          if(taskId == '9c0a0720-332d-4e29-9ac2-7bfb31a3bbc8'){
            var tJson = JSON.parse(JSON.stringify(TaskTemplate.ROGUE_AWIPS_TASK));
            var apDevice=SimLokiDatabaseActions.getFilteredRecordHandler('network-device',{name:'PLS06-AP3800-01'});
            tJson[1].data = '{"Operation":"CREATE","DeviceUuid":"'+apDevice[0].id+'"'+',"DeviceIp":'+'"'+apDevice[0].managementIpAddress+'"'+'}';
            return tJson
          }

          if(taskId == "6b7d1947-f69d-4a55-a8f1-8dc5f051d5e9"){
            return JSON.parse(JSON.stringify(TaskTemplate.CMX_STATUS_TASK_RESPONSE));
          } else if (taskId == "3495b396-002d-4f85-9c0b-87bc7a5154ac"){
            return JSON.parse(JSON.stringify(TaskTemplate.CMX_INFO_TASK_RESPONSE));
          }

          /* if(taskId == undefined || taskId == "undefined") {
              return TaskTemplate.UNDEFINED_TASKID;
          } */

          if(taskId_mapping[taskId] != undefined) {
              var result = this.getTaskResponse(taskId, isTree);
              if(isTree)
              {//If its tree, find the children
                var childObj = this.getTaskResponse(taskId, isTree);
                   if(childObj!=undefined && childObj.length >0)
                   {
                     result.push(childObj[0]);
                   }
                for(var index in taskId_mapping)
                {
                  if(taskId_mapping[index].parentId != undefined && taskId_mapping[index].parentId == taskId)
                  {
                   var childObj = this.getTaskResponse(index, isTree);
                   if(childObj!=undefined && childObj.length >0)
                   {
                     result.push(childObj[0]);
                   }
                   return result;
                  }
                }
              }
              return result;
          } else {
              var tJson = JSON.parse(JSON.stringify(TaskTemplate.TASK_POLL_MSG));
              tJson.endTime = UtilityFunctions.getTimeStamp();
              tJson.completed = true;
              return isTree ? [tJson] : tJson;
          }
      },

      updateTaskCompletion: function(taskId, finalResult){
          if( taskId_mapping[taskId] != undefined){

             taskId_mapping[taskId].isError = finalResult.isError;

             taskId_mapping[taskId].completed = true;
             taskId_mapping[taskId].endTime  = UtilityFunctions.getTimeStamp();
             if(finalResult.isError){
                 taskId_mapping[taskId].failureReason = finalResult.failureReason;
             }
             updateTaskDetails(taskId_mapping[taskId],finalResult);
          }
      },

      getTaskResponse: function(taskId, isTree) {
          var tJson;
          if(taskId_mapping[taskId].serviceType == 'SPFService') {
            tJson = JSON.parse(JSON.stringify(TaskTemplate.TASK_POLL_CFS_MSG));
            if(taskId_mapping[taskId].options != undefined && 
              taskId_mapping[taskId].options.currentDelayCount != undefined && 
              taskId_mapping[taskId].options.maxDelayNoOfCalls != undefined) {
                if(taskId_mapping[taskId].options.currentDelayCount <  taskId_mapping[taskId].options.maxDelayNoOfCalls) {
                    delete tJson.endTime;
                    delete taskId_mapping[taskId].endTime;
                    taskId_mapping[taskId].options.currentDelayCount++;
                } else {
                    taskId_mapping[taskId].endTime = UtilityFunctions.getTimeStamp();
                    tJson.completed = true;
                }
            }
          } else if(taskId_mapping[taskId].serviceType == 'IMGService') {
              tJson = JSON.parse(JSON.stringify(TaskTemplate.TASK_POLL_CFS_MSG));
          } 
          else if(taskId_mapping[taskId].serviceType == 'Grouping Service'){
             tJson = JSON.parse(JSON.stringify(TaskTemplate.TASK_POOL_GROUPING_MSG));
             if(taskId_mapping[taskId].options){
               tJson.data = taskId_mapping[taskId].options.id;
             }
          }
          else if(taskId_mapping[taskId].serviceType == 'Ipam Service'){
             tJson = JSON.parse(JSON.stringify(TaskTemplate.TASK_POOL_IPAM_MSG));
             if(taskId_mapping[taskId].options){
               tJson.progress = taskId_mapping[taskId].options.id;
              }
          }
          else if (taskId_mapping[taskId].serviceType == 'SPService') {
             tJson = JSON.parse(JSON.stringify(TaskTemplate.SPService_MSG));
             tJson.version = taskId_mapping[taskId].startTime;
             tJson.progress = "Network Profile [" + taskId_mapping[taskId].options.siteId + "] Successfully Created";
             if(taskId_mapping[taskId].options.progress){
                 tJson.progress = taskId_mapping[taskId].options.progress; //used for nfv profiling
             }
          }
          else if (taskId_mapping[taskId].serviceType == 'Common Settings Service') {
             tJson = JSON.parse(JSON.stringify(TaskTemplate.TASK_COMM_SETTING_MSG));
          }
          else if(taskId_mapping[taskId].serviceType == 'DNA Maps Service'){
             tJson = JSON.parse(JSON.stringify(TaskTemplate.TASK_DNA_MAPS_MSG));
             if(taskId_mapping[taskId].options){
               tJson.data = taskId_mapping[taskId].options.groupId;
               tJson.id = taskId_mapping[taskId].options.taskId;
               tJson.rootId = taskId_mapping[taskId].options.taskId;
             }
           }
          else if(taskId_mapping[taskId].serviceType == 'Inventory service'){
           tJson = JSON.parse(JSON.stringify(TaskTemplate.TASK_INVENTORY_MSG));
           tJson.version = taskId_mapping[taskId].startTime;
           if(taskId_mapping[taskId].options){
           tJson.endTime = taskId_mapping[taskId].startTime + 20000;//20s added
           tJson.id = taskId;
           if(taskId_mapping[taskId].options.data || taskId_mapping[taskId].options.progress) {
               //for discovery
                tJson.progress = taskId_mapping[taskId].options.progress;
                tJson.data = taskId_mapping[taskId].options.data;
           } else {
                tJson.progress = "Device exported successfully and the file id is: "+taskId_mapping[taskId].options.id;
                tJson["additionalStatusURL"] = "/api/v1/file/"+taskId_mapping[taskId].options.id;
            }
           }
           updateTaskDetails(tJson,taskId_mapping[taskId]);
           }
          else if(taskId_mapping[taskId].serviceType == "Command Runner Service"){
            tJson = JSON.parse(JSON.stringify(TaskTemplate.TASK_ASSURANCE_MSG));
            updateTaskDetails(tJson,taskId_mapping[taskId]);
          }
          else if(taskId_mapping[taskId].serviceType == "Tag Service"){
            tJson = JSON.parse(JSON.stringify(TaskTemplate.TASK_TAG));
            if(taskId_mapping[taskId].options){
                if(taskId_mapping[taskId].options.data == null) {
                    delete tJson.data;
                } else {
                    tJson.data = taskId_mapping[taskId].options.data;
                }
                tJson.id = taskId;
                tJson.rootId = taskId;
                tJson.version = taskId_mapping[taskId].startTime;
                if(taskId_mapping[taskId].options.progress) {
                    tJson.progress = taskId_mapping[taskId].options.progress;
                }
                //to check if tJson.operationIdList needs to be updated
              }
          } else if(taskId_mapping[taskId].serviceType == "Scheduler Service") {
            tJson = JSON.parse(JSON.stringify(TaskTemplate.TASK_SCHEDULER));
            if(taskId_mapping[taskId].options){
                var url = "";
                if(taskId_mapping[taskId].options.action == "distribute") {
                    url = "/api/v1/image/distribution";
                } else {
                    url = "/api/v1/image/activation/device";
                }
                tJson.data.replace('{}', url);
                tJson.endTime = taskId_mapping[taskId].startTime + 20000;//20s added
                tJson.version = taskId_mapping[taskId].startTime;
                tJson.progress.replace('{}', taskId);
            }
         } else if(taskId_mapping[taskId].serviceType == "Swim Service") {

            tJson = JSON.parse(JSON.stringify(TaskTemplate.TASK_UPGRADE_READINESS));
            tJson.id = taskId;
            tJson.rootId = taskId;
            updateTaskDetails(tJson,taskId_mapping[taskId]);
         } else if(taskId_mapping[taskId].serviceType == "Onboarding Service") {
            tJson = JSON.parse(JSON.stringify(TaskTemplate.TASK_ONBOARDING_DAYZERO));
         } else if(taskId_mapping[taskId].serviceType == "TemplateService") {
            tJson = JSON.parse(JSON.stringify(TaskTemplate.TASK_TEMPLATE_EDITOR));
            if(taskId_mapping[taskId].options) {
                if(taskId_mapping[taskId].options.id) {
                    tJson.data = taskId_mapping[taskId].options.id;
                } else if(taskId_mapping[taskId].options.data) {
                    tJson.data = taskId_mapping[taskId].options.data;
                }
                if(taskId_mapping[taskId].options.progress) tJson.progress = taskId_mapping[taskId].options.progress;
            }
         }else if(taskId_mapping[taskId].serviceType == "NCSS"){
            tJson = JSON.parse(JSON.stringify(TaskTemplate.TASK_ETA_SCHEDULER)); //StealthWatch
            tJson.progress =tJson.progress.replace('{}', taskId);
         }else if(taskId_mapping[taskId].serviceType == "NCTO"){
            tJson = JSON.parse(JSON.stringify(TaskTemplate.TASK_ETA_SCHEDULER)); //Topology
            tJson.progress ="c466b059-c898-43cb-92a3-1526240896c9";
            tJson.serviceType = "NCTO"
            if(taskId_mapping[taskId].options && taskId_mapping[taskId].options.progress) {
                tJson.progress = taskId_mapping[taskId].options.progress;
            }
         } else if(taskId_mapping[taskId].serviceType == "TASK_DeviceInterfaceInfoContainer_MSG") {
            if(taskId_mapping[taskId].options) {
                tJson = JSON.parse(JSON.stringify(TaskTemplate.TASK_DeviceInterfaceInfoContainer_MSG))
                tJson.data = tJson.data.replace('{}',taskId_mapping[taskId].options.wrkFlwId);
                tJson.data = tJson.data.replace('{}',taskId_mapping[taskId].options.cfsId);
            }
         } else if(taskId_mapping[taskId].serviceType == "Device Replacement Service") {
            tJson = JSON.parse(JSON.stringify(TaskTemplate.MARK_FOR_REPLACEMENT));
            if(taskId_mapping[taskId].options && taskId_mapping[taskId].options.progress) {
                tJson.progress = taskId_mapping[taskId].options.progress;
            }
         } else if(taskId_mapping[taskId].serviceType == "SDG Service") {
            tJson = JSON.parse(JSON.stringify(TaskTemplate.BONJOUR));
            if(taskId_mapping[taskId].options && taskId_mapping[taskId].options.data) {
                tJson.data = taskId_mapping[taskId].options.data;
            }
         } else if(taskId_mapping[taskId].serviceType == "NCCS") {

            tJson = JSON.parse(JSON.stringify(TaskTemplate.NCCS));

         }  else if(taskId_mapping[taskId].serviceType == "Swim Dummy"){
            //Special case where isError needs to be false and failureReason needs to be present
            tJson = JSON.parse(JSON.stringify(TaskTemplate.DUMMY_SIM_OS_FAILURE));

         } else if(taskId_mapping[taskId].serviceType == "MLCN Service"){
            tJson = JSON.parse(JSON.stringify(TaskTemplate.MLCN));
            //var progressId = UtilityFunctions.generate_uuid;
            tJson.progress = tJson.progress.replace('{}',taskId_mapping[taskId].options.id);
         } else if(taskId_mapping[taskId].serviceType == "NCIM"){
            tJson = JSON.parse(JSON.stringify(TaskTemplate.INTERFACE_TASK_NCIM));
         }

          /* else if(taskId_mapping[taskId].serviceType == "IMGActivateService"){
            tJson = this.getSwimTaskStatus(taskId);
            return tJson;
          } */
          else {
            tJson = JSON.parse(JSON.stringify(TaskTemplate.TASK_POLL_MSG));
           }


          tJson.startTime  = taskId_mapping[taskId].startTime;
          if(taskId_mapping[taskId].endTime != undefined) {
              tJson.endTime = taskId_mapping[taskId].endTime;
              tJson.completed = true;
           }
          if(taskId_mapping[taskId].isError) {
              tJson.isError = true;
              tJson.failureReason = taskId_mapping[taskId].failureReason;
          }

          if(taskId_mapping[taskId].errorCode) {
              tJson.errorCode = taskId_mapping[taskId].errorCode;
          }
          tJson.rootId   = taskId;
          tJson.id       = taskId;
          return isTree ? [tJson] : tJson;
      },

      getSwimTaskStatus: function(deviceTaskUuid) {
        var tJson = [];
        var records = SimLokiDatabaseActions.getFilteredRecordHandler('upgrade-os-list', {'parentId': deviceTaskUuid});
        if(records && records.length>0) {
            tJson = JSON.parse(JSON.stringify(records[0].result));
            if(tJson.length>1 && tJson[1].endTime > UtilityFunctions.getTimeStamp()) {
                tJson[1].endTime = UtilityFunctions.getTimeStamp();//fix for NaN
                tJson[1].progress = 'Activation of image is in progress..'; //not sure of the exact response
            }
            if(tJson[0].endTime > UtilityFunctions.getTimeStamp()) {
                tJson[0].endTime = UtilityFunctions.getTimeStamp();//fix for NaN
                tJson[0].progress = 'Distribution of image is in progress..'; //not sure of the exact response
                tJson.splice(1,1);
            }            
        }
        return tJson;
    },

    /* this is a temporary code written for OS upgrade failure scenario handling. This will be removed in the next release.
    PLEASE DONT USE THIS */
    createTaskWithTaskId: function(taskId) {
        var tJson = taskId_mapping[taskId];
        if(tJson == undefined) {
            var startTime = UtilityFunctions.getTimeStamp();
            taskId_mapping[taskId] = {startTime : startTime, serviceType: "Swim Dummy", completed: false};
            var tJson = JSON.parse(JSON.stringify(TaskTemplate.TASK_START_MSG));
            tJson.taskId = taskId;
            tJson.url = tJson.url + taskId;
        }
        return tJson;
    }

  };
});
