define({
  "Template": {
    "code": 4,
    "type": "ok",
    "message": "overall Network and Client Health update to 40.0"
  },

  "SITE_THRESHOLD_NETWORK_TEMPLATE" : {
    "_id": null,
    "siteName": "global",
    "siteId": "global",
    "ctTenantId": null,
    "type": "DEFAULT",
    "metricName": "overallNetworkHealth",
    "ndpNameSpace": "overallNetworkHealth",
    "category": "Network Health",
    "displayKey": "ct_11_overall_network_health",
    "description": "Overall Network Health",
    "tValue": 40,
    "tDefaultValue": 40,
    "tOperator": ">",
    "tUnit": "%",
    "tMin": 0,
    "tMinRecommended": 10,
    "tMaxRecommended": 99,
    "tMax": 100,
    "version": "1.0",
    "tenantId": null,
    "lastUpdated": null
  },

  "SITE_THRESHOLD_CLIENT_TEMPLATE" : {
    "_id": null,
    "siteName": "global",
    "siteId": "global",
    "ctTenantId": null,
    "type": "DEFAULT",
    "metricName": "clientHealth",
    "ndpNameSpace": "clientHealth",
    "category": "Client Health",
    "displayKey": "ct_21_client_health",
    "description": "Wired/Wireless Client Health",
    "tValue": 40,
    "tDefaultValue": 40,
    "tOperator": ">",
    "tUnit": "%",
    "tMin": 0,
    "tMinRecommended": 10,
    "tMaxRecommended": 99,
    "tMax": 100,
    "version": "1.0",
    "tenantId": null,
    "lastUpdated": null
  }
})