define({
    "Template":
    {
        "request": {
          "key": "dns",
          "type": "Application",
          "label": "dns",
          "collection": false,
          "componentContent": [
            {
              "component": "fusion",
              "additional": "010bfb04-4c10-498e-8c2b-ef7d40c0d216"
            }
          ]
        },
        "data": {
          "response": {
            "id": "010bfb04-4c10-498e-8c2b-ef7d40c0d216",
            "instanceId": 4382,
            "authEntityId": 4382,
            "displayName": "4382",
            "authEntityClass": -1909073334,
            "instanceTenantId": "SYS0",
            "deployPending": "NONE",
            "instanceVersion": 0,
            "createTime": 1528265512023,
            "deployed": false,
            "isSeeded": true,
            "isStale": false,
            "lastUpdateTime": 1528265512023,
            "name": "dns",
            "namespace": "scalablegroup:application",
            "provisioningState": "DEFINED",
            "qualifier": "application",
            "resourceVersion": 0,
            "targetIdList": [],
            "type": "scalablegroup",
            "cfsChangeInfo": [],
            "customProvisions": [],
            "scalableGroupExternalHandle": "dns",
            "scalableGroupType": "APPLICATION",
            "securityGroupTag": 0,
            "state": "ACTIVE",
            "vnAgnostic": true,
            "identitySource": {
              "id": "607e5f6a-d4bd-41e6-9861-bcd939c316b6",
              "instanceId": 10010,
              "authEntityId": 10010,
              "displayName": "10010",
              "authEntityClass": 1738953278,
              "instanceTenantId": "SYS0",
              "deployPending": "NONE",
              "instanceVersion": 0,
              "state": "INACTIVE",
              "type": "NBAR"
            },
            "indicativeNetworkIdentity": [
              {
                "id": "3691b5cd-0b47-41b8-b312-c6d86737199f",
                "instanceId": 12936,
                "authEntityId": 12936,
                "displayName": "12936",
                "authEntityClass": 124090504,
                "instanceTenantId": "SYS0",
                "deployPending": "NONE",
                "instanceVersion": 0,
                "ipv4Subnet": [],
                "ipv6Subnet": [],
                "lowerPort": 0,
                "ports": "53,5353",
                "protocol": "UDP",
                "upperPort": 0
              },
              {
                "id": "2b0bbca1-4213-4301-8032-348dc350d897",
                "instanceId": 12937,
                "authEntityId": 12937,
                "displayName": "12937",
                "authEntityClass": 124090504,
                "instanceTenantId": "SYS0",
                "deployPending": "NONE",
                "instanceVersion": 0,
                "ipv4Subnet": [],
                "ipv6Subnet": [],
                "lowerPort": 0,
                "ports": "53,5353",
                "protocol": "TCP",
                "upperPort": 0
              }
            ],
            "networkApplications": [
              {
                "id": "440822be-dbbe-425d-9349-2b96f36ac813",
                "instanceId": 11332,
                "authEntityId": 11332,
                "displayName": "11332",
                "authEntityClass": -217092956,
                "instanceTenantId": "SYS0",
                "deployPending": "NONE",
                "instanceVersion": 0,
                "appProtocol": "tcp/udp",
                "applicationGroup": "other",
                "applicationType": "DEFAULT",
                "categoryId": "211a6ea4-35b5-4cd7-a0dc-af56b821e903",
                "createTime": 1528265512023,
                "encrypted": "false",
                "engineId": "3",
                "helpString": "Domain Name Server lookup",
                "ignoreConflict": false,
                "lastUpdateTime": 1528265512023,
                "longDescription": "Domain Name Server (DNS) is a server that translates URLs into IP addresses based on client queries. It is based on client-server architecture.",
                "name": "dns",
                "nbarId": "72",
                "p2pTechnology": "false",
                "popularity": 10,
                "rank": 65535,
                "references": "https://www1.ietf.org/rfc/rfc1035.txt",
                "selectorId": "53",
                "subCategory": "9f623279-7abc-4697-bd15-27a015b345f5",
                "trafficClass": "OPS_ADMIN_MGMT",
                "tunnel": "false"
              }
            ],
            "networkIdentity": [
              {
                "id": "791d6339-338e-48c2-a5cc-47c3cb83df02",
                "instanceId": 12934,
                "authEntityId": 12934,
                "displayName": "12934",
                "authEntityClass": 124090504,
                "instanceTenantId": "SYS0",
                "deployPending": "NONE",
                "instanceVersion": 0,
                "ipv4Subnet": [],
                "ipv6Subnet": [],
                "lowerPort": 0,
                "ports": "53,80,5353",
                "protocol": "TCP",
                "upperPort": 0
              },
              {
                "id": "325c8cc5-8042-45f5-9a1f-745d5dfca933",
                "instanceId": 12935,
                "authEntityId": 12935,
                "displayName": "12935",
                "authEntityClass": 124090504,
                "instanceTenantId": "SYS0",
                "deployPending": "NONE",
                "instanceVersion": 0,
                "ipv4Subnet": [],
                "ipv6Subnet": [],
                "lowerPort": 0,
                "ports": "53,5353",
                "protocol": "UDP",
                "upperPort": 0
              }
            ],
            "parentScalableGroup": {
              "idRef": "7b4bcd20-3593-44e7-bd8c-1218a8548311"
            },
            "user": [],
            "userGroup": []
          }
        },
        "result": [
          {
            "bookID": {
              "namespace": "fusion",
              "name": "core",
              "version": "1.0.1"
            },
            "response": {
              "id": "010bfb04-4c10-498e-8c2b-ef7d40c0d216",
              "instanceId": 4382,
              "authEntityId": 4382,
              "displayName": "4382",
              "authEntityClass": -1909073334,
              "instanceTenantId": "SYS0",
              "deployPending": "NONE",
              "instanceVersion": 0,
              "createTime": 1528265512023,
              "deployed": false,
              "isSeeded": true,
              "isStale": false,
              "lastUpdateTime": 1528265512023,
              "name": "dns",
              "namespace": "scalablegroup:application",
              "provisioningState": "DEFINED",
              "qualifier": "application",
              "resourceVersion": 0,
              "targetIdList": [],
              "type": "scalablegroup",
              "cfsChangeInfo": [],
              "customProvisions": [],
              "scalableGroupExternalHandle": "dns",
              "scalableGroupType": "APPLICATION",
              "securityGroupTag": 0,
              "state": "ACTIVE",
              "vnAgnostic": true,
              "identitySource": {
                "id": "607e5f6a-d4bd-41e6-9861-bcd939c316b6",
                "instanceId": 10010,
                "authEntityId": 10010,
                "displayName": "10010",
                "authEntityClass": 1738953278,
                "instanceTenantId": "SYS0",
                "deployPending": "NONE",
                "instanceVersion": 0,
                "state": "INACTIVE",
                "type": "NBAR"
              },
              "indicativeNetworkIdentity": [
                {
                  "id": "3691b5cd-0b47-41b8-b312-c6d86737199f",
                  "instanceId": 12936,
                  "authEntityId": 12936,
                  "displayName": "12936",
                  "authEntityClass": 124090504,
                  "instanceTenantId": "SYS0",
                  "deployPending": "NONE",
                  "instanceVersion": 0,
                  "ipv4Subnet": [],
                  "ipv6Subnet": [],
                  "lowerPort": 0,
                  "ports": "53,5353",
                  "protocol": "UDP",
                  "upperPort": 0
                },
                {
                  "id": "2b0bbca1-4213-4301-8032-348dc350d897",
                  "instanceId": 12937,
                  "authEntityId": 12937,
                  "displayName": "12937",
                  "authEntityClass": 124090504,
                  "instanceTenantId": "SYS0",
                  "deployPending": "NONE",
                  "instanceVersion": 0,
                  "ipv4Subnet": [],
                  "ipv6Subnet": [],
                  "lowerPort": 0,
                  "ports": "53,5353",
                  "protocol": "TCP",
                  "upperPort": 0
                }
              ],
              "networkApplications": [
                {
                  "id": "440822be-dbbe-425d-9349-2b96f36ac813",
                  "instanceId": 11332,
                  "authEntityId": 11332,
                  "displayName": "11332",
                  "authEntityClass": -217092956,
                  "instanceTenantId": "SYS0",
                  "deployPending": "NONE",
                  "instanceVersion": 0,
                  "appProtocol": "tcp/udp",
                  "applicationGroup": "other",
                  "applicationType": "DEFAULT",
                  "categoryId": "211a6ea4-35b5-4cd7-a0dc-af56b821e903",
                  "createTime": 1528265512023,
                  "encrypted": "false",
                  "engineId": "3",
                  "helpString": "Domain Name Server lookup",
                  "ignoreConflict": false,
                  "lastUpdateTime": 1528265512023,
                  "longDescription": "Domain Name Server (DNS) is a server that translates URLs into IP addresses based on client queries. It is based on client-server architecture.",
                  "name": "dns",
                  "nbarId": "72",
                  "p2pTechnology": "false",
                  "popularity": 10,
                  "rank": 65535,
                  "references": "https://www1.ietf.org/rfc/rfc1035.txt",
                  "selectorId": "53",
                  "subCategory": "9f623279-7abc-4697-bd15-27a015b345f5",
                  "trafficClass": "OPS_ADMIN_MGMT",
                  "tunnel": "false"
                }
              ],
              "networkIdentity": [
                {
                  "id": "791d6339-338e-48c2-a5cc-47c3cb83df02",
                  "instanceId": 12934,
                  "authEntityId": 12934,
                  "displayName": "12934",
                  "authEntityClass": 124090504,
                  "instanceTenantId": "SYS0",
                  "deployPending": "NONE",
                  "instanceVersion": 0,
                  "ipv4Subnet": [],
                  "ipv6Subnet": [],
                  "lowerPort": 0,
                  "ports": "53,80,5353",
                  "protocol": "TCP",
                  "upperPort": 0
                },
                {
                  "id": "325c8cc5-8042-45f5-9a1f-745d5dfca933",
                  "instanceId": 12935,
                  "authEntityId": 12935,
                  "displayName": "12935",
                  "authEntityClass": 124090504,
                  "instanceTenantId": "SYS0",
                  "deployPending": "NONE",
                  "instanceVersion": 0,
                  "ipv4Subnet": [],
                  "ipv6Subnet": [],
                  "lowerPort": 0,
                  "ports": "53,5353",
                  "protocol": "UDP",
                  "upperPort": 0
                }
              ],
              "parentScalableGroup": {
                "idRef": "7b4bcd20-3593-44e7-bd8c-1218a8548311"
              },
              "user": [],
              "userGroup": []
            }
          }
        ]
      }
    });