define([
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/database/SimLokiDatabaseActions',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/design/ServerTemplate',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/design/CommonSettingKeyTemplate',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/data-generation/configuration/SimTaskDataGenerator',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/design/AAATemplate',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/design/PSNServerTemplate',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/utilities/UtilityFunctions',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/utilities/SimulationUtility',
     'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/data-generation/configuration/DefaultConfig'
], function(SimLokiDatabaseActions, ServerTemplate, CommonSettingKeyTemplate, SimTaskDataGenerator,
            AAATemplate, PSNServerTemplate, UtilityFunctions, SimulationUtility, DefaultConfig){

 function createDefaultServers() {
      var tJsonArr = JSON.parse(JSON.stringify( ServerTemplate.Server_Template ));
      SimLokiDatabaseActions.insert( 'servers', tJsonArr );
 }

  function createCommonSettingKey() {
      var tJsonArr = JSON.parse(JSON.stringify( CommonSettingKeyTemplate.KEY_Template ));
      for(var i = 0, len = tJsonArr.length; i < len; i++){
          SimLokiDatabaseActions.insert( 'common-setting-key', tJsonArr[i] );
      }
      SimLokiDatabaseActions.getAll('servers');
  }

  function createAaa() {
        var tJsonArr = JSON.parse(JSON.stringify( AAATemplate.AAA_Template_1_3_1 ));
      for(var i = 0, len = tJsonArr.length; i < len; i++){
          SimLokiDatabaseActions.insert( 'aaa', tJsonArr[i] );
      }
  }

  function createPSNServer() {
        var tJsonArr = JSON.parse(JSON.stringify( PSNServerTemplate.PSN_Server_Template ));
      for(var i = 0, len = tJsonArr.length; i < len; i++){
          SimLokiDatabaseActions.insert( 'psn-server', tJsonArr[i] );
      }
  }

  function updateHierarchyInfo(siteObj,settingDetails){

      //If siteObj is undefined, it means this serverDetail is the originator of commonsetting change. So the inherited details will be empty
      if(siteObj==undefined)
         {
             settingDetails.inheritedGroupName = "";
             settingDetails.inheritedGroupUuid = "";
         }
         else if(siteObj.length>0)
         {
            ////If siteObj is defined, it means this serverDetail is not the originator of commonsetting change. So the parents obj will be used to set the inherited Group name. Its minimum possiblity is /Global/SanJose, where length is 2
            settingDetails.inheritedGroupName = siteObj[0].name
            settingDetails.inheritedGroupUuid = siteObj[0].id;
         }
  }
//Function to Compare the commonsetting values of the type handled in this IF case
  function compareCommonSetting(sourceObj,targetObj){
     var returnValue;
     if(sourceObj.type == "pan.setting" || sourceObj.type == "banner.setting" || sourceObj.type == "timezone.setting" || sourceObj.type == "ip.address" )
      {
        var panCount=0;
        for(var sourceIndex in sourceObj.value)
        {
          for(var targetIndex in targetObj.value)
          {
           if(sourceObj.value[sourceIndex] == targetObj.value[targetIndex])
           {
             panCount++;
             break;
           }
         }
        }
        if(panCount == sourceObj.value.length)
        {
           returnValue = true;
        }
        else
        {
           returnValue = false;
        }
      }
       return returnValue;
  }

  //Function to Compare the AAA setting values.Their properties are different and so check done to compare the properties
  function compareAAASetting(sourceObj,targetObj){
     var returnValue;
     if(sourceObj.type == "aaa.setting")
      {
        var aaaCount=0;
        for(var sourceIndex in sourceObj.value)
        {
          for(var targetIndex in targetObj.value)
          {
           if(sourceObj.value[sourceIndex].ipAddress == targetObj.value[targetIndex].ipAddress  && sourceObj.value[sourceIndex].sharedSecret == targetObj.value[targetIndex].sharedSecret && targetObj.value[targetIndex].protocol == sourceObj.value[sourceIndex].protocol)
           {
             aaaCount++;
             break;
           }
         }
        }
        if(aaaCount == sourceObj.value.length)
        {
           returnValue = true;
        }
        else
        {
           returnValue = false;
        }
      }
      return returnValue;
  }

//Function to Compare the Netflow setting values. Their properties are different and so check is done to compare the properties
  function compareNetflowSetting(sourceObj,targetObj){
     var returnValue;
     if(sourceObj.type == "netflow.setting")
      {
        var netflowCount=0;
        for(var sourceIndex in sourceObj.value)
        {
          for(var targetIndex in targetObj.value)
          {
           if(sourceObj.value[sourceIndex].ipAddress == targetObj.value[targetIndex].ipAddress  && sourceObj.value[sourceIndex].port == targetObj.value[targetIndex].port)
           {
             netflowCount++;
             break;
           }
         }
        }
        if(netflowCount == sourceObj.value.length)
        {
           returnValue = true;
        }
        else
        {
           returnValue = false;
        }
      }
      return returnValue;
  }

//Function to Compare the DNS setting values. Their properties are different and so check is done to compare the properties
  function compareDNSSetting(sourceObj,targetObj){
     var returnValue;
     if(sourceObj.type == "dns.setting")
      {
        var dnsCount=0;
        for(var sourceIndex in sourceObj.value)
        {
          for(var targetIndex in targetObj.value)
          {//Secondary Ip address is optional. SO checking for undefined
           if(sourceObj.value[sourceIndex].domainName == targetObj.value[targetIndex].domainName  && sourceObj.value[sourceIndex].primaryIpAddress == targetObj.value[targetIndex].primaryIpAddress &&
            ((sourceObj.value[sourceIndex].secondaryIpAddress == undefined || sourceObj.value[sourceIndex].secondaryIpAddress == targetObj.value[targetIndex].secondaryIpAddress) || sourceObj.value[sourceIndex].secondaryIpAddress == undefined ))
           {
             dnsCount++;
             break;
           }
         }
        }
        if(dnsCount == sourceObj.value.length)
        {
           returnValue = true;
        }
        else
        {
           returnValue = false;
        }
      }
      return returnValue;
  }


 //Function to Compare the Interface setting values. Their properties are different and so check is done to compare the properties
  function compareInterfaceSetting(sourceObj,targetObj){
     var returnValue;
     if(sourceObj.type == "interface.setting")
      {
        var interfaceCount=0;
        for(var sourceIndex in sourceObj.value)
        {
          for(var targetIndex in targetObj.value)
          {//Secondary Ip address is optional. SO checking for undefined
           if(sourceObj.value[sourceIndex].interfaceName == targetObj.value[targetIndex].interfaceName  && sourceObj.value[sourceIndex].vlanId == targetObj.value[targetIndex].vlanId)
           {
             interfaceCount++;
             break;
           }
         }
        }
        if(interfaceCount == sourceObj.value.length)
        {
           returnValue = true;
        }
        else
        {
           returnValue = false;
        }
      }
      return returnValue;
  }

 //Function to Compare the Reference setting values. Their properties are different and so check is done to compare the properties
  function compareReferenceSetting(sourceObj,targetObj){
     var returnValue;
     if(sourceObj.type == "reference.setting")
      {
        var referenceCount=0;
        for(var sourceIndex in sourceObj.value)
        {
          for(var targetIndex in targetObj.value)
          {//Secondary Ip address is optional. SO checking for undefined
           if(sourceObj.value[sourceIndex].objReferences.length == targetObj.value[targetIndex].objReferences.length
             && sourceObj.value[sourceIndex].objReferences[0] == targetObj.value[targetIndex].objReferences[0])
           {
             referenceCount++;
             break;
           }
         }
        }
        if(referenceCount == sourceObj.value.length)
        {
           returnValue = true;
        }
        else
        {
           returnValue = false;
        }
      }
      return returnValue;
  }

  function compareSettings(sourceObj,targetObj){

       var isEqual;
       isEqual = compareAAASetting(sourceObj,targetObj);
       if( isEqual == undefined)
       {
         isEqual = compareCommonSetting(sourceObj,targetObj);
        if(isEqual == undefined)
        {
          isEqual = compareNetflowSetting(sourceObj,targetObj);
          if(isEqual == undefined)
          {
           isEqual = compareDNSSetting(sourceObj,targetObj);
           if(isEqual == undefined)
           {
           isEqual = compareInterfaceSetting(sourceObj,targetObj);
           if(isEqual == undefined)
           {
             isEqual = compareReferenceSetting(sourceObj,targetObj);
           }
           }
          }
         }
        }
         return isEqual;
    }

  //Propagate the commonsettings change to the child, only if the child has inherited the change from Global or if the child has already inherited the change from the same parent.
  function checkHierarchyInfo(parentObj,serverDetail){
  //Generally the values of reference setting type  will have one entry only in serverDetails. But there will be multiple reference setting type entries ,each with one value.
   //So if there is a match with the POST inout data and the existing serverdetail in DB, it means it is a deletion.So need to copy the valuefrom post data to the record serverdetail.
   if(serverDetail.inheritedGroupName == "Global" || (parentObj != undefined && serverDetail.inheritedGroupName == parentObj[0].name) || serverDetail.type == "reference.setting" )
     return true;
   else
     return false;
  }

  return {
      init: function(){
          createDefaultServers();
          createCommonSettingKey();
          createAaa();
          createPSNServer();
          this.createDefaultSiteSetting();
      },

      updateServers: function(urlAction) {

           var resultObj = SimTaskDataGenerator.createTask('SPFService');
           var inputData =urlAction.restPayload;

           var groupUuid = "";

           if(urlAction.service[2]!=undefined) {
             groupUuid = urlAction.service[2];
           } else {
             groupUuid = urlAction.action.id;
           }
           //var updatedSettings = [];
           var aaaSettingFlag = this.updateCommonSettingsForServers(groupUuid,undefined,inputData);
           var childrenSites = [];
           SimulationUtility.getChildrenSites(groupUuid,childrenSites);
           if(groupUuid == '-1')
            {
              groupUuid = DefaultConfig.DEFAULT_UUID.GlobalGroup;
            }
           var parentObj = SimLokiDatabaseActions.getFilteredRecordHandler("site",{id:groupUuid});
           if(parentObj != undefined)
           {
             for(var childSiteIndex in childrenSites)
             {
               this.updateCommonSettingsForServers(childrenSites[childSiteIndex].id,parentObj,inputData);
             }
             SimTaskDataGenerator.updateTaskCompletion(resultObj.taskId, {isError: false });
           }
           // for AAA setting alone, actual code expecting 202 code during post request.
           //if(aaaSettingFlag==true) {
             resultObj.statusCode= 202;
           //}
      return resultObj;
      },

      createServers:function (groupId) {
        var tJsonArr = JSON.parse(JSON.stringify( ServerTemplate.Server_Template ));
        tJsonArr.groupUuid =groupId;
          // inheriting from global
        //var globalRecord = SimLokiDatabaseActions.getFilteredRecordHandler('servers', {'groupUuid': "-1"});
        //var record =JSON.parse(JSON.stringify(globalRecord));
        //var serverDetails = record[0].serverDetails;
         for(i=0;i<  tJsonArr.serverDetails.length;i++) {
           //if(checkInherited(serverDetails[i])==true) {
                tJsonArr.serverDetails[i].inheritedGroupUuid = "-1";
                tJsonArr.serverDetails[i].groupUuid = groupId;
                tJsonArr.serverDetails[i].inheritedGroupName = "Global";
                tJsonArr.serverDetails[i].instanceUuid = UtilityFunctions.generate_uuid();
           //}
         }
         //tJsonArr.serverDetails =serverDetails;
        //for stealthwatch need to add esa_collector 1.4 by karan
        recordObj = SimLokiDatabaseActions.getFilteredRecordHandler('site',{name:"North America"})
        if(groupId == recordObj[0].id){
          serverDetail = {}
          serverDetail.instanceType = "eta_collector";
          serverDetail.instanceUuid = UtilityFunctions.generate_uuid();
          serverDetail.namespace = "global";
          serverDetail.type ="eta.setting";
          serverDetail.key = "eta.collector";
          serverDetail.version = "1";
          serverDetail.value = [{"ipAddress":"10.16.81.76", "port":2055}];
          serverDetail.groupUuid = groupId
          serverDetail.inheritedGroupUuid = "";
          serverDetail.inheritedGroupName = "";
          tJsonArr.serverDetails.push(serverDetail)
        }
        SimLokiDatabaseActions.insert( 'servers', tJsonArr );
      },

      createDefaultSiteSetting: function () {
          var tObj = SimLokiDatabaseActions.getAll("site");
          for(var i =0, len = tObj.length; i < len; i++){
              this.createServers(tObj[i].id);
          }
      },

      // This Function will update the commonsettings for the groups.
      //@param groupUuid Id of the site to be updated
      //@param inputData the json with new settings value. This json values has to be copied to the record of groupUuid
      //@param siteObj Details of the  parentSite,from where the change originated, needed to set the inheritedgroup name and id
      updateCommonSettingsForServers: function(groupUuid,siteObj,inputData)
      {
           var oldRecord = SimLokiDatabaseActions.getFilteredRecordHandler('servers', {'groupUuid': groupUuid});
           var record= JSON.parse(JSON.stringify(oldRecord));
           var aaaSettingFlag = false;
           var tJsonArr = JSON.parse(JSON.stringify(inputData));
          if(oldRecord.length>0) {
           for(i=0;i<tJsonArr.length;i++)
           {
               var key = tJsonArr[i].key;
                if (key =="aaa.server.primary" || key=="sp.info") {
                   aaaSettingFlag =true;
                }
               var flag =false;
               for(j=0;j< record[0].serverDetails.length;j++)
               {
                    if(record[0].serverDetails[j].key==key)
                    {
                     flag = true;
                     //UI sends empty string as value for ipAddress, which is considered as an update of commonsetting. To avoid that, this check is used.
                     if(key.startsWith("aaa")  && tJsonArr[i].value.length == 1)
                     {
                         if( tJsonArr[i].value[0].ipAddress == "")
                         {
                           break;
                         }
                     }
                     else if(key == "device.banner" && tJsonArr[i].value.length == 1)
                     {
                         if(tJsonArr[i].value == "")
                         {
                           break;
                         }
                     }
                     // Fix for Global setting update
                    if(groupUuid == '-1')
                    {
                        record[0].serverDetails[j].value =tJsonArr[i].value;
                        record[0].serverDetails[j].version =tJsonArr[i].version;
                    }
                     //If the record is already inherited, do not again propogate the new changes.So we check for Global inherited Groupname
                    else if(checkHierarchyInfo(siteObj,record[0].serverDetails[j]) && (record[0].serverDetails[j].value.length != tJsonArr[i].value.length || compareSettings(record[0].serverDetails[j],tJsonArr[i]) == false ))
                     {
                       updateHierarchyInfo(siteObj,record[0].serverDetails[j]);
                       record[0].serverDetails[j].value =tJsonArr[i].value;
                       record[0].serverDetails[j].version =tJsonArr[i].version;
                    }
                    break;
                    }
               }
               if(flag==false)
               {
                   tJsonArr[i].instanceUuid = UtilityFunctions.generate_uuid();
                   tJsonArr[i].groupUuid = groupUuid;
                   updateHierarchyInfo(siteObj,tJsonArr[i]);
                   record[0].serverDetails.push(tJsonArr[i]);
               }
           }
           SimLokiDatabaseActions.update('servers', record);
         }
           return aaaSettingFlag;
     }

   };

  });
