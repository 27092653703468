//CAT9K family Cisco Catalyst 9200 Switch Stack 
define({
  "runningImageList": [
    {
      "name": "cat9k_lite_iosxe.17.09.01.SPA.bin",
      "imageUuid": "9b8452e4-ff00-4444-1245-6d829f66ac0c",
      "version": "17.9.1",
      "softwareType": null,
      "imageType": "SYSTEM_SW",
      "isTaggedGolden": "true",
      "md5Checksum": "d8d7f57b834c0d635ec753a4c1dda5fc",
      "shaCheckSum": "82cec4a2c8e552a0f783dca9d95f9c00e348625a37d75f514f895ac994be15788b5694c3065eab81b72f75e3a03ffdac2a2cec137b06e97cb7c1188bf6831a7e",
      "createdTime": "2022-08-01 18:29:00.00",
      "applicationType": "UNKNOWN",
      "feature": null,
      "fileServiceId": "d15d386c-1324-aaaa-aa32-f2619cebe23c",
      "imageSeries": null,
      "imageSource": "CCO",
      "imageSourceType": "Imported from CCO",
      "imageFamily": "CAT9K",
      "vendor": "CISCO",
      "filesize": 526058711,
      "deviceCount": 0,
      "deviceUuidList": [],
      "smuCount": 0,
      "imageAttributes": [
        {
          "propertyName": "min_flash",
          "propertyValue": "4096"
        },
        {
          "propertyName": "image_guid",
          "propertyValue": "3FCBCEB6E46F7293EE8E239FD3E4A374EEF98D81"
        },
        {
          "propertyName": "release_fcs_date",
          "propertyValue": "01/Aug/2022"
        },
        {
          "propertyName": "image_size",
          "propertyValue": "482403517"
        },
        {
          "propertyName": "min_dram",
          "propertyValue": "2048"
        },
        {
          "propertyName": "image_description",
          "propertyValue": "CAT92xx"
        },
        {
          "propertyName": "metadata_trans_id",
          "propertyValue": "153426088"
        },
        {
          "propertyName": "udi",
          "propertyValue": "PID: C9200 VID: V01, SN: FCW2146L02A"
        },
        {
          "propertyName": "deviceMNEId",
          "propertyValue": "542549"
        },
        {
          "propertyName": "encryption_software_indicator",
          "propertyValue": "Y"
        },
        {
          "propertyName": "mdf_id",
          "propertyValue": "223415874"
        }
      ],
      "isSMUApplicable": true,
      "goldenTagInheritedFromSiteUuid": null,
      "goldenTagInheritedFromSiteName": null,
      "importSupported": true,
      "reasonForNotSupportingImport": null,
      "tagList": [
        {
          "taggedGolden": true,
          "role": "ALL",
          "inheritedSiteName": "",
          "inheritedSiteUuid": ""
        },
        {
          "taggedGolden": false,
          "role": "CORE",
          "inheritedSiteName": null,
          "inheritedSiteUuid": null
        },
        {
          "taggedGolden": false,
          "role": "DISTRIBUTION",
          "inheritedSiteName": null,
          "inheritedSiteUuid": null
        },
        {
          "taggedGolden": false,
          "role": "BORDER ROUTER",
          "inheritedSiteName": null,
          "inheritedSiteUuid": null
        },
        {
          "taggedGolden": false,
          "role": "UNKNOWN",
          "inheritedSiteName": null,
          "inheritedSiteUuid": null
        },
        {
          "taggedGolden": false,
          "role": "ACCESS",
          "inheritedSiteName": null,
          "inheritedSiteUuid": null
        }
      ],
      "imageCategory": "PHYSICAL",
      "imageIntegrityStatus": "",
      "imageImported": true,
      "applicableDevicesForImage": [
        {
          "mdfId": "223415874",
          "productName": "Cisco Catalyst 9200 Switch Stack",
          "productId": [
            "C9200-24T",
            "C9200-24PXG",
            "C9200-24P",
            "C9200-48T",
            "C9200-48PXG",
            "C9200-48P"
          ]
        }
      ],
      "profileInfo": null,
      "physicallyAvailable": true,
      "assignCount": 0,
      "showUnassign": false,
      "disableUnassign": false,
      "ccoreverseSync": false,
      "ccorecommended": false,
      "ccolatest": true
    },{
      "name": "cat9k_lite_iosxe.17.06.01.SPA.bin",
      "imageUuid": "9b8452e4-ff00-2343-1245-6d829f66ac0c",
      "version": "17.6.1",
      "softwareType": null,
      "imageType": "SYSTEM_SW",
      "isTaggedGolden": "false",
      "md5Checksum": "d8d7f57b834c0d635ec753a4c1dda5fc",
      "shaCheckSum": "82cec4a2c8e552a0f783dca9d95f9c00e348625a37d75f514f895ac994be15788b5694c3065eab81b72f75e3a03ffdac2a2cec137b06e97cb7c1188bf6831a7e",
      "createdTime": "2021-09-14 18:29:00.00",
      "applicationType": "UNKNOWN",
      "feature": null,
      "fileServiceId": "d15d386c-1324-43e8-aa32-f2619cebe23c",
      "imageSeries": null,
      "imageSource": "CCO",
      "imageSourceType": "Imported from CCO",
      "imageFamily": "CAT9K",
      "vendor": "CISCO",
      "filesize": 526058711,
      "deviceCount": 0,
      "deviceUuidList": [],
      "smuCount": 0,
      "imageAttributes": [
        {
          "propertyName": "min_flash",
          "propertyValue": "4096"
        },
        {
          "propertyName": "image_guid",
          "propertyValue": "3FCBCEB6E46F7293EE8E239FD3E4A374EEF98D81"
        },
        {
          "propertyName": "release_fcs_date",
          "propertyValue": "05/Mar/2021"
        },
        {
          "propertyName": "image_size",
          "propertyValue": "482403517"
        },
        {
          "propertyName": "min_dram",
          "propertyValue": "2048"
        },
        {
          "propertyName": "image_description",
          "propertyValue": "CAT92xx"
        },
        {
          "propertyName": "metadata_trans_id",
          "propertyValue": "153426088"
        },
        {
          "propertyName": "udi",
          "propertyValue": "PID: C9200 VID: V01, SN: FCW2146L02A"
        },
        {
          "propertyName": "deviceMNEId",
          "propertyValue": "542549"
        },
        {
          "propertyName": "encryption_software_indicator",
          "propertyValue": "Y"
        },
        {
          "propertyName": "mdf_id",
          "propertyValue": "223415874"
        }
      ],
      "isSMUApplicable": true,
      "goldenTagInheritedFromSiteUuid": null,
      "goldenTagInheritedFromSiteName": null,
      "importSupported": true,
      "reasonForNotSupportingImport": null,
      "tagList": [
        {
          "taggedGolden": false,
          "role": "ALL",
          "inheritedSiteName": "",
          "inheritedSiteUuid": ""
        },
        {
          "taggedGolden": false,
          "role": "CORE",
          "inheritedSiteName": null,
          "inheritedSiteUuid": null
        },
        {
          "taggedGolden": false,
          "role": "DISTRIBUTION",
          "inheritedSiteName": null,
          "inheritedSiteUuid": null
        },
        {
          "taggedGolden": false,
          "role": "BORDER ROUTER",
          "inheritedSiteName": null,
          "inheritedSiteUuid": null
        },
        {
          "taggedGolden": false,
          "role": "UNKNOWN",
          "inheritedSiteName": null,
          "inheritedSiteUuid": null
        },
        {
          "taggedGolden": false,
          "role": "ACCESS",
          "inheritedSiteName": null,
          "inheritedSiteUuid": null
        }
      ],
      "imageCategory": "PHYSICAL",
      "imageIntegrityStatus": "",
      "imageImported": true,
      "applicableDevicesForImage": [
        {
          "mdfId": "223415874",
          "productName": "Cisco Catalyst 9200 Switch Stack",
          "productId": [
            "C9200-24T",
            "C9200-24PXG",
            "C9200-24P",
            "C9200-48T",
            "C9200-48PXG",
            "C9200-48P"
          ]
        }
      ],
      "profileInfo": null,
      "physicallyAvailable": true,
      "assignCount": 0,
      "showUnassign": false,
      "disableUnassign": false,
      "ccoreverseSync": false,
      "ccorecommended": false,
      "ccolatest": true
    },
    {
      "name": "cat9k_lite_iosxe.17.03.03.SPA.bin",
      "imageUuid": "9b8452e4-ff00-1113-1245-6d829f66ac0c",
      "version": "17.3.3",
      "softwareType": null,
      "imageType": "SYSTEM_SW",
      "isTaggedGolden": "false",
      "md5Checksum": "26a59146e871777a36dce7e01b87dc5c",
      "shaCheckSum": "ba40f77a013f974caec578c69e1508102148cefd0150e146b89b03e2655429f1057e009e0c85ec56a0f0185d0a920d8b4eda25d63bc51b71003ac503548b589a",
      "createdTime": "2021-03-15 18:29:00.00",
      "applicationType": "UNKNOWN",
      "feature": null,
      "fileServiceId": "d15d386c-1324-43e8-aa32-f2619cebe23c",
      "imageSeries": null,
      "imageSource": "CCO",
      "imageSourceType": "Imported from CCO",
      "imageFamily": "CAT9K",
      "vendor": "CISCO",
      "filesize": 482403517,
      "deviceCount": 0,
      "deviceUuidList": [],
      "smuCount": 0,
      "imageAttributes": [
        {
          "propertyName": "min_flash",
          "propertyValue": "4096"
        },
        {
          "propertyName": "image_guid",
          "propertyValue": "3FCBCEB6E46F7293EE8E239FD3E4A374EEF98D81"
        },
        {
          "propertyName": "release_fcs_date",
          "propertyValue": "05/Mar/2021"
        },
        {
          "propertyName": "image_size",
          "propertyValue": "482403517"
        },
        {
          "propertyName": "min_dram",
          "propertyValue": "2048"
        },
        {
          "propertyName": "image_description",
          "propertyValue": "CAT92xx"
        },
        {
          "propertyName": "metadata_trans_id",
          "propertyValue": "153426088"
        },
        {
          "propertyName": "udi",
          "propertyValue": "PID: C9200 VID: V01, SN: FCW2146L02A"
        },
        {
          "propertyName": "deviceMNEId",
          "propertyValue": "542549"
        },
        {
          "propertyName": "encryption_software_indicator",
          "propertyValue": "Y"
        },
        {
          "propertyName": "mdf_id",
          "propertyValue": "223415874"
        }
      ],
      "isSMUApplicable": true,
      "goldenTagInheritedFromSiteUuid": null,
      "goldenTagInheritedFromSiteName": null,
      "importSupported": true,
      "reasonForNotSupportingImport": null,
      "tagList": [
        {
          "taggedGolden": false,
          "role": "ALL",
          "inheritedSiteName": "",
          "inheritedSiteUuid": ""
        },
        {
          "taggedGolden": false,
          "role": "CORE",
          "inheritedSiteName": null,
          "inheritedSiteUuid": null
        },
        {
          "taggedGolden": false,
          "role": "DISTRIBUTION",
          "inheritedSiteName": null,
          "inheritedSiteUuid": null
        },
        {
          "taggedGolden": false,
          "role": "BORDER ROUTER",
          "inheritedSiteName": null,
          "inheritedSiteUuid": null
        },
        {
          "taggedGolden": false,
          "role": "UNKNOWN",
          "inheritedSiteName": null,
          "inheritedSiteUuid": null
        },
        {
          "taggedGolden": false,
          "role": "ACCESS",
          "inheritedSiteName": null,
          "inheritedSiteUuid": null
        }
      ],
      "imageCategory": "PHYSICAL",
      "imageIntegrityStatus": "",
      "imageImported": true,
      "applicableDevicesForImage": [
        {
          "mdfId": "223415874",
          "productName": "Cisco Catalyst 9200 Switch Stack",
          "productId": [
            "C9200-24T",
            "C9200-24PXG",
            "C9200-24P",
            "C9200-48T",
            "C9200-48PXG",
            "C9200-48P"
          ]
        }
      ],
      "profileInfo": null,
      "physicallyAvailable": true,
      "assignCount": 0,
      "showUnassign": false,
      "disableUnassign": false,
      "ccoreverseSync": false,
      "ccorecommended": false,
      "ccolatest": true
    },
    {
      "name": "cat9k_lite_iosxe.17.03.01.SPA.bin",
      "imageUuid": "9b8452e4-ff00-6782-1245-6d829f66ac0c",
      "version": "17.3.1",//Amsterdam-17.3.1
      "softwareType": null,
      "imageType": "SYSTEM_SW",
      "isTaggedGolden": "false",
      "md5Checksum": "746d7b5074b8ece7979e80a4b5cc491f",
      "shaCheckSum": "1d1482059d99ad0f119d9d5ef460db5f8c9c9f8da0394fcc5ce6e4b91ea6752abbffd86d27dcd6580bad6cef8c3e3a3d82f8be79b14224cdd4349a0f694571fa",
      "createdTime": "2020-10-16 14:07:00.00",
      "applicationType": "UNKNOWN",
      "feature": null,
      "fileServiceId": "d15d386c-3333-43e8-aa32-f2619cebe23c",
      "imageSeries": null,
      "imageSource": "CCO",
      "imageSourceType": "Imported from CCO",
      "imageFamily": "CAT9K",
      "vendor": "CISCO",
      "filesize": 483549665,
      "deviceCount": 0,
      "deviceUuidList": [],
      "smuCount": 0,
      "imageAttributes": [
        {
          "propertyName": "min_flash",
          "propertyValue": "4096"
        },
        {
          "propertyName": "image_guid",
          "propertyValue": "3FCBCEB6E46F7293EE8E239FD3E4A374EEF98D81"
        },
        {
          "propertyName": "release_fcs_date",
          "propertyValue": "09/Aug/2020"
        },
        {
          "propertyName": "image_size",
          "propertyValue": "483549665"
        },
        {
          "propertyName": "min_dram",
          "propertyValue": "2048"
        },
        {
          "propertyName": "image_description",
          "propertyValue": "CAT92xx"
        },
        {
          "propertyName": "metadata_trans_id",
          "propertyValue": "153426088"
        },
        {
          "propertyName": "udi",
          "propertyValue": "PID: C9200 VID: V01, SN: FCW2146L02A"
        },
        {
          "propertyName": "deviceMNEId",
          "propertyValue": "542549"
        },
        {
          "propertyName": "encryption_software_indicator",
          "propertyValue": "Y"
        },
        {
          "propertyName": "mdf_id",
          "propertyValue": "223415874"
        }
      ],
      "isSMUApplicable": true,
      "goldenTagInheritedFromSiteUuid": null,
      "goldenTagInheritedFromSiteName": null,
      "importSupported": true,
      "reasonForNotSupportingImport": null,
      "tagList": [
        {
          "taggedGolden": false,
          "role": "ALL",
          "inheritedSiteName": "",
          "inheritedSiteUuid": ""
        },
        {
          "taggedGolden": false,
          "role": "CORE",
          "inheritedSiteName": null,
          "inheritedSiteUuid": null
        },
        {
          "taggedGolden": false,
          "role": "DISTRIBUTION",
          "inheritedSiteName": null,
          "inheritedSiteUuid": null
        },
        {
          "taggedGolden": false,
          "role": "BORDER ROUTER",
          "inheritedSiteName": null,
          "inheritedSiteUuid": null
        },
        {
          "taggedGolden": false,
          "role": "UNKNOWN",
          "inheritedSiteName": null,
          "inheritedSiteUuid": null
        },
        {
          "taggedGolden": false,
          "role": "ACCESS",
          "inheritedSiteName": null,
          "inheritedSiteUuid": null
        }
      ],
      "imageCategory": "PHYSICAL",
      "imageIntegrityStatus": "",
      "imageImported": true,
      "applicableDevicesForImage": [
        {
          "mdfId": "223415874",
          "productName": "Cisco Catalyst 9200 Switch Stack",
          "productId": [
            "C9200-24T",
            "C9200-24PXG",
            "C9200-24P",
            "C9200-48T",
            "C9200-48PXG",
            "C9200-48P"
          ]
        }
      ],
      "profileInfo": null,
      "physicallyAvailable": true,
      "assignCount": 0,
      "showUnassign": false,
      "disableUnassign": false,
      "ccoreverseSync": false,
      "ccorecommended": false,
      "ccolatest": true
    },
    {
      "name": "cat9k_lite_iosxe.17.02.01.SPA.bin",
      "imageUuid": "9b8452e4-0f57-6782-1245-6d829f66ac0c",
      "version": "17.2.1",//Amsterdam-17.2.1
      "softwareType": null,
      "imageType": "SYSTEM_SW",
      "isTaggedGolden": "false",
      "md5Checksum": "fdcbc9a81502d42938d549791aed797d",
      "shaCheckSum": "11d8a707ab33492199b4d9fb2769e279719fd24152334259986ba1b339fb0f73c909f5092e747d20e59bd1dee14de879f0c16f6d5a73d31c2fff0ac77a09ea73",
      "createdTime": "2020-04-28 18:07:00.00",
      "applicationType": "UNKNOWN",
      "feature": null,
      "fileServiceId": "d15d386c-1278-43e8-aa32-f2619cebe23c",
      "imageSeries": null,
      "imageSource": "CCO",
      "imageSourceType": "Imported from CCO",
      "imageFamily": "CAT9K",
      "vendor": "CISCO",
      "filesize": 487441937,
      "deviceCount": 0,
      "deviceUuidList": [],
      "smuCount": 0,
      "imageAttributes": [
        {
          "propertyName": "min_flash",
          "propertyValue": "16384"
        },
        {
          "propertyName": "image_guid",
          "propertyValue": "2FCBCEB5E46F7293EE8E239FD3E4A374EEF98D81"
        },
        {
          "propertyName": "release_fcs_date",
          "propertyValue": "28/Mar/2020"
        },
        {
          "propertyName": "image_size",
          "propertyValue": "487441937"
        },
        {
          "propertyName": "min_dram",
          "propertyValue": "2048"
        },
        {
          "propertyName": "image_description",
          "propertyValue": "CAT92xx"
        },
        {
          "propertyName": "metadata_trans_id",
          "propertyValue": "153426088"
        },
        {
          "propertyName": "udi",
          "propertyValue": "PID: C9200 VID: V01, SN: FCW2146L02A"
        },
        {
          "propertyName": "deviceMNEId",
          "propertyValue": "542549"
        },
        {
          "propertyName": "encryption_software_indicator",
          "propertyValue": "Y"
        },
        {
          "propertyName": "mdf_id",
          "propertyValue": "223415874"
        }
      ],
      "isSMUApplicable": true,
      "goldenTagInheritedFromSiteUuid": null,
      "goldenTagInheritedFromSiteName": null,
      "importSupported": true,
      "reasonForNotSupportingImport": null,
      "tagList": [
        {
          "taggedGolden": false,
          "role": "ALL",
          "inheritedSiteName": "",
          "inheritedSiteUuid": ""
        },
        {
          "taggedGolden": false,
          "role": "CORE",
          "inheritedSiteName": null,
          "inheritedSiteUuid": null
        },
        {
          "taggedGolden": false,
          "role": "DISTRIBUTION",
          "inheritedSiteName": null,
          "inheritedSiteUuid": null
        },
        {
          "taggedGolden": false,
          "role": "BORDER ROUTER",
          "inheritedSiteName": null,
          "inheritedSiteUuid": null
        },
        {
          "taggedGolden": false,
          "role": "UNKNOWN",
          "inheritedSiteName": null,
          "inheritedSiteUuid": null
        },
        {
          "taggedGolden": false,
          "role": "ACCESS",
          "inheritedSiteName": null,
          "inheritedSiteUuid": null
        }
      ],
      "imageCategory": "PHYSICAL",
      "imageIntegrityStatus": "",
      "imageImported": true,
      "applicableDevicesForImage": [
        {
          "mdfId": "223415874",
          "productName": "Cisco Catalyst 9200 Switch Stack",
          "productId": [
            "C9200-24T",
            "C9200-24PXG",
            "C9200-24P",
            "C9200-48T",
            "C9200-48PXG",
            "C9200-48P"
          ]
        }
      ],
      "profileInfo": null,
      "physicallyAvailable": true,
      "assignCount": 0,
      "showUnassign": false,
      "disableUnassign": false,
      "ccoreverseSync": false,
      "ccorecommended": false,
      "ccolatest": true
    },
    {
      "name": "cat9k_lite_iosxe.16.12.02t.SPA.bin",
      "imageUuid": "9b8452e4-0f57-6782-9843-6d829f66ac0c",
      "version": "16.12.2t",//Gibraltar-16.12.2t
      "softwareType": null,
      "imageType": "SYSTEM_SW",
      "isTaggedGolden": "false",
      "md5Checksum": "9ba9f898d529f5132b78bca636e95576",
      "shaCheckSum": "d728350bd089d6304553cf1e14e47efc6b8d9113cde87834038f52b10ec41f2fd17278799503d085f6ac4310a2f02bbe5596e72eb4ba35a69b6ad41f51496930",
      "createdTime": "2020-02-12 12:50:00.00",
      "applicationType": "UNKNOWN",
      "feature": null,
      "fileServiceId": "d15d386c-1278-43e8-aa32-f2619cebe23c",
      "imageSeries": null,
      "imageSource": "CCO",
      "imageSourceType": "Imported from CCO",
      "imageFamily": "CAT9K",
      "vendor": "CISCO",
      "filesize": 444031957,
      "deviceCount": 0,
      "deviceUuidList": [],
      "smuCount": 0,
      "imageAttributes": [
        {
          "propertyName": "min_flash",
          "propertyValue": "16384"
        },
        {
          "propertyName": "image_guid",
          "propertyValue": "2FCBCEB5E46F7293EE8E239FD3E4A374EEF98D81"
        },
        {
          "propertyName": "release_fcs_date",
          "propertyValue": "12/Feb/2020"
        },
        {
          "propertyName": "image_size",
          "propertyValue": "444031957"
        },
        {
          "propertyName": "min_dram",
          "propertyValue": "2048"
        },
        {
          "propertyName": "image_description",
          "propertyValue": "CAT92xx"
        },
        {
          "propertyName": "metadata_trans_id",
          "propertyValue": "153426088"
        },
        {
          "propertyName": "udi",
          "propertyValue": "PID: C9200 VID: V01, SN: FCW2146L02A"
        },
        {
          "propertyName": "deviceMNEId",
          "propertyValue": "542549"
        },
        {
          "propertyName": "encryption_software_indicator",
          "propertyValue": "Y"
        },
        {
          "propertyName": "mdf_id",
          "propertyValue": "223415874"
        }
      ],
      "isSMUApplicable": true,
      "goldenTagInheritedFromSiteUuid": null,
      "goldenTagInheritedFromSiteName": null,
      "importSupported": true,
      "reasonForNotSupportingImport": null,
      "tagList": [
        {
          "taggedGolden": false,
          "role": "ALL",
          "inheritedSiteName": "",
          "inheritedSiteUuid": ""
        },
        {
          "taggedGolden": false,
          "role": "CORE",
          "inheritedSiteName": null,
          "inheritedSiteUuid": null
        },
        {
          "taggedGolden": false,
          "role": "DISTRIBUTION",
          "inheritedSiteName": null,
          "inheritedSiteUuid": null
        },
        {
          "taggedGolden": false,
          "role": "BORDER ROUTER",
          "inheritedSiteName": null,
          "inheritedSiteUuid": null
        },
        {
          "taggedGolden": false,
          "role": "UNKNOWN",
          "inheritedSiteName": null,
          "inheritedSiteUuid": null
        },
        {
          "taggedGolden": false,
          "role": "ACCESS",
          "inheritedSiteName": null,
          "inheritedSiteUuid": null
        }
      ],
      "imageCategory": "PHYSICAL",
      "imageIntegrityStatus": "",
      "imageImported": true,
      "applicableDevicesForImage": [
        {
          "mdfId": "223415874",
          "productName": "Cisco Catalyst 9200 Switch Stack",
          "productId": [
            "C9200-24T",
            "C9200-24PXG",
            "C9200-24P",
            "C9200-48T",
            "C9200-48PXG",
            "C9200-48P"
          ]
        }
      ],
      "profileInfo": null,
      "physicallyAvailable": true,
      "assignCount": 0,
      "showUnassign": false,
      "disableUnassign": false,
      "ccoreverseSync": false,
      "ccorecommended": false,
      "ccolatest": true
    },
    {
      "name": "cat9k_lite_iosxe.17.01.01.SPA.bin",
      "imageUuid": "9b8452e4-4576-6782-91fe-6d829f66ac0c",
      "version": "17.1.1",//Amsterdam-17.1.1
      "softwareType": null,
      "imageType": "SYSTEM_SW",
      "isTaggedGolden": "false",
      "md5Checksum": "89b095e4bcaa70a0d4e60bf34c8f0796",
      "shaCheckSum": "2c307d6ace31181c98ed3958a6949a3c9425a842291bc2aaace21c5874539e8b3c4c41627fc6d17a30c6affcb8e223a366e3a35e8e7d3865852fdfd2372f27e0",
      "createdTime": "2019-11-23 12:50:00.00",
      "applicationType": "UNKNOWN",
      "feature": null,
      "fileServiceId": "d15d386c-1278-43e8-aa32-f2619cebe23c",
      "imageSeries": null,
      "imageSource": "CCO",
      "imageSourceType": "Imported from CCO",
      "imageFamily": "CAT9K",
      "vendor": "CISCO",
      "filesize": 472104301,
      "deviceCount": 0,
      "deviceUuidList": [],
      "smuCount": 0,
      "imageAttributes": [
        {
          "propertyName": "min_flash",
          "propertyValue": "16384"
        },
        {
          "propertyName": "image_guid",
          "propertyValue": "2FCBCEB5E46F7293EE8E239FD3E4A374EEF98D81"
        },
        {
          "propertyName": "release_fcs_date",
          "propertyValue": "23/Nov/2019"
        },
        {
          "propertyName": "image_size",
          "propertyValue": "472104301"
        },
        {
          "propertyName": "min_dram",
          "propertyValue": "2048"
        },
        {
          "propertyName": "image_description",
          "propertyValue": "CAT92xx"
        },
        {
          "propertyName": "metadata_trans_id",
          "propertyValue": "153426088"
        },
        {
          "propertyName": "udi",
          "propertyValue": "PID: C9200 VID: V01, SN: FCW2146L02A"
        },
        {
          "propertyName": "deviceMNEId",
          "propertyValue": "542549"
        },
        {
          "propertyName": "encryption_software_indicator",
          "propertyValue": "Y"
        },
        {
          "propertyName": "mdf_id",
          "propertyValue": "223415874"
        }
      ],
      "isSMUApplicable": true,
      "goldenTagInheritedFromSiteUuid": null,
      "goldenTagInheritedFromSiteName": null,
      "importSupported": true,
      "reasonForNotSupportingImport": null,
      "tagList": [
        {
          "taggedGolden": false,
          "role": "ALL",
          "inheritedSiteName": "",
          "inheritedSiteUuid": ""
        },
        {
          "taggedGolden": false,
          "role": "CORE",
          "inheritedSiteName": null,
          "inheritedSiteUuid": null
        },
        {
          "taggedGolden": false,
          "role": "DISTRIBUTION",
          "inheritedSiteName": null,
          "inheritedSiteUuid": null
        },
        {
          "taggedGolden": false,
          "role": "BORDER ROUTER",
          "inheritedSiteName": null,
          "inheritedSiteUuid": null
        },
        {
          "taggedGolden": false,
          "role": "UNKNOWN",
          "inheritedSiteName": null,
          "inheritedSiteUuid": null
        },
        {
          "taggedGolden": false,
          "role": "ACCESS",
          "inheritedSiteName": null,
          "inheritedSiteUuid": null
        }
      ],
      "imageCategory": "PHYSICAL",
      "imageIntegrityStatus": "",
      "imageImported": true,
      "applicableDevicesForImage": [
        {
          "mdfId": "223415874",
          "productName": "Cisco Catalyst 9200 Switch Stack",
          "productId": [
            "C9200-24T",
            "C9200-24PXG",
            "C9200-24P",
            "C9200-48T",
            "C9200-48PXG",
            "C9200-48P"
          ]
        }
      ],
      "profileInfo": null,
      "physicallyAvailable": true,
      "assignCount": 0,
      "showUnassign": false,
      "disableUnassign": false,
      "ccoreverseSync": false,
      "ccorecommended": false,
      "ccolatest": true
    },
    {
      "name": "cat9k_lite_iosxe.16.12.01s.SPA.bin",
      "imageUuid": "9b8452e4-0f57-6782-91fe-6d829f66ac0c",
      "version": "16.12.1s",//Gibraltar-16.12.1
      "softwareType": null,
      "imageType": "SYSTEM_SW",
      "isTaggedGolden": "false",
      "md5Checksum": "727cfc785bc304b985656dee660b0382",
      "shaCheckSum": "0793414950812e93465966b08e3ca0b3af4ee5ce255e5de90ecac500075e5b395a32a8c8886c9b3b4e14831c52ed430145e17ab2ec79c393b74fe4c5512f2eea",
      "createdTime": "2019-09-04 12:50:00.00",
      "applicationType": "UNKNOWN",
      "feature": null,
      "fileServiceId": "d15d386c-1278-43e8-aa32-f2619cebe23c",
      "imageSeries": null,
      "imageSource": "CCO",
      "imageSourceType": "Imported from CCO",
      "imageFamily": "CAT9K",
      "vendor": "CISCO",
      "filesize": 444031957,
      "deviceCount": 0,
      "deviceUuidList": [],
      "smuCount": 0,
      "imageAttributes": [
        {
          "propertyName": "min_flash",
          "propertyValue": "16384"
        },
        {
          "propertyName": "image_guid",
          "propertyValue": "2FCBCEB5E46F7293EE8E239FD3E4A374EEF98D81"
        },
        {
          "propertyName": "release_fcs_date",
          "propertyValue": "01/Aug/2019"
        },
        {
          "propertyName": "image_size",
          "propertyValue": "444031957"
        },
        {
          "propertyName": "min_dram",
          "propertyValue": "2048"
        },
        {
          "propertyName": "image_description",
          "propertyValue": "CAT92xx"
        },
        {
          "propertyName": "metadata_trans_id",
          "propertyValue": "153426088"
        },
        {
          "propertyName": "udi",
          "propertyValue": "PID: C9200 VID: V01, SN: FCW2146L02A"
        },
        {
          "propertyName": "deviceMNEId",
          "propertyValue": "542549"
        },
        {
          "propertyName": "encryption_software_indicator",
          "propertyValue": "Y"
        },
        {
          "propertyName": "mdf_id",
          "propertyValue": "223415874"
        }
      ],
      "isSMUApplicable": true,
      "goldenTagInheritedFromSiteUuid": null,
      "goldenTagInheritedFromSiteName": null,
      "importSupported": true,
      "reasonForNotSupportingImport": null,
      "tagList": [
        {
          "taggedGolden": false,
          "role": "ALL",
          "inheritedSiteName": "",
          "inheritedSiteUuid": ""
        },
        {
          "taggedGolden": false,
          "role": "CORE",
          "inheritedSiteName": null,
          "inheritedSiteUuid": null
        },
        {
          "taggedGolden": false,
          "role": "DISTRIBUTION",
          "inheritedSiteName": null,
          "inheritedSiteUuid": null
        },
        {
          "taggedGolden": false,
          "role": "BORDER ROUTER",
          "inheritedSiteName": null,
          "inheritedSiteUuid": null
        },
        {
          "taggedGolden": false,
          "role": "UNKNOWN",
          "inheritedSiteName": null,
          "inheritedSiteUuid": null
        },
        {
          "taggedGolden": false,
          "role": "ACCESS",
          "inheritedSiteName": null,
          "inheritedSiteUuid": null
        }
      ],
      "imageCategory": "PHYSICAL",
      "imageIntegrityStatus": "",
      "imageImported": true,
      "applicableDevicesForImage": [
        {
          "mdfId": "223415874",
          "productName": "Cisco Catalyst 9200 Switch Stack",
          "productId": [
            "C9200",
            "C9200-24T"
          ]
        }
      ],
      "profileInfo": null,
      "physicallyAvailable": true,
      "assignCount": 0,
      "showUnassign": false,
      "disableUnassign": false,
      "ccoreverseSync": false,
      "ccorecommended": false,
      "ccolatest": true
    },
    {
      "name": "cat9k_lite_iosxe.16.11.01s.SPA.bin",
      "imageUuid": "9b8452e4-0f57-2222-91fe-6d829f66ac0c",
      "version": "16.11.1s",
      "softwareType": null,
      "imageType": "SYSTEM_SW",
      "isTaggedGolden": "false",
      "md5Checksum": "3a5bab222108d5523f406fcbcebd0258",
      "shaCheckSum": "75ec123d5a21e041956cfdc9cd114cddaa15da59c85cc85ddc2428048ca7339a2a342920d879ac47019d07f2a10dbf6e4e9ae962200218f81bbced5f9fed560a",
      "createdTime": "2019-06-05 04:26:13.64",
      "applicationType": "UNKNOWN",
      "feature": null,
      "fileServiceId": "d15d386c-2222-43e8-aa32-f2619cebe23c",
      "imageSeries": null,
      "imageSource": "CCO",
      "imageSourceType": "Imported from CCO",
      "imageFamily": "CAT9K",
      "vendor": "CISCO",
      "filesize": 690470821,
      "deviceCount": 0,
      "deviceUuidList": [],
      "smuCount": 0,
      "imageAttributes": [
        {
          "propertyName": "min_flash",
          "propertyValue": "16384"
        },
        {
          "propertyName": "image_guid",
          "propertyValue": "2FCBCEB5E46F7293EE8E239AD3E4A374EEF98D81"
        },
        {
          "propertyName": "release_fcs_date",
          "propertyValue": "11/Mar/2019"
        },
        {
          "propertyName": "image_size",
          "propertyValue": "690470821"
        },
        {
          "propertyName": "min_dram",
          "propertyValue": "8192"
        },
        {
          "propertyName": "image_description",
          "propertyValue": "CAT92xx"
        },
        {
          "propertyName": "metadata_trans_id",
          "propertyValue": "153426088"
        },
        {
          "propertyName": "udi",
          "propertyValue": "PID: C9200 VID: V01, SN: FCW2146L02A"
        },
        {
          "propertyName": "deviceMNEId",
          "propertyValue": "542549"
        },
        {
          "propertyName": "encryption_software_indicator",
          "propertyValue": "Y"
        },
        {
          "propertyName": "mdf_id",
          "propertyValue": "223415874"
        }
      ],
      "isSMUApplicable": true,
      "goldenTagInheritedFromSiteUuid": null,
      "goldenTagInheritedFromSiteName": null,
      "importSupported": true,
      "reasonForNotSupportingImport": null,
      "tagList": [
        {
          "taggedGolden": false,
          "role": "ALL",
          "inheritedSiteName": "",
          "inheritedSiteUuid": ""
        },
        {
          "taggedGolden": false,
          "role": "CORE",
          "inheritedSiteName": null,
          "inheritedSiteUuid": null
        },
        {
          "taggedGolden": false,
          "role": "DISTRIBUTION",
          "inheritedSiteName": null,
          "inheritedSiteUuid": null
        },
        {
          "taggedGolden": false,
          "role": "BORDER ROUTER",
          "inheritedSiteName": null,
          "inheritedSiteUuid": null
        },
        {
          "taggedGolden": false,
          "role": "UNKNOWN",
          "inheritedSiteName": null,
          "inheritedSiteUuid": null
        },
        {
          "taggedGolden": false,
          "role": "ACCESS",
          "inheritedSiteName": null,
          "inheritedSiteUuid": null
        }
      ],
      "imageCategory": "PHYSICAL",
      "imageIntegrityStatus": "",
      "imageImported": true,
      "applicableDevicesForImage": [
        {
          "mdfId": "223415874",
          "productName": "Cisco Catalyst 9200 Switch Stack",
          "productId": [
            "C9200",
            "C9200-24T"
          ]
        }
      ],
      "profileInfo": null,
      "physicallyAvailable": true,
      "assignCount": 0,
      "showUnassign": false,
      "disableUnassign": false,
      "ccoreverseSync": false,
      "ccorecommended": false,
      "ccolatest": true
    },
    {
      "name": "cat9k_lite_iosxe.16.10.01e.SPA.bin",
      "imageUuid": "9b8452e4-0f57-4b77-91fe-6d829f66ac0c",
      "version": "16.10.1e",
      "softwareType": null,
      "imageType": "SYSTEM_SW",
      "isTaggedGolden": "false",
      "md5Checksum": "3a5bab6e2108d5523f406fcbcebd0258",
      "shaCheckSum": "75ec104d5a21e041956cfdc9cd114cddaa15da59c85cc85ddc2428048ca7339a2a342920d879ac47019d07f2a10dbf6e4e9ae962200218f81bbced5f9fed560a",
      "createdTime": "2019-04-28 04:26:13.64",
      "applicationType": "UNKNOWN",
      "feature": null,
      "fileServiceId": "d15d386c-565f-43e8-aa32-f2619cebe23c",
      "imageSeries": null,
      "imageSource": "CCO",
      "imageSourceType": "Imported from CCO",
      "imageFamily": "CAT9K",
      "vendor": "CISCO",
      "filesize": 690470821,
      "deviceCount": 0,
      "deviceUuidList": [],
      "smuCount": 0,
      "imageAttributes": [
        {
          "propertyName": "min_flash",
          "propertyValue": "16384"
        },
        {
          "propertyName": "image_guid",
          "propertyValue": "2FCBCEB5E46F7293EE8E239AD3E4A374EEF98D81"
        },
        {
          "propertyName": "release_fcs_date",
          "propertyValue": "11/Mar/2019"
        },
        {
          "propertyName": "image_size",
          "propertyValue": "690470821"
        },
        {
          "propertyName": "min_dram",
          "propertyValue": "8192"
        },
        {
          "propertyName": "image_description",
          "propertyValue": "CAT92xx"
        },
        {
          "propertyName": "metadata_trans_id",
          "propertyValue": "153426088"
        },
        {
          "propertyName": "udi",
          "propertyValue": "PID: C9200 VID: V01, SN: FCW2146L02A"
        },
        {
          "propertyName": "deviceMNEId",
          "propertyValue": "542549"
        },
        {
          "propertyName": "encryption_software_indicator",
          "propertyValue": "Y"
        },
        {
          "propertyName": "mdf_id",
          "propertyValue": "223415874"
        }
      ],
      "isSMUApplicable": true,
      "goldenTagInheritedFromSiteUuid": null,
      "goldenTagInheritedFromSiteName": null,
      "importSupported": true,
      "reasonForNotSupportingImport": null,
      "tagList": [
        {
          "taggedGolden": false,
          "role": "ALL",
          "inheritedSiteName": "",
          "inheritedSiteUuid": ""
        },
        {
          "taggedGolden": false,
          "role": "CORE",
          "inheritedSiteName": null,
          "inheritedSiteUuid": null
        },
        {
          "taggedGolden": false,
          "role": "DISTRIBUTION",
          "inheritedSiteName": null,
          "inheritedSiteUuid": null
        },
        {
          "taggedGolden": false,
          "role": "BORDER ROUTER",
          "inheritedSiteName": null,
          "inheritedSiteUuid": null
        },
        {
          "taggedGolden": false,
          "role": "UNKNOWN",
          "inheritedSiteName": null,
          "inheritedSiteUuid": null
        },
        {
          "taggedGolden": false,
          "role": "ACCESS",
          "inheritedSiteName": null,
          "inheritedSiteUuid": null
        }
      ],
      "imageCategory": "PHYSICAL",
      "imageIntegrityStatus": "",
      "imageImported": true,
      "applicableDevicesForImage": [
        {
          "mdfId": "223415874",
          "productName": "Cisco Catalyst 9200 Switch Stack",
          "productId": [
            "C9200",
            "C9200-24T"
          ]
        }
      ],
      "profileInfo": null,
      "physicallyAvailable": true,
      "assignCount": 0,
      "showUnassign": false,
      "disableUnassign": false,
      "ccoreverseSync": false,
      "ccorecommended": false,
      "ccolatest": true
    },
    {
      "name": "cat9k_lite_iosxe.16.10.01s.SPA.bin",
      "imageUuid": "9b8452e4-0f57-5c88-91fe-6d829f66ac0c",
      "version": "16.10.1s",
      "softwareType": null,
      "imageType": "SYSTEM_SW",
      "isTaggedGolden": "false",
      "md5Checksum": "3a8aab6e2108d5523f406fcbceef0258",
      "shaCheckSum": "34ec104d5a21e041956cfdc9cd114cddaa15dd89c85cc85ddc2428048ca7339a2a342920d879ac47019d07f2a10dbf6e4e9ae962200218f81bbced5f9fed560a",
      "createdTime": "2019-04-28 04:26:13.64",
      "applicationType": "UNKNOWN",
      "feature": null,
      "fileServiceId": "d15d386c-125f-43e8-aa32-f2619cebe23c",
      "imageSeries": null,
      "imageSource": "CCO",
      "imageSourceType": "Imported from CCO",
      "imageFamily": "CAT9K",
      "vendor": "CISCO",
      "filesize": 690470821,
      "deviceCount": 0,
      "deviceUuidList": [],
      "smuCount": 0,
      "imageAttributes": [
        {
          "propertyName": "min_flash",
          "propertyValue": "4096"
        },
        {
          "propertyName": "image_guid",
          "propertyValue": "2FCBCEB5E46F7293EE8E239AD3E4A374EEF98D81"
        },
        {
          "propertyName": "release_fcs_date",
          "propertyValue": "18/Jan/2019"
        },
        {
          "propertyName": "image_size",
          "propertyValue": "690470821"
        },
        {
          "propertyName": "min_dram",
          "propertyValue": "2048"
        },
        {
          "propertyName": "image_description",
          "propertyValue": "CAT92xx"
        },
        {
          "propertyName": "metadata_trans_id",
          "propertyValue": "153426088"
        },
        {
          "propertyName": "udi",
          "propertyValue": "PID: C9200 VID: V01, SN: FCW2146L02A"
        },
        {
          "propertyName": "deviceMNEId",
          "propertyValue": "542549"
        },
        {
          "propertyName": "encryption_software_indicator",
          "propertyValue": "Y"
        },
        {
          "propertyName": "mdf_id",
          "propertyValue": "223415874"
        }
      ],
      "isSMUApplicable": true,
      "goldenTagInheritedFromSiteUuid": null,
      "goldenTagInheritedFromSiteName": null,
      "importSupported": true,
      "reasonForNotSupportingImport": null,
      "tagList": [
        {
          "taggedGolden": false,
          "role": "ALL",
          "inheritedSiteName": "",
          "inheritedSiteUuid": ""
        },
        {
          "taggedGolden": false,
          "role": "CORE",
          "inheritedSiteName": null,
          "inheritedSiteUuid": null
        },
        {
          "taggedGolden": false,
          "role": "DISTRIBUTION",
          "inheritedSiteName": null,
          "inheritedSiteUuid": null
        },
        {
          "taggedGolden": false,
          "role": "BORDER ROUTER",
          "inheritedSiteName": null,
          "inheritedSiteUuid": null
        },
        {
          "taggedGolden": false,
          "role": "UNKNOWN",
          "inheritedSiteName": null,
          "inheritedSiteUuid": null
        },
        {
          "taggedGolden": false,
          "role": "ACCESS",
          "inheritedSiteName": null,
          "inheritedSiteUuid": null
        }
      ],
      "imageCategory": "PHYSICAL",
      "imageIntegrityStatus": "",
      "imageImported": true,
      "applicableDevicesForImage": [
        {
          "mdfId": "223415874",
          "productName": "Cisco Catalyst 9200 Switch Stack",
          "productId": [
            "C9200",
            "C9200-24T"
          ]
        }
      ],
      "profileInfo": null,
      "physicallyAvailable": true,
      "assignCount": 0,
      "showUnassign": false,
      "disableUnassign": false,
      "ccoreverseSync": false,
      "ccorecommended": false,
      "ccolatest": true
    }
  ]
});