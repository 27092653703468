define({
"Template":{
  "deviceId": "400ec95f-696a-4730-9936-66eccefe8cf9",
  "stackSwitchInfo": [
    {
      "hwPriority": 0,
      "macAddress": "d4:ad:bd:e3:81:80",
      "numNextReload": 1,
      "role": "ACTIVE",
      "softwareImage": "CAT9K_IOSXE",
      "stackMemberNumber": 1,
      "state": "READY",
      "switchPriority": 1,
      "entPhysicalIndex": "1000",
      "serialNumber": "FJB2328A01F",
      "platformId": "C9300-24U"
    }
  ],
  "stackPortInfo": [
  ],
  "svlSwitchInfo": null
},
"stackPortInfo_Template":
    {
      "isSynchOk": "Yes",
      "name": "StackSub-St1-2",
      "switchPort": "1/2",
      "neighborPort": "NONE/NONE",
      "nrLinkOkChanges": 0,
      "stackCableLengthInfo": "NO_CABLE",
      "stackPortOperStatusInfo": "DOWN",
      "linkActive": false,
      "linkOk": false
    }
})