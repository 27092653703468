define({
    "Template":
   {
  "version": "1.0",
  "response": [
   {
          "time": "2018-05-08T03:00:00.000+0000",
          "healthScore": 8,
          "packetLoss": 0.08,
          "latency": 28.44,
          "applicationDelay": 12.49
        },
        {
          "time": "2018-05-08T02:55:00.000+0000",
          "healthScore": 9.79,
          "packetLoss": 0.01,
          "latency": 25.79,
          "applicationDelay": 13.21
        },
        {
          "time": "2018-05-08T02:50:00.000+0000",
          "healthScore": 8.9,
          "packetLoss": 0.61,
          "latency": 14.46,
          "applicationDelay": 6.44
        },
        {
          "time": "2018-05-08T02:45:00.000+0000",
          "healthScore": 8.28,
          "packetLoss": 7,
          "latency": 30.59,
          "applicationDelay": 5.11
        },
        {
          "time": "2018-05-08T02:40:00.000+0000",
          "healthScore": 9.67,
          "packetLoss": 0.01,
          "latency": 33.31,
          "applicationDelay": 5.29
        },
        {
          "time": "2018-05-08T02:35:00.000+0000",
          "healthScore": 9.58,
          "packetLoss": 0.01,
          "latency": 49.25,
          "applicationDelay": 4.83
        },
        {
          "time": "2018-05-08T02:30:00.000+0000",
          "healthScore": 6.19,
          "packetLoss": 8.84,
          "latency": 19.71,
          "applicationDelay": 15.95
        },
        {
          "time": "2018-05-08T02:25:00.000+0000",
          "healthScore": 10,
          "packetLoss": 0,
          "latency": 2.56,
          "applicationDelay": 5.16
        },
        {
          "time": "2018-05-08T02:20:00.000+0000",
          "healthScore": 9.17,
          "packetLoss": 0.66,
          "latency": 2.06,
          "applicationDelay": 14.31
        },
        {
          "time": "2018-05-08T02:15:00.000+0000",
          "healthScore": 8.8,
          "packetLoss": 8.33,
          "latency": 1.63,
          "applicationDelay": 4.78
        },
        {
          "time": "2018-05-08T02:10:00.000+0000",
          "healthScore": 9.67,
          "packetLoss": 0.21,
          "latency": 9.28,
          "applicationDelay": 3.94
        },
        {
          "time": "2018-05-08T02:05:00.000+0000",
          "healthScore": 9.2,
          "packetLoss": 0.06,
          "latency": 59.2,
          "applicationDelay": 30.6
        },
        {
          "time": "2018-05-08T02:00:00.000+0000",
          "healthScore": 9.85,
          "packetLoss": 0.01,
          "latency": 13.58,
          "applicationDelay": 10.57
        },
        {
          "time": "2018-05-08T01:55:00.000+0000",
          "healthScore": 9.44,
          "packetLoss": 0.08,
          "latency": 14.56,
          "applicationDelay": 2.83
        },
        {
          "time": "2018-05-08T01:50:00.000+0000",
          "healthScore": 8.58,
          "packetLoss": 1.05,
          "latency": 18.05,
          "applicationDelay": 5.83
        },
        {
          "time": "2018-05-08T01:45:00.000+0000",
          "healthScore": 8.28,
          "packetLoss": 8.34,
          "latency": 22.97,
          "applicationDelay": 4.53
        },
        {
          "time": "2018-05-08T01:40:00.000+0000",
          "healthScore": 9.08,
          "packetLoss": 1.82,
          "latency": 46.25,
          "applicationDelay": 34.08
        },
        {
          "time": "2018-05-08T01:35:00.000+0000",
          "healthScore": 9.5,
          "packetLoss": 0.07,
          "latency": 28.19,
          "applicationDelay": 5
        },
        {
          "time": "2018-05-08T01:30:00.000+0000",
          "healthScore": 6.42,
          "packetLoss": 10.15,
          "latency": 46.93,
          "applicationDelay": 26.92
        },
        {
          "time": "2018-05-08T01:25:00.000+0000",
          "healthScore": 9.79,
          "packetLoss": 0.02,
          "latency": 20.69,
          "applicationDelay": 6.71
        },
        {
          "time": "2018-05-08T01:20:00.000+0000",
          "healthScore": 7.56,
          "packetLoss": 1.88,
          "latency": 45.61,
          "applicationDelay": 4.56
        },
        {
          "time": "2018-05-08T01:15:00.000+0000",
          "healthScore": 7.25,
          "packetLoss": 7.84,
          "latency": 436.42,
          "applicationDelay": 35.08
        },
        {
          "time": "2018-05-08T01:10:00.000+0000",
          "healthScore": 9.31,
          "packetLoss": 1.53,
          "latency": 25.94,
          "applicationDelay": 6.25
        },
        {
          "time": "2018-05-08T01:05:00.000+0000",
          "healthScore": 9.13,
          "packetLoss": 0.07,
          "latency": 221.47,
          "applicationDelay": 3.47
        },
        {
          "time": "2018-05-08T01:00:00.000+0000",
          "healthScore": 7.72,
          "packetLoss": 6.37,
          "latency": 102.03,
          "applicationDelay": 6.01
        },
        {
          "time": "2018-05-08T00:55:00.000+0000",
          "healthScore": 8.92,
          "packetLoss": 0.87,
          "latency": 45.08,
          "applicationDelay": 5.5
        },
        {
          "time": "2018-05-08T00:50:00.000+0000",
          "healthScore": 8.17,
          "packetLoss": 0.99,
          "latency": 125.81,
          "applicationDelay": 12.19
        },
        {
          "time": "2018-05-08T00:45:00.000+0000",
          "healthScore": 8.17,
          "packetLoss": 5.7,
          "latency": 30.94,
          "applicationDelay": 8.12
        },
        {
          "time": "2018-05-08T00:40:00.000+0000",
          "healthScore": 8.75,
          "packetLoss": 1.53,
          "latency": 5961.97,
          "applicationDelay": 4.28
        },
        {
          "time": "2018-05-08T00:35:00.000+0000",
          "healthScore": 9.75,
          "packetLoss": 0.02,
          "latency": 24.22,
          "applicationDelay": 5.16
        },
        {
          "time": "2018-05-08T00:30:00.000+0000",
          "healthScore": 6.5,
          "packetLoss": 5.92,
          "latency": 73.94,
          "applicationDelay": 23.06
        },
        {
          "time": "2018-05-08T00:25:00.000+0000",
          "healthScore": 9.56,
          "packetLoss": 0.04,
          "latency": 5.33,
          "applicationDelay": 13.67
        },
        {
          "time": "2018-05-08T00:20:00.000+0000",
          "healthScore": 8.31,
          "packetLoss": 8.56,
          "latency": 42,
          "applicationDelay": 4.94
        },
        {
          "time": "2018-05-08T00:15:00.000+0000",
          "healthScore": 7.11,
          "packetLoss": 6.76,
          "latency": 738.11,
          "applicationDelay": 18.78
        },
        {
          "time": "2018-05-08T00:10:00.000+0000",
          "healthScore": 9.26,
          "packetLoss": 0.42,
          "latency": 17.36,
          "applicationDelay": 3.9
        },
        {
          "time": "2018-05-08T00:05:00.000+0000",
          "healthScore": 9.2,
          "packetLoss": 0.08,
          "latency": 175.8,
          "applicationDelay": 3.7
        },
        {
          "time": "2018-05-08T00:00:00.000+0000",
          "healthScore": 9.61,
          "packetLoss": 0.07,
          "latency": 29.39,
          "applicationDelay": 41.78
        },
        {
          "time": "2018-05-07T23:55:00.000+0000",
          "healthScore": 10,
          "packetLoss": 0,
          "latency": 1.88,
          "applicationDelay": 8.08
        },
        {
          "time": "2018-05-07T23:50:00.000+0000",
          "healthScore": 8.75,
          "packetLoss": 5.23,
          "latency": 2.25,
          "applicationDelay": 4.58
        },
        {
          "time": "2018-05-07T23:45:00.000+0000",
          "healthScore": 8.47,
          "packetLoss": 6.99,
          "latency": 3.67,
          "applicationDelay": 4.18
        },
        {
          "time": "2018-05-07T23:40:00.000+0000",
          "healthScore": 10,
          "packetLoss": 0,
          "latency": 2.33,
          "applicationDelay": 5.5
        },
        {
          "time": "2018-05-07T23:35:00.000+0000",
          "healthScore": 9.5,
          "packetLoss": 0.04,
          "latency": 4.5,
          "applicationDelay": 14.25
        },
        {
          "time": "2018-05-07T23:30:00.000+0000",
          "healthScore": 7.59,
          "packetLoss": 7.3,
          "latency": 4.69,
          "applicationDelay": 7.06
        },
        {
          "time": "2018-05-07T23:25:00.000+0000",
          "healthScore": 9.5,
          "packetLoss": 0.04,
          "latency": 2.19,
          "applicationDelay": 5.84
        },
        {
          "time": "2018-05-07T23:20:00.000+0000",
          "healthScore": 9.17,
          "packetLoss": 0.6,
          "latency": 2.5,
          "applicationDelay": 3.17
        },
        {
          "time": "2018-05-07T23:15:00.000+0000",
          "healthScore": 8,
          "packetLoss": 13.89,
          "latency": 1.4,
          "applicationDelay": 4.9
        },
        {
          "time": "2018-05-07T23:10:00.000+0000",
          "healthScore": 9.7,
          "packetLoss": 0.02,
          "latency": 1.4,
          "applicationDelay": 41.6
        },
        {
          "time": "2018-05-07T23:05:00.000+0000",
          "healthScore": 9.72,
          "packetLoss": 0.05,
          "latency": 2.06,
          "applicationDelay": 3.17
        },
        {
          "time": "2018-05-07T23:00:00.000+0000",
          "healthScore": 10,
          "packetLoss": 0,
          "latency": 4.1,
          "applicationDelay": 9.03
        },
        {
          "time": "2018-05-07T22:55:00.000+0000",
          "healthScore": 9.49,
          "packetLoss": 0.05,
          "latency": 1.96,
          "applicationDelay": 3.87
        },
        {
          "time": "2018-05-07T22:50:00.000+0000",
          "healthScore": 8.1,
          "packetLoss": 1.57,
          "latency": 1.56,
          "applicationDelay": 4.48
        },
        {
          "time": "2018-05-07T22:45:00.000+0000",
          "healthScore": 7.94,
          "packetLoss": 13.89,
          "latency": 1.28,
          "applicationDelay": 4.39
        },
        {
          "time": "2018-05-07T22:40:00.000+0000",
          "healthScore": 10,
          "packetLoss": 0.01,
          "latency": 2.4,
          "applicationDelay": 4.8
        },
        {
          "time": "2018-05-07T22:35:00.000+0000",
          "healthScore": 9.38,
          "packetLoss": 0.13,
          "latency": 2.33,
          "applicationDelay": 5.38
        },
        {
          "time": "2018-05-07T22:30:00.000+0000",
          "healthScore": 6.4,
          "packetLoss": 10.57,
          "latency": 2.96,
          "applicationDelay": 6.26
        },
        {
          "time": "2018-05-07T22:25:00.000+0000",
          "healthScore": 9.75,
          "packetLoss": 0.03,
          "latency": 2.31,
          "applicationDelay": 7.38
        },
        {
          "time": "2018-05-07T22:20:00.000+0000",
          "healthScore": 8.33,
          "packetLoss": 1.4,
          "latency": 1.63,
          "applicationDelay": 3.21
        },
        {
          "time": "2018-05-07T22:15:00.000+0000",
          "healthScore": 7.93,
          "packetLoss": 13.89,
          "latency": 1.78,
          "applicationDelay": 19.13
        },
        {
          "time": "2018-05-07T22:10:00.000+0000",
          "healthScore": 10,
          "packetLoss": 0,
          "latency": 2.83,
          "applicationDelay": 4.17
        },
        {
          "time": "2018-05-07T22:05:00.000+0000",
          "healthScore": 8,
          "packetLoss": 3.57,
          "latency": 1.38,
          "applicationDelay": 5.33
        },
        {
          "time": "2018-05-07T22:00:00.000+0000",
          "healthScore": 10,
          "packetLoss": 0,
          "latency": 15.67,
          "applicationDelay": 21.89
        },
        {
          "time": "2018-05-07T21:55:00.000+0000",
          "healthScore": 10,
          "packetLoss": 0.02,
          "latency": 4.21,
          "applicationDelay": 5.86
        },
        {
          "time": "2018-05-07T21:50:00.000+0000",
          "healthScore": 7.96,
          "packetLoss": 13.89,
          "latency": 2.38,
          "applicationDelay": 23.74
        },
        {
          "time": "2018-05-07T21:45:00.000+0000",
          "healthScore": 8.82,
          "packetLoss": 3.94,
          "latency": 1.17,
          "applicationDelay": 3.91
        },
        {
          "time": "2018-05-07T21:40:00.000+0000",
          "healthScore": 9.26,
          "packetLoss": 1.45,
          "latency": 1.74,
          "applicationDelay": 4.96
        },
        {
          "time": "2018-05-07T21:35:00.000+0000",
          "healthScore": 9.33,
          "packetLoss": 0.05,
          "latency": 3,
          "applicationDelay": 4.33
        },
        {
          "time": "2018-05-07T21:30:00.000+0000",
          "healthScore": 6.57,
          "packetLoss": 13.33,
          "latency": 15.81,
          "applicationDelay": 21.24
        },
        {
          "time": "2018-05-07T21:25:00.000+0000",
          "healthScore": 9.31,
          "packetLoss": 0.04,
          "latency": 4.06,
          "applicationDelay": 11.69
        },
        {
          "time": "2018-05-07T21:20:00.000+0000",
          "healthScore": 10,
          "packetLoss": 0,
          "latency": 2.25,
          "applicationDelay": 6.5
        },
        {
          "time": "2018-05-07T21:15:00.000+0000",
          "healthScore": 10,
          "packetLoss": 0,
          "latency": 2,
          "applicationDelay": 5
        },
        {
          "time": "2018-05-07T21:10:00.000+0000",
          "healthScore": 10,
          "packetLoss": 0,
          "latency": 1.83,
          "applicationDelay": 4.67
        },
        {
          "time": "2018-05-07T21:05:00.000+0000",
          "healthScore": 10,
          "packetLoss": 0,
          "latency": 2.2,
          "applicationDelay": 6.6
        },
        {
          "time": "2018-05-07T21:00:00.000+0000",
          "healthScore": 9.83,
          "packetLoss": 0.01,
          "latency": 15.72,
          "applicationDelay": 22.28
        },
        {
          "time": "2018-05-07T20:55:00.000+0000",
          "healthScore": 10,
          "packetLoss": 0,
          "latency": 1.72,
          "applicationDelay": 10.89
        },
        {
          "time": "2018-05-07T20:50:00.000+0000",
          "healthScore": 10,
          "packetLoss": 0,
          "latency": 3,
          "applicationDelay": 8
        },
        {
          "time": "2018-05-07T20:45:00.000+0000",
          "healthScore": 10,
          "packetLoss": 0.01,
          "latency": 1.5,
          "applicationDelay": 5.25
        },
        {
          "time": "2018-05-07T20:40:00.000+0000",
          "healthScore": 9.7,
          "packetLoss": 0.02,
          "latency": 2.2,
          "applicationDelay": 11.3
        },
        {
          "time": "2018-05-07T20:35:00.000+0000",
          "healthScore": 9.36,
          "packetLoss": 1.26,
          "latency": 1.43,
          "applicationDelay": 37.36
        },
        {
          "time": "2018-05-07T20:30:00.000+0000",
          "healthScore": 7.97,
          "packetLoss": 2.47,
          "latency": 11.89,
          "applicationDelay": 18.19
        },
        {
          "time": "2018-05-07T20:25:00.000+0000",
          "healthScore": 9.67,
          "packetLoss": 0.03,
          "latency": 1.92,
          "applicationDelay": 5.75
        },
        {
          "time": "2018-05-07T20:20:00.000+0000",
          "healthScore": 10,
          "packetLoss": 0,
          "latency": 2.75,
          "applicationDelay": 6.25
        },
        {
          "time": "2018-05-07T20:15:00.000+0000",
          "healthScore": 10,
          "packetLoss": 0,
          "latency": 2.38,
          "applicationDelay": 4.5
        },
        {
          "time": "2018-05-07T20:10:00.000+0000",
          "healthScore": 9,
          "packetLoss": 1.35,
          "latency": 2.25,
          "applicationDelay": 10.38
        },
        {
          "time": "2018-05-07T20:05:00.000+0000",
          "healthScore": 10,
          "packetLoss": 0,
          "latency": 2.33,
          "applicationDelay": 5.67
        },
        {
          "time": "2018-05-07T20:00:00.000+0000",
          "healthScore": 10,
          "packetLoss": 0,
          "latency": 12.15,
          "applicationDelay": 18.45
        },
        {
          "time": "2018-05-07T19:55:00.000+0000",
          "healthScore": 10,
          "packetLoss": 0,
          "latency": 3.25,
          "applicationDelay": 4.75
        },
        {
          "time": "2018-05-07T19:50:00.000+0000",
          "healthScore": 10,
          "packetLoss": 0,
          "latency": 1.75,
          "applicationDelay": 4.75
        },
        {
          "time": "2018-05-07T19:45:00.000+0000",
          "healthScore": 9.75,
          "packetLoss": 0.02,
          "latency": 3.5,
          "applicationDelay": 36.38
        },
        {
          "time": "2018-05-07T19:40:00.000+0000",
          "healthScore": 9.75,
          "packetLoss": 0.02,
          "latency": 1.58,
          "applicationDelay": 4.58
        },
        {
          "time": "2018-05-07T19:35:00.000+0000",
          "healthScore": 10,
          "packetLoss": 0,
          "latency": 1.9,
          "applicationDelay": 5.43
        },
        {
          "time": "2018-05-07T19:30:00.000+0000",
          "healthScore": 6.33,
          "packetLoss": 11.79,
          "latency": 14.56,
          "applicationDelay": 21.39
        },
        {
          "time": "2018-05-07T19:25:00.000+0000",
          "healthScore": 9.75,
          "packetLoss": 0.03,
          "latency": 2.75,
          "applicationDelay": 6.31
        },
        {
          "time": "2018-05-07T19:20:00.000+0000",
          "healthScore": 10,
          "packetLoss": 0,
          "latency": 2.1,
          "applicationDelay": 16.4
        },
        {
          "time": "2018-05-07T19:15:00.000+0000",
          "healthScore": 8.75,
          "packetLoss": 0.24,
          "latency": 1.5,
          "applicationDelay": 6
        },
        {
          "time": "2018-05-07T19:10:00.000+0000",
          "healthScore": 9.86,
          "packetLoss": 0.02,
          "latency": 1.95,
          "applicationDelay": 5
        },
        {
          "time": "2018-05-07T19:05:00.000+0000",
          "healthScore": 10,
          "packetLoss": 0,
          "latency": 2.33,
          "applicationDelay": 6.33
        },
        {
          "time": "2018-05-07T19:00:00.000+0000",
          "healthScore": 10,
          "packetLoss": 0,
          "latency": 15.08,
          "applicationDelay": 26.92
        },
        {
          "time": "2018-05-07T18:55:00.000+0000",
          "healthScore": 9.5,
          "packetLoss": 0.1,
          "latency": 2.1,
          "applicationDelay": 15.4
        },
        {
          "time": "2018-05-07T18:50:00.000+0000",
          "healthScore": 9,
          "packetLoss": 2.09,
          "latency": 1.5,
          "applicationDelay": 5
        },
        {
          "time": "2018-05-07T18:45:00.000+0000",
          "healthScore": 9.93,
          "packetLoss": 0.01,
          "latency": 2.27,
          "applicationDelay": 5.13
        },
        {
          "time": "2018-05-07T18:40:00.000+0000",
          "healthScore": 10,
          "packetLoss": 0,
          "latency": 1.38,
          "applicationDelay": 6
        },
        {
          "time": "2018-05-07T18:35:00.000+0000",
          "healthScore": 10,
          "packetLoss": 0,
          "latency": 2.38,
          "applicationDelay": 3.75
        },
        {
          "time": "2018-05-07T18:30:00.000+0000",
          "healthScore": 6.52,
          "packetLoss": 8.88,
          "latency": 14.62,
          "applicationDelay": 30.38
        },
        {
          "time": "2018-05-07T18:25:00.000+0000",
          "healthScore": 9.36,
          "packetLoss": 0.82,
          "latency": 65.29,
          "applicationDelay": 4.71
        },
        {
          "time": "2018-05-07T18:20:00.000+0000",
          "healthScore": 9.07,
          "packetLoss": 0.96,
          "latency": 127.27,
          "applicationDelay": 6.93
        },
        {
          "time": "2018-05-07T18:15:00.000+0000",
          "healthScore": 9.25,
          "packetLoss": 1.33,
          "latency": 86.92,
          "applicationDelay": 4.75
        },
        {
          "time": "2018-05-07T18:10:00.000+0000",
          "healthScore": 10,
          "packetLoss": 0,
          "latency": 3.25,
          "applicationDelay": 4.75
        },
        {
          "time": "2018-05-07T18:05:00.000+0000",
          "healthScore": 8.67,
          "packetLoss": 0.2,
          "latency": 1.5,
          "applicationDelay": 30.5
        },
        {
          "time": "2018-05-07T18:00:00.000+0000",
          "healthScore": 9.67,
          "packetLoss": 0.02,
          "latency": 16.42,
          "applicationDelay": 22.17
        },
        {
          "time": "2018-05-07T17:55:00.000+0000",
          "healthScore": 10,
          "packetLoss": 0.01,
          "latency": 5.67,
          "applicationDelay": 6.33
        },
        {
          "time": "2018-05-07T17:50:00.000+0000",
          "healthScore": 10,
          "packetLoss": 0,
          "latency": 1.58,
          "applicationDelay": 5.75
        },
        {
          "time": "2018-05-07T17:45:00.000+0000",
          "healthScore": 10,
          "packetLoss": 0,
          "latency": 1.75,
          "applicationDelay": 5.42
        },
        {
          "time": "2018-05-07T17:40:00.000+0000",
          "healthScore": 10,
          "packetLoss": 0,
          "latency": 3.64,
          "applicationDelay": 30.57
        },
        {
          "time": "2018-05-07T17:35:00.000+0000",
          "healthScore": 10,
          "packetLoss": 0.01,
          "latency": 3.25,
          "applicationDelay": 5.08
        },
        {
          "time": "2018-05-07T17:30:00.000+0000",
          "healthScore": 7.5,
          "packetLoss": 5.39,
          "latency": 11.46,
          "applicationDelay": 21.04
        },
        {
          "time": "2018-05-07T17:25:00.000+0000",
          "healthScore": 9.71,
          "packetLoss": 0.04,
          "latency": 2.36,
          "applicationDelay": 5.07
        },
        {
          "time": "2018-05-07T17:20:00.000+0000",
          "healthScore": 9.5,
          "packetLoss": 1.39,
          "latency": 1.83,
          "applicationDelay": 3.75
        },
        {
          "time": "2018-05-07T17:15:00.000+0000",
          "healthScore": 9.9,
          "packetLoss": 0.01,
          "latency": 2.1,
          "applicationDelay": 32.6
        },
        {
          "time": "2018-05-07T17:10:00.000+0000",
          "healthScore": 10,
          "packetLoss": 0,
          "latency": 2.2,
          "applicationDelay": 10.3
        },
        {
          "time": "2018-05-07T17:05:00.000+0000",
          "healthScore": 10,
          "packetLoss": 0,
          "latency": 2.56,
          "applicationDelay": 4.89
        },
        {
          "time": "2018-05-07T17:00:00.000+0000",
          "healthScore": 10,
          "packetLoss": 0,
          "latency": 15.67,
          "applicationDelay": 23.67
        },
        {
          "time": "2018-05-07T16:55:00.000+0000",
          "healthScore": 9.67,
          "packetLoss": 0.06,
          "latency": 1.92,
          "applicationDelay": 5.33
        },
        {
          "time": "2018-05-07T16:50:00.000+0000",
          "healthScore": 9.25,
          "packetLoss": 0.06,
          "latency": 2.12,
          "applicationDelay": 4.25
        },
        {
          "time": "2018-05-07T16:45:00.000+0000",
          "healthScore": 9.83,
          "packetLoss": 0.01,
          "latency": 1.92,
          "applicationDelay": 26.25
        },
        {
          "time": "2018-05-07T16:40:00.000+0000",
          "healthScore": 10,
          "packetLoss": 0,
          "latency": 3,
          "applicationDelay": 4.11
        },
        {
          "time": "2018-05-07T16:35:00.000+0000",
          "healthScore": 10,
          "packetLoss": 0,
          "latency": 1.75,
          "applicationDelay": 15
        },
        {
          "time": "2018-05-07T16:30:00.000+0000",
          "healthScore": 7.07,
          "packetLoss": 7.79,
          "latency": 14.81,
          "applicationDelay": 22.59
        },
        {
          "time": "2018-05-07T16:25:00.000+0000",
          "healthScore": 10,
          "packetLoss": 0,
          "latency": 2.31,
          "applicationDelay": 15.94
        },
        {
          "time": "2018-05-07T16:20:00.000+0000",
          "healthScore": 9.3,
          "packetLoss": 0.11,
          "latency": 3.3,
          "applicationDelay": 4.4
        },
        {
          "time": "2018-05-07T16:15:00.000+0000",
          "healthScore": 10,
          "packetLoss": 0,
          "latency": 1.33,
          "applicationDelay": 6.58
        },
        {
          "time": "2018-05-07T16:10:00.000+0000",
          "healthScore": 9.17,
          "packetLoss": 0.17,
          "latency": 2,
          "applicationDelay": 6.5
        },
        {
          "time": "2018-05-07T16:05:00.000+0000",
          "healthScore": 9.38,
          "packetLoss": 0.12,
          "latency": 2.25,
          "applicationDelay": 6
        },
        {
          "time": "2018-05-07T16:00:00.000+0000",
          "healthScore": 9.67,
          "packetLoss": 0.03,
          "latency": 15.19,
          "applicationDelay": 22.29
        },
        {
          "time": "2018-05-07T15:55:00.000+0000",
          "healthScore": 9.3,
          "packetLoss": 0.15,
          "latency": 1.9,
          "applicationDelay": 3.7
        },
        {
          "time": "2018-05-07T15:50:00.000+0000",
          "healthScore": 9.73,
          "packetLoss": 0.05,
          "latency": 1.4,
          "applicationDelay": 7.73
        },
        {
          "time": "2018-05-07T15:45:00.000+0000",
          "healthScore": 10,
          "packetLoss": 0.02,
          "latency": 3.33,
          "applicationDelay": 5.83
        },
        {
          "time": "2018-05-07T15:40:00.000+0000",
          "healthScore": 8.67,
          "packetLoss": 0.21,
          "latency": 2.67,
          "applicationDelay": 6.17
        },
        {
          "time": "2018-05-07T15:35:00.000+0000",
          "healthScore": 9.64,
          "packetLoss": 0.07,
          "latency": 2,
          "applicationDelay": 7.07
        },
        {
          "time": "2018-05-07T15:30:00.000+0000",
          "healthScore": 6.71,
          "packetLoss": 10.36,
          "latency": 15.75,
          "applicationDelay": 20.75
        },
        {
          "time": "2018-05-07T15:25:00.000+0000",
          "healthScore": 9.71,
          "packetLoss": 0.03,
          "latency": 2.14,
          "applicationDelay": 10.79
        },
        {
          "time": "2018-05-07T15:20:00.000+0000",
          "healthScore": 9.75,
          "packetLoss": 0.02,
          "latency": 2.42,
          "applicationDelay": 3.42
        },
        {
          "time": "2018-05-07T15:15:00.000+0000",
          "healthScore": 10,
          "packetLoss": 0,
          "latency": 2.5,
          "applicationDelay": 6.25
        },
    {
      "time": "2018-05-31T02:25:00.000+0000",
      "healthScore": 10.0,
      "packetLoss": 0.0,
      "latency": 0.0,
      "applicationDelay": 0.0
    },
    {
      "time": "2018-05-31T02:20:00.000+0000",
      "healthScore": 10.0,
      "packetLoss": 0.0,
      "latency": 0.0,
      "applicationDelay": 0.0
    },
    {
      "time": "2018-05-31T02:15:00.000+0000",
      "healthScore": 10.0,
      "packetLoss": 0.0,
      "latency": 0.0,
      "applicationDelay": 0.0
    },
    {
      "time": "2018-05-31T02:10:00.000+0000",
      "healthScore": 10.0,
      "packetLoss": 0.0,
      "latency": 0.0,
      "applicationDelay": 0.0
    },
    {
      "time": "2018-05-31T01:55:00.000+0000",
      "healthScore": 10.0,
      "packetLoss": 0.0,
      "latency": 0.0,
      "applicationDelay": 0.0
    },
    {
      "time": "2018-05-31T01:50:00.000+0000",
      "healthScore": 10.0,
      "packetLoss": 0.0,
      "latency": 0.0,
      "applicationDelay": 0.0
    },
    {
      "time": "2018-05-31T01:45:00.000+0000",
      "healthScore": 10.0,
      "packetLoss": 0.0,
      "latency": 0.0,
      "applicationDelay": 0.0
    },
    {
      "time": "2018-05-31T01:35:00.000+0000",
      "healthScore": 10.0,
      "packetLoss": 0.0,
      "latency": 0.0,
      "applicationDelay": 0.0
    },
    {
      "time": "2018-05-31T01:25:00.000+0000",
      "healthScore": 10.0,
      "packetLoss": 0.0,
      "latency": 0.0,
      "applicationDelay": 0.0
    },
    {
      "time": "2018-05-31T01:20:00.000+0000",
      "healthScore": 10.0,
      "packetLoss": 0.0,
      "latency": 0.0,
      "applicationDelay": 0.0
    },
    {
      "time": "2018-05-31T01:10:00.000+0000",
      "healthScore": 10.0,
      "packetLoss": 0.0,
      "latency": 0.0,
      "applicationDelay": 0.0
    },
    {
      "time": "2018-05-31T01:05:00.000+0000",
      "healthScore": 10.0,
      "packetLoss": 0.0,
      "latency": 0.0,
      "applicationDelay": 0.0
    },
    {
      "time": "2018-05-31T00:50:00.000+0000",
      "healthScore": 10.0,
      "packetLoss": 0.0,
      "latency": 0.0,
      "applicationDelay": 0.0
    },
    {
      "time": "2018-05-31T00:35:00.000+0000",
      "healthScore": 10.0,
      "packetLoss": 0.0,
      "latency": 0.0,
      "applicationDelay": 0.0
    },
    {
      "time": "2018-05-31T00:30:00.000+0000",
      "healthScore": 10.0,
      "packetLoss": 0.0,
      "latency": 0.0,
      "applicationDelay": 0.0
    },
    {
      "time": "2018-05-31T00:25:00.000+0000",
      "healthScore": 10.0,
      "packetLoss": 0.0,
      "latency": 0.0,
      "applicationDelay": 0.0
    },
    {
      "time": "2018-05-31T00:20:00.000+0000",
      "healthScore": 10.0,
      "packetLoss": 0.0,
      "latency": 0.0,
      "applicationDelay": 0.0
    },
    {
      "time": "2018-05-31T00:00:00.000+0000",
      "healthScore": 10.0,
      "packetLoss": 0.0,
      "latency": 0.0,
      "applicationDelay": 0.0
    },
    {
      "time": "2018-05-30T23:50:00.000+0000",
      "healthScore": 10.0,
      "packetLoss": 0.0,
      "latency": 0.0,
      "applicationDelay": 0.0
    },
    {
      "time": "2018-05-30T23:45:00.000+0000",
      "healthScore": 10.0,
      "packetLoss": 0.0,
      "latency": 0.0,
      "applicationDelay": 0.0
    },
    {
      "time": "2018-05-30T23:40:00.000+0000",
      "healthScore": 10.0,
      "packetLoss": 0.0,
      "latency": 0.0,
      "applicationDelay": 0.0
    },
    {
      "time": "2018-05-30T23:30:00.000+0000",
      "healthScore": 10.0,
      "packetLoss": 0.0,
      "latency": 0.0,
      "applicationDelay": 0.0
    },
    {
      "time": "2018-05-30T23:25:00.000+0000",
      "healthScore": 10.0,
      "packetLoss": 0.0,
      "latency": 0.0,
      "applicationDelay": 0.0
    },
    {
      "time": "2018-05-30T23:20:00.000+0000",
      "healthScore": 10.0,
      "packetLoss": 0.0,
      "latency": 0.0,
      "applicationDelay": 0.0
    },
    {
      "time": "2018-05-30T23:10:00.000+0000",
      "healthScore": 10.0,
      "packetLoss": 0.0,
      "latency": 0.0,
      "applicationDelay": 0.0
    },
    {
      "time": "2018-05-30T23:00:00.000+0000",
      "healthScore": 10.0,
      "packetLoss": 0.0,
      "latency": 0.0,
      "applicationDelay": 0.0
    },
    {
      "time": "2018-05-30T22:50:00.000+0000",
      "healthScore": 10.0,
      "packetLoss": 0.0,
      "latency": 0.0,
      "applicationDelay": 0.0
    },
    {
      "time": "2018-05-30T22:45:00.000+0000",
      "healthScore": 10.0,
      "packetLoss": 0.0,
      "latency": 0.0,
      "applicationDelay": 0.0
    },
    {
      "time": "2018-05-30T22:25:00.000+0000",
      "healthScore": 10.0,
      "packetLoss": 0.0,
      "latency": 0.0,
      "applicationDelay": 0.0
    },
    {
      "time": "2018-05-30T22:20:00.000+0000",
      "healthScore": 10.0,
      "packetLoss": 0.0,
      "latency": 0.0,
      "applicationDelay": 0.0
    },
    {
      "time": "2018-05-30T22:15:00.000+0000",
      "healthScore": 10.0,
      "packetLoss": 0.0,
      "latency": 0.0,
      "applicationDelay": 0.0
    },
    {
      "time": "2018-05-30T22:10:00.000+0000",
      "healthScore": 10.0,
      "packetLoss": 0.0,
      "latency": 0.0,
      "applicationDelay": 0.0
    },
    {
      "time": "2018-05-30T22:05:00.000+0000",
      "healthScore": 10.0,
      "packetLoss": 0.0,
      "latency": 0.0,
      "applicationDelay": 0.0
    },
    {
      "time": "2018-05-30T21:40:00.000+0000",
      "healthScore": 10.0,
      "packetLoss": 0.0,
      "latency": 0.0,
      "applicationDelay": 0.0
    },
    {
      "time": "2018-05-30T21:30:00.000+0000",
      "healthScore": 10.0,
      "packetLoss": 0.0,
      "latency": 0.0,
      "applicationDelay": 0.0
    },
    {
      "time": "2018-05-30T21:25:00.000+0000",
      "healthScore": 10.0,
      "packetLoss": 0.0,
      "latency": 0.0,
      "applicationDelay": 0.0
    },
    {
      "time": "2018-05-30T21:15:00.000+0000",
      "healthScore": 10.0,
      "packetLoss": 0.0,
      "latency": 0.0,
      "applicationDelay": 0.0
    },
    {
      "time": "2018-05-30T21:10:00.000+0000",
      "healthScore": 10.0,
      "packetLoss": 0.0,
      "latency": 0.0,
      "applicationDelay": 0.0
    },
    {
      "time": "2018-05-30T21:00:00.000+0000",
      "healthScore": 10.0,
      "packetLoss": 0.0,
      "latency": 0.0,
      "applicationDelay": 0.0
    },
    {
      "time": "2018-05-30T20:55:00.000+0000",
      "healthScore": 10.0,
      "packetLoss": 0.0,
      "latency": 0.0,
      "applicationDelay": 0.0
    },
    {
      "time": "2018-05-30T20:40:00.000+0000",
      "healthScore": 10.0,
      "packetLoss": 0.0,
      "latency": 0.0,
      "applicationDelay": 0.0
    },
    {
      "time": "2018-05-30T20:30:00.000+0000",
      "healthScore": 10.0,
      "packetLoss": 0.0,
      "latency": 0.0,
      "applicationDelay": 0.0
    },
    {
      "time": "2018-05-30T20:25:00.000+0000",
      "healthScore": 10.0,
      "packetLoss": 0.0,
      "latency": 0.0,
      "applicationDelay": 0.0
    },
    {
      "time": "2018-05-30T20:10:00.000+0000",
      "healthScore": 10.0,
      "packetLoss": 0.0,
      "latency": 0.0,
      "applicationDelay": 0.0
    },
    {
      "time": "2018-05-30T20:05:00.000+0000",
      "healthScore": 10.0,
      "packetLoss": 0.0,
      "latency": 0.0,
      "applicationDelay": 0.0
    },
    {
      "time": "2018-05-30T19:55:00.000+0000",
      "healthScore": 10.0,
      "packetLoss": 0.0,
      "latency": 0.0,
      "applicationDelay": 0.0
    },
    {
      "time": "2018-05-30T19:50:00.000+0000",
      "healthScore": 10.0,
      "packetLoss": 0.0,
      "latency": 0.0,
      "applicationDelay": 0.0
    },
    {
      "time": "2018-05-30T19:40:00.000+0000",
      "healthScore": 10.0,
      "packetLoss": 0.0,
      "latency": 0.0,
      "applicationDelay": 0.0
    },
    {
      "time": "2018-05-30T19:25:00.000+0000",
      "healthScore": 10.0,
      "packetLoss": 0.0,
      "latency": 0.0,
      "applicationDelay": 0.0
    },
    {
      "time": "2018-05-30T19:20:00.000+0000",
      "healthScore": 10.0,
      "packetLoss": 0.0,
      "latency": 0.0,
      "applicationDelay": 0.0
    },
    {
      "time": "2018-05-30T19:10:00.000+0000",
      "healthScore": 10.0,
      "packetLoss": 0.0,
      "latency": 0.0,
      "applicationDelay": 0.0
    },
    {
      "time": "2018-05-30T19:05:00.000+0000",
      "healthScore": 10.0,
      "packetLoss": 0.0,
      "latency": 0.0,
      "applicationDelay": 0.0
    },
    {
      "time": "2018-05-30T19:00:00.000+0000",
      "healthScore": 10.0,
      "packetLoss": 0.0,
      "latency": 0.0,
      "applicationDelay": 0.0
    },
    {
      "time": "2018-05-30T18:55:00.000+0000",
      "healthScore": 10.0,
      "packetLoss": 0.0,
      "latency": 0.0,
      "applicationDelay": 0.0
    },
    {
      "time": "2018-05-30T18:50:00.000+0000",
      "healthScore": 10.0,
      "packetLoss": 0.0,
      "latency": 0.0,
      "applicationDelay": 0.0
    },
    {
      "time": "2018-05-30T18:40:00.000+0000",
      "healthScore": 10.0,
      "packetLoss": 0.0,
      "latency": 0.0,
      "applicationDelay": 0.0
    },
    {
      "time": "2018-05-30T18:35:00.000+0000",
      "healthScore": 10.0,
      "packetLoss": 0.0,
      "latency": 0.0,
      "applicationDelay": 0.0
    },
    {
      "time": "2018-05-30T18:25:00.000+0000",
      "healthScore": 10.0,
      "packetLoss": 0.0,
      "latency": 0.0,
      "applicationDelay": 0.0
    },
    {
      "time": "2018-05-30T18:10:00.000+0000",
      "healthScore": 10.0,
      "packetLoss": 0.0,
      "latency": 0.0,
      "applicationDelay": 0.0
    },
    {
      "time": "2018-05-30T18:05:00.000+0000",
      "healthScore": 10.0,
      "packetLoss": 0.0,
      "latency": 0.0,
      "applicationDelay": 0.0
    },
    {
      "time": "2018-05-30T18:00:00.000+0000",
      "healthScore": 10.0,
      "packetLoss": 0.0,
      "latency": 0.0,
      "applicationDelay": 0.0
    },
    {
      "time": "2018-05-30T17:55:00.000+0000",
      "healthScore": 10.0,
      "packetLoss": 0.0,
      "latency": 0.0,
      "applicationDelay": 0.0
    },
    {
      "time": "2018-05-30T17:45:00.000+0000",
      "healthScore": 10.0,
      "packetLoss": 0.0,
      "latency": 0.0,
      "applicationDelay": 0.0
    },
    {
      "time": "2018-05-30T17:30:00.000+0000",
      "healthScore": 10.0,
      "packetLoss": 0.0,
      "latency": 0.0,
      "applicationDelay": 0.0
    },
    {
      "time": "2018-05-30T17:25:00.000+0000",
      "healthScore": 10.0,
      "packetLoss": 0.0,
      "latency": 0.0,
      "applicationDelay": 0.0
    },
    {
      "time": "2018-05-30T17:20:00.000+0000",
      "healthScore": 10.0,
      "packetLoss": 0.0,
      "latency": 0.0,
      "applicationDelay": 0.0
    },
    {
      "time": "2018-05-30T17:05:00.000+0000",
      "healthScore": 10.0,
      "packetLoss": 0.0,
      "latency": 0.0,
      "applicationDelay": 0.0
    },
    {
      "time": "2018-05-30T17:00:00.000+0000",
      "healthScore": 10.0,
      "packetLoss": 0.0,
      "latency": 0.0,
      "applicationDelay": 0.0
    },
    {
      "time": "2018-05-30T16:55:00.000+0000",
      "healthScore": 10.0,
      "packetLoss": 0.0,
      "latency": 0.0,
      "applicationDelay": 0.0
    },
    {
      "time": "2018-05-30T16:50:00.000+0000",
      "healthScore": 10.0,
      "packetLoss": 0.0,
      "latency": 0.0,
      "applicationDelay": 0.0
    },
    {
      "time": "2018-05-30T16:40:00.000+0000",
      "healthScore": 10.0,
      "packetLoss": 0.0,
      "latency": 0.0,
      "applicationDelay": 0.0
    },
    {
      "time": "2018-05-30T16:35:00.000+0000",
      "healthScore": 10.0,
      "packetLoss": 0.0,
      "latency": 0.0,
      "applicationDelay": 0.0
    },
    {
      "time": "2018-05-30T16:30:00.000+0000",
      "healthScore": 10.0,
      "packetLoss": 0.0,
      "latency": 0.0,
      "applicationDelay": 0.0
    },
    {
      "time": "2018-05-30T16:25:00.000+0000",
      "healthScore": 10.0,
      "packetLoss": 0.0,
      "latency": 0.0,
      "applicationDelay": 0.0
    },
    {
      "time": "2018-05-30T16:20:00.000+0000",
      "healthScore": 10.0,
      "packetLoss": 0.0,
      "latency": 0.0,
      "applicationDelay": 0.0
    },
    {
      "time": "2018-05-30T16:15:00.000+0000",
      "healthScore": 10.0,
      "packetLoss": 0.0,
      "latency": 0.0,
      "applicationDelay": 0.0
    },
    {
      "time": "2018-05-30T16:10:00.000+0000",
      "healthScore": 10.0,
      "packetLoss": 0.0,
      "latency": 0.0,
      "applicationDelay": 0.0
    },
    {
      "time": "2018-05-30T16:05:00.000+0000",
      "healthScore": 10.0,
      "packetLoss": 0.0,
      "latency": 0.0,
      "applicationDelay": 0.0
    },
    {
      "time": "2018-05-30T16:00:00.000+0000",
      "healthScore": 10.0,
      "packetLoss": 0.0,
      "latency": 0.0,
      "applicationDelay": 0.0
    },
    {
      "time": "2018-05-30T15:55:00.000+0000",
      "healthScore": 10.0,
      "packetLoss": 0.0,
      "latency": 0.0,
      "applicationDelay": 0.0
    },
    {
      "time": "2018-05-30T15:45:00.000+0000",
      "healthScore": 10.0,
      "packetLoss": 0.0,
      "latency": 0.0,
      "applicationDelay": 0.0
    },
    {
      "time": "2018-05-30T15:35:00.000+0000",
      "healthScore": 10.0,
      "packetLoss": 0.0,
      "latency": 0.0,
      "applicationDelay": 0.0
    },
    {
      "time": "2018-05-30T15:30:00.000+0000",
      "healthScore": 10.0,
      "packetLoss": 0.0,
      "latency": 0.0,
      "applicationDelay": 0.0
    },
    {
      "time": "2018-05-30T15:25:00.000+0000",
      "healthScore": 10.0,
      "packetLoss": 0.0,
      "latency": 0.0,
      "applicationDelay": 0.0
    },
    {
      "time": "2018-05-30T15:15:00.000+0000",
      "healthScore": 10.0,
      "packetLoss": 0.0,
      "latency": 0.0,
      "applicationDelay": 0.0
    },
    {
      "time": "2018-05-30T15:10:00.000+0000",
      "healthScore": 10.0,
      "packetLoss": 0.0,
      "latency": 0.0,
      "applicationDelay": 0.0
    },
    {
      "time": "2018-05-30T15:05:00.000+0000",
      "healthScore": 10.0,
      "packetLoss": 0.0,
      "latency": 0.0,
      "applicationDelay": 0.0
    },
    {
      "time": "2018-05-30T14:55:00.000+0000",
      "healthScore": 10.0,
      "packetLoss": 0.0,
      "latency": 0.0,
      "applicationDelay": 0.0
    },
    {
      "time": "2018-05-30T14:45:00.000+0000",
      "healthScore": 10.0,
      "packetLoss": 0.0,
      "latency": 0.0,
      "applicationDelay": 0.0
    },
    {
      "time": "2018-05-30T14:40:00.000+0000",
      "healthScore": 10.0,
      "packetLoss": 0.0,
      "latency": 0.0,
      "applicationDelay": 0.0
    },
    {
      "time": "2018-05-30T14:25:00.000+0000",
      "healthScore": 10.0,
      "packetLoss": 0.0,
      "latency": 0.0,
      "applicationDelay": 0.0
    },
    {
      "time": "2018-05-30T14:05:00.000+0000",
      "healthScore": 10.0,
      "packetLoss": 0.0,
      "latency": 0.0,
      "applicationDelay": 0.0
    },
    {
      "time": "2018-05-30T13:55:00.000+0000",
      "healthScore": 10.0,
      "packetLoss": 0.0,
      "latency": 0.0,
      "applicationDelay": 0.0
    },
    {
      "time": "2018-05-30T13:50:00.000+0000",
      "healthScore": 10.0,
      "packetLoss": 0.0,
      "latency": 0.0,
      "applicationDelay": 0.0
    },
    {
      "time": "2018-05-30T13:45:00.000+0000",
      "healthScore": 10.0,
      "packetLoss": 0.0,
      "latency": 0.0,
      "applicationDelay": 0.0
    },
    {
      "time": "2018-05-30T13:40:00.000+0000",
      "healthScore": 10.0,
      "packetLoss": 0.0,
      "latency": 0.0,
      "applicationDelay": 0.0
    },
    {
      "time": "2018-05-30T13:35:00.000+0000",
      "healthScore": 10.0,
      "packetLoss": 0.0,
      "latency": 0.0,
      "applicationDelay": 0.0
    },
    {
      "time": "2018-05-30T13:30:00.000+0000",
      "healthScore": 10.0,
      "packetLoss": 0.0,
      "latency": 0.0,
      "applicationDelay": 0.0
    },
    {
      "time": "2018-05-30T13:25:00.000+0000",
      "healthScore": 10.0,
      "packetLoss": 0.0,
      "latency": 0.0,
      "applicationDelay": 0.0
    },
    {
      "time": "2018-05-30T13:15:00.000+0000",
      "healthScore": 10.0,
      "packetLoss": 0.0,
      "latency": 0.0,
      "applicationDelay": 0.0
    },
    {
      "time": "2018-05-30T13:10:00.000+0000",
      "healthScore": 10.0,
      "packetLoss": 0.0,
      "latency": 0.0,
      "applicationDelay": 0.0
    },
    {
      "time": "2018-05-30T13:05:00.000+0000",
      "healthScore": 10.0,
      "packetLoss": 0.0,
      "latency": 0.0,
      "applicationDelay": 0.0
    },
    {
      "time": "2018-05-30T13:00:00.000+0000",
      "healthScore": 10.0,
      "packetLoss": 0.0,
      "latency": 0.0,
      "applicationDelay": 0.0
    },
    {
      "time": "2018-05-30T12:55:00.000+0000",
      "healthScore": 10.0,
      "packetLoss": 0.0,
      "latency": 0.0,
      "applicationDelay": 0.0
    },
    {
      "time": "2018-05-30T12:50:00.000+0000",
      "healthScore": 10.0,
      "packetLoss": 0.0,
      "latency": 0.0,
      "applicationDelay": 0.0
    },
    {
      "time": "2018-05-30T12:30:00.000+0000",
      "healthScore": 10.0,
      "packetLoss": 0.0,
      "latency": 0.0,
      "applicationDelay": 0.0
    },
    {
      "time": "2018-05-30T12:25:00.000+0000",
      "healthScore": 10.0,
      "packetLoss": 0.0,
      "latency": 0.0,
      "applicationDelay": 0.0
    },
    {
      "time": "2018-05-30T12:00:00.000+0000",
      "healthScore": 10.0,
      "packetLoss": 0.0,
      "latency": 0.0,
      "applicationDelay": 0.0
    },
    {
      "time": "2018-05-30T11:45:00.000+0000",
      "healthScore": 10.0,
      "packetLoss": 0.0,
      "latency": 0.0,
      "applicationDelay": 0.0
    },
    {
      "time": "2018-05-30T11:40:00.000+0000",
      "healthScore": 10.0,
      "packetLoss": 0.0,
      "latency": 0.0,
      "applicationDelay": 0.0
    },
    {
      "time": "2018-05-30T11:35:00.000+0000",
      "healthScore": 10.0,
      "packetLoss": 0.0,
      "latency": 0.0,
      "applicationDelay": 0.0
    },
    {
      "time": "2018-05-30T11:25:00.000+0000",
      "healthScore": 10.0,
      "packetLoss": 0.0,
      "latency": 0.0,
      "applicationDelay": 0.0
    },
    {
      "time": "2018-05-30T11:20:00.000+0000",
      "healthScore": 10.0,
      "packetLoss": 0.0,
      "latency": 0.0,
      "applicationDelay": 0.0
    },
    {
      "time": "2018-05-30T11:10:00.000+0000",
      "healthScore": 10.0,
      "packetLoss": 0.0,
      "latency": 0.0,
      "applicationDelay": 0.0
    },
    {
      "time": "2018-05-30T11:05:00.000+0000",
      "healthScore": 10.0,
      "packetLoss": 0.0,
      "latency": 0.0,
      "applicationDelay": 0.0
    },
    {
      "time": "2018-05-30T10:55:00.000+0000",
      "healthScore": 10.0,
      "packetLoss": 0.0,
      "latency": 0.0,
      "applicationDelay": 0.0
    },
    {
      "time": "2018-05-30T10:35:00.000+0000",
      "healthScore": 10.0,
      "packetLoss": 0.0,
      "latency": 0.0,
      "applicationDelay": 0.0
    },
    {
      "time": "2018-05-30T10:30:00.000+0000",
      "healthScore": 10.0,
      "packetLoss": 0.0,
      "latency": 0.0,
      "applicationDelay": 0.0
    },
    {
      "time": "2018-05-30T10:25:00.000+0000",
      "healthScore": 10.0,
      "packetLoss": 0.0,
      "latency": 0.0,
      "applicationDelay": 0.0
    },
    {
      "time": "2018-05-30T10:20:00.000+0000",
      "healthScore": 10.0,
      "packetLoss": 0.0,
      "latency": 0.0,
      "applicationDelay": 0.0
    },
    {
      "time": "2018-05-30T10:15:00.000+0000",
      "healthScore": 10.0,
      "packetLoss": 0.0,
      "latency": 0.0,
      "applicationDelay": 0.0
    },
    {
      "time": "2018-05-30T10:10:00.000+0000",
      "healthScore": 10.0,
      "packetLoss": 0.0,
      "latency": 0.0,
      "applicationDelay": 0.0
    },
    {
      "time": "2018-05-30T09:55:00.000+0000",
      "healthScore": 10.0,
      "packetLoss": 0.0,
      "latency": 0.0,
      "applicationDelay": 0.0
    },
    {
      "time": "2018-05-30T09:30:00.000+0000",
      "healthScore": 10.0,
      "packetLoss": 0.0,
      "latency": 0.0,
      "applicationDelay": 0.0
    },
    {
      "time": "2018-05-30T09:25:00.000+0000",
      "healthScore": 10.0,
      "packetLoss": 0.0,
      "latency": 0.0,
      "applicationDelay": 0.0
    },
    {
      "time": "2018-05-30T09:20:00.000+0000",
      "healthScore": 10.0,
      "packetLoss": 0.0,
      "latency": 0.0,
      "applicationDelay": 0.0
    },
    {
      "time": "2018-05-30T09:15:00.000+0000",
      "healthScore": 10.0,
      "packetLoss": 0.0,
      "latency": 0.0,
      "applicationDelay": 0.0
    },
    {
      "time": "2018-05-30T09:00:00.000+0000",
      "healthScore": 10.0,
      "packetLoss": 0.0,
      "latency": 0.0,
      "applicationDelay": 0.0
    },
    {
      "time": "2018-05-30T08:55:00.000+0000",
      "healthScore": 10.0,
      "packetLoss": 0.0,
      "latency": 0.0,
      "applicationDelay": 0.0
    },
    {
      "time": "2018-05-30T08:50:00.000+0000",
      "healthScore": 10.0,
      "packetLoss": 0.0,
      "latency": 0.0,
      "applicationDelay": 0.0
    },
    {
      "time": "2018-05-30T08:40:00.000+0000",
      "healthScore": 10.0,
      "packetLoss": 0.0,
      "latency": 0.0,
      "applicationDelay": 0.0
    },
    {
      "time": "2018-05-30T08:35:00.000+0000",
      "healthScore": 10.0,
      "packetLoss": 0.0,
      "latency": 0.0,
      "applicationDelay": 0.0
    },
    {
      "time": "2018-05-30T08:30:00.000+0000",
      "healthScore": 10.0,
      "packetLoss": 0.0,
      "latency": 0.0,
      "applicationDelay": 0.0
    },
    {
      "time": "2018-05-30T08:25:00.000+0000",
      "healthScore": 10.0,
      "packetLoss": 0.0,
      "latency": 0.0,
      "applicationDelay": 0.0
    },
    {
      "time": "2018-05-30T08:20:00.000+0000",
      "healthScore": 10.0,
      "packetLoss": 0.0,
      "latency": 0.0,
      "applicationDelay": 0.0
    },
    {
      "time": "2018-05-30T08:15:00.000+0000",
      "healthScore": 10.0,
      "packetLoss": 0.0,
      "latency": 0.0,
      "applicationDelay": 0.0
    },
    {
      "time": "2018-05-30T08:10:00.000+0000",
      "healthScore": 10.0,
      "packetLoss": 0.0,
      "latency": 0.0,
      "applicationDelay": 0.0
    },
    {
      "time": "2018-05-30T08:00:00.000+0000",
      "healthScore": 10.0,
      "packetLoss": 0.0,
      "latency": 0.0,
      "applicationDelay": 0.0
    },
    {
      "time": "2018-05-30T07:50:00.000+0000",
      "healthScore": 10.0,
      "packetLoss": 0.0,
      "latency": 0.0,
      "applicationDelay": 0.0
    },
    {
      "time": "2018-05-30T07:45:00.000+0000",
      "healthScore": 10.0,
      "packetLoss": 0.0,
      "latency": 0.0,
      "applicationDelay": 0.0
    },
    {
      "time": "2018-05-30T07:40:00.000+0000",
      "healthScore": 10.0,
      "packetLoss": 0.0,
      "latency": 0.0,
      "applicationDelay": 0.0
    },
    {
      "time": "2018-05-30T07:30:00.000+0000",
      "healthScore": 10.0,
      "packetLoss": 0.0,
      "latency": 0.0,
      "applicationDelay": 0.0
    },
    {
      "time": "2018-05-30T07:25:00.000+0000",
      "healthScore": 10.0,
      "packetLoss": 0.0,
      "latency": 0.0,
      "applicationDelay": 0.0
    },
    {
      "time": "2018-05-30T07:15:00.000+0000",
      "healthScore": 10.0,
      "packetLoss": 0.0,
      "latency": 0.0,
      "applicationDelay": 0.0
    },
    {
      "time": "2018-05-30T07:10:00.000+0000",
      "healthScore": 10.0,
      "packetLoss": 0.0,
      "latency": 0.0,
      "applicationDelay": 0.0
    }
  ]
}
});