define({
  "Template": {
    "version": "1.0",
    "response": [
      {
        "id": "AWQM1GnxxNnJ13sZKwKv",
        "name": "global_sensor_mailserver_fail_trigger",
        "entityType": "Sensor",
        "entity": "global",
        "groupBy": "Unknown",
        "category": "Sensor Test",
        "severity": "HIGH",
        "priority": "P3",
        "summary": "Failed to connect to Mail Server from \"Site@ToChange\"",
        "scope": "GLOBAL",
        "rootCause": "sensor_connected_global_mailserver_test_failed",
        "timestamp": 1529223000000,
        "description": "2 sensors from \"Site@ToChange\" are unable to connect to Mail servers. Users may not be able to use mail services.",
        "suggestions": [
          {
            "message": "Verify if the Outlook service is overloaded with too many requests.",
            "steps": [

            ]
          },
          {
            "message": "Verify if the Outlook service is up and running.",
            "steps": [

            ]
          }
        ],
        "additionalParams": [
          {
            "key": "summary",
            "value": "Multiple sensor tests failed"
          },
          {
            "key": "locationName",
            "value": "Global/San Jose/SJ23/SJ23-3"
          },
          {
            "key": "test",
            "value": "mailserver"
          },
          {
            "key": "entityType",
            "value": "Sensor"
          },
          {
            "key": "__entity_type__",
            "value": "global"
          },
          {
            "key": "failure_count",
            "value": "2.0"
          },
          {
            "key": "entityId",
            "value": "global"
          },
          {
            "key": "scope",
            "value": "global"
          },
          {
            "key": "tenantid",
            "value": "TNT0"
          },
          {
            "key": "rootCause",
            "value": "sensor_connected_global_mailserver_test_failed"
          },
          {
            "key": "location",
            "value": "b7bb6612-253d-4aac-9640-0a6a0f764b20"
          },
          {
            "key": "category",
            "value": "Sensor Test"
          },
          {
            "key": "device",
            "value": "sensor"
          },
          {
            "key": "status",
            "value": "active"
          },
          {
            "key": "timestring",
            "value": "2018-06-17T08:10:00.000+0000"
          }
        ],
        "status": {
          "status": "ACTIVE",
          "updatedBy": "Unknown",
          "notes": "Unknown",
          "source": "Unknown",
          "updatedAt": null
        },
        "siteQulaifiedName": null
      }
    ]
  }
})