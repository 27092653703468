define([
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/policy/SDAHelperServiceinsightsTemplate',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/policy/SDAFeatureInsightTemplate.js'

], function (SDAHelperServiceinsightsTemplate,SDAFeatureInsightTemplate) {

        return {
            init: function () {
            },

            doProcessRestRequest: function (urlAction) {
                var data;
                switch (urlAction.method) {
                    
                    case 'GET':
                                        
                        data = processGetRequest(urlAction);
                        break;

                    case 'POST':
                        data = processPostRequest(urlAction);
                        break;

                    case 'PUT':
                        data = processPutRequest(urlAction);
                        break;

                    case 'DELETE':
                        data = processDeleteRequest(urlAction);
                        break;

                    default:
                        break;
                }

                return data;
            }
        };

        function processGetRequest(urlAction) {

            if ( (urlAction.service.indexOf('sdajourney') > -1)  &&  (urlAction.service.indexOf('sda-helper-service') > -1) &&   (urlAction.service.indexOf('services') > -1)  ) {                
                var tObj = JSON.parse(JSON.stringify(SDAFeatureInsightTemplate.SDAServices_Template));
                return tObj;
            }
            if ( (urlAction.service.indexOf('sdajourney') > -1)  &&  (urlAction.service.indexOf('sda-helper-service') > -1) &&   (urlAction.service.indexOf('features') > -1)  ) {                                                       
            var tObj = JSON.parse(JSON.stringify(SDAFeatureInsightTemplate.SDAFeatures_Template));
                return tObj;            
            } 
            if ( (urlAction.service.indexOf('sdajourney') > -1)  &&  (urlAction.service.indexOf('sda-helper-service') > -1) &&   (urlAction.service.indexOf('insights') > -1)  ) {                                                           
                var tObj = JSON.parse(JSON.stringify(SDAFeatureInsightTemplate.SDAInsights_Template));
                return tObj;               
            }                      
            if (urlAction.service.indexOf('mdnac') > -1 && urlAction.service.indexOf('sync-status') > -1) {
                var res = { "errorMessage": null, "iseVersion": "3.2.0.542", "isPxgridConnected": true, "isRemoteSdaTransitSynced": true, "remoteClusterIp": null, "transitName": null,"transitOpTaskId": null};
                return res;
            }
            if (urlAction.service.indexOf('VManageVpnIds') > -1) {
            var tempObj = JSON.parse(JSON.stringify(SDAHelperServiceinsightsTemplate.VManageVpnIds_Template));
            return tempObj
            }            
            return {}
        }

    });
