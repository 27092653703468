define({
"Template": {
  "request": {
    "entity": {
      "managementIpAddr": "128.17.10.2",
      "name": "wlc_reboot_crash_trigger"
    },
    "dimensions": [

    ],
    "timeRange": {
      "start": "2018-09-24T20:54:30.505Z",
      "end": "2018-09-27T06:38:06.520Z"
    },
    "values": [

    ]
  },
  "pagination": {
    "links": [
      {
        "rel": "self",
        "url": "http://queryengine.ndp.svc.cluster.local:8006/api/ndp/v1/data/triggers/?page=1&pageSize=100",
        "method": "POST",
        "body": "{\"entity\":{\"managementIpAddr\":\"128.17.10.2\",\"name\":\"wlc_reboot_crash_trigger\"},\"dimensions\":[],\"timeRange\":{\"start\":\"2018-09-24T20:54:30.505Z\",\"end\":\"2018-09-27T06:38:06.520Z\"},\"values\":[]}"
      },
      {
        "rel": "first",
        "url": "http://queryengine.ndp.svc.cluster.local:8006/api/ndp/v1/data/triggers/?page=1&pageSize=100",
        "method": "POST",
        "body": "{\"entity\":{\"managementIpAddr\":\"128.17.10.2\",\"name\":\"wlc_reboot_crash_trigger\"},\"dimensions\":[],\"timeRange\":{\"start\":\"2018-09-24T20:54:30.505Z\",\"end\":\"2018-09-27T06:38:06.520Z\"},\"values\":[]}"
      },
      {
        "rel": "last",
        "url": "http://queryengine.ndp.svc.cluster.local:8006/api/ndp/v1/data/triggers/?page=1&pageSize=100",
        "method": "POST",
        "body": "{\"entity\":{\"managementIpAddr\":\"128.17.10.2\",\"name\":\"wlc_reboot_crash_trigger\"},\"dimensions\":[],\"timeRange\":{\"start\":\"2018-09-24T20:54:30.505Z\",\"end\":\"2018-09-27T06:38:06.520Z\"},\"values\":[]}"
      }
    ],
    "page": 1,
    "pageSize": 100,
    "order": "ASC",
    "totalResults": 1
  },
  "warnings": null,
  "values": [
    {
      "name": "wlc_reboot_crash_trigger",
      "severity": "HIGH",
      "id": "AWYSgw5xdB8ZSOsITWi6",
      "time": "2018-09-25T20:54:30.505+0000",
      "endTime": "2018-09-25T20:54:30.505+0000",
      "count": 1,
      "attributes": [
        {
          "key": "managementIpAddr",
          "value": "128.17.10.2"
        },
        {
          "key": "upUntilLastCrash",
          "value": "1"
        },
        {
          "key": "entityType",
          "value": "network_device"
        },
        {
          "key": "__entity_type__",
          "value": "NetworkDevice"
        },
        {
          "key": "entityId",
          "value": "128.17.10.2"
        },
        {
          "key": "type",
          "value": "Issue"
        },
        {
          "key": "priority",
          "value": "P1"
        },
        {
          "key": "wlcName",
          "value": "namlab-5520"
        },
        {
          "key": "scope",
          "value": "global"
        },
        {
          "key": "category",
          "value": "Availability"
        },
        {
          "key": "status",
          "value": "active"
        }
      ],
      "source": "internal",
      "isActive": false
    }
  ]
}

})