define([
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/database/SimLokiDatabaseActions',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/utilities/UtilityFunctions',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/inventory/MdfPnpTemplate',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/nfv/NfvisMdfTemplate',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/data-generation/configuration/DefaultConfig'
], function (SimLokiDatabaseActions, UtilityFunctions, MdfPnpTemplate, NfvisMdfTemplate, DefaultConfig) {


    return {

        init: function () {},

        getPidList:function(isOld) {
            if(isOld) {
                return MdfPnpTemplate.PLATFORM_ID_LIST;
            } else {
                var tJson = {'id':[], 'count':0};
                tJson.id = JSON.parse(JSON.stringify(MdfPnpTemplate.PLATFORM_ID_LIST));
                tJson.count = tJson.id.length;
                return tJson;
            }
        },

        getPidDetail: function(platformId) {
            var tJson = JSON.parse(JSON.stringify(MdfPnpTemplate.PID_DETAIL_TEMPLATE));
            var devDtls = this.findDevDetailsByPid(platformId);
            if(devDtls) {
                tJson.associatedProductIds = devDtls.pids;
                tJson.ciscoMdfId = devDtls.mdfId;
                tJson.rootGroupingElement.name = devDtls.family;
                tJson.rootGroupingElement.nextElement.name = devDtls.series;
                tJson.rootGroupingElement.nextElement.nextElement.id = devDtls.mdfId;
                tJson.rootGroupingElement.nextElement.nextElement.name = devDtls.type;
            }
            return tJson;
        },

        getNfvisMdfProv: function() {
            var tJson = JSON.parse(JSON.stringify(NfvisMdfTemplate.Mdf_Template));
            return tJson;
        },

        findDevDetailsByPid: function(pid) {
            var mappingDetails = DefaultConfig.MDF_DEVICE_TYPE_MAPPING;
            for(var i=0; i<mappingDetails.length; i++) {
              if(mappingDetails[i].pids.indexOf(pid)>-1) {
                return mappingDetails[i];
              }
            }
            //entering default condition - when pid mapping is not present in simulation or the PID entered is wrong!
            var tJson = {mdfId:"286315874", type:"Cisco Catalyst 9300 Switch", series:"Cisco Catalyst 9300 Series Switches",
                swversion: "16.11.1s", family: "Switches and Hubs", pids:["C9300-48P", "C9300-24UX", "c9300-24P", "C9300-48UXM",
                "C9300-48T", "C9300-48U", "C9300-24T"]};
            return tJson;
        }

    }
});