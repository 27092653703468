define({
  "Template":{
   "siteId":"",
   "scoreDetail":[
      {
         "scoreCategory":{
            "scoreCategory":"CLIENT_TYPE",
            "value":"ALL"
         },
         "scoreValue":85,
         "clientCount":40,
         "clientUniqueCount":40,
         "starttime":1526984400000,
         "endtime":1526984700000,
         "scoreList":[

         ]
      },
      {
         "scoreCategory":{
            "scoreCategory":"CLIENT_TYPE",
            "value":"WIRED"
         },
         "scoreValue":94,
         "clientCount":32,
         "clientUniqueCount":32,
         "starttime":1526984400000,
         "endtime":1526984700000,
         "scoreList":[
            {
               "scoreCategory":{
                  "scoreCategory":"SCORE_TYPE",
                  "value":"POOR"
               },
               "scoreValue":-1,
               "clientCount":1,
               "clientUniqueCount":0,
               "starttime":1526984400000,
               "endtime":1526984700000,
               "scoreList":[
                  {
                     "scoreCategory":{
                        "scoreCategory":"DEVICE_TYPE",
                        "value":""
                     },
                     "scoreValue":-1,
                     "clientCount":0,
                     "clientUniqueCount":null,
                     "starttime":1526984400000,
                     "endtime":1526984700000,
                     "scoreList":[

                     ]
                  }
               ]
            },
            {
               "scoreCategory":{
                  "scoreCategory":"SCORE_TYPE",
                  "value":"FAIR"
               },
               "scoreValue":-1,
               "clientCount":1,
               "clientUniqueCount":0,
               "starttime":1526984400000,
               "endtime":1526984700000,
               "scoreList":[
                  {
                     "scoreCategory":{
                        "scoreCategory":"DEVICE_TYPE",
                        "value":""
                     },
                     "scoreValue":-1,
                     "clientCount":1,
                     "clientUniqueCount":null,
                     "starttime":1526984400000,
                     "endtime":1526984700000,
                     "scoreList":[

                     ]
                  }
               ]
            },
            {
               "scoreCategory":{
                  "scoreCategory":"SCORE_TYPE",
                  "value":"GOOD"
               },
               "scoreValue":-1,
               "clientCount":30,
               "clientUniqueCount":0,
               "starttime":1526984400000,
               "endtime":1526984700000,
               "scoreList":[
                  {
                     "scoreCategory":{
                        "scoreCategory":"DEVICE_TYPE",
                        "value":""
                     },
                     "scoreValue":-1,
                     "clientCount":30,
                     "clientUniqueCount":null,
                     "starttime":1526984400000,
                     "endtime":1526984700000,
                     "scoreList":[

                     ]
                  }
               ]
            },
            {
               "scoreCategory":{
                  "scoreCategory":"SCORE_TYPE",
                  "value":"IDLE"
               },
               "scoreValue":-1,
               "clientCount":0,
               "clientUniqueCount":0,
               "starttime":1526984400000,
               "endtime":1526984700000,
               "scoreList":[

               ]
            }
         ]
      },
      {
         "scoreCategory":{
            "scoreCategory":"CLIENT_TYPE",
            "value":"WIRELESS"
         },
         "scoreValue":50,
         "clientCount":8,
         "clientUniqueCount":8,
         "starttime":1526984400000,
         "endtime":1526984700000,
         "scoreList":[
            {
               "scoreCategory":{
                  "scoreCategory":"SCORE_TYPE",
                  "value":"POOR"
               },
               "scoreValue":-1,
               "clientCount":15,
               "clientUniqueCount":0,
               "starttime":1526984400000,
               "endtime":1526984700000,
               "scoreList":[
                  {
                     "scoreCategory":{
                        "scoreCategory":"DEVICE_TYPE",
                        "value":"Linux-Workstation"
                     },
                     "scoreValue":-1,
                     "clientCount":15,
                     "clientUniqueCount":null,
                     "starttime":1526984400000,
                     "endtime":1526984700000,
                     "scoreList":[

                     ]
                  }
               ]
            },
            {
               "scoreCategory":{
                  "scoreCategory":"SCORE_TYPE",
                  "value":"FAIR"
               },
               "scoreValue":-1,
               "clientCount":10,
               "clientUniqueCount":10,
               "starttime":1526984400000,
               "endtime":1526984700000,
               "scoreList":[
               ]
            },
            {
               "scoreCategory":{
                  "scoreCategory":"SCORE_TYPE",
                  "value":"GOOD"
               },
               "scoreValue":-1,
               "clientCount":4,
               "clientUniqueCount":0,
               "starttime":1526984400000,
               "endtime":1526984700000,
               "scoreList":[
                  {
                     "scoreCategory":{
                        "scoreCategory":"DEVICE_TYPE",
                        "value":""
                     },
                     "scoreValue":-1,
                     "clientCount":4,
                     "clientUniqueCount":null,
                     "starttime":1526984400000,
                     "endtime":1526984700000,
                     "scoreList":[

                     ]
                  }
               ]
            },
            {
               "scoreCategory":{
                  "scoreCategory":"SCORE_TYPE",
                  "value":"IDLE"
               },
               "scoreValue":-1,
               "clientCount":0,
               "clientUniqueCount":0,
               "starttime":1526984400000,
               "endtime":1526984700000,
               "scoreList":[

               ]
            }
         ]
      }
   ]
}
});
