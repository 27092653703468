define({
    "CLI_Info_Template": {
      "credentialSubType":"CLI",
      "password": "NO!$DATA!$",
      "enablePassword": "NO!$DATA!$",
      "username": "cisco",
      "description": null,
      "comments": null,
      "credentialType": null,
      "instanceUuid": "24b57716-450b-4fc6-8400-b1616c744d60",
      "id": "",
      "instanceTenantId": "5f35ea5f15de5300c6a0162a",
      },
     "SNMP_Read_Info_Template":{
      "credentialSubType":"SNMPV2_READ_COMMUNITY",
      "readCommunity": "NO!$DATA!$",
      "description": "",
      "comments": null,
      "credentialType": null,
      "instanceUuid": "acf9174c-9b41-4f6e-adf8-efb083833410",
      "id": ""
     },

     "SNMP_Write_Info_Template":{
      "credentialSubType":"SNMPV2_WRITE_COMMUNITY",
      "writeCommunity": "NO!$DATA!$",
      "description": "",
      "comments": null,
      "credentialType": null,
      "instanceUuid": "391a9532-c054-4590-b619-ee43de460a45",
      "id": ""
    },
    "SNMP3_Info_Template":{
     "credentialSubType":"SNMPV3",
      "authType": "",
      "authPassword": "NO!$DATA!$",
      "username": "",
      "snmpMode": "",
      "privacyPassword": "",
      "privacyType": "",
      "description": "",
      "comments": null,
      "credentialType": null,
      "instanceUuid": "",
      "id": ""
    },
    "HTTP_Info_Template":{
     "credentialSubType":"",
      "password": "NO!$DATA!$",
      "port":"" ,
      "secure": "",
      "username": "",
      "description": "",
      "comments": null,
      "credentialType": null,
      "instanceUuid": "",
      "id": ""
    },
    "NETCONF_Info_Template":{
      "credentialSubType":"NETCONF",
      "netconfPort":"" ,//830
      "description": "",//null
      "comments": null,
      "credentialType": null,
      "instanceUuid": "",//2ac9ad98-5b6b-407c-b2a8-c95b62de1553
      "id": ""//2ac9ad98-5b6b-407c-b2a8-c95b62de1553
      //"instanceTenantId":"5f35ea5f15de5300c6a0162a"
    },
    "HTTP_WRITE_Info_Template":{
      "credentialSubType":"HTTP_WRITE",
      "username": "cisco",
      "secure": true,
      "password": "NO!$DATA!$",
      "port": 443,
      "description": "cisco",
      "comments": null,
      "credentialType": null,
      "instanceTenantId": "5f35ea5f15de5300c6a0162a",
      "instanceUuid": "f0703f0b-a909-40df-94f6-16a078262dad",
      "id": "b5ab4f6a-d102-4bc3-83df-486d5e04ccfa"
    },
    "Credential_Update_Info_Template":{
      "upgraded": false,
      "status": [
        {
          "type": "SNMPv2WriteCommunity",
          "action": "Apply",
          "details": [
            {
              "status": "Failed",
              "count": 1
            },
            {
              "status": "Not Applicable",
              "count": 1
            },
            {
              "status": "Not Applied",
              "count": 6
            },
            {
              "status": "Success",
              "count": 16
            },
            {
              "status": "Not Applied",
              "count": 1
            }
          ]
        },
        {
          "type": "SNMPv2ReadCommunity",
          "action": "Apply",
          "details": [
            {
              "status": "Not Applicable",
              "count": 1
            },
            {
              "status": "Not Applied",
              "count": 6
            },
            {
              "status": "Success",
              "count": 16
            },
            {
              "status": "Not Applied",
              "count": 2
            }
          ]
        },
        {
          "type": "CLICredential",
          "action": "Apply",
          "details": [
            {
              "status": "Not Applicable",
              "count": 1
            },
            {
              "status": "Not Applied",
              "count": 4
            },
            {
              "status": "Success",
              "count": 18
            },
            {
              "status": "Not Applied",
              "count": 2
            }
          ]
        },
        {
          "type": "HTTPWriteCredential",
          "action": "Apply",
          "details": [
            {
              "status": "Not Applicable",
              "count": 20
            },
            {
              "status": "Not Applied",
              "count": 4
            },
            {
              "status": "Not Applied",
              "count": 1
            }
          ]
        }
      ]
    }
});