define([
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/database/data/SimLokiDatabaseDetails'
], function (SimLokiDb) {

    return {
        getCount: function (serviceName) {
            var dbInfo = SimLokiDb.getDatabseInfoByServiceName(serviceName);
            //It is assumed each service will have only one database/index adapter, if a service can have multiple database, this will rquire change
            if (dbInfo.length == 1) {
                var collectionHandler = SimLokiDb.getCollectionHandlerByCollectionName(dbInfo[0]['collectionName']);
                if (collectionHandler) {
                    return collectionHandler.data.length;
                } else {
                    return 0;
                }
            }
        },

        //usage example: SimLokiDatabaseActions.merge(['aggregatedStatus']).to(data).from('DeviceConfigStatus').using('id').as('deviceId')
        //NOTE: mutuates input array
        mergeKeys: function(keys) {
            return{to:data=>{
                return{from:collection=>{
                    return{using:dataKey=>{
                        return{as:joinKey=>{                            
                            data.forEach(e=>{                
                                let filter = {}
                                filter[joinKey]=e[dataKey]
                                let subCollection = this.getFilteredRecordHandler(collection, filter)
                                keys.forEach(key=>{
                                    e[key] = subCollection.length>0?subCollection[0][key]:null
                                })
                            })
                            return data
                        }}
                    }}
                }}
            }}
        },

        //usage example: SimLokiDatabaseActions.merge('site').to([{siteId:7,deviceName:'asda'}]).using('siteId').as('id')
        //NOTE: mutuates input array
        merge:function(collection){            
            return{to:data=>{
                return{using:dataKey=>{
                    return{as:joinKey=>{                            
                        data.forEach(e=>{                
                                let filter = {}
                                filter[joinKey]=e[dataKey]
                                let subCollection = this.getFilteredRecordHandler(collection, filter)
                                e[collection] = subCollection.length>0?subCollection[0]:null
                            })
                        return data
                    }}
                }}
            }}                    
        },

        getAll: function (serviceName, filter,urlAction) {
            var dbInfo = SimLokiDb.getDatabseInfoByServiceName(serviceName);
            if (dbInfo.length == 1) {
                var tOffset = filter != undefined && filter['offset'] != undefined ? filter['offset'] - 1 : 0;
                var tLimit = filter != undefined && filter['limit'] != undefined ? filter['limit'] - 1 : 500;

                var collectionHandler = SimLokiDb.getCollectionHandlerByCollectionName(dbInfo[0]['collectionName']);
                if(filter) {
                    if(filter['hostname']) {
                        var data = collectionHandler.chain().offset(tOffset).limit(tLimit).find({ hostname: { $contains: filter['hostname'] } }).data();
                        return data == undefined ? [] : data;
                    }
                    if(filter['family']) {
                        var data = collectionHandler.chain().offset(tOffset).limit(tLimit).find({ family: { $contains: filter['family'] } }).data();
                        return data == undefined ? [] : data;
                    }

                    if(filter['managementIpAddress']) {
                        var data = collectionHandler.chain().oflfset(tOffset).limit(tLimit).find({ managementIpAddress: { $contains: filter['managementIpAddress'] } }).data();
                        return data == undefined ? [] : data;
                    }

                    if(filter['serialNumber']) {
                        var data = collectionHandler.chain().offset(tOffset).limit(tLimit).find({ serialNumber: { $contains: filter['serialNumber'] } }).data();
                        return data == undefined ? [] : data;
                    }

                    if(filter['collectionStatus']) {
                        var data = collectionHandler.chain().offset(tOffset).limit(tLimit).find({ collectionStatus: { $contains: filter['collectionStatus'] } }).data();
                        return data == undefined ? [] : data;
                    }

                    if(filter['credentialSubType']) {
                    var data = collectionHandler.chain().find({ credentialSubType: { $contains: filter['credentialSubType'] } }).data();
                        return data == undefined ? [] : data;
                    }

                    if(filter['sortBy'] && filter['order']=='asc') {
                        var data = collectionHandler.chain().simplesort([filter['sortBy']]).data();
                        //return data == undefined ? [] : data;
                    }
               /*     if(urlAction.service.indexOf("host")>=0) {
                     return collectionHandler == undefined ? [] : collectionHandler.data;
                    } */

                    var data = collectionHandler.chain().offset(tOffset).limit(tLimit).data();
                    return data == undefined ? [] : data;
                }

                return collectionHandler == undefined ? [] : collectionHandler.data;
            }
        },

        getRecordHandlerForJsonStringAsFilter: function (serviceName, key, value) {
            var dbInfo = SimLokiDb.getDatabseInfoByServiceName(serviceName);
            if (dbInfo.length == 1) {
                var collectionHandler = SimLokiDb.getCollectionHandlerByCollectionName(dbInfo[0]['collectionName']);
                var recordObj = collectionHandler.find({key: value});
                return recordObj;
            }
            return undefined;
        },

        getFilteredRecordHandler: function (serviceName, filter) {
            var dbInfo = SimLokiDb.getDatabseInfoByServiceName(serviceName);
            if (dbInfo.length == 1) {
                var collectionHandler = SimLokiDb.getCollectionHandlerByCollectionName(dbInfo[0]['collectionName']);
                var recordObj = collectionHandler.find(filter);
                return recordObj;
            }
            return undefined;
        },

        filterByRegx  :function (serviceName,paraName,paraValue) {//Update this
            var dbInfo = SimLokiDb.getDatabseInfoByServiceName(serviceName);
            if (dbInfo.length == 1) {
                var collectionHandler = SimLokiDb.getCollectionHandlerByCollectionName(dbInfo[0]['collectionName']);
                var searchRegex = new RegExp(paraValue, 'i');
                var tObj = collectionHandler.find({ [paraName] : {'$regex': searchRegex} });
                return tObj;
            }
        },

        insert: function (serviceName, jsonObj) {
            var dbInfo = SimLokiDb.getDatabseInfoByServiceName(serviceName);
            if (dbInfo.length == 1) {
                var collectionHandler = SimLokiDb.getCollectionHandlerByCollectionName(dbInfo[0]['collectionName']);
                collectionHandler.insert(jsonObj);
            }
        },

        update: function (serviceName, recordHandler) {
            var dbInfo = SimLokiDb.getDatabseInfoByServiceName(serviceName);
            if (dbInfo.length == 1) {
                var collectionHandler = SimLokiDb.getCollectionHandlerByCollectionName(dbInfo[0]['collectionName']);
                collectionHandler.update(recordHandler);
            }
        },

        delete: function (serviceName, recordHandler) {
            var dbInfo = SimLokiDb.getDatabseInfoByServiceName(serviceName);
            if (dbInfo.length == 1) {
                var collectionHandler = SimLokiDb.getCollectionHandlerByCollectionName(dbInfo[0]['collectionName']);
                collectionHandler.remove(recordHandler);
            }
        },

        getKeys: function (serviceName) {
            var dbInfo = SimLokiDb.getDatabseInfoByServiceName(serviceName);
            if (dbInfo.length == 1) {
                var collectionHandler = SimLokiDb.getCollectionHandlerByCollectionName(dbInfo[0]['collectionName']);
                if(collectionHandler.data && collectionHandler.data.length>0) {
                    return Object.keys(collectionHandler.data[0]);
                }
            }
            return [];
        },

        getDistinctValues: function (serviceName, field) {
            var dbInfo = SimLokiDb.getDatabseInfoByServiceName(serviceName);
            if (dbInfo.length == 1) {
                var collectionHandler = SimLokiDb.getCollectionHandlerByCollectionName(dbInfo[0]['collectionName']);
                if(collectionHandler.data && collectionHandler.data.length>0) {
                    var values = collectionHandler.data.map(e => e[field] );
                    return Array.from(new Set(values));
                }
            }
            return [];
        }

    };

    /** Example usages - handy info useful for filters : https://github.com/techfort/LokiJS/wiki/Query-Examples
        * results = coll.find({'Name': 'Odin'});
        * results = coll.find({'Name': { '$eq' : 'Odin' }}); //property of (strict) equality
        * results = coll.find({'legs': { '$ne' : 8 }}); //property not equal to provided value
        * results = coll.find(age: {$aeq: 20}); //property of abstract (loose) equality. will match documents with age of '20' or 20.
        * results = items.find({ created: { $dteq: new Date("1/1/2017") } }); //date property equal to provided date value
        * results = coll.find({'age': {'$gt': 40}}); //property greater than provided value
        * results = coll.find({'age': {'$gte': 40}}); //property greater or equal to provided value
        * results = coll.find({'age': {'$lt': 40}}); //property less than provided value
        * results = coll.find({'age': {'$lte': 40}}); //property less than or equal to provided value
        * results = users.find({ count : { '$between': [50, 75] }}); //property between provided vals
        * results = coll.find({'Name': { '$regex' : /din/ }}); //pass in raw regex
        * results = coll.find({'Name': { '$regex': 'din' }}); //pass in string pattern only
        * results = coll.find({'Name': { '$regex': ['din', 'i'] }}); //pass in [pattern, options] string array
        * results = users.find({ 'name' : { '$in' : ['odin', 'thor'] } }); //property matching any of the provided array values. Property should not be an array but compare values should be.
        * results = users.find({ 'name' : { '$nin' : ['odin', 'thor'] } }); //property not matching any of the provided array values
        * results = users.find({ 'weapons' : { '$contains' : 'tyrfing' } }); //property containing the provided value.
        * results = users.find({ 'weapons' : { '$containsAny' : ['tyrfing', 'mjolnir'] } }); //property containing any of the provided values
        * results = users.find({ 'weapons' : { '$containsNone' : ['gungnir', 'mjolnir'] } }); //property containing none of the provided values
        * results = users.find({ 'weapons' : { '$type' : 'string' } }); //property of a specified type
        * results = users.find({ 'weapons' : { '$size' : 2 } }); //array property of specified size. (does not work for strings)
        * results = users.find({ 'name' : { '$len' : 9 } }); //string property of specified length
        * results = coll.find({'$and': [{'age' : {'$gt': 30}},{'name' : 'Thor'}]});
        * results = coll.find({'$or': [{ 'age' : {'$gte': '40'}},{'name' : 'Thor'}]}); //meet any of the nested subexpressions
        * results = coll.find({'age': {'$exists': false}}); //contains (even when the value is null) this field or not
    */

});
