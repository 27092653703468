define({
    "Sensor_Details_Template":
      [{"connection":"WIRELESS",
      "frequency":{"value": 30, "unit": "MINUTES"},
      "lastModifiedTime":0,
      "location":"",
      "name":"Topsecret_Sensor",
      "numAssociatedSensor":1,
      "scheduleInDays":0,
      "sensors":[{"name": "", "macAddress": "", "assigned": false, "status": "UP"}],
      "assigned":false,
      "status":"UP",
      "siteHierarchy":"",
      "startTime":0,
      "status":"NOTRUNNING",
      "ssids":[{
        "authProtocol":null,
        "authType":"WPA2_EAP",
        "authTypeRcvd":null,
        "bands":"5GHz",
        "certdownloadurl":null,
        "certfilename":null,
        "certpassphrase":null,
        "certstatus":"ACTIVE",
        "certxferprotocol":"HTTP",
        "eapMethod":"PEAP-MSCHAPv2",
        "id":0,
        "numAps":0,
        "numSensors":0,
        "password":"SkkWI7W9x/CsVKDOnOhf4Q==",
        "profileName":"LA-Topsecret3",
        "psk":null,
        "ssid":"LA-Topsecret3",
        "status":"ENABLED",
        "username":"sensortest",
        "validFrom":0,
        "validTo":0,
        "wlanId":0,
        "wlc":"192.168.110.19"}],
      "tenantId":"5a652bd04acf86008e7f7257",
      "testScheduleMode":"DEDICATED",
      "tests":
      [{
      "bands":"BOTH",
      "config":[{"url": "http://192.168.139.160/index.html", "port": 80}, {"url": "", "port": 443}],
      "connection":"WIRELESS",
      "frequency":{"value": 30, "unit": "MINUTES"},
      "name":"WebServer",
      "scheduleInDays":0,
      "ssids":null,
      "testSuiteId":null,
      "type":null,
      "wlans":["Corporate"]
      }],
      "version":3,
      "wlans":[],
      "_id":"5a659bd4e5605e0020dc1211"}]
});