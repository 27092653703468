define({
"Template": {
    success: true,
    result: "show 802.11a cleanair device ap DeviceNamePlaceHolder\n"
            +"DC    = Duty Cycle (%)\n"
            +"ISI   = Interference Severity Index (1-Low Interference, 100-High Interference)\n"
            +"RSSI  = Received Signal Strength Index (dBm)\n"
            +"DevID = Device ID\n\n"
            +"No  ClusterID/BSSID    DevID  Type           AP Name                 ISI  RSSI  DC   Channel \n"
            +"--- ------------------ ------ ------------- ------------------------ ---- ----- ---- ---------- \n"
            +"1   af:db:bd:00:17:40  0x72b6 Video camera   DeviceNamePlaceHolder    52   -52   100  36,40,44,48\n"
            +"2   af:db:bd:00:15:09  0x7438 BLE Beacon     DeviceNamePlaceHolder     2    -57   0    unknown\n"
            +"3   af:db:bd:00:00:01  0x7439 BLE Beacon     DeviceNamePlaceHolder     2    -76   0    unknown\n"
            +"4   af:db:bd:00:1d:9b  0x743a BLE Beacon     DeviceNamePlaceHolder     2    -59   0    unknown\n"
            +"5   af:db:bd:00:14:58  0x743d BLE Beacon     DeviceNamePlaceHolder     2    -67   0    unknown\n"
            +"6   af:db:bd:00:00:06  0x7440 BLE Beacon     DeviceNamePlaceHolder     2    -63   0    unknown\n"

            +"(Cisco Controller) >"

  }

})