define([
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/database/SimLokiDatabaseActions',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/data-generation/configuration/SimTaskDataGenerator',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/utilities/UtilityFunctions',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/inventory/ScheduledJobTemplate'
], function (SimLokiDatabaseActions, SimTaskDataGenerator, UtilityFunctions, ScheduledJobTemplate) {

    return {
        insertScheduledJobTask(restpayload, taskId, childTaskId) {
            if(childTaskId==undefined) childTaskId = taskId;
            restpayload.forEach(payload => {
                let moduleName = payload.externalSchedule.module;
                let scheduledJobTaskTemplate = UtilityFunctions.deepCloneAndMerge(payload, 
                    ScheduledJobTemplate.Scheduled_Job_Template_Extras);
                
                scheduledJobTaskTemplate.createTime = UtilityFunctions.getTimeStamp();
                scheduledJobTaskTemplate.id = UtilityFunctions.generate_uuid();
                scheduledJobTaskTemplate.lastUpdateTime = UtilityFunctions.getTimeStamp();
                scheduledJobTaskTemplate.scheduledId = scheduledJobTaskTemplate.id;
                scheduledJobTaskTemplate.taskId = taskId;
                if(childTaskId) { 
                    if(Array.isArray(childTaskId)) {
                        var temp = JSON.parse(JSON.stringify(scheduledJobTaskTemplate.triggeredJobs[0]));
                        scheduledJobTaskTemplate.triggeredJobs = [];
                        childTaskId.forEach(chldId => {
                            temp = JSON.parse(JSON.stringify(temp));
                            temp.triggeredJobTaskId = chldId;
                            temp.id = UtilityFunctions.generate_uuid();
                            temp.triggeredJobId = temp.id;
                            temp.triggeredTime = UtilityFunctions.getTimeStamp();
                            scheduledJobTaskTemplate.triggeredJobs.push(temp);
                        });
                    } else {
                        scheduledJobTaskTemplate.triggeredJobs[0].triggeredJobTaskId = childTaskId;
                        scheduledJobTaskTemplate.triggeredJobs[0].id = UtilityFunctions.generate_uuid();
                        scheduledJobTaskTemplate.triggeredJobs[0].triggeredJobId = scheduledJobTaskTemplate.triggeredJobs[0].id;
                        scheduledJobTaskTemplate.triggeredJobs[0].triggeredTime = UtilityFunctions.getTimeStamp();
                    }
                }
                switch (moduleName) {
                    case "OS_UPDATE":
                        //to try to update the jobs based on inprogress, failed or completed
                        var records = SimLokiDatabaseActions.getAll('image-activation');
                        scheduledJobTaskTemplate.jobsRunning = (records.filter(e => e.taskStatus=='In Progress')).length;
                        scheduledJobTaskTemplate.jobsFailed = (records.filter(e => e.taskStatus=='failure')).length;
                        scheduledJobTaskTemplate.jobsCompleted = (records.filter(e => e.taskStatus=='success')).length;
                        //scheduledJobTaskTemplate.jobsCompleted = 1;
                        //scheduledJobTaskTemplate.jobsFailed = 0;
                        //scheduledJobTaskTemplate.jobsRunning = 0;
                        scheduledJobTaskTemplate.externalSchedule.notificationHeader.SchedulingHook_Key_ServiceName = "swim-service";
                        scheduledJobTaskTemplate.scheduledDevices = [];//ideally should have devices. But since no impact, skipping logic
                        break;
                    case "NFV_PROVISIONING":
                        scheduledJobTaskTemplate.externalSchedule.notificationHeader.SchedulingHook_Key_ServiceName = "nfv-provision";
                        break;
                    case "DISCOVERY":
                        scheduledJobTaskTemplate.externalSchedule.notificationHeader.SchedulingHook_Key_ServiceName = "apic-em-inventory-manager-service";
                        break;
                    default:
                        break;
                }
                SimLokiDatabaseActions.insert('scheduled-job', scheduledJobTaskTemplate);
            });
        },

        scheduledJobStatus: function(urlAction){
            var record = SimLokiDatabaseActions.getAll('scheduled-job');
            if(urlAction.filter.indexOf('taskId') >-1){
                record = record.filter(e=>e.taskId == urlAction.filter['taskId']);    
            }
            if(urlAction.filter.indexOf('status') >-1){
                record = record.filter(e=>e.scheduleState == urlAction.filter['status']);    
            }
            if(urlAction.filter.indexOf('scheduleState') >-1){
                record = record.filter(e=>e.scheduleState == urlAction.filter['scheduleState']);
            }
            if(urlAction.filter.indexOf('source') >-1){
                record = record.filter(e=>e.source == urlAction.filter["source"]);
            }
            if(urlAction.filter.indexOf('module') >-1){
                record = record.filter(e=>e.externalSchedule.module == urlAction.filter["module"]);
            }
            if(urlAction.action.id) {
                record = record.filter(e=>e.id == urlAction.filter["id"]);
                record = record.length > 0 ? record[0] : {};
            }
            return record;
        }
    }

});