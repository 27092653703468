define({
"Template":{
  "request": {
    "key": "ms-olap",
    "type": "Application",
    "label": "ms-olap",
    "collection": false,
    "componentContent": [
      {
        "component": "fusion",
        "additional": "ab0b9ca3-7a58-437f-a6fc-d2f9a1b7d3d5",
        "origType": "Application"
      }
    ]
  },
  "data": {
    "response": {
      "id": "ab0b9ca3-7a58-437f-a6fc-d2f9a1b7d3d5",
      "instanceId": 4785,
      "displayName": "4785",
      "instanceVersion": 0,
      "createTime": 1565642661548,
      "deployed": false,
      "isSeeded": true,
      "isStale": false,
      "lastUpdateTime": 1565642661548,
      "name": "ms-olap",
      "namespace": "scalablegroup:application",
      "provisioningState": "DEFINED",
      "qualifier": "application",
      "resourceVersion": 0,
      "targetIdList": [],
      "type": "scalablegroup",
      "cfsChangeInfo": [],
      "customProvisions": [],
      "isDeleted": false,
      "iseReserved": false,
      "propagateToAci": false,
      "pushed": false,
      "scalableGroupExternalHandle": "ms-olap",
      "scalableGroupType": "APPLICATION",
      "securityGroupTag": 0,
      "state": "ACTIVE",
      "vnAgnostic": true,
      "identitySource": {
        "id": "50c060fe-8fc5-4057-b0e2-ca08b59af73a",
        "instanceId": 11011,
        "displayName": "11011",
        "instanceVersion": 0,
        "state": "INACTIVE",
        "type": "NBAR"
      },
      "indicativeNetworkIdentity": [
        {
          "id": "18fa5bd8-2bb1-4bbc-8c32-43e7fbb47f56",
          "instanceId": 14997,
          "displayName": "14997",
          "instanceVersion": 0,
          "ipv4Subnet": [],
          "ipv6Subnet": [],
          "lowerPort": 0,
          "ports": "2393",
          "protocol": "UDP",
          "upperPort": 0
        },
        {
          "id": "ccb46ada-f203-4f10-b820-d28adc88d8fe",
          "instanceId": 14998,
          "displayName": "14998",
          "instanceVersion": 0,
          "ipv4Subnet": [],
          "ipv6Subnet": [],
          "lowerPort": 0,
          "ports": "2393",
          "protocol": "TCP",
          "upperPort": 0
        }
      ],
      "networkApplications": [
        {
          "id": "5ae7ddd6-710d-4553-a083-9f062fd39e51",
          "instanceId": 12734,
          "displayName": "12734",
          "instanceVersion": 0,
          "appProtocol": "tcp/udp",
          "applicationGroup": "sqlsvr-group",
          "applicationSubType": "NONE",
          "applicationType": "DEFAULT",
          "categoryId": "5a6442b0-2f7c-4e56-93f7-6b0fd4c9e82e",
          "createTime": 1565642661548,
          "encrypted": "false",
          "engineId": "3",
          "helpString": "Microsoft OLAP",
          "ignoreConflict": false,
          "lastUpdateTime": 1565642661548,
          "longDescription": "Online Analytical Processing (OLAP) is part of the Microsoft SQL Server database management system. It is a technology that is used to organize large business databases in order to support business intelligence and swiftly answer multi-dimensional analytical (MDA) queries.",
          "name": "ms-olap",
          "nbarId": "686",
          "p2pTechnology": "false",
          "popularity": 1,
          "rank": 65535,
          "references": "http://office.microsoft.com/en-us/excel-help/overview-of-online-analytical-processing-olap-HP010177437.aspx#BMwhat_is_on-line_analytical_processing",
          "selectorId": "2393",
          "serverNames": [],
          "subCategory": "8433522e-cc49-428b-ac4e-208a335cfe05",
          "trafficClass": "TRANSACTIONAL_DATA",
          "tunnel": "false"
        }
      ],
      "networkIdentity": [
        {
          "id": "b754b91f-9055-4014-b91c-70571c56041d",
          "instanceId": 14995,
          "displayName": "14995",
          "instanceVersion": 0,
          "ipv4Subnet": [],
          "ipv6Subnet": [],
          "lowerPort": 0,
          "ports": "2393",
          "protocol": "UDP",
          "upperPort": 0
        },
        {
          "id": "8ad187e6-99af-44bb-979e-73aec0a50441",
          "instanceId": 14996,
          "displayName": "14996",
          "instanceVersion": 0,
          "ipv4Subnet": [],
          "ipv6Subnet": [],
          "lowerPort": 0,
          "ports": "2393",
          "protocol": "TCP",
          "upperPort": 0
        }
      ],
      "parentScalableGroup": {
        "idRef": "777b58d8-f3d9-47dc-874d-2f03f8d015c7"
      },
      "user": [],
      "userGroup": []
    }
  },
  "result": [
    {
      "bookID": {
        "namespace": "fusion",
        "name": "core",
        "version": "1.0.1"
      },
      "response": {
        "id": "ab0b9ca3-7a58-437f-a6fc-d2f9a1b7d3d5",
        "instanceId": 4785,
        "displayName": "4785",
        "instanceVersion": 0,
        "createTime": 1565642661548,
        "deployed": false,
        "isSeeded": true,
        "isStale": false,
        "lastUpdateTime": 1565642661548,
        "name": "ms-olap",
        "namespace": "scalablegroup:application",
        "provisioningState": "DEFINED",
        "qualifier": "application",
        "resourceVersion": 0,
        "targetIdList": [],
        "type": "scalablegroup",
        "cfsChangeInfo": [],
        "customProvisions": [],
        "isDeleted": false,
        "iseReserved": false,
        "propagateToAci": false,
        "pushed": false,
        "scalableGroupExternalHandle": "ms-olap",
        "scalableGroupType": "APPLICATION",
        "securityGroupTag": 0,
        "state": "ACTIVE",
        "vnAgnostic": true,
        "identitySource": {
          "id": "50c060fe-8fc5-4057-b0e2-ca08b59af73a",
          "instanceId": 11011,
          "displayName": "11011",
          "instanceVersion": 0,
          "state": "INACTIVE",
          "type": "NBAR"
        },
        "indicativeNetworkIdentity": [
          {
            "id": "18fa5bd8-2bb1-4bbc-8c32-43e7fbb47f56",
            "instanceId": 14997,
            "displayName": "14997",
            "instanceVersion": 0,
            "ipv4Subnet": [],
            "ipv6Subnet": [],
            "lowerPort": 0,
            "ports": "2393",
            "protocol": "UDP",
            "upperPort": 0
          },
          {
            "id": "ccb46ada-f203-4f10-b820-d28adc88d8fe",
            "instanceId": 14998,
            "displayName": "14998",
            "instanceVersion": 0,
            "ipv4Subnet": [],
            "ipv6Subnet": [],
            "lowerPort": 0,
            "ports": "2393",
            "protocol": "TCP",
            "upperPort": 0
          }
        ],
        "networkApplications": [
          {
            "id": "5ae7ddd6-710d-4553-a083-9f062fd39e51",
            "instanceId": 12734,
            "displayName": "12734",
            "instanceVersion": 0,
            "appProtocol": "tcp/udp",
            "applicationGroup": "sqlsvr-group",
            "applicationSubType": "NONE",
            "applicationType": "DEFAULT",
            "categoryId": "5a6442b0-2f7c-4e56-93f7-6b0fd4c9e82e",
            "createTime": 1565642661548,
            "encrypted": "false",
            "engineId": "3",
            "helpString": "Microsoft OLAP",
            "ignoreConflict": false,
            "lastUpdateTime": 1565642661548,
            "longDescription": "Online Analytical Processing (OLAP) is part of the Microsoft SQL Server database management system. It is a technology that is used to organize large business databases in order to support business intelligence and swiftly answer multi-dimensional analytical (MDA) queries.",
            "name": "ms-olap",
            "nbarId": "686",
            "p2pTechnology": "false",
            "popularity": 1,
            "rank": 65535,
            "references": "http://office.microsoft.com/en-us/excel-help/overview-of-online-analytical-processing-olap-HP010177437.aspx#BMwhat_is_on-line_analytical_processing",
            "selectorId": "2393",
            "serverNames": [],
            "subCategory": "8433522e-cc49-428b-ac4e-208a335cfe05",
            "trafficClass": "TRANSACTIONAL_DATA",
            "tunnel": "false"
          }
        ],
        "networkIdentity": [
          {
            "id": "b754b91f-9055-4014-b91c-70571c56041d",
            "instanceId": 14995,
            "displayName": "14995",
            "instanceVersion": 0,
            "ipv4Subnet": [],
            "ipv6Subnet": [],
            "lowerPort": 0,
            "ports": "2393",
            "protocol": "UDP",
            "upperPort": 0
          },
          {
            "id": "8ad187e6-99af-44bb-979e-73aec0a50441",
            "instanceId": 14996,
            "displayName": "14996",
            "instanceVersion": 0,
            "ipv4Subnet": [],
            "ipv6Subnet": [],
            "lowerPort": 0,
            "ports": "2393",
            "protocol": "TCP",
            "upperPort": 0
          }
        ],
        "parentScalableGroup": {
          "idRef": "777b58d8-f3d9-47dc-874d-2f03f8d015c7"
        },
        "user": [],
        "userGroup": []
      }
    }
  ]
}

})