define({
"Template":
{
       "managementIpAddr": "10.30.255.101",
      "remoteIP": "10.30.255.2",
      "_name": "OSPF_Down_event",
      "_entity_type": "NetworkDevice",
      "eventSource": "Network Device",
      "type": "event",
      "_key": "NetworkDevice:10.30.255.101",
      "floorId": "eab2a08d-7bbb-42b0-a207-d927784ec63e",
      "_count": 1,
      "areaName": "Global/USA",
      "scope": "global",
      "__type": "assurance_triggers",
      "rootCause": "504",
      "_primary_key": "NetworkDevice:10.30.255.101",
      "syslogMsg": "Process 100, Nbr 10.30.255.2 on TenGigabitEthernet1/0/23 from DOWN to DOWN, Neighbor Down: Ignore timer expired",
      "floorName": "Global/USA/LA/Level16",
      "__timestamp": 1582581303245,
      "deviceType": "Cisco Catalyst38xx stack-able ethernet switch",
      "severity": "HIGH",
      "deviceUuid": "5e880058-ffe6-4e69-b593-fd3d8ef649e3",
      "deviceRole": "ACCESS",
      "_suppression": "0",
      "deviceOS": "16.6.1",
      "entityType": "network_device",
      "nwDeviceName": "LA1-3850-CSW-2.corp.local",
      "_eventTime": 1582581303150,
      "_mostRecent": 1582581303150,
      "entityId": "10.30.255.101",
      "eventUniqueId": "10.30.255.101",
      "deviceFamily": "Switches and Hubs",
      "priority": "P2",
      "buildingId": "1e4b2421-4a4e-461f-b5ca-333129dda0e1",
      "buildingName": "Global/USA/LA",
      "areaId": "8edbff1a-e8f0-46d7-8b3a-c77ded39c4ab",
      "siteHierarchy": "Global/USA/LA/Level16",
      "failureReason": "Issue",
      "eventStatus": "Down",
      "siteId": "eab2a08d-7bbb-42b0-a207-d927784ec63e",
      "_source": "internal",
      "deviceModel": "FCW2129C0XU",
      "_id": "2e69f034-7518-4e4c-ac9c-db31a4c524a1",
      "category": "connectivity",
      "status": "active",
      "intfName": "TenGigabitEthernet1/0/23"

}
})