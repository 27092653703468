define({
"sensorName_Template":
{
"version": "1.0",
"response": {
  "dataList": [
    {
      "breakdown": "overallSummary",
      "breakdownValue": null,
      "overallAgg": [
        {
          "testType": "ONBOARDING",
          "categoryType": "category",
          "testStatusList": [
            {
              "testStatus": "pass",
              "count": 52624
            },
            {
              "testStatus": "fail",
              "count": 1975
            }
          ],
          "failedRate": 3.62,
          "childList": [
            {
              "testType": "ASSOC",
              "categoryType": "subCategory",
              "testStatusList": [
                {
                  "testStatus": "pass",
                  "count": 17706
                },
                {
                  "testStatus": "fail",
                  "count": 1526
                }
              ],
              "failedRate": 7.93,
              "childList": [

              ]
            },
            {
              "testType": "AUTH",
              "categoryType": "subCategory",
              "testStatusList": [
                {
                  "testStatus": "pass",
                  "count": 17647
                },
                {
                  "testStatus": "fail",
                  "count": 73
                }
              ],
              "failedRate": 0.41,
              "childList": [

              ]
            },
            {
              "testType": "DHCP",
              "categoryType": "subCategory",
              "testStatusList": [
                {
                  "testStatus": "pass",
                  "count": 17271
                },
                {
                  "testStatus": "fail",
                  "count": 376
                }
              ],
              "failedRate": 2.13,
              "childList": [

              ]
            },
            {
              "testType": "WEBAUTH",
              "categoryType": "subCategory",
              "testStatusList": [
                {
                  "testStatus": "pass",
                  "count": 17871
                },
                {
                  "testStatus": "fail",
                  "count": 366
                }
              ],
              "failedRate": 1.0,
              "childList": [

              ]
            }
          ]
        },
        {
          "testType": "RF_ASSESSMENT",
          "categoryType": "category",
          "testStatusList": [
            {
              "testStatus": "pass",
              "count": 168750
            },
            {
              "testStatus": "fail",
              "count": 3786
            }
          ],
          "failedRate": 2.19,
          "childList": [
            {
              "testType": "DATA_RATE",
              "categoryType": "subCategory",
              "testStatusList": [
                {
                  "testStatus": "pass",
                  "count": 82480
                },
                {
                  "testStatus": "fail",
                  "count": 3786
                }
              ],
              "failedRate": 4.39,
              "childList": [

              ]
            },
            {
              "testType": "SNR",
              "categoryType": "subCategory",
              "testStatusList": [
                {
                  "testStatus": "pass",
                  "count": 86270
                }
              ],
              "failedRate": 0.00,
              "childList": [

              ]
            }
          ]
        },
        {
          "testType": "NETWORK_SERVICES",
          "categoryType": "category",
          "testStatusList": [
            {
              "testStatus": "pass",
              "count": 31892
            },
            {
              "testStatus": "fail",
              "count": 2624
            }
          ],
          "failedRate": 27.81,
          "childList": [
            {
              "testType": "DNS",
              "categoryType": "subCategory",
              "testStatusList": [
                {
                  "testStatus": "pass",
                  "count": 15074
                },
                {
                  "testStatus": "fail",
                  "count": 2187
                }
              ],
              "failedRate": 25.08,
              "childList": [

              ]
            },
            {
              "testType": "RADIUS",
              "categoryType": "subCategory",
              "testStatusList": [
                {
                  "testStatus": "pass",
                  "count": 16818
                },
                {
                  "testStatus": "fail",
                  "count": 437
                }
              ],
              "failedRate": 5.53,
              "childList": [

              ]
            }
          ]
        },
        {
          "testType": "PERFORMANCE",
          "categoryType": "category",
          "testStatusList": [
            {
              "testStatus": "pass",
              "count": 17124
            },
            {
              "testStatus": "fail",
              "count": 120
            }
          ],
          "failedRate": 0.70,
          "childList": [
            {
              "testType": "SPEED",
              "categoryType": "subCategory",
              "testStatusList": [

              ],
              "failedRate": null,
              "childList": [

              ]
            },
            {
              "testType": "IPSLASENDER",
              "categoryType": "subCategory",
              "testStatusList": [
                {
                  "testStatus": "pass",
                  "count": 17124
                },
                {
                  "testStatus": "fail",
                  "count": 120
                }
              ],
              "failedRate": 0.70,
              "childList": [

              ]
            }
          ]
        },
        {
          "testType": "APP_CONNECTIVITY",
          "categoryType": "category",
          "testStatusList": [
            {
              "testStatus": "pass",
              "count": 32433
            },
            {
              "testStatus": "fail",
              "count": 2077
            }
          ],
          "failedRate": 6.02,
          "childList": [
            {
              "testType": "HOST_REACHABILITY",
              "categoryType": "subCategory",
              "testStatusList": [
                {
                  "testStatus": "pass",
                  "count": 16250
                },
                {
                  "testStatus": "fail",
                  "count": 1007
                }
              ],
              "failedRate": 5.84,
              "childList": [

              ]
            },
            {
              "testType": "WEBSERVER",
              "categoryType": "subCategory",
              "testStatusList": [
                {
                  "testStatus": "pass",
                  "count": 16183
                },
                {
                  "testStatus": "fail",
                  "count": 1070
                }
              ],
              "failedRate": 6.20,
              "childList": [

              ]
            },
            {
              "testType": "FILETRANSFER",
              "categoryType": "subCategory",
              "testStatusList": [
                {
                  "testStatus": "pass",
                  "count": 17124
                },
                {
                  "testStatus": "fail",
                  "count": 120
                }
              ],
              "failedRate": 0.70,
              "childList": [

              ]
            }
          ]
        },
        {
          "testType": "EMAIL",
          "categoryType": "category",
          "testStatusList": [
             {
                  "testStatus": "pass",
                  "count": 32248
             },
             {
                  "testStatus": "fail",
                  "count": 4808
             }

          ],
          "failedRate": 14.9,
          "childList": [
            {
              "testType": "MAILSERVER",
              "categoryType": "subCategory",
              "testStatusList": [
                {
                  "testStatus": "pass",
                  "count": 17124
                },
                {
                  "testStatus": "fail",
                  "count": 120
                }

              ],
              "failedRate": 0.70,
              "childList": [

              ]
            },
            {
              "testType": "OUTLOOKSERVER",
              "categoryType": "subCategory",
              "testStatusList": [
                {
                  "testStatus": "pass",
                  "count": 15124
                },
                {
                  "testStatus": "fail",
                  "count": 4688
                }

              ],
              "failedRate": 31,
              "childList": [

              ]
            }
          ]
        }
      ],
      "dataList": [
        {
          "timestamp": 1583218800000,
          "testTypeList": [
            {
              "testType": null,
              "categoryType": null,
              "testStatusList": [
                {
                  "testStatus": "pass",
                  "count": 6041
                },
                {
                  "testStatus": "fail",
                  "count": 208
                }
              ],
              "failedRate": 3.33,
              "childList": [

              ]
            }
          ]
        },
        {
          "timestamp": 1583220600000,
          "testTypeList": [
            {
              "testType": null,
              "categoryType": null,
              "testStatusList": [
                {
                  "testStatus": "pass",
                  "count": 6248
                },
                {
                  "testStatus": "fail",
                  "count": 208
                }
              ],
              "failedRate": 3.22,
              "childList": [

              ]
            }
          ]
        },
        {
          "timestamp": 1583222400000,
          "testTypeList": [
            {
              "testType": null,
              "categoryType": null,
              "testStatusList": [
                {
                  "testStatus": "pass",
                  "count": 6464
                },
                {
                  "testStatus": "fail",
                  "count": 179
                }
              ],
              "failedRate": 2.69,
              "childList": [

              ]
            }
          ]
        },
        {
          "timestamp": 1583224200000,
          "testTypeList": [
            {
              "testType": null,
              "categoryType": null,
              "testStatusList": [
                {
                  "testStatus": "pass",
                  "count": 6291
                },
                {
                  "testStatus": "fail",
                  "count": 148
                }
              ],
              "failedRate": 2.30,
              "childList": [

              ]
            }
          ]
        },
        {
          "timestamp": 1583226000000,
          "testTypeList": [
            {
              "testType": null,
              "categoryType": null,
              "testStatusList": [
                {
                  "testStatus": "pass",
                  "count": 6687
                },
                {
                  "testStatus": "fail",
                  "count": 157
                }
              ],
              "failedRate": 2.29,
              "childList": [

              ]
            }
          ]
        },
        {
          "timestamp": 1583227800000,
          "testTypeList": [
            {
              "testType": null,
              "categoryType": null,
              "testStatusList": [
                {
                  "testStatus": "pass",
                  "count": 6480
                },
                {
                  "testStatus": "fail",
                  "count": 157
                }
              ],
              "failedRate": 2.37,
              "childList": [

              ]
            }
          ]
        },
        {
          "timestamp": 1583229600000,
          "testTypeList": [
            {
              "testType": null,
              "categoryType": null,
              "testStatusList": [
                {
                  "testStatus": "pass",
                  "count": 6865
                },
                {
                  "testStatus": "fail",
                  "count": 236
                }
              ],
              "failedRate": 3.32,
              "childList": [

              ]
            }
          ]
        },
        {
          "timestamp": 1583231400000,
          "testTypeList": [
            {
              "testType": null,
              "categoryType": null,
              "testStatusList": [
                {
                  "testStatus": "pass",
                  "count": 6680
                },
                {
                  "testStatus": "fail",
                  "count": 174
                }
              ],
              "failedRate": 2.54,
              "childList": [

              ]
            }
          ]
        },
        {
          "timestamp": 1583233200000,
          "testTypeList": [
            {
              "testType": null,
              "categoryType": null,
              "testStatusList": [
                {
                  "testStatus": "pass",
                  "count": 6340
                },
                {
                  "testStatus": "fail",
                  "count": 173
                }
              ],
              "failedRate": 2.66,
              "childList": [

              ]
            }
          ]
        },
        {
          "timestamp": 1583235000000,
          "testTypeList": [
            {
              "testType": null,
              "categoryType": null,
              "testStatusList": [
                {
                  "testStatus": "pass",
                  "count": 6586
                },
                {
                  "testStatus": "fail",
                  "count": 187
                }
              ],
              "failedRate": 2.76,
              "childList": [

              ]
            }
          ]
        },
        {
          "timestamp": 1583236800000,
          "testTypeList": [
            {
              "testType": null,
              "categoryType": null,
              "testStatusList": [
                {
                  "testStatus": "pass",
                  "count": 6678
                },
                {
                  "testStatus": "fail",
                  "count": 175
                }
              ],
              "failedRate": 2.55,
              "childList": [

              ]
            }
          ]
        },
        {
          "timestamp": 1583238600000,
          "testTypeList": [
            {
              "testType": null,
              "categoryType": null,
              "testStatusList": [
                {
                  "testStatus": "pass",
                  "count": 6415
                },
                {
                  "testStatus": "fail",
                  "count": 160
                }
              ],
              "failedRate": 2.43,
              "childList": [

              ]
            }
          ]
        },
        {
          "timestamp": 1583240400000,
          "testTypeList": [
            {
              "testType": null,
              "categoryType": null,
              "testStatusList": [
                {
                  "testStatus": "pass",
                  "count": 6921
                },
                {
                  "testStatus": "fail",
                  "count": 226
                }
              ],
              "failedRate": 3.16,
              "childList": [

              ]
            }
          ]
        },
        {
          "timestamp": 1583242200000,
          "testTypeList": [
            {
              "testType": null,
              "categoryType": null,
              "testStatusList": [
                {
                  "testStatus": "pass",
                  "count": 7054
                },
                {
                  "testStatus": "fail",
                  "count": 156
                }
              ],
              "failedRate": 2.16,
              "childList": [

              ]
            }
          ]
        },
        {
          "timestamp": 1583244000000,
          "testTypeList": [
            {
              "testType": null,
              "categoryType": null,
              "testStatusList": [
                {
                  "testStatus": "pass",
                  "count": 7282
                },
                {
                  "testStatus": "fail",
                  "count": 231
                }
              ],
              "failedRate": 3.07,
              "childList": [

              ]
            }
          ]
        },
        {
          "timestamp": 1583245800000,
          "testTypeList": [
            {
              "testType": null,
              "categoryType": null,
              "testStatusList": [
                {
                  "testStatus": "pass",
                  "count": 7535
                },
                {
                  "testStatus": "fail",
                  "count": 232
                }
              ],
              "failedRate": 2.99,
              "childList": [

              ]
            }
          ]
        },
        {
          "timestamp": 1583247600000,
          "testTypeList": [
            {
              "testType": null,
              "categoryType": null,
              "testStatusList": [
                {
                  "testStatus": "pass",
                  "count": 6930
                },
                {
                  "testStatus": "fail",
                  "count": 212
                }
              ],
              "failedRate": 2.97,
              "childList": [

              ]
            }
          ]
        },
        {
          "timestamp": 1583249400000,
          "testTypeList": [
            {
              "testType": null,
              "categoryType": null,
              "testStatusList": [
                {
                  "testStatus": "pass",
                  "count": 6344
                },
                {
                  "testStatus": "fail",
                  "count": 163
                }
              ],
              "failedRate": 2.50,
              "childList": [

              ]
            }
          ]
        },
        {
          "timestamp": 1583251200000,
          "testTypeList": [
            {
              "testType": null,
              "categoryType": null,
              "testStatusList": [
                {
                  "testStatus": "pass",
                  "count": 6216
                },
                {
                  "testStatus": "fail",
                  "count": 187
                }
              ],
              "failedRate": 2.92,
              "childList": [

              ]
            }
          ]
        },
        {
          "timestamp": 1583253000000,
          "testTypeList": [
            {
              "testType": null,
              "categoryType": null,
              "testStatusList": [
                {
                  "testStatus": "pass",
                  "count": 5941
                },
                {
                  "testStatus": "fail",
                  "count": 154
                }
              ],
              "failedRate": 2.53,
              "childList": [

              ]
            }
          ]
        },
        {
          "timestamp": 1583254800000,
          "testTypeList": [
            {
              "testType": null,
              "categoryType": null,
              "testStatusList": [
                {
                  "testStatus": "pass",
                  "count": 6023
                },
                {
                  "testStatus": "fail",
                  "count": 205
                }
              ],
              "failedRate": 3.29,
              "childList": [

              ]
            }
          ]
        },
        {
          "timestamp": 1583256600000,
          "testTypeList": [
            {
              "testType": null,
              "categoryType": null,
              "testStatusList": [
                {
                  "testStatus": "pass",
                  "count": 6449
                },
                {
                  "testStatus": "fail",
                  "count": 165
                }
              ],
              "failedRate": 2.49,
              "childList": [

              ]
            }
          ]
        },
        {
          "timestamp": 1583258400000,
          "testTypeList": [
            {
              "testType": null,
              "categoryType": null,
              "testStatusList": [
                {
                  "testStatus": "pass",
                  "count": 6432
                },
                {
                  "testStatus": "fail",
                  "count": 154
                }
              ],
              "failedRate": 2.34,
              "childList": [

              ]
            }
          ]
        },
        {
          "timestamp": 1583260200000,
          "testTypeList": [
            {
              "testType": null,
              "categoryType": null,
              "testStatusList": [
                {
                  "testStatus": "pass",
                  "count": 6545
                },
                {
                  "testStatus": "fail",
                  "count": 155
                }
              ],
              "failedRate": 2.31,
              "childList": [

              ]
            }
          ]
        },
        {
          "timestamp": 1583262000000,
          "testTypeList": [
            {
              "testType": null,
              "categoryType": null,
              "testStatusList": [
                {
                  "testStatus": "pass",
                  "count": 256
                },
                {
                  "testStatus": "fail",
                  "count": 56
                }
              ],
              "targetServerList": [

              ],
              "failedRate": 31.95,
              "discoverRate": null,
              "childList": [

              ]
            }
          ]
        },
        {
          "timestamp": 1583263800000,
          "testTypeList": [
            {
              "testType": null,
              "categoryType": null,
              "testStatusList": [
                {
                  "testStatus": "pass",
                  "count": 6011
                },
                {
                  "testStatus": "fail",
                  "count": 223
                }
              ],
              "failedRate": 3.58,
              "childList": [

              ]
            }
          ]
        },
        {
          "timestamp": 1583265600000,
          "testTypeList": [
            {
              "testType": null,
              "categoryType": null,
              "testStatusList": [
                {
                  "testStatus": "pass",
                  "count": 5681
                },
                {
                  "testStatus": "fail",
                  "count": 207
                }
              ],
              "failedRate": 3.52,
              "childList": [

              ]
            }
          ]
        },
        {
          "timestamp": 1583267400000,
          "testTypeList": [
            {
              "testType": null,
              "categoryType": null,
              "testStatusList": [
                {
                  "testStatus": "pass",
                  "count": 6087
                },
                {
                  "testStatus": "fail",
                  "count": 194
                }
              ],
              "failedRate": 3.09,
              "childList": [

              ]
            }
          ]
        },
        {
          "timestamp": 1583269200000,
          "testTypeList": [
            {
              "testType": null,
              "categoryType": null,
              "testStatusList": [
                {
                  "testStatus": "pass",
                  "count": 6576
                },
                {
                  "testStatus": "fail",
                  "count": 180
                }
              ],
              "failedRate": 2.66,
              "childList": [

              ]
            }
          ]
        },
        {
          "timestamp": 1583271000000,
          "testTypeList": [
            {
              "testType": null,
              "categoryType": null,
              "testStatusList": [
                {
                  "testStatus": "pass",
                  "count": 6047
                },
                {
                  "testStatus": "fail",
                  "count": 140
                }
              ],
              "failedRate": 2.26,
              "childList": [

              ]
            }
          ]
        },
        {
          "timestamp": 1583272800000,
          "testTypeList": [
            {
              "testType": null,
              "categoryType": null,
              "testStatusList": [
                {
                  "testStatus": "pass",
                  "count": 6309
                },
                {
                  "testStatus": "fail",
                  "count": 146
                }
              ],
              "failedRate": 2.26,
              "childList": [

              ]
            }
          ]
        },
        {
          "timestamp": 1583274600000,
          "testTypeList": [
            {
              "testType": null,
              "categoryType": null,
              "testStatusList": [
                {
                  "testStatus": "pass",
                  "count": 5930
                },
                {
                  "testStatus": "fail",
                  "count": 130
                }
              ],
              "failedRate": 2.15,
              "childList": [

              ]
            }
          ]
        },
        {
          "timestamp": 1583276400000,
          "testTypeList": [
            {
              "testType": null,
              "categoryType": null,
              "testStatusList": [
                {
                  "testStatus": "pass",
                  "count": 5893
                },
                {
                  "testStatus": "fail",
                  "count": 149
                }
              ],
              "failedRate": 2.47,
              "childList": [

              ]
            }
          ]
        },
        {
          "timestamp": 1583278200000,
          "testTypeList": [
            {
              "testType": null,
              "categoryType": null,
              "testStatusList": [
                {
                  "testStatus": "pass",
                  "count": 6356
                },
                {
                  "testStatus": "fail",
                  "count": 163
                }
              ],
              "failedRate": 2.50,
              "childList": [

              ]
            }
          ]
        },
        {
          "timestamp": 1583280000000,
          "testTypeList": [
            {
              "testType": null,
              "categoryType": null,
              "testStatusList": [
                {
                  "testStatus": "pass",
                  "count": 5777
                },
                {
                  "testStatus": "fail",
                  "count": 191
                }
              ],
              "failedRate": 3.20,
              "childList": [

              ]
            }
          ]
        },
        {
          "timestamp": 1583281800000,
          "testTypeList": [
            {
              "testType": null,
              "categoryType": null,
              "testStatusList": [
                {
                  "testStatus": "pass",
                  "count": 6119
                },
                {
                  "testStatus": "fail",
                  "count": 189
                }
              ],
              "failedRate": 3.00,
              "childList": [

              ]
            }
          ]
        },
        {
          "timestamp": 1583283600000,
          "testTypeList": [
            {
              "testType": null,
              "categoryType": null,
              "testStatusList": [
                {
                  "testStatus": "pass",
                  "count": 256
                },
                {
                  "testStatus": "fail",
                  "count": 56
                }
              ],
              "targetServerList": [

              ],
              "failedRate": 31.95,
              "discoverRate": null,
              "childList": [

              ]
            }
          ]
        },
        {
          "timestamp": 1583285400000,
          "testTypeList": [
            {
              "testType": null,
              "categoryType": null,
              "testStatusList": [
                {
                  "testStatus": "pass",
                  "count": 7271
                },
                {
                  "testStatus": "fail",
                  "count": 158
                }
              ],
              "failedRate": 2.13,
              "childList": [

              ]
            }
          ]
        },
        {
          "timestamp": 1583287200000,
          "testTypeList": [
            {
              "testType": null,
              "categoryType": null,
              "testStatusList": [
                {
                  "testStatus": "pass",
                  "count": 6458
                },
                {
                  "testStatus": "fail",
                  "count": 172
                }
              ],
              "failedRate": 2.59,
              "childList": [

              ]
            }
          ]
        },
        {
          "timestamp": 1583289000000,
          "testTypeList": [
            {
              "testType": null,
              "categoryType": null,
              "testStatusList": [
                {
                  "testStatus": "pass",
                  "count": 6287
                },
                {
                  "testStatus": "fail",
                  "count": 174
                }
              ],
              "failedRate": 2.69,
              "childList": [

              ]
            }
          ]
        },
        {
          "timestamp": 1583290800000,
          "testTypeList": [
            {
              "testType": null,
              "categoryType": null,
              "testStatusList": [
                {
                  "testStatus": "pass",
                  "count": 6295
                },
                {
                  "testStatus": "fail",
                  "count": 165
                }
              ],
              "failedRate": 2.55,
              "childList": [

              ]
            }
          ]
        },
        {
          "timestamp": 1583292600000,
          "testTypeList": [
            {
              "testType": null,
              "categoryType": null,
              "testStatusList": [
                {
                  "testStatus": "pass",
                  "count": 256
                },
                {
                  "testStatus": "fail",
                  "count": 56
                }
              ],
              "targetServerList": [

              ],
              "failedRate": 37.95,
              "discoverRate": null,
              "childList": [

              ]
            }
          ]
        },
        {
          "timestamp": 1583294400000,
          "testTypeList": [
            {
              "testType": null,
              "categoryType": null,
              "testStatusList": [
                {
                  "testStatus": "pass",
                  "count": 256
                },
                {
                  "testStatus": "fail",
                  "count": 56
                }
              ],
              "targetServerList": [

              ],
              "failedRate": 17.95,
              "discoverRate": null,
              "childList": [

              ]
            }
          ]
        },
        {
          "timestamp": 1583296200000,
          "testTypeList": [
            {
              "testType": null,
              "categoryType": null,
              "testStatusList": [
                {
                  "testStatus": "pass",
                  "count": 7213
                },
                {
                  "testStatus": "fail",
                  "count": 158
                }
              ],
              "failedRate": 2.14,
              "childList": [

              ]
            }
          ]
        },
        {
          "timestamp": 1583298000000,
          "testTypeList": [
            {
              "testType": null,
              "categoryType": null,
              "testStatusList": [
                {
                  "testStatus": "pass",
                  "count": 6404
                },
                {
                  "testStatus": "fail",
                  "count": 152
                }
              ],
              "failedRate": 2.32,
              "childList": [

              ]
            }
          ]
        },
        {
          "timestamp": 1583299800000,
          "testTypeList": [
            {
              "testType": null,
              "categoryType": null,
              "testStatusList": [
                {
                  "testStatus": "pass",
                  "count": 256
                },
                {
                  "testStatus": "fail",
                  "count": 56
                }
              ],
              "targetServerList": [

              ],
              "failedRate": 17.95,
              "discoverRate": null,
              "childList": [

              ]
            }
          ]
        },
        {
          "timestamp": 1583301600000,
          "testTypeList": [
            {
              "testType": null,
              "categoryType": null,
              "testStatusList": [
                {
                  "testStatus": "pass",
                  "count": 6395
                },
                {
                  "testStatus": "fail",
                  "count": 189
                }
              ],
              "failedRate": 2.87,
              "childList": [

              ]
            }
          ]
        },
        {
          "timestamp": 1583303400000,
          "testTypeList": [
            {
              "testType": null,
              "categoryType": null,
              "testStatusList": [
                {
                  "testStatus": "pass",
                  "count": 256
                },
                {
                  "testStatus": "fail",
                  "count": 56
                }
              ],
              "targetServerList": [

              ],
              "failedRate": 17.95,
              "discoverRate": null,
              "childList": [

              ]
            }
          ]
        },
        {
          "timestamp": 1583305200000,
          "testTypeList": [

          ]
        }
      ]
    },
    {
      "breakdown": "Sensor-0298",
      "breakdownValue": "70:f3:5a:7c:70:80",
      "overallAgg": [
        {
          "testType": "overall",
          "categoryType": "category",
          "testStatusList": [

          ],
          "failedRate": 0.66,
          "childList": [

          ]
        },
        {
          "testType": "ONBOARDING",
          "categoryType": "category",
          "testStatusList": [
            {
              "testStatus": "pass",
              "count": 1980
            },
            {
              "testStatus": "fail",
              "count": 75
            }
          ],
          "failedRate": 3.65,
          "childList": [
            {
              "testType": "ASSOC",
              "categoryType": "subCategory",
              "testStatusList": [
                {
                  "testStatus": "pass",
                  "count": 660
                },
                {
                  "testStatus": "fail",
                  "count": 75
                }
              ],
              "failedRate": 10.20,
              "childList": [

              ]
            },
            {
              "testType": "AUTH",
              "categoryType": "subCategory",
              "testStatusList": [
                {
                  "testStatus": "pass",
                  "count": 660
                }
              ],
              "failedRate": 0.00,
              "childList": [

              ]
            },
            {
              "testType": "DHCP",
              "categoryType": "subCategory",
              "testStatusList": [
                {
                  "testStatus": "pass",
                  "count": 660
                }
              ],
              "failedRate": 0.00,
              "childList": [

              ]
            },
            {
              "testType": "WEBAUTH",
              "categoryType": "subCategory",
              "testStatusList": [
                {
                  "testStatus": "pass",
                  "count": 660
                }
              ],
              "failedRate": 0.01,
              "childList": [

              ]
            }
          ]
        },
        {
          "testType": "RF_ASSESSMENT",
          "categoryType": "category",
          "testStatusList": [
            {
              "testStatus": "pass",
              "count": 6554
            },
            {
              "testStatus": "fail",
              "count": 2
            }
          ],
          "failedRate": 0.03,
          "childList": [
            {
              "testType": "DATA_RATE",
              "categoryType": "subCategory",
              "testStatusList": [
                {
                  "testStatus": "pass",
                  "count": 3276
                },
                {
                  "testStatus": "fail",
                  "count": 2
                }
              ],
              "failedRate": 0.06,
              "childList": [

              ]
            },
            {
              "testType": "SNR",
              "categoryType": "subCategory",
              "testStatusList": [
                {
                  "testStatus": "pass",
                  "count": 3278
                }
              ],
              "failedRate": 0.00,
              "childList": [

              ]
            }
          ]
        },
        {
          "testType": "NETWORK_SERVICES",
          "categoryType": "category",
          "testStatusList": [
            {
              "testStatus": "pass",
              "count": 31892
            },
            {
              "testStatus": "fail",
              "count": 2624
            }
          ],
          "failedRate": 27.81,
          "childList": [
            {
              "testType": "DNS",
              "categoryType": "subCategory",
              "testStatusList": [
                {
                  "testStatus": "pass",
                  "count": 15074
                },
                {
                  "testStatus": "fail",
                  "count": 2187
                }
              ],
              "failedRate": 25.08,
              "childList": [

              ]
            },
            {
              "testType": "RADIUS",
              "categoryType": "subCategory",
              "testStatusList": [
                {
                  "testStatus": "pass",
                  "count": 16818
                },
                {
                  "testStatus": "fail",
                  "count": 437
                }
              ],
              "failedRate": 25.53,
              "childList": [

              ]
            }
          ]
        },
        {
          "testType": "PERFORMANCE",
          "categoryType": "category",
          "testStatusList": [
            {
              "testStatus": "pass",
              "count": 649
            },
            {
              "testStatus": "fail",
              "count": 2
            }
          ],
          "failedRate": 0.31,
          "childList": [
            {
              "testType": "SPEED",
              "categoryType": "subCategory",
              "testStatusList": [
                {
                  "testStatus": "pass",
                  "count": 649
                },
                {
                  "testStatus": "fail",
                  "count": 2
                }

              ],
              "failedRate": 0.31,
              "childList": [

              ]
            },
            {
              "testType": "IPSLASENDER",
              "categoryType": "subCategory",
              "testStatusList": [
                {
                  "testStatus": "pass",
                  "count": 649
                },
                {
                  "testStatus": "fail",
                  "count": 2
                }
              ],
              "failedRate": 0.31,
              "childList": [

              ]
            }
          ]
        },
        {
          "testType": "APP_CONNECTIVITY",
          "categoryType": "category",
          "testStatusList": [
            {
              "testStatus": "pass",
              "count": 1312
            }
          ],
          "failedRate": 0.00,
          "childList": [
            {
              "testType": "HOST_REACHABILITY",
              "categoryType": "subCategory",
              "testStatusList": [
                {
                  "testStatus": "pass",
                  "count": 657
                }
              ],
              "failedRate": 0.00,
              "childList": [

              ]
            },
            {
              "testType": "WEBSERVER",
              "categoryType": "subCategory",
              "testStatusList": [
                {
                  "testStatus": "pass",
                  "count": 655
                }
              ],
              "failedRate": 0.00,
              "childList": [

              ]
            },
            {
              "testType": "FILETRANSFER",
              "categoryType": "subCategory",
              "testStatusList": [
                {
                  "testStatus": "pass",
                  "count": 17124
                },
                {
                  "testStatus": "fail",
                  "count": 120
                }
              ],
              "failedRate": 0.70,
              "childList": [

              ]
            }
          ]
        },
        {
          "testType": "EMAIL",
          "categoryType": "category",
          "testStatusList": [
                {
                  "testStatus": "pass",
                  "count": 2928
                },
                {
                  "testStatus": "fail",
                  "count": 654
                }

          ],
          "failedRate": 22.3,
          "childList": [
            {
              "testType": "MAILSERVER",
              "categoryType": "subCategory",
              "testStatusList": [
                {
                  "testStatus": "pass",
                  "count": 1204
                },
                {
                  "testStatus": "fail",
                  "count": 120
                }

              ],
              "failedRate": 9.90,
              "childList": [

              ]
            },
            {
              "testType": "OUTLOOKSERVER",
              "categoryType": "subCategory",
              "testStatusList": [
                {
                  "testStatus": "pass",
                  "count": 1724
                },
                {
                  "testStatus": "fail",
                  "count": 534
                }

              ],
              "failedRate": 31,
              "childList": [

              ]
            }
          ]
        }
      ],
      "dataList": [
        {
          "timestamp": 1583218800000,
          "testTypeList": [

          ]
        },
        {
          "timestamp": 1583220600000,
          "testTypeList": [
            {
              "testType": null,
              "categoryType": null,
              "testStatusList": [
                {
                  "testStatus": "pass",
                  "count": 180
                }
              ],
              "failedRate": 0.00,
              "childList": [

              ]
            }
          ]
        },
        {
          "timestamp": 1583222400000,
          "testTypeList": [
            {
              "testType": null,
              "categoryType": null,
              "testStatusList": [
                {
                  "testStatus": "pass",
                  "count": 198
                }
              ],
              "failedRate": 0.00,
              "childList": [

              ]
            }
          ]
        },
        {
          "timestamp": 1583224200000,
          "testTypeList": [
            {
              "testType": null,
              "categoryType": null,
              "testStatusList": [
                {
                  "testStatus": "pass",
                  "count": 303
                }
              ],
              "failedRate": 0.00,
              "childList": [

              ]
            }
          ]
        },
        {
          "timestamp": 1583226000000,
          "testTypeList": [
            {
              "testType": null,
              "categoryType": null,
              "testStatusList": [
                {
                  "testStatus": "pass",
                  "count": 256
                },
                {
                  "testStatus": "fail",
                  "count": 56
                }
              ],
              "targetServerList": [

              ],
              "failedRate": 47.95,
              "discoverRate": null,
              "childList": [

              ]
            }
          ]
        },
        {
          "timestamp": 1583227800000,
          "testTypeList": [
            {
              "testType": null,
              "categoryType": null,
              "testStatusList": [
                {
                  "testStatus": "pass",
                  "count": 180
                }
              ],
              "failedRate": 0.00,
              "childList": [

              ]
            }
          ]
        },
        {
          "timestamp": 1583229600000,
          "testTypeList": [
            {
              "testType": null,
              "categoryType": null,
              "testStatusList": [
                {
                  "testStatus": "pass",
                  "count": 126
                }
              ],
              "failedRate": 0.00,
              "childList": [

              ]
            }
          ]
        },
        {
          "timestamp": 1583231400000,
          "testTypeList": [
            {
              "testType": null,
              "categoryType": null,
              "testStatusList": [
                {
                  "testStatus": "pass",
                  "count": 195
                }
              ],
              "failedRate": 0.00,
              "childList": [

              ]
            }
          ]
        },
        {
          "timestamp": 1583233200000,
          "testTypeList": [
            {
              "testType": null,
              "categoryType": null,
              "testStatusList": [
                {
                  "testStatus": "pass",
                  "count": 147
                }
              ],
              "failedRate": 0.00,
              "childList": [

              ]
            }
          ]
        },
        {
          "timestamp": 1583235000000,
          "testTypeList": [
            {
              "testType": null,
              "categoryType": null,
              "testStatusList": [
                {
                  "testStatus": "pass",
                  "count": 432
                }
              ],
              "failedRate": 0.00,
              "childList": [

              ]
            }
          ]
        },
        {
          "timestamp": 1583236800000,
          "testTypeList": [
            {
              "testType": null,
              "categoryType": null,
              "testStatusList": [
                {
                  "testStatus": "pass",
                  "count": 540
                }
              ],
              "failedRate": 0.00,
              "childList": [

              ]
            }
          ]
        },
        {
          "timestamp": 1583238600000,
          "testTypeList": [
            {
              "testType": null,
              "categoryType": null,
              "testStatusList": [
                {
                  "testStatus": "pass",
                  "count": 522
                }
              ],
              "failedRate": 0.00,
              "childList": [

              ]
            }
          ]
        },
        {
          "timestamp": 1583240400000,
          "testTypeList": [
            {
              "testType": null,
              "categoryType": null,
              "testStatusList": [
                {
                  "testStatus": "pass",
                  "count": 396
                }
              ],
              "failedRate": 0.00,
              "childList": [

              ]
            }
          ]
        },
        {
          "timestamp": 1583242200000,
          "testTypeList": [
            {
              "testType": null,
              "categoryType": null,
              "testStatusList": [
                {
                  "testStatus": "pass",
                  "count": 142
                },
                {
                  "testStatus": "fail",
                  "count": 2
                }
              ],
              "failedRate": 1.39,
              "childList": [

              ]
            }
          ]
        },
        {
          "timestamp": 1583244000000,
          "testTypeList": [
            {
              "testType": null,
              "categoryType": null,
              "testStatusList": [
                {
                  "testStatus": "pass",
                  "count": 270
                }
              ],
              "failedRate": 0.00,
              "childList": [

              ]
            }
          ]
        },
        {
          "timestamp": 1583245800000,
          "testTypeList": [
            {
              "testType": null,
              "categoryType": null,
              "testStatusList": [
                {
                  "testStatus": "pass",
                  "count": 256
                },
                {
                  "testStatus": "fail",
                  "count": 56
                }
              ],
              "targetServerList": [

              ],
              "failedRate": 17.95,
              "discoverRate": null,
              "childList": [

              ]
            }
          ]
        },
        {
          "timestamp": 1583247600000,
          "testTypeList": [
            {
              "testType": null,
              "categoryType": null,
              "testStatusList": [
                {
                  "testStatus": "pass",
                  "count": 252
                }
              ],
              "failedRate": 0.00,
              "childList": [

              ]
            }
          ]
        },
        {
          "timestamp": 1583249400000,
          "testTypeList": [
            {
              "testType": null,
              "categoryType": null,
              "testStatusList": [
                {
                  "testStatus": "pass",
                  "count": 342
                }
              ],
              "failedRate": 0.00,
              "childList": [

              ]
            }
          ]
        },
        {
          "timestamp": 1583251200000,
          "testTypeList": [
            {
              "testType": null,
              "categoryType": null,
              "testStatusList": [
                {
                  "testStatus": "pass",
                  "count": 18
                }
              ],
              "failedRate": 0.00,
              "childList": [

              ]
            }
          ]
        },
        {
          "timestamp": 1583253000000,
          "testTypeList": [
            {
              "testType": null,
              "categoryType": null,
              "testStatusList": [
                {
                  "testStatus": "pass",
                  "count": 90
                }
              ],
              "failedRate": 0.00,
              "childList": [

              ]
            }
          ]
        },
        {
          "timestamp": 1583254800000,
          "testTypeList": [
            {
              "testType": null,
              "categoryType": null,
              "testStatusList": [
                {
                  "testStatus": "pass",
                  "count": 234
                }
              ],
              "failedRate": 0.00,
              "childList": [

              ]
            }
          ]
        },
        {
          "timestamp": 1583256600000,
          "testTypeList": [
            {
              "testType": null,
              "categoryType": null,
              "testStatusList": [
                {
                  "testStatus": "pass",
                  "count": 270
                }
              ],
              "failedRate": 0.00,
              "childList": [

              ]
            }
          ]
        },
        {
          "timestamp": 1583258400000,
          "testTypeList": [
            {
              "testType": null,
              "categoryType": null,
              "testStatusList": [
                {
                  "testStatus": "pass",
                  "count": 234
                }
              ],
              "failedRate": 0.00,
              "childList": [

              ]
            }
          ]
        },
        {
          "timestamp": 1583260200000,
          "testTypeList": [
            {
              "testType": null,
              "categoryType": null,
              "testStatusList": [
                {
                  "testStatus": "pass",
                  "count": 249
                },
                {
                  "testStatus": "fail",
                  "count": 120
                }
              ],
              "failedRate": 35.40,
              "childList": [

              ]
            }
          ]
        },
        {
          "timestamp": 1583262000000,
          "testTypeList": [
            {
              "testType": null,
              "categoryType": null,
              "testStatusList": [
                {
                  "testStatus": "pass",
                  "count": 429
                },
                {
                  "testStatus": "fail",
                  "count": 25
                }
              ],
              "failedRate": 5.51,
              "childList": [

              ]
            }
          ]
        },
        {
          "timestamp": 1583263800000,
          "testTypeList": [
            {
              "testType": null,
              "categoryType": null,
              "testStatusList": [
                {
                  "testStatus": "pass",
                  "count": 279
                },
                {
                  "testStatus": "fail",
                  "count": 9
                }
              ],
              "failedRate": 3.13,
              "childList": [

              ]
            }
          ]
        },
        {
          "timestamp": 1583265600000,
          "testTypeList": [
            {
              "testType": null,
              "categoryType": null,
              "testStatusList": [
                {
                  "testStatus": "pass",
                  "count": 270
                },
                {
                  "testStatus": "fail",
                  "count": 16
                }
              ],
              "failedRate": 5.59,
              "childList": [

              ]
            }
          ]
        },
        {
          "timestamp": 1583267400000,
          "testTypeList": [
            {
              "testType": null,
              "categoryType": null,
              "testStatusList": [
                {
                  "testStatus": "pass",
                  "count": 288
                },
                {
                  "testStatus": "fail",
                  "count": 13
                }
              ],
              "failedRate": 4.32,
              "childList": [

              ]
            }
          ]
        },
        {
          "timestamp": 1583269200000,
          "testTypeList": [
            {
              "testType": null,
              "categoryType": null,
              "testStatusList": [
                {
                  "testStatus": "pass",
                  "count": 324
                }
              ],
              "failedRate": 0.00,
              "childList": [

              ]
            }
          ]
        },
        {
          "timestamp": 1583271000000,
          "testTypeList": [
            {
              "testType": null,
              "categoryType": null,
              "testStatusList": [
                {
                  "testStatus": "pass",
                  "count": 256
                },
                {
                  "testStatus": "fail",
                  "count": 56
                }
              ],
              "targetServerList": [

              ],
              "failedRate": 17.95,
              "discoverRate": null,
              "childList": [

              ]
            }
          ]
        },
        {
          "timestamp": 1583272800000,
          "testTypeList": [
            {
              "testType": null,
              "categoryType": null,
              "testStatusList": [
                {
                  "testStatus": "pass",
                  "count": 103
                },
                {
                  "testStatus": "fail",
                  "count": 2
                }
              ],
              "failedRate": 1.90,
              "childList": [

              ]
            }
          ]
        },
        {
          "timestamp": 1583274600000,
          "testTypeList": [
            {
              "testType": null,
              "categoryType": null,
              "testStatusList": [
                {
                  "testStatus": "pass",
                  "count": 258
                }
              ],
              "failedRate": 0.00,
              "childList": [

              ]
            }
          ]
        },
        {
          "timestamp": 1583276400000,
          "testTypeList": [
            {
              "testType": null,
              "categoryType": null,
              "testStatusList": [
                {
                  "testStatus": "pass",
                  "count": 198
                }
              ],
              "failedRate": 0.00,
              "childList": [

              ]
            }
          ]
        },
        {
          "timestamp": 1583278200000,
          "testTypeList": [
            {
              "testType": null,
              "categoryType": null,
              "testStatusList": [
                {
                  "testStatus": "pass",
                  "count": 279
                }
              ],
              "failedRate": 0.00,
              "childList": [

              ]
            }
          ]
        },
        {
          "timestamp": 1583280000000,
          "testTypeList": [
            {
              "testType": null,
              "categoryType": null,
              "testStatusList": [
                {
                  "testStatus": "pass",
                  "count": 126
                }
              ],
              "failedRate": 0.00,
              "childList": [

              ]
            }
          ]
        },
        {
          "timestamp": 1583281800000,
          "testTypeList": [
            {
              "testType": null,
              "categoryType": null,
              "testStatusList": [
                {
                  "testStatus": "pass",
                  "count": 129
                }
              ],
              "failedRate": 0.00,
              "childList": [

              ]
            }
          ]
        },
        {
          "timestamp": 1583283600000,
          "testTypeList": [
            {
              "testType": null,
              "categoryType": null,
              "testStatusList": [
                {
                  "testStatus": "pass",
                  "count": 180
                },
                {
                  "testStatus": "fail",
                  "count": 11
                }
              ],
              "failedRate": 5.76,
              "childList": [

              ]
            }
          ]
        },
        {
          "timestamp": 1583285400000,
          "testTypeList": [
            {
              "testType": null,
              "categoryType": null,
              "testStatusList": [
                {
                  "testStatus": "pass",
                  "count": 180
                }
              ],
              "failedRate": 0.00,
              "childList": [

              ]
            }
          ]
        },
        {
          "timestamp": 1583287200000,
          "testTypeList": [
            {
              "testType": null,
              "categoryType": null,
              "testStatusList": [
                {
                  "testStatus": "pass",
                  "count": 342
                }
              ],
              "failedRate": 0.00,
              "childList": [

              ]
            }
          ]
        },
        {
          "timestamp": 1583289000000,
          "testTypeList": [
            {
              "testType": null,
              "categoryType": null,
              "testStatusList": [
                {
                  "testStatus": "pass",
                  "count": 256
                },
                {
                  "testStatus": "fail",
                  "count": 56
                }
              ],
              "targetServerList": [

              ],
              "failedRate": 31.95,
              "discoverRate": null,
              "childList": [

              ]
            }
          ]
        },
        {
          "timestamp": 1583290800000,
          "testTypeList": [
            {
              "testType": null,
              "categoryType": null,
              "testStatusList": [
                {
                  "testStatus": "pass",
                  "count": 256
                },
                {
                  "testStatus": "fail",
                  "count": 56
                }
              ],
              "targetServerList": [

              ],
              "failedRate": 17.95,
              "discoverRate": null,
              "childList": [

              ]
            }
          ]
        },
        {
          "timestamp": 1583292600000,
          "testTypeList": [
            {
              "testType": null,
              "categoryType": null,
              "testStatusList": [
                {
                  "testStatus": "pass",
                  "count": 256
                },
                {
                  "testStatus": "fail",
                  "count": 56
                }
              ],
              "targetServerList": [

              ],
              "failedRate": 17.95,
              "discoverRate": null,
              "childList": [

              ]
            }
          ]
        },
        {
          "timestamp": 1583294400000,
          "testTypeList": [
            {
              "testType": null,
              "categoryType": null,
              "testStatusList": [
                {
                  "testStatus": "pass",
                  "count": 256
                },
                {
                  "testStatus": "fail",
                  "count": 56
                }
              ],
              "targetServerList": [

              ],
              "failedRate": 17.95,
              "discoverRate": null,
              "childList": [

              ]
            }
          ]
        },
        {
          "timestamp": 1583296200000,
          "testTypeList": [
            {
              "testType": null,
              "categoryType": null,
              "testStatusList": [
                {
                  "testStatus": "pass",
                  "count": 342
                }
              ],
              "failedRate": 0.00,
              "childList": [

              ]
            }
          ]
        },
        {
          "timestamp": 1583298000000,
          "testTypeList": [
            {
              "testType": null,
              "categoryType": null,
              "testStatusList": [
                {
                  "testStatus": "pass",
                  "count": 216
                }
              ],
              "failedRate": 0.00,
              "childList": [

              ]
            }
          ]
        },
        {
          "timestamp": 1583299800000,
          "testTypeList": [
            {
              "testType": null,
              "categoryType": null,
              "testStatusList": [
                {
                  "testStatus": "pass",
                  "count": 256
                },
                {
                  "testStatus": "fail",
                  "count": 56
                }
              ],
              "targetServerList": [

              ],
              "failedRate": 37.95,
              "discoverRate": null,
              "childList": [

              ]
            }
          ]
        },
        {
          "timestamp": 1583301600000,
          "testTypeList": [
            {
              "testType": null,
              "categoryType": null,
              "testStatusList": [
                {
                  "testStatus": "pass",
                  "count": 256
                },
                {
                  "testStatus": "fail",
                  "count": 56
                }
              ],
              "targetServerList": [

              ],
              "failedRate": 17.95,
              "discoverRate": null,
              "childList": [

              ]
            }
          ]
        },
        {
          "timestamp": 1583303400000,
          "testTypeList": [

          ]
        },
        {
          "timestamp": 1583305200000,
          "testTypeList": [

          ]
        }
      ]
    },
    {
      "breakdown": "Sensor-0188",
      "breakdownValue": "70:f3:5a:78:6c:80",
      "overallAgg": [
        {
          "testType": "overall",
          "categoryType": "category",
          "testStatusList": [

          ],
          "failedRate": 0.50,
          "childList": [

          ]
        },
        {
          "testType": "ONBOARDING",
          "categoryType": "category",
          "testStatusList": [
            {
              "testStatus": "pass",
              "count": 4140
            },
            {
              "testStatus": "fail",
              "count": 123
            }
          ],
          "failedRate": 2.89,
          "childList": [
            {
              "testType": "ASSOC",
              "categoryType": "subCategory",
              "testStatusList": [
                {
                  "testStatus": "pass",
                  "count": 1380
                },
                {
                  "testStatus": "fail",
                  "count": 123
                }
              ],
              "failedRate": 8.18,
              "childList": [

              ]
            },
            {
              "testType": "AUTH",
              "categoryType": "subCategory",
              "testStatusList": [
                {
                  "testStatus": "pass",
                  "count": 1380
                }
              ],
              "failedRate": 0.00,
              "childList": [

              ]
            },
            {
              "testType": "DHCP",
              "categoryType": "subCategory",
              "testStatusList": [
                {
                  "testStatus": "pass",
                  "count": 1380
                }
              ],
              "failedRate": 0.00,
              "childList": [

              ]
            },
            {
              "testType": "WEBAUTH",
              "categoryType": "subCategory",
              "testStatusList": [
                {
                  "testStatus": "pass",
                  "count": 660
                }

              ],
              "failedRate": 0.01,
              "childList": [

              ]
            }
          ]
        },
        {
          "testType": "RF_ASSESSMENT",
          "categoryType": "category",
          "testStatusList": [
            {
              "testStatus": "pass",
              "count": 13790
            }
          ],
          "failedRate": 0.00,
          "childList": [
            {
              "testType": "DATA_RATE",
              "categoryType": "subCategory",
              "testStatusList": [
                {
                  "testStatus": "pass",
                  "count": 6895
                }
              ],
              "failedRate": 0.00,
              "childList": [

              ]
            },
            {
              "testType": "SNR",
              "categoryType": "subCategory",
              "testStatusList": [
                {
                  "testStatus": "pass",
                  "count": 6895
                }
              ],
              "failedRate": 0.00,
              "childList": [

              ]
            }
          ]
        },
        {
          "testType": "NETWORK_SERVICES",
          "categoryType": "category",
          "testStatusList": [
            {
              "testStatus": "pass",
              "count": 31892
            },
            {
              "testStatus": "fail",
              "count": 2624
            }
          ],
          "failedRate": 27.81,
          "childList": [
            {
              "testType": "DNS",
              "categoryType": "subCategory",
              "testStatusList": [
                {
                  "testStatus": "pass",
                  "count": 15074
                },
                {
                  "testStatus": "fail",
                  "count": 2187
                }
              ],
              "failedRate": 25.08,
              "childList": [

              ]
            },
            {
              "testType": "RADIUS",
              "categoryType": "subCategory",
              "testStatusList": [
                {
                  "testStatus": "pass",
                  "count": 16818
                },
                {
                  "testStatus": "fail",
                  "count": 437
                }
              ],
              "failedRate": 2.53,
              "childList": [

              ]
            }
          ]
        },
        {
          "testType": "PERFORMANCE",
          "categoryType": "category",
          "testStatusList": [
            {
              "testStatus": "pass",
              "count": 2758
            },
            {
              "testStatus": "fail",
              "count": 150
            }
          ],
          "failedRate": 0.1,
          "childList": [
            {
              "testType": "SPEED",
              "categoryType": "subCategory",
              "testStatusList": [
                {
                  "testStatus": "pass",
                  "count": 1379
                },
                {
                  "testStatus": "fail",
                  "count": 100
                }
              ],
              "failedRate": 0.1,
              "childList": [

              ]
            },
            {
              "testType": "IPSLASENDER",
              "categoryType": "subCategory",
              "testStatusList": [
                {
                  "testStatus": "pass",
                  "count": 1379
                }
              ],
              "failedRate": 0.00,
              "childList": [

              ]
            }
          ]
        },
        {
          "testType": "APP_CONNECTIVITY",
          "categoryType": "category",
          "testStatusList": [
            {
              "testStatus": "pass",
              "count": 2757
            },
            {
              "testStatus": "fail",
              "count": 1
            }
          ],
          "failedRate": 0.04,
          "childList": [
            {
              "testType": "HOST_REACHABILITY",
              "categoryType": "subCategory",
              "testStatusList": [
                {
                  "testStatus": "pass",
                  "count": 1379
                }
              ],
              "failedRate": 0.00,
              "childList": [

              ]
            },
            {
              "testType": "WEBSERVER",
              "categoryType": "subCategory",
              "testStatusList": [
                {
                  "testStatus": "pass",
                  "count": 1378
                },
                {
                  "testStatus": "fail",
                  "count": 1
                }
              ],
              "failedRate": 0.07,
              "childList": [

              ]
            },
            {
              "testType": "FILETRANSFER",
              "categoryType": "subCategory",
              "testStatusList": [
                {
                  "testStatus": "pass",
                  "count": 17124
                },
                {
                  "testStatus": "fail",
                  "count": 120
                }
              ],
              "failedRate": 0.70,
              "childList": [

              ]
            }
          ]
        },
        {
          "testType": "EMAIL",
          "categoryType": "category",
          "testStatusList": [
                {
                  "testStatus": "pass",
                  "count": 2928
                },
                {
                  "testStatus": "fail",
                  "count": 654
                }

          ],
          "failedRate": 22.3,
          "childList": [
            {
              "testType": "MAILSERVER",
              "categoryType": "subCategory",
              "testStatusList": [
                {
                  "testStatus": "pass",
                  "count": 1204
                },
                {
                  "testStatus": "fail",
                  "count": 120
                }

              ],
              "failedRate": 9.90,
              "childList": [

              ]
            },
            {
              "testType": "OUTLOOKSERVER",
              "categoryType": "subCategory",
              "testStatusList": [
                {
                  "testStatus": "pass",
                  "count": 1724
                },
                {
                  "testStatus": "fail",
                  "count": 534
                }

              ],
              "failedRate": 31,
              "childList": [

              ]
            }
          ]
        }
      ],
      "dataList": [
        {
          "timestamp": 1583218800000,
          "testTypeList": [
            {
              "testType": null,
              "categoryType": null,
              "testStatusList": [
                {
                  "testStatus": "pass",
                  "count": 504
                },
                {
                  "testStatus": "fail",
                  "count": 14
                }
              ],
              "failedRate": 2.70,
              "childList": [

              ]
            }
          ]
        },
        {
          "timestamp": 1583220600000,
          "testTypeList": [
            {
              "testType": null,
              "categoryType": null,
              "testStatusList": [
                {
                  "testStatus": "pass",
                  "count": 774
                },
                {
                  "testStatus": "fail",
                  "count": 33
                }
              ],
              "failedRate": 4.09,
              "childList": [

              ]
            }
          ]
        },
        {
          "timestamp": 1583222400000,
          "testTypeList": [
            {
              "testType": null,
              "categoryType": null,
              "testStatusList": [
                {
                  "testStatus": "pass",
                  "count": 522
                }
              ],
              "failedRate": 0.00,
              "childList": [

              ]
            }
          ]
        },
        {
          "timestamp": 1583224200000,
          "testTypeList": [
            {
              "testType": null,
              "categoryType": null,
              "testStatusList": [
                {
                  "testStatus": "pass",
                  "count": 558
                }
              ],
              "failedRate": 0.00,
              "childList": [

              ]
            }
          ]
        },
        {
          "timestamp": 1583226000000,
          "testTypeList": [
            {
              "testType": null,
              "categoryType": null,
              "testStatusList": [
                {
                  "testStatus": "pass",
                  "count": 539
                },
                {
                  "testStatus": "fail",
                  "count": 1
                }
              ],
              "failedRate": 0.19,
              "childList": [

              ]
            }
          ]
        },
        {
          "timestamp": 1583227800000,
          "testTypeList": [
            {
              "testType": null,
              "categoryType": null,
              "testStatusList": [
                {
                  "testStatus": "pass",
                  "count": 519
                }
              ],
              "failedRate": 0.00,
              "childList": [

              ]
            }
          ]
        },
        {
          "timestamp": 1583229600000,
          "testTypeList": [
            {
              "testType": null,
              "categoryType": null,
              "testStatusList": [
                {
                  "testStatus": "pass",
                  "count": 525
                }
              ],
              "failedRate": 0.00,
              "childList": [

              ]
            }
          ]
        },
        {
          "timestamp": 1583231400000,
          "testTypeList": [
            {
              "testType": null,
              "categoryType": null,
              "testStatusList": [
                {
                  "testStatus": "pass",
                  "count": 522
                }
              ],
              "failedRate": 0.00,
              "childList": [

              ]
            }
          ]
        },
        {
          "timestamp": 1583233200000,
          "testTypeList": [
            {
              "testType": null,
              "categoryType": null,
              "testStatusList": [
                {
                  "testStatus": "pass",
                  "count": 558
                }
              ],
              "failedRate": 0.00,
              "childList": [

              ]
            }
          ]
        },
        {
          "timestamp": 1583235000000,
          "testTypeList": [
            {
              "testType": null,
              "categoryType": null,
              "testStatusList": [
                {
                  "testStatus": "pass",
                  "count": 256
                },
                {
                  "testStatus": "fail",
                  "count": 56
                }
              ],
              "targetServerList": [

              ],
              "failedRate": 17.95,
              "discoverRate": null,
              "childList": [

              ]
            }
          ]
        },
        {
          "timestamp": 1583236800000,
          "testTypeList": [
            {
              "testType": null,
              "categoryType": null,
              "testStatusList": [
                {
                  "testStatus": "pass",
                  "count": 540
                }
              ],
              "failedRate": 0.00,
              "childList": [

              ]
            }
          ]
        },
        {
          "timestamp": 1583238600000,
          "testTypeList": [
            {
              "testType": null,
              "categoryType": null,
              "testStatusList": [
                {
                  "testStatus": "pass",
                  "count": 540
                }
              ],
              "failedRate": 0.00,
              "childList": [

              ]
            }
          ]
        },
        {
          "timestamp": 1583240400000,
          "testTypeList": [
            {
              "testType": null,
              "categoryType": null,
              "testStatusList": [
                {
                  "testStatus": "pass",
                  "count": 558
                }
              ],
              "failedRate": 0.00,
              "childList": [

              ]
            }
          ]
        },
        {
          "timestamp": 1583242200000,
          "testTypeList": [
            {
              "testType": null,
              "categoryType": null,
              "testStatusList": [
                {
                  "testStatus": "pass",
                  "count": 540
                }
              ],
              "failedRate": 0.00,
              "childList": [

              ]
            }
          ]
        },
        {
          "timestamp": 1583244000000,
          "testTypeList": [
            {
              "testType": null,
              "categoryType": null,
              "testStatusList": [
                {
                  "testStatus": "pass",
                  "count": 522
                }
              ],
              "failedRate": 0.00,
              "childList": [

              ]
            }
          ]
        },
        {
          "timestamp": 1583245800000,
          "testTypeList": [
            {
              "testType": null,
              "categoryType": null,
              "testStatusList": [
                {
                  "testStatus": "pass",
                  "count": 540
                }
              ],
              "failedRate": 0.00,
              "childList": [

              ]
            }
          ]
        },
        {
          "timestamp": 1583247600000,
          "testTypeList": [
            {
              "testType": null,
              "categoryType": null,
              "testStatusList": [
                {
                  "testStatus": "pass",
                  "count": 256
                },
                {
                  "testStatus": "fail",
                  "count": 56
                }
              ],
              "targetServerList": [

              ],
              "failedRate": 17.95,
              "discoverRate": null,
              "childList": [

              ]
            }
          ]
        },
        {
          "timestamp": 1583249400000,
          "testTypeList": [
            {
              "testType": null,
              "categoryType": null,
              "testStatusList": [
                {
                  "testStatus": "pass",
                  "count": 540
                }
              ],
              "failedRate": 0.00,
              "childList": [

              ]
            }
          ]
        },
        {
          "timestamp": 1583251200000,
          "testTypeList": [
            {
              "testType": null,
              "categoryType": null,
              "testStatusList": [
                {
                  "testStatus": "pass",
                  "count": 522
                }
              ],
              "failedRate": 0.00,
              "childList": [

              ]
            }
          ]
        },
        {
          "timestamp": 1583253000000,
          "testTypeList": [
            {
              "testType": null,
              "categoryType": null,
              "testStatusList": [
                {
                  "testStatus": "pass",
                  "count": 540
                }
              ],
              "failedRate": 0.00,
              "childList": [

              ]
            }
          ]
        },
        {
          "timestamp": 1583254800000,
          "testTypeList": [
            {
              "testType": null,
              "categoryType": null,
              "testStatusList": [
                {
                  "testStatus": "pass",
                  "count": 450
                }
              ],
              "failedRate": 0.00,
              "childList": [

              ]
            }
          ]
        },
        {
          "timestamp": 1583256600000,
          "testTypeList": [
            {
              "testType": null,
              "categoryType": null,
              "testStatusList": [
                {
                  "testStatus": "pass",
                  "count": 576
                }
              ],
              "failedRate": 0.00,
              "childList": [

              ]
            }
          ]
        },
        {
          "timestamp": 1583258400000,
          "testTypeList": [
            {
              "testType": null,
              "categoryType": null,
              "testStatusList": [
                {
                  "testStatus": "pass",
                  "count": 486
                }
              ],
              "failedRate": 0.00,
              "childList": [

              ]
            }
          ]
        },
        {
          "timestamp": 1583260200000,
          "testTypeList": [
            {
              "testType": null,
              "categoryType": null,
              "testStatusList": [
                {
                  "testStatus": "pass",
                  "count": 540
                }
              ],
              "failedRate": 0.00,
              "childList": [

              ]
            }
          ]
        },
        {
          "timestamp": 1583262000000,
          "testTypeList": [
            {
              "testType": null,
              "categoryType": null,
              "testStatusList": [
                {
                  "testStatus": "pass",
                  "count": 900
                },
                {
                  "testStatus": "fail",
                  "count": 50
                }
              ],
              "failedRate": 5.26,
              "childList": [

              ]
            }
          ]
        },
        {
          "timestamp": 1583263800000,
          "testTypeList": [
            {
              "testType": null,
              "categoryType": null,
              "testStatusList": [
                {
                  "testStatus": "pass",
                  "count": 378
                }
              ],
              "failedRate": 0.00,
              "childList": [

              ]
            }
          ]
        },
        {
          "timestamp": 1583265600000,
          "testTypeList": [
            {
              "testType": null,
              "categoryType": null,
              "testStatusList": [
                {
                  "testStatus": "pass",
                  "count": 348
                }
              ],
              "failedRate": 0.00,
              "childList": [

              ]
            }
          ]
        },
        {
          "timestamp": 1583267400000,
          "testTypeList": [
            {
              "testType": null,
              "categoryType": null,
              "testStatusList": [
                {
                  "testStatus": "pass",
                  "count": 432
                },
                {
                  "testStatus": "fail",
                  "count": 22
                }
              ],
              "failedRate": 4.85,
              "childList": [

              ]
            }
          ]
        },
        {
          "timestamp": 1583269200000,
          "testTypeList": [
            {
              "testType": null,
              "categoryType": null,
              "testStatusList": [
                {
                  "testStatus": "pass",
                  "count": 501
                },
                {
                  "testStatus": "fail",
                  "count": 4
                }
              ],
              "failedRate": 0.79,
              "childList": [

              ]
            }
          ]
        },
        {
          "timestamp": 1583271000000,
          "testTypeList": [
            {
              "testType": null,
              "categoryType": null,
              "testStatusList": [
                {
                  "testStatus": "pass",
                  "count": 558
                }
              ],
              "failedRate": 0.00,
              "childList": [

              ]
            }
          ]
        },
        {
          "timestamp": 1583272800000,
          "testTypeList": [
            {
              "testType": null,
              "categoryType": null,
              "testStatusList": [
                {
                  "testStatus": "pass",
                  "count": 522
                }
              ],
              "failedRate": 0.00,
              "childList": [

              ]
            }
          ]
        },
        {
          "timestamp": 1583274600000,
          "testTypeList": [
            {
              "testType": null,
              "categoryType": null,
              "testStatusList": [
                {
                  "testStatus": "pass",
                  "count": 256
                },
                {
                  "testStatus": "fail",
                  "count": 56
                }
              ],
              "targetServerList": [

              ],
              "failedRate": 17.95,
              "discoverRate": null,
              "childList": [

              ]
            }
          ]
        },
        {
          "timestamp": 1583276400000,
          "testTypeList": [
            {
              "testType": null,
              "categoryType": null,
              "testStatusList": [
                {
                  "testStatus": "pass",
                  "count": 378
                }
              ],
              "failedRate": 0.00,
              "childList": [

              ]
            }
          ]
        },
        {
          "timestamp": 1583278200000,
          "testTypeList": [
            {
              "testType": null,
              "categoryType": null,
              "testStatusList": [
                {
                  "testStatus": "pass",
                  "count": 256
                },
                {
                  "testStatus": "fail",
                  "count": 56
                }
              ],
              "targetServerList": [

              ],
              "failedRate": 17.95,
              "discoverRate": null,
              "childList": [

              ]
            }
          ]
        },
        {
          "timestamp": 1583280000000,
          "testTypeList": [
            {
              "testType": null,
              "categoryType": null,
              "testStatusList": [
                {
                  "testStatus": "pass",
                  "count": 342
                }
              ],
              "failedRate": 0.00,
              "childList": [

              ]
            }
          ]
        },
        {
          "timestamp": 1583281800000,
          "testTypeList": [
            {
              "testType": null,
              "categoryType": null,
              "testStatusList": [
                {
                  "testStatus": "pass",
                  "count": 468
                }
              ],
              "failedRate": 0.00,
              "childList": [

              ]
            }
          ]
        },
        {
          "timestamp": 1583283600000,
          "testTypeList": [
            {
              "testType": null,
              "categoryType": null,
              "testStatusList": [
                {
                  "testStatus": "pass",
                  "count": 540
                }
              ],
              "failedRate": 0.00,
              "childList": [

              ]
            }
          ]
        },
        {
          "timestamp": 1583285400000,
          "testTypeList": [
            {
              "testType": null,
              "categoryType": null,
              "testStatusList": [
                {
                  "testStatus": "pass",
                  "count": 540
                }
              ],
              "failedRate": 0.00,
              "childList": [

              ]
            }
          ]
        },
        {
          "timestamp": 1583287200000,
          "testTypeList": [
            {
              "testType": null,
              "categoryType": null,
              "testStatusList": [
                {
                  "testStatus": "pass",
                  "count": 522
                }
              ],
              "failedRate": 0.00,
              "childList": [

              ]
            }
          ]
        },
        {
          "timestamp": 1583289000000,
          "testTypeList": [
            {
              "testType": null,
              "categoryType": null,
              "testStatusList": [
                {
                  "testStatus": "pass",
                  "count": 256
                },
                {
                  "testStatus": "fail",
                  "count": 56
                }
              ],
              "targetServerList": [

              ],
              "failedRate": 17.95,
              "discoverRate": null,
              "childList": [

              ]
            }
          ]
        },
        {
          "timestamp": 1583290800000,
          "testTypeList": [
            {
              "testType": null,
              "categoryType": null,
              "testStatusList": [
                {
                  "testStatus": "pass",
                  "count": 540
                }
              ],
              "failedRate": 0.00,
              "childList": [

              ]
            }
          ]
        },
        {
          "timestamp": 1583292600000,
          "testTypeList": [
            {
              "testType": null,
              "categoryType": null,
              "testStatusList": [
                {
                  "testStatus": "pass",
                  "count": 558
                }
              ],
              "failedRate": 0.00,
              "childList": [

              ]
            }
          ]
        },
        {
          "timestamp": 1583294400000,
          "testTypeList": [
            {
              "testType": null,
              "categoryType": null,
              "testStatusList": [
                {
                  "testStatus": "pass",
                  "count": 540
                }
              ],
              "failedRate": 0.00,
              "childList": [

              ]
            }
          ]
        },
        {
          "timestamp": 1583296200000,
          "testTypeList": [
            {
              "testType": null,
              "categoryType": null,
              "testStatusList": [
                {
                  "testStatus": "pass",
                  "count": 256
                },
                {
                  "testStatus": "fail",
                  "count": 56
                }
              ],
              "targetServerList": [

              ],
              "failedRate": 17.95,
              "discoverRate": null,
              "childList": [

              ]
            }
          ]
        },
        {
          "timestamp": 1583298000000,
          "testTypeList": [
            {
              "testType": null,
              "categoryType": null,
              "testStatusList": [
                {
                  "testStatus": "pass",
                  "count": 256
                },
                {
                  "testStatus": "fail",
                  "count": 56
                }
              ],
              "targetServerList": [

              ],
              "failedRate": 17.95,
              "discoverRate": null,
              "childList": [

              ]
            }
          ]
        },
        {
          "timestamp": 1583299800000,
          "testTypeList": [
            {
              "testType": null,
              "categoryType": null,
              "testStatusList": [
                {
                  "testStatus": "pass",
                  "count": 522
                }
              ],
              "failedRate": 0.00,
              "childList": [

              ]
            }
          ]
        },
        {
          "timestamp": 1583301600000,
          "testTypeList": [
            {
              "testType": null,
              "categoryType": null,
              "testStatusList": [
                {
                  "testStatus": "pass",
                  "count": 256
                },
                {
                  "testStatus": "fail",
                  "count": 56
                }
              ],
              "targetServerList": [

              ],
              "failedRate": 17.95,
              "discoverRate": null,
              "childList": [

              ]
            }
          ]
        },
        {
          "timestamp": 1583303400000,
          "testTypeList": [
            {
              "testType": null,
              "categoryType": null,
              "testStatusList": [
                {
                  "testStatus": "pass",
                  "count": 162
                }
              ],
              "failedRate": 0.00,
              "childList": [

              ]
            }
          ]
        },
        {
          "timestamp": 1583305200000,
          "testTypeList": [

          ]
        }
      ]
    },
    {
      "breakdown": "Sensor-4230",
      "breakdownValue": "0c:75:bd:0d:5d:c0",
      "overallAgg": [
        {
          "testType": "overall",
          "categoryType": "category",
          "testStatusList": [

          ],
          "failedRate": 0.46,
          "childList": [

          ]
        },
        {
          "testType": "ONBOARDING",
          "categoryType": "category",
          "testStatusList": [
            {
              "testStatus": "pass",
              "count": 3090
            },
            {
              "testStatus": "fail",
              "count": 82
            }
          ],
          "failedRate": 2.59,
          "childList": [
            {
              "testType": "ASSOC",
              "categoryType": "subCategory",
              "testStatusList": [
                {
                  "testStatus": "pass",
                  "count": 1030
                },
                {
                  "testStatus": "fail",
                  "count": 82
                }
              ],
              "failedRate": 7.37,
              "childList": [

              ]
            },
            {
              "testType": "AUTH",
              "categoryType": "subCategory",
              "testStatusList": [
                {
                  "testStatus": "pass",
                  "count": 1030
                }
              ],
              "failedRate": 0.00,
              "childList": [

              ]
            },
            {
              "testType": "DHCP",
              "categoryType": "subCategory",
              "testStatusList": [
                {
                  "testStatus": "pass",
                  "count": 1030
                }
              ],
              "failedRate": 0.00,
              "childList": [

              ]
            },
            {
              "testType": "WEBAUTH",
              "categoryType": "subCategory",
              "testStatusList": [
                {
                  "testStatus": "pass",
                  "count": 660
                }
              ],
              "failedRate": 0.01,
              "childList": [

              ]
            }
          ]
        },
        {
          "testType": "RF_ASSESSMENT",
          "categoryType": "category",
          "testStatusList": [
            {
              "testStatus": "pass",
              "count": 10258
            }
          ],
          "failedRate": 0.00,
          "childList": [
            {
              "testType": "DATA_RATE",
              "categoryType": "subCategory",
              "testStatusList": [
                {
                  "testStatus": "pass",
                  "count": 5129
                }
              ],
              "failedRate": 0.00,
              "childList": [

              ]
            },
            {
              "testType": "SNR",
              "categoryType": "subCategory",
              "testStatusList": [
                {
                  "testStatus": "pass",
                  "count": 5129
                }
              ],
              "failedRate": 0.00,
              "childList": [

              ]
            }
          ]
        },
        {
          "testType": "NETWORK_SERVICES",
          "categoryType": "category",
          "testStatusList": [
            {
              "testStatus": "pass",
              "count": 31892
            },
            {
              "testStatus": "fail",
              "count": 2624
            }
          ],
          "failedRate": 27.81,
          "childList": [
            {
              "testType": "DNS",
              "categoryType": "subCategory",
              "testStatusList": [
                {
                  "testStatus": "pass",
                  "count": 15074
                },
                {
                  "testStatus": "fail",
                  "count": 2187
                }
              ],
              "failedRate": 27.08,
              "childList": [

              ]
            },
            {
              "testType": "RADIUS",
              "categoryType": "subCategory",
              "testStatusList": [
                {
                  "testStatus": "pass",
                  "count": 16818
                },
                {
                  "testStatus": "fail",
                  "count": 437
                }
              ],
              "failedRate": 2.53,
              "childList": [

              ]
            }
          ]
        },
        {
          "testType": "PERFORMANCE",
          "categoryType": "category",
          "testStatusList": [
            {
              "testStatus": "pass",
              "count": 2401
            },
            {
              "testStatus": "fail",
              "count": 100
            }
          ],
          "failedRate": 0.10,
          "childList": [
            {
              "testType": "SPEED",
              "categoryType": "subCategory",
              "testStatusList": [
                {
                  "testStatus": "pass",
                  "count": 1379
                },
                {
                  "testStatus": "fail",
                  "count": 100
                }

              ],
              "failedRate": 0.07,
              "childList": [

              ]
            },
            {
              "testType": "IPSLASENDER",
              "categoryType": "subCategory",
              "testStatusList": [
                {
                  "testStatus": "pass",
                  "count": 1022
                },
                {
                  "testStatus": "fail",
                  "count": 1
                }
              ],
              "failedRate": 0.10,
              "childList": [

              ]
            }
          ]
        },
        {
          "testType": "APP_CONNECTIVITY",
          "categoryType": "category",
          "testStatusList": [
            {
              "testStatus": "pass",
              "count": 2050
            },
            {
              "testStatus": "fail",
              "count": 2
            }
          ],
          "failedRate": 0.10,
          "childList": [
            {
              "testType": "HOST_REACHABILITY",
              "categoryType": "subCategory",
              "testStatusList": [
                {
                  "testStatus": "pass",
                  "count": 1026
                }
              ],
              "failedRate": 0.00,
              "childList": [

              ]
            },
            {
              "testType": "WEBSERVER",
              "categoryType": "subCategory",
              "testStatusList": [
                {
                  "testStatus": "pass",
                  "count": 1024
                },
                {
                  "testStatus": "fail",
                  "count": 2
                }
              ],
              "failedRate": 0.19,
              "childList": [

              ]
            },
            {
              "testType": "FILETRANSFER",
              "categoryType": "subCategory",
              "testStatusList": [
                {
                  "testStatus": "pass",
                  "count": 17124
                },
                {
                  "testStatus": "fail",
                  "count": 120
                }
              ],
              "failedRate": 0.70,
              "childList": [

              ]
            }
          ]
        },
        {
          "testType": "EMAIL",
          "categoryType": "category",
          "testStatusList": [
                {
                  "testStatus": "pass",
                  "count": 2928
                },
                {
                  "testStatus": "fail",
                  "count": 654
                }

          ],
          "failedRate": 22.3,
          "childList": [
            {
              "testType": "MAILSERVER",
              "categoryType": "subCategory",
              "testStatusList": [
                {
                  "testStatus": "pass",
                  "count": 1204
                },
                {
                  "testStatus": "fail",
                  "count": 120
                }

              ],
              "failedRate": 9.90,
              "childList": [

              ]
            },
            {
              "testType": "OUTLOOKSERVER",
              "categoryType": "subCategory",
              "testStatusList": [
                {
                  "testStatus": "pass",
                  "count": 1724
                },
                {
                  "testStatus": "fail",
                  "count": 534
                }

              ],
              "failedRate": 31,
              "childList": [

              ]
            }
          ]
        }
      ],
      "dataList": [
        {
          "timestamp": 1583218800000,
          "testTypeList": [
            {
              "testType": null,
              "categoryType": null,
              "testStatusList": [
                {
                  "testStatus": "pass",
                  "count": 324
                }
              ],
              "failedRate": 0.00,
              "childList": [

              ]
            }
          ]
        },
        {
          "timestamp": 1583220600000,
          "testTypeList": [
            {
              "testType": null,
              "categoryType": null,
              "testStatusList": [
                {
                  "testStatus": "pass",
                  "count": 324
                }
              ],
              "failedRate": 0.00,
              "childList": [

              ]
            }
          ]
        },
        {
          "timestamp": 1583222400000,
          "testTypeList": [
            {
              "testType": null,
              "categoryType": null,
              "testStatusList": [
                {
                  "testStatus": "pass",
                  "count": 256
                },
                {
                  "testStatus": "fail",
                  "count": 56
                }
              ],
              "targetServerList": [

              ],
              "failedRate": 17.95,
              "discoverRate": null,
              "childList": [

              ]
            }
          ]
        },
        {
          "timestamp": 1583224200000,
          "testTypeList": [
            {
              "testType": null,
              "categoryType": null,
              "testStatusList": [
                {
                  "testStatus": "pass",
                  "count": 354
                }
              ],
              "failedRate": 0.00,
              "childList": [

              ]
            }
          ]
        },
        {
          "timestamp": 1583226000000,
          "testTypeList": [
            {
              "testType": null,
              "categoryType": null,
              "testStatusList": [
                {
                  "testStatus": "pass",
                  "count": 360
                }
              ],
              "failedRate": 0.00,
              "childList": [

              ]
            }
          ]
        },
        {
          "timestamp": 1583227800000,
          "testTypeList": [
            {
              "testType": null,
              "categoryType": null,
              "testStatusList": [
                {
                  "testStatus": "pass",
                  "count": 345
                }
              ],
              "failedRate": 0.00,
              "childList": [

              ]
            }
          ]
        },
        {
          "timestamp": 1583229600000,
          "testTypeList": [
            {
              "testType": null,
              "categoryType": null,
              "testStatusList": [
                {
                  "testStatus": "pass",
                  "count": 360
                }
              ],
              "failedRate": 0.00,
              "childList": [

              ]
            }
          ]
        },
        {
          "timestamp": 1583231400000,
          "testTypeList": [
            {
              "testType": null,
              "categoryType": null,
              "testStatusList": [
                {
                  "testStatus": "pass",
                  "count": 342
                }
              ],
              "failedRate": 0.00,
              "childList": [

              ]
            }
          ]
        },
        {
          "timestamp": 1583233200000,
          "testTypeList": [
            {
              "testType": null,
              "categoryType": null,
              "testStatusList": [
                {
                  "testStatus": "pass",
                  "count": 345
                }
              ],
              "failedRate": 0.00,
              "childList": [

              ]
            }
          ]
        },
        {
          "timestamp": 1583235000000,
          "testTypeList": [
            {
              "testType": null,
              "categoryType": null,
              "testStatusList": [
                {
                  "testStatus": "pass",
                  "count": 360
                }
              ],
              "failedRate": 0.00,
              "childList": [

              ]
            }
          ]
        },
        {
          "timestamp": 1583236800000,
          "testTypeList": [
            {
              "testType": null,
              "categoryType": null,
              "testStatusList": [
                {
                  "testStatus": "pass",
                  "count": 270
                }
              ],
              "failedRate": 0.00,
              "childList": [

              ]
            }
          ]
        },
        {
          "timestamp": 1583238600000,
          "testTypeList": [
            {
              "testType": null,
              "categoryType": null,
              "testStatusList": [
                {
                  "testStatus": "pass",
                  "count": 285
                }
              ],
              "failedRate": 0.00,
              "childList": [

              ]
            }
          ]
        },
        {
          "timestamp": 1583240400000,
          "testTypeList": [
            {
              "testType": null,
              "categoryType": null,
              "testStatusList": [
                {
                  "testStatus": "pass",
                  "count": 522
                }
              ],
              "failedRate": 0.00,
              "childList": [

              ]
            }
          ]
        },
        {
          "timestamp": 1583242200000,
          "testTypeList": [
            {
              "testType": null,
              "categoryType": null,
              "testStatusList": [
                {
                  "testStatus": "pass",
                  "count": 546
                }
              ],
              "failedRate": 0.00,
              "childList": [

              ]
            }
          ]
        },
        {
          "timestamp": 1583244000000,
          "testTypeList": [
            {
              "testType": null,
              "categoryType": null,
              "testStatusList": [
                {
                  "testStatus": "pass",
                  "count": 498
                }
              ],
              "failedRate": 0.00,
              "childList": [

              ]
            }
          ]
        },
        {
          "timestamp": 1583245800000,
          "testTypeList": [
            {
              "testType": null,
              "categoryType": null,
              "testStatusList": [
                {
                  "testStatus": "pass",
                  "count": 522
                }
              ],
              "failedRate": 0.00,
              "childList": [

              ]
            }
          ]
        },
        {
          "timestamp": 1583247600000,
          "testTypeList": [
            {
              "testType": null,
              "categoryType": null,
              "testStatusList": [
                {
                  "testStatus": "pass",
                  "count": 558
                }
              ],
              "failedRate": 0.00,
              "childList": [

              ]
            }
          ]
        },
        {
          "timestamp": 1583249400000,
          "testTypeList": [
            {
              "testType": null,
              "categoryType": null,
              "testStatusList": [
                {
                  "testStatus": "pass",
                  "count": 486
                }
              ],
              "failedRate": 0.00,
              "childList": [

              ]
            }
          ]
        },
        {
          "timestamp": 1583251200000,
          "testTypeList": [
            {
              "testType": null,
              "categoryType": null,
              "testStatusList": [
                {
                  "testStatus": "pass",
                  "count": 522
                }
              ],
              "failedRate": 0.00,
              "childList": [

              ]
            }
          ]
        },
        {
          "timestamp": 1583253000000,
          "testTypeList": [
            {
              "testType": null,
              "categoryType": null,
              "testStatusList": [
                {
                  "testStatus": "pass",
                  "count": 359
                },
                {
                  "testStatus": "fail",
                  "count": 7
                }
              ],
              "failedRate": 1.91,
              "childList": [

              ]
            }
          ]
        },
        {
          "timestamp": 1583254800000,
          "testTypeList": [
            {
              "testType": null,
              "categoryType": null,
              "testStatusList": [
                {
                  "testStatus": "pass",
                  "count": 414
                },
                {
                  "testStatus": "fail",
                  "count": 21
                }
              ],
              "failedRate": 4.83,
              "childList": [

              ]
            }
          ]
        },
        {
          "timestamp": 1583256600000,
          "testTypeList": [
            {
              "testType": null,
              "categoryType": null,
              "testStatusList": [
                {
                  "testStatus": "pass",
                  "count": 342
                }
              ],
              "failedRate": 0.00,
              "childList": [

              ]
            }
          ]
        },
        {
          "timestamp": 1583258400000,
          "testTypeList": [
            {
              "testType": null,
              "categoryType": null,
              "testStatusList": [
                {
                  "testStatus": "pass",
                  "count": 324
                }
              ],
              "failedRate": 0.00,
              "childList": [

              ]
            }
          ]
        },
        {
          "timestamp": 1583260200000,
          "testTypeList": [
            {
              "testType": null,
              "categoryType": null,
              "testStatusList": [
                {
                  "testStatus": "pass",
                  "count": 321
                }
              ],
              "failedRate": 0.00,
              "childList": [

              ]
            }
          ]
        },
        {
          "timestamp": 1583262000000,
          "testTypeList": [
            {
              "testType": null,
              "categoryType": null,
              "testStatusList": [
                {
                  "testStatus": "pass",
                  "count": 256
                },
                {
                  "testStatus": "fail",
                  "count": 56
                }
              ],
              "targetServerList": [

              ],
              "failedRate": 17.95,
              "discoverRate": null,
              "childList": [

              ]
            }
          ]
        },
        {
          "timestamp": 1583263800000,
          "testTypeList": [
            {
              "testType": null,
              "categoryType": null,
              "testStatusList": [
                {
                  "testStatus": "pass",
                  "count": 378
                },
                {
                  "testStatus": "fail",
                  "count": 25
                }
              ],
              "failedRate": 6.20,
              "childList": [

              ]
            }
          ]
        },
        {
          "timestamp": 1583265600000,
          "testTypeList": [
            {
              "testType": null,
              "categoryType": null,
              "testStatusList": [
                {
                  "testStatus": "pass",
                  "count": 378
                },
                {
                  "testStatus": "fail",
                  "count": 3
                }
              ],
              "failedRate": 0.79,
              "childList": [

              ]
            }
          ]
        },
        {
          "timestamp": 1583267400000,
          "testTypeList": [
            {
              "testType": null,
              "categoryType": null,
              "testStatusList": [
                {
                  "testStatus": "pass",
                  "count": 267
                }
              ],
              "failedRate": 0.00,
              "childList": [

              ]
            }
          ]
        },
        {
          "timestamp": 1583269200000,
          "testTypeList": [
            {
              "testType": null,
              "categoryType": null,
              "testStatusList": [
                {
                  "testStatus": "pass",
                  "count": 324
                }
              ],
              "failedRate": 0.00,
              "childList": [

              ]
            }
          ]
        },
        {
          "timestamp": 1583271000000,
          "testTypeList": [
            {
              "testType": null,
              "categoryType": null,
              "testStatusList": [
                {
                  "testStatus": "pass",
                  "count": 378
                }
              ],
              "failedRate": 0.00,
              "childList": [

              ]
            }
          ]
        },
        {
          "timestamp": 1583272800000,
          "testTypeList": [
            {
              "testType": null,
              "categoryType": null,
              "testStatusList": [
                {
                  "testStatus": "pass",
                  "count": 306
                }
              ],
              "failedRate": 0.00,
              "childList": [

              ]
            }
          ]
        },
        {
          "timestamp": 1583274600000,
          "testTypeList": [
            {
              "testType": null,
              "categoryType": null,
              "testStatusList": [
                {
                  "testStatus": "pass",
                  "count": 252
                }
              ],
              "failedRate": 0.00,
              "childList": [

              ]
            }
          ]
        },
        {
          "timestamp": 1583276400000,
          "testTypeList": [
            {
              "testType": null,
              "categoryType": null,
              "testStatusList": [
                {
                  "testStatus": "pass",
                  "count": 288
                }
              ],
              "failedRate": 0.00,
              "childList": [

              ]
            }
          ]
        },
        {
          "timestamp": 1583278200000,
          "testTypeList": [
            {
              "testType": null,
              "categoryType": null,
              "testStatusList": [
                {
                  "testStatus": "pass",
                  "count": 360
                }
              ],
              "failedRate": 0.00,
              "childList": [

              ]
            }
          ]
        },
        {
          "timestamp": 1583280000000,
          "testTypeList": [
            {
              "testType": null,
              "categoryType": null,
              "testStatusList": [
                {
                  "testStatus": "pass",
                  "count": 256
                },
                {
                  "testStatus": "fail",
                  "count": 56
                }
              ],
              "targetServerList": [

              ],
              "failedRate": 17.95,
              "discoverRate": null,
              "childList": [

              ]
            }
          ]
        },
        {
          "timestamp": 1583281800000,
          "testTypeList": [
            {
              "testType": null,
              "categoryType": null,
              "testStatusList": [
                {
                  "testStatus": "pass",
                  "count": 447
                },
                {
                  "testStatus": "fail",
                  "count": 23
                }
              ],
              "failedRate": 4.89,
              "childList": [

              ]
            }
          ]
        },
        {
          "timestamp": 1583283600000,
          "testTypeList": [
            {
              "testType": null,
              "categoryType": null,
              "testStatusList": [
                {
                  "testStatus": "pass",
                  "count": 256
                },
                {
                  "testStatus": "fail",
                  "count": 56
                }
              ],
              "targetServerList": [

              ],
              "failedRate": 17.95,
              "discoverRate": null,
              "childList": [

              ]
            }
          ]
        },
        {
          "timestamp": 1583285400000,
          "testTypeList": [
            {
              "testType": null,
              "categoryType": null,
              "testStatusList": [
                {
                  "testStatus": "pass",
                  "count": 414
                }
              ],
              "failedRate": 0.00,
              "childList": [

              ]
            }
          ]
        },
        {
          "timestamp": 1583287200000,
          "testTypeList": [
            {
              "testType": null,
              "categoryType": null,
              "testStatusList": [
                {
                  "testStatus": "pass",
                  "count": 360
                }
              ],
              "failedRate": 0.00,
              "childList": [

              ]
            }
          ]
        },
        {
          "timestamp": 1583289000000,
          "testTypeList": [
            {
              "testType": null,
              "categoryType": null,
              "testStatusList": [
                {
                  "testStatus": "pass",
                  "count": 306
                }
              ],
              "failedRate": 0.00,
              "childList": [

              ]
            }
          ]
        },
        {
          "timestamp": 1583290800000,
          "testTypeList": [
            {
              "testType": null,
              "categoryType": null,
              "testStatusList": [
                {
                  "testStatus": "pass",
                  "count": 360
                }
              ],
              "failedRate": 0.00,
              "childList": [

              ]
            }
          ]
        },
        {
          "timestamp": 1583292600000,
          "testTypeList": [
            {
              "testType": null,
              "categoryType": null,
              "testStatusList": [
                {
                  "testStatus": "pass",
                  "count": 432
                }
              ],
              "failedRate": 0.00,
              "childList": [

              ]
            }
          ]
        },
        {
          "timestamp": 1583294400000,
          "testTypeList": [
            {
              "testType": null,
              "categoryType": null,
              "testStatusList": [
                {
                  "testStatus": "pass",
                  "count": 256
                },
                {
                  "testStatus": "fail",
                  "count": 56
                }
              ],
              "targetServerList": [

              ],
              "failedRate": 17.95,
              "discoverRate": null,
              "childList": [

              ]
            }
          ]
        },
        {
          "timestamp": 1583296200000,
          "testTypeList": [
            {
              "testType": null,
              "categoryType": null,
              "testStatusList": [
                {
                  "testStatus": "pass",
                  "count": 540
                }
              ],
              "failedRate": 0.00,
              "childList": [

              ]
            }
          ]
        },
        {
          "timestamp": 1583298000000,
          "testTypeList": [
            {
              "testType": null,
              "categoryType": null,
              "testStatusList": [
                {
                  "testStatus": "pass",
                  "count": 522
                }
              ],
              "failedRate": 0.00,
              "childList": [

              ]
            }
          ]
        },
        {
          "timestamp": 1583299800000,
          "testTypeList": [
            {
              "testType": null,
              "categoryType": null,
              "testStatusList": [
                {
                  "testStatus": "pass",
                  "count": 256
                },
                {
                  "testStatus": "fail",
                  "count": 56
                }
              ],
              "targetServerList": [

              ],
              "failedRate": 17.95,
              "discoverRate": null,
              "childList": [

              ]
            }
          ]
        },
        {
          "timestamp": 1583301600000,
          "testTypeList": [
            {
              "testType": null,
              "categoryType": null,
              "testStatusList": [
                {
                  "testStatus": "pass",
                  "count": 504
                }
              ],
              "failedRate": 0.00,
              "childList": [

              ]
            }
          ]
        },
        {
          "timestamp": 1583303400000,
          "testTypeList": [
            {
              "testType": null,
              "categoryType": null,
              "testStatusList": [
                {
                  "testStatus": "pass",
                  "count": 256
                },
                {
                  "testStatus": "fail",
                  "count": 56
                }
              ],
              "targetServerList": [

              ],
              "failedRate": 17.95,
              "discoverRate": null,
              "childList": [

              ]
            }
          ]
        },
        {
          "timestamp": 1583305200000,
          "testTypeList": [

          ]
        }
      ]
    },
    {
      "breakdown": "Sensor.3910",
      "breakdownValue": "70:f3:5a:7b:4a:80",
      "overallAgg": [
        {
          "testType": "overall",
          "categoryType": "category",
          "testStatusList": [

          ],
          "failedRate": 0.42,
          "childList": [

          ]
        },
        {
          "testType": "ONBOARDING",
          "categoryType": "category",
          "testStatusList": [
            {
              "testStatus": "pass",
              "count": 6351
            },
            {
              "testStatus": "fail",
              "count": 156
            }
          ],
          "failedRate": 2.40,
          "childList": [
            {
              "testType": "ASSOC",
              "categoryType": "subCategory",
              "testStatusList": [
                {
                  "testStatus": "pass",
                  "count": 2117
                },
                {
                  "testStatus": "fail",
                  "count": 156
                }
              ],
              "failedRate": 6.86,
              "childList": [

              ]
            },
            {
              "testType": "AUTH",
              "categoryType": "subCategory",
              "testStatusList": [
                {
                  "testStatus": "pass",
                  "count": 2117
                }
              ],
              "failedRate": 0.00,
              "childList": [

              ]
            },
            {
              "testType": "DHCP",
              "categoryType": "subCategory",
              "testStatusList": [
                {
                  "testStatus": "pass",
                  "count": 2117
                }
              ],
              "failedRate": 0.00,
              "childList": [

              ]
            },
            {
              "testType": "WEBAUTH",
              "categoryType": "subCategory",
              "testStatusList": [
                {
                  "testStatus": "pass",
                  "count": 660
                }
              ],
              "failedRate": 0.01,
              "childList": [

              ]
            }
          ]
        },
        {
          "testType": "RF_ASSESSMENT",
          "categoryType": "category",
          "testStatusList": [
            {
              "testStatus": "pass",
              "count": 21156
            }
          ],
          "failedRate": 0.00,
          "childList": [
            {
              "testType": "DATA_RATE",
              "categoryType": "subCategory",
              "testStatusList": [
                {
                  "testStatus": "pass",
                  "count": 10578
                }
              ],
              "failedRate": 0.00,
              "childList": [

              ]
            },
            {
              "testType": "SNR",
              "categoryType": "subCategory",
              "testStatusList": [
                {
                  "testStatus": "pass",
                  "count": 10578
                }
              ],
              "failedRate": 0.00,
              "childList": [

              ]
            }
          ]
        },
        {
          "testType": "NETWORK_SERVICES",
          "categoryType": "category",
          "testStatusList": [
            {
              "testStatus": "pass",
              "count": 31892
            },
            {
              "testStatus": "fail",
              "count": 2624
            }
          ],
          "failedRate": 27.81,
          "childList": [
            {
              "testType": "DNS",
              "categoryType": "subCategory",
              "testStatusList": [
                {
                  "testStatus": "pass",
                  "count": 15074
                },
                {
                  "testStatus": "fail",
                  "count": 2187
                }
              ],
              "failedRate": 27.08,
              "childList": [

              ]
            },
            {
              "testType": "RADIUS",
              "categoryType": "subCategory",
              "testStatusList": [
                {
                  "testStatus": "pass",
                  "count": 16818
                },
                {
                  "testStatus": "fail",
                  "count": 437
                }
              ],
              "failedRate": 2.53,
              "childList": [

              ]
            }
          ]
        },
        {
          "testType": "PERFORMANCE",
          "categoryType": "category",
          "testStatusList": [
            {
              "testStatus": "pass",
              "count": 4232
            },
            {
              "testStatus": "fail",
              "count": 2
            }
          ],
          "failedRate": 0.05,
          "childList": [
            {
              "testType": "SPEED",
              "categoryType": "subCategory",
              "testStatusList": [
                {
                  "testStatus": "pass",
                  "count": 2116
                },
                {
                  "testStatus": "fail",
                  "count": 1
                }

              ],
              "failedRate": 0.05,
              "childList": [

              ]
            },
            {
              "testType": "IPSLASENDER",
              "categoryType": "subCategory",
              "testStatusList": [
                {
                  "testStatus": "pass",
                  "count": 2116
                },
                {
                  "testStatus": "fail",
                  "count": 1
                }
              ],
              "failedRate": 0.05,
              "childList": [

              ]
            }
          ]
        },
        {
          "testType": "APP_CONNECTIVITY",
          "categoryType": "category",
          "testStatusList": [
            {
              "testStatus": "pass",
              "count": 4226
            },
            {
              "testStatus": "fail",
              "count": 3
            }
          ],
          "failedRate": 0.07,
          "childList": [
            {
              "testType": "HOST_REACHABILITY",
              "categoryType": "subCategory",
              "testStatusList": [
                {
                  "testStatus": "pass",
                  "count": 2116
                }
              ],
              "failedRate": 0.00,
              "childList": [

              ]
            },
            {
              "testType": "WEBSERVER",
              "categoryType": "subCategory",
              "testStatusList": [
                {
                  "testStatus": "pass",
                  "count": 2110
                },
                {
                  "testStatus": "fail",
                  "count": 3
                }
              ],
              "failedRate": 0.14,
              "childList": [

              ]
            },
            {
              "testType": "FILETRANSFER",
              "categoryType": "subCategory",
              "testStatusList": [
                {
                  "testStatus": "pass",
                  "count": 17124
                },
                {
                  "testStatus": "fail",
                  "count": 120
                }
              ],
              "failedRate": 0.70,
              "childList": [

              ]
            }
          ]
        },
        {
          "testType": "EMAIL",
          "categoryType": "category",
          "testStatusList": [
                {
                  "testStatus": "pass",
                  "count": 2928
                },
                {
                  "testStatus": "fail",
                  "count": 654
                }

          ],
          "failedRate": 22.3,
          "childList": [
            {
              "testType": "MAILSERVER",
              "categoryType": "subCategory",
              "testStatusList": [
                {
                  "testStatus": "pass",
                  "count": 1204
                },
                {
                  "testStatus": "fail",
                  "count": 120
                }

              ],
              "failedRate": 9.90,
              "childList": [

              ]
            },
            {
              "testType": "OUTLOOKSERVER",
              "categoryType": "subCategory",
              "testStatusList": [
                {
                  "testStatus": "pass",
                  "count": 1724
                },
                {
                  "testStatus": "fail",
                  "count": 534
                }

              ],
              "failedRate": 31,
              "childList": [

              ]
            }
          ]
        }
      ],
      "dataList": [
        {
          "timestamp": 1583218800000,
          "testTypeList": [
            {
              "testType": null,
              "categoryType": null,
              "testStatusList": [
                {
                  "testStatus": "pass",
                  "count": 861
                },
                {
                  "testStatus": "fail",
                  "count": 3
                }
              ],
              "failedRate": 0.35,
              "childList": [

              ]
            }
          ]
        },
        {
          "timestamp": 1583220600000,
          "testTypeList": [
            {
              "testType": null,
              "categoryType": null,
              "testStatusList": [
                {
                  "testStatus": "pass",
                  "count": 882
                }
              ],
              "failedRate": 0.00,
              "childList": [

              ]
            }
          ]
        },
        {
          "timestamp": 1583222400000,
          "testTypeList": [
            {
              "testType": null,
              "categoryType": null,
              "testStatusList": [
                {
                  "testStatus": "pass",
                  "count": 828
                }
              ],
              "failedRate": 0.00,
              "childList": [

              ]
            }
          ]
        },
        {
          "timestamp": 1583224200000,
          "testTypeList": [
            {
              "testType": null,
              "categoryType": null,
              "testStatusList": [
                {
                  "testStatus": "pass",
                  "count": 324
                }
              ],
              "failedRate": 0.00,
              "childList": [

              ]
            }
          ]
        },
        {
          "timestamp": 1583226000000,
          "testTypeList": [
            {
              "testType": null,
              "categoryType": null,
              "testStatusList": [
                {
                  "testStatus": "pass",
                  "count": 256
                },
                {
                  "testStatus": "fail",
                  "count": 56
                }
              ],
              "targetServerList": [

              ],
              "failedRate": 17.95,
              "discoverRate": null,
              "childList": [

              ]
            }
          ]
        },
        {
          "timestamp": 1583227800000,
          "testTypeList": [
            {
              "testType": null,
              "categoryType": null,
              "testStatusList": [
                {
                  "testStatus": "pass",
                  "count": 900
                }
              ],
              "failedRate": 0.00,
              "childList": [

              ]
            }
          ]
        },
        {
          "timestamp": 1583229600000,
          "testTypeList": [
            {
              "testType": null,
              "categoryType": null,
              "testStatusList": [
                {
                  "testStatus": "pass",
                  "count": 882
                }
              ],
              "failedRate": 0.00,
              "childList": [

              ]
            }
          ]
        },
        {
          "timestamp": 1583231400000,
          "testTypeList": [
            {
              "testType": null,
              "categoryType": null,
              "testStatusList": [
                {
                  "testStatus": "pass",
                  "count": 879
                }
              ],
              "failedRate": 0.00,
              "childList": [

              ]
            }
          ]
        },
        {
          "timestamp": 1583233200000,
          "testTypeList": [
            {
              "testType": null,
              "categoryType": null,
              "testStatusList": [
                {
                  "testStatus": "pass",
                  "count": 864
                },
                {
                  "testStatus": "fail",
                  "count": 1
                }
              ],
              "failedRate": 0.12,
              "childList": [

              ]
            }
          ]
        },
        {
          "timestamp": 1583235000000,
          "testTypeList": [
            {
              "testType": null,
              "categoryType": null,
              "testStatusList": [
                {
                  "testStatus": "pass",
                  "count": 792
                },
                {
                  "testStatus": "fail",
                  "count": 43
                }
              ],
              "failedRate": 5.15,
              "childList": [

              ]
            }
          ]
        },
        {
          "timestamp": 1583236800000,
          "testTypeList": [
            {
              "testType": null,
              "categoryType": null,
              "testStatusList": [
                {
                  "testStatus": "pass",
                  "count": 828
                }
              ],
              "failedRate": 0.00,
              "childList": [

              ]
            }
          ]
        },
        {
          "timestamp": 1583238600000,
          "testTypeList": [
            {
              "testType": null,
              "categoryType": null,
              "testStatusList": [
                {
                  "testStatus": "pass",
                  "count": 882
                }
              ],
              "failedRate": 0.00,
              "childList": [

              ]
            }
          ]
        },
        {
          "timestamp": 1583240400000,
          "testTypeList": [
            {
              "testType": null,
              "categoryType": null,
              "testStatusList": [
                {
                  "testStatus": "pass",
                  "count": 792
                },
                {
                  "testStatus": "fail",
                  "count": 43
                }
              ],
              "failedRate": 5.15,
              "childList": [

              ]
            }
          ]
        },
        {
          "timestamp": 1583242200000,
          "testTypeList": [
            {
              "testType": null,
              "categoryType": null,
              "testStatusList": [
                {
                  "testStatus": "pass",
                  "count": 828
                },
                {
                  "testStatus": "fail",
                  "count": 1
                }
              ],
              "failedRate": 0.12,
              "childList": [

              ]
            }
          ]
        },
        {
          "timestamp": 1583244000000,
          "testTypeList": [
            {
              "testType": null,
              "categoryType": null,
              "testStatusList": [
                {
                  "testStatus": "pass",
                  "count": 846
                }
              ],
              "failedRate": 0.00,
              "childList": [

              ]
            }
          ]
        },
        {
          "timestamp": 1583245800000,
          "testTypeList": [
            {
              "testType": null,
              "categoryType": null,
              "testStatusList": [
                {
                  "testStatus": "pass",
                  "count": 864
                }
              ],
              "failedRate": 0.00,
              "childList": [

              ]
            }
          ]
        },
        {
          "timestamp": 1583247600000,
          "testTypeList": [
            {
              "testType": null,
              "categoryType": null,
              "testStatusList": [
                {
                  "testStatus": "pass",
                  "count": 828
                }
              ],
              "failedRate": 0.00,
              "childList": [

              ]
            }
          ]
        },
        {
          "timestamp": 1583249400000,
          "testTypeList": [
            {
              "testType": null,
              "categoryType": null,
              "testStatusList": [
                {
                  "testStatus": "pass",
                  "count": 720
                }
              ],
              "failedRate": 0.00,
              "childList": [

              ]
            }
          ]
        },
        {
          "timestamp": 1583251200000,
          "testTypeList": [
            {
              "testType": null,
              "categoryType": null,
              "testStatusList": [
                {
                  "testStatus": "pass",
                  "count": 576
                },
                {
                  "testStatus": "fail",
                  "count": 26
                }
              ],
              "failedRate": 4.32,
              "childList": [

              ]
            }
          ]
        },
        {
          "timestamp": 1583253000000,
          "testTypeList": [
            {
              "testType": null,
              "categoryType": null,
              "testStatusList": [
                {
                  "testStatus": "pass",
                  "count": 558
                }
              ],
              "failedRate": 0.00,
              "childList": [

              ]
            }
          ]
        },
        {
          "timestamp": 1583254800000,
          "testTypeList": [
            {
              "testType": null,
              "categoryType": null,
              "testStatusList": [
                {
                  "testStatus": "pass",
                  "count": 789
                }
              ],
              "failedRate": 0.00,
              "childList": [

              ]
            }
          ]
        },
        {
          "timestamp": 1583256600000,
          "testTypeList": [
            {
              "testType": null,
              "categoryType": null,
              "testStatusList": [
                {
                  "testStatus": "pass",
                  "count": 918
                }
              ],
              "failedRate": 0.00,
              "childList": [

              ]
            }
          ]
        },
        {
          "timestamp": 1583258400000,
          "testTypeList": [
            {
              "testType": null,
              "categoryType": null,
              "testStatusList": [
                {
                  "testStatus": "pass",
                  "count": 882
                }
              ],
              "failedRate": 0.00,
              "childList": [

              ]
            }
          ]
        },
        {
          "timestamp": 1583260200000,
          "testTypeList": [
            {
              "testType": null,
              "categoryType": null,
              "testStatusList": [
                {
                  "testStatus": "pass",
                  "count": 810
                }
              ],
              "failedRate": 0.00,
              "childList": [

              ]
            }
          ]
        },
        {
          "timestamp": 1583262000000,
          "testTypeList": [
            {
              "testType": null,
              "categoryType": null,
              "testStatusList": [
                {
                  "testStatus": "pass",
                  "count": 256
                },
                {
                  "testStatus": "fail",
                  "count": 56
                }
              ],
              "targetServerList": [

              ],
              "failedRate": 17.95,
              "discoverRate": null,
              "childList": [

              ]
            }
          ]
        },
        {
          "timestamp": 1583263800000,
          "testTypeList": [
            {
              "testType": null,
              "categoryType": null,
              "testStatusList": [
                {
                  "testStatus": "pass",
                  "count": 810
                }
              ],
              "failedRate": 0.00,
              "childList": [

              ]
            }
          ]
        },
        {
          "timestamp": 1583265600000,
          "testTypeList": [
            {
              "testType": null,
              "categoryType": null,
              "testStatusList": [
                {
                  "testStatus": "pass",
                  "count": 882
                }
              ],
              "failedRate": 0.00,
              "childList": [

              ]
            }
          ]
        },
        {
          "timestamp": 1583267400000,
          "testTypeList": [
            {
              "testType": null,
              "categoryType": null,
              "testStatusList": [
                {
                  "testStatus": "pass",
                  "count": 843
                }
              ],
              "failedRate": 0.00,
              "childList": [

              ]
            }
          ]
        },
        {
          "timestamp": 1583269200000,
          "testTypeList": [
            {
              "testType": null,
              "categoryType": null,
              "testStatusList": [
                {
                  "testStatus": "pass",
                  "count": 792
                }
              ],
              "failedRate": 0.00,
              "childList": [

              ]
            }
          ]
        },
        {
          "timestamp": 1583271000000,
          "testTypeList": [
            {
              "testType": null,
              "categoryType": null,
              "testStatusList": [
                {
                  "testStatus": "pass",
                  "count": 810
                }
              ],
              "failedRate": 0.00,
              "childList": [

              ]
            }
          ]
        },
        {
          "timestamp": 1583272800000,
          "testTypeList": [
            {
              "testType": null,
              "categoryType": null,
              "testStatusList": [
                {
                  "testStatus": "pass",
                  "count": 256
                },
                {
                  "testStatus": "fail",
                  "count": 56
                }
              ],
              "targetServerList": [

              ],
              "failedRate": 17.95,
              "discoverRate": null,
              "childList": [

              ]
            }
          ]
        },
        {
          "timestamp": 1583274600000,
          "testTypeList": [
            {
              "testType": null,
              "categoryType": null,
              "testStatusList": [
                {
                  "testStatus": "pass",
                  "count": 768
                }
              ],
              "failedRate": 0.00,
              "childList": [

              ]
            }
          ]
        },
        {
          "timestamp": 1583276400000,
          "testTypeList": [
            {
              "testType": null,
              "categoryType": null,
              "testStatusList": [
                {
                  "testStatus": "pass",
                  "count": 774
                }
              ],
              "failedRate": 0.00,
              "childList": [

              ]
            }
          ]
        },
        {
          "timestamp": 1583278200000,
          "testTypeList": [
            {
              "testType": null,
              "categoryType": null,
              "testStatusList": [
                {
                  "testStatus": "pass",
                  "count": 810
                }
              ],
              "failedRate": 0.00,
              "childList": [

              ]
            }
          ]
        },
        {
          "timestamp": 1583280000000,
          "testTypeList": [
            {
              "testType": null,
              "categoryType": null,
              "testStatusList": [
                {
                  "testStatus": "pass",
                  "count": 789
                }
              ],
              "failedRate": 0.00,
              "childList": [

              ]
            }
          ]
        },
        {
          "timestamp": 1583281800000,
          "testTypeList": [
            {
              "testType": null,
              "categoryType": null,
              "testStatusList": [
                {
                  "testStatus": "pass",
                  "count": 792
                },
                {
                  "testStatus": "fail",
                  "count": 13
                }
              ],
              "failedRate": 1.61,
              "childList": [

              ]
            }
          ]
        },
        {
          "timestamp": 1583283600000,
          "testTypeList": [
            {
              "testType": null,
              "categoryType": null,
              "testStatusList": [
                {
                  "testStatus": "pass",
                  "count": 738
                },
                {
                  "testStatus": "fail",
                  "count": 29
                }
              ],
              "failedRate": 3.78,
              "childList": [

              ]
            }
          ]
        },
        {
          "timestamp": 1583285400000,
          "testTypeList": [
            {
              "testType": null,
              "categoryType": null,
              "testStatusList": [
                {
                  "testStatus": "pass",
                  "count": 954
                }
              ],
              "failedRate": 0.00,
              "childList": [

              ]
            }
          ]
        },
        {
          "timestamp": 1583287200000,
          "testTypeList": [
            {
              "testType": null,
              "categoryType": null,
              "testStatusList": [
                {
                  "testStatus": "pass",
                  "count": 255
                },
                {
                  "testStatus": "fail",
                  "count": 57
                }
              ],
              "targetServerList": [

              ],
              "failedRate": 18.27,
              "discoverRate": null,
              "childList": [

              ]
            }
          ]
        },
        {
          "timestamp": 1583289000000,
          "testTypeList": [
            {
              "testType": null,
              "categoryType": null,
              "testStatusList": [
                {
                  "testStatus": "pass",
                  "count": 804
                }
              ],
              "failedRate": 0.00,
              "childList": [

              ]
            }
          ]
        },
        {
          "timestamp": 1583290800000,
          "testTypeList": [
            {
              "testType": null,
              "categoryType": null,
              "testStatusList": [
                {
                  "testStatus": "pass",
                  "count": 813
                }
              ],
              "failedRate": 0.00,
              "childList": [

              ]
            }
          ]
        },
        {
          "timestamp": 1583292600000,
          "testTypeList": [
            {
              "testType": null,
              "categoryType": null,
              "testStatusList": [
                {
                  "testStatus": "pass",
                  "count": 807
                }
              ],
              "failedRate": 0.00,
              "childList": [

              ]
            }
          ]
        },
        {
          "timestamp": 1583294400000,
          "testTypeList": [
            {
              "testType": null,
              "categoryType": null,
              "testStatusList": [
                {
                  "testStatus": "pass",
                  "count": 846
                }
              ],
              "failedRate": 0.00,
              "childList": [

              ]
            }
          ]
        },
        {
          "timestamp": 1583296200000,
          "testTypeList": [
            {
              "testType": null,
              "categoryType": null,
              "testStatusList": [
                {
                  "testStatus": "pass",
                  "count": 936
                }
              ],
              "failedRate": 0.00,
              "childList": [

              ]
            }
          ]
        },
        {
          "timestamp": 1583298000000,
          "testTypeList": [
            {
              "testType": null,
              "categoryType": null,
              "testStatusList": [
                {
                  "testStatus": "pass",
                  "count": 256
                },
                {
                  "testStatus": "fail",
                  "count": 56
                }
              ],
              "targetServerList": [

              ],
              "failedRate": 17.95,
              "discoverRate": null,
              "childList": [

              ]
            }
          ]
        },
        {
          "timestamp": 1583299800000,
          "testTypeList": [
            {
              "testType": null,
              "categoryType": null,
              "testStatusList": [
                {
                  "testStatus": "pass",
                  "count": 255
                },
                {
                  "testStatus": "fail",
                  "count": 57
                }
              ],
              "targetServerList": [

              ],
              "failedRate": 18.27,
              "discoverRate": null,
              "childList": [

              ]
            }
          ]
        },
        {
          "timestamp": 1583301600000,
          "testTypeList": [
            {
              "testType": null,
              "categoryType": null,
              "testStatusList": [
                {
                  "testStatus": "pass",
                  "count": 256
                },
                {
                  "testStatus": "fail",
                  "count": 56
                }
              ],
              "targetServerList": [

              ],
              "failedRate": 17.95,
              "discoverRate": null,
              "childList": [

              ]
            }
          ]
        },
        {
          "timestamp": 1583303400000,
          "testTypeList": [
            {
              "testType": null,
              "categoryType": null,
              "testStatusList": [
                {
                  "testStatus": "pass",
                  "count": 270
                }
              ],
              "failedRate": 0.00,
              "childList": [

              ]
            }
          ]
        },
        {
          "timestamp": 1583305200000,
          "testTypeList": [

          ]
        }
      ]
    }
  ]
},
"page": {
  "pageSize": 10,
  "offset": 1,
  "totalCount": 13
}
},
"Sites_Template":
{
  "version": "1.0",
  "response": {
    "dataList": [
      {
        "breakdown": "overallSummary",
        "breakdownValue": null,
        "overallAgg": [
          {
            "testType": "ONBOARDING",
            "categoryType": "category",
            "testStatusList": [
              {
                "testStatus": "pass",
                "count": 6207
              },
              {
                "testStatus": "fail",
                "count": 4
              }
            ],
            "failedRate": 0.1,
            "childList": [
              {
                "testType": "ASSOC",
                "categoryType": "subCategory",
                "testStatusList": [
                  {
                    "testStatus": "pass",
                    "count": 6203
                  },
                  {
                     "testStatus": "fail",
                     "count": 4
                  }

                ],
                "failedRate": 0.1,
                "childList": []
              },
              {
                "testType": "AUTH",
                "categoryType": "subCategory",
                "testStatusList": [
                  {
                    "testStatus": "pass",
                    "count": 6203
                  }
                ],
                "failedRate": 0.1,
                "childList": []
              },
              {
                "testType": "DHCP",
                "categoryType": "subCategory",
                "testStatusList": [
                  {
                    "testStatus": "pass",
                    "count": 4785
                  }
                ],
                "failedRate": 0.1,
                "childList": []
              },
              {
                "testType": "WEBAUTH",
                "categoryType": "subCategory",
                "testStatusList": [
                    {
                    "testStatus": "pass",
                    "count": 4795
                  }
                ],
                "failedRate": 0.2,
                "childList": []
              }
            ]
          },
          {
            "testType": "RF_ASSESSMENT",
            "categoryType": "category",
            "testStatusList": [
              {
                "testStatus": "pass",
                "count": 45312
              },
              {
                "testStatus": "fail",
                "count": 23
              }
            ],
            "failedRate": 2.1,
            "childList": [
              {
                "testType": "DATA_RATE",
                "categoryType": "subCategory",
                "testStatusList": [
                  {
                    "testStatus": "pass",
                    "count": 45312
                  },
                  {
                    "testStatus": "fail",
                    "count": 0
                  }
                ],
                "failedRate": 0.9,
                "childList": []
              },
              {
                "testType": "SNR",
                "categoryType": "subCategory",
                "testStatusList": [
                  {
                    "testStatus": "pass",
                    "count": 47312
                  }
                ],
                "failedRate": 0,
                "childList": []
              }
            ]
          },
          {
            "testType": "NETWORK_SERVICES",
            "categoryType": "category",
            "testStatusList": [
              {
                "testStatus": "pass",
                "count": 4784
              },
              {
                "testStatus": "fail",
                "count": 500
              }
            ],
            "failedRate": 27,
            "childList": [
              {
                "testType": "DNS",
                "categoryType": "subCategory",
                "testStatusList": [
                  {
                    "testStatus": "pass",
                    "count": 4784
                  },
                  {
                "testStatus": "fail",
                "count": 300
              }
                ],
                "failedRate": 27,
                "childList": []
              },
              {
                "testType": "RADIUS",
                "categoryType": "subCategory",
                "testStatusList": [
                    {
                    "testStatus": "pass",
                    "count": 4784
                  },
                  {
                "testStatus": "fail",
                "count": 200
              }
                ],
                "failedRate": 0,
                "childList": []
              }
            ]
          },
          {
            "testType": "PERFORMANCE",
            "categoryType": "category",
            "testStatusList": [
              {
                "testStatus": "pass",
                "count": 4782
              },
              {
                "testStatus": "fail",
                "count": 105
              }
            ],
            "failedRate": 0,
            "childList": [
              {
                "testType": "SPEED",
                "categoryType": "subCategory",
                "testStatusList": [
                  {
                    "testStatus": "pass",
                    "count": 2290
                  },
                  {
                    "testStatus": "fail",
                    "count": 104
                  }
                ],
                "failedRate": 0,
                "childList": []
              },
              {
                "testType": "IPSLASENDER",
                "categoryType": "subCategory",
                "testStatusList": [
                  {
                    "testStatus": "pass",
                    "count": 4782
                  },
                  {
                    "testStatus": "fail",
                    "count": 1
                  }
                ],
                "failedRate": 0,
                "childList": []
              }
            ]
          },
          {
            "testType": "APP_CONNECTIVITY",
            "categoryType": "category",
            "testStatusList": [
              {
                "testStatus": "pass",
                "count": 4783
              },
              {
                "testStatus": "fail",
                "count": 2
              }
            ],
            "failedRate": 0.84,
            "childList": [
              {
                "testType": "HOST_REACHABILITY",
                "categoryType": "subCategory",
                "testStatusList": [
                  {
                    "testStatus": "pass",
                    "count": 4783
                  },
                  {
                    "testStatus": "fail",
                    "count": 79
                  }
                ],
                "failedRate": 0,
                "childList": []
              },
              {
                "testType": "WEBSERVER",
                "categoryType": "subCategory",
                "testStatusList": [
                  {
                    "testStatus": "pass",
                    "count": 4783
                  },
                  {
                    "testStatus": "fail",
                    "count": 2
                  }
                ],
                "failedRate": 0,
                "childList": []
              },
              {
                "testType": "FILETRANSFER",
                "categoryType": "subCategory",
                "testStatusList": [
                  {
                    "testStatus": "pass",
                    "count": 2319
                  },
                  {
                    "testStatus": "fail",
                    "count": 75
                  }
                ],
                "failedRate": 4.08,
                "childList": []
              }
            ]
          },
          {
            "testType": "EMAIL",
            "categoryType": "category",
            "testStatusList": [
              {
                "testStatus": "pass",
                "count": 2395
              },
              {
                "testStatus": "fail",
                "count": 1300
              }
            ],
            "failedRate": 50,
            "childList": [
              {
                "testType": "MAILSERVER",
                "categoryType": "subCategory",
                "testStatusList": [
                  {
                    "testStatus": "pass",
                    "count": 2395
                  },
                  {
                    "testStatus": "fail",
                    "count": 0
                  }
                ],
                "failedRate": 0,
                "childList": []
              },
              {
                "testType": "OUTLOOKSERVER",
                "categoryType": "subCategory",
                "testStatusList": [
                  {
                    "testStatus": "pass",
                    "count": 2395
                  },
                  {
                    "testStatus": "fail",
                    "count": 1400
                  }

                ],
                "failedRate": 50,
                "childList": []
              }
            ]
          }
        ],
        "dataList": [
            {
            "timestamp": 1583218800000,
            "testTypeList": [
              {
                "testType": null,
                "categoryType": null,
                "testStatusList": [
                  {
                    "testStatus": "pass",
                    "count": 6041
                  },
                  {
                    "testStatus": "fail",
                    "count": 208
                  }
                ],
                "failedRate": 3.33,
                "childList": [

                ]
              }
            ]
          },
          {
            "timestamp": 1583220600000,
            "testTypeList": [
              {
                "testType": null,
                "categoryType": null,
                "testStatusList": [
                  {
                    "testStatus": "pass",
                    "count": 6248
                  },
                  {
                    "testStatus": "fail",
                    "count": 208
                  }
                ],
                "failedRate": 3.22,
                "childList": [

                ]
              }
            ]
          },
          {
            "timestamp": 1583222400000,
            "testTypeList": [
              {
                "testType": null,
                "categoryType": null,
                "testStatusList": [
                  {
                    "testStatus": "pass",
                    "count": 6464
                  },
                  {
                    "testStatus": "fail",
                    "count": 179
                  }
                ],
                "failedRate": 2.69,
                "childList": [

                ]
              }
            ]
          },
          {
            "timestamp": 1583224200000,
            "testTypeList": [
              {
                "testType": null,
                "categoryType": null,
                "testStatusList": [
                  {
                    "testStatus": "pass",
                    "count": 256
                  },
                  {
                    "testStatus": "fail",
                    "count": 156
                  }
                ],
                "failedRate": 37.95,
                "childList": [

                ]
              }
            ]
          },
          {
            "timestamp": 1583226000000,
            "testTypeList": [
              {
                "testType": null,
                "categoryType": null,
                "testStatusList": [
                  {
                    "testStatus": "pass",
                    "count": 6687
                  },
                  {
                    "testStatus": "fail",
                    "count": 157
                  }
                ],
                "failedRate": 2.29,
                "childList": [

                ]
              }
            ]
          },
          {
            "timestamp": 1583227800000,
            "testTypeList": [
              {
                "testType": null,
                "categoryType": null,
                "testStatusList": [
                  {
                    "testStatus": "pass",
                    "count": 6480
                  },
                  {
                    "testStatus": "fail",
                    "count": 157
                  }
                ],
                "failedRate": 2.37,
                "childList": [

                ]
              }
            ]
          },
          {
            "timestamp": 1583229600000,
            "testTypeList": [
              {
                "testType": null,
                "categoryType": null,
                "testStatusList": [
                  {
                    "testStatus": "pass",
                    "count": 256
                  },
                  {
                    "testStatus": "fail",
                    "count": 156
                  }
                ],
                "failedRate": 37.95,
                "childList": [

                ]
              }
            ]
          },
          {
            "timestamp": 1583231400000,
            "testTypeList": [
              {
                "testType": null,
                "categoryType": null,
                "testStatusList": [
                  {
                    "testStatus": "pass",
                    "count": 6680
                  },
                  {
                    "testStatus": "fail",
                    "count": 174
                  }
                ],
                "failedRate": 2.54,
                "childList": [

                ]
              }
            ]
          },
          {
            "timestamp": 1583233200000,
            "testTypeList": [
              {
                "testType": null,
                "categoryType": null,
                "testStatusList": [
                  {
                    "testStatus": "pass",
                    "count": 6340
                  },
                  {
                    "testStatus": "fail",
                    "count": 173
                  }
                ],
                "failedRate": 2.66,
                "childList": [

                ]
              }
            ]
          },
          {
            "timestamp": 1583235000000,
            "testTypeList": [
              {
                "testType": null,
                "categoryType": null,
                "testStatusList": [
                  {
                    "testStatus": "pass",
                    "count": 6586
                  },
                  {
                    "testStatus": "fail",
                    "count": 187
                  }
                ],
                "failedRate": 2.76,
                "childList": [

                ]
              }
            ]
          },
          {
            "timestamp": 1583236800000,
            "testTypeList": [
              {
                "testType": null,
                "categoryType": null,
                "testStatusList": [
                  {
                    "testStatus": "pass",
                    "count": 6678
                  },
                  {
                    "testStatus": "fail",
                    "count": 175
                  }
                ],
                "failedRate": 2.55,
                "childList": [

                ]
              }
            ]
          },
          {
            "timestamp": 1583238600000,
            "testTypeList": [
              {
                "testType": null,
                "categoryType": null,
                "testStatusList": [
                  {
                    "testStatus": "pass",
                    "count": 256
                  },
                  {
                    "testStatus": "fail",
                    "count": 156
                  }
                ],
                "failedRate": 37.95,
                "childList": [

                ]
              }
            ]
          },
          {
            "timestamp": 1583240400000,
            "testTypeList": [
              {
                "testType": null,
                "categoryType": null,
                "testStatusList": [
                  {
                    "testStatus": "pass",
                    "count": 6921
                  },
                  {
                    "testStatus": "fail",
                    "count": 226
                  }
                ],
                "failedRate": 3.16,
                "childList": [

                ]
              }
            ]
          },
          {
            "timestamp": 1583242200000,
            "testTypeList": [
              {
                "testType": null,
                "categoryType": null,
                "testStatusList": [
                  {
                    "testStatus": "pass",
                    "count": 7054
                  },
                  {
                    "testStatus": "fail",
                    "count": 156
                  }
                ],
                "failedRate": 2.16,
                "childList": [

                ]
              }
            ]
          },
          {
            "timestamp": 1583244000000,
            "testTypeList": [
              {
                "testType": null,
                "categoryType": null,
                "testStatusList": [
                  {
                    "testStatus": "pass",
                    "count": 7282
                  },
                  {
                    "testStatus": "fail",
                    "count": 231
                  }
                ],
                "failedRate": 3.07,
                "childList": [

                ]
              }
            ]
          },
          {
            "timestamp": 1583245800000,
            "testTypeList": [
              {
                "testType": null,
                "categoryType": null,
                "testStatusList": [
                  {
                    "testStatus": "pass",
                    "count": 256
                  },
                  {
                    "testStatus": "fail",
                    "count": 56
                  }
                ],
                "failedRate": 17.95,
                "childList": [

                ]
            }
            ]
          },
          {
            "timestamp": 1583247600000,
            "testTypeList": [
              {
                "testType": null,
                "categoryType": null,
                "testStatusList": [
                  {
                    "testStatus": "pass",
                    "count": 6930
                  },
                  {
                    "testStatus": "fail",
                    "count": 212
                  }
                ],
                "failedRate": 2.97,
                "childList": [

                ]
              }
            ]
          },
          {
            "timestamp": 1583249400000,
            "testTypeList": [
              {
                "testType": null,
                "categoryType": null,
                "testStatusList": [
                  {
                    "testStatus": "pass",
                    "count": 6344
                  },
                  {
                    "testStatus": "fail",
                    "count": 163
                  }
                ],
                "failedRate": 2.50,
                "childList": [

                ]
              }
            ]
          },
          {
            "timestamp": 1583251200000,
            "testTypeList": [
              {
                "testType": null,
                "categoryType": null,
                "testStatusList": [
                  {
                    "testStatus": "pass",
                    "count": 256
                  },
                  {
                    "testStatus": "fail",
                    "count": 56
                  }
                ],
                "failedRate": 17.95,
                "childList": [

                ]
            }
            ]
          },
          {
            "timestamp": 1583253000000,
            "testTypeList": [
              {
                "testType": null,
                "categoryType": null,
                "testStatusList": [
                  {
                    "testStatus": "pass",
                    "count": 5941
                  },
                  {
                    "testStatus": "fail",
                    "count": 154
                  }
                ],
                "failedRate": 2.53,
                "childList": [

                ]
              }
            ]
          },
          {
            "timestamp": 1583254800000,
            "testTypeList": [
              {
                "testType": null,
                "categoryType": null,
                "testStatusList": [
                  {
                    "testStatus": "pass",
                    "count": 256
                  },
                  {
                    "testStatus": "fail",
                    "count": 156
                  }
                ],
                "failedRate": 37.95,
                "childList": [

                ]
            }
            ]
          },
          {
            "timestamp": 1583256600000,
            "testTypeList": [
              {
                "testType": null,
                "categoryType": null,
                "testStatusList": [
                  {
                    "testStatus": "pass",
                    "count": 6449
                  },
                  {
                    "testStatus": "fail",
                    "count": 165
                  }
                ],
                "failedRate": 2.49,
                "childList": [

                ]
              }
            ]
          },
          {
            "timestamp": 1583258400000,
            "testTypeList": [
              {
                "testType": null,
                "categoryType": null,
                "testStatusList": [
                  {
                    "testStatus": "pass",
                    "count": 256
                  },
                  {
                    "testStatus": "fail",
                    "count": 156
                  }
                ],
                "failedRate": 37.95,
                "childList": [

                ]
            }
            ]
          },
          {
            "timestamp": 1583260200000,
            "testTypeList": [
              {
                "testType": null,
                "categoryType": null,
                "testStatusList": [
                  {
                    "testStatus": "pass",
                    "count": 6545
                  },
                  {
                    "testStatus": "fail",
                    "count": 155
                  }
                ],
                "failedRate": 2.31,
                "childList": [

                ]
              }
            ]
          },
          {
            "timestamp": 1583262000000,
            "testTypeList": [
              {
                "testType": null,
                "categoryType": null,
                "testStatusList": [
                  {
                    "testStatus": "pass",
                    "count": 6764
                  },
                  {
                    "testStatus": "fail",
                    "count": 217
                  }
                ],
                "failedRate": 3.11,
                "childList": [

                ]
              }
            ]
          },
          {
            "timestamp": 1583263800000,
            "testTypeList": [
              {
                "testType": null,
                "categoryType": null,
                "testStatusList": [
                  {
                    "testStatus": "pass",
                    "count": 6011
                  },
                  {
                    "testStatus": "fail",
                    "count": 223
                  }
                ],
                "failedRate": 3.58,
                "childList": [

                ]
              }
            ]
          },
          {
            "timestamp": 1583265600000,
            "testTypeList": [
              {
                "testType": null,
                "categoryType": null,
                "testStatusList": [
                  {
                    "testStatus": "pass",
                    "count": 5681
                  },
                  {
                    "testStatus": "fail",
                    "count": 207
                  }
                ],
                "failedRate": 3.52,
                "childList": [

                ]
              }
            ]
          },
          {
            "timestamp": 1583267400000,
            "testTypeList": [
              {
                "testType": null,
                "categoryType": null,
                "testStatusList": [
                  {
                    "testStatus": "pass",
                    "count": 6087
                  },
                  {
                    "testStatus": "fail",
                    "count": 194
                  }
                ],
                "failedRate": 3.09,
                "childList": [

                ]
              }
            ]
          },
          {
            "timestamp": 1583269200000,
            "testTypeList": [
              {
                "testType": null,
                "categoryType": null,
                "testStatusList": [
                  {
                    "testStatus": "pass",
                    "count": 6576
                  },
                  {
                    "testStatus": "fail",
                    "count": 180
                  }
                ],
                "failedRate": 2.66,
                "childList": [

                ]
              }
            ]
          },
          {
            "timestamp": 1583271000000,
            "testTypeList": [
              {
                "testType": null,
                "categoryType": null,
                "testStatusList": [
                  {
                    "testStatus": "pass",
                    "count": 256
                  },
                  {
                    "testStatus": "fail",
                    "count": 156
                  }
                ],
                "failedRate": 37.95,
                "childList": [

                ]
            }
            ]
          },
          {
            "timestamp": 1583272800000,
            "testTypeList": [
              {
                "testType": null,
                "categoryType": null,
                "testStatusList": [
                  {
                    "testStatus": "pass",
                    "count": 6309
                  },
                  {
                    "testStatus": "fail",
                    "count": 146
                  }
                ],
                "failedRate": 2.26,
                "childList": [

                ]
              }
            ]
          },
          {
            "timestamp": 1583274600000,
            "testTypeList": [
              {
                "testType": null,
                "categoryType": null,
                "testStatusList": [
                  {
                    "testStatus": "pass",
                    "count": 5930
                  },
                  {
                    "testStatus": "fail",
                    "count": 130
                  }
                ],
                "failedRate": 2.15,
                "childList": [

                ]
              }
            ]
          },
          {
            "timestamp": 1583276400000,
            "testTypeList": [
              {
                "testType": null,
                "categoryType": null,
                "testStatusList": [
                  {
                    "testStatus": "pass",
                    "count": 5893
                  },
                  {
                    "testStatus": "fail",
                    "count": 149
                  }
                ],
                "failedRate": 2.47,
                "childList": [

                ]
              }
            ]
          },
          {
            "timestamp": 1583278200000,
            "testTypeList": [
              {
                "testType": null,
                "categoryType": null,
                "testStatusList": [
                  {
                    "testStatus": "pass",
                    "count": 256
                  },
                  {
                    "testStatus": "fail",
                    "count": 156
                  }
                ],
                "failedRate": 37.95,
                "childList": [

                ]
            }
            ]
          },
          {
            "timestamp": 1583280000000,
            "testTypeList": [
              {
                "testType": null,
                "categoryType": null,
                "testStatusList": [
                  {
                    "testStatus": "pass",
                    "count": 5777
                  },
                  {
                    "testStatus": "fail",
                    "count": 191
                  }
                ],
                "failedRate": 3.20,
                "childList": [

                ]
              }
            ]
          },
          {
            "timestamp": 1583281800000,
            "testTypeList": [
              {
                "testType": null,
                "categoryType": null,
                "testStatusList": [
                  {
                    "testStatus": "pass",
                    "count": 6119
                  },
                  {
                    "testStatus": "fail",
                    "count": 189
                  }
                ],
                "failedRate": 3.00,
                "childList": [

                ]
              }
            ]
          },
          {
            "timestamp": 1583283600000,
            "testTypeList": [
              {
                "testType": null,
                "categoryType": null,
                "testStatusList": [
                  {
                    "testStatus": "pass",
                    "count": 6054
                  },
                  {
                    "testStatus": "fail",
                    "count": 267
                  }
                ],
                "failedRate": 4.22,
                "childList": [

                ]
              }
            ]
          },
          {
            "timestamp": 1583285400000,
            "testTypeList": [
              {
                "testType": null,
                "categoryType": null,
                "testStatusList": [
                  {
                    "testStatus": "pass",
                    "count": 7271
                  },
                  {
                    "testStatus": "fail",
                    "count": 158
                  }
                ],
                "failedRate": 2.13,
                "childList": [

                ]
              }
            ]
          },
          {
            "timestamp": 1583287200000,
            "testTypeList": [
              {
                "testType": null,
                "categoryType": null,
                "testStatusList": [
                  {
                    "testStatus": "pass",
                    "count": 6458
                  },
                  {
                    "testStatus": "fail",
                    "count": 172
                  }
                ],
                "failedRate": 2.59,
                "childList": [

                ]
              }
            ]
          },
          {
            "timestamp": 1583289000000,
            "testTypeList": [
              {
                "testType": null,
                "categoryType": null,
                "testStatusList": [
                  {
                    "testStatus": "pass",
                    "count": 6287
                  },
                  {
                    "testStatus": "fail",
                    "count": 174
                  }
                ],
                "failedRate": 2.69,
                "childList": [

                ]
              }
            ]
          },
          {
            "timestamp": 1583290800000,
            "testTypeList": [
              {
                "testType": null,
                "categoryType": null,
                "testStatusList": [
                  {
                    "testStatus": "pass",
                    "count": 6295
                  },
                  {
                    "testStatus": "fail",
                    "count": 165
                  }
                ],
                "failedRate": 2.55,
                "childList": [

                ]
              }
            ]
          },
          {
            "timestamp": 1583292600000,
            "testTypeList": [
              {
                "testType": null,
                "categoryType": null,
                "testStatusList": [
                  {
                    "testStatus": "pass",
                    "count": 256
                  },
                  {
                    "testStatus": "fail",
                    "count": 56
                  }
                ],
                "failedRate": 17.95,
                "childList": [

                ]
            }
            ]
          },
          {
            "timestamp": 1583294400000,
            "testTypeList": [
              {
                "testType": null,
                "categoryType": null,
                "testStatusList": [
                  {
                    "testStatus": "pass",
                    "count": 6798
                  },
                  {
                    "testStatus": "fail",
                    "count": 176
                  }
                ],
                "failedRate": 2.52,
                "childList": [

                ]
              }
            ]
          },
          {
            "timestamp": 1583296200000,
            "testTypeList": [
              {
                "testType": null,
                "categoryType": null,
                "testStatusList": [
                  {
                    "testStatus": "pass",
                    "count": 7213
                  },
                  {
                    "testStatus": "fail",
                    "count": 158
                  }
                ],
                "failedRate": 2.14,
                "childList": [

                ]
              }
            ]
          },
          {
            "timestamp": 1583298000000,
            "testTypeList": [
              {
                "testType": null,
                "categoryType": null,
                "testStatusList": [
                  {
                    "testStatus": "pass",
                    "count": 256
                  },
                  {
                    "testStatus": "fail",
                    "count": 56
                  }
                ],
                "failedRate": 17.95,
                "childList": [

                ]
            }
            ]
          },
          {
            "timestamp": 1583299800000,
            "testTypeList": [
              {
                "testType": null,
                "categoryType": null,
                "testStatusList": [
                  {
                    "testStatus": "pass",
                    "count": 6242
                  },
                  {
                    "testStatus": "fail",
                    "count": 194
                  }
                ],
                "failedRate": 3.01,
                "childList": [

                ]
              }
            ]
          },
          {
            "timestamp": 1583301600000,
            "testTypeList": [
              {
                "testType": null,
                "categoryType": null,
                "testStatusList": [
                  {
                    "testStatus": "pass",
                    "count": 6395
                  },
                  {
                    "testStatus": "fail",
                    "count": 189
                  }
                ],
                "failedRate": 2.87,
                "childList": [

                ]
              }
            ]
          },
          {
            "timestamp": 1583303400000,
            "testTypeList": [
              {
                "testType": null,
                "categoryType": null,
                "testStatusList": [
                  {
                    "testStatus": "pass",
                    "count": 7058
                  },
                  {
                    "testStatus": "fail",
                    "count": 202
                  }
                ],
                "failedRate": 2.78,
                "childList": [

                ]
              }
            ]
          },
          {
            "timestamp": 1583305200000,
            "testTypeList": [

            ]
          }
        ]
      },
      {
        "breakdown": "Global/North America/USA/California/San Jose",
        "breakdownValue": "c365365d-6af9-4989-a2c3-a04174a12e73",
        "overallAgg": [
          {
            "testType": "overall",
            "categoryType": "category",
            "testStatusList": [],
            "failedRate": 10.83,
            "childList": []
          },
          {
            "testType": "ONBOARDING",
            "categoryType": "category",
            "testStatusList": [
              {
                "testStatus": "pass",
                "count": 285
              }
            ],
            "failedRate": 0,
            "childList": [
              {
                "testType": "ASSOC",
                "categoryType": "subCategory",
                "testStatusList": [
                  {
                    "testStatus": "pass",
                    "count": 95
                  }
                ],
                "failedRate": 0,
                "childList": []
              },
              {
                "testType": "AUTH",
                "categoryType": "subCategory",
                "testStatusList": [
                  {
                    "testStatus": "pass",
                    "count": 95
                  }
                ],
                "failedRate": 0,
                "childList": []
              },
              {
                "testType": "DHCP",
                "categoryType": "subCategory",
                "testStatusList": [
                  {
                    "testStatus": "pass",
                    "count": 95
                  }
                ],
                "failedRate": 0,
                "childList": []
              },
              {
                "testType": "WEBAUTH",
                "categoryType": "subCategory",
                "testStatusList": [
                    {
                    "testStatus": "pass",
                    "count": 95
                  }
                ],
                "failedRate": 0,
                "childList": []
              }
            ]
          },
          {
            "testType": "RF_ASSESSMENT",
            "categoryType": "category",
            "testStatusList": [
              {
                "testStatus": "pass",
                "count": 1037
              },
              {
                "testStatus": "fail",
                "count": 10
              }
            ],
            "failedRate": 0.98,
            "childList": [
              {
                "testType": "DATA_RATE",
                "categoryType": "subCategory",
                "testStatusList": [
                  {
                    "testStatus": "pass",
                    "count": 461
                  },
                  {
                    "testStatus": "fail",
                    "count": 15
                  }
                ],
                "failedRate": 1.0,
                "childList": []
              },
              {
                "testType": "SNR",
                "categoryType": "subCategory",
                "testStatusList": [
                  {
                    "testStatus": "pass",
                    "count": 576
                  }
                ],
                "failedRate": 0,
                "childList": []
              }
            ]
          },
          {
            "testType": "NETWORK_SERVICES",
            "categoryType": "category",
            "testStatusList": [
              {
                "testStatus": "pass",
                "count": 95
              },
              {
                "testStatus": "fail",
                "count": 80
              }
            ],
            "failedRate": 27,
            "childList": [
              {
                "testType": "DNS",
                "categoryType": "subCategory",
                "testStatusList": [
                  {
                    "testStatus": "pass",
                    "count": 95
                  },
                  {
                "testStatus": "fail",
                "count": 90
              }
                ],
                "failedRate": 27,
                "childList": []
              },
              {
                "testType": "RADIUS",
                "categoryType": "subCategory",
                "testStatusList": [
                    {
                    "testStatus": "pass",
                    "count": 95
                  }
                ],
                "failedRate": 0,
                "childList": []
              }
            ]
          },
          {
            "testType": "PERFORMANCE",
            "categoryType": "category",
            "testStatusList": [
              {
                "testStatus": "pass",
                "count": 141
              },
              {
                "testStatus": "fail",
                "count": 0
              }
            ],
            "failedRate": 0,
            "childList": [
              {
                "testType": "SPEED",
                "categoryType": "subCategory",
                "testStatusList": [
                  {
                    "testStatus": "pass",
                    "count": 46
                  },
                  {
                    "testStatus": "fail",
                    "count": 0
                  }
                ],
                "failedRate": 0,
                "childList": []
              },
              {
                "testType": "IPSLASENDER",
                "categoryType": "subCategory",
                "testStatusList": [
                  {
                    "testStatus": "pass",
                    "count": 95
                  }
                ],
                "failedRate": 0,
                "childList": []
              }
            ]
          },
          {
            "testType": "APP_CONNECTIVITY",
            "categoryType": "category",
            "testStatusList": [
              {
                "testStatus": "pass",
                "count": 237
              },
              {
                "testStatus": "fail",
                "count": 2
              }
            ],
            "failedRate": 0.84,
            "childList": [
              {
                "testType": "HOST_REACHABILITY",
                "categoryType": "subCategory",
                "testStatusList": [
                  {
                    "testStatus": "pass",
                    "count": 95
                  }
                ],
                "failedRate": 0,
                "childList": []
              },
              {
                "testType": "WEBSERVER",
                "categoryType": "subCategory",
                "testStatusList": [
                  {
                    "testStatus": "pass",
                    "count": 95
                  }
                ],
                "failedRate": 0,
                "childList": []
              },
              {
                "testType": "FILETRANSFER",
                "categoryType": "subCategory",
                "testStatusList": [
                  {
                    "testStatus": "pass",
                    "count": 47
                  },
                  {
                    "testStatus": "fail",
                    "count": 2
                  }
                ],
                "failedRate": 4.08,
                "childList": []
              }
            ]
          },
          {
            "testType": "EMAIL",
            "categoryType": "category",
            "testStatusList": [
              {
                "testStatus": "pass",
                "count": 150
              },
              {
                "testStatus": "fail",
                "count": 50
              }
            ],
            "failedRate": 50,
            "childList": [
              {
                "testType": "MAILSERVER",
                "categoryType": "subCategory",
                "testStatusList": [
                  {
                    "testStatus": "pass",
                    "count": 50
                  }
                ],
                "failedRate": 0,
                "childList": []
              },
              {
                "testType": "OUTLOOKSERVER",
                "categoryType": "subCategory",
                "testStatusList": [
                  {
                    "testStatus": "pass",
                    "count": 50
                  },
                  {
                    "testStatus": "fail",
                    "count": 50
                  }
                ],
                "failedRate": 50,
                "childList": []
              }
            ]
          }
        ],
        "dataList": [
            {
            "timestamp": 1583218800000,
            "testTypeList": [
              {
                "testType": null,
                "categoryType": null,
                "testStatusList": [
                  {
                    "testStatus": "pass",
                    "count": 4334
                  },
                  {
                    "testStatus": "fail",
                    "count": 18
                  }
                ],
                "failedRate": 0.41,
                "childList": [

                ]
              }
            ]
          },
          {
            "timestamp": 1583220600000,
            "testTypeList": [
              {
                "testType": null,
                "categoryType": null,
                "testStatusList": [
                  {
                    "testStatus": "pass",
                    "count": 256
                  },
                  {
                    "testStatus": "fail",
                    "count": 56
                  }
                ],
                "failedRate": 17.95,
                "childList": [

                ]
            }
            ]
          },
          {
            "timestamp": 1583222400000,
            "testTypeList": [
              {
                "testType": null,
                "categoryType": null,
                "testStatusList": [
                  {
                    "testStatus": "pass",
                    "count": 4554
                  }
                ],
                "failedRate": 0.00,
                "childList": [

                ]
              }
            ]
          },
          {
            "timestamp": 1583224200000,
            "testTypeList": [
              {
                "testType": null,
                "categoryType": null,
                "testStatusList": [
                  {
                    "testStatus": "pass",
                    "count": 256
                  },
                  {
                    "testStatus": "fail",
                    "count": 56
                  }
                ],
                "failedRate": 17.95,
                "childList": [

                ]
              }
            ]
          },
          {
            "timestamp": 1583226000000,
            "testTypeList": [
              {
                "testType": null,
                "categoryType": null,
                "testStatusList": [
                  {
                    "testStatus": "pass",
                    "count": 4790
                  },
                  {
                    "testStatus": "fail",
                    "count": 1
                  }
                ],
                "failedRate": 0.02,
                "childList": [

                ]
              }
            ]
          },
          {
            "timestamp": 1583227800000,
            "testTypeList": [
              {
                "testType": null,
                "categoryType": null,
                "testStatusList": [
                  {
                    "testStatus": "pass",
                    "count": 256
                  },
                  {
                    "testStatus": "fail",
                    "count": 156
                  }
                ],
                "failedRate": 37.95,
                "childList": [

                ]
              }
            ]
          },
          {
            "timestamp": 1583229600000,
            "testTypeList": [
              {
                "testType": null,
                "categoryType": null,
                "testStatusList": [
                  {
                    "testStatus": "pass",
                    "count": 4701
                  },
                  {
                    "testStatus": "fail",
                    "count": 42
                  }
                ],
                "failedRate": 0.89,
                "childList": [

                ]
              }
            ]
          },
          {
            "timestamp": 1583231400000,
            "testTypeList": [
              {
                "testType": null,
                "categoryType": null,
                "testStatusList": [
                  {
                    "testStatus": "pass",
                    "count": 256
                  },
                  {
                    "testStatus": "fail",
                    "count": 156
                  }
                ],
                "failedRate": 37.95,
                "childList": [

                ]
              }
            ]
          },
          {
            "timestamp": 1583233200000,
            "testTypeList": [
              {
                "testType": null,
                "categoryType": null,
                "testStatusList": [
                  {
                    "testStatus": "pass",
                    "count": 4541
                  },
                  {
                    "testStatus": "fail",
                    "count": 42
                  }
                ],
                "failedRate": 0.92,
                "childList": [

                ]
              }
            ]
          },
          {
            "timestamp": 1583235000000,
            "testTypeList": [
              {
                "testType": null,
                "categoryType": null,
                "testStatusList": [
                  {
                    "testStatus": "pass",
                    "count": 4842
                  },
                  {
                    "testStatus": "fail",
                    "count": 43
                  }
                ],
                "failedRate": 0.88,
                "childList": [

                ]
              }
            ]
          },
          {
            "timestamp": 1583236800000,
            "testTypeList": [
              {
                "testType": null,
                "categoryType": null,
                "testStatusList": [
                  {
                    "testStatus": "pass",
                    "count": 4881
                  }
                ],
                "failedRate": 0.00,
                "childList": [

                ]
              }
            ]
          },
          {
            "timestamp": 1583238600000,
            "testTypeList": [
              {
                "testType": null,
                "categoryType": null,
                "testStatusList": [
                  {
                    "testStatus": "pass",
                    "count": 4977
                  }
                ],
                "failedRate": 0.00,
                "childList": [

                ]
              }
            ]
          },
          {
            "timestamp": 1583240400000,
            "testTypeList": [
              {
                "testType": null,
                "categoryType": null,
                "testStatusList": [
                  {
                    "testStatus": "pass",
                    "count": 256
                  },
                  {
                    "testStatus": "fail",
                    "count": 156
                  }
                ],
                "failedRate": 37.95,
                "childList": [

                ]
              }
            ]
          },
          {
            "timestamp": 1583242200000,
            "testTypeList": [
              {
                "testType": null,
                "categoryType": null,
                "testStatusList": [
                  {
                    "testStatus": "pass",
                    "count": 4804
                  },
                  {
                    "testStatus": "fail",
                    "count": 3
                  }
                ],
                "failedRate": 0.06,
                "childList": [

                ]
              }
            ]
          },
          {
            "timestamp": 1583244000000,
            "testTypeList": [
              {
                "testType": null,
                "categoryType": null,
                "testStatusList": [
                  {
                    "testStatus": "pass",
                    "count": 256
                  },
                  {
                    "testStatus": "fail",
                    "count": 156
                  }
                ],
                "failedRate": 37.95,
                "childList": [

                ]
              }
            ]
          },
          {
            "timestamp": 1583245800000,
            "testTypeList": [
              {
                "testType": null,
                "categoryType": null,
                "testStatusList": [
                  {
                    "testStatus": "pass",
                    "count": 4968
                  }
                ],
                "failedRate": 0.00,
                "childList": [

                ]
              }
            ]
          },
          {
            "timestamp": 1583247600000,
            "testTypeList": [
              {
                "testType": null,
                "categoryType": null,
                "testStatusList": [
                  {
                    "testStatus": "pass",
                    "count": 4932
                  }
                ],
                "failedRate": 0.00,
                "childList": [

                ]
              }
            ]
          },
          {
            "timestamp": 1583249400000,
            "testTypeList": [
              {
                "testType": null,
                "categoryType": null,
                "testStatusList": [
                  {
                    "testStatus": "pass",
                    "count": 4752
                  }
                ],
                "failedRate": 0.00,
                "childList": [

                ]
              }
            ]
          },
          {
            "timestamp": 1583251200000,
            "testTypeList": [
              {
                "testType": null,
                "categoryType": null,
                "testStatusList": [
                  {
                    "testStatus": "pass",
                    "count": 4299
                  },
                  {
                    "testStatus": "fail",
                    "count": 26
                  }
                ],
                "failedRate": 0.60,
                "childList": [

                ]
              }
            ]
          },
          {
            "timestamp": 1583253000000,
            "testTypeList": [
              {
                "testType": null,
                "categoryType": null,
                "testStatusList": [
                  {
                    "testStatus": "pass",
                    "count": 4229
                  },
                  {
                    "testStatus": "fail",
                    "count": 7
                  }
                ],
                "failedRate": 0.17,
                "childList": [

                ]
              }
            ]
          },
          {
            "timestamp": 1583254800000,
            "testTypeList": [
              {
                "testType": null,
                "categoryType": null,
                "testStatusList": [
                  {
                    "testStatus": "pass",
                    "count": 256
                  },
                  {
                    "testStatus": "fail",
                    "count": 56
                  }
                ],
                "failedRate": 17.95,
                "childList": [

                ]
              }
            ]
          },
          {
            "timestamp": 1583256600000,
            "testTypeList": [
              {
                "testType": null,
                "categoryType": null,
                "testStatusList": [
                  {
                    "testStatus": "pass",
                    "count": 4842
                  }
                ],
                "failedRate": 0.00,
                "childList": [

                ]
              }
            ]
          },
          {
            "timestamp": 1583258400000,
            "testTypeList": [
              {
                "testType": null,
                "categoryType": null,
                "testStatusList": [
                  {
                    "testStatus": "pass",
                    "count": 4659
                  }
                ],
                "failedRate": 0.00,
                "childList": [

                ]
              }
            ]
          },
          {
            "timestamp": 1583260200000,
            "testTypeList": [
              {
                "testType": null,
                "categoryType": null,
                "testStatusList": [
                  {
                    "testStatus": "pass",
                    "count": 4602
                  },
                  {
                    "testStatus": "fail",
                    "count": 1
                  }
                ],
                "failedRate": 0.02,
                "childList": [

                ]
              }
            ]
          },
          {
            "timestamp": 1583262000000,
            "testTypeList": [
              {
                "testType": null,
                "categoryType": null,
                "testStatusList": [
                  {
                    "testStatus": "pass",
                    "count": 5270
                  },
                  {
                    "testStatus": "fail",
                    "count": 76
                  }
                ],
                "failedRate": 1.42,
                "childList": [

                ]
              }
            ]
          },
          {
            "timestamp": 1583263800000,
            "testTypeList": [
              {
                "testType": null,
                "categoryType": null,
                "testStatusList": [
                  {
                    "testStatus": "pass",
                    "count": 4509
                  },
                  {
                    "testStatus": "fail",
                    "count": 84
                  }
                ],
                "failedRate": 1.83,
                "childList": [

                ]
              }
            ]
          },
          {
            "timestamp": 1583265600000,
            "testTypeList": [
              {
                "testType": null,
                "categoryType": null,
                "testStatusList": [
                  {
                    "testStatus": "pass",
                    "count": 3993
                  },
                  {
                    "testStatus": "fail",
                    "count": 22
                  }
                ],
                "failedRate": 0.55,
                "childList": [

                ]
              }
            ]
          },
          {
            "timestamp": 1583267400000,
            "testTypeList": [
              {
                "testType": null,
                "categoryType": null,
                "testStatusList": [
                  {
                    "testStatus": "pass",
                    "count": 4305
                  },
                  {
                    "testStatus": "fail",
                    "count": 35
                  }
                ],
                "failedRate": 0.81,
                "childList": [

                ]
              }
            ]
          },
          {
            "timestamp": 1583269200000,
            "testTypeList": [
              {
                "testType": null,
                "categoryType": null,
                "testStatusList": [
                  {
                    "testStatus": "pass",
                    "count": 256
                  },
                  {
                    "testStatus": "fail",
                    "count": 56
                  }
                ],
                "failedRate": 17.95,
                "childList": [

                ]
              }
            ]
          },
          {
            "timestamp": 1583271000000,
            "testTypeList": [
              {
                "testType": null,
                "categoryType": null,
                "testStatusList": [
                  {
                    "testStatus": "pass",
                    "count": 4296
                  }
                ],
                "failedRate": 0.00,
                "childList": [

                ]
              }
            ]
          },
          {
            "timestamp": 1583272800000,
            "testTypeList": [
              {
                "testType": null,
                "categoryType": null,
                "testStatusList": [
                  {
                    "testStatus": "pass",
                    "count": 4516
                  },
                  {
                    "testStatus": "fail",
                    "count": 2
                  }
                ],
                "failedRate": 0.04,
                "childList": [

                ]
              }
            ]
          },
          {
            "timestamp": 1583274600000,
            "testTypeList": [
              {
                "testType": null,
                "categoryType": null,
                "testStatusList": [
                  {
                    "testStatus": "pass",
                    "count": 256
                  },
                  {
                    "testStatus": "fail",
                    "count": 56
                  }
                ],
                "failedRate": 17.95,
                "childList": [

                ]
              }
            ]
          },
          {
            "timestamp": 1583276400000,
            "testTypeList": [
              {
                "testType": null,
                "categoryType": null,
                "testStatusList": [
                  {
                    "testStatus": "pass",
                    "count": 4302
                  }
                ],
                "failedRate": 0.00,
                "childList": [

                ]
              }
            ]
          },
          {
            "timestamp": 1583278200000,
            "testTypeList": [
              {
                "testType": null,
                "categoryType": null,
                "testStatusList": [
                  {
                    "testStatus": "pass",
                    "count": 4671
                  }
                ],
                "failedRate": 0.00,
                "childList": [

                ]
              }
            ]
          },
          {
            "timestamp": 1583280000000,
            "testTypeList": [
              {
                "testType": null,
                "categoryType": null,
                "testStatusList": [
                  {
                    "testStatus": "pass",
                    "count": 3993
                  },
                  {
                    "testStatus": "fail",
                    "count": 7
                  }
                ],
                "failedRate": 0.17,
                "childList": [

                ]
              }
            ]
          },
          {
            "timestamp": 1583281800000,
            "testTypeList": [
              {
                "testType": null,
                "categoryType": null,
                "testStatusList": [
                  {
                    "testStatus": "pass",
                    "count": 4479
                  },
                  {
                    "testStatus": "fail",
                    "count": 48
                  }
                ],
                "failedRate": 1.06,
                "childList": [

                ]
              }
            ]
          },
          {
            "timestamp": 1583283600000,
            "testTypeList": [
              {
                "testType": null,
                "categoryType": null,
                "testStatusList": [
                  {
                    "testStatus": "pass",
                    "count": 4425
                  },
                  {
                    "testStatus": "fail",
                    "count": 116
                  }
                ],
                "failedRate": 2.55,
                "childList": [

                ]
              }
            ]
          },
          {
            "timestamp": 1583285400000,
            "testTypeList": [
              {
                "testType": null,
                "categoryType": null,
                "testStatusList": [
                  {
                    "testStatus": "pass",
                    "count": 4914
                  }
                ],
                "failedRate": 0.00,
                "childList": [

                ]
              }
            ]
          },
          {
            "timestamp": 1583287200000,
            "testTypeList": [
              {
                "testType": null,
                "categoryType": null,
                "testStatusList": [
                  {
                    "testStatus": "pass",
                    "count": 256
                  },
                  {
                    "testStatus": "fail",
                    "count": 156
                  }
                ],
                "failedRate": 37.95,
                "childList": [

                ]
              }
            ]
          },
          {
            "timestamp": 1583289000000,
            "testTypeList": [
              {
                "testType": null,
                "categoryType": null,
                "testStatusList": [
                  {
                    "testStatus": "pass",
                    "count": 4782
                  }
                ],
                "failedRate": 0.00,
                "childList": [

                ]
              }
            ]
          },
          {
            "timestamp": 1583290800000,
            "testTypeList": [
              {
                "testType": null,
                "categoryType": null,
                "testStatusList": [
                  {
                    "testStatus": "pass",
                    "count": 4464
                  }
                ],
                "failedRate": 0.00,
                "childList": [

                ]
              }
            ]
          },
          {
            "timestamp": 1583292600000,
            "testTypeList": [
              {
                "testType": null,
                "categoryType": null,
                "testStatusList": [
                  {
                    "testStatus": "pass",
                    "count": 4767
                  }
                ],
                "failedRate": 0.00,
                "childList": [

                ]
              }
            ]
          },
          {
            "timestamp": 1583294400000,
            "testTypeList": [
              {
                "testType": null,
                "categoryType": null,
                "testStatusList": [
                  {
                    "testStatus": "pass",
                    "count": 4800
                  }
                ],
                "failedRate": 0.00,
                "childList": [

                ]
              }
            ]
          },
          {
            "timestamp": 1583296200000,
            "testTypeList": [
              {
                "testType": null,
                "categoryType": null,
                "testStatusList": [
                  {
                    "testStatus": "pass",
                    "count": 256
                  },
                  {
                    "testStatus": "fail",
                    "count": 156
                  }
                ],
                "failedRate": 37.95,
                "childList": [

                ]
              }
            ]
          },
          {
            "timestamp": 1583298000000,
            "testTypeList": [
              {
                "testType": null,
                "categoryType": null,
                "testStatusList": [
                  {
                    "testStatus": "pass",
                    "count": 4789
                  },
                  {
                    "testStatus": "fail",
                    "count": 1
                  }
                ],
                "failedRate": 0.02,
                "childList": [

                ]
              }
            ]
          },
          {
            "timestamp": 1583299800000,
            "testTypeList": [
              {
                "testType": null,
                "categoryType": null,
                "testStatusList": [
                  {
                    "testStatus": "pass",
                    "count": 4374
                  },
                  {
                    "testStatus": "fail",
                    "count": 41
                  }
                ],
                "failedRate": 0.93,
                "childList": [

                ]
              }
            ]
          },
          {
            "timestamp": 1583301600000,
            "testTypeList": [
              {
                "testType": null,
                "categoryType": null,
                "testStatusList": [
                  {
                    "testStatus": "pass",
                    "count": 4715
                  },
                  {
                    "testStatus": "fail",
                    "count": 42
                  }
                ],
                "failedRate": 0.88,
                "childList": [

                ]
              }
            ]
          },
          {
            "timestamp": 1583303400000,
            "testTypeList": [
              {
                "testType": null,
                "categoryType": null,
                "testStatusList": [
                  {
                    "testStatus": "pass",
                    "count": 256
                  },
                  {
                    "testStatus": "fail",
                    "count": 156
                  }
                ],
                "failedRate": 37.95,
                "childList": [

                ]
              }
            ]
          },
          {
            "timestamp": 1583305200000,
            "testTypeList": [

            ]
          }
        ]
      }
    ]
  },
  "page": {
    "pageSize": 1,
    "offset": 1,
    "totalCount": 1
  }
}
})