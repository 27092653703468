define({
    "Device_Image_Template" : {
      "deviceId": "9c1cdba2-4309-4dd6-b5b3-d37fd515aa74",
      "productType": "Cisco Catalyst38xx stack-able ethernet switch",
      "productSeries": "Cisco Catalyst 3850 Series Ethernet Stackable Switch",
      "productFamily": "Switches and Hubs",
      "deviceImageUpgradeStatus": "OUTDATED",
      "issuEnabled":false,
      "deviceInstalledInfo": [
        {
          "name": "cat3k_caa-universalk9.16.06.02.SPA.bin",
          "type": "SYSTEM",
          "version": "16.6.2",
          "family": "CAT3K_CAA",
          "golden": false,
          "subpackageType": null,
          "displayVersion":"17.3.2a"
        }
      ],
      "targetImageInfo": [
        {
          "imageUuid": "d9acdbce-29b7-45a6-b451-8b89de3cdba2",
          "name": "cat3k_caa-universalk9.16.09.02.SPA.bin",
          "family": "CAT3K_CAA",
          "version": "16.9.2",
          "displayVersion" : "17.3.2a",
          "md5Checksum": "1440b1249d762ccfd0b3fd327c3e7158",
          "shaCheckSum": "354d8c2774cea0e2a6304f7256d4dd77d864f2761029f6f074368210c6f9c57baef83cf659b53417ecf7668c50ce3bf4c42fc1a56727b0a22eb8aa8a969dc08e",
          "createdTime": "2018-12-07 04:26:13.64",
          "imageType": "SYSTEM_SW",
          "fileSize": "447920275 bytes",
          "imageName": "cat3k_caa-universalk9.16.09.02.SPA.bin123",
          "applicationType": "",
          "feature": "",
          "fileServiceId": "5ce69e57-99c6-4d27-8223-a314d1ffdb77",
          "isTaggedGolden": true,
          "imageSeries": [
            "Switches and Hubs/Cisco Catalyst 3850 Series Ethernet Stackable Switch",
            "Switches and Hubs/Cisco Catalyst 3650 Series Switches"
          ],
          "extendedAttributes": {
            "image_description": "CAT3850/3650 UNIVERSAL",
            "Description": "Cisco IOS Software, IOS-XE Software",
            "metadata_trans_id": "153427119",
            "image_size": "447920275",
            "field_notice_url": "https://www.cisco.com/en/US/products/ps12686/prod_field_notices_list.html",
            "DEFAULT_BOOTROM": "0",
            "md5_checksum": "1440b1249d762ccfd0b3fd327c3e7158",
            "min_dram": "4096",
            "field_notices": "Field Notices",
            "DEFAULT_MINFLASHSIZE": "0",
            "sha512_checksum": "354d8c2774cea0e2a6304f7256d4dd77d864f2761029f6f074368210c6f9c57baef83cf659b53417ecf7668c50ce3bf4c42fc1a56727b0a22eb8aa8a969dc08e",
            "deviceMNEId": "542561",
            "image_name": "cat3k_caa-universalk9.16.09.02.SPA.bin",
            "encryption_software_indicator": "Y",
            "release_fcs_date": "07/Nov/2018",
            "image_guid": "C464D0D39ABF27C9F55237D39DA51F56AFF4A2F7",
            "mdf_id": "284455436",
            "udi": "PID: WS-C3850-48P-E VID: V01, SN: FOC1645V0E6",
            "DEFAULT_RAM": "0",
            "min_flash": "2048",
            "release_version": "Fuji-16.9.2"
          },
          "vendor": "CISCO",
          "imageIntegrityStatus": "VERIFIED",
          "importSourceType": "CCO",
          "ccoreverseSync": true,
          "ccolatest": true
        }
      ],
      "error": null,
      "readinessCheckStatus": {
        "preCheckPassCount": 8,
        "preCheckFailCount": 1,
        "lastChecked": null,
        "isReadyForUpgrade": true
      },
      "upgradeStatus": "Distribution Pending",
      "rebootRequired": false
    },

    "Device_Image_Template_NCP" : {
      "deviceId": "9c1cdba2-4309-4dd6-b5b3-d37fd515aa74",
      "productType": "Cisco Catalyst38xx stack-able ethernet switch",
      "productSeries": "Cisco Catalyst 3850 Series Ethernet Stackable Switch",
      "productFamily": "Switches and Hubs",
      "deviceImageUpgradeStatus": "OUTDATED",
      "deviceInstalledInfo": {
        "name": "cat3k_caa-universalk9.16.06.02.SPA.bin",
        "type": "SYSTEM",
        "version": "16.6.2",
        "family": "CAT3K_CAA",
        "golden": false,
        "subpackageType": null
      },
      "targetImageInfo": {
        "imageUuid": "d9acdbce-29b7-45a6-b451-8b89de3cdba2",
        "family": "CAT3K_CAA",
        "version": "16.9.2",
        "imageType": "SYSTEM_SW",
        "imageName": "cat3k_caa-universalk9.16.09.02.SPA.bin123",
        "isTaggedGolden": true
      },
      "error": null,
      "readinessCheckStatus": {
        "preCheckPassCount": 8,
        "preCheckFailCount": 1,
        "lastChecked": null,
        "isReadyForUpgrade": true
      },
      "upgradeStatus": "Distribution Pending",
      "rebootRequired": false
    }
  });
