define({
    "Template": 
{
    "request": {
      "key": "ms-teams-media",
      "type": "Application",
      "label": "ms-teams-media",
      "collection": false,
      "componentContent": [
        {
          "component": "fusion",
          "additional": "faf551ce-082b-4f92-af19-5e9221cead3c",
          "origType": "Application"
        }
      ]
    },
    "data": {
      "response": {
        "id": "faf551ce-082b-4f92-af19-5e9221cead3c",
        "instanceId": 5847,
        "instanceCreatedOn": 1657037696506,
        "instanceUpdatedOn": 1657037696506,
        "instanceVersion": 0,
        "createTime": 1657037684649,
        "deployed": false,
        "isSeeded": true,
        "isStale": false,
        "lastUpdateTime": 1657037684649,
        "name": "ms-teams-media",
        "namespace": "scalablegroup:application",
        "provisioningState": "DEFINED",
        "qualifier": "application",
        "resourceVersion": 0,
        "targetIdList": [],
        "type": "scalablegroup",
        "cfsChangeInfo": [],
        "customProvisions": [],
        "isDeleted": false,
        "iseReserved": false,
        "mdnacDeletionStatus": "ACTIVE",
        "propagateToAci": false,
        "pushed": false,
        "scalableGroupExternalHandle": "ms-teams-media",
        "scalableGroupType": "APPLICATION",
        "securityGroupTag": 0,
        "state": "ACTIVE",
        "vnAgnostic": true,
        "defaultContracts": [],
        "identitySource": {
          "id": "551bc1e2-bcde-46b3-9dff-9053ef4d272b",
          "instanceId": 14014,
          "instanceCreatedOn": 1657037668944,
          "instanceUpdatedOn": 1657037668944,
          "instanceVersion": 0,
          "state": "INACTIVE",
          "type": "NBAR",
          "displayName": "14014"
        },
        "indicativeNetworkIdentity": [],
        "networkApplications": [
          {
            "id": "1bf50690-ba28-45a1-94cf-da68fa7e8559",
            "instanceId": 16797,
            "instanceCreatedOn": 1657037696506,
            "instanceUpdatedOn": 1657037696506,
            "instanceVersion": 0,
            "appProtocol": "tcp/udp",
            "applicationGroup": "ms-cloud-group",
            "applicationSubType": "NONE",
            "applicationType": "DEFAULT",
            "categoryId": "0adcf602-f1ac-4f02-b84c-25cc1aa528ed",
            "createTime": 1657037684649,
            "encrypted": "true",
            "engineId": "13",
            "helpString": "Microsoft Teams Conference Calls",
            "ignoreConflict": false,
            "lastUpdateTime": 1657037684649,
            "longDescription": "Video or audio portion of Microsoft Teams' call",
            "name": "ms-teams-media",
            "nbarId": "1213",
            "p2pTechnology": "true",
            "popularity": 7,
            "rank": 65535,
            "references": "https://news.microsoft.com/microsoft-teams/",
            "selectorId": "1213",
            "serverNames": [],
            "subCategory": "5aee80d3-0ef6-40c3-9c4b-9d2f46b904ea",
            "trafficClass": "MULTIMEDIA_CONFERENCING",
            "tunnel": "false",
            "displayName": "16797"
          }
        ],
        "networkIdentity": [
          {
            "id": "492131f5-dbec-414e-b1f2-bc606ad2e4f4",
            "instanceId": 19089,
            "instanceCreatedOn": 1657037696506,
            "instanceUpdatedOn": 1657037696506,
            "instanceVersion": 0,
            "ipv4Subnet": [],
            "ipv6Subnet": [],
            "lowerPort": 0,
            "ports": "3478",
            "protocol": "UDP",
            "upperPort": 0,
            "displayName": "19089"
          },
          {
            "id": "d0b75746-5dee-4498-9085-acbaee7579af",
            "instanceId": 19090,
            "instanceCreatedOn": 1657037696506,
            "instanceUpdatedOn": 1657037696506,
            "instanceVersion": 0,
            "ipv4Subnet": [],
            "ipv6Subnet": [],
            "lowerPort": 0,
            "ports": "3478",
            "protocol": "TCP",
            "upperPort": 0,
            "displayName": "19090"
          }
        ],
        "parentScalableGroup": {
          "idRef": "579eb909-1c37-4515-abce-59b77638b6eb"
        },
        "user": [],
        "userGroup": [],
        "displayName": "5847"
      }
    },
    "result": [
      {
        "bookID": {
          "namespace": "fusion",
          "name": "core",
          "version": "1.0.1"
        },
        "response": {
          "id": "faf551ce-082b-4f92-af19-5e9221cead3c",
          "instanceId": 5847,
          "instanceCreatedOn": 1657037696506,
          "instanceUpdatedOn": 1657037696506,
          "instanceVersion": 0,
          "createTime": 1657037684649,
          "deployed": false,
          "isSeeded": true,
          "isStale": false,
          "lastUpdateTime": 1657037684649,
          "name": "ms-teams-media",
          "namespace": "scalablegroup:application",
          "provisioningState": "DEFINED",
          "qualifier": "application",
          "resourceVersion": 0,
          "targetIdList": [],
          "type": "scalablegroup",
          "cfsChangeInfo": [],
          "customProvisions": [],
          "isDeleted": false,
          "iseReserved": false,
          "mdnacDeletionStatus": "ACTIVE",
          "propagateToAci": false,
          "pushed": false,
          "scalableGroupExternalHandle": "ms-teams-media",
          "scalableGroupType": "APPLICATION",
          "securityGroupTag": 0,
          "state": "ACTIVE",
          "vnAgnostic": true,
          "defaultContracts": [],
          "identitySource": {
            "id": "551bc1e2-bcde-46b3-9dff-9053ef4d272b",
            "instanceId": 14014,
            "instanceCreatedOn": 1657037668944,
            "instanceUpdatedOn": 1657037668944,
            "instanceVersion": 0,
            "state": "INACTIVE",
            "type": "NBAR",
            "displayName": "14014"
          },
          "indicativeNetworkIdentity": [],
          "networkApplications": [
            {
              "id": "1bf50690-ba28-45a1-94cf-da68fa7e8559",
              "instanceId": 16797,
              "instanceCreatedOn": 1657037696506,
              "instanceUpdatedOn": 1657037696506,
              "instanceVersion": 0,
              "appProtocol": "tcp/udp",
              "applicationGroup": "ms-cloud-group",
              "applicationSubType": "NONE",
              "applicationType": "DEFAULT",
              "categoryId": "0adcf602-f1ac-4f02-b84c-25cc1aa528ed",
              "createTime": 1657037684649,
              "encrypted": "true",
              "engineId": "13",
              "helpString": "Microsoft Teams Conference Calls",
              "ignoreConflict": false,
              "lastUpdateTime": 1657037684649,
              "longDescription": "Video or audio portion of Microsoft Teams' call",
              "name": "ms-teams-media",
              "nbarId": "1213",
              "p2pTechnology": "true",
              "popularity": 7,
              "rank": 65535,
              "references": "https://news.microsoft.com/microsoft-teams/",
              "selectorId": "1213",
              "serverNames": [],
              "subCategory": "5aee80d3-0ef6-40c3-9c4b-9d2f46b904ea",
              "trafficClass": "MULTIMEDIA_CONFERENCING",
              "tunnel": "false",
              "displayName": "16797"
            }
          ],
          "networkIdentity": [
            {
              "id": "492131f5-dbec-414e-b1f2-bc606ad2e4f4",
              "instanceId": 19089,
              "instanceCreatedOn": 1657037696506,
              "instanceUpdatedOn": 1657037696506,
              "instanceVersion": 0,
              "ipv4Subnet": [],
              "ipv6Subnet": [],
              "lowerPort": 0,
              "ports": "3478",
              "protocol": "UDP",
              "upperPort": 0,
              "displayName": "19089"
            },
            {
              "id": "d0b75746-5dee-4498-9085-acbaee7579af",
              "instanceId": 19090,
              "instanceCreatedOn": 1657037696506,
              "instanceUpdatedOn": 1657037696506,
              "instanceVersion": 0,
              "ipv4Subnet": [],
              "ipv6Subnet": [],
              "lowerPort": 0,
              "ports": "3478",
              "protocol": "TCP",
              "upperPort": 0,
              "displayName": "19090"
            }
          ],
          "parentScalableGroup": {
            "idRef": "579eb909-1c37-4515-abce-59b77638b6eb"
          },
          "user": [],
          "userGroup": [],
          "displayName": "5847"
        }
      }
    ]
  }
});