define([
    'core/../../../public/libs/node_modules/jquery-mockjax/src/jquery.mockjax',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/services/cfs/controller/SimCfsController',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/utilities/parseUrl'
], function (mockjax, SimCfsController, parseUrl) {

    mockjax($, {
        ///data/customer-facing-service/count/DeviceInfo
        url: "/api/v2/data/customer-facing-service/count/*",
        type: "get",
        response: function(settings, done) {
            var self = this;
            var arrQueryValue = parseUrl.getUrlVars(settings.url); 
            SimCfsController.getCfsCount(settings.url, arrQueryValue, function(data) {
                self.responseText = data;
                done();
            });
        }
    });
    /*mockjax($, {
        url: "/api/v2/data/customer-facing-service/count?type*",
        type: "get",
        response: function (settings, done) {
            var self = this;
            var arrQueryValue = parseUrl.getUrlVars(settings.url);
            var t = {"response": 0, "version": "1.0"};
            self.responseText = t;
            done();
        }
    });*/

    mockjax($, {
        url: "/api/v2/data/customer-facing-service/*",
        type: "get",
        response: function (settings, done) {
            var self = this;
            var arrQueryValue = parseUrl.getUrlVars(settings.url);
            SimCfsController.getCfsDetails(settings.url, arrQueryValue, function (data) {
                self.responseText = data;
                //console.log("request = "+settings.url+ "; response = "+JSON.stringify(data));
                done();
            });
        }
    });

    mockjax($, {
        url: "/api/v2/data/customer-facing-service?type=scalablegroup*",
        type: "get",
        response: function (settings, done) {
            var self = this;
            var arrQueryValue = parseUrl.getUrlVars(settings.url);
            SimCfsController.getCfsDetails(settings.url, arrQueryValue, function (data) {
                self.responseText = data;
                done();
            });
        }
    });
    //-service?type=policy&qualifier=application&name=Application-set-default-policy&_=1500447293845 
    mockjax($, {
        url: "/api/v2/data/customer-facing-service?type=policy*",
        type: "get",
        response: function (settings, done) {
            var self = this;
            var arrQueryValue = parseUrl.getUrlVars(settings.url);
            SimCfsController.getCfsDetails(settings.url, arrQueryValue, function (data) {
                self.responseText = data;
                done();
            });
        }
    });

    mockjax($, {
        url: "/api/v2/data/customer-facing-service/scratchpad/*",
        type: "POST",
        response: function (settings, done) {
            var self = this;
            console.log("customer facing post recvd in rest file");
            SimCfsController.CfsPost(settings.url, JSON.parse(settings.data), function (data) {
                self.responseText = data;
                self.status =202;
                done();
            });
        }
    });

    mockjax($, {
        url: "/api/v2/data/customer-facing-service/*",
        type: "POST",
        response: function (settings, done) {
            var self = this;
            console.log("customer facing post recvd in rest file");
            SimCfsController.CfsPost(settings.url, JSON.parse(settings.data)[0], function (data) {
                self.responseText = data;
                self.status =202;
                done();
            });
        }
    });

    mockjax($, {
        url: "/api/v2/data/customer-facing-service/*",
        type: "PUT",
        response: function (settings, done) {
            var self = this;
            SimCfsController.CfsPut(settings.url, JSON.parse(settings.data)[0], function (data) {
                self.responseText = data;
                done();
            });
        }
    });

    mockjax($, {
        url: "/api/v2/data/customer-facing-service/*",
        type: "DELETE",
        response: function (settings, done) {
            var self = this;
            SimCfsController.CfsDelete(settings.url, function (data) {
                self.responseText = data;
                done();
            });
        }
    });
   //url: /^\/api\/v1\/network-device*/i,
    mockjax($, {
        //url: "/api/v1/audit?tag=Policy V2&orderBy=createdDateTime&orderByType=desc&offset=0&limit=2",
        url: /^\/api\/v1\/audit\?tag=Policy V2*/i,
        //url: "/api/v1/audit?tag=Policy V2&orderBy=createdDateTime&orderByType=desc*",
        type: "get",
        response: function (settings, done) {
            var self = this;
            var arrQueryValue = parseUrl.getUrlVars(settings.url);
            SimCfsController.cfsAudit(settings.url, function (data) {
                self.responseText = data;
                done();
            });
        }
    });

    mockjax($, {
        url: "/api/v1/audit/count?tag=Policy V2",
        type: "get",
        response: function (settings, done) {
            var self = this;
            SimCfsController.cfsAuditCount(settings.url, function (data) {
                self.responseText = data;
                done();
            });
        }
    });

    /*mockjax($, {
        url: "/api/v1/group?groupName=*",
        type: "get",
        response: function (settings, done) {
            var self = this;
            var arrQueryValue = parseUrl.getUrlVars(settings.url);
            SimGroupController.getGroupNames(settings.url, arrQueryValue, function (data) {
                self.responseText = data;
                done();
            });
        }
    });

    ///api/v1/group/count?groupType=SITE&size=1000

    mockjax($, {
        url: "/api/v1/group/count?groupType=SITE&size=1000",
        type: "get",
        response: function (settings, done) {
            var self = this;
            var arrQueryValue = parseUrl.getUrlVars(settings.url);
            SimGroupController.getGroupNames(settings.url, arrQueryValue, function (data) {
                self.responseText = 2;
                done();
            });
        }
    });*/


    mockjax($, {
        url: "/api/v2/data/device-config-status/count*",
        type: "get",
        response: function(settings, done) {
            var self = this;
            var arrQueryValue = parseUrl.getUrlVars(settings.url);
            SimCfsController.getDeviceConfigStatusCount(arrQueryValue, function(data) {
                self.responseText = data;
                done();
            });
        }
    });
    
    mockjax($, {
        url: "/api/v2/data/device-config-status/aggregate/count*",
        type: "get",
        response: function(settings, done) {
            var self = this;
            var arrQueryValue = parseUrl.getUrlVars(settings.url);
            SimCfsController.getDeviceConfigStatusCount(arrQueryValue, function(data) {
                self.responseText = data;
                done();
            });
        }
    });

    mockjax($, {
        url: "/api/v2/data/device-config-status*",
        type: "get",
        response: function(settings, done) {
            var self = this;
            var arrQueryValue = parseUrl.getUrlVars(settings.url);
            SimCfsController.getDeviceConfigStatus(arrQueryValue, function(data) {
                self.responseText = data;
                done();
            });
        }
    });

    mockjax($, {
        url: "/api/v2/data/cfs-intent/policy/access/",
        type: "POST",
        response: function (settings, done) {
            var self = this;
            SimCfsController.CfsIntent(settings.url, JSON.parse(settings.data), function (data) {
                self.responseText = data;
                done();
            });
        }
    });

    mockjax($, {
        url: "/api/v2/data/cfs-intent/contract/access/",
        type: "POST",
        response: function (settings, done) {
            var self = this;
            SimCfsController.CfsIntentContractAccess(settings.url, JSON.parse(settings.data), function (data) {
                self.responseText = data;
                done();
            });
        }
    });

 //GET /api/v1/siteprofile/attribute?key=wireless.ssid&_=1505386599491
    /*mockjax($, {
        url: "/api/v1/siteprofile/attribute*",
        type: "get",
        response: function(settings, done) {
            var self = this;
            SimCfsController.CfsSsid(settings.url, function(data) {
                self.responseText = data;
                done();
            });
        }
    });*/

    // /api/v2/data/cfs-intent/scalablegroup/

    mockjax($, {
        url: "/api/v2/data/cfs-intent/scalablegroup/",
        type: "POST",
        response: function (settings, done) {
            var self = this;
            SimCfsController.CfsIntentScalableGroup(settings.url, JSON.parse(settings.data), function (data) {
                self.responseText = data;
                done();
            });
        }
    });


    mockjax($, {
        url: "/api/v2/data/cfs-intent/policy/copy/",
        type: "POST",
        response: function (settings, done) {
            var self = this;
            SimCfsController.CfsIntentCopyPolicy(settings.url, JSON.parse(settings.data), function (data) {
                self.responseText = data;
                done();
            });
        }
    });

     mockjax($, {
        url: "/api/v2/data/cfs-intent/policy/application",
        type: "POST",
        response: function (settings, done) {
            var self = this;
            SimCfsController.CfsIntentApplicationPolicy(settings.url, JSON.parse(settings.data), function (data) {
                self.responseText = data;
                 self.status =202;
                done();
            });
        }
    });


    return {
        init: function () {
        }
    };
});
