define([
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/database/SimLokiDatabaseActions',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/data-generation/services/SimDeviceReplacementData',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/data-generation/configuration/SimTaskDataGenerator',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/data-generation/services/SimDeviceImageData',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/utilities/UtilityFunctions',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/Security-Advisories/SimAdvisoriesTemplate',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/Security-Advisories/AdvisoryLimitTemplate',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/Security-Advisories/AllDevice_GraphQL'
    
], function (SimLokiDatabaseActions, SimDeviceReplacementData, SimTaskDataGenerator, SimDeviceImageData , UtilityFunctions , SimAdvisoriesTemplate , AdvisoryLimitTemplate, AllDevice_GraphQL) {

    var validReplacementStatus = {'done':'REPLACED', 'unMark':'NON-FAULTY', 'mark':'MARKED-FOR-REPLACEMENT'};
    //READY-FOR-REPLACEMENT, REPLACEMENT-SCHEDULED, REPLACEMENT-IN-PROGRESS, ERROR, REPLACED ?

    return {
        init: function () {
        },

        doProcessRestRequest: function (urlAction) {
            var data;
            switch (urlAction.method) {
                case 'GET':
                    data = processGetRequest(urlAction);
                    break;

                case 'POST':
                    data = processPostRequest(urlAction);
                    break;

                case 'PUT':
                    data = processPutRequest(urlAction);
                    break;

                case 'DELETE':
                    data = processDeleteRequest(urlAction);
                    break;

                default:
                    break;
            }

            return data;
        }
    };

    function processGetRequest(urlAction) {
        if (urlAction.service.indexOf('cisco.dna.rmaDeviceView') > -1) {
            return SimDeviceReplacementData.getRmaPersistence();
        }
        if(urlAction.service.indexOf('archive-config')>-1 ) {
            return SimDeviceReplacementData.getArchieveDetails(urlAction);
        }
        if (urlAction.filter['replacementStatus'] || urlAction.filter['status']) {
            return SimDeviceReplacementData.getReplacementHistory(urlAction);
        }
        //compliance calls
        if(urlAction.service.indexOf('compliance')>-1 ) {
            return SimDeviceReplacementData.getComplianceDetails(urlAction);
        }
        if(urlAction.service.indexOf('deploy-config')>-1 ) {
            data = SimDeviceReplacementData.getDeployConfigTaskStatus(urlAction);
            return data
        }
        //Security Advisories
        if(urlAction.service.indexOf('security-advisory')>-1 ) {
            if(urlAction.service.indexOf('tasktracker')>-1 && urlAction.service.indexOf('psirt')>-1){
                let tJson = JSON.parse(JSON.stringify(SimAdvisoriesTemplate.AdvisoriesTaskTemplate));
                return tJson.response ;
            }
            if(urlAction.service.indexOf('inference')>-1 && urlAction.service.indexOf('polling')>-1){
                let tJson = JSON.parse(JSON.stringify(SimAdvisoriesTemplate.AdvisoriesInferenceTemplate));
                return tJson.response ;
            }
            
            if(urlAction.service.indexOf('advisory')>-1 && urlAction.service.indexOf('aggregate')>-1){
                let tJson = JSON.parse(JSON.stringify(SimAdvisoriesTemplate.AdvisoriesAggregateTemplate));
                return tJson.response ;
            } else if(urlAction.service.indexOf('advisory')>-1 && urlAction.action.count) {
                if(urlAction.filter['psirtDevices.deviceId']){
                    var deviceId = urlAction.filter['psirtDevices.deviceId'];
                    var deviceDetails = SimLokiDatabaseActions.getFilteredRecordHandler('network-device', {"id" : deviceId});
                    var advisoryCountObject = AllDevice_GraphQL.advisoryCountObject;
                    var Device_SD_CountObj = advisoryCountObject.advisoryCountList.filter(function(obj){
                        return obj.deviceName == deviceDetails[0].hostname
                    })
                   return Device_SD_CountObj[0].advisoryCount;
                } else {
                    if(urlAction.filter.sir){
                        if(urlAction.filter.sir.includes(',')){
                            return count(dataset)
                        } else {
                            return count(data)
                        }
                    } else {
                        let tJson = JSON.parse(JSON.stringify(AdvisoryLimitTemplate.AllAdvisories));
                        return tJson.length;    
                    }
                }
                
            }
            
            else if(urlAction.service.indexOf('advisory')>-1 ) {
                if(urlAction.filter["psirtDevices.deviceId"]){
                    var deviceId =  urlAction.filter["psirtDevices.deviceId"];
                    var deviceDetails = SimLokiDatabaseActions.getFilteredRecordHandler('network-device', {'id':deviceId});
                    var advisoryCountObject =  JSON.parse(JSON.stringify(AllDevice_GraphQL.advisoryCountObject));
                    var temp = advisoryCountObject.advisoryCountList.filter(function(obj){
                        return obj.deviceName == deviceDetails[0].hostname;
                    });
    
                    let tJson = JSON.parse(JSON.stringify(AdvisoryLimitTemplate.AllAdvisories));
                    tJson = tJson.slice(0, temp[0].advisoryCount)
                    if(urlAction.action.count){
                        return tJson;
                    }
                    return tJson
                } else {
                    if(urlAction.filter.sir){
                    let sirValue = urlAction.filter.sir;
                    var arrayData = [25];
                    var dataset = [];

                    if(sirValue.includes(',')){
                        var textarray = sirValue.split(',');

                        for(let i=0; i<textarray.length; i++){
                            var data = getDataFromSIR(textarray[i]);
                            arrayData[i] = data;

                            for(let j=0; j<arrayData[i].length; j++){
                                    var singleData = [];
                                    singleData = JSON.parse(JSON.stringify(arrayData[i][j]));
                                    dataset.push(singleData);
                            }
                        }
                            return dataset;
                    }
                    else if(urlAction.filter.sir == sirValue){
                        var data = getDataFromSIR(sirValue);
                        return data
                    }
                    } else {
                        let tJson = JSON.parse(JSON.stringify(AdvisoryLimitTemplate.AllAdvisories));
                        return tJson;
                    }
                }
                
        }
            if(urlAction.service.indexOf('device')>-1 && urlAction.service.indexOf('count')>-1 && urlAction.service.filter['scanMode']==('ESSENTIALS')>-1){ 
                let tJson = JSON.parse(JSON.stringify(SimAdvisoriesTemplate.AdvisoriesSoftwareVersionTemplate));
                return tJson.response ;
            }

            return SimDeviceReplacementData.getSecAdvForDevice(urlAction);
        }
        
        function count(obj){
            var count = 0;
            for (let properties in obj) {
                count = count + 1
            }
            return count;
        }

        function getDataFromSIR(sirValue){
            let tJson = JSON.parse(JSON.stringify(AdvisoryLimitTemplate.AllAdvisories));
                var data1 = tJson.filter(obj => {
                    return obj.sir == sirValue
                })
                return data1;
            }
            
    }

    function processPostRequest(urlAction) {  
        var resultObj, restPayload = urlAction.restPayload;
        if(urlAction.service.indexOf('workflow') > -1) {
            resultObj = SimDeviceReplacementData.replaceDevice(restPayload);
        } else if(urlAction.service.indexOf("compliance")>-1) {
            resultObj = SimTaskDataGenerator.createTask('SPFService');//Run compliance - in progress
            SimDeviceImageData.fetchRunningImage({"filter":urlAction.restPayload.deviceUuids});
        } else if(urlAction.service.indexOf("deploy-config")>-1) {
            resultObj = SimTaskDataGenerator.createTask('SPFService');
            // using temp db table storing the bool value, so that when user do the sync it will not remove the lines from file
            SimLokiDatabaseActions.insert("temporary-data", {"key":"deploy-config-status", "value":true});
        } else if (urlAction.service.indexOf('cisco.dna.rmaDeviceView') > -1) {
            return SimDeviceReplacementData.postRmaPersistence(urlAction.restPayload);
        } else {
            //if(restPayload && restPayload.length>0 && restPayload[0].replacementStatus == validReplacementStatus.mark) {
                resultObj = SimDeviceReplacementData.markForReplace(restPayload);
            //}
        }
        resultObj.statusCode= 202;
        return resultObj;
    }

    function processPutRequest(urlAction) {
        var progress = "Device Unmarked Successfully.";
        var resultObj = SimTaskDataGenerator.createTask('Device Replacement Service', {'progress':progress} );
        var restPayload = urlAction.restPayload;
        //if(restPayload && restPayload.length>0 && restPayload[0].replacementStatus == validReplacementStatus.unMark) {
        SimDeviceReplacementData.unmarkForReplace(restPayload);
        //}
        SimTaskDataGenerator.updateTaskCompletion(resultObj.taskId, { isError: false });
        resultObj.statusCode= 202;
        return resultObj;
    }

    function processDeleteRequest(urlAction) {
        return [];
    }

});
