define({
    "Segment_Template": {
        id: "",
        instanceId: 83101,
        authEntityId: 83101,
        displayName: "002ca20f-809b-4d97-a1b7-8e960654f9bd",
        authEntityClass: -1032282281,
        instanceTenantId: "5ac3de89d3852f00894197d7",
        deployPending: "NONE",
        instanceVersion: 0,
        deployed: false,
        isStale: false,
        isSeeded: false,
        name: "",
        namespace: "",
        resourceVersion: 5,
        targetIdList: [],
        provisioningState: "UNKNOWN",
        type: "Segment",
        cfsChangeInfo: [],
        ipPoolId: "",
        isApProvisioning: false,
        isCriticalPool: false,
        isDefaultEnterprise: false,
        isDefaultGuest: false,
        isExtendedProvisioning: false,
        isFloodAndLearn: false,
        isInfraPool: false,
        l2Instance: 0,
        scalableGroupId: "",
        vlanId: "11",
        wiredAuthMode: "STATIC",
        connectivityDomain: {"idRef": ""},
        wlan: [],
        authenticationProfiles: ["No Authentication"],
        connectivityDomainId:'',
        distributedAnycastGateway: true,
        externalVlan: 0,
        gateway: "",
        isL2Flooding: false,
        isWakeOnLan: false,
        scalableGroups: [],
        vlanName: "10_16_52_0-VN_Employees",
        trafficType: "DATA",
        virtualNetworkContextId: "",
        virtualNetworkId: "",
        associatedL3VNInstanceId: 8188,
        siteCount: 1,
        usedForWireless: false,
        l2SegmentSummary: {
            id: "08d1cff5-b78a-4fb7-aff7-23ec9a2183fe",
            instanceId: 68974906,
            instanceVersion: 0,
            authenticationProfiles: ["No Authentication"],
            connectivityDomainId: "0a4583e0-33a8-44ee-94c9-c295fdf49e20",
            isBridgeModeVm: false,
            isCriticalPool: false,
            isCustomVlan: true,
            isGatewayAssociated: true,
            isL2Flooding: true,
            l2Instance: 8189,
            scalableGroupId: "",
            scalableGroups: [],
            siteCount: 1,
            trafficType: "DATA",
            usedForWireless: false,
            vlanId: 11,
            vlanName: "",
            displayName: "68974906"
        },
        l2Segment:{idRef: "e9f2b20c-f376-4275-b369-5a6a3196fb9a"},
        segmentSummary: {isExtendedProvisioning:true,isApProvisioning:true},
        segmentInfos:[
            {
              "id": "bcd23187-2ea8-4fbd-b3d9-f5e6cbf70939",
              "instanceId": 83269186,
              "instanceCreatedOn": 1672145557811,
              "instanceUpdatedOn": 1672145557811,
              "instanceVersion": 1,
              "addr": "10.16.55.176",
              "dhcpSvrAddr": [
                "10.16.1.10"
              ],
              "dnsSvrAddr": [
                "10.16.1.10"
              ],
              "gateway": "10.16.55.177",
              "ipPoolId": "7883311d-00f2-4aff-9ba0-fa25c97a6576",
              "ipVersion": "IPV4",
              "mask": 28,
              "displayName": "0"
            }
        ],
        customProvisions: [],
        enableSLACC: false,
        instanceCreatedOn: 1672145557811,
        instanceUpdatedOn: 1673447237487,
        isAENEnabled: false,
        isBridgeModeVm: false,
        isCommonPool: false,
        isCriticalPool: false,
        isCustomVLAN: true,
        isSelectiveFloodingEnabled: false,
        namespace: "",
        selectiveFloodingAddressSource: "CONNECTIVITY_DOMAIN_FLOODING_INDEX",
        isBridgeModeVm: false,
        isCustomVlan: true,
        isGatewayAssociated: true,
        isCommonPool: false,
        isSelectiveFloodingEnabled: false,
        isWakeOnLan: false,
        isWirelessPool:true,
        isL2OnlyPool: false,
        isSelectiveFloodingEnabled: false,
        isWakeOnLan: false
    },
    "SEGMENT_SSID": [{
        "name": "sample-ssid-002_Fabric",
        "ssid": "sample-ssid-002",
        "wlanUUID": "002ca20f-809b-4d97-a1b7-8e960654f9bc"
    }]
});
