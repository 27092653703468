define({

    "Template":
        {
            "version": "1.0",
            "response": {
                "detailList": [
                    {
                        "itemType": "Total APs",
                        "timestamp": "2022-03-25T02:30:00.000+0000",
                        "count": 41,
                        "nestedItems": [
                            {
                                "itemType": "Fully Powered",
                                "timestamp": "2022-03-25T02:30:00.000+0000",
                                "count": 35
                            },
                            {
                                "itemType": "Partially Powered",
                                "timestamp": "2022-03-25T02:30:00.000+0000",
                                "count": 6
                            }
                        ]
                    },

                ]
            }

        },
    "AP4800_Template":
        {
            "version": "1.0",
            "response": {
                "detailList": [
                    {
                        "itemType": "Total AP Power Budget",
                        "timestamp": "2022-03-25T02:30:00.000+0000",
                        "count": 34,
                        "nestedItems": [
                            {
                                "itemType": "Consumed Power",
                                "timestamp": "2022-03-25T02:30:00.000+0000",
                                "count": 25
                            },
                            {
                                "itemType": "Allocated Power",
                                "timestamp": "2022-03-25T02:30:00.000+0000",
                                "count": 0
                            }
                        ]
                    },

                ]
            }

        }
})