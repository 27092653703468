define([
     'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/database/SimLokiDatabaseActions',
     'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/data-generation/services/assurance/SimAssuranceCacheData',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/assurance/device/AssuranceNetworkDeviceTemplate',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/assurance/device/AssuranceNetworkDeviceDetailTemplate',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/assurance/device/AssuranceNetworkDeviceMetricTemplate',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/assurance/device/NetworkDeviceTopologyScoreTemplate',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/assurance/device/NetworkDeviceHealthMetric',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/assurance/device/NetworkDeviceHealthSummary_Global',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/inventory/DeviceUpTimeTemplate',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/assurance/device/DeviceDetail',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/assurance/metric/interface/avgInputErrors_page1',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/assurance/metric/interface/avgInputUtil_page1',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/assurance/metric/interface/avgOutputErrors_page1',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/assurance/metric/interface/avgOutputUtil_page1',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/utilities/UtilityFunctions',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/assurance/metric/interface/InterfaceConnectivityTemplate',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/data-generation/services/assurance/SimScheduledCaptureData',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/wireless/WlanTemplate',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/data-generation/services/assurance/AssuranceAutocompleteFilterData',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/assurance/wifi6/Wifi6Template',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/assurance/wifi6/Timestamp_AirtimeEfficiency_voice',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/assurance/wifi6/Timestamp_AirtimeEfficiency_video',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/assurance/wifi6/Timestamp_AirtimeEfficiency_be',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/assurance/wifi6/Timestamp_AirtimeEfficiency_bg',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/assurance/wifi6/Timestamp_AirtimeEfficiency_all',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/assurance/wifi6/Timestamp_Latency_voice',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/assurance/wifi6/Timestamp_Latency_video',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/assurance/wifi6/Timestamp_Latency_be',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/assurance/wifi6/Timestamp_Latency_bg',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/data-generation/configuration/DefaultConfig',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/assurance/pathtrace/PathTraceCompleted'
], function(SimLokiDatabaseActions, SimAssuranceCacheData,
             AssuranceNetworkDeviceTemplate,
             AssuranceNetworkDeviceDetailTemplate, AssuranceNetworkDeviceMetricTemplate,
             NetworkDeviceTopologyScoreTemplate,
             NetworkDeviceHealthMetric, NetworkDeviceHealthSummary_Global, DeviceUpTimeTemplate, DeviceDetail,
             interface_avgInputErrors_page1, interface_avgInputUtil_page1,  interface_avgOutputErrors_page1,
               interface_avgOutputUtil_page1, UtilityFunctions,InterfaceConnectivityTemplate,SimScheduledCaptureData,WlanTemplate,
             AssuranceAutocompleteFilterData, Wifi6Template, Timestamp_AirtimeEfficiency_voice, Timestamp_AirtimeEfficiency_video,
             Timestamp_AirtimeEfficiency_be, Timestamp_AirtimeEfficiency_bg, Timestamp_AirtimeEfficiency_all,
             Timestamp_Latency_voice, Timestamp_Latency_video, Timestamp_Latency_be, Timestamp_Latency_bg, DefaultConfig,PathTraceCompleted ){
  // Added WLC and AP for 1.2.4
  var deviceRoles = ['Core', 'Access', 'Distribution', 'Router', 'Wireless','WLC', 'AP', 'Control Plane', 'Border'];

  function generateData(urlAction, trendData,summaryData) {
        var tWindowTime = 5;
        var hours = UtilityFunctions.getHours(urlAction.filter["startTime"]-30*60000, urlAction.filter["endTime"]);
        hours = Math.floor(hours);

        if (hours > 24) {
            tWindowTime = 60;
        }
        var incrementBy = tWindowTime;
        var response = [];
        var fromTime = urlAction.filter["startTime"]-30*60000; // shift start time by 30 mins to fill gap
        var endTime= urlAction.filter["endTime"];
        // for hack , this will be removed.
        var iLen = UtilityFunctions.getNumberOfRecordsNeedToGenerate(fromTime, endTime, tWindowTime);

        //var tGoodCount = summaryData.overallGoodCount, tFairCount = summaryData.overallFairCount;
        //var tBadCount = summaryData.overallBadCount;
        //var tScore = summaryData.latestHealthScore;
        trendData.response.length = 0;

        if(iLen==0) {
            iLen=1;
        }
        var timeToSet = UtilityFunctions.incrementMinutes(fromTime,0);;
        for (var i=0; i < iLen; i++) {
            var responseObj={};
            responseObj.time = timeToSet;
            timeToSet = UtilityFunctions.incrementMinutes(fromTime,incrementBy);
             var tGoodCount = summaryData.overallGoodCount, tFairCount = summaryData.overallFairCount;
             var tBadCount = summaryData.overallBadCount;
             var tScore = summaryData.latestHealthScore;

             if(i % 25 == 0) {
                 if(tGoodCount - tBadCount > tBadCount) {
                     tGoodCount -= tBadCount;
                     tBadCount += tBadCount;
                     tScore = Math.round( (tGoodCount / summaryData.monitoredDevices) * 100);
                 }
             }

            //timeToSet = tGoodCount;//UtilityFunctions.incrementDateByMinutes(fromTime,incrementBy);
            responseObj.goodCount = tGoodCount;//summaryData.overallGoodCount;
            responseObj.fairCount = tFairCount;//summaryData.overallFairCount;
            responseObj.badCount = tBadCount;//summaryData.overallBadCount;
            responseObj.unmonCount = 0;
            responseObj.totalCount = summaryData.monitoredDevices;
            responseObj.healthScore = tScore;//summaryData.latestHealthScore;
            responseObj.entity = null;
            //response.push(responseObj);
            trendData.response.push(responseObj);
            incrementBy += tWindowTime;

           // UtilityFunctions.incrementDateByMinutes(fromTime,incrementBy);
        }

        trendData.response[iLen-1].time=new Date(endTime);
         //trendData.response=response;

    return trendData;

  }


  function getMinMax(healthMetrics) {
      var min, max;
      if(healthMetrics != undefined && healthMetrics.length) {
          if (healthMetrics[0].value == "POOR") {
              return {min:1,max:3};
          }else if (healthMetrics[0].value == "FAIR") {
              return {min:4,max:7};
          } else if (healthMetrics[0].value == "GOOD") {
              return {min:8,max:10};
          } else if (healthMetrics[0].value == "NA") {
            return {min:0,max:0};
          }
          return undefined;
      }
  }

  function getGroupNameHierarchy(siteId){
      var groupNameHierarchy = undefined;
      recordObj = SimLokiDatabaseActions.getFilteredRecordHandler('site',{'id': siteId });
      if (recordObj && recordObj.length){
          groupNameHierarchy = recordObj[0].groupNameHierarchy;
      }
      return groupNameHierarchy;
  }

  function getRole(urlAction) {
      var tRole = urlAction.restPayload.role;
      if(urlAction.restPayload.fabricRole != undefined) {
          var i = urlAction.restPayload.fabricRole.indexOf("::");
          if(i>=0) {
          var iRem = urlAction.restPayload.fabricRole.length - i;
          tRole  = urlAction.restPayload.fabricRole.substr(i + 2, iRem);
          } else {
           tRole =urlAction.restPayload.fabricRole

          }
          console.log(tRole);
          if(tRole == "EDGE") {
              tRole = "ACCESS";
          } else if(tRole == "MAP-SERVER") {
              tRole = "CP";
          }
      }
      return tRole;
  }

  function getApsUnderWlc(wlcIp) {
    var aps = [];
    var records = SimLokiDatabaseActions.getFilteredRecordHandler('network-device', {'associatedWlcIp': wlcIp});
    if(records && records.length>0) {
        records.forEach(record => {
            aps.push(record.id);
        });
    }
    return aps;
  }

  function getFilteredKpiObjs (objs, kpiObj, summaryData) {
    var devlist=objs, categoryissues=[], issueDevices=[], deviceissueIpMacs=[];
    issueDevices=[];
    deviceissueIpMacs=[];

    if(kpiObj.key==="CPU"){
        categoryissues =summaryData.mappedIssues.cpu_issues;
    } else if(kpiObj.key==="MEMORY"){
        categoryissues =summaryData.mappedIssues.memory_issues;
    } else if(kpiObj.key==="ERRORS"){
        categoryissues =summaryData.mappedIssues.link_issues;
    } else if(kpiObj.key==="CPCONECTIVITY"){
        categoryissues =summaryData.mappedIssues.reachability_issues;
    } else if(kpiObj.key==="UTILIZATION"){
            categoryissues =summaryData.mappedIssues.utilization_issues;
    } else if(kpiObj.key==='UPLINK'){//for interface
            categoryissues =summaryData.mappedIssues.interface_issues;
    } else if(kpiObj.key==="NOISE"){
            categoryissues =summaryData.mappedIssues.noise_issues;
    }

    var deviceIssues = SimLokiDatabaseActions.getFilteredRecordHandler('issue', {'$and': 
        [ {'entityType':'Device'}, {'issueNamekey':{'$in':categoryissues}} ] });

    for(var k=0;k<deviceIssues.length;k++){
        for(var h=0;h<devlist.length;h++) {
            if((devlist[h].managementIpAddress===deviceIssues[k].entity || devlist[h].macAddress.toUpperCase()===deviceIssues[k].entity) && devlist[h].assuranceHealthScore<4){
                if(deviceissueIpMacs.indexOf(deviceIssues[k].entity)<0) {
                    deviceissueIpMacs.push(deviceIssues[k].entity);
                    issueDevices.push(devlist[h]);
                }
            }
        }
    }    
    var goodDevices = devlist.filter( x => !issueDevices.filter( y => y.id === x.id).length);

    if(kpiObj.value==="GOOD" &&  kpiObj.key==="CPCONECTIVITY"){
        objs=devlist.filter(function(itm){ return itm.assuranceHealthScore > 7 });
    } else if(kpiObj.value==="FAIR" &&  kpiObj.key==="CPCONECTIVITY"){
        objs=devlist.filter(function(itm){ return itm.assuranceHealthScore >= 4 && itm.assuranceHealthScore <= 7 });
    } else if(kpiObj.value==="POOR" &&  kpiObj.key==="CPCONECTIVITY"){
        objs=devlist.filter(function(itm){ return itm.assuranceHealthScore >= 0 && itm.assuranceHealthScore <= 3 });
    } else if(kpiObj.value==="GOOD" ){
        objs=goodDevices;//.filter(function(itm){ return itm.assuranceHealthScore > 7 });
    } else if(kpiObj.value==="POOR"){
        objs=issueDevices;
    } else if(kpiObj.value==="NA"){
        objs=[];   
    }
    return objs;
  }

  function updateNetworkParameters(objs, summaryData) {
    var categoryissues = {};
    categoryissues["CPU"] = summaryData.mappedIssues.cpu_issues;
    categoryissues["MEMORY"] = summaryData.mappedIssues.memory_issues;
    categoryissues["ERRORS"] = summaryData.mappedIssues.link_issues;
    categoryissues["CPCONECTIVITY"] = summaryData.mappedIssues.reachability_issues;
    categoryissues["UTILIZATION"] = summaryData.mappedIssues.utilization_issues;
    categoryissues["UPLINK"] = summaryData.mappedIssues.interface_issues;
    categoryissues["NOISE"] = summaryData.mappedIssues.noise_issues;

    Object.keys(categoryissues).forEach(function(key) {
        var deviceIssues = SimLokiDatabaseActions.getFilteredRecordHandler('issue', {'$and': 
            [ {'entityType':'Device'}, {'issueNamekey':{'$in':categoryissues[key]}} ] });
        for(var i=0; i<deviceIssues.length; i++){
            for(var j=0; j<objs.length; j++ ) {
                var isErr = objs[j].managementIpAddress===deviceIssues[i].entity || 
                    objs[j].macAddress.toUpperCase()===deviceIssues[i].entity;
                switch (key) {
                    case "CPU":
                        objs[j].cpu = isErr ? UtilityFunctions.getRandomIntForRangeValues(90,98) : UtilityFunctions.getRandomIntForRangeValues(60,75);
                        objs[j].cpuScore = isErr ? UtilityFunctions.getRandomIntForRangeValues(1,3) : UtilityFunctions.getRandomIntForRangeValues(7,10);
                        break;
                    case "MEMORY":
                        objs[j].memory = isErr ? UtilityFunctions.getRandomIntForRangeValues(92,97) : UtilityFunctions.getRandomIntForRangeValues(70,80);
                        objs[j].memoryScore = isErr ? UtilityFunctions.getRandomIntForRangeValues(1,3) : UtilityFunctions.getRandomIntForRangeValues(7,10);
                        break;
                    case "ERRORS":
                        objs[j].errorScore = isErr ? UtilityFunctions.getRandomIntForRangeValues(1,3) : UtilityFunctions.getRandomIntForRangeValues(7,10);
                        break;
                    case "CPCONECTIVITY":
                        objs[j].cpScore = isErr ? UtilityFunctions.getRandomIntForRangeValues(1,3) : UtilityFunctions.getRandomIntForRangeValues(7,10); //-1?
                        break;
                    case "UTILIZATION":
                        objs[j].slot0chUtil = isErr ? UtilityFunctions.getRandomIntForRangeValues(92,97) : UtilityFunctions.getRandomIntForRangeValues(30,50);
                        objs[j].band24chUtil = objs[j].slot0chUtil;
                        objs[j].slot1chUtil = isErr ? UtilityFunctions.getRandomIntForRangeValues(92,97) : UtilityFunctions.getRandomIntForRangeValues(30,50);
                        objs[j].band5chUtil = objs[j].slot1chUtil;
                        objs[j].utilizationScore = isErr ? UtilityFunctions.getRandomIntForRangeValues(1,3) : UtilityFunctions.getRandomIntForRangeValues(7,10);
                        break;
                    case "UPLINK":
                        objs[j].slot0chIntfr = isErr ? UtilityFunctions.getRandomIntForRangeValues(92,97) : UtilityFunctions.getRandomIntForRangeValues(30,50);
                        objs[j].band24chIntfr = objs[j].slot0chIntfr;
                        objs[j].slot1chIntfr = isErr ? UtilityFunctions.getRandomIntForRangeValues(92,97) : UtilityFunctions.getRandomIntForRangeValues(30,50);
                        objs[j].band5chIntfr = objs[j].slot1chIntfr;
                        objs[j].interferenceScore = isErr ? UtilityFunctions.getRandomIntForRangeValues(1,3) : UtilityFunctions.getRandomIntForRangeValues(7,10);
                        break;
                    case "NOISE":
                        objs[j].slot0chNs = isErr ? UtilityFunctions.getRandomIntForRangeValues(-75,-80) : UtilityFunctions.getRandomIntForRangeValues(-90,-95);
                        objs[j].band24chNs = objs[j].slot1chNs;
                        objs[j].slot1chNs = isErr ? UtilityFunctions.getRandomIntForRangeValues(-75,-80) : UtilityFunctions.getRandomIntForRangeValues(-90,-95);
                        objs[j].band5chNs = objs[j].slot1chNs;
                        break;
                    default:
                        break;
                }
            }
        }
    });
  }

  function updateResponses(objs) {
    var devices = [];
    for(var j=0; j < objs.length; j++) {
        let family = objs[j].family, t;
        switch (family.toUpperCase()) {
            case "UNIFIED AP":
                t = JSON.parse(JSON.stringify(NetworkDeviceHealthMetric.AP_TEMPLATE));
                t.interferenceScore = objs[j].interferenceScore;
                t.owningEntityId = objs[j].macAddress;
                var clients_2_4 = SimLokiDatabaseActions.getFilteredRecordHandler('host',{'$and': [ 
                    { 'connectedNetworkDeviceId':objs[j].id }, { 'frequency':'2.4' } ]});
                var clients_5_0 = SimLokiDatabaseActions.getFilteredRecordHandler('host',{'$and': [ 
                    { 'connectedNetworkDeviceId':objs[j].id }, { 'frequency':'5.0' } ]});
                var clients_6_0 = SimLokiDatabaseActions.getFilteredRecordHandler('host',{'$and': [ 
                    { 'connectedNetworkDeviceId':objs[j].id }, { 'frequency':'6.0' } ]});
                t.slot0clCount = clients_2_4.length;
                t.band24clCount = clients_2_4.length;
                t.slot1clCount = clients_5_0.length;
                t.band5clCount = clients_5_0.length;
                t.slot3clCount = clients_6_0.length;
                t.band6clCount = clients_6_0.length;

                // to Update Channel noise, Utilization and interface for 6GHZ
                t.slot0chUtil = t.slot0clCount>0 ? objs[j].slot0chUtil : 0;
                t.band24chUtil = t.slot0chUtil;
                t.slot1chUtil = t.slot1clCount>0 ? objs[j].slot1chUtil : 0;
                t.band5chUtil = t.slot1chUtil;

                t.slot0chNs = t.slot0clCount>0 ? objs[j].slot0chNs : -85.65;
                t.band24chNs = t.slot0chNs;
                t.slot1chNs = t.slot1clCount>0 ? objs[j].slot1chNs : -85.65;
                t.band5chNs = t.slot1chNs;

                t.slot0chIntfr = t.slot0clCount>0 ? objs[j].slot0chIntfr : 0;
                t.band24chIntfr = t.slot0chIntfr;
                t.slot1chIntfr = t.slot1clCount>0 ? objs[j].slot1chIntfr : 0;
                t.band5chIntfr = t.slot1chIntfr;
                
                t.utilizationScore = Math.floor((t.slot0chUtil+t.slot1chUtil)/10);
                break;
            case "WIRELESS CONTROLLER":
                t = JSON.parse(JSON.stringify(NetworkDeviceHealthMetric.WLC_TEMPLATE));
                t.owningEntityId = objs[j].managementIpAddress;
                t.clCount = 0;
                //get APs connected to the WLC
                var aps = getApsUnderWlc(objs[j].managementIpAddress);
                t.apCount = aps.length;
                aps.forEach(ap => {
                    var clients_2_4 = SimLokiDatabaseActions.getFilteredRecordHandler('host',{'$and': [ 
                        { 'connectedNetworkDeviceId':ap }, { 'frequency':'2.4' } ]});
                    var clients_5_0 = SimLokiDatabaseActions.getFilteredRecordHandler('host',{'$and': [ 
                        { 'connectedNetworkDeviceId':ap }, { 'frequency':'5.0' } ]});
                    var clients_6_0 = SimLokiDatabaseActions.getFilteredRecordHandler('host',{'$and': [ 
                        { 'connectedNetworkDeviceId':ap }, { 'frequency':'6.0' } ]});
                    t.clCount += clients_2_4.length + clients_5_0.length + clients_6_0.length;
                });
                t.upLinkScore = objs[j].cpScore;
                break;
            case "ROUTERS":
                //dont have a reference template, so using Switched & Hubs Template.
                t = JSON.parse(JSON.stringify(NetworkDeviceHealthMetric.SWITCHES_TEMPLATE));
                t.deviceFamily = 'Routers';
                t.family = 'ROUTERS';
                t.owningEntityId = objs[j].managementIpAddress;
                var interfaces = SimLokiDatabaseActions.getFilteredRecordHandler('device-interface',{'$and':
                    [ {'status':'up'}, {'deviceId':objs[j].id} ]});
                var interfacesUpStr = '';
                interfaces.forEach(intf => {
                    interfacesUpStr += intf.portName + ',';
                });
                t.upLink = interfacesUpStr.substring(0,interfacesUpStr.length-2);//exclude the last comma
                break;
            default:
                t = JSON.parse(JSON.stringify(NetworkDeviceHealthMetric.SWITCHES_TEMPLATE));
                t.owningEntityId = objs[j].managementIpAddress;
                var interfaces = SimLokiDatabaseActions.getFilteredRecordHandler('device-interface',{'$and':
                    [ {'status':'up'}, {'deviceId':objs[j].id} ]});
                var interfacesUpStr = '';
                interfaces.forEach(intf => {
                    interfacesUpStr += intf.portName + ',';
                });
                t.upLink = interfacesUpStr.substring(0,interfacesUpStr.length-2);//exclude the last comma
                break;
        }

        t.cpu = objs[j].cpu;
        t.memory = objs[j].memory;
        t.memoryScore = objs[j].memoryScore;
        t.cpuScore = objs[j].cpuScore;
        t.overallScore = objs[j].assuranceHealthScore;
        t.cpScore = objs[j].cpScore;
        t.uuid = objs[j].id;
        t.id = objs[j].id;
        t.deviceModelType = objs[j].type;
        t.nwDeviceType = objs[j].type;
        t.name = objs[j].hostname;
        t.siteUUID = objs[j].siteId;
        t.softwareVersion = objs[j].softwareVersion;
        t.osVersion = objs[j].softwareVersion;
        t._NODE_IDS_ = objs[j].id;//not clear of what value to be assigned
        t.deviceMacAddress = objs[j].macAddress;
        t.managementIpAddress = objs[j].managementIpAddress;
        t.healthScore[0].score = objs[j].assuranceHealthScore;
        if(objs[j].reachabilityStatus=="Reachable") {
            t.connectivityStatus = 100;
            if(family.toUpperCase()=="UNIFIED AP")
                t.reachability = "UP";
            else
                t.reachability = "REACHABLE";
        } else {
            t.connectivityStatus = 0;
            if(family.toUpperCase()=="UNIFIED AP")
                t.reachability = "DOWN";
            else
                t.reachability = "UNREACHABLE";
        }

        var fRole = objs[j].role;
        if(Array.isArray(fRole)) {
            fRole = UtilityFunctions.convertArrayToCommaSeperated(fRole);
        }
        t.fabricRole = fRole.replace(/,/g, ':');
        t.fabricRole = t.fabricRole.replace('ACCESS', 'EDGE');
        t.fabricRole = t.fabricRole.replace('CP', 'MAP-SERVER');
        if(t.family != "UNIFIED_AP" && t.fabricRole != "-1") {
            t.fabricDomainName = "SanJose_Fabric";
            t.fabricName = "Global/SanJose_Fabric";
        } else {
            t.fabricDomainName = "-1";
            t.fabricName = "-1";
        }
        t.deviceModel = objs[j].platformId;
        t.platformId = objs[j].platformId;
        t.location = getGroupNameHierarchy(objs[j].siteId);
        t.siteHierarchy = getGroupNameHierarchy(objs[j].siteId);
        var siteRec = SimLokiDatabaseActions.getFilteredRecordHandler('site', {'id':objs[j].siteId});
        var biuldngId = siteRec[0].additionalInfo.map(e => {
            if(e.nameSpace == 'Location' && e.attributes.type == "building"){
                return siteRec[0].id
            }
        }).filter(f => f!== undefined)
        t.parentSiteId = siteRec[0].parentId;
        t.buildingId = (biuldngId[0] != undefined) ? biuldngId[0] : ""
        siteRec = SimLokiDatabaseActions.getFilteredRecordHandler('site', {'id':t.parentSiteId});
        t.ancestorSiteId = siteRec[0].parentId;
        t.roleSource = objs[j].role;
        t.deviceRole = objs[j].role;
        t.modifiedTimestamp = UtilityFunctions.getTimeStamp();
        var issuecount = SimLokiDatabaseActions.getFilteredRecordHandler('issue', {'deviceName': objs[j].hostname});
        t.issueCount = issuecount.length;//SimLokiDatabaseActions.getFilteredRecordHandler('issue', {'deviceName': objs[j].hostname});
        if(t.memory) t.allMetricValue = t.allMetricValue.replace('$memory',t.memory);
        if(t.cpu) t.allMetricValue = t.allMetricValue.replace('$cpu',t.cpu);
        if(t.band24chNs) t.allMetricValue = t.allMetricValue.replace('$noise_24',t.band24chNs);
        if(t.band5chNs) t.allMetricValue = t.allMetricValue.replace('$noise5',t.band5chNs);
        if(t.band24chUtil) t.allMetricValue = t.allMetricValue.replace('$util24',t.band24chUtil);
        if(t.band5chUtil) t.allMetricValue = t.allMetricValue.replace('$util5',t.band5chUtil);
        if(t.band24chIntfr) t.allMetricValue = t.allMetricValue.replace('$int24',t.band24chIntfr);
        if(t.band5chIntfr) t.allMetricValue = t.allMetricValue.replace('$int5',t.band5chIntfr);
          
        devices.push(t);
    }
    return devices;
  }

  function calculateInsight(field, records) {
      var insightSum = 0;
      if(field == "airTimeEfficiency") {
          records.forEach(record => {
              insightSum += ((record.axATE - record.nonAxATE)*100/record.nonAxATE);
          });
      } else {
          records.forEach(record => {
              insightSum += ((record.nonAxLatency - record.axLatency)*100/record.axLatency);
          });
      }
      return (insightSum/records.length).toFixed(2);
  }

  function generateWifi6TrendData(data, startTime, endTime) {
      var timeWindow = 300000;//5min
      if(endTime-startTime > (24*60*60*1000)) {/*7days - making it 10 min*/ timeWindow = timeWindow*2}
      var len = Math.floor((endTime-startTime)/timeWindow);
      while(data.records.length < len) {
          data.records = data.records.concat(data.records);//records need to exceed the data in template - repeat content.
      }
      data = JSON.parse(JSON.stringify(data));
      for (var i=0; i<len; i++) {
          data.records[i].modificationtime = startTime;
          data.records[i].timestamp = UtilityFunctions.getGivenTimeInGivenFormat(startTime, 'YYYY-MM-DDTHH:mm:ss.SSSZZ');
          startTime += timeWindow;
      }
      data.records.splice(i, data.records.length);//extras to be removed.
      return data;
  }

  function wifiRadioAnalyticsSelection(type, category, restPayload, offset, limit) {
      var { accessCategory, band, wifi6Enabled, name, clientCountMin, clientCountMax } = restPayload.filters;
      var { sites } = restPayload;
      clientCountMin = parseInt(clientCountMin), clientCountMax = parseInt(clientCountMax);
      var fieldToRead = category=='airTime' ? "ATE" : "Latency";
      fieldToRead = (wifi6Enabled ? "ax" : "nonAx") + fieldToRead;
      var dataRef = 10, fieldToPopulate, resp = [], prefix;
      if(accessCategory=="voice") prefix = "vo"
      else if(accessCategory=="video") prefix = "vd"
      else if(accessCategory=="all") prefix = ""
      else prefix = accessCategory;//be, bg

      if(category == 'airTime') {
          if(accessCategory=="voice") dataRef = Wifi6Template.WIFI6_VOICE_AIRTIME_EFFICIENCY.records[0][fieldToRead];
          if(accessCategory=="video") dataRef = Wifi6Template.WIFI6_VIDEO_AIRTIME_EFFICIENCY.records[0][fieldToRead];
          if(accessCategory=="bg") dataRef = Wifi6Template.WIFI6_BACKGROUND_AIRTIME_EFFICIENCY.records[0][fieldToRead];
          if(accessCategory=="be") dataRef = Wifi6Template.WIFI6_BEST_EFFORTS_AIRTIME_EFFICIENCY.records[0][fieldToRead];
          if(accessCategory=="all") dataRef = Wifi6Template.WIFI6_ALL_AIRTIME_EFFICIENCY.records[0][fieldToRead];
      }

      var devices = SimLokiDatabaseActions.getFilteredRecordHandler('network-device', {'family':'Unified AP'});
      if(!isNaN(clientCountMin) && !isNaN(clientCountMax)) {
          var devIds = [];
          var hosts = SimLokiDatabaseActions.getFilteredRecordHandler('host');
          var maxClientCountAp = {};
          hosts.forEach(host => {
              var devId = host.connectedNetworkDeviceId;
              maxClientCountAp[devId] = maxClientCountAp[devId]==undefined ? 0 : ++maxClientCountAp[devId];
          });
          Object.keys(maxClientCountAp).forEach(devId => {
              if(maxClientCountAp[devId] > clientCountMin && maxClientCountAp[devId] < clientCountMax) devIds.push(devId);
          });
          devIds = [...new Set(devIds)];
          devices = devices.filter(e => devIds.indexOf(e.id)>-1 );
      }
      
      if(wifi6Enabled) {
          devices = devices.filter( e => e.protocol_wifi6 == "5" );
      } else {
          devices = devices.filter( e => e.protocol_wifi6 != "5" ); 
      }
      if(band && band.length>0 && band[0]!="") {
          var isBand2_4 = band.indexOf(2.4)>-1; 
          var isBand5 = band.indexOf(5)>-1;
          var temp = [];
          devices.forEach(e => {
              if(isBand2_4 && (e.slot_wifi6 == "0" || e.slot_wifi6 == "2")) temp.push(e);
              if(isBand5 && e.slot_wifi6 != "0") temp.push(e);
          });
          devices = temp;
      }
      if(name && name.length>0 && name[0]!="") {
          var temp = [];
          name.forEach(filterName => {
              devices.forEach(e => {
                  if(e.hostname == filterName) temp.push(e);
              });
          });
          devices = temp;
      }
      if(sites && sites.length>0 && sites[0]!="") {
          var temp = [];
          sites.forEach(siteFilter => {
              devices.forEach(e => {
                  if(e.siteId == siteFilter) temp.push(e);
              });
          });
          devices = temp;
      }

      var latRecLen = Wifi6Template.WIFI6_VOICE_LATENCY_BY_CLIENT_COUNT.records.length;//all latencies have same bucket lengths
      devices.forEach(device => {
          var obj = {}, isDuplicate = false, idx = 0;
          var totalClients = (SimLokiDatabaseActions.getFilteredRecordHandler('host', {'connectedNetworkDeviceId':device.id})).length;
          if(category == 'Latency') {
              idx = UtilityFunctions.getRandomIntForRangeValues(0, latRecLen -1);
              if(accessCategory=="voice") dataRef = Wifi6Template.WIFI6_VOICE_LATENCY_BY_CLIENT_COUNT.records[idx][fieldToRead];
              if(accessCategory=="video") dataRef = Wifi6Template.WIFI6_VIDEO_LATENCY_BY_CLIENT_COUNT.records[idx][fieldToRead];
              if(accessCategory=="bg") dataRef = Wifi6Template.WIFI6_BG_LATENCY_BY_CLIENT_COUNT.records[idx][fieldToRead];
              if(accessCategory=="be") dataRef = Wifi6Template.WIFI6_BE_LATENCY_BY_CLIENT_COUNT.records[idx][fieldToRead];
          }

          var randVal = parseFloat(UtilityFunctions.getRandomFloatForRangeValues(dataRef/2, dataRef, 5));
          switch (type) {
              case "airTimeTopLocation":
                  isDuplicate = (resp.filter( r => r.floorId==device.siteId)).length > 0 ? true : false;
                  obj = {"floorId":device.siteId, "airTimeEfficiency":randVal, "siteHierarchy":"", "latency":0.0};
                  break;
              case "airTimeTopAP":
                  //devices are unique, so no check on duplicay performed
                  obj = {"airTimeEfficiency":randVal, "latency":0.0, "name":device.hostname};
                  break; 
              case "airTimeTopBand":
                  obj = {"airTimeEfficiency":randVal, "latency":0.0, "band":""};
                  obj.band = device.slot_wifi6 == "0" ? "2.4" : "5";//for now, not checking slot of 2, -1.
                  isDuplicate = (resp.filter( r => r.band==obj.band)).length > 0 ? true : false;
                  break; 
              case "latencyTopLocation":
                  isDuplicate = (resp.filter( r => r.floorId==device.siteId)).length > 0 ? true : false;
                  obj = {"floorId":device.siteId, "airTimeEfficiency":0.0, "siteHierarchy":"", "latency":randVal};
                  break;
              case "latencyTopAP":
                  //devices are unique, so no check on duplicay performed
                  obj = {"airTimeEfficiency":0.0, "latency":randVal, "name":device.hostname};
                  break; 
              case "latencyTopBand":
                  obj = {"airTimeEfficiency":0.0, "latency":randVal, "band":""};
                  obj.band = device.slot_wifi6 == "0" ? "2.4" : "5";//for now, not checking slot of 2, -1.
                  isDuplicate = (resp.filter( r => r.band==obj.band)).length > 0 ? true : false;
                  break; 
              default://radioList
                  var tempId = UtilityFunctions.generate_uuid();
                  var wlcDev = SimLokiDatabaseActions.getFilteredRecordHandler('network-device', {'managementIpAddress':device.associatedWlcIp});
                  var maxProtocol = device.protocol_wifi6;
                  maxProtocol = maxProtocol=="5" ? "4" : (maxProtocol=="4" ? maxProtocol="3" : (maxProtocol=="0" ? "-1" : maxProtocol));
                  maxProtocol = parseInt(maxProtocol);
                  obj = {"wifi6Status":device.wifi6Status, "wlcUuid":wlcDev[0].id, "slot": device.slot_wifi6, "uuid":device.id,
                    "osVer":device.softwareVersion, "floorId":device.siteId, "maxProtocol": maxProtocol, "wlcName":wlcDev[0].hostname,
                    "bldgId":tempId, "areaId":tempId, "siteHierarchy":"", "apModel":device.platformId, "name":device.hostname, 
                    "apIp":device.managementIpAddress, "band":""};
                  obj.band = device.slot_wifi6 == "0" ? "2.4" : "5";//for now, not checking slot of 2, -1.
                  if(category=="airTime") {
                      if(restPayload.filters.wifi6Enabled) {
                          fieldToPopulate = accessCategory=="all" ? "ateAx" : prefix+"AteAx";
                      } else {
                          fieldToPopulate = accessCategory=="all" ? "ateNonax" : prefix+"AteNonax";
                      }
                  } else if(category=="Latency") {
                      if(restPayload.filters.wifi6Enabled) {
                          fieldToPopulate = prefix+"LatAx";
                          obj[prefix+"ClcntAx"] = totalClients;
                      } else {
                          fieldToPopulate = prefix+"LatNonax";
                          obj[prefix+"ClcntNonax"] = totalClients;
                      }                      
                  }
                  obj[fieldToPopulate] = randVal;                  
                  break;
          }
          if(obj.floorId) {
              var siteObj = SimLokiDatabaseActions.getFilteredRecordHandler('site', {'id':obj.floorId});
              obj.siteHierarchy = siteObj[0].groupNameHierarchy;
          }
          if(isDuplicate) return; //go for next iteration
          resp.push(obj);
      });
      //sort and limit
      if(accessCategory!='radioList') {
          var sortBy = category=="airTime" ? "airTimeEfficiency" : "latency";
          resp = UtilityFunctions.decorateData( {'sortBy':sortBy, 'order':'desc'}, resp);
      }
      resp = UtilityFunctions.filterBasedOnOffset(offset, limit, resp);
      return resp;
  }

  function getBucketViseDetails(band) {
      var hosts = SimLokiDatabaseActions.getFilteredRecordHandler('host');
      var maxClientCountAp = {};
      hosts.forEach(host => {
          var devId = host.connectedNetworkDeviceId;
          maxClientCountAp[devId] = maxClientCountAp[devId]==undefined ? 0 : ++maxClientCountAp[devId];
      });
      var devices = SimLokiDatabaseActions.getFilteredRecordHandler('network-device', {'$and':[{'family':'Unified AP'},
        {'id':{'$in':Object.keys(maxClientCountAp)}} ]} );
      if(band!=undefined) {
          devices = devices.filter(e=> {
              if(band=="2.4") return e.slot_wifi6=="0" || e.slot_wifi6=="2"
              else if(band=="5") return e.slot_wifi6!="0";
          });
      }
      var bucketVice = {
          'upto10': {'isWifi6Present':false,'isNonWifi6Present':false},
          'upto20': {'isWifi6Present':false,'isNonWifi6Present':false},
          'upto30': {'isWifi6Present':false,'isNonWifi6Present':false},
          'upto40': {'isWifi6Present':false,'isNonWifi6Present':false},
          'upto50': {'isWifi6Present':false,'isNonWifi6Present':false},
          'above50': {'isWifi6Present':false,'isNonWifi6Present':false}
      };
      devices.forEach(dev => {
          if(dev.protocol_wifi6 == "5") {
              if(maxClientCountAp[dev.id] > 50) bucketVice['above50'].isWifi6Present = true;
              if(maxClientCountAp[dev.id] > 40) bucketVice['upto50'].isWifi6Present = true;
              if(maxClientCountAp[dev.id] > 30) bucketVice['upto40'].isWifi6Present = true;
              if(maxClientCountAp[dev.id] > 20) bucketVice['upto30'].isWifi6Present = true;
              if(maxClientCountAp[dev.id] > 10) bucketVice['upto20'].isWifi6Present = true;
              if(maxClientCountAp[dev.id] > 0) bucketVice['upto10'].isWifi6Present = true;
          } else {
              if(maxClientCountAp[dev.id] > 50) bucketVice['above50'].isNonWifi6Present = true;
              if(maxClientCountAp[dev.id] > 40) bucketVice['upto50'].isNonWifi6Present = true;
              if(maxClientCountAp[dev.id] > 30) bucketVice['upto40'].isNonWifi6Present = true;
              if(maxClientCountAp[dev.id] > 20) bucketVice['upto30'].isNonWifi6Present = true;
              if(maxClientCountAp[dev.id] > 10) bucketVice['upto20'].isNonWifi6Present = true;
              if(maxClientCountAp[dev.id] > 0) bucketVice['upto10'].isNonWifi6Present = true;
          }
      });
      return bucketVice;
  }

  function updateResponseFormat(data) {
      return {"version":"1.0", "response":data};
  }

  return {
          getDeviceUpTime: function(urlAction) {
              DeviceUpTimeTemplate.Device_UpTime_Template.response[0].startTime = urlAction.filter['startTime'];
              DeviceUpTimeTemplate.Device_UpTime_Template.response[0].endTime = urlAction.filter['endTime'];
              return DeviceUpTimeTemplate.Device_UpTime_Template;
          },

          getApIntfUtilDetails: function(urlAction){ 
            let macAddr =  urlAction.restPayload.apMac
            let deviceInfo = SimLokiDatabaseActions.getFilteredRecordHandler('network-device',{'macAddress':macAddr});            
            let tJson = JSON.parse(JSON.stringify(InterfaceConnectivityTemplate.apInterfaceUtilDetails))        
            let avgRxRate = UtilityFunctions.getRandomFloatForRangeValues(760,900)
            let avgTxRate = UtilityFunctions.getRandomFloatForRangeValues(13000,15000)
            let avgTotalRate = avgRxRate+avgTxRate
            tJson.response[0].avgRxRate = `${avgRxRate}`
            tJson.response[0].avgTxRate = `${avgTxRate}`
            tJson.response[0].avgTotalRate = `${avgTotalRate}`
            return tJson
          },

          getSysUptimeData : function(urlAction) {

            var DeviceId = urlAction.filter['nwDeviceId'].trim();
            var dataObj = JSON.parse(JSON.stringify( AssuranceNetworkDeviceDetailTemplate.NetworkDevice_SysUpTime_Template ));
            var devRecord = SimLokiDatabaseActions.getFilteredRecordHandler('network-device', {'id' : DeviceId });
            dataObj[0].sysUptime[0] = devRecord[0].upTime ;
            dataObj[0].id = DeviceId;
            dataObj[0].pmtimestamp = UtilityFunctions.getTimeStamp();
            return dataObj;
          },

          getUpTimeDetails : function (urlAction) {
            let devId = urlAction.filter['nwDeviceId'];
            let devRecord = SimLokiDatabaseActions.getFilteredRecordHandler('network-device', {'id' : devId });
            let dataObj = JSON.parse(JSON.stringify(DeviceUpTimeTemplate.wlc_upTime_Template));
            dataObj[0].nwDeviceFamily = [devRecord[0].family];
            let time = devRecord[0].uptimeSeconds;            
            let upTime = Math.floor((new Date().getTime())/1000)-time
            dataObj[0].upTime = [`${upTime}`]
            time = time*1000
            let up_Time = new Date().getTime()-time
            dataObj[0].lastBootTime = [up_Time]
            return dataObj;
          },

          getLocationDetails : function (urlAction) {
            let devId = urlAction.filter['nwDeviceId'];
            let devRecord = SimLokiDatabaseActions.getFilteredRecordHandler('network-device', {'id' : devId });
            let siteRec = SimLokiDatabaseActions.getFilteredRecordHandler('site', {'id' : devRecord[0].siteId });
            let dataObj = JSON.parse(JSON.stringify(DeviceUpTimeTemplate.device_location_details));
            dataObj[0].siteHierarchy = [siteRec[0].groupNameHierarchy]
            dataObj[0].latitude = [siteRec[0].additionalInfo[1].attributes.latitude]
            dataObj[0].name = [siteRec[0].name]
            dataObj[0].longitude = [siteRec[0].additionalInfo[1].attributes.longitude]
            dataObj[0].siteType = [siteRec[0].additionalInfo[1].attributes.type]
            return dataObj;
          },

          getDeviceDetails: function(urlAction){
              var tId = urlAction.action.id;
              var data = SimLokiDatabaseActions.getFilteredRecordHandler('network-device', {'id' : tId });
              if(data && data.length){
                  var obj =  JSON.parse(JSON.stringify( DeviceDetail.Device_Template ));
                  obj.devices[0].id = data[0].id;
                  obj.devices[0].name = data[0].name;
                  obj.devices[0].upTime = data[0].upTime;
                  obj.devices[0].macAddress = data[0].macAddress;
                  obj.devices[0].managementIpAddr = data[0].managementIpAddress;
                  obj.devices[0].nwDeviceId = data[0].nwDeviceId;
                  return obj;
              }
              return {"devices":[]};
         },

         getInterfaceMetricDetail: function(urlAction){
             var data = JSON.parse(JSON.stringify(InterfaceConnectivityTemplate.MetricData));
             var urlDtls = urlAction.url.split("/");
             var deviceId = urlDtls.length>5?urlDtls[5]:undefined;
             var deviceDetail = undefined;
             var role = "DEFAULT";
             if(deviceId) {
                deviceDetail = SimLokiDatabaseActions.getFilteredRecordHandler('network-device', {'id' : deviceId });
                if(deviceDetail && deviceDetail.length){
                    data.response.entityId = deviceId;
                    data.response.entityType = deviceDetail[0].type;
                    if(deviceDetail[0].role == "BORDER ROUTER") {
                        role = deviceDetail[0].role;
                    }
                }
             }
             data.response.startTime = urlAction.filter['startTime'];
             data.response.endTime = urlAction.filter['endTime'];
             var page = urlAction.filter['page'];

             var cachedData = SimAssuranceCacheData.getCacheData(urlAction);
             if(urlAction.url.indexOf('avgOutputUtil') >= 0){
                 for(var i=0;cachedData.interfaces[role] && i<cachedData.interfaces[role].length; i++) {
                     var tObj = {};
                     tObj.interfaceName = cachedData.interfaces[role][i];
                     var val = cachedData.interfaceMetricData[tObj.interfaceName];
                     tObj.MAX = val==undefined?0:val.max.tx;
                     tObj.AVG = val==undefined?0:val.avg.tx;
                     data.response.data.push(tObj);
                 }
             }
             if(urlAction.url.indexOf('avgOutputErrors') >= 0){
                for(var i=0;cachedData.interfaces[role] && i<cachedData.interfaces[role].length; i++) {
                    var tObj = {};
                    tObj.interfaceName = cachedData.interfaces[role][i];
                    var val = cachedData.interfaceMetricData[tObj.interfaceName];
                    tObj.MAX = val==undefined?0:val.max.txErr;
                    tObj.AVG = val==undefined?0:val.avg.txErr;
                    data.response.data.push(tObj);
                }
             }
             else if(urlAction.url.indexOf('avgInputErrors') >= 0){
                for(var i=0;cachedData.interfaces[role] && i<cachedData.interfaces[role].length; i++) {
                    var tObj = {};
                    tObj.interfaceName = cachedData.interfaces[role][i];
                    var val = cachedData.interfaceMetricData[tObj.interfaceName];
                    tObj.MAX = val==undefined?0:val.max.rxErr;
                    tObj.AVG = val==undefined?0:val.avg.rxErr;
                    data.response.data.push(tObj);
                }
             }
             else if(urlAction.url.indexOf('avgInputUtil') >= 0){
                for(var i=0;cachedData.interfaces[role] && i<cachedData.interfaces[role].length; i++) {
                    var tObj = {};
                    tObj.interfaceName = cachedData.interfaces[role][i];
                    var val = cachedData.interfaceMetricData[tObj.interfaceName];
                    tObj.MAX = val==undefined?0:val.max.rx;
                    tObj.AVG = val==undefined?0:val.avg.rx;
                    data.response.data.push(tObj);
                }
             }

             return data;
         },

          getInterfaceStatus: function(urlAction) {
             //if the logic is changed here, please update the same in interfaceStatus method of SimAssuranceMetricUtility too..
             var cachedData = SimAssuranceCacheData.getCacheData(urlAction);
             var urlDtls = urlAction.url.split("/");
             var deviceId = urlDtls.length>5?urlDtls[5]:undefined;
             var role = "DEFAULT";
             var ifType = ["L3","L2"];
             var ifAvail = [100,100];
             var data = [];

             var deviceDetail = SimLokiDatabaseActions.getFilteredRecordHandler('network-device', {'id':deviceId});
             var cat9kTypes = ["cisco catalyst 9200 switch stack", "cisco catalyst 9300 switch", "cisco catalyst 9404r switch", "cisco catalyst 9500 switch"];
             if(deviceDetail.length>0 && cat9kTypes.indexOf(deviceDetail[0].type) > -1) {
                role = "CAT 9K";
             }

             if(deviceId) {
                for(var i=0;cachedData.interfaces[role] && i<cachedData.interfaces[role].length; i++) {
                    var tObj = {};
                    tObj.interfaceName = cachedData.interfaces[role][i];
                    tObj.interfaceRole = "";
                    tObj.interfaceAvailablity = (i%2== 0)?ifAvail[0]:ifAvail[1];
                    tObj.interfaceType = (i<(cachedData.interfaces[role].length)/2)?ifType[0]:ifType[1];
                    data.push(tObj);
                }
             }
             return {"version":"1.0", "response":data};
         },

         getScoreForAllNetworkDevices: function(urlAction){
          var data = SimLokiDatabaseActions.getAll('network-device');
          var tResult = [];
          if(data && data.length){
              for(var i =0; i < data.length; i++) {
                  var obj =  JSON.parse(JSON.stringify(NetworkDeviceTopologyScoreTemplate.NetworkDevice_Score_Template ));
                  obj.device.id = data[i].id;
                  obj.device.properties.nwDeviceRole[0].value = data[i].role;
                  obj.device.properties.managementIpAddr[0].value = data[i].managementIpAddress;
                  obj.device.properties.nwDeviceFamily[0].value = data[i].family;
                  obj.device.properties.macAddress[0].value = data[i].macAddress;
                  obj.device.properties.nwDeviceName[0].value = data[i].hostname;
                  obj.device.properties.platformId[0].value = data[i].platformId;
                  obj.device.properties.softwareVersion[0].value = data[i].softwareVersion;
                  obj.device.properties.nwDeviceId[0].value = data[i].id;
                  obj.device.properties.nwDeviceType[0].value = data[i].type;
                  //obj.score = data[i].healthScore;
                  obj.score = data[i].assuranceHealthScore;

                  tResult.push(obj);
              }
          }
          return tResult;
      },

      getScoreForAPNetworkDevices: function(urlAction) {

       var deviceId =urlAction.filter["nwDeviceId"];

        var WlcDevice = SimLokiDatabaseActions.getFilteredRecordHandler('network-device',{'id':deviceId});

        //Getting IP Addresss
        var data = SimLokiDatabaseActions.getFilteredRecordHandler('network-device',{'associatedWlcIp':WlcDevice[0].managementIpAddress});
          var tResult = [];
          if(data && data.length){
              for(var i =0; i < data.length; i++) {
                  var obj =  JSON.parse(JSON.stringify(NetworkDeviceTopologyScoreTemplate.NetworkDevice_Score_Template ));
                  obj.device.id = data[i].id;
                  obj.device.properties.nwDeviceRole[0].value = data[i].role;
                  obj.device.properties.managementIpAddr[0].value = data[i].managementIpAddress;
                  obj.device.properties.nwDeviceFamily[0].value = data[i].family;
                  obj.device.properties.macAddress[0].value = data[i].macAddress;
                  obj.device.properties.nwDeviceName[0].value = data[i].hostname;
                  obj.device.properties.platformId[0].value = data[i].platformId;
                  obj.device.properties.softwareVersion[0].value = data[i].softwareVersion;
                  obj.device.properties.nwDeviceId[0].value = data[i].id;
                  obj.device.properties.nwDeviceType[0].value = data[i].type;
                  //obj.score = data[i].healthScore;
                  obj.score = [data[i].assuranceHealthScore];

                  tResult.push(obj);
              }
          }
         // Addded this check, In WLC 360, in topology AP count displayed as Object.
          if(urlAction.action.count){
              var count=[];
              count[0]=tResult.length;
              return count;
          }
        return tResult;
      },

      getHostDetails:function(urlAction) {
          var frequency = '5.0';
          if(urlAction.filter["band"]=="2.4") {
              frequency ="2.4";
          }

          var result= SimLokiDatabaseActions.getFilteredRecordHandler('host',{'$and': [ {
                          'connectedNetworkDeviceId': urlAction.action.id}, { 'frequency':frequency}]});
          return {  "version": "1.0", "response":result,"page": {"pageSize": 6,"offset": 1,"totalCount": result.length}};
      },
    getNetworkDeviceHealthSummary: function(urlAction) {
        var t1 = JSON.parse(JSON.stringify(NetworkDeviceHealthSummary_Global.HealthSummary_Template));

        var startTime = parseInt(urlAction.filter['startTime']);
        var endTime = parseInt(urlAction.filter['endTime']);

        var fabricName = '', tSites = [];
        var tEntity = null;

        if(urlAction.filter['measureBy'] != undefined) {
            if(urlAction.filter['measureBy'] == 'fabric') {
                fabricName = urlAction.restPayload.fabrics[0];
                t1.latestMeasuredByEntity = fabricName;
                t1.measuredBy = 'fabric';
                tEntity = fabricName;
            } else if(urlAction.filter['measureBy'] == 'sites') {
                tSites = urlAction.restPayload.sites;
            }
        }
        var filter = { measureBy: urlAction.filter['measureBy'], fabricName : fabricName, sites: tSites,
                       reloadNeeded:true};
        SimAssuranceCacheData.buildAssuranceCache(startTime, endTime, filter);

        var summaryData = SimAssuranceCacheData.getCacheData(urlAction);
        t1.monitoredDevices = summaryData.monitoredDevices;
        t1.monitoredHealthyDevices =summaryData.overallGoodCount;
        t1.monitoredUnHealthyDevices =summaryData.overallBadCount+summaryData.overallFairCount;
        t1.latestHealthScore = summaryData.latestHealthScore;

        for(var i = 0; i < deviceRoles.length; i++) {
            var tRole =  deviceRoles[i].toUpperCase();
            if(tRole == "CONTROL PLANE") {
                tRole = "CP";
            }
            var tRoleSummary = summaryData[ tRole ];
            var obj = {"category":deviceRoles[i],"totalCount":0,"healthScore":0,"goodPercentage":0,"badPercentage":0,"fairPercentage":0,"kpiMetrics": []};

            if(tRoleSummary != undefined) {
                obj.totalCount = tRoleSummary.TotalCount;
                obj.badCount = tRoleSummary.BadCount;
                obj.fairCount = tRoleSummary.FairCount;
                obj.goodCount = tRoleSummary.GoodCount;
                obj.healthScore =  tRoleSummary.Score;
                obj.goodPercentage =  tRoleSummary.goodPercentage;
                obj.badPercentage =  tRoleSummary.badPercentage;
                obj.fairPercentage =  tRoleSummary.fairPercentage;

                if(deviceRoles[i]=="Access" && tRoleSummary.BadCount>0) {
                 obj.kpiMetrics.push({"key": "ERRORS","value": "POOR"});
                 obj.kpiMetrics.push({"key": "MEMORY","value": "POOR"});
                }
                if(deviceRoles[i]=="AP" && tRoleSummary.BadCount>0) {
                 obj.kpiMetrics.push({"key": "NOISE","value": "FAIR"});
                 obj.kpiMetrics.push({"key": "INTERFERENCE","value": "FAIR"});
                 obj.kpiMetrics.push({"key": "UTILIZATION","value": "POOR"});
                }

                t1.healthDistirubution.push(obj);
            } else {
                t1.healthDistirubution.push(obj);
            }
        }

        var tScore =  summaryData.latestHealthScore;
      //  var curTime = UtilityFunctions.getCurrentDateAndTimeInValidFormat();
        //var score = {time:"2018-01-16T07:35:00.000+0000",healthScore:tScore,entity: tEntity};
      //  var score = {time:curTime,healthScore:tScore,entity: tEntity};
      //  t1.response.push(score);

      //  return t1;
      //Chnaged for 1.2.4
      return generateData(urlAction, t1,summaryData);
    },

    getNetworkDeviceHealthMetric: function(urlAction) {
        var healthStatus = urlAction.restPayload.filters.healthStatus;
        //var tRole = urlAction.restPayload.role;
        var tRole = getRole(urlAction);
        var healthMetrics = urlAction.restPayload.filters.healthMetrics;
        var communicationState = urlAction.restPayload.communicationState;

        var summaryData = SimAssuranceCacheData.getCacheData(urlAction), objs, count, bIgnoreFilter = false;

        if(tRole != undefined && tRole != '' && communicationState == undefined) {
            if(tRole == 'WIRELESS') {
                objs = summaryData.network_devices.filter(function(itm){ return itm.family == 'Wireless Controller' || itm.family == 'Unified AP'; });
            } else if(tRole == 'ACCESS') {
                //objs = summaryData.network_devices.filter(function(itm){ return itm.role == 'ACCESS' && itm.family != 'Wireless Controller' && itm.family != 'Unified AP' && itm.family != 'Wireless Sensor'; });
                //objs = summaryData.network_devices.filter(function(itm){ return itm.role == 'ACCESS' && itm.family != 'Wireless Controller' && itm.family != 'Unified AP'; });
                objs = summaryData.network_devices.filter(function(itm){
                           return (Array.isArray(itm.role) &&  itm.role.indexOf('ACCESSS') >= 0) ||
                                  itm.role == 'ACCESS';});
                // this is added for fabric ap is shown as access device
                if(urlAction.restPayload.fabricRole == undefined) {
                    objs = objs.filter(function(itm){ return itm.family != 'Wireless Controller' &&
                                                      itm.family != 'Unified AP'; });
                }
            } else if(tRole == 'WIRELESS CONTROLLER') {
                objs = summaryData.network_devices.filter(function(itm){ return itm.family == "Wireless Controller"; });
            } else if(tRole == 'UNIFIED AP') {
                objs = summaryData.network_devices.filter(function(itm){ return itm.family == "Unified AP"; });
            } else {
                //objs = summaryData.network_devices.filter(function(itm){ return itm.role == tRole; });
                objs = summaryData.network_devices.filter(function(itm){
                           return (Array.isArray(itm.role) &&  itm.role.indexOf(tRole) >= 0) ||
                                  itm.role == tRole;});
            }
        } else if(urlAction.restPayload.deviceFamily!= undefined ) {

             objs = summaryData.network_devices.filter(function(itm){ return itm.family == urlAction.restPayload.deviceFamily });

        } else {
            objs =  summaryData.network_devices;
        }

        if(urlAction.restPayload.deviceFamily == "Unified AP") {
            //bIgnoreFilter = true;
            if(urlAction.restPayload.connectivityStatus == "up" && (healthStatus=="monitored" || healthStatus=="all")) {
                //objs = summaryData.network_devices.filter(function(itm){ return itm.family == "Unified AP" && itm.reachabilityStatus=="Reachable"; });
                objs = summaryData.network_devices.filter(function(itm){ return itm.family == "Unified AP" && ((itm.reachabilityStatus=="Reachable") || itm.assuranceHealthScore > 3) });//this is a temp fix for handling ap4800 in 1.3dnac- to be corrected later
            } else if(urlAction.restPayload.connectivityStatus == "downAndNoData" ) {
                //objs = summaryData.network_devices.filter(function(itm){ return itm.family == "Unified AP" && itm.reachabilityStatus=="UnReachable"; });
                objs = summaryData.network_devices.filter(function(itm){ return itm.family == "Unified AP" && itm.assuranceHealthScore >= 1 && itm.assuranceHealthScore <= 3 });
            }else if(urlAction.restPayload.connectivityStatus == "down" ) {
                //objs = summaryData.network_devices.filter(function(itm){ return itm.family == "Unified AP" && itm.reachabilityStatus=="UnReachable"; });
                objs = summaryData.network_devices.filter(function(itm){ return itm.family == "Unified AP" && itm.assuranceHealthScore >= 1 && itm.assuranceHealthScore <= 3 });
            } else if(healthStatus=="unmonitored") {
               objs = [];
            } else {
               // objs = [];
               // Added for AP filter
                tObj = getMinMax(healthMetrics);
                if(tObj != undefined) {
                    var min = tObj.min, max = tObj.max;
                    objs = objs.filter(function(itm){return itm.assuranceHealthScore >= min && itm.assuranceHealthScore <= max && itm.family == "Unified AP";});
                }
            }
        }

        var bKpiMetrics = false;
        if(bIgnoreFilter == false) {
            if(healthMetrics == undefined && urlAction.restPayload.filters != undefined &&
                urlAction.restPayload.filters.kpiMetrics != undefined &&
                urlAction.restPayload.filters.kpiMetrics.length) {
                tObj = getMinMax( [{key: "overallScore", value: urlAction.restPayload.filters.kpiMetrics[0].value}] );
                bKpiMetrics = true;
            } else {
                tObj = getMinMax(healthMetrics);
            }
            //var tObj = getMinMax(healthMetrics);

            if(tObj != undefined && bKpiMetrics==false) {
                var min = tObj.min, max = tObj.max;
                objs = objs.filter(function(itm){return itm.assuranceHealthScore >= min && itm.assuranceHealthScore <= max;});
            }
        }

        if(urlAction.restPayload.deviceName || urlAction.restPayload.name) {
            var name = urlAction.restPayload.deviceName==undefined ? urlAction.restPayload.name : urlAction.restPayload.deviceName;
            objs = objs.filter(function(itm){return itm.hostname == name;});
        }
        if(urlAction.restPayload.platformId || urlAction.restPayload.deviceModel) {
            var pid = urlAction.restPayload.platformId==undefined ? urlAction.restPayload.deviceModel : urlAction.restPayload.platformId;
            objs = objs.filter(function(itm){return itm.platformId == pid;});
        }
        if(urlAction.restPayload.osVersion) {
            objs = objs.filter(function(itm){return itm.softwareVersion == urlAction.restPayload.osVersion;});
        }
        if(urlAction.restPayload.managementIpAddress) {
            objs = objs.filter(function(itm){return itm.managementIpAddress == urlAction.restPayload.managementIpAddress;});
        }
        if(urlAction.restPayload.siteName) {
            objs = objs.filter(function(itm){return getGroupNameHierarchy(itm.siteId) == urlAction.restPayload.siteName;});
        }
        // Setting this, currently no device in unmonitered state
        if(healthStatus=="unmonitored") {
            objs =[];
        }

        if(bKpiMetrics && urlAction.restPayload.filters.kpiMetrics.length>0 ) {
            var kpiObj= urlAction.restPayload.filters.kpiMetrics[0];
            //moved the content present to seperate method..
            objs = getFilteredKpiObjs (objs, kpiObj, summaryData);
        }

        if(urlAction.action.bAutoComplete) {
            var response = AssuranceAutocompleteFilterData.getAutoCompleteData(objs, urlAction.filter['field'], urlAction.filter['value']);
            return response;
        }

        var deviceTypeMap = new Object();
        var deviceSwMap = new Object();
        var devicePlatformMap = new Object();
        var deviceCategoryMap = new Object();
        var deviceBuildIdMap = new Object();

        var result = JSON.parse(JSON.stringify(NetworkDeviceHealthMetric.HealthMetric_Template));
        updateNetworkParameters (objs, summaryData);
        var devices = updateResponses(objs);

        //deviceReachability dashlet
        if(communicationState != undefined){
            communicationState = communicationState.toUpperCase();
            objs = objs.filter(e => e.reachabilityStatus.toUpperCase() === communicationState && e.family !== 'Unified AP')
            devices = devices.filter(e => e.reachability.toUpperCase() === communicationState && e.deviceFamily !== 'Unified AP')
            if(urlAction.restPayload.role != undefined){
                let role = urlAction.restPayload.role.toUpperCase();
                if(role == 'ROUTER') {role = "BORDER ROUTER"}
                devices = devices.filter(e => e.deviceRole === role)
                objs = objs.filter(e => e.role === role)
            }
        }

        for(var j=0;j<objs.length; j++) {        
            count = deviceTypeMap[objs[j].type] == undefined ? 1 : deviceTypeMap[objs[j].type] + 1;
            deviceTypeMap[objs[j].type] = count;
            count = devicePlatformMap[objs[j].platformId] == undefined ? 1 : devicePlatformMap[objs[j].platformId] + 1;
            devicePlatformMap[objs[j].platformId] = count;
            count = deviceSwMap[objs[j].softwareVersion] == undefined ? 1 : deviceSwMap[objs[j].softwareVersion] + 1;
            deviceSwMap[objs[j].softwareVersion] = count;

            count = deviceCategoryMap[devices[j].deviceRole] == undefined ? 1 : deviceCategoryMap[devices[j].deviceRole] + 1;
            deviceCategoryMap[devices[j].deviceRole] = count;
            count = deviceBuildIdMap[devices[j].buildingId] == undefined ? 1 : deviceBuildIdMap[devices[j].buildingId] + 1;
            deviceBuildIdMap[devices[j].buildingId] = count;
        }


        result.response.length = 0;
        result.response = devices;

        var devicesDistribution = [
            {type:"byType",values:[]},{type:"bySoftware",values:[]},{type:"byPlatform",values:[]},
            {type:"byCategory",values:[]},{type:"byBuildingId",values:[]}];

        for (var k in deviceTypeMap) {
            devicesDistribution[0].values.push({count:deviceTypeMap[k],id:null,name:k});
        }
        for (var k in deviceSwMap) {
            devicesDistribution[1].values.push({count:deviceSwMap[k],id:null,name:k});
        }
        for (var k in devicePlatformMap) {
            devicesDistribution[2].values.push({count:devicePlatformMap[k],id:null,name:k});
        }
        for (var k in deviceCategoryMap) {
            devicesDistribution[3].values.push({
                count:deviceCategoryMap[k],
                id:null,
                name: k =='ACCESS' ? 3 : (k =='CORE') ? 2 : (k == 'DISTRIBUTION') ? 4 : (k== 'BORDER ROUTER') ? 1 : (k== 'WIRELESS CONTROLLER') ? 5: k,
                type:null
            });
        }
        for (var k in deviceBuildIdMap) {
            devicesDistribution[4].values.push({count:deviceBuildIdMap[k],id:null,name:k});
        }
        
        result.devicesDistribution = devicesDistribution;

        result.page.pageSize = devices.length;
        result.page.totalCount = devices.length;

        return result;
    },

    getNetworkDeviceRelatedData: function(urlAction){
        if(urlAction.action.id != '' && urlAction.action.count){
            var obj = SimLokiDatabaseActions.getFilteredRecordHandler('network-device',{'siteId':urlAction.action.id});
            return obj && obj.length ? [obj.length] : [0];
        }

        if(urlAction.url.indexOf(".hasLabel('NetworkDevice').has('macAddress'") > -1 && urlAction.url.indexOf(".toList()") > -1){
            var index = urlAction.url.indexOf("macAddress")
            var mac = urlAction.url.slice(index+13, index+30);
            urlAction.filter['macAddress'] = mac;
        }

        if(urlAction.filter['nwDeviceId'] != undefined || urlAction.filter['serialNumber'] != undefined || urlAction.filter['macAddress'] != undefined || urlAction.url.indexOf('nwDeviceId') != undefined) {
            var data = [], key = '', value = '', filter = {};

            if(urlAction.filter['nwDeviceId'] != undefined) {
                value = urlAction.filter['nwDeviceId'].trim();
                key   = 'id';
            } else if(urlAction.filter['serialNumber'] != undefined){
                value = urlAction.filter['serialNumber'].trim();
                key = 'serialNumber';
            } else if(urlAction.filter['macAddress'] != undefined){
                value = urlAction.filter['macAddress'].trim();
                key = 'macAddress';
            } else if (urlAction.url.indexOf('nwDeviceId') != undefined) {
                key = 'id';
                var queries = urlAction.filter["query"];
                let queriesList = queries.split(".");
                let str = "";
                for (i = 0; i < queriesList.length; i++) {
                    if (queriesList[i].includes("nwDeviceId")) {
                        str = queriesList[i]; break;
                    }
                }
                let devId = str.split(",")[1];
                value = devId.slice(1, devId.length-2)
            }
            filter[key] = value;
            data = SimLokiDatabaseActions.getFilteredRecordHandler('network-device', filter);
            if(data && data.length){
                var siteDetails = SimLokiDatabaseActions.getFilteredRecordHandler('site', {'id': data[0].siteId});
                  var obj =  JSON.parse(JSON.stringify(AssuranceNetworkDeviceDetailTemplate.Assurance_NetworkDevice_New_Detail_Template ));
                  obj.id = data[0].id;
                  let platform_Id = data[0].platformId.split(',');
                  let serial_Num = data[0].serialNumber.split(',');
                  let time = UtilityFunctions.getTimeStamp();
                  obj.timestamp = UtilityFunctions.getGivenDateAndTimeInValidFormat(time);
                  obj.properties.managementIpAddr[0].value = data[0].managementIpAddress;
                  obj.properties.ip_addr_managementIpAddr[0].value = data[0].managementIpAddress;
                  obj.properties.serialNumber[0].value = serial_Num[0];
                  obj.properties.communicationState[0].value = data[0].reachabilityStatus.toUpperCase()
                  obj.properties.nwDeviceName[0].value = data[0].hostname;
                  obj.properties.platformId[0].value = platform_Id[0]
                  obj.properties.nwDeviceId[0].value = data[0].id;
                  obj.properties.nwDeviceRole[0].value = data[0].role;
                  obj.properties.macAddress[0].value = data[0].macAddress;
                  obj.properties.nwDeviceFamily[0].value = data[0].family;
                  obj.properties.deviceSeries[0].value = data[0].series;
                  obj.properties.softwareVersion[0].value = data[0].softwareVersion;
                  obj.properties.nwDeviceType[0].value = data[0].type;
                  obj.properties.lastBootTime[0].value = time;

                  obj.properties.location[0].value = siteDetails[0].groupNameHierarchy;
                  //obj.properties.deviceGroupHierarchyId[0].value = siteDetails[0].groupNameHierarchy;
                  obj.properties.ethernetMac[0].value = data[0].macAddress;
                  obj.properties.siteHierarchyGraphId[0].value = siteDetails[0].groupHierarchy;
                  
                  if(data[0].stackCount !== null){//stack switch will have multiple platformId's
                    obj.properties.ringStatus[0].value = true;
                    if(platform_Id[0] == "C9500-16X"){
                        obj.properties.stackType[0].value = "SVL";
                    }else{
                        obj.properties.stackType[0].value = "STACK";
                    }
                    obj.properties.haStatus[0].value = "sso";
                    obj.properties.nwDeviceFamily[0].value = "Switches and Hubs"
                  }


                  if(urlAction.action.metricAction == 8){
                      obj.metrics = [];
                      //obj.devices.metrics = [1, 10, 1, 1];
                      var arr = [1, 10, 1, 1];
                      var ii, len1;
                      for(ii=0, len1 = arr.length; ii < len1; ii++){
                        obj.metrics.push(arr[ii]);
                      }
                  }

                  return [obj];
              } else {
                  return [];
              }
          }

          var siteId = urlAction.filter['sites'];
          if(siteId != undefined) {
               siteId = siteId.replace(/'/g, '');
               siteId = siteId.replace(/"/g, '');
               var t = siteId.split(',');
               siteId = t[0];

               var data = getDeviceBasedOnRole(siteId, urlAction.filter['role']);
              var tResult =  JSON.parse(JSON.stringify(AssuranceNetworkDeviceTemplate.Assurance_NetworkDevice_Template));
              var i, len = data.length;
              for(i=0; i < len; i++){
                  var location = getGroupNameHierarchy(data[i].siteId);
                  location = location == undefined ? '': location;

                  var obj = {};
                  obj.deviceMacAddress = data[i].macAddress;
                  obj.deviceModelType = data[i].type;
                  obj.family = data[i].family;
                  obj.id = data[i].id;
                  obj.issueCount = 0;
                  obj.lastUpdated = undefined;
                  obj.location = location;
                  obj.managementIpAddress = data[i].managementIpAddress;
                  obj.name = data[i].hostname;
                  obj.platfromId = data[i].platformId;
                  obj.roleSource = data[i].role;
                  obj.softwareVersion = data[i].softwareVersion;
                  obj.healthScore = [{healthType: "OVERALL", reason: "", score: data[i].healthScore }];

                  tResult.response.push(obj);
              }

              tResult.page.pageSize =  urlAction.filter['limit'];
              tResult.page.offset =  urlAction.filter['offset'];
              tResult.page.totalCount = data.length;
              return tResult;
          }

          if(urlAction.action.id != '') {
             var tResult = [];
             if(urlAction.filter['nwDeviceRole'] != undefined){
                 var data = getDeviceBasedOnRole(urlAction.action.id, urlAction.filter['nwDeviceRole']);
                 var i, len = data.length;
                 for(i=0; i < len; i++){
                     var obj =  JSON.parse(JSON.stringify( AssuranceNetworkDeviceMetricTemplate.Assurance_NetworkDevice_Metric_Template ));
                     obj.devices.id = data[i].id;
                     obj.devices.properties.nwDeviceRole[0].value = data[i].role;
                     obj.devices.properties.managementIpAddr[0].value = data[i].managementIpAddress;
                     obj.devices.properties.nwDeviceFamily[0].value = data[i].family;
                     obj.devices.properties.macAddress[0].value = data[i].macAddress;
                     obj.devices.properties.nwDeviceName[0].value = data[i].hostname;
                     obj.devices.properties.platformId[0].value = data[i].platformId;
                     obj.devices.properties.softwareVersion[0].value = data[i].softwareVersion;
                     obj.devices.properties.nwDeviceId[0].value = data[i].id;
                     obj.devices.properties.nwDeviceType[0].value = data[i].type;

                     tResult.push(obj);
                 }
             }
             return tResult;
          }
           // Added for ICAP Schedule
          if((urlAction.filter['nwDeviceFamily'] && urlAction.filter['nwDeviceFamily'] == 'Wireless Controller') ||
            urlAction.filter['apDevsUnderSiteId']) {
              var version = "1.2.5";
              if(urlAction.url.indexOf("flatten") >=0) {
                  // api/ndp/v1/data/graph?query=networkDevices().has(%27nwDeviceFamily%27,%27Wireless%20Controller%27).as(%27WLC%27).locateIn().sites().as(%27Site%27).flatten()
                  return SimScheduledCaptureData.getNetworkDeviceDetails(urlAction, version);
              } else if((urlAction.filter['apsUnderWlcId'] || urlAction.filter['apDevsUnderSiteId']) && urlAction.url.indexOf("toList()") > -1) {
                  return SimScheduledCaptureData.getApsUnderWlcOrSite(urlAction.filter['apsUnderWlcId'], urlAction.filter['apDevsUnderSiteId']);
              } else if(urlAction.url.indexOf("toList()") > -1) {
                  // api/ndp/v1/data/graph?query=g.V().hasLabel(%27NetworkDevice%27).has(%27nwDeviceFamily%27,%27Wireless%20Controller%27).as(%27wlc%27).toList()
                  version = "1.2.6";
                  return SimScheduledCaptureData.getNetworkDeviceDetails(urlAction, version);
              } else {
                  // /api/ndp/v1/data/graph?query=networkDevices().has(nwDeviceFamily,%22Wireless%20Controller%22).locateIn().sites()
                  var location = "Global/North America/USA/California/San Jose/SJC01/Flr-SJC1-1";
                  var site = SimLokiDatabaseActions.getFilteredRecordHandler('site', {'groupNameHierarchy' : location});

                  var obj = [{"id":"33bbd24d-7df6-4dd6-bd4e-ce8397345889","label":"Site","timestamp":"2018-09-26T17:19:51.127+0000","isDeleted":false,"type":"vertex","properties":{"siteHierarchy":[{"value":"Global/North America/USA/California/San Jose/SJC01/Flr-SJC1-1"}],"name":[{"value":"Floor1"}],"siteType":[{"value":"floor"}]}}];
                  obj[0].id = site[0].id;
                  return obj;
              }
          }

          return [];
      },

      getWLCForAirsense : function(urlAction) {

       var deviceId =urlAction.filter["nwDeviceId"];

       var apDevice = SimLokiDatabaseActions.getFilteredRecordHandler('network-device',{'id':deviceId});

       var wlcDevice = SimLokiDatabaseActions.getFilteredRecordHandler('network-device',{'managementIpAddress':apDevice[0].associatedWlcIp});

        console.log(wlcDevice);
        var jsonObj=[];
        var obj =  JSON.parse(JSON.stringify(AssuranceNetworkDeviceDetailTemplate.Assurance_NetworkDevice_Detail_Template ));
               obj.id = wlcDevice[0].id;
               obj.timestamp = new Date().getTime();
               obj.properties.nwDeviceRole[0].value = wlcDevice[0].role;
               obj.properties.managementIpAddr[0].value = wlcDevice[0].managementIpAddress;
               obj.properties.nwDeviceFamily[0].value = wlcDevice[0].family;
               obj.properties.macAddress[0].value = wlcDevice[0].macAddress;
               obj.properties.nwDeviceName[0].value = wlcDevice[0].hostname;
               obj.properties.platformId[0].value = wlcDevice[0].platformId;
               obj.properties.softwareVersion[0].value = wlcDevice[0].softwareVersion;
               obj.properties.serialNumber[0].value = wlcDevice[0].serialNumber;
               obj.properties.nwDeviceId[0].value = wlcDevice[0].id;
               obj.properties.nwDeviceType[0].value = wlcDevice[0].type;
               obj.properties.upTime[0].value = wlcDevice[0].upTime;
               obj.properties.connectedTime[0].value = wlcDevice[0].upTime;

               jsonObj.push(obj);
      return jsonObj;

      },

       getSSIDDetails: function(urlAction){
          var ssidList=[];
          var hostList=SimLokiDatabaseActions.getAll('host');
          var ssidNamesList=[];for(var x=0;x<hostList.length;x++){ if(hostList[x].ssid!=undefined && hostList[x].ssid != null && ssidNamesList.indexOf(hostList[x].ssid)<0) ssidNamesList.push(hostList[x].ssid)}
          var wlanssids=WlanTemplate.Enterprise_Wlan_Template;
          for(var i=0;i<ssidNamesList.length;i++){
              for(var k=0;k<wlanssids.length;k++){
                if(ssidNamesList[i]===wlanssids[k].value[0].ssid){
                    var ssidobj=wlanssids[k];
                   for(var key in wlanssids[k].value[0]){
                       ssidobj[key]=wlanssids[k].value[0][key];
                   }
                   //adding for wifi6 some more fields
                   ssidobj.mgmtIp = "1.2.3.4"; // this is mapped to wireless controller's ip in ref cluster's data
                   ssidobj.nwElementType = ssidobj.namespace.toUpperCase();
                   ssidobj.wlanId = i+1;
                   ssidobj.ids = ['pid_serialNo_wlan/profileName'] //this is to have in this format with pid being optional
                    ssidList.push(ssidobj);
                }
              }
           }
          return ssidList;
       },

       getapRfDetails: function(urlAction){
            let deviceId = urlAction.action.id;
            let json = JSON.parse(JSON.stringify(AssuranceNetworkDeviceDetailTemplate.apRfDetails))
            return {"version":"1.0","response":json}
       },

       getAPdeviceInfo : function(urlAction){
            let deviceId = urlAction.restPayload.deviceId
            let deviceRec = SimLokiDatabaseActions.getFilteredRecordHandler('network-device',{'id':deviceId});
            let deviceInfo = [];
            let json = JSON.parse(JSON.stringify(AssuranceNetworkDeviceDetailTemplate.deviceInfoTemplate));
            let time = deviceRec[0].upTime
            let days = parseInt(time.split(',').slice(0,1))
            let Up_Time_Sec = UtilityFunctions.getDateInTimestamp_SubtractedByDays_FromCurrentDate(days)/1000;
            json.managementIpAddr = deviceRec[0].managementIpAddress
            json.communicationState = deviceRec[0].reachabilityStatus.toUpperCase()
            json.nwDeviceRole = deviceRec[0].role
            json.connectedTime = Up_Time_Sec
            json.nwDeviceType = deviceRec[0].type
            json.serialNumber = deviceRec[0].serialNumber
            json.nwDeviceName = deviceRec[0].hostname
            json.platformId = deviceRec[0].platformId
            json.nwDeviceId = deviceRec[0].id
            json.upTime = Up_Time_Sec
            json.nwDeviceFamily = deviceRec[0].family
            json.macAddress = deviceRec[0].macAddress
            json.deviceSeries = deviceRec[0].series
            json.softwareVersion = deviceRec[0].softwareVersion
            var protocol = deviceRec[0].protocol_wifi6;
            /*case referencing PROTOCOL_FOR_AP_INFO_SECTION, WIFI_6_PROTOCOL_MAPPING_INFO sections from SimulationUtility.js, 
            getMaxProtocolFreqForAp of SimNetworkDeviceData.js */
            protocol = protocol=="5" ? "4" : (protocol=="4" ? "3" : (protocol=="0" ? "-1" : protocol));
            json.protocol = protocol;
            json.ethernetMac = deviceRec[0].macAddress
            
            json.ip_addr_managementIpAddr = deviceRec[0].managementIpAddress
            var siteId = deviceRec[0].siteId;
            var siteDetails = SimLokiDatabaseActions.getFilteredRecordHandler('site', {'id': siteId})
            json.location = siteDetails[0].groupNameHierarchy
            var siteIds = siteDetails[0].groupHierarchy.split('/')
            json.floorId = siteIds[siteIds.length-2]
            json.siteHierarchy = siteDetails[0].groupNameHierarchy;
            json.siteHierarchyGraphId = siteDetails[0].groupHierarchy;
            deviceInfo.push(json);
            return {"response":deviceInfo}
       },

       getSwitchStackdetails: function(urlAction){
            let deviceId = urlAction.action.id;
            let deviceRec = SimLokiDatabaseActions.getFilteredRecordHandler('network-device',{'id':deviceId});
            let platformId = deviceRec[0].platformId.split(','); //
            let serialNumber = deviceRec[0].serialNumber.split(',');
            let priority_val = 10;
            let resultArr = [];
            let len = platformId.length
            for(let i=0; i<len ;i++){
                if(platformId[0] == "C9500-16X"){
                    let Json = JSON.parse(JSON.stringify(AssuranceNetworkDeviceDetailTemplate.StackDetails));
                    Json.switchNumber = `${i+1}`;
                    Json.switchNumberPeer = j==0 ? (i==0 ? len : i) : ((i+2)>len ? 1 : i+2);
                    Json.serialNumber = serialNumber[i]
                    Json.productID = platformId[i]
                    Json.macAddress = (i== 0)?deviceRec[0].macAddress: UtilityFunctions.incrementMacAddrres(deviceRec[0].macAddress,i);
                    Json.role = (i == 0)?'MASTER':(i == 1)?"STANDBY":"MEMBER";
                    Json.state = "READY"
                    Json.priority = priority_val;
                    var linkDetail = JSON.parse(JSON.stringify(AssuranceNetworkDeviceDetailTemplate.AssuranceStack_Stack_Links));
                    Json.links = linkDetail
                    resultArr.push(Json);
                }else{
                    for(let j=0; j<2; j++){
                        let Json = JSON.parse(JSON.stringify(AssuranceNetworkDeviceDetailTemplate.StackDetails));
                        Json.switchNumber = `${i+1}`;
                        Json.switchNumberPeer = j==0 ? (i==0 ? len : i) : ((i+2)>len ? 1 : i+2);
                        Json.serialNumber = serialNumber[i]
                        Json.productID = platformId[i]
                        Json.macAddress = (i== 0)?deviceRec[0].macAddress: UtilityFunctions.incrementMacAddrres(deviceRec[0].macAddress,i);
                        Json.role = (i == 0)?'MASTER':(i == 1)?"STANDBY":"MEMBER";
                        Json.state = "READY"
                        Json.priority = priority_val;
                        Json.links[0].sourcePort = `${(j== 0)? 1 : 2}`
                        Json.links[0].destinationPort = `${(j== 0)? 2 : 1}`
                        resultArr.push(Json);
                    }
                }
                priority_val -= 1
            }
            return resultArr;
       },
       getSwitchTopInfoDetails : function(urlAction){
            let deviceId = urlAction.action.id;
            let deviceRec = SimLokiDatabaseActions.getFilteredRecordHandler('network-device',{'id':deviceId});
            let Json = JSON.parse(JSON.stringify(AssuranceNetworkDeviceDetailTemplate.infotopDetails));
            let platformId = deviceRec[0].platformId.split(','); //
            Json.deviceModel = deviceRec[0].platformId
            Json.ipAddress = deviceRec[0].managementIpAddress
            Json.softwareVersion = deviceRec[0].softwareVersion
            Json.role = deviceRec[0].role
            if(deviceRec[0].stackCount !== null){//check for stackSwitch
                if(platformId[0] =="C9500-16X"){
                    Json.stackStatus.mode = "STACKVIRTUAL";
                    Json.stackStatus.ringStatus = "false"
                    Json.stackStatus.countMembers = platformId.length;
                    Json.stackStatus.domain = "4.0";
                }else{
                    Json.stackStatus.mode = "BACKSTACK";
                    Json.stackStatus.ringStatus = "ACTIVE"
                    Json.stackStatus.countMembers = platformId.length;
                    Json.haStatus =  "sso";
                }
            }
            Json.upTime = deviceRec[0].upTime;
            Json.location = getGroupNameHierarchy(deviceRec[0].siteId);
            return Json;
       },

       getSwitchChasisDetails : function(urlAction){
            let deviceId = urlAction.filter.nwDeviceId;
            if (deviceId == "" || deviceId == undefined) {
                let urlDevice = urlAction.url.split(".hasId('"); 
                deviceId = (urlDevice[1].split("')"))[0];
            }
            let deviceRec = SimLokiDatabaseActions.getFilteredRecordHandler('network-device',{'id':deviceId});
            let platformId = deviceRec[0].platformId.split(','); //
            let serialNumber = deviceRec[0].serialNumber.split(',');
            let result = []
            for(let i=0; i<platformId.length;i++){
                let obj = {}
                obj.serialNumber = [serialNumber[i]]
                obj.partNumber = [platformId[i]]
                result.push(obj)
            }
            return result;

       },

        getWifi6RadioAnalytics: function(urlAction) {
            var type = urlAction.filter["type"], accessCategory = urlAction.restPayload.filters.accessCategory;
            var band = urlAction.restPayload.filters.band;
            band = band && band.length>0 && band[0]!="" ? band[0].toString() : undefined;//this is either 5 or 2.4, numeric
            var checkString = type+"_"+accessCategory;
            var startTime = parseInt(urlAction.filter["startTime"]), endTime = parseInt(urlAction.filter["endTime"]);
            var data = {}, isTrend = ((endTime-startTime) > (2*60*60*1000));//trend - 3hrs or 24 hrs or 7 days
            var customTime = startTime + (300000 - (startTime%300000));//nearest 5min
            var category = type.startsWith('airTime') ? 'airTime' : 'Latency';

            if(urlAction.service.indexOf("radioList") > -1) type = "radioList";
            if(["airTimeTopLocation", "airTimeTopAP", "airTimeTopBand", "radioList", 
              "latencyTopLocation", "latencyTopAP", "latencyTopBand"].indexOf(type) > -1) {
                //when a bar is selected in View Details section
                data =  wifiRadioAnalyticsSelection(type, category, urlAction.restPayload, urlAction.filter.offset, urlAction.filter.limit);
                if(type == "radioList") {
                    var resp = {"page":{"pageSize":0, "offset":0, "totalCount":"0", "nextKey":null, "topN":null}};
                    if(data.length>0) {
                        resp.response = data;
                        resp.page.pageSize = data.length, resp.page.totalCount = data.length;
                    }
                    return resp;
                } else {
                    return updateResponseFormat( {"insights":null, "records":data} );
                }
            }

            switch (checkString) {
                case "airTimeEfficiency_voice":
                    data = Wifi6Template.WIFI6_VOICE_AIRTIME_EFFICIENCY;
                    if(isTrend) data = Timestamp_AirtimeEfficiency_voice.WIFI6_AT_VOICE;
                    break;
                case "airTimeEfficiency_video":
                    data = Wifi6Template.WIFI6_VIDEO_AIRTIME_EFFICIENCY;
                    if(isTrend) data = Timestamp_AirtimeEfficiency_video.WIFI6_AT_VIDEO;
                    break;
                case "airTimeEfficiency_bg":
                    data = Wifi6Template.WIFI6_BACKGROUND_AIRTIME_EFFICIENCY;
                    if(isTrend) data = Timestamp_AirtimeEfficiency_bg.WIFI6_AT_BG;
                    break;
                case "airTimeEfficiency_be":
                    data = Wifi6Template.WIFI6_BEST_EFFORTS_AIRTIME_EFFICIENCY;
                    if(isTrend) data = Timestamp_AirtimeEfficiency_be.WIFI6_AT_BE;
                    break;
                case "airTimeEfficiency_all":
                    data = Wifi6Template.WIFI6_ALL_AIRTIME_EFFICIENCY;
                    if(isTrend) data = Timestamp_AirtimeEfficiency_all.WIFI6_AT_ALL;
                    break;
                case "latencyByClcnt_voice":
                    data = Wifi6Template.WIFI6_VOICE_LATENCY_BY_CLIENT_COUNT;
                    break;
                case "latencyByClcnt_video":
                    data = Wifi6Template.WIFI6_VIDEO_LATENCY_BY_CLIENT_COUNT;
                    break;
                case "latencyByClcnt_be":
                    data = Wifi6Template.WIFI6_BE_LATENCY_BY_CLIENT_COUNT;
                    break;
                case "latencyByClcnt_bg":
                    data = Wifi6Template.WIFI6_BG_LATENCY_BY_CLIENT_COUNT;
                    break;
                case "latencyTrend_voice":
                    data = Timestamp_Latency_voice.WIFI6_LATENCY_VOICE;
                    break;
                case "latencyTrend_video":
                    data = Timestamp_Latency_video.WIFI6_LATENCY_VIDEO;
                    break;
                case "latencyTrend_be":
                    data = Timestamp_Latency_be.WIFI6_LATENCY_BE;
                    break;
                case "latencyTrend_bg":
                    data = Timestamp_Latency_bg.WIFI6_LATENCY_BG;
                    break;
                default:
                    break;
            }
            data = JSON.parse(JSON.stringify(data));
            if(band!=undefined) {
                var multFactor = UtilityFunctions.getRandomFloatForRangeValues(0.65, 0.75, 2);
                data.records.forEach(radio => {
                    Object.keys(radio).forEach(key => {
                        if(key != "modificationTime" && key != "timestamp" && key != "clientCountBucket") {
                            radio[key] = multFactor * radio[key];
                        }
                    })
                });
            }
            if(!isTrend) {
                var insight = 0;
                if(checkString.startsWith("airTimeEfficiency")) {
                    var bucketVice = getBucketViseDetails(band);
                    data.records[0].modificationTime = customTime
                    data.records[0].timestamp = UtilityFunctions.getGivenTimeInGivenFormat(data.modificationTime, 'YYYY-MM-DDTHH:mm:ss.SSSZZ');
                    if(!(bucketVice['upto10'].isWifi6Present || bucketVice['upto20'].isWifi6Present || bucketVice['upto30'].isWifi6Present || 
                      bucketVice['upto40'].isWifi6Present || bucketVice['upto50'].isWifi6Present || bucketVice['above50'].isWifi6Present)) {
                        data.records[0].axATE=0, data.records[0].axBytes=0, data.records[0].axAirtime=0;
                    }
                    if(!(bucketVice['upto10'].isNonWifi6Present || bucketVice['upto20'].isNonWifi6Present || bucketVice['upto30'].isNonWifi6Present || 
                      bucketVice['upto40'].isNonWifi6Present || bucketVice['upto50'].isNonWifi6Present || bucketVice['above50'].isNonWifi6Present)) {
                        data.records[0].nonAxATE=0, data.records[0].nonAxBytes=0, data.records[0].nonAxAirtime=0;
                    }
                    insight = calculateInsight("airTimeEfficiency", data.records);
                } else {
                    var bucketVice = getBucketViseDetails(band);
                    if(!bucketVice['upto10'].isWifi6Present) data.records[0].axLatency = 0
                    if(!bucketVice['upto10'].isNonWifi6Present) data.records[0].nonAxLatency = 0
                    if(!bucketVice['upto20'].isWifi6Present) data.records[1].axLatency = 0
                    if(!bucketVice['upto20'].isNonWifi6Present) data.records[1].nonAxLatency = 0
                    if(!bucketVice['upto30'].isWifi6Present) data.records[2].axLatency = 0
                    if(!bucketVice['upto30'].isNonWifi6Present) data.records[2].nonAxLatency = 0
                    if(!bucketVice['upto40'].isWifi6Present) data.records[3].axLatency = 0
                    if(!bucketVice['upto40'].isNonWifi6Present) data.records[3].nonAxLatency = 0
                    if(!bucketVice['upto50'].isWifi6Present) data.records[4].axLatency = 0
                    if(!bucketVice['upto50'].isNonWifi6Present) data.records[4].nonAxLatency = 0
                    if(!bucketVice['above50'].isWifi6Present) data.records[5].axLatency = 0
                    if(!bucketVice['above50'].isNonWifi6Present) data.records[5].nonAxLatency = 0                  
                    insight = calculateInsight("latencyByClcnt", data.records);
                }
                data.insights = insight;
            } else {
                data = generateWifi6TrendData(data, customTime, endTime);
            }
            return updateResponseFormat(data);
        },

        defaultClause: function(urlAction) {
            var role = urlAction.restPayload.role, apProtocol = urlAction.restPayload.apProtocol;
            var devices = SimLokiDatabaseActions.getAll('network-device');
            if(role)
                devices = devices.filter(dev => dev.family.toUpperCase() == role );
            var data = this.getNetworkDeviceHealthMetric(urlAction);
            var filteredDevices = data.response;
            filteredDevices.forEach(dev => {
                dev.apProtocol = apProtocol 
                if(role == "UNIFIED AP") {
                    var tempDev = devices.filter(obj => obj.id == dev.id);
                    dev.radio0wifi6status = tempDev[0].radio0wifi6status;
                    dev.radio1wifi6status = tempDev[0].radio1wifi6status;
                    dev.radio2wifi6status = tempDev[0].radio2wifi6status;
                    dev.radio3wifi6status = tempDev[0].radio3wifi6status;
                    dev.wifi6Status = tempDev[0].wifi6Status;
                    dev.apMode = "local"
                    dev.clCount = dev.band24clCount + dev.band5clCount + dev.band6clCount;
                    dev.protocol_wifi6 = tempDev[0].protocol_wifi6;
                    var wlcConnected = (SimLokiDatabaseActions.getFilteredRecordHandler("network-device",
                        {"managementIpAddress":tempDev[0].associatedWlcIp}));
                    dev.connectedWlcName = wlcConnected.length>0 ? wlcConnected[0].hostname : "";
                } else if(role == "WIRELESS CONTROLLER") {
                    var tempDev = devices.filter(obj => obj.id == dev.id);
                    dev.wifi6status = tempDev[0].wifi6status;
                    dev.wlcBand5Wifi6 = tempDev[0].wlcBand5Wifi6;
                    dev.wlcBand24Wifi6 = tempDev[0].wlcBand24Wifi6;
                    dev.protocol_wifi6 = tempDev[0].protocol_wifi6;
                }
            })
            if(apProtocol || apProtocol == 0) {
                if(role == DefaultConfig.NetworkDevice.AP_FAMILY.toUpperCase()) {
                    if(apProtocol == 5) {//wifi6E
                        filteredDevices = filteredDevices.filter(dev => dev.protocol_wifi6 == "7" )
                    }else if(apProtocol == 4) {//wifi6
                        filteredDevices = filteredDevices.filter(dev => dev.protocol_wifi6 == "5" )
                    } else if(apProtocol == 3) {//11ac
                        filteredDevices = filteredDevices.filter(dev => dev.protocol_wifi6 == "4" )
                    } else if(apProtocol == 0) {//11abg
                        filteredDevices = filteredDevices.filter(dev => dev.protocol_wifi6 == "1" )
                    } else if(apProtocol == 2) {//11n
                        filteredDevices = filteredDevices.filter(dev => dev.protocol_wifi6 == "2" )
                    } else if(apProtocol == -4) {//non wifi6
                        filteredDevices = filteredDevices.filter(dev => ["4","2","1","0"].indexOf(dev.protocol_wifi6) > -1 )
                    } else {//unclassified
                        filteredDevices = filteredDevices.filter(dev => dev.protocol_wifi6 == "0" )
                    }
                } else if (role == DefaultConfig.NetworkDevice.WLC_FAMILY.toUpperCase()) {
                    if(apProtocol == 4) {//wifi6
                        filteredDevices = filteredDevices.filter(dev => dev.protocol_wifi6 == "5" )
                    } else if(apProtocol == -4) {//non wifi6
                        filteredDevices = filteredDevices.filter(dev => ["4","2","1","0"].indexOf(dev.protocol_wifi6) > -1 )
                    }
                }
            }
            if(apProtocol == -4) {
                filteredDevices.forEach(e => {
                    var x = e.protocol_wifi6;
                    if(x == "5") e.apProtocol = 4
                    else if(x == "4") e.apProtocol = 3
                    else if(x == "2") e.apProtocol = 2
                    else if(x == "-1") e.apProtocol = 0
                    else e.apProtocol = 4;
                });
            }            
            return {
                "version":"1.0",
                "response":filteredDevices,
                "page":{"pageSize":filteredDevices.length,"offset":0,"totalCount":filteredDevices.length,"nextKey":null,"topN":null}
            };
        },
        getDeviceDetailsForPathTrace(deviceIdsString) {
            var deviceIds = deviceIdsString.split("','");
            var deviceData = [], nwd = [];
            var deviceEntityData = PathTraceCompleted.deviceEntityObj
            for (var i = 0; i < deviceIds.length ; i++) {
                if(UtilityFunctions.validateMacAddress(deviceIds[i])){                    
                    nwd = SimLokiDatabaseActions.getFilteredRecordHandler('host', {'id' : deviceIds[i] });
                    deviceEntityData.entity.properties.managementIpAddr[0].value = nwd[0].hostIpV4;
                    deviceEntityData.entity.properties.macAddress[0].value = nwd[0].hostMac;
                    deviceEntityData.entity.properties.ip_addr_managementIpAddr[0].value = nwd[0].hostIpV4;
                    deviceEntityData.metric = nwd[0].healthScore[0].score;
                    
                } else {
                    nwd = SimLokiDatabaseActions.getFilteredRecordHandler('network-device', {'id' : deviceIds[i] });                    
                    deviceEntityData.entity.properties.managementIpAddr[0].value = nwd[0].managementIpAddress;
                    deviceEntityData.entity.properties.macAddress[0].value = nwd[0].macAddress;
                    deviceEntityData.entity.properties.ip_addr_managementIpAddr[0].value = nwd[0].managementIpAddress;
                    deviceEntityData.metric = nwd[0].assuranceHealthScore;
                    
                }  
                deviceEntityData.entity.id = nwd[0].id;
                deviceEntityData.entity.timestamp = UtilityFunctions.getGivenDateAndTimeInValidFormat(new Date());
                deviceEntityData.entity.properties.serialNumber[0].value = nwd[0].serialNumber;
                deviceEntityData.entity.properties.nwDeviceName[0].value = nwd[0].name;
                deviceEntityData.entity.properties.platformId[0].value = nwd[0].platformId;
                deviceEntityData.entity.properties.nwDeviceId[0].value = nwd[0].id;
                deviceEntityData.entity.properties.nwDeviceRole[0].value = nwd[0].role;
                deviceEntityData.entity.properties.nwDeviceFamily[0].value = nwd[0].family;                
                deviceEntityData.entity.properties.deviceSeries[0].value = nwd[0].series;                
                deviceEntityData.entity.properties.softwareVersion[0].value = nwd[0].softwareVersion;
                deviceEntityData.entity.properties.nwDeviceType[0].value = nwd[0].id;
                deviceData.push(JSON.parse(JSON.stringify(deviceEntityData)));
            }
            return deviceData;
        }
    };
});
