define({
  "Template":
    {
  "version": "1.0",
  "response": {
    "transactions": [
      {
        "startTime": 1551872297506,
        "status": "Failure",
        "reason": "Failure",
        "duration": 211,
        "events": [
          {
            "type": "Assoc Start",
            "timestamp": 1551872297506,
            "result": "Success",
            "reasonCode": 1,
            "reason": "Assoc",
            "duration": 0
          },
          {
            "type": "Assoc Done",
            "timestamp": 1551872297506,
            "result": "Failure",
            "reasonCode": 0,
            "reason": "Failure",
            "duration": 211
          }
        ]
      },
      {
        "startTime": 1551872297717,
        "status": "Failure",
        "reason": "Invalid Data Rate",
        "duration": 9,
        "events": [
          {
            "type": "Assoc Start",
            "timestamp": 1551872297717,
            "result": "Success",
            "reasonCode": 1,
            "reason": "Assoc",
            "duration": 0
          },
          {
            "type": "Assoc Done",
            "timestamp": 1551872297717,
            "result": "Failure",
            "reasonCode": 30,
            "reason": "Invalid Data Rate",
            "duration": 9
          }
        ]
      },
      {
        "startTime": 1551872297726,
        "status": "Failure",
        "reason": "Invalid Data Rate",
        "duration": 10,
        "events": [
          {
            "type": "Assoc Start",
            "timestamp": 1551872297726,
            "result": "Success",
            "reasonCode": 1,
            "reason": "Assoc",
            "duration": 0
          },
          {
            "type": "Assoc Done",
            "timestamp": 1551872297726,
            "result": "Failure",
            "reasonCode": 30,
            "reason": "Invalid Data Rate",
            "duration": 10
          }
        ]
      },
      {
        "startTime": 1551872297736,
        "status": "Failure",
        "reason": "Invalid Data Rate",
        "duration": 10,
        "events": [
          {
            "type": "Assoc Start",
            "timestamp": 1551872297736,
            "result": "Success",
            "reasonCode": 1,
            "reason": "Assoc",
            "duration": 0
          },
          {
            "type": "Assoc Done",
            "timestamp": 1551872297736,
            "result": "Failure",
            "reasonCode": 30,
            "reason": "Invalid Data Rate",
            "duration": 10
          }
        ]
      },
      {
        "startTime": 1551872297746,
        "status": "Failure",
        "reason": "Invalid Data Rate",
        "duration": 10,
        "events": [
          {
            "type": "Assoc Start",
            "timestamp": 1551872297746,
            "result": "Success",
            "reasonCode": 1,
            "reason": "Assoc",
            "duration": 1
          },
          {
            "type": "Assoc Done",
            "timestamp": 1551872297747,
            "result": "Failure",
            "reasonCode": 30,
            "reason": "Invalid Data Rate",
            "duration": 9
          }
        ]
      },
      {
        "startTime": 1551872297756,
        "status": "Failure",
        "reason": "Invalid Data Rate",
        "duration": 2129,
        "events": [
          {
            "type": "Assoc Start",
            "timestamp": 1551872297756,
            "result": "Success",
            "reasonCode": 1,
            "reason": "Assoc",
            "duration": 0
          },
          {
            "type": "Assoc Done",
            "timestamp": 1551872297756,
            "result": "Failure",
            "reasonCode": 30,
            "reason": "Invalid Data Rate",
            "duration": 670
          },
          {
            "type": "Send Deauth",
            "timestamp": 1551872298426,
            "result": "Success",
            "reasonCode": 0,
            "reason": "",
            "duration": 0
          },
          {
            "type": "Delete",
            "timestamp": 1551872298426,
            "result": "Success",
            "reasonCode": 0,
            "reason": "",
            "duration": 1459
          }
        ]
      },
      {
        "startTime": 1551872299885,
        "status": "Success",
        "reason": "Success",
        "duration": 61862,
        "events": [
          {
            "type": "Assoc Start",
            "timestamp": 1551872299885,
            "result": "Success",
            "reasonCode": 1,
            "reason": "Assoc",
            "duration": 0
          },
          {
            "type": "Assoc Done",
            "timestamp": 1551872299885,
            "result": "Success",
            "reasonCode": 0,
            "reason": "",
            "duration": 2
          },
          {
            "type": "Auth Start",
            "timestamp": 1551872299887,
            "result": "Success",
            "reasonCode": 0,
            "reason": "",
            "duration": 1800
          },
          {
            "type": "EAPOL Start",
            "timestamp": 1551872301687,
            "result": "Success",
            "reasonCode": 0,
            "reason": "",
            "duration": 0
          },
          {
            "type": "Auth Start",
            "timestamp": 1551872301687,
            "result": "Success",
            "reasonCode": 0,
            "reason": "",
            "duration": 1315
          },
          {
            "type": "Auth Done",
            "timestamp": 1551872303002,
            "result": "Success",
            "reasonCode": 89,
            "reason": "dot1x Full Auth",
            "duration": 53
          },
          {
            "type": "L2Key Done",
            "timestamp": 1551872303055,
            "result": "Success",
            "reasonCode": 0,
            "reason": "",
            "duration": 0
          },
          {
            "type": "Mobility Done",
            "timestamp": 1551872303055,
            "result": "Success",
            "reasonCode": 147,
            "reason": "Mobility handoff timeout",
            "duration": 56729
          },
          {
            "type": "Mobility Done",
            "timestamp": 1551872359784,
            "result": "Success",
            "reasonCode": 147,
            "reason": "Mobility handoff timeout",
            "duration": 1963
          },
          {
            "type": "DHCP Done",
            "timestamp": 1551872361747,
            "result": "Success",
            "reasonCode": 191,
            "reason": "IP Learn from DHCP",
            "duration": 0
          },
          {
            "type": "Run",
            "timestamp": 1551872361747,
            "result": "Success",
            "reasonCode": 0,
            "reason": "",
            "duration": 0
          }
        ]
      }
    ]
  }
}
});
