define({
    "data":[
  {
    "id": "09ae1013-ad09-48e3-8794-cbd529b11a0d",
    "instanceId": 4163,
    "displayName": "4163",
    "instanceVersion": 0,
    "identitySource": {
      "id": "90ffd1f6-9b59-4591-a326-8d9ad39c08ff",
      "type": "NBAR"
    },
    "name": "enterprise-ipc",
    "namespace": "scalablegroup:application",
    "scalableGroupExternalHandle": "enterprise-ipc",
    "scalableGroupType": "APPLICATION-GROUP",
    "type": "scalablegroup"
  },
  {
    "id": "0e415af5-5380-4e1f-a099-df104f8376c4",
    "instanceId": 4104,
    "displayName": "4104",
    "instanceVersion": 0,
    "identitySource": {
      "id": "10f82aeb-789e-48a7-98a4-a74d4a48ca6c",
      "type": "NBAR"
    },
    "name": "desktop-virtualization-apps",
    "namespace": "scalablegroup:application",
    "scalableGroupExternalHandle": "desktop-virtualization-apps",
    "scalableGroupType": "APPLICATION-GROUP",
    "type": "scalablegroup"
  },
  {
    "id": "1a8ef982-ff60-440d-8af8-ecb91994f2b9",
    "instanceId": 4034,
    "displayName": "4034",
    "instanceVersion": 0,
    "identitySource": {
      "id": "3341b52b-fc56-4e4e-a998-2df6f120d4a5",
      "type": "NBAR"
    },
    "name": "general-misc",
    "namespace": "scalablegroup:application",
    "scalableGroupExternalHandle": "general-misc",
    "scalableGroupType": "APPLICATION-GROUP",
    "type": "scalablegroup"
  },
  {
    "id": "1f2a8c60-b1ac-4cb4-a04e-a9bc7a74c645",
    "instanceId": 4771,
    "displayName": "4771",
    "instanceVersion": 0,
    "identitySource": {
      "id": "c8eaae10-02b7-49fa-b111-263d86e8d571",
      "type": "NBAR"
    },
    "name": "saas-apps",
    "namespace": "scalablegroup:application",
    "scalableGroupExternalHandle": "saas-apps",
    "scalableGroupType": "APPLICATION-GROUP",
    "type": "scalablegroup"
  },
  {
    "id": "2c6a1b58-3ab2-4a05-803f-0e5c705fd38d",
    "instanceId": 4309,
    "displayName": "4309",
    "instanceVersion": 0,
    "identitySource": {
      "id": "72153b31-e229-4d20-8e5b-bbe367434494",
      "type": "NBAR"
    },
    "name": "naming-services",
    "namespace": "scalablegroup:application",
    "scalableGroupExternalHandle": "naming-services",
    "scalableGroupType": "APPLICATION-GROUP",
    "type": "scalablegroup"
  },
  {
    "id": "2f7091f9-a4c2-466d-9308-8ca7c540d6a1",
    "instanceId": 4153,
    "displayName": "4153",
    "instanceVersion": 0,
    "identitySource": {
      "id": "9a609cdf-0a1c-40fa-83ce-d79544cd96c1",
      "type": "NBAR"
    },
    "name": "tunneling",
    "namespace": "scalablegroup:application",
    "scalableGroupExternalHandle": "tunneling",
    "scalableGroupType": "APPLICATION-GROUP",
    "type": "scalablegroup"
  },
  {
    "id": "39234e05-0e78-442d-b6f9-c1d631887bfa",
    "instanceId": 4102,
    "displayName": "4102",
    "instanceVersion": 0,
    "identitySource": {
      "id": "45c5e693-1180-451d-b1e8-47d96f2f95f5",
      "type": "NBAR"
    },
    "name": "consumer-misc",
    "namespace": "scalablegroup:application",
    "scalableGroupExternalHandle": "consumer-misc",
    "scalableGroupType": "APPLICATION-GROUP",
    "type": "scalablegroup"
  },
  {
    "id": "39f44ead-d03f-4a0e-9e07-16af7d48dd93",
    "instanceId": 4559,
    "displayName": "4559",
    "instanceVersion": 0,
    "identitySource": {
      "id": "14672db2-45b0-4954-bffb-b0a1e5271f98",
      "type": "NBAR"
    },
    "name": "local-services",
    "namespace": "scalablegroup:application",
    "scalableGroupExternalHandle": "local-services",
    "scalableGroupType": "APPLICATION-GROUP",
    "type": "scalablegroup"
  },
  {
    "id": "3b5d5a85-693f-4e9e-9dde-9963570f79f1",
    "instanceId": 4091,
    "displayName": "4091",
    "instanceVersion": 0,
    "identitySource": {
      "id": "2d3934a0-e692-49bb-bd28-e80ecbdab962",
      "type": "NBAR"
    },
    "name": "network-control",
    "namespace": "scalablegroup:application",
    "scalableGroupExternalHandle": "network-control",
    "scalableGroupType": "APPLICATION-GROUP",
    "type": "scalablegroup"
  },
  {
    "id": "4a63686f-3bab-4f96-be74-64e5e4e7b5fc",
    "instanceId": 4192,
    "displayName": "4192",
    "instanceVersion": 0,
    "identitySource": {
      "id": "7ab133a9-f46c-40b1-ab9f-9bcd85b36e73",
      "type": "NBAR"
    },
    "name": "consumer-social-networking",
    "namespace": "scalablegroup:application",
    "scalableGroupExternalHandle": "consumer-social-networking",
    "scalableGroupType": "APPLICATION-GROUP",
    "type": "scalablegroup"
  },
  {
    "id": "586a2141-2e8e-4d3b-b269-96c454c62dab",
    "instanceId": 4078,
    "displayName": "4078",
    "instanceVersion": 0,
    "identitySource": {
      "id": "155188bb-0c14-4881-8957-51b020830e34",
      "type": "NBAR"
    },
    "name": "general-browsing",
    "namespace": "scalablegroup:application",
    "scalableGroupExternalHandle": "general-browsing",
    "scalableGroupType": "APPLICATION-GROUP",
    "type": "scalablegroup"
  },
  {
    "id": "6a08c17a-fd06-49a1-8618-f5f0ad09617a",
    "instanceId": 4140,
    "displayName": "4140",
    "instanceVersion": 0,
    "identitySource": {
      "id": "5e44638e-d9bb-4b67-96e7-550683ecd492",
      "type": "NBAR"
    },
    "name": "general-media",
    "namespace": "scalablegroup:application",
    "scalableGroupExternalHandle": "general-media",
    "scalableGroupType": "APPLICATION-GROUP",
    "type": "scalablegroup"
  },
  {
    "id": "6b928b72-3dde-4d71-9ce2-b3c8af65a1d4",
    "instanceId": 4213,
    "displayName": "4213",
    "instanceVersion": 0,
    "identitySource": {
      "id": "e35d5453-ccd5-494d-a539-bf230601a99b",
      "type": "NBAR"
    },
    "name": "database-apps",
    "namespace": "scalablegroup:application",
    "scalableGroupExternalHandle": "database-apps",
    "scalableGroupType": "APPLICATION-GROUP",
    "type": "scalablegroup"
  },
  {
    "id": "6ff8fe47-821d-426e-80b6-1a3c8a791fec",
    "instanceId": 4235,
    "displayName": "4235",
    "instanceVersion": 0,
    "identitySource": {
      "id": "6db8216d-2183-4429-8106-078628f489d3",
      "type": "NBAR"
    },
    "name": "streaming-media",
    "namespace": "scalablegroup:application",
    "scalableGroupExternalHandle": "streaming-media",
    "scalableGroupType": "APPLICATION-GROUP",
    "type": "scalablegroup"
  },
  {
    "id": "71963c26-c178-4eb0-8482-87589981dc22",
    "instanceId": 4061,
    "displayName": "4061",
    "instanceVersion": 0,
    "identitySource": {
      "id": "eccaeee7-fc22-4b40-9816-838f95decfd7",
      "type": "NBAR"
    },
    "name": "collaboration-apps",
    "namespace": "scalablegroup:application",
    "scalableGroupExternalHandle": "collaboration-apps",
    "scalableGroupType": "APPLICATION-GROUP",
    "type": "scalablegroup"
  },
  {
    "id": "749bd452-5650-48a8-8ed7-e7ce19142ec4",
    "instanceId": 4047,
    "displayName": "4047",
    "instanceVersion": 0,
    "identitySource": {
      "id": "2b821bbd-c769-4326-b6e0-cb75bf71e1ea",
      "type": "NBAR"
    },
    "name": "network-management",
    "namespace": "scalablegroup:application",
    "scalableGroupExternalHandle": "network-management",
    "scalableGroupType": "APPLICATION-GROUP",
    "type": "scalablegroup"
  },
  {
    "id": "78cade45-70c4-46aa-ae97-c93920a515ba",
    "instanceId": 4055,
    "displayName": "4055",
    "instanceVersion": 0,
    "identitySource": {
      "id": "a38438d8-7451-425d-b8fa-30cb5d3c9928",
      "type": "NBAR"
    },
    "name": "authentication-services",
    "namespace": "scalablegroup:application",
    "scalableGroupExternalHandle": "authentication-services",
    "scalableGroupType": "APPLICATION-GROUP",
    "type": "scalablegroup"
  },
  {
    "id": "8e691693-deb3-4a2b-9519-ec0433e87cc0",
    "instanceId": 4057,
    "displayName": "4057",
    "instanceVersion": 0,
    "identitySource": {
      "id": "017bd2ea-b82c-454d-b1be-8996bcf8a1a8",
      "type": "NBAR"
    },
    "name": "file-sharing",
    "namespace": "scalablegroup:application",
    "scalableGroupExternalHandle": "file-sharing",
    "scalableGroupType": "APPLICATION-GROUP",
    "type": "scalablegroup"
  },
  {
    "id": "a3035dbd-7631-4694-b4be-b812804fe29c",
    "instanceId": 4065,
    "displayName": "4065",
    "instanceVersion": 0,
    "identitySource": {
      "id": "e6d2c217-8257-4872-b5ee-0076574f84e5",
      "type": "NBAR"
    },
    "name": "backup-and-storage",
    "namespace": "scalablegroup:application",
    "scalableGroupExternalHandle": "backup-and-storage",
    "scalableGroupType": "APPLICATION-GROUP",
    "type": "scalablegroup"
  },
  {
    "id": "a709a3a6-cab9-4ea2-ab21-76a42b245413",
    "instanceId": 4263,
    "displayName": "4263",
    "instanceVersion": 0,
    "identitySource": {
      "id": "e30934d6-41c0-478e-b56e-9d2ad9cbefd5",
      "type": "NBAR"
    },
    "name": "software-development-tools",
    "namespace": "scalablegroup:application",
    "scalableGroupExternalHandle": "software-development-tools",
    "scalableGroupType": "APPLICATION-GROUP",
    "type": "scalablegroup"
  },
  {
    "id": "a9f1fed0-666b-4295-b5b8-cbad05280c34",
    "instanceId": 4073,
    "displayName": "4073",
    "instanceVersion": 0,
    "identitySource": {
      "id": "8cf963d5-d6b6-4f8b-a9b1-dc6e5bf5436b",
      "type": "NBAR"
    },
    "name": "remote-access",
    "namespace": "scalablegroup:application",
    "scalableGroupExternalHandle": "remote-access",
    "scalableGroupType": "APPLICATION-GROUP",
    "type": "scalablegroup"
  },
  {
    "id": "c3a25005-d233-473c-bbeb-39f57f2cb927",
    "instanceId": 4085,
    "displayName": "4085",
    "instanceVersion": 0,
    "identitySource": {
      "id": "36a9b5ed-cc82-49c8-8c35-374db1fe750f",
      "type": "NBAR"
    },
    "name": "software-updates",
    "namespace": "scalablegroup:application",
    "scalableGroupExternalHandle": "software-updates",
    "scalableGroupType": "APPLICATION-GROUP",
    "type": "scalablegroup"
  },
  {
    "id": "ce92b850-baf7-49f4-81de-557819697f39",
    "instanceId": 4195,
    "displayName": "4195",
    "instanceVersion": 0,
    "identitySource": {
      "id": "077e07a7-77c5-41a3-ac17-20e5312c854b",
      "type": "NBAR"
    },
    "name": "email",
    "namespace": "scalablegroup:application",
    "scalableGroupExternalHandle": "email",
    "scalableGroupType": "APPLICATION-GROUP",
    "type": "scalablegroup"
  },
  {
    "id": "d05dde20-4015-4943-8618-34543cd707d9",
    "instanceId": 4069,
    "displayName": "4069",
    "instanceVersion": 0,
    "identitySource": {
      "id": "6c0703eb-4f02-4d45-bc15-f049d7ca44ad",
      "type": "NBAR"
    },
    "name": "consumer-media",
    "namespace": "scalablegroup:application",
    "scalableGroupExternalHandle": "consumer-media",
    "scalableGroupType": "APPLICATION-GROUP",
    "type": "scalablegroup"
  },
  {
    "id": "d4ff51f0-d79e-48ea-9b97-7cfb90d1f2c1",
    "instanceId": 4190,
    "displayName": "4190",
    "instanceVersion": 0,
    "identitySource": {
      "id": "388bdc71-59f8-4b0c-9f65-db7648c26add",
      "type": "NBAR"
    },
    "name": "consumer-gaming",
    "namespace": "scalablegroup:application",
    "scalableGroupExternalHandle": "consumer-gaming",
    "scalableGroupType": "APPLICATION-GROUP",
    "type": "scalablegroup"
  },
  {
    "id": "da44d9db-2863-480a-a6d7-0144bf158455",
    "instanceId": 4043,
    "displayName": "4043",
    "instanceVersion": 0,
    "identitySource": {
      "id": "9b198de2-602f-4d6c-9fe0-95f0e657f0d0",
      "type": "NBAR"
    },
    "name": "consumer-file-sharing",
    "namespace": "scalablegroup:application",
    "scalableGroupExternalHandle": "consumer-file-sharing",
    "scalableGroupType": "APPLICATION-GROUP",
    "type": "scalablegroup"
  },
  {
    "id": "dbff627d-85e6-468f-89fb-61f67c4e5d71",
    "instanceId": 4327,
    "displayName": "4327",
    "instanceVersion": 0,
    "identitySource": {
      "id": "e72baa11-1c3f-42d7-8e8c-fc4a5f4bf432",
      "type": "NBAR"
    },
    "name": "signaling",
    "namespace": "scalablegroup:application",
    "scalableGroupExternalHandle": "signaling",
    "scalableGroupType": "APPLICATION-GROUP",
    "type": "scalablegroup"
  },
  {
    "id": "e3e4ff66-c71d-4b78-a51e-e946e2626066",
    "instanceId": 4038,
    "displayName": "4038",
    "instanceVersion": 0,
    "identitySource": {
      "id": "4e8d6f6b-4003-4361-b75b-bc8a2545c764",
      "type": "NBAR"
    },
    "name": "consumer-browsing",
    "namespace": "scalablegroup:application",
    "scalableGroupExternalHandle": "consumer-browsing",
    "scalableGroupType": "APPLICATION-GROUP",
    "type": "scalablegroup"
  }
]
})