define({
  "Template":
    {
  "issueCount": 0,
  "testType": "dhcp",
  "testResultDetails": {
    "passCount": 9078,
    "failureCount": 145,
    "slowCount": 14
  },
  "worstPerformingSite": {
    "tagName": "Global/North America/USA/California/San Jose/SJC01/Flr-SJC1-1",
    "count": 9237
  },
  "KpiPerformanceInterval": [
    {
      "kpi": "onboarding_addressingTime",
      "performanceThreshold": 5000,
      "averagePerformanceValue": 1477.9005125461874,
      "minPerformanceList": [{
      "intervalIndex": "interval-0",
      "value": 198.0,
      "endTime": 1527332400000
    },
    {
      "intervalIndex": "interval-1",
      "value": 208.0,
      "endTime": 1527328800000
    },
    {
      "intervalIndex": "interval-2",
      "value": 207.0,
      "endTime": 1527325200000
    },
    {
      "intervalIndex": "interval-3",
      "value": 208.0,
      "endTime": 1527321600000
    },
    {
      "intervalIndex": "interval-4",
      "value": 208.0,
      "endTime": 1527318000000
    },
    {
      "intervalIndex": "interval-5",
      "value": 207.0,
      "endTime": 1527314400000
    },
    {
      "intervalIndex": "interval-6",
      "value": 208.0,
      "endTime": 1527310800000
    },
    {
      "intervalIndex": "interval-7",
      "value": 208.0,
      "endTime": 1527307200000
    },
    {
      "intervalIndex": "interval-8",
      "value": 198.0,
      "endTime": 1527303600000
    },
    {
      "intervalIndex": "interval-9",
      "value": 198.0,
      "endTime": 1527300000000
    },
    {
      "intervalIndex": "interval-10",
      "value": 198.0,
      "endTime": 1527296400000
    },
    {
      "intervalIndex": "interval-11",
      "value": 191.0,
      "endTime": 1527292800000
    },
    {
      "intervalIndex": "interval-12",
      "value": 161.0,
      "endTime": 1527289200000
    },
    {
      "intervalIndex": "interval-13",
      "value": 198.0,
      "endTime": 1527285600000
    },
    {
      "intervalIndex": "interval-14",
      "value": 198.0,
      "endTime": 1527282000000
    },
    {
      "intervalIndex": "interval-15",
      "value": 199.0,
      "endTime": 1527278400000
    },
    {
      "intervalIndex": "interval-16",
      "value": -5.0,
      "endTime": 1527274800000
    },
    {
      "intervalIndex": "interval-17",
      "value": 198.0,
      "endTime": 1527271200000
    },
    {
      "intervalIndex": "interval-18",
      "value": 200.0,
      "endTime": 1527267600000
    },
    {
      "intervalIndex": "interval-19",
      "value": 200.0,
      "endTime": 1527264000000
    },
    {
      "intervalIndex": "interval-20",
      "value": 198.0,
      "endTime": 1527260400000
    },
    {
      "intervalIndex": "interval-21",
      "value": 203.0,
      "endTime": 1527256800000
    },
    {
      "intervalIndex": "interval-22",
      "value": 192.0,
      "endTime": 1527253200000
    },
    {
      "intervalIndex": "interval-23",
      "value": 207.0,
      "endTime": 1527249600000
    },
    {
      "intervalIndex": "interval-24",
      "value": 196.0,
      "endTime": 1527246000000
    },
    {
      "intervalIndex": "interval-25",
      "value": 208.0,
      "endTime": 1527242400000
    },
    {
      "intervalIndex": "interval-26",
      "value": 198.0,
      "endTime": 1527238800000
    },
    {
      "intervalIndex": "interval-27",
      "value": -304.0,
      "endTime": 1527235200000
    },
    {
      "intervalIndex": "interval-28",
      "value": 198.0,
      "endTime": 1527231600000
    },
    {
      "intervalIndex": "interval-29",
      "value": 182.0,
      "endTime": 1527228000000
    },
    {
      "intervalIndex": "interval-30",
      "value": 208.0,
      "endTime": 1527224400000
    },
    {
      "intervalIndex": "interval-31",
      "value": 198.0,
      "endTime": 1527220800000
    },
    {
      "intervalIndex": "interval-32",
      "value": 198.0,
      "endTime": 1527217200000
    },
    {
      "intervalIndex": "interval-33",
      "value": 198.0,
      "endTime": 1527213600000
    },
    {
      "intervalIndex": "interval-34",
      "value": 203.0,
      "endTime": 1527210000000
    },
    {
      "intervalIndex": "interval-35",
      "value": -295.0,
      "endTime": 1527206400000
    },
    {
      "intervalIndex": "interval-36",
      "value": 207.0,
      "endTime": 1527202800000
    },
    {
      "intervalIndex": "interval-37",
      "value": 197.0,
      "endTime": 1527199200000
    },
    {
      "intervalIndex": "interval-38",
      "value": 198.0,
      "endTime": 1527195600000
    },
    {
      "intervalIndex": "interval-39",
      "value": 207.0,
      "endTime": 1527192000000
    },
    {
      "intervalIndex": "interval-40",
      "value": 208.0,
      "endTime": 1527188400000
    },
    {
      "intervalIndex": "interval-41",
      "value": 198.0,
      "endTime": 1527184800000
    },
    {
      "intervalIndex": "interval-42",
      "value": 208.0,
      "endTime": 1527181200000
    },
    {
      "intervalIndex": "interval-43",
      "value": 208.0,
      "endTime": 1527177600000
    },
    {
      "intervalIndex": "interval-44",
      "value": 209.0,
      "endTime": 1527174000000
    },
    {
      "intervalIndex": "interval-45",
      "value": 199.0,
      "endTime": 1527170400000
    },
    {
      "intervalIndex": "interval-46",
      "value": 189.0,
      "endTime": 1527166800000
    },
    {
      "intervalIndex": "interval-47",
      "value": 193.0,
      "endTime": 1527163200000
    },
    {
      "intervalIndex": "interval-48",
      "value": 208.0,
      "endTime": 1527159600000
    },
    {
      "intervalIndex": "interval-49",
      "value": 205.0,
      "endTime": 1527156000000
    },
    {
      "intervalIndex": "interval-50",
      "value": 208.0,
      "endTime": 1527152400000
    },
    {
      "intervalIndex": "interval-51",
      "value": 208.0,
      "endTime": 1527148800000
    },
    {
      "intervalIndex": "interval-52",
      "value": 207.0,
      "endTime": 1527145200000
    },
    {
      "intervalIndex": "interval-53",
      "value": 208.0,
      "endTime": 1527141600000
    },
    {
      "intervalIndex": "interval-54",
      "value": 207.0,
      "endTime": 1527138000000
    },
    {
      "intervalIndex": "interval-55",
      "value": 207.0,
      "endTime": 1527134400000
    },
    {
      "intervalIndex": "interval-56",
      "value": 197.0,
      "endTime": 1527130800000
    },
    {
      "intervalIndex": "interval-57",
      "value": 171.0,
      "endTime": 1527127200000
    },
    {
      "intervalIndex": "interval-58",
      "value": 207.0,
      "endTime": 1527123600000
    },
    {
      "intervalIndex": "interval-59",
      "value": 198.0,
      "endTime": 1527120000000
    },
    {
      "intervalIndex": "interval-60",
      "value": 198.0,
      "endTime": 1527116400000
    },
    {
      "intervalIndex": "interval-61",
      "value": 207.0,
      "endTime": 1527112800000
    },
    {
      "intervalIndex": "interval-62",
      "value": 198.0,
      "endTime": 1527109200000
    },
    {
      "intervalIndex": "interval-63",
      "value": 208.0,
      "endTime": 1527105600000
    },
    {
      "intervalIndex": "interval-64",
      "value": 209.0,
      "endTime": 1527102000000
    },
    {
      "intervalIndex": "interval-65",
      "value": 208.0,
      "endTime": 1527098400000
    },
    {
      "intervalIndex": "interval-66",
      "value": 207.0,
      "endTime": 1527094800000
    },
    {
      "intervalIndex": "interval-67",
      "value": 207.0,
      "endTime": 1527091200000
    },
    {
      "intervalIndex": "interval-68",
      "value": 198.0,
      "endTime": 1527087600000
    },
    {
      "intervalIndex": "interval-69",
      "value": 208.0,
      "endTime": 1527084000000
    },
    {
      "intervalIndex": "interval-70",
      "value": 208.0,
      "endTime": 1527080400000
    },
    {
      "intervalIndex": "interval-71",
      "value": 207.0,
      "endTime": 1527076800000
    },
    {
      "intervalIndex": "interval-72",
      "value": 208.0,
      "endTime": 1527073200000
    },
    {
      "intervalIndex": "interval-73",
      "value": 208.0,
      "endTime": 1527069600000
    },
    {
      "intervalIndex": "interval-74",
      "value": 208.0,
      "endTime": 1527066000000
    },
    {
      "intervalIndex": "interval-75",
      "value": 208.0,
      "endTime": 1527062400000
    },
    {
      "intervalIndex": "interval-76",
      "value": 207.0,
      "endTime": 1527058800000
    },
    {
      "intervalIndex": "interval-77",
      "value": 199.0,
      "endTime": 1527055200000
    },
    {
      "intervalIndex": "interval-78",
      "value": 207.0,
      "endTime": 1527051600000
    },
    {
      "intervalIndex": "interval-79",
      "value": 198.0,
      "endTime": 1527048000000
    },
    {
      "intervalIndex": "interval-80",
      "value": 159.0,
      "endTime": 1527044400000
    },
    {
      "intervalIndex": "interval-81",
      "value": 198.0,
      "endTime": 1527040800000
    },
    {
      "intervalIndex": "interval-82",
      "value": 207.0,
      "endTime": 1527037200000
    },
    {
      "intervalIndex": "interval-83",
      "value": 198.0,
      "endTime": 1527033600000
    },
    {
      "intervalIndex": "interval-84",
      "value": 208.0,
      "endTime": 1527030000000
    },
    {
      "intervalIndex": "interval-85",
      "value": 207.0,
      "endTime": 1527026400000
    },
    {
      "intervalIndex": "interval-86",
      "value": 208.0,
      "endTime": 1527022800000
    },
    {
      "intervalIndex": "interval-87",
      "value": 198.0,
      "endTime": 1527019200000
    },
    {
      "intervalIndex": "interval-88",
      "value": 208.0,
      "endTime": 1527015600000
    },
    {
      "intervalIndex": "interval-89",
      "value": 199.0,
      "endTime": 1527012000000
    },
    {
      "intervalIndex": "interval-90",
      "value": 198.0,
      "endTime": 1527008400000
    },
    {
      "intervalIndex": "interval-91",
      "value": 199.0,
      "endTime": 1527004800000
    },
    {
      "intervalIndex": "interval-92",
      "value": 208.0,
      "endTime": 1527001200000
    },
    {
      "intervalIndex": "interval-93",
      "value": 206.0,
      "endTime": 1526997600000
    },
    {
      "intervalIndex": "interval-94",
      "value": 207.0,
      "endTime": 1526994000000
    },
    {
      "intervalIndex": "interval-95",
      "value": 208.0,
      "endTime": 1526990400000
    },
    {
      "intervalIndex": "interval-96",
      "value": 207.0,
      "endTime": 1526986800000
    },
    {
      "intervalIndex": "interval-97",
      "value": 176.0,
      "endTime": 1526983200000
    },
    {
      "intervalIndex": "interval-98",
      "value": 207.0,
      "endTime": 1526979600000
    },
    {
      "intervalIndex": "interval-99",
      "value": 198.0,
      "endTime": 1526976000000
    },
    {
      "intervalIndex": "interval-100",
      "value": 207.0,
      "endTime": 1526972400000
    },
    {
      "intervalIndex": "interval-101",
      "value": 207.0,
      "endTime": 1526968800000
    },
    {
      "intervalIndex": "interval-102",
      "value": 198.0,
      "endTime": 1526965200000
    },
    {
      "intervalIndex": "interval-103",
      "value": 198.0,
      "endTime": 1526961600000
    },
    {
      "intervalIndex": "interval-104",
      "value": 208.0,
      "endTime": 1526958000000
    },
    {
      "intervalIndex": "interval-105",
      "value": 207.0,
      "endTime": 1526954400000
    },
    {
      "intervalIndex": "interval-106",
      "value": 207.0,
      "endTime": 1526950800000
    },
    {
      "intervalIndex": "interval-107",
      "value": 207.0,
      "endTime": 1526947200000
    },
    {
      "intervalIndex": "interval-108",
      "value": 198.0,
      "endTime": 1526943600000
    },
    {
      "intervalIndex": "interval-109",
      "value": 207.0,
      "endTime": 1526940000000
    },
    {
      "intervalIndex": "interval-110",
      "value": -186.0,
      "endTime": 1526936400000
    },
    {
      "intervalIndex": "interval-111",
      "value": 200.0,
      "endTime": 1526932800000
    },
    {
      "intervalIndex": "interval-112",
      "value": 208.0,
      "endTime": 1526929200000
    },
    {
      "intervalIndex": "interval-113",
      "value": 209.0,
      "endTime": 1526925600000
    },
    {
      "intervalIndex": "interval-114",
      "value": 172.0,
      "endTime": 1526922000000
    },
    {
      "intervalIndex": "interval-115",
      "value": 198.0,
      "endTime": 1526918400000
    },
    {
      "intervalIndex": "interval-116",
      "value": 199.0,
      "endTime": 1526914800000
    },
    {
      "intervalIndex": "interval-117",
      "value": 208.0,
      "endTime": 1526911200000
    },
    {
      "intervalIndex": "interval-118",
      "value": 208.0,
      "endTime": 1526907600000
    },
    {
      "intervalIndex": "interval-119",
      "value": 206.0,
      "endTime": 1526904000000
    },
    {
      "intervalIndex": "interval-120",
      "value": 199.0,
      "endTime": 1526900400000
    },
    {
      "intervalIndex": "interval-121",
      "value": 208.0,
      "endTime": 1526896800000
    },
    {
      "intervalIndex": "interval-122",
      "value": 208.0,
      "endTime": 1526893200000
    },
    {
      "intervalIndex": "interval-123",
      "value": 198.0,
      "endTime": 1526889600000
    },
    {
      "intervalIndex": "interval-124",
      "value": 208.0,
      "endTime": 1526886000000
    },
    {
      "intervalIndex": "interval-125",
      "value": 208.0,
      "endTime": 1526882400000
    },
    {
      "intervalIndex": "interval-126",
      "value": 209.0,
      "endTime": 1526878800000
    },
    {
      "intervalIndex": "interval-127",
      "value": 198.0,
      "endTime": 1526875200000
    },
    {
      "intervalIndex": "interval-128",
      "value": 209.0,
      "endTime": 1526871600000
    },
    {
      "intervalIndex": "interval-129",
      "value": 190.0,
      "endTime": 1526868000000
    },
    {
      "intervalIndex": "interval-130",
      "value": 198.0,
      "endTime": 1526864400000
    },
    {
      "intervalIndex": "interval-131",
      "value": 208.0,
      "endTime": 1526860800000
    },
    {
      "intervalIndex": "interval-132",
      "value": 187.0,
      "endTime": 1526857200000
    },
    {
      "intervalIndex": "interval-133",
      "value": 209.0,
      "endTime": 1526853600000
    },
    {
      "intervalIndex": "interval-134",
      "value": 198.0,
      "endTime": 1526850000000
    },
    {
      "intervalIndex": "interval-135",
      "value": 192.0,
      "endTime": 1526846400000
    },
    {
      "intervalIndex": "interval-136",
      "value": 198.0,
      "endTime": 1526842800000
    },
    {
      "intervalIndex": "interval-137",
      "value": 208.0,
      "endTime": 1526839200000
    },
    {
      "intervalIndex": "interval-138",
      "value": 207.0,
      "endTime": 1526835600000
    },
    {
      "intervalIndex": "interval-139",
      "value": 192.0,
      "endTime": 1526832000000
    },
    {
      "intervalIndex": "interval-140",
      "value": 207.0,
      "endTime": 1526828400000
    },
    {
      "intervalIndex": "interval-141",
      "value": 207.0,
      "endTime": 1526824800000
    },
    {
      "intervalIndex": "interval-142",
      "value": 208.0,
      "endTime": 1526821200000
    },
    {
      "intervalIndex": "interval-143",
      "value": 208.0,
      "endTime": 1526817600000
    },
    {
      "intervalIndex": "interval-144",
      "value": 207.0,
      "endTime": 1526814000000
    },
    {
      "intervalIndex": "interval-145",
      "value": 199.0,
      "endTime": 1526810400000
    },
    {
      "intervalIndex": "interval-146",
      "value": 209.0,
      "endTime": 1526806800000
    },
    {
      "intervalIndex": "interval-147",
      "value": 208.0,
      "endTime": 1526803200000
    },
    {
      "intervalIndex": "interval-148",
      "value": 206.0,
      "endTime": 1526799600000
    },
    {
      "intervalIndex": "interval-149",
      "value": 208.0,
      "endTime": 1526796000000
    },
    {
      "intervalIndex": "interval-150",
      "value": 207.0,
      "endTime": 1526792400000
    },
    {
      "intervalIndex": "interval-151",
      "value": 199.0,
      "endTime": 1526788800000
    },
    {
      "intervalIndex": "interval-152",
      "value": 202.0,
      "endTime": 1526785200000
    },
    {
      "intervalIndex": "interval-153",
      "value": 208.0,
      "endTime": 1526781600000
    },
    {
      "intervalIndex": "interval-154",
      "value": 209.0,
      "endTime": 1526778000000
    },
    {
      "intervalIndex": "interval-155",
      "value": 208.0,
      "endTime": 1526774400000
    },
    {
      "intervalIndex": "interval-156",
      "value": 199.0,
      "endTime": 1526770800000
    },
    {
      "intervalIndex": "interval-157",
      "value": 208.0,
      "endTime": 1526767200000
    },
    {
      "intervalIndex": "interval-158",
      "value": 207.0,
      "endTime": 1526763600000
    },
    {
      "intervalIndex": "interval-159",
      "value": 208.0,
      "endTime": 1526760000000
    },
    {
      "intervalIndex": "interval-160",
      "value": 13.0,
      "endTime": 1526756400000
    },
    {
      "intervalIndex": "interval-161",
      "value": 202.0,
      "endTime": 1526752800000
    },
    {
      "intervalIndex": "interval-162",
      "value": 208.0,
      "endTime": 1526749200000
    },
    {
      "intervalIndex": "interval-163",
      "value": 197.0,
      "endTime": 1526745600000
    },
    {
      "intervalIndex": "interval-164",
      "value": 198.0,
      "endTime": 1526742000000
    },
    {
      "intervalIndex": "interval-165",
      "value": 208.0,
      "endTime": 1526738400000
    },
    {
      "intervalIndex": "interval-166",
      "value": 198.0,
      "endTime": 1526734800000
    },
    {
      "intervalIndex": "interval-167",
      "value": 162.0,
      "endTime": 1526731200000
    },
    {
      "intervalIndex": "interval-168",
      "value": 141.0,
      "endTime": 1526727600000
    }

      ],
      "averagePerformanceList": [{
      "intervalIndex": "interval-0",
      "value": 357.8412698412699,
      "endTime": 1527332400000
    },
    {
      "intervalIndex": "interval-1",
      "value": 353.5491666666666,
      "endTime": 1527328800000
    },
    {
      "intervalIndex": "interval-2",
      "value": 411.91709401709403,
      "endTime": 1527325200000
    },
    {
      "intervalIndex": "interval-3",
      "value": 467.5307692307692,
      "endTime": 1527321600000
    },
    {
      "intervalIndex": "interval-4",
      "value": 380.0635964912281,
      "endTime": 1527318000000
    },
    {
      "intervalIndex": "interval-5",
      "value": 395.55833333333334,
      "endTime": 1527314400000
    },
    {
      "intervalIndex": "interval-6",
      "value": 400.23153153153163,
      "endTime": 1527310800000
    },
    {
      "intervalIndex": "interval-7",
      "value": 485.87361111111113,
      "endTime": 1527307200000
    },
    {
      "intervalIndex": "interval-8",
      "value": 459.71111111111105,
      "endTime": 1527303600000
    },
    {
      "intervalIndex": "interval-9",
      "value": 433.7126126126126,
      "endTime": 1527300000000
    },
    {
      "intervalIndex": "interval-10",
      "value": 500.6736111111111,
      "endTime": 1527296400000
    },
    {
      "intervalIndex": "interval-11",
      "value": 334.2648148148148,
      "endTime": 1527292800000
    },
    {
      "intervalIndex": "interval-12",
      "value": 460.67156862745105,
      "endTime": 1527289200000
    },
    {
      "intervalIndex": "interval-13",
      "value": 443.89646464646466,
      "endTime": 1527285600000
    },
    {
      "intervalIndex": "interval-14",
      "value": 579.800925925926,
      "endTime": 1527282000000
    },
    {
      "intervalIndex": "interval-15",
      "value": 382.3904761904762,
      "endTime": 1527278400000
    },
    {
      "intervalIndex": "interval-16",
      "value": 470.09040404040405,
      "endTime": 1527274800000
    },
    {
      "intervalIndex": "interval-17",
      "value": 409.64193548387095,
      "endTime": 1527271200000
    },
    {
      "intervalIndex": "interval-18",
      "value": 388.3617647058823,
      "endTime": 1527267600000
    },
    {
      "intervalIndex": "interval-19",
      "value": 395.46666666666664,
      "endTime": 1527264000000
    },
    {
      "intervalIndex": "interval-20",
      "value": 383.67521367521374,
      "endTime": 1527260400000
    },
    {
      "intervalIndex": "interval-21",
      "value": 381.4640350877193,
      "endTime": 1527256800000
    },
    {
      "intervalIndex": "interval-22",
      "value": 542.8657894736841,
      "endTime": 1527253200000
    },
    {
      "intervalIndex": "interval-23",
      "value": 394.23829787234047,
      "endTime": 1527249600000
    },
    {
      "intervalIndex": "interval-24",
      "value": 426.1352941176471,
      "endTime": 1527246000000
    },
    {
      "intervalIndex": "interval-25",
      "value": 1453.9796296296295,
      "endTime": 1527242400000
    },
    {
      "intervalIndex": "interval-26",
      "value": 371.1823529411765,
      "endTime": 1527238800000
    },
    {
      "intervalIndex": "interval-27",
      "value": 459.80384615384617,
      "endTime": 1527235200000
    },
    {
      "intervalIndex": "interval-28",
      "value": 424.846,
      "endTime": 1527231600000
    },
    {
      "intervalIndex": "interval-29",
      "value": 486.48653846153843,
      "endTime": 1527228000000
    },
    {
      "intervalIndex": "interval-30",
      "value": 503.91866666666664,
      "endTime": 1527224400000
    },
    {
      "intervalIndex": "interval-31",
      "value": 500.62333333333333,
      "endTime": 1527220800000
    },
    {
      "intervalIndex": "interval-32",
      "value": 445.72222222222223,
      "endTime": 1527217200000
    },
    {
      "intervalIndex": "interval-33",
      "value": 438.3157894736842,
      "endTime": 1527213600000
    },
    {
      "intervalIndex": "interval-34",
      "value": 367.3488372093023,
      "endTime": 1527210000000
    },
    {
      "intervalIndex": "interval-35",
      "value": 303.02564102564105,
      "endTime": 1527206400000
    },
    {
      "intervalIndex": "interval-36",
      "value": 415.2875,
      "endTime": 1527202800000
    },
    {
      "intervalIndex": "interval-37",
      "value": 336.04901960784315,
      "endTime": 1527199200000
    },
    {
      "intervalIndex": "interval-38",
      "value": 316.3695652173913,
      "endTime": 1527195600000
    },
    {
      "intervalIndex": "interval-39",
      "value": 331.5625,
      "endTime": 1527192000000
    },
    {
      "intervalIndex": "interval-40",
      "value": 271.54347826086956,
      "endTime": 1527188400000
    },
    {
      "intervalIndex": "interval-41",
      "value": 355.3390151515151,
      "endTime": 1527184800000
    },
    {
      "intervalIndex": "interval-42",
      "value": 361.29166666666663,
      "endTime": 1527181200000
    },
    {
      "intervalIndex": "interval-43",
      "value": 346.8333333333333,
      "endTime": 1527177600000
    },
    {
      "intervalIndex": "interval-44",
      "value": 312.9868421052632,
      "endTime": 1527174000000
    },
    {
      "intervalIndex": "interval-45",
      "value": 366.38157894736844,
      "endTime": 1527170400000
    },
    {
      "intervalIndex": "interval-46",
      "value": 359.8904761904762,
      "endTime": 1527166800000
    },
    {
      "intervalIndex": "interval-47",
      "value": 590.2631578947369,
      "endTime": 1527163200000
    },
    {
      "intervalIndex": "interval-48",
      "value": 913.7552083333333,
      "endTime": 1527159600000
    },
    {
      "intervalIndex": "interval-49",
      "value": 907.2109375,
      "endTime": 1527156000000
    },
    {
      "intervalIndex": "interval-50",
      "value": 748.9558823529412,
      "endTime": 1527152400000
    },
    {
      "intervalIndex": "interval-51",
      "value": 645.8039215686274,
      "endTime": 1527148800000
    },
    {
      "intervalIndex": "interval-52",
      "value": 800.495238095238,
      "endTime": 1527145200000
    },
    {
      "intervalIndex": "interval-53",
      "value": 687.1041666666667,
      "endTime": 1527141600000
    },
    {
      "intervalIndex": "interval-54",
      "value": 715.3833333333333,
      "endTime": 1527138000000
    },
    {
      "intervalIndex": "interval-55",
      "value": 563.04,
      "endTime": 1527134400000
    },
    {
      "intervalIndex": "interval-56",
      "value": 908.9228395061727,
      "endTime": 1527130800000
    },
    {
      "intervalIndex": "interval-57",
      "value": 865.8076923076923,
      "endTime": 1527127200000
    },
    {
      "intervalIndex": "interval-58",
      "value": 975.6966666666666,
      "endTime": 1527123600000
    },
    {
      "intervalIndex": "interval-59",
      "value": 842.90625,
      "endTime": 1527120000000
    },
    {
      "intervalIndex": "interval-60",
      "value": 766.2118055555557,
      "endTime": 1527116400000
    },
    {
      "intervalIndex": "interval-61",
      "value": 920.8846153846155,
      "endTime": 1527112800000
    },
    {
      "intervalIndex": "interval-62",
      "value": 778.8188405797101,
      "endTime": 1527109200000
    },
    {
      "intervalIndex": "interval-63",
      "value": 895.9333333333334,
      "endTime": 1527105600000
    },
    {
      "intervalIndex": "interval-64",
      "value": 1099.9618055555554,
      "endTime": 1527102000000
    },
    {
      "intervalIndex": "interval-65",
      "value": 861.01,
      "endTime": 1527098400000
    },
    {
      "intervalIndex": "interval-66",
      "value": 627.6913580246915,
      "endTime": 1527094800000
    },
    {
      "intervalIndex": "interval-67",
      "value": 879.5777777777778,
      "endTime": 1527091200000
    },
    {
      "intervalIndex": "interval-68",
      "value": 599.5246913580247,
      "endTime": 1527087600000
    },
    {
      "intervalIndex": "interval-69",
      "value": 662.0535714285714,
      "endTime": 1527084000000
    },
    {
      "intervalIndex": "interval-70",
      "value": 725.5000000000001,
      "endTime": 1527080400000
    },
    {
      "intervalIndex": "interval-71",
      "value": 896.3703703703703,
      "endTime": 1527076800000
    },
    {
      "intervalIndex": "interval-72",
      "value": 926.3898809523808,
      "endTime": 1527073200000
    },
    {
      "intervalIndex": "interval-73",
      "value": 706.2233333333332,
      "endTime": 1527069600000
    },
    {
      "intervalIndex": "interval-74",
      "value": 638.68,
      "endTime": 1527066000000
    },
    {
      "intervalIndex": "interval-75",
      "value": 613.8125,
      "endTime": 1527062400000
    },
    {
      "intervalIndex": "interval-76",
      "value": 907.5766666666667,
      "endTime": 1527058800000
    },
    {
      "intervalIndex": "interval-77",
      "value": 995.9097222222223,
      "endTime": 1527055200000
    },
    {
      "intervalIndex": "interval-78",
      "value": 763.4642857142858,
      "endTime": 1527051600000
    },
    {
      "intervalIndex": "interval-79",
      "value": 1065.6916666666668,
      "endTime": 1527048000000
    },
    {
      "intervalIndex": "interval-80",
      "value": 672.5760869565217,
      "endTime": 1527044400000
    },
    {
      "intervalIndex": "interval-81",
      "value": 1045.5992063492063,
      "endTime": 1527040800000
    },
    {
      "intervalIndex": "interval-82",
      "value": 700.6031746031747,
      "endTime": 1527037200000
    },
    {
      "intervalIndex": "interval-83",
      "value": 872.6145833333334,
      "endTime": 1527033600000
    },
    {
      "intervalIndex": "interval-84",
      "value": 1051.5714285714287,
      "endTime": 1527030000000
    },
    {
      "intervalIndex": "interval-85",
      "value": 578.6340579710145,
      "endTime": 1527026400000
    },
    {
      "intervalIndex": "interval-86",
      "value": 834.3068181818181,
      "endTime": 1527022800000
    },
    {
      "intervalIndex": "interval-87",
      "value": 735.3295454545455,
      "endTime": 1527019200000
    },
    {
      "intervalIndex": "interval-88",
      "value": 423.86111111111114,
      "endTime": 1527015600000
    },
    {
      "intervalIndex": "interval-89",
      "value": 586.7638888888889,
      "endTime": 1527012000000
    },
    {
      "intervalIndex": "interval-90",
      "value": 591.5224358974359,
      "endTime": 1527008400000
    },
    {
      "intervalIndex": "interval-91",
      "value": 886.2466666666666,
      "endTime": 1527004800000
    },
    {
      "intervalIndex": "interval-92",
      "value": 889.9230769230769,
      "endTime": 1527001200000
    },
    {
      "intervalIndex": "interval-93",
      "value": 859.470238095238,
      "endTime": 1526997600000
    },
    {
      "intervalIndex": "interval-94",
      "value": 896.7844827586206,
      "endTime": 1526994000000
    },
    {
      "intervalIndex": "interval-95",
      "value": 967.0645161290323,
      "endTime": 1526990400000
    },
    {
      "intervalIndex": "interval-96",
      "value": 724.5885416666667,
      "endTime": 1526986800000
    },
    {
      "intervalIndex": "interval-97",
      "value": 760.3387096774195,
      "endTime": 1526983200000
    },
    {
      "intervalIndex": "interval-98",
      "value": 577.6640625,
      "endTime": 1526979600000
    },
    {
      "intervalIndex": "interval-99",
      "value": 733.274193548387,
      "endTime": 1526976000000
    },
    {
      "intervalIndex": "interval-100",
      "value": 764.4032258064515,
      "endTime": 1526972400000
    },
    {
      "intervalIndex": "interval-101",
      "value": 784.3035714285714,
      "endTime": 1526968800000
    },
    {
      "intervalIndex": "interval-102",
      "value": 689.3482142857143,
      "endTime": 1526965200000
    },
    {
      "intervalIndex": "interval-103",
      "value": 774.0172413793103,
      "endTime": 1526961600000
    },
    {
      "intervalIndex": "interval-104",
      "value": 640.8172043010753,
      "endTime": 1526958000000
    },
    {
      "intervalIndex": "interval-105",
      "value": 885.2231182795699,
      "endTime": 1526954400000
    },
    {
      "intervalIndex": "interval-106",
      "value": 526.0591397849463,
      "endTime": 1526950800000
    },
    {
      "intervalIndex": "interval-107",
      "value": 732.9375,
      "endTime": 1526947200000
    },
    {
      "intervalIndex": "interval-108",
      "value": 716.6925287356323,
      "endTime": 1526943600000
    },
    {
      "intervalIndex": "interval-109",
      "value": 670.7155172413793,
      "endTime": 1526940000000
    },
    {
      "intervalIndex": "interval-110",
      "value": 906.7142857142857,
      "endTime": 1526936400000
    },
    {
      "intervalIndex": "interval-111",
      "value": 883.661111111111,
      "endTime": 1526932800000
    },
    {
      "intervalIndex": "interval-112",
      "value": 697.4753086419753,
      "endTime": 1526929200000
    },
    {
      "intervalIndex": "interval-113",
      "value": 729.625,
      "endTime": 1526925600000
    },
    {
      "intervalIndex": "interval-114",
      "value": 901.3919753086419,
      "endTime": 1526922000000
    },
    {
      "intervalIndex": "interval-115",
      "value": 830.4166666666667,
      "endTime": 1526918400000
    },
    {
      "intervalIndex": "interval-116",
      "value": 902.0119047619047,
      "endTime": 1526914800000
    },
    {
      "intervalIndex": "interval-117",
      "value": 740.7113095238094,
      "endTime": 1526911200000
    },
    {
      "intervalIndex": "interval-118",
      "value": 667.5666666666667,
      "endTime": 1526907600000
    },
    {
      "intervalIndex": "interval-119",
      "value": 871.771505376344,
      "endTime": 1526904000000
    },
    {
      "intervalIndex": "interval-120",
      "value": 1068.592261904762,
      "endTime": 1526900400000
    },
    {
      "intervalIndex": "interval-121",
      "value": 1059.6722222222222,
      "endTime": 1526896800000
    },
    {
      "intervalIndex": "interval-122",
      "value": 863.996913580247,
      "endTime": 1526893200000
    },
    {
      "intervalIndex": "interval-123",
      "value": 961.5733333333333,
      "endTime": 1526889600000
    },
    {
      "intervalIndex": "interval-124",
      "value": 846.1602564102564,
      "endTime": 1526886000000
    },
    {
      "intervalIndex": "interval-125",
      "value": 702.301282051282,
      "endTime": 1526882400000
    },
    {
      "intervalIndex": "interval-126",
      "value": 641.8297101449275,
      "endTime": 1526878800000
    },
    {
      "intervalIndex": "interval-127",
      "value": 673.6597222222222,
      "endTime": 1526875200000
    },
    {
      "intervalIndex": "interval-128",
      "value": 771.6086956521739,
      "endTime": 1526871600000
    },
    {
      "intervalIndex": "interval-129",
      "value": 695.8266666666667,
      "endTime": 1526868000000
    },
    {
      "intervalIndex": "interval-130",
      "value": 563.2708333333333,
      "endTime": 1526864400000
    },
    {
      "intervalIndex": "interval-131",
      "value": 861.4102564102565,
      "endTime": 1526860800000
    },
    {
      "intervalIndex": "interval-132",
      "value": 789.8333333333331,
      "endTime": 1526857200000
    },
    {
      "intervalIndex": "interval-133",
      "value": 887.16,
      "endTime": 1526853600000
    },
    {
      "intervalIndex": "interval-134",
      "value": 1010.6634615384615,
      "endTime": 1526850000000
    },
    {
      "intervalIndex": "interval-135",
      "value": 915.2948717948719,
      "endTime": 1526846400000
    },
    {
      "intervalIndex": "interval-136",
      "value": 810.3506944444443,
      "endTime": 1526842800000
    },
    {
      "intervalIndex": "interval-137",
      "value": 776.3397435897436,
      "endTime": 1526839200000
    },
    {
      "intervalIndex": "interval-138",
      "value": 696.8066666666667,
      "endTime": 1526835600000
    },
    {
      "intervalIndex": "interval-139",
      "value": 751.2,
      "endTime": 1526832000000
    },
    {
      "intervalIndex": "interval-140",
      "value": 782.0347222222223,
      "endTime": 1526828400000
    },
    {
      "intervalIndex": "interval-141",
      "value": 660.5069444444443,
      "endTime": 1526824800000
    },
    {
      "intervalIndex": "interval-142",
      "value": 626.3466666666667,
      "endTime": 1526821200000
    },
    {
      "intervalIndex": "interval-143",
      "value": 420.8188405797102,
      "endTime": 1526817600000
    },
    {
      "intervalIndex": "interval-144",
      "value": 933.227564102564,
      "endTime": 1526814000000
    },
    {
      "intervalIndex": "interval-145",
      "value": 621.0381944444445,
      "endTime": 1526810400000
    },
    {
      "intervalIndex": "interval-146",
      "value": 703.4967948717949,
      "endTime": 1526806800000
    },
    {
      "intervalIndex": "interval-147",
      "value": 750.392857142857,
      "endTime": 1526803200000
    },
    {
      "intervalIndex": "interval-148",
      "value": 693.0,
      "endTime": 1526799600000
    },
    {
      "intervalIndex": "interval-149",
      "value": 668.3364197530865,
      "endTime": 1526796000000
    },
    {
      "intervalIndex": "interval-150",
      "value": 457.02976190476187,
      "endTime": 1526792400000
    },
    {
      "intervalIndex": "interval-151",
      "value": 629.5,
      "endTime": 1526788800000
    },
    {
      "intervalIndex": "interval-152",
      "value": 625.5698924731183,
      "endTime": 1526785200000
    },
    {
      "intervalIndex": "interval-153",
      "value": 552.7395833333333,
      "endTime": 1526781600000
    },
    {
      "intervalIndex": "interval-154",
      "value": 449.38709677419354,
      "endTime": 1526778000000
    },
    {
      "intervalIndex": "interval-155",
      "value": 640.3027777777777,
      "endTime": 1526774400000
    },
    {
      "intervalIndex": "interval-156",
      "value": 617.6586021505377,
      "endTime": 1526770800000
    },
    {
      "intervalIndex": "interval-157",
      "value": 516.4722222222223,
      "endTime": 1526767200000
    },
    {
      "intervalIndex": "interval-158",
      "value": 650.7660256410256,
      "endTime": 1526763600000
    },
    {
      "intervalIndex": "interval-159",
      "value": 554.1282051282051,
      "endTime": 1526760000000
    },
    {
      "intervalIndex": "interval-160",
      "value": 684.1742424242425,
      "endTime": 1526756400000
    },
    {
      "intervalIndex": "interval-161",
      "value": 1123.3115942028985,
      "endTime": 1526752800000
    },
    {
      "intervalIndex": "interval-162",
      "value": 826.5,
      "endTime": 1526749200000
    },
    {
      "intervalIndex": "interval-163",
      "value": 952.9528985507247,
      "endTime": 1526745600000
    },
    {
      "intervalIndex": "interval-164",
      "value": 624.6527777777778,
      "endTime": 1526742000000
    },
    {
      "intervalIndex": "interval-165",
      "value": 797.4782608695652,
      "endTime": 1526738400000
    },
    {
      "intervalIndex": "interval-166",
      "value": 776.3257575757575,
      "endTime": 1526734800000
    },
    {
      "intervalIndex": "interval-167",
      "value": 614.2361111111112,
      "endTime": 1526731200000
    },
    {
      "intervalIndex": "interval-168",
      "value": 747.3446969696971,
      "endTime": 1526727600000
    }

      ],
      "maxPerformanceList": [
        {
      "intervalIndex": "interval-0",
      "value": 1250.0,
      "endTime": 1527332400000
    },
    {
      "intervalIndex": "interval-1",
      "value": 3559.0,
      "endTime": 1527328800000
    },
    {
      "intervalIndex": "interval-2",
      "value": 3518.0,
      "endTime": 1527325200000
    },
    {
      "intervalIndex": "interval-3",
      "value": 3468.0,
      "endTime": 1527321600000
    },
    {
      "intervalIndex": "interval-4",
      "value": 3379.0,
      "endTime": 1527318000000
    },
    {
      "intervalIndex": "interval-5",
      "value": 3609.0,
      "endTime": 1527314400000
    },
    {
      "intervalIndex": "interval-6",
      "value": 4449.0,
      "endTime": 1527310800000
    },
    {
      "intervalIndex": "interval-7",
      "value": 4919.0,
      "endTime": 1527307200000
    },
    {
      "intervalIndex": "interval-8",
      "value": 4615.0,
      "endTime": 1527303600000
    },
    {
      "intervalIndex": "interval-9",
      "value": 3408.0,
      "endTime": 1527300000000
    },
    {
      "intervalIndex": "interval-10",
      "value": 4259.0,
      "endTime": 1527296400000
    },
    {
      "intervalIndex": "interval-11",
      "value": 3359.0,
      "endTime": 1527292800000
    },
    {
      "intervalIndex": "interval-12",
      "value": 3359.0,
      "endTime": 1527289200000
    },
    {
      "intervalIndex": "interval-13",
      "value": 3489.0,
      "endTime": 1527285600000
    },
    {
      "intervalIndex": "interval-14",
      "value": 4409.0,
      "endTime": 1527282000000
    },
    {
      "intervalIndex": "interval-15",
      "value": 3358.0,
      "endTime": 1527278400000
    },
    {
      "intervalIndex": "interval-16",
      "value": 3390.0,
      "endTime": 1527274800000
    },
    {
      "intervalIndex": "interval-17",
      "value": 3410.0,
      "endTime": 1527271200000
    },
    {
      "intervalIndex": "interval-18",
      "value": 3369.0,
      "endTime": 1527267600000
    },
    {
      "intervalIndex": "interval-19",
      "value": 3360.0,
      "endTime": 1527264000000
    },
    {
      "intervalIndex": "interval-20",
      "value": 3368.0,
      "endTime": 1527260400000
    },
    {
      "intervalIndex": "interval-21",
      "value": 3580.0,
      "endTime": 1527256800000
    },
    {
      "intervalIndex": "interval-22",
      "value": 4625.0,
      "endTime": 1527253200000
    },
    {
      "intervalIndex": "interval-23",
      "value": 3379.0,
      "endTime": 1527249600000
    },
    {
      "intervalIndex": "interval-24",
      "value": 3448.0,
      "endTime": 1527246000000
    },
    {
      "intervalIndex": "interval-25",
      "value": 29790.0,
      "endTime": 1527242400000
    },
    {
      "intervalIndex": "interval-26",
      "value": 3408.0,
      "endTime": 1527238800000
    },
    {
      "intervalIndex": "interval-27",
      "value": 3369.0,
      "endTime": 1527235200000
    },
    {
      "intervalIndex": "interval-28",
      "value": 3409.0,
      "endTime": 1527231600000
    },
    {
      "intervalIndex": "interval-29",
      "value": 3600.0,
      "endTime": 1527228000000
    },
    {
      "intervalIndex": "interval-30",
      "value": 3398.0,
      "endTime": 1527224400000
    },
    {
      "intervalIndex": "interval-31",
      "value": 3627.0,
      "endTime": 1527220800000
    },
    {
      "intervalIndex": "interval-32",
      "value": 3379.0,
      "endTime": 1527217200000
    },
    {
      "intervalIndex": "interval-33",
      "value": 3488.0,
      "endTime": 1527213600000
    },
    {
      "intervalIndex": "interval-34",
      "value": 4448.0,
      "endTime": 1527210000000
    },
    {
      "intervalIndex": "interval-35",
      "value": 3369.0,
      "endTime": 1527206400000
    },
    {
      "intervalIndex": "interval-36",
      "value": 4717.0,
      "endTime": 1527202800000
    },
    {
      "intervalIndex": "interval-37",
      "value": 3659.0,
      "endTime": 1527199200000
    },
    {
      "intervalIndex": "interval-38",
      "value": 3362.0,
      "endTime": 1527195600000
    },
    {
      "intervalIndex": "interval-39",
      "value": 3379.0,
      "endTime": 1527192000000
    },
    {
      "intervalIndex": "interval-40",
      "value": 1250.0,
      "endTime": 1527188400000
    },
    {
      "intervalIndex": "interval-41",
      "value": 3454.0,
      "endTime": 1527184800000
    },
    {
      "intervalIndex": "interval-42",
      "value": 3389.0,
      "endTime": 1527181200000
    },
    {
      "intervalIndex": "interval-43",
      "value": 3945.0,
      "endTime": 1527177600000
    },
    {
      "intervalIndex": "interval-44",
      "value": 1250.0,
      "endTime": 1527174000000
    },
    {
      "intervalIndex": "interval-45",
      "value": 4073.0,
      "endTime": 1527170400000
    },
    {
      "intervalIndex": "interval-46",
      "value": 3329.0,
      "endTime": 1527166800000
    },
    {
      "intervalIndex": "interval-47",
      "value": 4769.0,
      "endTime": 1527163200000
    },
    {
      "intervalIndex": "interval-48",
      "value": 4631.0,
      "endTime": 1527159600000
    },
    {
      "intervalIndex": "interval-49",
      "value": 6458.0,
      "endTime": 1527156000000
    },
    {
      "intervalIndex": "interval-50",
      "value": 4258.0,
      "endTime": 1527152400000
    },
    {
      "intervalIndex": "interval-51",
      "value": 3438.0,
      "endTime": 1527148800000
    },
    {
      "intervalIndex": "interval-52",
      "value": 3630.0,
      "endTime": 1527145200000
    },
    {
      "intervalIndex": "interval-53",
      "value": 3519.0,
      "endTime": 1527141600000
    },
    {
      "intervalIndex": "interval-54",
      "value": 4799.0,
      "endTime": 1527138000000
    },
    {
      "intervalIndex": "interval-55",
      "value": 4478.0,
      "endTime": 1527134400000
    },
    {
      "intervalIndex": "interval-56",
      "value": 3459.0,
      "endTime": 1527130800000
    },
    {
      "intervalIndex": "interval-57",
      "value": 3638.0,
      "endTime": 1527127200000
    },
    {
      "intervalIndex": "interval-58",
      "value": 3418.0,
      "endTime": 1527123600000
    },
    {
      "intervalIndex": "interval-59",
      "value": 3519.0,
      "endTime": 1527120000000
    },
    {
      "intervalIndex": "interval-60",
      "value": 3426.0,
      "endTime": 1527116400000
    },
    {
      "intervalIndex": "interval-61",
      "value": 4211.0,
      "endTime": 1527112800000
    },
    {
      "intervalIndex": "interval-62",
      "value": 4478.0,
      "endTime": 1527109200000
    },
    {
      "intervalIndex": "interval-63",
      "value": 4700.0,
      "endTime": 1527105600000
    },
    {
      "intervalIndex": "interval-64",
      "value": 6529.0,
      "endTime": 1527102000000
    },
    {
      "intervalIndex": "interval-65",
      "value": 4758.0,
      "endTime": 1527098400000
    },
    {
      "intervalIndex": "interval-66",
      "value": 3428.0,
      "endTime": 1527094800000
    },
    {
      "intervalIndex": "interval-67",
      "value": 3608.0,
      "endTime": 1527091200000
    },
    {
      "intervalIndex": "interval-68",
      "value": 3389.0,
      "endTime": 1527087600000
    },
    {
      "intervalIndex": "interval-69",
      "value": 3368.0,
      "endTime": 1527084000000
    },
    {
      "intervalIndex": "interval-70",
      "value": 4217.0,
      "endTime": 1527080400000
    },
    {
      "intervalIndex": "interval-71",
      "value": 3679.0,
      "endTime": 1527076800000
    },
    {
      "intervalIndex": "interval-72",
      "value": 6448.0,
      "endTime": 1527073200000
    },
    {
      "intervalIndex": "interval-73",
      "value": 4268.0,
      "endTime": 1527069600000
    },
    {
      "intervalIndex": "interval-74",
      "value": 3408.0,
      "endTime": 1527066000000
    },
    {
      "intervalIndex": "interval-75",
      "value": 3359.0,
      "endTime": 1527062400000
    },
    {
      "intervalIndex": "interval-76",
      "value": 4718.0,
      "endTime": 1527058800000
    },
    {
      "intervalIndex": "interval-77",
      "value": 4266.0,
      "endTime": 1527055200000
    },
    {
      "intervalIndex": "interval-78",
      "value": 3417.0,
      "endTime": 1527051600000
    },
    {
      "intervalIndex": "interval-79",
      "value": 4958.0,
      "endTime": 1527048000000
    },
    {
      "intervalIndex": "interval-80",
      "value": 3387.0,
      "endTime": 1527044400000
    },
    {
      "intervalIndex": "interval-81",
      "value": 4766.0,
      "endTime": 1527040800000
    },
    {
      "intervalIndex": "interval-82",
      "value": 3458.0,
      "endTime": 1527037200000
    },
    {
      "intervalIndex": "interval-83",
      "value": 4228.0,
      "endTime": 1527033600000
    },
    {
      "intervalIndex": "interval-84",
      "value": 6479.0,
      "endTime": 1527030000000
    },
    {
      "intervalIndex": "interval-85",
      "value": 4469.0,
      "endTime": 1527026400000
    },
    {
      "intervalIndex": "interval-86",
      "value": 3435.0,
      "endTime": 1527022800000
    },
    {
      "intervalIndex": "interval-87",
      "value": 3638.0,
      "endTime": 1527019200000
    },
    {
      "intervalIndex": "interval-88",
      "value": 3388.0,
      "endTime": 1527015600000
    },
    {
      "intervalIndex": "interval-89",
      "value": 3598.0,
      "endTime": 1527012000000
    },
    {
      "intervalIndex": "interval-90",
      "value": 3389.0,
      "endTime": 1527008400000
    },
    {
      "intervalIndex": "interval-91",
      "value": 3508.0,
      "endTime": 1527004800000
    },
    {
      "intervalIndex": "interval-92",
      "value": 4611.0,
      "endTime": 1527001200000
    },
    {
      "intervalIndex": "interval-93",
      "value": 3424.0,
      "endTime": 1526997600000
    },
    {
      "intervalIndex": "interval-94",
      "value": 6480.0,
      "endTime": 1526994000000
    },
    {
      "intervalIndex": "interval-95",
      "value": 6479.0,
      "endTime": 1526990400000
    },
    {
      "intervalIndex": "interval-96",
      "value": 4611.0,
      "endTime": 1526986800000
    },
    {
      "intervalIndex": "interval-97",
      "value": 4470.0,
      "endTime": 1526983200000
    },
    {
      "intervalIndex": "interval-98",
      "value": 3398.0,
      "endTime": 1526979600000
    },
    {
      "intervalIndex": "interval-99",
      "value": 3408.0,
      "endTime": 1526976000000
    },
    {
      "intervalIndex": "interval-100",
      "value": 3369.0,
      "endTime": 1526972400000
    },
    {
      "intervalIndex": "interval-101",
      "value": 4265.0,
      "endTime": 1526968800000
    },
    {
      "intervalIndex": "interval-102",
      "value": 3428.0,
      "endTime": 1526965200000
    },
    {
      "intervalIndex": "interval-103",
      "value": 4718.0,
      "endTime": 1526961600000
    },
    {
      "intervalIndex": "interval-104",
      "value": 3478.0,
      "endTime": 1526958000000
    },
    {
      "intervalIndex": "interval-105",
      "value": 4611.0,
      "endTime": 1526954400000
    },
    {
      "intervalIndex": "interval-106",
      "value": 3428.0,
      "endTime": 1526950800000
    },
    {
      "intervalIndex": "interval-107",
      "value": 3349.0,
      "endTime": 1526947200000
    },
    {
      "intervalIndex": "interval-108",
      "value": 3438.0,
      "endTime": 1526943600000
    },
    {
      "intervalIndex": "interval-109",
      "value": 3599.0,
      "endTime": 1526940000000
    },
    {
      "intervalIndex": "interval-110",
      "value": 3408.0,
      "endTime": 1526936400000
    },
    {
      "intervalIndex": "interval-111",
      "value": 3611.0,
      "endTime": 1526932800000
    },
    {
      "intervalIndex": "interval-112",
      "value": 4499.0,
      "endTime": 1526929200000
    },
    {
      "intervalIndex": "interval-113",
      "value": 4439.0,
      "endTime": 1526925600000
    },
    {
      "intervalIndex": "interval-114",
      "value": 4408.0,
      "endTime": 1526922000000
    },
    {
      "intervalIndex": "interval-115",
      "value": 4299.0,
      "endTime": 1526918400000
    },
    {
      "intervalIndex": "interval-116",
      "value": 4779.0,
      "endTime": 1526914800000
    },
    {
      "intervalIndex": "interval-117",
      "value": 4448.0,
      "endTime": 1526911200000
    },
    {
      "intervalIndex": "interval-118",
      "value": 3388.0,
      "endTime": 1526907600000
    },
    {
      "intervalIndex": "interval-119",
      "value": 3660.0,
      "endTime": 1526904000000
    },
    {
      "intervalIndex": "interval-120",
      "value": 6526.0,
      "endTime": 1526900400000
    },
    {
      "intervalIndex": "interval-121",
      "value": 4199.0,
      "endTime": 1526896800000
    },
    {
      "intervalIndex": "interval-122",
      "value": 3539.0,
      "endTime": 1526893200000
    },
    {
      "intervalIndex": "interval-123",
      "value": 3398.0,
      "endTime": 1526889600000
    },
    {
      "intervalIndex": "interval-124",
      "value": 6398.0,
      "endTime": 1526886000000
    },
    {
      "intervalIndex": "interval-125",
      "value": 3681.0,
      "endTime": 1526882400000
    },
    {
      "intervalIndex": "interval-126",
      "value": 3539.0,
      "endTime": 1526878800000
    },
    {
      "intervalIndex": "interval-127",
      "value": 4228.0,
      "endTime": 1526875200000
    },
    {
      "intervalIndex": "interval-128",
      "value": 4388.0,
      "endTime": 1526871600000
    },
    {
      "intervalIndex": "interval-129",
      "value": 3432.0,
      "endTime": 1526868000000
    },
    {
      "intervalIndex": "interval-130",
      "value": 3398.0,
      "endTime": 1526864400000
    },
    {
      "intervalIndex": "interval-131",
      "value": 6459.0,
      "endTime": 1526860800000
    },
    {
      "intervalIndex": "interval-132",
      "value": 4279.0,
      "endTime": 1526857200000
    },
    {
      "intervalIndex": "interval-133",
      "value": 6539.0,
      "endTime": 1526853600000
    },
    {
      "intervalIndex": "interval-134",
      "value": 4449.0,
      "endTime": 1526850000000
    },
    {
      "intervalIndex": "interval-135",
      "value": 4634.0,
      "endTime": 1526846400000
    },
    {
      "intervalIndex": "interval-136",
      "value": 3358.0,
      "endTime": 1526842800000
    },
    {
      "intervalIndex": "interval-137",
      "value": 3588.0,
      "endTime": 1526839200000
    },
    {
      "intervalIndex": "interval-138",
      "value": 3485.0,
      "endTime": 1526835600000
    },
    {
      "intervalIndex": "interval-139",
      "value": 3639.0,
      "endTime": 1526832000000
    },
    {
      "intervalIndex": "interval-140",
      "value": 4957.0,
      "endTime": 1526828400000
    },
    {
      "intervalIndex": "interval-141",
      "value": 3378.0,
      "endTime": 1526824800000
    },
    {
      "intervalIndex": "interval-142",
      "value": 4728.0,
      "endTime": 1526821200000
    },
    {
      "intervalIndex": "interval-143",
      "value": 3398.0,
      "endTime": 1526817600000
    },
    {
      "intervalIndex": "interval-144",
      "value": 3509.0,
      "endTime": 1526814000000
    },
    {
      "intervalIndex": "interval-145",
      "value": 3529.0,
      "endTime": 1526810400000
    },
    {
      "intervalIndex": "interval-146",
      "value": 3595.0,
      "endTime": 1526806800000
    },
    {
      "intervalIndex": "interval-147",
      "value": 3388.0,
      "endTime": 1526803200000
    },
    {
      "intervalIndex": "interval-148",
      "value": 3438.0,
      "endTime": 1526799600000
    },
    {
      "intervalIndex": "interval-149",
      "value": 3399.0,
      "endTime": 1526796000000
    },
    {
      "intervalIndex": "interval-150",
      "value": 3359.0,
      "endTime": 1526792400000
    },
    {
      "intervalIndex": "interval-151",
      "value": 3458.0,
      "endTime": 1526788800000
    },
    {
      "intervalIndex": "interval-152",
      "value": 3479.0,
      "endTime": 1526785200000
    },
    {
      "intervalIndex": "interval-153",
      "value": 6398.0,
      "endTime": 1526781600000
    },
    {
      "intervalIndex": "interval-154",
      "value": 3419.0,
      "endTime": 1526778000000
    },
    {
      "intervalIndex": "interval-155",
      "value": 3389.0,
      "endTime": 1526774400000
    },
    {
      "intervalIndex": "interval-156",
      "value": 3536.0,
      "endTime": 1526770800000
    },
    {
      "intervalIndex": "interval-157",
      "value": 3500.0,
      "endTime": 1526767200000
    },
    {
      "intervalIndex": "interval-158",
      "value": 4680.0,
      "endTime": 1526763600000
    },
    {
      "intervalIndex": "interval-159",
      "value": 4311.0,
      "endTime": 1526760000000
    },
    {
      "intervalIndex": "interval-160",
      "value": 3398.0,
      "endTime": 1526756400000
    },
    {
      "intervalIndex": "interval-161",
      "value": 29418.0,
      "endTime": 1526752800000
    },
    {
      "intervalIndex": "interval-162",
      "value": 4229.0,
      "endTime": 1526749200000
    },
    {
      "intervalIndex": "interval-163",
      "value": 4621.0,
      "endTime": 1526745600000
    },
    {
      "intervalIndex": "interval-164",
      "value": 3408.0,
      "endTime": 1526742000000
    },
    {
      "intervalIndex": "interval-165",
      "value": 3589.0,
      "endTime": 1526738400000
    },
    {
      "intervalIndex": "interval-166",
      "value": 4408.0,
      "endTime": 1526734800000
    },
    {
      "intervalIndex": "interval-167",
      "value": 4256.0,
      "endTime": 1526731200000
    },
    {
      "intervalIndex": "interval-168",
      "value": 5008.0,
      "endTime": 1526727600000
    }

      ]
    }
  ]
}
});
