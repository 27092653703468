define({
    "WIRELESS_TEMPLATE": [  
   {  
      "instanceType":"rfprofile",
      "instanceUuid":"d14d6ca9-bd39-456d-9910-ce11613b2047",
      "namespace":"wlan",
      "type":"rfprofile.setting",
      "key":"rfprofile.info",
      "version":2,
      "value":[  
         {  
            "rfProfileName":"LOW",
            "isARadioType":true,
            "isBRadioType":true,
            "channelWidth":"20",
            "aRadioChannels":"36,40,44,48,52,56,60,64,149,153,157,161",
            "bRadioChannels":"1,6,11",
            "dataRates":"1,2,5.5,6,9,11,12,18,24,36,48,54",
            "isCustom":false
         },
         {  
            "rfProfileName":"HIGH",
            "isARadioType":true,
            "isBRadioType":true,
            "channelWidth":"20",
            "aRadioChannels":"36,40,44,48,52,56,60,64,149,153,157,161",
            "bRadioChannels":"1,6,11",
            "dataRates":"12,18,24,36,48,54",
            "isCustom":false
         },
         {  
            "rfProfileName":"TYPICAL",
            "isARadioType":true,
            "isBRadioType":true,
            "channelWidth":"20",
            "aRadioChannels":"36,40,44,48,52,56,60,64,149,153,157,161",
            "bRadioChannels":"1,6,11",
            "dataRates":"6,9,12,18,24,36,48,54",
            "isCustom":false
         }
      ],
      "groupUuid":"-1",
      "inheritedGroupUuid":"",
      "inheritedGroupName":""
   },
   {  
      "instanceType":"wlan",
      "instanceUuid":"036c45c2-8e3e-44dc-9fd1-ccc504ada6ee",
      "namespace":"wlan",
      "type":"wlan.setting",
      "key":"wlan.info",
      "version":2,
      "value":[  
         {  
            "profileName":"",
            "ssid":"CorporateWifi",
            "wlanType":"Enterprise",
            "isFastLaneEnabled":true,
            "authType":"wpa2_enterprise",
            "authServer":"auth_ise",
            "passphrase":"",
            "peerIp":"",
            "scalableGroupTag":"",
            "trafficType":"voicedata",
            "isMacFilteringEnabled":false,
            "isEnabled":true,
            "isEmailReqd":false,
            "isFabric":false,
            "fabricId":null,
            "redirectUrl":"",
            "authSecServer":null,
            "portalType":"",
            "redirectUrlType":"",
            "externalAuthIpAddress":"",
            "portalName":""
         }
      ],
      "groupUuid":"-1",
      "inheritedGroupUuid":"",
      "inheritedGroupName":""
   }
]
});
