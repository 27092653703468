define({
  "USER_MGMT_Template": {
    "response":{
      "users":[{
        "authSource":"internal",
        "passphrase":null,
        "oldPassphrase":null,
        "roleList":["5a1d0659182cb264a098d321"],
        "userId":"5a1d06965d074e008fa67f4c",
        "username":"admin"
      }]
    }
  }
});
