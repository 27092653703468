define({
"Template":
{
  "version": "1.0",
  "response": {
    "id": "XGlncmsBAhyLSE4BcTWW",
    "name": "ap_flap",
    "enabled": true,
    "flattened": true,
    "entityType": "network_device",
    "entityName": "Unknown",
    "entity": "70:69:5A:51:3F:A0",
    "groupBy": "Unknown",
    "category": "Availability",
    "severity": "MEDIUM",
    "summary": "AP \"DeviceNamePlaceHolder\" has flapped.",
    "groupId": "ap_flap_grouping",
    "groupName": "AP has flapped",
    "scope": "GLOBAL",
    "priority": "P3",
    "rootCause": "Unknown",
    "timestamp": 1560992501125,
    "description": "This AP DeviceNamePlaceHolder has flapped from WLC Unknown and joined WLC ConnectedWlcNamePlaceHolder . At the time of the flap, AP was connected to switch BLD2-FLR2-ACCESS - port TwoGigabitEthernet2/0/16 ",
    "suggestions": [
      {
        "message": "Verify if there is any increase in packet drops/errors on the current switch BLD2-FLR2-ACCESS port TwoGigabitEthernet2/0/16 to which AP is connected.",
        "steps": [
          {
            "entityId": "DeviceIdPlaceHolder",
            "description": "Verify if there are any packet drops/errors",
            "command": "show interface TwoGigabitEthernet2/0/16 summary",
            "stepType": "command-Runner"
          }
        ]
      },
      {
        "message": "Check whether PoE is enabled on the current switch BLD2-FLR2-ACCESS port TwoGigabitEthernet2/0/16 to which AP is connected.",
        "steps": [
          {
            "entityId": "DeviceIdPlaceHolder",
            "description": "Check whether PoE is enabled",
            "command": "show power inline TwoGigabitEthernet2/0/16 detail",
            "stepType": "command-Runner"
          }
        ]
      },
      {
        "message": "Check the condition of the copper cable by running TDR (Time Domain Reflector) test on switch BLD2-FLR2-ACCESS port TwoGigabitEthernet2/0/16 . (Warning: Running this step might be disruptive to the connection)",
        "steps": [
          {
            "entityId": "DeviceIdPlaceHolder",
            "description": "Start the TDR test, check the result in subsequent steps. (Warning: This command might fail for some interface types)",
            "command": "test cable-diagnostics tdr interface TwoGigabitEthernet2/0/16",
            "stepType": "command-Runner"
          }
        ]
      },
      {
        "message": "Check the result of TDR (Time Domain Reflector) test. (Warning: Please wait for a few seconds before checking the results)",
        "steps": [
          {
            "entityId": "DeviceIdPlaceHolder",
            "description": "Check the result of the TDR test on the switch",
            "command": "show cable-diagnostics tdr interface TwoGigabitEthernet2/0/16",
            "stepType": "command-Runner"
          }
        ]
      },
      {
        "message": "Use the Path Trace tool to verify whether the AP can communicate with the WLC via UDP ports: 5246 & 5247 (CAPWAP)."
      },
      {
        "message": "Contact Cisco TAC World Wide – https://www.cisco.com/c/en/us/support/web/tsd-cisco-worldwide-contacts.html"
      }
    ],
    "additionalParams": [
      {
        "key": "__key__",
        "value": "NetworkDevice:70:69:5A:51:3F:A0"
      },
      {
        "key": "sourceDeviceType",
        "value": "1"
      },
      {
        "key": "portId",
        "value": "TwoGigabitEthernet2/0/16"
      },
      {
        "key": "type",
        "value": "issue"
      },
      {
        "key": "uuid",
        "value": "d67b62cd-bc57-43b0-b3e1-5ae7a8a9835b"
      },
      {
        "key": "apName",
        "value": "AP4800.606E"
      },
      {
        "key": "lastFailureReason",
        "value": "LINK_FAILURE"
      },
      {
        "key": "wlcCurrent",
        "value": "d67b62cd-bc57-43b0-b3e1-5ae7a8a9835b"
      },
      {
        "key": "duration",
        "value": "0"
      },
      {
        "key": "upEventTime",
        "value": "1560992503619"
      },
      {
        "key": "switchName",
        "value": "BLD2-FLR2-ACCESS"
      },
      {
        "key": "downStartTime",
        "value": "0"
      },
      {
        "key": "scope",
        "value": "global"
      },
      {
        "key": "flapCount",
        "value": "0"
      },
      {
        "key": "__suppression__",
        "value": "1800"
      },
      {
        "key": "wlcCount",
        "value": "0"
      },
      {
        "key": "entityType",
        "value": "network_device"
      },
      {
        "key": "__entity_type__",
        "value": "NetworkDevice"
      },
      {
        "key": "wlcOld",
        "value": ""
      },
      {
        "key": "apMac",
        "value": "70:69:5A:51:3F:A0"
      },
      {
        "key": "entityId",
        "value": "70:69:5A:51:3F:A0"
      },
      {
        "key": "priority",
        "value": "P3"
      },
      {
        "key": "macAddress",
        "value": "70:69:5A:51:3F:A0"
      },
      {
        "key": "category",
        "value": "availability"
      },
      {
        "key": "timestring",
        "value": "2019-06-20T01:01:41.125+0000"
      },
      {
        "key": "status",
        "value": "active"
      }
    ],
    "status": {
      "status": "ACTIVE",
      "updatedBy": "Unknown",
      "notes": "Unknown",
      "source": "Unknown",
      "updatedAt": null,
      "ignoreSource": "Unknown",
      "ignoreValue": "Unknown",
      "ignoreStartTime": null,
      "ignoreEndTime": null
    },
    "siteQulaifiedName": null
  }
}
})