define([
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/database/SimLokiDatabaseActions',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/app-hoisting/IotServicesTemplate',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/utilities/SimulationUtility',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/utilities/UtilityFunctions'
], function(SimLokiDatabaseActions, IotServicesTemplate, SimulationUtility, UtilityFunctions){

    return {
        init: function () {
        },

        doProcessRestRequest: function (urlAction) {
            var data;
            switch (urlAction.method) {
                case 'GET':
                    data = processGetRequest(urlAction);
                    break;
                case 'POST':
                    data = processPostRequest(urlAction);
                    break;
                case 'PUT':
                    data = processPutRequest(urlAction);
                    break;
                default:
                    console.log('other requests are not handled yet..')
                    break;
            }
            return data;
        }
    };

    function getapsData(aps){
        var apsDatas = aps.map(e => {
                var sumary = JSON.parse(JSON.stringify(IotServicesTemplate.AppHosting_NetworkDevices_ThousandEyes_Summary))
                let siteInfo = SimLokiDatabaseActions.getFilteredRecordHandler('site',{'id':e.siteId});
                sumary.networkData.id = e.id;
                sumary.networkData.softwareVersion = e.softwareVersion;
                sumary.networkData.hostname = e.hostname;
                sumary.networkData.serialNumber = e.serialNumber;
                sumary.networkData.lastUpdateTime = e.lastUpdateTime;
                sumary.networkData.upTime = e.upTime;
                sumary.networkData.site.name = siteInfo[0].name
                sumary.networkData.site.id = siteInfo[0].id
                sumary.networkData.site.groupNameHierarchy = siteInfo[0].groupNameHierarchy
                sumary.networkData.lastUpdated = e.lastUpdated
                sumary.networkData.managementIpAddress = e.managementIpAddress
                sumary.networkData.macAddress = e.macAddress
                sumary.networkData.instanceUuid = e.instanceUuid
                sumary.ipAddress = "10.16.55.6"   //need to change
                
                sumary.deviceId = e.id   
                sumary.instanceUuid = e.instanceUuid                     
                sumary.creationDate = 0
                
                sumary.ipAddress = e.managementIpAddress                    
                sumary.hostname = e.hostname
                sumary.serialNumber = e.serialNumber
                if(e.hostname == "SJC01-C9300-01" || e.hostname == "SJC01-C9300-02"){
                    sumary['appHostingStatus'] = "Ready"
                    sumary['desiredStatus'] = "RUNNING"
                    sumary['capabilityMatchMsgType'] = "COMPATIBLE"
                    sumary['displayMessage'] = ""
                    sumary['errorMessage'] = ""
                    sumary['message'] = ""
                    sumary.lastHeardTime = UtilityFunctions.getTimeStamp()
                    sumary.lastRefreshTime = UtilityFunctions.getTimeStamp()
                }else{
                    sumary['appHostingStatus'] = "Not Ready"
                    sumary['desiredStatus'] = "RUNNING"
                    sumary['capabilityMatchMsgType'] = "COMPATIBLE"
                    sumary['displayMessage'] = ""
                    sumary['errorMessage'] = ""
                    sumary['message'] = ""
                    sumary['messageCode'] = "ERR_AH_8031"
                    sumary.lastHeardTime = 0
                    sumary.lastRefreshTime = 0

                }
                // sumary.networkData = e
            
                // data.push(sumary)
                return sumary
        })
        return apsDatas
    }
    function processGetRequest(urlAction){
        if(urlAction.service.indexOf('appmgr') > -1 ){
            if(urlAction.service.indexOf('apps') > -1){
                let Json = JSON.parse(JSON.stringify(IotServicesTemplate.Template))
                if(urlAction.service.indexOf('55058C1E2DF3D8BD484A229F1474F496')>-1 && urlAction.service.indexOf('4.2.2') >-1){
                    if(urlAction.service.indexOf('jobs') >-1){
                        let data = SimLokiDatabaseActions.getFilteredRecordHandler("apphoisting-jobid",{'jobId':urlAction.action.id})
                        if(data.length >=1){
                            let Json = JSON.parse(JSON.stringify(IotServicesTemplate.Job_Info_Template))
                            Json.progress.startTime = UtilityFunctions.getTimeStamp()
                            return Json
                        }else{
                            return {"data":data}   
                        }
                    }else if(urlAction.service.indexOf('icon')>-1){
                        return {"code":2102,"description":"resource icon with given path 2E1B0685A7FA900599CADBBE5E1A8B70 cannot be found.",
                        "response":{isError:true, errorCode:404}}
                    }else{
                        return Json.data[1]
                    }
                }
                if(urlAction.service.indexOf('55058C1E2DF3D8BD484A229F1474F496')>-1 && urlAction.service.indexOf('latest') >-1){
                    return Json.data[1]
                }
                if(urlAction.service.indexOf('latest') > -1){
                    //Json.data[0].icon.href = "/api/v1/appmgr/apps/2E1B0685A7FA900599CADBBE5E1A8B70/4.0.0-beta.8-3/icon";
                    return Json.data[0]
                }
                if (urlAction.filter.indexOf("searchByName") > -1) {
                    //Json.data[0].icon.href = "/api/v1/appmgr/apps/2E1B0685A7FA900599CADBBE5E1A8B70/4.0.0-beta.8-3/icon";
                    return Json;
                }
                if(urlAction.service.indexOf('versions') > -1){ 
                    return {"data":["4.2.2", "4.0.2","4.0.0-beta.8-3"]}
                }                
                if(urlAction.service.indexOf('icon') > -1){
                    return {"code":2102,"description":"resource icon with given path 2E1B0685A7FA900599CADBBE5E1A8B70 cannot be found.",
                    "response":{isError:true, errorCode:404}}
                }
                if(urlAction.service.indexOf('jobs')>-1){
                    if(['WAITING,SUBMITTED,STARTED'].indexOf(urlAction.filter.jobStatuses)>-1){                         
                        return {"data":[]}
                    }                    
                    if(urlAction.action.id){ 
                        let curTime = UtilityFunctions.getTimeStamp()                        
                        if(!window.localStorage.futureCheckTime){                            
                            let delayedTime = curTime+ (10*1000) 
                            window.localStorage.setItem('futureCheckTime', delayedTime)                            
                        }
                        let futureTime = parseInt(window.localStorage.getItem('futureCheckTime'))                        
                        if(futureTime && (curTime>=futureTime)){
                            window.localStorage.removeItem('futureCheckTime')                            
                            return {"appId":"2E1B0685A7FA900599CADBBE5E1A8B70","appVersion":"4.0.0-beta.8-3","jobId":urlAction.action.id,"type":"APP_INSTALL","jobType":"APP_DEPLOY","progress":{"percentage":100,"startTime":curTime,"info":{"total":1,"completed":1,"inProgress":0,"failed":0,"estimatedTime":0}}}                                                            
                        }else {
                            return {"appId":"2E1B0685A7FA900599CADBBE5E1A8B70","appVersion":"4.0.0-beta.8-3","jobId":urlAction.action.id,"type":"APP_INSTALL","jobType":"APP_DEPLOY","progress":{"percentage":0,"startTime":curTime,"info":{"total":1,"completed":0,"inProgress":1,"failed":0,"estimatedTime":0}}}
                        }                        
                    }                   
                }
                if(urlAction.service.indexOf('summaryState')> -1 && urlAction.filter['getUpgradableCount'] === "true"){                    
                    let jobData = SimLokiDatabaseActions.getAll('apphoisting-jobid')
                    //let devices = [...new Set(jobData.map(obj => obj.deviceIds).flat())]
                    let json = JSON.parse(JSON.stringify(IotServicesTemplate.SummaryTemplate))
                    if(jobData.length>0){ 
                        json['4.0.0-beta.8-3'].summary.TOTAL_INSTALL_COUNT = jobData.length
                    }else { 
                        json['4.0.0-beta.8-3'].summary.TOTAL_INSTALL_COUNT = 0
                    }
                    return json
                }
                if(urlAction.service.indexOf('configurations')> -1){ 
                    let Json = JSON.parse(JSON.stringify(IotServicesTemplate.configuration_Template))
                    return Json
                }
                //handles after sucessful Installation
                if(urlAction.service.indexOf('devices')> -1 && urlAction.action.id){
                    let aps = SimLokiDatabaseActions.getFilteredRecordHandler('network-device',{'id': urlAction.action.id})
                    if(aps[0].name == "SJC01-C9300-01" || aps[0].name == "SJC01-C9300-02"){
                        let tData = [];
                        let sumary = JSON.parse(JSON.stringify(IotServicesTemplate.ThousandEyes_apps_data_Template))
                        sumary.deviceId = aps[0].id
                        sumary.ipAddress = aps[0].managementIpAddress
                        sumary.ipaddressInfo = aps[0].managementIpAddress
                        sumary.hostname = aps[0].hostname
                        sumary.platformVersion = aps[0].platformVersion
                        sumary.serialNumber = aps[0].serialNumber
                        sumary.lastHeardTime = UtilityFunctions.getTimeStamp() - (60*1000)
                        sumary.intentResourceAsk.startup.runtime_options = sumary.intentResourceAsk.startup.runtime_options.replace(/SYSTEM_NAME/g, aps[0].hostname)
                        sumary.operationalResourceAsk.startup.runtime_options = sumary.operationalResourceAsk.startup.runtime_options.replace(/SYSTEM_NAME/g, aps[0].hostname)
                        sumary.resourceAsk.startup.runtime_options = sumary.resourceAsk.startup.runtime_options.replace(/SYSTEM_NAME/g, aps[0].hostname)
                        sumary.networkInfo.eth0.ipv4 = aps[0].managementIpAddress
                        sumary.networkInfo.eth0.mac = aps[0].macAddress.toUpperCase();
                        sumary.networkInfo.eth0.mac_address = aps[0].macAddress
                        delete sumary.networkData
                        if(urlAction.service.indexOf('55058C1E2DF3D8BD484A229F1474F496')>-1){
                            return sumary
                        }else{
                            tData.push(sumary)
                            return {'data':tData}
                        }
                        
                    }
                    if(urlAction.service.indexOf('logs')>-1){
                        var deviceId = urlAction.action.id                    
                        if(urlAction.filter['filename'] && urlAction.filter['caf_downloadurl']){                        
                            let json = JSON.parse(JSON.stringify(IotServicesTemplate.Logmessgae_Template))
                            let time = Math.floor(new Date().getTime()/1000.0)
                            let repContent = json.content
                            json.content = repContent.replace(/TimePlaceHolder/g,time)
                            return json                        
                        }else { 
                            let json = JSON.parse(JSON.stringify(IotServicesTemplate.LogFileTemplate))
                            for (let ind in json){
                                let href = json[ind]._link.href;
                                json[ind]._link.href = href.replace(/DeviceIdplaceholder/g,deviceId)
                            }
                            return json
                        }                    
                    }else { 
                        let tJson = JSON.parse(JSON.stringify(IotServicesTemplate.succesInstallation_Template))
                        let deviceInfo = SimLokiDatabaseActions.getFilteredRecordHandler('network-device',{'id':urlAction.action.id})
                        let updatedLinks =  updateDeviceId(tJson.data[0].links,urlAction.action.id)
                        let ipAdress = deviceInfo[0].managementIpAddress
                        tJson.data[0].apps = ["MeshConnect"]
                        tJson.data[0].name = deviceInfo[0].hostname
                        tJson.data[0].links = updatedLinks
                        tJson.data[0].ipaddressInfo = `${ipAdress}:9000`
                        tJson.data[0].networkInfo.eth0.ipv4 = deviceInfo[0].managementIpAddress
                        tJson.data[0].networkInfo.eth0.mac = deviceInfo[0].macAddress
                        tJson.data[0].networkInfo.eth0.mac_address = deviceInfo[0].macAddress
                        if(urlAction.service.indexOf('2E1B0685A7FA900599CADBBE5E1A8B70')>-1){ 
                            return tJson.data[0]
                        }else { 
                            return tJson
                        }
                    }                                        
                }
                if(urlAction.service.indexOf('4.0.0-beta.8-3') > -1){ 
                    return Json.data[0]
                }
                return Json
            }
            if(urlAction.service.indexOf('devices')>-1 && urlAction.filter['detail'] === 'false'){                 
                let jobId  = urlAction.action.id  , response = []              
                let data = SimLokiDatabaseActions.getFilteredRecordHandler('apphoisting-jobid',{'jobId':jobId})
                //let deviceIds = [...new Set(data.map(obj => obj.deviceIds).flat())]
                data.map(obj =>{
                    let tJson = JSON.parse(JSON.stringify(IotServicesTemplate.device_job_summaryTemplate))
                    let deviceInfo = SimLokiDatabaseActions.getFilteredRecordHandler('network-device',{'id':obj.deviceId})
                    tJson.ipAddress = deviceInfo[0].managementIpAddress
                    tJson.deviceId =  deviceInfo[0].id
                    tJson.serialNumber =  deviceInfo[0].serialNumber
                    tJson.hostname =  deviceInfo[0].hostname
                    response.push(tJson)
                })
                return { 'data': response}
            }
            if(urlAction.url.indexOf('devices')>-1 && urlAction.url.indexOf('metadata')>-1){
                let count = 0                
                let appHoistJob = SimLokiDatabaseActions.getFilteredRecordHandler("apphoisting-jobid",{'appName':urlAction.filter['searchByApp']})
                // if(appHoistJob.length>0){
                //     count = [...new Set(appHoistJob.map(a => a.deviceId))].length
                // }
                if(urlAction.filter['searchByApp'] == "ThousandEyes Enterprise Agent"){
                    if(appHoistJob.length == 0){
                        count = 1
                    }else{
                        count = 2
                    }
                }else{
                    count = [...new Set(appHoistJob.map(a => a.deviceId))].length
                }
                return {"count":count}
            }
            if(urlAction.service.indexOf('about')>-1){
                return {"apiVersion":"1.0","releaseVersion": "1.9.02204011423", "builtOn": "1 Apr 2022 14:33:14", "appsStorageLimit": 5368709120, "appsCombinedSize": 592960723,"appsUploadLimit":20}
            }
            if(urlAction.service.indexOf('network-device')>-1 && urlAction.service.indexOf('troubleshoot')>-1){
                var netDeviceObj = SimLokiDatabaseActions.getFilteredRecordHandler('network-device',{'id':urlAction.action.id})
                if(netDeviceObj[0].hostname == "SJC01-C9300-01" || netDeviceObj[0].hostname == "SJC01-C9300-02"){
                    var tJson = JSON.parse(JSON.stringify(IotServicesTemplate.TroubleShoot_Info_Template))
                }else{
                    var tJson = JSON.parse(JSON.stringify(IotServicesTemplate.TroubleShoot_Info_Template1))
                }
                return {"response":tJson,"version":"1.0","time":UtilityFunctions.getTimeStamp()}
            }
            if(urlAction.service.indexOf('network-device')>-1){ 
                let aps = [],appHoistJob, devicesList, searchByStatus = "ALL", childrenSites = [];
                if(urlAction.service.indexOf('duplicates')>-1){
                    return {"devices":[]}
                }
                if(urlAction.filter.siteGroupHierarchy){
                    let family = urlAction.filter.family 
                    let siteId = urlAction.filter.siteGroupHierarchy
                    siteId = siteId.split('.*').join('')
                    SimulationUtility.getChildrenSites(siteId, childrenSites);
                    let sites = childrenSites.map(e=>e.id);
                    sites.push(siteId);
                    window.tempSites = sites.slice()                    
                    aps = SimLokiDatabaseActions.getFilteredRecordHandler('network-device', {'$and': 
                        [ {'family':'Unified AP'}, {'siteId':{'$in':sites}} ]})           
                    aps = aps.filter(e => e.platformId.includes("91") && !e.platformId.includes("9105AXI") && !e.platformId.includes("9124AXI"))                    
                }if(urlAction.service.indexOf('app-device') >-1 ){
                    searchByStatus = (urlAction.filter.searchByStatus) ? urlAction.filter.searchByStatus : searchByStatus
                    appHoistJob = SimLokiDatabaseActions.getAll("apphoisting-jobid")
                    //devicesList = [...new Set(appHoistJob.map(obj => obj.deviceIds).flat())]
                    devicesList = [...new Set(appHoistJob.map(obj => obj.deviceId))]
                    //handles Global section
                    if(!urlAction.filter.siteGroupHierarchy){                        
                        aps = SimLokiDatabaseActions.getFilteredRecordHandler('network-device',{'id': { '$containsAny' : devicesList}})
                    }else {    //handles all other site selection             
                        aps = aps.filter(function(obj) {
                            return devicesList.includes(obj.id)
                        });
                    }                    
                }
                let data = []
                if(urlAction.filter['searchByApp'] == "ThousandEyes Enterprise Agent"){
                    appHoistJob = SimLokiDatabaseActions.getAll("apphoisting-jobid")
                    if(urlAction.filter['searchByAppId'] == "55058C1E2DF3D8BD484A229F1474F496"){
                        if(appHoistJob.length !=0){
                            var netDevices = ['SJC01-C9300-01', 'SJC01-C9300-02'];
                            for(var i=0;i<netDevices.length;i++){
                                aps = SimLokiDatabaseActions.getFilteredRecordHandler('network-device',{'hostname':netDevices[i]});
                                var apsData = getapsData(aps)
                                data.push(apsData[0])
                            }
                        }else{
                            aps = SimLokiDatabaseActions.getFilteredRecordHandler('network-device',{'hostname':'SJC01-C9300-01'});
                            var apsData = getapsData(aps)
                            data.push(apsData[0])
                        }
                    }
                    else{
                        if(appHoistJob.length ==0){
                            aps = SimLokiDatabaseActions.getFilteredRecordHandler('network-device',{'hostname':'SJC01-C9300-02'});
                            var apsData = getapsData(aps)
                            data.push(apsData[0])
                        }else{
                            aps = []
                        }
                        
                    }
                    let response = JSON.parse(JSON.stringify(IotServicesTemplate.AppHosting_NetworkDevices))             
                    response.data.devices =  data               
                    response.data.totalCount = aps.length                
                    return response 
                }
                if(["ALL","RUNNING"].indexOf(searchByStatus)>-1){ 
                    if(aps.length>0){ 
                        aps.map(e => {
                            let sumary = JSON.parse(JSON.stringify(IotServicesTemplate.AppHosting_NetworkDevices_Summary))
                            sumary.deviceId = e.id   
                            sumary.instanceUuid = e.instanceUuid                     
                            sumary.creationDate = UtilityFunctions.getTimeStamp()
                            sumary.lastHeardTime = UtilityFunctions.getTimeStamp() - (60*1000)
                            sumary.ipAddress = e.managementIpAddress                    
                            sumary.hostname = e.hostname
                            sumary.platformVersion = e.platformVersion
                            sumary.serialNumber = e.serialNumber
                            sumary.networkData = e
                            if(urlAction.service.indexOf('app-device') >-1){
                                if(!sumary.appStatus){ 
                                    sumary.appStatus= "RUNNING"
                                    sumary.appVersion= "4.0.0-beta.8-3"
                                    sumary.onDeviceStatus= "RUNNING"
                                    sumary.desiredStatus= "RUNNING"
                                    sumary.transitionStatus= "RUNNING"
                                    sumary.actualStatus= "RUNNING"
                                    delete sumary.message 
                                    delete sumary.status 
                                    delete sumary.displayMessage 
                                    delete sumary.errorMessage 
                                }                            
                            }
                            let siteInfo = SimLokiDatabaseActions.getFilteredRecordHandler('site',
                            {'id':e.siteId})
                            if(sumary.networkData.site == undefined){
                                sumary.networkData.site = {}
                                sumary.networkData.site.name = siteInfo[0].name
                                sumary.networkData.site.id = siteInfo[0].id
                                sumary.networkData.site.groupNameHierarchy = siteInfo[0].groupNameHierarchy
                                sumary.networkData.site.__typename = "SiteStore"
                            }
                            if(sumary.networkData.provisionStatus== undefined){ 
                                sumary.networkData.provisionStatus = {
                                    "aggregatedStatus": "Not Provisioned",
                                    "__typename": "ProvisionStatusStore"
                                }
                            }
                            data.push(sumary)
                        })
                    }
                }
                if(urlAction.service.indexOf('network-device') && urlAction.filter['family'] == "Switches and Hubs" && urlAction.filter['sortBy'] == "hostname"){
                    var networkDeviceObj = SimLokiDatabaseActions.getFilteredRecordHandler('network-device',{'family':'Switches and Hubs'});
                    for(var k=0;k<networkDeviceObj.length;k++){
                        //need to review the code
                        let tListObj = [];
                        tListObj.push(networkDeviceObj[k])
                        var tempObj = getapsData(tListObj)
                        data.push(tempObj[0])
                    }
                }                               
                let response = JSON.parse(JSON.stringify(IotServicesTemplate.AppHosting_NetworkDevices))               
                response.data.devices =  data               
                response.data.totalCount = aps.length                
                return response                
            }
            //this condition handles Msehtechsupport dashboard logs screen 
            if(urlAction.service.indexOf('devices')>-1 && urlAction.action.id){
                if(urlAction.service.indexOf('techsupport')>-1){ 
                    return {}
                }
                let aps = SimLokiDatabaseActions.getFilteredRecordHandler('network-device',{'id': urlAction.action.id})
                if(aps[0].name == "SJC01-C9300-01" || aps[0].name == "SJC01-C9300-02"){
                    let sumary = JSON.parse(JSON.stringify(IotServicesTemplate.AppHosting_NetworkDevices_ThousandEyes_Summary))
                    sumary.deviceId = aps[0].id
                    sumary.ipAddress = aps[0].managementIpAddress                    
                    sumary.hostname = aps[0].hostname
                    sumary.platformVersion = aps[0].platformVersion
                    sumary.serialNumber = aps[0].serialNumber
                    sumary.lastHeardTime = UtilityFunctions.getTimeStamp() - (60*1000)
                    delete sumary.networkData
                    return sumary
                }else{
                    let sumary = JSON.parse(JSON.stringify(IotServicesTemplate.Meshtechlogs_devicesummary))
                    if(aps.length>0){                        
                        sumary.deviceId = aps[0].id
                        sumary.ipAddress = aps[0].managementIpAddress                    
                        sumary.hostname = aps[0].hostname
                        sumary.platformVersion = aps[0].platformVersion
                        sumary.serialNumber = aps[0].serialNumber
                        sumary.lastHeardTime = UtilityFunctions.getTimeStamp() - (60*1000)
                        sumary.networks[0].networkDescription.app_ip_map.MeshConnect_2021.eth0.mac_address = aps[0].macAddress
                        sumary.networks[0].networkDescription.app_ip_map.MeshConnect_2021.eth0.ipv4 = aps[0].macAddress                   
                    }
                    return sumary
                }
            }
        }
        if(urlAction.service.indexOf('cisco.dna.appHosting')>-1){
            let appInfo = SimLokiDatabaseActions.getAll('apphoisting-response');
            let value = {"instances":{}}
            if(appInfo.length>0){
                value.instances = appInfo[0].instances
            }
            return [{"key":"PERSISTENCE-cisco.dna.appHosting/enable-iot-ap",
            "plugin":"cisco.dna.appHosting",
            "value": JSON.stringify(value)}]
        }
    }

    function processPostRequest(urlAction){

        if(urlAction.service.indexOf('cisco.dna.appHosting')>-1){            
            let tvalue = JSON.parse(urlAction.restPayload.value);
            let appHoistInfo = SimLokiDatabaseActions.getAll("apphoisting-response");
            if(appHoistInfo.length>0){
                SimLokiDatabaseActions.delete("apphoisting-response",appHoistInfo[0]);
            }
            SimLokiDatabaseActions.insert("apphoisting-response",tvalue);
            return {
              "_id": "606f4193b36a785ee3756b5b",
              "key": "PERSISTENCE-cisco.dna.appHosting/enable-iot-ap",
              "plugin": "cisco.dna.appHosting",
              "user": "admin",
              "__v": 0,
              "value": urlAction.restPayload.value
            }
        }
        if(urlAction.service.indexOf('action')>-1){            
            let restPayld = urlAction.restPayload
            let appHoistJob = SimLokiDatabaseActions.getAll("apphoisting-jobid")
            let jobId
            if(urlAction.filter['mode'] == 'clipreview'){
                let sumary = JSON.parse(JSON.stringify(IotServicesTemplate.ClipView_Template))
                return sumary
            }
            // handling job status code for install mode
            if(urlAction.restPayload.install){                                       
                jobId = UtilityFunctions.generate_uuid()                              
                let restpyloadDeviceIds = restPayld.install.devices.map(e => e.deviceId)
                let chkDevIdExist = SimLokiDatabaseActions.getFilteredRecordHandler('apphoisting-jobid',{'deviceId': {'$in': restpyloadDeviceIds} });
                if(chkDevIdExist.length === 0){ 
                    restpyloadDeviceIds.forEach( id => { 
                        let insertJobInfo = {'deviceId':"",'jobId':"",'appStatus':'', 'appName':''}  
                        insertJobInfo.jobId = jobId
                        insertJobInfo.appStatus = 'RUNNING'
                        insertJobInfo.deviceId = id
                        if(urlAction.service.indexOf('55058C1E2DF3D8BD484A229F1474F496')>-1){
                            insertJobInfo.appName = "ThousandEyes Enterprise Agent"
                        }else{
                            insertJobInfo.appName = "MeshConnect"    
                        }
                        
                        SimLokiDatabaseActions.insert("apphoisting-jobid",insertJobInfo)
                    })
                }                
                return {               
                    "jobId":`${jobId}`,
                    "_links":{"href":`/api/v1/appmgr/jobs/${jobId}`}
                }                                   
            }   
            // handling job status code for undeploy mode         
            if(urlAction.restPayload.undeploy){
                let undeployDeviceInfo = restPayld.undeploy.devices ;//["",""]
                let delDevInfo = SimLokiDatabaseActions.getFilteredRecordHandler('apphoisting-jobid',{'deviceId': { '$containsAny' : undeployDeviceInfo}})
                //let remove undeploy deviceId from existing apphoistingJobid 
                for(let i=0; i<delDevInfo.length; i++) {                    
                    let record = SimLokiDatabaseActions.getFilteredRecordHandler('apphoisting-jobid', {'deviceId':undeployDeviceInfo[i]});
                    SimLokiDatabaseActions.delete('apphoisting-jobid', record);
                }
                let jobId = UtilityFunctions.generate_uuid()
                return {               
                    "jobId":`${jobId}`,
                    "_links":{"href":`/api/v1/appmgr/jobs/${jobId}`}
                }                
            }
        }

        if(urlAction.service.indexOf('interfaces') > -1){
            return {"data":[{"name":"default","type":"vlan"},{"name":"VLAN0144","type":"vlan"},{"name":"Management","type":"management"}]}
        }
        if(urlAction.service.indexOf('appmgr')>-1 && urlAction.service.indexOf('network-device')>-1){ 
            let deviceIds = urlAction.restPayload.id
            let data = []
            deviceIds.map(e => {
                    let deviceRec = SimLokiDatabaseActions.getFilteredRecordHandler('network-device',{'id':e})
                    let sumary = JSON.parse(JSON.stringify(IotServicesTemplate.AppHosting_NetworkDevices_Summary))
                    sumary.message = "An unexpected error happened. {0}"
                    sumary.status = "DISCOVERED_WITH_ERROR"
                    sumary.displayMessage = "An unexpected error happened. {0}"
                    sumary.errorMessage = "An unexpected error happened. {0}"
                    sumary.creationDate = UtilityFunctions.getTimeStamp()
                    sumary.lastHeardTime = UtilityFunctions.getTimeStamp() - (60*1000)
                    sumary.lastRefreshTime = UtilityFunctions.getTimeStamp()                    
                    sumary.deviceId = deviceRec[0].id
                    sumary.ipAddress = deviceRec[0].managementIpAddress                    
                    sumary.hostname = deviceRec[0].hostname
                    sumary.platformVersion = deviceRec[0].platformVersion
                    sumary.serialNumber = deviceRec[0].serialNumber
                    sumary.networkData = deviceRec[0]
                    let siteInfo = SimLokiDatabaseActions.getFilteredRecordHandler('site',
                    {'id':deviceRec[0].siteId})
                    if(sumary.networkData.site == undefined){
                        sumary.networkData.site = {}
                        sumary.networkData.site.name = siteInfo[0].name
                        sumary.networkData.site.id = siteInfo[0].id
                        sumary.networkData.site.groupNameHierarchy = siteInfo[0].groupNameHierarchy
                        sumary.networkData.site.__typename = "SiteStore"
                    }
                    if(sumary.networkData.provisionStatus== undefined){ 
                        sumary.networkData.provisionStatus = {
                            "aggregatedStatus": "Not Provisioned",
                            "__typename": "ProvisionStatusStore"
                        }
                    }
                    data.push(sumary)
                })
                let response = JSON.parse(JSON.stringify(IotServicesTemplate.AppHosting_NetworkDevices))
                response.data.devices = data
                response.data.totalCount = deviceIds.length
                return response
        }
        if(urlAction.service.indexOf("techsupport")>-1 && urlAction.service.indexOf("appmgr")>-1){
            let timeInIso = UtilityFunctions.getCurrentDateAndTimeInValidFormat()
            let splitTime = timeInIso.split(' ')
            let curDate = splitTime[0]
            let timeInSec = splitTime[1].split('.')[0].replace(/:/g,".")
            //let href = `/api/v1/appmgr/devices/${urlAction.action.id}/techsupport?file=tech_support_${curDate}_${timeInSec}.tar.gz`
            let href = `/api/simulation/logtarfie`;
            return {'_link':{'href':href}}
        }
    }

    function getSiteObj(siteData){
        let siteObj = { "id": "","name": "","groupNameHierarchy": "",
                        "location": {"country": "","address": "","latitude": "",
                        "addressInheritedFrom": "","type": "","longitude": ""},
                        "mapDisplayName": "Partner Building" }
        siteObj.id = siteData[0].id
        siteObj.name = siteData[0].name
        siteObj.groupNameHierarchy = siteData[0].groupNameHierarchy
        return siteObj
    }

    function updateDeviceId(linkData,id) {
        for(let key in linkData){
            let hrefLink = linkData[key].href
            hrefLink = hrefLink.replace(/DeviceIdplaceholder/g,id)            
        }
        return linkData
    }
});