define([
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/database/SimLokiDatabaseActions',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/cloud-provisioning/cloudProvisioningProductDetails',
], function(SimLokiDatabaseActions,cloudProvisioningProductDetails){

    return {
        init: function () {
        },

        doProcessRestRequest: function (urlAction) {
            var data;
            switch (urlAction.method) {
                case 'GET':
                    data = processGetRequest(urlAction);
                    break;

                case 'POST':
                    data = processPostRequest(urlAction);
                    break;

                case 'PUT':
                    data = processPutRequest(urlAction);
                    break;

                case 'DELETE':
                    data = processDeleteRequest(urlAction);
                    break;

                default:
                    break;
            }
            return data;
        }
    };
    function processGetRequest(urlAction) {
        if(urlAction.service.indexOf('cloud-provisioning') >-1 && urlAction.service.indexOf('product') >-1){
            let data = JSON.parse(JSON.stringify(cloudProvisioningProductDetails.Template))
            return data
        }
        if(urlAction.service.indexOf('cloud-provisioning') >-1 && urlAction.service.indexOf('cloudPlatformStatistics') >-1){
            return {"response":[],"version":"1.0"}
        }
    }



});