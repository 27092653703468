define({
"Template":
{
  "version": "1.0",
  "response": {
    "id": "250e7c12-b3b4-45bc-be06-45505adffd88",
    "name": "network_device_controller_poe_trigger",
    "enabled": true,
    "flattened": true,
    "entityType": "network_device",
    "entityName": "Unknown",
    "entity": "172.25.33.69",
    "groupBy": "Unknown",
    "category": "Device",
    "severity": "HIGH",
    "summary": "PoE powered device connected on DeviceNamePlaceHolder InterfaceDownPlaceHolder is flagged faulty due to Power Controller reports power Imax error detected",
    "groupId": "PoE_port_failure_grouping",
    "groupName": "PoE failures on network device",
    "scope": "GLOBAL",
    "priority": "P3",
    "rootCause": "ILPOWER-3-CONTROLLER_PORT_ERR",
    "timestamp": 1587510929595,
    "description": "PoE powered device connected on DeviceNamePlaceHolder InterfaceDownPlaceHolder is flagged faulty due to Power Controller reports power Imax error detected.",
    "suggestions": [
      {
        "message": "Check operational details of the affected interface Te3/0/45. Compare actual consumption and negotiated values",
        "steps": [
          {
            "entityId": "DeviceIdPlaceHolder",
            "description": "Detailed inline power status",
            "command": "sh power inline InterfaceDownPlaceHolder detail",
            "stepType": "command-Runner"
          }
        ]
      },
      {
        "message": "Check inline power status",
        "steps": [
          {
            "entityId": "DeviceIdPlaceHolder",
            "description": "Inline power status of all interfaces",
            "command": "show power inline",
            "stepType": "command-Runner"
          }
        ]
      },
      {
        "message": "Review the vendor specifications of the powered device to confirm its IEEE standard compliance"
      },
      {
        "message": "Contact Cisco TAC World Wide – https://www.cisco.com/c/en/us/support/web/tsd-cisco-worldwide-contacts.html"
      }
    ],
    "additionalParams": [
      {
        "key": "managementIpAddr",
        "value": "172.25.33.69"
      },
      {
        "key": "_name",
        "value": "network_device_controller_poe_trigger"
      },
      {
        "key": "_entity_type",
        "value": "NetworkDevice"
      },
      {
        "key": "eventSource",
        "value": "Network Device"
      },
      {
        "key": "type",
        "value": "Issue"
      },
      {
        "key": "_key",
        "value": "NetworkDevice:172.25.33.69"
      },
      {
        "key": "floorId",
        "value": ""
      },
      {
        "key": "_count",
        "value": "1"
      },
      {
        "key": "messageType",
        "value": "ILPOWER-3-CONTROLLER_PORT_ERR"
      },
      {
        "key": "areaName",
        "value": "Global"
      },
      {
        "key": "scope",
        "value": "global"
      },
      {
        "key": "rootCause",
        "value": "ILPOWER-3-CONTROLLER_PORT_ERR"
      },
      {
        "key": "_primary_key",
        "value": "NetworkDevice:172.25.33.69"
      },
      {
        "key": "floorName",
        "value": ""
      },
      {
        "key": "siteType",
        "value": "building"
      },
      {
        "key": "deviceType",
        "value": "Cisco Catalyst 9300 Switch"
      },
      {
        "key": "severity",
        "value": "HIGH"
      },
      {
        "key": "deviceUuid",
        "value": "fe31972f-fa78-4874-97be-0206433dcdaf"
      },
      {
        "key": "messageText",
        "value": "672: *Apr 21 15:15:28 PST:  Controller port error, Interface Te3/0/45: Power Controller reports power Imax error detected"
      },
      {
        "key": "param5",
        "value": ""
      },
      {
        "key": "deviceRole",
        "value": "ACCESS"
      },
      {
        "key": "_suppression",
        "value": "1800"
      },
      {
        "key": "deviceOS",
        "value": "17.3.20200408:002904"
      },
      {
        "key": "entityType",
        "value": "network_device"
      },
      {
        "key": "nwDeviceName",
        "value": "stack-N20"
      },
      {
        "key": "_eventTime",
        "value": "1587510929595"
      },
      {
        "key": "_mostRecent",
        "value": "1587510929595"
      },
      {
        "key": "entityId",
        "value": "172.25.33.69"
      },
      {
        "key": "eventUniqueId",
        "value": "172.25.33.69"
      },
      {
        "key": "deviceFamily",
        "value": "Switches and Hubs"
      },
      {
        "key": "param3",
        "value": ""
      },
      {
        "key": "priority",
        "value": "P3"
      },
      {
        "key": "param4",
        "value": ""
      },
      {
        "key": "param1",
        "value": "Te3/0/45"
      },
      {
        "key": "param2",
        "value": "Power Controller reports power Imax error detected"
      },
      {
        "key": "buildingId",
        "value": "22a4236a-94a1-443c-8073-a0f90581a91c"
      },
      {
        "key": "buildingName",
        "value": "Global/RAJ-SJC20-9K"
      },
      {
        "key": "areaId",
        "value": "Global"
      },
      {
        "key": "siteHierarchy",
        "value": "Global/North_America/USA/California/San_Francisco/SFO13"
      },
      {
        "key": "deviceModel",
        "value": "FCW2049G03T"
      },
      {
        "key": "category",
        "value": "Device"
      },
      {
        "key": "timestring",
        "value": "2020-04-21T23:15:29.595+0000"
      },
      {
        "key": "status",
        "value": "active"
      }
    ],
    "status": {
      "status": "ACTIVE",
      "updatedBy": "Unknown",
      "notes": "Unknown",
      "source": "Unknown",
      "updatedAt": null,
      "ignoreSource": "Unknown",
      "ignoreValue": "Unknown",
      "ignoreStartTime": null,
      "ignoreEndTime": null
    },
    "siteQulaifiedName": null
  }
}
})