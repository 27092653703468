define({
"Template":
{
  "version": "1.0",
  "response": [
    {
      "time": "2019-07-01T10:05:00.000+0000",
      "healthScore": null,
      "packetLoss": null,
      "jitter": null,
      "latency": null,
      "applicationDelay": null,
      "clientNetworkDelay": null,
      "serverNetworkDelay": null,
      "dscp": "default"
    },
    {
      "time": "2019-07-01T10:00:00.000+0000",
      "healthScore": null,
      "packetLoss": null,
      "jitter": null,
      "latency": null,
      "applicationDelay": null,
      "clientNetworkDelay": null,
      "serverNetworkDelay": null,
      "dscp": "default"
    },
    {
      "time": "2019-07-01T09:55:00.000+0000",
      "healthScore": null,
      "packetLoss": null,
      "jitter": null,
      "latency": null,
      "applicationDelay": null,
      "clientNetworkDelay": null,
      "serverNetworkDelay": null,
      "dscp": "default"
    },
    {
      "time": "2019-07-01T09:50:00.000+0000",
      "healthScore": null,
      "packetLoss": null,
      "jitter": null,
      "latency": null,
      "applicationDelay": null,
      "clientNetworkDelay": null,
      "serverNetworkDelay": null,
      "dscp": "default"
    },
    {
      "time": "2019-07-01T09:45:00.000+0000",
      "healthScore": null,
      "packetLoss": null,
      "jitter": null,
      "latency": null,
      "applicationDelay": null,
      "clientNetworkDelay": null,
      "serverNetworkDelay": null,
      "dscp": "default"
    },
    {
      "time": "2019-07-01T09:40:00.000+0000",
      "healthScore": null,
      "packetLoss": null,
      "jitter": null,
      "latency": null,
      "applicationDelay": null,
      "clientNetworkDelay": null,
      "serverNetworkDelay": null,
      "dscp": "default"
    },
    {
      "time": "2019-07-01T09:35:00.000+0000",
      "healthScore": null,
      "packetLoss": null,
      "jitter": null,
      "latency": null,
      "applicationDelay": null,
      "clientNetworkDelay": null,
      "serverNetworkDelay": null,
      "dscp": "default"
    },
    {
      "time": "2019-07-01T09:30:00.000+0000",
      "healthScore": null,
      "packetLoss": null,
      "jitter": null,
      "latency": null,
      "applicationDelay": null,
      "clientNetworkDelay": null,
      "serverNetworkDelay": null,
      "dscp": "default"
    },
    {
      "time": "2019-07-01T09:25:00.000+0000",
      "healthScore": null,
      "packetLoss": null,
      "jitter": null,
      "latency": null,
      "applicationDelay": null,
      "clientNetworkDelay": null,
      "serverNetworkDelay": null,
      "dscp": "default"
    },
    {
      "time": "2019-07-01T09:20:00.000+0000",
      "healthScore": null,
      "packetLoss": null,
      "jitter": null,
      "latency": null,
      "applicationDelay": null,
      "clientNetworkDelay": null,
      "serverNetworkDelay": null,
      "dscp": "default"
    },
    {
      "time": "2019-07-01T09:15:00.000+0000",
      "healthScore": null,
      "packetLoss": null,
      "jitter": null,
      "latency": null,
      "applicationDelay": null,
      "clientNetworkDelay": null,
      "serverNetworkDelay": null,
      "dscp": "default"
    },
    {
      "time": "2019-07-01T09:10:00.000+0000",
      "healthScore": null,
      "packetLoss": null,
      "jitter": null,
      "latency": null,
      "applicationDelay": null,
      "clientNetworkDelay": null,
      "serverNetworkDelay": null,
      "dscp": "default"
    },
    {
      "time": "2019-07-01T09:05:00.000+0000",
      "healthScore": null,
      "packetLoss": null,
      "jitter": null,
      "latency": null,
      "applicationDelay": null,
      "clientNetworkDelay": null,
      "serverNetworkDelay": null,
      "dscp": "default"
    },
    {
      "time": "2019-07-01T09:00:00.000+0000",
      "healthScore": null,
      "packetLoss": null,
      "jitter": null,
      "latency": null,
      "applicationDelay": null,
      "clientNetworkDelay": null,
      "serverNetworkDelay": null,
      "dscp": "default"
    },
    {
      "time": "2019-07-01T08:55:00.000+0000",
      "healthScore": null,
      "packetLoss": null,
      "jitter": null,
      "latency": null,
      "applicationDelay": null,
      "clientNetworkDelay": null,
      "serverNetworkDelay": null,
      "dscp": "default"
    },
    {
      "time": "2019-07-01T08:50:00.000+0000",
      "healthScore": null,
      "packetLoss": null,
      "jitter": null,
      "latency": null,
      "applicationDelay": null,
      "clientNetworkDelay": null,
      "serverNetworkDelay": null,
      "dscp": "default"
    },
    {
      "time": "2019-07-01T08:45:00.000+0000",
      "healthScore": null,
      "packetLoss": null,
      "jitter": null,
      "latency": null,
      "applicationDelay": null,
      "clientNetworkDelay": null,
      "serverNetworkDelay": null,
      "dscp": "default"
    },
    {
      "time": "2019-07-01T08:40:00.000+0000",
      "healthScore": null,
      "packetLoss": null,
      "jitter": null,
      "latency": null,
      "applicationDelay": null,
      "clientNetworkDelay": null,
      "serverNetworkDelay": null,
      "dscp": "default"
    },
    {
      "time": "2019-07-01T08:35:00.000+0000",
      "healthScore": null,
      "packetLoss": null,
      "jitter": null,
      "latency": null,
      "applicationDelay": null,
      "clientNetworkDelay": null,
      "serverNetworkDelay": null,
      "dscp": "default"
    },
    {
      "time": "2019-07-01T08:30:00.000+0000",
      "healthScore": null,
      "packetLoss": null,
      "jitter": null,
      "latency": null,
      "applicationDelay": null,
      "clientNetworkDelay": null,
      "serverNetworkDelay": null,
      "dscp": "default"
    },
    {
      "time": "2019-07-01T08:25:00.000+0000",
      "healthScore": null,
      "packetLoss": null,
      "jitter": null,
      "latency": null,
      "applicationDelay": null,
      "clientNetworkDelay": null,
      "serverNetworkDelay": null,
      "dscp": "default"
    },
    {
      "time": "2019-07-01T08:20:00.000+0000",
      "healthScore": null,
      "packetLoss": null,
      "jitter": null,
      "latency": null,
      "applicationDelay": null,
      "clientNetworkDelay": null,
      "serverNetworkDelay": null,
      "dscp": "default"
    },
    {
      "time": "2019-07-01T08:15:00.000+0000",
      "healthScore": null,
      "packetLoss": null,
      "jitter": null,
      "latency": null,
      "applicationDelay": null,
      "clientNetworkDelay": null,
      "serverNetworkDelay": null,
      "dscp": "default"
    },
    {
      "time": "2019-07-01T08:10:00.000+0000",
      "healthScore": null,
      "packetLoss": null,
      "jitter": null,
      "latency": null,
      "applicationDelay": null,
      "clientNetworkDelay": null,
      "serverNetworkDelay": null,
      "dscp": "default"
    },
    {
      "time": "2019-07-01T08:05:00.000+0000",
      "healthScore": null,
      "packetLoss": null,
      "jitter": null,
      "latency": null,
      "applicationDelay": null,
      "clientNetworkDelay": null,
      "serverNetworkDelay": null,
      "dscp": "default"
    },
    {
      "time": "2019-07-01T08:00:00.000+0000",
      "healthScore": null,
      "packetLoss": null,
      "jitter": null,
      "latency": null,
      "applicationDelay": null,
      "clientNetworkDelay": null,
      "serverNetworkDelay": null,
      "dscp": "default"
    },
    {
      "time": "2019-07-01T07:55:00.000+0000",
      "healthScore": null,
      "packetLoss": null,
      "jitter": null,
      "latency": null,
      "applicationDelay": null,
      "clientNetworkDelay": null,
      "serverNetworkDelay": null,
      "dscp": "default"
    },
    {
      "time": "2019-07-01T07:50:00.000+0000",
      "healthScore": null,
      "packetLoss": null,
      "jitter": null,
      "latency": null,
      "applicationDelay": null,
      "clientNetworkDelay": null,
      "serverNetworkDelay": null,
      "dscp": "default"
    },
    {
      "time": "2019-07-01T07:45:00.000+0000",
      "healthScore": null,
      "packetLoss": null,
      "jitter": null,
      "latency": null,
      "applicationDelay": null,
      "clientNetworkDelay": null,
      "serverNetworkDelay": null,
      "dscp": "default"
    },
    {
      "time": "2019-07-01T07:40:00.000+0000",
      "healthScore": null,
      "packetLoss": null,
      "jitter": null,
      "latency": null,
      "applicationDelay": null,
      "clientNetworkDelay": null,
      "serverNetworkDelay": null,
      "dscp": "default"
    },
    {
      "time": "2019-07-01T07:35:00.000+0000",
      "healthScore": null,
      "packetLoss": null,
      "jitter": null,
      "latency": null,
      "applicationDelay": null,
      "clientNetworkDelay": null,
      "serverNetworkDelay": null,
      "dscp": "default"
    },
    {
      "time": "2019-07-01T07:30:00.000+0000",
      "healthScore": null,
      "packetLoss": null,
      "jitter": null,
      "latency": null,
      "applicationDelay": null,
      "clientNetworkDelay": null,
      "serverNetworkDelay": null,
      "dscp": "default"
    },
    {
      "time": "2019-07-01T07:25:00.000+0000",
      "healthScore": null,
      "packetLoss": null,
      "jitter": null,
      "latency": null,
      "applicationDelay": null,
      "clientNetworkDelay": null,
      "serverNetworkDelay": null,
      "dscp": "default"
    },
    {
      "time": "2019-07-01T07:20:00.000+0000",
      "healthScore": null,
      "packetLoss": null,
      "jitter": null,
      "latency": null,
      "applicationDelay": null,
      "clientNetworkDelay": null,
      "serverNetworkDelay": null,
      "dscp": "default"
    },
    {
      "time": "2019-07-01T07:15:00.000+0000",
      "healthScore": null,
      "packetLoss": null,
      "jitter": null,
      "latency": null,
      "applicationDelay": null,
      "clientNetworkDelay": null,
      "serverNetworkDelay": null,
      "dscp": "default"
    },
    {
      "time": "2019-07-01T07:10:00.000+0000",
      "healthScore": null,
      "packetLoss": null,
      "jitter": null,
      "latency": null,
      "applicationDelay": null,
      "clientNetworkDelay": null,
      "serverNetworkDelay": null,
      "dscp": "default"
    },
    {
      "time": "2019-07-01T07:05:00.000+0000",
      "healthScore": null,
      "packetLoss": null,
      "jitter": null,
      "latency": null,
      "applicationDelay": null,
      "clientNetworkDelay": null,
      "serverNetworkDelay": null,
      "dscp": "default"
    },
    {
      "time": "2019-07-01T07:00:00.000+0000",
      "healthScore": null,
      "packetLoss": null,
      "jitter": null,
      "latency": null,
      "applicationDelay": null,
      "clientNetworkDelay": null,
      "serverNetworkDelay": null,
      "dscp": "default"
    },
    {
      "time": "2019-07-01T06:55:00.000+0000",
      "healthScore": null,
      "packetLoss": null,
      "jitter": null,
      "latency": null,
      "applicationDelay": null,
      "clientNetworkDelay": null,
      "serverNetworkDelay": null,
      "dscp": "default"
    },
    {
      "time": "2019-07-01T06:50:00.000+0000",
      "healthScore": null,
      "packetLoss": null,
      "jitter": null,
      "latency": null,
      "applicationDelay": null,
      "clientNetworkDelay": null,
      "serverNetworkDelay": null,
      "dscp": "default"
    },
    {
      "time": "2019-07-01T06:45:00.000+0000",
      "healthScore": null,
      "packetLoss": null,
      "jitter": null,
      "latency": null,
      "applicationDelay": null,
      "clientNetworkDelay": null,
      "serverNetworkDelay": null,
      "dscp": "default"
    },
    {
      "time": "2019-07-01T06:40:00.000+0000",
      "healthScore": null,
      "packetLoss": null,
      "jitter": null,
      "latency": null,
      "applicationDelay": null,
      "clientNetworkDelay": null,
      "serverNetworkDelay": null,
      "dscp": "default"
    },
    {
      "time": "2019-07-01T06:35:00.000+0000",
      "healthScore": null,
      "packetLoss": null,
      "jitter": null,
      "latency": null,
      "applicationDelay": null,
      "clientNetworkDelay": null,
      "serverNetworkDelay": null,
      "dscp": "default"
    },
    {
      "time": "2019-07-01T06:30:00.000+0000",
      "healthScore": null,
      "packetLoss": null,
      "jitter": null,
      "latency": null,
      "applicationDelay": null,
      "clientNetworkDelay": null,
      "serverNetworkDelay": null,
      "dscp": "default"
    },
    {
      "time": "2019-07-01T06:25:00.000+0000",
      "healthScore": null,
      "packetLoss": null,
      "jitter": null,
      "latency": null,
      "applicationDelay": null,
      "clientNetworkDelay": null,
      "serverNetworkDelay": null,
      "dscp": "default"
    },
    {
      "time": "2019-07-01T06:20:00.000+0000",
      "healthScore": null,
      "packetLoss": null,
      "jitter": null,
      "latency": null,
      "applicationDelay": null,
      "clientNetworkDelay": null,
      "serverNetworkDelay": null,
      "dscp": "default"
    },
    {
      "time": "2019-07-01T06:15:00.000+0000",
      "healthScore": null,
      "packetLoss": null,
      "jitter": null,
      "latency": null,
      "applicationDelay": null,
      "clientNetworkDelay": null,
      "serverNetworkDelay": null,
      "dscp": "default"
    },
    {
      "time": "2019-07-01T06:10:00.000+0000",
      "healthScore": null,
      "packetLoss": null,
      "jitter": null,
      "latency": null,
      "applicationDelay": null,
      "clientNetworkDelay": null,
      "serverNetworkDelay": null,
      "dscp": "default"
    },
    {
      "time": "2019-07-01T06:05:00.000+0000",
      "healthScore": null,
      "packetLoss": null,
      "jitter": null,
      "latency": null,
      "applicationDelay": null,
      "clientNetworkDelay": null,
      "serverNetworkDelay": null,
      "dscp": "default"
    },
    {
      "time": "2019-07-01T06:00:00.000+0000",
      "healthScore": null,
      "packetLoss": null,
      "jitter": null,
      "latency": null,
      "applicationDelay": null,
      "clientNetworkDelay": null,
      "serverNetworkDelay": null,
      "dscp": "default"
    },
    {
      "time": "2019-07-01T05:55:00.000+0000",
      "healthScore": null,
      "packetLoss": null,
      "jitter": null,
      "latency": null,
      "applicationDelay": null,
      "clientNetworkDelay": null,
      "serverNetworkDelay": null,
      "dscp": "default"
    },
    {
      "time": "2019-07-01T05:50:00.000+0000",
      "healthScore": null,
      "packetLoss": null,
      "jitter": null,
      "latency": null,
      "applicationDelay": null,
      "clientNetworkDelay": null,
      "serverNetworkDelay": null,
      "dscp": "default"
    },
    {
      "time": "2019-07-01T05:45:00.000+0000",
      "healthScore": null,
      "packetLoss": null,
      "jitter": null,
      "latency": null,
      "applicationDelay": null,
      "clientNetworkDelay": null,
      "serverNetworkDelay": null,
      "dscp": "default"
    },
    {
      "time": "2019-07-01T05:40:00.000+0000",
      "healthScore": null,
      "packetLoss": null,
      "jitter": null,
      "latency": null,
      "applicationDelay": null,
      "clientNetworkDelay": null,
      "serverNetworkDelay": null,
      "dscp": "default"
    },
    {
      "time": "2019-07-01T05:35:00.000+0000",
      "healthScore": null,
      "packetLoss": null,
      "jitter": null,
      "latency": null,
      "applicationDelay": null,
      "clientNetworkDelay": null,
      "serverNetworkDelay": null,
      "dscp": "default"
    },
    {
      "time": "2019-07-01T05:30:00.000+0000",
      "healthScore": null,
      "packetLoss": null,
      "jitter": null,
      "latency": null,
      "applicationDelay": null,
      "clientNetworkDelay": null,
      "serverNetworkDelay": null,
      "dscp": "default"
    },
    {
      "time": "2019-07-01T05:25:00.000+0000",
      "healthScore": null,
      "packetLoss": null,
      "jitter": null,
      "latency": null,
      "applicationDelay": null,
      "clientNetworkDelay": null,
      "serverNetworkDelay": null,
      "dscp": "default"
    },
    {
      "time": "2019-07-01T05:20:00.000+0000",
      "healthScore": null,
      "packetLoss": null,
      "jitter": null,
      "latency": null,
      "applicationDelay": null,
      "clientNetworkDelay": null,
      "serverNetworkDelay": null,
      "dscp": "default"
    },
    {
      "time": "2019-07-01T05:15:00.000+0000",
      "healthScore": null,
      "packetLoss": null,
      "jitter": null,
      "latency": null,
      "applicationDelay": null,
      "clientNetworkDelay": null,
      "serverNetworkDelay": null,
      "dscp": "default"
    },
    {
      "time": "2019-07-01T05:10:00.000+0000",
      "healthScore": null,
      "packetLoss": null,
      "jitter": null,
      "latency": null,
      "applicationDelay": null,
      "clientNetworkDelay": null,
      "serverNetworkDelay": null,
      "dscp": "default"
    },
    {
      "time": "2019-07-01T05:05:00.000+0000",
      "healthScore": null,
      "packetLoss": null,
      "jitter": null,
      "latency": null,
      "applicationDelay": null,
      "clientNetworkDelay": null,
      "serverNetworkDelay": null,
      "dscp": "default"
    },
    {
      "time": "2019-07-01T05:00:00.000+0000",
      "healthScore": null,
      "packetLoss": null,
      "jitter": null,
      "latency": null,
      "applicationDelay": null,
      "clientNetworkDelay": null,
      "serverNetworkDelay": null,
      "dscp": "default"
    },
    {
      "time": "2019-07-01T04:55:00.000+0000",
      "healthScore": null,
      "packetLoss": null,
      "jitter": null,
      "latency": null,
      "applicationDelay": null,
      "clientNetworkDelay": null,
      "serverNetworkDelay": null,
      "dscp": "default"
    },
    {
      "time": "2019-07-01T04:50:00.000+0000",
      "healthScore": null,
      "packetLoss": null,
      "jitter": null,
      "latency": null,
      "applicationDelay": null,
      "clientNetworkDelay": null,
      "serverNetworkDelay": null,
      "dscp": "default"
    },
    {
      "time": "2019-07-01T04:45:00.000+0000",
      "healthScore": null,
      "packetLoss": null,
      "jitter": null,
      "latency": null,
      "applicationDelay": null,
      "clientNetworkDelay": null,
      "serverNetworkDelay": null,
      "dscp": "default"
    },
    {
      "time": "2019-07-01T04:40:00.000+0000",
      "healthScore": null,
      "packetLoss": null,
      "jitter": null,
      "latency": null,
      "applicationDelay": null,
      "clientNetworkDelay": null,
      "serverNetworkDelay": null,
      "dscp": "default"
    },
    {
      "time": "2019-07-01T04:35:00.000+0000",
      "healthScore": null,
      "packetLoss": null,
      "jitter": null,
      "latency": null,
      "applicationDelay": null,
      "clientNetworkDelay": null,
      "serverNetworkDelay": null,
      "dscp": "default"
    },
    {
      "time": "2019-07-01T04:30:00.000+0000",
      "healthScore": null,
      "packetLoss": null,
      "jitter": null,
      "latency": null,
      "applicationDelay": null,
      "clientNetworkDelay": null,
      "serverNetworkDelay": null,
      "dscp": "default"
    },
    {
      "time": "2019-07-01T04:25:00.000+0000",
      "healthScore": null,
      "packetLoss": null,
      "jitter": null,
      "latency": null,
      "applicationDelay": null,
      "clientNetworkDelay": null,
      "serverNetworkDelay": null,
      "dscp": "default"
    },
    {
      "time": "2019-07-01T04:20:00.000+0000",
      "healthScore": null,
      "packetLoss": null,
      "jitter": null,
      "latency": null,
      "applicationDelay": null,
      "clientNetworkDelay": null,
      "serverNetworkDelay": null,
      "dscp": "default"
    },
    {
      "time": "2019-07-01T04:15:00.000+0000",
      "healthScore": null,
      "packetLoss": null,
      "jitter": null,
      "latency": null,
      "applicationDelay": null,
      "clientNetworkDelay": null,
      "serverNetworkDelay": null,
      "dscp": "default"
    },
    {
      "time": "2019-07-01T04:10:00.000+0000",
      "healthScore": null,
      "packetLoss": null,
      "jitter": null,
      "latency": null,
      "applicationDelay": null,
      "clientNetworkDelay": null,
      "serverNetworkDelay": null,
      "dscp": "default"
    },
    {
      "time": "2019-07-01T04:05:00.000+0000",
      "healthScore": null,
      "packetLoss": null,
      "jitter": null,
      "latency": null,
      "applicationDelay": null,
      "clientNetworkDelay": null,
      "serverNetworkDelay": null,
      "dscp": "default"
    },
    {
      "time": "2019-07-01T04:00:00.000+0000",
      "healthScore": null,
      "packetLoss": null,
      "jitter": null,
      "latency": null,
      "applicationDelay": null,
      "clientNetworkDelay": null,
      "serverNetworkDelay": null,
      "dscp": "default"
    },
    {
      "time": "2019-07-01T03:55:00.000+0000",
      "healthScore": null,
      "packetLoss": null,
      "jitter": null,
      "latency": null,
      "applicationDelay": null,
      "clientNetworkDelay": null,
      "serverNetworkDelay": null,
      "dscp": "default"
    },
    {
      "time": "2019-07-01T03:50:00.000+0000",
      "healthScore": null,
      "packetLoss": null,
      "jitter": null,
      "latency": null,
      "applicationDelay": null,
      "clientNetworkDelay": null,
      "serverNetworkDelay": null,
      "dscp": "default"
    },
    {
      "time": "2019-07-01T03:45:00.000+0000",
      "healthScore": null,
      "packetLoss": null,
      "jitter": null,
      "latency": null,
      "applicationDelay": null,
      "clientNetworkDelay": null,
      "serverNetworkDelay": null,
      "dscp": "default"
    },
    {
      "time": "2019-07-01T03:40:00.000+0000",
      "healthScore": null,
      "packetLoss": null,
      "jitter": null,
      "latency": null,
      "applicationDelay": null,
      "clientNetworkDelay": null,
      "serverNetworkDelay": null,
      "dscp": "default"
    },
    {
      "time": "2019-07-01T03:35:00.000+0000",
      "healthScore": null,
      "packetLoss": null,
      "jitter": null,
      "latency": null,
      "applicationDelay": null,
      "clientNetworkDelay": null,
      "serverNetworkDelay": null,
      "dscp": "default"
    },
    {
      "time": "2019-07-01T03:30:00.000+0000",
      "healthScore": null,
      "packetLoss": null,
      "jitter": null,
      "latency": null,
      "applicationDelay": null,
      "clientNetworkDelay": null,
      "serverNetworkDelay": null,
      "dscp": "default"
    },
    {
      "time": "2019-07-01T03:25:00.000+0000",
      "healthScore": null,
      "packetLoss": null,
      "jitter": null,
      "latency": null,
      "applicationDelay": null,
      "clientNetworkDelay": null,
      "serverNetworkDelay": null,
      "dscp": "default"
    },
    {
      "time": "2019-07-01T03:20:00.000+0000",
      "healthScore": null,
      "packetLoss": null,
      "jitter": null,
      "latency": null,
      "applicationDelay": null,
      "clientNetworkDelay": null,
      "serverNetworkDelay": null,
      "dscp": "default"
    },
    {
      "time": "2019-07-01T03:15:00.000+0000",
      "healthScore": null,
      "packetLoss": null,
      "jitter": null,
      "latency": null,
      "applicationDelay": null,
      "clientNetworkDelay": null,
      "serverNetworkDelay": null,
      "dscp": "default"
    },
    {
      "time": "2019-07-01T03:10:00.000+0000",
      "healthScore": null,
      "packetLoss": null,
      "jitter": null,
      "latency": null,
      "applicationDelay": null,
      "clientNetworkDelay": null,
      "serverNetworkDelay": null,
      "dscp": "default"
    },
    {
      "time": "2019-07-01T03:05:00.000+0000",
      "healthScore": null,
      "packetLoss": null,
      "jitter": null,
      "latency": null,
      "applicationDelay": null,
      "clientNetworkDelay": null,
      "serverNetworkDelay": null,
      "dscp": "default"
    },
    {
      "time": "2019-07-01T03:00:00.000+0000",
      "healthScore": null,
      "packetLoss": null,
      "jitter": null,
      "latency": null,
      "applicationDelay": null,
      "clientNetworkDelay": null,
      "serverNetworkDelay": null,
      "dscp": "default"
    },
    {
      "time": "2019-07-01T02:55:00.000+0000",
      "healthScore": null,
      "packetLoss": null,
      "jitter": null,
      "latency": null,
      "applicationDelay": null,
      "clientNetworkDelay": null,
      "serverNetworkDelay": null,
      "dscp": "default"
    },
    {
      "time": "2019-07-01T02:50:00.000+0000",
      "healthScore": null,
      "packetLoss": null,
      "jitter": null,
      "latency": null,
      "applicationDelay": null,
      "clientNetworkDelay": null,
      "serverNetworkDelay": null,
      "dscp": "default"
    },
    {
      "time": "2019-07-01T02:45:00.000+0000",
      "healthScore": null,
      "packetLoss": null,
      "jitter": null,
      "latency": null,
      "applicationDelay": null,
      "clientNetworkDelay": null,
      "serverNetworkDelay": null,
      "dscp": "default"
    },
    {
      "time": "2019-07-01T02:40:00.000+0000",
      "healthScore": null,
      "packetLoss": null,
      "jitter": null,
      "latency": null,
      "applicationDelay": null,
      "clientNetworkDelay": null,
      "serverNetworkDelay": null,
      "dscp": "default"
    },
    {
      "time": "2019-07-01T02:35:00.000+0000",
      "healthScore": null,
      "packetLoss": null,
      "jitter": null,
      "latency": null,
      "applicationDelay": null,
      "clientNetworkDelay": null,
      "serverNetworkDelay": null,
      "dscp": "default"
    },
    {
      "time": "2019-07-01T02:30:00.000+0000",
      "healthScore": null,
      "packetLoss": null,
      "jitter": null,
      "latency": null,
      "applicationDelay": null,
      "clientNetworkDelay": null,
      "serverNetworkDelay": null,
      "dscp": "default"
    },
    {
      "time": "2019-07-01T02:25:00.000+0000",
      "healthScore": null,
      "packetLoss": null,
      "jitter": null,
      "latency": null,
      "applicationDelay": null,
      "clientNetworkDelay": null,
      "serverNetworkDelay": null,
      "dscp": "default"
    },
    {
      "time": "2019-07-01T02:20:00.000+0000",
      "healthScore": null,
      "packetLoss": null,
      "jitter": null,
      "latency": null,
      "applicationDelay": null,
      "clientNetworkDelay": null,
      "serverNetworkDelay": null,
      "dscp": "default"
    },
    {
      "time": "2019-07-01T02:15:00.000+0000",
      "healthScore": null,
      "packetLoss": null,
      "jitter": null,
      "latency": null,
      "applicationDelay": null,
      "clientNetworkDelay": null,
      "serverNetworkDelay": null,
      "dscp": "default"
    },
    {
      "time": "2019-07-01T02:10:00.000+0000",
      "healthScore": null,
      "packetLoss": null,
      "jitter": null,
      "latency": null,
      "applicationDelay": null,
      "clientNetworkDelay": null,
      "serverNetworkDelay": null,
      "dscp": "default"
    },
    {
      "time": "2019-07-01T02:05:00.000+0000",
      "healthScore": null,
      "packetLoss": null,
      "jitter": null,
      "latency": null,
      "applicationDelay": null,
      "clientNetworkDelay": null,
      "serverNetworkDelay": null,
      "dscp": "default"
    },
    {
      "time": "2019-07-01T02:00:00.000+0000",
      "healthScore": null,
      "packetLoss": null,
      "jitter": null,
      "latency": null,
      "applicationDelay": null,
      "clientNetworkDelay": null,
      "serverNetworkDelay": null,
      "dscp": "default"
    },
    {
      "time": "2019-07-01T01:55:00.000+0000",
      "healthScore": null,
      "packetLoss": null,
      "jitter": null,
      "latency": null,
      "applicationDelay": null,
      "clientNetworkDelay": null,
      "serverNetworkDelay": null,
      "dscp": "default"
    },
    {
      "time": "2019-07-01T01:50:00.000+0000",
      "healthScore": null,
      "packetLoss": null,
      "jitter": null,
      "latency": null,
      "applicationDelay": null,
      "clientNetworkDelay": null,
      "serverNetworkDelay": null,
      "dscp": "default"
    },
    {
      "time": "2019-07-01T01:45:00.000+0000",
      "healthScore": null,
      "packetLoss": null,
      "jitter": null,
      "latency": null,
      "applicationDelay": null,
      "clientNetworkDelay": null,
      "serverNetworkDelay": null,
      "dscp": "default"
    },
    {
      "time": "2019-07-01T01:40:00.000+0000",
      "healthScore": null,
      "packetLoss": null,
      "jitter": null,
      "latency": null,
      "applicationDelay": null,
      "clientNetworkDelay": null,
      "serverNetworkDelay": null,
      "dscp": "default"
    },
    {
      "time": "2019-07-01T01:35:00.000+0000",
      "healthScore": null,
      "packetLoss": null,
      "jitter": null,
      "latency": null,
      "applicationDelay": null,
      "clientNetworkDelay": null,
      "serverNetworkDelay": null,
      "dscp": "default"
    },
    {
      "time": "2019-07-01T01:30:00.000+0000",
      "healthScore": null,
      "packetLoss": null,
      "jitter": null,
      "latency": null,
      "applicationDelay": null,
      "clientNetworkDelay": null,
      "serverNetworkDelay": null,
      "dscp": "default"
    },
    {
      "time": "2019-07-01T01:25:00.000+0000",
      "healthScore": null,
      "packetLoss": null,
      "jitter": null,
      "latency": null,
      "applicationDelay": null,
      "clientNetworkDelay": null,
      "serverNetworkDelay": null,
      "dscp": "default"
    },
    {
      "time": "2019-07-01T01:20:00.000+0000",
      "healthScore": null,
      "packetLoss": null,
      "jitter": null,
      "latency": null,
      "applicationDelay": null,
      "clientNetworkDelay": null,
      "serverNetworkDelay": null,
      "dscp": "default"
    },
    {
      "time": "2019-07-01T01:15:00.000+0000",
      "healthScore": null,
      "packetLoss": null,
      "jitter": null,
      "latency": null,
      "applicationDelay": null,
      "clientNetworkDelay": null,
      "serverNetworkDelay": null,
      "dscp": "default"
    },
    {
      "time": "2019-07-01T01:10:00.000+0000",
      "healthScore": null,
      "packetLoss": null,
      "jitter": null,
      "latency": null,
      "applicationDelay": null,
      "clientNetworkDelay": null,
      "serverNetworkDelay": null,
      "dscp": "default"
    },
    {
      "time": "2019-07-01T01:05:00.000+0000",
      "healthScore": null,
      "packetLoss": null,
      "jitter": null,
      "latency": null,
      "applicationDelay": null,
      "clientNetworkDelay": null,
      "serverNetworkDelay": null,
      "dscp": "default"
    },
    {
      "time": "2019-07-01T01:00:00.000+0000",
      "healthScore": null,
      "packetLoss": null,
      "jitter": null,
      "latency": null,
      "applicationDelay": null,
      "clientNetworkDelay": null,
      "serverNetworkDelay": null,
      "dscp": "default"
    },
    {
      "time": "2019-07-01T00:55:00.000+0000",
      "healthScore": null,
      "packetLoss": null,
      "jitter": null,
      "latency": null,
      "applicationDelay": null,
      "clientNetworkDelay": null,
      "serverNetworkDelay": null,
      "dscp": "default"
    },
    {
      "time": "2019-07-01T00:50:00.000+0000",
      "healthScore": null,
      "packetLoss": null,
      "jitter": null,
      "latency": null,
      "applicationDelay": null,
      "clientNetworkDelay": null,
      "serverNetworkDelay": null,
      "dscp": "default"
    },
    {
      "time": "2019-07-01T00:45:00.000+0000",
      "healthScore": null,
      "packetLoss": null,
      "jitter": null,
      "latency": null,
      "applicationDelay": null,
      "clientNetworkDelay": null,
      "serverNetworkDelay": null,
      "dscp": "default"
    },
    {
      "time": "2019-07-01T00:40:00.000+0000",
      "healthScore": null,
      "packetLoss": null,
      "jitter": null,
      "latency": null,
      "applicationDelay": null,
      "clientNetworkDelay": null,
      "serverNetworkDelay": null,
      "dscp": "default"
    },
    {
      "time": "2019-07-01T00:35:00.000+0000",
      "healthScore": null,
      "packetLoss": null,
      "jitter": null,
      "latency": null,
      "applicationDelay": null,
      "clientNetworkDelay": null,
      "serverNetworkDelay": null,
      "dscp": "default"
    },
    {
      "time": "2019-07-01T00:30:00.000+0000",
      "healthScore": null,
      "packetLoss": null,
      "jitter": null,
      "latency": null,
      "applicationDelay": null,
      "clientNetworkDelay": null,
      "serverNetworkDelay": null,
      "dscp": "default"
    },
    {
      "time": "2019-07-01T00:25:00.000+0000",
      "healthScore": null,
      "packetLoss": null,
      "jitter": null,
      "latency": null,
      "applicationDelay": null,
      "clientNetworkDelay": null,
      "serverNetworkDelay": null,
      "dscp": "default"
    },
    {
      "time": "2019-07-01T00:20:00.000+0000",
      "healthScore": null,
      "packetLoss": null,
      "jitter": null,
      "latency": null,
      "applicationDelay": null,
      "clientNetworkDelay": null,
      "serverNetworkDelay": null,
      "dscp": "default"
    },
    {
      "time": "2019-07-01T00:15:00.000+0000",
      "healthScore": null,
      "packetLoss": null,
      "jitter": null,
      "latency": null,
      "applicationDelay": null,
      "clientNetworkDelay": null,
      "serverNetworkDelay": null,
      "dscp": "default"
    },
    {
      "time": "2019-07-01T00:10:00.000+0000",
      "healthScore": null,
      "packetLoss": null,
      "jitter": null,
      "latency": null,
      "applicationDelay": null,
      "clientNetworkDelay": null,
      "serverNetworkDelay": null,
      "dscp": "default"
    },
    {
      "time": "2019-07-01T00:05:00.000+0000",
      "healthScore": null,
      "packetLoss": null,
      "jitter": null,
      "latency": null,
      "applicationDelay": null,
      "clientNetworkDelay": null,
      "serverNetworkDelay": null,
      "dscp": "default"
    },
    {
      "time": "2019-07-01T00:00:00.000+0000",
      "healthScore": null,
      "packetLoss": null,
      "jitter": null,
      "latency": null,
      "applicationDelay": null,
      "clientNetworkDelay": null,
      "serverNetworkDelay": null,
      "dscp": "default"
    },
    {
      "time": "2019-06-30T23:55:00.000+0000",
      "healthScore": null,
      "packetLoss": null,
      "jitter": null,
      "latency": null,
      "applicationDelay": null,
      "clientNetworkDelay": null,
      "serverNetworkDelay": null,
      "dscp": "default"
    },
    {
      "time": "2019-06-30T23:50:00.000+0000",
      "healthScore": null,
      "packetLoss": null,
      "jitter": null,
      "latency": null,
      "applicationDelay": null,
      "clientNetworkDelay": null,
      "serverNetworkDelay": null,
      "dscp": "default"
    },
    {
      "time": "2019-06-30T23:45:00.000+0000",
      "healthScore": null,
      "packetLoss": null,
      "jitter": null,
      "latency": null,
      "applicationDelay": null,
      "clientNetworkDelay": null,
      "serverNetworkDelay": null,
      "dscp": "default"
    },
    {
      "time": "2019-06-30T23:40:00.000+0000",
      "healthScore": null,
      "packetLoss": null,
      "jitter": null,
      "latency": null,
      "applicationDelay": null,
      "clientNetworkDelay": null,
      "serverNetworkDelay": null,
      "dscp": "default"
    },
    {
      "time": "2019-06-30T23:35:00.000+0000",
      "healthScore": null,
      "packetLoss": null,
      "jitter": null,
      "latency": null,
      "applicationDelay": null,
      "clientNetworkDelay": null,
      "serverNetworkDelay": null,
      "dscp": "default"
    },
    {
      "time": "2019-06-30T23:30:00.000+0000",
      "healthScore": null,
      "packetLoss": null,
      "jitter": null,
      "latency": null,
      "applicationDelay": null,
      "clientNetworkDelay": null,
      "serverNetworkDelay": null,
      "dscp": "default"
    },
    {
      "time": "2019-06-30T23:25:00.000+0000",
      "healthScore": null,
      "packetLoss": null,
      "jitter": null,
      "latency": null,
      "applicationDelay": null,
      "clientNetworkDelay": null,
      "serverNetworkDelay": null,
      "dscp": "default"
    },
    {
      "time": "2019-06-30T23:20:00.000+0000",
      "healthScore": null,
      "packetLoss": null,
      "jitter": null,
      "latency": null,
      "applicationDelay": null,
      "clientNetworkDelay": null,
      "serverNetworkDelay": null,
      "dscp": "default"
    },
    {
      "time": "2019-06-30T23:15:00.000+0000",
      "healthScore": null,
      "packetLoss": null,
      "jitter": null,
      "latency": null,
      "applicationDelay": null,
      "clientNetworkDelay": null,
      "serverNetworkDelay": null,
      "dscp": "default"
    },
    {
      "time": "2019-06-30T23:10:00.000+0000",
      "healthScore": null,
      "packetLoss": null,
      "jitter": null,
      "latency": null,
      "applicationDelay": null,
      "clientNetworkDelay": null,
      "serverNetworkDelay": null,
      "dscp": "default"
    },
    {
      "time": "2019-06-30T23:05:00.000+0000",
      "healthScore": null,
      "packetLoss": null,
      "jitter": null,
      "latency": null,
      "applicationDelay": null,
      "clientNetworkDelay": null,
      "serverNetworkDelay": null,
      "dscp": "default"
    },
    {
      "time": "2019-06-30T23:00:00.000+0000",
      "healthScore": null,
      "packetLoss": null,
      "jitter": null,
      "latency": null,
      "applicationDelay": null,
      "clientNetworkDelay": null,
      "serverNetworkDelay": null,
      "dscp": "default"
    },
    {
      "time": "2019-06-30T22:55:00.000+0000",
      "healthScore": null,
      "packetLoss": null,
      "jitter": null,
      "latency": null,
      "applicationDelay": null,
      "clientNetworkDelay": null,
      "serverNetworkDelay": null,
      "dscp": "default"
    },
    {
      "time": "2019-06-30T22:50:00.000+0000",
      "healthScore": null,
      "packetLoss": null,
      "jitter": null,
      "latency": null,
      "applicationDelay": null,
      "clientNetworkDelay": null,
      "serverNetworkDelay": null,
      "dscp": "default"
    },
    {
      "time": "2019-06-30T22:45:00.000+0000",
      "healthScore": null,
      "packetLoss": null,
      "jitter": null,
      "latency": null,
      "applicationDelay": null,
      "clientNetworkDelay": null,
      "serverNetworkDelay": null,
      "dscp": "default"
    },
    {
      "time": "2019-06-30T22:40:00.000+0000",
      "healthScore": null,
      "packetLoss": null,
      "jitter": null,
      "latency": null,
      "applicationDelay": null,
      "clientNetworkDelay": null,
      "serverNetworkDelay": null,
      "dscp": "default"
    },
    {
      "time": "2019-06-30T22:35:00.000+0000",
      "healthScore": null,
      "packetLoss": null,
      "jitter": null,
      "latency": null,
      "applicationDelay": null,
      "clientNetworkDelay": null,
      "serverNetworkDelay": null,
      "dscp": "default"
    },
    {
      "time": "2019-06-30T22:30:00.000+0000",
      "healthScore": null,
      "packetLoss": null,
      "jitter": null,
      "latency": null,
      "applicationDelay": null,
      "clientNetworkDelay": null,
      "serverNetworkDelay": null,
      "dscp": "default"
    },
    {
      "time": "2019-06-30T22:25:00.000+0000",
      "healthScore": null,
      "packetLoss": null,
      "jitter": null,
      "latency": null,
      "applicationDelay": null,
      "clientNetworkDelay": null,
      "serverNetworkDelay": null,
      "dscp": "default"
    },
    {
      "time": "2019-06-30T22:20:00.000+0000",
      "healthScore": null,
      "packetLoss": null,
      "jitter": null,
      "latency": null,
      "applicationDelay": null,
      "clientNetworkDelay": null,
      "serverNetworkDelay": null,
      "dscp": "default"
    },
    {
      "time": "2019-06-30T22:15:00.000+0000",
      "healthScore": null,
      "packetLoss": null,
      "jitter": null,
      "latency": null,
      "applicationDelay": null,
      "clientNetworkDelay": null,
      "serverNetworkDelay": null,
      "dscp": "default"
    },
    {
      "time": "2019-06-30T22:10:00.000+0000",
      "healthScore": null,
      "packetLoss": null,
      "jitter": null,
      "latency": null,
      "applicationDelay": null,
      "clientNetworkDelay": null,
      "serverNetworkDelay": null,
      "dscp": "default"
    },
    {
      "time": "2019-06-30T22:05:00.000+0000",
      "healthScore": null,
      "packetLoss": null,
      "jitter": null,
      "latency": null,
      "applicationDelay": null,
      "clientNetworkDelay": null,
      "serverNetworkDelay": null,
      "dscp": "default"
    },
    {
      "time": "2019-06-30T22:00:00.000+0000",
      "healthScore": null,
      "packetLoss": null,
      "jitter": null,
      "latency": null,
      "applicationDelay": null,
      "clientNetworkDelay": null,
      "serverNetworkDelay": null,
      "dscp": "default"
    },
    {
      "time": "2019-06-30T21:55:00.000+0000",
      "healthScore": null,
      "packetLoss": null,
      "jitter": null,
      "latency": null,
      "applicationDelay": null,
      "clientNetworkDelay": null,
      "serverNetworkDelay": null,
      "dscp": "default"
    },
    {
      "time": "2019-06-30T21:50:00.000+0000",
      "healthScore": null,
      "packetLoss": null,
      "jitter": null,
      "latency": null,
      "applicationDelay": null,
      "clientNetworkDelay": null,
      "serverNetworkDelay": null,
      "dscp": "default"
    },
    {
      "time": "2019-06-30T21:45:00.000+0000",
      "healthScore": null,
      "packetLoss": null,
      "jitter": null,
      "latency": null,
      "applicationDelay": null,
      "clientNetworkDelay": null,
      "serverNetworkDelay": null,
      "dscp": "default"
    },
    {
      "time": "2019-06-30T21:40:00.000+0000",
      "healthScore": null,
      "packetLoss": null,
      "jitter": null,
      "latency": null,
      "applicationDelay": null,
      "clientNetworkDelay": null,
      "serverNetworkDelay": null,
      "dscp": "default"
    },
    {
      "time": "2019-06-30T21:35:00.000+0000",
      "healthScore": null,
      "packetLoss": null,
      "jitter": null,
      "latency": null,
      "applicationDelay": null,
      "clientNetworkDelay": null,
      "serverNetworkDelay": null,
      "dscp": "default"
    },
    {
      "time": "2019-06-30T21:30:00.000+0000",
      "healthScore": null,
      "packetLoss": null,
      "jitter": null,
      "latency": null,
      "applicationDelay": null,
      "clientNetworkDelay": null,
      "serverNetworkDelay": null,
      "dscp": "default"
    },
    {
      "time": "2019-06-30T21:25:00.000+0000",
      "healthScore": null,
      "packetLoss": null,
      "jitter": null,
      "latency": null,
      "applicationDelay": null,
      "clientNetworkDelay": null,
      "serverNetworkDelay": null,
      "dscp": "default"
    },
    {
      "time": "2019-06-30T21:20:00.000+0000",
      "healthScore": null,
      "packetLoss": null,
      "jitter": null,
      "latency": null,
      "applicationDelay": null,
      "clientNetworkDelay": null,
      "serverNetworkDelay": null,
      "dscp": "default"
    },
    {
      "time": "2019-06-30T21:15:00.000+0000",
      "healthScore": null,
      "packetLoss": null,
      "jitter": null,
      "latency": null,
      "applicationDelay": null,
      "clientNetworkDelay": null,
      "serverNetworkDelay": null,
      "dscp": "default"
    },
    {
      "time": "2019-06-30T21:10:00.000+0000",
      "healthScore": null,
      "packetLoss": null,
      "jitter": null,
      "latency": null,
      "applicationDelay": null,
      "clientNetworkDelay": null,
      "serverNetworkDelay": null,
      "dscp": "default"
    },
    {
      "time": "2019-06-30T21:05:00.000+0000",
      "healthScore": null,
      "packetLoss": null,
      "jitter": null,
      "latency": null,
      "applicationDelay": null,
      "clientNetworkDelay": null,
      "serverNetworkDelay": null,
      "dscp": "default"
    },
    {
      "time": "2019-06-30T21:00:00.000+0000",
      "healthScore": null,
      "packetLoss": null,
      "jitter": null,
      "latency": null,
      "applicationDelay": null,
      "clientNetworkDelay": null,
      "serverNetworkDelay": null,
      "dscp": "default"
    },
    {
      "time": "2019-06-30T20:55:00.000+0000",
      "healthScore": null,
      "packetLoss": null,
      "jitter": null,
      "latency": null,
      "applicationDelay": null,
      "clientNetworkDelay": null,
      "serverNetworkDelay": null,
      "dscp": "default"
    },
    {
      "time": "2019-06-30T20:50:00.000+0000",
      "healthScore": null,
      "packetLoss": null,
      "jitter": null,
      "latency": null,
      "applicationDelay": null,
      "clientNetworkDelay": null,
      "serverNetworkDelay": null,
      "dscp": "default"
    },
    {
      "time": "2019-06-30T20:45:00.000+0000",
      "healthScore": null,
      "packetLoss": null,
      "jitter": null,
      "latency": null,
      "applicationDelay": null,
      "clientNetworkDelay": null,
      "serverNetworkDelay": null,
      "dscp": "default"
    },
    {
      "time": "2019-06-30T20:40:00.000+0000",
      "healthScore": null,
      "packetLoss": null,
      "jitter": null,
      "latency": null,
      "applicationDelay": null,
      "clientNetworkDelay": null,
      "serverNetworkDelay": null,
      "dscp": "default"
    },
    {
      "time": "2019-06-30T20:35:00.000+0000",
      "healthScore": null,
      "packetLoss": null,
      "jitter": null,
      "latency": null,
      "applicationDelay": null,
      "clientNetworkDelay": null,
      "serverNetworkDelay": null,
      "dscp": "default"
    },
    {
      "time": "2019-06-30T20:30:00.000+0000",
      "healthScore": null,
      "packetLoss": null,
      "jitter": null,
      "latency": null,
      "applicationDelay": null,
      "clientNetworkDelay": null,
      "serverNetworkDelay": null,
      "dscp": "default"
    },
    {
      "time": "2019-06-30T20:25:00.000+0000",
      "healthScore": null,
      "packetLoss": null,
      "jitter": null,
      "latency": null,
      "applicationDelay": null,
      "clientNetworkDelay": null,
      "serverNetworkDelay": null,
      "dscp": "default"
    },
    {
      "time": "2019-06-30T20:20:00.000+0000",
      "healthScore": null,
      "packetLoss": null,
      "jitter": null,
      "latency": null,
      "applicationDelay": null,
      "clientNetworkDelay": null,
      "serverNetworkDelay": null,
      "dscp": "default"
    },
    {
      "time": "2019-06-30T20:15:00.000+0000",
      "healthScore": null,
      "packetLoss": null,
      "jitter": null,
      "latency": null,
      "applicationDelay": null,
      "clientNetworkDelay": null,
      "serverNetworkDelay": null,
      "dscp": "default"
    },
    {
      "time": "2019-06-30T20:10:00.000+0000",
      "healthScore": null,
      "packetLoss": null,
      "jitter": null,
      "latency": null,
      "applicationDelay": null,
      "clientNetworkDelay": null,
      "serverNetworkDelay": null,
      "dscp": "default"
    },
    {
      "time": "2019-06-30T20:05:00.000+0000",
      "healthScore": null,
      "packetLoss": null,
      "jitter": null,
      "latency": null,
      "applicationDelay": null,
      "clientNetworkDelay": null,
      "serverNetworkDelay": null,
      "dscp": "default"
    },
    {
      "time": "2019-06-30T20:00:00.000+0000",
      "healthScore": null,
      "packetLoss": null,
      "jitter": null,
      "latency": null,
      "applicationDelay": null,
      "clientNetworkDelay": null,
      "serverNetworkDelay": null,
      "dscp": "default"
    },
    {
      "time": "2019-06-30T19:55:00.000+0000",
      "healthScore": null,
      "packetLoss": null,
      "jitter": null,
      "latency": null,
      "applicationDelay": null,
      "clientNetworkDelay": null,
      "serverNetworkDelay": null,
      "dscp": "default"
    },
    {
      "time": "2019-06-30T19:50:00.000+0000",
      "healthScore": null,
      "packetLoss": null,
      "jitter": null,
      "latency": null,
      "applicationDelay": null,
      "clientNetworkDelay": null,
      "serverNetworkDelay": null,
      "dscp": "default"
    },
    {
      "time": "2019-06-30T19:45:00.000+0000",
      "healthScore": null,
      "packetLoss": null,
      "jitter": null,
      "latency": null,
      "applicationDelay": null,
      "clientNetworkDelay": null,
      "serverNetworkDelay": null,
      "dscp": "default"
    },
    {
      "time": "2019-06-30T19:40:00.000+0000",
      "healthScore": null,
      "packetLoss": null,
      "jitter": null,
      "latency": null,
      "applicationDelay": null,
      "clientNetworkDelay": null,
      "serverNetworkDelay": null,
      "dscp": "default"
    },
    {
      "time": "2019-06-30T19:35:00.000+0000",
      "healthScore": null,
      "packetLoss": null,
      "jitter": null,
      "latency": null,
      "applicationDelay": null,
      "clientNetworkDelay": null,
      "serverNetworkDelay": null,
      "dscp": "default"
    },
    {
      "time": "2019-06-30T19:30:00.000+0000",
      "healthScore": null,
      "packetLoss": null,
      "jitter": null,
      "latency": null,
      "applicationDelay": null,
      "clientNetworkDelay": null,
      "serverNetworkDelay": null,
      "dscp": "default"
    },
    {
      "time": "2019-06-30T19:25:00.000+0000",
      "healthScore": null,
      "packetLoss": null,
      "jitter": null,
      "latency": null,
      "applicationDelay": null,
      "clientNetworkDelay": null,
      "serverNetworkDelay": null,
      "dscp": "default"
    },
    {
      "time": "2019-06-30T19:20:00.000+0000",
      "healthScore": null,
      "packetLoss": null,
      "jitter": null,
      "latency": null,
      "applicationDelay": null,
      "clientNetworkDelay": null,
      "serverNetworkDelay": null,
      "dscp": "default"
    },
    {
      "time": "2019-06-30T19:15:00.000+0000",
      "healthScore": null,
      "packetLoss": null,
      "jitter": null,
      "latency": null,
      "applicationDelay": null,
      "clientNetworkDelay": null,
      "serverNetworkDelay": null,
      "dscp": "default"
    },
    {
      "time": "2019-06-30T19:10:00.000+0000",
      "healthScore": null,
      "packetLoss": null,
      "jitter": null,
      "latency": null,
      "applicationDelay": null,
      "clientNetworkDelay": null,
      "serverNetworkDelay": null,
      "dscp": "default"
    },
    {
      "time": "2019-06-30T19:05:00.000+0000",
      "healthScore": null,
      "packetLoss": null,
      "jitter": null,
      "latency": null,
      "applicationDelay": null,
      "clientNetworkDelay": null,
      "serverNetworkDelay": null,
      "dscp": "default"
    },
    {
      "time": "2019-06-30T19:00:00.000+0000",
      "healthScore": null,
      "packetLoss": null,
      "jitter": null,
      "latency": null,
      "applicationDelay": null,
      "clientNetworkDelay": null,
      "serverNetworkDelay": null,
      "dscp": "default"
    },
    {
      "time": "2019-06-30T18:55:00.000+0000",
      "healthScore": null,
      "packetLoss": null,
      "jitter": null,
      "latency": null,
      "applicationDelay": null,
      "clientNetworkDelay": null,
      "serverNetworkDelay": null,
      "dscp": "default"
    },
    {
      "time": "2019-06-30T18:50:00.000+0000",
      "healthScore": null,
      "packetLoss": null,
      "jitter": null,
      "latency": null,
      "applicationDelay": null,
      "clientNetworkDelay": null,
      "serverNetworkDelay": null,
      "dscp": "default"
    },
    {
      "time": "2019-06-30T18:45:00.000+0000",
      "healthScore": null,
      "packetLoss": null,
      "jitter": null,
      "latency": null,
      "applicationDelay": null,
      "clientNetworkDelay": null,
      "serverNetworkDelay": null,
      "dscp": "default"
    },
    {
      "time": "2019-06-30T18:40:00.000+0000",
      "healthScore": null,
      "packetLoss": null,
      "jitter": null,
      "latency": null,
      "applicationDelay": null,
      "clientNetworkDelay": null,
      "serverNetworkDelay": null,
      "dscp": "default"
    },
    {
      "time": "2019-06-30T18:35:00.000+0000",
      "healthScore": null,
      "packetLoss": null,
      "jitter": null,
      "latency": null,
      "applicationDelay": null,
      "clientNetworkDelay": null,
      "serverNetworkDelay": null,
      "dscp": "default"
    },
    {
      "time": "2019-06-30T18:30:00.000+0000",
      "healthScore": null,
      "packetLoss": null,
      "jitter": null,
      "latency": null,
      "applicationDelay": null,
      "clientNetworkDelay": null,
      "serverNetworkDelay": null,
      "dscp": "default"
    },
    {
      "time": "2019-06-30T18:25:00.000+0000",
      "healthScore": null,
      "packetLoss": null,
      "jitter": null,
      "latency": null,
      "applicationDelay": null,
      "clientNetworkDelay": null,
      "serverNetworkDelay": null,
      "dscp": "default"
    },
    {
      "time": "2019-06-30T18:20:00.000+0000",
      "healthScore": null,
      "packetLoss": null,
      "jitter": null,
      "latency": null,
      "applicationDelay": null,
      "clientNetworkDelay": null,
      "serverNetworkDelay": null,
      "dscp": "default"
    },
    {
      "time": "2019-06-30T18:15:00.000+0000",
      "healthScore": null,
      "packetLoss": null,
      "jitter": null,
      "latency": null,
      "applicationDelay": null,
      "clientNetworkDelay": null,
      "serverNetworkDelay": null,
      "dscp": "default"
    },
    {
      "time": "2019-06-30T18:10:00.000+0000",
      "healthScore": null,
      "packetLoss": null,
      "jitter": null,
      "latency": null,
      "applicationDelay": null,
      "clientNetworkDelay": null,
      "serverNetworkDelay": null,
      "dscp": "default"
    },
    {
      "time": "2019-06-30T18:05:00.000+0000",
      "healthScore": null,
      "packetLoss": null,
      "jitter": null,
      "latency": null,
      "applicationDelay": null,
      "clientNetworkDelay": null,
      "serverNetworkDelay": null,
      "dscp": "default"
    },
    {
      "time": "2019-06-30T18:00:00.000+0000",
      "healthScore": null,
      "packetLoss": null,
      "jitter": null,
      "latency": null,
      "applicationDelay": null,
      "clientNetworkDelay": null,
      "serverNetworkDelay": null,
      "dscp": "default"
    },
    {
      "time": "2019-06-30T17:55:00.000+0000",
      "healthScore": null,
      "packetLoss": null,
      "jitter": null,
      "latency": null,
      "applicationDelay": null,
      "clientNetworkDelay": null,
      "serverNetworkDelay": null,
      "dscp": "default"
    },
    {
      "time": "2019-06-30T17:50:00.000+0000",
      "healthScore": null,
      "packetLoss": null,
      "jitter": null,
      "latency": null,
      "applicationDelay": null,
      "clientNetworkDelay": null,
      "serverNetworkDelay": null,
      "dscp": "default"
    },
    {
      "time": "2019-06-30T17:45:00.000+0000",
      "healthScore": null,
      "packetLoss": null,
      "jitter": null,
      "latency": null,
      "applicationDelay": null,
      "clientNetworkDelay": null,
      "serverNetworkDelay": null,
      "dscp": "default"
    },
    {
      "time": "2019-06-30T17:40:00.000+0000",
      "healthScore": null,
      "packetLoss": null,
      "jitter": null,
      "latency": null,
      "applicationDelay": null,
      "clientNetworkDelay": null,
      "serverNetworkDelay": null,
      "dscp": "default"
    },
    {
      "time": "2019-06-30T17:35:00.000+0000",
      "healthScore": null,
      "packetLoss": null,
      "jitter": null,
      "latency": null,
      "applicationDelay": null,
      "clientNetworkDelay": null,
      "serverNetworkDelay": null,
      "dscp": "default"
    },
    {
      "time": "2019-06-30T17:30:00.000+0000",
      "healthScore": null,
      "packetLoss": null,
      "jitter": null,
      "latency": null,
      "applicationDelay": null,
      "clientNetworkDelay": null,
      "serverNetworkDelay": null,
      "dscp": "default"
    },
    {
      "time": "2019-06-30T17:25:00.000+0000",
      "healthScore": null,
      "packetLoss": null,
      "jitter": null,
      "latency": null,
      "applicationDelay": null,
      "clientNetworkDelay": null,
      "serverNetworkDelay": null,
      "dscp": "default"
    },
    {
      "time": "2019-06-30T17:20:00.000+0000",
      "healthScore": null,
      "packetLoss": null,
      "jitter": null,
      "latency": null,
      "applicationDelay": null,
      "clientNetworkDelay": null,
      "serverNetworkDelay": null,
      "dscp": "default"
    },
    {
      "time": "2019-06-30T17:15:00.000+0000",
      "healthScore": null,
      "packetLoss": null,
      "jitter": null,
      "latency": null,
      "applicationDelay": null,
      "clientNetworkDelay": null,
      "serverNetworkDelay": null,
      "dscp": "default"
    },
    {
      "time": "2019-06-30T17:10:00.000+0000",
      "healthScore": null,
      "packetLoss": null,
      "jitter": null,
      "latency": null,
      "applicationDelay": null,
      "clientNetworkDelay": null,
      "serverNetworkDelay": null,
      "dscp": "default"
    },
    {
      "time": "2019-06-30T17:05:00.000+0000",
      "healthScore": null,
      "packetLoss": null,
      "jitter": null,
      "latency": null,
      "applicationDelay": null,
      "clientNetworkDelay": null,
      "serverNetworkDelay": null,
      "dscp": "default"
    },
    {
      "time": "2019-06-30T17:00:00.000+0000",
      "healthScore": null,
      "packetLoss": null,
      "jitter": null,
      "latency": null,
      "applicationDelay": null,
      "clientNetworkDelay": null,
      "serverNetworkDelay": null,
      "dscp": "default"
    },
    {
      "time": "2019-06-30T16:55:00.000+0000",
      "healthScore": null,
      "packetLoss": null,
      "jitter": null,
      "latency": null,
      "applicationDelay": null,
      "clientNetworkDelay": null,
      "serverNetworkDelay": null,
      "dscp": "default"
    },
    {
      "time": "2019-06-30T16:50:00.000+0000",
      "healthScore": null,
      "packetLoss": null,
      "jitter": null,
      "latency": null,
      "applicationDelay": null,
      "clientNetworkDelay": null,
      "serverNetworkDelay": null,
      "dscp": "default"
    },
    {
      "time": "2019-06-30T16:45:00.000+0000",
      "healthScore": null,
      "packetLoss": null,
      "jitter": null,
      "latency": null,
      "applicationDelay": null,
      "clientNetworkDelay": null,
      "serverNetworkDelay": null,
      "dscp": "default"
    },
    {
      "time": "2019-06-30T16:40:00.000+0000",
      "healthScore": null,
      "packetLoss": null,
      "jitter": null,
      "latency": null,
      "applicationDelay": null,
      "clientNetworkDelay": null,
      "serverNetworkDelay": null,
      "dscp": "default"
    },
    {
      "time": "2019-06-30T16:35:00.000+0000",
      "healthScore": null,
      "packetLoss": null,
      "jitter": null,
      "latency": null,
      "applicationDelay": null,
      "clientNetworkDelay": null,
      "serverNetworkDelay": null,
      "dscp": "default"
    },
    {
      "time": "2019-06-30T16:30:00.000+0000",
      "healthScore": null,
      "packetLoss": null,
      "jitter": null,
      "latency": null,
      "applicationDelay": null,
      "clientNetworkDelay": null,
      "serverNetworkDelay": null,
      "dscp": "default"
    },
    {
      "time": "2019-06-30T16:25:00.000+0000",
      "healthScore": null,
      "packetLoss": null,
      "jitter": null,
      "latency": null,
      "applicationDelay": null,
      "clientNetworkDelay": null,
      "serverNetworkDelay": null,
      "dscp": "default"
    },
    {
      "time": "2019-06-30T16:20:00.000+0000",
      "healthScore": null,
      "packetLoss": null,
      "jitter": null,
      "latency": null,
      "applicationDelay": null,
      "clientNetworkDelay": null,
      "serverNetworkDelay": null,
      "dscp": "default"
    },
    {
      "time": "2019-06-30T16:15:00.000+0000",
      "healthScore": null,
      "packetLoss": null,
      "jitter": null,
      "latency": null,
      "applicationDelay": null,
      "clientNetworkDelay": null,
      "serverNetworkDelay": null,
      "dscp": "default"
    },
    {
      "time": "2019-06-30T16:10:00.000+0000",
      "healthScore": null,
      "packetLoss": null,
      "jitter": null,
      "latency": null,
      "applicationDelay": null,
      "clientNetworkDelay": null,
      "serverNetworkDelay": null,
      "dscp": "default"
    },
    {
      "time": "2019-06-30T16:05:00.000+0000",
      "healthScore": null,
      "packetLoss": null,
      "jitter": null,
      "latency": null,
      "applicationDelay": null,
      "clientNetworkDelay": null,
      "serverNetworkDelay": null,
      "dscp": "default"
    },
    {
      "time": "2019-06-30T16:00:00.000+0000",
      "healthScore": null,
      "packetLoss": null,
      "jitter": null,
      "latency": null,
      "applicationDelay": null,
      "clientNetworkDelay": null,
      "serverNetworkDelay": null,
      "dscp": "default"
    },
    {
      "time": "2019-06-30T15:55:00.000+0000",
      "healthScore": null,
      "packetLoss": null,
      "jitter": null,
      "latency": null,
      "applicationDelay": null,
      "clientNetworkDelay": null,
      "serverNetworkDelay": null,
      "dscp": "default"
    },
    {
      "time": "2019-06-30T15:50:00.000+0000",
      "healthScore": null,
      "packetLoss": null,
      "jitter": null,
      "latency": null,
      "applicationDelay": null,
      "clientNetworkDelay": null,
      "serverNetworkDelay": null,
      "dscp": "default"
    },
    {
      "time": "2019-06-30T15:45:00.000+0000",
      "healthScore": null,
      "packetLoss": null,
      "jitter": null,
      "latency": null,
      "applicationDelay": null,
      "clientNetworkDelay": null,
      "serverNetworkDelay": null,
      "dscp": "default"
    },
    {
      "time": "2019-06-30T15:40:00.000+0000",
      "healthScore": null,
      "packetLoss": null,
      "jitter": null,
      "latency": null,
      "applicationDelay": null,
      "clientNetworkDelay": null,
      "serverNetworkDelay": null,
      "dscp": "default"
    },
    {
      "time": "2019-06-30T15:35:00.000+0000",
      "healthScore": null,
      "packetLoss": null,
      "jitter": null,
      "latency": null,
      "applicationDelay": null,
      "clientNetworkDelay": null,
      "serverNetworkDelay": null,
      "dscp": "default"
    },
    {
      "time": "2019-06-30T15:30:00.000+0000",
      "healthScore": null,
      "packetLoss": null,
      "jitter": null,
      "latency": null,
      "applicationDelay": null,
      "clientNetworkDelay": null,
      "serverNetworkDelay": null,
      "dscp": "default"
    },
    {
      "time": "2019-06-30T15:25:00.000+0000",
      "healthScore": null,
      "packetLoss": null,
      "jitter": null,
      "latency": null,
      "applicationDelay": null,
      "clientNetworkDelay": null,
      "serverNetworkDelay": null,
      "dscp": "default"
    },
    {
      "time": "2019-06-30T15:20:00.000+0000",
      "healthScore": null,
      "packetLoss": null,
      "jitter": null,
      "latency": null,
      "applicationDelay": null,
      "clientNetworkDelay": null,
      "serverNetworkDelay": null,
      "dscp": "default"
    },
    {
      "time": "2019-06-30T15:15:00.000+0000",
      "healthScore": null,
      "packetLoss": null,
      "jitter": null,
      "latency": null,
      "applicationDelay": null,
      "clientNetworkDelay": null,
      "serverNetworkDelay": null,
      "dscp": "default"
    },
    {
      "time": "2019-06-30T15:10:00.000+0000",
      "healthScore": null,
      "packetLoss": null,
      "jitter": null,
      "latency": null,
      "applicationDelay": null,
      "clientNetworkDelay": null,
      "serverNetworkDelay": null,
      "dscp": "default"
    },
    {
      "time": "2019-06-30T15:05:00.000+0000",
      "healthScore": null,
      "packetLoss": null,
      "jitter": null,
      "latency": null,
      "applicationDelay": null,
      "clientNetworkDelay": null,
      "serverNetworkDelay": null,
      "dscp": "default"
    },
    {
      "time": "2019-06-30T15:00:00.000+0000",
      "healthScore": null,
      "packetLoss": null,
      "jitter": null,
      "latency": null,
      "applicationDelay": null,
      "clientNetworkDelay": null,
      "serverNetworkDelay": null,
      "dscp": "default"
    },
    {
      "time": "2019-06-30T14:55:00.000+0000",
      "healthScore": null,
      "packetLoss": null,
      "jitter": null,
      "latency": null,
      "applicationDelay": null,
      "clientNetworkDelay": null,
      "serverNetworkDelay": null,
      "dscp": "default"
    },
    {
      "time": "2019-06-30T14:50:00.000+0000",
      "healthScore": null,
      "packetLoss": null,
      "jitter": null,
      "latency": null,
      "applicationDelay": null,
      "clientNetworkDelay": null,
      "serverNetworkDelay": null,
      "dscp": "default"
    },
    {
      "time": "2019-06-30T14:45:00.000+0000",
      "healthScore": null,
      "packetLoss": null,
      "jitter": null,
      "latency": null,
      "applicationDelay": null,
      "clientNetworkDelay": null,
      "serverNetworkDelay": null,
      "dscp": "default"
    },
    {
      "time": "2019-06-30T14:40:00.000+0000",
      "healthScore": null,
      "packetLoss": null,
      "jitter": null,
      "latency": null,
      "applicationDelay": null,
      "clientNetworkDelay": null,
      "serverNetworkDelay": null,
      "dscp": "default"
    },
    {
      "time": "2019-06-30T14:35:00.000+0000",
      "healthScore": null,
      "packetLoss": null,
      "jitter": null,
      "latency": null,
      "applicationDelay": null,
      "clientNetworkDelay": null,
      "serverNetworkDelay": null,
      "dscp": "default"
    },
    {
      "time": "2019-06-30T14:30:00.000+0000",
      "healthScore": null,
      "packetLoss": null,
      "jitter": null,
      "latency": null,
      "applicationDelay": null,
      "clientNetworkDelay": null,
      "serverNetworkDelay": null,
      "dscp": "default"
    },
    {
      "time": "2019-06-30T14:25:00.000+0000",
      "healthScore": null,
      "packetLoss": null,
      "jitter": null,
      "latency": null,
      "applicationDelay": null,
      "clientNetworkDelay": null,
      "serverNetworkDelay": null,
      "dscp": "default"
    },
    {
      "time": "2019-06-30T14:20:00.000+0000",
      "healthScore": null,
      "packetLoss": null,
      "jitter": null,
      "latency": null,
      "applicationDelay": null,
      "clientNetworkDelay": null,
      "serverNetworkDelay": null,
      "dscp": "default"
    },
    {
      "time": "2019-06-30T14:15:00.000+0000",
      "healthScore": null,
      "packetLoss": null,
      "jitter": null,
      "latency": null,
      "applicationDelay": null,
      "clientNetworkDelay": null,
      "serverNetworkDelay": null,
      "dscp": "default"
    },
    {
      "time": "2019-06-30T14:10:00.000+0000",
      "healthScore": null,
      "packetLoss": null,
      "jitter": null,
      "latency": null,
      "applicationDelay": null,
      "clientNetworkDelay": null,
      "serverNetworkDelay": null,
      "dscp": "default"
    },
    {
      "time": "2019-06-30T14:05:00.000+0000",
      "healthScore": null,
      "packetLoss": null,
      "jitter": null,
      "latency": null,
      "applicationDelay": null,
      "clientNetworkDelay": null,
      "serverNetworkDelay": null,
      "dscp": "default"
    },
    {
      "time": "2019-06-30T14:00:00.000+0000",
      "healthScore": null,
      "packetLoss": null,
      "jitter": null,
      "latency": null,
      "applicationDelay": null,
      "clientNetworkDelay": null,
      "serverNetworkDelay": null,
      "dscp": "default"
    },
    {
      "time": "2019-06-30T13:55:00.000+0000",
      "healthScore": null,
      "packetLoss": null,
      "jitter": null,
      "latency": null,
      "applicationDelay": null,
      "clientNetworkDelay": null,
      "serverNetworkDelay": null,
      "dscp": "default"
    },
    {
      "time": "2019-06-30T13:50:00.000+0000",
      "healthScore": null,
      "packetLoss": null,
      "jitter": null,
      "latency": null,
      "applicationDelay": null,
      "clientNetworkDelay": null,
      "serverNetworkDelay": null,
      "dscp": "default"
    },
    {
      "time": "2019-06-30T13:45:00.000+0000",
      "healthScore": null,
      "packetLoss": null,
      "jitter": null,
      "latency": null,
      "applicationDelay": null,
      "clientNetworkDelay": null,
      "serverNetworkDelay": null,
      "dscp": "default"
    },
    {
      "time": "2019-06-30T13:40:00.000+0000",
      "healthScore": null,
      "packetLoss": null,
      "jitter": null,
      "latency": null,
      "applicationDelay": null,
      "clientNetworkDelay": null,
      "serverNetworkDelay": null,
      "dscp": "default"
    },
    {
      "time": "2019-06-30T13:35:00.000+0000",
      "healthScore": null,
      "packetLoss": null,
      "jitter": null,
      "latency": null,
      "applicationDelay": null,
      "clientNetworkDelay": null,
      "serverNetworkDelay": null,
      "dscp": "default"
    },
    {
      "time": "2019-06-30T13:30:00.000+0000",
      "healthScore": null,
      "packetLoss": null,
      "jitter": null,
      "latency": null,
      "applicationDelay": null,
      "clientNetworkDelay": null,
      "serverNetworkDelay": null,
      "dscp": "default"
    },
    {
      "time": "2019-06-30T13:25:00.000+0000",
      "healthScore": null,
      "packetLoss": null,
      "jitter": null,
      "latency": null,
      "applicationDelay": null,
      "clientNetworkDelay": null,
      "serverNetworkDelay": null,
      "dscp": "default"
    },
    {
      "time": "2019-06-30T13:20:00.000+0000",
      "healthScore": null,
      "packetLoss": null,
      "jitter": null,
      "latency": null,
      "applicationDelay": null,
      "clientNetworkDelay": null,
      "serverNetworkDelay": null,
      "dscp": "default"
    },
    {
      "time": "2019-06-30T13:15:00.000+0000",
      "healthScore": null,
      "packetLoss": null,
      "jitter": null,
      "latency": null,
      "applicationDelay": null,
      "clientNetworkDelay": null,
      "serverNetworkDelay": null,
      "dscp": "default"
    },
    {
      "time": "2019-06-30T13:10:00.000+0000",
      "healthScore": null,
      "packetLoss": null,
      "jitter": null,
      "latency": null,
      "applicationDelay": null,
      "clientNetworkDelay": null,
      "serverNetworkDelay": null,
      "dscp": "default"
    },
    {
      "time": "2019-06-30T13:05:00.000+0000",
      "healthScore": null,
      "packetLoss": null,
      "jitter": null,
      "latency": null,
      "applicationDelay": null,
      "clientNetworkDelay": null,
      "serverNetworkDelay": null,
      "dscp": "default"
    },
    {
      "time": "2019-06-30T13:00:00.000+0000",
      "healthScore": null,
      "packetLoss": null,
      "jitter": null,
      "latency": null,
      "applicationDelay": null,
      "clientNetworkDelay": null,
      "serverNetworkDelay": null,
      "dscp": "default"
    },
    {
      "time": "2019-06-30T12:55:00.000+0000",
      "healthScore": null,
      "packetLoss": null,
      "jitter": null,
      "latency": null,
      "applicationDelay": null,
      "clientNetworkDelay": null,
      "serverNetworkDelay": null,
      "dscp": "default"
    },
    {
      "time": "2019-06-30T12:50:00.000+0000",
      "healthScore": null,
      "packetLoss": null,
      "jitter": null,
      "latency": null,
      "applicationDelay": null,
      "clientNetworkDelay": null,
      "serverNetworkDelay": null,
      "dscp": "default"
    },
    {
      "time": "2019-06-30T12:45:00.000+0000",
      "healthScore": null,
      "packetLoss": null,
      "jitter": null,
      "latency": null,
      "applicationDelay": null,
      "clientNetworkDelay": null,
      "serverNetworkDelay": null,
      "dscp": "default"
    },
    {
      "time": "2019-06-30T12:40:00.000+0000",
      "healthScore": null,
      "packetLoss": null,
      "jitter": null,
      "latency": null,
      "applicationDelay": null,
      "clientNetworkDelay": null,
      "serverNetworkDelay": null,
      "dscp": "default"
    },
    {
      "time": "2019-06-30T12:35:00.000+0000",
      "healthScore": null,
      "packetLoss": null,
      "jitter": null,
      "latency": null,
      "applicationDelay": null,
      "clientNetworkDelay": null,
      "serverNetworkDelay": null,
      "dscp": "default"
    },
    {
      "time": "2019-06-30T12:30:00.000+0000",
      "healthScore": null,
      "packetLoss": null,
      "jitter": null,
      "latency": null,
      "applicationDelay": null,
      "clientNetworkDelay": null,
      "serverNetworkDelay": null,
      "dscp": "default"
    },
    {
      "time": "2019-06-30T12:25:00.000+0000",
      "healthScore": null,
      "packetLoss": null,
      "jitter": null,
      "latency": null,
      "applicationDelay": null,
      "clientNetworkDelay": null,
      "serverNetworkDelay": null,
      "dscp": "default"
    },
    {
      "time": "2019-06-30T12:20:00.000+0000",
      "healthScore": null,
      "packetLoss": null,
      "jitter": null,
      "latency": null,
      "applicationDelay": null,
      "clientNetworkDelay": null,
      "serverNetworkDelay": null,
      "dscp": "default"
    },
    {
      "time": "2019-06-30T12:15:00.000+0000",
      "healthScore": null,
      "packetLoss": null,
      "jitter": null,
      "latency": null,
      "applicationDelay": null,
      "clientNetworkDelay": null,
      "serverNetworkDelay": null,
      "dscp": "default"
    },
    {
      "time": "2019-06-30T12:10:00.000+0000",
      "healthScore": null,
      "packetLoss": null,
      "jitter": null,
      "latency": null,
      "applicationDelay": null,
      "clientNetworkDelay": null,
      "serverNetworkDelay": null,
      "dscp": "default"
    },
    {
      "time": "2019-06-30T12:05:00.000+0000",
      "healthScore": null,
      "packetLoss": null,
      "jitter": null,
      "latency": null,
      "applicationDelay": null,
      "clientNetworkDelay": null,
      "serverNetworkDelay": null,
      "dscp": "default"
    },
    {
      "time": "2019-06-30T12:00:00.000+0000",
      "healthScore": null,
      "packetLoss": null,
      "jitter": null,
      "latency": null,
      "applicationDelay": null,
      "clientNetworkDelay": null,
      "serverNetworkDelay": null,
      "dscp": "default"
    },
    {
      "time": "2019-06-30T11:55:00.000+0000",
      "healthScore": null,
      "packetLoss": null,
      "jitter": null,
      "latency": null,
      "applicationDelay": null,
      "clientNetworkDelay": null,
      "serverNetworkDelay": null,
      "dscp": "default"
    },
    {
      "time": "2019-06-30T11:50:00.000+0000",
      "healthScore": null,
      "packetLoss": null,
      "jitter": null,
      "latency": null,
      "applicationDelay": null,
      "clientNetworkDelay": null,
      "serverNetworkDelay": null,
      "dscp": "default"
    },
    {
      "time": "2019-06-30T11:45:00.000+0000",
      "healthScore": null,
      "packetLoss": null,
      "jitter": null,
      "latency": null,
      "applicationDelay": null,
      "clientNetworkDelay": null,
      "serverNetworkDelay": null,
      "dscp": "default"
    },
    {
      "time": "2019-06-30T11:40:00.000+0000",
      "healthScore": null,
      "packetLoss": null,
      "jitter": null,
      "latency": null,
      "applicationDelay": null,
      "clientNetworkDelay": null,
      "serverNetworkDelay": null,
      "dscp": "default"
    },
    {
      "time": "2019-06-30T11:35:00.000+0000",
      "healthScore": null,
      "packetLoss": null,
      "jitter": null,
      "latency": null,
      "applicationDelay": null,
      "clientNetworkDelay": null,
      "serverNetworkDelay": null,
      "dscp": "default"
    },
    {
      "time": "2019-06-30T11:30:00.000+0000",
      "healthScore": null,
      "packetLoss": null,
      "jitter": null,
      "latency": null,
      "applicationDelay": null,
      "clientNetworkDelay": null,
      "serverNetworkDelay": null,
      "dscp": "default"
    },
    {
      "time": "2019-06-30T11:25:00.000+0000",
      "healthScore": null,
      "packetLoss": null,
      "jitter": null,
      "latency": null,
      "applicationDelay": null,
      "clientNetworkDelay": null,
      "serverNetworkDelay": null,
      "dscp": "default"
    },
    {
      "time": "2019-06-30T11:20:00.000+0000",
      "healthScore": null,
      "packetLoss": null,
      "jitter": null,
      "latency": null,
      "applicationDelay": null,
      "clientNetworkDelay": null,
      "serverNetworkDelay": null,
      "dscp": "default"
    },
    {
      "time": "2019-06-30T11:15:00.000+0000",
      "healthScore": null,
      "packetLoss": null,
      "jitter": null,
      "latency": null,
      "applicationDelay": null,
      "clientNetworkDelay": null,
      "serverNetworkDelay": null,
      "dscp": "default"
    },
    {
      "time": "2019-06-30T11:10:00.000+0000",
      "healthScore": null,
      "packetLoss": null,
      "jitter": null,
      "latency": null,
      "applicationDelay": null,
      "clientNetworkDelay": null,
      "serverNetworkDelay": null,
      "dscp": "default"
    },
    {
      "time": "2019-06-30T11:05:00.000+0000",
      "healthScore": null,
      "packetLoss": null,
      "jitter": null,
      "latency": null,
      "applicationDelay": null,
      "clientNetworkDelay": null,
      "serverNetworkDelay": null,
      "dscp": "default"
    },
    {
      "time": "2019-06-30T11:00:00.000+0000",
      "healthScore": null,
      "packetLoss": null,
      "jitter": null,
      "latency": null,
      "applicationDelay": null,
      "clientNetworkDelay": null,
      "serverNetworkDelay": null,
      "dscp": "default"
    },
    {
      "time": "2019-06-30T10:55:00.000+0000",
      "healthScore": null,
      "packetLoss": null,
      "jitter": null,
      "latency": null,
      "applicationDelay": null,
      "clientNetworkDelay": null,
      "serverNetworkDelay": null,
      "dscp": "default"
    },
    {
      "time": "2019-06-30T10:50:00.000+0000",
      "healthScore": null,
      "packetLoss": null,
      "jitter": null,
      "latency": null,
      "applicationDelay": null,
      "clientNetworkDelay": null,
      "serverNetworkDelay": null,
      "dscp": "default"
    },
    {
      "time": "2019-06-30T10:45:00.000+0000",
      "healthScore": null,
      "packetLoss": null,
      "jitter": null,
      "latency": null,
      "applicationDelay": null,
      "clientNetworkDelay": null,
      "serverNetworkDelay": null,
      "dscp": "default"
    },
    {
      "time": "2019-06-30T10:40:00.000+0000",
      "healthScore": null,
      "packetLoss": null,
      "jitter": null,
      "latency": null,
      "applicationDelay": null,
      "clientNetworkDelay": null,
      "serverNetworkDelay": null,
      "dscp": "default"
    },
    {
      "time": "2019-06-30T10:35:00.000+0000",
      "healthScore": null,
      "packetLoss": null,
      "jitter": null,
      "latency": null,
      "applicationDelay": null,
      "clientNetworkDelay": null,
      "serverNetworkDelay": null,
      "dscp": "default"
    },
    {
      "time": "2019-06-30T10:30:00.000+0000",
      "healthScore": null,
      "packetLoss": null,
      "jitter": null,
      "latency": null,
      "applicationDelay": null,
      "clientNetworkDelay": null,
      "serverNetworkDelay": null,
      "dscp": "default"
    },
    {
      "time": "2019-06-30T10:25:00.000+0000",
      "healthScore": null,
      "packetLoss": null,
      "jitter": null,
      "latency": null,
      "applicationDelay": null,
      "clientNetworkDelay": null,
      "serverNetworkDelay": null,
      "dscp": "default"
    },
    {
      "time": "2019-06-30T10:20:00.000+0000",
      "healthScore": null,
      "packetLoss": null,
      "jitter": null,
      "latency": null,
      "applicationDelay": null,
      "clientNetworkDelay": null,
      "serverNetworkDelay": null,
      "dscp": "default"
    },
    {
      "time": "2019-06-30T10:15:00.000+0000",
      "healthScore": null,
      "packetLoss": null,
      "jitter": null,
      "latency": null,
      "applicationDelay": null,
      "clientNetworkDelay": null,
      "serverNetworkDelay": null,
      "dscp": "default"
    }
  ]
}

})