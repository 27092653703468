//NFVIS
define({
    "runningImageList":[
      {
        "name": "Cisco_NFVIS_BRANCH-4.4.2-FC2.iso",
        "imageUuid": "02ffdaed-0987-efef-2222-5001ef27951e",
        "version": "4.4.2",
        "softwareType": null,
        "imageType": "RELEASE",
        "isTaggedGolden": "true",
        "md5Checksum": "9cb17c279a3c6cb17d7412e2cb589cd3",
        "shaCheckSum": "d98acfb2c61af5b91e30e305c614366d19ee5face632dc066d462a904790980a44aa1db14cc54422e4a9f02b1cef803ad2d1fd11bb599e2687381c75c2c29706",
        "createdTime": "2021-03-15 19:26:00.00",
        "applicationType": null,
        "feature": null,
        "fileServiceId": "abcd8765-1414-4236-aa32-f2619cebe23c",
        "imageSeries": null,
        "imageSource": "CCO",
        "imageSourceType": "Imported from CCO",
        "imageFamily": "NFVIS",
        "vendor": null,
        "filesize": 1730516992,
        "deviceCount": 0,
        "deviceUuidList": [],
        "smuCount": 0,
        "imageAttributes": [],
        "isSMUApplicable": false,
        "goldenTagInheritedFromSiteUuid": null,
        "goldenTagInheritedFromSiteName": null,
        "importSupported": true,
        "reasonForNotSupportingImport": null,
        "tagList": [
          {
            "taggedGolden": true,
            "role": "ALL",
            "inheritedSiteName": null,
            "inheritedSiteUuid": null
          },
          {
            "taggedGolden": false,
            "role": "CORE",
            "inheritedSiteName": null,
            "inheritedSiteUuid": null
          },
          {
            "taggedGolden": false,
            "role": "DISTRIBUTION",
            "inheritedSiteName": null,
            "inheritedSiteUuid": null
          },
          {
            "taggedGolden": false,
            "role": "BORDER ROUTER",
            "inheritedSiteName": null,
            "inheritedSiteUuid": null
          },
          {
            "taggedGolden": false,
            "role": "UNKNOWN",
            "inheritedSiteName": null,
            "inheritedSiteUuid": null
          },
          {
            "taggedGolden": false,
            "role": "ACCESS",
            "inheritedSiteName": null,
            "inheritedSiteUuid": null
          }
        ],
        "profileInfo": null,
        "physicallyAvailable": true,
        "assignCount": 0,
        "showUnassign": false,
        "disableUnassign": false,
        "imageCategory": "PHYSICAL",
        "imageIntegrityStatus": null,
        "imageImported": true,
        "applicableDevicesForImage": null,
        "ccorecommended": true,
        "ccolatest": true
    },
    {
        "name": "Cisco_NFVIS_BRANCH-4.2.1-FC3.iso",
        "imageUuid": "02ffdaed-0987-404d-2222-5001ef27951e",
        "version": "4.2.1",
        "softwareType": null,
        "imageType": "RELEASE",
        "isTaggedGolden": "false",
        "md5Checksum": "067df588b6fe30bcac001fad401a418a",
        "shaCheckSum": "c14f98d85f3125c5879ccc6f81f11e856809f53a4c521227e109debeef49f1f35ebf0c3bd51be279676c5a1ae179223595c55f9961cecc1c0a7381e3299642f0",
        "createdTime": "2020-10-16 14:51:00.00",//12 Aug 2020 Release
        "applicationType": null,
        "feature": null,
        "fileServiceId": "abcd8765-1414-ef01-aa32-f2619cebe23c",
        "imageSeries": null,
        "imageSource": "CCO",
        "imageSourceType": "Imported from CCO",
        "imageFamily": "NFVIS",
        "vendor": null,
        "filesize": 1542934528,
        "deviceCount": 0,
        "deviceUuidList": [],
        "smuCount": 0,
        "imageAttributes": [],
        "isSMUApplicable": false,
        "goldenTagInheritedFromSiteUuid": null,
        "goldenTagInheritedFromSiteName": null,
        "importSupported": true,
        "reasonForNotSupportingImport": null,
        "tagList": [
          {
            "taggedGolden": false,
            "role": "ALL",
            "inheritedSiteName": null,
            "inheritedSiteUuid": null
          },
          {
            "taggedGolden": false,
            "role": "CORE",
            "inheritedSiteName": null,
            "inheritedSiteUuid": null
          },
          {
            "taggedGolden": false,
            "role": "DISTRIBUTION",
            "inheritedSiteName": null,
            "inheritedSiteUuid": null
          },
          {
            "taggedGolden": false,
            "role": "BORDER ROUTER",
            "inheritedSiteName": null,
            "inheritedSiteUuid": null
          },
          {
            "taggedGolden": false,
            "role": "UNKNOWN",
            "inheritedSiteName": null,
            "inheritedSiteUuid": null
          },
          {
            "taggedGolden": false,
            "role": "ACCESS",
            "inheritedSiteName": null,
            "inheritedSiteUuid": null
          }
        ],
        "profileInfo": null,
        "physicallyAvailable": true,
        "assignCount": 0,
        "showUnassign": false,
        "disableUnassign": false,
        "imageCategory": "PHYSICAL",
        "imageIntegrityStatus": null,
        "imageImported": true,
        "applicableDevicesForImage": null,
        "ccorecommended": true,
        "ccolatest": true
    },
    {
        "name": "Cisco_NFVIS_BRANCH-4.1.1-FC1.iso",
        "imageUuid": "02ffdaed-40ef-404d-2222-5001ef27951e",
        "version": "4.1.1",
        "softwareType": null,
        "imageType": "RELEASE",
        "isTaggedGolden": "false",
        "md5Checksum": "e758bb2e8432069a1f0a3467a080b46e",
        "shaCheckSum": "494d53851484b240593ff0c3c82e1d68e04701aeb5fde51c17fd0bec9385b77ea7fd022d2af54c51f817684de1061f9b4c03ecf62f6597c7777f888c82a75219",
        "createdTime": "2020-04-28 21:50:00.00",//30 Mar 2020 Release
        "applicationType": null,
        "feature": null,
        "fileServiceId": "abcd8765-4321-ef01-aa32-f2619cebe23c",
        "imageSeries": null,
        "imageSource": "CCO",
        "imageSourceType": "Imported from CCO",
        "imageFamily": "NFVIS",
        "vendor": null,
        "filesize": 1370099712,
        "deviceCount": 0,
        "deviceUuidList": [],
        "smuCount": 0,
        "imageAttributes": [],
        "isSMUApplicable": false,
        "goldenTagInheritedFromSiteUuid": null,
        "goldenTagInheritedFromSiteName": null,
        "importSupported": true,
        "reasonForNotSupportingImport": null,
        "tagList": [
          {
            "taggedGolden": false,
            "role": "ALL",
            "inheritedSiteName": null,
            "inheritedSiteUuid": null
          },
          {
            "taggedGolden": false,
            "role": "CORE",
            "inheritedSiteName": null,
            "inheritedSiteUuid": null
          },
          {
            "taggedGolden": false,
            "role": "DISTRIBUTION",
            "inheritedSiteName": null,
            "inheritedSiteUuid": null
          },
          {
            "taggedGolden": false,
            "role": "BORDER ROUTER",
            "inheritedSiteName": null,
            "inheritedSiteUuid": null
          },
          {
            "taggedGolden": false,
            "role": "UNKNOWN",
            "inheritedSiteName": null,
            "inheritedSiteUuid": null
          },
          {
            "taggedGolden": false,
            "role": "ACCESS",
            "inheritedSiteName": null,
            "inheritedSiteUuid": null
          }
        ],
        "profileInfo": null,
        "physicallyAvailable": true,
        "assignCount": 0,
        "showUnassign": false,
        "disableUnassign": false,
        "imageCategory": "PHYSICAL",
        "imageIntegrityStatus": null,
        "imageImported": true,
        "applicableDevicesForImage": null,
        "ccorecommended": false,
        "ccolatest": true
    },
    {
        "name": "Cisco_NFVIS_BRANCH-3.12.3-FC4.iso",
        "imageUuid": "02ffdaed-40ef-404d-1111-5001ef27951e",
        "version": "3.12.3",
        "softwareType": null,
        "imageType": "RELEASE",
        "isTaggedGolden": "false",
        "md5Checksum": "e758bb2e8432069a1f0a3467a080b46e",
        "shaCheckSum": "494d53851484b240593ff0c3c82e1d68e04701aeb5fde51c17fd0bec9385b77ea7fd022d2af54c51f817684de1061f9b4c03ecf62f6597c7777f888c82a75219",
        "createdTime": "2020-01-31 13:33:00.00",
        "applicationType": null,
        "feature": null,
        "fileServiceId": "abcd8765-4321-ef01-aa32-f2619cebe23c",
        "imageSeries": null,
        "imageSource": "CCO",
        "imageSourceType": "Imported from CCO",
        "imageFamily": "NFVIS",
        "vendor": null,
        "filesize": 1401063424,
        "deviceCount": 0,
        "deviceUuidList": [],
        "smuCount": 0,
        "imageAttributes": [],
        "isSMUApplicable": false,
        "goldenTagInheritedFromSiteUuid": null,
        "goldenTagInheritedFromSiteName": null,
        "importSupported": true,
        "reasonForNotSupportingImport": null,
        "tagList": [
          {
            "taggedGolden": false,
            "role": "ALL",
            "inheritedSiteName": null,
            "inheritedSiteUuid": null
          },
          {
            "taggedGolden": false,
            "role": "CORE",
            "inheritedSiteName": null,
            "inheritedSiteUuid": null
          },
          {
            "taggedGolden": false,
            "role": "DISTRIBUTION",
            "inheritedSiteName": null,
            "inheritedSiteUuid": null
          },
          {
            "taggedGolden": false,
            "role": "BORDER ROUTER",
            "inheritedSiteName": null,
            "inheritedSiteUuid": null
          },
          {
            "taggedGolden": false,
            "role": "UNKNOWN",
            "inheritedSiteName": null,
            "inheritedSiteUuid": null
          },
          {
            "taggedGolden": false,
            "role": "ACCESS",
            "inheritedSiteName": null,
            "inheritedSiteUuid": null
          }
        ],
        "profileInfo": null,
        "physicallyAvailable": true,
        "assignCount": 0,
        "showUnassign": false,
        "disableUnassign": false,
        "imageCategory": "PHYSICAL",
        "imageIntegrityStatus": null,
        "imageImported": true,
        "applicableDevicesForImage": null,
        "ccorecommended": false,
        "ccolatest": true
      }
    ]
});