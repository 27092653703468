define({
  "Template": {
    "version": "1.0",
    "response": [
      {
        "id": "AWTyNkXMeyCAF3hQG4AH",
        "name": "wireless_client_onboarding",
        "entityType": "Client",
        "entity": "7C:46:85:20:7B:27",
        "groupBy": "SSID@ToChange",
        "category": "Onboarding",
        "severity": "HIGH",
        "priority": "P1",
        "summary": "Wireless client took a long time to connect (SSID: SSID@ToChange, AP: AP@ToChange, Band: Band@ToChange GHz) - Excessive time for Onboarding",
        "scope": "GLOBAL",
        "rootCause": "EXCESSIVE_TOTAL",
        "timestamp": 1533071907997,
        "description": "This client is taking longer than expected time to connect to 'SSID@ToChange' SSID<ul><li>Onboarding took 19.5 seconds seconds(expected time should be less than 10.0 seconds) </li><li>Association took less than a second seconds(expected time should be less than 2.0 seconds)</li><li>Authentication and Key Exchange took less than a second seconds(expected time should be less than 3.0 seconds)</li><li>IP Addressing took 0 seconds seconds(expected time should be less than Band@ToChange seconds)</li><li>This client tried to onboard 1 times which took 19.4 seconds .</li></ul><br>The client was connecting to 'SSID@ToChange' SSID on Band@ToChange GHz radio on 'AP@ToChange' AP  in 'Site@ToChange'. The AP was connected to 'Wlc@ToChange' WLC.",
        "suggestions": [
          {
            "message": "Check whether the client moved during the authentication phase.",
            "steps": [

            ]
          },
          {
            "message": "Check the RF conditions at the client location.",
            "steps": [

            ]
          },
          {
            "message": "Verify whether the  AAA server is responsive.",
            "steps": [

            ]
          },
          {
            "message": "Verify whether the  AAA server is responsive during MAC authentication.",
            "steps": [

            ]
          },
          {
            "message": "Verify whether the 10.13.4.1 DHCP server is responsive.",
            "steps": [

            ]
          },
          {
            "message": "Verify whether the DHCP client is responsive.",
            "steps": [

            ]
          },
          {
            "message": "Verify the network load between the AP and the WLC is less than the load between the WLC, AAA server, and DHCP server.",
            "steps": [

            ]
          }
        ],
        "additionalParams": [
          {
            "key": "hostName",
            "value": "android-2ccc07873cebb1f0"
          },
          {
            "key": "groupType",
            "value": "SSID"
          },
          {
            "key": "assocTime",
            "value": "1"
          },
          {
            "key": "authTime",
            "value": "138"
          },
          {
            "key": "groupBy",
            "value": "@CorpSSID"
          },
          {
            "key": "slot",
            "value": "1"
          },
          {
            "key": "type",
            "value": "issue"
          },
          {
            "key": "reasonType",
            "value": "cl_NO_REASON"
          },
          {
            "key": "previousApName",
            "value": "AP3800-7"
          },
          {
            "key": "ssid",
            "value": "@CorpSSID"
          },
          {
            "key": "apName",
            "value": "AP3800-4"
          },
          {
            "key": "frequency",
            "value": "5.0"
          },
          {
            "key": "reasonCodes",
            "value": "2,122,0,13,1,122,0,0,233"
          },
          {
            "key": "vlan",
            "value": "0"
          },
          {
            "key": "hostType",
            "value": "WIRELESS"
          },
          {
            "key": "rootCause",
            "value": "EXCESSIVE_TOTAL"
          },
          {
            "key": "aaaServerIp",
            "value": ""
          },
          {
            "key": "resultType",
            "value": "cl_SUCCESS"
          },
          {
            "key": "dhcpTime",
            "value": "0"
          },
          {
            "key": "hostOSType",
            "value": "Android-Motorola"
          },
          {
            "key": "isRoaming",
            "value": "true"
          },
          {
            "key": "entityType",
            "value": "interface_host"
          },
          {
            "key": "totalTime",
            "value": "19497"
          },
          {
            "key": "__entity_type__",
            "value": "Interface"
          },
          {
            "key": "previousWlcName",
            "value": ""
          },
          {
            "key": "apGroup",
            "value": "Wave2_APs"
          },
          {
            "key": "dhcpServerIp",
            "value": "10.13.4.1"
          },
          {
            "key": "apMac",
            "value": "CC:16:7E:5F:CC:10"
          },
          {
            "key": "entityId",
            "value": "7C:46:85:20:7B:27"
          },
          {
            "key": "eventType",
            "value": "PLACEHOLDER_FOR_INDEX_0"
          },
          {
            "key": "eventTypes",
            "value": "1,2,3,3,1,2,5,9,7"
          },
          {
            "key": "wlcName",
            "value": "CT5520-MK"
          },
          {
            "key": "eventTimestamps",
            "value": "1533071888500,0,2,119,19237,1,138,0,97"
          },
          {
            "key": "resultTypes",
            "value": "0,0,0,0,0,0,0,0,0"
          },
          {
            "key": "siteId",
            "value": "33bbd24d-7df6-4dd6-bd4e-ce8397345889"
          },
          {
            "key": "_id",
            "value": "7C:46:85:20:7B:27"
          },
          {
            "key": "category",
            "value": "onboarding"
          },
          {
            "key": "timestring",
            "value": "2018-07-31T21:18:27.997+0000"
          },
          {
            "key": "status",
            "value": "active"
          },
          {
            "key": "macAddr",
            "value": "7C:46:85:20:7B:27"
          }
        ],
        "status": {
          "status": "ACTIVE",
          "updatedBy": "Unknown",
          "notes": "Unknown",
          "source": "Unknown",
          "updatedAt": null
        },
        "siteQulaifiedName": "SJ/SJC23/Floor1"
      }
    ]
  }
})