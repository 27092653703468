define({
    "ISSUE_MAPPINGS": {
        "932eec26-db6e-40a5-a7d7-d2dfec91e8e7": {
            "dnacIssueId":"932eec26-db6e-40a5-a7d7-d2dfec91e8e7",
            "kairosId":"a1119232256982aa4247",
            "eventEntityId":"0625904349027df26431",
            "defDaysBack":"-2",
            "issueTimings":{
                "-2":["05:00:00","05:30:00","12:00:00","15:00:00","18:00:00"],
                "-1":["02:30:00","05:00:00","05:30:00","06:00:00"],
                "length":8
            },
            "issueDateTimes": [],
            "templateFile": "AI_EXCESSIVE_TIME"
        },
        "c646a06b-e496-43f2-ac20-10b7534c71e9": {
            "dnacIssueId":"c646a06b-e496-43f2-ac20-10b7534c71e9",
            "kairosId":"a101720e0d3123c2b2ca",
            "eventEntityId":"02e379f1b7d456fcf063",
            "radioEntityId":"02e379f1b7d456fcf063",
            "defDaysBack":"-2",
            "issueTimings":{
                "-2":["05:00:00","23:30:00","23:00:00","22:30:00","21:00:00"],
                "-1":["01:00:00","02:00:00","02:30:00"],
                "length":8
            },
            "issueDateTimes": [],
            "templateFile": "AI_TOTAL_RADIO_THROUGHPUT"
        },
         "3daf3e83-fa98-4294-90b5-c67c75258bb1": {
            "dnacIssueId":"3daf3e83-fa98-4294-90b5-c67c75258bb1",
            "kairosId":"a1cb44dd724b4059d40e",
            "eventEntityId":"0734121bc9dfa2fd2eea",
            "radioEntityId":"0734121bc9dfa2fd2eea",
            "defDaysBack":"-1",
            "issueTimings":{
                "-1":["17:00:00","18:30:00"],
                "length":2
            },
            "issueDateTimes": [],
            "templateFile": "AI_EXCESSIVE_TIME_IPADDRESS"
        },
        "c02fa82d-e298-44a9-a6de-040de5df885a": {
            "dnacIssueId":"c02fa82d-e298-44a9-a6de-040de5df885a",
            "kairosId":"a1ac2f925f0cc524fe4f",
            "eventEntityId":"023bae05a489d7c4d23e",
            "radioEntityId":"023bae05a489d7c4d23e",
            "defDaysBack":"-1",
            "issueTimings":{
                "-1":["01:00:00","03:30:00"],
                "length":2
            },
            "issueDateTimes": [],
            "templateFile": "AI_THROUGHPUT_SOCIAL"
        },
        "944b489e-c7c7-4589-bb5d-6450947cde30": {
            "dnacIssueId":"944b489e-c7c7-4589-bb5d-6450947cde30",
            "kairosId":"a1483ce0fd8abf62ec50",
            "eventEntityId":"02ddf4159d244969d190",
            "radioEntityId":"02ddf4159d244969d190",
            "defDaysBack":"-1",
            "issueTimings":{
                "-1":["06:00:00","06:30:00","07:00:00"],
                "length":3
            },
            "issueDateTimes":[],
            "templateFile":"AI_THROUGHPUT_CLOUD"
        },
        "ec9881d1-c12c-44bf-ad31-23d9df7c8aa0": {
            "dnacIssueId":"ec9881d1-c12c-44bf-ad31-23d9df7c8aa0",
            "kairosId":"a198acd938cdf278341c",
            "eventEntityId":"06aa489325abd983979e", //06873d194e0135357e3a //068f430aed1960b1e418
            "defDaysBack":"-1",
            "issueTimings":{
                "-1":["17:00:00","18:00:00","18:30:00","19:00:00","19:30:00","20:00:00","20:30:00","21:00:00","21:30:00","22:00:00","22:30:00","23:00:00"],
                "length":12
            },
            "additionalIssueTimings":{
                "-2":["11:30:00","12:30:00"]
            },
            "issueDateTimes":[],
            "templateFile":"AI_FAILURE_CONNECT"
        },
        "4ee45868-5cde-4737-a9b1-acab7621fbc1": {
            "dnacIssueId": "4ee45868-5cde-4737-a9b1-acab7621fbc1",
            "kairosId": "a1313820c2b2f30377c2",
            "eventEntityId": "036e825e8dff3181ab4a",
            "radioEntityId": "036e825e8dff3181ab4a",
            "defDaysBack": -2,
            "issueTimings": {
                "-2": ["20:00:00", "20:30:00", "23:00:00", "23:30:00"],
                "-1": ["02:00:00", "02:30:00","03.30.00","04.00.00"],
                "length": 8
            },
            "issueDateTimes": [],
            "templateFile": "AI_RADIO_THROUGHPUT_MEDIA_APPS"
        }
    }
})