define([
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/database/SimLokiDatabaseActions',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/utilities/UtilityFunctions',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/data-generation/configuration/DefaultConfig',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/virtualnetwork/VirtualNetworkContextTemplate',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/virtualnetwork/VirtualNetworkTemplate',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/scalablegroup/ScalableGroupIseTemplate'
], function(SimLokiDatabaseActions, UtilityFunctions, DefaultConfig, VirtualNetworkContextTemplate, VirtualNetworkTemplate, ScalableGroupIseTemplate){

  function createVirtualNetwork(jsonObj, isDefault,isInfra, isEmployee, isIOT) {
      var tVnContextJson =  JSON.parse(JSON.stringify( VirtualNetworkContextTemplate.VirtualNetworkContext_Template));
      var tVnJson =  JSON.parse(JSON.stringify( VirtualNetworkTemplate.VirtualNetwork_Template));
      var vncontextid = '';
      var vnid = '', vnName = '', isVNdefault = false;
      var  defaultSiteList= ['SJC01','SJC06','SJC22','SJC23',"SFO11","SFO12","SFO13","SFO15","SJC04"];
      if(isDefault){
          vncontextid = DefaultConfig.DEFAULT_UUID.VirtualNetworkContext;
          vnid = DefaultConfig.DEFAULT_UUID.VirtualNetwork;
          vnName =  DefaultConfig.DEFAULT_NAME.VirtualNetwork;

          tVnContextJson.name = vnName;

          tVnContextJson.virtualNetworkContextType = 'DEFAULT';
          tVnContextJson.lastUpdateTime = UtilityFunctions.getTimeStamp();
          var scalableGroup = SimLokiDatabaseActions.getFilteredRecordHandler('scalablegroup',{'scalableGroupType':'USER-DEVICE'})
        //   var scalableGroup = SimLokiDatabaseActions.getAll("scalablegroupsummary");
          for(var i=0; i < scalableGroup.length; i++) {
              if( scalableGroup[i].scalableGroupType == 'USER-DEVICE') {
                  var t_Json = JSON.parse(JSON.stringify(scalableGroup[i]));
                  tVnContextJson.scalableGroup.push({ idRef: t_Json.id });
              }
          }

          isVNdefault = true;
          tVnContextJson.isDefault = true;
          tVnJson.isDefault = true;
          tVnJson.name = vnName;

          //Assigning default sites to VN
          var siteList = [];
          for(var k=0; k < defaultSiteList.length; k++){
            var siteObj = SimLokiDatabaseActions.getFilteredRecordHandler('site',{'name':defaultSiteList[k]})
            siteList.push(siteObj[0].id)    
          }
          tVnContextJson.siteList = siteList;
          tVnContextJson.siteCount = siteList.length;


      } else if(isInfra || isEmployee || isIOT){
          vncontextid = UtilityFunctions.generate_uuid();
          vnid = UtilityFunctions.generate_uuid();

          if(isInfra){
            vnName =  DefaultConfig.DEFAULT_NAME.VirtualNetworkInfra;
            tVnContextJson.virtualNetworkContextType = 'INFRA';
          }
          if(isEmployee){
            vnName =  DefaultConfig.DEFAULT_NAME.VirtualNetworkEmployee;
            tVnContextJson.virtualNetworkContextType = 'EMPLOYEE';
          }
          if(isIOT){
            vnName =  DefaultConfig.DEFAULT_NAME.VirtualNetworkIOT;
            tVnContextJson.virtualNetworkContextType = 'IOT';
          }
          

          tVnContextJson.name = vnName;
          tVnContextJson.lastUpdateTime = UtilityFunctions.getTimeStamp();

          //Assigning default sites to VN
          var siteList = [];
          for(var k=0; k < defaultSiteList.length; k++){
            var siteObj = SimLokiDatabaseActions.getFilteredRecordHandler('site',{'name':defaultSiteList[k]})
            siteList.push(siteObj[0].id)    
          }

          tVnContextJson.siteList = siteList;
          tVnContextJson.siteCount = siteList.length;

      }else {
          //create VN with default FD
          vncontextid = UtilityFunctions.generate_uuid();
          vnid = UtilityFunctions.generate_uuid();
          vnName =  jsonObj.name;
          tVnContextJson.name = jsonObj.name;
          tVnContextJson.virtualNetworkContextType = jsonObj.virtualNetworkContextType;

          tVnContextJson.sdWanVrfName = jsonObj.sdWanVrfName;

          //1 . create hash/key of scalaeble group..
          var scalableGroupHash = new Object();
          if(jsonObj.scalableGroup != undefined) {
              for(var j =0; j < jsonObj.scalableGroup.length; j++){
                  tVnContextJson.scalableGroup.push({ idRef: jsonObj.scalableGroup[j].idRef });

                  scalableGroupHash[jsonObj.scalableGroup[j].idRef] = j;
                  var sgObj = SimLokiDatabaseActions.getFilteredRecordHandler('scalablegroup', {'id': jsonObj.scalableGroup[j].idRef});
                  sgObj[0].acaScalableGroupVNInfo[0].name = jsonObj.name;
                  sgObj[0].acaScalableGroupVNInfo[0].id = vncontextid;
                  sgObj[0].acaScalableGroupVNInfo[0].instanceId = 81082;
                  SimLokiDatabaseActions.update('scalablegroup', sgObj)
              }
          }

          //2 . if scalaeble group is used by other vnc, delete from there...
          var vncObjs = SimLokiDatabaseActions.getFilteredRecordHandler('virtualnetworkcontext');
          if(vncObjs && vncObjs.length){
              for(var i = 0, len = vncObjs.length; i < len; i++){
                  for(j = 0; j < vncObjs[i].scalableGroup.length;){
                     //3. if match found delete
                     if(scalableGroupHash[vncObjs[i].scalableGroup[j].idRef] != undefined){
                         vncObjs[i].scalableGroup.splice(j, 1);
                     }else {
                         j++;
                     }
                  }
              }
              //4. update vnc..
              SimLokiDatabaseActions.update('virtualnetworkcontext', vncObjs);
          }
          //Code written by Karan for 1.3.1, after creating the VN need to update the scalableGroup with VN Ref ID
        //   if(jsonObj.scalableGroup != undefined) {
        //       for(var j =0; j < jsonObj.scalableGroup.length; j++){
        //         var sgObj = SimLokiDatabaseActions.getFilteredRecordHandler('scalablegroup', {'id': jsonObj.scalableGroup[j].idRef});
        //         sgObj[0].acaScalableGroupVNInfo[0].name = jsonObj.name;
        //         sgObj[0].acaScalableGroupVNInfo[0].id = vncontextid;
        //         sgObj[0].acaScalableGroupVNInfo[0].instanceId = 81082;
        //         SimLokiDatabaseActions.update('scalablegroup', sgObj)
        //         }
        //     }
        }

      tVnContextJson.id = vncontextid;
      tVnContextJson.instanceId = UtilityFunctions.getRandomIntForRangeValues(81082, 81090);
      tVnContextJson.virtualNetworkInstanceUuid = vncontextid;
      tVnContextJson.virtualNetworkName = tVnContextJson.name;
      tVnContextJson.vnStatus = "ACTIVE";
      tVnContextJson.scalableGroupList = tVnContextJson.scalableGroup;
      tVnContextJson.ipPoolSubnetList = [];
      tVnContextJson.l3Instance = UtilityFunctions.getRandomIntForRangeValues(4101, 4199)
      
      SimLokiDatabaseActions.insert('virtualnetworkcontext', tVnContextJson );

      //add the VN to each of the fabric domain sites
      /* var fdObjs = SimLokiDatabaseActions.getFilteredRecordHandler('ConnectivityDomain');
      //console.log(fdObjs);
      if (fdObjs && fdObjs.length) {

          for(var i = 0, len = fdObjs.length; i < len; i++){
              var vnfdLinkid = this.createVNwithFD(vncontextid, vnName, isVNdefault,fdObjs[i].id, fdObjs[i].name);
              fdObjs[i].virtualNetwork.push({ 'idRef' : vnfdLinkid });
          }
          SimLokiDatabaseActions.update('ConnectivityDomain', fdObjs);
      } */
  }

  function updateVirtualNetworkContext(jsonObj){
      var recordObj = SimLokiDatabaseActions.getFilteredRecordHandler('virtualnetworkcontext', { 'id' : jsonObj.id });
      if(recordObj && recordObj.length){
          recordObj[0].scalableGroup = [];
          recordObj[0].scalableGroupList = [];
          var sgVar = jsonObj.scalableGroup.map(e=>e.idRef);
          sgVar = Array.from(new Set(sgVar));
          if(sgVar[0]!=undefined){
            for(var i=0; i < sgVar.length; i++){
                recordObj[0].scalableGroup.push({ idRef: sgVar[i] });
                recordObj[0].scalableGroupList.push({ idRef: sgVar[i] });
                var sgObj = SimLokiDatabaseActions.getFilteredRecordHandler('scalablegroup',{'id':sgVar[i]})
                // Commenting below line to aviod duplicate creation of VN in GBPA-->Scalable Group
              //   var isExist = sgObj[0].acaScalableGroupVNInfo.filter(e=>e.id == jsonObj.id);
                var isExist = sgObj[0].acaScalableGroupVNInfo.filter(e=>e.name == jsonObj.name);
                if((isExist.length == 0)){
                    var vndata = {}
                    vndata.id = jsonObj.id;
                    vndata.instanceId = sgObj[0].id;
                    vndata.displayName = sgObj[0].displayName;
                    vndata.instanceVersion = sgObj[0].instanceVersion;
                    vndata.name = recordObj[0].name;
                    sgObj[0].acaScalableGroupVNInfo.push(vndata)
                    if(sgObj[0].virtualNetworkNames.indexOf(recordObj[0].name) == -1){
                        sgObj[0].virtualNetworkNames = sgObj[0].virtualNetworkNames+','+recordObj[0].name;
                      }
                  sgObj[0].virtualNetworksCount = sgObj[0].acaScalableGroupVNInfo.length;
                  SimLokiDatabaseActions.update('scalablegroup',sgObj)   
                }
            }
          }

          recordObj[0].sdWanVrfName = jsonObj.sdWanVrfName;
          recordObj[0].virtualNetworkContextType = jsonObj.virtualNetworkContextType;
          SimLokiDatabaseActions.update('virtualnetworkcontext', recordObj);

          //As put request if scalable group used by other vn has to be removed..
        //   deleteGroupsFromOtherVnc(jsonObj.id, jsonObj.scalableGroup);

        //   missingGroupsToDefaultVnc();
      }
  }

  function associateDeletedVNGroupToDefaultVN(scalableGroups){
     var tId = DefaultConfig.DEFAULT_UUID.VirtualNetworkContext;
      var obj = SimLokiDatabaseActions.getFilteredRecordHandler('virtualnetworkcontext', { 'id' : tId });
      if(obj && obj.length){
          var i =0, len = scalableGroups.length;

          for(;i < len; i++){
              obj[0].scalableGroup.push({ idRef: scalableGroups[i].idRef });
              obj[0].scalableGroupList.push({ idRef: scalableGroups[i].idRef });
          }
          SimLokiDatabaseActions.update('virtualnetworkcontext', obj);
      }
  }

   function deleteGroupsFromOtherVnc(vncId, scalableGroups){
       var vncObjs = SimLokiDatabaseActions.getAll('virtualnetworkcontext');
       var seen = new Object();
       var i = 0, len = scalableGroups.length;
       for(; i <len; i++){
           seen[scalableGroups[i].idRef] = i;
       }

       if(vncObjs && vncObjs.length) {
           len = vncObjs.length;
           for(i=0; i < len; i++){
               if(vncObjs[i].id != vncId) {
                   var j = 0;
                   for(; j < vncObjs[i].scalableGroup.length;) {
                       if(seen[vncObjs[i].scalableGroup[j].idRef] == undefined) {
                           j++;
                       } else {
                           vncObjs[i].scalableGroup.splice(j, 1);
                           vncObjs[i].scalableGroupList.splice(j, 1);
                       }
                   }
               }
           }
           SimLokiDatabaseActions.update('virtualnetworkcontext', vncObjs);
       }
   }

   function missingGroupsToDefaultVnc(){
       var vncObjs = SimLokiDatabaseActions.getAll('virtualnetworkcontext');
       var seen = new Object();
       var i,j, defaultvncIndx;
       if(vncObjs && vncObjs.length) {
           for(i=0; i < vncObjs.length; i++){
              if(vncObjs[i].isDefault) {
                  defaultvncIndx = i;
              }

              for(j=0; j < vncObjs[i].scalableGroup.length;j++) {
                 seen[vncObjs[i].scalableGroup[j].idRef] = j;
              }
           }
       }
       var len = Object.keys(seen).length;
       var groups = SimLokiDatabaseActions.getFilteredRecordHandler('scalablegroup',{'scalableGroupType':'USER_DEVICE'});
       if(len < groups.length){
           //some groups have been deleted from vnc, add missing group to default vnc..
           for(i=0; i < groups.length; i++){
               if(seen[groups[i].id] == undefined) {
                   vncObjs[defaultvncIndx].scalableGroup.push({ idRef: groups[i].id });
                   vncObjs[defaultvncIndx].scalableGroupList.push({ idRef: groups[i].id });
               }
           }
           SimLokiDatabaseActions.update('virtualnetworkcontext', vncObjs);
       }
   }

  return {
      init: function(){
          createVirtualNetwork(null, true);
          createVirtualNetwork(null, false,true);
          createVirtualNetwork(null, false,false, true);
          createVirtualNetwork(null, false,false, false, true);
      },

      CreateOrUpdateOrDeleteVNContext: function(operation, jsonObj){
          var tJson =  JSON.parse(JSON.stringify( VirtualNetworkContextTemplate.VirtualNetworkContext_Template));
          if(operation == 'post'){
              createVirtualNetwork(jsonObj, false);
          }
          else if(operation == 'put'){
              updateVirtualNetworkContext(jsonObj);
          }
          else if(operation == 'delete'){
              var recordObj = SimLokiDatabaseActions.getFilteredRecordHandler('virtualnetworkcontext', { 'id' : jsonObj.id });
              if(recordObj && recordObj.length){
                  SimLokiDatabaseActions.delete('virtualnetworkcontext', recordObj);
              }

              var vnObj = SimLokiDatabaseActions.getFilteredRecordHandler('VirtualNetwork', { 'virtualNetworkContextId' : jsonObj.id });
              if(vnObj && vnObj.length){
                  var vnId = vnObj[0].id;
                  associateDeletedVNGroupToDefaultVN(recordObj[0].scalableGroup);

                  SimLokiDatabaseActions.delete('VirtualNetwork', vnObj);

                  var fdObj = SimLokiDatabaseActions.getFilteredRecordHandler('ConnectivityDomain', { 'type' : 'ConnectivityDomain' });
                  if(fdObj && fdObj.length){
                      for(var i=0; i < fdObj.length; i++) {
                          for(var j=0; j < fdObj[i].virtualNetwork.length;){
                              if(fdObj[i].virtualNetwork[j].idRef == vnId){
                                  fdObj[i].virtualNetwork.splice(j, 1);
                                  SimLokiDatabaseActions.update('ConnectivityDomain', fdObj);
                              } else {
                                  j++;
                              }
                          }
                      }
                  }
              }
          }
      },

      createVNwithFD: function(vncontextid, vnName, isVNdefault, fdId, fdName){
        var tVnJson =  JSON.parse(JSON.stringify( VirtualNetworkTemplate.VirtualNetwork_Template));
        var vnid = UtilityFunctions.generate_uuid();
        var tName = vnName + '-' + fdName;
        tVnJson.id = vnid;
        tVnJson.name = tName;
        tVnJson.namespace = fdId;
        tVnJson.virtualNetworkContextId = vncontextid;
        tVnJson.isDefault = isVNdefault;
        
        //add the anhored VN created hosted on other(parent) site (site1), here (site2)
        //in other words, inherited anchor VN check
        var resObj = this.vnAnchoredCheck(vncontextid);
        if(!UtilityFunctions.isObjectEmpty(resObj)) {
            tVnJson.anchor = JSON.parse(JSON.stringify(VirtualNetworkTemplate.ANCHOR_PART_TEMPLATE));
            tVnJson.anchor.id = UtilityFunctions.generate_uuid();
            tVnJson.anchor.anchoredAt = resObj.siteId;
            tVnJson.anchor.siteName = resObj.siteName;
            tVnJson.anchor.vnId = resObj.vnId;
            tVnJson.anchor.anchorDevice[0].deviceType = "BORDER_"+resObj.siteId;
            tVnJson.anchor.anchorDevice[0].deviceuuid = resObj.borderDevIds;
            tVnJson.anchor.anchorDevice[1].deviceType = "CP_"+resObj.siteId;
            tVnJson.anchor.anchorDevice[1].deviceuuid = resObj.cpDevIds;
        }

        SimLokiDatabaseActions.insert( 'VirtualNetwork', tVnJson );
        return vnid;
    },

    /** check if there is already an anchor VN for this virtual network context in any toher site. If so, get some data to fill in template  */
    vnAnchoredCheck: function(vncontextid) {
        var result = {};
        //get VNC (VN in policy)
        var recordObj = SimLokiDatabaseActions.getFilteredRecordHandler('virtualnetworkcontext', { 'id' : vncontextid });
        if(recordObj.length>0) {
            if(recordObj[0].isAnchored_sim) {
                var isFound = false, domains = [];
                //get VN of Fabric Site
                var vns =  SimLokiDatabaseActions.getFilteredRecordHandler('VirtualNetwork', 
                    { 'virtualNetworkContextId' : vncontextid });
                vns.forEach(vn => {
                    if(vn.anchor) {
                        isFound = true;
                        //getFabric Site Domain under which this VN exists
                        var domains = SimLokiDatabaseActions.getFilteredRecordHandler('ConnectivityDomain', 
                            {'domainType':'FABRIC_SITE'} );
                        domains = domains.filter(fabDom => {
                            return JSON.stringify(fabDom.virtualNetwork).includes(vn.id);
                        })
                        isFound = domains.length>0;
                        var deviceList = domains[0].deviceInfo;
                        deviceList = deviceList.map(e => e.idRef);
                        var deviceInfos = SimLokiDatabaseActions.getFilteredRecordHandler('DeviceInfo', {'id':{'$in':deviceList}});
                        //collect some data required to fill in anchored template
                        result.borderDevIds =[], result.cpDevIds = []
                        deviceInfos.forEach(devInfo => {
                            if(devInfo.roles.indexOf('MAPSERVER') > -1) result.cpDevIds.push(devInfo.networkDeviceId);
                            if(devInfo.roles.indexOf('BORDERNODE') > -1) result.borderDevIds.push(devInfo.networkDeviceId);
                        });
                        result.siteId = domains[0].siteId;
                        result.siteName = (SimLokiDatabaseActions.getFilteredRecordHandler('site', {'id': result.siteId}))[0].name;
                        result.vnId = vn.id;
                        return;//exits from for loop
                    }
                });
            }
        }
        return result;
    }

  };
});
