define([
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/data-generation/services/SimDesignData',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/data-generation/services/SimLanAutomationData',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/database/SimLokiDatabaseActions',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/data-generation/services/SimWirelessData',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/data-generation/configuration/SimTaskDataGenerator',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/data-generation/services/swim/SimSwimData',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/udn/udnWlanInfo',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/utilities/UtilityFunctions',
], function (SimDesignData, SimLanAutomationData, SimLokiDatabaseActions, SimWirelessData, SimTaskDataGenerator, SimSwimData, udnWlanInfo,
             UtilityFunctions) {

    function getIPPoolValue(serverDetails) {
        var ipPoolArray = [], i;
        for(i=0;i< serverDetails.length;i++) {
            var key = serverDetails[i].key;
            if(key.indexOf("ip.pool")>=0)  {
                ipPoolArray.push(serverDetails[i]);
            }
        }
        return ipPoolArray;
    }

    return {
        init: function() {
        },

        doProcessRestRequest: function(urlAction){
            /* console.log(urlAction.url);
            console.log(urlAction); */
            var data;
            switch(urlAction.method) {
                case 'GET':
                    data = processGetRequest(urlAction);
                    break;

                case 'POST':
                    data = processPostRequest(urlAction);
                    break;

                case 'DELETE':
                    data = processDeleteRequest(urlAction);
                    break;

                default:
                    break;
            }

            return data;
        }
    };

    function processGetRequest(urlAction){

        // wireless
        if(urlAction.service.indexOf('wlan')>=0 && urlAction.filter['key']) {
           // var  jObj = SimLokiDatabaseActions.getFilteredRecordHandler(urlAction.service[1], {'key' : urlAction.filter['key']});
           var  jObj = SimLokiDatabaseActions.filterByRegx(urlAction.service[1],'key' , urlAction.filter['key']);
            var data = jObj && jObj.length ? JSON.parse(JSON.stringify(jObj)) : [];
            if(urlAction.filter.key=="wlan.info.*" && urlAction.filter.groupUuids && data.length>0) {
                let groupUuids = urlAction.filter.groupUuids.split(',');
                let resultData = [];
                data.forEach(entry1 => {
                    groupUuids.forEach(entry2 => {
                        let newEntry = JSON.parse(JSON.stringify(entry1));
                        newEntry.instanceUuid = UtilityFunctions.generate_uuid();
                        newEntry.groupUuid = entry2;
                        resultData.push(newEntry);
                    });
                });
                return resultData;
            }
            if(urlAction.filter.key=="sitetag.info" && urlAction.filter.groupUuids && data.length>0) {
                let groupUuids = urlAction.filter.groupUuids.split(',');
                data = data.filter(e => groupUuids.indexOf(e.groupUuid)>-1 );
            }
            var newData = [];
            data.forEach(item => {
                if(urlAction.service.indexOf('-1') > 0) {
                    item.groupUuid =  "-1";
                    item.inheritedGroupName = "";
                    item.inheritedGroupUuid = "";
                    item.instanceUuid = UtilityFunctions.generate_uuid();                                              
                } else {
                    item.inheritedGroupName = "Global";
                    item.inheritedGroupUuid = "-1"; 
                }
                newData.push(item)                
            })
            return newData
        }

        if(urlAction.service.indexOf('wlan')>=0) {
         //var jObj = SimLokiDatabaseActions.getAll(urlAction.service[1]);
         var jObj = SimLokiDatabaseActions.getAll("wlan");
         var data = jObj && jObj.length ? jObj : [];

         return data;
        }
        if(urlAction.service.indexOf('sensor')>=0) {
            var jObj = SimLokiDatabaseActions.getFilteredRecordHandler("wlan", {'namespace':'sensor'});
            var data = jObj && jObj.length ? jObj : [];
            return data;
        }

        if(urlAction.service.indexOf('global')>=0 && urlAction.service.indexOf('undefined')>=0) {
             var data = new Object();
             data.statusCode =400;
             return data;
        }

        if(urlAction.service.indexOf('global')>=0 && urlAction.service.indexOf('key')>=0) {
            var data =SimLokiDatabaseActions.getAll('common-setting-key');
            return data;
        } else if(urlAction.url.indexOf('global')>=0) {
            var id ="";
            if(urlAction.service[2]!=undefined) {
                id = urlAction.service[2];// Global group id
            } else {
                id = urlAction.action.id;
             }
             var record = SimLokiDatabaseActions.getFilteredRecordHandler('servers', {'groupUuid': id});
             // need to get all the ippool types
             if (urlAction.filter['key'] && urlAction.filter['key'].indexOf("ip.pool")>=0) {
                 return getIPPoolValue(record[0].serverDetails);
             }
             else if(urlAction.filter['key'] )  {
                 for(j=0;j< record[0].serverDetails.length;j++) {
                     if(record[0].serverDetails[j].key==urlAction.filter['key'])  {
                         // workaround for sp info cases. expecting array.
                         if (urlAction.filter['key']=="sp.info"  || urlAction.filter['key'] =="interface.info"|| urlAction.filter['key'] =="cmx.server"  || urlAction.filter['key'] == "eta.collector" || urlAction.filter['key'] == "timezone.site"
                                || urlAction.filter['key']=='credential.cli' || urlAction.filter['key']=='credential.snmp_v3' || urlAction.filter['key']=='credential.snmp_v2_read') {
                             var tempArray =[];
                             tempArray.push(record[0].serverDetails[j]);
                             return tempArray;
                         }
                         return record[0].serverDetails[j];
                      }
                 }
                 return [];
             }
             return record[0].serverDetails;
         } else if(urlAction.service.indexOf('psn')>=0) {
            var data =SimLokiDatabaseActions.getAll('psn-server');
            return data;
         } else if(urlAction.service.indexOf('aaa')>=0) {
            var data =SimLokiDatabaseActions.getAll('aaa');
            return data;
        } else if(urlAction.service.indexOf('swim')>=0) {
            var mdfId;
            if((urlAction.filter['key'] && ((urlAction.filter['key']).split('.'))[2])) {
                mdfId = ((urlAction.filter['key']).split('.'))[2];
                if(mdfId) {
                    var goldenImages = SimSwimData.getGoldenImagesForMdf(mdfId);
                    return goldenImages;
                }
            } else {
                return [];
            }
        } else if(urlAction.service.indexOf('lan') > -1 && urlAction.action.id) {
            var siteId = urlAction.action.id;
            var records = SimLokiDatabaseActions.getFilteredRecordHandler('commonsetting-lan', {'groupUuid':siteId});
            return records;
        }
    }

    function processPostRequest(urlAction){

        if(urlAction.service.indexOf('wlan')>=0 || urlAction.service.indexOf('sensor')>=0)  {
            var resultObj = SimTaskDataGenerator.createTask('SPFService');
            //Fix for sensor setting from Network Settings
            //SimWirelessData.wlanCUD("post",urlAction.service[1],urlAction.restPayload);
            SimWirelessData.wlanCUD("post","wlan",urlAction.restPayload);
            SimTaskDataGenerator.updateTaskCompletion(resultObj.taskId, {isError: false });
            resultObj.statusCode = 202;
            return resultObj;
        }

        if(urlAction.service.indexOf('lan')>=0)  {
            return SimLanAutomationData.createLanAutomation(urlAction);
        }

        if(urlAction.url.indexOf('global')>=0) {
            var data =SimDesignData.updateServers(urlAction);
            return data;
        }
    }
    /**
     * Added to handle delete wireless in Design-> network settings -> Wireless
     */
    function processDeleteRequest(urlAction){
        if(urlAction.service.indexOf('wlan')>=0)  {
            var resultObj = SimTaskDataGenerator.createTask('SPFService');
            
            SimWirelessData.wlanCUD("delete",'wlan',urlAction.service);
            SimTaskDataGenerator.updateTaskCompletion(resultObj.taskId, {isError: false });
            
            return resultObj;
            console.log("Delete handled")
        }
    }

});
