define({
  "Template":
    {
  "issueCount": 0,
  "testType": "ping",
  "testResultDetails": {
    "passCount": 1700,
    "failureCount": 2,
    "slowCount": 1
  },
  "worstPerformingSite": {
    "tagName": "Global/North America/USA/California/San Jose/SJC01/Flr-SJC1-1",
    "count": 1703
  },
  "KpiPerformanceInterval": [
    {
      "kpi": "responseTimeMillis",
      "performanceThreshold": 2000,
      "averagePerformanceValue": 0.0,
      "minPerformanceList": [
        {
      "intervalIndex": "interval-0",
      "value": 2.0,
      "endTime": 1527332400000
    },
    {
      "intervalIndex": "interval-1",
      "value": 2.0,
      "endTime": 1527328800000
    },
    {
      "intervalIndex": "interval-2",
      "value": 4.0,
      "endTime": 1527325200000
    },
    {
      "intervalIndex": "interval-3",
      "value": 6.0,
      "endTime": 1527321600000
    },
    {
      "intervalIndex": "interval-4",
      "value": 2.0,
      "endTime": 1527318000000
    },
    {
      "intervalIndex": "interval-5",
      "value": 1.0,
      "endTime": 1527314400000
    },
    {
      "intervalIndex": "interval-6",
      "value": 1.0,
      "endTime": 1527310800000
    },
    {
      "intervalIndex": "interval-7",
      "value": 2.0,
      "endTime": 1527307200000
    },
    {
      "intervalIndex": "interval-8",
      "value": 2.0,
      "endTime": 1527303600000
    },
    {
      "intervalIndex": "interval-9",
      "value": 1.0,
      "endTime": 1527300000000
    },
    {
      "intervalIndex": "interval-10",
      "value": 1.0,
      "endTime": 1527296400000
    },
    {
      "intervalIndex": "interval-11",
      "value": 2.0,
      "endTime": 1527292800000
    },
    {
      "intervalIndex": "interval-12",
      "value": 2.0,
      "endTime": 1527289200000
    },
    {
      "intervalIndex": "interval-13",
      "value": 2.0,
      "endTime": 1527285600000
    },
    {
      "intervalIndex": "interval-14",
      "value": 1.0,
      "endTime": 1527282000000
    },
    {
      "intervalIndex": "interval-15",
      "value": 1.0,
      "endTime": 1527278400000
    },
    {
      "intervalIndex": "interval-16",
      "value": 1.0,
      "endTime": 1527274800000
    },
    {
      "intervalIndex": "interval-17",
      "value": 1.0,
      "endTime": 1527271200000
    },
    {
      "intervalIndex": "interval-18",
      "value": 3.0,
      "endTime": 1527267600000
    },
    {
      "intervalIndex": "interval-19",
      "value": 1.0,
      "endTime": 1527264000000
    },
    {
      "intervalIndex": "interval-20",
      "value": 1.0,
      "endTime": 1527260400000
    },
    {
      "intervalIndex": "interval-21",
      "value": 1.0,
      "endTime": 1527256800000
    },
    {
      "intervalIndex": "interval-22",
      "value": 1.0,
      "endTime": 1527253200000
    },
    {
      "intervalIndex": "interval-23",
      "value": 1.0,
      "endTime": 1527249600000
    },
    {
      "intervalIndex": "interval-24",
      "value": 2.0,
      "endTime": 1527246000000
    },
    {
      "intervalIndex": "interval-25",
      "value": 2.0,
      "endTime": 1527242400000
    },
    {
      "intervalIndex": "interval-26",
      "value": 1.0,
      "endTime": 1527238800000
    },
    {
      "intervalIndex": "interval-27",
      "value": 1.0,
      "endTime": 1527235200000
    },
    {
      "intervalIndex": "interval-28",
      "value": 2.0,
      "endTime": 1527231600000
    },
    {
      "intervalIndex": "interval-29",
      "value": 2.0,
      "endTime": 1527228000000
    },
    {
      "intervalIndex": "interval-30",
      "value": 2.0,
      "endTime": 1527224400000
    },
    {
      "intervalIndex": "interval-31",
      "value": 1.0,
      "endTime": 1527220800000
    },
    {
      "intervalIndex": "interval-32",
      "value": 1.0,
      "endTime": 1527217200000
    },
    {
      "intervalIndex": "interval-33",
      "value": 2.0,
      "endTime": 1527213600000
    },
    {
      "intervalIndex": "interval-34",
      "value": 3.0,
      "endTime": 1527210000000
    },
    {
      "intervalIndex": "interval-35",
      "value": 3.0,
      "endTime": 1527206400000
    },
    {
      "intervalIndex": "interval-36",
      "value": 2.0,
      "endTime": 1527202800000
    },
    {
      "intervalIndex": "interval-37",
      "value": 2.0,
      "endTime": 1527199200000
    },
    {
      "intervalIndex": "interval-38",
      "value": 1.0,
      "endTime": 1527195600000
    },
    {
      "intervalIndex": "interval-39",
      "value": 4.0,
      "endTime": 1527192000000
    },
    {
      "intervalIndex": "interval-40",
      "value": 1.0,
      "endTime": 1527188400000
    },
    {
      "intervalIndex": "interval-41",
      "value": 1.0,
      "endTime": 1527184800000
    },
    {
      "intervalIndex": "interval-42",
      "value": 1.0,
      "endTime": 1527181200000
    },
    {
      "intervalIndex": "interval-43",
      "value": 1.0,
      "endTime": 1527177600000
    },
    {
      "intervalIndex": "interval-44",
      "value": 1.0,
      "endTime": 1527174000000
    },
    {
      "intervalIndex": "interval-45",
      "value": 1.0,
      "endTime": 1527170400000
    },
    {
      "intervalIndex": "interval-46",
      "value": 1.0,
      "endTime": 1527166800000
    },
    {
      "intervalIndex": "interval-47",
      "value": 3.0,
      "endTime": 1527163200000
    },
    {
      "intervalIndex": "interval-48",
      "value": 3.0,
      "endTime": 1527159600000
    },
    {
      "intervalIndex": "interval-49",
      "value": 1.0,
      "endTime": 1527156000000
    },
    {
      "intervalIndex": "interval-50",
      "value": 1.0,
      "endTime": 1527152400000
    },
    {
      "intervalIndex": "interval-51",
      "value": 1.0,
      "endTime": 1527148800000
    },
    {
      "intervalIndex": "interval-52",
      "value": 2.0,
      "endTime": 1527145200000
    },
    {
      "intervalIndex": "interval-53",
      "value": 1.0,
      "endTime": 1527141600000
    },
    {
      "intervalIndex": "interval-54",
      "value": 1.0,
      "endTime": 1527138000000
    },
    {
      "intervalIndex": "interval-55",
      "value": 2.0,
      "endTime": 1527134400000
    },
    {
      "intervalIndex": "interval-56",
      "value": 2.0,
      "endTime": 1527130800000
    },
    {
      "intervalIndex": "interval-57",
      "value": 1.0,
      "endTime": 1527127200000
    },
    {
      "intervalIndex": "interval-58",
      "value": 1.0,
      "endTime": 1527123600000
    },
    {
      "intervalIndex": "interval-59",
      "value": 2.0,
      "endTime": 1527120000000
    },
    {
      "intervalIndex": "interval-60",
      "value": 2.0,
      "endTime": 1527116400000
    },
    {
      "intervalIndex": "interval-61",
      "value": 1.0,
      "endTime": 1527112800000
    },
    {
      "intervalIndex": "interval-62",
      "value": 2.0,
      "endTime": 1527109200000
    },
    {
      "intervalIndex": "interval-63",
      "value": 1.0,
      "endTime": 1527105600000
    },
    {
      "intervalIndex": "interval-64",
      "value": 2.0,
      "endTime": 1527102000000
    },
    {
      "intervalIndex": "interval-65",
      "value": 1.0,
      "endTime": 1527098400000
    },
    {
      "intervalIndex": "interval-66",
      "value": 1.0,
      "endTime": 1527094800000
    },
    {
      "intervalIndex": "interval-67",
      "value": 1.0,
      "endTime": 1527091200000
    },
    {
      "intervalIndex": "interval-68",
      "value": 1.0,
      "endTime": 1527087600000
    },
    {
      "intervalIndex": "interval-69",
      "value": 1.0,
      "endTime": 1527084000000
    },
    {
      "intervalIndex": "interval-70",
      "value": 2.0,
      "endTime": 1527080400000
    },
    {
      "intervalIndex": "interval-71",
      "value": 2.0,
      "endTime": 1527076800000
    },
    {
      "intervalIndex": "interval-72",
      "value": 1.0,
      "endTime": 1527073200000
    },
    {
      "intervalIndex": "interval-73",
      "value": 1.0,
      "endTime": 1527069600000
    },
    {
      "intervalIndex": "interval-74",
      "value": 1.0,
      "endTime": 1527066000000
    },
    {
      "intervalIndex": "interval-75",
      "value": 1.0,
      "endTime": 1527062400000
    },
    {
      "intervalIndex": "interval-76",
      "value": 1.0,
      "endTime": 1527058800000
    },
    {
      "intervalIndex": "interval-77",
      "value": 2.0,
      "endTime": 1527055200000
    },
    {
      "intervalIndex": "interval-78",
      "value": 2.0,
      "endTime": 1527051600000
    },
    {
      "intervalIndex": "interval-79",
      "value": 1.0,
      "endTime": 1527048000000
    },
    {
      "intervalIndex": "interval-80",
      "value": 1.0,
      "endTime": 1527044400000
    },
    {
      "intervalIndex": "interval-81",
      "value": 1.0,
      "endTime": 1527040800000
    },
    {
      "intervalIndex": "interval-82",
      "value": 1.0,
      "endTime": 1527037200000
    },
    {
      "intervalIndex": "interval-83",
      "value": 2.0,
      "endTime": 1527033600000
    },
    {
      "intervalIndex": "interval-84",
      "value": 1.0,
      "endTime": 1527030000000
    },
    {
      "intervalIndex": "interval-85",
      "value": 2.0,
      "endTime": 1527026400000
    },
    {
      "intervalIndex": "interval-86",
      "value": 3.0,
      "endTime": 1527022800000
    },
    {
      "intervalIndex": "interval-87",
      "value": 4.0,
      "endTime": 1527019200000
    },
    {
      "intervalIndex": "interval-88",
      "value": 1.0,
      "endTime": 1527015600000
    },
    {
      "intervalIndex": "interval-89",
      "value": 1.0,
      "endTime": 1527012000000
    },
    {
      "intervalIndex": "interval-90",
      "value": 1.0,
      "endTime": 1527008400000
    },
    {
      "intervalIndex": "interval-91",
      "value": 1.0,
      "endTime": 1527004800000
    },
    {
      "intervalIndex": "interval-92",
      "value": 2.0,
      "endTime": 1527001200000
    },
    {
      "intervalIndex": "interval-93",
      "value": 1.0,
      "endTime": 1526997600000
    },
    {
      "intervalIndex": "interval-94",
      "value": 1.0,
      "endTime": 1526994000000
    },
    {
      "intervalIndex": "interval-95",
      "value": 1.0,
      "endTime": 1526990400000
    },
    {
      "intervalIndex": "interval-96",
      "value": 1.0,
      "endTime": 1526986800000
    },
    {
      "intervalIndex": "interval-97",
      "value": 1.0,
      "endTime": 1526983200000
    },
    {
      "intervalIndex": "interval-98",
      "value": 1.0,
      "endTime": 1526979600000
    },
    {
      "intervalIndex": "interval-99",
      "value": 1.0,
      "endTime": 1526976000000
    },
    {
      "intervalIndex": "interval-100",
      "value": 6.0,
      "endTime": 1526972400000
    },
    {
      "intervalIndex": "interval-101",
      "value": 1.0,
      "endTime": 1526968800000
    },
    {
      "intervalIndex": "interval-102",
      "value": 1.0,
      "endTime": 1526965200000
    },
    {
      "intervalIndex": "interval-103",
      "value": 1.0,
      "endTime": 1526961600000
    },
    {
      "intervalIndex": "interval-104",
      "value": 1.0,
      "endTime": 1526958000000
    },
    {
      "intervalIndex": "interval-105",
      "value": 1.0,
      "endTime": 1526954400000
    },
    {
      "intervalIndex": "interval-106",
      "value": 1.0,
      "endTime": 1526950800000
    },
    {
      "intervalIndex": "interval-107",
      "value": 2.0,
      "endTime": 1526947200000
    },
    {
      "intervalIndex": "interval-108",
      "value": 1.0,
      "endTime": 1526943600000
    },
    {
      "intervalIndex": "interval-109",
      "value": 1.0,
      "endTime": 1526940000000
    },
    {
      "intervalIndex": "interval-110",
      "value": 2.0,
      "endTime": 1526936400000
    },
    {
      "intervalIndex": "interval-111",
      "value": 2.0,
      "endTime": 1526932800000
    },
    {
      "intervalIndex": "interval-112",
      "value": 1.0,
      "endTime": 1526929200000
    },
    {
      "intervalIndex": "interval-113",
      "value": 1.0,
      "endTime": 1526925600000
    },
    {
      "intervalIndex": "interval-114",
      "value": 1.0,
      "endTime": 1526922000000
    },
    {
      "intervalIndex": "interval-115",
      "value": 3.0,
      "endTime": 1526918400000
    },
    {
      "intervalIndex": "interval-116",
      "value": 2.0,
      "endTime": 1526914800000
    },
    {
      "intervalIndex": "interval-117",
      "value": 1.0,
      "endTime": 1526911200000
    },
    {
      "intervalIndex": "interval-118",
      "value": 1.0,
      "endTime": 1526907600000
    },
    {
      "intervalIndex": "interval-119",
      "value": 1.0,
      "endTime": 1526904000000
    },
    {
      "intervalIndex": "interval-120",
      "value": 1.0,
      "endTime": 1526900400000
    },
    {
      "intervalIndex": "interval-121",
      "value": 1.0,
      "endTime": 1526896800000
    },
    {
      "intervalIndex": "interval-122",
      "value": 1.0,
      "endTime": 1526893200000
    },
    {
      "intervalIndex": "interval-123",
      "value": 1.0,
      "endTime": 1526889600000
    },
    {
      "intervalIndex": "interval-124",
      "value": 1.0,
      "endTime": 1526886000000
    },
    {
      "intervalIndex": "interval-125",
      "value": 2.0,
      "endTime": 1526882400000
    },
    {
      "intervalIndex": "interval-126",
      "value": 1.0,
      "endTime": 1526878800000
    },
    {
      "intervalIndex": "interval-127",
      "value": 1.0,
      "endTime": 1526875200000
    },
    {
      "intervalIndex": "interval-128",
      "value": 1.0,
      "endTime": 1526871600000
    },
    {
      "intervalIndex": "interval-129",
      "value": 2.0,
      "endTime": 1526868000000
    },
    {
      "intervalIndex": "interval-130",
      "value": 1.0,
      "endTime": 1526864400000
    },
    {
      "intervalIndex": "interval-131",
      "value": 1.0,
      "endTime": 1526860800000
    },
    {
      "intervalIndex": "interval-132",
      "value": 1.0,
      "endTime": 1526857200000
    },
    {
      "intervalIndex": "interval-133",
      "value": 1.0,
      "endTime": 1526853600000
    },
    {
      "intervalIndex": "interval-134",
      "value": 1.0,
      "endTime": 1526850000000
    },
    {
      "intervalIndex": "interval-135",
      "value": 2.0,
      "endTime": 1526846400000
    },
    {
      "intervalIndex": "interval-136",
      "value": 1.0,
      "endTime": 1526842800000
    },
    {
      "intervalIndex": "interval-137",
      "value": 1.0,
      "endTime": 1526839200000
    },
    {
      "intervalIndex": "interval-138",
      "value": 2.0,
      "endTime": 1526835600000
    },
    {
      "intervalIndex": "interval-139",
      "value": 1.0,
      "endTime": 1526832000000
    },
    {
      "intervalIndex": "interval-140",
      "value": 2.0,
      "endTime": 1526828400000
    },
    {
      "intervalIndex": "interval-141",
      "value": 2.0,
      "endTime": 1526824800000
    },
    {
      "intervalIndex": "interval-142",
      "value": 1.0,
      "endTime": 1526821200000
    },
    {
      "intervalIndex": "interval-143",
      "value": 2.0,
      "endTime": 1526817600000
    },
    {
      "intervalIndex": "interval-144",
      "value": 2.0,
      "endTime": 1526814000000
    },
    {
      "intervalIndex": "interval-145",
      "value": 1.0,
      "endTime": 1526810400000
    },
    {
      "intervalIndex": "interval-146",
      "value": 1.0,
      "endTime": 1526806800000
    },
    {
      "intervalIndex": "interval-147",
      "value": 1.0,
      "endTime": 1526803200000
    },
    {
      "intervalIndex": "interval-148",
      "value": 1.0,
      "endTime": 1526799600000
    },
    {
      "intervalIndex": "interval-149",
      "value": 1.0,
      "endTime": 1526796000000
    },
    {
      "intervalIndex": "interval-150",
      "value": 1.0,
      "endTime": 1526792400000
    },
    {
      "intervalIndex": "interval-151",
      "value": 1.0,
      "endTime": 1526788800000
    },
    {
      "intervalIndex": "interval-152",
      "value": 1.0,
      "endTime": 1526785200000
    },
    {
      "intervalIndex": "interval-153",
      "value": 1.0,
      "endTime": 1526781600000
    },
    {
      "intervalIndex": "interval-154",
      "value": 1.0,
      "endTime": 1526778000000
    },
    {
      "intervalIndex": "interval-155",
      "value": 1.0,
      "endTime": 1526774400000
    },
    {
      "intervalIndex": "interval-156",
      "value": 1.0,
      "endTime": 1526770800000
    },
    {
      "intervalIndex": "interval-157",
      "value": 1.0,
      "endTime": 1526767200000
    },
    {
      "intervalIndex": "interval-158",
      "value": 1.0,
      "endTime": 1526763600000
    },
    {
      "intervalIndex": "interval-159",
      "value": 1.0,
      "endTime": 1526760000000
    },
    {
      "intervalIndex": "interval-160",
      "value": 1.0,
      "endTime": 1526756400000
    },
    {
      "intervalIndex": "interval-161",
      "value": 1.0,
      "endTime": 1526752800000
    },
    {
      "intervalIndex": "interval-162",
      "value": 1.0,
      "endTime": 1526749200000
    },
    {
      "intervalIndex": "interval-163",
      "value": 1.0,
      "endTime": 1526745600000
    },
    {
      "intervalIndex": "interval-164",
      "value": 1.0,
      "endTime": 1526742000000
    },
    {
      "intervalIndex": "interval-165",
      "value": 1.0,
      "endTime": 1526738400000
    },
    {
      "intervalIndex": "interval-166",
      "value": 1.0,
      "endTime": 1526734800000
    },
    {
      "intervalIndex": "interval-167",
      "value": 1.0,
      "endTime": 1526731200000
    },
    {
      "intervalIndex": "interval-168",
      "value": 2.0,
      "endTime": 1526727600000
    }
      ],
      "averagePerformanceList": [
        {
      "intervalIndex": "interval-0",
      "value": 7.166666666666667,
      "endTime": 1527332400000
    },
    {
      "intervalIndex": "interval-1",
      "value": 14.222222222222221,
      "endTime": 1527328800000
    },
    {
      "intervalIndex": "interval-2",
      "value": 20.7,
      "endTime": 1527325200000
    },
    {
      "intervalIndex": "interval-3",
      "value": 16.5,
      "endTime": 1527321600000
    },
    {
      "intervalIndex": "interval-4",
      "value": 16.8,
      "endTime": 1527318000000
    },
    {
      "intervalIndex": "interval-5",
      "value": 18.2,
      "endTime": 1527314400000
    },
    {
      "intervalIndex": "interval-6",
      "value": 8.5,
      "endTime": 1527310800000
    },
    {
      "intervalIndex": "interval-7",
      "value": 18.5,
      "endTime": 1527307200000
    },
    {
      "intervalIndex": "interval-8",
      "value": 19.6,
      "endTime": 1527303600000
    },
    {
      "intervalIndex": "interval-9",
      "value": 13.272727272727273,
      "endTime": 1527300000000
    },
    {
      "intervalIndex": "interval-10",
      "value": 17.8,
      "endTime": 1527296400000
    },
    {
      "intervalIndex": "interval-11",
      "value": 17.0,
      "endTime": 1527292800000
    },
    {
      "intervalIndex": "interval-12",
      "value": 8.2,
      "endTime": 1527289200000
    },
    {
      "intervalIndex": "interval-13",
      "value": 8.1,
      "endTime": 1527285600000
    },
    {
      "intervalIndex": "interval-14",
      "value": 16.636363636363637,
      "endTime": 1527282000000
    },
    {
      "intervalIndex": "interval-15",
      "value": 12.2,
      "endTime": 1527278400000
    },
    {
      "intervalIndex": "interval-16",
      "value": 11.2,
      "endTime": 1527274800000
    },
    {
      "intervalIndex": "interval-17",
      "value": 31.6,
      "endTime": 1527271200000
    },
    {
      "intervalIndex": "interval-18",
      "value": 11.6,
      "endTime": 1527267600000
    },
    {
      "intervalIndex": "interval-19",
      "value": 27.2,
      "endTime": 1527264000000
    },
    {
      "intervalIndex": "interval-20",
      "value": 12.454545454545455,
      "endTime": 1527260400000
    },
    {
      "intervalIndex": "interval-21",
      "value": 14.0,
      "endTime": 1527256800000
    },
    {
      "intervalIndex": "interval-22",
      "value": 10.6,
      "endTime": 1527253200000
    },
    {
      "intervalIndex": "interval-23",
      "value": 9.3,
      "endTime": 1527249600000
    },
    {
      "intervalIndex": "interval-24",
      "value": 27.1,
      "endTime": 1527246000000
    },
    {
      "intervalIndex": "interval-25",
      "value": 9.3,
      "endTime": 1527242400000
    },
    {
      "intervalIndex": "interval-26",
      "value": 12.7,
      "endTime": 1527238800000
    },
    {
      "intervalIndex": "interval-27",
      "value": 14.3,
      "endTime": 1527235200000
    },
    {
      "intervalIndex": "interval-28",
      "value": 14.2,
      "endTime": 1527231600000
    },
    {
      "intervalIndex": "interval-29",
      "value": 15.0,
      "endTime": 1527228000000
    },
    {
      "intervalIndex": "interval-30",
      "value": 20.25,
      "endTime": 1527224400000
    },
    {
      "intervalIndex": "interval-31",
      "value": 10.9,
      "endTime": 1527220800000
    },
    {
      "intervalIndex": "interval-32",
      "value": 43.8,
      "endTime": 1527217200000
    },
    {
      "intervalIndex": "interval-33",
      "value": 12.8,
      "endTime": 1527213600000
    },
    {
      "intervalIndex": "interval-34",
      "value": 14.4,
      "endTime": 1527210000000
    },
    {
      "intervalIndex": "interval-35",
      "value": 16.6,
      "endTime": 1527206400000
    },
    {
      "intervalIndex": "interval-36",
      "value": 13.6,
      "endTime": 1527202800000
    },
    {
      "intervalIndex": "interval-37",
      "value": 18.53846153846154,
      "endTime": 1527199200000
    },
    {
      "intervalIndex": "interval-38",
      "value": 9.444444444444445,
      "endTime": 1527195600000
    },
    {
      "intervalIndex": "interval-39",
      "value": 9.5,
      "endTime": 1527192000000
    },
    {
      "intervalIndex": "interval-40",
      "value": 7.466666666666667,
      "endTime": 1527188400000
    },
    {
      "intervalIndex": "interval-41",
      "value": 7.75,
      "endTime": 1527184800000
    },
    {
      "intervalIndex": "interval-42",
      "value": 11.6,
      "endTime": 1527181200000
    },
    {
      "intervalIndex": "interval-43",
      "value": 10.4,
      "endTime": 1527177600000
    },
    {
      "intervalIndex": "interval-44",
      "value": 7.1,
      "endTime": 1527174000000
    },
    {
      "intervalIndex": "interval-45",
      "value": 12.4,
      "endTime": 1527170400000
    },
    {
      "intervalIndex": "interval-46",
      "value": 17.181818181818183,
      "endTime": 1527166800000
    },
    {
      "intervalIndex": "interval-47",
      "value": 13.7,
      "endTime": 1527163200000
    },
    {
      "intervalIndex": "interval-48",
      "value": 11.5,
      "endTime": 1527159600000
    },
    {
      "intervalIndex": "interval-49",
      "value": 11.1,
      "endTime": 1527156000000
    },
    {
      "intervalIndex": "interval-50",
      "value": 6.0,
      "endTime": 1527152400000
    },
    {
      "intervalIndex": "interval-51",
      "value": 6.5,
      "endTime": 1527148800000
    },
    {
      "intervalIndex": "interval-52",
      "value": 13.9,
      "endTime": 1527145200000
    },
    {
      "intervalIndex": "interval-53",
      "value": 4.5,
      "endTime": 1527141600000
    },
    {
      "intervalIndex": "interval-54",
      "value": 4.5,
      "endTime": 1527138000000
    },
    {
      "intervalIndex": "interval-55",
      "value": 11.777777777777779,
      "endTime": 1527134400000
    },
    {
      "intervalIndex": "interval-56",
      "value": 14.6,
      "endTime": 1527130800000
    },
    {
      "intervalIndex": "interval-57",
      "value": 12.636363636363637,
      "endTime": 1527127200000
    },
    {
      "intervalIndex": "interval-58",
      "value": 10.6,
      "endTime": 1527123600000
    },
    {
      "intervalIndex": "interval-59",
      "value": 9.0,
      "endTime": 1527120000000
    },
    {
      "intervalIndex": "interval-60",
      "value": 9.2,
      "endTime": 1527116400000
    },
    {
      "intervalIndex": "interval-61",
      "value": 7.6,
      "endTime": 1527112800000
    },
    {
      "intervalIndex": "interval-62",
      "value": 11.9,
      "endTime": 1527109200000
    },
    {
      "intervalIndex": "interval-63",
      "value": 8.1,
      "endTime": 1527105600000
    },
    {
      "intervalIndex": "interval-64",
      "value": 22.8,
      "endTime": 1527102000000
    },
    {
      "intervalIndex": "interval-65",
      "value": 11.9,
      "endTime": 1527098400000
    },
    {
      "intervalIndex": "interval-66",
      "value": 15.5,
      "endTime": 1527094800000
    },
    {
      "intervalIndex": "interval-67",
      "value": 13.1,
      "endTime": 1527091200000
    },
    {
      "intervalIndex": "interval-68",
      "value": 11.8,
      "endTime": 1527087600000
    },
    {
      "intervalIndex": "interval-69",
      "value": 12.636363636363637,
      "endTime": 1527084000000
    },
    {
      "intervalIndex": "interval-70",
      "value": 10.3,
      "endTime": 1527080400000
    },
    {
      "intervalIndex": "interval-71",
      "value": 11.4,
      "endTime": 1527076800000
    },
    {
      "intervalIndex": "interval-72",
      "value": 11.545454545454545,
      "endTime": 1527073200000
    },
    {
      "intervalIndex": "interval-73",
      "value": 9.2,
      "endTime": 1527069600000
    },
    {
      "intervalIndex": "interval-74",
      "value": 8.1,
      "endTime": 1527066000000
    },
    {
      "intervalIndex": "interval-75",
      "value": 6.5,
      "endTime": 1527062400000
    },
    {
      "intervalIndex": "interval-76",
      "value": 9.636363636363637,
      "endTime": 1527058800000
    },
    {
      "intervalIndex": "interval-77",
      "value": 14.4,
      "endTime": 1527055200000
    },
    {
      "intervalIndex": "interval-78",
      "value": 11.6,
      "endTime": 1527051600000
    },
    {
      "intervalIndex": "interval-79",
      "value": 12.0,
      "endTime": 1527048000000
    },
    {
      "intervalIndex": "interval-80",
      "value": 11.636363636363637,
      "endTime": 1527044400000
    },
    {
      "intervalIndex": "interval-81",
      "value": 10.0,
      "endTime": 1527040800000
    },
    {
      "intervalIndex": "interval-82",
      "value": 9.6,
      "endTime": 1527037200000
    },
    {
      "intervalIndex": "interval-83",
      "value": 7.6,
      "endTime": 1527033600000
    },
    {
      "intervalIndex": "interval-84",
      "value": 9.2,
      "endTime": 1527030000000
    },
    {
      "intervalIndex": "interval-85",
      "value": 12.4,
      "endTime": 1527026400000
    },
    {
      "intervalIndex": "interval-86",
      "value": 18.8,
      "endTime": 1527022800000
    },
    {
      "intervalIndex": "interval-87",
      "value": 25.555555555555557,
      "endTime": 1527019200000
    },
    {
      "intervalIndex": "interval-88",
      "value": 12.9,
      "endTime": 1527015600000
    },
    {
      "intervalIndex": "interval-89",
      "value": 16.545454545454547,
      "endTime": 1527012000000
    },
    {
      "intervalIndex": "interval-90",
      "value": 12.4,
      "endTime": 1527008400000
    },
    {
      "intervalIndex": "interval-91",
      "value": 14.0,
      "endTime": 1527004800000
    },
    {
      "intervalIndex": "interval-92",
      "value": 16.8,
      "endTime": 1527001200000
    },
    {
      "intervalIndex": "interval-93",
      "value": 6.3,
      "endTime": 1526997600000
    },
    {
      "intervalIndex": "interval-94",
      "value": 13.181818181818182,
      "endTime": 1526994000000
    },
    {
      "intervalIndex": "interval-95",
      "value": 11.6,
      "endTime": 1526990400000
    },
    {
      "intervalIndex": "interval-96",
      "value": 13.454545454545455,
      "endTime": 1526986800000
    },
    {
      "intervalIndex": "interval-97",
      "value": 11.555555555555555,
      "endTime": 1526983200000
    },
    {
      "intervalIndex": "interval-98",
      "value": 6.7272727272727275,
      "endTime": 1526979600000
    },
    {
      "intervalIndex": "interval-99",
      "value": 22.0,
      "endTime": 1526976000000
    },
    {
      "intervalIndex": "interval-100",
      "value": 17.8,
      "endTime": 1526972400000
    },
    {
      "intervalIndex": "interval-101",
      "value": 11.9,
      "endTime": 1526968800000
    },
    {
      "intervalIndex": "interval-102",
      "value": 11.636363636363637,
      "endTime": 1526965200000
    },
    {
      "intervalIndex": "interval-103",
      "value": 12.8,
      "endTime": 1526961600000
    },
    {
      "intervalIndex": "interval-104",
      "value": 13.636363636363637,
      "endTime": 1526958000000
    },
    {
      "intervalIndex": "interval-105",
      "value": 12.727272727272727,
      "endTime": 1526954400000
    },
    {
      "intervalIndex": "interval-106",
      "value": 5.9,
      "endTime": 1526950800000
    },
    {
      "intervalIndex": "interval-107",
      "value": 9.5,
      "endTime": 1526947200000
    },
    {
      "intervalIndex": "interval-108",
      "value": 107365.4,
      "endTime": 1526943600000
    },
    {
      "intervalIndex": "interval-109",
      "value": 6.909090909090909,
      "endTime": 1526940000000
    },
    {
      "intervalIndex": "interval-110",
      "value": 9.9,
      "endTime": 1526936400000
    },
    {
      "intervalIndex": "interval-111",
      "value": 8.0,
      "endTime": 1526932800000
    },
    {
      "intervalIndex": "interval-112",
      "value": 18.6,
      "endTime": 1526929200000
    },
    {
      "intervalIndex": "interval-113",
      "value": 4.2727272727272725,
      "endTime": 1526925600000
    },
    {
      "intervalIndex": "interval-114",
      "value": 7.4,
      "endTime": 1526922000000
    },
    {
      "intervalIndex": "interval-115",
      "value": 17.5,
      "endTime": 1526918400000
    },
    {
      "intervalIndex": "interval-116",
      "value": 13.6,
      "endTime": 1526914800000
    },
    {
      "intervalIndex": "interval-117",
      "value": 19.2,
      "endTime": 1526911200000
    },
    {
      "intervalIndex": "interval-118",
      "value": 6.166666666666667,
      "endTime": 1526907600000
    },
    {
      "intervalIndex": "interval-119",
      "value": 8.818181818181818,
      "endTime": 1526904000000
    },
    {
      "intervalIndex": "interval-120",
      "value": 21.7,
      "endTime": 1526900400000
    },
    {
      "intervalIndex": "interval-121",
      "value": 14.181818181818182,
      "endTime": 1526896800000
    },
    {
      "intervalIndex": "interval-122",
      "value": 9.0,
      "endTime": 1526893200000
    },
    {
      "intervalIndex": "interval-123",
      "value": 11.4,
      "endTime": 1526889600000
    },
    {
      "intervalIndex": "interval-124",
      "value": 8.0,
      "endTime": 1526886000000
    },
    {
      "intervalIndex": "interval-125",
      "value": 8.5,
      "endTime": 1526882400000
    },
    {
      "intervalIndex": "interval-126",
      "value": 12.5,
      "endTime": 1526878800000
    },
    {
      "intervalIndex": "interval-127",
      "value": 6.7,
      "endTime": 1526875200000
    },
    {
      "intervalIndex": "interval-128",
      "value": 4.1,
      "endTime": 1526871600000
    },
    {
      "intervalIndex": "interval-129",
      "value": 9.9,
      "endTime": 1526868000000
    },
    {
      "intervalIndex": "interval-130",
      "value": 9.375,
      "endTime": 1526864400000
    },
    {
      "intervalIndex": "interval-131",
      "value": 4.090909090909091,
      "endTime": 1526860800000
    },
    {
      "intervalIndex": "interval-132",
      "value": 6.9,
      "endTime": 1526857200000
    },
    {
      "intervalIndex": "interval-133",
      "value": 17.3,
      "endTime": 1526853600000
    },
    {
      "intervalIndex": "interval-134",
      "value": 12.5,
      "endTime": 1526850000000
    },
    {
      "intervalIndex": "interval-135",
      "value": 7.9,
      "endTime": 1526846400000
    },
    {
      "intervalIndex": "interval-136",
      "value": 9.0,
      "endTime": 1526842800000
    },
    {
      "intervalIndex": "interval-137",
      "value": 9.8,
      "endTime": 1526839200000
    },
    {
      "intervalIndex": "interval-138",
      "value": 18.4,
      "endTime": 1526835600000
    },
    {
      "intervalIndex": "interval-139",
      "value": 9.6,
      "endTime": 1526832000000
    },
    {
      "intervalIndex": "interval-140",
      "value": 6.9,
      "endTime": 1526828400000
    },
    {
      "intervalIndex": "interval-141",
      "value": 9.5,
      "endTime": 1526824800000
    },
    {
      "intervalIndex": "interval-142",
      "value": 18.6,
      "endTime": 1526821200000
    },
    {
      "intervalIndex": "interval-143",
      "value": 9.7,
      "endTime": 1526817600000
    },
    {
      "intervalIndex": "interval-144",
      "value": 11.3,
      "endTime": 1526814000000
    },
    {
      "intervalIndex": "interval-145",
      "value": 17.9,
      "endTime": 1526810400000
    },
    {
      "intervalIndex": "interval-146",
      "value": 12.8,
      "endTime": 1526806800000
    },
    {
      "intervalIndex": "interval-147",
      "value": 27.4,
      "endTime": 1526803200000
    },
    {
      "intervalIndex": "interval-148",
      "value": 7.090909090909091,
      "endTime": 1526799600000
    },
    {
      "intervalIndex": "interval-149",
      "value": 6.1,
      "endTime": 1526796000000
    },
    {
      "intervalIndex": "interval-150",
      "value": 14.6,
      "endTime": 1526792400000
    },
    {
      "intervalIndex": "interval-151",
      "value": 10.0,
      "endTime": 1526788800000
    },
    {
      "intervalIndex": "interval-152",
      "value": 13.416666666666666,
      "endTime": 1526785200000
    },
    {
      "intervalIndex": "interval-153",
      "value": 9.3,
      "endTime": 1526781600000
    },
    {
      "intervalIndex": "interval-154",
      "value": 12.8,
      "endTime": 1526778000000
    },
    {
      "intervalIndex": "interval-155",
      "value": 3.6,
      "endTime": 1526774400000
    },
    {
      "intervalIndex": "interval-156",
      "value": 2.3,
      "endTime": 1526770800000
    },
    {
      "intervalIndex": "interval-157",
      "value": 8.4,
      "endTime": 1526767200000
    },
    {
      "intervalIndex": "interval-158",
      "value": 6.0,
      "endTime": 1526763600000
    },
    {
      "intervalIndex": "interval-159",
      "value": 5.090909090909091,
      "endTime": 1526760000000
    },
    {
      "intervalIndex": "interval-160",
      "value": 7.5,
      "endTime": 1526756400000
    },
    {
      "intervalIndex": "interval-161",
      "value": 6.3,
      "endTime": 1526752800000
    },
    {
      "intervalIndex": "interval-162",
      "value": 16.0,
      "endTime": 1526749200000
    },
    {
      "intervalIndex": "interval-163",
      "value": 20.1,
      "endTime": 1526745600000
    },
    {
      "intervalIndex": "interval-164",
      "value": 23.3,
      "endTime": 1526742000000
    },
    {
      "intervalIndex": "interval-165",
      "value": 9.5,
      "endTime": 1526738400000
    },
    {
      "intervalIndex": "interval-166",
      "value": 7.5,
      "endTime": 1526734800000
    },
    {
      "intervalIndex": "interval-167",
      "value": 13.090909090909092,
      "endTime": 1526731200000
    },
    {
      "intervalIndex": "interval-168",
      "value": 9.2,
      "endTime": 1526727600000
    }
      ],
      "maxPerformanceList": [
            {
      "intervalIndex": "interval-0",
      "value": 18.0,
      "endTime": 1527332400000
    },
    {
      "intervalIndex": "interval-1",
      "value": 40.0,
      "endTime": 1527328800000
    },
    {
      "intervalIndex": "interval-2",
      "value": 49.0,
      "endTime": 1527325200000
    },
    {
      "intervalIndex": "interval-3",
      "value": 43.0,
      "endTime": 1527321600000
    },
    {
      "intervalIndex": "interval-4",
      "value": 67.0,
      "endTime": 1527318000000
    },
    {
      "intervalIndex": "interval-5",
      "value": 75.0,
      "endTime": 1527314400000
    },
    {
      "intervalIndex": "interval-6",
      "value": 21.0,
      "endTime": 1527310800000
    },
    {
      "intervalIndex": "interval-7",
      "value": 64.0,
      "endTime": 1527307200000
    },
    {
      "intervalIndex": "interval-8",
      "value": 75.0,
      "endTime": 1527303600000
    },
    {
      "intervalIndex": "interval-9",
      "value": 49.0,
      "endTime": 1527300000000
    },
    {
      "intervalIndex": "interval-10",
      "value": 67.0,
      "endTime": 1527296400000
    },
    {
      "intervalIndex": "interval-11",
      "value": 68.0,
      "endTime": 1527292800000
    },
    {
      "intervalIndex": "interval-12",
      "value": 24.0,
      "endTime": 1527289200000
    },
    {
      "intervalIndex": "interval-13",
      "value": 25.0,
      "endTime": 1527285600000
    },
    {
      "intervalIndex": "interval-14",
      "value": 79.0,
      "endTime": 1527282000000
    },
    {
      "intervalIndex": "interval-15",
      "value": 57.0,
      "endTime": 1527278400000
    },
    {
      "intervalIndex": "interval-16",
      "value": 39.0,
      "endTime": 1527274800000
    },
    {
      "intervalIndex": "interval-17",
      "value": 277.0,
      "endTime": 1527271200000
    },
    {
      "intervalIndex": "interval-18",
      "value": 28.0,
      "endTime": 1527267600000
    },
    {
      "intervalIndex": "interval-19",
      "value": 90.0,
      "endTime": 1527264000000
    },
    {
      "intervalIndex": "interval-20",
      "value": 33.0,
      "endTime": 1527260400000
    },
    {
      "intervalIndex": "interval-21",
      "value": 57.0,
      "endTime": 1527256800000
    },
    {
      "intervalIndex": "interval-22",
      "value": 23.0,
      "endTime": 1527253200000
    },
    {
      "intervalIndex": "interval-23",
      "value": 27.0,
      "endTime": 1527249600000
    },
    {
      "intervalIndex": "interval-24",
      "value": 148.0,
      "endTime": 1527246000000
    },
    {
      "intervalIndex": "interval-25",
      "value": 29.0,
      "endTime": 1527242400000
    },
    {
      "intervalIndex": "interval-26",
      "value": 58.0,
      "endTime": 1527238800000
    },
    {
      "intervalIndex": "interval-27",
      "value": 65.0,
      "endTime": 1527235200000
    },
    {
      "intervalIndex": "interval-28",
      "value": 71.0,
      "endTime": 1527231600000
    },
    {
      "intervalIndex": "interval-29",
      "value": 51.0,
      "endTime": 1527228000000
    },
    {
      "intervalIndex": "interval-30",
      "value": 34.0,
      "endTime": 1527224400000
    },
    {
      "intervalIndex": "interval-31",
      "value": 42.0,
      "endTime": 1527220800000
    },
    {
      "intervalIndex": "interval-32",
      "value": 201.0,
      "endTime": 1527217200000
    },
    {
      "intervalIndex": "interval-33",
      "value": 43.0,
      "endTime": 1527213600000
    },
    {
      "intervalIndex": "interval-34",
      "value": 39.0,
      "endTime": 1527210000000
    },
    {
      "intervalIndex": "interval-35",
      "value": 52.0,
      "endTime": 1527206400000
    },
    {
      "intervalIndex": "interval-36",
      "value": 38.0,
      "endTime": 1527202800000
    },
    {
      "intervalIndex": "interval-37",
      "value": 65.0,
      "endTime": 1527199200000
    },
    {
      "intervalIndex": "interval-38",
      "value": 35.0,
      "endTime": 1527195600000
    },
    {
      "intervalIndex": "interval-39",
      "value": 23.0,
      "endTime": 1527192000000
    },
    {
      "intervalIndex": "interval-40",
      "value": 17.0,
      "endTime": 1527188400000
    },
    {
      "intervalIndex": "interval-41",
      "value": 27.0,
      "endTime": 1527184800000
    },
    {
      "intervalIndex": "interval-42",
      "value": 31.0,
      "endTime": 1527181200000
    },
    {
      "intervalIndex": "interval-43",
      "value": 37.0,
      "endTime": 1527177600000
    },
    {
      "intervalIndex": "interval-44",
      "value": 14.0,
      "endTime": 1527174000000
    },
    {
      "intervalIndex": "interval-45",
      "value": 37.0,
      "endTime": 1527170400000
    },
    {
      "intervalIndex": "interval-46",
      "value": 58.0,
      "endTime": 1527166800000
    },
    {
      "intervalIndex": "interval-47",
      "value": 37.0,
      "endTime": 1527163200000
    },
    {
      "intervalIndex": "interval-48",
      "value": 39.0,
      "endTime": 1527159600000
    },
    {
      "intervalIndex": "interval-49",
      "value": 31.0,
      "endTime": 1527156000000
    },
    {
      "intervalIndex": "interval-50",
      "value": 21.0,
      "endTime": 1527152400000
    },
    {
      "intervalIndex": "interval-51",
      "value": 19.0,
      "endTime": 1527148800000
    },
    {
      "intervalIndex": "interval-52",
      "value": 45.0,
      "endTime": 1527145200000
    },
    {
      "intervalIndex": "interval-53",
      "value": 13.0,
      "endTime": 1527141600000
    },
    {
      "intervalIndex": "interval-54",
      "value": 13.0,
      "endTime": 1527138000000
    },
    {
      "intervalIndex": "interval-55",
      "value": 53.0,
      "endTime": 1527134400000
    },
    {
      "intervalIndex": "interval-56",
      "value": 49.0,
      "endTime": 1527130800000
    },
    {
      "intervalIndex": "interval-57",
      "value": 56.0,
      "endTime": 1527127200000
    },
    {
      "intervalIndex": "interval-58",
      "value": 58.0,
      "endTime": 1527123600000
    },
    {
      "intervalIndex": "interval-59",
      "value": 23.0,
      "endTime": 1527120000000
    },
    {
      "intervalIndex": "interval-60",
      "value": 31.0,
      "endTime": 1527116400000
    },
    {
      "intervalIndex": "interval-61",
      "value": 15.0,
      "endTime": 1527112800000
    },
    {
      "intervalIndex": "interval-62",
      "value": 64.0,
      "endTime": 1527109200000
    },
    {
      "intervalIndex": "interval-63",
      "value": 34.0,
      "endTime": 1527105600000
    },
    {
      "intervalIndex": "interval-64",
      "value": 65.0,
      "endTime": 1527102000000
    },
    {
      "intervalIndex": "interval-65",
      "value": 39.0,
      "endTime": 1527098400000
    },
    {
      "intervalIndex": "interval-66",
      "value": 56.0,
      "endTime": 1527094800000
    },
    {
      "intervalIndex": "interval-67",
      "value": 51.0,
      "endTime": 1527091200000
    },
    {
      "intervalIndex": "interval-68",
      "value": 43.0,
      "endTime": 1527087600000
    },
    {
      "intervalIndex": "interval-69",
      "value": 27.0,
      "endTime": 1527084000000
    },
    {
      "intervalIndex": "interval-70",
      "value": 26.0,
      "endTime": 1527080400000
    },
    {
      "intervalIndex": "interval-71",
      "value": 38.0,
      "endTime": 1527076800000
    },
    {
      "intervalIndex": "interval-72",
      "value": 34.0,
      "endTime": 1527073200000
    },
    {
      "intervalIndex": "interval-73",
      "value": 28.0,
      "endTime": 1527069600000
    },
    {
      "intervalIndex": "interval-74",
      "value": 33.0,
      "endTime": 1527066000000
    },
    {
      "intervalIndex": "interval-75",
      "value": 22.0,
      "endTime": 1527062400000
    },
    {
      "intervalIndex": "interval-76",
      "value": 39.0,
      "endTime": 1527058800000
    },
    {
      "intervalIndex": "interval-77",
      "value": 55.0,
      "endTime": 1527055200000
    },
    {
      "intervalIndex": "interval-78",
      "value": 34.0,
      "endTime": 1527051600000
    },
    {
      "intervalIndex": "interval-79",
      "value": 56.0,
      "endTime": 1527048000000
    },
    {
      "intervalIndex": "interval-80",
      "value": 35.0,
      "endTime": 1527044400000
    },
    {
      "intervalIndex": "interval-81",
      "value": 26.0,
      "endTime": 1527040800000
    },
    {
      "intervalIndex": "interval-82",
      "value": 21.0,
      "endTime": 1527037200000
    },
    {
      "intervalIndex": "interval-83",
      "value": 18.0,
      "endTime": 1527033600000
    },
    {
      "intervalIndex": "interval-84",
      "value": 23.0,
      "endTime": 1527030000000
    },
    {
      "intervalIndex": "interval-85",
      "value": 48.0,
      "endTime": 1527026400000
    },
    {
      "intervalIndex": "interval-86",
      "value": 55.0,
      "endTime": 1527022800000
    },
    {
      "intervalIndex": "interval-87",
      "value": 80.0,
      "endTime": 1527019200000
    },
    {
      "intervalIndex": "interval-88",
      "value": 59.0,
      "endTime": 1527015600000
    },
    {
      "intervalIndex": "interval-89",
      "value": 58.0,
      "endTime": 1527012000000
    },
    {
      "intervalIndex": "interval-90",
      "value": 63.0,
      "endTime": 1527008400000
    },
    {
      "intervalIndex": "interval-91",
      "value": 71.0,
      "endTime": 1527004800000
    },
    {
      "intervalIndex": "interval-92",
      "value": 58.0,
      "endTime": 1527001200000
    },
    {
      "intervalIndex": "interval-93",
      "value": 30.0,
      "endTime": 1526997600000
    },
    {
      "intervalIndex": "interval-94",
      "value": 70.0,
      "endTime": 1526994000000
    },
    {
      "intervalIndex": "interval-95",
      "value": 38.0,
      "endTime": 1526990400000
    },
    {
      "intervalIndex": "interval-96",
      "value": 67.0,
      "endTime": 1526986800000
    },
    {
      "intervalIndex": "interval-97",
      "value": 37.0,
      "endTime": 1526983200000
    },
    {
      "intervalIndex": "interval-98",
      "value": 45.0,
      "endTime": 1526979600000
    },
    {
      "intervalIndex": "interval-99",
      "value": 189.0,
      "endTime": 1526976000000
    },
    {
      "intervalIndex": "interval-100",
      "value": 42.0,
      "endTime": 1526972400000
    },
    {
      "intervalIndex": "interval-101",
      "value": 34.0,
      "endTime": 1526968800000
    },
    {
      "intervalIndex": "interval-102",
      "value": 67.0,
      "endTime": 1526965200000
    },
    {
      "intervalIndex": "interval-103",
      "value": 41.0,
      "endTime": 1526961600000
    },
    {
      "intervalIndex": "interval-104",
      "value": 40.0,
      "endTime": 1526958000000
    },
    {
      "intervalIndex": "interval-105",
      "value": 68.0,
      "endTime": 1526954400000
    },
    {
      "intervalIndex": "interval-106",
      "value": 23.0,
      "endTime": 1526950800000
    },
    {
      "intervalIndex": "interval-107",
      "value": 44.0,
      "endTime": 1526947200000
    },
    {
      "intervalIndex": "interval-108",
      "value": 1073611.0,
      "endTime": 1526943600000
    },
    {
      "intervalIndex": "interval-109",
      "value": 24.0,
      "endTime": 1526940000000
    },
    {
      "intervalIndex": "interval-110",
      "value": 24.0,
      "endTime": 1526936400000
    },
    {
      "intervalIndex": "interval-111",
      "value": 17.0,
      "endTime": 1526932800000
    },
    {
      "intervalIndex": "interval-112",
      "value": 59.0,
      "endTime": 1526929200000
    },
    {
      "intervalIndex": "interval-113",
      "value": 19.0,
      "endTime": 1526925600000
    },
    {
      "intervalIndex": "interval-114",
      "value": 16.0,
      "endTime": 1526922000000
    },
    {
      "intervalIndex": "interval-115",
      "value": 46.0,
      "endTime": 1526918400000
    },
    {
      "intervalIndex": "interval-116",
      "value": 41.0,
      "endTime": 1526914800000
    },
    {
      "intervalIndex": "interval-117",
      "value": 40.0,
      "endTime": 1526911200000
    },
    {
      "intervalIndex": "interval-118",
      "value": 14.0,
      "endTime": 1526907600000
    },
    {
      "intervalIndex": "interval-119",
      "value": 31.0,
      "endTime": 1526904000000
    },
    {
      "intervalIndex": "interval-120",
      "value": 65.0,
      "endTime": 1526900400000
    },
    {
      "intervalIndex": "interval-121",
      "value": 75.0,
      "endTime": 1526896800000
    },
    {
      "intervalIndex": "interval-122",
      "value": 35.0,
      "endTime": 1526893200000
    },
    {
      "intervalIndex": "interval-123",
      "value": 65.0,
      "endTime": 1526889600000
    },
    {
      "intervalIndex": "interval-124",
      "value": 37.0,
      "endTime": 1526886000000
    },
    {
      "intervalIndex": "interval-125",
      "value": 35.0,
      "endTime": 1526882400000
    },
    {
      "intervalIndex": "interval-126",
      "value": 32.0,
      "endTime": 1526878800000
    },
    {
      "intervalIndex": "interval-127",
      "value": 21.0,
      "endTime": 1526875200000
    },
    {
      "intervalIndex": "interval-128",
      "value": 15.0,
      "endTime": 1526871600000
    },
    {
      "intervalIndex": "interval-129",
      "value": 30.0,
      "endTime": 1526868000000
    },
    {
      "intervalIndex": "interval-130",
      "value": 21.0,
      "endTime": 1526864400000
    },
    {
      "intervalIndex": "interval-131",
      "value": 12.0,
      "endTime": 1526860800000
    },
    {
      "intervalIndex": "interval-132",
      "value": 17.0,
      "endTime": 1526857200000
    },
    {
      "intervalIndex": "interval-133",
      "value": 83.0,
      "endTime": 1526853600000
    },
    {
      "intervalIndex": "interval-134",
      "value": 52.0,
      "endTime": 1526850000000
    },
    {
      "intervalIndex": "interval-135",
      "value": 28.0,
      "endTime": 1526846400000
    },
    {
      "intervalIndex": "interval-136",
      "value": 26.0,
      "endTime": 1526842800000
    },
    {
      "intervalIndex": "interval-137",
      "value": 43.0,
      "endTime": 1526839200000
    },
    {
      "intervalIndex": "interval-138",
      "value": 94.0,
      "endTime": 1526835600000
    },
    {
      "intervalIndex": "interval-139",
      "value": 18.0,
      "endTime": 1526832000000
    },
    {
      "intervalIndex": "interval-140",
      "value": 19.0,
      "endTime": 1526828400000
    },
    {
      "intervalIndex": "interval-141",
      "value": 36.0,
      "endTime": 1526824800000
    },
    {
      "intervalIndex": "interval-142",
      "value": 58.0,
      "endTime": 1526821200000
    },
    {
      "intervalIndex": "interval-143",
      "value": 47.0,
      "endTime": 1526817600000
    },
    {
      "intervalIndex": "interval-144",
      "value": 37.0,
      "endTime": 1526814000000
    },
    {
      "intervalIndex": "interval-145",
      "value": 58.0,
      "endTime": 1526810400000
    },
    {
      "intervalIndex": "interval-146",
      "value": 32.0,
      "endTime": 1526806800000
    },
    {
      "intervalIndex": "interval-147",
      "value": 174.0,
      "endTime": 1526803200000
    },
    {
      "intervalIndex": "interval-148",
      "value": 22.0,
      "endTime": 1526799600000
    },
    {
      "intervalIndex": "interval-149",
      "value": 18.0,
      "endTime": 1526796000000
    },
    {
      "intervalIndex": "interval-150",
      "value": 89.0,
      "endTime": 1526792400000
    },
    {
      "intervalIndex": "interval-151",
      "value": 39.0,
      "endTime": 1526788800000
    },
    {
      "intervalIndex": "interval-152",
      "value": 53.0,
      "endTime": 1526785200000
    },
    {
      "intervalIndex": "interval-153",
      "value": 36.0,
      "endTime": 1526781600000
    },
    {
      "intervalIndex": "interval-154",
      "value": 62.0,
      "endTime": 1526778000000
    },
    {
      "intervalIndex": "interval-155",
      "value": 10.0,
      "endTime": 1526774400000
    },
    {
      "intervalIndex": "interval-156",
      "value": 5.0,
      "endTime": 1526770800000
    },
    {
      "intervalIndex": "interval-157",
      "value": 32.0,
      "endTime": 1526767200000
    },
    {
      "intervalIndex": "interval-158",
      "value": 20.0,
      "endTime": 1526763600000
    },
    {
      "intervalIndex": "interval-159",
      "value": 20.0,
      "endTime": 1526760000000
    },
    {
      "intervalIndex": "interval-160",
      "value": 35.0,
      "endTime": 1526756400000
    },
    {
      "intervalIndex": "interval-161",
      "value": 20.0,
      "endTime": 1526752800000
    },
    {
      "intervalIndex": "interval-162",
      "value": 80.0,
      "endTime": 1526749200000
    },
    {
      "intervalIndex": "interval-163",
      "value": 99.0,
      "endTime": 1526745600000
    },
    {
      "intervalIndex": "interval-164",
      "value": 100.0,
      "endTime": 1526742000000
    },
    {
      "intervalIndex": "interval-165",
      "value": 23.0,
      "endTime": 1526738400000
    },
    {
      "intervalIndex": "interval-166",
      "value": 30.0,
      "endTime": 1526734800000
    },
    {
      "intervalIndex": "interval-167",
      "value": 84.0,
      "endTime": 1526731200000
    },
    {
      "intervalIndex": "interval-168",
      "value": 31.0,
      "endTime": 1526727600000
    }
      ]
    }
  ]
}
});
