define([
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/database/SimLokiDatabaseActions',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/utilities/UtilityFunctions',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/assurance/icap/ScheduledCaptureTemplate',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/assurance/icap/ScheduledCaptureDeviceTemplate',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/assurance/icap/ScheduledGlobalSettings',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/assurance/icap/ApUnderWlcTemplate',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/assurance/icap/AirSenceDevConfigStatusTemplate',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/data-generation/services/assurance/SimAssuranceSiteData',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/data-generation/configuration/SimTaskDataGenerator'
], function(SimLokiDatabaseActions, UtilityFunctions, ScheduledCaptureTemplate, ScheduledCaptureDeviceTemplate,ScheduledGlobalSettings,
    ApUnderWlcTemplate, AirSenceDevConfigStatusTemplate, SimAssuranceSiteData, SimTaskDataGenerator){

    var defLocation = "Global/North America/USA/California/San Jose/SJC01/Flr-SJC1-1";
    var isEnableOnlyDefLocation = true;
    //var is126OrAbove = true;//change this field to false, in case it is being deployed for DNAC 1.2.5 or below

    return {
        init: function(){
            globalSettings();
            createList();
            setApConfigure();
            configureSettings();
        },

        getScheduledCapture: function() {
           var tData= SimLokiDatabaseActions.getAll('scheduled-capture');
           var response = {"response":tData,"version":"1.0"};
            return response;
        },

        getNetworkDeviceDetails: function(urlAction, version) {
            var wlcDevice = SimLokiDatabaseActions.getFilteredRecordHandler('network-device', {'family':urlAction.filter['nwDeviceFamily']} );
            //console.log(wlcDevice);
            var site = SimLokiDatabaseActions.getFilteredRecordHandler('site', {'groupNameHierarchy' : defLocation});
            if(wlcDevice && wlcDevice.length){
                var jsonObj=[], obj;
                if(version =="1.2.5") {
                    obj =  JSON.parse(JSON.stringify(ScheduledCaptureDeviceTemplate.NetworkDevice_Detail_Template ));
                    obj.id = [];
                    obj.id.push(wlcDevice[0].id);
                    obj.id.push(site[0].id);
                    obj.managementIpAddr = wlcDevice[0].managementIpAddress;
                    obj.nwDeviceRole = wlcDevice[0].role;
                    //clientCount - not updating for now
                    obj.nwDeviceType = wlcDevice[0].type;
                    obj.serialNumber = wlcDevice[0].serialNumber;
                    obj.nwDeviceName = wlcDevice[0].hostname;
                    obj.platformId = wlcDevice[0].platformId;
                    obj.nwDeviceId = wlcDevice[0].id;
                    obj.nwDeviceFamily = wlcDevice[0].family;
                    obj.macAddress = wlcDevice[0].macAddress;
                    obj.deviceSeries = wlcDevice[0].series
                    obj.softwareVersion = wlcDevice[0].softwareVersion;
                    obj.siteHierarchy =defLocation;
                    jsonObj.push(obj);
                } else {
                    var site = getSite(defLocation), siteId= site.parentId;
                    if(isEnableOnlyDefLocation) {
                        //wlc is not mapped to floor
                        wlcDevice = SimLokiDatabaseActions.getFilteredRecordHandler('network-device', {'$and':[
                            {'family':urlAction.filter['nwDeviceFamily']}, {'siteId':siteId} ]} );
                    } else {
                        wlcDevice = SimLokiDatabaseActions.getFilteredRecordHandler('network-device', {'family':urlAction.filter['nwDeviceFamily']} );
                    }

                    obj =  JSON.parse(JSON.stringify(ScheduledCaptureDeviceTemplate.NetworkDevice_Detail_Template_1_2_6 ));
                    obj.id = wlcDevice[0].id;
                    obj.timestamp = UtilityFunctions.getGivenDateAndTimeInValidFormat(UtilityFunctions.getTimeStamp());
                    obj.properties.managementIpAddr[0].value = wlcDevice[0].managementIpAddress;
                    obj.properties.serialNumber[0].value = wlcDevice[0].serialNumber;
                    obj.properties.nwDeviceName[0].value = wlcDevice[0].hostname;
                    obj.properties.platformId[0].value = wlcDevice[0].platformId;
                    obj.properties.nwDeviceId[0].value = wlcDevice[0].id;
                    obj.properties.nwDeviceRole[0].value = wlcDevice[0].role;
                    obj.properties.nwDeviceFamily[0].value = wlcDevice[0].family;
                    obj.properties.macAddress[0].value = wlcDevice[0].macAddress;
                    obj.properties.deviceSeries[0].value = wlcDevice[0].series;
                    var apCount = (SimLokiDatabaseActions.getFilteredRecordHandler('network-device', {'associatedWlcIp': wlcDevice[0].managementIpAddress})).length;
                    obj.properties.clientCount[0].value = apCount;
                    obj.properties.softwareVersion[0].value = wlcDevice[0].softwareVersion;
                    obj.properties.nwDeviceType[0].value = wlcDevice[0].type;
                    if(urlAction.url.indexOf("hasLabel('Site')")>-1 && urlAction.url.indexOf("select('wlc','site')")>-1) {
                        jsonObj.push({});
                        jsonObj[0]["wlc"] = obj;
                        var siteObj = {"id":"8e57203b-f35c-4c41-871e-802179e0a9e5","label":"Site","timestamp":"2018-11-24T04:18:40.004+0000","isDeleted":false,"type":"vertex","properties":{"siteHierarchy":[{"value":"Global/North America/USA/California/San Jose/SJC01/Flr-SJC1-1"}],"name":[{"value":"Flr-SJC1-1"}],"siteType":[{"value":"floor"}]}};
                        //have to update the siteObj based on isEnableOnlyDefLocation, for now ignoring..
                        siteObj.id = site.id;
                        jsonObj[0]['site'] = siteObj;
                    } else {
                        jsonObj.push(obj);
                    }
                }

                //for now we shall keep only 1 device
                return jsonObj;
             }
        },

        createScheduledCapture : function(urlAction) {
            var reqObj = urlAction.restPayload;

            if(reqObj.action=="schedule") {

                var clientList = reqObj.clientDetailsList;
                var dt = new Date(reqObj.startTime);
                var tObj =  JSON.parse(JSON.stringify( ScheduledCaptureTemplate.Template));
                var tData= SimLokiDatabaseActions.getAll('scheduled-capture');
                tObj.sessionId =tData.length+1;
                tObj.locationId = reqObj.locationId
                tObj.startTime =  Date.UTC(dt.getFullYear(), dt.getMonth(), dt.getDate());
                tObj.configDuration =reqObj.duration;
                tObj.actualDuration =0;
                tObj.clientMacList =[];
                for(var i=0;i<clientList.length;i++) {
                  tObj.clientMacList.push(clientList[i].macAddress);
                }

                tObj.clientDetailsList = clientList;
                if(reqObj.startTime==0) {
                    dt = new Date();
                    tObj.startTime =  Date.UTC(dt.getFullYear(), dt.getMonth(), dt.getDate());
                    tObj.sessionStatus ="RUNNING";
                    SimLokiDatabaseActions.insert('scheduled-capture', tObj);
                    return {"version":"1.0","response":{"sessionId":tObj.sessionId,"status":"RUNNING"}};
                }

                tObj.sessionStatus ="SCHEDULED";

                SimLokiDatabaseActions.insert('scheduled-capture', tObj);
                return {"version":"1.0","response":{"sessionId":tObj.sessionId,"status":"PENDING_SCHEDULE"}};

            }  else if(reqObj.action=="re-schedule") {

              var record = SimLokiDatabaseActions.getFilteredRecordHandler('scheduled-capture', {'sessionId': parseInt(reqObj.sessionId)});
              var clientList = reqObj.clientDetailsList;
                record[0].locationId = reqObj.locationId
                record[0].startTime = reqObj.startTime
                record[0].configDuration =reqObj.duration;
                record[0].actualDuration =0;
                record[0].clientMacList =[];
                for(var i=0;i<clientList.length;i++) {
                  record[0].clientMacList.push(clientList[i].macAddress);
                }

                record[0].clientDetailsList = clientList;

                if(reqObj.startTime==0) {
                    record[0].sessionStatus ="RUNNING";
                    SimLokiDatabaseActions.update('scheduled-capture', record);
                    return {"version":"1.0","response":{"sessionId":reqObj.sessionId,"status":"RUNNING"}};
                }
                record[0].sessionStatus ="SCHEDULED";

              SimLokiDatabaseActions.update('scheduled-capture', record);

              return {"version":"1.0","response":{"sessionId":reqObj.sessionId,"status":"PENDING_CANCEL"}}

            } else if(reqObj.action=="stop") {

                var record = SimLokiDatabaseActions.getFilteredRecordHandler('scheduled-capture', {'sessionId': reqObj.sessionId});
                 record[0].sessionStatus ="STOPPED";
                 SimLokiDatabaseActions.update('scheduled-capture', record);
                return {"version":"1.0","response":{"sessionId":reqObj.sessionId,"status":"STOPPED"}}
            } else if(reqObj.action=="delete") {

                var record = SimLokiDatabaseActions.getFilteredRecordHandler('scheduled-capture', {'sessionId': reqObj.sessionId});
                SimLokiDatabaseActions.delete('scheduled-capture', record);
                return {"version":"1.0","response":{"sessionId":reqObj.sessionId,"status":"PENDING_CANCEL"}}
            }
        },

        updateSettings :function(subtype,data) {
            var dnacRecords = SimLokiDatabaseActions.getFilteredRecordHandler('dnacConstants', {'key':'version'});
            var dnacVersion = dnacRecords[0].value;
            var versionCmpOutput = UtilityFunctions.versionCompare(dnacVersion,'1.2.6');
            if(versionCmpOutput<0) {
                if(subtype== "anomaly") {
                    if(data.isClientAnomalyDetectionPacketTrace=="no") {
                        var list =  JSON.parse(JSON.stringify(ScheduledGlobalSettings.Template[0] ));
                        list.id= UtilityFunctions.generate_uuid();
                        list.isEnable =data.isEnable;
                        list.isDisable =data.isDisable;
                        list.siteId= data.siteId;
                        SimLokiDatabaseActions.insert('scheduled-capture-settings', list);
                    } else {
                        var list =  JSON.parse(JSON.stringify(ScheduledGlobalSettings.Template[1] ));
                        list.id= UtilityFunctions.generate_uuid();
                        list.isEnable =data.isEnable;
                        list.isDisable =data.isDisable;
                        list.siteId= data.siteId;
                        SimLokiDatabaseActions.insert('scheduled-capture-settings', list);
                    }
                } else if(subtype== "ap-statistic") {
                    if(data.apStatisticsType=="radio") {
                        var list =  JSON.parse(JSON.stringify(ScheduledGlobalSettings.Template[2] ));
                        list.id= UtilityFunctions.generate_uuid();
                        list.isEnable =data.isEnable;
                        list.isDisable =data.isDisable;
                        list.siteId= data.siteId;
                        SimLokiDatabaseActions.insert('scheduled-capture-settings', list);
                    } else {
                        var list =  JSON.parse(JSON.stringify(ScheduledGlobalSettings.Template[3] ));
                        list.id= UtilityFunctions.generate_uuid();
                        list.isEnable =data.isEnable;
                        list.isDisable =data.isDisable;
                        list.siteId= data.siteId;
                        SimLokiDatabaseActions.insert('scheduled-capture-settings', list);
                    }
                }
            } else {
                //ap-statistic related is handled in SimCfsModelsV1, while anomaly is handled here in 1.2.6
                var restPayload=data;
                var records = SimLokiDatabaseActions.getFilteredRecordHandler('scheduled-capture-settings_126', {'$and': [
                    {"siteId": restPayload.siteId}, {'name':'anomaly'} ]} );
                records[0].isGlobal = restPayload.isGlobal;
                records[0].isEnable = restPayload.isEnable;
                records[0].isDisable = restPayload.isDisable;
                records[0].isClientAnomalyDetectionPacketTrace = restPayload.isClientAnomalyDetectionPacketTrace;
                records[0].lastUpdateTime = UtilityFunctions.getTimeStamp();
                if(records[0].isClientAnomalyDetectionPacketTrace=='yes') {
                    records[0].namespace = 'f:anomaly adpt:yes s:Global a:Enable op:CREATE';
                    var siteId = (getSite(defLocation)).id;
                    record = SimLokiDatabaseActions.getFilteredRecordHandler('issue_capture_settings_126',{'siteId':siteId});
                    record[0].isIssueAutoPcktCap = true;
                    SimLokiDatabaseActions.update('issue_capture_settings_126', record);
                }
                SimLokiDatabaseActions.update('scheduled-capture-settings_126',records);
            }
        },

        deleteSettings :function(id, subType) {
            var record = SimLokiDatabaseActions.getFilteredRecordHandler('scheduled-capture-settings', {'id': id});
            SimLokiDatabaseActions.delete('scheduled-capture-settings', record);

            var dnacRecords = SimLokiDatabaseActions.getFilteredRecordHandler('dnacConstants', {'key':'version'});
            var dnacVersion = dnacRecords[0].value;
            var versionCmpOutput = UtilityFunctions.versionCompare(dnacVersion,'1.2.6');
            if(versionCmpOutput>=0) {
                var resultObj = SimTaskDataGenerator.createTask('SPFService');
                var records = SimLokiDatabaseActions.getFilteredRecordHandler('scheduled-capture-settings_126', {'$and': [
                    {"id": id}, {'name':subType} ]} );
                records[0].lastUpdateTime = UtilityFunctions.getTimeStamp();

                if(subType == 'ap-statistic') {
                    records[0].namespace = records[0].namespace.replace('a:Enable','a:Disabled').replace('op:CREATE','op:DELETE');
                    SimLokiDatabaseActions.update('scheduled-capture-settings_126',records);
                    record = SimLokiDatabaseActions.getFilteredRecordHandler('network-device', {'hostname':records[0].apName});
                    record[0].isAPAutoCapConfigured = false;
                    SimLokiDatabaseActions.update('network-device',record);
                } else {
                    var siteId = (getSite(defLocation)).id;
                    record = SimLokiDatabaseActions.getFilteredRecordHandler('issue_capture_settings_126', {"siteId": siteId});
                    if(records[0].isClientAnomalyDetectionPacketTrace=='yes') {
                        //toggle off Issue Auto Packet Capture action handling
                        records[0].isClientAnomalyDetectionPacketTrace = 'no';
                        records[0].namespace = 'f:anomaly s:Global a:Enable op:CREATE'; //'adpt:yes' is removed

                        record[0].isIssueAutoPcktCap = false;
                    } else if(records[0].isClientAnomalyDetectionPacketTrace=='no' && records[0].isDisable=='no') {
                        //toggle off Issue handling
                        records[0].namespace = records[0].namespace.replace('a:Enable','a:Disabled');
                        record[0].isIssue = false;
                    } else {
                        //toggle on Issue handling
                        records[0].namespace = records[0].namespace.replace('a:Disabled','a:Enable');
                        record[0].isIssue = true;
                    }
                    SimLokiDatabaseActions.update('scheduled-capture-settings_126',records);
                    SimLokiDatabaseActions.update('issue_capture_settings_126', record);
                }

                SimTaskDataGenerator.updateTaskCompletion(resultObj.taskId, {isError: false });
                return resultObj;
            }
        },

        getApsUnderWlcOrSite: function(wlcId, siteId) {
            //get the list of APs under the specific WLC or specific site
            var records = [];
            if(wlcId) {
                var wlc = SimLokiDatabaseActions.getFilteredRecordHandler('network-device', {'id':wlcId});
                wlc = wlc[0].managementIpAddress;
                //associatedWlcIp field is there only for AP, hence no family check if made
                records = SimLokiDatabaseActions.getFilteredRecordHandler('network-device', {'associatedWlcIp': wlc});
            } else {
                records = SimLokiDatabaseActions.getFilteredRecordHandler('network-device', {'$and': [{'siteId': siteId},
                {'family':'Unified AP'} ]} );
            }
            var result = [];
            if(records && records.length) {
                var timestamp = UtilityFunctions.getGivenDateAndTimeInValidFormat(UtilityFunctions.getTimeStamp());
                for(var i=0; i<records.length; i++) {
                    var tObj =  JSON.parse(JSON.stringify(ApUnderWlcTemplate.Ap_Template));
                    tObj.id = records[i].id;
                    tObj.timestamp = timestamp;
                    tObj.properties.managementIpAddr[0].value = records[i].managementIpAddress;
                    tObj.properties.serialNumber[0].value = records[i].serialNumber;
                    tObj.properties.nwDeviceName[0].value = records[i].hostname;
                    tObj.properties.platformId[0].value = records[i].platformId;
                    tObj.properties.nwDeviceId[0].value = records[i].id;
                    tObj.properties.nwDeviceRole[0].value = records[i].role;
                    tObj.properties.nwDeviceFamily[0].value = records[i].family;
                    tObj.properties.macAddress[0].value = records[i].macAddress;
                    tObj.properties.deviceSeries[0].value = records[i].series;
                    tObj.properties.softwareVersion[0].value = records[i].softwareVersion;
                    tObj.properties.nwDeviceType[0].value = records[i].type;
                    if(!records[i].isAPAutoCapConfigured) {
                        tObj.properties.connectivityStatus[0].value = 0;
                        tObj.properties.resetReason[0].value = 'Discovery Request';
                    }
                    result.push(tObj);
                }
            }
            return result;
        },

        airsenseDeviceConfigStatus: function(urlAction) {
            var result = [];
            result.push(JSON.parse(JSON.stringify(AirSenceDevConfigStatusTemplate.WLC_TEMPLATE)));
            var wlcId = urlAction.restPayload.wlcDeviceId, icapFeature = urlAction.restPayload.icapFeature;
            if(!wlcId) {
                //this is called when configure operation is done. but response is different. since it doesnt make difference, ignore.
                var site = getSite(defLocation), siteId= site.parentId;
                if(isEnableOnlyDefLocation) {
                    //wlc is not mapped to floor
                    wlcDevice = SimLokiDatabaseActions.getFilteredRecordHandler('network-device', {'$and':[
                        {'family':'Wireless Controller'}, {'siteId':siteId} ]} );
                    wlcId = wlcDevice[0].id;
                }
            }
            result[0].wlcDeviceId = wlcId;
            var wlc = SimLokiDatabaseActions.getFilteredRecordHandler('network-device', {'id':wlcId});
            wlc = wlc[0].managementIpAddress;
            if(icapFeature=='ap-statistic') {
                var records = SimLokiDatabaseActions.getFilteredRecordHandler('network-device', {'associatedWlcIp': wlc });
                if(records && records.length) {
                    for(var i=0; i<records.length; i++) {
                        var tObj = JSON.parse(JSON.stringify(AirSenceDevConfigStatusTemplate.AP_DETAIL_TEMPLATE));
                        tObj.wlcDeviceId = wlcId;
                        tObj.target.scope = records[i].hostname;
                        tObj.lastUpdatedTime = UtilityFunctions.getTimeStamp();
                        if(!records[i].isAPAutoCapConfigured) {
                            tObj.state = "DISABLE";
                            tObj.target.operation = "DELETE";
                        }
                        result[0].detailStatus.push(tObj);
                    }
                }
            } else {
                //iCapFeature is anomaly
                result[0].icapFeature = 'ANOMALY';
                var tObj = JSON.parse(JSON.stringify(AirSenceDevConfigStatusTemplate.AP_DETAIL_TEMPLATE));
                tObj.wlcDeviceId = wlcId;
                tObj.target.feature = 'ANOMALY';
                tObj.target.apStatisticType = null;
                tObj.target.scope = 'Global';
                tObj.lastUpdatedTime = UtilityFunctions.getTimeStamp();
                var namespaceTkns = urlAction.restPayload.namespaceTokens;
                var siteId = (getSite(defLocation)).id;
                var record = SimLokiDatabaseActions.getFilteredRecordHandler('issue_capture_settings_126', {"siteId": siteId});
                if(!record[0].isIssue) {
                    tObj.state = 'DISABLE';
                }
                if(namespaceTkns && namespaceTkns.anomalyPktTrace && namespaceTkns.anomalyPktTrace=="yes") {
                    if(!record[0].isIssueAutoPcktCap) {
                        tObj.target.operation = 'DELETE';
                        tObj.state = 'DISABLE';
                    }
                    tObj.target.adPacketTrace = "YES";
                }
                result[0].detailStatus.push(tObj);
            }
            var resp = {"version": "1.0","response": []};
            resp.response = result;
            return resp;
        }

    };

    function createList() {
       var site = SimLokiDatabaseActions.getFilteredRecordHandler('site', {'groupNameHierarchy' : defLocation});
        var clients = SimLokiDatabaseActions.getFilteredRecordHandler('host', {'location' : defLocation});
        // but these should ideally be clients under the AP connected to the WLCs under this location
        var resObj = [];

        for(var j=0; j<5; j++) {
            var tObj =  JSON.parse(JSON.stringify( ScheduledCaptureTemplate.Template));
            var clientsToAdd = [];
            var clientLength = UtilityFunctions.getRandomIntForRangeValues(1,16);
            for(var i=0; i<clientLength; i++) {
                var index = UtilityFunctions.getRandomIntForRangeValues(0,clients.length-1);
                clientsToAdd.push(clients[index].hostName);
            }
            tObj.sessionId = j+1;   //in live cluster, the min sessionId seen was 3
            tObj.locationId = site.length > 0 ? site[0].id: tObj.locationId;
            tObj.startTime = UtilityFunctions.getTimeStamp();
            tObj.clientMacList = [];
            tObj.clientDetailsList = [];
            for(var i=0; i<clientsToAdd.length; i++) {
                var hostDetail = SimLokiDatabaseActions.getFilteredRecordHandler('host',{'hostName': clientsToAdd[i]});
                tObj.clientMacList.push(hostDetail[0].hostMac);
                var clientDetail = {};
                clientDetail.macAddress = hostDetail[0].hostMac;
                clientDetail.hostName = clientsToAdd[i];
                clientDetail.userId = hostDetail[0].userId;
                clientDetail.hostIp = hostDetail[0].hostIpV4;
                clientDetail.deviceType = hostDetail[0].subType;
                tObj.clientDetailsList.push(clientDetail);
            }
            if(j==4) {
              tObj.sessionStatus="RUNNING";
            }
            resObj.push(tObj);
        }

        SimLokiDatabaseActions.insert('scheduled-capture', resObj);
    }

    function globalSettings() {
        var site = SimLokiDatabaseActions.getFilteredRecordHandler('site', {'groupNameHierarchy' : defLocation});

        var list =  JSON.parse(JSON.stringify(ScheduledGlobalSettings.Template ));

        for(var i=0;i<list.length;i++) {

          list[i].id= UtilityFunctions.generate_uuid();
          list[i].siteId= site[0].id;

        }
       SimLokiDatabaseActions.insert('scheduled-capture-settings', list);
       console.log(list);
    }

    function setApConfigure () {
        var records = [];
        if(isEnableOnlyDefLocation) {
            var siteId = (getSite(defLocation)).id;
            records = SimLokiDatabaseActions.getFilteredRecordHandler('network-device', {'$and': [ {'siteId': siteId}, {'family':'Unified AP'} ]});
        } else {
            records = SimLokiDatabaseActions.getFilteredRecordHandler('network-device', {'family':'Unified AP'});
        }
        for(var i=0; i<records.length; i++) {
            if(i == records.length-1) {
                //let us configure one as unconfigured AP
                records[i].isAPAutoCapConfigured = false;
            } else {
                records[i].isAPAutoCapConfigured = true;
            }
        }
        SimLokiDatabaseActions.update('network-device', records);
    }

    function getSite(siteName) {
        var site = SimLokiDatabaseActions.getFilteredRecordHandler('site', {'groupNameHierarchy' : defLocation});
        return site[0];
    }

    function configureSettings() {
        var records = [], instanceId = 650000, currTime = UtilityFunctions.getTimeStamp();
        var siteId = (getSite(defLocation)).id;
        if(isEnableOnlyDefLocation) {
            records = SimLokiDatabaseActions.getFilteredRecordHandler('network-device', {'$and': [{'siteId': siteId},
                {'family':'Unified AP'} ]} );
        } else {
            records = SimLokiDatabaseActions.getFilteredRecordHandler('network-device', {'family':'Unified AP'});
        }
        //set for ap-statistic
        for(var i=0; i<records.length; i++) {
            var j=0;//2 entries - radio and wlan
            while(j<2) {
                var tObj = JSON.parse(JSON.stringify(ScheduledGlobalSettings.AP_TEMPLATE));
                tObj.id = UtilityFunctions.generate_uuid();
                tObj.instanceId = instanceId;
                tObj.authEntityId = instanceId;
                tObj.createTime = currTime;
                tObj.lastUpdatedTime = currTime;
                var state, operation, apStatsType;
                records[i].isAPAutoCapConfigured ? (state = "Enable", operation = "CREATE") : (state = "Disable",operation = "DELETE");
                j==0 ? apStatsType='radio' : apStatsType='wlan';
                tObj.namespace = tObj.namespace.replace('<ApStatisticsType>',apStatsType).replace('<AP_Name>',records[i].hostname);
                tObj.namespace = tObj.namespace.replace('<State>',state).replace('<Operation>',operation);
                tObj.apName = records[i].hostname;
                tObj.apStatisticsType = apStatsType;
                tObj.siteId = records[i].siteId;
                SimLokiDatabaseActions.insert('scheduled-capture-settings_126', tObj);
                instanceId++; j++;
            }
        }
        //set for anomaly
        var tObj = JSON.parse(JSON.stringify(ScheduledGlobalSettings.AP_TEMPLATE));
        //retaining certain fields as it should not throw error when querying else where..
        tObj.apName = 'Unwanted';
        tObj.apStatisticsFrequency = 'Unwanted';
        tObj.apStatisticsType = 'Unwanted';
        tObj.id = UtilityFunctions.generate_uuid();
        tObj.instanceId = instanceId;
        tObj.authEntityId = instanceId;
        tObj.createTime = currTime;
        tObj.lastUpdatedTime = currTime;
        tObj.instanceVersion = 0;
        tObj.isClientAnomalyDetectionPacketTrace = 'yes';
        tObj.isGlobal = 'yes';
        tObj.name = 'anomaly';
        tObj.namespace = 'f:anomaly adpt:yes s:Global a:Enable op:CREATE',
        tObj.qualifier = 'anomaly';
        tObj.resourceVersion = 0;
        tObj.siteId = siteId;
        SimLokiDatabaseActions.insert('scheduled-capture-settings_126', tObj);

        //var obj = {'siteId':siteId, 'isIssue':true; 'isIssueAutoPcktCap':true};
        var obj = {}; obj['siteId']=siteId; obj['isIssue']=true; obj['isIssueAutoPcktCap']=true;
        SimLokiDatabaseActions.insert('issue_capture_settings_126', obj);
    }

});
