define([
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/database/SimLokiDatabaseActions',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/data-generation/services/SimLanAutomationData'
], function (SimLokiDatabaseActions, SimLanAutomationData) {

    return {
        init: function() {
        },

        doProcessRestRequest: function(urlAction){
            console.log(urlAction.url);
            console.log(urlAction);
            var data;
            switch(urlAction.method) {
                case 'GET':
                    data = processGetRequest(urlAction);
                    break;

                case 'POST':
                    data = processPostRequest(urlAction);
                    break;

                case 'DELETE':
                    data = processDeleteRequest(urlAction);
                    break;

                default:
                    break;
            }

            return data;
        }
    };

    function processGetRequest(urlAction){
        //Lan        
        //if(urlAction.service.indexOf('network-orchestration')>=0 && urlAction.service.indexOf('log')>=0) {
        if(urlAction.action.summary == true && urlAction.action.id != undefined) {
            var data = []
            data = SimLokiDatabaseActions.getFilteredRecordHandler('lan-network-orch-status',{'id': urlAction.action.id});
            if (data[0].status == "Initialized1"){
                data[0].status = "Initialized"
            }else if (data[0].status == "Initialized"){
                data[0].status = "Seed Provisioned"
            }else if (data[0].status == "Seed Provisioned"){
                data[0].status = "In Progress: Discovered Devices: 1"
                data[0].discoveredDevicesCount = 1;
                data[0].discoveredDevices = data[0].discoveredDeviceList;
            }else if (data[0].status == "In Progress: Discovered Devices: 1"){
                data[0].status = "Completed: Discovered Devices: 1"
                data[0].provisionedDevicesCount = 1
            }else if (data[0].status == "Stop In Progress1"){
                data[0].status = "Stop In Progress"
            }else if (data[0].status == "Stop In Progress"){
                data[0].status = "Stop In Progress: All Links Configured"
            }else if (data[0].status == "Stop In Progress: All Links Configured"){
                data[0].status = "Completed: Discovered Devices: 1"
            }
            SimLokiDatabaseActions.update('lan-network-orch-status',data)
            data[0].color = "#DE6BDE";
            data[0].discoveredDeviceSiteNameHierarchy = data[0].siteName;
            data[0].ipPoolList = data[0].ipPools;
            data[0].multicastEnabled = false;
            data[0].primaryDeviceInterfaceNames = data[0].primaryInterfaceList;
            if(typeof(data[0].primarySeedDevice)===("object")){
                var nwdeviceId = data[0].primarySeedDevice.inventoryDevice;
            }else{
                var nwdeviceId = data[0].primarySeedDevice;
            }
            // var nwdeviceId = typeof(data[0].primarySeedDevice)===("object") ? data[0].primarySeedDevice : data[0].primarySeedDevice.inventoryDevice;
            var primarySeedDeviceData = SimLokiDatabaseActions.getFilteredRecordHandler('network-device',{'id':nwdeviceId});
            var psDevice = {}
            psDevice.deviceSeries = primarySeedDeviceData[0].series;
            psDevice.inventoryDevice = primarySeedDeviceData[0].id;
            psDevice.managementIpAddress = primarySeedDeviceData[0].managementIpAddress;
            psDevice.name = primarySeedDeviceData[0].name;
            psDevice.nwOrchId = urlAction.action.id;
            psDevice.platform = primarySeedDeviceData[0].platformId;
            psDevice.role = primarySeedDeviceData[0].role;
            psDevice.serialNumber = primarySeedDeviceData[0].serialNumber;
            psDevice.siteId = primarySeedDeviceData[0].siteId;
            psDevice.siteName = data[0].siteName;
            
            data[0].primarySeedDevice = psDevice
            data[0].redistributeIsisToBgp = false;
            return data[0];
        }
        if(urlAction.service.indexOf('log')>=0) {
            var  jObj = SimLokiDatabaseActions.getAll("lan-network-orch-log");
            var   data = jObj && jObj.length ? jObj[0] : {};
            console.log(data);
            return data; 
        }else if(urlAction.service.indexOf('sessions')>=0){
            data = SimLokiDatabaseActions.getFilteredRecordHandler('lan-network-orch-status',{'action':{'$in':['START','STOP']}});
            if(data.length>0){
                var dataIds = [];
                for(var i=0;i<data.length;i++){
                    if(!(data[i].action == "STOP" && data[i].status == "Completed: Discovered Devices: 1")){
                        dataIds.push(data[i].id)
                    }
                } 
                return {"maxSupportedCount":"5","activeSessions":dataIds.length,"activeSessionIds":dataIds}
            }
            return {"maxSupportedCount":"5","activeSessions":"0","activeSessionIds":[]}
        }else if(urlAction.service.indexOf('lanAutomatedDevices')>=0 && urlAction.action.count == true){
            var data = SimLokiDatabaseActions.getAll("lan-network-orch-status")
            return data.length
        }else if(urlAction.service.indexOf('lanAutomatedDevices')>=0){
            var data = SimLokiDatabaseActions.getAll("lan-network-orch-status")
            var dataList = []
            if(urlAction.filter['seedDevice'] == "true"){
                for(var i=0;i<data.length;i++){
                    dataList.push(data[i].primarySeedDevice)
                }
            }else{
                for(var i=0;i<data.length;i++){
                    dataList.push(data[i].discoveredDeviceList[0])
                }
            }
            return dataList
        }else if(urlAction.action.count == true && urlAction.service.indexOf('devices') >=0){
            var deviceData = SimLokiDatabaseActions.getFilteredRecordHandler('network-device',{'$and' : [{'siteId':urlAction.action.id},{'family':'Switches and Hubs'}]});
            return deviceData.length
        }else if(urlAction.action.id != undefined && urlAction.service.indexOf('devices') >=0){
            var deviceData = SimLokiDatabaseActions.getFilteredRecordHandler('network-device',{'$and' : [{'siteId':urlAction.action.id},{'family':'Switches and Hubs'}]});
            var dataList = []
            if (deviceData.length >0){
                for(i=0;i<deviceData.length;i++){
                    var d = {}
                    d.siteId = deviceData[i].siteId;
                    d.text = deviceData[i].name;
                    d.value = deviceData[i].id;
                    dataList.push(d)
                }
            }
            return dataList
        }else if(urlAction.service.indexOf('cedge') >=0){
            return "false"
        }
        else {
            var  jObj = SimLokiDatabaseActions.getAll("lan-network-orch-status");
            var   data = jObj && jObj.length ? jObj : [];
            if(window.localStorage.getItem('SimLanAutomationStatus')==='STOP') {
                window.localStorage.removeItem('SimLanAutomationStatus')
                data[0].action='STOP'
            }
            return data;
        }
    }

    function processPostRequest(urlAction){
        if(urlAction.service.indexOf('lan')>=0)  {
            return SimLanAutomationData.createLanAutomation(urlAction);//not used now (in 1.3.3)
        }else if(urlAction.service.indexOf('interface')>=0)  {
            return {"taskId": "63d16bda-dcc9-4b1e-aed0-f8dfb995c35d","url": "/api/v1/task/63d16bda-dcc9-4b1e-aed0-f8dfb995c35d"}
        }
        if(urlAction.service.indexOf('network-orchestration')>=0)  {
            return SimLanAutomationData.createLanAutomation(urlAction);
        }
    }

    function processDeleteRequest(urlAction){
        if(urlAction.action.id != undefined){
            data = SimLokiDatabaseActions.getFilteredRecordHandler('lan-network-orch-status',{'action':'START'})
            data[0].action = 'STOP';
            data[0].status = "Stop In Progress1"
            SimLokiDatabaseActions.update('lan-network-orch-status',data)
            return []
        }
        // if(urlAction.service.indexOf('network-orchestration')>=0)  {
        //     window.localStorage.setItem('SimLanAutomationStatus', 'STOP')
        //     return [];
        // }
    }

});
