define({
  "Template":
    {
  "type": "byapupdown",
  "records": [
    {
      "time": "2018-07-31T06:45:00.000+0000",
      "UpCount": "3",
      "DownCount": "0"
    }
  ],
  "values": [

  ]
},

"DeviceRechabilityStatusTemplate":
    {
      "type": null,
      "records": [
        {
          "time": "2020-10-07T06:50:00.000+0000",
          "ReachableCount": "9",
          "UnreachableCount": "0"
        },
        {
          "time": "2020-10-07T06:55:00.000+0000",
          "ReachableCount": "9",
          "UnreachableCount": "0"
        }
      ]
    }
});
