define([
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/database/SimLokiDatabaseActions',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/assurance/trigger/AssuranceTrigger',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/assurance/trigger/ClientTrigger',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/utilities/UtilityFunctions',
], function(SimLokiDatabaseActions, AssuranceTrigger, ClientTrigger, UtilityFunctions){
  
    function updateCustomDetails(data) {
        var records = SimLokiDatabaseActions.getFilteredRecordHandler('customIssue', {'id':data[0].id});
        if(records.length<1) { return; }
    
        //get the updated threshold values
        var customDetail = SimLokiDatabaseActions.getFilteredRecordHandler('issueSettingCustomised', 
            {'$and': [{'isEnabled': "true"}, {'issueName': data[0].name}] });
        var dtls = {}, key, kpi;
        if(data[0].name == 'radio_util_trigger') {
            key = 'utilization', kpi = 'radioChannelUtilizationAP';
        }
        for(var j=0; j<customDetail.length; j++) {
            if(customDetail[j].kpi == kpi) {
                dtls['threshold'] = customDetail[j].threshold;
            }
            if(customDetail[0].priority) {
                dtls['priority'] = customDetail[0].priority;
            }
        }
        for(var i=0; i<data[0].attributes.length; i++){
            if(dtls['threshold'] != undefined) {
                if (data[0].attributes[i].key == "threshold") {  
                    data[0].attributes[i].value = dtls['threshold'].toString();
                } else if (data[0].attributes[i].key == key) {
                    data[0].attributes[i].value = (parseFloat(dtls['threshold'])+5).toString();
                }
            }
            if(dtls['priority'] != undefined && data[0].attributes[i].key == "priority") {
                data[0].attributes[i].value = dtls['priority'];
            }
        }
    }

  return {
      init: function(){
      },

      postTrigger: function(urlAction){
          var tJson = JSON.parse(JSON.stringify(AssuranceTrigger.Assurance_Trigger_Template));
          var endDate = urlAction.restPayload.timeRange.end;
          var window =-5;
          var incrementBy =-5;
          for(var i=0;i<tJson.values.length;i++) {
            var curTime = UtilityFunctions.incrementDateByMinutes(endDate, incrementBy);
            tJson.values[i].time= curTime;
            tJson.values[i].endTime= curTime;
            incrementBy=incrementBy+window;
          }


            if (urlAction.restPayload.entity.managementIpAddr != undefined) {
                var restPayload = urlAction.restPayload.entity['managementIpAddr'];
                var issues = SimLokiDatabaseActions.getFilteredRecordHandler('issue', { 'entity': restPayload });
                if (issues.length) {
                    tJson.pagination.totalResults = issues.length;
                }
                return tJson;

            } else {
				return tJson;
		    }
      },

      getIssueTriggers: function(urlAction){
          var triggerData = {};
          var id = urlAction.service[2];
          if(id == undefined) {
              var t = urlAction.url.split('/');
              id = t.length == 7 ? t[6] : "";
          }

          var obj = SimLokiDatabaseActions.getFilteredRecordHandler('issue', {'id': id});
          if(obj.length==0) {
              obj = SimLokiDatabaseActions.getFilteredRecordHandler('customIssue', {'id': id});
          }
          if(obj.length) {
              var issueTrigger = SimLokiDatabaseActions.getFilteredRecordHandler('issue-trigger', {'id': obj[0].id});
              var tTime = endTime =UtilityFunctions.getGivenDateAndTimeInValidFormat(UtilityFunctions.getTimeStamp());
              if(issueTrigger.length) {
                  var ip = obj[0].entity;
                  issueTrigger[0].id = id;
                  issueTrigger[0].endTime = tTime;
                  issueTrigger[0].time = tTime;
                  issueTrigger[0].attributes[0].value = ip;
                  //issueTrigger[0].attributes[3].value = ip;
                  updateCustomDetails(issueTrigger);
                  return issueTrigger[0];
              }

              // if no data is there in db form a response by reading from issue table..
              var t =  JSON.parse(JSON.stringify( ClientTrigger.Template ));
              t.id = id;
              t.endTime = tTime;
              t.time = tTime;
              t.count = 1;
              t.name = obj[0].name;
              t.severity = obj[0].severity;
              return t;
          }

          return triggerData;
      }
  };

});
