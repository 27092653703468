
define({
    "Template": [
      {
        "key": 'ssh',
        "value":'Healthtrend'

      },

      {
        "key": 'disney-web-portal',
        "value":'HealthtrendDisney'

      },
      {
        "key": 'webex-meeting',
        "value":'Healthtrendwebx'

      },
      {
        "key": 'ssl',
        "value":'HealthtrendSsl'

      },
       {
        "key": 'netflix',
        "value":'HealthtrendNetflix'

      },
      {
        "key": 'ms-services',
        "value":'HealthtrendMsservices'

      }, {
        "key": 'http',
        "value":'HealthtrendHttp'

      }, {
        "key": 'dns',
        "value":'HealthtrendDns'

      },{
        "key": 'snmp',
        "value":'HealthtrendSnmp'

      },{
        "key": 'citrix-static',
        "value":'Healthtrendcitrix'

      },{
        "key": 'outlook-web-service',
        "value":'HealthtrendOutlook'

      },{
        "key": 'ms-office-web-apps',
        "value":'Healthtrendmsoffice'

      },{
        "key": 'ntp',
        "value":'Healthtrendntp'

      },{
        "key": 'netbios-ns',
        "value":'Healthtrendnetbios'

      },{
        "key": 'ms-lync',
        "value":'Healthtrendmslync'

      },{
        "key": 'binary-over-http',
        "value":'Healthtrendbinaryoverhttp'

      },{
        "key": 'espn-browsing',
        "value":'Healthtrendespnbrowsing'

      },{
        "key": 'skype',
        "value":'Healthtrendskype'

      },{
        "key": 'bing',
        "value":'Healthtrendbing'

      },{
        "key": 'ms-office-365',
        "value":'Healthtrendmsoffice365'

      },
      {
        "key": 'MedicalRecords',
        "value":'Healthtrendmedicalrecords'

      },
      {
        "key": 'capwap-control',
        "value":'Healthtrendcapwapcontrol'

      },
      {
        "key": 'microsoft-teams',
        "value":'Healthtrendmicrosoftteams'

      },
      


     ]
  });
