define([
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/database/SimLokiDatabaseActions',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/utilities/UtilityFunctions',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/assurance/host1/HostWirelessTopology',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/assurance/host1/HostWiredTopology',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/data-generation/configuration/DefaultConfig',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/assurance/nsaTopology',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/topology/assurance/AccessPointDevice360Template',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/topology/assurance/WLCDevice360Template',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/topology/assurance/DrillDownNeighborTemplate'
 ], function(SimLokiDatabaseActions, UtilityFunctions, HostWirelessTopology, HostWiredTopology,
             DefaultConfig, nsaTopology, AccessPointDevice360Template,WLCDevice360Template, DrillDownNeighborTemplate) {

  function setNodeInfo(i,networkDevice){
      var obj = { id: i, level: i == 0 ? 0: 1};
      obj.clients = null;
      obj.deviceType = networkDevice.type;
      obj.fabricGroup = null;
      obj.ip= networkDevice.managementIpAddress;
      obj.family = networkDevice.family;
      obj.role = networkDevice.role;
      obj.name = networkDevice.hostname;
      obj.nwDeviceId = networkDevice.id;
      obj.softwareVersion = networkDevice.softwareVersion;
      obj.nodeType = 'NetworkDevice';
      obj.platformId = networkDevice.platformId;
      obj.radioFrequency = null;
      obj.count = null;
      obj.userId = null;
      obj.description = null;
      obj.connectedDevice = null;
      obj.stackType = (networkDevice.stackCount !== null) ? "BACKSTACK":"NORMAL";
      obj.additionalInfo = {"macAddress":networkDevice.macAddress}
       if(DefaultConfig.BAD_DEVICE.indexOf(networkDevice.hostname)>=0 ) {
          obj.healthScore = 1;
       } else {
         // obj.healthScore = networkDevice.healthScore;
         obj.healthScore = networkDevice.assuranceHealthScore;
       }
      return obj;
  }

  function getClientConnectedDeviceInfo(deviceId) {
      var t = {};
      var obj = SimLokiDatabaseActions.getFilteredRecordHandler('network-device', {'id': deviceId });
      if(obj.length == 0) {
          t.matchFound = false;
          return t;
      }
      t.matchFound = true;
      t.deviceType = obj[0].type;
      t.platformId = obj[0].platformId;
      t.family = obj[0].family;
      t.softwareVersion = obj[0].softwareVersion;
      t.ip = obj[0].managementIpAddress;
      //t.healthScore = obj[0].healthScore;//or obj[0].assuranceHealthScore? as this is an empty array
      if(Array.isArray(obj[0].healthScore) && obj[0].healthScore.length>0) {
        t.healthScore = obj[0].healthScore;
      } else {
        t.healthScore = obj[0].assuranceHealthScore;
      }
      t.associatedWlcIp=obj[0].associatedWlcIp;
      return t;
  }

  function getHostWiredTopology(clientObj, urlAction) {
      var topology = JSON.parse(JSON.stringify( HostWiredTopology.Host_TOPOLOGY_Template ));

      var clientId = clientObj[0].id.replace(/:/g,'_');

      topology.response.links[0].source = clientId;

      topology.response.nodes[0].id = clientId;
      topology.response.nodes[0].ip = clientObj[0].hostIpV4;
      topology.response.nodes[0].name = clientObj[0].hostName;
      topology.response.nodes[0].userId = clientObj[0].userId;
      topology.response.nodes[0].healthScore = clientObj[0].healthScore[0].score;

      var t = getClientConnectedDeviceInfo(clientObj[0].connectedNetworkDeviceId);
      if(t.matchFound == false) {
          return topology;
      }

      if(clientObj[0].healthScore[0].score >= 1 && clientObj[0].healthScore[0].score <= 3 && clientObj[0].hostName != "Camera2") {
          //As score is bad, no need to show it linked to access device.. except for "Camera2"
          topology.response.nodes.length = 1;
          topology.response.links.length = 0;
          return topology;
      }

      /* topology.response.nodes[1].name = clientObj[0].ssid;
      topology.response.nodes[1].id = clientObj[0].ssid;

      topology.response.links[0].source = clientId;
      topology.response.links[0].target = clientObj[0].ssid; */

      var ntwConnectedClients = SimLokiDatabaseActions.getFilteredRecordHandler('host',{'connectedNetworkDeviceId': clientObj[0].connectedNetworkDeviceId});

      topology.response.nodes[1].name = clientObj[0].clientConnection;
      topology.response.nodes[1].id = clientObj[0].connectedNetworkDeviceId;


      topology.response.nodes[1].deviceType = t.deviceType;
      topology.response.nodes[1].platformId = t.platformId;
      topology.response.nodes[1].ip = t.ip;
      topology.response.nodes[1].family = t.family;
      topology.response.nodes[1].softwareVersion = t.softwareVersion;
      topology.response.nodes[1].healthScore = t.healthScore; //10
      topology.response.nodes[1].clients = ntwConnectedClients.length;

      topology.response.links[0].source = clientId;
      topology.response.links[0].target = clientObj[0].connectedNetworkDeviceId;

      return topology;
  }

  function getHostWirelessTopology(clientObj, urlAction) {

      var topology = JSON.parse(JSON.stringify( HostWirelessTopology.Host_TOPOLOGY_Template ));

      var clientId = clientObj[0].id.replace(/:/g,'_');

      topology.response.links[0].source = clientId;

      topology.response.nodes[0].id = clientId;
      topology.response.nodes[0].ip = clientObj[0].hostIpV4;
      topology.response.nodes[0].name = clientObj[0].hostName;
      topology.response.nodes[0].userId = clientObj[0].userId;
      topology.response.nodes[0].healthScore = clientObj[0].healthScore[0].score;

      var t = getClientConnectedDeviceInfo(clientObj[0].connectedNetworkDeviceId);
      if(t.matchFound == false) {

          return topology;
      }

      topology.response.nodes[1].name = clientObj[0].ssid;
      topology.response.nodes[1].id = clientObj[0].ssid;

      topology.response.nodes[2].name = clientObj[0].clientConnection;
      topology.response.nodes[2].id = clientObj[0].connectedNetworkDeviceId;


      topology.response.nodes[2].deviceType = t.deviceType;
      topology.response.nodes[2].platformId = t.platformId;
      topology.response.nodes[2].ip = t.ip;
      topology.response.nodes[2].family = t.family;
      topology.response.nodes[2].softwareVersion = t.softwareVersion;
      topology.response.nodes[2].healthScore = t.healthScore;

      var objs = SimLokiDatabaseActions.getFilteredRecordHandler('host', {'connectedNetworkDeviceId': clientObj[0].connectedNetworkDeviceId });
      topology.response.nodes[2].clients = objs.length ? objs.length : 1; //t.clients;

      topology.response.links[0].source = clientId;
      topology.response.links[0].target = clientObj[0].ssid;

      topology.response.links[1].source = clientObj[0].ssid;
      topology.response.links[1].target = clientObj[0].connectedNetworkDeviceId;
      topology.response.links[2].source = clientObj[0].connectedNetworkDeviceId;
      //topology.response.links[0].target = clientObj[0].connectedNetworkDeviceId;

     var wlcDevice = SimLokiDatabaseActions.getFilteredRecordHandler('network-device', {'managementIpAddress':t.associatedWlcIp} );

      if(wlcDevice.length>0) {
         //Adding Node details
          topology.response.nodes[3].name = wlcDevice[0].name;
          topology.response.nodes[3].deviceType = wlcDevice[0].type;
          topology.response.nodes[3].platformId = wlcDevice[0].platformId;
          topology.response.nodes[3].family = wlcDevice[0].family;
          topology.response.nodes[3].softwareVersion = wlcDevice[0].softwareVersion;
          topology.response.nodes[3].healthScore = wlcDevice[0].assuranceHealthScore;
          topology.response.nodes[3].id = wlcDevice[0].instanceUuid;
          topology.response.nodes[3].ip = wlcDevice[0].managementIpAddress;
          //Adding links
           topology.response.links[2].target =wlcDevice[0].id ;

       var data = SimLokiDatabaseActions.getFilteredRecordHandler('network-device',{'associatedWlcIp':wlcDevice[0].managementIpAddress});
       var apDevicelistIds=[];
       for(var j=0;j<data.length;j++) {
          apDevicelistIds.push(data[j].id);
        }
       var clientList = SimLokiDatabaseActions.getFilteredRecordHandler('host',{
                                            'connectedNetworkDeviceId': { '$containsAny' :  apDevicelistIds}});
       // updating the clients
       topology.response.nodes[3].clients=clientList.length;

      }
      return topology;
  }

  return {

      init: function(){
      },

      getAssuranceTopologyData: function(urlAction){
          if(urlAction.service.indexOf('host') >= 0) {
              var id = urlAction.service[2];
              var objs = SimLokiDatabaseActions.getFilteredRecordHandler('host', {'id': id });
              if(objs.length) {
                  if(objs[0].hostType == 'WIRELESS') {
                      return getHostWirelessTopology(objs, urlAction);
                  } else if(objs[0].hostType == 'WIRED') {
                      return getHostWiredTopology(objs, urlAction);
                  }
              }
              //ToDo -- write a code to take care of unknown device is reached..
          }

          var deviceId = urlAction.action.id;
          var data = SimLokiDatabaseActions.getAll('physical-topology');
          //access point topology -- need to move to a helper function
          var obj = SimLokiDatabaseActions.getFilteredRecordHandler('network-device', {'id':deviceId} );
          if(obj.length && obj[0].family == "Unified AP") {
              var t =  JSON.parse(JSON.stringify(AccessPointDevice360Template.Template));
               for(var i =0; i < data.length; i++) {
                 if(data[i].topology_type == "link" && data[i].target == deviceId) {
                   var sourceDevice = SimLokiDatabaseActions.getFilteredRecordHandler('network-device', {'id':data[i].source});
                   var source =setNodeInfo(0,sourceDevice[0]);
                       source.id=data[i].source;
                   var target =setNodeInfo(1,obj[0]);
                      target.id= deviceId;
                   var  associatedWlcIp = obj[0].associatedWlcIp;

                   var wlcDevice = SimLokiDatabaseActions.getFilteredRecordHandler('network-device', {'managementIpAddress':associatedWlcIp} );
                   var wlcSource;
                    if(wlcDevice.length>0) {
                     wlcSource =setNodeInfo(0,wlcDevice[0]);
                     wlcSource.id =wlcDevice[0].id;
                    }

                     var client_List_2_4 = SimLokiDatabaseActions.getFilteredRecordHandler('host',{'$and': [ {
                                                                                                'connectedNetworkDeviceId': obj[0].id},
                                                                                                 { 'frequency':"2.4" }]});
                     var client_List_5 = SimLokiDatabaseActions.getFilteredRecordHandler('host',{'$and': [ {
                                                                                                'connectedNetworkDeviceId': obj[0].id},
                                                                                                 { 'frequency':"5.0" }]});
                     // updating clients
                     t.response.nodes[0].clients=client_List_2_4.length;
                     t.response.nodes[1].clients=client_List_5.length;
                     target.clients= client_List_2_4.length+ client_List_5.length;
                     t.response.nodes.push(source);
                     t.response.nodes.push(target);
                     t.response.nodes.push(wlcSource);

                     t.response.links[0].source=obj[0].id;
                     t.response.links[1].source=obj[0].id;
                     t.response.links.push({ source: data[i].target, target:data[i].source, label: [], linkStatus: null, 
                        portUtilization: null,id: obj[0].managementIpAddress+"-"+data[i].endPortName, sourceLinkStatus:"UP", targetLinkStatus:"UP", 
                        sourceInterfaceName:data[i].endPortName, targetInterfaceName:data[i].startPortName, sourceDuplexInfo:null, 
                        targetDuplexInfo: "FullDuplex", sourcePortMode:null, targetPortMode: "access",sourceAdminStatus:null, 
                        targetAdminStatus:"UP", interfaceDetails: [], apRadioAdminStatus: null, apRadioOperStatus: null});
                     t.response.links.push({ source: data[i].source, target:wlcDevice[0].id, label: ["checkDirect"], linkStatus: null, 
                        portUtilization: null,id: "Switch-WLC", sourceLinkStatus:null, targetLinkStatus:null, sourceInterfaceName:null,
                        targetInterfaceName:null, sourceDuplexInfo:null, targetDuplexInfo: null, sourcePortMode: null, targetPortMode: null, 
                        sourceAdminStatus:null, targetAdminStatus:null, interfaceDetails: [], apRadioAdminStatus: null, apRadioOperStatus: null});
                     t.response.links.push({ source: data[i].target, target:wlcDevice[0].id, label: ["overlay"], linkStatus: null, 
                        portUtilization: null,id: null, sourceLinkStatus:null, targetLinkStatus:null, sourceInterfaceName:null,
                        targetInterfaceName:null, sourceDuplexInfo:null, targetDuplexInfo: null, sourcePortMode: null, targetPortMode: null, 
                        sourceAdminStatus:null, targetAdminStatus:null, interfaceDetails: [], apRadioAdminStatus: null, apRadioOperStatus: null});
                 }
               }
              return t;
          }

          if(obj.length && obj[0].family == "Wireless Controller") {
              var t =  JSON.parse(JSON.stringify(WLCDevice360Template.Template));
                for(var i =0; i < data.length; i++) {
                    if(data[i].topology_type == "link" && data[i].target == deviceId) {
                        var WlcDevice = SimLokiDatabaseActions.getFilteredRecordHandler('network-device',{'id':data[i].target});
                        var sourceDevice = SimLokiDatabaseActions.getFilteredRecordHandler('network-device', {'id':data[i].source} );
                        var source =setNodeInfo(0,sourceDevice[0]);
                            source.id=data[i].source;
                        var target =setNodeInfo(1,obj[0]);
                        target.id= deviceId;

                        t.response.links.push({ source: data[i].target, target:data[i].source, label: [], linkStatus: null, portUtilization: null,
                            id: WlcDevice[0].managementIpAddress+"-"+data[i].endPortName, sourceLinkStatus:"UP", targetLinkStatus:"UP", 
                            sourceInterfaceName:data[i].endPortName, targetInterfaceName:data[i].startPortName, sourceDuplexInfo:"AutoNegotiate", 
                            targetDuplexInfo: "FullDuplex", sourcePortMode: "routed", targetPortMode: "trunk", sourceAdminStatus:"UP", targetAdminStatus:"UP", 
                            interfaceDetails: [], apRadioAdminStatus: null, apRadioOperStatus: null});
                        t.response.links.push({ source: data[i].target, target:"AP", label: [], linkStatus: null, portUtilization: null,id: null,
                            sourceLinkStatus:null, targetLinkStatus:null, sourceInterfaceName:null,targetInterfaceName:null, sourceDuplexInfo:null, 
                            targetDuplexInfo: null, sourcePortMode: null, targetPortMode: null, sourceAdminStatus:null, targetAdminStatus:null, 
                            interfaceDetails: [], apRadioAdminStatus: null, apRadioOperStatus: null});

                        //Getting IP Addresss
                        var data = SimLokiDatabaseActions.getFilteredRecordHandler('network-device',{'associatedWlcIp':WlcDevice[0].managementIpAddress});
                        t.response.nodes[0].count=data.length;
                        var apDevicelistIds=[];

                        for(var j=0;j<data.length;j++) {
                            apDevicelistIds.push(data[j].id);
                        }
                        var clientList = SimLokiDatabaseActions.getFilteredRecordHandler('host',{
                                                            'connectedNetworkDeviceId': { '$containsAny' :  apDevicelistIds}});
                        t.response.nodes[0].clients=clientList.length;
                        target.clients=clientList.length;
                        t.response.nodes.push(source);
                        t.response.nodes.push(target);
                    }
                }
              return t;
          }

            if(obj.length && obj[0].family == "Switches and Hubs" || obj[0].family == "Routers") {
                var t =  JSON.parse(JSON.stringify(WLCDevice360Template.Template));
                var node = SimLokiDatabaseActions.getFilteredRecordHandler('network-device', {'id':deviceId});
                var connectedClients = SimLokiDatabaseActions.getFilteredRecordHandler('host',{'connectedNetworkDeviceId': deviceId});
                t.response.nodes = [];
                var nodeDevice =setNodeInfo(1,node[0]);
                nodeDevice.id=deviceId;
                nodeDevice.clients = connectedClients.length;
                t.response.nodes.push(nodeDevice);
                for(var i =0; i < data.length; i++) {
                    if(data[i].topology_type == "link" && data[i].target == deviceId) {
                        var sourceDevice = SimLokiDatabaseActions.getFilteredRecordHandler('network-device', {'id':data[i].source});
                        if(sourceDevice && sourceDevice.length) {
                            var source =setNodeInfo(0,sourceDevice[0]);
                            source.id=data[i].source;
                            t.response.nodes.push(source);
                            t.response.links.push({source:deviceId, target:data[i].source, label:[], linkStatus:null, portUtilization:null, 
                                id:sourceDevice[0].managementIpAddress+"-"+data[i].endPortName, sourceLinkStatus:"UP", targetLinkStatus:"UP", 
                                sourceInterfaceName:data[i].startPortName, targetInterfaceName:data[i].endPortName,
                                sourceDuplexInfo:"FullDuplex", targetDuplexInfo: null,sourcePortMode: "access", targetPortMode: null,
                                sourceAdminStatus:"UP", targetAdminStatus:"UP", interfaceDetails: [], apRadioAdminStatus: null, apRadioOperStatus: null});
                        }
                    } else if(data[i].topology_type == "link" && data[i].source == deviceId) {
                        var targetDevice = SimLokiDatabaseActions.getFilteredRecordHandler('network-device', {'id':data[i].target});
                        if(targetDevice && targetDevice.length) {
                            var target =setNodeInfo(2,targetDevice[0]);
                            target.level=2;
                            target.id=data[i].target;
                            t.response.nodes.push(target);
                            t.response.links.push({source:deviceId, target:data[i].target, label:[], linkStatus:"UP", portUtilization:null, 
                            id:targetDevice[0].managementIpAddress+"-"+data[i].startPortName,sourceLinkStatus:"UP", targetLinkStatus:"UP", 
                            sourceInterfaceName:data[i].endPortName, targetInterfaceName:data[i].startPortName,
                            sourceDuplexInfo:"FullDuplex", targetDuplexInfo: "FullDuplex",sourcePortMode: "access", targetPortMode: "routed",
                            sourceAdminStatus:"UP", targetAdminStatus:"UP", interfaceDetails: [], apRadioAdminStatus: null, apRadioOperStatus: null});
                        }
                    }
                }
                if(connectedClients.length>0) {
                    var clientObj = JSON.parse('{"role":"null", "name":"clientCount Clients", "id":"CLIENTS", "description":null, "deviceType":null, "platformId":null, "family":"CLIENTS", "ip":null, "softwareVersion":null, "userId":null, "nodeType":"CLIENTS", "radioFrequency":null, "clients":null, "count":null, "healthScore":null, "level":2, "fabricGroup":null, "connectedDevice":null,"stackType":"NORMAL","additionalInfo":null}');
                    clientObj.name = connectedClients.length + " Clients";
                    t.response.nodes.push(clientObj);
                    var linkObj = { source:deviceId, target:"CLIENTS", linkStatus:"UP", portUtilization:null,
                    id:"CLIENTS", sourceLinkStatus:"UP", targetLinkStatus:null, sourceInterfaceName:null,sourceInterfaceName:"TwoGigabitEthernet1/0/16",
                        targetInterfaceName:"TwoGigabitEthernet1/0/16",sourceDuplexInfo:null, targetDuplexInfo: null, sourcePortMode: null, targetPortMode: null,
                        sourceAdminStatus:null, targetAdminStatus:null, interfaceDetails: [], apRadioAdminStatus: null, apRadioOperStatus: null,
                        sourcePortVLANInfo:null,targetPortVLANInfo:null};
                    var ifName = "GigabitEthernet1/0/";
                    for(var i=0; i<connectedClients.length; i++) {
                        var tObj =  JSON.parse(JSON.stringify(WLCDevice360Template.InterfaceTemplate));
                        tObj.clientMacAddress = connectedClients[i].hostMac;
                        tObj.connectedDeviceIntName = ifName+(i+2);
                        tObj.portMode = "access";
                        linkObj.interfaceDetails.push(tObj);
                    }
                    t.response.links.push(linkObj);
                }
                return t;
            }

         // var data = SimLokiDatabaseActions.getAll('physical-topology');
          var links = [];
          var targetLinks = [];
          links.push(deviceId);
          var length = data.length;
          for(var i =0; i < length; i++) {
              if(data[i].topology_type == "link" && data[i].target == deviceId) {
                  if(data[i].source != DefaultConfig.DEFAULT_UUID.TopologyCloudNode ){
                      links.push(data[i].source);
                  }
              }
              else if(data[i].topology_type == "link" && data[i].source == deviceId) {
                  if(data[i].source != DefaultConfig.DEFAULT_UUID.TopologyCloudNode ){
                      targetLinks.push(data[i].target);
                  }
              }
          }
          var devicesIndxMap = new Object();
          var networkDevices =  SimLokiDatabaseActions.getAll('network-device');
          for(i=0; i < networkDevices.length; i++){
              devicesIndxMap[networkDevices[i].id] = i;
          }

          var tObj =  JSON.parse(JSON.stringify( nsaTopology.NSA_TOPOLOGY_Template));
          tObj.nodes.splice(0,1);
          tObj.links.splice(0,1);

          for(var i=0; i < links.length;i++) {
              if(i > 0) {
                  tObj.links.push({ source: 0, target:i, label: [], linkStatus: "UP", portUtilization: null,id: null});
              }

              var indx = devicesIndxMap[links[i]];
              var obj =setNodeInfo(i,networkDevices[indx]);
              tObj.nodes.push(obj);
          }

          for(var i=0; i < targetLinks.length;i++) {
              tObj.links.push({ target: 0, source:i, label: [], linkStatus: "UP", portUtilization: null,id: null});
              //tObj.links.push({ source: i, target:0});
              var indx = devicesIndxMap[targetLinks[i]];
              var obj =setNodeInfo(i,networkDevices[indx]);
              tObj.nodes.push(obj);
          }

          return { 'response': tObj, 'version': '1.0'};

      },

      getDrillDownInfo : function(urlAction) {
          var neibourDevIds = urlAction.restPayload.neigbourDevices;
          var tJson = JSON.parse(JSON.stringify(DrillDownNeighborTemplate.Template));
          var entity = tJson.response[0];
          tJson.response.splice(0,1);
          for(var i=0; i<neibourDevIds.length; i++) {
              var obj = SimLokiDatabaseActions.getFilteredRecordHandler('network-device', {'id': neibourDevIds[i]});
              if(obj.length >= 0) {
                  var entityObj = JSON.parse(JSON.stringify(entity));
                  entityObj.id = obj[0].id;
                  entityObj.mgmtIp = obj[0].managementIpAddress;
                  entityObj.name = obj[0].hostname;
                  entityObj.healthScore[0].score = obj[0].assuranceHealthScore;
                  tJson.response.push(entityObj);
              }
          }
          return tJson;
      },

        //To load health of devices in Topology from Tools of dashboard
        getTopologyDeviceHealth: function() {
            var records = SimLokiDatabaseActions.getAll('network-device');
            var result = [];
            for(var i=0; i<records.length; i++) {
                result.push(records[i].id);
                result.push(records[i].assuranceHealthScore);
            }
            return result;
        }

  };

});
