define([
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/database/SimLokiDatabaseActions',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/utilities/UtilityFunctions',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/design/TemplateConfigTemplate',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/data-generation/configuration/SimTaskDataGenerator',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/utilities/SimulationUtility',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/design/TemplateConfigTemplateDefault',
], function (SimLokiDatabaseActions, UtilityFunctions, TemplateConfigTemplate, SimTaskDataGenerator, SimulationUtility,
    TemplateConfigTemplateDefault) {

    function createProjects(isShowDefault) {
        var tJson = JSON.parse(JSON.stringify(TemplateConfigTemplate.Template_Congig_Template));
        if(isShowDefault) {
            tJson = tJson.concat(TemplateConfigTemplateDefault.Template_Congig_Template_Default);
        }
        SimLokiDatabaseActions.insert('templateProject', tJson);
    }

    function createOnboardingTemplates(isShowDefault) {
        var tJson = JSON.parse(JSON.stringify(TemplateConfigTemplate.Onboarding_Config_Template));
        if(isShowDefault) {
            tJson = tJson.concat(TemplateConfigTemplateDefault.Onboarding_Config_Template_Default);
        }
        for(var i=0; i<tJson.length; i++) {
            tJson[i].versionsInfo.forEach(vInfo => {
                vInfo.versionTime = UtilityFunctions.getTimeStamp();
            });
            tJson[i].versionsInfo[0].versionTime = UtilityFunctions.getTimeStamp();
            tJson[i].versionsInfo[1].versionTime = UtilityFunctions.getTimeStamp()+10000;//10s added as commit version should be greater than versionTime
            SimLokiDatabaseActions.insert('templatesConfig', tJson[i]);
        }
    }

    function createTemplateDetail(isShowDefault) {
        var tJson = JSON.parse(JSON.stringify(TemplateConfigTemplate.TemplateDetail));
        if(isShowDefault) {
            tJson = tJson.concat(TemplateConfigTemplateDefault.TemplateDetail_Default);
        }
        for(var i=0; i<tJson.length; i++) {
            var templateHLDtl = SimLokiDatabaseActions.getFilteredRecordHandler('templatesConfig', {'name':tJson[i].name});
            var versionsInfo = templateHLDtl[0].versionsInfo;            
            if(tJson[i].templateContent == "") {
                tJson[i].templateContent = JSON.parse(JSON.stringify(TemplateConfigTemplate.TemplateContent));
            }
            tJson[i].createTime = UtilityFunctions.getTimeStamp();
            tJson[i].lastUpdateTime = UtilityFunctions.getTimeStamp();
            tJson[i].id = versionsInfo[0].id;
            // var templateLLDtl = JSON.parse(JSON.stringify(tJson[i]));
            //Can see double templates in templateDetail table so only commented 
            SimLokiDatabaseActions.insert('templateDetail', tJson[i]);

            // templateLLDtl.id = versionsInfo[1].id;
            // SimLokiDatabaseActions.insert('templateDetail', templateLLDtl);
        }
    }

    function updateProjectDetails(restPayload) {
        var records = SimLokiDatabaseActions.getFilteredRecordHandler('templateProject', {'id':restPayload.id});
        var oldProjName = records[0].name, oldProjDesc = records[0].description;
        records[0].name = restPayload.name;
        if(restPayload.description) records[0].description = restPayload.description;
        SimLokiDatabaseActions.update('templateProject', records);

        records = SimLokiDatabaseActions.getFilteredRecordHandler('templatesConfig', {'projectId':restPayload.id});
        for(var i=0; i<records.length; i++) {
            records[i].projectName = restPayload.name;
        }
        SimLokiDatabaseActions.update('templatesConfig', records);

        records = SimLokiDatabaseActions.getFilteredRecordHandler('templateDetail', {'projectId':restPayload.id});
        for(var i=0; i<records.length; i++) {
            records[i].projectName = restPayload.name;
        }
        SimLokiDatabaseActions.update('templateDetail', records);
    }

    function formatResponse(data) {
        return {'response':data, 'version':'1.0'};
    }

    function isTemplateExistInProj(templateName, projId) {
        var records = SimLokiDatabaseActions.getFilteredRecordHandler('templatesConfig', {'$and': [{'projectId':projId},{'name':templateName}]})
        return records.length > 0 ? true : false
    }

    function extractTemplateParams(templateContent, language) {
        //for jinja templates, assuming {{}} as variable
        let templateParams = [], reg;
        reg = language=="VELOCITY" ? new RegExp("\\$[a-zA-Z0-9.-_]+","g") : new RegExp("{{[a-zA-Z0-9.-_ ]+}}","g");
        let matches = templateContent.match(reg);
        console.log("matching template params: "+matches!=null ? JSON.stringify(matches) : matches);
        if(matches != null) {
            matches.forEach(e => {
                let tJson = JSON.parse(JSON.stringify(TemplateConfigTemplate.TemplateDetail[0].templateParams[0]));
                tJson.parameterName = e.replace("$","").replace("{{","").replace("}}","").trim();
                tJson.id = UtilityFunctions.generate_uuid();
                templateParams.push(tJson);
            });
        }
        return templateParams;
    }

    return {
        init: function () {
            let isShowDefault = false;//if default set of templates also need to be shown, flag this to true. else we will keep it as false.
            createProjects(isShowDefault);
            createOnboardingTemplates(isShowDefault);
            createTemplateDetail(isShowDefault);
        },

        getTemplateDetails: function(urlAction) {            
            var projectName = urlAction.filter['projectName'], data = [];
            var urlParams = (urlAction.url.split('?'))[0].split('/'), templateId = urlAction.action.id;
            let { productType, productSeries, productFamily } = urlAction.filter;
            if(urlAction.filter['filterConflictingTemplates']) {
                /* /api/v1/template-programmer/template?filterConflictingTemplates=true&productFamily=Switches%20and%20Hubs
                    &productSeries=Cisco%20Catalyst%209300%20Series%20Switches&projectNames=Onboarding%20Configuration& */
                var records = SimLokiDatabaseActions.getAll('templatesConfig');
                if(projectName) {
                    records = records.filter(function(itm){ return itm.projectName == projectName; });
                }
                var ids = [], filterVal;
                for(var i=0; i<records.length; i++) {
                    ids.push(records[i].templateId);
                }
                var filteredData = [];
                var TemplateRecords = SimLokiDatabaseActions.getAll('templateDetail');                
                records.forEach(function(configTemplate){                    
                    var temp = TemplateRecords.filter(function(detailTemplate){
                        return configTemplate.name == detailTemplate.name
                    });
                    filteredData.push(temp[0]);
                })
                if (urlAction.action.count) {
                    if(urlAction.filter.productFamily) {
                        var filteredTemplates = filteredData.filter(function (template) {
                            return template.deviceTypes[0].productFamily == urlAction.filter.productFamily;
                        });
                        return { "response": filteredTemplates.length, "version": "1.0" }
                    }
                }                
                //var filteredData = SimLokiDatabaseActions.getFilteredRecordHandler('templateDetail', {'id': {'$in': ids}});
                //the order of filter check below is purposely kept from bottom order
                if(productType) {
                    filteredData = filteredData.filter(function(itm) {
                        let x = itm.deviceTypes.filter(e => {
                            return (e.productType && e.productType==productType) || 
                            (e.productType==undefined && e.productSeries && e.productSeries==productSeries) ||
                            (e.productType==undefined && e.productSeries==undefined && e.productFamily==productFamily);
                        });
                        return x.length>0;
                    });
                } else if(productSeries) {
                    filteredData = filteredData.filter(function(itm) {
                        let x = itm.deviceTypes.filter(e => {
                            return (e.productType==undefined && e.productSeries && e.productSeries==productSeries) ||
                            (e.productType==undefined && e.productSeries==undefined && e.productFamily==productFamily);
                        });
                        return x.length>0;
                    });
                } else if(productFamily) {
                    filteredData = filteredData.filter(function(itm) {
                        let x = itm.deviceTypes.filter(e => {
                            return (e.productFamily==productFamily);
                        });
                        return x.length>0;
                    });
                }

                if(urlAction.filter['tags']) {
                    filteredData = filteredData.filter(function(itm){ 
                        for(var j=0; itm.tags && j<itm.tags.length; j++) {
                            if(urlAction.filter['tags'] == (itm.tags[j])['name']) { 
                                return true; 
                            }
                        }
                        return false;
                    });
                }
                ids = [];
                for(var i=0; i<filteredData.length; i++) {
                    ids.push(filteredData[i].id);
                }
                data = SimLokiDatabaseActions.getFilteredRecordHandler('templatesConfig', {'templateId': {'$in': ids}});
                
                // /api/v1/template-programmer/v2/template?allTemplateAttributes=false&filterConflictingTemplates=true&includeApplicableTemplateOnly=false&offset=1&limit=500&productFamily=Switches%20and%20Hubs&&projectName=Onboarding%20Configuration&__preventCache=1652793526793
                if(urlAction.url.indexOf('template-programmer/v2/template') > -1){
                    data.forEach(template =>{
                        template.id = template.templateId;
                    })
                    return {"response": data, "version": "1.0"}
                }
            } else if(templateId && urlAction.service.indexOf('devices') > -1) {
                data = JSON.parse(JSON.stringify(TemplateConfigTemplate.Devices_Template_1));
                //to update data with default config values..
            } else if(templateId) {
                if(urlAction.service.indexOf('version') > -1) {
                    //api/v1/template-programmer/template/version/1e0e24c6-fcab-461e-b9e7-5b0d0a6f97eb
                    data = SimLokiDatabaseActions.getFilteredRecordHandler('templatesConfig', {'templateId': templateId});
                } else if(urlAction.service.indexOf('project') > -1) {
                    ///api/v1/template-programmer/project/271845b0-525b-615f-bf5e-1cc167980c38
                    data = SimLokiDatabaseActions.getFilteredRecordHandler('templateProject', {'id': templateId});//projectid
                    data = data.length>0?data[0]:{};
                    if(urlAction.service.indexOf('template') > -1) {
                        data = data.templates.length>0?data.templates:[];
                    }
                } else {
                    //api/v1/template-programmer/template/36cbc01c-0fba-44ed-aaa0-df3cafaaadba
                    data = SimLokiDatabaseActions.getFilteredRecordHandler('templateDetail', {'id': templateId});
                    data = data.length>0?data[0]:{};
                    if(urlAction.service.indexOf('onboarding') == -1) {
                        data = formatResponse(data);
                    }
                }
            } else if(urlAction.service.indexOf('extendedTemplates') > -1 && urlAction.filter.indexOf('id')>-1){
                data = SimLokiDatabaseActions.getFilteredRecordHandler('templateDetail', {'id': urlAction.filter['id']});
                return {"response": data, "version": "1.0"}
            }else if(urlAction.service.indexOf('extendedTemplates') > -1){
                data = SimLokiDatabaseActions.getAll('templateDetail')
                return {"response": data, "version": "1.0"}
            } else if(urlAction.service.indexOf('emptyProjects') > -1){
                return {"response": [], "version": "1.0"}
            }else {
                //api/v1/template-programmer/project
                var templates = [...SimLokiDatabaseActions.getAll('templatesConfig')];
                data = [...SimLokiDatabaseActions.getAll('templateProject')];
                for(var i=0; i<templates.length; i++) {
                    for(var j=0; j<data.length; j++) {
                        if(data[j].name == templates[i].projectName) {
                            let templateDetail = SimLokiDatabaseActions.getFilteredRecordHandler('templateDetail', {'id':templates[i].templateId});
                            templateDetail = {...templateDetail[0]};
                            var obj = {
                                'name': templates[i].name, 
                                'composite': templates[i].composite, 
                                'id': templates[i].templateId,
                                'latestVersionTime': (templates[i].versionsInfo.length>1 ? 
                                    templates[i].versionsInfo[1].versionTime : templateDetail.lastUpdateTime),
                                //'latestVersionTime':templateDetail.lastUpdateTime,
                                'lastUpdateTime': templateDetail.lastUpdateTime,
                                'customParamsOrder': templateDetail.customParamsOrder,
                                'language': templateDetail.language,
                                'documentDatabase': templateDetail.documentDatabase,
                                'projectAssociated': templateDetail.projectAssociated
                            }                
                            data[j].templates.push(obj);
                            break;
                        }                        
                    }
                }
            }
            return data
        },

        processPutRequest: function(urlAction) {
            var data, restPayload = urlAction.restPayload;
            if(urlAction.service.indexOf('preview') > -1) {
                var template = JSON.parse(JSON.stringify(SimLokiDatabaseActions.getFilteredRecordHandler('templateDetail', {'id': restPayload.templateId})));
                var replacementVarArr = template[0].templateContent.match(/\$([a-zA-Z]+)/g);
                if(replacementVarArr!=null) {
                    replacementVarArr.forEach(replaceVar => {
                        replaceVar = replaceVar.substring(1);
                        if(restPayload.params[replaceVar])
                            template[0].templateContent = template[0].templateContent.replace('$'+replaceVar,restPayload.params[replaceVar]);
                    });
                }
                //template[0].templateContent = template[0].templateContent.replace('$hostname',restPayload.params['hostname']);
                data = {'templateId':template[0].id, 'cliPreview':template[0].templateContent, 'deviceId':null, 'validationErrors':[]};
            } else if(urlAction.service.indexOf('project') > -1 && urlAction.service.indexOf('template') == -1) {
                //update project
                var progress = "Successfully updated project with name " + restPayload.name;
                var obj = {'id':restPayload.id, 'progress': progress};
                var resultObj = SimTaskDataGenerator.createTask('TemplateService', obj);
                updateProjectDetails(restPayload);
                SimTaskDataGenerator.updateTaskCompletion(resultObj.taskId, {isError: false});
                resultObj.statusCode = 202;
                return {"response":resultObj, "version":"1.0", statusCode:202};
            } else {
                //update template
                var progress = "Successfully updated template with name " + restPayload.name;
                var obj = {'id':restPayload.id, 'progress': progress};
                var resultObj = SimTaskDataGenerator.createTask('TemplateService', obj);

                var template = SimLokiDatabaseActions.getFilteredRecordHandler('templateDetail', {'id': restPayload.id});
                template[0].description = restPayload.description == undefined ? "" : restPayload.description;
                template[0].tags = restPayload.tags == undefined ? [] : restPayload.tags;
                template[0].language = restPayload.language;
                template[0].deviceTypes = restPayload.deviceTypes;
                template[0].softwareType = restPayload.softwareType;
                template[0].composite = restPayload.composite == undefined ? false : restPayload.composite;
                template[0].containingTemplates = restPayload.containingTemplates;
                template[0].templateContent = restPayload.templateContent;
                //template[0].templateParams = restPayload.templateParams;
                template[0].templateParams = extractTemplateParams(restPayload.templateContent, restPayload.language);
                template[0].customParamsOrder =  restPayload.customParamsOrder != undefined ? restPayload.customParamsOrder : false;
                template[0].lastUpdateTime = UtilityFunctions.getTimeStamp();
                SimLokiDatabaseActions.update('templateDetail', template);//this is for Local/Edit version

                var newTemplate = JSON.parse(JSON.stringify(template[0]));
                delete newTemplate['$loki'];
                delete newTemplate.meta;
                newTemplate.id = UtilityFunctions.generate_uuid();
                newTemplate.validationErrors.templateId = newTemplate.id;
                // SimLokiDatabaseActions.insert('templateDetail', newTemplate);//this is for commit version

                var templateConfig = SimLokiDatabaseActions.getFilteredRecordHandler('templatesConfig', {'templateId': restPayload.id});
                var currTime = UtilityFunctions.getTimeStamp()-1000, version = 1;
                if(templateConfig[0].versionsInfo.length>1) {
                    version = parseInt(templateConfig[0].versionsInfo[1].version) + 1;
                }
                obj = {'id': newTemplate.id, 'description': '', 'author': 'admin', 'version': version.toString(), 
                    'versionComment': '', 'versionTime': currTime };
                templateConfig[0].versionsInfo.splice(1,0,obj);
                SimLokiDatabaseActions.update('templatesConfig', templateConfig);
                
                SimTaskDataGenerator.updateTaskCompletion(resultObj.taskId, {isError: false});
                resultObj.statusCode = 202;
                return {"response":resultObj, "version":"1.0", statusCode:202};
            }
            return data;
        },

        createProject: function(urlAction, id) {
            var restPayload = urlAction.restPayload;
            var progress = "Successfully created project with name " + restPayload.name;
            if(id == undefined) id = UtilityFunctions.generate_uuid();
            var obj = {'id':id, 'progress': restPayload.name};
            var resultObj = SimTaskDataGenerator.createTask('TemplateService', obj);
            var projTemplate = JSON.parse(JSON.stringify(TemplateConfigTemplate.Template_Congig_Template[0]));
            projTemplate.name = restPayload.name;
            if(restPayload.description) {
                projTemplate.description = restPayload.description;
            }
            projTemplate.id = id;
            projTemplate.isDeletable = true;
            SimLokiDatabaseActions.insert('templateProject', projTemplate);

            SimTaskDataGenerator.updateTaskCompletion(resultObj.taskId, {isError: false});
            resultObj.statusCode = 202;
            return {"response":resultObj, "version":"1.0", statusCode:202};
        },

        createTemplate: function(urlAction, isCloneReq, refTmpltId) {
            //if creation of template is from cloning, isCloneReq should be true and the reference clone templateId should be passed in refTmpltId
            var restPayload = urlAction.restPayload;
            var progress = "Successfully created template with name " + restPayload.name;
            var id = UtilityFunctions.generate_uuid(), projId = urlAction.action.id;

            var isExists = isTemplateExistInProj(projId, restPayload.name);
            if(isExists) {
                var progress = isCloneReq ? 'Going to clone template with id '+refTmpltId : "Cannot create template with name "+restPayload.name;
                var resultObj = SimTaskDataGenerator.createTask('TemplateService', {'progress': progress});
                SimTaskDataGenerator.updateTaskCompletion(resultObj.taskId, {
                    'isError': true, 'failureReason': 'NCTP10003: ' + restPayload.name + ' Template already exists', 'errorCode': 'NCTP10003'});
                resultObj.statusCode = 202;
                return {"response":resultObj, "version":"1.0"};
            }

            var obj = {'id':id, 'progress': progress}, currTime = UtilityFunctions.getTimeStamp();
            var resultObj = SimTaskDataGenerator.createTask('TemplateService', obj);
            var proj = SimLokiDatabaseActions.getFilteredRecordHandler('templateProject', {'id': projId});

            var templateConfig = JSON.parse(JSON.stringify(TemplateConfigTemplate.Onboarding_Config_Template[0]));
            templateConfig.name = restPayload.name;
            templateConfig.templateId = id;
            templateConfig.versionsInfo.splice(1,1);
            templateConfig.versionsInfo[0].id = id;
            templateConfig.projectId = proj[0].id;
            templateConfig.projectName = proj[0].name;
            templateConfig.latestVersionTime = 0;
            SimLokiDatabaseActions.insert('templatesConfig', templateConfig);

            var templateDtl = JSON.parse(JSON.stringify(TemplateConfigTemplate.TemplateDetail[0]));
            templateDtl.name = restPayload.name;
            templateDtl.description = restPayload.description;
            templateDtl.tags = restPayload.tags;
            templateDtl.language = restPayload.language;
            templateDtl.deviceTypes = restPayload.deviceTypes;
            templateDtl.softwareType = restPayload.softwareType;
            templateDtl.templateParams = [];
            templateDtl.composite = restPayload.composite;
            templateDtl.containingTemplates = restPayload.containingTemplates;
            templateDtl.id = id;
            templateDtl.validationErrors.templateId = id;
            templateDtl.createTime = currTime;
            templateDtl.lastUpdateTime = currTime;
            templateDtl.projectName = proj[0].name;
            templateDtl.projectId = proj[0].id;
            templateDtl.parentTemplateId = id;
            if(restPayload.softwareVariant != undefined) {
                templateDtl.softwareVariant = restPayload.softwareVariant;
            } else {
                var sfType = restPayload.softwareType;
                sfType = sfType.split('-');
                sfType.splice(0,1);
                if(sfType.length>0) {
                    templateDtl.softwareVariant = sfType.join('-');
                }
            }
            if(restPayload.softwareVersion != undefined) {
                templateDtl.softwareVersion = restPayload.softwareVersion;
            }
            if(restPayload.templateContent != undefined) {
                templateDtl.templateContent = restPayload.templateContent;
                templateDtl.templateParams = extractTemplateParams(restPayload.templateContent, restPayload.language);
            }
            SimLokiDatabaseActions.insert('templateDetail', templateDtl);

            SimTaskDataGenerator.updateTaskCompletion(resultObj.taskId, {isError: false});
            resultObj.statusCode = 202;
            return {"response":resultObj, "version":"1.0", statusCode:202};
        },

        commit: function(urlAction, isImport) {
            var restPayload = urlAction.restPayload;
            var templateConfig = SimLokiDatabaseActions.getFilteredRecordHandler('templatesConfig', {'templateId':restPayload.templateId});
            console.log("version info count: " + templateConfig[0].versionsInfo.length);
            if(templateConfig[0].versionsInfo.length==1) {
                //save should have been done before this n ideally shouldnt enter here
                templateConfig[0].versionsInfo.push(templateConfig[0].versionsInfo[0]);
                templateConfig[0].versionsInfo[1].version = 1;
                templateConfig[0].versionsInfo[1].author = isImport ? "SYSTEM": "admin";
                templateConfig[0].versionsInfo[1].versionComment = "";
            }
            templateConfig[0].versionsInfo[1].versionTime = UtilityFunctions.getTimeStamp();
            var version = templateConfig[0].versionsInfo[1].version;
            var obj = { 'progress': "Successfully versioned template to version " + version };
            var resultObj = SimTaskDataGenerator.createTask('TemplateService', obj);
            if(isImport) {
                templateConfig[0].versionsInfo[1].versionComment = "Imported Templates";
                SimLokiDatabaseActions.update('templatesConfig', templateConfig);
            } else if(restPayload.comments) {
                templateConfig[0].versionsInfo[1].versionComment = restPayload.comments;
                SimLokiDatabaseActions.update('templatesConfig', templateConfig);
            }

            SimTaskDataGenerator.updateTaskCompletion(resultObj.taskId, {isError: false});
            resultObj.statusCode = 202;
            return {"response":resultObj, "version":"1.0", statusCode:202};
        },

        deleteTemplate: function(id) {
            var obj = { 'progress': "Successfully deleted template with id " + id };
            var resultObj = SimTaskDataGenerator.createTask('TemplateService', obj);
            var records = SimLokiDatabaseActions.getFilteredRecordHandler('templateDetail', {'parentTemplateId': id});
            SimLokiDatabaseActions.delete('templateDetail',records);
            records = SimLokiDatabaseActions.getFilteredRecordHandler('templatesConfig', {'templateId': id});
            SimLokiDatabaseActions.delete('templatesConfig',records);
            SimTaskDataGenerator.updateTaskCompletion(resultObj.taskId, {isError: false});
            resultObj.statusCode = 202;
            return {"response":resultObj, "version":"1.0", statusCode:202};
        },

        deleteProject: function(id) {
            var obj = { 'progress': "Successfully deleted project with id " + id };
            var resultObj = SimTaskDataGenerator.createTask('TemplateService', obj);
            var records = SimLokiDatabaseActions.getFilteredRecordHandler('templateDetail', {'projectId': id});
            SimLokiDatabaseActions.delete('templateDetail',records);
            records = SimLokiDatabaseActions.getFilteredRecordHandler('templatesConfig', {'projectId': id});
            SimLokiDatabaseActions.delete('templatesConfig',records);
            records = SimLokiDatabaseActions.getFilteredRecordHandler('templateProject', {'id': id});
            SimLokiDatabaseActions.delete('templateProject',records);
            SimTaskDataGenerator.updateTaskCompletion(resultObj.taskId, {isError: false});
            resultObj.statusCode = 202;
            return {"response":resultObj, "version":"1.0", statusCode:202};
        },

        exportProjects: function(urlAction) {
            let isSpecific = urlAction.service.indexOf('exportspecifictemplates') > -1;
            let projIds = isSpecific ? Object.keys(urlAction.restPayload) : urlAction.restPayload;
            var projects = SimLokiDatabaseActions.getFilteredRecordHandler('templateProject', {'id': {'$in':projIds} });
            var exportedData = [];
            projects.forEach(proj => {
                var obj = { "name": proj.name, "description":proj.description, "tags":[], "templates":[], "isDeletable":proj.isDeletable };
                var templateConfig = SimLokiDatabaseActions.getFilteredRecordHandler('templatesConfig', {'projectId':proj.id}); 
                templateConfig.forEach(templateOverview => {
                    var templates = SimLokiDatabaseActions.getFilteredRecordHandler('templateDetail',{'id':templateOverview.templateId});
                    templates.forEach(template => {
                        if(isSpecific && (urlAction.restPayload[proj.id]).indexOf(template.id) == -1) return;//skip
                        template = JSON.parse(JSON.stringify(template));
                        delete template.id; delete template.createTime; delete template.lastUpdateTime; 
                        delete template.projectId; delete template.parentTemplateId;
                        delete template.meta; delete template['$loki'];
                        obj.templates.push(template);
                    })
                });                
                exportedData.push(obj);
            });
            
            var obj = { 'progress': "Successfully Exported Project(s)", 'data': JSON.stringify(exportedData) };
            var resultObj = SimTaskDataGenerator.createTask('TemplateService', obj);
            SimTaskDataGenerator.updateTaskCompletion(resultObj.taskId, {isError: false});
            resultObj.statusCode = 202;
            return {"response":resultObj, "version":"1.0"};
        },

        exportTemplates: function(urlAction) {
            var templates = SimLokiDatabaseActions.getFilteredRecordHandler('templateDetail', {'id': {'$in':urlAction.restPayload} });
            var exportedData = [];
            templates.forEach(template => {
                template = JSON.parse(JSON.stringify(template));
                delete template.id; delete template.createTime; delete template.lastUpdateTime;
                delete template.meta; delete template['$loki'];
                exportedData.push(template);
            });                
            
            var obj = { 'progress': "Successfully Exported Template(s)", 'data': JSON.stringify(exportedData) };
            var resultObj = SimTaskDataGenerator.createTask('TemplateService', obj);
            SimTaskDataGenerator.updateTaskCompletion(resultObj.taskId, {isError: false});
            resultObj.statusCode = 202;
            return {"response":resultObj, "version":"1.0"};
        },

        importProjects: function(urlAction) {
            var doVersion = urlAction.filter['doVersion']=="false" ? false : true;
            var errorProjs = [];
            var restPayload = JSON.parse(JSON.stringify(urlAction.restPayload));
            var finalProjIds = [];
            restPayload.forEach(proj => {
                var mandatoryUnfilled = SimulationUtility.validateFields(proj, {'name':'string'});
                if(mandatoryUnfilled.length>0) {
                    console.log('Mandatory fields missing for project: '+proj.name+'::'+UtilityFunctions.convertArrayToCommaSeperated(mandatoryUnfilled));
                    errorProjs.push(proj.name);
                    return;//go for next template
                }
                var project = SimLokiDatabaseActions.getFilteredRecordHandler('templateProject', {'name':proj.name});
                if(!doVersion && project.length>0) {
                    console.log('Overwriting of proj not allowed: '+proj.name);
                    errorProjs.push(proj.name);
                    return;//go for next template
                }
                var projId;
                if(project.length > 0) {
                    projId = project[0].id;
                    this.deleteProject(project[0].id);
                }
                var tempProjPayload = {'name':proj.name}
                if(proj.description) tempProjPayload.description = proj.description;
                urlAction.restPayload = tempProjPayload;
                this.createProject(urlAction, projId);
                var records = SimLokiDatabaseActions.getFilteredRecordHandler('templateProject', {'name':proj.name});
                finalProjIds.push(records[0].id);
                if(proj.templates && proj.templates.length>0) {
                    var tempTemplatePayload = JSON.parse(JSON.stringify(proj.templates));
                    tempTemplatePayload = tempTemplatePayload.map((e, i) => { return { ...e, 'projectId':records[0].id } });
                    urlAction.restPayload = tempTemplatePayload;
                    urlAction.action.id = records[0].id;
                    var templateImportResp = this.importTemplates(urlAction);
                    if(templateImportResp.simulationErrCheck) {
                        errorProjs.push(proj.name);
                    }
                }
            });
            var errorMsg = '';
            if(errorProjs.length > 0) {
                errorProjs = UtilityFunctions.convertArrayToCommaSeperated(errorProjs);
                errorMsg = 'NCTP10055: Failed to import Project(s): '+errorProjs;
            }
            if(errorMsg != '') {
                var resultObj = SimTaskDataGenerator.createTask('TemplateService', {'progress': 'Import Project(s)', 'data':'[]'});
                SimTaskDataGenerator.updateTaskCompletion(resultObj.taskId, {
                    'isError': true, 'failureReason': errorMsg, 'errorCode': 'NCTP10055'});
                resultObj.statusCode = 202;
                return {"response":resultObj, "version":"1.0"};
            } else {
                var data = SimLokiDatabaseActions.getFilteredRecordHandler('templateProject', {'id':{'$in':finalProjIds}});
                data = JSON.parse(JSON.stringify(data));
                data.forEach(proj => {
                    //delete proj['$loki']; delete proj.meta;
                    var mapping = SimLokiDatabaseActions.getFilteredRecordHandler('templatesConfig', {'projectId':proj.id});
                    mapping = mapping.map(e => e.templateId );
                    var templates = SimLokiDatabaseActions.getFilteredRecordHandler('templateDetail', {'id':{'$in':mapping}});
                    /* templates = [...templates];
                    templates.forEach(template => {
                        delete template['$loki']; delete template.meta;
                    }); */
                    proj.templates = templates;
                });
                data = JSON.stringify(data);
                var obj = {'data':data, 'progress': "Successfully imported Proj(s)"};
                var resultObj = SimTaskDataGenerator.createTask('TemplateService', obj);
                SimTaskDataGenerator.updateTaskCompletion(resultObj.taskId, {isError: false});
                resultObj.statusCode = 202;
                return {"response":resultObj, "version":"1.0"};
            } 
        },

        importTemplates: function(urlAction) {
            var doVersion = urlAction.filter['doVersion']=="false" ? false : true;
            var errorTemplates = [];
            var restPayload = JSON.parse(JSON.stringify(urlAction.restPayload));
            var finalTemplateIds = [];
            restPayload.forEach(template => {
                var mandatoryUnfilled = SimulationUtility.validateFields(template, {'name':'string', 'deviceTypes':'array', 'softwareType':'string', 'language':'string', 'projectId':'string'});
                if(mandatoryUnfilled.length>0) {
                    console.log('Mandatory fields missing for template: '+template.name+'::'+UtilityFunctions.convertArrayToCommaSeperated(mandatoryUnfilled));
                    errorTemplates.push(template.name);
                    return;//go for next template
                }
                var project = SimLokiDatabaseActions.getFilteredRecordHandler('templateProject', {'id':template.projectId});
                //projectId will be auto added from UI as we select the project and then perform import.
                var templateRec = SimLokiDatabaseActions.getFilteredRecordHandler('templatesConfig', {'$and':[{'projectId':template.projectId},
                    {'name':template.name}] });
                if(!doVersion && templateRec.length>0) {
                    console.log('Versioning of template not allowed: '+template.name);
                    errorTemplates.push(template.name);
                    return;//go for next template
                }
                if(templateRec.length>0) {
                    //template exists. Go for PUT eqvt
                    template.id = templateRec[0].id;
                    urlAction.restPayload = template;
                    this.processPutRequest(urlAction);                    
                } else {
                    //template doesnt exist. Go for POST eqvt.
                    urlAction.restPayload = template;
                    this.createTemplate(urlAction);
                    templateRec = SimLokiDatabaseActions.getFilteredRecordHandler('templatesConfig', {'$and':[{'projectId':template.projectId},
                    {'name':template.name}] });
                    
                    //in case this was called from import project section, urlAction needs little modification to handle commit eqvt;
                    var modUrlAction = JSON.parse(JSON.stringify(urlAction));
                    if(urlAction.service.indexOf('template') == -1) { modUrlAction.service.push('template')}
                    modUrlAction.restPayload.id = templateRec[0].templateId;
                    modUrlAction.restPayload.templateId = templateRec[0].templateId;
                    this.processPutRequest(modUrlAction);
                    this.commit(modUrlAction, true);
                    templateRec = SimLokiDatabaseActions.getFilteredRecordHandler('templatesConfig', {'$and':[{'projectId':template.projectId},
                    {'name':template.name}] });
                }
                finalTemplateIds.push(templateRec[0].templateId);
            });

            var errorMsg = '';
            if(errorTemplates.length > 0) {
                var errTemplates = UtilityFunctions.convertArrayToCommaSeperated(errorTemplates);
                errorMsg = 'NCTP10056: Failed to import Template(s): ' + errTemplates;
            }
            if(errorMsg != '') {
                var resultObj = SimTaskDataGenerator.createTask('TemplateService', {'progress': 'Import template(s)', 'data':'[]'});
                SimTaskDataGenerator.updateTaskCompletion(resultObj.taskId, {
                    'isError': true, 'failureReason': errorMsg, 'errorCode': 'NCTP10056' });
                resultObj.statusCode = 202;
                return {"response":resultObj, "version":"1.0", "simulationErrCheck":true};
            } else {
                var data = SimLokiDatabaseActions.getFilteredRecordHandler('templateDetail', {'id':{'$in':finalTemplateIds}});
                data = JSON.parse(JSON.stringify(data));
                /* data.forEach(template => {
                    delete template['$loki']; delete template.meta;
                }); */
                data = JSON.stringify(data);
                var obj = {'data':data, 'progress': "Successfully imported Template(s)"};
                var resultObj = SimTaskDataGenerator.createTask('TemplateService', obj);
                SimTaskDataGenerator.updateTaskCompletion(resultObj.taskId, {isError: false});
                resultObj.statusCode = 202;
                return {"response":resultObj, "version":"1.0", "simulationErrCheck":false};
            }
        },

        cloneTemplate: function(urlAction) {
            var urlParams = (urlAction.url.split('?'))[0].split('/');
            var refProjId, refTmpltId, destProjId, destTmpltName, idx;
            destTmpltName = urlAction.restPayload.name;
            refProjId = urlAction.restPayload.projectId;
            idx = urlParams.indexOf('template');
            refTmpltId = urlParams[idx+1];
            destProjId = urlAction.filter['projectId']

            var records = SimLokiDatabaseActions.getFilteredRecordHandler('templatesConfig', {'projectId':destProjId});
            var template = SimLokiDatabaseActions.getFilteredRecordHandler('templateDetail', {'id': refTmpltId });
            template =JSON.parse(JSON.stringify(template[0]));
            template.projectId = destProjId;
            template.projectName = records[0].projectName;
            template.name = destTmpltName;
            delete template.id; delete template.createTime; delete template.lastUpdateTime; delete template.parentTemplateId;
            delete template.meta; delete template['$loki'];

            urlAction.action.id = destProjId, urlAction.restPayload = template;
            return this.createTemplate(urlAction, true, refTmpltId)
        },

        getCompatibleDevices:function(restPayload) {
            let devId = Object.keys(restPayload.deviceTypeAndIds)[0];
            let device = SimLokiDatabaseActions.getFilteredRecordHandler('network-device', {'id':devId});
            return [{
                "templateId":restPayload.templateId, 
                "deviceId":devId, 
                "id":restPayload.templateId+'#'+devId,
                "categories":["deviceType"],
                "deviceDto":device[0],
                "compatible":true
            }];
        }

    }
});