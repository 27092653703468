define({
"Device_Config_Status_Template" : {

    "namespace":"",
     "status":"",
     "cfsVersion":"",
     "provisioningData":{},
     "deviceName":"",
     "deviceType":"",
     "deploymentErrors":"",
     "deviceConfig":"MBC CONFIG PUSH SUCCESS FOR THE DEVICE WITH DEVICEID 96096",
     "deviceId":"",
     "createTime":"",
     "isLatest":true,
     "lastSuccessfulVersion":"",
     "lastUpdateTime":"",
     "instanceUuid":"",
     "id":"",
     "aggregatedStatus":"Not Provisioned",
     "lastUpdateTime":"",
     "consolidatedErrors":"",
     "errorMessage":"",
     "provisionStatus":"",
     "credentialStatus": "Not Provisioned",
     "appName": "Intelligent Capture Automation",
     "__typename":"LatestProvisionStatusStore",
     "provisioningFeature":"Device Controllability And Telemetry"
      
      }
});
