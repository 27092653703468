define({
  "SiteAnalytics_Template":[
    {
      "slaType": "CLIENT_V2",
      "values": {
        "nestedData": [
          {
            "slaKpiType": "CONNECTION_SPEED",
            "values": {
              "nestedData": [
                {
                  "values": {
                    "siteName": "SJC01",
                    "kpiPct": 71,
                    "siteType": "floor"
                  },
                  "siteId": "50bfa92f-5716-4ffb-b86b-c0c3a4a97be8"
                }
              ]
            }
          },
          {
            "slaKpiType": "COVERAGE",
            "values": {
              "nestedData": [
                {
                  "values": {
                    "siteName": "SJC01",
                    "kpiPct": 79,
                    "siteType": "floor"
                  },
                  "siteId": "50bfa92f-5716-4ffb-b86b-c0c3a4a97be8"
                }
              ]
            }
          },
          /*{
            "slaKpiType": "ONBOARDING",
            "values": {
              "nestedData": [
                {
                  "values": {
                    "siteName": "SJC01",
                    "kpiPct": 24,
                    "siteType": "floor"
                  },
                  "siteId": "e8ff3416-d703-4554-9ed4-73deed00efca"
                }
              ]
            } 
          },
          {
            "slaKpiType": "ROAMING",
            "values": {
              "nestedData": [
                {
                  "values": {
                    "siteName": "SJC01",
                    "kpiPct": 62,
                    "siteType": "floor"
                  },
                  "siteId": "e8ff3416-d703-4554-9ed4-73deed00efca"
                }
              ]
            }
          }*/
          {
            "slaKpiType": "ONBOARDING_ATTEMPTS",
            "values": {
              "nestedData": [
                {
                  "values": {
                    "siteName": "San Jose",
                    "kpiPct": 24,
                    "siteType": "floor"
                  },
                  "siteId": "972ffb67-2cd3-48ab-94c5-6aba2ba90fd2"
                }
              ]
            }
          },
          {
            "slaKpiType": "ONBOARDING_DURATION",
            "values": {
              "nestedData": [
                {
                  "values": {
                    "siteName": "SJC01",
                    "kpiPct": 24,
                    "siteType": "floor"
                  },
                  "siteId": "e8ff3416-d703-4554-9ed4-73deed00efca"
                }
              ]
            }
          },
          {
            "slaKpiType": "ROAMING_ATTEMPTS",
            "values": {
              "nestedData": [
                {
                  "values": {
                    "siteName": "SJC01",
                    "kpiPct": 62,
                    "siteType": "floor"
                  },
                  "siteId": "972ffb67-2cd3-48ab-94c5-6aba2ba90fd2"
                }
              ]
            }
          },
          {
            "slaKpiType": "ROAMING_DURATION",
            "values": {
              "nestedData": [
                {
                  "values": {
                    "siteName": "SJC01",
                    "kpiPct": 62,
                    "siteType": "floor"
                  },
                  "siteId": "972ffb67-2cd3-48ab-94c5-6aba2ba90fd2"
                }
              ]
            }
          }
        ]
      }
    }
  ],
  "config_slaThreshold_entityType_SITE_PCT":[
    {
      "slaType": "COVERAGE",
      "values": {
        "kpiThreshold": -72,
        "sla": "COVERAGE"
      }
    },
    /*{
      "slaType": "ROAMING",
      "values": {
        "kpiThreshold": 3000,
        "sla": "ROAMING"
      }
    },*/
    {
      "slaType": "ROAMING_ATTEMPTS",
      "values": {
        "kpiThreshold": 3000,
        "sla": "ROAMING_ATTEMPTS"
      }
    },

    {
      "slaType": "ROAMING_DURATION",
      "values": {
        "kpiThreshold": 3000,
        "sla": "ROAMING_DURATION"
      }
    },
    {
      "slaType": "CONNECTION_SPEED",
      "values": {
        "kpiThreshold": 11,
        "sla": "CONNECTION_SPEED"
      }
    },
    /*{
      "slaType": "ONBOARDING",
      "values": {
        "kpiThreshold": 10000,
        "sla": "ONBOARDING"
      }
    },*/
    {
      "slaType": "ONBOARDING_ATTEMPTS",
      "values": {
        "kpiThreshold": 10000,
        "sla": "ONBOARDING_ATTEMPTS"
      }
    },
    {
      "slaType": "ONBOARDING_DURATION",
      "values": {
        "kpiThreshold": 10000,
        "sla": "ONBOARDING_DURATION"
      }
    },
    {
      "slaType": "SITE_PCT",
      "values": {
        "kpiThreshold": 85,
        "lastUpdateTimestamp": "2022-06-25T00:29:00.688668+00:00",
        "prevKpiThreshold": 80,
        "sla": "SITE_PCT"
      }
    }
  ],
  "slaKpiType_ONBOARDING_Template":[
    {
      "values": {
        "nestedData": [
          {
            "slaType": "CLIENT",
            "values": {
              "nestedData": [
               /* {
                  "slaKpiType": "ONBOARDING",
                  "values": {
                    "metSlaAttempts": "24",
                    "totalAttempts": "47",
                    "kpiPct": 51
                  }
                },*/
                {
                  "slaKpiType": "ONBOARDING_ATTEMPTS",
                  "values": {
                    "metSlaAttempts": "24",
                    "totalAttempts": "47",
                    "kpiPct": 51
                  }
                },
                {
                  "slaKpiType": "ONBOARDING_DURATION",
                  "values": {
                    "metSlaAttempts": "24",
                    "totalAttempts": "47",
                    "kpiPct": 51
                  }
                },
                {
                  "slaKpiType": "COVERAGE",
                  "values": {}
                },
                
               /* {
                  "slaKpiType": "ROAMING",
                  "values": {}
                },*/
                {
                  "slaKpiType": "ROAMING_ATTEMPTS",
                  "values": {}
                },
                {
                  "slaKpiType": "ROAMING_DURATION",
                  "values": {}
                },
                {
                  "slaKpiType": "CONNECTION_SPEED",
                  "values": {}
                }
              ]
            }
          }
        ]
      },
      "time": "2022-10-18T04:00:00.000+0000"
    }
  ],
  "slaKpiType_CONNECTION_SPEED_Template":[
    {
      "values": {
        "nestedData": [
          {
            "slaType": "CLIENT",
            "values": {
              "nestedData": [
                {
                  "slaKpiType": "CONNECTION_SPEED",
                  "values": {
                    "metSlaAttempts": "178",
                    "kpiValue": 184,
                    "totalAttempts": "237",
                    "kpiPct": 75
                  }
                },
                {
                  "slaKpiType": "COVERAGE",
                  "values": {}
                },
               /* {
                  "slaKpiType": "ONBOARDING",
                  "values": {}
                },
                {
                  "slaKpiType": "ROAMING",
                  "values": {}
                }*/
                {
                  "slaKpiType": "ONBOARDING_ATTEMPTS",
                  "values": {}
                },
                {
                  "slaKpiType": "ONBOARDING_DURATION",
                  "values": {}
                },
                {
                  "slaKpiType": "ROAMING_ATTEMPTS",
                  "values": {}
                },
                {
                  "slaKpiType": "ROAMING_DURATION",
                  "values": {}
                }
              ]
            }
          }
        ]
      },
      "time": "2022-10-17T15:30:00.000+0000"
    }
  ],
 /* "slaKpiType_ROAMING_Template":[
    {
      "values": {
        "nestedData": [
          {
            "slaType": "CLIENT",
            "values": {
              "nestedData": [
                {
                  "slaKpiType": "ROAMING",
                  "values": {
                    "metSlaAttempts": "6",
                    "totalAttempts": "8",
                    "kpiPct": 75
                  }
                },
                {
                  "slaKpiType": "COVERAGE",
                  "values": {}
                },
                {
                  "slaKpiType": "ONBOARDING",
                  "values": {}
                },
                {
                  "slaKpiType": "CONNECTION_SPEED",
                  "values": {}
                }
              ]
            }
          }
        ]
      },
      "time": "2022-10-18T04:00:00.000+0000"
    }
  ],*/
  "slaKpiType_ROAMING_ATTEMPTS_Template":[
    {
      "values": {
        "nestedData": [
          {
            "slaType": "CLIENT",
            "values": {
              "nestedData": [
                {
                  "slaKpiType": "ROAMING_ATTEMPTS",
                  "values": {
                    "metSlaAttempts": "6",
                    "totalAttempts": "8",
                    "kpiPct": 75
                  }
                },

               
                {
                  "slaKpiType": "ROAMING_DURATION",
                  "values": {
                    "metSlaAttempts": "6",
                    "totalAttempts": "8",
                    "kpiPct": 75
                  }
                },

                {
                  "slaKpiType": "COVERAGE",
                  "values": {}
                },
                {
                  "slaKpiType": "ONBOARDING_ATTEMPTS",
                  "values": {}
                },
                {
                  "slaKpiType": "ONBOARDING_DURATION",
                  "values": {}
                },
                {
                  "slaKpiType": "CONNECTION_SPEED",
                  "values": {}
                }
              ]
            }
          }
        ]
      },
      "time": "2022-10-18T04:00:00.000+0000"
    }
  ],
  "slaKpiType_ROAMING_DURATION_Template":[
    {
      "values": {
        "nestedData": [
          {
            "slaType": "CLIENT",
            "values": {
              "nestedData": [
                {
                  "slaKpiType": "ROAMING_ATTEMPTS",
                  "values": {
                    "metSlaAttempts": "6",
                    "totalAttempts": "8",
                    "kpiPct": 75
                  }
                },
                {
                  "slaKpiType": "ROAMING_DURATION",
                  "values": {
                    "metSlaAttempts": "6",
                    "totalAttempts": "8",
                    "kpiPct": 75
                  }
                },
                {
                  "slaKpiType": "COVERAGE",
                  "values": {}
                },
                {
                  "slaKpiType": "ONBOARDING_ATTEMPTS",
                  "values": {}
                },
                {
                  "slaKpiType": "ONBOARDING_DURATION",
                  "values": {}
                },
                {
                  "slaKpiType": "CONNECTION_SPEED",
                  "values": {}
                }
              ]
            }
          }
        ]
      },
      "time": "2022-10-18T04:00:00.000+0000"
    }
  ],
  "slaKpiType_COVERAGE_Template":[
    {
      "values": {
        "nestedData": [
          {
            "slaType": "CLIENT",
            "values": {
              "nestedData": [
                {
                  "slaKpiType": "COVERAGE",
                  "values": {
                    "metSlaAttempts": "32",
                    "kpiValue": -56,
                    "totalAttempts": "37",
                    "kpiPct": 86
                  }
                },
                /*{
                  "slaKpiType": "ONBOARDING",
                  "values": {}
                },
                {
                  "slaKpiType": "ROAMING",
                  "values": {}
                },*/
                {
                  "slaKpiType": "ONBOARDING_ATTEMPTS",
                  "values": {}
                },
                {
                  "slaKpiType": "ONBOARDING_DURATION",
                  "values": {}
                },
                {
                  "slaKpiType": "ROAMING_ATTEMPTS",
                  "values": {}
                },
                {
                  "slaKpiType": "ROAMING_DURATION",
                  "values": {}
                },
                {
                  "slaKpiType": "CONNECTION_SPEED",
                  "values": {}
                }
              ]
            }
          }
        ]
      },
      "time": "2022-10-17T16:00:00.000+0000"
    }
  ],
  "Site_Data_Template":[
    {
      "siteId": "80b4fcb8-6f56-4986-b520-3b6ce972464a",
      "values": {
        "coverageSlaPercentage": 79,
        "coverageMetSlaCount": "2919",
        "coverageTotalCount": "3090",
        "onboardingSlaPercentage": 24,
        "onboardingMetSlaCount": "4124",
        "onboardingTotalCount": "9413",
        "roamingSlaPercentage": 62,
        "roamingMetSlaCount": "532",
        "roamingTotalCount": "630",
        "connectionSpeedSlaPercentage": 71,
        "connectionSpeedMetSlaCount": "25638",
        "connectionSpeedTotalCount": "29932",
        "roamingAttemptsSlaPercentage": 99, 
        "roamingDurationSlaPercentage": 96,
        "onboardingAttemptsSlaPercentage": 97,
        "onboardingDurationSlaPercentage": 95,
      }
    }
  ],
  "Site_AnalyticsCollection_Template":[
    {
      "version": "2.0",
      "data": [
        {
          "ap": "SCJ01_9136_1",
          "values": {
            "notMetSlaCount": "1173",
            "notMetSlaPercentage": 26,
            "totalCount": "4588"
          }
        }
      ],
      "page": null,
      "scope": null,
      "metadata": [
        {
          "keyName": "ap",
          "dataType": "string"
        },
        {
          "keyName": "notMetSlaCount",
          "dataType": "int"
        },
        {
          "keyName": "notMetSlaPercentage",
          "dataType": "int"
        },
        {
          "keyName": "totalCount",
          "dataType": "int"
        }
      ],
      "instrumentation": null,
      "message": null
    },
    {
      "version": "2.0",
      "data": [
        {
          "deviceType": "Devices",
          "values": {
            "notMetSlaCount": "4588",
            "notMetSlaPercentage": 100,
            "totalCount": "4588"
          }
        }
      ],
      "page": null,
      "scope": null,
      "metadata": [
        {
          "keyName": "deviceType",
          "dataType": "string"
        },
        {
          "keyName": "notMetSlaCount",
          "dataType": "int"
        },
        {
          "keyName": "notMetSlaPercentage",
          "dataType": "int"
        },
        {
          "keyName": "totalCount",
          "dataType": "int"
        }
      ],
      "instrumentation": null,
      "message": null
    },
    {
      "version": "2.0",
      "data": [
        {
          "floor": "Flr-SJC1-1",
          "values": {
            "floorId": "50bfa92f-5716-4ffb-b86b-c0c3a4a97be8",
            "notMetSlaCount": "2360",
            "notMetSlaPercentage": 51,
            "totalCount": "4588"
          }
        }
      ],
      "page": null,
      "scope": null,
      "metadata": [
        {
          "keyName": "floor",
          "dataType": "string"
        },
        {
          "keyName": "notMetSlaCount",
          "dataType": "int"
        },
        {
          "keyName": "notMetSlaPercentage",
          "dataType": "int"
        },
        {
          "keyName": "totalCount",
          "dataType": "int"
        }
      ],
      "instrumentation": null,
      "message": null
    },
    {
      "version": "2.0",
      "data": [
        {
          "frequency": "5 GHz",
          "values": {
            "notMetSlaCount": "4244",
            "notMetSlaPercentage": 93,
            "totalCount": "4588"
          }
        },
        {
          "frequency": "2.4 GHz",
          "values": {
            "notMetSlaCount": "344",
            "notMetSlaPercentage": 7,
            "totalCount": "4588"
          }
        }
      ],
      "page": null,
      "scope": null,
      "metadata": [
        {
          "keyName": "frequency",
          "dataType": "string"
        },
        {
          "keyName": "notMetSlaCount",
          "dataType": "int"
        },
        {
          "keyName": "notMetSlaPercentage",
          "dataType": "int"
        },
        {
          "keyName": "totalCount",
          "dataType": "int"
        }
      ],
      "instrumentation": null,
      "message": null
    },
    {
      "version": "2.0",
      "data": [
        {
          "ssid": "@CorpSSID",
          "values": {
            "notMetSlaCount": "4251",
            "notMetSlaPercentage": 93,
            "totalCount": "4588"
          }
        },
        {
          "ssid": "c9800AP11AX",
          "values": {
            "notMetSlaCount": "337",
            "notMetSlaPercentage": 7,
            "totalCount": "4588"
          }
        }
      ],
      "page": null,
      "scope": null,
      "metadata": [
        {
          "keyName": "ssid",
          "dataType": "string"
        },
        {
          "keyName": "notMetSlaCount",
          "dataType": "int"
        },
        {
          "keyName": "notMetSlaPercentage",
          "dataType": "int"
        },
        {
          "keyName": "totalCount",
          "dataType": "int"
        }
      ],
      "instrumentation": null,
      "message": null
    }
  ],
  "Site_networkDevicesData_Template":{
    "version": "1.0",
    "page": {
      "pageSize": 0,
      "offset": 0,
      "totalCount": 0,
      "randomMacCount": null,
      "duidCount": null,
      "nextKey": null,
      "topN": null
    }
  },
  "Site_Global_Data_Template":[
    {
      "siteId": "80b4fcb8-6f56-4986-b520-3b6ce972464a",
      "values": {
        "coverageViolationsCount": "122",
        "connectionSpeedViolationsCount": "163",
        "siteType": "none",
        "onboardingAttemptsMetSlaCount": "1222",
        "onboardingAttemptsViolationsCount": "617",
        "roamingAttemptsTotalCount": "629",
        "connectionSpeedSlaPercentage": 83,
        "onboardingAttemptsTotalCount": "7614",
        "siteHierarchyGraphId": "/80b4fcb8-6f56-4986-b520-3b6ce972464a/",       
        "onboardingDurationSlaPercentage": 99,
        "roamingAttemptsMetSlaCount": "370",
        "roamingDurationSlaPercentage": 99,
        "parentSiteId": "",
        "siteName": "San Jose",
        "coverageSlaPercentage": 79,
        "roamingDurationViolationsCount": "4",
        "coverageSlaAverage": -57,
        "roamingAttemptsSlaPercentage": 59,
        "leafSite": false,
        "roamingDurationSlaAverage": 792,
        "roamingAttemptsViolationsCount": "151",
        "onboardingAttemptsSlaPercentage": 16,
        "connectionSpeedSlaAverage": 343,
        "siteHierarchy": "Global/San Jose",
        "onboardingDurationSlaAverage": 1582,
        "apDeviceTotalCount": 23,
        "onboardingDurationViolationsCount": "12",
        "numberOfWirelessClients": 12,
        "siteId": "80b4fcb8-6f56-4986-b520-3b6ce972464a",
        "coverageMetSlaCount": "2919",
        "coverageTotalCount": "3090",
        "onboardingSlaPercentage": 24,
        "onboardingMetSlaCount": "4124",
        "onboardingTotalCount": "9413",
        "roamingSlaPercentage": 62,
        "roamingMetSlaCount": "532",
        "roamingTotalCount": "630",
        "connectionSpeedSlaPercentage": 71,
        "connectionSpeedMetSlaCount": "25638",
        "connectionSpeedTotalCount": "29932",
        "roamingAttemptsSlaPercentage": 99,
        "onboardingAttemptsSlaPercentage": 97,
        "roamingDurationSlaPercentage": 96,
        "onboardingDurationSlaPercentage": 95,
      }
    }
  ]
});